export default {
  10: {
    63710: [
      {
        code: '63710000',
        text:
          'Для обеспечения безопасных условий транспортирования каких наливных химически опасных грузов рекомендуется использование цистерны, рассчитанной на избыточное давление, оборудованной предохранительным клапаном с мембранным предохранительным устройством?',
        answers: [
          'Для вязких нефтепродуктов',
          'Для аммиака безводного',
          'Для легковоспламеняющихся жидкостей (нефтепродуктов)',
          'Для азота, аргона, гелия, кислорода, криптона, ксенона, неона, воздуха, углекислого газа',
        ],
        correctAnswers: ['Для аммиака безводного'],
      },
      {
        code: '63710001',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63710002',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от вида взрывозащиты',
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63710003',
        text:
          'На основании какой документации следует производить приемку и опорожнение вагонов-цистерн и контейнеров-цистерн с жидким хлором?',
        answers: [
          'Инструкции, утвержденной техническим руководителем отправляющей организации',
          'Инструкции, утвержденной техническим руководителем принимающей организации',
          'Документации, составленной организацией-разработчиком',
          'Правил безопасности производств хлора и хлорсодержащих сред',
        ],
        correctAnswers: [
          'Инструкции, утвержденной техническим руководителем принимающей организации',
        ],
      },
      {
        code: '63710004',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в исходных данных на проектирование и технологическом регламенте на производство продукции',
          'Только в проектной документации',
          'Только в технологическом регламенте на производство продукции',
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63710005',
        text:
          'Кто осуществляет окраску вновь изготовленной транспортной тары для жидкого хлора и нанесение на ней надписей?',
        answers: [
          'Организация-изготовитель',
          'Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара',
          'Экспертная организация',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63710006',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из частных разделов',
          'Из разделов, предусмотренных требованиями Федеральных норм и правил в области промышленной безопасности',
          'Из общих и специальных разделов',
          'Из специализированных разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63710007',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63710008',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63710009',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63710010',
        text:
          'Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только физико-химические свойства перемещаемых продуктов',
          'Только параметры технологического процесса',
          'Должны быть учтены все перечисленные характеристики',
          'Только показатели надежности и конструктивные особенности с учетом критических параметров',
        ],
        correctAnswers: ['Должны быть учтены все перечисленные характеристики'],
      },
      {
        code: '63710011',
        text:
          'В каком из перечисленных случаев рекомендуется использовать положения Руководства по безопасности при транспортировании опасных веществ на опасных производственных объектах железнодорожными и автомобильными транспортными средствами?',
        answers: [
          'При разработке технологических процессов, эксплуатации, техническом перевооружении, капитальном ремонте, консервации и ликвидации ОПО, разработке обоснования безопасности ОПО, а также изменений, вносимых в обоснование безопасности ОПО',
          'При проведении экспертизы промышленной безопасности технических устройств, предназначенных для транспортирования (перемещения) опасных веществ, зданий и сооружений на ОПО',
          'При изготовлении, монтаже, наладке, обслуживании, диагностировании и ремонте технических устройств, применяемых на ОПО',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63710012',
        text:
          'Какая устанавливается максимальная норма налива жидкого хлора в емкость (резервуар, танк, сборник, вагон-цистерна, контейнер-цистерна)?',
        answers: ['1,25 кг/дм³', '2,15 кг/дм³', '1,75 кг/дм³', '1 кг/дм³'],
        correctAnswers: ['1,25 кг/дм³'],
      },
      {
        code: '63710013',
        text:
          'Какие требования к использованию железнодорожных цистерн с растворителями на объектах производств растительных масел указаны неверно?',
        answers: [
          'Для слива растворителя из цистерн должны использоваться установки, предназначенные для нижнего слива-налива нефти и нефтепродуктов из железнодорожных цистерн',
          'Для слива растворителя из цистерн могут быть использованы сливоналивные механизированные стояки со стационарной площадкой, лестницей и откидным мостиком для обслуживания горловины цистерны',
          'Цистерны, находящиеся под сливом (наливом) должны быть заземлены',
          'Допускается использование железнодорожных цистерн с растворителями, находящиеся на железнодорожных путях, в качестве стационарных складских (расходных) емкостей',
        ],
        correctAnswers: [
          'Допускается использование железнодорожных цистерн с растворителями, находящиеся на железнодорожных путях, в качестве стационарных складских (расходных) емкостей',
        ],
      },
      {
        code: '63710014',
        text:
          'Что может являться основанием для принятия органом по сертификации решения об отказе в выдаче сертификата соответствия требованиям технического регламента Таможенного союза?',
        answers: [
          'Несоответствие продукции требованиям настоящего технического регламента ТС',
          'Отрицательный результат проверки состояния производства продукции (если это установлено схемой сертификации)',
          'Наличие недостоверной информации в представленных документах',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63710015',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63710016',
        text:
          'В каком случае в нормативных документах на продукцию указывают температуру разложения?',
        answers: [
          'В случае, если температура разложения продукции не более 50°С',
          'В случае, если температура разложения продукции выше 10°С',
          'В случае, если температура разложения продукции не более 40°С',
          'В случае, если температура разложения продукции не более 10°С',
        ],
        correctAnswers: [
          'В случае, если температура разложения продукции не более 50°С',
        ],
      },
      {
        code: '63710017',
        text:
          'Кто определяет нормы наполнения цистерн и бочек для перевозки сжиженных газов?',
        answers: [
          'Изготовитель',
          'Эксплуатирующая организация',
          'Научно-исследовательская организация',
          'Ростехнадзор',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63710018',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Дистанционно (из безопасного места)',
          'По месту',
          'Определяется разработчиком проекта',
          'По месту и дистанционно (из безопасного места)',
        ],
        correctAnswers: ['По месту и дистанционно (из безопасного места)'],
      },
      {
        code: '63710019',
        text:
          'В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?',
        answers: [
          'Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 10 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
          'После назначения нового руководителя организации, эксплуатирующей объект',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63710020',
        text:
          'При каком условии допускается выполнять производство погрузочно-разгрузочных работ на ОПО?',
        answers: [
          'Во время использования открытого огня в местах производства погрузки-выгрузки опасных веществ при условии соблюдения дополнительных мер безопасности',
          'При условии курения на расстоянии 30 м от мест производства погрузки или выгрузки опасных грузов',
          'Во время грозы',
          'В темное время суток при искусственном освещении',
        ],
        correctAnswers: ['В темное время суток при искусственном освещении'],
      },
      {
        code: '63710021',
        text:
          'Что из перечисленного не приводится в аварийной карточке на перевозку опасного груза в разделе "Необходимые указания по действиям при аварийной ситуации"?',
        answers: [
          'Указания по действиям при пожаре',
          'Указания по нейтрализации опасных веществ',
          'Указания по применению средств индивидуальной защиты',
          'Указания по мерам первой помощи',
        ],
        correctAnswers: [
          'Указания по применению средств индивидуальной защиты',
        ],
      },
      {
        code: '63710022',
        text:
          'Где разрешается использование железнодорожных цистерн со сжиженными горючими газами, легковоспламеняющимися жидкостями и горючими жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?',
        answers: [
          'В местах проведения погрузочно-разгрузочных работ',
          'На технологических путях организации',
          'На путях необщего пользования',
          'Нигде не разрешается',
        ],
        correctAnswers: ['Нигде не разрешается'],
      },
      {
        code: '63710023',
        text:
          'Какой категории взрывоопасности технологических блоков не существует?',
        answers: [
          'I категории',
          'II категории',
          'III категории',
          'IV категории',
        ],
        correctAnswers: ['IV категории'],
      },
      {
        code: '63710024',
        text:
          'В соответствии с каким из нижеуказанных ГОСТов осуществляется классификация грузов?',
        answers: [
          'ГОСТ 14192-96. Межгосударственный стандарт. Маркировка грузов',
          'ГОСТ 19433-88. Грузы опасные. Классификация и маркировка',
          'ГОСТ 26319-2020. Грузы опасные. Упаковка',
          'ГОСТ 19433-81. Грузы опасные. Классификация',
        ],
        correctAnswers: [
          'ГОСТ 19433-88. Грузы опасные. Классификация и маркировка',
        ],
      },
      {
        code: '63710025',
        text:
          'Каким образом рекомендуется осуществлять транспортирование капролактама?',
        answers: [
          'Под слоем воды',
          'Под слоем атмосферы инертного газа',
          'Под слоем незамерзающей жидкости',
        ],
        correctAnswers: ['Под слоем атмосферы инертного газа'],
      },
      {
        code: '63710026',
        text:
          'Куда наносится маркировка, характеризующая вид и степень опасности груза, при транспортировании груза в контейнере?',
        answers: [
          'На дверь, боковую стенку и, если позволяет конструкция, крышу',
          'Только на боковую стенку',
          'На дверь, заднюю стенку и, если позволяет конструкция, днище',
          'Только на дверь',
        ],
        correctAnswers: [
          'На дверь, боковую стенку и, если позволяет конструкция, крышу',
        ],
      },
      {
        code: '63710027',
        text: 'Какие опасные грузы относятся к 4 классу опасности?',
        answers: [
          'Взрывчатые материалы',
          'Окисляющие вещества',
          'Газы сжатые, сжиженные и растворенные под давлением',
          'Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой',
        ],
        correctAnswers: [
          'Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой',
        ],
      },
      {
        code: '63710028',
        text: 'Какие опасные грузы относятся к 7 классу опасности?',
        answers: [
          'Твердые легковоспламеняющиеся вещества',
          'Радиоактивные материалы',
          'Взрывчатые вещества',
          'Едкие и коррозионные вещества',
        ],
        correctAnswers: ['Радиоактивные материалы'],
      },
      {
        code: '63710029',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Все устройства, задействованные в системе противоаварийной защиты',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63710030',
        text:
          'В какие сроки должен пересматриваться план мероприятий по локализации и ликвидации последствий аварий на ОПО при истечении срока действия предыдущего плана мероприятий?',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не менее чем за 7 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'В течение не более чем 7 календарных дней после истечения срока действия предыдущего плана мероприятий',
          'В течение не более чем 15 календарных дней после истечения срока действия предыдущего плана мероприятий',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
      },
      {
        code: '63710031',
        text:
          'Какие вагоны-цистерны рекомендуется использовать для транспортирования водорода пероксида, водного раствора (концентрацией более 60%)?',
        answers: [
          'Цистерны, оборудованные колпаком с кожухом и приспособлением для запорно-предохранительного устройства, с устройством верхнего слива',
          'Цистерны, оборудованные устройствами верхнего слива',
          'Цистерны из алюминиевых сплавов, рассчитанные на избыточное давление',
          'Цистерны из нержавеющей стали, с устройством термоизоляции, с устройством для разогрева',
        ],
        correctAnswers: [
          'Цистерны из алюминиевых сплавов, рассчитанные на избыточное давление',
        ],
      },
      {
        code: '63710032',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63710033',
        text:
          'В чьи обязанности при перевозке опасных грузов входит нанесение на тару, вагоны, контейнеры знаков, кодов опасности, предусмотренных правилами перевозок грузов железнодорожным транспортом?',
        answers: [
          'На руководство железнодорожной станции, принимающей груз к перевозке',
          'На грузоотправителя',
          'На грузополучателя',
          'На владельца железнодорожного пути',
        ],
        correctAnswers: ['На грузоотправителя'],
      },
      {
        code: '63710034',
        text:
          'Кто определяет давление срабатывания мембраны и открытия предохранительного клапана, его пропускную способность для вагонов-цистерн и контейнеров-цистерн?',
        answers: [
          'Разработчик оборудования',
          'Владелец оборудования',
          'Грузоперевозчик',
        ],
        correctAnswers: ['Разработчик оборудования'],
      },
      {
        code: '63710035',
        text:
          'Сколько классов опасных грузов определяется по ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"?',
        answers: ['5 классов', '6 классов', '9 классов', '7 классов'],
        correctAnswers: ['9 классов'],
      },
      {
        code: '63710036',
        text:
          'Каким документом устанавливается перечень опасных грузов, погрузка и выгрузка которых в местах общего и необщего пользования не допускаются?',
        answers: [
          'Правилами перевозок грузов железнодорожным транспортом',
          'Правилами технической эксплуатации железных дорог Российской Федерации',
          'Правилами по охране труда в хозяйстве перевозок федерального железнодорожного транспорта',
          'Всеми перечисленными документами',
        ],
        correctAnswers: [
          'Правилами перевозок грузов железнодорожным транспортом',
        ],
      },
      {
        code: '63710037',
        text:
          'Размещение какого количества одновременно отправляемых или поступающих вагонов-цистерн с жидким хлором должен обеспечивать отстойный путь (тупик)?',
        answers: [
          'Всей партии, но не более 14 штук',
          'Всей партии, но не более 10 штук',
          'Половины партии, при этом не более 7 штук',
          'Половины партии, при этом не более 5 штук',
        ],
        correctAnswers: ['Всей партии, но не более 10 штук'],
      },
      {
        code: '63710038',
        text:
          'При перевозке каких опасных грузов сливоналивные устройства вагонов-цистерн оборудуются скоростными клапанами, исключающими выход продукта при разрыве трубопровода?',
        answers: [
          'Опасных грузов класса 6',
          'Опасных грузов класса 2',
          'Опасных грузов класса 3',
          'Опасных грузов класса 5',
          'При перевозке любых опасных грузов',
        ],
        correctAnswers: ['Опасных грузов класса 2'],
      },
      {
        code: '63710039',
        text:
          'Какое из приведенных требований к эксплуатации транспортных цистерн и бочек указано неверно?',
        answers: [
          'При эксплуатации транспортных цистерн регистрации в государственном реестре ОПО подлежат только те объекты эксплуатирующих организаций, на которых осуществляют хранение и использование цистерн под давлением газов в технологическом процессе',
          'Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом является деятельностью в области промышленной безопасности',
          'Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом не регламентируется требованиями ФНП ОРПД и осуществляется в соответствии с требованиями иных нормативных правовых актов и международных соглашений, действующих на территории Российской Федерации',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом является деятельностью в области промышленной безопасности',
        ],
      },
      {
        code: '63710040',
        text:
          'Что из перечисленного не допускается при наполнении контейнеров хлором?',
        answers: [
          'Сброс абгазов до остаточного давления, соответствующего равновесному давлению насыщенных паров при температуре налива жидкого хлора, после окончания налива',
          'Наполнение контейнеров в горизонтальном положении',
          'Расположение вентилей друг над другом при наполнении контейнеров',
          'Отвод абгазов при наливе хлора',
        ],
        correctAnswers: ['Отвод абгазов при наливе хлора'],
      },
      {
        code: '63710041',
        text:
          'Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Организацию взаимодействия сил и средств',
          'Состав и дислокацию сил и средств',
          'Организацию управления, связи и оповещения при аварии на объекте, первоочередные действия при получении сигнала об авариях на объекте',
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
          'Мероприятия, направленные на обеспечение безопасности населения, сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте',
        ],
        correctAnswers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
        ],
      },
      {
        code: '63710042',
        text:
          'Каким должно быть минимальное электрическое сопротивление между всеми элементами цистерны магистральных железных дорог колеи 1520 мм?',
        answers: [
          'Не более 0,05 Ом',
          'Не более 0,15 Ом',
          'Не более 0,5 Ом',
          'Не более 0,25 Ом',
        ],
        correctAnswers: ['Не более 0,15 Ом'],
      },
      {
        code: '63710043',
        text: 'Какие цистерны должны иметь термоизоляцию или теневую защиту?',
        answers: [
          'Цистерны, наполняемые жидким аммиаком, при температуре, не превышающей в момент окончания наполнения минус 25 °С',
          'Цистерны, заполняемые сжиженным кислородом и азотом при температуре, не превышающей в момент окончания наполнения минус 25 °С',
          'Все цистерны',
        ],
        correctAnswers: [
          'Цистерны, наполняемые жидким аммиаком, при температуре, не превышающей в момент окончания наполнения минус 25 °С',
        ],
      },
      {
        code: '63710044',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В технологическом регламенте',
          'В паспортах оборудования и трубопроводной арматуры',
          'В техническом задании на изготовление оборудования',
          'В проектной документации',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63710045',
        text:
          'Какое минимальное время пребывания под навесом заполненных хлором контейнеров или баллонов?',
        answers: [
          'Не менее одних суток',
          'Не менее двух суток',
          'Не менее трех суток',
          'Не менее семи суток',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63710046',
        text:
          'Какая допускается погрешность массы тары при взвешивании цистерны магистральных железных дорог колеи 1520 мм на вагонных весах?',
        answers: ['±10 кг', '±50 кг', '±70 кг', '±100 кг'],
        correctAnswers: ['±50 кг'],
      },
      {
        code: '63710047',
        text:
          'Какую маркировку, обеспечивающую идентификацию продукции независимо от года ее выпуска, должен иметь железнодорожный подвижной состав в соответствии с конструкторской документацией?',
        answers: [
          'Единый знак обращения продукции на рынке государств - членов ТС',
          'Табличка или надпись о проведенных ремонтах',
          'Наименование изделия и (или) обозначение серии или типа, номер',
          'Масса тары',
          'Всю перечисленную',
        ],
        correctAnswers: ['Всю перечисленную'],
      },
      {
        code: '63710048',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63710049',
        text:
          'Какие данные необходимо установить для присвоения опасному грузу классификационного шифра?',
        answers: [
          'Категорию, характеризующую дополнительный вид опасности',
          'Класс (подкласс), к которому относится груз',
          'Виды опасности, характеризующие груз и степень опасности',
          'Группу, характеризующую степень опасности груза',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63710050',
        text:
          'Каким оборудованием оснащаются цистерны, предназначенные для перевозки по железным дорогам сжиженного горючего газа, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только средствами контроля уровня налива',
          'Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами',
          'Только предохранительными клапанами и другой арматурой',
          'Только сливоналивными устройствами',
        ],
        correctAnswers: [
          'Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами',
        ],
      },
      {
        code: '63710051',
        text:
          'Какую маркировку должна содержать каждая грузовая единица, содержащая опасный груз?',
        answers: [
          'Транспортное наименование груза',
          'Вид и степень опасности груза',
          'Номер аварийной карточки',
          'Адрес получателя груза',
        ],
        correctAnswers: ['Вид и степень опасности груза'],
      },
      {
        code: '63710052',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63710053',
        text:
          'Какой из перечисленных газов относится к группе 3 опасных грузов класса 2?',
        answers: [
          'Сжатые газы, критическая температура которых менее 10 °С',
          'Растворенные газы под давлением',
          'Сжиженные газы, критическая температура которых не менее 70 °С',
          'Сжиженные охлажденные газы, транспортируемые под давлением, близким к атмосферному',
        ],
        correctAnswers: [
          'Сжиженные газы, критическая температура которых не менее 70 °С',
        ],
      },
      {
        code: '63710054',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63710055',
        text: 'Каким образом необходимо наполнять цистерны фосфором?',
        answers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °С. Слой воды или раствора должен быть высотой не менее 500 мм',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 25 °С. Слой воды или раствора должен быть высотой не менее 100 мм, свободное пространство цистерны - не менее 10 %',
          'Перед наполнением цистерн их необходимо продуть инертным газом',
        ],
        correctAnswers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
        ],
      },
      {
        code: '63710056',
        text:
          'Что из перечисленного не входит в комплект "В" для ликвидации утечек хлора из железнодорожной (автомобильной) цистерны, танка, контейнера-цистерны?',
        answers: [
          'Быстромонтируемое устройство для ликвидации утечек хлора из корпуса цистерны, танка',
          'Набор свинцовых конусных пробок для заделки отверстий до 50 мм',
          'Герметизирующие устройства для ликвидации утечек хлора из предохранительного клапана цистерны',
          'Герметизирующий колпак на арматуру цистерны',
        ],
        correctAnswers: [
          'Набор свинцовых конусных пробок для заделки отверстий до 50 мм',
        ],
      },
      {
        code: '63710057',
        text:
          'Какие из перечисленных требований к стояночным тормозам железнодорожного подвижного состава указаны неверно?',
        answers: [
          'Стояночные тормоза железнодорожного подвижного состава должны обеспечивать расчетное тормозное нажатие и удержание единицы железнодорожного подвижного состава в пределах допустимых значений',
          'Штурвал ручного стояночного тормоза должен быть оснащен устройством, исключающим самопроизвольное вращение штурвала',
          'Применение автоматических стояночных тормозов не допускается',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Применение автоматических стояночных тормозов не допускается',
        ],
      },
      {
        code: '63710058',
        text:
          'В каком случае допускается совмещение маркировки, характеризующей вид и степень опасности, с транспортной маркировкой и маркировкой, характеризующей груз, на одном ярлыке?',
        answers: [
          'Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков',
          'Не допускается ни в каком случае',
          'Допускается только при согласовании с Минтрансом России',
          'Допускается только для класса опасности 1',
        ],
        correctAnswers: [
          'Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков',
        ],
      },
      {
        code: '63710059',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Наличие природных оврагов, выемок, низин',
          'Траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63710060',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
          'Во время грозы',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63710061',
        text:
          'Каким образом допускается налив гипохлорита натрия в транспортные емкости?',
        answers: [
          'При помощи насосов, предназначенных для перекачки гипохлорита натрия',
          'Самотеком из напорных емкостей',
          'Методом передавливания сжатым воздухом (азотом)',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63710062',
        text:
          'Какая устанавливается степень защиты электрического оборудования системы разогрева цистерн магистральных железных дорог колеи 1520 мм для перевозки затвердевающих грузов?',
        answers: ['IP43', 'IP44', 'IP54', 'IP65'],
        correctAnswers: ['IP44'],
      },
      {
        code: '63710063',
        text:
          'Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?',
        answers: [
          'В захоложенном состоянии при температуре ниже температуры окружающей среды',
          'При температуре кипения жидкого хлора при атмосферном давлении (изотермическом способе хранения)',
          'При температуре плавления жидкого хлора',
          'При температуре окружающей среды',
        ],
        correctAnswers: ['При температуре плавления жидкого хлора'],
      },
      {
        code: '63710064',
        text:
          'Чем должен быть снабжен каждый наливной и спускной вентиль цистерны и бочки для сжиженного газа?',
        answers: ['Всем перечисленным', 'Клапаном', 'Заглушкой', 'Штуцером'],
        correctAnswers: ['Заглушкой'],
      },
      {
        code: '63710065',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При обосновании в проектной документации (документации на техническое перевооружение) при малых объемных скоростях подачи, в том числе в системах дозирования',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'При обосновании в проектной документации (документации на техническое перевооружение) при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63710066',
        text:
          'Нахождение каких стационарных систем трубопроводов на пунктах слива-налива жидкого хлора не предусмотрено Правилами безопасности производств хлора и хлорсодержащих сред?',
        answers: [
          'Трубопроводов для отвода газообразного хлора на потребление или поглощение',
          'Трубопроводов для слива-налива жидкого хлора',
          'Трубопроводов для подачи в вагон-цистерну сжатого воздуха (азота) или хлора для передавливания',
          'Трубопроводов пара и горячей воды для подогрева',
        ],
        correctAnswers: ['Трубопроводов пара и горячей воды для подогрева'],
      },
      {
        code: '63710067',
        text:
          'Что из перечисленного не включает в себя железнодорожный подвижной состав?',
        answers: [
          'Грузовые вагоны',
          'Пассажирские вагоны локомотивной тяги',
          'Включает все перечисленное',
          'Мотор-вагонный подвижной состав и его вагоны',
          'Локомотивы',
          'Специальный железнодорожный подвижной состав',
        ],
        correctAnswers: ['Включает все перечисленное'],
      },
      {
        code: '63710068',
        text:
          'Какие требования, предъявляемые к приборам и устройствам для управления железнодорожным подвижным составом, указаны неверно?',
        answers: [
          'Должны быть снабжены надписями и (или) символами в соответствии с конструкторской документацией',
          'Все перечисленные требования указаны верно',
          'Должны быть спроектированы и размещены так, чтобы исключалось непроизвольное их включение, выключение или переключение',
          'Должны быть размещены с учетом значимости выполняемых функций, последовательности и частоты использования',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63710069',
        text:
          'В каком месте основного знака опасности указывается номер класса (подкласса), к которому отнесен груз?',
        answers: [
          'Не регламентируется',
          'В верхнем углу основных знаков указывается номер класса (для грузов класса 5 - номер подкласса), к которому отнесен груз',
          'В нижнем углу основных знаков указывается номер класса (для грузов класса 5 - номер подкласса), к которому отнесен груз',
          'Вдоль условной горизонтальной диагонали',
        ],
        correctAnswers: [
          'В нижнем углу основных знаков указывается номер класса (для грузов класса 5 - номер подкласса), к которому отнесен груз',
        ],
      },
      {
        code: '63710070',
        text: 'Какие опасные грузы относятся к 6 классу опасности?',
        answers: [
          'Взрывчатые вещества',
          'Инфекционные вещества',
          'Ядовитые и инфекционные вещества',
          'Радиоактивные материалы',
        ],
        correctAnswers: ['Ядовитые и инфекционные вещества'],
      },
      {
        code: '63710071',
        text:
          'Когда план мероприятий по локализации и ликвидации последствий аварий считается принятым?',
        answers: [
          'После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, или руководителями обособленных подразделений',
          'После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, или руководителями обособленных подразделений и согласования с органами Ростехнадзора',
        ],
        correctAnswers: [
          'После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63710072',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63710073',
        text:
          'Какой вид временных соединений может применяться для стыковки железнодорожной цистерны к стационарным узлам холодильной установки?',
        answers: [
          'Гибкие металлические рукава',
          'Гибкие рукава из неметаллических материалов',
          'Шарнирные поворотные соединения',
          'Все перечисленные виды соединений',
        ],
        correctAnswers: ['Все перечисленные виды соединений'],
      },
      {
        code: '63710074',
        text:
          'Каким из перечисленных способов осуществляется поиск необходимой аварийной карточки на опасные грузы, перевозимые по железным дорогам?',
        answers: [
          'По Указателю поиска Аварийной карточки по номеру ООН',
          'По Указателю поиска Аварийной карточки по наименованию опасного груза',
          'По Указателю поиска Аварийной карточки на взрывчатые материалы по условному номеру или номеру ООН',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63710075',
        text:
          'Какие из перечисленных мер не принимает лицо, ответственное за организацию и осуществление маневровой работы на железнодорожных путях необщего пользования, при движении маневрового поезда (состава), в котором имеются вагоны с опасными веществами?',
        answers: [
          'Меры по информации машинисту локомотива о наличии в маневровом поезде (составе) вагонов с опасными веществами',
          'Меры по проведению инструктажа работников маневровой бригады о дополнительных мерах, направленных на обеспечение безопасности при производстве маневров',
          'Меры по очистке желобов переездов, технологических проездов, по которым будут следовать вагоны',
        ],
        correctAnswers: [
          'Меры по информации машинисту локомотива о наличии в маневровом поезде (составе) вагонов с опасными веществами',
        ],
      },
      {
        code: '63710076',
        text:
          'По согласованию с кем рекомендуется осуществлять подачу вагонов к фронтам погрузки (выгрузки) опасных веществ?',
        answers: [
          'С лицом, ответственным за организацию и осуществление маневровой работы на железнодорожных путях необщего пользования',
          'С владельцем инфраструктуры железнодорожного транспорта, к которой примыкает этот путь',
          'С руководителем организации, эксплуатирующей ОПО (владельцем железнодорожного пути необщего пользования)',
          'С дежурным по железнодорожной станции',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63710077',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63710078',
        text:
          'Какой из перечисленных терминов соответствует определению "календарная продолжительность эксплуатации продукции, при достижении которой эксплуатация продукции должна быть прекращена независимо от ее технического состояния"?',
        answers: [
          'Назначенный ресурс',
          'Назначенный срок службы',
          'Срок безопасной эксплуатации',
          'Назначенный срок хранения',
        ],
        correctAnswers: ['Назначенный срок службы'],
      },
      {
        code: '63710079',
        text:
          'Кем назначаются работники, ответственные за содержание сигнальных и путевых знаков, тупиковых упоров, переносных сигналов, искусственных сооружений на железнодорожных путях необщего пользования при транспортировании опасных веществ железнодорожным транспортом?',
        answers: [
          'Руководителем организации',
          'Владельцем железнодорожного пути необщего пользования',
          'Лицом, ответственным за обеспечение безопасности при проведении погрузочно-разгрузочных операций и расстановку вагонов по фронтам погрузки (выгрузки)',
          'Лицом, ответственным за организацию и осуществление маневровых работ на железнодорожных путях необщего пользования',
        ],
        correctAnswers: ['Руководителем организации'],
      },
      {
        code: '63710080',
        text:
          'Какая из перечисленных маркировок не содержится на боковом швеллере рамы цистерны магистральных железных дорог колеи 1520 мм?',
        answers: [
          'Заводской номер цистерны по системе нумерации предприятия-изготовителя',
          'Масса тары',
          'Обозначение основной марки стали',
          'Товарный знак',
        ],
        correctAnswers: ['Обозначение основной марки стали'],
      },
      {
        code: '63710081',
        text:
          'В каком случае допускается не устанавливать помост около люка в верхней части железнодорожных цистерн для сжиженных газов?',
        answers: [
          'На железнодорожных цистернах для любых криогенных жидкостей',
          'На железнодорожных цистернах для сжиженного кислорода',
          'На железнодорожных цистернах для азота',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63710082',
        text:
          'В какой цвет должны быть окрашены наконечник и головка соединительного рукава, концевой и разобщительный краны, ручка переключателя режимов и толкатель выпускного клапана воздухораспределителя, сигнальный отросток замка автосцепки, штурвал стояночного тормоза цистерны магистральных железных дорог колеи 1520 мм?',
        answers: [
          'В зеленый цвет',
          'В черный цвет',
          'В желтый цвет',
          'В красный цвет',
        ],
        correctAnswers: ['В красный цвет'],
      },
      {
        code: '63710083',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63710084',
        text:
          'Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?',
        answers: [
          'Только под эстакадами технологических трубопроводов',
          'Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
        correctAnswers: [
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
      },
      {
        code: '63710085',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Инспектор Ростехнадзора',
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63710086',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['30 минут', '10 минут', '60 минут', '15 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63710087',
        text:
          'Что из перечисленного допускается при производстве на железнодорожных путях необщего пользования маневровых работ?',
        answers: [
          'Использование для отстоя вагонов улавливающие и предохранительные тупики',
          'Все ответы неверны',
          'Производство маневров толчками',
          'Использование для закрепления вагонов посторонних предметов в аварийных случаях',
          'Передвижение вагонов автомашинами',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63710088',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Всеми перечисленными средствами',
          'Защитными очками и защитной каской',
          'Воздушными изолирующими аппаратами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63710089',
        text:
          'В каком случае в нормативных документах на продукцию указывают температуру вспышки?',
        answers: [
          'В случае, если температура вспышки не более 61 °С',
          'В случае, если температура вспышки более 61 °С',
          'В случае, если температура вспышки более 75 °С',
          'В случае, если температура вспышки не более 75 °С',
        ],
        correctAnswers: ['В случае, если температура вспышки не более 61 °С'],
      },
      {
        code: '63710090',
        text:
          'Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?',
        answers: [
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
          'Параллельно с рабочими предохранительными клапанами необходимо установить резервные предохранительные клапаны',
          'На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом',
          'Применение рычажно-грузовых предохранительных клапанов не допускается',
        ],
        correctAnswers: [
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
        ],
      },
      {
        code: '63710091',
        text:
          'Какой устанавливается максимальный срок действия сертификата соответствия требованиям технического регламента Таможенного союза?',
        answers: ['3 года', '5 лет', '1 год', '10 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63710092',
        text:
          'Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы различных категорий взрывоопасности технологических блоков взрывопожароопасных производств?',
        answers: [
          'Эксплуатационной документацией',
          'Паспортом технического устройства',
          'Проектной документацией или документацией на техническое перевооружение',
          'Планом ликвидации аварий',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63710093',
        text:
          'Какие из перечисленных вагонов-цистерн, контейнеров-цистерн, контейнеров (бочек) и баллонов допускается использовать для транспортировки жидкого хлора?',
        answers: [
          'На которых имеются механические повреждения',
          'Которые исправны, предназначены для транспортировки любых продуктов',
          'На которых истек срок ревизии предохранительного клапана и мембраны для вагонов-цистерн и контейнеров-цистерн',
          'На которых демонтированы сифонные трубки из баллонов',
        ],
        correctAnswers: [
          'На которых демонтированы сифонные трубки из баллонов',
        ],
      },
      {
        code: '63710094',
        text: 'Какие опасные грузы относятся к 3 классу опасности?',
        answers: [
          'Твердые легковоспламеняющиеся вещества',
          'Легковоспламеняющиеся жидкости',
          'Твердые легковоспламеняющиеся вещества',
          'Газы сжатые, сжиженные и растворенные под давлением',
        ],
        correctAnswers: ['Легковоспламеняющиеся жидкости'],
      },
      {
        code: '63710095',
        text: 'Какие опасные грузы относятся к 5 классу опасности?',
        answers: [
          'Твердые легковоспламеняющиеся вещества',
          'Жидкие легковоспламеняющиеся вещества',
          'Самовозгорающиеся вещества',
          'Окисляющие вещества и органические пероксиды',
        ],
        correctAnswers: ['Окисляющие вещества и органические пероксиды'],
      },
      {
        code: '63710096',
        text:
          'В течение какого времени после наполнения жидким хлором осуществляется отстой вагонов-цистерн (контейнеров-цистерн), заполненных жидким хлором, на территории организации, сопровождающийся ежесменным визуальным осмотром и контролем утечек хлора?',
        answers: [
          'Не менее 3 часов',
          'Не менее 6 часов',
          'Не менее 12 часов',
          'Не менее суток',
        ],
        correctAnswers: ['Не менее суток'],
      },
      {
        code: '63710097',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 15 % НКПР',
          'При 18 % НКПР',
          'При 10 % НКПР',
          'При 20 % НКПР',
        ],
        correctAnswers: ['При 20 % НКПР'],
      },
      {
        code: '63710098',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63710099',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если это регламентировано внутренней документацией организации',
          'План мероприятий разрабатывается на каждый опасный объект отдельно',
          'В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63710100',
        text:
          'С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?',
        answers: [
          'С учетом возможности проведения эффективной и безопасной очистки системы',
          'С учетом химического анализа проб горючих веществ на содержание кислорода',
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
        correctAnswers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
      },
      {
        code: '63710101',
        text:
          'Кто проводит регистрацию изотермического резервуара в установленном порядке?',
        answers: [
          'Организация, эксплуатирующая изотермический резервуар',
          'Проектная организация',
          'Специализированная организация, осуществляющая экспертизу промышленной безопасности',
          'Регистрация изотермического резервуара не требуется',
        ],
        correctAnswers: [
          'Организация, эксплуатирующая изотермический резервуар',
        ],
      },
      {
        code: '63710102',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с тканевым уплотнением в затворе',
          'Арматура в соответствии с проектной документацией',
          'Арматура с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63710103',
        text:
          'С какими из перечисленных устройств и приспособлений допускается изготавливать вагоны-цистерны магистральных железных дорог колеи 1520 мм?',
        answers: [
          'С переходными площадками, расположенными на консолях рамы',
          'С авторежимом',
          'С будками для сопровождающих лиц',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63710104',
        text:
          'В каком случае допускается наполнять газом цистерны и бочки для перевозки сжиженных газов?',
        answers: [
          'Если отсутствуют контрольно-измерительные приборы',
          'Если отсутствуют надписи',
          'В цистернах или бочках находится не тот газ, для которого они предназначены',
          'Все ответы неверны',
          'Если отсутствует арматура',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63710105',
        text:
          'Каким из нижеперечисленных федеральных законов регулируются отношения, возникающие между перевозчиками, пассажирами, грузоотправителями (отправителями), грузополучателями (получателями), владельцами инфраструктур железнодорожного транспорта общего пользования, владельцами железнодорожных путей необщего пользования?',
        answers: [
          'Федеральный закон от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
          'Федеральный закон от 10 января 2003 г. N 18-ФЗ "Устав железнодорожного транспорта Российской Федерации"',
          'Федеральный закон от 21 июля 1997 г. N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Федеральный закон от 10 января 2003 г. N 18-ФЗ "Устав железнодорожного транспорта Российской Федерации"',
        ],
      },
      {
        code: '63710106',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
          'Руководства по эксплуатации технологического оборудования и их ксерокопии',
          'Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах',
          'Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63710107',
        text:
          'С кем согласовывается Инструкция о порядке обслуживания и организации движения на железнодорожном пути необщего пользования?',
        answers: [
          'С владельцем инфраструктуры железнодорожного транспорта, к которой примыкает путь необщего пользования',
          'С владельцем железнодорожного пути необщего пользования',
          'С лицом, ответственным за организацию и осуществление маневровой работы на железнодорожных путях необщего пользования',
          'С инспектором Ростехнадзора',
        ],
        correctAnswers: [
          'С владельцем инфраструктуры железнодорожного транспорта, к которой примыкает путь необщего пользования',
        ],
      },
      {
        code: '63710108',
        text:
          'С кем согласовывается подача вагонов на железнодорожные пути необщего пользования?',
        answers: [
          'С лицом, ответственным за организацию и осуществление маневровой работы на железнодорожных путях необщего пользования',
          'С руководителем организации, эксплуатирующей ОПО (владельцем железнодорожного пути необщего пользования)',
          'С владельцем инфраструктуры железнодорожного транспорта, к которой примыкает этот путь',
          'С лицом, ответственным за обеспечение безопасности при проведении погрузочно-разгрузочных операций и расстановку вагонов по фронтам погрузки (выгрузки)',
        ],
        correctAnswers: [
          'С лицом, ответственным за организацию и осуществление маневровой работы на железнодорожных путях необщего пользования',
        ],
      },
      {
        code: '63710109',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63710110',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10 %, а содержание кислорода не менее 20 % объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10 %, а содержание кислорода не менее 25 % объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30 % объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20 % объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20 % объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63710111',
        text:
          'Каким диаметром должен быть люк-лаз вагона-цистерны магистральных железных дорог колеи 1520 мм?',
        answers: [
          'Не менее 350 мм',
          'Не менее 550 мм',
          'Не менее 450 мм',
          'Не менее 500 мм',
        ],
        correctAnswers: ['Не менее 450 мм'],
      },
      {
        code: '63710112',
        text:
          'Какие вещества относятся к категории 912 опасных грузов класса 9?',
        answers: [
          'Жидкости с температурой вспышки более 61 °C, но не более 90 °C',
          'Твердые вещества, воспламеняющиеся от действия (не менее 30 секунд, но не более 120 секунд) газовой горелки',
          'Вещества, которые в условиях специальных испытаний способны самонагреваться до температуры более 150 °С, но не более 200 °С за время не более 24 часов при температуре окружающей среды 140 °С',
          'Воспламеняющиеся, ядовитые, едкие и (или) коррозионные вещества в аэрозольной упаковке вместимостью от 50 до 1000 см³',
        ],
        correctAnswers: [
          'Жидкости с температурой вспышки более 61 °C, но не более 90 °C',
        ],
      },
      {
        code: '63710113',
        text: 'Какие опасные грузы относятся к 8 классу опасности?',
        answers: [
          'Самовозгорающиеся вещества',
          'Токсичные вещества',
          'Окисляющие вещества',
          'Едкие и коррозионные вещества',
        ],
        correctAnswers: ['Едкие и коррозионные вещества'],
      },
      {
        code: '63710114',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63710115',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О знании действий при пожаре',
          'О знании правил оказания первой помощи',
          'О наличии медицинских противопоказаний к работе',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63710116',
        text:
          'Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите 2 правильных варианта ответа.',
        answers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции в случае недостаточности воздухообмена',
          'Общеобменная вентиляция должна включаться при срабатывании установленных в помещении сигнализаторов довзрывных концентраций',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
        ],
        correctAnswers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
        ],
      },
      {
        code: '63710117',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63710118',
        text:
          'Кем должен быть осмотрен прибывший в организацию вагон-цистерна для перевозки жидкого хлора с целью проверки исправности ходовой части вагона-цистерны, а также крепления котла вагона-цистерны к раме?',
        answers: [
          'Уполномоченными лицами организации-грузоотправителем',
          'Представителем организации-разработчика',
          'Представителем организации-грузополучателя',
          '. Представителем организации-грузоперевозчика',
        ],
        correctAnswers: [
          'Уполномоченными лицами организации-грузоотправителем',
        ],
      },
      {
        code: '63710119',
        text:
          'Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'Руководителями муниципальных образований, на территории которых расположен объект',
          'Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63710120',
        text: 'На сколько допускается уменьшать размер знака опасности?',
        answers: [
          'До 35 мм',
          'До 25 мм',
          'До 30 мм',
          'Размер знака опасности не допускается уменьшать',
        ],
        correctAnswers: ['До 25 мм'],
      },
      {
        code: '63710121',
        text:
          'Чем должны быть оснащены локомотивы, используемые для перевозки пассажиров, специальных и опасных грузов, и головные вагоны моторвагонного подвижного состава?',
        answers: [
          'Всем перечисленным',
          'Устройством контроля плотности пневматической тормозной магистрали.',
          'Автоматической локомотивной сигнализацией',
          'Аппаратурой спутниковой навигации, способствующей обеспечению безопасности движения',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63710122',
        text:
          'В течение какого максимального времени орган по сертификации оборудования требованиям технического регламента "О безопасности инфраструктуры железнодорожного транспорта" рассматривает заявку на проведение сертификации и сообщает заявителю о своем решении?',
        answers: ['5 дней', '15 дней', '1 месяца', '45 дней'],
        correctAnswers: ['1 месяца'],
      },
      {
        code: '63710123',
        text:
          'Каким способом допускается проводить передавливание жидкого хлора?',
        answers: [
          'Нагнетанием в опорожняемую емкость сухого сжатого воздуха (азота) или паров газообразного хлора из другого сосуда или термокомпрессора',
          'За счет собственного давления паров хлора в опорожняемом сосуде и отбора хлоргаза из наполняемого сосуда',
          'Комбинированным способом',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63710124',
        text:
          'Кто осуществляет непосредственное руководство маневровой работой одиночных локомотивов и маневровых составов на железнодорожных путях необщего пользования?',
        answers: [
          'Дежурный по железнодорожной станции',
          'Лицо, ответственное за организацию и осуществление маневровых работ',
          'Руководитель маневров',
          'Любое перечисленное лицо',
        ],
        correctAnswers: ['Руководитель маневров'],
      },
      {
        code: '63710125',
        text:
          'Проведение каких работ является обязательным при постановке вагона-цистерны на пункт слива-налива?',
        answers: [
          'Проверка работоспособности крепительных устройств крышки люка',
          'Заземление и закрепление тормозными башмаками с обеих сторон',
          'Удаление загрязнений с наружной поверхности цистерны',
          'Проверка наличия знаков опасности, надписей и трафаретов',
        ],
        correctAnswers: [
          'Заземление и закрепление тормозными башмаками с обеих сторон',
        ],
      },
      {
        code: '63710126',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Порядок действий в случае аварий на объекте',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63710127',
        text:
          'Какой максимальный срок выдачи сертификата соответствия требованиям технического регламента Таможенного союза с даты получения органом по сертификации протоколов испытаний и при необходимости документов об устранении выявленных при сертификации несоответствий?',
        answers: [
          '10 рабочих дней',
          '15 рабочих дней',
          '45 рабочих дней',
          '30 рабочих дней',
        ],
        correctAnswers: ['15 рабочих дней'],
      },
      {
        code: '63710128',
        text:
          'Что из перечисленного не содержится в аварийной карточке на перевозку опасного груза?',
        answers: [
          'Номер ООН',
          'Наименование опасного груза',
          'Предприятие - производитель опасного груза',
          'Указания по применению средств индивидуальной защиты',
        ],
        correctAnswers: ['Предприятие - производитель опасного груза'],
      },
      {
        code: '63710129',
        text:
          'По какому показателю определяют степень опасности веществ, выделяющих легковоспламеняющиеся газы при соприкосновении с водой?',
        answers: [
          'По температуре газообразования',
          'По относительному объему газовыделения',
          'По интенсивности газовыделения',
          'По уровню водостойкости',
        ],
        correctAnswers: ['По интенсивности газовыделения'],
      },
      {
        code: '63710130',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63710131',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в установленной степени готовности',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63710132',
        text:
          'В каком случае допускается применение резиновых и резинометаллических рукавов для слива (налива) цистерн жидкого аммиака?',
        answers: [
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
          'Если это регламентировано внутренней эксплуатационной документацией',
          'Если рукава с внутренним диаметром 50 мм',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
        ],
      },
      {
        code: '63710133',
        text:
          'В соответствии с чем устанавливаются обязательные требования к железнодорожному подвижному составу, используемому для организации перевозочного процесса, и формы подтверждения соответствия?',
        answers: [
          'С законодательством Российской Федерации о техническом регулировании',
          'С законодательством Российской Федерации о безопасности движения и эксплуатации железнодорожного транспорта',
          'С законодательством Российской Федерации об экологической безопасности',
          'С законодательством Российской Федерации о безопасности жизни и здоровья граждан',
        ],
        correctAnswers: [
          'С законодательством Российской Федерации о техническом регулировании',
        ],
      },
      {
        code: '63710134',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны уметь пользоваться СИЗ',
          'Исполнители должны пройти инструктаж',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63710135',
        text:
          'К выполнению каких работ рекомендуется предъявлять локомотивы, предназначенные для осуществления маневровых работ на ОПО? Выберите 2 варианта ответа.',
        answers: [
          'К выполнению работ по техническому осмотру',
          'К плановым видам ремонта',
          'К весеннему комиссионному осмотру технического состояния',
          'К осеннему комиссионному осмотру технического состояния',
        ],
        correctAnswers: [
          'К выполнению работ по техническому осмотру',
          'К плановым видам ремонта',
        ],
      },
      {
        code: '63710136',
        text:
          'На сколько должно быть произведено заполнение всех видов тары гипохлоритом натрия (калия)?',
        answers: [
          'Не более чем на 80 % объема тары',
          'Не более чем на 85 % объема тары',
          'Не более чем на 95 % объема тары',
          'Не более чем на 90 % объема тары',
        ],
        correctAnswers: ['Не более чем на 90 % объема тары'],
      },
      {
        code: '63710137',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Установить систему подачи условных сигналов',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Увеличить число наблюдающих и работающих',
          'Только предусмотреть дополнительное освещение внутри емкости',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63710138',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите 2 правильных варианта ответа.',
        answers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
        ],
        correctAnswers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
        ],
      },
      {
        code: '63710139',
        text:
          'Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Токи самозапуска электродвигателей не должны превышать номинальные более чем в два раза',
          'Время срабатывания систем самозапуска должно определяться нагрузкой насосов и компрессоров, но не должно превышать 5 минут',
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
          'Насосы и компрессоры должны оснащаться маховиками для облегчения повторного самозапуска электродвигателей',
        ],
        correctAnswers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
        ],
      },
      {
        code: '63710140',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами организации',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63710141',
        text:
          'Какие из перечисленных данных не указываются в журнале наполнения цистерн и бочек для перевозки сжиженных газов?',
        answers: [
          'Дата наполнения',
          'Наименование организации-изготовителя цистерны и бочек',
          'Уровень жидкости',
          'Подпись лица, производившего наполнение',
        ],
        correctAnswers: ['Уровень жидкости'],
      },
      {
        code: '63710142',
        text:
          'Где приведены основные формы проявления транспортной опасности грузов, а также конкретные меры предосторожности, которые должны соблюдаться при ликвидации аварийных ситуаций с опасными грузами?',
        answers: [
          'В групповых или индивидуальных аварийных карточках',
          'В Положении о допуске перевозчиков к осуществлению железнодорожных перевозок',
          'В должностных инструкциях персонала, сопровождающего опасный груз',
          'В Правилах безопасности и порядке ликвидации аварийных ситуаций с опасными грузами при перевозке их по железным дорогам',
        ],
        correctAnswers: ['В групповых или индивидуальных аварийных карточках'],
      },
      {
        code: '63710143',
        text:
          'Что должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Показатели надежности, конструктивные особенности и параметры работы',
          'Показатели надежности и конструктивные особенности',
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов, исполнение по взрывозащите',
        ],
        correctAnswers: [
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
        ],
      },
      {
        code: '63710144',
        text: 'Какие грузы относятся к 2 классу опасности?',
        answers: [
          'Газы',
          'Ядовитые вещества',
          'Легковоспламеняющиеся жидкости',
          'Инфекционные вещества',
        ],
        correctAnswers: ['Газы'],
      },
      {
        code: '63710145',
        text:
          'Каким должно быть давление сжатого воздуха (азота) при передавливании жидкого хлора газообразным хлором?',
        answers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 1,5 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 2,0 МПа',
          'Давление сжатого воздуха (азота) должно быть не менее чем на 0,05 МПа выше давления в сосуде, в который передавливается хлор',
        ],
        correctAnswers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
        ],
      },
      {
        code: '63710146',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Опасно для жизни!", "Внимание!"',
          '"Газоопасные работы", "Газ"',
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Работают люди!", "Опасно для жизни!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63710147',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63710148',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63710149',
        text:
          'Какие из перечисленных отлитых знаков маркировки не должны иметь в обязательном порядке рамы и балки тележек грузовых вагонов в соответствии с конструкторской документацией?',
        answers: [
          'Условный номер изготовителя',
          'Класс защиты',
          'Две последние цифры года изготовления',
          'Условное обозначение марки стали',
          'Порядковый номер рам и балок по системе нумерации изготовителя',
        ],
        correctAnswers: ['Класс защиты'],
      },
      {
        code: '63710150',
        text:
          'В течение какого минимального срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?',
        answers: [
          'Не менее одних суток',
          'Не менее двух суток',
          'Не менее трех суток',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63710151',
        text:
          'Из какого материала допускается изготовление наконечников резиновых шлангов, предназначенных для сливоналивных операций, на объектах производств растительных масел? Укажите все правильные ответы.',
        answers: ['Из титана', 'Из бронзы', 'Из меди', 'Из нержавеющей стали'],
        correctAnswers: ['Из бронзы', 'Из меди'],
      },
      {
        code: '63710152',
        text:
          'Какой устанавливается максимальный срок действия декларации о соответствии требованиям технического регламента Таможенного союза?',
        answers: ['3 года', '5 лет', '1 год', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63710153',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главным инженером эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63710154',
        text:
          'Какими устройствами должны быть оборудованы изотермические вагоны со служебными и вспомогательными помещениями?',
        answers: [
          'Системой кондиционирования воздуха (вентиляции, отопления, охлаждения)',
          'Экологически чистыми туалетными комплексами',
          'Системой питьевого и хозяйственного водоснабжения',
          'Системой контроля нагрева букс',
          'Всеми перечисленными устройствами',
        ],
        correctAnswers: ['Всеми перечисленными устройствами'],
      },
      {
        code: '63710155',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 25 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63710156',
        text:
          'В каком случае допускается совмещение маркировки, характеризующей вид и степень опасности, с транспортной маркировкой и маркировкой, характеризующей груз, на одном ярлыке?',
        answers: [
          'Допускается только при согласовании с Минтрансом России',
          'Допускается только для класса опасности 1',
          'Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков',
        ],
      },
      {
        code: '63710157',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['20 °С', '35 °С', '25 °С', '30 °С'],
        correctAnswers: ['30 °С'],
      },
      {
        code: '63710158',
        text:
          'Что должны обеспечивать объекты инфраструктуры железнодорожного транспорта и продукция согласно техническому регламенту "О безопасности инфраструктуры железнодорожного транспорта"?',
        answers: [
          'Соблюдение габарита приближения строений',
          'Техническую совместимость с железнодорожным подвижным составом',
          'Выполнение условий эксплуатации с учетом внешних климатических и механических воздействий',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63710159',
        text:
          'В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?',
        answers: [
          'В течение периода приемки цистерны',
          'Наблюдение за цистернами с жидким аммиаком не требуется',
          'В течение всего времени нахождения',
          'В течение слива аммиака',
        ],
        correctAnswers: ['В течение всего времени нахождения'],
      },
      {
        code: '63710160',
        text:
          'Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?',
        answers: [
          'Стальная арматура и фасонные части',
          'Стальная арматура и фасонные части, а также допускается применение запорной арматуры из ковкого и высокопрочного чугуна',
          'Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди, цинка и их сплавов',
          'Стальная арматура и фасонные части, а также допускается применение чугунной запорно-регулирующей арматуры',
        ],
        correctAnswers: ['Стальная арматура и фасонные части'],
      },
      {
        code: '63710161',
        text:
          'Каким из нижеперечисленных федеральных законов устанавливаются основы взаимодействия организаций железнодорожного транспорта и выполняющих работы (услуги) на железнодорожном транспорте индивидуальных предпринимателей с органами государственной власти и организациями других видов транспорта?',
        answers: [
          'Федеральный закон от 21 июля 1997 г. N 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'Федеральный закон от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
          'Федеральный закон от 10 января 2003 г. N 18-ФЗ "Устав железнодорожного транспорта Российской Федерации"',
        ],
        correctAnswers: [
          'Федеральный закон от 10 января 2003 г. N 17-ФЗ "О железнодорожном транспорте в Российской Федерации"',
        ],
      },
      {
        code: '63710162',
        text:
          'Какие из перечисленных данных наносятся клеймением на цистерны и бочки для перевозки сжиженного газа?',
        answers: [
          'Вместимость',
          'Год изготовления',
          'Клеймо отдела технического контроля',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63710163',
        text:
          'Какую информацию не должна содержать маркировка вагона-цистерны, содержащая опасный груз?',
        answers: [
          'Серийный номер ООН',
          'Номер аварийной карточки',
          'Наименование организации-владельца',
          'Знак опасности по ГОСТ 19433',
        ],
        correctAnswers: ['Наименование организации-владельца'],
      },
      {
        code: '63710164',
        text:
          'До какого остаточного давления отводят абгазы из цистерны в абгазную систему после завершения слива хлора?',
        answers: [
          'Не менее 0,05 МПа (0,5 кгс/см2)',
          'Не менее 0,07 МПа (0,7 кгс/см2)',
          'Не менее 0,01 МПа (0,1 кгс/см2)',
        ],
        correctAnswers: ['Не менее 0,05 МПа (0,5 кгс/см2)'],
      },
      {
        code: '63710165',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: [
          'Определяются руководством объекта',
          '3 года',
          '5 лет',
          '2 года',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63710166',
        text:
          'В каком из перечисленных случаев гибкие шланги не применяются при проведении операций слива, налива, транспортирования и хранения растворителя и масла?',
        answers: [
          'Для проведения операций слива и налива в железнодорожные цистерны и другое нестандартное оборудование',
          'Для выполнения вспомогательных операций',
          'Во взрывопожароопасных производствах в качестве стационарных трубопроводов для транспортирования растворителя, мисцеллы',
          'Для продувки аппаратов',
        ],
        correctAnswers: [
          'Во взрывопожароопасных производствах в качестве стационарных трубопроводов для транспортирования растворителя, мисцеллы',
        ],
      },
      {
        code: '63710167',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда допуска',
          'Не менее 6 месяцев со дня закрытия наряда допуска',
          'Не менее 1 года со дня закрытия наряда допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда допуска'],
      },
      {
        code: '63710168',
        text:
          'Что из перечисленного предоставляет в орган по сертификации заявитель, если при подтверждении соответствия продукции требованиям технического регламента "О безопасности инфраструктуры железнодорожного транспорта" не применяет или применяет стандарты частично?',
        answers: [
          'Доказательства соответствия продукции требованиям технического регламента ТС',
          'Сертификат соответствия системы менеджмента качества',
          'Сведения о проведенных исследованиях (испытаниях) в аккредитованных испытательных лабораториях (центрах)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63710169',
        text:
          'Кто обязан за свой счет провести промывку и дезинфекцию вагонов, контейнеров после выгрузки опасных грузов в случаях, предусмотренных правилами перевозок грузов железнодорожным транспортом?',
        answers: [
          'Грузополучатель',
          'Грузоотправитель',
          'Перевозчик',
          'Владелец инфраструктуры',
        ],
        correctAnswers: ['Грузополучатель'],
      },
      {
        code: '63710170',
        text:
          'Какое из приведенных требований к предохранительному клапану, установленному на цистерне, указано неверно?',
        answers: [
          'Площадь отверстий в колпаке должна быть равной площади рабочего сечения предохранительного клапана',
          'Предохранительный клапан, установленный на цистерне, должен сообщаться с газовой фазой цистерны',
          'Предохранительный клапан, установленный на цистерне, должен иметь колпак с отверстиями для выпуска газа в случае открывания клапана',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Площадь отверстий в колпаке должна быть равной площади рабочего сечения предохранительного клапана',
        ],
      },
      {
        code: '63710171',
        text:
          'Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?',
        answers: [
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
          'Тремя предохранительными клапанами',
          'Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива',
          'Двумя штуцерами с вентилем для съемного манометра',
        ],
        correctAnswers: [
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
        ],
      },
      {
        code: '63710172',
        text:
          'По каким показателям определяется степень опасности легковоспламеняющихся жидкостей?',
        answers: [
          'По температуре вспышки и температуре кипения',
          'По вязкости и текучести',
          'По плотности и объему',
          'По температуре горения и температуре плавления',
        ],
        correctAnswers: ['По температуре вспышки и температуре кипения'],
      },
      {
        code: '63710173',
        text:
          'В какой цвет окрашены сигналы остановки, которыми ограждаются вагоны на месте погрузки (выгрузки)?',
        answers: [
          'В красный цвет',
          'В желтый цвет',
          'В оранжевый цвет',
          'В красно-белый цвет',
        ],
        correctAnswers: ['В красный цвет'],
      },
      {
        code: '63710174',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63710175',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'Ко II группе',
          'К группе работ высокой степени опасности',
          'К группе работ средней степени опасности',
        ],
        correctAnswers: ['Ко II группе'],
      },
      {
        code: '63710176',
        text:
          'Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварийных ситуаций',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте (далее - силы и средства), и их соответствие задачам по локализации и ликвидации последствий аварий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
        ],
        correctAnswers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
        ],
      },
      {
        code: '63710177',
        text:
          'Какое количество тормозных башмаков для закрепления различных типов цистерн должно быть установлено для закрепления железнодорожных цистерн при операциях слива, налива растворителя и масла?',
        answers: [
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
          'Не менее 4 на каждой цистерне',
          'Не менее 6 на каждой цистерне',
          'От 4 до 8 в зависимости от типа цистерны',
        ],
        correctAnswers: [
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
        ],
      },
    ],
    63711: [
      {
        code: '63711000',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Укажите все правильные ответы.',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
          'Не позднее 15 календарных дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий',
          'В случае назначения нового руководителя организации',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63711001',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63711002',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
          'Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов',
          'Руководства по эксплуатации технологического оборудования и их ксерокопии',
          'Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63711003',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса и достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63711004',
        text:
          'С учетом каких критериев выбираются насосы и компрессоры, используемые для перемещения газов, легковоспламеняющихся и горючих жидкостей, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств? Выберите 2 правильных варианта ответа.',
        answers: [
          'С учетом физико-химических свойств перемещаемых продуктов',
          'С учетом регламентированных параметров технологического процесса',
          'С учетом диаметра и толщины стенки трубопроводов',
          'С учетом протяженности и способа прокладки трубопроводов',
        ],
        correctAnswers: [
          'С учетом физико-химических свойств перемещаемых продуктов',
          'С учетом регламентированных параметров технологического процесса',
        ],
      },
      {
        code: '63711005',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора.',
          'Исполнители должны пройти инструктаж',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора.',
        ],
      },
      {
        code: '63711006',
        text:
          'Каким образом необходимо укрывать тентом транспортное средство для перевозки опасных грузов?',
        answers: [
          'Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 200 мм и удерживаться фиксирующими приспособлениями',
          'Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 400 мм и быть свободным снизу с целью проветривания',
          'Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 300 мм и быть свободным снизу с целью проветривания',
          'Правилами не регламентируется',
        ],
        correctAnswers: [
          'Тент должен быть натянут, перекрывать борта кузова со всех сторон не менее чем на 200 мм и удерживаться фиксирующими приспособлениями',
        ],
      },
      {
        code: '63711007',
        text:
          'Как должен выглядеть маркировочный знак вещества, опасного для окружающей среды, расположенный рядом с маркировочными надписями на упаковке опасного груза?',
        answers: [
          'Рыба и дерево в равностороннем ромбе',
          'Слон и куст в равнобедренном треугольнике',
          'Зебра и дорога в равностороннем квадрате',
          'Птица и гнездо в круге',
        ],
        correctAnswers: ['Рыба и дерево в равностороннем ромбе'],
      },
      {
        code: '63711008',
        text: 'Что в ДОПОГ понимается под определением "опасные грузы"?',
        answers: [
          'Взрыво- и пожароопасные грузы, которые допускаются к перевозке только соответствующими компетентными органами',
          'Вещества и изделия, которые не допускаются к перевозке согласно ДОПОГ или допускаются к ней только с соблюдением предписанных в ДОПОГ условий',
          'Только жидкие или газообразные вещества, которые не допускаются к перевозке согласно ДОПОГ',
          'Только изделия, которые допускаются к перевозке с соблюдением предписанных в ДОПОГ условий',
        ],
        correctAnswers: [
          'Вещества и изделия, которые не допускаются к перевозке согласно ДОПОГ или допускаются к ней только с соблюдением предписанных в ДОПОГ условий',
        ],
      },
      {
        code: '63711009',
        text:
          'Как должны быть отрегулированы устройства ограничения скорости автотранспортных средств, перевозящих опасные грузы максимальной массой более 3,5 т?',
        answers: [
          'Так, чтобы при увеличении скорости до 90 км/ч раздавался звуковой сигнал',
          'Так, чтобы при увеличении скорости до 120 км/ч раздавался звуковой сигнал',
          'Так, чтобы скорость не могла превысить 90 км/ч',
          'Так, чтобы скорость не могла превысить 120 км/ч',
        ],
        correctAnswers: ['Так, чтобы скорость не могла превысить 90 км/ч'],
      },
      {
        code: '63711010',
        text:
          'С какой периодичностью аварийные сосуды под давлением должны подвергаться очистке, продувке и визуальной проверке их внешнего и внутреннего состояния?',
        answers: [
          'После реконструкции перед первым использованием',
          'Регулярно, один раз в месяц',
          'Не реже одного раза в пять лет',
          'После каждого использования',
        ],
        correctAnswers: ['После каждого использования'],
      },
      {
        code: '63711011',
        text:
          'Из какого материала допускается изготовление наконечников резиновых шлангов, предназначенных для сливоналивных операций, на объектах производств растительных масел? Укажите все правильные ответы.',
        answers: ['Из бронзы', 'Из меди', 'Из нержавеющей стали', 'Из титана'],
        correctAnswers: ['Из бронзы', 'Из меди'],
      },
      {
        code: '63711012',
        text:
          'К какой группе упаковки следует относить сильнотоксичные вещества?',
        answers: [
          'К группе упаковки I',
          'К группе упаковки II',
          'К группе упаковки III',
          'К группе упаковки II или III',
        ],
        correctAnswers: ['К группе упаковки I'],
      },
      {
        code: '63711013',
        text:
          'По каким показателям определяется степень опасности легковоспламеняющихся жидкостей?',
        answers: [
          'По плотности и объему',
          'По вязкости и текучести',
          'По температуре вспышки и температуре кипения',
          'По температуре горения и температуре плавления',
        ],
        correctAnswers: ['По температуре вспышки и температуре кипения'],
      },
      {
        code: '63711014',
        text:
          'Какой код присваивается всему опасному грузу, если в одной транспортной единице содержатся опасные грузы, которым назначены разные коды ограничения проезда через автодорожные туннели?',
        answers: [
          'Код опасного груза, имеющего в транспортной единице наибольший объем',
          'Код опасного груза, имеющего в транспортной единице наибольшую массу',
          'Наименее ограничительный из этих кодов',
          'Наиболее ограничительный из этих кодов',
        ],
        correctAnswers: ['Наиболее ограничительный из этих кодов'],
      },
      {
        code: '63711015',
        text:
          'Кем устанавливается порядок транзитного (кратковременного) хранения загруженных опасными веществами автотранспортных средств и контейнеров-цистерн?',
        answers: [
          'Организацией, эксплуатирующей ОПО',
          'Ростехнадзором',
          'Проектной организацией',
        ],
        correctAnswers: ['Организацией, эксплуатирующей ОПО'],
      },
      {
        code: '63711016',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О знании действий при пожаре',
          'О знании правил оказания первой помощи',
          'О наличии медицинских противопоказаний к работе',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63711017',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '6 месяцев со дня его окончания',
          '3 месяца со дня его окончания',
          '3 года со дня его окончания',
          '1 год со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63711018',
        text:
          'Что обозначает знак Организации Объединенных Наций на транспортном средстве?',
        answers: [
          'Данный вид опасного груза запрещен к перевозкам',
          'Данный вид опасного груза допущен к перевозкам',
          'Данный вид груза идентифицирован в Типовых правилах Организации Объединенных Наций',
          'Данный вид груза отнесен к особо опасным грузам',
        ],
        correctAnswers: [
          'Данный вид груза идентифицирован в Типовых правилах Организации Объединенных Наций',
        ],
      },
      {
        code: '63711019',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63711020',
        text:
          'Допускается ли использовать коммерческие названия в качестве технического названия груза?',
        answers: [
          'Допускается',
          'Допускается, если это название не используется в периодических изданиях',
          'Допускается в исключительных случаях',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63711021',
        text:
          'При какой интенсивной коррозии арматуры с ослаблением площади рекомендуется ограничивать движение автотранспортных средств с опасными веществами на автомобильной дороге?',
        answers: [
          'С ослаблением площади на 5% и более',
          'С ослаблением площади на 10% и более',
          'С ослаблением площади на 7% и более',
          'С ослаблением площади на 3% и более',
        ],
        correctAnswers: ['С ослаблением площади на 10% и более'],
      },
      {
        code: '63711022',
        text:
          'В каких контейнерах должны перевозиться навалом или насыпью коррозионные вещества?',
        answers: [
          'В закрытых пластиковых контейнерах',
          'В контейнерах с брезентовым покрытием',
          'В водонепроницаемых контейнерах для массовых грузов',
          'В неметаллических контейнерах для массовых грузов',
        ],
        correctAnswers: ['В водонепроницаемых контейнерах для массовых грузов'],
      },
      {
        code: '63711023',
        text:
          'Какие требования к транспортным средствам - цистернам для перевозки и заправки сжиженных углеводородных газов указаны верно?',
        answers: [
          'На обеих сторонах сосуда от шва переднего днища до шва заднего днища должны быть нанесены отличительные полосы красного цвета шириной 200 мм вниз от продольной оси сосуда',
          'Надпись "Опасно" на заднем днище сосуда и надпись черного цвета "Опасно - сжиженный газ" над отличительными полосами должны быть читаемы',
          'Наружная поверхность сосуда должна окрашиваться эмалью желтого цвета',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'На обеих сторонах сосуда от шва переднего днища до шва заднего днища должны быть нанесены отличительные полосы красного цвета шириной 200 мм вниз от продольной оси сосуда',
        ],
      },
      {
        code: '63711024',
        text:
          'При каком раскрытии многочисленных трещин автомобильной дороги рекомендуется ограничивать движение автотранспортных средств с опасными веществами?',
        answers: [
          'Более 0,3 мм',
          'Более 0,1 мм',
          'Более 0,2 мм',
          'В любом случае',
        ],
        correctAnswers: ['Более 0,3 мм'],
      },
      {
        code: '63711025',
        text:
          'Что в том числе требуется для получения специального разрешения, выдаваемого уполномоченным компетентным органом, для движения по автомобильным дорогам транспортного средства, осуществляющего перевозку опасных грузов?',
        answers: [
          'Согласование общего объема опасных грузов',
          'Согласование классов опасных грузов',
          'Согласование скорости движения транспортного средства',
          'Согласование маршрута транспортного средства',
        ],
        correctAnswers: ['Согласование маршрута транспортного средства'],
      },
      {
        code: '63711026',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63711027',
        text: 'Какие вещества относятся к классу инфекционных веществ?',
        answers: [
          'Радиоактивные отходы и лекарственные препараты',
          'Клинические отходы и биологические препараты',
          'Бытовые отходы и небиологические препараты',
          'Производственные отходы и противомикробные препараты',
        ],
        correctAnswers: ['Клинические отходы и биологические препараты'],
      },
      {
        code: '63711028',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии главного инженера и начальника службы охраны труда',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63711029',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63711030',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63711031',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63711032',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Воздушными изолирующими аппаратами',
          'Защитными очками и защитной каской',
          'Всеми перечисленными средствами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63711033',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63711034',
        text:
          'Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы различных категорий взрывоопасности технологических блоков взрывопожароопасных производств?',
        answers: [
          'Проектной документацией или документацией на техническое перевооружение',
          'Техническим регламентом',
          'Руководствами по эксплуатации',
          'Схемами',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63711035',
        text:
          'Могут ли взрывчатые изделия при перевозке снабжаться собственными средствами воспламенения или упаковываться вместе с ними?',
        answers: [
          'Могут без ограничительных условий',
          'Могут при условии, что срабатывание средств воспламенения при нормальных условиях перевозки исключено',
          'Могут при условии, что взрывчатые изделия относятся по степени опасности к подклассу 1.3 и ниже',
          'Не могут',
        ],
        correctAnswers: [
          'Могут при условии, что срабатывание средств воспламенения при нормальных условиях перевозки исключено',
        ],
      },
      {
        code: '63711036',
        text:
          'Что должно быть установлено по всему периметру цистерны на автоцистернах и прицепах (полуприцепах) - цистернах, на транспортных средствах для перевозки съемных цистерн и транспортных средствах - батареях?',
        answers: [
          'Боковые или задние защитные устройства',
          'Передние защитные устройства',
          'Проблесковые маячки',
          'Светоотражающая лента',
        ],
        correctAnswers: ['Боковые или задние защитные устройства'],
      },
      {
        code: '63711037',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63711038',
        text:
          'Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите 2 правильных варианта ответа.',
        answers: [
          'На наружных установках',
          'В помещении диспетчера предприятия',
          'На контрольно-пропускном пункте объекта',
          'На ограждении по периметру объекта через каждые 50 м',
        ],
        correctAnswers: [
          'На наружных установках',
          'В помещении диспетчера предприятия',
        ],
      },
      {
        code: '63711039',
        text: 'Какие взрывчатые вещества не допускаются к перевозке?',
        answers: [
          'Первичные взрывчатые вещества',
          'Вторичные взрывчатые вещества с собственными средствами инициирования',
          'Вещества, обладающие чрезмерной чувствительностью или способные к самопроизвольной реакции',
          'Вещества, представляющие особую опасность в связи с их водоактивностью',
        ],
        correctAnswers: [
          'Вещества, обладающие чрезмерной чувствительностью или способные к самопроизвольной реакции',
        ],
      },
      {
        code: '63711040',
        text:
          'Каким образом допускается налив гипохлорита натрия в транспортные емкости?',
        answers: [
          'Методом передавливания сжатым воздухом (азотом)',
          'При помощи насосов, предназначенных для перекачки гипохлорита натрия',
          'Самотеком из напорных емкостей',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63711041',
        text:
          'К перевозке каких из перечисленных газов могут быть применимы положения ДОПОГ?',
        answers: [
          'Газов, содержащихся в топливных баках перевозимых транспортных средств',
          'Газов, содержащихся в оборудовании, используемом для эксплуатации транспортных средств',
          'Газов, содержащихся в электрических лампочках (при соблюдении требований упаковки)',
          'Газов, являющихся сильнотоксичными или пирофорными веществами',
        ],
        correctAnswers: [
          'Газов, являющихся сильнотоксичными или пирофорными веществами',
        ],
      },
      {
        code: '63711042',
        text:
          'Каким должно быть минимальное остаточное избыточное давление в транспортировочных емкостях аммиака при их полном опорожнении?',
        answers: ['0,05 МПа', '0,02 МПа', '0,01 МПа', '0,03 МПа'],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63711043',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['30 минут', '15 минут', '10 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63711044',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63711045',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'В технологических блоках всех категорий взрывоопасности',
          'Только в технологических блоках II категории взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
          'Только в технологических блоках I категории взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63711046',
        text:
          'Каким документом устанавливаются места расположения запорных и (или) отсекающих устройств?',
        answers: [
          'Проектной документацией или документацией на техническое перевооружение',
          'Проектом производства работ',
          'Планом ликвидации аварий',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63711047',
        text: 'Какие опасные грузы относятся к 8 классу опасности?',
        answers: [
          'Едкие и коррозионные вещества',
          'Самовозгорающиеся вещества',
          'Токсичные вещества',
          'Окисляющие вещества',
        ],
        correctAnswers: ['Едкие и коррозионные вещества'],
      },
      {
        code: '63711048',
        text:
          'Каким должно быть сопротивление заземляющего устройства автоцистерны, прицепа (полуприцепа) - цистерны для перевозки нефтепродуктов совместно с контуром заземления?',
        answers: [
          'Не более 100 Ом',
          'Не более 150 Ом',
          'Не более 200 Ом',
          'Не более 50 Ом',
        ],
        correctAnswers: ['Не более 100 Ом'],
      },
      {
        code: '63711049',
        text: 'На сколько допускается уменьшать размер знака опасности?',
        answers: [
          'До 25 мм',
          'До 30 мм',
          'До 35 мм',
          'Размер знака опасности не допускается уменьшать',
        ],
        correctAnswers: ['До 25 мм'],
      },
      {
        code: '63711050',
        text:
          'В течение какого минимального срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?',
        answers: [
          'Не регламентируется',
          'Не менее одних суток',
          'Не менее двух суток',
          'Не менее трех суток',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63711051',
        text: 'Что относится к технологическим трубопроводам?',
        answers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы, предназначенные для транспортирования различных веществ, необходимых для ведения технологического процесса или эксплуатации оборудования',
        ],
        correctAnswers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
      },
      {
        code: '63711052',
        text:
          'Допускается ли перевозка веществ, способных вступать в опасную реакцию друг с другом, в смежных секциях металлических цистерн?',
        answers: [
          'Допускается без ограничений',
          'Допускается, если возможная опасная реакция не может сопровождаться взрывом, горением или разрушением стенок цистерны',
          'Допускается, если толщина перегородки между секциями равна толщине стенок цистерны или превышает ее',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если толщина перегородки между секциями равна толщине стенок цистерны или превышает ее',
        ],
      },
      {
        code: '63711053',
        text:
          'В зависимости от какого параметра назначаются группы упаковки веществам в соответствии с принципами классификации опасных грузов ДОПОГ?',
        answers: [
          'В зависимости от степени опасности веществ',
          'В зависимости от объема вмещаемых веществ',
          'В зависимости от агрегатного состояния веществ',
          'В зависимости от дальности перевозки веществ',
        ],
        correctAnswers: ['В зависимости от степени опасности веществ'],
      },
      {
        code: '63711054',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Наличие природных оврагов, выемок, низин',
          'Устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63711055',
        text:
          'Каким из перечисленных средств не должен оснащаться автомобильный транспорт, осуществляющий перевозку жидкого хлора в баллонах и контейнерах?',
        answers: [
          'Герметизирующими колпаками на арматуры контейнера и баллона',
          'Переносным или индивидуальным индикатором утечки хлора',
          'Двумя переносными распыливающими устройствами специальной конструкции',
          'Мобильными средствами оперативной связи',
        ],
        correctAnswers: [
          'Герметизирующими колпаками на арматуры контейнера и баллона',
        ],
      },
      {
        code: '63711056',
        text:
          'Какую маркировку не должна содержать упаковка и (или) транспортный пакет при транспортировке автомобильным транспортом?',
        answers: [
          'Номер аварийной карточки',
          'Знак опасности',
          'Транспортное наименование груза',
          'Номер ООН',
          'Классификационный шифр',
        ],
        correctAnswers: ['Номер аварийной карточки'],
      },
      {
        code: '63711057',
        text: 'Для каких веществ назначается группа упаковки III?',
        answers: [
          'Для веществ с высокой степенью опасности',
          'Для веществ со средней степенью опасности',
          'Для веществ с низкой степенью опасности',
          'Для веществ со сверхнизкой степенью опасности',
        ],
        correctAnswers: ['Для веществ с низкой степенью опасности'],
      },
      {
        code: '63711058',
        text:
          'Кто проводит регистрацию изотермического резервуара в установленном порядке?',
        answers: [
          'Организация, эксплуатирующая изотермический резервуар',
          'Организация, осуществляющих экспертизу промышленной безопасности',
          'Проектная организация',
          'Регистрация изотермического резервуара не требуется',
        ],
        correctAnswers: [
          'Организация, эксплуатирующая изотермический резервуар',
        ],
      },
      {
        code: '63711059',
        text:
          'Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору',
          'Руководителями муниципальных образований, на территории которых расположен объект',
          'Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63711060',
        text:
          'Чем должны быть оснащены транспортные средства, используемые для перевозки опасных грузов?',
        answers: [
          'Автоматическим компьютерным управлением двигателем',
          'Средствами видеорегистрации',
          'Средствами звукового и речевого оповещения населения',
          'Аппаратурой спутниковой навигации',
        ],
        correctAnswers: ['Аппаратурой спутниковой навигации'],
      },
      {
        code: '63711061',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: [
          'Определяются руководством объекта',
          '3 года',
          '5 лет',
          '2 года',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63711062',
        text:
          'Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
          'Только с тканевым уплотнением в затворе',
          'Только с резиновым уплотнением в затворе',
          'Только с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63711063',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами организации',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63711064',
        text:
          'Какие требования предъявляются Техническим регламентом Таможенного союза "О безопасности колесных транспортных средств" к установке на транспортном средстве для перевозки опасных грузов дополнительных топливных баков, не предусмотренных изготовителем транспортного средства?',
        answers: [
          'Установка запрещается',
          'Установка допускается на специализированной станции технического обслуживания',
          'Установка допускается только по согласованию с центром технической экспертизы',
          'Допускается установка дополнительных топливных баков емкостью не более 50% от объема основного бака',
        ],
        correctAnswers: ['Установка запрещается'],
      },
      {
        code: '63711065',
        text:
          'В течение какого времени после внесения изменений в системы управления технологическими процессами на объекте должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Не позднее полугода',
          'Не позднее 30 календарных дней',
          'Не позднее одного года',
          'Не позднее трех месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63711066',
        text:
          'Какое максимальное номинальное напряжение электрооборудования может быть у транспортного средства для перевозки опасных грузов?',
        answers: ['24 В', '12 В', '36 В', '6 В', '48 В'],
        correctAnswers: ['24 В'],
      },
      {
        code: '63711067',
        text: 'Какие опасные грузы относятся к 4 классу опасности?',
        answers: [
          'Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой',
          'Окисляющие вещества',
          'Газы сжатые, сжиженные и растворенные под давлением',
          'Взрывчатые материалы',
        ],
        correctAnswers: [
          'Легковоспламеняющиеся твердые вещества, самовозгорающиеся вещества, вещества, выделяющие воспламеняющиеся газы при взаимодействии с водой',
        ],
      },
      {
        code: '63711068',
        text:
          'Что из перечисленного не допускается при наполнении контейнеров хлором?',
        answers: [
          'Отвод абгазов при наливе хлора',
          'Расположение вентилей друг над другом при наполнении контейнеров',
          'Наполнение контейнеров в горизонтальном положении',
          'Сброс абгазов до остаточного давления, соответствующего равновесному давлению насыщенных паров при температуре налива жидкого хлора, после окончания налива',
        ],
        correctAnswers: ['Отвод абгазов при наливе хлора'],
      },
      {
        code: '63711069',
        text: 'Какие из перечисленных веществ допускаются к перевозке?',
        answers: [
          'Серная кислота, регенерированная из кислого гудрона',
          'Неденитрованные смеси остаточных серной и азотной кислот',
          'Химически неустойчивые нитрующие кислотные смеси',
          'Химически неустойчивые смеси отработанной серной кислоты',
        ],
        correctAnswers: ['Серная кислота, регенерированная из кислого гудрона'],
      },
      {
        code: '63711070',
        text:
          'При наличии необрезанной растительности, затрудняющей видимость дорожных знаков и направляющих устройств, на каком расстоянии принимаются меры по временному ограничению движения по автомобильным дорогам?',
        answers: [
          'На расстоянии 100 м',
          'На расстоянии 200 м',
          'На расстоянии 250 м',
          'На расстоянии 150 м',
        ],
        correctAnswers: ['На расстоянии 100 м'],
      },
      {
        code: '63711071',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63711072',
        text:
          'При каком условии допускается выполнять производство погрузочно-разгрузочных работ на ОПО?',
        answers: [
          'В темное время суток при искусственном освещении',
          'Во время грозы',
          'При условии курения на расстоянии 30 м от мест производства погрузки или выгрузки опасных грузов',
          'Во время использования открытого огня в местах производства погрузки-выгрузки опасных веществ при условии соблюдения дополнительных мер безопасности',
        ],
        correctAnswers: ['В темное время суток при искусственном освещении'],
      },
      {
        code: '63711073',
        text:
          'Какая устанавливается максимальная норма налива жидкого хлора в емкость (резервуар, танк, сборник, вагон-цистерна, контейнер-цистерна)?',
        answers: ['1,25 кг/дм³', '2,15 кг/дм³', '1,75 кг/дм³'],
        correctAnswers: ['1,25 кг/дм³'],
      },
      {
        code: '63711074',
        text:
          'В каком случае в нормативных документах на продукцию указывают температуру вспышки?',
        answers: [
          'В случае если температура вспышки не более 61 °C',
          'В случае если температура вспышки не более 65°C',
          'В случае если температура вспышки не более 70 °C',
          'В случае если температура вспышки не более 75 °C',
        ],
        correctAnswers: ['В случае если температура вспышки не более 61 °C'],
      },
      {
        code: '63711075',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых согласно Плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
          'Требования к обозначению определяются при разработке плана мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63711076',
        text:
          'Какого показателя категорий взрывоопасности технологических блоков не существует?',
        answers: ['I', 'II', 'III', 'IV'],
        correctAnswers: ['IV'],
      },
      {
        code: '63711077',
        text: 'Какие грузы относятся к 2 классу опасности?',
        answers: [
          'Газы',
          'Ядовитые вещества',
          'Легковоспламеняющиеся жидкости',
          'Инфекционные вещества',
        ],
        correctAnswers: ['Газы'],
      },
      {
        code: '63711078',
        text:
          'Какие вещества, согласно положениям о классе 1 (взрывчатые вещества и изделия), относятся к взрывчатым веществам?',
        answers: [
          'Вещества, которые сами по себе не являются взрывчатыми, но могут образовывать взрывчатую смесь в виде тепла, света, газа или дыма в результате самоподдерживающихся экзотермических химических реакций, протекающих без детонации',
          'Только твердые вещества, которые способны к химической реакции с ощутимым выделением газов при любой температуре, давлении и скорости реакции, что вызывает разрушение окружающих предметов',
          'Только жидкие вещества (или смеси веществ), которые способны к химической реакции с ощутимым выделением тепла при любом давлении и скорости реакции, что вызывает возгорание окружающих предметов',
          'Твердые или жидкие вещества, которые способны к химической реакции с выделением газов при такой температуре, таком давлении и с такой скоростью, что это вызывает повреждение окружающих предметов',
        ],
        correctAnswers: [
          'Твердые или жидкие вещества, которые способны к химической реакции с выделением газов при такой температуре, таком давлении и с такой скоростью, что это вызывает повреждение окружающих предметов',
        ],
      },
      {
        code: '63711079',
        text:
          'На каком основании разрешается совместная погрузка упаковки с различными знаками опасности в одно и то же транспортное средство или контейнер?',
        answers: [
          'На основании распоряжения лица, ответственного за погрузку',
          'На основании разрешения руководителя транспортной компании, осуществляющей перевозку',
          'На основании классификации опасных грузов ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"',
          'На основании таблицы совместимости знаков опасности ДОПОГ',
        ],
        correctAnswers: [
          'На основании таблицы совместимости знаков опасности ДОПОГ',
        ],
      },
      {
        code: '63711080',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63711081',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом исключающим возможность искрообразования',
          'Только с разрешения представителя газоспасательной службы, и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63711082',
        text:
          'В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке',
          'На каждый объект должен быть разработан свой план в любом случае',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63711083',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63711084',
        text:
          'Какие действия должны незамедлительно предпринять участники перевозки опасных грузов, если в процессе перевозки создается прямая угроза для общественной безопасности?',
        answers: [
          'Уведомить об этом свое непосредственное руководство и принять меры по устранению возникшей угрозы',
          'Уведомить об этом свое непосредственное руководство и покинуть место возникновения угрозы',
          'Уведомить об этом аварийно-спасательные службы и предоставить информацию, необходимую для принятия соответствующих мер',
          'Уведомить об этом население и подготовить его к эвакуации',
        ],
        correctAnswers: [
          'Уведомить об этом аварийно-спасательные службы и предоставить информацию, необходимую для принятия соответствующих мер',
        ],
      },
      {
        code: '63711085',
        text: 'Какие аэрозоли не допускаются к перевозке?',
        answers: [
          'Аэрозоли, в которых в качестве газов-вытеснителей используются токсичные или пирофорные газы',
          'Аэрозоли, содержимое которых не удовлетворяет критериям группы упаковки I в отношении токсичности',
          'Аэрозоли, содержимое которых не удовлетворяет критериям группы упаковки II в отношении коррозионности',
          'Любые аэрозоли',
        ],
        correctAnswers: [
          'Аэрозоли, в которых в качестве газов-вытеснителей используются токсичные или пирофорные газы',
        ],
      },
      {
        code: '63711086',
        text: 'Какие опасные грузы относятся к 5 классу опасности?',
        answers: [
          'Окисляющие вещества и органические пероксиды',
          'Твердые легковоспламеняющиеся вещества',
          'Самовозгорающиеся вещества',
          'Жидкие легковоспламеняющиеся вещества',
        ],
        correctAnswers: ['Окисляющие вещества и органические пероксиды'],
      },
      {
        code: '63711087',
        text:
          'Кем осуществляется выдача специального разрешения для движения по автомобильным дорогам федерального значения транспортного средства, осуществляющего перевозку опасных грузов?',
        answers: [
          'Федеральным органом исполнительной власти, осуществляющим функции по оказанию государственных услуг и управлению государственным имуществом в сфере дорожного хозяйства',
          'Уполномоченным федеральным органом исполнительной власти',
          'Органами исполнительной власти субъектов Российской Федерации',
          'Органами местного самоуправления муниципальных районов',
        ],
        correctAnswers: [
          'Уполномоченным федеральным органом исполнительной власти',
        ],
      },
      {
        code: '63711088',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Не моложе 18 лет',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
          'Прошедшие обучение приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63711089',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из частных разделов',
          'Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности',
          'Из общих и специальных разделов',
          'Из специальных разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63711090',
        text:
          'Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?',
        answers: [
          'Двумя штуцерами с вентилем для съемного манометра',
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
          'Тремя предохранительными клапанами',
          'Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива',
        ],
        correctAnswers: [
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
        ],
      },
      {
        code: '63711091',
        text:
          'Что должно проводиться для подтверждения соответствия Ех-оборудования стандартам на определенный вид взрывозащиты?',
        answers: [
          'Испытания промышленных образцов оборудования на взрывозащищенность',
          'Получение заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Опытные работы, проводимые разработчиком данного оборудования, и экспертиза промышленной безопасности',
          'Оценка научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
        ],
        correctAnswers: [
          'Испытания промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63711092',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'C руководителями службы производственного контроля',
          'C руководителями аварийно-спасательных служб',
          'C руководителями службы охраны труда и санитарными службами',
        ],
        correctAnswers: [
          'C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63711093',
        text:
          'Каким должно быть давление сжатого воздуха (азота) при передавливании жидкого хлора газообразным хлором?',
        answers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 2,0 МПа',
          'Давление сжатого воздуха (азота) должно быть не менее чем на 0,05 МПа выше давления в сосуде, в который передавливается хлор',
          'Давление сжатого воздуха (азота) не должно превышать 1,5 МПа',
        ],
        correctAnswers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
        ],
      },
      {
        code: '63711094',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Руководитель эксплуатирующей организации',
          'Руководитель огневых работ',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63711095',
        text:
          'Что является критерием взрывоопасности согласно Федеральным нормам и правилам "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63711096',
        text:
          'В каком случае допускается совмещение маркировки, характеризующей вид и степень опасности, с транспортной маркировкой и маркировкой, характеризующей груз, на одном ярлыке?',
        answers: [
          'Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков',
          'Допускается только для класса опасности 1',
          'Допускается только при согласовании с Минтрансом России',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается, размер ярлыка должен быть увеличен на величину, кратную количеству знаков',
        ],
      },
      {
        code: '63711097',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63711098',
        text:
          'Какие меры необходимо принять для предотвращения опасного разложения или полимеризации химически неустойчивых токсичных веществ во время перевозки?',
        answers: [
          'Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции',
          'Обеспечить защищенность сосудов и цистерн от ударов',
          'Обеспечить устойчивое положение сосудов и цистерн',
          'Обеспечить, чтобы сосуды и цистерны были сделаны из химически неактивных материалов',
        ],
        correctAnswers: [
          'Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции',
        ],
      },
      {
        code: '63711099',
        text:
          'Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?',
        answers: [
          'Стальная арматура и фасонные части',
          'Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди, цинка и их сплавов',
          'Стальная арматура и фасонные части, а также допускается применение чугунной запорно-регулирующей арматуры',
          'Стальная арматура и фасонные части, а также допускается применение запорной арматуры из ковкого и высокопрочного чугуна',
        ],
        correctAnswers: ['Стальная арматура и фасонные части'],
      },
      {
        code: '63711100',
        text:
          'В каком случае допускается перевозка химически неустойчивых легковоспламеняющихся жидкостей?',
        answers: [
          'В случае если приняты необходимые меры для предотвращения их опасного разложения или полимеризации во время перевозки',
          'В случае если приняты необходимые меры для предотвращения их возгорания во время погрузки',
          'В случае если приняты необходимые меры для предотвращения их контакта с воздухом во время погрузки и перевозки',
          'В случае если приняты необходимые меры для предотвращения их встряхивания и взбалтывания во время перевозки',
        ],
        correctAnswers: [
          'В случае если приняты необходимые меры для предотвращения их опасного разложения или полимеризации во время перевозки',
        ],
      },
      {
        code: '63711101',
        text:
          'Допускается ли перевозка пищевых продуктов в металлических цистернах, использовавшихся для перевозки опасных веществ?',
        answers: [
          'Допускается только после перевозки грузов 1 - 3 классов',
          'Допускается, только если приняты необходимые меры для предотвращения нанесения какого-либо вреда здоровью людей',
          'Допускается, только если исключена возможность вступления остатков опасных веществ в опасные реакции с пищевыми продуктами',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, только если приняты необходимые меры для предотвращения нанесения какого-либо вреда здоровью людей',
        ],
      },
      {
        code: '63711102',
        text:
          'Что не допускается по отношению к кондиционерам и холодильному оборудованию, применяемым на транспортных средствах?',
        answers: [
          'Наличие в их составе только озоноразрушающих материалов, перечень которых приводится в Киотском протоколе',
          'Наличие в их составе вредных веществ',
          'Наличие в их составе озоноразрушающих веществ и материалов, перечень которых утвержден Комиссией Таможенного союза',
          'Наличие в их составе в качестве хладагента аммиака',
        ],
        correctAnswers: [
          'Наличие в их составе озоноразрушающих веществ и материалов, перечень которых утвержден Комиссией Таможенного союза',
        ],
      },
      {
        code: '63711103',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63711104',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление дистанционно (из безопасного места)',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Должно быть организовано управление по месту',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63711105',
        text:
          'Допускается ли вскрытие водителем и помощником водителя упаковки с опасным грузом?',
        answers: [
          'Допускается',
          'Допускается в случае аварийной ситуации',
          'Допускается в случае, если необходимо предъявление целостности груза',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63711106',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Порядок действий в случае аварий на объекте',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63711107',
        text:
          'Что должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов, исполнение по взрывозащите',
          'Показатели надежности, конструктивные особенности и параметры работы',
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
          'Показатели надежности и конструктивные особенности',
        ],
        correctAnswers: [
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
        ],
      },
      {
        code: '63711108',
        text:
          'Какие сведения должны быть нанесены на каждую упаковку в виде разборчивой и долговечной маркировки, если в ДОПОГ не предусмотрено иное?',
        answers: [
          'Точное наименование опасного груза в соответствии с накладной',
          'Номер Организации Объединенных Наций, соответствующий содержащимся в упаковке опасным грузам',
          'Класс опасности, соответствующий содержащимся в упаковке опасным грузам',
          'Дата упаковки опасного груза',
        ],
        correctAnswers: [
          'Номер Организации Объединенных Наций, соответствующий содержащимся в упаковке опасным грузам',
        ],
      },
      {
        code: '63711109',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'Ко II группе',
          'К группе работ высокой степени опасности',
          'К группе работ средней степени опасности',
        ],
        correctAnswers: ['Ко II группе'],
      },
      {
        code: '63711110',
        text: 'Какие взрывчатые изделия не допускаются к перевозке?',
        answers: [
          'Изделия, по степени опасности относящиеся к подклассу 1.6',
          'Изделия, содержащие как взрывчатое вещество, так и токсичный химический агент',
          'Изделия, по степени опасности относящиеся к подклассу 1.1',
        ],
        correctAnswers: [
          'Изделия, содержащие как взрывчатое вещество, так и токсичный химический агент',
        ],
      },
      {
        code: '63711111',
        text:
          'Что из перечисленного не входит в комплект "В" для ликвидации утечек хлора из железнодорожной (автомобильной) цистерны, танка, контейнер-цистерны?',
        answers: [
          'Герметизирующий колпак на арматуру цистерны',
          'Герметизирующие устройства для ликвидации утечек хлора из предохранительного клапана цистерны',
          'Быстромонтируемое устройство для ликвидации утечек хлора из корпуса цистерны, танка',
          'Набор свинцовых конусных пробок для заделки отверстий до 50 мм',
        ],
        correctAnswers: [
          'Набор свинцовых конусных пробок для заделки отверстий до 50 мм',
        ],
      },
      {
        code: '63711112',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['35 °С', '20 °С', '25 °С', '30 °С'],
        correctAnswers: ['30 °С'],
      },
      {
        code: '63711113',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Установить систему подачи условных сигналов',
          'Увеличить число наблюдающих и работающих',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63711114',
        text:
          'Сколько в процентном отношении должна составлять температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?',
        answers: [
          'Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63711115',
        text:
          'В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?',
        answers: [
          'Наблюдение за цистернами с жидким аммиаком не требуется',
          'В течение периода приемки цистерны',
          'В течение слива аммиака',
          'В течение всего времени нахождения',
        ],
        correctAnswers: ['В течение всего времени нахождения'],
      },
      {
        code: '63711116',
        text:
          'Каким способом допускается проводить передавливание жидкого хлора?',
        answers: [
          'Нагнетанием в опорожняемую емкость сухого сжатого воздуха (азота) или паров газообразного хлора из другого сосуда или термокомпрессора',
          'За счет собственного давления паров хлора в опорожняемом сосуде и отбора хлоргаза из наполняемого сосуда',
          'Комбинированным способом',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63711117',
        text:
          'Какие из перечисленных вагонов-цистерн, контейнеров-цистерн, контейнеров (бочек) и баллонов допускается использовать для транспортировки жидкого хлора?',
        answers: [
          'На которых демонтированы сифонные трубки из баллонов',
          'Которые исправны, предназначены для транспортировки любых продуктов',
          'На которых истек срок ревизии предохранительного клапана и мембраны для вагонов-цистерн и контейнеров-цистерн',
          'На которых имеются механические повреждения',
        ],
        correctAnswers: [
          'На которых демонтированы сифонные трубки из баллонов',
        ],
      },
      {
        code: '63711118',
        text:
          'В каком случае переносные цистерны могут быть предъявлены к перевозке?',
        answers: [
          'В случае если наружная поверхность корпуса или сервисное оборудование загрязнены ранее перевозимыми веществами',
          'В случае если повреждения таковы, что это не скажется на целостности цистерны и ее крепежных приспособлений',
          'В случае если повреждения таковы, что это может сказаться на целостности цистерны и ее подъемных приспособлений',
          'В случае если сервисное оборудование не проверено',
        ],
        correctAnswers: [
          'В случае если повреждения таковы, что это не скажется на целостности цистерны и ее крепежных приспособлений',
        ],
      },
      {
        code: '63711119',
        text: 'Какие вещества называются окисляющими?',
        answers: [
          'Вещества, которые могут подвергать коррозии любые другие вещества при соприкосновении',
          'Горючие вещества, которые могут вызывать горение других веществ и материалов при участии катализатора',
          'Вещества, которые, сами по себе необязательно вступая в реакцию, могут создавать сильную коррозионную среду вне зависимости от присутствия воздуха',
          'Вещества, которые, сами по себе необязательно являясь горючими, могут вызывать или поддерживать горение других материалов',
        ],
        correctAnswers: [
          'Вещества, которые, сами по себе необязательно являясь горючими, могут вызывать или поддерживать горение других материалов',
        ],
      },
      {
        code: '63711120',
        text:
          'Кто осуществляет окраску вновь изготовленной транспортной тары для жидкого хлора и нанесение на ней надписей?',
        answers: [
          'Организация-изготовитель',
          'Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара',
          'Экспертная организация',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63711121',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63711122',
        text:
          'Какие меры необходимо принять для предотвращения опасного разложения или полимеризации химически неустойчивых веществ класса 4.1 во время перевозки?',
        answers: [
          'Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции',
          'Обеспечить, чтобы сосуды и цистерны были сделаны из химически неактивных материалов',
          'Обеспечить устойчивое положение сосудов и цистерн',
          'Обеспечить защищенность сосудов и цистерн от ударов',
        ],
        correctAnswers: [
          'Обеспечить, чтобы в сосудах и цистернах не содержалось веществ, способных активировать эти реакции',
        ],
      },
      {
        code: '63711123',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63711124',
        text: 'Какие опасные грузы относятся к 7 классу опасности?',
        answers: [
          'Радиоактивные материалы',
          'Твердые легковоспламеняющиеся вещества',
          'Взрывчатые вещества',
          'Едкие и коррозионные вещества',
        ],
        correctAnswers: ['Радиоактивные материалы'],
      },
      {
        code: '63711125',
        text:
          'К каким из перечисленных транспортных операций с опасными грузами могут быть применимы положения ДОПОГ?',
        answers: [
          'К перевозкам, осуществляемым автомобилями техпомощи, перевозящими потерпевшие аварию транспортные средства, содержащие опасные грузы',
          'К перевозкам опасных грузов частными лицами, если эти грузы упакованы для розничной продажи при условии, что приняты меры для предотвращения утечки содержимого',
          'К перевозкам с целью локализации и сбора опасных грузов в случае инцидента или аварии и перемещения их в ближайшее безопасное место',
          'К перевозкам опасных грузов с целью их захоронения, обезвреживания и утилизации',
        ],
        correctAnswers: [
          'К перевозкам опасных грузов с целью их захоронения, обезвреживания и утилизации',
        ],
      },
      {
        code: '63711126',
        text:
          'В каком случае допускается перевозка сосудов с истекшим сроком технического освидетельствования, заполненных хлором?',
        answers: [
          'Не допускается ни в каком случае',
          'Если срок технического освидетельствования истек не более 3 месяцев назад',
          'В случае согласования с территориальным органом Ростехнадзора',
          'При заполнении хлором ниже установленного предела',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63711127',
        text:
          'Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?',
        answers: [
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
          'На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом',
          'Применение рычажно-грузовых предохранительных клапанов не допускается',
          'Параллельно с рабочими предохранительными клапанами необходимо установить резервные предохранительные клапаны',
        ],
        correctAnswers: [
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
        ],
      },
      {
        code: '63711128',
        text:
          'В каком случае перевозка опасного груза считается достаточно безопасной?',
        answers: [
          'Если на борту транспортного средства имеется все необходимое для очистки транспортного средства или контейнера от вытекших, пролитых или рассыпанных опасных грузов',
          'Если приняты надлежащие меры для предотвращения неконтролируемого выхода наружу вытекших, пролитых или рассыпанных опасных грузов',
          'Если в плане локализации и ликвидации аварий четко прописаны и доведены до экипажа его действия в случае неконтролируемого выхода наружу вытекших, пролитых или рассыпанных опасных грузов',
          'Если неконтролируемый выход наружу вытекших, пролитых или рассыпанных опасных грузов не может нанести существенный ущерб окружающей среде и населению',
        ],
        correctAnswers: [
          'Если приняты надлежащие меры для предотвращения неконтролируемого выхода наружу вытекших, пролитых или рассыпанных опасных грузов',
        ],
      },
      {
        code: '63711129',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63711130',
        text:
          'Чем должны быть укомплектованы баллоны? Укажите все правильные ответы.',
        answers: [
          'Баллоны вместимостью более 50 л должны иметь предохранительные клапаны',
          'Вентилями, плотно ввернутыми в отверстия горловины',
          'Вентилями, плотно ввернутыми в расходно-наполнительные штуцера у специальных баллонов, не имеющих горловины',
        ],
        correctAnswers: [
          'Вентилями, плотно ввернутыми в отверстия горловины',
          'Вентилями, плотно ввернутыми в расходно-наполнительные штуцера у специальных баллонов, не имеющих горловины',
        ],
      },
      {
        code: '63711131',
        text:
          'На сколько должно быть произведено заполнение всех видов тары гипохлоритом натрия (калия)?',
        answers: [
          'Не более чем на 90% объема тары',
          'Не более чем на 95% объема тары',
          'Не более чем на 80% объема тары',
          'Не более чем на 85% объема тары',
        ],
        correctAnswers: ['Не более чем на 90% объема тары'],
      },
      {
        code: '63711132',
        text: 'Что называется "транспортным средством EX/II"?',
        answers: [
          'Транспортное средство, предназначенное для перевозки взрывчатых веществ и изделий (класс 1)',
          'Транспортное средство, предназначенное для перевозки токсичных веществ (класс 6.1)',
          'Транспортное средство, предназначенное для перевозки инфекционных веществ (класс 6.2)',
          'Транспортное средство, предназначенное для перевозки прочих опасных веществ и изделий (класс 9)',
        ],
        correctAnswers: [
          'Транспортное средство, предназначенное для перевозки взрывчатых веществ и изделий (класс 1)',
        ],
      },
      {
        code: '63711133',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При обосновании в проектной документации (документации на техническое перевооружение) при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
        correctAnswers: [
          'При обосновании в проектной документации (документации на техническое перевооружение) при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63711134',
        text:
          'Какую информацию включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Только характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Только характеристику объекта, в отношении которого разрабатывается план мероприятий',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63711135',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда допуска',
          'Не менее 1 года со дня закрытия наряда допуска',
          'Не менее 6 месяцев со дня закрытия наряда допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда допуска'],
      },
      {
        code: '63711136',
        text:
          'Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?',
        answers: [
          'При температуре плавления жидкого хлора',
          'При температуре окружающей среды',
          'В захоложенном состоянии при температуре ниже температуры окружающей среды',
          'При температуре кипения жидкого хлора при атмосферном давлении (изотермический способ хранения)',
        ],
        correctAnswers: ['При температуре плавления жидкого хлора'],
      },
      {
        code: '63711137',
        text:
          'В соответствии с каким документом должны выполняться требования к конструкции и оборудованию транспортного средства, перевозящего опасные грузы?',
        answers: [
          'С ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"',
          'С Правилами перевозок грузов автомобильным транспортом',
          'С Европейским соглашением о международной дорожной перевозке опасных грузов (ДОПОГ)',
          'С техническим регламентом Таможенного союза "О безопасности колесных транспортных средств"',
        ],
        correctAnswers: [
          'С Европейским соглашением о международной дорожной перевозке опасных грузов (ДОПОГ)',
        ],
      },
      {
        code: '63711138',
        text:
          'Какие требования к транспортным средствам-цистернам для перевозки и заправки нефтепродуктов указаны верно?',
        answers: [
          'На цистерне должны размещаться два знака "Опасность", знак "Ограничение скорости", мигающий фонарь красного цвета или знак аварийной остановки, кошма, емкость для песка массой не менее 25 кг',
          'Сопротивление электрической цепи, образуемой электропроводящим покрытием между переходником и замком рукава, должно быть не более 10 Ом; на цистернах, снабженных антистатическими рукавами, сопротивление обозначенной цепи должно быть не более указанного в эксплуатационной документации; сопротивление отдельных участков цепи должно быть не более 100 Ом',
          'Сопротивление каждого из звеньев электрических цепей "рама шасси - штырь", "цистерна - рама шасси", "рама шасси - контакты вилки провода заземления" не должно превышать 50 Ом',
          'Надпись "Огнеопасно" на боковых сторонах и заднем днище сосуда должна быть читаема. Надписи выполняются на французском языке',
        ],
        correctAnswers: [
          'На цистерне должны размещаться два знака "Опасность", знак "Ограничение скорости", мигающий фонарь красного цвета или знак аварийной остановки, кошма, емкость для песка массой не менее 25 кг',
        ],
      },
      {
        code: '63711139',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление дистанционно',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Определяется разработчиком проекта',
          'Должно быть организовано управление по месту',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63711140',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'По месту',
          'Дистанционно (из безопасного места)',
          'По месту и дистанционно (из безопасного места)',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: ['По месту и дистанционно (из безопасного места)'],
      },
      {
        code: '63711141',
        text:
          'Какие меры должны быть предприняты, если контейнер средней грузоподъемности для массовых грузов используется для перевозки жидкостей с температурой вспышки 60 ºС (закрытый сосуд) или ниже либо для перевозки порошков, пыль которых является взрывоопасной?',
        answers: [
          'Меры по ограничению скорости транспортного средства до 90 км/ч',
          'Меры для предотвращения опасного электрического разряда',
          'Меры по недопущению тряски контейнера во время перевозки',
          'Меры по недопущению их перевозки вместе с другими грузами',
        ],
        correctAnswers: [
          'Меры для предотвращения опасного электрического разряда',
        ],
      },
      {
        code: '63711142',
        text:
          'В каком случае в нормативных документах на продукцию указывают температуру разложения?',
        answers: [
          'В случае, если температура разложения продукции не более 50 °C',
          'В случае, если температура разложения продукции не более 40 °C',
          'В случае, если температура разложения продукции не более 10 °C',
        ],
        correctAnswers: [
          'В случае, если температура разложения продукции не более 50 °C',
        ],
      },
      {
        code: '63711143',
        text:
          'Какими средствами комплектуется транспортное средство для перевозки опасных грузов?',
        answers: [
          'Всеми перечисленными',
          'Не менее чем двумя противооткатными упорами на каждое транспортное средство (звено автопоезда), размеры которых соответствуют диаметру колес',
          'Двумя знаками аварийной остановки; средствами нейтрализации перевозимых опасных грузов',
          'Набором ручного инструмента для аварийного ремонта транспортного средства; одеждой яркого цвета для каждого члена экипажа',
          'Двумя фонарями автономного питания с мигающими или постоянными огнями оранжевого цвета; карманными фонарями для каждого члена экипажа',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63711144',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63711145',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Начальнику цеха',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63711146',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63711147',
        text:
          'Какие вещества обозначаются знаком опасности в виде черного или белого пламени на синем фоне?',
        answers: [
          'Легковоспламеняющиеся жидкости',
          'Вещества, способные к самовозгоранию',
          'Легковоспламеняющиеся твердые вещества',
          'Вещества, выделяющие легковоспламеняющиеся газы при соприкосновении с водой',
        ],
        correctAnswers: [
          'Вещества, выделяющие легковоспламеняющиеся газы при соприкосновении с водой',
        ],
      },
      {
        code: '63711148',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
          'Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63711149',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура в соответствии с проектной документацией',
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с тканевым уплотнением в затворе',
          'Арматура с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63711150',
        text:
          'Какое максимальное сопротивление заземляющего устройства вместе с контуром заземления должно быть у транспортного средства для перевозки опасных грузов?',
        answers: ['100 Ом', '50 Ом', '1 кОм', '200 Ом'],
        correctAnswers: ['100 Ом'],
      },
      {
        code: '63711151',
        text:
          'В каком из перечисленных случаев рекомендуется использовать положения Руководства по безопасности при транспортировании опасных веществ на опасных производственных объектах железнодорожными и автомобильными транспортными средствами?',
        answers: [
          'При разработке технологических процессов, эксплуатации, техническом перевооружении, капитальном ремонте, консервации и ликвидации ОПО, разработке обоснования безопасности ОПО, а также изменений, вносимых в обоснование безопасности ОПО',
          'При изготовлении, монтаже, наладке, обслуживании, диагностировании и ремонте технических устройств, применяемых на ОПО',
          'При проведении экспертизы промышленной безопасности технических устройств, предназначенных для транспортирования (перемещения) опасных веществ, зданий и сооружений на ОПО',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63711152',
        text:
          'В зависимости от каких факторов выбирается форма подготовки (общее ознакомление, специализированная подготовка или подготовка в области безопасности) работников, участвующих в перевозке опасных грузов?',
        answers: [
          'В зависимости от функций и обязанностей соответствующих лиц',
          'В зависимости от вида договора найма на работу соответствующих лиц',
          'В зависимости от дальности перевозки опасного груза',
          'В зависимости от класса перевозимого опасного груза',
        ],
        correctAnswers: [
          'В зависимости от функций и обязанностей соответствующих лиц',
        ],
      },
      {
        code: '63711153',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения.',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения.',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения.',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения.',
        ],
      },
      {
        code: '63711154',
        text:
          'Как в переносной цистерне должно располагаться сервисное оборудование, такое как клапаны и наружный трубопровод?',
        answers: [
          'Только сверху цистерны',
          'Только по бокам цистерны',
          'Так, чтобы вещество не оставалось в них после заполнения цистерны',
          'Так, чтобы их внутренний объем не входил в расчетный объем цистерны',
        ],
        correctAnswers: [
          'Так, чтобы вещество не оставалось в них после заполнения цистерны',
        ],
      },
      {
        code: '63711155',
        text:
          'Каким должно быть испытательное давление для металлических цистерн, предназначенных для перевозки охлажденных сжиженных газов, по отношению к максимально допустимому рабочему давлению, указанному на цистерне?',
        answers: [
          'Превышать максимально допустимое давление минимум в 1,3 раза, но составлять не менее 300 кПа',
          'Превышать максимально допустимое давление минимум в 1,5 раза, но составлять не менее 500 кПа',
          'Превышать максимально допустимое давление минимум в 2 раза без нижнего фактического порога',
          'Быть равным максимально допустимому давлению без нижнего фактического порога',
        ],
        correctAnswers: [
          'Превышать максимально допустимое давление минимум в 1,3 раза, но составлять не менее 300 кПа',
        ],
      },
      {
        code: '63711156',
        text:
          'По какому показателю определяют степень опасности веществ, выделяющих воспламеняющиеся газы при соприкосновении с водой?',
        answers: [
          'По уровню водостойкости',
          'По температуре газообразования',
          'По интенсивности газовыделения',
          'По относительному объему газовыделения',
        ],
        correctAnswers: ['По интенсивности газовыделения'],
      },
      {
        code: '63711157',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63711158',
        text:
          'В течение какого срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?',
        answers: [
          'Не менее одних суток',
          'Не менее двух суток',
          'Не менее трех суток',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63711159',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Лицо, зарегистрировавшее наряд-допуск, на срок, требуемый для окончания работ',
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63711160',
        text:
          'К какому классу должны быть отнесены вещество, раствор или смесь, если определить их физические и химические свойства посредством измерения или расчета невозможно без несоразмерных затрат или усилий?',
        answers: [
          'К классу компонента, представляющего наибольшую опасность',
          'К классу компонента, представляющего наименьшую опасность',
          'К классу компонента, составляющего наибольшую долю общего объема',
          'К классу компонента, составляющего наименьшую долю общего объема',
        ],
        correctAnswers: [
          'К классу компонента, представляющего наибольшую опасность',
        ],
      },
      {
        code: '63711161',
        text:
          'Какое минимальное расстояние должно соблюдаться при движении между следующими друг за другом транспортными средствами, перевозящими взрывчатые вещества и изделия, в составе автоколонны?',
        answers: [
          'Не менее 10 м',
          'Не менее 25 м',
          'Не менее 50 м',
          'Не менее 100 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63711162',
        text:
          'В каком случае допускается применение резиновых и резинометаллических рукавов для слива (налива) цистерн жидкого аммиака?',
        answers: [
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
          'Если рукава с внутренним диаметром 50 мм',
          'Если это регламентировано внутренней эксплуатационной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
        ],
      },
      {
        code: '63711163',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся и горючих жидкостей поршневых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
        correctAnswers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63711164',
        text:
          'Допускается ли перевозка химически неустойчивых окисляющих веществ класса 5.1?',
        answers: [
          'Допускается без ограничительных условий',
          'Допускается, если приняты необходимые меры для предотвращения их опасного разложения или полимеризации в ходе перевозки',
          'Допускается, если приняты меры для предотвращения их вибрации при обычных условиях перевозки',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если приняты необходимые меры для предотвращения их опасного разложения или полимеризации в ходе перевозки',
        ],
      },
      {
        code: '63711165',
        text:
          'Какое из перечисленных требований к дыхательным устройствам автоцистерн для перевозки нефтепродуктов указано неверно?',
        answers: [
          'Наличие на входе и выходе дыхательных устройств запорных элементов, частично или полностью перекрывающих проходные сечения при работе, допускается при соблюдении дополнительных требований безопасности',
          'Дыхательные устройства должны быть расположены в местах, доступных для их осмотра',
          'Дыхательные устройства должны быть оборудованы огневыми предохранителями или фильтрами, выполняющими функции пылеулавливателя и огневого предохранителя',
        ],
        correctAnswers: [
          'Наличие на входе и выходе дыхательных устройств запорных элементов, частично или полностью перекрывающих проходные сечения при работе, допускается при соблюдении дополнительных требований безопасности',
        ],
      },
      {
        code: '63711166',
        text:
          'Когда должна пройти соответствующее испытание на герметичность при нормативных уровнях испытаний каждая единица тары, предназначенная для наполнения жидкостями?',
        answers: [
          'До первого использования в целях перевозки и после реконструкции, перед ее очередным использованием в целях перевозки',
          'Только до первого использования в целях перевозки',
          'Только после реконструкции, перед ее очередным использованием в целях перевозки',
          'Перед каждым очередным наполнением',
        ],
        correctAnswers: [
          'До первого использования в целях перевозки и после реконструкции, перед ее очередным использованием в целях перевозки',
        ],
      },
      {
        code: '63711167',
        text: 'Какие опасные грузы относятся к 6 классу опасности?',
        answers: [
          'Взрывчатые материалы',
          'Радиоактивные материалы',
          'Ядовитые и инфекционные вещества',
          'Окисляющие вещества и органические пероксиды',
        ],
        correctAnswers: ['Ядовитые и инфекционные вещества'],
      },
      {
        code: '63711168',
        text:
          'Чем не оснащаются в обязательном порядке емкости цистерны на шасси автомобиля, переносные контейнеры-цистерны на шасси автомобильного прицепа, переносные контейнеры гипохлорита натрия (калия)?',
        answers: [
          'Дыхательным клапаном',
          'Запорным клапаном в нижней части емкости для слива гипохлорита натрия (калия) с устройством для подсоединения сливного гибкого шланга для емкостей с нижним сливом',
          'Устройствами для пломбирования крышки люка',
          'Системами измерения и контроля массы (уровня)',
        ],
        correctAnswers: ['Системами измерения и контроля массы (уровня)'],
      },
      {
        code: '63711169',
        text:
          'На основании какой документации следует производить приемку и опорожнение вагонов-цистерн и контейнеров-цистерн с жидким хлором?',
        answers: [
          'Инструкции, утвержденной техническим руководителем принимающей организации',
          'Документации, составленной организацией-разработчиком',
          'Инструкции, утвержденной техническим руководителем отправляющей организации',
          'Правил безопасности производств хлора и хлорсодержащих сред',
        ],
        correctAnswers: [
          'Инструкции, утвержденной техническим руководителем принимающей организации',
        ],
      },
      {
        code: '63711170',
        text:
          'Допускается ли установление постоянных маршрутов транспортных средств, осуществляющих перевозки опасных грузов?',
        answers: [
          'Допускается для всех классов опасных грузов',
          'Допускается для всех классов опасных грузов, кроме токсичных и инфекционных веществ',
          'Допускается только для газов и окисляющих веществ',
          'Не допускается',
        ],
        correctAnswers: ['Допускается для всех классов опасных грузов'],
      },
      {
        code: '63711171',
        text: 'Какие вещества называются пирофорными?',
        answers: [
          'Вещества, которые даже в малых количествах воспламеняются при контакте с воздухом в течение 5 минут',
          'Вещества, которые в больших количествах воспламеняются при контакте с углекислым газом в течение 10 минут',
          'Вещества, которые в больших количествах воспламеняются при контакте друг с другом в течение 1 минуты',
          'Вещества, которые даже в малых количествах воспламеняются при контакте с водой в течение 3 минут',
        ],
        correctAnswers: [
          'Вещества, которые даже в малых количествах воспламеняются при контакте с воздухом в течение 5 минут',
        ],
      },
      {
        code: '63711172',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Работают люди!", "Опасно для жизни!"',
          '"Газоопасные работы", "Газ"',
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Опасно для жизни!", "Внимание!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63711173',
        text:
          'Какие вещества обозначаются знаком опасности в виде черного пламени на белом фоне с семью вертикальными красными полосами?',
        answers: [
          'Самореактивные вещества',
          'Окисляющие вещества',
          'Коррозионные вещества',
          'Органические пероксиды',
        ],
        correctAnswers: ['Самореактивные вещества'],
      },
      {
        code: '63711174',
        text:
          'Сколько прицепов или полуприцепов может включать одна транспортная единица, загруженная опасными грузами?',
        answers: [
          'Не более одного',
          'Не более двух',
          'Более одного, если перевозимые грузы не относятся к взрывчатым веществам',
          'Более одного, если перевозимые грузы не относится к легковоспламеняющимся веществам',
        ],
        correctAnswers: ['Не более одного'],
      },
      {
        code: '63711175',
        text:
          'В каком месте основного знака опасности указывается номер класса (подкласса), к которому отнесен груз?',
        answers: [
          'В нижнем углу основных знаков указывается номер класса (для грузов класса 5 - номер подкласса), к которому отнесен груз',
          'Не регламентируется',
          'Вдоль условной горизонтальной диагонали знака',
          'В верхнем углу основных знаков указывается номер класса (для грузов класса 5 - номер подкласса), к которому отнесен груз',
        ],
        correctAnswers: [
          'В нижнем углу основных знаков указывается номер класса (для грузов класса 5 - номер подкласса), к которому отнесен груз',
        ],
      },
      {
        code: '63711176',
        text:
          'В соответствии с каким из нижеуказанных ГОСТов осуществляется классификация грузов?',
        answers: [
          'ГОСТ 26319-84. Грузы опасные. Упаковка',
          'ГОСТ 14192-96. Межгосударственный стандарт. Маркировка грузов',
          'ГОСТ 19433-88. Грузы опасные. Классификация и маркировка',
        ],
        correctAnswers: [
          'ГОСТ 19433-88. Грузы опасные. Классификация и маркировка',
        ],
      },
      {
        code: '63711177',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Должностным лицом территориального управления Ростехнадзора',
          'Главными техническими специалистами организаций, эксплуатирующих объекты',
          'Специалистом военизированной газоспасательной службы',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63711178',
        text:
          'Чем должны оснащаться насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Системами самозапуска электродвигателей',
          'Автономной электрической подстанцией',
          'Устройствами для сбора и удаления жидкой фазы',
          'Блокировками',
        ],
        correctAnswers: ['Системами самозапуска электродвигателей'],
      },
      {
        code: '63711179',
        text:
          'Какими знаками опасности и маркировочными надписями должна быть снабжена упаковка, если два и более опасных грузов помещаются в одну и ту же наружную тару?',
        answers: [
          'Знаками и надписями, которые требуются для наиболее опасного вещества или изделия',
          'Знаками и надписями, которые требуются для каждого вещества или изделия',
          'Знаками и надписями, требующимися для вещества или изделия, объем которого больше',
          'Знаками и надписями, требующимися вещества или изделия, масса которого больше',
        ],
        correctAnswers: [
          'Знаками и надписями, которые требуются для каждого вещества или изделия',
        ],
      },
      {
        code: '63711180',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63711181',
        text:
          'Какие вещества относятся к категории 912 опасных грузов класса 9?',
        answers: [
          'Жидкости с температурой вспышки более 61 °C, но не более 90 °C',
          'Твердые вещества, воспламеняющиеся от действия (не менее 30 с, но не более 120 с) газовой горелки',
          'Воспламеняющиеся, ядовитые, едкие и (или) коррозионные вещества в аэрозольной упаковке, вместимостью от 50 до 1000 см',
          'Вещества, которые в условиях специальных испытаний способны самонагреваться до температуры более 150°С, но не более 200°С за время не более 24 ч при температуре окружающей среды 140°С',
        ],
        correctAnswers: [
          'Жидкости с температурой вспышки более 61 °C, но не более 90 °C',
        ],
      },
      {
        code: '63711182',
        text:
          'Какую маркировку должна содержать каждая грузовая единица, содержащая опасный груз?',
        answers: [
          'Вид и степень опасности груза',
          'Адрес получателя груза',
          'Номер аварийной карточки',
          'Транспортное наименование груза',
        ],
        correctAnswers: ['Вид и степень опасности груза'],
      },
      {
        code: '63711183',
        text:
          'Какие грузовые транспортные единицы должны использоваться для перевозки фумигированного груза?',
        answers: [
          'Закрывающиеся таким образом, чтобы выпуск газа был сокращен до минимума',
          'Закрывающиеся таким образом, чтобы во время перевозки осуществлялось постоянное проветривание груза',
          'Только транспортные средства с брезентовым покрытием кузова',
          'Только контейнеры-цистерны',
        ],
        correctAnswers: [
          'Закрывающиеся таким образом, чтобы выпуск газа был сокращен до минимума',
        ],
      },
      {
        code: '63711184',
        text:
          'Что из перечисленного не включает в себя план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Организацию управления, связи и оповещения при авариях на объекте',
          'Организацию материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Состав и дислокацию сил и средств',
          'Порядок ремонтно-восстановительных работ, проводимых после полной ликвидации последствий аварии',
          'Мероприятия, направленные на обеспечение безопасности населения (в случае если в результате аварий на объекте может возникнуть угроза безопасности населения)',
        ],
        correctAnswers: [
          'Порядок ремонтно-восстановительных работ, проводимых после полной ликвидации последствий аварии',
        ],
      },
      {
        code: '63711185',
        text:
          'Какова одна из основных целей базовой подготовки водителей транспортных средств, перевозящих опасные грузы?',
        answers: [
          'Ознакомить водителей с воздействиями, которые оказывают опасные вещества на окружающую среду',
          'Ознакомить водителей с рисками, связанными с перевозкой опасных грузов',
          'Ознакомить водителей с химическим составом опасных веществ',
          'Ознакомить водителей с принципами классификации опасных грузов',
        ],
        correctAnswers: [
          'Ознакомить водителей с рисками, связанными с перевозкой опасных грузов',
        ],
      },
      {
        code: '63711186',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63711187',
        text: 'Какие опасные грузы относятся к 3 классу опасности?',
        answers: [
          'Легковоспламеняющиеся жидкости',
          'Газы сжатые, сжиженные и растворенные под давлением',
          'Твердые легковоспламеняющиеся вещества',
          'Ядовитые вещества',
        ],
        correctAnswers: ['Легковоспламеняющиеся жидкости'],
      },
      {
        code: '63711188',
        text:
          'Какими приборами и средствами автоматизации должны оснащаться сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Укажите все правильные ответы.',
        answers: [
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами измерения перепада давления на сепараторе',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63711189',
        text:
          'Нахождение каких стационарных систем трубопроводов на пунктах слива-налива жидкого хлора не предусмотрено Правилами безопасности производств хлора и хлорсодержащих сред?',
        answers: [
          'Трубопроводов для подачи в вагон-цистерну сжатого воздуха (азота) или хлора для передавливания',
          'Трубопроводов пара и горячей воды для подогрева',
          'Трубопроводов для отвода газообразного хлора на потребление или поглощение',
          'Трубопроводов для слива-налива жидкого хлора',
        ],
        correctAnswers: ['Трубопроводов пара и горячей воды для подогрева'],
      },
      {
        code: '63711190',
        text:
          'Куда наносится маркировка, характеризующая вид и степень опасности груза, при транспортировании груза в контейнере?',
        answers: [
          'На дверь и, если позволяет конструкция, днище',
          'На дверь, боковую стенку и, если позволяет конструкция, крышу',
          'Только на боковую стенку',
          'Только на дверь',
        ],
        correctAnswers: [
          'На дверь, боковую стенку и, если позволяет конструкция, крышу',
        ],
      },
      {
        code: '63711191',
        text:
          'Какие устанавливаются требования к огнетушителям на автоцистернах для перевозки нефтепродуктов?',
        answers: [
          'Должен быть один порошковый огнетушитель вместимостью не менее 5 л',
          'Должно быть два порошковых огнетушителя вместимостью не менее 5 л каждый',
          'Должно быть два углекислотных огнетушителя с массой заряда огнетушащего вещества (ОТВ) 3 кг каждый',
          'Должен быть один углекислотный огнетушитель с массой заряда огнетушащего вещества (ОТВ) 3 кг',
        ],
        correctAnswers: [
          'Должно быть два порошковых огнетушителя вместимостью не менее 5 л каждый',
        ],
      },
      {
        code: '63711192',
        text:
          'На какие виды перевозок опасных грузов автомобильным транспортом распространяются требования Европейского соглашения о международной дорожной перевозке опасных грузов (далее – ДОПОГ)?',
        answers: [
          'На внутригосударственные перевозки',
          'На международные перевозки',
          'На перевозки между странами СНГ',
          'На внутригосударственные и международные перевозки',
          'На внутригосударственные перевозки и перевозки между странами СНГ',
        ],
        correctAnswers: ['На внутригосударственные и международные перевозки'],
      },
      {
        code: '63711193',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63711194',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В проектной документации',
          'В технологическом регламенте на производство продукции',
          'В исходных данных на проектирование',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63711195',
        text:
          'Какие данные необходимо установить для присвоения опасному грузу классификационного шифра?',
        answers: [
          'Все перечисленные',
          'Виды опасности, характеризующие груз и степень опасности',
          'Класс (подкласс), к которому относится груз',
          'Категорию, характеризующую дополнительный вид опасности',
          'Группу, характеризующую степень опасности груза',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63711196',
        text:
          'Каким количеством и емкостью переносных огнетушителей должны комплектоваться транспортные средства для перевозки опасных грузов технически допустимой максимальной массой более 7,5 т?',
        answers: [
          'Не менее чем одним огнетушителем емкостью не менее 12 кг или двумя огнетушителями емкостью каждого не менее 6 кг',
          'Не менее чем одним огнетушителем или более минимальной емкостью 8 кг или двумя огнетушителями, из которых один емкостью не менее 6 кг',
          'Не менее чем одним огнетушителем или более общей емкостью не менее 4 кг',
          'Не менее чем одним огнетушителем или более емкостью не менее 2 кг, пригодным для тушения пожара в двигателе или кабине транспортного средства',
        ],
        correctAnswers: [
          'Не менее чем одним огнетушителем емкостью не менее 12 кг или двумя огнетушителями емкостью каждого не менее 6 кг',
        ],
      },
      {
        code: '63711197',
        text:
          'Какое минимальное время пребывания под навесом заполненных хлором контейнеров или баллонов?',
        answers: [
          'Не менее семи суток',
          'Не менее одних суток',
          'Не менее двух суток',
          'Не менее трех суток',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63711198',
        text:
          'На каких языках, кроме официального языка страны происхождения груза, должна быть выполнена маркировочная надпись "ТРАНСПОРТНЫЙ ПАКЕТ"?',
        answers: [
          'На английском, французском или немецком',
          'На английском, итальянском или русском',
          'На испанском, английском или французском',
          'На английском',
        ],
        correctAnswers: ['На английском, французском или немецком'],
      },
      {
        code: '63711199',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от вида взрывозащиты',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63711200',
        text:
          'В течение какого времени после наполнения жидким хлором осуществляется отстой вагонов-цистерн (контейнеров-цистерн), заполненных жидким хлором, на территории организации, сопровождающийся ежесменным визуальным осмотром и контролем утечек хлора?',
        answers: [
          'Не менее суток',
          'Не менее 12 часов',
          'Не менее 6 часов',
          'Не менее 3 часов',
        ],
        correctAnswers: ['Не менее суток'],
      },
      {
        code: '63711201',
        text:
          'Какую поверхность или покрытие должен иметь пол контейнера при перевозке сыпучих порошкообразных веществ, а также пиротехнических средств?',
        answers: [
          'Металлическую поверхность или покрытие',
          'Неметаллическую поверхность или покрытие',
          'Только цинковую поверхность или покрытие',
          'Только деревянную поверхность или покрытие',
        ],
        correctAnswers: ['Неметаллическую поверхность или покрытие'],
      },
      {
        code: '63711202',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главным инженером эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63711203',
        text:
          'Каких значений не должна превышать максимальная температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?',
        answers: [
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63711204',
        text:
          'Сколько классов опасных грузов определяется по ГОСТ 19433-88 "Грузы опасные. Классификация и маркировка"?',
        answers: ['9 классов', '5 классов', '6 классов', '7 классов'],
        correctAnswers: ['9 классов'],
      },
      {
        code: '63711205',
        text:
          'Какие действия должны быть предприняты при обнаружении нарушений требований Правил безопасности химически опасных производственных объектов в отношении цистерн с жидким аммиаком?',
        answers: [
          'Немедленно сообщить в территориальный орган Ростехнадзора',
          'Аммиак должен быть немедленно слит',
          'Необходимо поставить круглосуточное наблюдение за цистернами до устранения нарушений',
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
        ],
        correctAnswers: [
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
        ],
      },
      {
        code: '63711206',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63711207',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Все перечисленное',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63711208',
        text:
          'Какие из перечисленных табличек должны присутствовать на контейнерах-цистернах для транспортирования опасных веществ?',
        answers: [
          'Таблички по безопасности в соответствии с Международной Конвенцией по безопасным контейнерам',
          'Таблички по Таможенной конвенции, касающейся контейнеров',
          'Таблички с техническими данными по цистерне в соответствии с нормами международных сообщений и кодексов по перевозкам опасных грузов',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63711209',
        text:
          'Какой из перечисленных газов относится к группе 3 опасных грузов класса 2?',
        answers: [
          'Сжатые газы, критическая температура которых менее минус 10 °C',
          'Сжиженные газы, критическая температура которых не менее 70 °С',
          'Сжиженные охлажденные газы, транспортируемые под давлением, близким к атмосферному',
          'Растворенные газы под давлением',
        ],
        correctAnswers: [
          'Сжиженные газы, критическая температура которых не менее 70 °С',
        ],
      },
      {
        code: '63711210',
        text:
          'До какого остаточного давления отводят абгазы из цистерны в абгазную систему после завершения слива хлора?',
        answers: [
          'Не менее 0,05 МПа (0,5 кгс/см2)',
          'Не менее 0,01 МПа (0,1 кгс/см2)',
          'Не менее 0,07 МПа (0,7 кгс/см2)',
        ],
        correctAnswers: ['Не менее 0,05 МПа (0,5 кгс/см2)'],
      },
      {
        code: '63711211',
        text:
          'Какое минимальное расстояние должно быть между задней стенкой цистерны и задней частью защитного устройства (от крайней задней точки стенки цистерны или от выступающей арматуры, соприкасающейся с перевозимым грузом)?',
        answers: ['100 мм', '80 мм', '120 мм', '160 мм'],
        correctAnswers: ['100 мм'],
      },
      {
        code: '63711212',
        text:
          'Какой из перечисленных органов определяет порядок установления постоянных маршрутов транспортных средств, осуществляющих перевозки опасных грузов?',
        answers: [
          'Орган государственной власти Российской Федерации в области использования автомобильных дорог и осуществления дорожной деятельности',
          'Ростехнадзор',
          'Росприроднадзор',
          'Росстандарт',
        ],
        correctAnswers: [
          'Орган государственной власти Российской Федерации в области использования автомобильных дорог и осуществления дорожной деятельности',
        ],
      },
    ],
  },
  12: {
    63718: [
      {
        code: '63718000',
        text:
          'В какой срок после окончания расследования организация, на объекте которой произошла утрата взрывчатых материалов промышленного назначения, должна разослать материалы расследования в соответствующие органы?',
        answers: [
          'В срок не позднее 3 рабочих дней',
          'В срок не позднее 5 рабочих дней',
          'В срок не позднее 7 рабочих дней',
          'В срок не позднее 10 рабочих дней',
        ],
        correctAnswers: ['В срок не позднее 3 рабочих дней'],
      },
      {
        code: '63718001',
        text:
          'Издания какого внутреннего распорядительного акта организации вправе требовать комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения?',
        answers: [
          'О порядке и сроках реализации разработанных мер',
          'О привлечении к ответственности работников, нарушивших установленный порядок хранения и использования взрывчатых материалов промышленного назначения',
          'О поощрении работников',
        ],
        correctAnswers: ['О порядке и сроках реализации разработанных мер'],
      },
      {
        code: '63718002',
        text:
          'В какой срок комиссия должна составить акт по результатам технического расследования случая утраты взрывчатых материалов промышленного назначения?',
        answers: [
          'В срок не более 15 рабочих дней',
          'В срок не более 25 рабочих дней',
          'В срок не более 30 рабочих дней',
          'В срок не более 35 рабочих дней',
        ],
        correctAnswers: ['В срок не более 15 рабочих дней'],
      },
      {
        code: '63718003',
        text:
          'В какой документ машинист погрузочной техники должен заносить информацию о времени обнаружения отказа, принятых мерах безопасности, а также данные о том, кому сообщено об обнаружении отказа?',
        answers: [
          'В журнал приема-сдачи смен',
          'В наряд-путевку',
          'В акт обнаружения отказа',
          'В журнал регистрации отказов при взрывных работах',
        ],
        correctAnswers: ['В журнал приема-сдачи смен'],
      },
      {
        code: '63718004',
        text: 'Как следует вводить огнепроводный шнур в капсюль-детонатор?',
        answers: [
          'До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения',
          'До соприкосновения с чашечкой капсюля-детонатора поступательно-вращательным движением по часовой стрелке',
          'До соприкосновения с чашечкой капсюля-детонатора поступательно-вращательным движением против часовой стрелки',
          'Прямым движением без вращения, не доводя до соприкосновения с чашечкой капсюля-детонатора на 5 мм',
        ],
        correctAnswers: [
          'До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения',
        ],
      },
      {
        code: '63718005',
        text:
          'Для каких взрывчатых веществ не требуется подтверждения соответствия требованиям технического регламента Таможенного союза от 20.07.2012 № 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          'Для взрывчатых веществ, изготавливаемых для использования в промышленных целях',
          'Для взрывчатых веществ, изготавливаемых для обращения на единой таможенной территории государств – членов Таможенного союза',
          'Для взрывчатых веществ, изготавливаемых для собственных нужд',
        ],
        correctAnswers: [
          'Для взрывчатых веществ, изготавливаемых для собственных нужд',
        ],
      },
      {
        code: '63718006',
        text:
          'Какова максимально допустимая общая масса зарядов, находящихся на самоходном маломерном судне при выполнении подводных взрывных работ?',
        answers: ['40 кг', '80 кг', '50 кг', '20 кг'],
        correctAnswers: ['40 кг'],
      },
      {
        code: '63718007',
        text:
          'При выполнении каких взрывных работ взрывание зарядов должно проводиться по утвержденным проектам (техническим проектам)?',
        answers: [
          'Разовых взрывов зарядов в целях ликвидации отказов',
          'Дноуглубительных и ледоходных работ',
          'Разовых взрывов зарядов для выравнивания почвы выработки',
          'Разовых взрывов зарядов в шпурах для доведения контура выработки до размеров, предусмотренных проектом',
        ],
        correctAnswers: ['Дноуглубительных и ледоходных работ'],
      },
      {
        code: '63718008',
        text:
          'Каков максимально допустимый срок предоставления Ростехнадзором государственной услуги при продлении действия (переоформлении) разрешения на ведение работ со взрывчатыми материалами промышленного назначения со дня регистрации заявления?',
        answers: [
          '10 рабочих дней',
          '45 календарных дней',
          '30 календарных дней',
          '15 рабочих дней',
        ],
        correctAnswers: ['15 рабочих дней'],
      },
      {
        code: '63718009',
        text:
          'В каком случае оборудование и здание пункта производства и механизированной подготовки взрывчатых веществ должны быть полностью освобождены от взрывоопасных продуктов?',
        answers: [
          'Если пункт остановлен на период более 2 суток',
          'Если невозможно организовать постоянное наблюдение за этим оборудованием и зданием',
          'Если пункт остановлен перед нерабочей сменой',
          'Если пункт остановлен после окончания смены',
        ],
        correctAnswers: ['Если пункт остановлен на период более 2 суток'],
      },
      {
        code: '63718010',
        text:
          'Что следует использовать для перемещения гранулированных взрывчатых веществ или их компонентов по внутренним трактам оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Шнековые питатели',
          'Колесные питатели',
          'Цепные питатели',
          'Вибропитатели',
        ],
        correctAnswers: ['Шнековые питатели', 'Вибропитатели'],
      },
      {
        code: '63718011',
        text:
          'Когда необходимо комиссионно проверять молниезащиту складов взрывчатых материалов? Выберите два правильных варианта ответа.',
        answers: [
          'В предгрозовой период',
          'После выявления повреждений',
          'Весной и осенью',
          'В послегрозовой период',
          'После проведения ремонтных работ',
        ],
        correctAnswers: [
          'В предгрозовой период',
          'После выявления повреждений',
        ],
      },
      {
        code: '63718012',
        text:
          'Когда разрешается выход взрывника из укрытия после взрыва с применением электродетонаторов?',
        answers: [
          'После всего перечисленного',
          'Только после отсоединения электровзрывной сети от источника тока и замыкания ее накоротко',
          'Только после проветривания',
          'Только после истечения не менее 5 минут',
        ],
        correctAnswers: ['После всего перечисленного'],
      },
      {
        code: '63718013',
        text:
          'Во что следует упаковывать заряд при температуре более 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?',
        answers: [
          'В общую термоизолирующую оболочку',
          'В пергаментную бумагу',
          'В крафтцеллюлозную бумагу',
          'В оберточную бумагу',
        ],
        correctAnswers: ['В общую термоизолирующую оболочку'],
      },
      {
        code: '63718014',
        text:
          'Что из перечисленного выполняется при руководстве взрывными работами?',
        answers: [
          'Непосредственное управление технологическими процессами на производственных объектах, а также разработка, согласование и утверждение технических, методических и иных документов, регламентирующих порядок хранения опасных веществ',
          'Разработка, согласование и утверждение порядка подготовки и проверки знаний специалистов по вопросам безопасного ведения работ в области взрывного дела',
          'Непосредственное управление технологическими процессами, связанными с обращением со взрывчатыми материалами, разработка, согласование и утверждение технических, проектных, методических и иных документов, регламентирующих порядок выполнения взрывных работ и работ со взрывчатыми материалами',
          'Обучение и подготовка руководителей горных и взрывных работ с выдачей лицензии на право выполнения данной деятельности, получаемой в установленном порядке',
        ],
        correctAnswers: [
          'Непосредственное управление технологическими процессами, связанными с обращением со взрывчатыми материалами, разработка, согласование и утверждение технических, проектных, методических и иных документов, регламентирующих порядок выполнения взрывных работ и работ со взрывчатыми материалами',
        ],
      },
      {
        code: '63718015',
        text:
          'Кто должен вести книгу учета прихода и расхода взрывчатых материалов?',
        answers: [
          'Руководитель взрывных работ',
          'Начальники участков, на которых проводятся взрывные работы',
          'Заведующие и раздатчики складов взрывчатых материалов',
          'Работники складов взрывчатых материалов, осуществляющие погрузочные операции',
        ],
        correctAnswers: [
          'Заведующие и раздатчики складов взрывчатых материалов',
        ],
      },
      {
        code: '63718016',
        text:
          'На какие вещества распространяется действие технического регламента Таможенного союза от 20.07.2012 № 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"? Выберите два правильных варианта ответа.',
        answers: [
          'На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции',
          'На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях',
          'На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ',
          'На пиротехнические изделия',
        ],
        correctAnswers: [
          'На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях',
          'На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ',
        ],
      },
      {
        code: '63718017',
        text:
          'Представители какой организации должны быть включены в состав комиссии по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения? Выберите два правильных варианта ответа.',
        answers: [
          'Территориального органа МЧС России',
          'Территориального органа Минприроды России',
          'Организации, в которой произошла утрата взрывчатых материалов',
          'Территориального органа ФСБ России',
        ],
        correctAnswers: [
          'Организации, в которой произошла утрата взрывчатых материалов',
          'Территориального органа ФСБ России',
        ],
      },
      {
        code: '63718018',
        text:
          'Кто выдает разрешение на ведение работ со взрывчатыми материалами промышленного назначения?',
        answers: [
          'Территориальные органы Ростехнадзора',
          'Центральный аппарат Ростехнадзора',
          'Территориальные органы МВД России',
          'Территориальные органы ФСБ России',
        ],
        correctAnswers: ['Территориальные органы Ростехнадзора'],
      },
      {
        code: '63718019',
        text:
          'С какой периодичностью должен проводиться пересмотр регламента технологического процесса производства и подготовки взрывчатых веществ?',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 6 лет',
          'Не реже 1 раза в 8 лет',
          'Не реже 1 раза в 10 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '63718020',
        text:
          'Какие из перечисленных взрывов относятся к массовым взрывам зарядов взрывчатых веществ на земной поверхности?',
        answers: [
          'Взрывы единичных зарядов в выработках протяженностью 7 м',
          'Взрывы смонтированных в общую взрывную сеть 2 и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки',
          'Взрывы, при осуществлении которых требуется большее время для проветривания и возобновления работ в руднике, чем это предусмотрено в расчете при повседневной организации работ',
          'Специальные взрывы по обрушению потолочин камер, междуэтажных целиков на всю высоту этажа',
        ],
        correctAnswers: [
          'Взрывы смонтированных в общую взрывную сеть 2 и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки',
        ],
      },
      {
        code: '63718021',
        text:
          'Для чего изъятая единая книжка взрывника передается территориальному органу Ростехнадзора?',
        answers: [
          'Для уничтожения',
          'Для временного хранения',
          'Для внесения сведений о причине изъятия',
          'Для замены на новую',
        ],
        correctAnswers: ['Для уничтожения'],
      },
      {
        code: '63718022',
        text:
          'Что из перечисленного обозначает номер взрывника, наносимый на гильзы электродетонаторов и капсюлей-детонаторов?',
        answers: [
          '2 буквенных индекса справа от цифровых',
          '2 цифровых индекса',
          '2 буквенных индекса слева от цифровых',
          'Второй буквенный индекс слева от цифровых',
        ],
        correctAnswers: ['2 буквенных индекса справа от цифровых'],
      },
      {
        code: '63718023',
        text:
          'Каково минимально допустимое расстояние от устья скважины до передвижной зарядной мастерской (лаборатории перфораторной станции) при хранении в ней взрывчатых материалов, прострелочных и взрывных аппаратов?',
        answers: ['20 м', '50 м', '70 м', '30 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63718024',
        text:
          'В каком случае у взрывника может быть изъята единая книжка взрывника?',
        answers: [
          'В случае нарушения режима рабочего времени',
          'В случае нарушения установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов',
          'В случае появления на рабочем месте в состоянии алкогольного или наркотического опьянения',
          'В случае перехода на работу в другую организацию, ведущую работы со взрывчатыми материалами',
        ],
        correctAnswers: [
          'В случае нарушения установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов',
        ],
      },
      {
        code: '63718025',
        text:
          'Что является основанием для отказа в выдаче разрешения на ведение работ со взрывчатыми материалами промышленного назначения?',
        answers: [
          'Наличие в составе материалов заявителя сведений об оплате государственной услуги',
          'Планирование взрывных работ в районе населенных пунктов',
          'Истечение срока рассмотрения заявления',
          'Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений',
        ],
        correctAnswers: [
          'Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений',
        ],
      },
      {
        code: '63718026',
        text: 'Какими способами допускается уничтожать взрывчатые материалы?',
        answers: [
          'Взрыванием, сжиганием или растворением в воде',
          'Растворением в кислоте или щелочи',
          'Растворением в бензине, измельчением с последующим распылением',
          'Измельчением с последующей биологической обработкой',
        ],
        correctAnswers: ['Взрыванием, сжиганием или растворением в воде'],
      },
      {
        code: '63718027',
        text:
          'На какое минимальное расстояние от скважины следует убирать буровые установки, не имеющие приспособлений для заряжания, при взрывании зарядов в сложных горно-геологических условиях?',
        answers: ['На 10 м', 'На 8 м', 'На 5 м', 'На 3 м'],
        correctAnswers: ['На 10 м'],
      },
      {
        code: '63718028',
        text:
          'В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?',
        answers: [
          'В хранилищах аммиачной селитры',
          'В хранилищах натриевой селитры',
          'В хранилищах кальциевой селитры',
        ],
        correctAnswers: ['В хранилищах аммиачной селитры'],
      },
      {
        code: '63718029',
        text:
          'Кто должен быть включен в состав комиссии по проведению испытаний взрывчатых веществ при оформлении разрешения на их постоянное применение?',
        answers: [
          'Представитель организации, в которой проводятся испытания',
          'Представитель экспертной организации',
          'Представитель территориального органа МЧС России',
          'Представитель территориального органа ФСБ России',
          'Представитель территориального органа МВД России',
        ],
        correctAnswers: [
          'Представитель организации, в которой проводятся испытания',
          'Представитель экспертной организации',
        ],
      },
      {
        code: '63718030',
        text:
          'На какие вещества должно быть оформлено руководство (инструкция) по применению?',
        answers: [
          'На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях',
          'На взрывчатые вещества, непосредственно не применяемые для использования энергии взрыва в промышленных целях',
          'На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ',
        ],
        correctAnswers: [
          'На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях',
        ],
      },
      {
        code: '63718031',
        text:
          'В течение какого времени с момента возникновения аварии, инцидента на опасном производственном объекте должно быть передано оперативное сообщение о случившемся?',
        answers: [
          'В течение 72 часов',
          'В течение 36 часов',
          'В течение 60 часов',
          'В течение 24 часов',
        ],
        correctAnswers: ['В течение 24 часов'],
      },
      {
        code: '63718032',
        text:
          'При каких взрывных работах допускается использовать слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без разминания или измельчения?',
        answers: [
          'При работах на земной поверхности',
          'При работах в шахтах, не опасных по газу',
          'При работах в рудниках, разрабатывающих пласты (рудные тела), не опасные по взрывам пыли',
        ],
        correctAnswers: ['При работах на земной поверхности'],
      },
      {
        code: '63718033',
        text:
          'В какой срок после окончания расследования организация, на объекте которой произошла авария, должна разослать материалы расследования в соответствующие органы?',
        answers: [
          'В срок не позднее 7 рабочих дней',
          'В срок не позднее 10 рабочих дней',
          'В срок не позднее 14 календарных дней',
          'В срок не позднее 12 календарных дней',
        ],
        correctAnswers: ['В срок не позднее 7 рабочих дней'],
      },
      {
        code: '63718034',
        text:
          'В какой срок с даты подписания правового акта о назначении комиссии проводится техническое расследование причин аварии на опасном производственном объекте?',
        answers: [
          'В срок не более 30 календарных дней',
          'В срок не более 20 рабочих дней',
          'В срок не более 15 календарных дней',
          'В срок не более 10 рабочих дней',
        ],
        correctAnswers: ['В срок не более 30 календарных дней'],
      },
      {
        code: '63718035',
        text:
          'Что запрещается при выполнении работ по заряжанию шпуров (скважин) и монтажу взрывной сети на высоте более 2 м?',
        answers: [
          'Заряжание шпуров с лестниц',
          'Монтаж взрывной сети с оборудованных подъемных площадок (помостов)',
          'Заряжание шпуров с площадок подъемных механизмов',
          'Монтаж взрывной сети с полков, примыкающих к забою',
        ],
        correctAnswers: ['Заряжание шпуров с лестниц'],
      },
      {
        code: '63718036',
        text:
          'Через какое время после последнего взрыва взрывнику разрешается подойти к месту взрыва, если вести счет взорвавшихся зарядов невозможно или какой-либо заряд не взорвался?',
        answers: [
          'Не ранее чем через 15 минут',
          'Не ранее чем через 12 минут',
          'Не ранее чем через 10 минут',
          'Не ранее чем через 5 минут',
        ],
        correctAnswers: ['Не ранее чем через 15 минут'],
      },
      {
        code: '63718037',
        text:
          'Какое требование к ведению специальных взрывных работ при образовании каналов, канав и котлованов указано верно?',
        answers: [
          'При заряжании линейных зарядов более 2 суток боевики необходимо укладывать в день производства взрыва',
          'Отставание заряда от одноковшового экскаватора должно быть не менее 5 м',
          'При формировании траншейных зарядов расстояние между краном и бульдозером, выполняющим забойку, должно быть не менее 1,1 радиуса разворота крана',
          'В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D применение дымного пороха не допускается',
        ],
        correctAnswers: [
          'При формировании траншейных зарядов расстояние между краном и бульдозером, выполняющим забойку, должно быть не менее 1,1 радиуса разворота крана',
        ],
      },
      {
        code: '63718038',
        text:
          'К какому классу опасности относятся все взрывчатые вещества и изделия на их основе?',
        answers: [
          'К 1 классу опасности',
          'Ко 2 классу опасности',
          'К 3 классу опасности',
          'К 4 классу опасности',
        ],
        correctAnswers: ['К 1 классу опасности'],
      },
      {
        code: '63718039',
        text:
          'Какой должна быть длина огнепроводного шнура контрольной трубки при поджигании 5 зажигательных трубок и более в случае ведения взрывных работ на земной поверхности?',
        answers: [
          'Не менее чем на 60 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 40 см',
          'Не менее чем на 50 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 35 см',
          'Не менее чем на 40 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 60 см',
          'Не менее чем на 35 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 50 см',
        ],
        correctAnswers: [
          'Не менее чем на 60 см короче по сравнению со шнуром самой короткой из применяемых зажигательных трубок, но не менее 40 см',
        ],
      },
      {
        code: '63718040',
        text:
          'Через какое время после последнего взрыва взрывнику разрешается подойти к месту взрыва при ведении счета взорвавшихся зарядов и отсутствии отказов?',
        answers: [
          'Не ранее чем через 2 минуты',
          'Не ранее чем через 3 минуты',
          'Не ранее чем через 4 минуты',
          'Не ранее чем через 5 минут',
        ],
        correctAnswers: ['Не ранее чем через 5 минут'],
      },
      {
        code: '63718041',
        text:
          'По какому документу следует отпускать взрывникам взрывчатые материалы?',
        answers: [
          'По наряду-путевке',
          'По наряду-допуску',
          'По наряду-заказу',
          'По наряду-заданию',
        ],
        correctAnswers: ['По наряду-путевке'],
      },
      {
        code: '63718042',
        text:
          'К какой категории риска причинения вреда, определяемой в целях лицензионного контроля, относится деятельность по применению взрывчатых материалов промышленного назначения?',
        answers: ['К низкой', 'К средней', 'К высокой'],
        correctAnswers: ['К высокой'],
      },
      {
        code: '63718043',
        text:
          'Какое определение соответствует понятию "средства инициирования" согласно техническому регламенту Таможенного союза от 20.07.2012 № 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          'Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации',
          'Компактная масса взрывчатого вещества конечных размеров, заключенная в оболочку или без нее, предназначенная для использования в изготовленном виде самостоятельно или в сочетании с другими взрывчатыми веществами',
          'Высокочувствительное взрывчатое вещество, легко детонирующее от простейших начальных импульсов (удара, трения, нагрева, искрового разряда), предназначенное для возбуждения детонации или воспламенения других взрывчатых веществ',
          'Средство или комплекс средств, предназначенных для защиты взрывчатых веществ и изделий на их основе от повреждений и исключения воздействия атмосферных явлений',
        ],
        correctAnswers: [
          'Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации',
        ],
      },
      {
        code: '63718044',
        text:
          'Сколько времени допускается кратковременно хранить взрывчатые материалы на специальных площадках для производства геофизических и других разовых взрывных работ?',
        answers: [
          'До 90 суток',
          'До 110 суток',
          'До 145 суток',
          'До 180 суток',
        ],
        correctAnswers: ['До 90 суток'],
      },
      {
        code: '63718045',
        text:
          'Каково минимальное количество членов комиссии по техническому расследованию причин аварий на опасном производственном объекте?',
        answers: ['5 человек', '7 человек', '3 человека', '6 человек'],
        correctAnswers: ['5 человек'],
      },
      {
        code: '63718046',
        text:
          'Какую максимальную массу взрывчатых веществ без средств инициирования разрешается переносить в сумках взрывнику?',
        answers: ['24 кг', '27 кг', '30 кг', '40 кг'],
        correctAnswers: ['24 кг'],
      },
      {
        code: '63718047',
        text:
          'В каком случае руководителю взрывных работ в смене разрешается подписывать наряд-накладную? Выберите два правильных варианта ответа.',
        answers: [
          'Если производится отпуск доставщикам взрывчатых материалов со склада для перевозки к местам производства взрывных работ',
          'Если производится передача взрывчатых материалов с одного склада взрывчатых материалов на другой',
          'Если производится отпуск доставщикам взрывчатых материалов для перемещения с одного склада взрывчатых материалов на другой',
          'Если производится отпуск доставщикам взрывчатых материалов со склада для перевозки в участковые пункты хранения',
        ],
        correctAnswers: [
          'Если производится отпуск доставщикам взрывчатых материалов со склада для перевозки к местам производства взрывных работ',
          'Если производится отпуск доставщикам взрывчатых материалов со склада для перевозки в участковые пункты хранения',
        ],
      },
      {
        code: '63718048',
        text:
          'Какую максимальную массу взрывчатых материалов разрешается переносить в сумках взрывнику при доставке взрывчатых веществ одновременно со средствами инициирования?',
        answers: ['10 кг', '15 кг', '18 кг', '20 кг'],
        correctAnswers: ['10 кг'],
      },
      {
        code: '63718049',
        text:
          'Куда необходимо передавать акт об уничтожении взрывчатых материалов?',
        answers: [
          'На склад взрывчатых материалов',
          'В приемную руководителя организации',
          'В территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['На склад взрывчатых материалов'],
      },
      {
        code: '63718050',
        text:
          'Какое утверждение соответствует требованиям безопасности к размещению площадок пунктов производства и подготовки взрывчатых веществ?',
        answers: [
          'Поверхностные пункты производства и подготовки взрывчатых веществ допускается размещать над действующими горными выработками',
          'Поверхностные пункты производства и подготовки взрывчатых веществ запрещается располагать в запретной зоне склада взрывчатых материалов',
          'Территория пунктов производства и подготовки взрывчатых веществ, расположенных на самостоятельных площадках, по внешнему периметру может не охраняться при наличии ограждения',
          'Территория, отводимая для размещения на складе взрывчатых материалов пунктов производства и (или) подготовки взрывчатых веществ, должна иметь самостоятельные въезд и выезд',
        ],
        correctAnswers: [
          'Территория, отводимая для размещения на складе взрывчатых материалов пунктов производства и (или) подготовки взрывчатых веществ, должна иметь самостоятельные въезд и выезд',
        ],
      },
      {
        code: '63718051',
        text:
          'Как должна быть обеспечена охрана запретной и опасной зон при организации взрывных работ на земной поверхности?',
        answers: [
          'На границах запретной и опасной зон должны быть выставлены посты',
          'На границах запретной и опасной зон должны быть установлены ограждения, освещаемые в ночное время',
          'На границах запретной и опасной зон должны быть выставлены аншлаги с надписями, запрещающими вход в эти зоны',
          'На границах запретной и опасной зон должны быть выставлены предупреждающие плакаты с надписями, запрещающими вход в эти зоны',
        ],
        correctAnswers: [
          'На границах запретной и опасной зон должны быть выставлены посты',
        ],
      },
      {
        code: '63718052',
        text:
          'На каком расстоянии вокруг каждого здания поверхностного или полууглубленного склада взрывчатых материалов должен быть снят дерн для предохранения зданий от лесных и напольных пожаров?',
        answers: [
          'На расстоянии не менее 3,0 м',
          'На расстоянии не менее 4,0 м',
          'На расстоянии не менее 4,5 м',
          'На расстоянии не менее 5,0 м',
        ],
        correctAnswers: ['На расстоянии не менее 5,0 м'],
      },
      {
        code: '63718053',
        text:
          'Как подразделяются склады взрывчатых материалов по месту расположения относительно земной поверхности?',
        answers: [
          'На наземные, полуподземные и подземные',
          'На поверхностные, полууглубленные, углубленные и подземные',
          'На шахтные, карьерные и рудниковые',
        ],
        correctAnswers: [
          'На поверхностные, полууглубленные, углубленные и подземные',
        ],
      },
      {
        code: '63718054',
        text:
          'Что необходимо сделать при ликвидации отказавшего наружного заряда?',
        answers: [
          'Произвести взрывание заряда, размещенного параллельно отказавшему',
          'Поместить на него новый заряд и провести взрывание в обычном порядке',
          'Выдернуть огнепроводный или детонирующий шнур',
          'Размыть заряд струей воды гидромонитора',
        ],
        correctAnswers: [
          'Поместить на него новый заряд и провести взрывание в обычном порядке',
        ],
      },
      {
        code: '63718055',
        text: 'Какое утверждение о средствах инициирования указано верно?',
        answers: [
          'Монтаж электровзрывной сети должен выполняться от источника тока к заряду',
          'Допускается использование трубы в качестве одного из проводников электровзрывной сети',
          'При производстве взрывных работ в каждый электродетонатор должен поступать ток, сила которого не превышает значение, установленное технической документацией',
          'Электровзрывная сеть должна быть трехпроводной',
          'В рудниках, опасных по газу, должны применяться электродетонаторы только с медными проводами',
        ],
        correctAnswers: [
          'В рудниках, опасных по газу, должны применяться электродетонаторы только с медными проводами',
        ],
      },
      {
        code: '63718056',
        text:
          'К какой группе совместимости относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие менее 2 независимых предохранительных устройств?',
        answers: ['К группе Е', 'К группе В', 'К группе С', 'К группе D'],
        correctAnswers: ['К группе В'],
      },
      {
        code: '63718057',
        text:
          'Каков срок хранения проектов буровзрывных (взрывных) работ, паспортов, схем, в соответствии с которыми осуществлялись взрывные работы?',
        answers: [
          'Не менее года',
          'Не менее 6 месяцев',
          'Не менее 3 месяцев',
          'Не менее 1 месяца',
        ],
        correctAnswers: ['Не менее года'],
      },
      {
        code: '63718058',
        text:
          'Каков минимально допустимый размер запретной зоны от ближайшего заряда на открытых горных работах?',
        answers: ['20 м', '50 м', '30 м', '10 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63718059',
        text:
          'При каком расхождении измеренного и расчетного сопротивления электровзрывной сети скважинных и камерных зарядов необходимо устранить неисправности, вызывающие отклонения?',
        answers: [
          'При расхождении более чем на 10 %',
          'При расхождении более чем на 9 %',
          'При расхождении более чем на 8 %',
          'При расхождении более чем на 7 %',
        ],
        correctAnswers: ['При расхождении более чем на 10 %'],
      },
      {
        code: '63718060',
        text:
          'С какой периодичностью и кем должен проводиться наружный осмотр молниезащитных устройств склада взрывчатых материалов?',
        answers: [
          'Не реже 1 раза в месяц заведующим складом взрывчатых материалов',
          'Не реже 1 раза в квартал комиссией, назначенной руководителем организации',
          'Не реже 1 раза в полугодие службой энергетика организации',
          'Не реже 1 раза в месяц руководителем взрывных работ, в ведении которого находится склад взрывчатых материалов',
        ],
        correctAnswers: [
          'Не реже 1 раза в месяц заведующим складом взрывчатых материалов',
        ],
      },
      {
        code: '63718061',
        text:
          'Кто должен финансировать расходы на техническое расследование причин аварии на опасном производственном объекте?',
        answers: [
          'Страховая компания, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта',
          'Территориальный орган Ростехнадзора',
          'Организация, эксплуатирующая опасный производственный объект, на котором произошла авария',
          'Орган местного самоуправления',
        ],
        correctAnswers: [
          'Организация, эксплуатирующая опасный производственный объект, на котором произошла авария',
        ],
      },
      {
        code: '63718062',
        text:
          'Каков минимально допустимый радиус опасной зоны при взрывных работах на земной поверхности с применением наружных зарядов?',
        answers: ['100 м', '200 м', '400 м', '300 м'],
        correctAnswers: ['300 м'],
      },
      {
        code: '63718063',
        text:
          'В каких условиях следует проводить контрольные и приемочные испытания новых взрывчатых веществ и изделий на их основе для получения разрешения?',
        answers: [
          'В производственных условиях',
          'В условиях полигона',
          'В лабораторных условиях',
        ],
        correctAnswers: ['В производственных условиях'],
      },
      {
        code: '63718064',
        text: 'Как проводится сжигание взрывчатых материалов?',
        answers: [
          'Все взрывчатые материалы сжигаются в одном костре, за один прием допускается сжигать не более 45 кг',
          'Все взрывчатые материалы сжигаются раздельно, за один прием допускается сжигать не более 35 кг',
          'Все взрывчатые материалы сжигаются раздельно, за один прием допускается сжигать не более 20 кг',
          'Все взрывчатые материалы сжигаются в одном костре, за один прием допускается сжигать не более 10 кг',
        ],
        correctAnswers: [
          'Все взрывчатые материалы сжигаются раздельно, за один прием допускается сжигать не более 20 кг',
        ],
      },
      {
        code: '63718065',
        text:
          'Кто должен осуществлять расчет вреда от аварии на опасном производственном объекте?',
        answers: [
          'Организация, на объекте которой произошла авария',
          'Страховщик, с которым организация, эксплуатирующая опасный производственный объект, заключила договор обязательного страхования гражданской ответственности',
          'Орган местного самоуправления',
          'Территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['Организация, на объекте которой произошла авария'],
      },
      {
        code: '63718066',
        text:
          'Кто должен утверждать регламент технологического процесса производства и подготовки взрывчатых веществ и вносимые в него изменения?',
        answers: [
          'Руководитель (технический руководитель) эксплуатирующей организации',
          'Руководитель взрывных работ',
          'Руководитель службы вентиляции',
          'Горный мастер',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) эксплуатирующей организации',
        ],
      },
      {
        code: '63718067',
        text:
          'На какой срок допускается продлевать техническое расследование причин аварии на опасном производственном объекте?',
        answers: [
          'Не более чем на 15 календарных дней',
          'Не более чем на 15 рабочих дней',
          'Не более чем на 30 рабочих дней',
          'Не более чем на 30 календарных дней',
        ],
        correctAnswers: ['Не более чем на 15 календарных дней'],
      },
      {
        code: '63718068',
        text:
          'Какие из перечисленных смесей и взрывчатых веществ разрешается производить на пунктах производства взрывчатых веществ?',
        answers: [
          'Только смеси холодного смешения гранулированной аммиачной селитры с гранулированным (чешуированным) тротилом, жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими',
          'Только водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими',
          'Только смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63718069',
        text:
          'Кто должен утверждать маршруты транспортирования взрывчатых материалов от склада взрывчатых материалов на места работ в пределах опасного производственного объекта?',
        answers: [
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
          'Территориальный орган Ростехнадзора',
          'Руководитель взрывных работ',
          'Орган местного самоуправления, на территории которого располагается опасный производственный объект',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63718070',
        text:
          'Какое утверждение соответствует требованиям безопасности к временным поверхностным и полууглубленным складам взрывчатых материалов? Выберите два правильных варианта ответа.',
        answers: [
          'Временные склады взрывчатых материалов необходимо обустраивать противопожарными водоемами (резервуарами, скважинами)',
          'На временных складах взрывчатых материалов должны быть обустроены тамбуры',
          'Для рабочего освещения внутри хранилищ допускается использовать рудничные аккумуляторные светильники',
          'Деревянные стены и крыши следует покрывать огнезащитным составом',
        ],
        correctAnswers: [
          'Для рабочего освещения внутри хранилищ допускается использовать рудничные аккумуляторные светильники',
          'Деревянные стены и крыши следует покрывать огнезащитным составом',
        ],
      },
      {
        code: '63718071',
        text:
          'На сколько метров контур ограждения должен быть больше зоны монтажа электровзрывной сети на земной поверхности?',
        answers: ['На 50 м', 'На 30 м', 'На 75 м', 'На 15 м'],
        correctAnswers: ['На 50 м'],
      },
      {
        code: '63718072',
        text:
          'Какова продолжительность стажировки взрывника после перерыва в работе по профессии более 1 года?',
        answers: [
          '10 рабочих дней',
          '5 рабочих дней',
          '7 рабочих дней',
          '15 рабочих дней',
        ],
        correctAnswers: ['10 рабочих дней'],
      },
      {
        code: '63718073',
        text:
          'Кто должен возглавлять комиссию по техническому расследованию причин аварии на опасном производственном объекте, подконтрольном Ростехнадзору?',
        answers: [
          'Представитель Ростехнадзора или его территориального органа',
          'Представитель органа исполнительной власти субъекта Российской Федерации',
          'Представитель органа местного самоуправления, на территории которого располагается опасный производственный объект',
          'Руководитель организации, эксплуатирующей опасный производственный объект',
        ],
        correctAnswers: [
          'Представитель Ростехнадзора или его территориального органа',
        ],
      },
      {
        code: '63718074',
        text:
          'В каком случае допускается оставлять взрывчатые материалы на местах работ?',
        answers: [
          'Если организован постоянный надзор (охрана)',
          'Если закончилась смена',
          'Если места размещения взрывчатых материалов обозначены аншлагами',
          'Если места размещения взрывчатых материалов ограждены',
        ],
        correctAnswers: ['Если организован постоянный надзор (охрана)'],
      },
      {
        code: '63718075',
        text:
          'В каком документе следует фиксировать отказы зарядов при взрывных работах?',
        answers: [
          'В журнале регистрации отказов при взрывных работах',
          'В акте выполненных работ',
          'В журнале учета испытаний взрывчатых материалов',
          'В журнале осмотра технического состояния и учета работы зарядного оборудования',
        ],
        correctAnswers: ['В журнале регистрации отказов при взрывных работах'],
      },
      {
        code: '63718076',
        text:
          'При выполнении каких из перечисленных условий ответственному руководителю массового взрыва разрешается давать указание о подаче боевого сигнала?',
        answers: [
          'Только при получении донесений от ответственных за заряжание и подготовку к взрыву блоков, за охрану опасной зоны и выставление постов, а также за вывод людей с территории опасной зоны',
          'Только при условии ознакомления с заполненной таблицей параметров взрывных работ',
          'Только при условии выполнения мероприятий, перечисленных в распорядке проведения массового взрыва',
          'При выполнении всех перечисленных условий',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63718077',
        text:
          'Какова максимально допустимая температура воздуха для оттаивания взрывчатых веществ в заводской упаковке в отапливаемых помещениях поверхностных складов?',
        answers: ['30 °С', '35 °С', '45   °С', '40     °С'],
        correctAnswers: ['30 °С'],
      },
      {
        code: '63718078',
        text:
          'К какому подклассу относятся взрывчатые вещества и изделия на их основе, способные взрываться массой?',
        answers: [
          'К подклассу 1.1',
          'К подклассу 1.2',
          'К подклассу 1.3',
          'К подклассу 1.4',
        ],
        correctAnswers: ['К подклассу 1.1'],
      },
      {
        code: '63718079',
        text:
          'Какой способ ликвидации отказавших скважинных зарядов указан верно? Выберите два правильных варианта ответа.',
        answers: [
          'Взрывание заряда в скважине, пробуренной параллельно на расстоянии 1 м от скважины с отказавшим зарядом',
          'Ликвидация по специально разработанному проекту, утвержденному руководителем (техническим руководителем) организации',
          'Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура',
          'Разборка породы в месте нахождения скважины с отказавшим зарядом с извлечением последнего вручную',
        ],
        correctAnswers: [
          'Ликвидация по специально разработанному проекту, утвержденному руководителем (техническим руководителем) организации',
          'Разборка породы в месте нахождения скважины с отказавшим зарядом с извлечением последнего вручную',
        ],
      },
      {
        code: '63718080',
        text:
          'Что должен сделать взрывник, если при подаче напряжения взрыва не произошло?',
        answers: [
          'Отсоединить от источника тока электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа',
          'Отсоединить от источника тока электровзрывную сеть, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа',
          'Отсоединить от источника тока электровзрывную сеть и проверить ее электрическое сопротивление, при обнаружении неисправности принять меры к ее устранению',
          'Подать напряжение в электровзрывную сеть не менее 3 раз, при отсутствии взрыва отключить взрывной прибор, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа',
        ],
        correctAnswers: [
          'Отсоединить от источника тока электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа',
        ],
      },
      {
        code: '63718081',
        text: 'В каком случае взрывные работы разрешается проводить по схемам?',
        answers: [
          'Если проводятся разовые взрывы зарядов в шпурах для удаления навесей, выравнивания забоя',
          'Если проводятся взрывы котловых зарядов при выполнении взрывных работ на строительных объектах',
          'Если проводятся взрывы камерных зарядов при выполнении сейсморазведочных работ',
          'Если проводятся взрывы скважинных зарядов при выполнении прострелочно-взрывных работ',
        ],
        correctAnswers: [
          'Если проводятся разовые взрывы зарядов в шпурах для удаления навесей, выравнивания забоя',
        ],
      },
      {
        code: '63718082',
        text:
          'В каком случае допускается применять и хранить взрывчатые вещества и изделия на их основе с истекшим гарантийным сроком хранения?',
        answers: [
          'Если они хранятся в полуподземных хранилищах',
          'Если количество хранящихся взрывчатых веществ снизилось в 3 раза от рекомендованного',
          'Если проведены испытания, предусмотренные технической документацией',
          'Если гарантийный срок хранения истек в текущем месяце',
        ],
        correctAnswers: [
          'Если проведены испытания, предусмотренные технической документацией',
        ],
      },
      {
        code: '63718083',
        text:
          'Для каких взрывчатых веществ минимальная масса партии, необходимая для проведения приемочных испытаний, составляет 50 тонн?',
        answers: [
          'Для патронированных взрывчатых веществ, шашек и других штучных взрывчатых веществ, предназначенных для изготовления боевиков на открытых и подземных работах',
          'Для взрывчатых веществ, предназначенных для подземных работ при механизированном заряжании шпуров и скважин',
          'Для предохранительных взрывчатых веществ',
          'Для взрывчатых веществ, предназначенных для открытых работ',
        ],
        correctAnswers: [
          'Для взрывчатых веществ, предназначенных для открытых работ',
        ],
      },
      {
        code: '63718084',
        text:
          'Что из перечисленного следует прилагать к заявлению о выдаче разрешения на постоянное применение взрывчатых веществ и изделий на их основе? Выберите два правильных варианта ответа.',
        answers: [
          'Акт контрольных испытаний',
          'Программу и методику приемочных испытаний',
          'Экспертное заключение по промышленной безопасности',
          'Руководство (инструкцию) по применению',
        ],
        correctAnswers: [
          'Экспертное заключение по промышленной безопасности',
          'Руководство (инструкцию) по применению',
        ],
      },
      {
        code: '63718085',
        text:
          'Чем регламентируется порядок проведения технического расследования причин инцидентов на опасном производственном объекте?',
        answers: [
          'Документами, утвержденными организацией, эксплуатирующей опасный производственный объект',
          'Правовым актом федерального органа исполнительной власти в области промышленной безопасности',
          'Правовым актом федерального органа исполнительной власти в области охраны труда',
          'Правовым актом федерального органа исполнительной власти в области охраны недр',
        ],
        correctAnswers: [
          'Документами, утвержденными организацией, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63718086',
        text:
          'Каков максимально допустимый срок предоставления Ростехнадзором государственной услуги при выдаче (отказе в выдаче) разрешения на ведение работ со взрывчатыми материалами промышленного назначения со дня регистрации заявления?',
        answers: [
          '60 рабочих дней',
          '45 календарных дней',
          '30 рабочих дней',
          '15 календарных дней',
        ],
        correctAnswers: ['30 рабочих дней'],
      },
      {
        code: '63718087',
        text:
          'Что необходимо сделать с неиспользованными боевиками после взрывания зарядов?',
        answers: [
          'Передать боевики на другие объекты взрывания в установленном порядке',
          'Уничтожить боевики взрыванием в установленном порядке',
          'Разрядить боевики в установленном порядке',
          'Передать боевики на хранение в бронированных ящиках для последующего применения в установленном порядке',
        ],
        correctAnswers: [
          'Уничтожить боевики взрыванием в установленном порядке',
        ],
      },
      {
        code: '63718088',
        text:
          'В каком из перечисленных случаев взрывчатые материалы должны подвергаться испытаниям?',
        answers: [
          'Только в случае неудовлетворительных результатов взрывных работ',
          'Только в случае сомнений в качестве взрывчатых материалов по результатам внешнего осмотра',
          'Только в случае заканчивающегося гарантийного срока',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63718089',
        text:
          'Каков максимальный срок действия разрешения на ведение работ со взрывчатыми материалами промышленного назначения?',
        answers: ['1 год', '2 года', '6 месяцев', '3 года'],
        correctAnswers: ['1 год'],
      },
      {
        code: '63718090',
        text:
          'Сколько подклассов взрывчатых веществ выделяется согласно классификации по степени их опасности?',
        answers: ['6', '7', '8', '4'],
        correctAnswers: ['6'],
      },
      {
        code: '63718091',
        text:
          'Какова продолжительность стажировки взрывника перед допуском к самостоятельному производству взрывных работ?',
        answers: ['3 недели', '1 месяц', '3 месяца', '9 месяцев'],
        correctAnswers: ['1 месяц'],
      },
      {
        code: '63718092',
        text:
          'Как следует обозначить невзорвавшийся заряд при обнаружении отказа на земной поверхности?',
        answers: [
          'Выставлением охраны у невзорвавшегося заряда',
          'Выставлением отличительного знака у невзорвавшегося заряда',
          'Выставлением ограждения вокруг невзорвавшегося заряда',
        ],
        correctAnswers: [
          'Выставлением отличительного знака у невзорвавшегося заряда',
        ],
      },
      {
        code: '63718093',
        text:
          'Какие из перечисленных складов взрывчатых материалов относятся к кратковременным?',
        answers: [
          'Склады, эксплуатируемые до 1 года',
          'Склады, эксплуатируемые до 3 лет',
          'Склады, эксплуатируемые до 2 лет',
          'Склады, эксплуатируемые до 5 лет',
        ],
        correctAnswers: ['Склады, эксплуатируемые до 1 года'],
      },
      {
        code: '63718094',
        text:
          'Какого цвета должны быть предохранительные полосы или оболочки патронов (пачек) у предохранительных взрывчатых веществ и изделий из предохранительных взрывчатых веществ V–VI классов для ведения специальных взрывных работ?',
        answers: ['Белого', 'Желтого', 'Красного', 'Синего'],
        correctAnswers: ['Желтого'],
      },
      {
        code: '63718095',
        text: 'С какой периодичностью следует очищать места хранения селитры?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63718096',
        text:
          'В какой документ следует вносить результаты измерения сопротивлений заземлителей молниезащиты?',
        answers: [
          'В акт проведения измерений сопротивления заземлителей',
          'В сменный журнал',
          'В ведомость состояния заземлителей молниезащиты',
          'В протокол осмотра заземлителей',
        ],
        correctAnswers: ['В ведомость состояния заземлителей молниезащиты'],
      },
      {
        code: '63718097',
        text:
          'К какой группе совместимости относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие 2 или более независимых предохранительных устройства?',
        answers: ['К группе Е', 'К группе В', 'К группе С', 'К группе D'],
        correctAnswers: ['К группе D'],
      },
      {
        code: '63718098',
        text:
          'При какой температуре теплоносителя разрешается использовать воздушные сушилки для сушки взрывчатых веществ, сенсибилизированных нитроэфирами?',
        answers: [
          'При температуре не выше 40 °С',
          'При температуре не выше 75 °С',
          'При температуре не выше 55 °С',
          'При температуре не выше 30 °С',
        ],
        correctAnswers: ['При температуре не выше 30 °С'],
      },
      {
        code: '63718099',
        text:
          'Что необходимо сделать со взрывчатыми веществами при несоответствии показателей, полученных в результате испытаний, указанным в технической документации?',
        answers: [
          'Уничтожить в кратчайшие сроки',
          'Возвратить изготовителю',
          'Подвергнуть повторным испытаниям',
          'Использовать в кратчайшие сроки',
        ],
        correctAnswers: ['Уничтожить в кратчайшие сроки'],
      },
      {
        code: '63718100',
        text:
          'Какой должна быть высота ограждения погрузочно-разгрузочной площадки взрывчатых материалов, за исключением площадок, расположенных на территории складов взрывчатых материалов, в околоствольных дворах шахт, рудников, штолен?',
        answers: [
          'Не менее 2 м',
          'Не менее 1,8 м',
          'Не менее 1,75 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63718101',
        text:
          'Какое требование к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?',
        answers: [
          'Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
          'Нанесение индивидуальных индексов на электродетонаторы с помощью устройств обжимного типа должно производиться на 4,0 мм ниже нижнего зига заводской обжимки гильзы',
          'В случае появления трещин во внешней оболочке электродетонатора при нанесении индивидуальных индексов его необходимо отремонтировать',
          'Нанесение индивидуальных индексов на капсюли-детонаторы должно осуществляться после изготовления зажигательных трубок',
        ],
        correctAnswers: [
          'Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
        ],
      },
      {
        code: '63718102',
        text:
          'Что из перечисленного должно входить в работы по проверке молниезащиты складов взрывчатых материалов?',
        answers: [
          'Только наружный осмотр молниезащитных устройств',
          'Только измерение сопротивления заземлителей молниезащиты',
          'Только проверка переходного сопротивления контактов устройств защиты от вторичных воздействий молнии',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63718103',
        text:
          'Как необходимо удалять частицы на внутренней поверхности гильзы капсюля-детонатора перед изготовлением зажигательной (контрольной) трубки?',
        answers: [
          'Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца',
          'Выдуванием',
          'Пинцетом',
          'Деревянной палочкой',
        ],
        correctAnswers: [
          'Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца',
        ],
      },
      {
        code: '63718104',
        text:
          'Какое утверждение соответствует требованиям безопасности к ведению взрывных работ в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль?',
        answers: [
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки и производить взрывание в каждом из них раздельно',
          'Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания не должно превышать 320 миллисекунд при применении взрывчатых веществ IV класса',
          'Одновременное взрывание должно осуществляться только по разрешению технического руководителя объекта',
          'В этих забоях выработок разрешается применять непредохранительные электродетонаторы',
        ],
        correctAnswers: [
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки и производить взрывание в каждом из них раздельно',
        ],
      },
      {
        code: '63718105',
        text:
          'В каком случае запрещается проведение прострелочных или взрывных работ в скважинах?',
        answers: [
          'В случае ограничения видимости из-за тумана до 100 м',
          'В случае необходимости проведения работ в закрытых помещениях буровых во время грозы',
          'В случае необходимости проведения работ в сухих газирующих и поглощающих раствор скважинах с применением лубрикаторов',
          'В случае необходимости проведения работ во время пурги',
        ],
        correctAnswers: [
          'В случае необходимости проведения работ во время пурги',
        ],
      },
      {
        code: '63718106',
        text:
          'Что из перечисленного должно содержать руководство (инструкция) по применению взрывчатых веществ?',
        answers: [
          'Все перечисленное',
          'Только показатели пожаровзрывоопасности и электростатической опасности',
          'Только технические показатели, определяющие потребительские свойства взрывчатых веществ и изделий на их основе (отдельно контролируемые и неконтролируемые показатели)',
          'Только порядок действия персонала при аварийных ситуациях',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63718107',
        text:
          'При какой глубине взрывных скважин обязательно дублирование внутрискважинной сети?',
        answers: [
          'При глубине более 15 м',
          'При глубине более 5 м',
          'При глубине более 10 м',
          'При глубине более 7 м',
        ],
        correctAnswers: ['При глубине более 15 м'],
      },
      {
        code: '63718108',
        text:
          'Какое требование к устройству оборудования, предназначенного для механизации взрывных работ, указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В трактах прохождения взрывчатых веществ не должно быть крепежных деталей (болтов, шпилек, шпонок, пальцев, шплинтов)',
          'При эксплуатации оборудования температура разогрева поверхностей узлов и деталей, на которых возможно оседание пыли взрывчатых веществ, не должна превышать 90 °C',
          'Конструкция оборудования должна исключать попадание взрывчатых веществ в зазоры между трущимися и соударяющимися деталями',
          'В резьбовых соединениях в трактах прохождения взрывчатых веществ необходимо предусматривать шплинтовку или иной способ фиксирования крепежных деталей',
        ],
        correctAnswers: [
          'В трактах прохождения взрывчатых веществ не должно быть крепежных деталей (болтов, шпилек, шпонок, пальцев, шплинтов)',
          'Конструкция оборудования должна исключать попадание взрывчатых веществ в зазоры между трущимися и соударяющимися деталями',
        ],
      },
      {
        code: '63718109',
        text:
          'В течение какого срока с даты подписания акта технического расследования причин аварии руководитель организации должен издать внутренний распорядительный акт по результатам расследования?',
        answers: [
          'В течение 7 рабочих дней',
          'В течение 12 календарных дней',
          'В течение 10 рабочих дней',
          'В течение 14 календарных дней',
        ],
        correctAnswers: ['В течение 7 рабочих дней'],
      },
      {
        code: '63718110',
        text:
          'При какой температуре запрещается заряжать и взрывать заряды в шпурах при ведении взрывных работ по металлу?',
        answers: [
          'При температуре выше 200 °C',
          'При температуре выше 120 °C',
          'При температуре выше 100 °C',
          'При температуре выше 150 °C',
        ],
        correctAnswers: ['При температуре выше 200 °C'],
      },
      {
        code: '63718111',
        text:
          'Как должны размещаться взрывчатые материалы на специальных площадках при кратковременном хранении в период проведения специальных взрывных работ?',
        answers: [
          'На деревянном настиле высотой не менее 20 см от земли и под навесом или брезентовым покрытием',
          'На твердом основании, покрытом брезентовым настилом, и под брезентовым покрытием',
          'На земле под брезентовым покрытием',
        ],
        correctAnswers: [
          'На деревянном настиле высотой не менее 20 см от земли и под навесом или брезентовым покрытием',
        ],
      },
      {
        code: '63718112',
        text:
          'Кто должен утверждать типовой проект буровзрывных (взрывных) работ?',
        answers: [
          'Представитель военизированной горноспасательной части',
          'Руководитель (технический руководитель) организации, ведущей взрывные работы, или его заместитель',
          'Руководитель территориального органа Ростехнадзора',
          'Представитель технического надзора',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) организации, ведущей взрывные работы, или его заместитель',
        ],
      },
      {
        code: '63718113',
        text:
          'Какова периодичность предоставления информации о произошедших инцидентах в территориальный орган Ростехнадзора?',
        answers: [
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в 9 месяцев',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в квартал'],
      },
      {
        code: '63718114',
        text:
          'Как часто должны проверяться взрывные приборы на соответствие установленным техническим характеристикам?',
        answers: [
          'Каждый раз перед каждой выдачей взрывникам',
          'Каждый раз перед возвращением на хранение',
          'Каждый месяц',
          'Каждый квартал',
        ],
        correctAnswers: ['Каждый раз перед каждой выдачей взрывникам'],
      },
      {
        code: '63718115',
        text:
          'Какое утверждение соответствует требованиям безопасности к приему, отпуску и учету взрывчатых материалов? Выберите два правильных варианта ответа.',
        answers: [
          'Индивидуальные заводские номера изделий со взрывчатыми веществами при выдаче взрывникам должны регистрироваться в книге учета прихода и расхода взрывчатых материалов',
          'Перед выдачей взрывникам для проведения огневого взрывания электродетонаторам и капсюлям-детонаторам должны быть присвоены индивидуальные индексы и нанесены на них',
          'Доставленные на места хранения взрывчатые материалы должны быть немедленно переданы на места проведения взрывных работ и выданы взрывникам',
          'Учет находящейся на складах взрывчатых материалов аммиачной селитры во всех случаях осуществляется в том же порядке, что и для взрывчатых материалов',
        ],
        correctAnswers: [
          'Перед выдачей взрывникам для проведения огневого взрывания электродетонаторам и капсюлям-детонаторам должны быть присвоены индивидуальные индексы и нанесены на них',
          'Учет находящейся на складах взрывчатых материалов аммиачной селитры во всех случаях осуществляется в том же порядке, что и для взрывчатых материалов',
        ],
      },
      {
        code: '63718116',
        text:
          'Какое максимальное количество представителей организации, эксплуатирующей опасный производственный объект, допускается включать в комиссию по техническому расследованию причин аварии?',
        answers: [
          '30 % от общего числа членов комиссии',
          '10 % от общего числа членов комиссии',
          '20 % от общего числа членов комиссии',
          '50 % от общего числа членов комиссии',
        ],
        correctAnswers: ['30 % от общего числа членов комиссии'],
      },
      {
        code: '63718117',
        text:
          'Какое требование к углубленным складам взрывчатых материалов указано верно?',
        answers: [
          'Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу',
          'Наружная дверь выработок, ведущих к углубленному складу, должна быть деревянной, а внутренняя – металлической',
          'Если расстояние от входа в углубленный склад до ближайшей камеры хранения взрывчатых материалов составляет менее 5 м, склад должен иметь 2 выхода',
          'Перед устьем выработки, ведущей к углубленному складу, запрещается устраивать защитный вал',
        ],
        correctAnswers: [
          'Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу',
        ],
      },
      {
        code: '63718118',
        text:
          'Какое утверждение соответствует требованиям к защите технологического оборудования от статического электричества при взрывных работах?',
        answers: [
          'Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов',
          'Допускается объединение заземляющих устройств для защиты от статического электричества с защитным заземлением электрооборудования',
          'Для снижения интенсивности возникновения зарядов статического электричества необходимо увеличивать скорости транспортирования и переработки, турбулентность потоков пыле-, парогазовых смесей и жидкостей',
          'Для снижения интенсивности возникновения зарядов статического электричества необходимо поддерживать относительную влажность воздуха ниже 65 %',
        ],
        correctAnswers: [
          'Допускается объединение заземляющих устройств для защиты от статического электричества с защитным заземлением электрооборудования',
        ],
      },
      {
        code: '63718119',
        text:
          'Каким документом назначается специальная комиссия по техническому расследованию причин аварии на опасном производственном объекте, подконтрольном Ростехнадзору?',
        answers: [
          'Правовым актом Ростехнадзора или его территориального органа',
          'Внутренним распорядительным актом руководителя эксплуатирующей организации',
          'Правовым актом МЧС России',
          'Правовым актом Минприроды России',
        ],
        correctAnswers: [
          'Правовым актом Ростехнадзора или его территориального органа',
        ],
      },
      {
        code: '63718120',
        text:
          'Чем должно определяться расстояние от объектов до мест взрывания и сжигания взрывчатых веществ на площадках для испытаний или уничтожения?',
        answers: [
          'Проектом',
          'Приказом или распоряжением по предприятию',
          'Инструкцией, утвержденной руководителем взрывных работ',
          'Указанием территориальных органов Ростехнадзора',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63718121',
        text:
          'Что необходимо сделать для ликвидации отказавших камерных зарядов, если при проверке линии наименьшего сопротивления выявлена вероятность опасного разлета кусков горной массы?',
        answers: [
          'Провести разборку забойки с последующим извлечением взрывчатых веществ',
          'Провести взрыв заряда в скважине, пробуренной параллельно',
          'Провести разборку забойки с последующим вводом нового боевика и взрыванием в обычном порядке',
          'Провести вымывание заряда из камеры водой',
        ],
        correctAnswers: [
          'Провести разборку забойки с последующим извлечением взрывчатых веществ',
        ],
      },
      {
        code: '63718122',
        text:
          'При какой остаточной площади поперечного сечения токоотводы молниезащиты, поврежденные ржавчиной, должны быть заменены новыми?',
        answers: [
          'При площади менее 65 мм²',
          'При площади менее 60 мм²',
          'При площади менее 55 мм²',
          'При площади менее 50 мм²',
        ],
        correctAnswers: ['При площади менее 50 мм²'],
      },
      {
        code: '63718123',
        text:
          'Какова максимально допустимая длина выемки забоечного материала для установления направления отказавших шпуров?',
        answers: [
          '20 см от устья шпура',
          '30 см от устья   шпура',
          '10 см от устья   шпура',
          '15 см от устья   шпура',
        ],
        correctAnswers: ['20 см от устья шпура'],
      },
      {
        code: '63718124',
        text:
          'Что необходимо сделать в выработках, где будут находиться камерные заряды, перед заряжанием?',
        answers: [
          'Снять электропроводку',
          'Отключить электроснабжение',
          'Заземлить электрооборудование',
          'Применить устройства блокировки электровзрывной сети',
        ],
        correctAnswers: ['Снять электропроводку'],
      },
      {
        code: '63718125',
        text:
          'Сколько зарядов разрешается одновременно заряжать и взрывать при ведении взрывных работ по металлу, если температура в шпуре ниже 80 °C?',
        answers: [
          'Не более 5 зарядов',
          'Не более 10 зарядов',
          'Не более 3 зарядов',
          'Не более 12 зарядов',
        ],
        correctAnswers: ['Не более 5 зарядов'],
      },
      {
        code: '63718126',
        text:
          'Когда следует проводить измерение сопротивления заземлителей молниеотводов?',
        answers: [
          'Ежеквартально',
          '1 раз в год в весенний период',
          'В период наибольшего просыхания грунта',
          '2 раза в год в весенний и осенний периоды',
        ],
        correctAnswers: ['В период наибольшего просыхания грунта'],
      },
      {
        code: '63718127',
        text:
          'Какой документ должен быть выдан на взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях?',
        answers: [
          'Инструкция по применению на всех государственных языках государств – членов Таможенного союза',
          'Лицензия на применение, выданная Министерством РФ по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства – члена Таможенного союза',
        ],
        correctAnswers: [
          'Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства – члена Таможенного союза',
        ],
      },
      {
        code: '63718128',
        text: 'Что запрещается делать при применении средств инициирования?',
        answers: [
          'Расширять гнезда патронов (шашек) заводского изготовления при изготовлении боевиков из прессованных или литых взрывчатых веществ с инициированием от детонаторов',
          'Обматывать конец детонирующего шнура вокруг патрона взрывчатых веществ',
          'Завязывать узлом конец детонирующего шнура в патроне при изготовлении боевиков из патронированных взрывчатых веществ с применением детонирующего шнура',
          'Складывать не менее чем вдвое конец детонирующего шнура в патроне при изготовлении боевиков из патронированных взрывчатых веществ с применением детонирующего шнура',
          'Вводить детонатор в патрон на полную глубину и фиксировать при изготовлении боевиков из патронированных взрывчатых веществ, а иглы, используемые для образования углублений, изготавливать из материалов, не дающих искр и не корродирующих от взаимодействия со взрывчатыми веществами',
        ],
        correctAnswers: [
          'Расширять гнезда патронов (шашек) заводского изготовления при изготовлении боевиков из прессованных или литых взрывчатых веществ с инициированием от детонаторов',
        ],
      },
      {
        code: '63718129',
        text:
          'На каком расстоянии от греющих поверхностей (печей, труб, радиаторов) должны находиться столы и полки, на которых при сушке в помещении раскладываются взрывчатые вещества?',
        answers: [
          'На расстоянии не менее 1,0 м',
          'На расстоянии не менее 0,8 м',
          'На расстоянии не менее 0,6 м',
          'На расстоянии не менее 0,4 м',
        ],
        correctAnswers: ['На расстоянии не менее 1,0 м'],
      },
      {
        code: '63718130',
        text:
          'Какие из перечисленных взрывчатых веществ и изделий относятся к группе совместимости С?',
        answers: [
          'Метательные взрывчатые вещества и изделия (бездымный порох)',
          'Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов; изделия, содержащие инициирующие взрывчатые вещества и имеющие 2 или более независимых предохранительных устройства',
          'Изделия, содержащие взрывчатые вещества без средств инициирования, но с метательным зарядом (кроме содержащих легковоспламеняющуюся жидкость, гель или самовоспламеняющуюся жидкость)',
          'Изделия, содержащие взрывчатые вещества чрезвычайно низкой чувствительности',
        ],
        correctAnswers: [
          'Метательные взрывчатые вещества и изделия (бездымный порох)',
        ],
      },
      {
        code: '63718131',
        text:
          'Какова периодичность плановых выездных проверок лицензиата категории среднего риска, осуществляющего деятельность, связанную с обращением взрывчатых материалов промышленного назначения?',
        answers: [
          '1 раз в 3 года',
          '1 раз в 2 года',
          '1 раз в год',
          '1 раз в 5 лет',
        ],
        correctAnswers: ['1 раз в 3 года'],
      },
      {
        code: '63718132',
        text:
          'Каково минимально допустимое расстояние от вспомогательных шпуров до невзорвавшихся шпуровых зарядов при их ликвидации?',
        answers: ['30 см', '50 см', '60 см', '100 см'],
        correctAnswers: ['30 см'],
      },
      {
        code: '63718133',
        text: 'При каких повреждениях молниеотвод необходимо заменить?',
        answers: [
          'При покрытии наконечника молниеотвода ржавчиной',
          'При оплавлении или повреждении конического наконечника, покрытии молниеотвода ржавчиной более чем на 1/3 площади поперечного сечения',
          'При покрытии ржавчиной молниеотвода и   поверхности в соединениях на болтах',
          'При повреждении полуды, оцинковки молниеотвода',
        ],
        correctAnswers: [
          'При оплавлении или повреждении конического наконечника, покрытии молниеотвода ржавчиной более чем на 1/3 площади поперечного сечения',
        ],
      },
      {
        code: '63718134',
        text:
          'Кто имеет право получить единую книжку взрывника на право руководства любыми видами взрывных работ без дополнительного обучения? Выберите два правильных варианта ответа.',
        answers: [
          'Лица, окончившие высшие учебные заведения по направлению подготовки "нефтегазовое дело"',
          'Лица, имеющие высшее техническое образование',
          'Лица, окончившие высшие учебные заведения по направлению подготовки "физические процессы горного производства"',
          'Лица, окончившие высшие учебные заведения по направлению подготовки "технологические машины и оборудование"',
          'Лица, окончившие высшие учебные заведения по направлению подготовки "техносферная безопасность"',
        ],
        correctAnswers: [
          'Лица, окончившие высшие учебные заведения по направлению подготовки "нефтегазовое дело"',
          'Лица, окончившие высшие учебные заведения по направлению подготовки "физические процессы горного производства"',
        ],
      },
      {
        code: '63718135',
        text:
          'Кто может получить профессию взрывника, имея стаж работы в шахтах, опасных по газу и пыли?',
        answers: [
          'Лица мужского пола не моложе 16 лет, имеющие среднее профессиональное образование',
          'Лица мужского пола старше 18 лет, имеющие образование не ниже среднего (полного) общего и стаж работы на подземных работах проходчика или рабочего очистного забоя не менее 2 лет',
          'Лица, не имеющие медицинских противопоказаний и имеющие стаж работы не менее 1 года по специальности, соответствующей профилю работ организации',
          'Лица, имеющие высшее профессиональное образование (технического профиля) и стаж работы не менее 1 года по специальности',
        ],
        correctAnswers: [
          'Лица мужского пола старше 18 лет, имеющие образование не ниже среднего (полного) общего и стаж работы на подземных работах проходчика или рабочего очистного забоя не менее 2 лет',
        ],
      },
      {
        code: '63718136',
        text:
          'При какой высоте загрузочных люков смесительного оборудования, предназначенного для механизации взрывных работ, необходимо предусматривать рабочие площадки, оборудованные ограждениями и поручнями?',
        answers: [
          'При высоте более 0,5 м от уровня пола (площадки)',
          'При высоте более 1,1 м от уровня пола (площадки)',
          'При высоте более 1,3 м от уровня пола (площадки)',
          'При высоте более 1,5 м от уровня пола (площадки)',
        ],
        correctAnswers: ['При высоте более 1,5 м от уровня пола (площадки)'],
      },
      {
        code: '63718137',
        text:
          'Какие из перечисленных объектов следует защищать только от прямого удара молнии?',
        answers: [
          'Хранилища постоянных поверхностных складов взрывчатых материалов',
          'Площадки для хранения взрывчатых материалов в контейнерах',
          'Хранилища временных полууглубленных складов взрывчатых материалов',
          'Пункты изготовления боевиков с электродетонаторами',
        ],
        correctAnswers: [
          'Площадки для хранения взрывчатых материалов в контейнерах',
        ],
      },
      {
        code: '63718138',
        text:
          'Кто должен проводить заряжание и забойку при ведении взрывных работ по металлу?',
        answers: [
          '2 взрывника в присутствии руководителя взрывных работ',
          '1 взрывник',
          '2 взрывника',
          '1 взрывник в присутствии руководителя взрывных работ',
        ],
        correctAnswers: [
          '2 взрывника в присутствии руководителя взрывных работ',
        ],
      },
      {
        code: '63718139',
        text:
          'При какой температуре воздуха в помещениях для сушки взрывчатых веществ должна осуществляться сушка дымного пороха?',
        answers: [
          'При температуре не выше 40 °С',
          'При температуре не выше 75 °С',
          'При температуре не выше 55 °С',
          'При температуре не выше 60 °С',
        ],
        correctAnswers: ['При температуре не выше 40 °С'],
      },
      {
        code: '63718140',
        text:
          'Кто имеет право быть заявителем на получение государственной услуги по выдаче разрешений на постоянное применение взрывчатых веществ и изделий на их основе?',
        answers: [
          'Только юридические лица, являющиеся потребителями взрывчатых материалов, либо их законные представители',
          'Юридические лица, осуществляющие изготовление взрывчатых материалов, либо их представители, полномочия которых удостоверены в соответствии с законодательством Российской Федерации, имеющие лицензии на деятельность, связанную с обращением взрывчатых материалов промышленного    назначения, в соответствии с законодательством Российской Федерации',
          'Все перечисленные',
          'Только физические лица, являющиеся потребителями взрывчатых материалов, либо их законные представители',
        ],
        correctAnswers: [
          'Юридические лица, осуществляющие изготовление взрывчатых материалов, либо их представители, полномочия которых удостоверены в соответствии с законодательством Российской Федерации, имеющие лицензии на деятельность, связанную с обращением взрывчатых материалов промышленного    назначения, в соответствии с законодательством Российской Федерации',
        ],
      },
      {
        code: '63718141',
        text:
          'Каким документом назначается комиссия по расследованию причин инцидента на опасном производственном объекте?',
        answers: [
          'Внутренним распорядительным актом руководителя организации, эксплуатирующей объект',
          'Внутренним распорядительным актом МЧС России',
          'Внутренним распорядительным актом Ростехнадзора',
          'Внутренним распорядительным актом органа местного самоуправления, на территории которого находится производственный объект',
        ],
        correctAnswers: [
          'Внутренним распорядительным актом руководителя организации, эксплуатирующей объект',
        ],
      },
      {
        code: '63718142',
        text:
          'Что допускается сделать, если электровзрывная сеть была смонтирована до наступления грозы? Выберите два правильных варианта ответа.',
        answers: [
          'Произвести взрывание во время грозы',
          'Произвести взрывание вне зависимости от погодных условий',
          'Произвести взрывание перед грозой',
          'Произвести отсоединение участковых проводов от магистральных, концы тщательно изолировать, людей вывести за пределы опасной зоны или в укрытие',
        ],
        correctAnswers: [
          'Произвести взрывание перед грозой',
          'Произвести отсоединение участковых проводов от магистральных, концы тщательно изолировать, людей вывести за пределы опасной зоны или в укрытие',
        ],
      },
      {
        code: '63718143',
        text:
          'Что должна содержать инструкция по ликвидации отказавших зарядов взрывчатых веществ? Выберите два правильных варианта ответа.',
        answers: [
          'Величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности',
          'Методы ликвидации отказов для каждого вида взрывных работ',
          'Требования к квалификации участвующих в ликвидации неразорвавшихся зарядов',
          'Описание наименований (типов) взрывчатых веществ',
        ],
        correctAnswers: [
          'Величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности',
          'Методы ликвидации отказов для каждого вида взрывных работ',
        ],
      },
      {
        code: '63718144',
        text:
          'На каком минимально допустимом расстоянии от установленной границы опасной зоны вверх и вниз по течению реки должны выставляться оцепление и сигналы при ведении взрывных работ на реках?',
        answers: [
          'На расстоянии 500 м',
          'На расстоянии 1000 м',
          'На расстоянии 2000 м',
          'На расстоянии 1500 м',
        ],
        correctAnswers: ['На расстоянии 500 м'],
      },
      {
        code: '63718145',
        text:
          'Какие прострелочно-взрывные аппараты должны подниматься над устьем скважины и опускаться с помощью грузоподъемных механизмов? Выберите два правильных варианта ответа.',
        answers: [
          'Длиной более 2 м',
          'Массой более 50 кг',
          'Массой более 30 кг',
          'Длиной более 1,5 м',
        ],
        correctAnswers: ['Длиной более 2 м', 'Массой более 50 кг'],
      },
      {
        code: '63718146',
        text:
          'Каков минимальный срок хранения книги учета прихода и расхода взрывчатых материалов и книги учета выдачи и возврата взрывчатых материалов?',
        answers: ['3 года', '2 года', '1 год', '5 лет'],
        correctAnswers: ['3 года'],
      },
      {
        code: '63718147',
        text: 'Что должно быть включено в состав проекта буровзрывных работ?',
        answers: [
          'Таблицы параметров взрывных работ',
          'Паспорта буровзрывных работ',
          'Инструкции (руководства) по эксплуатации забоечных машин',
          'Инструкции по ликвидации отказавших зарядов взрывчатых веществ',
        ],
        correctAnswers: ['Таблицы параметров взрывных работ'],
      },
      {
        code: '63718148',
        text:
          'Где необходимо размещать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных (смесительно-зарядных, транспортно-зарядных) машин?',
        answers: [
          'За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не менее 50 м от здания подготовки и (или) производства взрывчатых веществ',
          'На территории поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не менее 50 м от здания подготовки и (или) производства взрывчатых веществ',
          'За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не менее 25 м от здания подготовки и (или) производства взрывчатых веществ',
          'На территории поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не менее 25 м от здания подготовки и (или) производства взрывчатых веществ',
        ],
        correctAnswers: [
          'За территорией поверхностных пунктов производства и подготовки взрывчатых веществ на расстоянии не менее 50 м от здания подготовки и (или) производства взрывчатых веществ',
        ],
      },
      {
        code: '63718149',
        text:
          'Как оформляется передача взрывчатых материалов в организации с одного склада на другой?',
        answers: [
          'Нарядом-накладной в 2 экземплярах',
          'Нарядом-путевкой в 2 экземплярах',
          'Распоряжением руководителя взрывных работ',
          'Распоряжением заведующего складом взрывчатых материалов',
        ],
        correctAnswers: ['Нарядом-накладной в 2 экземплярах'],
      },
      {
        code: '63718150',
        text:
          'Каково минимально допустимое расстояние от сейфа со взрывчатыми веществами до сейфа со средствами инициирования при организации их хранения в научных, исследовательских и образовательных организациях?',
        answers: ['2 м', '1 м', '3 м', '5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63718151',
        text:
          'Какие взрывчатые материалы допускается хранить совместно? Выберите два правильных варианта ответа.',
        answers: [
          'Детонирующий шнур групп совместимости D и S со средствами инициирования групп совместимости B и S',
          'Средства инициирования групп совместимости S и B',
          'Кумулятивные заряды групп совместимости S и A',
          'Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом',
        ],
        correctAnswers: [
          'Детонирующий шнур групп совместимости D и S со средствами инициирования групп совместимости B и S',
          'Средства инициирования групп совместимости S и B',
        ],
      },
      {
        code: '63718152',
        text:
          'При каких испытаниях взрывчатых веществ и изделий на их основе в состав комиссии должна быть включена экспертная организация?',
        answers: [
          'Только при приемочных испытаниях',
          'Только при контрольных испытаниях',
          'При контрольных и приемочных испытаниях',
        ],
        correctAnswers: ['При контрольных и приемочных испытаниях'],
      },
      {
        code: '63718153',
        text:
          'Что из перечисленного должна включать маркировка упаковки взрывчатых веществ и изделий на их основе, а также маркировка изделий на основе взрывчатых веществ?',
        answers: [
          'Все перечисленное',
          'Только наименование (условное обозначение) взрывчатого вещества или изделия',
          'Только обозначение соответствия транспортной тары по механической прочности',
          'Только информацию о подтверждении соответствия продукции требованиям технического регламента',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63718154',
        text:
          'В какое максимальное количество рядов по ширине полки стеллажа допускается ставить ящики со взрывчатыми материалами?',
        answers: ['В 2 ряда', 'В 3 ряда', 'В 4 ряда', 'В 5 рядов'],
        correctAnswers: ['В 2 ряда'],
      },
      {
        code: '63718155',
        text:
          'В какой срок после получения оперативного сообщения об утрате взрывчатых материалов промышленного назначения должна быть сформирована комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов?',
        answers: [
          'В срок не позднее 24 часов',
          'В срок не позднее 36 часов',
          'В срок не позднее 48 часов',
          'В срок не позднее 72 часов',
        ],
        correctAnswers: ['В срок не позднее 24 часов'],
      },
      {
        code: '63718156',
        text:
          'Как должен быть оповещен руководитель организации о месте и времени производства взрывных работ при попадании объектов организации в опасную зону?',
        answers: [
          'Письменно не менее чем за сутки',
          'Устно или сообщением по электронной почте не менее чем за 20 часов',
          'Посредством телефонной связи не менее чем за 8 часов',
          'Любым возможным способом менее чем за 12 часов',
        ],
        correctAnswers: ['Письменно не менее чем за сутки'],
      },
      {
        code: '63718157',
        text:
          'Как должно быть организовано временное хранение пришедших в негодность и бракованных взрывчатых веществ и изделий на их основе на складах?',
        answers: [
          'В отдельном помещении, запирающемся на замок, с присвоением соответствующей категории',
          'В отдельном месте, обозначенном предупредительной надписью: "ВНИМАНИЕ БРАК"',
          'В отдельном месте с металлическим ограждением',
          'В отдельном месте, обозначенном предупредительными огнями в виде светильников красного цвета',
        ],
        correctAnswers: [
          'В отдельном месте, обозначенном предупредительной надписью: "ВНИМАНИЕ БРАК"',
        ],
      },
      {
        code: '63718158',
        text:
          'Какие из перечисленных труб или кабелей допускается прокладывать в производственных зданиях (помещениях), где ведутся работы с окислителями или их растворами?',
        answers: [
          'Пластмассовые импульсные трубы',
          'Медные импульсные и командные трубы',
          'Бронированные кабели с оцинкованной броней',
          'Бронированные кабели с открытой свинцовой оболочкой',
        ],
        correctAnswers: ['Пластмассовые импульсные трубы'],
      },
      {
        code: '63718159',
        text:
          'Какое утверждение соответствует требованиям безопасности к хранению взрывчатых материалов?',
        answers: [
          'На складах взрывчатых материалов с их круглосуточной выдачей и приемкой взрывчатых материалов и постоянным дежурством раздатчиков опломбирование или опечатывание хранилищ может не проводиться',
          'Взрывчатые материалы на складах должны размещаться на стеллажах или настилах (поддонах) в распакованной заводской таре в незакрытом виде',
          'Разрешается совместное (в одном сейфе) хранение вновь изготовленных взрывчатых материалов со взрывчатыми материалами, на которые имеются разрешения',
          'Дверь в помещения с сейфами для хранения взрывчатых материалов должна быть изготовлена из материала с пределом огнестойкости не менее 30 минут',
        ],
        correctAnswers: [
          'На складах взрывчатых материалов с их круглосуточной выдачей и приемкой взрывчатых материалов и постоянным дежурством раздатчиков опломбирование или опечатывание хранилищ может не проводиться',
        ],
      },
      {
        code: '63718160',
        text:
          'С какой периодичностью проводится измерение параметров электризации пунктов производства и механизированной подготовки к применению взрывчатых веществ в условиях производства?',
        answers: [
          'Не реже 2 раз в год',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
        ],
        correctAnswers: ['Не реже 2 раз в год'],
      },
      {
        code: '63718161',
        text:
          'На каком расстоянии от стен и пола следует располагать стеллажи для взрывчатых веществ и средств инициирования на складах взрывчатых материалов?',
        answers: [
          'На расстоянии не менее 20 см от стен и не менее 10 см от пола',
          'На расстоянии не менее 18 см от стен и не менее 8 см от пола',
          'На расстоянии не менее 15 см от стен и не менее 5 см от пола',
          'На расстоянии не менее 10 см от стен и не менее 1 см от пола',
        ],
        correctAnswers: [
          'На расстоянии не менее 20 см от стен и не менее 10 см от пола',
        ],
      },
      {
        code: '63718162',
        text:
          'Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте? Выберите два правильных варианта ответа.',
        answers: [
          'Информацию о страховщике ответственности организации за причинение материального ущерба, в том числе вреда, нанесенного окружающей среде',
          'Информацию о продолжительности простоя',
          'Информацию о лицах, ответственных за соблюдение требований промышленной безопасности на опасном производственном объекте',
          'Информацию о мерах по устранению причин инцидента',
        ],
        correctAnswers: [
          'Информацию о продолжительности простоя',
          'Информацию о мерах по устранению причин инцидента',
        ],
      },
      {
        code: '63718163',
        text:
          'Что из перечисленного необходимо приложить к заявлению на выдачу разрешения на ведение работ со взрывчатыми материалами промышленного назначения на земной поверхности?',
        answers: [
          'Справку об отсутствии на участке проведения работ сельскохозяйственных угодий и особо охраняемых природных территорий, заверенную территориальным уполномоченным органом',
          'План местности с нанесением мест производства взрывных работ, границ опасной зоны и находящихся в ее пределах жилых и производственных зданий, сооружений, железных и шоссейных дорог, трубопроводов, линий электропередачи',
          'Схемы профилей работ, типовую схему охраны опасной зоны',
          'Справку об отсутствии на участке проведения работ объектов военного назначения, заверенную территориальным уполномоченным органом',
        ],
        correctAnswers: [
          'План местности с нанесением мест производства взрывных работ, границ опасной зоны и находящихся в ее пределах жилых и производственных зданий, сооружений, железных и шоссейных дорог, трубопроводов, линий электропередачи',
        ],
      },
      {
        code: '63718164',
        text:
          'Что из перечисленного должно располагаться за запретной зоной поверхностного склада?',
        answers: [
          'Административно-бытовое помещение',
          'Сарай для хранения отстрелянных модулей перфораторов',
          'Контейнер для хранения комплектующих модулей перфораторов',
          'Стеллажи или навес для хранения тары',
        ],
        correctAnswers: ['Административно-бытовое помещение'],
      },
      {
        code: '63718165',
        text:
          'Какое утверждение соответствует требованиям безопасности к хранилищам поверхностных и полууглубленных складов взрывчатых материалов? Выберите два правильных варианта ответа.',
        answers: [
          'При устройстве деревянных полов в помещениях для хранения взрывчатых материалов щели должны быть не более 5 мм',
          'Каждое хранилище взрывчатых материалов должно проветриваться и защищаться от проникновения осадков',
          'В местностях с сухим климатом допускается возводить глинобитные хранилища',
          'При устройстве каркасно-засыпных стен и перегородок применение шлака в качестве засыпки запрещается',
        ],
        correctAnswers: [
          'Каждое хранилище взрывчатых материалов должно проветриваться и защищаться от проникновения осадков',
          'В местностях с сухим климатом допускается возводить глинобитные хранилища',
        ],
      },
      {
        code: '63718166',
        text:
          'В течение какого времени материалы технического расследования аварии должны быть направлены территориальным органом Ростехнадзора в центральный аппарат?',
        answers: [
          'В течение 2 недель',
          'В течение 1 недели',
          'В течение 20 дней',
          'В течение 2 месяцев',
        ],
        correctAnswers: ['В течение 2 недель'],
      },
      {
        code: '63718167',
        text:
          'Какие взрывчатые вещества следует использовать при разупрочнении труднообрушаемых пород любой крепости?',
        answers: [
          'Вещества, не содержащие сенсибилизаторов более чувствительных, чем тротил',
          'Вещества, содержащие в своем составе гексоген',
          'Любые взрывчатые вещества',
          'Вещества, содержащие в своем составе нитроэфиры',
        ],
        correctAnswers: [
          'Вещества, не содержащие сенсибилизаторов более чувствительных, чем тротил',
        ],
      },
      {
        code: '63718168',
        text:
          'В каком из перечисленных случаев транспортные пути для перевозки взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 15 м от зданий?',
        answers: [
          'Если пути предназначены для подъезда к этим зданиям',
          'Если в этих зданиях изготавливаются или перерабатываются взрывчатые вещества',
          'Если в этих зданиях имеются открытые огневые топки и источники открытого огня',
          'Если в этих зданиях расположены хранилища горючих и легковоспламеняющихся веществ',
        ],
        correctAnswers: [
          'Если в этих зданиях изготавливаются или перерабатываются взрывчатые вещества',
        ],
      },
      {
        code: '63718169',
        text:
          'Каким документом определяются меры безопасности, а также место и порядок погрузки-выгрузки взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и в надшахтных зданиях?',
        answers: [
          'Распорядительным документом шахты (рудника)',
          'Приказом Ростехнадзора',
          'Договором на оказание охранных услуг',
          'Паспортом или проектом буровзрывных (взрывных) работ',
        ],
        correctAnswers: ['Распорядительным документом шахты (рудника)'],
      },
      {
        code: '63718170',
        text:
          'На каком расстоянии от места погрузки (выгрузки) транспортных средств, перевозящих взрывчатые материалы, должна ограждаться погрузочно-разгрузочная площадка, за исключением площадок, расположенных на территории складов взрывчатых материалов, в околоствольных дворах шахт, рудников, штолен?',
        answers: [
          'На расстоянии не менее 15 м',
          'На расстоянии не менее 12 м',
          'На расстоянии не менее 9 м',
          'На расстоянии не менее 7 м',
        ],
        correctAnswers: ['На расстоянии не менее 15 м'],
      },
      {
        code: '63718171',
        text:
          'Что разрешается сделать в случае, когда работы по ликвидации отказа не могут быть закончены в данной смене?',
        answers: [
          'Поручить их продолжение взрывнику очередной смены с соответствующим инструктажем и отметкой в наряде-путевке',
          'Поручить их продолжение только взрывнику данной смены до окончательного выполнения',
          'Поручить их продолжение взрывнику данной смены совместно со взрывником очередной смены до окончательного выполнения',
          'Поручить их продолжение в смену выполнявшего работы взрывника с переносом на другой день',
        ],
        correctAnswers: [
          'Поручить их продолжение взрывнику очередной смены с соответствующим инструктажем и отметкой в наряде-путевке',
        ],
      },
      {
        code: '63718172',
        text:
          'Какие взрывчатые вещества не допускаются к применению по результатам испытаний на чувствительность к удару и трению?',
        answers: [
          'Взрывчатые вещества с нижним пределом чувствительности к удару менее 100 мм, к трению – менее 200 МПа',
          'Взрывчатые вещества с нижним пределом чувствительности к удару менее 125 мм, к трению – менее 225 МПа',
          'Взрывчатые вещества с нижним пределом чувствительности к удару менее 175 мм, к трению – менее 300 МПа',
          'Взрывчатые вещества с нижним пределом чувствительности к удару менее 200 мм, к трению – менее 375 МПа',
        ],
        correctAnswers: [
          'Взрывчатые вещества с нижним пределом чувствительности к удару менее 100 мм, к трению – менее 200 МПа',
        ],
      },
      {
        code: '63718173',
        text: 'Как допускается проводить электрическое взрывание?',
        answers: [
          'От силовой сети',
          'От осветительной сети',
          'От взрывных приборов (машинок)',
          'От контактной сети',
        ],
        correctAnswers: ['От взрывных приборов (машинок)'],
      },
      {
        code: '63718174',
        text:
          'Какова максимально допустимая скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?',
        answers: ['15 км/ч', '25 км/ч', '20 км/ч', '30 км/ч'],
        correctAnswers: ['15 км/ч'],
      },
      {
        code: '63718175',
        text:
          'Как часто необходимо осматривать помещения, в которых проводится обработка металлов (с составлением акта) и которые рассчитаны на взрыв максимально допустимого заряда?',
        answers: ['Ежегодно', 'Еженедельно', 'Ежеквартально', 'Ежемесячно'],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63718176',
        text:
          'К каким складам относятся склады с толщиной грунта над хранилищем более 15 м?',
        answers: ['К подземным', 'К углубленным', 'К полууглубленным'],
        correctAnswers: ['К подземным'],
      },
      {
        code: '63718177',
        text:
          'Через какое время взрывнику разрешается подойти к месту взрыва, если при инициировании неэлектрических систем инициирования электронными детонаторами взрыва не произошло?',
        answers: [
          'Не ранее чем через 15 минут',
          'Не ранее чем через 5 минут',
          'Не ранее чем через 7 минут',
          'Не ранее чем через 10 минут',
        ],
        correctAnswers: ['Не ранее чем через 15 минут'],
      },
      {
        code: '63718178',
        text:
          'Какие объекты необходимо защищать от прямых ударов молний и от вторичных воздействий?',
        answers: [
          'Площадки для хранения взрывчатых материалов в контейнерах',
          'Кратковременные склады взрывчатых материалов',
          'Пункты изготовления боевиков с электродетонаторами',
          'Пункты отстоя транспортных средств со взрывчатыми материалами',
        ],
        correctAnswers: ['Пункты изготовления боевиков с электродетонаторами'],
      },
      {
        code: '63718179',
        text:
          'Каково минимально допустимое исполнение кожухов коммутационных аппаратов электродвигателей и аккумуляторных батарей на самоходном шасси по степени защиты от воздействия окружающей среды?',
        answers: ['IP54', 'IP46', 'IP65', 'IP37'],
        correctAnswers: ['IP54'],
      },
      {
        code: '63718180',
        text:
          'Какое утверждение об уничтожении взрывчатых материалов соответствует требованиям безопасности? Выберите два правильных варианта ответа.',
        answers: [
          'Запрещается подход к месту сжигания до полного прекращения горения костра со взрывчатыми материалами',
          'Взрывчатые материалы допускается сжигать в их таре',
          'Допускается сжигать взрывчатые материалы с находящимися в них средствами инициирования',
          'Допускается растворять в воде взрывчатые вещества, содержащие гексоген',
          'Тару со следами экссудата следует уничтожать сжиганием отдельно от взрывчатых материалов',
        ],
        correctAnswers: [
          'Запрещается подход к месту сжигания до полного прекращения горения костра со взрывчатыми материалами',
          'Тару со следами экссудата следует уничтожать сжиганием отдельно от взрывчатых материалов',
        ],
      },
      {
        code: '63718181',
        text:
          'Через какое время после производства массового взрыва разрешается допуск работников на рабочие места?',
        answers: [
          'Не ранее чем через 30 минут',
          'Не ранее чем через 10 минут',
          'Не ранее чем через 20 минут',
          'Не ранее чем через 15 минут',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63718182',
        text:
          'При каких кабелях защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения обеспечивается присоединением металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?',
        answers: [
          'При бронированных кабелях, проложенных в земле',
          'При небронированных кабелях',
          'При кабелях, присоединенных к воздушной линии',
        ],
        correctAnswers: ['При бронированных кабелях, проложенных в земле'],
      },
      {
        code: '63718183',
        text:
          'Какое требование к хранилищам, имеющим рампы и средства механизации погрузочно-разгрузочных работ, указано верно?',
        answers: [
          'Устройство тамбуров в них не является обязательным, но обе двери подлежат установке',
          'Необходимо оборудовать в них не менее 1 тамбура',
          'Вход через тамбур в них следует оборудовать не менее чем 2 двустворчатыми дверями, открывающимися наружу',
          'Тамбур в них должен иметь размер 2 x 2 м и сооружаться из несгораемых материалов',
        ],
        correctAnswers: [
          'Устройство тамбуров в них не является обязательным, но обе двери подлежат установке',
        ],
      },
      {
        code: '63718184',
        text:
          'Каков максимальный срок действия сертификата соответствия взрывчатых веществ?',
        answers: ['2 года', '3 года', '4 года', '5 лет'],
        correctAnswers: ['3 года'],
      },
      {
        code: '63718185',
        text:
          'Какое требование к характеристикам электродетонаторов указано верно?',
        answers: [
          'Значение безопасного тока должно быть не менее 0,18 А',
          'Безопасный импульс воспламенения должен быть не менее 0,22 А² × мс',
          'Длительный воспламеняющий ток должен быть не менее 0,16 А',
        ],
        correctAnswers: [
          'Значение безопасного тока должно быть не менее 0,18 А',
        ],
      },
      {
        code: '63718186',
        text:
          'Какова периодичность проверки знания требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими единую книжку взрывника, специальной комиссией организации под председательством представителя территориального органа Ростехнадзора, за исключением заведующих складами взрывчатых материалов, пунктами производства взрывчатых материалов и руководителей взрывных работ?',
        answers: [
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 4 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 2 года'],
      },
      {
        code: '63718187',
        text: 'Что должен содержать паспорт взрывных работ?',
        answers: [
          'Наименования взрывчатых материалов',
          'Информацию о составе бригады взрывников, требования к их квалификации',
          'Перечень необходимых при выполнении взрывных работ средств индивидуальной защиты работников',
          'Перечень техники, необходимой для производства взрывных работ',
        ],
        correctAnswers: ['Наименования взрывчатых материалов'],
      },
      {
        code: '63718188',
        text:
          'Какие помещения разрешается устраивать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите два правильных варианта ответа.',
        answers: [
          'Слесарные мастерские для мелкого текущего ремонта',
          'Помещения временного пребывания дежурных слесарей и электриков',
          'Слесарные мастерские с возможностью применения сварочного оборудования',
          'Подсобные помещения для временного хранения тары из-под взрывчатых веществ и окислителей',
        ],
        correctAnswers: [
          'Слесарные мастерские для мелкого текущего ремонта',
          'Помещения временного пребывания дежурных слесарей и электриков',
        ],
      },
      {
        code: '63718189',
        text:
          'Кому разрешается допускать людей к месту взрыва после его проведения?',
        answers: [
          'Осуществляющему руководство взрывными работами или по его поручению взрывнику',
          'Специалисту военизированной горноспасательной части',
          'Руководителю подразделения организации, в которой производились взрывные работы',
          'Диспетчеру карьера',
        ],
        correctAnswers: [
          'Осуществляющему руководство взрывными работами или по его поручению взрывнику',
        ],
      },
      {
        code: '63718190',
        text: 'Какие сигналы необходимо подавать по окончании взрывных работ?',
        answers: [
          '1 продолжительный',
          '2 коротких',
          '2 продолжительных',
          '3 коротких',
        ],
        correctAnswers: ['3 коротких'],
      },
      {
        code: '63718191',
        text:
          'Какими должны быть ширина по верху и глубина канавы, оборудованной вокруг территории склада взрывчатых материалов на расстоянии 10 м от его ограды для предохранения от лесных и напольных пожаров?',
        answers: [
          'Ширина по верху не менее 1,5 м и глубина не менее 0,5 м',
          'Ширина по верху не менее 1,25 м и глубина не менее 0,75 м',
          'Ширина по верху не менее 1,2 м и глубина не менее 0,5 м',
          'Ширина по верху не менее 1,25 м и глубина не менее 0,45 м',
        ],
        correctAnswers: [
          'Ширина по верху не менее 1,5 м и глубина не менее 0,5 м',
        ],
      },
      {
        code: '63718192',
        text:
          'Каково минимальное количество членов комиссии по техническому расследованию причин инцидентов на опасном производственном объекте?',
        answers: ['5 человек', '7 человек', '3 человека', '6 человек'],
        correctAnswers: ['3 человека'],
      },
      {
        code: '63718193',
        text:
          'На каком расстоянии от места нахождения взрывчатых материалов запрещается применять открытый огонь и курить?',
        answers: [
          'На расстоянии менее 100 м',
          'На расстоянии менее 90 м',
          'На расстоянии менее 75 м',
          'На расстоянии менее 50 м',
        ],
        correctAnswers: ['На расстоянии менее 100 м'],
      },
      {
        code: '63718194',
        text: 'Что является основанием для проведения взрывных работ?',
        answers: [
          'Распоряжение представителя технического надзора',
          'Запись в сменном журнале',
          'Указание технического руководителя организации',
          'Письменный наряд (задание на выполнение работ) с ознакомлением с ним работника под подпись и соответствующие наряды-путевки',
        ],
        correctAnswers: [
          'Письменный наряд (задание на выполнение работ) с ознакомлением с ним работника под подпись и соответствующие наряды-путевки',
        ],
      },
      {
        code: '63718195',
        text:
          'Какую информацию должен содержать отчет о произошедших инцидентах, направляемый в территориальный орган Ростехнадзора? Выберите два правильных варианта ответа.',
        answers: [
          'Информацию о принятых мерах по устранению причин возникновения инцидентов',
          'Информацию о характере инцидентов',
          'Информацию о продолжительности простоя и материальном ущербе',
          'Информацию о лицах, ответственных за инцидент',
        ],
        correctAnswers: [
          'Информацию о принятых мерах по устранению причин возникновения инцидентов',
          'Информацию о характере инцидентов',
        ],
      },
      {
        code: '63718196',
        text:
          'Когда после массового взрыва ответственному руководителю разрешается организовать осмотр взорванных блоков с принятием мер, предотвращающих отравление проверяющих работников газами?',
        answers: [
          'Не ранее чем через 3 минуты',
          'Не ранее чем через 6 минут',
          'Не ранее чем через 12 минут',
          'Не ранее чем через 15 минут',
        ],
        correctAnswers: ['Не ранее чем через 15 минут'],
      },
      {
        code: '63718197',
        text: 'Кто должен переносить средства инициирования?',
        answers: [
          'Взрывник',
          'Руководитель взрывных работ',
          'Проинструктированный рабочий под наблюдением взрывника',
        ],
        correctAnswers: ['Взрывник'],
      },
      {
        code: '63718198',
        text:
          'Какова максимально допустимая частота вращения вертикально расположенного шнек-винта питателя диаметром более 200 мм для перемещения гранулированных взрывчатых веществ?',
        answers: ['90 об/мин', '120 об/мин', '150 об/мин', '200 об/мин'],
        correctAnswers: ['150 об/мин'],
      },
      {
        code: '63718199',
        text:
          'Что из перечисленного должно быть указано в разрешении на постоянное применение взрывчатых веществ и изделий на их основе?',
        answers: [
          'Сведения о лицензии',
          'Сведения о маркировке',
          'Сведения об изготовителе',
        ],
        correctAnswers: ['Сведения об изготовителе'],
      },
      {
        code: '63718200',
        text:
          'Какие металлы разрешается использовать для деталей и узлов, контактирующих со взрывчатыми веществами в составе оборудования, предназначенного для механизации взрывных работ? Выберите два правильных варианта ответа.',
        answers: ['Сплавы меди', 'Свинец', 'Хромоникелевые стали', 'Алюминий'],
        correctAnswers: ['Хромоникелевые стали', 'Алюминий'],
      },
      {
        code: '63718201',
        text:
          'В каком из перечисленных мест допускается хранить взрывчатые материалы?',
        answers: [
          'В тамбуре хранилища',
          'В контейнере на открытой площадке',
          'В здании подготовки взрывчатых материалов',
          'В здании выдачи взрывчатых материалов',
        ],
        correctAnswers: ['В контейнере на открытой площадке'],
      },
      {
        code: '63718202',
        text:
          'На что из перечисленного должно быть направлено техническое расследование случаев утраты взрывчатых материалов промышленного назначения? Выберите два правильных варианта ответа.',
        answers: [
          'На определение мероприятий по недопущению подобных случаев в дальнейшем',
          'На установление обстоятельств утраты взрывчатых материалов промышленного назначения',
          'На выявление лиц, по вине которых произошла утрата',
          'На определение степени ответственности лиц, по вине которых произошла утрата',
        ],
        correctAnswers: [
          'На определение мероприятий по недопущению подобных случаев в дальнейшем',
          'На установление обстоятельств утраты взрывчатых материалов промышленного назначения',
        ],
      },
      {
        code: '63718203',
        text:
          'Какое максимально допустимое количество электродетонаторов разрешается располагать на рабочем столе проверяющего при их проверке по электрическому сопротивлению?',
        answers: ['100', '70', '150', '50'],
        correctAnswers: ['100'],
      },
      {
        code: '63718204',
        text: 'Что допускается при ведении взрывных работ?',
        answers: [
          'Переломы выходящих из зарядов концов детонирующего шнура',
          'Взрывание зарядов без забойки на рудниках, опасных по газу и пыли',
          'Выдергивание детонирующего шнура, введенного в боевик',
          'Размещение забойки в шпурах с помощью забоечных машин',
        ],
        correctAnswers: [
          'Размещение забойки в шпурах с помощью забоечных машин',
        ],
      },
      {
        code: '63718205',
        text:
          'Как должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления?',
        answers: [
          '1 поперечной полосой шириной 15 мм красного цвета',
          '1 поперечной полосой шириной 15 мм желтого цвета',
          '1 поперечной полосой шириной 15 мм синего цвета',
          '2 поперечными полосами шириной 15 мм зеленого цвета',
        ],
        correctAnswers: ['1 поперечной полосой шириной 15 мм красного цвета'],
      },
      {
        code: '63718206',
        text:
          'Кому взрывник лично должен сдавать остатки взрывчатых материалов по окончании работ?',
        answers: [
          'Ответственному за выдачу и приемку взрывчатых материалов',
          'Ответственному руководителю взрыва',
          'Ответственному за монтаж взрывной сети',
        ],
        correctAnswers: [
          'Ответственному за выдачу и приемку взрывчатых материалов',
        ],
      },
      {
        code: '63718207',
        text:
          'Каков допустимый срок размещения зарядных машин, загруженных взрывчатыми веществами, на специально выделенной площадке территории склада взрывчатых материалов?',
        answers: [
          'Не более 4 суток',
          'Не более 2 суток',
          'Не более 3 суток',
          'Не более 5 суток',
        ],
        correctAnswers: ['Не более 2 суток'],
      },
      {
        code: '63718208',
        text:
          'Что является основанием для разработки паспортов и проектов буровзрывных (взрывных) работ, выполняемых в конкретных условиях?',
        answers: [
          'Лицензия на применение и хранение взрывчатых материалов промышленного назначения',
          'Типовой проект буровзрывных (взрывных) работ',
          'Таблица параметров взрывных работ',
          'Технический расчет со схемой расположения скважин и графическими материалами',
          'Проект массовых взрывов',
        ],
        correctAnswers: ['Типовой проект буровзрывных (взрывных) работ'],
      },
      {
        code: '63718209',
        text:
          'Какие из перечисленных постоянных складов взрывчатых материалов допускается не оборудовать молниезащитой?',
        answers: [
          "Поверхностные, расположенные выше 66°33' северной широты",
          "Углубленные, расположенные ниже 66°33' северной широты",
          'Полууглубленные, расположенные вне населенного пункта',
          'Подземные, расположенные в зоне с низкой грозовой активностью',
        ],
        correctAnswers: [
          "Поверхностные, расположенные выше 66°33' северной широты",
        ],
      },
      {
        code: '63718210',
        text:
          'Каким федеральным органом исполнительной власти осуществляется лицензирование деятельности, связанной с обращением взрывчатых материалов промышленного назначения?',
        answers: [
          'Ростехнадзором',
          'МЧС России',
          'Роспотребнадзором',
          'Минприроды России',
        ],
        correctAnswers: ['Ростехнадзором'],
      },
      {
        code: '63718211',
        text:
          'Какого цвета должны быть предохранительные полосы или оболочки патронов (пачек) у непредохранительных взрывчатых веществ для взрывания только на земной поверхности?',
        answers: ['Красного', 'Белого', 'Синего', 'Зеленого'],
        correctAnswers: ['Белого'],
      },
      {
        code: '63718212',
        text:
          'Какова допустимая загрузка транспортного средства при совместном транспортировании в пределах опасного производственного объекта взрывчатых веществ, средств инициирования и прострелочно-взрывной аппаратуры?',
        answers: [
          'Не более 2/3 его грузоподъемности',
          'Не более 3/4 его грузоподъемности',
          'Не более 4/5 его грузоподъемности',
          'Не более его номинальной грузоподъемности',
        ],
        correctAnswers: ['Не более 2/3 его грузоподъемности'],
      },
      {
        code: '63718213',
        text: 'Что следует указывать в схеме проведения взрывных работ?',
        answers: [
          'Устройства для контроля выполнения работ',
          'Массу и конструкцию зарядов',
          'Количественный состав бригады взрывников',
          'Требования к квалификации взрывников',
        ],
        correctAnswers: ['Массу и конструкцию зарядов'],
      },
      {
        code: '63718214',
        text:
          'Какова максимально допустимая степень наполнения емкостей для горючих легковоспламеняющихся жидкостей и растворов окислителей?',
        answers: [
          '90 % вместимости',
          '95 % вместимости',
          '85 % вместимости',
          '80 % вместимости',
        ],
        correctAnswers: ['90 % вместимости'],
      },
      {
        code: '63718215',
        text:
          'Что из перечисленного допускается использовать на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов?',
        answers: [
          'Металлические сейфы, изготовленные из металлических листов толщиной 1 мм',
          'Шахтные вагонетки, оборудованные металлическими крышками',
          'Деревянные шкафы (ящики)',
          'Пластиковые контейнеры, оборудованные металлическими крышками',
          'Металлические стеллажи, изготовленные из металлических листов толщиной не менее 2 мм',
        ],
        correctAnswers: [
          'Шахтные вагонетки, оборудованные металлическими крышками',
        ],
      },
      {
        code: '63718216',
        text:
          'Какие из перечисленных мероприятий необходимо проводить при производстве взрывных работ в сульфидсодержащих рудах?',
        answers: [
          'Только мероприятия по обеспечению безопасности персонала',
          'Только мероприятия по предупреждению отравления людей пылью взрывчатых веществ и ядовитыми продуктами взрывов',
          'Только мероприятия по предупреждению загораний и взрывов сульфидной пыли',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63718217',
        text:
          'Какова максимально допустимая суммарная загрузка здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?',
        answers: ['60 т', '70 т', '40 т', '50 т'],
        correctAnswers: ['60 т'],
      },
      {
        code: '63718218',
        text:
          'Какова минимально допустимая ширина запретной зоны от ограды поверхностных постоянных складов взрывчатых материалов?',
        answers: ['50 м', '30 м', '20 м', '70 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63718219',
        text:
          'Какая максимально допустимая концентрация вредного вещества в воздухе рабочей зоны при работе оборудования, предназначенного для механизации взрывных работ, указана верно?',
        answers: [
          '1,0 мг/м³ тротила',
          '2 мг/м³ алюминиевой пудры',
          '5 мг/м³ аммиачной селитры',
          '150 мг/м³ дизельного топлива',
        ],
        correctAnswers: ['2 мг/м³ алюминиевой пудры'],
      },
      {
        code: '63718220',
        text:
          'Какие случаи утраты взрывчатых материалов промышленного назначения, произошедшие в организациях и на объектах, подлежат техническому расследованию и учету?',
        answers: [
          'Только утраты в результате пожаров',
          'Только утраты в результате стихийных бедствий',
          'Только утраты в результате промышленных аварий',
          'Все случаи утраты взрывчатых материалов',
        ],
        correctAnswers: ['Все случаи утраты взрывчатых материалов'],
      },
      {
        code: '63718221',
        text:
          'На каком расстоянии от места погрузки (выгрузки) взрывчатых материалов на погрузочно-разгрузочной площадке должно размещаться караульное помещение с телефонной связью, за исключением площадок, расположенных на территории складов взрывчатых материалов, в околоствольных дворах шахт, рудников, штолен?',
        answers: [
          'На расстоянии не более 50 м',
          'На расстоянии не более 90 м',
          'На расстоянии не более 60 м',
          'На расстоянии не более 75 м',
        ],
        correctAnswers: ['На расстоянии не более 50 м'],
      },
      {
        code: '63718222',
        text:
          'Какие из перечисленных веществ относятся к группе совместимости D взрывчатых веществ и изделий на их основе?',
        answers: [
          'Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов',
          'Метательные взрывчатые вещества и изделия (бездымный порох)',
          'Изделия, содержащие взрывчатые вещества без средств инициирования, но с метательным зарядом',
          'Взрывчатые вещества или изделия, упакованные или сконструированные так, что при случайном срабатывании любое опасное проявление ограничено самой упаковкой, а если тара разрушена огнем, то эффект взрыва или разбрасывания ограничен, что не препятствует проведению аварийных мер или тушению пожара в непосредственной близости от упаковки',
        ],
        correctAnswers: [
          'Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов',
        ],
      },
      {
        code: '63718223',
        text:
          'Как часто лица, назначенные распорядительным документом организации, должны проверять правильность учета, хранения и наличия взрывчатых материалов на складах?',
        answers: ['Ежемесячно', 'Ежеквартально', 'Ежедекадно', 'Еженедельно'],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63718224',
        text:
          'Где должны храниться подлинники документов, включенных в материалы технического расследования случаев утраты взрывчатых материалов промышленного назначения?',
        answers: [
          'В организации, где произошел случай утраты взрывчатых материалов промышленного назначения',
          'В региональной прокуратуре',
          'В территориальном органе МВД',
          'В территориальном органе Ростехнадзора',
        ],
        correctAnswers: [
          'В организации, где произошел случай утраты взрывчатых материалов промышленного назначения',
        ],
      },
      {
        code: '63718225',
        text:
          'Каков максимальный срок предоставления Ростехнадзором государственной услуги при выдаче дубликата разрешения на постоянное применение взрывчатых веществ и изделий на их основе?',
        answers: [
          '45 рабочих дней',
          '15 рабочих дней',
          '30 рабочих дней',
          '10 рабочих дней',
        ],
        correctAnswers: ['10 рабочих дней'],
      },
      {
        code: '63718226',
        text:
          'Каково минимально допустимое расстояние от ближайшей стены хранилища до ограды кратковременных складов?',
        answers: ['20 м', '10 м', '30 м', '50 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63718227',
        text: 'Какой сигнал необходимо подавать при вводе опасной зоны?',
        answers: [
          '1 продолжительный',
          '2 коротких и 1 продолжительный',
          '3 продолжительных',
          '3 коротких и 1 продолжительный',
        ],
        correctAnswers: ['1 продолжительный'],
      },
      {
        code: '63718228',
        text:
          'Какие источники освещения запрещается использовать в качестве аварийного освещения для хранилищ склада взрывчатых материалов?',
        answers: [
          'Рудничные аккумуляторные светильники',
          'Фонари с сухими батареями, если их металлические корпуса закрыты резиновыми чехлами',
          'Ручные переносные лампы, питаемые от электросети',
        ],
        correctAnswers: ['Ручные переносные лампы, питаемые от электросети'],
      },
      {
        code: '63718229',
        text:
          'Каково минимально допустимое расстояние от зданий и сооружений на земной поверхности до мест кратковременного хранения взрывчатых материалов в объеме сменной потребности?',
        answers: ['50 м', '60 м', '70 м', '30 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63718230',
        text:
          'Как часто следует проверять техническую исправность транспортных средств, используемых для доставки взрывчатых материалов?',
        answers: ['Ежесменно', 'Еженедельно', 'Ежемесячно', 'Ежедекадно'],
        correctAnswers: ['Ежесменно'],
      },
    ],
    63717: [
      {
        code: '63717000',
        text:
          'Какой должна быть температура воздуха в помещениях для сушки взрывчатых веществ?',
        answers: [
          'Не выше 50 °С',
          'Не выше 55 °С',
          'Не выше 60 °С',
          'Не выше 70 °С',
        ],
        correctAnswers: ['Не выше 50 °С'],
      },
      {
        code: '63717001',
        text:
          'Какова периодичность проведения измерений параметров электризации в условиях производства?',
        answers: [
          'Не реже 2 раз в год',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
        ],
        correctAnswers: ['Не реже 2 раз в год'],
      },
      {
        code: '63717002',
        text:
          'С какой периодичностью пересматривается регламент технологического процесса производства и подготовки взрывчатых веществ?',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 7 лет',
          'Не реже 1 раза в 10 лет',
          'Не регламентируется',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '63717003',
        text:
          'Кому предоставляется право руководства любыми взрывными работами без дополнительного обучения?',
        answers: [
          'Лицам, окончившим средние профессиональные учебные заведения по специальности "взрывное дело"',
          'Лицам, окончившим высшие учебные заведения по специальности "техногенная безопасность"',
          'Лицам, окончившим высшие учебные заведения по направлению подготовки "машиностроение"',
          'Лицам, окончившим средние профессиональные учебные заведения по специальности "подземная разработка пластовых месторождений"',
          'Лицам, окончившим высшие учебные заведения по направлению подготовки "нефтегазовое дело"',
        ],
        correctAnswers: [
          'Лицам, окончившим высшие учебные заведения по направлению подготовки "нефтегазовое дело"',
        ],
      },
      {
        code: '63717004',
        text:
          'Какой документ применяется для отпуска взрывчатых материалов взрывникам на производство взрывных работ?',
        answers: [
          'Наряд-накладная на выдачу и перемещение взрывчатых материалов',
          'Единая книжка взрывника',
          'Наряд-путевка',
          'Акт о готовности забоя к заряжанию',
        ],
        correctAnswers: ['Наряд-путевка'],
      },
      {
        code: '63717005',
        text:
          'Кто производит заряжание и забойку для ведения взрывных работ по металлу?',
        answers: [
          '1 взрывник',
          '1 взрывник в присутствии руководителя взрывных работ',
          '2 взрывника в присутствии руководителя взрывных работ',
          '3 взрывника в присутствии руководителя взрывных работ',
        ],
        correctAnswers: [
          '2 взрывника в присутствии руководителя взрывных работ',
        ],
      },
      {
        code: '63717006',
        text:
          'Как долго следует хранить проекты буровзрывных (взрывных) работ, паспорта, схемы, в соответствии с которыми осуществляются взрывные работы?',
        answers: [
          'Не менее 3 месяцев',
          'Не менее 6 месяцев',
          'Не менее 9 месяцев',
          'Не менее года',
        ],
        correctAnswers: ['Не менее года'],
      },
      {
        code: '63717007',
        text:
          'Какие помещения разрешается размещать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите два правильных варианта ответа.',
        answers: [
          'Слесарные мастерские для мелкого текущего ремонта',
          'Мастерские для производства сварочных работ',
          'Места постоянного хранения тары, отведенные в рабочем помещении',
          'Помещения временного пребывания дежурных слесарей и электриков',
        ],
        correctAnswers: [
          'Слесарные мастерские для мелкого текущего ремонта',
          'Помещения временного пребывания дежурных слесарей и электриков',
        ],
      },
      {
        code: '63717008',
        text:
          'Что из перечисленного должны сделать работники подземного рудника (шахты), обнаружившие отказавший заряд? Выберите два правильных варианта ответа.',
        answers: [
          'Прекратить все работы',
          'Закрестить выработку',
          'Выставить отличительный знак у невзорвавшегося заряда',
          'Немедленно поставить в известность технического руководителя организации (шахты, рудника, карьера, разреза) или лицо, его замещающее',
        ],
        correctAnswers: ['Прекратить все работы', 'Закрестить выработку'],
      },
      {
        code: '63717009',
        text:
          'Что из перечисленного необходимо включать в паспорт взрывных работ?',
        answers: [
          'Только схему расположения шпуров или наружных зарядов, наименования взрывчатых материалов, данные о способе заряжания, числе шпуров, их глубине и диаметре, массе и конструкции зарядов и боевиков',
          'Только состав бригады взрывников и требования к их квалификации',
          'Только перечень средств индивидуальной защиты работников при выполнении взрывных работ',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Только схему расположения шпуров или наружных зарядов, наименования взрывчатых материалов, данные о способе заряжания, числе шпуров, их глубине и диаметре, массе и конструкции зарядов и боевиков',
        ],
      },
      {
        code: '63717010',
        text:
          'На какой срок выдается разрешение на ведение работ со взрывчатыми материалами промышленного назначения?',
        answers: [
          'Не более чем на 1 год',
          'Не более чем на 2 года',
          'Не более чем на 6 месяцев',
        ],
        correctAnswers: ['Не более чем на 1 год'],
      },
      {
        code: '63717011',
        text:
          'На каком протяжении от забоя подземная выработка должна быть освобождена от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения, перед проведением сотрясательного взрывания?',
        answers: [
          'На протяжении не менее 100 м',
          'На протяжении не менее 90 м',
          'На протяжении не менее 75 м',
          'На протяжении не менее 50 м',
        ],
        correctAnswers: ['На протяжении не менее 100 м'],
      },
      {
        code: '63717012',
        text:
          'Где разрешается применять предохранительные взрывчатые вещества III класса при ведении взрывных работ в подземных выработках?',
        answers: [
          'В угольных и смешанных забоях выработок, проводимых по угольным пластам, опасным по взрывам пыли, при отсутствии выделения метана в этих выработках',
          'В бутовых штреках с нижней подрывкой пород',
          'При взрывании по породе в смешанных забоях выработок, проводимых по пластам, опасным по внезапным выбросам угля и газа, при опережающем породном забое',
          'В забоях стволов, проводимых только по породе, при их углубке с действующих горизонтов и выделении в них метана',
        ],
        correctAnswers: [
          'В забоях стволов, проводимых только по породе, при их углубке с действующих горизонтов и выделении в них метана',
        ],
      },
      {
        code: '63717013',
        text:
          'Какое из перечисленных требований безопасности необходимо соблюдать во время взрывания при образовании каналов, канав и котлованов?',
        answers: [
          'При формировании траншейных зарядов расстояние между экскаватором (траншеекопателем) и краном, укладывающим взрывчатые материалы в траншею, должно быть не менее 1,0 суммы радиусов разворота ковша экскаватора с вытянутой рукоятью и разворота крана',
          'При формировании траншейных зарядов расстояние между краном и бульдозером, выполняющим забойку, должно быть не менее 1,0 радиуса разворота крана',
          'В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещать удлиненные горизонтальные заряды (траншейные, щелевые) непосредственно вслед за проведением горных выработок',
          'Отставание заряда от многоковшового экскаватора должно быть не менее 10 м',
          'Отставание заряда от одноковшового экскаватора должно быть не менее 5 м',
        ],
        correctAnswers: [
          'В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещать удлиненные горизонтальные заряды (траншейные, щелевые) непосредственно вслед за проведением горных выработок',
        ],
      },
      {
        code: '63717014',
        text:
          'Какие электродетонаторы разрешается применять в бутовых штреках с подрывкой кровли?',
        answers: [
          'Электродетонаторы короткозамедленного действия с интервалом замедления не более 30 миллисекунд (по номиналу)',
          'Электродетонаторы короткозамедленного действия с интервалом замедления не более 45 миллисекунд (по номиналу)',
          'Электродетонаторы короткозамедленного действия с интервалом замедления не более 60 миллисекунд (по номиналу)',
          'Электродетонаторы мгновенного действия',
        ],
        correctAnswers: ['Электродетонаторы мгновенного действия'],
      },
      {
        code: '63717015',
        text:
          'Через сколько минут после последнего взрыва разрешается подходить к месту взрыва, если какой-либо заряд не взорвался или вести счет взорвавшихся зарядов невозможно?',
        answers: [
          'Не ранее чем через 15 минут',
          'Не ранее чем через 12 минут',
          'Не ранее чем через 10 минут',
          'Не ранее чем через 5 минут',
        ],
        correctAnswers: ['Не ранее чем через 15 минут'],
      },
      {
        code: '63717016',
        text:
          'Какая информация должна содержаться в акте по установлению причин инцидента на опасном производственном объекте?',
        answers: [
          'Только дата и место инцидента, его причины и обстоятельства',
          'Только сведения о принятых мерах по ликвидации инцидента и по устранению причин инцидента',
          'Только сведения о продолжительности простоя и материальном ущербе, в том числе о вреде, нанесенном окружающей среде',
          'Только сведения о лицах, ответственных за инцидент, о разработанных мероприятиях по предупреждению аналогичных инцидентов',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63717017',
        text:
          'В каком случае допускается применять и хранить взрывчатые вещества и изделия на их основе с истекшим гарантийным сроком хранения?',
        answers: [
          'В случае хранения в подземных хранилищах',
          'В случае снижения количества хранящихся взрывчатых веществ в 2 раза от рекомендованного',
          'В случае проведения испытаний, предусмотренных технической документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае проведения испытаний, предусмотренных технической документацией',
        ],
      },
      {
        code: '63717018',
        text:
          'Кто утверждает регламент технологического процесса производства и подготовки взрывчатых веществ и изменения в нем?',
        answers: [
          'Руководитель (технический руководитель) эксплуатирующей организации',
          'Начальник службы охраны труда',
          'Начальник пункта производства и (или) подготовки взрывчатых веществ',
          'Руководитель территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) эксплуатирующей организации',
        ],
      },
      {
        code: '63717019',
        text:
          'Что из перечисленного относится к лицензионным требованиям, предъявляемым к соискателю лицензии на осуществление лицензируемого вида деятельности, связанной с обращением взрывчатых материалов промышленного назначения?',
        answers: [
          'Наличие работника, уполномоченного на принятие решений по организации выполнения заявленных работ и ответственного за их выполнение, назначенного распорядительным документом, имеющего высшее или среднее профессиональное (техническое) образование, а также стаж по заявленному виду работ не менее 6 месяцев',
          'Производство, хранение и применение взрывчатых материалов промышленного назначения в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Наличие технической документации, а также технических устройств и контрольно-проверочной аппаратуры, принадлежащих соискателю и обеспечивающих выполнение заявленных видов работ',
          'Наличие помещений, зданий, сооружений и иных объектов, являющихся объектами жилищного фонда',
        ],
        correctAnswers: [
          'Наличие технической документации, а также технических устройств и контрольно-проверочной аппаратуры, принадлежащих соискателю и обеспечивающих выполнение заявленных видов работ',
        ],
      },
      {
        code: '63717020',
        text:
          'Что из перечисленного должно прилагаться к заявлению на выдачу разрешения на ведение работ со взрывчатыми материалами промышленного назначения при взрывных работах в подземных условиях?',
        answers: [
          'Сведения об опасности шахты (рудника, объекта геолого-разведочных работ) по газу и пыли',
          'План местности с обозначением мест производства взрывных работ',
          'Схемы профилей работ, типовая схема охраны опасной зоны',
          'Проект на взрывные работы',
        ],
        correctAnswers: [
          'Сведения об опасности шахты (рудника, объекта геолого-разведочных работ) по газу и пыли',
        ],
      },
      {
        code: '63717021',
        text:
          'Кому обязан сообщить взрывник о возникновении аварийной ситуации в процессе заряжания?',
        answers: [
          'Руководителю взрывных работ',
          'Раздатчику на склад взрывчатых материалов',
          'Горному диспетчеру',
          'Взрывнику следующей смены',
        ],
        correctAnswers: ['Руководителю взрывных работ'],
      },
      {
        code: '63717022',
        text:
          'Кто дает разрешение на использование индивидуальных рудничных аккумуляторных светильников для освещения в углубленном складе при отсутствии стационарных источников электроэнергии?',
        answers: [
          'Ростехнадзор',
          'Руководитель (технический руководитель) организации',
          'Начальник службы охраны труда',
          'Начальник углубленного склада',
        ],
        correctAnswers: ['Руководитель (технический руководитель) организации'],
      },
      {
        code: '63717023',
        text:
          'Какие из перечисленных буровзрывных (взрывных) работ следует проводить по утвержденным проектам (техническим проектам)?',
        answers: [
          'Работы по ликвидации отказов с применением разовых взрывов зарядов',
          'Дноуглубительные и ледоходные работы',
          'Работы с применением разовых взрывов зарядов для выравнивания почвы выработки',
          'Работы с применением разовых взрывов зарядов в шпурах для доведения контура выработки до размеров, предусмотренных проектом',
        ],
        correctAnswers: ['Дноуглубительные и ледоходные работы'],
      },
      {
        code: '63717024',
        text:
          'Что из перечисленного входит в полномочия комиссии по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения в ходе технического расследования?',
        answers: [
          'Только привлечение к расследованию независимых экспертных организаций',
          'Только привлечение к расследованию независимых экспертов, специалистов',
          'Только проведение опросов и получение объяснений работников организации для уточнения обстоятельств утраты взрывчатых материалов, выявления недостатков в организации производства взрывных работ, а также для определения конкретных причин нарушения установленного порядка хранения, перевозки, использования и учета взрывчатых материалов промышленного назначения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63717025',
        text:
          'Когда взрывнику разрешается подходить к месту взрыва, для которого использовались электронные детонаторы, электродетонаторы и капсюли-детонаторы, если взрыва не произошло?',
        answers: [
          'Сразу после отказа взрывания',
          'Не ранее чем через 5 минут после отказа взрывания',
          'Не ранее чем через 10 минут после отказа взрывания',
          'Не ранее чем через 15 минут после отказа взрывания',
        ],
        correctAnswers: ['Не ранее чем через 15 минут после отказа взрывания'],
      },
      {
        code: '63717026',
        text:
          'Какое количество приемов допускается при проведении взрывания по породе выработок при отсутствии выделения метана с применением электродетонаторов мгновенного, короткозамедленного и замедленного действия со временем замедления до 2 секунд?',
        answers: [
          'Не более 4 приемов',
          'Не более 3 приемов',
          'Не более 2 приемов',
          'Количество приемов не ограничено',
        ],
        correctAnswers: ['Количество приемов не ограничено'],
      },
      {
        code: '63717027',
        text:
          'Кто из перечисленных должен быть включен в состав комиссии по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения?',
        answers: [
          'Только представители организации, в которой произошла утрата взрывчатых материалов, и ее вышестоящего органа или организации (при наличии)',
          'Только представители территориальных органов ФСБ России',
          'Только представители МВД России',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63717028',
        text:
          'Где следует размещать взрывчатые материалы при кратковременном хранении на площадках?',
        answers: [
          'Под навесом или брезентовым покрытием на деревянном настиле высотой не менее 20 см от земли',
          'В передвижных прицепах',
          'В палатках, расположенных на расстоянии, безопасном по передаче детонации',
          'В специальных временных деревянных сараях, на настиле высотой не менее 10 см от уровня пола',
        ],
        correctAnswers: [
          'Под навесом или брезентовым покрытием на деревянном настиле высотой не менее 20 см от земли',
        ],
      },
      {
        code: '63717029',
        text:
          'Какие источники освещения запрещается использовать в качестве аварийного освещения для хранилищ поверхностного склада взрывчатых материалов?',
        answers: [
          'Рудничные аккумуляторные светильники',
          'Фонари с сухими батареями (при наличии металлического корпуса – в резиновых чехлах)',
          'Ручные переносные лампы с питанием от электросети',
        ],
        correctAnswers: ['Ручные переносные лампы с питанием от электросети'],
      },
      {
        code: '63717030',
        text:
          'В течение какого времени взрывник должен отработать стажером под руководством опытного взрывника перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?',
        answers: [
          'В течение 2 недель',
          'В течение 1 месяца',
          'В течение 3 недель',
          'В течение 2 месяцев',
        ],
        correctAnswers: ['В течение 1 месяца'],
      },
      {
        code: '63717031',
        text: 'Как следует уничтожать порох при сжигании?',
        answers: [
          'Рассыпать дорожками шириной не более 30 см при толщине слоя до 10 см и расстоянии между ними не менее 5 м; одновременно поджигать не более 3 дорожек',
          'Рассыпать дорожками шириной не более 40 см при толщине слоя до 12 см и расстоянии между ними не менее 6 м; одновременно поджигать не более 4 дорожек',
          'Рассыпать дорожками шириной не более 50 см при толщине слоя до 15 см и расстоянии между ними не менее 4 м; одновременно поджигать не более 4 дорожек',
          'Рассыпать дорожками шириной не более 60 см при толщине слоя до 20 см и расстоянии между ними не менее 5 м; одновременно поджигать не более 3 дорожек',
        ],
        correctAnswers: [
          'Рассыпать дорожками шириной не более 30 см при толщине слоя до 10 см и расстоянии между ними не менее 5 м; одновременно поджигать не более 3 дорожек',
        ],
      },
      {
        code: '63717032',
        text: 'Что является основанием для проведения взрывных работ?',
        answers: [
          'Распоряжение руководителя подразделения организации, в котором производятся взрывные работы',
          'Запись в сменном журнале, сделанная представителем технического надзора',
          'Указание технического руководителя организации',
          'Письменный наряд (задание на выполнение работ) и соответствующий наряд-путевка',
        ],
        correctAnswers: [
          'Письменный наряд (задание на выполнение работ) и соответствующий наряд-путевка',
        ],
      },
      {
        code: '63717033',
        text:
          'На каком расстоянии от хранилищ взрывчатых материалов должно находиться здание выдачи?',
        answers: [
          'На расстоянии не менее 20 м',
          'На расстоянии не менее 5 м',
          'На расстоянии не менее 10 м',
          'На расстоянии не менее 15 м',
        ],
        correctAnswers: ['На расстоянии не менее 20 м'],
      },
      {
        code: '63717034',
        text:
          'В какой срок проводится техническое расследование причин аварии на опасном производственном объекте?',
        answers: [
          'В срок, не превышающий 30 календарных дней с даты подписания правового акта о назначении комиссии',
          'В срок, не превышающий 35 календарных дней с даты подписания правового акта о назначении комиссии',
          'В срок, не превышающий 40 календарных дней с даты подписания правового акта о назначении комиссии',
          'В срок, не превышающий 60 календарных дней с даты подписания правового акта о назначении комиссии',
        ],
        correctAnswers: [
          'В срок, не превышающий 30 календарных дней с даты подписания правового акта о назначении комиссии',
        ],
      },
      {
        code: '63717035',
        text:
          'Какие индексы, нанесенные на гильзы электродетонаторов и капсюлей-детонаторов, обозначают номер взрывника?',
        answers: [
          '2 цифровых индекса',
          '2 буквенных индекса, расположенные слева от цифровых',
          '2 буквенных индекса, расположенные справа от цифровых',
          'Буквенные индексы, расположенные слева и справа от цифровых',
        ],
        correctAnswers: [
          '2 буквенных индекса, расположенные справа от цифровых',
        ],
      },
      {
        code: '63717036',
        text:
          'На кого возлагается финансирование расходов на техническое расследование причин аварии на опасном производственном объекте?',
        answers: [
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
          'На территориальный орган Ростехнадзора',
          'На органы местного самоуправления, на территории которых произошла авария',
          'На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии',
        ],
        correctAnswers: [
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
        ],
      },
      {
        code: '63717037',
        text:
          'Какое количество зарядов разрешается одновременно заряжать и взрывать при температуре в шпуре ниже 80 °C при ведении взрывных работ по металлу?',
        answers: [
          'Не более 5 зарядов',
          'Не более 6 зарядов',
          'Не более 7 зарядов',
          'Не более 10 зарядов',
        ],
        correctAnswers: ['Не более 5 зарядов'],
      },
      {
        code: '63717038',
        text:
          'На сколько дней может быть продлен срок технического расследования причин аварии на опасном производственном объекте?',
        answers: [
          'Не более чем на 15 календарных дней',
          'Не более чем на 20 календарных дней',
          'Не более чем на 25 календарных дней',
          'Не более чем на 35 календарных дней',
        ],
        correctAnswers: ['Не более чем на 15 календарных дней'],
      },
      {
        code: '63717039',
        text:
          'Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?',
        answers: [
          'Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения',
          'Регламентом технологического процесса производства и подготовки взрывчатых веществ',
          'Постановлением Правительства Российской Федерации от 15.09.2020 № 1435 "О лицензировании деятельности, связанной с обращением взрывчатых материалов промышленного назначения"',
          'Документами, утвержденными организацией, эксплуатирующей объект',
        ],
        correctAnswers: [
          'Документами, утвержденными организацией, эксплуатирующей объект',
        ],
      },
      {
        code: '63717040',
        text:
          'Кто перед началом работы по механизированному заряжанию шпуров, скважин или камер обязан осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние? Выберите два правильных варианта ответа.',
        answers: [
          'Взрывник',
          'Специально проинструктированный рабочий',
          'Руководитель взрывных работ',
          'Помощник взрывника',
        ],
        correctAnswers: ['Взрывник', 'Руководитель взрывных работ'],
      },
      {
        code: '63717041',
        text:
          'С какой периодичностью необходимо проверять взрывные приборы стационарных взрывных пунктов на угольных, сланцевых шахтах и объектах геологоразведки, опасных по газу или пыли, в местах их установки?',
        answers: [
          'Не реже 1 раза в 15 дней',
          'Не реже 1 раза в 20 дней',
          'Не реже 1 раза в 25 дней',
          'Не реже 1 раза в 30 дней',
        ],
        correctAnswers: ['Не реже 1 раза в 15 дней'],
      },
      {
        code: '63717042',
        text:
          'Какие параметры должны иметь прострелочно-взрывные аппараты для подъема над устьем скважины и спуска с помощью грузоподъемных механизмов?',
        answers: [
          'Прострелочно-взрывные аппараты должны иметь массу более 40 кг или длину от 1,5 м до 2 м',
          'Прострелочно-взрывные аппараты должны иметь массу более 30 кг или длину более 1 м',
          'Прострелочно-взрывные аппараты должны иметь массу более 20 кг или длину от 0,5 до 1 м',
          'Прострелочно-взрывные аппараты должны иметь массу более 50 кг или длину более 2 м',
        ],
        correctAnswers: [
          'Прострелочно-взрывные аппараты должны иметь массу более 50 кг или длину более 2 м',
        ],
      },
      {
        code: '63717043',
        text:
          'При какой температуре воздуха в помещениях необходимо проводить сушку дымного пороха?',
        answers: [
          'При температуре не выше 40 °С',
          'При температуре не выше 45 °С',
          'При температуре не выше 50 °С',
          'При температуре не выше 55 °С',
        ],
        correctAnswers: ['При температуре не выше 40 °С'],
      },
      {
        code: '63717044',
        text:
          'Какие действия необходимо выполнять для уменьшения пыления и просыпи взрывчатых веществ?',
        answers: [
          'Только при пневмозаряжании алюмо- и тротилсодержащими рассыпными гранулированными взрывчатыми веществами добавлять во взрывчатые вещества воду или смачивающий раствор',
          'Только выдерживать оптимальное расстояние от конца шланга до заряда',
          'Только при заряжании центрировать конец зарядного трубопровода относительно оси скважины',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63717045',
        text:
          'Что должен сделать взрывник, если при подаче напряжения не произошло взрыва?',
        answers: [
          'Отсоединить от прибора (источника тока) электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от прибора (ящика, в котором находится взрывное устройство) и только после этого выяснить причину отказа',
          'Отсоединить от прибора (источника тока) электровзрывную сеть, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа',
          'Отсоединить от прибора (источника тока) электровзрывную сеть и проверить ее электрическое сопротивление, при обнаружении неисправности принять меры к ее устранению',
          'Периодически (не менее 3 раз) подавать напряжение в электровзрывную сеть, при отсутствии взрыва отключить взрывной прибор, взять с собой ключ от взрывного прибора и только после этого выяснить причину отказа',
        ],
        correctAnswers: [
          'Отсоединить от прибора (источника тока) электровзрывную сеть, замкнуть накоротко ее концы, взять с собой ключ от прибора (ящика, в котором находится взрывное устройство) и только после этого выяснить причину отказа',
        ],
      },
      {
        code: '63717046',
        text:
          'Какое требование к хранилищам полууглубленных складов, имеющим рампы и средства механизации погрузочно-разгрузочных работ, указано верно?',
        answers: [
          'В хранилище должен быть оборудован минимум 1 тамбур',
          'В хранилище должен быть оборудован минимум 1 тамбур, вход через него следует оснастить металлической дверью',
          'Необходимо оборудовать не менее 2 тамбуров, вход через них следует оснастить не менее чем 2 двустворчатыми решетчатыми дверями',
          'Устройство тамбуров не обязательно, но обе двери подлежат установке',
        ],
        correctAnswers: [
          'Устройство тамбуров не обязательно, но обе двери подлежат установке',
        ],
      },
      {
        code: '63717047',
        text:
          'Каким должно быть расстояние от входа в поверхностное хранилище взрывчатых материалов до наиболее удаленной точки одного помещения по проходам?',
        answers: [
          'Не более 15 м',
          'Не более 18 м',
          'Не более 25 м',
          'Не более 30 м',
        ],
        correctAnswers: ['Не более 15 м'],
      },
      {
        code: '63717048',
        text:
          'Кто должен выехать из забоя до начала монтажа электровзрывной сети?',
        answers: [
          'Рабочие',
          'Ответственный за подачу сигналов',
          'Ответственный за обслуживание проходческого полка',
          'Взрывник',
        ],
        correctAnswers: ['Рабочие'],
      },
      {
        code: '63717049',
        text:
          'Каков максимальный срок предоставления государственной услуги при выдаче (отказе в выдаче) разрешения на ведение работ со взрывчатыми материалами промышленного назначения со дня регистрации заявления?',
        answers: [
          '30 рабочих дней',
          '45 рабочих дней',
          '50 рабочих дней',
          '60 рабочих дней',
        ],
        correctAnswers: ['30 рабочих дней'],
      },
      {
        code: '63717050',
        text: 'Какая характеристика электродетонаторов указана верно?',
        answers: [
          'Значение безопасного тока не менее 0,18 А',
          'Длительный воспламеняющий ток не менее 0,25 А',
          'Безопасный импульс воспламенения не менее 0,8 А²·мс',
        ],
        correctAnswers: ['Значение безопасного тока не менее 0,18 А'],
      },
      {
        code: '63717051',
        text:
          'Какие допускаются размеры основной выработки склада взрывчатых материалов, в которой применяются погрузчики?',
        answers: [
          'Размеры, превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 60 см с каждой стороны по ширине и 50 см по высоте от светильников',
          'Размеры, превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 55 см с каждой стороны по ширине и 45 см по высоте от светильников',
          'Размеры, превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 50 см с каждой стороны по ширине и 40 см по высоте от светильников',
          'Размеры, превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 40 см с каждой стороны по ширине и 35 см по высоте от светильников',
        ],
        correctAnswers: [
          'Размеры, превышающие максимальные размеры погрузочно-разгрузочных механизмов с грузами, в том числе на криволинейных участках, не менее чем на 60 см с каждой стороны по ширине и 50 см по высоте от светильников',
        ],
      },
      {
        code: '63717052',
        text:
          'На каком расстоянии от ограды склада должно находиться ближайшее хранилище взрывчатых материалов?',
        answers: [
          'На расстоянии не менее 40 м',
          'На расстоянии не менее 35 м',
          'На расстоянии не менее 30 м',
          'На расстоянии не менее 25 м',
        ],
        correctAnswers: ['На расстоянии не менее 40 м'],
      },
      {
        code: '63717053',
        text:
          'Каким документом создается комиссия по расследованию причин инцидентов на опасном производственном объекте?',
        answers: [
          'Приказом руководителя Ростехнадзора',
          'Распорядительным документом руководителя местного муниципального органа',
          'Внутренним распорядительным актом руководителя организации, эксплуатирующей объект',
          'Приказом руководителя профессиональной аварийно-спасательной службы',
        ],
        correctAnswers: [
          'Внутренним распорядительным актом руководителя организации, эксплуатирующей объект',
        ],
      },
      {
        code: '63717054',
        text:
          'Какое требование к приему, отпуску и учету взрывчатых материалов указано верно?',
        answers: [
          'Доставленные на места хранения взрывчатые материалы должны быть приняты по количеству, массе, оприходованы и помещены в хранилища, на площадки',
          'Доставленные на места хранения взрывчатые материалы должны быть немедленно переданы на места проведения взрывных работ и выданы взрывникам',
          'Индивидуальные заводские номера изделий с взрывчатыми веществами при выдаче взрывникам не должны регистрироваться в книге учета выдачи и возврата взрывчатых материалов',
          'Допускается выдача взрывникам электродетонаторов и капсюлей-детонаторов без присвоения и нанесения индивидуальных индексов',
        ],
        correctAnswers: [
          'Доставленные на места хранения взрывчатые материалы должны быть приняты по количеству, массе, оприходованы и помещены в хранилища, на площадки',
        ],
      },
      {
        code: '63717055',
        text:
          'На каком расстоянии от ствола шахты или устья штольни (тоннеля) при их проходке разрешается размещать в будках или под навесами взрывчатые материалы в размере сменной потребности?',
        answers: [
          'На расстоянии не менее 20 м',
          'На расстоянии не менее 30 м',
          'На расстоянии не менее 40 м',
          'На расстоянии не менее 50 м',
        ],
        correctAnswers: ['На расстоянии не менее 50 м'],
      },
      {
        code: '63717056',
        text:
          'Каким документом оформляется отпуск взрывчатых материалов с одного места хранения на другое, принадлежащее одной и той же организации?',
        answers: [
          'Письменным распоряжением заведующего складом взрывчатых материалов',
          'Письменным распоряжением руководителя горных и взрывных работ',
          'Нарядом-накладной',
          'Нарядом-путевкой',
        ],
        correctAnswers: ['Нарядом-накладной'],
      },
      {
        code: '63717057',
        text:
          'Какие взрывчатые вещества разрешается производить на пунктах производства взрывчатых веществ?',
        answers: [
          'Только смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими',
          'Только водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими',
          'Все перечисленные',
          'Только смеси холодного смешения гранулированной аммиачной селитры с гранулированным (чешуированным) тротилом, жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63717058',
        text:
          'Какое требование к уничтожению взрывчатых материалов указано верно?',
        answers: [
          'За 1 прием разрешается сжигать не более 30 кг взрывчатых материалов',
          'Взрывчатые вещества, огнепроводные и детонирующие шнуры необходимо сжигать совместно',
          'Запрещается подходить к месту сжигания до полного прекращения горения костра с взрывчатыми материалами',
          'Одновременно разрешается поджигать не более 5 дорожек с порохами',
        ],
        correctAnswers: [
          'Запрещается подходить к месту сжигания до полного прекращения горения костра с взрывчатыми материалами',
        ],
      },
      {
        code: '63717059',
        text:
          'Где и при каком условии во время ведения взрывных работ в подземных выработках разрешается находиться людям? Выберите два правильных варианта ответа.',
        answers: [
          'В раздаточной камере при взрывных работах, проводящихся на расстоянии менее 100 м от нее',
          'В одном из встречных забоев при сближении забоев на расстояние 35 м перед началом заряжания шпуров',
          'В параллельно проводимых выработках угольных и нефтяных шахт при расстоянии между выработками 35 м и менее',
          'В параллельной выработке, забой которой отстает на расстояние более 30 м от забоя, где проводится взрывание',
        ],
        correctAnswers: [
          'В одном из встречных забоев при сближении забоев на расстояние 35 м перед началом заряжания шпуров',
          'В параллельно проводимых выработках угольных и нефтяных шахт при расстоянии между выработками 35 м и менее',
        ],
      },
      {
        code: '63717060',
        text:
          'Какое мероприятие входит в проверку молниезащиты складов взрывчатых материалов?',
        answers: [
          'Только наружный осмотр молниезащитных устройств',
          'Только измерение сопротивления заземлителей молниезащиты',
          'Только проверка переходного сопротивления контактов устройств защиты от вторичных воздействий молнии',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63717061',
        text:
          'Какие сигналы следует подавать для оповещения людей при производстве взрывных работ в темное время суток?',
        answers: [
          'Только голосовые сигналы',
          'Только звуковые и световые сигналы',
          'Только сигналы с применением взрывчатых материалов',
          'Все перечисленные сигналы',
        ],
        correctAnswers: ['Только звуковые и световые сигналы'],
      },
      {
        code: '63717062',
        text:
          'Когда и кем проводится наружный осмотр молниезащитных устройств склада взрывчатых материалов?',
        answers: [
          'Не реже 1 раза в месяц заведующим складом взрывчатых материалов',
          'Не реже 1 раза в квартал комиссией, назначенной руководителем организации',
          'Не реже 1 раза в неделю службой энергетика организации',
          'Не реже 1 раза в месяц руководителем взрывных работ, в ведении которого находится склад взрывчатых материалов',
        ],
        correctAnswers: [
          'Не реже 1 раза в месяц заведующим складом взрывчатых материалов',
        ],
      },
      {
        code: '63717063',
        text:
          'На каком расстоянии от складов взрывчатых материалов, раздаточных камер или участковых пунктов хранения взрывчатых материалов запрещается вести взрывные работы при наличии в них взрывчатых веществ (средств инициирования)?',
        answers: [
          'На расстоянии менее 30 м',
          'На расстоянии менее 40 м',
          'На расстоянии менее 50 м',
          'На расстоянии менее 60 м',
        ],
        correctAnswers: ['На расстоянии менее 30 м'],
      },
      {
        code: '63717064',
        text:
          'Каков допустимый диаметр прутка стальной решетки, которой оборудуются окна хранилищ полууглубленных складов взрывчатых материалов?',
        answers: [
          'Не менее 15 мм',
          'Не менее 12 мм',
          'Не менее 10 мм',
          'Не менее 8 мм',
        ],
        correctAnswers: ['Не менее 15 мм'],
      },
      {
        code: '63717065',
        text:
          'При каком содержании метана в забоях подземных выработок запрещается выполнять взрывные работы?',
        answers: [
          'При содержании метана 0,1 % и более',
          'При содержании метана 0,25 % и более',
          'При содержании метана 0,5 % и более',
          'При содержании метана 1 % и более',
        ],
        correctAnswers: ['При содержании метана 1 % и более'],
      },
      {
        code: '63717066',
        text:
          'Каким способом разрешается проводить взрывание камерных зарядов?',
        answers: [
          'Только с применением детонирующего шнура',
          'Только с применением электродетонаторов',
          'Только с применением неэлектрических систем инициирования',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63717067',
        text:
          'Что из перечисленного допускается делать при ведении взрывных работ?',
        answers: [
          'Переламывать выходящие из зарядов концы детонирующего шнура, волноводов неэлектрических систем инициирования',
          'Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах',
          'Взрывать заряды без забойки на шахтах (рудниках), опасных по газу и пыли',
          'Тянуть провода электронных детонаторов и электродетонаторов, введенные в боевики',
        ],
        correctAnswers: [
          'Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах',
        ],
      },
      {
        code: '63717068',
        text:
          'Что из перечисленного необходимо сделать перед выдачей электродетонаторов?',
        answers: [
          'Только проверить электродетонаторы на отсутствие повреждений корпуса',
          'Только проверить электродетонаторы на отсутствие следов коррозии',
          'Только присвоить электродетонаторам индивидуальные индексы и нанести их',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63717069',
        text:
          'При какой температуре воздуха необходимо проводить оттаивание взрывчатых веществ, находящихся в заводской упаковке, в поверхностных складах в отапливаемых помещениях?',
        answers: [
          'При температуре не выше 30 °С',
          'При температуре не выше 35 °С',
          'При температуре не выше 40 °С',
          'При температуре не выше 50 °С',
        ],
        correctAnswers: ['При температуре не выше 30 °С'],
      },
      {
        code: '63717070',
        text:
          'Кто разрабатывает и согласовывает с командиром обслуживающего аварийно-спасательного формирования план по обслуживанию массового взрыва силами аварийно-спасательных формирований? Выберите два правильных варианта ответа.',
        answers: [
          'Руководитель рудника, шахты, объекта строительства',
          'Технический руководитель рудника, шахты, объекта строительства',
          'Инспектор территориального органа Ростехнадзора.',
          'Ответственный руководитель взрыва',
        ],
        correctAnswers: [
          'Технический руководитель рудника, шахты, объекта строительства',
          'Ответственный руководитель взрыва',
        ],
      },
      {
        code: '63717071',
        text:
          'Что из перечисленного может использоваться на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов? Выберите два правильных варианта ответа.',
        answers: [
          'Прочные деревянные шкафы с металлическими полками',
          'Металлические сейфы или ящики, изготовленные из металлических листов толщиной не менее 2 мм',
          'Шахтные вагонетки, оборудованные металлическими крышками',
          'Металлические стеллажи, изготовленные из металлических листов толщиной не менее 2 мм',
        ],
        correctAnswers: [
          'Металлические сейфы или ящики, изготовленные из металлических листов толщиной не менее 2 мм',
          'Шахтные вагонетки, оборудованные металлическими крышками',
        ],
      },
      {
        code: '63717072',
        text: 'Что необходимо сделать по окончании взрывных работ?',
        answers: [
          'Подать 1 продолжительный сигнал',
          'Подать 2 коротких сигнала',
          'Подать 2 продолжительных сигнала',
          'Подать 3 коротких сигнала',
        ],
        correctAnswers: ['Подать 3 коротких сигнала'],
      },
      {
        code: '63717073',
        text:
          'Какое расстояние допускается от ближайшей камеры склада камерного типа до выработок, служащих для постоянного прохода людей?',
        answers: [
          'Не менее 15 м',
          'Не менее 20 м',
          'Не менее 22 м',
          'Не менее 25 м',
        ],
        correctAnswers: ['Не менее 25 м'],
      },
      {
        code: '63717074',
        text:
          'Какое требование к монтажу электровзрывной сети предъявляется Правилами безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения?',
        answers: [
          'Электровзрывная сеть должна быть двухпроводной, при этом в качестве одного из проводников может использоваться вода, земля, трубы, рельсы, канаты',
          'Электровзрывную сеть следует монтировать в направлении от источника тока или включающего ток устройства к заряду',
          'Электровзрывную сеть следует монтировать в направлении от заряда к источнику тока',
        ],
        correctAnswers: [
          'Электровзрывную сеть следует монтировать в направлении от заряда к источнику тока',
        ],
      },
      {
        code: '63717075',
        text:
          'При каком условии для вскрытия пластов сотрясательным взрыванием допускается применять рассредоточенные (двухъярусные) заряды взрывчатых веществ?',
        answers: [
          'При использовании допущенных для соответствующих условий взрывчатых веществ III и IV классов, при этом длина забойки между рассредоточенными зарядами должна быть не менее 0,75 м, а масса первого от устья шпура заряда должна быть не более 1,2 кг',
          'При использовании допущенных для соответствующих условий взрывчатых веществ III и IV классов, при этом длина забойки между рассредоточенными зарядами должна быть не менее 0,8 м, а масса первого от устья шпура заряда должна быть не более 1,3 кг',
          'При использовании взрывчатых веществ II класса, при этом длина забойки между рассредоточенными зарядами должна быть не менее 1,5 м, а масса первого от устья шпура заряда взрывчатых веществ – не более 1 кг',
          'При длине шпуров с рассредоточенными зарядами не менее 2 м',
          'При условии, что в шпурах с рассредоточенными зарядами замедление в донном заряде меньше, чем в первом заряде от устья',
        ],
        correctAnswers: [
          'При использовании допущенных для соответствующих условий взрывчатых веществ III и IV классов, при этом длина забойки между рассредоточенными зарядами должна быть не менее 0,75 м, а масса первого от устья шпура заряда должна быть не более 1,2 кг',
        ],
      },
      {
        code: '63717076',
        text:
          'Какой цвет отличительной полосы или оболочек патронов (пачек) должны иметь предохранительные взрывчатые вещества для взрывания только по породе в забоях подземных выработок, в которых имеется выделение горючих газов, но отсутствует взрывчатая угольная (сланцевая) пыль?',
        answers: ['Синий', 'Красный', 'Белый', 'Желтый'],
        correctAnswers: ['Синий'],
      },
      {
        code: '63717077',
        text:
          'При каком расстоянии между параллельно проводимыми выработками угольных и нефтяных шахт взрывание зарядов в каждом забое следует проводить только после вывода людей из забоя параллельной выработки и выставления постов охраны?',
        answers: [
          'При расстоянии 15 м и менее',
          'При расстоянии 20 м и менее',
          'При расстоянии 25 м и менее',
          'При расстоянии 30 м и менее',
        ],
        correctAnswers: ['При расстоянии 15 м и менее'],
      },
      {
        code: '63717078',
        text:
          'Кто является заявителем на получение государственной услуги по выдаче разрешений на постоянное применение взрывчатых веществ и изделий на их основе?',
        answers: [
          'Юридические лица, осуществляющие изготовление взрывчатых материалов, либо их представители, полномочия которых удостоверены в соответствии с законодательством Российской Федерации, имеющие лицензии на деятельность, связанную с обращением взрывчатых материалов промышленного назначения, в соответствии с законодательством Российской Федерации',
          'Юридические лица, являющиеся потребителями взрывчатых материалов, либо их законные представители',
          'Физические лица, ведущие взрывные работы и имеющие лицензии в области взрывчатых материалов промышленного назначения',
          'Физические лица, осуществляющие изготовление взрывчатых материалов и имеющие лицензии на деятельность, связанную с обращением взрывчатых материалов промышленного назначения',
        ],
        correctAnswers: [
          'Юридические лица, осуществляющие изготовление взрывчатых материалов, либо их представители, полномочия которых удостоверены в соответствии с законодательством Российской Федерации, имеющие лицензии на деятельность, связанную с обращением взрывчатых материалов промышленного назначения, в соответствии с законодательством Российской Федерации',
        ],
      },
      {
        code: '63717079',
        text:
          'Что из перечисленного необходимо защищать только от прямого удара молнии?',
        answers: [
          'Хранилища постоянных поверхностных складов взрывчатых материалов',
          'Площадки для хранения взрывчатых материалов в контейнерах',
          'Хранилища временных полууглубленных складов взрывчатых материалов',
          'Пункты изготовления боевиков с электродетонаторами',
        ],
        correctAnswers: [
          'Площадки для хранения взрывчатых материалов в контейнерах',
        ],
      },
      {
        code: '63717080',
        text:
          'Сколько килограммов огнепроводных шнуров разрешается сжигать на костре за 1 прием?',
        answers: [
          'Не более 20 кг',
          'Не более 25 кг',
          'Не более 30 кг',
          'Не более 40 кг',
        ],
        correctAnswers: ['Не более 20 кг'],
      },
      {
        code: '63717081',
        text:
          'В течение какого срока назначается комиссия по техническому расследованию причин аварии после получения оперативного сообщения или подтверждения факта произошедшей аварии?',
        answers: [
          'В течение не более 24 часов',
          'В течение не более 36 часов',
          'В течение не более 48 часов',
          'В течение не более 60 часов',
        ],
        correctAnswers: ['В течение не более 24 часов'],
      },
      {
        code: '63717082',
        text:
          'Какова максимально допустимая температура сжатого воздуха (при работе с автономным компрессором), а также нагрева узлов зарядных устройств, через которые проходят взрывчатые вещества?',
        answers: ['40 °C', '50 °C', '60 °C', '70 °C'],
        correctAnswers: ['60 °C'],
      },
      {
        code: '63717083',
        text:
          'Что необходимо указывать в разрешении на постоянное применение взрывчатых веществ и изделий на их основе?',
        answers: [
          'Номер лицензии',
          'Сведения об изготовителе',
          'Сведения о сертификате',
          'Сведения о маркировке',
        ],
        correctAnswers: ['Сведения об изготовителе'],
      },
      {
        code: '63717084',
        text:
          'В каком случае запрещается производить заряжание шпуров (скважин) и монтаж взрывной сети на высоте более 2 м?',
        answers: [
          'Если работы проводятся с оборудованных подъемных площадок (помостов)',
          'Если работы проводятся с полков, примыкающих к забою',
          'Если работы проводятся с площадок подъемных механизмов или с применением погрузочно-доставочной техники',
          'Если работы проводятся с применением погрузочно-доставочной техники',
          'Если работы проводятся с лестниц',
        ],
        correctAnswers: ['Если работы проводятся с лестниц'],
      },
      {
        code: '63717085',
        text:
          'Для каких взрывчатых веществ минимальная масса партии, необходимая для проведения приемочных испытаний, составляет 5 т? Выберите два правильных варианта ответа.',
        answers: [
          'Для взрывчатых веществ, используемых для подземных работ при ручном заряжании шпуров и скважин',
          'Для взрывчатых веществ, используемых для подземных работ при механизированном заряжании шпуров и скважин',
          'Для предохранительных взрывчатых веществ',
          'Для взрывчатых веществ, предназначенных для открытых работ',
          'Для патронированных взрывчатых веществ, шашек и других штучных взрывчатых веществ, предназначенных для изготовления боевиков на открытых и подземных работах',
          'Для неэлектрических систем инициирования',
        ],
        correctAnswers: [
          'Для взрывчатых веществ, используемых для подземных работ при механизированном заряжании шпуров и скважин',
          'Для патронированных взрывчатых веществ, шашек и других штучных взрывчатых веществ, предназначенных для изготовления боевиков на открытых и подземных работах',
        ],
      },
      {
        code: '63717086',
        text:
          'Где перед заряжанием шпуров, а также перед взрыванием зарядов необходимо производить замер содержания углеводородных газов, паров жидких углеводородов?',
        answers: [
          'Только в забое',
          'Только в примыкающих к забою выработках на протяжении 20 м',
          'Только в месте нахождения взрывника',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63717087',
        text:
          'С каким документом должен под подпись ознакомиться взрывник перед началом взрывных работ?',
        answers: [
          'С производственной инструкцией',
          'С планом проведения взрывных работ',
          'С нарядом (заданием на выполнение работ)',
          'С проектом технологического массового взрыва',
        ],
        correctAnswers: ['С нарядом (заданием на выполнение работ)'],
      },
      {
        code: '63717088',
        text:
          'В какой срок после получения оперативного сообщения об утрате взрывчатых материалов промышленного назначения должна быть сформирована комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов?',
        answers: [
          'В срок не позднее 24 часов',
          'В срок не позднее 36 часов',
          'В срок не позднее 48 часов',
          'В срок не позднее 72 часов',
        ],
        correctAnswers: ['В срок не позднее 24 часов'],
      },
      {
        code: '63717089',
        text: 'В какой период необходимо измерять сопротивление заземлителей?',
        answers: [
          'В предгрозовой период',
          'Весной (1 раз в год)',
          'В период наибольшего просыхания грунта',
          'Весной и осенью (2 раза в год)',
        ],
        correctAnswers: ['В период наибольшего просыхания грунта'],
      },
      {
        code: '63717090',
        text:
          'Где следует хранить подлинники документов, включенных в материалы технического расследования случаев утраты взрывчатых материалов промышленного назначения?',
        answers: [
          'В Ростехнадзоре',
          'В архиве территориального муниципального образования',
          'В организации, где произошел случай утраты взрывчатых материалов промышленного назначения',
          'В профессиональной аварийно-спасательной службе',
        ],
        correctAnswers: [
          'В организации, где произошел случай утраты взрывчатых материалов промышленного назначения',
        ],
      },
      {
        code: '63717091',
        text: 'Кто должен ежемесячно проверять состояние зарядных устройств?',
        answers: [
          'Ростехнадзор',
          'Комиссия организации, эксплуатирующей зарядное оборудование',
          'Профессиональная аварийно-спасательная служба (формирование)',
          'Предприятие – изготовитель оборудования',
        ],
        correctAnswers: [
          'Комиссия организации, эксплуатирующей зарядное оборудование',
        ],
      },
      {
        code: '63717092',
        text:
          'Какие из перечисленных камер относятся к вспомогательным камерам подземного склада?',
        answers: [
          'Только камеры для размещения средств механизации взрывных работ',
          'Только камеры для хранения средств инициирования',
          'Только камеры для хранения взрывчатых веществ',
          'Все перечисленные камеры',
        ],
        correctAnswers: [
          'Только камеры для размещения средств механизации взрывных работ',
        ],
      },
      {
        code: '63717093',
        text:
          'На какую длину от устья разрешается вынимать из шпуров отказавших зарядов забоечный материал для установки направления вспомогательных шпуров?',
        answers: [
          'На длину до 20 см',
          'На длину до 25 см',
          'На длину до 40 см',
          'На длину до 50 см',
        ],
        correctAnswers: ['На длину до 20 см'],
      },
      {
        code: '63717094',
        text:
          'Кто имеет право допускать людей к месту взрыва после его проведения?',
        answers: [
          'Лицо, осуществляющее руководство взрывными работами, или по его поручению взрывник',
          'Технический руководитель организации',
          'Руководитель подразделения организации, в котором производились взрывные работы',
          'После подачи сигнала "Отбой" отсутствует необходимость в специальном допуске',
        ],
        correctAnswers: [
          'Лицо, осуществляющее руководство взрывными работами, или по его поручению взрывник',
        ],
      },
      {
        code: '63717095',
        text:
          'Какими должны быть полы в хранилищах взрывчатых материалов поверхностного склада?',
        answers: [
          'Только деревянными',
          'Только бетонными',
          'Только глинобитными',
          'Любыми из перечисленных',
          'Только асфальтированными',
        ],
        correctAnswers: ['Любыми из перечисленных'],
      },
      {
        code: '63717096',
        text:
          'В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?',
        answers: [
          'В хранилищах натриевой селитры',
          'В хранилищах кальциевой селитры',
          'В хранилищах аммиачной селитры',
          'Не регламентируется',
        ],
        correctAnswers: ['В хранилищах аммиачной селитры'],
      },
      {
        code: '63717097',
        text:
          'Взрывчатые материалы какой массой разрешается переносить взрывнику при переноске в сумках без средств инициирования?',
        answers: [
          'Не более 24 кг',
          'Не более 28 кг',
          'Не более 32 кг',
          'Не более 36 кг',
        ],
        correctAnswers: ['Не более 24 кг'],
      },
      {
        code: '63717098',
        text:
          'Какое требование к спуску-подъему взрывчатых материалов при проходке шурфов, оборудованных ручными воротками и лебедками, указано верно?',
        answers: [
          'В забое допускается нахождение лиц, не связанных со взрывными работами',
          'Спуск-подъем взрывчатых материалов должны осуществлять не менее 3 работников',
          'Вороток или лебедку необходимо оборудовать храповыми устройствами или автоматически действующими тормозами, а прицепной крюк – предохранительным замком',
          'Спуск-подъем взрывчатых веществ и средств инициирования необходимо проводить одновременно',
        ],
        correctAnswers: [
          'Вороток или лебедку необходимо оборудовать храповыми устройствами или автоматически действующими тормозами, а прицепной крюк – предохранительным замком',
        ],
      },
      {
        code: '63717099',
        text:
          'В каком случае разрешается доставка аммиачно-селитренных взрывчатых веществ к местам проведения взрывных работ в подземных выработках в ковшах погрузочно-доставочных машин от участковых пунктов хранения и мест выгрузки взрывчатых материалов?',
        answers: [
          'В случае загрузки ковша не более 2/3 по его высоте',
          'В случае совместной доставки аммиачно-селитренных взрывчатых веществ и средств инициирования',
          'В случае одновременной доставки средств инициирования и аммиачно-селитренных взрывчатых веществ в ковше (не более 10 кг взрывчатых материалов)',
          'В случае доставки аммиачно-селитренных взрывчатых веществ без средств инициирования в ковше (не более 24 кг взрывчатых материалов)',
        ],
        correctAnswers: ['В случае загрузки ковша не более 2/3 по его высоте'],
      },
      {
        code: '63717100',
        text:
          'На сколько метров контур зоны монтажа электровзрывной сети на земной поверхности должен превышать контур электровзрывной сети?',
        answers: ['На 50 м', 'На 55 м', 'На 60 м', 'На 65 м'],
        correctAnswers: ['На 50 м'],
      },
      {
        code: '63717101',
        text:
          'Что из перечисленного разрешается делать взрывникам при пневмозаряжании?',
        answers: [
          'Производить одновременное заряжание взрывчатых веществ, содержащих в своем составе тротил или алюминиевую пудру',
          'Использовать для механизированного заряжания взрывчатые вещества, собранные из просыпи',
          'Входить в камеры при пневмозаряжании только после прекращения процесса механизированного заряжания и полного проветривания камеры',
          'Производить одновременно в одном забое ручное формирование основного заряда с применением высокочувствительных к механическим воздействиям взрывчатых веществ и пневматическое заряжание',
        ],
        correctAnswers: [
          'Входить в камеры при пневмозаряжании только после прекращения процесса механизированного заряжания и полного проветривания камеры',
        ],
      },
      {
        code: '63717102',
        text:
          'Где допускается использовать слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без разминания или измельчения при взрывных работах?',
        answers: [
          'В шахтах (рудниках), не опасных по газу',
          'В шахтах (рудниках), разрабатывающих пласты (рудные тела), не опасные по взрывам пыли',
          'На земной поверхности',
        ],
        correctAnswers: ['На земной поверхности'],
      },
      {
        code: '63717103',
        text: 'Кто имеет право переносить средства инициирования?',
        answers: [
          'Взрывник',
          'Заведующий складом взрывчатых веществ',
          'Раздатчик взрывных материалов',
          'Рукоятчик',
        ],
        correctAnswers: ['Взрывник'],
      },
      {
        code: '63717104',
        text:
          'К какому подклассу относятся взрывчатые вещества и изделия на их основе, способные взрываться массой?',
        answers: [
          'К подклассу 1.1',
          'К подклассу 1.2',
          'К подклассу 1.3',
          'К подклассу 1.4',
        ],
        correctAnswers: ['К подклассу 1.1'],
      },
      {
        code: '63717105',
        text:
          'На какой срок допускается размещать зарядные машины, загруженные взрывчатыми веществами, на специально выделенной площадке территории поверхностного склада взрывчатых материалов?',
        answers: [
          'Не более чем на сутки',
          'Не более чем на 2 суток',
          'Не более чем на 3 суток',
          'Не более чем на 5 суток',
        ],
        correctAnswers: ['Не более чем на 2 суток'],
      },
      {
        code: '63717106',
        text:
          'В течение какого срока организация должна предоставить информацию о выполнении мероприятий, предложенных комиссией по техническому расследованию причин аварии, в территориальный орган уполномоченного органа?',
        answers: [
          'В течение 15 календарных дней',
          'В течение 10 рабочих дней',
          'В течение 10 календарных дней',
          'В течение 7 рабочих дней',
        ],
        correctAnswers: ['В течение 10 рабочих дней'],
      },
      {
        code: '63717107',
        text:
          'Что является основанием для отказа в выдаче разрешения на ведение работ со взрывчатыми материалами промышленного назначения при соответствии заявительных документов требованиям законодательства Российской Федерации и нормативных правовых актов в области промышленной безопасности опасных производственных объектов?',
        answers: [
          'Планирование взрывных работ в прибрежной водоохранной зоне',
          'Планирование взрывных работ в районе населенных пунктов',
          'Истечение срока рассмотрения заявления',
          'Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений',
        ],
        correctAnswers: [
          'Наличие в составе материалов заявителя неполных, искаженных или недостоверных сведений',
        ],
      },
      {
        code: '63717108',
        text:
          'Какой должна быть длина огнепроводного шнура контрольной трубки, используемой для контроля времени, затрачиваемого на зажигание, при поджигании 5 трубок и более на земной поверхности?',
        answers: [
          'Не менее чем на 45 см короче шнура самой короткой из применяемых зажигательных трубок, но не менее 25 см',
          'Не менее чем на 50 см короче шнура самой короткой из применяемых зажигательных трубок, но не менее 30 см',
          'Не менее чем на 55 см короче шнура самой короткой из применяемых зажигательных трубок, но не менее 35 см',
          'Не менее чем на 60 см короче шнура самой короткой из применяемых зажигательных трубок, но не менее 40 см',
        ],
        correctAnswers: [
          'Не менее чем на 60 см короче шнура самой короткой из применяемых зажигательных трубок, но не менее 40 см',
        ],
      },
      {
        code: '63717109',
        text:
          'При какой площади поперечного сечения токоотводов, поврежденных ржавчиной, необходима их замена?',
        answers: [
          'При площади менее 65 мм²',
          'При площади менее 60 мм²',
          'При площади менее 55 мм²',
          'При площади менее 50 мм²',
        ],
        correctAnswers: ['При площади менее 50 мм²'],
      },
      {
        code: '63717110',
        text:
          'Какое требование к мерам защиты от статического электричества технологического оборудования при взрывных работах указано верно?',
        answers: [
          'Технологическое оборудование, где возможно образование и накопление зарядов статического электричества, должно быть изготовлено из неэлектропроводных материалов',
          'Запрещается последовательно включать несколько заземляющих аппаратов, агрегатов или трубопроводов в заземляющую шину',
          'Запрещается присоединять к внутреннему контуру заземления аппараты, соединенные трубопроводами с общей системой аппаратов и емкостей',
          'Заземляющие устройства запрещается объединять с защитным заземлением электрооборудования',
        ],
        correctAnswers: [
          'Запрещается последовательно включать несколько заземляющих аппаратов, агрегатов или трубопроводов в заземляющую шину',
        ],
      },
      {
        code: '63717111',
        text:
          'Где и в каких условиях проводятся приемочные испытания взрывчатых веществ и изделий на их основе?',
        answers: [
          'В организациях, ведущих исследовательские работы, в лабораторных условиях',
          'В экспертных организациях, на испытательных полигонах',
          'В организациях, ведущих взрывные работы, в производственных условиях',
          'В организациях - изготовителях взрывчатых материалов на специальных стендах',
        ],
        correctAnswers: [
          'В организациях, ведущих взрывные работы, в производственных условиях',
        ],
      },
      {
        code: '63717112',
        text: 'За что может быть изъята у взрывника единая книжка взрывника?',
        answers: [
          'За неоднократные нарушения режима рабочего времени',
          'За нарушение установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов',
          'За появление на рабочем месте в состоянии алкогольного опьянения',
          'За нарушения требований охраны труда при выполнении взрывных работ',
        ],
        correctAnswers: [
          'За нарушение установленного порядка хранения, транспортирования, использования или учета взрывчатых материалов, которое привело или могло привести к несчастному случаю, аварии или утрате взрывчатых материалов',
        ],
      },
      {
        code: '63717113',
        text:
          'Каким документом определяются меры безопасности, а также место и порядок погрузки (выгрузки) взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и надшахтных зданиях?',
        answers: [
          'Приказом Ростехнадзора',
          'Распоряжением МЧС России',
          'Распорядительным документом шахты (рудника)',
          'Указанием профессиональной аварийно-спасательной службы',
        ],
        correctAnswers: ['Распорядительным документом шахты (рудника)'],
      },
      {
        code: '63717114',
        text:
          'Какие случаи утрат взрывчатых материалов промышленного назначения, произошедшие в организациях и на объектах, поднадзорных Ростехнадзору, подлежат техническому расследованию и учету?',
        answers: [
          'Только случаи утрат в результате пожаров',
          'Только случаи утрат в результате стихийных бедствий',
          'Только случаи утрат в результате промышленных аварий',
          'Все случаи утрат взрывчатых материалов',
        ],
        correctAnswers: ['Все случаи утрат взрывчатых материалов'],
      },
      {
        code: '63717115',
        text:
          'В каком случае обеспечивается защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения посредством присоединения металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?',
        answers: [
          'Если используются бронированные кабели, проложенные в земле',
          'Если используются небронированные кабели',
          'Если кабели присоединены к воздушной линии',
        ],
        correctAnswers: [
          'Если используются бронированные кабели, проложенные в земле',
        ],
      },
      {
        code: '63717116',
        text:
          'Что разрешается делать, если работы по ликвидации отказа не могут быть закончены в данную смену?',
        answers: [
          'Продолжать выполнять работы взрывнику очередной смены с соответствующим инструктажем и отметкой в выдаваемом ему наряде-путевке',
          'Продолжать выполнять работы до их завершения только взрывнику данной смены',
          'Продолжать работы до их завершения только взрывнику данной смены совместно с взрывником очередной смены',
          'Продолжать работы только в смену выполнявшего их работника с ее переносом на другой день',
        ],
        correctAnswers: [
          'Продолжать выполнять работы взрывнику очередной смены с соответствующим инструктажем и отметкой в выдаваемом ему наряде-путевке',
        ],
      },
      {
        code: '63717117',
        text:
          'Какова максимально допустимая скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?',
        answers: ['5 км/ч', '15 км/ч', '25 км/ч', '35 км/ч'],
        correctAnswers: ['15 км/ч'],
      },
      {
        code: '63717118',
        text:
          'В какой документ заносятся результаты замеров сопротивления заземлителей молниезащиты?',
        answers: [
          'В ремонтный журнал',
          'В сменный журнал',
          'В ведомость состояния заземлителей молниезащиты',
          'В протокол осмотра заземлителей',
        ],
        correctAnswers: ['В ведомость состояния заземлителей молниезащиты'],
      },
      {
        code: '63717119',
        text:
          'На каких складах взрывчатых материалов необходимо устраивать молниезащиту?',
        answers: [
          'Только на поверхностных складах',
          'Только на складах, расположенных в черте населенного пункта',
          'На всех складах с высокой грозовой активностью местности',
          "На всех складах, независимо от грозовой активности местности, за исключением складов, расположенных выше 66°33' северной широты, которые допускается не оборудовать молниезащитой",
        ],
        correctAnswers: [
          "На всех складах, независимо от грозовой активности местности, за исключением складов, расположенных выше 66°33' северной широты, которые допускается не оборудовать молниезащитой",
        ],
      },
      {
        code: '63717120',
        text: 'Где допускается кратковременно хранить взрывчатые материалы?',
        answers: [
          'В тамбурах хранилищ',
          'В здании выдачи взрывчатых материалов',
          'В здании подготовки взрывчатых материалов',
          'На кратковременных складах (для производства работ кратковременного характера: в контейнерах, неиспользуемых строениях, сараях, землянках и подобных сооружениях)',
        ],
        correctAnswers: [
          'На кратковременных складах (для производства работ кратковременного характера: в контейнерах, неиспользуемых строениях, сараях, землянках и подобных сооружениях)',
        ],
      },
      {
        code: '63717121',
        text:
          'На какое расстояние от скважины должны быть убраны буровые установки, не имеющие приспособлений для заряжания, в сложных горно-геологических условиях?',
        answers: [
          'На расстояние не менее 5 м',
          'На расстояние не менее 15 м',
          'На расстояние не менее 10 м',
          'На расстояние не менее 20 м',
        ],
        correctAnswers: ['На расстояние не менее 10 м'],
      },
      {
        code: '63717122',
        text:
          'Кто должен утверждать маршруты транспортирования взрывчатых материалов от склада на места работ (в пределах опасного производственного объекта)?',
        answers: [
          'Представитель Ростехнадзора',
          'Представитель МЧС России',
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
          'Руководитель профессиональной аварийно-спасательной службы',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63717123',
        text:
          'Какие требования предъявляются к устройству хранилищ складов взрывчатых материалов?',
        answers: [
          'В хранилищах складов взрывчатых материалов полы должны быть ровными, без щелей, а стены побелены или покрашены',
          'Каждое хранилище взрывчатых материалов должно проветриваться и защищаться от проникновения воды и снега',
          'Хранилища взрывчатых материалов должны обеспечиваться приточно-вытяжным естественным проветриванием',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63717124',
        text:
          'Что допускается при устройстве заземления и защите от образования статического электричества в пневмозарядных устройствах?',
        answers: [
          'Пневмозаряжание по одному трубопроводу взрывчатых веществ разных типов',
          'Пневматическое заряжание шпуров в подземных горных выработках при относительной влажности рудничного воздуха более 50 % зарядчиками с металлической зарядной трубкой или с электропроводящим зарядным трубопроводом длиной не более 5 м',
          'Пневмозаряжание гранулированными алюмо- и тротилсодержащими взрывчатыми веществами без предварительного их увлажнения',
          'Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 10⁷ Ом x м',
        ],
        correctAnswers: [
          'Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 10⁷ Ом x м',
        ],
      },
      {
        code: '63717125',
        text:
          'В каком случае допускается одновременное взрывание в обоих крыльях калотты?',
        answers: [
          'В случае рассечки верхних штолен из восстающих',
          'В случае ведения горных работ в городских условиях и наличии большого притока воды',
          'В случае проходки тоннелей с применением электровзрывания',
          'Ни в каком случае',
        ],
        correctAnswers: ['Ни в каком случае'],
      },
      {
        code: '63717126',
        text:
          'Что из перечисленного должна включать маркировка упаковки взрывчатых веществ и изделий на их основе, а также маркировка изделий на основе взрывчатых веществ?',
        answers: [
          'Только наименование (условное обозначение) взрывчатого вещества или изделия, а также гарантийный срок хранения',
          'Только наименование предприятия-изготовителя (поставщика и (или) импортера), а также товарный знак (при его наличии) и его юридический адрес',
          'Только классификационные обозначения в соответствии с ТР ТС 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"  и обозначение соответствия транспортной тары по механической прочности',
          'Только обозначение технических условий или стандартов, в соответствии с которыми изготовлена продукция, если эти стандарты были применены',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63717127',
        text:
          'Взрывчатые материалы какой массой разрешается переносить взрывнику при одновременной доставке вручную средств инициирования и взрывчатых веществ?',
        answers: [
          'Не более 10 кг',
          'Не более 14 кг',
          'Не более 16 кг',
          'Не более 20 кг',
        ],
        correctAnswers: ['Не более 10 кг'],
      },
      {
        code: '63717128',
        text:
          'Какие из перечисленных взрывчатых веществ и изделий относятся к группе совместимости (опасности) С?',
        answers: [
          'Огнепроводные шнуры',
          'Сигнальные ракеты',
          'Метательные взрывчатые вещества и изделия (бездымный порох)',
          'Детонирующие шнуры',
        ],
        correctAnswers: [
          'Метательные взрывчатые вещества и изделия (бездымный порох)',
        ],
      },
      {
        code: '63717129',
        text:
          'Кому из перечисленных работников разрешается присутствовать при погрузке, разгрузке, перемещении взрывчатых материалов по стволу шахты в околоствольном дворе и надшахтном здании около ствола?',
        answers: [
          'Только взрывнику, раздатчику, рукоятчику',
          'Только нагружающим и разгружающим взрывчатые материалы рабочим',
          'Только стволовому и лицу, ответственному за доставку взрывчатых материалов',
          'Всем перечисленным работникам',
        ],
        correctAnswers: ['Всем перечисленным работникам'],
      },
      {
        code: '63717130',
        text:
          'Какие взрывы зарядов на земной поверхности относятся к массовым?',
        answers: [
          'Единичные заряды в выработках протяженностью более 7 м',
          'Взрывы смонтированных в общую взрывную сеть 2 и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки, а также единичных зарядов в выработках протяженностью более 10 м',
          'Взрывы, при осуществлении которых требуется большее время для проветривания и возобновления работ в руднике (шахте, участке), чем это предусмотрено в расчете при повседневной организации работ',
          'Специальные взрывы по обрушению потолочин камер, междуэтажных целиков на всю высоту этажа',
        ],
        correctAnswers: [
          'Взрывы смонтированных в общую взрывную сеть 2 и более скважинных, котловых или камерных зарядов, независимо от протяженности заряжаемой выработки, а также единичных зарядов в выработках протяженностью более 10 м',
        ],
      },
      {
        code: '63717131',
        text:
          'В каких случаях допускается оставлять взрывчатые материалы на местах работ без постоянного надзора (охраны)?',
        answers: [
          'В случаях если взрывчатые материалы хранятся в отдельных металлических ящиках и контейнерах (сейфах)',
          'В случаях если взрывчатые материалы защищены от атмосферных осадков',
          'В случаях, утвержденных распорядительным документом организации, ведущей взрывные работы',
          'Ни в каких случаях',
        ],
        correctAnswers: ['Ни в каких случаях'],
      },
      {
        code: '63717132',
        text:
          'Через сколько минут после последнего взрыва разрешается подходить к месту взрыва при отсутствии отказов?',
        answers: [
          'Не ранее чем через 2 минуты',
          'Не ранее чем через 3 минуты',
          'Не ранее чем через 4 минуты',
          'Не ранее чем через 5 минут',
        ],
        correctAnswers: ['Не ранее чем через 5 минут'],
      },
      {
        code: '63717133',
        text:
          'Чем следует очищать оборудование при наличии несмываемых остатков взрывчатых веществ по окончании заряжания? Выберите два правильных варианта ответа.',
        answers: [
          'Паром',
          'Сжатым воздухом',
          'Горячей водой',
          'Холодным мыльным раствором',
        ],
        correctAnswers: ['Паром', 'Горячей водой'],
      },
      {
        code: '63717134',
        text:
          'При каком отставании забоя параллельной выработки от забоя выработки, в которой производится взрывание, разрешается не выводить людей из параллельной выработки при работе в подземных выработках угольных и нефтяных шахт?',
        answers: [
          'При отставании на расстояние не менее 35 м',
          'При отставании на расстояние не менее 40 м',
          'При отставании на расстояние не менее 45 м',
          'При отставании на расстояние более 50 м',
        ],
        correctAnswers: ['При отставании на расстояние более 50 м'],
      },
      {
        code: '63717135',
        text:
          'Что следует сделать с неиспользованными боевиками после взрывания зарядов?',
        answers: [
          'Сдать на хранение на постоянный склад взрывчатых материалов',
          'Сдать на хранение на кратковременный склад взрывчатых материалов',
          'Сдать на хранение на передвижной склад взрывчатых материалов',
          'Уничтожить взрыванием в установленном порядке',
        ],
        correctAnswers: ['Уничтожить взрыванием в установленном порядке'],
      },
      {
        code: '63717136',
        text:
          'Кому взрывники по окончании взрывных работ обязаны сдать остатки взрывчатых материалов?',
        answers: [
          'Лицу, ответственному за ведение взрывных работ',
          'Взрывнику следующей смены',
          'Лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов), лично',
          'Горному мастеру',
        ],
        correctAnswers: [
          'Лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов), лично',
        ],
      },
      {
        code: '63717137',
        text:
          'Какое требование предъявляется к поверхностным постоянным складам?',
        answers: [
          'Склады должны иметь противопожарный водоем (резервуар, скважину, насосы, гидранты)',
          'Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации',
          'Хранилища взрывчатых веществ и другие объекты, связанные с приемом, хранением и отгрузкой взрывчатых материалов, должны располагаться за территорией склада',
        ],
        correctAnswers: [
          'Склады должны иметь противопожарный водоем (резервуар, скважину, насосы, гидранты)',
        ],
      },
      {
        code: '63717138',
        text:
          'Что из перечисленного необходимо защищать как от прямых ударов, так и от вторичных воздействий молний?',
        answers: [
          'Площадки для хранения взрывчатых материалов в контейнерах',
          'Пункты отстоя транспортных средств со взрывчатыми материалами',
          'Пункты изготовления боевиков с электродетонаторами',
        ],
        correctAnswers: ['Пункты изготовления боевиков с электродетонаторами'],
      },
      {
        code: '63717139',
        text:
          'Какой должна быть минимальная величина забойки для всех забоечных материалов при взрывании по породе и глубине шпуров более 1 м?',
        answers: ['0,5 м', '0,4 м', '0,3 м', '0,2 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63717140',
        text:
          'Какие документы прилагаются к заявлению о выдаче разрешения на постоянное применение взрывчатых веществ и изделий на их основе?',
        answers: [
          'Программа и методика приемочных испытаний; акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства – при наличии); руководство (инструкция) по применению',
          'Сведения о разработчике взрывчатого материала (полное и (при наличии) сокращенное наименование организации, место нахождения юридического лица и почтовый адрес, ИНН, телефон, факс и (при наличии) адрес электронной почты)',
          'Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению',
          'Акт контрольных испытаний; акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства – при наличии); руководство (инструкция) по применению',
        ],
        correctAnswers: [
          'Акт приемочных испытаний опытной партии; экспертное заключение по промышленной безопасности; технические условия (стандарт) (для взрывчатых материалов иностранного производства - при наличии); руководство (инструкция) по применению',
        ],
      },
      {
        code: '63717141',
        text:
          'Какие условия ведения взрывных работ в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль, указаны верно?',
        answers: [
          'В подготовительных выработках, проводимых по углю с подрывкой боковых пород, взрывание зарядов в шпурах по углю и породе следует проводить только раздельно, одновременное взрывание не допускается',
          'Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания не должно превышать 320 миллисекунд при применении взрывчатых веществ IV класса',
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки и взрывание в каждом из них производить раздельно',
          'Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания не должно превышать 420 миллисекунд при применении взрывчатых веществ V и VI классов',
        ],
        correctAnswers: [
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки и взрывание в каждом из них производить раздельно',
        ],
      },
      {
        code: '63717142',
        text:
          'Что из перечисленного должно находиться за запретной зоной склада?',
        answers: [
          'Хранилища взрывчатых веществ',
          'Хранилища средств инициирования',
          'Здание (помещение) для выдачи взрывчатых материалов',
          'Административно-бытовое помещение',
        ],
        correctAnswers: ['Административно-бытовое помещение'],
      },
      {
        code: '63717143',
        text:
          'Как необходимо оповещать руководителя организации, объекты которой попали в опасную зону, о месте и времени производства взрывных работ?',
        answers: [
          'Письменно не менее чем за сутки',
          'Устным или письменным сообщением не менее чем за 20 часов',
          'Только посредством телефонной связи не менее чем за 20 часов',
          'Любым возможным способом, но не менее чем за 12 часов',
        ],
        correctAnswers: ['Письменно не менее чем за сутки'],
      },
      {
        code: '63717144',
        text:
          'В течение какого времени в организации должны храниться Книга учета прихода и расхода взрывчатых материалов и Книга учета выдачи и возврата взрывчатых материалов?',
        answers: [
          'В течение 6 месяцев и более',
          'В течение 1 года и более',
          'В течение 2 лет и более',
          'В течение 3 лет и более',
        ],
        correctAnswers: ['В течение 3 лет и более'],
      },
      {
        code: '63717145',
        text:
          'Какой документ должен быть выдан на взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях?',
        answers: [
          'Инструкция по применению на всех государственных языках государств - членов Таможенного союза',
          'Лицензия на применение, выданная Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства - члена Таможенного союза',
          'Разрешение на постоянное применение, выданное всеми уполномоченными органами в области промышленной безопасности государств - членов Таможенного союза',
        ],
        correctAnswers: [
          'Разрешение на постоянное применение, выданное одним из уполномоченных органов в области промышленной безопасности государства - члена Таможенного союза',
        ],
      },
      {
        code: '63717146',
        text:
          'Когда оборудование и здание пунктов производства и подготовки взрывчатых веществ должны быть полностью освобождены от взрывоопасных продуктов?',
        answers: [
          'Перед нерабочей сменой',
          'Когда появляется необходимость поддержания части оборудования в рабочем состоянии',
          'Когда пункт останавливается более чем на 2 суток',
          'Перед проверкой контролирующими органами',
        ],
        correctAnswers: ['Когда пункт останавливается более чем на 2 суток'],
      },
      {
        code: '63717147',
        text:
          'Каким из перечисленных способов производится электрическое взрывание?',
        answers: [
          'Непосредственно от силовой сети',
          'Непосредственно от осветительной сети',
          'С применением взрывных приборов (машинок)',
          'Непосредственно от контактной сети',
        ],
        correctAnswers: ['С применением взрывных приборов (машинок)'],
      },
      {
        code: '63717148',
        text:
          'Какое определение соответствует понятию "средства инициирования" согласно техническому регламенту Таможенного союза от 20.07.2012 № 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          'Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации',
          'Компактная масса взрывчатого вещества конечных размеров, заключенная в оболочку или без нее, предназначенная для использования в изготовленном виде самостоятельно или в сочетании с другими взрывчатыми веществами',
          'Высокочувствительное взрывчатое вещество, легко детонирующее от простейших начальных импульсов (удара, трения, нагрева, искрового разряда), предназначенное для возбуждения детонации или воспламенения других взрывчатых веществ',
          'Средство или комплекс средств, предназначенных для защиты взрывчатых веществ и изделий на их основе от повреждений и исключения воздействия на них атмосферных явлений',
        ],
        correctAnswers: [
          'Изделия, содержащие взрывчатое вещество и предназначенные для возбуждения или передачи и возбуждения детонации',
        ],
      },
      {
        code: '63717149',
        text:
          'Каким должен быть состав комиссии по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: [
          'Не менее 2 человек',
          'Нечетное количество членов комиссии, но не менее 5 человек',
          'Не менее 10 человек',
          'Четное количество членов комиссии, но не менее 4 человек',
        ],
        correctAnswers: [
          'Нечетное количество членов комиссии, но не менее 5 человек',
        ],
      },
      {
        code: '63717150',
        text:
          'Сколько времени разрешается хранить незатаренную аммиачную селитру в бункере без перегрузки или рыхления?',
        answers: [
          'Не более 10 дней',
          'Не более 12 дней',
          'Не более 15 дней',
          'Не более 20 дней',
        ],
        correctAnswers: ['Не более 10 дней'],
      },
      {
        code: '63717151',
        text:
          'С какой периодичностью следует проводить определение относительной влажности воздуха в забойном пространстве после внедрения пневматического заряжания?',
        answers: [
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в квартал'],
      },
      {
        code: '63717152',
        text:
          'В течение какого времени с момента возникновения аварии или инцидента на опасном производственном объекте должно быть передано оперативное сообщение об этом?',
        answers: [
          'В течение 24 часов',
          'В течение 36 часов',
          'В течение 48 часов',
          'В течение 60 часов',
        ],
        correctAnswers: ['В течение 24 часов'],
      },
      {
        code: '63717153',
        text:
          'Каким должно быть расстояние между вагонетками со взрывчатыми веществами и вагонетками со средствами инициирования, а также между этими вагонетками и локомотивом при их перевозке в одном железнодорожном составе?',
        answers: [
          'Не менее 3 м',
          'Не менее 2 м',
          'Не менее 1,5 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 3 м'],
      },
      {
        code: '63717154',
        text:
          'Что запрещается делать при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и (или) пыли? Выберите два правильных варианта ответа.',
        answers: [
          'Проводить замеры концентрации метана по всему сечению забоя перед каждым заряжанием и взрыванием шпуров, а также при осмотре забоя после взрывания',
          'Выполнять взрывные работы при содержании метана 1 % и более в забоях и в примыкающих выработках на протяжении 20 м от них',
          'Предварительно рыхлить угольный массив в очистных забоях впереди комбайнов, стругов',
          'Проводить взрывные работы только в забоях выработок, непрерывно и устойчиво проветриваемых, при осуществлении необходимых мер пылевзрывозащиты',
        ],
        correctAnswers: [
          'Выполнять взрывные работы при содержании метана 1 % и более в забоях и в примыкающих выработках на протяжении 20 м от них',
          'Предварительно рыхлить угольный массив в очистных забоях впереди комбайнов, стругов',
        ],
      },
      {
        code: '63717155',
        text:
          'Какое из перечисленных утверждений соответствует требованиям к условиям заряжания, массе зарядов взрывчатых веществ и длине забойки?',
        answers: [
          'В шпурах нефтяных шахт глубиной от 1 до 1,5 м заряд должен занимать не более 2/3 их длины',
          'При наличии в забое нефтяных шахт нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе',
          'В шпурах нефтяных шахт глубиной более 1,5 м заряд должен занимать не более 1/2 их длины',
          'В продуктивном пласте нефтяных шахт запрещается применять шпуры глубиной менее 1,5 м',
        ],
        correctAnswers: [
          'При наличии в забое нефтяных шахт нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе',
        ],
      },
      {
        code: '63717156',
        text:
          'Что необходимо сделать перед началом заряжания шпуров и скважин при ведении взрывных работ в подземных выработках?',
        answers: [
          'Только обеспечить проветривание забоя',
          'Только убрать ранее взорванную в забое горную массу',
          'Только вывести людей, не связанных с выполнением взрывных работ, за пределы опасной зоны в места, определенные паспортом (проектом) буровзрывных (взрывных) работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63717157',
        text:
          'Какие требования к оросителям, установленным на противопожарном водопроводе в каждом забое продуктивного пласта, указаны верно?',
        answers: [
          'Должно быть установлено 2 оросителя на расстоянии не более 8 м от забоя, водоразбрызгиватели должны приводиться в действие за 5 минут до взрывания зарядов',
          'Должен быть установлен 1 ороситель на расстоянии не более 8 м от забоя, водоразбрызгиватель должен приводиться в действие за 10 минут до взрывания зарядов',
          'Должен быть установлен 1 ороситель на расстоянии не более 10 м от забоя, водоразбрызгиватель должен приводиться в действие за 15 минут до взрывания зарядов',
          'Должно быть установлено не менее 2 оросителей на расстоянии не более 10 м от забоя, водоразбрызгиватели должны приводиться в действие за 8 минут до взрывания зарядов',
        ],
        correctAnswers: [
          'Должно быть установлено 2 оросителя на расстоянии не более 8 м от забоя, водоразбрызгиватели должны приводиться в действие за 5 минут до взрывания зарядов',
        ],
      },
      {
        code: '63717158',
        text: 'Как следует вводить огнепроводный шнур в капсюль-детонатор?',
        answers: [
          'До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения',
          'До соприкосновения с чашечкой капсюля-детонатора вращением в одну сторону',
          'До соприкосновения с чашечкой капсюля-детонатора попеременным вращением в разные стороны',
        ],
        correctAnswers: [
          'До соприкосновения с чашечкой капсюля-детонатора прямым движением без вращения',
        ],
      },
      {
        code: '63717159',
        text:
          'Для каких взрывных работ применяются предохранительные взрывчатые вещества VI класса при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и пыли?',
        answers: [
          'Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой',
          'Для ликвидации зависаний горной массы в углеспускных выработках',
          'Для дробления негабаритов наружными зарядами',
          'Для взрывного перебивания деревянных стоек при посадке кровли',
        ],
        correctAnswers: [
          'Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой',
        ],
      },
      {
        code: '63717160',
        text: 'Куда передается акт об уничтожении взрывчатых материалов?',
        answers: [
          'В бухгалтерию предприятия',
          'В службу охраны труда',
          'В службу безопасности',
          'На склад взрывчатых материалов',
        ],
        correctAnswers: ['На склад взрывчатых материалов'],
      },
      {
        code: '63717161',
        text:
          'Как необходимо удалять частицы на внутренней поверхности гильзы капсюля-детонатора перед изготовлением зажигательной (контрольной) трубки?',
        answers: [
          'Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца',
          'Выдуванием',
          'С помощью медицинского пинцета',
          'С помощью иглы',
        ],
        correctAnswers: [
          'Легким постукиванием открытым дульцем капсюля-детонатора о ноготь пальца',
        ],
      },
      {
        code: '63717162',
        text:
          'На каком расстоянии от мест посадки людей в пассажирские вагоны и погрузки-выгрузки горной массы допускается размещать раздаточные камеры?',
        answers: [
          'На расстоянии не менее 200 м',
          'На расстоянии не менее 180 м',
          'На расстоянии не менее 150 м',
          'На расстоянии не менее 120 м',
        ],
        correctAnswers: ['На расстоянии не менее 200 м'],
      },
      {
        code: '63717163',
        text:
          'Какие требования к вместимости подземных складов и камер взрывчатых материалов указаны верно? Выберите два правильных варианта ответа.',
        answers: [
          'Вместимость камеры на складах камерного типа не должна превышать 4000 кг взрывчатых веществ',
          'На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать 7-суточного запаса взрывчатых веществ и 15-суточного запаса средств инициирования',
          'Вместимость камеры на складах камерного типа не должна превышать 2000 кг взрывчатых веществ',
          'Раздаточные камеры вместимостью до 2000 кг взрывчатых веществ могут оборудоваться на расширении выработок горизонтов',
        ],
        correctAnswers: [
          'На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать 7-суточного запаса взрывчатых веществ и 15-суточного запаса средств инициирования',
          'Вместимость камеры на складах камерного типа не должна превышать 2000 кг взрывчатых веществ',
        ],
      },
      {
        code: '63717164',
        text:
          'Какие требования к ограждению погрузочно-разгрузочной площадки взрывчатых материалов указаны верно?',
        answers: [
          'Ограждение должно быть выполнено из сплошного металлического забора, должно находиться на расстоянии не менее 12 м от места погрузки (выгрузки) транспортных средств, при этом высота ограды должна составлять не менее 1,8 м',
          'Ограждение должно быть выполнено из колючей проволоки, должно находиться на расстоянии не менее 15 м от места погрузки (выгрузки) транспортных средств, при этом высота ограды должна составлять не менее 2 м',
          'Ограждение должно быть выполнено из колючей проволоки, должно находиться на расстоянии не менее 12 м от места погрузки (выгрузки) транспортных средств, при этом высота ограды должна составлять не менее 1,8 м',
          'Ограждение должно быть выполнено из сплошного металлического забора, должно находиться на расстоянии не менее 10 м от места погрузки (выгрузки) транспортных средств, при этом высота ограды должна составлять не менее 1,5 м',
        ],
        correctAnswers: [
          'Ограждение должно быть выполнено из колючей проволоки, должно находиться на расстоянии не менее 15 м от места погрузки (выгрузки) транспортных средств, при этом высота ограды должна составлять не менее 2 м',
        ],
      },
      {
        code: '63717165',
        text:
          'Какие из перечисленных конвейеров, транспортирующих пожаровзрывоопасные вещества, запрещается использовать без устройства блокировки?',
        answers: [
          'Только ленточные конвейеры',
          'Только винтовые конвейеры',
          'Только цепные конвейеры',
          'Все перечисленные конвейеры',
        ],
        correctAnswers: ['Все перечисленные конвейеры'],
      },
      {
        code: '63717166',
        text:
          'Кем выдается разрешение на ведение работ со взрывчатыми материалами промышленного назначения?',
        answers: [
          'Территориальным органом Ростехнадзора',
          'Центральным аппаратом Ростехнадзора',
          'Органами МВД России',
          'Органами МЧС России',
        ],
        correctAnswers: ['Территориальным органом Ростехнадзора'],
      },
      {
        code: '63717167',
        text:
          'Каким должно быть расстояние от склада камерного типа до поверхности?',
        answers: [
          'Не менее 15 м',
          'Не менее 20 м',
          'Не менее 25 м',
          'Не менее 30 м',
        ],
        correctAnswers: ['Не менее 30 м'],
      },
      {
        code: '63717168',
        text:
          'Что из перечисленного необходимо отражать в инструкции по ликвидации отказавших зарядов взрывчатых веществ?',
        answers: [
          'Только основные мероприятия по предупреждению отказавших зарядов, порядок обнаружения невзорвавшихся зарядов, методы ликвидации отказов для каждого вида взрывных работ',
          'Только величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности и в подземных выработках, а также мероприятия по ее охране, организацию работ по ликвидации отказов',
          'Только порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа, мероприятия по безопасности работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63717169',
        text:
          'Что необходимо выполнить для ликвидации отказавших камерных зарядов, если при проверке выявится возможность опасного разлета кусков горной массы или воздействия ударной воздушной волны?',
        answers: [
          'Разборку забойки с последующим вводом нового боевика и взрывание в обычном порядке',
          'Ликвидацию по специально разработанным проектам, утвержденным руководителем (техническим руководителем) организации, ведущей взрывные работы, или назначенным им лицом',
          'Разборку забойки с последующим извлечением взрывчатых веществ',
          'Взрывание дополнительно опущенного накладного заряда',
        ],
        correctAnswers: [
          'Разборку забойки с последующим извлечением взрывчатых веществ',
        ],
      },
      {
        code: '63717170',
        text:
          'С какой периодичностью должна направляться информация о произошедших инцидентах в территориальный орган Ростехнадзора?',
        answers: [
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
          'Информация направляется только при наличии инцидентов сразу после их возникновения',
        ],
        correctAnswers: ['Не реже 1 раза в квартал'],
      },
      {
        code: '63717171',
        text:
          'Какое утверждение соответствует требованиям безопасности при размещении поверхностных пунктов производства и (или) подготовки взрывчатых веществ?',
        answers: [
          'Поверхностные пункты производства и (или) подготовки взрывчатых веществ должны располагаться на самостоятельных площадках',
          'Поверхностные пункты производства и (или) подготовки взрывчатых веществ не допускается размещать на территории склада взрывчатых материалов',
          'Поверхностные пункты производства и (или) подготовки взрывчатых веществ могут размещаться над действующими горными выработками',
          'Поверхностные пункты производства и (или) подготовки взрывчатых веществ не допускается размещать в запретной зоне склада взрывчатых материалов',
        ],
        correctAnswers: [
          'Поверхностные пункты производства и (или) подготовки взрывчатых веществ должны располагаться на самостоятельных площадках',
        ],
      },
      {
        code: '63717172',
        text:
          'Что из перечисленного осуществляется в ходе технического расследования случаев утраты взрывчатых материалов промышленного назначения?',
        answers: [
          'Только установление обстоятельств и причин утраты взрывчатых материалов промышленного назначения',
          'Только выявление организационных и технических недостатков, приведших к нарушению действующего порядка хранения, перевозки, использования и учета взрывчатых материалов промышленного назначения',
          'Только разработка мероприятий по недопущению подобных случаев утраты взрывчатых материалов промышленного назначения в дальнейшем',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63717173',
        text:
          'Какое количество взрывчатых веществ разрешается размещать непосредственно у зарядного оборудования?',
        answers: [
          'Не более сменной производительности оборудования',
          'Не более недельной производительности оборудования',
          'Не более декадной производительности оборудования',
          'Не более месячной производительности оборудования',
        ],
        correctAnswers: ['Не более сменной производительности оборудования'],
      },
      {
        code: '63717174',
        text:
          'Каким способом следует проводить бурение шпуров по углю для сотрясательного взрывания?',
        answers: [
          'Только вращательным способом',
          'Только с применением ударных инструментов',
          'Только с применением ударно-вращательных инструментов',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Только вращательным способом'],
      },
      {
        code: '63717175',
        text:
          'Какие утверждения, применяемые к временным складам взрывчатых материалов, указаны верно? Выберите два правильных варианта ответа.',
        answers: [
          'Устройство противопожарных водоемов (резервуаров, скважин) необязательно',
          'Рабочее освещение внутри хранилищ не должно осуществляться рудничными аккумуляторными светильниками',
          'Деревянные стены и крыши разрешается не покрывать огнезащитным составом',
          'Устройство тамбуров необязательно, допускается ставить одинарные двери',
        ],
        correctAnswers: [
          'Устройство противопожарных водоемов (резервуаров, скважин) необязательно',
          'Устройство тамбуров необязательно, допускается ставить одинарные двери',
        ],
      },
      {
        code: '63717176',
        text:
          'Какой часовой обмен воздуха следует обеспечить для проветривания подземного склада взрывчатых материалов во всех выработках?',
        answers: [
          '4-кратный обмен',
          '3-кратный обмен',
          '2-кратный обмен',
          '1-кратный обмен',
        ],
        correctAnswers: ['4-кратный обмен'],
      },
      {
        code: '63717177',
        text:
          'Что необходимо отключить при проведении сотрясательного взрывания в подземных выработках?',
        answers: [
          'Только электроэнергию во всех выработках шахты, расположенных в пределах опасной зоны',
          'Только вентиляторы местного проветривания',
          'Только приборы автоматического контроля содержания метана',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Только электроэнергию во всех выработках шахты, расположенных в пределах опасной зоны',
        ],
      },
      {
        code: '63717178',
        text:
          'На каком расстоянии от места погрузки (выгрузки) взрывчатых материалов необходимо размещать караульное помещение с телефонной связью?',
        answers: [
          'На расстоянии не более 50 м',
          'На расстоянии не более 55 м',
          'На расстоянии не более 60 м',
          'На расстоянии не более 70 м',
        ],
        correctAnswers: ['На расстоянии не более 50 м'],
      },
      {
        code: '63717179',
        text:
          'К какой группе совместимости (опасности) относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие менее 2 независимых предохранительных устройств?',
        answers: ['К группе Е', 'К группе B', 'К группе C', 'К группе D'],
        correctAnswers: ['К группе B'],
      },
      {
        code: '63717180',
        text:
          'Куда передается оперативное сообщение об аварии на опасном производственном объекте?',
        answers: [
          'Только в территориальный орган федерального органа исполнительной власти в области промышленной безопасности, вышестоящий орган или организацию (при наличии) и орган местного самоуправления, на территории которого располагается опасный производственный объект',
          'Только в страховую организацию, с которой заключен договор обязательного страхования гражданской ответственности, в профсоюзную организацию',
          'Только в комиссию по предупреждению и ликвидации чрезвычайных ситуаций и обеспечению пожарной безопасности субъекта Российской Федерации и в федеральный орган исполнительной власти, осуществляющий функции по контролю и надзору в области охраны окружающей среды (при авариях, связанных с выбросом опасных веществ)',
          'Только в территориальный орган Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий, на территории деятельности которого произошла авария',
          'Во все перечисленные организации',
        ],
        correctAnswers: ['Во все перечисленные организации'],
      },
      {
        code: '63717181',
        text:
          'На каком расстоянии от отказавших шпуровых зарядов разрешается взрывать заряды во вспомогательных шпурах, пробуренных параллельно отказавшим?',
        answers: [
          'На расстоянии не менее 30 см',
          'На расстоянии не менее 25 см',
          'На расстоянии не менее 20 см',
          'На расстоянии не менее 15 см',
        ],
        correctAnswers: ['На расстоянии не менее 30 см'],
      },
      {
        code: '63717182',
        text:
          'При каком размере целика между встречными забоями работы должны проводиться только из 1 забоя?',
        answers: [
          'При размере 7 м',
          'При размере 8 м',
          'При размере 9 м',
          'При размере 10 м',
        ],
        correctAnswers: ['При размере 7 м'],
      },
      {
        code: '63717183',
        text:
          'Где должен находиться руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания?',
        answers: [
          'Рядом со взрывником',
          'На расстоянии 8 м позади взрывника',
          'На расстоянии 3 м впереди взрывника',
        ],
        correctAnswers: ['На расстоянии 3 м впереди взрывника'],
      },
      {
        code: '63717184',
        text:
          'Какое из перечисленных требований к углубленным складам взрывчатых материалов указано верно?',
        answers: [
          'Устья выработок, ведущих к углубленному складу взрывчатых материалов, должны быть оборудованы двойными дверями, открывающимися наружу',
          'Если расстояние от входа в углубленный склад взрывчатых материалов до их ближайшей камеры хранения более 15 м, склад должен иметь только один выход',
          'Наружные двери устьев выработок, ведущих к углубленному складу взрывчатых материалов, должны быть решетчатыми',
          'Внутренние двери устьев выработок, ведущих к углубленному складу взрывчатых материалов, должны быть сплошными металлическими или деревянными, обитыми кровельной сталью',
        ],
        correctAnswers: [
          'Устья выработок, ведущих к углубленному складу взрывчатых материалов, должны быть оборудованы двойными дверями, открывающимися наружу',
        ],
      },
      {
        code: '63717185',
        text:
          'Когда осуществляется допуск работников на рабочие места после производства массовых взрывов на земной поверхности?',
        answers: [
          'Не ранее чем через 15 минут после взрыва и осмотра взорванных блоков с принятием мер, предотвращающих отравление газами проверяющих работников',
          'После осмотра места взрыва на предмет отсутствия отказов скважинных зарядов и снижения концентрации ядовитых продуктов взрыва в воздухе до установленных норм',
          'После снятия постов охраны опасной зоны',
          'После получения сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК, но не ранее чем через 30 минут после взрыва, рассеивания пылевого облака и полного восстановления видимости, а также осмотра мест (места) взрыва',
        ],
        correctAnswers: [
          'После получения сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК, но не ранее чем через 30 минут после взрыва, рассеивания пылевого облака и полного восстановления видимости, а также осмотра мест (места) взрыва',
        ],
      },
      {
        code: '63717186',
        text:
          'На каком расстоянии от стен и пола необходимо располагать стеллажи для взрывчатых веществ и средств инициирования на складах взрывчатых материалов?',
        answers: [
          'На расстоянии не менее 20 см от стен и не менее 10 см от пола',
          'На расстоянии не менее 15 см от стен и не менее 8 см от пола',
          'На расстоянии не менее 10 см от стен и не менее 5 см от пола',
          'На расстоянии не менее 5 см от стен и не менее 2 см от пола',
        ],
        correctAnswers: [
          'На расстоянии не менее 20 см от стен и не менее 10 см от пола',
        ],
      },
      {
        code: '63717187',
        text:
          'В каком случае взрывнику разрешается выйти из укрытия после взрыва при взрывании с применением электродетонаторов?',
        answers: [
          'Только если после взрыва прошло не менее 5 минут',
          'Только если место взрыва проветрено',
          'Только если выполнено отсоединение электровзрывной сети от источника тока и замыкание ее накоротко',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63717188',
        text:
          'Где разрешается применять предохранительные взрывчатые вещества IV класса при ведении взрывных работ в подземных выработках?',
        answers: [
          'В нишах лав, не отнесенных к забоям с повышенным выделением метана',
          'В забоях выработок, проводимых только по породе (в том числе и по выбросоопасным породам) при выделении метана и отсутствии взрывчатой пыли',
          'В угольных и смешанных забоях восстающих (10° и более) выработок, в которых выделяется метан, при их проведении с предварительно пробуренными скважинами, обеспечивающими проветривание выработок за счет общешахтной депрессии',
          'В угольных и смешанных забоях горизонтальных, наклонных и восстающих (до 10°) выработок, проводимых по пластам, опасным по газу или пыли, в которых отсутствует повышенное выделение метана при взрывных работах',
        ],
        correctAnswers: [
          'В угольных и смешанных забоях горизонтальных, наклонных и восстающих (до 10°) выработок, проводимых по пластам, опасным по газу или пыли, в которых отсутствует повышенное выделение метана при взрывных работах',
        ],
      },
      {
        code: '63717189',
        text:
          'Чем необходимо отмечать специально выделенное место для временного хранения на складах пришедших в негодность и бракованных взрывчатых веществ и изделий на их основе?',
        answers: [
          'Металлическим ограждением',
          'Предупредительной надписью: "ВНИМАНИЕ БРАК"',
          'Предупредительными огнями в виде светильников красного цвета',
          'Временное хранение таких веществ и изделий не допускается',
        ],
        correctAnswers: ['Предупредительной надписью: "ВНИМАНИЕ БРАК"'],
      },
      {
        code: '63717190',
        text:
          'Какова установленная суммарная загрузка поверхностного здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом взрывчатых веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?',
        answers: [
          'Не более 20 т',
          'Не более 40 т',
          'Не более 60 т',
          'Не более 80 т',
        ],
        correctAnswers: ['Не более 60 т'],
      },
      {
        code: '63717191',
        text:
          'При какой температуре в шпурах запрещается заряжать и взрывать заряды в них при ведении взрывных работ по металлу?',
        answers: [
          'При температуре до 60 °C',
          'При температуре выше 80 °C',
          'При температуре до 180 °C',
          'При температуре выше 200 °C',
        ],
        correctAnswers: ['При температуре выше 200 °C'],
      },
      {
        code: '63717192',
        text:
          'На какие виды по месту расположения относительно земной поверхности подразделяются склады взрывчатых материалов?',
        answers: [
          'На поверхностные, полууглубленные, углубленные и подземные',
          'На надземные, полуподземные и подземные',
          'На углубленные, полууглубленные и неуглубленные',
          'На наземные, полузаглубленные и заглубленные',
        ],
        correctAnswers: [
          'На поверхностные, полууглубленные, углубленные и подземные',
        ],
      },
      {
        code: '63717193',
        text:
          'В течение какого срока с даты подписания акта технического расследования причин аварии руководителем организации издается внутренний распорядительный акт, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?',
        answers: [
          'В течение 7 рабочих дней',
          'В течение 9 рабочих дней',
          'В течение 15 рабочих дней',
          'В течение 30 рабочих дней',
        ],
        correctAnswers: ['В течение 7 рабочих дней'],
      },
      {
        code: '63717194',
        text:
          'На каком расстоянии от здания подготовки и (или) производства взрывчатых веществ необходимо располагать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных машин?',
        answers: [
          'На расстоянии не менее 20 м',
          'На расстоянии не менее 30 м',
          'На расстоянии не менее 40 м',
          'На расстоянии не менее 50 м',
        ],
        correctAnswers: ['На расстоянии не менее 50 м'],
      },
      {
        code: '63717195',
        text:
          'Какой должна быть температура воздуха в хранилищах складов и контейнеров со взрывчатыми веществами на основе аммиачной селитры?',
        answers: [
          'Не выше 30 °С',
          'Не выше 32 °С',
          'Не выше 35 °С',
          'Не выше 36 °С',
        ],
        correctAnswers: ['Не выше 30 °С'],
      },
      {
        code: '63717196',
        text:
          'С какой периодичностью специальная комиссия организации должна проверять знание требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими единую книжку взрывника?',
        answers: [
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 2,5 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 2 года'],
      },
      {
        code: '63717197',
        text:
          'Какие взрывные работы с применением разовых взрывов зарядов разрешается проводить по схемам?',
        answers: [
          'Только удаление навесов, выравнивание забоя',
          'Только выравнивание почвы выработки',
          'Только ликвидацию отказов',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63717198',
        text:
          'Как часто необходимо проверять техническую исправность транспортных средств, используемых для доставки взрывчатых материалов?',
        answers: ['Ежесменно', 'Ежесуточно', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63717199',
        text:
          'У каких складов взрывчатых материалов толща грунта над хранилищем составляет менее 15 м?',
        answers: ['У подземных', 'У углубленных', 'У полууглубленных'],
        correctAnswers: ['У углубленных'],
      },
      {
        code: '63717200',
        text:
          'Кому разрешается находиться в поезде при транспортировании взрывчатых материалов рельсовым транспортом?',
        answers: [
          'Только начальнику взрывного участка (цеха) или начальнику смены',
          'Только представителям Ростехнадзора и ВГСЧ',
          'Только машинисту локомотива, взрывнику или раздатчику, а также рабочим, связанным с перевозкой взрывчатых материалов',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'Только машинисту локомотива, взрывнику или раздатчику, а также рабочим, связанным с перевозкой взрывчатых материалов',
        ],
      },
      {
        code: '63717201',
        text:
          'Как должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления при взрывных работах?',
        answers: [
          '1 продольной полосой шириной 25 мм черного цвета',
          '2 поперечными полосами синего цвета, каждая шириной 5 мм',
          '1 поперечной полосой шириной 15 мм красного цвета',
          '1 продольной полосой шириной 20 мм зеленого цвета',
        ],
        correctAnswers: ['1 поперечной полосой шириной 15 мм красного цвета'],
      },
      {
        code: '63717202',
        text:
          'Кто и с какой периодичностью должен осматривать рассчитанные на взрыв максимально допустимого заряда помещения и площадки, где проводится обработка металлов?',
        answers: [
          'Ростехнадзор не реже 1 раза в месяц',
          'Комиссия организаций ежегодно',
          'Муниципальные органы не реже 1 раза в 6 месяцев',
          'Профессиональные аварийно-спасательные службы и формирования каждые 3 месяца',
        ],
        correctAnswers: ['Комиссия организаций ежегодно'],
      },
      {
        code: '63717203',
        text:
          'Какую информацию следует указывать на табличках, вывешиваемых возле камер, стеллажей и штабелей на складе взрывчатых материалов?',
        answers: [
          'Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, номер партии, наименование изготовителя',
          'Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, наименование изготовителя и дату изготовления',
          'Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, номер партии, дату изготовления и гарантийный срок хранения',
        ],
        correctAnswers: [
          'Наименование взрывчатых веществ, средств инициирования или прострелочных и взрывных аппаратов, их количество, номер партии, дату изготовления и гарантийный срок хранения',
        ],
      },
      {
        code: '63717204',
        text:
          'На что не распространяется действие технического регламента Таможенного союза от 20.07.2012 № 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          'На взрывчатые вещества и изделия на их основе, разрабатываемые (проектируемые) и изготавливаемые для использования энергии взрыва в промышленных целях',
          'На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции, и на пиротехнические изделия',
          'На взрывчатые вещества, непосредственно не применяемые для использования энергии взрыва в промышленных целях, но используемые для производства взрывчатых веществ и изделий для такого применения',
          'На эмульсии и матрицы окислителя на основе нитрата аммония, разрабатываемые (проектируемые) и изготавливаемые для получения водоэмульсионных и водногелевых взрывчатых веществ',
        ],
        correctAnswers: [
          'На взрывчатые вещества и изделия на их основе, относящиеся к оборонной продукции, и на пиротехнические изделия',
        ],
      },
      {
        code: '63717205',
        text:
          'Кто может получить профессию взрывника, имея стаж работы в шахтах, опасных по газу и пыли?',
        answers: [
          'Лица мужского пола не моложе 16 лет, имеющие среднее специальное образование',
          'Лица мужского пола не моложе 21 года, имеющие стаж работы не менее 1 года по специальности, соответствующей характеру работы организации',
          'Лица мужского пола старше 18 лет, имеющие образование не ниже среднего общего и стаж на подземных работах в качестве проходчика или рабочего очистного забоя не менее 2 лет',
          'Лица мужского пола, имеющие высшее горнотехническое образование и стаж не менее 1 года по специальности',
        ],
        correctAnswers: [
          'Лица мужского пола старше 18 лет, имеющие образование не ниже среднего общего и стаж на подземных работах в качестве проходчика или рабочего очистного забоя не менее 2 лет',
        ],
      },
      {
        code: '63717206',
        text:
          'Что из перечисленного должно находиться на территории склада взрывчатых материалов в пределах ограды?',
        answers: [
          'Полигон для испытаний и уничтожения взрывчатых материалов',
          'Административно-бытовое помещение для персонала, обслуживающего склад',
          'Здание (помещение) для выдачи взрывчатых материалов',
          'Водопроводные и канализационные насосные станции',
        ],
        correctAnswers: ['Здание (помещение) для выдачи взрывчатых материалов'],
      },
      {
        code: '63717207',
        text:
          'Какое расстояние допускается от любой ближайшей точки камерного склада до ствола шахты и околоствольных выработок, а также до вентиляционных дверей?',
        answers: [
          'Не менее 60 м',
          'Не менее 75 м',
          'Не менее 90 м',
          'Не менее 100 м',
        ],
        correctAnswers: ['Не менее 100 м'],
      },
      {
        code: '63717208',
        text:
          'Во сколько рядов разрешается устанавливать ящики со взрывчатыми материалами по ширине полки стеллажа?',
        answers: [
          'Не более чем в 2 ряда',
          'Не более чем в 3 ряда',
          'Не более чем в 4 ряда',
          'Не более чем в 5 рядов',
        ],
        correctAnswers: ['Не более чем в 2 ряда'],
      },
      {
        code: '63717209',
        text:
          'В каком из перечисленных случаев транспортные пути для перевозок взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 3 м от зданий?',
        answers: [
          'Если пути проходят у зданий (помещений), в которых изготавливаются или перерабатываются взрывчатые вещества',
          'Если пути проходят у зданий, где имеются открытые огневые топки и источники открытого огня или производятся работы с открытым огнем (кузниц, котельных, сварочных мастерских)',
          'Если пути предназначены для подъезда к этим зданиям',
          'Если пути проходят у вспомогательных зданий, находящихся на территории пункта',
        ],
        correctAnswers: ['Если пути предназначены для подъезда к этим зданиям'],
      },
      {
        code: '63717210',
        text:
          'Куда должна направить материалы технического расследования и копию внутреннего распорядительного акта по результатам расследования (если он издавался) организация, в которой произошла утрата взрывчатых материалов промышленного назначения?',
        answers: [
          'Только в вышестоящий орган или организацию (при наличии)',
          'Только в территориальный орган федерального органа в области промышленной безопасности, проводивший расследование',
          'Только в территориальный орган Министерства внутренних дел Российской Федерации по месту совершения предполагаемого факта хищения взрывчатых материалов промышленного назначения',
          'Во все перечисленные организации',
        ],
        correctAnswers: ['Во все перечисленные организации'],
      },
      {
        code: '63717211',
        text:
          'На протяжении какого расстояния от раздаточных камер подводящие выработки необходимо закреплять несгораемой крепью?',
        answers: [
          'На протяжении не менее чем 5 м',
          'На протяжении не менее чем 4,5 м',
          'На протяжении не менее чем 4 м',
          'На протяжении не менее чем 3,5 м',
        ],
        correctAnswers: ['На протяжении не менее чем 5 м'],
      },
      {
        code: '63717212',
        text:
          'На каком расстоянии допускается размещать места (площадки) выгрузки, погрузки и отстоя железнодорожных вагонов со взрывчатыми материалами от жилых и производственных строений, от главных стационарных железнодорожных путей?',
        answers: [
          'На расстоянии не менее 50 м',
          'На расстоянии не менее 75 м',
          'На расстоянии не менее 100 м',
          'На расстоянии не менее 125 м',
        ],
        correctAnswers: ['На расстоянии не менее 125 м'],
      },
      {
        code: '63717213',
        text:
          'На каком расстоянии допускается размещать оборудуемые отдельно подземные пункты производства и (или) подготовки взрывчатых веществ?',
        answers: [
          'На расстоянии не менее 25 м от выработок, служащих для постоянного прохода людей',
          'На расстоянии не менее 25 м от поверхности',
          'На расстоянии не менее 30 м от мест посадки людей в пассажирские вагоны и мест погрузки-выгрузки горной массы',
          'На расстоянии не менее 50 м от ствола шахты, околоствольных выработок и вентиляционных дверей, регулирующих приток свежего воздуха на всю шахту или значительные участки',
        ],
        correctAnswers: [
          'На расстоянии не менее 25 м от выработок, служащих для постоянного прохода людей',
        ],
      },
      {
        code: '63717214',
        text:
          'Кто проверяет подготовленный к массовому взрыву подэтаж (блок, панель)?',
        answers: [
          'Руководитель рудника (шахты, объекта строительства)',
          'Технический руководитель рудника (шахты, объекта строительства)',
          'Руководитель массового взрыва',
          'Комиссия, назначенная руководителем рудника (шахты, объекта строительства)',
        ],
        correctAnswers: [
          'Комиссия, назначенная руководителем рудника (шахты, объекта строительства)',
        ],
      },
      {
        code: '63717215',
        text:
          'К какой группе совместимости взрывчатых веществ и изделий на их основе относятся изделия, содержащие инициирующие взрывчатые вещества и имеющие менее 2 независимых предохранительных устройств?',
        answers: ['К группе B', 'К группе C', 'К группе D', 'К группе F'],
        correctAnswers: ['К группе B'],
      },
      {
        code: '63717216',
        text:
          'Что из перечисленного запрещается делать при транспортировании взрывчатых материалов по стволу шахты?',
        answers: [
          'Спускать и поднимать взрывчатые материалы по стволу шахты только после извещения об этом диспетчера (дежурного по шахте)',
          'Перемещать взрывчатые материалы по стволу шахты в околоствольном дворе и надшахтном здании около ствола в присутствии взрывника, раздатчика, нагружающих и разгружающих взрывчатые материалы рабочих, рукоятчика, стволового и лица, ответственного за доставку взрывчатых материалов',
          'Спускать и поднимать средства инициирования отдельно от взрывчатых веществ',
          'Спускать и поднимать людей',
        ],
        correctAnswers: ['Спускать и поднимать людей'],
      },
      {
        code: '63717217',
        text:
          'Каков срок хранения взрывчатых материалов на специальных площадках для производства геофизических и других разовых работ?',
        answers: [
          'Не более 150 суток',
          'Не более 120 суток',
          'Не более 100 суток',
          'Не более 90 суток',
        ],
        correctAnswers: ['Не более 90 суток'],
      },
      {
        code: '63717218',
        text:
          'В каком случае перед началом заряжания шпуров в одном из встречных забоев все люди, не связанные с выполнением взрывных работ, должны быть удалены из встречных забоев на безопасное расстояние и у входа в противоположный забой должен быть выставлен пост?',
        answers: [
          'В случае сближения забоев на расстояние 15 м',
          'В случае сближения забоев на расстояние 12 м',
          'В случае сближения забоев на расстояние 10 м',
          'В случае сближения забоев на расстояние 8 м',
        ],
        correctAnswers: ['В случае сближения забоев на расстояние 15 м'],
      },
      {
        code: '63717219',
        text:
          'С какой периодичностью места хранения селитры должны подвергаться очистке?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 4 года',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63717220',
        text:
          'Каким способом разрешается ликвидировать отказавшие скважинные заряды?',
        answers: [
          'Взрыванием, если при проверке выявится возможность опасного разлета кусков горной массы или воздействия ударной воздушной волны при взрыве',
          'Взрыванием в скважине, пробуренной параллельно на расстоянии не менее 2 м от скважины с отказавшим зарядом',
          'Разборкой породы в месте нахождения скважин с отказавшими зарядами с извлечением последних вручную',
          'Вымыванием из скважины при взрывании взрывчатых веществ группы совместимости D (в том числе дымного пороха) с применением детонирующего шнура',
        ],
        correctAnswers: [
          'Разборкой породы в месте нахождения скважин с отказавшими зарядами с извлечением последних вручную',
        ],
      },
      {
        code: '63717221',
        text:
          'При каком расхождении величин измеренного и расчетного сопротивления перед взрыванием скважинных и камерных зарядов необходимо устранить неисправности, вызывающие отклонения от расчетного сопротивления электровзрывной сети?',
        answers: [
          'При расхождении более чем на 10%',
          'При расхождении более чем на 8%',
          'При расхождении более чем на 6%',
          'При расхождении более чем на 5%',
        ],
        correctAnswers: ['При расхождении более чем на 10%'],
      },
      {
        code: '63717222',
        text:
          'Каким должно быть расстояние от заряда взрывчатых веществ до ближайшей поверхности по породе в подземных выработках?',
        answers: [
          'Не менее 0,3 м',
          'Не менее 0,25 м',
          'Не менее 0,22 м',
          'Не менее 0,15 м',
        ],
        correctAnswers: ['Не менее 0,3 м'],
      },
      {
        code: '63717223',
        text:
          'Какие трубы или кабели допускается прокладывать в производственных зданиях (помещениях), в которых ведутся работы с окислителями или их растворами?',
        answers: [
          'Медные импульсные и командные трубы',
          'Кабели, бронированные с оцинкованной броней',
          'Кабели, бронированные с открытой свинцовой оболочкой',
          'Пластмассовые импульсные трубы',
        ],
        correctAnswers: ['Пластмассовые импульсные трубы'],
      },
      {
        code: '63717224',
        text:
          'Из чего состоит проект буровзрывных (взрывных) работ (проект массового взрыва) для конкретных условий?',
        answers: [
          'Только из технического расчета со схемой расположения скважин и графическими материалами',
          'Только из таблицы параметров взрывных работ',
          'Только из распорядка проведения массового взрыва',
          'Из всего перечисленного',
        ],
        correctAnswers: ['Из всего перечисленного'],
      },
      {
        code: '63717225',
        text:
          'Кто должен вести книгу учета прихода и расхода взрывчатых материалов?',
        answers: [
          'Заведующие и раздатчики складов взрывчатых материалов',
          'Специально обученные и проинструктированные рабочие',
          'Взрывники',
          'Начальники участков буровзрывных работ',
        ],
        correctAnswers: [
          'Заведующие и раздатчики складов взрывчатых материалов',
        ],
      },
      {
        code: '63717226',
        text:
          'Какие взрывчатые вещества разрешается использовать при разупрочнении труднообрушаемых пород любой крепости?',
        answers: [
          'Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил',
          'Взрывчатые вещества, содержащие гексоген',
          'Взрывчатые вещества, содержащие нитроэфиры',
          'Любые взрывчатые вещества',
        ],
        correctAnswers: [
          'Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил',
        ],
      },
      {
        code: '63717227',
        text:
          'Какое требование к применению средств инициирования указано верно?',
        answers: [
          'Боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов следует изготавливать только из патронов (шашек) с гнездами заводского производства',
          'При изготовлении боевиков из патронированных взрывчатых веществ с применением детонирующего шнура запрещается складывать конец детонирующего шнура в патроне',
          'При изготовлении боевиков из патронированных взрывчатых веществ запрещается обматывать детонирующий шнур вокруг патрона взрывчатых веществ',
          'При изготовлении боевиков из патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне запрещается завязывать узлом',
        ],
        correctAnswers: [
          'Боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов следует изготавливать только из патронов (шашек) с гнездами заводского производства',
        ],
      },
      {
        code: '63717228',
        text:
          'Где должно располагаться место укрытия взрывников, удаленное от места взрыва на расстояние не менее 200 м?',
        answers: [
          'В восстающих выработках при пропуске угля и породы',
          'В лавах (слоях) с углом залегания до 18°',
          'В очистных забоях камерного типа, а также при погашении угольных целиков',
          'В щитовых забоях',
        ],
        correctAnswers: [
          'В очистных забоях камерного типа, а также при погашении угольных целиков',
        ],
      },
      {
        code: '63717229',
        text:
          'В каком случае допускается проводить осмотр подземной выработки после окончания сотрясательного взрывания?',
        answers: [
          'Если после взрыва прошло не менее 30 минут, а концентрация метана менее 2,0 %',
          'Если после взрыва прошло не менее 25 минут, а концентрация метана менее 2,5 %',
          'Если после взрыва прошло не менее 20 минут, а концентрация метана менее 2,5 %',
          'Если после взрыва прошло не менее 15 минут, а концентрация метана менее 3,0 %',
        ],
        correctAnswers: [
          'Если после взрыва прошло не менее 30 минут, а концентрация метана менее 2,0 %',
        ],
      },
      {
        code: '63717230',
        text:
          'Какое требование к транспортированию взрывчатых материалов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Транспортировать взрывчатые материалы по подземным выработкам следует со скоростью не более 10 м/с',
          'При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более 1 взрывника или подносчика',
          'В 1 клети запрещается одновременно спускать или поднимать более 1 взрывника или подносчика с сумками с взрывчатыми материалами',
          'Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди',
        ],
        correctAnswers: [
          'При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более 1 взрывника или подносчика',
          'Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди',
        ],
      },
      {
        code: '63717231',
        text:
          'Как упаковывается заряд при температуре выше 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?',
        answers: [
          'В оберточную бумагу',
          'В изолирующую оболочку патрона-боевика, а огнепроводный шнур в скрученном и свернутом виде – в общую оболочку',
          'В общую термоизолирующую оболочку',
          'В пергаментную бумагу',
        ],
        correctAnswers: ['В общую термоизолирующую оболочку'],
      },
      {
        code: '63717232',
        text:
          'Какого вида массового взрыва в подземных условиях не существует?',
        answers: [
          'Технологического взрыва',
          'Экспериментального взрыва',
          'Пробного взрыва',
          'Специального взрыва',
        ],
        correctAnswers: ['Пробного взрыва'],
      },
      {
        code: '63717233',
        text:
          'При какой толщине породной пробки между забоем выработки и крутым пластом (пропластком) необходимо производить вскрытие и пересечение пластов при помощи буровзрывных работ?',
        answers: [
          'При толщине не менее 0,5 м',
          'При толщине не менее 1 м',
          'При толщине не менее 1,5 м',
          'При толщине не менее 2 м',
        ],
        correctAnswers: ['При толщине не менее 2 м'],
      },
      {
        code: '63717234',
        text:
          'Кто может получить единую книжку взрывника на право руководства взрывными работами?',
        answers: [
          'Только лица, имеющие высшее или среднее профессиональное горнотехническое образование либо высшее или среднее профессиональное образование, связанное с обращением взрывчатых материалов',
          'Только лица, окончившие средние специальные учебные заведения или получившие образование по очной форме, не связанное с обращением взрывчатых материалов',
          'Только лица, окончившие любые высшие учебные заведения',
          'Все перечисленные лица',
        ],
        correctAnswers: [
          'Только лица, имеющие высшее или среднее профессиональное горнотехническое образование либо высшее или среднее профессиональное образование, связанное с обращением взрывчатых материалов',
        ],
      },
      {
        code: '63717235',
        text:
          'Какое требование к изготовлению зажигательных и контрольных трубок указано верно?',
        answers: [
          'На столе исполнителя должно находиться не более 1 коробки капсюлей-детонаторов с соответствующим количеством отрезков огнепроводного шнура',
          'Огнепроводный шнур следует резать при отсутствии на столе капсюлей-детонаторов',
          'Каждая контрольная трубка должна иметь четко видимый невооруженным глазом отличительный знак',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63717236',
        text:
          'Кто возглавляет комиссию по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: [
          'Представитель Ростехнадзора или его территориального органа',
          'Руководитель опасного производственного объекта',
          'Технический руководитель опасного производственного объекта',
          'Представитель профессиональной аварийно-спасательной службы',
        ],
        correctAnswers: [
          'Представитель Ростехнадзора или его территориального органа',
        ],
      },
      {
        code: '63717237',
        text:
          'В каком случае по результатам осмотра молниеприемников молниеотвод необходимо заменить новым?',
        answers: [
          'Если у молниеотвода оплавился наконечник',
          'Если у молниеотвода оплавлен или поврежден конический наконечник, а молниеотвод поврежден ржавчиной более чем на 1/3 площади поперечного сечения',
          'Если молниеотвод и поверхность в соединениях на болтах покрыты ржавчиной',
        ],
        correctAnswers: [
          'Если у молниеотвода оплавлен или поврежден конический наконечник, а молниеотвод поврежден ржавчиной более чем на 1/3 площади поперечного сечения',
        ],
      },
      {
        code: '63717238',
        text:
          'На каком расстоянии от зарядного оборудования следует вводить запретную зону при механизированном заряжании?',
        answers: [
          'На расстоянии не менее 30 м',
          'На расстоянии не менее 50 м',
          'На расстоянии не менее 10 м',
          'На расстоянии не менее 45 м',
        ],
        correctAnswers: ['На расстоянии не менее 50 м'],
      },
      {
        code: '63717239',
        text:
          'Какова разрешенная скорость спуска-подъема боевиков в опрокидывающихся бадьях при движении по направляющим?',
        answers: [
          'Не более 1,0 м/с',
          'Не более 3,0 м/с',
          'Не более 2,5 м/с',
          'Не более 2,0 м/с',
        ],
        correctAnswers: ['Не более 2,0 м/с'],
      },
      {
        code: '63717240',
        text:
          'Что должен сделать взрывник, если электровзрывная сеть была смонтирована перед наступлением грозы?',
        answers: [
          'Провести взрывание во время грозы',
          'Провести взрывание вне зависимости от погодных условий',
          'Провести взрывание перед грозой или отсоединить участковые провода от магистральных, концы тщательно изолировать, эвакуировать людей за пределы опасной зоны или в укрытие',
          'Эвакуировать людей в укрытие и провести взрывание во время грозы',
        ],
        correctAnswers: [
          'Провести взрывание перед грозой или отсоединить участковые провода от магистральных, концы тщательно изолировать, эвакуировать людей за пределы опасной зоны или в укрытие',
        ],
      },
      {
        code: '63717241',
        text:
          'При какой глубине взрывной скважины необходимо дублировать внутрискважинную сеть?',
        answers: [
          'При глубине более 15 м',
          'При глубине более 12 м',
          'При глубине более 8 м',
          'При глубине более 5 м',
        ],
        correctAnswers: ['При глубине более 15 м'],
      },
      {
        code: '63717242',
        text:
          'Какие требования предъявляются к прокладке колючей проволоки (ленты), натянутой по верху ограды хранилища на складе взрывчатых материалов?',
        answers: [
          'Должна натягиваться колючая проволока в 4 нитки либо спиральная колючая проволока (лента) в 1 ряд на металлические стержни высотой не менее 0,5 м',
          'Должна натягиваться колючая проволока в 4 нитки на металлические стержни высотой не менее 0,4 м',
          'Должна натягиваться спиральная колючая проволока (лента) в 2 ряда на металлические стержни высотой не менее 2 м',
          'Должна натягиваться колючая проволока в 2 нитки на металлические стержни высотой не менее 0,4 м',
        ],
        correctAnswers: [
          'Должна натягиваться колючая проволока в 4 нитки либо спиральная колючая проволока (лента) в 1 ряд на металлические стержни высотой не менее 0,5 м',
        ],
      },
      {
        code: '63717243',
        text:
          'На каком расстоянии от греющих поверхностей (печей, труб, радиаторов) в помещении должны находиться столы и полки, на которых раскладываются при сушке взрывчатые вещества?',
        answers: [
          'На расстоянии не менее 1,0 м',
          'На расстоянии не менее 0,9 м',
          'На расстоянии не менее 0,7 м',
          'На расстоянии не менее 0,5 м',
        ],
        correctAnswers: ['На расстоянии не менее 1,0 м'],
      },
      {
        code: '63717244',
        text:
          'На каком расстоянии от места нахождения взрывчатых материалов запрещается применять открытый огонь и курить?',
        answers: [
          'На расстоянии менее 100 м',
          'На расстоянии менее 110 м',
          'На расстоянии менее 125 м',
          'На расстоянии менее 150 м',
        ],
        correctAnswers: ['На расстоянии менее 100 м'],
      },
      {
        code: '63717245',
        text: 'Кто утверждает типовой проект буровзрывных (взрывных) работ?',
        answers: [
          'Руководитель (технический руководитель) организации, ведущей взрывные работы, или лицо, его замещающее',
          'Руководитель подразделения организации, проводящей взрывные работы',
          'Представитель технического надзора',
          'Руководитель службы охраны труда и промышленной безопасности',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) организации, ведущей взрывные работы, или лицо, его замещающее',
        ],
      },
      {
        code: '63717246',
        text:
          'Для каких взрывчатых веществ и изделий на их основе не требуется подтверждение соответствия требованиям технического регламента Таможенного союза от 20.07.2012 № 028/2012 "О безопасности взрывчатых веществ и изделий на их основе"?',
        answers: [
          'Для взрывчатых веществ и изделий на их основе, изготавливаемых для использования энергии взрыва в промышленных целях',
          'Для взрывчатых веществ и изделий на их основе, выпускаемых в обращение на единой таможенной территории государств – членов Таможенного союза',
          'Для взрывчатых веществ и изделий на их основе, изготавливаемых для собственных нужд',
          'Для любых взрывчатых веществ и изделий на их основе',
        ],
        correctAnswers: [
          'Для взрывчатых веществ и изделий на их основе, изготавливаемых для собственных нужд',
        ],
      },
      {
        code: '63717247',
        text:
          'В каком документе необходимо фиксировать отказы зарядов при взрывных работах?',
        answers: [
          'В журнале регистрации отказов при взрывных работах',
          'В сменном журнале',
          'В акте выполненных работ',
          'В ведомости учета зарядов',
          'В наряде-путевке',
        ],
        correctAnswers: ['В журнале регистрации отказов при взрывных работах'],
      },
      {
        code: '63717248',
        text:
          'К какому классу опасности относятся все взрывчатые вещества и изделия на их основе?',
        answers: [
          'К 1 классу опасности',
          'Ко 2 классу опасности',
          'К 3 классу опасности',
          'К 4 классу опасности',
        ],
        correctAnswers: ['К 1 классу опасности'],
      },
      {
        code: '63717249',
        text:
          'Где разрешается применять предохранительные взрывчатые вещества V класса при ведении взрывных работ в подземных выработках?',
        answers: [
          'В верхних нишах лав с повышенным выделением метана',
          'В забоях выработок, проводимых по нарушенному массиву (в том числе и в забоях выработок, проводимых в присечку к нарушенному массиву), при выделении в них метана',
          'В угольных забоях восстающих (10° и более) выработок, в которых выделяется метан, при их проведении без предварительно пробуренных скважин',
          'В нишах лав, не отнесенных к забоям с повышенным выделением метана',
        ],
        correctAnswers: [
          'В нишах лав, не отнесенных к забоям с повышенным выделением метана',
        ],
      },
      {
        code: '63717250',
        text:
          'В течение какого срока материалы технического расследования аварии на опасном производственном объекте должны быть направлены территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?',
        answers: [
          'В течение 2 недель',
          'В течение 3 недель',
          'В течение 1 месяца',
          'Не регламентируется',
        ],
        correctAnswers: ['В течение 2 недель'],
      },
      {
        code: '63717251',
        text:
          'Какую информацию необходимо указывать в распорядительном документе, устанавливающем специальный режим для проведения взрывных работ в подземных выработках, в которых имеется газовыделение или взрывчатая пыль?',
        answers: [
          'Только наименование забоя, тип применяемого взрывчатого вещества и средств взрывания',
          'Только установленное время ведения взрывных работ, включая начало заряжания, время проветривания, осмотра забоя',
          'Только места вывода людей и место укрытия взрывника, наличие людей на пути движения исходящей струи воздуха',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63717252',
        text:
          'Какова предельно допустимая высота штабеля для взрывчатых веществ в хранилище?',
        answers: ['2,8 м', '2,5 м', '2,2 м', '2,0 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63717253',
        text:
          'Каков максимальный срок действия сертификата соответствия взрывчатых веществ?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['3 года'],
      },
      {
        code: '63717254',
        text:
          'В каких испытаниях взрывчатых веществ и изделий на их основе должна участвовать экспертная организация?',
        answers: [
          'В стендовых испытаниях',
          'В полигонных испытаниях',
          'В контрольных и приемочных испытаниях',
        ],
        correctAnswers: ['В контрольных и приемочных испытаниях'],
      },
      {
        code: '63717255',
        text:
          'Какого размера должна быть канава для предохранения от лесных и напольных пожаров, оборудуемая вокруг территории склада взрывчатых материалов на расстоянии 10 м от его ограды?',
        answers: [
          'Шириной по верху не менее 1,5 м и глубиной не менее 0,5 м',
          'Шириной по верху не менее 1,25 м и глубиной не менее 0,75 м',
          'Шириной по верху не менее 1,2 м и глубиной не менее 0,5 м',
          'Шириной по верху не менее 1,25 м и глубиной не менее 0,45 м',
        ],
        correctAnswers: [
          'Шириной по верху не менее 1,5 м и глубиной не менее 0,5 м',
        ],
      },
      {
        code: '63717256',
        text:
          'Когда следует проводить сотрясательное взрывание при отработке пластов, опасных по внезапным выбросам угля, породы и газа? Выберите два правильных варианта ответа.',
        answers: [
          'При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился',
          'При проведении восстающих выработок с углом наклона 15°',
          'При вскрытии песчаников на глубине 300 м и более, если прогноз выбросоопасности перед вскрытием не осуществлялся',
          'При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м',
        ],
        correctAnswers: [
          'При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился',
          'При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м',
        ],
      },
      {
        code: '63717257',
        text:
          'Какой срок эксплуатации предусматривается для временных складов взрывчатых материалов?',
        answers: ['До 1 года', 'До 2 лет', 'До 3 лет', 'До 5 лет'],
        correctAnswers: ['До 3 лет'],
      },
      {
        code: '63717258',
        text:
          'При какой концентрации метана руководитель сотрясательного взрывания и взрывник в забое при продвижении к забою для осмотра его после взрывания обязаны немедленно возвратиться в выработку со свежей струей воздуха?',
        answers: [
          'При концентрации 1 % и более',
          'При концентрации 1,5 % и более',
          'При концентрации 2 % и более',
          'При концентрации 0,5 % и более',
        ],
        correctAnswers: ['При концентрации 2 % и более'],
      },
      {
        code: '63717259',
        text:
          'В какой документ машинист погрузочной техники должен заносить информацию о времени обнаружения отказа, принятых мерах безопасности, а также данные о том, кому сообщено об обнаружении отказа?',
        answers: [
          'В книгу учета прихода и расхода взрывчатых материалов',
          'В журнал трехступенчатого контроля состояния охраны и условий труда на рабочем месте',
          'В журнал приема-сдачи смен',
        ],
        correctAnswers: ['В журнал приема-сдачи смен'],
      },
      {
        code: '63717260',
        text:
          'Кого должна информировать организация, проводящая взрывные работы, о проводимых массовых взрывах?',
        answers: [
          'Территориальные органы МВД России',
          'Территориальный орган исполнительной власти в области промышленной безопасности',
          'Федеральную службу государственной регистрации, кадастра и картографии',
          'Федеральную службу по гидрометеорологии и мониторингу окружающей среды',
        ],
        correctAnswers: [
          'Территориальный орган исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63717261',
        text:
          'Какое утверждение соответствует требованиям безопасности при ведении взрывных работ в подземных выработках?',
        answers: [
          'Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии менее 100 м от нее',
          'При сближении забоев на расстояние 35 м перед началом заряжания шпуров в одном из встречных забоев все не связанные с выполнением взрывных работ люди должны быть эвакуированы из этих забоев на безопасное расстояние, а у входа в противоположный забой должен быть выставлен пост',
          'В параллельно проводимых выработках угольных и нефтяных шахт при расстоянии между выработками 35 м и менее взрывание зарядов в каждом забое должно проводиться только после эвакуации людей из забоя параллельной выработки и выставления постов охраны, предусмотренных паспортами буровзрывных работ',
          'Разрешается не выводить людей из параллельной выработки, забой которой отстает на расстояние более 30 м от забоя, где проводится взрывание',
        ],
        correctAnswers: [
          'Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии менее 100 м от нее',
        ],
      },
      {
        code: '63717262',
        text:
          'Каким федеральным органом исполнительной власти осуществляется лицензирование деятельности, связанной с обращением взрывчатых материалов промышленного назначения?',
        answers: [
          'Ростехнадзором',
          'МЧС России',
          'МВД России',
          'Минприроды России',
        ],
        correctAnswers: ['Ростехнадзором'],
      },
      {
        code: '63717263',
        text:
          'На каком расстоянии вокруг каждого здания складов взрывчатых материалов должен быть снят дерн для предохранения от лесных и напольных пожаров?',
        answers: [
          'На расстоянии не менее 3,0 м',
          'На расстоянии не менее 4,0 м',
          'На расстоянии не менее 4,5 м',
          'На расстоянии не менее 5,0 м',
        ],
        correctAnswers: ['На расстоянии не менее 5,0 м'],
      },
      {
        code: '63717264',
        text:
          'На каком расстоянии от места погрузки (выгрузки) взрывчатых материалов разрешается располагать рубильники в нормальном исполнении?',
        answers: [
          'На расстоянии не менее 30 м',
          'На расстоянии не менее 40 м',
          'На расстоянии не менее 45 м',
          'На расстоянии не менее 50 м',
        ],
        correctAnswers: ['На расстоянии не менее 50 м'],
      },
      {
        code: '63717265',
        text:
          'Какими способами допускается уничтожать взрывчатые материалы согласно Правилам безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения?',
        answers: [
          'Только взрыванием',
          'Только сжиганием',
          'Только растворением в воде',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63717266',
        text:
          'Какова минимально допустимая запретная зона в подземных выработках при производстве взрывных работ?',
        answers: ['50 м', '45 м', '40 м', '30 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63717267',
        text:
          'Что необходимо делать в процессе пневмотранспортирования или пневмозаряжания?',
        answers: [
          'Производить одновременное заряжание взрывчатых веществ, содержащих в своем составе тротил или алюминиевую пудру',
          'Одновременно производить в 1 забое ручное формирование основного заряда с применением высокочувствительных к механическим воздействиям взрывчатых веществ и пневматическое заряжание',
          'Смешивать 2 и более типа взрывчатых веществ',
          'Расстилать брезент на месте заряжания под восходящими скважинами',
        ],
        correctAnswers: [
          'Расстилать брезент на месте заряжания под восходящими скважинами',
        ],
      },
      {
        code: '63717268',
        text:
          'Как часто следует проводить замер концентрации метана в месте укрытия взрывника в подземных выработках, опасных по газу?',
        answers: [
          'Перед каждым подключением электровзрывной сети к взрывному прибору',
          'Через каждые 4 часа',
          '1 раз в сутки',
          '1 раз в смену',
        ],
        correctAnswers: [
          'Перед каждым подключением электровзрывной сети к взрывному прибору',
        ],
      },
      {
        code: '63717269',
        text:
          'Какой документ является базовым для разработки паспортов и проектов буровзрывных (взрывных) работ, в том числе проектов массовых взрывов, выполняемых в конкретных условиях?',
        answers: [
          'Правила безопасности при взрывных работах',
          'Программа проведения взрывных работ',
          'Руководство по проведению буровзрывных работ',
          'Типовой проект буровзрывных (взрывных) работ',
        ],
        correctAnswers: ['Типовой проект буровзрывных (взрывных) работ'],
      },
      {
        code: '63717270',
        text:
          'Когда следует начинать загрузку бункера зарядного оборудования и непосредственно заряжание?',
        answers: [
          'После того как взрывник и специально проинструктированный рабочий доставят средства инициирования и взрывчатые материалы к месту производства взрывных работ',
          'После того как взрывник и руководитель взрывных работ осмотрят состояние кровли и стенок выработки и примут меры по приведению их в безопасное состояние',
          'После того как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены',
          'После того как взрывник ознакомится с техническим состоянием зарядного оборудования и проверит состояние узлов оборудования',
        ],
        correctAnswers: [
          'После того как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены',
        ],
      },
      {
        code: '63717271',
        text:
          'С какой периодичностью необходимо проверять взрывные приборы стационарных взрывных пунктов на угольных, сланцевых шахтах и объектах геологоразведки, опасных по газу или пыли, на соответствие установленным техническим характеристикам?',
        answers: [
          'Не реже 1 раза в 15 дней',
          'Не реже 1 раза в 20 дней',
          'Не реже 1 раза в 25 дней',
          'Не реже 1 раза в 30 дней',
        ],
        correctAnswers: ['Не реже 1 раза в 15 дней'],
      },
      {
        code: '63717272',
        text:
          'Кто дает разрешение  на проведение сотрясательного взрывания  в подземных выработках?',
        answers: [
          'Непосредственный руководитель сотрясательным взрыванием в забое',
          'Руководитель сотрясательным взрыванием с поверхности шахты',
          'Лицо технического надзора',
          'Технический руководитель организации',
        ],
        correctAnswers: [
          'Руководитель сотрясательным взрыванием с поверхности шахты',
        ],
      },
      {
        code: '63717273',
        text:
          'В течение какого времени комиссия по техническому расследованию обстоятельств и причин утраты взрывчатых материалов промышленного назначения должна составить акт технического расследования случая утраты?',
        answers: [
          'В течение 15 рабочих дней',
          'В течение 20 рабочих дней',
          'В течение 25 рабочих дней',
          'В течение 30 рабочих дней',
        ],
        correctAnswers: ['В течение 15 рабочих дней'],
      },
      {
        code: '63717274',
        text:
          'С какой периодичностью лица, назначенные распорядительным документом организации, должны проводить проверку правильности учета, хранения и наличия взрывчатых материалов на складах?',
        answers: [
          'Ежемесячно',
          '1 раз в 3 месяца',
          '1 раз в 6 месяцев',
          '1 раз в год',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63717275',
        text:
          'Какие материалы допускается использовать для насыпки валов складов взрывчатых материалов?',
        answers: ['Камень', 'Щебень', 'Пластичные или сыпучие грунты'],
        correctAnswers: ['Пластичные или сыпучие грунты'],
      },
      {
        code: '63717276',
        text:
          'Какое требование к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?',
        answers: [
          'Нанесение индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
          'Наносить индивидуальные индексы на электродетонаторы с помощью устройств обжимного типа необходимо на 4 мм ниже нижнего зига заводской обжимки гильзы',
          'Нанесение индивидуальных индексов, проверку сопротивления и другие операции необходимо проводить на специальном столе с закраинами, обитом брезентом по войлоку или полупроводящей резиновой пластиной толщиной не менее 2 мм',
        ],
        correctAnswers: [
          'Нанесение индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
        ],
      },
      {
        code: '63717277',
        text:
          'Какой цвет отличительной полосы или оболочек патронов (пачек) должны иметь непредохранительные взрывчатые вещества для взрывания только на земной поверхности?',
        answers: ['Белый', 'Желтый', 'Красный', 'Синий'],
        correctAnswers: ['Белый'],
      },
      {
        code: '63717278',
        text:
          'Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?',
        answers: [
          'Сторонней организацией, имеющей лицензию на выполнение экономических расчетов',
          'Ростехнадзором',
          'Организацией, на объекте которой произошла авария',
          'Муниципальным образованием, на территории которого произошла авария',
        ],
        correctAnswers: ['Организацией, на объекте которой произошла авария'],
      },
      {
        code: '63717279',
        text: 'С какой периодичностью следует проверять молниезащиту?',
        answers: [
          'В предгрозовой период, но не реже 1 раза в год, а также после выявления повреждений комиссией, назначенной руководителем (техническим руководителем) организации',
          'Не реже 2 раз в год: в предгрозовой и послегрозовой периоды',
          'Не реже 2 раз в год: весной и осенью',
          'В предгрозовой период, а затем не реже 1 раза в месяц в течение грозового периода, а также после выявления повреждений комиссией, назначенной руководителем организации',
        ],
        correctAnswers: [
          'В предгрозовой период, но не реже 1 раза в год, а также после выявления повреждений комиссией, назначенной руководителем (техническим руководителем) организации',
        ],
      },
      {
        code: '63717280',
        text:
          'В каком случае взрывчатые материалы должны подвергаться испытаниям? Выберите два правильных варианта ответа.',
        answers: [
          'Если взрывчатые материалы поступили на склад',
          'Если возникли сомнения в доброкачественности взрывчатых материалов',
          'Если истекает гарантийный срок взрывчатых материалов',
          'Если взрывчатые материалы хранятся за пределами полигона или лаборатории',
        ],
        correctAnswers: [
          'Если возникли сомнения в доброкачественности взрывчатых материалов',
          'Если истекает гарантийный срок взрывчатых материалов',
        ],
      },
      {
        code: '63717281',
        text:
          'В каком из перечисленных случаев допускается проведение прострелочных или взрывных работ в нефтяных, газовых и водяных скважинах?',
        answers: [
          'Если работы проводятся без применения лубрикаторов в сухих газирующих и поглощающих раствор скважинах',
          'Если работы проводятся в закрытых помещениях во время тумана (при видимости более 50 м)',
          'Если работы проводятся во время пурги, буранов',
          'Если работы проводятся во время грозы',
        ],
        correctAnswers: [
          'Если работы проводятся в закрытых помещениях во время тумана (при видимости более 50 м)',
        ],
      },
      {
        code: '63717282',
        text:
          'Через какое время после взрыва на земной поверхности ответственный руководитель массового взрыва должен организовать осмотр взорванных блоков и принять меры, предотвращающие отравление газами проверяющих работников?',
        answers: [
          'Не ранее чем через 3 минуты',
          'Не ранее чем через 5 минут',
          'Не ранее чем через 10 минут',
          'Не ранее чем через 15 минут',
        ],
        correctAnswers: ['Не ранее чем через 15 минут'],
      },
      {
        code: '63717283',
        text:
          'При каком условии ответственный руководитель взрыва дает указание о подаче боевого сигнала?',
        answers: [
          'Только при условии получения донесений от лиц, ответственных за заряжание и подготовку к взрыву блоков, за охрану опасной зоны и выставление постов, а также за вывод людей с территории опасной зоны',
          'Только при условии ознакомления с заполненной таблицей параметров взрывных работ',
          'Только при условии выполнения мероприятий, перечисленных в распорядке проведения массового взрыва',
          'При всех перечисленных условиях',
        ],
        correctAnswers: ['При всех перечисленных условиях'],
      },
      {
        code: '63717284',
        text:
          'На каком расстоянии от места взрыва должно находиться место укрытия взрывников при пропуске угля и породы в восстающих выработках?',
        answers: [
          'На расстоянии не менее 50 м',
          'На расстоянии не менее 100 м',
          'На расстоянии не менее 150 м',
          'На расстоянии не менее 200 м',
        ],
        correctAnswers: ['На расстоянии не менее 150 м'],
      },
      {
        code: '63717285',
        text:
          'Какие взрывчатые материалы различных групп совместимости должны храниться раздельно?',
        answers: [
          'Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом',
          'Средства инициирования группы совместимости S и средства инициирования группы совместимости В',
          'Дымные (группа совместимости D) и бездымные (группа совместимости C) пороха',
          'Детонирующий шнур групп совместимости D и S и средства инициирования групп совместимости B и S',
        ],
        correctAnswers: [
          'Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом',
        ],
      },
      {
        code: '63717286',
        text:
          'Какие из перечисленных веществ относятся к группе совместимости взрывчатых веществ и изделий на их основе D?',
        answers: [
          'Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов',
          'Метательные взрывчатые вещества и изделия (бездымный порох)',
          'Пиротехнические вещества и изделия, содержащие их',
          'Изделия, содержащие взрывчатые вещества без средств инициирования, но с метательным зарядом',
        ],
        correctAnswers: [
          'Взрывчатые вещества и изделия на их основе без средств инициирования и метательных зарядов',
        ],
      },
      {
        code: '63717287',
        text:
          'На каком расстоянии от выработок, служащих для постоянного прохода людей, допускается размещать раздаточную камеру вместимостью более 1000 кг взрывчатых веществ?',
        answers: [
          'На расстоянии не менее 25 м',
          'На расстоянии не менее 22 м',
          'На расстоянии не менее 20 м',
          'На расстоянии не менее 15 м',
        ],
        correctAnswers: ['На расстоянии не менее 25 м'],
      },
    ],
  },
  11: {
    63714: [
      {
        code: '63714000',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
          'Необходимо разработать планы мероприятий для каждого объекта отдельно',
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
        correctAnswers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63714001',
        text:
          'Что устанавливают перед предохранительной мембраной взрыворазрядителя при систематических нарушениях целостности мембран вследствие разрежения внутри оборудования или значительных пульсаций давления?',
        answers: [
          'Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм',
          'Защитную сетку из капрона толщиной 1 мм с ячейками размером 20 x 20 мм',
          'Защитную сетку из базальтового волокна толщиной 1 мм с ячейками размером 50 x 50 мм',
        ],
        correctAnswers: [
          'Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм',
        ],
      },
      {
        code: '63714002',
        text:
          'С какой периодичностью следует осуществлять контроль целостности мембран, герметизирующих прокладок, подвижности откидных клапанов и поворотных створок комбинированных взрыворазрядителей, отсутствие накоплений на мембранах и в отводящих трубопроводах пыли или продукта?',
        answers: [
          'Один раз в год',
          'Один раз в квартал',
          'Два раза в год',
          'Один раз в месяц',
        ],
        correctAnswers: ['Один раз в месяц'],
      },
      {
        code: '63714003',
        text:
          'К какой категории по надежности электроснабжения следует относить электроприемники предприятий по хранению и переработке зерна?',
        answers: [
          'К первой категории',
          'Ко второй категории',
          'К третьей категории',
        ],
        correctAnswers: ['Ко второй категории'],
      },
      {
        code: '63714004',
        text:
          'Что в соответствии с СП 108.13330.2012 определяется как "саморазгружающееся емкостное сооружение с высотой вертикальной части, не превышающей полуторную величину диаметра или меньшего размера"?',
        answers: [
          'Элеватор',
          'Комбикормовое предприятие',
          'Полова',
          'Силос',
          'Бункер',
        ],
        correctAnswers: ['Силос'],
      },
      {
        code: '63714005',
        text:
          'Какое требование предъявляется к взрыворазрядителям после их установки?',
        answers: [
          'Все взрыворазрядители пломбируются, а шиберные и бандажные - нумеруются',
          'Все взрыворазрядители пломбируются, а шиберные - нумеруются',
          'Все взрыворазрядители нумеруются, а шиберные и бандажные - пломбируются',
        ],
        correctAnswers: [
          'Все взрыворазрядители нумеруются, а шиберные и бандажные - пломбируются',
        ],
      },
      {
        code: '63714006',
        text:
          'Какой должен быть минимальный зооветеринарный разрыв между предприятиями и ветеринарно-санитарными утилизационными заводами для городских поселений и других муниципальных образований?',
        answers: ['500 м', '1200 м', '160 м', '700 м', '1000 м'],
        correctAnswers: ['1000 м'],
      },
      {
        code: '63714007',
        text:
          'Какие проверки необходимо производить при расчете подпорных стен по предельным состояниям второй группы (по пригодности к эксплуатации)? Выберите два правильных варианта ответа.',
        answers: [
          'Железобетонных элементов на допустимые величины раскрытия трещин',
          'Прочности элементов конструкции и узлов соединений',
          'Устойчивости положения стены против сдвига, опрокидывания и поворота',
          'Основания на допустимые деформации',
        ],
        correctAnswers: [
          'Железобетонных элементов на допустимые величины раскрытия трещин',
          'Основания на допустимые деформации',
        ],
      },
      {
        code: '63714008',
        text:
          'Какие нории оснащаются автоматически действующими тормозными устройствами?',
        answers: [
          'Нории производительностью более 50 т/ч',
          'Нории, проходящие внутри силосов или бункеров',
          'Нории со скоростью движения ленты менее 1 м/с',
          'Все нории должны быть оснащены тормозными устройствами',
          'Нории с производительностью менее 50 т/ч и скоростью движения ленты более 1 м/с',
        ],
        correctAnswers: [
          'Все нории должны быть оснащены тормозными устройствами',
        ],
      },
      {
        code: '63714009',
        text:
          'Из какого материала следует выполнять подвалы производственного назначения при их устройстве в сложных гидрогеологических условиях строительной площадки, при больших нагрузках на пол цеха или при наличии разнообразных проемов в стенах и перекрытиях, а также при особых технологических требованиях?',
        answers: [
          'Из несущих железобетонных панелей',
          'Из материала определенного в проектной документации',
          'Из бетонных блоков',
          'Из монолитного железобетона',
        ],
        correctAnswers: ['Из монолитного железобетона'],
      },
      {
        code: '63714010',
        text:
          'На проектирование каких сооружений распространяется СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий? Выберите два правильных варианта ответа.',
        answers: [
          'На проектирование сооружений со сроком эксплуатации до 3 лет',
          'На проектирование емкостных сооружений для водоснабжения и канализации',
          'На проектирование емкостных сооружений для жидкостей и газов',
          'На проектирование сооружений специального назначения',
          'На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях',
        ],
        correctAnswers: [
          'На проектирование емкостных сооружений для жидкостей и газов',
          'На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях',
        ],
      },
      {
        code: '63714011',
        text:
          'Какой надлежит принимать ширину ворот для железнодорожных въездов?',
        answers: [
          'Не менее 4,5 м',
          'Не менее 4,9 м',
          'Не менее 4,0 м',
          'Не менее 5,0 м',
        ],
        correctAnswers: ['Не менее 4,5 м'],
      },
      {
        code: '63714012',
        text:
          'Какой тип фундаментов не применяют при проектировании машин с динамическими нагрузками?',
        answers: ['Свайный винтовой', 'Стенчатый', 'Рамный', 'Безростверковый'],
        correctAnswers: ['Свайный винтовой'],
      },
      {
        code: '63714013',
        text:
          'Какой минимальный диаметр продольных и поперечных стержней следует принимать для армирования подошвы фундаментов при стороне подошвы менее 3 м?',
        answers: ['9 мм', '16 мм', '10 мм', '5 мм'],
        correctAnswers: ['10 мм'],
      },
      {
        code: '63714014',
        text:
          'При каком свободном объеме на нориях допускается не устанавливать взрыворазрядители?',
        answers: ['Менее 0,25 м3', '0,3 м3', '0,5 м3', '1,0 м3'],
        correctAnswers: ['Менее 0,25 м3'],
      },
      {
        code: '63714015',
        text:
          'Чем определяются методы (способы) управления, объемы автоматизации и используемая для этих целей элементная база?',
        answers: [
          'Техническим регламентом',
          'Внутренними распорядительными документами эксплуатирующей организации',
          'Правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
          'Техническим заданием на проектирование',
        ],
        correctAnswers: ['Техническим заданием на проектирование'],
      },
      {
        code: '63714016',
        text:
          'При помощи чего должен производиться спуск работников в силосы и бункеры (для хранения зерна, муки, отрубей, комбикормов и других продуктов)?',
        answers: [
          'Устройств, предназначенных для проведения работ на высоте',
          'Устройств, утвержденных для этих целей руководителем эксплуатирующей организации',
          'Веревочных складных лестниц',
        ],
        correctAnswers: [
          'Устройств, предназначенных для проведения работ на высоте',
        ],
      },
      {
        code: '63714017',
        text:
          'Кто должен обеспечивать целостность и исправность взрыворазрядителей при остановке объекта (краткосрочной, среднесрочной или длительной) в соответствии с требованиями Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'Организация-изготовитель',
          'Эксплуатирующая организация',
          'Сотрудники Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Проектная организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63714018',
        text: 'Где не следует устанавливать огнепреграждающие устройства?',
        answers: [
          'На трубопроводах подачи зерна с головок норий и из дробилок, через которые непосредственно ведется загрузка бункеров (в том числе надвесовых) и силосов',
          'На трубопроводах подачи зерна с головок норий и из дробилок на цепные и закрытые ленточные конвейеры, через которые ведется загрузка бункеров и силосов',
          'На трубопроводах подачи зерна с головок норий в надсушильные бункеры встроенных зерносушилок',
          'На трубопроводах подачи минерального сырья',
          'На трубопроводах, соединяющих (в том числе и через цепные и закрытые ленточные конвейеры) бункеры, силосы или нории элеватора с отдельно стоящими зерносушилками',
        ],
        correctAnswers: ['На трубопроводах подачи минерального сырья'],
      },
      {
        code: '63714019',
        text:
          'В каком случае у зданий и сооружений антисейсмические швы допускается не устраивать?',
        answers: [
          'Если здание или сооружение имеет сложную форму в плане',
          'Если смежные участки здания или сооружения имеют перепады высоты 5 м и более',
          'Если смежные участки здания или сооружения имеют существенные отличия друг от друга по жесткости и (или) массе',
          'Если одноэтажные здания высотой до 10 м имеют расчетную сейсмичность 7 баллов',
        ],
        correctAnswers: [
          'Если одноэтажные здания высотой до 10 м имеют расчетную сейсмичность 7 баллов',
        ],
      },
      {
        code: '63714020',
        text:
          'Каким фундаментам должно отдаваться предпочтение при проектировании фундаментов дробилок?',
        answers: ['Стенчатым', 'Плитным', 'Столбчатым', 'Ленточным'],
        correctAnswers: ['Стенчатым'],
      },
      {
        code: '63714021',
        text:
          'Где устанавливаются взрыворазрядители для взрывозащиты зерносушилок?',
        answers: [
          'Только на каскадных нагревателях',
          'Только на камерах нагрева',
          'Только на надсушильных бункерах: на верхней крышке или на боковой стенке в верхней части бункера',
          'На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки',
          'Не регламентируется',
        ],
        correctAnswers: [
          'На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки',
        ],
      },
      {
        code: '63714022',
        text:
          'Какие рекомендации по проектированию стенчатых и рамных фундаментов не соответствуют своду правил?',
        answers: [
          'При проектировании рамных фундаментов необходимо располагать ригели поперечных рам симметрично по отношению к осям стоек и избегать передачи нагрузок на ригели и балки с эксцентриситетом',
          'При проектировании рамных фундаментов высоту опорного сечения консоли при отсутствии соответствующих расчетов следует принимать не менее 0,75 ее вылета',
          'Высоту нижней фундаментной плиты в стенчатых и рамных фундаментах следует принимать по расчету, но не менее 0,4 м и не менее толщины стены или большего размера стоек',
          'При динамических нагрузках, требующих установки болтов диаметром не менее 42 мм, следует применять съемные фундаментные болты. Расстояние от нижних концов болтов до подошвы фундамента должно быть не менее 70 мм',
        ],
        correctAnswers: [
          'При динамических нагрузках, требующих установки болтов диаметром не менее 42 мм, следует применять съемные фундаментные болты. Расстояние от нижних концов болтов до подошвы фундамента должно быть не менее 70 мм',
        ],
      },
      {
        code: '63714023',
        text:
          'На какой коэффициент умножается расчетная нагрузка от веса сыпучих материалов при расчете на сжатие нижней зоны стен силосов?',
        answers: ['0,4', '0,3', '0,25', '0,9', '0,6'],
        correctAnswers: ['0,9'],
      },
      {
        code: '63714024',
        text:
          'Каким образом следует располагать торец отводящего трубопровода при применении (установке) взрыворазрядителей на первых этажах?',
        answers: [
          'Не ниже 2,5 м от планировочной отметки',
          'Не выше 2,0 м от планировочной отметки',
          'Не ниже 1,5 м от планировочной отметки',
          'Не выше 0,5 м от планировочной отметки',
        ],
        correctAnswers: ['Не ниже 2,5 м от планировочной отметки'],
      },
      {
        code: '63714025',
        text:
          'На каком расстоянии от корпуса затворов размещаются люки в самотеках, по которым поступает и выводится продукт из шлюзовых затворов?',
        answers: [
          'Не менее 250 мм',
          'Не менее 200 мм',
          'Не менее 100 мм',
          'Не менее 150 мм',
        ],
        correctAnswers: ['Не менее 250 мм'],
      },
      {
        code: '63714026',
        text:
          'Какие размеры должна иметь вертикальная колонна в механизированных зерновых складах с плоскими полами?',
        answers: [
          'Высоту 4000 мм, наружный диаметр 300 мм и шаг между кольцами 135 мм',
          'Высоту 5400 мм, наружный диаметр 380 мм и шаг между кольцами 160 мм',
          'Высоту 5500 мм, наружный диаметр 394 мм и шаг между кольцами 165 мм',
          'Высоту 4500 мм, наружный диаметр 375 мм и шаг между кольцами 155 мм',
        ],
        correctAnswers: [
          'Высоту 5500 мм, наружный диаметр 394 мм и шаг между кольцами 165 мм',
        ],
      },
      {
        code: '63714027',
        text: 'Какие конвейеры должны быть оснащены реле контроля скорости?',
        answers: [
          'Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более',
          'Винтовые конвейеры длиной более 15 м',
          'Цепные конвейеры со скоростью движения цепи 1 м/с и более',
          'Цепные конвейеры, установленные на подсилосных этажах деревянных элеваторов',
        ],
        correctAnswers: [
          'Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более',
        ],
      },
      {
        code: '63714028',
        text:
          'Что из перечисленного не отражается в технологической схеме производства графически?',
        answers: [
          'Движение сырья, готовой продукции',
          'Технологическое, аспирационное оборудование с указанием моделей и их основных характеристик',
          'Средства взрывопредупреждения',
          'Сведения и возможных инцидентах в работе и способах их ликвидации',
        ],
        correctAnswers: [
          'Сведения и возможных инцидентах в работе и способах их ликвидации',
        ],
      },
      {
        code: '63714029',
        text:
          'На каких расстояниях рекомендуется предусматривать температурно-усадочные швы для монолитных бетонных фундаментов?',
        answers: ['20 м', '45 м', '58 м', '10 м', '5 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63714030',
        text:
          'Какую высоту следует принимать от уровня земли до низа труб или поверхности изоляции, прокладываемых на высоких опорах, в непроезжей части территории, в местах прохода людей?',
        answers: ['2,2 м', '3 м', '5,5 м', '8 м', '7 м'],
        correctAnswers: ['2,2 м'],
      },
      {
        code: '63714031',
        text:
          'При выполнении какого из перечисленных условий допускается выводить взрыворазрядные устройства в производственные помещения?',
        answers: [
          'При наличии тамбур-шлюзов на выходе из этих производственных помещений',
          'При нормативном оснащении производственных помещений легкосбрасываемыми конструкциями',
          'При отсутствии в этих помещениях обслуживающего персонала',
          'При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз',
        ],
        correctAnswers: [
          'При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз',
        ],
      },
      {
        code: '63714032',
        text: 'Кем утверждается технический паспорт взрывобезопасности?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Главным инженером',
          'Технологом',
          'Механиком',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63714033',
        text:
          'В помещениях какой категории запрещается устройство выбоя отходов производства в тару в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'В помещениях категории Г',
          'В помещениях категорий А и Б',
          'В помещениях категории В',
          'В помещениях категории А',
          'В помещениях категории Б',
          'В помещениях категории Д',
        ],
        correctAnswers: ['В помещениях категории В'],
      },
      {
        code: '63714034',
        text:
          'Что из перечисленного должны содержать и предусматривать проекты силосов и силосных корпусов?',
        answers: [
          'Установку осадочных марок и реперов',
          'Указания по наблюдению за осадками этих сооружений',
          'Указания по режиму первичной и эксплуатационной загрузок и разгрузки силосов',
          'Проекты должны содержать и предусматривать все перечисленное',
        ],
        correctAnswers: [
          'Проекты должны содержать и предусматривать все перечисленное',
        ],
      },
      {
        code: '63714035',
        text:
          'Чем должны быть оборудованы все силосы элеваторов и склады силосного типа для хранения сырья?',
        answers: [
          'Защитным кожухом',
          'Устройством дистанционного контроля температуры',
          'Двойными уплотняющими прокладками',
        ],
        correctAnswers: ['Устройством дистанционного контроля температуры'],
      },
      {
        code: '63714036',
        text:
          'Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации?',
        answers: [
          'В подвальных и цокольных этажах не допускается размещение помещений всех перечисленных категорий',
          'Категории В1',
          'Категории А',
          'Категории Б',
        ],
        correctAnswers: ['Категории В1'],
      },
      {
        code: '63714037',
        text:
          'Какую наименьшую высоту компактной струи на уровне наивысшей точки следует принимать для тушения пожара рабочего здания элеватора высотой свыше 50 м от гидрантов с помощью насосов при расчетном расходе воды 5 л/с?',
        answers: ['10 м', '35 м', '5 м', '30 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63714038',
        text:
          'Какими следует проектировать фундаменты мельничных установок? Выберите два правильных варианта ответа.',
        answers: [
          'Кирпичными',
          'Монолитными',
          'Сборно-монолитными',
          'Ячеисто-бетонными',
        ],
        correctAnswers: ['Монолитными', 'Сборно-монолитными'],
      },
      {
        code: '63714039',
        text:
          'Какой этажности допускается проектировать здания I и II степеней огнестойкости категории Б мукомольно-крупяной и комбикормовой промышленности?',
        answers: [
          'До пяти включительно',
          'Не регламентируется',
          'До трех включительно',
          'До восьми включительно',
        ],
        correctAnswers: ['До восьми включительно'],
      },
      {
        code: '63714040',
        text:
          'Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристика объектов, в отношении которых разрабатывается план мероприятий',
          'Возможные сценарии возникновения и развития аварий на объектах, а также источники (места) возникновения аварий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается План мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63714041',
        text:
          'К какой категории по сейсмическим свойствам относятся твердомерзлые грунты при температуре выше -2 °С при строительстве и эксплуатации по принципу I при скорости поперечных волн от 250 до 700 м/с?',
        answers: ['К I категории', 'Ко II категории', 'К III категории'],
        correctAnswers: ['Ко II категории'],
      },
      {
        code: '63714042',
        text:
          'Какой формы следует предусматривать подошву отдельных фундаментов конусных дробилок?',
        answers: ['Круглой', 'Прямоугольной', 'Овальной', 'Квадратной'],
        correctAnswers: ['Квадратной'],
      },
      {
        code: '63714043',
        text:
          'Какие материалы допускается использовать в качестве легкосбрасываемых конструкций при недостаточной площади остекления?',
        answers: [
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 10 кПа (1000 кгс/м²)',
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 14 кПа (1400 кгс/м²)',
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 8 кПа (800 кгс/м²)',
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 2 кПа (200 кгс/м²)',
        ],
        correctAnswers: [
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 2 кПа (200 кгс/м²)',
        ],
      },
      {
        code: '63714044',
        text:
          'Какой орган согласовывает размещение промышленных объектов на территориях залегания полезных ископаемых?',
        answers: [
          'Ростехнадзор',
          'Минэнерго России',
          'Росприроднадзор',
          'Роснедра',
          'Роспотребнадзор',
        ],
        correctAnswers: ['Ростехнадзор'],
      },
      {
        code: '63714045',
        text:
          'Какие из перечисленных устройств могут использоваться в качестве огнепреграждающих в составе системы локализации взрывов?',
        answers: [
          'Порционные весы',
          'Цепной конвейер',
          'Закрытый ленточный конвейер',
          'Поворотные и распределительные устройства',
          'Пресс-грануляторы',
        ],
        correctAnswers: ['Порционные весы'],
      },
      {
        code: '63714046',
        text:
          'В помещениях какой категории допускается не предусматривать приточно-вытяжную или вытяжную вентиляцию для приямков, предназначенных для обслуживания норий и цепных конвейеров?',
        answers: [
          'В помещениях категории А',
          'В помещениях категории Б',
          'В помещениях категории В',
          'В помещениях категории Г',
        ],
        correctAnswers: ['В помещениях категории Б'],
      },
      {
        code: '63714047',
        text:
          'Какой должен быть объем производственного помещения на каждого работающего и площадь помещения?',
        answers: [
          'Не менее 15 м³ и не менее 4,5 м² соответственно',
          'Не менее 10 м2',
          'Не менее 9 м2',
          'Не менее 5 м2',
        ],
        correctAnswers: ['Не менее 15 м³ и не менее 4,5 м² соответственно'],
      },
      {
        code: '63714048',
        text:
          'На основании какой документации осуществляются периодические перемещения (перекачивания) шрота, жмыха и другого мучнистого сырья, склонного к самовозгоранию, из занимаемых ими емкостей в свободные?',
        answers: [
          'Планов-графиков, разработанных на основании Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
          'Планов-графиков, разработанных организацией и утвержденных в территориальных органах Ростехнадзора',
          'Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте',
        ],
        correctAnswers: [
          'Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте',
        ],
      },
      {
        code: '63714049',
        text:
          'Как оформляется готовность организации (объектов) к работе по приемке и размещению свежеубранного (нового урожая) зерна?',
        answers: [
          'Внутренним актом эксплуатирующей организации',
          'Техническим регламентом',
          'Проектной документацией',
          'Приказом (распоряжением) по предприятию',
        ],
        correctAnswers: ['Внутренним актом эксплуатирующей организации'],
      },
      {
        code: '63714050',
        text:
          'В соответствии с чем составляются паспорта аспирационных и пневмотранспортных установок?',
        answers: [
          'В соответствии с инструкцией по эксплуатации',
          'В соответствии с внутренними распорядительными документами эксплуатирующей объект организации',
          'В соответствии с технологическим регламентом',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: [
          'В соответствии с внутренними распорядительными документами эксплуатирующей объект организации',
        ],
      },
      {
        code: '63714051',
        text:
          'Контроль температуры подшипников каких устройств на объектах необходимо осуществлять устройствами дистанционного автоматического контроля температуры?',
        answers: [
          'Турбокомпрессоров',
          'Турбовоздуходувных машин',
          'Дробилок и вальцовых станков',
          'Всех перечисленных устройств',
        ],
        correctAnswers: ['Всех перечисленных устройств'],
      },
      {
        code: '63714052',
        text:
          'Что из перечисленного допускается размещать во взрывопожароопасных зданиях и сооружениях?',
        answers: [
          'Все перечисленное размещать не допускается',
          'Бункеры для хранения аспирационных относов',
          'Бункеры для хранения пыли',
          'Бункеры для хранения пылевидных продуктов',
        ],
        correctAnswers: ['Все перечисленное размещать не допускается'],
      },
      {
        code: '63714053',
        text:
          'При какой сейсмичности запрещаются выполнение кладки несущих, самонесущих стен, заполнение каркаса и перегородок, в том числе усиленных армированием или железобетонными включениями, из кирпича (камня, блоков)?',
        answers: [
          'При сейсмичности 6 баллов',
          'При сейсмичности 7 баллов',
          'При сейсмичности 8 баллов',
          'При сейсмичности 9 баллов',
          'При сейсмичности 5 баллов',
        ],
        correctAnswers: ['При сейсмичности 9 баллов'],
      },
      {
        code: '63714054',
        text:
          'Что запрещается при проектировании подвалов производственного назначения?',
        answers: [
          'Предусматривать высоту проходов в подвалах (в чистоте) не менее 2 м',
          'Защищать подвал гидроизоляцией при наличии грунтовых вод',
          'Предусматривать полы с уклоном к трапам (приямкам) канализации с обособленной системой отвода воды',
          'Непосредственно соединять приямки с ливневой и другими типами канализации',
        ],
        correctAnswers: [
          'Непосредственно соединять приямки с ливневой и другими типами канализации',
        ],
      },
      {
        code: '63714055',
        text:
          'Чему должна быть равна площадь легкосбрасываемых конструкций при отсутствии расчетных данных?',
        answers: [
          'Не менее 0,05 м2 на 1,0 м3 объема помещений категории А и не менее 0,03 м2 на 1,0 м3 объема помещений категории Б',
          'Не менее 0,040 м2 на 1,0 м3 объема помещений категории А и не менее 0,02 м2 на 1,5 м3 объема помещений категории Б',
          'Не менее 0,035 м2 на 2,0 м3 объема помещений категории А и не менее 0,015 м2 на 3,0 м3 объема помещений категории Б',
          'Не менее 0,025 м2 на 1,5 м3 объема помещений категории А и не менее 0,01 м2 на 2,0 м3 объема помещений категории Б',
        ],
        correctAnswers: [
          'Не менее 0,05 м2 на 1,0 м3 объема помещений категории А и не менее 0,03 м2 на 1,0 м3 объема помещений категории Б',
        ],
      },
      {
        code: '63714056',
        text:
          'Какую высоту от уровня земли до низа труб (или поверхности их изоляции), прокладываемых на низких опорах на свободной территории вне проезда транспортных средств и прохода людей, следует принимать при ширине группы труб от 1,5 м и более?',
        answers: ['0,2 м', '0,5 м', '0,6 м', '0,1 м', '1,2 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63714057',
        text:
          'Какое требование к проектированию оснований, фундаментов и стен подвалов в сейсмических районах указано неверно?',
        answers: [
          'В фундаментах и стенах подвалов из крупных блоков должна быть обеспечена перевязка кладки в каждом ряду, а также во всех углах и пересечениях на глубину не менее 1/2 высоты блока',
          'В зданиях при расчетной сейсмичности 9 баллов должна предусматриваться укладка в горизонтальные швы в углах и пересечениях стен подвалов арматурных сеток длиной 2 м с продольной арматурой общей площадью сечения не менее 1 см²',
          'По верху сборных ленточных фундаментов следует укладывать слой цементного раствора марки 200 толщиной не менее 40 мм и продольную арматуру диаметром 10 мм в количестве трех стержней при расчетной сейсмичности 7 баллов',
        ],
        correctAnswers: [
          'По верху сборных ленточных фундаментов следует укладывать слой цементного раствора марки 200 толщиной не менее 40 мм и продольную арматуру диаметром 10 мм в количестве трех стержней при расчетной сейсмичности 7 баллов',
        ],
      },
      {
        code: '63714058',
        text:
          'Какие из перечисленных материалов относятся к связным сыпучим материалам?',
        answers: [
          'Материалы с крупностью зерен 3 мм',
          'Песок с крупностью зерен до 2 мм и влажностью до 2%',
          'Щебень',
          'Галька',
          'Материалы, содержащие фракции менее 2 мм и имеющие влажность более 2%',
        ],
        correctAnswers: [
          'Материалы, содержащие фракции менее 2 мм и имеющие влажность более 2%',
        ],
      },
      {
        code: '63714059',
        text:
          'Какие требования электростатической искробезопасности на объектах хранения и переработки растительного сырья указаны неверно?',
        answers: [
          'Гибкие рукава, служащие для соединения автомуковоза с системой приема, должны быть токопроводными и выполнены в виде металлорукава из электропроводной резины',
          'Вставки из органического стекла, устанавливаемые в пневмотранспортных установках, обвивают снаружи проволокой с шагом витков не более 300 мм',
          'Защитное заземление должно быть основным средством защиты от опасных проявлений статического электричества',
          'Оборудование из электропроводящих материалов, а также его рабочие органы, узлы и элементы конструкций, выполненные из электропроводящих материалов, подлежат заземлению',
        ],
        correctAnswers: [
          'Вставки из органического стекла, устанавливаемые в пневмотранспортных установках, обвивают снаружи проволокой с шагом витков не более 300 мм',
        ],
      },
      {
        code: '63714060',
        text:
          'В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?',
        answers: [
          'Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 10 дней после внесения изменений в системы управления технологическими процессами на объекте',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства.',
          'После назначения нового руководителя организации, эксплуатирующей объект',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства.',
        ],
      },
      {
        code: '63714061',
        text:
          'Какой следует принимать коэффициент надежности по нагрузке для давления сыпучих материалов на стены и днища силосов, бункеров и зерноскладов при расчете на прочность?',
        answers: ['2,4', '1,3', '1,2', '1,7'],
        correctAnswers: ['1,3'],
      },
      {
        code: '63714062',
        text:
          'Какой не следует принимать высоту стен закромов при проектировании сооружений промышленных предприятий?',
        answers: ['2,5 м', '3,6 м', '4,8 м', '6,0 м'],
        correctAnswers: ['2,5 м'],
      },
      {
        code: '63714063',
        text:
          'Какую степень защиты оболочки должны иметь применяемые во взрыво- и пожароопасных зонах ручные и переносные аппараты и приборы?',
        answers: [
          'Не ниже IP54',
          'Не ниже IP44',
          'Не ниже IP23',
          'Не ниже IP53',
        ],
        correctAnswers: ['Не ниже IP54'],
      },
      {
        code: '63714064',
        text:
          'На сколько уровень полов первого этажа зданий должен быть выше планировочной отметки примыкающих к зданиям участков?',
        answers: [
          'Не менее чем на 15 см',
          'Не менее чем на 12 см',
          'Не менее чем на 10 см',
          'Не менее чем на 8 см',
        ],
        correctAnswers: ['Не менее чем на 15 см'],
      },
      {
        code: '63714065',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из общих и специальных разделов',
          'Из частных разделов',
          'Из специализированных разделов',
          'Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности',
          'Из графических разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63714066',
        text:
          'Какие нормы по проектированию наружных и располагаемых внутри зданий этажерок указаны неверно?',
        answers: [
          'По наружному периметру этажерок и площадок необходимо предусматривать ограждения высотой 1 м. Нижняя часть ограждения должна иметь сплошной борт высотой 0,14 м',
          'Площадь перекрытия этажерок должна обеспечивать проход шириной не менее 1 м при постоянном обслуживании оборудования',
          'Наружные этажерки следует рассчитывать на снеговую и ветровую нагрузки. На верхнем ярусе снеговую нагрузку надлежит учитывать полностью, а на промежуточных ярусах - в размере 50%',
          'Опирание площадок и лестниц на оборудование, являющееся источником вибрации, допускается в исключительных случаях',
        ],
        correctAnswers: [
          'Опирание площадок и лестниц на оборудование, являющееся источником вибрации, допускается в исключительных случаях',
        ],
      },
      {
        code: '63714067',
        text:
          'С учетом ограничений каких документов эксплуатирующей организацией в технологическом регламенте определяются сроки, в которые проверяется температура сырья? Выберите 2 варианта ответа.',
        answers: [
          'Документов по стандартизации',
          'Технического паспорта',
          'Технических регламентов',
          'Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
          'Инструкций',
        ],
        correctAnswers: [
          'Документов по стандартизации',
          'Технических регламентов',
        ],
      },
      {
        code: '63714068',
        text:
          'Что из перечисленного не отражается в технологическом регламенте?',
        answers: [
          'Описание технологического процесса производства',
          'Информация о контроле и управлении технологическим процессом',
          'Условия безопасной эксплуатации производства',
          'Движение сырья, готовой продукции',
        ],
        correctAnswers: ['Движение сырья, готовой продукции'],
      },
      {
        code: '63714069',
        text:
          'На каком расстоянии друг от друга должны размещаться мостики через конвейеры в производственных помещениях, а также в галереях и эстакадах?',
        answers: [
          'Не более 50 м в производственных помещениях, не более 100 м в галереях и эстакадах',
          'Не более 75 м в производственных помещениях, галереях и эстакадах',
          'Не более 55 м в производственных помещениях, не более 120 м в галереях и эстакадах',
          'Не более 90 м в производственных помещениях, не более 150 м в галереях и эстакадах',
        ],
        correctAnswers: [
          'Не более 50 м в производственных помещениях, не более 100 м в галереях и эстакадах',
        ],
      },
      {
        code: '63714070',
        text:
          'Какие требования надлежит принимать при проектировании отдельно стоящих силосов и силосных корпусов? Выберите все правильные варианты ответов.',
        answers: [
          'Высоту стен силосов, подсилосных и надсилосных этажей - кратную 0,5 м',
          'В силосных корпусах для хранения сырья и готовой продукции мукомольно-крупяных предприятий и комбикормовых заводов с двумя подсилосными этажами и более допускается принимать каркас по типу производственных зданий с сеткой колонн 6 x 3 м',
          'Наружные диаметры круглых отдельно стоящих силосов, равные 6, 9, 12, 18 и 24 м',
          'Сетки разбивочных осей, проходящих через центры сблокированных в силосные корпуса силосов, размером 3 x 3, 6 x 6, 9 x 9 и 12 x 12 м',
          'Наружные диаметры круглых отдельно стоящих силосов, равные 8, 15, 19 и 25 м',
        ],
        correctAnswers: [
          'В силосных корпусах для хранения сырья и готовой продукции мукомольно-крупяных предприятий и комбикормовых заводов с двумя подсилосными этажами и более допускается принимать каркас по типу производственных зданий с сеткой колонн 6 x 3 м',
          'Наружные диаметры круглых отдельно стоящих силосов, равные 6, 9, 12, 18 и 24 м',
          'Сетки разбивочных осей, проходящих через центры сблокированных в силосные корпуса силосов, размером 3 x 3, 6 x 6, 9 x 9 и 12 x 12 м',
        ],
      },
      {
        code: '63714071',
        text:
          'Какие действия необходимо предпринять в случае превышения допустимой температуры заложенного на хранение растительного сырья, продуктов его переработки и комбикормового сырья, указанной для соответствующего вида сырья (продукта) в технологическом регламенте? Выберите все правильные варианты ответов.',
        answers: [
          'Применить активное вентилирование, сушку',
          'Периодически увлажнять сырье (продукт) до снижения температуры до допустимых значений',
          'Обеспечить постоянный контакт сырья (продукта) с токопроводящей поверхностью стенки бункера',
          'Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку',
        ],
        correctAnswers: [
          'Применить активное вентилирование, сушку',
          'Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку',
        ],
      },
      {
        code: '63714072',
        text:
          'С каким пределом огнестойкости должны проектироваться ограждающие конструкции лестничных клеток?',
        answers: [
          'Не менее RE 115',
          'Не менее RE 145',
          'Не менее RE 130',
          'Не менее RE 125',
        ],
        correctAnswers: ['Не менее RE 145'],
      },
      {
        code: '63714073',
        text:
          'Что является критерием эффективности магнитной защиты при приеме сырья с автомобильного, водного и железнодорожного транспорта?',
        answers: [
          'Полное извлечение металломагнитных примесей, способных инициировать искру',
          'Условия, исключающие возникновение разрядов статического электричества',
          'Заземление рабочих органов, узлов и элементов конструкций, выполненных из электропроводящих материалов',
        ],
        correctAnswers: [
          'Полное извлечение металломагнитных примесей, способных инициировать искру',
        ],
      },
      {
        code: '63714074',
        text: 'В каких местах допускается размещать надземные коммуникации?',
        answers: [
          'Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами прокладываются по эстакадам, отдельно стоящим колоннам и опорам из горючих материалов',
          'Все ответы неверны',
          'Трубопроводы с горючими жидкими и газообразными продуктами размещаются в галереях, если смешение продуктов может вызвать взрыв или пожар',
          'Газопроводы горючих газов размещаются по территории складов легковоспламеняющихся и горючих жидкостей и материалов',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63714075',
        text:
          'Что недопустимо при проектировании фундаментов машин с вращающимися частями?',
        answers: [
          'Опирать стойки площадок обслуживания машин и перекрытия над подвалом на нижние плиты (или ростверки) рамных фундаментов машин',
          'Опирать вкладные участки перекрытия на элементы верхнего строения фундаментов машин',
          'Возводить фундаменты машин при устройстве под всем машинным залом общей фундаментной плиты непосредственно на этой плите',
          'Связывать элементы верхнего строения фундаментов с элементами и конструкциями здания',
        ],
        correctAnswers: [
          'Связывать элементы верхнего строения фундаментов с элементами и конструкциями здания',
        ],
      },
      {
        code: '63714076',
        text:
          'Что необходимо использовать в качестве отводящих трубопроводов взрыворазрядителей?',
        answers: [
          'Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм',
          'Гибкие резиновые шланги',
          'Только медные металлополимерные трубы',
          'Только керамические трубы',
        ],
        correctAnswers: [
          'Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм',
        ],
      },
      {
        code: '63714077',
        text:
          'Каким требованиям должны отвечать устройства, применяемые для спуска людей в силосы?',
        answers: [
          'Требованиям, установленным нормативными правовыми актами, содержащими требования промышленной безопасности при проведении работ с инструментом и приспособлениями',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при выполнении огневых и газоопасных работ',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе в замкнутом пространстве',
        ],
        correctAnswers: [
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте',
        ],
      },
      {
        code: '63714078',
        text:
          'Каким образом следует проектировать лестничные марши для эвакуации не более 50 человек?',
        answers: [
          'Допускается принимать ширину лестничных маршей 0,7 м и уклон 1:1,5',
          'Допускается принимать ширину лестничных маршей 0,9 м и уклон 1:1,5',
          'Допускается принимать ширину лестничных маршей 1,0 м и уклон 1:1,1',
          'Допускается принимать ширину лестничных маршей 1,2 м и уклон 1:1,2',
        ],
        correctAnswers: [
          'Допускается принимать ширину лестничных маршей 0,9 м и уклон 1:1,5',
        ],
      },
      {
        code: '63714079',
        text:
          'При каком способе закрепления строительных конструкций и технологического оборудования на фундаментах не требуется соответствующее обоснование?',
        answers: [
          'На виброгасителях',
          'На анкерных болтах',
          'При всех перечисленных способах',
          'На клею',
        ],
        correctAnswers: ['На анкерных болтах'],
      },
      {
        code: '63714080',
        text:
          'Из какого материала, как правило, следует проектировать закрома?',
        answers: [
          'Из шамотного кирпича',
          'Из листового металла',
          'Из железобетона',
          'Из поликарбоната',
          'Из стеклопластика',
        ],
        correctAnswers: ['Из железобетона'],
      },
      {
        code: '63714081',
        text:
          'Какое количество входов-выходов должны иметь галереи и площадки, имеющие длину более 20 м и расположенные на высоте свыше 2 м от уровня земли или пола помещения?',
        answers: [
          'Не менее двух входов-выходов',
          'Не менее четырех входов-выходов',
          'Не менее трех входов-выходов',
        ],
        correctAnswers: ['Не менее двух входов-выходов'],
      },
      {
        code: '63714082',
        text:
          'При расположении на какой высоте от пола осей приводных барабанов необходимо предусматривать специальные площадки с перилами высотой не менее 1 м с зашивкой внизу на 0,15 м с обеспечением проходов для обслуживания головок норий?',
        answers: ['Более 1,5 м', 'Более 1,0 м', 'Более 1,2 м', 'Более 0,5 м'],
        correctAnswers: ['Более 1,5 м'],
      },
      {
        code: '63714083',
        text:
          'Что из перечисленного не отражается в паспортах аспирационных установок, находящихся в эксплуатации?',
        answers: [
          'Принципиальные схемы установки и спецификации оборудования',
          'Описание проведенного ремонта',
          'Результаты осмотра установки',
          'Типы разгрузителей, шлюзовых затворов и приемных устройств',
        ],
        correctAnswers: [
          'Типы разгрузителей, шлюзовых затворов и приемных устройств',
        ],
      },
      {
        code: '63714084',
        text:
          'Инертные газы с каким расходом необходимо подавать в технологические люки конусной части аварийных силосов, бункеров для доступа воздуха в случае обрушения выгружаемого продукта в процессе выгрузки?',
        answers: [
          'С расходом, в два раза превышающим расходы на флегматизацию',
          'С расходом, в три раза превышающими расходы на флегматизацию',
          'С расходом, в четыре раза превышающим расходы на флегматизацию',
          'С расходом, равным расходу на флегматизацию',
        ],
        correctAnswers: [
          'С расходом, в три раза превышающими расходы на флегматизацию',
        ],
      },
      {
        code: '63714085',
        text:
          'Какое оборудование допускается устанавливать без фундаментов на подстилающий слой полов промышленных зданий при обосновании расчетом?',
        answers: [
          'Все перечисленное оборудование',
          'С вращающимися частями',
          'Станочное оборудование, агрегируемое на железобетонных опорных плитах',
          'С кривошипно-шатунными механизмами',
        ],
        correctAnswers: ['Все перечисленное оборудование'],
      },
      {
        code: '63714086',
        text:
          'Какими должны приниматься размеры пешеходных галерей или тоннелей при необходимости наличия в проектах?',
        answers: [
          'В зависимости от пропускной способности, но не более 2 м',
          'Высота - не менее 3 м, ширина - не менее 2 м',
          'Высота - не менее 2 м, ширина - не менее 1,5 м',
          'Высота - не менее 4 м, ширина - не менее 2,5 м',
        ],
        correctAnswers: ['Высота - не менее 2 м, ширина - не менее 1,5 м'],
      },
      {
        code: '63714087',
        text:
          'В помещения каких классов вводы кабелей и труб электропроводки из взрывоопасных зон всех классов не должны быть плотно заделаны несгораемыми материалами? Выберите 2 варианта ответа.',
        answers: [
          'В помещениях классов В-Iа',
          'В помещениях классов В-Iб',
          'В помещениях классов В-IIа',
          'В помещениях классов В-I',
          'В помещениях классов В-II',
        ],
        correctAnswers: [
          'В помещениях классов В-I',
          'В помещениях классов В-II',
        ],
      },
      {
        code: '63714088',
        text:
          'Кто проводит обследование объекта перед разработкой технического паспорта взрывобезопасности?',
        answers: [
          'Комиссия, назначенная приказом эксплуатирующей организации',
          'Главный инженер',
          'Технический руководитель',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: [
          'Комиссия, назначенная приказом эксплуатирующей организации',
        ],
      },
      {
        code: '63714089',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '3 года', '3 месяца', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63714090',
        text:
          'Какое из перечисленных требований к взрывопожароопасным производственным объектам хранения и переработки растительного сырья указано неверно?',
        answers: [
          'К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель',
          'Устройство железнодорожных путей, переездов и переходов через них, а также организация и эксплуатация железнодорожного хозяйства организации должны соответствовать требованиям нормативных правовых актов в сфере транспорта',
          'Необходимые для производственных целей подземные резервуары, колодцы, пожарные водоемы должны быть закрыты или ограждены со всех сторон',
          'В организации, эксплуатирующей объекты, должно быть обеспечено исправное состояние отводов атмосферных осадков от зданий и сооружений к водостокам, дорог для транспорта, пожарных проездов, рельсовых путей (с соблюдением требуемых габаритов, допускаемых уклонов и радиусов закруглений), сетей наружного освещения, пешеходных дорожек, пожарных и хозяйственных водопроводов, а также ограждений территории объектов и организации',
          'Доступ на территорию организации, эксплуатирующей объекты, посторонним лицам запрещен',
        ],
        correctAnswers: [
          'К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель',
        ],
      },
      {
        code: '63714091',
        text:
          'Какие из перечисленных устройств не должны входить в комплект серийно изготавливаемых норий, конвейеров?',
        answers: [
          'Реле контроля скорости',
          'Датчики подпора',
          'Световая и звуковая сигнализации',
          'Устройства контроля сбегания ленты',
          'Устройства контроля обрыва цепи',
        ],
        correctAnswers: ['Световая и звуковая сигнализации'],
      },
      {
        code: '63714092',
        text:
          'Какие показатели не входят в число расчетных статических нагрузок?',
        answers: [
          'Коэффициент надежности по нагрузке',
          'Вес грунта на обрезах фундамента',
          'Вес фундамента',
          'Вес машины и вес вспомогательного оборудования',
        ],
        correctAnswers: ['Коэффициент надежности по нагрузке'],
      },
      {
        code: '63714093',
        text:
          'Каким должно быть сопротивление изоляции обмоток электромагнитов?',
        answers: [
          'Не менее 0,3 МОм',
          'Не менее 0,4 МОм',
          'Не менее 0,5 МОм',
          'Не менее 0,2 МОм',
        ],
        correctAnswers: ['Не менее 0,5 МОм'],
      },
      {
        code: '63714094',
        text:
          'На каком расстоянии от корпуса защищаемого оборудования необходимо устанавливать предохранительную мембрану или клапан?',
        answers: [
          'На минимально возможном расстоянии',
          'На максимально возможном расстоянии',
          'На расстоянии не более 1 м',
          'На расстоянии не менее 2 м',
          'На расстоянии 5 м',
        ],
        correctAnswers: ['На минимально возможном расстоянии'],
      },
      {
        code: '63714095',
        text:
          'Что из перечисленного допускается хранить на территории эксплуатирующей организации открытым способом?',
        answers: [
          'Зерновые отходы',
          'Лузгу',
          'Зерновую пыль',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63714096',
        text:
          'Что не учитывается при определении диаметров (площадей) проходных сечений взрыворазрядителей для оборудования?',
        answers: [
          'Величина защищаемого объема',
          'Допустимое давление взрыва, статическое давление вскрытия мембраны (клапана)',
          'Тип взрыворазрядителя',
          'Длина, количество и величина углов поворота отводящих трубопроводов',
          'Температура и влажность в месте установки оборудования',
        ],
        correctAnswers: [
          'Температура и влажность в месте установки оборудования',
        ],
      },
      {
        code: '63714097',
        text: 'Какое требование по проектированию бункеров указано неверно?',
        answers: [
          'При эксплуатации бункеров в агрессивной и газовой средах их наружные поверхности следует защищать от коррозии',
          'При сочетании истирающего воздействия, высокой температуры и химической агрессии сыпучего материала внутренние поверхности бункеров следует защищать плитами из шлакокаменного литья, износостойкого и жаростойкого бетона',
          'При проектировании бункеров для связных материалов, поступающих в нагретом или смерзшемся состоянии, необходимо предусматривать теплоизоляцию стен бункеров в соответствии с теплотехническим расчетом',
          'При проектировании бункеров для влажных сыпучих материалов, располагаемых в неотапливаемых помещениях, необходимо предусматривать специальную защитную облицовку-футеровку',
        ],
        correctAnswers: [
          'При проектировании бункеров для влажных сыпучих материалов, располагаемых в неотапливаемых помещениях, необходимо предусматривать специальную защитную облицовку-футеровку',
        ],
      },
      {
        code: '63714098',
        text:
          'К какой категории по сейсмическим свойствам относятся глинистые грунты с показателем консистенции IL больше 0,5?',
        answers: ['К I категории', 'К II категории', 'К III категории'],
        correctAnswers: ['К III категории'],
      },
      {
        code: '63714099',
        text:
          'Какие из перечисленных документов разрабатываются (составляются) в целях обеспечения безопасности производственных процессов на объектах организации?',
        answers: [
          'Технологические регламенты и схемы',
          'Паспорта на аспирационные и пневмотранспортные установки',
          'Паспорта на взрыворазрядные устройства',
          'Все перечисленные документы',
          'Технический паспорт взрывобезопасности опасного производственного объекта',
        ],
        correctAnswers: ['Все перечисленные документы'],
      },
      {
        code: '63714100',
        text:
          'Какое минимальное расстояние по горизонтали (в свету) от кабеля до крайнего провода должно быть при прокладке кабельной линии параллельно высоковольтной линии (ВЛ) напряжением 110 кВ и выше?',
        answers: ['5 м', '10 м', '8 м', '12 м', '25 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63714101',
        text:
          'На каком расстоянии от кромки полотна автомобильной дороги должен выходить на поверхность кабель в случае перехода кабельной линии, непосредственно прокладываемой в земле, в воздушную линию?',
        answers: [
          'Не менее 3,5 м',
          'Не менее 3,1 м',
          'Не менее 2,2 м',
          'Не менее 1,7 м',
        ],
        correctAnswers: ['Не менее 3,5 м'],
      },
      {
        code: '63714102',
        text:
          'В помещениях какой категории по пожарной и взрывопожарной опасности на объектах хранения и переработки растительного сырья должны предусматриваются наружные легкосбрасываемые конструкции? Выберите 2 варианта ответа.',
        answers: [
          'В помещениях категории А',
          'В помещениях категории Б',
          'В помещениях категории В',
          'В помещениях категории Г',
        ],
        correctAnswers: [
          'В помещениях категории А',
          'В помещениях категории Б',
        ],
      },
      {
        code: '63714103',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
          'В целях минимизации последствий аварий',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63714104',
        text: 'При каком условии допускается проведение обеззараживания зерна?',
        answers: [
          'При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов',
          'При условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации объекта',
          'При условии разработки и осуществления компенсирующих мер организационно-технического характера, направленных на повышение уровня взрывопожаробезопасности',
        ],
        correctAnswers: [
          'При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов',
        ],
      },
      {
        code: '63714105',
        text: 'Какие должны быть проходы у башмака нории?',
        answers: [
          'С трех сторон, подлежащих обслуживанию, шириной не менее 0,7 м.',
          'С четырех сторон, подлежащих обслуживанию, шириной не менее 0,9 м',
          'С двух сторон, подлежащих обслуживанию, шириной не менее 0,5 м',
          'С четырех сторон, подлежащих обслуживанию, шириной не менее 1,2 м',
        ],
        correctAnswers: [
          'С трех сторон, подлежащих обслуживанию, шириной не менее 0,7 м.',
        ],
      },
      {
        code: '63714106',
        text:
          'В каком случае нарушены требования к выполнению армирования элементов стенчатых и рамных фундаментов?',
        answers: [
          'Стойки следует армировать симметричной продольной арматурой с шагом не более 300 мм',
          'По боковым граням балок и ригелей не реже чем через 400 мм по высоте сечения следует устанавливать промежуточные стержни диаметром не менее 10 мм',
          'При конструктивном армировании стен стенчатого фундамента диаметр вертикальных стержней должен быть не менее 12 мм, а горизонтальных - не менее 10 мм. Шаг стержней в обоих направлениях следует принимать равным 200 мм',
        ],
        correctAnswers: [
          'По боковым граням балок и ригелей не реже чем через 400 мм по высоте сечения следует устанавливать промежуточные стержни диаметром не менее 10 мм',
        ],
      },
      {
        code: '63714107',
        text:
          'Какое должно быть минимальное расстояние по горизонтали (в свету) от канализации до водопровода из железобетонных труб, прокладываемых в глинистых грунтах?',
        answers: ['1,5 м', '6 м', '8 м', '5 м', '25 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63714108',
        text:
          'Какими рекомендуется проектировать сборно-монолитные фундаменты дробилок? Выберите все правильные варианты ответов.',
        answers: ['Рамными', 'Ригельными', 'Блочными', 'Стенчатыми'],
        correctAnswers: ['Рамными', 'Стенчатыми'],
      },
      {
        code: '63714109',
        text:
          'Какие из перечисленных расстояний при устройстве подпорных стен соответствуют СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий?',
        answers: [
          'В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м',
          'Минимальное расстояние от оси ближайшего железнодорожного пути до внутренней грани подпорной стены на прямых участках следует принимать не менее 1,5 м',
          'Высота подпорных стен для грузовых рамп автомобильного транспорта со стороны подъезда автомобилей должна быть равной 1,5 м от уровня поверхности проезжей части дорог или погрузочно-разгрузочной площадки',
          'При расположении автодорог вдоль подпорной стены у нее следует предусматривать тротуар шириной не менее 1 м с бортовым камнем высотой не менее 0,6 м',
        ],
        correctAnswers: [
          'В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м',
        ],
      },
      {
        code: '63714110',
        text:
          'На какие из перечисленных нагрузок следует рассчитывать пролетные строения и опоры конвейерных и пешеходных галерей и эстакад?',
        answers: [
          'Только на нагрузки от атмосферных воздействий',
          'Только на продольные нагрузки, передающиеся от ленточных конвейеров, и на динамические, создаваемые подвижными частями конвейера',
          'Только на вертикальные нагрузки, возникающие от веса транспортируемого на ленте груза, веса просыпи, ремонтных материалов и людей',
          'На все перечисленные нагрузки, включая вертикальные нагрузки от собственного веса галерей и конвейера',
        ],
        correctAnswers: [
          'На все перечисленные нагрузки, включая вертикальные нагрузки от собственного веса галерей и конвейера',
        ],
      },
      {
        code: '63714111',
        text: 'Что должно предусматривать взрывопредупреждение?',
        answers: [
          'Обеззараживание зерна',
          'Уменьшение пылеобразования в технологическом оборудовании, силосах и бункерах',
          'Организацию планово-предупредительного ремонта',
          'Должно предусматривать все перечисленное',
          'Установку производственной и аварийной сигнализации',
        ],
        correctAnswers: ['Должно предусматривать все перечисленное'],
      },
      {
        code: '63714112',
        text:
          'В каком случае допускается размещать предприятия, здания и сооружения по хранению и переработке зерна в санитарно-защитной зоне предприятий, относимых по выделению производственных вредностей в окружающую среду к I и II классу?',
        answers: [
          'Только при согласовании с территориальным органом Федеральной службы по экологическому, технологическому и атомному надзору',
          'Не допускается ни в каком случае',
          'Допускается в любом случае',
          'Только при соответствующем обосновании в проектной документации',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63714113',
        text:
          'Какое количество буровых скважин закладывают при опускных колодцах диаметром более 10-15 м?',
        answers: [
          'Не менее 4 буровых скважин',
          'Не менее 2 буровых скважин',
          'Не менее 3 буровых скважин',
          '1 буровую скважину',
        ],
        correctAnswers: ['Не менее 3 буровых скважин'],
      },
      {
        code: '63714114',
        text:
          'В каком из перечисленных случаев может производиться спуск рабочих в силосы и бункеры?',
        answers: [
          'Если спуск производится для проверки температуры хранящегося сырья',
          'Если спуск производится для отбора проб сырья',
          'В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)',
          'Если спуск производится для проведения анализа газовой среды',
        ],
        correctAnswers: [
          'В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)',
        ],
      },
      {
        code: '63714115',
        text:
          'Какую температуру теплоносителя следует принимать для систем отопления и теплоснабжения воздухонагревателей приточных установок по условиям обеспечения пожарной безопасности помещений категорий А и Б?',
        answers: [
          'Не более 110 °С',
          'Не более 130 °С',
          'Не более 150 °С',
          'Не более 145 °С',
          'Не более 90 °С',
        ],
        correctAnswers: ['Не более 110 °С'],
      },
      {
        code: '63714116',
        text:
          'Что из перечисленного не допускается в шахтах для прокладки кабелей?',
        answers: [
          'Установка норий',
          'Проход самотечных труб',
          'Проход аспирационных воздуходувов',
          'Все перечисленное не допускается',
        ],
        correctAnswers: ['Все перечисленное не допускается'],
      },
      {
        code: '63714117',
        text:
          'Какие тоннели и каналы должны быть заглублены от поверхности до верха перекрытия не менее чем на 1 м?',
        answers: [
          'Тоннели и каналы, располагаемые вне зданий и дорог',
          'Тоннели и каналы, располагаемые под автомобильными дорогами',
          'Тоннели и каналы, располагаемые внутри цехов',
          'Тоннели и каналы, располагаемые под железными дорогами',
        ],
        correctAnswers: [
          'Тоннели и каналы, располагаемые под железными дорогами',
        ],
      },
      {
        code: '63714118',
        text:
          'Где должны устанавливаться взрыворазрядители на подогревателях для взрывозащиты зерносушилок?',
        answers: [
          'На боковой стенке секций подогревателя со стороны входа агента сушки',
          'На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки',
          'На боковой стенке секций только в нижней части подогревателя',
          'На минимальном расстоянии от входа агента сушки',
        ],
        correctAnswers: [
          'На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки',
        ],
      },
      {
        code: '63714119',
        text:
          'Что допускается при расчете амплитуд колебаний фундаментов вертикальных машин? Выберите все правильные варианты ответов.',
        answers: [
          'Расчет амплитуд горизонтальных колебаний ограничить только для направления, параллельного главному валу машины',
          'Расчет амплитуд вертикальных колебаний производить только с учетом влияния вертикальной составляющей возмущающих сил',
          'Расчет амплитуд горизонтальных колебаний ограничить только для направления, перпендикулярного главному валу машины',
          'Расчет амплитуд вертикальных колебаний производить только с учетом влияния горизонтальной составляющей возмущающих сил',
        ],
        correctAnswers: [
          'Расчет амплитуд вертикальных колебаний производить только с учетом влияния вертикальной составляющей возмущающих сил',
          'Расчет амплитуд горизонтальных колебаний ограничить только для направления, перпендикулярного главному валу машины',
        ],
      },
      {
        code: '63714120',
        text:
          'Каким документом определяются лица, ответственные за выполнение плана мероприятий по доведению объекта до нормативных требований промышленной безопасности, своевременное внесение соответствующих дополнений (изменений)?',
        answers: [
          'Внутренним распорядительным документом эксплуатирующей объект организации',
          'Техническим регламентом',
          'Техническим паспортом',
        ],
        correctAnswers: [
          'Внутренним распорядительным документом эксплуатирующей объект организации',
        ],
      },
      {
        code: '63714121',
        text:
          'Каким образом следует распределять взрыворазрядители на камерах нагрева при установке нескольких взрыворазрядителей?',
        answers: [
          'Равномерно по длине камеры',
          'Равномерно по ширине камеры',
          'Равномерно по высоте камеры',
          'В соответствии с указаниями лица, ответственного за безопасное производство работ',
        ],
        correctAnswers: ['Равномерно по высоте камеры'],
      },
      {
        code: '63714122',
        text:
          'Какое должно быть минимальное расстояние по горизонтали (в свету) от водопроводов и напорной канализации до фундаментов зданий и сооружений?',
        answers: ['7 м', '5 м', '4 м', '3 м', '2 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63714123',
        text:
          'С учетом соблюдения каких требований следует, по возможности, размещать здания и сооружения производственных объектов исходя из специфики производства и природных условий?',
        answers: [
          'В районах массового переноса песка ветрами наиболее низкие здания необходимо располагать с наветренной стороны площадки перпендикулярно потоку переносимого песка, а также предусматривать полосы зеленых насаждений (шириной не менее 5 м)',
          'Продольные оси аэрационных фонарей и стены зданий с проемами, используемыми для аэрации помещений, следует ориентировать в плане параллельно или под углом не менее 30° к преобладающему направлению ветров летнего периода года',
          'Продольные оси здания и световых фонарей следует ориентировать в пределах от 10 до 45° к меридиану',
          'В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия',
        ],
        correctAnswers: [
          'В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия',
        ],
      },
      {
        code: '63714124',
        text:
          'Какие из перечисленных сведений отражаются в технологическом регламенте, разрабатываемом на объекте хранения и переработки растительного сырья? Выберите все правильные варианты ответа.',
        answers: [
          'Нормы расхода основных видов сырья',
          'Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции',
          'Сведения о возможных инцидентах в работе и способах их ликвидации',
          'Описание технологического процесса производства',
          'Движение сырья, готовой продукции в графическом виде',
        ],
        correctAnswers: [
          'Нормы расхода основных видов сырья',
          'Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции',
          'Описание технологического процесса производства',
        ],
      },
      {
        code: '63714125',
        text:
          'Что из перечисленного не допускается на объектах хранения и переработки растительного сырья?',
        answers: [
          'Перепускные окна между бункерами и силосами, предназначенными для хранения муки',
          'Внесение изменений в технологический регламент и схемы размещения оборудования, средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях',
          'Вывод взрыворазрядителей в производственное помещение',
          'Не предусматривать приточно-вытяжную или вытяжную вентиляцию для приямков, предназначенных для обслуживания норий, цепных конвейеров, расположенных в помещениях категории Б',
        ],
        correctAnswers: [
          'Перепускные окна между бункерами и силосами, предназначенными для хранения муки',
        ],
      },
      {
        code: '63714126',
        text:
          'Какими следует проектировать участки перекрытий производственных зданий с большим числом технологических отверстий?',
        answers: [
          'Только сборными',
          'Только сборно-монолитными',
          'Только монолитными',
          'Сборно-монолитными или монолитными',
        ],
        correctAnswers: ['Сборно-монолитными или монолитными'],
      },
      {
        code: '63714127',
        text:
          'Под каким углом должны предусматриваться пересечения трубопроводов с железнодорожными путями и автодорогами?',
        answers: [
          'Под углом 90°',
          'Под углом 65°',
          'Под углом 45°',
          'Под углом 50°',
        ],
        correctAnswers: ['Под углом 90°'],
      },
      {
        code: '63714128',
        text: 'Какие нагрузки не учитываются при расчете силосов?',
        answers: [
          'Возникающие при изготовлении, перевозке и монтаже сборных конструкций',
          'Возникающие при изменении температур наружного воздуха',
          'Возникающие от давления при взрыве',
          'Возникающие от столкновений транспортных средств с частями сооружения',
        ],
        correctAnswers: [
          'Возникающие от столкновений транспортных средств с частями сооружения',
        ],
      },
      {
        code: '63714129',
        text:
          'В каком случае допускается использовать пространство под баками водонапорных башен для размещения служебных и конторских помещений, складов, производственных помещений?',
        answers: [
          'В случае вместимости бака от 15 до 50 м³',
          'Не допускается ни в каком случае',
          'В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений',
          'В случае соответствующего технико-экономического обоснования',
        ],
        correctAnswers: [
          'В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений',
        ],
      },
      {
        code: '63714130',
        text: 'Какое требование к проектированию фундаментов указано неверно?',
        answers: [
          'Фундаменты машин допускается проектировать отдельными под каждую машину (агрегат) или общими под несколько машин (агрегатов)',
          'При соответствующем обосновании рекомендуется предусматривать виброзащиту фундаментов',
          'Фундаменты машин должны быть отделены сквозным швом от смежных фундаментов здания, сооружения и оборудования, а также от пола',
          'При соответствующем обосновании допускается использование в конструкциях фундаментов машин бывших в употреблении металлоконструкций',
        ],
        correctAnswers: [
          'При соответствующем обосновании допускается использование в конструкциях фундаментов машин бывших в употреблении металлоконструкций',
        ],
      },
      {
        code: '63714131',
        text:
          'Каким должно быть расстояние между трубопроводами и силовыми кабелями напряжением до 35 кВ и кабелями связи?',
        answers: ['0,9 м', '0,5 м', '0,6 м', '0,3 м', '1,1 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63714132',
        text:
          'Какой документ должен оформляться на каждый установленный взрыворазрядитель?',
        answers: [
          'Паспорт',
          'Формуляр',
          'Операционная карта',
          'Технологическая инструкция',
          'Заключение',
          'Акт',
        ],
        correctAnswers: ['Паспорт'],
      },
      {
        code: '63714133',
        text:
          'Какие из перечисленных данных служат исходными для проектирования фундаментов машин с динамическими нагрузками?',
        answers: [
          'Только техническая характеристика машины (наименование, тип, число оборотов в минуту, мощность, общая масса и масса движущихся частей, кинематическая схема оборудования с привязкой движущихся масс, скорость ударяющих частей и т. п.)',
          'Только данные о привязке проектируемого фундамента к конструкциям здания (сооружения), в частности, к его фундаментам, данные об особенностях здания (сооружения), в том числе о виде и расположении имеющихся в нем оборудования и коммуникаций',
          'Только чертежи габаритов фундамента в пределах расположения машины, элементов ее крепления, а также вспомогательного оборудования и коммуникаций с указанием расположения и размеров выемок, каналов и отверстий, размеров подливки и пр.',
          'Все перечисленные, включая специальные требования к защите фундамента и его приямков от подземных вод, воздействия агрессивных сред, промышленных стоков, температурных воздействий и другие, вытекающие из специфики каждого вида машин',
        ],
        correctAnswers: [
          'Все перечисленные, включая специальные требования к защите фундамента и его приямков от подземных вод, воздействия агрессивных сред, промышленных стоков, температурных воздействий и другие, вытекающие из специфики каждого вида машин',
        ],
      },
      {
        code: '63714134',
        text:
          'Чем должна быть обоснована протяженность горизонтальных участков воздуховодов?',
        answers: [
          'Проектными решениями',
          'Внутренними распорядительными документами',
          'Инструкциями',
          'Техническим регламентом',
        ],
        correctAnswers: ['Проектными решениями'],
      },
      {
        code: '63714135',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63714136',
        text:
          'Что из перечисленного не допускается размещать в производственных зданиях?',
        answers: [
          'Комнаты для собраний и для приема пищи',
          'Административно-бытовые помещения, расположенные в пристройках в торце производственных зданий',
          'Диспетчерскую',
          'Помещения для обогрева рабочих, вальцерезную мастерскую, а также подсобные помещения без постоянного пребывания людей',
        ],
        correctAnswers: ['Комнаты для собраний и для приема пищи'],
      },
      {
        code: '63714137',
        text:
          'Норийные трубы каких норий должны быть рассчитаны на внутреннее остаточное давление пылевоздушного взрыва?',
        answers: [
          'Норий транспортировки минерального сырья',
          'Норий, установленных в рабочих зданиях элеваторов',
          'Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт',
          'Норий высотой более 20 м вне зависимости от места установки',
          'Норий с трубами круглого сечения и толщиной стенки более 2 мм',
        ],
        correctAnswers: [
          'Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт',
        ],
      },
      {
        code: '63714138',
        text:
          'Каким образом следует назначать длину разгрузочной железнодорожной эстакады?',
        answers: [
          'В соответствии с требованиями инструкций по эксплуатации',
          'В зависимости от материала конструкций эстакады',
          'Равной 1,8, 3, 6, 9 м',
          'В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады',
          'В зависимости от высоты эстакады',
        ],
        correctAnswers: [
          'В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады',
        ],
      },
      {
        code: '63714139',
        text:
          'На сколько отметка пола подвальных помещений должна быть выше уровня грунтовых вод?',
        answers: [
          'Не менее чем на 0,5 м',
          'Не менее чем на 0,4 м',
          'Не менее чем на 0,3 м',
          'Не менее чем на 0,05 м',
        ],
        correctAnswers: ['Не менее чем на 0,5 м'],
      },
      {
        code: '63714140',
        text:
          'Что из перечисленного не соответствует Правилам безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'На нориях с высотой норийных труб не более 36 м взрыворазрядители следует устанавливать на головке нории или на обеих норийных трубах в любом месте по высоте нории, удобном для монтажа и обслуживания взрыворазрядителей',
          'На нориях с высотой норийных труб более 36 м взрыворазрядители следует устанавливать на головке нории (или на норийных трубах на расстоянии не более 1/3 высоты норийных труб от головки нории) и на обеих норийных трубах на расстоянии не более 1/3 высоты норийных труб от башмака нории в любом месте, удобном для монтажа и обслуживания взрыворазрядителей',
          'Установка взрыворазрядителя на крышке головки нории не должна приводить к обратной сыпи и попаданию продукта на предохранительную мембрану',
          'Взрыворазрядители на норийных трубах устанавливаются на фланцах входных патрубков таким образом, чтобы норийная лента не препятствовала выбросу продуктов взрывного горения и несгоревшей смеси',
          'На головке сдвоенной нории не допускается устанавливать общий для обеих частей нории взрыворазрядитель',
          'При установке взрыворазрядителей на норийных трубах объединение двух норийных труб одной нории допускается в месте расположения общего взрыворазрядителя',
        ],
        correctAnswers: [
          'На головке сдвоенной нории не допускается устанавливать общий для обеих частей нории взрыворазрядитель',
        ],
      },
      {
        code: '63714141',
        text:
          'Чего не должны допускать форма и расположение переходных патрубков для присоединения взрыворазрядителей при нормальном режиме работы оборудования?',
        answers: [
          'Только накопления в них пыли',
          'Только накопления в них продукта',
          'Только механического повреждения предохранительной мембраны из-за попадания на нее продукта',
          'Всего перечисленного',
        ],
        correctAnswers: ['Всего перечисленного'],
      },
      {
        code: '63714142',
        text:
          'Какое требование по оснащению производственных помещений лифтами указано неверно?',
        answers: [
          'В производственных зданиях предусматривается пассажирский лифт для постоянно работающих людей на этажах, расположенных выше 15 м от уровня входа в здание',
          'Тамбур-шлюзы могут устраиваться общими для двух помещений (при условии, что в помещении категории Б имеется второй эвакуационный выход)',
          'Грузовой лифт в производственных зданиях следует предусматривать при наличии требований технологии производства',
          'В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента',
        ],
        correctAnswers: [
          'В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента',
        ],
      },
      {
        code: '63714143',
        text:
          'Какой надлежит принимать ширину ворот автомобильных въездов на земельный участок производственного объекта?',
        answers: [
          'Не менее 5,0 м',
          'Не менее 3,5 м',
          'По наибольшей ширине применяемых автомобилей',
        ],
        correctAnswers: ['Не менее 3,5 м'],
      },
      {
        code: '63714144',
        text:
          'Какой материал допускается использовать для опор (колонн) водонапорных башен?',
        answers: [
          'Монолитный железобетон',
          'Кирпичную кладку',
          'Сталь',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63714145',
        text:
          'В каком случае допускается выполнять бункер для хранения и перегрузки сыпучего материала без перекрытия, но с обязательным устройством сплошного ограждения?',
        answers: [
          'Не допускается ни в каком случае',
          'Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)',
          'Если производится загрузка несвязных сыпучих материалов',
          'Если производится загрузка связных сыпучих материалов',
        ],
        correctAnswers: [
          'Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)',
        ],
      },
      {
        code: '63714146',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          '1,5 года',
          '4 года',
          'Наибольший срок, предусмотренный для этих объектов',
          'Наименьший срок, предусмотренный для этих объектов',
          '2 года',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63714147',
        text:
          'Какие из перечисленных действий допускаются при эксплуатации объектов хранения и переработки растительного сырья?',
        answers: [
          'Хранение сырого зерна на складах силосного типа',
          'Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов',
          'Временное хранение зерна на открытых площадках при наличии специальных навесов',
          'Хранение влажного зерна в силосах элеватора',
        ],
        correctAnswers: [
          'Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов',
        ],
      },
      {
        code: '63714148',
        text:
          'Где не допускается прокладка трубопроводов с пожаро- и взрывоопасными веществами (смесями)?',
        answers: [
          'Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции',
          'Только через комплектные трансформаторные подстанции',
          'Только через распределительные устройства',
          'Только через трансформаторные подстанции',
        ],
        correctAnswers: [
          'Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции',
        ],
      },
      {
        code: '63714149',
        text:
          'Какую зону бункера необходимо защищать шлакоситаллом или другими материалами?',
        answers: [
          'I зону - участок, подвергающийся ударам потока сыпучего материала при загрузке бункера и истиранию при его разгрузке',
          'II зону - участок, подвергающийся истиранию сыпучим материалом в процессе разгрузки бункера',
          'III зону - участок не подвергающийся износу',
        ],
        correctAnswers: [
          'II зону - участок, подвергающийся истиранию сыпучим материалом в процессе разгрузки бункера',
        ],
      },
      {
        code: '63714150',
        text:
          'В каком положении нарушены требования Свода правил СП 43.13330.2012 при расчете конструкций силосов и их элементов?',
        answers: [
          'При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами',
          'Расчет оснований сблокированных и отдельно стоящих силосов, возводимых на нескальных грунтах, должен производиться по предельным состояниям второй группы (по деформациям) с учетом ветровой нагрузки',
          'По периметру наружных стен силосных корпусов высотой до верха карниза более 10 м следует предусматривать на кровле решетчатые ограждения высотой не менее 0,6 м из негорючих материалов',
          'При проектировании силосов для сыпучих материалов, пыль которых способна образовывать взрывоопасные концентрации, следует предусматривать мероприятия по взрывозащите и защите от статического электричества',
        ],
        correctAnswers: [
          'При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами',
        ],
      },
      {
        code: '63714151',
        text:
          'С каким покрытием следует проектировать автомобильные дороги на площадках мукомольно-крупяных и комбикормовых предприятий по санитарным условиям?',
        answers: [
          'Шлаковым',
          'Асфальтобетонным или бетонным',
          'Гравийным',
          'Щебеночным',
        ],
        correctAnswers: ['Асфальтобетонным или бетонным'],
      },
      {
        code: '63714152',
        text:
          'Какой крен допускается для фундаментных плит силосных корпусов с несколькими подсилосными этажами?',
        answers: [
          'Не более 0,002',
          'Не более 0,0025',
          'Не более 0,003',
          'Не более 0,004',
          'Не более 0,005',
        ],
        correctAnswers: ['Не более 0,002'],
      },
      {
        code: '63714153',
        text:
          'В каких районах не следует размещать промышленные объекты с источниками загрязнения атмосферного воздуха вредными веществами 1-го и 2-го классов опасности?',
        answers: [
          'В районах с повторяющимися туманами частотой 25% за год',
          'В районах с относительной среднегодовой влажностью воздуха более 70%',
          'В районах с повторяющимися штилями частотой 20% за год',
          'В районах с преобладающими ветрами со скоростью до 1 м/с',
        ],
        correctAnswers: [
          'В районах с преобладающими ветрами со скоростью до 1 м/с',
        ],
      },
      {
        code: '63714154',
        text:
          'Что следует предусматривать при проектировании искусственного освещения зданий и сооружений по хранению и переработке зерна? Выберите несколько правильных вариантов ответа.',
        answers: [
          'Светильники прожекторного типа без защиты от проникновения пыли при освещении бункеров и силосов',
          'Светильники с лампами накаливания для комбикормовых заводов и других зданий и помещений',
          'Применение энергосберегающих ламп и оборудования',
          'Светильники с люминесцентными и светодиодными лампами для производственных помещений мельниц, крупяных заводов и диспетчерских помещений',
        ],
        correctAnswers: [
          'Светильники с лампами накаливания для комбикормовых заводов и других зданий и помещений',
          'Применение энергосберегающих ламп и оборудования',
          'Светильники с люминесцентными и светодиодными лампами для производственных помещений мельниц, крупяных заводов и диспетчерских помещений',
        ],
      },
      {
        code: '63714155',
        text:
          'Какое из перечисленных требований к устройству закромов для хранения сыпучих материалов указано неверно?',
        answers: [
          'При загрузке и выгрузке материалов грейферными кранами следует предусматривать буферный слой из хранимого материала внутри закромов толщиной не менее 0,3 м',
          'Стены закромов должны быть рассчитаны также на горизонтальное давление грунта с учетом временной нормативной нагрузки на поверхности земли интенсивностью не менее 20 кПа (2 тс/м²) при опорожненном закроме',
          'Полы закромов надлежит выполнять из камня грубого окола или грунтовыми',
          'Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями',
        ],
        correctAnswers: [
          'Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями',
        ],
      },
      {
        code: '63714156',
        text:
          'Какие требования, установленные Правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья, при наличии в проходе между конвейерами строительных конструкций (колонны, пилястры), создающих местное сужение прохода, указаны неверно?',
        answers: [
          'Места прохода должны быть ограждены',
          'Места прохода должны быть ограждены  Расстояние между конвейерами и строительными конструкциями должно быть не менее 0,5 м',
          'Длина прохода должна быть не менее 3-х метров',
        ],
        correctAnswers: ['Длина прохода должна быть не менее 3-х метров'],
      },
      {
        code: '63714157',
        text:
          'Какие требования к проектированию ограждений, расположенных внутри производственных зданий площадок, антресолей, приямков, на которых размещено технологическое оборудование, указаны верно?',
        answers: [
          'Ограждения следует проектировать высотой 0,5 м',
          'Ограждения следует проектировать алюминиевыми',
          'Ограждения должны быть сплошными на высоту не менее 150 мм от пола',
          'Ограждения следует проектировать высотой 2,0 м',
        ],
        correctAnswers: [
          'Ограждения должны быть сплошными на высоту не менее 150 мм от пола',
        ],
      },
      {
        code: '63714158',
        text:
          'Какие требования к размещению надземных коммуникаций указаны верно?',
        answers: [
          'В случае если смешение продуктов может вызвать взрыв или пожар, трубопроводы с горючими жидкими и газообразными продуктами следует размещать в галереях',
          'Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами допускается размещать по эстакадам',
          'Трубопроводы с легковоспламеняющимися и горючими жидкостями и газами следует  размещать по покрытиям и стенам зданий категорий А и Б по взрывопожароопасности',
          'Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов',
        ],
        correctAnswers: [
          'Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов',
        ],
      },
      {
        code: '63714159',
        text:
          'Какой должна быть минимальная площадь оконного стекла толщиной 5 мм, используемого в качестве легкосбрасываемой конструкции?',
        answers: ['1,5 м²', '1,2 м²', '0,8 м²', '1,9 м²'],
        correctAnswers: ['1,5 м²'],
      },
      {
        code: '63714160',
        text:
          'Каким документом определяется порядок (очередность) проведения обследования объекта перед разработкой технического паспорта взрывобезопасности?',
        answers: [
          'Внутренним распорядительным документом эксплуатирующей объект организации',
          'Техническим регламентом',
          'Инструкцией по эксплуатации оборудования',
          'Документом по стандартизации',
        ],
        correctAnswers: [
          'Внутренним распорядительным документом эксплуатирующей объект организации',
        ],
      },
      {
        code: '63714161',
        text:
          'Какие требования к размещению инженерных коммуникаций указаны неверно?',
        answers: [
          'Не допускается размещение сетей инженерно-технического обеспечения с легковоспламеняющимися и горючими жидкостями и газами под производственными зданиями и инженерными сооружениями',
          'Допускается совместное подземное размещение трубопроводов оборотного водоснабжения, тепловых сетей и газопроводов с технологическими трубопроводами, независимо от параметров теплоносителя и параметров среды в технологических трубопроводах',
          'Во входных зонах объектов, в т. ч. размещаемых в индустриальных парках и промышленных кластерах, а также вдоль автомобильных дорог транспортной инфраструктуры, следует предусматривать преимущественно подземное размещение сетей инженерно-технического обеспечения',
          'На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций',
        ],
        correctAnswers: [
          'На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций',
        ],
      },
      {
        code: '63714162',
        text:
          'Что необходимо предпринять при отклонениях от нормальной работы оборудования (завал продукта, интенсивное пыление, повышенные вибрации и другие подобные причины)?',
        answers: [
          'Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций',
          'Остановить оборудование и незамедлительно покинуть помещение',
          'Продолжить работу, сообщив ответственному специалисту об отклонениях',
          'Оповестить представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий об отклонениях и продолжить работу',
        ],
        correctAnswers: [
          'Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций',
        ],
      },
      {
        code: '63714163',
        text:
          'Каким образом должен располагаться низ опорной плиты стальных опор открытых сооружений при проектировании сооружений промышленных предприятий?',
        answers: [
          'Выше планировочной отметки земли, как правило, не менее чем на 150 мм',
          'Ниже планировочной отметки земли на 50 мм',
          'Выше планировочной отметки земли, как правило, не менее чем на 100 мм',
          'Выше планировочной отметки земли на 70 мм',
        ],
        correctAnswers: [
          'Выше планировочной отметки земли, как правило, не менее чем на 150 мм',
        ],
      },
      {
        code: '63714164',
        text:
          'В помещениях какой категории запрещается складирование мешков с сырьем или готовой продукцией, пустых мешков или других горючих материалов, если это не связано с необходимостью ведения технологического процесса?',
        answers: [
          'В помещениях категории А',
          'В помещении категории Б',
          'В помещении категории В',
          'В помещении категории Г',
        ],
        correctAnswers: ['В помещении категории Б'],
      },
      {
        code: '63714165',
        text:
          'Какое минимальное количество выездов должно иметь на объектах с земельным участком более 5 га?',
        answers: ['Два', 'Четыре', 'Один', 'Три'],
        correctAnswers: ['Два'],
      },
      {
        code: '63714166',
        text:
          'В каких случаях могут применяться Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'При разработке обоснования безопасности объектов',
          'При изготовлении, монтаже, наладке, обслуживании и ремонте технических устройств, применяемых на объектах',
          'При проведении экспертизы промышленной безопасности',
          'При разработке технологических процессов, разработке документации, эксплуатации, капитальном ремонте, техническом перевооружении, консервации и ликвидации объектов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63714167',
        text:
          'Покрытия каких силосов допускается проектировать в виде оболочек? Выберите два правильных варианта ответа.',
        answers: [
          'Группы силосов, объединенных в силосный корпус',
          'Силосов диаметром более 12 м',
          'Отдельно стоящих круглых силосов при отсутствии надсилосного помещения',
          'Отдельно стоящих круглых силосов, надсилосные помещения которых спроектированы с применением облегченных стеновых ограждений',
        ],
        correctAnswers: [
          'Силосов диаметром более 12 м',
          'Отдельно стоящих круглых силосов при отсутствии надсилосного помещения',
        ],
      },
      {
        code: '63714168',
        text:
          'В каких местах допускается размещение отдельно стоящих зданий или сооружений?',
        answers: [
          'В замкнутых дворах',
          'Все ответы неверны',
          'В полузамкнутых дворах, при этом расстояние от этих сооружений до зданий должно удовлетворять требованиям, предъявляемым к устройству замкнутых дворов',
          'В замкнутых и полузамкнутых дворах',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63714169',
        text:
          'Где должны устанавливаться взрыворазрядители на надсушильных бункерах для взрывозащиты зерносушилок?',
        answers: [
          'На верхней крышке или на боковой стенке в верхней части бункера',
          'Только на верхней крышке бункера',
          'На боковой стенке в нижней части бункера',
          'На боковой стенке со стороны, противоположной входу агента сушки',
        ],
        correctAnswers: [
          'На верхней крышке или на боковой стенке в верхней части бункера',
        ],
      },
      {
        code: '63714170',
        text:
          'Что из перечисленного должно быть предусмотрено в конструкции сдвоенной нории?',
        answers: [
          'Усиление норийных труб, выполненное по методикам, согласованным с территориальным органом Федеральной службы по экологическому, технологическому и атомному надзору',
          'Единое устройство натяжения обеих лент',
          'Система локализации взрывов, исключающая распространение высокотемпературных продуктов взрывного горения из одной трубы в другую',
          'Раздельное натяжение каждой ленты',
          'Индивидуальный привод каждой ленты',
        ],
        correctAnswers: ['Раздельное натяжение каждой ленты'],
      },
      {
        code: '63714171',
        text:
          'Что из перечисленного допускается прокладывать через бытовые, подсобные и административно-хозяйственные помещения, помещения пультов управления, электрораспределительных устройств и вентиляционных камер, через лестничные клетки и тамбур-шлюзы?',
        answers: [
          'Воздуховоды воздушного отопления',
          'Материалопроводы',
          'Нории и конвейеры',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63714172',
        text:
          'Что должна предусматривать схема транспорта, разрабатываемая в составе проекта, планировочной организации земельного участка объекта, группы объектов?',
        answers: [
          'Возможность последующего развития схемы внешнего транспорта',
          'Использование сооружений и устройств, проектируемых для других целей (дамб водохранилищ и плотин, водопропускных сооружений), под земляное полотно и искусственные сооружения железных и автомобильных дорог',
          'Максимальное совмещение транспортных сооружений и устройств для различных видов транспорта (совмещенные автомобильные и железнодорожные или автомобильные и трамвайные мосты и путепроводы, общее земляное полотно для автомобильных дорог и трамвайных путей, кроме скоростных, и др.)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63714173',
        text:
          'Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации при техническом перевооружении и реконструкции объектов?',
        answers: [
          'Категории А',
          'Категории Б',
          'В подвальных и цокольных этажах допускается размещение помещений всех перечисленных категорий',
          'Категории Г',
        ],
        correctAnswers: ['Категории Г'],
      },
      {
        code: '63714174',
        text:
          'Какое расстояние между температурно-усадочными швами следует принимать в монолитных и сборно-монолитных железобетонных конструкциях стен?',
        answers: [
          'Не более 3,5 м',
          'Не более 15 м',
          'Не более 20 м',
          'Не более 25 м',
        ],
        correctAnswers: ['Не более 25 м'],
      },
      {
        code: '63714175',
        text:
          'При какой минимальной нагрузке на пол не следует размещать подвалы производственного назначения в зданиях и сооружениях?',
        answers: [
          '100 кПа (10 тс/м²)',
          '200 кПа (20 тс/м²)',
          '150 кПа (15 тс/м²)',
          '50 кПа (5 тс/м²)',
        ],
        correctAnswers: ['100 кПа (10 тс/м²)'],
      },
      {
        code: '63714176',
        text:
          'Кем определяется рациональный выбор средств ДАУ, блокировки, контроля и противоаварийной защиты?',
        answers: [
          'Разработчиками этих средств',
          'Руководителем эксплуатирующей организации',
          'Главным инженером',
        ],
        correctAnswers: ['Разработчиками этих средств'],
      },
      {
        code: '63714177',
        text:
          'Какой шириной должны быть разрывы между шкивами вальцовых станков, расположенных в группе при трансмиссионном приводе или при приводе от индивидуальных электродвигателей, расположенных на нижележащем этаже?',
        answers: [
          'Не менее 0,20 м',
          'Не менее 0,25 м',
          'Не менее 0,35 м',
          'Не менее 0,15 м',
        ],
        correctAnswers: ['Не менее 0,35 м'],
      },
      {
        code: '63714178',
        text:
          'Какое углубление решеток люков силосов, бункеров и других устройств от поверхности пола помещения должно быть обосновано?',
        answers: ['Более 60 мм', 'Более 80 мм', 'Более 90 мм', 'Более 70 мм'],
        correctAnswers: ['Более 60 мм'],
      },
      {
        code: '63714179',
        text:
          'Какую марку бетона следует принимать при проектировании сборных железобетонных элементов стен силосов?',
        answers: [
          'Не ниже В12,5',
          'Не ниже В15',
          'Не ниже В20',
          'Не ниже В25',
          'Не ниже В40',
        ],
        correctAnswers: ['Не ниже В25'],
      },
      {
        code: '63714180',
        text: 'Какие требования к хранению и сушке кукурузы указаны неверно?',
        answers: [
          'Кукурузу в зерне следует сушить только в шахтных прямоточных отдельно стоящих сушилках',
          'При хранении зерна кукурузы должно предусматриваться минимальное число ее перемещений',
          'Уровень влажности при хранении зерна кукурузы до года не должен превышать 15%',
          'Уровень влажности при длительном хранении (более года) для кукурузы не должен превышать 12%',
        ],
        correctAnswers: [
          'Уровень влажности при хранении зерна кукурузы до года не должен превышать 15%',
        ],
      },
      {
        code: '63714181',
        text:
          'Какой класс бетона по прочности на сжатие должен быть предусмотрен для сборных фундаментов?',
        answers: [
          'Не ниже В12,5',
          'Не ниже В15',
          'Не ниже В7,5',
          'Не ниже В10',
        ],
        correctAnswers: ['Не ниже В15'],
      },
      {
        code: '63714182',
        text:
          'Что допускается размещать при проектировании многорядных силосных корпусов с круглыми в плане силосами в пространстве между ними (звездочках)?',
        answers: [
          'Установки для хранения связных сыпучих материалов',
          'Установки технологического оборудования, требующего обслуживания',
          'Лестницы',
        ],
        correctAnswers: ['Лестницы'],
      },
      {
        code: '63714183',
        text:
          'Что запрещено при проектировании путей эвакуации зданий и сооружений по хранению и переработке зерна?',
        answers: [
          'Предусматривать не менее двух выходов из каждого производственного помещения',
          'Предусматривать в качестве второго эвакуационного выхода с третьего этажа зданий с помещениями категории В или Б и количеством работающих на каждом этаже более 15 человек наружную открытую лестницу',
          'Предусматривать один эвакуационный выход по незадымляемой лестничной клетке или наружной открытой лестнице 3-го типа в зданиях и сооружениях, где на этаже выше первого нет постоянных рабочих мест',
        ],
        correctAnswers: [
          'Предусматривать в качестве второго эвакуационного выхода с третьего этажа зданий с помещениями категории В или Б и количеством работающих на каждом этаже более 15 человек наружную открытую лестницу',
        ],
      },
      {
        code: '63714184',
        text:
          'Что запрещено при устройстве лестниц производственных зданий и сооружений, проектируемых в сейсмических районах?',
        answers: [
          'Выполнять лестничные клетки в виде отдельно стоящих сооружений',
          'Заделывать в стены на глубину не менее 250 мм и заанкеривать междуэтажные лестничные площадки в каменных зданиях',
          'Выполнять лестницы с применением металлических или железобетонных косоуров с наборными ступенями',
          'Выполнять лестницы из монолитного железобетона',
        ],
        correctAnswers: [
          'Выполнять лестничные клетки в виде отдельно стоящих сооружений',
        ],
      },
      {
        code: '63714185',
        text:
          'Какой класс бетона (по прочности на сжатие) допускается применять для монолитных фундаментов?',
        answers: [
          'Не ниже В12,5',
          'Не ниже В10',
          'Не ниже В7,5',
          'Не ниже В40',
        ],
        correctAnswers: ['Не ниже В12,5'],
      },
      {
        code: '63714186',
        text:
          'Какое должно быть минимальное расстояние по горизонтали (в свету) от газопроводов среднего давления (св. 0,005 до 0,3 МПа) до фундаментов зданий и сооружений?',
        answers: ['7 м', '4 м', '2 м', '3 м', '10 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63714187',
        text:
          'Какой фундамент следует предусматривать, если расчетные деформации естественного основания силосов и силосных корпусов превышают предельные или не обеспечивается его устойчивость, а также при наличии просадочных грунтов и в других случаях при соответствующем технико-экономическом обосновании?',
        answers: [
          'Сборный фундамент',
          'Ленточный фундамент',
          'Кольцевой фундамент',
          'Свайный фундамент',
        ],
        correctAnswers: ['Свайный фундамент'],
      },
      {
        code: '63714188',
        text: 'Чем определяются режимы вентилирования зерна?',
        answers: [
          'Технологическим регламентом',
          'Инструкцией',
          'Паспортом оборудования',
          'Эксплуатационной документацией',
        ],
        correctAnswers: ['Технологическим регламентом'],
      },
      {
        code: '63714189',
        text:
          'При каком диаметре силосы следует проектировать отдельно стоящими?',
        answers: ['Более 7 м', '10 м', 'Более 12 м', '5 м', '8 м'],
        correctAnswers: ['Более 12 м'],
      },
      {
        code: '63714190',
        text:
          'Какие трубопроводы допускается размещать в открытых траншеях и лотках?',
        answers: [
          'Трубопроводы для горючих газов',
          'Все ответы неверны',
          'Трубопроводы, по которым транспортируются кислоты и щелочи',
          'Трубопроводы бытовой канализации',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63714191',
        text:
          'Какое максимальное расстояние должно быть от проходных пунктов до входов в санитарно-бытовые помещения основных цехов?',
        answers: ['800 м', '550 м', '1300 м', '700 м'],
        correctAnswers: ['800 м'],
      },
      {
        code: '63714192',
        text:
          'На каких конвейерах должны быть установлены устройства, предохраняющие конвейеры от переполнения короба продуктом?',
        answers: [
          'Только на винтовых конвейерах',
          'На закрытых ленточных конвейерах',
          'Только на цепных конвейерах',
          'На цепных и винтовых конвейерах',
          'На цепных, винтовых и ленточных конвейерах',
        ],
        correctAnswers: ['На цепных и винтовых конвейерах'],
      },
      {
        code: '63714193',
        text:
          'На основании чего и с учетом каких данных должны проектироваться фундаменты машин с динамическими нагрузками?',
        answers: [
          'Только на основании результатов инженерных изысканий для строительства, включая экологические и санитарно-эпидемиологические требования',
          'Только с учетом нагрузок, действующих на фундаменты машин, окружающей застройки и влияния на нее вновь строящихся и реконструируемых фундаментов машин',
          'На основании всех перечисленных данных, включая данные, характеризующие назначение, конструктивные и технологические особенности машин с динамическими нагрузками, а также условия их эксплуатации',
        ],
        correctAnswers: [
          'На основании всех перечисленных данных, включая данные, характеризующие назначение, конструктивные и технологические особенности машин с динамическими нагрузками, а также условия их эксплуатации',
        ],
      },
      {
        code: '63714194',
        text:
          'На каком расстоянии от стен и другого оборудования должны устанавливаться мешковыбивальные машины?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 0,9 м',
          'Не менее 0,8 м',
          'Не менее 0,7 м',
        ],
        correctAnswers: ['Не менее 0,9 м'],
      },
      {
        code: '63714195',
        text:
          'Кратными какому значению рекомендуется принимать высоту и ширину тоннелей, каналов (между выступающими частями несущих конструкций)?',
        answers: ['100 мм', '200 мм', '400 мм', '300 мм'],
        correctAnswers: ['300 мм'],
      },
      {
        code: '63714196',
        text:
          'Какие требования к проектированию эстакад под железную дорогу колеи 1520 мм не соответствуют Своду правил?',
        answers: [
          'Эстакады высотой до 3 м следует, как правило, проектировать из железобетонных конструкций или бетонных блоков, располагаемых с обеих сторон железнодорожного пути и связанных между собой, с заполнением пространства между ними утрамбованным дренирующим материалом',
          'При обращении и разгрузке на эстакаде вагонов-самосвалов дополнительно следует производить расчет на нагрузку от вагонов-самосвалов в момент разгрузки, принимая нормативное значение вертикального давления на упорный рельс 70%',
          'Нормативную временную вертикальную нагрузку следует принимать по СП 35.13330, но не менее СК = 14. Нормативную горизонтальную поперечную нагрузку от ударов подвижного состава следует определять в зависимости от расчетной скорости движения по эстакаде',
        ],
        correctAnswers: [
          'При обращении и разгрузке на эстакаде вагонов-самосвалов дополнительно следует производить расчет на нагрузку от вагонов-самосвалов в момент разгрузки, принимая нормативное значение вертикального давления на упорный рельс 70%',
        ],
      },
      {
        code: '63714197',
        text:
          'Бункеры какой формы не допускается проектировать для связных материалов гидравлического истечения?',
        answers: ['Конической', 'Пирамидальной', 'Лотковой', 'Параболической'],
        correctAnswers: ['Параболической'],
      },
      {
        code: '63714198',
        text: 'Какие существуют формы истечения сыпучего материала из бункера?',
        answers: [
          'В зависимости от типа движения сыпучего материала во всем объеме бункера (гидравлическая и негидравлическая)',
          'В зависимости от формы бункера и его воронки (низкопроизводительная и высокопроизводительная)',
          'В зависимости от физико-механических характеристик сыпучего материала (быстрого и медленного истечения)',
        ],
        correctAnswers: [
          'В зависимости от типа движения сыпучего материала во всем объеме бункера (гидравлическая и негидравлическая)',
        ],
      },
      {
        code: '63714199',
        text:
          'Какая должна быть высота помещений от пола до низа выступающих конструкций перекрытия (покрытия)?',
        answers: [
          'Не менее 2,0 м',
          'Не менее 2,2 м',
          'Не менее 1,5 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63714200',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только руководителями (заместителями руководителей) организаций, эксплуатирующих объекты',
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Руководителями структурных подразделений объекта',
          'Специалистами поднадзорных организаций совместно со специалистами надзорных органов',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63714201',
        text:
          'Что из перечисленного не относится к организационным и организационно-техническим мероприятиям, которые должны выполняться для обеспечения взрывобезопасности объекта?',
        answers: [
          'Разработка нормативно-технических документов',
          'Применение средств оповещения об аварийных ситуациях',
          'Производственный контроль за соблюдением требований безопасности',
          'Устройство путей эвакуации и вынос бытовых помещений из производственных зданий',
        ],
        correctAnswers: [
          'Устройство путей эвакуации и вынос бытовых помещений из производственных зданий',
        ],
      },
      {
        code: '63714202',
        text: 'Что не допускается при расчете колебаний фундаментов машин?',
        answers: [
          'При действии на фундамент машины одновременно нескольких возмущающих сил и отсутствии данных об их фазовом соотношении рассматривать варианты синфазного и противофазного действия сил, вызывающие наиболее неблагоприятные формы колебаний',
          'Полностью пренебрегать эксцентриситетом в распределении масс фундамента',
          'Рассматривать основание как упруго-вязкое линейно деформируемое, свойства которого определяются коэффициентами упругого равномерного и неравномерного сжатия, упругого равномерного и неравномерного сдвига и коэффициентами, характеризующими демпфирование',
          'При упругом неравномерном сжатии (повороте подошвы фундамента относительно горизонтальной оси, проходящей через центр тяжести подошвы фундамента перпендикулярно плоскости колебаний) принимать, что плоскость колебаний параллельна линии действия возмущающей силы или плоскости действия возмущающего момента',
        ],
        correctAnswers: [
          'Полностью пренебрегать эксцентриситетом в распределении масс фундамента',
        ],
      },
      {
        code: '63714203',
        text:
          'На кого возлагается ответственность за техническое состояние, эксплуатацию и своевременный ремонт взрыворазрядителей?',
        answers: [
          'На должностное лицо, назначенное руководителем эксплуатирующей организации',
          'На технического руководителя эксплуатирующей организации',
          'На должностное лицо организации-изготовителя',
          'На представителя территориального управления Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'На должностное лицо специализированной подрядной организации',
        ],
        correctAnswers: [
          'На должностное лицо, назначенное руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63714204',
        text: 'На какие нагрузки должна быть рассчитана конструкция бункера?',
        answers: [
          'Только на действие постоянной нагрузки от собственного веса конструкций, веса футеровки, а также на действие постоянных и временных нагрузок надбункерного перекрытия',
          'Только на действие постоянной нагрузки от собственного веса конструкций, временной нагрузки от веса сыпучего материала, заполняющего бункер, и нагрузки надбункерного перекрытия',
          'На действие временной нагрузки от веса сыпучего материала, заполняющего бункер, постоянных нагрузок от собственного веса конструкций, веса футеровки, а также на действие постоянных и временных нагрузок надбункерного перекрытия',
        ],
        correctAnswers: [
          'На действие временной нагрузки от веса сыпучего материала, заполняющего бункер, постоянных нагрузок от собственного веса конструкций, веса футеровки, а также на действие постоянных и временных нагрузок надбункерного перекрытия',
        ],
      },
      {
        code: '63714205',
        text:
          'Что из перечисленного не учитывается при анализе риска аварий на объектах?',
        answers: [
          'Источники опасности и факторы риска',
          'Условия возникновения аварий и их сценарии',
          'Численность и размещение производственного персонала',
          'Расчетное время прибытия специализированных профессиональных служб (формирований)',
          'Учитывается все перечисленное',
        ],
        correctAnswers: ['Учитывается все перечисленное'],
      },
      {
        code: '63714206',
        text:
          'Какие характеристики определяются по расчетным схемам, учитывающим наличие наружных стен и днища опускных колодцев, при строительстве опускных колодцев? Выберите два правильных варианта ответа.',
        answers: [
          'Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)',
          'Устойчивость формы цилиндрической оболочки колодцев, погружаемых в тиксотропной рубашке',
          'Всплытие колодца',
          'Погружение колодца',
        ],
        correctAnswers: [
          'Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)',
          'Всплытие колодца',
        ],
      },
      {
        code: '63714207',
        text:
          'При каком условии должен производиться доступ рабочих в силосы и бункеры через нижний люк для производства огневых работ?',
        answers: [
          'Только при наличии разрешения территориального органа Федеральной службы по экологическому, технологическому и атомному надзору и в присутствии наблюдающего',
          'Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением',
          'Доступ рабочих в силосы и бункеры через нижний люк запрещается',
          'Только в присутствии главного инженера эксплуатирующей организации и представителя территориального органа Федеральной службы по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением',
        ],
      },
      {
        code: '63714208',
        text:
          'Какие требования предъявляются к выходам из конвейерных, коммуникационных (кроме кабельных) тоннелей?',
        answers: [
          'Выходы должны предусматриваться не реже чем через 30 м, но не менее двух',
          'Выходы должны предусматриваться не реже чем через 60 м, но не менее двух',
          'Выходы должны предусматриваться не реже чем через 100 м, но не менее двух',
          'Выходы должны предусматриваться не реже чем через 150 м',
        ],
        correctAnswers: [
          'Выходы должны предусматриваться не реже чем через 100 м, но не менее двух',
        ],
      },
      {
        code: '63714209',
        text:
          'Какие функционально-технологические зоны следует выделять при разработке планировочной организации земельных участков производственных объектов?',
        answers: [
          'Входную',
          'Все перечисленные зоны',
          'Складскую',
          'Производственную, включая зоны исследовательского назначения и опытных производств',
          'Подсобную',
        ],
        correctAnswers: ['Все перечисленные зоны'],
      },
      {
        code: '63714210',
        text:
          'Допускается ли эксплуатация неисправных и утративших целостность взрыворазрядителей и их конструктивных элементов?',
        answers: [
          'Допускается только в светлое время суток',
          'Не допускается',
          'Допускается только в темное время суток',
          'Допускается при согласовании с территориальным органом Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63714211',
        text:
          'Без устройства чего в зданиях категории Б не допускается предусматривать соединяющие их с другими зданиями и помещениями тоннели и подземные галереи? Выберите 2 варианта ответа.',
        answers: [
          'Без устройства огнепреграждающих клапанов',
          'Без устройства тамбур-шлюзов',
          'Без устройства воздушной зоны',
          'Без устройства вентиляционных камер',
        ],
        correctAnswers: [
          'Без устройства огнепреграждающих клапанов',
          'Без устройства тамбур-шлюзов',
        ],
      },
      {
        code: '63714212',
        text:
          'В каких из перечисленных конструкциях расстояние между температурно-усадочными швами следует принимать не более 10 м?',
        answers: [
          'В монолитных бетонных конструкциях при наличии конструктивного армирования',
          'В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования',
          'В монолитных и сборно-монолитных железобетонных конструкциях стен',
          'В сборных железобетонных конструкциях',
        ],
        correctAnswers: [
          'В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования',
        ],
      },
      {
        code: '63714213',
        text:
          'Каким должно быть минимальное расстояние от верха насыпи зерна до низа несущих конструкций покрытия?',
        answers: ['1,2 м', '1,0 м', '0,8 м', '0,5 м', '0,05 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63714214',
        text:
          'В каких местах допускается размещение промышленных объектов и их групп?',
        answers: [
          'В зеленых зонах городов',
          'На землях особо охраняемых природных территорий, в т. ч. заповедников и их охранных зон',
          'В зонах возможного затопления на глубину от 0,5 до 1,2 м',
          'В первом поясе зоны санитарной охраны подземных и наземных источников водоснабжения',
        ],
        correctAnswers: [
          'В зонах возможного затопления на глубину от 0,5 до 1,2 м',
        ],
      },
      {
        code: '63714215',
        text:
          'В каких случаях допускается применять здания, образующие полузамкнутые дворы?',
        answers: [
          'В случае расположения зданий перпендикулярно или под углом не менее 30° к преобладающему направлению ветров летнего периода года',
          'Во всех перечисленных случаях',
          'В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции',
          'В случае отсутствия вредных производственных выделений',
        ],
        correctAnswers: [
          'В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции',
        ],
      },
      {
        code: '63714216',
        text:
          'Под каким углом должны предусматриваться пересечения кабельных эстакад с воздушными линиями электропередачи?',
        answers: [
          'В зависимости от конструкции эстакад, но не более 45°',
          'Пересечение надземных коммуникаций не допускается',
          'Под углом не менее 30°',
          'Под углом не менее 20°',
        ],
        correctAnswers: ['Под углом не менее 30°'],
      },
      {
        code: '63714217',
        text:
          'Для каких водонапорных башен высота опор от уровня земли до верха опоры бака устанавливается кратной 3 м?',
        answers: [
          'Для башен с баками вместимостью от 50 до 150 м³',
          'Для башен с баками вместимостью 150 м³ и более',
          'Для башен с баками вместимостью от 15 до 50 м³',
          'Для башен с баками вместимостью от 100 до 150 м³',
        ],
        correctAnswers: ['Для башен с баками вместимостью от 15 до 50 м³'],
      },
      {
        code: '63714218',
        text:
          'На каких из перечисленных территорий не допускается размещать производственные объекты?',
        answers: [
          'Только в первом поясе зоны санитарной охраны подземных и наземных источников водоснабжения',
          'Только в зеленых зонах городов',
          'Только на землях особо охраняемых природных территорий',
          'На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов',
        ],
        correctAnswers: [
          'На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов',
        ],
      },
      {
        code: '63714219',
        text:
          'Что из перечисленного устанавливают на производственном оборудовании с целью его защиты от разрушения и обеспечения выброса (отвода) пламени и высокотемпературных продуктов взрывного горения пылевоздушной смеси в безопасную зону (за пределы помещений)?',
        answers: [
          'Взрыворазрядители',
          'Систему локализации взрывов',
          'Систему автоматического пожаротушения',
          'Систему подавления взрывов',
          'Газоанализаторы',
        ],
        correctAnswers: ['Взрыворазрядители'],
      },
      {
        code: '63714220',
        text:
          'Через какие промежутки следует предусматривать установку кнопок "Стоп" вдоль подсилосных и надсилосных, нижних и верхних конвейеров, складов?',
        answers: [
          'Через каждые 10 м',
          'Через каждые 5 м',
          'Через каждые 15 м',
          'Через каждые 13 м',
        ],
        correctAnswers: ['Через каждые 10 м'],
      },
      {
        code: '63714221',
        text:
          'Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору',
          'Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Руководителями муниципальных образований, на территории которых расположен объект',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63714222',
        text:
          'На основании чего в организации разрабатывается технологический регламент?',
        answers: [
          'На основании внутреннего акта эксплуатирующей организации',
          'На основании инструкции по эксплуатации оборудования',
          'На основании проектной документации',
          'На основании паспорта оборудования',
        ],
        correctAnswers: ['На основании проектной документации'],
      },
      {
        code: '63714223',
        text:
          'В каком случае требования по проектированию разгрузочных железнодорожных эстакад указаны неверно?',
        answers: [
          'При проектировании разгрузочных железнодорожных эстакад должны предусматриваться помещения для защиты работающих от неблагоприятных метеорологических воздействий',
          'На железобетонных конструкциях сооружений должны предусматриваться гидроизоляция и сливы, обеспечивающие свободный сток воды',
          'Настил обслуживающих площадок разгрузочных железнодорожных эстакад следует проектировать с таким расчетом, чтобы исключалось скольжение при ходьбе',
          'Марка бетона устанавливается проектом и не зависит от значения расчетных зимних температур наружного воздуха в районе строительства',
        ],
        correctAnswers: [
          'Марка бетона устанавливается проектом и не зависит от значения расчетных зимних температур наружного воздуха в районе строительства',
        ],
      },
      {
        code: '63714224',
        text:
          'Какие требования к проектированию складских зданий зерноскладов указаны неверно?',
        answers: [
          'Площадь зданий зерноскладов между противопожарными стенами следует принимать по нормативным документам по пожарной безопасности, но не более 5000 м²',
          'Полы в складских зданиях следует проектировать, как правило, асфальтобетонными с толщиной покрытия 25 мм в зерноскладах и 50 мм - в складах тарных грузов. В покрытиях полов не допускается применение дегтей и дегтевых мастик',
          'Проекты зерноскладов должны содержать указания о нанесении на стены ярких линий и надписей, ограничивающих предельную высоту зерновой насыпи',
          'Вынос кровли (за наружную поверхность стен) для зерноскладов должен быть не менее 0,7 м',
          'Противокапиллярную гидроизоляцию несущих стен зданий зерноскладов следует предусматривать из цементного раствора состава 1 : 2 толщиной 20 мм',
        ],
        correctAnswers: [
          'Площадь зданий зерноскладов между противопожарными стенами следует принимать по нормативным документам по пожарной безопасности, но не более 5000 м²',
        ],
      },
      {
        code: '63714225',
        text:
          'Какие из перечисленных мероприятий необходимо предусматривать при разработке планировочной организации земельных участков производственных объектов?',
        answers: [
          'Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве',
          'Только рациональные производственные, транспортные и инженерные связи на объектах и между ними',
          'Только защиту прилегающих территорий от эрозии, заболачивания, засоления и загрязнения подземных вод и открытых водоемов сточными водами, отходами и отбросами предприятий',
          'Только функционально-технологическое зонирование земельного участка с учетом технологических связей, санитарно-гигиенических и противопожарных требований, грузооборота и видов транспорта',
        ],
        correctAnswers: [
          'Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве',
        ],
      },
      {
        code: '63714226',
        text:
          'К определению какого показателя сводится расчет колебаний фундаментов дробилок?',
        answers: [
          'Наибольшей амплитуды горизонтальных колебаний верхней грани фундамента',
          'Динамических нагрузок',
          'Среднеквадратического значения амплитуды колебаний',
        ],
        correctAnswers: [
          'Наибольшей амплитуды горизонтальных колебаний верхней грани фундамента',
        ],
      },
      {
        code: '63714227',
        text:
          'Что запрещается при проектировании складов по хранению и переработке зерна?',
        answers: [
          'Располагать у торца зданий тарных складов на первом этаже зарядную станцию для аккумуляторных погрузчиков',
          'Принимать сетку колонн 6 х 6 м и высоту этажей 4,8 м для многоэтажных складов тарных грузов',
          'Применять перекрытия складов тарных грузов сборно-монолитными с устройством монолитного железобетонного слоя поверх сборных железобетонных плит',
          'Проектировать здания половохранилищ двухэтажными и располагать в них вспомогательные помещения',
        ],
        correctAnswers: [
          'Проектировать здания половохранилищ двухэтажными и располагать в них вспомогательные помещения',
        ],
      },
      {
        code: '63714228',
        text:
          'Под каким углом должны предусматриваться пересечения кабельных эстакад с внутризаводскими железными и автомобильными дорогами?',
        answers: [
          'Под углом не менее 75° к оси пути',
          'При соответствующем обосновании - под углом 45°',
          'Под углом не менее 30°',
          'Под углом не менее 25°',
        ],
        correctAnswers: ['Под углом не менее 30°'],
      },
      {
        code: '63714229',
        text: 'В каком порядке следует производить расчет опускного колодца?',
        answers: [
          'Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки',
          'Рассчитывать отдельные конструктивные элементы оболочки, определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки',
          'Порядок не регламентируется',
          'Рассчитывать отдельные конструктивные элементы оболочки, глубину колодца, определять наружные размеры (диаметр) колодца',
        ],
        correctAnswers: [
          'Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки',
        ],
      },
      {
        code: '63714230',
        text:
          'В каком случае разрывные предохранительные мембраны из полиэтиленовой пленки или алюминиевой фольги подлежат обязательной замене с отражением действий в журнале периодических осмотров и ремонтов взрыворазрядителей?',
        answers: [
          'По истечении одного года эксплуатации',
          'По истечении трех лет эксплуатации',
          'По истечении пяти лет эксплуатации',
          'По истечении семи лет эксплуатации',
        ],
        correctAnswers: ['По истечении одного года эксплуатации'],
      },
      {
        code: '63714231',
        text:
          'В каком случае допускается спуск людей в силосы и бункеры без их письменного согласия?',
        answers: [
          'В случае плановой зачистки после опорожнения силосов',
          'В случае зачистки бункеров от остатков сырья предыдущего хранения',
          'Не допускается ни в каком случае',
          'В случае обслуживания (ремонта) внутренних поверхностей силосов и бункеров',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63714232',
        text:
          'В каком случае допускается временное размещение семян подсолнечника в силосах элеваторов и складах силосного типа, оборудованных устройствами автоматизированного дистанционного контроля температуры?',
        answers: [
          'Влажностью не более 12%.',
          'Влажностью не более 10%.',
          'Влажностью не более 8%.',
        ],
        correctAnswers: ['Влажностью не более 8%.'],
      },
      {
        code: '63714233',
        text:
          'В помещениях какой категории допускается применять отопительные приборы с гладкой поверхностью и размещать их на высоте, обеспечивающей возможность их очистки от пыли?',
        answers: [
          'В помещениях категории В',
          'Во всех перечисленных помещениях',
          'В помещениях категории А',
          'В помещениях категории Б',
        ],
        correctAnswers: ['Во всех перечисленных помещениях'],
      },
      {
        code: '63714234',
        text:
          'Для каких помещений системы приточной вентиляции совмещают с воздушным отоплением?',
        answers: [
          'Для любых производственных и вспомогательных помещений',
          'Для взрывопожароопасных производственных помещений с трехсменным режимом работы',
          'Для взрывопожароопасных производственных помещений с режимом работы в одну смену',
        ],
        correctAnswers: [
          'Для взрывопожароопасных производственных помещений с трехсменным режимом работы',
        ],
      },
      {
        code: '63714235',
        text:
          'Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Организацию взаимодействия сил и средств',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
          'Состав и дислокацию сил и средств',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте',
          'Мероприятия, направленные на обеспечение безопасности населения',
        ],
        correctAnswers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
        ],
      },
      {
        code: '63714236',
        text:
          'Какое допускается максимальное содержание арматуры железобетонных колонн подсилосного этажа?',
        answers: ['3%', '10%', '5%', '15%'],
        correctAnswers: ['3%'],
      },
      {
        code: '63714237',
        text:
          'Какие бункеры для хранения и перегрузки сыпучего материала допускается проектировать стальными?',
        answers: [
          'Параболические (висячие бункеры)',
          'Все перечисленные бункеры',
          'Бункеры, которые по технологическим условиям подвергаются механическим, химическим и температурным воздействиям сыпучего материала и не могут быть выполнены из железобетона',
        ],
        correctAnswers: ['Все перечисленные бункеры'],
      },
      {
        code: '63714238',
        text: 'В каких местах следует размещать надземные коммуникации?',
        answers: [
          'На эстакадах',
          'В галереях',
          'На стенах зданий и сооружений',
          'Во всех перечисленных',
          'На опорах',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63714239',
        text:
          'Какую минимальную площадь легкосбрасываемых конструкций следует принимать при отсутствии расчетных данных на 1 м³ взрывоопасного помещения?',
        answers: ['0,01 м²', '0,03 м²', '0,07 м²', '0,02 м²'],
        correctAnswers: ['0,03 м²'],
      },
      {
        code: '63714240',
        text:
          'Какие из перечисленных средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации не должны предусматриваться (определяться на стадиях разработки процесса) на объектах?',
        answers: [
          'Аварийная остановка всех электродвигателей цеха с любого этажа и пульта управления (диспетчерской)',
          'Местное управление электроприводом каждой единицы оборудования',
          'Дистанционный контроль за верхним и нижним уровнями сырья и продуктов в силосах и бункерах',
          'В тех случаях, когда в дробилках отсутствует устройство для автоматического регулирования загрузки, это устройство должно быть сблокировано с электродвигателем дробилки',
        ],
        correctAnswers: [
          'В тех случаях, когда в дробилках отсутствует устройство для автоматического регулирования загрузки, это устройство должно быть сблокировано с электродвигателем дробилки',
        ],
      },
      {
        code: '63714241',
        text:
          'Через какие перечисленные помещения допускается прокладка транзитных воздуховодов?',
        answers: [
          'Через помещения складов сырья',
          'Через помещения складов готовой продукции',
          'Через помещения разных категорий',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
    ],
    63713: [
      {
        code: '63713000',
        text:
          'В каком из перечисленных случаев может производиться спуск рабочих в силосы и бункеры?',
        answers: [
          'В случае проверки температуры хранящегося сырья (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)',
          'В случае проведения анализа газовой среды',
          'В случае отбора проб сырья',
          'В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)',
        ],
        correctAnswers: [
          'В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)',
        ],
      },
      {
        code: '63713001',
        text:
          'В каком случае допускается применение на объекте средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, связи, оповещения об аварийных ситуациях, отработавших назначенный срок службы?',
        answers: [
          'В случае особенностей технологического процесса',
          'В случае если они установлены разработчиком процесса',
          'Не допускается ни в каком случае',
          'В случае установления контроля, объем которого должен обеспечить надежную работу средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, связи, оповещения об аварийных ситуациях',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63713002',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63713003',
        text:
          'Чем должны быть оборудованы силосы, бункеры и склады, используемые в качестве накопительных емкостей при приемке и формировании партий свежеубранного зерна?',
        answers: [
          'Термоподвесками',
          'Переносными измерительными приборами, подключаемыми к термоподвескам',
          'Стационарными устройствами контроля температуры с ЭВМ',
          'Цифровыми устройствами контроля температуры с радиоканалом',
          'Средствами дистанционного контроля температуры',
        ],
        correctAnswers: ['Средствами дистанционного контроля температуры'],
      },
      {
        code: '63713004',
        text:
          'Как осуществляется флегматизация горючей газовоздушной смеси в свободных объемах силоса?',
        answers: [
          'Путем его заполнения инертными газами и снижения содержания кислорода до оптимального значения, равного 15% объема',
          'Путем распыления воздушно-механической пены, подаваемой в силос снизу',
          'Путем его заполнения инертными газами и снижения содержания кислорода до оптимального значения, равного 8% объема и менее, а также воздушно-механической пеной, подаваемой в силос сверху через загрузочный люк',
          'Путем распыления с помощью стволов с насадками воды через загрузочные люки',
          'Путем герметизации технологических люков силосов',
        ],
        correctAnswers: [
          'Путем его заполнения инертными газами и снижения содержания кислорода до оптимального значения, равного 8% объема и менее, а также воздушно-механической пеной, подаваемой в силос сверху через загрузочный люк',
        ],
      },
      {
        code: '63713005',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '6 месяцев', '1 год', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63713006',
        text:
          'Какой уровень влажности не должна превышать влажность пшеницы, ржи, ячменя, риса-зерна, гречихи при их хранении до года?',
        answers: ['17%', '14,5%', '15%', '16%', '16,5%'],
        correctAnswers: ['14,5%'],
      },
      {
        code: '63713007',
        text:
          'С учетом ограничений каких документов эксплуатирующей организацией в технологическом регламенте определяются сроки, в которые проверяется температура сырья? Выберите 2 варианта ответа.',
        answers: [
          'Технических регламентов',
          'Документов по стандартизации',
          'Технического паспорта взрывобезопасности опасного производственного объекта',
          'Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
        ],
        correctAnswers: [
          'Технических регламентов',
          'Документов по стандартизации',
        ],
      },
      {
        code: '63713008',
        text:
          'Какие требования предъявляются к настилам, устроенным в местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями на территории взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные на уровне головки рельсов',
          'В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные ниже уровня головки рельсов на 50 мм',
          'В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные выше уровня головки рельсов на 50 мм',
          'В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные ниже уровня головки рельсов на 20 мм',
        ],
        correctAnswers: [
          'В местах пересечения автомобильных дорог и пешеходных дорожек с железнодорожными путями должны быть устроены сплошные настилы, уложенные на уровне головки рельсов',
        ],
      },
      {
        code: '63713009',
        text:
          'В каком из перечисленных случаев не допускается отключение аспирационных установок?',
        answers: [
          'При работе технологического и транспортного оборудования',
          'При повышенной запыленности производственных помещений',
          'При проведении огневых работ',
          'При наличии в производственных помещениях обслуживающего персонала',
        ],
        correctAnswers: [
          'При работе технологического и транспортного оборудования',
        ],
      },
      {
        code: '63713010',
        text:
          'Для каких помещений системы приточной вентиляции совмещают с воздушным отоплением?',
        answers: [
          'Для взрывопожароопасных производственных помещений с трехсменным режимом работы',
          'Для взрывопожароопасных производственных помещений с режимом работы в одну смену',
          'Для любых производственных и вспомогательных помещений',
        ],
        correctAnswers: [
          'Для взрывопожароопасных производственных помещений с трехсменным режимом работы',
        ],
      },
      {
        code: '63713011',
        text:
          'Какая устанавливается максимальная длина каждого отводящего трубопровода от защищаемого оборудования до коллектора?',
        answers: ['3 м', '5 м', '7 м', '10 м'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63713012',
        text:
          'В каком случае не должна срабатывать автоматика безопасности горения, установленная на топках зерносушилок на жидком или газообразном топливе?',
        answers: [
          'При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива',
          'При выбросе горячего топлива в предтопочное пространство',
          'При протекании топлива в топку при потухшем факеле',
        ],
        correctAnswers: [
          'При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива',
        ],
      },
      {
        code: '63713013',
        text:
          'На каком минимальном расстоянии от сушилки допускается хранение топлива и смазочных материалов?',
        answers: ['20 м', '15 м', '10 м', '5 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63713014',
        text:
          'Что из перечисленного не указывается в графиках уборки пыли в производственных помещениях взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'Периодичность текущих и генеральных уборок',
          'Объемы уборки',
          'Ф. И. О. и должность работника, выполняющего уборку',
        ],
        correctAnswers: ['Ф. И. О. и должность работника, выполняющего уборку'],
      },
      {
        code: '63713015',
        text:
          'В каком случае необходимо пользоваться специальными скребками и щетками при обслуживании машин?',
        answers: [
          'Во всех перечисленных случаях',
          'Только при прочистке зазора между заслонкой и питающим валком вальцового станка',
          'Только при очистке на холостом ходу верхних плоскостей решет сепараторов',
          'Только при расчистке завалов в башмаках норий и конвейерах',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63713016',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          '5 лет',
          'Наименьший срок, предусмотренный для этих объектов',
          '3 года',
          '1,5 года',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63713017',
        text:
          'Кем составляется перечень контролируемых параметров, определяющих взрывоопасность процесса в каждом конкретном случае?',
        answers: [
          'Организацией, осуществляющей экспертизу промышленной безопасности',
          'Разработчиком процесса',
          'Комиссией эксплуатирующей организации',
          'Техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63713018',
        text:
          'Какую систему отопления необходимо проектировать для ликвидации вакуума и снижения запыленности воздуха в рабочих помещениях?',
        answers: [
          'Систему воздушного отопления',
          'Систему парового отопления',
          'Систему водяного отопления',
          'Систему панельного отопления',
        ],
        correctAnswers: ['Систему воздушного отопления'],
      },
      {
        code: '63713019',
        text: 'В каком случае допускается проводить огневые работы?',
        answers: [
          'После открытия всех смотровых и базовых проемов и люков в помещениях, где проводятся огневые работы',
          'Только после проветривания и остановки всего оборудования с вывешиванием предупредительных надписей и плакатов в помещении, где проводятся огневые работы',
          'Только после отключения пусковой аппаратуры, машин и механизмов с вывешиванием предупредительных надписей и плакатов в помещении, в котором будут проводиться огневые работы',
          'После остановки всего оборудования объекта, отключения и обесточивания пусковой аппаратуры, машин и механизмов с вывешиванием предупредительных надписей и плакатов, предупреждающих возможность их пуска',
        ],
        correctAnswers: [
          'После остановки всего оборудования объекта, отключения и обесточивания пусковой аппаратуры, машин и механизмов с вывешиванием предупредительных надписей и плакатов, предупреждающих возможность их пуска',
        ],
      },
      {
        code: '63713020',
        text:
          'Какой должна быть минимальная площадь оконного стекла толщиной 5 мм, используемого в качестве легкосбрасываемой конструкции?',
        answers: ['1,5 м²', '1,0 м²', '0,8 м²', '1,2 м²'],
        correctAnswers: ['1,5 м²'],
      },
      {
        code: '63713021',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только руководителями (заместителями руководителей) организаций, эксплуатирующих объекты',
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Руководителями структурных подразделений объекта',
          'Специалистами поднадзорных организаций совместно со специалистами надзорных органов',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63713022',
        text:
          'На площадках с каким уклоном допускается проведение погрузочно-разгрузочных работ регулярного перемещения передвижных транспортных механизмов (конвейеры, самоподаватели, электропогрузчики)?',
        answers: [
          'Не более 7°',
          'Не более 4°',
          'Не более 3°',
          'Не более 6°',
          'Не более 5°',
        ],
        correctAnswers: ['Не более 3°'],
      },
      {
        code: '63713023',
        text: 'Каким способом следует заземлять электросварочные установки?',
        answers: [
          'Электросварочные установки следует надежно заземлять гибкими медными проводами, снабженными зажимами, обеспечивающими надежный контакт',
          'Электросварочные установки следует надежно заземлять гибкими алюминиевыми проводами сечением не менее 25 мм',
          'Электросварочные установки следует надежно заземлять           жесткими медными проводниками сечением не менее 35 мм',
          'Электросварочные установки следует надежно заземлять жесткими алюминиевыми проводниками с клеммными соединениями',
        ],
        correctAnswers: [
          'Электросварочные установки следует надежно заземлять гибкими медными проводами, снабженными зажимами, обеспечивающими надежный контакт',
        ],
      },
      {
        code: '63713024',
        text:
          'Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Организацию взаимодействия сил и средств',
          'Организацию управления, связи и оповещения при аварии на объекте, первоочередные действия при получении сигнала об аварии на объекте',
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
          'Состав и дислокацию сил и средств',
          'Мероприятия, направленные на обеспечение безопасности населения,  сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Мероприятия, направленные на обеспечение безопасности населения, возможные сценарии возникновения и развития аварий на объекте',
        ],
        correctAnswers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
        ],
      },
      {
        code: '63713025',
        text:
          'Какое количество буровых скважин закладывают при опускных колодцах диаметром более 10 - 15 м?',
        answers: [
          'Не менее двух буровых скважин',
          'Не менее трех буровых скважин',
          'Не менее четырех буровых скважин',
          'Одну буровую скважину',
        ],
        correctAnswers: ['Не менее трех буровых скважин'],
      },
      {
        code: '63713026',
        text:
          'Какой должен быть минимальный зооветеринарный разрыв между предприятиями и ветеринарно-санитарными утилизационными заводами для городских поселений и других муниципальных образований?',
        answers: ['1000 м', '150 м', '600 м', '300 м'],
        correctAnswers: ['1000 м'],
      },
      {
        code: '63713027',
        text:
          'Какие требования предъявляются к настилу на всем протяжении железнодорожных путей в случае применения на путях ручной подкатки вагонов?',
        answers: [
          'Настил должен обеспечивать безопасность передвижения по всей ширине шпал, быть выше уровня головки рельсов',
          'Настил должен быть ниже уровня головки рельсов',
          'Настил должен быть на уровне головки рельсов',
          'Настил должен обеспечивать безопасность передвижения по всей ширине колеи, быть ниже уровня головки рельсов на 50 мм',
        ],
        correctAnswers: [
          'Настил должен обеспечивать безопасность передвижения по всей ширине шпал, быть выше уровня головки рельсов',
        ],
      },
      {
        code: '63713028',
        text:
          'Что следует предусматривать при проектировании искусственного освещения зданий и сооружений по хранению и переработке зерна? Выберите несколько правильных вариантов ответа.',
        answers: [
          'Светильники с лампами накаливания для комбикормовых заводов и других зданий и помещений',
          'Применение энергосберегающих ламп и оборудования',
          'Светильники с люминесцентными и светодиодными лампами для производственных помещений мельниц, крупяных заводов и диспетчерских помещений',
          'Светильники прожекторного типа без защиты от проникновения пыли при освещении бункеров и силосов',
        ],
        correctAnswers: [
          'Светильники с лампами накаливания для комбикормовых заводов и других зданий и помещений',
          'Применение энергосберегающих ламп и оборудования',
          'Светильники с люминесцентными и светодиодными лампами для производственных помещений мельниц, крупяных заводов и диспетчерских помещений',
        ],
      },
      {
        code: '63713029',
        text:
          'Что определяется внутренними распорядительными документами организации, эксплуатирующей взрывопожароопасные производственные объекты хранения и переработки растительного сырья?',
        answers: [
          'Только организация работ по поддержанию надежного и безопасного уровня эксплуатации и ремонта оборудования, средств дистанционного автоматизированного управления, блокировки',
          'Только перечень и объем эксплуатационной, ремонтной и другой технической документации',
          'Только организация работ по поддержанию надежного и безопасного уровня контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях, средств связи, энергообеспечения, а также зданий и сооружений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63713030',
        text:
          'Кратными какому значению рекомендуется принимать высоту и ширину тоннелей, каналов (между выступающими частями несущих конструкций)?',
        answers: ['100 мм', '300 мм', '200 мм', '150 мм'],
        correctAnswers: ['300 мм'],
      },
      {
        code: '63713031',
        text:
          'Кто проводит проверку знания действий персонала в предаварийных и аварийных ситуациях?',
        answers: [
          'Пожарно-спасательная комиссия МЧС',
          'Учебно-экзаменационная комиссия обучающей организации',
          'Квалификационная (экзаменационная) комиссия эксплуатирующей организации',
          'Аттестационная комиссия Ростехнадзора',
        ],
        correctAnswers: [
          'Квалификационная (экзаменационная) комиссия эксплуатирующей организации',
        ],
      },
      {
        code: '63713032',
        text:
          'Когда должна отключаться приточно-вытяжная вентиляция аккумуляторных помещений после окончания заряда зарядных устройств?',
        answers: [
          'Не ранее чем через 1,5 часа',
          'Не ранее чем через 1 час',
          'Не ранее чем через 2 часа',
          'Не ранее чем через 2,5 часа',
        ],
        correctAnswers: ['Не ранее чем через 1,5 часа'],
      },
      {
        code: '63713033',
        text:
          'Какие требования безопасности при работе вальцедекового станка указаны неверно?',
        answers: [
          'Необходимо следить за уравновешенным ходом барабана вальцедекового станка. При возникновении стуков или неравномерного хода станок должен быть немедленно остановлен',
          'На выходе продуктов из зоны шелушения вальцедекового станка следует устанавливать отражатель, препятствующий разбрасыванию продукта',
          'Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности',
          'Абразивные части вальцедекового станка (барабан и дека) должны быть прочными, не иметь выбоин и трещин и должны быть скреплены с чугунными основаниями. Запрещается допускать к эксплуатации вальцы и деки, имеющие трещины',
        ],
        correctAnswers: [
          'Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности',
        ],
      },
      {
        code: '63713034',
        text:
          'В каких целях используют наружные силосы сборных корпусов элеваторов?',
        answers: [
          'В целях длительного хранения обработанного зерна',
          'В целях размещения свежеубранных партий после их обработки',
          'В целях размещения свежеубранных партий до их обработки',
          'В целях размещения партий, не предназначенных для первоочередной реализации',
          'В целях длительного хранения необработанного зерна',
        ],
        correctAnswers: [
          'В целях размещения свежеубранных партий до их обработки',
        ],
      },
      {
        code: '63713035',
        text:
          'Чем должны быть снабжены части станков, машин, аппаратов, а также механизмы, требующие смазки?',
        answers: [
          'Приспособлениями, исключающими возможность заклинивания',
          'Ремнедержателями',
          'Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования',
          'Ворошителями или вибраторами',
        ],
        correctAnswers: [
          'Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования',
        ],
      },
      {
        code: '63713036',
        text:
          'Кто может разрешить пуск вновь установленного оборудования или оборудования после ремонта?',
        answers: [
          'Главный механик эксплуатирующей организации',
          'Руководитель, технический руководитель или главный инженер эксплуатирующей организации',
          'Главный энергетик эксплуатирующей организации',
          'Начальник службы производственного контроля эксплуатирующей организации',
          'Руководитель территориального управления Федеральной службы по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Руководитель, технический руководитель или главный инженер эксплуатирующей организации',
        ],
      },
      {
        code: '63713037',
        text:
          'Где применяют устройства дистанционного контроля температуры зерна?',
        answers: [
          'Только в силосах элеваторов',
          'Только в силосах складов силосного типа',
          'Только в металлических силосах',
          'Только в складах напольного типа',
          'В силосах, бункерах и складах, используемых в качестве накопительных емкостей при приемке и формировании партий свежеубранного зерна',
        ],
        correctAnswers: [
          'В силосах, бункерах и складах, используемых в качестве накопительных емкостей при приемке и формировании партий свежеубранного зерна',
        ],
      },
      {
        code: '63713038',
        text:
          'При нагревании до какой максимальной температуры деталей, соприкасающихся с продукцией, допускается работа электромагнитных сепараторов?',
        answers: ['До 50 °C', 'До 60 °C', 'До 70 °C', 'До 90 °C'],
        correctAnswers: ['До 50 °C'],
      },
      {
        code: '63713039',
        text:
          'На кого возлагается ответственность за техническое состояние, эксплуатацию и своевременный ремонт взрыворазрядителей?',
        answers: [
          'На технического руководителя организации',
          'На главного технолога организации',
          'На инженера по охране труда организации',
          'На соответствующее должностное лицо, назначенное руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'На соответствующее должностное лицо, назначенное руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63713040',
        text:
          'Кто должен присутствовать при спуске рабочих в силосы и бункеры?',
        answers: [
          'Главный инженер',
          'Представитель территориального управления Ростехнадзора',
          'Руководитель отдела охраны труда и промышленного контроля',
          'Ответственный руководитель работ',
        ],
        correctAnswers: ['Ответственный руководитель работ'],
      },
      {
        code: '63713041',
        text:
          'Что из перечисленного включает в себя подготовка помещений и рабочего места к проведению огневых работ?',
        answers: [
          'Покрытие ветошью пола и сгораемых конструкций в радиусе не менее 15 м от места проведения работ',
          'Покрытие металлическими листами пола и сгораемых конструкций по всему помещению, в котором будут проводиться огневые работы',
          'Покрытие мокрыми мешками пола и сгораемых конструкций в радиусе не менее 10 м от места проведения работ',
          'Покрытие фанерой пола и сгораемых конструкций в радиусе не менее 20 м от места проведения работ',
        ],
        correctAnswers: [
          'Покрытие мокрыми мешками пола и сгораемых конструкций в радиусе не менее 10 м от места проведения работ',
        ],
      },
      {
        code: '63713042',
        text:
          'Какое действие необходимо произвести в первую очередь при появлении стуков или других признаков неисправности в сепараторе?',
        answers: [
          'Немедленно остановить',
          'Вызвать ремонтную бригаду',
          'Оповестить лицо, ответственное за безопасное производство работ',
          'Попытаться устранить неполадки',
        ],
        correctAnswers: ['Немедленно остановить'],
      },
      {
        code: '63713043',
        text:
          'Из какого материала следует выполнять подвалы производственного назначения при их устройстве в сложных гидрогеологических условиях строительной площадки, при больших нагрузках на пол цеха или при наличии разнообразных проемов в стенах и перекрытиях, а также при особых технологических требованиях?',
        answers: [
          'Из бетонных блоков',
          'Из монолитного железобетона',
          'Из кирпича',
          'Из несущих железобетонных панелей',
        ],
        correctAnswers: ['Из монолитного железобетона'],
      },
      {
        code: '63713044',
        text:
          'Какой материал допускается использовать для опор (колонн) водонапорных башен?',
        answers: [
          'Только монолитный железобетон',
          'Только кирпич',
          'Только сталь',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63713045',
        text:
          'Какое из перечисленных мероприятий, предусматриваемых проектными решениями в целях повышения эффективности работы аспирационных установок, указано неверно?',
        answers: [
          'Максимальная герметизация источников пылеобразования',
          'Применение пылеотделителей с высоким коэффициентом очистки',
          'Установка пылеотделителей после вентиляторов',
          'Применение вентиляторов с наибольшим коэффициентом полезного действия',
        ],
        correctAnswers: ['Установка пылеотделителей после вентиляторов'],
      },
      {
        code: '63713046',
        text:
          'Какие должны быть ограждения, расположенные внутри производственных зданий, площадок, антресолей, приямков, на которых размещено технологическое оборудование?',
        answers: [
          'Стальные решетчатые ограждения высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола',
          'Дюралюминиевые сплошные ограждения высотой 1,5 м',
          'Стальные решетчатые ограждения высотой 1,5 м, при этом ограждения должны быть сплошными на высоту не менее 0,25 м от пола',
          'Стальные сплошные ограждения высотой 1 м',
        ],
        correctAnswers: [
          'Стальные решетчатые ограждения высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола',
        ],
      },
      {
        code: '63713047',
        text:
          'В каком случае допускается использовать пространство под баками водонапорных башен для размещения служебных и конторских помещений, складов, производственных помещений?',
        answers: [
          'В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений',
          'В случае вместимости бака от 15 до 50 м³',
          'В случае соответствующего технико-экономического обоснования',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае применения сплошных конструкций опор (монолитный железобетон или кирпич), исключающих образование пыли, дыма и газовыделений',
        ],
      },
      {
        code: '63713048',
        text:
          'Какой должна быть температура наружных поверхностей горячих конструктивных частей зерносушилок, вентиляторов?',
        answers: [
          'Не должна превышать 70 °С',
          'Не должна превышать 55 °С',
          'Не должна превышать 50 °С',
          'Не должна превышать 45 °С',
        ],
        correctAnswers: ['Не должна превышать 45 °С'],
      },
      {
        code: '63713049',
        text: 'Каким способом рекомендуется осуществлять аспирацию силосов?',
        answers: [
          'Всеми перечисленными',
          'Только путем организации отсоса воздуха непосредственно от силоса или группы силосов',
          'Только путем организации отсоса воздуха от транспортирующих механизмов, загружающих силосы',
          'Только путем вывода воздуховодов диаметром 500 - 700 мм на 1 м выше конька кровли надсилосного этажа и надсилосных галерей',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63713050',
        text:
          'Какая принимается минимальная скорость воздуха в воздуховодах аспирационных установок в размольном отделении мукомольных и крупяных заводов при вертикальном и наклонном (более 60°) расположении воздуховодов?',
        answers: ['8 м/с', '10 м/с', '12 м/с', '16 м/с'],
        correctAnswers: ['10 м/с'],
      },
      {
        code: '63713051',
        text:
          'Какие требования к проектированию ограждений, расположенных внутри производственных зданий площадок, антресолей, приямков, на которых размещено технологическое оборудование, указаны верно?',
        answers: [
          'Ограждения следует проектировать алюминиевыми',
          'Ограждения должны быть сплошными на высоту не менее 150 мм от пола',
          'Все перечисленные указаны верно',
          'Ограждения следует проектировать высотой 0,5 м',
        ],
        correctAnswers: [
          'Ограждения должны быть сплошными на высоту не менее 150 мм от пола',
        ],
      },
      {
        code: '63713052',
        text:
          'Какими сетками должны быть закрыты открытые всасывающие отверстия при работе вентиляторов?',
        answers: [
          'С размером ячеек 30 x 30 мм',
          'С размером ячеек 20 x 20 мм',
          'С размером ячеек 25 x 25 мм',
          'С размером ячеек 15 x 15 мм',
        ],
        correctAnswers: ['С размером ячеек 20 x 20 мм'],
      },
      {
        code: '63713053',
        text:
          'С какой периодичностью пыль и другие относы должны выводиться из пылеуловителей?',
        answers: [
          '1 раз в смену',
          'Непрерывно',
          '1 раз в сутки',
          'Каждые 2 часа',
        ],
        correctAnswers: ['Непрерывно'],
      },
      {
        code: '63713054',
        text:
          'На каком из следующих устройств необходимо установить взрыворазрядители?',
        answers: [
          'На нориях со свободным объемом менее 0,25 м³',
          'На фильтр-циклонах со свободным объемом более 0,5 м³',
          'На дробилках с объемом рабочей зоны и выпускного (поддробильного) бункера менее 0,3 м³',
          'На нориях подачи и измельчения минерального сырья',
        ],
        correctAnswers: [
          'На фильтр-циклонах со свободным объемом более 0,5 м³',
        ],
      },
      {
        code: '63713055',
        text:
          'Какие бункеры для хранения и перегрузки сыпучего материала допускается проектировать стальными?',
        answers: [
          'Все перечисленные бункеры',
          'Только параболические (висячие бункеры)',
          'Только бункеры, которые по технологическим условиям подвергаются механическим, химическим и температурным воздействиям сыпучего материала и не могут быть выполнены из железобетона',
        ],
        correctAnswers: ['Все перечисленные бункеры'],
      },
      {
        code: '63713056',
        text:
          'Каким образом следует назначать длину разгрузочной железнодорожной эстакады?',
        answers: [
          'В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады',
          'В зависимости от материала конструкций и блоков эстакады',
          'Равной 1,8, 3, 6, 9 м',
          'В соответствии с требованиями внутренних инструкций',
        ],
        correctAnswers: [
          'В соответствии с технологическими расчетами и с учетом местных условий строительства эстакады',
        ],
      },
      {
        code: '63713057',
        text:
          'Допускается ли эксплуатация неисправных и утративших целостность взрыворазрядителей и их конструктивных элементов?',
        answers: [
          'Не допускается',
          'Допускается в светлое время суток',
          'Допускается только при соблюдении дополнительных требований',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63713058',
        text:
          'При каком условии допускается устройство порогов в дверных проемах распределительных устройств, трансформаторных подстанций и комплектных трансформаторных подстанций, диспетчерских и тамбурах при них?',
        answers: [
          'Устройство порогов в дверях распределительных устройств, трансформаторных подстанций и комплектных трансформаторных подстанций, диспетчерских и тамбурах при них не допускается',
          'При условии наличия несгораемого козырька с вылетом 70 см',
          'При условии обязательного согласования с территориальным управлением Федеральной службы по экологическому, технологическому и атомному надзору',
          'При условии перекрытия съемными деревянными щитами, подшитыми с наружной стороны листовым железом',
        ],
        correctAnswers: [
          'Устройство порогов в дверях распределительных устройств, трансформаторных подстанций и комплектных трансформаторных подстанций, диспетчерских и тамбурах при них не допускается',
        ],
      },
      {
        code: '63713059',
        text: 'Какие работы допускается производить на ходу падди-машин?',
        answers: [
          'Устранять неравномерность хода',
          'Регулировать корпуса падди-машин, подвешенных на качалках',
          'Производить смазку',
          'Передвигать параллели при сработке ползуна',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63713060',
        text:
          'Что из перечисленного должен сделать ответственный руководитель работ до начала спуска в силос?',
        answers: [
          'Только проинструктировать на рабочем месте всех участвующих в спуске рабочих',
          'Только лично проследить за соблюдением каждым рабочим рабочим всех мер безопасности при подготовке к спуску',
          'Только лично проверить состояние применяемых для спуска людей в силосы устройств, страховочной привязи и средств индивидуальной (коллективной) защиты, обеспечивающих безопасность работ на высоте',
          'Выполнить все перечисленные требования',
        ],
        correctAnswers: ['Выполнить все перечисленные требования'],
      },
      {
        code: '63713061',
        text:
          'Какой уровень влажности не должна превышать влажность пшеницы, ржи, ячменя, овса, гречихи при их длительном хранении (более года)?',
        answers: ['16%', '14,5%', '13,5%', '13%', '15%'],
        correctAnswers: ['13%'],
      },
      {
        code: '63713062',
        text:
          'Что из перечисленного устанавливают на производственном оборудовании с целью защиты его от разрушения и обеспечения выброса (отвода) пламени и высокотемпературных продуктов взрывного горения пылевоздушной смеси в безопасную зону (за пределы помещений)?',
        answers: [
          'Взрыворазрядители',
          'Огнепреграждающие устройства',
          'Систему локализации взрывов',
          'Систему автоматического пожаротушения',
        ],
        correctAnswers: ['Взрыворазрядители'],
      },
      {
        code: '63713063',
        text: 'Какое должно быть расстояние от пола до монорельса?',
        answers: [
          'Не менее 3,2 м',
          'Не менее 1,2 м',
          'Не менее 2,2 м',
          'Не менее 5,5 м',
        ],
        correctAnswers: ['Не менее 3,2 м'],
      },
      {
        code: '63713064',
        text:
          'На основании письменного решения какого лица осуществляется ввод в эксплуатацию аварийного участка производства (объекта) после проведения восстановительных работ?',
        answers: [
          'Руководителя аварийно-спасательных формирований',
          'Руководителя эксплуатирующей организации',
          'Инспектора территориального органа Ростехнадзора',
          'Технического руководителя эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителя эксплуатирующей организации'],
      },
      {
        code: '63713065',
        text:
          'Разрешается ли смазывать вяжущими веществами (смолой, канифолью) приводные барабаны стационарных ленточных конвейеров при ослаблении натяжения ленты?',
        answers: [
          'Запрещается',
          'Разрешается',
          'Разрешается только по согласованию с главным инженером',
          'Разрешается, если расстояние от нижней ленты конвейера до пола менее 150 мм',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63713066',
        text:
          'В каком из перечисленных случаев не допускается работа вальцовых станков?',
        answers: [
          'Без продукта с прижатыми вальцами, с перекосом и смещением их вдоль оси',
          'При перепаде температур входного и измельченного продукта менее 12 °С',
          'При перепаде температур входного и измельченного продукта менее 10 °С',
          'При отсутствии устройства контроля температуры подшипников валков',
        ],
        correctAnswers: [
          'Без продукта с прижатыми вальцами, с перекосом и смещением их вдоль оси',
        ],
      },
      {
        code: '63713067',
        text:
          'Какие требования к швейным машинам для ремонта тканевой тары указаны неверно?',
        answers: [
          'Швейные машины для ремонта тканевой тары устанавливаются на общем столе длиной не более 25 м, допускается не закреплять машины на столах при условии обеспечения их устойчивости',
          'У каждой машины должен быть местный отсос для удаления пыли и тканевого ворса',
          'Швейные машины должны быть обеспечены предохранительными приспособлениями, исключающими попадание рук под иглу',
          'Швейные машины должны иметь быстродействующие тормозные устройства',
          'У лопаты иглодержателя швейных машин должна быть прикреплена дугообразная пластина, а у ножки иглодержателя должно быть прикреплено лезвие для обрезания нитки',
        ],
        correctAnswers: [
          'Швейные машины для ремонта тканевой тары устанавливаются на общем столе длиной не более 25 м, допускается не закреплять машины на столах при условии обеспечения их устойчивости',
        ],
      },
      {
        code: '63713068',
        text:
          'Какое из перечисленных требований к взрывопожароопасным производственным объектам хранения и переработки растительного сырья указано неверно?',
        answers: [
          'Доступ на территорию организации, эксплуатирующей объекты, посторонним лицам запрещен',
          'Необходимые для производственных целей подземные резервуары, колодцы, пожарные водоемы должны быть закрыты или ограждены со всех сторон',
          'Устройство железнодорожных путей, переездов и переходов через них, а также организация и эксплуатация железнодорожного хозяйства организации должны соответствовать требованиям нормативных правовых актов в сфере транспорта',
          'В организации, эксплуатирующей объекты, должно быть обеспечено исправное состояние отводов атмосферных осадков от зданий и сооружений к водостокам, дорог для транспорта, пожарных проездов, рельсовых путей (с соблюдением требуемых габаритов, допускаемых уклонов и радиусов закруглений), сетей наружного освещения, пешеходных дорожек, пожарных и хозяйственных водопроводов, а также ограждений территории объектов и организации',
          'К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель',
        ],
        correctAnswers: [
          'К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель',
        ],
      },
      {
        code: '63713069',
        text:
          'Какое требование предъявляется к объему загрузки зерна в подогреватель зерна перед пуском в него пара?',
        answers: [
          'Подогреватель должен быть загружен зерном доверху',
          'Подогреватель должен быть загружен зерном наполовину',
          'Подогреватель должен быть загружен зерном на 1/4 всего объема',
          'Подогреватель должен быть загружен зерном на 3/4 всего объема',
        ],
        correctAnswers: ['Подогреватель должен быть загружен зерном доверху'],
      },
      {
        code: '63713070',
        text:
          'Какие кабели применяют для подключения к сети передвижных и переносных электроприемников?',
        answers: [
          'Гибкие шланговые кабели',
          'Гибкие шланговые кабели специального назначения',
          'Гибкие шланговые кабели с медными или алюминиевыми жилами и с полиэтиленовой изоляцией',
          'Гибкие шланговые кабели только с алюминиевыми жилами, полиэтиленовой изоляцией и резиновой оболочкой',
          'Силовые монтажные кабели с медной жилой и полиэтиленовой оболочкой',
        ],
        correctAnswers: ['Гибкие шланговые кабели'],
      },
      {
        code: '63713071',
        text:
          'В каком из перечисленных случаев не допускается работа фильтров с механическим встряхиванием рукавов?',
        answers: [
          'Только при неисправном встряхивающем механизме',
          'Только при работе встряхивающего механизма со сниженным числом ударов',
          'При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов',
        ],
        correctAnswers: [
          'При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов',
        ],
      },
      {
        code: '63713072',
        text: 'Где допускается покрывать полы линолеумом или паркетом?',
        answers: [
          'В галереях',
          'В тоннелях',
          'На лестничных клетках многоэтажных производственных зданий',
          'В помещениях диспетчерских щитов управления',
          'В помещениях рабочего здания элеватора',
        ],
        correctAnswers: ['В помещениях диспетчерских щитов управления'],
      },
      {
        code: '63713073',
        text:
          'Каким образом следует производить очистку шлюзового затвора от продукта, налипшего на стенки крыльчатки?',
        answers: [
          'Путем продувки его воздухом',
          'Путем пропарки',
          'Путем промывки',
          'Путем продувки инертным газом',
        ],
        correctAnswers: ['Путем продувки его воздухом'],
      },
      {
        code: '63713074',
        text:
          'Какой фундамент следует предусматривать, если расчетные деформации естественного основания силосов и силосных корпусов превышают предельные или не обеспечивается его устойчивость, а также при наличии просадочных грунтов и в других случаях при соответствующем технико-экономическом обосновании?',
        answers: [
          'Свайный фундамент',
          'Ленточный фундамент',
          'Монолитный фундамент',
          'Сборный фундамент',
          'Кольцевой фундамент',
        ],
        correctAnswers: ['Свайный фундамент'],
      },
      {
        code: '63713075',
        text:
          'Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации?',
        answers: [
          'Категории А',
          'Категории В1',
          'Никакой из перечисленных категорий',
          'Категории Б',
        ],
        correctAnswers: ['Категории В1'],
      },
      {
        code: '63713076',
        text:
          'На каком расстоянии друг от друга должны размещаться мостики через конвейеры в производственных помещениях, а также в галереях и эстакадах?',
        answers: [
          'Не более 50 м в производственных помещениях, не более 100 м в галереях и эстакадах',
          'Не более 75 м в производственных помещениях, галереях и эстакадах',
          'Не более 100 м в производственных помещениях, не более 110 м в галереях и эстакадах',
          'Не более 55 м в производственных помещениях, не более 120 м в галереях и эстакадах',
        ],
        correctAnswers: [
          'Не более 50 м в производственных помещениях, не более 100 м в галереях и эстакадах',
        ],
      },
      {
        code: '63713077',
        text:
          'В каком из перечисленных случаев не допускается включение электромагнитных сепараторов под напряжение?',
        answers: [
          'При отсутствии или неисправности световой сигнализации',
          'При отсутствии защитных ограждений',
          'При отсутствии постоянного контроля за работой со стороны обслуживающего персонала',
          'При сопротивлении изоляции обмоток не менее 0,5 МОм',
        ],
        correctAnswers: [
          'При отсутствии или неисправности световой сигнализации',
        ],
      },
      {
        code: '63713078',
        text:
          'Какие требования предъявляются к совместному складированию различных продуктов в одном и том же силосе (бункере)?',
        answers: [
          'Запрещается совместное складирование различных продуктов',
          'Разрешается совместное складирование различных продуктов',
          'Разрешается совместное кратковременное хранение различных продуктов',
          'Разрешаются совместное складирование и кратковременное хранение различных продуктов при условии оснащения силоса (бункера) устройством дистанционного контроля температуры',
        ],
        correctAnswers: [
          'Запрещается совместное складирование различных продуктов',
        ],
      },
      {
        code: '63713079',
        text: 'Где следует устанавливать датчики подпора на нориях?',
        answers: [
          'В головке нории',
          'На приемном патрубке',
          'На восходящей ветви на высоте 300 - 400 мм от башмака нории',
          'На нисходящей ветви на высоте 300 - 400 мм от башмака нории',
        ],
        correctAnswers: [
          'На восходящей ветви на высоте 300 - 400 мм от башмака нории',
        ],
      },
      {
        code: '63713080',
        text:
          'При выполнении какого из перечисленных условий допускается выводить взрыворазрядные устройства в производственные помещения?',
        answers: [
          'При наличии тамбур-шлюзов на выходе из этих производственных помещений',
          'При отсутствии в этих помещениях обслуживающего персонала',
          'При нормативном оснащении производственных помещений легкосбрасываемыми конструкциями',
          'При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз',
        ],
        correctAnswers: [
          'При условии установки на них исправных огнепреграждающих (пламеотсекающих) устройств, снижающих температуру выбрасываемых продуктов взрывного горения и подавляющих пламя, если выброс (отвод) продуктов взрывного горения осуществляется в зону производственного помещения, где не предусмотрено пребывание людей для осуществления технологического процесса, проведения работ по обслуживанию оборудования, и при наличии соответствующих положительных заключений экспертиз',
        ],
      },
      {
        code: '63713081',
        text:
          'Какие проверки необходимо производить при расчете подпорных стен по предельным состояниям второй группы (по пригодности к эксплуатации)? Укажите все правильные ответы.',
        answers: [
          'Основания на допустимые деформации',
          'Железобетонных элементов на допустимые величины раскрытия трещин',
          'Устойчивости положения стены против сдвига',
          'Прочности скального основания',
        ],
        correctAnswers: [
          'Основания на допустимые деформации',
          'Железобетонных элементов на допустимые величины раскрытия трещин',
        ],
      },
      {
        code: '63713082',
        text:
          'Какая из перечисленных характеристик не относится к аспирационным установкам элеваторов?',
        answers: [
          'Ни одна из перечисленных характеристик',
          'Содержание в обращающемся продукте большого количества сорной и минеральной примеси',
          'Наличие оборудования и участков, не подлежащих герметизации',
          'Невысокая мощность транспортных потоков и коммуникаций',
        ],
        correctAnswers: [
          'Невысокая мощность транспортных потоков и коммуникаций',
        ],
      },
      {
        code: '63713083',
        text:
          'Какое из перечисленных условий организованного подвода воздуха в помещения элеваторов указано верно?',
        answers: [
          'Подачу воздуха в помещение следует производить в нижнюю зону',
          'Специальные приточные устройства следует предусматривать при воздухообмене, превышающем один обмен в час',
          'Приточные устройства рекомендуется устанавливать в зонах с наибольшим загрязнением наружного воздуха',
          'Наружные отверстия приточных устройств следует закрывать сеткой с отверстиями 10 x 10 мм',
        ],
        correctAnswers: [
          'Специальные приточные устройства следует предусматривать при воздухообмене, превышающем один обмен в час',
        ],
      },
      {
        code: '63713084',
        text:
          'Какая часть конвейера должна ограждаться на высоту не менее 2 м от пола?',
        answers: [
          'Грузы вертикальных натяжных станций',
          'Опорные ролики',
          'Шкивы',
          'Концы валов',
          'Муфты',
        ],
        correctAnswers: ['Грузы вертикальных натяжных станций'],
      },
      {
        code: '63713085',
        text: 'Какой допустимый предел зазора между вальцами?',
        answers: [
          'От 0,05 до 0,5 мм',
          'От 0,25 до 1,0 мм',
          'От 0,1 до 1,0 мм',
          'От 0,2 до 1,5 мм',
        ],
        correctAnswers: ['От 0,1 до 1,0 мм'],
      },
      {
        code: '63713086',
        text:
          'Какие из перечисленных действий допускаются при эксплуатации объектов хранения и переработки растительного сырья?',
        answers: [
          'Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов',
          'Хранение сырого зерна на складах силосного типа',
          'Все перечисленные действия',
          'Хранение влажного зерна в силосах элеватора',
        ],
        correctAnswers: [
          'Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов',
        ],
      },
      {
        code: '63713087',
        text:
          'В каком случае допускается одновременная разборка или ремонт конструкций, оборудования в двух или более ярусах по одной вертикали?',
        answers: [
          'Не допускается ни в каком случае',
          'При согласовании с лицами, ответственными за эксплуатацию',
          'При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках',
          'При обосновании проектной документацией',
        ],
        correctAnswers: [
          'При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках',
        ],
      },
      {
        code: '63713088',
        text:
          'Какое из перечисленных действий разрешается при производстве работ в силосах и бункерах?',
        answers: [
          'Нахождение людей под силосом или бункером при разрушении сводов и зависших масс зерна или других продуктов',
          'Хождение по насыпи зерна или других продуктов хранения',
          'Доступ в силосы и бункеры через нижний люк',
          'Нахождение людей, не участвующих в разрушении сводов или зависших масс, в зоне лазовых и загрузочных люков',
        ],
        correctAnswers: ['Доступ в силосы и бункеры через нижний люк'],
      },
      {
        code: '63713089',
        text:
          'С какой периодичностью следует тщательно очищать воздушный ресивер?',
        answers: [
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 8 месяцев',
          'Не реже 1 раза в 9 месяцев',
          'Не реже 1 раза в 12 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63713090',
        text:
          'Какие из перечисленных требований предъявляются к вальцовым станкам?',
        answers: [
          'Должны иметь постоянный нерегулируемый зазор между вальцами',
          'Должны иметь световую сигнализацию холостого хода',
          'Должны иметь звуковую сигнализацию холостого хода',
          'Должны иметь устройство останова при отсутствии продукта',
          'Должны иметь регулируемый зазор между вальцами в пределах от 1,0 до 3,0 мм',
        ],
        correctAnswers: ['Должны иметь световую сигнализацию холостого хода'],
      },
      {
        code: '63713091',
        text:
          'Какие ширина и высота должны быть у крытых проездов автомобильных весов и приемных устройств при выполнении погрузочно-разгрузочных работ на автомобильном транспорте на территории взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'Ширина 3,5 м и высота не более 4 м',
          'Ширина 3,0 м и высота более 4,5 м',
          'Ширина 2,5 м и высота не более 5 м',
          'Ширина 5 м и высота не более 10 м',
        ],
        correctAnswers: ['Ширина 3,5 м и высота не более 4 м'],
      },
      {
        code: '63713092',
        text:
          'При нагревании магнитопровода до какой максимальной температуры допускается работа электромагнитных сепараторов?',
        answers: ['До 60 °C', 'До 70 °C', 'До 90 °C', 'До 100 °C'],
        correctAnswers: ['До 60 °C'],
      },
      {
        code: '63713093',
        text:
          'Какое из перечисленных требований к станкам для резки заплат указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм',
          'Приводной вал с дисковыми ножами станка должен быть отбалансирован',
          'Станок со всех сторон должен иметь проходы не менее 1,5 м. Диски должны быть отбалансированы',
          'Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 5 мм',
        ],
        correctAnswers: [
          'Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм',
          'Приводной вал с дисковыми ножами станка должен быть отбалансирован',
        ],
      },
      {
        code: '63713094',
        text:
          'В помещениях какой категории запрещается устройство выбоя отходов производства в тару в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'В помещениях категории В',
          'В помещениях категории А',
          'В помещениях категории Б',
          'В помещениях категорий А и Б',
          'В помещениях категории Г',
        ],
        correctAnswers: ['В помещениях категории В'],
      },
      {
        code: '63713095',
        text:
          'С какой периодичностью должен испытываться и проверяться квалифицированным персоналом пневматический инструмент?',
        answers: [
          'Ежемесячно',
          'Ежесменно',
          'Перед каждым применением',
          '1 раз в 3 месяца',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63713096',
        text:
          'Какое требование к лазовым и загрузочным люкам силосов, бункеров указано неверно?',
        answers: [
          'Люки силосов, бункеров в перекрытиях производственных помещений должны закрываться крышками в уровень с полом',
          'Углубление решеток люков более 60 мм от поверхности пола помещения должно быть обосновано',
          'Лазовые люки должны закрываться на замок',
          'Лазовые люки должны быть круглого сечения диаметром не менее 500 мм',
        ],
        correctAnswers: [
          'Лазовые люки должны быть круглого сечения диаметром не менее 500 мм',
        ],
      },
      {
        code: '63713097',
        text: 'Какие конвейеры должны быть оснащены реле контроля скорости?',
        answers: [
          'Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более',
          'Винтовые конвейеры длиной более 15 м',
          'Цепные конвейеры со скоростью движения цепи 1 м/с и более',
          'Цепные конвейеры, установленные на подсилосных этажах деревянных элеваторов',
          'Винтовые конвейеры, установленные в производственных помещениях комбикормового производства',
        ],
        correctAnswers: [
          'Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более',
        ],
      },
      {
        code: '63713098',
        text:
          'Какой должна быть температура нагрева корпусов подшипников во время работы взрывопожароопасного оборудования?',
        answers: [
          'Не более 60 °C',
          'Не более 45 °C',
          'Не более 50 °C',
          'Не более 70 °C',
        ],
        correctAnswers: ['Не более 60 °C'],
      },
      {
        code: '63713099',
        text:
          'Какое из перечисленных требований при работе с ручным немеханизированным инструментом указано неверно? Выберите 2 варианта ответа.',
        answers: [
          'Выколотка должна быть из твердого металла',
          'Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях',
          'Напильники, ножовки, стамески, долота и другие ручные инструменты должны быть прочно закреплены в деревянной рукоятке с наложенным на нее стальным кольцом',
          'При запрессовке и распрессовке деталей (подшипников, втулок) с помощью кувалды и выколотки последнюю следует держать клещами или захватом',
        ],
        correctAnswers: [
          'Выколотка должна быть из твердого металла',
          'Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях',
        ],
      },
      {
        code: '63713100',
        text:
          'В каком случае допускается выполнять бункер для хранения и перегрузки сыпучего материала без перекрытия, но с обязательным устройством сплошного ограждения?',
        answers: [
          'Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)',
          'Если загрузка производится периодически',
          'Все ответы неверны',
          'В случае загрузки связных сыпучих материалов',
        ],
        correctAnswers: [
          'Если загрузка производится средствами из непрерывного транспорта (вагоны, машины, грейферы)',
        ],
      },
      {
        code: '63713101',
        text:
          'В каком положении нарушены требования Свода правил СП 43.13330.2012 при расчете конструкций силосов и их элементов?',
        answers: [
          'Следует предусматривать мероприятия по взрывозащите и защите от статического электричества при проектировании силосов для сыпучих материалов, пыль которых способна образовать взрывоопасные концентрации',
          'Расчет оснований сблокированных и отдельно стоящих силосов, возводимых на нескальных грунтах, должен производиться по предельным состояниям второй группы (по деформациям) с учетом ветровой нагрузки',
          'По периметру наружных стен силосных корпусов высотой до верха карниза более 10 м следует предусматривать на кровле решетчатые ограждения высотой не менее 0,6 м из негорючих материалов',
          'При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами',
        ],
        correctAnswers: [
          'При определении давления на грунт под подошвой фундамента силосов следует учитывать только нагрузку, возникающую при полной загрузке силосов сыпучими материалами',
        ],
      },
      {
        code: '63713102',
        text:
          'Какие явления допускаются при эксплуатации металлических силосов?',
        answers: [
          'Неплотности в зоне опоры стенок силосных емкостей на фундаменты',
          'Все ответы неверны',
          'Подтеки на внутренних поверхностях стен',
          'Неплотности в местах крепления дефлекторов и термоподвесок',
          'Подтеки на днище',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63713103',
        text:
          'Какой допускается применять минимальный диаметр воздуховода аспирационных установок при соответствующем обосновании?',
        answers: ['80 мм', '50 мм', '150 мм', '120 мм'],
        correctAnswers: ['80 мм'],
      },
      {
        code: '63713104',
        text:
          'Какой должна быть степень защиты оболочки переносных светильников прожекторного типа, использующихся для освещения бункеров и силосов сверху через люки?',
        answers: [
          'Не ниже IP65',
          'Не ниже IP54',
          'Не ниже IP68',
          'Не ниже IP44',
        ],
        correctAnswers: ['Не ниже IP54'],
      },
      {
        code: '63713105',
        text:
          'Какое из перечисленных условий компоновки аспирационных установок указано неверно?',
        answers: [
          'В состав установки следует включать преимущественно оборудование, работающее одновременно',
          'Аспирационные установки для повышения надежности работ и удобства эксплуатации рекомендуется проектировать с максимально возможным количеством точек отсоса',
          'При проектировании аспирационных установок необходимо стремиться к минимальной протяженности воздуховодов',
          'В одну установку по возможности включают однородные машины',
          'Следует стремиться к симметричному расположению воздуховодов относительно главной магистрали',
        ],
        correctAnswers: [
          'Аспирационные установки для повышения надежности работ и удобства эксплуатации рекомендуется проектировать с максимально возможным количеством точек отсоса',
        ],
      },
      {
        code: '63713106',
        text:
          'Кем должны подтверждаться эффективность и надежность технических средств блокировки, контроля и противоаварийной защиты объекта хранения и переработки растительного сырья испытанием промышленных образцов оборудования на взрывозащищенность?',
        answers: [
          'Организацией-изготовителем',
          'Эксплуатирующей организацией',
          'Специализированной подрядной организацией',
        ],
        correctAnswers: ['Организацией-изготовителем'],
      },
      {
        code: '63713107',
        text:
          'Чем тушат горящий продукт в подсилосном этаже в целях предотвращения возможного образования пылевого облака при выходе продукта из силоса?',
        answers: [
          'Направленной струей воды',
          'Распыленной водой',
          'Углекислой пеной',
          'Азотом',
        ],
        correctAnswers: ['Распыленной водой'],
      },
      {
        code: '63713108',
        text:
          'Что из перечисленного разрешается при эксплуатации технологического, аспирационного и транспортного оборудования?',
        answers: [
          'Использование при обслуживании машин безопасных приспособлений для проведения очистки питающих механизмов, очистки на холостом ходу верхних плоскостей решет сепараторов и др.',
          'Заклеивать и забивать специально устраиваемые в крышках машин аспирационные щели',
          'Ручной отбор проб зерна, продуктов размола, зерна и крупы из оборудования, имеющего в месте отбора или непосредственной близости движущиеся части',
          'Пуск и работа машин, выделяющих пыль, с открытыми люками',
        ],
        correctAnswers: [
          'Использование при обслуживании машин безопасных приспособлений для проведения очистки питающих механизмов, очистки на холостом ходу верхних плоскостей решет сепараторов и др.',
        ],
      },
      {
        code: '63713109',
        text:
          'Где не допускается прокладка трубопроводов с пожаро- и взрывоопасными веществами (смесями)?',
        answers: [
          'Только через распределительные устройства',
          'Только через трансформаторные подстанции',
          'Только через комплектные трансформаторные подстанции',
          'Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции',
        ],
        correctAnswers: [
          'Через распределительные устройства, трансформаторные подстанции и преобразовательные подстанции',
        ],
      },
      {
        code: '63713110',
        text:
          'Какие мероприятия должны быть предусмотрены при эксплуатации действующих норий в соответствии с требованиями промышленной безопасности?',
        answers: [
          'Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака',
          'Необходимо провести внеплановый инструктаж и проверку знаний обслуживающего персонала',
          'Необходимо обеспечить периодическую круглосуточную уборку пыли в производственных помещениях',
          'Необходимо предусмотреть дополнительную аспирацию башмака нории',
        ],
        correctAnswers: [
          'Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака',
        ],
      },
      {
        code: '63713111',
        text:
          'При каком количестве напольных машин, имеющих тяговые аккумуляторные батареи, их заряжают как в отдельных помещениях с естественной вентиляцией, так и в общих невзрывопожароопасных производственных помещениях?',
        answers: ['До 6', 'До 10', 'До 15', 'До 20'],
        correctAnswers: ['До 6'],
      },
      {
        code: '63713112',
        text:
          'Какие мероприятия необходимо осуществить перед началом выгрузки горящего продукта из силоса?',
        answers: [
          'Весь свободный объем надсводного пространства только аварийного силоса заполняется воздушно-механической пеной',
          'Весь свободный объем надсводного пространства аварийного и смежного с ним силосов заполняется воздушно-механической пеной, подаваемой сверху через загрузочные люки',
          'Весь свободный объем надсводного пространства аварийного силоса заполняется свежей партией продукта',
          'Весь свободный объем надсводного пространства аварийного и смежного с ним силосов заполняется свежей партией продукта',
          'В аварийном и смежном с ним силосе обеспечивают непрерывное распыление воды с помощью стволов с насадками через загрузочные люки',
        ],
        correctAnswers: [
          'Весь свободный объем надсводного пространства аварийного и смежного с ним силосов заполняется воздушно-механической пеной, подаваемой сверху через загрузочные люки',
        ],
      },
      {
        code: '63713113',
        text:
          'Что из перечисленного не допускается объединять в одну аспирационную установку? Выберите все правильные варианты ответов.',
        answers: [
          'Обеспыливание потенциально опасного оборудования и бункеров',
          'Обеспыливание потенциально опасного оборудования и другого оборудования бункерного типа (гравитационных смесителей, весов)',
          'Обеспыливание машин, однотипных по технологическому назначению',
          'Обеспыливание потенциально опасного оборудования и силосов',
        ],
        correctAnswers: [
          'Обеспыливание потенциально опасного оборудования и бункеров',
          'Обеспыливание потенциально опасного оборудования и другого оборудования бункерного типа (гравитационных смесителей, весов)',
          'Обеспыливание потенциально опасного оборудования и силосов',
        ],
      },
      {
        code: '63713114',
        text:
          'На какую высоту должны быть ограждены люки в полах выбойных и фасовочных отделений, через которые подаются мешки на конвейер?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 1,0 м',
          'Не менее 0,85 м',
          'Не менее 0,75 м',
        ],
        correctAnswers: ['Не менее 1,0 м'],
      },
      {
        code: '63713115',
        text:
          'При какой температуре очага самосогревания зерна ситуация считается аварийной и производственные процессы останавливаются?',
        answers: [
          'При температуре более 45 °С',
          'При температуре более 55 °С',
          'При температуре более 80 °С',
          'При температуре более 95 °С',
          'При температуре более 100 °С',
        ],
        correctAnswers: ['При температуре более 100 °С'],
      },
      {
        code: '63713116',
        text:
          'На проектирование каких сооружений распространяется СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий? Укажите все правильные ответы.',
        answers: [
          'На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях',
          'Все ответы неверны',
          'На проектирование сооружений специального назначения',
          'На проектирование емкостных сооружений для водоснабжения и канализации',
          'На проектирование емкостных сооружений для жидкостей и газов',
          'На проектирование сооружений со сроком эксплуатации до 5 лет',
        ],
        correctAnswers: [
          'На проектирование сооружений промышленных предприятий, предназначенных для строительства в особых условиях',
          'На проектирование емкостных сооружений для жидкостей и газов',
        ],
      },
      {
        code: '63713117',
        text:
          'Каким требованиям должны отвечать устройства, применяемые для спуска людей в силосы?',
        answers: [
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при выполнении огневых и газоопасных работ',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования промышленной безопасности при проведении работ с инструментом и приспособлениями',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе в замкнутом пространстве',
        ],
        correctAnswers: [
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте',
        ],
      },
      {
        code: '63713118',
        text:
          'Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается План мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63713119',
        text:
          'Какой допускается перекос общих осей поверхностей цапф (диаметром 65 мм) мелющих вальцов в каждой половине вальцового станка?',
        answers: [
          'Не более 0,25 мм по длине 1000 мм',
          'Не более 0,2 мм по длине 800 мм',
          'Не более 0,3 мм по длине 900 мм',
          'Не более 0,15 мм по длине 1000 мм',
        ],
        correctAnswers: ['Не более 0,25 мм по длине 1000 мм'],
      },
      {
        code: '63713120',
        text:
          'Какое из перечисленных требований к топкам, работающим на жидком и газообразном топливе, указано верно?',
        answers: [
          'На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки',
          'Повторная подача топлива в топку и розжиг его после устранения причины неисправности осуществляется только после проветривания топки в течение 30 минут',
          'Если жидкое или газообразное топливо при розжиге топки не загорается в течение 1 минуты, система контроля и автоматики горения топлива должна отключить подачу его в форсунку',
          'Допускается оставлять работающую топку без присмотра в течение 10 минут',
        ],
        correctAnswers: [
          'На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки',
        ],
      },
      {
        code: '63713121',
        text:
          'Каким из перечисленных способов должно производиться сращивание концов приводных ремней оборудования взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'При помощи стальных соединителей',
          'Путем склеивания или сыромятными сшивками',
          'Путем горячей вулканизации',
          'При помощи медных или алюминиевых соединителей',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Путем склеивания или сыромятными сшивками'],
      },
      {
        code: '63713122',
        text:
          'В каких из перечисленных конструкциях расстояние между температурно-усадочными швами следует принимать не более 10 м?',
        answers: [
          'В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования',
          'В монолитных бетонных конструкциях при наличии конструктивного армирования',
          'В монолитных и сборно-монолитных железобетонных конструкциях стен',
          'В сборных железобетонных конструкциях',
        ],
        correctAnswers: [
          'В монолитных бутобетонных и бетонных подпорных стенах без конструктивного армирования',
        ],
      },
      {
        code: '63713123',
        text:
          'Какое расстояние между температурно-усадочными швами следует принимать в монолитных и сборно-монолитных железобетонных конструкциях стен?',
        answers: [
          'Не более 25 м',
          'Не более 10 м',
          'Не более 20 м',
          'Не более 30 м',
        ],
        correctAnswers: ['Не более 25 м'],
      },
      {
        code: '63713124',
        text:
          'Что должны иметь дверцы, смотровые лючки и выпускные устройства оборудования?',
        answers: [
          'Рукоятки, скобы и другие устройства для удобного и безопасного удержания их при снятии и установке',
          'Концевые выключатели',
          'Уплотнения, не пропускающие пыль',
          'Устройства, исключающие их случайное запирание',
        ],
        correctAnswers: ['Уплотнения, не пропускающие пыль'],
      },
      {
        code: '63713125',
        text:
          'В каком случае допускается объединять в одну аспирационную установку оборудование первичной ("черной") и окончательной ("белой") очистки зерна в зерноочистительных отделениях мукомольных и крупяных заводов?',
        answers: [
          'На предприятиях малой мощности',
          'На предприятиях, оборудованных внутрицеховым пневматическим транспортом',
          'Не допускается',
          'На предприятиях высокой мощности',
          'При горизонтальном расположении воздуховодов',
        ],
        correctAnswers: ['На предприятиях малой мощности'],
      },
      {
        code: '63713126',
        text:
          'Какие мероприятия необходимо предусматривать в случае обнаружения в мучнистом или гранулированном сырье повышения температуры, связанного с признаками самосогревания?',
        answers: [
          'Производить ежесуточную проверку температуры в данной партии с извлечением этой партии сырья из емкостей в первую очередь',
          'Производить перемещение сырья из занимаемых им емкостей в свободные независимо от температуры очага самосогревания',
          'Производить перемещение сырья в тот же силос',
          'Производить ежесуточную проверку влажности в данной партии',
          'Производить выборочную проверку температуры и влажности в данной партии',
        ],
        correctAnswers: [
          'Производить ежесуточную проверку температуры в данной партии с извлечением этой партии сырья из емкостей в первую очередь',
        ],
      },
      {
        code: '63713127',
        text: 'Какие нагрузки не учитываются при расчете силосов?',
        answers: [
          'Нагрузки, возникающие при изготовлении, перевозке и монтаже сборных конструкций',
          'Нагрузки, возникающие при изменении температур наружного воздуха',
          'Нагрузки, возникающие от давления при взрыве',
          'Нагрузки, возникающие от столкновений транспортных средств с частями сооружения',
        ],
        correctAnswers: [
          'Нагрузки, возникающие от столкновений транспортных средств с частями сооружения',
        ],
      },
      {
        code: '63713128',
        text:
          'Что из перечисленного необходимо предусматривать на цепных конвейерах?',
        answers: [
          'Установку взрыворазрядного устройства',
          'Установку независимых реле контроля скорости',
          'Установку устройства контроля обрыва цепи',
          'Установку устройства контроля схода цепи',
        ],
        correctAnswers: ['Установку устройства контроля обрыва цепи'],
      },
      {
        code: '63713129',
        text:
          'Какие светильники допускается использовать внутри деревянных емкостей при выключенных разгрузочных механизмах и оборудовании?',
        answers: [
          'Переносные светильники при напряжении в сети 42 В повышенной надежности против взрыва, а их стеклянные колпаки должны быть защищены металлической сеткой',
          'Переносные светильники при напряжении в сети не более 24 В повышенной надежности против взрыва со степенью защиты оболочки не ниже IР44',
          'Переносные светильники при напряжении в сети не более 12 В',
          'Переносные светильники повышенной надежности против взрыва со степенью защиты оболочки не ниже IP44 и металлической защитой стеклянного колпака',
        ],
        correctAnswers: [
          'Переносные светильники при напряжении в сети 42 В повышенной надежности против взрыва, а их стеклянные колпаки должны быть защищены металлической сеткой',
        ],
      },
      {
        code: '63713130',
        text:
          'В каком случае ширина проходов для обслуживания конвейеров может быть 0,7 м?',
        answers: [
          'Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями',
          'Между параллельно установленными конвейерами без коробов',
          'Для ленточных конвейеров',
          'Для цепных конвейеров',
        ],
        correctAnswers: [
          'Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями',
        ],
      },
      {
        code: '63713131',
        text:
          'Какое из перечисленных действий допускается при проведении огневых работ на объектах хранения и переработки растительного сырья? Выберите 2 варианта ответа.',
        answers: [
          'Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом',
          'Проведение уборки помещений',
          'Прокладка электрических проводов на расстоянии менее 1,0 м от баллонов с горючими газами',
          'Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ',
          'Использование в качестве обратного провода сети заземления или зануления металлических конструкций зданий, коммуникаций и технологического оборудования',
        ],
        correctAnswers: [
          'Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом',
          'Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ',
        ],
      },
      {
        code: '63713132',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63713133',
        text:
          'Кто должен присутствовать при осмотре или ремонте в надсушильных, подсушильных бункерах и тепловлагообменниках?',
        answers: [
          'Начальник цеха или смены',
          'Любой работник цеха или смены',
          'Представитель проектной организации',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Начальник цеха или смены'],
      },
      {
        code: '63713134',
        text:
          'С каким покрытием следует проектировать автомобильные дороги на площадках мукомольно-крупяных и комбикормовых предприятий по санитарным условиям?',
        answers: [
          'Гравийным',
          'Асфальтобетонным или бетонным',
          'Щебеночным',
          'Шлаковым',
        ],
        correctAnswers: ['Асфальтобетонным или бетонным'],
      },
      {
        code: '63713135',
        text:
          'Какие требования необходимо соблюдать при разрушении сводов и зависших масс зерна или других продуктов?',
        answers: [
          'Лазовые и загрузочные люки силосов и бункеров должны быть открыты',
          'Лазовые и загрузочные люки силосов и бункеров должны быть закрыты',
          'Лазовые люки должны быть открыты, а загрузочные люки - закрыты',
          'Лазовые люки должны быть закрыты, а загрузочные люки - открыты',
        ],
        correctAnswers: [
          'Лазовые и загрузочные люки силосов и бункеров должны быть открыты',
        ],
      },
      {
        code: '63713136',
        text:
          'Какое из перечисленных требований к обустройству головок норий с целью их обслуживания указано неверно?',
        answers: [
          'Для обслуживания головок норий, оси приводных барабанов которых расположены на высоте от пола более 1,5 м, необходимо предусматривать специальные площадки с перилами высотой не менее 1 м',
          'Для обслуживания головок норий, оси приводных барабанов которых расположены на высоте от пола более 1,5 м, необходимо предусматривать специальные площадки с зашивкой внизу на 0,15 м с обеспечением проходов для обслуживания',
          'Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м',
        ],
        correctAnswers: [
          'Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м',
        ],
      },
      {
        code: '63713137',
        text:
          'В каких целях должны проектироваться аспирационные установки на предприятиях по хранению и переработке зерна и предприятиях хлебопекарной промышленности?',
        answers: [
          'Только для создания разрежения внутри технологического оборудования и транспортирующих машин для предотвращения выделения пыли в производственные помещения',
          'Только для очистки зерна и сортирования воздушными потоками продуктов размола и шелушения',
          'Только для удаления избыточного тепла и влаги из оборудования',
          'Для достижения всех перечисленных целей, создания необходимых санитарно-гигиенических условий и предупреждения возникновения пожаров и взрывов пылевоздушных смесей',
        ],
        correctAnswers: [
          'Для достижения всех перечисленных целей, создания необходимых санитарно-гигиенических условий и предупреждения возникновения пожаров и взрывов пылевоздушных смесей',
        ],
      },
      {
        code: '63713138',
        text:
          'На каких конвейерах должны быть предусмотрены устройства, предохраняющие конвейеры от переполнения короба продуктом?',
        answers: [
          'Только на цепных конвейерах',
          'Только на винтовых конвейерах',
          'На закрытых ленточных конвейерах',
          'На цепных и винтовых конвейерах',
        ],
        correctAnswers: ['На цепных и винтовых конвейерах'],
      },
      {
        code: '63713139',
        text:
          'По указанию какого лица с момента поступления информации об аварийной ситуации производятся выключение или включение электроэнергии, обеспечиваются бесперебойное действие связи, исправное состояние водопровода, бесперебойная работа необходимого электромеханического оборудования и подвижных транспортных средств?',
        answers: [
          'Ответственного руководителя работ по локализации и ликвидации последствий аварии',
          'Технического руководителя организации',
          'Главного энергетика',
          'Главного механика',
          'Главного технолога',
        ],
        correctAnswers: [
          'Ответственного руководителя работ по локализации и ликвидации последствий аварии',
        ],
      },
      {
        code: '63713140',
        text:
          'Кто утверждает графики уборки пыли в производственных помещениях объектов хранения и переработки растительного сырья?',
        answers: [
          'Руководитель эксплуатирующей организации (технический директор)',
          'Представитель территориального управления Федеральной службы по экологическому, технологическому и атомному надзору',
          'Лицо, ответственное за промышленную безопасность в производственном помещении (цехе)',
          'Начальник смены',
        ],
        correctAnswers: [
          'Руководитель эксплуатирующей организации (технический директор)',
        ],
      },
      {
        code: '63713141',
        text:
          'Использование каких перечисленных инструментов допускается при производстве погрузочно-разгрузочных работ с зерном и другими сыпучими продуктами на железнодорожном транспорте?',
        answers: [
          'Использование вибраторов для удаления зависшего в вагоне продукта',
          'Использование всех перечисленных инструментов',
          'Использование рычагов для вращения штурвалов',
          'Использование удлинителей для фиксации крышки загрузочного люка',
        ],
        correctAnswers: [
          'Использование вибраторов для удаления зависшего в вагоне продукта',
        ],
      },
      {
        code: '63713142',
        text:
          'Что из перечисленного запрещается использовать для уборки пыли в производственных помещениях объектов хранения и переработки растительного сырья? Выберите все правильные варианты ответа.',
        answers: [
          'Горючие жидкости',
          'Сжатый воздух и другой сжатый газ',
          'Средства, исключающие образование взрывоопасной пылевоздушной смеси',
        ],
        correctAnswers: [
          'Горючие жидкости',
          'Сжатый воздух и другой сжатый газ',
        ],
      },
      {
        code: '63713143',
        text:
          'Какие требования предъявляются к хранению рисовой, просяной, ячменной, гречневой лузги?',
        answers: [
          'Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах вместимостью на 1 - 2 суток работы завода',
          'Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах вместимостью на 2 смены работы завода',
          'Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах и силосах в объеме, не превышающем 3 смены работы завода',
          'Рисовая, просяная, ячменная, гречневая лузга должна храниться на складах бестарного напольного хранения',
        ],
        correctAnswers: [
          'Рисовая, просяная, ячменная, гречневая лузга должна храниться в бункерах вместимостью на 1 - 2 суток работы завода',
        ],
      },
      {
        code: '63713144',
        text:
          'Каким образом следует распределять взрыворазрядители на камерах нагрева при установке нескольких взрыворазрядителей?',
        answers: [
          'Равномерно по ширине камеры',
          'Равномерно по длине камеры',
          'В соответствии с указаниями лица, ответственного за безопасное производство работ',
          'Равномерно по высоте камеры',
        ],
        correctAnswers: ['Равномерно по высоте камеры'],
      },
      {
        code: '63713145',
        text:
          'Какие требования надлежит принимать при проектировании отдельно стоящих силосов и силосных корпусов? Выберите все правильные варианты ответов.',
        answers: [
          'Наружные диаметры круглых отдельно стоящих силосов, равные 6, 9, 12, 18 и 24 м',
          'Сетки разбивочных осей, проходящих через центры сблокированных в силосные корпуса силосов, размером 3 x 3, 6 x 6, 9 x 9 и 12 x 12 м',
          'Высоту стен силосов, подсилосных и надсилосных этажей, кратную 0,5 м',
          'В силосных корпусах для хранения сырья и готовой продукции мукомольно-крупяных предприятий и комбикормовых заводов с двумя подсилосными этажами и более допускается принимать каркас по типу производственных зданий с сеткой колонн 6 x 3 м',
        ],
        correctAnswers: [
          'Наружные диаметры круглых отдельно стоящих силосов, равные 6, 9, 12, 18 и 24 м',
          'Сетки разбивочных осей, проходящих через центры сблокированных в силосные корпуса силосов, размером 3 x 3, 6 x 6, 9 x 9 и 12 x 12 м',
          'В силосных корпусах для хранения сырья и готовой продукции мукомольно-крупяных предприятий и комбикормовых заводов с двумя подсилосными этажами и более допускается принимать каркас по типу производственных зданий с сеткой колонн 6 x 3 м',
        ],
      },
      {
        code: '63713146',
        text:
          'Что из перечисленного не допускается в соединениях между элементами пневмотранспортных установок?',
        answers: [
          'Использование металлических шайб под болты из металла, окрашенных неэлектропроводными красками',
          'Использование металлических шайб под металлические болты, окрашенных электропроводными красками',
          'Использование шайб под болты из диэлектрических материалов, окрашенных неэлектропроводными красками',
          'Использование шайб под болты из диэлектрических материалов, окрашенных электропроводными красками',
        ],
        correctAnswers: [
          'Использование шайб под болты из диэлектрических материалов, окрашенных неэлектропроводными красками',
        ],
      },
      {
        code: '63713147',
        text:
          'В каком из перечисленных случаев в процессе выполнения работ в силосах и бункерах подачу воздуха в противогаз осуществляют при помощи воздуходувки?',
        answers: [
          'При длине шланга 12 м и более',
          'При спуске рабочих в силосы и бункеры высотой 3 м и более',
          'При спуске рабочих в силосы и бункеры высотой 7 м и более',
          'При длине шланга 5 м и более',
        ],
        correctAnswers: ['При длине шланга 12 м и более'],
      },
      {
        code: '63713148',
        text:
          'С какой периодичностью из водомаслоотделителя необходимо удалять воду, масло и грязь?',
        answers: ['Ежесменно', 'Ежедневно', 'Раз в 3 дня', 'Раз в неделю'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63713149',
        text:
          'Запрещается ли снимать или надевать приводные ремни на ходу, регулировать натяжение ремней или цепей машин во время их работы при эксплуатации технологического, аспирационного и транспортного оборудования?',
        answers: [
          'Разрешается при условии, что работы производятся на основании наряда-допуска',
          'Разрешается при условии, что работы производятся под наблюдением лица, ответственного за безопасную эксплуатацию оборудования',
          'Разрешается при условии, что данные работы не угрожают безопасности обслуживающего персонала',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63713150',
        text:
          'Какие нории необходимо оснащать автоматически действующими тормозными устройствами?',
        answers: [
          'Нории производительностью менее 50 т/ч',
          'Нории, не оснащенные реле контроля скорости, датчиками подпора',
          'Нории, оснащенные реле контроля скорости, датчиками подпора',
          'Все виды норий',
          'Сдвоенные нории, имеющие возможность раздельного натяжения лент',
        ],
        correctAnswers: ['Все виды норий'],
      },
      {
        code: '63713151',
        text:
          'С какими из перечисленных устройств должны быть сблокированы электроприводы молотковых дробилок?',
        answers: [
          'С датчиками нижнего уровня наддробильных бункеров',
          'С датчиками верхнего уровня поддробильных бункеров',
          'С устройством контроля температуры подшипников',
          'С датчиками нижнего уровня поддробильных бункеров',
          'С датчиками верхнего уровня наддробильных бункеров',
        ],
        correctAnswers: ['С датчиками нижнего уровня наддробильных бункеров'],
      },
      {
        code: '63713152',
        text:
          'Какой уровень влажности не должна превышать влажность кукурузы в зерне, проса, сорго, овса при их хранении до года?',
        answers: ['13,5%', '14,5%', '15%', '14%', '15,5%'],
        correctAnswers: ['13,5%'],
      },
      {
        code: '63713153',
        text:
          'С какой периодичностью проводят контроль температуры сырья в сухом состоянии в металлических силосах при температуре выше 10 °С?',
        answers: [
          '1 раз в день',
          '1 раз в 2 дня',
          '1 раз в 3 дня',
          '1 раз в 7 дней',
          '1 раз в 5 дней',
        ],
        correctAnswers: ['1 раз в 3 дня'],
      },
      {
        code: '63713154',
        text:
          'Какое допускается максимальное содержание арматуры железобетонных колонн подсилосного этажа?',
        answers: ['5%', '3%', '7%', '9%'],
        correctAnswers: ['3%'],
      },
      {
        code: '63713155',
        text:
          'Каким образом определяется очаг самовозгорания в силосах и бункерах?',
        answers: [
          'Только на основе измерения температуры в массе продукта и обработки измерительной информации',
          'Только при визуальном наблюдении по выходу дыма и пара через неплотности в конструкции силоса, по изменению цвета ограждающих конструкций, образованию в них трещин, обгоранию краски',
          'Только по едкому, резкому и неприятному запаху продуктов, свойственному запаху продуктов сухой перегонки растительного сырья',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63713156',
        text:
          'В каком из перечисленных случаев допускается размещение приточных установок?',
        answers: [
          'В обслуживаемых помещениях категорий А и Б при условии их взрывозащищенного исполнения',
          'В необслуживаемых помещениях категорий А и Б',
          'Только в обслуживаемых помещениях категории А',
          'Только в обслуживаемых помещениях категории Б',
        ],
        correctAnswers: [
          'В обслуживаемых помещениях категорий А и Б при условии их взрывозащищенного исполнения',
        ],
      },
      {
        code: '63713157',
        text:
          'Какое устанавливается максимальное усилие, необходимое для перемещения несамоходной разгрузочной тележки?',
        answers: [
          '150 Н (15 кгс)',
          '100 Н (10 кгс)',
          '200 Н (20 кгс)',
          '250 Н (25 кгс)',
        ],
        correctAnswers: ['150 Н (15 кгс)'],
      },
      {
        code: '63713158',
        text: 'Что допускается делать при эксплуатации электроустановок?',
        answers: [
          'Включать электроустановки без обеспечения их защиты от механических повреждений',
          'Отключать электроустановки от сети при исчезновении напряжения',
          'Подключать к трансформаторам, питающим искробезопасные приборы, другие цепи и приборы, не входящие в комплект искробезопасных приборов',
          'Пускать в работу электроустановки при неисправностях блокировки крышек аппаратов и блокировки пуска машин',
        ],
        correctAnswers: [
          'Отключать электроустановки от сети при исчезновении напряжения',
        ],
      },
      {
        code: '63713159',
        text:
          'Какие тоннели и каналы должны быть заглублены от поверхности до верха перекрытия не менее чем на 1 м?',
        answers: [
          'Тоннели и каналы, располагаемые под железными дорогами',
          'Тоннели и каналы, располагаемые под автомобильными дорогами',
          'Тоннели и каналы, располагаемые вне зданий и дорог',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Тоннели и каналы, располагаемые под железными дорогами',
        ],
      },
      {
        code: '63713160',
        text:
          'Какими совками разрешается брать пробу из лючка выпускного патрубка паровой сушилки?',
        answers: [
          'Деревянными',
          'Оловянными',
          'Металлическими',
          'Керамическими',
        ],
        correctAnswers: ['Деревянными'],
      },
      {
        code: '63713161',
        text:
          'С какой периодичностью следует проверять переносные электроинструменты, лампы, трансформаторы на стенде или прибором в отношении исправности их заземляющих проводов и отсутствия замыкания между проводами?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в смену',
          'Не реже одного раза в сутки',
          'Один раз в 10 дней',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63713162',
        text:
          'Какое из перечисленных требований к устройству закромов для хранения сыпучих материалов указано неверно?',
        answers: [
          'Полы закромов надлежит выполнять из камня грубого окола или грунтовыми',
          'Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями',
          'Стены закромов должны быть рассчитаны на горизонтальное давление грунта с учетом временной нормативной нагрузки на поверхности земли интенсивностью не менее 20 кПа (2 тс/м²) при опорожненном закроме',
          'Все ответы неверны',
          'При загрузке и выгрузке материалов грейферными кранами следует предусматривать буферный слой из хранимого материала внутри закромов толщиной не менее 0,3 м',
        ],
        correctAnswers: [
          'Стены с внутренней стороны и сверху должны быть защищены деревянными брусьями',
        ],
      },
      {
        code: '63713163',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из специализированных разделов',
          'Из частных разделов',
          'Из разделов, предусмотренных требованиями Федеральных норм и правил в области промышленной безопасности',
          'Из общих и специальных разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63713164',
        text:
          'На основании какой документации осуществляются периодические перемещения (перекачивания) шрота, жмыха и другого мучнистого сырья, склонного к самовозгоранию, из занимаемых ими емкостей в свободные?',
        answers: [
          'Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте',
          'Все ответы неверны',
          'Планов-графиков, разработанных на основании Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
          'Планов-графиков, разработанных организацией и утвержденных в территориальных органах Ростехнадзора',
        ],
        correctAnswers: [
          'Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте',
        ],
      },
      {
        code: '63713165',
        text:
          'В каком случае необходимо устанавливать крюки для подвески талей, блоков над съемными деталями оборудования?',
        answers: [
          'Если съемные детали оборудования массой более 50 кг',
          'Все ответы неверны',
          'В любом случае вне зависимости от их массы съемных деталей',
          'Если съемные детали оборудования массой более 20 кг',
        ],
        correctAnswers: ['Если съемные детали оборудования массой более 50 кг'],
      },
      {
        code: '63713166',
        text:
          'Покрытия каких силосов допускается проектировать в виде оболочек? Укажите все правильные ответы.',
        answers: [
          'Отдельно стоящих круглых силосов при отсутствии надсилосного помещения',
          'Силосов диаметром более 12 м',
          'Все ответы неверны',
          'Группы силосов, объединенных в силосный корпус',
          'Отдельно стоящих круглых силосов, надсилосные помещения которых спроектированы с применением облегченных стеновых ограждений',
        ],
        correctAnswers: [
          'Отдельно стоящих круглых силосов при отсутствии надсилосного помещения',
          'Силосов диаметром более 12 м',
        ],
      },
      {
        code: '63713167',
        text:
          'В каких воздуховодах аспирационных установок элеваторов следует принимать скорость воздуха до пылеотделителя не менее 12 м/с при расчете? Выберите два правильных варианта ответа.',
        answers: [
          'В горизонтальных',
          'В вертикальных',
          'В наклонных более 60°',
          'В наклонных менее 60°',
        ],
        correctAnswers: ['В вертикальных', 'В наклонных более 60°'],
      },
      {
        code: '63713168',
        text:
          'Для каких напряжений штепсельные соединения, предназначенные для подключения электроинструмента и переносных светильников, должны иметь зануляющий контакт?',
        answers: [
          'Только для напряжения 42 В',
          'Только для напряжения 127 В',
          'Только для напряжения 220 В',
          'Для напряжений 127 В и 220 В',
          'Для напряжений 42 В, 127 В и 220 В',
        ],
        correctAnswers: ['Для напряжений 127 В и 220 В'],
      },
      {
        code: '63713169',
        text:
          'Какие требования предъявляются к выходам из конвейерных, коммуникационных (кроме кабельных) тоннелей?',
        answers: [
          'Выходы должны предусматриваться не реже чем через 50 м',
          'Выходы должны предусматриваться не реже чем через 100 м, но не менее двух',
          'Выходы должны предусматриваться не реже чем через 150 м, но не менее трех',
          'Выходы должны предусматриваться не реже чем через 200 м, но не менее трех',
        ],
        correctAnswers: [
          'Выходы должны предусматриваться не реже чем через 100 м, но не менее двух',
        ],
      },
      {
        code: '63713170',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается единый план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63713171',
        text:
          'Какими должны быть интервалы при расстановке автомобилей на площади разгрузки и погрузки взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'Между автомобилями, стоящими друг за другом, в глубину - не менее 1 м, между автомобилями, стоящими рядом по фронту, - не менее 1,5 м',
          'Между автомобилями, стоящими друг за другом, в глубину - не менее 0,8 м, между автомобилями, стоящими рядом по фронту, - не менее 1,2 м',
          'Между автомобилями, стоящими друг за другом, в глубину - не менее 0,6 м, между автомобилями, стоящими рядом по фронту, - не менее 1,0 м',
          'Между автомобилями, стоящими друг за другом, в глубину - не менее 0,5 м, между автомобилями, стоящими рядом по фронту, - не менее 0,5 м',
        ],
        correctAnswers: [
          'Между автомобилями, стоящими друг за другом, в глубину - не менее 1 м, между автомобилями, стоящими рядом по фронту, - не менее 1,5 м',
        ],
      },
      {
        code: '63713172',
        text:
          'Какие из перечисленных материалов относятся к связным сыпучим материалам?',
        answers: [
          'Материалы, содержащие фракции менее 2 мм и имеющие влажность более 2%',
          'Песок с крупностью зерен до 2 мм и влажностью до 2%',
          'Галька',
          'Щебень',
          'Материалы с крупностью зерен 3 мм',
        ],
        correctAnswers: [
          'Материалы, содержащие фракции менее 2 мм и имеющие влажность более 2%',
        ],
      },
      {
        code: '63713173',
        text:
          'При какой минимальной нагрузке на пол не следует размещать подвалы производственного назначения в зданиях и сооружениях?',
        answers: [
          '100 кПа (10 тс/м²)',
          '80 кПа (8 тс/м²)',
          '150 кПа (15 тс/м²)',
          '50 кПа (5 тс/м²)',
        ],
        correctAnswers: ['100 кПа (10 тс/м²)'],
      },
      {
        code: '63713174',
        text: 'Какие требования к рассеву указаны неверно?',
        answers: [
          'Разрешается в период разбега останавливать рассев, работающий исправно, а также повторно включать рассев до его полной остановки',
          'При пусках и остановках рассевов запрещается находиться в проходе между рассевами',
          'Рассев должен вращаться равномерно без ударов и стуков. При нарушении нормального хода или появлении стуков рассев необходимо немедленно остановить, прекратив подачу продукта',
          'Перед пуском рассева надлежит проверить отсутствие в машине посторонних предметов',
        ],
        correctAnswers: [
          'Разрешается в период разбега останавливать рассев, работающий исправно, а также повторно включать рассев до его полной остановки',
        ],
      },
      {
        code: '63713175',
        text:
          'Каким образом следует осуществлять досмотр порожних вагонов-зерновозов?',
        answers: [
          'Единолично или бригадой в составе не более 2 человек',
          'Посредством их освещения через загрузочные люки аккумуляторными фонарями',
          'Обязательно спустившись внутрь вагона',
          'Бригадой в составе 3 человек',
        ],
        correctAnswers: [
          'Посредством их освещения через загрузочные люки аккумуляторными фонарями',
        ],
      },
      {
        code: '63713176',
        text: 'На какой высоте располагают светильники в помещениях?',
        answers: [
          'На высоте не более 2,0 м',
          'На высоте 1,7 м',
          'На высоте 2,0 м',
          'На высоте не менее 2,5 м',
        ],
        correctAnswers: ['На высоте не менее 2,5 м'],
      },
      {
        code: '63713177',
        text:
          'При каком минимальном повышении содержания бензина в шроте следует немедленно поставить в известность руководство эксплуатирующей организации, открыть двери и люки вагонов для его проветривания?',
        answers: ['0,05%', '0,1%', '0,3%', '0,2%'],
        correctAnswers: ['0,1%'],
      },
      {
        code: '63713178',
        text: 'Какие требования предъявляются к электрическим рубильникам?',
        answers: [
          'Электрические рубильники должны иметь закрытые кожухи',
          'Электрические рубильники должны иметь несгораемый козырек',
          'Электрические рубильники должны иметь степень защиты оболочки шкафа не ниже IP65',
          'Электрические рубильники должны быть заземлены не менее чем в двух местах',
          'Электрические рубильники должны быть окрашены неэлектропроводными красками',
        ],
        correctAnswers: [
          'Электрические рубильники должны иметь закрытые кожухи',
        ],
      },
      {
        code: '63713179',
        text: 'Какой вид клапана устанавливается перед водомаслоотделителем?',
        answers: [
          'Регулирующий клапан',
          'Обратный клапан',
          'Предохранительный клапан',
          'Пружинно-предохранительный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63713180',
        text:
          'Из каких сплавов металлов изготовляются воздуховоды и фасонные детали аспирационных установок?',
        answers: [
          'Из оцинкованной стали',
          'Из стеклопластика',
          'Из нержавеющей стали',
          'Из алюминия',
        ],
        correctAnswers: ['Из оцинкованной стали'],
      },
      {
        code: '63713181',
        text: 'В каком порядке следует производить расчет опускного колодца?',
        answers: [
          'Не регламентируется',
          'Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки',
          'Рассчитывать отдельные конструктивные элементы оболочки, определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки',
          'Рассчитывать отдельные конструктивные элементы оболочки, глубину колодца, определять наружные размеры (диаметр) колодца',
        ],
        correctAnswers: [
          'Определять глубину колодца, наружные размеры (диаметр) колодца, толщину стенок оболочки, рассчитывать отдельные конструктивные элементы оболочки',
        ],
      },
      {
        code: '63713182',
        text:
          'Что из перечисленного должно быть предусмотрено в конструкции сдвоенной нории?',
        answers: [
          'Усиление норийных труб, выполненное по методикам, согласованным с территориальным управлением Федеральной службы по экологическому, технологическому и атомному надзору',
          'Единое устройство натяжения обеих лент',
          'Система локализации взрывов, исключающая распространение высокотемпературных продуктов взрывного горения из одной трубы в другую',
          'Возможность раздельного натяжения каждой ленты',
          'Индивидуальный привод каждой ленты',
        ],
        correctAnswers: ['Возможность раздельного натяжения каждой ленты'],
      },
      {
        code: '63713183',
        text:
          'В каком случае допускается размещать масляные, сухие, а также с негорючей жидкостью комплектные трансформаторные подстанции в общем помещении с распределительными устройствами?',
        answers: [
          'Только при наличии аварийной сигнализации',
          'Только если трансформаторы отделены перегородками',
          'Только если предусмотрена приточно-вытяжная вентиляция',
          'Допускается размещать в любом случае',
          'Ни в каком случае',
        ],
        correctAnswers: ['Допускается размещать в любом случае'],
      },
      {
        code: '63713184',
        text:
          'С помощью какого приспособления осуществляются выемка из станка, перемещение и установка вальцов?',
        answers: [
          'С помощью любых перечисленных приспособлений',
          'С помощью монорельсовых путей',
          'С помощью талей',
          'С помощью крановых и других тележек',
        ],
        correctAnswers: ['С помощью любых перечисленных приспособлений'],
      },
      {
        code: '63713185',
        text:
          'Размещение каких из перечисленных устройств после пылеулавливающих установок не допускается?',
        answers: [
          'Аспирационных пылеосадочных шахт, камер, коробов',
          'Воздуходувных машин и вентиляторов',
          'Искробезопасных вентиляторов',
          'Взрывозащищенных вентиляторов',
          'Вихревых инерционных пылеуловителей на встречных закрученных потоках',
        ],
        correctAnswers: ['Аспирационных пылеосадочных шахт, камер, коробов'],
      },
      {
        code: '63713186',
        text:
          'Какие мероприятия рекомендуется предусматривать перед закладкой на хранение просушенного зерна?',
        answers: [
          'Перемешивать (перекачивать из занимаемых им емкостей в свободные)',
          'Предусматривать минимальное число его перемещений',
          'Пропускать через воздушно-ситовые машины независимо от степени его засоренности',
          'Транспортировать на открытых ленточных конвейерах',
          'Обеспыливать с целью недопущения выхода пыли в помещение',
        ],
        correctAnswers: [
          'Пропускать через воздушно-ситовые машины независимо от степени его засоренности',
        ],
      },
      {
        code: '63713187',
        text:
          'Что необходимо предпринять при отклонениях от нормальной работы оборудования (завал продукта, интенсивное пыление, повышенные вибрации и другие подобные причины)?',
        answers: [
          'Продолжить работу, сообщив ответственному специалисту об отклонениях',
          'Остановить оборудование и незамедлительно покинуть помещение',
          'Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций',
          'Оповестить представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий об отклонениях и продолжить работу',
        ],
        correctAnswers: [
          'Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций',
        ],
      },
      {
        code: '63713188',
        text:
          'Для каких сепараторов проход со стороны выпуска зерна должен быть шириной не менее 0,7 м?',
        answers: [
          'Для всех сепараторов',
          'Только для сепараторов с боковой выемкой решет',
          'Только для сепараторов с круговым вращением решет',
          'Только для сепараторов производительностью до 50 т/ч',
        ],
        correctAnswers: ['Для всех сепараторов'],
      },
      {
        code: '63713189',
        text:
          'С какой периодичностью следует осуществлять контроль целостности мембран, герметизирующих прокладок, подвижности откидных клапанов и поворотных створок комбинированных взрыворазрядителей, отсутствия накоплений на мембранах и в отводящих трубопроводах пыли или продукта?',
        answers: [
          'Один раз в сутки',
          'Один раз в месяц',
          'Один раз в смену',
          'Один раз в квартал',
        ],
        correctAnswers: ['Один раз в месяц'],
      },
      {
        code: '63713190',
        text:
          'Кто должен проверять аспирационную установку после замены оборудования?',
        answers: [
          'Представитель проектной организации',
          'Руководитель эксплуатирующей организации',
          'Инженер по аспирации или другое лицо, назначенное руководителем эксплуатирующей организации',
          'Представитель территориального органа Федеральной службы по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Инженер по аспирации или другое лицо, назначенное руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63713191',
        text:
          'На какой высоте от пола устанавливаются смотровые люки для удобства наблюдения за ходом ленты в норийных трубах?',
        answers: ['1,0 м', '1,6 м', '1,2 м', '0,9 м'],
        correctAnswers: ['1,6 м'],
      },
      {
        code: '63713192',
        text:
          'Какие требования предъявляются к совместному складированию в одном и том же силосе (бункере) различных продуктов?',
        answers: [
          'Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается при условии, что уровень влажности в них не превышает 18%',
          'Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается, если силосы и бункеры оснащены устройствами дистанционного контроля температуры продукта',
          'Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается, если перед засыпкой продуктом емкости были тщательно зачищены, проветрены и просушены',
          'Совместное складирование в одном и том же силосе (бункере) различных продуктов разрешается, если они доведены до параметров, обеспечивающих возможность их хранения',
          'Совместное складирование в одном и том же силосе (бункере) различных продуктов запрещается',
        ],
        correctAnswers: [
          'Совместное складирование в одном и том же силосе (бункере) различных продуктов запрещается',
        ],
      },
      {
        code: '63713193',
        text:
          'Какие из перечисленных расстояний при устройстве подпорных стен соответствуют СП 43.13330.2012. Свод правил. Сооружения промышленных предприятий?',
        answers: [
          'В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м',
          'Высота подпорных стен для грузовых рамп автомобильного транспорта со стороны подъезда автомобилей должна быть равной 1,5 м от уровня поверхности проезжей части дорог или погрузочно-разгрузочной площадки',
          'Минимальное расстояние от оси ближайшего железнодорожного пути до внутренней грани подпорной стены на прямых участках следует принимать не менее 1,5 м',
          'При расположении автодорог вдоль подпорной стены у нее следует предусматривать тротуар шириной не менее 1 м с бортовым камнем высотой не менее 0,6 м',
        ],
        correctAnswers: [
          'В местах, где возможно движение пешеходов, подпорные стены должны иметь ограждение высотой 1 м',
        ],
      },
      {
        code: '63713194',
        text:
          'Что допускается размещать при проектировании многорядных силосных корпусов с круглыми в плане силосами в пространстве между ними (звездочках)?',
        answers: [
          'Лестницы',
          'Установки технологического оборудования, требующего обслуживания',
          'Установки для хранения связных сыпучих материалов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Лестницы'],
      },
      {
        code: '63713195',
        text:
          'Какие требования предъявляются к помещениям, где составляют суспензии и обогатительные смеси, в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'Помещения должны быть изолированы от основных производственных помещений, в нерабочее время запираться на замок, иметь приточно-вытяжную вентиляцию, стены должны быть облицованы глазурованной плиткой',
          'Помещения должны располагаться совместно с основными производственными помещениями, но должны при этом иметь дополнительную охрану, приточно-вытяжную вентиляцию, стены должны быть покрыты металлическими листами',
          'Помещения могут быть не изолированы от основных производственных помещений, стены должны быть отштукатурены и покрыты водоэмульсионной краской, на полы должен быть положен деревянный настил',
        ],
        correctAnswers: [
          'Помещения должны быть изолированы от основных производственных помещений, в нерабочее время запираться на замок, иметь приточно-вытяжную вентиляцию, стены должны быть облицованы глазурованной плиткой',
        ],
      },
      {
        code: '63713196',
        text:
          'Какое из перечисленных действий, которые необходимо произвести в случае загорания зерна в сушилке, указано неверно?',
        answers: [
          'Прекратить подачу сырого зерна в зерносушилку',
          'Все ответы неверны',
          'Выключить все вентиляторы',
          'Прекратить подачу зерна из сушилки в элеватор или на склад',
          'Закрыть задвижки в воздуховоде от топки и сушилки',
        ],
        correctAnswers: ['Прекратить подачу сырого зерна в зерносушилку'],
      },
      {
        code: '63713197',
        text:
          'В какой цвет должны быть окрашены органы управления аварийного выключения оборудования взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'В серый цвет',
          'В красный цвет',
          'В синий цвет',
          'В зеленый цвет',
        ],
        correctAnswers: ['В красный цвет'],
      },
      {
        code: '63713198',
        text:
          'Какие действия необходимо предпринять в случае превышения допустимой температуры заложенного на хранение растительного сырья, продуктов его переработки и комбикормового сырья, указанной для соответствующего вида сырья (продукта) в технологическом регламенте? Выберите все правильные варианты ответов.',
        answers: [
          'Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку',
          'Применить активное вентилирование, сушку',
          'Периодически увлажнять сырье (продукт) до снижения температуры в допустимых пределах',
          'Обеспечить постоянный контакт сырья (продукта) с токопроводящей поверхностью стенки бункера',
        ],
        correctAnswers: [
          'Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку',
          'Применить активное вентилирование, сушку',
        ],
      },
      {
        code: '63713199',
        text:
          'В какой из перечисленных нештатных ситуаций оборудование должно быть остановлено?',
        answers: [
          'При появлении несвойственного шума и вибрации',
          'При завале, подпоре и перегрузке продуктом',
          'При поломках и неисправностях',
          'При попадании в рабочие органы посторонних предметов',
          'При срабатывании датчиков - индикаторов взрыва',
          'Во всех перечисленных ситуациях, а также при неработающей аспирации',
        ],
        correctAnswers: [
          'Во всех перечисленных ситуациях, а также при неработающей аспирации',
        ],
      },
      {
        code: '63713200',
        text:
          'На какие из перечисленных коммуникаций не устанавливаются огнепреграждающие (пламеотсекающие) устройства?',
        answers: [
          'На трубопроводы подачи зерна с головок норий и из дробилок, через которые непосредственно ведется загрузка бункеров (в том числе надвесовых) и силосов',
          'На трубопроводы подачи зерна с головок норий и из дробилок на цепные и закрытые ленточные конвейеры, через которые ведется загрузка бункеров и силосов',
          'На все коммуникации минерального сырья',
          'На трубопроводы подачи зерна с головок норий в надсушильные бункеры встроенных зерносушилок',
        ],
        correctAnswers: ['На все коммуникации минерального сырья'],
      },
      {
        code: '63713201',
        text:
          'Какие из перечисленных устройств могут использоваться в качестве огнепреграждающих (пламеотсекающих) устройств в составе системы локализации взрывов?',
        answers: [
          'Только шлюзовые затворы',
          'Только винтовые конвейеры',
          'Только порционные весы',
          'Все перечисленные, включая другие технические устройства, предотвращающие возможность распространения взрыва',
        ],
        correctAnswers: [
          'Все перечисленные, включая другие технические устройства, предотвращающие возможность распространения взрыва',
        ],
      },
      {
        code: '63713202',
        text:
          'Какие характеристики определяются по расчетным схемам, учитывающим наличие наружных стен и днища опускных колодцев, при строительстве опускных колодцев? Укажите все правильные ответы.',
        answers: [
          'Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)',
          'Всплытие колодца',
          'Погружение колодца',
          'Устойчивость формы цилиндрической оболочки колодцев, погружаемых в тиксотропной рубашке',
        ],
        correctAnswers: [
          'Сдвиг по подошве при односторонней выемке грунта вблизи колодца (если она предусматривается проектом)',
          'Всплытие колодца',
        ],
      },
      {
        code: '63713203',
        text:
          'После какого времени продувки топки вентилятором допускается зажигание топлива при обслуживании топок зерносушилки?',
        answers: [
          'После 3 минут',
          'После 5 минут',
          'После 10 минут',
          'После 15 минут',
        ],
        correctAnswers: ['После 10 минут'],
      },
      {
        code: '63713204',
        text:
          'Кто несет ответственность за выполнение (соблюдение) мероприятий, предусмотренных актом-допуском, оформленным перед началом работ на территории эксплуатирующей объекты организации?',
        answers: [
          'Только руководители строительно-монтажной организации',
          'Только заказчик',
          'Руководители строительно-монтажной организации и заказчик',
          'Генеральный подрядчик',
        ],
        correctAnswers: [
          'Руководители строительно-монтажной организации и заказчик',
        ],
      },
      {
        code: '63713205',
        text:
          'Какие из перечисленных сведений отражаются в технологическом регламенте, разрабатываемом на объекте хранения и переработки растительного сырья? Выберите все правильные варианты ответа.',
        answers: [
          'Описание технологического процесса производства',
          'Движение сырья, готовой продукции в графическом виде',
          'Нормы расхода основных видов сырья',
          'Характеристики производства, используемого сырья и выпускаемой (производимой) продукции',
        ],
        correctAnswers: [
          'Описание технологического процесса производства',
          'Нормы расхода основных видов сырья',
          'Характеристики производства, используемого сырья и выпускаемой (производимой) продукции',
        ],
      },
      {
        code: '63713206',
        text:
          'В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?',
        answers: [
          'Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 10 рабочих дней после внесения изменений в системы управления технологическими процессами на объекте',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'После назначения нового руководителя организации, эксплуатирующей объект',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63713207',
        text:
          'Какие из перечисленных устройств должны входить в комплект серийно изготавливаемых норий, конвейеров?',
        answers: [
          'Только датчики подпора',
          'Только устройства контроля сбегания ленты',
          'Только устройства контроля обрыва цепи',
          'Все перечисленные устройства, включая реле контроля скорости',
        ],
        correctAnswers: [
          'Все перечисленные устройства, включая реле контроля скорости',
        ],
      },
      {
        code: '63713208',
        text:
          'Какой из перечисленных элементов не входит в состав ротационных воздуходувок?',
        answers: [
          'Обратный клапан',
          'Глушитель',
          'Система смазки',
          'Предохранительный клапан',
          'Манометр',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63713209',
        text:
          'Какие светильники допускается использовать внутри металлических и железобетонных емкостей при выключенных разгрузочных механизмах и оборудовании?',
        answers: [
          'Переносные светильники при напряжении в сети 42 В с металлической защитной сеткой стеклянного колпака',
          'Переносные светильники при напряжении в сети не более 24 В',
          'Переносные светильники повышенной надежности против взрыва при напряжении в сети не выше 12 В и металлической защитой стеклянного колпака',
          'Переносные светильники повышенной надежности против взрыва при напряжении в сети не более 127 В',
          'Переносные светильники повышенной надежности против взрыва со степенью защиты оболочки не ниже IP44 и металлической защитой стеклянного колпака',
        ],
        correctAnswers: [
          'Переносные светильники повышенной надежности против взрыва при напряжении в сети не выше 12 В и металлической защитой стеклянного колпака',
        ],
      },
      {
        code: '63713210',
        text:
          'Что из перечисленного не должно соблюдаться при работе пропаривателей, паровых сушилок, запарных и варочных котлов?',
        answers: [
          'Надежная герметизация оборудования, паро- и теплопроводов и их соединений, исключающая проникновение пара в рабочее помещение',
          'Тщательная термоизоляция всех горячих участков машин, аппаратов, паро- и теплопроводов',
          'Исправность предохранительных клапанов контрольных и измерительных приборов (манометров, термометров и т. д.)',
          'Давление пара должно поддерживаться выше контрольной отметки, указанной на шкале манометра',
          'Надежность работы запорных вентилей, шлюзовых затворов на поступлении и выпуске продукции и исправность ограждений приводных ремней, шкивов и цепных передач',
        ],
        correctAnswers: [
          'Давление пара должно поддерживаться выше контрольной отметки, указанной на шкале манометра',
        ],
      },
      {
        code: '63713211',
        text:
          'В каком случае вентиляторы аспирационных систем помещений категории Б допускается устанавливать до пылеуловителей?',
        answers: [
          'При оснащении пылеуловителей взрыворазрядным устройством в соответствии с требованиями нормативных документов',
          'При свободном объеме пылеуловителя менее 0,5 м³',
          'При свободном объеме пылеуловителя менее 0,25 м³',
          'При условии обеспечения взрывозащиты электрического и неэлектрического оборудования вентиляторов в соответствии с требованиями Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
        correctAnswers: [
          'При условии обеспечения взрывозащиты электрического и неэлектрического оборудования вентиляторов в соответствии с требованиями Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
      },
      {
        code: '63713212',
        text:
          'При каком способе закрепления строительных конструкций и технологического оборудования на фундаментах не требуется соответствующее обоснование?',
        answers: [
          'На анкерных болтах',
          'На виброгасителях или на клею',
          'Сваркой',
          'Все ответы неверны',
        ],
        correctAnswers: ['На анкерных болтах'],
      },
      {
        code: '63713213',
        text:
          'Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору',
          'Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Руководителями муниципальных образований, на территории которых расположен объект',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63713214',
        text:
          'Какой вентиляцией должны быть оборудованы помещения, где размещены пропариватели, сушилки, запарные и варочные аппараты?',
        answers: [
          'Естественной вентиляцией',
          'Общеобменной вентиляцией',
          'Вытяжной вентиляцией',
          'Приточной вентиляцией',
        ],
        correctAnswers: ['Приточной вентиляцией'],
      },
      {
        code: '63713215',
        text:
          'Что в соответствии с СП 108.13330.2012 определяется как "саморазгружающееся емкостное сооружение с высотой вертикальной части, не превышающей полуторную величину диаметра или меньшего размера"?',
        answers: ['Бункер', 'Силос', 'Башня', 'Элеватор'],
        correctAnswers: ['Силос'],
      },
      {
        code: '63713216',
        text:
          'Какое мероприятие из перечисленных не исключает причины, приводящие к самосогреванию растительного сырья?',
        answers: [
          'Контроль температуры продукта в силосах (бункерах, складах)',
          'Контроль влажности продукта в силосах (бункерах, складах)',
          'Недопущение вентилирования и перемещения продукта из силоса в силос',
          'Контроль газовой среды в свободных объемах силосов (бункеров) переносными или стационарными газоанализаторами',
          'Вентилирование и перемещение продукта из силоса в силос (в случаях обоснованной необходимости)',
          'Контроль зараженности зерна вредителями',
        ],
        correctAnswers: [
          'Недопущение вентилирования и перемещения продукта из силоса в силос',
        ],
      },
      {
        code: '63713217',
        text:
          'Какой следует принимать коэффициент надежности по нагрузке для давления сыпучих материалов на стены и днища силосов, бункеров и зерноскладов при расчете на прочность?',
        answers: ['1,3', '1,1', '1,2', '1,5'],
        correctAnswers: ['1,3'],
      },
      {
        code: '63713218',
        text:
          'Какую наименьшую высоту компактной струи на уровне наивысшей точки следует принимать для тушения пожара рабочего здания элеватора высотой свыше 50 м от гидрантов с помощью насосов при расчетном расходе воды 5 л/с?',
        answers: ['10 м', '15 м', '5 м', '20 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63713219',
        text:
          'В каком случае допускается размещать предприятия, здания и сооружения по хранению и переработке зерна в санитарно-защитной зоне предприятий, относимых по выделению производственных вредностей в окружающую среду к I и II классу?',
        answers: [
          'Только при соответствующем обосновании в проектной документации',
          'Только при согласовании с территориальным органом Федеральной службы по экологическому, технологическому и атомному надзору',
          'Не допускается ни в каком случае',
          'Допускается в любом случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63713220',
        text:
          'При помощи чего должен производиться спуск работников в силосы и бункеры (для хранения зерна, муки, отрубей, комбикормов и других продуктов)?',
        answers: [
          'Устройств, предназначенных для проведения работ на высоте',
          'Веревочных лестниц',
          'Стремянок высотой более 5 м',
        ],
        correctAnswers: [
          'Устройств, предназначенных для проведения работ на высоте',
        ],
      },
      {
        code: '63713221',
        text:
          'Как следует защищать матерчатые фильтры, на которых осаждается мука, от накопления зарядов статического электричества?',
        answers: [
          'Прошивать медной проволокой и соединять ее с системой заземления',
          'Только заземлять',
          'Выполнять окантовку фильтра из электропроводной резины',
          'Прошивать алюминиевой проволокой',
        ],
        correctAnswers: [
          'Прошивать медной проволокой и соединять ее с системой заземления',
        ],
      },
      {
        code: '63713222',
        text:
          'На какие объекты распространяются Федеральные нормы и правила в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'На приемно-отпускные устройства для приема и отпуска растительного сырья и продуктов его переработки с железнодорожного, автомобильного и водного транспорта, рабочие здания, силосные корпуса элеваторов, склады силосного типа, склады напольные для бестарного хранения и галереи для перемещения растительного сырья и продуктов его переработки',
          'На цехи, отделения, блочно-модульные и агрегатные установки по производству муки, крупы, солода, комбикормов и кормовых смесей, семяобрабатывающие и кукурузообрабатывающие производства',
          'На подготовительные, подработочные, дробильные отделения по очистке, измельчению солода, зерна, шелушению маслосемян, отделения для растаривания, взвешивания, просеивания муки, размола сахарного песка, зерносушильные установки, приемно-очистительные и сушильно-очистительные башни, цехи отходов, пыли, очистки и сортировки мешкотары',
          'На опасные производственные объекты, на которых осуществляются хранение и (или) переработка растительного сырья, в процессе которых образуются взрывоопасные пылевоздушные смеси, способные самовозгораться, возгораться от источника зажигания и самостоятельно гореть после его удаления, а также осуществляется хранение зерна, продуктов его переработки и комбикормового сырья, склонных к самосогреванию и самовозгоранию',
        ],
        correctAnswers: [
          'На опасные производственные объекты, на которых осуществляются хранение и (или) переработка растительного сырья, в процессе которых образуются взрывоопасные пылевоздушные смеси, способные самовозгораться, возгораться от источника зажигания и самостоятельно гореть после его удаления, а также осуществляется хранение зерна, продуктов его переработки и комбикормового сырья, склонных к самосогреванию и самовозгоранию',
        ],
      },
      {
        code: '63713223',
        text:
          'На какой коэффициент умножается расчетная нагрузка от веса сыпучих материалов при расчете на сжатие нижней зоны стен силосов?',
        answers: ['0,9', '0,3', '0,5', '0,7'],
        correctAnswers: ['0,9'],
      },
      {
        code: '63713224',
        text:
          'При каком условии должен производиться доступ рабочих в силосы и бункеры через нижний люк для производства огневых работ?',
        answers: [
          'Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением',
          'Доступ рабочих в силосы и бункеры через нижний люк запрещается',
          'Только в присутствии главного инженера эксплуатирующей организации и представителя территориального органа Федеральной службы по экологическому, технологическому и атомному надзору',
          'Только при наличии разрешения территориального органа Федеральной службы по экологическому, технологическому и атомному надзору и в присутствии наблюдающего',
        ],
        correctAnswers: [
          'Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением',
        ],
      },
      {
        code: '63713225',
        text:
          'Какую минимальную площадь легкосбрасываемых конструкций следует принимать при отсутствии расчетных данных на 1 м³ взрывоопасного помещения?',
        answers: ['0,04 м²', '0,03 м²', '0,25 м²', '0,3 м²'],
        correctAnswers: ['0,03 м²'],
      },
      {
        code: '63713226',
        text:
          'Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?',
        answers: [
          'Техническим регламентом',
          'Проектом',
          'Правилами безопасности производства хлора',
          'Заключением метрологической лаборатории',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63713227',
        text:
          'От каких штепсельных соединений должны отличаться штепсельные соединения (розетки, вилки) по своему конструктивному исполнению?',
        answers: [
          'Штепсельные соединения, применяемые для напряжения 42 В, от штепсельных соединений, предназначенных для напряжения 12 В',
          'Штепсельные соединения, применяемые на напряжение 12 В, от штепсельных соединений, предназначенных для напряжения 42 В',
          'Штепсельные соединения, применяемые на напряжение 42 В, от штепсельных соединений, предназначенных для напряжения 127 В и 220 В',
          'Штепсельные соединения, применяемые на напряжение 12 В, от штепсельных соединений, предназначенных для напряжения 36 В',
        ],
        correctAnswers: [
          'Штепсельные соединения, применяемые на напряжение 42 В, от штепсельных соединений, предназначенных для напряжения 127 В и 220 В',
        ],
      },
      {
        code: '63713228',
        text:
          'Для каких водонапорных башен высота опор от уровня земли до верха опоры бака устанавливается кратной 3 м?',
        answers: [
          'Для башен с баками вместимостью 100 м³',
          'Для башен с баками вместимостью от 15 до 50 м³',
          'Для башен с баками вместимостью до 10 м³',
          'Для башен с баками вместимостью до 5 м³',
        ],
        correctAnswers: ['Для башен с баками вместимостью от 15 до 50 м³'],
      },
      {
        code: '63713229',
        text:
          'Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации при техническом перевооружении и реконструкции объектов?',
        answers: ['Категории А', 'Категории Г', 'Категории Б'],
        correctAnswers: ['Категории Г'],
      },
      {
        code: '63713230',
        text: 'В какие сроки проверяют температуру сырья?',
        answers: [
          'В сроки, установленные для сырья, предназначенного для длительного хранения',
          'В сроки, установленные для свежеубранного сырья',
          'В сроки, установленные для каждого вида сырья в зависимости от состояния влажности',
          '1 раз в 3 дня при температуре сырья 10 °С и ниже',
          '1 раз в 7 дней при температуре сырья выше 10 °С',
        ],
        correctAnswers: [
          'В сроки, установленные для каждого вида сырья в зависимости от состояния влажности',
        ],
      },
      {
        code: '63713231',
        text:
          'Какое требование по оснащению производственных помещений лифтами указано неверно?',
        answers: [
          'В производственных зданиях предусматривается пассажирский лифт для постоянно работающих людей на этажах, расположенных выше 15 м от уровня входа в здание',
          'Тамбур-шлюзы могут устраиваться общими для двух помещений (при условии, что в помещении категории Б имеется второй эвакуационный выход)',
          'Грузовой лифт в производственных зданиях следует предусматривать при наличии требований технологии производства',
          'В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента',
        ],
        correctAnswers: [
          'В производственных зданиях высотой более 15 м один из лифтов должен быть рассчитан на перевозку пожарных подразделений и отвечать требованиям регламента',
        ],
      },
      {
        code: '63713232',
        text:
          'В каком из перечисленных случаев допускается объединение в одну установку аспирации оперативных емкостей и оборудования?',
        answers: [
          'При обеспыливании потенциально опасного оборудования (норий, дробилок, вальцовых станков и других машин ударного действия) и бункеров',
          'При обеспыливании потенциально опасного оборудования и другого оборудования бункерного типа (гравитационных смесителей, весов и т. п.)',
          'При обеспыливании потенциально опасного оборудования и бункеров, имеющих свободный объем менее 0,01 объема производственного помещения',
          'При обеспыливании потенциально опасного оборудования и силосов',
          'При обеспыливании оперативных емкостей с оборудованием, в котором отсутствуют вращающиеся детали',
        ],
        correctAnswers: [
          'При обеспыливании оперативных емкостей с оборудованием, в котором отсутствуют вращающиеся детали',
        ],
      },
      {
        code: '63713233',
        text:
          'Какой инструктаж проводится при выполнении разовых работ, работ по локализации и ликвидации последствий аварий, стихийных бедствий и работ, на которые оформляются наряд-допуск, разрешение или другие специальные документы?',
        answers: [
          'Повторный инструктаж',
          'Внеплановый инструктаж',
          'Целевой инструктаж',
          'Вводный инструктаж',
        ],
        correctAnswers: ['Целевой инструктаж'],
      },
      {
        code: '63713234',
        text:
          'Какими фланцами рекомендуется соединять между собой звенья воздуховодов аспирационных установок диаметром до 280 мм?',
        answers: [
          'Из цветных металлов',
          'Из полосовой стали',
          'Из угловой стали',
          'Из сталеалюминевых сплавов',
        ],
        correctAnswers: ['Из полосовой стали'],
      },
      {
        code: '63713235',
        text:
          'Какие из перечисленных вариантов размещения и прокладки оборудования допускаются на объектах хранения и переработки растительного сырья?',
        answers: [
          'Прокладка транзитных воздуховодов через помещения складов сырья и готовой продукции',
          'Прокладка транзитных воздуховодов через помещения разных категорий',
          'Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием',
          'Размещение вентиляторов и пылеуловителей отдельно стоящих зерносушилок в рабочих зданиях элеваторов',
        ],
        correctAnswers: [
          'Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием',
        ],
      },
      {
        code: '63713236',
        text:
          'После выполнения каких действий допускается включение электромагнитного сепаратора под напряжение? Укажите все правильные ответы.',
        answers: [
          'После визуального осмотра ремонтной бригадой',
          'После измерения сопротивления изоляции обмоток электромагнитов, которое должно быть менее 0,5 МОм',
          'После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок',
          'После испытания изоляции',
        ],
        correctAnswers: [
          'После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок',
          'После испытания изоляции',
        ],
      },
      {
        code: '63713237',
        text:
          'Что из перечисленного не должно применяться для очистки магнитных колонок от металломагнитных примесей?',
        answers: [
          'Специальные щетки',
          'Струя воды под сильным напором',
          'Деревянные скребки',
        ],
        correctAnswers: ['Струя воды под сильным напором'],
      },
      {
        code: '63713238',
        text:
          'Что из перечисленного должно быть установлено между вентилятором и присоединяемыми к нему трубами?',
        answers: [
          'Гибкие патрубки (вставки) из недиэлектрических материалов',
          'Гибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 100 мм',
          'Негибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 50 мм',
          'Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)',
          'Металлическая сетка с размерами ячеек 20 х 20 мм',
        ],
        correctAnswers: [
          'Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)',
        ],
      },
      {
        code: '63713239',
        text:
          'Какой уровень влажности не должна превышать влажность кукурузы и проса при их длительном хранении (более года)?',
        answers: ['14,5%', '13%', '12%', '12,5%', '15%'],
        correctAnswers: ['12%'],
      },
      {
        code: '63713240',
        text:
          'Норийные трубы каких норий должны быть рассчитаны на внутреннее остаточное давление пылевоздушного взрыва?',
        answers: [
          'Норий транспортировки минерального сырья',
          'Норий, установленных в рабочих зданиях элеваторов',
          'Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт',
          'Норий высотой более 20 м вне зависимости от места установки',
        ],
        correctAnswers: [
          'Норий (кроме норий минерального сырья), проходящих внутри силосов, бункеров и шахт',
        ],
      },
      {
        code: '63713241',
        text:
          'Какие требования к проектированию складских зданий зерноскладов указаны неверно?',
        answers: [
          'Площадь зданий зерноскладов между противопожарными стенами следует принимать по нормативным документам по пожарной безопасности, но не более 5000 м2',
          'Противокапиллярную гидроизоляцию несущих стен зданий зерноскладов следует предусматривать из цементного раствора состава 1 : 2 толщиной 20 мм',
          'Вынос кровли (за наружную поверхность стен) для зерноскладов должен быть не менее 0,7 м',
          'Полы в складских зданиях следует проектировать, как правило, асфальтобетонными с толщиной покрытия 25 мм в зерноскладах и 50 мм - в складах тарных грузов',
          'Проекты зерноскладов должны содержать указания о нанесении на стены ярких линий и надписей, ограничивающих предельную высоту зерновой насыпи',
          'В покрытиях полов не допускается применение дегтей и дегтевых мастик',
        ],
        correctAnswers: [
          'Площадь зданий зерноскладов между противопожарными стенами следует принимать по нормативным документам по пожарной безопасности, но не более 5000 м2',
        ],
      },
      {
        code: '63713242',
        text:
          'Электроустановки с каким напряжением питания запрещается использовать внутри силосов и бункеров, других емкостей и сушилок?',
        answers: [
          'С напряжением питания более 42 В',
          'Внутри силосов и бункеров, других емкостей и сушилок запрещается использование любых электроустановок',
          'С напряжением питания 127 В',
        ],
        correctAnswers: ['С напряжением питания более 42 В'],
      },
      {
        code: '63713243',
        text:
          'С какой периодичностью необходимо очищать и промывать содовым раствором трубу между компрессором и ресивером?',
        answers: [
          'Каждый месяц',
          'Каждые 6 месяцев',
          'Каждые 3 месяца',
          'Каждые 10 дней',
        ],
        correctAnswers: ['Каждые 6 месяцев'],
      },
      {
        code: '63713244',
        text: 'В какой таре допускается транспортирование горючих веществ?',
        answers: [
          'В металлических бочках',
          'В бутылях',
          'В бидонах',
          'В ведрах',
        ],
        correctAnswers: ['В металлических бочках'],
      },
    ],
    63715: [
      {
        code: '63715000',
        text:
          'В каком случае допускается подтягивание болтовых соединений, устранение неисправностей на движущихся частях оборудования без его полной остановки?',
        answers: [
          'Если обеспечена безопасность подхода к местам неисправностей',
          'Если осуществляется дистанционное управление',
          'Если обеспечен надзор во время проведения работ',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63715001',
        text:
          'Что из перечисленного должно быть установлено между вентилятором и присоединяемыми к нему трубами?',
        answers: [
          'Гибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 100 мм',
          'Гибкие патрубки (вставки) из недиэлектрических материалов',
          'Металлическая сетка с размерами ячеек 20 х 20 мм',
          'Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)',
          'Негибкие патрубки (вставки), обвитые снаружи проволокой с шагом витков не более 50 мм',
        ],
        correctAnswers: [
          'Гибкие патрубки (вставки) из воздухонепроницаемого материала (резины, прорезиненной ткани, синтетической ткани с полимерным или иным, схожим по характеристикам, покрытием)',
        ],
      },
      {
        code: '63715002',
        text:
          'В каком случае допускается размещать масляные, сухие, а также с негорючей жидкостью комплектные трансформаторные подстанции в общем помещении с распределительными устройствами?',
        answers: [
          'Только если установлена аварийная свето-звуковая сигнализация',
          'Допускается размещать в любом случае',
          'Только если предусмотрена приточно-вытяжная вентиляция',
          'Только если трансформаторы отделены перегородками',
        ],
        correctAnswers: ['Допускается размещать в любом случае'],
      },
      {
        code: '63715003',
        text:
          'С какой периодичностью пыль и другие относы должны выводиться из пылеуловителей?',
        answers: [
          'Ежедневно',
          'Ежесменно',
          'Ежемесячно',
          'Непрерывно',
          'Еженедельно',
        ],
        correctAnswers: ['Непрерывно'],
      },
      {
        code: '63715004',
        text:
          'С каким опережением должны включаться в работу аспирационные установки, сблокированные с технологическим и транспортным оборудованием?',
        answers: [
          'С опережением на 25 - 30 секунд включения технологического и транспортного оборудования',
          'С опережением на 5 - 10 секунд включения технологического и транспортного оборудования',
          'С опережением на 10 - 15 секунд включения технологического и транспортного оборудования',
          'С опережением на 15 - 20 секунд включения технологического и транспортного оборудования',
        ],
        correctAnswers: [
          'С опережением на 15 - 20 секунд включения технологического и транспортного оборудования',
        ],
      },
      {
        code: '63715005',
        text:
          'Что должны иметь дверцы, смотровые лючки и выпускные устройства оборудования?',
        answers: [
          'Устройства, исключающие их случайное снятие или открывание',
          'Уплотнения, не пропускающие пыль',
          'Концевые выключатели',
          'Рукоятки, скобы и другие устройства для удобного и безопасного удержания их при снятии и установке',
        ],
        correctAnswers: ['Уплотнения, не пропускающие пыль'],
      },
      {
        code: '63715006',
        text:
          'Где устанавливаются взрыворазрядители для взрывозащиты зерносушилок?',
        answers: [
          'Только на каскадных нагревателях',
          'Только на камерах нагрева',
          'Только на надсушильных бункерах: на верхней крышке или на боковой стенке в верхней части бункера',
          'На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки',
        ],
        correctAnswers: [
          'На камерах нагрева, подогревателях, каскадных нагревателях, осадочных камерах, топках, надсушильных бункерах и на нориях, обслуживающих зерносушилки',
        ],
      },
      {
        code: '63715007',
        text:
          'Чем должна быть обоснована протяженность горизонтальных участков воздуховодов?',
        answers: [
          'Климатическими условиями',
          'Проектными решениями',
          'Характеристиками оборудования',
          'Сейсмической активностью',
        ],
        correctAnswers: ['Проектными решениями'],
      },
      {
        code: '63715008',
        text:
          'Кто несет ответственность за выполнение (соблюдение) мероприятий, предусмотренных актом-допуском, оформленным перед началом работ на территории эксплуатирующей объекты организации?',
        answers: [
          'Только заказчик',
          'Только руководители строительно-монтажной организации',
          'Руководители строительно-монтажной организации и заказчик',
          'Генеральный подрядчик',
        ],
        correctAnswers: [
          'Руководители строительно-монтажной организации и заказчик',
        ],
      },
      {
        code: '63715009',
        text:
          'В помещениях какой категории допускается не предусматривать приточно-вытяжную или вытяжную вентиляцию для приямков, предназначенных для обслуживания норий и цепных конвейеров?',
        answers: [
          'В помещениях категории А',
          'В помещениях категории Б',
          'В помещениях категории Д',
          'В помещениях категории Г',
        ],
        correctAnswers: ['В помещениях категории Б'],
      },
      {
        code: '63715010',
        text:
          'Что из перечисленного не допускается в шахтах для прокладки кабелей?',
        answers: [
          'Установка норий',
          'Проход самотечных труб',
          'Проход аспирационных воздуховодов',
          'Все перечисленное не допускается',
        ],
        correctAnswers: ['Все перечисленное не допускается'],
      },
      {
        code: '63715011',
        text:
          'Каким образом следует производить очистку шлюзового затвора от продукта, налипшего на стенки крыльчатки?',
        answers: [
          'Струей воды',
          'Путем продувки его воздухом',
          'Специальными скребками',
          'Специальными щетками',
          'Путем пропарки',
        ],
        correctAnswers: ['Путем продувки его воздухом'],
      },
      {
        code: '63715012',
        text:
          'На каком расстоянии от корпуса затворов размещаются люки в самотеках, по которым поступает и выводится продукт из шлюзовых затворов?',
        answers: [
          'Не менее 300 мм',
          'Не менее 250 мм',
          'Не менее 350 мм',
          'Не менее 400 мм',
        ],
        correctAnswers: ['Не менее 250 мм'],
      },
      {
        code: '63715013',
        text: 'В каком случае разрешается пуск охладителя?',
        answers: [
          'После проверки герметичности',
          'Только при работающем вентиляторе и шлюзовом затворе',
          'После проверки готовности всасывающей линии',
        ],
        correctAnswers: [
          'Только при работающем вентиляторе и шлюзовом затворе',
        ],
      },
      {
        code: '63715014',
        text:
          'Какое требование предъявляется к взрыворазрядителям после их установки?',
        answers: [
          'Все взрыворазрядители пломбируются, а шиберные и бандажные нумеруются',
          'Все взрыворазрядители пломбируются, а шиберные нумеруются',
          'Все взрыворазрядители нумеруются, а шиберные и бандажные пломбируются',
        ],
        correctAnswers: [
          'Все взрыворазрядители нумеруются, а шиберные и бандажные пломбируются',
        ],
      },
      {
        code: '63715015',
        text:
          'Какое оборудование может быть расположено сторонами, не требующими обслуживания, у стен и колонн с разрывом от них не менее 0,25 м? Укажите все правильные ответы.',
        answers: [
          'Самотечный трубопровод',
          'Материало- и воздухопроводы',
          'Машины для улавливания магнитных примесей',
          'Норийные трубы',
        ],
        correctAnswers: [
          'Самотечный трубопровод',
          'Материало- и воздухопроводы',
          'Норийные трубы',
        ],
      },
      {
        code: '63715016',
        text:
          'При нагревании до какой максимальной температуры деталей, соприкасающихся с продукцией, допускается работа электромагнитных сепараторов?',
        answers: ['До 30 °C', 'До 50 °C', 'До 65 °C', 'До 55 °C'],
        correctAnswers: ['До 50 °C'],
      },
      {
        code: '63715017',
        text:
          'Какие из перечисленных расстояний при размещении оборудования объектов хранения и переработки растительного сырья указаны верно? Укажите все правильные ответы.',
        answers: [
          'Проходы у весового карусельного устройства для фасовки и упаковки продукции со всех сторон должны быть шириной не менее 2 м',
          'В топочных помещениях стационарных зерносушилок с топками, работающими на твердом топливе, проходы со стороны зольников должны быть не менее 1 м, а проход перед топкой - не менее 2,2 м',
          'Для создания условий обслуживания при ремонте от привода шнека гранулятора до стены должен быть проход шириной не менее 1,7 м и от охладителя со стороны привода разгрузочного устройства до стены - не менее 1,6 м',
          'При использовании жидкого или газообразного топлива расстояние от выступающих частей форсунок, газовых горелок или арматуры топок до стен или других частей здания, а также до оборудования на действующих зерносушилках должно быть не менее 1,2 м',
        ],
        correctAnswers: [
          'Проходы у весового карусельного устройства для фасовки и упаковки продукции со всех сторон должны быть шириной не менее 2 м',
          'Для создания условий обслуживания при ремонте от привода шнека гранулятора до стены должен быть проход шириной не менее 1,7 м и от охладителя со стороны привода разгрузочного устройства до стены - не менее 1,6 м',
        ],
      },
      {
        code: '63715018',
        text:
          'Что определяется внутренними распорядительными документами организации, эксплуатирующей взрывопожароопасные производственные объекты хранения и переработки растительного сырья?',
        answers: [
          'Только организация работ по поддержанию надежного и безопасного уровня эксплуатации и ремонта оборудования, средств дистанционного автоматизированного управления, блокировки',
          'Только организация работ по поддержанию надежного и безопасного уровня контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях, средств связи, энергообеспечения, а также зданий и сооружений',
          'Только перечень и объем эксплуатационной, ремонтной и другой технической документации',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63715019',
        text:
          'Кто должен дать разрешение для проведения ремонтных работ в помещениях действующего производства?',
        answers: [
          'Только главный инженер организации',
          'Только технический руководитель объекта',
          'Представитель территориального органа Федеральной службы по экологическому, технологическому и атомному надзору',
          'Руководитель или должностное лицо эксплуатирующей организации, ответственные за промышленную безопасность (технический руководитель, главный инженер)',
        ],
        correctAnswers: [
          'Руководитель или должностное лицо эксплуатирующей организации, ответственные за промышленную безопасность (технический руководитель, главный инженер)',
        ],
      },
      {
        code: '63715020',
        text:
          'Какое требование к эксплуатации оборудования на объектах хранения и переработки растительного сырья указано неверно?',
        answers: [
          'Оборудование должно быть отрегулировано',
          'Оборудование должно постоянно находиться в исправном состоянии',
          'При обеспечении оборудования противоаварийными устройствами разработка мер, направленных на предотвращение образования в нем источников зажигания, может не выполняться',
          'Для оборудования устанавливается назначенный срок службы с учетом конкретных условий эксплуатации и конструктивных особенностей',
        ],
        correctAnswers: [
          'При обеспечении оборудования противоаварийными устройствами разработка мер, направленных на предотвращение образования в нем источников зажигания, может не выполняться',
        ],
      },
      {
        code: '63715021',
        text:
          'Допускается ли эксплуатация неисправных и утративших целостность взрыворазрядителей и их конструктивных элементов?',
        answers: [
          'Допускается при согласовании с территориальным органом Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Допускается только в светлое время суток',
          'Допускается только в темное время суток',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63715022',
        text:
          'При расположении на какой высоте от пола осей приводных барабанов необходимо предусматривать специальные площадки с перилами высотой не менее 1 м с зашивкой внизу на 0,15 м с обеспечением проходов для обслуживания головок норий?',
        answers: ['Более 0,5 м', 'Более 1,0 м', 'Более 1,5 м', 'Более 1,2 м'],
        correctAnswers: ['Более 1,5 м'],
      },
      {
        code: '63715023',
        text:
          'На каком оборудовании из перечисленного устанавливают взрыворазрядители?',
        answers: [
          'На нориях со свободным объемом менее 0,25 м3',
          'На дробилках с объемом рабочей зоны и выпускного (поддробильного) бункера менее 0,3 м3',
          'На дробилках подачи и измельчения минерального сырья',
          'На фильтр-циклонах со свободным объемом более 0,5 м3',
        ],
        correctAnswers: [
          'На фильтр-циклонах со свободным объемом более 0,5 м3',
        ],
      },
      {
        code: '63715024',
        text:
          'Где должны устанавливаться взрыворазрядители на подогревателях для взрывозащиты зерносушилок?',
        answers: [
          'На минимальном расстоянии от входа агента сушки',
          'На боковой стенке секций только в нижней части подогревателя',
          'На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки',
          'На боковой стенке секций подогревателя со стороны входа агента сушки',
        ],
        correctAnswers: [
          'На боковой стенке секций подогревателя со стороны, противоположной входу агента сушки',
        ],
      },
      {
        code: '63715025',
        text:
          'Каким должно быть сопротивление изоляции обмоток электромагнитов?',
        answers: ['Не менее 0,1 МОм', 'Не менее 0,3 МОм', 'Не менее 0,5 МОм'],
        correctAnswers: ['Не менее 0,5 МОм'],
      },
      {
        code: '63715026',
        text:
          'Кем определяется рациональный выбор средств ДАУ, блокировки, контроля и противоаварийной защиты?',
        answers: [
          'Производителями этих средств',
          'Наладчиками этих средств',
          'Ростехнадзором',
          'Разработчиками этих средств',
        ],
        correctAnswers: ['Разработчиками этих средств'],
      },
      {
        code: '63715027',
        text:
          'Кем утверждается наряд-допуск на производство работ повышенной опасности?',
        answers: [
          'Руководителем подразделения, где выполняются работы',
          'Должностным лицом эксплуатирующей организации, ответственным за промышленную безопасность (технический руководитель, главный инженер)',
          'Руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Должностным лицом эксплуатирующей организации, ответственным за промышленную безопасность (технический руководитель, главный инженер)',
        ],
      },
      {
        code: '63715028',
        text:
          'Какие должны быть ограждения, расположенные внутри производственных зданий, площадок, антресолей, приямков, на которых размещено технологическое оборудование?',
        answers: [
          'Стальные сплошные ограждения высотой 1 м',
          'Стальные решетчатые ограждения высотой 1,5 м, при этом ограждения должны быть сплошными на высоту не менее 0,25 м от пола',
          'Стальные решетчатые ограждения высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола',
          'Дюралюминиевые сплошные ограждения высотой 1,5 м',
        ],
        correctAnswers: [
          'Стальные решетчатые ограждения высотой 1 м, при этом ограждения должны быть сплошными на высоту не менее 0,15 м от пола',
        ],
      },
      {
        code: '63715029',
        text:
          'Работа какого перечисленного оборудования допускается при открытых крышках?',
        answers: [
          'Смесителей',
          'Шнеков',
          'Цепных конвейеров',
          'Все ответы неверны',
          'Аэрожелобов',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715030',
        text:
          'Чем тушат горящий продукт в подсилосном этаже в целях предотвращения возможного образования пылевого облака при выходе продукта из силоса?',
        answers: [
          'Распыленной водой',
          'Углекислой пеной',
          'Азотом',
          'Направленной струей воды',
          'Паром',
        ],
        correctAnswers: ['Распыленной водой'],
      },
      {
        code: '63715031',
        text:
          'Какая устанавливается максимальная длина каждого отводящего трубопровода от защищаемого оборудования до коллектора?',
        answers: ['3 м', '6 м', '5 м', '1,5 м'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63715032',
        text:
          'Какие действия необходимо предпринять в случае превышения допустимой температуры заложенного на хранение растительного сырья, продуктов его переработки и комбикормового сырья, указанной для соответствующего вида сырья (продукта) в технологическом регламенте? Выберите все правильные варианты ответов.',
        answers: [
          'Периодически увлажнять сырье (продукт) до снижения температуры в допустимых пределах',
          'Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку',
          'Обеспечить постоянный контакт сырья (продукта) с токопроводящей поверхностью стенки бункера',
          'Применить активное вентилирование, сушку',
        ],
        correctAnswers: [
          'Производить перекачку сырья (продукта) из одного силоса (бункера) в другой или с площадки на площадку',
          'Применить активное вентилирование, сушку',
        ],
      },
      {
        code: '63715033',
        text:
          'Какая часть конвейера должна ограждаться на высоту не менее 2 м от пола?',
        answers: [
          'Муфты',
          'Шкивы',
          'Концы валов',
          'Опорные ролики',
          'Грузы вертикальных натяжных станций',
        ],
        correctAnswers: ['Грузы вертикальных натяжных станций'],
      },
      {
        code: '63715034',
        text:
          'Какие требования, предъявляемые к оборудованию взрывопожароопасных производственных объектов хранения или переработки растительного сырья, не соответствуют Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'Оборудование должно постоянно находиться в исправном состоянии',
          'Оборудование должно быть установлено в металлических защитных кожухах',
          'Оборудование должно быть отрегулировано',
          'Оборудование должно работать без несвойственного ему шума, вибрации и повышенного трения движущихся частей',
        ],
        correctAnswers: [
          'Оборудование должно быть установлено в металлических защитных кожухах',
        ],
      },
      {
        code: '63715035',
        text:
          'На каких конвейерах должны быть предусмотрены устройства, предохраняющие конвейеры от переполнения короба продуктом?',
        answers: [
          'На закрытых ленточных конвейерах',
          'На цепных и винтовых конвейерах',
          'Только на винтовых конвейерах',
          'Только на цепных конвейерах',
        ],
        correctAnswers: ['На цепных и винтовых конвейерах'],
      },
      {
        code: '63715036',
        text:
          'Кем составляется перечень контролируемых параметров, определяющих взрывоопасность процесса в каждом конкретном случае?',
        answers: [
          'Комиссией эксплуатирующей организации',
          'Разработчиком процесса',
          'Организацией, осуществляющей экспертизу промышленной безопасности',
          'Техническим руководителем эксплуатирующей организации',
          'Организацией - изготовителем оборудования',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63715037',
        text:
          'В каких нештатных ситуациях оборудование взрывопожароопасных производственных объектов хранения или переработки растительного сырья должно останавливаться?',
        answers: [
          'При появлении несвойственного шума и вибрации',
          'При завале, подпоре и перегрузке продуктом',
          'При поломках и неисправностях',
          'При попадании в рабочие органы посторонних предметов',
          'Во всех перечисленных ситуациях, включая неработающую аспирацию',
          'При срабатывании датчиков-индикаторов взрыва',
        ],
        correctAnswers: [
          'Во всех перечисленных ситуациях, включая неработающую аспирацию',
        ],
      },
      {
        code: '63715038',
        text:
          'Что из перечисленного допускается при эксплуатации технологического оборудования?',
        answers: [
          'Снимать или надевать приводные ремни на ходу',
          'Регулировать натяжение ремней или цепей машин во время их работы',
          'Все перечисленное запрещено',
          'Расчищать от завалов, запрессованного продукта или от попавших предметов конвейеры, башмаки норий и другие машины во время их работы',
        ],
        correctAnswers: ['Все перечисленное запрещено'],
      },
      {
        code: '63715039',
        text:
          'Что не учитывается при определении диаметров (площадей) проходных сечений взрыворазрядителей для оборудования?',
        answers: [
          'Величина защищаемого объема',
          'Допустимое давление взрыва, статическое давление вскрытия мембраны (клапана)',
          'Форма проходного сечения',
          'Длина, количество и величина углов поворота отводящих трубопроводов',
          'Температура и влажность в месте установки оборудования',
        ],
        correctAnswers: [
          'Температура и влажность в месте установки оборудования',
        ],
      },
      {
        code: '63715040',
        text:
          'Какой должна быть минимальная площадь оконного стекла толщиной 5 мм, используемого в качестве легкосбрасываемой конструкции?',
        answers: ['1,5 м²', '1,25 м²', '0,9 м²', '1,3 м²'],
        correctAnswers: ['1,5 м²'],
      },
      {
        code: '63715041',
        text:
          'Как следует защищать матерчатые фильтры, на которых осаждается мука, от накопления зарядов статического электричества?',
        answers: [
          'Заземлять',
          'Выполнять окантовку фильтра из электропроводной резины',
          'Прошивать алюминиевой проволокой',
          'Прошивать медной проволокой и соединять ее с системой заземления',
        ],
        correctAnswers: [
          'Прошивать медной проволокой и соединять ее с системой заземления',
        ],
      },
      {
        code: '63715042',
        text:
          'В соответствии с чем должен осуществляться выбор оборудования на объектах хранения и переработки растительного сырья?',
        answers: [
          'Только в соответствии с исходными данными на проектирование',
          'Только в соответствии с требованиями нормативных правовых актов в области промышленной безопасности',
          'Только в соответствии с правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63715043',
        text:
          'Под непосредственным наблюдением какого лица должны осуществляться работы на высоте, а также по подъему и перемещению оборудования и других тяжеловесных грузов?',
        answers: [
          'Бригадира',
          'Специально назначенного работника, не занятого ведение технологического режима',
          'Работника, ответственного за соблюдение мер безопасности',
        ],
        correctAnswers: [
          'Работника, ответственного за соблюдение мер безопасности',
        ],
      },
      {
        code: '63715044',
        text:
          'Какая ширина проходов должна быть между отдельными машинами и станками, применяемыми на объектах хранения и переработки растительного сырья?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 1,5 м',
          'Не менее 1,2 м',
          'Не менее 1 м',
          'Не менее 1,8 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63715045',
        text:
          'Что разрабатывает организация при наличии в технологическом оборудовании (силосных и (или) бункерных емкостях) опасных веществ (газов) или возможности их образования?',
        answers: [
          'Дополнительные правила ведения технологических процессов',
          'Мероприятия по дальнейшей безопасной эксплуатации объектов',
          'Меры защиты персонала от воздействия этих веществ при взрывах, пожарах и других авариях',
          'Меры по поддержанию надежного и безопасного уровня контроля и противоаварийной защиты, производственной и аварийной сигнализации',
        ],
        correctAnswers: [
          'Меры защиты персонала от воздействия этих веществ при взрывах, пожарах и других авариях',
        ],
      },
      {
        code: '63715046',
        text:
          'В каком случае необходимо пользоваться специальными скребками и щетками при обслуживании машин? Укажите все правильные ответы.',
        answers: [
          'При расчистке завалов в башмаках норий и конвейерах',
          'При прочистке зазора между заслонкой и питающим валком вальцового станка',
          'При очистке на холостом ходу верхних плоскостей решет сепараторов',
        ],
        correctAnswers: [],
      },
      {
        code: '63715047',
        text:
          'Кто осуществляет контроль за исправностью инструмента, находящегося в работе?',
        answers: [
          'Руководитель подразделения',
          'Руководитель (производитель) работ',
          'Непосредственно работники',
          'Мастер ремонтного цеха или лицо, назначенное главным инженером',
        ],
        correctAnswers: [
          'Мастер ремонтного цеха или лицо, назначенное главным инженером',
        ],
      },
      {
        code: '63715048',
        text:
          'Каким образом следует осуществлять досмотр порожних вагонов-зерновозов?',
        answers: [
          'Необходимо обязательно спускаться внутрь вагона',
          'Единолично или бригадой в составе не более двух человек',
          'Посредством их освещения через загрузочные люки аккумуляторными фонарями',
        ],
        correctAnswers: [
          'Посредством их освещения через загрузочные люки аккумуляторными фонарями',
        ],
      },
      {
        code: '63715049',
        text:
          'Какое требование к лазовым и загрузочным люкам силосов, бункеров указано неверно?',
        answers: [
          'Лазовые и загрузочные люки силосов, бункеров и других устройств, независимо от мест их расположения, помимо крышек должны иметь прочные металлические решетки с ячейками размером не более 250 x 75 мм',
          'Люки силосов, бункеров в перекрытиях производственных помещений должны закрываться крышками в уровень с полом',
          'Лазовые люки должны быть круглого сечения диаметром не менее 500 мм',
          'Углубление решеток люков более 60 мм от поверхности пола помещения должно быть обосновано',
        ],
        correctAnswers: [
          'Лазовые люки должны быть круглого сечения диаметром не менее 500 мм',
        ],
      },
      {
        code: '63715050',
        text:
          'Какое действие необходимо произвести в первую очередь при появлении стуков или других признаков неисправности в сепараторе?',
        answers: [
          'Оповестить ответственного за безопасное производство работ',
          'Вызвать ремонтную бригаду',
          'Немедленно остановить',
          'Попытаться устранить неполадки',
        ],
        correctAnswers: ['Немедленно остановить'],
      },
      {
        code: '63715051',
        text:
          'Какое из перечисленных требований к взрывопожароопасным производственным объектам хранения и переработки растительного сырья указано неверно?',
        answers: [
          'Устройство железнодорожных путей, переездов и переходов через них, а также организация и эксплуатация железнодорожного хозяйства организации должны соответствовать требованиям нормативных правовых актов в сфере транспорта',
          'К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель',
          'Доступ на территорию организации, эксплуатирующей объекты, посторонним лицам запрещен',
          'Необходимые для производственных целей подземные резервуары, колодцы, пожарные водоемы должны быть закрыты или ограждены со всех сторон',
          'В организации, эксплуатирующей объекты, должно быть обеспечено исправное состояние отводов атмосферных осадков от зданий и сооружений к водостокам, дорог для транспорта, пожарных проездов, рельсовых путей (с соблюдением требуемых габаритов, допускаемых уклонов и радиусов закруглений), сетей наружного освещения, пешеходных дорожек, пожарных и хозяйственных водопроводов, а также ограждений территории объектов и организации',
        ],
        correctAnswers: [
          'К зданиям, сооружениям и строениям объектов должен быть обеспечен подъезд пожарных автомобилей, пожарные водоемы в ночное время должны иметь звуковой указатель',
        ],
      },
      {
        code: '63715052',
        text:
          'Каким документом определяется порядок (очередность) проведения обследования объекта перед разработкой технического паспорта взрывобезопасности?',
        answers: [
          'Документами проектной организации',
          'Внутренними распорядительными документами эксплуатирующей объект организации',
          'Приказом Ростехнадзора',
          'Актом',
        ],
        correctAnswers: [
          'Внутренними распорядительными документами эксплуатирующей объект организации',
        ],
      },
      {
        code: '63715053',
        text:
          'В каком случае допускается применение на объекте средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, связи, оповещения об аварийных ситуациях, отработавших назначенный срок службы?',
        answers: [
          'Не допускается ни в каком случае',
          'В случае упрощения технологического процесса',
          'В случае разрешения их применения разработчиком процесса',
          'В случае установления контроля, объем которого должен обеспечить их надежную работу',
          'В случае проведения работ в особых климатических условиях',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63715054',
        text:
          'В каком случае допускается временное размещение семян подсолнечника в силосах элеваторов и складах силосного типа, оборудованных устройствами автоматизированного дистанционного контроля температуры?',
        answers: [
          'При влажности не более 15%',
          'При влажности не более 10%',
          'При влажности от 8 до 15%',
          'При влажности не более 8%',
        ],
        correctAnswers: ['При влажности не более 8%'],
      },
      {
        code: '63715055',
        text:
          'Какие из перечисленных средств ДАУ, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации не должны предусматриваться (определяться на стадиях разработки процесса) на объектах?',
        answers: [
          'Контроль за работой норий',
          'Дистанционный контроль за верхним и нижним уровнями сырья и продуктов в силосах и бункерах',
          'Аварийная остановка всех электродвигателей цеха с любого этажа и пульта управления (диспетчерской)',
          'В тех случаях, когда в дробилках отсутствует устройство для автоматического регулирования загрузки, это устройство должно быть сблокировано с электродвигателем дробилки',
        ],
        correctAnswers: [
          'В тех случаях, когда в дробилках отсутствует устройство для автоматического регулирования загрузки, это устройство должно быть сблокировано с электродвигателем дробилки',
        ],
      },
      {
        code: '63715056',
        text:
          'Какое из перечисленных действий разрешается при производстве работ в силосах и бункерах?',
        answers: [
          'Хождение по насыпи зерна или других продуктов хранения',
          'Нахождение людей под силосом или бункером при разрушении сводов и зависших масс зерна или других продуктов',
          'Доступ в силосы и бункеры через нижний люк',
          'Нахождение людей, не участвующих в разрушении сводов или зависших масс, в зоне лазовых и загрузочных люков',
        ],
        correctAnswers: ['Доступ в силосы и бункеры через нижний люк'],
      },
      {
        code: '63715057',
        text:
          'После чего производятся работы по ремонту оборудования взрывопожароопасных производственных объектов хранения или переработки растительного сырья?',
        answers: [
          'Только после полной его остановки',
          'Только после отключения напряжения',
          'Только после снятия приводных ремней и обеспечения необходимых мер взрывопожаробезопасности',
          'Только после полной его остановки, при выключенном напряжении, снятых приводных ремнях и обеспечения необходимых мер взрывопожаробезопасности',
        ],
        correctAnswers: [
          'Только после полной его остановки, при выключенном напряжении, снятых приводных ремнях и обеспечения необходимых мер взрывопожаробезопасности',
        ],
      },
      {
        code: '63715058',
        text:
          'Какие требования предъявляются к зубчатым передачам, являющимся источником опасности, согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'Зубчатые передачи должны быть закрыты глухими кожухами, прочно прикрепленными к станине или другой неподвижной части машины',
          'Зубчатые передачи должны размещаться внутри машины',
          'Зубчатые передачи должны заключаться в прочные и надежно укрепленные ограждения',
          'Зубчатые передачи должны иметь съемные, откидные или раздвижные ограждения',
        ],
        correctAnswers: [
          'Зубчатые передачи должны быть закрыты глухими кожухами, прочно прикрепленными к станине или другой неподвижной части машины',
        ],
      },
      {
        code: '63715059',
        text:
          'Что из перечисленного не отражается в технологическом регламенте?',
        answers: [
          'Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции',
          'Сведения о возможных инцидентах в работе и способах их ликвидации',
          'Движение сырья, готовой продукции',
          'Перечень обязательных инструкций, спецификации основного технологического оборудования (технических устройств), технологические схемы производства (графическая часть)',
          'Нормы расхода основных видов сырья',
          'Информация о контроле и управлении технологическим процессом',
        ],
        correctAnswers: ['Движение сырья, готовой продукции'],
      },
      {
        code: '63715060',
        text:
          'Какие узлы оборудования (шкивы, рабочие колеса вентиляторов со шкивами, щеточные и бичевые барабаны, вальцы со шкивами и шестернями, валы дисковых триеров с дисками, барабаны шлифовальных и полировочных машин) должны быть статически отбалансированы как в собранном виде, так и отдельными частями?',
        answers: [
          'Со скоростью вращения выше 2 м/с',
          'Со скоростью вращения выше 3 м/с',
          'Все узлы независимо от скорости вращения',
          'Со скоростью вращения выше 5 м/с',
        ],
        correctAnswers: ['Со скоростью вращения выше 5 м/с'],
      },
      {
        code: '63715061',
        text:
          'Что устанавливают перед предохранительной мембраной взрыворазрядителя при систематических нарушениях целостности мембран вследствие разрежения внутри оборудования или значительных пульсаций давления?',
        answers: [
          'Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм',
          'Защитную сетку из капрона толщиной 1 мм с ячейками размером 20 x 20 мм',
          'Защитную сетку из базальтового волокна толщиной 1 мм с ячейками размером 50 x 50 мм',
        ],
        correctAnswers: [
          'Защитную сетку из проволоки толщиной 1 - 2 мм с ячейками размером 30 x 30 мм',
        ],
      },
      {
        code: '63715062',
        text:
          'Что является критерием эффективности магнитной защиты при приеме сырья с автомобильного, водного и железнодорожного транспорта?',
        answers: [
          'Категория электроснабжения',
          'Система блокировок и противоаварийной защиты',
          'Заземление',
          'Полное извлечение металломагнитных примесей, способных инициировать искру',
        ],
        correctAnswers: [
          'Полное извлечение металломагнитных примесей, способных инициировать искру',
        ],
      },
      {
        code: '63715063',
        text:
          'Что из перечисленного не относится к организационным и организационно-техническим мероприятиям, которые должны выполняться для обеспечения взрывобезопасности объекта?',
        answers: [
          'Обучение, инструктаж и проверка уровня знаний работников объектов',
          'Обеспечение работников средствами индивидуальной защиты',
          'Производственный контроль за соблюдением требований безопасности',
          'Разработка нормативно-технических документов и наглядной агитации',
          'Устройство путей эвакуации и вынос бытовых помещений из производственных зданий',
          'Применение средств оповещения об аварийных ситуациях',
        ],
        correctAnswers: [
          'Устройство путей эвакуации и вынос бытовых помещений из производственных зданий',
        ],
      },
      {
        code: '63715064',
        text:
          'Какое из перечисленных действий, которые необходимо произвести в случае загорания зерна в сушилке, указано неверно?',
        answers: [
          'Прекратить подачу зерна из сушилки в элеватор или склад',
          'Закрыть задвижки в воздуховоде от топки и сушилки',
          'Прекратить подачу сырого зерна в зерносушилку',
          'Выключить все вентиляторы',
        ],
        correctAnswers: ['Прекратить подачу сырого зерна в зерносушилку'],
      },
      {
        code: '63715065',
        text:
          'С какой периодичностью необходимо очищать и промывать содовым раствором трубу между компрессором и ресивером?',
        answers: [
          'Каждый год',
          'Каждые 6 месяцев',
          'Каждые 3 месяца',
          'Каждый месяц',
          'Каждые 10 дней',
        ],
        correctAnswers: ['Каждые 6 месяцев'],
      },
      {
        code: '63715066',
        text:
          'Какое углубление решеток люков силосов, бункеров и других устройств от поверхности пола помещения должно быть обосновано?',
        answers: ['До 40 мм', 'До 55 мм', 'До 60 мм', 'Более 60 мм'],
        correctAnswers: ['Более 60 мм'],
      },
      {
        code: '63715067',
        text:
          'Что из перечисленного необходимо предусматривать на цепных конвейерах?',
        answers: [
          'Установку независимых реле контроля скорости',
          'Установку устройства контроля схода цепи',
          'Установку взрыворазрядного устройства',
          'Установку устройства контроля обрыва цепи',
        ],
        correctAnswers: ['Установку устройства контроля обрыва цепи'],
      },
      {
        code: '63715068',
        text: 'Какой допустимый предел зазора между вальцами?',
        answers: [
          'От 0,1 до 0,9 мм',
          'От 0,1 до 1,0 мм',
          'От 0,05 до 1,25 мм',
          'От 0,15 до 2,5 мм',
        ],
        correctAnswers: ['От 0,1 до 1,0 мм'],
      },
      {
        code: '63715069',
        text:
          'Чего не должны допускать форма и расположение переходных патрубков для присоединения взрыворазрядителей при нормальном режиме работы оборудования?',
        answers: [
          'Только механического повреждения предохранительной мембраны из-за попадания на нее продукта',
          'Только накопления в них пыли',
          'Всего перечисленного',
          'Только накопления в них продукта',
        ],
        correctAnswers: ['Всего перечисленного'],
      },
      {
        code: '63715070',
        text:
          'Какое устройство должны иметь загрузочные воронки дробилок для предотвращения попадания посторонних предметов?',
        answers: [
          'Заслонку',
          'Проволочную сетку',
          'Защитную решетку',
          'Запорную арматуру',
        ],
        correctAnswers: ['Защитную решетку'],
      },
      {
        code: '63715071',
        text:
          'На площадках с каким уклоном допускается проведение погрузочно-разгрузочных работ регулярного перемещения передвижных транспортных механизмов (конвейеры, самоподаватели, электропогрузчики)?',
        answers: ['Не более 10°', 'Не более 6°', 'Не более 3°', 'Не более 8°'],
        correctAnswers: ['Не более 3°'],
      },
      {
        code: '63715072',
        text:
          'Какой перепад температуры входного и измельченного продукта должна обеспечивать конструкция устройства охлаждения вальцов с водяным охлаждением?',
        answers: [
          'Не более 25 °С',
          'Не более 18 °С',
          'Не более 15 °С',
          'Не более 12 °С',
        ],
        correctAnswers: ['Не более 12 °С'],
      },
      {
        code: '63715073',
        text:
          'В каких случаях могут применяться Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'Только при разработке технологических процессов, разработке документации, эксплуатации, капитальном ремонте, техническом перевооружении, консервации и ликвидации объектов',
          'Только при изготовлении, монтаже, наладке, обслуживании и ремонте технических устройств, применяемых на объектах',
          'Только при разработке обоснования безопасности объектов',
          'Только при проведении экспертизы промышленной безопасности',
          'Во всех перечисленных случаях',
          'Только при подготовке и переподготовке работников объектов в необразовательных учреждениях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63715074',
        text:
          'В каком случае необходимо устанавливать крюки для подвески талей, блоков над съемными деталями оборудования?',
        answers: [
          'Во всех случаях вне зависимости от массы',
          'Если съемные детали оборудования массой более 35 кг',
          'Если съемные детали оборудования массой более 50 кг',
          'Если съемные детали оборудования массой более 40 кг',
        ],
        correctAnswers: ['Если съемные детали оборудования массой более 50 кг'],
      },
      {
        code: '63715075',
        text:
          'Что из перечисленного допускается при эксплуатации оборудования?',
        answers: [
          'Подтягивание болтовых соединений, устранение неисправностей на движущихся частях выполнять до полной остановки оборудования',
          'Cнимать или надевать приводные ремни на ходу, регулировать натяжение ремней или цепей машин во время их работы',
          'Все ответы неверны',
          'Расчищать от завалов, запрессованного продукта или от попавших предметов конвейеры, башмаки норий и другие машины во время их работы',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715076',
        text:
          'Какое из перечисленных требований к электростатической искробезопасности технических устройств объектов хранения и переработки растительного сырья указано верно?',
        answers: [
          'Запрещается использование общего заземляющего устройства для защиты от статического электричества, первичных и вторичных воздействий молнии и защитного заземления электроустановок',
          'Фланцевые соединения на трубах, аппаратах, соединения крышек с корпусами, соединения на разбортовке не требуют дополнительных устройств для создания непрерывной электрической цепи, например, установки перемычек',
          'Во фланцевых соединениях допускается применение шайб, изготовленных из диэлектриков',
          'Во фланцевых соединениях допускается применение шайб, окрашенных неэлектропроводными красками',
        ],
        correctAnswers: [
          'Фланцевые соединения на трубах, аппаратах, соединения крышек с корпусами, соединения на разбортовке не требуют дополнительных устройств для создания непрерывной электрической цепи, например, установки перемычек',
        ],
      },
      {
        code: '63715077',
        text:
          'Чем должны быть оборудованы нории, установленные снаружи зданий?',
        answers: [
          'Площадками с перилами высотой не менее 0,8 м и лестницами с поручнями высотой подъема не более 5 м и уклоном маршей 65°',
          'Площадками с перилами высотой не менее 1 м и лестницами с поручнями высотой подъема не более 6 м и уклоном маршей 60°',
          'Площадками с перилами высотой не менее 1,5 м и лестницами с поручнями высотой подъема не более 5 м и уклоном маршей 55°',
          'Площадками с перилами высотой не менее 1,2 м и лестницами с поручнями высотой подъема не более 7 м и уклоном маршей 60°',
        ],
        correctAnswers: [
          'Площадками с перилами высотой не менее 1 м и лестницами с поручнями высотой подъема не более 6 м и уклоном маршей 60°',
        ],
      },
      {
        code: '63715078',
        text:
          'Какой должна быть толщина стенки норийных труб сварного круглого сечения при отсутствии расчетных данных для защиты норий от разрушения?',
        answers: [
          'Не менее 1 мм',
          'Не менее 1,5 мм',
          'Не менее 1,7 мм',
          'Не менее 2 мм',
        ],
        correctAnswers: ['Не менее 2 мм'],
      },
      {
        code: '63715079',
        text:
          'В помещениях какой категории запрещается складирование мешков с сырьем или готовой продукцией, пустых мешков или других горючих материалов, если это не связано с необходимостью ведения технологического процесса?',
        answers: [
          'В помещениях категории Д',
          'В помещениях категории Б',
          'В помещениях категории А',
          'В помещениях категории Г',
        ],
        correctAnswers: ['В помещениях категории Б'],
      },
      {
        code: '63715080',
        text:
          'Чему должна быть равна площадь легкосбрасываемых конструкций при отсутствии расчетных данных?',
        answers: [
          'Не менее 0,07 м² на 1,0 м³ объема помещения категории А и не менее 0,09 м² на 1,0 м³ объема помещения категории Б',
          'Не менее 0,05 м² на 1,0 м³ объема помещения категории А и не менее 0,03 м² на 1,0 м³ объема помещения категории Б',
          'Не менее 0,02 м² на 1,0 м³ объема помещения категории А и не менее 0,04 м² на 1,0 м³ объема помещения категории Б',
        ],
        correctAnswers: [
          'Не менее 0,05 м² на 1,0 м³ объема помещения категории А и не менее 0,03 м² на 1,0 м³ объема помещения категории Б',
        ],
      },
      {
        code: '63715081',
        text:
          'Какое минимальное количество металлических стяжных болтов, установленных под ступенями, должны иметь переносные деревянные лестницы и раздвижные лестницы-стремянки длиной более 3 м?',
        answers: ['Один', 'Три', 'Два', 'Пять'],
        correctAnswers: ['Два'],
      },
      {
        code: '63715082',
        text:
          'Какие из перечисленных требований предъявляются к вальцовым станкам?',
        answers: [
          'Должны иметь регулируемый зазор между вальцами в пределах от 1,0 до 3,0 мм',
          'Должны иметь световую сигнализацию холостого хода',
          'Должны иметь постоянный нерегулируемый зазор между вальцами',
          'Должны иметь звуковую сигнализацию холостого хода',
          'Должны иметь устройство останова при отсутствии продукта',
        ],
        correctAnswers: ['Должны иметь световую сигнализацию холостого хода'],
      },
      {
        code: '63715083',
        text:
          'Перед какими из перечисленных машин должны быть установлены магнитная защита и устройства отбора посторонних предметов?',
        answers: [
          'Перед пальцевыми и штифтовыми измельчителями',
          'Перед обоечными машинами',
          'Перед дробилками',
          'Перед всеми перечисленными машинами',
        ],
        correctAnswers: ['Перед всеми перечисленными машинами'],
      },
      {
        code: '63715084',
        text:
          'На какой высоте от пола устанавливаются смотровые люки для удобства наблюдения за ходом ленты в норийных трубах?',
        answers: ['1,2 м', '1,6 м', '1,4 м', '1,9 м'],
        correctAnswers: ['1,6 м'],
      },
      {
        code: '63715085',
        text:
          'Кто проводит проверку знания действий персонала в предаварийных и аварийных ситуациях?',
        answers: [
          'Представитель Ростехнадзора',
          'Квалификационная (экзаменационная) комиссия эксплуатирующей организации',
          'Непосредственный руководитель, начальник подразделения, производства',
          'Лицо, назначенное руководителем организации за общее руководство объектом в предаварийных и аварийных ситуациях',
        ],
        correctAnswers: [
          'Квалификационная (экзаменационная) комиссия эксплуатирующей организации',
        ],
      },
      {
        code: '63715086',
        text:
          'В соответствии с чем составляются паспорта аспирационных и пневмотранспортных установок?',
        answers: [
          'В соответствии с проектом',
          'В соответствии с результатами испытаний',
          'В соответствии с внутренними распорядительными документами эксплуатирующей объект организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с внутренними распорядительными документами эксплуатирующей объект организации',
        ],
      },
      {
        code: '63715087',
        text:
          'Каким образом следует распределять взрыворазрядители на камерах нагрева при установке нескольких взрыворазрядителей?',
        answers: [
          'Равномерно по длине камеры',
          'Равномерно по высоте камеры',
          'Равномерно по ширине камеры',
          'В соответствии с указаниями лица, ответственного за безопасное производство работ',
          'Не регламентируется',
        ],
        correctAnswers: ['Равномерно по высоте камеры'],
      },
      {
        code: '63715088',
        text:
          'Какой вентиляцией должны быть оборудованы помещения, где размещены пропариватели, сушилки, запарные и варочные аппараты?',
        answers: [
          'Общеобменной вентиляцией',
          'Естественной вентиляцией',
          'Вытяжной вентиляцией',
          'Приточной вентиляцией',
          'Аварийной вентиляцией',
        ],
        correctAnswers: ['Приточной вентиляцией'],
      },
      {
        code: '63715089',
        text:
          'Какие из перечисленных сведений отражаются в технологическом регламенте, разрабатываемом на объекте хранения и переработки растительного сырья? Выберите все правильные варианты ответа.',
        answers: [
          'Описание технологического процесса производства',
          'Нормы расхода основных видов сырья',
          'Графики ремонтов и реконструкций',
          'Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции',
        ],
        correctAnswers: [
          'Описание технологического процесса производства',
          'Нормы расхода основных видов сырья',
          'Характеристики производства, используемого в производстве сырья и выпускаемой (производимой) продукции',
        ],
      },
      {
        code: '63715090',
        text:
          'Какие требования безопасности при работе вальцедекового станка указаны неверно?',
        answers: [
          'Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности',
          'Необходимо следить за уравновешенным ходом барабана вальцедекового станка. При возникновении стуков или неравномерного хода станок должен быть немедленно остановлен',
          'На выходе продуктов из зоны шелушения вальцедекового станка следует устанавливать отражатель, препятствующий разбрасыванию продукта',
          'Абразивные части вальцедекового станка (барабан и дека) должны быть прочными, не иметь выбоин и трещин и должны быть прочно скреплены с чугунными основаниями. Запрещается допускать в эксплуатацию валки и деки, имеющие трещины',
        ],
        correctAnswers: [
          'Разрешается подхватывать руками посторонние предметы, попавшие в рабочую зону станка (между вальцом и декой), до полной остановки станка при соблюдении дополнительных мер безопасности',
        ],
      },
      {
        code: '63715091',
        text:
          'В каком случае допускается эксплуатация оборудования и осуществление (ведение) технологических процессов с неисправными или отключенными средствами, обеспечивающими противоаварийную защиту объекта?',
        answers: [
          'Только в случае чрезвычайной ситуации',
          'Только в случае если присутствует наблюдающий',
          'Только с случае если получено разрешение ответственного за безопасное выполнение работ',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63715092',
        text:
          'Что из перечисленного необходимо проверить перед пуском смесителя?',
        answers: [
          'Только затяжку болтов всех уплотняющих устройств',
          'Необходимо проверить все перечисленное',
          'Только наличие смазки в редукторе и маслораспределителе',
          'Только срабатывание конечных выключателей, фиксирующих плотность закрывания нижней крышки и ее открывания',
        ],
        correctAnswers: ['Необходимо проверить все перечисленное'],
      },
      {
        code: '63715093',
        text:
          'Что из перечисленного не отражается в технологической схеме производства графически?',
        answers: [
          'Технологическое, аспирационное, транспортное оборудование с указанием моделей и их основных характеристик',
          'Движение сырья, готовой продукции',
          'Сведения о возможных инцидентах в работе и способах их ликвидации',
          'Средства взрывопредупреждения, взрывозащиты и другие технические средства, обеспечивающие блокировку, контроль и противоаварийную защиту',
        ],
        correctAnswers: [
          'Сведения о возможных инцидентах в работе и способах их ликвидации',
        ],
      },
      {
        code: '63715094',
        text:
          'На кого возлагается ответственность за техническое состояние, эксплуатацию и своевременный ремонт взрыворазрядителей?',
        answers: [
          'На должностное лицо, назначенное руководителем эксплуатирующей организации',
          'На технического руководителя эксплуатирующей организации',
          'На должностное лицо организации-изготовителя',
          'На представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: [
          'На должностное лицо, назначенное руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63715095',
        text:
          'В каком случае ширина проходов для обслуживания конвейеров может быть 0,7 м?',
        answers: [
          'Между цепными конвейерами',
          'Между параллельно установленными конвейерами',
          'Между ленточными конвейерами',
          'Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями',
        ],
        correctAnswers: [
          'Между параллельно установленными конвейерами, закрытыми по всей трассе жесткими коробами или сетчатыми ограждениями',
        ],
      },
      {
        code: '63715096',
        text:
          'Как должны быть заземлены технологическое оборудование и продуктопроводы, расположенные во взрыво- и пожароопасных зонах всех классов?',
        answers: [
          'Не менее чем в четырех местах',
          'В одном месте',
          'Не менее чем в трех местах с выравниванием потенциалов до безопасных значений',
          'Не менее чем в двух местах с выравниванием потенциалов до безопасных значений',
        ],
        correctAnswers: [
          'Не менее чем в двух местах с выравниванием потенциалов до безопасных значений',
        ],
      },
      {
        code: '63715097',
        text:
          'В помещения каких классов вводы кабелей и труб электропроводки из взрывоопасных зон всех классов не должны быть плотно заделаны несгораемыми материалами? Выберите 2 варианта ответа.',
        answers: [
          'В помещения классов В-Iа, В-Iб и В-IIа',
          'В помещения пожароопасных зон РУ, ТП',
          'В помещения классов В-II',
          'В помещения классов В-I',
        ],
        correctAnswers: ['В помещения классов В-II', 'В помещения классов В-I'],
      },
      {
        code: '63715098',
        text:
          'Какое из перечисленных требований при работе с ручным немеханизированным инструментом указано неверно? Выберите 2 варианта ответа.',
        answers: [
          'Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях',
          'При запрессовке и распрессовке деталей (подшипники, втулки) с помощью кувалды и выколотки последнюю следует держать клещами или специальным захватом',
          'Напильники, ножовки, стамески, долота и другие ручные инструменты должны быть прочно закреплены в деревянной рукоятке с наложенным на нее стальным кольцом',
          'Выколотка должна быть из твердого металла',
          'Не допускается удаление ключей с помощью труб и других предметов, завертывание гайки ключом больших размеров',
        ],
        correctAnswers: [
          'Губки гаечных ключей должны быть закатаны. Разводные ключи должны быть ослаблены в своих подвижных частях',
          'Выколотка должна быть из твердого металла',
        ],
      },
      {
        code: '63715099',
        text:
          'Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации при техническом перевооружении и реконструкции объектов?',
        answers: [
          'Категории А',
          'Категории Б',
          'Категории В1',
          'Допускается размещение помещений всех перечисленных категорий',
        ],
        correctAnswers: ['Категории В1'],
      },
      {
        code: '63715100',
        text:
          'По разрешению какого лица должно выдаваться сварочное оборудование?',
        answers: [
          'Лица, выдавшего наряд-допуск',
          'Главного инженера (или лица, его замещающего)',
          'Лица, ответственного за безопасное производство работ',
        ],
        correctAnswers: ['Главного инженера (или лица, его замещающего)'],
      },
      {
        code: '63715101',
        text:
          'При каком условии должен производиться доступ рабочих в силосы и бункеры через нижний люк для производства огневых работ?',
        answers: [
          'Только при наличии разрешения территориального органа Федеральной службы по экологическому, технологическому и атомному надзору и в присутствии наблюдающего',
          'Только в присутствии главного инженера эксплуатирующей организации и представителя территориального органа Федеральной службы по экологическому, технологическому и атомному надзору',
          'Доступ рабочих в силосы и бункеры через нижний люк запрещен',
          'Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением',
        ],
        correctAnswers: [
          'Только при наличии наряда-допуска, оформленного в порядке, определенном Правилами противопожарного режима в Российской Федерации, утвержденными постановлением Правительства Российской Федерации от 16 сентября 2020 г. N 1479, и разрешения ответственного руководителя работ и под его наблюдением',
        ],
      },
      {
        code: '63715102',
        text:
          'На каком минимальном расстоянии от сушилки допускается хранение топлива и смазочных материалов?',
        answers: ['35 м', '10 м', '5 м', '20 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63715103',
        text:
          'По указанию какого лица с момента поступления информации об аварийной ситуации производятся выключение или включение электроэнергии, обеспечиваются бесперебойное действие связи, исправное состояние водопровода, бесперебойная работа необходимого электромеханического оборудования и подвижных транспортных средств?',
        answers: [
          'Главного энергетика',
          'Ответственного руководителя работ по локализации и ликвидации последствий аварии',
          'Главного технолога',
          'Главного механика',
          'Технического руководителя организации',
        ],
        correctAnswers: [
          'Ответственного руководителя работ по локализации и ликвидации последствий аварии',
        ],
      },
      {
        code: '63715104',
        text: 'Какие работы допускается производить на ходу падди-машин?',
        answers: [
          'Все ответы неверны',
          'Устранять неравномерность хода',
          'Передвигать параллели при сработке ползуна',
          'Регулировать корпуса падди-машин, подвешенных на качалках',
          'Производить смазку',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715105',
        text: 'Какой вид клапана устанавливается перед водомаслоотделителем?',
        answers: [
          'Запорный клапан',
          'Регулирующий клапан',
          'Пружинно-предохранительный клапан',
          'Обратный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63715106',
        text:
          'Через какие промежутки следует предусматривать установку кнопок «Стоп» вдоль подсилосных и надсилосных, нижних и верхних конвейеров, складов?',
        answers: [
          'Через каждые 15 м',
          'Через каждые 10 м',
          'Через каждые 5 м',
          'Через каждые 20 м',
        ],
        correctAnswers: ['Через каждые 10 м'],
      },
      {
        code: '63715107',
        text:
          'Кем должны подтверждаться эффективность и надежность технических средств блокировки, контроля и противоаварийной защиты объекта хранения и переработки растительного сырья испытанием промышленных образцов оборудования на взрывозащищенность?',
        answers: [
          'Организацией-изготовителем',
          'Проектной организацией',
          'Подрядной организацией',
          'Эксплуатирующей организацией',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Организацией-изготовителем'],
      },
      {
        code: '63715108',
        text:
          'Какое из перечисленных требований допускается при эксплуатации грануляторов? Выберите 2 варианта ответа.',
        answers: [
          'Пользоваться подъемником для замены матриц',
          'Для замены матриц пользоваться грейфером',
          'Регулирование зазоров между роликами и матрицей производить при остановленном грануляторе',
          'Заменять предохранительные штифты металлическим стержнем',
        ],
        correctAnswers: [
          'Пользоваться подъемником для замены матриц',
          'Регулирование зазоров между роликами и матрицей производить при остановленном грануляторе',
        ],
      },
      {
        code: '63715109',
        text:
          'В какой цвет должны быть окрашены органы управления аварийного выключения оборудования взрывопожароопасных производственных объектов хранения или переработки растительного сырья?',
        answers: [
          'В черный цвет',
          'В желтый цвет',
          'В красный цвет',
          'В белый цвет',
          'В зеленый цвет',
        ],
        correctAnswers: ['В красный цвет'],
      },
      {
        code: '63715110',
        text:
          'Какую степень защиты оболочки должны иметь применяемые во взрыво- и пожароопасных зонах ручные и переносные аппараты и приборы?',
        answers: [
          'Не ниже IP65',
          'Не ниже IP22',
          'Не ниже IP68',
          'Не ниже IP54',
        ],
        correctAnswers: ['Не ниже IP54'],
      },
      {
        code: '63715111',
        text:
          'Каким из перечисленных способов должно производиться сращивание концов приводных ремней оборудования взрывопожароопасных производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'Путем склеивания или сыромятными сшивками',
          'Всеми перечисленными способами',
          'При помощи стальных соединителей',
          'При помощи медных или алюминиевых соединителей',
          'Путем горячей вулканизации',
        ],
        correctAnswers: ['Путем склеивания или сыромятными сшивками'],
      },
      {
        code: '63715112',
        text:
          'Что является основным средством защиты от опасных проявлений статического электричества согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'Влажная уборка',
          'Ионизаторы воздуха',
          'Заземление',
          'Напольные покрытия из электропроводящих материалов',
        ],
        correctAnswers: ['Заземление'],
      },
      {
        code: '63715113',
        text:
          'Какая допускается мощность стационарно установленных электрообогревателей для обогревания рабочих в помещениях (кабинах), расположенных в рабочих зданиях элеваторов и неотапливаемых складах?',
        answers: ['До 0,5 кВт', 'До 1 кВт', 'До 1,5 кВт'],
        correctAnswers: ['До 1 кВт'],
      },
      {
        code: '63715114',
        text: 'В какой таре допускается транспортирование горючих веществ?',
        answers: [
          'В металлических бидонах',
          'В металлических бочках',
          'В бутылях',
          'В металлических ведрах',
        ],
        correctAnswers: ['В металлических бочках'],
      },
      {
        code: '63715115',
        text:
          'Допускается ли устройство аспирационных пылеосадочных шахт, камер, коробов, размещаемых после пылеулавливающих установок?',
        answers: [
          'Не допускается',
          'Допускается',
          'Допускается только при специальном обосновании в проектной документации',
          'Допускается только при согласовании с территориальными органами Федеральной службы по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63715116',
        text:
          'Какое требование электростатической искробезопасности на объектах хранения и переработки растительного сырья указано неверно?',
        answers: [
          'Допускается использование общего заземляющего устройства для защиты от статического электричества, первичных и вторичных воздействий молнии и защитного заземления электроустановок',
          'Вставки из органического стекла, устанавливаемые в пневмотранспортных установках, обвивают снаружи проволокой с шагом витков не более 300 мм',
          'Вставки из брезента, резины, установленные на аспирационных воздуховодах, должны иметь перемычки из проволоки или троса',
          'Технологическое оборудование и продуктопроводы, расположенные во взрыво- и пожароопасных зонах всех классов, должны быть заземлены не менее чем в двух местах, с выравниванием потенциалов до безопасных значений',
        ],
        correctAnswers: [
          'Вставки из органического стекла, устанавливаемые в пневмотранспортных установках, обвивают снаружи проволокой с шагом витков не более 300 мм',
        ],
      },
      {
        code: '63715117',
        text: 'Какие требования к хранению и сушке кукурузы указаны неверно?',
        answers: [
          'Уровень влажности при хранении кукурузы в зерне до года не должен превышать 13,5%, при длительном хранении (более 1 года) - 12%',
          'Кукурузу в зерне следует сушить только в шахтных прямоточных отдельно стоящих сушилках',
          'При хранении зерна кукурузы должно предусматриваться максимальное число ее перемещений',
        ],
        correctAnswers: [
          'При хранении зерна кукурузы должно предусматриваться максимальное число ее перемещений',
        ],
      },
      {
        code: '63715118',
        text:
          'Для каких сепараторов проход со стороны выпуска зерна должен быть шириной не менее 0,7 м?',
        answers: [
          'Для сепараторов с круговым вращением решет',
          'Для сепараторов с боковой выемкой решет',
          'Для сепараторов производительностью до 50 т/ч (при расчете на элеваторную очистку) с возвратно-поступательным движением решет',
          'Для всех сепараторов',
        ],
        correctAnswers: ['Для всех сепараторов'],
      },
      {
        code: '63715119',
        text: 'Что должно предусматривать взрывопредупреждение?',
        answers: [
          'Установку производственной и аварийной сигнализации',
          'Исключение условий образования взрывоопасной среды в производственных помещениях применением герметичного оборудования, рабочей вентиляции и аспирации, технических средств пылеподавления, контролем за отложениями пыли (обеспечение пылевого режима)',
          'Регламентирование условий хранения и соблюдение схемы размещения и правил хранения зерна, продуктов его переработки и комбикормового сырья, склонных к самосогреванию и самовозгоранию',
          'Применение средств автоматизированного дистанционного контроля температуры зерна, продуктов его переработки и комбикормового сырья, обеспечивающих обнаружение очага самосогревания на ранних стадиях',
          'Должно предусматривать все перечисленное',
        ],
        correctAnswers: ['Должно предусматривать все перечисленное'],
      },
      {
        code: '63715120',
        text:
          'Какой из перечисленных элементов не входит в состав ротационных воздуходувок?',
        answers: [
          'Обратный клапан',
          'Манометр',
          'Система смазки',
          'Глушитель',
          'Индивидуальный электропривод',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63715121',
        text:
          'Использование каких перечисленных инструментов допускается при производстве погрузочно-разгрузочных работ с зерном и другими сыпучими продуктами на железнодорожном транспорте?',
        answers: [
          'Использование вибраторов для удаления зависшего в вагоне продукта',
          'Использование рычагов для вращения штурвалов',
          'Использование удлинителей для фиксации крышки загрузочного люка',
          'Допускается использование всех перечисленных инструментов',
        ],
        correctAnswers: [
          'Использование вибраторов для удаления зависшего в вагоне продукта',
        ],
      },
      {
        code: '63715122',
        text:
          'Какая ширина проходов должна быть между группами машин и станков, применяемых на объектах хранения и переработки растительного сырья?',
        answers: [
          'Не менее 2,5 м',
          'Не менее 0,7 м',
          'Не менее 1,5 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 1 м'],
      },
      {
        code: '63715123',
        text:
          'С какой периодичностью следует проверять переносные электроинструменты, лампы, трансформаторы на стенде или прибором в отношении исправности их заземляющих проводов и отсутствия замыкания между проводами?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в квартал',
          'Ежедневно',
          'Не реже одного раза в месяц',
          'Не реже одного раза в 10 дней',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63715124',
        text:
          'Что из перечисленного допускается при использовании клиновых ремней технических устройств на объектах хранения и переработки растительного сырья?',
        answers: [
          'Работа привода с неполным комплектом клиновых ремней',
          'Одинаковое натяжение всех клиновых ремней в клиноременных передачах',
          'Замена отдельных клиновых ремней',
          'Применение ремней с профилем, не соответствующим профилю канавок шкивов',
        ],
        correctAnswers: [
          'Одинаковое натяжение всех клиновых ремней в клиноременных передачах',
        ],
      },
      {
        code: '63715125',
        text:
          'С помощью какого приспособления осуществляются выемка из станка, перемещение и установка вальцов?',
        answers: [
          'С помощью крановых и других тележек',
          'С помощью любых перечисленных приспособлений',
          'С помощью талей',
          'С помощью монорельсовых путей',
        ],
        correctAnswers: ['С помощью любых перечисленных приспособлений'],
      },
      {
        code: '63715126',
        text:
          'Какие устройства должны быть установлены на нориях для предотвращения обратного хода ленты при внезапных остановках норий?',
        answers: [
          'Реле контроля скорости',
          'Устройства контроля сбегания ленты',
          'Датчики подпора',
          'Автоматически действующие тормозные устройства',
        ],
        correctAnswers: ['Автоматически действующие тормозные устройства'],
      },
      {
        code: '63715127',
        text:
          'В каком из перечисленных случаев может производиться спуск рабочих в силосы и бункеры?',
        answers: [
          'В случае проверки температуры хранящегося сырья',
          'В случае проведения анализа газовой среды',
          'В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)',
          'В случае отбора проб сырья',
        ],
        correctAnswers: [
          'В исключительных случаях при обоснованной производственной необходимости (плановая зачистка после опорожнения силосов или бункеров от остатков сырья предыдущего хранения, обслуживание (ремонт) внутренних поверхностей силосов и бункеров)',
        ],
      },
      {
        code: '63715128',
        text:
          'Какой должен быть объем производственного помещения на каждого работающего и площадь помещения?',
        answers: [
          'Не менее 20 м³, и не менее 2,5 м² соответственно',
          'Не менее 10 м³, и не менее 3,5 м² соответственно',
          'Не менее 15 м³ и не менее 4,5 м² соответственно',
          'Не менее 25 м³, и не менее 5,5 м² соответственно',
        ],
        correctAnswers: ['Не менее 15 м³ и не менее 4,5 м² соответственно'],
      },
      {
        code: '63715129',
        text:
          'На каком расстоянии от стен и другого оборудования должны устанавливаться мешковыбивальные машины?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 0,7 м',
          'Не менее 0,8 м',
          'Не менее 0,9 м',
        ],
        correctAnswers: ['Не менее 0,9 м'],
      },
      {
        code: '63715130',
        text:
          'Какие требования к гибким соединениям кузовов рассевов и камнеотборников указаны неверно?',
        answers: [
          'Штуцеры для подвешивания рукавов не должны иметь острых кромок и заусенцев',
          'Нижние ребра штуцеров должны быть закатаны наружу',
          'Все гибкие соединения кузовов рассевов и камнеотборников с выпускными патрубками должны быть из материалов, не пропускающих пыль',
        ],
        correctAnswers: ['Нижние ребра штуцеров должны быть закатаны наружу'],
      },
      {
        code: '63715131',
        text:
          'Каким требованиям должны отвечать устройства, применяемые для спуска людей в силосы?',
        answers: [
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при выполнении огневых и газоопасных работ',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе в замкнутом пространстве',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования промышленной безопасности при проведении работ с инструментом и приспособлениями',
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте',
        ],
        correctAnswers: [
          'Требованиям, установленным нормативными правовыми актами, содержащими требования охраны труда при работе на высоте',
        ],
      },
      {
        code: '63715132',
        text:
          'Какими сетками должны быть закрыты открытые всасывающие отверстия при работе вентиляторов?',
        answers: [
          'С размером ячеек 25 х 25 мм',
          'С размером ячеек 20 х 20 мм',
          'С размером ячеек 15 х 15 мм',
          'С размером ячеек 35 х 35 мм',
        ],
        correctAnswers: ['С размером ячеек 20 х 20 мм'],
      },
      {
        code: '63715133',
        text: 'Каким образом должны быть подвешены кузова рассевов?',
        answers: [
          'Нижняя плоскость кузова должна находиться от уровня пола на высоте не менее 0,35 м',
          'Нижняя плоскость кузова должна находиться от уровня пола на высоте не менее 0,15 м',
          'Нижняя плоскость кузова должна находиться от уровня пола на высоте не менее 0,25 м',
        ],
        correctAnswers: [
          'Нижняя плоскость кузова должна находиться от уровня пола на высоте не менее 0,35 м',
        ],
      },
      {
        code: '63715134',
        text:
          'Какой решеткой должна быть ограждена открытая часть шнека, применяемого для погрузки в железнодорожные вагоны или автомашины отрубей, мучки, комбикормов и других сыпучих грузов?',
        answers: [
          'С размером ячеек 100 x 50 мм',
          'С размером ячеек 150 x 60 мм',
          'С размером ячеек 250 x 75 мм',
        ],
        correctAnswers: ['С размером ячеек 250 x 75 мм'],
      },
      {
        code: '63715135',
        text: 'Где следует устанавливать датчики подпора на нориях?',
        answers: [
          'В головке нории',
          'На приемном патрубке',
          'На нисходящей ветви на высоте 300 - 400 мм от башмака нории',
          'На восходящей ветви на высоте 300 - 400 мм от башмака нории',
        ],
        correctAnswers: [
          'На восходящей ветви на высоте 300 - 400 мм от башмака нории',
        ],
      },
      {
        code: '63715136',
        text:
          'Какие из перечисленных документов разрабатываются (составляются) в целях обеспечения безопасности производственных процессов на объектах организации?',
        answers: [
          'Технический паспорт взрывобезопасности опасного производственного объекта',
          'Только технологические регламенты и схемы',
          'Только паспорта на аспирационные и пневмотранспортные установки',
          'Только паспорта на взрыворазрядные устройства',
          'Все перечисленные документы',
        ],
        correctAnswers: ['Все перечисленные документы'],
      },
      {
        code: '63715137',
        text:
          'В каком случае может осуществляться внесение изменений в технологический регламент и схемы размещения оборудования, средств дистанционного автоматизированного управления, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях?',
        answers: [
          'Только после согласования с руководителем территориального органа Федеральной службы по экологическому, технологическому и атомному надзору',
          'Только после согласования с проектной и экспертными организациями',
          'Только после внесения изменений в документацию на техническое перевооружение объекта при наличии положительного заключения экспертизы промышленной безопасности разработанной документации',
          'Только после принятия совместного решения по итогам совещания с представителями проектной организации, экспертных организаций, эксплуатирующей организации и представителями территориального органа Федеральной службы по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Только после внесения изменений в документацию на техническое перевооружение объекта при наличии положительного заключения экспертизы промышленной безопасности разработанной документации',
        ],
      },
      {
        code: '63715138',
        text:
          'Без устройства чего в зданиях категории Б не допускается предусматривать соединяющие их с другими зданиями и помещениями тоннели и подземные галереи? Выберите 2 варианта ответа.',
        answers: [
          'Без устройства переходных мостиков',
          'Без устройства металлической герметической двери',
          'Без устройства тамбур-шлюзов',
          'Без устройства огнепреграждающих клапанов',
        ],
        correctAnswers: [
          'Без устройства тамбур-шлюзов',
          'Без устройства огнепреграждающих клапанов',
        ],
      },
      {
        code: '63715139',
        text:
          'Какое из перечисленных действий допускается при проведении огневых работ на объектах хранения и переработки растительного сырья? Выберите 2 варианта ответа.',
        answers: [
          'Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом',
          'Использование в качестве обратного провода сети заземления или зануления металлических конструкций зданий, коммуникаций и технологического оборудования',
          'Прокладка электрических проводов на расстоянии менее 1,0 м от баллонов с горючими газами',
          'Проведение уборки помещений',
          'Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ',
        ],
        correctAnswers: [
          'Прокладка электрических проводов на расстоянии 1,0 м от горячих трубопроводов и баллонов с кислородом',
          'Плавное опускание на пол (землю) оборудования, сооружений и их частей, демонтируемых посредством электро- или газорезательных работ',
        ],
      },
      {
        code: '63715140',
        text:
          'Какой документ должен оформляться на каждый установленный взрыворазрядитель?',
        answers: [
          'Паспорт',
          'Формуляр',
          'Операционная карта',
          'Технологическая инструкция',
        ],
        correctAnswers: ['Паспорт'],
      },
      {
        code: '63715141',
        text:
          'С какой периодичностью следует осуществлять контроль целостности мембран, герметизирующих прокладок, подвижности откидных клапанов и поворотных створок комбинированных взрыворазрядителей, отсутствия накоплений на мембранах и в отводящих трубопроводах пыли или продукта?',
        answers: [
          'Один раз в два года',
          'Один раз в квартал',
          'Два раза в год',
          'Один раз в месяц',
          'Один раз в неделю',
        ],
        correctAnswers: ['Один раз в месяц'],
      },
      {
        code: '63715142',
        text:
          'Что из перечисленного не используется в качестве огнепреграждающих (пламеотсекающих) устройств для систем локализации взрывов?',
        answers: [
          'Шлюзовые затворы',
          'Винтовые конвейеры',
          'Порционные весы',
          'Самотечные трубопроводы',
          'Быстродействующие устройства, предотвращающие возможность распространения взрыва',
        ],
        correctAnswers: ['Самотечные трубопроводы'],
      },
      {
        code: '63715143',
        text:
          'В каком случае допускается одновременная разборка или ремонт конструкций, оборудования в двух или более ярусах по одной вертикали?',
        answers: [
          'При согласовании с лицами, ответственными за эксплуатацию',
          'Если это обосновано проектной документацией',
          'При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках',
          'Ни в каких случаях',
        ],
        correctAnswers: [
          'При наличии соответствующих защитных устройств (настилы, сетки, козырьки), обеспечивающих безопасную работу на всех отметках',
        ],
      },
      {
        code: '63715144',
        text:
          'Что из перечисленного должна предусматривать система планово-предупредительного ремонта?',
        answers: [
          'Только поддержание оборудования в рабочем состоянии, а также текущее наблюдение и периодический осмотр состояния оборудования объектов целях своевременного выявления неисправностей и их устранения',
          'Только подготовку зданий и сооружений к использованию, а машин и оборудования - к работе, защиту от атмосферных, тепловых и прочих воздействий внешней среды',
          'Только уход за зданиями, сооружениями и оборудованием во время их эксплуатации с соблюдением установленных режимов использования, наблюдения, смазки',
          'Должна предусматривать все перечисленное',
          'Только своевременное и качественное проведение текущего и капитального ремонтов, осуществляемых в планово-предупредительном порядке',
        ],
        correctAnswers: ['Должна предусматривать все перечисленное'],
      },
      {
        code: '63715145',
        text:
          'В помещениях какой категории допускается применять отопительные приборы с гладкой поверхностью и размещать их на высоте, обеспечивающей возможность их очистки от пыли?',
        answers: [
          'Только в помещениях категорий В',
          'Только в помещениях категорий Б',
          'Только в помещениях категорий А',
          'Во всех перечисленных помещениях',
        ],
        correctAnswers: ['Во всех перечисленных помещениях'],
      },
      {
        code: '63715146',
        text:
          'Какое оборудование объектов хранения и переработки растительного сырья допускается устанавливать группами?',
        answers: [
          'Рассевы',
          'Сепараторы',
          'Вертикальные круглые щеточные машины',
          'Обоечные и моечные машины',
        ],
        correctAnswers: ['Вертикальные круглые щеточные машины'],
      },
      {
        code: '63715147',
        text:
          'На основании какой документации осуществляются периодические перемещения (перекачивания) шрота, жмыха и другого мучнистого сырья, склонного к самовозгоранию, из занимаемых ими емкостей в свободные?',
        answers: [
          'Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте',
          'Планов-графиков, разработанных на основании Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
          'Планов-графиков, разработанных организацией и утвержденных в территориальных органах Ростехнадзора',
        ],
        correctAnswers: [
          'Планов-графиков, разработанных на основании допустимых сроков непрерывного хранения сырья, установленных организацией в технологическом регламенте',
        ],
      },
      {
        code: '63715148',
        text:
          'Кем устанавливаются данные о сроке службы оборудования производственных объектов хранения и переработки растительного сырья?',
        answers: [
          'Организацией-изготовителем',
          'Эксплуатирующей организацией',
          'Организацией, осуществляющей экспертизу промышленной безопасности',
          'Организацией-изготовителем совместно с территориальным органом Ростехнадзора',
          'Проектной организацией',
        ],
        correctAnswers: ['Организацией-изготовителем'],
      },
      {
        code: '63715149',
        text:
          'Что из перечисленного допускается хранить на территории эксплуатирующей организации открытым способом?',
        answers: [
          'Все ответы неверны',
          'Зерновые отходы',
          'Лузгу',
          'Зерновую пыль',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715150',
        text:
          'Какие размеры должна иметь вертикальная колонна в механизированных зерновых складах с плоскими полами?',
        answers: [
          'Высоту 4500 мм, наружный диаметр 245 мм и шаг между кольцами 145 мм',
          'Высоту 6000 мм, наружный диаметр 400 мм и шаг между кольцами 185 мм',
          'Высоту 5500 мм, наружный диаметр 394 мм и шаг между кольцами 165 мм',
          'Высоту 6200 мм, наружный диаметр 554 мм и шаг между кольцами 175 мм',
        ],
        correctAnswers: [
          'Высоту 5500 мм, наружный диаметр 394 мм и шаг между кольцами 165 мм',
        ],
      },
      {
        code: '63715151',
        text:
          'Где должны устанавливаться взрыворазрядители на надсушильных бункерах для взрывозащиты зерносушилок?',
        answers: [
          'На верхней крышке или на боковой стенке в верхней части бункера',
          'Только на верхней крышке бункера',
          'На боковой стенке в нижней части бункера',
          'На боковой стенке со стороны, противоположной входу агента сушки',
          'На днище бункера',
        ],
        correctAnswers: [
          'На верхней крышке или на боковой стенке в верхней части бункера',
        ],
      },
      {
        code: '63715152',
        text:
          'Чем должны быть оборудованы все силосы элеваторов и склады силосного типа для хранения сырья?',
        answers: [
          'Устройством дистанционного контроля температуры',
          'Защитным кожухом',
          'Двойными уплотняющими прокладками',
        ],
        correctAnswers: ['Устройством дистанционного контроля температуры'],
      },
      {
        code: '63715153',
        text:
          'При каком условии при производстве монтажных работ допускается использовать для закрепления технологической и монтажной оснастки оборудование и трубопроводы, а также технологические и строительные конструкции?',
        answers: [
          'Если работы производятся только обученным персоналом',
          'Если работы производятся только в присутствии ответственного за эксплуатацию оборудования и трубопроводов,  технологических и строительных конструкций',
          'При согласовании с лицами, ответственными за их эксплуатацию',
          'При согласовании с проектной организацией',
        ],
        correctAnswers: [
          'При согласовании с лицами, ответственными за их эксплуатацию',
        ],
      },
      {
        code: '63715154',
        text:
          'Каким образом должны быть заземлены воздуховоды и материалопроводы взрывопожароопасных производственных объектов хранения или переработки растительного сырья?',
        answers: [
          'Не менее чем в двух местах',
          'Через каждые 10 м по всей длине',
          'Только в одном месте',
          'В трех местах',
        ],
        correctAnswers: ['Не менее чем в двух местах'],
      },
      {
        code: '63715155',
        text:
          'Что из перечисленного не допускается на объектах хранения и переработки растительного сырья?',
        answers: [
          'Применение в складских помещениях погрузчиков, оборудованных искрогасителями',
          'Перепускные окна между бункерами и силосами, предназначенными для хранения муки',
          'Проемы и отверстия в стенах и перекрытиях производственных помещений с целью воздухообмена отапливаемых помещений',
        ],
        correctAnswers: [
          'Перепускные окна между бункерами и силосами, предназначенными для хранения муки',
        ],
      },
      {
        code: '63715156',
        text:
          'В каком случае не должна срабатывать автоматика безопасности горения, установленная на топках зерносушилок на жидком или газообразном топливе?',
        answers: [
          'При протекании топлива в топку при потухшем факеле',
          'При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива',
          'При выбросе горячего топлива в предтопочное пространство',
        ],
        correctAnswers: [
          'При зажигании топлива с предварительным запуском вентилятора и продувкой топки для удаления застоявшихся паров топлива',
        ],
      },
      {
        code: '63715157',
        text:
          'Каким образом следует располагать торец отводящего трубопровода при применении (установке) взрыворазрядителей на первых этажах?',
        answers: [
          'Не ниже 2,5 м от планировочной отметки',
          'Не выше 2,0 м от планировочной отметки',
          'Не ниже 1,5 м от планировочной отметки',
          'Не выше 0,5 м от планировочной отметки',
        ],
        correctAnswers: ['Не ниже 2,5 м от планировочной отметки'],
      },
      {
        code: '63715158',
        text:
          'С какой периодичностью должен испытываться и проверяться квалифицированным персоналом пневматический инструмент?',
        answers: [
          'Ежеквартально',
          'Ежемесячно',
          'Ежедневно',
          'Еженедельно',
          'Ежесменно',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63715159',
        text: 'Какое должно быть расстояние от пола до монорельса?',
        answers: [
          'Не менее 3,6 м',
          'Не менее 3,2 м',
          'Не менее 2,4 м',
          'Не менее 5,5 м',
        ],
        correctAnswers: ['Не менее 3,2 м'],
      },
      {
        code: '63715160',
        text:
          'Какой должна быть степень защиты оболочки переносных светильников прожекторного типа, использующихся для освещения бункеров и силосов сверху через люки?',
        answers: [
          'Не ниже IP25',
          'Не ниже IP60',
          'Не ниже IP54',
          'Не ниже IP40',
        ],
        correctAnswers: ['Не ниже IP54'],
      },
      {
        code: '63715161',
        text:
          'При каком условии в галереях, тоннелях, под и над площадками светильники подвешиваются на высоте не менее 1,7 м?',
        answers: [
          'При условии, что галереи и тоннели оборудованы аварийной сигнализацией',
          'При условии, что крепление арматуры не мешает движению обслуживающего персонала в проходах',
          'При условии, что применяются светильники, в которых доступ к лампе и токоведущим частям возможен без электроремонтного инструмента',
        ],
        correctAnswers: [
          'При условии, что крепление арматуры не мешает движению обслуживающего персонала в проходах',
        ],
      },
      {
        code: '63715162',
        text:
          'Что из перечисленного разрешается при эксплуатации технологического, аспирационного и транспортного оборудования?',
        answers: [
          'Заклеивание и забивание устраиваемых в крышках машин аспирационных щелей',
          'Пуск и работа машин, выделяющих пыль, с открытыми люками',
          'Ручной отбор проб зерна, продуктов размола, зерна и крупы из оборудования, имеющего в месте отбора или непосредственной близости движущиеся части',
          'Использование при обслуживании машин безопасных приспособлений для проведения очистки питающих механизмов, очистки на холостом ходу верхних плоскостей решет сепараторов и др.',
        ],
        correctAnswers: [
          'Использование при обслуживании машин безопасных приспособлений для проведения очистки питающих механизмов, очистки на холостом ходу верхних плоскостей решет сепараторов и др.',
        ],
      },
      {
        code: '63715163',
        text:
          'Через какие перечисленные помещения допускается прокладка транзитных воздуховодов?',
        answers: [
          'Через помещения складов сырья',
          'Все ответы неверны',
          'Через помещения разных категорий',
          'Через помещения складов готовой продукции',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715164',
        text:
          'Что необходимо предпринять при отклонениях от нормальной работы оборудования (завал продукта, интенсивное пыление, повышенные вибрации и другие подобные причины)?',
        answers: [
          'Оповестить представителя территориального органа Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий об отклонениях и продолжить работу',
          'Остановить оборудование и незамедлительно покинуть помещение',
          'Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций',
          'Продолжить работу, сообщив ответственному специалисту об отклонениях',
        ],
        correctAnswers: [
          'Остановить оборудование и незамедлительно проверить взрыворазрядители, при необходимости заменить разрывные предохранительные мембраны или другие поврежденные элементы конструкций',
        ],
      },
      {
        code: '63715165',
        text:
          'Кто может разрешить пуск вновь установленного оборудования или оборудования после ремонта?',
        answers: [
          'Руководитель территориального управления Федеральной службы по экологическому, технологическому и атомному надзору',
          'Главный энергетик эксплуатирующей организации',
          'Главный механик эксплуатирующей организации',
          'Начальник службы производственного контроля эксплуатирующей организации',
          'Руководитель, технический руководитель или главный инженер эксплуатирующей организации',
        ],
        correctAnswers: [
          'Руководитель, технический руководитель или главный инженер эксплуатирующей организации',
        ],
      },
      {
        code: '63715166',
        text:
          'В каком случае вальцовые станки могут быть установлены группами с учетом возможности проводить работы по смене рабочих валков на любом станке, не останавливая работы остальных станков в группе?',
        answers: [
          'Если в каждой группе будет не более пяти станков общей длиной вместе с электродвигателями не более 15 м',
          'Если в каждой группе будет не более четырех станков общей длиной вместе с электродвигателями не более 25 м',
          'Если в каждой группе будет не более семи станков общей длиной вместе с электродвигателями не более 30 м',
          'Если в каждой группе будет не более шести станков общей длиной вместе с электродвигателями не более 10 м',
        ],
        correctAnswers: [
          'Если в каждой группе будет не более пяти станков общей длиной вместе с электродвигателями не более 15 м',
        ],
      },
      {
        code: '63715167',
        text:
          'Какой должна быть высота бортов металлических или бетонных корыт, в которых установлены моечные машины?',
        answers: ['10 - 25 мм', '30 - 45 мм', '50 - 75 мм', '80 - 95 мм'],
        correctAnswers: ['50 - 75 мм'],
      },
      {
        code: '63715168',
        text:
          'Контроль температуры подшипников каких устройств на объектах необходимо осуществлять устройствами дистанционного автоматического контроля температуры?',
        answers: [
          'Турбокомпрессоров',
          'Турбовоздуходувных машин',
          'Дробилок',
          'Вальцовых станков',
          'Всех перечисленных устройств',
        ],
        correctAnswers: ['Всех перечисленных устройств'],
      },
      {
        code: '63715169',
        text:
          'В каком из перечисленных случаев не допускается включение электромагнитных сепараторов под напряжение?',
        answers: [
          'При отсутствии постоянного контроля за работой со стороны обслуживающего персонала',
          'При отсутствии защитных ограждений',
          'При сопротивлении изоляции обмоток не менее 0,5 МОм',
          'При отсутствии или неисправности световой сигнализации',
        ],
        correctAnswers: [
          'При отсутствии или неисправности световой сигнализации',
        ],
      },
      {
        code: '63715170',
        text:
          'Какой инструктаж проводится при выполнении разовых работ, работ по локализации и ликвидации последствий аварий, стихийных бедствий и работ, на которые оформляются наряд-допуск, разрешение или другие специальные документы?',
        answers: [
          'Первичный инструктаж на рабочем месте',
          'Вводный инструктаж',
          'Внеплановый инструктаж',
          'Целевой инструктаж',
        ],
        correctAnswers: ['Целевой инструктаж'],
      },
      {
        code: '63715171',
        text:
          'Чем определяются методы (способы) управления, объемы автоматизации и используемая для этих целей элементная база?',
        answers: [
          'Уровнем взрывобезопасности',
          'Техническим заданием на проектирование',
          'Инструкцией завода-изготовителя',
          'Особенностями технологического процесса',
        ],
        correctAnswers: ['Техническим заданием на проектирование'],
      },
      {
        code: '63715172',
        text:
          'Какое требование, установленное Правилами безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья, при наличии в проходе между конвейерами строительных конструкций (колонны, пилястры), создающих местное сужение прохода, указано неверно?',
        answers: [
          'Расстояние между конвейерами и строительными конструкциями должно быть не менее 0,5 м; по длине прохода до 1 м',
          'Длина прохода должна быть не менее 3-х метров',
          'Места суженного прохода должны быть ограждены',
        ],
        correctAnswers: ['Длина прохода должна быть не менее 3-х метров'],
      },
      {
        code: '63715173',
        text:
          'На каком расстоянии от корпуса защищаемого оборудования необходимо устанавливать предохранительную мембрану или клапан?',
        answers: [
          'На максимально возможном расстоянии',
          'На расстоянии не менее 3 м',
          'На минимально возможном расстоянии',
          'На расстоянии не более 1,5 м',
          'Не регламентируется',
        ],
        correctAnswers: ['На минимально возможном расстоянии'],
      },
      {
        code: '63715174',
        text:
          'Какое из перечисленных действий допускается при аварийной выгрузке продукта хранения из силосов и бункеров?',
        answers: [
          'Прерывать процесс флегматизации аварийного и смежных с ним силосов, бункеров до полного их освобождения от продукта',
          'Прерывать выгрузку продукта и оставлять частично разгруженные силосы, бункеры',
          'Тушение загорания компактной направленной струей воды',
          'Проведение выгрузки при одновременной подаче инертных газов в подсводное и в надсводное пространство силосов и бункеров',
        ],
        correctAnswers: [
          'Проведение выгрузки при одновременной подаче инертных газов в подсводное и в надсводное пространство силосов и бункеров',
        ],
      },
      {
        code: '63715175',
        text:
          'Что допускается использовать при монтаже оборудования во взрывопожароопасных помещениях, в которых работает оборудование?',
        answers: [
          'Открытый огонь',
          'Механизмы и приспособления, которые могут вызвать искрообразование',
          'Отогревание узлов и частей оборудования и устройств паром или горячей водой',
        ],
        correctAnswers: [
          'Отогревание узлов и частей оборудования и устройств паром или горячей водой',
        ],
      },
      {
        code: '63715176',
        text:
          'Какой должна быть температура нагрева корпусов подшипников во время работы взрывопожароопасного оборудования?',
        answers: [
          'Не более 60 °С',
          'Не более 67 °С',
          'Не более 75 °С',
          'Не более 90 °С',
        ],
        correctAnswers: ['Не более 60 °С'],
      },
      {
        code: '63715177',
        text: 'Кем утверждается технический паспорт взрывобезопасности?',
        answers: [
          'Главным инженером',
          'Руководителем эксплуатирующей организации',
          'Техническим директором',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63715178',
        text:
          'Какое из перечисленных требований к обустройству головок норий с целью их обслуживания указано неверно?',
        answers: [
          'Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м',
          'Специальные площадки должны иметь перила высотой не менее 1,0 м',
          'Оси приводных барабанов, которые расположены на высоте от пола более 1,5 м, предусматривают специальные площадки',
          'Специальные площадки должны иметь зашивку внизу на 0,15 м с обеспечением проходов для обслуживания',
        ],
        correctAnswers: [
          'Для подъема на площадки должны быть устроены стационарные лестницы с перилами шириной не менее 1,0 м',
        ],
      },
      {
        code: '63715179',
        text:
          'В каком случае допускается спуск людей в силосы и бункеры без их письменного согласия?',
        answers: [
          'В случаях, связанных со спасением пострадавших',
          'В аварийных случаях',
          'Не допускается ни в каком случае',
          'В присутствии ответственного руководителя работ',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63715180',
        text:
          'Какая должна быть высота помещений от пола до низа выступающих конструкций перекрытия (покрытия)?',
        answers: [
          'Не менее 3,0 м',
          'Не менее 4,5 м',
          'Не менее 4,8 м',
          'Не менее 2,2 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63715181',
        text:
          'Размещение помещений какой категории по взрывопожароопасности допускается в подвальных и цокольных этажах и при этом не требуется дополнительного обоснования в проектной документации?',
        answers: [
          'Не допускается размещение помещений всех перечисленных категорий',
          'Категории В1',
          'Категории А',
          'Категории Б',
        ],
        correctAnswers: ['Категории В1'],
      },
      {
        code: '63715182',
        text:
          'Под руководством какого лица допускается проведение огневых работ, связанных с локализацией и ликвидацией последствий аварии на объектах?',
        answers: [
          'Представителя пожарной службы',
          'Лица, выдавшего наряд-допуск',
          'Специалиста отдела охраны труда и промышленной безопасности',
          'Руководителя организации',
        ],
        correctAnswers: ['Лица, выдавшего наряд-допуск'],
      },
      {
        code: '63715183',
        text:
          'В помещениях какой категории по пожарной и взрывопожарной опасности на объектах хранения и переработки растительного сырья должны предусматриваются наружные легкосбрасываемые конструкции? Выберите 2 варианта ответа.',
        answers: [
          'В помещениях категорий Г',
          'В помещениях категорий А',
          'В помещениях категорий Б',
          'В помещениях категорий Д',
        ],
        correctAnswers: [
          'В помещениях категорий А',
          'В помещениях категорий Б',
        ],
      },
      {
        code: '63715184',
        text:
          'Где не устанавливаются огнепреграждающие (пламеотсекающие) устройства согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'На трубопроводах с головок норий и из дробилок, через которые непосредственно ведется загрузка бункеров и силосов',
          'На трубопроводах с головок норий и из дробилок на цепные и закрытые ленточные конвейеры, через которые ведется загрузка бункеров и силосов',
          'На коммуникациях минерального сырья',
          'На трубопроводах подачи зерна с головок норий в надсушильные бункеры встроенных зерносушилок',
          'На трубопроводах, соединяющих (в том числе и через цепные и закрытые ленточные конвейеры) бункеры, силосы или нории элеватора с отдельно стоящими зерносушилками',
        ],
        correctAnswers: ['На коммуникациях минерального сырья'],
      },
      {
        code: '63715185',
        text:
          'Какая предупредительная надпись должна быть вывешена у пускового устройства в помещении распределительного пункта и на пункте диспетчерского управления с начала ремонта оборудования и до его окончания согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          '"Опасно для жизни!"',
          '"Стой!"',
          '"Не включать, ремонт!"',
          '"Ведутся работы"',
          '"Обесточено"',
        ],
        correctAnswers: ['"Не включать, ремонт!"'],
      },
      {
        code: '63715186',
        text:
          'Какое из перечисленных требований к эксплуатации мешковыбивальных машин указано неверно?',
        answers: [
          'Бичевые барабаны мешковыбивальных машин должны быть отбалансированы',
          'Бичи должны свободно проходить между решетками и не задевать неподвижных частей машин',
          'Крышки, лючки и дверки мешковыбивальных машин должны быть уплотнены резиновыми и тканевыми прокладками и не пропускать пыль',
          'Бичи барабанов должны быть разной длины, не иметь острых краев и заусенцев',
        ],
        correctAnswers: [
          'Бичи барабанов должны быть разной длины, не иметь острых краев и заусенцев',
        ],
      },
      {
        code: '63715187',
        text:
          'В каком из перечисленных случаев не допускается работа вальцовых станков?',
        answers: [
          'Без продукта с прижатыми вальцами с перекосом и смещением их вдоль оси',
          'При перепаде температур входного и измельченного продукта менее 10 °С',
          'При перепаде температур входного и измельченного продукта менее 12 °С',
        ],
        correctAnswers: [
          'Без продукта с прижатыми вальцами с перекосом и смещением их вдоль оси',
        ],
      },
      {
        code: '63715188',
        text:
          'Что из перечисленного не допускается размещать в производственных зданиях?',
        answers: [
          'Вальцерезную мастерскую',
          'Диспетчерскую',
          'Комнаты для собраний и для приема пищи',
        ],
        correctAnswers: ['Комнаты для собраний и для приема пищи'],
      },
      {
        code: '63715189',
        text:
          'В каком случае допускается применение в складских помещениях погрузчиков с двигателями внутреннего сгорания?',
        answers: [
          'Ни в каком случае',
          'Если реализуются мероприятия, снижающие запыление территории',
          'Если погрузчики оборудованы искрогасителями, находящимися в исправном состоянии',
          'Если их применение определяется внутренними распорядительными документами эксплуатирующей организации',
        ],
        correctAnswers: [
          'Если погрузчики оборудованы искрогасителями, находящимися в исправном состоянии',
        ],
      },
      {
        code: '63715190',
        text:
          'При какой температуре наружной поверхности оборудование, емкости, продуктопроводы теплоизолируются?',
        answers: ['Выше 40 °С', 'Выше 45 °С', 'Выше 25 °С', 'Выше 30 °С'],
        correctAnswers: ['Выше 45 °С'],
      },
      {
        code: '63715191',
        text:
          'После выполнения каких действий допускается включение электромагнитного сепаратора под напряжение? Укажите все правильные ответы.',
        answers: [
          'После измерения сопротивления изоляции обмоток электромагнитов, которое должно быть менее 0,5 МОм',
          'После визуального осмотра ремонтной бригадой',
          'После испытания изоляции',
          'После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок',
        ],
        correctAnswers: [
          'После испытания изоляции',
          'После проверки соответствия всей электрической части техническим регламентам и другим нормативно-техническим документам по устройству электроустановок',
        ],
      },
      {
        code: '63715192',
        text:
          'Запрещается ли снимать или надевать приводные ремни на ходу, регулировать натяжение ремней или цепей машин во время их работы при эксплуатации технологического, аспирационного и транспортного оборудования?',
        answers: [
          'Разрешается при условии, что работы производятся под наблюдением лица, ответственного за безопасную эксплуатацию оборудования',
          'Запрещается',
          'Разрешается при условии, что данные работы не угрожают безопасности обслуживающего персонала',
          'Разрешается при условии, что работы производятся при наличии наряда-допуска',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63715193',
        text:
          'Что из перечисленного устанавливают на производственном оборудовании с целью защиты его от разрушения и обеспечения выброса (отвода) пламени и высокотемпературных продуктов взрывного горения пылевоздушной смеси в безопасную зону (за пределы помещений)?',
        answers: [
          'Взрыворазрядители',
          'Огнепреграждающие устройства',
          'Систему локализации взрывов',
          'Систему автоматического пожаротушения',
        ],
        correctAnswers: ['Взрыворазрядители'],
      },
      {
        code: '63715194',
        text:
          'Какие действия из перечисленных разрешается осуществлять при работе автомата для фасовки муки и крупы?',
        answers: [
          'Снимать испорченные пакеты',
          'Производить смазку частей машины',
          'Все ответы неверны',
          'Производить обтирку частей машины',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715195',
        text:
          'С учетом ограничений каких документов эксплуатирующей организацией в технологическом регламенте определяются сроки, в которые проверяется температура сырья? Выберите 2 варианта ответа.',
        answers: [
          'Правил безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья',
          'Технических регламентов',
          'Документов по стандартизации',
          'Технического паспорта взрывобезопасности опасного производственного объекта',
        ],
        correctAnswers: [
          'Технических регламентов',
          'Документов по стандартизации',
        ],
      },
      {
        code: '63715196',
        text:
          'Что из перечисленного не отражается в паспортах аспирационных установок, находящихся в эксплуатации?',
        answers: [
          'Принципиальные схемы установки',
          'Спецификации оборудования',
          'Производительность вентилятора и развиваемое им давление',
          'Результаты периодического осмотра установки',
          'Типы разгрузителей, шлюзовых затворов и приемных устройств.',
        ],
        correctAnswers: [
          'Типы разгрузителей, шлюзовых затворов и приемных устройств.',
        ],
      },
      {
        code: '63715197',
        text:
          'Применение каких переносных светильников во взрывобезопасном исполнении допускается использовать в трюмах судов и барж при разгрузке и загрузке?',
        answers: [
          'Не выше 24 В',
          'Не выше 220 В',
          'Не выше 12 В',
          'Не выше 36 В',
        ],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '63715198',
        text:
          'Какой шириной должны быть разрывы между шкивами вальцовых станков, расположенных в группе при трансмиссионном приводе или при приводе от индивидуальных электродвигателей, расположенных на нижележащем этаже?',
        answers: [
          'Не менее 0,15 м',
          'Не менее 0,35 м',
          'Не менее 0,25 м',
          'Не менее 0,12 м',
        ],
        correctAnswers: ['Не менее 0,35 м'],
      },
      {
        code: '63715199',
        text:
          'Что из перечисленного не учитывается при анализе риска аварий на объектах?',
        answers: [
          'Условия возникновения аварий и их сценарии',
          'Расчетное время прибытия специализированных профессиональных служб (формирований)',
          'Численность и размещение производственного персонала',
          'Учитывается все перечисленное',
        ],
        correctAnswers: ['Учитывается все перечисленное'],
      },
      {
        code: '63715200',
        text:
          'Какое из предъявляемых требований к крепежу деталей в машинах на объектах хранения и переработки растительного сырья указано неверно?',
        answers: [
          'В конструкции машин (снаружи и внутри) должны быть предусмотрены меры, исключающие самоотвинчивание крепежных деталей',
          'Вертикально расположенные болты должны иметь выступающую нарезную часть болта не более пяти - шести витков резьбы',
          'Вертикально расположенные болты должны устанавливаться вверх головкой',
        ],
        correctAnswers: [
          'Вертикально расположенные болты должны иметь выступающую нарезную часть болта не более пяти - шести витков резьбы',
        ],
      },
      {
        code: '63715201',
        text:
          'Что необходимо использовать в качестве отводящих трубопроводов взрыворазрядителей?',
        answers: [
          'Гибкие резиновые шланги',
          'Только керамические трубы',
          'Только медные металлополимерные трубы',
          'Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм',
        ],
        correctAnswers: [
          'Стальные сварные трубы круглого сечения с толщиной стенок не менее 1 мм',
        ],
      },
      {
        code: '63715202',
        text:
          'Какой допускается перекос общих осей поверхностей цапф (диаметром 65 мм) мелющих вальцов в каждой половине вальцового станка?',
        answers: [
          'Не более 0,25 мм по длине 1000 мм',
          'Не более 0,35 мм по длине 1000 мм',
          'Не более 0,4 мм по длине 1000 мм',
          'Не более 1,5 мм по длине 1000 мм',
        ],
        correctAnswers: ['Не более 0,25 мм по длине 1000 мм'],
      },
      {
        code: '63715203',
        text:
          'В каком случае трос подвески кузова у камнеотборников и сепараторов с круговым поступательным движением следует заменять новым?',
        answers: [
          'Если количество оборванных проволок достигает 10% от общего их числа на участке, равном шагу свивки',
          'Если количество оборванных проволок достигает 5% от общего их числа на участке, равном шагу свивки',
          'Если количество оборванных проволок достигает 7% от общего их числа на участке, равном шагу свивки',
        ],
        correctAnswers: [
          'Если количество оборванных проволок достигает 5% от общего их числа на участке, равном шагу свивки',
        ],
      },
      {
        code: '63715204',
        text:
          'При каком количестве напольных машин, имеющих тяговые аккумуляторные батареи, их заряжают как в отдельных помещениях с естественной вентиляцией, так и в общих невзрывопожароопасных производственных помещениях?',
        answers: ['До 12', 'До 8', 'До 10', 'До 6'],
        correctAnswers: ['До 6'],
      },
      {
        code: '63715205',
        text:
          'Какую длину носика должны иметь ручные масленки для заполнения подшипников на ходу при эксплуатации технологического оборудования?',
        answers: [
          'Не менее 100 мм',
          'Не менее 200 мм',
          'Не менее 50 мм',
          'Не менее 150 мм',
        ],
        correctAnswers: ['Не менее 200 мм'],
      },
      {
        code: '63715206',
        text:
          'Кто проводит обследование объекта перед разработкой технического паспорта взрывобезопасности?',
        answers: [
          'Комиссия, назначенная приказом эксплуатирующей организации',
          'Ростехнадзор',
          'Комиссия проектной организации',
          'Представители пусконаладочной организации',
        ],
        correctAnswers: [
          'Комиссия, назначенная приказом эксплуатирующей организации',
        ],
      },
      {
        code: '63715207',
        text:
          'Что необходимо предпринять при бестарном приеме и отпуске зерна, комбикормового, мучнистого сырья и готовой продукции (а также при погрузке отходов) на железнодорожном и автомобильном транспорте согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'Установку световой сигнализации',
          'Мероприятия, снижающие запыление территории',
          'Установку дополнительной емкости для загрузки',
        ],
        correctAnswers: ['Мероприятия, снижающие запыление территории'],
      },
      {
        code: '63715208',
        text:
          'Какие мероприятия должны быть предусмотрены при эксплуатации действующих норий в соответствии с требованиями промышленной безопасности?',
        answers: [
          'Необходимо обеспечить периодическую круглосуточную уборку пыли в производственных помещениях',
          'Необходимо провести внеплановый инструктаж и проверку знаний обслуживающего персонала',
          'Необходимо предусмотреть дополнительную аспирацию башмака нории',
          'Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака',
        ],
        correctAnswers: [
          'Необходимо обеспечить натяжение и регулировку норийной ленты, исключающие возможность ее пробуксовки на барабане и задевание ленты и ковшей о норийные трубы, кожух головки и башмака',
        ],
      },
      {
        code: '63715209',
        text: 'Какие должны быть проходы у башмака нории?',
        answers: [
          'С двух сторон, подлежащих обслуживанию, шириной не менее 0,5 м',
          'С трех сторон, подлежащих обслуживанию, шириной не менее 0,6 м',
          'С трех сторон, подлежащих обслуживанию, шириной не менее 0,7 м',
        ],
        correctAnswers: [
          'С трех сторон, подлежащих обслуживанию, шириной не менее 0,7 м',
        ],
      },
      {
        code: '63715210',
        text:
          'Работы на какой минимальной высоте должны проводиться с подмостей, огражденных перилами, высотой не менее 1 м с обшивкой по низу не менее 0,15 м?',
        answers: ['1,0 м', '1,3 м', '1,8 м'],
        correctAnswers: ['1,3 м'],
      },
      {
        code: '63715211',
        text:
          'Каким из перечисленных транспортов запрещается транспортирование отходов производства?',
        answers: [
          'Пневмотранспортом',
          'Цепным конвейером',
          'Винтовым конвейером',
          'Открытым ленточным конвейером',
        ],
        correctAnswers: ['Открытым ленточным конвейером'],
      },
      {
        code: '63715212',
        text:
          'На основании чего в организации разрабатывается технологический регламент?',
        answers: [
          'На основании эксплуатационной документации',
          'На основании проектной документации',
          'На основании рекомендаций Ростехнадзора',
          'На основании распорядительных документов организации, эксплуатирующей объект',
        ],
        correctAnswers: ['На основании проектной документации'],
      },
      {
        code: '63715213',
        text:
          'При какой объемной доле горючих газов в свободном объеме горящего и смежных с ним силосов, бункеров производится выгрузка продукта хранения, подвергшегося самосогреванию (самовозгоранию)?',
        answers: [
          'Не более 18% от значений нижних концентрационных пределов распространения пламени для каждого горючего газа',
          'Не более 10% от значений нижних концентрационных пределов распространения пламени для каждого горючего газа',
          'Не более 5% от значений нижних концентрационных пределов распространения пламени для каждого горючего газа',
        ],
        correctAnswers: [
          'Не более 5% от значений нижних концентрационных пределов распространения пламени для каждого горючего газа',
        ],
      },
      {
        code: '63715214',
        text:
          'С чем должны быть сблокированы электроприводы молотковых дробилок?',
        answers: [
          'С магнитными заграждениями',
          'С грануляторами',
          'С энтолейтором',
          'С устройством загрузки',
          'С датчиками нижнего уровня наддробильных бункеров',
        ],
        correctAnswers: ['С датчиками нижнего уровня наддробильных бункеров'],
      },
      {
        code: '63715215',
        text:
          'Какое устанавливается максимальное усилие, необходимое для перемещения несамоходной разгрузочной тележки?',
        answers: [
          '230 Н (23 кгс)',
          '120 Н (12 кгс)',
          '150 Н (15 кгс)',
          '180 Н (18 кгс)',
        ],
        correctAnswers: ['150 Н (15 кгс)'],
      },
      {
        code: '63715216',
        text:
          'Какие из перечисленных вариантов размещения и прокладки оборудования допускаются на объектах хранения и переработки растительного сырья?',
        answers: [
          'Прокладка транзитных воздуховодов через помещения складов сырья и готовой продукции',
          'Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием',
          'Размещение вентиляторов и пылеуловителей отдельно стоящих зерносушилок в рабочих зданиях элеваторов',
          'Прокладка транзитных воздуховодов через помещения разных категорий',
        ],
        correctAnswers: [
          'Размещение оборудования аспирационных установок (фильтров, циклонов, вентиляторов) в производственных помещениях категорий Б и В совместно с транспортным и технологическим оборудованием',
        ],
      },
      {
        code: '63715217',
        text:
          'На основании письменного решения какого лица осуществляется ввод в эксплуатацию аварийного участка производства (объекта) после проведения восстановительных работ?',
        answers: [
          'Технического руководителя эксплуатирующей организации',
          'Инспектора территориального органа Ростехнадзора',
          'Руководителя аварийно-спасательных формирований',
          'Руководителя эксплуатирующей организации',
          'Руководителя проектной организации',
        ],
        correctAnswers: ['Руководителя эксплуатирующей организации'],
      },
      {
        code: '63715218',
        text:
          'При каком виде работ машины могут не отключаться от сети электропитания?',
        answers: [
          'Все ответы неверны',
          'При выключении машин на продолжительное время',
          'При внутреннем осмотре машин',
          'При ремонте машин',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715219',
        text:
          'Какое из перечисленных требований к станкам для резки заплат указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Приводной вал с дисковыми ножами станка должен быть отбалансирован',
          'Станок со всех сторон должен иметь проходы не менее 3 м',
          'Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм',
          'Станки должны иметь быстродействующие тормозные устройства',
        ],
        correctAnswers: [
          'Приводной вал с дисковыми ножами станка должен быть отбалансирован',
          'Между кромкой стола и ограждением ножевого диска станка для резки заплат должен быть зазор не более 3 мм',
        ],
      },
      {
        code: '63715220',
        text:
          'Что должно быть графически отражено в технологической схеме производства?',
        answers: [
          'Только технологическое, аспирационное, транспортное оборудование с указанием моделей и их основных характеристик',
          'Только движение сырья, готовой продукции',
          'Только средства взрывопредупреждения, взрывозащиты и другие технические средства, обеспечивающие блокировку, контроль и противоаварийную защиту',
          'Должно быть отражено все перечисленное',
        ],
        correctAnswers: ['Должно быть отражено все перечисленное'],
      },
      {
        code: '63715221',
        text:
          'Какое количество человек должно участвовать в работе по отбору проб зерна из трюма, склада или бунта?',
        answers: [
          'Один человек',
          'Не менее двух человек',
          'Назначается ответственным лицом в каждом отдельном случае',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее двух человек'],
      },
      {
        code: '63715222',
        text:
          'При нагревании магнитопровода до какой максимальной температуры допускается работа электромагнитных сепараторов?',
        answers: ['До 90 °C', 'До 55 °C', 'До 45 °C', 'До 60 °C'],
        correctAnswers: ['До 60 °C'],
      },
      {
        code: '63715223',
        text:
          'Чем должны быть снабжены части станков, машин, аппаратов, а также механизмы, требующие смазки?',
        answers: [
          'Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования',
          'Приспособлениями, исключающими возможность заклинивания',
          'Ремнедержателями',
          'Ворошителями или вибраторами',
        ],
        correctAnswers: [
          'Автоматическими смазочными приборами или масленками с резервуарами достаточной вместительности, которые заполняются смазкой во время остановки этого оборудования',
        ],
      },
      {
        code: '63715224',
        text: 'При каком условии допускается проведение обеззараживания зерна?',
        answers: [
          'При условии обеспечения работников спецодеждой',
          'При условии полной засыпки силосов и бункеров продуктом',
          'При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов',
        ],
        correctAnswers: [
          'При условии разработки и соблюдения комплекса организационно-технических мер по обеспечению безопасности при проведении работ с применением химических препаратов',
        ],
      },
      {
        code: '63715225',
        text:
          'Что должна обеспечивать конструкция вальцового станка с полой бочкой согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности взрывопожароопасных производственных объектов хранения и переработки растительного сырья"?',
        answers: [
          'Прохождение между вальцами инородных тел размером не более 5 мм',
          'Прохождение между вальцами инородных тел размером не более 3 мм',
          'Прохождение между вальцами инородных тел размером не более 9 мм',
          'Прохождение между вальцами инородных тел размером не более 4 мм',
        ],
        correctAnswers: [
          'Прохождение между вальцами инородных тел размером не более 5 мм',
        ],
      },
      {
        code: '63715226',
        text:
          'Кто должен присутствовать при осмотре или ремонте в надсушильных, подсушильных бункерах и тепловлагообменниках?',
        answers: [
          'Представитель проектной организации',
          'Любой работник цеха или смены',
          'Технический руководитель организации',
          'Начальник цеха или смены',
          'Инженер отдела технического надзора',
        ],
        correctAnswers: ['Начальник цеха или смены'],
      },
      {
        code: '63715227',
        text:
          'Какое из перечисленных требований к взрыворазрядителям указано неверно?',
        answers: [
          'Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом величины защищаемого объема',
          'Диаметры (площади) проходных сечений взрыворазрядителей для оборудования определяются расчетом с учетом допустимого давления взрыва',
          'Форма и расположение переходных патрубков для присоединения взрыворазрядителей при нормальном режиме работы оборудования не должны допускать накопления в них пыли и продукта, а также должны исключать механическое повреждение предохранительной мембраны из-за попадания на нее продукта',
          'Для взрывозащиты дробилок взрыворазрядители устанавливаются на боковой стенке в нижней части поддробильного бункера или конуса',
        ],
        correctAnswers: [
          'Для взрывозащиты дробилок взрыворазрядители устанавливаются на боковой стенке в нижней части поддробильного бункера или конуса',
        ],
      },
      {
        code: '63715228',
        text:
          'Что из перечисленного не обеспечивает дистанционное управление работой оборудования взрывопожароопасных производственных объектов хранения или переработки растительного сырья?',
        answers: [
          'Возможность проведения контроля работоспособности средств дистанционного автоматизированного управления, блокировки, контроля и противоаварийной защиты, производственной и аварийной сигнализации, оповещения об аварийных ситуациях',
          'Постоянный контроль за параметрами технологического процесса и управление режимами для поддержания их регламентированных значений',
          'Регистрацию срабатывания и определение устройства, выдавшего сигнал на автоматическое аварийное отключение технологической линии или оборудования',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Возможность повторного включения конвейера при возникновении аварийной ситуации до полного схода с него транспортируемого продукта',
        ],
        correctAnswers: [
          'Возможность повторного включения конвейера при возникновении аварийной ситуации до полного схода с него транспортируемого продукта',
        ],
      },
      {
        code: '63715229',
        text:
          'Когда должна отключаться приточно-вытяжная вентиляция аккумуляторных помещений после окончания заряда зарядных устройств?',
        answers: [
          'Не ранее чем через 3,5 часа',
          'Не ранее чем через 1,5 часа',
          'Не ранее чем через 1,2 часа',
        ],
        correctAnswers: ['Не ранее чем через 1,5 часа'],
      },
      {
        code: '63715230',
        text:
          'Что из перечисленного допускается размещать во взрывопожароопасных зданиях и сооружениях?',
        answers: [
          'Все перечисленное размещать не допускается',
          'Бункеры для хранения аспирационных относов',
          'Бункеры для хранения пыли',
          'Бункеры для хранения пылевидных продуктов',
        ],
        correctAnswers: ['Все перечисленное размещать не допускается'],
      },
      {
        code: '63715231',
        text:
          'Какие явления допускаются при эксплуатации металлических силосов?',
        answers: [
          'Все ответы неверны',
          'Подтеки на внутренних поверхностях стен',
          'Подтеки на днище',
          'Неплотности в зоне опоры стенок силосных емкостей на фундаменты',
          'Неплотности в местах крепления дефлекторов и термоподвесок',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63715232',
        text: 'Какие конвейеры должны быть оснащены реле контроля скорости?',
        answers: [
          'Винтовые конвейеры длиной более 15 м',
          'Цепные конвейеры, установленные на подсилосных этажах деревянных элеваторов',
          'Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более',
          'Цепные конвейеры со скоростью движения цепи 1 м/с и более',
        ],
        correctAnswers: [
          'Стационарные ленточные конвейеры со скоростью движения ленты 1 м/с и более',
        ],
      },
      {
        code: '63715233',
        text:
          'Какое из перечисленных требований к топкам, работающим на жидком и газообразном топливе, указано верно?',
        answers: [
          'На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки',
          'Если жидкое или газообразное топливо при розжиге топки не загорается в течение 1 минуты., система контроля и автоматики горения топлива должна отключить подачу его в форсунку',
          'Повторную подачу топлива в топку и розжиг его после устранения причины неисправности осуществляют только после проветривания топки в течение 30 минут',
          'Допускается оставлять работающую топку без присмотра в течение 10 минут',
        ],
        correctAnswers: [
          'На магистрали, подводящей жидкое или газообразное топливо, должен быть головной запорный вентиль, установленный у выхода из топочного помещения, на расстоянии не менее 3 м от топки',
        ],
      },
      {
        code: '63715234',
        text:
          'В каком из перечисленных случаев не допускается работа фильтров с механическим встряхиванием рукавов?',
        answers: [
          'Только при работе встряхивающего механизма со сниженным числом ударов',
          'При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов',
          'Только при неисправном встряхивающем механизме',
        ],
        correctAnswers: [
          'При неисправном встряхивающем механизме либо при работе встряхивающего механизма со сниженным числом ударов',
        ],
      },
      {
        code: '63715235',
        text:
          'Какие из перечисленных действий допускаются при эксплуатации объектов хранения и переработки растительного сырья?',
        answers: [
          'Хранение сырого зерна на складах силосного типа',
          'Все перечисленные действия',
          'Хранение влажного зерна в силосах элеватора',
          'Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов',
        ],
        correctAnswers: [
          'Временное размещение сырого и влажного зерна в накопительных емкостях при условии разработки комплекса организационно-технических мер по обеспечению безопасности эксплуатации таких объектов',
        ],
      },
      {
        code: '63715236',
        text:
          'При каком минимальном повышении содержания бензина в шроте следует немедленно поставить в известность руководство эксплуатирующей организации, открыть двери и люки вагонов для его проветривания?',
        answers: ['0,003', '0,001', '0,002', '0,004'],
        correctAnswers: ['0,001'],
      },
      {
        code: '63715237',
        text:
          'Когда следует осуществлять обязательную замену разрывных предохранительных мембран из полиэтиленовой пленки или алюминиевой фольги с отражением действий в журнале периодических осмотров и ремонтов взрыворазрядителей?',
        answers: [
          'По истечении одного года эксплуатации',
          'По истечении трех лет эксплуатации',
          'По истечении пяти лет эксплуатации',
        ],
        correctAnswers: ['По истечении одного года эксплуатации'],
      },
      {
        code: '63715238',
        text:
          'Какие материалы допускается использовать в качестве легкосбрасываемых конструкций при недостаточной площади остекления?',
        answers: [
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 6 кПа (600 кгс/м²)',
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 4 кПа (400 кгс/м²)',
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 3 кПа (300 кгс/м²)',
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 2 кПа (200 кгс/м²)',
        ],
        correctAnswers: [
          'Вскрывающиеся или разрушающиеся при избыточном давлении внутри помещения не более 2 кПа (200 кгс/м²)',
        ],
      },
      {
        code: '63715239',
        text:
          'При помощи чего должен производиться спуск работников в силосы и бункеры (для хранения зерна, муки, отрубей, комбикормов и других продуктов)?',
        answers: [
          'Устройств, утвержденных для этих целей руководителем эксплуатирующей организации',
          'Веревочных складных лестниц',
          'Устройств, предназначенных для проведения работ на высоте',
        ],
        correctAnswers: [
          'Устройств, предназначенных для проведения работ на высоте',
        ],
      },
      {
        code: '63715240',
        text:
          'От чего защищают производственное оборудование, в котором возможно возникновение источника зажигания пылевоздушной смеси?',
        answers: [
          'От разрушения и выброса из него горючих веществ в производственное помещение',
          'От воздействия на него атмосферных осадков',
          'От перегрузки',
          'От неосторожного обращения работников с его элементами и частями',
        ],
        correctAnswers: [
          'От разрушения и выброса из него горючих веществ в производственное помещение',
        ],
      },
      {
        code: '63715241',
        text:
          'Каким документом определяются лица, ответственные за выполнение плана мероприятий по доведению объекта до нормативных требований промышленной безопасности, своевременное внесение соответствующих дополнений (изменений)?',
        answers: [
          'Приказом Ростехнадзора',
          'Распоряжением проектной организации',
          'Внутренним распорядительным документом эксплуатирующей объект организации',
          'Актом по результатам экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Внутренним распорядительным документом эксплуатирующей объект организации',
        ],
      },
      {
        code: '63715242',
        text:
          'Что из перечисленного допускается при погрузочно-разгрузочных работах с зерном и другими сыпучими продуктами на железнодорожном транспорте? Выберите 2 варианта ответа.',
        answers: [
          'Удалять зависший в вагоне продукт ломами',
          'Доступ работающих внутрь вагонов-зерновозов при наличии в них продукта',
          'Удалять зависший в вагоне продукт вибраторами или лопатами с удлиненной ручкой',
          'При загрузке вагона через люки в крыше переходить со вспомогательной площадки на крышу железнодорожного вагона в зимнее время',
        ],
        correctAnswers: [
          'Удалять зависший в вагоне продукт вибраторами или лопатами с удлиненной ручкой',
          'При загрузке вагона через люки в крыше переходить со вспомогательной площадки на крышу железнодорожного вагона в зимнее время',
        ],
      },
    ],
  },
  3: {
    63662: [
      {
        code: '63662000',
        text:
          'Как часто должна проверяться надежность крепления головок электродов?',
        answers: [
          'Один раз в смену',
          'Правилами не регламентируется',
          'Один раз в сутки',
        ],
        correctAnswers: ['Правилами не регламентируется'],
      },
      {
        code: '63662001',
        text:
          'Какие криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ, не следует оснащать предохранительными устройствами?',
        answers: [
          'Вместимостью до 15 л',
          'Вместимостью до 25 л',
          'Вместимостью до 20 л',
          'Вместимостью до 30 л',
        ],
        correctAnswers: ['Вместимостью до 15 л'],
      },
      {
        code: '63662002',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой',
          'Должны быть установлены на расстоянии не менее 15 м от печей',
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63662003',
        text:
          'С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?',
        answers: [
          'С интервалом не менее чем 10 минут',
          'С интервалом не менее чем 15 минут',
          'С интервалом не менее чем 5 минут',
          'С интервалом не менее чем 20 минут',
        ],
        correctAnswers: ['С интервалом не менее чем 10 минут'],
      },
      {
        code: '63662004',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В четырех экземплярах',
          'В трех экземплярах',
          'В двух экземплярах',
          'В одном экземпляре',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63662005',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением, равным рабочему',
          'Давлением, выше рабочего на 25%',
          'Давлением, выше рабочего на 50%.',
          'Давлением, равным номинальному',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63662006',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
          'Не менее 2 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63662007',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от механических воздействий',
          'От всего перечисленного',
          'Только от тепловых воздействий',
          'Только от возможного попадания на них расплава',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63662008',
        text:
          'При каком уменьшении толщины стенки осматриваемого трубопровода газообразных продуктов разделения воздуха срок последующего измерения должен сокращаться вдвое?',
        answers: [
          'Более чем на 0,4 мм в год',
          'Более чем на 0,3 мм в год',
          'Более чем на 0,2 мм в год',
          'Более чем на 0,1 мм в год',
        ],
        correctAnswers: ['Более чем на 0,4 мм в год'],
      },
      {
        code: '63662009',
        text:
          'Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?',
        answers: [
          'Не ниже 3,0 кПа',
          'Не ниже 2,5 кПа',
          'Не ниже 2,0 кПа',
          'Не ниже 1,5 кПа',
        ],
        correctAnswers: ['Не ниже 3,0 кПа'],
      },
      {
        code: '63662010',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в пять лет', 'Один раз в три года', 'Ежегодно'],
        correctAnswers: ['Один раз в пять лет'],
      },
      {
        code: '63662011',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников; двери помещения должны открываться наружу и не должны иметь внутренних запоров',
          'Помещения должны иметь защиту от воздействия теплового излучения',
          'Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом',
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63662012',
        text:
          'В течение какого времени не допускается приближаться к открытому пламени (курить) или к раскаленным предметам по окончании работы с жидким кислородом или работы в местах с повышенным содержанием кислорода?',
        answers: [
          'В течение 30 минут',
          'В течение 45 минут',
          'В течение 15 минут',
          'В течение 60 минут',
        ],
        correctAnswers: ['В течение 30 минут'],
      },
      {
        code: '63662013',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Только паспортом (формуляром) на компрессорную установку, инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов',
          'Графиком ремонтов компрессорной установки',
          'Только журналом учета работы компрессора, паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа, графиком ремонтов компрессорной установки',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63662014',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['1 час', '2 часа', '1,5 часа', '0,5 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63662015',
        text:
          'При каких остановках воздухоразделительной установки жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент регенерировать?',
        answers: [
          'Продолжительностью более чем на 8 часов',
          'Продолжительностью более чем на 6 часов',
          'Продолжительностью более чем на 1 час',
          'Продолжительностью более чем на 2 часа',
        ],
        correctAnswers: ['Продолжительностью более чем на 8 часов'],
      },
      {
        code: '63662016',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,3 кПа',
          'Не более 0,1 кПа',
          'Не более 0,5 кПа',
          'Не более 0,9 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63662017',
        text:
          'При каком условии допускается производить замер уровня пека в цистерне деревянной линейкой?',
        answers: [
          'Если длина линейки не менее 3 м',
          'Если длина линейки не менее 2 м',
          'Если длина линейки не менее 1 м',
        ],
        correctAnswers: ['Если длина линейки не менее 3 м'],
      },
      {
        code: '63662018',
        text:
          'Что из перечисленного допускается при производстве работ в цехе улавливания химических продуктов коксования?',
        answers: [
          'Возврат раствора в каждый сатуратор в том же количестве, в каком раствор забирается из сатураторов насосами',
          'Использование сжатого воздуха для подачи кислоты в сатураторы или для выдачи раствора из сатураторов',
          'Выпуск маточного раствора в котлованы под сатураторами',
          'Применение надсмольной воды для промывки соли в центрифугах и промывки ванны сатуратора',
        ],
        correctAnswers: [
          'Возврат раствора в каждый сатуратор в том же количестве, в каком раствор забирается из сатураторов насосами',
        ],
      },
      {
        code: '63662019',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Подсыпанием канифоли и других материалов',
          'Ручной регулировкой',
          'Соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
        correctAnswers: [
          'Соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63662020',
        text:
          'Где должен находиться работник при ручной подправке металла на ножницах?',
        answers: ['Сбоку от ножниц', 'Спереди ножниц', 'Сзади ножниц'],
        correctAnswers: ['Сбоку от ножниц'],
      },
      {
        code: '63662021',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 15 календарных дней',
          'На 20 рабочих дней',
          'На 30 календарных дней',
          'На 45 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63662022',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Возможность разгерметизации системы',
          'Попадание воздуха',
          'Образование взрывоопасной среды',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63662023',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'На один объект, площадку, территорию должен оформляться один акт-допуск',
          'Акт-допуск должен оформляться письменно',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63662024',
        text:
          'Какой документацией устанавливается объем функций газового цеха?',
        answers: [
          'Распорядительной документацией эксплуатирующей организации',
          'Эксплуатационной документацией',
          'Проектной документацией',
          'Правилами безопасности процессов получения или применения металлов',
        ],
        correctAnswers: [
          'Распорядительной документацией эксплуатирующей организации',
        ],
      },
      {
        code: '63662025',
        text:
          'При каком расположении дистанционно управляемой запорной и регулирующей арматуры, установленной на трубопроводе кислорода с давлением выше 1,6 МПа, для защиты персонала при возгорании арматуры следует устанавливать защитные экраны?',
        answers: [
          'На расстоянии менее 3 м от рабочих мест',
          'На расстоянии менее 4 м от рабочих мест',
          'На расстоянии менее 5 м от рабочих мест',
          'На расстоянии менее 10 м от рабочих мест',
        ],
        correctAnswers: ['На расстоянии менее 3 м от рабочих мест'],
      },
      {
        code: '63662026',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 1 м',
          'Не менее 2 м',
          'Не менее 1,2 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63662027',
        text:
          'Какие надписи должны быть на бочках, заполненных пиридиновыми и хинолиновыми продуктами? Выберите 2 варианта ответа.',
        answers: [
          'Ядовито',
          'Огнеопасно',
          'Взрывоопасно',
          'Беречь от влаги',
          'Беречь от солнечных лучей',
        ],
        correctAnswers: ['Ядовито', 'Огнеопасно'],
      },
      {
        code: '63662028',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63662029',
        text:
          'Какое из перечисленных требований при выполнении работ в цехе (отделении) кристаллического нафталина указано неверно?',
        answers: [
          'Для транспортирования нафталина должен использоваться сжатый воздух',
          'Погрузка прессованного нафталина в железнодорожные вагоны должна быть механизирована',
          'Колеса тележек, используемые в цехах нафталина, должны быть изготовлены из материала, не дающего искры',
          'Перевозки жидкого нафталина должны осуществляться спецтранспортом',
        ],
        correctAnswers: [
          'Для транспортирования нафталина должен использоваться сжатый воздух',
        ],
      },
      {
        code: '63662030',
        text:
          'В каком случае допускается осуществлять обогрев конденсатоотводчиков межцеховых газопроводов зимой путем ввода острого пара внутрь сосуда?',
        answers: [
          'В аварийной ситуации',
          'В случае получения письменного разрешения главного инженера',
          'В случае использования необходимых СИЗ',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['В аварийной ситуации'],
      },
      {
        code: '63662031',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Все перечисленные работы',
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Только работы, связанные с аварийными ситуациями',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63662032',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: [
          'Из сплавов титана',
          'Из стали или чугуна',
          'Из цветных сплавов',
        ],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63662033',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63662034',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение года',
          'В течение трех месяцев',
          'В течение одного месяца',
          'В течение шести месяцев',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63662035',
        text:
          'Какие требования установлены к снабжению литейных производств щелочью?',
        answers: [
          'Должны снабжаться кристаллизованной щелочью в металлических бочках',
          'Должны снабжаться сульфитной щелочью',
          'Должны снабжаться щелочью в виде порошка',
        ],
        correctAnswers: ['Должны снабжаться сульфитной щелочью'],
      },
      {
        code: '63662036',
        text:
          'При каком условии допускается входить в закрытый склад сырого бензола и продуктов его переработки и производить в нем какие-либо работы?',
        answers: [
          'Только под наблюдением газоспасателя',
          'Только под наблюдением начальника смены',
          'Только под наблюдением технического руководителя',
          'Только под наблюдением лица, назначенного распоряжением по цеху ответственным за проведение этих работ',
        ],
        correctAnswers: ['Только под наблюдением газоспасателя'],
      },
      {
        code: '63662037',
        text:
          'До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?',
        answers: ['120 °C', '130 °C', '140 °C', '90 °C'],
        correctAnswers: ['120 °C'],
      },
      {
        code: '63662038',
        text:
          'В каком случае должна производиться проверка настройки и срабатывания предохранительных запорных и сбросных клапанов ГРП и ГРУ?',
        answers: [
          'Только после ревизии',
          'Только один раз в два месяца',
          'Во всех перечисленных случаях',
          'Только после ремонта',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63662039',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы постами с дежурными стрелочного поста',
          'Должны быть оборудованы светофорами',
          'Должны быть оборудованы автоматической сигнализацией',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63662040',
        text:
          'Ведение каких из перечисленных работ допускается в обслуживающих туннелях и по всему газовому тракту коксового блока печей?',
        answers: ['Огневых работ', 'Ремонтных работ', 'Аварийных работ'],
        correctAnswers: ['Ремонтных работ'],
      },
      {
        code: '63662041',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы могут быть продолжены с соблюдением требований безопасности. В течение смены следует оформить новый наряд-допуск',
          'Работы могут быть продолжены. Оформление нового наряда-допуска не требуется',
          'Действия должен определить работник, выдающий наряд-допуск',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63662042',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Главный инженер предприятия',
          'Руководитель предприятия',
          'Генеральный директор предприятия',
          'Специалист, ответственный за промышленную безопасность на предприятии',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63662043',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
          'Оповещение эксплуатационного персонала о признаках пожара',
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63662044',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Фурмы должны иметь цилиндрическую форму',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
          'Должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63662045',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Строительно-монтажной организацией',
          'Ремонтным персоналам предприятия под руководством главного механика',
          'Техническим руководством предприятия',
          'Проектной организацией',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63662046',
        text:
          'Какое из перечисленных требований предъявляется к размещению баллонов у каждого места потребления для постоянных потребителей небольших количеств ПРВ?',
        answers: [
          'Не более одного баллона вместимостью 20 л, заполненного ПРВ под давлением до 10 МПа',
          'Не более двух баллонов вместимостью 40 л, заполненных ПРВ под давлением до 20 МПа',
          'Не более двух баллонов вместимостью 80 л, заполненных ПРВ под давлением до 30 МПа',
          'Не более трех баллонов вместимостью 60 л, заполненных ПРВ под давлением до 30 МПа',
        ],
        correctAnswers: [
          'Не более двух баллонов вместимостью 40 л, заполненных ПРВ под давлением до 20 МПа',
        ],
      },
      {
        code: '63662047',
        text:
          'Чем осуществляется прогрев нагнетателей коксового газа перед их пуском?',
        answers: [
          'Паром',
          'Коксовым газом',
          'Электрическими тенами',
          'Горячей водой',
        ],
        correctAnswers: ['Паром'],
      },
      {
        code: '63662048',
        text:
          'Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Сухим подогретым воздухом',
          'Открытым пламенем',
          'Теплой водой',
          'Паром',
        ],
        correctAnswers: ['Сухим подогретым воздухом'],
      },
      {
        code: '63662049',
        text:
          'Какое из перечисленных требований к стационарным газоразборным постам указано неверно?',
        answers: [
          'Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 150 мм',
          'Дверцы шкафов во время работы должны быть закрыты',
          'При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок',
          'Газоразборные посты, шкафы должны иметь опознавательную окраску',
        ],
        correctAnswers: ['Дверцы шкафов во время работы должны быть закрыты'],
      },
      {
        code: '63662050',
        text:
          'Кем должен утверждаться акт комиссионного обследования всех коммуникаций (трубопроводов и арматуры) коксохимических производств?',
        answers: [
          'Главным энергетиком',
          'Специалистом по качеству',
          'Главным технологом',
          'Техническим руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63662051',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,2% в час',
          'Не более 0,1% в час',
          'Не более 0,5% в час',
          'Не более 0,05% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63662052',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Только обратный клапан',
          'Только 2 запорных вентиля',
          'Только свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63662053',
        text:
          'Кого обязан предупредить обслуживающий персонал машинного отделения о пуске и остановке нагнетателя коксового газа?',
        answers: [
          'Только диспетчера производства',
          'Только начальника смены коксового цеха',
          'Только начальников смены парокотельной и газоповысительной станции цеха - потребителя газа',
          'Всех перечисленных лиц',
        ],
        correctAnswers: ['Всех перечисленных лиц'],
      },
      {
        code: '63662054',
        text:
          'Какая должна быть объемная доля кислорода в продувочном газе перед подачей водорода в установки очистки сырого аргона от кислорода и водородные коммуникации?',
        answers: [
          'Не должна превышать 4%',
          'Не должна превышать 8%',
          'Не должна превышать 10%',
          'Не должна превышать 12%',
        ],
        correctAnswers: ['Не должна превышать 4%'],
      },
      {
        code: '63662055',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в два месяца',
        ],
        correctAnswers: ['Не реже одного раза в три месяца'],
      },
      {
        code: '63662056',
        text:
          'Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Перевести на ручной режим систему автоматического управления регенераторов',
          'Закрыть или открыть соответствующие клапаны с помощью дистанционного управления',
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          'Необходимо осуществить все перечисленные действия',
        ],
        correctAnswers: ['Необходимо осуществить все перечисленные действия'],
      },
      {
        code: '63662057',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63662058',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При незначительном повышении давления воды в системе охлаждения',
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При незначительном повышении температуры воды в системе водоохлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63662059',
        text: 'Как должны подготавливаться растворы кислот и щелочей?',
        answers: [
          'Вода должна заливаться небольшой струей в кислоту или щелочь',
          'Кислота или щелочь должны заливаться небольшой струей в холодную воду',
          'Последовательность смешения кислоты или щелочи и воды не имеет значения',
        ],
        correctAnswers: [
          'Кислота или щелочь должны заливаться небольшой струей в холодную воду',
        ],
      },
      {
        code: '63662060',
        text:
          'Чем должен быть продут маслобак компрессора перед пуском центробежного кислородного компрессора? Выберите 2 варианта ответа.',
        answers: [
          'Сухим азотом',
          'Сухим воздухом',
          'Сухим природным газом',
          'Сухим аргоном',
          'Паром',
        ],
        correctAnswers: ['Сухим азотом', 'Сухим воздухом'],
      },
      {
        code: '63662061',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 1,2 м',
          'Не менее 1,0 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63662062',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Пожарные краны, гидранты',
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Емкости с песком, огнетушители',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63662063',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Инструкции по эксплуатации',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Инструкции по проведению пусконаладочных работ',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63662064',
        text:
          'Какие требования установлены к материалам, используемым для приготовления формовочных и стержневых смесей?',
        answers: [
          'Должны иметь накладные',
          'Должны иметь сертификаты качества',
          'Должны иметь данные о поставщике',
        ],
        correctAnswers: ['Должны иметь сертификаты качества'],
      },
      {
        code: '63662065',
        text:
          'С какой периодичностью должна производиться проверка состояния взрывных клапанов газовых сушильных установок обслуживающим персоналом?',
        answers: ['Ежедекадно', 'Ежемесячно', 'Ежесменно', 'Еженедельно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63662066',
        text:
          'В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?',
        answers: [
          'Крышка должна быть открыта для наблюдения за работой горелки',
          'Крышка должна быть закрыта',
          'Крышка должна быть снята',
        ],
        correctAnswers: ['Крышка должна быть закрыта'],
      },
      {
        code: '63662067',
        text:
          'На сколько должен превышать объем помещения (в метрах кубических) объем жидкости, находящейся в сосудах (в литрах), для того, чтобы в помещении с естественной вентиляцией допускалась работа с открытыми сосудами жидких ПРВ?',
        answers: [
          'Не менее чем в 7 раз',
          'Не менее чем в 5 раз',
          'Не менее чем в 3 раза',
          'Не менее чем в 10 раз',
        ],
        correctAnswers: ['Не менее чем в 7 раз'],
      },
      {
        code: '63662068',
        text:
          'При каком содержании кислорода в парогазовой смеси допускается эксплуатация газовых сушильных установок?',
        answers: [
          'Не выше норм, предусмотренных комиссией эксплуатирующей организации, и согласованных с Ростехнадзором',
          'Не выше норм, предусмотренных техническим регламентом',
          'Не выше норм, предусмотренных Правилами безопасности процессов получения или применения металлов',
          'Не выше норм, предусмотренных технологической инструкцией',
        ],
        correctAnswers: [
          'Не выше норм, предусмотренных технологической инструкцией',
        ],
      },
      {
        code: '63662069',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования.',
          'Ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Ответственный за производственный контроль',
          'Главный инженер',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63662070',
        text:
          'Чем необходимо регулярно промывать витые конденсаторы-испарители с внутритрубным кипением кислорода в установках, не производящих криптоновый концентрат?',
        answers: [
          'Жидким кислородом',
          'Жидким азотом',
          'Жидким водородом',
          'Дистиллированной водой',
        ],
        correctAnswers: ['Жидким кислородом'],
      },
      {
        code: '63662071',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Арматура должна размещаться над технологическими проходами',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'Арматура должна размещаться над дверными проемами',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63662072',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в два месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63662073',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через производственные помещения, связанные с потреблением водорода',
          'Через вентиляционные камеры, помещения КИП, лестничные клетки, пути эвакуации',
          'Прокладка водородопроводов через все перечисленные помещения не допускается',
          'Через электромашинные, электрораспределительные, трансформаторные помещения',
          'Через бытовые, подсобные, административно-хозяйственные, складские помещения',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63662074',
        text:
          'Какое из перечисленных требований при отогреве воздухоразделительных устройств ПРВ указано неверно?',
        answers: [
          'Испарение жидких криогенных продуктов разделения воздуха, сливаемых из отдельных аппаратов перед их отогревом, должно производиться в специальных испарителях быстрого слива, предусмотренных проектом отдельно для каждой установки',
          'Отогрев аппаратов воздухоразделительной установки должен производиться до достижения температуры воздуха, выходящего из аппаратов в течение двух часов, до температуры, определенной технологической инструкцией',
          'Для воздухоразделительной установки малой производительности слив жидких криогенных продуктов из аппаратов может производиться в переносные криогенные сосуды с последующим испарением в устройстве для слива и испарения жидких ПРВ',
          'Объединение трубопроводов для слива жидких продуктов из ВРУ допускается в случае, предусмотренном проектом',
        ],
        correctAnswers: [
          'Объединение трубопроводов для слива жидких продуктов из ВРУ допускается в случае, предусмотренном проектом',
        ],
      },
      {
        code: '63662075',
        text:
          'В каком случае оборудование, использовавшееся для работы с одним продуктом разделения воздуха, допускается для работы с другим продуктом?',
        answers: [
          'Только по согласованию с разработчиком оборудования',
          'Только по решению комиссии эксплуатирующей организации',
          'Только по согласованию с Ростехнадзором',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Только по согласованию с разработчиком оборудования'],
      },
      {
        code: '63662076',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в полгода',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63662077',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'Только на первой ступени сжатия',
          'Только на второй ступени сжатия',
          'На всех ступенях',
          'Только на третьей ступени сжатия',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63662078',
        text:
          'С какой периодичностью должно проводиться обслуживание систем контроля загазованности и уровня содержания окиси углерода в помещениях с установленным газоиспользующим оборудованием специализированными организациями или сервисными службами изготовителя?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63662079',
        text: 'Чем должны быть продуты азотные турбодетандеры перед ремонтом?',
        answers: ['Воздухом', 'Кислородом', 'Азотом', 'Углекислым газом'],
        correctAnswers: ['Воздухом'],
      },
      {
        code: '63662080',
        text:
          'Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?',
        answers: [
          'Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания',
          'При отсутствии работника, пользующегося арматурой, шкаф должен быть закрыт на замок',
          'Дверцы шкафа при выполнении работы должны быть закрыты',
          'Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории',
        ],
        correctAnswers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
      },
      {
        code: '63662081',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
          'У главного механика',
          'У начальника цеха',
          'У главного инженера',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63662082',
        text: 'Как должны сооружаться газопроводы на территории предприятия?',
        answers: [
          'Надземными',
          'Наземными',
          'Подземными',
          'Не регламентируется',
        ],
        correctAnswers: ['Надземными'],
      },
      {
        code: '63662083',
        text:
          'С какой периодичностью должен производиться контроль за отсутствием электрического потенциала за вторым электроизолированным стыком рельсов въездных железнодорожных путей при их эксплуатации?',
        answers: [
          'Два раза в год',
          'Один раз в два года',
          'Один раз в год',
          'Один раз в квартал',
        ],
        correctAnswers: ['Два раза в год'],
      },
      {
        code: '63662084',
        text:
          'Какие из перечисленных работ должны осуществляться по наряду-допуску на проведение работ в газоопасных местах? Выберите правильный вариант ответа.',
        answers: [
          'Все перечисленные работы',
          'Только работы по очистке емкостей от пека',
          'Только работы по очистке емкостей от пековой смолы и дистиллята',
          'Только работы по обслуживанию и ремонту пекококсовых печей и участков погрузки пека',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63662085',
        text:
          'Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?',
        answers: [
          'Только аэродинамической воздушной завесой',
          'Только аэродинамической  азотной завесой',
          'Любым из перечисленных',
        ],
        correctAnswers: ['Любым из перечисленных'],
      },
      {
        code: '63662086',
        text:
          'Какое максимальное значение не должно превышать содержание масел в воздухе или азоте, используемом для пневмоиспытаний и продувки кислородопроводов?',
        answers: ['10,0 мг/м³', '15,0 мг/м³', '20,0 мг/м³', '5,0 мг/м³'],
        correctAnswers: ['10,0 мг/м³'],
      },
      {
        code: '63662087',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?',
        answers: ['0,4 кПа', '0,3 кПа', '0,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63662088',
        text:
          'В каком случае после каждого компрессора необходимо устанавливать обратный клапан и запорные органы (отключающую арматуру) с дистанционным управлением электропривода для отключения компрессора от коллектора и сброса кислорода в атмосферу?',
        answers: [
          'При работе на один коллектор нагнетания кислорода двух и более центробежных компрессоров или двух и более поршневых компрессоров (единичной производительностью более 2000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)',
          'При работе на один коллектор нагнетания кислорода одного и более центробежных компрессоров или одного и более поршневых компрессоров (единичной производительностью более 2000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)',
          'При работе на один коллектор нагнетания кислорода двух и более центробежных компрессоров или двух и более поршневых компрессоров (единичной производительностью более 1000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)',
        ],
        correctAnswers: [
          'При работе на один коллектор нагнетания кислорода двух и более центробежных компрессоров или двух и более поршневых компрессоров (единичной производительностью более 2000 м³/ч) и давлением кислорода в трубопроводе нагнетания кислорода свыше 1,6 МПа (избыточное давление)',
        ],
      },
      {
        code: '63662089',
        text:
          'С какой периодичностью обслуживающий персонал должен проводить визуальный осмотр работающего оборудования и трубопроводов химического цеха?',
        answers: ['Ежеквартально', 'Еженедельно', 'Ежесменно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63662090',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Только установлены задвижки в нужное положение',
          'Только плотно закрыты все люки, лазы и свечи, за исключением той свечи, через которую будет производиться продувка',
          'Только залиты водяные затворы водой',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63662091',
        text:
          'За какое время до выдачи кокса допускается открывание стояков при эксплуатации пекококсовых печей?',
        answers: [
          'Не ранее чем за 60 минут',
          'Не ранее чем за 40 минут',
          'Не ранее чем за 30 минут',
          'Не ранее чем за 20 минут',
        ],
        correctAnswers: ['Не ранее чем за 20 минут'],
      },
      {
        code: '63662092',
        text:
          'Какие из перечисленных данных записываются в агрегатный (ремонтный) журнал на объектах кислородного цеха и в цехах, в составе которых имеются объекты потребления ПРВ? Выберите правильный вариант ответа.',
        answers: [
          'Нарушения Правил безопасности процессов получения или применения металлов',
          'Мероприятия по устранению нарушений и фактические сроки их выполнения',
          'Настройка приборов агрегатов',
          'Все перечисленные данные',
          'Неисправности, выявленные в течение каждой смен',
        ],
        correctAnswers: ['Настройка приборов агрегатов'],
      },
      {
        code: '63662093',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть увлажнены',
          'Должны быть выдержаны в сухом отапливаемом помещении не менее суток',
          'Должны быть предварительно просушены или прокалены',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63662094',
        text:
          'Кем должен быть составлен технический паспорт, содержащий основные технические характеристики, на каждый объект производства/потребления ПРВ?',
        answers: [
          'Организацией (заказчиком)',
          'Техническим руководителем организации',
          'Ростехнадзором',
          'Главным механиком',
        ],
        correctAnswers: ['Организацией (заказчиком)'],
      },
      {
        code: '63662095',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 12 лет',
          'Один раз в 10 лет',
          'Один раз в 7 лет',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63662096',
        text:
          'Какая предельная величина заполнения тары пиридиновым продуктом?',
        answers: [
          'Не более 85%',
          'Не более 80%',
          'Не более 90%',
          'Не более 95%',
        ],
        correctAnswers: ['Не более 90%'],
      },
      {
        code: '63662097',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На наличие дефектов и ненадежных соединений путем проведения гидроиспытаний',
          'На плотность под рабочим давлением',
          'На отсутствие загрязнений',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63662098',
        text:
          'Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?',
        answers: [
          'Только номер сертификата на заглушку',
          'Только номер заглушки',
          'Только марка материала',
          'Только условное давление и условный диаметр',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63662099',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63662100',
        text:
          'Какая должна быть толщина перекрывающих листов заглушек и листовых задвижек газопроводов?',
        answers: [
          'Не менее 4 мм',
          'Не менее 3 мм',
          'Не менее 2 мм',
          'Не менее 5 мм',
        ],
        correctAnswers: ['Не менее 4 мм'],
      },
      {
        code: '63662101',
        text:
          'На содержание какого газа необходимо проводить анализ состава циркулирующего газа с помощью автоматических газоанализаторов в УСТК?',
        answers: [
          'Только СО',
          'Только H2',
          'Только O2',
          'Только CH4',
          'Всех перечисленных газов',
        ],
        correctAnswers: ['Всех перечисленных газов'],
      },
      {
        code: '63662102',
        text:
          'Какое должно быть минимальное расстояние между каждой парой баллонов на каждом уровне размещения баллонов у мест потребления для постоянных потребителей небольших количеств ПРВ?',
        answers: ['12 м', '10 м', '8 м', '5 м'],
        correctAnswers: ['12 м'],
      },
      {
        code: '63662103',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['2 м', '1,5 м', '1 м', '5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63662104',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'При стабильной работе газоиспользующего оборудования',
          'На усмотрение ответственного за безопасное производство работ',
          'Если проводится периодическая корректировка режимных карт',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63662105',
        text:
          'Как должны производиться работы в случае повышения концентрации вредных веществ выше допустимой нормы в местах мойки тары, а также разливки пиридиновых и хинолиновых продуктов?',
        answers: [
          'Не менее чем двумя работниками в изолирующих аппаратах под наблюдением газоспасателя',
          'Не менее чем двумя работниками в фильтрующих аппаратах под наблюдением газоспасателя',
          'Одним работником в изолирующем аппарате под наблюдением газоспасателя',
          'Не менее чем двумя работниками в изолирующих аппаратах под наблюдением работника газовой службы',
        ],
        correctAnswers: [
          'Не менее чем двумя работниками в изолирующих аппаратах под наблюдением газоспасателя',
        ],
      },
      {
        code: '63662106',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Работы продолжаются без оформления акта-допуска',
          'Составляется новый акт-допуск на следующий срок',
          'Продлевается акт-допуск, изначально оформленный на этот вид работ',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63662107',
        text:
          'Через какие промежутки времени производится продувка влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?',
        answers: [
          'Через каждые 30 минут',
          'Через каждые 45 минут',
          'Через каждые 15 минут',
          'Через каждые 60 минут',
        ],
        correctAnswers: ['Через каждые 30 минут'],
      },
      {
        code: '63662108',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено на поддонах в производственном помещении',
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено в закрытых шкафах',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63662109',
        text:
          'Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.',
        answers: ['CO', 'O₂', 'CO₂', 'H₂'],
        correctAnswers: ['CO', 'O₂'],
      },
      {
        code: '63662110',
        text:
          'Какой бригадой необходимо выполнять работы в камере перед фильтром во время работы воздушного центробежного компрессора и воздушного фильтра?',
        answers: [
          'Состоящей не менее чем из двух человек',
          'Состоящей не более чем из трёх человек',
          'Состоящей не менее чем из четырёх человек',
        ],
        correctAnswers: ['Состоящей не менее чем из двух человек'],
      },
      {
        code: '63662111',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полугодие',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63662112',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,5 м в свету',
          'Не менее 3,5 м в свету',
          'Не менее 1,0 м в свету',
          'Не менее 0,5 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63662113',
        text:
          'Что из перечисленного не требуется обрабатывать известковым молоком для снижения возможности загорания коксового газа до начала выполнения работ?',
        answers: [
          'Только поверхности фланцев',
          'Только поверхности стен и пола газового цеха',
          'Необходимо обрабатывать все перечисленное',
          'Только поверхности заглушек',
          'Только поверхности газопроводов',
        ],
        correctAnswers: ['Только поверхности стен и пола газового цеха'],
      },
      {
        code: '63662114',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых металлических ящиках с крышками',
          'На специально оборудованной площадке',
          'В специально выделенном помещении',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63662115',
        text:
          'Какое требование предъявляется к сбросным трубопроводам, к которым должны подключаться транспортные сосуды для жидких ПРВ в течение всего времени нахождения их в гараже?',
        answers: [
          'Должны быть выведены на 2 м выше конька крыши гаража',
          'Должны быть выведены на 3 м выше конька крыши гаража',
          'Должны быть выведены на 1 м выше конька крыши гаража',
          'Должны быть вровень с коньком крыши гаража',
        ],
        correctAnswers: [
          'Должны быть выведены на 2 м выше конька крыши гаража',
        ],
      },
      {
        code: '63662116',
        text:
          'Какую отличительную полосу должен иметь инструмент, предназначенный для разборки и ремонта узлов, работающих в кислородной среде ПРВ?',
        answers: ['Голубую', 'Красную', 'Белую', 'Оранжевую'],
        correctAnswers: ['Голубую'],
      },
      {
        code: '63662117',
        text:
          'Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?',
        answers: [
          'Не менее 2,0 величины рабочего давления охлаждающей воды',
          'Не менее 1,5 величины рабочего давления охлаждающей воды',
          'Не менее 1,25 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: [
          'Не менее 1,5 величины рабочего давления охлаждающей воды',
        ],
      },
      {
        code: '63662118',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В производственных помещениях',
          'Размещение операторных помещений допускается во всех перечисленных местах',
          'В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами',
          'В отдельно стоящих зданиях',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63662119',
        text:
          'Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?',
        answers: [
          'Проливы растворителей на пол не допускаются, случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними',
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
        correctAnswers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
      },
      {
        code: '63662120',
        text:
          'Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'Обезжиривание производится в сроки, определенные разработчиком ВРУ',
          'Не реже одного раза в год',
          'Не регламентируются',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63662121',
        text:
          'С какой периодичностью все коммуникации (трубопроводы и арматура) коксохимических производств должны подвергаться комиссионному обследованию?',
        answers: [
          'Один раз в пять лет',
          'Один раз в два года',
          'Один раз в три года',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63662122',
        text:
          'При каком условии должны производиться ремонт и ручная очистка путей тушильного вагона? Выберите правильный вариант ответа.',
        answers: [
          'Только во время остановки выдачи кокса',
          'Только с обязательным снятием напряжения с троллей электровоза',
          'Только под наблюдением лиц, ответственных за проведение работ',
          'При выполнении всех перечисленных условий',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63662123',
        text:
          'Транспортирование и перемешивание каких легковоспламеняющихся продуктов с помощью сжатого воздуха не допускается? Выберите правильный вариант ответа.',
        answers: [
          'Только сырого бензола',
          'Только продуктов ректификации бензола',
          'Только пиридиновых оснований',
          'Всех перечисленных легковоспламеняющихся продуктов',
        ],
        correctAnswers: ['Всех перечисленных легковоспламеняющихся продуктов'],
      },
      {
        code: '63662124',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Один раз в полгода',
          'Один раз в два года',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63662125',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть прогреты до температуры не менее 45 °С',
          'Должны быть очищены от мусора',
          'Должны быть выдержаны в отапливаемом помещении не менее суток',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63662126',
        text:
          'В течение какого времени должна храниться диаграмма испытаний предохранительного клапана для взрывоопасных и агрессивных опасных сред?',
        answers: [
          'В течение трех лет',
          'В течение шести месяцев',
          'В течение года',
          'В течение двух лет',
        ],
        correctAnswers: ['В течение трех лет'],
      },
      {
        code: '63662127',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 99,1%',
          'Не менее 98,7%',
          'Не менее 95,7%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63662128',
        text:
          'Какие из перечисленных требований, предъявляемых к коксовым цехам, указаны верно?',
        answers: [
          'Входные двери кабин контакторных панелей коксовых машин должны быть постоянно закрыты на замок и иметь блокировку или сигнализацию об их открывании, выведенную в кабину машиниста',
          'При работе двух электровозов на одну рампу порядок их передвижения должен определяться проектной документацией',
          'Уборка пыли должна производиться еженедельно',
          'Все перечисленные требования указаны верно',
          'Допускается спуск кусков недотушенного кокса с рампы на конвейерную ленту',
        ],
        correctAnswers: [
          'Входные двери кабин контакторных панелей коксовых машин должны быть постоянно закрыты на замок и иметь блокировку или сигнализацию об их открывании, выведенную в кабину машиниста',
        ],
      },
      {
        code: '63662129',
        text:
          'Какое максимальное значение не должно превышать падение давления в системе за 4 часа при начальном давлении, равном рабочему, при испытаниях на плотность соединений коммуникаций и технических устройств по переработке криптоноксенонового концентрата, смеси криптона и ксенона?',
        answers: ['0,01 МПа', '0,05 МПа', '0,1 МПа', '0,5 МПа'],
        correctAnswers: ['0,01 МПа'],
      },
      {
        code: '63662130',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу A', 'Классу В', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу В'],
      },
      {
        code: '63662131',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
        ],
        correctAnswers: [
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
        ],
      },
      {
        code: '63662132',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.',
        answers: [
          'Сливание расплава металла из ковшей и миксеров должно быть механизировано',
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63662133',
        text:
          'Какие из перечисленных действий не осуществляются в случае прорыва газа в помещение либо наружу через неплотности газопроводов и аппаратов цеха улавливания химических продуктов коксования?',
        answers: [
          'Снижение давления газа путем уменьшения отсоса',
          'Отключение участков с нарушенной герметичностью',
          'Усиление естественной вентиляции помещения',
          'Включение аварийной сигнализации',
        ],
        correctAnswers: ['Включение аварийной сигнализации'],
      },
      {
        code: '63662134',
        text:
          'В каком случае допускается обмерзание опорной рамы насоса при работе поршневых и центробежных насосов жидких ПРВ?',
        answers: [
          'Только в местах у выводных штуцеров',
          'Только в случае обмерзания стояночных и динамических уплотнителей',
          'Только в случае обмерзания сальников насосов',
          'Обмерзание опорной рамы насоса не допускается',
        ],
        correctAnswers: ['Только в местах у выводных штуцеров'],
      },
      {
        code: '63662135',
        text:
          'Какие действия необходимо произвести с деталями, подвергаемыми охлаждению в ваннах с жидким азотом? Выберите 2 варианта ответа.',
        answers: [
          'Обезжирить',
          'Высушить',
          'Очистить',
          'Смазать техническим маслом',
        ],
        correctAnswers: ['Обезжирить', 'Высушить'],
      },
      {
        code: '63662136',
        text:
          'В каких системах кислородоснабжения операции по снижению и поддержанию давления кислорода, регулированию его расхода следует производить в КРП?',
        answers: [
          'С расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа',
          'С расходами свыше 4000 м³/ч и давлением от 0,6 до 4,0 МПа',
          'С расходами свыше 2000 м³/ч и давлением от 0,6 до 4,0 МПа',
          'С расходами свыше 6000 м³/ч и давлением от 0,3 до 2,0 МПа',
        ],
        correctAnswers: [
          'С расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа',
        ],
      },
      {
        code: '63662137',
        text:
          'С какой периодичностью необходимо производить анализ газа при аварийном выходе из строя газоанализаторов в УСТК?',
        answers: [
          'Не реже двух раз в день',
          'Не реже двух раз в смену',
          'Не реже одного раза в смену',
          'Не реже одного раза в день',
        ],
        correctAnswers: ['Не реже двух раз в смену'],
      },
      {
        code: '63662138',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации. Не допускается уборка, вызывающая распыление вредных веществ',
          'Все перечисленные требования указаны неверно',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63662139',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите все правильные варианты ответа.',
        answers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
          'При остановке оборудования на 1 час',
          'В случае превышения температуры оборудования более чем на 25 % от рабочих показателей',
        ],
        correctAnswers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
        ],
      },
      {
        code: '63662140',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 4 года',
          'Не позднее чем через 5 лет',
          'Не позднее чем через 7 лет',
          'Не позднее чем через 10 лет',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63662141',
        text:
          'Чем должны быть продуты трубопроводы в коксохимическом производстве? Выберите 2 варианта ответа.',
        answers: [
          'Инертными газами',
          'Сжатым воздухом',
          'Углекислым газом',
          'Водяным паром',
        ],
        correctAnswers: ['Инертными газами', 'Водяным паром'],
      },
      {
        code: '63662142',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Количество производителей (руководителей) работ определяет работник, выдающий наряд-допуск',
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
          'Один производитель (руководитель) работ',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63662143',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу A', 'Классу В', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу A'],
      },
      {
        code: '63662144',
        text:
          'С какой периодичностью необходимо проверять работоспособность автоматических блокирующих и регулирующих систем и систем сигнализации производств ПРВ?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в два месяца',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в шесть месяцев',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63662145',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 10% нижнего предела взрываемости (0,4% об.)',
          'Выше 20% нижнего предела взрываемости (0,8% об.)',
          'Выше 25% нижнего предела взрываемости (1% об.)',
          'Выше 35% нижнего предела взрываемости (1,4% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63662146',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Жетон-бирочная система применяется при выводе технических устройств (ТУ) на продолжительную остановку',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63662147',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['2,5 часа', '45 минут', '1,5 часа', '1 час'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63662148',
        text:
          'На каком расстоянии на каждом кислородопроводе перед КРП необходимо устанавливать отключающую задвижку с дистанционным управлением?',
        answers: [
          'Не менее 10 м и не более 50 м',
          'Не менее 5 м и не более 30 м',
          'Не менее 5 м и не более 50 м',
          'Не менее 10 м и не более 30 м',
        ],
        correctAnswers: ['Не менее 10 м и не более 50 м'],
      },
      {
        code: '63662149',
        text:
          'Что из перечисленного допускается при эксплуатации компрессоров ПРВ?',
        answers: [
          'Использовать масла в воздушных фильтрах при очистке воздуха, поступающего на сжатие в центробежные, осецентробежные, осевые и поршневые воздушные компрессоры, работающие без смазки цилиндров',
          'Использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей',
          'Применять дистиллят, полученный из воды питьевого качества, для смазки цилиндров поршневых кислородных компрессоров',
          'Применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия ПРВ',
        ],
        correctAnswers: [
          'Применять дистиллят, полученный из воды питьевого качества, для смазки цилиндров поршневых кислородных компрессоров',
        ],
      },
      {
        code: '63662150',
        text:
          'Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров воздухоразделительных установок (далее – ВРУ) при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?',
        answers: ['Менее 200 м', 'Менее 150 м', 'Менее 100 м'],
        correctAnswers: ['Менее 100 м'],
      },
      {
        code: '63662151',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 16 мм',
          'Не менее 12 мм',
          'Не менее 10 мм',
          'Не менее 20 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63662152',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'Только в соответствии с требованиями строительными нормами и правилами',
          'Только в соответствии с требованиями нормативно-технической документацией для технологических стальных трубопроводов',
          'Только в соответствии с требованиями Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63662153',
        text:
          'Требования какого документа необходимо соблюдать при устройстве, размещении и эксплуатации технических устройств по переработке или очистке криптоноксенонового концентрата, по получению криптоноксеноновых смесей, криптона и ксенона?',
        answers: [
          'Проекта',
          'Технологической инструкции',
          'Правил безопасности процессов получения или применения металлов',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63662154',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю в помещениях, а вне помещений - не реже одного раза в месяц',
          'Не реже одного раза в две недели в помещениях, а вне помещений - не реже одного раза в два месяца',
          'Не реже одного раза в месяц в помещениях, а вне помещений - не реже одного раза в три месяца',
          'Не реже одного раза в декаду в помещениях, а вне помещений - не реже одного раза в два месяца',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю в помещениях, а вне помещений - не реже одного раза в месяц',
        ],
      },
      {
        code: '63662155',
        text:
          'С какой периодичностью должна производиться ревизия предохранительных клапанов в коксохимическом производстве?',
        answers: [
          'Не реже одного раза в пять лет',
          'Не реже одного раза в три года',
          'Не реже одного раза в десять лет',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63662156',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 15% больше расчетного',
          'На 10% больше расчетного',
          'На 5% больше расчетного',
          'На 25% больше расчетного',
        ],
        correctAnswers: ['На 15% больше расчетного'],
      },
      {
        code: '63662157',
        text:
          'Для каких реципиентов ПРВ в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?',
        answers: [
          'Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа',
          'Вместимостью более 100 м³ с рабочим давлением более 1,6 МПа',
          'Вместимостью более 200 м³ с рабочим давлением более 1,2 МПа',
          'Вместимостью более 200 м³ с рабочим давлением более 1,4 МПа',
        ],
        correctAnswers: [
          'Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа',
        ],
      },
      {
        code: '63662158',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['85 °C', '87 °C', '95 °C', '90 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63662159',
        text:
          'Каким образом не допускается производить выдачу пека из кубов и пекотушителей при отсутствии самотека?',
        answers: [
          'Сжатым воздухом',
          'Допускается всеми перечисленными способами',
          'Под давлением пара',
          'Откачивающим насосом',
        ],
        correctAnswers: ['Сжатым воздухом'],
      },
      {
        code: '63662160',
        text:
          'Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 2,0 величины рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63662161',
        text:
          'В соответствии с чем осуществляется порядок использования реагентов на углеобогатительных производствах?',
        answers: [
          'В соответствии с инструкцией по эксплуатации оборудования',
          'В соответствии с технологической инструкцией',
          'В соответствии с производственной инструкцией',
          'В соответствии с Правилами безопасности процессов получения или применения металлов',
        ],
        correctAnswers: ['В соответствии с технологической инструкцией'],
      },
      {
        code: '63662162',
        text:
          'Какие требования установлены к алюминиевой стружке, принимаемой на хранение?',
        answers: [
          'Не должна иметь крупных включений',
          'Должна быть сухой, без следов масла и грязи',
          'Должна быть в брикетах',
        ],
        correctAnswers: ['Должна быть сухой, без следов масла и грязи'],
      },
      {
        code: '63662163',
        text:
          'В каких резервуарах необходимо хранить бензолсодержащие технологические продукты?',
        answers: [
          'В герметичных стальных резервуарах',
          'В железобетонных резервуарах',
          'В организованных в природных пустотах резервуарах',
          'В герметичных неметаллических резервуарах',
        ],
        correctAnswers: ['В герметичных стальных резервуарах'],
      },
      {
        code: '63662164',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 50 м',
          'В радиусе 100 м',
          'В радиусе 150 м',
          'В радиусе 25 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63662165',
        text:
          'Какое допускается максимальное содержание кислорода в коксовом газе?',
        answers: ['0.02', '0.03', '0.01', '0.04'],
        correctAnswers: ['0.01'],
      },
      {
        code: '63662166',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63662167',
        text:
          'Какое расчетное давление газа соответствует газопроводам среднего давления?',
        answers: [
          'Свыше 0,1 до 0,3 МПа включительно',
          'Свыше 0,3 до 1,2 МПа включительно',
          'Свыше 0,6 до 1,2 МПа включительно',
          'Свыше 0,3 до 0,6 МПа включительно',
        ],
        correctAnswers: ['Свыше 0,1 до 0,3 МПа включительно'],
      },
      {
        code: '63662168',
        text:
          'В чьем ведении должен находиться агрегатный (ремонтный) журнал на объектах кислородного цеха и в цехах, в составе которых имеются объекты потребления ПРВ?',
        answers: [
          'В ведении лица, ответственного за исправное техническое состояние объекта газового хозяйства',
          'В ведении дежурного персонала, который должен вести в журнале регулярные записи',
          'В ведении ремонтного персонала, который должен вести в журнале регулярные записи',
          'В ведении главного механика',
        ],
        correctAnswers: [
          'В ведении лица, ответственного за исправное техническое состояние объекта газового хозяйства',
        ],
      },
      {
        code: '63662169',
        text:
          'В каком случае разрешается перевод обогрева коксовых батарей с коксового газа на доменный?',
        answers: [
          'Разрешается в дневное и ночное время суток при использовании переносных светильников',
          'Запрещается в любом случае',
          'Разрешается только в дневное время суток',
          'Разрешается в любом случае',
        ],
        correctAnswers: ['Разрешается только в дневное время суток'],
      },
      {
        code: '63662170',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,1% в час',
          'Не более 0,2% в час',
          'Не более 0,3% в час',
          'Не более 0,5% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63662171',
        text:
          'Чем следует продувать установки очистки сырого аргона от кислорода и водородные коммуникации перед подачей водорода в них и после остановки? Выберите 2 варианта ответа.',
        answers: [
          'Сырым аргоном',
          'Азотом с объемной долей кислорода не более 4%',
          'Углекислым газом',
          'Паром',
          'Воздухом',
        ],
        correctAnswers: [
          'Сырым аргоном',
          'Азотом с объемной долей кислорода не более 4%',
        ],
      },
      {
        code: '63662172',
        text:
          'Что из перечисленного необходимо производить перед вскрытием устройств или трубопроводов, заполненных жидкими или газообразными продуктами разделения воздуха? Выберите правильный вариант ответа.',
        answers: [
          'Слить жидкие ПРВ из сосудов и трубопроводов',
          'Установить предупреждающий знак безопасности с поясняющей надписью: "Не включать, работают люди!"',
          'Снизить до атмосферного давление в устройствах и трубопроводах, подлежащих ремонту',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63662173',
        text:
          'Кто является ответственным за хранение технических паспортов на каждый объект производства/потребления ПРВ?',
        answers: [
          'Лицо, ответственное за безопасную эксплуатацию',
          'Лицо, ответственное за исправное техническое состояние данного объекта',
          'Руководитель объекта',
          'Главный инженер',
        ],
        correctAnswers: ['Лицо, ответственное за безопасную эксплуатацию'],
      },
      {
        code: '63662174',
        text:
          'В каком случае допускается попеременное использование технических устройств и коммуникаций, работающих с кислородом, для работы с воздухом, азотом, аргоном и другими газами?',
        answers: [
          'Не допускается ни в каком случае',
          'В случаях, предусмотренных технологическим процессом',
          'В случае пропаривания и продувки оборудования после работы с кислородом',
          'В случае промывки оборудования после работы с кислородом',
        ],
        correctAnswers: [
          'В случаях, предусмотренных технологическим процессом',
        ],
      },
      {
        code: '63662175',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Из стали',
          'Из ковкого и высокопрочного чугуна',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63662176',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны быть выполнены во взрывозащищенном исполнении',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
          'Приборы должны устанавливаться в непосредственной близости к оборудованию',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63662177',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенного вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['50 м', '100 м', '25 м', '10 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63662178',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: [
          'Только паром',
          'Только сжатым воздухом',
          'Только газом',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63662179',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: [
          'Один раз в четыре года',
          'Один раз в два года',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в два года'],
      },
      {
        code: '63662180',
        text:
          'Какое значение является предминимальной степенью заполнения мокрых и сухих стальных газгольдеров постоянного давления для ПРВ с сигнализацией по уровню заполнения?',
        answers: [
          '10% полного объема',
          '20% полного объема',
          '80% полного объема',
          '90% полного объема',
        ],
        correctAnswers: ['20% полного объема'],
      },
      {
        code: '63662181',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Должно быть предусмотрено всё перечисленное',
          'Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Только исключение падения транспортируемых материалов',
          'Только предохраняющие устройства, отключающие приводы при перегрузке',
        ],
        correctAnswers: ['Должно быть предусмотрено всё перечисленное'],
      },
      {
        code: '63662182',
        text:
          'Какая продолжительность продувки воздухом или азотом перед началом эксплуатации кислородопровода?',
        answers: [
          'Не менее 2 часов',
          'Не менее 5 часов',
          'Не менее часа',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 2 часов'],
      },
      {
        code: '63662183',
        text:
          'С какими цехами машинное отделение цехов улавливания химических продуктов должно иметь прямую телефонную связь? Выберите 2 варианта ответа.',
        answers: [
          'С коксовым цехом',
          'С цехом потребителя газа',
          'С пекоксовым цехом',
          'С химическим цехом',
          'С цехом ректификации',
        ],
        correctAnswers: ['С коксовым цехом', 'С цехом потребителя газа'],
      },
      {
        code: '63662184',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы должны производиться цеховым обслуживающим персоналом',
          'Работы допускается производить взрывниками в процессе эксплуатации оборудования цеха',
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63662185',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения, работающие от напряжения 12 В',
          'Должны предусматриваться системы освещения, работающие от напряжения 24 В',
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63662186',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее одного раза в три года',
          'Не менее двух раз в год',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63662187',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В наклонном положении с вентилем, направленным вверх',
          'В вертикальном положении',
          'В горизонтальном положении',
          'В любом положении',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63662188',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
          'Должны расставляться на свободных площадках пролета цеха',
          'Должны расставляться только на горизонтальных площадках в любом месте пролета цеха',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63662189',
        text:
          'Какие требования предъявляются к корзинам, загружаемым в щелочные ванны?',
        answers: [
          'Должны быть наполнены промасленным металлом',
          'Должны быть наполнены горячим металлом',
          'Должны быть не влажными',
        ],
        correctAnswers: ['Должны быть не влажными'],
      },
      {
        code: '63662190',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
          'Пока на участке продувки кислорода будет не более 3% кислорода',
          'Пока на участке продувки кислорода будет не более 5% кислорода',
          'Пока на участке продувки кислорода будет не более 8% кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
        ],
      },
      {
        code: '63662191',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: [
          'При скорости коррозии 0,1 - 0,5 мм/г',
          'При скорости коррозии более 0,5 мм/г',
          'При скорости коррозии до 0,1 мм/г',
        ],
        correctAnswers: ['При скорости коррозии 0,1 - 0,5 мм/г'],
      },
      {
        code: '63662192',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На плотность', 'На прочность', 'На герметичность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63662193',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?',
        answers: [
          'Сероводорода - 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
          'Сероводорода - 0,01 г/нм³, нафталина - 0,03 г/нм³ (летом) и 0,2 г/нм³ (зимой)',
          'Сероводорода - 0,03 г/нм³, нафталина - 0,04 г/нм³ (летом) и 0,4 г/нм³ (зимой)',
          'Сероводорода - 0,05 г/нм³, нафталина - 0,07 г/нм³ (летом) и 0,3 г/нм³ (зимой)',
        ],
        correctAnswers: [
          'Сероводорода - 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
        ],
      },
      {
        code: '63662194',
        text:
          'В присутствии кого должен осуществляться розжиг горелок в трубчатых печах?',
        answers: [
          'Начальника смены',
          'Мастера смены',
          'Специалиста-технолога',
          'Технического руководителя',
        ],
        correctAnswers: ['Начальника смены'],
      },
      {
        code: '63662195',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже двух раз в год',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63662196',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь по периметру пешеходные проходы шириной 1,0 м',
          'Со всех сторон должны иметь ограждение',
          'Должны иметь по периметру пешеходные проходы шириной 0,8 м',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63662197',
        text:
          'Какие из перечисленных работ необходимо производить не реже одного раза в смену в цехах улавливания химических продуктов коксования?',
        answers: [
          'Все перечисленные работы',
          'Только проверять стоки из аппаратуры и газопроводов в гидрозатворы',
          'Только производить пропарку линий стоков в гидрозатворы и из них',
          'Только проверять стоки из аппаратуры и газопроводов в конденсатоотводчики',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63662198',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63662199',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Горячим воздухом, паром или горячей водой',
          'Газовой горелкой',
          'Теплым песком',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63662200',
        text:
          'К какому уровню заполнения мокрых и сухих стальных газгольдеров постоянного давления для ПРВ относится значение 80% полного объема?',
        answers: [
          'Минимальному',
          'Предминимальному',
          'Предмаксимальному',
          'Максимальному',
        ],
        correctAnswers: ['Предмаксимальному'],
      },
      {
        code: '63662201',
        text:
          'В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?',
        answers: [
          'Без предварительной замены колец и цапфы',
          'Без предварительной замены футеровки',
          'Без предварительной замены стопора и стакана',
        ],
        correctAnswers: ['Без предварительной замены стопора и стакана'],
      },
      {
        code: '63662202',
        text:
          'Какие из перечисленных работ относятся к работам повышенной опасности? Выберите правильный вариант ответа.',
        answers: [
          'Только работы в закрытых резервуарах, цистернах, тоннелях, ямах и дымоходах, где возможно отравление и удушье',
          'Только работы в замкнутых пространствах',
          'Только работы внутри горячей печи',
          'Все перечисленные виды работ',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63662203',
        text:
          'Чем должна выполняться продувка блока разделения воздуха при производстве его ремонта после обезжиривания аппаратов и коммуникаций блока?',
        answers: ['Воздухом', 'Кислородом', 'Азотом', 'Углекислым газом'],
        correctAnswers: ['Воздухом'],
      },
      {
        code: '63662204',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'Со службой производственного контроля',
          'С территориальным органом Ростехнадзора',
          'С главным инженером',
          'С ГСС',
        ],
        correctAnswers: ['Со службой производственного контроля', 'С ГСС'],
      },
      {
        code: '63662205',
        text:
          'На каком центробежном кислородном компрессоре необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?',
        answers: [
          'С давлением нагнетания более 0,6 МПа',
          'С давлением нагнетания более 0,5 МПа',
          'С давлением нагнетания более 0,4 МПа',
          'С давлением нагнетания более 0,2 МПа',
        ],
        correctAnswers: ['С давлением нагнетания более 0,6 МПа'],
      },
      {
        code: '63662206',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Фонарные переплеты',
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Окна с обычным оконным стеклом толщиной 3 мм и площадью 0,8 м²',
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
          'Все перечисленные конструкции относятся к легкосбрасываемым',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
      },
      {
        code: '63662207',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в три месяца',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63662208',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'В аварийных случаях',
          'При наличии письменного разрешения главного инженера',
          'Не допускается ни в каком случае',
          'Если в радиусе 50 м приостановлено движение всех видов транспорта',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63662209',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак? Выберите правильный вариант ответа.',
        answers: [
          'Только загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Не допускается все перечисленное',
          'Только загрузка шихты, не прошедшей пиротехнического контроля',
        ],
        correctAnswers: [
          'Только загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63662210',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Должны быть изготовлены из железа или его сплавов',
          'Должны быть изготовлены из стали',
          'Не должны давать искры при работе',
        ],
        correctAnswers: ['Не должны давать искры при работе'],
      },
      {
        code: '63662211',
        text:
          'В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если изменился состав бригады',
          'Если появилась угроза жизни и здоровью работников',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
          'Если подан аварийный сигнал',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63662212',
        text:
          'С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?',
        answers: [
          'Один раз в четыре года',
          'Один раз в два года',
          'Один раз в пять лет',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63662213',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждые пять лет',
          'Каждый год',
          'Через каждые два года',
          'Через каждые три года',
        ],
        correctAnswers: ['Через каждые три года'],
      },
      {
        code: '63662214',
        text:
          'Какое максимальное значение не должно превышать содержание масел в воде, используемой для гидроиспытаний кислородопроводов?',
        answers: ['5,0 мг/л', '10,0 мг/л', '15,0 мг/л', '20,0 мг/л'],
        correctAnswers: ['5,0 мг/л'],
      },
      {
        code: '63662215',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 18% и не более 23%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63662216',
        text:
          'На какие поверхности не допускается слив жидких ПРВ? Выберите правильный вариант ответа.',
        answers: [
          'Только на пол в производственном помещении',
          'Только на грунт',
          'Только на асфальтовое покрытие',
          'На все перечисленные поверхности',
        ],
        correctAnswers: ['На все перечисленные поверхности'],
      },
      {
        code: '63662217',
        text:
          'Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?',
        answers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
          'Должны покрываться огнеупорными материалами для защиты от воздействия тепловой нагрузки',
          'Должны защищаться специальными смазочными материалами от воздействия лучистого тепла',
        ],
        correctAnswers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
        ],
      },
      {
        code: '63662218',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'Только при наличии раковин, трещин в стенках и в местах крепления цапф',
          'Только при потере формы ковша вследствие деформации',
          'Только при поврежденной футеровке ковша',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63662219',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после увлажнения и охлаждения',
          'Только после тщательной их очистки и просушивания',
          'Только после тщательного их просушивания и разогрева',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63662220',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В журнал технического обслуживания и ремонта оборудования',
          'В эксплуатационный журнал',
          'В паспорт оборудования',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63662221',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с начальником газоспасательной службы',
          'Со всеми перечисленными',
          'Только со службой производственного контроля',
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63662222',
        text:
          'При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?',
        answers: ['0.02', '0.015', '0.01', '0.005'],
        correctAnswers: ['0.02'],
      },
      {
        code: '63662223',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'В случае аварии дополнительно к постоянно работающей общеобменной вентиляции производства водорода должна автоматически включаться аварийная приточная система',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 4 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 6 в час',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63662224',
        text:
          'Какие надписи должны наноситься на кожухах стационарных хранилищ и на транспортных сосудах (цистерны) жидких ПРВ? Выберите 2 варианта ответа.',
        answers: [
          'Наименование хранимого продукта',
          'Отличительные полосы',
          'Надписи с допустимым уровнем заполнения хранимого продукта',
          'Надписи о пожароопасности хранимого продукта',
        ],
        correctAnswers: [
          'Наименование хранимого продукта',
          'Отличительные полосы',
        ],
      },
      {
        code: '63662225',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 10 минут',
          'В течение 5 минут',
          'В течение 15 минут',
          'В течение 20 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63662226',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
          'При условии выданного разрешения от лиц, ответственных за техническое состояние газового хозяйства',
          'При снятии для ремонта манометра',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63662227',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 48 часов',
          'Не менее 12 часов',
          'Не менее 8 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63662228',
        text:
          'В каком случае допускается продление срока эксплуатации воздухоразделительной установки между двумя полными отогревами? Выберите правильный вариант ответа.',
        answers: [
          'Только по согласованию с разработчиком установки',
          'Только по согласованию с главным механиком',
          'Только по согласованию с главным инженером',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Только по согласованию с разработчиком установки'],
      },
      {
        code: '63662229',
        text:
          'Какие действия из перечисленных допускаются при эксплуатации обесфеноливающего скруббера? Выберите правильный вариант ответа.',
        answers: [
          'Все перечисленные действия',
          'Только подача холодной воды или холодных фенолятов в работающий скруббер',
          'Только закрытие крана на гидрозатворе скруббера',
          'Только включение вентилятора при закрытом дроссельном клапане',
        ],
        correctAnswers: [
          'Только включение вентилятора при закрытом дроссельном клапане',
        ],
      },
      {
        code: '63662230',
        text:
          'В какие тона окрашивается наружная поверхность стальных газгольдеров и реципиентов ПРВ, расположенных вне здания?',
        answers: [
          'В светлые тона',
          'В темные тона',
          'В любые тона только коррозионностойкими красками',
        ],
        correctAnswers: ['В светлые тона'],
      },
      {
        code: '63662231',
        text:
          'Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?',
        answers: [
          'Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики',
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
          'Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения',
          'Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены',
        ],
        correctAnswers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
      },
      {
        code: '63662232',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Приоткрывание вентиля кислорода',
          'Открытие вентиля горючего газа',
          'Кратковременная продувка рукава для удаления воздуха',
          'Зажигание горючей смеси газов',
        ],
        correctAnswers: ['Приоткрывание вентиля кислорода'],
      },
      {
        code: '63662233',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Только работниками эксплуатирующей организации',
          'Только работниками подрядных организаций',
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63662234',
        text:
          'Согласно какой документации должны производиться включение в работу, продувка распределительных газопроводов коксовых печей коксового или смешанного (коксовый и доменный) газов?',
        answers: [
          'Согласно технологической инструкции',
          'Согласно инструкции предприятия-изготовителя',
          'Согласно производственной инструкции',
          'Согласно распорядительной документации эксплуатирующей организации',
        ],
        correctAnswers: ['Согласно технологической инструкции'],
      },
      {
        code: '63662235',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные изготовителем',
          'В сроки, установленные эксплуатирующей организацией',
          'В сроки, установленные Ростехнадзором',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63662236',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'На расстоянии 20 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания',
          'Во всех перечисленных зонах',
          'В зоне погрузки грейферными или магнитными кранами',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63662237',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: [
          'Один раз в четыре года',
          'Один раз в два года',
          'Один раз в пять лет',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63662238',
        text:
          'Чем следует производить тушение горящего угля? Выберите все правильные варианты ответа.',
        answers: [
          'Песком',
          'Углекислотными огнетушителями',
          'Распыленной водой',
          'Паром',
          'Пеной',
        ],
        correctAnswers: ['Распыленной водой', 'Паром'],
      },
      {
        code: '63662239',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.',
        answers: [
          'Продувать кислородный рукав горючим газом',
          'Продувать рукав для горючих газов кислородом',
          'Взаимозаменять кислородный рукав и рукав для горючих газов при работе',
          'Направлять пламя горелки (резак) в сторону, противоположную источнику газоснабжения, при работе',
          'Допускаются все перечисленные действия',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резак) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63662240',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,05 МПа (0,5 кгс/см²)',
          'При давлении 0,02 МПа (0,2 кгс/см²)',
          'При давлении 0,01 МПа (0,1 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63662241',
        text:
          'С какой периодичностью следует производить текущий ремонт оборудования КРП?',
        answers: [
          'Не менее двух раз в год',
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее одного раза в три года',
        ],
        correctAnswers: ['Не менее двух раз в год'],
      },
      {
        code: '63662242',
        text:
          'При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?',
        answers: ['20 мг/м³', '15 мг/м³', '25 мг/м³', '30 мг/м³'],
        correctAnswers: ['20 мг/м³'],
      },
      {
        code: '63662243',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Устранение утечек газа из разъемных соединений технических устройств',
          'Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений',
          'Проверка работоспособности запорной арматуры',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
      {
        code: '63662244',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 3 месяцев',
          'В течение года',
          'В течение месяца',
          'В течение 6 месяцев',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63662245',
        text: 'При каком условии ВРУ должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами, должна подвергаться обезжириванию',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63662246',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'С главным инженером',
          'С Ростехнадзором',
          'С руководителем газоспасательной службы',
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63662247',
        text:
          'Каким составом должны осуществлять эксплуатацию не полностью автоматизированных ГПС, ГКС и ГТРС?',
        answers: [
          'Не менее двух человек в смену',
          'Один человек в смену',
          'Не более двух человек в смену',
          'Эксплуатация ГПС, ГКС и ГТРС допускается без работников',
        ],
        correctAnswers: ['Не менее двух человек в смену'],
      },
      {
        code: '63662248',
        text:
          'Какой бригадой необходимо выполнять работы в помещении камеры после воздушного фильтра во время работы воздушного центробежного компрессора и воздушного фильтра?',
        answers: [
          'Бригадой не менее чем из двух человек',
          'Бригадой не более чем из трёх человек',
          'Бригадой не менее чем из четырёх человек',
          'Не допускается производство работ в помещении камеры после воздушного фильтра',
        ],
        correctAnswers: [
          'Не допускается производство работ в помещении камеры после воздушного фильтра',
        ],
      },
      {
        code: '63662249',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Проверка параметров срабатывания предохранительных запорных и сбросных клапанов',
          'Техническое обслуживание',
          'Текущий ремонт',
          'Капитальный ремонт',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63662250',
        text:
          'С какой периодичностью должна производиться проверка герметичности соединений коммуникаций и технических устройств по переработке криптоноксенонового концентрата, смеси криптона и ксенона в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в сутки',
          'Не реже одного раза в две недели',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63662251',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы, пропитанные креозотом',
          'Деревянные шпалы, пропитанные каменноугольным маслом',
          'Огнестойкие шпалы',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63662252',
        text:
          'С какой периодичностью должен проводиться анализ воздуха рабочей зоны в первые сутки проведения ремонтных работ внутри кожуха блока разделения воздуха и далее?',
        answers: [
          'Через каждые 30 минут, а далее - не реже двух раз в смену',
          'Через каждые 15 минут, а далее - не реже трех раз в смену',
          'Через каждые 60 минут, а далее - не реже одного раза в смену',
          'Через каждые 120 минут, а далее - не реже одного раза в смену',
        ],
        correctAnswers: [
          'Через каждые 30 минут, а далее - не реже двух раз в смену',
        ],
      },
      {
        code: '63662253',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из чугуна',
          'Из алюминия',
          'Из коррозионно-стойкой стали или медных сплавов',
        ],
        correctAnswers: ['Из коррозионно-стойкой стали или медных сплавов'],
      },
      {
        code: '63662254',
        text:
          'Какая информация выбивается на хвостовике заглушки, применяемой для отключения технических устройств и трубопроводов ПРВ? Выберите 2 варианта ответа.',
        answers: [
          'Номер заглушки',
          'Давление, на которое она рассчитана',
          'Наименование газа, применяемого в газопроводе',
          'Допустимая скорость перемещения газа в газопроводе',
        ],
        correctAnswers: [
          'Номер заглушки',
          'Давление, на которое она рассчитана',
        ],
      },
      {
        code: '63662255',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии положительного заключения экспертного обследования',
          'При наличии письменного распоряжения руководителя эксплуатирующей организации',
          'При наличии письменного разрешения Ростехнадзора',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63662256',
        text: 'Через какое расстояние должны заземляться наружные газопроводы?',
        answers: [
          'Через каждые 250 м',
          'Через каждые 200 м',
          'Через каждые 150 м',
          'Через каждые 300 м',
        ],
        correctAnswers: ['Через каждые 250 м'],
      },
      {
        code: '63662257',
        text:
          'Что необходимо подавать в циркулирующий газ для предотвращения образования взрывоопасного состава в УСТК? Выберите 2 варианта ответа.',
        answers: [
          'Азот',
          'Воздух',
          'Пар, содержание кислорода в котором не должно превышать 3,5%',
          'Углекислый газ',
          'Пар, содержание кислорода в котором не должно превышать 5%',
        ],
        correctAnswers: [
          'Азот',
          'Пар, содержание кислорода в котором не должно превышать 3,5%',
        ],
      },
      {
        code: '63662258',
        text:
          'Как должны производиться очистка боровов и ремонтные работы внутри них?',
        answers: [
          'Должны производиться по наряду-допуску при температуре внутри борова не выше 45 °C',
          'Должны производиться по наряду-допуску при температуре внутри борова не выше 50 °C',
          'Должны производиться по наряду-допуску при полной остановке печи',
        ],
        correctAnswers: [
          'Должны производиться по наряду-допуску при полной остановке печи',
        ],
      },
      {
        code: '63662259',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?',
        answers: [
          'Из цветных сплавов',
          'Из поролона или других органических материалов',
          'Из нержавеющей стали',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63662260',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в два года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63662261',
        text:
          'С какой периодичностью проверяются показания дифманометров-уровнемеров с занесением результатов проверки в технологический журнал?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в два месяца',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в шесть месяцев',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63662262',
        text:
          'До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?',
        answers: [
          'До температуры не ниже 20 °C и продуваться воздухом',
          'До температуры не ниже 15 °C и продуваться воздухом',
          'До температуры не ниже 20 °C и продуваться азотом',
          'До температуры не ниже 10 °C и продуваться кислородом',
        ],
        correctAnswers: ['До температуры не ниже 20 °C и продуваться воздухом'],
      },
      {
        code: '63662263',
        text:
          'Какие действия следует немедленно предпринимать во всех случаях отсутствия тяги дымовой трубы? Выберите правильный вариант ответа.',
        answers: [
          'Только прекратить обогрев коксовых печей',
          'Только вывести обслуживающий персонал из помещений батареи',
          'Только принять срочные меры к усилению вентиляции обслуживающих туннелей и других примыкающих к ним помещений',
          'Необходимо немедленно осуществить все перечисленные действия',
        ],
        correctAnswers: [
          'Необходимо немедленно осуществить все перечисленные действия',
        ],
      },
      {
        code: '63662264',
        text:
          'Какое из перечисленных требований к химическим цехам указано неверно?',
        answers: [
          'Слив из железнодорожных цистерн кислоты и щелочи и передача их в хранилища и напорные баки должны осуществляться с помощью перекачивающих насосов без создания избыточного давления в цистернах',
          'На аппаратах и трубопроводах для кислотных растворов в качестве прокладочного материала должны применяться кислотостойкие материалы',
          'Ввод трубопроводов для подачи ЛВЖ в емкости должен располагаться выше уровня сливного трубопровода',
          'Трубопроводы для заполнения и опорожнения емкостей с ЛВЖ должны прокладываться на специальных опорах и закрепляться',
        ],
        correctAnswers: [
          'Ввод трубопроводов для подачи ЛВЖ в емкости должен располагаться выше уровня сливного трубопровода',
        ],
      },
      {
        code: '63662265',
        text:
          'В каком случае сторонние лица имеют право находиться на объектах ПРВ?',
        answers: [
          'В сопровождении специалиста данного производства',
          'В сопровождении главного механика',
          'В сопровождении дежурного электромеханика',
          'В сопровождении представителя газоспасательной службы',
        ],
        correctAnswers: ['В сопровождении специалиста данного производства'],
      },
      {
        code: '63662266',
        text:
          'Какие центробежные кислородные компрессоры должны ограждаться защитными экранами для защиты обслуживающего персонала, на отметке обслуживания?',
        answers: [
          'С давлением нагнетания 0,6 МПа и выше',
          'С давлением нагнетания 0,4 МПа и выше',
          'С давлением нагнетания 0,2 МПа и выше',
          'С давлением нагнетания 0,1 МПа и выше',
        ],
        correctAnswers: ['С давлением нагнетания 0,6 МПа и выше'],
      },
      {
        code: '63662267',
        text:
          'Кто утверждает график проведения технического обслуживания (осмотра, ревизии, ремонта) технических устройств КРП?',
        answers: [
          'Технический руководитель организации',
          'Генеральный директор',
          'Специалист, ответственный за безопасную эксплуатацию оборудования, работающего под давлением',
          'Специалист, ответственный за производственный контроль',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63662268',
        text:
          'Какое из перечисленных требований к установке сероочистки природного газа указано верно?',
        answers: [
          'Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы. Содержание CH4 в двух последовательно отобранных пробах должно быть не более 2%.',
          'При перерыве в работе горелок газонагревателя более 30 минут необходимо установить заглушку на газопроводе топливного газа',
          'Перед пуском после ремонта аппараты и газопроводы сероочистки должны быть испытаны на плотность и прочность, продуты азотом до уровня содержания O2 не более 2% в двух последовательно отобранных пробах',
          'Установка сероочистки должна иметь подвод воздуха',
        ],
        correctAnswers: [
          'При перерыве в работе горелок газонагревателя более 30 минут необходимо установить заглушку на газопроводе топливного газа',
        ],
      },
      {
        code: '63662269',
        text:
          'При какой объемной доле кислорода в жидком азоте он может применяться в качестве хладагента без специальных мер по предупреждению загорания и взрыва?',
        answers: [
          'Не более 30%',
          'Не более 35%',
          'Не более 40%',
          'Не более 50%',
        ],
        correctAnswers: ['Не более 30%'],
      },
      {
        code: '63662270',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63662271',
        text:
          'Что из перечисленного допускается в смолоперерабатывающих цехах?',
        answers: [
          'Подвод пара к камерам ретурбендов, кубов и топок трубчатых печей',
          'Спуск продукта в пусковой резервуар при наличии в нем воды при пуске непрерывного агрегата дистилляции смолы',
          'Загрузка пека в напорные баки и смесители для приготовления лака и препарированной смолы при наличии в них воды и масла',
          'Включение в работу вакуум-фильтров при заполненной нафталиновой фракцией ванне',
        ],
        correctAnswers: [
          'Подвод пара к камерам ретурбендов, кубов и топок трубчатых печей',
        ],
      },
      {
        code: '63662272',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждые пять лет',
          'Каждый год',
          'Через каждые два года',
          'Через каждые три года',
        ],
        correctAnswers: ['Через каждые пять лет'],
      },
      {
        code: '63662273',
        text:
          'Какие из перечисленных работ допускается производить в помещении для стоянки автомобилей с цистернами для жидких криогенных ПРВ? Выберите правильный вариант ответа.',
        answers: [
          'Только работы без применения сварки и открытого огня',
          'Только работы по обслуживанию и ремонту автомобилей, не требующих смотровых канав',
          'Только работы по ремонту установленных на автомобилях сосудов, насосов, испарителей, трубопроводов и арматуры, работающих с ПРВ',
          'Допускается производить все перечисленные виды работ',
        ],
        correctAnswers: [
          'Допускается производить все перечисленные виды работ',
        ],
      },
      {
        code: '63662274',
        text:
          'В какое время суток должны производиться очистка и ремонт оросительной системы башни тушения?',
        answers: [
          'В дневное и ночное время суток при наличии должного освещения',
          'Только в дневное время суток',
          'В любое время суток',
        ],
        correctAnswers: ['Только в дневное время суток'],
      },
      {
        code: '63662275',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме',
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
          'При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63662276',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '5 м', '3 м', '15 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63662277',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'При зажигании горелок следует подавать максимальное количество воздуха',
          'При увеличении нагрузки на горелку следует сначала увеличить подачу воздуха, затем газа',
          'Место отбора импульса для сигнализатора падения давления определяется актом технической комиссии',
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63662278',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Еженедельно', 'Ежедневно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63662279',
        text:
          'С какой периодичностью необходимо пропаривать трубы от смотровых фонарей и диссоциаторов в отделениях концентрированной аммиачной воды?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в две смены',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63662280',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением',
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63662281',
        text:
          'В каком случае должно осуществляться отключение выносных конденсаторов для планового отогрева?',
        answers: [
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток',
          'При наличии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток',
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих 12 часов',
          'При наличии в расположенных перед ними конденсаторах ацетилена в течение предыдущих двух суток',
        ],
        correctAnswers: [
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток',
        ],
      },
      {
        code: '63662282',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полугодие',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63662283',
        text:
          'С какой периодичностью должен производиться осмотр технологических трубопроводов и технических устройств ПРВ комиссией на производстве?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63662284',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['90 °C', '85 °C', '100 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63662285',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под избыточным давлением',
          'На газопроводах после их продувки',
          'На газопроводах, находящихся под разрежением',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63662286',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63662287',
        text:
          'Какое из перечисленных требований при чистке и ремонте отопительной арматуры, регенераторов и газораспределительных каналов в кладке коксовых печей указано неверно?',
        answers: [
          'При обогреве доменным газом чистка газовоздушных клапанов и кантовочных кранов для доменного газа должна производиться после предварительного закрытия регулировочного (запорного) клапана, при этом чистка клапанов должна осуществляться только при работе их на нисходящем потоке',
          'При обогреве коксовым газом чистка, ревизия и ремонт арматуры на участке от распределительного газопровода до ввода в отопительный простенок, чистка и ремонт корнюров и дюзовых каналов (в печах с нижним подводом коксового газа), а также замена диафрагм и регулирующих стержней должны производиться только после предварительного закрытия стопорного крана и отключения реверсивного крана от кантовочного механизма',
          'Во время кантовки чистка кантовочного и стопорного кранов при обогреве коксовым и доменным газами должна производиться с помощью специальной манжетной пробки только после отсоединения ведущего рычага от кантовочного крана',
        ],
        correctAnswers: [
          'Во время кантовки чистка кантовочного и стопорного кранов при обогреве коксовым и доменным газами должна производиться с помощью специальной манжетной пробки только после отсоединения ведущего рычага от кантовочного крана',
        ],
      },
      {
        code: '63662288',
        text:
          'Какие установлены требования к хранению материалов для приготовления формовочных смесей?',
        answers: [
          'Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений',
          'Должны храниться в специально отведенных местах цеха',
          'Должны храниться в отдельных помещениях производственных участков в герметичной таре',
        ],
        correctAnswers: [
          'Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений',
        ],
      },
      {
        code: '63662289',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Только системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Только блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- и пневмоснабжения КИПиА',
          'Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство',
          'Только средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб, характеризующимися падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63662290',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Одним человеком под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек под наблюдением газоспасателя или члена ДГСД',
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63662291',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны быть предназначены для выполнения любых работ',
          'Должны находиться в свободном доступе',
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63662292',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I, II и III группы газоопасности',
          'На I, II, III и IV группы газоопасности',
          'На I и II группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63662293',
        text:
          'При каком максимальном содержании кислорода в циркуляционном газе допускается эксплуатация УСТК?',
        answers: ['0.02', '0.03', '0.01', '0.05'],
        correctAnswers: ['0.01'],
      },
      {
        code: '63662294',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?',
        answers: ['4 г/нм³', '5 г/нм³', '3 г/нм³', '2 г/нм³'],
        correctAnswers: ['4 г/нм³'],
      },
      {
        code: '63662295',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Техническому освидетельствованию',
          'Механическим испытаниям',
          'Проверке состояния',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63662296',
        text:
          'Что из перечисленного должен включать осмотр технологических трубопроводов и технических устройств ПРВ, производимый комиссией на производстве? Выберите правильный вариант ответа.',
        answers: [
          'Только проверку плотности швов и фланцевых соединений трубопроводов и аппаратов, находящихся внутри зданий',
          'Только проверку плотности люков и свечей, затворов',
          'Только проверку анкерных болтов и надземной части фундаментов',
          'Должен включать все перечисленные проверки',
        ],
        correctAnswers: ['Должен включать все перечисленные проверки'],
      },
      {
        code: '63662297',
        text:
          'Какое допускается максимальное значение пульсации давления газа на выходе из ГРП и ГРУ?',
        answers: [
          '10% заданного рабочего давления',
          '15% заданного рабочего давления',
          '25% заданного пробного давления',
          '5% заданного пробного давления',
        ],
        correctAnswers: ['10% заданного рабочего давления'],
      },
      {
        code: '63662298',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В непосредственной близости от агрегатов',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке.',
          'На расстоянии не менее 5 м от агрегатов',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке.',
        ],
      },
      {
        code: '63662299',
        text:
          'В каком из перечисленных случаях должен подаваться азот при постоянном снижении циркуляции во всасывающий короб дымососа и в камеру тушения через короба разгрузочного устройства УСТК?',
        answers: [
          'Только при обнаружении течи в котле',
          'Только при увеличении содержания водорода и метана до максимально допустимых величин, установленных инструкцией',
          'Только при нарушении герметичности или поломки, требующих остановки камеры',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63662300',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должны быть устроены ящики с песком',
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Должен быть устроен медицинский кабинет',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63662301',
        text:
          'В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?',
        answers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
          'Арматура, предназначенная для работы с кислородом, должна обезжириваться в любом случае',
          'Подлежит обезжириванию во всех случаях',
          'Если невозможно исключить загрязнение воздуха производственных помещений парами моющих растворов, используемых для обезжиривания',
        ],
        correctAnswers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
      },
      {
        code: '63662302',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 4 года',
          'Один раз в 8 лет',
          'Один раз в 10 лет',
          'Один раз в 12 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63662303',
        text:
          'Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Допускается одновременное проведение ремонта трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Допускается одновременное проведение ремонта трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          'Допускается одновременное проведение ремонта принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'Не допускается одновременное проведение ремонта',
        ],
        correctAnswers: ['Не допускается одновременное проведение ремонта'],
      },
      {
        code: '63662304',
        text:
          'Чему подвергаются все газопроводы и газовые установки после окончания строительно-монтажных и сварочных работ (включая ремонтные работы) и оформления документов, подтверждающих качество выполненных работ?',
        answers: [
          'Только наружному осмотру',
          'Только испытанию на прочность и плотность',
          'Только дополнительным испытаниям на герметичность с определением падения давления при необходимости',
          'Всему перечисленному',
        ],
        correctAnswers: ['Всему перечисленному'],
      },
      {
        code: '63662305',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
          'Хранение в помещении ГРП обтирочных и других горючих материалов не допускается',
          'При необходимости следует пользоваться переносными аккумуляторными взрывобезопасными светильниками в помещении категории A',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63662306',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['5 м', '10 м', '3 м', '1,5 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63662307',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,05 МПа',
          'Не менее 0,04 МПа',
          'Не менее 0,03 МПа',
          'Не менее 0,02 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63662308',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 600 °C',
          'При наличии светозвуковой сигнализации',
          'Не допускается ни в каком случае.',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63662309',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Сжатым воздухом', 'Газом', 'Водяным паром'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63662310',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63662311',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Начальник участка',
          'Технический руководитель организации',
          'Выдающий наряд-допуск',
          'Работник, ответственный за осуществление производственного контроля',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63662312',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63662313',
        text:
          'В каких местах не допускается находиться во время работы коксовых машин? Выберите правильный вариант ответа.',
        answers: [
          'Только на верхних площадках углезагрузочного вагона в момент его передвижения и загрузки печей',
          'Только на лестницах и площадках электровоза во время его движения',
          'Только на крыше двересъемной машины во время ее передвижения, выдачи кокса и при наличии напряжения на троллеях',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63662314',
        text:
          'При каком максимальном содержании водорода в циркулирующем газе допускается работа котлоагрегатов УСТК?',
        answers: ['0.12', '0.08', '0.05', '0.01'],
        correctAnswers: ['0.08'],
      },
      {
        code: '63662315',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее пяти лет',
          'Не менее трех лет',
          'Не менее одного года',
          'Не менее двух лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63662316',
        text:
          'Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?',
        answers: [
          'Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки или заглушки',
          'Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается',
          'Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом',
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
        correctAnswers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
      },
      {
        code: '63662317',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'Только по легкосбрасываемым ограждающим конструкциям',
          'Только по стенам зданий на участках со сплошным остеклением',
          'Только по наружным стенам производственных зданий (транзитных водородопроводов)',
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63662318',
        text:
          'При каком содержании газов должны срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 20% нижнего предела взрываемости (0,8% об.), кислорода менее 25% и более 34%, угарного газа более 25 мг/м³, метана не более 50% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25% нижнего предела взрываемости (1% об.), кислорода менее 35% и более 40%, угарного газа более 29 мг/м³, метана не более 5% нижнего предела взрываемостии',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63662319',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,5 м',
          'Ресиверы следует размещать на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'На ограждении ресиверов должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен"',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63662320',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
          'Не реже трех раз в год и не реже одного раза в год соответственно',
          'Не реже одного раза в год и не реже одного раза в два года соответственно',
          'Не реже одного раза в три года и не реже одного раза в пять лет соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63662321',
        text:
          'Каким образом необходимо нейтрализовать (связать в сульфат пиридина) пиридиновые основания перед началом уборки при их случайном разливе?',
        answers: [
          '15 - 20%-ным раствором серной кислоты',
          '15 - 20%-ным раствором соды',
          '15 - 20%-ным раствором соли',
          '15 - 20%-ным раствором соляной кислоты',
        ],
        correctAnswers: ['15 - 20%-ным раствором серной кислоты'],
      },
      {
        code: '63662322',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Класс точности не ниже 2,0',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
          'Диаметр корпуса не менее 200 мм',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63662323',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Организация, проектирующая систему автоматики печи',
          'Техническая комиссия эксплуатирующей организации при согласовании с Ростехнадзором',
          'Техническая комиссия эксплуатирующей организации',
          'Технический руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63662324',
        text:
          'В течение какого времени должна проветриваться одежда, в которой выполнялись работы с жидким кислородом или работы в местах с повышенным содержанием кислорода?',
        answers: [
          'В течение 30 минут',
          'В течение 40 минут',
          'В течение 50 минут',
          'В течение 60 минут',
        ],
        correctAnswers: ['В течение 30 минут'],
      },
      {
        code: '63662325',
        text:
          'Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя',
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63662326',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Должны производиться в условиях, исключающих искрообразование',
          'Должны осуществляться только в специально выделенном для этого помещении',
          'Должны производиться только в светлое время суток',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63662327',
        text:
          'Что должно быть у каждого плавильного агрегата с выпуском металла через летку?',
        answers: [
          'Должны быть три штанги длиной не менее 2,5 м и запасные пробки для закрывания леток',
          'Должны быть две штанги длиной не менее 2 м и запасные пробки для закрывания леток',
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
        ],
        correctAnswers: [
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
        ],
      },
      {
        code: '63662328',
        text:
          'На каких кислородопроводах перед КРП необходимо устанавливать фильтры?',
        answers: [
          'Работающих под давлением более 1,6 МПа',
          'Работающих под давлением более 1,2 МПа',
          'Работающих под давлением более 1,4 МПа',
          'Работающих под давлением более 0,8 МПа',
        ],
        correctAnswers: ['Работающих под давлением более 1,6 МПа'],
      },
      {
        code: '63662329',
        text:
          'С какой периодичностью должна производиться уборка угольной пыли с оборудования и в помещениях углеподготовки?',
        answers: ['Ежесменно', 'Ежедневно', 'Ежемесячно', 'Еженедельно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63662330',
        text:
          'Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?',
        answers: [
          'Только в начале плавления шихты до образования значительного количества жидкого металла',
          'Только до начала плавления шихты',
          'Только после выпуска металла',
        ],
        correctAnswers: [
          'Только в начале плавления шихты до образования значительного количества жидкого металла',
        ],
      },
      {
        code: '63662331',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна иметь шумоизоляцию и защищать машиниста от вредных производственных факторов',
          'Должна иметь тонированные стекла и защищать машиниста от ультрафиолетового излучения',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63662332',
        text:
          'Когда должна производиться замена адсорбента воздухоразделительной установки ПРВ? Выберите правильный вариант ответа.',
        answers: [
          'Только в сроки, установленные технологической инструкцией',
          'Только при значительном изменении его первоначального цвета',
          'Только при систематическом обнаружении ацетилена в жидком кислороде с концентрацией выше допустимой и не устраняемой после проведения высокотемпературной регенерации адсорбента',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63662333',
        text:
          'Какая проверка должна производиться для предупреждения утечки отопительного газа в обслуживающие туннели и борова печей при обогреве коксовым газом?',
        answers: [
          'Только герметичности крышек клапанов для воздуха обезграфичивающего устройства',
          'Только герметичности стопорных и кантовочных кранов',
          'Только плотности соединения арматуры с кладкой',
          'Проверка всего перечисленного',
        ],
        correctAnswers: ['Проверка всего перечисленного'],
      },
      {
        code: '63662334',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?',
        answers: ['0.01', '0.02', '0.03', '0.05'],
        correctAnswers: ['0.01'],
      },
      {
        code: '63662335',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Разрешено при увеличении давления газа до 10%',
          'Запрещено',
          'Только в аварийных случаях',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63662336',
        text:
          'Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Колонны высокого и низкого давлений',
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
          'Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)',
        ],
        correctAnswers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63662337',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже двух раз в год',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63662338',
        text:
          'Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?',
        answers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
          'На 400 мм вод. ст. больше расчетного давления газа, но не менее 1000 мм вод. ст.',
          'На 300 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.',
        ],
        correctAnswers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
        ],
      },
      {
        code: '63662339',
        text:
          'При каком перерыве в эксплуатации кислородопровод давлением 0,6 МПа и выше должен быть продут воздухом или азотом со скоростью на выходе не менее 40 м/с?',
        answers: [
          'Более месяца',
          'Более двух месяцев',
          'Более двух недель',
          'Более недели',
        ],
        correctAnswers: ['Более месяца'],
      },
      {
        code: '63662340',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Только установка запорных или отсекающих устройств на линиях всасывания',
          'Должны предусматриваться все перечисленные требования',
          'Только установка запорных или отсекающих устройств на линиях нагнетания',
          'Только дистанционное отключение',
        ],
        correctAnswers: [
          'Должны предусматриваться все перечисленные требования',
        ],
      },
      {
        code: '63662341',
        text:
          'Что из перечисленного допускается при непрерывной работе загрузочного и разгрузочного устройств сухого тушения кокса? Выберите правильный вариант ответа.',
        answers: [
          'Вскрывать и производить переуплотнение загрузочного и разгрузочного устройств',
          'Работать и находиться вблизи разгрузочных устройств при включенной вентиляции',
          'Производить ревизию и ремонты коксо- и пылеразгрузочных устройств',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Работать и находиться вблизи разгрузочных устройств при включенной вентиляции',
        ],
      },
      {
        code: '63662342',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: ['Каждые двое суток', 'Ежедневно', 'Еженедельно'],
        correctAnswers: ['Ежедневно'],
      },
    ],
    63669: [
      {
        code: '63669000',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются механической приточно-вытяжной общеобменной вентиляцией из верхней зоны через короб в объеме не менее двукратного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 4 в час',
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63669001',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63669002',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров?',
        answers: [
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Сливание расплава металла из ковшей и миксеров должно быть механизировано',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63669003',
        text:
          'Какая объемная доля водорода в техническом аргоне, поступающем на очистку в ВРУ?',
        answers: [
          'Не должна превышать 2,5%',
          'Не должна превышать 3,0%',
          'Не должна превышать 4,0%',
        ],
        correctAnswers: ['Не должна превышать 2,5%'],
      },
      {
        code: '63669004',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63669005',
        text:
          'Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?',
        answers: [
          'Скорость не должна превышать 5 км/ч',
          'Скорость не должна превышать 7 км/ч',
          'Скорость не должна превышать 10 км/ч',
          'Скорость не должна превышать 15 км/ч',
        ],
        correctAnswers: ['Скорость не должна превышать 5 км/ч'],
      },
      {
        code: '63669006',
        text:
          'Какие требования для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода указаны неверно?',
        answers: [
          'Для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода должны быть установлены датчики довзрывных концентраций',
          'Все перечисленные требования указаны верно',
          'Количество и места расположения датчиков довзрывных концентраций. должны определяться проектом',
          'Светозвуковая сигнализация должна иметь вывод на пульт оператора и в помещение пожарного депо предприятия',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63669007',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Емкости с песком, огнетушители',
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Брезентовые покрывала',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63669008',
        text:
          'Какое из перечисленных требований к установке сероочистки природного газа указано верно?',
        answers: [
          'Установка сероочистки должна работать в ручном режиме и снабжаться блокировками, отключающими установку при отклонении параметров от заданных',
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
          '1 раз в 5 лет газопроводы сероочистки подвергаются пневматическому испытанию',
          'Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы, а содержание CH₄ в 2 последовательно отобранных пробах должно быть не более 3%',
        ],
        correctAnswers: [
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
        ],
      },
      {
        code: '63669009',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'Потерявших форму вследствие деформации',
          'С поврежденной футеровкой',
          'Имеющих раковины, трещины в стенках ковшей и в местах крепления цапф',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63669010',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'В аварийных случаях',
          'Если на время производства работ выставлены посты',
          'Не допускается ни в каком случае',
          'Если в радиусе 50 м приостановлено движение всех видов транспорта',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63669011',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['85 °C', '80 °C', '75 °C', '95°C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63669012',
        text:
          'При каком давлении должна производиться настройка срабатывания отсекающих клапанов кислородно-распределительного (регуляторного) пункта (далее – КРП)?',
        answers: [
          'При давлении на 25% более расчетного и температуре 100 °C',
          'При давлении на 20% более расчетного и температуре 100 °C',
          'При давлении на 15% более расчетного и температуре 100 °C',
        ],
        correctAnswers: [
          'При давлении на 15% более расчетного и температуре 100 °C',
        ],
      },
      {
        code: '63669013',
        text:
          'Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?',
        answers: [
          'Номер сертификата на заглушку',
          'Номер заглушки',
          'Марка материала',
          'Условное давление и условный диаметр',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63669014',
        text:
          'Какое расчетное давление газа соответствует газопроводам среднего давления?',
        answers: [
          'Свыше 0,1 до 0,3 МПа включительно',
          'Свыше 0,3 до 1,2 МПа включительно',
          'Свыше 0,3 до 0,6 МПа включительно',
          'Свыше 0,6 до 1,2 МПа включительно',
        ],
        correctAnswers: ['Свыше 0,1 до 0,3 МПа включительно'],
      },
      {
        code: '63669015',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь вдоль периметра пешеходные проходы шириной 0,8 м',
          'Должны иметь вдоль периметра пешеходные проходы шириной 1,0 м',
          'Со всех сторон должны иметь ограждение',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63669016',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: ['Один раз в 1,5 года', 'Один раз в год', 'Один раз в 2 года'],
        correctAnswers: ['Один раз в 2 года'],
      },
      {
        code: '63669017',
        text:
          'Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?',
        answers: [
          'Десятикратный',
          'Восьмикратный',
          'Пятикратный',
          'Шестикратный',
        ],
        correctAnswers: ['Восьмикратный'],
      },
      {
        code: '63669018',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В паспорт оборудования',
          'В специальный журнал по устранению дефектов',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63669019',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'В зоне работы электромостовых кранов',
          'В зоне погрузки грейферными или магнитными кранами',
          'В зоне работы кран-балок',
          'Во всех перечисленных зонах',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63669020',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63669021',
        text:
          'С какой периодичностью оператору установки необходимо производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в три смены',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63669022',
        text:
          'При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?',
        answers: ['0.02', '0.01', '0.015', '0.005'],
        correctAnswers: ['0.02'],
      },
      {
        code: '63669023',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы',
          'Деревянные шпалы, пропитанные  креозотом',
          'Огнестойкие шпалы',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63669024',
        text:
          'Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?',
        answers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
          'На 300 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.',
          'На 200 мм вод. ст. больше расчетного давления газа, но не менее 1750 мм вод. ст.',
          'На 150 мм вод. ст. больше расчетного давления газа, но не менее 1400 мм вод. ст.',
        ],
        correctAnswers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
        ],
      },
      {
        code: '63669025',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные изготовителем',
          'В сроки, установленные эксплуатирующей организацией',
          'Через 3 года после ввода в эксплуатацию',
          'Через 5 лет после ввода в эксплуатацию',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63669026',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63669027',
        text:
          'В каких случаях допускается применение арматуры из ковкого и высокопрочного чугуна на водородопроводах?',
        answers: [
          'При давлении не более 1,0 МПа и температуре от 0 °C до 50 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима',
          'При давлении не более 2,0 МПа и температуре от 10 °C до 40 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима',
          'При давлении не более 1,5 МПа и температуре от 5 °C до 30 °C',
          'При давлении не более 3,5 МПа и температуре от 15 °C до 45 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима',
        ],
        correctAnswers: [
          'При давлении не более 1,0 МПа и температуре от 0 °C до 50 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима',
        ],
      },
      {
        code: '63669028',
        text:
          'На какой высоте должна предусматриваться прокладка газопроводов в местах прохода людей?',
        answers: [
          'Не менее 2,2 м от пола до низа газопровода',
          'Не менее 2,0 м от пола до низа газопровода',
          'Не менее 2,5 м от пола до низа газопровода',
          'Не менее 1,6 м от пола до низа газопровода',
        ],
        correctAnswers: ['Не менее 2,2 м от пола до низа газопровода'],
      },
      {
        code: '63669029',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Перед установкой фурмы должны храниться в специальном помещении не менее 2 суток',
          'Перед установкой фурмы должны храниться в специальном помещении не менее 5 суток',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
          'Фурмы должны иметь цилиндрическую форму',
          'Должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63669030',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На герметичность', 'На плотность', 'На прочность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63669031',
        text:
          'Какое из перечисленных требований к стационарным газоразборным постам указано неверно?',
        answers: [
          'Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции',
          'Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 150 мм',
          'Дверцы шкафов во время работы должны быть закрыты',
          'Газоразборные посты, шкафы должны иметь опознавательную окраску',
        ],
        correctAnswers: ['Дверцы шкафов во время работы должны быть закрыты'],
      },
      {
        code: '63669032',
        text:
          'В зданиях и помещениях категории А следует предусматривать наружные легкосбрасываемые ограждающие конструкции площадью, принимаемой в соответствии с расчетом. Какая площадь конструкций должна быть при отсутствии расчетных данных?',
        answers: [
          'Не менее 0,05 м² на 1 м³ объема помещений',
          'Не менее 0,03 м² на 1 м³ объема помещений',
          'Не менее 0,01 м² на 1 м³ объема помещений',
          'Не менее 0,02 м² на 1 м³ объема помещений',
        ],
        correctAnswers: ['Не менее 0,05 м² на 1 м³ объема помещений'],
      },
      {
        code: '63669033',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?',
        answers: [
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации',
          'Не допускается уборка, вызывающая распыление вредных веществ',
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63669034',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в квартал',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63669035',
        text: 'Как должны подготавливаться растворы кислот и щелочей?',
        answers: [
          'Кислота и щелочь должны заливаться небольшой струей в воду, нагретую до температуры 70 °C',
          'Кислота и щелочь должны заливаться небольшой струей в холодную воду',
          'Вода должна заливаться небольшой струей в кислоту и щелочь',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Кислота и щелочь должны заливаться небольшой струей в холодную воду',
        ],
      },
      {
        code: '63669036',
        text:
          'Какое должно быть давление во всасывающих коммуникациях компрессоров, сжимающих ПРВ?',
        answers: [
          'Должно быть не ниже 0,5 кПа (50 мм вод. ст.)',
          'Должно быть не ниже 0,4 кПа (40 мм вод. ст.)',
          'Должно быть не ниже 0,2 кПа (20 мм вод. ст.)',
        ],
        correctAnswers: ['Должно быть не ниже 0,5 кПа (50 мм вод. ст.)'],
      },
      {
        code: '63669037',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63669038',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы автоматической сигнализацией',
          'Должны быть оборудованы световой сигнализацией',
          'Должны быть оборудованы звуковой сигнализацией',
          'Должны быть оборудованы автоматическими шлагбаумами',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63669039',
        text:
          'При какой предельной температуре кислорода после регулятора давления на линии регулирования прекращается его подача?',
        answers: [
          'При повышении более 100 °С',
          'При повышении более 120   °С',
          'При повышении более 150 °С',
        ],
        correctAnswers: ['При повышении более 100 °С'],
      },
      {
        code: '63669040',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,5 м в свету',
          'Не менее 1,0 м в свету',
          'Не менее 0,5 м в свету',
          'Не менее 0,8 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63669041',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 18% и не более 23%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63669042',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Средствами сигнализации о прекращении поступления топлива',
          'Средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи, а при компоновке печных агрегатов с котлами-утилизаторами системами по переводу на работу агрегатов без дымососов',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63669043',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: ['Ежедневно', 'Каждую смену', 'Через двое суток'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63669044',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,5 м',
          'Расстояние между ресиверами одного газа должно быть не менее 1,5 м в свету и обеспечивать удобство их обслуживания',
          'На ограждении ресивера должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен", на ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63669045',
        text:
          'Чем допускается тушить пламя при возгорании водорода? Выберите два варианта ответа.',
        answers: ['Песком', 'Кошмой', 'Водой', 'Огнетушителем'],
        correctAnswers: ['Песком', 'Кошмой'],
      },
      {
        code: '63669046',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'С требованиями строительных норм и правил',
          'С требованиями нормативно-технической документации для технологических стальных трубопроводов',
          'С требованиями Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63669047',
        text:
          'Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ?',
        answers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Колонны высокого и низкого давлений',
          'Аппараты и коммуникации на потоке воздуха от поршневого детандера и от дожимающего поршневого компрессора',
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
        ],
        correctAnswers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63669048',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При незначительном падении давления воды в системе водоохлаждения',
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При незначительном повышении давления воды в системе охлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63669049',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63669050',
        text:
          'Какое допускается содержание кислорода в инертном газе, предназначенном для продувки водородной системы?',
        answers: ['Не более 1%', 'Не более 3%', 'Не более 5%', 'Не более 7%'],
        correctAnswers: ['Не более 1%'],
      },
      {
        code: '63669051',
        text:
          'При выявлении каких признаков компрессор, компримирующий водород, может продолжать работать?',
        answers: [
          'При неисправности контрольно-измерительных приборов',
          'При неисправности системы смазки компрессора',
          'При наличии стуков и ударов (в компрессоре, двигателе и других)',
          'При выявлении всех перечисленных признаков компрессор должен быть немедленно остановлен',
          'При повышении температуры охлаждающей воды на 20 °C, но ниже сверх допустимой',
        ],
        correctAnswers: [
          'При повышении температуры охлаждающей воды на 20 °C, но ниже сверх допустимой',
        ],
      },
      {
        code: '63669052',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Освидетельствованию',
          'Проверке состояния',
          'Механическим испытаниям',
          'Регистрации',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63669053',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63669054',
        text:
          'Какое испытательное давление должно приниматься для участка газопровода чистого газа от дроссельной группы до листовой задвижки, где P2 - расчетное избыточное давление (по проекту) газовой среды на участке, МПа?',
        answers: [
          '1,25Р2, но не менее 0,05 МПа',
          '1,5Р2, но не менее 0,1 МПа',
          '1,1Р2, но не менее 0, 45 МПа',
          '1,35Р2, но не менее 0,25 МПа',
        ],
        correctAnswers: ['1,25Р2, но не менее 0,05 МПа'],
      },
      {
        code: '63669055',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63669056',
        text:
          'Чем должна быть продута горелка перед включением и подачей газа?',
        answers: ['Азотом', 'Сжатым воздухом', 'Кислородом'],
        correctAnswers: ['Кислородом'],
      },
      {
        code: '63669057',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?',
        answers: [
          'Из поролона или других органических материалов',
          'Из картона',
          'Из цветных металлов',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63669058',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '1,5 м', '5 м', '7,5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63669059',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Начальник цеха - ответственный за производственный контроль',
          'Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
          'Главный инженер',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63669060',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Все перечисленные требования указаны верно',
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63669061',
        text:
          'Какие требования по обеспечению производства инертным газом указаны неверно?',
        answers: [
          'Давление продувочного инертного газа должно быть выше давления в продуваемой системе',
          'На каждом вводе азотопровода в здание должны быть установлены обратный клапан, запорный вентиль, а на каждом ответвлении (на вводе в каждое помещение) должны быть установлены манометр и запорный вентиль',
          'Во всех случаях в помещениях, связанных с обращением водорода, а также на ресиверных площадках должна быть предусмотрена стационарная разводка трубопроводов инертного газа на продувку и пожаротушение',
          'На каждом ответвлении азотопровода (на вводе в каждое помещение) должны быть установлены манометр и запорный вентиль',
        ],
        correctAnswers: [
          'Давление продувочного инертного газа должно быть выше давления в продуваемой системе',
        ],
      },
      {
        code: '63669062',
        text:
          'Каким способом допускается прокладка трубопроводов водорода в пределах предприятия? Выберите 2 варианта ответа.',
        answers: [
          'Над поверхностью земли на стойках',
          'Над поверхностью земли на эстакадах',
          'Подземной прокладкой',
          'Канальной прокладкой',
        ],
        correctAnswers: [
          'Над поверхностью земли на стойках',
          'Над поверхностью земли на эстакадах',
        ],
      },
      {
        code: '63669063',
        text: 'При каком условии ВРУ должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,35 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,25 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63669064',
        text:
          'Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?',
        answers: ['Не более двух', 'Не более одного', 'Не более четырех'],
        correctAnswers: ['Не более двух'],
      },
      {
        code: '63669065',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
          'Не реже одного раза в год и не реже одного раза в четыре года соответственно',
          'Не реже одного раз в два года и не реже одного раза в пять лет соответственно',
          'Не реже трех раз в год и не реже одного раза в год соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63669066',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I, II, III и IV группы газоопасности',
          'На I, II и III группы газоопасности',
          'На I и II группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63669067',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В отдельно стоящих зданиях',
          'В смежных с помещениями с взрывоопасными зонами',
          'В отдельных щитовых помещениях (встроенных или пристроенных)',
          'В производственных помещениях',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63669068',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63669069',
        text:
          'С каким минимальным количеством независимых источников электроснабжения должно быть выполнено электропитание оборудования производства водорода?',
        answers: ['С двумя', 'С одним', 'С тремя', 'Не регламентируется'],
        correctAnswers: ['С двумя'],
      },
      {
        code: '63669070',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
          'Не реже одного раза в месяц и не реже одного раза в три месяца соответственно',
          'Не реже одного раза в три месяца и не реже одного раза в шесть месяцев соответственно',
          'Не реже одного раза в шесть месяцев и не реже одного раза в год соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
      {
        code: '63669071',
        text:
          'Каким газом должны продуваться перед пуском и после остановки установки по производству водорода?',
        answers: ['Азотом', 'Кислородом', 'Углекислым газом', 'Аргоном'],
        correctAnswers: ['Азотом'],
      },
      {
        code: '63669072',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Выдающий наряд-допуск',
          'Лицо, ответственное за осуществление производственного контроля',
          'Допускающий к работе',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63669073',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,05 МПа (0,5 кгс/см²)',
          'При давлении 0,02 МПа (0,2 кгс/см²)',
          'При давлении 0,01 МПа (0,1 кгс/см²)',
          'При давлении 0,03 МПа (0,3 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63669074',
        text:
          'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей жидкости',
          'На 2,0 величины рабочего давления охлаждающей жидкости',
          'На 2,5 величины рабочего давления охлаждающей жидкости',
          'На 0,5 величины рабочего давления охлаждающей жидкости',
        ],
        correctAnswers: [
          'На 1,5 величины рабочего давления охлаждающей жидкости',
        ],
      },
      {
        code: '63669075',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,2% в час',
          'Не более 0,3% в час',
          'Не более 0,5% в час',
          'Не более 0,7% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63669076',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться только на горизонтальных площадках в любом месте пролета цеха',
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
          'Должны расставляться на свободных площадках пролета цеха',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63669077',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 4 года',
          'Не позднее чем через 5 лет',
          'Не позднее чем через 7 лет',
          'Не позднее чем через 10 лет',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63669078',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?',
        answers: ['0,4 кПа', '0,3 кПа', '0,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63669079',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
          'Акт-допуск должен оформляться письменно',
          'На один объект, площадку, территорию оформляется один акт-допуск',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63669080',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Техническое обслуживание',
          'Текущий ремонт',
          'Проверка параметров срабатывания предохранительных запорных и сбросных клапанов',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63669081',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом',
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Помещения должны иметь защиту от воздействия теплового излучения',
          'Помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников; исполнением дверей помещения с открыванием наружу и не иметь внутренних запоров',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63669082',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
          'При наличии предохранительного запорного клапана',
          'Подача газа по байпасу не допускается',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63669083',
        text:
          'В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных   производственных объектах?',
        answers: [
          'Если изменился состав бригады',
          'Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технических устройств и порядка выполнения работ',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
          'Если выявлено нарушение условий отключения технических устройств',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63669084',
        text:
          'Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ?',
        answers: [
          'Перевести на ручной режим систему автоматического управления регенераторов',
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          'С помощью дистанционного управления закрыть или открыть соответствующие клапаны',
          'Необходимо осуществить все перечисленные действия',
        ],
        correctAnswers: ['Необходимо осуществить все перечисленные действия'],
      },
      {
        code: '63669085',
        text:
          'Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?',
        answers: [
          'Не ниже 3,0 кПа',
          'Не ниже 1,0 кПа',
          'Не ниже 1,7 кПа',
          'Не ниже 0,8 кПа',
        ],
        correctAnswers: ['Не ниже 3,0 кПа'],
      },
      {
        code: '63669086',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением',
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63669087',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В вертикальном положении',
          'В горизонтальном положении',
          'Под углом 45°',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63669088',
        text:
          'Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?',
        answers: [
          'Осмотр должен производиться не реже одного раза в три года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
          'Осмотр должен производиться не реже одного раза в два года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежесменно',
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежесменно',
        ],
        correctAnswers: [
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
        ],
      },
      {
        code: '63669089',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'С применением открытого пламени',
          'Горячим воздухом, паром или горячей водой',
          'Электрическими приборами',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63669090',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже 1 раза в месяц',
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети, с использованием, при необходимости, переносных аккумуляторных взрывобезопасных светильников',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63669091',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 5 минут',
          'В течение 10 минут',
          'В течение 15 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63669092',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63669093',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,05 МПа',
          'Не менее 0,01 МПа',
          'Не менее 0,03 МПа',
          'Не менее 0,02 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63669094',
        text:
          'В каких помещениях предупреждающий и аварийный сигналы должны подаваться у входа вне помещения?',
        answers: [
          'В помещениях с периодическим пребыванием персонала, где установлены датчики',
          'В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами и в отдельно стоящих зданиях',
          'В помещениях с постоянным пребыванием обслуживающего персонала',
          'В помещениях наполнения, хранения и разрядки баллонов',
        ],
        correctAnswers: [
          'В помещениях с периодическим пребыванием персонала, где установлены датчики',
        ],
      },
      {
        code: '63669095',
        text: 'Какие требования к помещению операторной указаны верно?',
        answers: [
          'Полы в операторной должны быть диэлектрическими',
          'Помещения операторной должны быть оборудованы сигнализацией для оповещения персонала о появлении отклонений от технологического процесса',
          'Для слежения за работой оборудования, находящегося вне зоны видимости, предусматривается технологическая сигнализация и устройство видеонаблюдения',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63669096',
        text:
          'Какая периодичность проведения ревизий технологических трубопроводов установлена для I и II категорий трубопровода при скорости коррозии 0,1-0,5 мм/год и транспортировке чрезвычайно, высоко и умеренно опасных веществ 1, 2, 3-го классов?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 2 года'],
      },
      {
        code: '63669097',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в 5 лет', 'Один раз в 3 года', 'Ежегодно'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63669098',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С Ростехнадзором',
          'С техническим руководителем организации',
          'С руководителем газоспасательной службы',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63669099',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 0,5 м',
          'Не менее 0,7 м',
          'Не менее 0,6 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63669100',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['100 °C', '85 °C', '90 °C', '95 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63669101',
        text:
          'При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?',
        answers: [
          'При увеличении до 5%',
          'При увеличении до 3%',
          'При увеличении до 1%',
          'При увеличении до 2%',
        ],
        correctAnswers: ['При увеличении до 2%'],
      },
      {
        code: '63669102',
        text:
          'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 20 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
        correctAnswers: [
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
      },
      {
        code: '63669103',
        text:
          'Какие требования безопасности при получении водорода методом диссоциации аммиака указаны неверно?',
        answers: [
          'Помещения, в которых размещены диссоциаторы, должны иметь категорию А по взрывопожароопасности выше отметки 5,5 м от уровня пола',
          'Сигнал о пожаре должен поступать на щит пожарной сигнализации, расположенный в помещении оператора и в помещении пожарного депо предприятия',
          'Все стены, разделяющие помещения диссоциаторов, должны быть выполнены из негорючих материалов с пределом огнестойкости более 45 минут',
          'Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован',
        ],
        correctAnswers: [
          'Все стены, разделяющие помещения диссоциаторов, должны быть выполнены из негорючих материалов с пределом огнестойкости более 45 минут',
        ],
      },
      {
        code: '63669104',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть выдержаны в помещении не менее одной смены',
          'Должны быть выдержаны в помещении не менее суток',
          'Должны быть очищены от мусора',
          'Должны быть очищены от наледи',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63669105',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после тщательного их просушивания и разогрева',
          'Только после выдержки в течение суток при температуре 40 °С',
          'Только после выдержки в течение одной смены при температуре свыше 40 °С',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63669106',
        text:
          'Какими устройствами должны быть оборудованы аппараты с взрывопожароопасными веществами?',
        answers: [
          'Устройствами для подключения линий инертного газа',
          'Устройствами для подключения линий пара',
          'Устройствами для подключения линий воды',
          'Всеми перечисленными устройствами',
        ],
        correctAnswers: ['Всеми перечисленными устройствами'],
      },
      {
        code: '63669107',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Должен быть открыт вентиль горючего газа',
          'Должен быть немного приоткрыт вентиль кислорода',
          'Должна быть зажжена горючая смесь газов',
        ],
        correctAnswers: ['Должен быть немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63669108',
        text:
          'Какая допускается максимальная погрешность (точность измерения) газоанализаторов водорода?',
        answers: ['±0,2% об.', '±0,01% об.', '±0,1% об.', '±0,15% об.'],
        correctAnswers: ['±0,2% об.'],
      },
      {
        code: '63669109',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Количество производителей (руководителей) работ определяет выдающий наряд-допуск',
          'Один производитель (руководитель) работ',
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63669110',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В непосредственной близости от агрегатов',
          'На расстоянии не менее 5 м от агрегатов',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63669111',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы должны быть прекращены, для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности',
          'Работы должны быть продолжены, оформление нового наряда-допуска не требуется',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63669112',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На плотность под рабочим давлением',
          'Должна подвергаться гидроиспытаниям',
          'Должен быть проведен внешний осмотр',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63669113',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['50 м', '10 м', '25 м', '65 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63669114',
        text:
          'Какие производственные помещения должны быть оборудованы системами связи и сигнализации?',
        answers: [
          'Помещения, связанные с получением водорода',
          'Помещения, связанные с хранением водорода',
          'Помещения, связанные с применением водорода',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63669115',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов?',
        answers: [
          'Продувать рукав для горючих газов кислородом.2. Д) Допускаются все перечисленные действия',
          'Взаимозаменять кислородный рукав и рукав для горючих газов при работе',
          'Продувать кислородный рукав горючим газом',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63669116',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 8 лет',
          'Один раз в 5 лет',
          'Один раз в 3 года',
          'Один раз в 10 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63669117',
        text:
          'Какой порядок действий персонала при пожаре на установке получения водорода указан верно?',
        answers: [
          '1. Вызвать пожарную команду. 2. Перекрыть подачу технологических газов на установку. 3. Снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу',
          '1. Вызвать пожарную команду. 2. Произвести аварийное отключение установки. 3. Перекрыть подачу технологических газов на установку. 3. Снизить давление и выпустить водород в факельную систему для утилизации',
          '1. Перекрыть подачу технологических газов на установку. 2. Вызвать пожарную команду, газоспасательную службу. 3. Снизить давление и выпустить водород в факельную систему для утилизации',
        ],
        correctAnswers: [
          '1. Вызвать пожарную команду. 2. Перекрыть подачу технологических газов на установку. 3. Снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу',
        ],
      },
      {
        code: '63669118',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых металлических ящиках с крышками',
          'На специально оборудованной площадке',
          'В специально выделенном помещении',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63669119',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63669120',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Окна (когда оконные переплеты заполнены обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м²)',
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Фонарные переплеты',
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
      },
      {
        code: '63669121',
        text:
          'Какие системы должны быть проверены в обязательном порядке перед началом пуска установок получения водорода?',
        answers: [
          'Системы тепло- и водоснабжения',
          'Системы электро- и воздухоснабжения',
          'Системы охлаждения и снабжения инертными газами',
          'Системы отопления и вентиляции',
          'Все перечисленные системы',
        ],
        correctAnswers: ['Все перечисленные системы'],
      },
      {
        code: '63669122',
        text:
          'Какая должна быть степень огнестойкости зданий с производством и обращением водорода?',
        answers: [
          'Не ниже II',
          'Не выше I',
          'Не ниже III',
          'Не регламентируется',
        ],
        correctAnswers: ['Не ниже II'],
      },
      {
        code: '63669123',
        text:
          'При каких показателях газа разрешено применять жидкостные манометры?',
        answers: [
          'При давлении до 0,04 МПа',
          'При давлении до 0,03 МПа',
          'При давлении до 0,05 МПа',
        ],
        correctAnswers: ['При давлении до 0,03 МПа'],
      },
      {
        code: '63669124',
        text:
          'Какое минимальное количество выходов допускается в помещении операторной при площади свыше 150 м²?',
        answers: ['Один', 'Два', 'Три', 'Не регламентируется'],
        correctAnswers: ['Два'],
      },
      {
        code: '63669125',
        text:
          'Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?',
        answers: [
          'Инструкцией о мерах пожарной безопасности при проведении огневых работ',
          'Технологической инструкцией',
          'Должностной инструкцией',
          'Инструкцией по охране труда',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63669126',
        text:
          'Какая электроизоляция предусмотрена при проектировании и строительстве корпусов электролиза при производстве магния?',
        answers: [
          'Внутренних стен на высоту не менее 3 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий',
          'Внутренних стен на высоту не менее 1,5 м, колонн - на высоту не менее 2,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров',
          'Внутренних стен на высоту не менее 2,5 м, колонн - на высоту не менее 1,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров',
        ],
        correctAnswers: [
          'Внутренних стен на высоту не менее 3 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий',
        ],
      },
      {
        code: '63669127',
        text:
          'Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?',
        answers: [
          'Сброс конденсата из цеховых газопроводов следует производить через конденсатоотводчики',
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
          'Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения',
          'Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены',
        ],
        correctAnswers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
      },
      {
        code: '63669128',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства продуктов разделения воздуха (далее – ПРВ)?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 18% и не более 23%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63669129',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты?',
        answers: [
          'Дистанционное отключение',
          'Установка на линиях нагнетания запорных или отсекающих устройств',
          'Установка на линиях всасывания запорных или отсекающих устройств',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63669130',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'При стабильной работе газоиспользующего оборудования',
          'Если проводится периодическая корректировка режимных карт',
          'Не допускается ни в каком случае',
          'На усмотрение ответственного за безопасное производство работ',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63669131',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
          'У начальника цеха',
          'У ответственного за безопасное производство работ данного объекта газового хозяйства',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63669132',
        text:
          'Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
          'На 1,75 величины рабочего давления охлаждающей воды',
          'На 2,0 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63669133',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63669134',
        text:
          'Какие требования к процессу получения водорода и диссоциированного аммиака указаны неверно?',
        answers: [
          'Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован',
          'Регулировка и контроль производится с помощью приборов',
          'Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности А',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности А',
        ],
      },
      {
        code: '63669135',
        text:
          'Какие требования к выборочной ревизии водородопроводов указаны неверно?',
        answers: [
          'Периодичность ревизии должна быть в пределах от 1 года до 8 лет',
          'Периодичность ревизии должна быть в пределах от 5 года до 15 лет',
          'Ревизия должна совмещаться с периодическими испытаниями на прочность и плотность',
          'Сроки проведения выборочной ревизии водородопроводов устанавливает руководство организации с учетом специфики производства, результатов наружного осмотра и предыдущей ревизии',
        ],
        correctAnswers: [
          'Периодичность ревизии должна быть в пределах от 5 года до 15 лет',
        ],
      },
      {
        code: '63669136',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,3 кПа',
          'Не более 0,5 кПа',
          'Не более 1,0 кПа',
          'Не более 0,7 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63669137',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов?',
        answers: [
          'Предохраняющие устройства, отключающие приводы при перегрузке',
          'Исключение падения транспортируемых материалов',
          'Ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63669138',
        text:
          'Сколько суток после наполнения должны выдерживаться баллоны с криптоноксеноновой смесью и криптоном в наполнительной комнате или складе баллонов?',
        answers: [
          'Не менее 14 суток',
          'Не менее 10 суток',
          'Не менее 12 суток',
        ],
        correctAnswers: ['Не менее 14 суток'],
      },
      {
        code: '63669139',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 90,2%',
          'Не менее 95,2%',
          'Не менее 97,5%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63669140',
        text:
          'С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?',
        answers: [
          'Один раз в четыре года',
          'Один раз в пять лет',
          'Один раз в семь лет',
          'Один раз в шесть лет',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63669141',
        text:
          'Когда могут производиться ремонтные работы с открытым огнем в помещении с обращением водорода водородной станции?',
        answers: [
          'Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через 2 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через 3 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через час после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
        ],
        correctAnswers: [
          'Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
        ],
      },
      {
        code: '63669142',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о начале и окончании смены',
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63669143',
        text:
          'Какое из перечисленных положений нарушает требования безопасности при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Не допускается одновременное проведение ремонта трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          'Допускается одновременное проведение ремонта трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Не допускается одновременное проведение ремонта принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
        ],
        correctAnswers: [
          'Допускается одновременное проведение ремонта трубопроводов и арматуры теплого и холодного концов регенераторов',
        ],
      },
      {
        code: '63669144',
        text:
          'Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?',
        answers: [
          'Из природного и доменного',
          'Из коксового и конвертерного',
          'Из ферросплавного и реформенного',
          'Из всех перечисленных газов',
        ],
        correctAnswers: ['Из всех перечисленных газов'],
      },
      {
        code: '63669145',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: ['Один раз в полгода', 'Один раз в год', 'Один раз в 2 года'],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63669146',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В двух экземплярах',
          'В четырех экземплярах',
          'В одном экземпляре',
          'В трех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63669147',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?',
        answers: [
          'Сероводорода 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
          'Сероводорода 0,03 г/нм³, нафталина - 0,06 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
          'Сероводорода 0,04 г/нм³, нафталина - 0,07 г/нм³ (летом) и 0,2 г/нм³ (зимой)',
          'Сероводорода 0,05 г/нм³, нафталина - 0,09 г/нм³ (летом) и 0,4 г/нм³ (зимой)',
        ],
        correctAnswers: [
          'Сероводорода 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
        ],
      },
      {
        code: '63669148',
        text:
          'Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?',
        answers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
          'Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'Проливы растворителей на пол не допускаются, случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов',
        ],
        correctAnswers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
      },
      {
        code: '63669149',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств?',
        answers: [
          'Работы, связанные с аварийными ситуациями',
          'Постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Работы повышенной опасности (сложные, разовые, уникальные)',
          'Производятся все перечисленные виды работ',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63669150',
        text:
          'На каком расстоянии должны располагаться электрические распределительные устройства силовых и осветительных сетей с глухозаземленной нейтралью в электролизных корпусах производства магния от неогражденных шинопроводов и частей электролизеров, находящихся под напряжением постоянного тока?',
        answers: [
          'Не ближе 6 м',
          'Не ближе 5 м',
          'Не ближе 3 м',
          'Не ближе 1 м',
        ],
        correctAnswers: ['Не ближе 6 м'],
      },
      {
        code: '63669151',
        text:
          'С какой периодичностью следует проверять состояние адсорбента в адсорбционных блоках осушки?',
        answers: ['Один раз в 2 года', 'Один раз в год', 'Один раз в 3 года'],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63669152',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['2 м', '1,3 м', '0,8 м', '1,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63669153',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 36 часов',
          'Не менее 48 часов',
          'Не менее 72 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63669154',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'При зажигании горелок следует подавать максимальное количество воздуха',
          'При увеличении нагрузки на горелку следует сначала увеличить подачу воздуха, затем газа',
          'Место отбора импульса для сигнализатора падения давления определяется актом технической комиссии',
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63669155',
        text:
          'При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?',
        answers: ['20 мг/м³', '10 мг/м³', '35 мг/м³', '25 мг/м³'],
        correctAnswers: ['20 мг/м³'],
      },
      {
        code: '63669156',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 25% нижнего предела взрываемости (1% об.)',
          'Выше 20% нижнего предела взрываемости (0,8% об.)',
          'Выше 35 % нижнего предела взрываемости (2% об.)',
          'Выше 30 % нижнего предела взрываемости (1,5% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63669157',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
          'Должны быть предназначены для выполнения любых работ',
          'Должны быть выполнены из материалов, исключающих искрообразование',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63669158',
        text: 'Через какое расстояние должны заземляться наружные газопроводы?',
        answers: [
          'Через каждые 250 м',
          'Через каждые 300 м',
          'Через каждые 500 м',
          'Через каждые 750 м',
        ],
        correctAnswers: ['Через каждые 250 м'],
      },
      {
        code: '63669159',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу A', 'Классу B', 'Классу C', 'Классу D'],
        correctAnswers: ['Классу B'],
      },
      {
        code: '63669160',
        text:
          'Каким образом не допускается определять утечки газов из соединений?',
        answers: [
          'С помощью открытого пламени',
          'С использованием мыльного раствора',
          'С использованием специальных течеискателей',
        ],
        correctAnswers: ['С помощью открытого пламени'],
      },
      {
        code: '63669161',
        text:
          'Какие из перечисленных требований к стенам, отделяющим помещения операторной от помещений с взрывоопасными зонами любого класса, указаны верно?',
        answers: [
          'Стены должны быть выполнены из несгораемых материалов и иметь предел огнестойкости не менее 0,75 ч',
          'Стены должны быть пылегазонепроницаемыми',
          'Стены не должны иметь дверей и окон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63669162',
        text:
          'В каком случае установка листовой заглушки за отключающим запорным устройством не требуется при отключении участка газопровода или газового аппарата с последующим выполнением работ внутри него?',
        answers: [
          'Если отключающим устройством является листовая задвижка',
          'Если длительность работ составляет не более 30 минут',
          'Если это обусловлено проектной документацией',
          'Установка листовой заглушки требуется во всех случаях',
        ],
        correctAnswers: [
          'Если отключающим устройством является листовая задвижка',
        ],
      },
      {
        code: '63669163',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Загрузка шихты, не прошедшей пиротехнического контроля',
          'Загрузка пакетированного металлолома',
          'Допускается все перечисленное',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63669164',
        text:
          'Какой должен быть общий объем инертного газа для нужд продувки водородной системы?',
        answers: [
          'Не менее 4-кратного объема системы',
          'Не менее 2-кратного объема системы',
          'Не менее 3-кратного объема системы',
          'Равный объему системы',
        ],
        correctAnswers: ['Не менее 4-кратного объема системы'],
      },
      {
        code: '63669165',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением, равным 1,8 рабочего',
          'Давлением, равным 1,2 рабочего',
          'Давлением, равным рабочему',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63669166',
        text:
          'Какая объемная доля водорода в техническом аргоне, поступающем на очистку в воздухоразделительную установку (далее – ВРУ)?',
        answers: [
          'Не должна превышать 2,5%',
          'Не должна превышать 3,0%',
          'Не должна превышать 4,0%',
        ],
        correctAnswers: ['Не должна превышать 2,5%'],
      },
      {
        code: '63669167',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
          'Должна устраняться при помощи ручной регулировки',
          'Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63669168',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
          'По легкосбрасываемым ограждающим конструкциям',
          'По наружным стенам производственных зданий',
          'По стенам зданий на участках со сплошным остеклением',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63669169',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Образование взрывоопасной среды',
          'Попадание воздушной среды',
          'Возможность разгерметизации системы',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63669170',
        text:
          'Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?',
        answers: ['Менее 100 м', 'Менее 175 м', 'Менее 150 м'],
        correctAnswers: ['Менее 100 м'],
      },
      {
        code: '63669171',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,05 МПа (0,5 кгс/см²)',
          'При давлении 0,02 МПа (0,2 кгс/см²)',
          'При давлении 0,01 МПа (0,1 кгс/см²)',
          'При давлении 0,03 МПа (0,3 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63669172',
        text:
          'Что должно быть предусмотрено автоматизированной системой управления процессом при получении водорода методом диссоциации аммиака?',
        answers: [
          'Светозвуковая сигнализация при достижении предельной концентрации водорода под кровлей помещения диссоциаторов и компрессорной водорода',
          'Сигнализация понижения или повышения температуры диссоциации',
          'Обеспечение равномерной подачи аммиака к нижнему диссоциатору',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63669173',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу A', 'Классу B', 'Классу C', 'Классу D'],
        correctAnswers: ['Классу A'],
      },
      {
        code: '63669174',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: ['В радиусе 50 м', 'В радиусе 10 м', 'В радиусе 30 м'],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63669175',
        text:
          'Какое минимальное количество выходов допускается в помещении операторной, расположенном на первом этаже?',
        answers: ['Один', 'Два', 'Три', 'Не регламентируется'],
        correctAnswers: ['Один'],
      },
      {
        code: '63669176',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'От тепловых воздействий',
          'От возможного попадания на них расплава',
          'От механических воздействий',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63669177',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
          'Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха',
          'Работы должны производиться специально обученным цеховым обслуживающим персоналом',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63669178',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Класс точности не ниже 2,0',
          'Диаметр корпуса не менее 200 мм',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63669179',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,1% в час',
          'Не более 0,3% в час',
          'Не более 0,6% в час',
          'Не более 0,8% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63669180',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Должны быть изготовлены из прочной стали',
          'Должны быть изготовлены из легированного чугуна',
          'Не должны давать искры при работе с ними',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63669181',
        text: 'Какие требования к пневматическим КИПиА указаны неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'Воздухопроводы для КИПиА должны иметь буферные емкости, обеспечивающие запас сжатого воздуха для работы приборов',
          'Для пневматических КИПиА должны предусматриваться специальные установки и отдельные сети сжатого воздуха',
          'Воздухопроводы для КИПиА должны быть отдельными, не связанными с трубопроводами на технологические нужды',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63669182',
        text:
          'При каком давлении должна производиться настройка срабатывания отсекающих клапанов КРП?',
        answers: [
          'При давлении на 15% более расчетного и температуре 100 °C',
          'При давлении на 20% более расчетного и температуре 100 °C',
          'При давлении на 25% более расчетного и температуре 100 °C',
        ],
        correctAnswers: [
          'При давлении на 15% более расчетного и температуре 100 °C',
        ],
      },
      {
        code: '63669183',
        text:
          'При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 20% нижнего предела взрываемости (0,8% об.), кислорода менее 25% и более 34%, угарного газа более 25 мг/м³, метана не более 50% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 50% нижнего предела взрываемости (1,4% об.), кислорода менее 5% и более 20%, угарного газа более 60 мг/м³, метана не более 17% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25% нижнего предела взрываемости (0,85% об.), кислорода менее 35% и более 40%, угарного газа более 29 мг/м³, метана не более 5% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63669184',
        text:
          'При какой температуре возможно вскрытие реакторов каталитического гидрирования?',
        answers: [
          'После остывания их до температуры 40 °С',
          'После остывания их до температуры 45 °С',
          'После остывания их до температуры 50 °С',
        ],
        correctAnswers: ['После остывания их до температуры 40 °С'],
      },
      {
        code: '63669185',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 500 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 300 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 600 °C',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63669186',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: ['Один раз в 2 года', 'Один раз в 4 года', 'Один раз в 5 лет'],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63669187',
        text:
          'В соответствии с какой документацией должны производиться подготовка к пуску и пуск установок по производству водорода?',
        answers: [
          'В соответствии с технологическим регламентом',
          'В соответствии с инструкцией по эксплуатации',
          'В соответствии с проектом',
          'В соответствии со всей перечисленной документацией',
        ],
        correctAnswers: ['В соответствии со всей перечисленной документацией'],
      },
      {
        code: '63669188',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: ['Паром', 'Сжатым воздухом', 'Газом', 'Всем перечисленным'],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63669189',
        text:
          'До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?',
        answers: [
          'До температуры не ниже 20 °C и продувается воздухом',
          'До температуры не ниже 20 °C и продувается инертным газом',
          'До температуры не ниже 10 °C и продувается воздухом',
          'До температуры не ниже 15°C и продувается азотом',
        ],
        correctAnswers: ['До температуры не ниже 20 °C и продувается воздухом'],
      },
      {
        code: '63669190',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Составляется новый акт-допуск на следующий срок',
          'Продлевается текущий акт-допуск',
          'Работы продолжаются без оформления акта-допуска',
          'Работы продолжаются по письменному распоряжению начальника ОПО',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63669191',
        text:
          'Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?',
        answers: [
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
          'Должно быть выше верхних площадок колошника не менее чем на 2 м',
          'Должно быть выше верхних площадок колошника не менее чем на 3 м',
          'Должно быть выше верхних площадок колошника не менее чем на 1 м',
        ],
        correctAnswers: [
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
        ],
      },
      {
        code: '63669192',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Паспортом (формуляром) на компрессорную установку; инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов',
          'Журналом учета работы компрессора, графиком ремонтов компрессорной установки',
          'Паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63669193',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено в специально  отведенных выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено на свободных площадках, обеспечивающих свободный проход',
          'Должно быть предусмотрено в специальном помещении',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально  отведенных выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63669194',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Обратный клапан',
          'Два запорных вентиля',
          'Свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63669195',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'С ответственным представителем подразделения или организации (главным энергетиком)',
          'Со службой производственного контроля',
          'С начальником ГСС',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63669196',
        text:
          'Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?',
        answers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
          'Использование ПРВ по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям',
          'Для ведения надзора за безопасной эксплуатацией технических устройств и коммуникаций, связанных с потреблением ПРВ, назначаются ответственные лица из числа специалистов',
          'На кислородопроводах не допускается установка арматуры из сплавов на основе титана',
        ],
        correctAnswers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
      },
      {
        code: '63669197',
        text:
          'Какое количество ступеней электроизоляции от строительных конструкций корпуса электролиза должны иметь металлические трубопроводы, защитные трубы, короба и бронированные кабели, располагаемые на высоте менее 3,5 м, при производстве магния?',
        answers: [
          'Две ступени',
          'Три ступени',
          'Четыре ступени',
          'Одну ступень',
        ],
        correctAnswers: ['Две ступени'],
      },
      {
        code: '63669198',
        text:
          'На каких аппаратах, заполненных водородом, допускается производство ремонтных работ?',
        answers: [
          'Производство ремонтных работ не допускается',
          'На аппаратах, заполненных водородом менее чем на 50%',
          'На аппаратах с объемом водорода не более 0.3 м³',
        ],
        correctAnswers: ['Производство ремонтных работ не допускается'],
      },
      {
        code: '63669199',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее одного раза в пять лет',
          'Не менее одного раза в три года',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63669200',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63669201',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна быть удобной в эксплуатации',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
          'Конструкция завалочной машины должна обеспечить устойчивое выполнение операций',
          'Должна иметь металлическую сетку на смотровых стеклах',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63669202',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть выдержаны в помещении не менее смены',
          'Должны быть выдержаны в помещении не менее суток',
          'Должны быть предварительно просушены или прокалены',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63669203',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'Только на третьей ступени',
          'Только на первой ступени',
          'На всех ступенях',
          'Только на второй ступени',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63669204',
        text:
          'Что должно предусматриваться для обеспечения требуемых режимов эксплуатации установки по производству водорода?',
        answers: [
          'Управление и контроль всех технологических параметров оборудования из операторной',
          'Дистанционное (аварийное) отключение установки по производству водорода',
          'Дистанционный контроль оборудования из операторной',
          'Автоматическое отключение установки по производству водорода при нарушении нормальной работы по технологическим параметрам',
          'Должно предусматриваться все перечисленное',
        ],
        correctAnswers: ['Должно предусматриваться все перечисленное'],
      },
      {
        code: '63669205',
        text: 'Какие требования к компрессорным установкам указаны верно?',
        answers: [
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций',
          'Монтаж, наладка и эксплуатация компрессоров должны производиться в соответствии с инструкциями организаций-изготовителей',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63669206',
        text:
          'Какие из перечисленных требований при ведении работ на объектах производства водорода указаны неверно?',
        answers: [
          'Работники на объектах производства водорода должны быть обеспечены СИЗ, спецодеждой, спецобувью и другими средствами, которые должны отвечать требованиям соответствующих стандартов безопасности труда',
          'Открытые движущиеся части машин и механизмов, а также ременные, цепные и зубчатые передачи должны быть снабжены ограждениями, исключающими опасность травмирования людей этими частями и попадания в них посторонних предметов',
          'Узлы, детали, приспособления и элементы оборудования, которые могут служить источником опасности для работающих, а также поверхности оградительных и защитных устройств должны быть окрашены в сигнальные цвета',
          'Входить на объекты с производством и обращением водорода необходимо в обуви с железными набойками, а также в одежде, способной проводить заряды статического электричества',
        ],
        correctAnswers: [
          'Входить на объекты с производством и обращением водорода необходимо в обуви с железными набойками, а также в одежде, способной проводить заряды статического электричества',
        ],
      },
      {
        code: '63669207',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Еженедельно', 'Ежедневно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63669208',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через производственные помещения, связанные с потреблением водорода',
          'Через электрораспределительные, трансформаторные помещения, вентиляционные камеры',
          'Через бытовые, подсобные и административно-хозяйственные помещения',
          'Через лестничные клетки, пути эвакуации',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63669209',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в четыре года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63669210',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в 6 лет',
          'Один раз в 7 лет',
          'Один раз в 8 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63669211',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже трех раз в год',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63669212',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 3 месяцев',
          'В течение 6 месяцев',
          'В течение года',
          'В течение месяца',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63669213',
        text:
          'Каким должно быть сопротивление изоляции внутренних стен и колонн электролиза при производстве магния?',
        answers: [
          'Не менее 0,5 МОм',
          'Не менее 0,1 МОм',
          'Не менее 0,3 МОм',
          'Не менее 0,4 МОм',
        ],
        correctAnswers: ['Не менее 0,5 МОм'],
      },
      {
        code: '63669214',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Специальную для водорода',
          'Допускается применять любую арматуру из перечисленных',
          'Стальную для взрывоопасных сред',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63669215',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 1 часа',
          'Не менее 2 часов',
          'Не менее 3 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63669216',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 45 календарных дней',
          'На 15 рабочих дней',
          'На 30 календарных дней',
          'На 20 рабочих дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63669217',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
        ],
        correctAnswers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
        ],
      },
      {
        code: '63669218',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода?',
        answers: [
          'Задвижки должны быть установлены в нужное положение',
          'Водяные затворы должны быть залиты водой',
          'Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет проводиться продувка',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63669219',
        text:
          'В соответствии с каким документом работникам необходимо соблюдать требования безопасности при загрузке и выгрузке катализаторов и адсорбентов?',
        answers: [
          'В соответствии с технологическим регламентом',
          'В соответствии с техническими условиями завода-изготовителя',
          'В соответствии с требованиями инструкции, разработанной эксплуатирующей организацией',
        ],
        correctAnswers: ['В соответствии с технологическим регламентом'],
      },
      {
        code: '63669220',
        text: 'Какой срок должны храниться данные самопишущих приборов?',
        answers: [
          'Не менее 3 месяцев',
          'Не менее 6 месяцев',
          'Не менее 12 месяцев',
        ],
        correctAnswers: ['Не менее 3 месяцев'],
      },
      {
        code: '63669221',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из углеродистой стали',
          'Из коррозионно-стойкой стали или медных сплавов',
          'Из чугуна',
        ],
        correctAnswers: ['Из коррозионно-стойкой стали или медных сплавов'],
      },
      {
        code: '63669222',
        text:
          'Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?',
        answers: [
          'Задвижки и краны, устанавливаемые на газопроводах и устройствах, должны иметь указатель открытого и закрытого положения',
          'Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки или заглушки',
          'Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом',
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
        correctAnswers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
      },
      {
        code: '63669223',
        text:
          'Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?',
        answers: [
          'Автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов',
          'Применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции',
          'Применением средств индивидуальной и коллективной защиты работников',
          'Заменой производственных процессов и операций, связанных с наличием вредных и (или) опасных производственных факторов, процессами и операциями, при которых указанные факторы отсутствуют или имеют меньшую интенсивность',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63669224',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: [
          '0,1- 0,5 мм/г',
          'До 0,1 мм/г',
          'Более 0,5 мм/г',
          'Не регламентируется',
        ],
        correctAnswers: ['0,1- 0,5 мм/г'],
      },
      {
        code: '63669225',
        text:
          'Каково предельно допустимое содержание масла в кислороде, поступающем в компрессор?',
        answers: [
          'Не должно превышать 0,05 мг/м³',
          'Не должно превышать 0,03 мг/м³',
          'Не должно превышать 0,02 мг/м³',
        ],
        correctAnswers: ['Не должно превышать 0,02 мг/м³'],
      },
      {
        code: '63669226',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения, работающие от напряжения не более 24 В',
          'Должны предусматриваться системы освещения, работающие от напряжения не более 12 В',
          'Должны предусматриваться системы освещения, работающие от напряжения не более 36 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63669227',
        text:
          'До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?',
        answers: ['120 °C', '150 °C', '100 °C', '90 °C'],
        correctAnswers: ['120 °C'],
      },
      {
        code: '63669228',
        text:
          'Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Только ремонт трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Только ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          'Только ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'Не допускается производить одновременно все перечисленные ремонтные работы',
        ],
        correctAnswers: [
          'Не допускается производить одновременно все перечисленные ремонтные работы',
        ],
      },
      {
        code: '63669229',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть установлены на расстоянии не менее 15 м от печей',
          'Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63669230',
        text: 'Как должны сооружаться газопроводы на территории предприятия?',
        answers: [
          'Надземными',
          'Наземными',
          'Подземными',
          'Не регламентируется',
        ],
        correctAnswers: ['Надземными'],
      },
      {
        code: '63669231',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Только работниками подрядных организаций',
          'Только работниками эксплуатирующей организации',
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63669232',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['5 м', '10 м', '1,5 м', '7,5 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63669233',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 16 мм',
          'Не менее 14 мм',
          'Не менее 8 мм',
          'Не менее 12 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63669234',
        text:
          'Какая должна быть величина прибавки на коррозию при проектировании трубопроводов из углеродистой стали, работающих в среде водорода?',
        answers: [
          '0,1 - 0,5 мм/год',
          '0,7 - 1,3 мм/год',
          '0,5 - 0,8 мм/год',
          '0,3 - 0,9 мм/год',
        ],
        correctAnswers: ['0,1 - 0,5 мм/год'],
      },
      {
        code: '63669235',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Главный инженер предприятия',
          'Главный технолог предприятия',
          'Руководитель предприятия',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63669236',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
          'Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме',
          'При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта. На газопроводе природного газа должны быть установлены заглушки',
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63669237',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 1,3 м',
          'Не менее 0,8 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63669238',
        text:
          'Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 1,0 величины рабочего давления охлаждающей воды',
          'На 1,15 величины рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63669239',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Только в аварийных случаях',
          'Не разрешено',
          'Разрешено при увеличении давления газа до 5%',
          'Разрешено при увеличении давления газа до 10%',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63669240',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?',
        answers: ['0,01', '0,02', '0,03', '0,04'],
        correctAnswers: ['0,01'],
      },
      {
        code: '63669241',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
          'Приборы должны устанавливаться в непосредственной близости к оборудованию',
          'Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63669242',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Представителем Ростехнадзора',
          'Комиссией предприятия-заказчика',
          'Комиссией предприятия-заказчика, с участием представителя Ростехнадзора',
          'Строительно-монтажной организацией',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63669243',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Одним человеком под наблюдением газоспасателя или члена ДГСД',
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек под наблюдением газоспасателя или члена ДГСД',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63669244',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Организация, проектирующая систему автоматики печи',
          'Специализированная организация',
          'Организация, эксплуатирующая систему автоматики печи',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63669245',
        text:
          'Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?',
        answers: [
          'Немедленно должна быть отключена подача воды',
          'Должна быть снижена нагрузка на печь',
          'Печь должна быть немедленно отключена',
        ],
        correctAnswers: ['Печь должна быть немедленно отключена'],
      },
      {
        code: '63669246',
        text:
          'Какая должна быть величина прибавки на коррозию при проектировании трубопроводов из легированной стали, работающих в среде водорода?',
        answers: [
          'Не менее 0,5 мм/год',
          'Не менее 0,1 мм/год',
          'Не менее 0,3 мм/год',
          'Не менее 0,4 мм/год',
        ],
        correctAnswers: ['Не менее 0,5 мм/год'],
      },
      {
        code: '63669247',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.',
        answers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
          'Перед вскрытием для осмотра',
          'Перед ремонтом хотя бы одного узла',
        ],
        correctAnswers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
        ],
      },
      {
        code: '63669248',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Должны производиться в условиях, исключающих искрообразование',
          'Проверка может производиться непосредственно во  врывопожароопасных  и пожароопасных помещениях',
          'Проверка должна осуществляться только в специально выделенном для этого помещении',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63669249',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение года',
          'В течение трех месяцев',
          'В течение одного месяца',
          'В течение шести месяцев',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63669250',
        text:
          'Какое должно быть время срабатывания отсекающих устройств с дистанционным управлением для максимального снижения выбросов водорода в окружающую среду при аварийной разгерметизации системы?',
        answers: [
          'Не более 120 с',
          'Не более 180 с',
          'Не более 240 с',
          'Не более 300 с',
        ],
        correctAnswers: ['Не более 120 с'],
      },
      {
        code: '63669251',
        text:
          'Какое должно быть содержание кислорода в воздухе помещения наполнения баллонов?',
        answers: [
          'При объемной доле кислорода в воздухе не менее 19% и не более 23%',
          'При объемной доле кислорода в воздухе не менее 18% и не более 24%',
          'При объемной доле кислорода в воздухе не менее 18% и не более 23%',
        ],
        correctAnswers: [
          'При объемной доле кислорода в воздухе не менее 19% и не более 23%',
        ],
      },
      {
        code: '63669252',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?',
        answers: ['4 г/нм³', '9 г/нм³', '7 г/нм³', '5 г/нм³'],
        correctAnswers: ['4 г/нм³'],
      },
      {
        code: '63669253',
        text:
          'В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?',
        answers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
          'Если монтаж осуществляется внутри компрессорного помещения',
          'Если это прописано в технологической инструкции',
        ],
        correctAnswers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
      },
      {
        code: '63669254',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['2,5 часа', '1,0 час', '1,5 часа', '2,0 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63669255',
        text:
          'Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.',
        answers: ['СО', 'О2', 'СО2', 'Н2'],
        correctAnswers: ['СО', 'О2'],
      },
      {
        code: '63669256',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: ['Из чугуна', 'Из стали', 'Из сплавов титана'],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63669257',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Инструкции по эксплуатации',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Инструкция по проведению пусконаладочных работ',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63669258',
        text:
          'В каких местах должна устанавливаться запорная арматура и отсекающие устройства с дистанционным управлением на вводах водородопроводов в производственные здания?',
        answers: [
          'На расстоянии не менее 3,0 м и не более 50,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания',
          'На расстоянии не менее 2,0 м и не более 60,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания',
          'На расстоянии не менее 1,0 м и не более 65,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания',
          'На расстоянии не менее 1,5 м и не более 70,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания',
        ],
        correctAnswers: [
          'На расстоянии не менее 3,0 м и не более 50,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания',
        ],
      },
      {
        code: '63669259',
        text: 'Где допускается размещение операторных помещений?',
        answers: [
          'Размещение операторных помещений не допускается во всех перечисленных местах',
          'Под приточными вентиляционными камерами',
          'Под производственными помещениями с мокрым технологическим процессом',
          'Над помещениями с взрывоопасными зонами 2-го класса',
          'Под душевыми, санузлами',
        ],
        correctAnswers: [
          'Размещение операторных помещений не допускается во всех перечисленных местах',
        ],
      },
      {
        code: '63669260',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под разрежением',
          'После продувки газопроводов сжатым воздухом',
          'На газопроводах, находящихся под давлением',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63669261',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63669262',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 15% больше расчетного',
          'На 13% больше расчетного',
          'На 10% больше расчетного',
          'На 5% больше расчетного',
        ],
        correctAnswers: ['На 15% больше расчетного'],
      },
      {
        code: '63669263',
        text:
          'Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          '1 раз в месяц',
          '1 раз в неделю',
          '1 раз в квартал',
          'Не регламентируются',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63669264',
        text:
          'Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?',
        answers: [
          'Воздушной завесой',
          'Аэродинамической завесой',
          'Любым из перечисленных',
          'Азотной завесой',
        ],
        correctAnswers: ['Любым из перечисленных'],
      },
      {
        code: '63669265',
        text:
          'Как часто должна производиться проверка настройки отсечных и предохранительных клапанов?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в два месяца, а также после каждой ревизии и ремонта',
          'Не реже одного раза в три месяца, а также после каждой ревизии и ремонта',
        ],
        correctAnswers: [
          'Не реже одного раза в два месяца, а также после каждой ревизии и ремонта',
        ],
      },
      {
        code: '63669266',
        text:
          'Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Сухим подогретым воздухом',
          'Подогретой водой',
          'Открытым пламенем',
          'За счет тепла, подводимого к отогреваемым аппаратам',
        ],
        correctAnswers: ['Сухим подогретым воздухом'],
      },
      {
        code: '63669267',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63669268',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63669269',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63669270',
        text:
          'Чему должны соответствовать параметры процесса эксплуатации и ремонта оборудования?',
        answers: [
          'Требованиям установленного технологического регламента',
          'Рекомендациям завода-изготовителя',
          'Паспортным данным оборудования',
          'Всему перечисленному',
        ],
        correctAnswers: ['Всему перечисленному'],
      },
      {
        code: '63669271',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Не менее трех лет',
          'Не менее двух лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63669272',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Газом', 'Сжатым воздухом', 'Азотом'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63669273',
        text:
          'В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?',
        answers: [
          'Не менее 15 секунд',
          'Не менее 20 секунд',
          'Не менее 5 секунд',
          'Не менее 10 секунд',
        ],
        correctAnswers: ['Не менее 10 секунд'],
      },
      {
        code: '63669274',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['1 час', '2 часа', '1,5 часа', '3 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63669275',
        text:
          'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ['150 °C', '200 °C', '100 °C', '220 °C'],
        correctAnswers: ['150 °C'],
      },
      {
        code: '63669276',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии положительного заключения экспертного обследования',
          'При наличии разрешения завода-изготовителя',
          'По решению руководителя предприятия',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63669277',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
          'Пока на участке продувки кислорода будет не более 2% кислорода',
          'Пока на участке продувки кислорода будет не более 3% кислорода',
          'Пока на участке продувки кислорода будет не более 5% кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
        ],
      },
      {
        code: '63669278',
        text:
          'С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?',
        answers: [
          'С интервалом не менее чем 10 минут',
          'С интервалом не менее чем 5 минут',
          'С интервалом не менее чем 7 минут',
          'С интервалом не менее чем 3 минуты',
        ],
        correctAnswers: ['С интервалом не менее чем 10 минут'],
      },
      {
        code: '63669279',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Проверка работоспособности запорной арматуры',
          'Проверка соответствия параметров настройки предохранительной и защитной арматуры режимной карте',
          'Устранение утечек газа из разъемных соединений технических устройств',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
      {
        code: '63669280',
        text:
          'Какие требования к аварийной системе вентиляции в компрессорном помещении при получении водорода методом диссоциации аммиака указаны неверно?',
        answers: [
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 2,4% содержания водорода в воздухе помещения',
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 20% нижнего предела взрываемости',
          'Аварийная система вентиляции должна обеспечивать в компрессорном помещении 8-кратный воздухообмен',
        ],
        correctAnswers: [
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 2,4% содержания водорода в воздухе помещения',
        ],
      },
      {
        code: '63669281',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'С ГСС',
          'Со службой производственного контроля',
          'С главным энергетиком',
          'С главным инженером',
        ],
        correctAnswers: ['С ГСС', 'Со службой производственного контроля'],
      },
      {
        code: '63669282',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно?',
        answers: [
          'Ручной привод арматуры должен располагаться на высоте не более 3,5 м от уровня площадки или пола помещения',
          'В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 3,0 МПа и температуре от 10 °C до 40 °C, работающей в условиях, не подверженных вибрациям и резко переменному температурному режиму',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'Арматура должна размещаться над дверными проемами и технологическими проходами',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
    ],
    63666: [
      {
        code: '63666000',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только со службой производственного контроля',
          'Только с начальником газоспасательной службы',
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63666001',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'На расстоянии 20 метров от зоны работы грейферного крана в случае, если кран работает внутри производственного здания',
          'В зоне погрузки грейферными или магнитными кранами',
          'На расстоянии 15 метров от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'Во всех перечисленных зонах',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63666002',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Ежеквартально', 'Ежедневно', 'Ежедекадно', 'Ежемесячно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63666003',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 19 % и не более 23 %',
          'Не менее 18 % и не более 23 %',
          'Не менее 17 % и не более 23 %',
        ],
        correctAnswers: ['Не менее 19 % и не более 23 %'],
      },
      {
        code: '63666004',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должны быть в наличии огнетушители',
          'Должны быть устроены ящики с песком',
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63666005',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения напряжением 36 В',
          'Должны предусматриваться системы освещения напряжением 24 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63666006',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже одного раза в год и не реже одного раза в три года соответственно',
          'Не реже одного раза в три года и не реже одного раза в пять лет соответственно',
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
          'Не реже двух раз в год и не реже одного раза в пять лет соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63666007',
        text:
          'Какое из перечисленных требований к стационарным газоразборным постам указано неверно?',
        answers: [
          'Газоразборные посты, шкафы должны иметь опознавательную окраску',
          'Дверцы шкафов во время работы должны быть закрыты',
          'При снабжении газоразборного поста газом от баллона на последнем должен быть установлен редуктор для снижения давления газа',
          'При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок',
        ],
        correctAnswers: ['Дверцы шкафов во время работы должны быть закрыты'],
      },
      {
        code: '63666008',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Загрузка шихты, не прошедшей пиротехнический контроль',
          'Загрузка пакетированного металлолома',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63666009',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением, равным рабочему',
          'Давлением, на 20% превышающем рабочее',
          'Давлением, на 25% превышающем рабочее',
          'Давлением, на 50% превышающем рабочее',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63666010',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,5% в час',
          'Не более 0,3% в час',
          'Не более 0,2% в час',
          'Не более 0,1% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63666011',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых металлических ящиках с крышками',
          'На специально оборудованной площадке',
          'В специально выделенном помещении',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63666012',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство',
          'Средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи, а при компоновке печных агрегатов с котлами-утилизаторами системами по переводу на работу агрегатов без дымососов',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63666013',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Газом', 'Сжатым воздухом', 'Техническим воздухом'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63666014',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь ограждение минимум с двух сторон',
          'Должны иметь по периметру пешеходные  проходы шириной 1,0 м',
          'Со всех сторон должны иметь ограждение',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63666015',
        text:
          'Каким документом должны быть предусмотрены время вывалки шлака после выпуска или разливки его, а также порядок производства работ по очистке бункеров от слежавшегося шлака?',
        answers: [
          'Технологической инструкцией',
          'Правилами безопасности процессов получения или применения металлов',
          'Технологическим регламентом',
          'Не регламентируется',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63666016',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о начале и окончании смены',
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63666017',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после тщательного их просушивания и разогрева',
          'Только после выдержки в течение суток при температуре 40 °С',
          'Только после тщательного их просушивания',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63666018',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'От механических воздействий',
          'От тепловых воздействий',
          'От возможного попадания на них расплава',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63666019',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (22 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63666020',
        text:
          'При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?',
        answers: ['20 мг/м³', '15 мг/м³', '18 мг/м³', '12 мг/м³'],
        correctAnswers: ['20 мг/м³'],
      },
      {
        code: '63666021',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63666022',
        text:
          'Какое расчетное давление газа соответствует газопроводам среднего давления?',
        answers: [
          'Свыше 0,1 до 0,3 МПа включительно',
          'Свыше 0,3 до 0,6 МПа включительно',
          'Свыше 0,3 до 1,2 МПа включительно',
          'Свыше 0,6 до 1,2 МПа включительно',
        ],
        correctAnswers: ['Свыше 0,1 до 0,3 МПа включительно'],
      },
      {
        code: '63666023',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'Розжиг газопотребляющего агрегата должен проводиться в соответствии с инструкцией по эксплуатации агрегата',
          'Отключающее устройство на газопроводе перед горелкой необходимо открыть до поднесения к горелке запальника, факела или другого средства, воспламеняющего газ',
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
          'Продувочные свечи после отключения газопровода должны быть в закрытом положении',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63666024',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением',
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63666025',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через вентиляционные камеры',
          'Через бытовые и подсобные помещения',
          'Через административно-хозяйственные помещения',
          'Через производственные помещения, связанные с потреблением водорода',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63666026',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны быть во взрывозащищенном исполнении',
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
          'Должны быть предназначены для выполнения любых работ',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63666027',
        text:
          'Какое из перечисленных требований не распространяется на аспирационные установки дробильных агрегатов для силикокальция и модификаторов ферросилициймагния?',
        answers: [
          'Должны быть оснащены датчиками контроля содержания кислорода',
          'Должны быть выполнены во взрывозащищенном исполнении',
          'Должны быть оснащены предохранительными взрывными клапанами и свечами для сброса водорода',
          'Должны быть оснащены датчиками контроля содержания водорода',
        ],
        correctAnswers: [
          'Должны быть оснащены датчиками контроля содержания кислорода',
        ],
      },
      {
        code: '63666028',
        text: 'Как должны сооружаться газопроводы на территории предприятия?',
        answers: [
          'Надземными',
          'Наземными',
          'Подземными',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Надземными'],
      },
      {
        code: '63666029',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 25 % нижнего предела взрываемости (1% об.)',
          'Выше 30 % нижнего предела взрываемости (1,5% об.)',
          'Выше 35 % нижнего предела взрываемости (2% об.)',
          'Выше 40 % нижнего предела взрываемости (2,5% об.)',
        ],
        correctAnswers: ['Выше 25 % нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63666030',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны устанавливаться в непосредственной близости к оборудованию',
          'Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63666031',
        text:
          'Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?',
        answers: [
          'Из природного и доменного',
          'Из коксового и конверторного',
          'Из ферросплавного и реформерного',
          'Из всех перечисленных газов',
        ],
        correctAnswers: ['Из всех перечисленных газов'],
      },
      {
        code: '63666032',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 6 месяцев',
          'В течение 3 месяцев',
          'В течение месяца',
          'В течение 12 месяцев',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63666033',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 20 % больше расчетного',
          'На 15 % больше расчетного',
          'На 10 % больше расчетного',
          'На 5 % больше расчетного',
        ],
        correctAnswers: ['На 15 % больше расчетного'],
      },
      {
        code: '63666034',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'Только при наличии качки цапф в теле ковша',
          'Только при потере формы ковша вследствие деформации',
          'Только при повреждении футеровки ковша',
          'При наличии хотя бы одного из перечисленных.',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных.'],
      },
      {
        code: '63666035',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
          'Только работы, связанные с аварийными ситуациями',
          'Производятся все перечисленные виды работ',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63666036',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Текущий ремонт',
          'Капитальный ремонт',
          'Техническое обслуживание',
          'Осмотр технического состояния (обход)',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63666037',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['95 °C', '90 °C', '85 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63666038',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'При стабильной работе газоиспользующего оборудования',
          'В случае если срок срок эксплуатации оборудования составляет не более 15 лет',
          'В случае если срок срок эксплуатации оборудования составляет не более 10 лет',
          'В случае организации систематического контроля выбросов загрязняющих веществ в атмосферу не реже чем 1 раз в месяц',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63666039',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Не менее полугода',
          'Не менее двух лет',
          'Не менее трех лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63666040',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 45 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63666041',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: ['Один раз в 7 лет', 'Один раз в 4 года', 'Один раз в 5 лет'],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63666042',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в 6 лет',
          'Один раз в 7 лет',
          'Один раз в 8 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63666043',
        text:
          'Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?',
        answers: [
          'Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними',
          'Перелив растворителей из одного сосуда в другой не допускается',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'Проливы растворителей на пол не допускаются; случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов',
        ],
        correctAnswers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
      },
      {
        code: '63666044',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Должны быть очищены',
          'Перед установкой фурмы должны храниться в специальном помещении не менее суток',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63666045',
        text:
          'На каком расстоянии от путей и натяжного троса допускается нахождение людей при транспортировании тележек с изложницами или ковшами, наполненными металлом или шлаком?',
        answers: ['Не ближе 5 м', 'Не ближе 3 м', 'Не ближе 2 м'],
        correctAnswers: ['Не ближе 5 м'],
      },
      {
        code: '63666046',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63666047',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Должны производиться в условиях, исключающих искрообразование',
          'Проведение проверки допускается непосредственно во взрывопожароопасных и пожароопасных помещениях',
          'Проверка должна осуществляться только в специально выделенном для этого помещении',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63666048',
        text:
          'Какие требования из приведенных должны выполняться при грануляции сплавов?',
        answers: [
          'Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана только через носок ковша в приемник',
          'Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана через приемник',
          'Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана через стопор',
        ],
        correctAnswers: [
          'Грануляция передельного силикомарганца и углеродистого феррохрома, ферросилиция 45 % разрешается при помощи электромостового крана через приемник',
        ],
      },
      {
        code: '63666049',
        text:
          'По чьей команде должны производиться установка и снятие с ковша кантователя?',
        answers: [
          'Ответственного за производственный контроль',
          'Машиниста разливочной машины',
          'Технического руководителя организации (главного инженера)',
          'Руководителя подразделения',
        ],
        correctAnswers: ['Машиниста разливочной машины'],
      },
      {
        code: '63666050',
        text:
          'На каком расстоянии не допускается нахождение персонала в процессе грануляции металла?',
        answers: [
          'В радиусе менее 10 м',
          'В радиусе менее 8 м',
          'В радиусе менее 12 м',
        ],
        correctAnswers: ['В радиусе менее 10 м'],
      },
      {
        code: '63666051',
        text:
          'Каким минимальным количеством лиц должно быть обеспечено постоянное наблюдение за работниками в воздушном сепараторе при выполнении работ по очистке его внутренних полостей?',
        answers: ['Одним', 'Двумя', 'Тремя', 'Четырьмя'],
        correctAnswers: ['Двумя'],
      },
      {
        code: '63666052',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63666053',
        text:
          'С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?',
        answers: [
          'Один раз в четыре года',
          'Один раз в пять лет',
          'Один раз в три года',
          'Один раз в десять лет',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63666054',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'По наружным стенам производственных зданий',
          'По стенам зданий на участках со сплошным остеклением',
          'По легкосбрасываемым ограждающим конструкциям',
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63666055',
        text:
          'Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Колонны высокого и низкого давлений',
          'Средства измерения общего назначения, предназначенные для работы в кислородосодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)',
          'Воздушные секции теплообменников',
        ],
        correctAnswers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородосодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63666056',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии положительного заключения экспертного обследования',
          'При наличии разрешения завода-изготовителя',
          'При наличии разрешения территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63666057',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу А', 'Классу B', 'Классу C', 'Классу D'],
        correctAnswers: ['Классу B'],
      },
      {
        code: '63666058',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На герметичность', 'На плотность', 'На прочность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63666059',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Жетон-бирочная система применяется для предотвращения травм и аварий в результате ошибочного или несанкционированного включения технического устройства (ТУ), а также в целях согласования действий технологического и ремонтного персонала',
          'Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63666060',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['30 минут', '40 минут', '1 час', '2 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63666061',
        text:
          'Какое из перечисленных требований к установке сероочистки природного газа указано верно?',
        answers: [
          'Установка сероочистки должна иметь подвод азота и кислорода',
          'Один раз в пять лет газопроводы сероочистки подвергаются пневматическому испытанию',
          'Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы. Содержание CH₄ в двух последовательно отобранных пробах должно быть не более 3 %',
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
        ],
        correctAnswers: [
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
        ],
      },
      {
        code: '63666062',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?',
        answers: ['0,1 кПа', '0,3 кПа', '0,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63666063',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?',
        answers: [
          'Из меди',
          'Из бронзы',
          'Из поролона или других органических материалов',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63666064',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'С Ростехнадзором',
          'С профессиональной аварийно-спасательной службой',
          'С ГСС',
          'Со службой производственного контроля',
        ],
        correctAnswers: ['С ГСС', 'Со службой производственного контроля'],
      },
      {
        code: '63666065',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 1 % кислорода',
          'Пока на участке продувки кислорода будет не более 1,5 % кислорода',
          'Пока на участке продувки кислорода будет не более 2 % кислорода',
          'Пока на участке продувки кислорода будет не более 3 % кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1 % кислорода',
        ],
      },
      {
        code: '63666066',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
          'Один производитель (руководитель) работ',
          'Не регламентируется',
          'В зависимости от вида работ',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63666067',
        text:
          'Какой должен использоваться инструмент при загрузке карбида кальция в металлические барабаны?',
        answers: ['Медный, латунный', 'Стальной', 'Чугунный'],
        correctAnswers: ['Медный, латунный'],
      },
      {
        code: '63666068',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Составляется новый акт-допуск на следующий срок',
          'Акт-допуск продлевается',
          'Работы продолжаются без оформления',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63666069',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'Если подающее устройство оборудовано автоматическими системами гашения пламени',
          'Если подающее устройство оборудовано системами автоматического пожаротушения',
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63666070',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение одного месяца',
          'В течение трех месяцев',
          'В течение шести месяцев',
          'В течение одного года',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63666071',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['85 °C', '90 °C', '95 °C', '87 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63666072',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63666073',
        text:
          'Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          '1 раз в месяц',
          '1 раз в квартал',
          '1 раз в год',
          'Не регламентируются',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63666074',
        text: 'Через какое расстояние должны заземляться наружные газопроводы?',
        answers: [
          'Через каждые 250 м',
          'Через каждые 350 м',
          'Через каждые 300 м',
          'Через каждые 400 м',
        ],
        correctAnswers: ['Через каждые 250 м'],
      },
      {
        code: '63666075',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На плотность под рабочим давлением',
          'На плотность под избыточным давлением',
          'На плотность под испытательным давлением',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63666076',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В двух экземплярах',
          'В одном экземпляре',
          'В трех экземплярах',
          'В четырех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63666077',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['1,5 м', '3 м', '5 м', '10 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63666078',
        text:
          'Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя',
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63666079',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 1,3 м',
          'Не менее 1,2 м',
          'Не менее 1,1 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63666080',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 4 года',
          'Один раз в 5 лет',
          'Один раз в 8 лет',
          'Один раз в 10 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63666081',
        text:
          'Каким гидравлическим давлением должны быть испытаны на прочность и плотность трубки индуктора?',
        answers: [
          'Давлением, превышающим рабочее давление охлаждающей воды не менее чем в 1,5 раза',
          'Давлением, превышающим рабочее давление охлаждающей воды не менее чем в 0,5 раза',
          'Давлением, превышающим рабочее давление охлаждающей воды не менее чем в 1,25 раза',
          'Давлением, превышающим рабочее давление охлаждающей воды не менее чем в 0,75 раза',
        ],
        correctAnswers: [
          'Давлением, превышающим рабочее давление охлаждающей воды не менее чем в 1,5 раза',
        ],
      },
      {
        code: '63666082',
        text:
          'В каком случае допускается охлаждать слитки, ковши с остатками застывшего металла и изложницы в грануляционных баках при производстве ферросплавов?',
        answers: [
          'В баках для замачивания слитков, предусмотренных проектом',
          'В грануляционных баках',
          'Ни в каком случае',
          'В любом случае',
        ],
        correctAnswers: [
          'В баках для замачивания слитков, предусмотренных проектом',
        ],
      },
      {
        code: '63666083',
        text:
          'Согласно требованиям какого документа должна проводиться чистка горячих продуктов ферросплавного производства?',
        answers: [
          'Технологической инструкции',
          'Инструкции по эксплуатации',
          'Технологической карты',
          'Регламента по обслуживанию',
        ],
        correctAnswers: ['Технологической инструкции'],
      },
      {
        code: '63666084',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: ['Один раз в 3 года', 'Один раз в год', 'Один раз в 2 года'],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63666085',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?',
        answers: ['0,01', '0,02', '0,015', '0,03'],
        correctAnswers: ['0,01'],
      },
      {
        code: '63666086',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть прогреты',
          'Должны быть очищены от мусора',
          'Должны быть просушены',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63666087',
        text: 'В каком месте разрешено прожигать и расшуровывать летку?',
        answers: [
          'На сухой площадке (подставке), выполненной из диэлектрического материала',
          'На открытой площадке с применением подставки из стальной сетки',
          'На закрытой площадке, покрытой металлическими листами',
          'На закрытой площадке, отсыпанной песком',
        ],
        correctAnswers: [
          'На сухой площадке (подставке), выполненной из диэлектрического материала',
        ],
      },
      {
        code: '63666088',
        text:
          'В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если изменился состав бригады',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
          'Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технического устройства и порядка выполнения работ',
          'Если выявлено нарушение условий отключения технических устройств',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63666089',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'Хранение в помещении ГРП обтирочных и других горючих материалов не допускается',
          'В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в месяц',
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63666090',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть установлены на расстоянии не менее 3 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть установлены на расстоянии не менее 1 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63666091',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в 5 лет', 'Один раз в 3 года', 'Один раз в 2 года'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63666092',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63666093',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'На расстоянии не более 1 м от агрегатов',
          'На расстоянии не менее 3 м от агрегатов',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63666094',
        text:
          'Что из перечисленного допускается продувать сжатым воздухом при работе на доменном, ферросплавном и конвертерном газах и их смесях? Выберите правильный вариант ответа.',
        answers: [
          'Только газодувки',
          'Только нагнетатели и компрессоры',
          'Только газовые утилизационные бескомпрессорные турбины',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63666095',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Образование взрывоопасной среды',
          'Попадание воздушной среды',
          'Возможность разгерметизации системы',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63666096',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждые 5 лет',
          'Через каждые 7 лет',
          'Через каждые 4 года',
          'Через каждые 2 года',
        ],
        correctAnswers: ['Через каждые 5 лет'],
      },
      {
        code: '63666097',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 3 месяца',
          'В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 2 месяца',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63666098',
        text:
          'Какие из перечисленных требований безопасности в ферросплавном производстве указаны неверно?',
        answers: [
          'Допускается подавать на склад шихтовых материалов горячие прошлакованные оборотные отходы',
          'Нарушения кладки обжиговых печей, сопровождающиеся выделением газов в помещение, должны устраняться',
          'На электропечах должен осуществляться постоянный контроль за целостностью кожухов (отсутствие трещин, прогаров)',
          'Удаление обломков электродов должно производиться на отключенной печи',
        ],
        correctAnswers: [
          'Допускается подавать на склад шихтовых материалов горячие прошлакованные оборотные отходы',
        ],
      },
      {
        code: '63666099',
        text:
          'При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10 % нижнего предела взрываемости (0,4 % об.), кислорода менее 19 % и более 23 %, угарного газа более 20 мг/м³, метана не более 10 % нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 20 % нижнего предела взрываемости (0,8 % об.), кислорода менее 25 % и более 34 %, угарного газа более 25 мг/м³, метана не более 50 % нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25 % нижнего предела взрываемости (0,85 % об.), кислорода менее 35 % и более 40 %, угарного газа более 29 мг/м³, метана не более 5 % нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 50 % нижнего предела взрываемости (1,4 % об.), кислорода менее 5 % и более 20 %, угарного газа более 60 мг/м³, метана не более 17 % нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10 % нижнего предела взрываемости (0,4 % об.), кислорода менее 19 % и более 23 %, угарного газа более 20 мг/м³, метана не более 10 % нижнего предела взрываемости',
        ],
      },
      {
        code: '63666100',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1 м в свету',
          'Не менее 1,3 м в свету',
          'Не менее 1,5 м в свету',
          'Не менее 3 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63666101',
        text:
          'Чем должны быть оборудованы ванны с кислотой для травления оборудования?',
        answers: [
          'Защитным экраном',
          'Местными отсосами',
          'Укрытиями',
          'Всем перечисленным',
        ],
        correctAnswers: ['Местными отсосами'],
      },
      {
        code: '63666102',
        text:
          'При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?',
        answers: [
          'При увеличении до 1 %',
          'При увеличении до 2 %',
          'При увеличении до 1,5 %',
        ],
        correctAnswers: ['При увеличении до 2 %'],
      },
      {
        code: '63666103',
        text: 'В каких случаях не допускается дробление карбида кальция?',
        answers: [
          'При наличии влаги на загрузочной площадке дробилки',
          'При наличии влаги в лотках дробилки',
          'При наличии влаги в приемном конусе',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63666104',
        text:
          'Какие из перечисленных смесей не допускается применять при производстве ферросплавов? Выберите правильный вариант ответа.',
        answers: [
          'Только смеси, процесс горения которых переходит во взрыв',
          'Только смеси, способные к самостоятельному горению и имеющие удельную теплоту процесса горения более 50 кДж/моль',
          'Только смеси, чувствительность которых к механическому воздействию (удару) составляет 19,6 Дж и менее, а активной составляющей - 9,8 Дж и менее',
          'Все перечисленные смеси применять не допускается',
        ],
        correctAnswers: ['Все перечисленные смеси применять не допускается'],
      },
      {
        code: '63666105',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Окна с оконными переплетами, заполненными обычным оконным стеклом толщиной 3, 4 и 5 мм, площадью не менее соответственно 0,8, 1 и 1,5 м²',
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Фонарные переплеты',
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
      },
      {
        code: '63666106',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: ['Из меди', 'Из стали', 'Из сплавов титана'],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63666107',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В паспорт оборудования',
          'В специальный журнал по устранению дефектов',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63666108',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 100 м',
          'В радиусе 70 м',
          'В радиусе 50 м',
          'В радиусе 25 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63666109',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Установка по производству реформерного газа для производства металлизованного горячевосстановленного железа должна иметь подвод азота для его продувки перед пуском в работу',
          'Продувка азотом должна осуществляться до тех пор, пока на участке продувки кислорода будет не более 1 % кислорода',
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63666110',
        text:
          'Какие из перечисленных требований указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'При образовании электрической дуги, связанной с разрывом электрической цепи, не допускается подходить к электролизным ваннам до снятия напряжения',
          'Погрузка слитков рафинированного феррохрома разрешается только на платформы и в короба с высокими бортами или в обычные короба, установленные в специальном укрытии',
          'Передача слитков в склад готовой продукции должна производиться только после полного их остывания',
          'Не допускается производить выгрузку шлаковых гарниссажей в ковш при наличии в нем жидкого шлака и металла',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63666111',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Должны быть изготовлены из стали',
          'Должны быть изготовлены из чугуна',
          'Не должны давать искры при работе с ними',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63666112',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Класс точности не ниже 2,0',
          'Диаметр корпуса не менее 200 мм',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63666113',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
        ],
        correctAnswers: [
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
        ],
      },
      {
        code: '63666114',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: ['Паром', 'Сжатым воздухом', 'Газом', 'Всем перечисленным'],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63666115',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'В соответствии с требованиями строительных норм и правил',
          'В соответствии с требованиями нормативно-технической документации для технологических стальных трубопроводов',
          'В соответствии с требованиями Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63666116',
        text:
          'Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?',
        answers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
          'Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения',
          'Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
      },
      {
        code: '63666117',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
          'Конструкция завалочной машины должна обеспечить устойчивое выполнение операций',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63666118',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: ['Один раз в 3 года', 'Один раз в 5 лет', 'Один раз в 2 года'],
        correctAnswers: ['Один раз в 2 года'],
      },
      {
        code: '63666119',
        text:
          'Светильники каким напряжением разрешается применять для освещения внутри вагона при складировании, шлакопереработке, транспортировании ферросплавов?',
        answers: ['До 220 В', 'До 127 В', 'До 50 В', 'До 380 В'],
        correctAnswers: ['До 50 В'],
      },
      {
        code: '63666120',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено на свободных площадках, обеспечивающих беспрепятственный проход',
          'Должно быть предусмотрено в специальном помещении',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63666121',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.',
        answers: [
          'Перед пуском',
          'После остановки более чем на 1 час',
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском, при условии что в период остановки оборудование находилось под избыточным давлением кислорода',
        ],
        correctAnswers: [
          'Перед пуском',
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
        ],
      },
      {
        code: '63666122',
        text:
          'До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?',
        answers: [
          'До температуры не ниже 15 °C и продуваться азотом',
          'До температуры не ниже 20 °C и продуваться азотом',
          'До температуры не ниже 15 °C и продуваться воздухом',
          'До температуры не ниже 20 °C и продуваться воздухом',
        ],
        correctAnswers: ['До температуры не ниже 20 °C и продуваться воздухом'],
      },
      {
        code: '63666123',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В производственных помещениях',
          'В отдельно стоящих зданиях',
          'В отдельных щитовых помещениях (встроенных или пристроенных)',
          'В смежных с помещениями с взрывоопасными зонами',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63666124',
        text:
          'Какая должна быть температура в помещениях в холодное время года там, где осуществляются мокрые производственные процессы?',
        answers: ['Не ниже 16 °С', 'Не ниже 18 °С', 'Не ниже 20 °С'],
        correctAnswers: ['Не ниже 16 °С'],
      },
      {
        code: '63666125',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.',
        answers: [
          'Баллоны при перемещении на тележке можно не закреплять при условии перевозки в вертикальном положении',
          'Максимально допустимая температура баллона сжиженного газа должна соответствовать требованиям технологических карт',
          'При питании передвижного рабочего поста сжиженным газом от баллона допускается отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63666126',
        text:
          'С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?',
        answers: [
          'С интервалом не менее чем 5 минут',
          'С интервалом не менее чем 10 минут',
          'С интервалом не менее чем 15 минут',
          'С интервалом не менее чем 30 минут',
        ],
        correctAnswers: ['С интервалом не менее чем 10 минут'],
      },
      {
        code: '63666127',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Начальник цеха - ответственный за производственный контроль',
          'Главный инженер',
          'Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63666128',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,7 кПа',
          'Не более 0,5 кПа',
          'Не более 0,4 кПа',
          'Не более 0,3 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63666129',
        text:
          'При каких показателях газа разрешено применять жидкостные манометры?',
        answers: [
          'При давлении до 0,04 МПа',
          'При давлении до 0,03 МПа',
          'При давлении до 0,05 МПа',
        ],
        correctAnswers: ['При давлении до 0,03 МПа'],
      },
      {
        code: '63666130',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['1,5 м', '2 м', '3 м', '3,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63666131',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Не допускается уборка, вызывающая распыление вредных веществ',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63666132',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63666133',
        text:
          'В каком случае допускается течь масла из гидравлического привода механизма перемещения электродов?',
        answers: [
          'В случае осуществления постоянного контроля',
          'В случае если это допускается проектной документацией',
          'В случае если это допускается производственной инструкцией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63666134',
        text:
          'Освещением с каким напряжением должны пользоваться лица, выполняющие работу в воздушном сепараторе, при выполнении работ по очистке его внутренних полостей?',
        answers: [
          'Не выше 12 В',
          'Не выше 36 В',
          'Не выше 42 В',
          'Не выше 24 В',
        ],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '63666135',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При падении давления воды в системе водоохлаждения на 10 %',
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При  повышении давления воды в системе охлаждения на 5 %',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63666136',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три месяца',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63666137',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['45 минут', '1,5 часа', '2 часа', '2,5 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63666138',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Только исключение падения транспортируемых материалов',
          'Только предохраняющие устройства, отключающие приводы при перегрузке',
          'Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63666139',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
          'Должна устраняться при помощи ручной регулировки',
          'Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63666140',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В вертикальном положении',
          'В горизонтальном положении',
          'В наклонном положении',
          'Не регламентируется',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63666141',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из углеродистой стали',
          'Только из медных сплавов',
          'Из коррозионностойкой стали или медных сплавов',
        ],
        correctAnswers: ['Из коррозионностойкой стали или медных сплавов'],
      },
      {
        code: '63666142',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенных вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['50 м', '25 м', '30 м', '40 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63666143',
        text:
          'В соответствии с требованиями какой документации должны проводиться работы по перепуску и наращиванию самообжигающихся электродов рудовосстановительных ферросплавных печей, приварке тормозной ленты и загрузке электродной массы?',
        answers: [
          'Технологической инструкции',
          'Правил безопасности процессов получения или применения металлов',
          'Технологического регламента',
          'Всех перечисленных',
        ],
        correctAnswers: ['Технологической инструкции'],
      },
      {
        code: '63666144',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы могут быть продолжены с разрешения производителя (руководителя) работ',
          'Работы могут быть продолжены с разрешения выдающего наряд-допуск',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен дубликат наряда-допуска',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63666145',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчик)',
          'Акт-допуск должен определять допуск и условия производства работ подрядчиком на территории заказчика с учетом согласованных организационных и технических мероприятий, обеспечивающих безопасность работ',
          'На один объект, площадку, территорию следует оформлять один акт-допуск',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчик)',
        ],
      },
      {
        code: '63666146',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя',
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63666147',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в шесть месяцев',
          'Не менее одного раза в три месяца',
          'Не менее одного раза в два года',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63666148',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63666149',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные изготовителем',
          'В сроки, установленные эксплуатирующей организацией',
          'В сроки, определяемые специализированной организацией, но не реже чем один раз в пять лет',
          'В сроки, определяемые специализированной организацией, но не реже чем один раз в десять лет',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63666150',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в 2 часа',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 2 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 5 в час.',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63666151',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Брезентовые покрывала',
          'Воздушно-пенный огнетушитель',
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63666152',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,7% в час',
          'Не более 0,5% в час',
          'Не более 0,3% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63666153',
        text:
          'Как часто должна производиться уборка пыли с полов и оборудования в помещении бункеров, конвейерных галерей и отделений упаковки в производстве алюминиевых порошков?',
        answers: ['Один раз в смену', 'Один раз в декаду', 'Один раз в неделю'],
        correctAnswers: ['Один раз в смену'],
      },
      {
        code: '63666154',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в три года',
          'Не реже одного раза в четыре года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63666155',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?',
        answers: ['3 г/нм³', '4 г/нм³', '5 г/нм³', '6 г/нм³'],
        correctAnswers: ['4 г/нм³'],
      },
      {
        code: '63666156',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Выдающий наряд-допуск',
          'Допускающий к работе',
          'Производитель (руководитель) работ',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63666157',
        text:
          'Какими документами должны устанавливаться и регламентироваться давление и температура колошникового газа в подсводовом пространстве закрытой рудовосстановительной печи? Выберите 2 варианта ответа.',
        answers: [
          'Проектом',
          'Технологической инструкцией',
          'Инструкцией по эксплуатации электропечи',
          'Правилами эксплуатации',
        ],
        correctAnswers: ['Проектом', 'Технологической инструкцией'],
      },
      {
        code: '63666158',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу А', 'Классу B', 'Классу C', 'Классу D'],
        correctAnswers: ['Классу А'],
      },
      {
        code: '63666159',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.',
        answers: [
          'Сливание расплава металла из ковшей и миксеров должно быть механизировано',
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63666160',
        text:
          'При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?',
        answers: ['0,015', '0,018', '0,017', '0,02'],
        correctAnswers: ['0,02'],
      },
      {
        code: '63666161',
        text:
          'Какое допускается максимальное содержание водорода в ферросплавном газе в зависимости от условий производства?',
        answers: ['0,12', '0,17', '0,13', '0,15'],
        correctAnswers: ['0,12'],
      },
      {
        code: '63666162',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?',
        answers: [
          'Сероводорода - 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
          'Сероводорода - 0,07 г/нм³, нафталина - 0,2 г/нм³ (летом) и 0,2 г/нм³ (зимой)',
          'Сероводорода - 0,03 г/нм³, нафталина - 0,09 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
          'Сероводорода - 0,04 г/нм³, нафталина - 0,04 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
        ],
        correctAnswers: [
          'Сероводорода - 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
        ],
      },
      {
        code: '63666163',
        text: 'Каков порядок приготовления растворов серной кислоты?',
        answers: [
          'При приготовлении смеси кислот серную кислоту и воду следует заливать одновременно',
          'При приготовлении растворов серной кислоты сначала необходимо заливать кислоту, а затем воду',
          'При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту',
        ],
        correctAnswers: [
          'При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту',
        ],
      },
      {
        code: '63666164',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63666165',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'На 1 и 2 ступени сжатия',
          'На всех ступенях',
          'На всех, кроме 1 ступени сжатия',
          'На последней ступени сжатия',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63666166',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы',
          'Деревянные шпалы, пропитанные креозотом',
          'Огнестойкие шпалы',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63666167',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть только просушены',
          'Должны быть выдержаны в помещении не менее суток',
          'Должны быть предварительно просушены или прокалены',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63666168',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Из легированной стали',
          'Из высокопрочного чугуна',
          'Из стали для взрывоопасных сред',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63666169',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99%',
          'Не менее 99,3%',
          'Не менее 99,5%',
          'Не менее 99,7%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63666170',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
          'Только работниками эксплуатирующей организации',
          'Только работниками подрядных организаций',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63666171',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Задвижки должны быть установлены в нужное положение',
          'Водяные затворы должны быть залиты водой',
          'Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет производиться продувка',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63666172',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'В аварийных случаях',
          'В случае проведения продувки газопровода',
          'В случае проведения дополнительного пневматического испытания на герметичность',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63666173',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63666174',
        text:
          'Какие действия необходимо предпринять в случае вспенивания расплава?',
        answers: [
          'Уменьшить скорость заливки без остановки процесса заливки восстановителя и загрузки твердой шихты',
          'Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки',
          'Снять пену специальным приспособлением, последующую заливку производить с уменьшенной скоростью',
        ],
        correctAnswers: [
          'Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки',
        ],
      },
      {
        code: '63666175',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
          'Не реже одного раза в месяц и не реже одного раза в три месяца соответственно',
          'Не реже двух раз в месяц и не реже одного раза в месяц соответственно',
          'Не реже четырех раз в месяц и не реже двух раз в месяц соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
      {
        code: '63666176',
        text:
          'Какие из перечисленных требований указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Перед разливкой металла машинист разливочной машины должен убедиться в надежности крепления крана в кантовальном устройстве',
          'Упоры ковша для захвата не должны иметь дефектов, в том числе надрезов, трещин',
          'Чистка форсунок известкового раствора должна производиться при включенном насосе',
          'Не допускается заливать металл в неисправные изложницы. За исправностью изложниц должен быть установлен контроль',
          'Все перечисленные требования указаны неверно',
        ],
        correctAnswers: [
          'Чистка форсунок известкового раствора должна производиться при включенном насосе',
        ],
      },
      {
        code: '63666177',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Только в аварийных случаях',
          'Ни в каком случае',
          'Только при увеличении давления газа до 10 %',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63666178',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 10 минут',
          'В течение 8 минут',
          'В течение 15 минут',
          'В течение 5 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63666179',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Должна быть зажжена горючая смесь газов',
          'Должен быть открыт вентиль горючего газа',
          'Должен быть немного приоткрыт вентиль кислорода',
          'Должен быть кратковременно продут рукав для удаления воздуха',
        ],
        correctAnswers: ['Должен быть немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63666180',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Газовой горелкой',
          'Электрическими приборами',
          'Горячим воздухом, паром или горячей водой',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63666181',
        text:
          'Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Сухим подогретым воздухом',
          'Горячим паром',
          'Открытым пламенем',
        ],
        correctAnswers: ['Сухим подогретым воздухом'],
      },
      {
        code: '63666182',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63666183',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 8 часов',
          'Не менее 6 часов',
          'Не менее 4 часов',
          'Не менее 2 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63666184',
        text:
          'Согласно какому документу должны осуществляться работы по очистке внутренних полостей воздушного сепаратора от зацементировавшегося шлака?',
        answers: [
          'Наряда-допуска',
          'Плана-графика',
          'Карта технологического обслуживания',
          'Инструкция по очистке внутренних полостей воздушного сепаратора от зацементировавшегося шлака',
        ],
        correctAnswers: ['Наряда-допуска'],
      },
      {
        code: '63666185',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63666186',
        text:
          'Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?',
        answers: [
          'Использование продуктов разделения воздуха по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям',
          'На кислородопроводах не допускается установка арматуры из сплавов на основе титана',
          'Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории',
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
        correctAnswers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
      },
      {
        code: '63666187',
        text:
          'Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Перевести на ручной режим систему автоматического управления регенераторов',
          'С помощью дистанционного управления закрыть или открыть соответствующие клапаны',
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          'Необходимо осуществить все перечисленные действия',
        ],
        correctAnswers: ['Необходимо осуществить все перечисленные действия'],
      },
      {
        code: '63666188',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Пульты управления агрегатов должны быть расположены в безопасном месте, при этом обеспечивать видимость агрегата и проводимых работ на площадке',
          'При расположении пультов управления в зоне облучения электродугой должны применяться защитные экраны',
          'Окна закрытых пультов управления должны быть застеклены специальными стеклами с теплоотражающими покрытиями и оборудованы съемными металлическими сетками или специальными защитными экранами',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63666189',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63666190',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5 %',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10 %',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63666191',
        text:
          'Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?',
        answers: [
          'Аэродинамической завесой',
          'Воздушной завесой',
          'Азотной завесой',
          'Любым из перечисленных',
        ],
        correctAnswers: ['Любым из перечисленных'],
      },
      {
        code: '63666192',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Начальник смены',
          'Специалист по промышленной безопасности',
          'Главный инженер предприятия',
          'Генеральный директор',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63666193',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждые 3 года',
          'Через каждые 5 лет',
          'Через каждые 2 года',
          'Ежегодно',
        ],
        correctAnswers: ['Через каждые 3 года'],
      },
      {
        code: '63666194',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления? Выберите правильный вариант ответа.',
        answers: [
          'Обратный клапан',
          'Два запорных вентиля',
          'Свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63666195',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 2 года',
          'Не позднее чем через 4 года',
          'Не позднее чем через 5 лет',
          'Не позднее чем через 10 лет',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63666196',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Не более двух человек',
          'Одним человеком под наблюдением газоспасателя',
          'Не менее трех человек',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63666197',
        text:
          'Какими средствами пожаротушения должны быть оборудованы помещения, где хранится карбид кальция?',
        answers: [
          'Порошковые огнетушители, сухой песок, войлок',
          'Углекислотные огнетушители',
          'Пенные огнетушители, кошма',
        ],
        correctAnswers: ['Порошковые огнетушители, сухой песок, войлок'],
      },
      {
        code: '63666198',
        text:
          'Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?',
        answers: [
          'Номер сертификата на заглушку',
          'Номер заглушки',
          'Марка материала',
          'Условное давление  Pу и условный диаметр Ду',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63666199',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Проверке состояния',
          'Механическим испытаниям',
          'Освидетельствованию',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63666200',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Паспортом (формуляром) на компрессорную установку; инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Схемой трубопроводов (сжатого воздуха или газа, воды, масл с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов; схемы вывешиваются на видном месте',
          'Паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа; журналом учета работы компрессора',
          'Графиком ремонтов компрессорной установки',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63666201',
        text:
          'Какое допускается максимальное содержание водорода в ферросплавном газе в зависимости от условий производства ферросплавов?',
        answers: ['0,12', '0,13', '0,14', '0,15'],
        correctAnswers: ['0,12'],
      },
      {
        code: '63666202',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У специалиста по промышленной безопасности',
          'У начальника смены',
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
          'У главного инженера',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63666203',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 30 календарных дней',
          'На 10 календарных дней',
          'На 15 календарных дней',
          'На 45 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63666204',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Строительно-монтажной организацией',
          'Специализированной организацией',
          'Участком технического обслуживания и ремонта предприятия',
          'Газовой службой',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63666205',
        text:
          'Стальными листами (щитами) какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 16 мм',
          'Не менее 15 мм',
          'Не менее 12 мм',
          'Не менее 10 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63666206',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы постами со стрелочниками',
          'Должны быть оборудованы светофорами',
          'Должны быть оборудованы автоматической сигнализацией',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63666207',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под разрежением',
          'После продувки газопроводов сжатым воздухом',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63666208',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,01 МПа',
          'Не менее 0,02 МПа',
          'Не менее 0,03 МПа',
          'Не менее 0,05 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63666209',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'Не допускается ни в каком случае',
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
          'При условии наличия сигнализации',
          'При условии выданного разрешения от лиц, ответственных за техническое состояние газового хозяйства',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63666210',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Установка на линиях всасывания запорных или отсекающих устройств',
          'Установка на линиях нагнетания запорных или отсекающих устройств',
          'Дистанционное отключение',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63666211',
        text:
          'До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?',
        answers: ['90 °C', '120 °C', '150 °C', '160 °C'],
        correctAnswers: ['120 °C'],
      },
      {
        code: '63666212',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'Ресиверы размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,5 м.',
          'На ограждении ресиверов должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен"',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63666213',
        text:
          'Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?',
        answers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст',
          'На 300 мм вод. ст. больше расчетного давления газа, но не менее 1800 мм вод. ст',
          'На 200 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст',
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст',
        ],
        correctAnswers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст',
        ],
      },
      {
        code: '63666214',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?',
        answers: ['Ежедневно', 'Ежедекадно', 'Ежемесячно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63666215',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Инструкции по эксплуатации',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Производственная инструкция',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63666216',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Арматуру следует размещать над дверными проемами',
          'Для быстрого доступа арматура должна размещаться в технологических проходах',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63666217',
        text:
          'Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?',
        answers: [
          'Не ниже 3,0 кПа',
          'Не ниже 2,5 кПа',
          'Не ниже 2,0 кПа',
          'Не ниже 1,5 кПа',
        ],
        correctAnswers: ['Не ниже 3,0 кПа'],
      },
      {
        code: '63666218',
        text:
          'Какое требование предъявляется к установкам бутобоев во вновь строящихся и реконструируемых цехах? Выберите правильный вариант ответа.',
        answers: [
          'Установки бутобоев должны быть оборудованы защитными ограждениями, предотвращающими разлетание кусков металла',
          'Установки бутобоев должны быть заключены в звукоизолирующие камеры',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63666219',
        text:
          'Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.',
        answers: ['CO', 'O₂', 'CO₂', 'H₂'],
        correctAnswers: ['CO', 'O₂'],
      },
      {
        code: '63666220',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: [
          '0,1 - 0,5 мм/г',
          'Более 0,5 мм/г',
          'До 0,1 мм/г',
          'Более 0,7 мм/г',
        ],
        correctAnswers: ['0,1 - 0,5 мм/г'],
      },
      {
        code: '63666221',
        text:
          'В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?',
        answers: [
          'Проведения монтажа нового оборудования',
          'Проведения технического обслуживания арматуры',
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
      },
      {
        code: '63666222',
        text:
          'Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?',
        answers: [
          'Перекрывающие листы листовых задвижек и заглушек диаметром до 2 м должны изготовляться из целого листа',
          'Задвижки и краны, устанавливаемые на газопроводах и устройствах, должны иметь указатель открытого и закрытого положения',
          'Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается',
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
        correctAnswers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
      },
      {
        code: '63666223',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Разборка подвижных элементов запорной арматуры',
          'Устранение утечек газа из разъемных соединений технических устройств',
          'Проверка работоспособности средств измерений установкой стрелки на нулевое деление шкалы и (при необходимости) их замена',
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Проверка работоспособности запорной арматуры',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
      {
        code: '63666224',
        text:
          'Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Ремонт трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          'Ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'Одновременное проведение ремонтных работ не допускается',
        ],
        correctAnswers: [
          'Одновременное проведение ремонтных работ не допускается',
        ],
      },
      {
        code: '63666225',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 12 часов',
          'Не менее 10 часов',
          'Не менее 8 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63666226',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться на свободных площадках пролета цеха',
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
          'Должны расставляться на горизонтальных площадках в любом месте пролета цеха',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63666227',
        text:
          'Кем определяется номенклатура ферросплавов, для которых допустима грануляция?',
        answers: [
          'Заводом-производителем',
          'Эксплуатирующей организацией',
          'Ростехнадзором',
          'Проектной организацией',
        ],
        correctAnswers: ['Заводом-производителем'],
      },
      {
        code: '63666228',
        text: 'Какие требования на производстве ферросплавов указаны неверно?',
        answers: [
          'Во время работы мельницы в размольном помещении должны быть открыты все двери, включена вентиляция и включены световые предупредительные табло',
          'Перевозка порошков должна производиться в закрытых саморазгружающихся контейнерах. Конструкция контейнеров и площадок для установки их при складировании и пересыпке должна исключать возможность искрообразования',
          'Помещения и оборудование, в которых хранятся или применяются активные ферросплавы, взаимодействующие с водой, должны убираться сухим способом',
          'В помещении помола курить и применять открытый огонь не допускается',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Во время работы мельницы в размольном помещении должны быть открыты все двери, включена вентиляция и включены световые предупредительные табло',
        ],
      },
      {
        code: '63666229',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы допускается производить взрывникам в процессе эксплуатации оборудования цеха',
          'Работы должны производиться специально обученным цеховым обслуживающим персоналом',
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63666230',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С техническим руководителем организации (главным инженером)',
          'С территориальным органом Ростехнадзора',
          'С профессиональной аварийно-спасательной службой',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63666231',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I и II группы газоопасности',
          'На I, II и III группы газоопасности',
          'На I, II, III и IV группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63666232',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['3 м', '5 м', '7 м', '10 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63666233',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 0,7 м',
          'Не менее 0,6 м',
          'Не менее 0,75 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63666234',
        text: 'При каком условии ВРУ должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,25 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63666235',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Организация, обслуживающая печь',
          'Организация, проектирующая печь',
          'Организация, эксплуатирующая печь',
          'Организация, проектирующая систему автоматики печи',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63666236',
        text:
          'Какое допускается максимальное содержание пыли в ферросплавном газе, поступающем в заводские сети?',
        answers: ['20 мг/нм³', '25 мг/нм³', '30 мг/нм³', '40 мг/нм³'],
        correctAnswers: ['20 мг/нм³'],
      },
    ],
    63664: [
      {
        code: '63664000',
        text:
          'С какой периодичностью проводится проверка электрического сопротивления трубопроводов подачи воды для охлаждения элементов электролизеров?',
        answers: [
          'Не реже одного раза в 10 дней',
          'Не реже одного раза в 2 недели',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63664001',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после тщательного их просушивания и разогрева',
          'Только после охлаждения и увлажнения',
          'Только после смазки смазочным материалом',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63664002',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'На расстоянии 15 метров от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'В зоне погрузки грейферными или магнитными кранами',
          'На расстоянии 15 метров от зоны работы грейферного крана в случае, если кран работает внутри производственного здания',
          'Во всех перечисленных зонах',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63664003',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: [
          'Только паром',
          'Только сжатым воздухом',
          'Только газом',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63664004',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['1 час', '1,5 часа', '2 часа', '2,5 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63664005',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63664006',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'В любом случае',
          'При нарушении в работе газоиспользующего оборудования',
          'При стабильной работе газоиспользующего оборудования',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63664007',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?',
        answers: [
          'Сероводорода - 0,01 г/нм³, нафталина - 0,03 г/нм³ (летом) и 0,2 г/нм³ (зимой)',
          'Сероводорода - 0,05 г/нм³, нафталина - 0,07 г/нм³ (летом) и 0,3 г/нм³ (зимой)',
          'Сероводорода - 0,03 г/нм³, нафталина - 0,04 г/нм³ (летом) и 0,4 г/нм³ (зимой)',
          'Сероводорода - 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
        ],
        correctAnswers: [
          'Сероводорода - 0,02 г/нм³, нафталина - 0,05 г/нм³ (летом) и 0,1 г/нм³ (зимой)',
        ],
      },
      {
        code: '63664008',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть выдержаны в помещении не менее смены',
          'Должны быть выдержаны в помещении не менее суток',
          'Должны быть предварительно просушены или прокалены',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63664009',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Комиссией предприятия-заказчика, с участием представителя Ростехнадзора',
          'Комиссией предприятия-заказчика',
          'Строительно-монтажной организацией',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63664010',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенного вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['50 м', '75 м', '100 м', '150 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63664011',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,05 МПа',
          'Не менее 0,1 МПа',
          'Не менее 0,25 МПа',
          'Не менее 0,01 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63664012',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение одного месяца',
          'В течение трех месяцев',
          'В течение шести месяцев',
          'В течение года',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63664013',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 6 месяцев',
          'В течение месяца',
          'В течение года',
          'В течение 3 месяцев',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63664014',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь по периметру пешеходные проходы шириной 0,75 м',
          'Должны иметь по периметру пешеходные проходы шириной 1,5 м',
          'Со всех сторон должны иметь ограждение',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63664015',
        text:
          'Чем производится удаление воды из рубашки охлаждения фланцев реторт и крышки перед демонтажем аппаратов вакуумной сепарации?',
        answers: [
          'Сжатым воздухом',
          'Азотом',
          'Аргоном',
          'Любым инертным газом',
        ],
        correctAnswers: ['Сжатым воздухом'],
      },
      {
        code: '63664016',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения, работающие от напряжения 24 В',
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения, работающие от напряжения 12 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63664017',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.',
        answers: [
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Сливание расплава металла из ковшей и миксеров должно быть механизировано',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63664018',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Загрузка просушенных и прокаленных материалов',
          'Загрузка шихты, не прошедшей пиротехнического контроля',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63664019',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Расстояние между ресиверами одного газа должно быть не менее 1,5 м в свету и обеспечивать удобство их обслуживания',
          'Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'На ограждении должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен"',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63664020',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Одним человеком под наблюдением газоспасателя',
          'Не менее трех человек',
          'Не менее трех человек под наблюдением газоспасателя или члена ДГСД',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63664021',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63664022',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
          'Работы должны быть прекращены, для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности',
          'Работы должны быть продолжены. Оформление нового наряда-допуска не требуется',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63664023',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Специализированная организация',
          'Организация, проектирующая систему автоматики печи',
          'Организация, эксплуатирующая систему автоматики печи',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63664024',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с пневматическим приводом',
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с гидравлическим приводом',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63664025',
        text:
          'Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Только перевести на ручной режим систему автоматического управления регенераторов',
          'Только закрыть или открыть соответствующие клапаны с помощью дистанционного управления',
          'Только установить предупреждающий знак безопасности "Не включать, работают люди!"',
          'Необходимо осуществить все перечисленные действия',
        ],
        correctAnswers: ['Необходимо осуществить все перечисленные действия'],
      },
      {
        code: '63664026',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: [
          'Из сплавов титана',
          'Из стали',
          'Из чугуна',
          'Из сплавов меди и латуни',
        ],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63664027',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть установлены на расстоянии не менее 15 м от печей',
          'Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63664028',
        text:
          'Какая допускается максимальная температура стенок бака и четыреххлористого титана при периодическом процессе получения низших хлоридов титана?',
        answers: ['30 °C', '40 °C', '50 °C', '60 °C'],
        correctAnswers: ['40 °C'],
      },
      {
        code: '63664029',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I и II группы газоопасности',
          'На I, II и III группы газоопасности',
          'На I, II, III и IV группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63664030',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Арматура должна размещаться над дверными проемами',
          'Арматура должна размещаться над технологическими проходами',
          'В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 3,0 МПа и температуре от 10 °C до 40 °C при условии, что она не будет подвергаться вибрации и не будет использоваться при резких изменениях температуры',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63664031',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о начале и окончании смены',
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63664032',
        text:
          'Какие из перечисленных сведений не должна содержать сопроводительная документация на исходные взрывоопасные и легковоспламеняющиеся порошковые материалы?',
        answers: [
          'Нижний концентрационный предел взрывания и воспламенения',
          'Температуру воспламенения аэровзвеси и самовоспламенения в слое',
          'Максимальное давление взрыва',
          'Расчетную удельную теплоту и температуру горения',
        ],
        correctAnswers: ['Расчетную удельную теплоту и температуру горения'],
      },
      {
        code: '63664033',
        text:
          'Под каким давлением водоохлаждаемые элементы плавильных печей перед монтажом должны испытываться на герметичность при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'В 1,5 раза больше рабочего давления воды',
          'В 3,0 раза больше рабочего давления воды',
          'В 2,0 раза больше рабочего давления воды',
          'В 2,5 раза больше рабочего давления воды',
        ],
        correctAnswers: ['В 1,5 раза больше рабочего давления воды'],
      },
      {
        code: '63664034',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в год',
          'Не менее двух раза в год',
          'Не менее трех раза в год',
          'Не менее четырех раза в год',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63664035',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено на свободных площадках, обеспечивающих свободный проход',
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено в закрытом помещении',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63664036',
        text:
          'После чего должна выполняться заливка металла в кристаллизатор машины полунепрерывного литья?',
        answers: [
          'Только после пуска водного охлаждения и отсутствия влаги на поддоне кристаллизатора и литейном столе',
          'Только после включения системы вентиляции',
          'Только после проведения осмотра',
          'Только после разрешения ответственного за производство работ',
        ],
        correctAnswers: [
          'Только после пуска водного охлаждения и отсутствия влаги на поддоне кристаллизатора и литейном столе',
        ],
      },
      {
        code: '63664037',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'При условии выданного разрешения от лиц, ответственных за техническое состояние газового хозяйства',
          'При условии наличия сигнализации',
          'Не допускается ни в каком случае',
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63664038',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В двух экземплярах',
          'В одном экземпляре',
          'В трех экземплярах',
          'Не регламентируется',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63664039',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63664040',
        text:
          'Согласно какому документу должна проводиться проверка электрического сопротивления изоляции узлов и деталей электролизной установки?',
        answers: [
          'Согласно проекту',
          'Согласно Правилам по охране труда при эксплуатации электроустановок',
          'Согласно Правилам безопасности процессов получения или применения металлов',
          'Согласно технологической инструкции',
        ],
        correctAnswers: ['Согласно проекту'],
      },
      {
        code: '63664041',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,5 м в свету',
          'Не менее 2 м в свету',
          'Не менее 2,5 м в свету',
          'Не менее 3 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63664042',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Составляется новый акт-допуск на следующий срок',
          'Продлевается текущий акт-допуск',
          'Работы продолжаются без оформления',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63664043',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых пластиковых контейнерах',
          'В чистых металлических ящиках с крышками',
          'В деревянных ящиках',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63664044',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В отдельных щитовых помещениях (встроенных или пристроенных), смежных с помещениями с взрывоопасными зонами',
          'В производственных помещениях',
          'В отдельно стоящих зданиях',
          'Размещение операторных помещений допускается во всех перечисленных местах',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63664045',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от возможного попадания на них расплава',
          'Только от механических воздействий',
          'Только от тепловых воздействий',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63664046',
        text:
          'С какой периодичностью должна производиться влажная уборка рабочих мест, площадок и полов в местах рассева и магнитной сепарации титан пористых порошков?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в 3 смены',
          'Не реже одного раза в 2 смены',
          'Влажная уборка рабочих мест, площадок и полов в местах рассева и магнитной сепарации титан пористых порошков не регламентирована',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63664047',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63664048',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны устанавливаться в непосредственной близости к оборудованию',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
          'Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63664049',
        text:
          'При какой длине трубки разрешается прожигать летку при наличии предохранительного ограждения (стационарного щита) при производстве титанового шлака?',
        answers: [
          'Не менее 2 м',
          'Не менее 5 м',
          'Не менее 4 м',
          'Не менее 3 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63664050',
        text:
          'Какие из перечисленных видов работ не допускается производить при работающей дробилке при производстве титанового шлака? Выберите правильный вариант ответа.',
        answers: [
          'Только регулирование ширины щели дробилки',
          'Только подтягивание пружин, болтов',
          'Только проталкивание и шуровка материала',
          'Все перечисленные виды работ не допускаются',
        ],
        correctAnswers: ['Все перечисленные виды работ не допускаются'],
      },
      {
        code: '63664051',
        text:
          'На каком минимальном расстоянии могут находиться люди от путей и натяжного троса при транспортировании тележек с изложницами или ковшами, наполненными металлом или шлаком, при производстве титанового шлака?',
        answers: ['10 м', '7 м', '3 м', '5 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63664052',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Не разрешено',
          'Только в аварийных случаях',
          'Разрешено при увеличении давления газа до 5%',
          'Разрешено при увеличении давления газа до 10%',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63664053',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 40% нижнего предела взрываемости (1,6% об.)',
          'Выше 35% нижнего предела взрываемости (1,4% об.)',
          'Выше 25% нижнего предела взрываемости (1% об.)',
          'Выше 30% нижнего предела взрываемости (1,2% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63664054',
        text:
          'Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?',
        answers: [
          'Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом',
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
          'Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается',
          'Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки или заглушки',
        ],
        correctAnswers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
      },
      {
        code: '63664055',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?',
        answers: [
          'Из поролона или других органических материалов',
          'Из цветных металлов',
          'Из нержавеющей стали',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63664056',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Стальную для взрывоопасных сред',
          'Из ковкого и высокопрочного чугуна',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63664057',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Только системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство',
          'Только средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63664058',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У начальника цеха',
          'У работника, ответственного за безопасное производство работ на данном объекте газового хозяйства',
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63664059',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Сжатым воздухом', 'Газом', 'Паром'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63664060',
        text:
          'Какие из перечисленных требований на электролитическом производстве магния указаны неверно?',
        answers: [
          'Периодичность проверки оборудования, переносных трансформаторов и их частей, шинопроводов постоянного тока и строительных конструкций устанавливается проектом',
          'Перед заливкой расплава, извлечением металла и удалением электролита в подвал обслуживаемого электролизера должны подаваться световой и звуковой сигналы',
          'Нахождение людей в подвале, в зоне обслуживаемого электролизера, допускается в количестве не более двух человек',
          'Результаты замеров сопротивлений электроизоляции должны отмечаться в специальном журнале',
          'Металлические инструменты, применяемые в корпусах электролиза, должны быть изготовлены из немагнитного металла',
        ],
        correctAnswers: [
          'Нахождение людей в подвале, в зоне обслуживаемого электролизера, допускается в количестве не более двух человек',
        ],
      },
      {
        code: '63664061',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже двух раз в год',
          'Не реже трех раз в год',
          'Не реже четырех раз в год',
          'Не реже пяти раз в год',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63664062',
        text:
          'Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?',
        answers: [
          'Только номер сертификата на заглушку, номер заглушки',
          'Только марка материала',
          'Только условное давление Py и условный диаметр Ду',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63664063',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63664064',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 2,0 м',
          'Не менее 2,5 м',
          'Не менее 3,0 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63664065',
        text:
          'Какие действия могут быть предприняты перед проведением операции по перестановке штырей на электролизерах с верхним токопроводом к самообжигающемуся аноду согласно Правилам безопасности процессов получения или применения металлов? Выберите 2 варианта ответа.',
        answers: [
          'Выставить знаки, запрещающие вход в опасную зону',
          'Поставить дежурного сотрудника',
          'Установить световую проекцию знака стоп',
          'Выставить ограждения со специальной сигнальной лентой',
        ],
        correctAnswers: [
          'Выставить знаки, запрещающие вход в опасную зону',
          'Установить световую проекцию знака стоп',
        ],
      },
      {
        code: '63664066',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в  два года',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63664067',
        text:
          'Какой персонал выявляет и устраняет причины отключения при автоматическом отключении высокочастотной установки в процессе работы при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'Электротехнический персонал',
          'Неэлектротехнический персонал',
          'Электротехнологический персонал',
        ],
        correctAnswers: ['Электротехнический персонал'],
      },
      {
        code: '63664068',
        text:
          'Что из перечисленного не применяется для тушения алюминиевой пудры?',
        answers: [
          'Песок',
          'Вода',
          'Асбестовые (базальтовые) одеяла',
          'Обезвоженный карналлит',
        ],
        correctAnswers: ['Вода'],
      },
      {
        code: '63664069',
        text:
          'В какой таре должно производиться транспортирование бихромата калия к месту приготовления рабочего раствора?',
        answers: [
          'В закрытой таре',
          'В открытой таре',
          'В таре, оборудованной фильтрующей вентиляцией',
          'Не регламентируется',
        ],
        correctAnswers: ['В закрытой таре'],
      },
      {
        code: '63664070',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['75 °C', '85 °C', '95 °C', '105 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63664071',
        text:
          'Чем должна быть продута камера печи при обнаружении утечки водорода из камеры или при перегорании спирали при производстве благородных металлов, сплавов и полуфабрикатов? Выберите 2 варианта ответа.',
        answers: ['Азотом', 'Инертным газом', 'Сжатым воздухом', 'Паром'],
        correctAnswers: ['Азотом', 'Инертным газом'],
      },
      {
        code: '63664072',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'Не допускается ни в каком случае',
          'В аварийных случаях',
          'По распоряжению главного инженера',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63664073',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['85 °C', '95 °C', '90 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63664074',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу А', 'Классу Б', 'Классу В', 'Классу Г'],
        correctAnswers: ['Классу А'],
      },
      {
        code: '63664075',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'Со службой производственного контроля',
          'С ГСС',
          'С главным энергетиком',
          'С главным инженером',
        ],
        correctAnswers: ['Со службой производственного контроля', 'С ГСС'],
      },
      {
        code: '63664076',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На прочность под рабочим давлением',
          'На плотность под рабочим давлением',
          'На целостность визуальным осмотром',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63664077',
        text:
          'Где указывается высота штабелей, в которые укладываются остывшие рулоны готовой продукции, при бесслитковом производстве катанки и ленты?',
        answers: [
          'В производственной инструкции',
          'В техническом регламенте',
          'В проектной документации',
          'В технологической карте',
        ],
        correctAnswers: ['В производственной инструкции'],
      },
      {
        code: '63664078',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['1,5 м', '3 м', '5 м', '10 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63664079',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?',
        answers: ['1%', '1,5%', '2%', '2,5%'],
        correctAnswers: ['1%'],
      },
      {
        code: '63664080',
        text:
          'Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?',
        answers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
          'На 300 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.',
          'На 200 мм вод. ст. больше расчетного давления газа, но не менее 1750 мм вод. ст.',
          'На 150 мм вод. ст. больше расчетного давления газа, но не менее 1400 мм вод. ст.',
        ],
        correctAnswers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
        ],
      },
      {
        code: '63664081',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 4 месяца',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63664082',
        text:
          'Какие из перечисленных требований при разливке металлов указаны неверно?',
        answers: [
          'Тигли для плавки магния и магниевых сплавов, инструменты и приспособления для передвижных тиглей после изготовления должны быть приняты документарно',
          'Выбраковка тиглей, литейных форм и изложниц должна производиться в соответствии с производственной инструкцией',
          'Выбраковка тиглей, литейных форм и изложниц должна производиться в соответствии с технологической инструкцией',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63664083',
        text:
          'Какой предусмотрен размер ячеек предохранительных решеток бункеров при производстве титанового шлака?',
        answers: [
          'Не более 250 x 250 мм',
          'Не более 200 x 200 мм',
          'Не более 150 x 150 мм',
          'Не более 300 x 300 мм',
        ],
        correctAnswers: ['Не более 250 x 250 мм'],
      },
      {
        code: '63664084',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63664085',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
          'Только работы, связанные с аварийными ситуациями',
          'Производятся все перечисленные виды работ',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63664086',
        text: 'Через какое расстояние должны заземляться наружные газопроводы?',
        answers: [
          'Через каждые 150 м',
          'Через каждые 250 м',
          'Через каждые 350 м',
          'Через каждые 450 м',
        ],
        correctAnswers: ['Через каждые 250 м'],
      },
      {
        code: '63664087',
        text:
          'Какое из перечисленных требований к электролитическому производству магния указано неверно?',
        answers: [
          'В организациях, имеющих цехи, производящие и потребляющие анодный хлоргаз, должен быть организован контроль качества хлоргаза',
          'При чистке хлоропроводов и газоходов местного отсоса на работающем электролизере необходимо открывать не более одного очистного люка',
          'Все работы по подключению и отключению электролизеров на сушку и разогрев с помощью сухих переносных трансформаторов должны производиться электротехническим персоналом при снятом напряжении на трансформаторе',
          'Замена электродов на работающих электролизерах должна производиться по Правилам безопасности процессов получения или применения металлов',
        ],
        correctAnswers: [
          'Замена электродов на работающих электролизерах должна производиться по Правилам безопасности процессов получения или применения металлов',
        ],
      },
      {
        code: '63664088',
        text:
          'Какое количество ступеней электроизоляции от строительных конструкций корпуса электролиза должны иметь металлические трубопроводы, защитные трубы, короба и бронированные кабели, располагаемые на высоте менее 3,5 м, при производстве магния?',
        answers: [
          'Одну ступень',
          'Две ступени',
          'Три ступени',
          'Четыре ступени',
        ],
        correctAnswers: ['Две ступени'],
      },
      {
        code: '63664089',
        text:
          'Чем должны быть оборудованы помещения, в которых размещены печи для отжига полуфабрикатов в среде водорода, при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'Только аварийной вытяжной вентиляцией',
          'Только сигнализацией',
          'Только автоматическими газоанализаторами',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63664090',
        text:
          'Какие помещения должны быть соединены крытыми коридорами и галереями для транспорта материалов, расплавов и передвижения людей по установленным маршрутам?',
        answers: [
          'Только блоки вспомогательных отделений',
          'Только бытовые помещения',
          'Только электролизные и литейные корпуса',
          'Все перечисленные помещения',
        ],
        correctAnswers: ['Все перечисленные помещения'],
      },
      {
        code: '63664091',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
          'Должны быть предназначены для выполнения любых работ',
          'Должны быть выполнены из материалов, исключающих искрообразование',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63664092',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С Ростехнадзором',
          'С техническим руководителем организации',
          'С руководителем газоспасательной службы',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63664093',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в 3 года', 'Один раз в 5 лет', 'Один раз в год'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63664094',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В непосредственной близости от агрегатов',
          'В отдельном помещении',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63664095',
        text:
          'Каким должно быть сопротивление изоляции внутренних стен и колонн электролиза при производстве магния?',
        answers: [
          'Не менее 0,5 МОм',
          'Не менее 0,7 МОм',
          'Не менее 0,9 МОм',
          'Не менее 1,0 МОм',
        ],
        correctAnswers: ['Не менее 0,5 МОм'],
      },
      {
        code: '63664096',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Попадание воздушной среды',
          'Образование взрывоопасной среды',
          'Возможность разгерметизации системы',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63664097',
        text:
          'Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.',
        answers: ['CO', 'CO₂', 'O₂', 'H₂'],
        correctAnswers: ['CO', 'O₂'],
      },
      {
        code: '63664098',
        text:
          'До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?',
        answers: [
          'До температуры не ниже 20 °C и продувается воздухом',
          'До температуры не ниже 40 °C и продувается воздухом',
          'До температуры не ниже 20 °C и продувается азотом',
          'До температуры не ниже 40 °C и продувается азотом',
        ],
        correctAnswers: ['До температуры не ниже 20 °C и продувается воздухом'],
      },
      {
        code: '63664099',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.',
        answers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
          'После остановки более чем на час',
          'Перед вскрытием для осмотра',
        ],
        correctAnswers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
        ],
      },
      {
        code: '63664100',
        text:
          'На каком расстоянии должны располагаться электрические распределительные устройства силовых и осветительных сетей с глухозаземленной нейтралью в электролизных корпусах производства магния от не огражденных шинопроводов и частей электролизеров, находящихся под напряжением постоянного тока?',
        answers: [
          'Не ближе 6 м',
          'Не ближе 8 м',
          'Не ближе 10 м',
          'Не ближе 12 м',
        ],
        correctAnswers: ['Не ближе 6 м'],
      },
      {
        code: '63664101',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 18% и не более 23%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63664102',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'На всех ступенях',
          'Только на первой ступени',
          'Только на второй ступени',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63664103',
        text:
          'В каком случае напряжение с электродов должно быть снято при обезвоживании карналлита и производстве флюсов?',
        answers: [
          'Только при производстве работ на миксерах, печах СКН и хлораторах с применением грузоподъемных механизмов',
          'Только при заливке, перемешивании расплава',
          'Только при удалении шлама',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63664104',
        text:
          'С какой периодичностью должна проводиться проверка состояния форм и изложниц для разливки металлов?',
        answers: ['Ежеквартально', 'Еженедельно', 'Ежесменно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63664105',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63664106',
        text:
          'В соответствии с каким документом должно быть организовано хранение металлического лития, используемого для приготовления сплавов с алюминием?',
        answers: [
          'В соответствии с проектом',
          'В соответствии с Правилами безопасности процессов получения или применения металлов',
          'В соответствии с Межотраслевыми правилами по охране труда при использовании химических веществ',
          'В соответствии с технической документацией завода-изготовителя',
        ],
        correctAnswers: [
          'В соответствии с технической документацией завода-изготовителя',
        ],
      },
      {
        code: '63664107',
        text:
          'В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если изменился состав бригады',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
          'Если подан аварийный сигнал',
          'Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения ТУ и порядка выполнения работ',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63664108',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 1,0 м',
          'Не менее 1,2 м',
          'Не менее 1,8 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63664109',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
          'Только работниками эксплуатирующей организации',
          'Только работниками подрядных организаций',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63664110',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,01% в час',
          'Не более 0,1% в час',
          'Не более 0,15% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63664111',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Начальник цеха - ответственный за производственный контроль',
          'Главный инженер',
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
          'Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63664112',
        text:
          'Какие из перечисленных смесей не допускается применять при производстве твердых сплавов и тугоплавких металлов, а также электродов для наплавочных работ? Выберите правильный вариант ответа.',
        answers: [
          'Только смеси, процесс горения которых переходит во взрыв',
          'Только смеси, способные к самостоятельному горению и имеющие удельную теплоту процесса горения более 50 кДж/моль',
          'Только смеси, чувствительность которых к механическому воздействию (удару) составляет 19,6 Дж и менее, а активной составляющей - 9,8 Дж и менее',
          'Все перечисленные смеси',
        ],
        correctAnswers: ['Все перечисленные смеси'],
      },
      {
        code: '63664113',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['1,5 м', '2 м', '2,5 м', '3 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63664114',
        text:
          'Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?',
        answers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
          'При отсутствии работника, пользующегося арматурой, шкаф должен быть закрыт на замок',
          'Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории',
          'Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания',
        ],
        correctAnswers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
      },
      {
        code: '63664115',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 0,5% кислорода',
          'Пока на участке продувки кислорода будет не более 1% кислорода',
          'Пока на участке продувки кислорода будет не более 2% кислорода',
          'Пока на участке продувки кислорода будет не более 2,5% кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
        ],
      },
      {
        code: '63664116',
        text:
          'Какое испытательное напряжение должна выдержать в течение 1 минуты изоляция индуктора относительно корпуса электропечи при номинальном напряжении более 1000 В при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'В 1,3 раза больше номинального',
          'В 1,5 раза больше номинального',
          'В 1,8 раза больше номинального',
          'В 2 раза больше номинального',
        ],
        correctAnswers: ['В 1,3 раза больше номинального'],
      },
      {
        code: '63664117',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные руководителем организации',
          'В сроки, установленные изготовителем',
          'В сроки, установленные работником, ответственным за выполнение работ',
          'В сроки, установленные в технологической инструкции',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63664118',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Открытым пламенем',
          'Горячим воздухом, паром или горячей водой',
          'Электрическими приборами',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63664119',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?',
        answers: ['4 г/нм³', '5 г/нм³', '6 г/нм³', '7 г/нм³'],
        correctAnswers: ['4 г/нм³'],
      },
      {
        code: '63664120',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна быть удобной в эксплуатации',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
          'Конструкция кабины завалочной машины должна обеспечивать устойчивость при выполнении операций',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63664121',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
          'Акт-допуск должен оформляться письменно',
          'На один объект, площадку, территорию оформляется один акт-допуск',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63664122',
        text:
          'Каким составом должны проводиться ремонт и чистка шахтных печей сопротивления?',
        answers: [
          'Не менее чем двумя рабочими, один из которых - наблюдающий',
          'Не менее чем тремя рабочими, один из которых - наблюдающий',
          'Не менее чем четырьмя рабочими, два из которых - наблюдающие',
          'Не менее чем четырьмя рабочими, один из которых - наблюдающий',
        ],
        correctAnswers: [
          'Не менее чем двумя рабочими, один из которых - наблюдающий',
        ],
      },
      {
        code: '63664123',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63664124',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через бытовые, подсобные, административно-хозяйственные, складские помещения',
          'Через вентиляционные камеры, помещения КИП, лестничные клетки, пути эвакуации',
          'Через электромашинные, электрораспределительные, трансформаторные помещения',
          'Через производственные помещения, связанные с потреблением водорода',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63664125',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['4 часа', '1 час', '1,5 часа', '2 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63664126',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
          'Работы должны производиться специально обученным цеховым обслуживающим персоналом',
          'Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63664127',
        text:
          'Что из перечисленного допускается в складских помещениях для хранения готовой продукции литейного производства?',
        answers: [
          'Применение печного отопления',
          'Хранение легковоспламеняющихся материалов',
          'Хранение химически активных веществ',
          'Все перечисленное не допускается',
        ],
        correctAnswers: ['Все перечисленное не допускается'],
      },
      {
        code: '63664128',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.',
        answers: [
          'Продувать кислородный рукав горючим газом',
          'Взаимозаменять кислородный рукав и рукав для горючих газов при работе',
          'Продувать рукав для горючих газов кислородом',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63664129',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Все перечисленные устройства',
          'Два запорных вентиля',
          'Обратный клапан',
          'Свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63664130',
        text:
          'Какое из перечисленных требований к установке сероочистки природного газа указано верно?',
        answers: [
          'Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы. Содержание CH₄ в двух последовательно отобранных пробах должно быть не более 2%',
          'Установка сероочистки должна иметь подвод воздуха',
          'Перед пуском после ремонта аппараты и газопроводы сероочистки должны быть испытаны на плотность и прочность, продуты азотом до уровня содержания O₂ не более 2% в двух последовательно отобранных пробах',
          'При перерыве в работе горелок газонагревателя более 30 минут необходимо установить заглушку на газопроводе топливного газа',
        ],
        correctAnswers: [
          'При перерыве в работе горелок газонагревателя более 30 минут необходимо установить заглушку на газопроводе топливного газа',
        ],
      },
      {
        code: '63664131',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
          'Не менее 14 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63664132',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
        ],
        correctAnswers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
        ],
      },
      {
        code: '63664133',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 99,9%',
          'Не менее 97,9%',
          'Не менее 97,7%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63664134',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63664135',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Выдающий наряд-допуск',
          'Допускающий к работе',
          'Технический руководитель организации',
          'Лицо, ответственное за осуществление производственного контроля',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63664136',
        text:
          'Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          '1 раз в 3 месяца',
          '1 раз в 2 месяца',
          '1 раз в месяц',
          'Не регламентируются',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63664137',
        text:
          'Что из перечисленного должно производиться в соответствии с инструкциями по пуску и остановке агрегатов (технических устройств) на объекте электролитического производства магния? Выберите правильный вариант ответа.',
        answers: [
          'Только порядок пуска и остановки электролизеров',
          'Только проверка герметичности устройств для транспортирования анодного газа',
          'Только удаление возгонов солей из хлоропроводов',
          'Должно производиться все перечисленное',
        ],
        correctAnswers: ['Должно производиться все перечисленное'],
      },
      {
        code: '63664138',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На прочность', 'На плотность', 'На герметичность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63664139',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При незначительном падении давления воды в системе водоохлаждения',
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При незначительном повышении давления воды в системе охлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63664140',
        text:
          'С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?',
        answers: [
          'Один раз в год',
          'Один раз в два года',
          'Один раз в три года',
          'Один раз в четыре года',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63664141',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 10 мм',
          'Не менее 12 мм',
          'Не менее 14 мм',
          'Не менее 16 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63664142',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Только ограждение приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Только предохраняющие устройства, отключающие приводы при перегрузке',
          'Только исключение падения транспортируемых материалов',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63664143',
        text:
          'Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?',
        answers: [
          'Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними',
          'Перелив растворителей из одного сосуда в другой не допускается',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'Проливы растворителей на пол не допускаются; случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов',
        ],
        correctAnswers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
      },
      {
        code: '63664144',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться при помощи ручной регулировки',
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
          'Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63664145',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В вертикальном положении',
          'В горизонтальном положении',
          'В наклонном положении с вентилем, направленным вверх',
          'В наклонном положении с вентилем, направленным вниз',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63664146',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Окна с обычным оконным стеклом толщиной 3 мм и площадью 1 м²',
          'Фонарные переплеты',
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
      },
      {
        code: '63664147',
        text: 'При каком условии ВРУ должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,10 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63664148',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Главный инженер предприятия',
          'Руководитель предприятия',
          'Главный технолог предприятия',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63664149',
        text:
          'Какие из перечисленных требований при рафинировании и разливке металлов указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Транспортирование расплавленного металла в ковшах (тиглях) вручную должно производиться по проходам с безопасной шириной, установленной проектом',
          'Пуск хода платформы машины полунепрерывного литья разрешается только при одинаковом уровне металла в кристаллизаторах',
          'Пуск гидравлической машины полунепрерывного литья допускается только при отсутствии подтекания масла в трубопроводах и отсутствии его в кессоне',
          'Литейные ковши и тигли для ручной разливки металла независимо от их объема должны наполняться металлом до уровня, указанного в технологической инструкции',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63664150',
        text:
          'Под каким давлением рубашки водоохлаждаемых изложниц и кристаллизаторов перед пуском их в эксплуатацию и после ремонта должны подвергаться гидравлическим испытаниям при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'В 1,5 раза выше максимального рабочего давления',
          'В 2,2 раза выше максимального рабочего давления',
          'В 1,2 раза выше максимального рабочего давления',
          'В 1,8 раза выше максимального рабочего давления',
        ],
        correctAnswers: ['В 1,5 раза выше максимального рабочего давления'],
      },
      {
        code: '63664151',
        text:
          'Чем должно производиться тушение загоревшихся легких металлов и их сплавов? Выберите 2 варианта ответа.',
        answers: [
          'Пеной',
          'Флюсом',
          'Водой',
          'Углекислотой',
          'Обезвоженным карналлитом',
        ],
        correctAnswers: ['Флюсом', 'Обезвоженным карналлитом'],
      },
      {
        code: '63664152',
        text:
          'Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?',
        answers: [
          'Любым из перечисленных',
          'Только аэродинамической воздушной завесой',
          'Только аэродинамической азотной завесой',
        ],
        correctAnswers: ['Любым из перечисленных'],
      },
      {
        code: '63664153',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждые 2 года',
          'Через каждые 3 года',
          'Через каждые 4 года',
          'Через каждые 5 лет',
        ],
        correctAnswers: ['Через каждые 5 лет'],
      },
      {
        code: '63664154',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
          'Не реже одного раза в три года и не реже одного раза в пять лет соответственно',
          'Не реже одного раза в год и не реже одного раза в два года соответственно',
          'Не реже одного раза в два года и не реже одного раза в три года соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63664155',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Устранение утечек газа из разъемных соединений технических устройств',
          'Разборка подвижных элементов запорной арматуры',
          'Проверка работоспособности запорной арматуры',
          'Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений (при необходимости)',
        ],
        correctAnswers: [
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Разборка подвижных элементов запорной арматуры',
        ],
      },
      {
        code: '63664156',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Емкости с песком, огнетушители',
          'Брезентовые покрывала',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63664157',
        text:
          'Каким должно быть минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?',
        answers: ['0,5 кПа', '0,7 кПа', '0,9 кПа', '1,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63664158',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением ниже рабочего',
          'Давлением, равным рабочему',
          'Давлением выше рабочего на 25%',
          'Не регламентируется',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63664159',
        text:
          'Чем определяется уровень заполнения ковша при транспортировании магния и хлористого магния?',
        answers: [
          'Инструкцией',
          'Техническим регламентом',
          'Проектной документацией',
          'Правилами безопасности процессов получения или применения металлов',
        ],
        correctAnswers: ['Инструкцией'],
      },
      {
        code: '63664160',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'При зажигании горелок следует подавать максимальное количество воздуха для полного сгорания газа',
          'Место отбора импульса для сигнализатора падения давления определяется актом технической комиссии',
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
          'При увеличении нагрузки на горелку следует сначала увеличить подачу воздуха, затем газа',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63664161',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 8 лет',
          'Один раз в 5 лет',
          'Один раз в 4 года',
          'Один раз в 2 года',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63664162',
        text:
          'Какое испытательное напряжение должна выдержать в течение 1 минуты изоляция индуктора относительно корпуса электропечи при номинальном напряжении до 1000 В при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'В 2 раза больше номинального',
          'В 2,5 раза больше номинального',
          'В 1,3 раза больше номинального',
          'В 1,5 раза больше номинального',
        ],
        correctAnswers: ['В 2 раза больше номинального'],
      },
      {
        code: '63664163',
        text:
          'Какое из перечисленных требований при хранении магния и его сплавов указано неверно?',
        answers: [
          'Магний и его сплавы должны храниться в отдельных, изолированных от основного производства зданиях или помещениях, огражденных сплошными негорючими перегородками',
          'Расстояние от склада магния до помещения с плавильными печами должно быть не менее 20 м',
          'Запас твердого магния вблизи плавильных агрегатов не должен превышать суточной потребности',
          'Вывозить отходы металлического магния и его сплавов необходимо на полигон отходов',
        ],
        correctAnswers: [
          'Вывозить отходы металлического магния и его сплавов необходимо на полигон отходов',
        ],
      },
      {
        code: '63664164',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 55 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 50 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63664165',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'Утеряна форма вследствие деформации',
          'Качаются цапфы в теле ковша',
          'Образовались раковины, трещины в стенках ковшей и в местах крепления цапф',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63664166',
        text:
          'Какое расчетное давление газа соответствует газопроводам среднего давления?',
        answers: [
          'Свыше 0,3 до 1,2 МПа включительно',
          'Свыше 0,1 до 0,3 МПа включительно',
          'Свыше 0,6 до 1,2 МПа включительно',
          'Свыше 0,3 до 0,6 МПа включительно',
        ],
        correctAnswers: ['Свыше 0,1 до 0,3 МПа включительно'],
      },
      {
        code: '63664167',
        text:
          'Какая электроизоляция предусмотрена при проектировании и строительстве корпусов электролиза при производстве магния?',
        answers: [
          'Внутренних стен на высоту не менее 3,0 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий',
          'Внутренних стен на высоту не менее 1,5 м, колонн - на высоту не менее 2,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий',
          'Внутренних стен на высоту не менее 2,0 м, колонн - на высоту не менее 3,0 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий',
          'Внутренних стен на высоту не менее 2,5 м, колонн - на высоту не менее 2,0 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий',
        ],
        correctAnswers: [
          'Внутренних стен на высоту не менее 3,0 м, колонн - на высоту не менее 3,5 м от уровня рабочих площадок, фундаментов и опорных конструкций электролизеров, подземных каналов и междуэтажных перекрытий',
        ],
      },
      {
        code: '63664168',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 8 лет',
          'Один раз в 5 лет',
          'Один раз в 3 года',
          'Один раз в 2 года',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63664169',
        text:
          'Какое должно быть содержание влаги в шихтовых материалах, загружаемых в руднотермическую печь, при производстве титанового шлака?',
        answers: [
          'В пределах, предусмотренных инструкцией',
          'Не регламентируется',
          'В пределах, предусмотренных Правилами безопасности процессов получения или применения металлов',
          'В пределах, предусмотренных Межотраслевыми правилами по охране труда при использовании химических веществ',
        ],
        correctAnswers: ['В пределах, предусмотренных инструкцией'],
      },
      {
        code: '63664170',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Задвижки должны быть установлены в нужное положение',
          'Водяные затворы должны быть залиты водой',
          'Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет производиться продувка',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63664171',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63664172',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы',
          'Деревянные шпалы, пропитанные креозотом',
          'Огнестойкие шпалы',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63664173',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Требования не регламентированы',
          'Должны быть охлаждены и смазаны перед установкой',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63664174',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии положительного заключения экспертного обследования',
          'При наличии разрешения завода-изготовителя',
          'По решению руководителя предприятия',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63664175',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Должны быть изготовлены из стали',
          'Не должны давать искры при работе с ними',
          'Должны быть изготовлены из чугуна',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63664176',
        text:
          'Какому документу должны соответствовать электропечи для плавки шихтовых материалов, благородных металлов и сплавов?',
        answers: [
          'Проекту',
          'Техническому регламенту',
          'Эксплуатационной документации',
          'Технологической инструкции',
        ],
        correctAnswers: ['Проекту'],
      },
      {
        code: '63664177',
        text:
          'В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?',
        answers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
          'Если монтаж осуществляется внутри компрессорного помещения',
          'Если это прописано в технологической инструкции',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
      },
      {
        code: '63664178',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,01% в час',
          'Не более 0,1% в час',
          'Не более 0,15% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63664179',
        text:
          'До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?',
        answers: ['80 °C', '100 °C', '120 °C', '140 °C'],
        correctAnswers: ['120 °C'],
      },
      {
        code: '63664180',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,05 кПа',
          'Не более 0,1 кПа',
          'Не более 0,2 кПа',
          'Не более 0,3 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63664181',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: ['Более 0,5 мм/г', '0,1 - 0,5 мм/г', 'До 0,1 мм/г'],
        correctAnswers: ['0,1 - 0,5 мм/г'],
      },
      {
        code: '63664182',
        text:
          'Какие из перечисленных требований указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Не допускается вывозить отходы металлического магния и его сплавов на свалку. Отходы должны быть утилизированы согласно проектной технологии в местах, специально отведенных для этих целей',
          'Не допускается применение воды, пены и углекислоты для тушения горящего металла',
          'Тушение загоревшихся легких металлов и их сплавов должно производиться сухими порошковыми материалами: флюсом, хлоркалиевым электролитом или обезвоженным карналлитом',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63664183',
        text:
          'С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?',
        answers: [
          'С интервалом не менее чем 10 минут',
          'С интервалом не менее чем 15 минут',
          'С интервалом не менее чем 20 минут',
          'С интервалом не менее чем 25 минут',
        ],
        correctAnswers: ['С интервалом не менее чем 10 минут'],
      },
      {
        code: '63664184',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждые 2 года',
          'Через каждые 3 года',
          'Через каждые 4 года',
          'Через каждые 5 лет',
        ],
        correctAnswers: ['Через каждые 3 года'],
      },
      {
        code: '63664185',
        text:
          'С какой периодичностью должна производиться уборка производственных помещений участка получения низших хлоридов титана?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже двух раз в смену',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63664186',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63664187',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Для защиты от повышения давления газа выше проектного должны устанавливаться предохранительные сбросные клапаны',
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
          'При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта',
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
          'Установка по производству реформерного газа для производства металлизованного горячевосстановленного железа должна иметь подвод азота для его продувки перед пуском в работу',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63664188',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Должен быть открыт вентиль горючего газа',
          'Должна быть зажжена горючая смесь газов',
          'Должен быть немного приоткрыт вентиль кислорода',
        ],
        correctAnswers: ['Должен быть немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63664189',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Только дистанционное отключение насосов',
          'Только установка на линиях всасывания запорных или отсекающих устройств',
          'Только установка на линиях нагнетания запорных или отсекающих устройств',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63664190',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
          'Один производитель (руководитель) работ',
          'Количество производителей (руководителей) работ определяет выдающий наряд-допуск',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63664191',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'Помещения оборудуются механической приточно-вытяжной общеобменной вентиляцией из верхней зоны через короб в объеме не менее двукратного в час',
          'При обосновании допускается устройство естественной вытяжной вентиляцией с кратностью воздухообмена не менее 2 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 6 в час',
        ],
        correctAnswers: [
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
        ],
      },
      {
        code: '63664192',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 5 минут',
          'В течение 10 минут',
          'В течение 15 минут',
          'В течение 20 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63664193',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'После продувки газопроводов сжатым воздухом',
          'На газопроводах, находящихся под разрежением',
          'На газопроводах, находящихся под давлением',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63664194',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
          'Хранение в помещении ГРП обтирочных и других горючих материалов не допускается',
          'При необходимости следует пользоваться переносными аккумуляторными взрывобезопасными светильниками',
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63664195',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 1 год',
          'Не позднее чем через 2 года',
          'Не позднее чем через 3 года',
          'Не позднее чем через 4 года',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63664196',
        text:
          'Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций',
          'Монтаж, наладка и эксплуатация компрессоров должны производиться в соответствии с инструкциями организаций-изготовителей',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63664197',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом',
          'Помещения должны иметь защиту от воздействия теплового излучения',
          'Помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников, с дверями, открывающимися наружу, и не иметь внутренних запоров',
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63664198',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 30 календарных дней',
          'На 14 календарных дней',
          'На 45 календарных дней',
          'На 20 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63664199',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Должны производиться непосредственно во взрывопожароопасных и пожароопасных помещениях',
          'Должны производиться в условиях, исключающих искрообразование',
          'Должны осуществляться только в специально выделенном для этого помещении',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63664200',
        text: 'Как должны сооружаться газопроводы на территории предприятия?',
        answers: ['Наземными', 'Надземными', 'Подземными'],
        correctAnswers: ['Надземными'],
      },
      {
        code: '63664201',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
          'Не реже одного раза в три месяца и не реже одного раза в шесть месяцев соответственно',
          'Не реже одного раза в шесть месяцев и не реже одного раза в год соответственно',
          'Не реже одного раза в месяц и не реже одного раза в три месяца соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
      {
        code: '63664202',
        text:
          'Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?',
        answers: [
          'Не ниже 3,0 кПа',
          'Не ниже 3,5 кПа',
          'Не ниже 5,0 кПа',
          'Не ниже 5,5 кПа',
        ],
        correctAnswers: ['Не ниже 3,0 кПа'],
      },
      {
        code: '63664203',
        text:
          'Какое из перечисленных требований к стационарным газоразборным постам указано неверно?',
        answers: [
          'Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 150 мм',
          'Дверцы шкафов во время работы должны быть закрыты',
          'При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок',
          'Газоразборные посты, шкафы должны иметь опознавательную окраску',
        ],
        correctAnswers: ['Дверцы шкафов во время работы должны быть закрыты'],
      },
      {
        code: '63664204',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: ['Ежедневно', 'Два раза в неделю', 'Один раз в неделю'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63664205',
        text:
          'Каким должно быть сопротивление изоляции элементов напольных рельсовых машин на производстве алюминия?',
        answers: [
          'Не менее величин, указанных в Правилах безопасности процессов получения или применения металлов',
          'Не менее величин, указанных в Правилах по охране труда при эксплуатации электроустановок',
          'Не менее величин, указанных в техническом регламенте',
          'Не менее величин, указанных в проекте, разработанном для данного производства',
        ],
        correctAnswers: [
          'Не менее величин, указанных в проекте, разработанном для данного производства',
        ],
      },
      {
        code: '63664206',
        text:
          'На каком расстоянии должна устанавливаться тара с алюминиевой пудрой от отопительных систем?',
        answers: [
          'Не ближе 1 м',
          'Не ближе 0,8 м',
          'Не ближе 0,6 м',
          'Не ближе 0,4 м',
        ],
        correctAnswers: ['Не ближе 0,8 м'],
      },
      {
        code: '63664207',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Не менее двух лет',
          'Не менее трех лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63664208',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Механическим испытаниям',
          'Освидетельствованию',
          'Проверке состояния',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63664209',
        text:
          'При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?',
        answers: ['1%', '1,5%', '2%', '0,5%'],
        correctAnswers: ['2%'],
      },
      {
        code: '63664210',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: ['Один раз в 2 года', 'Один раз в 4 года', 'Один раз в 5 лет'],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63664211',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 36 часов',
          'Не менее 48 часов',
          'Не менее 72 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63664212',
        text:
          'В каком случае допускается загрузка шихты и проведение технологических работ с применением неизолированного металлического инструмента при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'При отключенной электропечи',
          'При работающей электропечи в присутствии наблюдающего',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['При отключенной электропечи'],
      },
      {
        code: '63664213',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в год',
          'Не менее двух раз в год',
          'Не менее трех раз в год',
          'Не менее четырех раз в год',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63664214',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должен быть устроен медицинский кабинет',
          'Должны быть устроены пожарные краны и ящики с песком',
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Должна быть установлена автоматическая система пожаротушения',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63664215',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы постами со стрелочниками',
          'Должны быть оборудованы светофорами',
          'Должны быть оборудованы автоматической сигнализацией',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63664216',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В специальный журнал по устранению дефектов',
          'В паспорт оборудования',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63664217',
        text:
          'Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Ремонт трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          'Ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'Ничего из перечисленного',
        ],
        correctAnswers: ['Ничего из перечисленного'],
      },
      {
        code: '63664218',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 15% больше расчетного',
          'На 25% больше расчетного',
          'На 5% больше расчетного',
          'На 20% больше расчетного',
        ],
        correctAnswers: ['На 15% больше расчетного'],
      },
      {
        code: '63664219',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
          'В производственных помещениях, не связанных с потреблением водорода',
          'В проходах подсобных и складских помещений',
          'Через лестничные клетки и пути эвакуации',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63664220',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63664221',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Только паспортом (формуляром) на компрессорную установку; инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов',
          'Только журналом учета работы компрессора, графиком ремонтов компрессорной установки',
          'Всей перечисленной документацией',
          'Только паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63664222',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
          'Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку',
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63664223',
        text:
          'При каком содержании газов должны срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 5% нижнего предела взрываемости (0,2% об.), кислорода менее 19% и более 23%, угарного газа более 30 мг/м³, метана не более 9% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 7% нижнего предела взрываемости (0,28% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 27% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 15% нижнего предела взрываемости (0,6% об.), кислорода менее 19% и более 23%, угарного газа более 10 мг/м³, метана не более 50% нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63664224',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Инструкции по эксплуатации',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Инструкции по проведению пусконаладочных работ',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63664225',
        text:
          'Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Сухим подогретым воздухом',
          'За счет тепла, подводимого к отогреваемым аппаратам',
          'Подогретой водой',
          'Открытым пламенем',
        ],
        correctAnswers: ['Сухим подогретым воздухом'],
      },
      {
        code: '63664226',
        text:
          'Какие из перечисленных требований на электролитическом производстве магния указаны неверно?',
        answers: [
          'Керамические хлоропроводы необходимо очищать от возгонов солей согласно требованиям производственной инструкции',
          'При чистке хлоропроводов и газоходов местного отсоса на работающем электролизере необходимо открывать не менее двух очистных люков',
          'Все работы по подключению и отключению электролизеров на сушку и разогрев с помощью сухих переносных трансформаторов должны производиться электротехническим персоналом при снятом напряжении на трансформаторе',
          'Замена электродов на работающих электролизерах должна производиться по технологической инструкции',
        ],
        correctAnswers: [
          'При чистке хлоропроводов и газоходов местного отсоса на работающем электролизере необходимо открывать не менее двух очистных люков',
        ],
      },
      {
        code: '63664227',
        text:
          'Какое из перечисленных требований при производстве четыреххлористого титана указано неверно?',
        answers: [
          'Слив расплава с печи по переработке медно-ванадиевых пульп производится в помещение с полами, обеспечивающими удаление застывшего расплава',
          'Слив расплава из хлораторов производится в сухие короба или в специальную сухую тару. Извлечение расплава из коробов должно производиться после затвердевания расплава',
          'Не допускается вскрытие хлорирующих устройств и аппаратов систем конденсации, находящихся под давлением',
          'Слив расплава из хлоратора с последующим гидроудалением должен производиться в ванну, заполненную водой до уровня перелива, вода в ванну должна подаваться через интервалы, установленные в проектной документации',
        ],
        correctAnswers: [
          'Слив расплава из хлоратора с последующим гидроудалением должен производиться в ванну, заполненную водой до уровня перелива, вода в ванну должна подаваться через интервалы, установленные в проектной документации',
        ],
      },
      {
        code: '63664228',
        text:
          'При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?',
        answers: ['40 мг/м³', '10 мг/м³', '30 мг/м³', '20 мг/м³'],
        correctAnswers: ['20 мг/м³'],
      },
      {
        code: '63664229',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 70 м',
          'В радиусе 50 м',
          'В радиусе 120 м',
          'В радиусе 100 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63664230',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'Только в соответствии с требованиями строительных норм и правил',
          'Только в соответствии с требованиями нормативно-технической документации для технологических стальных трубопроводов',
          'Только в соответствии с требованиями Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63664231',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с начальником ГСС',
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Только со службой производственного контроля',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63664232',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Проверка параметров срабатывания предохранительных запорных и сбросных клапанов',
          'Техническое обслуживание',
          'Текущий ремонт',
          'Капитальный ремонт',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63664233',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
          'В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла',
          'Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63664234',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Класс точности не ниже 2,0',
          'Диаметр корпуса не менее 200 мм',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63664235',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
          'Должны расставляться на любых свободных площадках цеха',
          'Должны расставляться в зоне работы крана в цехе',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63664236',
        text:
          'Как часто необходимо осматривать электрододержатели и электроды, а также системы токоподвода к печам при производстве титанового шлака?',
        answers: ['Ежесменно', 'Еженедельно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63664237',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в 2 месяца',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63664238',
        text:
          'В каком случае должна производиться подача кислоты с концентрацией более 15% в травильные ванны?',
        answers: [
          'После предварительного осушения травильных ванн согласно производственной инструкции',
          'После предварительного наполнения их водой согласно производственной инструкции',
          'После обезжиривания ванн',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'После предварительного наполнения их водой согласно производственной инструкции',
        ],
      },
      {
        code: '63664239',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть очищены от мусора',
          'Должны быть выдержаны в помещении не менее одной смены',
          'Должны быть выдержаны в помещении не менее суток',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63664240',
        text:
          'Какое должно быть содержание водорода, подаваемого в установки, при производстве благородных металлов, сплавов и полуфабрикатов?',
        answers: [
          'Не менее 95%',
          'Не менее 85%',
          'Не менее 75%',
          'Не менее 80%',
        ],
        correctAnswers: ['Не менее 95%'],
      },
      {
        code: '63664241',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['15 м', '3 м', '5 м', '10 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63664242',
        text:
          'Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?',
        answers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
          'Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения',
          'Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
      },
      {
        code: '63664243',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу А', 'Классу Б', 'Классу В', 'Классу Г'],
        correctAnswers: ['Классу В'],
      },
      {
        code: '63664244',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 700 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 600 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 500 °C',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63664245',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63664246',
        text:
          'Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Аппараты и коммуникации на потоке воздуха от поршневого детандера и от дожимающего поршневого компрессора',
          'Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)',
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
        ],
        correctAnswers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
    ],
    63663: [
      {
        code: '63663000',
        text:
          'Что из перечисленного должно быть заземлено в обязательном порядке при производстве анодной массы и обожженных анодов? Выберите правильный вариант ответа.',
        answers: [
          'Термоцистерны',
          'Сливные трубы',
          'Пекоприемники',
          'Трубопроводы для перекачки пека',
          'Должно быть заземлено все перечисленное',
        ],
        correctAnswers: ['Должно быть заземлено все перечисленное'],
      },
      {
        code: '63663001',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после тщательного их просушивания и разогрева',
          'Только после охлаждения и увлажнения ковшей',
          'После всего перечисленного',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63663002',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Должны быть в наличии огнетушители в достаточном количестве',
          'Должны быть организованы автоматические установки пожаротушения',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63663003',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?',
        answers: [
          'Из поролона или других органических материалов',
          'Из алюминия или магния',
          'Из меди или титана',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63663004',
        text:
          'Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Паром',
          'Теплом, подводимым к отогреваемым аппаратам',
          'Открытым пламенем',
          'Сухим подогретым воздухом',
          'Электрическим подогревом',
        ],
        correctAnswers: ['Сухим подогретым воздухом'],
      },
      {
        code: '63663005',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Ежедневно', 'Ежемесячно', 'Еженедельно', 'Ежеквартально'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63663006',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 5% больше расчетного',
          'На 10% больше расчетного',
          'На 15% больше расчетного',
          'На 20% больше расчетного',
        ],
        correctAnswers: ['На 15% больше расчетного'],
      },
      {
        code: '63663007',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Техническим руководством предприятия',
          'Комиссией, состоящей из представителей строительно-монтажной организации и Ростехнадзора',
          'Представителем Ростехнадзора',
          'Строительно-монтажной организацией',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63663008',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть выдержаны в помещении не менее 12 часов',
          'Должны быть предварительно просушены или прокалены',
          'Должны быть выдержаны в помещении не менее 48 часов',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63663009',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'Если проводится периодическая корректировка режимных карт',
          'На усмотрение ответственного за безопасное производство работ',
          'При стабильной работе газоиспользующего оборудования',
          'Всегда',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63663010',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В смежных помещениях с взрывоопасными зонами',
          'В отдельных щитовых помещениях (встроенных или пристроенных)',
          'В производственных помещениях',
          'В отдельно стоящих зданиях',
          'Размещение операторных подразделений допускается во всех перечисленных местах',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63663011',
        text:
          'Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?',
        answers: [
          'При отсутствии работника, пользующегося арматурой, шкаф должен быть закрыт на замок',
          'Дверцы шкафа при выполнении работы должны быть закрыты',
          'Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории',
          'Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания',
        ],
        correctAnswers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
      },
      {
        code: '63663012',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, связанных с конструкциями здания',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63663013',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,0%',
          'Не менее 99,5%',
          'Не менее 99,7%',
          'Не менее 99,9%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63663014',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь вдоль периметра пешеходные проходы шириной 0,9 м',
          'Со всех сторон должны иметь ограждение',
          'Должны иметь вдоль периметра пешеходные проходы шириной 1,2 м',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63663015',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63663016',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?',
        answers: [
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63663017',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.',
        answers: [
          'Сливание расплава металла из ковшей и миксеров должно быть механизировано',
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63663018',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от тепловых воздействий',
          'Только от механических воздействий',
          'Только от возможного попадания на них расплава',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63663019',
        text:
          'Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Перевести на ручной режим систему автоматического управления регенераторов',
          'С помощью дистанционного управления закрыть или открыть соответствующие клапаны',
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          'Необходимо осуществить все перечисленные действия',
        ],
        correctAnswers: ['Необходимо осуществить все перечисленные действия'],
      },
      {
        code: '63663020',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю и не реже одного раза в полгода соответственно',
          'Не реже одного раза в шесть месяцев и не реже одного раза в год соответственно',
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
          'Не реже одного раза в месяц и не реже одного раза в три месяца соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
      {
        code: '63663021',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63663022',
        text: 'Какие требования к компрессорным установкам указаны верно?',
        answers: [
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя',
          'Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63663023',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.',
        answers: [
          'Продувать рукав для горючих газов кислородом',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
          'Взаимозаменять кислородный рукав и рукав для горючих газов при работе',
          'Продувать кислородный рукав горючим газом',
          'Допускаются все перечисленные действия',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63663024',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
          'Механизмами кантования (поворот) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением',
        ],
        correctAnswers: [
          'Механизмами кантования (поворот) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63663025',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны находиться на каждом рабочем месте',
          'Должны быть предназначены для выполнения любых работ',
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63663026',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Ежегодно', 'Один раз в 3 года', 'Один раз в 5 лет'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63663027',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в два года',
          'Не реже трех раз в год',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63663028',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения, работающие от напряжения не более 12 В',
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения люминесцентными лампами низкого давления',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63663029',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.',
        answers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
          'В случае превышения температуры оборудования более чем на 25% от рабочих показателей',
          'После остановки более чем на 1 час',
        ],
        correctAnswers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
        ],
      },
      {
        code: '63663030',
        text:
          'Какие требования безопасности при ведении технологических процессов электролитического производства алюминия и магния указаны неверно?',
        answers: [
          'Механизмы управления фрамугами должны быть работоспособными и покрыты электроизоляционным материалом',
          'Конструкция фрамуг, створок и фонарей электролизных корпусов должна исключать попадание внутрь атмосферных осадков',
          'Течи воды в корпуса должны устраняться',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63663031',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Электрическими приборами',
          'С применением открытого огня',
          'Горячим воздухом, паром или горячей водой',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63663032',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении предельно допустимого значения на 5%',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
          'Запрещается эксплуатация печей при остаточном разрежении выше величины, указанной в технологической инструкции',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63663033',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы могут производиться взрывниками в процессе эксплуатации оборудования цеха',
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
          'Работы должны производиться специально обученным цеховым обслуживающим персоналом',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63663034',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63663035',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,0 м',
          'Не менее 2,5 м',
          'Не менее 2,0 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63663036',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в три года',
          'Не менее одного раза в два года',
          'Не менее одного раза в год',
          'Не менее двух раз в год',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63663037',
        text:
          'Что из перечисленного допускается при производстве анодной массы и обожженных анодов?',
        answers: [
          'Применение резиновых шлангов для транспортирования жидких пеков',
          'Использование сжатого воздуха для передавливания жидкого пека',
          'Применение открытого огня и курение на складах пека и в зонах его слива из термоцистерн',
          'Транспортировка пека по трубопроводам при температуре, равной 70% температуры самовоспламенения',
          'Все перечисленное не допускается',
        ],
        correctAnswers: [
          'Транспортировка пека по трубопроводам при температуре, равной 70% температуры самовоспламенения',
        ],
      },
      {
        code: '63663038',
        text:
          'С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?',
        answers: [
          'С интервалом не менее чем 10 минут',
          'С интервалом не менее чем 15 минут',
          'С интервалом не менее чем 5 минут',
          'С интервалом не менее чем 20 минут',
        ],
        correctAnswers: ['С интервалом не менее чем 10 минут'],
      },
      {
        code: '63663039',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,01 МПа',
          'Не менее 0,25 МПа',
          'Не менее 0,05 МПа',
          'Не менее 0,1 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63663040',
        text:
          'В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технических устройств и порядка выполнения работ',
          'Если изменился состав бригады',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
          'Если выявлено нарушение условий отключения технических устройств',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63663041',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны перед установкой храниться не менее 5 суток в специальном помещении',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63663042',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['1,8 м', '2,0 м', '2,5 м', '3,0 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63663043',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о неисправности технических устройств',
          'Оповещение эксплуатационного персонала о критичном приближении технологического транспорта',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63663044',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Только в аварийных случаях',
          'Не допускается',
          'Разрешено при увеличении давления газа до 10 %',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63663045',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под разрежением',
          'На газопроводах после продувки сжатым воздухом',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63663046',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: ['Один раз в 2 года', 'Один раз в 4 года', 'Один раз в год'],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63663047',
        text:
          'Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?',
        answers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
          'Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается',
          'Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом',
          'Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки и заглушки',
        ],
        correctAnswers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
      },
      {
        code: '63663048',
        text:
          'В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?',
        answers: [
          'Если консервирующая смазка не подлежит удалению',
          'Если невозможно исключить загрязнение воздуха производственных помещений парами моющих растворов, используемых для обезжиривания',
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
          'Подлежит обезжириванию во всех случаях',
        ],
        correctAnswers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
      },
      {
        code: '63663049',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 4 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
          'Не менее 48 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63663050',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение одного месяца',
          'В течение трех месяцев',
          'В течение шести месяцев',
          'В течение года',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63663051',
        text: 'При каком условии ВРУ должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной четырьмя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,60 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63663052',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'По легкосбрасываемым ограждающим конструкциям',
          'По наружным стенам производственных зданий (транзитных водородопроводов)',
          'По стенам зданий на участках со сплошным остеклением',
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63663053',
        text:
          'Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?',
        answers: [
          'Аэродинамической воздушной завесой',
          'Аэродинамической азотной завесой',
          'Любым из перечисленных',
        ],
        correctAnswers: ['Любым из перечисленных'],
      },
      {
        code: '63663054',
        text:
          'Через какое время должны закрываться крышки и люки термоцистерн после слива пека при производстве анодной массы и обожженных анодов?',
        answers: [
          'Не ранее 0,5 часа после полного их опорожнения',
          'Не ранее 1 часа после полного их опорожнения',
          'Не ранее 1,5 часов после полного их опорожнения',
          'Не ранее 2 часов после полного их опорожнения',
        ],
        correctAnswers: ['Не ранее 1 часа после полного их опорожнения'],
      },
      {
        code: '63663055',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Текущий ремонт',
          'Проверка параметров срабатывания предохранительных запорных и сбросных клапанов',
          'Техническое обслуживание',
          'Все перечисленные виды работ',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63663056',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63663057',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?',
        answers: ['4%', '3%', '2%', '1%'],
        correctAnswers: ['1%'],
      },
      {
        code: '63663058',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Только работы, связанные с аварийными ситуациями',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Производятся все перечисленные виды работ',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63663059',
        text:
          'Какие из перечисленных требований безопасности при производстве глинозема указаны неверно?',
        answers: [
          'Каждый хлоратор должен быть оборудован сигнализацией, срабатывающей при падении давления хлора  в подводящих хлоропроводах ниже величины, установленной технологической инструкцией',
          'Барабаны для разливки флюсов должны быть предварительно очищены от мусора, посторонних предметов и просушены',
          'Ковши для транспортирования расплавленного карналлита по открытым коридорам и проездам должны быть оборудованы закрывающимися крышками. Допускается перевозка расплава в коробках в присутствии наблюдающего',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Ковши для транспортирования расплавленного карналлита по открытым коридорам и проездам должны быть оборудованы закрывающимися крышками. Допускается перевозка расплава в коробках в присутствии наблюдающего',
        ],
      },
      {
        code: '63663060',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63663061',
        text:
          'Какие требования безопасности при электролитическом производстве и рафинировании алюминия указаны неверно?',
        answers: [
          'Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не менее 50 кОм',
          'Крышки проемов между электролизерами в перекрытии второго этажа, а также перекрытия проемов реконструируемых электролизеров должны быть электроизолированы от кожухов соседних электролизеров',
          'Проверка сопротивления электроизоляции ошиновки и конструкций электролизера от "земли" должна проводиться после монтажа и капитального ремонта',
          'Сопротивление изоляции устройств и конструктивных элементов должно быть не менее величин, указанных в проекте, разработанном для данного производства',
        ],
        correctAnswers: [
          'Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не менее 50 кОм',
        ],
      },
      {
        code: '63663062',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В технический паспорт оборудования',
          'В эксплуатационный журнал',
          'В специальный журнал по учету дефектов и срокам их устранения',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63663063',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Ящики с песком, огнетушители',
          'Брезентовые покрывала',
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63663064',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В вертикальном положении',
          'В наклонном положении с вентилем, направленным вверх',
          'В наклонном положении с вентилем, направленным вниз',
          'В горизонтальном положении',
          'Не регламентируется',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63663065',
        text:
          'С какой периодичностью должно проверяться состояние электроизоляции ошиновки и конструкций электролизера?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в год',
          'Не реже двух раз в год',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63663066',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['1,5 м', '3 м', '5 м', '10 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63663067',
        text:
          'Какое из перечисленных требований к стационарным газоразборным постам указано неверно?',
        answers: [
          'Расстояние между шкафами газоразборных постов для кислорода и горючего газа должно быть не менее 150 мм',
          'Дверцы шкафов во время работы должны быть закрыты',
          'При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок',
          'Газоразборные посты, шкафы должны иметь опознавательную окраску',
        ],
        correctAnswers: ['Дверцы шкафов во время работы должны быть закрыты'],
      },
      {
        code: '63663068',
        text:
          'Какое количество производителей (руководителей) работ назначается на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Количество производителей (руководителей) работ определяет выдающий наряд-допуск',
          'Один производитель (руководитель) работ',
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63663069',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Ответственный за производственный контроль',
          'Ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
          'Главный инженер',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63663070',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На коррозионную стойкость',
          'На плотность под рабочим давлением',
          'На надежность',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63663071',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (30 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63663072',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные Ростехнадзором',
          'В сроки, установленные технической комиссией организации',
          'В сроки, установленные изготовителем',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63663073',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу A', 'Классу В', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу В'],
      },
      {
        code: '63663074',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,1% в час',
          'Не более 0,2% в час',
          'Не более 0,3% в час',
          'Не более 0,4% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63663075',
        text:
          'Каким должно быть сопротивление изоляции элементов напольных рельсовых машин на производстве алюминия?',
        answers: [
          'Не менее 0,5 МОм',
          'Не менее 1,0 МОм',
          'Не менее 1,5 МОм',
          'Не менее величин, указанных в проекте, разработанном для данного производства',
        ],
        correctAnswers: [
          'Не менее величин, указанных в проекте, разработанном для данного производства',
        ],
      },
      {
        code: '63663076',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63663077',
        text: 'Как должны сооружаться газопроводы на территории предприятия?',
        answers: [
          'Надземными',
          'Наземными',
          'Подземными',
          'Не регламентируется',
        ],
        correctAnswers: ['Надземными'],
      },
      {
        code: '63663078',
        text:
          'С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?',
        answers: [
          'Один раз в год',
          'Один раз в два года',
          'Один раз в три года',
          'Один раз в четыре года',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63663079',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенного вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['10 м', '25 м', '50 м', '100 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63663080',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'На ограждении ресиверов должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен"',
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,5 м',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63663081',
        text:
          'Какое расчетное давление газа соответствует газопроводам среднего давления?',
        answers: [
          'Свыше 0,6 до 1,2 МПа включительно',
          'Свыше 0,1 до 0,3 МПа включительно',
          'Свыше 0,3 до 0,6 МПа включительно',
          'Свыше 0,3 до 1,2 МПа включительно',
        ],
        correctAnswers: ['Свыше 0,1 до 0,3 МПа включительно'],
      },
      {
        code: '63663082',
        text:
          'Чем должны быть оборудованы ванны с кислотой для травления оборудования?',
        answers: [
          'Укрытиями',
          'Системой подачи аргона',
          'Местными отсосами',
          'Всем перечисленным',
        ],
        correctAnswers: ['Местными отсосами'],
      },
      {
        code: '63663083',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С',
          'Проведение ремонтных работ разрешается после продувки и снижения температуры воздуха внутри технических устройств до 45 °C',
          'Проведение ремонтных работ разрешается после снижения температуры воздуха внутри технических устройств до 50 °C и их проветривания',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С',
        ],
      },
      {
        code: '63663084',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть установлены на расстоянии не менее 3 м от печей',
          'Должны быть установлены на расстоянии не менее 4 м от печей и должны быть защищены паровой завесой',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63663085',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,6 м',
          'Не менее 0,8 м',
          'Не менее 1,0 м',
          'Не менее 1,2 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63663086',
        text:
          'Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?',
        answers: [
          'Не ниже 1,0 кПа',
          'Не ниже 2,0 кПа',
          'Не ниже 3,0 кПа',
          'Не ниже 4,0 кПа',
        ],
        correctAnswers: ['Не ниже 3,0 кПа'],
      },
      {
        code: '63663087',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
          'На расстоянии не менее 3 м от агрегатов',
          'В непосредственной близости от агрегатов',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63663088',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?',
        answers: [
          'Содержание сероводорода в коксовом газе не должно превышать 0,01 г/нм3, нафталина - 0,03 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
          'Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
          'Содержание сероводорода в коксовом газе не должно превышать 0,04 г/нм3, нафталина - 0,06 г/нм3 (летом) и 0,2 г/нм3 (зимой)',
        ],
        correctAnswers: [
          'Содержание сероводорода в коксовом газе не должно превышать 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
        ],
      },
      {
        code: '63663089',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Возможность разгерметизации системы транспортирования',
          'Попадание в коммуникации воздуха',
          'Образование взрывоопасной среды',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63663090',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу A', 'Классу В', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу A'],
      },
      {
        code: '63663091',
        text:
          'Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
          'Колонны высокого и низкого давлений',
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Воздушные секции теплообменников',
          'Обезжириванию подлежит все перечисленное',
        ],
        correctAnswers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63663092',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Средствами сигнализации о прекращении поступления топлива',
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи, а при компоновке печных агрегатов с котлами-утилизаторами – системами по переводу на работу агрегатов без дымососов',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63663093',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Только работниками эксплуатирующей организации',
          'Только работниками подрядных организаций',
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63663094',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,0 м в свету и обеспечивать удобство их обслуживания',
          'Не менее 1,2 м в свету и обеспечивать удобство их обслуживания',
          'Не менее 1,5 м в свету и обеспечивать удобство их обслуживания',
          'Не менее 1,8 м в свету и обеспечивать удобство их обслуживания',
        ],
        correctAnswers: [
          'Не менее 1,5 м в свету и обеспечивать удобство их обслуживания',
        ],
      },
      {
        code: '63663095',
        text:
          'Какие действия могут быть предприняты перед проведением операции по перестановке штырей на электролизерах с верхним токопроводом к самообжигающемуся аноду согласно Правилам безопасности процессов получения или применения металлов? Выберите 2 варианта ответа.',
        answers: [
          'Установить световую проекцию знака стоп',
          'Выставить знаки, запрещающие вход в опасную зону',
          'Выставить ограждения со специальной сигнальной лентой',
          'Поставить дежурного сотрудника',
        ],
        correctAnswers: [
          'Установить световую проекцию знака стоп',
          'Выставить знаки, запрещающие вход в опасную зону',
        ],
      },
      {
        code: '63663096',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 2 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 6 в час',
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее двукратного в час',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63663097',
        text:
          'Какими устройствами из перечисленных не должны быть оснащены электролизеры в обязательном порядке?',
        answers: [
          'Устройствами для дожигания оксида углерода',
          'Устройствами для дожигания смолистых веществ',
          'Устройствами для дожигания сероуглерода',
          'Устройствами для дожигания бензпирена',
        ],
        correctAnswers: ['Устройствами для дожигания сероуглерода'],
      },
      {
        code: '63663098',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на наружные поверхности межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждые 2 года',
          'Через каждые 3 года',
          'Через каждые 5 лет',
          'Через каждые 7 лет',
        ],
        correctAnswers: ['Через каждые 5 лет'],
      },
      {
        code: '63663099',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'На один объект, площадку, территорию оформляется один акт-допуск',
          'Акт-допуск должен оформляться письменно',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63663100',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 10 мм',
          'Не менее 12 мм',
          'Не менее 16 мм',
          'Не менее 20 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63663101',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в год',
          'Один раз в 3 года',
          'Один раз в 5 лет',
          'Один раз в 8 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63663102',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: [
          'До 0,1 мм/г',
          '0,1 - 0,5 мм/г',
          'Более 0,5 мм/г',
          'Не регламентируется',
        ],
        correctAnswers: ['0,1 - 0,5 мм/г'],
      },
      {
        code: '63663103',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 40% нижнего предела взрываемости (1,6% об.)',
          'Выше 35% нижнего предела взрываемости (1,4% об.)',
          'Выше 30% нижнего предела взрываемости (1,2% об.)',
          'Выше 25% нижнего предела взрываемости (1% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63663104',
        text:
          'Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости',
          'Ремонтные работы трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Ремонтные работы трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          'Ремонтные работы принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
        ],
        correctAnswers: [
          'Ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости',
        ],
      },
      {
        code: '63663105',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Техническая комиссия эксплуатирующей организации при согласовании с Ростехнадзором',
          'Техническая комиссия эксплуатирующей организации',
          'Технический руководитель эксплуатирующей организации',
          'Организация, проектирующая систему автоматики печи',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63663106',
        text:
          'Каким количеством ступеней изоляции должны быть электроизолированы от земли и строительных конструкций кожухи электролизеров и внутрицеховые шинопроводы?',
        answers: [
          'Не менее чем двумя ступенями изоляции',
          'Не более чем двумя ступенями изоляции',
          'Не более чем одной ступенью изоляции',
          'Количество ступеней определяется технологической инструкцией',
        ],
        correctAnswers: ['Не менее чем двумя ступенями изоляции'],
      },
      {
        code: '63663107',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых металлических ящиках с крышками',
          'В специальных пластиковых контейнерах с крышками',
          'В контейнерах, расположенных в специально выделенном помещении',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63663108',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Руководитель предприятия',
          'Ответственный за производственный контроль',
          'Главный инженер предприятия',
          'Ответственный за безопасное производство работ',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63663109',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна обеспечивать устойчивое выполнение операций',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
          'Должна быть удобной в эксплуатации',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63663110',
        text:
          'Какое из перечисленных требований к установке сероочистки природного газа указано верно?',
        answers: [
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
          'Перед ремонтом аппаратов и газопроводов сероочистки они должны быть отглушены и провентилированы. Содержание CH4 в двух последовательно отобранных пробах должно быть не более 2%',
          'Установка сероочистки должна иметь подвод воздуха',
          'Перед пуском после ремонта аппараты и газопроводы сероочистки должны быть испытаны на плотность и прочность, продуты азотом до уровня содержания O2 не более 2% в двух последовательно отобранных пробах',
        ],
        correctAnswers: [
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
        ],
      },
      {
        code: '63663111',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63663112',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Допускается производить непосредственно в взрывопожароопасных и пожароопасных помещениях',
          'Должны осуществляться только в специально выделенном для этого помещении',
          'Должны производиться в условиях, исключающих искрообразование',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63663113',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'Со службой производственного контроля',
          'С главным энергетиком',
          'С ГСС',
          'С территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Со службой производственного контроля', 'С ГСС'],
      },
      {
        code: '63663114',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено на любых свободных площадках, обеспечивающих свободный проход',
          'Должно быть предусмотрено только в отдельно стоящих специальных помещениях',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63663115',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63663116',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Не должны давать искры при работе с ними',
          'Должны быть изготовлены из стали',
          'Должны быть изготовлены из чугуна',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63663117',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'С главным инженером организации',
          'С Ростехнадзором',
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С руководителем газоспасательной службы',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63663118',
        text:
          'Каким должно быть минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?',
        answers: ['0,1 кПа', '0,3 кПа', '0,5 кПа', '0,7 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63663119',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 2 года',
          'Не позднее чем через 3 года',
          'Не позднее чем через 4 года',
          'Не позднее чем через 5 лет',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63663120',
        text:
          'Через какое время должны наноситься защитные лакокрасочные покрытия на фланцы и болты межцеховых и цеховых газопроводов и газовых аппаратов?',
        answers: [
          'Через каждый год',
          'Через каждые 3 года',
          'Через каждые 5 лет',
          'Через каждые 7 лет',
        ],
        correctAnswers: ['Через каждые 3 года'],
      },
      {
        code: '63663121',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В одном экземпляре',
          'В двух экземплярах',
          'В трех экземплярах',
          'В четырех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63663122',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'В радиусе 20 м от зоны работы электромостовых кранов',
          'На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'В зоне погрузки грейферными или магнитными кранами',
          'Только в радиусе 15 м от зоны работы любого грузоподъемного сооружения',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63663123',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'Только на первой ступени',
          'Только на второй ступени',
          'Только на третьей ступени',
          'На всех ступенях',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63663124',
        text:
          'Чему должно быть равно сопротивление каждой ступени электроизоляции установок электролиза при ведении технологических процессов производства алюминия?',
        answers: [
          'Не менее 50 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции',
          'Не менее 300 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции',
          'Не менее 400 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции',
          'Не менее 500 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции',
        ],
        correctAnswers: [
          'Не менее 500 Ом на каждый вольт максимального напряжения технологического тока, измеренного на выходе из преобразовательной подстанции',
        ],
      },
      {
        code: '63663125',
        text:
          'При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?',
        answers: ['10 мг/м3', '15 мг/м3', '20 мг/м3', '25 мг/м3'],
        correctAnswers: ['20 мг/м3'],
      },
      {
        code: '63663126',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При незначительном падении давления воды в системе водоохлаждения',
          'При незначительном повышении давления воды в системе охлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63663127',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Стальную для взрывоопасных сред',
          'Специальную для водорода',
          'Допускается применять любую арматуру из перечисленных',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63663128',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Составляется новый акт-допуск на следующий срок',
          'Продлевается срок действия текущего акта-допуска',
          'Работы продолжаются без акта-допуска',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63663129',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Предохраняющие устройства, отключающие приводы при перегрузке',
          'Ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Исключение падения транспортируемых материалов',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63663130',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться при помощи ручной регулировки',
          'Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием канифоли или других материалов',
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63663131',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: [
          'Только паром',
          'Только сжатым воздухом',
          'Только газом',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63663132',
        text:
          'До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?',
        answers: [
          'До температуры не ниже 20 °C и продувается воздухом',
          'До температуры не ниже 20 °C и продувается инертным газом',
          'До температуры не ниже 12 °C и продувается  паром',
          'До температуры не выше 30 °C и продувается продувочным газом. При этом водород в продувочном газе должен отсутствовать, а содержание кислорода в продувочном газе не должно превышать 1% (об.)',
        ],
        correctAnswers: ['До температуры не ниже 20 °C и продувается воздухом'],
      },
      {
        code: '63663133',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63663134',
        text:
          'Какие помещения должны быть соединены крытыми коридорами и галереями для транспорта материалов, расплавов и передвижения людей по установленным маршрутам? Выберите правильный вариант ответа.',
        answers: [
          'Только литейные и электролизные корпуса',
          'Только блоки вспомогательных отделений',
          'Только бытовые помещения',
          'Все перечисленные помещения',
        ],
        correctAnswers: ['Все перечисленные помещения'],
      },
      {
        code: '63663135',
        text:
          'На каком расстоянии от анода не допускается пребывание людей при извлечении и подъеме штыря из анодного гнезда в течение первых двух минут с начала подъема?',
        answers: ['Ближе 15 м', 'Ближе 10 м', 'Ближе 8 м', 'Ближе 6 м'],
        correctAnswers: ['Ближе 6 м'],
      },
      {
        code: '63663136',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'Раковин, трещин в стенках и в местах крепления цапф',
          'Деформации, которая привела к потере формы ковшей, а также повреждении их футеровки',
          'Качки цапф в теле ковша',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63663137',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 15 м',
          'В радиусе 25 м',
          'В радиусе 50 м',
          'В радиусе 75 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63663138',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'Не допускается ни в каком случае',
          'В аварийных случаях',
          'По распоряжению главного инженера',
          'Если на время производства работ выставлены посты',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63663139',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'При условии выданного разрешения от лиц, ответственных за техническое состояние газового хозяйства',
          'При условии наличия сигнализации',
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63663140',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 2 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63663141',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: ['Из стали', 'Из сплавов титана', 'Из чугуна'],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63663142',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63663143',
        text:
          'При каких показателях газа разрешено применять жидкостные манометры?',
        answers: [
          'При давлении до 0,05 МПа',
          'При давлении до 0,04 МПа',
          'При давлении до 0,03 МПа',
        ],
        correctAnswers: ['При давлении до 0,03 МПа'],
      },
      {
        code: '63663144',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'При необходимости следует пользоваться переносными аккумуляторными взрывобезопасными светильниками в помещении категории A',
          'Хранение в помещении ГРП обтирочных и других горючих материалов не допускается',
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63663145',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Только со службой производственного контроля',
          'Только с начальником газоспасательной службы',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63663146',
        text:
          'С какой периодичностью необходимо выполнять проверку сопротивления изоляции крюков мостовых кранов и штоков механизма захвата штыревых кранов, а также захвата механизма перестановки обожженных анодов и колонны кабины на комплексных анодных кранах с низкоопущенной кабиной?',
        answers: ['Еженедельно', 'Ежемесячно', 'Ежеквартально', 'Ежесменно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63663147',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Установка на линиях нагнетания запорных или отсекающих устройств',
          'Установка на линиях всасывания запорных или отсекающих устройств',
          'Дистанционное отключение',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63663148',
        text:
          'Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже двух раз в год',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63663149',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Диаметр корпуса должен быть не менее 200 мм',
          'Класс точности манометров должен быть не ниже 2,0',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63663150',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?',
        answers: ['0,02 г/нм3', '0,5 г/нм3', '1 г/нм3', '4 г/нм3'],
        correctAnswers: ['4 г/нм3'],
      },
      {
        code: '63663151',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Не менее двух лет',
          'Не менее трех лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63663152',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63663153',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'Арматура должна размещаться над технологическими проходами',
          'Арматура должна размещаться над дверными проемами',
          'Все перечисленное требования указаны верно',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63663154',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы, пропитанные каменноугольным маслом',
          'Огнестойкие шпалы',
          'Деревянные шпалы, пропитанные элемсентом',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63663155',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка шихты, не прошедшей пиротехнического контроля',
          'Загрузка пакетированного металлолома',
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63663156',
        text:
          'В каких случаях при производстве глинозема с применением грузоподъемных механизмов напряжение с электродов должно быть снято?',
        answers: [
          'Только при удалении шлама',
          'Только при заливке и перемешивании расплава',
          'Только при производстве работ на миксерах, печах и хлораторах',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63663157',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в год',
          'Один раз в 3 года',
          'Один раз в 5 лет',
          'Один раз в 8 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63663158',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через бытовые, подсобные, административно-хозяйственные, складские помещения',
          'Через электромашинные, электрораспределительные, трансформаторные помещения',
          'Через вентиляционные камеры, помещения КИП, лестничные клетки, пути эвакуации',
          'Через производственные помещения, связанные с потреблением водорода',
          'Прокладка водородопроводов через все перечисленные помещения не допускается',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63663159',
        text:
          'Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?',
        answers: [
          'Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены',
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
          'Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
      },
      {
        code: '63663160',
        text:
          'Какие требования к установкам для очистки технологических газов при производстве алюминия указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'В помещениях дымососных станций должны быть установлены автоматические газоанализаторы, сблокированные с автоматическими аварийными системами вытяжной вентиляции, включающимися при образовании в воздухе рабочей зоны концентраций вредных веществ, превышающих ПДК',
          'Все емкостные аппараты очистки газов, содержащие взрывоопасные пыли и газы, должны быть оборудованы предохранительными клапанами',
          'Электролизеры должны быть оснащены устройствами для дожигания оксида углерода, смолистых веществ и бензпирена',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63663161',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'Строительных норм и правил',
          'Нормативно-технической документации для технологических стальных трубопроводов',
          'Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63663162',
        text:
          'При каком содержании газов должны срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м3, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25% нижнего предела взрываемости (1% об.), кислорода менее 35% и более 40%, угарного газа более 29 мг/м3, метана не более 5% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 50% нижнего предела взрываемости (2% об.), кислорода менее 5% и более 20%, угарного газа более 60 мг/м3, метана не более 17% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 20% нижнего предела взрываемости (0,8% об.), кислорода менее 25% и более 34%, угарного газа более 25 мг/м3, метана не более 50% нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м3, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63663163',
        text:
          'Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.',
        answers: ['CO', 'CO2', 'O2', 'H2'],
        correctAnswers: ['CO', 'O2'],
      },
      {
        code: '63663164',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'При увеличении нагрузки на горелку следует сначала увеличить подачу воздуха, затем газа',
          'Место отбора импульса для сигнализатора падения давления определяется актом технической комиссии',
          'При зажигании горелок следует подавать максимальное количество воздуха',
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63663165',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 21% и не более 25%',
          'Не менее 20% и не более 23%',
          'Не менее 19% и не более 23%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63663166',
        text:
          'Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          'Сроки, определённые разработчиком ВРУ',
          'Сроки, определенные графиком, утвержденным техническим руководителем',
          'Сроки, определенные Правилами безопасности процессов получения или применения металлов',
          'Не регламентируются',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63663167',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Паспортом (формуляром) на компрессорную установку, инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа, журналом учета работы компрессора',
          'Схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов',
          'Графиком ремонтов компрессорной установки',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63663168',
        text:
          'До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?',
        answers: ['80 °C', '100 °C', '120 °C', '150 °C'],
        correctAnswers: ['120 °C'],
      },
      {
        code: '63663169',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63663170',
        text: 'Через какое расстояние должны заземляться наружные газопроводы?',
        answers: [
          'Через каждые 50 м',
          'Через каждые 150 м',
          'Через каждые 250 м',
          'Через каждые 500 м',
        ],
        correctAnswers: ['Через каждые 250 м'],
      },
      {
        code: '63663171',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии положительного заключения экспертного обследования',
          'При наличии решения главного инженера предприятия',
          'При наличии разрешения Ростехнадзора',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63663172',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Одним человеком под наблюдением газоспасателя или члена ДГСД',
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63663173',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Лицо, ответственное за осуществление производственного контроля',
          'Руководитель организации',
          'Допускающий к работе',
          'Выдающий наряд-допуск',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63663174',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Внутренние производственные помещения должны иметь не менее двух выходов, расположенных с учетом аварийной эвакуации работников; двери помещения должны открываться наружу и не иметь внутренних запоров',
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Пульты управления электропечами должны быть расположены так, чтобы была исключена возможность ослепляющего действия электрической дуги на операторов',
          'Внутренние производственные помещения должны иметь защиту от воздействия теплового излучения',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63663175',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Обратный клапан',
          'Два запорных вентиля',
          'Свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63663176',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны устанавливаться на расстоянии не менее 3 м от оборудования',
          'Приборы должны устанавливаться на расстоянии не менее 1 м от оборудования',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63663177',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 30 минут',
          'В течение 15 минут',
          'В течение 10 минут',
          'В течение 5 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63663178',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['75 °C', '85 °C', '80 °C', '95 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63663179',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,3 кПа',
          'Не более 0,5 кПа',
          'Не более 3 кПа',
          'Не более 5 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63663180',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У главного инженера предприятия',
          'У начальника цеха',
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63663181',
        text:
          'Какая допускается температура пека при транспортировании его по трубопроводам в процессе производства анодной массы и обожженных анодов?',
        answers: [
          'Не выше 50% температуры самовоспламенения',
          'Не выше 60% температуры самовоспламенения',
          'Не выше 70% температуры самовоспламенения',
          'Не выше 80% температуры самовоспламенения',
        ],
        correctAnswers: ['Не выше 80% температуры самовоспламенения'],
      },
      {
        code: '63663182',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Техническим воздухом', 'Сжатым воздухом', 'Газом'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63663183',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
          'Пока на участке продувки кислорода будет не более 3% кислорода',
          'Пока на участке продувки кислорода будет не более 5% кислорода',
          'Пока на участке продувки кислорода будет не более 7% кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
        ],
      },
      {
        code: '63663184',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: ['Освидетельствованию', 'Проверке состояния', 'Экспертизе'],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63663185',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Водяные затворы должны быть залиты водой',
          'Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет производиться продувка',
          'Задвижки должны быть установлены в нужное положение',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63663186',
        text:
          'Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?',
        answers: [
          'Только номер сертификата на заглушку',
          'Только номер заглушки',
          'Только условное давление Pу и условный диаметр Ду',
          'Только марка материала',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63663187',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['0,5 часа', '1 час', '1,5 часа', '4 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63663188',
        text:
          'Какое допускается максимальное сопротивление заземляющих устройств крановых путей в корпусах электролиза?',
        answers: ['8 Ом', '6 Ом', '4 Ом', '10 Ом'],
        correctAnswers: ['4 Ом'],
      },
      {
        code: '63663189',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'При наличии светозвуковой сигнализации',
          'Если температура кладки в разогретой зоне розжига факела превышает 600 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63663190',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы светофорами',
          'Должны быть оборудованы постами с дежурными стрелочного поста',
          'Должны быть оборудованы автоматической сигнализацией',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63663191',
        text:
          'При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?',
        answers: ['(0,01) 1%', '(0,015) 1,5%', '(0,02) 2%', '(0,025) 2,5%'],
        correctAnswers: ['(0,02) 2%'],
      },
      {
        code: '63663192',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 3 месяца',
          'В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63663193',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Ответственный специалист, разобравший схему, должен принимать меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивать предупредительные плакаты "Не включать! Работают люди", проверять отсутствие энергетического потенциала',
          'Жетон-бирочная система должна применяться при выводе технического устройства на продолжительную остановку',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Жетон-бирки после разборки электрической схемы ТУ должны передаваться от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63663194',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I, II, III и IV группы газоопасности',
          'На I и II группы газоопасности',
          'На I, II и III группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63663195',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение месяца',
          'В течение 3 месяцев',
          'В течение 6 месяцев',
          'В течение года',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63663196',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Для защиты от повышения давления газа выше проектного должны устанавливаться предохранительные сбросные клапаны',
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
          'При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63663197',
        text:
          'Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?',
        answers: [
          'Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'Проливы растворителей на пол не допускаются, случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов',
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
        correctAnswers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
      },
      {
        code: '63663198',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Инструкции по эксплуатации нового оборудования',
          'Инструкции по проведению пусконаладочных работ',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63663199',
        text:
          'Какие из перечисленных устройств (конструкций) должны быть соединены заземляющими проводами с металлоконструкциями, на которых они установлены при ведении технологических процессов производства алюминия? Выберите правильный вариант ответа.',
        answers: [
          'Шкафы пусковой аппаратуры электродвигателей строительных конструкций, установленные на строительные конструкции корпусов',
          'Шкафы пусковой аппаратуры электродвигателей строительных конструкций, установленные на строительные конструкции пола',
          'Корпуса электродвигателей, установленные на электролизерах',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Корпуса электродвигателей, установленные на электролизерах',
        ],
      },
      {
        code: '63663200',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['85 °С', '90 °С', '100 °С'],
        correctAnswers: ['85 °С'],
      },
      {
        code: '63663201',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Не регламентируется',
          'Давлением, превышающим рабочее в 1,5 раза',
          'Давлением, превышающим рабочее в 1,25 раза',
          'Давлением, равным рабочему',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63663202',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['1,5 м', '3 м', '5 м', '10 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63663203',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности, затем работы могут быть продолжены',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
          'Решение о продолжении или прекращении работы определяет выдающий наряд-допуск',
          'Работы должны быть продолжены. Оформление нового наряда-допуска не требуется',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63663204',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в полгода',
          'Не менее одного раза в год',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63663205',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: ['Ежедневно', 'Каждую смену', 'Еженедельно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63663206',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 15 календарных дней',
          'На 20 рабочих дней',
          'На 30 календарных дней',
          'На 45 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63663207',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже одного раза в год и не реже одного раза в два года соответственно',
          'Не реже одного раза в три года и не реже одного раза в пять лет соответственно',
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
          'Не реже трех раз в год и не реже одного раза в год соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63663208',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться на горизонтальных площадках в любом месте пролета цеха',
          'Должны расставляться на свободных площадках пролета цеха',
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63663209',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: [
          'На плотность',
          'На герметичность',
          'На наличие наружных дефектов (трещин, пор)',
        ],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63663210',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
          'Окна с обычным оконным стеклом толщиной 3 мм и площадью 1 м2',
          'Фонарные переплеты',
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Все перечисленные конструкции относятся к легкосбрасываемым',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
        ],
      },
      {
        code: '63663211',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['0,5 часа', '1,5 часа', '2,0 часа', '2,5 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63663212',
        text:
          'Каким образом должен проводиться процесс обезвоживания карналлита во вращающихся печах и печах "кипящего слоя"?',
        answers: [
          'Под давлением в соответствии с технологической инструкцией',
          'Под давлением в соответствии с проектной документацией',
          'Под разрежением в соответствии с технологической инструкцией',
          'Под разрежением в соответствии с проектной документацией',
        ],
        correctAnswers: [
          'Под разрежением в соответствии с технологической инструкцией',
        ],
      },
      {
        code: '63663213',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть выдержаны в помещении не менее одной смены',
          'Должны быть очищены от мусора',
          'Должны быть выдержаны в помещении не менее суток',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63663214',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Проверка работоспособности запорной арматуры',
          'Разборка подвижных элементов запорной арматуры',
          'Устранение утечек газа из разъемных соединений технических устройств',
          'Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
      {
        code: '63663215',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,1% в час',
          'Не более 0,2% в час',
          'Не более 0,3% в час',
          'Не более 0,4% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63663216',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
        ],
        correctAnswers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
        ],
      },
      {
        code: '63663217',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Должен быть немного приоткрыт вентиль кислорода',
          'Должен быть открыт вентиль горючего газа',
          'Должна быть зажжена горючая смесь газов',
        ],
        correctAnswers: ['Должен быть немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63663218',
        text:
          'Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?',
        answers: [
          'На 700 мм вод. ст. больше расчетного давления газа, но не менее 3000 мм вод. ст.',
          'На 1000 мм вод. ст. больше расчетного давления газа, но не менее 2500 мм вод. ст.',
          'На 300 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.',
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
        ],
        correctAnswers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
        ],
      },
    ],
    63665: [
      {
        code: '63665000',
        text:
          'На каком расстоянии от кантуемых ковшей должен размещаться пульт управления на шлаковом отвале?',
        answers: [
          'На расстоянии не менее 5 м',
          'На расстоянии не менее 8 м',
          'На расстоянии не менее 10 м',
        ],
        correctAnswers: ['На расстоянии не менее 10 м'],
      },
      {
        code: '63665001',
        text:
          'В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если характер и объемы работ изменены в такой степени, что требуется изменение порядка выполнения работ и схемы отключения технических устройств (ТУ)',
          'Если изменился состав бригады',
          'Если выявлено нарушение условий отключения ТУ',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63665002',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Проверке состояния',
          'Техническому освидетельствованию',
          'Механическим испытаниям',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63665003',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Лицо, ответственное за осуществление производственного контроля',
          'Специалист по охране труда',
          'Выдающий наряд-допуск',
          'Допускающий к работе',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63665004',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от возможного попадания на них расплава',
          'От всего перечисленного',
          'Только от механических воздействий',
          'Только от тепловых воздействий',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63665005',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Разрешено при повышении давления газа на 10% от рабочего',
          'Не разрешено',
          'Только в аварийных случаях',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63665006',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На герметичность', 'На плотность', 'На прочность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63665007',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В непосредственной близости от агрегатов',
          'На расстоянии не менее 5 м от агрегатов и обеспечивать полную видимость проводимых на площадке работ',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63665008',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в 3 года',
          'Один раз в 8 лет',
          'Один раз в 6 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63665009',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Электрическими приборами',
          'Горячим воздухом, паром или горячей водой',
          'С применением открытого пламени',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63665010',
        text:
          'При каком максимальном давлении в межконусном пространстве загрузочного устройства доменной печи осуществляется открытие малого конуса?',
        answers: [
          '0,020 МПа (0,2 кгс/см²)',
          '0,025 МПа (0,25 кгс/см²)',
          '0,015 МПа (0,15 кгс/см²)',
          '0,030 МПа (0,3 кгс/см²)',
        ],
        correctAnswers: ['0,015 МПа (0,15 кгс/см²)'],
      },
      {
        code: '63665011',
        text:
          'Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?',
        answers: [
          'Не реже одного раза в полугодие',
          'Не реже одного раза в год',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63665012',
        text:
          'Какое из перечисленных требований допускается при обслуживании и эксплуатации электропечей?',
        answers: [
          'Нахождение вблизи токоведущих частей во время работы установки электрошлакового переплава',
          'Накопление металла в печи до уровня шлакового холодильника',
          'Извлечение слитков после полного застывания шлака и металла в кристаллизаторе',
          'Включение печи при наличии течи воды из поддона или кристаллизатора',
        ],
        correctAnswers: [
          'Извлечение слитков после полного застывания шлака и металла в кристаллизаторе',
        ],
      },
      {
        code: '63665013',
        text:
          'Давление какого газа в донных фурмах после заливки жидкого чугуна должно быть больше величины ферростатического давления жидкого металла в конвертере?',
        answers: [
          'Только аргона',
          'Только азота',
          'Только природного газа',
          'Только коксового газа',
          'Всех перечисленных газов',
        ],
        correctAnswers: ['Всех перечисленных газов'],
      },
      {
        code: '63665014',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63665015',
        text:
          'Каков предельно допустимый износ цапф конвертера во всех измерениях в случае применения подшипников скольжения?',
        answers: [
          'Не должен превышать 12% их первоначальных размеров',
          'Не должен превышать 15% их первоначальных размеров',
          'Не должен превышать 10% их первоначальных размеров',
        ],
        correctAnswers: ['Не должен превышать 10% их первоначальных размеров'],
      },
      {
        code: '63665016',
        text:
          'Чем должна быть продута горелка перед включением и подачей газа?',
        answers: ['Инертным газом', 'Кислородом', 'Сжатым воздухом'],
        correctAnswers: ['Кислородом'],
      },
      {
        code: '63665017',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Должны быть подогреты до температуры, регламентированной производственной инструкцией, и должны иметь цилиндрическую форму',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
          'Должны быть пористыми и должны иметь специальный экран от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63665018',
        text:
          'Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?',
        answers: [
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
          'Осмотр должен производиться не реже одного раза в полгода, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - еженедельно',
          'Осмотр должен производиться не реже одного раза в два года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежемесячно',
        ],
        correctAnswers: [
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
        ],
      },
      {
        code: '63665019',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'При наличии раковин, трещин в стенках и в местах крепления цапф',
          'При наличии качки цапф в теле ковша, потери формы вследствие деформации',
          'При наличии повреждений футеровки',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63665020',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после тщательного их просушивания и разогрева',
          'Только после их увлажнения и охлаждения',
          'Только после нанесения на них смазочного материала',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63665021',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Загрузка шихты и материалов, не прошедших пиротехнического контроля',
          'Загрузка шихты и материалов с количеством влаги ниже, чем предусмотрено производственной инструкцией',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63665022',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Должны быть в наличии закрытые ящики с песком и асбестовое покрывало',
          'Должны быть в наличии огнетушители и средства индивидуальной защиты органов дыхания и глаз',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63665023',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 3 года',
          'Не позднее чем через 4 года',
          'Не позднее чем через 2 года',
          'Не позднее чем через год',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63665024',
        text:
          'Какое из перечисленных требований при загрузке шихтовых материалов указано неверно?',
        answers: [
          'Работы по текущему обслуживанию оборудования, связанные с кратковременным прекращением загрузки печи, должны производиться с разрешения мастера печи',
          'Проверка состояния стальных канатов и их замена должны производиться в порядке, установленном технологической или производственной инструкцией',
          'В течение замены скиповых канатов нахождение посторонних работников, не занятых этой работой на наклонном мосту и в скиповой яме, не допускается',
          'При остановках печи, сопровождающихся открыванием конусов и зажиганием газа на колошнике, в случае, когда пламя может угрожать разогревом канатов, скипы должны находиться без движения',
        ],
        correctAnswers: [
          'При остановках печи, сопровождающихся открыванием конусов и зажиганием газа на колошнике, в случае, когда пламя может угрожать разогревом канатов, скипы должны находиться без движения',
        ],
      },
      {
        code: '63665025',
        text:
          'Какой запас прочности должны иметь цапфы ковшей, предназначенных для расплава металла?',
        answers: [
          'Не менее восьмикратного запаса прочности',
          'Не менее шестикратного запаса прочности',
          'Не менее пятикратного запаса прочности',
        ],
        correctAnswers: ['Не менее восьмикратного запаса прочности'],
      },
      {
        code: '63665026',
        text: 'Какие требования установлены к диаметру лазов пылеуловителей?',
        answers: [
          'Диаметр не менее 600 мм',
          'Диаметр не менее 400 мм',
          'Диаметр не менее 200 мм',
        ],
        correctAnswers: ['Диаметр не менее 600 мм'],
      },
      {
        code: '63665027',
        text:
          'При каком условии могут изменяться режимы работ и основные технологические параметры грануляционных установок?',
        answers: [
          'Только по согласованию с территориальным органом Ростехнадзора',
          'Только по согласованию с проектной организацией',
          'Только по согласованию с экспертной организацией',
          'Только по согласованию с техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: ['Только по согласованию с проектной организацией'],
      },
      {
        code: '63665028',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы светофорами',
          'Должны быть оборудованы предупреждающими знаками',
          'Должны быть оборудованы автоматической сигнализацией',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63665029',
        text: 'Какие требования к компрессорным установкам указаны верно?',
        answers: [
          'Перед пуском каждого компрессора машинист обязан осмотреть установку, убедиться в ее исправности, проверить систему смазки и охлаждения и произвести пуск в соответствии с инструкцией',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
          'Все перечисленные требования указаны верно',
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63665030',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Только обратный клапан',
          'Только 2 запорных вентиля',
          'Только свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63665031',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Попадание влаги',
          'Вероятность разгерметизации системы',
          'Образование взрывоопасной среды',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63665032',
        text: 'Какие требования установлены к укладке слитков?',
        answers: [
          'Укладка должна производиться в специальные закрома, исключающие попадание прямых солнечных лучей',
          'Укладка должна производиться на специально выделенные в цехе площадки, исключающие попадание влаги',
          'Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков',
        ],
        correctAnswers: [
          'Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков',
        ],
      },
      {
        code: '63665033',
        text:
          'В соответствии с чем производятся подтяжка канатов скипов и выполнение других работ, связанных с кратковременным прекращением загрузки шихтовых материалов в печь?',
        answers: [
          'В соответствии с производственной документацией',
          'В соответствии с паспортом объекта',
          'В соответствии с нарядом-допуском',
        ],
        correctAnswers: ['В соответствии с производственной документацией'],
      },
      {
        code: '63665034',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Должен быть открыт вентиль горючего газа',
          'Должна быть зажжена горючая смесь газов',
          'Должен быть немного приоткрыт вентиль кислорода',
        ],
        correctAnswers: ['Должен быть немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63665035',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 2,0 м в свету',
          'Не менее 1,7 м в свету',
          'Не менее 1,5 м в свету',
          'Не менее 2,5 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63665036',
        text:
          'При каком условии допускается осуществлять маневры со шлаковозными ковшами на постановочных путях? Выберите два варианта ответа.',
        answers: [
          'После согласования между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой',
          'При наличии светового разрешающего сигнала',
          'При наличии звукового разрешающего сигнала',
          'При дистанционном управлении маневрами шлаковых ковшей',
        ],
        correctAnswers: [
          'После согласования между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой',
          'При наличии светового разрешающего сигнала',
        ],
      },
      {
        code: '63665037',
        text:
          'Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?',
        answers: ['Пятикратный', 'Восьмикратный', 'Десятикратный'],
        correctAnswers: ['Восьмикратный'],
      },
      {
        code: '63665038',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о времени начала и окончания смены',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
          'Оповещение эксплуатационного персонала о приближающемся технологическом транспорте',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63665039',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Один раз в квартал',
          'Один раз в год',
          'Один раз в полугодие',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63665040',
        text:
          'На каком расстоянии от бровки отвала должна располагаться ось пути для шлака на отвале?',
        answers: [
          'Не менее 1,2 м',
          'Не менее 1,0 м',
          'Не менее 0,8 м',
          'Не менее 1,4 м',
        ],
        correctAnswers: ['Не менее 1,4 м'],
      },
      {
        code: '63665041',
        text:
          'В каком случае допускается проезд локомотивов и вагонов в разливочном пролете напротив печи, из которой производится выпуск металла?',
        answers: [
          'В случае наличия графика, утвержденного техническим руководителем',
          'В случае возникновения аварийной ситуации',
          'В случае соблюдения требований технологической инструкции',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63665042',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63665043',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I, II, III и IV группы газоопасности',
          'На I и II группы газоопасности',
          'На I, II и III группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63665044',
        text: 'Как должна производиться разделка сталевыпускного отверстия?',
        answers: [
          'Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами',
          'Должна производиться только в светлое время суток при наличии специального инструмента',
          'Должна производиться только с бортиков желоба под руководством мастера смены',
        ],
        correctAnswers: [
          'Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами',
        ],
      },
      {
        code: '63665045',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек под наблюдением газоспасателя или члена добровольной газоспасательной дружины (ДГСД)',
          'Одним человеком под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек под руководством руководителя ДГСД',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63665046',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?',
        answers: ['0,3 кПа', '0,4 кПа', '0,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63665047',
        text: 'Чем должны быть ограждены грануляционные бассейны?',
        answers: [
          'Перилами высотой не менее 1,1 м',
          'Сплошным ограждением высотой не менее 0,9 м',
          'Стальным сетчатым ограждением высотой не менее 0,8 м',
        ],
        correctAnswers: ['Перилами высотой не менее 1,1 м'],
      },
      {
        code: '63665048',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?',
        answers: [
          'Из поролона или других органических материалов',
          'Из цветных металлов',
          'Из бумажных и картонных материалов',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63665049',
        text:
          'Какие требования установлены к конусным и бесконусным засыпным аппаратам?',
        answers: [
          'Засыпные аппараты должны быть надежными и рассчитаны на двукратное рабочее давление газа под колошником',
          'Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником',
          'Засыпные аппараты должны быть надежными и рассчитаны на полуторакратное рабочее давление газа под колошником',
        ],
        correctAnswers: [
          'Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником',
        ],
      },
      {
        code: '63665050',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['1 час', '6 часов', '3 часа', '8 часов'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63665051',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 5,0 мм',
          'Не менее 10 мм',
          'Не менее 12 мм',
          'Не менее 16 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63665052',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Только работниками эксплуатирующей организации',
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
          'Только работниками подрядных организаций',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63665053',
        text:
          'Какой должна быть продолжительность проветривания воздухонагревателя перед повторным зажиганием газа и перед постановкой его на дутьевой режим?',
        answers: [
          'Не менее пяти минут',
          'Не менее одной минуты',
          'Не менее десяти минут',
          'Не менее трех минут',
        ],
        correctAnswers: ['Не менее одной минуты'],
      },
      {
        code: '63665054',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть тщательно очищены и промыты',
          'Должны быть предварительно просушены или прокалены',
          'Должны быть выдержаны в помещении не менее 1 суток',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63665055',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения, работающие от напряжения 127 В',
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения, работающие от напряжения 24 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63665056',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу B', 'Классу А', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу B'],
      },
      {
        code: '63665057',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,03 МПа',
          'Не менее 0,05 МПа',
          'Не менее 0,02 МПа',
          'Не менее 0,04 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63665058',
        text:
          'В каком случае допускается повторный налив шлака в ковш с образовавшейся разделительной коркой?',
        answers: [
          'Не регламентируется',
          'Если корка остыла до температуры окружающей среды',
          'Если на дно ковша уложен влажный песок',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63665059',
        text:
          'Как часто должны производиться проверки приборов измерения уровня засыпи печи?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже двух раз в месяц',
        ],
        correctAnswers: ['Не реже двух раз в месяц'],
      },
      {
        code: '63665060',
        text:
          'Как часто комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63665061',
        text:
          'Чем должны быть перекрыты приемные бункера грануляционных установок?',
        answers: [
          'Предохранительными решетками с ячейками не более 200 х 200 мм',
          'Предохранительными решетками с ячейками не более 250 х 250 мм',
          'Предохранительными решетками с ячейками не более 350 х 350 мм',
        ],
        correctAnswers: [
          'Предохранительными решетками с ячейками не более 200 х 200 мм',
        ],
      },
      {
        code: '63665062',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
        ],
        correctAnswers: [
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
        ],
      },
      {
        code: '63665063',
        text:
          'С какой периодичностью должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей?',
        answers: ['Ежемесячно', 'Ежеквартально', 'Ежегодно'],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63665064',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63665065',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением ниже рабочего на 10%',
          'Давлением выше рабочего на 15%',
          'Давлением, равным рабочему',
          'Не регламентируется',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63665066',
        text:
          'В каком из перечисленных случаев не допускается производить выпуск чугуна?',
        answers: [
          'По короткой летке',
          'По сырой летке',
          'При слабой летке',
          'При неисправном футляре',
        ],
        correctAnswers: ['По сырой летке'],
      },
      {
        code: '63665067',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенного вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['25 м', '30 м', '40 м', '50 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63665068',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу B', 'Классу А', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу А'],
      },
      {
        code: '63665069',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 99,5%',
          'Не менее 99,2%',
          'Не менее 99,1%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63665070',
        text:
          'При какой температуре поверхности воздухопроводов горячего дутья принимаются меры по устранению причин, приведших к их перегреву?',
        answers: [
          'Свыше 100 ­°С',
          'Свыше 120 ­°С',
          'Свыше 150 ­°С',
          'Свыше 200 ­°С',
        ],
        correctAnswers: ['Свыше 200 ­°С'],
      },
      {
        code: '63665071',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Не менее двух лет',
          'Не менее пяти лет',
          'Не менее трех лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63665072',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.',
        answers: [
          'Размещать стационарные газоразборные посты в деревянных шкафах с плотно закрывающимися дверцами',
          'Продувать рукав для горючих газов кислородом',
          'Взаимозаменять кислородный рукав и рукав для горючих газов при работе',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63665073',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63665074',
        text:
          'Какими средствами необходимо тушить загоревшиеся легковоспламеняющиеся порошковые материалы и смеси?',
        answers: [
          'Водой',
          'Пенными огнетушителями',
          'Рекомендованными изготовителями и специализированными организациями',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Рекомендованными изготовителями и специализированными организациями',
        ],
      },
      {
        code: '63665075',
        text:
          'В каком случае разрешено нахождение работников на колошниковой площадке? Выберите 2 варианта ответа.',
        answers: [
          'С оформлением разрешения лица, ответственного за производство работ',
          'С оформлением наряда-допуска на газоопасные работы',
          'При наличии у работника персонального газоанализатора',
          'При наличии ограждения перилами высотой не менее 0,8 м',
          'При неработающей шахтной печи',
        ],
        correctAnswers: [
          'С оформлением наряда-допуска на газоопасные работы',
          'При наличии у работника персонального газоанализатора',
        ],
      },
      {
        code: '63665076',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Не регламентируется',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
          'Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования',
          'Приборы должны устанавливаться на расстоянии не менее 3 м от оборудования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63665077',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63665078',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'Через производственные помещения, не связанные с потреблением водорода',
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
          'Через бытовые, подсобные, административно-хозяйственные и складские помещения',
          'Через электромашинные, электрораспределительные и трансформаторные помещения',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63665079',
        text: 'В каком случае не допускается прожигание корки чугунной летки?',
        answers: [
          'При длине кислородной трубки менее 3 м',
          'При длине кислородной трубки менее 2 м',
          'При длине кислородной трубки менее 5 м',
          'При длине кислородной трубки менее 4м',
        ],
        correctAnswers: ['При длине кислородной трубки менее 2 м'],
      },
      {
        code: '63665080',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Задвижки должны быть установлены в нужное положение',
          'Водяные затворы должны быть залиты водой',
          'Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет производиться продувка',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63665081',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении ниже величины, указанной в проектной документации',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63665082',
        text:
          'На сколько давление природного газа на входе в установку для подачи природного газа в смеси с кислородом в доменной печи (далее – УГКС) должно быть выше расчетного давления в фурменной зоне доменной печи?',
        answers: [
          'Не менее чем на 0,1 МПа (1 кгс/см²)',
          'Не менее чем на 0,2 МПа (2 кгс/см²)',
          'Не менее чем на 0,05 МПа (0,5 кгс/см²)',
          'Не менее чем на 0,08 МПа (0,8 кгс/см²)',
        ],
        correctAnswers: ['Не менее чем на 0,2 МПа (2 кгс/см²)'],
      },
      {
        code: '63665083',
        text:
          'Какие требования установлены к раздеванию слитков с помощью напольных машин или кранов?',
        answers: [
          'Необходимо осуществлять только после остывания слитков до температуры не выше 550 °C',
          'Необходимо осуществлять только в предусмотренном производственной инструкцией помещении после образования частичного затвердевания',
          'Необходимо осуществлять только после полного затвердевания слитков',
        ],
        correctAnswers: [
          'Необходимо осуществлять только после полного затвердевания слитков',
        ],
      },
      {
        code: '63665084',
        text:
          'В каком случае осуществляется доливка чугуна в мартеновские и двухванные сталеплавильные агрегаты?',
        answers: [
          'В случае наличия разрешения сталевара печи',
          'Ни в каком случае',
          'В исключительных случаях согласно технологической инструкции',
          'В случае наличия письменного разрешения лица, ответственного за безопасное выполнение работ',
        ],
        correctAnswers: [
          'В исключительных случаях согласно технологической инструкции',
        ],
      },
      {
        code: '63665085',
        text:
          'Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?',
        answers: [
          'Скорость не должна превышать 10 км/ч',
          'Скорость не должна превышать 6 км/ч',
          'Скорость не должна превышать 5 км/ч',
        ],
        correctAnswers: ['Скорость не должна превышать 5 км/ч'],
      },
      {
        code: '63665086',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Диаметр корпуса манометра должен быть не менее 200 мм',
          'Давление должно измеряться двумя манометрами, один из которых должен быть поверен и опломбирован',
          'Класс точности манометров должен быть не ниже 2,5',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63665087',
        text:
          'Какие требования к внепечной обработке жидкого металла указаны неверно?',
        answers: [
          'Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией',
          'Агрегаты внепечной обработки жидкого металла должны быть оборудованы системой управления, обеспечивающей функционирование механизмов в безопасных режимах и автоматическое отключение агрегатов при отклонении контролируемых параметров от заданных',
          'Механизмы перемещения сталевоза и передвижной платформы вакуумной камеры должны иметь блокировку, исключающую возможность включения механизма перемещения сталевоза при нахождении торца всасывающего патрубка вакуумной камеры на уровне или ниже верхней кромки сталеразливочного ковша, установленного на сталевозе',
          'Ввод раскислителей и легирующих материалов в ковш с жидким металлом под вакуумом должен осуществляться через вакуумный шлюз, конструкция которого должна обеспечивать сохранение вакуума в системе',
        ],
        correctAnswers: [
          'Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией',
        ],
      },
      {
        code: '63665088',
        text:
          'Что должны обеспечивать конструкция и установка элементов фурменного прибора?',
        answers: [
          'Должны обеспечивать контроль разгара футеровки',
          'Должны обеспечивать герметичность',
          'Должны обеспечивать продув воздуха',
        ],
        correctAnswers: ['Должны обеспечивать герметичность'],
      },
      {
        code: '63665089',
        text:
          'Какие действия необходимо осуществить в тех случаях, когда была допущена разливка стали напротив ремонтируемой мартеновской печи?',
        answers: [
          'Ремонтные работы в шлаковиках должны быть продолжены с оформлением наряда-допуска на работы повышенной опасности',
          'Ремонтные работы в шлаковиках должны быть продолжены с особой осторожностью и c использованием дополнительных средств индивидуальной защиты органов дыхания и зрения',
          'Ремонтные работы в шлаковиках должны быть прекращены, а работники удалены в безопасное место',
        ],
        correctAnswers: [
          'Ремонтные работы в шлаковиках должны быть прекращены, а работники удалены в безопасное место',
        ],
      },
      {
        code: '63665090',
        text:
          'Что должно регулярно производиться в корпусах обезвоживания шлама?',
        answers: [
          'Гидросмыв шлама с рабочих площадок и строительных конструкций',
          'Механическое удаление шлама с рабочих площадок и строительных конструкций',
          'Влажная уборка рабочих площадок и строительных конструкций от пыли',
        ],
        correctAnswers: [
          'Гидросмыв шлама с рабочих площадок и строительных конструкций',
        ],
      },
      {
        code: '63665091',
        text: 'Кем должно быть проверено качество просушки желоба и стыка?',
        answers: [
          'Начальником участка',
          'Сталеваром печи',
          'Техническим руководителем организации',
          'Любым работником цеха, назначенным приказом по организации',
        ],
        correctAnswers: ['Сталеваром печи'],
      },
      {
        code: '63665092',
        text: 'Какие требования установлены к сливу шлака в шлаковую яму?',
        answers: [
          'Должен производиться равномерной струей',
          'Должен производиться со скоростью, предусмотренной производственной инструкцией',
          'Должен производиться кантовкой ковша на 90°',
        ],
        correctAnswers: ['Должен производиться равномерной струей'],
      },
      {
        code: '63665093',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 30 календарных дней',
          'На 10 рабочих дней',
          'На 15 календарных дней',
          'На 45 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63665094',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'При условии выданного разрешения от лиц, ответственных за техническое состояние газового хозяйства',
          'При условии отсутствия манометров на байпасе до и после регулирующей задвижки',
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63665095',
        text:
          'Какое из перечисленных требований к воздухонагревателям в доменном производстве указано неверно?',
        answers: [
          'Площадки со стороны щели должны ограждаться сплошной отбортовкой на высоту не менее 0,5 м',
          'Ширина кольцевой щели между кожухом и площадками обслуживания, а также между вертикальным газопроводом, пересекающим площадку, и площадкой должна быть не менее 50 мм',
          'Ежегодно должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей',
          'Конструкция подземных боровов воздухонагревателей должна исключать возможность попадания в них грунтовых вод',
        ],
        correctAnswers: [
          'Площадки со стороны щели должны ограждаться сплошной отбортовкой на высоту не менее 0,5 м',
        ],
      },
      {
        code: '63665096',
        text:
          'В каком случае допускается работа подъемников мелочи кокса и агломерата при неисправных путевых выключателях, выключателях слабины каната, а также концевых выключателях крайних положений скипа? Выберите правильный вариант ответа.',
        answers: [
          'В случае письменного разрешения лица, ответственного за безопасное производство работ',
          'В случае присутствия наблюдающего',
          'В случае наличия исправной сигнализации',
          'Во всех перечисленных случаях не допускается',
        ],
        correctAnswers: ['Во всех перечисленных случаях не допускается'],
      },
      {
        code: '63665097',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы, пропитанные креозотом',
          'Любые деревянные шпалы',
          'Огнестойкие шпалы',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63665098',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 8 часов',
          'Не менее 4 часов',
          'Не менее 2 часов',
          'Не менее 3 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63665099',
        text: 'Как должна производиться установка мульд на стеллажах?',
        answers: [
          'С наклоном в сторону завалочной машины',
          'По центру стеллажей',
          'Без свесов и перекосов',
        ],
        correctAnswers: ['Без свесов и перекосов'],
      },
      {
        code: '63665100',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в 5 лет', 'Один раз в 3 года', 'Один раз 4 года'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63665101',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У диспетчера',
          'У работника, ответственного за безопасное производство работ',
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
          'У технического руководителя предприятия',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63665102',
        text:
          'Какой сигнал должен быть подан на пост управления миксером о правильности установки ковша под сливным носком перед началом слива чугуна из миксера?',
        answers: [
          'Только звуковой',
          'Только световой',
          'Только по телефону',
          'Только радио-',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63665103',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в два года',
          'Не менее одного раза в год',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63665104',
        text:
          'Какие независимые технологические линии должны иметь в своем составе установки придоменной грануляции шлака? Выберите два варианта ответа.',
        answers: ['Рабочую', 'Сточную', 'Резервную', 'Аварийную'],
        correctAnswers: ['Рабочую', 'Резервную'],
      },
      {
        code: '63665105',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 0,7 м',
          'Не менее 0,8 м',
          'Не менее 0,6 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63665106',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'Только с требованиями строительных норм и правил',
          'Только с требованиями нормативно-технической документации для технологических стальных трубопроводов',
          'Только с требованиями Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63665107',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Производятся все перечисленные виды работ',
          'Только работы, связанные с аварийными ситуациями',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63665108',
        text:
          'С помощью каких устройств должно производиться удаление пыли из изложниц?',
        answers: [
          'С помощью продувочных устройств',
          'С помощью пылеотсасывающих устройств',
          'С помощью мокрого пылеулавливания',
        ],
        correctAnswers: ['С помощью пылеотсасывающих устройств'],
      },
      {
        code: '63665109',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '5 м', '8 м', '3 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63665110',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в месяц',
          'Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63665111',
        text:
          'Как часто должно проверяться состояние системы охлаждения печей?',
        answers: ['Каждые 4 часа', 'Каждые 36 часов', 'Ежесменно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63665112',
        text: 'Чем должен быть покрыт пол рабочей площадки возле электропечи?',
        answers: [
          'Термостойким настилом',
          'Профилированным настилом',
          'Электроизолирующим настилом',
        ],
        correctAnswers: ['Электроизолирующим настилом'],
      },
      {
        code: '63665113',
        text:
          'Что должно устраиваться над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора в целях исключения попадания атмосферных осадков в ковши?',
        answers: [
          'Только металлические крыши',
          'Крыши или специальные навесы',
          'Только специальные навесы из полиэтиленовых материалов',
        ],
        correctAnswers: ['Крыши или специальные навесы'],
      },
      {
        code: '63665114',
        text:
          'В каком случае проводится внеочередное обследование кожухов доменных печей и воздухонагревателей?',
        answers: [
          'Только в случае выявления при эксплуатационных осмотрах необъяснимого роста повреждений и опасности общего разрушения кожуха',
          'Только в случае аварии в цехе, связанной с огневым (пожар) или механическим (обрушение конструкций) воздействием на кожух печи',
          'Только в случае намечаемой реконструкции печи',
          'Только в случае увеличения нормируемых проектных показателей и (или) климатических воздействий (рост районной сейсмики, рост рабочего давления, переход на цинкосодержащие руды)',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63665115',
        text:
          'После выполнения каких мероприятий разрешается производить работы на своде мартеновской печи?',
        answers: [
          'Только после выпуска плавки и до заливки чугуна в печь',
          'Только после полной остановки печи',
          'Только после остановки печи на капитальный ремонт',
        ],
        correctAnswers: [
          'Только после выпуска плавки и до заливки чугуна в печь',
        ],
      },
      {
        code: '63665116',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено отдельно стоящее специальное помещение',
          'Должно быть предусмотрено на закрытых площадках, обеспечивающих свободный проход персонала',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63665117',
        text:
          'Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?',
        answers: [
          'Только использованием блокировочных устройств, средств световой и звуковой сигнализации, аварийного отключения производственного оборудования при нарушениях производственных процессов',
          'Только автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов',
          'Только заменой производственных процессов и операций, связанных с наличием вредных и (или) опасных производственных факторов, процессами и операциями, при которых указанные факторы отсутствуют или имеют меньшую интенсивность',
          'Всем перечисленным',
          'Только применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63665118',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В отдельных щитовых встроенных помещениях, смежных с помещениями с взрывоопасными зонами',
          'В производственных помещениях',
          'В отдельно стоящих зданиях',
          'В отдельных щитовых пристроенных помещениях, смежных с помещениями с взрывоопасными зонами',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63665119',
        text:
          'Какое из перечисленных требований допускается при транспортировании и погрузке шлака?',
        answers: [
          'Погрузка шлака в сырые думпкары',
          'Нахождение на подвижном составе во время кантовки шлака',
          'Транспортирование и погрузка шлаковых глыб на железнодорожную платформу без тары',
          'Не допускается ни одно из перечисленных требований',
        ],
        correctAnswers: ['Не допускается ни одно из перечисленных требований'],
      },
      {
        code: '63665120',
        text: 'Каков допустимый износ цапф ковшей во всех измерениях?',
        answers: [
          'Не должен превышать 10% первоначальных размеров',
          'Не должен превышать 12% первоначальных размеров',
          'Не должен превышать 15% первоначальных размеров',
        ],
        correctAnswers: ['Не должен превышать 10% первоначальных размеров'],
      },
      {
        code: '63665121',
        text:
          'Какие требования установлены к сцепке и расцепке сталевозной тележки?',
        answers: [
          'Должны быть автоматическими',
          'Должны быть дистанционными',
          'Должны производиться сцепщиком при помощи специального приспособления',
        ],
        correctAnswers: ['Должны быть дистанционными'],
      },
      {
        code: '63665122',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,5% в час',
          'Не более 0,3% в час',
          'Не более 0,1% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63665123',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'С нештатным аварийно-спасательным формированием',
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С территориальным органом Ростехнадзора',
          'С техническим руководителем организации',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63665124',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'В аварийных случаях',
          'В светлое время суток',
          'В случае приостановления движения транспортных средств в радиусе 80 м от места производства работ',
          'Ни в каком случае',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63665125',
        text:
          'Какие требования предъявляются к предохранительным решеткам бункера?',
        answers: [
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 400 мм',
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм',
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 400 х 400 мм',
        ],
        correctAnswers: [
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм',
        ],
      },
      {
        code: '63665126',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Только предохраняющие устройства, отключающие приводы при перегрузке',
          'Только исключение падения транспортируемых материалов',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63665127',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: [
          'Только паром',
          'Только сжатым воздухом',
          'Только газом',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63665128',
        text:
          'Чем должен быть покрыт пол пульта управления электронно-лучевой печью?',
        answers: [
          'Электроизолирующим материалом, на который должно быть нанесено клеймо испытания',
          'Деревянным покрытием, пропитанным огнеустойчивым средством',
          'Противоскользящим покрытием из искробезопасного материала',
        ],
        correctAnswers: [
          'Электроизолирующим материалом, на который должно быть нанесено клеймо испытания',
        ],
      },
      {
        code: '63665129',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом и автоматическим управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и механическим управлением',
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63665130',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В одном экземпляре',
          'В трех экземплярах',
          'В двух экземплярах',
          'В четырех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63665131',
        text:
          'Какое из перечисленных требований к отделению перелива чугуна указано неверно?',
        answers: [
          'Опасная зона во время слива чугуна в заливочный ковш должна быть определена в технологической или производственной инструкции',
          'Сливать чугун из чугуновозного ковша с застывшими остатками чугуна допускается в соответствии с производственной инструкцией',
          'До отцепки локомотива под колеса чугуновоза с обеих сторон должны быть установлены железнодорожные тормозные башмаки. Кроме того, чугуновоз должен быть заторможен стояночным тормозом',
          'Слив чугуна должен производиться в центр ковша равномерной струей',
        ],
        correctAnswers: [
          'Сливать чугун из чугуновозного ковша с застывшими остатками чугуна допускается в соответствии с производственной инструкцией',
        ],
      },
      {
        code: '63665132',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 25% больше расчетного',
          'На 20% больше расчетного',
          'На 15% больше расчетного',
          'На 30% больше расчетного',
        ],
        correctAnswers: ['На 15% больше расчетного'],
      },
      {
        code: '63665133',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в два года',
          'Не реже одного раза в год',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63665134',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,5% в час',
          'Не более 0,3% в час',
          'Не более 0,1% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63665135',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Главный инженер предприятия',
          'Лицо, ответственное за безопасное производство работ',
          'Главный технолог предприятия',
          'Руководитель предприятия',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63665136',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
          'Не реже одного раза в год и не реже одного раза в два года соответственно',
          'Не реже трех раз в год и не реже одного раза в год соответственно',
          'Не реже одного раза в три года и не реже одного раза в пять лет соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63665137',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии разрешения завода-изготовителя',
          'При наличии письменного распоряжения технического руководителя предприятия',
          'При наличии положительного заключения экспертного обследования',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63665138',
        text:
          'Как часто должна производиться проверка состояния механизма поворота конвертера?',
        answers: ['Еженедельно', 'После каждого процесса плавки', 'Ежесменно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63665139',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'Ресиверы размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'На ограждении ресиверов должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,5 м',
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63665140',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'При стабильной работе газоиспользующего оборудования',
          'При наличии письменного разрешения территориального органа Ростехнадзора',
          'При нарушении работы выбросов загрязняющих веществ в атмосферу',
          'Ни в каком случае',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63665141',
        text:
          'Какое допускается максимальное содержание масла в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?',
        answers: ['15 мг/м³', '20 мг/м³', '25 мг/м³', '10 мг/м³'],
        correctAnswers: ['10 мг/м³'],
      },
      {
        code: '63665142',
        text:
          'Какие ручки должен иметь металлический инструмент, применяемый при обслуживании индукционных печей?',
        answers: [
          'Деревянные ручки',
          'Электроизолированные ручки',
          'Медные ручки',
        ],
        correctAnswers: ['Электроизолированные ручки'],
      },
      {
        code: '63665143',
        text:
          'Какие из перечисленных требований, предъявляемых к мостикам для перехода через канавы и желоба при выпуске чугуна и шлака, указаны верно?',
        answers: [
          'Перила ограждения должны быть со сплошной обшивкой по низу',
          'Мостики должны быть ограждены перилами',
          'Мостики должны быть теплоизолированы',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63665144',
        text:
          'После выполнения каких мероприятий должна производиться заливка чугуна в печь?',
        answers: [
          'Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей',
          'Только после полного закрытия сталевыпускного отверстия и отключения горелок',
          'Только после установки сталевыпускного желоба и шлаковых ковшей',
        ],
        correctAnswers: [
          'Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей',
        ],
      },
      {
        code: '63665145',
        text: 'В каких случаях не допускается завалка лома в конвертер?',
        answers: [
          'При наличии в нем жидкого шлака',
          'При наличии в нем остатков жидкого металла',
          'При наличии в нем твердой корки шлака',
        ],
        correctAnswers: ['При наличии в нем жидкого шлака'],
      },
      {
        code: '63665146',
        text:
          'В каком случае должна быть обеспечена двусторонняя радиосвязь для машиниста с мастером (бригадиром) загрузки доменных печей?',
        answers: [
          'Только в случае управления вагоноопрокидывателем',
          'Только в случае управления перегрузочным краном',
          'Только в случае управления трансферкаром',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63665147',
        text:
          'Какое допускается максимальное давление кислорода при подаче в УГКС кислорода?',
        answers: [
          '2,5 МПа (25 кгс/см²)',
          '1,2 МПа (12 кгс/см²)',
          '1,6 МПа (16 кгс/см²)',
          '1,8 МПа (18 кгс/см²)',
        ],
        correctAnswers: ['1,6 МПа (16 кгс/см²)'],
      },
      {
        code: '63665148',
        text:
          'Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите все правильные ответы.',
        answers: [
          'Требованиям Правил безопасности процессов получения или применения металлов',
          'Требованиям ГОСТ 7566-2018 "Межгосударственный стандарт. Металлопродукция. Правила приемки, маркировка, упаковка, транспортирование и хранение"',
          'Требованиям технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
          'Производственной документации',
        ],
        correctAnswers: [
          'Требованиям Правил безопасности процессов получения или применения металлов',
          'Производственной документации',
        ],
      },
      {
        code: '63665149',
        text:
          'Кем должна определяться удельная теплота и температура процесса горения легковоспламеняющейся смеси?',
        answers: [
          'Разработчиком',
          'Изготовителем',
          'Эксплуатирующей организацией',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком'],
      },
      {
        code: '63665150',
        text:
          'Какое из перечисленных требований при выдувке металла из ям подины указано неверно?',
        answers: [
          'Шланги, ранее применявшиеся для выдувки металла воздухом, допускается применять для выдувки металла кислородом',
          'До начала выдувки металла из ям перед сталевыпускным отверстием должен быть установлен экран, предотвращающий разбрызгивание металла и шлака',
          'Выдувка металла из ям должна производиться со специальной переносной площадки',
          'Выдувка металла из ям подины должна производиться в сталевыпускное отверстие сжатым воздухом или кислородом, через смотровые отверстия в крышках завалочных окон',
        ],
        correctAnswers: [
          'Шланги, ранее применявшиеся для выдувки металла воздухом, допускается применять для выдувки металла кислородом',
        ],
      },
      {
        code: '63665151',
        text:
          'В какой сталеплавильный агрегат завалка металлической стружки не допускается?',
        answers: [
          'В дуговую печь',
          'В двухванный агрегат',
          'В мартеновскую печь',
        ],
        correctAnswers: ['В двухванный агрегат'],
      },
      {
        code: '63665152',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Ящики с песком, углекислотные или порошковые огнетушители',
          'Брезентовые или асбестовые покрывала',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63665153',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63665154',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Работы продолжаются без оформления акта-допуска и только в светлое время суток',
          'Составляется новый акт-допуск на следующий срок',
          'Продлевается текущий акт-допуск',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63665155',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Комиссией предприятия-заказчика',
          'Строительно-монтажной организацией',
          'Комиссией эксплуатирующей организации',
          'Проектной организацией',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63665156',
        text:
          'Какое из перечисленных требований при обслуживании электропечей указано верно? Выберите два варианта ответа.',
        answers: [
          'Во время работы газокислородной горелки крышка рабочего окна должна быть открыта',
          'Для защиты работников от брызг шлака в месте установки ковша или шлаковни под печью должны быть реализованы мероприятия по безопасности работников',
          'Шлаковни должны быть снабжены устройством для их транспортирования и кантовки',
          'Печь перед открыванием должна быть заполнена паром',
        ],
        correctAnswers: [
          'Для защиты работников от брызг шлака в месте установки ковша или шлаковни под печью должны быть реализованы мероприятия по безопасности работников',
          'Шлаковни должны быть снабжены устройством для их транспортирования и кантовки',
        ],
      },
      {
        code: '63665157',
        text:
          'Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?',
        answers: [
          'Руководством по эксплуатации',
          'Технологической инструкцией',
          'Производственной инструкцией',
          'Инструкцией о мерах пожарной безопасности при проведении газоопасных работ',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63665158',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 12 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63665159',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
          'Окна с обычным оконным стеклом толщиной 5 мм и площадью 1,5 м²',
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Фонарные переплеты',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
      },
      {
        code: '63665160',
        text:
          'Что должно подаваться в межконусное пространство загрузочного устройства доменной печи во избежание образования взрывоопасных смесей? Выберите 2 варианта ответа.',
        answers: ['Углекислый газ', 'Воздух', 'Пар', 'Азот'],
        correctAnswers: ['Пар', 'Азот'],
      },
      {
        code: '63665161',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Организация, эксплуатирующая систему автоматики печи',
          'Комиссия эксплуатирующей организации при согласовании с территориальным органом Ростехнадзором',
          'Организация, проектирующая систему автоматики печи',
          'Технический руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63665162',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом',
          'Помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников',
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Помещения должны иметь защиту от воздействия теплового излучения',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63665163',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Арматура должна размещаться над технологическими проходами',
          'Арматура должна размещаться над дверными проемами',
          'Ручной привод арматуры должен располагаться на высоте не более 2,0 м от уровня пола',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63665164',
        text: 'Какие требования к цинкованию металлов указаны неверно?',
        answers: [
          'Электропроводка на ваннах цинкования должна быть защищена от возможного повреждения их горячим металлом',
          'Порядок загрузки блоков цинка в ванну цинкования определяется проектом',
          'Срок эксплуатации ванн цинкования определяется проектной документацией завода-изготовителя',
          'Все перечисленные требования указаны верно',
          'Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования',
        ],
        correctAnswers: [
          'Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования',
        ],
      },
      {
        code: '63665165',
        text: 'Что должно быть установлено в здании подъемника?',
        answers: [
          'Телефонная связь с диспетчерской службой',
          'Радиосвязь с техническим руководителем организации',
          'Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой',
        ],
        correctAnswers: [
          'Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой',
        ],
      },
      {
        code: '63665166',
        text:
          'На какую величину с возвышением внешнего рельса со стороны слива по отношению к другому должны укладываться пути для шлака на отвале?',
        answers: [
          'Не более 200 мм',
          'Не более 180 мм',
          'Не более 150 мм',
          'Не более 250 мм',
        ],
        correctAnswers: ['Не более 150 мм'],
      },
      {
        code: '63665167',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['2,5 м', '2,0 м', '1,5 м', '3,0 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63665168',
        text:
          'Какое допускается минимальное давление газа в газопроводах перед воздухонагревателями в доменном производстве?',
        answers: [
          '400 Па (40 мм вод. ст.)',
          '350 Па (35 мм вод. ст.)',
          '500 Па (50 мм вод. ст.)',
          '200 Па (20 мм вод. ст.)',
        ],
        correctAnswers: ['500 Па (50 мм вод. ст.)'],
      },
      {
        code: '63665169',
        text:
          'Какая допускается максимальная скорость движения состава ковшей с жидким чугуном?',
        answers: ['10 км/ч', '15 км/ч', '5 км/ч', '20 км/ч'],
        correctAnswers: ['5 км/ч'],
      },
      {
        code: '63665170',
        text:
          'В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?',
        answers: [
          'Не менее 50 cекунд',
          'Не менее 10 cекунд',
          'Не менее 30 cекунд',
          'Не менее 5 cекунд',
        ],
        correctAnswers: ['Не менее 10 cекунд'],
      },
      {
        code: '63665171',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под разрежением',
          'На газопроводах, продутых инертным газом',
          'На газопроводах, находящихся под давлением',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63665172',
        text:
          'Какое максимальное значение не должна превышать объемная доля кислорода в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?',
        answers: ['3%', '5%', '4%', '6%'],
        correctAnswers: ['3%'],
      },
      {
        code: '63665173',
        text:
          'Какие из перечисленных работ по доставке и заливке чугуна в мартеновские печи, двухванные сталеплавильные агрегаты, конвертеры не осуществляются в соответствии с требованиями технологической инструкции? Выберите правильный вариант ответа.',
        answers: [
          'Замена кислородных фурм и производство других работ на своде двухванного сталеплавильного агрегата',
          'Заливка чугуна в одну из ванн двухванного сталеплавильного агрегата',
          'Пробивка или прожигание чугунной корки кислородом',
          'Все перечисленные работы',
          'Работы на своде двухванного сталеплавильного агрегата в период завалки шихты на соседней ванне',
        ],
        correctAnswers: [
          'Работы на своде двухванного сталеплавильного агрегата в период завалки шихты на соседней ванне',
        ],
      },
      {
        code: '63665174',
        text:
          'Где должна производиться регистрация показаний постоянного быстродействующего замера содержания оксида углерода и кислорода в дымоходе за дымососом? Выберите два варианта ответа.',
        answers: [
          'На щите управления газоотводящего тракта',
          'На щите пульта управления конвертером',
          'На пульте диспетчерской службы',
          'На рабочей площадке конвертера',
        ],
        correctAnswers: [
          'На щите управления газоотводящего тракта',
          'На щите пульта управления конвертером',
        ],
      },
      {
        code: '63665175',
        text:
          'На каком расстоянии от скиповой ямы производится остановка вагона-весов с установкой заградительных устройств с обеих сторон скиповой ямы?',
        answers: [
          'Не ближе 20 м',
          'Не ближе 15 м',
          'Не ближе 10 м',
          'Не ближе 5 м',
        ],
        correctAnswers: ['Не ближе 10 м'],
      },
      {
        code: '63665176',
        text:
          'Подвод азота или пара под каким давлением следует предусматривать для продувки трубопроводов кислорода и газокислородной смеси в доменном производстве?',
        answers: [
          'Не менее чем на 0,05 МПа (0,5 кгс/см²) выше давления кислорода',
          'Не менее чем на 0,08 МПа (0,8 кгс/см²) выше давления кислорода',
          'Не менее чем на 0,1 МПа (1 кгс/см²) выше давления кислорода',
          'Не менее чем на 0,03 МПа (0,3 кгс/см²) выше давления кислорода',
        ],
        correctAnswers: [
          'Не менее чем на 0,1 МПа (1 кгс/см²) выше давления кислорода',
        ],
      },
      {
        code: '63665177',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На прочность под избыточным давлением на 35% от рабочего',
          'На герметичность под избыточным давлением на 25% от рабочего',
          'На плотность под рабочим давлением',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63665178',
        text: 'Как должна производиться заливка чугуна в миксер?',
        answers: [
          'В центр окна равномерной струей с минимальной высоты',
          'В центр окна порционно с высоты, предусмотренной производственной инструкцией',
          'В верхнюю часть окна с помощью специальной воронки с минимальной высоты',
        ],
        correctAnswers: [
          'В центр окна равномерной струей с минимальной высоты',
        ],
      },
      {
        code: '63665179',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Лицо, ответственное за производственный контроль',
          'Лицо, ответственное за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Лица, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
          'Главный инженер',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63665180',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'При зажигании горелок должно быть подано максимальное количество воздуха',
          'Место отбора импульса для сигнализатора падения давления должно быть определено актом технической комиссии',
          'При увеличении нагрузки на горелку сначала должна быть увеличена подача воздуха, а затем газа',
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63665181',
        text: 'Какие требования установлены к подвешиванию скипов?',
        answers: [
          'Скипы должны подвешиваться не менее чем на трех канатах, имеющих восьмикратный запас прочности каждый',
          'Скипы должны подвешиваться не менее чем на четырех канатах, имеющих семикратный запас прочности каждый',
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый',
        ],
        correctAnswers: [
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый',
        ],
      },
      {
        code: '63665182',
        text:
          'На каком расстоянии не допускается производство работ на путях от стоящих под наливом ковшей?',
        answers: [
          'На расстоянии ближе 15 м',
          'На расстоянии ближе 17 м',
          'На расстоянии ближе 20 м',
        ],
        correctAnswers: ['На расстоянии ближе 15 м'],
      },
      {
        code: '63665183',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы должны быть остановлены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
          'Работы должны быть продолжены. Оформление нового наряда-допуска не требуется',
          'Решение о продолжении или прекращении работ должен принять работник, выдавший наряд-допуск. Допуск к работе разрешается только после проведения повторного инструктажа',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63665184',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 15 м от печей и должны быть ограждены предупреждающими знаками',
          'Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой',
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63665185',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 50 м',
          'В радиусе 30 м',
          'В радиусе 20 м',
          'В радиусе 40 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63665186',
        text:
          'Какое из перечисленных требований к завалке материалов в печи, конвертеры указано верно? Выберите правильный вариант ответа.',
        answers: [
          'В цехах, где подача мульд на стеллажи балкона рабочей площадки производится при помощи цепей с крюками, одновременная установка мульд на стеллажи краном и взятие их со стеллажей завалочной машиной не допускаются',
          'Во избежание повреждения подины и разлета кусков шихтовых материалов открывать запорный механизм бадьи нужно в соответствии с технологической инструкцией',
          'Перед началом завалки должен производиться осмотр подины печи',
          'Перед включением привода выдвижной площадки или привода подъема и отворота свода сталевар обязан убедиться, что все работающие отошли от печи на безопасное расстояние',
          'Все перечисленные требования верны',
        ],
        correctAnswers: ['Все перечисленные требования верны'],
      },
      {
        code: '63665187',
        text:
          'Какое значение не должна превышать объемная доля кислорода в смеси с природным газом при использовании газокислородных смесей в доменном производстве?',
        answers: ['50%', '40%', '30%', '25%'],
        correctAnswers: ['25%'],
      },
      {
        code: '63665188',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны изготавливаться из цветных металлов и применяться для выполнения любых работ',
          'Должны изготавливаться только из черных металлов и иметь нанесенный инвентарный номер',
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63665189',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 2% кислорода',
          'Пока на участке продувки кислорода будет не более 3% кислорода',
          'Пока на участке продувки кислорода будет не более 1% кислорода',
          'Пока на участке продувки кислорода будет не более 5% кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
        ],
      },
      {
        code: '63665190',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При допустимом падении давления воды в системе охлаждения',
          'При минимальном повышении давления воды в системе охлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63665191',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В паспорт газопроводов',
          'В журнал учета принятого в эксплуатацию газового оборудования',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63665192',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Водяным паром', 'Газом', 'Сжатым воздухом'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63665193',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 3 года',
          'Один раз в 6 лет',
          'Один раз в 8 лет',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63665194',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже двух раз в год',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63665195',
        text:
          'Чем должна заполняться электропечь в случае пробоя индуктора током и проникновения воды в вакуумную камеру? Выберите два варианта ответа.',
        answers: ['Кислородом', 'Инертным газом', 'Воздухом', 'Паром'],
        correctAnswers: ['Инертным газом', 'Воздухом'],
      },
      {
        code: '63665196',
        text:
          'Какая допускается предельная температура колошникового газа в случае задержки загрузки печи?',
        answers: [
          'Не должна превышать 900 °C',
          'Не должна превышать 700 °C',
          'Не должна превышать 500 °C',
        ],
        correctAnswers: ['Не должна превышать 500 °C'],
      },
      {
        code: '63665197',
        text:
          'Из каких материалов должны быть выполнены здания литейных дворов и поддоменников?',
        answers: [
          'Не регламентируется',
          'Из огнестойких материалов',
          'Из плит полипропилена',
        ],
        correctAnswers: ['Из огнестойких материалов'],
      },
      {
        code: '63665198',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Ежедневно', 'Ежемесячно', 'Ежеквартально', 'Еженедельно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63665199',
        text:
          'В каком случае допускается подъем ковша краном при зацепленном крюке для кантовки в сталеплавильном производстве?',
        answers: [
          'В случае наличия сигнальной связи',
          'В случае соблюдения дополнительных мер безопасности',
          'В случае оформления дополнительного письменного разрешения на выполнение работ повышенной опасности',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63665200',
        text:
          'С чьего разрешения должна производиться уборка шлака и мусора из-под мартеновской печи в период завалки и прогрева шихты?',
        answers: [
          'С разрешения диспетчера',
          'С разрешения лица, ответственного за производственный контроль в организации',
          'С разрешения руководителя организации',
          'С разрешения сталевара печи',
        ],
        correctAnswers: ['С разрешения сталевара печи'],
      },
      {
        code: '63665201',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В деревянных ящиках с крышками',
          'В пластиковых открытых ящиках',
          'В чистых металлических ящиках с крышками',
          'В металлических открытых бочках',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63665202',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Из стали для взрывоопасных сред',
          'Из ковкого чугуна',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63665203',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: [
          'Из сплавов алюминия',
          'Их сплавов стали',
          'Из сплавов титана',
        ],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63665204',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Только блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров',
          'Только системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Только средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи',
          'Всем перечисленным',
          'Только средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб',
          'Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63665205',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['100 °C', '90 °C', '85 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63665206',
        text:
          'Какое из перечисленных требований допускается при эксплуатации устройства для отвода, охлаждения и очистки конвертерных газов?',
        answers: [
          'Минимальное налипание настылей за счет конструкции нижней части охладителя',
          'Производство работ под конвертером во время очистки охладителя конвертерных газов',
          'Вскрытие люков, лазов, гидрозатворов и предохранительных клапанов газоотводящего тракта при работающем конвертере',
          'Работа конвертера при наличии течи в охладителе',
        ],
        correctAnswers: [
          'Минимальное налипание настылей за счет конструкции нижней части охладителя',
        ],
      },
      {
        code: '63665207',
        text:
          'В каком случае заправка мартеновских печей и двухванных сталеплавильных агрегатов не допускается?',
        answers: [
          'При повышении давления газа выше давления воздуха на величину, предусмотренную технологической инструкцией',
          'При открытом отверстии в площадке для спуска шлака',
          'При работе защитных блокировок в автоматическом режиме',
        ],
        correctAnswers: ['При открытом отверстии в площадке для спуска шлака'],
      },
      {
        code: '63665208',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в четыре года',
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63665209',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63665210',
        text:
          'Каким образом в эксплуатирующей организации, имеющей газовое хозяйство, производящей и потребляющей горючие газы, должен быть организован контроль за обеспечением безопасной эксплуатации газового хозяйства?',
        answers: [
          'Только путем создания газовой службы',
          'Только назначением ответственных работников',
          'Только заключением договора на оказание услуг по техническому обслуживанию и ремонту газового хозяйства с организацией',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63665211',
        text:
          'Что обязаны проверить сталевар и ответственные лица перед включением печи на плавку?',
        answers: [
          'Исправность оборудования, футеровки и свода печи',
          'Исправность системы звукового оповещения о пожаре',
          'Исправность сети газопотребления',
        ],
        correctAnswers: ['Исправность оборудования, футеровки и свода печи'],
      },
      {
        code: '63665212',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Должны производиться непосредственно во взрывопожароопасных помещениях',
          'Должны производиться только в специальном отдельно стоящем помещении',
          'Должны производиться в условиях, исключающих искрообразование',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63665213',
        text:
          'При каком содержании газов должны срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 50% нижнего предела взрываемости (2% об.), кислорода менее 5% и более 20%, угарного газа более 60 мг/м³, метана не более 17% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 20% нижнего предела взрываемости (0,8% об.), кислорода менее 25% и более 34%, угарного газа более 25 мг/м³, метана не более 50% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25% нижнего предела взрываемости (1% об.), кислорода менее 35% и более 40%, угарного газа более 29 мг/м³, метана не более 5% нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63665214',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Технологические карты и дополнительные должностные инструкции',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Инструкции по безопасному проведению пусконаладочных работ',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63665215',
        text:
          'В период выполнения ремонтных работ с каким сроком допускается загрузка шихты в печь при вышедшем из строя вращающемся распределителе шихты?',
        answers: [
          'Не более 5 часов',
          'Не более 6 часов',
          'Не более 4 часов',
          'Не более 8 часов',
        ],
        correctAnswers: ['Не более 4 часов'],
      },
      {
        code: '63665216',
        text:
          'В присутствии какого лица должна производиться присадка легковоспламеняющихся порошковых материалов, а также смесей на их основе в печь или ковш?',
        answers: [
          'В присутствии лица, ответственного за производственный контроль на предприятии',
          'В присутствии ответственного лица, назначенного распоряжением по цеху',
          'В присутствии лица, ответственного за безопасную эксплуатацию печей',
        ],
        correctAnswers: [
          'В присутствии ответственного лица, назначенного распоряжением по цеху',
        ],
      },
      {
        code: '63665217',
        text:
          'Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?',
        answers: [
          'Только из природного и доменного',
          'Только из коксового и конвертерного',
          'Только из ферросплавного и реформерного',
          'Из всех перечисленных газов',
        ],
        correctAnswers: ['Из всех перечисленных газов'],
      },
      {
        code: '63665218',
        text: 'Какие требования установлены к оснащению колошниковой площадки?',
        answers: [
          'Должна быть освещена в вечернее и ночное время, должна иметь аварийное освещение и сплошное ограждение высотой не менее 1,5 м',
          'Должна быть освещена в ночное время суток и должна иметь металлическое сетчатое ограждение высотой не менее 1,7 м',
          'Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами',
        ],
        correctAnswers: [
          'Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами',
        ],
      },
      {
        code: '63665219',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 600 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
          'Ни в каком случае',
          'Если температура кладки в разогретой зоне розжига факела превышает 700 °C',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63665220',
        text:
          'Какие из перечисленных легковоспламеняющихся смесей допускается применять при производстве стали?',
        answers: [
          'Смеси, способные к самостоятельному горению и имеющие удельную теплоту процесса горения более 50 кДж/моль',
          'Смеси, чувствительность которых к механическому воздействию (удару) составляет 19,6 Дж и менее, а активной составляющей - 9,8 Дж и менее',
          'Смеси, способные к самостоятельному горению без доступа воздуха',
          'Смеси, процесс горения которых переходит во взрыв',
        ],
        correctAnswers: [
          'Смеси, способные к самостоятельному горению без доступа воздуха',
        ],
      },
      {
        code: '63665221',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 40% нижнего предела взрываемости (1,6% об.)',
          'Выше 35% нижнего предела взрываемости (1,4% об.)',
          'Выше 30% нижнего предела взрываемости (1,2% об.)',
          'Выше 25% нижнего предела взрываемости (1% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63665222',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Со всех сторон должны иметь ограждение',
          'Должны иметь по периметру пешеходные проходы шириной 0,8 м',
          'Должны иметь по периметру пешеходные проходы шириной 1 м',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63665223',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Количество производителей (руководителей) работ должен определить работник, ответственный за выдачу наряда-допуска',
          'Один производитель (руководитель) работ по наряду-допуску, независимо от количества смен',
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63665224',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63665225',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство естественной вытяжной вентиляцией с кратностью воздухообмена не менее 2 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 6 в час',
          'Помещения оборудуются механической приточно-вытяжной общеобменной вентиляцией из верхней зоны через короб в объеме не менее двукратного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63665226',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,2 кПа',
          'Не более 0,3 кПа',
          'Не менее 0,6 кПа',
          'Не менее 0,5 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63665227',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.',
        answers: [
          'Перед пуском',
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Если содержание кислорода в продувочном газе более 3% (об.)',
          'Если оборудование остановлено более чем на 1 час',
        ],
        correctAnswers: [
          'Перед пуском',
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
        ],
      },
      {
        code: '63665228',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже трех раз в год',
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63665229',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?',
        answers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
          'Периодичность проведения уборки помещений должна устанавливаться в зависимости от условий их эксплуатации',
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63665230',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Жетон-бирочная система применяется при выводе технических устройств (ТУ) на продолжительную остановку',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63665231',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63665232',
        text:
          'Какие из перечисленных характеристик не должны быть указаны в технической документации на исходные легковоспламеняющиеся порошковые материалы?',
        answers: [
          'Группа горючести',
          'Способность к самостоятельному горению',
          'Нижний концентрационный предел распространения пламени',
          'Температура воспламенения аэровзвеси',
          'Максимальное давление взрыва',
        ],
        correctAnswers: ['Способность к самостоятельному горению'],
      },
      {
        code: '63665233',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
          'Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме',
          'При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта',
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63665234',
        text:
          'Кем должен утверждаться график отбора проб пыли для определения ее способности к самостоятельному горению и температуры самовоспламенения с поверхности оборудования и из аспирационных систем?',
        answers: [
          'Лицом, ответственным за безопасное производство работ',
          'Начальником участка',
          'Руководителем эксплуатирующей организации',
          'Техническим руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63665235',
        text:
          'Какие требования установлены к выгрузке шихтовых материалов на рудном дворе?',
        answers: [
          'Выгрузка должна быть организована ручным способом',
          'Выгрузка смерзшихся шихтовых материалов из вагонов должна производиться только при отрицательных температурах',
          'Выгрузка должна быть механизирована',
        ],
        correctAnswers: ['Выгрузка должна быть механизирована'],
      },
      {
        code: '63665236',
        text:
          'В каких случаях не допускается отключение системы охлаждения кристаллизатора?',
        answers: [
          'До выгрузки слитка из камеры',
          'До окончания процесса разгрузки камеры',
          'До температуры охлаждения камеры не ниже 45 °С',
        ],
        correctAnswers: ['До выгрузки слитка из камеры'],
      },
      {
        code: '63665237',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63665238',
        text:
          'В каком месте должна быть вывешена схема испарительного охлаждения мартеновской печи? Выберите правильный вариант ответа.',
        answers: [
          'Только в галерее барабанов-сепараторов',
          'Только в помещении дежурного персонала',
          'Только на посту управления печью',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63665239',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
          'На один объект, площадку и территорию должен оформляться один акт-допуск',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
          'Акт-допуск должен оформляться письменно',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63665240',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В вертикальном положении',
          'В наклонном положении',
          'В горизонтальном положении',
          'Не регламентируется',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63665241',
        text:
          'Что должны обеспечивать системы охлаждения горна и лещади доменной печи?',
        answers: [
          'Должны обеспечивать перепад температуры не более 3 °С',
          'Должны обеспечивать перепад температуры не более 5 °С',
          'Должны обеспечивать перепад температуры не более 7 °С',
        ],
        correctAnswers: [
          'Должны обеспечивать перепад температуры не более 3 °С',
        ],
      },
      {
        code: '63665242',
        text:
          'Что необходимо делать во время работы мельницы в помольном помещении?',
        answers: [
          'Закрывать все двери и включать световое предупредительное табло',
          'Закрывать все двери и включать приточно-вытяжную вентиляцию',
          'Включать световое предупредительное табло и звуковое оповещение о начале работы мельницы',
        ],
        correctAnswers: [
          'Закрывать все двери и включать световое предупредительное табло',
        ],
      },
      {
        code: '63665243',
        text:
          'На основании какого документа должна определяться периодичность измерения напряженности и плотности потока энергии электромагнитных полей на рабочих местах при обслуживании электропечей?',
        answers: [
          'Технологической документации',
          'Производственной документации',
          'Правил безопасности процессов получения или применения металлов',
          'Организационно-распорядительного документа эксплуатирующей организации',
        ],
        correctAnswers: ['Производственной документации'],
      },
      {
        code: '63665244',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Со всеми перечисленными',
          'Только со службой производственного контроля',
          'Только с начальником газоспасательной службы',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63665245',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['95 °C', '100 °C', '90 °C', '85 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63665246',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из чугунных сплавов',
          'Из коррозионно-стойкой стали или медных сплавов',
          'Из низкоуглеродистой стали',
        ],
        correctAnswers: ['Из коррозионно-стойкой стали или медных сплавов'],
      },
      {
        code: '63665247',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'С ГСС',
          'С командиром нештатного аварийно-спасательного формирования',
          'Со службой производственного контроля',
          'С территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['С ГСС', 'Со службой производственного контроля'],
      },
      {
        code: '63665248',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Не должны давать искры при работе с ними',
          'Должны быть изготовлены из стали',
          'Не должны быть изготовлены из цветных металлов',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63665249',
        text:
          'С какой периодичностью должны производиться замеры температуры кожухов воздухонагревателя?',
        answers: [
          'Систематически (не реже одного раза в квартал)',
          'Систематически (не реже одного раза в месяц)',
          'Систематически (не реже двух раз в месяц)',
        ],
        correctAnswers: ['Систематически (не реже одного раза в месяц)'],
      },
      {
        code: '63665250',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
          'Проведение ремонтных работ разрешается после их продувки инертным газом и снижения температуры воздуха в них до 50 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 55 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63665251',
        text:
          'В каком месте конвертера допускается накопление настылей и скрапа в соответствии с технологической документацией?',
        answers: [
          'На кожухе',
          'На горловине',
          'На опорном кольце',
          'Не допускается во всех перечисленных местах',
        ],
        correctAnswers: ['Не допускается во всех перечисленных местах'],
      },
      {
        code: '63665252',
        text:
          'Какие мероприятия должны быть выполнены при уходе металла из тигля?',
        answers: [
          'Печь должна быть отключена, и жидкий металл слит в изложницу',
          'Печь должна быть остановлена и заполнена инертным газом',
          'Печь должна быть заполнена инертным газом, и жидкий металл должен быть слит в миксер',
        ],
        correctAnswers: [
          'Печь должна быть отключена, и жидкий металл слит в изложницу',
        ],
      },
      {
        code: '63665253',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: [
          'Ежедневно',
          'Не реже 1 раза в 12 часов',
          'Не реже 1 раза в 48 часов',
        ],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63665254',
        text:
          'Эксплуатация какого из перечисленного оборудования не допускается в доменном производстве при наличии трещин? Выберите правильный вариант ответа.',
        answers: [
          'Только пылеуловителей доменных печей',
          'Только газоотводов доменных печей',
          'Только газопроводов доменных печей',
          'Всего перечисленного оборудования',
        ],
        correctAnswers: ['Всего перечисленного оборудования'],
      },
      {
        code: '63665255',
        text:
          'Какое количество выходов должен иметь поддоменник, не считая выхода на литейный двор?',
        answers: [
          'Не менее чем четыре выхода',
          'Не менее чем два выхода',
          'Не менее чем три выхода',
          'Не менее чем один выход',
        ],
        correctAnswers: ['Не менее чем два выхода'],
      },
      {
        code: '63665256',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна быть удобной и оснащаться устройством принудительной вентиляции',
          'Должна быть шумоизолированной и должна иметь металлическую сетку на смотровых стеклах',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63665257',
        text:
          'Какие требования установлены к управлению стопорами и шиберными затворами ковшей?',
        answers: [
          'Управление должно быть автоматизированным',
          'Управление должно быть механическим',
          'Управление должно быть дистанционным',
        ],
        correctAnswers: ['Управление должно быть дистанционным'],
      },
      {
        code: '63665258',
        text:
          'Кем устанавливается скорость движения чугуновозов с жидким чугуном и шлаковозов с жидким шлаком на переездах, стрелках и в районе доменных печей?',
        answers: [
          'Организацией-изготовителем',
          'Эксплуатирующей организацией',
          'Территориальным органом Ростехнадзора',
          'Проектной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63665259',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 10 часов',
          'Не менее 12 часов',
          'Не менее 16 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63665260',
        text:
          'С какой периодичностью отмечаются все изменения в состоянии кожуха и холодильников печи на схеме расположения холодильников, составляемой на каждую доменную печь?',
        answers: ['Ежесменно', 'Ежемесячно', 'Еженедельно', 'Ежедневно'],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63665261',
        text:
          'До какой температуры должен быть нагрет верх насадок газового регенератора во избежание хлопков и взрывов при пуске газа в мартеновскую печь?',
        answers: [
          'До температуры 600 - 650 °C',
          'До температуры 500 - 550 °C',
          'До температуры 700 - 750 °C',
          'До температуры 300 - 350 °C',
        ],
        correctAnswers: ['До температуры 700 - 750 °C'],
      },
      {
        code: '63665262',
        text:
          'Какое из перечисленных требований допускается при грануляции шлака за пределами цеха? Выберите два варианта ответа.',
        answers: [
          'Нахождение шлакоуборочных кранов в конце крановой эстакады на время грануляции шлака',
          'Ограждение грануляционных бассейнов перилами высотой не менее 1,0 м',
          'Слив шлака в бассейн или камеру медленно с оставлением на дне ковша части шлака',
          'Проезд составов по путям для вывозки гранулированного шлака и маневрирование на этих путях во время слива шлака из ковшей',
        ],
        correctAnswers: [
          'Нахождение шлакоуборочных кранов в конце крановой эстакады на время грануляции шлака',
          'Слив шлака в бассейн или камеру медленно с оставлением на дне ковша части шлака',
        ],
      },
      {
        code: '63665263',
        text:
          'До какой температуры должны быть охлаждены системы оборотного водоснабжения установок придоменной грануляции шлака для производства работ по их ремонту?',
        answers: [
          'Не выше 50 °C',
          'Не выше 70 °C',
          'Не выше 40 °C',
          'Не выше 60 °C',
        ],
        correctAnswers: ['Не выше 40 °C'],
      },
      {
        code: '63665264',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 1,5 м',
          'Не менее 1,3 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63665265',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
          'Работы должны производиться обслуживающим персоналом, прошедшим обучение и проверку знаний в специализированном учебном центре и не имеющим противопоказаний по результатам медицинского осмотра',
          'Работы должны производиться взрывниками в процессе эксплуатации оборудования цеха в соответствии с эксплуатационной документацией',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63665266',
        text: 'Что должно исключаться при присадке раскислителей в ковш?',
        answers: [
          'Разбрызгивание раскислителей',
          'Повреждение стопорного устройства',
          'Сбой в работе дозировочного устройства',
        ],
        correctAnswers: ['Повреждение стопорного устройства'],
      },
      {
        code: '63665267',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Разборка подвижных элементов запорной арматуры',
          'Устранение утечек газа из разъемных соединений технических устройств',
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
      {
        code: '63665268',
        text:
          'В соответствии с каким документом должно производиться опускание в печь застрявших на малом и большом конусах шихтовых материалов?',
        answers: [
          'В соответствии с производственной инструкцией',
          'В соответствии с технологической инструкцией',
          'В соответствии с паспортом объекта',
          'В соответствии с организационно-распорядительным документом эксплуатирующей организации',
        ],
        correctAnswers: ['В соответствии с технологической инструкцией'],
      },
      {
        code: '63665269',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться увеличением трения между лентой и тянущим барабаном с использованием специальных материалов',
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
          'Должна устраняться с помощью ручной регулировки',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63665270',
        text:
          'Что из перечисленного допускается при разливке чугуна на разливочных машинах?',
        answers: [
          'Кантовка ковшей со сплошной коркой чугуна',
          'Перелив чугуна в мульды',
          'Нахождение людей в галереях во время проведения маневровых работ в здании разливочных машин',
          'Прожигание чугунной корки кислородом',
        ],
        correctAnswers: ['Прожигание чугунной корки кислородом'],
      },
      {
        code: '63665271',
        text:
          'Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?',
        answers: [
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
          'Должно быть выше верхних площадок колошника не менее чем на 2 м',
          'Должно быть выше верхних площадок колошника не менее чем на 3 м',
        ],
        correctAnswers: [
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
        ],
      },
      {
        code: '63665272',
        text:
          'Какое максимальное содержание кислорода должно быть во время плавки при содержании оксида углерода в газе за дымососом конвертера 10% и более?',
        answers: [
          '3% (по объему)',
          '5% (по объему)',
          '4% (по объему)',
          '2% (по объему)',
        ],
        correctAnswers: ['2% (по объему)'],
      },
      {
        code: '63665273',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть выдержаны в помещении не менее 12 часов',
          'Должны быть очищены от мусора',
          'Должны быть выдержаны в помещении не менее 24 часов',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63665274',
        text:
          'Какие действия должны быть выполнены при возникновении в мартеновской и двухванной сталеплавильной печи бурных реакций? Выберите правильный вариант ответа.',
        answers: [
          'Только уменьшена или прекращена подача кислорода',
          'Только уменьшена тепловая нагрузка печи',
          'Должны быть выполнены все перечисленные действия одновременно',
        ],
        correctAnswers: [
          'Должны быть выполнены все перечисленные действия одновременно',
        ],
      },
      {
        code: '63665275',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Только дистанционное отключение',
          'Только установка на линиях всасывания запорных или отсекающих устройств',
          'Все перечисленные требования',
          'Только установка на линиях нагнетания запорных или отсекающих устройств',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63665276',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться на горизонтальных площадках в любом месте пролета цеха в соответствии с проектной документацией',
          'Должны расставляться на свободных площадках пролета цеха в соответствии с производственной инструкцией',
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63665277',
        text:
          'Что должно в обязательном порядке производиться при категорировании зданий и помещений?',
        answers: [
          'Расчет тротилового эквивалента',
          'Расчет скорости распространения ударной волны',
          'Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации',
        ],
        correctAnswers: [
          'Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации',
        ],
      },
      {
        code: '63665278',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Только паспортом (формуляром) на компрессорную установку',
          'Только схемой трубопроводов',
          'Только инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Только журналом учета работы компрессора',
          'Только паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа',
          'Только графиком ремонтов компрессорной установки',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63665279',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '8 м', '5 м', '3 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63665280',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение одного месяца',
          'В течение трех месяцев',
          'В течение шести месяцев',
          'В течение двенадцати месяцев',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63665281',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: ['Один раз в 4 года', 'Один раз в 5 лет', 'Один раз в 6 лет'],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63665282',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['1,0 час', '1,5 часа', '2,0 часа', '2,5 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63665283',
        text:
          'Куда может осуществляться сброс горячего воздуха, оставшегося в воздухонагревателе при переводе с дутья на нагрев, в зависимости от конструкции (проекта)?',
        answers: [
          'Только в боров дымовой трубы',
          'Только в специальный глушитель',
          'Только в другой воздухонагреватель',
          'Во все перечисленные места',
        ],
        correctAnswers: ['Во все перечисленные места'],
      },
      {
        code: '63665284',
        text:
          'В каком случае разрешается эксплуатация азотопровода без обогрева в доменном производстве?',
        answers: [
          'Если объемная доля кислорода в азоте не превышает 5%',
          'Если содержание масла в азоте не более 12 мг/м³',
          'Если подаваемый азот сухой',
          'Если подаваемый азот в жидком состоянии',
        ],
        correctAnswers: ['Если подаваемый азот сухой'],
      },
      {
        code: '63665285',
        text:
          'С какой периодичностью проверяется состояние внешнего оборудования колошника и засыпного аппарата с оформлением результатов проверок?',
        answers: ['Ежесменно', 'Ежедневно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Ежеквартально'],
      },
      {
        code: '63665286',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 18% и не более 23%',
          'Не менее 17% и не более 25%',
          'Не менее 19% и не более 23%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63665287',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'На расстоянии 20 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания',
          'В зоне погрузки грейферными или магнитными кранами',
          'В зоне погрузки только грейферными кранами',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63665288',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'На всех ступенях',
          'Только на средней ступени',
          'Только на последней ступени',
          'Только на первой ступени',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63665289',
        text:
          'Какие требования к цинкованию металлов и полимерным покрытиям указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Все операции по транспортированию и обработке проката при нанесении защитных покрытий, а также вспомогательные операции (смена анодов в ваннах электролитического лужения и оцинкования, загрузка металла в ванну с расплавом, приготовление, подача и очистка растворов) должны быть механизированы и должны выполняться в соответствии с технологическими инструкциями',
          'Нанесение защитных покрытий на листовой металл (лужение, оцинкование) следует производить на установках непрерывного действия',
          'Оборудование для нанесения защитных покрытий (полимерных материалов) должно иметь местные отсосы',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63665290',
        text:
          'Как должен быть подготовлен газоотводящий тракт конвертера к ремонту?',
        answers: [
          'Должен быть тщательно очищен от загрязнений',
          'Должен быть надежно отключен от общих коллекторов и боровов',
          'Должен быть продут инертным газом',
        ],
        correctAnswers: [
          'Должен быть надежно отключен от общих коллекторов и боровов',
        ],
      },
      {
        code: '63665291',
        text:
          'Кому должен сообщить мастер доменной печи или газовщик о подвисании и осадке шихты?',
        answers: [
          'Только диспетчеру газового хозяйства',
          'Только машинисту воздуходувной машины',
          'Только персоналу загрузки',
          'Всем перечисленным лицам',
        ],
        correctAnswers: ['Всем перечисленным лицам'],
      },
      {
        code: '63665292',
        text:
          'Какая информация должна быть указана на каждом стопоре перед загрузкой в сушила?',
        answers: [
          'Дата и время постановки стопора на сушку',
          'Только дата постановки стопора на сушку',
          'Фамилия и должность лица, ответственного за сушку',
          'Только время постановки стопора на сушку',
        ],
        correctAnswers: ['Дата и время постановки стопора на сушку'],
      },
      {
        code: '63665293',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Проверка параметров срабатывания предохранительных запорных и сбросных клапанов',
          'Техническое обслуживание',
          'Текущий ремонт',
          'Капитальный ремонт',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63665294',
        text:
          'Чем должен производиться нагрев воздухонагревателей в доменном производстве?',
        answers: [
          'Паром',
          'Очищенным газом',
          'Горячей водой',
          'Не регламентируется',
        ],
        correctAnswers: ['Очищенным газом'],
      },
      {
        code: '63665295',
        text:
          'Какое количество люков должно устраиваться на газовых затворах с цилиндрической вставкой для устойчивого горения газа при проверке засыпного материала?',
        answers: ['Три люка', 'Один люк', 'Два люка', 'Не регламентируется'],
        correctAnswers: ['Два люка'],
      },
      {
        code: '63665296',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63665297',
        text:
          'Где должны регистрироваться расходы природного газа и кислорода, подаваемых на смешение, в доменном производстве?',
        answers: [
          'В оперативном журнале работы доменной печи',
          'В ежесменном отчете',
          'В эксплуатационном паспорте доменной печи',
          'В цеховом журнале осмотра и ремонта аппаратуры и оборудования',
        ],
        correctAnswers: ['В оперативном журнале работы доменной печи'],
      },
      {
        code: '63665298',
        text:
          'В соответствии с чем должны проводить контроль состояния подшипников и проверку цапф конверторов?',
        answers: [
          'В соответствии с паспортом объекта',
          'В соответствии с Правилами безопасности процессов получения или применения металлов',
          'В соответствии с производственной документацией',
          'В соответствии с организационно-распорядительным документом эксплуатирующей организации',
        ],
        correctAnswers: ['В соответствии с производственной документацией'],
      },
      {
        code: '63665299',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров?Выберите правильный вариант ответа.',
        answers: [
          'Только механизированное сливание расплава металла из ковшей и миксеров',
          'Только запрет на работу с технологической емкостью (ковшом) с расплавом металлов общим весом более 15 кг, держа ее в руках на весу',
          'Только совпадение оси сливного отверстия ковша, установленного под слив, с осью заливочного ковша',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63665300',
        text:
          'Что должно быть выполнено во время завалки материалов и подвалки шихты?',
        answers: [
          'Должны быть реализованы мероприятия по безопасности работников',
          'Должен быть незамедлительно оповещен руководитель предприятия',
          'Должна быть остановлена печь и эвакуированы рабочие',
        ],
        correctAnswers: [
          'Должны быть реализованы мероприятия по безопасности работников',
        ],
      },
      {
        code: '63665301',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: [
          'Более 0,5 мм/г',
          '0,1 - 0,5 мм/г',
          'До 0,1 мм/г',
          'Не регламентируется',
        ],
        correctAnswers: ['0,1 - 0,5 мм/г'],
      },
      {
        code: '63665302',
        text:
          'Чем определяется высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций?',
        answers: [
          'Проектной документацией',
          'Производственной документацией',
          'Требованиями ГОСТ 7566-2018 "Межгосударственный стандарт. Металлопродукция. Правила приемки, маркировка, упаковка, транспортирование и хранение"',
          'Требованиями Правил безопасности процессов получения или применения металлов',
        ],
        correctAnswers: ['Производственной документацией'],
      },
      {
        code: '63665303',
        text:
          'С какой периодичностью должна проверяться герметичность сводов и стен регенераторов, работающих с подогревом газа в них?',
        answers: ['Каждые 48 часов', 'Еженедельно', 'Ежесменно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63665304',
        text:
          'Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?',
        answers: [
          'Печь должна быть немедленно отключена',
          'Подача воды должна быть приостановлена',
          'Нагрузка на печь должна быть максимально снижена',
        ],
        correctAnswers: ['Печь должна быть немедленно отключена'],
      },
      {
        code: '63665305',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 15 минут',
          'В течение 10 минут',
          'В течение 20 минут',
          'В течение 30 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63665306',
        text: 'Как и кем должны проверяться состояние и исправность миксера?',
        answers: [
          'Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале',
          'Должны каждые 3 смены проверяться миксеровым с записью результатов в акте осмотра',
          'Должны 2 раза в смену проверяться миксеровым с записью результатов осмотра в паспорте миксера',
        ],
        correctAnswers: [
          'Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале',
        ],
      },
      {
        code: '63665307',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: [
          'Один раз в 3 года',
          'Один раз в 1,5 года',
          'Один раз в 2 года',
        ],
        correctAnswers: ['Один раз в 2 года'],
      },
      {
        code: '63665308',
        text:
          'Какое из перечисленных требований к отводу шлакового желоба на резервную линию установки придоменной грануляции шлака указано верно?',
        answers: [
          'Должен быть перекрыт отсечным устройством и на длине не менее 2 м засыпан песком на 50 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не менее 0,5 м засыпан песком на 50 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не менее 1,5 м засыпан песком на 150 мм выше бортов желоба',
        ],
        correctAnswers: [
          'Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба',
        ],
      },
      {
        code: '63665309',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 6 месяцев',
          'В течение 1 месяца',
          'В течение 12 месяцев',
          'В течение 3 месяцев',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63665310',
        text:
          'Что из перечисленного отмечается в техническом паспорте каждого воздухонагревателя? Выберите правильный вариант ответа.',
        answers: [
          'Только результаты периодических осмотров',
          'Только все производимые ремонты с указанием их характера с приложением чертежей, по которым были произведены ремонты',
          'Только информация о лицах, выполнявших сварочные работы',
          'Отмечается все перечисленное',
        ],
        correctAnswers: ['Отмечается все перечисленное'],
      },
      {
        code: '63665311',
        text:
          'Какое из перечисленных требований допускается при выпуске, разливке и уборке стали?',
        answers: [
          'Съем крышек с изложниц до полного застывания верха слитка',
          'Слив остатков жидкого шлака из сталеразливочного ковша в шлаковые ковши или шлаковни по окончании разливки стали',
          'Нахождение работников на бортах изложниц, наполненных жидким металлом',
          'Установка шлаковен в 2 яруса',
        ],
        correctAnswers: [
          'Слив остатков жидкого шлака из сталеразливочного ковша в шлаковые ковши или шлаковни по окончании разливки стали',
        ],
      },
      {
        code: '63665312',
        text:
          'Через какие помещения допускается прокладка водородопроводов? Выберите правильный вариант ответа.',
        answers: [
          'Через бытовые, подсобные, административно-хозяйственные и складские помещения',
          'Через вентиляционные камеры, помещения КИП, лестничные клетки и пути эвакуации',
          'Через электромашинные, электрораспределительные и трансформаторные помещения',
          'Через производственные помещения, связанные с потреблением водорода',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63665313',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные техническим руководителем эксплуатирующей организации',
          'В сроки, установленные руководителем территориального органа Ростехнадзора',
          'В сроки, установленные изготовителем',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63665314',
        text:
          'При каких условиях должны производиться все работы по подготовке плавки?',
        answers: [
          'Только при условии окончания плавки',
          'Только при отключенной печи (установке)',
          'Только при проведении плавки',
          'Только при условии присутствия руководителя предприятия',
        ],
        correctAnswers: ['Только при отключенной печи (установке)'],
      },
      {
        code: '63665315',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в две недели и не реже одного раза в три месяца соответственно',
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
          'Не реже одного раза в три месяца и не реже одного раза в шесть месяцев соответственно',
          'Не реже одного раза в четыре месяца и не реже одного раза в год соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
    ],
    63667: [
      {
        code: '63667000',
        text: 'Что должно быть установлено в здании подъемника?',
        answers: [
          'Прямая телефонная (радио) связь с колошниковой площадкой и скиповой ямой',
          'Телефонная связь с диспетчерской службой',
          'Радиосвязь только с колошниковой площадкой',
          'Радиосвязь с операторной',
        ],
        correctAnswers: [
          'Прямая телефонная (радио) связь с колошниковой площадкой и скиповой ямой',
        ],
      },
      {
        code: '63667001',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['1 час', '1,5 часа', '2 часа', '2,5 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63667002',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны храниться в специальном помещении не менее 2 суток',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63667003',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'Только на первой ступени',
          'Только на второй ступени',
          'Только на третьей ступени',
          'На всех ступенях',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63667004',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны быть омедненными',
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
          'Должны быть предназначены для выполнения любых работ',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63667005',
        text:
          'Какое из перечисленных требований допускается при транспортировании и погрузке шлака?',
        answers: [
          'Транспортирование и погрузка шлаковых глыб на железнодорожную платформу или в думпкар без тары',
          'Погрузка шлака в сырые думпкары или на сырые платформы',
          'Нахождение на подвижном составе во время кантовки шлака',
          'Не допускается ни одно из перечисленных требований',
        ],
        correctAnswers: ['Не допускается ни одно из перечисленных требований'],
      },
      {
        code: '63667006',
        text:
          'Какие требования безопасности при производстве ртути указаны неверно?',
        answers: [
          'Работы, связанные с применением ртути, должны проводиться в отдельных помещениях, оборудованных принудительной приточно-вытяжной вентиляцией, в вытяжных шкафах в соответствии с технологической инструкцией',
          'При попадании ртути на нагретые поверхности технических устройств необходимо, не отключая вытяжной вентиляции, увеличить мощность источника нагрева, прогреть поверхность и провести ее демеркуризацию',
          'Конструкция вытяжных шкафов, используемых для работы с ртутью и ее соединениями, разрабатывается проектной организацией',
          'Пары, образующиеся в процессе получения ртути и ее соединений, должны подвергаться конденсации с последующей утилизацией',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При попадании ртути на нагретые поверхности технических устройств необходимо, не отключая вытяжной вентиляции, увеличить мощность источника нагрева, прогреть поверхность и провести ее демеркуризацию',
        ],
      },
      {
        code: '63667007',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от возможного попадания на них расплава',
          'Только от тепловых воздействий',
          'От всего перечисленного',
          'Только от механических воздействий',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63667008',
        text:
          'Какое минимальное количество выходов допускается в помещении операторной при площади свыше 150 м²?',
        answers: ['Один', 'Два', 'Три', 'Четыре'],
        correctAnswers: ['Два'],
      },
      {
        code: '63667009',
        text:
          'Какие независимые технологические линии должны иметь в своем составе установки придоменной грануляции шлака? Выберите два варианта ответа.',
        answers: ['Рабочую', 'Резервную', 'Байпасную', 'Аварийную'],
        correctAnswers: ['Рабочую', 'Резервную'],
      },
      {
        code: '63667010',
        text:
          'В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?',
        answers: [
          'Только без предварительной замены стопора',
          'Без предварительной замены стопора и стакана',
          'Без предварительной замены футеровки',
        ],
        correctAnswers: ['Без предварительной замены стопора и стакана'],
      },
      {
        code: '63667011',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
          'Только работниками подрядных организаций',
          'Только работниками эксплуатирующей организации',
          'Сотрудниками отдела промышленной безопасности эксплуатирующей организации',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63667012',
        text:
          'В соответствии с чем производятся подтяжка канатов скипов и выполнение других работ, связанных с кратковременным прекращением загрузки шихтовых материалов в печь?',
        answers: [
          'В соответствии с производственной документацией',
          'В соответствии с проектной документацией',
          'В соответствии с Правилами безопасности процессов получения или применения металлов',
          'В соответствии с нарядом-допуском',
        ],
        correctAnswers: ['В соответствии с производственной документацией'],
      },
      {
        code: '63667013',
        text:
          'Какие требования к аварийной системе вентиляции в компрессорном помещении при получении водорода методом диссоциации аммиака указаны неверно?',
        answers: [
          'В технологическом пролете производства должны быть обустроены светоаэрационные фонари, обеспечивающие удаление избыточного тепла от технологического оборудования',
          'Аварийная система вентиляции должна обеспечивать в компрессорном помещении 8-кратный воздухообмен',
          'Все перечисленные требования указаны верно',
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 2,4% содержания водорода в воздухе помещения',
        ],
        correctAnswers: [
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 2,4% содержания водорода в воздухе помещения',
        ],
      },
      {
        code: '63667014',
        text:
          'С какой периодичностью проверяется состояние внешнего оборудования колошника и засыпного аппарата с оформлением результатов проверок?',
        answers: ['Ежегодно', 'Еженедельно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Ежеквартально'],
      },
      {
        code: '63667015',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Один раз в полгода',
          'Один раз в год',
          'Один раз в 2 года',
          'Один раз в 4 года',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63667016',
        text: 'Какие требования установлены к укладке слитков?',
        answers: [
          'Укладка должна производиться в специальные закрома',
          'Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков',
          'Укладка должна производиться на специально выделенные в цехе площадки',
          'Укладка должна производиться на площадки с бетонным основанием и обвалованием',
        ],
        correctAnswers: [
          'Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков',
        ],
      },
      {
        code: '63667017',
        text:
          'С помощью каких устройств должно производиться удаление пыли из изложниц?',
        answers: [
          'С помощью продувочных устройств',
          'С помощью пылеотсасывающих устройств',
          'С помощью промывочных устройств',
          'С помощью пропаривающих устройств',
        ],
        correctAnswers: ['С помощью пылеотсасывающих устройств'],
      },
      {
        code: '63667018',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение трех месяцев',
          'В течение шести месяцев',
          'В течение одного месяца',
          'В течение года',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63667019',
        text:
          'На каком расстоянии от кантуемых ковшей должен размещаться пульт управления на шлаковом отвале?',
        answers: [
          'На расстоянии не менее 10 м',
          'На расстоянии не менее 8 м',
          'На расстоянии не менее 5 м',
        ],
        correctAnswers: ['На расстоянии не менее 10 м'],
      },
      {
        code: '63667020',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть установлены на расстоянии не более 3 м от печей',
          'Должны быть установлены на расстоянии не менее 10 м от печей и должны быть защищены паровой завесой',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63667021',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть очищены от мусора',
          'Должны быть выдержаны в помещении не менее суток',
          'Должны быть увлажнены',
          'Должны быть обезжирены',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63667022',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В двух экземплярах',
          'В одном экземпляре',
          'В четырех экземплярах',
          'В трех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63667023',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,01 МПа (0,1 кгс/см²)',
          'При давлении 0,03 МПа (0,3 кгс/см²)',
          'При давлении 0,05 МПа (0,5 кгс/см²)',
          'При давлении 0,07 МПа (0,7 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63667024',
        text:
          'Какие требования к помещению операторной указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Для слежения за работой оборудования, находящегося вне зоны видимости, предусматривается технологическая сигнализация и устройство видеонаблюдения',
          'Полы в операторной должны быть диэлектрическими',
          'Помещения операторной должны быть оборудованы сигнализацией для оповещения персонала о появлении отклонений от технологического процесса',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63667025',
        text:
          'В соответствии с какой документацией должны производиться подготовка к пуску и пуск установок по производству водорода?',
        answers: [
          'Только в соответствии с технологическим регламентом',
          'Только в соответствии с инструкцией по эксплуатации',
          'Только в соответствии с проектом',
          'В соответствии со всей перечисленной документацией',
        ],
        correctAnswers: ['В соответствии со всей перечисленной документацией'],
      },
      {
        code: '63667026',
        text:
          'Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На величину рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63667027',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При незначительном падении давления воды в системе водоохлаждения',
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При незначительном повышении давления воды в системе охлаждения',
          'При незначительном повышении температуры воды в системе водоохлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63667028',
        text:
          'Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?',
        answers: [
          'Осмотр должен производиться не реже одного раза в три года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - еженедельно',
          'Осмотр должен производиться не реже одного раза в два года, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
        ],
        correctAnswers: [
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
        ],
      },
      {
        code: '63667029',
        text:
          'Кем устанавливается скорость движения чугуновозов с жидким чугуном и шлаковозов с жидким шлаком на переездах, стрелках и в районе доменных печей?',
        answers: [
          'Эксплуатирующей организацией',
          'Заводом-изготовителем',
          'Начальником цеха',
          'Главным инженером предприятия',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63667030',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка предварительно просушенной и прокаленной руды',
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Допускается все перечисленное',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63667031',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должен быть устроен медицинский кабинет',
          'Должны быть устроены пожарные краны и ящики с песком',
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Должна быть установлена автоматическая система пожаротушения',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63667032',
        text:
          'Каким образом в эксплуатирующей организации, имеющей газовое хозяйство, производящей и потребляющей горючие газы, должен быть организован контроль за обеспечением безопасной эксплуатации газового хозяйства?',
        answers: [
          'Только путем создания газовой службы',
          'Только путем назначения ответственных работников',
          'Только путем заключения договора на оказание услуг по техническому обслуживанию и ремонту газового хозяйства с организацией',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63667033',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 20 рабочих дней',
          'На 45 календарных дней',
          'На 15 календарных дней',
          'На 30 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63667034',
        text:
          'Каким инструментом выполняется взятие проб жидкого чугуна на химический анализ?',
        answers: [
          'Инструментом, покрытым огнеупорным материалом',
          'Просушенным и холодным инструментом',
          'Просушенным и подогретым инструментом',
          'Искробезопасным инструментом',
        ],
        correctAnswers: ['Просушенным и подогретым инструментом'],
      },
      {
        code: '63667035',
        text: 'Какие требования установлены к сливу шлака в шлаковую яму?',
        answers: [
          'Должен производиться равномерной струей',
          'Должен производиться кантовкой ковша на 90°',
          'Должен производиться прерываясь, с равными интервалами времени',
          'Должен производиться тонкой струей',
        ],
        correctAnswers: ['Должен производиться равномерной струей'],
      },
      {
        code: '63667036',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В отдельно стоящих зданиях',
          'Размещение операторных помещений допускается во всех перечисленных местах',
          'В отдельных встроенных щитовых помещениях, смежных смежных с помещениями с взрывоопасными зонами',
          'В производственных помещениях',
          'В отдельных пристроенных щитовых помещениях, смежных с помещениями с взрывоопасными зонами',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63667037',
        text:
          'Какое допускается максимальное содержание масла в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?',
        answers: ['5 мг/м³', '20 мг/м³', '12 мг/м³', '10 мг/м³'],
        correctAnswers: ['10 мг/м³'],
      },
      {
        code: '63667038',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Освидетельствованию',
          'Проверке состояния',
          'Механическим испытаниям',
          'Чистке',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63667039',
        text:
          'Что должно быть выполнено во время завалки материалов и подвалки шихты?',
        answers: [
          'Все работы на печи должны быть приостановлены, а работающие удалены в безопасное место',
          'Печь должна быть остановлена, а работающие должны находиться в укрытиях',
          'Должны быть реализованы мероприятия по безопасности работников',
        ],
        correctAnswers: [
          'Должны быть реализованы мероприятия по безопасности работников',
        ],
      },
      {
        code: '63667040',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
          'Оповещение эксплуатационного персонала о начале и окончании смены',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63667041',
        text:
          'Какая допускается максимальная скорость движения состава ковшей с жидким чугуном?',
        answers: ['10 км/ч', '3 км/ч', '5 км/ч', '7 км/ч'],
        correctAnswers: ['5 км/ч'],
      },
      {
        code: '63667042',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
          'Должна устраняться при помощи ручной регулировки',
          'Должна устраняться подсыпанием канифоли и других материалов',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63667043',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением',
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63667044',
        text:
          'В каком месте должна быть вывешена схема испарительного охлаждения мартеновской печи?',
        answers: [
          'Только в галерее барабанов-сепараторов',
          'Только в помещении дежурного персонала',
          'Только на посту управления печью',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63667045',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,3 м в свету',
          'Не менее 1,1 м в свету',
          'Не менее 1,5 м в свету',
          'Не менее 1,4 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63667046',
        text:
          'Что необходимо делать во время работы мельницы в помольном помещении?',
        answers: [
          'Закрывать все двери и включать приточно-вытяжную вентиляцию',
          'Только включать световое предупредительное табло',
          'Закрывать все двери и включать световое предупредительное табло',
          'Включать аспирационную систему',
        ],
        correctAnswers: [
          'Закрывать все двери и включать световое предупредительное табло',
        ],
      },
      {
        code: '63667047',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Только паспортом (формуляром) на компрессорную установку',
          'Только схемой трубопроводов',
          'Только инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Только паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа',
          'Только графиком ремонтов компрессорной установки',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63667048',
        text:
          'На каком расстоянии от бровки отвала должна располагаться ось пути для шлака на отвале?',
        answers: [
          'Не менее 0,9 м',
          'Не менее 1,2 м',
          'Не менее 1,4 м',
          'Не менее 1,8 м',
        ],
        correctAnswers: ['Не менее 1,4 м'],
      },
      {
        code: '63667049',
        text: 'Какие требования установлены к подвешиванию скипов?',
        answers: [
          'Скипы должны подвешиваться не менее чем на четырех канатах, имеющих пятикратный запас прочности каждый',
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих трехкратный запас прочности каждый',
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый',
        ],
        correctAnswers: [
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый',
        ],
      },
      {
        code: '63667050',
        text:
          'Какое из перечисленных требований при выдувке металла из ям подины указано неверно?',
        answers: [
          'Выдувка металла из ям подины должна производиться в сталевыпускное отверстие сжатым воздухом или кислородом',
          'Шланги, ранее применявшиеся для выдувки металла воздухом, допускается применять для выдувки металла кислородом',
          'До начала выдувки металла из ям перед сталевыпускным отверстием должен быть установлен экран, предотвращающий разбрызгивание металла и шлака',
          'Выдувка металла из ям должна производиться со специальной переносной площадки или с состава, предназначенного для ремонта подин',
        ],
        correctAnswers: [
          'Шланги, ранее применявшиеся для выдувки металла воздухом, допускается применять для выдувки металла кислородом',
        ],
      },
      {
        code: '63667051',
        text:
          'На каких аппаратах, заполненных водородом, допускается производство ремонтных работ?',
        answers: [
          'На аппаратах, оборудованных дополнительной системой защиты',
          'На аппаратах, прошедших очередную техническую проверку',
          'Производство ремонтных работ не допускается',
          'На аппаратах с объемом водорода не более 1,2 м³',
        ],
        correctAnswers: ['Производство ремонтных работ не допускается'],
      },
      {
        code: '63667052',
        text:
          'Что должно в обязательном порядке производиться при категорировании зданий и помещений?',
        answers: [
          'Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации',
          'Расчет тротилового эквивалента',
          'Оценка риска аварий',
          'Расчет максимального срока эксплуатации при критических параметрах',
        ],
        correctAnswers: [
          'Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации',
        ],
      },
      {
        code: '63667053',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 16 мм',
          'Не менее 10 мм',
          'Не менее 26 мм',
          'Не менее 14 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63667054',
        text:
          'На какое давление должны быть рассчитаны специальные жесткие переходы и крепления для соединения кранов и стеклянных трубок приборов и аппаратуры, содержащих ртуть?',
        answers: [
          'На давление, превышающее на 5 - 8% максимальное рабочее давление',
          'На давление, превышающее на 10 - 15% максимальное рабочее давление',
          'На давление, превышающее на 17 - 20% максимальное рабочее давление',
          'На давление, превышающее на 20 - 25% максимальное рабочее давление',
        ],
        correctAnswers: [
          'На давление, превышающее на 10 - 15% максимальное рабочее давление',
        ],
      },
      {
        code: '63667055',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'В помещении, в котором размещено оборудование компрессорной установки (машинный зал), не допускается устанавливать аппаратуру и оборудование, технологически или конструктивно не связанное с процессом компримирования водорода',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63667056',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Одним человеком под наблюдением газоспасателя или члена ДГСД',
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Не менее трех человек',
          'Не более двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63667057',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Составляется новый акт-допуск на следующий срок',
          'Продлевается текущий акт-допуск',
          'Работы продолжаются без оформления нового акта-допуска',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63667058',
        text:
          'Какой запас прочности должны иметь цапфы ковшей, предназначенных для расплава металла?',
        answers: [
          'Не менее пятикратного запаса прочности',
          'Не менее восьмикратного запаса прочности',
          'Не менее шестикратного запаса прочности',
          'Не менее трехкратного запаса прочности',
        ],
        correctAnswers: ['Не менее восьмикратного запаса прочности'],
      },
      {
        code: '63667059',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: ['Ежедневно', 'Каждую смену', 'Каждые двое суток'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63667060',
        text:
          'Какие системы должны быть проверены в обязательном порядке перед началом пуска установок получения водорода? Выберите правильный вариант ответа.',
        answers: [
          'Только системы охлаждения и снабжения инертными газами',
          'Только теплоснабжение, водоснабжение, электроснабжение, воздухоснабжение',
          'Только системы отопления и вентиляции',
          'Все перечисленные системы',
        ],
        correctAnswers: ['Все перечисленные системы'],
      },
      {
        code: '63667061',
        text:
          'Какое допускается минимальное давление газа в газопроводах перед воздухонагревателями в доменном производстве?',
        answers: [
          '150 Па (15 мм вод. ст.)',
          '350 Па (35 мм вод. ст.)',
          '500 Па (50 мм вод. ст.)',
          '200 Па (20 мм вод. ст.)',
        ],
        correctAnswers: ['500 Па (50 мм вод. ст.)'],
      },
      {
        code: '63667062',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом',
          'Помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников',
          'Помещения должны иметь защиту от воздействия теплового излучения',
          'Двери помещения должны открываться наружу и не иметь внутренних запоров',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63667063',
        text:
          'Какие требования установлены к конструкции клапанов, перекрывающих поступление газа к горелкам?',
        answers: [
          'Конструкция должна обеспечивать класс герметичности затвора А',
          'Конструкция должна обеспечивать перекрытие газопровода в течение 12 секунд',
          'Конструкция должна обеспечивать плотное перекрытие газопровода',
          'Конструкция должна обеспечивать класс герметичности затвора В',
        ],
        correctAnswers: [
          'Конструкция должна обеспечивать плотное перекрытие газопровода',
        ],
      },
      {
        code: '63667064',
        text:
          'Как часто комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в сутки',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63667065',
        text: 'Какие требования установлены к диаметру лазов пылеуловителей?',
        answers: [
          'Диаметр не менее 600 мм',
          'Диаметр не менее 500 мм',
          'Диаметр не менее 400 мм',
        ],
        correctAnswers: ['Диаметр не менее 600 мм'],
      },
      {
        code: '63667066',
        text: 'Какие требования к пневматическим КИПиА указаны неверно?',
        answers: [
          'Воздухопроводы для КИПиА должны иметь буферные емкости, обеспечивающие запас сжатого воздуха для работы приборов',
          'Воздухопроводы для КИПиА должны быть отдельными, не связанными с трубопроводами на технологические нужды',
          'Для пневматических КИПиА должны предусматриваться специальные установки и отдельные сети сжатого воздуха',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63667067',
        text: 'Чем должен быть покрыт пол рабочей площадки возле электропечи?',
        answers: [
          'Термостойким настилом',
          'Электроизолирующим настилом',
          'Деревянным настилом',
        ],
        correctAnswers: ['Электроизолирующим настилом'],
      },
      {
        code: '63667068',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под разрежением',
          'После продувки газопроводов сжатым воздухом',
          'На газопроводах, находящихся под давлением',
          'После продувки газопроводов инертным газом',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63667069',
        text:
          'При какой предельной температуре кислорода после регулятора давления на линии регулирования прекращается его подача?',
        answers: [
          'При повышении более 100 °С',
          'При повышении более 120 °С',
          'При повышении более 150 °С',
        ],
        correctAnswers: ['При повышении более 100 °С'],
      },
      {
        code: '63667070',
        text: 'Как должны подготавливаться растворы кислот и щелочей?',
        answers: [
          'Вода должна заливаться небольшой струей в кислоту и щелочь',
          'Кислота и щелочь должны заливаться небольшой струей в холодную воду',
          'Кислота и щелочь должны заливаться небольшой струей в воду, нагретую до температуры 70 °С',
          'Кислота и щелочь должны смешиваться с водой в пропорции 1:1 в специальных емкостях для щелочных растворов и кислотосборниках',
        ],
        correctAnswers: [
          'Кислота и щелочь должны заливаться небольшой струей в холодную воду',
        ],
      },
      {
        code: '63667071',
        text:
          'Какое должно быть содержание кислорода в воздухе помещения наполнения баллонов?',
        answers: [
          'При объемной доле кислорода в воздухе не менее 19% и не более 23%',
          'При объемной доле кислорода в воздухе не менее 18% и не более 24%',
          'При объемной доле кислорода в воздухе не менее 17% и не более 23%',
        ],
        correctAnswers: [
          'При объемной доле кислорода в воздухе не менее 19% и не более 23%',
        ],
      },
      {
        code: '63667072',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 4 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63667073',
        text:
          'В течение какого времени можно хранить ртуть в производственных помещениях с работающей вытяжной системой в герметично закрытых толстостенных стеклянных сосудах?',
        answers: [
          'До одних суток',
          'До двух суток',
          'До трех суток',
          'До четырех суток',
        ],
        correctAnswers: ['До одних суток'],
      },
      {
        code: '63667074',
        text:
          'В соответствии с каким документом работникам необходимо соблюдать требования безопасности при загрузке и выгрузке катализаторов и адсорбентов?',
        answers: [
          'В соответствии с технологическим регламентом',
          'В соответствии с Правилами безопасности процессов получения или применения металлов',
          'В соответствии с техническим регламентом',
          'В соответствии с проектом',
        ],
        correctAnswers: ['В соответствии с технологическим регламентом'],
      },
      {
        code: '63667075',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Только со службой производственного контроля',
          'Только с начальником ГСС',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63667076',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 99,5%',
          'Не менее 99,2%',
          'Не менее 98,1%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63667077',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63667078',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63667079',
        text:
          'Кому должен сообщить мастер доменной печи или газовщик о подвисании и осадке шихты?',
        answers: [
          'Только диспетчеру газового хозяйства',
          'Только персоналу загрузки',
          'Только машинисту воздуходувной машины',
          'Всем перечисленным лицам',
        ],
        correctAnswers: ['Всем перечисленным лицам'],
      },
      {
        code: '63667080',
        text:
          'Что обязаны проверить сталевар и ответственные лица перед включением печи на плавку?',
        answers: [
          'Только исправность систем автоматизации',
          'Только исправность системы газоснабжения',
          'Исправность оборудования, футеровки и свода печи',
          'Только исправность систем водоснабжения',
        ],
        correctAnswers: ['Исправность оборудования, футеровки и свода печи'],
      },
      {
        code: '63667081',
        text:
          'Какие требования установлены к конусным и бесконусным засыпным аппаратам?',
        answers: [
          'Засыпные аппараты должны быть открытыми и рассчитаны на номинальное давление газа под колошником',
          'Засыпные аппараты должны быть закрытыми и рассчитаны на двукратное рабочее давление газа под колошником',
          'Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником',
        ],
        correctAnswers: [
          'Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником',
        ],
      },
      {
        code: '63667082',
        text:
          'До какой температуры должны быть охлаждены системы оборотного водоснабжения установок придоменной грануляции шлака для производства работ по их ремонту?',
        answers: [
          'Не выше 10 °C',
          'Не выше 45 °C',
          'Не выше 40 °C',
          'Не выше 55 °C',
        ],
        correctAnswers: ['Не выше 40 °C'],
      },
      {
        code: '63667083',
        text: 'Где допускается размещение операторных помещений?',
        answers: [
          'Только под приточными вентиляционными камерами',
          'Только под душевыми, санузлами',
          'Только над помещениями с взрывоопасными зонами любого класса',
          'Только под производственными помещениями с мокрым технологическим процессом',
          'Размещение операторных помещений не допускается во всех перечисленных местах',
        ],
        correctAnswers: [
          'Размещение операторных помещений не допускается во всех перечисленных местах',
        ],
      },
      {
        code: '63667084',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы должны производиться специально обученным цеховым обслуживающим персоналом',
          'Работы могут производиться только специалистами сторонней организации',
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63667085',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63667086',
        text: 'Каков допустимый износ цапф ковшей во всех измерениях?',
        answers: [
          'Не должен превышать 10% первоначальных размеров',
          'Не должен превышать 12% первоначальных размеров',
          'Не должен превышать 15% первоначальных размеров',
        ],
        correctAnswers: ['Не должен превышать 10% первоначальных размеров'],
      },
      {
        code: '63667087',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: [
          'Один раз в 2 года',
          'Один раз в год',
          'Один раз в 4 года',
          'Один раз в полгода',
        ],
        correctAnswers: ['Один раз в 2 года'],
      },
      {
        code: '63667088',
        text:
          'Какие требования установлены к выгрузке шихтовых материалов на рудном дворе?',
        answers: [
          'Выгрузка должна быть механизирована',
          'Выгрузка должна быть автоматизирована',
          'Выгрузка может быть организована только в дневное время',
        ],
        correctAnswers: ['Выгрузка должна быть механизирована'],
      },
      {
        code: '63667089',
        text:
          'Каким гидравлическим давлением должны быть испытаны на прочность и плотность трубки индуктора?',
        answers: [
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2,5 раза',
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза',
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2 раза',
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,25 раза',
        ],
        correctAnswers: [
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза',
        ],
      },
      {
        code: '63667090',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено на площадках, обеспечивающих свободный проход',
          'Должно быть предусмотрено в специальном помещении',
          'Должно быть предусмотрено в специальном помещении за пределами ОПО',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63667091',
        text:
          'Каково предельно допустимое содержание масла в кислороде, поступающем в компрессор?',
        answers: [
          'Не должно превышать 0,02 мг/м³',
          'Не должно превышать 0,03 мг/м³',
          'Не должно превышать 0,05 мг/м³',
        ],
        correctAnswers: ['Не должно превышать 0,02 мг/м³'],
      },
      {
        code: '63667092',
        text:
          'Каков предельно допустимый износ цапф конвертера во всех измерениях в случае применения подшипников скольжения?',
        answers: [
          'Не должен превышать 12% их первоначальных размеров',
          'Не должен превышать 10% их первоначальных размеров',
          'Не должен превышать 15% их первоначальных размеров',
        ],
        correctAnswers: ['Не должен превышать 10% их первоначальных размеров'],
      },
      {
        code: '63667093',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы',
          'Пластиковые шпалы',
          'Огнестойкие шпалы',
          'Любые из перечисленных видов шпал',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63667094',
        text:
          'При каком условии допускается осуществлять маневры со шлаковозными ковшами на постановочных путях? Выберите два варианта ответа.',
        answers: [
          'После согласования между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой',
          'При наличии светового разрешающего сигнала',
          'При наличии звукового разрешающего сигнала',
          'После согласования между ковшевым шлаковозных ковшей (диспетчером) и оператором',
        ],
        correctAnswers: [
          'После согласования между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой',
          'При наличии светового разрешающего сигнала',
        ],
      },
      {
        code: '63667095',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Только предохраняющие устройства, отключающие приводы при перегрузке',
          'Должно быть предусмотрено все перечисленное',
          'Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Только исключение падения транспортируемых материалов',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63667096',
        text: 'В каком случае не допускается прожигание корки чугунной летки?',
        answers: [
          'При длине кислородной трубки менее 2,5 м',
          'При длине кислородной трубки менее 2 м',
          'При длине кислородной трубки менее 3 м',
          'При длине кислородной трубки менее 5 м',
        ],
        correctAnswers: ['При длине кислородной трубки менее 2 м'],
      },
      {
        code: '63667097',
        text:
          'В каких случаях не допускается отключение системы охлаждения кристаллизатора?',
        answers: [
          'До охлаждения камеры до температуры 45 °С',
          'До выгрузки слитка из камеры',
          'До окончания процесса разгрузки камеры',
          'До полной остановки печи и установки заглушек на обвязочных трубопроводах',
        ],
        correctAnswers: ['До выгрузки слитка из камеры'],
      },
      {
        code: '63667098',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'Ресиверы размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'На ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,5 м',
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63667099',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 3 года',
          'Один раз в 6 лет',
          'Один раз в 10 лет',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63667100',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'Со службой производственного контроля',
          'С территориальным органом Ростехнадзора',
          'С главным энергетиком',
          'С ГСС',
          'С техническим директором',
        ],
        correctAnswers: ['Со службой производственного контроля', 'С ГСС'],
      },
      {
        code: '63667101',
        text:
          'Как часто должна проверяться надежность крепления головок электродов?',
        answers: [
          'Один раз в смену',
          'Правилами не регламентируется',
          'Один раз в сутки',
          'Один раз в неделю',
        ],
        correctAnswers: ['Правилами не регламентируется'],
      },
      {
        code: '63667102',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Инструкции по эксплуатации',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Инструкция по проведению пусконаладочных работ',
          'Проект производства работ',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63667103',
        text:
          'При каком давлении должна производиться настройка срабатывания отсекающих клапанов кислородно-распределительного (регуляторного) пункта (далее - КРП)?',
        answers: [
          'При давлении на 20% более расчетного и температуре 100 °С',
          'При давлении на 15% более расчетного и температуре 100 °С',
          'При давлении на 25% более расчетного и температуре 100 °С',
        ],
        correctAnswers: [
          'При давлении на 15% более расчетного и температуре 100 °С',
        ],
      },
      {
        code: '63667104',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна иметь шумоизоляцию и защищать машиниста от вредных производственных факторов',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
          'Должна иметь тонированные стекла и защищать машиниста от ультрафиолетового излучения',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63667105',
        text:
          'Что из перечисленного допускается при разливке чугуна на разливочных машинах?',
        answers: [
          'Нахождение людей в галереях, а также у чугуновозных ковшей во время проведения маневровых работ в здании разливочных машин',
          'Перелив чугуна в мульды',
          'Прожигание чугунной корки кислородом',
          'Кантовка ковшей со сплошной коркой чугуна или коркой, образовавшейся у носка ковша',
        ],
        correctAnswers: ['Прожигание чугунной корки кислородом'],
      },
      {
        code: '63667106',
        text:
          'Какая объемная доля водорода в техническом аргоне, поступающем на очистку в воздухоразделительную установку (далее - ВРУ)?',
        answers: [
          'Не должна превышать 3,0%',
          'Не должна превышать 4,0%',
          'Не должна превышать 2,5%',
        ],
        correctAnswers: ['Не должна превышать 2,5%'],
      },
      {
        code: '63667107',
        text:
          'Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?',
        answers: [
          'На 2,0 величины рабочего давления охлаждающей воды',
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63667108',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 10 лет',
          'Не позднее чем через 4 года',
          'Не позднее чем через 6 лет',
          'Не позднее чем через 5 лет',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63667109',
        text:
          'Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите все правильные ответы.',
        answers: [
          'Должностным инструкциям',
          'Производственной документации',
          'Требованиям Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
          'Требованиям Правил безопасности процессов получения или применения металлов',
        ],
        correctAnswers: [
          'Производственной документации',
          'Требованиям Правил безопасности процессов получения или применения металлов',
        ],
      },
      {
        code: '63667110',
        text:
          'Какие действия должны быть выполнены при возникновении в мартеновской и двухванной сталеплавильной печи бурных реакций? Выберите правильный вариант ответа.',
        answers: [
          'Только прекращена или уменьшена подача кислорода',
          'Только уменьшена тепловая нагрузка печи',
          'Должны быть выполнены все перечисленные действия одновременно',
        ],
        correctAnswers: [
          'Должны быть выполнены все перечисленные действия одновременно',
        ],
      },
      {
        code: '63667111',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из углеродистой стали',
          'Из коррозионностойкой стали или медных сплавов',
          'Из чугуна',
        ],
        correctAnswers: ['Из коррозионностойкой стали или медных сплавов'],
      },
      {
        code: '63667112',
        text:
          'При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 15% нижнего предела взрываемости (0,6% об.), кислорода менее 19% и более 23%, угарного газа более 25 мг/м³, метана не более 15% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 20% и более 25%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 25%, угарного газа более 30 мг/м³, метана не более 15% нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63667113',
        text:
          'Какая допускается предельная температура колошникового газа в случае задержки загрузки печи?',
        answers: [
          'Не должна превышать 500 °С',
          'Не должна превышать 700 °С',
          'Не должна превышать 800 °С',
          'Не должна превышать 600 °С',
        ],
        correctAnswers: ['Не должна превышать 500 °С'],
      },
      {
        code: '63667114',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Стальную',
          'Специальную для водорода',
          'Из высокопрочного чугуна',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63667115',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['1 час', '6 часов', '3 часа', '30 минут'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63667116',
        text:
          'С какой периодичностью должны производиться замеры температуры кожухов воздухонагревателя?',
        answers: [
          'Систематически (не реже одного раза в месяц)',
          'Систематически (не реже одного раза в два месяца)',
          'Систематически (не реже одного раза в квартал)',
          'Систематически (не реже одного раза в полугодие)',
        ],
        correctAnswers: ['Систематически (не реже одного раза в месяц)'],
      },
      {
        code: '63667117',
        text:
          'Как часто должно проверяться состояние системы охлаждения печей?',
        answers: ['Ежесменно', 'Два раза в смену', 'Ежесуточно', 'Еженедельно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63667118',
        text:
          'Что должно регулярно производиться в корпусах обезвоживания шлама?',
        answers: [
          'Механическое удаление шлама с рабочих площадок и строительных конструкций',
          'Влажная уборка рабочих площадок и строительных конструкций от пыли',
          'Гидросмыв шлама с рабочих площадок и строительных конструкций',
        ],
        correctAnswers: [
          'Гидросмыв шлама с рабочих площадок и строительных конструкций',
        ],
      },
      {
        code: '63667119',
        text:
          'Где должен находиться работник при ручной подправке металла на ножницах?',
        answers: [
          'Сзади ножниц',
          'Спереди ножниц',
          'Сбоку от ножниц',
          'Ручная подправка металла не допускается',
          'С любой стороны от ножниц',
        ],
        correctAnswers: ['Сбоку от ножниц'],
      },
      {
        code: '63667120',
        text:
          'Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в два года',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63667121',
        text: 'Как и кем должны проверяться состояние и исправность миксера?',
        answers: [
          'Должны ежесуточно проверяться руководителем подразделения',
          'Должны проверяться два раза в смену старшим по смене',
          'Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале',
        ],
        correctAnswers: [
          'Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале',
        ],
      },
      {
        code: '63667122',
        text:
          'Когда могут производиться ремонтные работы с открытым огнем в помещении с обращением водорода водородной станции?',
        answers: [
          'Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через 2 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через 3 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через час после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
        ],
        correctAnswers: [
          'Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
        ],
      },
      {
        code: '63667123',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?',
        answers: [
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации. Не допускается уборка, вызывающая распыление вредных веществ',
          'Все перечисленные',
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63667124',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 1,3 м',
          'Не менее 1,2 м',
          'Не менее 1,1 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63667125',
        text:
          'Какие требования для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода указаны неверно?',
        answers: [
          'Для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода должны быть установлены датчики довзрывных концентраций',
          'Количество и места расположения датчиков довзрывных концентраций должны определяться проектом',
          'Светозвуковая сигнализация должна иметь вывод на пульт оператора и в помещение пожарного депо предприятия',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63667126',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На плотность под рабочим давлением',
          'На прочность под рабочим давлением',
          'На целостность визуальным осмотром',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63667127',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С руководителем газоспасательной службы',
          'С техническим руководителем организации',
          'С Ростехнадзором',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63667128',
        text:
          'В присутствии какого лица должна производиться присадка легковоспламеняющихся порошковых материалов, а также смесей на их основе в печь или ковш?',
        answers: [
          'В присутствии сменного мастера',
          'В присутствии ответственного лица, назначенного распоряжением по цеху',
          'В присутствии бригадира',
          'В присутствии главного технолога',
        ],
        correctAnswers: [
          'В присутствии ответственного лица, назначенного распоряжением по цеху',
        ],
      },
      {
        code: '63667129',
        text:
          'При каком давлении должна производиться настройка срабатывания отсекающих клапанов КРП?',
        answers: [
          'При давлении на 20% более расчетного и температуре 100 °С',
          'При давлении на 15% более расчетного и температуре 100 °С',
          'При давлении на 25% более расчетного и температуре 100 °С',
        ],
        correctAnswers: [
          'При давлении на 15% более расчетного и температуре 100 °С',
        ],
      },
      {
        code: '63667130',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['1,8 м', '2 м', '1,5 м', '1,3 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63667131',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых металлических ящиках с крышками',
          'На специально оборудованной площадке',
          'В чистых пластмассовых ящиках с крышками',
          'В деревянных ящиках',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63667132',
        text:
          'Чем должен быть покрыт пол пульта управления электронно-лучевой печью?',
        answers: [
          'Электроизолирующим материалом, на который должно быть нанесено клеймо испытания',
          'Нескользящим материалом',
          'Деревянным покрытием',
          'Огнеупорной плиткой',
        ],
        correctAnswers: [
          'Электроизолирующим материалом, на который должно быть нанесено клеймо испытания',
        ],
      },
      {
        code: '63667133',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
        ],
        correctAnswers: [
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
        ],
      },
      {
        code: '63667134',
        text:
          'Какое допускается содержание кислорода в инертном газе, предназначенном для продувки водородной системы?',
        answers: ['Не более 1%', 'Не более 3%', 'Не более 5%', 'Не более 7%'],
        correctAnswers: ['Не более 1%'],
      },
      {
        code: '63667135',
        text: 'Как должна производиться установка мульд на стеллажах?',
        answers: [
          'С уклоном в сторону завалочной машины',
          'Без свесов и перекосов',
          'По центру стеллажа',
          'С уклоном в противоположную сторону от завалочной машины',
        ],
        correctAnswers: ['Без свесов и перекосов'],
      },
      {
        code: '63667136',
        text:
          'В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если выявлено нарушение условий отключения технических устройств',
          'Если изменился состав бригады',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
          'Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения ТУ и порядка выполнения работ',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63667137',
        text:
          'Какие требования к процессу получения водорода и диссоциированного аммиака указаны неверно?',
        answers: [
          'Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован',
          'Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности А',
          'Регулировка и контроль процесса получения водорода и диссоциированного аммиака производится с помощью приборов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности А',
        ],
      },
      {
        code: '63667138',
        text:
          'В каких помещениях предупреждающий и аварийный сигналы должны подаваться у входа вне помещения?',
        answers: [
          'В помещении с периодическим пребыванием персонала, где установлены датчики',
          'В помещении с постоянным пребыванием обслуживающего персонала (операторной)',
          'В коммуникационных помещениях',
          'В технико-эксплуатационных помещениях',
        ],
        correctAnswers: [
          'В помещении с периодическим пребыванием персонала, где установлены датчики',
        ],
      },
      {
        code: '63667139',
        text:
          'Какой должен быть общий объем инертного газа для нужд продувки водородной системы?',
        answers: [
          'Не менее 2 кратного объема системы',
          'Не менее 3 кратного объема системы',
          'Не менее 4 кратного объема системы',
          'Не регламентировано',
        ],
        correctAnswers: ['Не менее 4 кратного объема системы'],
      },
      {
        code: '63667140',
        text:
          'Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?',
        answers: [
          'Всем перечисленным',
          'Только автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов',
          'Только применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции',
          'Только заменой производственных процессов и операций, связанных с наличием вредных и (или) опасных производственных факторов, процессами и операциями, при которых указанные факторы отсутствуют или имеют меньшую интенсивность',
          'Только применением средств индивидуальной и коллективной защиты работников',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63667141',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 10 лет',
          'Один раз в 3 года',
          'Один раз в 8 лет',
          'Один раз в 6 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63667142',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?',
        answers: [
          'Из поролона или других органических материалов',
          'Из цветных металлов',
          'Из композита',
          'Из фторопласта',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63667143',
        text:
          'Сколько суток после наполнения должны выдерживаться баллоны с криптоноксеноновой смесью и криптоном в наполнительной комнате или складе баллонов?',
        answers: [
          'Не менее 10 суток',
          'Не менее 14 суток',
          'Не менее 12 суток',
          'Не менее 7 суток',
        ],
        correctAnswers: ['Не менее 14 суток'],
      },
      {
        code: '63667144',
        text: 'Какой срок должны храниться данные самопишущих приборов?',
        answers: [
          'Не менее 3 месяцев',
          'Не менее 2 месяцев',
          'Не менее месяца',
          'Не менее 12 месяцев',
        ],
        correctAnswers: ['Не менее 3 месяцев'],
      },
      {
        code: '63667145',
        text:
          'Каким образом не допускается определять утечки газов из соединений?',
        answers: [
          'С помощью мыльного раствора',
          'С помощью специальных течеискателей',
          'С помощью открытого пламени',
        ],
        correctAnswers: ['С помощью открытого пламени'],
      },
      {
        code: '63667146',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Окна с обычным оконным стеклом толщиной 3 мм и площадью 1 м²',
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
          'Фонарные переплеты',
          'Все перечисленные конструкции относятся к легкосбрасываемым',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м²',
        ],
      },
      {
        code: '63667147',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63667148',
        text: 'Как должна производиться заливка чугуна в миксер?',
        answers: [
          'В центр окна равномерной струей с минимальной высоты',
          'По стенке миксера равномерной струей с максимальной высоты',
          'Медленно в центр окна',
          'Порционно, через равные промежутки времени',
        ],
        correctAnswers: [
          'В центр окна равномерной струей с минимальной высоты',
        ],
      },
      {
        code: '63667149',
        text:
          'В каком случае разрешается эксплуатация азотопровода без обогрева в доменном производстве?',
        answers: [
          'Если объемная доля кислорода в азоте не превышает 3%',
          'Если азот подается в жидком состоянии',
          'Если подаваемый азот сухой',
          'Не разрешается ни в каком случае',
        ],
        correctAnswers: ['Если подаваемый азот сухой'],
      },
      {
        code: '63667150',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Только системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Только падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения',
          'Только повышением температуры над перевальной стенкой',
          'Только средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи, а при компоновке печных агрегатов с котлами-утилизаторами системами по переводу на работу агрегатов без дымососов',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63667151',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63667152',
        text: 'Чем должны быть ограждены грануляционные бассейны?',
        answers: [
          'Сплошным ограждением высотой не менее 0,9 м',
          'Перилами высотой не менее 1,1 м',
          'Стальными сетчатыми ограждениями высотой не менее 0,8 м',
          'Забором из профнастила высотой не менее 2 м',
        ],
        correctAnswers: ['Перилами высотой не менее 1,1 м'],
      },
      {
        code: '63667153',
        text:
          'Как часто должны производиться проверки приборов измерения уровня засыпи печи?',
        answers: [
          'Не реже двух раз в месяц',
          'Не реже одного раза в месяц',
          'Не реже двух раз в год',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже двух раз в месяц'],
      },
      {
        code: '63667154',
        text:
          'После выполнения каких мероприятий разрешается производить работы на своде мартеновской печи?',
        answers: [
          'Только после полной остановки печи',
          'Только после остановки печи на горячий ремонт',
          'Только после выпуска плавки и до заливки чугуна в печь',
          'Только после остановки печи на горячий ремонт и оформления наряда-допуска',
        ],
        correctAnswers: [
          'Только после выпуска плавки и до заливки чугуна в печь',
        ],
      },
      {
        code: '63667155',
        text:
          'Что должны обеспечивать конструкция и установка элементов фурменного прибора?',
        answers: [
          'Должны обеспечивать надежность',
          'Должны обеспечивать герметичность',
          'Должны обеспечивать доступность',
        ],
        correctAnswers: ['Должны обеспечивать герметичность'],
      },
      {
        code: '63667156',
        text:
          'Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?',
        answers: [
          'Скорость не должна превышать 10 км/ч',
          'Скорость не должна превышать 5 км/ч',
          'Скорость не должна превышать 6 км/ч',
        ],
        correctAnswers: ['Скорость не должна превышать 5 км/ч'],
      },
      {
        code: '63667157',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: ['Один раз в 4 года', 'Один раз в 2 года', 'Один раз в год'],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63667158',
        text:
          'Какие требования установлены к управлению стопорами и шиберными затворами ковшей?',
        answers: [
          'Управление должно быть радиоуправляемым',
          'Управление должно быть дистанционным',
          'Управление должно быть механизированным',
          'Управление должно быть автоматизированным',
        ],
        correctAnswers: ['Управление должно быть дистанционным'],
      },
      {
        code: '63667159',
        text:
          'С каким минимальным количеством независимых источников электроснабжения должно быть выполнено электропитание оборудования производства водорода?',
        answers: ['С двумя', 'С тремя', 'С четырьмя', 'С пятью'],
        correctAnswers: ['С двумя'],
      },
      {
        code: '63667160',
        text:
          'В каком случае проводится внеочередное обследование кожухов доменных печей и воздухонагревателей?',
        answers: [
          'Только при увеличении нормируемых проектных показателей и (или) климатических воздействий',
          'Только после аварии в цехе, связанной с огневым (пожар) или механическим (обрушение конструкций) воздействием на кожух печи',
          'Только при намечаемой реконструкции печи',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63667161',
        text:
          'Какие действия необходимо предпринять в случае вспенивания расплава?',
        answers: [
          'Снять пену специальным приспособлением, последующую заливку производить с уменьшенной скоростью',
          'Уменьшить скорость заливки без остановки процесса заливки восстановителя и загрузки твердой шихты',
          'Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки',
        ],
        correctAnswers: [
          'Заливка восстановителя и загрузка твердой шихты в расплав должны быть приостановлены с последующим уменьшением скорости заливки',
        ],
      },
      {
        code: '63667162',
        text: 'В каких случаях не допускается завалка лома в конвертер?',
        answers: [
          'При наличии в нем остатков сухого металла',
          'При наличии в нем жидкого шлака',
          'При наличии в нем сухого шлака',
        ],
        correctAnswers: ['При наличии в нем жидкого шлака'],
      },
      {
        code: '63667163',
        text:
          'Какие металлы допускается применять в помещениях, в которых происходит выделение в воздух паров ртути, в качестве конструкционных материалов строительных конструкций?',
        answers: ['Сталь', 'Чугун', 'Железо', 'Все перечисленные металлы'],
        correctAnswers: ['Все перечисленные металлы'],
      },
      {
        code: '63667164',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 0,7 м',
          'Не менее 0,6 м',
          'Не менее 0,75 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63667165',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите все правильные варианты ответа.',
        answers: [
          'Перед пуском',
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'После капитального ремонта',
          'После остановки более чем на 1 час',
        ],
        correctAnswers: [
          'Перед пуском',
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
        ],
      },
      {
        code: '63667166',
        text:
          'С какой периодичностью следует проверять состояние адсорбента в адсорбционных блоках осушки?',
        answers: [
          'Один раз в 3 месяца',
          'Один раз в год',
          'Два раза в год',
          'Один раз в 2 года',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63667167',
        text:
          'Чем должны быть оборудованы ванны с кислотой для травления оборудования?',
        answers: [
          'Системой подачи аргона',
          'Местными отсосами',
          'Всем перечисленным',
          'Укрытиями',
          'Системой нейтрализации стоков',
        ],
        correctAnswers: ['Местными отсосами'],
      },
      {
        code: '63667168',
        text:
          'С какой периодичностью оператору установки необходимо производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в 2 смены',
          'Не реже одного раза в неделю',
          'Не реже одного раза в декаду',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63667169',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении на 10% ниже величины, указанной в технологической инструкции',
          'Допускается эксплуатация печей при остаточном разрежении на 5% ниже величины, указанной в технологической инструкции',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
          'Эксплуатация печей возможна в любом случае',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63667170',
        text:
          'Какое из перечисленных требований при обслуживании электропечей указано верно? Выберите два варианта ответа.',
        answers: [
          'Во время работы газокислородной горелки крышка рабочего окна должна быть открыта',
          'Для защиты работников от брызг шлака в месте установки ковша или шлаковни под печью должны быть реализованы мероприятия по безопасности работников',
          'Переполнение ковшей или шлаковен шлаком должно осуществляться в соответствии с технологической инструкцией',
          'Шлаковни должны быть снабжены устройством для их транспортирования и кантовки',
        ],
        correctAnswers: [
          'Для защиты работников от брызг шлака в месте установки ковша или шлаковни под печью должны быть реализованы мероприятия по безопасности работников',
          'Шлаковни должны быть снабжены устройством для их транспортирования и кантовки',
        ],
      },
      {
        code: '63667171',
        text:
          'Куда может осуществляться сброс горячего воздуха, оставшегося в воздухонагревателе при переводе с дутья на нагрев, в зависимости от конструкции (проекта)? Выберите правильный вариант ответа.',
        answers: [
          'Только в боров дымовой трубы',
          'Только в другой воздухонагреватель',
          'Только в специальный глушитель',
          'Во все перечисленные места',
        ],
        correctAnswers: ['Во все перечисленные места'],
      },
      {
        code: '63667172',
        text:
          'Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?',
        answers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
          'Должны покрываться огнестойкими составами для защиты от воздействия тепловой нагрузки',
          'Должны защищаться специальным смазочным материалом, защищающим от воздействия лучистого тепла',
          'Должны защищаться тепловой изоляцией',
        ],
        correctAnswers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
        ],
      },
      {
        code: '63667173',
        text:
          'Какая объемная доля водорода в техническом аргоне, поступающем на очистку в ВРУ?',
        answers: [
          'Не должна превышать 2,5%',
          'Не должна превышать 3,0%',
          'Не должна превышать 4,0%',
        ],
        correctAnswers: ['Не должна превышать 2,5%'],
      },
      {
        code: '63667174',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.',
        answers: [
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Все перечисленные требования',
          'Сливание расплава металла из ковшей и миксеров должно быть механизировано',
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63667175',
        text:
          'Какие производственные помещения должны быть оборудованы системами связи и сигнализации? Выберите правильный вариант ответа.',
        answers: [
          'Только помещения, связанные с хранением водорода',
          'Только помещения, связанные с получением водорода',
          'Только помещения, связанные с применением водорода',
          'Все перечисленные помещения',
        ],
        correctAnswers: ['Все перечисленные помещения'],
      },
      {
        code: '63667176',
        text:
          'Как должен быть подготовлен газоотводящий тракт конвертера к ремонту?',
        answers: [
          'Должен быть надежно отключен от общих коллекторов и боровов',
          'Должен быть продут азотом',
          'Должен быть тщательно очищен от отложений',
          'Должен быть пропарен в течение 2 часов',
        ],
        correctAnswers: [
          'Должен быть надежно отключен от общих коллекторов и боровов',
        ],
      },
      {
        code: '63667177',
        text:
          'На каком расстоянии не допускается производство работ на путях от стоящих под наливом ковшей?',
        answers: [
          'На расстоянии ближе 15 м',
          'На расстоянии ближе 20 м',
          'На расстоянии ближе 17 м',
        ],
        correctAnswers: ['На расстоянии ближе 15 м'],
      },
      {
        code: '63667178',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь ограждение только со стороны железнодорожных путей',
          'Со всех сторон должны иметь ограждение',
          'Должны иметь вдоль периметра пешеходные проходы шириной не менее 1,0 м',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63667179',
        text:
          'Какое максимальное значение не должна превышать объемная доля кислорода в азоте, подаваемом на продувку трубопроводов кислорода и газокислородной смеси, в доменном производстве?',
        answers: ['0,03', '0,05', '0,04', '0,02'],
        correctAnswers: ['0,03'],
      },
      {
        code: '63667180',
        text:
          'Какое количество люков должно устраиваться на газовых затворах с цилиндрической вставкой для устойчивого горения газа при проверке засыпного материала?',
        answers: ['Три люка', 'Один люк', 'Два люка', 'Не регламентируется'],
        correctAnswers: ['Два люка'],
      },
      {
        code: '63667181',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Образование взрывоопасной среды',
          'Проверка системы на плотность под рабочим давлением перед вводом в эксплуатацию',
          'Герметичность коммуникаций системы',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63667182',
        text:
          'Из каких материалов должны быть выполнены здания литейных дворов и поддоменников?',
        answers: [
          'Из любых строительных материалов',
          'Из огнестойких материалов',
          'Из влагостойких материалов',
          'Из деревянного бруса',
        ],
        correctAnswers: ['Из огнестойких материалов'],
      },
      {
        code: '63667183',
        text:
          'Какое из перечисленных требований к отделению перелива чугуна указано неверно?',
        answers: [
          'Слив чугуна должен производиться в центр ковша равномерной струей',
          'До отцепки локомотива под колеса чугуновоза с обеих сторон должны быть установлены железнодорожные тормозные башмаки',
          'Во время слива чугуна в заливочный ковш нахождение работников в опасной зоне не допускается',
          'Сливать чугун из чугуновозного ковша с застывшими остатками чугуна допускается в соответствии с производственной инструкцией',
        ],
        correctAnswers: [
          'Сливать чугун из чугуновозного ковша с застывшими остатками чугуна допускается в соответствии с производственной инструкцией',
        ],
      },
      {
        code: '63667184',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Кислородом', 'Сжатым воздухом', 'Газом', 'Водяным паром'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63667185',
        text:
          'В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?',
        answers: [
          'Не менее 10 секунд',
          'Не менее 3 секунд',
          'Не менее 7 секунд',
          'Не менее 5 секунд',
        ],
        correctAnswers: ['Не менее 10 секунд'],
      },
      {
        code: '63667186',
        text:
          'При каком максимальном давлении в межконусном пространстве загрузочного устройства доменной печи осуществляется открытие малого конуса?',
        answers: [
          '0,01 МПа (0,1 кгс/см²)',
          '0,015 МПа (0,15 кгс/см²)',
          '0,025 МПа (0,25 кгс/см²)',
          '0,03 МПа (0,3 кгс/см²)',
        ],
        correctAnswers: ['0,015 МПа (0,15 кгс/см²)'],
      },
      {
        code: '63667187',
        text:
          'Какие требования установлены к сцепке и расцепке сталевозной тележки?',
        answers: [
          'Должны быть дистанционными',
          'Должны осуществляться сцепщиком при помощи приспособления',
          'Должны быть автоматическими',
          'Должны быть механизированы',
        ],
        correctAnswers: ['Должны быть дистанционными'],
      },
      {
        code: '63667188',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в 3 года',
          'Ежегодно',
          'Один раз в 2 года',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63667189',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Не должны давать искры при работе с ними',
          'Должны быть изготовлены из стали',
          'Должны быть изготовлены из чугуна',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63667190',
        text: 'Что должно исключаться при присадке раскислителей в ковш?',
        answers: [
          'Повреждение стопорного устройства',
          'Распыление раскислителей',
          'Передозировка раскислителей',
          'Механизация процесса',
        ],
        correctAnswers: ['Повреждение стопорного устройства'],
      },
      {
        code: '63667191',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Емкости с песком, огнетушители',
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Брезентовые ширмы',
          'Автоматическую установку пожаротушения',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63667192',
        text:
          'Какое испытательное давление должно приниматься для участка газопровода чистого газа от дроссельной группы до листовой задвижки, где P2 - расчетное избыточное давление (по проекту) газовой среды на участке, МПа?',
        answers: [
          '2,5P2, но не менее 0,45 МПа',
          '1,25P2, но не менее 0,05 МПа',
          '1,5P2, но не менее 0,1 МПа',
          '1,75P2, но не менее 0,25 МПа',
        ],
        correctAnswers: ['1,25P2, но не менее 0,05 МПа'],
      },
      {
        code: '63667193',
        text:
          'Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?',
        answers: [
          'Должно быть выше верхних площадок колошника не менее чем на 2 м',
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
          'Должно быть выше верхних площадок колошника не менее чем на 3 м',
        ],
        correctAnswers: [
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
        ],
      },
      {
        code: '63667194',
        text:
          'Чем должна быть продута горелка перед включением и подачей газа?',
        answers: ['Кислородом', 'Сжатым воздухом', 'Азотом', 'Паром'],
        correctAnswers: ['Кислородом'],
      },
      {
        code: '63667195',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'Помещения оборудуются механической приточно-вытяжной общеобменной вентиляцией из верхней зоны через короб в объеме не менее двукратного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'При обосновании допускается устройство естественной вытяжной вентиляцией с кратностью воздухообмена не менее 2 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 6 в час',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63667196',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'Акт-допуск должен оформляться письменно',
          'На один объект, площадку, территорию должен оформляться один акт-допуск',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63667197',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63667198',
        text:
          'Какие из перечисленных требований при ведении работ на объектах производства водорода указаны неверно?',
        answers: [
          'Работники на объектах производства водорода должны быть обеспечены СИЗ, спецодеждой, спецобувью и другими средствами, которые должны отвечать требованиям соответствующих стандартов безопасности труда',
          'Открытые движущиеся части машин и механизмов, а также ременные, цепные и зубчатые передачи должны быть снабжены ограждениями',
          'Узлы, детали, приспособления и элементы оборудования, которые могут служить источником опасности для работающих, а также поверхности оградительных и защитных устройств должны быть окрашены в сигнальные цвета',
          'Входить на объекты с производством и обращением водорода необходимо в обуви с железными набойками, а также в одежде, способной проводить заряды статического электричества',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Входить на объекты с производством и обращением водорода необходимо в обуви с железными набойками, а также в одежде, способной проводить заряды статического электричества',
        ],
      },
      {
        code: '63667199',
        text:
          'Какие требования к цинкованию металлов и полимерным покрытиям указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Все операции по транспортированию и обработке проката при нанесении защитных покрытий, а также вспомогательные операции (смена анодов в ваннах электролитического лужения и оцинкования, загрузка металла в ванну с расплавом, приготовление, подача и очистка растворов) должны быть механизированы и выполняться в соответствии с технологическими инструкциями',
          'Все перечисленные требования указаны верно',
          'Нанесение защитных покрытий на листовой металл (лужение, оцинкование) следует производить на установках непрерывного действия',
          'Оборудование для нанесения защитных покрытий (полимерных материалов) должно иметь местные отсосы',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63667200',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['100 °C', '90 °C', '85 °C', '95 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63667201',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Количество производителей (руководителей) работ определяет выдающий наряд-допуск',
          'Один производитель (руководитель) работ',
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63667202',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Открытым пламенем',
          'Горячим воздухом, паром или горячей водой',
          'Электрическими приборами',
          'Прокачкой легкими нефтепродуктами',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63667203',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Действия регламентируются выдающим наряд-допуск',
          'Работы должны быть продолжены. Оформление нового наряда-допуска не требуется',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
          'Работы должны быть прекращены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63667204',
        text:
          'В каком случае допускается подъем ковша краном при зацепленном крюке для кантовки в сталеплавильном производстве?',
        answers: [
          'В случае наличия сигнальной связи',
          'В случае соблюдения дополнительных мер безопасности',
          'Если присутствует наблюдающий',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63667205',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Только в аварийных случаях',
          'Не разрешено',
          'Разрешено при увеличении давления газа до 10%',
          'Разрешено только при испытании газопроводов',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63667206',
        text:
          'Какое минимальное количество выходов допускается в помещении операторной, расположенном на первом этаже?',
        answers: ['Один', 'Два', 'Три', 'Четыре'],
        correctAnswers: ['Один'],
      },
      {
        code: '63667207',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства продуктов разделения воздуха (далее – ПРВ)?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 18% и не более 23%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63667208',
        text:
          'В каком случае должна быть обеспечена двусторонняя радиосвязь для машиниста с мастером (бригадиром) загрузки доменных печей?',
        answers: [
          'Только при управлении вагоноопрокидывателем',
          'Только при управлении перегрузочным краном',
          'Только при управлении трансферкаром',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63667209',
        text: 'Какие требования установлены к оснащению колошниковой площадки?',
        answers: [
          'Должна быть освещена круглосуточно и ограждена перилами высотой не менее 1,0 м со сплошной зашивкой стальными листами',
          'Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами',
          'Должна быть освещена в ночное время и ограждена перилами высотой не менее 0,8 м со сплошной зашивкой стальными листами',
        ],
        correctAnswers: [
          'Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами',
        ],
      },
      {
        code: '63667210',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Ежедневно', '1 раз в квартал', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63667211',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Двусторонняя громкоговорящая связь с диспетчерской службой производства',
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63667212',
        text:
          'Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?',
        answers: [
          'Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла',
          'Работы должны выполняться только во время плавления шихты, при образовании значительного количества жидкого металла',
          'Работы должны выполняться после выпуска металла',
          'Работы должны выполняться ежесменно после каждого полного цикла плавления',
        ],
        correctAnswers: [
          'Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла',
        ],
      },
      {
        code: '63667213',
        text:
          'При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?',
        answers: [
          'При увеличении до 3%',
          'При увеличении до 1%',
          'При увеличении до 2%',
        ],
        correctAnswers: ['При увеличении до 2%'],
      },
      {
        code: '63667214',
        text:
          'Какое из перечисленных требований к отводу шлакового желоба на резервную линию установки придоменной грануляции шлака указано верно?',
        answers: [
          'Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не менее 0,5 м засыпан песком на 100 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не менее 1,5 м засыпан песком на 200 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 300 мм выше бортов желоба',
        ],
        correctAnswers: [
          'Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба',
        ],
      },
      {
        code: '63667215',
        text:
          'Что должно предусматриваться для обеспечения требуемых режимов эксплуатации установки по производству водорода? Выберите правильный вариант ответа.',
        answers: [
          'Только управление и контроль всех технологических параметров оборудования из операторной',
          'Только дистанционное (аварийное) отключение установки по производству водорода',
          'Только дистанционный контроль оборудования из операторной',
          'Только автоматическое отключение установки по производству водорода при нарушении нормальной работы по технологическим параметрам',
          'Должно предусматриваться все перечисленное',
        ],
        correctAnswers: ['Должно предусматриваться все перечисленное'],
      },
      {
        code: '63667216',
        text:
          'Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?',
        answers: ['Не более двух', 'Не более трех', 'Не более четырех'],
        correctAnswers: ['Не более двух'],
      },
      {
        code: '63667217',
        text:
          'В каком случае осуществляется доливка чугуна в мартеновские и двухванные сталеплавильные агрегаты?',
        answers: [
          'В исключительных случаях согласно технологической инструкции',
          'В исключительных случаях согласно производственной инструкции',
          'В исключительных случаях согласно должностной инструкции',
          'В исключительных случаях по решению ответственного за производство работ',
          'Не осуществляется ни в каком случае',
        ],
        correctAnswers: [
          'В исключительных случаях согласно технологической инструкции',
        ],
      },
      {
        code: '63667218',
        text:
          'Где должны регистрироваться расходы природного газа и кислорода, подаваемых на смешение, в доменном производстве?',
        answers: [
          'В оперативном журнале работы доменной печи',
          'В цеховом журнале осмотра и ремонта аппаратуры и оборудования',
          'В ежесменном отчете',
          'В эксплуатационном паспорте доменной печи',
        ],
        correctAnswers: ['В оперативном журнале работы доменной печи'],
      },
      {
        code: '63667219',
        text:
          'На основании какого документа должна определяться периодичность измерения напряженности и плотности потока энергии электромагнитных полей на рабочих местах при обслуживании электропечей?',
        answers: [
          'Производственной документации',
          'Проектной документации',
          'Правил безопасности процессов получения или применения металлов',
          'Приказа технического руководителя',
        ],
        correctAnswers: ['Производственной документации'],
      },
      {
        code: '63667220',
        text:
          'В период выполнения ремонтных работ с каким сроком допускается загрузка шихты в печь при вышедшем из строя вращающемся распределителе шихты?',
        answers: [
          'Не более 5 часов',
          'Не более 8 часов',
          'Не более 4 часов',
          'Не более 6 часов',
        ],
        correctAnswers: ['Не более 4 часов'],
      },
      {
        code: '63667221',
        text:
          'Чем должны быть перекрыты приемные бункера грануляционных установок?',
        answers: [
          'Предохранительными решетками с ячейками не более 300 х 300 мм',
          'Предохранительными решетками с ячейками не более 250 х 250 мм',
          'Предохранительными решетками с ячейками не более 200 х 200 мм',
        ],
        correctAnswers: [
          'Предохранительными решетками с ячейками не более 200 х 200 мм',
        ],
      },
      {
        code: '63667222',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежегодно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63667223',
        text:
          'На какую величину с возвышением внешнего рельса со стороны слива по отношению к другому должны укладываться пути для шлака на отвале?',
        answers: [
          'Не более 180 мм',
          'Не более 150 мм',
          'Не более 200 мм',
          'Не более 250 мм',
        ],
        correctAnswers: ['Не более 150 мм'],
      },
      {
        code: '63667224',
        text:
          'Какое допускается максимальное давление кислорода при подаче в УГКС кислорода?',
        answers: [
          '2,5 МПа (25 кгс/см²)',
          '2,0 МПа (20 кгс/см²)',
          '1,6 МПа (16 кгс/см²)',
          '1,8 МПа (18 кгс/см²)',
        ],
        correctAnswers: ['1,6 МПа (16 кгс/см²)'],
      },
      {
        code: '63667225',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Должны осуществляться только в специально выделенном для этого помещении',
          'Могут производиться непосредственно во взрывопожароопасных и пожароопасных помещениях',
          'Должны производиться в условиях, исключающих искрообразование',
          'Должны производиться в условиях повышенной влажности',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63667226',
        text:
          'В какой сталеплавильный агрегат завалка металлической стружки не допускается?',
        answers: [
          'В двухванный',
          'В мартеновскую печь',
          'В электродуговую печь',
          'В конвертер',
        ],
        correctAnswers: ['В двухванный'],
      },
      {
        code: '63667227',
        text:
          'Чем должен производиться нагрев воздухонагревателей в доменном производстве?',
        answers: [
          'Водяным паром',
          'Очищенным газом',
          'Горячей водой',
          'Горячим воздухом',
        ],
        correctAnswers: ['Очищенным газом'],
      },
      {
        code: '63667228',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть предварительно увлажнены',
          'Должны быть выдержаны в помещении не менее суток',
          'Должны быть предварительно просушены или прокалены',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63667229',
        text:
          'Что должно быть у каждого плавильного агрегата с выпуском металла через летку?',
        answers: [
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
          'Должны быть три штанги длиной не менее 2,5 м и запасные пробки для закрывания леток',
          'Должны быть две штанги длиной не менее 2 м и запасные пробки для закрывания леток',
        ],
        correctAnswers: [
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
        ],
      },
      {
        code: '63667230',
        text: 'Чем должны быть оснащены доменные печи?',
        answers: [
          'Системой дистанционного контроля',
          'Автоматизированной системой контроля, управления и диагностики технологического процесса',
          'Системой аварийной остановки',
        ],
        correctAnswers: [
          'Автоматизированной системой контроля, управления и диагностики технологического процесса',
        ],
      },
      {
        code: '63667231',
        text:
          'Какое значение не должна превышать объемная доля кислорода в смеси с природным газом при использовании газокислородных смесей в доменном производстве?',
        answers: ['0,5', '0,4', '0,35', '0,25'],
        correctAnswers: ['0,25'],
      },
      {
        code: '63667232',
        text:
          'Чем должны быть защищены опорные колонны печи здания литейного двора и поддоменника от возможного воздействия чугуна и шлака?',
        answers: [
          'Должны быть защищены огнеупорным материалом',
          'Должны быть защищены сетчатыми ограждениями',
          'Должны быть защищены пластиковыми плитами',
        ],
        correctAnswers: ['Должны быть защищены огнеупорным материалом'],
      },
      {
        code: '63667233',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 18% и не более 23%',
          'Не менее 19% и не более 23%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63667234',
        text:
          'Эксплуатация какого из перечисленного оборудования не допускается в доменном производстве при наличии трещин? Выберите правильный вариант ответа.',
        answers: [
          'Только газоотводов доменных печей',
          'Только пылеуловителей доменных печей',
          'Только газопроводов доменных печей',
          'Всего перечисленного оборудования',
        ],
        correctAnswers: ['Всего перечисленного оборудования'],
      },
      {
        code: '63667235',
        text:
          'При какой температуре поверхности воздухопроводов горячего дутья принимаются меры по устранению причин, приведших к их перегреву?',
        answers: [
          'Свыше 100 ­°С',
          'Свыше 120 ­°С',
          'Свыше 150 ­°С',
          'Свыше 200 ­°С',
        ],
        correctAnswers: ['Свыше 200 ­°С'],
      },
      {
        code: '63667236',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'В зоне погрузки грейферными или магнитными кранами',
          'На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'На расстоянии 20 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания',
          'Во всех перечисленных зонах',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63667237',
        text:
          'Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя',
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63667238',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 6 месяцев',
          'В течение 3 месяцев',
          'В течение месяца',
          'В течение года',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63667239',
        text:
          'Какой должна быть продолжительность проветривания воздухонагревателя перед повторным зажиганием газа и перед постановкой его на дутьевой режим?',
        answers: [
          'Не менее пяти минут',
          'Не менее одной минуты',
          'Не менее восьми минут',
          'Не менее десяти минут',
        ],
        correctAnswers: ['Не менее одной минуты'],
      },
      {
        code: '63667240',
        text:
          'При какой температуре возможно вскрытие реакторов каталитического гидрирования?',
        answers: [
          'После остывания их до температуры 40 °C',
          'После остывания их до температуры 45 °C',
          'После остывания их до температуры 50 °C',
        ],
        correctAnswers: ['После остывания их до температуры 40 °C'],
      },
      {
        code: '63667241',
        text:
          'Какое из перечисленных требований к воздухонагревателям в доменном производстве указано неверно?',
        answers: [
          'Площадки со стороны щели должны ограждаться сплошной отбортовкой на высоту не менее 0,5 м',
          'Ширина кольцевой щели между кожухом и площадками обслуживания, а также между вертикальным газопроводом, пересекающим площадку, и площадкой должна быть не менее 50 мм',
          'Помещения КИПиА воздухонагревателей должны иметь принудительную приточно-вытяжную вентиляцию с подогревом подаваемого воздуха в зимнее время',
          'Конструкция подземных боровов воздухонагревателей должна исключать возможность попадания в них грунтовых вод',
        ],
        correctAnswers: [
          'Площадки со стороны щели должны ограждаться сплошной отбортовкой на высоту не менее 0,5 м',
        ],
      },
      {
        code: '63667242',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,05 МПа',
          'Не менее 0,03 МПа',
          'Не менее 0,35 МПа',
          'Не менее 0,01 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63667243',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения, работающие от напряжения 36 В',
          'Должны предусматриваться системы освещения, работающие от напряжения 24 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63667244',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 35% нижнего предела взрываемости (1,4% об.)',
          'Выше 25% нижнего предела взрываемости (1% об.)',
          'Выше 40% нижнего предела взрываемости (1,6% об.)',
          'Выше 30% нижнего предела взрываемости (1,2% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63667245',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии положительного заключения экспертного обследования',
          'При наличии разрешения завода-изготовителя',
          'При наличии письменного разрешения руководителя предприятия',
          'При наличии разрешения проектной организации',
          'При наличии разрешения Ростехнадзора',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63667246',
        text:
          'С какой периодичностью отмечаются все изменения в состоянии кожуха и холодильников печи на схеме расположения холодильников, составляемой на каждую доменную печь?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63667247',
        text:
          'Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?',
        answers: ['Менее 100 м', 'Менее 150 м', 'Менее 200 м', 'Менее 130 м'],
        correctAnswers: ['Менее 100 м'],
      },
      {
        code: '63667248',
        text:
          'Какое количество выходов должен иметь поддоменник, не считая выхода на литейный двор?',
        answers: [
          'Не менее одного выхода',
          'Не менее чем два выхода',
          'Не менее чем три выхода',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее чем два выхода'],
      },
      {
        code: '63667249',
        text:
          'Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?',
        answers: [
          'Нагрузка на печь должна быть снижена',
          'Печь должна быть немедленно отключена',
          'Подача воды должна быть немедленно отключена',
          'Печь должна быть переведена на циркуляцию по топливу',
        ],
        correctAnswers: ['Печь должна быть немедленно отключена'],
      },
      {
        code: '63667250',
        text:
          'Что должны обеспечивать системы охлаждения горна и лещади доменной печи?',
        answers: [
          'Должны обеспечивать перепад температуры не более 3 °С',
          'Должны обеспечивать перепад температуры не более 4 °С',
          'Должны обеспечивать перепад температуры не более 5 °С',
          'Должны обеспечивать перепад температуры не более 7 °С',
        ],
        correctAnswers: [
          'Должны обеспечивать перепад температуры не более 3 °С',
        ],
      },
      {
        code: '63667251',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после пропарки и снижения температуры воздуха в них до 55 °C',
          'Проведение ремонтных работ разрешается после продувки и снижения температуры воздуха в них до 50 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63667252',
        text: 'Какие требования к цинкованию металлов указаны неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'Порядок загрузки блоков цинка в ванну цинкования определяется проектом. При необходимости разрабатываются дополнительные меры для безопасности персонала',
          'Срок эксплуатации ванн цинкования определяется проектной документацией завода-изготовителя',
          'Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования',
          'Электропроводка на ваннах цинкования должна быть защищена от возможного повреждения их горячим металлом',
        ],
        correctAnswers: [
          'Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования',
        ],
      },
      {
        code: '63667253',
        text:
          'Какая допускается максимальная погрешность (точность измерения) газоанализаторов водорода?',
        answers: [
          '+/- 0,2% об.',
          '+/- 0,5% об.',
          '+/- 0,7% об.',
          '+/- 1,0% об.',
        ],
        correctAnswers: ['+/- 0,2% об.'],
      },
      {
        code: '63667254',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'Только строительных норм и правил',
          'Только нормативно-технической документации для технологических стальных трубопроводов',
          'Только правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63667255',
        text:
          'После выполнения каких мероприятий должна производиться заливка чугуна в печь?',
        answers: [
          'Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей',
          'Только после полного закрытия сталевыпускного отверстия',
          'После отключения горелок',
          'После полной остановки печи',
        ],
        correctAnswers: [
          'Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей',
        ],
      },
      {
        code: '63667256',
        text:
          'Как часто должна производиться проверка состояния механизма поворота конвертера?',
        answers: [
          'Ежесменно',
          'Ежедневно',
          'После каждой плавки',
          'Еженедельно',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63667257',
        text:
          'На каком расстоянии от путей и натяжного троса допускается нахождение людей при транспортировании тележек с изложницами или ковшами, наполненными металлом или шлаком?',
        answers: ['Не ближе 2 м', 'Не ближе 3 м', 'Не ближе 5 м'],
        correctAnswers: ['Не ближе 5 м'],
      },
      {
        code: '63667258',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Только дистанционное отключение',
          'Только установка на линиях всасывания и нагнетания запорных или отсекающих устройств',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63667259',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: [
          'Из чугуна',
          'Из стали',
          'Из сплавов титана',
          'Из сплавов меди и латуни',
        ],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63667260',
        text:
          'Какой сигнал должен быть подан на пост управления миксером о правильности установки ковша под сливным носком перед началом слива чугуна из миксера?',
        answers: [
          'Звуковой',
          'Световой',
          'По телефону, радио',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63667261',
        text:
          'Чем допускается тушить пламя при возгорании водорода? Выберите два варианта ответа.',
        answers: ['Песком', 'Кошмой', 'Водой', 'Огнетушителями'],
        correctAnswers: ['Песком', 'Кошмой'],
      },
      {
        code: '63667262',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63667263',
        text:
          'Какие требования установлены к раздеванию слитков с помощью напольных машин или кранов?',
        answers: [
          'Необходимо осуществлять в специально выделенном помещении после образования твердой корки',
          'Необходимо осуществлять только после полного затвердевания слитков',
          'Необходимо осуществлять после остывания слитков до температуры не выше 300 °С',
          'Необходимо осуществлять после остывания слитков до температуры не выше 60 °С',
        ],
        correctAnswers: [
          'Необходимо осуществлять только после полного затвердевания слитков',
        ],
      },
      {
        code: '63667264',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?',
        answers: ['0,4 кПа', '0,3 кПа', '0,5 кПа', '0,2 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63667265',
        text:
          'В соответствии с каким документом должно производиться опускание в печь застрявших на малом и большом конусах шихтовых материалов?',
        answers: [
          'В соответствии с технологической инструкцией',
          'В соответствии с Правилами безопасности процессов получения или применения металлов',
          'В соответствии с должностной инструкцией',
          'В соответствии с нарядом-допуском',
        ],
        correctAnswers: ['В соответствии с технологической инструкцией'],
      },
      {
        code: '63667266',
        text:
          'В каком случае заправка мартеновских печей и двухванных сталеплавильных агрегатов не допускается?',
        answers: [
          'При включенных защитных блокировках',
          'При открытом отверстии в площадке для спуска шлака',
          'При сокращении интенсивности продувки до значений, установленных технологической инструкцией',
          'При закрытом отверстии в площадке для спуска шлака',
        ],
        correctAnswers: ['При открытом отверстии в площадке для спуска шлака'],
      },
      {
        code: '63667267',
        text:
          'Какое из перечисленных требований к завалке материалов в печи, конвертеры указано верно? Выберите правильный вариант ответа.',
        answers: [
          'В цехах, где подача мульд на стеллажи балкона рабочей площадки производится при помощи цепей с крюками, одновременная установка мульд на стеллажи краном и взятие их со стеллажей завалочной машиной не допускаются',
          'Во избежание повреждения подины и разлета кусков шихтовых материалов открывать запорный механизм бадьи нужно в соответствии с технологической инструкцией',
          'Перед включением привода выдвижной площадки или привода подъема и отворота свода сталевар обязан убедиться, что все работающие отошли от печи на безопасное расстояние',
          'Перед началом завалки должен производиться осмотр подины печи',
          'Все перечисленные требования верны',
        ],
        correctAnswers: ['Все перечисленные требования верны'],
      },
      {
        code: '63667268',
        text:
          'На сколько давление природного газа на входе в УГКС должно быть выше расчетного давления в фурменной зоне доменной печи?',
        answers: [
          'Не менее чем на 0,1 МПа (1 кгс/см²)',
          'Не менее чем на 0,2 МПа (2 кгс/см²)',
          'Не менее чем на 0,05 МПа (0,5 кгс/см²)',
          'Не менее чем на 0,08 МПа (0,8 кгс/см²)',
        ],
        correctAnswers: ['Не менее чем на 0,2 МПа (2 кгс/см²)'],
      },
      {
        code: '63667269',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В специальный журнал по устранению дефектов',
          'В паспорт оборудования',
          'В вахтовый журнал',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63667270',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Выдающий наряд-допуск',
          'Лицо, ответственное за осуществление производственного контроля',
          'Технический руководитель организации',
          'Допускающий к работе',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63667271',
        text: 'Как должна производиться разделка сталевыпускного отверстия?',
        answers: [
          'Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами',
          'Должна производиться только искробезопасным инструментом',
          'Должна производиться под руководством мастера смены',
        ],
        correctAnswers: [
          'Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами',
        ],
      },
      {
        code: '63667272',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В непосредственной близости от агрегатов',
          'На расстоянии не менее 5 м от агрегатов',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63667273',
        text:
          'Как часто должна производиться проверка настройки отсечных и предохранительных клапанов?',
        answers: [
          'Не реже одного раза в два месяца, а также после каждой ревизии и ремонта',
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца, а также после каждой ревизии и ремонта',
        ],
        correctAnswers: [
          'Не реже одного раза в два месяца, а также после каждой ревизии и ремонта',
        ],
      },
      {
        code: '63667274',
        text:
          'Какие установлены требования к хранению материалов для приготовления формовочных смесей?',
        answers: [
          'Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений',
          'Должны храниться в специально отведенных местах цеха',
          'Должны храниться в отдельных помещениях в герметичной таре',
          'Должны храниться в смежных с производственными участками помещениях',
        ],
        correctAnswers: [
          'Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений',
        ],
      },
      {
        code: '63667275',
        text:
          'Какая должна быть температура в помещениях в холодное время года, там, где осуществляются мокрые производственные процессы?',
        answers: ['Не ниже 10 °C', 'Не ниже 14 °C', 'Не ниже 16 °C'],
        correctAnswers: ['Не ниже 16 °C'],
      },
      {
        code: '63667276',
        text:
          'Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?',
        answers: [
          'Технологической инструкцией',
          'Инструкцией о мерах пожарной безопасности при проведении огневых работ',
          'Должностной инструкцией',
          'Инструкцией по проведению газоопасных работ',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63667277',
        text:
          'Какие требования к внепечной обработке жидкого металла указаны неверно?',
        answers: [
          'Ввод раскислителей и легирующих материалов в ковш с жидким металлом под вакуумом должен осуществляться через вакуумный шлюз, конструкция которого должна обеспечивать сохранение вакуума в системе',
          'Агрегаты внепечной обработки жидкого металла должны быть оборудованы системой управления, обеспечивающей функционирование механизмов в безопасных режимах и автоматическое отключение агрегатов при отклонении контролируемых параметров от заданных',
          'Высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций, определяется производственной документацией',
          'Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией',
        ],
        correctAnswers: [
          'Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией',
        ],
      },
      {
        code: '63667278',
        text:
          'До какой температуры должен быть нагрет верх насадок газового регенератора во избежание хлопков и взрывов при пуске газа в мартеновскую печь?',
        answers: [
          'До температуры 600 °C - 650 °C',
          'До температуры 500 °C - 550 °C',
          'До температуры 700 °C - 750 °C',
          'До температуры 300 °C - 350 °C',
        ],
        correctAnswers: ['До температуры 700 °C - 750 °C'],
      },
      {
        code: '63667279',
        text:
          'Какие требования предъявляются к предохранительным решеткам бункера?',
        answers: [
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 400 х 400 мм',
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 400 мм',
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм',
        ],
        correctAnswers: [
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм',
        ],
      },
      {
        code: '63667280',
        text:
          'При каких условиях осуществляется пользование механизмами передвижения ковшей?',
        answers: [
          'Только при исправной световой сигнализации',
          'Только при исправной звуковой сигнализации',
          'Только при исправной звуковой и световой сигнализации',
          'Только при исправном аварийном освещении',
        ],
        correctAnswers: [
          'Только при исправной звуковой и световой сигнализации',
        ],
      },
      {
        code: '63667281',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через бытовые, подсобные, административно-хозяйственные, складские помещения',
          'Через вентиляционные камеры, помещения КИП, лестничные клетки, пути эвакуации',
          'Через электромашинные, электрораспределительные, трансформаторные помещения',
          'Через производственные помещения, связанные с потреблением водорода',
          'Прокладка водородопроводов через все перечисленные помещения не допускается',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63667282',
        text:
          'Какие требования установлены к материалам, используемым для приготовления формовочных и стержневых смесей?',
        answers: [
          'Должны иметь накладные',
          'Должны иметь сертификаты качества',
          'Должны иметь данные о поставщике',
        ],
        correctAnswers: ['Должны иметь сертификаты качества'],
      },
      {
        code: '63667283',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после тщательного их просушивания и разогрева',
          'Только после выдержки в течение суток при температуре 40 °С',
          'Только после смазки смазочным материалом',
          'Только после их охлаждения',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63667284',
        text:
          'В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?',
        answers: [
          'Крышка должна быть закрыта',
          'Крышка должна быть снята',
          'Крышка должна быть открыта для наблюдения за работой горелки',
          'Крышка должна быть опломбирована',
        ],
        correctAnswers: ['Крышка должна быть закрыта'],
      },
      {
        code: '63667285',
        text:
          'Какие ручки должен иметь металлический инструмент, применяемый при обслуживании индукционных печей?',
        answers: [
          'Алюминиевые ручки',
          'Электроизолированные ручки',
          'Омедненные ручки',
        ],
        correctAnswers: ['Электроизолированные ручки'],
      },
      {
        code: '63667286',
        text:
          'Что должно подаваться в межконусное пространство загрузочного устройства доменной печи во избежание образования взрывоопасных смесей? Выберите 2 варианта ответа.',
        answers: ['Пар', 'Воздух', 'Аргон', 'Азот'],
        correctAnswers: ['Пар', 'Азот'],
      },
      {
        code: '63667287',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования',
          'Приборы должны устанавливаться в непосредственной близости к оборудованию',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
          'Расположение приборов не регламентируется',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63667288',
        text:
          'Какими средствами пожаротушения должны быть оборудованы помещения, где хранится карбид кальция?',
        answers: [
          'Пенные огнетушители, бочки с водой',
          'Порошковые огнетушители, сухой песок',
          'Пенные огнетушители',
          'Углекислотные огнетушители, кошма',
        ],
        correctAnswers: ['Порошковые огнетушители, сухой песок'],
      },
      {
        code: '63667289',
        text:
          'Что из перечисленного отмечается в техническом паспорте каждого воздухонагревателя? Выберите правильный вариант ответа.',
        answers: [
          'Только результаты периодических осмотров',
          'Только все производимые ремонты с указанием их характера с приложением чертежей, по которым были произведены ремонты',
          'Только лица, выполнявшие сварочные работы',
          'Отмечается все перечисленное',
        ],
        correctAnswers: ['Отмечается все перечисленное'],
      },
      {
        code: '63667290',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'При потере ковшом формы вследствие деформации',
          'При наличии качки цапф в теле ковша',
          'При повреждении футеровки ковша',
          'При наличии раковин, трещин в стенках и в местах крепления цапф',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63667291',
        text:
          'При каких условиях должны производиться все работы по подготовке плавки?',
        answers: [
          'В период проведения плавки',
          'Только при отключенной печи (установке)',
          'Только при включенной печи (установке)',
          'При разогретой печи',
        ],
        correctAnswers: ['Только при отключенной печи (установке)'],
      },
      {
        code: '63667292',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: [
          'Один раз в год',
          'Один раз в 2 года',
          'Один раз в 4 года',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63667293',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу B', 'Классу А', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу А'],
      },
      {
        code: '63667294',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Только обратный клапан',
          'Только 2 запорных вентиля',
          'Только свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63667295',
        text:
          'Какие из перечисленных работ по доставке и заливке чугуна в мартеновские печи, двухванные сталеплавильные агрегаты, конвертеры не осуществляются в соответствии с требованиями технологической инструкции?',
        answers: [
          'Замена кислородных фурм и производство других работ на своде двухванного сталеплавильного агрегата',
          'Работы на своде двухванного сталеплавильного агрегата в период завалки шихты на соседней ванне',
          'Все перечисленные работы осуществляются в соответствии с требованиями технологической инструкции',
          'Пробивка или прожигание чугунной корки кислородом',
          'Заливка чугуна в одну из ванн двухванного сталеплавильного агрегата',
        ],
        correctAnswers: [
          'Работы на своде двухванного сталеплавильного агрегата в период завалки шихты на соседней ванне',
        ],
      },
      {
        code: '63667296',
        text:
          'Подвод азота или пара под каким давлением следует предусматривать для продувки трубопроводов кислорода и газокислородной смеси в доменном производстве?',
        answers: [
          'Не менее чем на 0,05 МПа (0,5 кгс/см²) выше давления кислорода',
          'Не менее чем на 0,08 МПа (0,8 кгс/см²) выше давления кислорода',
          'Не менее чем на 0,1 МПа (1 кгс/см²) выше давления кислорода',
          'Не менее чем на 0,03 МПа (0,3 кгс/см²) выше давления кислорода',
        ],
        correctAnswers: [
          'Не менее чем на 0,1 МПа (1 кгс/см²) выше давления кислорода',
        ],
      },
      {
        code: '63667297',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Не менее двух лет',
          'Не менее трех лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63667298',
        text:
          'Какой порядок действий персонала при пожаре на установке получения водорода указан верно?',
        answers: [
          'Вызвать пожарную команду; перекрыть подачу технологических газов на установку; снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованном проектом случае в атмосферу',
          'Перекрыть подачу технологических газов на установку; снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованном проектом случае в атмосферу; вызвать пожарную команду',
          'Снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованном проектом случае в атмосферу; вызвать пожарную команду; перекрыть подачу технологических газов на установку',
        ],
        correctAnswers: [
          'Вызвать пожарную команду; перекрыть подачу технологических газов на установку; снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованном проектом случае в атмосферу',
        ],
      },
      {
        code: '63667299',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63667300',
        text:
          'Какие требования установлены к снабжению литейных производств щелочью?',
        answers: [
          'Должны снабжаться кристаллизованной щелочью в металлических бочках',
          'Должны снабжаться сульфитной щелочью',
          'Должны снабжаться щелочью в виде порошка',
        ],
        correctAnswers: ['Должны снабжаться сульфитной щелочью'],
      },
      {
        code: '63667301',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: [
          'На плотность',
          'На герметичность',
          'На прочность',
          'На чистоту и качество исполнения',
        ],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63667302',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Производятся все перечисленные виды работ',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
          'Только работы, связанные с аварийными ситуациями',
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63667303',
        text:
          'Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?',
        answers: [
          'Только из природного газа',
          'Только из доменного газа',
          'Только из коксового газа',
          'Только из конвертерного газа',
          'Только из ферросплавного и реформерного газов',
          'Из всех перечисленных газов',
        ],
        correctAnswers: ['Из всех перечисленных газов'],
      },
      {
        code: '63667304',
        text:
          'Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?',
        answers: [
          'Десятикратный',
          'Пятикратный',
          'Восьмикратный',
          'Трехкратный',
        ],
        correctAnswers: ['Восьмикратный'],
      },
      {
        code: '63667305',
        text:
          'После чего должны производиться работы по ремонту пылевыпускного клапана?',
        answers: [
          'После перекрытия запорной арматуры',
          'После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности',
          'После остановки работы печи',
          'После письменного разрешения ответственного за проведение работ',
        ],
        correctAnswers: [
          'После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности',
        ],
      },
      {
        code: '63667306',
        text:
          'Какие требования предъявляются к корзинам, загружаемым в щелочные ванны?',
        answers: [
          'Должны быть не влажные',
          'Должны быть наполнены горячим металлом',
          'Должны быть наполнены промасленным металлом',
          'Должны быть пронумерованы',
          'Должны быть изготовлены из просечного металла',
        ],
        correctAnswers: ['Должны быть не влажные'],
      },
      {
        code: '63667307',
        text:
          'В каком из перечисленных случаев не допускается производить выпуск чугуна?',
        answers: [
          'При короткой летке',
          'При слабой летке',
          'При неисправном футляре',
          'По сырой летке',
        ],
        correctAnswers: ['По сырой летке'],
      },
      {
        code: '63667308',
        text:
          'Каким газом должны продуваться перед пуском и после остановки установки по производству водорода?',
        answers: ['Азотом', 'Техническим аргоном', 'Кислородом', 'Гелием'],
        correctAnswers: ['Азотом'],
      },
      {
        code: '63667309',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'По стенам зданий на участках со сплошным остеклением',
          'По наружным стенам производственных зданий (транзитных водородопроводов)',
          'По легкосбрасываемым ограждающим конструкциям',
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63667310',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Жетон-бирочная система применяется при выводе ТУ на продолжительную остановку',
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63667311',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,05 МПа (0,5 кгс/см²)',
          'При давлении 0,02 МПа (0,2 кгс/см²)',
          'При давлении 0,01 МПа (0,1 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63667312',
        text:
          'С чьего разрешения должна производиться уборка шлака и мусора из-под мартеновской печи в период завалки и прогрева шихты?',
        answers: [
          'С разрешения диспетчера',
          'С разрешения оператора',
          'С разрешения сталевара печи',
          'С разрешения начальника цеха',
        ],
        correctAnswers: ['С разрешения сталевара печи'],
      },
      {
        code: '63667313',
        text:
          'При каких показателях газа разрешено применять жидкостные манометры?',
        answers: [
          'При давлении до 0,04 МПа',
          'При давлении до 0,05 МПа',
          'При давлении до 0,03 МПа',
          'При давлении до 0,07 МПа',
        ],
        correctAnswers: ['При давлении до 0,03 МПа'],
      },
      {
        code: '63667314',
        text:
          'В каком случае допускается работа подъемников мелочи кокса и агломерата при неисправных путевых выключателях, выключателях слабины каната, а также концевых выключателях крайних положений скипа? Выберите правильный вариант ответа.',
        answers: [
          'Только в присутствии наблюдающего',
          'Только по указанию ответственного за безопасное производство работ',
          'Только при наличии исправной сигнализации',
          'Во всех перечисленных случаях не допускается',
        ],
        correctAnswers: ['Во всех перечисленных случаях не допускается'],
      },
      {
        code: '63667315',
        text:
          'Что должно быть предусмотрено автоматизированной системой управления процессом при получении водорода методом диссоциации аммиака? Выберите правильный вариант ответа.',
        answers: [
          'Только светозвуковая сигнализация при достижении предельной концентрации водорода под кровлей помещения диссоциаторов и компрессорной водорода',
          'Только сигнализация понижения или повышения температуры диссоциации',
          'Только регулирование давления диссоциированного аммиака после компрессора',
          'Только обеспечение равномерной подачи аммиака к нижнему диссоциатору',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63667316',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться на свободных площадках пролета цеха',
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
          'Должны расставляться на горизонтальных площадках в любом месте цеха',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63667317',
        text:
          'С какой периодичностью должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей?',
        answers: [
          'Ежеквартально',
          'Ежегодно',
          'Один раз в полугодие',
          'Ежемесячно',
        ],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63667318',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутри кислородопровода при его обезжиривании?',
        answers: [
          'Из поролона или других органических материалов',
          'Из картона',
          'Из цветных металлов',
          'Из нержавеющей стали',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63667319',
        text:
          'С какой периодичностью должна проверяться герметичность сводов и стен регенераторов, работающих с подогревом газа в них?',
        answers: ['Ежесменно', 'Ежесуточно', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63667320',
        text: 'Где должна производиться грануляция шлака?',
        answers: [
          'В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха',
          'В специальных грануляционных бассейнах, установленных на территории цеха',
          'В специальных герметизированных установках и резервуарах на территории цеха',
        ],
        correctAnswers: [
          'В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха',
        ],
      },
      {
        code: '63667321',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы светофорами',
          'Должны быть оборудованы постами с сигналистами',
          'Должны быть оборудованы автоматической сигнализацией',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63667322',
        text:
          'Какое должно быть давление во всасывающих коммуникациях компрессоров, сжимающих ПРВ?',
        answers: [
          'Должно быть не ниже 0,5 кПа (50 мм вод. ст.)',
          'Должно быть не ниже 0,4 кПа (40 мм вод. ст.)',
          'Должно быть не ниже 0,2 кПа (20 мм вод. ст.)',
        ],
        correctAnswers: ['Должно быть не ниже 0,5 кПа (50 мм вод. ст.)'],
      },
      {
        code: '63667323',
        text:
          'Что должно устраиваться над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора в целях исключения попадания атмосферных осадков в ковши?',
        answers: [
          'Крыши или специальные навесы',
          'Туннель для передвижения персонала',
          'Обслуживающие площадки',
        ],
        correctAnswers: ['Крыши или специальные навесы'],
      },
      {
        code: '63667324',
        text:
          'Какое из перечисленных требований допускается при грануляции шлака за пределами цеха? Выберите два варианта ответа.',
        answers: [
          'Слив шлака в бассейн или камеру медленно с оставлением на дне ковша части шлака',
          'Нахождение шлакоуборочных кранов в конце крановой эстакады на время грануляции шлака',
          'Наличие механизированных устройств для подачи сигналов во время передвижения грейферных шлакоуборочных кранов',
          'Ограждение грануляционных бассейнов перилами высотой не менее 1 м',
        ],
        correctAnswers: [
          'Слив шлака в бассейн или камеру медленно с оставлением на дне ковша части шлака',
          'Нахождение шлакоуборочных кранов в конце крановой эстакады на время грануляции шлака',
        ],
      },
      {
        code: '63667325',
        text:
          'Чем определяется высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций?',
        answers: [
          'Проектной документацией',
          'Правилами безопасности процессов получения или применения металлов',
          'Производственной документацией',
        ],
        correctAnswers: ['Производственной документацией'],
      },
      {
        code: '63667326',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу B', 'Классу А', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу B'],
      },
      {
        code: '63667327',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I и II группы газоопасности',
          'На I, II и III группы газоопасности',
          'На I, II, III и IV группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63667328',
        text:
          'В каком случае допускается повторный налив шлака в ковш с образовавшейся разделительной коркой?',
        answers: [
          'В случае наличия звуковой и световой сигнализации',
          'Если это регламентировано технологической или производственной инструкцией',
          'В случае согласования между ковшевым шлаковозных ковшей (диспетчером) и составительской бригадой',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63667329',
        text:
          'Чему должны соответствовать параметры процесса эксплуатации и ремонта оборудования?',
        answers: [
          'Только требованиям установленного технологического регламента',
          'Только рекомендациям завода-изготовителя',
          'Только паспортным данным оборудования',
          'Всему перечисленному',
        ],
        correctAnswers: ['Всему перечисленному'],
      },
      {
        code: '63667330',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Арматура должна размещаться над дверными проемами',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'Арматура должна размещаться над технологическими проходами',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63667331',
        text:
          'Какие мероприятия должны быть выполнены при уходе металла из тигля?',
        answers: [
          'Жидкий металл должен быть слит в изложницу',
          'Печь должна быть остановлена и остужена',
          'Печь должна быть отключена, и жидкий металл слит в изложницу',
          'Печь должна быть остановлена и продута воздухом',
        ],
        correctAnswers: [
          'Печь должна быть отключена, и жидкий металл слит в изложницу',
        ],
      },
      {
        code: '63667332',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
    ],
    63660: [
      {
        code: '63660000',
        text:
          'Какой допускается зазор между колошниковой площадкой, вагранкой и шахтой подъемника?',
        answers: [
          'Не более 20 мм',
          'Не более 30 мм',
          'Не более 50 мм',
          'Не более 70 мм',
        ],
        correctAnswers: ['Не более 50 мм'],
      },
      {
        code: '63660001',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 6 месяцев',
          'В течение месяца',
          'В течение 3 месяцев',
          'В течение года',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63660002',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
          'Только работниками эксплуатирующей организации',
          'Только работниками подрядных организаций',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63660003',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в газоочистное устройство вагранки, при отводе газов с полным дожиганием?',
        answers: ['5%', '8%', '1%', '2%'],
        correctAnswers: ['1%'],
      },
      {
        code: '63660004',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,5 м в свету',
          'Не менее 2,0 м в свету',
          'Не менее 1,0 м в свету',
          'Не менее 2,5 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63660005',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы должны быть прекращены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
          'Решение о дальнейшей работе должен принять работник, выдавший наряд-допуск',
          'Работы могут быть продолжены, оформление нового наряд-допуска не требуется',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63660006',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Должен быть продут рукав для удаления воздуха',
          'Должна быть зажжена горючая смесь газов',
          'Должен быть открыт вентиль горючего газа',
          'Должен быть немного приоткрыт вентиль кислорода',
        ],
        correctAnswers: ['Должен быть немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63660007',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Электрическими приборами',
          'Открытым пламенем с использованием паяльной лампы',
          'Горячим воздухом, паром или горячей водой',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63660008',
        text:
          'Какой должна быть температура воды, отходящей от водоохлаждаемых элементов дуговых электропечей?',
        answers: [
          'Ниже температуры выпадения осадков временной жесткости',
          'Выше температуры выпадения осадков временной жесткости',
          'Выше температуры выпадения осадков временной жесткости на 10°С',
        ],
        correctAnswers: [
          'Ниже температуры выпадения осадков временной жесткости',
        ],
      },
      {
        code: '63660009',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь пешеходные проходы шириной 1,0 м по всему периметру',
          'Должны иметь пешеходные проходы шириной 0,7 м по всему периметру',
          'Со всех сторон должны иметь ограждение',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63660010',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в год', 'Один раз в 3 года', 'Один раз в 5 лет'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63660011',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 14 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63660012',
        text:
          'Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?',
        answers: [
          'Только номер сертификата на заглушку',
          'Только номер заглушки и марка материала',
          'Только условное давление Pу и условный диаметр Ду',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63660013',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы могут проводиться без остановки рабочего оборудования с соблюдением требований правил безопасности при расплаве металлов',
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
          'Работы должны производиться цеховым обслуживающим персоналом',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63660014',
        text:
          'Какие требования установлены к материалам, используемым для приготовления формовочных и стержневых смесей?',
        answers: [
          'Должны иметь сертификаты качества',
          'Должны иметь товарные накладные',
          'Должны иметь данные о поставщике',
        ],
        correctAnswers: ['Должны иметь сертификаты качества'],
      },
      {
        code: '63660015',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Жетон-бирочная система применяется при выводе технических устройств (ТУ) на продолжительную остановку',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
          'Жетон-бирочная система применяется для предотвращения травм и аварий в результате ошибочного или несанкционированного включения ТУ, а также в целях согласования действий технологического и ремонтного персонала',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63660016',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'Только в соответствии с требованиями строительных норм и правил',
          'Только в соответствии с требованиями нормативно-технической документации для технологических стальных трубопроводов',
          'Только в соответствии с требованиями Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63660017',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В двух экземплярах',
          'В трех экземплярах',
          'В четырех экземплярах',
          'В одном экземпляре',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63660018',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Только предохраняющие устройства, отключающие приводы при перегрузке',
          'Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Только исключение падения транспортируемых материалов',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63660019',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Образование взрывоопасной среды',
          'Возможность разгерметизации системы',
          'Попадание воздушной среды',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63660020',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?',
        answers: ['2 г/нм3', '2,5 г/нм3', '3 г/нм3', '4 г/нм3'],
        correctAnswers: ['4 г/нм3'],
      },
      {
        code: '63660021',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В вертикальном положении',
          'В наклонном положении',
          'В наклонном положении с вентилем, направленным вверх',
          'В любом устойчивом положении',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63660022',
        text:
          'Каким количеством работников должно осуществляться выполнение операций на установке по нанесению полимерных покрытий?',
        answers: [
          'Не менее чем двумя работниками',
          'Не менее чем тремя работниками',
          'Не менее чем пятью работниками',
          'Не менее чем шестью работниками',
        ],
        correctAnswers: ['Не менее чем двумя работниками'],
      },
      {
        code: '63660023',
        text:
          'В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если появилась угроза жизни и здоровью работников',
          'Если изменился состав бригады',
          'Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технических устройств и порядка выполнения работ',
          'Если подан аварийный сигнал',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63660024',
        text:
          'На какое время смесеприготовительные машины и средства транспортирования смесей должны быть остановлены, а электрические схемы разобраны?',
        answers: [
          'Только на время внутренних осмотров',
          'Только на время ремонта',
          'Только на время смазки или чистки',
          'На время проведения всех перечисленных работ',
        ],
        correctAnswers: ['На время проведения всех перечисленных работ'],
      },
      {
        code: '63660025',
        text:
          'Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров воздухоразделительных установок (ВРУ) при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?',
        answers: ['Менее 100 м', 'Менее 150 м', 'Менее 200 м', 'Менее 250 м'],
        correctAnswers: ['Менее 100 м'],
      },
      {
        code: '63660026',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'При перерыве в работе в течение одной смены (обеденный перерыв, перерыв по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на текущий момент',
        ],
        correctAnswers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
        ],
      },
      {
        code: '63660027',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 5% больше расчетного',
          'На 15% больше расчетного',
          'На 25% больше расчетного',
          'На 30% больше расчетного',
        ],
        correctAnswers: ['На 15% больше расчетного'],
      },
      {
        code: '63660028',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63660029',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В непосредственной близости от агрегатов',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
          'На расстоянии не более 2 м от агрегатов',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63660030',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 0,5 м',
          'Не менее 1,2 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63660031',
        text:
          'Каким требованиям должна соответствовать эксплуатация сушильных устройств, работающих на газе, а также сушильных устройств с электроподогревом?',
        answers: [
          'Требованиям технологической инструкции',
          'Требованиям Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
          'Требованиям наряда-допуска',
        ],
        correctAnswers: ['Требованиям технологической инструкции'],
      },
      {
        code: '63660032',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 12 часов',
          'Не менее 8 часов',
          'Не менее 20 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63660033',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Количество производителей (руководителей) работ должен определять работник, выдающий наряд-допуск',
          'Один производитель (руководитель) работ',
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63660034',
        text:
          'С какой периодичностью кольца и цапфы ковша после изготовления должны проверяться методом неразрушающего контроля?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в полгода',
          'Не реже одного раза в три года',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63660035',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от тепловых воздействий',
          'Только от механических воздействий',
          'Только от возможного попадания на них расплава',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63660036',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства продуктов разделения воздуха (ПРВ)?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 18% и не более 24%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63660037',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 3 м от печей и должны быть защищены паровой завесой',
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть установлены на расстоянии не менее 7 м от печей и иметь специальные ограждения',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63660038',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Освидетельствованию',
          'Проверке состояния',
          'Гидравлическим испытаниям',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63660039',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под давлением',
          'На газопроводах, находящихся под разрежением',
          'На газопроводах, продутых сжатым воздухом',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63660040',
        text:
          'Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 2,0 величины рабочего давления охлаждающей воды',
          'На величину рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63660041',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На плотность', 'На прочность', 'На герметичность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63660042',
        text:
          'Какая допускается максимальная температура воды в рубашке водяного охлаждения фурменного и плавильного поясов вагранки?',
        answers: ['40 °C', '60 °C', '80 °C', '100 °C'],
        correctAnswers: ['80 °C'],
      },
      {
        code: '63660043',
        text:
          'Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?',
        answers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
          'На кислородопроводах не допускается установка арматуры из сплавов на основе титана',
          'Кислородная арматура для присоединения рукавов должна быть размещена в металлическом шкафу с отверстиями для проветривания',
          'При отсутствии работника, пользующегося арматурой, шкаф должен быть закрыт на замок',
        ],
        correctAnswers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
      },
      {
        code: '63660044',
        text: 'Подача шихты с какой влажностью запрещается в печь "Аусмелт"?',
        answers: [
          'Более 3% по массе',
          'Более 5% по массе',
          'Более 7% по массе',
          'Более 8% по массе',
        ],
        correctAnswers: ['Более 8% по массе'],
      },
      {
        code: '63660045',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['1 м', '2 м', '5 м', '8 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63660046',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Организация, проектирующая систему автоматики печи',
          'Комиссия эксплуатирующей организации',
          'Организация, эксплуатирующая систему автоматики объекта',
          'Сервисные службы изготовителя',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63660047',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в год',
          'Не реже одного раза в два года',
          'Не менее одного раза в три года',
          'Не менее одного раза в полгода',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63660048',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'Ковшей, имеющих качку цапф в теле ковша',
          'Ковшей, потерявших форму вследствие деформации',
          'Ковшей с поврежденной футеровкой',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63660049',
        text:
          'Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?',
        answers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
          'Должны смазываться специальным материалом, защищающим от воздействия лучистого тепла',
          'Должны покрываться огнестойкими составами для защиты от воздействия тепловой нагрузки',
        ],
        correctAnswers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
        ],
      },
      {
        code: '63660050',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 96,5%',
          'Не менее 98,0%',
          'Не менее 97,7%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63660051',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63660052',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с начальником газоспасательной службы (ГСС)',
          'Только со службой производственного контроля',
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63660053',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['2,5 часа', '1,5 часа', '1 час', '2 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63660054',
        text:
          'Какие этикетки или бирки должна иметь тара, в которой находятся полимеры, ЛКМ?',
        answers: [
          'С наименованием и обозначением содержащихся материалов',
          'С указанием условий хранения находящихся в таре материалов (покрытий)',
          'С указанием данных работника, ответственного за выдачу материалов из тары',
          'С указанием срока годности материалов и смесей, хранящихся в таре',
        ],
        correctAnswers: [
          'С наименованием и обозначением содержащихся материалов',
        ],
      },
      {
        code: '63660055',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Только дистанционное отключение насосов и компрессоров',
          'Только установка на линии всасывания запорных или отсекающих устройств',
          'Только установка на линии нагнетания запорных или отсекающих устройств',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63660056',
        text:
          'Как часто должна проверяться надежность крепления головок электродов?',
        answers: [
          'Один раз в сутки',
          'Один раз в три дня',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Правилами не регламентируется'],
      },
      {
        code: '63660057',
        text: 'Как должны сооружаться газопроводы на территории предприятия?',
        answers: [
          'Надземными',
          'Подземными',
          'Не регламентируется',
          'Наземными',
        ],
        correctAnswers: ['Надземными'],
      },
      {
        code: '63660058',
        text:
          'В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?',
        answers: [
          'Если исключен контакт с жировыми загрязнениями',
          'Если предназначенные для работы детали контактируют с кислородом',
          'Если невозможно исключить загрязнение воздуха производственных помещений парами моющих растворов, используемых для обезжиривания',
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
        correctAnswers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
      },
      {
        code: '63660059',
        text:
          'Чем из перечисленного должны оснащаться топливные баки пламенных печей, работающих на жидком топливе?',
        answers: [
          'Только указателем уровня топлива',
          'Только спускным краном с трубопроводом, выведенным в аварийный подземный резервуар',
          'Только трубопроводом для сообщения с атмосферой (воздушник)',
          'Только переливной трубкой, выведенной в аварийный подземный резервуар',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63660060',
        text:
          'Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ?',
        answers: [
          'Колонны высокого и низкого давления',
          'Воздушные секции теплообменников',
          'Аппараты и коммуникации на потоке жидкого кислорода',
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
        correctAnswers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63660061',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'Только на первой ступени',
          'Только на третьей ступени',
          'Только на второй ступени',
          'На всех ступенях',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63660062',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
          'При зажигании горелок следует подавать максимальное количество воздуха для полного сгорания газа и исключить отрыв пламени от горелки',
          'Газ и воздух при зажигании и регулировании горелок необходимо подавать сразу, не дожидаясь установившегося давления',
          'При увеличении нагрузки на горелку следует сначала увеличить подачу воздуха, затем газа',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63660063',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раз в три года',
          'Не реже одного раза в два года',
          'Не реже двух раз в год',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63660064',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?',
        answers: [
          'Cероводорода - 0,01 г/нм3, нафталина - 0,03 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
          'Cероводорода - 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
          'Cероводорода - 0,04 г/нм3, нафталина - 0,06 г/нм3 (летом) и 0,2 г/нм3 (зимой)',
          'Cероводорода - 0,03 г/нм3, нафталина - 0,08 г/нм3 (летом) и 0,3 г/нм3 (зимой)',
        ],
        correctAnswers: [
          'Cероводорода - 0,02 г/нм3, нафталина - 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
        ],
      },
      {
        code: '63660065',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?',
        answers: ['Ежедневно', 'Еженедельно', 'Через каждые двое суток'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63660066',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,01 МПа (0,1 кгс/см²)',
          'При давлении 0,05 МПа (0,5 кгс/см²)',
          'При давлении 0,02 МПа (0,2 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63660067',
        text:
          'В каком случае взятие пробы смеси может производиться во время работы бегунов?',
        answers: [
          'Если взятие пробы проводится механическим приспособлением',
          'Если взятие пробы проводится ручным способом',
          'Если взятие пробы проводится ручным конусным приспособлением',
        ],
        correctAnswers: [
          'Если взятие пробы проводится механическим приспособлением',
        ],
      },
      {
        code: '63660068',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63660069',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'Хранение в помещении ГРП обтирочных и других горючих материалов не допускается',
          'При необходимости следует пользоваться переносными аккумуляторными взрывобезопасными светильниками в помещении категории A',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63660070',
        text:
          'Каким образом должен определяться объем аварийного резервуара пламенных печей, работающих на жидком топливе?',
        answers: [
          'Технологической картой с учетом объема производства',
          'Проектом с учетом общей вместимости расходных баков, установленных в помещении',
          'Правилами безопасности процессов получения или применения металлов с учетом средней вместимости расходных баков, установленных в смежных помещениях',
          'Эксплуатирующей организацией с уточнением общего количества имеющихся баков',
        ],
        correctAnswers: [
          'Проектом с учетом общей вместимости расходных баков, установленных в помещении',
        ],
      },
      {
        code: '63660071',
        text:
          'С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?',
        answers: [
          'С интервалом не менее чем 10 минут',
          'С интервалом не менее чем 15 минут',
          'С интервалом не менее чем 25 минут',
          'С интервалом не менее чем 30 минут',
        ],
        correctAnswers: ['С интервалом не менее чем 10 минут'],
      },
      {
        code: '63660072',
        text:
          'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 30 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
        correctAnswers: [
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
      },
      {
        code: '63660073',
        text:
          'Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?',
        answers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
          'На 300 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.',
          'На 700 мм вод. ст. больше расчетного давления газа, но не менее 2500 мм вод. ст.',
          'На 1000 мм вод. ст. больше расчетного давления газа, но не менее 3000 мм вод. ст.',
        ],
        correctAnswers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
        ],
      },
      {
        code: '63660074',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и с учетом технологии производства',
          'Должны расставляться на свободных площадках в любом месте пролета производственных цехов',
          'Должны расставляться в соответствии с проектом производства работ',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и с учетом технологии производства',
        ],
      },
      {
        code: '63660075',
        text:
          'Для чего печь "Аусмелт" оборудуется резервной газовоздушной горелкой?',
        answers: [
          'Только для поддержания параметров котла-утилизатора',
          'Только для поддержания температурных параметров огнеупорной кладки',
          'Только для поддержания температурных параметров расплава',
          'Для всего перечисленного',
        ],
        correctAnswers: ['Для всего перечисленного'],
      },
      {
        code: '63660076',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после их увлажнения и охлаждения',
          'Только после тщательного их просушивания и разогрева',
          'Только после обмазки ковшей смазочным материалом',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63660077',
        text: 'При каком условии ВРУ должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,50 мг/дм и выше, подтвержденной четырьмя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,30 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной двумя последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63660078',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 1,2 м',
          'Не менее 0,8 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63660079',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
          'Должны находиться в свободном доступе',
          'Должны быть предназначены для выполнения любых работ',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63660080',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из чугуна',
          'Из коррозионно-стойкой стали или медных сплавов',
          'Из алюминия',
        ],
        correctAnswers: ['Из коррозионно-стойкой стали или медных сплавов'],
      },
      {
        code: '63660081',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Не менее трех лет',
          'Не менее двух лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63660082',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?',
        answers: [
          'Из медного сплава',
          'Из алюминия',
          'Из поролона или других органических материалов',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63660083',
        text:
          'Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?',
        answers: [
          'Только автоматизацией производственных процессов и герметизацией технологического оборудования, являющегося источником вредных и (или) опасных производственных факторов',
          'Только применением безопасных способов хранения и транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции',
          'Только комплексной механизацией и автоматизацией ручного труда, дистанционным управлением производственными процессами и операциями',
          'Только применением средств индивидуальной и коллективной защиты работников',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63660084',
        text:
          'Какое из перечисленных устройств должно быть установлено на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Только обратный клапан',
          'Только два запорных вентиля',
          'Только свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63660085',
        text:
          'Какие требования установлены к снабжению литейных производств щелочью?',
        answers: [
          'Должны снабжаться щелочью в виде порошка',
          'Должны снабжаться кристаллизованной щелочью в металлических бочках',
          'Должны снабжаться сульфитной щелочью',
        ],
        correctAnswers: ['Должны снабжаться сульфитной щелочью'],
      },
      {
        code: '63660086',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть предварительно просушены или прокалены',
          'Должны быть выдержаны в помещении не менее 3 суток',
          'Должны быть выдержаны в помещении не менее суток',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63660087',
        text:
          'До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?',
        answers: ['80 °C', '100 °C', '120 °C', '140 °C'],
        correctAnswers: ['120 °C'],
      },
      {
        code: '63660088',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Не менее трех человек под наблюдением газоспасателя или члена добровольной газоспасательной дружины (ДГСД)',
          'Не менее четырех человек под наблюдением газоспасателя или члена ДГСД',
          'Одним человеком под наблюдением газоспасателя или члена ДГСД',
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63660089',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?',
        answers: ['0,3 кПа', '0,4 кПа', '0,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63660090',
        text:
          'При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25% нижнего предела взрываемости (1% об.), кислорода менее 35% и более 40%, угарного газа более 29 мг/м³, метана не более 5% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 20% нижнего предела взрываемости (0,8% об.), кислорода менее 25% и более 34%, угарного газа более 25 мг/м³, метана не более 15% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 50% нижнего предела взрываемости (2% об.), кислорода менее 17% и более 20%, угарного газа более 60 мг/м³, метана не более 20% нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63660091',
        text:
          'Какая допускается максимальная температура воздуха внутри печи для производства ремонтных работ?',
        answers: ['30 °C', '40 °C', '50 °C', '60 °C'],
        correctAnswers: ['40 °C'],
      },
      {
        code: '63660092',
        text:
          'Какое количество запорных вентилей должно быть на топливопроводе каждой пламенной печи, работающей на жидком или газовом топливе?',
        answers: [
          'Три запорных вентиля',
          'Два запорных вентиля',
          'Один запорный вентиль',
          'Четыре запорных вентиля',
        ],
        correctAnswers: ['Два запорных вентиля'],
      },
      {
        code: '63660093',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['85 °C', '80 °C', '75 °C', '70 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63660094',
        text: 'Какие требования к компрессорным установкам указаны верно?',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Изготовление, монтаж, наладка, ремонт, испытания и эксплуатация компрессорных установок должны проводиться специализированными в этой области организациями',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63660095',
        text:
          'Что из перечисленного относят к отходам литейного производства? Выберите правильный вариант ответа.',
        answers: [
          'Только отработанные формовочные, стержневые смеси, брак форм и стержней',
          'Только просыпи, литейные шлаки, абразивную и галтовочную пыль',
          'Только огнеупорные материалы, керамику и шламы мокрых пылеочистных вентиляционных систем',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63660096',
        text:
          'С помощью чего допускается производить подъем и перемещение ЛКМ?',
        answers: [
          'Только с помощью электропогрузчиков и специальных тележек',
          'Только с помощью автотранспорта',
          'Только с помощью мостового крана',
          'С помощью всего перечисленного',
        ],
        correctAnswers: ['С помощью всего перечисленного'],
      },
      {
        code: '63660097',
        text:
          'Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите два правильных ответа.',
        answers: [
          'Требованиям Правил безопасности в нефтяной и газовой промышленности',
          'Производственной документации',
          'Требованиям Правил безопасности процессов получения или применения металлов',
          'Требованиям Технического регламента Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
        correctAnswers: [
          'Производственной документации',
          'Требованиям Правил безопасности процессов получения или применения металлов',
        ],
      },
      {
        code: '63660098',
        text:
          'В каком из перечисленных случаев запрещено возобновлять подачу воды к любому из водоохлаждаемых компонентов печи "Аусмелт"?',
        answers: [
          'Если температура этих компонентов превышает 50 °C',
          'Если температура этих компонентов превышает 100 °C',
          'Если температура этих компонентов превышает 150 °C',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Если температура этих компонентов превышает 150 °C'],
      },
      {
        code: '63660099',
        text:
          'При каком условии допускаются осмотр и ремонт оборудования, расположенного под открытой индукционной печью, находящейся в поднятом положении?',
        answers: [
          'Только при условии дополнительного крепления поднятой печи с помощью специальных упоров',
          'Только при условии организации свободного доступа к печи',
          'Только при условии выдачи акта-допуска исполнителю начальником ремонтной бригады',
          'Только при условии ограничения времени выполнения работ и в присутствии наблюдающего',
        ],
        correctAnswers: [
          'Только при условии дополнительного крепления поднятой печи с помощью специальных упоров',
        ],
      },
      {
        code: '63660100',
        text:
          'Какое из перечисленных требований к изготовлению форм и стержней указано неверно?',
        answers: [
          'Покрытие поверхности форм и стержней противопригарными красками, выделяющими вредные вещества, должно проводиться под вытяжкой в соответствии с технологической инструкцией',
          'Очистка плит формовочных машин от остатков формовочной смеси должна проводиться механизированными устройствами и приспособлениями с локализацией пылеудаления',
          'Этажерки для сушки стержней должны быть испытаны методом радиографического исследования',
          'Прочность крепления лопастей колеса метательной головки должна проверяться во избежание вылета лопастей при ослаблении крепления',
        ],
        correctAnswers: [
          'Этажерки для сушки стержней должны быть испытаны методом радиографического исследования',
        ],
      },
      {
        code: '63660101',
        text:
          'Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
          'На 1,15 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63660102',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'С Ростехнадзором',
          'С руководителем газоспасательной службы',
          'С лицом, ответственным за безопасное производство работ на объекте',
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63660103',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно?',
        answers: [
          'Арматура может размещаться над технологическими и дверными проходами',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'Арматуру из серого чугуна допускается применять на водородопроводах',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63660104',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'Со службой производственного контроля',
          'С ГСС',
          'С руководителем организации',
          'С территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Со службой производственного контроля', 'С ГСС'],
      },
      {
        code: '63660105',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров?',
        answers: [
          'Сливание расплава металла из ковшей и миксеров должно быть автоматизировано',
          'Пол рабочей площадки в местах установки ковшей должен быть влажным',
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
        ],
      },
      {
        code: '63660106',
        text:
          'Какие требования установлены к алюминиевой стружке, принимаемой на хранение?',
        answers: [
          'Должна быть упакована в герметичные емкости или брикеты',
          'Не должна содержать крупных включений шлака, песка',
          'Должна быть сухой, без следов масла и грязи',
        ],
        correctAnswers: ['Должна быть сухой, без следов масла и грязи'],
      },
      {
        code: '63660107',
        text:
          'До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?',
        answers: [
          'До температуры не ниже 10 °C и продувается кислородом',
          'До температуры не ниже 15 °C и продувается азотом',
          'До температуры не ниже 20 °C и продувается воздухом',
          'До температуры не ниже 30 °C и продувается азотом',
        ],
        correctAnswers: ['До температуры не ниже 20 °C и продувается воздухом'],
      },
      {
        code: '63660108',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 10 минут',
          'В течение 25 минут',
          'В течение 30 минут',
          'В течение 15 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63660109',
        text:
          'Какие установлены требования к хранению материалов для приготовления формовочных смесей?',
        answers: [
          'Должны храниться в специально отведенных местах цеха',
          'Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений',
          'Должны храниться в отдельных помещениях производственных участков в герметичной таре',
        ],
        correctAnswers: [
          'Должны храниться в отдельных помещениях, размещаемых вне пределов производственных участков и отделений',
        ],
      },
      {
        code: '63660110',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При незначительном повышении давления воды в системе водоохлаждения',
          'При незначительном падении давления воды в системе водоохлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63660111',
        text:
          'Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?',
        answers: [
          'Проектом',
          'Инструкцией о мерах пожарной безопасности при проведении огневых работ',
          'Технологической инструкцией',
          'Правилами безопасности процессов получения или применения металлов',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63660112',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы постами с дежурными стрелочного поста',
          'Должны быть оборудованы автоматической сигнализацией',
          'Должны быть оборудованы автоматическими шлагбаумами',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63660113',
        text:
          'Какие действия необходимо предпринять при подозрении на вспенивание (переокисление) в ванне печи "Аусмелт"? Выберите правильный вариант ответа.',
        answers: [
          'Только вывести работников в безопасную зону',
          'Только прекратить шихтоподачу и дутье фурмы',
          'Только осуществить подачу угля',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63660114',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу А', 'Классу D', 'Классу В', 'Классу С'],
        correctAnswers: ['Классу В'],
      },
      {
        code: '63660115',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 25% нижнего предела взрываемости (1% об.)',
          'Выше 30% нижнего предела взрываемости (1,2% об.)',
          'Выше 20% нижнего предела взрываемости (0,8% об.)',
          'Выше 35% нижнего предела взрываемости (1,4% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63660116',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
          'Не реже одного раза в месяц и не реже одного раза в три месяца соответственно',
          'Не реже одного раза в шесть месяцев и не реже одного раза в год соответственно',
          'Не реже одного раза в три месяца и не реже одного раза в шесть месяцев соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
      {
        code: '63660117',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'В производственных помещениях, не связанных с потреблением водорода',
          'В проходах подсобных и складских помещений',
          'Через лестничные клетки и пути эвакуации',
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63660118',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.',
        answers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
          'В случае перегрева оборудования в результате длительной эксплуатации',
          'После остановки более чем на 1 час',
        ],
        correctAnswers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
        ],
      },
      {
        code: '63660119',
        text:
          'Что из перечисленного допускается при осуществлении процессов литейного производства?',
        answers: [
          'Подвергать кольцо и цапфы ковша отжигу',
          'Использовать вторично ковш для заливки и разливки металла без предварительной замены стопора и стакана',
          'Сваривать отдельные части колец и цапф',
          'Производить ремонт неохлажденных ковшей',
        ],
        correctAnswers: ['Подвергать кольцо и цапфы ковша отжигу'],
      },
      {
        code: '63660120',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 5 часов',
          'Не менее 2 часов',
          'Не менее часа',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63660121',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 10 лет',
          'Один раз в 8 лет',
          'Один раз в 5 лет',
          'Один раз в 3 года',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63660122',
        text:
          'Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?',
        answers: [
          'Аэродинамической завесой',
          'Воздушной завесой',
          'Азотной завесой',
          'Любым из перечисленных',
        ],
        correctAnswers: ['Любым из перечисленных'],
      },
      {
        code: '63660123',
        text:
          'В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?',
        answers: [
          'Без предварительной замены футеровки',
          'Без предварительной замены стопора и стакана',
          'Без предварительной замены кожуха',
        ],
        correctAnswers: ['Без предварительной замены стопора и стакана'],
      },
      {
        code: '63660124',
        text:
          'В каком месте допускается хранение спирта и эфирно-альдегидной фракции в помещениях, в которых проводят гидролиз этилсиликата?',
        answers: [
          'В несгораемом металлическом ящике',
          'В пластиковой таре в деревянном коробе',
          'В любой таре без следов масла и грязи',
          'Не допускается ни в каком из мест',
        ],
        correctAnswers: ['В несгораемом металлическом ящике'],
      },
      {
        code: '63660125',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть выдержаны в отапливаемом помещении не менее суток',
          'Должны быть выдержаны в помещении не менее 3 суток',
          'Должны быть очищены от мусора',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63660126',
        text:
          'В каком случае допускается размещение в одном помещении с фольгопрокатным оборудованием взрывопожароопасных отделений промывки, окраски и приготовления краски?',
        answers: [
          'В случае наличия в помещении потивопожарного оборудования',
          'В случае наличия вытяжной вентиляции в помещении',
          'В случае если это обусловлено проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63660127',
        text:
          'Чем должны быть оборудованы ванны с кислотой для травления оборудования?',
        answers: [
          'Местными отсосами',
          'Укрытиями',
          'Системой подачи аргона',
          'Всем перечисленным',
        ],
        correctAnswers: ['Местными отсосами'],
      },
      {
        code: '63660128',
        text:
          'Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения',
          'Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены',
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
        correctAnswers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
      },
      {
        code: '63660129',
        text:
          'Как должны производиться очистка боровов и ремонтные работы внутри них?',
        answers: [
          'Должны производиться по наряду-допуску при температуре внутри борова не выше 45 °C',
          'Должны производиться по наряду-допуску при температуре внутри борова не выше 50 °C',
          'Должны производиться по наряду-допуску при полной остановке печи',
        ],
        correctAnswers: [
          'Должны производиться по наряду-допуску при полной остановке печи',
        ],
      },
      {
        code: '63660130',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63660131',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Только системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство',
          'Только средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи, а при компоновке печных агрегатов с котлами-утилизаторами системами по переводу на работу агрегатов без дымососов',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63660132',
        text:
          'В каких случаях не допускается отключение системы охлаждения кристаллизатора?',
        answers: [
          'Если внезапно остановился процесс расплава',
          'До выгрузки слитка из камеры',
          'Если снизилась температура плавления',
        ],
        correctAnswers: ['До выгрузки слитка из камеры'],
      },
      {
        code: '63660133',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения должно быть не менее 1,5 м',
          'Ресиверы для водорода (сосуды) должны размещаться на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63660134',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '5 м', '3 м', '15 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63660135',
        text:
          'Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Закрыть или открыть соответствующие клапаны с помощью дистанционного управления',
          'Перевести на ручной режим систему автоматического управления регенераторов',
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          'Необходимо осуществить все перечисленные действия',
        ],
        correctAnswers: ['Необходимо осуществить все перечисленные действия'],
      },
      {
        code: '63660136',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: ['Один раз в 4 года', 'Один раз в 2 года', 'Один раз в 5 лет'],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63660137',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение одного месяца',
          'В течение трех месяцев',
          'В течение шести месяцев',
          'В течение года',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63660138',
        text:
          'Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?',
        answers: [
          'Только из природного и доменного газов',
          'Только из коксового и конвертерного газов',
          'Только из ферросплавного, реформерного и иных газов, возникающих в процессе металлургического производства',
          'Из всех перечисленных газов',
        ],
        correctAnswers: ['Из всех перечисленных газов'],
      },
      {
        code: '63660139',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'Не допускается ни в каком случае',
          'В случае если на время выполнения работ выставлены посты',
          'В аварийных случаях',
          'В случае если минимально допустимое давление газа соответствует 0,5 кПа',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63660140',
        text:
          'На какую величину должны подвергаться гидравлическому испытанию водоохлаждаемые элементы дуговых электропечей перед их установкой?',
        answers: [
          'На 1,2 Рраб',
          'На 1,5 Рраб',
          'На 1,8 рабочего давления (Рраб)',
          'На 2,5 Рраб',
        ],
        correctAnswers: ['На 1,5 Рраб'],
      },
      {
        code: '63660141',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['1 час', '1,5 часа', '3 часа', '45 минут'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63660142',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Составляется новый акт-допуск на следующий срок',
          'Работы продолжаются без оформления акта-допуска',
          'Продлевается текущий акт-допуск на срок не более 10 рабочих смен',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63660143',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'Помещения оборудуются механической приточно-вытяжной общеобменной вентиляцией из верхней зоны через короб в объеме не менее двукратного в час',
          'В помещениях устанавливаются механические приточно-вытяжные общеобменные вентиляции с кратностью воздухообмена не менее 6 в час',
        ],
        correctAnswers: [
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
        ],
      },
      {
        code: '63660144',
        text:
          'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ['100 °C', '150 °C', '200 °C', '220 °C'],
        correctAnswers: ['150 °C'],
      },
      {
        code: '63660145',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Только паспортом (формуляром) на компрессорную установку и инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов',
          'Только журналом учета работы компрессора, графиком ремонтов компрессорной установки, паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63660146',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено в закрытом отдельном помещении',
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
          'Должно быть предусмотрено на площадках при входе в цех, обеспечивающих свободный подход работников',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63660147',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением, равным рабочему',
          'Давлением, выше рабочего давления на 15%',
          'Давлением, выше рабочего давления на 25%',
          'Давлением, ниже рабочего давления',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63660148',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
          'Для защиты от повышения давления газа выше проектного должны устанавливаться предохранительные сбросные клапаны',
          'При остановке агрегатов по производству реформерного газа подача воздуха и природного газа на горелки должна быть закрыта',
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63660149',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I и II группы газоопасности',
          'На I, II, III и IV группы газоопасности',
          'На I, II и III группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63660150',
        text:
          'Какое из перечисленных требований безопасности в прокатном производстве указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Не допускается работа на осевшем флюсе, а также при перегреве масла выше температуры вспышки паров во избежание ожогов и воспламенения паров масла',
          'Не допускается укладка металла на перекрытия каналов, тоннелей, траншей, маслоподвалов и люков',
          'Заливать жидкий металл во влажные формы не допускается',
          'Формы, наполненные жидким цинком, оловом или другим металлом, следует заливать водой до затвердевания металла',
        ],
        correctAnswers: [
          'Формы, наполненные жидким цинком, оловом или другим металлом, следует заливать водой до затвердевания металла',
        ],
      },
      {
        code: '63660151',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии разрешения завода-изготовителя',
          'При наличии письменного разрешения технического руководителя',
          'При наличии положительного заключения экспертного обследования',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63660152',
        text:
          'При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?',
        answers: ['6%', '2%', '3%', '5%'],
        correctAnswers: ['2%'],
      },
      {
        code: '63660153',
        text:
          'Какие из перечисленных требований при производстве меди методом пирометаллургической переработки в печи "Аусмелт" указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'На пульте управления печи должна иметься схема газоотводящего тракта с параметрами газоочистки и схемой запорно-регулируемой аппаратуры',
          'Запуск системы подачи охлаждающей воды должен производиться до нагрева печи',
          'Шпуры и леточные отверстия должны регулярно очищаться от настылей',
          'Вскрытие люков, гидрозатворов, предохранительных клапанов газоотводящего тракта при работающей печи допускается в присутствии наблюдающего',
        ],
        correctAnswers: [
          'Вскрытие люков, гидрозатворов, предохранительных клапанов газоотводящего тракта при работающей печи допускается в присутствии наблюдающего',
        ],
      },
      {
        code: '63660154',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['85 °C', '90 °C', '100 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63660155',
        text:
          'В каком случае допускается попадание аэрозолей противопригарных красок в воздух рабочей зоны во время покрытия поверхностных форм и стержней противопригарными веществами?',
        answers: [
          'Если обслуживающий персонал использует соответствующие средства индивидуальной защиты',
          'Если присутствующая аварийная вентиляция исправна',
          'Если имеется вытяжная вентиляция',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63660156',
        text:
          'Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?',
        answers: [
          'Не ниже 1,0 кПа',
          'Не ниже 5,0 кПа',
          'Не ниже 3,0 кПа',
          'Не ниже 2,0 кПа',
        ],
        correctAnswers: ['Не ниже 3,0 кПа'],
      },
      {
        code: '63660157',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'На расстоянии 15 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания',
          'В зоне погрузки грейферными или магнитными кранами',
          'На расстоянии 20 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'Во всех перечисленных зонах',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63660158',
        text: 'Какие требования к цинкованию металлов указаны неверно?',
        answers: [
          'Все вращающие механизмы, ванны с расплавом цинка, а также движущаяся полоса должны иметь стационарные защитные ограждения',
          'Срок эксплуатации ванн цинкования определяется проектной документацией завода-изготовителя',
          'Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования',
          'Электропроводка на ваннах цинкования должна быть защищена от возможного повреждения их горячим металлом',
          'Порядок загрузки блоков цинка в ванну цинкования определяется проектом',
        ],
        correctAnswers: [
          'Срок замены оборудования и футеровки ванн для цинкования определяется эксплуатирующей организацией по результатам проведения экспертизы промышленной безопасности ванн для цинкования',
        ],
      },
      {
        code: '63660159',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Техническое обслуживание',
          'Текущий ремонт',
          'Проверка срабатывания предохранительных запорных и сбросных клапанов',
          'Капитальный ремонт при замене оборудования',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63660160',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 5 лет',
          'Не позднее чем через 2 года',
          'Не позднее чем через 3 года',
          'Не позднее чем через 4 года',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63660161',
        text:
          'Какой должен быть запас угольной пыли в бункере для хранения угольной пыли?',
        answers: [
          'Не должен превышать суточной потребности',
          'Не должен превышать недельной потребности',
          'Не должен превышать месячной потребности',
        ],
        correctAnswers: ['Не должен превышать суточной потребности'],
      },
      {
        code: '63660162',
        text:
          'В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?',
        answers: [
          'Не менее 5 с',
          'Не менее 10 с',
          'Не менее 15 с',
          'Не менее 25 с',
        ],
        correctAnswers: ['Не менее 10 с'],
      },
      {
        code: '63660163',
        text:
          'Какие работы производятся на опасных производственных объектах горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Только работы, связанные с аварийными ситуациями',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
          'Производятся все перечисленные виды работ',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63660164',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,05 МПа',
          'Не менее 0,02 МПа',
          'Не менее 0,01 МПа',
          'Не менее 0,015 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63660165',
        text:
          'В какое положение должна выводиться фурма из-под дутья при помощи лебедки, оборудованной системой автоматического аварийного вывода фурмы, при производстве меди методом пирометаллургической переработки в печи "Аусмелт"?',
        answers: [
          'Только в крайнее правое положение',
          'В устойчивое боковое положение',
          'В верхнее положение',
          'В нижнее положение',
        ],
        correctAnswers: ['В верхнее положение'],
      },
      {
        code: '63660166',
        text:
          'Какое из перечисленных требований к стационарным газоразборным постам указано неверно?',
        answers: [
          'При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок',
          'Газоразборные посты, шкафы должны иметь опознавательную окраску',
          'Дверцы шкафов во время работы должны быть закрыты',
          'Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции',
        ],
        correctAnswers: ['Дверцы шкафов во время работы должны быть закрыты'],
      },
      {
        code: '63660167',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Декларация пожарной безопасности',
          'Руководство по проведению пусконаладочных работ',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63660168',
        text:
          'Чем должны быть продуты воздухопровод и камера плазменной печи перед зажиганием газовых горелок?',
        answers: ['Аргоном', 'Любым инертным газом', 'Воздухом', 'Азотом'],
        correctAnswers: ['Воздухом'],
      },
      {
        code: '63660169',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Должны быть подогреты до температуры, регламентированной производственной инструкцией, и должны иметь цилиндрическую форму',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
          'Должны быть термоустойчивыми и защищаться специальным экраном от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63660170',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должны быть обустроены ящики с песком',
          'Должны быть установлены пожарные шкафы',
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63660171',
        text:
          'Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?',
        answers: [
          'Задвижки и краны, устанавливаемые на газопроводах и устройствах, должны иметь указатель открытого и закрытого положения',
          'Установка внутри зданий и цехов на газопроводах листовых задвижек любого типа без дисковых задвижек перед ними не допускается',
          'Листовые задвижки на газопроводах диаметром более 300 мм должны быть оснащены механизированным приводом',
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
        correctAnswers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
      },
      {
        code: '63660172',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов?',
        answers: [
          'Продувать рукав для горючих газов кислородом и кислородный рукав горючим газом',
          'Взаимозаменять рукава для горючих газов и кислородный рукав при работе',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
          'Допускаются все перечисленные действия',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63660173',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'В случае если было проведено испытание сбросного предохранительного клапана или манометра',
          'При условии исправной аварийной сигнализации',
          'В случае получения разрешения от лиц, ответственных за техническое состояние газового хозяйства',
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63660174',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Не должны давать искры при работе с ними',
          'Должны быть изготовлены из стали',
          'Должны быть изготовлены из железа или его сплавов',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63660175',
        text:
          'Каким образом должна определяться продолжительность нахождения залитых металлом форм в зоне активной вентиляции?',
        answers: [
          'Технологической инструкцией',
          'Правилами безопасности процессов получения или применения металлов',
          'Проектом производства работ',
          'Пояснительной запиской к плану работ',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63660176',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения, работающие от напряжения 42 В',
          'Должны предусматриваться системы освещения, работающие от напряжения 24 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63660177',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников; двери помещения должны открываться наружу и не должны иметь внутренних запоров',
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Помещения должны иметь защиту от воздействия теплового излучения',
          'Окна закрытых пультов управления, в которые возможно попадание брызг расплава, должны быть оборудованы в соответствии с проектом',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63660178',
        text:
          'Чем должен устанавливаться порядок запуска и отключения плазмотронов?',
        answers: [
          'Инструкциями предприятия',
          'Методическими рекомендациями по подогреву металла',
          'Правилами безопасности процессов получения или применения металлов',
          'Техническим регламентом',
        ],
        correctAnswers: ['Инструкциями предприятия'],
      },
      {
        code: '63660179',
        text:
          'В соответствии с какой документацией должен проводиться разогрев печи "Аусмелт" после капитального ремонта?',
        answers: [
          'В соответствии с технологической инструкцией',
          'В соответствии с паспортом оборудования',
          'В соответствии с проектной документацией',
          'В соответствии с технологической картой',
        ],
        correctAnswers: ['В соответствии с технологической инструкцией'],
      },
      {
        code: '63660180',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: ['Один раз в год', 'Один раз в 2 года', 'Один раз в 3 года'],
        correctAnswers: ['Один раз в 2 года'],
      },
      {
        code: '63660181',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'Для уменьшения динамических нагрузок компрессоры должны устанавливаться на виброизолирующих фундаментах или с устройством амортизаторов (антивибраторов) либо должны приниматься другие меры для уменьшения вибраций',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63660182',
        text:
          'Каким образом в эксплуатирующей организации, имеющей газовое хозяйство, производящей и потребляющей горючие газы, должен быть организован контроль за обеспечением безопасной эксплуатации газового хозяйства?',
        answers: [
          'Путем создания газовой службы',
          'Путем назначения ответственных работников',
          'Путем заключения договора с организацией на оказание услуг по техническому обслуживанию и ремонту газового хозяйства',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63660183',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,5% в час',
          'Не более 0,3% в час',
          'Не более 0,1% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63660184',
        text:
          'Понижение давления до какого значения должны обеспечивать противовзрывные клапаны аппаратов системы пылеочистки и очистки отходящих ваграночных газов?',
        answers: [
          'До 5 кПа (0,05 кгс/см2)',
          'До 10 кПа (0,1 кгс/см2)',
          'До 12 кПа (0,12 кгс/см2)',
          'До 7 кПа (0,07 кгс/см2)',
        ],
        correctAnswers: ['До 5 кПа (0,05 кгс/см2)'],
      },
      {
        code: '63660185',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Брезентовые покрывала',
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Емкости с песком',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63660186',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже трех раз в год и один раз в месяц соответственно',
          'Не реже одного раза в два года и не реже двух раз в год соответственно',
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
          'Не реже двух раз в год и не реже одного раза в год соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63660187',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 8 лет',
          'Один раз в 5 лет',
          'Один раз в 4 года',
          'Один раз в 10 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63660188',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: [
          'Из алюминиевых сплавов',
          'Из сплавов титана',
          'Из любых сплавов стали',
        ],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63660189',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Строительно-монтажной организацией',
          'Проектной организацией',
          'Представителем Ростехнадзора',
          'Спецальной комиссией предприятия-заказчика',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63660190',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 15%',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 20%',
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63660191',
        text:
          'Какое из перечисленных требований при производстве глинозема указано неверно?',
        answers: [
          'Приводной механизм вращения печей спекания и кальцинации должен быть оборудован резервным двигателем с независимым источником питания',
          'Шуровка материала в печах должна производиться при разрежении в печи, исключающем выбросы пламени, газов и материала в рабочую зону',
          'На газоходах отходящих газов печей спекания и кальцинации должны быть установлены автоматические газоанализаторы для контроля содержания в них окиси углерода',
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
        ],
        correctAnswers: [
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
        ],
      },
      {
        code: '63660192',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Задвижки должны быть установлены в нужное положение',
          'Водяные затворы должны быть залиты водой',
          'Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет производиться продувка',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63660193',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Загрузка в печи предварительно прокаленных ферросплавов и других материалов',
          'Загрузка шихты, не прошедшей пиротехнического контроля',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63660194',
        text:
          'Каким образом проводится охлаждение кожуха пода или стенок дуговой электропечи при обнаружении перегрева этих мест во время плавки?',
        answers: [
          'Сжатым воздухом',
          'Водой',
          'Только отключением печи',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Сжатым воздухом'],
      },
      {
        code: '63660195',
        text:
          'На основании чего должны определяться утилизация, нейтрализация, складирование или захоронение отходов литейных производств?',
        answers: [
          'Правил безопасности процессов получения или применения металлов',
          'Эксплуатационной документации',
          'Проекта',
          'Технологической инструкции',
        ],
        correctAnswers: ['Проекта'],
      },
      {
        code: '63660196',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Испытания проводятся двумя приборами, один из которых должен быть опломбирован',
          'Диаметр корпуса манометров должен быть не менее 120 мм',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63660197',
        text:
          'Ограждение какой высотой должны иметь ванны горячего цинкования, расположенные на уровне пола (настила рабочей площадки), по всему периметру?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 1,0 м',
          'Не менее 1,1 м',
          'Не менее 1,3 м',
        ],
        correctAnswers: ['Не менее 1,1 м'],
      },
      {
        code: '63660198',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63660199',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 45 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 50 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63660200',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,3 кПа',
          'Не более 0,4 кПа',
          'Не более 0,5 кПа',
          'Не более 0,6 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63660201',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'На один объект, площадку, территорию должен оформляться один акт-допуск',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
          'Акт-допуск должен оформляться письменно',
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63660202',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
          'Должна быть шумоизолированной и оборудована металлической сеткой на смотровых стеклах',
          'Конструкция кабины завалочной машины должна быть полностью герметичной',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63660203',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 70 м',
          'В радиусе 30 м',
          'В радиусе 50 м',
          'В радиусе 100 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63660204',
        text:
          'Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Ремонт трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'Ремонт трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов или механизма переключения',
          'Ничего из перечисленного',
        ],
        correctAnswers: ['Ничего из перечисленного'],
      },
      {
        code: '63660205',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
          'У специалиста, ответственного за безопасное выполнение работ на объектах металлургической промышленности',
          'У работника, ответственного за пожарную безопасность на предприятии',
          'У руководителя предприятия',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63660206',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через вентиляционные камеры, лестничные клетки и пути эвакуации',
          'Через производственные помещения, связанные с потреблением водорода',
          'Через бытовые, подсобные административно-хозяйственные, складские помещения',
          'Через производственные помещения, не связанные с потреблением водорода',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63660207',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 15 календарных дней',
          'На 20 календарных дней',
          'На 30 календарных дней',
          'На 40 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63660208',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные комиссией специализированной организации',
          'В сроки, установленные Ростехнадзором',
          'В сроки, установленные эксплуатирующей организацией',
          'В сроки, установленные изготовителем',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63660209',
        text:
          'Какие требования установлены к расположению контрольно-измерительных приборов и автоматики (КИПиА)?',
        answers: [
          'Приборы КИПиА должны устанавливаться в непосредственной близости к оборудованию',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
          'Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63660210',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: [
          'Только паром',
          'Только газом',
          'Только сжатым воздухом',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63660211',
        text:
          'Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?',
        answers: ['Не более одного', 'Не более двух', 'Не более трех'],
        correctAnswers: ['Не более двух'],
      },
      {
        code: '63660212',
        text:
          'Чем определяется высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций?',
        answers: [
          'Производственной документацией',
          'Проектной документацией',
          'Правилами безопасности процессов получения или применения металлов',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Производственной документацией'],
      },
      {
        code: '63660213',
        text:
          'Какое из перечисленных положений нарушает требования промышленной безопасности в прокатном производстве?',
        answers: [
          'Попадание угля, сажи, смазочных материалов на поверхность щелочной ванны не допускается',
          'Корректировка обезжиривающих растворов должна производиться концентрированными растворами каустической соды и тринатрийфосфата',
          'Корректировка обезжиривающих растворов каустической содой и тринатрийфосфатом в твердом (порошкообразном) виде производится непосредственно в рабочей ванне',
          'Погружение влажных корзин с металлом в щелочную ванну не допускается',
        ],
        correctAnswers: [
          'Корректировка обезжиривающих растворов каустической содой и тринатрийфосфатом в твердом (порошкообразном) виде производится непосредственно в рабочей ванне',
        ],
      },
      {
        code: '63660214',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров газорегуляторных пунктов (далее- ГРП) и газорегуляторных установок (ГРУ), если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в полгода',
          'Не менее одного раза в 3 года',
          'Не менее одного раза в год',
          'Не менее одного раза в 2 года',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63660215',
        text:
          'Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Открытым пламенем',
          'Паром',
          'Горячей водой',
          'Сухим подогретым воздухом',
        ],
        correctAnswers: ['Сухим подогретым воздухом'],
      },
      {
        code: '63660216',
        text:
          'Какое из перечисленных требований к смесеприготовлению указано неверно?',
        answers: [
          'В бункерах для хранения угольной пыли должна контролироваться температура внутри бункера. Температура пыли не должна превышать 70 °C',
          'При варке сульфитной щелочи в цехе варочные банки должны помещаться в вытяжных шкафах с параметрами вытяжки согласно требованиям эксплуатирующей организации',
          'Материалы, используемые для приготовления формовочных и стержневых смесей, должны иметь сертификаты качества',
          'Взятие пробы смеси во время работы бегунов должно производиться механическим приспособлением',
        ],
        correctAnswers: [
          'При варке сульфитной щелочи в цехе варочные банки должны помещаться в вытяжных шкафах с параметрами вытяжки согласно требованиям эксплуатирующей организации',
        ],
      },
      {
        code: '63660217',
        text:
          'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          'На 0,5 величины рабочего давления охлаждающей жидкости',
          'На 1,25 величины рабочего давления охлаждающей жидкости',
          'На 1,5 величины рабочего давления охлаждающей жидкости',
          'На 2,5 величины рабочего давления охлаждающей жидкости',
        ],
        correctAnswers: [
          'На 1,5 величины рабочего давления охлаждающей жидкости',
        ],
      },
      {
        code: '63660218',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Газом', 'Сжатым воздухом', 'Паром'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63660219',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: ['Из серого чугуна', 'Из стали', 'Из алюминия', 'Из меди'],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63660220',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В журнал технического обслуживания и ремонта оборудования',
          'В паспорт газопровода',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63660221',
        text:
          'Какое расчетное давление газа соответствует газопроводам среднего давления?',
        answers: [
          'Свыше 0,5 до 1,0 МПа включительно',
          'Свыше 0,6 до 1,2 МПа включительно',
          'Свыше 0,1 до 0,3 МПа включительно',
          'Свыше 0,3 до 0,6 МПа включительно',
        ],
        correctAnswers: ['Свыше 0,1 до 0,3 МПа включительно'],
      },
      {
        code: '63660222',
        text:
          'Какие требования к внепечной обработке жидкого металла указаны неверно?',
        answers: [
          'Ввод раскислителей и легирующих материалов в ковш с жидким металлом под вакуумом должен осуществляться через вакуумный шлюз, конструкция которого должна обеспечивать сохранение вакуума в системе',
          'Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией',
          'Высота наполнения ковша металлом и общее количество присадок, обеспечивающее безопасное выполнение технологических операций, должны определяться производственной документацией',
          'Агрегаты внепечной обработки жидкого металла должны быть оборудованы системой управления, обеспечивающей функционирование механизмов в безопасных режимах и автоматическое отключение агрегатов при отклонении контролируемых параметров от заданных',
        ],
        correctAnswers: [
          'Процесс выдувания порошкообразного материала из пневмонагнетателя и тракта подачи должен осуществляться вручную в соответствии с производственной документацией',
        ],
      },
      {
        code: '63660223',
        text:
          'При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?',
        answers: ['10 мг/м3', '15 мг/м3', '20 мг/м3', '25 мг/м3'],
        correctAnswers: ['20 мг/м3'],
      },
      {
        code: '63660224',
        text:
          'При каких показателях газа разрешено применять жидкостные манометры?',
        answers: [
          'При давлении до 0,03 МПа',
          'При давлении до 0,04 МПа',
          'При давлении до 0,05 МПа',
        ],
        correctAnswers: ['При давлении до 0,03 МПа'],
      },
      {
        code: '63660225',
        text:
          'При каком условии должно проводиться покрытие поверхности форм и стержней противопригарными красками, выделяющими вредные вещества?',
        answers: [
          'Под вытяжкой в соответствии с технологической инструкцией',
          'При условии проветривания помещения',
          'При наличии системы кондиционирования воздуха',
          'При наличии естественной вентиляции',
        ],
        correctAnswers: [
          'Под вытяжкой в соответствии с технологической инструкцией',
        ],
      },
      {
        code: '63660226',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: ['Один раз в год', 'Один раз в 4 года', 'Один раз в 2 года'],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63660227',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Главный инженер предприятия',
          'Лицо, ответственное за безопасное производство работ',
          'Руководитель организации',
          'Начальник производственного участка',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63660228',
        text:
          'С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?',
        answers: [
          'Один раз в четыре года',
          'Один раз в год',
          'Один раз в три года',
          'Один раза в пять лет',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63660229',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?',
        answers: [
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации',
          'Не допускается уборка, вызывающая распыление вредных веществ',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63660230',
        text:
          'Какие требования к цинкованию металлов и полимерным покрытиям указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Нанесение защитных покрытий на листовой металл (лужение, оцинкование) следует производить на установках непрерывного действия',
          'Все операции по транспортированию и обработке проката при нанесении защитных покрытий, а также вспомогательные операции (смена анодов в ваннах электролитического лужения и оцинкования, загрузка металла в ванну с расплавом, приготовление, подача и очистка растворов) должны быть механизированы и выполняться в соответствии с технологическими инструкциями',
          'Оборудование для нанесения защитных покрытий (полимерных материалов) должно иметь местные отсосы',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63660231',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Система спутникового мониторинга',
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63660232',
        text:
          'Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?',
        answers: [
          'Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'Проливы растворителей на пол не допускаются, в случае случайного пролива растворитель должен быть немедленно убран с помощью сухих материалов',
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
        correctAnswers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
      },
      {
        code: '63660233',
        text:
          'Какие работы на всех электропечах (за исключением тигельных печей сопротивления) должны производиться только после снятия напряжения с нагревательных элементов? Выберите правильный вариант ответа.',
        answers: [
          'Только загрузка шихты и подшихтовка',
          'Только введение присадок и перемешивание расплавленного металла',
          'Только снятие шлака и взятие проб',
          'Все перечисленные виды работ',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63660234',
        text:
          'В каком месте может быть установлен запорный вентиль на топливопроводе каждой пламенной печи, работающей на жидком или газовом топливе? Выберите 2 варианта ответа.',
        answers: [
          'У форсунки',
          'У горелки',
          'На расстоянии 10 м от печи',
          'На расстоянии 5 м от печи',
        ],
        correctAnswers: ['У форсунки', 'У горелки'],
      },
      {
        code: '63660235',
        text:
          'На какое значение нижняя отметка борова в футеровке должна быть выше нижней отметки загрузочного окна во избежание попадания расплавленного металла в боров пламенной печи?',
        answers: [
          'Не менее чем на 50 мм',
          'Не менее чем на 100 мм',
          'Не менее чем на 120 мм',
          'Не менее чем на 70 мм',
        ],
        correctAnswers: ['Не менее чем на 100 мм'],
      },
      {
        code: '63660236',
        text:
          'Какой системой вентиляции должны быть оборудованы пламенные печи, работающие на жидком и твердом топливе?',
        answers: [
          'Вытяжной системой вентиляции',
          'Приточной системой вентиляции',
          'Общеобменной системой вентиляции',
        ],
        correctAnswers: ['Вытяжной системой вентиляции'],
      },
      {
        code: '63660237',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: [
          'Более 0,5 мм/г',
          '0,1 - 0,5 мм/г',
          'До 0,1 мм/г',
          'Не регламентируется',
        ],
        correctAnswers: ['0,1 - 0,5 мм/г'],
      },
      {
        code: '63660238',
        text:
          'При какой температуре должны храниться на специально оборудованном складе компоненты полиуретанового клея и растворители?',
        answers: [
          'Не ниже 5°C',
          'Не ниже 10 °C',
          'Не ниже 15 °C',
          'Не ниже 25 °C',
        ],
        correctAnswers: ['Не ниже 10 °C'],
      },
      {
        code: '63660239',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 16 мм',
          'Не менее 12 мм',
          'Не менее 5 мм',
          'Не менее 10 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63660240',
        text:
          'После чего выполняется включение электропечи (для просушки или плавки металла)?',
        answers: [
          'После осмотра главным инженером',
          'После осмотра дежурным электриком',
          'После осмотра работником, ответственным за безопасную эксплуатацию электропечи',
          'После осмотра начальником смены',
        ],
        correctAnswers: ['После осмотра дежурным электриком'],
      },
      {
        code: '63660241',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Производитель (руководитель) работ',
          'Работник, ответственный за осуществление производственного контроля в организации',
          'Выдающий наряд-допуск',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63660242',
        text:
          'Какое из перечисленных требований не допускается при эксплуатации вакуумно-дуговых печей? Выберите правильный вариант ответа.',
        answers: [
          'Только выдавливание штоком слитка в кристаллизаторе в случае его зависания',
          'Только использование открытого огня при осмотре внутренних частей печи',
          'Только полное сплавление электрода',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63660243',
        text:
          'Что должно быть у каждого плавильного агрегата с выпуском металла через летку?',
        answers: [
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
          'Должны быть три штанги длиной не менее 2,0 м и запасные пробки для закрывания леток',
          'Должны быть две штанги длиной не менее 1,0 м и запасные пробки для закрывания леток',
        ],
        correctAnswers: [
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
        ],
      },
      {
        code: '63660244',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63660245',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
          'Оповещение эксплуатационного персонала о начале смены и об ее окончании',
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63660246',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в полгода',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63660247',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,1% в час',
          'Не более 0,4% в час',
          'Не более 0,3% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63660248',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов',
          'Должна устраняться с помощью ручной регулировки',
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63660249',
        text:
          'В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?',
        answers: [
          'Крышка должна быть закрыта',
          'Крышка должна быть открыта',
          'Крышка должна быть снята',
        ],
        correctAnswers: ['Крышка должна быть закрыта'],
      },
      {
        code: '63660250',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже двух раз в три года',
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63660251',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63660252',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенного вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['25 м', '30 м', '45 м', '50 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63660253',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 7% кислорода',
          'Пока на участке продувки кислорода будет не более 1% кислорода',
          'Пока на участке продувки кислорода будет не более 5% кислорода',
          'Пока на участке продувки кислорода будет не более 3% кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
        ],
      },
      {
        code: '63660254',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?',
        answers: ['1%', '3%', '2%', '4%'],
        correctAnswers: ['1%'],
      },
      {
        code: '63660255',
        text:
          'Какое из перечисленных требований к газокислородным горелкам дуговых электропечей указано неверно?',
        answers: [
          'Горелка перед включением должна быть продута кислородом, после чего должен подаваться газ',
          'Для установки газокислородной горелки в завалочное окно электропечи в крышке окна должно быть устроено специальное отверстие, соответствующее размерам горелки',
          'Газокислородные горелки должны быть оборудованы запорной арматурой, а также приборами, контролирующими расход и давление газа, кислорода и охлаждающей воды',
          'Отключение горелки должно производиться в том же порядке, что и включение',
        ],
        correctAnswers: [
          'Отключение горелки должно производиться в том же порядке, что и включение',
        ],
      },
      {
        code: '63660256',
        text: 'Через какое расстояние должны заземляться наружные газопроводы?',
        answers: [
          'Через каждые 50 м',
          'Через каждые 150 м',
          'Через каждые 250 м',
          'Через каждые 300 м',
        ],
        correctAnswers: ['Через каждые 250 м'],
      },
      {
        code: '63660257',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Еженедельно', 'Ежемесячно', 'Ежеквартально', 'Ежедневно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63660258',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'При стабильной работе газоиспользующего оборудования',
          'На усмотрение ответственного за безопасное производство работ',
          'В случае проведения капитального ремонта газоиспользующего оборудования или внесения конструктивных изменений, влияющих на эффективность использования газа',
          'В случае систематических отклонений контролируемых параметров работы газоиспользующего оборудования от режимных карт',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63660259',
        text:
          'Какая должна быть температура угольной пыли в бункерах для хранения угольной пыли?',
        answers: [
          'Не более 60 °C',
          'Не более 50 °C',
          'Не более 70 °C',
          'Не более 80 °C',
        ],
        correctAnswers: ['Не более 70 °C'],
      },
      {
        code: '63660260',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Конструкции асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
          'Фонарные переплеты',
          'Оконные переплеты с обычным оконным стеклом толщиной 5 мм и площадью 1,5 м2',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
        ],
      },
      {
        code: '63660261',
        text:
          'Какое из перечисленных требований при эксплуатации открытых индукционных печей указано верно? Выберите 2 варианта ответа.',
        answers: [
          'При работе печи подача влажной шихты и ферросплавов в расплавленную ванну при догрузке печи должна производиться с органичением времени загрузки',
          'Контроль за непрерывным поступлением охлаждающей воды в индуктор печи должен производиться только визуально',
          'При проведении работ, связанных с применением неизолированного металлического инструмента, печь должна быть отключена',
          'Каркас индукционной печи должен быть изолирован от витков обмотки индуктора',
        ],
        correctAnswers: [
          'При проведении работ, связанных с применением неизолированного металлического инструмента, печь должна быть отключена',
          'Каркас индукционной печи должен быть изолирован от витков обмотки индуктора',
        ],
      },
      {
        code: '63660262',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Разрешено при увеличении давления газа на 10%',
          'Только в аварийных случаях',
          'Запрещено',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63660263',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Начальник цеха',
          'Работник, ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
          'Главный инженер',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63660264',
        text:
          'Какое из перечисленных требований к дуговым электропечам указано верно? Выберите два варианта ответа.',
        answers: [
          'Расположение центра тяжести дуговых электропечей должно обеспечивать возврат печи в вертикальное положение при выходе из строя механизма наклона (поворота) печи',
          'Наклоняющиеся и качающиеся электропечи с электроприводом должны иметь ограничители наклона, самотормозящие устройства',
          'Фундамент печи должен иметь уклон в противоположную сторону от литейного зала для удобной очистки печи',
          'Направление вращения маховика пускателя (контроллера) должно быть противоположным направлению наклона печи',
        ],
        correctAnswers: [
          'Расположение центра тяжести дуговых электропечей должно обеспечивать возврат печи в вертикальное положение при выходе из строя механизма наклона (поворота) печи',
          'Наклоняющиеся и качающиеся электропечи с электроприводом должны иметь ограничители наклона, самотормозящие устройства',
        ],
      },
      {
        code: '63660265',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых металлических ящиках с крышками',
          'В специальной стеклянной таре',
          'В пластиковых коробах со съемными крышками',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63660266',
        text:
          'Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа.',
        answers: ['СO2', 'H2', 'CO', 'O2'],
        correctAnswers: ['CO', 'O2'],
      },
      {
        code: '63660267',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63660268',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На прочность соединений путем проведения гидравлических испытаний',
          'На плотность под рабочим давлением',
          'На отсутствие загрязнений при проведении внешнего осмотра',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63660269',
        text:
          'В каком количестве должны храниться обтирочные материалы, пропитанные лаком и растворителями, в закрытой таре?',
        answers: [
          'В количестве, не превышающем их суточное потребление',
          'В количестве, не превышающем их недельное потребление',
          'В количестве, не превышающем их месячное потребление',
        ],
        correctAnswers: [
          'В количестве, не превышающем их суточное потребление',
        ],
      },
      {
        code: '63660270',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу А', 'Классу В', 'Классу C', 'Классу D'],
        correctAnswers: ['Классу А'],
      },
      {
        code: '63660271',
        text:
          'Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?',
        answers: [
          'Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла',
          'Работы должны выполняться только до начала плавления шихты',
          'Работы должны выполняться после выпуска металла',
        ],
        correctAnswers: [
          'Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла',
        ],
      },
      {
        code: '63660272',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В отдельных щитовых помещениях (встроенных или пристроенных)',
          'В смежных помещениях с взрывоопасными зонами',
          'В производственных помещениях',
          'В отдельно стоящих зданиях',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63660273',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Огнестойкие шпалы',
          'Деревянные шпалы',
          'Деревянные шпалы, пропитанные креозотом',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63660274',
        text:
          'Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          'Сроки, определенные разработчиком ВРУ',
          'Сроки, установленные лицом, ответственным за безопасное производство работ',
          'Сроки, утвержденные руководителем эксплуатирующей организации',
          'Не регламентируются',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63660275',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63660276',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63660277',
        text:
          'Чем должен производиться подогрев мазута в баках топлива пламенных печей? Выберите 2 варианта ответа.',
        answers: [
          'Паром',
          'Горячей водой',
          'Потоком теплого воздуха',
          'Электрическим нагревом',
        ],
        correctAnswers: ['Паром', 'Горячей водой'],
      },
      {
        code: '63660278',
        text:
          'Какое из перечисленных требований к установке сероочистки природного газа указано верно?',
        answers: [
          'Установка сероочистки должна иметь подвод воздуха',
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
          'Два раз в год газопроводы сероочистки подвергаются пневматическому испытанию',
          'Содержание метана в двух последовательно отобранных пробах должно быть не более 1,5%',
        ],
        correctAnswers: [
          'При перерыве в работе горелок газонагревателя более 30 мин необходимо установить заглушку на газопроводе топливного газа',
        ],
      },
      {
        code: '63660279',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63660280',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['2 м', '3,5 м', '3 м', '5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63660281',
        text:
          'Каким образом должно осуществляться хранение сыпучих материалов?',
        answers: [
          'В герметичной таре',
          'В закрытых коробах, подключенных к системе вытяжной вентиляции',
          'В несгораемом, плотно закрывающемся металлическом ящике',
          'В специальных цеховых кладовых',
        ],
        correctAnswers: [
          'В закрытых коробах, подключенных к системе вытяжной вентиляции',
        ],
      },
      {
        code: '63660282',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Устранение утечек газа из разъемных соединений технических устройств',
          'Осмотр фильтра и (при необходимости) очистка фильтрующего элемента',
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Проверка работоспособности запорной арматуры',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
    ],
    63661: [
      {
        code: '63661000',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '3м', '1,5 м', '5 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63661001',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным пневматическим приводом и автоматическим управлением',
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63661002',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Двусторонняя громкоговорящая связь с диспетчерской службой',
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Мобильная связь с диспетчерской службой предприятия',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63661003',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: [
          'Более 0,5 мм/г',
          '0,1 - 0,5 мм/г',
          'Менее 0,1 мм/г',
          'Не регламентируется',
        ],
        correctAnswers: ['0,1 - 0,5 мм/г'],
      },
      {
        code: '63661004',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 700 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 650 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 815 °C',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63661005',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна быть удобной в эксплуатации',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
          'Конструкция завалочной машины должна обеспечивать устойчивость при выполнении операций',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63661006',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Начальник цеха',
          'Работник, ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
          'Главный инженер',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63661007',
        text:
          'Какое допускается максимальное содержание оксида углерода в газах, поступающих в электрофильтр, при отводе газов с полным дожиганием?',
        answers: ['2 %', '1 %', '3 %', '4 %'],
        correctAnswers: ['1 %'],
      },
      {
        code: '63661008',
        text:
          'С какой периодичностью должны проверяться на работоспособность вентиляционные системы и состояние ванн травления?',
        answers: ['Ежемесячно', 'Ежедневно', 'Еженедельно', 'Ежедекадно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63661009',
        text:
          'Какой минимальный состав бригады должен производить работы в газоопасных местах, например, при разгерметизации технических устройств или коммуникаций?',
        answers: [
          'Два человека',
          'Три человека',
          'Четыре человека',
          'Пять человек',
        ],
        correctAnswers: ['Два человека'],
      },
      {
        code: '63661010',
        text:
          'Каким документом должны определяться места установки термопар для контроля температуры кладки шахтной печи?',
        answers: [
          'Проектом',
          'Техническим регламентом',
          'Производственной инструкцией',
          'Паспортом оборудования',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63661011',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже одного раза в  месяц',
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети',
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63661012',
        text:
          'Какие из перечисленных требований при производстве меди методом пирометаллургической переработки в печи "Аусмелт" указаны неверно?',
        answers: [
          'Вскрытие люков, гидрозатворов, предохранительных клапанов газоотводящего тракта при работающей печи допускается в присутствии наблюдающего',
          'Эксплуатация печи при нарушении подачи охлаждающей воды к блокам шпуров и неисправной сигнализации запрещается',
          'Водоохлаждаемые элементы перед их установкой должны подвергаться гидравлическому испытанию на 1,5 величины рабочего давления охлаждающей жидкости',
          'Запуск системы подачи охлаждающей воды должен производиться до нагрева печи',
        ],
        correctAnswers: [
          'Вскрытие люков, гидрозатворов, предохранительных клапанов газоотводящего тракта при работающей печи допускается в присутствии наблюдающего',
        ],
      },
      {
        code: '63661013',
        text:
          'Какие из перечисленных требований при производстве медного и никелевого купороса указаны неверно?',
        answers: [
          'Глубокое обезмеживание маточных растворов методом электролиза должно выполняться в отдельном помещении, оснащенном системой приточно-вытяжной вентиляции',
          'Контроль за содержанием мышьяковистого водорода в воздухе указанного помещения должен осуществляться автоматическими газоанализаторами с устройством световой и звуковой сигнализации',
          'Все перечисленные требования указаны верно',
          'В случае если содержание мышьяковистого водорода в воздухе производственного помещения превышает ПДК, обслуживающий персонал должен быть выведен из помещения, а помещение проветрено',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661014',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть предварительно охлаждены',
          'Должны быть очищены от мусора',
          'Должны быть выдержаны в помещении в течение суток',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63661015',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы должны производиться в соответствии с технической документацией, согласованной с территориальным органом Ростехнадзора',
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
          'Работы должны производиться специально обученным цеховым обслуживающим персоналом',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63661016',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы постами с дежурными стрелочного поста (по ЕТКС)',
          'Должны быть оборудованы светофорами',
          'Должны быть оборудованы автоматической сигнализацией',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63661017',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На плотность под рабочим давлением',
          'На прочность под рабочим давлением',
          'На герметичность при рабочем давлении',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63661018',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть выдержаны в помещении не менее смены',
          'Должны быть выдержаны в помещении в течение суток',
          'Должны быть предварительно просушены или прокалены',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63661019',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63661020',
        text:
          'Чем производится отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Сухим подогретым воздухом',
          'Горячей водой',
          'Паром',
          'Газовоздушной горелкой',
          'Электрическим подогревом',
        ],
        correctAnswers: ['Сухим подогретым воздухом'],
      },
      {
        code: '63661021',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Проверке состояния',
          'Механическим испытаниям',
          'Техническому освидетельствованию',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63661022',
        text:
          'В какое положение должна выводиться фурма из-под дутья при помощи лебедки, оборудованной системой автоматического аварийного вывода фурмы, при производстве меди методом пирометаллургической переработки в печи "Аусмелт"?',
        answers: [
          'В верхнее положение',
          'В правое положение',
          'В левое положение',
          'В нижнее положение',
        ],
        correctAnswers: ['В верхнее положение'],
      },
      {
        code: '63661023',
        text:
          'Какое из перечисленных требований к установке сероочистки природного газа указано верно?',
        answers: [
          'Установка сероочистки природного газа для получения реформерного газа должна быть снабжена быстродействующими отсекающими клапанами на входе газа в установку, срабатывающими только при снижении давления газа от установленных проектом значений',
          'При перерыве в работе горелок газонагревателя более 30 минут установить заглушку на газопроводе топливного газа',
          'Газопроводы сероочистки необходимо подвергать пневматическому испытанию два раза в год',
          'Содержание CH4 в двух последовательно отобранных пробах должно быть не более 1,5%',
        ],
        correctAnswers: [
          'При перерыве в работе горелок газонагревателя более 30 минут установить заглушку на газопроводе топливного газа',
        ],
      },
      {
        code: '63661024',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть только установлены на расстоянии не менее 3 м от печей',
          'Должны быть установлены на расстоянии не менее 2 м от печей и должны быть защищены паровой завесой',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63661025',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 0,5 м',
          'Не менее 1,2 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63661026',
        text:
          'Какое из перечисленных требований к отводу конденсата из цеховых газопроводов указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Сброс конденсата через горелки печей и другого оборудования потребителя газа не допускается',
          'Конструкция конденсатоотводчиков, установленных в зданиях цехов, должна исключать возможность попадания газов в помещения',
          'Участки труб, отводящих конденсат, а также сами конденсатоотводчики, если цех не отапливается, должны быть утеплены',
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
        correctAnswers: [
          'Сброс конденсата из цеховых газопроводов следует производить через горелки печей',
        ],
      },
      {
        code: '63661027',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров?',
        answers: [
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Сливание расплава металла из ковшей и миксеров должно быть механизировано',
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63661028',
        text:
          'Какие требования установлены к снабжению литейных производств щелочью?',
        answers: [
          'Должны снабжаться сульфитной щелочью',
          'Должны снабжаться щелочью в виде порошка',
          'Должны снабжаться кристаллизованной щелочью в металлических бочках',
        ],
        correctAnswers: ['Должны снабжаться сульфитной щелочью'],
      },
      {
        code: '63661029',
        text:
          'Для чего печь "Аусмелт" оборудуется резервной газовоздушной горелкой?',
        answers: [
          'Для достижения поддержания температурных параметров огнеупорной кладки',
          'Для достижения поддержания температурных параметров расплава',
          'Для достижения поддержания температурных параметров котла-утилизатора',
          'Для всего перечисленного',
        ],
        correctAnswers: ['Для всего перечисленного'],
      },
      {
        code: '63661030',
        text:
          'До какой температуры должен отогреваться сосуд, бывший в эксплуатации, перед проведением работ по обезжириванию способом протирки и чем он должен продуваться?',
        answers: [
          'До температуры не ниже 15 °C и продувается азотом',
          'До температуры не ниже 20 °C и продувается воздухом',
          'До температуры не ниже 20 °C и продувается азотом',
          'До температуры не ниже 10 °C и продувается кислород',
        ],
        correctAnswers: ['До температуры не ниже 20 °C и продувается воздухом'],
      },
      {
        code: '63661031',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должны быть в наличии огнетушители',
          'Должны быть установлены ящики с песком',
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63661032',
        text:
          'Каким давлением необходимо испытывать на плотность технологические аппараты, работающие под давлением водорода ниже 0,07 МПа (0,7 кгс/см²), после капитального ремонта и вновь установленные перед пуском в эксплуатацию?',
        answers: [
          'Составляющим 1,20 Pраб, но не более 0,2 МПа (2 кгс/см²)',
          'Составляющим 1,25 Pраб, но не более 0,1 МПа (1 кгс/см²)',
          'Составляющим 1,10 Pраб, но не более 0,3 МПа (3 кгс/см²)',
          'Составляющим 2,0 Pраб, но не более 0,4 МПа (4 кгс/см²)',
        ],
        correctAnswers: [
          'Составляющим 1,25 Pраб, но не более 0,1 МПа (1 кгс/см²)',
        ],
      },
      {
        code: '63661033',
        text:
          'Какое оборудование из перечисленного должно быть защищено от статического электричества?',
        answers: [
          'Только оборудование для сушки, рассева, перегрузки и затаривания никелевых, медных и кобальтовых порошков',
          'Только оборудование для сушки, рассева, пересыпки и затаривания порошков драгоценных металлов',
          'Только оборудование для систем аспирации',
          'Все перечисленное оборудование',
        ],
        correctAnswers: ['Все перечисленное оборудование'],
      },
      {
        code: '63661034',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,5% в час',
          'Не более 1% в час',
          'Не более 0,1% в час',
          'Не более 0,25% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63661035',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Проверка должна производиться в условиях, исключающих искрообразование',
          'Проверка должна осуществляться только в специально выделенном для этого помещении',
          'Проверка может производиться непосредственно во взрывопожароопасных и пожароопасных помещениях',
        ],
        correctAnswers: [
          'Проверка должна производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63661036',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после их продувки и снижения температуры воздуха в них до 50 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 45 °C',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °C',
        ],
      },
      {
        code: '63661037',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу А', 'Классу D', 'Классу B', 'Классу C'],
        correctAnswers: ['Классу B'],
      },
      {
        code: '63661038',
        text:
          'При каком минимальном содержании водорода в воздухе производственных помещений технологическое оборудование, работающее в этом помещении, должно быть остановлено?',
        answers: [
          'Более 1% (по объему)',
          'Более 5% (по объему)',
          'Более 2% (по объему)',
          'Более 1,5% (по объему)',
        ],
        correctAnswers: ['Более 1% (по объему)'],
      },
      {
        code: '63661039',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в два года',
          'Не реже трех раз в год',
          'Не реже двух раз в два года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63661040',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Немного приоткрыт вентиль кислорода',
          'Открыт вентиль горючего газа',
          'Зажжена горючая смесь газов',
          'Кратковременная продувка рукава для удаления воздуха',
        ],
        correctAnswers: ['Немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63661041',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
          'Проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка должны производиться один раз в год',
          'Пылеуборка и дезинфекция вентиляционных каналов должны проводиться один раз в три года',
          'При зажигании горелок следует подавать максимальное количество воздуха',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63661042',
        text:
          'Какие действия необходимо выполнить перед снятием шунтов, устанавливаемых в ванной на производстве медных порошков?',
        answers: [
          'Закончить загрузку',
          'Проверить правильности расстановки электродов',
          'Заполнить ванну электролитом',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63661043',
        text:
          'Какая информация должна быть выбита на хвостовике каждой съемной заглушки газопровода?',
        answers: [
          'Только номер сертификата',
          'Только номер заглушки',
          'Только марка материала',
          'Только условное давление Pу и условный диаметр Ду',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63661044',
        text:
          'Из каких материалов не могут быть изготовлены разделители (заглушки) или другие изделия, помещаемые внутрь кислородопровода при его обезжиривании?',
        answers: [
          'Из поролона или других органических материалов',
          'Из латуни',
          'Из меди',
        ],
        correctAnswers: ['Из поролона или других органических материалов'],
      },
      {
        code: '63661045',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Загрузка шихты, не прошедшей пиротехнического контроля',
          'Загрузка пакетированного металлолома',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63661046',
        text:
          'Какое из перечисленных требований безопасности при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса и антисептика указано неверно?',
        answers: [
          'Допускается разгрузка горячих корок из ковшей на сырые площадки',
          'Температура печной кладки должна контролироваться. Места установки термопар определяются проектом',
          'Для удаления корок из ковшей, чаш и погрузки их с помощью мостового крана в цехе должно быть отведено специальное место',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Допускается разгрузка горячих корок из ковшей на сырые площадки',
        ],
      },
      {
        code: '63661047',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение 20 дней',
          'В течение 10 дней',
          'В течение одного месяца',
          'В течение трех месяцев',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63661048',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'В помещениях должна быть установлена аварийная вытяжная система',
          'В помещениях должны быть установлены механические приточно-вытяжные общеобменные вентиляции с кратностью воздухообмена не более 6 в час',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63661049',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться системы освещения, работающие от напряжения 30 В',
          'Должны предусматриваться системы освещения, работающие от напряжения не более 24 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63661050',
        text:
          'Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?',
        answers: [
          'Не менее 1,5 величины рабочего давления охлаждающей воды',
          'Не менее 2,5 величины рабочего давления охлаждающей воды',
          'Не менее 2,0 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: [
          'Не менее 1,5 величины рабочего давления охлаждающей воды',
        ],
      },
      {
        code: '63661051',
        text:
          'Когда должны выполняться работы по очистке пространства под печью, а также приямков от шлака и мусора?',
        answers: [
          'Работы должны выполняться только до начала плавления шихты',
          'Работы должны выполняться после выпуска металла',
          'Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла',
        ],
        correctAnswers: [
          'Работы должны выполняться только в начале плавления шихты до образования значительного количества жидкого металла',
        ],
      },
      {
        code: '63661052',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,01 МПа (0,1 кгс/см²)',
          'При давлении 0,03 МПа (0,3 кгс/см²)',
          'При давлении 0,05 МПа (0,5 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63661053',
        text:
          'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ['150 °C', '120 °C', '200 °C', '210 °C'],
        correctAnswers: ['150 °C'],
      },
      {
        code: '63661054',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Два раза в 6 лет',
          'Один раз в 8 лет',
          'Один раз в 5 лет',
          'Один раз в 3 года',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63661055',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Составляется новый акт-допуск на следующий срок',
          'Продлевается текущий акт-допуск',
          'Работы продолжаются без оформления акта-допуска',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63661056',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63661057',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'Акт-допуск должен оформляться письменно',
          'На один объект, площадку, территорию должен оформляться один акт-допуск',
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
          'Не допускается оформлять акты-допуски, характеризующиеся одинаковыми координатами рабочих зон',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63661058',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы должны быть продолжены. Оформление нового наряда-допуска не требуется',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
          'Работы должны быть прекращены. Для продолжения работ должна быть внесена запись в журнал регистрации нарядов-допусков на работы повышенной опасности',
          'Решение о продолжении или прекращении работы определяет выдающий наряд-допуск',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63661059',
        text:
          'Какая должна быть минимальная высота водяного затвора конденсатоотводчиков межцеховых газопроводов, работающих под избыточным давлением?',
        answers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
          'На 300 мм вод. ст. больше расчетного давления газа, но не менее 1000 мм вод. ст.',
          'На 350 мм вод. ст. больше расчетного давления газа, но не менее 1500 мм вод. ст.',
          'На 400 мм вод. ст. больше расчетного давления газа, но не менее 800 мм вод. ст.',
        ],
        correctAnswers: [
          'На 500 мм вод. ст. больше расчетного давления газа, но не менее 2000 мм вод. ст.',
        ],
      },
      {
        code: '63661060',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из углеродистой стали',
          'Из коррозионно-стойкой стали или медных сплавов',
          'Из чугуна',
        ],
        correctAnswers: ['Из коррозионно-стойкой стали или медных сплавов'],
      },
      {
        code: '63661061',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'При стабильной работе газоиспользующего оборудования',
          'В любом случае',
          'После капитального ремонта газоиспользующего оборудования или внесения конструктивных изменений, влияющих на эффективность использования газа',
          'В случае систематических отклонений контролируемых параметров работы газоиспользующего оборудования от режимных карт',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63661062',
        text:
          'Какое расчетное давление газа соответствует газопроводам промышленных газов среднего давления?',
        answers: [
          'Свыше 0,1 до 0,3 МПа включительно',
          'Свыше 0,3 до 1,2 МПа включительно',
          'До 0,1 МПа включительно',
          'Свыше 1,2 МПа включительно',
        ],
        correctAnswers: ['Свыше 0,1 до 0,3 МПа включительно'],
      },
      {
        code: '63661063',
        text:
          'Чем могут продуваться перед пуском и после остановки печь, автоклав, трубопроводы и свечи дожигания водорода? Выберите 2 варианта ответа.',
        answers: [
          'Азотом',
          'Влажным паром',
          'Сжатым воздухом',
          'Кислородом',
          'Углекислым газом',
        ],
        correctAnswers: ['Азотом', 'Влажным паром'],
      },
      {
        code: '63661064',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.',
        answers: [
          'Продувать рукав для горючих газов кислородом и кислородный рукав горючим газом',
          'Взаимозаменять рукава при работе',
          'При питании передвижного рабочего поста сжиженным газом от баллона отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого',
          'Устанавливать баллоны со сжатыми газами в наклонном положении с вентилем, направленным вниз',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63661065',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в год',
          'Не менее двух раз в три года',
          'Не менее трех в пять лет',
          'Не менее одного раза в три месяца',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63661066',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся под разрежением',
          'На газопроводах, продутых сжатым воздухом',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63661067',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'При перерыве в работе в течение одной смены (обеденном перерыве, перерыве по условиям производства работ) наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'При допуске бригады к работе допускающий к работе вручает первый экземпляр наряда-допуска производителю работ, а второй экземпляр наряда-допуска хранится вместе с нарядами, действующими на этот момент времени',
        ],
        correctAnswers: [
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
        ],
      },
      {
        code: '63661068',
        text:
          'Какие из перечисленных требований к эксплуатации электролизных цехов указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Высота стоп анодных остатков, укладываемых на транспортные тележки, не должна превышать 3 м',
          'Подвальные этажи цехов электролиза должны иметь освещение в соответствии с инструкцией',
          'Схема технологической цепи аппаратов для нанесения гальваностойкого покрытия, их конструкция и размещение должны исключать смешивание растворов цианистых соединений с кислыми растворами',
          'В цехах и на участках, где возможно выделение паров синильной кислоты, должны быть установлены автоматические газоанализаторы, сблокированные со звуковым сигналом, оповещающим о превышении ПДК указанных паров в воздухе рабочей зоны',
        ],
        correctAnswers: [
          'Схема технологической цепи аппаратов для нанесения гальваностойкого покрытия, их конструкция и размещение должны исключать смешивание растворов цианистых соединений с кислыми растворами',
          'В цехах и на участках, где возможно выделение паров синильной кислоты, должны быть установлены автоматические газоанализаторы, сблокированные со звуковым сигналом, оповещающим о превышении ПДК указанных паров в воздухе рабочей зоны',
        ],
      },
      {
        code: '63661069',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'Не допускается ни в каком случае',
          'В любом случае',
          'В аварийных случаях',
          'Если в радиусе 50 м приостановлено движение всех видов транспорта.',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63661070',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 1 год',
          'Не позднее чем через 4 года',
          'Не позднее чем через 3 года',
          'Не позднее чем через 2 года',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63661071',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В паспорт оборудования',
          'В специальный журнал по устранению дефектов',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63661072',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 1%',
          'Пока на участке продувки кислорода будет не более 2%',
          'Пока на участке продувки кислорода будет не более 1,5%',
          'Пока на участке продувки кислорода будет не более 3%',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1%',
        ],
      },
      {
        code: '63661073',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В вертикальном положении',
          'В наклонном',
          'В наклонном положении с вентилем, направленным вверх',
          'В горизонтальном положении',
          'В наклонном положении с вентилем, направленным вниз',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63661074',
        text:
          'Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?',
        answers: [
          'Должны защищаться специальным смазочным материалом, защищающим от воздействия лучистого тепла',
          'Должны покрываться огнестойкими составами для защиты от воздействия тепловой нагрузки',
          'Должны защищаться кожухами от воздействия лучистого тепла',
        ],
        correctAnswers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
        ],
      },
      {
        code: '63661075',
        text:
          'Какие из перечисленных печей должны быть оборудованы системами автоматического контроля и регулирования соотношения "шихта - кислород" и отключения дутья при прекращении подачи шихты, а также при отключении подачи природного газа, мазута, пылеугольного топлива, подаваемых на восстановление сернистого ангидрида в технологических газах? Выберите правильный вариант ответа.',
        answers: [
          'Только печи взвешенной плавки',
          'Только печи кислородно-факельной плавки',
          'Только печи кислородно-взвешенной циклонной плавки с электротермическим окончанием',
          'Только печи по выгрузке',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63661076',
        text:
          'Каким уплотнительным устройством, препятствующим выбиванию газов в цех, должно оборудоваться отверстие в охладителе конвертерных газов?',
        answers: [
          'Только  аэродинамической завесой',
          'Только воздушной завесо',
          'Только азотной завесой',
          'Любым из перечисленных',
        ],
        correctAnswers: ['Любым из перечисленных'],
      },
      {
        code: '63661077',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ.',
          'Установка по производству реформерного газа для производства металлизованного горячевосстановленного железа должна иметь подвод азота для его продувки перед пуском в работу',
          'Для регулирования давления реформерного газа после холодильника и сброса его на свечу должны быть установлены регулирующие клапаны, работающие в ручном или автоматическом режиме',
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ.',
        ],
      },
      {
        code: '63661078',
        text:
          'При каком увеличении содержания кислорода в газе в напорном коллекторе газоотсасывающей станции отвод газа от электропечи должен быть немедленно прекращен?',
        answers: ['2 %', '1 %', '1,5 %', '3 %'],
        correctAnswers: ['2 %'],
      },
      {
        code: '63661079',
        text:
          'Какие требования безопасности при производстве никеля и меди указаны верно? Выберите правильный вариант   ответа.',
        answers: [
          'Допускается разгрузка горячих корок из ковшей на сырые площадки',
          'При загрузке анодных печей оборотными материалами в первую очередь должны загружаться легковесные материалы, затем анодные остатки, скрап электролитного производства, другие материалы в соответствии с технологической инструкцией',
          'Скорость движения транспорта для перевозки шлака на сливных путях, переходах, неохраняемых переездах и в местах маневрирования составов не должна превышать 8 км/ч',
          'Не допускается слив шлака без отцепки локомотива от шлаковозного состава при наличии между шлаковозом и локомотивом железнодорожной платформы прикрытия',
        ],
        correctAnswers: [
          'При загрузке анодных печей оборотными материалами в первую очередь должны загружаться легковесные материалы, затем анодные остатки, скрап электролитного производства, другие материалы в соответствии с технологической инструкцией',
        ],
      },
      {
        code: '63661080',
        text:
          'Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?',
        answers: ['Не более двух', 'Не более трех', 'Не более четырех'],
        correctAnswers: ['Не более двух'],
      },
      {
        code: '63661081',
        text:
          'В соответствии с какой документацией должен проводиться разогрев печи "Аусмелт" после капитального ремонта?',
        answers: [
          'В соответствии с технологической инструкцией',
          'В соответствии с паспортом оборудования',
          'В соответствии с внутренними распорядительными документами организации',
          'В соответствии с техническим регламентом',
        ],
        correctAnswers: ['В соответствии с технологической инструкцией'],
      },
      {
        code: '63661082',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Не должны давать искры при работе с ними',
          'Должны быть изготовлены из легированной стали',
          'Должны быть изготовлены из чугуна',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63661083',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'С руководителем профессиональной аварийно-спасательной службы',
          'С территориальным органом Ростехнадзора',
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С техническим руководителем (главным инженером) организации',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63661084',
        text:
          'Сколько электроизоляционных разрывов должны иметь металлические каркасы желобов, расположенные поперек цеха производства меди?',
        answers: [
          'Между каждыми тремя ваннами',
          'Между каждыми двумя ваннами',
          'Между каждыми двумя рядами ванн',
          'Между каждыми рядами ванн',
        ],
        correctAnswers: ['Между каждыми двумя рядами ванн'],
      },
      {
        code: '63661085',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 10 минут',
          'В течение 5 минут',
          'В течение 30 минут',
          'В течение 20 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63661086',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 20 календарных дней',
          'На 30 календарных дней',
          'На 15 календарных дней',
          'На 45 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63661087',
        text:
          'Как часто должна производиться нивелировка действующих-межцеховых газопроводов?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в 3 года',
          'Один раз в год',
          'Ежегодно, на протяжении четырех лет после окончания строительства',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63661088',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На герметичность', 'На плотность', 'На прочность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63661089',
        text:
          'Какое из перечисленных требований к задвижкам и заглушкам, устанавливаемым на газопроводах, указано неверно?',
        answers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
          'Для плотного отключения отдельных участков газопроводов, газопотребляющих агрегатов и газовых аппаратов от действующих газопроводов после дисковых задвижек (по ходу газа) должны устанавливаться листовые задвижки или заглушки',
          'Съемные заглушки, устанавливаемые на трубопроводах, должны быть изготовлены согласно проектной документации и иметь паспорт (сертификат) изготовителя',
          'Паспорта (сертификаты) на съемные заглушки должны храниться у лица, ответственного за техническое состояние данного объекта газового хозяйства, или в журнале учета установки-снятия заглушек',
        ],
        correctAnswers: [
          'Заглушки необходимо применять при ремонтах, длительных остановках, ревизиях и аварийных условиях, даже если установлены листовые задвижки',
        ],
      },
      {
        code: '63661090',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I и II группы газоопасности',
          'На I, II и III группы газоопасности',
          'На I, II, III и IV группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63661091',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Устранение утечек газа из разъемных соединений технических устройств',
          'Осмотр фильтра и (при необходимости) очистка фильтрующего элемента',
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
      {
        code: '63661092',
        text:
          'В каком положении должна быть крышка завалочного окна во время работы газокислородной горелки?',
        answers: [
          'Крышка должна быть закрыта',
          'Крышка должна быть приоткрыта для наблюдения за работой горелки',
          'Крышка должна быть снята',
        ],
        correctAnswers: ['Крышка должна быть закрыта'],
      },
      {
        code: '63661093',
        text:
          'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          'Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 25 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
        correctAnswers: [
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
      },
      {
        code: '63661094',
        text:
          'Что должно предусматриваться для тушения тлеющей пыли и подавления горения пыли в бункерах? Выберите 2 варианта ответа.',
        answers: [
          'Подвод азота',
          'Ручное включение вентиляторов',
          'Подвод насыщенного пара',
          'Аварийная система вентиляции',
        ],
        correctAnswers: ['Подвод азота', 'Подвод насыщенного пара'],
      },
      {
        code: '63661095',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63661096',
        text:
          'Какое количество ступеней изоляции от заземлителя должен иметь крюк крана или другого подъемного устройства, используемого для загрузки электродной массы без отключения печи?',
        answers: [
          'Не менее двух последовательных ступеней',
          'Одну ступень',
          'Не более двух последовательных ступеней',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее двух последовательных ступеней'],
      },
      {
        code: '63661097',
        text:
          'Что из перечисленного должно предусматриваться технологической инструкцией?',
        answers: [
          'Только порядок эксплуатации на автоклавных установках',
          'Только порядок подачи воздуха на окисление паромазутной смеси или газа на восстановление',
          'Только порядок и сроки проверки исправности арматуры, контрольно-измерительных приборов и предохранительных устройств',
          'Только порядок загрузки, перемешивания и выгрузки материала из камерных печей, а также порядок осмотра и ремонта печей',
          'Должно предусматриваться все перечисленное',
        ],
        correctAnswers: ['Должно предусматриваться все перечисленное'],
      },
      {
        code: '63661098',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'При прокладке трубопроводов водорода следует использовать бесшовные стальные трубы или трубопроводы из нержавеющей стали, соединенные с применением сварки',
          'Проектирование, монтаж и эксплуатация водородопроводов должны производиться в соответствии с требованиями строительных норм и правил, а также нормативно-технической документации для технологических стальных трубопроводов и Правил безопасности процессов получения или применения металлов',
          'Толщина стенок трубопроводов выбирается при проектировании исходя из расчетного срока эксплуатации, с учетом расчетного давления и прибавки на коррозию',
          'Все перечисленные требования указаны верно.',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно.'],
      },
      {
        code: '63661099',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть предусмотрено в специально отведенных местах на стеллажах или в таре',
          'Должно быть предусмотрено на свободных площадках, обеспечивающих свободный проход',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально отведенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63661100',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'На расстоянии 15 м от зоны работы магнитного крана в случае, если кран работает внутри производственного здания',
          'В зоне погрузки грейферными или магнитными кранами',
          'На расстоянии 20 м от зоны работы грейферного крана в случае, если кран работает внутри производственного здания',
          'Во всех перечисленных зонах',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63661101',
        text:
          'В каких случаях следует немедленно перевести конвертер в нерабочее положение и принять меры к устранению неполадок?',
        answers: [
          'В случае прекращения подачи энергии на один из двигателей поворота',
          'В случае прогаре фурм',
          'В случае прогаре кожуха конвертера',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63661102',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
          'Только работниками подрядных организаций',
          'Только работниками эксплуатирующей организации',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63661103',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'С ГСС',
          'Со службой производственного контроля',
          'С территориальным органом Ростехнадзора',
          'С главным энергетиком',
        ],
        correctAnswers: ['С ГСС', 'Со службой производственного контроля'],
      },
      {
        code: '63661104',
        text:
          'Какие требования безопасности при производстве меди и никеля указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Исправность арматуры, контрольно-измерительных приборов и предохранительных устройств должна периодически проверяться в порядке и в сроки, предусмотренные технологической инструкцией',
          'Все перечисленные требования указаны верно',
          'В случае применения подшипников скольжения износ цапф конвертеров во всех измерениях не должен превышать 10% их первоначальных размеров',
          'Крюк крана или другого подъемного устройства, используемого для загрузки электродной массы без отключения печи, должен иметь не менее двух последовательных ступеней изоляции от заземлителя',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661105',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Должны расставляться только на горизонтальных площадках в любом месте пролета цеха',
          'Должны расставляться только на свободных площадках пролета цеха',
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63661106',
        text:
          'Какая допускается максимальная скорость движения транспорта для перевозки шлака на сливных путях, переходах, неохраняемых переездах и в местах маневрирования составов?',
        answers: ['5 км/ч', '7 км/ч', '10 км/ч', '12 км/ч'],
        correctAnswers: ['5 км/ч'],
      },
      {
        code: '63661107',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Могут находиться в неисправном состоянии',
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
          'Должны быть предназначены для выполнения любых работ',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63661108',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Газом', 'Кислородом', 'Азотом'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63661109',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Одним человеком под наблюдением газоспасателя или члена добровольной газоспасательной дружины',
          'Не менее трех человек',
          'Не менее двух человек',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63661110',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 6 месяцев',
          'В течение месяца',
          'В течение 5 месяцев',
          'В течение 3 месяцев',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63661111',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 50 м',
          'В радиусе 40 м',
          'В радиусе 35 м',
          'В радиусе 22 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63661112',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У работника, ответственного за техническое состояние объекта газового хозяйства',
          'У начальника цеха',
          'У главного инженера',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние объекта газового хозяйства',
        ],
      },
      {
        code: '63661113',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 8 лет',
          'Один раз в 5 лет',
          'Один раз в 3 года',
          'Два раза в 6 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63661114',
        text:
          'Каким документом должно определяться количество одновременно подтягиваемых вагонеток при работе на электрошпиле?',
        answers: [
          'Планом производства работ',
          'Техническим регламентом',
          'Технологической инструкцией',
          'Правилами безопасности процессов получения или применения металлов',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63661115',
        text:
          'Какое из перечисленных требований к потреблению газообразного кислорода и других продуктов разделения воздуха указано неверно?',
        answers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
          'Не допускается устанавливать и применять кислородное оборудование возле замасляного или прожированного оборудования, территории',
          'Использование продуктов разделения воздуха не по назначению не допускается',
          'Использование продуктов разделения воздуха по каждому производству, участку или объекту должно осуществляться по технологическим инструкциям',
        ],
        correctAnswers: [
          'Дверцы шкафа при выполнении работы должны быть закрыты',
        ],
      },
      {
        code: '63661116',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Строительно-монтажной организацией',
          'Руководителем организации',
          'Главным инженером',
          'Главным механиком',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63661117',
        text:
          'Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?',
        answers: ['Менее 100 м', 'Менее 110 м', 'Менее 120 м', 'Менее 130 м'],
        correctAnswers: ['Менее 100 м'],
      },
      {
        code: '63661118',
        text:
          'Какое требование к площадкам обслуживания электропечей при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса, антисептика указано неверно?',
        answers: [
          'На площадках обслуживания электропечей должна быть предусмотрена световая сигнализация, предупреждающая персонал о том, что агрегат находится под напряжением',
          'Световая сигнализация должна быть сблокирована с высоковольтным выключателем',
          'Перед включением печи в работу должен подаваться предупредительный звуковой сигнал',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661119',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Техническое обслуживание',
          'Текущий ремонт',
          'Проверка срабатывания предохранительных запорных и сбросных клапанов',
          'Капитальный ремонт при замене оборудования',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63661120',
        text:
          'Какие технические устройства должны быть защищены от статического электричества?',
        answers: [
          'Только технические устройства для сушки и рассева медных порошков',
          'Только технические устройства для перегрузки и затаривания никелевых порошков',
          'Только технические устройства для перегрузки и затаривания медных и кобальтовых порошков',
          'Все перечисленные технические устройства',
        ],
        correctAnswers: ['Все перечисленные технические устройства'],
      },
      {
        code: '63661121',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки установленные изготовителем',
          'В сроки установленные эксплуатирующей организацией',
          'В сроки установленные инструкцией',
        ],
        correctAnswers: ['В сроки установленные изготовителем'],
      },
      {
        code: '63661122',
        text:
          'Какие действия необходимо предпринять при подозрении на вспенивание (переокисление) в ванне печи "Аусмелт"?',
        answers: [
          'Вывести работников в безопасную зону',
          'Прекратить шихтоподачу и дутье фурмы',
          'Подать уголь',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63661123',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 18% и не более 24%',
          'Не менее 17% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63661124',
        text:
          'Какие из перечисленных требований при производстве медного и никелевого купороса указаны неверно?',
        answers: [
          'Технологические аппараты для переработки растворов при атмосферном давлении должны быть оборудованы переливными трубами, связанными с резервными емкостями, или снабжены сигнализирующими устройствами, предупреждающими о переполнении аппаратов',
          'Смотровые люки и лазы на крышках аппаратуры должны быть снабжены решетками или плотно закрывающимися крышками',
          'В случае если содержание мышьяковистого водорода в воздухе производственного помещения превышает ПДК, обслуживающий персонал должен надеть СИЗОД до момента проветривания помещения',
          'Контроль за содержанием мышьяковистого водорода в воздухе указанного помещения должен осуществляться автоматическими газоанализаторами с устройством световой и звуковой сигнализации',
        ],
        correctAnswers: [
          'В случае если содержание мышьяковистого водорода в воздухе производственного помещения превышает ПДК, обслуживающий персонал должен надеть СИЗОД до момента проветривания помещения',
        ],
      },
      {
        code: '63661125',
        text:
          'В каком случае арматура, предназначенная для работы с кислородом, не подлежит обезжириванию перед монтажом?',
        answers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
          'В любом случае подлежит обезжириванию',
          'В случае если детали были подвергнуты охлаждению жидким азотом',
          'В случае если детали контактировали с кислородом',
        ],
        correctAnswers: [
          'Если обезжиривание было проведено на заводе-изготовителе (что подтверждается сопроводительными документами) и не нарушена упаковка',
        ],
      },
      {
        code: '63661126',
        text:
          'Какое допускается максимальное содержание водорода в продувочном газе после остановки продувки автоклава?',
        answers: [
          'Водород в продувочном газе должен отсутствовать',
          '5%',
          '7%',
          '6%',
        ],
        correctAnswers: ['Водород в продувочном газе должен отсутствовать'],
      },
      {
        code: '63661127',
        text:
          'Какие требования безопасности при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса, антисептика указаны верно?',
        answers: [
          'Персонал, работающий в помещениях сушки, прокалки и восстановления порошка в атмосфере водорода (восстановителе), должен иметь переносные газоанализаторы для контроля содержания водорода в атмосфере помещений',
          'После проведения испытаний на плотность окисью углерода технические устройства и трубопроводы перед пуском в работу должны заполняться азотом',
          'Реакционные печи восстановления закиси никеля и газоходы должны быть снабжены взрывными предохранительными устройствами',
          'При производстве никелевого порошка карбонильным способом технические устройства и трубопроводы перед подачей в них токсичных и взрывоопасных веществ должны быть продуты сжатым воздухом',
        ],
        correctAnswers: [
          'Реакционные печи восстановления закиси никеля и газоходы должны быть снабжены взрывными предохранительными устройствами',
        ],
      },
      {
        code: '63661128',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Организация, проектирующая систему автоматики печи',
          'Организация, эксплуатирующая объект',
          'Руководителем организации',
          'Сервисными службами изготовителя',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63661129',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже двух раз в три года',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63661130',
        text:
          'Чем должны быть оборудованы коллекторы кислородно-воздушной смеси, подаваемой в печь Ванюкова?',
        answers: [
          'Только взрывными клапанами',
          'Только отсекающими устройствами',
          'Только автоматическими газоанализаторами',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63661131',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 0,5 м',
          'Не менее 0,4 м',
          'Не менее 0,7 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63661132',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в четыре года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63661133',
        text:
          'При каком содержании газов должны срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4 % об.), кислорода менее 19 % и более 23 %, угарного газа более 20 мг/м3, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25 % нижнего предела взрываемости (0,85 % об.), кислорода менее 35 % и более 40 %, угарного газа более 29 мг/м³, метана не более 5 % нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 20 % нижнего предела взрываемости (0,8 % об.), кислорода менее 25 % и более 34 %, угарного газа более 25 мг/м³, метана не более 50 % нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 50 % нижнего предела взрываемости (1,4 % об.), кислорода менее 5 % и более 20 %, угарного газа более 60 мг/м³, метана не более 17 % нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4 % об.), кислорода менее 19 % и более 23 %, угарного газа более 20 мг/м3, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63661134',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,5 м',
          'Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
          'Все перечисленные',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63661135',
        text:
          'Какие из перечисленных ремонтных работ допускается производить одновременно при ремонте арматуры, расположенной на теплом и холодном концах регенераторов в период остановки ВРУ?',
        answers: [
          'Принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'Трубопроводов и арматуры теплого или холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов, или механизма переключения',
          'Никакие из перечисленных',
          'Трубопроводов и арматуры теплого и холодного концов регенераторов',
        ],
        correctAnswers: ['Никакие из перечисленных'],
      },
      {
        code: '63661136',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В непосредственной близости от агрегатов',
          'На расстоянии не менее 5 м от агрегатов',
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63661137',
        text:
          'Чем должен быть оборудован барабан-сепаратор на производстве никеля и меди?',
        answers: [
          'Только предохранительными клапанами',
          'Только сигнализаторами предельных уровней воды и ее давления',
          'Только линией периодической продувки',
          'Только пробоотборником пара и воды',
          'Всеми перечисленными устройствами',
        ],
        correctAnswers: ['Всеми перечисленными устройствами'],
      },
      {
        code: '63661138',
        text:
          'Что должно быть у каждого плавильного агрегата с выпуском металла через летку?',
        answers: [
          'Две штанги длиной не менее 1 м и запасные пробки для закрывания леток',
          'Две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
          'Три штанги длиной не менее 2 м и запасные пробки для закрывания леток',
        ],
        correctAnswers: [
          'Две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
        ],
      },
      {
        code: '63661139',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
          'Приборы должны устанавливаться в непосредственной близости к оборудованию',
          'Приборы должны устанавливаться на расстоянии не менее 5 м от оборудования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63661140',
        text:
          'До какой максимальной температуры допускается нагрев поверхностей в помещениях, где производится обезжиривание кислородного оборудования пожаровзрывоопасными растворителями?',
        answers: ['120 °C', '130 °C', '150 °C', '170 °C'],
        correctAnswers: ['120 °C'],
      },
      {
        code: '63661141',
        text:
          'Какие действия необходимо осуществить перед ремонтом клапанов (трубопроводов) теплого и холодного концов регенераторов в период остановки ВРУ? Выберите правильный вариант ответа.',
        answers: [
          'Систему автоматического управления регенераторов следует перевести на ручной режим',
          'С помощью дистанционного управления закрыть или открыть соответствующие клапаны',
          'Установить предупреждающий знак безопасности "Не включать, работают люди!"',
          'Необходимо осуществить все перечисленные действия',
        ],
        correctAnswers: ['Необходимо осуществить все перечисленные действия'],
      },
      {
        code: '63661142',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Допускается эксплуатация печей при остаточном разрежении предельно допустимого значения на 5%',
          'Допускается эксплуатация печей при остаточном разрежении предельно допустимого значения на 15%',
          'Не допускается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%',
        ],
        correctAnswers: [
          'Не допускается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63661143',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
          'После снижения температуры воздуха до 45 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
          'После снижения температуры воздуха до 50 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °C) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °C и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °C) и проветривания',
        ],
      },
      {
        code: '63661144',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 20 часов',
          'Не менее 10 часов',
          'Не менее 15 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63661145',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Горячей водно-масляной эмульсией',
          'Горячим воздухом, паром или горячей водой',
          'Паяльной лампой',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63661146',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 1,5 года',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63661147',
        text:
          'Какие требования к площадке конвертерщика-фурмовщика указаны неверно?',
        answers: [
          'Площадка конвертерщика-фурмовщика должна иметь не менее двух выходов, расположенных с противоположных сторон',
          'Над площадкой конвертерщика-фурмовщика должно постоянно быть включено световое табло "Высокая температура"',
          'Привод поворота конвертера должен иметь не менее двух двигателей',
          'При прекращении подачи энергии на один из двигателей поворота, прогаре фурм или кожуха конвертера следует немедленно перевести конвертер в нерабочее положение и принять меры к устранению неполадок',
        ],
        correctAnswers: [
          'Над площадкой конвертерщика-фурмовщика должно постоянно быть включено световое табло "Высокая температура"',
        ],
      },
      {
        code: '63661148',
        text:
          'Каким документом должны определяться схема контроля и периодичность замеров величины сопротивления изоляции технических устройств в электролизных цехах и отделениях?',
        answers: [
          'Технологической инструкцией',
          'Паспортом оборудования',
          'Внутренним регламентом',
          'Техническим регламентом',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63661149',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии положительного заключения экспертного обследования',
          'При наличии разрешения завода-изготовителя',
          'При наличии письменного распоряжения руководителя производства (цеха)',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63661150',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы',
          'Деревянные шпалы, пропитанные креозотом',
          'Огнестойкие шпалы',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63661151',
        text:
          'Какие требования к металлическим коммуникациям систем пароводоснабжения, сжатого воздуха и вентиляции в залах электролиза указаны неверно?',
        answers: [
          'Должны быть размещены на высоте не менее 1,5 м от рабочих площадок',
          'Должны быть изолированы от земли или ограждены',
          'Должны иметь электроизоляционные разрывы по длине цеха, а также на входе в здание и выходе из него',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Должны быть размещены на высоте не менее 1,5 м от рабочих площадок',
        ],
      },
      {
        code: '63661152',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 96,5%',
          'Не менее 98,0%',
          'Не менее 97,7%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63661153',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['2,5 часа', '1,5 часа', '1 час', '2 часа'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63661154',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'При давлении на 15% больше расчетного',
          'При давлении на 10% больше расчетного',
          'При давлении на 20% больше расчетного',
          'При давлении на 5% больше расчетного',
        ],
        correctAnswers: ['При давлении на 15% больше расчетного'],
      },
      {
        code: '63661155',
        text:
          'В каком из перечисленных случаев запрещено возобновлять подачу воды к любому из водоохлаждаемых компонентов печи "Аусмелт"?',
        answers: [
          'Если температура этих компонентов превышает 120 °C',
          'Если температура этих компонентов превышает 100 °C',
          'Если температура этих компонентов превышает 150 °C',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Если температура этих компонентов превышает 150 °C'],
      },
      {
        code: '63661156',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При незначительном падении давления воды в системе водяного охлаждения',
          'При разгерметизации системы водяного охлаждения этих агрегатов',
          'При незначительном повышении давления воды в системе водяного охлаждения',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63661157',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
          'Один производитель (руководитель) работ',
          'Количество производителей (руководителей) работ определяет выдающий наряд-допуск',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63661158',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63661159',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Огнетушители',
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Ящик с асбестовым полотном',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63661160',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Должны храниться в специальном помещении не менее 2 суток перед их установкой',
          'Должны быть защищены специальными экранами от нагревания теплоизлучением',
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63661161',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,3 кПа',
          'Не более 0,4 кПа',
          'Не более 0,5 кПа',
          'Не более 0,6 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63661162',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от возможного попадания на них расплава',
          'Только от тепловых воздействий',
          'Только от механических воздействий',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63661163',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенного вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['50 м', '20 м', '15 м', '10 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63661164',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Запорная арматура и отсекающие устройства с дистанционным управлением должны устанавливаться вне здания на расстоянии не менее 2,0 м и не более 100,0 м от места ввода в здание или ближайшего аппарата, стоящего вне здания',
          'В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 5,0 МПа и температуре от 0 °C до 50 °C, работающей в условиях, не подверженных вибрациям и резко переменного температурного режима',
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63661165',
        text:
          'Стальными щитами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 16 мм',
          'Не менее 9 мм',
          'Не менее 5 мм',
          'Не менее 10 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63661166',
        text:
          'Какое оборудование из перечисленного перед пуском и после остановки должно быть продуто азотом или влажным паром? Выберите правильный вариант ответа.',
        answers: [
          'Только печь',
          'Только автоклав',
          'Только трубопроводы',
          'Все перечисленное оборудование',
        ],
        correctAnswers: ['Все перечисленное оборудование'],
      },
      {
        code: '63661167',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
          'В любом случае',
          'Не допускается ни в каком случае',
          'При условии если было проведено испытание сбросного предохранительного клапана или манометра',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63661168',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В отдельных щитовых помещениях (встроенных или пристроенных)',
          'В смежных помещениях с взрывоопасными зонами',
          'В отдельно стоящих зданиях',
          'В производственных помещениях',
          'Размещение операторных помещений допускается во всех перечисленных местах',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63661169',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'При наличии трещин в стенках и местах крепления цапф',
          'При потере формы ковша вследствие деформации',
          'При поврежденной футеровке',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63661170',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63661171',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['85 °C', '95 °C', '105 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63661172',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
          'Не реже одного раза в три месяца и не реже одного раза в шесть месяцев соответственно',
          'Не реже одного раза в месяц и не реже одного раза в три месяца соответственно',
          'Не реже одного раза в шесть месяцев и не реже одного раза в год соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
      {
        code: '63661173',
        text:
          'В каких из перечисленных случаях допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если изменился состав бригады',
          'Если выявлено нарушение условий отключения технических устройств',
          'Если характер и объемы работ изменены в такой степени, что требуется изменение схемы отключения технического устройства и порядка выполнения работ',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63661174',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Из стали для взрывоопасных сред',
          'Специальную для водорода',
          'Допускается применять любую арматуру из перечисленных',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63661175',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе в заводских сетях действующих предприятий?',
        answers: ['5 г/нм3', '4 г/нм3', '6 г/нм3', '5,5 г/нм3'],
        correctAnswers: ['4 г/нм3'],
      },
      {
        code: '63661176',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['85 °C', '90 °C', '75 °C', '80°C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63661177',
        text:
          'При каких условиях должен производиться замер уровня расплава вручную в шахтной печи? Выберите 2 варианта ответа.',
        answers: [
          'При отключенной печи',
          'Не допускается выдача продуктов плавки',
          'При подаче предупредительного сигнала',
          'При вывешивании предупредительных знаков',
        ],
        correctAnswers: [
          'При отключенной печи',
          'Не допускается выдача продуктов плавки',
        ],
      },
      {
        code: '63661178',
        text:
          'Какое из перечисленных требований при производстве глинозема указано неверно?',
        answers: [
          'Приводной механизм вращения печей спекания и кальцинации должен быть оборудован резервным двигателем с независимым источником питания',
          'На газоходах отходящих газов печей спекания и кальцинации должны быть установлены автоматические газоанализаторы для контроля содержания в них окиси углерода',
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
          'При производстве работ на миксерах, печах и хлораторах с применением грузоподъемных механизмов, а также при заливке, перемешивании расплава и удалении шлама напряжение с электродов должно быть снято',
        ],
        correctAnswers: [
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
        ],
      },
      {
        code: '63661179',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Главный инженер предприятия',
          'Главный механик',
          'Руководитель организации',
          'Ответственный за безопасное производство работ',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63661180',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления? Выберите правильный вариант ответа.',
        answers: [
          'Обратный клапан',
          'Все перечисленные устройства',
          'Свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
          '2 запорных вентиля',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63661181',
        text:
          'Какие установлены сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          'Не регламентируются',
          'Не реже 1 раза в 3 месяца',
          'Не реже 2 раз в 1 год',
          'Не реже 1 раза в месяц',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63661182',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Окна с обычным оконным стеклом толщиной 3 мм и площадью 0,9 м²',
          'Фонарные переплеты',
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
          'Все перечисленные конструкции относятся к легкосбрасываемым',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
        ],
      },
      {
        code: '63661183',
        text:
          'При каком содержании водорода в воздухе производственных помещений (по объему) технологическое оборудование, работающее в этом помещении, должно быть остановлено?',
        answers: ['Более 1%', 'Более 0,8%', 'Более 0,5%', 'Более 0,3%'],
        correctAnswers: ['Более 1%'],
      },
      {
        code: '63661184',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите все правильные варианты ответа.',
        answers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед остановкой оборудования на 1 час',
          'Перед пуском',
          'Перед установкой оборудования',
        ],
        correctAnswers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
        ],
      },
      {
        code: '63661185',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Образование взрывоопасной среды',
          'Попадание воздушной среды',
          'Возможность разгерметизации системы',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63661186',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'На всех ступенях',
          'На любой ступени',
          'Одноступенчатой',
          'Двухступенчатой',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63661187',
        text:
          'Какой допускается максимальный износ цапф конвертеров во всех измерениях в случае применения подшипников скольжения?',
        answers: [
          '12% их первоначальных размеров',
          '10% их первоначальных размеров',
          '15% их первоначальных размеров',
          '8% их первоначальных размеров',
        ],
        correctAnswers: ['10% их первоначальных размеров'],
      },
      {
        code: '63661188',
        text:
          'Что из перечисленного не подлежит обезжириванию в процессе эксплуатации ВРУ?',
        answers: [
          'Колонны высокого и низкого давления',
          'Регенераторы (при переработке воздуха, сжимаемого компрессорами со смазываемыми цилиндрами)',
          'Аппараты и коммуникации на потоке жидкого кислорода',
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Обезжириванию подлежит все перечисленное',
        ],
        correctAnswers: [
          'Средства измерения общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63661189',
        text:
          'Как часто должна проверяться надежность крепления головок электродов?',
        answers: ['Правилами не регламентируется', 'Ежедневно', 'Еженедельно'],
        correctAnswers: ['Правилами не регламентируется'],
      },
      {
        code: '63661190',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Жетон-бирочная система применяется при выводе технического устройства (ТУ) на продолжительную остановку',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Ответственный специалист, разобравший схему, принимает меры, исключающие ошибочное или самопроизвольное включение пусковых устройств, на пусковых устройствах вывешивает предупредительные плакаты "Не включать! Работают люди", проверяет отсутствие энергетического потенциала',
          'Жетон-бирки после разборки электрической схемы ТУ передаются от технологического персонала ремонтному персоналу с отметкой всех этапов в журнале выдачи и приема жетон-бирок',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63661191',
        text: 'Через какое расстояние должны заземляться наружные газопроводы?',
        answers: [
          'Через каждые 250 м',
          'Через каждые 150 м',
          'Через каждые 50 м',
          'Через каждые 350 м',
        ],
        correctAnswers: ['Через каждые 250 м'],
      },
      {
        code: '63661192',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Дистанционное отключение',
          'Установка на линиях всасывания запорных или отсекающих устройств',
          'Установка на линиях нагнетания запорных или отсекающих устройств',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63661193',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Производственные помещения должны иметь защиту от воздействия теплового излучения',
          'Окна пультов управления, в которые возможно попадание брызг расплава, должны оборудоваться в соответствии с проектом',
          'Производственные помещения должны иметь не менее 2 выходов, расположенных с учетом аварийной эвакуации работников; исполнением дверей помещения с открыванием наружу и не иметь внутренних запоров',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63661194',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?',
        answers: [
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации',
          'Не допускается уборка, вызывающая распыление вредных веществ',
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63661195',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 25% нижнего предела взрываемости (1 % об.)',
          'Выше 38% нижнего предела взрываемости (1,5 % об.)',
          'Выше 45% нижнего предела взрываемости (1,8 % об.)',
          'Выше 50% нижнего предела взрываемости (2 % об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1 % об.)'],
      },
      {
        code: '63661196',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Лицо, ответственное за осуществление производственного контроля',
          'Выдающий наряд-допуск',
          'Производитель (руководитель) работ',
          'Допускающий к работе',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63661197',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Только системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство',
          'Только падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63661198',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'Строительных норм и правил',
          'Нормативно-технической документации для технологических стальных трубопроводов',
          'Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63661199',
        text:
          'Какие из перечисленных требований к эксплуатации электролизных цехов указаны неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'В электролизных цехах и отделениях должен быть предусмотрен контроль величины сопротивления изоляции технических устройств, схема контроля и периодичность замеров должны определяться регламентом',
          'Электролизные цехи и отделения должны быть оборудованы дистанционными средствами аварийного отключения электропитания серии ванн с пульта управления цеха (отделения)',
          'Электролизные ванны (серии) должны быть пронумерованы',
          'Корпуса ванн должны быть гидроизолированы. Эксплуатация ванн с нарушенной гидроизоляцией не допускается',
        ],
        correctAnswers: [
          'В электролизных цехах и отделениях должен быть предусмотрен контроль величины сопротивления изоляции технических устройств, схема контроля и периодичность замеров должны определяться регламентом',
        ],
      },
      {
        code: '63661200',
        text:
          'Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
          'Не допускается хранение ЛВЖ в помещении машинного зала компрессорной установки',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661201',
        text:
          'Какое минимальное количество двигателей должен иметь привод поворота конвертера?',
        answers: ['Два', 'три', 'Один', 'Не регламентируется'],
        correctAnswers: ['Два'],
      },
      {
        code: '63661202',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Задвижки должны быть установлены в нужное положение',
          'Водяные затворы должны быть залиты водой',
          'Все люки, лазы и свечи должны быть плотно закрыты, за исключением той свечи, через которую будет производится продувка',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63661203',
        text: 'Как должны сооружаться газопроводы на территории предприятия?',
        answers: [
          'Надземными',
          'Подземными',
          'В помещениях, где расположены газопотребляющие агрегаты',
          'Не регламентируется',
        ],
        correctAnswers: ['Надземными'],
      },
      {
        code: '63661204',
        text:
          'Какие требования безопасности при производстве никеля и меди указаны неверно?',
        answers: [
          'Для удаления корок из ковшей, чаш и погрузки их с помощью мостового крана в цехе должно быть отведено специальное место',
          'Операции закрывания штейновых и шлаковых шпуров, сифонных отверстий, леток и шлаковых окоп плавильных печей должны быть механизированы',
          'На печах стационарного типа должен предусматриваться и поддерживаться в рабочем состоянии резервный шпур для выпуска расплава',
          'При замере уровня расплава вручную допускается выдача продуктов плавки',
        ],
        correctAnswers: [
          'При замере уровня расплава вручную допускается выдача продуктов плавки',
        ],
      },
      {
        code: '63661205',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: ['Паром', 'Сжатым воздухом', 'Газом', 'Всем перечисленным'],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63661206',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: ['0,05 МПа', '0,02 МПа', '0,01 МПа', '0,015 МПа'],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63661207',
        text:
          'При каких показателях газа разрешено применять жидкостные манометры?',
        answers: [
          'При давлении до 0,04 МПа',
          'При давлении до 0,03 МПа',
          'При давлении до 0,05 МПа',
        ],
        correctAnswers: ['При давлении до 0,03 МПа'],
      },
      {
        code: '63661208',
        text:
          'Какие из перечисленных мест должны быть оборудованы аспирационными установками?',
        answers: [
          'Только места пересыпки измельченного медного порошка',
          'Только места пересыпки измельченного кобальтового порошка',
          'Только места пересыпки измельченного никелевого порошка',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63661209',
        text:
          'Какие требования к работе с тетракарбонилом никеля указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Работы с жидким тетракарбонилом никеля должны выполняться в соответствии с технологической инструкцией, с использованием СИЗ',
          'Транспортирование тетракарбонила никеля и отходов производства, содержащих тетракарбонил никеля, для нейтрализации (уничтожения) должно осуществляться в специальных, герметично закрываемых емкостях',
          'Слив тетракарбонила никеля должен производиться в специальные емкости только под слой воды',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661210',
        text:
          'Какие требования безопасности при расчистке горловины конвертера, анодной печи с помощью мостового крана указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Допускается использовать механизм поворота (привод) конвертера, анодной печи для срыва настыли с горловины в присутствии ответственного за производство работ',
          'При расчистке горловины конвертера, анодной печи с помощью мостового крана должны применяться специальные приспособления с предохранительными устройствами ограничения нагрузки',
          'Конструкция приспособлений должна обеспечивать механическую связь с крюком крана при разрушении предохранительного элемента',
          'При загрузке анодных печей оборотными материалами в первую очередь должны загружаться легковесные материалы, затем анодные остатки, скрап электролитного производства, другие материалы в соответствии с технологической инструкцией',
        ],
        correctAnswers: [
          'Допускается использовать механизм поворота (привод) конвертера, анодной печи для срыва настыли с горловины в присутствии ответственного за производство работ',
        ],
      },
      {
        code: '63661211',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее одного года',
          'Полгода',
          'Два года',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63661212',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением, равным рабочему',
          'Соответствующим с требованиями производственной документации',
          'Превышающим рабочее в 1,5 раза',
          'Не регламентируется',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63661213',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже одного раза в 3 года  и  не реже двух раз в пять лет соответственно',
          'Не реже двух раз в 3 года и не реже одного раза в пять лет соответственно',
          'Не реже двух раз в год  и не реже одного раза в три года соответственно',
          'Не реже одного раза в год и не реже двух раз в четыре года соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год  и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63661214',
        text:
          'Какие требования к электролизным цехам указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Металлические каркасы желобов должны быть изолированы от заземлителя и иметь электроизоляционные разрывы, расположенные вдоль ванн между каждыми двумя ваннами',
          'Перемычки (шунты) для отключения ванн должны быть рассчитаны на допустимую силу тока и храниться на специальных стеллажах',
          'Подвальные этажи цехов электролиза должны иметь освещение в соответствии с проектом',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661215',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через производственные помещения, связанные с потреблением водорода',
          'Через бытовые, подсобные административно-хозяйственные, складские помещения',
          'Через производственные помещения, не связанные с потреблением водорода',
          'Через электрораспределительные, трансформаторные помещения',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63661216',
        text:
          'Какое минимальное количество выходов должна иметь колошниковая площадка шахтных печей?',
        answers: ['Два', 'Три', 'Четыре', 'Один'],
        correctAnswers: ['Два'],
      },
      {
        code: '63661217',
        text:
          'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          'На 1,2 величины рабочего давления охлаждающей жидкости',
          'На 1,5 величины рабочего давления охлаждающей жидкости',
          'На 1,8 величины рабочего давления охлаждающей жидкости',
          'На 2,0 величины рабочего давления охлаждающей жидкости',
        ],
        correctAnswers: [
          'На 1,5 величины рабочего давления охлаждающей жидкости',
        ],
      },
      {
        code: '63661218',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 3 часов',
          'Не менее 2 часов',
          'Не менее 1 часа',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63661219',
        text: 'При каком условии ВРУ должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,50 мг/дм подтвержденной двумя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,70 мг/дм и выше подтвержденной шестью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 1,0 мг/дм подтвержденной тремя последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63661220',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода, в зимнее время?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежедекадно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63661221',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после тщательного их просушивания и разогрева',
          'Только после смазки смазочным материалом',
          'Только после их увлажнения и охлаждения',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63661222',
        text: 'Какие требования к электролизным ваннам указаны неверно?',
        answers: [
          'Мостики между изоляторами должны быть выполнены из металла',
          'При работе на электролизных ваннах должны соблюдаться меры предосторожности, исключающие замыкания двух рядом стоящих блоков ванн',
          'Электролизные ванны и шинопроводы должны быть изолированы от земли, а сборные баки для электролита заземлены',
          'Подключение и отключение электролизных ванн к ошиновке, замена электродов на регенеративных ваннах должны производиться только после снятия электропитания',
        ],
        correctAnswers: [
          'Мостики между изоляторами должны быть выполнены из металла',
        ],
      },
      {
        code: '63661223',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о начале и окончании смены',
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63661224',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В одном экземпляре',
          'В двух экземплярах',
          'В трех экземплярах',
          'В четырех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63661225',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Паспортом (формуляром) на компрессорную установку',
          'Схемой трубопроводов',
          'Журналом учета работы компрессора',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63661226',
        text:
          'Какое допускается максимальное содержание кислорода в продувочном газе перед пуском автоклава?',
        answers: [
          'Содержание кислорода не допускается',
          '7% (по объему)',
          '6% (по объему)',
          '4% (по объему)',
        ],
        correctAnswers: ['4% (по объему)'],
      },
      {
        code: '63661227',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63661228',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Только в аварийных случаях',
          'Не допускается',
          'Разрешается при увеличении давления газа до 5%',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63661229',
        text:
          'При каком максимальном содержании сероводорода в газе допускается установка бронзовых кранов или задвижек с бронзовыми кольцами на газопроводах?',
        answers: ['20 мг/м3', '30 мг/м3', '25 мг/м3', '23 мг/м3'],
        correctAnswers: ['20 мг/м3'],
      },
      {
        code: '63661230',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '5 м', '3м', '1,5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63661231',
        text:
          'В каких случаях не допускается вторичное использование ковша для заливки или разливки металла?',
        answers: [
          'Без предварительной замены стопора и стакана',
          'Без предварительной замены футеровки',
          'Только без предварительной замены стопора',
        ],
        correctAnswers: ['Без предварительной замены стопора и стакана'],
      },
      {
        code: '63661232',
        text:
          'С каким интервалом должна проверяться объемная доля кислорода в месте проведения ремонтных работ во время нахождения персонала внутри трубопроводов, опорных обечаек регенераторов, в отсеке обратноповоротных клапанов или арматуры?',
        answers: [
          'С интервалом не  менее чем 10 минут',
          'С интервалом не  менее чем 5 минут',
          'С интервалом не  менее чем 8 минут',
          'С интервалом не  менее чем 7 минут',
        ],
        correctAnswers: ['С интервалом не  менее чем 10 минут'],
      },
      {
        code: '63661233',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь вдоль периметра пешеходные проходы шириной 0,8 м',
          'Должны иметь вдоль периметра пешеходные проходы шириной 1,0 м',
          'Со всех сторон должны иметь ограждение',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63661234',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 2 года'],
      },
      {
        code: '63661235',
        text: 'Подача шихты с какой влажностью запрещается в печь "Аусмелт"?',
        answers: [
          'Более 8% по массе',
          'Более 7% по массе',
          'Более 6% по массе',
          'Более 5% по массе',
        ],
        correctAnswers: ['Более 8% по массе'],
      },
      {
        code: '63661236',
        text:
          'Что должно выполняться перед заливкой расплава в конвертер? Выберите 2 варианта ответа.',
        answers: [
          'Подаваться предупредительный сигнал',
          'Во всех проходах в опасную зону должно быть включено световое табло "Заливка расплава"',
          'Очистка емкости для расплава от мусора',
          'Вывод всех работников из опасной зоны',
        ],
        correctAnswers: [
          'Подаваться предупредительный сигнал',
          'Во всех проходах в опасную зону должно быть включено световое табло "Заливка расплава"',
        ],
      },
      {
        code: '63661237',
        text:
          'Какой должна быть высота стоп анодных остатков, укладываемых на транспортные тележки?',
        answers: [
          'Не должна превышать  1 м',
          'Не должна превышать  1,5 м',
          'Не должна превышать  2 м',
          'Не должна превышать  3 м',
        ],
        correctAnswers: ['Не должна превышать  1 м'],
      },
      {
        code: '63661238',
        text:
          'Какой должна быть концентрация водорода перед его пуском в печь (автоклав)?',
        answers: [
          'Не менее 95% (по объему)',
          'Не менее 90% (по объему)',
          'Не менее 93% (по объему)',
          'Не менее 87% (по объему)',
        ],
        correctAnswers: ['Не менее 95% (по объему)'],
      },
      {
        code: '63661239',
        text:
          'Какое значение не должно превышать содержание сероводорода в коксовом газе при газоплазменной обработке металла в закрытых помещениях, а также нафталина летом и зимой?',
        answers: [
          'Сероводорода – 0,05 г/нм3, нафталина – 0,06 г/нм3 (летом) и 0,15 г/нм3 (зимой)',
          'Сероводорода – 0,02 г/нм3, нафталина – 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
          'Сероводорода – 0,08 г/нм3, нафталина – 0,07 г/нм3 (летом) и 0,2 г/нм3 (зимой)',
          'Сероводорода –  0,2 г/нм3, нафталина – 0,15 г/нм3 (летом) и 0,16 г/нм3 (зимой)',
        ],
        correctAnswers: [
          'Сероводорода – 0,02 г/нм3, нафталина – 0,05 г/нм3 (летом) и 0,1 г/нм3 (зимой)',
        ],
      },
      {
        code: '63661240',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных, муфтовых и других передач',
          'Только предохраняющие устройства, отключающие приводы при перегрузке',
          'Только исключение падения транспортируемых материалов',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63661241',
        text:
          'Какие требования к эксплуатации автоклавов на медно никелевом производстве указаны верно?',
        answers: [
          'Для смазки оборудования, непосредственно связанного с работой автоклава, необходимо применять смазочные материалы стойкие и пожаробезопасные в кислородно-воздушной смеси',
          'Все перечисленные требования указаны верно',
          'Водород в продувочном газе после остановки должен отсутствовать, а содержание кислорода в продувочном газе перед пуском не должно превышать 4% (по объему)',
          'Не допускается открывать крышки и снимать заглушки с патрубков автоклавов без полного снятия давления в аппарате',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661242',
        text:
          'Содержание каких из перечисленных газов измеряется в продуктах неполного сгорания за дымососом при отводе газов без дожигания? Выберите 2 варианта ответа',
        answers: ['CO', 'CO﻿2', 'O2', 'H2'],
        correctAnswers: ['CO', 'O2'],
      },
      {
        code: '63661243',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 1% в час',
          'Не более 2% в час',
          'Не более 0,35% в час',
          'Не более 0,2% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63661244',
        text:
          'Какое из перечисленных требований при необходимости проведения обезжиривания технических устройств и трубопроводов ПРВ растворителями указано неверно?',
        answers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
          'Пролив растворителей на пол не допускаются, случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'Доступ в помещение, где хранятся растворители, разрешается только лицам, допущенным к работе с ними',
        ],
        correctAnswers: [
          'Перелив растворителей из одного сосуда в другой не допускается',
        ],
      },
      {
        code: '63661245',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: ['Из чугуна', 'Из стали', 'Из сплавов титана'],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63661246',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 15 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 20 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63661247',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,5 м в свету',
          'Не менее 1,0 м в свету',
          'Не менее 0,5 м в свету',
          'Не менее 1,2 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63661248',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
          'По наружным стенам производственных зданий транзитных водородопроводов',
          'Через бытовые, подсобные административно-хозяйственные, складские помещения',
          'Через производственные помещения, не связанные с потреблением водорода',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63661249',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Инструкции по эксплуатации оборудования',
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Должностные и производственные инструкции',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63661250',
        text:
          'Какое из перечисленных требований к стационарным газоразборным постам указано неверно?',
        answers: [
          'При отсутствии рабочего, пользующегося газоразборным постом, шкаф должен быть закрыт на замок',
          'Газоразборные посты, шкафы должны иметь опознавательную окраску',
          'Дверцы шкафов во время работы должны быть закрыты',
          'Стационарные газоразборные посты должны быть размещены в металлических шкафах с отверстиями для вентиляции',
        ],
        correctAnswers: ['Дверцы шкафов во время работы должны быть закрыты'],
      },
      {
        code: '63661251',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее двух раз в пять лет',
          'Не менее одного раза в три года',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63661252',
        text:
          'Как должны производиться очистка боровов и ремонтные работы внутри них?',
        answers: [
          'Должны производиться по наряду-допуску при температуре воздуха внутри борова не выше 50 °С',
          'Должны производиться по распоряжению при температуре воздуха внутри борова не выше 45 °С',
          'Должны производиться по наряду-допуску при полной остановке печи',
        ],
        correctAnswers: [
          'Должны производиться по наряду-допуску при полной остановке печи',
        ],
      },
      {
        code: '63661253',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в 10 дней',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63661254',
        text:
          'Какой должен быть нижний предел давления для I разряда газов-заменителей ацетилена перед газопламенной аппаратурой?',
        answers: [
          'Не ниже 3,0 кПа',
          'Не ниже 2,0 кПа',
          'Не ниже 1,5 кПа',
          'Не ниже 0,5 кПа',
        ],
        correctAnswers: ['Не ниже 3,0 кПа'],
      },
      {
        code: '63661255',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В чистых металлических ящиках с крышками',
          'В специальной герметичной таре',
          'В чистых деревянных ящиках, закрытых на замок',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63661256',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['2м', '1м', '1,5 м', '1,2 м'],
        correctAnswers: ['2м'],
      },
      {
        code: '63661257',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу A', 'Классу C', 'Классу B', 'Классу D'],
        correctAnswers: ['Классу A'],
      },
      {
        code: '63661258',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 2 месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63661259',
        text:
          'Какие из перечисленных требований к эксплуатации шахтных печей указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Места установки термопар определяются проектом',
          'Места приема расплава должны быть сухими',
          'Колошниковая площадка шахтных печей должна иметь не менее двух выходов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661260',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
          'Должна устраняться при помощи ручной регулировки',
          'Должна устраняться увеличением трения между тянущим барабаном и лентой с использованием специальных материалов',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63661261',
        text:
          'Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?',
        answers: [
          'На 2,5 величины рабочего давления охлаждающей воды',
          'На 2,0 величины рабочего давления охлаждающей воды',
          'На 1,5 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63661262',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с начальником газоспасательной службы',
          'Только со службой производственного контроля',
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63661263',
        text:
          'Какие из перечисленных действий при осуществлении процесса электролиза никеля, меди и кобальта указаны верно?',
        answers: [
          'Подключение и отключение электролизных ванн к ошиновке, замена электродов на регенеративных ваннах производятся только после снятия электропитания',
          'Обслуживание регенеративных ванн производится с использованием неэлектропроводного инструмента',
          'Регенеративные ванны оборудуются вытяжной системой или поверхность электролита в них покрывается защитным слоем',
          'Все перечисленные действия указаны верно',
        ],
        correctAnswers: ['Все перечисленные действия указаны верно'],
      },
      {
        code: '63661264',
        text:
          'Какое из перечисленных требований безопасности при производстве никеля, меди и кобальта, медных порошков, медного и никелевого купороса и антисептика указано верно?',
        answers: [
          'На печах стационарного типа должен предусматриваться и поддерживаться в рабочем состоянии резервный шпур для выпуска расплава',
          'Все операции по замене шпуровых плит, рам и текущий ремонт шпуровой кладки должны производиться под наблюдением лица, назначенного распоряжением по цеху ответственным за проведение этих работ',
          'Операции закрывания штейновых и шлаковых шпуров, сифонных отверстий, леток и шлаковых окоп плавильных печей должны быть механизированы',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63661265',
        text:
          'Какие из перечисленных действий должны производиться только после снятия электропитания в цехе производства меди? Выберите правильный вариант ответа.',
        answers: [
          'Только подключение электролизных ванн к ошиновке',
          'Только отключение электролизных ванн от ошиновки',
          'Только замена электродов на регенеративных ваннах',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63661266',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Только работы повышенной опасности (сложные, разовые, уникальные)',
          'Только работы, связанные с аварийными ситуациями',
          'Производятся все перечисленные виды работ',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63661267',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['1 час', '1,5 часа', '2 часа', '3 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63661268',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'При проведении испытаний газопроводов и газовых установок давление должно измеряться одним манометром, прошедшим поверку и опломбирование',
          'Класс точности манометров должен быть не ниже 1 с диаметром корпуса не менее 120 мм',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
          'При применении манометров без указания класса точности их погрешность допускается не более чем 0,5 порога измерения',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63661269',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих органов)?',
        answers: ['0,2 кПа', '0,3 кПа', '0,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63661270',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63661271',
        text:
          'С какой периодичностью газопроводы сероочистки природного газа подвергаются пневматическому испытанию?',
        answers: [
          'Один раз в четыре года',
          'Два раза в год',
          'Один раз в год',
          'Два раза в пять лет',
        ],
        correctAnswers: ['Один раз в четыре года'],
      },
      {
        code: '63661272',
        text:
          'Чем должны быть оборудованы ванны с кислотой для травления оборудования?',
        answers: [
          'Системой подачи аргона',
          'Местными отсосами',
          'Укрытиями',
          'Всем перечисленным',
        ],
        correctAnswers: ['Местными отсосами'],
      },
      {
        code: '63661273',
        text:
          'Какие требования к компрессорным установкам указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'Компрессор и его электродвигатель  должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Качество изготовления компрессорных установок должно соответствовать требованиям нормативно-технической документации и документации организации-изготовителя',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
    ],
    63668: [
      {
        code: '63668000',
        text: 'Что должна обеспечить система светозвуковой сигнализации?',
        answers: [
          'Оповещение эксплуатационного персонала о начале и окончании смены',
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
          'Оповещение эксплуатационного персонала о приближении технологического транспорта',
        ],
        correctAnswers: [
          'Оповещение эксплуатационного персонала о пуске, остановке и нарушениях установленного режима работы технических устройств',
        ],
      },
      {
        code: '63668001',
        text: 'Через какие помещения допускается прокладка водородопроводов?',
        answers: [
          'Через производственные помещения, связанные с потреблением водорода',
          'Через электрораспределительные, трансформаторные помещения, вентиляционные камеры',
          'Через бытовые, подсобные и административно-хозяйственные помещения',
          'Через помещения КИП',
        ],
        correctAnswers: [
          'Через производственные помещения, связанные с потреблением водорода',
        ],
      },
      {
        code: '63668002',
        text:
          'Что должно устраиваться над постановочными путями чугуновозных и шлаковозных ковшей в пределах литейного двора в целях исключения попадания атмосферных осадков в ковши?',
        answers: [
          'Специальные навесы из оцинкованной стали на высоких опорах',
          'Крыши или специальные навесы',
          'Крыши из материалов с полимерным покрытием',
        ],
        correctAnswers: ['Крыши или специальные навесы'],
      },
      {
        code: '63668003',
        text:
          'Какие производственные помещения должны быть оборудованы системами связи и сигнализации? Выберите правильный вариант ответа.',
        answers: [
          'Только помещения, связанные с получением водорода',
          'Только помещения, связанные с хранением водорода',
          'Только помещения, связанные с применением водорода',
          'Все перечисленные производственные помещения',
        ],
        correctAnswers: ['Все перечисленные производственные помещения'],
      },
      {
        code: '63668004',
        text:
          'При каких условиях запрещается эксплуатация плавильных агрегатов?',
        answers: [
          'При незначительном повышении давления воды в системе водоохлаждения',
          'При незначительном падении давления воды в системе водоохлаждения',
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
        correctAnswers: [
          'При разгерметизации системы водяного охлаждения этих агрегатов',
        ],
      },
      {
        code: '63668005',
        text:
          'Какое из перечисленных требований к установке по производству реформерного газа указано неверно?',
        answers: [
          'Для защиты от повышения давления газа выше проектного должны устанавливаться предохранительные сбросные клапаны',
          'Перед розжигом горелок реформера должна быть произведена продувка его топочного пространства',
          'Не допускается продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами',
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
        correctAnswers: [
          'Продувка воздухом трубопроводов с водой, соединенных с газовыми системами и агрегатами, допускается по решению ответственного за безопасное производство работ',
        ],
      },
      {
        code: '63668006',
        text:
          'Кем производится испытание газопроводов и газовых установок после окончания строительно-монтажных и сварочных работ (включая ремонтные работы)?',
        answers: [
          'Строительно-монтажной организацией',
          'Комиссией предприятия-заказчика',
          'Техническим руководством предприятия',
          'Представителем Ростехнадзора',
        ],
        correctAnswers: ['Строительно-монтажной организацией'],
      },
      {
        code: '63668007',
        text:
          'Какие требования установлены к месту расстановки ковшей, предназначенных для расплава металла?',
        answers: [
          'Допускается расставлять в любом месте на рабочих площадках',
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
          'Должны расставляться на свободных площадках пролета цеха',
        ],
        correctAnswers: [
          'Должны расставляться на горизонтальных площадках в соответствии с проектом и учетом технологии производства',
        ],
      },
      {
        code: '63668008',
        text: 'Какие требования к компрессорным установкам указаны неверно?',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
          'В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла',
          'Помещение, где расположены компрессорные установки, следует оснащать средствами оперативной, в том числе диспетчерской, связи',
        ],
        correctAnswers: [
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться внутрь',
        ],
      },
      {
        code: '63668009',
        text:
          'На что должен проверяться выполненный сварной шов после врезки в действующий газопровод?',
        answers: ['На плотность', 'На герметичность', 'На прочность'],
        correctAnswers: ['На плотность'],
      },
      {
        code: '63668010',
        text:
          'Что должно быть у каждого плавильного агрегата с выпуском металла через летку?',
        answers: [
          'Должны быть две штанги длиной не менее 1 м и запасные пробки для закрывания выпускного отверстия',
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
          'Должны быть две штанги длиной не менее 1,2 м и инструмент для подрубки наружной глиняной пробки летки',
        ],
        correctAnswers: [
          'Должны быть две штанги длиной не менее 1,5 м и запасные пробки для закрывания леток',
        ],
      },
      {
        code: '63668011',
        text:
          'Какие действия предпринимаются при необходимости ведения работ после истечения срока действия акта-допуска подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'Продлевается текущий акт-допуск',
          'Составляется новый акт-допуск на следующий срок',
          'Работы продолжаются без оформления',
        ],
        correctAnswers: ['Составляется новый акт-допуск на следующий срок'],
      },
      {
        code: '63668012',
        text: 'Каков порядок приготовления растворов серной кислоты?',
        answers: [
          'При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту',
          'При приготовлении смеси кислот воду и серную кислоту следует заливать одновременно',
          'При приготовлении растворов серной кислоты сначала необходимо заливать кислоту, а затем добавлять воду',
        ],
        correctAnswers: [
          'При приготовлении растворов серной кислоты сначала необходимо заливать воду, а затем кислоту',
        ],
      },
      {
        code: '63668013',
        text:
          'В каком случае проводится внеочередное обследование кожухов доменных печей и воздухонагревателей?',
        answers: [
          'Только в случае выявления при эксплуатационных осмотрах необъяснимого роста повреждений и опасности общего разрушения кожуха',
          'Только после аварии в цехе, связанной с огневым (пожар) или механическим (обрушение конструкций) воздействием на кожух печи',
          'Только при намечаемой реконструкции печи',
          'Только при увеличении нормируемых проектных показателей и (или) климатических воздействий (рост районной сейсмики, рост рабочего давления, переход на цинкосодержащие руды)',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63668014',
        text:
          'Какие действия должны быть произведены перед продувкой газопровода? Выберите правильный вариант ответа.',
        answers: [
          'Все люки, лазы и свечи плотно закрыты, за исключением той свечи, через которую будет производиться продувка',
          'Водяные затворы должны быть залиты водой',
          'Задвижки должны быть установлены в нужное положение',
          'Должны быть произведены все перечисленные действия',
        ],
        correctAnswers: ['Должны быть произведены все перечисленные действия'],
      },
      {
        code: '63668015',
        text:
          'В каком из перечисленных случаев запрещено возобновлять подачу воды к любому из водоохлаждаемых компонентов печи "Аусмелт"?',
        answers: [
          'Если температура этих компонентов превышает 120 °C',
          'Если температура этих компонентов превышает 100 °C',
          'Если температура этих компонентов превышает 60 °C',
          'Если температура этих компонентов превышает 150 °C',
        ],
        correctAnswers: ['Если температура этих компонентов превышает 150 °C'],
      },
      {
        code: '63668016',
        text:
          'Каким гидравлическим давлением должны быть испытаны на прочность и плотность трубки индуктора?',
        answers: [
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2 раза',
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза',
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,25 раза',
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 2,5 раза',
        ],
        correctAnswers: [
          'Давлением, превышающем рабочее давление охлаждающей воды не менее чем в 1,5 раза',
        ],
      },
      {
        code: '63668017',
        text:
          'Кто определяет (назначает) работников, выполняющих мероприятия по безопасности труда, указанных в наряде-допуске (ответственных исполнителей)?',
        answers: [
          'Руководитель организации',
          'Начальник технического подразделения',
          'Выдающий наряд-допуск',
          'Руководитель бригады',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '63668018',
        text:
          'Какие требования установлены к конусным и бесконусным засыпным аппаратам?',
        answers: [
          'Засыпные аппараты должны быть надежными и рассчитаны на двукратное рабочее давление газа под колошником',
          'Засыпные аппараты должны быть надежными и рассчитаны на полуторакратное рабочее давление газа под колошником',
          'Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником',
        ],
        correctAnswers: [
          'Засыпные аппараты должны быть герметичными и рассчитаны на рабочее давление газа под колошником',
        ],
      },
      {
        code: '63668019',
        text:
          'Какие требования установлены к состоянию вакуум-ковшей, тиглей, коробов, кристаллизаторов, изложниц и других емкостей для расплава перед заливкой в них расплава в электролитическом производстве магния?',
        answers: [
          'Должны быть покрыты огнеупорным материалом',
          'Должны быть выдержаны в помещении не менее одной смены',
          'Должны быть очищены от мусора',
        ],
        correctAnswers: ['Должны быть очищены от мусора'],
      },
      {
        code: '63668020',
        text:
          'Чем должна обеспечиваться безопасность производственных процессов нанесения металлопокрытий?',
        answers: [
          'Только применением безопасных способов хранения, транспортирования исходных и вспомогательных веществ и материалов, заготовок и готовой продукции',
          'Только заменой производственных процессов и операций с вредными и (или) опасными производственными факторами на процессы и операции с отсутствием данных факторов или имеющих меньшую интенсивность',
          'Только отсутствием (ограничением) непосредственного контакта работников с веществами, растворами, исходными материалами и отходами производства, оказывающими вредное воздействие на организм работников',
          'Только применением блокировочных устройств, средств световой и звуковой сигнализации, аварийного отключения производственного оборудования при нарушениях производственных процессов',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63668021',
        text:
          'Какие из перечисленных требований к акту-допуску подрядных организаций на опасный производственный объект горно-металлургической промышленности указаны неверно?',
        answers: [
          'На один объект, площадку, территорию должен оформляться один акт-допуск',
          'Акт-допуск должен оформляться письменно',
          'Не допускается оформление актов-допусков, характеризующихся одинаковыми координатами рабочих зон',
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
        correctAnswers: [
          'Акт-допуск должен подписываться только представителями эксплуатирующей организации (заказчика)',
        ],
      },
      {
        code: '63668022',
        text:
          'Какое устанавливается минимальное расстояние от испытываемого газопровода большого диаметра, расположенного вне помещений, до границ охраняемой зоны во время проведения пневматических испытаний на прочность?',
        answers: ['10 м', '25 м', '50 м', '100 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63668023',
        text:
          'Какой технической документацией должен быть оснащен каждый компрессорный агрегат или установка, или группа однородных компрессорных установок?',
        answers: [
          'Только паспортом (формуляром) на компрессорную установку',
          'Только схемой трубопроводов (сжатого воздуха или газа, воды, масла) с указанием мест установок задвижек, вентилей, влагомаслоотделителей, промежуточных и концевых холодильников, воздухосборников, контрольно-измерительных приборов; схемы вывешиваются на видном месте',
          'Только инструкцией (руководством) по эксплуатации (безопасному обслуживанию) компрессорной установки',
          'Только журналом учета работы компрессора, паспортами-сертификатами компрессорного масла и результатами его лабораторного анализа, графиком ремонтов компрессорной установки',
          'Всей перечисленной документацией',
        ],
        correctAnswers: ['Всей перечисленной документацией'],
      },
      {
        code: '63668024',
        text:
          'Какие мероприятия должны быть выполнены при прогаре водоохлаждаемых элементов печи и попадании воды в зону плавления печи?',
        answers: [
          'Должен быть снижен объем загрузки в печь',
          'Печь должна быть немедленно отключена',
          'Подача воды должна быть приостановлена',
        ],
        correctAnswers: ['Печь должна быть немедленно отключена'],
      },
      {
        code: '63668025',
        text:
          'В течение какого времени хранится журнал регистрации нарядов-допусков со дня внесения последней записи?',
        answers: [
          'В течение 1 года',
          'В течение 3 месяцев',
          'В течение 6 месяцев',
          'В течение 2 лет',
        ],
        correctAnswers: ['В течение 6 месяцев'],
      },
      {
        code: '63668026',
        text:
          'В каком случае заправка мартеновских печей и двухванных сталеплавильных агрегатов не допускается?',
        answers: [
          'При открытом отверстии в площадке для спуска шлака',
          'При неисправности запорного оборудования',
          'При отключенных блокировочных устройствах',
        ],
        correctAnswers: ['При открытом отверстии в площадке для спуска шлака'],
      },
      {
        code: '63668027',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63668028',
        text:
          'В течение какого времени хранятся закрытые (подписанные) наряды-допуски на работы повышенной опасности?',
        answers: [
          'В течение одного месяца',
          'В течение двух месяцев',
          'В течение шести месяцев',
          'В течение года',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63668029',
        text:
          'При какой предельной температуре кислорода после регулятора давления на линии регулирования прекращается его подача?',
        answers: [
          'При повышении более 100 °С',
          'При повышении более 150 °С',
          'При повышении более 120 °С',
        ],
        correctAnswers: ['При повышении более 100 °С'],
      },
      {
        code: '63668030',
        text:
          'С какой периодичностью следует проверять состояние адсорбента в адсорбционных блоках осушки?',
        answers: ['Один раз в месяц', 'Один раз в год', 'Два раза в год'],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63668031',
        text:
          'Какой стаж работы в газовом хозяйстве должны иметь лица, назначаемые ответственными руководителями и ответственными исполнителями газоопасных работ?',
        answers: [
          'Не менее пяти лет',
          'Не менее трех лет',
          'Не менее одного года',
          'Не менее двух лет',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63668032',
        text:
          'Какие требования установлены к загружаемым в печи руде, ферросплавам и другим материалам?',
        answers: [
          'Должны быть предварительно просушены или прокалены',
          'Должны быть выдержаны в помещении не менее суток',
          'Должны быть разделены на части и увлажнены',
        ],
        correctAnswers: ['Должны быть предварительно просушены или прокалены'],
      },
      {
        code: '63668033',
        text:
          'Какие ручки должен иметь металлический инструмент, применяемый при обслуживании индукционных печей?',
        answers: [
          'Электроизолированные ручки',
          'Деревянные ручки',
          'Стальные ручки',
        ],
        correctAnswers: ['Электроизолированные ручки'],
      },
      {
        code: '63668034',
        text:
          'В соответствии с какой документацией должны производиться подготовка к пуску и пуск установок по производству водорода?',
        answers: [
          'Только в соответствии с проектом',
          'Только в соответствии с технологическим регламентом',
          'Только в соответствии с инструкцией по эксплуатации',
          'В соответствии со всей перечисленной документацией',
        ],
        correctAnswers: ['В соответствии со всей перечисленной документацией'],
      },
      {
        code: '63668035',
        text:
          'В каком случае допускается подача потребителям газа по обводной линии (байпасу) ГРП и ГРУ?',
        answers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
          'При условии выданного разрешения от лиц, ответственных за техническое состояние газового хозяйства',
          'При условии наличия сигнализации',
          'Подача газа по байпасу не допускается',
        ],
        correctAnswers: [
          'При условии постоянного нахождения в ГРП или у ГРУ дежурного, регулирующего давление газа на выходе из ГРП или ГРУ',
        ],
      },
      {
        code: '63668036',
        text:
          'Какие требования установлены к фурмам для продувки жидкого металла газами?',
        answers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
          'Должны иметь цилиндрическую форму',
          'Должны быть защищены специальными экранами от нагревания',
        ],
        correctAnswers: [
          'Должны быть просушены и подогреты в специальной печи до температуры, регламентированной технологическими инструкциями',
        ],
      },
      {
        code: '63668037',
        text:
          'Какие требования установлены к конструкции клапанов, перекрывающих поступление газа к горелкам?',
        answers: [
          'Конструкция должна обеспечивать класс герметичности запорной арматуры "А"',
          'Конструкция должна обеспечивать перекрытие газопровода в течение 10 секунд',
          'Конструкция должна обеспечивать плотное перекрытие газопровода',
        ],
        correctAnswers: [
          'Конструкция должна обеспечивать плотное перекрытие газопровода',
        ],
      },
      {
        code: '63668038',
        text: 'Чем должны быть оборудованы шлаковозы?',
        answers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
          'Механизмами кантования (поворота) чаши с ручным гидравлическим приводом',
          'Механизмами кантования (поворота) чаши с пневматическим приводом и автоматическим управлением',
        ],
        correctAnswers: [
          'Механизмами кантования (поворота) чаши с электрическим приводом и дистанционным управлением',
        ],
      },
      {
        code: '63668039',
        text:
          'Из каких газов состоят промышленные газы объектов металлургии, возникающие (используемые) в процессе металлургического производства?',
        answers: [
          'Только из природного и доменного газов',
          'Только из коксового и конвертерного газов',
          'Только из ферросплавного и реформерного газов',
          'Из всех перечисленных газов',
        ],
        correctAnswers: ['Из всех перечисленных газов'],
      },
      {
        code: '63668040',
        text:
          'Какими средствами пожаротушения должны быть оборудованы помещения, где хранится карбид кальция?',
        answers: [
          'Специальные емкости для воды и песка',
          'Пенные огнетушители и кошма',
          'Порошковые огнетушители, сухой песок',
        ],
        correctAnswers: ['Порошковые огнетушители, сухой песок'],
      },
      {
        code: '63668041',
        text: 'Чем должен быть покрыт пол рабочей площадки возле электропечи?',
        answers: [
          'Электроизолирующим настилом',
          'Деревянным настилом с пропиткой',
          'Листовым термостойким материалом',
        ],
        correctAnswers: ['Электроизолирующим настилом'],
      },
      {
        code: '63668042',
        text:
          'Кто из перечисленных лиц не должен входить в состав постоянно действующей комиссии для проведения осмотров объектов газового хозяйства?',
        answers: [
          'Начальник цеха - ответственный за производственный контроль',
          'Работник - ответственный за безопасную эксплуатацию и техническое состояние газового хозяйства цеха',
          'Работники, ответственные за безопасную эксплуатацию и техническое состояние соответствующих технических устройств, сооружений и оборудования',
          'Главный инженер',
        ],
        correctAnswers: ['Главный инженер'],
      },
      {
        code: '63668043',
        text:
          'Какое допускается содержание кислорода в инертном газе, предназначенном для продувки водородной системы?',
        answers: ['Не более 1%', 'Не более 2%', 'Не более 3%', 'Не более 5%'],
        correctAnswers: ['Не более 1%'],
      },
      {
        code: '63668044',
        text:
          'Как часто должно проверяться состояние системы охлаждения печей?',
        answers: ['Еженедельно', 'Ежеквартально', 'Ежесменно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63668045',
        text:
          'Какая допускается максимальная погрешность (точность измерения) газоанализаторов водорода?',
        answers: ['+/- 0,2% об', '+/- 0,3% об', '+/- 0,5% об'],
        correctAnswers: ['+/- 0,2% об'],
      },
      {
        code: '63668046',
        text:
          'В каких случаях запрещается газовая резка и сварка на действующих газопроводах?',
        answers: [
          'На газопроводах, находящихся в эксплуатации менее 5 лет',
          'На газопроводах, находящихся под давлением',
          'На газопроводах, находящихся под разрежением',
        ],
        correctAnswers: ['На газопроводах, находящихся под разрежением'],
      },
      {
        code: '63668047',
        text: 'Какие требования установлены к расположению КИПиА?',
        answers: [
          'Приборы должны устанавливаться на расстоянии не менее 3 м от оборудования',
          'Приборы должны устанавливаться в непосредственной близости к оборудованию',
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
        correctAnswers: [
          'Приборы должны устанавливаться в удобных и безопасных местах для наблюдения и регулирования',
        ],
      },
      {
        code: '63668048',
        text:
          'В течение какого времени выдерживают испытательное давление в трубопроводе при испытании на прочность, после чего снижают до рабочего давления, при котором производят тщательный осмотр сварных швов (испытание на плотность)?',
        answers: [
          'В течение 30 минут',
          'В течение 15 минут',
          'В течение 10 минут',
          'В течение 5 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63668049',
        text:
          'С какой периодичностью должна проверяться герметичность сводов и стен регенераторов, работающих с подогревом газа в них?',
        answers: ['Два раза в сутки', 'Ежесменно', 'Еженедельно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63668050',
        text:
          'На какой ступени сжатия водородные компрессоры должны иметь предохранительные клапаны?',
        answers: [
          'Только на первой ступени сжатия',
          'Только на второй ступени сжатия',
          'На всех ступенях',
        ],
        correctAnswers: ['На всех ступенях'],
      },
      {
        code: '63668051',
        text:
          'Каким должно быть расстояние от устройства забора воздуха и камер всасывания работающих воздушных компрессоров ВРУ при выполнении ремонтных работ, работ по газовой сварке и резке металла, чтобы для их выполнения было необходимо наличие письменного разрешения руководителя производства (цеха) и наряда-допуска?',
        answers: ['Менее 100 м', 'Менее 75 м', 'Менее 150 м', 'Менее 200 м'],
        correctAnswers: ['Менее 100 м'],
      },
      {
        code: '63668052',
        text:
          'В каких помещениях предупреждающий и аварийный сигналы должны подаваться у входа вне помещения?',
        answers: [
          'В пультовой',
          'Во всех помещениях с постоянным пребыванием обслуживающего персонала',
          'В операторной',
          'В помещении с периодическим пребыванием персонала, где установлены датчики',
        ],
        correctAnswers: [
          'В помещении с периодическим пребыванием персонала, где установлены датчики',
        ],
      },
      {
        code: '63668053',
        text:
          'На каких минимальных расстояниях (по горизонтали) до групповых газобаллонных установок допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['10 м', '5 м', '3 м', '1,5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63668054',
        text:
          'С кем согласовывается перечень работ повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'С управляющим территориального органа Ростехнадзора',
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
          'С руководителем газоспасательной службы',
          'С главным инженером организации',
        ],
        correctAnswers: [
          'Со службой производственного контроля или с лицом, ответственным за осуществление производственного контроля',
        ],
      },
      {
        code: '63668055',
        text:
          'Какая допускается предельная температура колошникового газа в случае задержки загрузки печи?',
        answers: [
          'Не должна превышать 500 °С',
          'Не должна превышать 600 °С',
          'Должна составлять 550 °С',
        ],
        correctAnswers: ['Не должна превышать 500 °С'],
      },
      {
        code: '63668056',
        text:
          'Какой предел огнестойкости должен быть у стен, разделяющих взрывоопасные помещения на водородных станциях?',
        answers: ['2,5 часа', '1,5 часа', '2 часа', '1 час'],
        correctAnswers: ['2,5 часа'],
      },
      {
        code: '63668057',
        text:
          'После установления каких показателей температуры разрешается проводить ремонтные работы внутри нагретых технических устройств, а также низкотемпературных технических устройств?',
        answers: [
          'После снижения температуры воздуха до 45 °С, а низкотемпературных технических устройств - после отогрева до положительных температур (18 °С) и проветривания',
          'После снижения температуры воздуха до 40 °С и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °С) и проветривания',
          'После снижения температуры воздуха до 50 °С, а низкотемпературных технических устройств - после отогрева до положительных температур (25 °С) и проветривания',
        ],
        correctAnswers: [
          'После снижения температуры воздуха до 40 °С и проветривания, а низкотемпературных технических устройств - после отогрева до положительных температур (20 °С) и проветривания',
        ],
      },
      {
        code: '63668058',
        text:
          'Что должны обеспечивать конструкция и установка элементов фурменного прибора?',
        answers: [
          'Должны обеспечивать надежность',
          'Должны обеспечивать герметичность',
          'Должны обеспечивать безопасность',
        ],
        correctAnswers: ['Должны обеспечивать герметичность'],
      },
      {
        code: '63668059',
        text:
          'Чем должны быть оборудованы места пересечения железнодорожных путей для подачи составов ковшей с жидким чугуном с путями для подачи шихтовых материалов?',
        answers: [
          'Должны быть оборудованы схемами разъезда по направлению путей',
          'Должны быть оборудованы автоматической сигнализацией',
          'Должны быть оборудованы стрелочными постами',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматической сигнализацией',
        ],
      },
      {
        code: '63668060',
        text:
          'Какие требования установлены к ведению взрывных работ, хранению, транспортированию взрывчатых материалов и эксплуатации броневых ям?',
        answers: [
          'Работы допускается производить без остановки основного технологического оборудования',
          'Работы должны производиться специально выделенным обслуживающим персоналом',
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
        correctAnswers: [
          'Работы должны производиться в соответствии с технологической инструкцией, учитывающей требования правил безопасности при взрывных работах',
        ],
      },
      {
        code: '63668061',
        text:
          'Какие требования предъявляются к устройству и эксплуатации печей для обжига шихтовых материалов и концентратов?',
        answers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 5%',
          'Допускается эксплуатация печей при остаточном разрежении ниже предельно допустимого значения на 10%',
        ],
        correctAnswers: [
          'Запрещается эксплуатация печей при остаточном разрежении ниже величины, указанной в технологической инструкции',
        ],
      },
      {
        code: '63668062',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность горячих участков (с температурой 200 - 400 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          '1 раз в 8 лет',
          'Один раз в 7 лет',
          'Один раз в 5 лет',
          'Один раз в 10 лет',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63668063',
        text: 'Где должна производиться грануляция шлака?',
        answers: [
          'В специальных грануляционных бассейнах, установленных на шлаковом отвале',
          'В специальных барабанных грануляционных системах, расположенных на территории цеха',
          'В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха',
        ],
        correctAnswers: [
          'В специальных герметизированных установках или в грануляционных бассейнах за пределами цеха',
        ],
      },
      {
        code: '63668064',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность внутрицеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,1% в час',
          'Не более 0,2% в час',
          'Не более 0,3% в час',
        ],
        correctAnswers: ['Не более 0,1% в час'],
      },
      {
        code: '63668065',
        text:
          'Как часто должна проводиться проверка правильности показаний приборов, перечень которых утверждается руководителем производства?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 4 месяца',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63668066',
        text:
          'Что должно в обязательном порядке производиться при категорировании зданий и помещений?',
        answers: [
          'Анализ опасностей и оценка риска аварий',
          'Расчет значений тротилового эквивалента мощности взрыва',
          'Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации',
        ],
        correctAnswers: [
          'Расчет избыточного давления взрыва с учетом наиболее опасной модели аварийной ситуации',
        ],
      },
      {
        code: '63668067',
        text:
          'Как часто должна производиться проверка конденсатоотводчиков и пароспутников, обогревающих трубопроводы кислорода в зимнее время?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63668068',
        text:
          'Какая должна быть минимальная высота забора по периметру территории всего комплекса производства водорода?',
        answers: ['2 м', '1 м', '1,5 м', '2,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63668069',
        text: 'Какой срок должны храниться данные самопишущих приборов?',
        answers: ['Не менее 3 месяцев', 'Не менее 6 месяцев', 'Не менее года'],
        correctAnswers: ['Не менее 3 месяцев'],
      },
      {
        code: '63668070',
        text:
          'С какой периодичностью должна производиться проверка технического состояния вентиляционных каналов в зданиях (помещениях) с установленным газоиспользующим оборудованием и пылеуборка, и дезинфекция вентиляционных каналов?',
        answers: [
          'Не реже одного раза в год и не реже одного раза в три месяца соответственно',
          'Не реже двух раз в год и не реже одного раза в год соответственно',
          'Не реже одного раза в год и не реже одного раза в два года соответственно',
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
        correctAnswers: [
          'Не реже двух раз в год и не реже одного раза в три года соответственно',
        ],
      },
      {
        code: '63668071',
        text: 'Где допускается размещение операторных помещений?',
        answers: [
          'Размещение операторных помещений не допускается во всех перечисленных местах',
          'Над помещениями с взрывоопасными зонами любого класса',
          'Под производственными помещениями с мокрым технологическим процессом',
          'Под приточными вентиляционными камерами',
        ],
        correctAnswers: [
          'Размещение операторных помещений не допускается во всех перечисленных местах',
        ],
      },
      {
        code: '63668072',
        text:
          'Каким образом не допускается определять утечки газов из соединений?',
        answers: [
          'С помощью открытого пламени',
          'С помощью специальных течеискателей',
          'С помощью мыльного раствора',
        ],
        correctAnswers: ['С помощью открытого пламени'],
      },
      {
        code: '63668073',
        text:
          'Каким давлением производится дополнительное испытание на герметичность межцеховых и внутрицеховых газопроводов?',
        answers: [
          'Давлением, превышающим рабочее в 1,1 раза',
          'Давлением, превышающим рабочее в 1,5 раза',
          'Давлением, превышающим рабочее в 1,25 раза',
          'Давлением, равным рабочему',
        ],
        correctAnswers: ['Давлением, равным рабочему'],
      },
      {
        code: '63668074',
        text:
          'Какое из перечисленных требований к отводу шлакового желоба на резервную линию установки придоменной грануляции шлака указано верно?',
        answers: [
          'Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не более 1,5 м засыпан песком на 200 мм выше бортов желоба',
          'Должен быть перекрыт отсечным устройством и на длине не менее 0,5 м засыпан песком на 150 мм выше бортов желоба',
        ],
        correctAnswers: [
          'Должен быть перекрыт отсечным устройством и на длине не менее 1 м засыпан песком на 100 мм выше бортов желоба',
        ],
      },
      {
        code: '63668075',
        text:
          'В какой сталеплавильный агрегат завалка металлической стружки не допускается?',
        answers: [
          'В агрегат конверторного типа',
          'В двухванный агрегат',
          'В тигельную печь',
        ],
        correctAnswers: ['В двухванный агрегат'],
      },
      {
        code: '63668076',
        text:
          'Какие требования установлены к проверке и испытанию приборов во взрывопожароопасных и пожароопасных помещениях?',
        answers: [
          'Должны производиться в условиях, исключающих искрообразование',
          'Должны производиться только в специальной защитной одежде',
          'Должны производиться в специально выделенном для этого помещении',
        ],
        correctAnswers: [
          'Должны производиться в условиях, исключающих искрообразование',
        ],
      },
      {
        code: '63668077',
        text:
          'Разрешено ли использовать продувочные свечи для выпуска в атмосферу избыточного газа?',
        answers: [
          'Разрешено при увеличении давления газа до 10%',
          'Не разрешено',
          'Только в аварийных случаях',
        ],
        correctAnswers: ['Только в аварийных случаях'],
      },
      {
        code: '63668078',
        text: 'Где не допускается размещение операторных помещений?',
        answers: [
          'В отдельных щитовых помещениях (встроенных или пристроенных)',
          'В смежных с помещениями с взрывоопасными зонами',
          'В отдельно стоящих зданиях',
          'В производственных помещениях',
        ],
        correctAnswers: ['В производственных помещениях'],
      },
      {
        code: '63668079',
        text:
          'Что необходимо делать во время работы мельницы в помольном помещении?',
        answers: [
          'Закрывать все двери и включать приточно-вытяжную вентиляцию',
          'Закрывать все двери и включать световое предупредительное табло',
          'Включать дополнительное рабочее освещение',
        ],
        correctAnswers: [
          'Закрывать все двери и включать световое предупредительное табло',
        ],
      },
      {
        code: '63668080',
        text: 'Какую арматуру не допускается применять на водородопроводах?',
        answers: [
          'Из серого чугуна',
          'Стальную арматуру для взрывоопасных сред',
          'Специальную арматуру для водорода',
          'Из ковкого и высокопрочного чугуна',
        ],
        correctAnswers: ['Из серого чугуна'],
      },
      {
        code: '63668081',
        text:
          'Что должно быть выполнено во время завалки материалов и подвалки шихты?',
        answers: [
          'Должны быть выполнены требования выполнения производственного процесса согласно акту-наряду',
          'Печь должна быть остановлена, а работники удалены с производственной площадки',
          'Должны быть реализованы мероприятия по безопасности работников',
        ],
        correctAnswers: [
          'Должны быть реализованы мероприятия по безопасности работников',
        ],
      },
      {
        code: '63668082',
        text:
          'В каком радиусе у мест выброса газовоздушной смеси в атмосферу должно быть приостановлено движение всех видов транспорта, запрещено применение открытого огня и производство сварочных работ в период продувки газопровода?',
        answers: [
          'В радиусе 100 м',
          'В радиусе 125 м',
          'В радиусе 50 м',
          'В радиусе 75 м',
        ],
        correctAnswers: ['В радиусе 50 м'],
      },
      {
        code: '63668083',
        text:
          'Как часто должно проверяться исправное действие автоблокировки и сигнализации?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в полгода',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63668084',
        text:
          'Какие требования установлены к скорости движения железнодорожного транспорта на шлаковом дворе?',
        answers: [
          'Скорость не должна превышать 5 км/ч',
          'Скорость не должна превышать 8 км/ч',
          'Скорость не должна превышать 12 км/ч',
        ],
        correctAnswers: ['Скорость не должна превышать 5 км/ч'],
      },
      {
        code: '63668085',
        text:
          'Какие требования установлены к оборудованию производственных помещений, в которых возможны воспламенения одежды или химические ожоги?',
        answers: [
          'Должны предусматриваться санитарные посты',
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Должны быть обустроены пожарные щиты в каждом цеху',
        ],
        correctAnswers: [
          'Должны быть установлены фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63668086',
        text: 'С какой периодичностью следует очищать фильтры?',
        answers: [
          'В первый раз - через 7 суток после ввода в эксплуатацию, далее - через каждые 3 месяца',
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
          'В первый раз - через 14 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
        correctAnswers: [
          'В первый раз - через 10 суток после ввода в эксплуатацию, далее - через каждые 6 месяцев',
        ],
      },
      {
        code: '63668087',
        text:
          'Какие требования установлены к процессу сливания расплава металла из ковшей и миксеров? Выберите правильный вариант ответа.',
        answers: [
          'Сливание должно быть механизировано',
          'Пол рабочей площадки в местах установки ковшей должен быть сухим',
          'Ось сливного отверстия ковша, установленного под слив, должна совпадать с осью заливочного ковша',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63668088',
        text: 'Что должны иметь закрома и ямы?',
        answers: [
          'Должны иметь служебные проходы',
          'Должны иметь с двух сторон металлические решетки',
          'Со всех сторон должны иметь ограждение',
        ],
        correctAnswers: ['Со всех сторон должны иметь ограждение'],
      },
      {
        code: '63668089',
        text:
          'Куда должны заноситься записи о проведении технического обслуживания, выявленных дефектах и нарушениях газопроводов?',
        answers: [
          'В эксплуатационный журнал',
          'В паспорт технического устройства',
          'В журнал дефектов и неполадок оборудования',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63668090',
        text:
          'Как часто должны проходить поверку контрольно-измерительные приборы в процессе эксплуатации?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в год',
          'Не реже одного раза в шесть месяцев',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63668091',
        text:
          'Чем проводится комплексное испытание (совместное испытание на прочность и герметичность) законченных строительством или реконструкцией наружных и внутренних (межцеховых и цеховых) газопроводов?',
        answers: ['Азотом', 'Воздухом', 'Жидкостью под давлением', 'Нагревом'],
        correctAnswers: ['Воздухом'],
      },
      {
        code: '63668092',
        text: 'Как должна производиться установка мульд на стеллажах?',
        answers: [
          'С уклоном в сторону завалочной машины',
          'Со смещением ближе к одной из сторон стеллажа',
          'Без свесов и перекосов',
        ],
        correctAnswers: ['Без свесов и перекосов'],
      },
      {
        code: '63668093',
        text:
          'Какой должен быть общий объем инертного газа для нужд продувки водородной системы?',
        answers: [
          'Не менее 4-кратного объема системы',
          'Не менее 3-кратного объема системы',
          'Не менее 2-кратного объема системы',
        ],
        correctAnswers: ['Не менее 4-кратного объема системы'],
      },
      {
        code: '63668094',
        text:
          'Какие требования к аварийной системе вентиляции в компрессорном помещении при получении водорода методом диссоциации аммиака указаны неверно?',
        answers: [
          'Аварийная система вентиляции должна обеспечивать в компрессорном помещении 8-кратный воздухообмен',
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 20% нижнего предела взрываемости',
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 2,4% содержания водорода в воздухе помещения',
        ],
        correctAnswers: [
          'Аварийная система вентиляции должна включаться в работу автоматически при наличии в помещении газов, достигших 2,4% содержания водорода в воздухе помещения',
        ],
      },
      {
        code: '63668095',
        text:
          'В каком случае допускается режимную наладку газоиспользующего оборудования проводить не реже одного раза в четыре года?',
        answers: [
          'Всегда',
          'При режимной наладке газоиспользующего оборудования',
          'При стабильной работе газоиспользующего оборудования',
        ],
        correctAnswers: [
          'При стабильной работе газоиспользующего оборудования',
        ],
      },
      {
        code: '63668096',
        text:
          'Какие мероприятия должны быть выполнены при уходе металла из тигля?',
        answers: [
          'Жидкий металл должен быть слит для последующего застывания',
          'Работа печи должна быть остановлена на сутки',
          'Печь должна быть отключена, и жидкий металл - слит в изложницу',
        ],
        correctAnswers: [
          'Печь должна быть отключена, и жидкий металл - слит в изложницу',
        ],
      },
      {
        code: '63668097',
        text:
          'Какие действия должны быть выполнены при утрате наряда-допуска на работы повышенной опасности?',
        answers: [
          'Работы должны быть прекращены. Решение о возобновлении работ должна принять комиссия организации',
          'Решение о продолжении работ без наряда-допуска должен принять допускающий к работе',
          'Работы должны быть завершены без оформления нового наряда-допуска',
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
        correctAnswers: [
          'Работы должны быть прекращены. На продолжение работ должен быть оформлен новый наряд-допуск, и допуск к работе произведен заново',
        ],
      },
      {
        code: '63668098',
        text:
          'Из каких материалов выполняются линии отбора кислорода на анализ?',
        answers: [
          'Из коррозионно-стойкой стали или медных сплавов',
          'Из углеродистой стали',
          'Из серого чугуна',
        ],
        correctAnswers: ['Из коррозионно-стойкой стали или медных сплавов'],
      },
      {
        code: '63668099',
        text: 'Как должна устраняться пробуксовка ленты конвейера?',
        answers: [
          'Должна устраняться при помощи ручной регулировки',
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
          'Должна устраняться путем подсыпания канифоли для увеличения трения между лентой и тянущим барабаном',
        ],
        correctAnswers: [
          'Должна устраняться соответствующей натяжкой ленты натяжными устройствами после очистки барабанов и ленты',
        ],
      },
      {
        code: '63668100',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии 0,1 - 0,5 мм/г?',
        answers: ['Один раз в 2 года', 'Один раз в год', 'Один раз в 4 года'],
        correctAnswers: ['Один раз в 2 года'],
      },
      {
        code: '63668101',
        text:
          'Какому классу должна соответствовать герметичность арматуры с металлическим уплотнением в затворе, применяемая для установки на трубопроводах водорода?',
        answers: ['Классу A', 'Классу В', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу В'],
      },
      {
        code: '63668102',
        text: 'Как должна производиться разделка сталевыпускного отверстия?',
        answers: [
          'Должна производиться под руководством начальника смены',
          'Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами',
          'Должна производиться только с использованием металлического инструмента',
        ],
        correctAnswers: [
          'Должна производиться только при наличии под желобом ковшей, а в разливочном пролете - состава с изложницами',
        ],
      },
      {
        code: '63668103',
        text:
          'На каких аппаратах, заполненных водородом, допускается производство ремонтных работ?',
        answers: [
          'Производство ремонтных работ на аппаратах, заполненных водородом, не допускается',
          'На аппаратах, заполненных водородом не более чем на 20% от общего объема',
          'На аппаратах, установленных вне взрывопожароопасных помещений',
        ],
        correctAnswers: [
          'Производство ремонтных работ на аппаратах, заполненных водородом, не допускается',
        ],
      },
      {
        code: '63668104',
        text:
          'В присутствии какого лица должна производиться присадка легковоспламеняющихся порошковых материалов, а также смесей на их основе в печь или ковш?',
        answers: [
          'В присутствии ответственного лица, назначенного распоряжением по цеху',
          'В присутствии сменного литейщика',
          'В присутствии начальника участка работ',
        ],
        correctAnswers: [
          'В присутствии ответственного лица, назначенного распоряжением по цеху',
        ],
      },
      {
        code: '63668105',
        text:
          'Каким образом должен проводиться отогрев трубопроводной арматуры?',
        answers: [
          'Горячим воздухом, паром или горячей водой',
          'Газовыми горелками',
          'Нагревательными электроприборами',
        ],
        correctAnswers: ['Горячим воздухом, паром или горячей водой'],
      },
      {
        code: '63668106',
        text:
          'Какие требования к ресиверам для водорода (сосудам) указаны неверно?',
        answers: [
          'Ресиверы для водорода (сосуды) размещаются на открытых площадках, имеющих по периметру ограждение легкого типа высотой не менее 1,2 м из несгораемого материала',
          'На ограждении открытых площадок, где размещаются ресиверы для водорода (сосуды), должны быть вывешены предупреждающие знаки безопасности: "Курить запрещается", "Посторонним вход воспрещен", на ресиверах должны быть поясняющие надписи: "Водород. Взрывоопасно"',
          'Расстояние от ресиверов (сосудов) с водородом до ограждения - не менее 1,5 м',
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
        correctAnswers: [
          'На ресиверах должны быть поясняющие надписи: "Водород! Не влезай! Убьет!"',
        ],
      },
      {
        code: '63668107',
        text:
          'Какое минимальное количество выходов допускается в помещении операторной при площади свыше 150 м²?',
        answers: ['Два', 'Один', 'Три'],
        correctAnswers: ['Два'],
      },
      {
        code: '63668108',
        text:
          'Чему подлежат производственные емкости (ковши, шлаковые чаши, совки, кюбели, тара, корзины) после изготовления или ремонта?',
        answers: [
          'Механическим испытаниям',
          'Техническому освидетельствованию',
          'Проверке состояния',
        ],
        correctAnswers: ['Проверке состояния'],
      },
      {
        code: '63668109',
        text:
          'Какой порядок действий персонала при пожаре на установке получения водорода указан верно?',
        answers: [
          '1. Вызвать пожарную команду; 2. Перекрыть подачу технологических газов на установку; 3. Снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу',
          '1. Перекрыть подачу технологических газов на установку; 2. Снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу; 3. Вызвать пожарную команду и газоспасательную службу',
          '1. Перекрыть подачу технологических газов на установку; 2. Снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу; 3. Вызвать пожарную команду',
        ],
        correctAnswers: [
          '1. Вызвать пожарную команду; 2. Перекрыть подачу технологических газов на установку; 3. Снизить давление и выпустить водород в факельную систему для утилизации либо в обоснованных проектом случае в атмосферу',
        ],
      },
      {
        code: '63668110',
        text:
          'Когда могут производиться ремонтные работы с открытым огнем в помещении с обращением водорода водородной станции?',
        answers: [
          'Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через 3 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
          'Не ранее чем через 2 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
        ],
        correctAnswers: [
          'Не ранее чем через 4 часа после отключения установки, проведения анализа воздуха на отсутствие водорода и при соблюдении мер безопасности при огневых работах',
        ],
      },
      {
        code: '63668111',
        text:
          'Какое из перечисленных требований к применению жетон-бирочной системы указано неверно?',
        answers: [
          'Жетон-бирки после разборки электрической схемы ТУ должны передаваться от технологического персонала ремонтным работникам с отметкой всех этапов в журнале',
          'Жетон-бирочная система должна применяться при выводе ТУ на продолжительную остановку',
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
          'Жетон-бирочная система применяется для предотвращения травм и аварий в результате ошибочного или несанкционированного включения технических устройств (ТУ)',
        ],
        correctAnswers: [
          'Жетон-бирочная система предусматривает применение бирки при эксплуатации только тех ТУ, которые оборудованы замками-выключателями',
        ],
      },
      {
        code: '63668112',
        text:
          'На каком расстоянии не допускается производство работ на путях от стоящих под наливом ковшей?',
        answers: [
          'На расстоянии ближе 15 м',
          'На расстоянии ближе 12 м',
          'На расстоянии ближе 10 м',
        ],
        correctAnswers: ['На расстоянии ближе 15 м'],
      },
      {
        code: '63668113',
        text:
          'После выполнения каких мероприятий должна производиться заливка чугуна в печь?',
        answers: [
          'Только после подготовки и установки шлаковых ковшей',
          'Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей',
          'Только после отключения горелок',
        ],
        correctAnswers: [
          'Только после полного закрытия сталевыпускного отверстия, установки сталевыпускного желоба и шлаковых ковшей',
        ],
      },
      {
        code: '63668114',
        text:
          'Что должно регулярно производиться в корпусах обезвоживания шлама?',
        answers: [
          'Гидросмыв шлама с рабочих площадок и строительных конструкций',
          'Механическое удаление шлама с рабочих площадок и строительных конструкций',
          'Влажная уборка рабочих площадок и конструкций от пыли',
        ],
        correctAnswers: [
          'Гидросмыв шлама с рабочих площадок и строительных конструкций',
        ],
      },
      {
        code: '63668115',
        text:
          'Что должны обеспечивать системы охлаждения горна и лещади доменной печи?',
        answers: [
          'Должны обеспечивать перепад температуры не более 3 °С',
          'Должны обеспечивать перепад температуры не более 5 °С',
          'Должны обеспечивать перепад температуры не более 4 °С',
        ],
        correctAnswers: [
          'Должны обеспечивать перепад температуры не более 3 °С',
        ],
      },
      {
        code: '63668116',
        text:
          'Как часто комиссионно должно проверяться техническое состояние воздухонагревателей и их арматура?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в три месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63668117',
        text:
          'Чему из перечисленного должны соответствовать средства автоматизации, производственной сигнализации и связи, блокировочные устройства? Выберите все правильные ответы.',
        answers: [
          'Производственной документации',
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
          'Основным требованиям по обеспечению готовности к аварийным ситуациям',
          'Требованиям Технического регламента "О безопасности оборудования для работы во взрывоопасных средах"',
        ],
        correctAnswers: [
          'Производственной документации',
          'Требованиям "Правил безопасности процессов получения или применения металлов"',
        ],
      },
      {
        code: '63668118',
        text:
          'При какой чистоте водорода должен производиться пуск компрессора для наполнения баллонов?',
        answers: [
          'Не менее 99,7%',
          'Не менее 99,1%',
          'Не менее 98,7%',
          'Не менее 95,7%',
        ],
        correctAnswers: ['Не менее 99,7%'],
      },
      {
        code: '63668119',
        text:
          'Какое требование к устройству внутренних производственных помещений, находящихся на производственной площадке, включающей технологию с расплавами металлов, имеющей опасность аварии или травмирования работников, указано неверно?',
        answers: [
          'Внутренние помещения производственной площадки должны иметь защиту от воздействия теплового излучения',
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
          'Закрытые пульты управления должны быть оборудованы съемными металлическими сетками или специальными защитными экранами на окнах',
          'Пульты управления агрегатов должны быть расположены на площадке в месте, обеспечивающем их безопасность',
        ],
        correctAnswers: [
          'Помещения пультов управления должны иметь вытяжную вентиляцию',
        ],
      },
      {
        code: '63668120',
        text:
          'В каком количестве экземпляров составляется акт-допуск подрядных организаций на опасный производственный объект горно-металлургической промышленности?',
        answers: [
          'В двух экземплярах',
          'В одном экземпляре',
          'В трех экземплярах',
          'В четырех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63668121',
        text:
          'Какому давлению гидравлического испытания должны подвергаться водоохлаждаемые элементы печей перед их установкой?',
        answers: [
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
          'На 1,6 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63668122',
        text: 'Какой установлен порядок хранения обтирочных материалов?',
        answers: [
          'В открытой таре в отдельном помещении',
          'В закрытых деревянных ящиках на производственной площадке',
          'В чистых металлических ящиках с крышками',
        ],
        correctAnswers: ['В чистых металлических ящиках с крышками'],
      },
      {
        code: '63668123',
        text:
          'Какие требования к размещению запорной арматуры на водородопроводах указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
          'В отдельных случаях допускается применение запорной арматуры из ковкого и высокопрочного чугуна при давлении не более 3,0 МПа и температуре от 10 °C до 40 °C, работающей в условиях, не подверженных вибрациям и резко переменному температурному режиму',
          'Арматура должна быть закреплена так, чтобы в результате температурных деформаций газопроводов в них создавались изгибающие напряжения',
          'Арматура должна размещаться над дверными проемами и технологическими проходами',
        ],
        correctAnswers: [
          'Ручной привод арматуры должен располагаться на высоте не более 1,8 м от уровня площадки или пола помещения',
        ],
      },
      {
        code: '63668124',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей?',
        answers: [
          'Только системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Только блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- и пневмоснабжения КИПиА',
          'Только средствами сигнализации о прекращении поступления топлива, а также воздуха при его принудительной подаче в топочное пространство',
          'Только средствами контроля за уровнем тяги и автоматического прекращения подачи топливного газа в зону горения при остановке дымососа или недопустимом снижении разрежения в печи, а при компоновке печных агрегатов с котлами-утилизаторами системами по переводу на работу агрегатов без дымососов',
          'Только средствами автоматической подачи водяного пара или инертного газа в топочное пространство и в змеевики при прогаре труб, характеризующимися падением давления нагреваемого продукта на выходе из печи ниже регламентированного значения',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63668125',
        text: 'Как должна производиться заливка чугуна в миксер?',
        answers: [
          'В центр окна равномерной струей с минимальной высоты',
          'С помощью специальной воронки',
          'Медленно с кратковременными перерывами',
        ],
        correctAnswers: [
          'В центр окна равномерной струей с минимальной высоты',
        ],
      },
      {
        code: '63668126',
        text:
          'На какой максимальный срок выдается наряд-допуск на работы повышенной опасности?',
        answers: [
          'На 7 календарных дней',
          'На 30 календарных дней',
          'На 10 рабочих дней',
          'На 14 календарных дней',
        ],
        correctAnswers: ['На 30 календарных дней'],
      },
      {
        code: '63668127',
        text:
          'Какие требования к процессу получения водорода и диссоциированного аммиака указаны неверно?',
        answers: [
          'Процесс получения водорода и диссоциированного аммиака должен быть автоматизирован',
          'Регулировка и контроль производится с помощью приборов',
          'Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности Б',
        ],
        correctAnswers: [
          'Аппаратура автоматического регулирования, контроля и сигнализации должна быть установлена на пульте управления, расположенном в отдельном помещении категории пожарной опасности Б',
        ],
      },
      {
        code: '63668128',
        text:
          'Чем должны быть защищены опорные колонны печи здания литейного двора и поддоменника от возможного воздействия чугуна и шлака?',
        answers: [
          'Должны быть защищены решетчатыми металлическими ограждениями',
          'Должны быть защищены огнеупорным материалом',
          'Должны быть защищены плитами из поликарбоната',
        ],
        correctAnswers: ['Должны быть защищены огнеупорным материалом'],
      },
      {
        code: '63668129',
        text:
          'В каких из перечисленных случаев допускается продолжать работы, проводимые по наряду-допуску, на опасных производственных объектах?',
        answers: [
          'Если изменился состав бригады',
          'Если обнаружено несоответствие фактического состояния производства работ требованиям безопасности и охраны труда',
          'Если выявлено разовое нарушение условий отключения технических устройств',
          'Если изменился характер, объем и порядок выполнения работ',
        ],
        correctAnswers: ['Если изменился состав бригады'],
      },
      {
        code: '63668130',
        text:
          'С какой периодичностью должны проводиться испытания на плотность и прочность холодных участков (с температурой до 200 °C) водородопроводов, работающих под давлением до 15,0 МПа (150 кгс/см²)?',
        answers: [
          'Один раз в 8 лет',
          'Один раз в 9 лет',
          'Один раз в 11 лет',
          'Один раз в 10 лет',
        ],
        correctAnswers: ['Один раз в 8 лет'],
      },
      {
        code: '63668131',
        text:
          'С какой периодичностью оператору установки необходимо производить анализ на содержание аммиака в помещении диссоциаторов методами и приборами, установленными в технологическом регламенте (технологической инструкции)?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже двух раз в месяц',
          'Не реже одного раза в неделю',
          'Два раза в сутки',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63668132',
        text: 'Что должно исключаться при присадке раскислителей в ковш?',
        answers: [
          'Повреждение стопорного устройства',
          'Подача в ковш порошкообразных раскислителей',
          'Механизированная присадка',
        ],
        correctAnswers: ['Повреждение стопорного устройства'],
      },
      {
        code: '63668133',
        text:
          'В соответствии с каким документом работникам необходимо соблюдать требования безопасности при загрузке и выгрузке катализаторов и адсорбентов?',
        answers: [
          'В соответствии с технологическим регламентом',
          'В соответствии с конструкторской документацией',
          'В соответствии с производственной документацией',
          'В соответствии с инструкцией по эксплуатации',
        ],
        correctAnswers: ['В соответствии с технологическим регламентом'],
      },
      {
        code: '63668134',
        text:
          'Какие требования из перечисленных должны предусматриваться для насосов и компрессоров (группы насосов и компрессоров), перемещающих горючие продукты? Выберите правильный вариант ответа.',
        answers: [
          'Должно предусматриваться только дистанционное отключение',
          'Должна предусматриваться только установка на линиях всасывания и нагнетания запорных или отсекающих устройств',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63668135',
        text:
          'Из каких сплавов металлов запрещается установка арматуры на кислородопроводах?',
        answers: ['Из чугуна', 'Из сплавов титана', 'Из стали'],
        correctAnswers: ['Из сплавов титана'],
      },
      {
        code: '63668136',
        text:
          'При какой температуре возможно вскрытие реакторов каталитического гидрирования?',
        answers: [
          'После остывания их до температуры 40 °С',
          'После остывания их до температуры 45 °С',
          'После остывания их до температуры 50 °С',
        ],
        correctAnswers: ['После остывания их до температуры 40 °С'],
      },
      {
        code: '63668137',
        text:
          'Какой должна быть ширина свободного прохода между щитом управления вентилями и ближайшими стенами наполнительной?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 0,6 м',
          'Не менее 0,7 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63668138',
        text:
          'Какая устанавливается продолжительность периодических испытаний на герметичность для газопроводов, а также испытаний после ремонта, связанного со сваркой и разборкой газопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63668139',
        text:
          'С какой периодичностью должны производиться замеры температуры кожухов воздухонагревателя?',
        answers: [
          'Постоянно (не реже одного раза в неделю)',
          'Регулярно (не реже двух раз в месяц)',
          'Систематически (не реже одного раза в месяц)',
        ],
        correctAnswers: ['Систематически (не реже одного раза в месяц)'],
      },
      {
        code: '63668140',
        text:
          'Какие виды работ должны выполняться не реже одного раза в 6 месяцев при эксплуатации ГРП и ГРУ?',
        answers: [
          'Проверка параметров срабатывания предохранительных запорных и сбросных клапанов',
          'Техническое обслуживание',
          'Текущий ремонт',
          'Капитальный ремонт',
        ],
        correctAnswers: ['Техническое обслуживание'],
      },
      {
        code: '63668141',
        text:
          'На какой высоте от пола должны быть расположены окна в помещениях отделений наполненных баллонов, наполнительных и разрядных рамп?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 1,2 м',
          'Не менее 1,0 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63668142',
        text:
          'Какой допускается максимальный подъем температуры газа в газопроводах?',
        answers: ['65 °C', '75 °C', '85 °C', '90 °C'],
        correctAnswers: ['85 °C'],
      },
      {
        code: '63668143',
        text:
          'При каком содержании кислорода в колошниковом газе закрытых рудовосстановительных печей печь немедленно отключается?',
        answers: [
          'При увеличении до 1%',
          'При увеличении до 3%',
          'При увеличении до 2%',
        ],
        correctAnswers: ['При увеличении до 2%'],
      },
      {
        code: '63668144',
        text: 'Какие требования установлены к подвешиванию скипов?',
        answers: [
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый',
          'Скипы должны подвешиваться не менее чем на четырех канатах, имеющих четырехкратный запас прочности каждый',
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих десятикратный запас прочности каждый',
        ],
        correctAnswers: [
          'Скипы должны подвешиваться не менее чем на двух канатах, имеющих шестикратный запас прочности каждый',
        ],
      },
      {
        code: '63668145',
        text:
          'Каким инструментом выполняется взятие проб жидкого чугуна на химический анализ?',
        answers: [
          'Инструментом, изготовленным из огнестойких материалов',
          'Просушенным и подогретым инструментом',
          'Инструментом, обладающим термостойкими качествами',
        ],
        correctAnswers: ['Просушенным и подогретым инструментом'],
      },
      {
        code: '63668146',
        text:
          'Чем должны защищаться стальные канаты и цепи грузоподъемных устройств, предназначенные для перемещения ковшей с расплавленным металлом, а также траверсы самих ковшей?',
        answers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
          'Должны покрываться огнестойкими составами для защиты от тепловой нагрузки',
          'Должны защищаться теплоизолированными материалами от воздействия температур',
        ],
        correctAnswers: [
          'Должны защищаться кожухами от воздействия лучистого тепла',
        ],
      },
      {
        code: '63668147',
        text:
          'Чем должна быть продута горелка перед включением и подачей газа?',
        answers: ['Сжатым воздухом', 'Азотом', 'Кислородом'],
        correctAnswers: ['Кислородом'],
      },
      {
        code: '63668148',
        text:
          'Каким должно быть минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?',
        answers: ['0,5 кПа', '0,4 кПа', '0,3 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63668149',
        text:
          'Каким гидравлическим давлением должны испытываться на прочность и плотность трубки системы охлаждения индуктора?',
        answers: [
          'На 1,15 величины рабочего давления охлаждающей воды',
          'На 1,5 величины рабочего давления охлаждающей воды',
          'На 1,25 величины рабочего давления охлаждающей воды',
        ],
        correctAnswers: ['На 1,5 величины рабочего давления охлаждающей воды'],
      },
      {
        code: '63668150',
        text:
          'Какие независимые технологические линии должны иметь в своем составе установки придоменной грануляции шлака? Выберите два варианта ответа.',
        answers: ['Рабочую', 'Резервную', 'Запасную', 'Дополнительную'],
        correctAnswers: ['Рабочую', 'Резервную'],
      },
      {
        code: '63668151',
        text: 'Какие требования установлены к сливу шлака в шлаковую яму?',
        answers: [
          'Должен производиться свободно падающей струей',
          'Должен производиться равномерной струей',
          'Должен выполняться кантовкой одновременно нескольких ковшей',
        ],
        correctAnswers: ['Должен производиться равномерной струей'],
      },
      {
        code: '63668152',
        text: 'Какие требования установлены к диаметру лазов пылеуловителей?',
        answers: [
          'Диаметр не менее 600 мм',
          'Диаметр не менее 400 мм',
          'Диаметр не менее 500 мм',
        ],
        correctAnswers: ['Диаметр не менее 600 мм'],
      },
      {
        code: '63668153',
        text:
          'Какое из перечисленных требований к манометрам, используемым при проведении испытаний газопроводов и газовых установок, указано верно?',
        answers: [
          'Класс точности манометров не ниже 2,0',
          'Диаметр корпуса не менее 200 мм',
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
        correctAnswers: [
          'Шкала на номинальное давление не менее 4/3 и не более 5/3 от величины измеряемого',
        ],
      },
      {
        code: '63668154',
        text:
          'Какие устройства должны быть установлены на нагнетательном газопроводе для отключения компрессора от коллектора высокого давления?',
        answers: [
          'Только обратный клапан',
          'Два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 5 мм',
          'Обратный клапан и два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
        ],
        correctAnswers: [
          'Обратный клапан и два запорных вентиля, между которыми должна быть свеча с условным проходом не менее 6 мм, имеющая прямое сообщение с атмосферой',
        ],
      },
      {
        code: '63668155',
        text:
          'Что должно предусматриваться для обеспечения требуемых режимов эксплуатации установки по производству водорода? Выберите правильный вариант ответа.',
        answers: [
          'Должно предусматриваться только управление и контроль всех технологических параметров оборудования из операторной',
          'Должно предусматриваться только дистанционный контроль оборудования из операторной',
          'Должно предусматриваться только автоматическое отключение установки по производству водорода при нарушении нормальной работы по технологическим параметрам',
          'Должно предусматриваться только дистанционное (аварийное) отключение установки по производству водорода',
          'Должно предусматриваться все перечисленное',
        ],
        correctAnswers: ['Должно предусматриваться все перечисленное'],
      },
      {
        code: '63668156',
        text:
          'Каким документом определяется максимальная величина давления газа (аргона), необходимая для открывания донных продувочных фурм и ковшевых пробок?',
        answers: [
          'Инструкцией по охране труда в литейном производстве',
          'Инструкцией о мерах пожарной безопасности при проведении огневых работ',
          'Технологической инструкцией',
          'Планом производства работ повышенной опасности',
        ],
        correctAnswers: ['Технологической инструкцией'],
      },
      {
        code: '63668157',
        text:
          'Чем должны быть оборудованы ванны с кислотой для травления оборудования?',
        answers: [
          'Средствами для защиты органов дыхания',
          'Магистральными фильтрами',
          'Местными отсосами',
          'Ручным насосом',
        ],
        correctAnswers: ['Местными отсосами'],
      },
      {
        code: '63668158',
        text: 'Какие требования установлены к кабине завалочной машины?',
        answers: [
          'Должна иметь 4 фиксированных положения',
          'Должна оснащаться устройством принудительной вентиляции',
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
        correctAnswers: [
          'Должна быть теплоизолированной и защищать машиниста от возможных брызг металла и шлака',
        ],
      },
      {
        code: '63668159',
        text:
          'До каких пор должна осуществляться продувка азотом перед пуском в работу установки по производству реформерного газа для производства металлизованного горячевосстановленного железа?',
        answers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
          'Пока на участке продувки кислорода будет не более 2% кислорода',
          'Пока на участке продувки кислорода будет не более 3% кислорода',
          'Пока на участке продувки кислорода будет не более 4% кислорода',
        ],
        correctAnswers: [
          'Пока на участке продувки кислорода будет не более 1% кислорода',
        ],
      },
      {
        code: '63668160',
        text:
          'Какие требования установлены к проведению ремонтных работ внутри нагретых технических устройств?',
        answers: [
          'Проведение ремонтных работ разрешается после разгерметизации и снижения температуры воздуха в них до 45 °С',
          'Проведение ремонтных работ разрешается после продувки и снижения температуры воздуха в них до 50 °С',
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С',
        ],
        correctAnswers: [
          'Проведение ремонтных работ разрешается после проветривания и снижения температуры воздуха в них до 40 °С',
        ],
      },
      {
        code: '63668161',
        text:
          'Что обязаны проверить сталевар и ответственные лица перед включением печи на плавку?',
        answers: [
          'Исправность систем автоматизации',
          'Исправность оборудования, футеровки и свода печи',
          'Исправность систем газоснабжения',
        ],
        correctAnswers: ['Исправность оборудования, футеровки и свода печи'],
      },
      {
        code: '63668162',
        text:
          'Какие требования к вентиляции помещения, где возможно выделение водорода, указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
          'В случае аварии дополнительно к постоянно работающей общеобменной вентиляции производства водорода должна автоматически включаться аварийная приточная система',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 2 в час',
        ],
        correctAnswers: [
          'Помещения оборудуются естественной вытяжной вентиляцией из верхней зоны через дефлекторы в объеме не менее однократного в час',
          'При обосновании допускается устройство механической приточно-вытяжной общеобменной вентиляции с кратностью воздухообмена не менее 6 в час. В этом случае должна быть предусмотрена аварийная вентиляция с кратностью не менее 8 в час',
        ],
      },
      {
        code: '63668163',
        text:
          'На какие группы подразделяются газоопасные места на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'На I и II группы газоопасности',
          'На I, II и III группы газоопасности',
          'На I, II, III и IV группы газоопасности',
        ],
        correctAnswers: ['На I и II группы газоопасности'],
      },
      {
        code: '63668164',
        text:
          'Какая продолжительность пневматических испытаний установлена Правилами безопасности процессов получения или применения металлов после проведения плановых или аварийных остановок водородопроводов?',
        answers: ['1 час', '2 часа', '1,5 часа', '0,5 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63668165',
        text:
          'После выполнения каких мероприятий разрешается производить работы на своде мартеновской печи?',
        answers: [
          'Только после выпуска плавки и до заливки чугуна в печь',
          'Только после полной остановки работы печи',
          'Только после остановки печи на текущий ремонт',
        ],
        correctAnswers: [
          'Только после выпуска плавки и до заливки чугуна в печь',
        ],
      },
      {
        code: '63668166',
        text:
          'Какие требования установлены к выгрузке шихтовых материалов на рудном дворе?',
        answers: [
          'Выгрузка должна быть механизирована',
          'Выгрузка должна осуществляться с применением стального инструмента',
          'Выгрузка может быть организована ручным способом',
        ],
        correctAnswers: ['Выгрузка должна быть механизирована'],
      },
      {
        code: '63668167',
        text:
          'Какие требования установлены к инструментам и приспособлениям, используемым во взрывопожароопасных зонах и помещениях?',
        answers: [
          'Не должны давать искры при работе с ними',
          'Должны быть изготовлены из стали',
          'Должны быть изготовлены из чугуна',
        ],
        correctAnswers: ['Не должны давать искры при работе с ними'],
      },
      {
        code: '63668168',
        text:
          'При каком давлении должна производиться настройка срабатывания предохранительно-запорных клапанов при их наличии в ГРП (ГРУ)?',
        answers: [
          'На 15% больше расчетного',
          'На 10% больше расчетного',
          'На 15% больше пробного',
          'На 15% больше атмосферного',
        ],
        correctAnswers: ['На 15% больше расчетного'],
      },
      {
        code: '63668169',
        text:
          'В каком положении должны находиться баллоны со сжиженным газом во время работы?',
        answers: [
          'В наклонном положении с вентилем, направленным вверх',
          'В вертикальном положении',
          'В горизонтальном положении',
          'В любом положении',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63668170',
        text:
          'Какие требования установлены к установке расходных баков с мазутом?',
        answers: [
          'Должны быть установлены на расстоянии не менее 7 м от печей и защищены металлическим ограждением',
          'Должны быть установлены на расстоянии не менее 10 м от печей',
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
        correctAnswers: [
          'Должны быть установлены на расстоянии не менее 5 м от печей и должны быть защищены специальными экранами от нагревания теплоизлучением',
        ],
      },
      {
        code: '63668171',
        text:
          'В каком случае при проектировании крюковых подвесок необходимо предусматривать стопорение гайки дополнительной фиксацией?',
        answers: [
          'С механизмом вращения крюка',
          'С использованием радиальных подшипников',
          'С применением укороченных крюковых подвесок',
          'С применением всех перечисленных устройств',
        ],
        correctAnswers: ['С механизмом вращения крюка'],
      },
      {
        code: '63668172',
        text:
          'До розжига газопотребляющего агрегата чем должен быть продут газопровод перед коллектором?',
        answers: ['Водяным паром', 'Газом', 'Сжатым воздухом'],
        correctAnswers: ['Газом'],
      },
      {
        code: '63668173',
        text:
          'При какой скорости коррозии срок проведения ревизии газопроводов должен быть не реже одного раза в 2 года?',
        answers: ['До 0,1 мм/г', '0,1 - 0,5 мм/г', 'Более 0,5 мм/г'],
        correctAnswers: ['0,1 - 0,5 мм/г'],
      },
      {
        code: '63668174',
        text:
          'Какие требования установлены к раздеванию слитков с помощью напольных машин или кранов?',
        answers: [
          'Необходимо осуществлять только после полного затвердевания слитков',
          'Необходимо осуществлять только после охлаждения слитков до температуры не выше 300 °С',
          'Необходимо осуществлять только в специальном помещении после образования твердой корки',
        ],
        correctAnswers: [
          'Необходимо осуществлять только после полного затвердевания слитков',
        ],
      },
      {
        code: '63668175',
        text:
          'На какую величину с возвышением внешнего рельса со стороны слива по отношению к другому должны укладываться пути для шлака на отвале?',
        answers: [
          'Не более 150 мм',
          'Не более 160 мм',
          'Не более 170 мм',
          'Не более 180 мм',
        ],
        correctAnswers: ['Не более 150 мм'],
      },
      {
        code: '63668176',
        text: 'Подача шихты с какой влажностью запрещается в печь "Аусмелт"?',
        answers: [
          'Более 5% по массе',
          'Более 7% по массе',
          'Более 8% по массе',
          'Более 3% по массе',
        ],
        correctAnswers: ['Более 8% по массе'],
      },
      {
        code: '63668177',
        text:
          'Какие сроки проведения ревизии газопроводов должны быть при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63668178',
        text:
          'После чего разрешается производить слив расплава в ковши, вышедшие из ремонта?',
        answers: [
          'Только после их охлаждения и смазки',
          'Только после выдержки в течение суток при температуре 40 °С',
          'Только после тщательного их просушивания и разогрева',
        ],
        correctAnswers: [
          'Только после тщательного их просушивания и разогрева',
        ],
      },
      {
        code: '63668179',
        text:
          'Какой напорный бак должен быть установлен в печи "Аусмелт" на случай отключения электроснабжения?',
        answers: [
          'Обеспечивающий подачу воды в течение 10 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 5 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 25 минут с момента отказа системы циркуляционного водяного охлаждения',
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
        correctAnswers: [
          'Обеспечивающий подачу воды в течение 15 минут с момента отказа системы циркуляционного водяного охлаждения',
        ],
      },
      {
        code: '63668180',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,01 МПа (0,1 кгс/см²)',
          'При давлении 0,02 МПа (0,2 кгс/см²)',
          'При давлении 0,05 МПа (0,5 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63668181',
        text:
          'Какие из перечисленных ограждающих конструкций не относятся к легкосбрасываемым?',
        answers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
          'Конструкции из асбоцементных, алюминиевых и стальных листов с легким утеплением',
          'Фонарные переплеты',
        ],
        correctAnswers: [
          'Окна с обычным оконным стеклом толщиной 4 мм и площадью 0,7 м2',
        ],
      },
      {
        code: '63668182',
        text:
          'Какой запас прочности должны иметь канаты для подвески и подъема контргрузов?',
        answers: ['Десятикратный', 'Восьмикратный', 'Четырехкратный'],
        correctAnswers: ['Восьмикратный'],
      },
      {
        code: '63668183',
        text:
          'Чем должны быть перекрыты приемные бункера грануляционных установок?',
        answers: [
          'Предохранительными решетками с ячейками не более 200 х 200 мм',
          'Предохранительными решетками с ячейками не более 230 х 230 мм',
          'Предохранительными решетками с ячейками не более 300 х 300 мм',
        ],
        correctAnswers: [
          'Предохранительными решетками с ячейками не более 200 х 200 мм',
        ],
      },
      {
        code: '63668184',
        text:
          'Какое минимальное количество выходов допускается в помещении операторной, расположенном на первом этаже?',
        answers: ['Один', 'Два', 'Три'],
        correctAnswers: ['Один'],
      },
      {
        code: '63668185',
        text:
          'Кем могут проводиться работы повышенной опасности на опасных производственных объектах горно-металлургической промышленности?',
        answers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
          'Только работниками подрядных организаций',
          'Только работниками эксплуатирующей организации',
        ],
        correctAnswers: [
          'Как работниками эксплуатирующей организации, так и работниками подрядных организаций',
        ],
      },
      {
        code: '63668186',
        text:
          'В соответствии с требованиями какой документации должны производиться проектирование, монтаж и эксплуатация водородопроводов, а также вспомогательных трубопроводов обвязки участков комплекса получения и потребления водорода?',
        answers: [
          'В соответствии с требованиями только строительных норм и правил',
          'В соответствии с требованиями только нормативно-технической документации для технологических стальных трубопроводов',
          'В соответствии с требованиями только Правил безопасности процессов получения или применения металлов',
          'В соответствии с требованиями всех перечисленных документов',
        ],
        correctAnswers: [
          'В соответствии с требованиями всех перечисленных документов',
        ],
      },
      {
        code: '63668187',
        text:
          'Какие требования установлены к сцепке и расцепке сталевозной тележки?',
        answers: [
          'Должны производиться вручную с применением сцепных устройств',
          'Должны быть с автоматическим гидрораспределителем',
          'Должны быть дистанционными',
        ],
        correctAnswers: ['Должны быть дистанционными'],
      },
      {
        code: '63668188',
        text:
          'В каком случае допускается использовать продувочные свечи газопроводов для выпуска в атмосферу избыточного газа?',
        answers: [
          'Всегда',
          'В аварийных случаях',
          'По распоряжению главного инженера',
        ],
        correctAnswers: ['В аварийных случаях'],
      },
      {
        code: '63668189',
        text:
          'Какие из перечисленных требований к наряду-допуску на работы повышенной опасности указаны неверно? Выберите два правильных ответа.',
        answers: [
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
          'Допуск работников на рабочее место после обеденного перерыва в течение одной смены осуществляется производителем работ без оформления в наряде-допуске',
          'После окончания работы наряд-допуск сдается допускающему к работе или работнику, выдавшему наряд-допуск',
        ],
        correctAnswers: [
          'Работники бригады имеют право возвращаться после обеденного перерыва на рабочее место без производителя (руководителя) работ',
          'После окончания работы наряд-допуск остается у производителя (руководителя) работ',
        ],
      },
      {
        code: '63668190',
        text:
          'Сколько суток после наполнения должны выдерживаться баллоны с криптоноксеноновой смесью и криптоном в наполнительной комнате или на складе баллонов?',
        answers: [
          'Не менее 10 суток',
          'Не менее 14 суток',
          'Не менее 12 суток',
        ],
        correctAnswers: ['Не менее 14 суток'],
      },
      {
        code: '63668191',
        text:
          'Какое количество производителей (руководителей) работ назначаются на все время действия наряда-допуска на работы повышенной опасности при производстве работ в несколько смен?',
        answers: [
          'Не более двух производителей (руководителей) работ',
          'Количество производителей (руководителей) работ должен определять выдающий наряд-допуск',
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
          'Один производитель (руководитель) работ',
        ],
        correctAnswers: [
          'Несколько производителей (руководителей) работ в соответствии с количеством смен',
        ],
      },
      {
        code: '63668192',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии до 0,1 мм/г?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в 2 года',
          'Один раз в 4 года',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в 4 года'],
      },
      {
        code: '63668193',
        text:
          'При каком избыточном давлении газа в газопроводах разрешается производить электросварочные работы на газопроводах при ремонтах?',
        answers: [
          'Не более 0,3 кПа',
          'Не более 0,5 кПа',
          'Не более 3 кПа',
          'Не более 5 кПа',
        ],
        correctAnswers: ['Не более 0,3 кПа'],
      },
      {
        code: '63668194',
        text:
          'Какие требования к компрессорным установкам указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Компрессор и его электродвигатель должны устанавливаться на фундаментах, не связанных с конструкциями здания',
          'Двери и окна помещения, где расположены компрессорные установки, должны открываться наружу',
          'В помещении компрессорной установки следует предусматривать специальные места для хранения в закрытом виде обтирочных материалов, инструмента, прокладок, а также для хранения недельного запаса масла',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63668195',
        text:
          'При каком давлении должен срабатывать предохранительный клапан вакуумной камеры печи?',
        answers: [
          'При давлении 0,02 МПа (0,2 кгс/см²)',
          'При давлении 0,05 МПа (0,5 кгс/см²)',
          'При давлении 0,01 МПа (0,1 кгс/см²)',
        ],
        correctAnswers: ['При давлении 0,01 МПа (0,1 кгс/см²)'],
      },
      {
        code: '63668196',
        text: 'Как должны подготавливаться растворы кислот и щелочей?',
        answers: [
          'Вода комнатной температуры должна заливаться равномерной струей в кислоту и щелочь',
          'Кислота и щелочь должны заливаться небольшой струей в воду, нагретую до температуры 60 °С',
          'Кислота и щелочь должны заливаться небольшой струей в холодную воду',
        ],
        correctAnswers: [
          'Кислота и щелочь должны заливаться небольшой струей в холодную воду',
        ],
      },
      {
        code: '63668197',
        text: 'Что должно быть установлено в здании подъемника?',
        answers: [
          'Телефонная связь с диспетчерской службой',
          'Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой',
          'Видеосвязь с колошниковой площадкой',
        ],
        correctAnswers: [
          'Прямая телефонная (радио-) связь с колошниковой площадкой и скиповой ямой',
        ],
      },
      {
        code: '63668198',
        text:
          'При каких показателях газа разрешено применять жидкостные манометры?',
        answers: [
          'При давлении до 0,06 МПа',
          'При давлении до 0,03 МПа',
          'При давлении до 0,04 МПа',
        ],
        correctAnswers: ['При давлении до 0,03 МПа'],
      },
      {
        code: '63668199',
        text: 'Как должны быть расположены пульты управления агрегатами?',
        answers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
          'На расстоянии не менее 5 м от агрегатов',
          'В непосредственной близости от агрегатов',
        ],
        correctAnswers: [
          'В безопасном месте и обеспечивать видимость агрегата и проводимых работ на площадке',
        ],
      },
      {
        code: '63668200',
        text:
          'С помощью каких устройств должно производиться удаление пыли из изложниц?',
        answers: [
          'С помощью промывочного насоса',
          'С помощью продувочного оборудования',
          'С помощью пылеотсасывающих устройств',
        ],
        correctAnswers: ['С помощью пылеотсасывающих устройств'],
      },
      {
        code: '63668201',
        text:
          'Какие требования установлены к срокам осмотра баков (емкостей) для смазочных материалов и баков для варки смолы и к осмотру и чистке смотровых люков и вытяжных труб баков для варки смолы?',
        answers: [
          'Осмотр должен производиться не реже двух раз в месяц, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - еженедельно',
          'Осмотр должен производиться не реже одного раза в полугодие, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежесменно',
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
        ],
        correctAnswers: [
          'Осмотр должен производиться не реже одного раза в год, а осмотр и чистка смотровых люков и вытяжных труб баков для варки смолы - ежедневно',
        ],
      },
      {
        code: '63668202',
        text:
          'Каково минимально допустимое давление газа непосредственно перед потребителем (после регулирующих приборов)?',
        answers: ['0,3 кПа', '0,4 кПа', '0,5 кПа'],
        correctAnswers: ['0,5 кПа'],
      },
      {
        code: '63668203',
        text: 'Какое должно быть расстояние между ресиверами одного газа?',
        answers: [
          'Не менее 1,5 м в свету',
          'Не менее 1,0 м в свету',
          'Не менее 0,5 м в свету',
          'Не менее 1,2 м в свету',
        ],
        correctAnswers: ['Не менее 1,5 м в свету'],
      },
      {
        code: '63668204',
        text:
          'С какой периодичностью должна производиться ревизия с разборкой регулятора давления, предохранительных клапанов, фильтров газопроводов, газовых установок и газового оборудования?',
        answers: [
          'Не менее одного раза в полгода',
          'Не менее одного раза в год',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63668205',
        text:
          'Какие из перечисленных видов работ не должны выполняться при техническом обслуживании технологического оборудования ГРП и ГРУ? Выберите 2 варианта ответа.',
        answers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
          'Смазка подвижных элементов запорной арматуры (без разборки)',
          'Очистка помещения и технических устройств пунктов редуцирования газа от загрязнений (при необходимости)',
          'Устранение выявленных дефектов и неисправностей',
        ],
        correctAnswers: [
          'Разборка подвижных элементов запорной арматуры',
          'Восстановление окраски шкафов пунктов редуцирования газа',
        ],
      },
      {
        code: '63668206',
        text:
          'Кто утверждает инструкцию по перекачке сероуглерода из транспортной емкости в емкость хранения?',
        answers: [
          'Руководитель предприятия',
          'Главный технолог предприятия',
          'Главный инженер предприятия',
        ],
        correctAnswers: ['Главный инженер предприятия'],
      },
      {
        code: '63668207',
        text:
          'Какое количество выходов должен иметь поддоменник, не считая выхода на литейный двор?',
        answers: [
          'Не менее чем три выхода',
          'Только один выход',
          'Не менее чем два выхода',
          'Не более чем два выхода',
        ],
        correctAnswers: ['Не менее чем два выхода'],
      },
      {
        code: '63668208',
        text:
          'Какое требование установлено к устройству выходных отверстий продувочных свечей на доменных печах?',
        answers: [
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
          'Должно быть выше верхних площадок колошника не менее чем на 2 м',
          'Должно быть выше верхних площадок колошника на 3 м',
        ],
        correctAnswers: [
          'Должно быть выше верхних площадок колошника не менее чем на 4 м',
        ],
      },
      {
        code: '63668209',
        text:
          'Какое минимальное остаточное давление должны иметь баллоны, подготовленные для наполнения водородом?',
        answers: [
          'Не менее 0,05 МПа',
          'Не менее 0,04 МПа',
          'Не менее 0,03 МПа',
          'Не менее 0,02 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63668210',
        text:
          'Как часто должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных вне помещений?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в шесть месяцев',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63668211',
        text:
          'Какое из перечисленных действий допускается при эксплуатации оборудования для газопламенной обработки металлов? Выберите правильный вариант ответа.',
        answers: [
          'Нагревать баллоны со сжиженным газом прямыми солнечными лучами или другими источниками тепла сверх температуры, указанной производителем',
          'Отбирать газ из баллона при снижении в нем рабочего давления ниже требуемого при питании передвижного рабочего поста сжиженным газом от баллона',
          'Продувать рукав для горючих газов кислородом и кислородного рукава горючим газом, а также взаимозамена рукавов при работе',
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
        correctAnswers: [
          'Направлять пламя горелки (резака) в сторону, противоположную источнику газоснабжения, при работе',
        ],
      },
      {
        code: '63668212',
        text:
          'Какие шпалы должны применяться на железнодорожных путях шлакового отвала?',
        answers: [
          'Деревянные шпалы, пропитанные креозотом',
          'Обрезные деревянные шпалы',
          'Огнестойкие шпалы',
        ],
        correctAnswers: ['Огнестойкие шпалы'],
      },
      {
        code: '63668213',
        text:
          'С какой периодичностью должны производиться замеры аэродинамического сопротивления насадки воздухонагревателей?',
        answers: ['Ежеквартально', 'Ежегодно', 'Ежемесячно'],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63668214',
        text:
          'Какой должна быть объемная доля кислорода в воздухе производственных помещений производства ПРВ?',
        answers: [
          'Не менее 19% и не более 23%',
          'Не менее 16% и не более 23%',
          'Не менее 21% и не более 25%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63668215',
        text:
          'Какие требования к производственным помещениям, оборудованию участков приготовления травильных растворов и травления металла указаны неверно?',
        answers: [
          'После работы у ванн травления с химически опасными веществами, используемыми в виде добавок для технологических растворов, вещества должны быть удалены из рабочей зоны на склад',
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
          'Периодичность проведения уборки помещений устанавливается в зависимости от условий их эксплуатации. Не допускается уборка, вызывающая распыление вредных веществ',
        ],
        correctAnswers: [
          'Вентиляционные системы, состояние ванн травления еженедельно должны проверяться на работоспособность',
        ],
      },
      {
        code: '63668216',
        text:
          'Каков предельно допустимый износ цапф конвертера во всех измерениях в случае применения подшипников скольжения?',
        answers: [
          'Не должен превышать 10% их первоначальных размеров',
          'Не должен превышать 12% их первоначальных размеров',
          'Не должен превышать 15% их первоначальных размеров',
        ],
        correctAnswers: ['Не должен превышать 10% их первоначальных размеров'],
      },
      {
        code: '63668217',
        text:
          'Из каких материалов должны быть выполнены здания литейных дворов и поддоменников?',
        answers: [
          'Из цементно-стружечных плит',
          'Из любых строительных материалов',
          'Из огнестойких материалов',
        ],
        correctAnswers: ['Из огнестойких материалов'],
      },
      {
        code: '63668218',
        text:
          'Какое из перечисленных требований при эксплуатации взрывопожароопасных объектов указано неверно?',
        answers: [
          'В помещениях категории A проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией должна производиться в сроки, предусмотренные заводской инструкцией, но не реже 1 раза в месяц',
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
          'На каждой двери помещения категории A должны быть указаны категория помещения и класс взрывоопасной зоны',
          'Работы по ремонту электрооборудования в помещениях категории A должны выполняться после обесточивания электросети, используя, при необходимости, переносные аккумуляторные взрывобезопасные светильники',
        ],
        correctAnswers: [
          'В помещениях категории A хранение обтирочных и смазочных материалов не допускается',
        ],
      },
      {
        code: '63668219',
        text:
          'Какие требования установлены к хранению стержней, шаров, футеровки, запасных деталей и приспособлений?',
        answers: [
          'Должно быть организовано в специальном помещении',
          'Должно производиться в любом месте на площадках, обеспечивающих свободный проход к ним',
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
        correctAnswers: [
          'Должно быть предусмотрено в специально выделенных местах на стеллажах или в таре',
        ],
      },
      {
        code: '63668220',
        text:
          'При каком содержании газов должна срабатывать световая и звуковая сигнализации, установленные с автоматическими газоанализаторами в помещениях категории А, где обращается водород?',
        answers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 20% нижнего предела взрываемости (0,8% об.), кислорода менее 25% и более 34%, угарного газа более 25 мг/м³, метана не более 50% нижнего предела взрываемости',
          'При содержании водорода в воздухе помещения не более 25% нижнего предела взрываемости (0,85% об.), кислорода менее 35% и более 40%, угарного газа более 29 мг/м³, метана не более 5% нижнего предела взрываемости',
        ],
        correctAnswers: [
          'При содержании водорода в воздухе помещения не более 10% нижнего предела взрываемости (0,4% об.), кислорода менее 19% и более 23%, угарного газа более 20 мг/м³, метана не более 10% нижнего предела взрываемости',
        ],
      },
      {
        code: '63668221',
        text:
          'От чего должны быть защищены составные части производственного оборудования, в том числе энергетические трубопроводы, рукава подачи природного газа, кислорода, мазута, воздуха, масла, воды, троллеи для питания электрического оборудования тележек, перевозящих расплавы металлов, и электрические кабели?',
        answers: [
          'Только от теплового воздействия',
          'Только от возможности попадания расплава',
          'Только от механических воздействий',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63668222',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в 5 лет', 'Один раз в 3 года', 'Один раз в год'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63668223',
        text:
          'Каким составом должны выполняться газоопасные работы I и II группы газоопасности с образованием взрывопожароопасных смесей?',
        answers: [
          'Не менее трех человек под наблюдением члена добровольной газоспасательной дружины (ДГСД)',
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
          'Один работник под наблюдением газоспасателя',
          'Не менее пяти работников',
        ],
        correctAnswers: [
          'Не менее двух человек под наблюдением газоспасателя или члена ДГСД',
        ],
      },
      {
        code: '63668224',
        text: 'Какие требования к пневматическим КИПиА указаны неверно?',
        answers: [
          'Для пневматических КИПиА должны предусматриваться специальные установки сжатого воздуха',
          'Для пневматических КИПиА должны предусматриваться отдельные сети сжатого воздуха',
          'Воздухопроводы для КИПиА должны иметь буферные емкости, обеспечивающие запас сжатого воздуха для работы приборов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63668225',
        text:
          'С кем согласовывается инструкция, определяющая порядок подготовки и безопасного проведения газоопасных работ на опасных производственных объектах горно-металлургической промышленности? Выберите два правильных ответа.',
        answers: [
          'С территориальным органом Ростехнадзора',
          'С маркшейдерской службой',
          'С ГСС',
          'Со службой производственного контроля',
        ],
        correctAnswers: ['С ГСС', 'Со службой производственного контроля'],
      },
      {
        code: '63668226',
        text:
          'Что необходимо устанавливать в производственных помещениях, где возможны воспламенение одежды или химические ожоги у работников?',
        answers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
          'Емкости с песком, огнетушители',
          'Брезентовые покрывала',
        ],
        correctAnswers: [
          'Фонтанчики питьевой воды, краны, раковины, ванны самопомощи или аварийные души',
        ],
      },
      {
        code: '63668227',
        text:
          'Какому классу должна соответствовать герметичность затвора запорной арматуры со средой водорода?',
        answers: ['Классу A', 'Классу В', 'Классу С', 'Классу D'],
        correctAnswers: ['Классу A'],
      },
      {
        code: '63668228',
        text: 'Чем должна производиться продувка импульсных газовых проводок?',
        answers: [
          'Только паром',
          'Только сжатым воздухом',
          'Только газом',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63668229',
        text:
          'Стальными листами какой толщины должны отделяться от рабочей зоны баллоны с водородом при их горизонтальном расположении и размещении внутри помещения?',
        answers: [
          'Не менее 16 мм',
          'Не менее 12 мм',
          'Не менее 10 мм',
          'Не менее 8 мм',
        ],
        correctAnswers: ['Не менее 16 мм'],
      },
      {
        code: '63668230',
        text:
          'На что должна быть проверена система пневмотранспорта перед вводом в эксплуатацию?',
        answers: [
          'На прочность движимых частей при проведении внешнего осмотра',
          'На герметичность путем проведения гидравлических испытаний',
          'На плотность под рабочим давлением',
        ],
        correctAnswers: ['На плотность под рабочим давлением'],
      },
      {
        code: '63668231',
        text:
          'Какие требования к помещению операторной указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Полы в операторной должны быть диэлектрическими',
          'Для слежения за работой оборудования, находящегося вне зоны видимости, предусматривается технологическая сигнализация и устройство видеонаблюдения',
          'Помещения операторной должны быть оборудованы сигнализацией для оповещения персонала о появлении отклонений от технологического процесса',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63668232',
        text:
          'Как часто должна производиться проверка настройки отсечных и предохранительных клапанов?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца, а также после каждого текущего ремонта',
          'Не реже одного раза в два месяца, а также после каждой ревизии и ремонта',
        ],
        correctAnswers: [
          'Не реже одного раза в два месяца, а также после каждой ревизии и ремонта',
        ],
      },
      {
        code: '63668233',
        text:
          'На каком расстоянии от бровки отвала должна располагаться ось пути для шлака на отвале?',
        answers: [
          'Не менее 1,2 м',
          'Не менее 1,4 м',
          'Не менее 1,3 м',
          'Не менее 1,1 м',
        ],
        correctAnswers: ['Не менее 1,4 м'],
      },
      {
        code: '63668234',
        text:
          'Какая объемная доля водорода в техническом аргоне, поступающем на очистку в ВРУ?',
        answers: [
          'Не должна превышать 3,0%',
          'Не должна превышать 4,0%',
          'Не должна превышать 2,5%',
        ],
        correctAnswers: ['Не должна превышать 2,5%'],
      },
      {
        code: '63668235',
        text:
          'До какой температуры должны быть охлаждены системы оборотного водоснабжения установок придоменной грануляции шлака для производства работ по их ремонту?',
        answers: [
          'Не выше 40 °C',
          'Не выше 42 °C',
          'Не выше 43 °C',
          'Не выше 45 °C',
        ],
        correctAnswers: ['Не выше 40 °C'],
      },
      {
        code: '63668236',
        text: 'Чем должны быть оснащены доменные печи?',
        answers: [
          'Автоматизированной системой контроля, управления и диагностики технологического процесса',
          'Системой дистанционного управления',
          'Системой диагностики производственного процесса',
        ],
        correctAnswers: [
          'Автоматизированной системой контроля, управления и диагностики технологического процесса',
        ],
      },
      {
        code: '63668237',
        text:
          'Что должно быть предусмотрено при проектировании конвейеров для подачи материалов? Выберите правильный вариант ответа.',
        answers: [
          'Должно быть предусмотрено только исключение падения транспортируемых материалов',
          'Должно быть предусмотрены только предохраняющие устройства, отключающие приводы при перегрузке',
          'Должно быть предусмотрены только ограждения приводных, натяжных и отклоняющих барабанов, натяжных устройств, канатов и блоков натяжных устройств, ременных, червячных и муфтовых передач',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63668238',
        text:
          'Какое должно быть содержание кислорода в воздухе помещения наполнения баллонов?',
        answers: [
          'При объемной доле кислорода в воздухе не менее 19% и не более 23%',
          'При объемной доле кислорода в воздухе не менее 18% и не более 24%',
          'При объемной доле кислорода в воздухе не менее 18% и не более 23%',
        ],
        correctAnswers: [
          'При объемной доле кислорода в воздухе не менее 19% и не более 23%',
        ],
      },
      {
        code: '63668239',
        text: 'Какие требования установлены к укладке слитков?',
        answers: [
          'Укладка должна производиться в специальные закрома складских помещений',
          'Укладка должна производиться на выделенных площадках конвертерных цехов',
          'Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков',
        ],
        correctAnswers: [
          'Укладка должна производиться на специальные стеллажи, исключающие раскатывание слитков',
        ],
      },
      {
        code: '63668240',
        text:
          'Через какое время после ввода объекта в эксплуатацию должна проводиться первая выборочная ревизия водородопроводов на вновь осваиваемых производствах?',
        answers: [
          'Не позднее чем через 4 года',
          'Не позднее чем через 5 лет',
          'Не позднее чем через 7 лет',
          'Не позднее чем через 10 лет',
        ],
        correctAnswers: ['Не позднее чем через 4 года'],
      },
      {
        code: '63668241',
        text:
          'Какие системы должны быть проверены в обязательном порядке перед началом пуска установок получения водорода? Выберите правильный вариант ответа.',
        answers: [
          'Только теплоснабжение, водоснабжение и электроснабжение',
          'Только воздухоснабжение',
          'Только системы охлаждения и снабжения инертными газами, системы отопления и вентиляции',
          'Все перечисленные системы',
        ],
        correctAnswers: ['Все перечисленные системы'],
      },
      {
        code: '63668242',
        text:
          'После чего должны производиться работы по ремонту пылевыпускного клапана?',
        answers: [
          'После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности',
          'После перекрытия запорной арматуры',
          'После остановки работы печи',
        ],
        correctAnswers: [
          'После установки листовой заглушки над пылевыпускным клапаном с соблюдением мер безопасности',
        ],
      },
      {
        code: '63668243',
        text: 'В каких случаях не допускается завалка лома в конвертер?',
        answers: [
          'При наличии в нем жидкого шлака',
          'При наличии в нем остатков жидкого металла',
          'При наличии в нем отделяемой шлаковой корки',
        ],
        correctAnswers: ['При наличии в нем жидкого шлака'],
      },
      {
        code: '63668244',
        text:
          'Какие требования предъявляются к корзинам, загружаемым в щелочные ванны?',
        answers: [
          'Должны быть не влажные',
          'Должны быть изготовлены только из нержавеющей стали',
          'Должны быть оборудованы съемными подвесными приспособлениями',
        ],
        correctAnswers: ['Должны быть не влажные'],
      },
      {
        code: '63668245',
        text:
          'Каким газом должны продуваться перед пуском и после остановки установки по производству водорода?',
        answers: ['Азотом', 'Кислородом', 'Ксеноном', 'Аргоном'],
        correctAnswers: ['Азотом'],
      },
      {
        code: '63668246',
        text:
          'При каком содержании водорода в воздухе производственного помещения по сигналу от автоматического газоанализатора технологическое оборудование этого помещения должно быть остановлено?',
        answers: [
          'Выше 10% нижнего предела взрываемости (0,4% об.)',
          'Выше 15% высшего предела взрываемости (0,6% об.)',
          'Выше 20% высшего предела взрываемости (0,8% об.)',
          'Выше 25% нижнего предела взрываемости (1% об.)',
        ],
        correctAnswers: ['Выше 25% нижнего предела взрываемости (1% об.)'],
      },
      {
        code: '63668247',
        text:
          'Как часто должна производиться нивелировка действующих газопроводов?',
        answers: ['Один раз в 5 лет', 'Один раз в 3 года', 'Один раз в год'],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63668248',
        text: 'В каких местах допускается прокладка водородопроводов?',
        answers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
          'Через производственные помещения, не связанные с потреблением водорода',
          'По стенам зданий на участках со сплошным остеклением',
          'По легкосбрасываемым ограждающим конструкциям',
        ],
        correctAnswers: [
          'Прокладка водородопроводов во всех перечисленных местах не допускается',
        ],
      },
      {
        code: '63668249',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для вновь сооружаемых газопроводов?',
        answers: [
          'Не менее 4 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
          'Не менее 48 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63668250',
        text:
          'До какой минимальной температуры должна быть охлаждена печь "Аусмелт" для возобновления подачи охлаждающей жидкости при перегреве печи и неисправности водоохлаждающих элементов?',
        answers: ['100 °C', '150 °C', '200 °C', '120 °C'],
        correctAnswers: ['150 °C'],
      },
      {
        code: '63668251',
        text:
          'У кого должен находиться агрегатный (ремонтный) журнал на отдельных объектах газового цеха, а также в цехах, в составе которых имеются объекты газового хозяйства?',
        answers: [
          'У работника, ответственного за производственный контроль',
          'У начальника цеха',
          'У работника, ответственного за безопасное производство работ данного объекта газового хозяйства',
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
        correctAnswers: [
          'У работника, ответственного за техническое состояние данного объекта газового хозяйства',
        ],
      },
      {
        code: '63668252',
        text:
          'Какие требования безопасности при электролитическом производстве и рафинировании алюминия указаны неверно?',
        answers: [
          'Система электроизоляции в корпусах электролиза должна исключать наличие потенциала "земля" в ремонтных зонах напольных рельсовых машин',
          'Перекрытия проемов реконструируемых электролизеров должны быть электроизолированы от кожухов соседних электролизеров',
          'Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не более 500 кОм',
          'Сопротивление изоляции элементов напольных рельсовых машин должно быть не менее величин, указанных в проекте, разработанном для данного производства',
        ],
        correctAnswers: [
          'Рельсы напольных машин для обслуживания электролизеров должны иметь электроизоляционные вставки между соседними в ряду электролизерами. Сопротивление изоляции вставок должно быть не более 500 кОм',
        ],
      },
      {
        code: '63668253',
        text:
          'На каком расстоянии от кантуемых ковшей должен размещаться пульт управления на шлаковом отвале?',
        answers: [
          'На расстоянии не менее 5 м',
          'На расстоянии не менее 8 м',
          'На расстоянии не менее 10 м',
        ],
        correctAnswers: ['На расстоянии не менее 10 м'],
      },
      {
        code: '63668254',
        text:
          'В какие сроки в помещениях категории А должна осуществляться проверка плотности фланцевых и резьбовых соединений газопроводов, арматуры и приборов мыльной эмульсией?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63668255',
        text:
          'Какое действие должно быть осуществлено в первую очередь при зажигании ручной горелки или резака?',
        answers: [
          'Должен быть немного приоткрыт вентиль кислорода',
          'Должен быть открыт вентиль горючего газа',
          'Должна быть сделана кратковременная продувка рукава для удаления воздуха',
          'Должна быть зажжена горючая смесь газов',
        ],
        correctAnswers: ['Должен быть немного приоткрыт вентиль кислорода'],
      },
      {
        code: '63668256',
        text:
          'Какое из перечисленных требований при эксплуатации газового оборудования газопотребляющих агрегатов указано верно?',
        answers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
          'Продувка газопровода и коллектора агрегата должна производиться при открытых задвижках на горелках',
          'Отключающее устройство на газопроводе перед горелкой разрешается открывать до поднесения к горелке запальника, факела или другого средства, воспламеняющего газ',
          'Подачу газа в котлах без поднесения запальника, факела или другого воспламеняющего средства допускается производить, если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
        correctAnswers: [
          'Для агрегатов, оборудованных инжекционными горелками, установка быстродействующих отсекающих клапанов не требуется, на них должны устанавливаться сигнализаторы падения давления газа',
        ],
      },
      {
        code: '63668257',
        text:
          'С кем согласовывается план организации и проведения газоопасной работы?',
        answers: [
          'Только с начальником газоспасательной службы',
          'Только с ответственным представителем подразделения или организации (главным энергетиком)',
          'Только со службой производственного контроля',
          'Со всеми перечисленными',
          'Только с представителем пожарной охраны (при ведении огневых работ)',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '63668258',
        text:
          'Какому гидравлическому испытанию должны подвергаться водоохлаждаемые элементы печи "Аусмелт" перед их установкой?',
        answers: [
          'На 1,15 величины рабочего давления охлаждающей жидкости',
          'На 1,25 величины рабочего давления охлаждающей жидкости',
          'На 1,5 величины рабочего давления охлаждающей жидкости',
          'На 1,6 величины рабочего давления охлаждающей жидкости',
        ],
        correctAnswers: [
          'На 1,5 величины рабочего давления охлаждающей жидкости',
        ],
      },
      {
        code: '63668259',
        text:
          'Какие требования установлены к инструментам, приспособлениям и оснастке, используемым для ведения технологии или обслуживания оборудования?',
        answers: [
          'Должны быть предназначены для выполнения любых работ',
          'Должны быть изготовлены из ударопрочных материалов',
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
        correctAnswers: [
          'Должны применяться по назначению и соответствовать требованиям безопасности при работе с ними',
        ],
      },
      {
        code: '63668260',
        text:
          'Какое количество баллонов с эталонными и поверочными газовыми смесями разрешается хранить в помещении хроматографии?',
        answers: ['Не более двух', 'Не более трех', 'Не более четырех'],
        correctAnswers: ['Не более двух'],
      },
      {
        code: '63668261',
        text:
          'Как часто должна производиться проверка состояния механизма поворота конвертера?',
        answers: [
          'Два раза в смену',
          'Ежесменно',
          'Постоянно, перед каждой плавкой',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63668262',
        text:
          'Где должен находиться работник при ручной подправке металла на ножницах?',
        answers: ['Спереди ножниц', 'Сзади ножниц', 'Сбоку от ножниц'],
        correctAnswers: ['Сбоку от ножниц'],
      },
      {
        code: '63668263',
        text: 'Каков допустимый износ цапф ковшей во всех измерениях?',
        answers: [
          'Не должен превышать 15% первоначальных размеров',
          'Не должен превышать 12% первоначальных размеров',
          'Не должен превышать 10% первоначальных размеров',
        ],
        correctAnswers: ['Не должен превышать 10% первоначальных размеров'],
      },
      {
        code: '63668264',
        text:
          'Как часто цапфы ковшей должны проверяться методом неразрушающего контроля?',
        answers: [
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в месяц',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63668265',
        text:
          'При каких условиях допускается перенос сроков проведения капитальных ремонтов основного технологического оборудования?',
        answers: [
          'При наличии производственной необходимости по решению руководителя предприятия',
          'При наличии в паспорте оборудования отметки о продлении сроков эксплуатации',
          'При наличии положительного заключения экспертного обследования',
        ],
        correctAnswers: [
          'При наличии положительного заключения экспертного обследования',
        ],
      },
      {
        code: '63668266',
        text:
          'Какие требования установлены к системам освещения во взрывоопасных помещениях?',
        answers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
          'Должны предусматриваться комбинированные системы освещения, работающие от напряжения 42 В',
          'Должно предусматриваться общее локализованное освещение напряжением не выше 220 В',
        ],
        correctAnswers: [
          'Должны предусматриваться системы освещения во взрывобезопасном исполнении',
        ],
      },
      {
        code: '63668267',
        text:
          'Что не допускается к загрузке в агрегаты, содержащие расплавленный металл или шлак?',
        answers: [
          'Загрузка пакетированного металлолома',
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
          'Загрузка шихты, не прошедшей пиротехнического контроля',
        ],
        correctAnswers: [
          'Загрузка шихты и материалов с количеством влаги выше, чем предусмотрено в технологической инструкции',
        ],
      },
      {
        code: '63668268',
        text:
          'Что должно быть предусмотрено автоматизированной системой управления процессом при получении водорода методом диссоциации аммиака? Выберите правильный вариант ответа.',
        answers: [
          'Должно быть предусмотрено только светозвуковая сигнализация при достижении предельной концентрации водорода под кровлей помещения диссоциаторов и компрессорной водорода',
          'Должно быть предусмотрено только регулирование теплового режима диссоциаторов',
          'Должно быть предусмотрено только сигнализация понижения или повышения температуры диссоциации',
          'Должно быть предусмотрено только обеспечение равномерной подачи аммиака к нижнему диссоциатору',
          'Должно быть предусмотрено только регулирование давления в коллекторе диссоциированного аммиак',
          'Должно быть предусмотрено только регулирование давления диссоциированного аммиака после компрессора',
          'Должно быть предусмотрено все перечисленное',
        ],
        correctAnswers: ['Должно быть предусмотрено все перечисленное'],
      },
      {
        code: '63668269',
        text:
          'Чем должен быть покрыт пол пульта управления электронно-лучевой печью?',
        answers: [
          'Теплоизоляционным покрытием',
          'Электроизолирующим материалом, на который должно быть нанесено клеймо испытания',
          'Нескользящим материалом',
        ],
        correctAnswers: [
          'Электроизолирующим материалом, на который должно быть нанесено клеймо испытания',
        ],
      },
      {
        code: '63668270',
        text:
          'Что из перечисленного должны включать технические и организационные меры по обеспечению промышленной безопасности металлургических производств при проектировании объектов металлургии? Выберите правильный вариант ответа.',
        answers: [
          'Должны включать только механизацию или автоматизацию управления технологическим оборудованием',
          'Должны включать только испытание систем на соответствие их проектным показателям',
          'Должны включать только контроль состояния металла и сварных соединений оборудования и трубопроводов',
          'Должны включать только проверку метрологических характеристик измерительных каналов на соответствие проектным требованиям',
          'Должны включать все перечисленное',
        ],
        correctAnswers: ['Должны включать все перечисленное'],
      },
      {
        code: '63668271',
        text:
          'Какие требования установлены к управлению стопорами и шиберными затворами ковшей?',
        answers: [
          'Управление должно быть автоматизированным',
          'Управление должно быть дистанционным',
          'Управление должно быть синхронным',
        ],
        correctAnswers: ['Управление должно быть дистанционным'],
      },
      {
        code: '63668272',
        text: 'Чем должны быть ограждены грануляционные бассейны?',
        answers: [
          'Сплошным ограждением высотой не менее 1 м',
          'Перилами высотой не менее 1,1 м',
          'Металлическими решетчатыми ограждениями высотой не менее 0,9 м',
        ],
        correctAnswers: ['Перилами высотой не менее 1,1 м'],
      },
      {
        code: '63668273',
        text:
          'Какое из перечисленных требований к воздухонагревателям в доменном производстве указано неверно?',
        answers: [
          'Ширина кольцевой щели между кожухом и площадками обслуживания, а также между вертикальным газопроводом, пересекающим площадку, и площадкой должна быть не менее 50 мм',
          'Засорение и забивка щелей не допускаются',
          'Площадки со стороны щели должны ограждаться сплошной отбортовкой на высоту не менее 0,5 м',
          'Ширина кольцевой щели между кожухом воздухонагревателя и его рабочей площадкой должна быть определена проектом',
        ],
        correctAnswers: [
          'Площадки со стороны щели должны ограждаться сплошной отбортовкой на высоту не менее 0,5 м',
        ],
      },
      {
        code: '63668274',
        text: 'Что должно иметься на шлаковом отвале?',
        answers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
          'Приемопередающий радиоузел коротковолновой связи',
          'Световая сигнализирующая связь рабочих с аварийной службой',
        ],
        correctAnswers: [
          'Телефонная или радиосвязь с диспетчерской службой производства',
        ],
      },
      {
        code: '63668275',
        text:
          'Какая должна быть температура в помещениях в холодное время года там, где осуществляются мокрые производственные процессы?',
        answers: ['Не ниже 20 °C', 'Не ниже 16 °C', 'Не ниже 18 °C'],
        correctAnswers: ['Не ниже 16 °C'],
      },
      {
        code: '63668276',
        text: 'В какие сроки должен проводиться капитальный ремонт ГРП и ГРУ?',
        answers: [
          'В сроки, установленные изготовителем',
          'В сроки, установленные эксплуатирующей организацией',
          'Через каждые 3 года после ввода в эксплуатацию',
          'Через каждые 5 лет после ввода в эксплуатацию',
        ],
        correctAnswers: ['В сроки, установленные изготовителем'],
      },
      {
        code: '63668277',
        text:
          'Что должно быть разработано для обеспечения безопасности при освоении новых производств, технологических процессов и технических устройств?',
        answers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
          'Инструкции по эксплуатации технических устройств',
          'Инструкция по проведению пусконаладочных работ',
        ],
        correctAnswers: [
          'Временные инструкции, содержащие меры, направленные на безопасное ведение технологических процессов и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63668278',
        text: 'Как и кем должны проверяться состояние и исправность миксера?',
        answers: [
          'Должны проверяться миксеровым перед каждым запуском работы миксера',
          'Должны проверяться два раза в смену исполнителем работ',
          'Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале',
        ],
        correctAnswers: [
          'Должны ежесменно проверяться миксеровым с записью результатов осмотра в журнале',
        ],
      },
      {
        code: '63668279',
        text:
          'Какое должно быть давление во всасывающих коммуникациях компрессоров, сжимающих ПРВ?',
        answers: [
          'Должно быть не ниже 0,2 кПа (20 мм вод. ст.)',
          'Должно быть не ниже 0,4 кПа (40 мм вод. ст.)',
          'Должно быть не ниже 0,5 кПа (50 мм вод. ст.)',
        ],
        correctAnswers: ['Должно быть не ниже 0,5 кПа (50 мм вод. ст.)'],
      },
      {
        code: '63668280',
        text:
          'Какие из перечисленных требований при ведении работ на объектах производства водорода указаны неверно?',
        answers: [
          'Работники на объектах производства водорода должны быть обеспечены СИЗ, спецодеждой, спецобувью и другими средствами, которые должны отвечать требованиям соответствующих стандартов безопасности труда',
          'Открытые движущиеся части машин и механизмов, а также ременные, цепные и зубчатые передачи должны быть снабжены ограждениями, исключающими опасность травмирования людей этими частями и попадания в них посторонних предметов',
          'Узлы, детали, приспособления и элементы оборудования, которые могут служить источником опасности для работающих, а также поверхности оградительных и защитных устройств должны быть окрашены в сигнальные цвета',
          'Входить на объекты с производством и обращением водорода необходимо в обуви с железными набойками, а также в одежде, способной проводить заряды статического электричества',
        ],
        correctAnswers: [
          'Входить на объекты с производством и обращением водорода необходимо в обуви с железными набойками, а также в одежде, способной проводить заряды статического электричества',
        ],
      },
      {
        code: '63668281',
        text:
          'Чему должны соответствовать параметры процесса эксплуатации и ремонта оборудования?',
        answers: [
          'Должны соответствовать только требованиям установленного технологического регламента',
          'Должны соответствовать только рекомендациям завода-изготовителя',
          'Должны соответствовать только паспортным данным оборудования',
          'Всему перечисленному',
        ],
        correctAnswers: ['Всему перечисленному'],
      },
      {
        code: '63668282',
        text:
          'С какой периодичностью должен производиться осмотр газопроводов, газовых установок и газового оборудования комиссией на производстве?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в три года',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63668283',
        text:
          'С какой периодичностью должен производиться текущий ремонт оборудования с разборкой регуляторов давления, предохранительных клапанов и фильтров ГРП и ГРУ, если в паспортах заводов-изготовителей на это оборудование не установлены другие сроки обслуживания?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в два года',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее одного раза в год'],
      },
      {
        code: '63668284',
        text:
          'На каких минимальных расстояниях (по горизонтали) до отдельных баллонов с кислородом и горючими газами допускаются работы по газовой резке, сварке и другим видам газопламенной обработки металлов, а также применение открытого огня от других источников?',
        answers: ['5 м', '10 м', '3 м', '1,5 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63668285',
        text:
          'В каком случае допускается подача газа без поднесения запальника, факела или другого воспламеняющего средства к горелке газопровода?',
        answers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 500 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 600 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 700 °C',
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
        correctAnswers: [
          'Если температура кладки в разогретой зоне розжига факела превышает 800 °C',
        ],
      },
      {
        code: '63668286',
        text:
          'При каких условиях должны производиться все работы по подготовке плавки?',
        answers: [
          'Только при запуске печи на начальном этапе',
          'Только при достижении оптимальной температуры печи',
          'Только при отключенной печи (установке)',
        ],
        correctAnswers: ['Только при отключенной печи (установке)'],
      },
      {
        code: '63668287',
        text:
          'При какой скорости падения давления за время дополнительного пневматического испытания на герметичность межцеховых газопроводов (для трубопроводов внутренним диаметром до 250 мм включительно) результаты признаются удовлетворительными?',
        answers: [
          'Не более 0,1% в час',
          'Не более 0,2% в час',
          'Не более 0,3% в час',
        ],
        correctAnswers: ['Не более 0,2% в час'],
      },
      {
        code: '63668288',
        text:
          'Какие работы производятся на ОПО горно-металлургических производств? Выберите правильный вариант ответа.',
        answers: [
          'Производятся только постоянные работы, выполняемые в соответствии с документированной процедурой (инструкцией, картой, регламентом), принятой в организации',
          'Производятся только работы повышенной опасности (сложные, разовые, уникальные)',
          'Производятся только работы, связанные с аварийными ситуациями',
          'Производятся все перечисленные виды работ',
        ],
        correctAnswers: ['Производятся все перечисленные виды работ'],
      },
      {
        code: '63668289',
        text:
          'Каково предельно допустимое содержание масла в кислороде, поступающем в компрессор?',
        answers: [
          'Не должно превышать 0,02 мг/м³',
          'Не должно превышать 0,03 мг/м³',
          'Не должно превышать 0,05 мг/м³',
        ],
        correctAnswers: ['Не должно превышать 0,02 мг/м³'],
      },
      {
        code: '63668290',
        text: 'Какие требования установлены к оснащению колошниковой площадки?',
        answers: [
          'Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами',
          'Должна быть освещена в вечернее и ночное время, иметь аварийное освещение и съемное ограждение по периметру',
          'Должна быть освещена в темное время суток и иметь решетчатые ограждения высотой не менее 1 м',
        ],
        correctAnswers: [
          'Должна быть освещена в вечернее и ночное время и ограждена перилами высотой не менее 1,1 м со сплошной зашивкой стальными листами',
        ],
      },
      {
        code: '63668291',
        text:
          'В каких местах осуществляется дозировка шихтовых материалов и смешивание их с алюминиевым порошком и селитрой во вновь строящихся и реконструируемых металлотермических цехах?',
        answers: [
          'Непосредственно возле плавильной печи',
          'В помещениях, где производится дробление и размол материалов',
          'В отдельных помещениях',
          'В помещениях хранения сыпучих материалов',
        ],
        correctAnswers: ['В отдельных помещениях'],
      },
      {
        code: '63668292',
        text:
          'Как должен быть подготовлен газоотводящий тракт конвертера к ремонту?',
        answers: [
          'Должен быть продут сжатым воздухом',
          'Должен быть надежно отключен от общих коллекторов и боровов',
          'Должен быть разгерметизирован',
        ],
        correctAnswers: [
          'Должен быть надежно отключен от общих коллекторов и боровов',
        ],
      },
      {
        code: '63668293',
        text:
          'С учетом какой минимальной скорости движения воздуха проектируются открытые проемы укрытия потенциально опасного оборудования?',
        answers: ['1,5 м/с', '2 м/с', '1 м/с', '0,5 м/с'],
        correctAnswers: ['1,5 м/с'],
      },
      {
        code: '63668294',
        text:
          'Какое испытательное давление должно приниматься для участка газопровода чистого газа от дроссельной группы до листовой задвижки, где P2 - расчетное избыточное давление (по проекту) газовой среды на участке, МПа?',
        answers: [
          '0,5Р2, но не менее 0,25 МПа',
          '1,5P2, но не менее 0,1 МПа',
          '1,25P2, но не менее 0,05 МПа',
        ],
        correctAnswers: ['1,25P2, но не менее 0,05 МПа'],
      },
      {
        code: '63668295',
        text:
          'С каким минимальным количеством независимых источников электроснабжения должно быть выполнено электропитание оборудования производства водорода?',
        answers: ['С одним', 'С двумя', 'С тремя', 'С четырьмя'],
        correctAnswers: ['С двумя'],
      },
      {
        code: '63668296',
        text:
          'С какой периодичностью должны производиться проверка технического состояния промышленных дымоотводящих устройств (газоходов газоиспользующего оборудования, дымоходов и дымовых труб) и их прочистка?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63668297',
        text:
          'В каких случаях не допускается отключение системы охлаждения кристаллизатора?',
        answers: [
          'До выгрузки слитка из камеры',
          'До охлаждения камеры до температуры 45 °С',
          'До окончания процесса разгрузки камеры',
        ],
        correctAnswers: ['До выгрузки слитка из камеры'],
      },
      {
        code: '63668298',
        text:
          'С какой периодичностью должна проверяться плотность присоединения импульсных труб и резинотканевых рукавов к штуцерам газопроводов и контрольно-измерительных приборов, расположенных в помещениях, и вне помещений?',
        answers: [
          'Не реже одного раза в неделю и не реже одного раза в полгода соответственно',
          'Не реже одного раза в неделю и не реже одного раза в квартал соответственно',
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
        correctAnswers: [
          'Не реже одного раза в неделю и не реже одного раза в месяц соответственно',
        ],
      },
      {
        code: '63668299',
        text:
          'Кто осуществляет выбор типа быстродействующего отсекающего клапана на подводе газа к каждому агрегату с дутьевыми горелками?',
        answers: [
          'Организация, эксплуатирующая систему автоматики печи',
          'Специализированная организация',
          'Организация, проектирующая систему автоматики печи',
        ],
        correctAnswers: ['Организация, проектирующая систему автоматики печи'],
      },
      {
        code: '63668300',
        text:
          'Какое из перечисленных требований при производстве глинозема указано неверно?',
        answers: [
          'Приводной механизм вращения печей спекания и кальцинации оборудуется резервным двигателем с независимым источником питания',
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
          'Шуровка материала в печах производится при разрежении в печи, исключающем выбросы в рабочую зону',
          'При заливке, перемешивании расплава и удалении шлама напряжение с электродов на миксерах, печах и хлораторах должно быть снято',
        ],
        correctAnswers: [
          'Допускается открывание люков на топках и газораспределительных камерах во время работы печей "кипящего слоя" в присутствии наблюдающего',
        ],
      },
      {
        code: '63668301',
        text: 'В какой зоне не допускается нахождение людей?',
        answers: [
          'В зоне погрузки грейферными или магнитными кранами',
          'На расстоянии 15 метров от зоны работы магнитного крана в производственном помещении',
          'На расстоянии менее 20 метров от зоны работы грейферного крана',
          'Во всех перечисленных зонах',
        ],
        correctAnswers: ['В зоне погрузки грейферными или магнитными кранами'],
      },
      {
        code: '63668302',
        text:
          'При наличии каких дефектов ковшей их эксплуатация не допускается?',
        answers: [
          'Только при потере формы вследствие деформации',
          'Только при наличии раковин, трещин в стенках и в местах крепления цапф',
          'Только при повреждении футеровки',
          'При наличии хотя бы одного из перечисленных',
        ],
        correctAnswers: ['При наличии хотя бы одного из перечисленных'],
      },
      {
        code: '63668303',
        text:
          'В каком случае все технологическое оборудование должно продуваться инертным газом? Выберите 2 варианта ответа.',
        answers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
          'Перед пуском, если оно в период остановки находилось под избыточным давлением водорода',
          'После остановки более чем на 1 час',
        ],
        correctAnswers: [
          'После остановки более чем на 2 часа, если оно в период остановки не находилось под избыточным давлением водорода',
          'Перед пуском',
        ],
      },
      {
        code: '63668304',
        text:
          'При каких условиях осуществляется пользование механизмами передвижения ковшей?',
        answers: [
          'Только после проверки изношенности механизмов',
          'Только при исправной звуковой и световой сигнализации',
          'Только при наличии сигнализации с автозапуском',
        ],
        correctAnswers: [
          'Только при исправной звуковой и световой сигнализации',
        ],
      },
      {
        code: '63668305',
        text:
          'Как часто должны производиться проверки приборов измерения уровня засыпи печи?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже двух раз в месяц',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже двух раз в месяц'],
      },
      {
        code: '63668306',
        text:
          'Что должно быть исключено в коммуникациях системы транспортирования взрывопожароопасных веществ и материалов?',
        answers: [
          'Попадание воздушной среды',
          'Возможность разгерметизации системы',
          'Образование взрывоопасной среды',
        ],
        correctAnswers: ['Образование взрывоопасной среды'],
      },
      {
        code: '63668307',
        text: 'Как часто должен производиться осмотр трубопроводов кислорода?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63668308',
        text:
          'В течение какого времени должны подаваться звуковые и световые сигналы перед пуском в работу технического устройства, узлы которого или все устройство перемещаются в процессе работы?',
        answers: [
          'Не менее 20 с',
          'Не менее 5 с',
          'Не менее 30 с',
          'Не менее 10 с',
        ],
        correctAnswers: ['Не менее 10 с'],
      },
      {
        code: '63668309',
        text:
          'С какой периодичностью на предприятиях проводится ревизия газопроводов при скорости коррозии более 0,5 мм/г?',
        answers: [
          'Один раз в два года',
          'Один раз в год',
          'Один раз в полугодие',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63668310',
        text:
          'Какой запас прочности должны иметь цапфы ковшей, предназначенных для расплава металла?',
        answers: [
          'Не менее восьмикратного запаса прочности',
          'Не менее шестикратного запаса прочности',
          'Не менее четырехкратного запаса прочности',
        ],
        correctAnswers: ['Не менее восьмикратного запаса прочности'],
      },
      {
        code: '63668311',
        text:
          'Чем допускается тушить пламя при возгорании водорода? Выберите два варианта ответа.',
        answers: ['Водой', 'Огнетушителями', 'Песком', 'Кошмой'],
        correctAnswers: ['Песком', 'Кошмой'],
      },
      {
        code: '63668312',
        text:
          'С какой периодичностью должна производиться режимная наладка газоиспользующего оборудования?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63668313',
        text:
          'Какая допускается максимальная температура газа в газопроводах в случае подачи в них пара для пропарки, продувки и отогрева?',
        answers: ['100 °С', '90 °С', '85 °С'],
        correctAnswers: ['85 °С'],
      },
      {
        code: '63668314',
        text:
          'Какие требования к монтажу и эксплуатации водородопроводов указаны неверно?',
        answers: [
          'Не допускается применять во взрывопожароопасных технологических системах гибкие шланги (резиновые, пластмассовые) в качестве стационарных трубопроводов для транспортирования водорода, веществ в парогазовом состоянии',
          'Все перечисленные требования указаны верно',
          'Прокладка водородопроводов должна обеспечивать наименьшую протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Конструкция уплотнения, материал прокладок и монтаж фланцевых соединений должны обеспечивать необходимую степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической системы',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63668315',
        text:
          'Какие требования для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода указаны неверно?',
        answers: [
          'Для предотвращения взрывоопасных концентраций под кровлей помещения диссоциаторов и компрессорной водорода должны быть установлены датчики довзрывных концентраций',
          'Количество и места расположения датчиков довзрывных концентраций должны определяться проектом',
          'Светозвуковая сигнализация должна иметь вывод на пульт оператора и в помещение пожарного депо предприятия',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63668316',
        text:
          'Какие требования предъявляются к предохранительным решеткам бункера?',
        answers: [
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм',
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 400 х 400 мм',
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 200 х 300 мм',
        ],
        correctAnswers: [
          'Должны быть оборудованы предохранительными решетками с ячейками размером не более 300 х 300 мм',
        ],
      },
      {
        code: '63668317',
        text:
          'При каком давлении должна производиться настройка срабатывания отсекающих клапанов КРП?',
        answers: [
          'При давлении на 15% более расчетного и температуре 100 °С',
          'При давлении на 20% более расчетного и температуре 100 °С',
          'При давлении на 25% более расчетного и температуре 110 °С',
        ],
        correctAnswers: [
          'При давлении на 15% более расчетного и температуре 100 °С',
        ],
      },
    ],
  },
  0: {
    63627: [
      {
        code: '63627000',
        text:
          'Кто осуществляет лицензирование эксплуатации взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности?',
        answers: [
          'Федеральная служба по экологическому, технологическому и атомному надзору',
          'Федеральное агентство по техническому регулированию и метрологии',
          'Федеральная служба по аккредитации',
          'Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: [
          'Федеральная служба по экологическому, технологическому и атомному надзору',
        ],
      },
      {
        code: '63627001',
        text:
          'В какой форме осуществляется обязательная оценка соответствия зданий и сооружений, а также связанных со зданиями и с сооружениями процессов эксплуатации?',
        answers: [
          'В форме производственного контроля (надзора)',
          'В форме государственного строительного надзора, а также государственного контроля',
          'В форме эксплуатационного и государственного контроля (надзора)',
        ],
        correctAnswers: [
          'В форме эксплуатационного и государственного контроля (надзора)',
        ],
      },
      {
        code: '63627002',
        text:
          'Какие опасные производственные объекты не относятся к особо опасным и технически сложным объектам?',
        answers: [
          'Опасные производственные объекты I и II классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества',
          'Опасные производственные объекты, на которых получаются, транспортируются, используются расплавы черных и цветных металлов, сплавы на основе этих расплавов с применением оборудования, рассчитанного на максимальное количество расплава 500 килограммов и более',
          'Опасные производственные объекты, на которых используются стационарно установленные грузоподъемные механизмы',
          'Опасные производственные объекты, на которых ведутся горные работы (за исключением добычи общераспространенных полезных ископаемых и разработки россыпных месторождений полезных ископаемых, осуществляемых открытым способом без применения взрывных работ), работы по обогащению полезных ископаемых',
        ],
        correctAnswers: [
          'Опасные производственные объекты, на которых используются стационарно установленные грузоподъемные механизмы',
        ],
      },
      {
        code: '63627003',
        text:
          'Какими документами могут приниматься технические регламенты в соответствии с Федеральным законом от 27.12.2002 № 184-ФЗ "О техническом регулировании"?',
        answers: [
          'Только федеральными законами и межправительственными соглашениями стран - участниц Евразийского союза',
          'Только федеральными законами и постановлениями Правительства Российской Федерации',
          'Любыми нормативными правовыми актами Российской Федерации',
          'Международными договорами Российской Федерации, подлежащими ратификации в порядке, установленном законодательством Российской Федерации, или в соответствии с международными договорами Российской Федерации, ратифицированными в порядке, установленном законодательством Российской Федерации, или указами Президента Российской Федерации, или постановлениями Правительства Российской Федерации, или нормативным правовым актом федерального органа исполнительной власти по техническому регулированию',
        ],
        correctAnswers: [
          'Международными договорами Российской Федерации, подлежащими ратификации в порядке, установленном законодательством Российской Федерации, или в соответствии с международными договорами Российской Федерации, ратифицированными в порядке, установленном законодательством Российской Федерации, или указами Президента Российской Федерации, или постановлениями Правительства Российской Федерации, или нормативным правовым актом федерального органа исполнительной власти по техническому регулированию',
        ],
      },
      {
        code: '63627004',
        text:
          'Каким документом установлен перечень сведений, содержащихся в декларации промышленной безопасности, и порядок ее оформления?',
        answers: [
          'Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'Правилами, утвержденными постановлением Правительства Российской Федерации',
          'Документом, утвержденным федеральным органом исполнительной власти в области промышленной безопасности',
          'Положением, утвержденным совместным приказом Ростехнадзора и МЧС России',
        ],
        correctAnswers: [
          'Документом, утвержденным федеральным органом исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63627005',
        text:
          'Кто устанавливает требования к форме предоставления сведений об организации производственного контроля за соблюдением требований промышленной безопасности?',
        answers: [
          'Правительство Российской Федерации',
          'Ростехнадзор',
          'Федеральная служба по труду и занятости',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Ростехнадзор'],
      },
      {
        code: '63627006',
        text:
          'На какие классы опасности, в зависимости от уровня потенциальной опасности аварий на них для жизненно важных интересов личности и общества, подразделяются опасные производственные объекты?',
        answers: [
          'I класс - опасные производственные объекты чрезвычайно высокой опасности;  II класс - опасные производственные объекты высокой опасности;  III класс - опасные производственные объекты средней опасности;  IV класс - опасные производственные объекты низкой опасности',
          'I класс - опасные производственные объекты низкой опасности; II класс - опасные производственные объекты средней опасности;  III класс - опасные производственные объекты высокой опасности;  IV класс - опасные производственные объекты чрезвычайно высокой опасности',
          'I класс - опасные производственные объекты высокой опасности; II класс - опасные производственные объекты средней опасности;  III класс - опасные производственные объекты низкой опасности;  IV класс - неопасные производственные объекты (вероятность аварии равна нулю)',
        ],
        correctAnswers: [
          'I класс - опасные производственные объекты чрезвычайно высокой опасности;  II класс - опасные производственные объекты высокой опасности;  III класс - опасные производственные объекты средней опасности;  IV класс - опасные производственные объекты низкой опасности',
        ],
      },
      {
        code: '63627007',
        text:
          'Куда организация, эксплуатирующая опасный производственный объект, представляет информацию об организации производственного контроля за соблюдением требований промышленной безопасности?',
        answers: [
          'В МЧС России',
          'В Ростехнадзор или его территориальные органы',
          'В вышестоящую организацию или ведомства',
          'В центральный аппарат Ростехнадзора',
        ],
        correctAnswers: ['В Ростехнадзор или его территориальные органы'],
      },
      {
        code: '63627008',
        text:
          'Что из перечисленного не подлежит экспертизе промышленной безопасности?',
        answers: [
          'Документация на техническое перевооружение, консервацию и ликвидацию опасного производственного объекта',
          'Технические устройства, применяемые на опасном производственном объекте',
          'Здания и сооружения на опасном производственном объекте, предназначенные для технологических процессов, хранения сырья или продукции, перемещения людей и грузов, локализации и ликвидации последствий аварий',
          'Обоснование безопасности опасного производственного объекта, а также изменения, вносимые в обоснование безопасности опасного производственного объекта',
          'Декларация промышленной безопасности, разрабатываемая в составе документации на техническое перевооружение (в случае, если указанная документация входит в состав проектной документации опасного производственного объекта, подлежащей экспертизе в соответствии с законодательством о градостроительной деятельности)',
        ],
        correctAnswers: [
          'Декларация промышленной безопасности, разрабатываемая в составе документации на техническое перевооружение (в случае, если указанная документация входит в состав проектной документации опасного производственного объекта, подлежащей экспертизе в соответствии с законодательством о градостроительной деятельности)',
        ],
      },
      {
        code: '63627009',
        text:
          'Кто устанавливает требования к документационному обеспечению систем управления промышленной безопасностью?',
        answers: [
          'Ростехнадзор',
          'Правительство Российской Федерации',
          'Федеральное агентство по техническому регулированию и метрологии',
          'Федеральная служба по аккредитации',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '63627010',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63627011',
        text:
          'Уполномочены ли иные федеральные органы исполнительной власти помимо Федеральной службы по экологическому, технологическому и атомному надзору осуществлять специальные разрешительные, контрольные или надзорные функции в области промышленной безопасности?',
        answers: [
          'Да, если Президентом Российской Федерации или Правительством Российской Федерации им предоставлено такое право',
          'Нет, это противоречит Федеральному закону от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'Да, только в случае, если указанные органы функционируют в условиях чрезвычайной ситуации',
        ],
        correctAnswers: [
          'Да, если Президентом Российской Федерации или Правительством Российской Федерации им предоставлено такое право',
        ],
      },
      {
        code: '63627012',
        text:
          'Каков размер страховой выплаты за вред, причиненный здоровью каждого потерпевшего в результате аварии на опасном производственном объекте?',
        answers: [
          'Не более 500 тысяч рублей',
          'Не более 360 тысяч рублей',
          'Не более 3 миллионов рублей',
          'Не более 200 тысяч рублей',
        ],
        correctAnswers: ['Не более 3 миллионов рублей'],
      },
      {
        code: '63627013',
        text:
          'Какие формы обязательного подтверждения соответствия установлены Федеральным законом от 27.12.2002 № 184-ФЗ "О техническом регулировании"?',
        answers: [
          'Экспертиза промышленной безопасности',
          'Только обязательная сертификация продукции',
          'Обязательная сертификация или декларирование соответствия продукции',
          'Оценка риска применения продукции',
        ],
        correctAnswers: [
          'Обязательная сертификация или декларирование соответствия продукции',
        ],
      },
      {
        code: '63627014',
        text:
          'Кто должен принять меры, предупреждающие причинение вреда населению и окружающей среде, при прекращении эксплуатации здания или сооружения согласно Техническому регламенту о безопасности зданий и сооружений?',
        answers: [
          'Представитель территориального органа Ростехнадзора',
          'Организация, эксплуатирующая здание или сооружение',
          'Собственник здания или сооружения',
          'Организация, проводившая экспертизу промышленной безопасности',
        ],
        correctAnswers: ['Собственник здания или сооружения'],
      },
      {
        code: '63627015',
        text:
          'Что понимается под требованиями промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Условия, запреты, ограничения, а также другие обязательные требования, содержащиеся в федеральных законах, соблюдение которых обеспечивает промышленную безопасность',
          'Требования, содержащиеся в нормативных технических документах, принимаемых федеральным органом исполнительной власти, специально уполномоченным в области промышленной безопасности, в рамках его компетенции',
          'Условия, запреты, ограничения и другие обязательные требования, содержащиеся в Федеральном законе от 21.07.1997 № 116-ФЗ, других федеральных законах и принимаемых в соответствии с ними нормативных правовых актах Президента Российской Федерации, нормативных правовых актах Правительства Российской Федерации, а также федеральных нормах и правилах в области промышленной безопасности',
          'Условия, запреты, ограничения, установленные в нормативных актах, соблюдение которых обеспечивает состояние защищенности жизненно важных интересов личности и общества от аварий на опасных производственных объектах, а также последствий указанных аварий',
        ],
        correctAnswers: [
          'Условия, запреты, ограничения и другие обязательные требования, содержащиеся в Федеральном законе от 21.07.1997 № 116-ФЗ, других федеральных законах и принимаемых в соответствии с ними нормативных правовых актах Президента Российской Федерации, нормативных правовых актах Правительства Российской Федерации, а также федеральных нормах и правилах в области промышленной безопасности',
        ],
      },
      {
        code: '63627016',
        text:
          'Какая из перечисленных задач не относится к задачам производственного контроля за соблюдением требований промышленной безопасности на опасном производственном объекте?',
        answers: [
          'Анализ состояния промышленной безопасности опасных производственных объектов',
          'Координация работ, направленных на предупреждение аварий на опасных производственных объектах',
          'Контроль за своевременным проведением необходимых испытаний и технических освидетельствований технических устройств, применяемых на опасных производственных объектах, ремонта и поверки контрольных средств измерений',
          'Декларирование соответствия условий труда государственным нормативным требованиям охраны труда',
        ],
        correctAnswers: [
          'Декларирование соответствия условий труда государственным нормативным требованиям охраны труда',
        ],
      },
      {
        code: '63627017',
        text:
          'Кто проводит государственную экспертизу проектной документации особо опасных и технически сложных объектов?',
        answers: [
          'Организация, имеющая лицензию Министерства строительства и жилищно-коммунального хозяйства Российской Федерации',
          'Федеральный орган исполнительной власти, осуществляющий функции по выработке и реализации государственной политики и нормативно-правовому регулированию в сфере строительства, архитектуры, градостроительства или подведомственное ему государственное (бюджетное или автономное) учреждение',
          'Организация, имеющая лицензию Ростехнадзора или Федеральной службы по надзору в сфере природопользования на проведение данного вида экспертизы',
          'Независимые эксперты',
          'Органы государственной власти субъектов Российской Федерации',
        ],
        correctAnswers: [
          'Федеральный орган исполнительной власти, осуществляющий функции по выработке и реализации государственной политики и нормативно-правовому регулированию в сфере строительства, архитектуры, градостроительства или подведомственное ему государственное (бюджетное или автономное) учреждение',
        ],
      },
      {
        code: '63627018',
        text:
          'Разработка каких документов в рамках организации документационного обеспечения систем управления промышленной безопасностью не предусмотрена в нормативном правовом акте?',
        answers: [
          'Плана мероприятий по снижению риска аварий на опасных производственных объектах на срок более 1 календарного года',
          'Плана мероприятий по обеспечению промышленной безопасности на календарный год',
          'Плана работ по модернизации опасного производственного объекта',
          'Разработка всех перечисленных документов обязательна',
        ],
        correctAnswers: [
          'Плана работ по модернизации опасного производственного объекта',
        ],
      },
      {
        code: '63627019',
        text:
          'Какие сведения отражаются в заключении экспертизы промышленной безопасности по результатам экспертизы технического устройства?',
        answers: [
          'Расчетные и аналитические процедуры оценки и прогнозирования технического состояния объекта экспертизы, включающие определение остаточного ресурса (срока службы)',
          'Выводы о правильности и достоверности выполненных расчетов по анализу риска, а также полноты учета факторов, влияющих на конечные результаты',
          'Обоснованность применяемых физико-математических моделей и использованных методов расчета последствий аварии и показателей риска',
          'Выводы о достаточности мер предотвращения проникновения на опасный производственный объект посторонних лиц',
        ],
        correctAnswers: [
          'Расчетные и аналитические процедуры оценки и прогнозирования технического состояния объекта экспертизы, включающие определение остаточного ресурса (срока службы)',
        ],
      },
      {
        code: '63627020',
        text:
          'В отношении каких из перечисленных объектов капитального строительства государственная экспертиза проектов не проводится?',
        answers: [
          'Объектов, строительство, реконструкцию и (или) капитальный ремонт которых предполагается осуществлять на территориях двух и более субъектов Российской Федерации',
          'Объектов капитального строительства, в отношении которых не требуется получение разрешения на строительство',
          'Особо опасных, технически сложных и уникальных объектов',
          'Объектов, строительство, реконструкцию и (или) капитальный ремонт которых предполагается осуществлять в исключительной экономической зоне Российской Федерации, на континентальном шельфе Российской Федерации, во внутренних морских водах и в территориальном море Российской Федерации',
        ],
        correctAnswers: [
          'Объектов капитального строительства, в отношении которых не требуется получение разрешения на строительство',
        ],
      },
      {
        code: '63627021',
        text:
          'В каком из перечисленных случаев требования промышленной безопасности к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта (ОПО) могут быть установлены в обосновании безопасности опасного производственного объекта?',
        answers: [
          'В случае, если при проектировании, строительстве, эксплуатации, реконструкции, капитальном ремонте, консервации или ликвидации опасного производственного объекта требуется отступление от требований промышленной безопасности, установленных федеральными нормами и правилами в области промышленной безопасности, таких требований недостаточно и (или) они не установлены',
          'При подготовке проектной документации на любой опасный объект независимо от класса опасности',
          'В случае, если разработчиком проектной документации является иностранная организация',
          'При разработке плана по локализации и ликвидации последствий аварий на опасных объектах',
        ],
        correctAnswers: [
          'В случае, если при проектировании, строительстве, эксплуатации, реконструкции, капитальном ремонте, консервации или ликвидации опасного производственного объекта требуется отступление от требований промышленной безопасности, установленных федеральными нормами и правилами в области промышленной безопасности, таких требований недостаточно и (или) они не установлены',
        ],
      },
      {
        code: '63627022',
        text:
          'Кто является страхователями гражданской ответственности за причинение вреда в результате аварии на опасном производственном объекте?',
        answers: [
          'Юридические лица или физические лица, заключившие со страховщиками договоры страхования',
          'Владельцы опасных производственных объектов (юридические лица или индивидуальные предприниматели), заключившие договор обязательного страхования гражданской ответственности за причинение вреда потерпевшим в результате аварии на опасном объекте',
          'Владельцы опасных производственных объектов, за исключением индивидуальных предпринимателей, заключившие договор страхования гражданской ответственности за причинение вреда потерпевшим в результате аварии на опасном объекте',
        ],
        correctAnswers: [
          'Владельцы опасных производственных объектов (юридические лица или индивидуальные предприниматели), заключившие договор обязательного страхования гражданской ответственности за причинение вреда потерпевшим в результате аварии на опасном объекте',
        ],
      },
      {
        code: '63627023',
        text:
          'Какая организация осуществляет авторский надзор в процессе капитального ремонта или технического перевооружения опасного производственного объекта?',
        answers: [
          'Организация, эксплуатирующая опасный объект',
          'Организация, разработавшая соответствующую документацию в порядке, установленном сводом правил "Положение об авторском надзоре за строительством зданий и сооружений"',
          'Территориальный орган Ростехнадзора',
          'Орган местного самоуправления, на территории которого расположен опасный объект',
        ],
        correctAnswers: [
          'Организация, разработавшая соответствующую документацию в порядке, установленном сводом правил "Положение об авторском надзоре за строительством зданий и сооружений"',
        ],
      },
      {
        code: '63627024',
        text:
          'В каких документах устанавливаются формы оценки соответствия обязательным требованиям к техническим устройствам, применяемым на опасном производственном объекте?',
        answers: [
          'В федеральных нормах и правилах в области промышленной безопасности',
          'В технических регламентах',
          'В соответствующих нормативных правовых актах, утверждаемых Правительством Российской Федерации',
          'В Федеральном законе от 21.07.1997№116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: ['В технических регламентах'],
      },
      {
        code: '63627025',
        text:
          'В какой срок со дня поступления требования страхователя об изменении условий договора обязательного страхования в связи с уменьшением страхового риска, включая уменьшение размера страховой премии, страховщик обязан рассмотреть такое требование?',
        answers: [
          '20 рабочих дней',
          '30 рабочих дней',
          '5 рабочих дней',
          '10 рабочих дней',
        ],
        correctAnswers: ['30 рабочих дней'],
      },
      {
        code: '63627026',
        text:
          'Кем осуществляется контроль за соблюдением лицензиатом лицензионных требований?',
        answers: [
          'Лицензирующим органом совместно с органом прокуратуры',
          'Органом исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется опасный объект',
          'Лицензирующим органом',
          'Органом местного самоуправления',
        ],
        correctAnswers: ['Лицензирующим органом'],
      },
      {
        code: '63627027',
        text:
          'Кем устанавливается перечень сведений, содержащихся в декларации и информационном листе (приложении к декларации), и порядок их оформления?',
        answers: [
          'Органами местного самоуправления по месту нахождения ОПО',
          'Правительством Российской Федерации',
          'Организацией, эксплуатирующей ОПО',
          'Федеральной службой по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Федеральной службой по экологическому, технологическому и атомному надзору',
        ],
      },
      {
        code: '63627028',
        text:
          'Какие требования устанавливает Технический регламент Таможенного союза "О безопасности машин и оборудования"?',
        answers: [
          'Обеспечение безопасности эксплуатации машин и оборудования',
          'Обеспечение на единой таможенной территории Таможенного союза обязательных для применения и исполнения минимально необходимых требований к машинам и оборудованию',
          'Условия свободного перемещения машин и оборудования, выпускаемого в обращение на единой таможенной территории',
        ],
        correctAnswers: [
          'Обеспечение на единой таможенной территории Таможенного союза обязательных для применения и исполнения минимально необходимых требований к машинам и оборудованию',
        ],
      },
      {
        code: '63627029',
        text:
          'Какие виды классификаций оборудования для работы во взрывоопасных средах не устанавливает ТР "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Классификация взрывоопасных зон',
          'Классификация оборудования по группам (в зависимости от области применения)',
          'Классификация оборудования по уровням и видам взрывозащиты',
          'Классификация оборудования по температурным классам',
          'Классификация оборудования по давлению',
        ],
        correctAnswers: ['Классификация оборудования по давлению'],
      },
      {
        code: '63627030',
        text:
          'В целях решения каких задач разработаны Правила представления декларации промышленной безопасности опасных производственных объектов?',
        answers: [
          'Только в целях обеспечения деятельности в области охраны окружающей среды и защиты населения и территорий от чрезвычайных ситуаций',
          'Только в целях повышения эффективности взаимодействия органов государственной власти, органов местного самоуправления и общественных объединений по проблемам обеспечения промышленной безопасности опасных производственных объектов',
          'Только в целях создания условий для организации и осуществления государственного надзора в области промышленной безопасности',
          'Всех перечисленных задач',
        ],
        correctAnswers: ['Всех перечисленных задач'],
      },
      {
        code: '63627031',
        text:
          'Как производится ввод в эксплуатацию опасного производственного объекта?',
        answers: [
          'В порядке, установленном законодательством Российской Федерации о промышленной безопасности',
          'В порядке, установленном законодательством Российской Федерации о градостроительной деятельности',
          'В порядке, установленном законодательством Российской Федерации о техническом регулировании',
        ],
        correctAnswers: [
          'В порядке, установленном законодательством Российской Федерации о градостроительной деятельности',
        ],
      },
      {
        code: '63627032',
        text:
          'Какой срок проведения экспертизы промышленной безопасности установлен Федеральными нормами и правилами "Правила проведения экспертизы промышленной безопасности"?',
        answers: [
          'Срок проведения экспертизы не должен превышать 6 месяцев',
          'Срок проведения экспертизы определяется сложностью объекта экспертизы, но не должен превышать трех месяцев со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов',
          'Срок проведения экспертизы определяется сложностью объекта экспертизы и согласовывается с заказчиком экспертизы',
        ],
        correctAnswers: [
          'Срок проведения экспертизы определяется сложностью объекта экспертизы, но не должен превышать трех месяцев со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов',
        ],
      },
      {
        code: '63627033',
        text:
          'В какие федеральные органы исполнительной власти заявитель, предполагающий выполнение работ (оказание услуг) при эксплуатации взрывопожароопасных и химически опасных производственных объектов IV класса опасности, должен представлять уведомления о начале осуществления своей деятельности?',
        answers: [
          'В Федеральное агентство по техническому регулированию и метрологии',
          'В Федеральную службу по надзору в сфере здравоохранения',
          'В Федеральную службу по экологическому, технологическому и атомному надзору',
          'В Федеральную службу по труду и занятости',
          'В Федеральное медико-биологическое агентство',
          'В Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: [
          'В Федеральную службу по экологическому, технологическому и атомному надзору',
        ],
      },
      {
        code: '63627034',
        text:
          'Каким нормативным документом устанавливается обязательность проведения подготовки и аттестации работников, эксплуатирующих опасные производственные объекты, в области промышленной безопасности?',
        answers: [
          'Постановлением Правительства Российской Федерации от 25.10.2019 № 1365 "О подготовке и аттестации в области промышленной безопасности, по вопросам безопасности гидротехнических сооружений, безопасности в сфере электроэнергетики"',
          'Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'Трудовым кодексом Российской Федерации',
        ],
        correctAnswers: [
          'Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63627035',
        text:
          'На какие организации распространяются нормы Федерального закона от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'На все организации независимо от их организационно-правовых форм и форм собственности, осуществляющие деятельность в области промышленной безопасности опасных производственных объектов на территории Российской Федерации и на иных территориях, над которыми Российская Федерация осуществляет юрисдикцию в соответствии с законодательством Российской Федерации и нормами международного права',
          'На все организации независимо от их организационно-правовых форм и форм собственности, осуществляющие деятельность в области промышленной безопасности опасных производственных объектов только на территории Российской Федерации',
          'На государственные и негосударственные некоммерческие организации, эксплуатирующие опасные производственные объекты в порядке, установленном законодательством Российской Федерации',
          'На все коммерческие организации независимо от форм осуществления деятельности в области промышленной безопасности опасных производственных объектов',
        ],
        correctAnswers: [
          'На все организации независимо от их организационно-правовых форм и форм собственности, осуществляющие деятельность в области промышленной безопасности опасных производственных объектов на территории Российской Федерации и на иных территориях, над которыми Российская Федерация осуществляет юрисдикцию в соответствии с законодательством Российской Федерации и нормами международного права',
        ],
      },
      {
        code: '63627036',
        text:
          'Кем осуществляется контроль за исполнением владельцем опасного производственного объекта обязанности по обязательному страхованию гражданской ответственности за причинение вреда в результате аварии на опасном объекте?',
        answers: [
          'Ростехнадзором, осуществляющим в пределах своей компетенции функции по контролю и надзору в области безопасности соответствующих производственных объектов',
          'Фондом социального страхования Российской Федерации',
          'Национальным союзом страховщиков ответственности',
          'Страховой компанией',
        ],
        correctAnswers: [
          'Ростехнадзором, осуществляющим в пределах своей компетенции функции по контролю и надзору в области безопасности соответствующих производственных объектов',
        ],
      },
      {
        code: '63627037',
        text:
          'Кто имеет право проводить сертификацию технических устройств, применяемых на опасных производственных объектах?',
        answers: [
          'Орган по сертификации, аккредитованный в соответствии с законодательством Российской Федерации об аккредитации в национальной системе аккредитации',
          'Организации, аккредитованные федеральным органом исполнительной власти по стандартизации, метрологии и сертификации',
          'Организации, аккредитованные федеральным органом исполнительной власти в области промышленной безопасности, совместно с федеральным органом исполнительной власти по стандартизации, метрологии и сертификации',
        ],
        correctAnswers: [
          'Орган по сертификации, аккредитованный в соответствии с законодательством Российской Федерации об аккредитации в национальной системе аккредитации',
        ],
      },
      {
        code: '63627038',
        text:
          'В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?',
        answers: [
          'В недельный срок',
          'В десятидневный срок',
          'В месячный срок',
          'В двухнедельный срок',
        ],
        correctAnswers: ['В двухнедельный срок'],
      },
      {
        code: '63627039',
        text:
          'Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?',
        answers: [
          'Документом, утвержденным организацией, эксплуатирующей опасные производственные объекты, по согласованию с представительным органом работников данной организации',
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект, согласованным с органом исполнительной власти субъекта Российской Федерации, на территории которого находится опасный производственный объект',
        ],
        correctAnswers: [
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63627040',
        text:
          'С какой периодичностью организация, эксплуатирующая опасные производственные объекты, должна направлять информацию об инцидентах, происшедших на опасных производственных объектах, в территориальный орган Ростехнадзора?',
        answers: [
          'Ежегодно, независимо от того, были инциденты или нет',
          'Ежеквартально',
          'Информация об инцидентах не сообщается в Ростехназор и его территориальные органы',
          'Информация направляется 1 раз в 3 месяца при наличии инцидентов',
        ],
        correctAnswers: ['Ежеквартально'],
      },
      {
        code: '63627041',
        text:
          'Какой из перечисленных случаев не может являться основанием для исключения объекта из государственного реестра опасных производственных объектов?',
        answers: [
          'Ликвидация объекта или вывод его из эксплуатации',
          'Утрата объектом признаков опасности',
          'Грубое нарушение требований промышленной безопасности при эксплуатации опасного производственного объекта',
          'Изменение критериев отнесения объектов к категории опасных производственных объектов или требований к идентификации опасных производственных объектов',
        ],
        correctAnswers: [
          'Грубое нарушение требований промышленной безопасности при эксплуатации опасного производственного объекта',
        ],
      },
      {
        code: '63627042',
        text:
          'В каком виде допускается представлять сведения об организации производственного контроля за соблюдением требований промышленной безопасности в Ростехнадзор?',
        answers: [
          'В письменной форме либо в форме электронного документа, подписанного усиленной квалифицированной электронной подписью',
          'В виде электронного документа в формате .pdf',
          'Обязательно на бумажном носителе',
        ],
        correctAnswers: [
          'В письменной форме либо в форме электронного документа, подписанного усиленной квалифицированной электронной подписью',
        ],
      },
      {
        code: '63627043',
        text:
          'Что является результатом проведения экспертизы промышленной безопасности?',
        answers: [
          'Заключение экспертизы промышленной безопасности в письменной форме либо в форме электронного документа',
          'Сертификат соответствия объекта экспертизы',
          'Экспертная оценка объекта экспертизы, оформленная протоколом',
        ],
        correctAnswers: [
          'Заключение экспертизы промышленной безопасности в письменной форме либо в форме электронного документа',
        ],
      },
      {
        code: '63627044',
        text:
          'В каких случаях из перечисленных регистрирующим органом вносятся изменения в государственный реестр?',
        answers: [
          'Только при изменении адреса места нахождения опасного производственного объекта',
          'Только при изменении сведений, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации',
          'Только при изменении сведений об эксплуатирующей организации, собственнике опасного производственного объекта и/или сведений, указанных эксплуатирующей организацией в заявлении о регистрации опасного производственного объекта в государственном реестре',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63627045',
        text: 'В каких случаях лицензия подлежит переоформлению?',
        answers: [
          'В связи с окончанием срока действия',
          'В случаях изменения места жительства, имени, фамилии и (в случае, если имеется) отчества руководителя юридического лица',
          'В случаях реорганизации юридического лица в форме преобразования, изменения его наименования, адреса места нахождения',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'В случаях реорганизации юридического лица в форме преобразования, изменения его наименования, адреса места нахождения',
        ],
      },
      {
        code: '63627046',
        text:
          'В какой срок после внесения в реестр последней декларации промышленной безопасности для действующих опасных производственных объектов декларация должна быть разработана вновь?',
        answers: [
          'По истечении десяти лет',
          'По истечении пяти лет',
          'Повторно декларация не разрабатывается',
          'В десятидневный срок при смене владельца опасного объекта',
        ],
        correctAnswers: ['По истечении десяти лет'],
      },
      {
        code: '63627047',
        text:
          'Допускается ли подача сведений об организации производственного контроля на бумажном носителе с приложением электронных таблиц в формате .xls или .xlsx на машиночитаемом носителе?',
        answers: [
          'Да, с приложением электронных таблиц в случае наличия технической возможности',
          'Да, с обязательным приложением электронных таблиц',
          'Нет, не допускается',
        ],
        correctAnswers: [
          'Да, с приложением электронных таблиц в случае наличия технической возможности',
        ],
      },
      {
        code: '63627048',
        text:
          'Что из перечисленного относится к обязанностям организации в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Специальная оценка условий труда',
          'Обеспечение укомплектованности штата работников опасного производственного объекта',
          'Обязательное социальное страхование от несчастных случаев на производстве и профессиональных заболеваний физических лиц, выполняющих работу на основании гражданско-правового договора',
        ],
        correctAnswers: [
          'Обеспечение укомплектованности штата работников опасного производственного объекта',
        ],
      },
      {
        code: '63627049',
        text:
          'Взимается ли плата за предоставление или переоформление лицензии, если да, то в соответствии с каким законодательством?',
        answers: [
          'Да, в соответствии с законодательством Российской Федерации о налогах и сборах',
          'Да, в соответствии с законодательством Российской Федерации о лицензировании',
          'Нет',
        ],
        correctAnswers: [
          'Да, в соответствии с законодательством Российской Федерации о налогах и сборах',
        ],
      },
      {
        code: '63627050',
        text:
          'Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Характеристику объекта, в отношении которого разрабатывается план мероприятий',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63627051',
        text:
          'Какое количество экспертов в области промышленной безопасности должно быть в штате соискателя лицензии по проведению экспертизы промышленной безопасности?',
        answers: [
          'Не менее 3 экспертов в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", для которых работа в этой организации является основной',
          'Не менее 2 экспертов, аттестованных в областях аттестации, соответствующих заявляемым работам (услугам)',
          'Не менее 4 экспертов в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", для которых работа в этой организации является основной',
        ],
        correctAnswers: [
          'Не менее 3 экспертов в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", для которых работа в этой организации является основной',
        ],
      },
      {
        code: '63627052',
        text:
          'Кто является владельцем опасного объекта в терминологии Федерального закона от 27.07.2010 № 225-ФЗ "Об обязательном страховании гражданской ответственности владельцев опасных объектов за причинение вреда в результате аварии на опасном объекте"?',
        answers: [
          'Юридическое лицо, владеющее опасным производственным объектом на праве собственности',
          'Юридическое лицо или индивидуальный предприниматель, владеющие опасным объектом на праве собственности, праве хозяйственного ведения или праве оперативного управления либо на ином законном основании и осуществляющие эксплуатацию опасного объекта',
          'Юридические лица, владеющие опасным объектом на праве собственности, праве хозяйственного ведения или праве оперативного управления либо на ином законном основании, независимо от того, осуществляют они эксплуатацию опасного производственного объекта или нет',
        ],
        correctAnswers: [
          'Юридическое лицо или индивидуальный предприниматель, владеющие опасным объектом на праве собственности, праве хозяйственного ведения или праве оперативного управления либо на ином законном основании и осуществляющие эксплуатацию опасного объекта',
        ],
      },
      {
        code: '63627053',
        text:
          'Как называется один из видов деятельности в области промышленной безопасности, подлежащий лицензированию в соответствии с Федеральным законом от 04.05.2011 № 99-ФЗ "О лицензировании отдельных видов деятельности"?',
        answers: [
          'Эксплуатация взрывопожароопасных и химически опасных производственных объектов всех классов опасности',
          'Эксплуатация взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности',
          'Эксплуатация взрывопожароопасных производственных объектов',
          'Эксплуатация химически опасных производственных объектов',
        ],
        correctAnswers: [
          'Эксплуатация взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности',
        ],
      },
      {
        code: '63627054',
        text:
          'Кем представляется заключение экспертизы промышленной безопасности технических устройств в Ростехнадзор для внесения в реестр?',
        answers: [
          'Заказчиком экспертизы',
          'Лабораторией, проводившей неразрушающий контроль металла и сварных соединений',
          'Проектной организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Заказчиком экспертизы'],
      },
      {
        code: '63627055',
        text:
          'Какие из перечисленных требований не являются лицензионными требованиями к лицензиату при осуществлении им лицензируемой деятельности по проведению экспертизы промышленной безопасности?',
        answers: [
          'Наличие дипломов о высшем техническом образовании, соответствующих заявленным работам (услугам) как минимум у 3 специалистов, состоящих в штате организации и для которых работа в данной организации является основной',
          'Наличие в штате лицензиата как минимум 3 экспертов в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов", и для которых работа в этой организации является основной',
          'Проведение экспертизы в соответствии с работами (услугами), указанными в лицензии, экспертами в области промышленной безопасности, которые соответствуют требованиям, установленным Федеральным законом "О промышленной безопасности опасных производственных объектов"',
          'Проведение экспертизы и оформление ее результатов в соответствии с требованиями, установленными нормативными правовыми актами Российской Федерации в области промышленной безопасности',
        ],
        correctAnswers: [
          'Наличие дипломов о высшем техническом образовании, соответствующих заявленным работам (услугам) как минимум у 3 специалистов, состоящих в штате организации и для которых работа в данной организации является основной',
        ],
      },
      {
        code: '63627056',
        text:
          'Каким из указанных требованиям должен соответствовать эксперт второй категории в области промышленной безопасности? Укажите все правильные ответы.',
        answers: [
          'Иметь протокол об аттестации в области промышленной безопасности, выданный территориальной аттестационной комиссией',
          'Иметь стаж работы не менее 7 лет по специальности, соответствующей его области (областям) аттестации',
          'Иметь лицензию на деятельность по проведению экспертизы промышленной безопасности',
          'Иметь опыт проведения не менее 5 экспертиз',
          'Иметь высшее образование',
        ],
        correctAnswers: [
          'Иметь стаж работы не менее 7 лет по специальности, соответствующей его области (областям) аттестации',
          'Иметь высшее образование',
        ],
      },
      {
        code: '63627057',
        text:
          'В какой срок осуществляется внесение в государственный реестр изменений сведений, связанных с изменением адреса места нахождения опасного производственного объекта?',
        answers: [
          'В срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений',
          'В срок, не превышающий 20 (двадцати) рабочих дней с даты регистрации заявления о внесении изменений',
          'В срок, не превышающий 30 (тридцати) рабочих дней со дня наступления указанных изменений',
          'В срок, не превышающий 5 (пяти) рабочих дней со дня наступления указанных изменений',
        ],
        correctAnswers: [
          'В срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений',
        ],
      },
      {
        code: '63627058',
        text:
          'Кто устанавливает требования к организации и осуществлению производственного контроля за соблюдением требований промышленной безопасности?',
        answers: [
          'Федеральный орган исполнительной власти в области промышленной безопасности совместно с Федеральным органом исполнительной власти в области гражданской обороны, защиты населения и территорий от чрезвычайных ситуаций природного и техногенного характера',
          'Правительство Российской Федерации',
          'Федеральный орган исполнительной власти в области промышленной безопасности',
          'Организация, эксплуатирующая опасный производственный объект',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '63627059',
        text:
          'Кто устанавливает порядок организации и проведения государственной экспертизы проектной документации и результатов инженерных изысканий?',
        answers: [
          'Минстрой России',
          'Правительство Российской Федерации',
          'Минстрой России совместно с Ростехнадзором',
          'Главгосэкспертиза',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '63627060',
        text:
          'Какой минимальный срок действия лицензии установлен Федеральным законом от 04.05.2011 № 99-ФЗ "О лицензировании отдельных видов деятельности"?',
        answers: ['1 год', '3 года', '5 лет', 'Лицензия действует бессрочно'],
        correctAnswers: ['Лицензия действует бессрочно'],
      },
      {
        code: '63627061',
        text: 'Кто утверждает декларацию промышленной безопасности?',
        answers: [
          'Руководитель эксплуатирующей организации совместно с территориальным органом Ростехнадзора',
          'Руководитель территориального органа федерального органа исполнительной власти в области промышленной безопасности или его заместители',
          'Руководитель организации, эксплуатирующей опасный производственный объект',
          'Руководитель экспертной организации, выполнившей экспертизу декларации промышленной безопасности',
        ],
        correctAnswers: [
          'Руководитель организации, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63627062',
        text:
          'Какие квалификационные требования предъявляются к работникам, ответственным за осуществление производственного контроля на опасных производственных объектах IV класса опасности?',
        answers: [
          'Высшее образование, общий стаж работы не менее 3 лет, аттестация в области промышленной безопасности',
          'Высшее или среднее техническое образование, стаж работы не менее 3 лет на соответствующем опасном производственном объекте отрасли, аттестация в области промышленной безопасности',
          'Высшее техническое образование, общий стаж работы не менее 3 лет, аттестация в области промышленной безопасности',
          'Высшее техническое образование и дополнительное профессиональное образование в области промышленной безопасности, стаж работы на опасном производственном объекте отрасли не менее 3 лет, аттестация в области промышленной безопасности',
        ],
        correctAnswers: [
          'Высшее техническое образование и дополнительное профессиональное образование в области промышленной безопасности, стаж работы на опасном производственном объекте отрасли не менее 3 лет, аттестация в области промышленной безопасности',
        ],
      },
      {
        code: '63627063',
        text:
          'Каким образом допускается представлять сведения об организации производственного контроля организацией, эксплуатирующей несколько опасных производственных объектов?',
        answers: [
          'Сведения могут представляться в виде единого файла или нескольких файлов',
          'Сведения следует предоставлять в виде отдельных файлов по каждому опасному производственному объекту',
          'Сведения следует предоставлять на бумажном носителе в виде общего отчета организации, эксплуатирующей опасные производственные объекты',
        ],
        correctAnswers: [
          'Сведения могут представляться в виде единого файла или нескольких файлов',
        ],
      },
      {
        code: '63627064',
        text:
          'Кто имеет право принимать решение о создании государственной комиссии по техническому расследованию причин аварии и назначать председателя указанной комиссии?',
        answers: [
          'Только Президент Российской Федерации',
          'Только Правительство Российской Федерации',
          'Президент Российской Федерации или Правительство Российской Федерации',
          'Президент Российской Федерации, Правительство Российской Федерации или руководитель федерального органа исполнительной власти в области промышленной безопасности',
        ],
        correctAnswers: [
          'Президент Российской Федерации или Правительство Российской Федерации',
        ],
      },
      {
        code: '63627065',
        text:
          'На кого возлагается финансирование расходов на техническое расследование причин аварий?',
        answers: [
          'На территориальный орган Ростехнадзора',
          'На организацию, эксплуатирующую опасные производственные объекты',
          'На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном производственном объекте',
          'На организацию, эксплуатирующую опасный производственный объект, или страховую компанию, в которой застрахована гражданская ответственность этой организации',
        ],
        correctAnswers: [
          'На организацию, эксплуатирующую опасные производственные объекты',
        ],
      },
      {
        code: '63627066',
        text:
          'Кто обязан представлять в Ростехнадзор сведения, необходимые для формирования и ведения государственного реестра опасных производственных объектов?',
        answers: [
          'Территориальные органы Ростехнадзора',
          'Территориальные органы МЧС России',
          'Юридические лица независимо от организационно-правовой формы и индивидуальные предприниматели, осуществляющие эксплуатацию опасных производственных объектов',
          'Федеральные государственные учреждения, эксплуатирующие опасные производственные объекты',
        ],
        correctAnswers: [
          'Юридические лица независимо от организационно-правовой формы и индивидуальные предприниматели, осуществляющие эксплуатацию опасных производственных объектов',
        ],
      },
      {
        code: '63627067',
        text:
          'Что из перечисленного не обязана выполнять организация в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Обеспечивать наличие и функционирование необходимых приборов и систем контроля за производственными процессами в соответствии с установленными требованиями',
          'Предотвращать проникновение на опасный производственный объект посторонних лиц',
          'Организовывать и осуществлять производственный контроль за соблюдением требований промышленной безопасности',
          'Создавать систему управления промышленной безопасностью и обеспечивать ее функционирование на опасных производственных объектах III класса опасности',
        ],
        correctAnswers: [
          'Создавать систему управления промышленной безопасностью и обеспечивать ее функционирование на опасных производственных объектах III класса опасности',
        ],
      },
      {
        code: '63627068',
        text:
          'Какая административная ответственность предусмотрена законодательством Российской Федерации за нарушение должностными лицами требований промышленной безопасности или лицензионных требований на осуществление видов деятельности в области промышленной безопасности?',
        answers: [
          'Вынесение письменного предупреждения, о чем делается соответствующая отметка в личном деле привлеченного к ответственности лица, или штраф в размере до одного минимального размера оплаты труда',
          'Административный арест на срок до 15 суток или административный штраф в размере до тридцати тысяч рублей',
          'Исправительные работы или административный штраф в размере до пятидесяти тысяч рублей',
          'Наложение административного штрафа в размере от двадцати до тридцати тысяч рублей или дисквалификация на срок от шести месяцев до одного года',
        ],
        correctAnswers: [
          'Наложение административного штрафа в размере от двадцати до тридцати тысяч рублей или дисквалификация на срок от шести месяцев до одного года',
        ],
      },
      {
        code: '63627069',
        text:
          'При каком обстоятельстве в случае досрочного прекращения действия договора обязательного страхования гражданской ответственности владельца опасного объекта страховая премия по договору не возвращается страхователю?',
        answers: [
          'Только по требованию страхователя',
          'Только при ликвидации страхователя - юридического лица или смерти страхователя - индивидуального предпринимателя, за исключением случаев, предусмотренных законодательством Российской Федерации',
          'Только по требованию страховщика в случае просрочки уплаты страховой премии (очередного страхового взноса) более чем на тридцать календарных дней',
          'Во всех перечисленных обстоятельствах',
        ],
        correctAnswers: ['Во всех перечисленных обстоятельствах'],
      },
      {
        code: '63627070',
        text:
          'Какие действия не вправе осуществлять страхователь при заключении договора обязательного страхования гражданской ответственности владельца опасного объекта, а также в период действия договора?',
        answers: [
          'Требовать от страховщика разъяснения условий обязательного страхования и проведения консультаций по заключению договора обязательного страхования',
          'В случае повреждения или утраты страхового полиса обязательного страхования в период его действия страхователю на основании письменного заявления страховщик за установленную плату может выдавать его дубликат',
          'В письменной форме запрашивать у Ростехнадзора и получать сведения о выполнении страхователем норм и правил эксплуатации опасного объекта, установленных в соответствии с законодательством Российской Федерации',
          'Проводить за свой счет экспертизу опасного объекта в целях оценки вреда, который может быть причинен в результате аварии на опасном объекте',
        ],
        correctAnswers: [
          'В случае повреждения или утраты страхового полиса обязательного страхования в период его действия страхователю на основании письменного заявления страховщик за установленную плату может выдавать его дубликат',
        ],
      },
      {
        code: '63627071',
        text:
          'Что обязан сделать лицензиат, если он планирует выполнять работы (оказывать услуги), составляющие лицензируемую деятельность, и не указанные в лицензии?',
        answers: [
          'Подать заявление в лицензирующий орган о переоформлении лицензии (о внесении изменений в реестр лицензий)',
          'Подать заявление в лицензирующий орган о выдаче новой лицензии',
          'Направить в лицензирующий орган уведомление о своих намерениях',
          'Направить в лицензирующий орган информацию о наличии экспертов, аттестованных в областях аттестации, соответствующих вновь заявляемым работам (услугам)',
        ],
        correctAnswers: [
          'Подать заявление в лицензирующий орган о переоформлении лицензии (о внесении изменений в реестр лицензий)',
        ],
      },
      {
        code: '63627072',
        text:
          'Что является идентификационным признаком оборудования для работы во взрывоопасных средах?',
        answers: [
          'Только наличие маркировки взрывозащиты',
          'Только наличие сертификата взрывозащиты, выданного аккредитованным органом по сертификации',
          'Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование',
        ],
        correctAnswers: [
          'Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование',
        ],
      },
      {
        code: '63627073',
        text:
          'В каком нормативном правовом акте содержится перечень критериев, по которым производственный объект относится к категории опасных?',
        answers: [
          'В Федеральном законе "О промышленной безопасности опасных производственных объектов"',
          'В постановлении Правительства Российской Федерации "О регистрации объектов в государственном реестре"',
          'В Указе Президента Российской Федерации "Об утверждении перечня опасных производственных объектов"',
          'В Положении о Федеральной службе по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'В Федеральном законе "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63627074',
        text:
          'Кем устанавливаются порядок разработки и требования к содержанию планов мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Федеральной службой по экологическому, технологическому и атомному надзору',
          'Правительством Российской Федерации',
          'Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: ['Правительством Российской Федерации'],
      },
      {
        code: '63627075',
        text:
          'Что должно быть осуществлено эксплуатирующей организацией при проведении идентификации опасных производственных объектов?',
        answers: [
          'Должны быть учтены все осуществляемые на объекте технологические процессы и применяемые технические устройства, обладающие признаками опасности',
          'Должны быть выявлены все признаки опасности на объекте',
          'Необходимо учесть количественные и качественные характеристики признаков опасности на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63627076',
        text:
          'В течение какого времени организация, эксплуатирующая опасный производственный объект, при внесении изменений в обоснование безопасности опасного производственного объекта должна направить их в Ростехнадзор?',
        answers: [
          'В течение 1 месяца после внесения изменений',
          'В течение 10 рабочих дней со дня получения положительного заключения экспертизы промышленной безопасности',
          'В течение 10 рабочих дней со дня передачи обоснования на экспертизу промышленной безопасности',
          'В течение 1 месяца после утверждения изменений',
        ],
        correctAnswers: [
          'В течение 10 рабочих дней со дня получения положительного заключения экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63627077',
        text:
          'В течение какого времени при наступлении события, имеющего признаки страхового случая, страхователь обязан сообщить об этом страховщику в письменной форме?',
        answers: [
          'В течение 12 часов',
          'В течение 24 часов',
          'В течение 48 часов',
          'В течение трех суток',
        ],
        correctAnswers: ['В течение 24 часов'],
      },
      {
        code: '63627078',
        text:
          'Какие виды экспертизы проектной документации проводятся в соответствии с Градостроительным кодексом Российской Федерации?',
        answers: [
          'Только государственная экспертиза',
          'Государственная экспертиза - для особо опасных, технически сложных и уникальных объектов, для всех остальных - негосударственная экспертиза',
          'Как государственная, так и негосударственная экспертиза по выбору застройщика или технического заказчика, за исключением случаев, когда проводится только государственная экспертиза',
        ],
        correctAnswers: [
          'Как государственная, так и негосударственная экспертиза по выбору застройщика или технического заказчика, за исключением случаев, когда проводится только государственная экспертиза',
        ],
      },
      {
        code: '63627079',
        text:
          'На что направлены мероприятия, проводимые эксплуатирующей организацией в рамках осуществления производственного контроля?',
        answers: [
          'Только на обеспечение готовности к действиям по локализации аварий и ликвидации их последствий',
          'Только на обеспечение безопасного функционирования опасных производственных объектов',
          'Только на предупреждение аварий и инцидентов на опасных производственных объектах',
          'На все перечисленное',
        ],
        correctAnswers: ['На все перечисленное'],
      },
      {
        code: '63627080',
        text:
          'В каком случае при смене владельца опасного объекта в период действия договора обязательного страхования права и обязанности страхователя по этому договору переходят к новому владельцу опасного объекта?',
        answers: [
          'Если новый владелец опасного объекта в течение тридцати календарных дней со дня вступления во владение опасным объектом в письменной форме уведомил об этом страховщика',
          'При смене владельца опасного объекта необходимо заключать новый договор обязательного страхования',
          'Права и обязанности страхователя переходят новому владельцу опасного объекта автоматически',
        ],
        correctAnswers: [
          'Если новый владелец опасного объекта в течение тридцати календарных дней со дня вступления во владение опасным объектом в письменной форме уведомил об этом страховщика',
        ],
      },
      {
        code: '63627081',
        text: 'При каком условии событие признается страховым случаем?',
        answers: [
          'Если вред, причиненный в период действия договора страхования, является результатом последствий или продолжающегося воздействия аварии, произошедшей до заключения договора обязательного страхования',
          'Если причинен вред потерпевшим, явившийся результатом последствий воздействия аварии, произошедшей в период действия договора обязательного страхования, которое влечет за собой обязанность страховщика произвести страховую выплату потерпевшим',
          'Если в результате аварии на опасном объекте после окончания действия договора страхования причинен вред нескольким потерпевшим',
        ],
        correctAnswers: [
          'Если причинен вред потерпевшим, явившийся результатом последствий воздействия аварии, произошедшей в период действия договора обязательного страхования, которое влечет за собой обязанность страховщика произвести страховую выплату потерпевшим',
        ],
      },
      {
        code: '63627082',
        text:
          'Кем проводится техническое расследование причин аварии на опасном производственном объекте?',
        answers: [
          'Специальной комиссией по расследованию, возглавляемой представителем федерального органа исполнительной власти в области охраны труда',
          'Специальной комиссией по расследованию, возглавляемой представителем Ростехнадзора или его территориального органа',
          'Комиссией по расследованию, возглавляемой либо представителем федерального органа исполнительной власти, специально уполномоченного в области охраны труда, либо представителем федерального органа исполнительной власти в области промышленной безопасности',
          'Комиссией по расследованию, возглавляемой руководителем эксплуатирующей организации, на которой произошла авария, с обязательным участием представителей федерального органа исполнительной власти в области промышленной безопасности',
        ],
        correctAnswers: [
          'Специальной комиссией по расследованию, возглавляемой представителем Ростехнадзора или его территориального органа',
        ],
      },
      {
        code: '63627083',
        text:
          'В каком нормативном правовом акте устанавливаются критерии классификации опасных производственных объектов?',
        answers: [
          'В Федеральном законе',
          'В постановлении Правительства Российской Федерации',
          'В нормативном правовом акте Ростехнадзора',
          'В нормативном правовом акте МЧС России',
        ],
        correctAnswers: ['В Федеральном законе'],
      },
      {
        code: '63627084',
        text:
          'В каком документе устанавливается порядок проведения технического расследования причин аварий на опасных производственных объектах?',
        answers: [
          'В Федеральном законе от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'В постановлении Правительства Российской Федерации',
          'В Трудовом кодексе Российской Федерации',
          'В нормативном документе, утвержденном федеральным органом исполнительной власти в области промышленной безопасности',
        ],
        correctAnswers: [
          'В нормативном документе, утвержденном федеральным органом исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63627085',
        text:
          'С какой периодичностью проводится документальное оформление результатов анализа функционирования системы управления промышленной безопасностью эксплуатирующей организацией?',
        answers: [
          'Не реже одного раза в течение календарного года',
          'Не реже двух раз в течение календарного года',
          'Не реже одного раза в течение квартала',
          'На усмотрение эксплуатирующей организации',
        ],
        correctAnswers: ['Не реже одного раза в течение календарного года'],
      },
      {
        code: '63627086',
        text:
          'В отношении каких объектов предусмотрена разработка планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В отношении всех опасных производственных объектов',
          'В отношении опасных производственных объектов I и II классов опасности',
          'В отношении опасных производственных объектов I, II и III классов опасности, предусмотренных пп. 1, 4, 5 и 6 приложения 1 к Федеральному закону от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'В отношении опасных производственных объектов I, II и III классов опасности, предусмотренных пп. 1, 4, 5 и 6 приложения 1 к Федеральному закону от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63627087',
        text:
          'Кто ведет реестр заключений экспертизы промышленной безопасности?',
        answers: [
          'Ростехнадзор и его территориальные органы',
          'Федеральная служба по аккредитации',
          'Федеральное автономное учреждение "Главное управление государственной экспертизы"',
          'Федеральное агентство по техническому регулированию и метрологии',
        ],
        correctAnswers: ['Ростехнадзор и его территориальные органы'],
      },
      {
        code: '63627088',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два варианта ответа.',
        answers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63627089',
        text:
          'Какие документы страхователь предоставляет страховщику при заключении договора обязательного страхования до регистрации опасного производственного объекта? Укажите все правильные ответы.',
        answers: [
          'Заявление об обязательном страховании',
          'Копию документа, содержащего сведения, характеризующие опасный производственный объект',
          'Копии документов об аттестации в области промышленной безопасности работников опасного производственного объекта',
          'Сведения о количестве и характере наступивших страховых случаев, об осуществленных и о предстоящих страховых выплатах',
          'Копию выписки из Российского регистра опасных производственных объектов',
        ],
        correctAnswers: [
          'Заявление об обязательном страховании',
          'Копию документа, содержащего сведения, характеризующие опасный производственный объект',
        ],
      },
      {
        code: '63627090',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Укажите все правильные ответы.',
        answers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 15 календарных дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
      },
      {
        code: '63627091',
        text:
          'В какой срок и на какой период времени в случае вынесения решения суда или должностного лица Ростехнадзора о назначении административного наказания в виде административного приостановления деятельности лицензиата лицензирующий орган приостанавливает действие лицензии?',
        answers: [
          'В течение суток со дня принятия решения на срок административного приостановления деятельности лицензиата',
          'В течение суток со дня вступления этого решения в законную силу на срок административного приостановления деятельности лицензиата',
          'В течение трех суток со дня вступления этого решения в законную силу на срок административного приостановления деятельности лицензиата',
          'В течение суток со дня вступления этого решения в законную силу на срок не более 30 суток',
        ],
        correctAnswers: [
          'В течение суток со дня вступления этого решения в законную силу на срок административного приостановления деятельности лицензиата',
        ],
      },
      {
        code: '63627092',
        text: 'Когда положение о производственном контроле считается принятым?',
        answers: [
          'После утверждения его руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем',
          'После его утверждения руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем и согласования с территориальным органом Ростехнадзора',
          'После его утверждения территориальным органом Ростехнадзора',
          'После его утверждения руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем и согласования с центральным аппаратом Ростехнадзора',
        ],
        correctAnswers: [
          'После утверждения его руководителем эксплуатирующей организации (руководителем обособленного подразделения юридического лица), индивидуальным предпринимателем',
        ],
      },
      {
        code: '63627093',
        text:
          'При строительстве и реконструкции каких объектов капитального строительства осуществляется государственный строительный надзор?',
        answers: [
          'При строительстве объектов капитального строительства, проектная документация которых подлежит экспертизе в соответствии со статьей 49 Градостроительного кодекса Российской Федерации',
          'При строительстве любых объектов',
          'Только при строительстве объектов, которые в соответствии с Градостроительным кодексом Российской Федерации являются особо опасными, технически сложными или уникальными',
          'Только при строительстве объектов, площадь которых составляет более 1500 м2',
        ],
        correctAnswers: [
          'При строительстве объектов капитального строительства, проектная документация которых подлежит экспертизе в соответствии со статьей 49 Градостроительного кодекса Российской Федерации',
        ],
      },
      {
        code: '63627094',
        text:
          'Что из перечисленного входит в обязанности организации в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Выполнение обязательств по охране труда, предусмотренных коллективными договорами и другими соглашениями',
          'Приостановление эксплуатации опасного производственного объекта в случае аварии или инцидента на опасном производственном объекте',
          'Участие в рассмотрении вопросов, связанных с обеспечением безопасных условий труда на рабочем месте, а также в расследовании происшедшего несчастного случая на производстве или профессионального заболевания',
          'Все перечисленные обязанности',
        ],
        correctAnswers: [
          'Приостановление эксплуатации опасного производственного объекта в случае аварии или инцидента на опасном производственном объекте',
        ],
      },
      {
        code: '63627095',
        text:
          'Кому вменена обязанность страховать свою ответственность за причинение вреда в результате аварии на опасном объекте в соответствии с Федеральным законом от 27.07.2010 № 225-ФЗ "Об обязательном страховании гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном объекте"?',
        answers: [
          'Эксплуатирующим организациям независимо от того, являются они владельцами опасного объекта или нет',
          'Проектным организациям',
          'Владельцам опасного объекта',
          'Экспертным организациям',
        ],
        correctAnswers: ['Владельцам опасного объекта'],
      },
      {
        code: '63627096',
        text:
          'Машины и оборудование, находящиеся в эксплуатации или изготовленные для собственных нужд, не подлежат:',
        answers: [
          'Декларированию соответствия или обязательной сертификации',
          'Техническому аудиту',
          'Экспертизе промышленной безопасности, если иные формы соответствия не установлены в технических регламентах',
        ],
        correctAnswers: [
          'Декларированию соответствия или обязательной сертификации',
        ],
      },
      {
        code: '63627097',
        text:
          'Что из перечисленного не определяется при разработке и проектировании машины и (или) оборудования?',
        answers: [
          'Допустимый риск для машины и (или) оборудования',
          'Методика измерений и правила отбора образцов, необходимых для применения и исполнения требований ТР ТС 010/2011',
          'Условия безопасной эксплуатации машин и оборудования',
        ],
        correctAnswers: [
          'Методика измерений и правила отбора образцов, необходимых для применения и исполнения требований ТР ТС 010/2011',
        ],
      },
      {
        code: '63627098',
        text:
          'Куда организация обязана направить результаты технического расследования причин аварии?',
        answers: [
          'В федеральный орган исполнительной власти в области промышленной безопасности',
          'В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии',
          'В центральный аппарат или территориальный орган Ростехнадзора, проводивший расследование, страховую организацию, территориальный орган МЧС России',
          'В федеральный орган исполнительной власти в области промышленной безопасности, орган местного самоуправления, государственную инспекцию труда субъекта Российской Федерации, территориальное объединение профсоюза, а также в территориальные органы МЧС России',
        ],
        correctAnswers: [
          'В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии',
        ],
      },
      {
        code: '63627099',
        text:
          'В каком из перечисленных случаев декларация промышленной безопасности находящегося в эксплуатации опасного производственного объекта не разрабатывается вновь?',
        answers: [
          'В случае истечения девяти лет со дня внесения в реестр деклараций промышленной безопасности последней декларации промышленной безопасности',
          'В случае изменения технологических процессов на опасном производственном объекте либо увеличения более чем на двадцать процентов количества опасных веществ, которые находятся или могут находиться на опасном производственном объекте',
          'В случае изменения требований промышленной безопасности',
          'По предписанию федерального органа исполнительной власти в области промышленной безопасности или его территориального органа в случае выявления несоответствия сведений, содержащихся в декларации промышленной безопасности, сведениям, полученным в ходе осуществления федерального государственного надзора в области промышленной безопасности',
        ],
        correctAnswers: [
          'В случае истечения девяти лет со дня внесения в реестр деклараций промышленной безопасности последней декларации промышленной безопасности',
        ],
      },
      {
        code: '63627100',
        text:
          'Что входит в обязанности работника, на которого возложены функции лица, ответственного за осуществление производственного контроля? Укажите все правильные ответы.',
        answers: [
          'Контроль за устранением причин возникновения аварий, инцидентов и несчастных случаев',
          'Контроль за выполнением предписаний Федеральной службы по экологическому, технологическому и атомному надзору и ее территориальных органов, а также соответствующих федеральных органов исполнительной власти по вопросам промышленной безопасности',
          'Выполнение лицензионных требований при оформлении лицензии на эксплуатацию взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности',
          'Контроль за выполнением лицензионных требований при осуществлении лицензируемой деятельности в области промышленной безопасности',
        ],
        correctAnswers: [
          'Контроль за устранением причин возникновения аварий, инцидентов и несчастных случаев',
          'Контроль за выполнением предписаний Федеральной службы по экологическому, технологическому и атомному надзору и ее территориальных органов, а также соответствующих федеральных органов исполнительной власти по вопросам промышленной безопасности',
          'Контроль за выполнением лицензионных требований при осуществлении лицензируемой деятельности в области промышленной безопасности',
        ],
      },
      {
        code: '63627101',
        text:
          'Какими нормативными правовыми актами устанавливаются требования к проведению экспертизы промышленной безопасности и к оформлению заключения экспертизы промышленной безопасности?',
        answers: [
          'Постановлениями Правительства Российской Федерации',
          'Федеральными законами',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Стандартами саморегулируемых организаций в области экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63627102',
        text:
          'Когда план мероприятий по локализации и ликвидации последствий аварий считается принятым?',
        answers: [
          'После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, либо руководителями обособленных подразделений',
          'После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'После утверждения руководителем организации, эксплуатирующей опасные производственные объекты, либо руководителями обособленных подразделений и согласования с органами Ростехнадзора',
        ],
        correctAnswers: [
          'После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях) и согласования руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63627103',
        text:
          'Какими документами могут устанавливаться обязательные требования в сфере технического регулирования?',
        answers: [
          'Техническими регламентами',
          'Национальными стандартами и сводами правил',
          'Техническими регламентами, национальными стандартами и сводами правил',
        ],
        correctAnswers: ['Техническими регламентами'],
      },
      {
        code: '63627104',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63627105',
        text:
          'Что является грубым нарушением лицензионных требований при осуществлении лицензируемого вида деятельности?',
        answers: [
          'Неукомплектованность штата работников, соответствующих требованиям, установленным федеральными нормами и правилами в области промышленной безопасности',
          'Отсутствие документов, подтверждающих ввод в эксплуатацию объектов, или наличие положительных заключений экспертизы промышленной безопасности в соответствии со статьями 6, 7 и 13 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Отсутствие подготовки и аттестации в области промышленной безопасности работников, в том числе руководителей организаций, осуществляющих деятельность на объектах, в соответствии со статьями 9 и 14.1 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Отсутствие в соответствии со статьей 10 Федерального закона "О промышленной безопасности опасных производственных объектов" договоров на обслуживание с профессиональными аварийно-спасательными службами или формированиями, а в случаях, предусмотренных указанным Федеральным законом, другими федеральными законами и принимаемыми в соответствии с ними иными нормативными правовыми актами Российской Федерации, - наличие собственных профессиональных аварийно-спасательных служб или формирований, а также наличие нештатного аварийно-спасательного формирования из числа работников лицензиата',
        ],
        correctAnswers: [
          'Отсутствие подготовки и аттестации в области промышленной безопасности работников, в том числе руководителей организаций, осуществляющих деятельность на объектах, в соответствии со статьями 9 и 14.1 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63627106',
        text:
          'В какой срок опасные производственные объекты, вводимые в эксплуатацию, должны быть внесены в государственный реестр?',
        answers: [
          'Не позднее трех месяцев с даты начала их эксплуатации',
          'В течение 40 рабочих дней с даты начала их эксплуатации',
          'Не позднее 20 рабочих дней со дня поступления в регистрирующий орган сведений, характеризующих каждый объект',
          'Срок не регламентирован',
        ],
        correctAnswers: [
          'Не позднее 20 рабочих дней со дня поступления в регистрирующий орган сведений, характеризующих каждый объект',
        ],
      },
      {
        code: '63627107',
        text:
          'Что входит в понятие "инцидент" в соответствии с Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от установленного режима технологического процесса',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте, неконтролируемые взрыв и (или) выброс опасных веществ, при которых нет пострадавших',
          'Контролируемое и (или) неконтролируемое горение, а также взрыв опасного производственного объекта, не сопровождающиеся выбросом в окружающую среду опасных веществ',
          'Нарушение целостности или полное разрушение сооружений и технических устройств опасного производственного объекта при отсутствии взрыва либо выброса опасных веществ',
        ],
        correctAnswers: [
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от установленного режима технологического процесса',
        ],
      },
      {
        code: '63627108',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если план мероприятий разрабатывается на каждый опасный объект отдельно',
          'В случае если это регламентировано внутренней документацией организации',
          'В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63627109',
        text:
          'Какое право не предоставлено должностным лицам Ростехнадзора при осуществлении федерального государственного надзора в области промышленной безопасности?',
        answers: [
          'Посещать (осматривать) производственные объекты, если иное не предусмотрено федеральными законами',
          'Выдавать лицензии на отдельные виды деятельности, связанные с повышенной опасностью промышленных производств',
          'Выдавать контролируемым лицам рекомендации по обеспечению безопасности и предотвращению нарушений обязательных требований',
          'Составлять акты по фактам непредставления или несвоевременного представления контролируемым лицом документов и материалов, запрошенных при проведении контрольных (надзорных) мероприятий',
          'Обращаться за содействием к органам полиции в случаях, если инспектору оказывается противодействие или угрожает опасность',
        ],
        correctAnswers: [
          'Выдавать лицензии на отдельные виды деятельности, связанные с повышенной опасностью промышленных производств',
        ],
      },
      {
        code: '63627110',
        text:
          'В каком случае должностные лица Ростехнадзора вправе привлекать к административной ответственности лиц, виновных в нарушении требований промышленной безопасности?',
        answers: [
          'Это не относится к их компетенции',
          'При осуществлении федерального государственного надзора в области промышленной безопасности',
          'Только если это сопряжено с направлением в суд материалов о привлечении указанных лиц к уголовной ответственности за нарушение требований',
        ],
        correctAnswers: [
          'При осуществлении федерального государственного надзора в области промышленной безопасности',
        ],
      },
      {
        code: '63627111',
        text:
          'Кто осуществляет ведение реестра деклараций промышленной безопасности опасных производственных объектов?',
        answers: [
          'Ростехнадзор',
          'Государственная регистрационная палата',
          'Ростехнадзор совместно с МЧС России',
          'Минстрой России',
        ],
        correctAnswers: ['Ростехнадзор'],
      },
      {
        code: '63627112',
        text:
          'В каких комиссиях проходят аттестацию члены аттестационных комиссий организаций, эксплуатирующих опасные производственные объекты (за исключением организаций, работники которых подлежат аттестации в ведомственных аттестационных комиссиях)?',
        answers: [
          'Только в территориальных аттестационных комиссиях',
          'В аттестационных комиссиях Федеральной службы по экологическому, технологическому и атомному надзору и ее территориальных органов',
          'Только в центральной аттестационной комиссии',
          'Решение о месте прохождения аттестации, принимается на основании заявки организации, подаваемой в центральную аттестационную комиссию',
        ],
        correctAnswers: ['Только в территориальных аттестационных комиссиях'],
      },
      {
        code: '63627113',
        text:
          'В каком нормативном правовом акте установлен порядок принятия решений лицензирующим органом о предоставлении и переоформлении лицензии на деятельность по проведению экспертизы промышленной безопасности?',
        answers: [
          'В Положении о лицензировании деятельности по проведению экспертизы промышленной безопасности, утв. постановлением Правительства Российской Федерации',
          'В Федеральном законе "О лицензировании отдельных видов деятельности"',
          'В Федеральном законе "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'В Федеральном законе "О лицензировании отдельных видов деятельности"',
        ],
      },
      {
        code: '63627114',
        text:
          'Что обязан сделать лицензиат, если он намерен изменить адрес места осуществления лицензируемого вида деятельности?',
        answers: [
          'Подать заявление в лицензирующий орган о переоформлении лицензии (о внесении изменений в реестр лицензий)',
          'Подать заявление в лицензирующий орган о выдаче новой лицензии',
          'Направить в лицензирующий орган уведомление о своих намерениях',
        ],
        correctAnswers: [
          'Подать заявление в лицензирующий орган о переоформлении лицензии (о внесении изменений в реестр лицензий)',
        ],
      },
      {
        code: '63627115',
        text:
          'В каких законах устанавливаются виды деятельности, подлежащие лицензированию в области промышленной безопасности?',
        answers: [
          'Только в Федеральном законе от 04.05.2011 № 99-ФЗ "О лицензировании отдельных видов деятельности"',
          'Только в Федеральном законе от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'В Федеральном законе от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов" и Федеральном законе от 04.05.2011 №99-ФЗ "О лицензировании отдельных видов деятельности"',
          'В Федеральном законе от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов", Федеральном законе от 04.05.2011 №99-ФЗ "О лицензировании отдельных видов деятельности" и Федеральном законе от 21.12.1994 № 68-ФЗ "О защите населения и территорий от чрезвычайных ситуаций природного и техногенного характера"',
        ],
        correctAnswers: [
          'Только в Федеральном законе от 04.05.2011 № 99-ФЗ "О лицензировании отдельных видов деятельности"',
        ],
      },
      {
        code: '63627116',
        text:
          'Что из перечисленного включает в себя документация системы управления промышленной безопасностью?',
        answers: [
          'Только документы планирования мероприятий по снижению риска аварий на опасных производственных объектах',
          'Только заявление о политике эксплуатирующих организаций в области промышленной безопасности',
          'Только положение о системе управления промышленной безопасностью',
          'Только положение (положения) о производственном контроле за соблюдением требований промышленной безопасности на опасных производственных объектах',
          'Все перечисленные документы',
        ],
        correctAnswers: ['Все перечисленные документы'],
      },
      {
        code: '63627117',
        text:
          'Кем утверждается положение о системе управления промышленной безопасностью?',
        answers: [
          'Руководителем организации, эксплуатирующей опасный производственный объект, или руководителями обособленных подразделений, а также инспектором территориального органа Ростехнадзора',
          'Руководителем эксплуатирующей организации',
          'Руководителем организации, проводившей экспертизу промышленной безопасности документа',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63627118',
        text:
          'Для каких опасных производственных объектов обязательна разработка декларации промышленной безопасности?',
        answers: [
          'Для опасных производственных объектов I, II и III классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются опасные вещества в количествах, указанных в приложении 2 к Федеральному закону от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'Для всех опасных производственных объектов независимо от их класса опасности',
          'Для опасных производственных объектов I и II классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества в количествах, указанных в приложении 2 к Федеральному закону от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов" (за исключением использования взрывчатых веществ при проведении взрывных работ)',
          'Для опасных производственных объектов, указанных в приложении 1 к Федеральному закону от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Для опасных производственных объектов I и II классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества в количествах, указанных в приложении 2 к Федеральному закону от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов" (за исключением использования взрывчатых веществ при проведении взрывных работ)',
        ],
      },
      {
        code: '63627119',
        text:
          'Какая организация имеет право проводить экспертизу промышленной безопасности?',
        answers: [
          'Организация, имеющая лицензию на проведение экспертизы промышленной безопасности',
          'Организация, аккредитованная в Федеральной службе по аккредитации на проведение экспертизы промышленной безопасности',
          'Организация, имеющая допуск СРО на проведение экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Организация, имеющая лицензию на проведение экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63627120',
        text:
          'В отношении каких опасных объектов заключается договор обязательного страхования?',
        answers: [
          'В отношении всего предприятия',
          'В отношении каждого опасного объекта, если иное не предусмотрено договором в отношении опасных объектов',
          'В отношении только декларируемых опасных производственных объектов',
          'В отношении групп опасных объектов, объединенных по территориальному принципу (или по специфике технологических операций)',
        ],
        correctAnswers: [
          'В отношении каждого опасного объекта, если иное не предусмотрено договором в отношении опасных объектов',
        ],
      },
      {
        code: '63627121',
        text:
          'Какие требования к экспертам в области промышленной безопасности указаны верно?',
        answers: [
          'При аттестации на 1 категорию эксперт обязан иметь высшее образование и стаж работы в области проведения экспертизы промышленной безопасности не менее 5 лет',
          'Эксперт обязан иметь лицензию на деятельность по проведению экспертизы промышленной безопасности',
          'Эксперту запрещается участвовать в проведении экспертизы в отношении опасных производственных объектов, принадлежащих на праве собственности или ином законном основании организации, в трудовых отношениях с которой он состоит',
          'Эксперт обязан подготавливать заключение экспертизы промышленной безопасности и предоставлять его в территориальный орган Ростехнадзора для дальнейшей регистрации',
        ],
        correctAnswers: [
          'Эксперту запрещается участвовать в проведении экспертизы в отношении опасных производственных объектов, принадлежащих на праве собственности или ином законном основании организации, в трудовых отношениях с которой он состоит',
        ],
      },
      {
        code: '63627122',
        text: 'В каком случае лицензия может быть аннулирована решением суда?',
        answers: [
          'При обнаружении недостоверных или искаженных данных в документах, представленных в лицензирующий орган для получения лицензии',
          'При ликвидации юридического лица или прекращении его деятельности в результате реорганизации',
          'Из-за неуплаты лицензиатом в течение трех месяцев лицензионного сбора',
          'Если в установленный судом срок административного наказания в виде административного приостановления деятельности и приостановления действия лицензии лицензиат не устранил грубое нарушение лицензионных требований',
        ],
        correctAnswers: [
          'Если в установленный судом срок административного наказания в виде административного приостановления деятельности и приостановления действия лицензии лицензиат не устранил грубое нарушение лицензионных требований',
        ],
      },
      {
        code: '63627123',
        text:
          'Какие документы предоставляет страховщику владелец опасного производственного объекта для заключения договора обязательного страхования гражданской ответственности?',
        answers: [
          'Только заявление об обязательном страховании',
          'Только копию свидетельства о регистрации опасного производственного объекта в государственном реестре опасных производственных объектов',
          'Только копию карты учета опасного производственного объекта',
          'Только копии документов, подтверждающих право собственности и (или) владения опасным объектом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63627124',
        text:
          'Каким образом должна обеспечиваться безопасность здания или сооружения в процессе эксплуатации?',
        answers: [
          'Только посредством мониторинга состояния основания',
          'Только посредством периодических осмотров строительных конструкций',
          'Только посредством технического обслуживания систем инженерно-технического обеспечения',
          'Только посредством проведения экспертизы промышленной безопасности',
          'Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения',
        ],
        correctAnswers: [
          'Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения',
        ],
      },
      {
        code: '63627125',
        text:
          'В каком документе установлен перечень сведений об организации производственного контроля за соблюдением требований промышленной безопасности, направляемых эксплуатирующей организацией в Ростехнадзор?',
        answers: [
          'В Федеральном законе "О промышленной безопасности опасных производственных объектов"',
          'В Правилах организации и осуществления производственного контроля за соблюдением требований промышленной безопасности',
          'В Общих правилах промышленной безопасности для организаций, осуществляющих свою деятельность в области промышленной безопасности опасных производственных объектов',
          'Во всех перечисленных документах',
        ],
        correctAnswers: [
          'В Правилах организации и осуществления производственного контроля за соблюдением требований промышленной безопасности',
        ],
      },
      {
        code: '63627126',
        text:
          'Исходя из данных в каком документе определяется страховая сумма для находящихся на расстоянии менее 500 м друг от друга опасных производственных объектов 1 и 2 классов опасности в случае суммарного количества обращающегося на них опасного вещества, равного или превышающего предельно допустимое количество?',
        answers: [
          'Сведений, указанных в отчете о специальной оценке условий труда',
          'Данных, указанных в декларации промышленной безопасности',
          'Данных, указанных в карте учета опасного производственного объекта',
          'Исходя из всех перечисленных данных',
        ],
        correctAnswers: [
          'Данных, указанных в декларации промышленной безопасности',
        ],
      },
      {
        code: '63627127',
        text:
          'Как назначается специальная комиссия по техническому расследованию причин аварии?',
        answers: [
          'Приказом руководителя организации, в которой произошла авария',
          'В зависимости от характера и возможных последствий аварии правовым актом уполномоченного органа или его территориального органа',
          'Совместным приказом Ростехнадзора и МЧС России',
          'Распоряжением Правительства Российской Федерации',
        ],
        correctAnswers: [
          'В зависимости от характера и возможных последствий аварии правовым актом уполномоченного органа или его территориального органа',
        ],
      },
      {
        code: '63627128',
        text:
          'Какие выводы может содержать заключение экспертизы промышленной безопасности технических устройств?',
        answers: [
          'Объект экспертизы соответствует требованиям промышленной безопасности',
          'Объект экспертизы не соответствует требованиям промышленной безопасности',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63627129',
        text: 'Кто проводит строительный контроль?',
        answers: [
          'Подрядчик и застройщик, технический заказчик, лицо, ответственное за эксплуатацию здания, сооружения либо организация, осуществляющая подготовку проектной документации и привлеченная техническим заказчиком (застройщиком) по договору для осуществления строительного контроля',
          'Саморегулируемая организация',
          'Федеральный орган исполнительной власти, уполномоченный на осуществление строительного надзора (контроля)',
          'Органы исполнительной власти субъектов Российской Федерации, уполномоченные на осуществление регионального строительного надзора (контроля)',
        ],
        correctAnswers: [
          'Подрядчик и застройщик, технический заказчик, лицо, ответственное за эксплуатацию здания, сооружения либо организация, осуществляющая подготовку проектной документации и привлеченная техническим заказчиком (застройщиком) по договору для осуществления строительного контроля',
        ],
      },
      {
        code: '63627130',
        text:
          'Что понимается под обоснованием безопасности опасного производственного объекта?',
        answers: [
          'Это документ, содержащий сведения об условиях безопасной эксплуатации опасного производственного объекта, требования к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта',
          'Это документ, содержащий сведения о результатах оценки риска аварии на опасном производственном объекте и связанной с ней угрозы, требования к безопасной эксплуатации опасного производственного объекта, требования к обслуживающему персоналу',
          'Это документ, содержащий сведения о результатах оценки риска аварии на опасном производственном объекте и связанной с ней угрозы, условия безопасной эксплуатации опасного производственного объекта, требования к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта',
        ],
        correctAnswers: [
          'Это документ, содержащий сведения о результатах оценки риска аварии на опасном производственном объекте и связанной с ней угрозы, условия безопасной эксплуатации опасного производственного объекта, требования к эксплуатации, капитальному ремонту, консервации и ликвидации опасного производственного объекта',
        ],
      },
      {
        code: '63627131',
        text:
          'В какой срок лицензирующий орган обязан принять решение о предоставлении или об отказе в предоставлении лицензии?',
        answers: [
          'Не позднее 60 календарных дней со дня получения заявления соискателя лицензии со всеми необходимыми документами',
          'Не позднее 30 рабочих дней со дня получения заявления соискателя лицензии со всеми необходимыми документами',
          'Не позднее 45 рабочих дней со дня приема заявления о предоставлении лицензии и прилагаемых к нему документов',
          'Срок определяется договором между лицензиатом и лицензирующим органом',
        ],
        correctAnswers: [
          'Не позднее 45 рабочих дней со дня приема заявления о предоставлении лицензии и прилагаемых к нему документов',
        ],
      },
      {
        code: '63627132',
        text:
          'После прохождения каких процедур заключение экспертизы промышленной безопасности может быть использовано в целях, установленных Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Сразу после подписания заключения экспертизы руководителем экспертной организации и экспертами, проводившими экспертизу',
          'После утверждения заключения экспертизы промышленной безопасности в органах Ростехнадзора',
          'После подписания заключения экспертизы руководителем экспертной организации и экспертами, проводившими экспертизу, и внесения его в реестр заключений экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'После подписания заключения экспертизы руководителем экспертной организации и экспертами, проводившими экспертизу, и внесения его в реестр заключений экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63627133',
        text:
          'В каких случаях техническое устройство, применяемое на опасном производственном объекте, подлежит экспертизе промышленной безопасности, если техническим регламентом не установлена иная форма оценки соответствия указанного устройства обязательным требованиям?',
        answers: [
          'Только по истечении срока службы или при превышении количества циклов нагрузки такого технического устройства, установленных его производителем',
          'Только после проведения работ, связанных с изменением конструкции, заменой материала несущих элементов такого технического устройства',
          'Только до начала применения на опасном производственном объекте',
          'Только при отсутствии в технической документации данных о сроке службы такого технического устройства, если фактический срок его службы превышает двадцать лет',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63627134',
        text:
          'В какой срок осуществляется внесение в государственный реестр изменений сведений, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации?',
        answers: [
          'В срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений',
          'В срок, не превышающий 20 (двадцати) рабочих дней с даты регистрации заявления о внесении изменений',
          'В срок, не превышающий 30 (тридцати) рабочих дней со дня наступления указанных изменений',
          'В срок, не превышающий 5 (пяти) рабочих дней со дня наступления указанных изменений',
        ],
        correctAnswers: [
          'В срок, не превышающий 20 (двадцати) рабочих дней с даты регистрации заявления о внесении изменений',
        ],
      },
      {
        code: '63627135',
        text:
          'При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?',
        answers: [
          'Представители организации не принимают участия в расследовании',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 25% от общего числа членов комиссии',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии',
        ],
        correctAnswers: [
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии',
        ],
      },
      {
        code: '63627136',
        text:
          'В какой документации определяются обязанности и права работников, на которых возложены функции лиц, ответственных за организацию и осуществление производственного контроля? Укажите все правильные ответы.',
        answers: [
          'В положении о производственном контроле',
          'В постановлении Правительства РФ "Об организации и осуществлении производственного контроля за соблюдением требований промышленной безопасности"',
          'В должностных инструкциях или заключаемых с этими работниками договорах (контрактах)',
          'В плане мероприятий по обеспечению промышленной безопасности на основании результатов проверки состояния промышленной безопасности',
        ],
        correctAnswers: [
          'В положении о производственном контроле',
          'В должностных инструкциях или заключаемых с этими работниками договорах (контрактах)',
        ],
      },
      {
        code: '63627137',
        text:
          'Кто осуществляет регистрацию объектов в государственном реестре опасных производственных объектов и ведение этого реестра?',
        answers: [
          'Только Федеральная служба по экологическому, технологическому и атомному надзору',
          'Регистрационная палата при Правительстве Российской Федерации',
          'Федеральная служба по экологическому, технологическому и атомному надзору, а также федеральные органы исполнительной власти, которым в установленном порядке предоставлено право проводить регистрацию подведомственных объектов, и Государственная корпорация по атомной энергии "Росатом"',
          'Министерство промышленности и торговли Российской Федерации, а также федеральные органы исполнительной власти, которым в установленном порядке предоставлено право проводить регистрацию подведомственных объектов',
        ],
        correctAnswers: [
          'Федеральная служба по экологическому, технологическому и атомному надзору, а также федеральные органы исполнительной власти, которым в установленном порядке предоставлено право проводить регистрацию подведомственных объектов, и Государственная корпорация по атомной энергии "Росатом"',
        ],
      },
      {
        code: '63627138',
        text:
          'Что из указанного относится к обязанностям организации в области промышленной безопасности в соответствии с Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Разработка локальных нормативных документов по охране труда',
          'Наличие на опасном производственном объекте нормативных правовых актов, устанавливающих требования промышленной безопасности, а также правил ведения работ на опасном производственном объекте',
          'Обеспечение работников опасного производственного объекта средствами индивидуальной защиты',
          'Декларирование соответствия условий труда государственным нормативным требованиям охраны труда',
        ],
        correctAnswers: [
          'Наличие на опасном производственном объекте нормативных правовых актов, устанавливающих требования промышленной безопасности, а также правил ведения работ на опасном производственном объекте',
        ],
      },
      {
        code: '63627139',
        text:
          'Кем осуществляется производственный контроль в эксплуатирующей организации? Укажите все правильные ответы.',
        answers: [
          'Назначенным (определенным) решением руководителя эксплуатирующей организации работником',
          'Инспектором территориального органа Ростехнадзора',
          'Службой производственного контроля',
          'Техническим руководителем организации',
          'Разработчиком системы управления промышленной безопасностью',
        ],
        correctAnswers: [
          'Назначенным (определенным) решением руководителя эксплуатирующей организации работником',
          'Службой производственного контроля',
        ],
      },
      {
        code: '63627140',
        text:
          'Какой экспертизе подлежит декларация промышленной безопасности, разрабатываемая в составе документации на техническое перевооружение опасного производственного объекта?',
        answers: [
          'Экспертизе промышленной безопасности в установленном порядке',
          'Государственной экспертизе в соответствии с законодательством Российской Федерации о градостроительной деятельности',
          'Никакую экспертизу декларация промышленной безопасности проходить не должна',
          'Экологической экспертизе в установленном порядке',
        ],
        correctAnswers: [
          'Экспертизе промышленной безопасности в установленном порядке',
        ],
      },
      {
        code: '63627141',
        text:
          'Какие организации обязаны создавать системы управления промышленной безопасностью?',
        answers: [
          'Все организации, эксплуатирующие опасные производственные объекты',
          'Все юридические лица',
          'Организации, эксплуатирующие объекты I и II классов опасности',
          'Организации, эксплуатирующие объекты I, II или III классов опасности',
        ],
        correctAnswers: [
          'Организации, эксплуатирующие объекты I и II классов опасности',
        ],
      },
      {
        code: '63627142',
        text:
          'Что из перечисленного не подлежит экспертизе промышленной безопасности?',
        answers: [
          'Декларация промышленной безопасности опасного производственного объекта',
          'Обоснование безопасности опасного производственного объекта и изменения к обоснованию безопасности опасного производственного объекта',
          'Технические устройства, применяемые на опасном производственном объекте',
          'Документация на капитальный ремонт опасного производственного объекта',
          'Здания и сооружения на опасном производственном объекте, предназначенные для технологических процессов, хранения сырья или продукции, перемещения людей и грузов, локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Документация на капитальный ремонт опасного производственного объекта',
        ],
      },
      {
        code: '63627143',
        text:
          'В какой срок эксплуатирующие организации и индивидуальные предприниматели обязаны предоставить в регистрирующий орган сведения, характеризующие опасные производственные объекты?',
        answers: [
          'Не позднее трех месяцев с даты начала эксплуатации',
          'Не позднее 10 рабочих дней со дня начала их эксплуатации',
          'Не позднее 30 рабочих дней со дня начала их эксплуатации',
          'Срок предоставления сведений не регламентирован',
        ],
        correctAnswers: [
          'Не позднее 10 рабочих дней со дня начала их эксплуатации',
        ],
      },
      {
        code: '63627144',
        text:
          'В каком случае руководитель или иное уполномоченное лицо организации, эксплуатирующей опасный производственный объект, обязан представлять утвержденный экземпляр декларации в Федеральную службу по экологическому, технологическому и атомному надзору?',
        answers: [
          'Не обязан ни в каком случае',
          'Только в случае, если обяжет суд',
          'Правилами не регламентируется',
          'Обязан в любом случае',
        ],
        correctAnswers: ['Обязан в любом случае'],
      },
      {
        code: '63627145',
        text:
          'Какой экспертизе в соответствии с Федеральным законом от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов" подлежит обоснование безопасности опасного производственного объекта?',
        answers: [
          'Государственной экспертизе',
          'Экспертизе промышленной безопасности',
          'Экологической экспертизе',
        ],
        correctAnswers: ['Экспертизе промышленной безопасности'],
      },
      {
        code: '63627146',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63627147',
        text:
          'В случае если техническим регламентом не установлена иная форма оценки соответствия технического устройства, применяемого на опасном производственном объекте, обязательным требованиям к такому техническому устройству, то до начала эксплуатации оно подлежит:',
        answers: [
          'Техническому аудиту',
          'Добровольной сертификации или добровольному декларированию соответствия по выбору производителя технического устройства',
          'Экспертизе промышленной безопасности',
        ],
        correctAnswers: ['Экспертизе промышленной безопасности'],
      },
      {
        code: '63627148',
        text:
          'В каком случае юридическое лицо признается виновным в совершении административного правонарушения?',
        answers: [
          'Если будет установлено, что у него имелась возможность для соблюдения правил и норм, за нарушение которых предусмотрена административная ответственность, но им не были приняты все зависящие от него меры по их соблюдению',
          'Если должностное лицо, рассматривающее дело об административном правонарушении, абсолютно уверено в виновности юридического лица',
          'Если юридическое лицо признало факт совершения административного правонарушения',
        ],
        correctAnswers: [
          'Если будет установлено, что у него имелась возможность для соблюдения правил и норм, за нарушение которых предусмотрена административная ответственность, но им не были приняты все зависящие от него меры по их соблюдению',
        ],
      },
      {
        code: '63627149',
        text:
          'Какие квалификационные требования предъявляются к работникам, ответственным за осуществление производственного контроля на опасных производственных объектах I-III класса опасности?',
        answers: [
          'Высшее техническое образование, общий стаж работы не менее 3 лет, дополнительное профессиональное образование в области промышленной безопасности и аттестацию в области промышленной безопасности',
          'Высшее или среднее техническое образование, стаж работы не менее 3 лет на соответствующей работе на опасном производственном объекте отрасли, дополнительное профессиональное образование в области промышленной безопасности и аттестацию в области промышленной безопасности',
          'Высшее техническое образование, стаж работы на опасном производственном объекте отрасли не менее 3 лет, не реже одного раза в 5 лет получать дополнительное профессиональное образование в области промышленной безопасности и проходить аттестацию в области промышленной безопасности',
        ],
        correctAnswers: [
          'Высшее техническое образование, стаж работы на опасном производственном объекте отрасли не менее 3 лет, не реже одного раза в 5 лет получать дополнительное профессиональное образование в области промышленной безопасности и проходить аттестацию в области промышленной безопасности',
        ],
      },
      {
        code: '63627150',
        text:
          'В какой срок проводится первичная аттестация работников в области промышленной безопасности при назначении на соответствующую должность?',
        answers: [
          'Не позднее 10 дней',
          'Не позднее 2 недель',
          'Не позднее 1 месяца',
          'Не позднее 2 месяцев',
        ],
        correctAnswers: ['Не позднее 1 месяца'],
      },
      {
        code: '63627151',
        text:
          'Вложения в каком формате не могут содержать электронные документы?',
        answers: [
          'В бинарном формате',
          'В форматах .doc, .docx',
          'XML-документы',
        ],
        correctAnswers: ['В бинарном формате'],
      },
      {
        code: '63627152',
        text:
          'Выполнение каких работ на объектах не включает в себя лицензируемый вид деятельности по эксплуатации взрывопожароопасных и химических опасных производственных объектов I, II и III классов опасности?',
        answers: [
          'Использование (эксплуатация) на объектах оборудования, работающего под избыточным давлением более 0,07 МПа воды при температуре нагрева более 115 °C и иных жидкостей при температуре, превышающей температуру их кипения при избыточном давлении 0,07 МПа',
          'Получение расплавов черных и цветных металлов, сплавов на основе этих расплавов с применением оборудования, рассчитанного на максимальное количество расплава, составляющее 500 кг и более',
          'Работы, связанные с получением, использованием, переработкой, хранением, транспортированием и уничтожением взрывчатых материалов промышленного назначения',
          'Получение (образование) воспламеняющихся, окисляющих, горючих, взрывчатых, токсичных, высокотоксичных веществ и веществ, представляющих опасность для окружающей среды, на взрывопожароопасных и химически опасных производственных объектах I, II или III классов опасности',
        ],
        correctAnswers: [
          'Работы, связанные с получением, использованием, переработкой, хранением, транспортированием и уничтожением взрывчатых материалов промышленного назначения',
        ],
      },
      {
        code: '63627153',
        text:
          'Какие из указанных опасных объектов не относятся к объектам, владельцы которых обязаны осуществлять обязательное страхование?',
        answers: [
          'Опасные производственные объекты, подлежащие регистрации в государственном реестре',
          'Лифты, подъемные платформы для инвалидов, эскалаторы (за исключением эскалаторов в метрополитенах)',
          'Автозаправочные станции жидкого моторного топлива',
          'Опасные производственные объекты, расположенные в границах объектов использования атомной энергии',
          'Пассажирские конвейеры (движущиеся пешеходные дорожки)',
        ],
        correctAnswers: [
          'Опасные производственные объекты, расположенные в границах объектов использования атомной энергии',
        ],
      },
      {
        code: '63627154',
        text:
          'Что из перечисленного не относится к обязанностям работника, на которого возложены функции ответственного за осуществление производственного контроля?',
        answers: [
          'Проведение проверок состояния промышленной безопасности',
          'Разработка плана работы по осуществлению производственного контроля',
          'Организация и проведение работ по специальной оценке условий труда',
          'Участие в техническом расследовании причин аварий, а также участие в расследовании инцидентов и несчастных случаев',
        ],
        correctAnswers: [
          'Организация и проведение работ по специальной оценке условий труда',
        ],
      },
      {
        code: '63627155',
        text:
          'Что не является предметом государственного строительного надзора?',
        answers: [
          'Наличие разрешения на строительство',
          'Соответствие строительных материалов, применяемых в процессе строительства, реконструкции объекта капитального строительства требованиям технических регламентов, проектной документации',
          'Выполнение работ по договорам о строительстве, реконструкции, капитальном ремонте объектов капитального строительства, заключенным с застройщиком, техническим заказчиком, лицом, ответственным за эксплуатацию здания, сооружения, региональным оператором, только индивидуальными предпринимателями или юридическими лицами, которые являются членами саморегулируемых организаций в области строительства, реконструкции, капитального ремонта объектов капитального строительства',
          'Наличие декларации промышленной безопасности',
        ],
        correctAnswers: ['Наличие декларации промышленной безопасности'],
      },
      {
        code: '63627156',
        text:
          'Кем устанавливается порядок проведения аттестации в области промышленной безопасности?',
        answers: [
          'Федеральным агентством по техническому регулированию и метрологии',
          'Федеральными органами исполнительной власти в области промышленной безопасности',
          'Федеральной службой по экологическому, технологическому и атомному надзору',
          'Правительством Российской Федерации',
        ],
        correctAnswers: ['Правительством Российской Федерации'],
      },
      {
        code: '63627157',
        text:
          'На каком этапе осуществляется присвоение класса опасности опасному производственному объекту?',
        answers: [
          'На этапе подготовки проектной документации',
          'На этапе проведения экспертизы промышленной безопасности зданий, сооружений и технических устройств, применяемых на опасном производственном объекте',
          'На этапе его регистрации в государственном реестре опасных производственных объектов',
          'На этапе ввода в эксплуатацию',
        ],
        correctAnswers: [
          'На этапе его регистрации в государственном реестре опасных производственных объектов',
        ],
      },
      {
        code: '63627158',
        text:
          'В отношении какого опасного производственного объекта эксперту запрещается участвовать в проведении экспертизы промышленной безопасности?',
        answers: [
          'В отношении опасных производственных объектов по хранению и уничтожению химического оружия',
          'В отношении объектов, находящихся в государственной собственности',
          'В отношении опасного производственного объекта, принадлежащего на праве собственности или ином законном основании организации, в трудовых отношениях с которой состоит эксперт',
        ],
        correctAnswers: [
          'В отношении опасного производственного объекта, принадлежащего на праве собственности или ином законном основании организации, в трудовых отношениях с которой состоит эксперт',
        ],
      },
      {
        code: '63627159',
        text:
          'В каком случае лицензирующие органы могут приостанавливать действие лицензии?',
        answers: [
          'В случае ликвидации юридического лица или прекращения его деятельности в результате реорганизации',
          'В случае неуплаты лицензиатом в течение 3 месяцев лицензионного сбора',
          'В случае смены собственника организации',
          'В случае привлечения лицензиата к административной ответственности за неисполнение в установленный срок предписания об устранении грубого нарушения лицензионных требований',
        ],
        correctAnswers: [
          'В случае привлечения лицензиата к административной ответственности за неисполнение в установленный срок предписания об устранении грубого нарушения лицензионных требований',
        ],
      },
      {
        code: '63627160',
        text:
          'Кем утверждается заявление о политике эксплуатирующей организации в области промышленной безопасности?',
        answers: [
          'Территориальным органом Ростехнадзора',
          'Техническим руководителем эксплуатирующей организации',
          'Руководителем эксплуатирующей организации',
          'Руководителем муниципального образования, на территории которого расположен объект',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63627161',
        text:
          'Кто осуществляет государственный строительный надзор за строительством, реконструкцией объектов капитального строительства, отнесенных Градостроительным кодексом Российской Федерации к особо опасным, технически сложным и уникальным?',
        answers: [
          'Федеральный орган исполнительной власти, уполномоченный на осуществление федерального государственного строительного надзора',
          'Орган исполнительной власти субъекта Российской Федерации, уполномоченный на осуществление регионального государственного строительного надзора',
          'Федеральный орган исполнительной власти, уполномоченный на проведение государственной экспертизы проектной документации, или подведомственные ему государственные (бюджетные или автономные) учреждения',
        ],
        correctAnswers: [
          'Федеральный орган исполнительной власти, уполномоченный на осуществление федерального государственного строительного надзора',
        ],
      },
      {
        code: '63627162',
        text:
          'Внесение каких изменений в государственный реестр осуществляется в срок, не превышающий 10 (десяти) рабочих дней с даты регистрации заявления о внесении изменений? Выберите два правильных варианта ответов.',
        answers: [
          'Изменение состава опасного производственного объекта, в том числе при изменении количественного и качественного состава технического устройства; технологического процесса; признаков или класса опасности опасного производственного объекта)',
          'Изменение адреса места нахождения опасного производственного объекта',
          'Изменение сведений об эксплуатирующей организации, собственнике опасного производственного объекта и/или сведений, указанных эксплуатирующей организацией в заявлении о регистрации опасного производственного объекта в государственном реестре',
          'Изменение сведений, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации',
        ],
        correctAnswers: [
          'Изменение адреса места нахождения опасного производственного объекта',
          'Изменение сведений об эксплуатирующей организации, собственнике опасного производственного объекта и/или сведений, указанных эксплуатирующей организацией в заявлении о регистрации опасного производственного объекта в государственном реестре',
        ],
      },
      {
        code: '63627163',
        text:
          'Где должны храниться оригиналы декларации промышленной безопасности?',
        answers: [
          'В Федеральной службе по экологическому, технологическому и атомному надзору',
          'В организациях, эксплуатирующих опасные производственные объекты',
          'В органах местного самоуправления',
          'В МЧС России',
        ],
        correctAnswers: [
          'В организациях, эксплуатирующих опасные производственные объекты',
        ],
      },
      {
        code: '63627164',
        text: 'Кто должен разрабатывать Положение о производственном контроле?',
        answers: [
          'Только эксплуатирующая организация',
          'Только структурные подразделения эксплуатирующей организации',
          'Эксплуатирующая организация (обособленные подразделения юридического лица в случаях, предусмотренных положениями об обособленных подразделениях), индивидуальный предприниматель',
        ],
        correctAnswers: [
          'Эксплуатирующая организация (обособленные подразделения юридического лица в случаях, предусмотренных положениями об обособленных подразделениях), индивидуальный предприниматель',
        ],
      },
      {
        code: '63627165',
        text:
          'Какое определение соответствует понятию "авария", изложенному в Федеральном законе от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от установленного режима технологического процесса',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте, неконтролируемые взрыв и (или) выброс опасных веществ',
          'Контролируемое и (или) неконтролируемое горение, а также взрыв опасного производственного объекта',
          'Нарушение целостности или разрушение сооружений и технических устройств опасного производственного объекта при отсутствии взрыва либо выброса опасных веществ',
        ],
        correctAnswers: [
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте, неконтролируемые взрыв и (или) выброс опасных веществ',
        ],
      },
      {
        code: '63627166',
        text:
          'Какие требования не могут быть отнесены к лицензионным требованиям? Укажите все правильные ответы.',
        answers: [
          'Требования к конкретным видам и объему выпускаемой или планируемой к выпуску продукции',
          'Требования о наличии у соискателя лицензии и лицензиата работников, заключивших с ними трудовые договоры, имеющих профессиональное образование, обладающих соответствующей квалификацией и (или) имеющих стаж работы, необходимый для осуществления лицензируемого вида деятельности',
          'Требования о соблюдении законодательства Российской Федерации в соответствующей сфере деятельности в целом',
          'Требования о соответствии соискателя лицензии и лицензиата требованиям, установленным федеральными законами и касающимся организационно-правовой формы юридического лица, размера уставного капитала, отсутствия задолженности по обязательствам перед третьими лицами',
        ],
        correctAnswers: [
          'Требования к конкретным видам и объему выпускаемой или планируемой к выпуску продукции',
          'Требования о соблюдении законодательства Российской Федерации в соответствующей сфере деятельности в целом',
        ],
      },
      {
        code: '63627167',
        text:
          'В какой срок должен быть составлен акт технического расследования причин аварии?',
        answers: [
          'В течение 30 рабочих дней',
          'В течение 30 календарных дней',
          'В течение 20 рабочих дней',
          'Предельный срок не устанавливается',
        ],
        correctAnswers: ['В течение 30 календарных дней'],
      },
      {
        code: '63627168',
        text:
          'Что является грубым нарушением требований промышленной безопасности в соответствии с Кодексом Российской Федерации об административных правонарушениях?',
        answers: [
          'Нарушение требований промышленной безопасности, приведшее к возникновению непосредственной угрозы жизни или здоровью людей',
          'Нарушение требований промышленной безопасности, которое может привести к длительному простою оборудования',
          'Нарушение требований промышленной безопасности, которое может привести к остановке технологического процесса предприятия и, как следствие, вынужденным отпускам работников',
          'Нарушение требований промышленной безопасности, результатом которого может быть инцидент на опасном производственном объекте без возникновения угрозы жизни или здоровью работников',
        ],
        correctAnswers: [
          'Нарушение требований промышленной безопасности, приведшее к возникновению непосредственной угрозы жизни или здоровью людей',
        ],
      },
      {
        code: '63627169',
        text:
          'После выполнения каких мероприятий экспертная организация приступает к проведению экспертизы промышленной безопасности? Укажите все правильные ответы.',
        answers: [
          'Предоставления заказчиком необходимых для проведения экспертизы документов',
          'После проведения расчетных и аналитических процедур оценки и прогнозирования технического состояния технических устройств',
          'Проведения технического диагностирования технических устройств',
          'Предоставления образцов технических устройств либо обеспечения доступа экспертов к техническим устройствам, применяемым на опасном производственном объекте',
        ],
        correctAnswers: [
          'Предоставления заказчиком необходимых для проведения экспертизы документов',
          'Предоставления образцов технических устройств либо обеспечения доступа экспертов к техническим устройствам, применяемым на опасном производственном объекте',
        ],
      },
      {
        code: '63627170',
        text:
          'При какой численности работников организации, эксплуатирующей опасный производственный объект, функции лица, ответственного за осуществление производственного контроля, возлагаются на специально назначенного работника?',
        answers: [
          'Менее 150 человек',
          'От 150 до 500 человек',
          'Свыше 500 человек',
        ],
        correctAnswers: ['От 150 до 500 человек'],
      },
      {
        code: '63627171',
        text:
          'В какие сроки эксплуатирующая организация представляет в Ростехнадзор или его территориальные органы сведения об организации производственного контроля за соблюдением требований промышленной безопасности?',
        answers: [
          'Раз в полгода, не позднее 15 числа месяца, следующего за отчетным периодом',
          'Ежегодно, не позднее 1 февраля текущего года',
          'Ежегодно, до 1 апреля соответствующего календарного года',
          'Ежегодно, в течение 1 квартала текущего года',
        ],
        correctAnswers: [
          'Ежегодно, до 1 апреля соответствующего календарного года',
        ],
      },
      {
        code: '63627172',
        text:
          'Что входит в обязанности лица, осуществляющего строительство здания или сооружения, в соответствии с законодательством о градостроительной деятельности?',
        answers: [
          'Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства',
          'Наблюдение за производством работ, регистрация действий, противоречащих законодательству о градостроительной деятельности',
          'Только контроль за качеством применяемых строительных материалов',
          'Наблюдение за производством работ, своевременной доставкой строительных материалов и изделий',
        ],
        correctAnswers: [
          'Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства',
        ],
      },
      {
        code: '63627173',
        text:
          'Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?',
        answers: [
          'Специализированной сторонней организацией',
          'Территориальным органом Ростехнадзора',
          'Организацией, на объекте которой произошла авария',
          'Комиссией по техническому расследованию аварии',
        ],
        correctAnswers: ['Организацией, на объекте которой произошла авария'],
      },
      {
        code: '63627174',
        text:
          'На сколько классов опасности подразделяются опасные производственные объекты?',
        answers: ['На три', 'На четыре', 'На два', 'На пять'],
        correctAnswers: ['На четыре'],
      },
      {
        code: '63627175',
        text:
          'На какой срок заключается договор обязательного страхования гражданской ответственности за причинение вреда в результате аварии или инцидента на опасном производственном объекте?',
        answers: [
          'На срок не более одного года',
          'На срок не более шести месяцев',
          'На срок не менее чем один год',
          'На срок не менее чем девять месяцев',
        ],
        correctAnswers: ['На срок не менее чем один год'],
      },
      {
        code: '63627176',
        text:
          'Какие из перечисленных документов не вправе требовать лицензирующий орган у соискателей лицензий на эксплуатацию взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности?',
        answers: [
          'Данные документа о постановке соискателя лицензии на учет в налоговом органе',
          'Копии документов, перечень которых определяется положением о лицензировании конкретного вида деятельности и которые свидетельствуют о соответствии соискателя лицензии лицензионным требованиям',
          'Копии документов, свидетельствующие об отсутствии у юридического лица налоговой задолженности за предыдущий год',
          'Данные документа, подтверждающего факт внесения сведений о юридическом лице в единый государственный реестр юридических лиц',
        ],
        correctAnswers: [
          'Копии документов, свидетельствующие об отсутствии у юридического лица налоговой задолженности за предыдущий год',
        ],
      },
      {
        code: '63627177',
        text:
          'Какими нормативными правовыми актами устанавливаются требования к порядку осуществления федерального лицензионного контроля за соблюдением лицензионных требований при осуществлении деятельности по проведению экспертизы промышленной безопасности?',
        answers: [
          'Федеральным законом "О защите прав юридических лиц и индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля" и Федеральным законом "О лицензировании отдельных видов деятельности"',
          'Исключительно Федеральным законом "О лицензировании отдельных видов деятельности"',
          'Федеральным законом "О лицензировании отдельных видов деятельности" и Федеральным законом "О промышленной безопасности опасных производственных объектов"',
          'Исключительно постановлением Правительства Российской Федерации "О лицензировании деятельности по проведению экспертизы промышленной безопасности"',
        ],
        correctAnswers: [
          'Федеральным законом "О защите прав юридических лиц и индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля" и Федеральным законом "О лицензировании отдельных видов деятельности"',
        ],
      },
      {
        code: '63627178',
        text:
          'В каком случае для действующих опасных производственных объектов декларация промышленной безопасности не должна разрабатываться вновь?',
        answers: [
          'В случае истечения десяти лет со дня внесения в реестр деклараций промышленной безопасности последней декларации промышленной безопасности',
          'В случае увеличения на пять процентов количества опасных веществ, которые находятся или могут находиться на опасном производственном объекте',
          'В случае изменения требований промышленной безопасности или изменения технологического процесса',
          'По предписанию федерального органа исполнительной власти в области промышленной безопасности или его территориального органа в случае выявления несоответствия сведений, содержащихся в декларации промышленной безопасности, сведениям, полученным в ходе осуществления федерального государственного надзора в области промышленной безопасности',
        ],
        correctAnswers: [
          'В случае увеличения на пять процентов количества опасных веществ, которые находятся или могут находиться на опасном производственном объекте',
        ],
      },
      {
        code: '63627179',
        text:
          'В виде каких файлов должны формироваться электронные документы при подготовке отчета о производственном контроле?',
        answers: [
          'В формате XML',
          'В формате DIF',
          'В форматах JPEG, TIFF, BMP, PDF',
        ],
        correctAnswers: ['В формате XML'],
      },
      {
        code: '63627180',
        text:
          'Эксперты какой категории имеют право участвовать в проведении экспертизы промышленной безопасности опасных производственных объектов II класса опасности?',
        answers: [
          'Только первой',
          'Третьей и (или) второй',
          'Первой и (или) второй',
          'Только второй',
        ],
        correctAnswers: ['Первой и (или) второй'],
      },
      {
        code: '63627181',
        text:
          'В каком из перечисленных случаев при внесении изменений в государственный реестр объекту присваивается иной регистрационный номер?',
        answers: [
          'При внесении изменений в государственный реестр, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации',
          'При изменении количественного и качественного состава технического устройства опасного производственного объекта',
          'При изменении только качественного состава технического устройства опасного производственного объекта',
          'При изменении только количественного состава технического устройства опасного производственного объекта',
        ],
        correctAnswers: [
          'При внесении изменений в государственный реестр, связанных с исключением опасного производственного объекта в связи со сменой эксплуатирующей организации',
        ],
      },
    ],
  },
  1: {
    63632: [
      {
        code: '63632000',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от вида взрывозащиты',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63632001',
        text:
          'Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?',
        answers: [
          'Только включение светового и звукового сигналов при превышении предельно допустимых концентраций',
          'Только регистрацию всех случаев загазованности приборами',
          'Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63632002',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63632003',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
          'Начальник производственно-технического (технического) отдела организации',
          'Начальник производства',
        ],
        correctAnswers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
      },
      {
        code: '63632004',
        text: 'Какую функцию не должен обеспечивать кустовой склад хлора?',
        answers: [
          'Прием, хранение затаренного хлора с учетом повагонных поставок хлора по железной дороге',
          'Выполнение заявок потребителей на отправку хлора автомобильным транспортом',
          'Организацию сбора порожней тары и ее упорядоченный возврат организации-наполнителю',
          'Розлив хлора в контейнеры и баллоны',
        ],
        correctAnswers: ['Розлив хлора в контейнеры и баллоны'],
      },
      {
        code: '63632005',
        text:
          'В каком случае допускается перевозка сосудов с истекшим сроком технического освидетельствования, заполненных хлором?',
        answers: [
          'Если срок технического освидетельствования истек не более 3 месяцев назад',
          'При заполнении хлором ниже установленного предела',
          'В случае согласования с территориальным органом Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63632006',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 10 лет',
          'Не более 20 лет',
          'Не более 3 лет',
          'Не более 5 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63632007',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
          'Не менее 5 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63632008',
        text:
          'Какую суммарную погрешность измерения концентрации хлора должны иметь сигнализаторы хлора помещений, где обращается химический гипохлорит натрия?',
        answers: [
          'Не более ±25%',
          'Не более ±35%',
          'Не более ±15%',
          'Не более ±20%',
        ],
        correctAnswers: ['Не более ±25%'],
      },
      {
        code: '63632009',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза 2 года',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63632010',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?',
        answers: [
          'Не более 12 секунд',
          'Не более 300 секунд',
          'Не более 120 секунд',
          'Не более 400 секунд',
        ],
        correctAnswers: ['Не более 12 секунд'],
      },
      {
        code: '63632011',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ передает наряд-допуск после его закрытия?',
        answers: [
          'В ПАСС(Ф), аварийно-спасательную службу организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
        ],
      },
      {
        code: '63632012',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'С руководителями аварийно-спасательных служб',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'С руководителями службы производственного контроля',
          'С руководителями службы охраны труда и санитарными службами',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63632013',
        text:
          'Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?',
        answers: [
          'В местах пересечения железных и автомобильных дорог',
          'По наружным стенам зданий, не связанных с обращением кислот и щелочей',
          'Через вспомогательные помещения',
          'Через бытовые помещения',
        ],
        correctAnswers: ['В местах пересечения железных и автомобильных дорог'],
      },
      {
        code: '63632014',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63632015',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63632016',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с проектной документацией',
          'В соответствии с декларацией промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63632017',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63632018',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Технологическим руководителем организации',
          'Комиссией организации под председательством представителя проектной организации',
          'Лицом, утверждающим технологический регламент',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63632019',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На технологическую службу организации, производства, отделения, установки',
          'На организацию-разработчика процесса',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63632020',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Нарушение энергообеспечения',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Рациональный подбор взаимодействующих компонентов, исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63632021',
        text:
          'Какие электролизеры не допускается использовать для производства химического гипохлорита натрия, применяемого для целей водоподготовки?',
        answers: [
          'Диафрагменные электролизеры',
          'Ртутные электролизеры',
          'Мембранные электролизеры',
          'Допускается использовать любые электролизеры',
        ],
        correctAnswers: ['Ртутные электролизеры'],
      },
      {
        code: '63632022',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63632023',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Непосредственный руководитель работ и лица ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63632024',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Только об организации, выполнявшей проектную документацию',
          'Только об организации, выполнявшей функции генерального проектировщика',
          'Только об организации - разработчике технологической части проектной документации',
          'Только об организации - разработчике технологического процесса',
          'О всех перечисленных организациях',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63632025',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
          'Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63632026',
        text:
          'Каким должно быть расчетное давление сосудов, содержащих жидкий хлор?',
        answers: [
          'Не менее 1,6 МПа',
          'Не менее 1,5 МПа',
          'Не менее 1,4 МПа',
          'Менее 1,2 МПа',
        ],
        correctAnswers: ['Не менее 1,6 МПа'],
      },
      {
        code: '63632027',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Технологические схемы производства',
          'Характеристика производимой продукции',
          'Возможные инциденты в работе и способы их ликвидации',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63632028',
        text: 'Какой должна быть вместимость расходного склада хлора?',
        answers: [
          'Не более 10-суточного потребления его организацией',
          'Не более 15-суточного потребления его организацией',
          'Не более 25-суточного потребления его организацией',
          'Не более 20-суточного потребления его организацией',
        ],
        correctAnswers: ['Не более 15-суточного потребления его организацией'],
      },
      {
        code: '63632029',
        text:
          'До какого остаточного давления отводят абгазы из цистерны в абгазную систему после завершения слива хлора?',
        answers: [
          'Не менее 0,07 МПа (0,7 кгс/см2)',
          'Не менее 0,01 МПа (0,1 кгс/см2)',
          'Не менее 0,05 МПа (0,5 кгс/см2)',
        ],
        correctAnswers: ['Не менее 0,05 МПа (0,5 кгс/см2)'],
      },
      {
        code: '63632030',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'На факельную установку',
          'В специальные контейнеры',
          'В закрытые системы для дальнейшей утилизации',
          'В централизованную систему водоотведения',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63632031',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Технический руководитель организации',
          'Разработчик проекта',
          'Представитель организации-изготовителя систем противоаварийной автоматической защиты',
          'Разработчик технологического процесса',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63632032',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований Правил противопожарного режима',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63632033',
        text:
          'Чему равна объемная доля водорода в хлоре, находящемся в общем хлорном коллекторе, при электролизе ртутным методом?',
        answers: [
          'Не более 1,5%',
          'Не более 1%',
          'Не более 2%',
          'Не более 0,5%',
        ],
        correctAnswers: ['Не более 1,5%'],
      },
      {
        code: '63632034',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом руководителя организации',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом главного инженера организации (технического директора, директора по производству)',
          'Приказом комиссии организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63632035',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63632036',
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ['Один год', '6 месяцев', '3 месяца', '1 месяц'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63632037',
        text:
          'Размещение какого количества одновременно отправляемых или поступающих вагонов-цистерн с жидким хлором должен обеспечивать отстойный путь (тупик)?',
        answers: [
          'Всей партии, но не более 10 штук',
          'Всей партии, но не более 14 штук',
          'Половины партии, при этом не более 5 штук',
          'Половины партии, при этом не более 7 штук',
        ],
        correctAnswers: ['Всей партии, но не более 10 штук'],
      },
      {
        code: '63632038',
        text:
          'Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?',
        answers: [
          'Организация-изготовитель',
          'Разработчик документации',
          'Орган по сертификации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63632039',
        text:
          'На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?',
        answers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
          'На алгоритмах, разработанных по сценариям всех возможных аварий и их развития',
          'На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
        ],
        correctAnswers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
        ],
      },
      {
        code: '63632040',
        text:
          'Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?',
        answers: [
          'Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива',
          'Тремя предохранительными клапанами',
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
          'Двумя штуцерами с вентилем для съемного манометра',
        ],
        correctAnswers: [
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
        ],
      },
      {
        code: '63632041',
        text:
          'Для каких складов неорганических жидких кислот требуется расчет радиуса опасной зоны?',
        answers: [
          'Для складов, не оборудованных поддонами',
          'Для складов, в которых осуществляется хранение неорганических жидких кислот, пары которых обладают остронаправленным механизмом действия',
          'Для складов, где хранятся концентрированные кислоты, при розливе которых может образоваться облако в результате мгновенного (менее 1 - 3 мин.) перехода в атмосферу части кислот (первичное облако)',
          'Для всех складов неорганических жидких кислот',
        ],
        correctAnswers: [
          'Для складов, где хранятся концентрированные кислоты, при розливе которых может образоваться облако в результате мгновенного (менее 1 - 3 мин.) перехода в атмосферу части кислот (первичное облако)',
        ],
      },
      {
        code: '63632042',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63632043',
        text:
          'Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?',
        answers: [
          'Проектом',
          'Технологическим регламентом',
          'Эксплуатационной документацией',
          'Правилами безопасности производств хлора и хлорсодержащих сред',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63632044',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63632045',
        text:
          'При какой концентрации хлора в воздухе допускается применение средств индивидуальной защиты органов дыхания фильтрующих?',
        answers: [
          'Не более 0,5% по объему',
          'Не более 2,5% по объему',
          'Не более 1,5% по объему',
          'Не более 1,0% по объему',
        ],
        correctAnswers: ['Не более 0,5% по объему'],
      },
      {
        code: '63632046',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Аварийно-спасательной службе организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63632047',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63632048',
        text:
          'Кто осуществляет окраску тары для жидкого хлора и нанесение на ней надписей в процессе эксплуатации?',
        answers: [
          'Организация-изготовитель',
          'Специализированная организация',
          'Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара',
          'Экспертная организация',
        ],
        correctAnswers: [
          'Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара',
        ],
      },
      {
        code: '63632049',
        text:
          'Какое значение не должна превышать концентрация солей аммония в питающем рассоле и в воде, подаваемой на холодильники смешения для охлаждения хлора?',
        answers: [
          '5 мг/дм³ (в пересчете на аммиак)',
          '20 мг/дм³ (в пересчете на аммиак)',
          '10 мг/дм³ (в пересчете на аммиак)',
          '15 мг/дм³ (в пересчете на аммиак)',
        ],
        correctAnswers: ['10 мг/дм³ (в пересчете на аммиак)'],
      },
      {
        code: '63632050',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Ростехнадзором',
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Эксплуатирующими организациями',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63632051',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63632052',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Иметь документы о прохождении аттестации',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63632053',
        text:
          'На каком уровне сигнализаторы хлора должны иметь избирательность по хлору в присутствии сопутствующих компонентов?',
        answers: ['0,5 ПДК', '1 ПДК', '2 ПДК', '1,5 ПДК'],
        correctAnswers: ['0,5 ПДК'],
      },
      {
        code: '63632054',
        text:
          'В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок?  Выберите два правильных варианта ответа.',
        answers: [
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если в тексте технологического регламента обнаружены подчистки и поправки',
          'Если такое решение принято технологической службой организации, производства, отделения, установки',
          'При сроке освоения производства более года',
        ],
        correctAnswers: [
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
        ],
      },
      {
        code: '63632055',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Требования к обозначению определяются при разработке плана мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63632056',
        text:
          'В каких случаях на трубопроводах следует применять арматуру под приварку?',
        answers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температуре, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температуре, равной температуре кипения при регламентированном давлении',
        ],
        correctAnswers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температуре, равной температуре кипения при регламентированном давлении',
        ],
      },
      {
        code: '63632057',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
          'В соответствии со сценариями возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий и их развития',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63632058',
        text:
          'Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?',
        answers: [
          'Не менее 1 м с каждой его стороны',
          'Не менее 2 м с каждой его стороны',
          'Не менее 10 м с каждой его стороны',
          'Не менее 5 м с каждой его стороны',
        ],
        correctAnswers: ['Не менее 2 м с каждой его стороны'],
      },
      {
        code: '63632059',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О наличии медицинских противопоказаний к работе',
          'О самочувствии',
          'О знании правил оказания первой помощи',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63632060',
        text:
          'Каким образом допускается налив гипохлорита натрия в транспортные емкости?',
        answers: [
          'Только методом передавливания сжатым воздухом (азотом)',
          'Только самотеком из напорных емкостей',
          'Только при помощи насосов, предназначенных для перекачки гипохлорита натрия',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63632061',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже 1 раза в 8 лет',
          'Не реже 1 раза в 4 года',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 10 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 4 года'],
      },
      {
        code: '63632062',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Газоопасные работы", "Газ"',
          '"Опасно для жизни!", "Внимание!"',
          '"Работают люди!", "Опасно для жизни!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63632063',
        text:
          'На сколько должно быть произведено заполнение всех видов тары гипохлоритом натрия (калия)?',
        answers: [
          'Не более чем на 90% объема тары',
          'Не более чем на 80% объема тары',
          'Не более чем на 95% объема тары',
          'Не более чем на 85% объема тары',
        ],
        correctAnswers: ['Не более чем на 90% объема тары'],
      },
      {
        code: '63632064',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63632065',
        text:
          'Каким способом допускается проводить передавливание жидкого хлора?',
        answers: [
          'Только нагнетанием в опорожняемую емкость сухого сжатого воздуха (азота) или паров газообразного хлора из другого сосуда или термокомпрессора',
          'Только за счет собственного давления паров хлора в опорожняемом сосуде и отбора хлоргаза из наполняемого сосуда',
          'Только комбинированным способом',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63632066',
        text:
          'Какой должна быть величина прибавки на коррозию для толщины стенки трубопровода хлора дополнительно к расчетной?',
        answers: [
          'Не менее 0,7 мм',
          'Не менее 0,5 мм',
          'Не менее 0,1 мм',
          'Не менее 1 мм',
        ],
        correctAnswers: ['Не менее 1 мм'],
      },
      {
        code: '63632067',
        text:
          'Какое емкостное оборудование для использования кислот и (или) щелочей должно быть оснащено поддонами?',
        answers: [
          'Объемом 700 л и более',
          'Объемом 500 л и более',
          'Объемом 1000 л и более',
          'Любое емкостное оборудование для использования кислот и (или) щелочей',
        ],
        correctAnswers: ['Объемом 1000 л и более'],
      },
      {
        code: '63632068',
        text:
          'При каких условиях допускается включение электролизера проточного действия, подающего воздух на разбавление и отдувку водорода, при электрохимическом способе получения гипохлорита натрия?',
        answers: [
          'После промывки электролизера от катодных карбонатных отложений',
          'После кислотной промывки электролизера',
          'Только после включения вентилятора',
          'До заполнения электролитом',
        ],
        correctAnswers: ['Только после включения вентилятора'],
      },
      {
        code: '63632069',
        text:
          'Какая максимальная объемная доля кислорода в водороде устанавливается в общем коллекторе при электролизе диафрагменным методом?',
        answers: ['0,5%', '0,3%', '0,2%', '0,4%'],
        correctAnswers: ['0,5%'],
      },
      {
        code: '63632070',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее одного',
          'Не менее двух',
          'Определяется ответственным за проведение газоопасных работ',
          'Не менее четырех',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63632071',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63632072',
        text:
          'Каким документом обосновывается минимально необходимое количество жидкого хлора, которое должно храниться в организациях?',
        answers: [
          'Проектом',
          'Технологическим регламентом',
          'Федеральными нормами и правилами',
          'Стандартом организации',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63632073',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63632074',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель структурного подразделения или лицо, его замещающее',
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63632075',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Установка заглушек',
          'Контроль сплошности изоляции и толщины стенок',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63632076',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63632077',
        text:
          'Чему равно значение предельно допустимой концентрации хлора в воздухе рабочей зоны производственного помещения?',
        answers: ['1,0 мг/м3', '5,0 мг/м3', '10 мг/м3', '15 мг/м3'],
        correctAnswers: ['1,0 мг/м3'],
      },
      {
        code: '63632078',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63632079',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['40 °C', '45 °C', '50 °C', '55 °C'],
        correctAnswers: ['50 °C'],
      },
      {
        code: '63632080',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: [
          'На одну выше',
          'I категории',
          'II категории',
          'III категории',
        ],
        correctAnswers: ['На одну выше'],
      },
      {
        code: '63632081',
        text:
          'Какое действие необходимо произвести при выбросе товарного гипохлорита натрия (калия) в распыленном виде (брызги, тонкие струйки)?',
        answers: [
          'Обработка места выброса песком',
          'Орошение места выброса распыленной водой',
          'Обработка места выброса гипосульфитом натрия',
        ],
        correctAnswers: ['Орошение места выброса распыленной водой'],
      },
      {
        code: '63632082',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63632083',
        text:
          'Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?',
        answers: [
          'В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание',
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
          'Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования',
          'Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание',
        ],
        correctAnswers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
        ],
      },
      {
        code: '63632084',
        text:
          'Какое из перечисленных требований при производстве жидкого хлора указано неверно?',
        answers: [
          'Давление воздуха, подаваемого в систему сжижения, должно превышать давление подаваемого хлоргаза не менее чем на 0,1 МПа (1 кгс/см2)',
          'Объемная доля водорода в абгазах конденсации должна быть не более 4%',
          'Воздух (азот) для разбавления абгазов, поступающих на вторую стадию сжижения хлора, необходимо осушать',
          'Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений',
        ],
        correctAnswers: [
          'Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений',
        ],
      },
      {
        code: '63632085',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63632086',
        text:
          'С какой периодичностью следует проводить анализ на содержание паров ртути в воздухе рабочей зоны в помещениях, где работают с ртутью, при электролизе ртутным методом?',
        answers: [
          'Ежесменно',
          'Трижды в смену',
          'Еженедельно',
          'Дважды в смену',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63632087',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы.',
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63632088',
        text:
          'Каким образом устанавливаются минимально допустимые расстояния от складов кислот и щелочей до взрывоопасных объектов?',
        answers: [
          'С учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, они должны обеспечивать устойчивость зданий складов к воздействию данных факторов',
          'В соответствии с требованиями Правил безопасности химически опасных производственных объектов',
          'По нормам пожарной безопасности',
          'В соответствии с требованиями строительных норм и правил и с учетом расчетного радиуса опасной зоны',
        ],
        correctAnswers: [
          'С учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, они должны обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
      },
      {
        code: '63632089',
        text:
          'Кто определяет давление срабатывания мембраны и открытия предохранительного клапана, его пропускную способность, для вагонов-цистерн и контейнеров-цистерн?',
        answers: [
          'Владелец оборудования',
          'Грузоперевозчик',
          'Разработчик оборудования',
        ],
        correctAnswers: ['Разработчик оборудования'],
      },
      {
        code: '63632090',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63632091',
        text:
          'В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?',
        answers: [
          'Объемом до 1000 кг/год',
          'Объемом до 1500 кг/год',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Объемом до 1000 кг/год'],
      },
      {
        code: '63632092',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63632093',
        text:
          'На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Выберите два правильных варианта ответа.',
        answers: [
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО IV класса опасности',
          'На объектах ХОПО II класса опасности',
          'На объектах ХОПО I класса опасности',
          'На всех объектах ХОПО',
        ],
        correctAnswers: [
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО IV класса опасности',
        ],
      },
      {
        code: '63632094',
        text:
          'Каким принимается радиус опасной зоны для складов жидкого хлора?',
        answers: [
          'Радиус опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией',
          'Радиус опасной зоны принимается равным 300 м',
          'Радиус опасной зоны определяется проектом',
          'Радиус опасной зоны принимается равным 450 м',
        ],
        correctAnswers: [
          'Радиус опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией',
        ],
      },
      {
        code: '63632095',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более одного года',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63632096',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Не допускается',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63632097',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Начальнику цеха',
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
          'Пожарной службе эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63632098',
        text:
          'Что из перечисленного допускается использовать в качестве расходной емкости с отбором газообразного хлора на потребление?',
        answers: [
          'Железнодорожные вагоны-цистерны',
          'Резервуары',
          'Контейнеры-цистерны',
          'Все перечисленное',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63632099',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На сети водоснабжения и канализации',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63632100',
        text:
          'Какое из перечисленных требований к трубопроводам, транспортирующим кислоты и щелочи, указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Для трубопроводов кислот и щелочей следует предусматривать возможность их продувки любым инертным газом',
          'При транспортировании кислот и щелочей по трубопроводам для предотвращения застывания (кристаллизации) следует предусматривать прокладку наружных трубопроводов с теплоспутниками и теплоизоляцией трубопроводов',
          'При прокладке трубопроводов кислот и щелочей следует обеспечивать их наименьшую протяженность, исключать провисание и образование застойных зон',
          'К трубопроводам, транспортирующим кислоты и щелочи, не должны крепиться другие трубопроводы (кроме теплоспутников, закрепляемых с помощью приварки)',
        ],
        correctAnswers: [
          'При транспортировании кислот и щелочей по трубопроводам для предотвращения застывания (кристаллизации) следует предусматривать прокладку наружных трубопроводов с теплоспутниками и теплоизоляцией трубопроводов',
          'При прокладке трубопроводов кислот и щелочей следует обеспечивать их наименьшую протяженность, исключать провисание и образование застойных зон',
        ],
      },
      {
        code: '63632101',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'Только в соответствии с проектной документацией',
          'Только в соответствии с рабочей документацией',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63632102',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура в соответствии с проектной документацией',
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с тканевым уплотнением в затворе',
          'Арматура с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63632103',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: не в фазе!"',
          '"Не включать: работа на линии!"',
          '"Не включать: работают люди!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63632104',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63632105',
        text:
          'Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?',
        answers: [
          'Произвести запись в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен) и наряде-допуске на проведение газоопасных работ',
          'Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ',
          'Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ',
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
        correctAnswers: [
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
      },
      {
        code: '63632106',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Не моложе 18 лет',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Прошедшие обучение приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63632107',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '3 месяца со дня его окончания',
          '6 месяцев со дня его окончания',
          '3 года со дня его окончания',
          '1 год со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63632108',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
          'В течение 8 часов',
          'Время устанавливается в проектной документации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63632109',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63632110',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Техническими характеристиками применяемых насосов и компрессоров',
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Критическими параметрами технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63632111',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 24 часов',
          'В течение 8 часов',
          'Время устанавливается в проектной документации',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63632112',
        text:
          'В течение какого времени наряд-допуск на проведение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной дневной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной дневной смены'],
      },
      {
        code: '63632113',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Только испытание на прочность пробным давлением',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63632114',
        text:
          'Какое количество жидких кислот и (или) щелочей может единовременно находиться на территории предприятия или организации-потребителя?',
        answers: [
          'Не более 40-суточной потребности организации',
          'Количество должно быть минимальным для обеспечения производственного цикла, и обосновано в документации на химически опасных производственных объектах',
          'Не более 60-суточной потребности организации',
          'Количество должно быть определено техническим руководителем организации, но быть не более 30-суточной потребности организации',
        ],
        correctAnswers: [
          'Количество должно быть минимальным для обеспечения производственного цикла, и обосновано в документации на химически опасных производственных объектах',
        ],
      },
      {
        code: '63632115',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63632116',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63632117',
        text:
          'В каком документе организация, эксплуатирующая химически опасный производственный объект I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?',
        answers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'В Положении о производственном контроле',
          'В плане по локализации аварийных ситуаций',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63632118',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К I группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63632119',
        text:
          'Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?',
        answers: [
          'Разовые (опытные) технологические регламенты',
          'Временные технологические регламенты',
          'Постоянные технологические регламенты',
          'Лабораторные технологические регламенты',
        ],
        correctAnswers: ['Разовые (опытные) технологические регламенты'],
      },
      {
        code: '63632120',
        text:
          'Какие требования предъявляются к трубопроводам для транспортировки кислот и щелочей, прокладываемым по эстакадам? Выберите два правильных варианта ответа.',
        answers: [
          'Трубопроводы для транспортировки кислот и щелочей должны быть защищены от возможных ударов со стороны транспортных средств',
          'Трубопроводы для транспортировки кислот и щелочей должны быть защищены от падающих предметов',
          'Трубопроводы для транспортировки кислот и щелочей должны быть изготовлены из композиционных материалов',
          'При многоярусной прокладке трубопроводы кислот и щелочей следует располагать на самых верхних ярусах',
        ],
        correctAnswers: [
          'Трубопроводы для транспортировки кислот и щелочей должны быть защищены от возможных ударов со стороны транспортных средств',
          'Трубопроводы для транспортировки кислот и щелочей должны быть защищены от падающих предметов',
        ],
      },
      {
        code: '63632121',
        text:
          'Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63632122',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Испытание трубопровода на прочность и плотность',
          'Осуществляется все перечисленное',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63632123',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63632124',
        text:
          'Каким образом необходимо испытывать на плотность трубопроводы гипохлорита натрия (калия)?',
        answers: [
          'Водой или сжатым воздухом (азотом) давлением, равным 1,75 рабочего давления, но не менее 0,8 МПа (8 кгс/см2)',
          'Водой или сжатым воздухом (азотом) давлением, равным 1,25 рабочего давления, но не менее 0,2 МПа (2 кгс/см2)',
          'Водой или сжатым воздухом (азотом) давлением, равным 1,5 рабочего давления, но не менее 0,4 МПа (4 кгс/см2)',
          'Водой или сжатым воздухом (азотом) давлением, равным 1,15 рабочего давления, но не менее 0,1 МПа (1 кгс/см2)',
        ],
        correctAnswers: [
          'Водой или сжатым воздухом (азотом) давлением, равным 1,25 рабочего давления, но не менее 0,2 МПа (2 кгс/см2)',
        ],
      },
      {
        code: '63632125',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63632126',
        text:
          'Какая устанавливается объемная доля водорода в газовой фазе в газовом сепараторе, емкостях хранения готового гипохлорита натрия или гипохлорита калия (накопителях) и трубопроводах, транспортирующих гипохлорит натрия (калия) от емкостей хранения готового продукта до мест потребления продукта?',
        answers: [
          'Не более 1%',
          'Не более 1,5%',
          'Не более 2%',
          'Не более 2,5%',
        ],
        correctAnswers: ['Не более 1%'],
      },
      {
        code: '63632127',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63632128',
        text:
          'Какое давление водорода необходимо поддерживать в разлагателях амальгамы при электролизе ртутным методом?',
        answers: [
          'Не менее 50 - 100 Па (5 - 10 мм водяного столба)',
          'Не менее 150 - 200 Па (15 - 20 мм водяного столба)',
          'Не менее 200 - 250 Па (20 - 25 мм водяного столба)',
          'Не менее 100 - 150 Па (10 - 15 мм водяного столба)',
        ],
        correctAnswers: ['Не менее 100 - 150 Па (10 - 15 мм водяного столба)'],
      },
      {
        code: '63632129',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Промывка - водой, продувка - сжатым воздухом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63632130',
        text:
          'Проведение каких работ является обязательным при постановке вагона-цистерны на пункт слива-налива?',
        answers: [
          'Проверка наличия знаков опасности, надписей и трафаретов',
          'Проверка работоспособности крепительных устройств крышки люка',
          'Удаление загрязнений с наружной поверхности цистерны',
          'Заземление и закрепление тормозными башмаками с обеих сторон',
        ],
        correctAnswers: [
          'Заземление и закрепление тормозными башмаками с обеих сторон',
        ],
      },
      {
        code: '63632131',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63632132',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В паспортах оборудования и трубопроводной арматуры',
          'В техническом задании на изготовление оборудования',
          'В технологическом регламенте',
          'В проектной документации',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63632133',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63632134',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63632135',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С газоспасательной службой и службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63632136',
        text:
          'Какая устанавливается максимальная норма налива жидкого хлора в емкость (резервуар, танк, сборник, вагон-цистерна, контейнер-цистерна)?',
        answers: ['1,25 кг/дм3', '1 кг/дм3', '2,15 кг/дм3', '1,75 кг/дм3'],
        correctAnswers: ['1,25 кг/дм3'],
      },
      {
        code: '63632137',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63632138',
        text:
          'Чем следует продуть водородные коллекторы до остаточного содержания кислорода в отходящих газах не более 2% объемных перед пуском электролизеров?',
        answers: ['Азотом', 'Паром', 'Воздухом', 'Любым инертным газом'],
        correctAnswers: ['Азотом'],
      },
      {
        code: '63632139',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Только непрерывный контроль состояния воздушной среды',
          'Только наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
          'Только наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63632140',
        text:
          'Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?',
        answers: [
          'Допускать к самостоятельной работе не ранее, чем через 6 месяцев после стажировки на объекте',
          'Иметь специализированные центры обучения и подготовки для производственного персонала',
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
          'Иметь компьютерные тренажеры, включающие приближенные к реальным динамические модели процессов и средств управления',
        ],
        correctAnswers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
      },
      {
        code: '63632141',
        text:
          'В каком положении должен находиться баллон (без сифона) при отборе газообразного хлора?',
        answers: [
          'Баллон должен находиться в вертикальном или наклонном положении',
          'Баллон должен находиться только в наклонном положении вентилем вниз',
          'Баллон должен находиться только в вертикальном положении',
          'Баллон должен находиться только в горизонтальном положении',
        ],
        correctAnswers: [
          'Баллон должен находиться в вертикальном или наклонном положении',
        ],
      },
      {
        code: '63632142',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 10 минут',
          'Через 5 минут',
          'Через 60 минут',
          'Через 30 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63632143',
        text:
          'Какие производственные помещения, предназначенные для хранения неорганических жидких кислот и (или) щелочей, допускается не оборудовать общеобменными вентиляционными системами?',
        answers: [
          'Помещения для хранения кислот и щелочей в резервуарах',
          'Помещения для хранения кислот и щелочей в таре (без постоянных рабочих мест), оборудованные световой сигнализацией',
          'Помещения, оборудованные световой и звуковой сигнализациями',
          'Помещения, оборудованные громкоговорящей и (или) телефонной связью',
        ],
        correctAnswers: [
          'Помещения для хранения кислот и щелочей в таре (без постоянных рабочих мест), оборудованные световой сигнализацией',
        ],
      },
      {
        code: '63632144',
        text:
          'Какой должна быть погрешность (точность измерения) газоанализаторов водорода?',
        answers: ['±0,2% объемных', '±1% объемных', '±0,5% объемных'],
        correctAnswers: ['±0,2% объемных'],
      },
      {
        code: '63632145',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63632146',
        text:
          'Какого показателя категорий взрывоопасности технологических блоков не существует?',
        answers: [
          'I категории',
          'II категории',
          'III категории',
          'IV категории',
        ],
        correctAnswers: ['IV категории'],
      },
      {
        code: '63632147',
        text:
          'Какое минимальное время пребывания под навесом заполненных хлором контейнеров или баллонов?',
        answers: [
          'Не менее одних суток',
          'Не менее трех суток',
          'Не менее двух суток',
          'Не менее семи суток',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63632148',
        text:
          'При достижении какой концентрации хлора должна включаться аварийная вентиляция, сблокированная с системой поглощения хлора, в помещениях, где обращается жидкий хлор?',
        answers: ['10 ПДК', '5 ПДК', '15 ПДК', '20 ПДК'],
        correctAnswers: ['20 ПДК'],
      },
      {
        code: '63632149',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63632150',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Только при появлении трещин, срыва или коррозионного износа резьбы',
          'Только в случае износа боковых граней головок болтов и гаек',
          'Только в случае изгиба болтов и шпилек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63632151',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, меры взрывобезопасности, показатели пожароопасности и токсичности',
          'Показатели взрыво- пожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63632152',
        text:
          'Куда допускается сброс неорганических жидких кислот и (или) щелочей от предохранительных клапанов?',
        answers: [
          'В окружающую среду',
          'В специальные емкости',
          'В рабочую зону',
          'Допускается во все перечисленные места в обоснованных случаях',
        ],
        correctAnswers: ['В специальные емкости'],
      },
      {
        code: '63632153',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования',
          'Только с разрешения представителя газоспасательной службы, и при обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63632154',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63632155',
        text:
          'Кто устанавливает назначенный срок службы для технологических трубопроводов?',
        answers: [
          'Разработчик документации',
          'Организация-изготовитель',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Орган по сертификации',
        ],
        correctAnswers: ['Разработчик документации'],
      },
      {
        code: '63632156',
        text:
          'Какое из перечисленных требований к электролизу мембранным методом указано неверно?',
        answers: [
          'Приемные емкости анолита и католита должны быть оборудованы дублированными системами контроля уровня',
          'В электролизерах должен быть обеспечен постоянный контроль концентрации хлорида натрия (калия) в анолите и концентрации гидроксида натрия (калия) в католите',
          'При отключении электролиза должна быть предусмотрена возможность включения продувки катодных и анодных пространств электролизера, хлорных и водородных коллекторов азотом',
          'Перед пуском электролизера должна быть проверена его целостность и электрическое сопротивление мембран',
        ],
        correctAnswers: [
          'Перед пуском электролизера должна быть проверена его целостность и электрическое сопротивление мембран',
        ],
      },
      {
        code: '63632157',
        text:
          'Какая объемная доля водорода в хлоре допускается в общем хлорном коллекторе при электролизе диафрагменным методом?',
        answers: [
          'Не более 1,5%',
          'Не более 0,5%',
          'Не более 2%',
          'Не более 1%',
        ],
        correctAnswers: ['Не более 0,5%'],
      },
      {
        code: '63632158',
        text:
          'Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?',
        answers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
          'Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями',
          'Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц',
          'Запрещено для защиты технологического оборудования использовать неметаллические покрытия',
        ],
        correctAnswers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
      },
      {
        code: '63632159',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63632160',
        text:
          'Кто осуществляет окраску вновь изготовленной транспортной тары для жидкого хлора и нанесение на нее надписей?',
        answers: [
          'Организация-изготовитель',
          'Экспертная организация',
          'Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63632161',
        text:
          'Какие из перечисленных вагонов-цистерн, контейнеров-цистерн, контейнеров (бочек) и баллонов допускается использовать для транспортировки жидкого хлора?',
        answers: [
          'На которых демонтированы сифонные трубки из баллонов',
          'На которых имеются механические повреждения',
          'На которых истек срок ревизии предохранительного клапана и мембраны для вагонов-цистерн и контейнеров-цистерн',
          'Которые исправны, предназначены для транспортировки любых продуктов',
        ],
        correctAnswers: [
          'На которых демонтированы сифонные трубки из баллонов',
        ],
      },
      {
        code: '63632162',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63632163',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63632164',
        text:
          'В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?',
        answers: [
          'В журнале ведения технологического процесса',
          'В наряде-допуске на проведение газоопасных работ',
          'В технологическом регламенте и производственных инструкциях по рабочим местам',
          'В специально разрабатываемой эксплуатирующей организацией инструкции',
        ],
        correctAnswers: ['В наряде-допуске на проведение газоопасных работ'],
      },
      {
        code: '63632165',
        text:
          'Чем следует оборудовать производственные помещения без постоянных рабочих мест согласно Правилам безопасности при производстве, хранении, транспортировании и применении хлора?',
        answers: [
          'Снаружи у входа в помещение необходимо предусматривать световую сигнализацию превышения уровня загазованности хлором в помещении',
          'При производстве ремонтных работ обогрев этих помещений должен быть осуществлен передвижными вентиляционно-отопительными устройствами',
          'Данные помещения следует оборудовать аварийной или общеобменными вентиляционными системами',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63632166',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 6',
          'Не менее 15',
          'Не менее 10',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63632167',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов',
          'Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах',
          'Руководства по эксплуатации технологического оборудования и их ксерокопии',
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63632168',
        text:
          'Какой системой вентиляции должны быть оснащены производственные помещения при производстве товарного гипохлорита натрия (калия)?',
        answers: [
          'Только вытяжной вентиляцией',
          'Приточно-вытяжной вентиляцией',
          'Только приточной вентиляцией',
        ],
        correctAnswers: ['Приточно-вытяжной вентиляцией'],
      },
      {
        code: '63632169',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63632170',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?',
        answers: [
          'Должно быть организовано управление только по месту',
          'Должно быть организовано управление только дистанционно (из безопасного места)',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63632171',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63632172',
        text:
          'При каком давлении следует проверять на герметичность трубопроводы и арматуру для кислот и щелочей перед пуском в эксплуатацию?',
        answers: [
          'При условном давлении',
          'При пробном давления',
          'При рабочем давлении',
          'При расчетном давлении',
        ],
        correctAnswers: ['При рабочем давлении'],
      },
      {
        code: '63632173',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В проектной документации',
          'В технологическом регламенте',
          'В техническом регламенте',
          'В руководствах по безопасности',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63632174',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63632175',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63632176',
        text:
          'Каким должно быть сопротивление изоляции электроизолирующих устройств (вставок, изоляторов, подвесок) электролизеров?',
        answers: [
          'Не менее 0,5 МОм',
          'Не менее 0,4 МОм',
          'Не менее 0,3 МОм',
          'Не менее 0,1 МОм',
        ],
        correctAnswers: ['Не менее 0,5 МОм'],
      },
      {
        code: '63632177',
        text:
          'Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Входящие в состав проектной документации или пусковые',
          'Периодически пересматриваемые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63632178',
        text:
          'При какой минимальной температуре окружающей среды может осуществляться хранение электролитического гипохлорита натрия (калия) в расходных баках (накопителях)?',
        answers: ['3°С', '5°С', '7°С', '0°С'],
        correctAnswers: ['5°С'],
      },
      {
        code: '63632179',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для новых в данной организации производств',
          'Для производств с новой технологией',
          'Все ответы неверны',
          'Для всех перечисленных производств',
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63632180',
        text:
          'С какой целью склады хлора оборудуются сплошным глухим ограждением высотой не менее двух метров?',
        answers: [
          'Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада',
          'Для исключения случаев хищения жидкого хлора',
          'Для защиты территории склада от ветра',
        ],
        correctAnswers: [
          'Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада',
        ],
      },
      {
        code: '63632181',
        text:
          'Как следует обезопасить место розлива гипохлорита натрия по окончании ликвидации аварии?',
        answers: [
          'Промыть большим количеством воды',
          'Засыпать песком',
          'Обработать сорбентом',
          'Залить большим количеством пенного раствора',
        ],
        correctAnswers: ['Промыть большим количеством воды'],
      },
      {
        code: '63632182',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63632183',
        text:
          'Какое из перечисленных требований при отборе хлора из контейнеров (баллонов) указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Отбор хлора из контейнера следует осуществлять при горизонтальном его положении. Вентили должны быть расположены друг над другом, при этом верхний вентиль через сифон сообщается с жидкой фазой, а нижний вентиль - с газовой фазой',
          'Отбор жидкого хлора из баллонов и контейнеров необходимо осуществлять за счет собственного давления хлора в таре',
          'При использовании контейнеров допускается передавливание хлором или сухим воздухом (азотом) при давлении не более 1,2 МПа (12 кгс/см2)',
          'Допускается отбор жидкого хлора одновременно из двух и более сосудов',
        ],
        correctAnswers: [
          'Отбор жидкого хлора из баллонов и контейнеров необходимо осуществлять за счет собственного давления хлора в таре',
          'При использовании контейнеров допускается передавливание хлором или сухим воздухом (азотом) при давлении не более 1,2 МПа (12 кгс/см2)',
        ],
      },
      {
        code: '63632184',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Главным инженером эксплуатирующей организации',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63632185',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63632186',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63632187',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?',
        answers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
          'Не менее 5 независимых датчиков с раздельными точками отбора',
          'Не менее 3 независимых датчиков с раздельными точками отбора',
          'Правилами безопасности химически опасных производственных объектов не регламентируется',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63632188',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63632189',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63632190',
        text:
          'Какие из перечисленных складов жидкого хлора предназначены для хранения его в контейнерах-цистернах, контейнерах, баллонах в количествах, необходимых для текущих нужд организации в период между поставками?',
        answers: [
          'Расходные склады',
          'Кустовые склады',
          'Прицеховые склады',
          'Базисные склады',
        ],
        correctAnswers: ['Расходные склады'],
      },
      {
        code: '63632191',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
          'Инспектор территориального органа Ростехнадзора',
          'Комиссия эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63632192',
        text:
          'На основании какой документации следует производить приемку и опорожнение вагонов-цистерн и контейнеров-цистерн с жидким хлором?',
        answers: [
          'Инструкции, утвержденной техническим руководителем принимающей организации',
          'Инструкции, утвержденной техническим руководителем отправляющей организации',
          'Документации, составленной организацией-разработчиком',
          'Правил безопасности производств хлора и хлорсодержащих сред',
        ],
        correctAnswers: [
          'Инструкции, утвержденной техническим руководителем принимающей организации',
        ],
      },
      {
        code: '63632193',
        text:
          'Какое из перечисленных требований к электролизу соляной кислоты указано верно?',
        answers: [
          'В общем хлорном коллекторе объемная доля водорода в хлоре не должна превышать 0,5%',
          'Объемная доля хлора в водороде в общем коллекторе не должна превышать 1,5%',
          'После промывки объемная доля водорода должна быть не менее 99,5% при содержании хлора не более 1 мг/м³',
        ],
        correctAnswers: [
          'После промывки объемная доля водорода должна быть не менее 99,5% при содержании хлора не более 1 мг/м³',
        ],
      },
      {
        code: '63632194',
        text:
          'Какие требования предъявляются к размещению сосудов с хлором на складах при вертикальной укладке?',
        answers: [
          'У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечивать свободный доступ к запорным вентилям',
          'У стен следует размещать не более 3 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 3 ряда, необходимо исключить свободный доступ к запорным вентилям',
          'У стен следует размещать не более 4 рядов баллонов и 3 рядов контейнеров, размещение сосудов в проходах запрещено',
          'У стен следует размещать не более 2 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 3 ряда, необходимо исключить свободный доступ к запорным вентилям',
        ],
        correctAnswers: [
          'У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечивать свободный доступ к запорным вентилям',
        ],
      },
      {
        code: '63632195',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
          'До окончания работ на конкретном месте',
          'В течение одной смены',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63632196',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система водяного отопления',
          'Система парового отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63632197',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63632198',
        text:
          'Какие требования устанавливаются к наружной поверхности трубопроводов, предназначенных для транспортировки жидкого и газообразного хлора?',
        answers: [
          'Трубопроводы должны быть окрашены в желтый цвет',
          'Трубопроводы должны иметь антикоррозионное покрытие, опознавательную окраску, предупреждающие знаки и маркировочные щитки',
          'Трубопроводы должны иметь гладкую поверхность, необходимую окраску',
          'На трубопроводе должна быть нанесена предупредительная надпись "Осторожно, яд!"',
        ],
        correctAnswers: [
          'Трубопроводы должны иметь антикоррозионное покрытие, опознавательную окраску, предупреждающие знаки и маркировочные щитки',
        ],
      },
      {
        code: '63632199',
        text:
          'В каком случае допускается отключение серий диафрагменных электролизеров?',
        answers: [
          'По решению руководителя эксплуатирующей организации',
          'В случаях, предусмотренных Правилами безопасности при производстве, хранении, транспортировании и применении хлора',
          'В случаях, предусмотренных технологическим регламентом и планами мероприятий по локализации и ликвидации последствий аварий на опасном производственном объекте',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случаях, предусмотренных технологическим регламентом и планами мероприятий по локализации и ликвидации последствий аварий на опасном производственном объекте',
        ],
      },
      {
        code: '63632200',
        text:
          'Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63632201',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63632202',
        text:
          'Какие из перечисленных трубопроводов хлора должны быть выполнены из хладостойких марок стали?',
        answers: [
          'Только трубопроводы жидкого хлора, имеющие рабочую температуру минус 40 °С',
          'Тролько наружные трубопроводы хлора, размещаемые в климатических районах с расчетной минимальной температурой ниже минус 40 °С',
          'Только трубопроводы жидкого хлора, имеющие рабочую температуру минус 70 °С',
          'Все перечисленные трубопроводы',
        ],
        correctAnswers: ['Все перечисленные трубопроводы'],
      },
      {
        code: '63632203',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией - разработчиком процесса',
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Специализированной сторонней организацией',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63632204',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта совместно с работниками аварийно-спасательных подразделений',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63632205',
        text:
          'Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?',
        answers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
          'Представителем Ростехнадзора',
          'Лицом, ответственным за осуществление производственного контроля',
          'Лицом, ответственным за подготовку газоопасных работ',
        ],
        correctAnswers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
        ],
      },
      {
        code: '63632206',
        text:
          'При каких остановах серии электролизеров необходимо проводить электрическое разъединение конечных шин серии электролизеров от шинопровода выпрямительного агрегата?',
        answers: [
          'Более 8 часов',
          'Более 12 часов',
          'Более 6 часов',
          'Более 10 часов',
          'При любых остановах',
        ],
        correctAnswers: ['Более 12 часов'],
      },
      {
        code: '63632207',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, проложенных по эстакадам',
          'На трубопроводах, идущих по стенам зданий',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63632208',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Только при деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63632209',
        text:
          'Какие из перечисленных величин устанавливает разработчик процесса при электролизе мембранным методом? Выберите два правильных варианта ответа.',
        answers: [
          'Величину максимального значения рабочего значения плотности тока',
          'Величину перепада давления между катодным и анодным пространствами',
          'Величины давления хлора и водорода в электролизере',
          'Величину параметра расхода питающего рассола в зависимости от типа электролизера',
        ],
        correctAnswers: [
          'Величину перепада давления между катодным и анодным пространствами',
          'Величины давления хлора и водорода в электролизере',
        ],
      },
      {
        code: '63632210',
        text: 'Каким должен быть радиус кривизны изгибов трубопровода хлора?',
        answers: [
          'Не менее трех диаметров трубы',
          'Не менее двух диаметров трубы',
          'Не менее четырех диаметров трубы',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63632211',
        text:
          'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63632212',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?',
        answers: [
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Не более 240 секунд',
          'Не более 300 секунд',
        ],
        correctAnswers: ['Не более 120 секунд'],
      },
      {
        code: '63632213',
        text:
          'Что из перечисленного допускается при эксплуатации систем контроля, управления, сигнализации и автоматики при производстве, хранении, транспортировании и применении хлора?',
        answers: [
          'Ручное деблокирование в системах автоматического управления технологическими процессами',
          'Применять для проверки исправности схем средства микропроцессорной техники и приборы с самодиагностикой',
          'Вводить импульсные трубки с хлором и водородом в помещение управления',
          'Ведение технологических процессов и работа оборудования с неисправными или отключенными системами контроля, управления, сигнализации и противоаварийной защиты',
        ],
        correctAnswers: [
          'Применять для проверки исправности схем средства микропроцессорной техники и приборы с самодиагностикой',
        ],
      },
      {
        code: '63632214',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63632215',
        text:
          'Какое принимается минимальное расчетное давление для трубопровода жидкого хлора?',
        answers: [
          '1,4 МПа (14 кгс/см2)',
          '1,6 МПа (16 кгс/см2)',
          '2 МПа (20 кгс/см2)',
          '1,8 МПа (18 кгс/см2)',
        ],
        correctAnswers: ['1,6 МПа (16 кгс/см2)'],
      },
      {
        code: '63632216',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63632217',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63632218',
        text:
          'Кто определяет способы опорожнения емкостей для хранения кислот и щелочей?',
        answers: [
          'Научно-исследовательская организация',
          'Разработчик документации на химически опасный производственный объект',
          'Техническая комиссия химически опасного производственного объекта',
          'Руководитель химически опасного производственного объекта',
        ],
        correctAnswers: [
          'Разработчик документации на химически опасный производственный объект',
        ],
      },
      {
        code: '63632219',
        text:
          'Какая установлена норма налива товарного гипохлорита натрия (калия) в емкость?',
        answers: [
          '95% объема тары',
          '85% объема тары',
          '90% объема тары',
          '100% объема тары',
        ],
        correctAnswers: ['90% объема тары'],
      },
      {
        code: '63632220',
        text:
          'Что следует применять для охлаждения контактных поверхностей передвижных шунтирующих устройств при производстве хлора методом электролиза?',
        answers: ['Обессоленную воду', 'Азот', 'Охлажденный воздух'],
        correctAnswers: ['Обессоленную воду'],
      },
      {
        code: '63632221',
        text:
          'Что из перечисленного допускается при прокладке трубопроводов гипохлорита натрия (калия)? Выберите два правильных варианта ответа.',
        answers: [
          'Прокладка трубопроводов гипохлорита натрия (калия) через административные и бытовые помещения',
          'Подземная прокладка трубопроводов гипохлорита натрия (калия) в грунте, в коллекторах или непроходных каналах при нецелесообразности применения по технологическим или эксплуатационным условиям надземной прокладки',
          'Повторное использование прокладок для фланцевых соединений',
          'Крепить теплоспутники, закрепляемые без приварки, к трубопроводам, транспортирующим гипохлорит натрия (калия)',
        ],
        correctAnswers: [
          'Подземная прокладка трубопроводов гипохлорита натрия (калия) в грунте, в коллекторах или непроходных каналах при нецелесообразности применения по технологическим или эксплуатационным условиям надземной прокладки',
          'Крепить теплоспутники, закрепляемые без приварки, к трубопроводам, транспортирующим гипохлорит натрия (калия)',
        ],
      },
      {
        code: '63632222',
        text:
          'Какая максимальная объемная доля водорода в хлоре устанавливается в общем хлорном коллекторе при электролизе мембранным методом?',
        answers: ['0,5%', '0,2%', '0,3%', '0,4%'],
        correctAnswers: ['0,2%'],
      },
      {
        code: '63632223',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63632224',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме - закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63632225',
        text:
          'С какой периодичностью необходимо проводить периодическую выборочную ревизию трубопроводов хлора?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 4 года',
        ],
        correctAnswers: ['Не реже 1 раза в 4 года'],
      },
      {
        code: '63632226',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии главного инженера',
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии начальника цеха',
          'В присутствии рабочего, который будет спускаться в емкость, и наблюдающего',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63632227',
        text:
          'Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?',
        answers: [
          'Полимерные материалы',
          'Композитные материалы',
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
          'Нержавеющая сталь',
        ],
        correctAnswers: [
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
        ],
      },
      {
        code: '63632228',
        text:
          'В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'В исходных данных на проектирование и технологическом регламенте',
          'В проектной документации',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63632229',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, указанные в наряде-допуске',
          'Работники подрядной организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63632230',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63632231',
        text:
          'Каким требованиям должны соответствовать вновь проектируемые склады кислот, в которых возможно образование первичного кислотного облака? Выберите два правильных варианта ответа.',
        answers: [
          'Склады должны располагаться в более высоких местах по отношению к другим зданиям и сооружениям',
          'Склады должны располагаться с наветренной стороны преобладающих направлений ветров относительно места расположения ближайших населенных пунктов',
          'На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада',
          'На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках',
        ],
        correctAnswers: [
          'На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада',
          'На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках',
        ],
      },
      {
        code: '63632232',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63632233',
        text:
          'Какие требования установлены в Правилах безопасности производств хлора и хлорсодержащих сред для закрытых складов жидкого хлора?',
        answers: [
          'Склады хлора должны располагаться только в подземных сооружениях',
          'Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях',
          'Двери на складах должны открываться внутрь',
          'Требования установлены в строительных нормах и правилах',
        ],
        correctAnswers: [
          'Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях',
        ],
      },
      {
        code: '63632234',
        text:
          'Кто устанавливает сроки проведения ревизии трубопроводов, запорной арматуры и предохранительных клапанов для неорганических жидких кислот и (или) щелочей в зависимости от скорости коррозионно-эрозионного износа?',
        answers: [
          'Научно-исследовательские организации',
          'Предприятие - владелец трубопровода',
          'Ростехнадзор',
          'Проектная организация',
        ],
        correctAnswers: ['Предприятие - владелец трубопровода'],
      },
      {
        code: '63632235',
        text:
          'Каким образом должны быть заполнены емкости для хранения гипохлорита натрия?',
        answers: [
          'Не более чем на 85% объема',
          'Не более чем на 90% объема',
          'Не более чем на 95% объема',
          'Определяется технологическим регламентом',
        ],
        correctAnswers: ['Не более чем на 90% объема'],
      },
      {
        code: '63632236',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63632237',
        text:
          'Какую температуру и расчетное давление следует принимать при определении категории и группы рабочей среды трубопроводов гипохлорита натрия?',
        answers: [
          'Не выше 50 °С и не более 1,6 МПа',
          'Не выше 60 °С и не более 2,0 МПа',
          'Не выше 40 °С и не более 1,2 МПа',
          'Определяется технологическим регламентом',
        ],
        correctAnswers: ['Не выше 50 °С и не более 1,6 МПа'],
      },
      {
        code: '63632238',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'Нужно в любом случае',
          'По решению руководителя эксплуатирующей организации',
          'Правилами не регламентируется',
          'Решение принимается совместно руководителем эксплуатирующей организации и руководителем газоспасательной службы',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63632239',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Правилами пожарной безопасности',
          'Организационно-распорядительными документами организации',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63632240',
        text:
          'Каким должно быть давление сжатого воздуха (азота) при проведении операции по сливу-наливу жидкого хлора с использованием сжатого газа?',
        answers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,5 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 2 МПа и должно быть не менее чем на 0,2 МПа выше давления в сосуде, в который передавливается хлор',
          'Давление сжатого воздуха (азота) должно быть не менее чем на 0,05 МПа выше давления в сосуде, в который передавливается хлор',
        ],
        correctAnswers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
        ],
      },
      {
        code: '63632241',
        text:
          'Каким необходимо поддерживать разрежение в групповом водородном коллекторе при электролизе диафрагменным методом?',
        answers: [
          'На 250-350 Па (25 - 35 мм водяного столба) выше, чем в групповом коллекторе хлора',
          'На 50-150 Па (5 - 15 мм водяного столба) выше, чем в групповом коллекторе хлора',
          'На 150-200 Па (15 - 20 мм водяного столба) выше, чем в групповом коллекторе хлора',
        ],
        correctAnswers: [
          'На 50-150 Па (5 - 15 мм водяного столба) выше, чем в групповом коллекторе хлора',
        ],
      },
      {
        code: '63632242',
        text:
          'Какое содержание влаги должно быть в осушенном газе, используемом для технологических целей (передавливание хлора, продувка, разбавление при конденсации) в электролизерах?',
        answers: [
          'Должно соответствовать температуре точки росы не выше -40 °С',
          'Должно соответствовать температуре точки росы не выше -35 °С',
          'Должно соответствовать температуре точки росы не выше -30 °С',
          'Должно соответствовать температуре точки росы не выше -20 °С',
        ],
        correctAnswers: [
          'Должно соответствовать температуре точки росы не выше -40 °С',
        ],
      },
      {
        code: '63632243',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса',
          'В случае использования специальных систем аварийного освобождения',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63632244',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Производить подготовку объекта к проведению газоопасных работ',
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Непосредственно участвовать в выполнении газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63632245',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 5 лет',
          'Не более 10 лет',
          'Не более 1 года',
          'Не более 3 лет',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63632246',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 10 лет',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63632247',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63632248',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'Во время грозы',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63632249',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Главный инженер эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63632250',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны пройти инструктаж',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63632251',
        text: 'Где могут быть расположены склады хлора?',
        answers: [
          'Только в танках и контейнерах-цистернах под навесом',
          'Только в наземных и полузаглубленных одноэтажных зданиях',
          'Только в подземных сооружениях',
          'Во всех перечисленных сооружениях',
        ],
        correctAnswers: ['Во всех перечисленных сооружениях'],
      },
      {
        code: '63632252',
        text: 'Что относится к технологическим трубопроводам?',
        answers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы, предназначенные для транспортирования различных веществ, необходимых для ведения технологического процесса или эксплуатации оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
        correctAnswers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
      },
      {
        code: '63632253',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63632254',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63632255',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Только на единицу времени (час)',
          'Только на единицу выпускаемой продукции',
          'Только на один производственный поток или на мощность производства в целом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63632256',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['30 °С', '35 °С', '20 °С', '25 °С'],
        correctAnswers: ['30 °С'],
      },
      {
        code: '63632257',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
          'В соответствии с технологическим регламентом',
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63632258',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
          'Организовать изготовление необходимых узлов и деталей для замены',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63632259',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы в кДж',
          'Категорией взрывоопасности технологических блоков',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Радиусом зон разрушения в м',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63632260',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Защитными очками и защитной каской',
          'Воздушными изолирующими аппаратами',
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Всеми перечисленными средствами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63632261',
        text:
          'В каком случае допускается установка на нижней части сосуда с жидким хлором штуцеров для отбора жидкого хлора?',
        answers: [
          'По решению разработчика оборудования',
          'В случаях, определенных Правилами безопасности при производстве, хранении, транспортировании и применении хлора',
          'В случаях, обоснованных руководителем эксплуатирующей организации',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63632262',
        text:
          'На основе каких данных составляется материальный баланс для действующих производств?',
        answers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
          'Материальный баланс для действующих производств составляется по данным технологического регламента',
          'Материальный баланс для действующих производств составляется по данным проекта с учетом внесенных в проект изменений, включения или исключения дополнительных операций или стадий',
        ],
        correctAnswers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
        ],
      },
      {
        code: '63632263',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63632264',
        text:
          'Через какое время необходимо проводить периодическую выборочную ревизию трубопроводов хлора после пуска?',
        answers: [
          'Не позднее двух лет',
          'Не позднее одного года',
          'Не позднее трех лет',
          'Не позднее пяти лет',
        ],
        correctAnswers: ['Не позднее двух лет'],
      },
      {
        code: '63632265',
        text:
          'Нахождение каких стационарных систем трубопроводов на пунктах слива-налива жидкого хлора не предусмотрено Правилами безопасности производств хлора и хлорсодержащих сред?',
        answers: [
          'Трубопроводов для подачи в вагон-цистерну сжатого воздуха (азота) или хлора для передавливания',
          'Трубопроводов пара и горячей воды для подогрева',
          'Трубопроводов для слива-налива жидкого хлора',
          'Трубопроводов для отвода газообразного хлора на потребление или поглощение',
        ],
        correctAnswers: ['Трубопроводов пара и горячей воды для подогрева'],
      },
      {
        code: '63632266',
        text:
          'Какое должно быть содержание объемной доли кислорода в водороде в общем водородном коллекторе при электролизе мембранным методом?',
        answers: [
          'Не более 0,5%',
          'Не более 0,3%',
          'Не более 0,2%',
          'Не более 0,7%',
        ],
        correctAnswers: ['Не более 0,3%'],
      },
      {
        code: '63632267',
        text:
          'В каком документе определяется количество компрессоров и необходимость установки резервного (резервных) компрессора (компрессоров)?',
        answers: [
          'В распорядительной внутренней документации',
          'В проекте',
          'В Правилах безопасности при производстве, хранении, транспортировании и применении хлора',
        ],
        correctAnswers: ['В проекте'],
      },
      {
        code: '63632268',
        text:
          'В течение какого времени после наполнения жидким хлором осуществляется отстой вагонов-цистерн с проведением визуального осмотра и контролем утечек хлора?',
        answers: [
          'Не менее 6 часов',
          'Не менее суток',
          'Не менее 3 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее суток'],
      },
      {
        code: '63632269',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63632270',
        text:
          'В каком из перечисленных случаев следует предусматривать установку обратных клапанов на насосах, используемых для перекачки гипохлорита натрия?',
        answers: [
          'Только на нагнетательных линиях дозирующих насосов',
          'Только на всасывающих линиях при подключении нескольких насосов к общему всасывающему коллектору',
          'Только на нагнетательных линиях перекачивающих насосов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63632271',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
          'Провести экспертизу промышленной безопасности',
          'Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов',
          'Провести реконструкцию химически опасного производственного объекта',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
        ],
      },
      {
        code: '63632272',
        text:
          'Что из перечисленного не допускается при наполнении контейнеров хлором?',
        answers: [
          'Сброс абгазов до остаточного давления, соответствующего равновесному давлению насыщенных паров при температуре налива жидкого хлора, после окончания налива',
          'Отвод абгазов при наливе хлора',
          'Наполнение контейнеров в горизонтальном положении',
          'Расположение вентилей друг над другом при наполнении контейнеров',
        ],
        correctAnswers: ['Отвод абгазов при наливе хлора'],
      },
      {
        code: '63632273',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчиком в задании на проектирование',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63632274',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63632275',
        text:
          'Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?',
        answers: [
          'Лицо, ответственное за проведение газоопасных работ',
          'Главный инженер',
          'Начальник цеха',
          'Лицо, ответственное за осуществление производственного контроля',
        ],
        correctAnswers: ['Лицо, ответственное за проведение газоопасных работ'],
      },
      {
        code: '63632276',
        text:
          'В каком случае допускается шунтировать электролизер вручную при электролизе ртутным методом?',
        answers: [
          'При нагрузке менее 50 кА',
          'Если это устанавливается разработчиком электролизера',
          'При прекращении циркуляции ртути и остановке ртутного насоса на одном электролизере',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['При нагрузке менее 50 кА'],
      },
      {
        code: '63632277',
        text:
          'Каким должно быть число последовательных ступеней изоляции крюка крана от земли в залах диафрагменного электролиза при производстве хлора?',
        answers: [
          'Не менее трех',
          'Не менее четырех',
          'Не менее пяти',
          'Не менее двух',
        ],
        correctAnswers: ['Не менее трех'],
      },
      {
        code: '63632278',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['10 минут', '30 минут', '15 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63632279',
        text:
          'Кем должен быть осмотрен прибывший в организацию вагон-цистерна для перевозки жидкого хлора с целью проверки исправности ходовой части вагона-цистерны, а также крепления котла вагона-цистерны к раме?',
        answers: [
          'Представителем организации-грузополучателя',
          'Представителем организации-грузоперевозчика',
          'Уполномоченными лицами организации-грузоотправителя',
          'Представителем организации-разработчика',
        ],
        correctAnswers: ['Уполномоченными лицами организации-грузоотправителя'],
      },
      {
        code: '63632280',
        text:
          'В течение какого минимального срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?',
        answers: [
          'Не менее двух суток',
          'Не менее трех суток',
          'Не менее одних суток',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63632281',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Увеличить число наблюдающих и работающих',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Установить систему подачи условных сигналов',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63632282',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63632283',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63632284',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '12 месяцев',
          '24 месяца',
          '3 месяца',
          '6 месяцев',
          '36 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63632285',
        text:
          'Какие из документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63632286',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63632287',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'По I или II категории надежности',
          'Только по I категории надежности',
          'По II или III категории надежности',
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
        ],
        correctAnswers: ['По I или II категории надежности'],
      },
      {
        code: '63632288',
        text:
          'Чем не оснащаются в обязательном порядке емкости цистерны на шасси автомобиля, переносные контейнеры-цистерны на шасси автомобильного прицепа, переносные контейнеры гипохлорита натрия (калия)?',
        answers: [
          'Устройствами для пломбирования крышки люка',
          'Дыхательным клапаном',
          'Запорным клапаном в нижней части емкости для слива гипохлорита натрия (калия) с устройством для подсоединения сливного гибкого шланга для емкостей с нижним сливом',
          'Системами измерения и контроля массы (уровня)',
        ],
        correctAnswers: ['Системами измерения и контроля массы (уровня)'],
      },
      {
        code: '63632289',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63632290',
        text:
          'Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?',
        answers: [
          'В захоложенном состоянии при температуре ниже температуры окружающей среды',
          'При температуре кипения жидкого хлора при атмосферном давлении (изотермический способ хранения)',
          'При температуре окружающей среды',
          'При температуре плавления жидкого хлора',
        ],
        correctAnswers: ['При температуре плавления жидкого хлора'],
      },
      {
        code: '63632291',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований Правил противопожарного режима',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63632292',
        text:
          'Какую вентиляцию следует использовать для локализации аварийных ситуаций на складах химического гипохлорита натрия и в помещениях насосных?',
        answers: [
          'Естественную вентиляцию',
          'Общеобменную приточную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
          'Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
          'Общеобменную приточно-вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
        ],
        correctAnswers: [
          'Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
        ],
      },
      {
        code: '63632293',
        text:
          'Что должно быть учтено при расчете толщины стенок сосудов, работающих под давлением в среде хлора?',
        answers: [
          'Расчетный срок эксплуатации',
          'Расчетное давление и прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса',
          'Прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса',
          'Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах',
        ],
        correctAnswers: [
          'Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах',
        ],
      },
      {
        code: '63632294',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63632295',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63632296',
        text:
          'Чем должны быть продуты накопители гипохлорита натрия (калия) при отключении электролиза?',
        answers: ['Азотом', 'Воздухом', 'Любым инертным газом', 'Паром'],
        correctAnswers: ['Воздухом'],
      },
    ],
    63639: [
      {
        code: '63639000',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
          'В соответствии с технологическим регламентом',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63639001',
        text:
          'Какие из обязательных мер безопасного ведения газоопасных работ, предусмотренных правилами, указаны неверно?',
        answers: [
          'Обеспечение контроля за состоянием воздушной среды',
          'Выполнение работ бригадой исполнителей не менее трех человек',
          'Обеспечение членов бригады СИЗ, спецодеждой, инструментом',
          'Исключение возможности присутствия на месте проведения газоопасной работы лиц, не занятых ее выполнением',
        ],
        correctAnswers: [
          'Выполнение работ бригадой исполнителей не менее трех человек',
        ],
      },
      {
        code: '63639002',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: не в фазе!"',
          '"Не включать: работа на линии!"',
          '"Не включать: работают люди!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63639003',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее двух',
          'Не менее четырех',
          'Не менее одного',
          'Определяется ответственным за проведение газоопасных работ',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63639004',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, и в средствах защиты органов дыхания',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования',
          'Только с разрешения представителя газоспасательной службы и при обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63639005',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии технического руководителя организации и начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63639006',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63639007',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов',
          'Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах',
          'Руководства по эксплуатации технологического оборудования и их ксерокопии',
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63639008',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Аварийно-спасательной службе организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63639009',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63639010',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63639011',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63639012',
        text:
          'Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?',
        answers: [
          'Лицо, ответственное за осуществление производственного контроля',
          'Лицо, ответственное за проведение газоопасных работ',
          'Начальник цеха',
          'Главный инженер',
        ],
        correctAnswers: ['Лицо, ответственное за проведение газоопасных работ'],
      },
      {
        code: '63639013',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63639014',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Увеличение объема и характера работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63639015',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ передает наряд-допуск после его закрытия?',
        answers: [
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф)',
          'Только в ПАСС(Ф)',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
        ],
      },
      {
        code: '63639016',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63639017',
        text:
          'Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?',
        answers: [
          'Произвести запись в журнале ведения технологического процесса (в вахтенном журнале, журнале приема-сдачи смен) и наряде-допуске на проведение газоопасных работ',
          'Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ',
          'Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ',
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
        correctAnswers: [
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
      },
      {
        code: '63639018',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Правилами не регламентируется',
          'Работники подрядной организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, указанные в наряде-допуске',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63639019',
        text:
          'Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?',
        answers: [
          'Лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
          'Представителем Ростехнадзора',
          'Лицом, ответственным за подготовку газоопасных работ',
        ],
        correctAnswers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
        ],
      },
      {
        code: '63639020',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала (дочернего общества)',
          'С газоспасательной службой и службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63639021',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63639022',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63639023',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Проект производства ремонтных работ',
          'Технологический регламент',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63639024',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 10% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63639025',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Правилами пожарной безопасности',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Организационно-распорядительными документами организации',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63639026',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
          'На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63639027',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
          'Электротехнический персонал эксплуатирующей организации',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63639028',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['30 °C', '20 °C', '25 °C', '35 °C'],
        correctAnswers: ['30 °C'],
      },
      {
        code: '63639029',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63639030',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Всеми перечисленными средствами',
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Защитными очками и защитной каской',
          'Воздушными изолирующими аппаратами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63639031',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'Во время грозы',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63639032',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63639033',
        text:
          'Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63639034',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Установить систему подачи условных сигналов',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Увеличить число наблюдающих и работающих',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63639035',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, список которых определяется внутренними документами организации',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, осуществляющие эксплуатацию объекта',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63639036',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О знании правил оказания первой помощи',
          'О самочувствии',
          'О знании действий при пожаре',
          'О наличии медицинских противопоказаний к работе',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63639037',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Начальнику цеха',
          'Пожарной службе эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63639038',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Непрерывный контроль состояния воздушной среды',
          'Наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
          'Все перечисленное',
          'Наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63639039',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение обучения по пожарной безопасности в объеме знаний требований Правил противопожарного режима в Российской Федерации',
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63639040',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63639041',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['30 минут', '15 минут', '10 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63639042',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63639043',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63639044',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '3 года со дня его окончания',
          '1 год со дня его окончания',
          '6 месяцев со дня его окончания',
          '3 месяца со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63639045',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в полгода',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63639046',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'До окончания работ на конкретном месте',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63639047',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63639048',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований Правил противопожарного режима в Российской Федерации',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63639049',
        text: 'Где регистрируются газоопасные работы II группы?',
        answers: [
          'В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности',
          'В журнале регистрации нарядов-допусков на проведение газоопасных работ с присвоением очередного номера',
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
          'В журнале регистрации целевого инструктажа',
        ],
        correctAnswers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
        ],
      },
      {
        code: '63639050',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии рабочего, который будет спускаться в емкость, и наблюдающего',
          'В присутствии главного инженера',
          'В присутствии начальника цеха',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63639051',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['40 °C', '50 °C', '55 °C', '45 °C'],
        correctAnswers: ['50 °C'],
      },
      {
        code: '63639052',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
          'Руководитель структурного подразделения или лицо, его замещающее',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63639053',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63639054',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Главный инженер эксплуатирующей организации',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63639055',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны пройти инструктаж',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63639056',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63639057',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63639058',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63639059',
        text:
          'В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?',
        answers: [
          'В наряде-допуске на проведение газоопасных работ',
          'В журнале ведения технологического процесса',
          'В технологическом регламенте и производственных инструкциях по рабочим местам',
          'В специально разрабатываемой эксплуатирующей организацией инструкции',
        ],
        correctAnswers: ['В наряде-допуске на проведение газоопасных работ'],
      },
      {
        code: '63639060',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации',
          'Главным инженером эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63639061',
        text:
          'В течение какого времени наряд-допуск на проведение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной дневной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной дневной смены'],
      },
      {
        code: '63639062',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Газоопасные работы", "Газ"',
          '"Работают люди!", "Опасно для жизни!"',
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Опасно для жизни!", "Внимание!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63639063',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63639064',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63639065',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63639066',
        text:
          'На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?',
        answers: [
          'На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве',
          'На 3 группы, в зависимости от степени риска проводимых работ',
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
        correctAnswers: [
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
      },
      {
        code: '63639067',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'С руководителями аварийно-спасательных служб',
          'С руководителями службы производственного контроля',
          'С руководителями службы охраны труда и санитарных служб',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63639068',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Все перечисленные требования',
          'Не моложе 18 лет',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
          'Прошедшие обучение приемам и методам проведения работ',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63639069',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63639070',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63639071',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Любым из перечисленных лиц',
          'Только лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Только лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63639072',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'Ко II группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['Ко II группе'],
      },
      {
        code: '63639073',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63639074',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63639075',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
    ],
    63630: [
      {
        code: '63630000',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63630001',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы, в кДж',
          'Категорией взрывоопасности технологических блоков',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Радиусом зон разрушения, в м',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63630002',
        text:
          'Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63630003',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Специализированной сторонней организацией',
          'Организацией-разработчиком процесса',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63630004',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63630005',
        text:
          'Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?',
        answers: [
          'Проектная организация',
          'Эксплуатирующая организация',
          'Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов',
          'Монтажно-наладочная организация',
        ],
        correctAnswers: ['Проектная организация'],
      },
      {
        code: '63630006',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'Время устанавливается в проектной документации',
          'В течение 24 часов',
          'В течение 8 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63630007',
        text:
          'При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещения на опасных производственных объектах складов нефти и нефтепродуктов?',
        answers: [
          'Не допускается ни в каком случае',
          'Если это утверждено внутренней документацией',
          'Если это обусловлено в проектной документации',
          'Если предусмотрены меры и средства, обеспечивающие безопасность эксплуатации таких приборов',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63630008',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
          'Необходимо разработать планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
        ],
        correctAnswers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63630009',
        text:
          'В каком месяце должны быть проведены все ремонты молниезащитных устройств на объектах складов нефти и нефтепродуктов?',
        answers: ['В апреле', 'В мае', 'В марте', 'В сентябре'],
        correctAnswers: ['В апреле'],
      },
      {
        code: '63630010',
        text:
          'При каком снижении уровня жидкости над нагревательным устройством должны быть отключены переносные электрические подогреватели блокировочными устройствами?',
        answers: ['Ниже 500 мм', 'Ниже 600 мм', 'Ниже 800 мм', 'Ниже 700 мм'],
        correctAnswers: ['Ниже 500 мм'],
      },
      {
        code: '63630011',
        text:
          'На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?',
        answers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
          'На алгоритмах, разработанных по сценариям всех возможных аварий и их развития',
          'На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
        ],
        correctAnswers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
        ],
      },
      {
        code: '63630012',
        text:
          'Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?',
        answers: [
          'Из стали',
          'Из полиэтилена',
          'Из фторопласта',
          'Из винипласта',
          'Из стекла',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63630013',
        text:
          'На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?',
        answers: ['10 м', '15 м', '12 м', '8 м', '5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63630014',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'В случае назначения нового руководителя организации',
          'Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте',
          'Не позднее 15 дней после внесения изменений в применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте методики (методы) измерений или типы средств измерений',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
      },
      {
        code: '63630015',
        text:
          'Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?',
        answers: ['Бетонным', 'Асфальтированным', 'Деревянным', 'Глинобитным'],
        correctAnswers: ['Бетонным'],
      },
      {
        code: '63630016',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63630017',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Порядок действий в случае аварий на объекте',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63630018',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63630019',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63630020',
        text:
          'На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?',
        answers: [
          'На 0,3 м',
          'На 0,5 м',
          'Должны полностью доходить до пола и покрытия (перекрытия) насосной станции',
          'На 1,0 м',
        ],
        correctAnswers: ['На 0,3 м'],
      },
      {
        code: '63630021',
        text:
          'Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Характеристику объекта, в отношении которого разрабатывается план мероприятий',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63630022',
        text:
          'Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?',
        answers: [
          'Только электрообогрев',
          'Только пар',
          'Только промтеплофикационная вода',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63630023',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В технологическом регламенте',
          'В руководствах по безопасности',
          'В техническом регламенте',
          'В проектной документации',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63630024',
        text:
          'Что из перечисленного должен предусматривать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах (далее - ОПО)?',
        answers: [
          'Только организацию материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Только состав и дислокацию сил и средств',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63630025',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '1 год', '2 года', '3 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63630026',
        text:
          'На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?',
        answers: [
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
          'На товарно-сырьевые парки центральных пунктов сбора нефтяных месторождений',
          'На наливные станции магистральных нефтепроводов и нефтепродуктопроводов',
          'На объекты, предназначенные для приема и выдачи нефти и нефтепродуктов',
        ],
        correctAnswers: [
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
        ],
      },
      {
        code: '63630027',
        text:
          'Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?',
        answers: [
          'Сальниковых компенсаторов',
          'Волнистых компенсаторов',
          'Линзовых компенсаторов',
          'Допускается применение всех перечисленных компенсаторов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63630028',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
        ],
      },
      {
        code: '63630029',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Предотвращение взрывов внутри технологического оборудования',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Периодический контроль за состоянием воздушной среды',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63630030',
        text:
          'При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?',
        answers: [
          '50% объемных от нижнего концентрационного предела распространения пламени',
          '30% объемных от нижнего концентрационного предела распространения пламени',
          '20% объемных от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          '50% объемных от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63630031',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчиком в задании на проектирование',
          'Разработчиком процесса',
          'Разработчиком проекта',
          'Разработчиками процесса и проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63630032',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Организации, выполнившей проектную документацию',
          'Организации, выполнившей функции генерального проектировщика',
          'Организации-разработчике технологического процесса',
          'Организации-разработчике технологической части проектной документации',
          'О всех перечисленных организациях',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63630033',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 2 часов',
          'В течение 24 часов',
          'В течение 8 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63630034',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от вида взрывозащиты',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63630035',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Показатели взрыво- пожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
          'Степень разделения сред, меры взрывобезопасности, показатели пожароопасности и токсичности',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63630036',
        text:
          'В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?',
        answers: [
          'Для коммерческого учета нефти и нефтепродуктов, хранящихся в резервуарах',
          'Для вновь строящихся и реконструируемых резервуаров',
          'Для определения качества нефти и нефтепродуктов, хранящихся в резервуарах',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63630037',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Рациональный подбор взаимодействующих компонентов, исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Нарушение энергообеспечения',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63630038',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?',
        answers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63630039',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 6',
          'Не менее 10',
          'Не менее 15',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63630040',
        text:
          'Что из перечисленного допускается в отношении резервуарных парков?',
        answers: [
          'Ручной отбор проб светлых нефтепродуктов во время закачки или откачки продукта',
          'При хранении нефтепродуктов в резервуарах наличие подтоварной воды выше минимального уровня, обеспечиваемого устройством для дренажа воды',
          'Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара',
          'Все перечисленное не допускается',
        ],
        correctAnswers: ['Все перечисленное не допускается'],
      },
      {
        code: '63630041',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Контроль сплошности изоляции и толщины стенок',
          'Промывка (пропарка)',
          'Продувка',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63630042',
        text:
          'В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?',
        answers: [
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'На каждый объект должен быть разработан свой план в любом случае',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63630043',
        text:
          'В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?',
        answers: [
          'Только при выдаче заданной нормы',
          'Только при достижении предельного уровня заполнения железнодорожной цистерны',
          'Только при нарушении целостности цепи заземления железнодорожной цистерны',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63630044',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63630045',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'Только в исходных данных на проектирование и технологическом регламенте на производство продукции',
          'Только в технологическом регламенте на производство продукции',
          'Только в проектной документации',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63630046',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63630047',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)',
        ],
      },
      {
        code: '63630048',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Только наличие природных оврагов, выемок, низин',
          'Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63630049',
        text:
          'Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?',
        answers: [
          'Только автоматически, когда стендер достигает обусловленного граничного положения',
          'Только дистанционно с учетом нажатия кнопки на центральном пульте управления',
          'Только вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63630050',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 5 лет',
          'Не более 10 лет',
          'Не более 1 года',
          'Не более 3 лет',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63630051',
        text:
          'Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?',
        answers: [
          'Не более 0,03 Ом на каждый контакт',
          'Не более 0,5 Ом на каждый контакт',
          'Не более 0,1 Ом на каждый контакт',
          'Не более 0,05 Ом на каждый контакт',
        ],
        correctAnswers: ['Не более 0,03 Ом на каждый контакт'],
      },
      {
        code: '63630052',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Иметь документы о прохождении аттестации',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63630053',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 10 лет',
          'Не более 5 лет',
          'Не более 3 лет',
          'Не более 20 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63630054',
        text:
          'В каком случае не производится налив (слив) растворителя из железнодорожных цистерн?',
        answers: [
          'Во время грозы',
          'Налив (слив) растворителя производится в любом из перечисленных случаев',
          'В условиях ограниченной видимости (сильный снегопад, туман)',
          'В ночное время суток',
        ],
        correctAnswers: ['Во время грозы'],
      },
      {
        code: '63630055',
        text:
          'При какой минимальной скорости ветра запрещается проведение сливоналивных операций с легковоспламеняющимися жидкостями?',
        answers: [
          'Более 15 м/с',
          'Более 20 м/с',
          'Более 10 м/с',
          'Более 8 м/с',
        ],
        correctAnswers: ['Более 15 м/с'],
      },
      {
        code: '63630056',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Требования к обозначению определяются при разработке плана мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63630057',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'В закрытые системы для дальнейшей утилизации',
          'На факельную установку',
          'В централизованную систему водоотведения',
          'В специальные контейнеры',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63630058',
        text:
          'Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?',
        answers: [
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
          'Измерение указанного сопротивления должно проводиться при относительной влажности окружающего воздуха не выше 40%',
          'При измерениях электрод должен располагаться в точках поверхности оборудования, наименее удаленных от точек контакта поверхности с заземленными металлическими элементами, деталями, арматурой',
          'Площадь соприкосновения измерительного электрода с поверхностью оборудования не должна превышать 30 см²',
        ],
        correctAnswers: [
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
        ],
      },
      {
        code: '63630059',
        text:
          'Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?',
        answers: [
          'Годовое число циклов заполнений-опорожнений резервуара',
          'Показатели взрывоопасности хранимых нефтепродуктов',
          'Физико-химические свойства хранимых нефтепродуктов',
        ],
        correctAnswers: [
          'Годовое число циклов заполнений-опорожнений резервуара',
        ],
      },
      {
        code: '63630060',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?',
        answers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
          'Не менее 5 независимых датчиков с раздельными точками отбора',
          'Не менее 3 независимых датчиков с раздельными точками отбора',
          'Правилами безопасности химически опасных производственных объектов не регламентируется',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63630061',
        text:
          'В каких случаях на трубопроводах следует применять арматуру под приварку?',
        answers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения, при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности с температурой, равной температуре кипения, при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа',
        ],
        correctAnswers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения, при регламентированном давлении',
        ],
      },
      {
        code: '63630062',
        text:
          'В каком случае допускается сбрасывать взрывопожароопасные и пожароопасные нефтепродукты в канализацию?',
        answers: [
          'Не допускается ни в каком случае',
          'При авариях',
          'По решению технического руководителя организации',
          'После предварительной локальной очистки',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63630063',
        text:
          'Чем подтверждаются эффективность и надежность средств взрывозащиты и локализации пламени и других противоаварийных устройств, направленных на взрывозащищенность оборудования?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63630064',
        text:
          'В каком случае допускается торможение цистерн башмаками, изготовленными из материала, дающего искрение, на участках слива-налива?',
        answers: [
          'Если цистерны имеют прокладки, устойчивые к разрушению парами нефтепродуктов',
          'Если цистерны имеют прокладки из неискрящего материала',
          'Если прием и отгрузка нефти и нефтепродуктов осуществляется через специально обрудованные сливоналивные устройства',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63630065',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63630066',
        text:
          'Какие из перечисленных видов арматур должны быть установлены совместно или по отдельности на технологических трубопроводах для транспортировки нефти и нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Запорная арматура',
          'Отсекающая арматура',
          'Распределительно-смесительная арматура',
          'Предохранительная арматура',
        ],
        correctAnswers: ['Запорная арматура', 'Отсекающая арматура'],
      },
      {
        code: '63630067',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей системы, нанесенное на нем обозначение номера по технологической схеме должно быть закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63630068',
        text:
          'Кем принимается решение о выводе объекта (блока, установки) из эксплуатации на длительный период и вводе этих объектов (блоков, установок) в эксплуатацию после длительных остановок?',
        answers: [
          'Эксплуатирующей организацией',
          'Проектной организацией',
          'Эксплуатирующей организацией при согласовании с Ростехнадзором',
          'Сторонней специализированной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63630069',
        text:
          'Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?',
        answers: [
          'Требуемая скорость перемещения нефтепродукта',
          'Физико-химические свойства перемещаемой среды',
          'Параметры давления и температуры перемещаемой среды',
          'Размер судового трубопровода',
        ],
        correctAnswers: ['Требуемая скорость перемещения нефтепродукта'],
      },
      {
        code: '63630070',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63630071',
        text:
          'Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?',
        answers: [
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 300 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 200 мм',
        ],
        correctAnswers: [
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
        ],
      },
      {
        code: '63630072',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Главными техническими специалистами организаций, эксплуатирующих объекты',
          'Должностным лицом территориального управления Ростехнадзора',
          'Специалистом военизированной газоспасательной службы',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63630073',
        text:
          'В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?',
        answers: [
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручным дистанционным управлением при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное по месту',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручным дистанционным управлением, с технологическими блоками III категории взрывоопасности допускается ручное по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63630074',
        text:
          'В каком документе организация, эксплуатирующая химически опасный производственный объект I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?',
        answers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'В технологическом регламенте',
          'В плане по локализации аварийных ситуаций',
          'В Положении о производственном контроле',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63630075',
        text:
          'В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны',
          'Допускается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Допускается в любом случае',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны',
        ],
      },
      {
        code: '63630076',
        text:
          'В каком случае разрешается выполнение болтовых соединений молниеприемников с токоотводами и токоотводов с заземлителями с переходным сопротивлением не более 0,05 Ом?',
        answers: [
          'Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода',
          'Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области пожарной безопасности',
          'Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Не разрешается ни в каком случае',
        ],
        correctAnswers: [
          'Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода',
        ],
      },
      {
        code: '63630077',
        text:
          'В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Время должно быть подтверждено расчетом, но не менее 35 минут',
          'Время должно быть подтверждено расчетом, но не более 45 минут',
          'Время должно быть подтверждено расчетом, но не менее 20 минут',
        ],
        correctAnswers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63630078',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)',
        ],
      },
      {
        code: '63630079',
        text:
          'При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?',
        answers: [
          'При превышении концентрации паров более 20%',
          'При превышении концентрации паров более 30%',
          'При превышении концентрации паров более 50%',
        ],
        correctAnswers: ['При превышении концентрации паров более 20%'],
      },
      {
        code: '63630080',
        text:
          'Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?',
        answers: [
          'Не более 2,5 м/ч',
          'Не более 2,0 м/ч',
          'Не более 3,5 м/ч',
          'Не более 1,5 м/ч',
        ],
        correctAnswers: ['Не более 2,5 м/ч'],
      },
      {
        code: '63630081',
        text:
          'Какой электрифицированный транспорт во взрывозащищенном исполнении допускается применять на территории опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Только самоходные аккумуляторные тележки (электрокары)',
          'Только электропогрузчики',
          'Только тягачи',
          'Любой из перечисленных видов транспорта',
        ],
        correctAnswers: ['Любой из перечисленных видов транспорта'],
      },
      {
        code: '63630082',
        text:
          'Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Только установкой частотно-регулируемого электропривода насоса',
          'Только посредством запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде',
          'Только перепуском части продукта во всасывающий трубопровод насоса',
          'Любым из перечисленных способом',
        ],
        correctAnswers: ['Любым из перечисленных способом'],
      },
      {
        code: '63630083',
        text:
          'На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?',
        answers: ['500 мм', '300 мм', '600 мм', '800 мм'],
        correctAnswers: ['500 мм'],
      },
      {
        code: '63630084',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63630085',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63630086',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'План мероприятий разрабатывается на каждый опасный объект отдельно',
          'В случае если это регламентировано внутренней документацией организации',
          'В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63630087',
        text:
          'Какие требования предъявляются к температуре подогрева мазута в резервуарах?',
        answers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C',
        ],
        correctAnswers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
        ],
      },
      {
        code: '63630088',
        text:
          'Какие из перечисленных данных по каждому шлангу не должны иметь лица, ответственные за проведение сливоналивных операций с нефтепродуктами?',
        answers: [
          'Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться',
          'Значение величины рабочего давления',
          'Название нефтепродукта, для которого он предназначен',
          'Значение величины разрывного давления',
        ],
        correctAnswers: [
          'Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться',
        ],
      },
      {
        code: '63630089',
        text:
          'В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?',
        answers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 10 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
          'После назначения нового руководителя организации, эксплуатирующей объект',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63630090',
        text:
          'В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?',
        answers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 81 °C',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
        ],
      },
      {
        code: '63630091',
        text:
          'В течение какого времени после внесения изменений в системы управления технологическими процессами на объекте должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее трех месяцев',
          'Не позднее полугода',
          'Не позднее одного года',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63630092',
        text:
          'При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?',
        answers: [
          '20% объемных от НКПРП',
          '40% объемных от НКПРП',
          '30% объемных от НКПРП',
          '50% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63630093',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом руководителя организации',
          'Приказом главного инженера организации (технического директора, директора по производству)',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом комиссии организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63630094',
        text:
          'Какое из перечисленных требований к использованию переносных подогревателей на железнодорожных сливоналивных эстакадах указано неверно?',
        answers: [
          'При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом',
          'Разогрев нефтепродуктов в железнодорожных цистернах переносными электрическими подогревателями должен производиться только в сочетании с циркуляционным нагревом в выносном подогревателе (теплообменнике)',
          'При использовании переносных электрических подогревателей последние должны быть оснащены блокировочными устройствами, отключающими их при снижении уровня жидкости над нагревательным устройством ниже 500 мм',
          'При использовании переносных пароподогревателей давление пара в подогревателе должно соответствовать показателям, установленным в технической документации (паспорте) пароподогревателя',
        ],
        correctAnswers: [
          'При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом',
        ],
      },
      {
        code: '63630095',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63630096',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случае изгиба болтов и шпилек',
          'В случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63630097',
        text:
          'Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Проектной документацией',
          'Технологическим регламентом',
          'Техническим регламентом',
          'Эксплуатационной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63630098',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63630099',
        text:
          'Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?',
        answers: [
          'Применение гибких шлангов для налива при обосновании в проектной документации',
          'Нахождение водителей автомобильных цистерн, выполняющих операции слива-налива нефтепродуктов, в одежде, способной накапливать заряды статического электричества',
          'Запуск двигателей автомобильных цистерн, находящихся на площадке, в случаях пролива нефтепродукта',
          'Эксплуатация сливоналивных устройств при обнаружении нарушения целостности единого контура заземления при обосновании в проектной документации',
        ],
        correctAnswers: [
          'Применение гибких шлангов для налива при обосновании в проектной документации',
        ],
      },
      {
        code: '63630100',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
          'Помещения управления должны быть отдельно стоящими',
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать их к зданиям',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63630101',
        text:
          'За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?',
        answers: [
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
          'За счет установки на технологических трубопроводах для транспортировки мазута волнистых компенсаторов',
          'За счет установки на технологических трубопроводах для транспортировки мазута линзовых компенсаторов',
          'За счет установки на технологических трубопроводах для транспортировки мазута сальниковых компенсаторов',
        ],
        correctAnswers: [
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
        ],
      },
      {
        code: '63630102',
        text:
          'Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?',
        answers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
          'Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования',
          'Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание',
          'В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание',
        ],
        correctAnswers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
        ],
      },
      {
        code: '63630103',
        text:
          'Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?',
        answers: [
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
          'Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации',
          'Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования',
          'Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из операторной и по месту ее установки',
        ],
        correctAnswers: [
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
        ],
      },
      {
        code: '63630104',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводится не реже двух раз в смену',
        ],
        correctAnswers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63630105',
        text:
          'Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?',
        answers: [
          'Не менее 0,25 м',
          'Не менее 0,75 м',
          'Не менее 1,0 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 0,25 м'],
      },
      {
        code: '63630106',
        text:
          'Для налива каких легковоспламеняющихся жидкостей (далее - ЛВЖ) сливоналивные устройства должны снабжаться устройствами отвода паров?',
        answers: [
          'С упругостью паров от 66,65 кПа',
          'С упругостью паров от 61,65 кПа',
          'С упругостью паров от 60,65 кПа',
          'С упругостью паров от 56,65 кПа',
        ],
        correctAnswers: ['С упругостью паров от 66,65 кПа'],
      },
      {
        code: '63630107',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
          'Начальник производственно-технического (технического) отдела организации',
          'Начальник производства',
        ],
        correctAnswers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
      },
      {
        code: '63630108',
        text:
          'Какими документами определяются объем, периодичность и порядок организации и проведения работ по техническому обслуживанию и ремонту оборудования, резервуаров и технологических трубопроводов, систем инженерно-технического обеспечения с учетом конкретных условий эксплуатации опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Нормативно-техническими документами, разработанными эксплуатирующей организацией',
          'Проектной документацией',
          'Федеральными нормами и правилами в области промышленной безопасности',
        ],
        correctAnswers: [
          'Нормативно-техническими документами, разработанными эксплуатирующей организацией',
        ],
      },
      {
        code: '63630109',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63630110',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63630111',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Проектной организацией',
          'Технической комиссией эксплуатирующей организации',
          'Монтажной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63630112',
        text:
          'Кем утверждается перечень уставок срабатывания блокировок и сигнализации для осуществления технологических операций, предусмотренных проектной документацией?',
        answers: [
          'Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов',
          'Организацией, осуществляющей техническое обслуживание и ремонт автоматизированных систем управления на опасных производственных объектах складов нефти и нефтепродуктов',
          'Разработчиком проектной документации или организацией, специализирующейся на проектировании аналогичных объектов',
          'Разработчиком технологического процесса по согласованию с разработчиком проектной документации',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов',
        ],
      },
      {
        code: '63630113',
        text:
          'Что из перечисленного не включает в себя план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Организацию управления, связи и оповещения при авариях на объекте',
          'Мероприятия, направленные на обеспечение безопасности населения (в случае если в результате аварий на объекте может возникнуть угроза безопасности населения)',
          'Порядок ремонтно-восстановительных работ, проводимых после полной ликвидации последствий аварии',
          'Состав и дислокацию сил и средств',
          'Организацию материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
        ],
        correctAnswers: [
          'Порядок ремонтно-восстановительных работ, проводимых после полной ликвидации последствий аварии',
        ],
      },
      {
        code: '63630114',
        text:
          'Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?',
        answers: ['6 м/ч', '2 м/ч', '4 м/ч', '8 м/ч'],
        correctAnswers: ['6 м/ч'],
      },
      {
        code: '63630115',
        text:
          'Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?',
        answers: [
          'Светлые нефтепродукты',
          'Авиационное топливо',
          'Нефтепродукты с низкой вязкостью',
          'Высоковязкие нефтепродукты',
        ],
        correctAnswers: ['Светлые нефтепродукты'],
      },
      {
        code: '63630116',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Автоматическое',
          'Дистанционное, неавтоматическое',
          'Ручное управление по месту',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63630117',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - маслом, продувка - паром',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63630118',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63630119',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63630120',
        text:
          'Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63630121',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для новых в данной организации производств',
          'Для производств с новой технологией',
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для всех перечисленных производств',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63630122',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63630123',
        text:
          'В каком случае допускается сброс химически загрязненных, технологических, смывных и других сточных вод без предварительной очистки?',
        answers: [
          'В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков',
          'В случае, когда это утверждено внутренней документацией эксплуатирующей организации',
          'В случае, когда это утверждено инспектором Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков',
        ],
      },
      {
        code: '63630124',
        text: 'Какой должна быть температура подогрева мазута в резервуарах?',
        answers: [
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не превышать 90 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 10 °C и не превышать 95 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 5 °C и не превышать 85 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не превышать 100 °C',
        ],
        correctAnswers: [
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не превышать 90 °C',
        ],
      },
      {
        code: '63630125',
        text:
          'На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?',
        answers: ['2 м', '3 м', '1,5 м', '1 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63630126',
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ['6 месяцев', '1 месяц', '3 месяца', 'Один год'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63630127',
        text:
          'На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?',
        answers: [
          'На три категории',
          'На четыре категории',
          'На пять категорий',
          'На две категории',
        ],
        correctAnswers: ['На три категории'],
      },
      {
        code: '63630128',
        text:
          'Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?',
        answers: [
          'В пунктах разлива и фасовки нефтепродуктов',
          'В складских помещениях для хранения нефтепродуктов в таре',
          'На открытых площадках',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['В пунктах разлива и фасовки нефтепродуктов'],
      },
      {
        code: '63630129',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63630130',
        text:
          'В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?',
        answers: [
          'В проектной документации',
          'В техническом регламенте',
          'В технологическом регламенте',
          'В эксплуатационной документации',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63630131',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63630132',
        text:
          'Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?',
        answers: [
          'Заглубленные более чем на 0,5 м',
          'Заглубленные не менее чем на 1 м',
          'Все насосные станции',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Заглубленные более чем на 0,5 м'],
      },
      {
        code: '63630133',
        text:
          'На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Выберите два правильных варианта ответа.',
        answers: [
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО II класса опасности',
          'На объектах ХОПО I класса опасности',
          'На объектах ХОПО IV класса опасности',
          'На всех объектах ХОПО',
        ],
        correctAnswers: [
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО IV класса опасности',
        ],
      },
      {
        code: '63630134',
        text:
          'В течение какого срока после реконструкции или технического перевооружения объекта должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее 45 календарных дней',
          'Не позднее 6 месяцев',
          'Не позднее 3 месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63630135',
        text:
          'В течение какого времени пересматривается план мероприятий по локализации и ликвидации последствий аварий после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее 15 календарных дней',
          'Не позднее 10 календарных дней',
          'Не позднее 3 месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63630136',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
          'В соответствии с декларацией промышленной безопасности',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63630137',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Техническими характеристиками применяемых насосов и компрессоров',
          'Критическими параметрами технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63630138',
        text:
          'Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?',
        answers: [
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
          'Не менее 2-х раз в год для любых нефтепродуктов',
          'Не менее 1 раза в год - для авиационного топлива и не менее 2-х раз в год - для остальных светлых нефтепродуктов и масел',
        ],
        correctAnswers: [
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
        ],
      },
      {
        code: '63630139',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63630140',
        text:
          'Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только физико-химические свойства перемещаемых продуктов',
          'Только параметры технологического процесса',
          'Только показатели надежности и конструктивные особенности с учетом критических параметров',
          'Должны быть учтены все перечисленные характеристики',
        ],
        correctAnswers: ['Должны быть учтены все перечисленные характеристики'],
      },
      {
        code: '63630141',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами измерения перепада давления на сепараторе',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63630142',
        text:
          'На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?',
        answers: ['4 м', '3 м', '5 м', '10 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63630143',
        text:
          'Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?',
        answers: [
          'Подтоварные воды от отстоя нефти и нефтепродуктов',
          'Нефтешлам',
          'Сточные воды от зачистки и пропарки резервуаров для нефти и нефтепродуктов',
          'Все перечисленное',
        ],
        correctAnswers: ['Подтоварные воды от отстоя нефти и нефтепродуктов'],
      },
      {
        code: '63630144',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?',
        answers: [
          'Не более 120 секунд',
          'Не менее 300 секунд',
          'Не менее 12 секунд',
          'Не менее 240 секунд',
        ],
        correctAnswers: ['Не более 120 секунд'],
      },
      {
        code: '63630145',
        text:
          'На основе каких данных составляется материальный баланс для действующих производств?',
        answers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
          'Материальный баланс для действующих производств составляется по данным проекта с учетом внесенных в проект изменений, включения или исключения дополнительных операций или стадий',
          'Материальный баланс для действующих производств составляется по данным технологического регламента',
        ],
        correctAnswers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
        ],
      },
      {
        code: '63630146',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63630147',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На технологическую службу организации, производства, отделения, установки',
          'На организацию - разработчика процесса',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63630148',
        text:
          'Какой клапан должен быть установлен на береговом трубопроводе, предназначенном для выгрузки из судна нефти, нефтепродукта или балласта, в районе шлангоприемников?',
        answers: [
          'Обратный клапан',
          'Предохранительный клапан',
          'Запорный клапан',
          'Редукционный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63630149',
        text:
          'Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: ['2%', '3%', '1%', '5%'],
        correctAnswers: ['2%'],
      },
      {
        code: '63630150',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Ростехнадзором',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Эксплуатирующими организациями',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63630151',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63630152',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту',
          'Должно быть организовано управление дистанционно (из безопасного места)',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63630153',
        text:
          'Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?',
        answers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 500 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 300 мм',
        ],
        correctAnswers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
        ],
      },
      {
        code: '63630154',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 10 минут',
          'Не менее 30 минут',
          'Не менее 5 минут',
          'Не менее 15 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63630155',
        text:
          'Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
        ],
        correctAnswers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63630156',
        text:
          'Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Проектной документацией (документацией на техническое перевооружение)',
          'Эксплуатационной документацией',
          'Руководствами по безопасности',
          'Технологическим регламентом',
        ],
        correctAnswers: [
          'Проектной документацией (документацией на техническое перевооружение)',
        ],
      },
      {
        code: '63630157',
        text:
          'Каким образом не должен производиться разогрев застывающих и высоковязких нефтепродуктов в железнодорожных цистернах, сливоналивных устройствах?',
        answers: [
          'Горячей водой',
          'Паром',
          'Электроподогревом не выше 90 °C',
          'Нефтепродуктом, нагретым циркуляционным способом',
        ],
        correctAnswers: ['Горячей водой'],
      },
      {
        code: '63630158',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63630159',
        text:
          'Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?',
        answers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
          'Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
        ],
        correctAnswers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
        ],
      },
      {
        code: '63630160',
        text:
          'Какое из перечисленных требований при очистке оборудования от пирофорных соединений указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Во избежание самонагревания пирофорных отложений при ремонтных работах все разбираемые узлы и детали технологического оборудования необходимо поддерживать во влажном состоянии',
          'По завершении пропарки оборудование должно быть заполнено водой до верхнего уровня',
          'Открывать люки для проветривания оборудования необходимо начиная с нижнего',
          'Подача пара должна производиться с такой интенсивностью, чтобы в емкостях и аппаратах все время поддерживалось давление несколько ниже атмосферного',
          'Продолжительность пропарки устанавливается соответствующими инструкциями для каждого типоразмера оборудования индивидуально, но должна быть не менее 12 часов',
        ],
        correctAnswers: [
          'Во избежание самонагревания пирофорных отложений при ремонтных работах все разбираемые узлы и детали технологического оборудования необходимо поддерживать во влажном состоянии',
          'По завершении пропарки оборудование должно быть заполнено водой до верхнего уровня',
        ],
      },
      {
        code: '63630161',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63630162',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в установленной степени готовности',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63630163',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: [
          '5 лет',
          'Определяются руководством объекта',
          '3 года',
          '2 года',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63630164',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63630165',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63630166',
        text:
          'Для каких целей печи с открытым огневым процессом должны быть оборудованы паровой завесой?',
        answers: [
          'Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи',
          'Для локализации пожаров на печи при установке ее внутри помещений',
          'Для локализации пожара на печи при установке ее вне зданий',
          'Для пожаротушения при аварии на печи',
        ],
        correctAnswers: [
          'Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи',
        ],
      },
      {
        code: '63630167',
        text:
          'Кем может проводиться зачистка резервуаров и тары на опасных производственных объектах складов нефти и нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Персоналом эксплуатирующей организации',
          'Специализированной организацией',
          'Проектной организацией',
          'Подрядной организацией, занимающейся экспертизой промышленной безопасности',
        ],
        correctAnswers: [
          'Персоналом эксплуатирующей организации',
          'Специализированной организацией',
        ],
      },
      {
        code: '63630168',
        text:
          'Какие из перечисленных требований к цистернам, находящимся под сливом (наливом) указаны неверно?',
        answers: [
          'Использование сливоналивных механизированных стояков со стационарной площадкой, лестницей и откидным мостиком для обслуживания горловины цистерны не допускается',
          'Для слива растворителя из железнодорожных цистерн используются установки, предназначенные для нижнего слива-налива нефти и нефтепродуктов из железнодорожных цистерн',
          'Все перечисленные требования указаны верно',
          'Цистерны, находящиеся под сливом (наливом) должны быть заземлены',
        ],
        correctAnswers: [
          'Использование сливоналивных механизированных стояков со стационарной площадкой, лестницей и откидным мостиком для обслуживания горловины цистерны не допускается',
        ],
      },
      {
        code: '63630169',
        text:
          'В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?',
        answers: [
          'При расположении береговых насосов более чем в 100 м от причала',
          'Если это обосновано в проектной документации',
          'При расположении береговых насосов более чем в 50 м от причала',
          'В любом случае',
        ],
        correctAnswers: [
          'При расположении береговых насосов более чем в 100 м от причала',
        ],
      },
      {
        code: '63630170',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
          'В присутствии технического руководителя предприятия при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
          'По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'В присутствии начальника производства и начальника службы контрольно-измерительных приборов и автоматики (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63630171',
        text:
          'Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['Аварийная', 'Производственная', 'Дождевая', 'Бытовая'],
        correctAnswers: ['Аварийная'],
      },
      {
        code: '63630172',
        text:
          'При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?',
        answers: [
          'При обосновании в проектной документации',
          'При величине допустимого риска меньше, чем 10-6',
          'При разработке обоснования безопасности и реализации компенсирующих мероприятий',
        ],
        correctAnswers: ['При обосновании в проектной документации'],
      },
      {
        code: '63630173',
        text:
          'Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?',
        answers: [
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
          'При оснащении резервуарных парков газоуравнительной системой допускается объединять ею резервуары с авиационными и автомобильными бензинами',
          'Производительность наполнения (опорожнения) резервуаров может превышать суммарную пропускную способность установленных на резервуаре дыхательных и предохранительных устройств, если это обусловлено в проектной документации',
          'При обнаружении нарушения герметичности основного стального резервуара с защитной стенкой необходимо немедленно устранить причину утечки продукта',
        ],
        correctAnswers: [
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
        ],
      },
      {
        code: '63630174',
        text:
          'В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?',
        answers: [
          'В течение четырех лет',
          'В течение года',
          'В течение пяти лет',
          'В течение шести лет',
        ],
        correctAnswers: ['В течение четырех лет'],
      },
      {
        code: '63630175',
        text:
          'Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Входящие в состав проектной документации или пусковые',
          'Периодически пересматриваемые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63630176',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Лицом, утверждающим технологический регламент',
          'Комиссией организации под председательством представителя проектной организации',
          'Технологическим руководителем организации',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63630177',
        text:
          'В соответствии с требованиями какой документации проводятся все технологические операции по приему, хранению и разливу нефтепродуктов в тару? Выберите два правильных варианта ответа.',
        answers: [
          'Производственных инструкций, утвержденных эксплуатирующей организацией',
          'Правил промышленной безопасности складов нефти и нефтепродуктов',
          'Технических регламентов',
          'Проектной документации',
        ],
        correctAnswers: [
          'Производственных инструкций, утвержденных эксплуатирующей организацией',
          'Правил промышленной безопасности складов нефти и нефтепродуктов',
        ],
      },
      {
        code: '63630178',
        text:
          'Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?',
        answers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
          'Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц',
          'Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями',
          'Запрещено для защиты технологического оборудования использовать неметаллические покрытия',
        ],
        correctAnswers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
      },
      {
        code: '63630179',
        text:
          'В каком случае при использовании переносных подогревателей допускается непосредственный контакт теплоносителя с нефтепродуктом?',
        answers: [
          'Не допускается ни в каком случае',
          'Если это обусловлено проектной документацией',
          'Если подогреватели имеют взрывозащищенное исполнение',
          'Если давление пара в подогревателе соответствует показателям, установленным в технической документации (паспорте) пароподогревателя',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63630180',
        text:
          'В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?',
        answers: [
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
          'В соответствии с требованиями градостроительного законодательства',
          'В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов',
        ],
        correctAnswers: [
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
        ],
      },
      {
        code: '63630181',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Характеристика производимой продукции',
          'Возможные инциденты в работе и способы их ликвидации',
          'Технологические схемы производства',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63630182',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Только испытание на прочность пробным давлением',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63630183',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '12 месяцев',
          '3 месяца',
          '24 месяца',
          '36 месяцев',
          '6 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63630184',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          'Наибольший срок, предусмотренный для этих объектов',
          '3 года',
          '5 лет',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63630185',
        text:
          'До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?',
        answers: [
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
          'До момента заполнения резервуара до верхней проектной отметки',
          'До момента срабатывания блокировки ограничения скорости заполнения резервуара',
        ],
        correctAnswers: [
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
        ],
      },
      {
        code: '63630186',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное, дистанционное',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное, дистанционное управление, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63630187',
        text:
          'В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?',
        answers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
          'Допускается при условии разработки эксплуатирующей организацией компенсационных мер обеспечения требований пожарной и промышленной безопасности',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63630188',
        text:
          'Какая должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['40 °C', '34 °C', '60 °C'],
        correctAnswers: ['40 °C'],
      },
      {
        code: '63630189',
        text:
          'Каким должно быть максимальное сопротивление заземляющего устройства, предназначенного только для защиты от статического электричества?',
        answers: ['100 Ом', '80 Ом', '120 Ом', '150 Ом'],
        correctAnswers: ['100 Ом'],
      },
      {
        code: '63630190',
        text:
          'Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?',
        answers: [
          'Только наземные трубопроводы через каждые 200 м и дополнительно на каждом ответвлении с присоединением каждого ответвления к заземлителю',
          'Только металлические оголовки и патрубки гибких шлангов для слива и налива нефти и нефтепродуктов',
          'Только железнодорожные рельсы сливоналивных участков, электрически соединенные между собой, а также металлические конструкции сливоналивных эстакад с двух сторон по длине',
          'Все перечисленные конструкции',
        ],
        correctAnswers: ['Все перечисленные конструкции'],
      },
      {
        code: '63630191',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура в соответствии с проектной документацией',
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с пластмассовым уплотнением в затворе',
          'Арматура с тканевым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63630192',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63630193',
        text:
          'В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?',
        answers: [
          'В проектной документации',
          'В производственной инструкции по наливу нефти и нефтепродуктов',
          'В нормативных правовых актах в области охраны труда',
          'В нормативных правовых актах в области промышленной безопасности',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63630194',
        text:
          'В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Выберите два правильных варианта ответа.',
        answers: [
          'Проектной документации',
          'Технической документации организации-изготовителя',
          'Руководств по безопасной эксплуатации',
          'Внутренних инструкций эксплуатирующей организации',
        ],
        correctAnswers: [
          'Проектной документации',
          'Технической документации организации-изготовителя',
        ],
      },
      {
        code: '63630195',
        text:
          'Что из перечисленного не входит в общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
        correctAnswers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63630196',
        text:
          'Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Выберите два правильных варианта ответа.',
        answers: [
          'Из круглой стали диаметром более 6 мм',
          'Из полосовой стали сечением не менее 48 мм²',
          'Из стальной проволоки диаметром не менее 5 мм',
          'Из стальной ленты сечением не менее 24 мм²',
        ],
        correctAnswers: [
          'Из круглой стали диаметром более 6 мм',
          'Из полосовой стали сечением не менее 48 мм²',
        ],
      },
      {
        code: '63630197',
        text:
          'Кто утверждает график проведения периодической проверки стендеров?',
        answers: [
          'Эксплуатирующая организация',
          'Ростехнадзор',
          'Комиссия под председательством инспектора Ростехнадзора',
          'Проектная организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63630198',
        text:
          'Какими клапанами должны быть оборудованы резервуары для аварийного сброса нефти и нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Дыхательными клапанами',
          'Предохранительными клапанами',
          'Обратными клапанами',
          'Регулирующими клапанами',
        ],
        correctAnswers: [
          'Дыхательными клапанами',
          'Предохранительными клапанами',
        ],
      },
      {
        code: '63630199',
        text:
          'Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчик процесса',
          'Разработчик проекта',
          'Заказчик в задании на проектирование',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63630200',
        text:
          'На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?',
        answers: [
          'Не менее 10 м до и после нефтеловушек',
          'Не менее 15 м после нефтеловушек',
          'В непосредственной близости от нефтеловушек',
        ],
        correctAnswers: ['Не менее 10 м до и после нефтеловушек'],
      },
      {
        code: '63630201',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 10 минут',
          'Через 30 минут',
          'Через 60 минут',
          'Через 5 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63630202',
        text:
          'Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
          'Состав и дислокацию сил и средств',
          'Организацию управления, связи и оповещения при аварии на объекте, первоочередные действия при получении сигнала об авариях на объекте',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте',
          'Мероприятия, направленные на обеспечение безопасности населения, сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Организацию взаимодействия сил и средств',
        ],
        correctAnswers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
        ],
      },
      {
        code: '63630203',
        text:
          'Каким давлением испытываются грузовые шланги, находящиеся в эксплуатации, на сливоналивных причалах для выявления утечки содержимого шланга или смещения его концевых соединительных устройств?',
        answers: [
          'Давлением, значение которого составляет 1,5 номинального рабочего давления',
          'Давлением, значение которого составляет 2,0 номинального рабочего давления',
          'Давлением, значение которого составляет 2,5 номинального рабочего давления',
          'Номинальным рабочим давлением',
        ],
        correctAnswers: [
          'Давлением, значение которого составляет 1,5 номинального рабочего давления',
        ],
      },
      {
        code: '63630204',
        text:
          'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63630205',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63630206',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более 3 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63630207',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Только при деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63630208',
        text:
          'При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?',
        answers: [
          '50% объемных от НКПРП',
          '20% объемных от НКПРП',
          '40% объемных от НКПРП',
          '30% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63630209',
        text:
          'Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?',
        answers: [
          'Организация-изготовитель',
          'Орган по сертификации',
          'Разработчик документации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63630210',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях регламентации действий персонала при возникновении аварии',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63630211',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'Только в проектной документации',
          'Только в технологическом регламенте на производство продукции',
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63630212',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
        ],
        correctAnswers: [
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
        ],
      },
      {
        code: '63630213',
        text:
          'Что из перечисленного не требует защиты от прямых ударов молнии?',
        answers: [
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
          'Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней',
          'Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м',
        ],
        correctAnswers: [
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
        ],
      },
      {
        code: '63630214',
        text:
          'С какой скоростью следует снижать уровень воды в оборудовании после заполнения для обеспечения медленного окисления пирофорных отложений при очистке оборудования от пирофорных соединений?',
        answers: [
          'Не более 0,5 м в час',
          'Не более 1 м в час',
          'Не более 1,2 м в час',
          'Не более 0,8 м в час',
        ],
        correctAnswers: ['Не более 0,5 м в час'],
      },
      {
        code: '63630215',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63630216',
        text:
          'В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?',
        answers: [
          'Допускается при обосновании принятого технического решения в проектной документации',
          'Допускается в случае, если данное условие их применения определено в технической документации организации-изготовителя',
          'Допускается при согласовании с организацией-изготовителем и федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается при обосновании принятого технического решения в проектной документации',
        ],
      },
      {
        code: '63630217',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Только на единицу времени (час)',
          'Только на единицу выпускаемой продукции',
          'Только на один производственный поток или на мощность производства в целом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63630218',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 8 лет',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63630219',
        text:
          'Какой документ должен быть оформлен для проведения земляных работ на территории опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Наряд-допуск',
          'Акт-допуск',
          'Проект производства работ',
          'Письменное разрешение технического руководителя организации',
        ],
        correctAnswers: ['Наряд-допуск'],
      },
      {
        code: '63630220',
        text:
          'Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте (далее - силы и средства), и их соответствие задачам по локализации и ликвидации последствий аварий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварийных ситуаций',
        ],
        correctAnswers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
        ],
      },
      {
        code: '63630221',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система водяного отопления',
          'Система парового отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63630222',
        text:
          'С какой периодичностью следует визуально проверять целостность заземления элементов сливоналивных устройств, соединенных шарнирами с сальниковыми уплотнениями, изготовленными из неметаллических материалов, с регистрацией (записью) результатов осмотра в журнале приема-передачи смены?',
        answers: [
          'Каждую смену',
          'Еженедельно',
          'Ежемесячно',
          'Каждые три смены',
        ],
        correctAnswers: ['Каждую смену'],
      },
      {
        code: '63630223',
        text:
          'Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 0,4 м',
          'Не менее 0,5 м',
          'Не менее 0,6 м',
        ],
        correctAnswers: ['Не менее 0,2 м'],
      },
      {
        code: '63630224',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63630225',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'На трубопроводах, проложенных по эстакадам',
          'На трубопроводах, идущих по стенам зданий',
          'Над автодорогами',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63630226',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'Если выбросы рассчитываются, в количествах определяемых условиями безопасной остановки технологического процесса',
          'В случае использования специальных систем аварийного освобождения',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63630227',
        text:
          'С какой периодичностью заземлители, токоотводы подвергаются периодическому контролю?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в год',
          'Один раз в 3 года',
          'Один раз в 2 года',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63630228',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Осуществляется все перечисленное',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Испытание трубопровода на прочность и плотность',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63630229',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
          'Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов',
          'Провести реконструкцию химически опасного производственного объекта',
          'Провести экспертизу промышленной безопасности',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
        ],
      },
      {
        code: '63630230',
        text:
          'В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?',
        answers: [
          'При расположении береговых насосов более чем в 100 м от причала',
          'Если это обосновано в проектной документации',
          'При расположении береговых насосов более чем в 50 м от причала',
          'В любом случае',
        ],
        correctAnswers: [
          'При расположении береговых насосов более чем в 100 м от причала',
        ],
      },
    ],
    63633: [
      {
        code: '63633000',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего компрессора',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63633001',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
          'В соответствии с проектной документацией',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с декларацией промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63633002',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63633003',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите 2 правильных варианта ответа.',
        answers: [
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводится не реже двух раз в смену',
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
        ],
        correctAnswers: [
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
        ],
      },
      {
        code: '63633004',
        text:
          'В каком случае допускается определение толщин стенок трубопроводов иным способом, отличным от метода неразрушающего контроля?',
        answers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
          'Допускается в соответствии с документацией на ХОПО',
          'Допускается в присутствии инспектора Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
        ],
      },
      {
        code: '63633005',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?',
        answers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
          'Не менее 5 независимых датчиков с раздельными точками отбора',
          'Правилами безопасности химически опасных производственных объектов не регламентируется',
          'Не более 2 независимых датчиков с раздельными точками отбора',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63633006',
        text:
          'Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
          'Средствами контроля за фракционным составом измельченных твердых горючих продуктов',
          'Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут',
          'Средствами контроля за температурой измельченных твердых горючих продуктов',
        ],
        correctAnswers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
        ],
      },
      {
        code: '63633007',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63633008',
        text:
          'На основе каких данных составляется материальный баланс для действующих производств?',
        answers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
          'Материальный баланс для действующих производств составляется по данным проекта с учетом внесенных в проект изменений, включения или исключения дополнительных операций или стадий',
          'Материальный баланс для действующих производств составляется по данным технологического регламента',
        ],
        correctAnswers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
        ],
      },
      {
        code: '63633009',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'Нужно в любом случае',
          'По решению руководителя эксплуатирующей организации',
          'Правилами не регламентируется',
          'Решение принимается совместно руководителем эксплуатирующей организации и руководителем газоспасательной службы',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63633010',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
          'Организовать изготовление необходимых узлов и деталей для замены',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63633011',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Контроль сплошности изоляции и толщины стенок',
          'Промывка (пропарка)',
          'Продувка',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63633012',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63633013',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Производить подготовку объекта к проведению газоопасных работ',
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Непосредственно участвовать в выполнении газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63633014',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'На факельную установку',
          'В специализированные контейнеры',
          'В закрытые системы для дальнейшей утилизации',
          'В централизованную систему водоотведения',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63633015',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Комиссией организации под председательством представителя проектной организации',
          'Лицом, утверждающим технологический регламент',
          'Технологическим руководителем организации',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63633016',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63633017',
        text:
          'На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Выберите 2 правильных варианта ответа.',
        answers: [
          'На объектах ХОПО I класса опасности',
          'На объектах ХОПО II класса опасности',
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО IV класса опасности',
          'На всех объектах ХОПО',
        ],
        correctAnswers: [
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО IV класса опасности',
        ],
      },
      {
        code: '63633018',
        text:
          'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63633019',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Техническими характеристиками применяемых насосов и компрессоров',
          'Критическими параметрами технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63633020',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Непосредственный руководитель работ и лица ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63633021',
        text:
          'Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?',
        answers: [
          'Справочной литературой',
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
          'Данными, запрашиваемыми у научно-исследовательской организации',
          'Научно-технической литературой',
        ],
        correctAnswers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
        ],
      },
      {
        code: '63633022',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
          'Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63633023',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Наличие природных оврагов, выемок, низин',
          'Устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Все перечисленное',
          'Траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63633024',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Все перечисленные',
          'На единицу времени (час)',
          'На единицу выпускаемой продукции',
          'На один производственный поток или на мощность производства в целом',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63633025',
        text:
          'Какая должна быть ширина основных проходов (для транспортирования бочек) в складе желтого фосфора при хранении его в бочках?',
        answers: [
          'Не менее 1,8 м',
          'Не менее 2,2 м',
          'Не менее 2 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 1,8 м'],
      },
      {
        code: '63633026',
        text:
          'На какой уровень должны быть заглублены полуподземные резервуары и хранилища фосфора?',
        answers: [
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
          'На уровень, обеспечивающий вместимость не менее 30% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
        ],
        correctAnswers: [
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
        ],
      },
      {
        code: '63633027',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'Время устанавливается в проектной документации',
          'В течение 8 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63633028',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На организацию - разработчика процесса',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На технологическую службу организации, производства, отделения, установки',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63633029',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63633030',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите 2 правильных варианта ответа.',
        answers: [
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
        ],
        correctAnswers: [
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
        ],
      },
      {
        code: '63633031',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
          'В течение 8 часов',
          'Время устанавливается в проектной документации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63633032',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63633033',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63633034',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'В соответствии с проектной документацией',
          'В соответствии с рабочей документацией',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63633035',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы в кДж',
          'Категорией взрывоопасности технологических блоков',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Радиусом зон разрушения в м',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63633036',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63633037',
        text:
          'Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?',
        answers: [
          'Технологическим регламентом',
          'Технической документацией производителя',
          'Проектной документацией',
          'Исходными данными на проектирование',
        ],
        correctAnswers: ['Технической документацией производителя'],
      },
      {
        code: '63633038',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63633039',
        text:
          'Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при QВ ≤ 10 допускается ручное дистанционное управление',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63633040',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63633041',
        text:
          'Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?',
        answers: [
          'Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями',
          'Запрещено для защиты технологического оборудования использовать неметаллические покрытия',
          'Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже одного раза в месяц',
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
        correctAnswers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
      },
      {
        code: '63633042',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 18% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63633043',
        text:
          'Что необходимо сделать перед включением электропечи после ремонта, выполненного с ее разгерметизацией?',
        answers: [
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%',
          'Продуть все аппараты и газоходы инертным газом до содержания азота не более 2%',
          'Очистить все аппараты и газоходы и провести осмотр',
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода более 10%',
        ],
        correctAnswers: [
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%',
        ],
      },
      {
        code: '63633044',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - маслом, продувка - паром',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63633045',
        text:
          'Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Периодически пересматриваемые',
          'Входящие в состав проектной документации или пусковые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63633046',
        text:
          'Как необходимо составлять описание схемы процесса при наличии нескольких аналогичных технологических ниток?',
        answers: [
          'Допускается делать описание схемы процесса не менее чем по двум технологическим ниткам',
          'Допускается делать описание схемы процесса по одной технологической нитке',
          'Необходимо представить описание всех технологических ниток',
        ],
        correctAnswers: [
          'Допускается делать описание схемы процесса по одной технологической нитке',
        ],
      },
      {
        code: '63633047',
        text:
          'Какие требования предъявляются к работе адсорбера воздухоразделительной установки?',
        answers: [
          'Адсорберы должны заполняться любым адсорбентом, имеющимся в наличии',
          'Адсорберы должны заполняться только адсорбентом, прошедшим периодическую регенерацию в соответствии с технологической инструкцией',
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки',
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технологической инструкцией',
        ],
        correctAnswers: [
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки',
        ],
      },
      {
        code: '63633048',
        text:
          'Какие требования установлены к отделениям, в которых производят дробление пека, приготовление или разогрев электродной массы, и к оборудованию в этих отделениях?',
        answers: [
          'Отделения должны быть изолированы от остальных рабочих помещений, к оборудованию специальных требований не установлено',
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть снабжено средствами местного отсоса',
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть герметичным',
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
        ],
        correctAnswers: [
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
        ],
      },
      {
        code: '63633049',
        text:
          'Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке оборудования?',
        answers: [
          'Наличие оросительных систем',
          'Наличие средств гидравлической, механической или химической очистки',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Возможность изоляции соседнего оборудования',
        ],
        correctAnswers: [
          'Наличие средств гидравлической, механической или химической очистки',
        ],
      },
      {
        code: '63633050',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите 2 правильных варианта ответа.',
        answers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
          'Начальник производственно-технического (технического) отдела организации',
          'Начальник производства',
        ],
        correctAnswers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
      },
      {
        code: '63633051',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Предотвращение взрывов внутри технологического оборудования',
          'Периодический контроль за состоянием воздушной среды',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63633052',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'По II или III категории надежности',
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
          'По I или II категории надежности',
          'Только по I категории надежности',
        ],
        correctAnswers: ['По I или II категории надежности'],
      },
      {
        code: '63633053',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63633054',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только испытание на прочность пробным давлением',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63633055',
        text:
          'В каких условиях должна проводиться реакция получения фосфида цинка?',
        answers: [
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воздуха в реактор',
          'Перед установкой реактора необходимо включить муфель',
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор',
          'Перед дозировкой фосфора реактор должен быть тщательно продут инертным газом в течение не более 3 минут',
        ],
        correctAnswers: [
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор',
        ],
      },
      {
        code: '63633056',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Газоопасные работы", "Газ"',
          '"Опасно для жизни!", "Внимание"',
          '"Работают люди!", "Опасно для жизни!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63633057',
        text:
          'Что должна обеспечивать система электрического управления механизмами поточно-транспортных систем при производстве фосфора и его соединений?',
        answers: [
          'Аварийное отключение транспортеров с помощью троса, соединенного с выключателем',
          'Электрическую блокировку всех механизмов от завала транспортируемых веществ с применением реле скорости для элеваторов и транспортеров',
          'Предпусковую звуковую сигнализацию',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63633058',
        text:
          'В каком случае допускается объединение трубопроводов для слива жидких продуктов из воздухоразделительных установок?',
        answers: [
          'Не допускается ни в каком случае',
          'Если воздухоразделительные установки малой производительности',
          'Если получено такое заключение от территориального органа Ростехнадзора в каждом конкретном случае',
          'Если это обосновывается в проектной документации (документации) в каждом конкретном случае',
        ],
        correctAnswers: [
          'Если это обосновывается в проектной документации (документации) в каждом конкретном случае',
        ],
      },
      {
        code: '63633059',
        text:
          'Какая устанавливается минимальная высота гидрозатвора в приемном баке при гидравлическом способе удаления пыли из электрофильтров при производстве фосфора и его соединений?',
        answers: [
          '200 мм с учетом конуса, образующегося при работе мешалки',
          '300 мм с учетом конуса, образующегося при работе мешалки',
          '100 мм с учетом конуса, образующегося при работе мешалки',
          '400 мм с учетом конуса, образующегося при работе мешалки',
        ],
        correctAnswers: [
          '200 мм с учетом конуса, образующегося при работе мешалки',
        ],
      },
      {
        code: '63633060',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63633061',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63633062',
        text:
          'Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите 2 правильных варианта ответа.',
        answers: [
          'Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений',
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
        correctAnswers: [
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
      },
      {
        code: '63633063',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63633064',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63633065',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
          'В течение 8 часов',
          'В течение времени, установленного проектной документацией',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63633066',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
          'Руководитель структурного подразделения или лицо, его замещающее',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63633067',
        text:
          'В каких случаях работу насосов жидких продуктов разделения воздуха требуется остановить для ремонта?',
        answers: [
          'В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 23% или уменьшилась до 19%',
          'Только в случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа',
          'В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 22% или уменьшилась до 19%',
          'Только при повышении объемной доли кислорода в помещении размещения насосов до 23% или уменьшении до 20%',
        ],
        correctAnswers: [
          'В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 23% или уменьшилась до 19%',
        ],
      },
      {
        code: '63633068',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники подрядной организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Правилами не регламентируется',
          'Работники, указанные в наряде-допуске',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63633069',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63633070',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63633071',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Непрерывный контроль состояния воздушной среды',
          'Наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
          'Наличие у каждого работающего в емкости (аппарате) спасательного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63633072',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63633073',
        text:
          'Какие требования установлены для трубопроводов, предназначенных для транспортирования фосфора и фосфорного шлама?',
        answers: [
          'Фосфоропроводы прокладываются в закрытых галереях эстакадного типа',
          'Трубопроводы для транспортирования фосфора и фосфорного шлама не допускается прокладывать с обогревающим спутником в одной изоляции',
          'Трубопроводы фосфора и фосфорного шлама следует продувать инертным газом до и после каждой перекачки фосфора',
          'Расстояние по горизонтали от трубопроводов фосфора, фосфорного шлама до трубопроводов, содержащих пожароопасные и токсичные продукты, не должно быть менее 1,5 м',
        ],
        correctAnswers: [
          'Расстояние по горизонтали от трубопроводов фосфора, фосфорного шлама до трубопроводов, содержащих пожароопасные и токсичные продукты, не должно быть менее 1,5 м',
        ],
      },
      {
        code: '63633074',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом главного инженера организации (технического директора, директора по производству',
          'Приказом комиссии организации',
          'Приказом руководителя организации',
          'Приказом технологической службы организации, производства, отделения, установки',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63633075',
        text:
          'В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'В технологическом регламенте',
          'В проектной документации',
          'В исходных данных на проектирование и технологическом регламенте',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63633076',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63633077',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63633078',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений, должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 20% НКПР',
          'При 10% НКПР',
          'При 18% НКПР',
          'При 15% НКПР',
        ],
        correctAnswers: ['При 20% НКПР'],
      },
      {
        code: '63633079',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63633080',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случае изгиба болтов и шпилек',
          'В случае износа боковых граней головок болтов и гаек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63633081',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63633082',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 3 лет',
          'Не более 5 лет',
          'Не более 10 лет',
          'Не более 1 года',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63633083',
        text:
          'Что следует выполнить организации, эксплуатирующей объекты производства продуктов разделения воздуха, в случае, когда в процессе эксплуатации технического устройства степень загрязнения воздуха превысит допустимую норму?',
        answers: [
          'Остановить работу технического устройства',
          'Следует выполнить мероприятия по оснащению такого технического устройства дополнительными средствами очистки воздуха, обеспечивающими безопасность его дальнейшей работы',
          'Продолжить работу с проведением периодического мониторинга степени загрязнения перерабатываемого воздуха',
          'Заменить или самостоятельно модернизировать техническое устройство',
        ],
        correctAnswers: [
          'Следует выполнить мероприятия по оснащению такого технического устройства дополнительными средствами очистки воздуха, обеспечивающими безопасность его дальнейшей работы',
        ],
      },
      {
        code: '63633084',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63633085',
        text:
          'В каких случаях на трубопроводах следует применять арматуру под приварку?',
        answers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа',
        ],
        correctAnswers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
        ],
      },
      {
        code: '63633086',
        text:
          'Что должна обеспечивать система контроля и автоматизации воздухоразделительной установки при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом?',
        answers: [
          'Звуковую сигнализацию при отклонении технологических параметров, определенных технологическим регламентом',
          'Автоматическое отключение устройств установки при отклонении технологических параметров, определенных технологическим регламентом',
          'Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом',
          'Включение резервных (дополнительных) модернизированных устройств, обеспечивающих безопасность дальнейшей работы систем и устройств',
        ],
        correctAnswers: [
          'Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом',
        ],
      },
      {
        code: '63633087',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Не допускается',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63633088',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Руководителю эксплуатирующей организации',
          'Лицу, ответственному за проведение газоопасной работы',
          'Пожарной службе эксплуатирующей организации',
          'Начальнику цеха',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63633089',
        text:
          'Какое из перечисленных требований к феррофосфорам указано верно?',
        answers: [
          'Под феррофосфорными летками в перерывах между выпусками феррофосфора должен быть установлен ковш',
          'После уборки феррофосфора из приямков наличие в них влаги допускается в соответствии с инструкцией, утвержденной техническим руководителем организации',
          'Во время охлаждения феррофосфора водой допускается попадание в приямок жидкого феррофосфора из печи',
          'В аварийных случаях феррофосфор сливают в аварийные приямки или в аварийные емкости, где по истечении 3 ч после слива его охлаждают водой',
        ],
        correctAnswers: [
          'Под феррофосфорными летками в перерывах между выпусками феррофосфора должен быть установлен ковш',
        ],
      },
      {
        code: '63633090',
        text:
          'Какие требования безопасности необходимо выполнить при остановках воздухоразделительных установок продолжительностью более 8 часов?',
        answers: [
          'Слить кубовую жидкость из адсорберов и заменить адсорбент',
          'Жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент - подвергнуть регенерации',
          'Жидкий кислород и кубовую жидкость из адсорберов не сливать',
          'Слить жидкий кислород, а кубовую жидкость из адсорберов не сливать',
        ],
        correctAnswers: [
          'Жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент - подвергнуть регенерации',
        ],
      },
      {
        code: '63633091',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63633092',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Устройствами для подключения линий водорода',
          'Циклонами',
          'Устройствами для подключения линий воды, пара, инертного газа',
          'Виброситом',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63633093',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
          'Организационно-распорядительными документами организации',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63633094',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом исключающим возможность искрообразования',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения представителя газоспасательной службы и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63633095',
        text:
          'Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?',
        answers: [
          'Произвести запись в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен) и наряде-допуске на проведение газоопасных работ',
          'Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ',
          'Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ',
          'Проверять состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
        correctAnswers: [
          'Проверять состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
      },
      {
        code: '63633096',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите 2 правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от датчиков повышения температуры',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63633097',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63633098',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63633099',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: [
          'На одну выше',
          'I категории',
          'II категории',
          'III категории',
        ],
        correctAnswers: ['На одну выше'],
      },
      {
        code: '63633100',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, меры взрывобезопасности, показатели пожароопасности и токсичности',
          'Показатели взрывопожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63633101',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63633102',
        text:
          'Что необходимо делать для обеспечения взрывобезопасной эксплуатации воздухоразделительных установок организации, эксплуатирующей объекты производства продуктов разделения воздуха?',
        answers: [
          'Проводить периодическое обследование состояния установок в соответствии с требованиями технологического регламента',
          'Допускать к работе на установках обученный и аттестованный персонал',
          'Контролировать герметичность установок',
          'Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента',
        ],
        correctAnswers: [
          'Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента',
        ],
      },
      {
        code: '63633103',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63633104',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?',
        answers: [
          'Не менее 12 секунд',
          'Не более 120 секунд',
          'Не менее 240 секунд',
          'Не менее 300 секунд',
        ],
        correctAnswers: ['Не более 120 секунд'],
      },
      {
        code: '63633105',
        text:
          'Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?',
        answers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
          'Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой каждой отдельных единиц оборудования',
          'В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание',
          'Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание',
        ],
        correctAnswers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
        ],
      },
      {
        code: '63633106',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63633107',
        text:
          'Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63633108',
        text:
          'Какая концентрация взрывоопасных примесей допускается в жидком кислороде?',
        answers: [
          'Не превышающая нормы, установленной технологическим процессом',
          'Не превышающая предельно допустимой нормы',
          'Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса',
          'Не превышающая 19% нормы, установленной разработчиком воздухоразделительной установки',
        ],
        correctAnswers: [
          'Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса',
        ],
      },
      {
        code: '63633109',
        text:
          'Какое давление должно быть обеспечено внутри аппаратов для реактора и сборника для пятисернистого фосфора?',
        answers: [
          'Не более 25 мм вод. ст.',
          'Не более 35 мм вод. ст.',
          'Не более 30 мм вод. ст.',
          'Не более 50 мм вод. ст.',
        ],
        correctAnswers: ['Не более 25 мм вод. ст.'],
      },
      {
        code: '63633110',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63633111',
        text:
          'На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?',
        answers: [
          'На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
          'На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'На алгоритмах, разработанных по сценариям всех возможных аварий и их развития',
        ],
        correctAnswers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
        ],
      },
      {
        code: '63633112',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63633113',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите 2 правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63633114',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Характеристика производимой продукции',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Технологические схемы производства',
          'Возможные инциденты в работе и способы их ликвидации',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63633115',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63633116',
        text:
          'Какие из документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ указаны неверно?',
        answers: [
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Проект производства ремонтных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63633117',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
          'Руководитель структурного подразделения, не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63633118',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С газоспасательной службой и службой охраны труда',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63633119',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Иметь документы о прохождении аттестации',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63633120',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Технологический регламент',
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63633121',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Только устройство световой сигнализации',
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Только устройство звуковой сигнализации',
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63633122',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Защитными очками и защитной каской',
          'Воздушными изолирующими аппаратами',
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Всеми перечисленными средствами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63633123',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Все ответы неверны',
          'Не менее 6',
          'Не менее 15',
          'Не менее 10',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63633124',
        text:
          'Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 100% длины сварного шва каждого сварного соединения',
          'Определяется специалистами неразрушающего контроля',
          'Не менее 75% длины сварного шва каждого сварного соединения',
          'Не менее 50% длины сварного шва каждого сварного соединения',
        ],
        correctAnswers: [
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
      },
      {
        code: '63633125',
        text:
          'Какие требования к барабанам и контейнерам, заполненным пятисернистым фосфором, установлены Правилами безопасности химически опасных производственных объектов?',
        answers: [
          'Барабаны и контейнеры должны быть оборудованы приборами, сигнализирующими об их переполнении',
          'Барабаны и контейнеры должны быть герметично закрыты и храниться в сухих, проветриваемых складах',
          'Барабаны и контейнеры следует хранить в сухих, непроветриваемых помещениях',
          'Барабаны и контейнеры должны храниться при температуре не выше +10 °С',
        ],
        correctAnswers: [
          'Барабаны и контейнеры должны быть герметично закрыты и храниться в сухих, проветриваемых складах',
        ],
      },
      {
        code: '63633126',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Главным инженером эксплуатирующей организации',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63633127',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту',
          'Должно быть организовано управление дистанционно (из безопасного места)',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63633128',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите 2 правильных варианта ответа.',
        answers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Приборами измерения перепада давления на сепараторе',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63633129',
        text:
          'Каким должен быть остаточный слой материалов при разгрузке приемных бункеров для предотвращения поступления запыленного воздуха в помещение при производстве фосфора и его соединений?',
        answers: [
          'На 0,7 м выше разгрузочного проема',
          'На 0,5 м выше разгрузочного проема',
          'На 0,8 м выше разгрузочного проема',
          'На 1,1 м выше разгрузочного проема',
        ],
        correctAnswers: ['На 0,7 м выше разгрузочного проема'],
      },
      {
        code: '63633130',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63633131',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Специализированной сторонней организацией',
          'Организацией-разработчиком процесса',
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63633132',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Аварийно-спасательной службе организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63633133',
        text: 'Какой устаналивается срок действия "Накопительной ведомости"?',
        answers: ['6 месяцев', '1 месяц', '3 месяца', 'Один год'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63633134',
        text:
          'Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63633135',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 10 минут',
          'Не менее 30 минут',
          'Не менее 15 минут',
          'Не менее 5 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63633136',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63633137',
        text:
          'Какого показателя категорий взрывоопасности технологических блоков не существует?',
        answers: [
          'I категории',
          'II категории',
          'III категории',
          'IV категории',
        ],
        correctAnswers: ['IV категории'],
      },
      {
        code: '63633138',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Во всех указанных местах',
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
        ],
        correctAnswers: ['Во всех указанных местах'],
      },
      {
        code: '63633139',
        text:
          'Кто устанавливает назначенный срок службы для технологических трубопроводов?',
        answers: [
          'Орган по сертификации',
          'Разработчик документации',
          'Организация-изготовитель',
          'Орган по сертификации на основании заключения испытательной лаборатории',
        ],
        correctAnswers: ['Разработчик документации'],
      },
      {
        code: '63633140',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На сети водоснабжения и канализации',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63633141',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63633142',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63633143',
        text:
          'В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?',
        answers: [
          'В журнале ведения технологического процесса',
          'В наряде-допуске на проведение газоопасных работ',
          'В технологическом регламенте и производственных инструкциях по рабочим местам',
          'В специально разрабатываемой эксплуатирующей организацией инструкции',
        ],
        correctAnswers: ['В наряде-допуске на проведение газоопасных работ'],
      },
      {
        code: '63633144',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
          'Комиссия эксплуатирующей организации',
          'Инспектор территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63633145',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63633146',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Руководства по эксплуатации технологического оборудования и их ксерокопии',
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
          'Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах',
          'Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63633147',
        text:
          'Какая вместимость емкостей с фосфором допустима при их установке в производственном помещении?',
        answers: [
          'Не более 20 м³',
          'Не более 50 м³',
          'Количество фосфора не должно превышать 2-суточной потребности',
          'Количество фосфора не должно превышать 3-суточной потребности',
        ],
        correctAnswers: ['Не более 20 м³'],
      },
      {
        code: '63633148',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63633149',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О знании правил оказания первой помощи',
          'О наличии медицинских противопоказаний к работе',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63633150',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В руководствах по безопасности',
          'В технологическом регламенте',
          'В техническом регламенте',
          'В проектной документации',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63633151',
        text:
          'Какие технические требования необходимо обеспечивать конденсаторам-испарителям при работе воздухоразделительных установок?',
        answers: [
          'Проточность конденсаторов-испарителей в соответствии с технологическим регламентом',
          'Оптимальный расход воздуха, перерабатываемого установкой во избежание перехода конденсаторов-испарителей в режим работы без циркуляции',
          'Своевременное обслуживание в соответствии с требованиями инструкции по эксплуатации',
          'Своевременный ремонт и обслуживание для поддержания эффективности работы',
        ],
        correctAnswers: [
          'Проточность конденсаторов-испарителей в соответствии с технологическим регламентом',
        ],
      },
      {
        code: '63633152',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63633153',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63633154',
        text:
          'В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?',
        answers: [
          'В плане по локализации аварийных ситуаций',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'В Положении о производственном контроле',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63633155',
        text:
          'Какое максимальное разрежение поддерживается для предотвращения попадания фосфорного ангидрида в атмосферу цеха в башне сжигания?',
        answers: [
          '5 мм вод. ст.',
          '10 мм вод. ст.',
          '6 мм вод. ст.',
          '3 мм вод. ст.',
        ],
        correctAnswers: ['5 мм вод. ст.'],
      },
      {
        code: '63633156',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 20 лет',
          'Не более 5 лет',
          'Не более 3 лет',
          'Не более 10 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63633157',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура в соответствии с проектной документацией',
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с тканевым уплотнением в затворе',
          'Арматура с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63633158',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63633159',
        text: 'Каким образом необходимо наполнять цистерны фосфором?',
        answers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 25 °С. Слой воды или раствора должен быть высотой не менее 100 мм, свободное пространство цистерны - не менее 10%',
          'Перед наполнением цистерн их необходимо продуть инертным газом',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °С. Слой воды или раствора должен быть высотой не менее 500 мм',
        ],
        correctAnswers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
        ],
      },
      {
        code: '63633160',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Увеличить число наблюдающих и работающих',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Установить систему подачи условных сигналов',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63633161',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63633162',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63633163',
        text:
          'Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?',
        answers: [
          'Допускать к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Иметь специализированные центры обучения и подготовки для производственного персонала',
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
          'Иметь компьютерные тренажеры, включающие приближенные к реальным динамические модели процессов и средства управления',
        ],
        correctAnswers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
      },
      {
        code: '63633164',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63633165',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['40 °C', '45 °C', '50 °C', '55 °C'],
        correctAnswers: ['50 °C'],
      },
      {
        code: '63633166',
        text:
          'Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке технологического оборудования?',
        answers: [
          'Наличие средств гидравлической, механической или химической чистки',
          'Наличие оросительных систем',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Возможность изоляции соседнего оборудования',
        ],
        correctAnswers: [
          'Наличие средств гидравлической, механической или химической чистки',
        ],
      },
      {
        code: '63633167',
        text:
          'Какие требования безопасности предъявляются к пуску воздухоразделительной установки при уровне жидкого кислорода (жидкого воздуха) в основных конденсаторах-испарителях меньше номинального?',
        answers: [
          'Пуск воздухораспределительной установки должен осуществляться в рабочем режиме',
          'Пуск воздухораспределительной установки должен осуществляться в режиме накопления жидкости',
          'Пуск воздухораспределительной установки должен осуществляться в соответствии с инструкцией по эксплуатации установки',
          'Пуск воздухораспределительной установки должен осуществляться в соответствии с картой технологического процесса',
        ],
        correctAnswers: [
          'Пуск воздухораспределительной установки должен осуществляться в режиме накопления жидкости',
        ],
      },
      {
        code: '63633168',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Во всех перечисленных',
          'В исходных данных на проектирование',
          'В проектной документации',
          'В технологическом регламенте на производство продукции',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63633169',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63633170',
        text: 'Чем должно быть оснащено оборудование для разделения суспензий?',
        answers: [
          'Гидрозатвором',
          'Фильтрами',
          'Обратным клапаном',
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
        correctAnswers: [
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63633171',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63633172',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'Во время грозы',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63633173',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Предохранительный клапан',
          'Запорный клапан',
          'Обратный клапан',
          'Регулирующий клапан',
          'Гидроклапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63633174',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 10 минут',
          'Через 5 минут',
          'Через 60 минут',
          'Через 30 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63633175',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчиком процесса',
          'Разработчиком проекта',
          'Заказчиком в задании на проектирование',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63633176',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
          'В течение 8 часов',
          'Время устанавливается в проектной документации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63633177',
        text:
          'На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?',
        answers: [
          'Не менее 2,2 м',
          'Не более 2,2 м',
          'Не более 3,4 м',
          'Не менее 3,4 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63633178',
        text:
          'В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?',
        answers: [
          'Не допускается ни в каком случае',
          'Объемом до 1000 кг/год',
          'Объемом до 1500 кг/год',
        ],
        correctAnswers: ['Объемом до 1000 кг/год'],
      },
      {
        code: '63633179',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, список которых определяется внутренними документами организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта совместно с работниками аварийно-спасательных подразделений',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63633180',
        text:
          'Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?',
        answers: [
          'Разовые (опытные) технологические регламенты',
          'Постоянные технологические регламенты',
          'Лабораторные технологические регламенты',
          'Временные технологические регламенты',
        ],
        correctAnswers: ['Разовые (опытные) технологические регламенты'],
      },
      {
        code: '63633181',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63633182',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63633183',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Только средствами автоматического регулирования',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
          'Только средствами противоаварийной защиты',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63633184',
        text:
          'Какие меры безопасности должны соблюдаться при хранении и перекачке фосфора и фосфорного шлама?',
        answers: [
          'Температура фосфора и фосфорного шлама не должна быть более 80 °С',
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены приборами контроля давления пара',
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены устройствами ("воздушками") для предотвращения образования вакуума и попадания фосфора в паропровод',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63633185',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству',
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63633186',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Рациональный подбор взаимодействующих компонентов, исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Нарушение энергообеспечения',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63633187',
        text:
          'Какой должна быть вместимость поддона, на который следует устанавливать производственные емкости с фосфором?',
        answers: [
          'Объемом, равным сумме объемов всех производственных емкостей с фосфором',
          'Объемом не менее вместимости одного наибольшего резервуара и слоя воды не более 100 мм',
          'Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм',
          'Объемом не менее емкости резервуара с фосфором',
        ],
        correctAnswers: [
          'Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм',
        ],
      },
      {
        code: '63633188',
        text:
          'Какими автоматическими устройствами необходимо оснащать системы азотно-водяного охлаждения воздухоразделительных установок?',
        answers: [
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 5%',
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере',
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 10%',
          'Исключающими подачу продукционного азота',
        ],
        correctAnswers: [
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере',
        ],
      },
      {
        code: '63633189',
        text:
          'Под каким слоем воды должны постоянно находится в аппаратах фосфор и фосфорный шлам?',
        answers: [
          'Не менее 500 мм',
          'Не менее 200 мм',
          'Не менее 100 мм',
          'Не менее 300 мм',
        ],
        correctAnswers: ['Не менее 300 мм'],
      },
      {
        code: '63633190',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и устанавливается для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и устанавливается для блоков I и II категорий',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63633191',
        text:
          'Какой показатель необходимо контролировать для предотвращения попадания фосфорной кислоты в оборотную систему водоснабжения?',
        answers: [
          'рН нагретой воды',
          'Температуру раствора',
          'Давление в системе',
          'Все перечисленные',
        ],
        correctAnswers: ['рН нагретой воды'],
      },
      {
        code: '63633192',
        text:
          'Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?',
        answers: [
          'Из стали',
          'Из чугуна',
          'Из стале-алюминиевых сплавов',
          'Из неметаллических конструкционных материалов',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63633193',
        text:
          'В каких условиях должна проводиться реакция синтеза пятисернистого фосфора?',
        answers: [
          'В герметичных аппаратах в атмосфере инертного газа',
          'В герметичной таре',
          'В охлаждаемых аппаратах, снабженных устройствами для отвода тепла',
          'В аппаратах, обогреваемых паром',
        ],
        correctAnswers: ['В герметичных аппаратах в атмосфере инертного газа'],
      },
      {
        code: '63633194',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'Только в проектной документации',
          'Только в технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63633195',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63633196',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63633197',
        text:
          'Какое количество бочек с фосфором должно быть в каждом ярусе по длине и по ширине?',
        answers: [
          'Не более 15 бочек и не более 2 бочек соответственно',
          'Не более 21 бочки и не более 3 бочек соответственно',
          'Не более 11 бочек и не более 2 бочек соответственно',
          'Не более 20 бочек и не более 4 бочек соответственно',
        ],
        correctAnswers: ['Не более 15 бочек и не более 2 бочек соответственно'],
      },
      {
        code: '63633198',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии главного инженера',
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии начальника цеха',
          'В присутствии рабочего, который будет спускаться в емкость, и наблюдающего',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63633199',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63633200',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 18% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63633201',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ передает наряд-допуск после его закрытия?',
        answers: [
          'Главному инженеру, руководителю службы производственного контроля, а также в ГСС',
          'В ГСС и аварийно-спасательную службу организации',
          'Руководителю структурного подразделения или его заместителю, а также в ГСС',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ГСС',
        ],
      },
      {
        code: '63633202',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах ввода в технологические здания и сооружения',
          'Над местами, предназначенными для проезда транспорта на высоте не менее 5 м',
          'Над дверными и оконными проемами в случае применения спирально навитых прокладок',
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63633203',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63633204',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода - не менее 25% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода - не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода - не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода - не менее 30% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода - не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63633205',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63633206',
        text:
          'В течение какого времени наряд-допуск на проведение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной дневной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной дневной смены'],
      },
      {
        code: '63633207',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63633208',
        text:
          'Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?',
        answers: [
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Все перечисленное',
          'Получение разрешения на применения технических устройств, выдаваемого органами Ростехнадзора',
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
      },
      {
        code: '63633209',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63633210',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 8 лет',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63633211',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?',
        answers: [
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Не более 300 секунд',
          'Не более 350 секунд',
        ],
        correctAnswers: ['Не более 12 секунд'],
      },
      {
        code: '63633212',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К I группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63633213',
        text:
          'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Выберите 2 правильных варианта ответа.',
        answers: [
          'Начальника производства',
          'Главного метролога организации',
          'Начальника центральной лаборатории организации',
          'Начальника цеха',
          'Заместителя руководителя организации по охране окружающей среды',
        ],
        correctAnswers: ['Начальника производства', 'Начальника цеха'],
      },
      {
        code: '63633214',
        text:
          'Какое из перечисленных требований при складировании фосфида цинка указано верно?',
        answers: [
          'Высота штабелей банок с фосфидом цинка не должна превышать двух банок',
          'Все перечисленные требования указаны верно',
          'Расстояние между штабелями банок должно быть не менее 1 м',
          'Укладывать банки с фосфидом цинка необходимо так, чтобы верхняя опиралась дном на две банки нижнего ряда',
        ],
        correctAnswers: [
          'Укладывать банки с фосфидом цинка необходимо так, чтобы верхняя опиралась дном на две банки нижнего ряда',
        ],
      },
      {
        code: '63633215',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63633216',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63633217',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Главный инженер эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63633218',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны пройти инструктаж',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63633219',
        text:
          'Под каким избыточным давлением должна постоянно находиться вся система электровозгонки фосфора?',
        answers: [
          'Не менее 3 мм водяного столба',
          'Не более 2 мм водяного столба',
          'Не менее 2 мм водяного столба',
          'Не более 3 мм водяного столба',
        ],
        correctAnswers: ['Не менее 3 мм водяного столба'],
      },
      {
        code: '63633220',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63633221',
        text:
          'Что должны обеспечивать системы контроля, автоматического и дистанционного управления (системы управления), системы оповещения об аварийных ситуациях в разделе технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Надежность и безопасность проведения технологических процессов, мониторинг входных параметров процесса',
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
          'Контроль за параметрами процесса, надежность применяемого оборудования, контроль за действиями персонала',
        ],
        correctAnswers: [
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
        ],
      },
      {
        code: '63633222',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63633223',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Организации-разработчике технологической части проектной документации',
          'Организации, выполнивший проектную документацию',
          'О всех перечисленных организациях',
          'Организации, выполнивший функции генерального проектировщика',
          'Организации-разработчике технологического процесса',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63633224',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['30 °С', '35 °С', '20 °С', '25 °С'],
        correctAnswers: ['30 °С'],
      },
      {
        code: '63633225',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63633226',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63633227',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности не регламентируется',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63633228',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63633229',
        text:
          'При какой температуре поверхности аппараты, находящиеся в помещении должны быть теплоизолированы несгораемыми материалами?',
        answers: [
          '45 °С и выше',
          '25 °С и выше',
          '35 °С и выше',
          '30 °С и выше',
        ],
        correctAnswers: ['45 °С и выше'],
      },
      {
        code: '63633230',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Ростехнадзором',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Эксплуатирующими организациями',
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63633231',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее четырех',
          'Не менее двух',
          'Не менее одного',
          'Определяется ответственным за проведение газоопасных работ',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63633232',
        text:
          'Какая максимальная вместимость одного отсека на складах предприятий, производящих желтый фосфор?',
        answers: ['100 т', '50 т', '150 т', '200 т'],
        correctAnswers: ['100 т'],
      },
      {
        code: '63633233',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В технологическом регламенте',
          'В техническом задании на изготовление оборудования',
          'В проектной документации',
          'В паспортах оборудования и трубопроводной арматуры',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63633234',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63633235',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите 2 правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63633236',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Осуществляется все перечисленное',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Испытание трубопровода на прочность и плотность',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63633237',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '1 год со дня его окончания',
          '6 месяцев со дня его окончания',
          '3 года со дня его окончания',
          '3 месяца со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63633238',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Заключение о техническом состоянии арматуры',
          'Все перечисленное',
          'Акты испытания технологического трубопровода на прочность и плотность',
          'Акты периодического наружного осмотра технологического трубопровода',
          'Заключение о качестве сварных стыков',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63633239',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
          'Провести экспертизу промышленной безопасности',
          'Провести реконструкцию химически опасного производственного объекта',
          'Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
        ],
      },
      {
        code: '63633240',
        text:
          'Какое из перечисленных требований к хранению фосфора и его соединений указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Расстояние в свету между резервуарами должно быть не менее 0,5 диаметра наибольшего резервуара',
          'Резервуары для хранения фосфора следует размещать не более чем в три ряда',
          'Поддоны склада не нуждаются в усиленной гидроизоляции',
          'Поддоны (отсеки) склада следует выполнять с уклонами в сторону приямка для сбора возможных проливов фосфора и воды',
          'Расстояние в свету от крайних резервуаров до стен склада или стенок поддона (отсека) должно быть не менее 2 м',
        ],
        correctAnswers: [
          'Расстояние в свету между резервуарами должно быть не менее 0,5 диаметра наибольшего резервуара',
          'Поддоны (отсеки) склада следует выполнять с уклонами в сторону приямка для сбора возможных проливов фосфора и воды',
        ],
      },
      {
        code: '63633241',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система водяного отопления',
          'Система парового отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63633242',
        text:
          'В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Выберите 2 правильных варианта ответа.',
        answers: [
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если в тексте технологического регламента обнаружены подчистки и поправки',
          'При сроке освоения производства более года',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если такое решение принято технологической службой организации, производства, отделения, установки',
        ],
        correctAnswers: [
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
        ],
      },
      {
        code: '63633243',
        text:
          'Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?',
        answers: [
          'Лицо, ответственное за проведение газоопасных работ',
          'Лицо, ответственное за осуществление производственного контроля',
          'Главный инженер',
          'Начальник цеха',
        ],
        correctAnswers: ['Лицо, ответственное за проведение газоопасных работ'],
      },
      {
        code: '63633244',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63633245',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63633246',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['10 минут', '30 минут', '15 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63633247',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите 2 правильных варианта ответа.',
        answers: [
          'Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности',
          'Возможность проведения ремонтных работ',
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ',
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
        ],
      },
      {
        code: '63633248',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '12 месяцев',
          '36 месяцев',
          '24 месяца',
          '6 месяцев',
          '3 месяца',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63633249',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'С руководителями аварийно-спасательных служб',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'С руководителями службы производственного контроля',
          'С руководителями службы охраны труда и санитарными службами',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63633250',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'Не допускается ни в каком случае',
          'Если выбросы рассчитываются в количествах определяемых условиями безопасной остановки технологического процесса',
          'В случае использования специальных систем аварийного освобождения',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63633251',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Прошедшие обучение приемам и методам проведения работ',
          'Не моложе 18 лет',
          'Все перечисленные требования',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63633252',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Не включать: работают люди!"',
          '"Не включать: работа на линии!"',
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: не в фазе!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63633253',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Все ответы неверны',
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для новых в данной организации производств',
          'Для производств с новой технологией',
          'Для всех перечисленных производств',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63633254',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63633255',
        text:
          'Какой минимальный уклон должны иметь внутрицеховые трубопроводы для фосфора?',
        answers: ['0,005', '0,002', '0,1', '0,02', '0,05'],
        correctAnswers: ['0,005'],
      },
      {
        code: '63633256',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий и их развития',
          'В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'В соответствии со сценариями возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63633257',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63633258',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63633259',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'При неудовлетворительном состоянии уплотнительных поверхностей',
          'При деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
          'При срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63633260',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
          'По устному разрешению технического руководителя организации только в дневную смену, при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену, при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63633261',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Эксплуатирующая организация должна согласовать с подрядной организации расстановку знаков, обозначающих границы земляных работ',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63633262',
        text:
          'Какую потребность не должна превышать вместимость резервуаров в дозаторном отделении цехов, потребляющих фосфор?',
        answers: [
          'Двухсуточной потребности производства в фосфоре',
          'Трехсуточной потребности производства в фосфоре',
          'Суточной потребности производства в фосфоре',
          'Пятисуточной потребности производства в фосфоре',
        ],
        correctAnswers: ['Двухсуточной потребности производства в фосфоре'],
      },
    ],
    63646: [
      {
        code: '63646000',
        text:
          'Какие производственные подразделения (цехи, участки, отделения) следует располагать в отдельных производственных помещениях при проектировании?',
        answers: [
          'Очистки пресс-форм щелочными растворами',
          'Приготовления паст на основе токсичных ингредиентов 1 и 2 классов опасности, эбонитовой пыли, приготовления резиновых смесей на вальцах, фактиса, поверхностно активных веществ, щелочных и кислотных растворов',
          'Вулканизации изделий на основе фтористых и силиконовых каучуков с применением перекиси дикумила пероксимона',
          'Все перечисленные производственные подразделения',
          'Подготовки нитрит-нитратных солей, арматуры (латунирование, фосфатирование и т. п.)',
        ],
        correctAnswers: ['Все перечисленные производственные подразделения'],
      },
      {
        code: '63646001',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О знании порядка выполнения работ',
          'О знании порядка применения первичных средств пожаротушения',
          'О знании порядка оказания первой помощи',
          'О самочувствии',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63646002',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения представителя газоспасательной службы и после проверки места проведения газоопасных работ газоанализатором',
          'После осмотра места проведения газоопасных работ начальником смены и специалистом по пожарной безопасности',
          'Только с разрешения специалиста по пожарной безопасности и в средствах индивидуальной защиты',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63646003',
        text:
          'Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?',
        answers: [
          'Иметь компьютерные тренажеры, включающие приближенные к реальным динамические модели процессов и средств управления',
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
          'Иметь специализированные центры обучения и подготовки для производственного персонала',
          'Допускать к самостоятельной работе не ранее, чем через 6 месяцев после стажировки на объекте',
        ],
        correctAnswers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
      },
      {
        code: '63646004',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Периодический контроль за состоянием воздушной среды',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Предотвращение взрывов внутри технологического оборудования',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63646005',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Газоопасные работы", "Газ"',
          '"Работают люди!", "Внимание!"',
          '"Осторожно! Опасная зона", "Опасно для жизни!"',
          '"Внимание!", "Опасно для жизни!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63646006',
        text:
          'На кого возлагаются обязанности по проведению газоспасательных работ в случае отсутствия в организации специальной службы или невозможности прибытия другого профессионального аварийно-спасательного формирования, аттестованного на проведение газоспасательных работ, в срок, установленный ПЛА?',
        answers: [
          'На нештатное аварийно-спасательное формирование',
          'На подразделения осуществляющие эксплуатацию объекта',
          'На подразделения осуществляющие ремонтные работы на объекте',
          'На работников назначенных распоряжением руководителя объекта',
        ],
        correctAnswers: ['На нештатное аварийно-спасательное формирование'],
      },
      {
        code: '63646007',
        text:
          'Какой высоты должно быть ограждение площадок с техническими устройствами при размещении площадок на территории, имеющей общее ограждение?',
        answers: [
          'Не менее 1,2 м',
          'Не менее 1,0 м',
          'Не менее 1,1 м',
          'Не менее 0,9 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63646008',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К группе работ с повышенной опасностью',
          'К III группе',
          'К I группе',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63646009',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63646010',
        text:
          'В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?',
        answers: [
          'Не менее чем за 15 календарных дней',
          'Не менее чем за 30 календарных дней',
          'Не менее чем за 3 месяца',
          'Не менее чем за 10 рабочих дней',
        ],
        correctAnswers: ['Не менее чем за 15 календарных дней'],
      },
      {
        code: '63646011',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии со сценарием возможных аварийных ситуаций и способами перевода объекта в безопасное состояние',
          'В соответствии с алгоритмом, разработанным по сценариям развития возможных аварий',
          'В соответствии с методикой, применяемой для моделирования аварийных ситуаций, согласованной с Ростехнадзором',
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63646012',
        text:
          'Кем утверждается акт перевода технического устройства, используемого для работы с одним ПРВ, на работу с другим продуктом, подтверждающий выполнение всех необходимых для этого требований?',
        answers: [
          'Техническим руководителем организации, эксплуатирующей объекты потребления и производства ПРВ',
          'Представителем сторонней специализированной организации',
          'Представителем территориального органа Ростехнадзора',
          'Представителем проектной организации',
        ],
        correctAnswers: [
          'Техническим руководителем организации, эксплуатирующей объекты потребления и производства ПРВ',
        ],
      },
      {
        code: '63646013',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 10 лет',
          'Не более 12 лет',
          'Не более 5 лет',
          'Не более 20 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63646014',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Комиссия по организации и проведению производственного контроля за соблюдением требований промышленной безопасности',
          'Руководитель эксплуатирующей организации',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63646015',
        text:
          'В каких средствах индивидуальной защиты необходимо выполнять все работы с соляной и серной кислотами при производстве эбонитовых изделий и эбонитовой пыли?',
        answers: [
          'В резиновом фартуке',
          'В защитных очках',
          'В резиновой обуви',
          'В противокислотных перчатках',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63646016',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['40 °C', '45 °C', '50 °C', '55 °C'],
        correctAnswers: ['50 °C'],
      },
      {
        code: '63646017',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63646018',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Разработать проект производства работ',
          'Разработать проект производства аварийно-восстановительных работ',
          'Составить сметную документацию',
          'Передать заказчику перечень оборудования, трубопроводов, коммуникаций, разрешенных к использованию подрядной организацией',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63646019',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Комбинированное',
          'Ручное (по месту)',
          'Дистанционное (неавтоматическое)',
          'Автоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63646020',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 5 лет',
          'Не более 6 лет',
          'Не более 10 лет',
          'Не более 7 лет',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63646021',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Определить количество человек, выполняющих газоопасные работы',
          'Провести стажировку работников',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63646022',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к отогреву трубопроводной арматуры, указаны неверно?',
        answers: [
          'Отогрев трубопроводной арматуры производится снаружи горячим паром',
          'Отогрев трубопроводной арматуры производится снаружи горячей водой',
          'Отогрев трубопроводной арматуры производится снаружи открытым пламенем',
          'Отогрев трубопроводной арматуры производится снаружи горячим воздухом',
        ],
        correctAnswers: [
          'Отогрев трубопроводной арматуры производится снаружи открытым пламенем',
        ],
      },
      {
        code: '63646023',
        text: 'Какие устанавливаются уровни для стадий развития аварии?',
        answers: [
          'Уровень "А", когда авария характеризуется ее развитием в пределах одного ОПО или его составляющей',
          'Уровень "Б", когда авария характеризуется ее выходом за пределы ОПО или его составляющей и развитием ее в пределах границ предприятия',
          'Уровень "В", когда авария характеризуется развитием и выходом ее поражающих факторов за пределы границ предприятия',
          'Уровни "А", "Б" и "В"',
        ],
        correctAnswers: ['Уровни "А", "Б" и "В"'],
      },
      {
        code: '63646024',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Любой из специалистов, участвующих в проведении газоопасных работ',
          'Работник, ответственный за проведение производственного контроля',
          'Работники подрядной организации',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63646025',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Подрядная организация своими силами должна оформить наряд-допуск и передать копию в эксплуатирующую организацию',
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63646026',
        text:
          'При какой максимальной температуре формовых и неформовых изделий следует выгружать изделия из термостатов?',
        answers: ['45 °C', '35 °C', '55 °C', '40 °C'],
        correctAnswers: ['45 °C'],
      },
      {
        code: '63646027',
        text:
          'Какие аппараты и коммуникации не подлежат обезжириванию в процессе эксплуатации?',
        answers: [
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
          'Аппараты и коммуникации на потоке воздуха от поршневого детандера и от дожимающего поршневого компрессора',
          'Воздушные секции теплообменников',
          'Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
        correctAnswers: [
          'Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63646028',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['20 °C', '35 °C', '25 °C', '30 °C'],
        correctAnswers: ['30 °C'],
      },
      {
        code: '63646029',
        text:
          'Какие баллоны со сжатым инертным газом, установленные в металлических шкафах, разрешается располагать в производственных помещениях?',
        answers: [
          'Емкостью до 100 л каждый',
          'Емкостью до 200 л каждый',
          'Емкостью до 250 л каждый',
          'Емкостью до 150 л каждый',
        ],
        correctAnswers: ['Емкостью до 100 л каждый'],
      },
      {
        code: '63646030',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Правилами пожарной безопасности',
          'Инструкцией по пожарной безопасности',
          'Технологией работ',
          'Организационно-распорядительными документами организации',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63646031',
        text:
          'Кем подписывается оперативная часть плана локализации и ликвидации аварий уровня "А"?',
        answers: [
          'Руководителем опасного производственного объекта',
          'Руководитель ремонтного подразделения в оганизации',
          'Главным диспетчером организации, эксплуатирующей опасный производственный объект',
          'Главным технологом организации, эксплуатирующей опасный производственный объект',
        ],
        correctAnswers: ['Руководителем опасного производственного объекта'],
      },
      {
        code: '63646032',
        text:
          'В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?',
        answers: [
          'В инструкции по охране труда при проведении газоопасных работ внутри емкости (аппарата)',
          'В наряде-допуске на проведение газоопасных работ',
          'В журнале учета газоопасных работ',
          'В журнале учета инструктажей перед выполнением работ',
        ],
        correctAnswers: ['В наряде-допуске на проведение газоопасных работ'],
      },
      {
        code: '63646033',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Запрещается передавать инструменты и материалы после спуска работников внутрь емкости',
          'Разрешается применение фильтрующих противогазов внутри емкости',
          'Шланговые или кислородно-изолирующие противогазы должны использоваться только при работе в емкости в одиночку',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63646034',
        text:
          'Каким количеством шланговых противогазов оснащается нештатное аварийно-спасательное формирование?',
        answers: [
          'Не менее 2 на технологическое отделение',
          'Не менее 1 на технологическое отделение',
          'Не менее 3 на технологическое отделение',
          'Не менее 4 на технологическое отделение',
        ],
        correctAnswers: ['Не менее 2 на технологическое отделение'],
      },
      {
        code: '63646035',
        text:
          'Выполнение какого условия, относящегося к трубопроводам и компрессорам продуктов разделения воздуха, допускается?',
        answers: [
          'Попадание кислорода во всасывающую линию при обкатке компрессоров',
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
          'Попадание паров масла в воздух, поступающий на всас воздушных компрессоров и вентиляционных систем',
          'Объединение дренажных трубопроводов',
        ],
        correctAnswers: [
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
        ],
      },
      {
        code: '63646036',
        text:
          'Какие из перечисленных разделов включаются в ПЛА уровня "А"? Выберите два правильных варианта ответа.',
        answers: [
          'Принципиальные технологические схемы блоков, входящих в состав ОПО',
          'Планы расположения основного технологического оборудования блоков, входящих в состав ОПО',
          'Развернутая характеристика ОПО',
          'Планы расположения вспомогательного оборудования входящего в состав ОПО',
        ],
        correctAnswers: [
          'Принципиальные технологические схемы блоков, входящих в состав ОПО',
          'Планы расположения основного технологического оборудования блоков, входящих в состав ОПО',
        ],
      },
      {
        code: '63646037',
        text:
          'С помощью каких несгораемых переходов могут сообщаться производственные подразделения подготовки сырья и приготовления резиновых смесей, склады сырья и готовой продукции с основными производственными корпусами?',
        answers: [
          'Длиной не менее 6 м',
          'Длиной не менее 7 м',
          'Длиной не менее 5 м',
          'Длиной не менее 8 м',
        ],
        correctAnswers: ['Длиной не менее 6 м'],
      },
      {
        code: '63646038',
        text:
          'В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в исходных данных на проектирование и технологическом регламенте на производство продукции',
          'Только в технологическом регламенте на производство продукции',
          'Только в проектной документации',
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63646039',
        text:
          'При каком давлении во всасывающих коммуникациях компрессоров, сжимающих продукты разделения воздуха, данные компрессоры должны автоматически отключаться?',
        answers: [
          'При давлении ниже 0,5 кПа',
          'При давлении ниже 0,7 кПа',
          'При давлении ниже 0,9 кПа',
          'При давлении ниже 1,1 кПа',
        ],
        correctAnswers: ['При давлении ниже 0,5 кПа'],
      },
      {
        code: '63646040',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Любым из перечисленных лиц',
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63646041',
        text:
          'Что из перечисленного должно подаваться по трубопроводам при производстве регенерата?',
        answers: [
          'Подача сырья в вальцы',
          'Подача сырья в девулканизаторы',
          'Подача сырья в вибрационные сита',
          'Подача мягчителей в смеситель',
        ],
        correctAnswers: ['Подача мягчителей в смеситель'],
      },
      {
        code: '63646042',
        text:
          'Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?',
        answers: [
          'Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ',
          'Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ',
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
          'Произвести запись в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен) и наряде-допуске на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
      },
      {
        code: '63646043',
        text:
          'В каком исполнении следует применять электрическое оборудование (пускатели, электрощиты, лампы освещения и т. п.) на участке шероховки покрышек?',
        answers: [
          'Не ниже IP20',
          'Не ниже IP65',
          'Не ниже IP54',
          'Не ниже IP24',
        ],
        correctAnswers: ['Не ниже IP54'],
      },
      {
        code: '63646044',
        text:
          'Навеска какого количества покрышек разрешается на подвеску конвейера сушильной камеры при шиноремонтном производстве?',
        answers: [
          'Трех покрышек',
          'Двух покрышек',
          'Четырех покрышек',
          'Только одной покрышки',
        ],
        correctAnswers: ['Только одной покрышки'],
      },
      {
        code: '63646045',
        text:
          'В каком случае работа по гуммированию внутренних поверхностей аппаратов и емкостей может не прекращаться?',
        answers: [
          'При проливе клея и растворителей внутри аппарата или емкости',
          'Во время грозы, если аппарат или емкость находятся в здании',
          'При производстве огневых работ',
          'При остановке вентилятора, обеспечивающего воздухообмен в аппарате, емкости',
        ],
        correctAnswers: [
          'Во время грозы, если аппарат или емкость находятся в здании',
        ],
      },
      {
        code: '63646046',
        text:
          'Какие требования безопасности к баллонам для постоянных потребителей небольших количеств продуктов разделения воздуха (газоанализаторы, хроматографы) указаны неверно?',
        answers: [
          'Для постоянных потребителей небольших количеств продуктов разделения воздуха (например, газоанализаторы, хроматографы) у каждого места потребления должно быть размещено не более 2 баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 20 МПа',
          'Расстояние между каждой парой баллонов должно быть не менее 12,0 м на каждом уровне размещения баллонов',
          'Баллоны следует размещать в металлических шкафах и закреплять',
          'Шкафы с баллонами должны иметь запорные устройства',
        ],
        correctAnswers: [
          'Для постоянных потребителей небольших количеств продуктов разделения воздуха (например, газоанализаторы, хроматографы) у каждого места потребления должно быть размещено не более 2 баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 20 МПа',
        ],
      },
      {
        code: '63646047',
        text:
          'Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры промышленного оборудования, а также показатели надежности',
          'Требования действующих нормативных актов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных актов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63646048',
        text:
          'Какие требования к сетям водопроводов и канализации и устройствам, подключаемым к сетям, не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'В производственных помещениях, где возможно повышенное содержание кислорода, которое может привести к воспламенению одежды, или наличие опасных веществ, которые могут вызвать химические ожоги, должны устанавливаться фонтанчики, краны, раковины или ванны самопомощи, аварийные души',
          'Осмотр и очистка канализационных сетей и колодцев должны производиться по графикам и в соответствии с порядком проведения газоопасных работ',
          'Соединение сетей хозяйственно-питьевого водопровода с сетями водопроводов, подающих воду технического качества, не допускается',
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души, в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
        ],
        correctAnswers: [
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души, в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
        ],
      },
      {
        code: '63646049',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Радиусом зон разрушения',
          'Приведенной массой вещества, участвующего во взрыве',
          'Энергией сгорания парогазовой фазы',
          'Категорией взрывоопасности технологических блоков',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63646050',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Контроль содержания горючих веществ в технологической системе после остановки технологического оборудования',
          'Поддержание избыточного давления инертного газа в системе во время остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающих образование застойных зон',
          'Регламентация режимов и порядка пуска и остановки оборудования',
        ],
        correctAnswers: [
          'Разработка способов продувки оборудования инертными газами, исключающих образование застойных зон',
          'Регламентация режимов и порядка пуска и остановки оборудования',
        ],
      },
      {
        code: '63646051',
        text:
          'Что из перечисленного не рекомендуется включать в список оповещения об аварии?',
        answers: [
          'Фамилию, имя и отчество',
          'Адрес проживания',
          'Перечень должностных лиц с указанием наименования подразделений, организаций',
          'Должность оповещаемого лица',
          'Номера контактных телефонов',
        ],
        correctAnswers: ['Адрес проживания'],
      },
      {
        code: '63646052',
        text:
          'Какие требования к эксплуатации поршневых компрессоров указаны неверно?',
        answers: [
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
          'Не допускается использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей',
          'Для смазки поршневой группы компрессоров, подающих воздух в воздухоразделительные установки, необходимо использовать масла, рекомендованные разработчиками компрессора',
          'Наличие нагара в клапанных коробках и трубопроводах поршневых компрессоров не допускается',
        ],
        correctAnswers: [
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
        ],
      },
      {
        code: '63646053',
        text:
          'В каком случае допускается водяное и паровое отопление в помещениях приготовления клеев, маканых изделий из клеев, шпредингования тканей, приготовления эбонитовой пыли?',
        answers: [
          'Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 95% температуры самовоспламенения продуктов, которые могут находиться в помещении',
          'Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 90% температуры самовоспламенения продуктов, которые могут находиться в помещении',
          'Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 80% температуры самовоспламенения продуктов, которые могут находиться в помещении',
        ],
        correctAnswers: [
          'Только в случаях, если температура поверхностей нагревательных приборов и трубопроводов не превысит 80% температуры самовоспламенения продуктов, которые могут находиться в помещении',
        ],
      },
      {
        code: '63646054',
        text:
          'Кем обеспечивается взаимодействие с органами исполнительной власти субъекта Российской Федерации (комиссией по предупреждению и ликвидации чрезвычайных ситуаций) и органами местного самоуправления при локализации и ликвидации аварий?',
        answers: [
          'Должностным лицом, в обязанности которого входит обеспечение выполнения требований промышленной безопасности при эксплуатации ОПО',
          'Должностным лицом, в обязанности которого входит контроль над соблюдением требований промышленной безопасности при эксплуатации ОПО',
          'Должностным лицом, в обязанности которого входит обеспечение взаимодействия со сторонними предприятиями',
          'Руководителем службы делопроизводства и контроля на предприятии, эксплуатирующем ОПО',
        ],
        correctAnswers: [
          'Должностным лицом, в обязанности которого входит обеспечение выполнения требований промышленной безопасности при эксплуатации ОПО',
        ],
      },
      {
        code: '63646055',
        text:
          'Что не соответствует требованиям, предъявляемым к освещению в производственных и вспомогательных зданиях?',
        answers: [
          'Естественное и искусственное освещение в производственных и вспомогательных зданиях и помещениях объектов производств и потребления продуктов разделения воздуха, включая аварийное освещение основных рабочих мест, должно соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов',
          'Организациями, эксплуатирующими объекты производства и потребления продуктов разделения воздуха, должны осуществляться систематические проверки исправности сети аварийного освещения в порядке и с периодичностью, установленной эксплуатирующей организацией',
          'Не допускается загромождать световые проемы помещений',
          'Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа',
        ],
        correctAnswers: [
          'Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа',
        ],
      },
      {
        code: '63646056',
        text:
          'На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?',
        answers: [
          'На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия',
          'На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди',
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
        ],
        correctAnswers: [
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
        ],
      },
      {
        code: '63646057',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Отключение систем в случае переключений на резервный или аварийный источник электропитания не позднее 0,5 с',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63646058',
        text:
          'С какой периодичностью нормативно-техническая документация, устанавливающая порядок и условия безопасного ведения производственного процесса, подлежит пересмотру?',
        answers: [
          'Каждый год',
          'Каждые 3 года',
          'Каждые 4 года',
          'Каждые 5 лет',
        ],
        correctAnswers: ['Каждые 3 года'],
      },
      {
        code: '63646059',
        text:
          'Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварийных ситуаций',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
        ],
        correctAnswers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
        ],
      },
      {
        code: '63646060',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: работают люди!"',
          '"Не открывать: работают люди!"',
          '"Стой! Опасно для жизни!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63646061',
        text:
          'Каким количеством работников должна осуществляться разборка дорна после сборки кранцев?',
        answers: ['Двумя', 'Тремя', 'Одним', 'Определяется руководителем'],
        correctAnswers: ['Двумя'],
      },
      {
        code: '63646062',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Должны пройти медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Должны быть не моложе 18 лет',
          'Все перечисленные требования',
          'Должны пройти обучение приемам и методам проведения работ',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63646063',
        text:
          'С кем согласовывается план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'С руководителями всех специализированных служб, задействованных в соответствии с оперативной частью ПЛА в работах по локализации и ликвидации аварий',
          'С руководителями служб, задействованных в обеспечении объекта оборудованием и специальными средвтвами',
          'С руководителем службы по работе с персоналом',
          'С руководителем службы охраны труда',
        ],
        correctAnswers: [
          'С руководителями всех специализированных служб, задействованных в соответствии с оперативной частью ПЛА в работах по локализации и ликвидации аварий',
        ],
      },
      {
        code: '63646064',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты и окрашены в красный цвет',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты и прикрыты железобетонным колпаком',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63646065',
        text:
          'В течение какого срока вносятся изменения в план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае изменений в производственных технологиях?',
        answers: [
          'Не позднее 1 месяца',
          'Не позднее 2 месяцев',
          'Не позднее 45 дней',
          'Не позднее 3 месяцев',
        ],
        correctAnswers: ['Не позднее 1 месяца'],
      },
      {
        code: '63646066',
        text:
          'Допускается ли сброс продуктов разделения воздуха в производственное помещение при продувке технических устройств и коммуникаций?',
        answers: [
          'Не допускается',
          'Допускается в помещении с работающей вентиляцией, обеспечивающей объемную долю кислорода в воздухе помещения не менее 19% и не более 23%',
          'Допускается в помещении с постоянно действующей приточно-вытяжной вентиляцией',
          'Допускается в помещении с естественной вентиляцией, если объем помещения в метрах кубических превышает объем сброса продуктов разделения воздуха в литрах, не менее чем в 7 раз',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63646067',
        text:
          'Кем принимается решение о выводе объекта (блока, установки) из эксплуатации на длительный период и вводе этих объектов (блоков, установок) в эксплуатацию после длительных остановок?',
        answers: [
          'Эксплуатирующей организацией',
          'Организацией, выполняющей их ремонты',
          'Проектной организацией',
          'Исследовательской организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63646068',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, которые должны соблюдаться при обоснованной необходимости проведения обезжиривания растворителями, указаны неверно?',
        answers: [
          'Перелив растворителей из одного сосуда в другой допускается только закрытым способом при наличии у работников средств индивидуальной защиты',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'При неработающей вентиляции случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов (например, опилки, песок)',
          'Специальные помещения, в которых проводится обезжиривание и хранятся растворители, необходимо оснащать постоянно действующей приточно-вытяжной вентиляцией',
        ],
        correctAnswers: [
          'При неработающей вентиляции случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов (например, опилки, песок)',
        ],
      },
      {
        code: '63646069',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'В рабочие и выходные дни с разрешения руководителя организации',
          'Только в выходные дни в присутствии лица, ответственного за выполнение газоопасных работ',
          'В дневную и ночную рабочие смены после письменного уведомления работника, ответственного за проведение производственного контроля',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63646070',
        text:
          'Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?',
        answers: [
          'Лицо, ответственное за проведение газоопасных работ',
          'Начальник смены',
          'Непосредственный руководитель работников, участвовавших в проведении газоопасных работ',
          'Лицо, ответственное за проведение производственного контроля',
        ],
        correctAnswers: ['Лицо, ответственное за проведение газоопасных работ'],
      },
      {
        code: '63646071',
        text:
          'Кто в организации является ответственным руководителем работ по локализации и ликвидации аварии на уровне "А" развития аварии?',
        answers: [
          'Начальник структурного подразделения ОПО (цеха, производственного участка, установки)',
          'Руководитель организации (должностное лицо, в обязанности которого входит обеспечение выполнения требований промышленной безопасности при эксплуатации ОПО)',
          'Руководитель пожарных формирований на объекте',
          'Руководитель ремонтного подразделения в оганизации',
        ],
        correctAnswers: [
          'Начальник структурного подразделения ОПО (цеха, производственного участка, установки)',
        ],
      },
      {
        code: '63646072',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'По I или II категории надежности',
          'Только по I категории надежности',
          'По II или III категории надежности',
          'По любой категории надежности',
        ],
        correctAnswers: ['По I или II категории надежности'],
      },
      {
        code: '63646073',
        text:
          'В каком случае допускается определение толщин стенок трубопроводов иным способом, отличным от метода неразрушающего контроля?',
        answers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
          'Допускается в присутствии инспектора Ростехнадзора',
          'Допускается в соответствии с документацией на ХОПО',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
        ],
      },
      {
        code: '63646074',
        text:
          'При какой минимальной температуре резиновой смеси, подающейся в червячную машину, рабочий должен работать в рукавицах?',
        answers: ['60 °C', '70 °C', '50 °C', '80 °C'],
        correctAnswers: ['60 °C'],
      },
      {
        code: '63646075',
        text:
          'В каком случае допускается подача растворителей в смесители клея и герметиков с использованием переносных сосудов?',
        answers: [
          'Не допускается ни в каком случае',
          'Если работы выполняются не менее чем двумя работниками',
          'В случае недостаточной производительности счетчиков-дозаторов',
          'При наличии обоснования проектной документацией',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63646076',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальник цеха',
          'Начальник производственно-технического (технического) отдела организации',
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
        correctAnswers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
      },
      {
        code: '63646077',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать его автоматическую остановку при снижении давления газа, подаваемого в лабиринтные уплотнения?',
        answers: [
          'На компрессорах с давлением нагнетания свыше 0,6 МПа',
          'На компрессорах с давлением нагнетания свыше 0,4 МПа',
          'На компрессорах с давлением нагнетания свыше 0,5 МПа',
          'На компрессорах с давлением нагнетания свыше 0,3 МПа',
        ],
        correctAnswers: [
          'На компрессорах с давлением нагнетания свыше 0,6 МПа',
        ],
      },
      {
        code: '63646078',
        text:
          'Какие требования к стационарным обводным линиям предусматриваются Правилами безопасности химически опасных производственных объектов?',
        answers: [
          'Должны размещаться на расстоянии не менее 50,0 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки',
          'Должны размещаться на расстоянии не менее 100,0 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки',
          'Должны иметь съемные патрубки и заглушки, при этом данные обводные линии следует размещать за пределами помещений кислородно-распределительных (регуляторных) пунктов',
          'Должны размещаться на расстоянии не менее 10,0 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки',
        ],
        correctAnswers: [
          'Должны иметь съемные патрубки и заглушки, при этом данные обводные линии следует размещать за пределами помещений кислородно-распределительных (регуляторных) пунктов',
        ],
      },
      {
        code: '63646079',
        text:
          'Переносные лампы с каким напряжением необходимо использовать для освещения при чистке реактора при приготовлении пропиточного состава, пропитки и термообработки корда (ткани)?',
        answers: [
          'Не выше 12 В',
          'Не выше 36 В',
          'Не выше 24 В',
          'Не выше 48 В',
        ],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '63646080',
        text:
          'За какое время до начала работы технологического оборудования следует включать местные вытяжные вентиляционные установки, не сблокированные с технологическим оборудованием?',
        answers: [
          'За 3 - 5 минут',
          'За 10 - 15 минут',
          'За 7 - 10 минут',
          'За 15 - 20 минут',
        ],
        correctAnswers: ['За 3 - 5 минут'],
      },
      {
        code: '63646081',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологической картой',
          'В соответствии с инструкцией по пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63646082',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии инженера по оборудованию',
          'В присутствии специалиста по промышленной безопасности',
          'В присутствии начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63646083',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал владельца электросетевого хозяйства по заявке персонала эксплуатирующей организации',
          'Электротехнологический персонал подрядной организации',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63646084',
        text:
          'Как производится включение компрессора, отключенного из-за прекращения подачи охлаждающей воды?',
        answers: [
          'Только после возобновления подачи воды',
          'Только после его охлаждения и возобновления подачи воды',
          'Только после проведения отбора проб для анализа газа и последующего возобновления подачи воды',
          'Только после его охлаждения',
        ],
        correctAnswers: [
          'Только после его охлаждения и возобновления подачи воды',
        ],
      },
      {
        code: '63646085',
        text:
          'Каким должно быть содержание масла в кислороде, поступающем в компрессоры и газодувки?',
        answers: [
          'Не более 0,02 мг/м³',
          'Не более 0,03 мг/м³',
          'Не более 0,04 мг/м³',
          'Не более 0,05 мг/м³',
        ],
        correctAnswers: ['Не более 0,02 мг/м³'],
      },
      {
        code: '63646086',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Технологический регламент',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63646087',
        text:
          'В каком из перечисленных случаев допускается эксплуатация вулканизационных прессов?',
        answers: [
          'При наличии в них пропусков масла',
          'При наличии в них пропусков сжатого воздуха',
          'При наличии в них пропусков пара',
          'Во всех перечисленных случаях эксплуатация не допускается',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях эксплуатация не допускается',
        ],
      },
      {
        code: '63646088',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'Работы выполняются с письменного разрешения ответственного за проведение производственного контроля',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением при очередном пересмотре перечня газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63646089',
        text:
          'Каким из перечисленных способом не допускается прокладка кабелей по территории предприятий и установок?',
        answers: [
          'Допускаются все перечисленные способы прокладки',
          'Только по территории технологических установок и производств',
          'Только в каналах, засыпанных песком, и траншеях',
          'Только на кабельных конструкциях технологических эстакад',
        ],
        correctAnswers: ['Допускаются все перечисленные способы прокладки'],
      },
      {
        code: '63646090',
        text:
          'Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
          'Предотвращение взрывов и предотвращение травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
        correctAnswers: [
          'Предотвращение взрывов и предотвращение травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
      },
      {
        code: '63646091',
        text:
          'С какой периодичностью предохранительные клапаны, установленные на автоклавах вулканизации эбонитовых изделий, должны проходить ревизию с проверкой их установочного давления на стенде?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
          'Не реже одного раза в год',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в квартал'],
      },
      {
        code: '63646092',
        text:
          'Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции в случае недостаточности воздухообмена',
          'Общеобменная вентиляция должна включаться при срабатывании установленных в помещении сигнализаторов довзрывных концентраций',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
        ],
        correctAnswers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
        ],
      },
      {
        code: '63646093',
        text:
          'Что из перечисленного должно подаваться с использованием конвейеров при приготовлении пропиточного состава, пропитки и термообработки корда (ткани)?',
        answers: [
          'Подача жидких компонентов пропиточного состава в мерники',
          'Подача жидких компонентов пропиточного состава в дозаторы',
          'Подача жидких компонентов пропиточного состава в реакторы',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63646094',
        text:
          'В каком случае необходимо предусматривать устойчивую площадку с ограждением и сплошными ступеньками для обслуживания загрузочной воронки на червячной машине?',
        answers: [
          'При расположении загрузочной воронки выше 1500 мм от пола',
          'При расположении загрузочной воронки выше 850 мм от пола',
          'При расположении загрузочной воронки выше 700 мм от пола',
          'При расположении загрузочной воронки выше 1200 мм от пола',
        ],
        correctAnswers: [
          'При расположении загрузочной воронки выше 1500 мм от пола',
        ],
      },
      {
        code: '63646095',
        text:
          'На какое расстояние допускается приближение рук работающего к перечисленным механизмам?',
        answers: [
          '150 мм к пуансону при пробивке отверстий в ободной ленте',
          '180 мм к зазору протягивающих роликов дублера при выходе сердечника конвейерной ленты',
          '200 мм к бобине в процессе перемотки бинта, ножу при отборе резиновой кромки или профилировании заготовок клиновых ремней, отборочному валку при заправке в него кромки сердечника конвейерной ленты',
        ],
        correctAnswers: [
          '150 мм к пуансону при пробивке отверстий в ободной ленте',
        ],
      },
      {
        code: '63646096',
        text:
          'Включение каких механизмов должны исключать блокирующие устройства диагонально-резательных агрегатов?',
        answers: [
          'Привода отборочного конвейера агрегата при перемещении каретки',
          'Агрегата при снятом ограждении ножа',
          'Привода перемещения каретки при движении отборочного конвейера агрегата',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63646097',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Письменным распоряжением технического директора (главного инженера) организации',
          'Приказом комиссии организации',
          'Приказом технологической службы организации',
          'Приказом руководителя организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63646098',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'Только в проектной документации',
          'Только в технологическом регламенте на производство продукции',
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63646099',
        text:
          'Какими документами должны сопровождаться технологические трубопроводы после монтажа и ремонта?',
        answers: [
          'Лицензиями и другими учредительными документами организации, проводившей монтаж и ремонт',
          'Разрешениями организации, проводившей монтаж и ремонт на проведение этих видов работ',
          'Документами о стоимости материалов и работ по проведению монтажа и ремонта',
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
        ],
        correctAnswers: [
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
        ],
      },
      {
        code: '63646100',
        text:
          'Что в том числе должно входить в состав приложений к плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Действия ответственного руководителя и работников по локализации и ликвидации аварий и их последствий',
          'Заключение экспертизы промышленной безопасности',
          'Сведения о квалификации работников, ответственных за организацию мероприятий по локализации и ликвидации последствий аварий',
          'Описание аварий, произошедших на аналогичных опасных производственных объектах за последние 5 лет',
        ],
        correctAnswers: [
          'Действия ответственного руководителя и работников по локализации и ликвидации аварий и их последствий',
        ],
      },
      {
        code: '63646101',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Порядок действий в случае аварий на объекте',
          'Организация взаимодействия сил и средств',
          'Мероприятия, направленные на обеспечение безопасности населения (в случае если в результате аварий на объекте может возникнуть угроза безопасности населения)',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63646102',
        text:
          'В каком случае допускается транспортировка жидкости путем передавливания сжатым воздухом?',
        answers: [
          'Растительных масел и жиров, нагретых от 30 °C до 50 °C',
          'При температуре горючих жидкостей, нагретых выше 80 °C',
          'При температуре легковоспламеняющихся жидкостей, нагретых выше 30 °C',
        ],
        correctAnswers: [
          'Растительных масел и жиров, нагретых от 30 °C до 50 °C',
        ],
      },
      {
        code: '63646103',
        text:
          'При разработке технологических процессов какими источниками информации следует руководствоваться для определения регламентированных значений параметров, определяющих взрывоопасность процесса?',
        answers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
          'Только данными, запрашиваемыми у научно-исследовательской организации',
          'Только научно-технической литературой',
          'Только справочной литературой',
        ],
        correctAnswers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
        ],
      },
      {
        code: '63646104',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
          'Разрабатывается один план для одной организации, независимо от расположения объектов',
          'Разрабатывается один план при условии что объекты находятся на территории одного субъекта Российской Федерации',
          'Необходимо разработать планы мероприятий для каждого объекта отдельно',
        ],
        correctAnswers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63646105',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63646106',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63646107',
        text:
          'Какое из перечисленных требований при производстве работ по ремонту технических устройств и трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'На весь период ремонта арматура на сбросе газа из блока в атмосферу должна быть закрыта',
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить',
          'До выполнения работ на одной или обеих трехходовых заслонках, связанных с доступом в подводящие к ним трубопроводы, устанавливаются заглушки (по ходу газа) после обех трехходовых заслонок',
        ],
        correctAnswers: [
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить',
        ],
      },
      {
        code: '63646108',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Использование корректора для внесения исправлений при заполнении наряда-допуска на проведение газоопасных работ',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63646109',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?',
        answers: [
          'На каждом с давлением нагнетания более 0,6 МПа',
          'На любом из компрессоров с давлением нагнетания 0,3 МПа',
          'С давлением нагнетания 0,5 МПа',
          'На одном из компрессоров с давлением нагнетания более 0,4 МПа',
        ],
        correctAnswers: ['На каждом с давлением нагнетания более 0,6 МПа'],
      },
      {
        code: '63646110',
        text:
          'Каким количеством работников следует производить перемещение рукавов с дорнами в накопитель и на бинтовку при длине дорнов более 4 м на комбинат-машине?',
        answers: ['Двумя', 'Одним', 'Тремя', 'Определяется руководителем'],
        correctAnswers: ['Двумя'],
      },
      {
        code: '63646111',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее одного',
          'Не менее трех',
          'Не менее двух',
          'Не менее четырех',
          'Определяется локальными нормативными актами',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63646112',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Непрерывный контроль состояния воздушной среды',
          'Наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
          'Все перечисленное',
          'Наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63646113',
        text:
          'Какое из перечисленных устройств не должны в обязательном порядке иметь многопозиционные вулканизаторы покрышек?',
        answers: [
          'Защитный козырек',
          'Штанговые аварийные выключатели для остановки движения перезарядчиков, расположенные с двух сторон перезарядчика (со стороны площадки обслуживания вулканизатора и со стороны стойки МИМов)',
          'Световой сигнал об отсутствии давления в диафрагме или паровой камере',
          'Индикатор утечки теплоносителя из диафрагмы, установленной на верхней полуформе',
        ],
        correctAnswers: ['Защитный козырек'],
      },
      {
        code: '63646114',
        text:
          'В течение какого времени после детального изучения допущенных ошибок рекомендуется предусматривать проведение повторных учебных занятий по плану мероприятий по локализации и ликвидации последствий аварий при неудовлетворительных результатах предыдущих занятий?',
        answers: [
          'В течение 14 дней',
          'В течение 15 дней',
          'В течение 20 дней',
          'В течение 1 месяца',
        ],
        correctAnswers: ['В течение 14 дней'],
      },
      {
        code: '63646115',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений, должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При достижении концентрации обращающихся веществ в воздухе помещения 15% НКПР',
          'При достижении концентрации обращающихся веществ в воздухе помещения 17% НКПР',
          'При достижении концентрации обращающихся веществ в воздухе помещения 18% НКПР',
          'При достижении концентрации обращающихся веществ в воздухе помещения 20% НКПР',
        ],
        correctAnswers: [
          'При достижении концентрации обращающихся веществ в воздухе помещения 20% НКПР',
        ],
      },
      {
        code: '63646116',
        text:
          'В каком документе организация, эксплуатирующая химически опасный производственный объект I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?',
        answers: [
          'В инструкции по охране труда',
          'В положении о производственном контроле',
          'В технологическом регламенте',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63646117',
        text:
          'Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями территориальных управлений Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Руководителями территориальных органов Ростехнадзора',
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'Руководителями территориальных органов Росприроднадзора',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63646118',
        text:
          'В каком случае следует устанавливать защитные экраны для защиты персонала при возгорании арматуры?',
        answers: [
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 0,6 МПа, расположена на расстоянии менее 4,0 м от рабочих мест',
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест',
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 5,0 м от рабочих мест',
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 0,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест',
        ],
        correctAnswers: [
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 0,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест',
        ],
      },
      {
        code: '63646119',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей, обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 30 минут',
          'Переключением сети воздуха устройств автоматического регулирования и контрольно-измерительных приборов через осушитель на заводскую сеть сжатого воздуха',
          'Установкой резервного компрессора с включением его автоматически при остановке основного оборудования',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63646120',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии технического директора',
          'В присутствии ответственного за охрану труда в подразделении',
          'В присутствии начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63646121',
        text:
          'Какую кислородную арматуру допускается размещать в помещениях щитов управления (щитовая)?',
        answers: [
          'Не допускается размещать кислородную арматуру (независимо от давления)',
          'Кислородную арматуру с давлением 2,6 МПа и выше',
          'Кислородную аппаратуру с давлением 0,6 МПа и выше',
          'Кислородную арматуру с давлением не выше 1,6 МПа',
        ],
        correctAnswers: [
          'Не допускается размещать кислородную арматуру (независимо от давления)',
        ],
      },
      {
        code: '63646122',
        text:
          'Какой допускается максимальный зазор между ограничительными стрелками и валками во избежание захвата рукавицы и руки рабочего при эксплуатации основного технологического оборудования?',
        answers: ['1,5 - 2 мм', '2 - 4 мм', '4 мм', '2,5 - 3,5 мм'],
        correctAnswers: ['1,5 - 2 мм'],
      },
      {
        code: '63646123',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          '5 копий',
          '3 копии',
          '7 копий',
          'Количество, определенное организацией, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Количество, определенное организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63646124',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Сведения о непосредственном руководителе работ и лицах, ответственных за подготовку к ремонтным работам',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63646125',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'Место направления сбрасываемых химически опасных веществ устанавливается разработчиком проектной документации',
          'В специальные контейнеры для последующей переработки',
          'В закрытые системы для дальнейшей утилизации',
          'На факельную установку для сжигания',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63646126',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Выполняться обслуживающим персоналом по инструкции',
        ],
        correctAnswers: ['Выполняться обслуживающим персоналом по инструкции'],
      },
      {
        code: '63646127',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63646128',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Руководителем службы охраны труда',
          'Техническим руководителем организации',
          'Руководителем структурного подразделения, ответственным за организацию газоопасных работ',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63646129',
        text:
          'Кто в организации является ответственным руководителем работ по локализации и ликвидации аварии на уровне "Б" развития аварии до прибытия на место аварии руководителя организации?',
        answers: [
          'Руководитель организации (должностное лицо, в обязанности которого входит обеспечение выполнения требований промышленной безопасности при эксплуатации ОПО)',
          'Начальник структурного подразделения ОПО (цеха, производственного участка, установки)',
          'Руководитель пожарных формирований на объекте',
          'Руководитель ремонтного подразделения в оганизации',
        ],
        correctAnswers: [
          'Руководитель организации (должностное лицо, в обязанности которого входит обеспечение выполнения требований промышленной безопасности при эксплуатации ОПО)',
        ],
      },
      {
        code: '63646130',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Требования к обозначению средств автоматики не регламентируются',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63646131',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63646132',
        text:
          'Какое из перечисленных требований к каландрам указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Каландры должны иметь устройства для аварийной их остановки. Эти устройства необходимо располагать с передней и задней сторон каландров',
          'Обрезка резиновой кромки с обрезиненной ткани (корда) должна быть механизирована',
          'В схеме управления каландром должна быть предусмотрена световая сигнализация, предшествующая пуску каландра',
          'Питание каландра резиновой смесью должно быть только автоматическим',
          'Допускается вырезка образцов обрезиненной ткани (корда), расправка складок на ткани (корде) на рабочей скорости',
        ],
        correctAnswers: [
          'Каландры должны иметь устройства для аварийной их остановки. Эти устройства необходимо располагать с передней и задней сторон каландров',
          'Обрезка резиновой кромки с обрезиненной ткани (корда) должна быть механизирована',
        ],
      },
      {
        code: '63646133',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 10% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63646134',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'Со специалистом по охране труда',
          'Со специалистом по промышленной безопасности',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'Со службой внутреннего инспекционного контроля',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63646135',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?',
        answers: [
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Не более 300 секунд',
          'Время срабатывания устанавливается организацией - разработчиком проекта',
        ],
        correctAnswers: ['Не более 12 секунд'],
      },
      {
        code: '63646136',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63646137',
        text: 'Какие органы информируются о развитии аварии на уровне "А"?',
        answers: [
          'Территориальные органы Ростехнадзора России',
          'Государственной инспекции труда',
          'Территориальные органы МЧС России',
          'Все перечисленные, а при необходимости - органы местного самоуправления о ходе и характере аварии, о пострадавших в ходе спасательных работ',
        ],
        correctAnswers: [
          'Все перечисленные, а при необходимости - органы местного самоуправления о ходе и характере аварии, о пострадавших в ходе спасательных работ',
        ],
      },
      {
        code: '63646138',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
          'Средствами автоматического управления объектом по действующим программам',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63646139',
        text:
          'Чем следует руководствоваться при организации работ по поддержанию надежного и безопасного уровня эксплуатации и ремонта технологического и вспомогательного оборудования, трубопроводов и арматуры, систем контроля, противоаварийной защиты, средств связи и оповещения, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Справочной литературой',
          'Внутренними распорядительными документами организации',
          'Исходными данными, полученными в результате научно-исследовательских и опытных работ',
          'Предписаниями Ростехнадзора',
        ],
        correctAnswers: [
          'Внутренними распорядительными документами организации',
        ],
      },
      {
        code: '63646140',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 10 рабочих дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий',
          'Не позднее 15 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63646141',
        text:
          'С какой периодичностью необходимо проверять надежность крепления подвижного электрода камеры сушки при сушке латексных губчатых изделий токами высокой частоты?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в смену',
          'Не реже двух раз в неделю',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63646142',
        text: 'Чем характеризуется авария уровня "Б"?',
        answers: [
          'Ее выходом за пределы ОПО или его составляющей и развитием ее в пределах границ предприятия',
          'Ее развитием в пределах одного ОПО или его составляющей',
          'Развитием и выходом ее поражающих факторов за пределы границ предприятия',
          'Другими характеристиками',
        ],
        correctAnswers: [
          'Ее выходом за пределы ОПО или его составляющей и развитием ее в пределах границ предприятия',
        ],
      },
      {
        code: '63646143',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
          'Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63646144',
        text:
          'Через какое время следует выключать приточные и общеобменные вытяжные установки после окончания работы цеха?',
        answers: [
          'Через 10 - 12 минут',
          'Через 15- 20 минут',
          'Через 2 - 5 минут',
          'Через 5 - 7 минут',
        ],
        correctAnswers: ['Через 10 - 12 минут'],
      },
      {
        code: '63646145',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Начальнику смены',
          'Непосредственному руководителю',
          'Работнику, ответственному за организацию производственного контроля',
          'Специалисту по пожарной безопасности',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63646146',
        text:
          'Кем должен быть утвержден перечень помещений и мест, в которых содержание кислорода по объемной доле может быть менее 19% или более 23% (в аварийной ситуации)?',
        answers: [
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается только первым руководителем организации',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается территориальным органом Ростехнадзора',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается комиссией эксплуатирующей организации',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации, эксплуатирующей объекты производства и потребления продуктов разделения воздуха',
        ],
        correctAnswers: [
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации, эксплуатирующей объекты производства и потребления продуктов разделения воздуха',
        ],
      },
      {
        code: '63646147',
        text:
          'На каком оборудовании следует производить осмотр уплотнительной прокладки не реже одного раза в смену?',
        answers: [
          'На форматорах-вулканизаторах',
          'На всем перечисленном оборудовании',
          'На индивидуальных вулканизаторах',
          'На автоклавах-прессах',
        ],
        correctAnswers: ['На всем перечисленном оборудовании'],
      },
      {
        code: '63646148',
        text:
          'В каком случае должна предусматриваться локальная система пожаротушения для тушения возможного загорания резиновой смеси в камере смешения резиносмесителя?',
        answers: [
          'В камере смешения резиносмесителя емкостью более 100 л',
          'В камере резиносмесителя любого объема при смешивании резиновой смеси низкой температуры воспламенения',
          'Если это установлено проектной документацией',
        ],
        correctAnswers: [
          'В камере смешения резиносмесителя емкостью более 100 л',
        ],
      },
      {
        code: '63646149',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из общих и специальных',
          'Из общих и особенных',
          'Из общих и частных',
          'Из общих и технических',
        ],
        correctAnswers: ['Из общих и специальных'],
      },
      {
        code: '63646150',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Только со службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С газоспасательной службой',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63646151',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Ответственный за организацию ремонтных работ фиксирует проведение инструктажа только в наряде-допуске',
          'Специалист по охране труда проводит инструктаж всем исполнителям и делает отметку в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63646152',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Аварийно-спасательной службе организации',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63646153',
        text:
          'С какой целью не разрабатывается план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'С целью выявления достаточности принятых мер по предупреждению аварий на объекте',
          'С целью планирования действий персонала опасного производственного объекта и специализированных служб на различных уровнях развития ситуаций',
          'С целью определения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'С целью разработки декларации промышленной безопасности',
        ],
        correctAnswers: [
          'С целью разработки декларации промышленной безопасности',
        ],
      },
      {
        code: '63646154',
        text:
          'С какой целью разрабатывается план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'С целью выявления достаточности принятых мер по предупреждению аварий на объекте',
          'С целью планирования действий персонала опасного производственного объекта и специализированных служб на различных уровнях развития ситуаций',
          'С целью обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63646155',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к отключающей арматуре для присоединения гибких трубопроводов (шлангам, рукавам) при периодическом отборе продуктов разделения воздуха из трубопроводов, указаны неверно?',
        answers: [
          'Отключающая арматура для присоединения гибких трубопроводов (шланги, рукава) должна размещаться в металлическом шкафу с отверстиями для вентиляции',
          'Шкаф с размещенной отключающей арматурой должен быть заперт после прекращения работ по периодическому отбору продуктов разделения воздуха из трубопроводов',
          'Сигнально-предупредительные надписи шкафов, для размещения отключающей арматуры, должны быть выполнены в соответствии с проектной документацией (документацией)',
          'Опознавательная окраска шкафов, для размещения отключающей арматуры, должна быть выполнена в соответствии с рекомендациями разработчика',
        ],
        correctAnswers: [
          'Опознавательная окраска шкафов, для размещения отключающей арматуры, должна быть выполнена в соответствии с рекомендациями разработчика',
        ],
      },
      {
        code: '63646156',
        text:
          'Какая устанавливается максимальная температура нагретых поверхностей вулканизационного оборудования, трубопроводов и ограждений на рабочих местах?',
        answers: ['45 °C', '35 °C', '55 °C', '65 °C'],
        correctAnswers: ['45 °C'],
      },
      {
        code: '63646157',
        text:
          'Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Пусковые распоряжения, производственные инструкции',
          'Постоянные, временные, разовые и лабораторные',
          'Периодические, ежегодные',
          'Плановые, целевые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63646158',
        text:
          'В каком количестве разрешается хранение смазочных масел в шкафах или ящиках из несгораемых материалов с плотно закрывающимися крышками в производственных помещениях?',
        answers: [
          'Не более 20 л',
          'Не более 30 л',
          'Не более 40 л',
          'Не более 50 л',
        ],
        correctAnswers: ['Не более 20 л'],
      },
      {
        code: '63646159',
        text:
          'Что не соответствует установленным требованиям безопасности при обслуживании компрессора?',
        answers: [
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть закрыты',
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть снабжены знаками безопасности, запрещающими вход в камеры',
          'Во время работы воздушного центробежного компрессора и воздушного фильтра вход обслуживающего персонала в помещение камеры после воздушного фильтра (камеры чистого воздуха) не допускается',
        ],
        correctAnswers: [
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
        ],
      },
      {
        code: '63646160',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Технический руководитель предприятия',
          'Руководитель территориального органа Ростехнадзора',
          'Специалист по гражданской обороне и чрезвычайным ситуациям',
          'Специалист по промышленной безопасности',
        ],
        correctAnswers: ['Технический руководитель предприятия'],
      },
      {
        code: '63646161',
        text:
          'С какой периодичностью предусматривается проведение учебных занятий по одной или нескольким позициям оперативной части ПЛАС уровня "Б" в разные периоды года и в разное время суток в структурных подразделениях?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 18 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63646162',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 24 часов',
          'В течение 8 часов',
          'Время устанавливается при проектировании средств противоаврийной защиты',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63646163',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Решением руководителя организации с учетом объема работ',
          'Решением лица, утверждающего технологический регламент',
          'Решением начальника производственного участка с учетом затрат',
          'В соответствии с проектной документацией руководящим составом производства',
        ],
        correctAnswers: [
          'Решением лица, утверждающего технологический регламент',
        ],
      },
      {
        code: '63646164',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите два правильных варианта ответа.',
        answers: [
          'Расположение крупногабаритного и тяжеловесного оборудования по внутреннему контуру объекта',
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
        ],
        correctAnswers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
        ],
      },
      {
        code: '63646165',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          '5 лет',
          '2 года',
          'Срок устанавливается территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63646166',
        text:
          'Какую объемную долю кислорода в воздухе должна обеспечивать постоянно действующая приточно-вытяжная вентиляция в помещениях, объем которых в метрах кубических превышает объем жидкости, находящейся в сосудах в литрах, менее чем в 7 раз?',
        answers: [
          'Не менее 19 и не более 23%',
          'Не менее 18 и не более 23%',
          'Не менее 19 и не более 24%',
          'Не менее 20 и не более 25%',
        ],
        correctAnswers: ['Не менее 19 и не более 23%'],
      },
      {
        code: '63646167',
        text:
          'В каких случаях производится внеочередная проверка знаний плана локализации и ликвидации аварий (ПЛА) на опасных производственных объектах?',
        answers: [
          'В случае внесения изменений в ПЛА',
          'В случае перевода работников организации на другое рабочее место',
          'В случае их неквалифицированных действий при проведении учебной тревоги',
          'Во всех перечисленных случаях, а также по предложениям территориальных органов Ростехнадзора',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях, а также по предложениям территориальных органов Ростехнадзора',
        ],
      },
      {
        code: '63646168',
        text:
          'Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией',
          'Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63646169',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается в случае описания порядка оформления электронного документа в локальных нормативных актах',
          'Допускается в случае согласования порядка оформления и регистрации наряда-допуска территориальными органами Ростехнадзора',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63646170',
        text:
          'Какие из случаев, предусматривающих дистанционное управление арматурой технологических трубопроводов, указаны неверно?',
        answers: [
          'На трубопроводах кислорода при давлении менее 1,6 МПа и диаметром 100 мм и менее',
          'На вводах трубопроводов кислорода и азота в здание и выходе из здания при расходах более 5000 м3/ч',
          'Для включения резервных линий регулирования и резервных источников снабжения продуктов разделения воздуха',
          'Если арматура включается в систему автоматического регулирования или управления',
        ],
        correctAnswers: [
          'На трубопроводах кислорода при давлении менее 1,6 МПа и диаметром 100 мм и менее',
        ],
      },
      {
        code: '63646171',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Ботинками с защитным металлическим подноском и каской',
          'Защитной одеждой из синтетических тканей',
          'Всеми перечисленными средствами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63646172',
        text:
          'Какой высоты должно быть ограждение реципиентов, расположенных вне зданий?',
        answers: [
          'Не менее 1,2 м',
          'Не менее 1,1 м',
          'Не менее 1,0 м',
          'Не менее 0,9 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63646173',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях повышения устойчивости развития организации',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях обеспечения взаимодействия между пожарной охраной и другими службами, участвующими в локализации и ликвидации последствий аварии',
          'В целях обеспечения соответствия объекта требованиям законодательства',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63646174',
        text:
          'Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?',
        answers: [
          'Наличие декларации промышленной безопасности',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Наличие разрешения на применение технических устройств, выдаваемого органами Ростехнадзора',
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
      },
      {
        code: '63646175',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Критическими параметрами технологического процесса',
          'Техническими характеристиками применяемых компрессоров и насосов',
          'Свойствами перемещаемых продуктов',
          'Необходимостью и условиями обеспечения непрерывности технологического процесса.',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса.',
        ],
      },
      {
        code: '63646176',
        text:
          'Кем определяются порядок и сроки приведения организаций в соответствии с требованиями Правил промышленной безопасности резиновых производств?',
        answers: [
          'Руководителем территориального органа Ростехнадзора',
          'Руководителем организации',
          'Независимой экспертной организацией',
          'Главным инженером',
        ],
        correctAnswers: ['Руководителем организации'],
      },
      {
        code: '63646177',
        text:
          'Какое из перечисленных требований допускается при производстве формовых и неформовых изделий?',
        answers: [
          'Хранить хладагент в местах его потребления',
          'Производить шероховку и т. п. работы внутри изделия механизированным инструментом (дрелями) с пневмоприводом',
          'Изготавливать заготовки сырой резины для формовых резинотехнических изделий ручным способом',
          'Применять сухой кварцевый песок под давлением для очистки арматуры от окислов',
        ],
        correctAnswers: [
          'Производить шероховку и т. п. работы внутри изделия механизированным инструментом (дрелями) с пневмоприводом',
        ],
      },
      {
        code: '63646178',
        text: 'Какие ремонтные работы выполняются по наряду-допуску?',
        answers: [
          'Только ремонтные работы на низкотемпературных технических устройствах без их полного отогрева',
          'Только ремонтные работы на оборудовании, отключенном от работающей воздухоразделительной установки',
          'Только ремонтные работы на участке трубопровода, отключенном от работающей воздухоразделительной установки',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63646179',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Технологическая карта выполнения работ в емкостях',
          'Инструкции по применению средств индивидуальной защиты',
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
          'Паспорта технических устройств, на которых проводятся газоопасные работы',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63646180',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63646181',
        text:
          'Что не соответствует требованиям безопасности при транспортировании, перемещении, погрузке и разгрузке баллонов с продуктами разделения воздуха?',
        answers: [
          'Перемещение баллонов грузоподъемными устройствами и транспортными средствами должно осуществляться в соответствии с технологической инструкцией',
          'При погрузке и разгрузке баллонов не допускается их сбрасывание, соударение',
          'Допускается транспортирование баллона с кислородом и баллона с горючим газом на тележке к рабочему месту',
          'Транспортирование баллонов с продуктами разделения воздуха производится в горизонтальном положении в контейнерах. Работы по погрузке и выгрузке контейнеров необходимо механизировать',
        ],
        correctAnswers: [
          'Транспортирование баллонов с продуктами разделения воздуха производится в горизонтальном положении в контейнерах. Работы по погрузке и выгрузке контейнеров необходимо механизировать',
        ],
      },
      {
        code: '63646182',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Инспектором территориального органа Ростехнадзора',
          'Главным пожарным инспектором МЧС России',
          'Специалистом по промышленной безопасности',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63646183',
        text:
          'В течение какого времени наряд-допуск на проведение огневых работ действителен?',
        answers: [
          'В течение одной дневной смены',
          'До завершения проведения огневых работ',
          'По решению оперативного руководителя',
        ],
        correctAnswers: ['В течение одной дневной смены'],
      },
      {
        code: '63646184',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только для опасных производственных объектов I класса опасности',
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для опасных производственных объектов I и II классов опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63646185',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Специализированная подрядная организация',
          'Работники, назначение приказом по предприятию',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63646186',
        text:
          'Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?',
        answers: [
          'Дистиллят, полученный из воды питьевого качества',
          'Масла, рекомендованные разработчиками компрессора',
          'Умягченную воду, полученную из заводской системы пароснабжения',
          'Конденсат, полученный из заводской системы пароснабжения',
        ],
        correctAnswers: ['Дистиллят, полученный из воды питьевого качества'],
      },
      {
        code: '63646187',
        text:
          'Какой должна быть максимальная масса снаряженного автономного воздушного изолирующего дыхательного аппарата при оснащении нештатного аварийно-спасательного формирования?',
        answers: ['16 кг', '12 кг', '10 кг', '20 кг'],
        correctAnswers: ['16 кг'],
      },
      {
        code: '63646188',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63646189',
        text:
          'В каком случае допускается одновременное проведение ремонта технических устройств и трубопроводов?',
        answers: [
          'В случае проведения ремонта трубопроводов и арматуры теплого и холодного концов регенераторов',
          'В случае проведения ремонта трубопроводов и арматуры холодного конца регенераторов и системы приказного воздуха переключения регенераторов или механизма переключения',
          'В случае проведения ремонта принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63646190',
        text:
          'Что должно проводиться для подтверждения соответствия Ех-оборудования стандартам на определенный вид взрывозащиты?',
        answers: [
          'Получение заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Опытные работы, проводимые разработчиком данного оборудования, и экспертиза промышленной безопасности',
          'Оценка научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Испытания промышленных образцов оборудования на взрывозащищенность',
        ],
        correctAnswers: [
          'Испытания промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63646191',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует к наряду-допуску оформить акт осмотра места проведения работ комиссией по охране труда',
          'Следует к наряду-допуску оформить график проведения проверок места выполнения работ',
          'Следует к наряду-допуску приложить копии документов, подтверждающих факт проведения стажировки работников по данному виду работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63646192',
        text:
          'С какой периодичностью необходимо удалять шлак с поверхности расплава солей, во избежание его загорания?',
        answers: [
          'Не реже 2 раз в смену',
          'Не реже 3 раз в неделю',
          'Не реже раза в смену',
        ],
        correctAnswers: ['Не реже 2 раз в смену'],
      },
      {
        code: '63646193',
        text:
          'Электроосвещение каким напряжением должна иметь охладительная установка протекторного агрегата?',
        answers: [
          'Не выше 60 В',
          'Не выше 12 В',
          'Не выше 36 В',
          'Не выше 48 В',
        ],
        correctAnswers: ['Не выше 36 В'],
      },
      {
        code: '63646194',
        text:
          'Какие требования к техническим устройствам и инженерным коммуникациям не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Система продувок кислородных, азотных и аргонных технических устройств и коммуникаций должна исключать возможность поступления продуктов разделения воздуха в помещение',
          'При изменении технологического процесса или реконструкции производственного участка действующие на этом участке вентиляционные системы необходимо привести в соответствие с новыми производственными условиями',
          'При устройстве под блоками разделения воздуха подвального помещения в нем необходимо поддерживать температуру воздуха не ниже 5 °C, а также предусматривать систему вытяжной вентиляции',
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
        ],
        correctAnswers: [
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63646195',
        text:
          'Когда план мероприятий по локализации и ликвидации последствий аварий считается принятым?',
        answers: [
          'После введения в действие приказом по организации',
          'После согласования с руководителями всех специализированных служб, задействованных в соответствии с оперативной частью плана',
          'После утверждения руководителями (заместителями руководителей) организаций, эксплуатирующих объекты',
          'По истечение 1 месяца с даты издания приказа о введении в действие и внесения изменений после опробования',
        ],
        correctAnswers: ['После введения в действие приказом по организации'],
      },
      {
        code: '63646196',
        text:
          'Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?',
        answers: [
          'Строительная организация',
          'Эксплуатирующая организация',
          'Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов',
          'Проектная организация',
        ],
        correctAnswers: ['Проектная организация'],
      },
      {
        code: '63646197',
        text:
          'Какие требования безопасности, установленные Правилами безопасности химически опасных производственных объектов, к проведению ремонта в отсеках клапанных коробок (обратноповоротных клапанов) регенераторов блока разделения воздуха, указаны неверно?',
        answers: [
          'Вход в отсеки для ремонта клапанов разрешается только после снятия давления из аппаратов блока',
          'Вход в отсеки для ремонта клапанов разрешается только после местного отогрева отсеков клапанных коробок (обратноповоротных клапанов)',
          'Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску',
          'Объемная доля кислорода внутри кожуха блока разделения воздуха должна составлять 19 - 23%',
        ],
        correctAnswers: [
          'Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску',
        ],
      },
      {
        code: '63646198',
        text:
          'Что из перечисленного входит в функции командного пункта (оперативного штаба), который создается ответственным руководителем для принятия эффективных мер по локализации и ликвидации аварии?',
        answers: [
          'Сбор и регистрация информации о ходе развития аварии и принятых мерах по ее локализации и ликвидации',
          'Текущая оценка информации и принятие решений по оперативным действиям в зоне действия поражающих факторов аварии и за ее пределами',
          'Координация действий персонала ОПО и всех привлеченных подразделений и служб, участвующих в локализации и ликвидации аварии',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63646199',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63646200',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63646201',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63646202',
        text:
          'С какой периодичностью следует чистить светильники, установленные в подготовительных и других цехах, на участках с большим выделением пыли?',
        answers: [
          '1 раз в месяц',
          '1 раз в 3 месяца',
          '1 раз в 6 месяцев',
          '1 раз в год',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63646203',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Выбор значений параметров состояния технологической среды (состава, давления, температуры), снижающих ее химическую опасность',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Надежное энергообеспечение',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63646204',
        text:
          'Каким должно быть расстояние от открытых площадок, на которых производится хранение утильных и изношенных покрышек, до зданий и сооружений?',
        answers: [
          'Не менее 30 м',
          'Не менее 50 м',
          'Не менее 10 м',
          'Не менее 100 м',
        ],
        correctAnswers: ['Не менее 30 м'],
      },
      {
        code: '63646205',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Руководителю структурного подразделения или его заместителю, а также в газоспасательную службу (ГСС)',
          'Руководителю структурного подразделения и специалисту по охране труда',
          'Специалисту по промышленной безопасности и в газоспасательную службу (ГСС)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в газоспасательную службу (ГСС)',
        ],
      },
      {
        code: '63646206',
        text:
          'Кем должен быть утвержден перечень нормативно-технической документации, устанавливающей порядок и условия безопасного ведения производственного процесса, для каждого рабочего места организации?',
        answers: [
          'Техническим руководством организации',
          'Руководителем организации',
          'Ответственным за проведение производственного контроля',
        ],
        correctAnswers: ['Техническим руководством организации'],
      },
      {
        code: '63646207',
        text:
          'Под чьим надзором, в случае аварии, руководителем специализированной службы осуществляются работы в соответствии с мероприятиями ПЛА до прибытия на место аварии ответственного руководителя?',
        answers: [
          'Ответственного руководителя в организации и, по согласованию с ним',
          'Самостоятельно',
          'Ответственного руководителя работ по телефонной связи',
          'Руководителя пожарных формирований на объекте',
        ],
        correctAnswers: ['Самостоятельно'],
      },
      {
        code: '63646208',
        text:
          'В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?',
        answers: [
          'В справочной литературе',
          'В эксплуатационной документации организации-изготовителя',
          'В технологических регламентах',
          'В технологических картах',
        ],
        correctAnswers: [
          'В эксплуатационной документации организации-изготовителя',
        ],
      },
      {
        code: '63646209',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
        ],
        correctAnswers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
        ],
      },
      {
        code: '63646210',
        text:
          'На основании каких характеристик проводится анализ опасности аварии на ОПО?',
        answers: [
          'На основании физико-химических свойств веществ, обращающихся в оборудовании',
          'На основании аппаратурного оформления',
          'На основании режимов работы оборудования',
          'На основании всех перечисленных характеристик, а также с учетом анализа аварий, имевших место на данном и на аналогичных объектах',
        ],
        correctAnswers: [
          'На основании всех перечисленных характеристик, а также с учетом анализа аварий, имевших место на данном и на аналогичных объектах',
        ],
      },
      {
        code: '63646211',
        text:
          'Каким должно быть остаточное давление в кислородном баллоне при его разрядке?',
        answers: [
          'Не ниже 0,05 МПа',
          'Не ниже 0,04 МПа',
          'Не ниже 0,03 МПа',
          'Не ниже 0,02 МПа',
        ],
        correctAnswers: ['Не ниже 0,05 МПа'],
      },
    ],
    63642: [
      {
        code: '63642000',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Только средствами автоматического регулирования',
          'Только средствами противоаварийной защиты',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63642001',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63642002',
        text:
          'Как должна производиться инструментальная проверка эффективности работы вентиляционных систем?',
        answers: [
          'Только после реконструкции вентиляционных систем с оформлением акта проверки',
          'Только после каждого капитального ремонта с оформлением акта проверки',
          'Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации',
          'Не реже одного раза в полгода с оформлением акта проверки',
        ],
        correctAnswers: [
          'Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации',
        ],
      },
      {
        code: '63642003',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63642004',
        text:
          'Какие требования к техническим устройствам и инженерным коммуникациям не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
          'На все вентиляционные системы составляются соответствующие эксплуатационные документы (паспорта или формуляры)',
          'При изменении технологического процесса или реконструкции производственного участка действующие на этом участке вентиляционные системы приводятся в соответствие с новыми производственными условиями',
          'Система продувок кислородных, азотных и аргонных технических устройств и коммуникаций должна исключать возможность поступления продуктов разделения воздуха в помещение',
        ],
        correctAnswers: [
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63642005',
        text:
          'Как следует производить досыпку адсорбентов в адсорберы блоков комплексной очистки?',
        answers: [
          'Следует производить при плановой остановке воздухоразделительной установки по наряду-допуску',
          'Следует производить на работающем блоке разделения воздуха по наряду-допуску',
          'Следует производить на неработающем блоке разделения воздуха перед началом смены',
          'Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухораспределительной установки по наряду-допуску',
        ],
        correctAnswers: [
          'Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухораспределительной установки по наряду-допуску',
        ],
      },
      {
        code: '63642006',
        text:
          'Каким документом определяются сроки проверки состояния адсорбента в блоке комплексной очистки при условии сохранения его работоспособности?',
        answers: [
          'Общепроизводственной инструкцией',
          'Руководством по эксплуатации блока комплексной очистки',
          'Технологической инструкцией',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Технологическим регламентом'],
      },
      {
        code: '63642007',
        text:
          'Каким должен быть коэффициент заполнения резервуаров при хранении аммиака под избыточным давлением?',
        answers: [
          'Не более 0,85 от геометрического объема резервуара',
          'Не более 0,87 от геометрического объема резервуара',
          'Не более 0,91 от геометрического объема резервуара',
          'Не более 0,95 от геометрического объема резервуара',
        ],
        correctAnswers: ['Не более 0,85 от геометрического объема резервуара'],
      },
      {
        code: '63642008',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63642009',
        text:
          'Каким должно быть количество деблокирующих ключей в схемах противоаварийной защиты объектов производств масел?',
        answers: [
          'Максимальным',
          'Минимальным',
          'Не больше 10-ти',
          'Не меньше 8-ми',
        ],
        correctAnswers: ['Минимальным'],
      },
      {
        code: '63642010',
        text:
          'С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 10 лет',
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
        correctAnswers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63642011',
        text:
          'Как должны быть оборудованы места пересыпки и транспортирования пылящего химически опасного продукта в производстве пигментов?',
        answers: [
          'Требования к местам пересыпки и транспортирования устанавливаются в проекте',
          'Пересыпка и транспортирование должны осуществляться в закрытых бункерах и конвейерах',
          'Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам',
          'Места пересыпки и транспортирования должны оборудоваться на открытых площадках, защищенных от ветра',
        ],
        correctAnswers: [
          'Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам',
        ],
      },
      {
        code: '63642012',
        text:
          'Что необходимо немедленно предпринять при внезапной остановке жаровни объектов производств растительных масел?',
        answers: [
          'Выгрузить мезгу и очистить чаны',
          'Прекратить подачу глухого пара в жаровню',
          'Сообщить ответственному за безопасное производство работ',
          'Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню',
        ],
        correctAnswers: [
          'Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню',
        ],
      },
      {
        code: '63642013',
        text:
          'При какой минимальной остановке шнекового пресса необходимо очистить питатель и пресс от мезги?',
        answers: [
          'На 5 минут',
          'На 10 минут',
          'На 30 минут',
          'При любой внезапной остановке',
        ],
        correctAnswers: ['На 10 минут'],
      },
      {
        code: '63642014',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к управлению оборудованием, предназначенным для переработки и хранения криптоноксеноновой смеси, криптона и ксенона, указаны неверно?',
        answers: [
          'Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления',
          'Местное управление допускается при наличии ограждения вокруг оборудования на расстоянии 1 м от него',
          'Должно быть предусмотрено дистанционное управление',
          'Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м',
        ],
        correctAnswers: [
          'Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м',
        ],
      },
      {
        code: '63642015',
        text:
          'Какой минимальный уклон должны иметь внутрицеховые трубопроводы для пятисернистого фосфора?',
        answers: ['0,005', '0,002', '0,02', '0,1'],
        correctAnswers: ['0,1'],
      },
      {
        code: '63642016',
        text: 'Каким образом необходимо наполнять цистерны фосфором?',
        answers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 25 °С. Слой воды или раствора должен быть высотой не менее 100 мм, свободное пространство цистерны - не менее 10%',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °С. Слой воды или раствора должен быть высотой не менее 500 мм',
          'Перед наполнением цистерн их необходимо продуть инертным газом',
        ],
        correctAnswers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
        ],
      },
      {
        code: '63642017',
        text:
          'Какая минимальная ширина прохода должна быть предусмотрена в складе между штабелями мешков с серой?',
        answers: ['1 м', '2 м', '3 м', '4 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63642018',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63642019',
        text:
          'Какое из перечисленных требований не допускается на территории склада жидкого аммиака?',
        answers: [
          'Сопряжение двух наружных ограждений резервуаров для хранения жидкого аммиака',
          'Планирование территории в ограждении резервуаров для хранения жидкого аммиака с уклоном в сторону приямка',
          'Размещение сливоналивных устройств между расположенными рядом путями',
          'Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака',
        ],
        correctAnswers: [
          'Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака',
        ],
      },
      {
        code: '63642020',
        text:
          'В соответствии с каким документом выполняется защита от статического электричества технических устройств и коммуникаций газообразного кислорода и жидких продуктов разделения воздуха?',
        answers: [
          'В соответствии с технологическими регламентами',
          'В соответствии с распорядительными документами эксплуатирующей организации',
          'В соответствии с внутренними инструкциями эксплуатирующей организации',
          'В соответствии с проектной документацией (документацией)',
        ],
        correctAnswers: [
          'В соответствии с проектной документацией (документацией)',
        ],
      },
      {
        code: '63642021',
        text:
          'Чему соответствует вместимость поддонов, которыми оснащают емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?',
        answers: [
          '50% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей',
          '80% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей',
          'Объему всего расположенного в них оборудования',
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
        ],
        correctAnswers: [
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
        ],
      },
      {
        code: '63642022',
        text:
          'Какие требования безопасности предъявляются на период регенерации адсорбента к эксплуатации блока разделения воздуха при наличии в воздухораспределительной установке только одного адсорбера на потоке кубовой жидкости?',
        answers: [
          'Блок разделения воздуха необходимо останавливать не менее чем за 5 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента и предварительного проведения замеров концентрации ацетилена в жидком кислороде. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию',
        ],
        correctAnswers: [
          'Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию',
        ],
      },
      {
        code: '63642023',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
          'Только при деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63642024',
        text: 'Где следует устанавливать блочные холодильные машины?',
        answers: [
          'На открытых площадках, если позволяет климатическое исполнение оборудования',
          'Место размещения определяется проектной документацией',
          'Над площадками открытых насосных и компрессорных установок',
          'В помещении машинного (аппаратного) отделения',
        ],
        correctAnswers: ['В помещении машинного (аппаратного) отделения'],
      },
      {
        code: '63642025',
        text:
          'Каким документом определяется продолжительность эксплуатации воздухораспределительной установки между двумя полными отогревами установки?',
        answers: [
          'Правилами промышленной безопасности',
          'Заключением экспертизы промышленной безопасности',
          'Техническим регламентом Таможенного союза',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Технологическим регламентом'],
      },
      {
        code: '63642026',
        text:
          'Что должна обеспечивать система электрического управления механизмами поточно-транспортных систем при производстве фосфора и его соединений?',
        answers: [
          'Только электрическую блокировку всех механизмов от завала транспортируемых веществ с применением реле скорости для элеваторов и транспортеров',
          'Только аварийное отключение транспортеров с помощью троса, соединенного с выключателем',
          'Только предпусковую звуковую сигнализацию',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63642027',
        text:
          'Какое из перечисленных утверждений, относящихся к трубопроводной арматуре объектов производств растительных масел, допускается?',
        answers: [
          'Все перечисленные утверждения',
          'Изготовление лючков и задвижек на перепускных течках (экстрактор, испаритель растворителя из шрота) из нержавеющей стали',
          'Использование пробковых чугунных кранов в качестве арматуры противоаварийного назначения',
          'Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств',
        ],
        correctAnswers: [
          'Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств',
        ],
      },
      {
        code: '63642028',
        text:
          'В каком случае допускается эксплуатация воздухоразделительных технических устройств, применяемых на опасных производственных объектах производств продуктов разделения воздуха?',
        answers: [
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают предельно допустимой нормы',
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной технологическим процессом',
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают 10% нормы, установленной разработчиком такого технического устройства',
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства',
        ],
        correctAnswers: [
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства',
        ],
      },
      {
        code: '63642029',
        text:
          'Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?',
        answers: [
          'Для технологических блоков I категории взрывоопасности',
          'Для технологических блоков II категории взрывоопасности',
          'Для технологических блоков III категории взрывоопасности',
        ],
        correctAnswers: [
          'Для технологических блоков I категории взрывоопасности',
        ],
      },
      {
        code: '63642030',
        text:
          'Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?',
        answers: [
          'Проектной документацией',
          'Исходными данными на проектирование',
          'Технической документацией производителя',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Технической документацией производителя'],
      },
      {
        code: '63642031',
        text:
          'На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?',
        answers: [
          'Не менее 2,2 м',
          'Не менее 3,4 м',
          'Не более 2,2 м',
          'Не более 3,4 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63642032',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'Только в технологическом регламенте на производство продукции',
          'Только в проектной документации',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63642033',
        text:
          'Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?',
        answers: [
          'Vз.р. больше Vс х 0,2 м³',
          'Vз.р. больше Vс х 0,5 м³',
          'Vз.р. больше Vс х 0,9 м³',
          'Vз.р. больше Vс х 1,5 м³',
        ],
        correctAnswers: ['Vз.р. больше Vс х 0,5 м³'],
      },
      {
        code: '63642034',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63642035',
        text:
          'Какой максимальный коэффициент заполнения объема резервуара допускается при хранении жидкого аммиака под избыточным давлением?',
        answers: [
          '0,75 от геометрического объема резервуара',
          '0,8 от геометрического объема резервуара',
          '0,85 от геометрического объема резервуара',
          '0,9 от геометрического объема резервуара',
        ],
        correctAnswers: ['0,85 от геометрического объема резервуара'],
      },
      {
        code: '63642036',
        text:
          'Какие должны быть здания на территории склада жидкого аммиака по степени огнестойкости?',
        answers: [
          'Не ниже II степени огнестойкости',
          'Не ниже III степени огнестойкости',
          'Не ниже IV степени огнестойкости',
          'Не ниже V степени огнестойкости',
        ],
        correctAnswers: ['Не ниже II степени огнестойкости'],
      },
      {
        code: '63642037',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для производств с новой технологией',
          'Для новых в данной организации производств',
          'Для всех перечисленных производств',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63642038',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Только наличие природных оврагов, выемок, низин',
          'Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63642039',
        text:
          'Какое требование предъявляется к продолжительности непрерывной работы аппаратов воздухораспределительных установок, в которых испаряется жидкий кислород и отогрев которых при работающей установке предусмотрен технологическим процессом?',
        answers: [
          'Не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде',
          'Продолжительность непрерывной работы не устанавливается при условии их исправности и проведения всех видов технических осмотров',
          'Не должна превышать срока, установленного документацией завода-изготовителя',
          'Не должна превышать срока, установленного техническими условиями разработчика установки',
        ],
        correctAnswers: [
          'Не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде',
        ],
      },
      {
        code: '63642040',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63642041',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
          'Во всех указанных местах',
        ],
        correctAnswers: ['Во всех указанных местах'],
      },
      {
        code: '63642042',
        text:
          'Какие требования предъявляются к температурному режиму работы регенераторов?',
        answers: [
          'Температурный режим должен регулироваться автоматически для исключения колебания температурного режима регенераторов',
          'Температурный режим следует поддерживать строго в пределах, оговоренных технологической инструкцией',
          'Температурный режим должен обеспечить работу регенераторов в оптимальном режиме',
          'Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха',
        ],
        correctAnswers: [
          'Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха',
        ],
      },
      {
        code: '63642043',
        text:
          'Какое количество запорных арматур следует устанавливать на технологических трубопроводах взрывопожароопасных сред для герметичного отключения от коллектора агрегатов (технологических аппаратов) с рабочим давлением (далее - Pр) ≥4 МПа (40 кгс/см²)?',
        answers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
          'Одну единицу запорной арматуры и дренажную арматуру с заглушкой',
          'Три единицы запорной арматуры',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
        ],
      },
      {
        code: '63642044',
        text:
          'Чем должно быть оснащено оборудование для разделения суспензий и фильтрации?',
        answers: [
          'Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
          'Манометром, обеспечивающим измерение давления при подаче суспензий',
          'Укрытиями, обеспечивающими прекращение подачи суспензий при отклонениях параметров инертной среды',
          'Блокировками, обеспечивающими прекращение подачи суспензий при допустимых отклонениях параметров инертной среды',
        ],
        correctAnswers: [
          'Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63642045',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63642046',
        text:
          'Какими устройствами не должны оснащаться насосы, применяемые для нагнетания легковоспламеняющихся и горючих жидкостей (масло растительное и минеральное, мисцелла, растворитель)?',
        answers: [
          'Устройствами, регулирующими частоту вращения вала',
          'Блокировками, исключающими пуск и работу насоса "всухую" или прекращающими работу насоса при падении давления перемещаемой жидкости в нагнетательном патрубке насоса ниже установленного регламентом или паспортными данными или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений (верхний и нижний уровни)',
          'Средствами предупредительной сигнализации о нарушении параметров работы, влияющих на безопасность',
          'Средствами местного и дистанционного отключения, расположенными в легкодоступных местах',
        ],
        correctAnswers: ['Устройствами, регулирующими частоту вращения вала'],
      },
      {
        code: '63642047',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 8 часов',
          'В течение 24 часов',
          'Время устанавливается в проектной документации',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63642048',
        text: 'В какие сроки производится замена адсорбента?',
        answers: [
          'В сроки, установленные технологическим регламентом',
          'В сроки, установленные технической документацией разработчика установки',
          'В сроки, установленные отраслевым стандартом',
          'В сроки, установленные технологической инструкцией',
        ],
        correctAnswers: ['В сроки, установленные технологическим регламентом'],
      },
      {
        code: '63642049',
        text:
          'Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?',
        answers: [
          'Через бытовые помещения',
          'Через вспомогательные помещения',
          'По наружным стенам зданий, не связанных с обращением кислот и щелочей',
          'В местах пересечения железных и автомобильных дорог',
        ],
        correctAnswers: ['В местах пересечения железных и автомобильных дорог'],
      },
      {
        code: '63642050',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Проектной организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63642051',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В паспортах оборудования и трубопроводной арматуры',
          'В технологическом регламенте',
          'В техническом задании на изготовление оборудования',
          'В проектной документации',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63642052',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 6 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63642053',
        text: 'Какое оборудование подлежит обезжириванию?',
        answers: [
          'Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Средства измерений, работающие при давлении до 0,6 МПа, конструкция которых позволяет их устанавливать в положении, обеспечивающем свободное стекание масла с внутренних поверхностей, соприкасающихся с кислородом',
          'Арматура перед ее монтажом, предназначенная для работы с кислородом, обезжиривание которой было проведено на заводе-изготовителе (что подтверждается сопроводительными документами или соответствующим клеймением) и не нарушена упаковка',
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
        ],
        correctAnswers: [
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
        ],
      },
      {
        code: '63642054',
        text:
          'Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?',
        answers: [
          'Расстояние определяется по нормам пожарной безопасности',
          'Расстояние должно быть обусловлено устойчивостью здания склада к воздействию ударной волны',
          'Расстояние определяется в соответствии с требованиями строительных норм и правил и с учетом расчетного радиуса опасной зоны',
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
        correctAnswers: [
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
      },
      {
        code: '63642055',
        text:
          'Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите два правильных варианта ответа.',
        answers: [
          'На контрольно-пропускном пункте объекта',
          'В помещении диспетчера предприятия',
          'На ограждении по периметру объекта через каждые 50 м',
          'На наружных установках',
        ],
        correctAnswers: [
          'В помещении диспетчера предприятия',
          'На наружных установках',
        ],
      },
      {
        code: '63642056',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 10 минут',
          'Через 30 минут',
          'Через 5 минут',
          'Через 60 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63642057',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63642058',
        text:
          'Какая максимальная объемная доля аммиака в межстенном пространстве резервуара во время эксплуатации?',
        answers: ['0,5%', '1%', '1,5%', '2%'],
        correctAnswers: ['0,5%'],
      },
      {
        code: '63642059',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.',
        answers: [
          'Запорную и запорно-регулирующую арматуру',
          'Клапаны, отсекающие и другие отключающие устройства',
          'Средства, регистрирующие превышение давления',
          'Мануальные системы подавления взрыва',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру',
          'Клапаны, отсекающие и другие отключающие устройства',
        ],
      },
      {
        code: '63642060',
        text:
          'Как требуется размещать технологическое оборудование взрывопожароопасных производств?',
        answers: [
          'С учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'С учетом обеспечения безопасности обслуживания и эксплуатации',
          'С учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене',
          'С учетом всех перечисленных требований',
        ],
        correctAnswers: ['С учетом всех перечисленных требований'],
      },
      {
        code: '63642061',
        text:
          'Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
        correctAnswers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
      },
      {
        code: '63642062',
        text:
          'Какой должна быть свободная высота эстакад для технологических трубопроводов над проездами и проходами для железнодорожных путей (над головкой рельса)?',
        answers: [
          'Не менее 5,55 м',
          'Не менее 5,0 м',
          'Не менее 6,0 м',
          'Не менее 4,5 м',
        ],
        correctAnswers: ['Не менее 5,55 м'],
      },
      {
        code: '63642063',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 6',
          'Не менее 10',
          'Не менее 15',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63642064',
        text:
          'При каких условиях жидкий азот в качестве хладоагента может применяться без специальных мер по предупреждению загорания и взрыва?',
        answers: [
          'Если объемная доля кислорода в жидком азоте не превышает 30%',
          'Если объемная доля кислорода в жидком азоте 31%',
          'Если объемная доля кислорода в жидком азоте 32%',
          'Если объемная доля кислорода в жидком азоте не превышает 33%',
        ],
        correctAnswers: [
          'Если объемная доля кислорода в жидком азоте не превышает 30%',
        ],
      },
      {
        code: '63642065',
        text:
          'В каком случае допускается постоянное пребывание людей в анализаторных помещениях объектов производств растительных масел?',
        answers: [
          'Если анализаторы имеют защиту от воспламенения и взрыва по газовым линиям',
          'Если анализаторные помещения имеют предохраняющие конструкции',
          'Если ограничители расхода и давления на пробоотборных устройствах размещаются вне анализаторного помещения',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63642066',
        text:
          'Какие сроки освидетельствования систем холодоснабжения с ограниченной зарядкой аммиаком (не более 50 кг), поставляемых комплектно организациями-изготовителями?',
        answers: [
          'Устанавливаются эксплуатационной документацией организаций-изготовителей',
          'Устанавливаются лицом, ответственным за исправное состояние и безопасную работу сосудов (аппаратов)',
          'Устанавливаются Правилами безопасности химически опасных производственных объектов',
          'Не реже 1 раза в год',
        ],
        correctAnswers: [
          'Устанавливаются эксплуатационной документацией организаций-изготовителей',
        ],
      },
      {
        code: '63642067',
        text:
          'Какова предельно допустимая величина концентрации взрывоопасной парогазовой фазы сигнализации средств автоматического газового анализа в производственных помещениях на открытых наружных установках?',
        answers: [
          'Не более 20% от нижнего концентрационного предела распространения пламени',
          'Не более 25% от нижнего концентрационного предела распространения пламени',
          'Не более 30% от нижнего концентрационного предела распространения пламени',
          'Не более 40% от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Не более 20% от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63642068',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63642069',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63642070',
        text:
          'При какой концентрации масла в кислороде воздухоразделительная установка должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63642071',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'Если это определено и обосновано разработчиком проекта',
          'По решению территориального органа Ростехнадзора',
          'Если это определено и обосновано научно-исследовательской организацией',
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63642072',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
        ],
      },
      {
        code: '63642073',
        text:
          'Какие меры безопасности необходимо предпринять, если при забросе жидкости в турбодетандер или при понижении температуры газа на входе ниже температуры, указанной в технологической инструкции, не сработала автоматическая защита?',
        answers: [
          'Немедленно продуть трубопроводы до и после турбодетандера',
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до турбодетандера',
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы после турбодетандера',
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера',
        ],
        correctAnswers: [
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера',
        ],
      },
      {
        code: '63642074',
        text:
          'Под каким разрежением должен находиться экстрактор многократного орошения?',
        answers: [
          '0,02 - 0,05 кПа (2 - 5 мм вод. ст.)',
          '0,05 - 0,10 кПа (5 - 10 мм вод. ст.)',
          '0,10 - 0,15 кПа (10 - 15 мм вод. ст.)',
          '0,15 - 0,30 кПа (15 - 30 мм вод. ст.)',
        ],
        correctAnswers: ['0,05 - 0,10 кПа (5 - 10 мм вод. ст.)'],
      },
      {
        code: '63642075',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63642076',
        text:
          'Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
        ],
        correctAnswers: [
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
        ],
      },
      {
        code: '63642077',
        text:
          'Какое из перечисленных пылеулавливающих оборудований объектов производств растительных масел допускается размещать вне зданий?',
        answers: [
          'Пылеулавливающее оборудование для сухой очистки воздуха',
          'Пылеулавливающее оборудование для очистки воздуха от минеральной пыли после сепараторов',
          'Пылеулавливающее оборудование для очистки воздуха от масличной пыли после семеновеечных машин',
          'Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси',
        ],
        correctAnswers: [
          'Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси',
        ],
      },
      {
        code: '63642078',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63642079',
        text:
          'В течение какого времени и при каком давлении следует проводить вакуумирование холодильной установки перед пуском в эксплуатацию после пневматических испытаний?',
        answers: [
          'В течение 7 часов при остаточном давлении 0,05 МПа (0,5 кгс/см²)',
          'В течение 10 часов при остаточном давлении 0,015 МПа (0,15 кгс/см²)',
          'В течение 15 часов при остаточном давлении 0,03 МПа (0,3 кгс/см²)',
          'В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)',
        ],
        correctAnswers: [
          'В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)',
        ],
      },
      {
        code: '63642080',
        text:
          'С какой скоростью следует снижать уровень воды в оборудовании после заполнения для обеспечения медленного окисления пирофорных отложений при очистке оборудования от пирофорных соединений?',
        answers: [
          'Не более 0,5 м в час',
          'Не более 0,8 м в час',
          'Не более 1,2 м в час',
          'Не более 1 м в час',
        ],
        correctAnswers: ['Не более 0,5 м в час'],
      },
      {
        code: '63642081',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?',
        answers: [
          'Правилами безопасности химически опасных производственных объектов не регламентируется',
          'Не более 2 независимых датчиков с раздельными точками отбора',
          'Не менее 5 независимых датчиков с раздельными точками отбора',
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63642082',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к баллонам с криптоноксеноновой смесью, криптоном и ксеноном после их наполнения, необходимо выполнить?',
        answers: [
          'Произвести взвешивание баллонов после их наполнения в течение суток',
          'В суточный срок произвести первичный отбор проб газа для анализа после наполнения баллонов',
          'В течение суток после наполнения баллонов осуществить их транспортировку к месту применения на тележках или другом транспорте',
          'Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или в складе баллонов',
        ],
        correctAnswers: [
          'Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или в складе баллонов',
        ],
      },
      {
        code: '63642083',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63642084',
        text:
          'В каком положении должны быть опломбированы запорные клапаны на аммиачных газовых нагнетательных трубопроводах?',
        answers: ['В открытом', 'В закрытом', 'Не имеет значения'],
        correctAnswers: ['В открытом'],
      },
      {
        code: '63642085',
        text:
          'На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?',
        answers: [
          'На две категории',
          'На три категории',
          'На четыре категории',
          'На пять категорий',
        ],
        correctAnswers: ['На три категории'],
      },
      {
        code: '63642086',
        text:
          'Что необходимо сделать перед включением электропечи после ремонта, выполненного с ее разгерметизацией?',
        answers: [
          'Продуть все аппараты и газоходы инертным газом до содержания азота не более 2%',
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%',
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода более 10%',
          'Очистить все аппараты и газоходы и провести осмотр',
        ],
        correctAnswers: [
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%',
        ],
      },
      {
        code: '63642087',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты',
          'Средствами автоматического управления объектом по действующим программам',
          'Выполняется обслуживающим персоналом по инструкции',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63642088',
        text:
          'Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
          'Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией',
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63642089',
        text:
          'В каких местах не допускается размещать фланцевые соединения объектов производств растительных масел?',
        answers: [
          'В местах установки арматуры',
          'В местах подсоединения трубопроводов к аппаратам',
          'На участках, на которых по условиям технологии требуется периодическая разборка для проведения чистки и ремонта трубопроводов',
          'Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности',
        ],
        correctAnswers: [
          'Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности',
        ],
      },
      {
        code: '63642090',
        text:
          'В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?',
        answers: [
          'Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам',
          'Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам',
          'В любом из указанных случаев',
        ],
        correctAnswers: ['В любом из указанных случаев'],
      },
      {
        code: '63642091',
        text:
          'Какой клапан должен устанавливаться на трубопроводах, подводящих острый водяной пар в аппараты для отгонки растворителя, пропаривания, барботирования?',
        answers: [
          'Редукционный клапан',
          'Предохранительный клапан',
          'Обратный клапан',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63642092',
        text:
          'Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах безопасности химически опасных производственных объектов?',
        answers: [
          'Определяется специалистами неразрушающего контроля',
          'Не менее 50% длины сварного шва каждого сварного соединения',
          'Не менее 75% длины сварного шва каждого сварного соединения',
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
        correctAnswers: [
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
      },
      {
        code: '63642093',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63642094',
        text:
          'При какой периодичности использования трубопроводной арматуры ручной привод следует располагать на высоте не более 1,6 м от уровня пола или площадки, с которой ведется управление?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
          'Расположение привода не зависит от частоты использования арматуры',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63642095',
        text:
          'Какую объемную долю кислорода в воздухе в местах возможного нахождения обслуживающего персонала и забора воздуха для вентиляции и технологических нужд должны обеспечить конструкция и размещение устройств сброса в атмосферу азота и кислорода?',
        answers: [
          'Не менее 17% и не более 22%',
          'Не менее 19%',
          'Не более 23%',
          'В пределах от 19% до 23%',
        ],
        correctAnswers: ['В пределах от 19% до 23%'],
      },
      {
        code: '63642096',
        text:
          'Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?',
        answers: ['На 50%', 'На 70%', 'На 80%', 'На 90%'],
        correctAnswers: ['На 80%'],
      },
      {
        code: '63642097',
        text:
          'Какие помещения объектов производств растительных масел относятся к неотапливаемым помещениям?',
        answers: [
          'Только здания складов',
          'Только здания элеваторов масличного сырья и шрота',
          'Только надсилосные и подсилосные этажи',
          'Все перечисленные помещения',
        ],
        correctAnswers: ['Все перечисленные помещения'],
      },
      {
        code: '63642098',
        text: 'Какие ремонтные работы выполняются по наряду-допуску?',
        answers: [
          'Только ремонтные работы на оборудовании, отключенном от работающей воздухоразделительной установки',
          'Только ремонтные работы на участке трубопровода, отключенном от работающей воздухоразделительной установки',
          'Только ремонтные работы на низкотемпературных технических устройствах без их полного отогрева',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63642099',
        text:
          'Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?',
        answers: [
          'Только под эстакадами технологических трубопроводов',
          'Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
        correctAnswers: [
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
      },
      {
        code: '63642100',
        text:
          'Что необходимо делать для обеспечения взрывобезопасной эксплуатации воздухоразделительных установок организации, эксплуатирующей объекты производства продуктов разделения воздуха?',
        answers: [
          'Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента',
          'Проводить периодическое обследование состояния установок в соответствии с требованиями технологического регламента',
          'Допускать к работе на установках обученный и аттестованный персонал',
          'Контролировать герметичность установок',
        ],
        correctAnswers: [
          'Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента',
        ],
      },
      {
        code: '63642101',
        text:
          'С какой периодичностью проводится ревизия и ремонт предохранительных клапанов резервуаров жидкого аммиака со снятием их с мест установки, проверкой и настройкой на стенде?',
        answers: [
          'Ежегодно',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 6 месяцев',
          'В сроки, установленные технологическим регламентом и эксплуатационной документацией',
        ],
        correctAnswers: [
          'В сроки, установленные технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63642102',
        text:
          'Что из перечисленного не допускается при эксплуатации систем холодоснабжения? Выберите два правильных варианта ответа.',
        answers: [
          'Эксплуатация винтовых компрессоров с впрыском жидкого аммиака, если это предусмотрено инструкцией организации-изготовителя',
          'Установка вспрыскивающих устройств, если это предусмотрено инструкцией организации-изготовителя',
          'Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора',
          'Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки',
        ],
        correctAnswers: [
          'Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора',
          'Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки',
        ],
      },
      {
        code: '63642103',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'Только П-образные компенсаторы',
          'Только Г-образные компенсаторы',
          'Только Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63642104',
        text:
          'Каким должен быть процент первичного заполнения жидким аммиаком внутреннего объема воздухоохладителей с верхней подачей аммиака?',
        answers: ['15%', '30%', '50%', '80%'],
        correctAnswers: ['50%'],
      },
      {
        code: '63642105',
        text:
          'Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?',
        answers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
          'Справочной литературой',
          'Данными, запрашиваемыми у научно-исследовательской организации',
          'Научно-технической литературой',
        ],
        correctAnswers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
        ],
      },
      {
        code: '63642106',
        text:
          'В каком случае допускаются отклонения от действующего проекта, на основании которого проводятся работы, связанные с восстановлением работоспособного (исправного) состояния технологических трубопроводов?',
        answers: [
          'По решению комиссии эксплуатирующей организации',
          'При наличии письменного распоряжения технического руководителя эксплуатирующей организации',
          'В случае согласования необходимого отклонения с территориальным органом Ростехнадзора',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: ['Не допускаются ни в каком случае'],
      },
      {
        code: '63642107',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Порошкообразные теплоизоляционные материалы',
          'Допускаются все перечисленные материалы',
          'Базальтовое супертонкое волокно',
          'Вату из непрерывного стеклянного волокна',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63642108',
        text:
          'На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'На основании задания на проектирование',
          'На основании расчетных данных на проектирование',
          'На основании справочных данных на проектирование',
          'На основании литературных данных на проектирование',
        ],
        correctAnswers: ['На основании задания на проектирование'],
      },
      {
        code: '63642109',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63642110',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 24 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63642111',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'В соответствии с проектной документацией',
          'В соответствии с рабочей документацией',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63642112',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'На единицу времени (час)',
          'На единицу выпускаемой продукции',
          'На один производственный поток или на мощность производства в целом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63642113',
        text:
          'Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?',
        answers: [
          'Композитные материалы',
          'Полимерные материалы',
          'Нержавеющая сталь',
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
        ],
        correctAnswers: [
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
        ],
      },
      {
        code: '63642114',
        text:
          'На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются аммиачные трубопроводы аммиачных холодильных установок?',
        answers: [
          'Только на массу хладагента, принятую с коэффициентом запаса 2,5',
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2',
          'Только на массу тепловой изоляции, принятую с коэффициентом запаса 1,7',
          'Только на массу трубопровода, принятую с коэффициентом запаса 1,1',
        ],
        correctAnswers: [
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2',
        ],
      },
      {
        code: '63642115',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В руководствах по безопасности',
          'В проектной документации',
          'В техническом регламенте',
          'В технологическом регламенте',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63642116',
        text:
          'В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и максимальному снижению тяжести последствий?',
        answers: [
          'В технологическом регламенте',
          'В техническом регламенте',
          'Только в Положении о производственном контроле',
          'В Плане мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'В Плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63642117',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Периодический контроль за состоянием воздушной среды',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Предотвращение взрывов внутри технологического оборудования',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63642118',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'Только в случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Только для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Только для обеспечения энергоэффективности',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63642119',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система парового отопления',
          'Система водяного отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63642120',
        text:
          'Чему соответствует вместимость поддонов, которыми оснащается емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?',
        answers: [
          '50% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей',
          '80% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей',
          'Объему всего расположенного в них оборудования',
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
        ],
        correctAnswers: [
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
        ],
      },
      {
        code: '63642121',
        text:
          'В каком количестве допускается хранение в цехе суховальцованных паст для подколеровки эмалей?',
        answers: [
          'Не более 2% сменной потребности',
          'Не более 3% сменной потребности',
          'Не более 4% сменной потребности',
          'Не более 5% сменной потребности',
        ],
        correctAnswers: ['Не более 2% сменной потребности'],
      },
      {
        code: '63642122',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, при производстве ремонта турбодетандеров или при замене фильтрующих элементов детандерных фильтров, указаны неверно?',
        answers: [
          'Должно быть отключено электропитание',
          'На азотных турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера. Перед ремонтом эти турбодетандеры продуваются воздухом',
          'Должна быть закрыта арматура на входе воздуха в турбодетандер и на выходе из него',
          'На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера',
        ],
        correctAnswers: [
          'На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера',
        ],
      },
      {
        code: '63642123',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63642124',
        text:
          'Какое из перечисленных требований к прокладке и устройству технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
          'Технологические трубопроводы в производственных помещениях должны прокладываться закрыто',
          'Сварные и разъемные соединения трубопроводов внутри футляров или гильз допускаются в соответствии с проектом',
        ],
        correctAnswers: [
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
        ],
      },
      {
        code: '63642125',
        text:
          'Что необходимо учитывать при размещении технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках?',
        answers: [
          'Минимизацию контакта с коррозионно-активными веществами',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Возможность быстрого демонтажа оборудования',
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
        ],
      },
      {
        code: '63642126',
        text:
          'На сколько должны быть заглублены полуподземные резервуары и хранилища фосфора?',
        answers: [
          'На уровень, обеспечивающий вместимость не менее 30% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
        ],
        correctAnswers: [
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
        ],
      },
      {
        code: '63642127',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63642128',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Нарушение энергообеспечения',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63642129',
        text:
          'Какое напряжение применяется для питания переносных светильников, работающих от аккумуляторных батарей, выполненных во взрывозащищенном исполнении, для внутреннего освещения аппаратов (резервуаров), заполненных растворителем, мисцеллой или парами растворителя?',
        answers: [
          'Не более 12 В',
          'Не более 24 В',
          'Не более 36 В',
          'Не более 42 В',
        ],
        correctAnswers: ['Не более 12 В'],
      },
      {
        code: '63642130',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 50 кг',
          'Массой более 30 кг',
          'Массой более 40 кг',
          'Массой более 35 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63642131',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На сети водоснабжения и канализации',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63642132',
        text: 'В каком случае допускается запускать аммиачный насос?',
        answers: [
          'При неполном заполнении насоса жидким хладагентом',
          'При закрытых клапанах на его входе и выходе',
          'При отсутствии защитного кожуха муфты',
          'Во всех перечисленных случаях',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63642133',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63642134',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63642135',
        text:
          'С какой периодичностью проводится ревизия и ремонт предохранительных клапанов резервуаров жидкого аммиака?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Ежегодно',
          'Не реже одного раза в три года',
          'В сроки, установленные технологическим регламентом и эксплуатационной документацией',
        ],
        correctAnswers: [
          'В сроки, установленные технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63642136',
        text:
          'Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Разработчиком процесса',
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63642137',
        text:
          'При какой скорости падения давления результаты дополнительного пневматического испытания на герметичность признаются удовлетворительными для технологических трубопроводов внутренним диаметром до 250 мм включительно со средами, относящимися к опасным веществам 1-го и 2-го классов опасности?',
        answers: [
          'Не более 0,1% за 1 час',
          'Не более 0,2% за 1 час',
          'Не более 0,8% за 1 час',
          'Не более 0,4% за 1 час',
        ],
        correctAnswers: ['Не более 0,1% за 1 час'],
      },
      {
        code: '63642138',
        text:
          'Какие из перечисленных требований к сосудам газификаторов и других стационарных сосудов с жидкими ПРВ, установленных снаружи зданий потребителей, и в которых производится непосредственный слив жидких ПРВ из транспортных цистерн, указаны верно?',
        answers: [
          'Сосуды следует располагать около стен, не имеющих проемов на расстоянии не менее 2,0 м от габаритов сосуда',
          'Оконные проемы на расстоянии 3,0 м вверх от габаритов сосудов могут иметь открывающиеся элементы',
          'Оконные проемы на расстоянии 7,0 м в каждую сторону от габаритов сосудов не должны иметь открывающихся элементов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63642139',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'Если выбросы рассчитываются, в количествах определяемых условиями безопасной остановки технологического процесса',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'В случае использования специальных систем аварийного освобождения',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63642140',
        text:
          'Каким образом разрешается размещать штуцера на резервуарах для хранения жидкого аммиака?',
        answers: [
          'Штуцера для выдачи жидкого аммиака разрешается размещать в нижней части резервуаров, штуцера для выдачи жидкого дренажа - в верхней части резервуаров',
          'Штуцера для дренажа разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
          'Штуцера для контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
          'Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
        ],
        correctAnswers: [
          'Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
        ],
      },
      {
        code: '63642141',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Все перечисленные требования указаны верно',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63642142',
        text:
          'Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?',
        answers: [
          'Технологическим регламентом, технологическими инструкциями',
          'Правилами безопасности химически опасных производственных объектов',
          'Сторонней специализированной организацией',
          'Техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технологическим регламентом, технологическими инструкциями',
        ],
      },
      {
        code: '63642143',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
          'По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63642144',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Обратный клапан',
          'Запорный клапан',
          'Гидроклапан',
          'Регулирующий клапан',
          'Предохранительный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63642145',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'Показатели взрыво-пожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, меры взрывобезопасности, показатели пожароопасности и токсичности',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63642146',
        text:
          'С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом диаметра и протяженности трубопровода',
          'С учетом толщины стенки трубопровода',
          'С учетом характеристики транспортируемой среды',
          'С учетом марки стали трубопровода',
        ],
        correctAnswers: [
          'С учетом диаметра и протяженности трубопровода',
          'С учетом характеристики транспортируемой среды',
        ],
      },
      {
        code: '63642147',
        text:
          'В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?',
        answers: [
          'Допускается для аппаратов вертикального типа',
          'Допускается для аппаратов горизонтального типа',
          'Допускается в обоснованных в проектной документации случаях',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в обоснованных в проектной документации случаях',
        ],
      },
      {
        code: '63642148',
        text:
          'Куда производится сброс первичного криптонового концентрата и криптоноксеноновой смеси?',
        answers: [
          'Только в газгольдер первичного криптонового концентрата',
          'В специальный резервуар транспортной системы',
          'В хранилище жидкого первичного концентрата',
          'В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)',
        ],
        correctAnswers: [
          'В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)',
        ],
      },
      {
        code: '63642149',
        text:
          'Какие требования установлены к насосам для перекачки растворов коллоксилина?',
        answers: [
          'Насосы должны быть быстроходными и соответствовать требованиям токсической безопасности',
          'Для насосов, расположенных в зданиях, необходимо предусматривать их дистанционное отключение с внешней стороны помещения насосной',
          'Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности',
        ],
        correctAnswers: [
          'Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности',
        ],
      },
      {
        code: '63642150',
        text:
          'Какое из перечисленных требований к нориям производств растительных масел указано неверно?',
        answers: [
          'Нории должны быть оборудованы электроблокировкой, обеспечивающей автоматическое отключение электродвигателя при перегрузках',
          'Нории должны быть оснащены устройством, предотвращающим обратный ход ленты, цепи',
          'Ковши норий для шрота должны выполняться из нержавеющей стали',
        ],
        correctAnswers: [
          'Ковши норий для шрота должны выполняться из нержавеющей стали',
        ],
      },
      {
        code: '63642151',
        text:
          'Каким документом определяются выбор способа подачи флегматизатора, его количества для обеспечения эффективности продувки и исключения возможности образования застойных зон на объектах, связанных с производством растительных масел?',
        answers: [
          'Техническим регламентом',
          'Технологическим регламентом',
          'Инструкцией по безопасному производству работ',
          'Проектной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63642152',
        text:
          'Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?',
        answers: [
          'Постоянные технологические регламенты',
          'Временные технологические регламенты',
          'Лабораторные технологические регламенты',
          'Разовые (опытные) технологические регламенты',
        ],
        correctAnswers: ['Разовые (опытные) технологические регламенты'],
      },
      {
        code: '63642153',
        text:
          'Что необходимо проводить в целях обеспечения эффективной очистки технологических потоков?',
        answers: [
          'Периодическую замену адсорбента в соответствии с технологическим регламентом',
          'Периодическое просеивание адсорбента в соответствии с технологическим регламентом',
          'Периодическую досыпку адсорбента в соответствии с технологическим регламентом',
          'Периодическую регенерацию адсорбента в соответствии с технологическим регламентом',
        ],
        correctAnswers: [
          'Периодическую регенерацию адсорбента в соответствии с технологическим регламентом',
        ],
      },
      {
        code: '63642154',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63642155',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63642156',
        text:
          'Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите два правильных варианта ответа.',
        answers: [
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Разработка методов отвода тепла',
          'Разработка методов приостановки реакции путем увеличения подачи одного из смешиваемых веществ',
          'Эффективное разделение этих продуктов и возможность образования застойных зон',
        ],
        correctAnswers: [
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Разработка методов отвода тепла',
        ],
      },
      {
        code: '63642157',
        text:
          'Какое количество тормозных башмаков для закрепления различных типов цистерн должно быть установлено для закрепления железнодорожных цистерн при операциях слива, налива растворителя и масла?',
        answers: [
          'Не менее 6 на каждой цистерне',
          'Не менее 4 на каждой цистерне',
          'От 4 до 8 в зависимости от типа цистерны',
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
        ],
        correctAnswers: [
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
        ],
      },
      {
        code: '63642158',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы в кДж',
          'Радиусом зон разрушения в м',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Категорией взрывоопасности технологических блоков',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63642159',
        text:
          'Какие требования предъявляются к работе адсорбера воздухораспределительной установки?',
        answers: [
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технологической инструкцией',
          'Адсорберы должны заполняться любым адсорбентом, имеющимся в наличии',
          'Адсорберы должны заполняться только адсорбентом, прошедшим периодическую регенерацию в соответствии с технологической инструкцией',
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки',
        ],
        correctAnswers: [
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки',
        ],
      },
      {
        code: '63642160',
        text:
          'Что происходит с выбросами аммиака при продувках оборудования и трубопроводов, снижении в них давления, сливе (наливе) цистерн, а также со сбросами от предохранительных клапанов?',
        answers: [
          'Выбросы и сбросы утилизируются или направляются в факельную систему',
          'Выбросы и сбросы поступают в резервный резервуар',
          'Выбросы и сбросы поступают обратно в резервуар',
        ],
        correctAnswers: [
          'Выбросы и сбросы утилизируются или направляются в факельную систему',
        ],
      },
      {
        code: '63642161',
        text:
          'В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?',
        answers: [
          'Если геометрический объем сосуда не превышает 0,3 м³',
          'Если это определено в проектной документации',
          'Не допускается ни в каком случае',
          'Если клапан рассчитан на полную пропускную способность',
        ],
        correctAnswers: [
          'Если геометрический объем сосуда не превышает 0,3 м³',
        ],
      },
      {
        code: '63642162',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63642163',
        text:
          'Какую кислородную арматуру допускается размещать в помещениях щитов управления (щитовая)?',
        answers: [
          'Кислородную арматуру с давлением 0,6 МПа и выше',
          'Кислородную арматуру с давлением 1,6 МПа и выше',
          'Кислородную арматуру с давлением 2,6 МПа и выше',
          'Не допускается размещать кислородную арматуру (независимо от давления)',
        ],
        correctAnswers: [
          'Не допускается размещать кислородную арматуру (независимо от давления)',
        ],
      },
      {
        code: '63642164',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63642165',
        text:
          'Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?',
        answers: [
          'Соответствовать диаметру трубы',
          'Не менее двух диаметров трубы',
          'Не менее трех диаметров трубы',
          'Не менее четырех диаметров трубы',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63642166',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63642167',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
        ],
        correctAnswers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
        ],
      },
      {
        code: '63642168',
        text:
          'В каких системах кислородоснабжения операции по снижению и поддержанию давления кислорода, регулированию его расхода следует производить в кислородно-распределительных (регуляторных) пунктах?',
        answers: [
          'С расходами более 2000 м³/ч и давлением свыше 0,6 МПа',
          'С расходами свыше 4000 м³/ч и давлением от 0,4 до 2,0 МПа',
          'С расходами свыше 5000 м³/ч и давлением от 0,2 до 4,0 МПа',
          'С расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа',
        ],
        correctAnswers: [
          'С расходами свыше 6000 м³/ч и давлением от 0,6 до 4,0 МПа',
        ],
      },
      {
        code: '63642169',
        text:
          'Какие требования безопасности, установленные Правилами безопасности химически опасных производственных объектов, к проведению ремонта в отсеках клапанных коробок (обратноповоротных клапанов) регенераторов блока разделения воздуха, указаны неверно?',
        answers: [
          'Вход в отсеки для ремонта клапанов разрешается только после снятия давления из аппаратов блока',
          'Вход в отсеки для ремонта клапанов разрешается только после местного отогрева отсеков клапанных коробок (обратноповоротных клапанов)',
          'Объемная доля кислорода внутри кожуха блока разделения воздуха должна быть 19% - 23%',
          'Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску',
        ],
        correctAnswers: [
          'Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску',
        ],
      },
      {
        code: '63642170',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от датчиков повышения температуры',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63642171',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63642172',
        text:
          'Какими автоматизированными устройствами необходимо оснащать воздухораспределительные установки, вырабатывающие газообразный азот?',
        answers: [
          'Автоматизированными устройствами, допускающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией) не более чем на 10%',
          'Устройствами, не допускающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией) более чем на 10%',
          'Устройствами, которые должны обеспечивать световую и звуковую сигнализации при подаче продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)',
          'Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)',
        ],
        correctAnswers: [
          'Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)',
        ],
      },
      {
        code: '63642173',
        text:
          'Какие требования, предъявляемые Правилами безопасности химически опасных производственных объектов, к транспортным сосудам для жидких продуктов разделения воздуха при нахождении их в гараже, указаны неверно?',
        answers: [
          'Транспортные сосуды в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам',
          'Сбросные трубопроводы, подключенные к транспортным сосудам, должны выводиться на 2,0 м выше конька крыши гаража',
          'Для каждого сосуда предусматривается отдельный сброс',
          'Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража',
        ],
        correctAnswers: [
          'Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража',
        ],
      },
      {
        code: '63642174',
        text:
          'Какое условие при механической очистке труб конденсатора от водяного камня является неверным?',
        answers: [
          'Работа должна выполняться с оформлением наряда-допуска',
          'Работа должна выполняться под руководством технического руководителя организации',
          'Работа должна выполняться только после освобождения конденсатора от аммиака',
        ],
        correctAnswers: [
          'Работа должна выполняться под руководством технического руководителя организации',
        ],
      },
      {
        code: '63642175',
        text:
          'Какие действия, осуществление которых необходимо при остановке чанного испарителя (тостера) объектов производств растительных масел, указаны неверно?',
        answers: [
          'Отключается подача глухого пара',
          'Перед открытием люков тостера необходимо подать острый пар во все чаны',
          'При разгрузке тостера необходимо производить тщательную зачистку чанов от остатков шрота',
          'Проверяется целостность контура заземления',
        ],
        correctAnswers: ['Проверяется целостность контура заземления'],
      },
      {
        code: '63642176',
        text:
          'Какая устанавливается минимальная высота гидрозатвора в приемном баке при гидравлическом способе удаления пыли из электрофильтров при производстве фосфора и его соединений?',
        answers: [
          '100 мм с учетом конуса, образующегося при работе мешалки',
          '200 мм с учетом конуса, образующегося при работе мешалки',
          '300 мм с учетом конуса, образующегося при работе мешалки',
        ],
        correctAnswers: [
          '200 мм с учетом конуса, образующегося при работе мешалки',
        ],
      },
      {
        code: '63642177',
        text:
          'Какое из перечисленных требований к оборудованию и коммуникациям продуктов разделения воздуха указано неверно?',
        answers: [
          'Все сосуды, заполняемые жидкими криогенными продуктами разделения воздуха, необходимо оснащать указателями уровня заполнения',
          'Холодные участки низкотемпературного оборудования и коммуникаций, находящиеся в зоне обслуживания, подлежат изоляции или ограждению',
          'Хранение, газификацию и транспортирование жидких (криогенных) ПРВ необходимо производить только в технических устройствах, специально предназначенных для данного продукта',
          'На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп',
        ],
        correctAnswers: [
          'На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп',
        ],
      },
      {
        code: '63642178',
        text:
          'Какие требования к сетям водопроводов и канализации и устройствам, подключаемым к сетям, не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Устройства (фонтанчики, краны, раковины или ванны самопомощи, аварийные души) должны располагаться в легкодоступных местах и подключаться к хозяйственно-питьевому водопроводу',
          'В производственных помещениях, где возможно повышенное содержание кислорода, которое может привести к воспламенению одежды или наличие опасных веществ, которые могут вызвать химические ожоги, должны устанавливаться фонтанчики, краны, раковины или ванны самопомощи, аварийные души',
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
          'Осмотр и очистка канализационных сетей и колодцев производится по графикам и в соответствии с порядком проведения газоопасных работ',
          'Соединение сетей хозяйственно-питьевого водопровода с сетями водопроводов, подающих воду технического качества, не допускается',
        ],
        correctAnswers: [
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
        ],
      },
      {
        code: '63642179',
        text:
          'Какая величина избыточного давления должна постоянно быть в системе электровозгонки фосфора?',
        answers: [
          'Не более 3 мм водяного столба',
          'Не более 5 мм водяного столба',
          'Не менее 5 мм водяного столба',
          'Не менее 3 мм водяного столба',
        ],
        correctAnswers: ['Не менее 3 мм водяного столба'],
      },
      {
        code: '63642180',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63642181',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие токсичные, высокотоксичные вещества, органические теплоносители, воспламеняющиеся и горючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 6 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63642182',
        text:
          'Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?',
        answers: [
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 120 секунд',
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд',
        ],
        correctAnswers: [
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
        ],
      },
      {
        code: '63642183',
        text:
          'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальника центральной лаборатории организации',
          'Заместителя руководителя организации по охране окружающей среды',
          'Главного метролога организации',
          'Начальника цеха',
          'Начальника производства',
        ],
        correctAnswers: ['Начальника цеха', 'Начальника производства'],
      },
      {
        code: '63642184',
        text:
          'Какой должна быть свободная высота эстакад для технологических трубопроводов над проездами и проходами для пешеходных дорог?',
        answers: [
          'Не менее 2,2 м',
          'Не менее 2,5 м',
          'Не менее 2,7 м',
          'Не менее 2,0 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63642185',
        text:
          'Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?',
        answers: [
          'Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций',
          'Только регистрацию всех случаев загазованности приборами',
          'Только включение светового и звукового сигналов при превышении предельно допустимых концентраций',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63642186',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случае износа боковых граней головок болтов и гаек',
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случаях изгиба болтов и шпилек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63642187',
        text:
          'С какой периодичностью необходимо проверять отходящую из конденсатора воду на присутствие аммиака?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63642188',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к технологическому процессу очистки сырого аргона, указаны неверно?',
        answers: [
          'Установку очистки сырого аргона от кислорода необходимо оснащать блокировкой, прекращающей подачу водорода при повышении температуры в реакторе выше допустимой, а также при содержании кислорода в аргоне, поступающем на очистку, более нормативных величин',
          'В технологическом процессе должна быть предусмотрена система автоматического измерения содержания кислорода в сыром аргоне, поступающем в реактор',
          'Во время пуска установки допускается повышение объемной доли кислорода в аргоне не более 8% на входе в узел смешения реактора. При этом расход подаваемого водорода не должен превышать 2,5% от расхода поступающего на дистанционное управление. Местное управление допускается при наличии аргона',
          'Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8%',
        ],
        correctAnswers: [
          'Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8%',
        ],
      },
      {
        code: '63642189',
        text:
          'Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?',
        answers: [
          'Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания',
          'Только в картере',
          'Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63642190',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами измерения перепада давления на сепараторе',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63642191',
        text:
          'Какое из перечисленных требований к манометрам, контролирующим давление при испытании на прочность сосудов (аппаратов), трубопроводов указано неверно?',
        answers: [
          'Давление при испытании должно контролироваться двумя манометрами, опломбированными и прошедшими поверку',
          'Один манометр должен устанавливаться у источника давления (воздушный компрессор, баллон с инертным газом), другой - на сосуде (аппарате) и трубопроводе в точке, наиболее удаленной от воздушного компрессора',
          'Манометры должны быть с диаметром корпуса не менее 160 мм и шкалой на номинальное давление, равное 4/3 от измеряемого давления',
          'Манометры должны быть одинакового класса точности, не ниже 2,5',
        ],
        correctAnswers: [
          'Манометры должны быть одинакового класса точности, не ниже 2,5',
        ],
      },
      {
        code: '63642192',
        text:
          'Какая концентрация взрывоопасных примесей допускается в жидком кислороде?',
        answers: [
          'Не превышающая предельно допустимой нормы',
          'Не превышающая нормы, установленной технологическим процессом',
          'Не превышающая 19% нормы, установленной разработчиком воздухоразделительной установки',
          'Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса',
        ],
        correctAnswers: [
          'Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса',
        ],
      },
      {
        code: '63642193',
        text:
          'Что должно исключать остаточное давление в кислородных баллонах при их разрядке?',
        answers: [
          'Возможность перетекания кислорода в системы, не связанные с осуществляемым технологическим процессом',
          'Возможность перетекания кислорода в системы коммуникаций, заполненные горючими газами',
          'Возможность перетекания кислорода в технические устройства, заполненные горючими газами',
          'Перетечку горючих газов из подключенной системы обратно в баллоны',
        ],
        correctAnswers: [
          'Перетечку горючих газов из подключенной системы обратно в баллоны',
        ],
      },
      {
        code: '63642194',
        text:
          'В каком случае допускается стационарное применение цельносварных гофрированных стальных труб, включая конструкции с теплоизоляционными и (или) защитными слоями?',
        answers: [
          'При обосновании в проекте',
          'По решению технической комиссии эксплуатирующей организации',
          'Не допускается ни в каком случае',
          'При согласовании с территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['При обосновании в проекте'],
      },
      {
        code: '63642195',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Только устройство световой сигнализации',
          'Только устройство звуковой сигнализации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63642196',
        text:
          'Какой свободный объем для демпфирования температурного расширения растворителя необходимо оставлять при заполнении резервуаров на объектах производств растительных масел?',
        answers: [
          'Не менее 10%',
          'Не более 10%',
          'Не менее 15%',
          'Устанавливается в зависимости от растворителя',
        ],
        correctAnswers: ['Не менее 10%'],
      },
      {
        code: '63642197',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Обеспечивать минимально возможное время освобождения',
          'Быть мобильными, иметь небольшие габариты и вес',
          'Переход из режима ожидания в рабочее состояние должен осуществляться в течение 30 секунд',
        ],
        correctAnswers: [
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Обеспечивать минимально возможное время освобождения',
        ],
      },
      {
        code: '63642198',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с технологическими регламентами на производство продукции',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63642199',
        text:
          'В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?',
        answers: [
          'Только в соответствии с требованиями технических регламентов',
          'Только в соответствии с требованиями градостроительной деятельности',
          'Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии со всеми перечисленными требованиями',
        ],
        correctAnswers: ['В соответствии со всеми перечисленными требованиями'],
      },
      {
        code: '63642200',
        text:
          'Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?',
        answers: ['90 °C', '135 °C', '160 °C', '185 °C'],
        correctAnswers: ['160 °C'],
      },
      {
        code: '63642201',
        text: 'В каком случае аппарат (сосуд) подлежит немедленной остановке?',
        answers: [
          'В случае неисправности указателя уровня жидкости',
          'В случае неисправности предохранительных клапанов',
          'В случае неисправности предусмотренных проектной документацией контрольно-измерительных приборов и средств автоматики',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63642202',
        text:
          'В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: ['15 минут', '30 минут', '1 часа', '2 часов'],
        correctAnswers: ['1 часа'],
      },
      {
        code: '63642203',
        text:
          'На каких технологических трубопроводах не допускается применять сальниковые компенсаторы?',
        answers: [
          'На трубопроводах со средами 1-ой группы',
          'Допускается применять на всех перечисленных трубопроводах',
          'На трубопроводах для трудногорючих веществ',
          'На трубопроводах для негорючих веществ',
        ],
        correctAnswers: ['На трубопроводах со средами 1-ой группы'],
      },
      {
        code: '63642204',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'Только в каналах (закрытых или с засыпкой песком)',
          'Только в грунте',
          'Только надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63642205',
        text:
          'Что должно контролироваться перед заполнением адсорбера адсорбентом?',
        answers: [
          'Влажность и насыпная масса адсорбента',
          'Концентрация и состав взрывоопасных примесей в перерабатываемом воздухе',
          'Температура и влажность адсорбента',
          'Цвет адсорбента и концентрация двуокиси углерода в нем',
        ],
        correctAnswers: ['Влажность и насыпная масса адсорбента'],
      },
      {
        code: '63642206',
        text:
          'Какой величины должно приниматься расчетное давление при проектировании резервуаров для хранения жидкого аммиака?',
        answers: [
          'Больше рабочего на 10%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
          'Больше рабочего на 15%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
          'Больше рабочего на 20%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
          'Больше рабочего на 25%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
        ],
        correctAnswers: [
          'Больше рабочего на 25%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
        ],
      },
      {
        code: '63642207',
        text:
          'В каком случае замена адсорбента должна производиться немедленно?',
        answers: [
          'Если необходимо произвести пересеивание адсорбента',
          'Если адсорбент загрязнен (замаслен)',
          'В случаях, определенных документацией разработчика оборудования',
          'Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм',
        ],
        correctAnswers: [
          'Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм',
        ],
      },
      {
        code: '63642208',
        text:
          'При каком виде остановки аппараты и трубопроводы, содержащие растворитель или мисцеллу, должны продуваться азотом или водяным паром после опорожнения?',
        answers: [
          'При аварии',
          'При ремонте',
          'При профилактике',
          'При любых видах остановки',
        ],
        correctAnswers: ['При любых видах остановки'],
      },
      {
        code: '63642209',
        text:
          'Каким требованиям должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Полы в помещении должны быть холодными, с повышенной механической стойкостью',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63642210',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
          'Перила должны быть высотой не менее 1,3 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63642211',
        text:
          'С учетом какой документации должны осуществляться выбор систем контроля, управления и противоаварийной автоматической защиты, а также систем связи и оповещения об аварийных ситуациях объектов производств растительных масел?',
        answers: [
          'Правил безопасности химически опасных производственных объектов',
          'Действующей нормативно-технической документации',
          'Документации на ХОПО',
          'С учетом всей перечисленной документации',
        ],
        correctAnswers: ['С учетом всей перечисленной документации'],
      },
      {
        code: '63642212',
        text:
          'Как необходимо выполнять ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости?',
        answers: [
          'По наряду-допуску',
          'По устному разрешению руководителя смены',
          'Только в выходные дни по письменному разрешению руководителя организации',
          'Только по графикам в соответствии со сроками, установленными техническими условиями разработчика оборудования',
        ],
        correctAnswers: ['По наряду-допуску'],
      },
      {
        code: '63642213',
        text:
          'Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?',
        answers: [
          'Допускать к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Иметь компьютерные тренажеры, включающие приближенные к реальным динамические модели процессов и средства управления',
          'Иметь специализированные центры обучения и подготовки для производственного персонала',
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
        correctAnswers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
      },
      {
        code: '63642214',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
          'В соответствии с декларацией промышленной безопасности',
          'В соответствии с проектной документацией',
          'В соответствии с технологическими регламентами на производство продукции',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63642215',
        text:
          'В каком случае допускается применение резиновых и резинометаллических рукавов для слива (налива) цистерн жидкого аммиака?',
        answers: [
          'Не допускается ни в каком случае',
          'Если это регламентировано внутренней эксплуатационной документацией',
          'Если рукава с внутренним диаметром 50 мм',
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
        ],
        correctAnswers: [
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
        ],
      },
      {
        code: '63642216',
        text:
          'Какие контрольно-измерительные приборы, применяемые для измерения параметров кислорода и газовых смесей, подлежат защите от жировых загрязнений?',
        answers: [
          'С объемной долей кислорода 19%',
          'С объемной долей кислорода менее 19%',
          'С объемной долей кислорода 23%',
          'С объемной долей кислорода более 23%',
        ],
        correctAnswers: ['С объемной долей кислорода более 23%'],
      },
      {
        code: '63642217',
        text:
          'С какой периодичностью необходимо проверять исправность защитных реле уровня на аппаратах (сосудах)?',
        answers: [
          '1 раз в месяц',
          '1 раз в 3 месяца',
          '1 раз в 6 месяцев',
          '1 раз в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63642218',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Ростехнадзором',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Эксплуатирующими организациями',
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63642219',
        text:
          'Какое из перечисленных требований при производстве работ по ремонту технических устройств и трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'На весь период ремонта арматура на сбросе газа из блока в атмосферу должна быть закрыта',
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить',
          'До выполнения работ на обеих трехходовых заслонках, связанных с доступом в подводящие к ним трубопроводы, устанавливается заглушка (по ходу газа) после обеих трехходовых заслонок',
        ],
        correctAnswers: [
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить',
        ],
      },
      {
        code: '63642220',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63642221',
        text:
          'Какая должна быть минимальная глубина заложения подземных технологических трубопроводов от поверхности земли до верхней части трубы или теплоизоляции в тех местах, где не предусмотрено движение транспорта?',
        answers: ['0,6 м', '0,7 м', '0,8 м', '0,9 м'],
        correctAnswers: ['0,6 м'],
      },
      {
        code: '63642222',
        text:
          'В каком случае разрешается установка запорного устройства на вытяжной трубе объектов производств растительных масел?',
        answers: [
          'Если вытяжная труба оборудована пароэжектором или вентилятором',
          'Если запорное устройство установлено на вытяжной трубе дефлегматора',
          'Если запорное устройство установлено на вытяжной трубе конденсатора',
        ],
        correctAnswers: [
          'Если вытяжная труба оборудована пароэжектором или вентилятором',
        ],
      },
      {
        code: '63642223',
        text:
          'Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?',
        answers: [
          'Стальная арматура и фасонные части, а также допускается применение чугунной запорно-регулирующей арматуры',
          'Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди, цинка и их сплавов',
          'Стальная арматура и фасонные части, а также допускается применение запорной арматуры из ковкого и высокопрочного чугуна',
          'Стальная арматура и фасонные части',
        ],
        correctAnswers: ['Стальная арматура и фасонные части'],
      },
      {
        code: '63642224',
        text:
          'Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?',
        answers: [
          'Дистиллят, полученный из воды питьевого качества',
          'Умягченную воду, полученную из заводской системы пароснабжения',
          'Конденсат, полученный из заводской системы пароснабжения',
          'Масла, рекомендованные разработчиками компрессора',
        ],
        correctAnswers: ['Дистиллят, полученный из воды питьевого качества'],
      },
      {
        code: '63642225',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '12 месяцев',
          '3 месяца',
          '24 месяца',
          '36 месяцев',
          '6 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63642226',
        text:
          'Где размещают лаковыпускные отделения в производстве лаков на конденсационных смолах?',
        answers: [
          'Только в изолированном помещении корпуса синтеза',
          'Только в отдельно стоящем корпусе',
          'Только на открытых площадках',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63642227',
        text:
          'Какую минимальную температуру следует принимать для сливных, наливных и эвакуационных насосов жидкого аммиака?',
        answers: ['- 20 °C', '- 28 °C', '- 34 °C', '- 35 °C'],
        correctAnswers: ['- 34 °C'],
      },
      {
        code: '63642228',
        text:
          'Кем принимается решение о выводе объекта (блока, установки) из эксплуатации на длительный период и вводе этих объектов (блоков, установок) в эксплуатацию после длительных остановок?',
        answers: [
          'Эксплуатирующей организацией',
          'Эксплуатирующей организацией при согласовании с Ростехнадзором',
          'Проектной организацией',
          'Сторонней специализированной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63642229',
        text:
          'Какие изотермические резервуары дополнительно рассчитываются на сейсмические нагрузки?',
        answers: [
          'Все изотермические резервуары рассчитываются на сейсмические нагрузки',
          'Двустенные с двумя самонесущими крышами',
          'Двустенные с подвесной крышей внутреннего резервуара',
          'Одностенные',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63642230',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'После проведения расчета на прочность',
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63642231',
        text:
          'В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?',
        answers: [
          'В соответствии с требованиями Общих правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии с требованиями территориальных органов Ростехнадзора',
          'В соответствии с техническими регламентами',
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
        correctAnswers: [
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
      },
      {
        code: '63642232',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов в системах с подогревателями',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63642233',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений, должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 20% НКПР',
          'При 10% НКПР',
          'При 15% НКПР',
          'При 18% НКПР',
        ],
        correctAnswers: ['При 20% НКПР'],
      },
      {
        code: '63642234',
        text:
          'Какой должна быть максимальная температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений объектов производств растительных масел, при металлическом покрывном слое?',
        answers: ['45 °С', '50 °С', '55 °С', '60 °С'],
        correctAnswers: ['55 °С'],
      },
      {
        code: '63642235',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводится не реже двух раз в смену',
        ],
        correctAnswers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63642236',
        text:
          'Кем рассчитывается и подбирается гидрозатвор для каждого типа водоотделителя объектов производств растительных масел?',
        answers: [
          'Проектной организацией',
          'Научно-исследовательской организацией',
          'Эксплуатирующей организацией',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Проектной организацией'],
      },
      {
        code: '63642237',
        text:
          'Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?',
        answers: [
          'Орган по сертификации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Разработчик документации',
          'Организация-изготовитель',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63642238',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от вида взрывозащиты',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63642239',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
          'Над местами, предназначенными для прохода людей',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63642240',
        text:
          'С какой периодичностью необходимо проверять промежуточный хладоноситель в системах охлаждения на присутствие аммиака?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в полгода',
          'Не реже одного раза в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63642241',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Только испытание на прочность пробным давлением',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63642242',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Комиссией эксплуатирующей организации',
          'Разработчиком проекта',
          'Специализированной сторонней организацией',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63642243',
        text:
          'В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?',
        answers: [
          'Объемом до 1000 кг/год',
          'Объемом до 1500 кг/год',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Объемом до 1000 кг/год'],
      },
      {
        code: '63642244',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам',
          'Обслуживающим персоналом по инструкции',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63642245',
        text:
          'Из какого материала допускается изготовление наконечников резиновых шлангов, предназначенных для сливоналивных операций, на объектах производств растительных масел? Выберите два правильных варианта ответа.',
        answers: ['Из нержавеющей стали', 'Из бронзы', 'Из меди', 'Из титана'],
        correctAnswers: ['Из бронзы', 'Из меди'],
      },
      {
        code: '63642246',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63642247',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Расчет на прочность',
          'Регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63642248',
        text:
          'Что должна обеспечивать система контроля и автоматизации воздухораспределительной установки при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом?',
        answers: [
          'Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом',
          'Включение резервных (дополнительных) модернизированных устройств, обеспечивающих безопасность дальнейшей работы систем и устройств',
          'Звуковую сигнализацию при отклонении технологических параметров, определенных технологическим регламентом',
          'Автоматическое отключение устройств установки при отклонении технологических параметров, определенных технологическим регламентом',
        ],
        correctAnswers: [
          'Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом',
        ],
      },
      {
        code: '63642249',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Гидравлический расчет',
          'Теплотехнический расчет',
          'Статический и динамический прочностный расчет',
          'Расчет на прочность и устойчивость',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63642250',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, к адсорбенту в адсорбционных блоках осушки, указаны неверно?',
        answers: [
          'Если адсорбент загрязнен (замаслен или пожелтел), его необходимо заменить',
          'В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °C), замену адсорбента в блоке осушки производить два раза в год согласно технологическому регламенту',
          'Состояние адсорбента требуется проверять не реже одного раза в год',
          'В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °C), замену адсорбента в блоке осушки производить один раз в год согласно технологической инструкции',
        ],
        correctAnswers: [
          'В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °C), замену адсорбента в блоке осушки производить один раз в год согласно технологической инструкции',
        ],
      },
      {
        code: '63642251',
        text:
          'В каких условиях должна проводиться реакция синтеза пятисернистого фосфора?',
        answers: [
          'В охлаждаемых аппаратах, снабженных устройствами для отвода тепла',
          'В аппаратах, обогреваемых паром',
          'В герметичной таре',
          'В герметичных аппаратах в атмосфере инертного газа',
        ],
        correctAnswers: ['В герметичных аппаратах в атмосфере инертного газа'],
      },
      {
        code: '63642252',
        text:
          'Какие требования безопасности к баллонам для постоянных потребителей небольших количеств продуктов разделения воздуха (газоанализаторы, хроматографы) указаны неверно?',
        answers: [
          'Баллоны следует размещать в металлических шкафах и закреплять. Шкафы с баллонами должны иметь запорные устройства',
          'Расстояние между каждой парой баллонов должно быть не менее 12,0 м на каждом уровне размещения баллонов',
          'У каждого места потребления допускается размещать не более двух баллонов вместимостью 40 л, заполненных продуктами разделения воздуха под давлением до 20 МПа',
          'У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа',
        ],
        correctAnswers: [
          'У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа',
        ],
      },
      {
        code: '63642253',
        text:
          'Какие из указанных действий, которые должны быть предприняты при появлении металлического стука и скрежета в чанах жаровни, указаны неверно?',
        answers: [
          'Немедленно остановить жаровню',
          'Прекратить подачу пара',
          'Освободить жаровню от мезги',
          'Немедленно провести ревизию чанов',
        ],
        correctAnswers: ['Немедленно провести ревизию чанов'],
      },
      {
        code: '63642254',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией-разработчиком процесса',
          'Специализированной сторонней организацией',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63642255',
        text:
          'Какими автоматическими устройствами необходимо оснащать системы азотно-водяного охлаждения воздухораспределительных установок?',
        answers: [
          'Исключающими подачу продукционного азота',
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 5%',
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 10%',
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере',
        ],
        correctAnswers: [
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере',
        ],
      },
      {
        code: '63642256',
        text:
          'Как должен производиться отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Непосредственно сухим подогретым воздухом',
          'Непосредственно подогретой водой',
          'Открытым огнем',
          'За счет тепла, подводимого к отогреваемым аппаратам',
        ],
        correctAnswers: ['Непосредственно сухим подогретым воздухом'],
      },
      {
        code: '63642257',
        text:
          'С какими параметрами рабочего давления и вместимости вместе с реципиентами необходимо оснащать кислородопроводы автоматически действующей системой защиты, прекращающей поступление кислорода из реципиентов в трубопровод при нарушении его целостности?',
        answers: [
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 2000 м³',
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³',
          'С рабочим давлением 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³',
          'С рабочим давлением более 0,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³',
        ],
        correctAnswers: [
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³',
        ],
      },
      {
        code: '63642258',
        text:
          'Какие требования установлены к отделениям, в которых производят дробление пека, приготовление или разогрев электродной массы, и к оборудованию в этих отделениях?',
        answers: [
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть герметичным',
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть снабжено средствами местного отсоса',
          'Отделения должны быть изолированы от остальных рабочих помещений, к оборудованию специальных требований не установлено',
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
        ],
        correctAnswers: [
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
        ],
      },
      {
        code: '63642259',
        text:
          'В каких условиях должна проводиться реакция получения фосфида цинка?',
        answers: [
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор',
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воздуха в реактор',
          'Перед установкой реактора необходимо включить муфель',
          'Перед дозировкой фосфора реактор должен быть тщательно продут инертным газом в течение не более 3 минут',
        ],
        correctAnswers: [
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор',
        ],
      },
      {
        code: '63642260',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63642261',
        text:
          'Какое из перечисленных требований к обслуживанию и ремонту технологического оборудования и трубопроводов противоречит Правилам безопасности химически опасных производственных объектов?',
        answers: [
          'Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом',
          'Все аппараты экстракционного цеха должны отключаться от системы технологических трубопроводов с помощью стандартных пронумерованных заглушек с видимыми хвостовиками',
          'Теплообменные поверхности аппаратов (дистилляторов, конденсаторов, теплообменников) должны очищаться от нагара путем щелочения и промывки водой',
          'После завершения указанных операций с аппаратов снимаются крышки люков-лазов и проводятся операции проветривания',
        ],
        correctAnswers: [
          'Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом',
        ],
      },
      {
        code: '63642262',
        text:
          'С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?',
        answers: [
          'Не реже 1 раза в 10 дней',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63642263',
        text:
          'Что должно предшествовать пуску транспортных систем при транспортировке семян жмыхов и шротов?',
        answers: [
          'Включение звуковой и световой сигнализаций',
          'Отключение звуковой сигнализации при включенной световой',
          'Включение вентиляции',
        ],
        correctAnswers: ['Включение звуковой и световой сигнализаций'],
      },
      {
        code: '63642264',
        text:
          'Какое действие необходимо предпринять в случае перерыва слива аммиака?',
        answers: [
          'Съемные участки трубопроводов должны быть отсоединены от цистерн',
          'Цистерна остается присоединенной к системе под наблюдением работников, назначенных приказом по организации',
          'На время перерыва допускается оставлять цистерну присоединенной к системе',
          'По усмотрению лица, ответственного за производство работ по сливу аммиака',
        ],
        correctAnswers: [
          'Съемные участки трубопроводов должны быть отсоединены от цистерн',
        ],
      },
      {
        code: '63642265',
        text:
          'Какие действия должны быть предприняты при обнаружении нарушений требований Правил безопасности химически опасных производственных объектов в отношении цистерн с жидким аммиаком?',
        answers: [
          'Необходимо поставить круглосуточное наблюдение за цистернами до устранения нарушений',
          'Аммиак должен быть немедленно слит',
          'Немедленно сообщить в территориальный орган Ростехнадзора',
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
        ],
        correctAnswers: [
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
        ],
      },
      {
        code: '63642266',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'Помещения управления должны быть отдельно стоящими',
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать их к зданиям',
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63642267',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
        ],
        correctAnswers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
        ],
      },
      {
        code: '63642268',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Представитель организации-изготовителя систем противоаварийной автоматической защиты',
          'Технический руководитель организации',
          'Разработчик проекта',
          'Разработчик технологического процесса',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63642269',
        text:
          'Как должны располагаться внутрицеховые технологические трубопроводы с тяжелыми газами, прокладываемые по несгораемой поверхности несущих стен производственных зданий с оконными и дверными проемами?',
        answers: [
          'На 0,5 м ниже оконных и дверных проемов',
          'На 0,5 м выше оконных и дверных проемов',
          'На 1 м ниже оконных и дверных проемов',
          'На 1 м выше оконных и дверных проемов',
        ],
        correctAnswers: ['На 0,5 м ниже оконных и дверных проемов'],
      },
      {
        code: '63642270',
        text:
          'В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Выберите два правильных варианта ответа.',
        answers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
          'В случае размещения холодильного оборудования над площадками на расстоянии, определенном проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
      },
      {
        code: '63642271',
        text:
          'Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?',
        answers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,1 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,15 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,25 м',
        ],
        correctAnswers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
        ],
      },
      {
        code: '63642272',
        text:
          'В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?',
        answers: [
          'Для одноступенчатых компрессоров',
          'Для ступени высокого давления двухступенчатых компрессоров',
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
        correctAnswers: [
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
      },
      {
        code: '63642273',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Лицом, утверждающим технологический регламент',
          'Комиссией организации под председательством представителя проектной организции',
          'Технологическим руководителем организации',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63642274',
        text:
          'Каким образом должны выполняться участки перекрытий под оборудованием экстракционного цеха во избежание растекания (розлива) растворителя (мисцеллы)?',
        answers: [
          'Без проемов и ограждаться бортом высотой не менее 0,15 м',
          'С проемами в виде поддонов с бортиками не менее 0,1 м',
          'С проемами в виде поддонов с бортиками не менее 0,2 м',
          'Без проемов и ограждаться бортом высотой не менее 0,25 м',
        ],
        correctAnswers: [
          'Без проемов и ограждаться бортом высотой не менее 0,15 м',
        ],
      },
      {
        code: '63642275',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
        ],
        correctAnswers: [
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
        ],
      },
      {
        code: '63642276',
        text:
          'Какое из перечисленных требований к процессам транспортирования и хранения масличного сырья, жмыхов и шротов указано неверно?',
        answers: [
          'Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается',
          'Для вновь строящихся элеваторов для шрота должны предусматриваться устройства, обеспечивающие разрыхление слежавшегося шрота',
          'Температуру заложенных на хранение жмыха и шрота необходимо проверять по графику, утвержденному в эксплуатирующей организации, с помощью дистанционных стационарных термометров (термоподвесок)',
          'Пневмотранспорт и его элементы должны быть заземлены путем установки электропроводящих перемычек в местах фланцевых соединений элементов',
        ],
        correctAnswers: [
          'Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается',
        ],
      },
      {
        code: '63642277',
        text:
          'Как осуществляется охлаждение изотермического резервуара с аммиаком?',
        answers: [
          'Подачей охлаждающей жидкости в рубашку резервуара',
          'За счет возврата в резервуар под слой сжиженных на холодильной установке отводимых из резервуара паров аммиака',
          'Подачей охлаждающей жидкости в змеевик, расположенный в нижней части резервуара',
          'Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака',
        ],
        correctAnswers: [
          'Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака',
        ],
      },
      {
        code: '63642278',
        text:
          'Кем должен быть утвержден перечень помещений и мест, в которых содержание кислорода по объемной доле может быть менее 19% или более 23% (в аварийной ситуации)?',
        answers: [
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается только первым руководителем организации',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается региональным отделением Ростехнадзора',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается территориальным отделом Роспотребнадзора',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации',
        ],
        correctAnswers: [
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации',
        ],
      },
      {
        code: '63642279',
        text:
          'В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?',
        answers: [
          'В технологических регламентах',
          'В руководствах по безопасности',
          'В справочной литературе',
          'В эксплуатационной документации организации-изготовителя',
        ],
        correctAnswers: [
          'В эксплуатационной документации организации-изготовителя',
        ],
      },
      {
        code: '63642280',
        text:
          'Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?',
        answers: [
          'Резиновые гибкие шланги',
          'Пластмассовые гибкие шланги',
          'Съемные участки трубопроводов',
          'Гибкие металлические шланги',
        ],
        correctAnswers: ['Съемные участки трубопроводов'],
      },
      {
        code: '63642281',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63642282',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 8 лет',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63642283',
        text:
          'Какие требования предъявляются к испарению жидких криогенных продуктов разделения воздуха, сливаемых из отдельных аппаратов воздухораспределительных установок перед их отогревом?',
        answers: [
          'Испарение производится в специальных испарителях медленного слива, предусмотренных проектной документацией отдельно для каждой установки',
          'Испарение производится в испарителях, предусмотренных проектной документацией (документацией) разработчика установки',
          'Испарение производится в устройствах для слива и испарения жидких продуктов разделения воздуха, предусмотренных проектной документацией (документацией) на установки',
          'Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки',
        ],
        correctAnswers: [
          'Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки',
        ],
      },
      {
        code: '63642284',
        text:
          'Для каких реципиентов в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?',
        answers: [
          'Вместимостью более 10 м³ с рабочим давлением более 1,3 МПа',
          'Вместимостью более 20 м³ с рабочим давлением более 1,5 МПа',
          'Вместимостью 200 м³ с рабочим давлением 1,6 МПа',
          'Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа',
        ],
        correctAnswers: [
          'Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа',
        ],
      },
      {
        code: '63642285',
        text:
          'Какое из перечисленных требований должно соблюдаться при обезжиривании сосудов (емкостных аппаратов) способом протирки? Выберите два правильных варианта ответа.',
        answers: [
          'Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20°С',
          'Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, продувается инертным газом',
          'Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19% и не более 23%',
          'Технический руководитель организации должен осмотреть место работы и убедиться, что сосуд отогрет и подготовлен к проведению работ',
        ],
        correctAnswers: [
          'Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20°С',
          'Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19% и не более 23%',
        ],
      },
      {
        code: '63642286',
        text:
          'На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?',
        answers: [
          'На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия',
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди',
          'На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей',
        ],
        correctAnswers: [
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
        ],
      },
      {
        code: '63642287',
        text:
          'Каким минимальным количеством эвакуационных выходов должны оснащаться помещения машинного и аппаратного отделения?',
        answers: [
          'Одним',
          'Двумя',
          'Тремя',
          'Определяется проектной документацией',
        ],
        correctAnswers: ['Двумя'],
      },
      {
        code: '63642288',
        text:
          'Какие требования к ваннам для охлаждения деталей жидким азотом, установленные Правилами безопасности химически опасных производственных объектов, указаны неверно?',
        answers: [
          'Подлежат периодическому обезжириванию',
          'Над ваннами необходимо предусматривать местные отсосы',
          'Подлежат отогреву при объемной доле кислорода в азоте более 30%',
          'Подлежат отогреву при объемной доле кислорода в азоте более 23%',
        ],
        correctAnswers: [
          'Подлежат отогреву при объемной доле кислорода в азоте более 23%',
        ],
      },
      {
        code: '63642289',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
          'Только по I категории надежности',
          'По I или II категории надежности',
          'По II или III категории надежности',
        ],
        correctAnswers: ['По I или II категории надежности'],
      },
      {
        code: '63642290',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
          'Устройствами для подключения линий водорода',
          'Циклонами',
          'Виброситом',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63642291',
        text:
          'Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
          'Системами ручного (без применения вычислительной техники) регулирования',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63642292',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Отключение систем в случае переключений на резервный или аварийный источник электропитания не позднее 0,5 секунды',
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63642293',
        text:
          'На каком расстоянии от опор и подвесок следует располагать сварные стыки трубопроводов для труб диаметром менее 50 мм?',
        answers: [
          'Не менее 100 мм',
          'Не менее 150 мм',
          'Не менее 200 мм',
          'Не менее 250 мм',
        ],
        correctAnswers: ['Не менее 100 мм'],
      },
      {
        code: '63642294',
        text:
          'Какие требования к технологическим трубопроводам для растворителя, мисцеллы и паров растворителя указаны неверно?',
        answers: [
          'Технологические трубопроводы размещаются с учетом возможности проведения визуального контроля их состояния',
          'Технологические трубопроводы размещаются с учетом возможности выполнения работ по обслуживанию и ремонту',
          'На маховиках или рукоятках арматуры должны быть нанесены стрелки, указывающие направления их вращения',
          'Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом',
        ],
        correctAnswers: [
          'Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом',
        ],
      },
      {
        code: '63642295',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
          'Со средой 1-ой группы',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63642296',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Только радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Только выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Только испытание трубопровода на прочность и плотность',
          'Только измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Осуществляется все перечисленное',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63642297',
        text:
          'Какие устанавливаются сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          'Один раз в квартал',
          'Один раз в полгода',
          'Один раз в год',
          'Не регламентируются',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63642298',
        text:
          'Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?',
        answers: [
          'Не менее 1,0 м от стены здания',
          'Не менее 1,5 м от стены здания',
          'Не менее 2,0 м от стены здания',
          'Не менее 2,5 м от стены здания',
        ],
        correctAnswers: ['Не менее 1,0 м от стены здания'],
      },
      {
        code: '63642299',
        text:
          'Какие требования безопасности предъявляются к клапанным коробкам регенераторов?',
        answers: [
          'Допускается частичное попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать',
          'Контроль за отсутствием жидкого кислорода в клапанных коробках кислородных регенераторов должен осуществляться ежесуточно продувкой через соответствующую арматуру',
          'Необходимо контролировать отсутствие жидкого кислорода в клапанных коробках продувкой клапанных коробок через соответствующие продувочные вентили по мере необходимости',
          'Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать',
        ],
        correctAnswers: [
          'Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать',
        ],
      },
      {
        code: '63642300',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?',
        answers: [
          'На каждом с давлением нагнетания более 0,6 МПа',
          'С давлением нагнетания 0,6 МПа',
          'На одном из компрессоров с давлением нагнетания более 0,6 МПа',
          'На любом из компрессоров с давлением нагнетания 0,6 МПа',
        ],
        correctAnswers: ['На каждом с давлением нагнетания более 0,6 МПа'],
      },
      {
        code: '63642301',
        text:
          'В каком случае необходимо выгрузить и очистить чаны жаровни после их охлаждения от остатков мезги?',
        answers: [
          'Если температура мезги превышает установленные нормы',
          'Если отсутствует контроль со стороны рабочего персонала',
          'Если длительность остановки жаровни превышает 1 час',
          'Если длительность остановки жаровни превышает 10 минут',
        ],
        correctAnswers: ['Если длительность остановки жаровни превышает 1 час'],
      },
      {
        code: '63642302',
        text:
          'Какой высоты должно быть ограждение реципиентов, расположенных вне зданий?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 1,0 м',
          'Не менее 1,2 м',
          'Не менее 2,0 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63642303',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть демонтировано',
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63642304',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Иметь документы о прохождении аттестации',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63642305',
        text:
          'Как необходимо составлять описание схемы процесса при наличии нескольких аналогичных технологических ниток?',
        answers: [
          'Допускается делать описание схемы процесса по одной технологической нитке',
          'Необходимо представить описание всех технологических ниток',
          'Допускается делать описание схемы процесса не менее, чем по двум технологическим ниткам',
        ],
        correctAnswers: [
          'Допускается делать описание схемы процесса по одной технологической нитке',
        ],
      },
      {
        code: '63642306',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63642307',
        text:
          'Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?',
        answers: [
          'Без фильтра',
          'Без предохранительного клапана',
          'Без регулируемого клапана',
          'Без гидрозатвора',
        ],
        correctAnswers: ['Без гидрозатвора'],
      },
      {
        code: '63642308',
        text:
          'Какие требования установлены к люку-лазу, предназначенному для осмотра и ремонтных работ внутри силосных ячеек (бункеров, завальных ям)?',
        answers: [
          'Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой',
          'Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 50 x 50 мм и герметичной крышкой',
          'Люк размером не менее 400 x 400 мм, оборудованный съемной прочной решеткой с размером ячеек не более 80 x 80 мм',
          'Люк размером не менее 600 x 600 мм, оборудованный съемной прочной решеткой с размером ячеек не более 150 x 150 мм и герметичной крышкой',
        ],
        correctAnswers: [
          'Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой',
        ],
      },
      {
        code: '63642309',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование',
          'В проектной документации',
          'В технологическом регламенте на производство продукции',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63642310',
        text:
          'С какой целью предусматривается подача пара в месте расположения градирен на объектах производств растительных масел?',
        answers: [
          'С целью продувки сопел',
          'С целью продувки трубопроводов',
          'С целью размораживания льда в зимнее время',
          'Для достижения всех перечисленных целей',
        ],
        correctAnswers: ['Для достижения всех перечисленных целей'],
      },
      {
        code: '63642311',
        text:
          'В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?',
        answers: [
          'В течение периода приемки цистерны',
          'В течение слива аммиака',
          'В течение всего времени нахождения',
          'Наблюдение за цистернами с жидким аммиаком не требуется',
        ],
        correctAnswers: ['В течение всего времени нахождения'],
      },
      {
        code: '63642312',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Все перечисленные требования указаны верно',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63642313',
        text:
          'С какой пропускной способностью трубопроводы сброса продуктов разделения воздуха от предохранительных клапанов и других защитных устройств необходимо выводить за пределы здания?',
        answers: [
          'С пропускной способностью более 10 м³/ч',
          'С пропускной способностью более 50 м³/ч',
          'С пропускной способностью 100 м³/ч',
          'С пропускной способностью более 100 м³/ч',
        ],
        correctAnswers: ['С пропускной способностью более 100 м³/ч'],
      },
      {
        code: '63642314',
        text:
          'Какая арматура должна применяться в экстракционном цехе объектов производств растительных масел?',
        answers: [
          'Стальная',
          'Из неметаллических конструкционных материалов',
          'Чугунная',
          'Не регламентируется',
        ],
        correctAnswers: ['Стальная'],
      },
      {
        code: '63642315',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Инспектор территориального органа Ростехнадзора',
          'Комиссия эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63642316',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63642317',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом комиссии организации',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом главного инженера организации (технического директора, директора по производству)',
          'Приказом руководителя организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63642318',
        text:
          'Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?',
        answers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности',
        ],
        correctAnswers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
        ],
      },
    ],
    63636: [
      {
        code: '63636000',
        text:
          'Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?',
        answers: [
          'Не менее 1,5 м от стены здания',
          'Не менее 1,0 м от стены здания',
          'Не менее 2,5 м от стены здания',
          'Не менее 2,0 м от стены здания',
        ],
        correctAnswers: ['Не менее 1,0 м от стены здания'],
      },
      {
        code: '63636001',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63636002',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Организации-разработчике технологической части проектной документации',
          'Организации-разработчике технологического процесса',
          'Организации, выполнившей функции генерального проектировщика',
          'Организации, выполнившей проектную документацию',
          'О всех перечисленных организациях',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63636003',
        text:
          'Какая устанавливается максимальная норма налива жидкого хлора в емкость (резервуар, танк, сборник, вагон-цистерна, контейнер-цистерна)?',
        answers: ['2,15 кг/дм3', '1 кг/дм3', '1,25 кг/дм3', '1,75 кг/дм3'],
        correctAnswers: ['1,25 кг/дм3'],
      },
      {
        code: '63636004',
        text:
          'Какие из перечисленных вагонов-цистерн, контейнеров-цистерн, контейнеров (бочек) и баллонов допускается использовать для транспортировки жидкого хлора?',
        answers: [
          'Которые исправны, предназначены для транспортировки любых продуктов',
          'На которых демонтированы сифонные трубки из баллонов',
          'На которых имеются механические повреждения',
          'На которых истек срок ревизии предохранительного клапана и мембраны для вагонов-цистерн и контейнеров-цистерн',
        ],
        correctAnswers: [
          'На которых демонтированы сифонные трубки из баллонов',
        ],
      },
      {
        code: '63636005',
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ['Один год', '3 месяца', '6 месяцев', '1 месяц'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63636006',
        text: 'Каким принимают радиус опасной зоны для складов жидкого хлора?',
        answers: [
          'В пределах распространения хлорного облака со средней концентрацией, но не более 1000 м',
          'В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)',
          'В пределах глубины распространения хлорного облака с минимальной концентрацией (определяется по факту распространения)',
          'В пределах распространения хлорного облака со средней концентрацией, но не менее 100 м',
        ],
        correctAnswers: [
          'В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)',
        ],
      },
      {
        code: '63636007',
        text: 'Каким образом допускается прокладка аммиачных трубопроводов?',
        answers: [
          'Через вентиляционные камеры',
          'В горизонтальных проходных каналах',
          'В горизонтальных непроходных каналах',
          'Через производственные помещения, отнесенные к категории Б',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63636008',
        text:
          'Какой документ составляется по результатам технического диагностирования, неразрушающего контроля, разрушающего контроля технических устройств, обследования зданий и сооружений?',
        answers: [
          'Акты по результатам проведения указанных работ',
          'Техническая справка о проведении указанных работ',
          'Отчеты по результатам проведения указанных работ',
          'Раздел заключения экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Акты по результатам проведения указанных работ'],
      },
      {
        code: '63636009',
        text:
          'На сколько должны быть заглублены полуподземные резервуары и хранилища фосфора?',
        answers: [
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
          'На уровень, обеспечивающий вместимость не менее 30% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
        ],
        correctAnswers: [
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
        ],
      },
      {
        code: '63636010',
        text:
          'Какое требование к условиям проведения реакции синтеза, процесса охлаждения и размола пятисернистого фосфора указано верно?',
        answers: [
          'Должны проводиться в герметичной таре',
          'Должны проводиться в охлаждаемых аппаратах, снабженных устройствами для отвода тепла',
          'Должны проводиться в аппаратах, обогреваемых паром',
          'Должны проводиться в герметичных аппаратах в атмосфере инертного газа',
        ],
        correctAnswers: [
          'Должны проводиться в герметичных аппаратах в атмосфере инертного газа',
        ],
      },
      {
        code: '63636011',
        text:
          'Каким должно быть давление сжатого воздуха (азота) при передавливании жидкого хлора газообразным хлором?',
        answers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,5 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 2,0 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
          'Давление сжатого воздуха (азота) должно быть не менее чем на 0,05 МПа выше давления в сосуде, в который передавливается хлор',
        ],
        correctAnswers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
        ],
      },
      {
        code: '63636012',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
        ],
        correctAnswers: [
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
        ],
      },
      {
        code: '63636013',
        text:
          'В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите два правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков повышения температуры',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63636014',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальник производства',
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
          'Начальник производственно-технического (технического) отдела организации',
        ],
        correctAnswers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
      },
      {
        code: '63636015',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'В централизованную систему водоотведения',
          'На факельную установку',
          'В закрытые системы для дальнейшей утилизации',
          'В специальные контейнеры',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63636016',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63636017',
        text:
          'Что должны обеспечивать системы контроля, автоматического и дистанционного управления (системы управления), системы оповещения об аварийных ситуациях в разделе технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
          'Контроль за параметрами процесса, надежность применяемого оборудования, контроль за действиями персонала',
          'Надежность и безопасность проведения технологических процессов, мониторинг входных параметров процесса',
        ],
        correctAnswers: [
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
        ],
      },
      {
        code: '63636018',
        text:
          'Каким должен быть срок проведения экспертизы промышленной безопасности со дня получения экспертной организацией от заказчика экспертизы комплекта необходимых материалов и документов?',
        answers: [
          'Не более трех месяцев, но, по соглашению сторон, может быть продлен',
          'Не более одного месяца',
          'Не более двух месяцев',
          'Не менее шести месяцев',
        ],
        correctAnswers: [
          'Не более трех месяцев, но, по соглашению сторон, может быть продлен',
        ],
      },
      {
        code: '63636019',
        text:
          'Размещение какого количества одновременно отправляемых или поступающих вагонов-цистерн с жидким хлором должен обеспечивать отстойный путь (тупик)?',
        answers: [
          'Половины партии, при этом не более 5 штук',
          'Всей партии, но не более 14 штук',
          'Всей партии, но не более 10 штук',
          'Половины партии, при этом не более 7 штук',
        ],
        correctAnswers: ['Всей партии, но не более 10 штук'],
      },
      {
        code: '63636020',
        text:
          'Какие расстояния устанавливаются между зданиями, в которых размещены машинные, аппаратные отделения, и другими сооружениями на площадке холодопотребляющей организации?',
        answers: [
          '10 м',
          '5 м',
          'Расстояния определяется в соответствии с требованиями технических регламентов',
          'Расстояния определяются в соответствии с требованиями Правил безопасности химически опасных производственных объектов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63636021',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом руководителя организации',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом главного инженера организации (технического директора, директора по производству)',
          'Приказом комиссии организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63636022',
        text:
          'В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?',
        answers: [
          'Только в соответствии с требованиями технических регламентов',
          'Только в соответствии с требованиями градостроительной деятельности',
          'Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии со всеми перечисленными требованиями',
        ],
        correctAnswers: ['В соответствии со всеми перечисленными требованиями'],
      },
      {
        code: '63636023',
        text:
          'При какой концентрации хлора в воздухе допускается применение средств индивидуальной защиты органов дыхания фильтрующих?',
        answers: [
          'Не более 1,5% по объему',
          'Не более 0,5% по объему',
          'Не более 1,0% по объему',
          'Не более 2,5% по объему',
        ],
        correctAnswers: ['Не более 0,5% по объему'],
      },
      {
        code: '63636024',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 10 лет',
          'Не более 5 лет',
          'Не более 3 лет',
          'Не более 1 года',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63636025',
        text:
          'При какой периодичности использования трубопроводной арматуры ручной привод следует располагать на высоте не более 1,6 м от уровня пола или площадки, с которой ведется управление?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
          'Расположение привода не зависит от частоты использования арматуры',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63636026',
        text:
          'Каким должно быть расстояние по горизонтали от грани наземной части ближайшей опоры эстакады до оси железнодорожного пути нормальной колеи и до бордюра автомобильной дороги при пересечении высокими эстакадами железнодорожных путей и автомобильных дорог?',
        answers: [
          'Не менее 2,45 м и не менее 1,0 м соответственно',
          'Не менее 2,65 м и не менее 1,35 м соответственно',
          'Не менее 2,2 м и не менее 1,2 м соответственно',
          'Не менее 2,5 м и не менее 1,5 м соответственно',
        ],
        correctAnswers: ['Не менее 2,45 м и не менее 1,0 м соответственно'],
      },
      {
        code: '63636027',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В техническом регламенте',
          'В проектной документации',
          'В технологическом регламенте',
          'В руководствах по безопасности',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63636028',
        text:
          'Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами контроля за плотностью поступающих на разделение продукта и флегмы',
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
          'Средствами контроля за компонентным составом поступающего на разделение продукта',
        ],
        correctAnswers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
        ],
      },
      {
        code: '63636029',
        text:
          'Каких значений не должна превышать максимальная температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?',
        answers: [
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63636030',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'Если это определено и обосновано разработчиком проекта',
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
          'Если это определено и обосновано научно-исследовательской организацией',
          'По решению территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63636031',
        text:
          'Какие требования к экспертам в области промышленной безопасности указаны верно?',
        answers: [
          'При аттестации на 1 категорию эксперт обязан иметь высшее образование и стаж работы в области проведения экспертизы промышленной безопасности не менее 5 лет',
          'Эксперт обязан подготавливать заключение экспертизы промышленной безопасности и предоставлять его в территориальный орган Ростехнадзора для дальнейшей регистрации',
          'Эксперту запрещается участвовать в проведении экспертизы в отношении опасных производственных объектов, принадлежащих на праве собственности или ином законном основании организации, в трудовых отношениях с которой он состоит',
          'Эксперт обязан иметь лицензию на деятельность по проведению экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Эксперту запрещается участвовать в проведении экспертизы в отношении опасных производственных объектов, принадлежащих на праве собственности или ином законном основании организации, в трудовых отношениях с которой он состоит',
        ],
      },
      {
        code: '63636032',
        text:
          'Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?',
        answers: [
          'Не менее трех диаметров трубы',
          'Не менее двух диаметров трубы',
          'Соответствовать диаметру трубы',
          'Не менее четырех диаметров трубы',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63636033',
        text:
          'В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?',
        answers: [
          'Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам',
          'Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63636034',
        text:
          'Какие требования установлены к наружной поверхности трубопроводов, работающих в среде хлора?',
        answers: [
          'Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки',
          'Трубопроводы должны быть окрашены в зеленый цвет',
          'На трубопровод должна быть нанесена предупредительная надпись "Осторожно, яд!"',
          'Трубопроводы должны иметь гладкую поверхность и иметь необходимую окраску',
        ],
        correctAnswers: [
          'Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки',
        ],
      },
      {
        code: '63636035',
        text:
          'Какие сведения отражаются в заключении экспертизы промышленной безопасности по результатам экспертизы технического устройства?',
        answers: [
          'Расчетные и аналитические процедуры оценки и прогнозирования технического состояния объекта экспертизы, включающие определение остаточного ресурса (срока службы)',
          'Обоснованность применяемых физико-математических моделей и использованных методов расчета последствий аварии и показателей риска',
          'Выводы о правильности и достоверности выполненных расчетов по анализу риска, а также полноты учета факторов, влияющих на конечные результаты',
          'Выводы о достаточности мер предотвращения проникновения на опасный производственный объект посторонних лиц',
        ],
        correctAnswers: [
          'Расчетные и аналитические процедуры оценки и прогнозирования технического состояния объекта экспертизы, включающие определение остаточного ресурса (срока службы)',
        ],
      },
      {
        code: '63636036',
        text:
          'Каким из указанных требований должен соответствовать эксперт второй категории в области промышленной безопасности? Выберите два правильных варианта ответа.',
        answers: [
          'Иметь высшее образование',
          'Иметь стаж работы не менее 7 лет по специальности, соответствующей его области (областям) аттестации',
          'Иметь опыт проведения не менее 5 экспертиз',
          'Иметь лицензию на деятельность по проведению экспертизы промышленной безопасности',
          'Иметь протокол об аттестации в области промышленной безопасности, выданный территориальной аттестационной комиссией',
        ],
        correctAnswers: [
          'Иметь высшее образование',
          'Иметь стаж работы не менее 7 лет по специальности, соответствующей его области (областям) аттестации',
        ],
      },
      {
        code: '63636037',
        text:
          'Чему равно значение предельно допустимой концентрации хлора в воздухе рабочей зоны производственного помещения?',
        answers: ['1,0 мг/м3', '5,0 мг/м3', '10 мг/м3', '15 мг/м3'],
        correctAnswers: ['1,0 мг/м3'],
      },
      {
        code: '63636038',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'Время устанавливается в проектной документации',
          'В течение 24 часов',
          'В течение 8 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63636039',
        text:
          'Каким принимается радиус опасной зоны для складов жидкого хлора?',
        answers: [
          'Радиус опасной зоны определяется проектом',
          'Радиус опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией',
          'Радиус опасной зоны принимается равным 300 м',
          'Радиус опасной зоны принимается равным 450 м',
        ],
        correctAnswers: [
          'Радиус опасной зоны определяется расчетом, исходя из глубины распространения хлорного облака с поражающей концентрацией',
        ],
      },
      {
        code: '63636040',
        text:
          'Каким минимальным количеством эвакуационных выходов должны оснащаться помещения машинного и аппаратного отделения?',
        answers: [
          'Одним',
          'Тремя',
          'Двумя',
          'Определяется проектной документацией',
        ],
        correctAnswers: ['Двумя'],
      },
      {
        code: '63636041',
        text:
          'В течение какого времени после наполнения жидким хлором осуществляется отстой вагонов-цистерн с проведением визуального осмотра и контролем утечек хлора?',
        answers: [
          'Не менее 12 часов',
          'Не менее 3 часов',
          'Не менее суток',
          'Не менее 6 часов',
        ],
        correctAnswers: ['Не менее суток'],
      },
      {
        code: '63636042',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 20% НКПР',
          'При 18% НКПР',
          'При 15% НКПР',
          'При 10% НКПР',
        ],
        correctAnswers: ['При 20% НКПР'],
      },
      {
        code: '63636043',
        text:
          'Какой должна быть свободная высота эстакад для технологических трубопроводов над проездами и проходами для железнодорожных путей (над головкой рельса)?',
        answers: [
          'Не менее 5,55 м',
          'Не менее 5,0 м',
          'Не менее 6,0 м',
          'Не менее 4,5 м',
        ],
        correctAnswers: ['Не менее 5,55 м'],
      },
      {
        code: '63636044',
        text:
          'Какие из перечисленных трубопроводов хлора должны быть выполнены из хладостойких марок стали?',
        answers: [
          'Только наружные трубопроводы хлора, размещаемые в климатических районах с расчетной минимальной температурой ниже минус 40 °С',
          'Только трубопроводы жидкого хлора, имеющие рабочую температуру минус 40 °С',
          'Все перечисленные трубопроводы',
          'Только трубопроводы жидкого хлора, имеющие рабочую температуру минус 70 °С',
        ],
        correctAnswers: ['Все перечисленные трубопроводы'],
      },
      {
        code: '63636045',
        text:
          'Что не входит в обязанности эксперта в области промышленной безопасности?',
        answers: [
          'Представлять заключение экспертизы промышленной безопасности в Ростехнадзор для регистрации',
          'Определять соответствие объектов экспертизы промышленной безопасности требованиям промышленной безопасности',
          'Обеспечивать объективность и обоснованность выводов заключения экспертизы',
          'Обеспечивать сохранность документов и конфиденциальность сведений, представленных на экспертизу',
        ],
        correctAnswers: [
          'Представлять заключение экспертизы промышленной безопасности в Ростехнадзор для регистрации',
        ],
      },
      {
        code: '63636046',
        text: 'Что относится к технологическим трубопроводам?',
        answers: [
          'Трубопроводы, предназначенные для транспортирования различных веществ, необходимых для ведения технологического процесса или эксплуатации оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
        correctAnswers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
      },
      {
        code: '63636047',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'Со средой 1-ой группы',
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63636048',
        text:
          'Эксперты какой категории имеют право участвовать в проведении экспертизы промышленной безопасности опасных производственных объектов II класса опасности?',
        answers: [
          'Третьей и (или) второй',
          'Только второй',
          'Только первой',
          'Первой и (или) второй',
        ],
        correctAnswers: ['Первой и (или) второй'],
      },
      {
        code: '63636049',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63636050',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Критическими параметрами технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
          'Техническими характеристиками используемых насосов и компрессоров',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63636051',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводится не реже двух раз в смену',
        ],
        correctAnswers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63636052',
        text:
          'Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска и оснащаться системами самозапуска электродвигателей',
          'Время срабатывания системы самозапуска должно быть больше времени выхода параметров технологического процесса за предельно допустимые значения',
          'Насосы и компрессоры должны оснащаться маховиками для облегчения повторного самозапуска электродвигателей',
          'Насосы и компрессоры должны выбираться с учетом исключения возможности их повторного автоматического пуска',
        ],
        correctAnswers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска и оснащаться системами самозапуска электродвигателей',
        ],
      },
      {
        code: '63636053',
        text:
          'Кто осуществляет окраску вновь изготовленной транспортной тары для жидкого хлора и нанесение на нее надписей?',
        answers: [
          'Экспертная организация',
          'Организация-изготовитель',
          'Организация, которой на праве собственности либо ином законном основании принадлежит хлорная тара',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63636054',
        text:
          'На каких из перечисленных участках аммиачных трубопроводов должны быть нанесены три опознавательных кольца?',
        answers: [
          'На паровых линиях стороны высокого давления',
          'На парожидкостных и жидкостных линиях стороны низкого давления систем холодоснабжения',
          'На жидкостных линиях стороны высокого давления',
          'На паровых линиях стороны низкого давления систем холодоснабжения',
        ],
        correctAnswers: ['На жидкостных линиях стороны высокого давления'],
      },
      {
        code: '63636055',
        text:
          'Какой должна быть свободная высота эстакад для технологических трубопроводов над проездами и проходами для пешеходных дорог?',
        answers: [
          'Не менее 2,2 м',
          'Не менее 2,5 м',
          'Не менее 2,7 м',
          'Не менее 2,0 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63636056',
        text:
          'Что из перечисленного допускается при прокладке трубопроводов гипохлорита натрия (калия)? Выберите два правильных варианта ответа.',
        answers: [
          'Прокладка трубопроводов гипохлорита натрия (калия) через административные и бытовые помещения',
          'Крепить теплоспутники, закрепляемые без приварки, к трубопроводам, транспортирующим гипохлорит натрия (калия)',
          'Повторное использование прокладок для фланцевых соединений',
          'Подземная прокладка трубопроводов гипохлорита натрия (калия) в грунте, в коллекторах или непроходных каналах при нецелесообразности применения по технологическим или эксплуатационным условиям надземной прокладки',
        ],
        correctAnswers: [
          'Крепить теплоспутники, закрепляемые без приварки, к трубопроводам, транспортирующим гипохлорит натрия (калия)',
          'Подземная прокладка трубопроводов гипохлорита натрия (калия) в грунте, в коллекторах или непроходных каналах при нецелесообразности применения по технологическим или эксплуатационным условиям надземной прокладки',
        ],
      },
      {
        code: '63636057',
        text:
          'Оснащение какими устройствами вагонов-цистерн и контейнеров-цистерн для перевозки жидкого хлора указано верно?',
        answers: [
          'Тремя предохранительными клапанами',
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
          'Тремя вентилями, предназначенными для выпуска газообразного хлора (абгазов) или подачи газа для передавливания жидкого хлора, соединенными с укороченными сифонами, исключающими переполнение вагона-цистерны сверх установленной нормы налива',
          'Двумя штуцерами с вентилем для съемного манометра',
        ],
        correctAnswers: [
          'Двумя запорными клапанами для налива (слива) жидкого хлора с сифонными трубами и скоростными или внутренними отсечными клапанами, автоматически прекращающими выход жидкого хлора при разрыве трубопровода',
        ],
      },
      {
        code: '63636058',
        text:
          'В каком количестве допускается хранение в цехе суховальцованных паст для подколеровки эмалей?',
        answers: [
          'Не более 2% сменной потребности',
          'Не более 3% сменной потребности',
          'Не более 4% сменной потребности',
          'Не более 5% сменной потребности',
        ],
        correctAnswers: ['Не более 2% сменной потребности'],
      },
      {
        code: '63636059',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63636060',
        text:
          'Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?',
        answers: [
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 120 секунд',
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд',
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
        ],
        correctAnswers: [
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
        ],
      },
      {
        code: '63636061',
        text:
          'Каким должно быть количество жидкого хлора, хранящегося в организациях-потребителях в стационарных емкостях и хлорной транспортной таре?',
        answers: [
          'Количество жидкого хлора должно быть согласовано с территориальным управлением Ростехнадзора',
          'Количество жидкого хлора должно быть максимально необходимым для обеспечения производственного цикла',
          'Количество жидкого хлора не должно превышать 45-суточного потребления его организацией',
          'Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла',
        ],
        correctAnswers: [
          'Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла',
        ],
      },
      {
        code: '63636062',
        text:
          'Какое из перечисленных требований к системе вентиляции аммиачных холодильных установок указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Машинные, аппаратные отделения, а также конденсаторные отделения и распредустройства, располагаемые в помещениях, должны быть оборудованы системами приточно-вытяжной и аварийной вытяжной механической вентиляции',
          'Удаляемый воздух может выбрасываться в атмосферу без очистки',
          'Общеобменная и аварийная вентиляции оборудования холодильной установки должны иметь ручные пусковые устройства вне вентилируемых помещений',
          'Кратность воздухообмена должна определяться техническими регламентами',
        ],
        correctAnswers: [
          'Машинные, аппаратные отделения, а также конденсаторные отделения и распредустройства, располагаемые в помещениях, должны быть оборудованы системами приточно-вытяжной и аварийной вытяжной механической вентиляции',
          'Удаляемый воздух может выбрасываться в атмосферу без очистки',
        ],
      },
      {
        code: '63636063',
        text:
          'В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: [
          'Время должно быть подтверждено расчетом, но не менее 20 минут',
          'Время должно быть подтверждено расчетом, но не менее 35 минут',
          'Время должно быть подтверждено расчетом, но не более 45 минут',
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63636064',
        text:
          'Что следует применять для охлаждения контактных поверхностей передвижных шунтирующих устройств при производстве хлора методом электролиза?',
        answers: ['Обессоленную воду', 'Азот', 'Охлажденный воздух'],
        correctAnswers: ['Обессоленную воду'],
      },
      {
        code: '63636065',
        text:
          'Какие устройства должны иметь машинные, аппаратные и конденсаторные отделения, относящиеся к помещениям с взрывоопасной зоной В-1б?',
        answers: [
          'Только устройства молниезащиты зданий по II категории',
          'Только защиту от заноса высокого потенциала по наземным и подземным коммуникациям',
          'Только защиту от вторичных проявлений молнии',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63636066',
        text:
          'Какой условный диаметр должен быть у дренажного клапана, устанавливаемого на всасывающих и нагнетательных аммиачных трубопроводах для отвода масла и конденсата?',
        answers: [
          'Выбирается из размера (диаметра) основного трубопровода с учетом возможной производительности по сливу',
          'Не менее 22 мм',
          'Не менее 17 мм',
          'Не менее 12 мм',
        ],
        correctAnswers: [
          'Выбирается из размера (диаметра) основного трубопровода с учетом возможной производительности по сливу',
        ],
      },
      {
        code: '63636067',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Переход из режима ожидания в рабочее состояние должен осуществляться в течение 30 секунд',
          'Быть мобильными, иметь небольшие габариты и вес',
        ],
        correctAnswers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
        ],
      },
      {
        code: '63636068',
        text:
          'Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите два правильных варианта ответа.',
        answers: [
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Разработка методов отвода тепла',
          'Разработка методов приостановки реакции путем увеличения подачи одного из смешиваемых веществ',
          'Эффективное разделение этих продуктов и возможность образования застойных зон',
        ],
        correctAnswers: [
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Разработка методов отвода тепла',
        ],
      },
      {
        code: '63636069',
        text:
          'Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?',
        answers: [
          'Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов',
          'Проектная организация',
          'Монтажно-наладочная организация',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Проектная организация'],
      },
      {
        code: '63636070',
        text:
          'Какое из перечисленных требований при производстве жидкого хлора указано неверно?',
        answers: [
          'Воздух (азот) для разбавления абгазов, поступающих на вторую стадию сжижения хлора, необходимо осушать',
          'Объемная доля водорода в абгазах конденсации должна быть не более 4%',
          'Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений',
          'Давление воздуха, подаваемого в систему сжижения, должно превышать давление подаваемого хлоргаза не менее чем на 0,1 МПа (1 кгс/см2)',
        ],
        correctAnswers: [
          'Попадание абгазов конденсации в приемники жидкого хлора допускается не выше допустимых значений',
        ],
      },
      {
        code: '63636071',
        text:
          'Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?',
        answers: [
          'Из стали',
          'Из чугуна',
          'Из стале-алюминиевых сплавов',
          'Из неметаллических конструкционных материалов',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63636072',
        text:
          'Каким документом обосновывается минимально необходимое количество жидкого хлора, которое должно храниться в организациях?',
        answers: [
          'Стандартом организации',
          'Федеральными нормами и правилами',
          'Проектом',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63636073',
        text:
          'На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?',
        answers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
          'На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'На алгоритмах, разработанных по сценариям всех возможных аварий и их развития',
          'На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
        ],
        correctAnswers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
        ],
      },
      {
        code: '63636074',
        text:
          'Какой системой вентиляции должны быть оснащены производственные помещения при производстве товарного гипохлорита натрия (калия)?',
        answers: [
          'Приточно-вытяжной вентиляцией',
          'Только вытяжной вентиляцией',
          'Только приточной вентиляцией',
        ],
        correctAnswers: ['Приточно-вытяжной вентиляцией'],
      },
      {
        code: '63636075',
        text:
          'Какие из перечисленных помещений могут быть отнесены к категории "Д" в соответствии с нормами технологических регламентов, если при принятых технологических и объемно-планировочных проектных решениях концентрация аммиака в воздухе камеры не превысит нижнего предела взрывоопасности при аварийном раскрытии?',
        answers: [
          'Только помещения производственных цехов - потребителей холода, в технологическом оборудовании которых обращается аммиак',
          'Только помещения для установки распредустройств, размещаемые вблизи от потребителей холода',
          'Только помещения камер с непосредственным охлаждением',
          'Все перечисленные помещения',
        ],
        correctAnswers: ['Все перечисленные помещения'],
      },
      {
        code: '63636076',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Комиссией организации под председательством представителя проектной организации',
          'Лицом, утверждающим технологический регламент',
          'Технологическим руководителем организации',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63636077',
        text:
          'На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?',
        answers: [
          'На две категории',
          'На три категории',
          'На четыре категории',
          'На пять категорий',
        ],
        correctAnswers: ['На три категории'],
      },
      {
        code: '63636078',
        text:
          'Какие требования предъявляются к размещению сосудов с хлором на складах при вертикальной укладке?',
        answers: [
          'У стен следует размещать не более 2 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 3 ряда, необходимо исключать свободный доступ к запорным вентилям',
          'У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечивать свободный доступ к запорным вентилям',
          'У стен следует размещать не более 4 рядов баллонов и 3 рядов контейнеров, размещение сосудов в проходах запрещено',
          'У стен следует размещать не более 3 рядов баллонов и 2 рядов контейнеров, в проходах соответственно - 4 и 3 ряда, необходимо исключать свободный доступ к запорным вентилям',
        ],
        correctAnswers: [
          'У стен следует размещать не более 2 рядов баллонов и 1 ряд контейнеров, в проходах соответственно - 4 и 2 ряда, необходимо исключить возможность их падения или перемещения и обеспечивать свободный доступ к запорным вентилям',
        ],
      },
      {
        code: '63636079',
        text:
          'Какой должна быть высота защитного ограждения каждой группы резервуаров по отношению к уровню расчетного объема разлившейся жидкости?',
        answers: [
          'На 0,2 м выше',
          'На 0,15 м выше',
          'На 0,1 м выше',
          'На 0,05 м выше',
        ],
        correctAnswers: ['На 0,2 м выше'],
      },
      {
        code: '63636080',
        text:
          'Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?',
        answers: [
          'Полимерные материалы',
          'Композитные материалы',
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
          'Нержавеющая сталь',
        ],
        correctAnswers: [
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
        ],
      },
      {
        code: '63636081',
        text:
          'Какое из перечисленных требований к подземным технологическим трубопроводам, прокладываемым непосредственно в грунте, в местах пересечения автомобильных дорог и железных дорог, указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Должны быть размещены в защитных пластиковых трубах',
          'Концы защитных труб, в которых размещаются подземные технологические трубопроводы, должны отстоять от головки рельсов или от бровки обочины дороги не менее чем на 2 м',
          'Расстояние от верхней образующей защитной трубы до подошвы шпалы железнодорожного пути должно быть не менее 1 м',
          'Расстояние от верхней образующей защитной трубы до бровки полотна автодороги должно быть не менее 1 м',
        ],
        correctAnswers: [
          'Концы защитных труб, в которых размещаются подземные технологические трубопроводы, должны отстоять от головки рельсов или от бровки обочины дороги не менее чем на 2 м',
          'Расстояние от верхней образующей защитной трубы до подошвы шпалы железнодорожного пути должно быть не менее 1 м',
        ],
      },
      {
        code: '63636082',
        text:
          'Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Цикличный контроль за параметрами процесса и управление режимами для поддержания их регламентированных значений',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
        correctAnswers: [
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
      },
      {
        code: '63636083',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
        ],
        correctAnswers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
        ],
      },
      {
        code: '63636084',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63636085',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63636086',
        text:
          'В каком месте не допускается размещать запорную и регулирующую арматуру, устанавливаемые на аммиачных трубопроводах?',
        answers: [
          'Только в холодильных камерах',
          'Только над проходами для обслуживания оборудования',
          'Только над дверными проемами и над окнами',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63636087',
        text:
          'Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?',
        answers: [
          'Запрещено для защиты технологического оборудования использовать неметаллические покрытия',
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
          'Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц',
          'Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями',
        ],
        correctAnswers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
      },
      {
        code: '63636088',
        text:
          'Какие условия должны соблюдаться при перемещении по трубопроводам застывающих продуктов и расплавов, способных кристаллизоваться?',
        answers: [
          'Перемещения должны осуществляться по обогреваемым трубопроводам',
          'Температура в трубопроводе должна быть не ниже +25 °С',
          'Перемещения в трубопроводе должны осуществляться в среде соответствующего растворителя',
          'Для застывающих продуктов и расплавов, способных кристаллизоваться, перемещения по трубопроводам не допускаются',
        ],
        correctAnswers: [
          'Перемещения должны осуществляться по обогреваемым трубопроводам',
        ],
      },
      {
        code: '63636089',
        text:
          'В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?',
        answers: [
          'В соответствии с техническими регламентами',
          'В соответствии с требованиями территориальных органов Ростехнадзора',
          'В соответствии с требованиями законодательства о градостроительной деятельности',
          'В соответствии с требованиями Общих правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
        ],
        correctAnswers: [
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
      },
      {
        code: '63636090',
        text:
          'Кем представляется заключение экспертизы промышленной безопасности технических устройств в Ростехнадзор для внесения в реестр?',
        answers: [
          'Заказчиком экспертизы',
          'Экспертной организацией',
          'Проектной организацией',
          'Лабораторией, проводившей неразрушающий контроль металла и сварных соединений',
        ],
        correctAnswers: ['Заказчиком экспертизы'],
      },
      {
        code: '63636091',
        text:
          'Какие выводы может содержать заключение экспертизы промышленной безопасности опасного производственного объекта?',
        answers: [
          'Объект экспертизы не соответствует требованиям промышленной безопасности',
          'Все перечисленные',
          'Объект экспертизы соответствует требованиям промышленной безопасности',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63636092',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'Время устанавливается в проектной документации',
          'В течение 8 часов',
          'В течение 24 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63636093',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
          'Время срабатывания систем защиты должно равняться времени, необходимому для перехода параметра от предупредительного до предельно допустимого значения',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63636094',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63636095',
        text:
          'В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?',
        answers: [
          'Допускается для аппаратов вертикального типа',
          'Допускается в обоснованных в проектной документации случаях',
          'Допускается для аппаратов горизонтального типа',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в обоснованных в проектной документации случаях',
        ],
      },
      {
        code: '63636096',
        text:
          'В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Выберите два правильных варианта ответа.',
        answers: [
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если в тексте технологического регламента обнаружены подчистки и поправки',
          'Если такое решение принято технологической службой организации, производства, отделения, установки',
          'При сроке освоения производства более года',
        ],
        correctAnswers: [
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
        ],
      },
      {
        code: '63636097',
        text:
          'Каким требованиям должны соответствовать вновь проектируемые склады кислот, в которых возможно образование первичного кислотного облака? Выберите два правильных варианта ответа.',
        answers: [
          'Склады должны располагаться в более высоких местах по отношению к другим зданиям и сооружениям',
          'Склады должны располагаться с наветренной стороны преобладающих направлений ветров относительно места расположения ближайших населенных пунктов',
          'На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада',
          'На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках',
        ],
        correctAnswers: [
          'На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада',
          'На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках',
        ],
      },
      {
        code: '63636098',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63636099',
        text:
          'В течение какого срока выдерживаются на складе вновь скомплектованные партии наполненных контейнеров или баллонов жидким хлором?',
        answers: [
          'Не менее одних суток',
          'Не менее двух суток',
          'Не менее трех суток',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63636100',
        text:
          'Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых систем холодоснабжения?',
        answers: [
          'Не более 1,2 м',
          'Не менее 1,5 м',
          'Не менее 1 м',
          'Не более 0,8 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63636101',
        text:
          'В каком случае допускается стационарное применение цельносварных гофрированных стальных труб, включая конструкции с теплоизоляционными и (или) защитными слоями?',
        answers: [
          'При обосновании в проекте',
          'По решению технической комиссии эксплуатирующей организации',
          'При согласовании с территориальным органом Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['При обосновании в проекте'],
      },
      {
        code: '63636102',
        text:
          'На каком уровне сигнализаторы хлора должны иметь избирательность по хлору в присутствии сопутствующих компонентов?',
        answers: ['0,5 ПДК', '1,5 ПДК', '1 ПДК', '2 ПДК'],
        correctAnswers: ['0,5 ПДК'],
      },
      {
        code: '63636103',
        text:
          'Кто устанавливает назначенный срок службы для технологических трубопроводов?',
        answers: [
          'Разработчик документации',
          'Орган по сертификации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Организация-изготовитель',
        ],
        correctAnswers: ['Разработчик документации'],
      },
      {
        code: '63636104',
        text:
          'С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом марки стали трубопровода',
          'С учетом толщины стенки трубопровода',
          'С учетом диаметра и протяженности трубопровода',
          'С учетом характеристики транспортируемой среды',
        ],
        correctAnswers: [
          'С учетом диаметра и протяженности трубопровода',
          'С учетом характеристики транспортируемой среды',
        ],
      },
      {
        code: '63636105',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики',
          'Средствами ручного управления подачей водяного пара в топочное пространство и змеевики при прогаре труб',
          'Средствами контроля за температурой в топочном пространстве',
        ],
        correctAnswers: [
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики',
        ],
      },
      {
        code: '63636106',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Технический руководитель организации',
          'Представитель организации-изготовителя систем противоаварийной автоматической защиты',
          'Разработчик проекта',
          'Разработчик технологического процесса',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63636107',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63636108',
        text:
          'Какое содержание влаги должно быть в осушенном газе, используемом для технологических целей (передавливание хлора, продувка, разбавление при конденсации) в электролизерах?',
        answers: [
          'Должно соответствовать температуре точки росы не выше -40 °С',
          'Должно соответствовать температуре точки росы не выше -35 °С',
          'Должно соответствовать температуре точки росы не выше -30 °С',
          'Должно соответствовать температуре точки росы не выше -20 °С',
        ],
        correctAnswers: [
          'Должно соответствовать температуре точки росы не выше -40 °С',
        ],
      },
      {
        code: '63636109',
        text:
          'Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции в случае недостаточности воздухообмена',
          'Общеобменная вентиляция должна включаться при срабатывании установленных в помещении сигнализаторов довзрывных концентраций',
        ],
        correctAnswers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
        ],
      },
      {
        code: '63636110',
        text:
          'Где размещают лаковыпускные отделения в производстве лаков на конденсационных смолах?',
        answers: [
          'Только в изолированном помещении корпуса синтеза',
          'Только в отдельно стоящем корпусе',
          'Только на открытых площадках',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63636111',
        text:
          'Какое из перечисленных требований к трубе, выпускающей пары аммиака в атмосферу через предохранительные устройства, указано верно?',
        answers: [
          'Устье трубы для выпуска аммиака должно быть направлено вниз, труба должна быть защищена от скопления атмосферных осадков',
          'Внутренний диаметр трубы, отводящей пары аммиака, по всей длине должен быть меньше внутреннего диаметра выходного патрубка предохранительного устройства',
          'Труба должна быть выведена на 3 м выше конька крыши наиболее высокого здания в радиусе 50 м, но не менее 6 м от уровня территории (земли) и не менее 3 м от площадок обслуживания, находящихся в радиусе 15 м',
          'Присоединение предохранительных устройств к общей отводящей трубе, поперечное сечение которой должно быть не менее 100% суммы сечений для 1 - 4-х отводящих труб и не менее 50% суммы сечений отдельных отводящих труб, допускается в случае, если число отводящих труб более двух',
        ],
        correctAnswers: [
          'Труба должна быть выведена на 3 м выше конька крыши наиболее высокого здания в радиусе 50 м, но не менее 6 м от уровня территории (земли) и не менее 3 м от площадок обслуживания, находящихся в радиусе 15 м',
        ],
      },
      {
        code: '63636112',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63636113',
        text:
          'Проведение каких работ является обязательным при постановке вагона-цистерны на пункт слива-налива?',
        answers: [
          'Проверка наличия знаков опасности, надписей и трафаретов',
          'Заземление и закрепление тормозными башмаками с обеих сторон',
          'Удаление загрязнений с наружной поверхности цистерны',
          'Проверка работоспособности крепительных устройств крышки люка',
        ],
        correctAnswers: [
          'Заземление и закрепление тормозными башмаками с обеих сторон',
        ],
      },
      {
        code: '63636114',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63636115',
        text:
          'Какой должна быть скорость воздуха в воронках местных отсосов аспирационных воздуховодов?',
        answers: [
          'Не выше 1 м/с',
          'Не выше 2 м/с',
          'Не выше 3 м/с',
          'Не выше 4 м/с',
        ],
        correctAnswers: ['Не выше 2 м/с'],
      },
      {
        code: '63636116',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Периодический контроль за состоянием воздушной среды',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Предотвращение взрывов внутри технологического оборудования',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63636117',
        text:
          'В каком из перечисленных случаев следует предусматривать установку обратных клапанов на насосах, используемых для перекачки гипохлорита натрия?',
        answers: [
          'Только на всасывающих линиях при подключении нескольких насосов к общему всасывающему коллектору',
          'Только на нагнетательных линиях дозирующих насосов',
          'Только на нагнетательных линиях перекачивающих насосов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63636118',
        text:
          'В каком случае допускается укладка технологических трубопроводов в два яруса и более?',
        answers: [
          'Диаметром до 300 мм включительно',
          'Не допускается ни в каком случае',
          'Диаметром до 500 мм включительно',
          'Диаметром до 400 мм включительно',
        ],
        correctAnswers: ['Диаметром до 300 мм включительно'],
      },
      {
        code: '63636119',
        text:
          'В каком месте может размещаться оборудование, работающее на аммиаке?',
        answers: [
          'Только в помещении потребителей холода',
          'Только в машинном или аппаратном отделении',
          'Только на открытой площадке',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63636120',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
          'На организацию - разработчика процесса',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На технологическую службу организации, производства, отделения, установки',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63636121',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?',
        answers: [
          'Не менее 5 независимых датчиков с раздельными точками отбора',
          'Не менее 2 независимых датчиков с раздельными точками отбора',
          'Не менее 3 независимых датчиков с раздельными точками отбора',
          'Правилами безопасности химически опасных производственных объектов не регламентируется',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63636122',
        text:
          'На какой стадии предусматриваются средства контроля и регулирования уровня разделения фаз горючих паров (газов) и жидкостей?',
        answers: [
          'На стадии эксплуатации',
          'На стадии проектирования процесса',
          'На стадии строительства',
          'На стадии ликвидации',
        ],
        correctAnswers: ['На стадии проектирования процесса'],
      },
      {
        code: '63636123',
        text:
          'Что является результатом проведения экспертизы промышленной безопасности?',
        answers: [
          'Заключение экспертизы промышленной безопасности в письменной форме либо в форме электронного документа',
          'Экспертная оценка объекта экспертизы, оформленная протоколом',
          'Сертификат соответствия объекта экспертизы',
        ],
        correctAnswers: [
          'Заключение экспертизы промышленной безопасности в письменной форме либо в форме электронного документа',
        ],
      },
      {
        code: '63636124',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63636125',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Комиссией эксплуатирующей организации',
          'Разработчиком проекта',
          'Специализированной сторонней организацией',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63636126',
        text:
          'В каких случаях техническое устройство, применяемое на опасном производственном объекте, подлежит экспертизе промышленной безопасности, если техническим регламентом не установлена иная форма оценки соответствия указанного устройства обязательным требованиям?',
        answers: [
          'Только до начала применения на опасном производственном объекте',
          'Только по истечении срока службы или при превышении количества циклов нагрузки такого технического устройства, установленных его производителем',
          'Только при отсутствии в технической документации данных о сроке службы такого технического устройства, если фактический срок его службы превышает двадцать лет',
          'Только после проведения работ, связанных с изменением конструкции, заменой материала несущих элементов такого технического устройства',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63636127',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать их к зданиям',
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'Помещения управления должны быть отдельно стоящими',
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63636128',
        text:
          'Какую вентиляцию следует использовать для локализации аварийных ситуаций на складах химического гипохлорита натрия и в помещениях насосных?',
        answers: [
          'Общеобменную приточно-вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
          'Естественную вентиляцию',
          'Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
          'Общеобменную приточную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
        ],
        correctAnswers: [
          'Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
        ],
      },
      {
        code: '63636129',
        text:
          'При каких условиях допускается включение электролизера проточного действия при электрохимическом способе получения гипохлорита натрия?',
        answers: [
          'До заполнения электролитом',
          'После включения вентилятора, подающего воздух на разбавление и отдувку водорода',
          'После кислотной промывки электролизера',
          'После промывки электролизера от катодных карбонатных отложений',
        ],
        correctAnswers: [
          'После включения вентилятора, подающего воздух на разбавление и отдувку водорода',
        ],
      },
      {
        code: '63636130',
        text:
          'Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?',
        answers: [
          'Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания',
          'Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер',
          'Только в картере',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63636131',
        text:
          'В каком случае должна производиться чистка трубок конденсаторов объектов производств растительных масел?',
        answers: [
          'По графику, определенному руководителем организации',
          'По решению лица, ответственного за безопасное производство работ',
          'В установленных проектной документацией и документацией завода-изготовителя случаях',
        ],
        correctAnswers: [
          'В установленных проектной документацией и документацией завода-изготовителя случаях',
        ],
      },
      {
        code: '63636132',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63636133',
        text:
          'Какая максимальная объемная доля водорода в хлоре устанавливается в общем хлорном коллекторе при электролизе мембранным методом?',
        answers: ['0,3%', '0,4%', '0,2%', '0,5%'],
        correctAnswers: ['0,2%'],
      },
      {
        code: '63636134',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Только на единицу выпускаемой продукции',
          'Только на единицу времени (час)',
          'Только на один производственный поток или на мощность производства в целом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63636135',
        text:
          'Каким из перечисленных способов не допускается прокладка кабелей по территории предприятий и установок?',
        answers: [
          'Только в галереях',
          'Только в каналах, засыпанных песком',
          'Только на кабельных конструкциях технологических эстакад',
          'Допускаются все перечисленные способы прокладки',
        ],
        correctAnswers: ['Допускаются все перечисленные способы прокладки'],
      },
      {
        code: '63636136',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'Только П-образные компенсаторы',
          'Только Г-образные компенсаторы',
          'Только Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63636137',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Нарушение энергообеспечения',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63636138',
        text:
          'С учетом каких критериев выбираются насосы и компрессоры, используемые для перемещения газов, легковоспламеняющихся и горючих жидкостей, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом протяженности и способа прокладки трубопроводов',
          'С учетом физико-химических свойств перемещаемых продуктов',
          'С учетом регламентированных параметров технологического процесса',
          'С учетом диаметра и толщины стенки трубопроводов',
        ],
        correctAnswers: [
          'С учетом физико-химических свойств перемещаемых продуктов',
          'С учетом регламентированных параметров технологического процесса',
        ],
      },
      {
        code: '63636139',
        text:
          'В каком случае не предусмотрено проведение экспертизы промышленной безопасности зданий и сооружений на опасном производственном объекте?',
        answers: [
          'После аварии на опасном производственном объекте, в результате которой не были повреждены несущие конструкции данных зданий и сооружений',
          'В случае истечения срока эксплуатации здания или сооружения, установленного проектной документацией',
          'В случае отсутствия проектной документации, либо отсутствия в проектной документации данных о сроке эксплуатации здания или сооружения',
          'По истечении сроков безопасной эксплуатации, установленных заключениями экспертизы',
        ],
        correctAnswers: [
          'После аварии на опасном производственном объекте, в результате которой не были повреждены несущие конструкции данных зданий и сооружений',
        ],
      },
      {
        code: '63636140',
        text:
          'Куда допускается сброс неорганических жидких кислот и (или) щелочей от предохранительных клапанов?',
        answers: [
          'В окружающую среду',
          'В специальные емкости',
          'В рабочую зону',
          'Допускается во все перечисленные места в обоснованных случаях',
        ],
        correctAnswers: ['В специальные емкости'],
      },
      {
        code: '63636141',
        text:
          'Каким должно быть сопротивление изоляции электроизолирующих устройств (вставок, изоляторов, подвесок) электролизеров?',
        answers: [
          'Не менее 0,5 МОм',
          'Не менее 0,4 МОм',
          'Не менее 0,3 МОм',
          'Не менее 0,1 МОм',
        ],
        correctAnswers: ['Не менее 0,5 МОм'],
      },
      {
        code: '63636142',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 10',
          'Не менее 15',
          'Не менее 6',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63636143',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
        ],
      },
      {
        code: '63636144',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 5 лет',
          'Не более 3 лет',
          'Не более 10 лет',
          'Не более 20 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63636145',
        text:
          'В течение какого срока выдерживаются на складе вновь скомплектованные партии наполненных жидким хлором контейнеров или баллонов?',
        answers: [
          'Не менее двух суток',
          'Не менее трех суток',
          'Не менее одних суток',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63636146',
        text:
          'В каком положении должен находиться баллон (без сифона) при отборе из него газообразного хлора?',
        answers: [
          'В вертикальном положении вентилем вниз (угол наклона более 15°)',
          'В вертикальном или наклонном положении, при этом вентиль - в верхнем положении (угол наклона не более 15°)',
          'В наклонном положении вентилем вниз',
          'В горизонтальном положении',
        ],
        correctAnswers: [
          'В вертикальном или наклонном положении, при этом вентиль - в верхнем положении (угол наклона не более 15°)',
        ],
      },
      {
        code: '63636147',
        text:
          'На каких технологических трубопроводах не допускается применять сальниковые компенсаторы?',
        answers: [
          'На трубопроводах со средами 1-ой группы',
          'На трубопроводах для трудногорючих веществ',
          'На трубопроводах для негорючих веществ',
          'Допускается применять на всех перечисленных трубопроводах',
        ],
        correctAnswers: ['На трубопроводах со средами 1-ой группы'],
      },
      {
        code: '63636148',
        text:
          'Какой минимальный уклон должны иметь внутрицеховые трубопроводы для пятисернистого фосфора?',
        answers: ['0,005', '0,02', '0,1', '0,002'],
        correctAnswers: ['0,1'],
      },
      {
        code: '63636149',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Только средствами противоаварийной защиты',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
          'Только средствами автоматического регулирования',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63636150',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
          'Время срабатывания определяется расчетом',
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63636151',
        text:
          'Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?',
        answers: [
          'Техническим регламентом',
          'Проектной документацией',
          'Правилами безопасности',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63636152',
        text:
          'Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?',
        answers: [
          'Только включение светового и звукового сигналов при превышении предельно допустимых концентраций',
          'Только регистрацию всех случаев загазованности приборами',
          'Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63636153',
        text:
          'Каким должен быть внутренний диаметр стальных гильз из труб, используемых для прокладки трубопроводов через стены или перекрытия здания?',
        answers: [
          'На 3 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
          'На 3 - 5 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
          'На 5 - 10 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
          'На 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
        ],
        correctAnswers: [
          'На 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
        ],
      },
      {
        code: '63636154',
        text: 'Какую функцию не должен обеспечивать кустовой склад хлора?',
        answers: [
          'Выполнение заявок потребителей на отправку хлора автомобильным транспортом',
          'Организацию сбора порожней тары и ее упорядоченный возврат организации-наполнителю',
          'Розлив хлора в контейнеры и баллоны',
          'Прием, хранение затаренного хлора с учетом повагонных поставок хлора по железной дороге',
        ],
        correctAnswers: ['Розлив хлора в контейнеры и баллоны'],
      },
      {
        code: '63636155',
        text:
          'Каким образом допускается налив гипохлорита натрия в транспортные емкости?',
        answers: [
          'Только методом передавливания сжатым воздухом (азотом)',
          'Только самотеком из напорных емкостей',
          'Только при помощи насосов, предназначенных для перекачки гипохлорита натрия',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63636156',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На сети водоснабжения и канализации',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63636157',
        text:
          'Что необходимо предпринять в случае превышения установленной нормы заполнения тары хлором (1,25 кг/дм3)?',
        answers: [
          'В присутствии представителей наполнительной станции и территориального управления Ростехнадзора осуществить опорожнение баллона',
          'Отправить контейнер обратно на наполнительную станцию',
          'Немедленно отправить переполненный контейнер (баллон) на опорожнение',
          'Сделать соответствующую запись в журнале приемки контейнеров',
        ],
        correctAnswers: [
          'Немедленно отправить переполненный контейнер (баллон) на опорожнение',
        ],
      },
      {
        code: '63636158',
        text:
          'На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?',
        answers: [
          'Не более 2,2 м',
          'Не менее 3,4 м',
          'Не менее 2,2 м',
          'Не более 3,4 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63636159',
        text:
          'Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
          'Средствами контроля за фракционным составом измельченных твердых горючих продуктов',
          'Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут',
          'Средствами контроля за температурой измельченных твердых горючих продуктов',
        ],
        correctAnswers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
        ],
      },
      {
        code: '63636160',
        text:
          'Какой принимается радиус опасной зоны для складов жидкого хлора?',
        answers: [
          'Определяется проектом',
          'Принимается равным 300 м',
          'Принимается равным 450 м',
          'Принимается в пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)',
        ],
        correctAnswers: [
          'Принимается в пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)',
        ],
      },
      {
        code: '63636161',
        text:
          'Что из перечисленного может не учитываться для определения рисков при проектировании технологических трубопроводов?',
        answers: [
          'Наличие защищенных подвижных элементов',
          'Охлаждение от температуры окружающего воздуха',
          'Вибрация',
          'Усталость при переменных нагрузках',
        ],
        correctAnswers: ['Наличие защищенных подвижных элементов'],
      },
      {
        code: '63636162',
        text:
          'Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
        correctAnswers: [
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
      },
      {
        code: '63636163',
        text:
          'Каким должен быть диаметр стальных гильз из труб, используемых в местах прохода трубопроводов через стены или перекрытия здания?',
        answers: [
          'Внутренний диаметр гильз должен быть на 3 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
          'Внутренний диаметр гильз должен быть на 3 - 5 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
          'Внутренний диаметр гильз должен быть на 5 - 10 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
          'Внутренний диаметр гильз должен быть на 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
        ],
        correctAnswers: [
          'Внутренний диаметр гильз должен быть на 10 - 20 мм больше наружного диаметра трубопроводов (с учетом тепловой изоляции)',
        ],
      },
      {
        code: '63636164',
        text:
          'Как должны располагаться внутрицеховые технологические трубопроводы с тяжелыми газами, прокладываемые по несгораемой поверхности несущих стен производственных зданий с оконными и дверными проемами?',
        answers: [
          'На 0,5 м ниже оконных и дверных проемов',
          'На 0,5 м выше оконных и дверных проемов',
          'На 1 м ниже оконных и дверных проемов',
          'На 1 м выше оконных и дверных проемов',
        ],
        correctAnswers: ['На 0,5 м ниже оконных и дверных проемов'],
      },
      {
        code: '63636165',
        text:
          'Как необходимо составлять описание схемы процесса при наличии нескольких аналогичных технологических ниток?',
        answers: [
          'Допускается делать описание схемы процесса по одной технологической нитке',
          'Необходимо представить описание всех технологических ниток',
          'Допускается делать описание схемы процесса не менее чем по двум технологическим ниткам',
        ],
        correctAnswers: [
          'Допускается делать описание схемы процесса по одной технологической нитке',
        ],
      },
      {
        code: '63636166',
        text:
          'Какое из перечисленных утверждений к прокладке аммиачных трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Допускается прокладка аммиачных трубопроводов по глухим стенам',
          'Допускается прокладка аммиачных трубопроводов над частями зданий и сооружений, в которых размещено холодильное и технологическое оборудование с непосредственным охлаждением',
          'Допускается подземная прокладка аммиачных трубопроводов по территории организации в обоснованных проектной документацией случаях',
          'Допускается прокладка аммиачных трубопроводов по наружным стенам производственной части здания с дверными и оконными проемами',
        ],
        correctAnswers: [
          'Допускается прокладка аммиачных трубопроводов по глухим стенам',
          'Допускается прокладка аммиачных трубопроводов над частями зданий и сооружений, в которых размещено холодильное и технологическое оборудование с непосредственным охлаждением',
        ],
      },
      {
        code: '63636167',
        text:
          'Кто должен установить расчетный срок службы объектов систем холодоснабжения для технологических трубопроводов, который отражается в документации трубопроводов, вносится в паспорт трубопроводов и учитывается при организации и осуществлении деятельности на опасном производственном объекте?',
        answers: [
          'Организация, осуществляющая экспертизу промышленной безопасности',
          'Разработчик документации',
          'Территориальный орган Ростехнадзора',
          'Монтажная организация',
        ],
        correctAnswers: ['Разработчик документации'],
      },
      {
        code: '63636168',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Гидравлический расчет',
          'Теплотехнический расчет',
          'Статический и динамический прочностный расчет',
          'Расчет на прочность и устойчивость',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63636169',
        text:
          'Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке технологического оборудования?',
        answers: [
          'Наличие оросительных систем',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Наличие средств гидравлической, механической или химической чистки',
          'Возможность изоляции соседнего оборудования',
        ],
        correctAnswers: [
          'Наличие средств гидравлической, механической или химической чистки',
        ],
      },
      {
        code: '63636170',
        text:
          'Какое из перечисленных требований к образованию, хранению, транспортированию, уничтожению лаков и красок указано неверно?',
        answers: [
          'Приводы аппаратов, расположенных в помещениях, где возможно скапливание пыли, следует выполнять на одном валу с электродвигателем',
          'В отдельных случаях при установке типового оборудования разрешается применять клиноременные передачи с ремнями из электропроводящей резины',
          'В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива',
        ],
      },
      {
        code: '63636171',
        text:
          'На каких отдельных участках трассы допускается прокладка технологических трубопроводов в полупроходных каналах?',
        answers: [
          'Протяженностью не более 100 м',
          'Протяженностью не более 150 м',
          'Протяженностью не более 200 м',
          'Протяженностью не более 250 м',
        ],
        correctAnswers: ['Протяженностью не более 100 м'],
      },
      {
        code: '63636172',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Технологические схемы производства',
          'Характеристика производимой продукции',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Возможные инциденты в работе и способы их ликвидации',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63636173',
        text:
          'При каком сроке службы технического устройства, из-за отсутствия в технической документации соответствующих данных, для оценки его фактического состояния проводится техническое диагностирование?',
        answers: [
          'Свыше 20 лет',
          'Свыше 10 лет',
          'Свыше 15 лет',
          'Свыше 5 лет',
        ],
        correctAnswers: ['Свыше 20 лет'],
      },
      {
        code: '63636174',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Количество их определяется проектом',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Перила должны быть высотой не менее 1,3 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63636175',
        text:
          'Какая допускается максимальная рабочая температура технологического трубопровода при прокладке в грунте?',
        answers: ['150 °C', '100 °C', '200 °C', '170 °C'],
        correctAnswers: ['150 °C'],
      },
      {
        code: '63636176',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63636177',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Порошкообразные теплоизоляционные материалы',
          'Допускаются все перечисленные материалы',
          'Базальтовое супертонкое волокно',
          'Вату из непрерывного стеклянного волокна',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63636178',
        text:
          'Какие действия, осуществление которых необходимо при остановке чанного испарителя (тостера) объектов производств растительных масел, указаны неверно?',
        answers: [
          'Отключается подача глухого пара',
          'Проверяется целостность контура заземления',
          'При разгрузке тостера необходимо производить тщательную зачистку чанов от остатков шрота',
          'Перед открытием люков тостера необходимо подать острый пар во все чаны',
        ],
        correctAnswers: ['Проверяется целостность контура заземления'],
      },
      {
        code: '63636179',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63636180',
        text:
          'На каком максимальном расстоянии от верха опоры должны быть расположены обслуживающие площадки высоких опор вращающихся печей и сушилок в лакокрасочном производстве?',
        answers: ['200 мм', '300 мм', '400 мм', '500 мм'],
        correctAnswers: ['300 мм'],
      },
      {
        code: '63636181',
        text:
          'С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?',
        answers: [
          'С учетом возможности проведения эффективной и безопасной очистки системы',
          'С учетом химического анализа проб горючих веществ на содержание кислорода',
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
        correctAnswers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
      },
      {
        code: '63636182',
        text:
          'Каким требованиям должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
          'Полы в помещении должны быть холодными, с повышенной механической стойкостью',
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63636183',
        text:
          'Какое количество запорных арматур следует устанавливать на технологических трубопроводах взрывопожароопасных сред для герметичного отключения от коллектора агрегатов (технологических аппаратов) с рабочим давлением (далее - Pр) ≥4 МПа (40 кгс/см²)?',
        answers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
          'Одну единицу запорной арматуры и дренажную арматуру с заглушкой',
          'Три единицы запорной арматуры',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
        ],
      },
      {
        code: '63636184',
        text:
          'В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?',
        answers: [
          'Если это определено в проектной документации',
          'Если геометрический объем сосуда не превышает 0,3 м3',
          'Если клапан рассчитан на полную пропускную способность',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Если геометрический объем сосуда не превышает 0,3 м3',
        ],
      },
      {
        code: '63636185',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63636186',
        text:
          'Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?',
        answers: [
          'Требования территориальных органов Ростехнадзора',
          'Риски срабатывания автоматических систем противоаварийной защиты',
          'Требования организации-изготовителя реакционной аппаратуры',
          'Указания технического руководителя организации',
        ],
        correctAnswers: [
          'Риски срабатывания автоматических систем противоаварийной защиты',
        ],
      },
      {
        code: '63636187',
        text:
          'Какой минимальный уклон должны иметь внутрицеховые трубопроводы для фосфора?',
        answers: ['0,02', '0,1', '0,002', '0,05', 'Все ответы неверны'],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63636188',
        text:
          'Каким должно быть расчетное давление сосудов, содержащих жидкий хлор?',
        answers: [
          'Не менее 1 МПа',
          'Менее 1,2 МПа',
          'Не менее 1,4 МПа',
          'Не менее 1,6 МПа',
        ],
        correctAnswers: ['Не менее 1,6 МПа'],
      },
      {
        code: '63636189',
        text:
          'Какое минимальное время пребывания под навесом заполненных хлором контейнеров или баллонов?',
        answers: [
          'Не менее семи суток',
          'Не менее одних суток',
          'Не менее двух суток',
          'Не менее трех суток',
        ],
        correctAnswers: ['Не менее одних суток'],
      },
      {
        code: '63636190',
        text:
          'Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у организации-потребителя с электроснабжающей системой',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Обеспечение селективности защит на устройствах электроснабжающей и электропотребляющей организаций',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63636191',
        text:
          'В каком положении должен находиться баллон (без сифона) при отборе газообразного хлора?',
        answers: [
          'Баллон должен находиться только в наклонном положении вентилем вниз',
          'Баллон должен находиться только в горизонтальном положении',
          'Баллон должен находиться в вертикальном или наклонном положении',
          'Баллон должен находиться только в вертикальном положении',
        ],
        correctAnswers: [
          'Баллон должен находиться в вертикальном или наклонном положении',
        ],
      },
      {
        code: '63636192',
        text:
          'Какая величина избыточного давления должна постоянно быть в системе электровозгонки фосфора?',
        answers: [
          'Не более 3 мм водяного столба',
          'Не более 5 мм водяного столба',
          'Не менее 3 мм водяного столба',
          'Не менее 5 мм водяного столба',
        ],
        correctAnswers: ['Не менее 3 мм водяного столба'],
      },
      {
        code: '63636193',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите два правильных варианта ответа.',
        answers: [
          'Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности',
          'Возможность проведения ремонтных работ',
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ',
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
        ],
      },
      {
        code: '63636194',
        text:
          'Нахождение каких стационарных систем трубопроводов на пунктах слива-налива жидкого хлора не предусмотрено ФНП "Правила безопасности производств хлора и хлорсодержащих сред"?',
        answers: [
          'Трубопроводов пара и горячей воды для подогрева',
          'Трубопроводов для слива-налива жидкого хлора',
          'Трубопроводов для отвода газообразного хлора на потребление или поглощение',
          'Трубопроводов для подачи в вагон-цистерну сжатого воздуха (азота) или хлора для передавливания',
        ],
        correctAnswers: ['Трубопроводов пара и горячей воды для подогрева'],
      },
      {
        code: '63636195',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Только схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Только расчет на прочность',
          'Только регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63636196',
        text: 'Какой должна быть вместимость расходного склада хлора?',
        answers: [
          'Не более 15-суточного потребления его организацией',
          'Не более 10-суточного потребления его организацией',
          'Не более 20-суточного потребления его организацией',
          'Не более 25-суточного потребления его организацией',
        ],
        correctAnswers: ['Не более 15-суточного потребления его организацией'],
      },
      {
        code: '63636197',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией-разработчиком процесса',
          'Специализированной сторонней организацией',
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63636198',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'По I или II категории надежности',
          'По II или III категории надежности',
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
          'Только по I категории надежности',
        ],
        correctAnswers: ['По I или II категории надежности'],
      },
      {
        code: '63636199',
        text:
          'После выполнения каких мероприятий экспертная организация приступает к проведению экспертизы промышленной безопасности? Выберите два правильных варианта ответа.',
        answers: [
          'Предоставления образцов технических устройств либо обеспечения доступа экспертов к техническим устройствам, применяемым на опасном производственном объекте',
          'Предоставления заказчиком необходимых для проведения экспертизы документов',
          'После проведения расчетных и аналитических процедур оценки и прогнозирования технического состояния технических устройств',
          'После проведения технического диагностирования технических устройств',
        ],
        correctAnswers: [
          'Предоставления образцов технических устройств либо обеспечения доступа экспертов к техническим устройствам, применяемым на опасном производственном объекте',
          'Предоставления заказчиком необходимых для проведения экспертизы документов',
        ],
      },
      {
        code: '63636200',
        text:
          'Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?',
        answers: [
          'Предохранительные клапаны прямого действия',
          'Любые из перечисленных',
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
          'Предохранительный клапан с мембранным чувствительным элементом',
        ],
        correctAnswers: [
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
        ],
      },
      {
        code: '63636201',
        text:
          'Какая предельно допустимая концентрация аммиака в воздухе рабочей зоны помещений и вне помещений, у мест установки датчиков соответствует I уровню контроля концентрации аммиака в воздухе?',
        answers: [
          'Равная 20 мг/м3',
          'Равная 40 мг/м3',
          'Равная 120 мг/м3',
          'Равная 80 мг/м3',
        ],
        correctAnswers: ['Равная 20 мг/м3'],
      },
      {
        code: '63636202',
        text:
          'Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?',
        answers: [
          'Не менее 1,5 м с каждой его стороны',
          'Не менее 2 м с каждой его стороны',
          'Не менее 1,8 м с каждой его стороны',
          'Не менее 1,2 м с каждой его стороны',
        ],
        correctAnswers: ['Не менее 2 м с каждой его стороны'],
      },
      {
        code: '63636203',
        text:
          'Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?',
        answers: [
          'Через бытовые помещения',
          'По наружным стенам зданий, не связанных с обращением кислот и щелочей',
          'В местах пересечения железных и автомобильных дорог',
          'Через вспомогательные помещения',
        ],
        correctAnswers: ['В местах пересечения железных и автомобильных дорог'],
      },
      {
        code: '63636204',
        text:
          'Какое из перечисленных требований к прокладке и устройству технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
          'Технологические трубопроводы в производственных помещениях должны прокладываться закрыто',
          'Сварные и разъемные соединения трубопроводов внутри футляров или гильз допускаются в соответствии с проектом',
        ],
        correctAnswers: [
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
        ],
      },
      {
        code: '63636205',
        text:
          'В каком случае допускается размещение машинного (аппаратного) отделения в подвальных и цокольных этажах?',
        answers: [
          'Допускается, если это определяется требованиями технических регламентов',
          'Допускается, если это обосновано проектной документацией',
          'Допускается, если это согласовано с территориальным органом Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63636206',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса',
          'В случае использования специальных систем аварийного освобождения',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63636207',
        text:
          'Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?',
        answers: [
          'Расположенного на уровне выше 1,5 м от пола',
          'Расположенного на уровне выше 1,8 м от пола',
          'Расположенного на уровне не ниже 2,0 м от пола',
          'Расположенного на уровне, определенном проектной документацией',
        ],
        correctAnswers: ['Расположенного на уровне выше 1,8 м от пола'],
      },
      {
        code: '63636208',
        text:
          'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63636209',
        text:
          'Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?',
        answers: [
          'Расстояние определяется по нормам пожарной безопасности',
          'Расстояние определяется в соответствии с требованиями строительных норм и правил и с учетом расчетного радиуса опасной зоны',
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
          'Расстояние должно быть обусловлено устойчивостью здания склада к воздействию ударной волны',
        ],
        correctAnswers: [
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
      },
      {
        code: '63636210',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63636211',
        text:
          'Какая периодичность осмотра технического состояния трубок конденсаторов объектов производств растительных масел путем осмотра со вскрытием крышек?',
        answers: [
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в год',
          'Определяется технологическим регламентом',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63636212',
        text:
          'Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите два правильных варианта ответа.',
        answers: [
          'В помещении диспетчера предприятия',
          'На наружных установках',
          'На ограждении по периметру объекта через каждые 50 м',
          'На контрольно-пропускном пункте объекта',
        ],
        correctAnswers: [
          'В помещении диспетчера предприятия',
          'На наружных установках',
        ],
      },
      {
        code: '63636213',
        text:
          'Какими приборами могут быть оснащены сепараторы, устанавливаемые на всасывающей линии компрессора, вакуум-насоса, газодувки для отделения жидкой фазы из перемещаемой газовой среды?',
        answers: [
          'Только приборами контроля уровня',
          'Только сигнализацией по максимальному уровню',
          'Только средствами блокировки',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63636214',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'Только в каналах (закрытых или с засыпкой песком)',
          'Только в грунте',
          'Только надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63636215',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, показатели пожароопасности и токсичности',
          'Показатели взрыво- пожароопасности, а также и токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63636216',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.',
        answers: [
          'Запорную и запорно-регулирующую арматуру',
          'Клапаны, отсекающие и другие отключающие устройства',
          'Мануальные системы подавления взрыва',
          'Средства, регистрирующие превышение давления',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру',
          'Клапаны, отсекающие и другие отключающие устройства',
        ],
      },
      {
        code: '63636217',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63636218',
        text:
          'Какой должна быть величина прибавки на коррозию для толщины стенки трубопровода хлора дополнительно к расчетной?',
        answers: [
          'Не менее 1 мм',
          'Не менее 0,7 мм',
          'Не менее 0,5 мм',
          'Не менее 0,1 мм',
        ],
        correctAnswers: ['Не менее 1 мм'],
      },
      {
        code: '63636219',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'Не допускается ни в каком случае',
          'После проведения расчета на прочность',
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63636220',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 50 кг',
          'Массой более 40 кг',
          'Массой более 35 кг',
          'Массой более 30 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63636221',
        text:
          'Каким должно быть число последовательных ступеней изоляции крюка крана от земли в залах диафрагменного электролиза при производстве хлора?',
        answers: [
          'Не менее трех',
          'Не менее четырех',
          'Не менее пяти',
          'Не менее двух',
        ],
        correctAnswers: ['Не менее трех'],
      },
      {
        code: '63636222',
        text:
          'В какой документации должны быть обоснованы принятые решения по размещению и оснащению помещений, в которых размещено аммиачное оборудование и инженерные системы?',
        answers: [
          'В руководстве по безопасности',
          'В технологическом регламенте',
          'В проектной документации',
          'В заключении экспертизы по промышленной безопасности',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63636223',
        text:
          'В каком случае допускается перевозка сосудов с истекшим сроком технического освидетельствования, заполненных хлором?',
        answers: [
          'При заполнении хлором ниже установленного предела',
          'В случае согласования с территориальным органом Ростехнадзора',
          'Если срок технического освидетельствования истек не более 3 месяцев назад',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63636224',
        text:
          'Федеральные нормы и правила в области промышленной безопасности "Правила проведения экспертизы промышленной безопасности" не устанавливают:',
        answers: [
          'Требования к экспертам в области промышленной безопасности',
          'Процедуру проведения экспертизы промышленной безопасности',
          'Требования к оформлению заключения экспертизы промышленной безопасности',
          'Порядок ведения реестра заключений экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Порядок ведения реестра заключений экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63636225',
        text:
          'Какая должна быть минимальная глубина заложения подземных технологических трубопроводов от поверхности земли до верхней части трубы или теплоизоляции в тех местах, где не предусмотрено движение транспорта?',
        answers: ['0,6 м', '0,7 м', '0,8 м', '0,9 м'],
        correctAnswers: ['0,6 м'],
      },
      {
        code: '63636226',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
          'Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования',
        ],
        correctAnswers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
        ],
      },
      {
        code: '63636227',
        text:
          'Чем должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей? Выберите два правильных варианта ответа.',
        answers: [
          'Системами измерения плотности и вязкости перекачиваемых сред',
          'Средствами контроля температуры перемещаемой жидкости',
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63636228',
        text:
          'В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?',
        answers: [
          'В синий цвет',
          'В желтый цвет',
          'В красный цвет',
          'В черный цвет',
        ],
        correctAnswers: ['В желтый цвет'],
      },
      {
        code: '63636229',
        text:
          'Чем следует оборудовать производственные помещения без постоянных рабочих мест согласно Правилам безопасности при производстве, хранении, транспортировании и применении хлора?',
        answers: [
          'Данные помещения следует оборудовать аварийной или общеобменными вентиляционными системами',
          'Снаружи у входа в помещение необходимо предусматривать световую сигнализацию превышения уровня загазованности хлором в помещении',
          'При производстве ремонтных работ обогрев этих помещений должен быть осуществлен передвижными вентиляционно-отопительными устройствами',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63636230',
        text: 'Каким должен быть радиус кривизны изгибов трубопровода хлора?',
        answers: [
          'Не менее трех диаметров трубы',
          'Не менее двух диаметров трубы',
          'Не регламентируется',
          'Не менее четырех диаметров трубы',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63636231',
        text:
          'В каком случае для постоянного обслуживания оборудования (арматуры) аммиачных холодильных установок должна быть устроена металлическая площадка с ограждением и лестницей?',
        answers: [
          'При обслуживании оборудования на уровне выше 1,8 м от пола',
          'При обслуживании оборудования на уровне 1,5 м от пола и выше',
          'При обслуживании оборудования на уровне 1,2 м от пола и выше',
          'При обслуживании оборудования на уровне 1,0 м от пола и выше',
        ],
        correctAnswers: [
          'При обслуживании оборудования на уровне выше 1,8 м от пола',
        ],
      },
      {
        code: '63636232',
        text:
          'Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?',
        answers: [
          'Технологическим регламентом',
          'Правилами безопасности производства хлора и хлорсодержащих сред',
          'Эксплуатационной документацией',
          'Проектом',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63636233',
        text:
          'С какой периодичностью необходимо проводить периодическую выборочную ревизию трубопроводов хлора?',
        answers: [
          'Не реже 1 раза в 4 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 3 года',
        ],
        correctAnswers: ['Не реже 1 раза в 4 года'],
      },
      {
        code: '63636234',
        text:
          'При какой температуре поверхности аппаратов, находящихся в помещении, должны быть теплоизолированы несгораемыми материалами при производстве фосфора и его соединений?',
        answers: [
          '35 °C и выше',
          '45 °C и выше',
          '25 °C и выше',
          'Теплоизоляция не предполагается ни в каком случае',
        ],
        correctAnswers: ['45 °C и выше'],
      },
      {
        code: '63636235',
        text:
          'Что необходимо указать в разделе "Описание химико-технологического процесса и схемы" при применении катализаторов, в том числе металлоорганических, которые при взаимодействии с кислородом воздуха и (или) водой могут самовозгораться и (или) взрываться?',
        answers: [
          'Меры, исключающие возможность подачи в систему сырья, материалов и инертного газа, содержащих кислород и (или) влагу в количествах, превышающих предельно допустимые значения',
          'Меры, исключающие возможность превышения установленного количества катализаторов, подаваемых в систему',
          'Допустимые количества катализаторов, в том числе металлоорганических, их физико-химические свойства, а также допустимые концентрации кислорода и влаги в составе подаваемого в систему сырья',
        ],
        correctAnswers: [
          'Меры, исключающие возможность подачи в систему сырья, материалов и инертного газа, содержащих кислород и (или) влагу в количествах, превышающих предельно допустимые значения',
        ],
      },
      {
        code: '63636236',
        text:
          'Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Выберите два правильных варианта ответа.',
        answers: [
          'В машинном отделении',
          'В аппаратном отделении',
          'Над машинным отделением',
          'На открытой площадке',
        ],
        correctAnswers: ['В машинном отделении', 'В аппаратном отделении'],
      },
      {
        code: '63636237',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Блокировками по отключению подачи топлива при превышении значения температуры нагреваемого жидкого продукта',
          'Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта',
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
        ],
        correctAnswers: [
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
        ],
      },
      {
        code: '63636238',
        text:
          'Чем должно быть оснащено оборудование для разделения суспензий и фильтрации?',
        answers: [
          'При оснащении оборудования должны предусматриваться меры, предотвращающие образование химически опасных смесей как в самом оборудовании, так и в помещении',
          'Оборудование должно быть оснащено блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
          'При оснащении оборудования должны предусматриваться меры, предотвращающие образование химически опасных смесей в этом оборудовании',
          'При оснащении оборудования должны предусматриваться меры, предотвращающие возникновение взрывоопасной смеси',
        ],
        correctAnswers: [
          'Оборудование должно быть оснащено блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63636239',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Автоматическое',
          'Дистанционное неавтоматическое',
          'Ручное управление по месту',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63636240',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Только устройство световой сигнализации',
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Только устройство звуковой сигнализации',
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63636241',
        text:
          'Какие требования установлены в Правилах безопасности производств хлора и хлорсодержащих сред для закрытых складов жидкого хлора?',
        answers: [
          'Склады хлора должны располагаться только в подземных сооружениях',
          'Требования установлены в строительных нормах и правилах',
          'Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях',
          'Двери на складах должны открываться внутрь',
        ],
        correctAnswers: [
          'Склады хлора должны быть расположены в наземных и полузаглубленных одноэтажных зданиях или подземных сооружениях',
        ],
      },
      {
        code: '63636242',
        text:
          'Какие требования предъявляются к трубопроводам для транспортировки кислот и щелочей, прокладываемым по эстакадам? Выберите два правильных варианта ответа.',
        answers: [
          'Трубопроводы должны быть защищены от падающих предметов',
          'Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств',
          'При многоярусной прокладке трубопроводы кислот и щелочей следует располагать на самых верхних ярусах',
          'Трубопроводы для транспортировки кислот и щелочей должны быть изготовлены из композиционных материалов',
        ],
        correctAnswers: [
          'Трубопроводы должны быть защищены от падающих предметов',
          'Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств',
        ],
      },
      {
        code: '63636243',
        text:
          'В каком документе определяется количество компрессоров и необходимость установки резервного (резервных) компрессора (компрессоров)?',
        answers: [
          'В проекте',
          'В Правилах безопасности при производстве, хранении, транспортировании и применении хлора',
          'В распорядительной внутренней документации',
        ],
        correctAnswers: ['В проекте'],
      },
      {
        code: '63636244',
        text:
          'Чем следует оборудовать производственные помещения без постоянных рабочих мест согласно Правилам безопасности производств хлора и хлорсодержащих сред?',
        answers: [
          'При производстве ремонтных работ обогрев этих помещений должен быть осуществлен передвижными вентиляционно-отопительными устройствами',
          'Снаружи у входа в помещение необходимо предусматривать световую сигнализацию превышения уровня загазованности хлором в помещении',
          'Данные помещения следует оборудовать аварийной или общеобменными вентиляционными системами',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63636245',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчиком проекта',
          'Разработчиком процесса',
          'Разработчиками процесса и проекта',
          'Заказчиком в задании на проектирование',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63636246',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'Только в случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Только для обеспечения энергоэффективности',
          'Только для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
    ],
    63637: [
      {
        code: '63637000',
        text:
          'Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчик процесса',
          'Разработчик проекта',
          'Заказчик в задании на проектирование',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63637001',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63637002',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
          'Начальник производства',
          'Начальник производственно-технического отдела организации',
        ],
        correctAnswers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
      },
      {
        code: '63637003',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 10 копий',
          'Не менее 15 копий',
          'Все ответы неверны',
          'Не менее 6 копий',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63637004',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
          'Только журналы сварочных работ, паспорта, заключения по неразрушающему контролю',
          'Только акты, заключения и протоколы испытаний сварных соединений',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63637005',
        text:
          'Какие знаки вывешиваются рядом с местами хранения ила (выработанного карбида кальция)?',
        answers: [
          'Об опасности химического поражения',
          'О возможности отравления ядовитыми газами',
          'О запрете подходить на расстояние ближе 5 м',
          'О запрете курения и применения открытого огня в радиусе 10 м',
        ],
        correctAnswers: [
          'О запрете курения и применения открытого огня в радиусе 10 м',
        ],
      },
      {
        code: '63637006',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'В соответствии с проектной документацией',
          'Лицом, утверждающим технологический регламент',
          'Комиссией организации под председательством представителя проектной организации',
          'Технологическим руководителем организации',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63637007',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63637008',
        text:
          'При каких погодных условиях производится монтаж изоляции изотермических резервуаров жидкого аммиака?',
        answers: [
          'При соблюдении всех перечисленных погодных условий',
          'Только при отсутствии атмосферных осадков',
          'Только в теплое время года',
          'Только при температуре не ниже предусмотренной техническими условиями',
        ],
        correctAnswers: ['При соблюдении всех перечисленных погодных условий'],
      },
      {
        code: '63637009',
        text:
          'Какую вентиляцию следует использовать для локализации аварийных ситуаций на складах химического гипохлорита натрия и в помещениях насосных?',
        answers: [
          'Общеобменную приточно-вытяжную вентиляцию, которая должна иметь 2 резервных вентилятора, автоматически включающихся при выходе из строя рабочего агрегата',
          'Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
          'Естественную вентиляцию',
          'Общеобменную приточную вентиляцию, которая должна иметь резервный вентилятор, включающийся автоматически при выходе из строя рабочего агрегата',
        ],
        correctAnswers: [
          'Общеобменную вытяжную вентиляцию, которая должна иметь резервный вентилятор, автоматически включающийся при выходе из строя рабочего агрегата',
        ],
      },
      {
        code: '63637010',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?',
        answers: [
          'Не менее 240 секунд',
          'Не более 120 секунд',
          'Не менее 300 секунд',
          'Не более 12 секунд',
        ],
        correctAnswers: ['Не более 120 секунд'],
      },
      {
        code: '63637011',
        text:
          'В случае какой остановки сосуды, аппараты и трубопроводы холодильных установок должны подвергаться техническому освидетельствованию?',
        answers: [
          'Длительность остановки оборудования не влияет на необходимость проведения технического освидетельствования',
          'В случае остановки на срок более месяца',
          'Все ответы неверны',
          'В случае остановки на срок более 6 месяцев',
          'В случае остановки на срок более 3 месяцев',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63637012',
        text:
          'На каких из перечисленных участках аммиачных трубопроводов должны быть нанесены три опознавательных кольца?',
        answers: [
          'На паровых линиях стороны высокого давления',
          'На жидкостных линиях стороны высокого давления',
          'На парожидкостных линиях стороны низкого давления систем холодоснабжения',
          'На паровых линиях стороны низкого давления систем холодоснабжения',
        ],
        correctAnswers: ['На жидкостных линиях стороны высокого давления'],
      },
      {
        code: '63637013',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах индивидуальной защиты органов дыхания',
          'Только с разрешения представителя газоспасательной службы при обеспечении принудительного воздухообмена в газоопасном месте',
          'Только с разрешения лица, ответственного за подготовку и проведение работ, с инструментом, исключающим возможность искрообразования',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63637014',
        text:
          'Каким должно быть расчетное давление сосудов, содержащих жидкий хлор?',
        answers: [
          'Не менее 1,5 МПа',
          'Не менее 1,2 МПа',
          'Не менее 1,6 МПа',
          'Не менее 1,4 МПа',
        ],
        correctAnswers: ['Не менее 1,6 МПа'],
      },
      {
        code: '63637015',
        text:
          'Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?',
        answers: [
          'Расстояние определяется с учетом требований строительных норм и правил и с учетом расчетного радиуса опасной зоны',
          'Расстояние определяется в соответствии с нормами пожарной безопасности',
          'Расстояние должно рассчитываться с учетом устойчивости здания склада к воздействию ударной волны',
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
        correctAnswers: [
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
      },
      {
        code: '63637016',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для всех перечисленных производств',
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для производств с новой технологией',
          'Для новых в данной организации производств',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63637017',
        text:
          'Чем должны оснащаться сушильные установки, имеющие непосредственный контакт высушиваемого продукта с сушильным агентом?',
        answers: [
          'Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки',
          'Звуковой сигнализацией',
          'Световой сигнализацией',
          'Блокировками',
          'Сепараторами',
        ],
        correctAnswers: [
          'Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки',
        ],
      },
      {
        code: '63637018',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом комиссии организации',
          'Приказом руководителя организации',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом главного инженера организации (технического директора, директора по производству)',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63637019',
        text:
          'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальника центральной лаборатории организации',
          'Заместителя руководителя службы охраны труда',
          'Главного метролога организации',
          'Начальника цеха',
          'Начальника производства',
        ],
        correctAnswers: ['Начальника цеха', 'Начальника производства'],
      },
      {
        code: '63637020',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Надежное энергообеспечение',
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63637021',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К группе работ высокой степени опасности',
          'К группе работ cредней степени опасности',
          'К I группе',
          'Ко II группе',
        ],
        correctAnswers: ['Ко II группе'],
      },
      {
        code: '63637022',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Все перечисленные требования указаны верно',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63637023',
        text:
          'Каким способом хранение жидкого хлора в резервуарах (танках, контейнерах-цистернах) не осуществляется?',
        answers: [
          'При температуре плавления жидкого хлора',
          'При температуре кипения жидкого хлора при атмосферном давлении (изотермический способ хранения)',
          'При температуре окружающей среды',
          'В захоложенном состоянии при температуре ниже температуры окружающей среды',
        ],
        correctAnswers: ['При температуре плавления жидкого хлора'],
      },
      {
        code: '63637024',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'Показатели взрывоопасности и токсичные свойства веществ, участвующих на всех стадиях процесса',
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, а также показатели пожароопасности и токсичности',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63637025',
        text:
          'Каким принимают расчетное давление для трубопровода жидкого хлора?',
        answers: [
          'Не ниже 1,2 МПа',
          'Не ниже 1,5 МПа',
          'Не ниже 1,6 МПа',
          'Не ниже 1,3 МПа',
        ],
        correctAnswers: ['Не ниже 1,6 МПа'],
      },
      {
        code: '63637026',
        text:
          'С какой периодичностью проводятся наружный осмотр и испытание пробным давлением при техническом освидетельствовании трубопроводов? Выберите два правильных варианта ответа.',
        answers: [
          'По окончании монтажных работ перед пуском в эксплуатацию',
          'Не реже 1 раза в 8 лет',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: [
          'По окончании монтажных работ перед пуском в эксплуатацию',
          'Не реже 1 раза в 8 лет',
        ],
      },
      {
        code: '63637027',
        text:
          'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с загрузки сырья в оборудование и заканчивая выгрузкой',
          'По стадиям технологического процесса, начиная с загрузки подготовленного сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63637028',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоопасных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С газоспасательной службой и службой охраны труда',
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоопасных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63637029',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['30 °C', '20 °C', '25 °C', '35 °C'],
        correctAnswers: ['30 °C'],
      },
      {
        code: '63637030',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'В случае использования специальных систем аварийного освобождения',
          'Если выбросы рассчитываются, в количествах определяемых условиями безопасной остановки технологического процесса',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63637031',
        text:
          'Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?',
        answers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
          'Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
        ],
        correctAnswers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
        ],
      },
      {
        code: '63637032',
        text:
          'Каким документом определяются порог чувствительности датчиков системы контроля утечек хлора, их количество и месторасположение?',
        answers: [
          'Проектом',
          'Эксплуатационно-технической документацией',
          'Технологическим регламентом',
          'Правилами безопасности производства хлора',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63637033',
        text:
          'В какой момент необходимо монтировать внутренний противопожарный водопровод и автоматические системы пожаротушения, предусмотренные проектной документацией?',
        answers: [
          'Одновременно с возведением объекта защиты',
          'После возведения объекта защиты',
          'По решению руководителя строительно-монтажной организации',
          'Не регламентируется',
        ],
        correctAnswers: ['Одновременно с возведением объекта защиты'],
      },
      {
        code: '63637034',
        text:
          'В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Выберите два правильных варианта ответа.',
        answers: [
          'Если срок освоения производства составляет более года',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если в тексте технологического регламента обнаружены исправления, подчистки и поправки',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если такое решение принято технологической службой организации',
        ],
        correctAnswers: [
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
        ],
      },
      {
        code: '63637035',
        text:
          'С какой пропускной способностью трубопроводы сброса продуктов разделения воздуха от предохранительных клапанов и других защитных устройств необходимо выводить за пределы здания?',
        answers: [
          'С пропускной способностью более 25 м³/ч',
          'С пропускной способностью более 60 м³/ч',
          'С пропускной способностью более 75 м³/ч',
          'С пропускной способностью более 100 м³/ч',
        ],
        correctAnswers: ['С пропускной способностью более 100 м³/ч'],
      },
      {
        code: '63637036',
        text:
          'Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
          'Скорость распространения горения веществ, обращающихся в процессе',
          'Температура самовоспламенения паров веществ, обращающихся в процессе',
          'Класс опасности веществ, обращающихся в процессе',
        ],
        correctAnswers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
      },
      {
        code: '63637037',
        text:
          'Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только соответствие численного состава и квалификации персонала сварочного производства требованиям ПТД',
          'Выполнение всех подготовительных мероприятий',
          'Только соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям ПТД',
          'Только соответствие основных и сварочных материалов требованиям ПТД',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63637038',
        text:
          'На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?',
        answers: [
          'На алгоритмах, разработанных по сценариям возникновения возможных аварий и их развития',
          'На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, согласованных Ростехнадзором',
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
          'На сценариях возможных аварий и способах перевода опасного объекта в безопасное состояние',
        ],
        correctAnswers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
        ],
      },
      {
        code: '63637039',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63637040',
        text:
          'Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?',
        answers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
          'Проверку знания теоретических основ сварочного дела',
          'Проверку умения определять и устранять видимые и скрытые дефекты сварного соединения',
        ],
        correctAnswers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
        ],
      },
      {
        code: '63637041',
        text:
          'Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?',
        answers: [
          'Не менее 5 м с каждой его стороны',
          'Не менее 2 м с каждой его стороны',
          'Не менее 10 м с каждой его стороны',
          'Не менее 1 м с каждой его стороны',
        ],
        correctAnswers: ['Не менее 2 м с каждой его стороны'],
      },
      {
        code: '63637042',
        text:
          'С какой периодичностью проводится термографирование наружной поверхности резервуара жидкого аммиака в целях выявления участков с нарушенной теплоизоляцией?',
        answers: [
          'Один раз в год',
          'Один раз в 6 месяцев',
          'Один раз в месяц',
          'Один раз в 3 месяца',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63637043',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?',
        answers: [
          'Должно быть организовано только управление дистанционно (из безопасного места)',
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
          'Должно быть организовано только управление по месту',
        ],
        correctAnswers: [
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63637044',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Не моложе 18 лет',
          'Прошедшие обучение приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63637045',
        text:
          'Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?',
        answers: [
          'Соответствовать диаметру трубы',
          'Не менее четырех диаметров трубы',
          'Не менее двух диаметров трубы',
          'Не менее трех диаметров трубы',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63637046',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'П-образные компенсаторы',
          'Г-образные компенсаторы',
          'Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63637047',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии главного инженера',
          'В присутствии начальника цеха',
          'В присутствии рабочего, который будет спускаться в емкость, и наблюдающего',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63637048',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает запись в журнале проведения инструктажа структурного подразделения ремонтируемого объекта и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и сообщает о проведении инструктажа руководителю бригады, выполняющей ремонтные работы',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63637049',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В техническом регламенте',
          'В технологическом регламенте',
          'В руководстве по безопасности',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63637050',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'В соответствии со всем перечисленным',
          'В соответствии с проектной документацией',
          'В соответствии с рабочей документацией',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63637051',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63637052',
        text: 'Что из перечисленного обозначает личные шифры клейм сварщиков?',
        answers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий шестизначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и меняющийся при последующих аттестациях',
        ],
        correctAnswers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
      },
      {
        code: '63637053',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 1 лет',
          'Не более 3 лет',
          'Не более 5 лет',
          'Не более 10 лет',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63637054',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
          'Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования',
        ],
        correctAnswers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
        ],
      },
      {
        code: '63637055',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63637056',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации',
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'В технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63637057',
        text:
          'Для каких технологических трубопроводов стилоскопирование проводится выборочно, но не менее двух соединений, выполненных одним сварщиком с использованием сварочных материалов из одной партии, при отсутствии в проекте иных указаний?',
        answers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
          'Для технологических трубопроводов опасных веществ 1-го и 2-го классов опасности',
          'Для технологических трубопроводов при расчетном давлении выше 10 МПа',
          'Для всех перечисленных технологических трубопроводов',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
        ],
      },
      {
        code: '63637058',
        text: 'Какие ремонтные работы выполняются по наряду-допуску?',
        answers: [
          'Ремонтные работы на участке трубопровода, отключенном от работающей воздухоразделительной установки',
          'Ремонтные работы на низкотемпературных технических устройствах без их полного отогрева',
          'Ремонтные работы на оборудовании, отключенном от работающей воздухоразделительной установки',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63637059',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить структурные подразделения организации, которым поручено взаимодействовать с бригадой, выполняющей газоопасные работы',
          'Провести обучение и инструктаж персонала, выполняющего газоопасные работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63637060',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более 3 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63637061',
        text:
          'Какую скорость паров аммиака допускается принимать в сечении паровой зоны вертикального сосуда или аппарата, исполняющего функции отделителя жидкости?',
        answers: [
          'Не регламентируется',
          'Не более 0,5 м/с',
          'Не более 1,0 м/с',
          'Не более 1,5 м/с',
        ],
        correctAnswers: ['Не более 0,5 м/с'],
      },
      {
        code: '63637062',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Все перечисленное',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только акты испытания технологического трубопровода на прочность и плотность',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63637063',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'При неудовлетворительном состоянии уплотнительных поверхностей',
          'При срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'При деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63637064',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Увеличить число наблюдающих и работающих',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Установить систему подачи условных сигналов',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63637065',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ, а также лица, ответственные за подготовку объекта в целом или оборудования к ремонту',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования, технических устройств, коммуникаций в эксплуатацию',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63637066',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Главный инженер (технический директор) эксплуатирующей организации',
          'Руководитель аварийно-спасательной службы совместно с руководителем эксплуатирующей организации',
          'Руководитель службы производственного контроля (лицо, ответственное за осуществление производственного контроля)',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63637067',
        text:
          'Каким образом необходимо испытывать на плотность трубопроводы гипохлорита натрия (калия)?',
        answers: [
          'Водой или сжатым воздухом (азотом) давлением, равным 1,25 рабочего давления, но не менее 0,2 МПа (2 кгс/см²)',
          'Водой или сжатым воздухом (азотом) давлением, равным 1,75 рабочего давления, но не менее 0,8 МПа (8 кгс/см²)',
          'Водой или сжатым воздухом (азотом) давлением, равным 1,5 рабочего давления, но не менее 0,4 МПа (4 кгс/см²)',
          'Водой или сжатым воздухом (азотом) давлением, равным 1,15 рабочего давления, но не менее 0,1 МПа (1 кгс/см²)',
        ],
        correctAnswers: [
          'Водой или сжатым воздухом (азотом) давлением, равным 1,25 рабочего давления, но не менее 0,2 МПа (2 кгс/см²)',
        ],
      },
      {
        code: '63637068',
        text:
          'Какими документами должны сопровождаться технологические трубопроводы после монтажа и ремонта?',
        answers: [
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
          'Документами о стоимости материалов и работ по проведению монтажа и ремонта',
          'Лицензиями и другими учредительными документами организации, проводившей монтаж и ремонт',
          'Разрешениями организации, проводившей монтаж и ремонт на проведение этих видов работ',
        ],
        correctAnswers: [
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
        ],
      },
      {
        code: '63637069',
        text:
          'Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных актов',
          'Требования действующих нормативных актов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
          'Расчетные данные, которым должны соответствовать параметры промышленного оборудования, а также показатели надежности',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63637070',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над рабочими площадками',
          'В местах ввода трубопровода в технологические здания и сооружения',
          'Над местами, предназначенными для прохода людей',
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63637071',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Рпр), и при рабочем давлении',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Рпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63637072',
        text:
          'Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?',
        answers: ['На 75%', 'На 60%', 'На 95%', 'На 80%'],
        correctAnswers: ['На 80%'],
      },
      {
        code: '63637073',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Осуществляется все перечисленное',
          'Испытание трубопровода на прочность и плотность',
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63637074',
        text:
          'Где необходимо устанавливать фильтры на кислородопроводах, изготовленных из углеродистых или низколегированных сталей, работающих под давлением более 1,6 МПа?',
        answers: [
          'По ходу кислорода перед регулирующей арматурой, запорной арматурой при длине трубопроводов более 250 м',
          'Перед регулирующей и запорной арматурой при длине трубопроводов более 300 м',
          'На вводах трубопроводов кислорода в здание',
          'Только перед регулирующей арматурой',
        ],
        correctAnswers: [
          'По ходу кислорода перед регулирующей арматурой, запорной арматурой при длине трубопроводов более 250 м',
        ],
      },
      {
        code: '63637075',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее 4',
          'Не менее 3',
          'Не менее 2',
          'Количество наблюдающих определяет ответственный за проведение газоопасных работ',
        ],
        correctAnswers: ['Не менее 2'],
      },
      {
        code: '63637076',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой среды в его корпусе, достижении предельно допустимых значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отклонениях от опасных значений в расходной емкости',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63637077',
        text:
          'По указанию какого лица должны производиться снятие предохранительных клапанов на проверку, их установка и пломбирование?',
        answers: [
          'Лица, ответственного за проведение ремонтных работ',
          'Представителя проектной организации',
          'Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
          'Технического руководителя организации',
        ],
        correctAnswers: [
          'Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
        ],
      },
      {
        code: '63637078',
        text:
          'Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала',
          'Посредством изучения наглядных пособий (плакатов) по охране труда',
          'Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
        correctAnswers: [
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
      },
      {
        code: '63637079',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Гидравлический расчет',
          'Прочностный расчет',
          'Теплотехнический расчет',
          'Расчет на прочность и устойчивость',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63637080',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты «масляное или негорючей жидкостью заполнение оболочки» («о»)',
        ],
      },
      {
        code: '63637081',
        text:
          'Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63637082',
        text: 'Какой должна быть вместимость расходного склада хлора?',
        answers: [
          'Не более 15-суточного потребления его организацией',
          'Не более 25-суточного потребления его организацией',
          'Не более 20-суточного потребления его организацией',
          'Не более 10-суточного потребления его организацией',
        ],
        correctAnswers: ['Не более 15-суточного потребления его организацией'],
      },
      {
        code: '63637083',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в исходных данных на проектирование и технологическом регламенте на производство продукции',
          'Только в технологическом регламенте на производство продукции',
          'Только в проектной документации',
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63637084',
        text: 'Где могут быть расположены склады хлора?',
        answers: [
          'Во всех перечисленных сооружениях',
          'В наземных и полузаглубленных одноэтажных зданиях',
          'В подземных сооружениях',
          'В танках и контейнерах-цистернах под навесом',
        ],
        correctAnswers: ['Во всех перечисленных сооружениях'],
      },
      {
        code: '63637085',
        text:
          'Какое из перечисленных требований при производстве работ по ремонту технических устройств и трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить',
          'До выполнения работ на обеих трехходовых заслонках, связанных с доступом в подводящие к ним трубопроводы, устанавливаются заглушки (по ходу газа) после обеих трехходовых заслонок',
          'На весь период ремонта арматура на сбросе газа из блока в атмосферу должна быть закрыта',
        ],
        correctAnswers: [
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машина) на период ремонта клапанов (трубопроводов) необходимо остановить',
        ],
      },
      {
        code: '63637086',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень всех мест выполнения огневых работ',
          'Следует получить письменное разрешение от лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на проведение огневых работ, закрыв при этом наряд-допуск на выполнение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63637087',
        text:
          'Каким должно быть количество жидкого хлора, хранящегося в организациях-потребителях в стационарных емкостях и хлорной транспортной таре?',
        answers: [
          'Количество жидкого хлора, хранящегося в организациях-потребителях, должно быть согласовано с территориальным управлением Ростехнадзора',
          'Количество жидкого хлора должно быть максимально необходимым для обеспечения всего производственного цикла',
          'Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла',
          'Количество жидкого хлора не должно превышать 45-суточного потребления его в организации',
        ],
        correctAnswers: [
          'Количество жидкого хлора должно быть минимально необходимым для обеспечения производственного цикла',
        ],
      },
      {
        code: '63637088',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'О всех перечисленных организациях',
          'Организации, выполнявшей функции генерального проектировщика',
          'Организации - разработчике технологического процесса',
          'Организации - разработчике технологической части проектной документации',
          'Организации, выполнявшие проектную документацию',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63637089',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Установка заглушек',
          'Контроль сплошности изоляции и толщины стенок',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63637090',
        text:
          'Чем оснащаются системы разделения газожидкостных смесей в целях обеспечения высокой эффективности разделения фаз?',
        answers: [
          'Обратным клапаном',
          'Фильтрами',
          'Фазоразделителями',
          'Гидрозатвором',
        ],
        correctAnswers: ['Фазоразделителями'],
      },
      {
        code: '63637091',
        text:
          'Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
          'Только с пластмассовым уплотнением в затворе',
          'Только с резиновым уплотнением в затворе',
          'Только с тканевым уплотнением в затворе',
        ],
        correctAnswers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63637092',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Расчет на прочность',
          'Схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63637093',
        text:
          'Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?',
        answers: [
          'Расположенного на уровне выше 1,8 м от пола',
          'Расположенного на уровне не ниже 2,0 м от пола',
          'Расположенного на уровне выше 1,5 м от пола',
          'Расположенного на уровне, определенном проектной документацией',
        ],
        correctAnswers: ['Расположенного на уровне выше 1,8 м от пола'],
      },
      {
        code: '63637094',
        text:
          'В каком случае допускается предусматривать линейный ресивер для холодильных машин с дозированной зарядкой аммиака?',
        answers: [
          'Допускается предусматривать во всех случаях',
          'Все ответы неверны',
          'Допускается, если это обосновано проектной документацией',
          'Допускается если линейный ресивер используется в качестве защитного, дренажного или циркуляционного',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63637095',
        text:
          'Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?',
        answers: [
          'Лицо, ответственное за осуществление производственного контроля',
          'Лицо, ответственное за проведение газоопасных работ',
          'Начальник цеха',
          'Главный инженер',
        ],
        correctAnswers: ['Лицо, ответственное за проведение газоопасных работ'],
      },
      {
        code: '63637096',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'В случае наработки компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63637097',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Требования к обозначению определяются при разработке Плана мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63637098',
        text:
          'Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Только система водяного отопления',
          'Только система парового отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63637099',
        text:
          'Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?',
        answers: [
          'Стальная арматура и фасонные части, а также допускается применение арматуры и фитингов с деталями из цинка',
          'Стальная арматура и фасонные части, а также допускается применение запорной арматуры из высокопрочного чугуна',
          'Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди',
          'Стальная арматура и фасонные части',
        ],
        correctAnswers: ['Стальная арматура и фасонные части'],
      },
      {
        code: '63637100',
        text:
          'Куда допускается сброс неорганических жидких кислот и (или) щелочей от предохранительных клапанов?',
        answers: [
          'Допускается во все перечисленные места в обоснованных случаях',
          'В окружающую среду',
          'В рабочую зону',
          'В специальные емкости',
        ],
        correctAnswers: ['В специальные емкости'],
      },
      {
        code: '63637101',
        text:
          'Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?',
        answers: [
          'Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 1,2',
          'Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,5',
          'Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,6',
          'Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,3',
        ],
        correctAnswers: [
          'Vз.р. должны быть более суммарного геометрического объема Vс устройств охлаждения и технологических аппаратов (для рассматриваемой температуры кипения аммиака), умноженного на коэффициент 0,5',
        ],
      },
      {
        code: '63637102',
        text:
          'На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?',
        answers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
          'На трубопроводах технологических блоков II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении',
          'На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа',
        ],
        correctAnswers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
        ],
      },
      {
        code: '63637103',
        text:
          'Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?',
        answers: [
          'Масла, рекомендованные разработчиками компрессора',
          'Умягченную воду, полученную из заводской системы пароснабжения',
          'Дистиллят, полученный из воды питьевого качества',
          'Конденсат, полученный из заводской системы пароснабжения',
        ],
        correctAnswers: ['Дистиллят, полученный из воды питьевого качества'],
      },
      {
        code: '63637104',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Системами ручного регулирования',
          'Средствами обеспечения питания инертными газами систем контрольно-измерительных приборов и автоматики',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63637105',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63637106',
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ['6 месяцев', '12 месяцев', '2 месяца', '5 месяцев'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63637107',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?',
        answers: [
          'Не более 120 секунд',
          'Время срабатывания устанавливается разработчиком проекта',
          'Не более 12 секунд',
          'Не более 300 секунд',
        ],
        correctAnswers: ['Не более 12 секунд'],
      },
      {
        code: '63637108',
        text:
          'Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?',
        answers: [
          'Через административные и бытовые помещения',
          'В местах пересечения железных и автомобильных дорог',
          'Через вспомогательные и подсобные помещения',
          'По наружным стенам зданий, не связанных с обращением кислот и щелочей',
        ],
        correctAnswers: ['В местах пересечения железных и автомобильных дорог'],
      },
      {
        code: '63637109',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На сети водоснабжения и канализации',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63637110',
        text:
          'В каком случае допускается стационарное применение цельносварных гофрированных стальных труб, включая конструкции с теплоизоляционными и (или) защитными слоями?',
        answers: [
          'При обосновании в проекте',
          'По решению технической комиссии эксплуатирующей организации',
          'При согласовании с территориальным органом Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['При обосновании в проекте'],
      },
      {
        code: '63637111',
        text:
          'Каким документом определяется порядок отключения электроприводов арматуры на ремонт или ревизию на работающей воздухораспределительной установке?',
        answers: [
          'Локальной инструкцией по организации с учетом технологического процесса и руководством по эксплуатации на арматуру',
          'Инструкцией по эксплуатации электрооборудования и технологической инструкцией с учетом технической документации разработчика устройства',
          'Технологическим регламентом',
          'Технологической инструкцией с учетом технической документации разработчика устройства',
        ],
        correctAnswers: ['Технологическим регламентом'],
      },
      {
        code: '63637112',
        text:
          'Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Входящие в состав проектной документации или пусковые',
          'Периодически пересматриваемые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63637113',
        text:
          'В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: ['2 часа', '1 час', '15 минут', '30 минут'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63637114',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Возможные инциденты в работе и способы их ликвидации',
          'Характеристика производимой продукции',
          'Технологические схемы производства',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63637115',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63637116',
        text:
          'Какое из перечисленных требований к поддонам (приямкам) для сбора жидкого аммиака в случае разгерметизации сосуда указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу',
          'Количество пролитого аммиака из циркуляционного ресивера должно определяться по минимально допустимому заполнению сосуда',
          'Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)',
          'Количество пролитого аммиака из защитного ресивера должно определяться по номинальному заполнению сосуда',
          'Глубина приямка должна быть не более 3 м',
        ],
        correctAnswers: [
          'Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу',
          'Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)',
        ],
      },
      {
        code: '63637117',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'Надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'В каналах (закрытых или с засыпкой песком)',
          'В грунте',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63637118',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Разработчиком проекта',
          'Комиссией эксплуатирующей организации',
          'Специализированной сторонней организацией',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63637119',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'После проведения расчета на прочность',
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63637120',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 50 кг',
          'Массой более 40 кг',
          'Массой более 30 кг',
          'Массой более 35 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63637121',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять какие-либо работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63637122',
        text:
          'Какие требования безопасности предъявляются на период регенерации адсорбента к эксплуатации блока разделения воздуха при наличии в воздухораспределительной установке только одного адсорбера на потоке кубовой жидкости?',
        answers: [
          'Блок разделения воздуха необходимо останавливать не менее чем за 5 минут до начала проведения высокотемпературной регенерации адсорбента и предварительного проведения замеров концентрации ацетилена в жидком кислороде. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать не менее чем за 15 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию',
        ],
        correctAnswers: [
          'Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию',
        ],
      },
      {
        code: '63637123',
        text:
          'В течение какого времени должен быть обеспечен контроль места производства огневых работ после завершения работ?',
        answers: [
          'В течение не менее 4 часов',
          'В течение не менее 5 часов',
          'В течение не менее 6 часов',
          'В течение не менее 8 часов',
        ],
        correctAnswers: ['В течение не менее 4 часов'],
      },
      {
        code: '63637124',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 0,1 МПа',
          'Под давлением, равным рабочему, но не более 10 МПа',
          'Под давлением, равным рабочему, но не более 5 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63637125',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Электроприводы движущихся механизмов аппаратов, машин, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины, емкости, трубопроводы и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63637126',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'На трубопроводах, проложенных по эстакадам',
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автомобильными дорогами',
          'На трубопроводах, идущих по стенам производственных зданий',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63637127',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63637128',
        text:
          'Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?',
        answers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,25 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,1 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,15 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
        ],
        correctAnswers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
        ],
      },
      {
        code: '63637129',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?',
        answers: [
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления по действующим программам после производства обслуживающим персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления по действующим программам',
          'Средствами автоматического управления по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63637130',
        text:
          'Какие требования установлены к наружной поверхности трубопроводов, работающих в среде хлора?',
        answers: [
          'Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки',
          'На трубопровод должна быть нанесена предупредительная надпись "Осторожно, ядовитое вещество!"',
          'Трубопроводы должны иметь ровную поверхность и иметь соответствующую окраску',
          'Трубопроводы должны быть окрашены в зеленый цвет',
        ],
        correctAnswers: [
          'Трубопроводы должны иметь антикоррозийное покрытие, иметь опознавательную окраску, предупреждающие знаки и маркировочные щитки',
        ],
      },
      {
        code: '63637131',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Аварийно-спасательной службе организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63637132',
        text:
          'Кто устанавливает сроки проведения ревизии трубопроводов, запорной арматуры и предохранительных клапанов для неорганических жидких кислот и (или) щелочей в зависимости от скорости коррозионно-эрозионного износа?',
        answers: [
          'Научно-исследовательская организация',
          'Предприятие - владелец трубопровода',
          'Территориальный орган Ростехнадзора',
          'Проектная организация',
        ],
        correctAnswers: ['Предприятие - владелец трубопровода'],
      },
      {
        code: '63637133',
        text:
          'Какие из перечисленных трубопроводов хлора должны быть выполнены из хладостойких марок стали?',
        answers: [
          'Наружные трубопроводы хлора, размещаемые в климатических районах с расчетной минимальной температурой ниже минус 40 °С',
          'Все перечисленные трубопроводы',
          'Трубопроводы жидкого хлора, имеющие рабочую температуру минус 40 °С',
          'Трубопроводы жидкого хлора, имеющие рабочую температуру минус 70 °С',
        ],
        correctAnswers: ['Все перечисленные трубопроводы'],
      },
      {
        code: '63637134',
        text:
          'С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?',
        answers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 4 года',
        ],
        correctAnswers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63637135',
        text:
          'Как должен производиться отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'Открытым пламенем',
          'За счет тепла, подводимого к отогреваемым аппаратам',
          'Непосредственно сухим подогретым воздухом',
          'Непосредственно подогретой водой',
        ],
        correctAnswers: ['Непосредственно сухим подогретым воздухом'],
      },
      {
        code: '63637136',
        text:
          'Какое из перечисленных требований при проведении газосварочных работ указано верно?',
        answers: [
          'Запрещается в местах хранения и вскрытия барабанов с карбидом кальция курение, пользование открытым огнем; допускается применение искрообразующего инструмента',
          'Запрещается хранение в одном помещении кислородных баллонов и баллонов с горючими газами',
          'Разрешается хранение в одном помещении кислородных баллонов и карбида кальция, красок, масел и жиров',
          'Разрешается  курение и применение открытого огня в радиусе более 5 метров от мест хранения известкового ила',
        ],
        correctAnswers: [
          'Запрещается хранение в одном помещении кислородных баллонов и баллонов с горючими газами',
        ],
      },
      {
        code: '63637137',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 300 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 250 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63637138',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Во всех перечисленных случаях производятся диагностические работы',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63637139',
        text:
          'В каком случае допускается использование ртутных термометров и ртутных устройств для измерения температуры в контрольных точках аммиачной холодильной системы?',
        answers: [
          'Не допускается ни в каком случае',
          'В случае применения дополнительной защиты от механических повреждений',
          'В случае обоснования такого решения проектной документацией',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63637140',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?',
        answers: [
          'В соответствии с рекомендациями территориального органа Ростехнадзора',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63637141',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
          'Допускается применение клейма только того сварщика, который выполнил наибольший объем работ',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63637142',
        text:
          'Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?',
        answers: [
          'Инспектор Ростехнадзора',
          'Руководитель организации',
          'Технический руководитель организации',
          'Руководитель независимого аттестационного центра',
        ],
        correctAnswers: ['Руководитель независимого аттестационного центра'],
      },
      {
        code: '63637143',
        text:
          'Каким должно быть давление сжатого воздуха (азота) при передавливании жидкого хлора газообразным хлором?',
        answers: [
          'Давление сжатого воздуха (азота) не должно превышать 2,1 МПа',
          'Давление сжатого воздуха (азота) должно быть не менее чем на 0,1 МПа выше давления в сосуде, в который передавливается хлор',
          'Давление сжатого воздуха (азота) не должно превышать 1,6 МПа',
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
        ],
        correctAnswers: [
          'Давление сжатого воздуха (азота) не должно превышать 1,2 МПа',
        ],
      },
      {
        code: '63637144',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 4 часов',
          'Не менее 12 часов',
          'Не менее 8 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63637145',
        text:
          'На основе каких данных составляется материальный баланс для действующих производств?',
        answers: [
          'На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента',
          'На основе данных проектной документации с учетом внесенных в нее изменений, включения или исключения дополнительных операций или стадий',
          'На основе данных проектной документации',
        ],
        correctAnswers: [
          'На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента',
        ],
      },
      {
        code: '63637146',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 10 минут',
          'Не менее 5 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63637147',
        text:
          'Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?',
        answers: [
          'Полимерные материалы',
          'Нержавеющая сталь',
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
          'Композитные материалы',
        ],
        correctAnswers: [
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
        ],
      },
      {
        code: '63637148',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
          'Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
          'Во всех указанных местах',
        ],
        correctAnswers: ['Во всех указанных местах'],
      },
      {
        code: '63637149',
        text:
          'Что необходимо учитывать при размещении технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках?',
        answers: [
          'Возможность быстрого демонтажа оборудования',
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'Наличие специального персонала, имеющего необходимые допуски, для очистки и обслуживания оборудования',
          'Минимизацию контакта с коррозионно-активными веществами',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
        ],
      },
      {
        code: '63637150',
        text:
          'На каких участках следует производить укладку утеплителя, выполненного из горючего и слабогорючего материала, и устройство гидроизоляционного ковра на покрытии, устройство защитного гравийного слоя, монтаж ограждающих конструкций с применением горючих утеплителей?',
        answers: [
          'Площадью не более 900 м2',
          'Площадью не более 500 м2',
          'Площадью не более 1000 м2',
          'Площадью не более 700 м2',
        ],
        correctAnswers: ['Площадью не более 500 м2'],
      },
      {
        code: '63637151',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63637152',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
          'Лист регистрации изменений и дополнений размещается в начале регламента',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63637153',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Автоматический ввод резерва должен быть между каждым из 3 самостоятельных источников электроснабжения',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63637154',
        text: 'Что должно быть указано в технологических картах сварки?',
        answers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
          'Требования к применяемым сварочным технологиям, последовательность проводимых операций, технические приемы, особенности процесса сварки, обеспечивающие качество всех соединений',
          'Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, методы контроля качества сварных соединений',
        ],
        correctAnswers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
        ],
      },
      {
        code: '63637155',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Правилами не регламентируется',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники подрядных организаций',
          'Работники, указанные в наряде-допуске',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63637156',
        text:
          'Каким минимальным количеством эвакуационных выходов должны оснащаться помещения машинного и аппаратного отделения?',
        answers: ['3', '2', 'Определяется проектной документацией', '1'],
        correctAnswers: ['2'],
      },
      {
        code: '63637157',
        text:
          'Каким должно быть число последовательных ступеней изоляции крюка крана от земли в залах диафрагменного электролиза при производстве хлора?',
        answers: ['Не менее 3', 'Не менее 2', 'Не менее 5', 'Не менее 4'],
        correctAnswers: ['Не менее 3'],
      },
      {
        code: '63637158',
        text:
          'Какие меры безопасности должны соблюдаться при хранении и перекачке фосфора и фосфорного шлама?',
        answers: [
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены устройствами ("воздушками") для предотвращения образования вакуума и попадания фосфора в паропровод',
          'Все перечисленные',
          'Температура фосфора и фосфорного шлама не должна быть выше 80 °С',
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены приборами контроля давления пара',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63637159',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещеющее',
          'Руководитель эксплуатирующей организации',
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которого возложены функции обеспечения мер пожарной безопасности',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63637160',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 20 лет',
          'Не более 10 лет',
          'Не более 3 лет',
          'Не более 5 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63637161',
        text:
          'Чему соответствует вместимость поддонов, которыми оснащают емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?',
        answers: [
          '50% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и (или) емкостей',
          '80% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и (или) емкостей',
          'Объему всего расположенного в поддонах оборудования',
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
        ],
        correctAnswers: [
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
        ],
      },
      {
        code: '63637162',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Разработать проект производства работ',
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63637163',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 24 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63637164',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно может быть изолировано от действующих технологических систем',
          'Оборудование должно быть изолировано от действующих технологических систем, а нанесенное на нем обозначение номера по технологической документации должно быть закрашено на схеме',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
        ],
      },
      {
        code: '63637165',
        text:
          'Какой отличительный знак должен быть у инструмента, предназначенного для разборки и ремонта узлов, работающих в кислородной среде?',
        answers: [
          'Инструмент должен иметь отличительную зеленую полосу',
          'Инструмент должен иметь отличительную голубую полосу',
          'Инструмент должен иметь отличительную красную полосу',
          'Инструмент должен иметь отличительную желтую полосу',
        ],
        correctAnswers: [
          'Инструмент должен иметь отличительную голубую полосу',
        ],
      },
      {
        code: '63637166',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Провести реконструкцию химически опасного производственного объекта',
          'Провести экспертизу промышленной безопасности химически опасного производственного объекта',
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
          'Немедленно сообщить в Ростехнадзор об отклонениях, выявленных в рамках проведения производственного контроля',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
        ],
      },
      {
        code: '63637167',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '12 месяцев',
          '6 месяцев',
          '24 месяца',
          '36 месяцев',
          '3 месяца',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63637168',
        text:
          'В каком случае не допускается применение гибких резиновых или пластмассовых шлангов?',
        answers: [
          'В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака',
          'Для проведения вспомогательных операций (освобождение трубопроводов, аппаратов, фильтров от остатков аммиака, масла)',
          'При выполнении операций слива аммиака (при заполнении системы) из цистерны',
        ],
        correctAnswers: [
          'В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака',
        ],
      },
      {
        code: '63637169',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами организации',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Технологическими регламентами',
          'Правилами противопожарного режима',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63637170',
        text:
          'Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?',
        answers: [
          'Временные технологические регламенты',
          'Разовые (опытные) технологические регламенты',
          'Постоянные технологические регламенты',
          'Лабораторные технологические регламенты',
        ],
        correctAnswers: ['Разовые (опытные) технологические регламенты'],
      },
      {
        code: '63637171',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 2 лет',
          'Не реже одного раза в 8 лет',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63637172',
        text:
          'В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?',
        answers: [
          'Не допускается ни в каком случае',
          'Объемом до 1500 кг/год',
          'Объемом до 1000 кг/год',
        ],
        correctAnswers: ['Объемом до 1000 кг/год'],
      },
      {
        code: '63637173',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
          'Специалисты, обладающие необходимыми умениями и ознакомленные с требованиями охраны труда при производстве сварочных работ',
          'Сварщики и специалисты сварочного производства старше 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение мерам пожарной безопасности',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63637174',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания первой медицинской помощи',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63637175',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 5 минут',
          'Через 10 минут',
          'Через 30 минут',
          'Через 60 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63637176',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63637177',
        text:
          'Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?',
        answers: [
          'Внутреннее распоряжение о производстве указанных работ',
          'Оформление дополнительного документа на проведение указанных работ не требуется',
          'План работ, утвержденный техническим руководителем организации',
          'Наряд-допуск на указанные работы',
        ],
        correctAnswers: ['Наряд-допуск на указанные работы'],
      },
      {
        code: '63637178',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63637179',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 допускается ручное или дистанционное управление',
          'Для установок с технологическими блоками I и II категорий взрывоопасности, предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - дистанционное, для установок с технологическими блоками III категории взрывоопасности - ручное',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности предусматривается только автоматическое управление, а при Qв ≤ 10 допускается ручное управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности, предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление',
        ],
      },
      {
        code: '63637180',
        text:
          'В каком случае допускается определение толщин стенок трубопроводов иным способом, отличным от метода неразрушающего контроля?',
        answers: [
          'Допускается в соответствии с документацией на ХОПО',
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
          'Не допускается ни в каком случае',
          'Допускается в присутствии инспектора Ростехнадзора',
        ],
        correctAnswers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
        ],
      },
      {
        code: '63637181',
        text:
          'Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых аммиачных систем холодоснабжения?',
        answers: ['Не менее 1,2 м', 'Не менее 1,5 м', 'Более 1 м', 'Более 2 м'],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63637182',
        text:
          'Что учитывается при определении пропускной способности предохранительных устройств для защиты от разрушений сосудов, аппаратов и технологического оборудования, содержащих жидкий аммиак?',
        answers: [
          'Площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 2,5 раза больше расчетного давления защищаемого сосуда (аппарата)',
          'Скорость теплового потока испаряющегося аммиака в случае пожара',
          'Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата',
          'Общий объем аммиака в сосуде, аппарате',
        ],
        correctAnswers: [
          'Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата',
        ],
      },
      {
        code: '63637183',
        text:
          'Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?',
        answers: [
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
          'При прокладке трубопроводов через строительные конструкции зданий и другие препятствия принимаются меры, исключающие возможность передачи дополнительных нагрузок на трубы',
          'Трубопроводы не должны иметь фланцевых или других разъемных соединений',
          'Материал фланцев, конструкция уплотнения принимаются в соответствии с нормативно-техническими документами с учетом условий эксплуатации',
        ],
        correctAnswers: [
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
        ],
      },
      {
        code: '63637184',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случаях изгиба болтов и шпилек',
          'В случае износа боковых граней головок болтов и гаек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63637185',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'На рабочем месте не должно быть ничего, кроме исправного сварочного оборудования',
          'Необходимым сварочным оборудованием и наглядными пособиями (плакатами, схемами) по вопросу соблюдения противопожарного режима на объекте',
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63637186',
        text:
          'Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?',
        answers: [
          'Только идентификацию применяемых сварочных материалов и сварочного оборудования',
          'Только регистрацию сведений о сварщиках, выполняющих сварные соединения',
          'Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений',
          'Все перечисленное',
          'Только регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63637187',
        text:
          'В каком случае допускается проведение сварочных и резательных работ на объектах защиты, в конструкциях которых использованы горючие материалы?',
        answers: [
          'Только после согласования с Государственной противопожарной службой',
          'Допускается в случае, если конструкции ограждены сплошной перегородкой из негорючего материала',
          'Только в случае нахождения на месте работы первичных средств пожаротушения в достаточном объеме',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в случае, если конструкции ограждены сплошной перегородкой из негорючего материала',
        ],
      },
      {
        code: '63637188',
        text:
          'В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?',
        answers: [
          'В белый цвет',
          'В голубой цвет',
          'В желтый цвет',
          'В зеленый цвет',
        ],
        correctAnswers: ['В желтый цвет'],
      },
      {
        code: '63637189',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Предотвращение взрывов и пожаров внутри технологического оборудования',
          'Периодический контроль за состоянием воздушной среды',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
        ],
        correctAnswers: [
          'Предотвращение взрывов и пожаров внутри технологического оборудования',
        ],
      },
      {
        code: '63637190',
        text:
          'При какой скорости падения давления результаты дополнительного пневматического испытания на герметичность признаются удовлетворительными для технологических трубопроводов внутренним диаметром до 250 мм включительно со средами, относящимися к опасным веществам 1-го и 2-го классов опасности?',
        answers: [
          'Не более 0,1% за 1 час',
          'Не более 0,2% за 1 час',
          'Не более 0,8% за 1 час',
          'Не более 0,4% за 1 час',
        ],
        correctAnswers: ['Не более 0,1% за 1 час'],
      },
      {
        code: '63637191',
        text:
          'Какое из обязательных требований к проектированию, техническому перевооружению или реконструкции действующих объектов производства растительных масел указано неверно?',
        answers: [
          'Осуществление оценки взрывоопасности технологических блоков',
          'Определение мест расположения насосного и компрессорного оборудования',
          'Установление вида системы защиты автоматическими установками тушения и обнаружения пожара в соответствии с нормами пожарной безопасности',
          'Установление категории помещений, зданий и наружных установок по взрывопожарной и пожарной опасности',
        ],
        correctAnswers: [
          'Определение мест расположения насосного и компрессорного оборудования',
        ],
      },
      {
        code: '63637192',
        text:
          'Какой инструмент должен применяться во взрывоопасных зонах участков, цехов и помещений? Выберите два правильных варианта ответа.',
        answers: [
          'Из безыскровых материалов',
          'В соответствующем взрывобезопасном исполнении',
          'Инструмент из стали',
          'Из материалов, указанных в распоряжении территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Из безыскровых материалов',
          'В соответствующем взрывобезопасном исполнении',
        ],
      },
      {
        code: '63637193',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: ['II категории', 'I категории', 'III категории', 'На 1 выше'],
        correctAnswers: ['На 1 выше'],
      },
      {
        code: '63637194',
        text:
          'С какими параметрами рабочего давления и вместимости вместе с реципиентами необходимо оснащать кислородопроводы автоматически действующей системой защиты, прекращающей поступление кислорода из реципиентов в трубопровод при нарушении его целостности?',
        answers: [
          'С рабочим давлением более 2,0 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³',
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 160 м³',
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³',
          'С рабочим давлением более 2,0 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 160 м³',
        ],
        correctAnswers: [
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м³',
        ],
      },
      {
        code: '63637195',
        text:
          'В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?',
        answers: [
          'Инспектора Ростехнадзора',
          'Руководителя независимого аттестационного центра',
          'Руководителя организации',
          'Производителя сварочного оборудования',
        ],
        correctAnswers: ['Производителя сварочного оборудования'],
      },
      {
        code: '63637196',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Технический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технологического контроля организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Специальная комиссия организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63637197',
        text:
          'В каком из перечисленных случаев следует предусматривать установку обратных клапанов на насосах, используемых для перекачки гипохлорита натрия?',
        answers: [
          'На всасывающих линиях при подключении нескольких насосов к общему всасывающему коллектору',
          'На нагнетательных линиях дозирующих насосов',
          'Во всех перечисленных случаях',
          'На нагнетательных линиях перекачивающих насосов',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63637198',
        text:
          'Какое количество тормозных башмаков для закрепления различных типов цистерн должно быть установлено для закрепления железнодорожных цистерн при операциях слива, налива растворителя и масла?',
        answers: [
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
          'Не менее 3 на каждой цистерне',
          'Не менее 2 на каждой цистерне',
          'От 2 до 6 в зависимости от типа цистерны',
        ],
        correctAnswers: [
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
        ],
      },
      {
        code: '63637199',
        text:
          'Какие из перечисленных помещений допускается размещать в строящихся зданиях, имеющих не защищенные от огня несущие металлические конструкции и панели с горючими полимерными утеплителями?',
        answers: [
          'Мастерские',
          'Временные складские (кладовые)',
          'Административно-бытовые помещения',
          'Запрещается размещать в таких зданиях все перечисленные помещения',
        ],
        correctAnswers: [
          'Запрещается размещать в таких зданиях все перечисленные помещения',
        ],
      },
      {
        code: '63637200',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'В системах вентиляции должны быть предусмотрены меры и средства, исключающие поступление взрывопожароопасных паров и газов по воздуховодам из одного помещения в другое',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63637201',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
          'Со средой 1-ой группы',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63637202',
        text:
          'Какой системой вентиляции должны быть оснащены производственные помещения при производстве товарного гипохлорита натрия (калия)?',
        answers: [
          'Приточно-вытяжной вентиляцией',
          'Только приточной вентиляцией',
          'Только вытяжной вентиляцией',
        ],
        correctAnswers: ['Приточно-вытяжной вентиляцией'],
      },
      {
        code: '63637203',
        text:
          'Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?',
        answers: [
          'Только операционный контроль',
          'Только входной контроль',
          'Только приемочный контроль',
          'Все перечисленные виды контроля',
        ],
        correctAnswers: ['Все перечисленные виды контроля'],
      },
      {
        code: '63637204',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Только на единицу времени (час)',
          'Только на единицу выпускаемой продукции',
          'Все перечисленные',
          'Только на один производственный поток или на мощность производства в целом',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63637205',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'На факельную установку для сжигания',
          'В закрытые системы для дальнейшей утилизации',
          'В специальные контейнеры',
          'Место направления сбрасываемых химически опасных веществ устанавливается разработчиком проектной документации',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63637206',
        text:
          'Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?',
        answers: [
          'Используемые при сборке приспособления и оборудование',
          'Размеры, количество и расположение прихваток',
          'Порядок и последовательность сборки',
          'Способы крепления деталей',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63637207',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63637208',
        text:
          'Что из перечисленного должно контролироваться в процессе сварки, как минимум?',
        answers: [
          'Соответствия параметров режима сварки и технологических приемов выполнения сварного соединения',
          'Все перечисленное',
          'Очередности выполнения сварных швов и участков наплавки',
          'Отсутствия видимых дефектов',
          'Контроль параметров, предусмотренных в технологических (операционных) картах сварки',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63637209',
        text:
          'Какое количество запорных арматур следует устанавливать на технологических трубопроводах взрывопожароопасных сред для герметичного отключения от коллектора агрегатов (технологических аппаратов) с рабочим давлением (далее - Pр) &gt;= 4 МПа (40 кгс/см²)?',
        answers: [
          'Не регламентируется',
          'Три единицы запорной арматуры',
          'Две единицы запорной арматуры с дренажным устройством между ними',
          'Одну единицу запорной арматуры и дренажную арматуру с заглушкой',
        ],
        correctAnswers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
        ],
      },
      {
        code: '63637210',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Категорией взрывоопасности технологических блоков',
          'Приведенной массой вещества, участвующего во взрыве в кг',
          'Радиусом зон разрушения в м',
          'Энергией сгорания парогазовой фазы в кДж',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63637211',
        text:
          'С кем согласовываются изменения в проекте, потребность в которых выявляется в процессе изготовления, монтажа, реконструкции и ремонта технологических трубопроводов?',
        answers: [
          'С территориальным органом Ростехнадзора',
          'С научно-исследовательской организацией',
          'Изменения в проекте не допускаются',
          'С разработчиком проекта или с другой проектной организацией, имеющей право на проведение указанной работы',
        ],
        correctAnswers: [
          'С разработчиком проекта или с другой проектной организацией, имеющей право на проведение указанной работы',
        ],
      },
      {
        code: '63637212',
        text: 'Каким должен быть радиус кривизны изгибов трубопровода хлора?',
        answers: [
          'Не менее трех диаметров трубы',
          'Не более двух диаметра трубы',
          'Не менее пяти диаметров трубы',
          'Не более четырех диаметров трубы',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63637213',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63637214',
        text:
          'Что из перечисленного допускается при прокладке трубопроводов гипохлорита натрия (калия)? Выберите два правильных варианта ответа.',
        answers: [
          'Повторное использование прокладок для фланцевых соединений',
          'Прокладка трубопроводов гипохлорита натрия (калия) через административные и бытовые помещения',
          'Крепить теплоспутники, закрепляемые без приварки, к трубопроводам, транспортирующим гипохлорит натрия (калия)',
          'Подземная прокладка трубопроводов гипохлорита натрия (калия) в грунте, в коллекторах или непроходных каналах при нецелесообразности применения по технологическим или эксплуатационным условиям надземной прокладки',
        ],
        correctAnswers: [
          'Крепить теплоспутники, закрепляемые без приварки, к трубопроводам, транспортирующим гипохлорит натрия (калия)',
          'Подземная прокладка трубопроводов гипохлорита натрия (калия) в грунте, в коллекторах или непроходных каналах при нецелесообразности применения по технологическим или эксплуатационным условиям надземной прокладки',
        ],
      },
      {
        code: '63637215',
        text:
          'В каком случае допускается одновременное проведение ремонта технических устройств и трубопроводов?',
        answers: [
          'Все ответы неверны',
          'В случае проведения ремонта трубопроводов и арматуры теплого и холодного концов регенераторов',
          'В случае проведения ремонта принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
          'В случае проведения ремонта трубопроводов и арматуры холодного конца регенераторов и системы приказного воздуха переключения регенераторов или механизма переключения',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63637216',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей, обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 1 часа',
          'Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель',
          'Установкой резервного компрессора с автоматическим его включением при остановке рабочего',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63637217',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками профессиональных аварийно-спасательных формирований',
          'Работники, список которых определяется внутренними документами организации',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63637218',
        text:
          'Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?',
        answers: [
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
          'Предохранительные клапаны прямого действия',
          'Любые из перечисленных',
          'Предохранительный клапан с мембранным чувствительным элементом',
        ],
        correctAnswers: [
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
        ],
      },
      {
        code: '63637219',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63637220',
        text:
          'Как необходимо выполнять ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости?',
        answers: [
          'По наряду-допуску',
          'По устному разрешению руководителя смены',
          'Только в выходные дни по письменному разрешению руководителя организации',
          'Только по графикам, разработанным в соответствии со сроками, установленными техническими условиями разработчика оборудования',
        ],
        correctAnswers: ['По наряду-допуску'],
      },
      {
        code: '63637221',
        text:
          'При выполнении каких условий обезжиривание воздухоразделительных установок (далее – ВРУ) в сборе после монтажа или капитального ремонта может не производиться? Выберите два правильных варианта ответа.',
        answers: [
          'Обезжиривание необходимо производить в любом случае',
          'Если все детали, узлы трубопроводов и инструмент, применявшийся при работах, были предварительно очищены от консервирующей смазки и обезжирены',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'Если работы проводились в условиях, исключающих загрязнение внутренних поверхностей оборудования жировыми веществами',
        ],
        correctAnswers: [
          'Если все детали, узлы трубопроводов и инструмент, применявшийся при работах, были предварительно очищены от консервирующей смазки и обезжирены',
          'Если работы проводились в условиях, исключающих загрязнение внутренних поверхностей оборудования жировыми веществами',
        ],
      },
      {
        code: '63637222',
        text:
          'На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются аммиачные трубопроводы аммиачных холодильных установок?',
        answers: [
          'Только на массу трубопровода и массу хладагента, принятых с коэффициентом запаса 1,1',
          'Только на массу хладагента, принятую с коэффициентом запаса 2,2',
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2',
          'Только на массу трубопровода и массу тепловой изоляции, принятых с коэффициентом запаса 1,5',
        ],
        correctAnswers: [
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2',
        ],
      },
      {
        code: '63637223',
        text:
          'В каком случае допускается укладка технологических трубопроводов в два яруса и более?',
        answers: [
          'Диаметром до 300 мм включительно',
          'Диаметром до 400 мм включительно',
          'Диаметром до 500 мм включительно',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Диаметром до 300 мм включительно'],
      },
      {
        code: '63637224',
        text:
          'К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?',
        answers: [
          'К выполнению сварочных работ, указанных в действующих документах',
          'К сварочным работам, на которые они когда-либо были аттестованы независимо от того, действует ли соответствующее аттестационное удостоверение',
          'К выполнению любых сварочных работ при наличии каких-либо действующих аттестационных удостоверений по какому-либо способу сварки',
        ],
        correctAnswers: [
          'К выполнению сварочных работ, указанных в действующих документах',
        ],
      },
      {
        code: '63637225',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63637226',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63637227',
        text:
          'Что должно быть учтено при расчете толщины стенок сосудов, работающих под давлением в среде хлора?',
        answers: [
          'Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах',
          'Только расчетный срок службы',
          'Только расчетное давление и дополнительная прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса',
          'Только дополнительная прибавка на стенки для компенсации коррозии на всех элементах сосуда по исходным данным разработчика процесса',
        ],
        correctAnswers: [
          'Расчетный срок эксплуатации, расчетное давление и прибавка на стенки для компенсации коррозии не менее 1 мм и не менее 2 мм для компенсации коррозии на штуцерах',
        ],
      },
      {
        code: '63637228',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Обозначается (ограждается) место проведения газоопасной работы в пределах площади, где возможно поступление паров и газов опасных веществ',
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'По решению лица, ответственного за подготовку газоопасной работы, выставляются посты в целях исключения допуска посторонних лиц в опасную зону',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63637229',
        text:
          'Кто утверждает годовые и месячные графики ремонта холодильного оборудования?',
        answers: [
          'Начальник службы производственного контроля организации',
          'Руководитель организации',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63637230',
        text:
          'Какое из перечисленных требований к обслуживанию и ремонту технологического оборудования и трубопроводов противоречит Правилам безопасности химически опасных производственных объектов?',
        answers: [
          'Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом',
          'Все аппараты экстракционного цеха должны отключаться от системы технологических трубопроводов с помощью стандартных пронумерованных заглушек с видимыми хвостовиками',
          'Теплообменные поверхности аппаратов (дистилляторов, конденсаторов, теплообменников) должны очищаться от нагара путем щелочения и промывки водой',
          'После завершения указанных операций с аппаратов снимаются крышки люков-лазов и проводятся операции проветривания',
        ],
        correctAnswers: [
          'Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом',
        ],
      },
      {
        code: '63637231',
        text:
          'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны обеспечивать максимально возможную производительность работ и взаимозаменяемость',
          'Оборудование и материалы должны быть взаимозаменяемы при использовании, а также просты в ремонте',
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63637232',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение 1 года со дня его закрытия',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение 1 года со дня его закрытия',
        ],
      },
      {
        code: '63637233',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с разработанным технологическим регламентом',
          'В соответствии с требованиями, установленными Правилами пожарной безопасности',
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63637234',
        text:
          'В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и максимальному снижению тяжести последствий?',
        answers: [
          'В Плане мероприятий по локализации и ликвидации последствий аварий',
          'В техническом регламенте',
          'В технологическом регламенте',
          'Только в положении о производственном контроле',
        ],
        correctAnswers: [
          'В Плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63637235',
        text:
          'Каким образом устанавливается минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?',
        answers: [
          'Расстояние должно определяться на основании проверки устойчивости здания склада к воздействию ударной волны',
          'Расстояние определяется в соответствии с расчетным радиусом опасной зоны',
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
          'Расстояние определяется по нормам пожарной безопасности с учетом требований строительных норм и правил',
        ],
        correctAnswers: [
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
      },
      {
        code: '63637236',
        text:
          'Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах безопасности химически опасных производственных объектов?',
        answers: [
          'Определяется специалистами неразрушающего контроля',
          'Не менее 50% длины сварного шва каждого сварного соединения',
          'Не менее 75% длины сварного шва каждого сварного соединения',
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
        correctAnswers: [
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
      },
      {
        code: '63637237',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов в системах с подогревателями',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63637238',
        text:
          'В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?',
        answers: [
          'Допускается только для аппаратов горизонтального типа',
          'Не допускается ни в каком случае',
          'Допускается только для аппаратов вертикального типа',
          'Допускается в обоснованных в проектной документации случаях',
        ],
        correctAnswers: [
          'Допускается в обоснованных в проектной документации случаях',
        ],
      },
      {
        code: '63637239',
        text:
          'Какой должна быть степень защиты электроприборов и средств автоматического и дистанционного управления, располагающихся в помещениях с аммиачным оборудованием?',
        answers: [
          'Не ниже IP44',
          'Не ниже IP65',
          'Не ниже IP54',
          'Не ниже IP20',
        ],
        correctAnswers: ['Не ниже IP44'],
      },
      {
        code: '63637240',
        text:
          'Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?',
        answers: [
          'Не менее 1,2 м от стены здания',
          'Не менее 1,0 м от стены здания',
          'Не менее 0,5 м от стены здания',
          'Не менее 0,7 м от стены здания',
        ],
        correctAnswers: ['Не менее 1,0 м от стены здания'],
      },
      {
        code: '63637241',
        text:
          'Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Выберите два правильных варианта ответа.',
        answers: [
          'В машинном отделении',
          'Над машинным отделением',
          'В аппаратном отделении',
          'На открытой площадке',
        ],
        correctAnswers: ['В машинном отделении', 'В аппаратном отделении'],
      },
      {
        code: '63637242',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'До окончания работ на конкретном месте',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63637243',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно быть выше давления нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Соотношение давлений теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) не должно быть выше давления нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63637244',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
        ],
        correctAnswers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
        ],
      },
      {
        code: '63637245',
        text:
          'С какой целью склады хлора оборудуются сплошным глухим ограждением высотой не менее двух метров?',
        answers: [
          'Для защиты территории склада от ветра',
          'Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада',
          'Для исключения случаев хищения жидкого хлора',
        ],
        correctAnswers: [
          'Для ограничения распространения газовой волны в начальный период аварийной ситуации и исключения доступа посторонних лиц на территорию склада',
        ],
      },
      {
        code: '63637246',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63637247',
        text:
          'В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Выберите два правильных варианта ответа.',
        answers: [
          'Не допускается в любом случае',
          'В случае размещения холодильного оборудования над площадками на расстоянии, определенном соответствующей проектной документацией',
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
        correctAnswers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
      },
      {
        code: '63637248',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, при производстве ремонта турбодетандеров или при замене фильтрующих элементов детандерных фильтров, указаны неверно?',
        answers: [
          'Электропитание должно быть отключено',
          'На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера',
          'Арматура должна быть закрыта на входе воздуха в турбодетандер и на выходе из него',
          'На азотных турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера. Перед ремонтом эти турбодетандеры необходимо продувать воздухом',
        ],
        correctAnswers: [
          'На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера',
        ],
      },
      {
        code: '63637249',
        text:
          'Нахождение каких стационарных систем трубопроводов на пунктах слива-налива жидкого хлора не предусмотрено Правилами безопасности производств хлора и хлорсодержащих сред?',
        answers: [
          'Трубопроводов для слива-налива жидкого хлора',
          'Трубопроводов для подачи в вагон-цистерну сжатого воздуха (азота) или хлора для передавливания',
          'Трубопроводов пара и горячей воды для подогрева',
          'Трубопроводов для отвода газообразного хлора на потребление или поглощение',
        ],
        correctAnswers: ['Трубопроводов пара и горячей воды для подогрева'],
      },
      {
        code: '63637250',
        text:
          'Какого показателя категорий взрывоопасности технологических блоков не существует?',
        answers: ['I', 'II', 'III', 'IV'],
        correctAnswers: ['IV'],
      },
      {
        code: '63637251',
        text: 'О чем сигнализирует красная лампа световой сигнализации?',
        answers: [
          'Сигнал об опасном повышении уровня жидкого аммиака в сосуде (предупредительная сигнализация)',
          'Сигнал о понижении верхнего уровня жидкого аммиака в сосуде (предаварийная сигнализация)',
          'Сигнал об аварийной остановке аппарата',
          'Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)',
        ],
        correctAnswers: [
          'Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)',
        ],
      },
      {
        code: '63637252',
        text:
          'Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?',
        answers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
          'Должны быть просвечены рентгеном',
          'Должны быть обезжирены водными смывками',
        ],
        correctAnswers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
        ],
      },
      {
        code: '63637253',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Специализированной сторонней организацией',
          'Организацией - разработчиком процесса',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63637254',
        text:
          'На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?',
        answers: [
          'На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди',
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия',
        ],
        correctAnswers: [
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
        ],
      },
      {
        code: '63637255',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Начальнику цеха',
          'Пожарной службе эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63637256',
        text:
          'Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только организовать проведение операционного контроля',
          'Все перечисленные функции',
          'Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки',
          'Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63637257',
        text:
          'При строительстве какого объекта защиты следует применять инвентарные металлические строительные леса?',
        answers: [
          'При строительстве объекта защиты в 3 этажа и более',
          'При строительстве объекта защиты в 1 этаж',
          'При строительстве любого объекта защиты',
          'При строительстве объекта защиты не более 2 этажей',
        ],
        correctAnswers: ['При строительстве объекта защиты в 3 этажа и более'],
      },
      {
        code: '63637258',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Не допускается',
          'Допускается при наличии разрешающих внутренних документов организации, определяющих порядок использования электронной подписи',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63637259',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: не в фазе!"',
          '"Не включать: работа на линии!"',
          '"Не включать: работают люди!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63637260',
        text:
          'Чем следует оборудовать производственные помещения без постоянных рабочих мест согласно Правилам безопасности производств хлора и хлорсодержащих сред?',
        answers: [
          'Данные помещения следует оборудовать аварийной или общеобменными вентиляционными системами',
          'Снаружи у входа в помещение необходимо предусматривать световую сигнализацию превышения уровня загазованности хлором в помещении',
          'При производстве ремонтных работ обогрев этих помещений должен быть осуществлен передвижными вентиляционно-отопительными устройствами',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63637261',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63637262',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 8 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
          'В течение времени, обозначенного в проектной документации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63637263',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63637264',
        text:
          'Каким образом должны подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств для оборудования (аппаратов и трубопроводов) до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63637265',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Качество сжатого воздуха должно проверяться не реже одного раза в месяц',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования должно определяться конкретными условиями и потребностями объекта',
          'Для пневматических систем контроля, управления и противоаварийной защиты должны предусматриваться единые установки и единые сети сжатого воздуха',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63637266',
        text:
          'Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?',
        answers: [
          'Vз.р. больше Vс x 0,7 м³',
          'Vз.р. больше Vс x 1,1 м³',
          'Vз.р. больше Vс x 0,5 м³',
          'Vз.р. больше Vс x 0,4 м³',
        ],
        correctAnswers: ['Vз.р. больше Vс x 0,5 м³'],
      },
      {
        code: '63637267',
        text:
          'При достижении какой концентрации хлора должна включаться аварийная вентиляция, сблокированная с системой поглощения хлора, в помещениях, где обращается жидкий хлор?',
        answers: ['20 ПДК', '2 ПДК', '10 ПДК', '15 ПДК'],
        correctAnswers: ['20 ПДК'],
      },
      {
        code: '63637268',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63637269',
        text: 'Каким принимают радиус опасной зоны для складов жидкого хлора?',
        answers: [
          'В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)',
          'В пределах глубины распространения хлорного облака со средней концентрацией (определяется по факту распространения)',
          'В пределах распространения хлорного облака со средней концентрацией, но не более 1500 м',
          'В пределах распространения хлорного облака с минимальной концентрацией, но не менее 200 м',
        ],
        correctAnswers: [
          'В пределах глубины распространения хлорного облака с поражающей концентрацией (определяется расчетом)',
        ],
      },
      {
        code: '63637270',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
          'Проверку умения определять и устранять видимые и скрытые дефекты сварного соединения',
          'Проверку знания теоретических основ сварочного дела',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63637271',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63637272',
        text:
          'В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?',
        answers: [
          'Наблюдение не требуется',
          'В течение всего времени нахождения',
          'В течение периода приемки цистерны',
          'В течение слива аммиака',
        ],
        correctAnswers: ['В течение всего времени нахождения'],
      },
      {
        code: '63637273',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На организацию - разработчика процесса',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На технологическую службу организации, производства, отделения, установки',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63637274',
        text:
          'Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном в организации',
          'Системы канализации технологических объектов должны исключать аварийные выбросы стоков в магистральную сеть',
          'Системы канализации технологических объектов в обязательном порядке должны быть оборудованы звуковой и световой сигнализацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63637275',
        text:
          'Какая допускается максимальная рабочая температура технологического трубопровода при прокладке в грунте?',
        answers: ['150 °C', '180 °C', '200 °C', '250 °C'],
        correctAnswers: ['150 °C'],
      },
      {
        code: '63637276',
        text:
          'Как должна производиться инструментальная проверка эффективности работы вентиляционных систем?',
        answers: [
          'Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации',
          'Только после каждого капитального ремонта вентиляционных систем с оформлением акта проверки',
          'Только после реконструкции вентиляционных систем',
          'Не реже одного раза в полгода с оформлением акта проверки',
        ],
        correctAnswers: [
          'Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации',
        ],
      },
      {
        code: '63637277',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В паспортах оборудования и трубопроводной арматуры',
          'В проектной документации',
          'В технологическом регламенте',
          'В техническом задании на изготовление оборудования',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63637278',
        text:
          'Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами контроля за плотностью поступающих на разделение продукта и флегмы',
          'Средствами контроля за компонентным составом поступающего не разделение продукта',
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
        ],
        correctAnswers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
        ],
      },
      {
        code: '63637279',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Должны выполняться все перечисленные мероприятия',
          'Только испытания на прочность пробным давлением',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63637280',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания определяется расчетом для технологических блоков I категории взрывоопасности и установлено для блоков II и III категорий',
          'Время срабатывания установлено для каждого из технологических блоков и зависит от категории взрывоопасности',
          'Время срабатывания определяется расчетом для технологических блоков II и III категорий взрывоопасности и установлено для блоков I категории',
          'Время срабатывания определяется расчетом',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63637281',
        text:
          'Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?',
        answers: [
          'Из стали',
          'Из чугуна',
          'Из неметаллических материалов',
          'Из сплавов титана',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63637282',
        text:
          'В каком месте может размещаться оборудование, работающее на аммиаке?',
        answers: [
          'В машинном или аппаратном отделении',
          'Во всех перечисленных местах',
          'В помещении потребителей холода',
          'На открытой площадке',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63637283',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с разработанным технологическим регламентом',
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с требованиями, установленными в Правилах противопожарного режима',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63637284',
        text:
          'Какое из перечисленных требований к испытанию на прочность после монтажа до пуска в эксплуатацию сосуда (аппарата), трубопровода (его участка) указано верно?',
        answers: [
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
          'Толщина заглушки должна быть рассчитана на условия работы при давлении выше пробного в 1,25 раза',
          'Использование запорной арматуры для отключения испытываемого сосуда и трубопровода допускается в присутствии работника, ответственного за исправное состояние и безопасную работу сосудов',
          'При периодическом освидетельствовании и техническом диагностировании с использованием метода акустической эмиссии и аммиака в качестве нагружающей среды не допускается испытывать отдельные технологические линии блоком',
        ],
        correctAnswers: [
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
        ],
      },
      {
        code: '63637285',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Поддержание положительной (выше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Пневматическое испытание должно проводиться только в условиях освещенности площадки проведения работ, достаточной для наблюдения за испытываемым оборудованием',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63637286',
        text:
          'Работы в каких помещениях и местах для объектов производства и потребления продуктов разделения воздуха не относятся к работам повышенной опасности?',
        answers: [
          'На площадках с оборудованием, расположенным вне здания, и сооружениях вблизи сбросных трубопроводов или на расстоянии 15,0 м от систем азотно-водяного охлаждения',
          'В трубопроводах, клапанах, колодцах, закрытых траншеях, приямках газгольдеров',
          'В подвальных помещениях низкотемпературных технических устройств (воздухоразделительных установках, криогенных комплексах, хранилищах жидких продуктов разделения воздуха)',
          'Во внутриблочном пространстве и отсеках низкотемпературных технических устройств',
        ],
        correctAnswers: [
          'На площадках с оборудованием, расположенным вне здания, и сооружениях вблизи сбросных трубопроводов или на расстоянии 15,0 м от систем азотно-водяного охлаждения',
        ],
      },
      {
        code: '63637287',
        text:
          'В каком случае допускаются отклонения от действующего проекта, на основании которого проводятся работы, связанные с восстановлением работоспособного (исправного) состояния технологических трубопроводов?',
        answers: [
          'Не допускаются ни в каком случае',
          'В случае согласования необходимого отклонения с территориальным органом Ростехнадзора',
          'По решению комиссии эксплуатирующей организации',
          'При наличии письменного распоряжения технического руководителя эксплуатирующей организации',
        ],
        correctAnswers: ['Не допускаются ни в каком случае'],
      },
    ],
    63629: [
      {
        code: '63629000',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей технологической системы, и нанесенное на нем обозначение номера по технологической документации должно быть на схеме закрашено',
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих технологических систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
        ],
      },
      {
        code: '63629001',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы находились на консервации более одного года',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63629002',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На технологическую службу организации, производства, отделения, установки',
          'На организацию - разработчика процесса',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63629003',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
        ],
      },
      {
        code: '63629004',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Специализированной сторонней организацией',
          'Организацией - разработчиком процесса',
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63629005',
        text:
          'Какие параметры должны регламентироваться в процессах смешивания при возможности развития самоускоряющихся экзотермических реакций?',
        answers: [
          'Только последовательность и допустимые количества загружаемых в аппаратуру веществ',
          'Только скорость сгрузки (поступления) реагентов',
          'Только подача флегматизирующих агентов',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63629006',
        text:
          'Какие требования установлены к насосам для перекачки растворов коллоксилина?',
        answers: [
          'Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности',
          'Насосы должны быть быстроходными и соответствовать требованиям токсической безопасности',
          'Для насосов, расположенных в зданиях, необходимо предусматривать их дистанционное отключение с внешней стороны помещения насосной',
        ],
        correctAnswers: [
          'Насосы должны быть тихоходными и соответствовать требованиям токсической безопасности',
        ],
      },
      {
        code: '63629007',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 6',
          'Не менее 10',
          'Не менее 15',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629008',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Иметь документы о прохождении аттестации',
          'Всем перечисленным требованиям',
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63629009',
        text:
          'На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Выберите два правильных варианта ответа.',
        answers: [
          'На объектах ХОПО I класса опасности',
          'На объектах ХОПО II класса опасности',
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО IV класса опасности',
          'На всех объектах ХОПО',
        ],
        correctAnswers: [
          'На объектах ХОПО III класса опасности',
          'На объектах ХОПО IV класса опасности',
        ],
      },
      {
        code: '63629010',
        text:
          'На каком расстоянии от опор и подвесок следует располагать сварные стыки трубопроводов для труб диаметром менее 50 мм?',
        answers: [
          'Не менее 250 мм',
          'Не менее 200 мм',
          'Не менее 150 мм',
          'Не менее 100 мм',
        ],
        correctAnswers: ['Не менее 100 мм'],
      },
      {
        code: '63629011',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?',
        answers: [
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Не более 300 секунд',
          'Не более 240 секунд',
        ],
        correctAnswers: ['Не более 12 секунд'],
      },
      {
        code: '63629012',
        text:
          'При каких параметрах экстракционной установки остановка цеха не требуется?',
        answers: [
          'При падении давления и температуры пара на коллекторах',
          'При падении давления воды в циркуляционной системе',
          'При падении давления сжатого воздуха ниже 0,2 МПа (2 кгс/см²) для питания систем контроля и управления системы противоаварийной защиты',
          'При повышении концентрации паров растворителя в воздушной среде цеха до 20% от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'При повышении концентрации паров растворителя в воздушной среде цеха до 20% от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63629013',
        text:
          'Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?',
        answers: [
          'Предохранительный клапан с мембранным чувствительным элементом',
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
          'Предохранительные клапаны прямого действия',
          'Любые из перечисленных',
        ],
        correctAnswers: [
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
        ],
      },
      {
        code: '63629014',
        text:
          'Какая устанавливается минимальная высота гидрозатвора в приемном баке при гидравлическом способе удаления пыли из электрофильтров при производстве фосфора и его соединений?',
        answers: [
          '100 мм с учетом конуса, образующегося при работе мешалки',
          '300 мм с учетом конуса, образующегося при работе мешалки',
          '200 мм с учетом конуса, образующегося при работе мешалки',
        ],
        correctAnswers: [
          '200 мм с учетом конуса, образующегося при работе мешалки',
        ],
      },
      {
        code: '63629015',
        text:
          'Какая максимальная объемная доля аммиака в межстенном пространстве резервуара во время эксплуатации?',
        answers: ['2%', '0,5%', '1%', '1,5%'],
        correctAnswers: ['0,5%'],
      },
      {
        code: '63629016',
        text:
          'С какой периодичностью проводится ревизия и ремонт предохранительных клапанов резервуаров жидкого аммиака со снятием их с мест установки, проверкой и настройкой на стенде?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 6 месяцев',
          'В сроки, установленные технологическим регламентом и эксплуатационной документацией',
          'Ежегодно',
        ],
        correctAnswers: [
          'В сроки, установленные технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63629017',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с проектной документацией',
          'В соответствии с декларацией промышленной безопасности',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63629018',
        text:
          'Что предусматривается для максимального снижения выбросов в окружающую среду растворителя в виде паров и жидкой фазы при аварийной разгерметизации системы в технологических системах объектов производств растительных масел для технологических блоков I категории взрывоопасности?',
        answers: [
          'Установка запорных и (или) отсекающих устройств с дистанционным управлением и временем срабатывания не более 120 секунд',
          'Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 секунд',
          'Установка запорных устройств с ручным приводом, при этом предусматривается минимальное время приведения их в действие за счет рационального размещения (максимально допустимого приближения к рабочему месту оператора), но не более 300 секунд',
        ],
        correctAnswers: [
          'Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 секунд',
        ],
      },
      {
        code: '63629019',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 5 лет',
          'Не более 20 лет',
          'Не более 3 лет',
          'Не более 10 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63629020',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63629021',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Надежное энергообеспечение',
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63629022',
        text:
          'Какие меры безопасности должны соблюдаться при нахождении фосфора и фосфорного шлама в аппаратах?',
        answers: [
          'Аппараты должны быть герметичны',
          'Аппараты должны быть заполнены инертным газом',
          'В аппаратах фосфор и фосфорный шлам должны находиться под слоем воды не менее 300 мм',
          'В аппаратах фосфор и фосфорный шлам должны находиться под слоем воды не менее 100 мм',
        ],
        correctAnswers: [
          'В аппаратах фосфор и фосфорный шлам должны находиться под слоем воды не менее 300 мм',
        ],
      },
      {
        code: '63629023',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На сети водоснабжения и канализации',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63629024',
        text:
          'Какое из перечисленных требований к феррофосфорам указано верно?',
        answers: [
          'Под феррофосфорными летками в перерывах между выпусками феррофосфора должен быть установлен ковш',
          'После уборки феррофосфора из приямков наличие в них влаги допускается в соответствии с инструкцией, утвержденной техническим руководителем организации',
          'В аварийных случаях феррофосфор сливают в аварийные приямки или в аварийные емкости, где по истечении 3 часов после слива его охлаждают водой',
          'Во время охлаждения феррофосфора водой допускается попадание в приямок жидкого феррофосфора из печи',
        ],
        correctAnswers: [
          'Под феррофосфорными летками в перерывах между выпусками феррофосфора должен быть установлен ковш',
        ],
      },
      {
        code: '63629025',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Только об организации, выполнившей функции генерального проектировщика',
          'Только об организации - разработчике технологической части проектной документации',
          'Только об организации - разработчике технологического процесса',
          'Только об организации, выполнившей проектную документацию',
          'О всех перечисленных организациях',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63629026',
        text:
          'Какие действия должны быть предприняты при обнаружении нарушений требований Правил безопасности химически опасных производственных объектов в отношении цистерн с жидким аммиаком?',
        answers: [
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
          'Аммиак должен быть немедленно слит',
          'Необходимо поставить круглосуточное наблюдение за цистернами до устранения нарушений',
          'Немедленно сообщить в территориальный орган Ростехнадзора',
        ],
        correctAnswers: [
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
        ],
      },
      {
        code: '63629027',
        text:
          'Для каких блоков объектов производств растительных масел допускается применение автоматических средств контроля параметров, значения которых определяют взрывоопасность процесса, и ручного регулирования?',
        answers: [
          'Для блоков, имеющих относительный энергетический потенциал Qв меньше 10',
          'Для блоков I категории',
          'Для блоков II категории',
          'Для блоков III категории',
        ],
        correctAnswers: [
          'Для блоков, имеющих относительный энергетический потенциал Qв меньше 10',
        ],
      },
      {
        code: '63629028',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Технологические схемы производства',
          'Возможные инциденты в работе и способы их ликвидации',
          'Характеристика производимой продукции',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63629029',
        text:
          'Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?',
        answers: [
          'Расположенного на уровне выше 1,5 м от пола',
          'Расположенного на уровне не ниже 2,0 м от пола',
          'Расположенного на уровне выше 1,8 м от пола',
          'Расположенного на уровне, определенном проектной документацией',
        ],
        correctAnswers: ['Расположенного на уровне выше 1,8 м от пола'],
      },
      {
        code: '63629030',
        text:
          'В каких теплообменных аппаратах объектов производств растительных масел допускается возможность взаимного проникновения пара (воды) и нагреваемого (охлаждаемого) продукта?',
        answers: [
          'В оборудовании, где это предусматривается технологией',
          'В подогревателях',
          'В дистилляторах',
          'Во всех перечисленных аппаратах',
        ],
        correctAnswers: [
          'В оборудовании, где это предусматривается технологией',
        ],
      },
      {
        code: '63629031',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'В случае использования специальных систем аварийного освобождения',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63629032',
        text:
          'Кто устанавливает значения параметров процесса и допустимых диапазонов их изменения, исключающих возможность возникновения опасных отклонений, способных стать причиной аварийной ситуации или аварии на объектах, связанных с производством растительных масел?',
        answers: [
          'Разработчик процесса',
          'Главный технолог',
          'Технический руководитель эксплуатирующей организации',
          'Проектная организация',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63629033',
        text:
          'Какие материалы допускается перемещать на ленточных транспортерах?',
        answers: [
          'Только свинец- и хромосодержащие пигменты',
          'Только высокотоксичные материалы в сухом виде',
          'Только высокотоксичные материалы в пастообразном виде',
          'Допускается перемещать все перечисленные материалы',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629034',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 10 минут',
          'Через 30 минут',
          'Через 60 минут',
          'Через 5 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63629035',
        text:
          'Кто определяет график контроля воздушной среды в подвальных и полуподвальных помещениях масличного сырья, галереях, туннелях и приямках, связанных с транспортированием масличного сырья?',
        answers: [
          'Руководитель структурного подразделения',
          'Технический руководитель организации',
          'Начальник складского помещения',
          'Ответственный за охрану труда',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63629036',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'В соответствии с проектной документацией',
          'Технологическим руководителем организации',
          'Комиссией организации под председательством представителя проектной организации',
          'Лицом, утверждающим технологический регламент',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63629037',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63629038',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в исходных данных на проектирование',
          'Только в проектной документации',
          'Только в технологическом регламенте',
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63629039',
        text:
          'Каким образом устанавливается минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?',
        answers: [
          'Расстояние определяется по нормам пожарной безопасности',
          'Расстояние определяется в соответствии с требованиями строительных норм и правил и с учетом расчетного радиуса опасной зоны',
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения и должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
          'Расстояние должно быть обусловлено только устойчивостью здания склада к воздействию ударной волны',
        ],
        correctAnswers: [
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения и должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
      },
      {
        code: '63629040',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63629041',
        text:
          'Учитывается ли вместимость резервного резервуара при определении вместимости склада в процессе хранения аммиака под давлением?',
        answers: [
          'Учитывается только при хранении аммиака в резервуарах под давлением, близким к атмосферному',
          'Учитывается только при хранении аммиака в резервуарах под давлением до 2,0 МПа включительно',
          'Учитывается',
          'Не учитывается',
        ],
        correctAnswers: ['Не учитывается'],
      },
      {
        code: '63629042',
        text:
          'Чему соответствует вместимость поддонов, которыми оснащается емкостное оборудование для использования кислот и (или) щелочей объемом 1000 л и более?',
        answers: [
          'Объему всего расположенного в них оборудования',
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
          '80% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей',
          '50% емкости всех расположенных в поддоне или на площадке с бортиками аппаратов и емкостей',
        ],
        correctAnswers: [
          'Объему, достаточному для содержания одного аппарата максимальной емкости в случае его аварийного разрушения',
        ],
      },
      {
        code: '63629043',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 10 лет',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63629044',
        text:
          'Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?',
        answers: [
          'Стальная арматура и фасонные части',
          'Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди, цинка и их сплавов',
          'Стальная арматура и фасонные части, а также допускается применение запорной арматуры из ковкого и высокопрочного чугуна',
          'Стальная арматура и фасонные части, а также допускается применение чугунной запорно-регулирующей арматуры',
        ],
        correctAnswers: ['Стальная арматура и фасонные части'],
      },
      {
        code: '63629045',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63629046',
        text:
          'Какими устройствами не должны оснащаться насосы, применяемые для нагнетания легковоспламеняющихся и горючих жидкостей (масло растительное и минеральное, мисцелла, растворитель)?',
        answers: [
          'Средствами предупредительной сигнализации о нарушении параметров работы, влияющих на безопасность',
          'Устройствами, регулирующими частоту вращения вала',
          'Блокировками, исключающими пуск и работу насоса "всухую" или прекращающими работу насоса при падении давления перемещаемой жидкости в нагнетательном патрубке насоса ниже установленного регламентом или паспортными данными или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений (верхний и нижний уровни)',
          'Средствами местного и дистанционного отключения, расположенными в легкодоступных местах',
        ],
        correctAnswers: ['Устройствами, регулирующими частоту вращения вала'],
      },
      {
        code: '63629047',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63629048',
        text:
          'При какой температуре поверхности аппаратов, находящихся в помещении, должны быть теплоизолированы несгораемыми материалами при производстве фосфора и его соединений?',
        answers: [
          '25 °C и выше',
          '35 °C и выше',
          '45 °C и выше',
          'Теплоизоляция не предполагается ни в каком случае',
        ],
        correctAnswers: ['45 °C и выше'],
      },
      {
        code: '63629049',
        text:
          'Какое из перечисленных требований к арматурам и трубопроводам жидкого аммиака указано верно?',
        answers: [
          'Защитные устройства следует устанавливать после запорной арматуры на трубопроводе подачи аммиака',
          'Резервуары для хранения жидкого аммиака следует отключать от трубопроводов одним запорным органом',
          'Арматура, расположенная непосредственно у шаровых, изотермических и горизонтальных резервуаров вместимостью 50 т и более, должна иметь дистанционное и ручное управление',
          'Все перечисленные требования указаны верно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629050',
        text:
          'Чем должен быть продут изотермический резервуар перед наливом в него жидкого аммиака?',
        answers: [
          'Азотом',
          'Кислородом',
          'Газообразным аммиаком',
          'Инертным газом',
        ],
        correctAnswers: ['Газообразным аммиаком'],
      },
      {
        code: '63629051',
        text:
          'Какие требования к подшипниковым узлам шнековых питателей, ленточных конвейеров, норий, скребковых конвейеров, валов, винтовых конвейеров и перемешивающих устройств экстракторов, тостеров объектов производств растительных масел указаны верно?',
        answers: [
          'Должны находиться в зоне видимости оператора установки',
          'Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов',
          'Должны проходить ежесменный контроль состояния',
          'Должны смазываться перед началом смены',
        ],
        correctAnswers: [
          'Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов',
        ],
      },
      {
        code: '63629052',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равном рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
        ],
        correctAnswers: ['Под давлением, равном рабочему, но не более 4 МПа'],
      },
      {
        code: '63629053',
        text:
          'В каких условиях должна проводиться реакция получения фосфида цинка?',
        answers: [
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воздуха в реактор',
          'Перед установкой реактора необходимо включить муфель',
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор',
          'Перед дозировкой фосфора реактор должен быть тщательно продут инертным газом в течение не более 3 минут',
        ],
        correctAnswers: [
          'Мерник для фосфора должен быть снабжен устройством, предотвращающим попадание воды в реактор',
        ],
      },
      {
        code: '63629054',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Ростехнадзором',
          'Эксплуатирующими организациями',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63629055',
        text:
          'Какое содержание кислорода допускается при анализе продувочного газа после продувки экстракционной линии?',
        answers: [
          'Не более 7%',
          'Не более 5%',
          'Не более 10%',
          'Требование к содержанию кислорода устанавливается в технологическом регламенте',
        ],
        correctAnswers: ['Не более 7%'],
      },
      {
        code: '63629056',
        text:
          'Какое действие необходимо предпринять в случае перерыва слива аммиака?',
        answers: [
          'Цистерна остается присоединенной к системе под наблюдением работников, назначенных приказом по организации',
          'На время перерыва допускается оставлять цистерну присоединенной к системе',
          'По усмотрению лица, ответственного за производство работ по сливу аммиака',
          'Съемные участки трубопроводов должны быть отсоединены от цистерн',
        ],
        correctAnswers: [
          'Съемные участки трубопроводов должны быть отсоединены от цистерн',
        ],
      },
      {
        code: '63629057',
        text:
          'Какие действия должны быть выполнены при остановке чанного испарителя (тостера) во избежание возможного обугливания в нем шрота и возникновения аварийной ситуации?',
        answers: [
          'Подача острого пара во все чаны и загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)',
          'Отключение подачи глухого пара',
          'Загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)',
        ],
        correctAnswers: ['Отключение подачи глухого пара'],
      },
      {
        code: '63629058',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом главного инженера организации (технического директора, директора по производству)',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом руководителя организации',
          'Приказом комиссии организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63629059',
        text:
          'Какое из перечисленных требований к расположению цистерн с жидким аммиаком в организации указано верно?',
        answers: [
          'До и во время проведения слива цистерна должна ограждаться переносными сигналами и должен быть установлен знак размером 300 × 400 мм с надписью "Осторожно! Слив аммиака"',
          'Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны',
          'На внутренних железнодорожных путях организации, не имеющих стрелочных переводов, должен устанавливаться затворный предохранительный брус на расстоянии не менее 5 м от цистерны',
          'Колеса цистерны на рельсовом пути должны быть закреплены и с одной стороны подклинены тормозными башмаками',
        ],
        correctAnswers: [
          'Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны',
        ],
      },
      {
        code: '63629060',
        text:
          'На каком расстоянии со стороны зданий и открытых установок, примыкающих к ограждению резервуаров для хранения жидкого аммиака, допускается располагать объезд?',
        answers: [
          'Не более 39 м от ограждения резервуаров',
          'Не более 40 м от ограждения резервуаров',
          'Не более 50 м от ограждения резервуаров',
          'Не более 45 м от ограждения резервуаров',
        ],
        correctAnswers: ['Не более 39 м от ограждения резервуаров'],
      },
      {
        code: '63629061',
        text:
          'Какие требования к условиям устойчивой работы объектов производств масел указаны неверно?',
        answers: [
          'Обеспечение электроэнергией, паром, водой, сжатым воздухом, инертным газом (азотом)',
          'Обеспечение производства системами контроля и регулирования технологических параметров в заданном диапазоне',
          'Проведение своевременной диспансеризации технического персонала',
          'Проведение своевременного диагностирования технического состояния технологического оборудования',
        ],
        correctAnswers: [
          'Проведение своевременной диспансеризации технического персонала',
        ],
      },
      {
        code: '63629062',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для напорных трубопроводов',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63629063',
        text:
          'Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке технологического оборудования?',
        answers: [
          'Наличие оросительных систем',
          'Наличие средств гидравлической, механической или химической чистки',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Возможность изоляции соседнего оборудования',
        ],
        correctAnswers: [
          'Наличие средств гидравлической, механической или химической чистки',
        ],
      },
      {
        code: '63629064',
        text:
          'Каким должен быть остаточный слой материалов при разгрузке приемных бункеров для предотвращения поступления запыленного воздуха в помещение при производстве фосфора и его соединений?',
        answers: [
          'На 1,1 м выше разгрузочного проема',
          'На 0,8 м выше разгрузочного проема',
          'На 0,7 м выше разгрузочного проема',
          'На 0,5 м выше разгрузочного проема',
        ],
        correctAnswers: ['На 0,7 м выше разгрузочного проема'],
      },
      {
        code: '63629065',
        text:
          'Какие сроки освидетельствования систем холодоснабжения с ограниченной зарядкой аммиаком (не более 50 кг), поставляемых комплектно организациями-изготовителями?',
        answers: [
          'Не реже 1 раза в год',
          'Устанавливаются лицом, ответственным за исправное состояние и безопасную работу сосудов (аппаратов)',
          'Устанавливаются Правилами безопасности химически опасных производственных объектов',
          'Устанавливаются эксплуатационной документацией организаций-изготовителей',
        ],
        correctAnswers: [
          'Устанавливаются эксплуатационной документацией организаций-изготовителей',
        ],
      },
      {
        code: '63629066',
        text:
          'С какой периодичностью проводятся наружный осмотр и испытание пробным давлением при техническом освидетельствовании трубопроводов? Выберите два правильных варианта ответа.',
        answers: [
          'Не реже 1 раза в 2 года',
          'По окончании монтажных работ перед пуском в эксплуатацию',
          'Не реже 1 раза в 8 лет',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: [
          'По окончании монтажных работ перед пуском в эксплуатацию',
          'Не реже 1 раза в 8 лет',
        ],
      },
      {
        code: '63629067',
        text:
          'С какой периодичностью должно проводиться техническое освидетельствование сосудов и аппаратов холодильных установок?',
        answers: [
          'Периодичность устанавливается только распорядительными документами эксплуатирующей организации',
          '1 раз перед пуском в работу',
          'Не реже 1 раза в 15 лет',
          'Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением',
        ],
        correctAnswers: [
          'Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением',
        ],
      },
      {
        code: '63629068',
        text:
          'В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?',
        answers: [
          'Для одноступенчатых компрессоров',
          'Для ступени высокого давления двухступенчатых компрессоров',
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
        correctAnswers: [
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
      },
      {
        code: '63629069',
        text:
          'Какие требования к газоходу, соединяющему тостер и мокрую шротоловушку, указаны неверно?',
        answers: [
          'Должен иметь подвод пара для пропаривания газохода и шротоловушки перед вскрытием их для осмотра или ремонта',
          'Должен иметь лючки в торцах для ревизии и очистки внутренней поверхности',
          'Должен иметь уклон в сторону мокрой шротоловушки не менее 2 см на 1 м трубы газохода',
          'Должен быть оборудован показывающим манометром',
        ],
        correctAnswers: ['Должен быть оборудован показывающим манометром'],
      },
      {
        code: '63629070',
        text:
          'В каких условиях должна проводиться реакция синтеза пятисернистого фосфора?',
        answers: [
          'В герметичных аппаратах в атмосфере инертного газа',
          'В герметичной таре',
          'В охлаждаемых аппаратах, снабженных устройствами для отвода тепла',
          'В аппаратах, обогреваемых паром',
        ],
        correctAnswers: ['В герметичных аппаратах в атмосфере инертного газа'],
      },
      {
        code: '63629071',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Осуществляется все перечисленное',
          'Испытание трубопровода на прочность и плотность',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63629072',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63629073',
        text:
          'Какие меры безопасности должны соблюдаться при хранении и перекачке фосфора и фосфорного шлама?',
        answers: [
          'Температура фосфора и фосфорного шлама не должна быть более 80 °С',
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены приборами контроля давления пара',
          'Паропроводы, подводящие острый пар для разогрева фосфора и поддержания его в расплавленном состоянии, должны быть оснащены устройствами ("воздушками") для предотвращения образования вакуума и попадания фосфора в паропровод',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63629074',
        text: 'О чем сигнализирует красная лампа световой сигнализации?',
        answers: [
          'Сигнал об аварийной остановке аппарата',
          'Сигнал об опасном понижении верхнего уровня жидкого аммиака в сосуде (предаварийная сигнализация)',
          'Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)',
          'Сигнал об опасном повышении верхнего уровня жидкого аммиака в сосуде (предупредительная сигнализация)',
        ],
        correctAnswers: [
          'Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)',
        ],
      },
      {
        code: '63629075',
        text:
          'При какой вместимости резервуаров, работающих под избыточным внутренним давлением, допускается применение подогревательных устройств, размещаемых внутри или на наружной поверхности резервуаров?',
        answers: [
          'Не более 60 т',
          'Не более 50 т',
          'Не более 100 т',
          'Не более 80 т',
        ],
        correctAnswers: ['Не более 50 т'],
      },
      {
        code: '63629076',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63629077',
        text:
          'Каким образом обеспечивается противоаварийная защита от превышения уровня аммиака сверх допустимого в резервуарах вместимостью до 50 м³?',
        answers: [
          'Дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния',
          'Дублированием систем контроля параметров',
          'Наличием систем самодиагностики',
          'Сопоставлением технологически связанных параметров',
        ],
        correctAnswers: [
          'Дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния',
        ],
      },
      {
        code: '63629078',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63629079',
        text:
          'В течение какого минимального отрезка времени сосуд (аппарат), трубопровод должен находиться под пробным давлением, после чего давление постепенно должно быть снижено до расчетного, при котором проводится осмотр наружной поверхности сосуда (аппарата, трубопровода) с проверкой плотности его швов и разъемных соединений мыльным раствором или другим способом?',
        answers: [
          'В течение 20 минут',
          'В течение 10 минут',
          'В течение 15 минут',
          'Не регламентируется',
        ],
        correctAnswers: ['В течение 15 минут'],
      },
      {
        code: '63629080',
        text:
          'Каким должно быть минимальное остаточное избыточное давление в транспортировочных емкостях аммиака при их полном опорожнении?',
        answers: ['0,01 МПа', '0,02 МПа', '0,05 МПа', '0,03 МПа'],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63629081',
        text:
          'В каком случае необходимо контролировать величину и равномерность осадки фундаментов шаровых резервуаров в эксплуатации?',
        answers: [
          'Только после гидравлического испытания резервуара',
          'Только до гидравлического испытания резервуара',
          'Только перед подачей в него жидкого аммиака',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63629082',
        text:
          'На какой уровень должны быть заглублены полуподземные резервуары и хранилища фосфора?',
        answers: [
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
          'На уровень, обеспечивающий вместимость не менее 40% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
          'На уровень, обеспечивающий вместимость не менее 30% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,1 м',
        ],
        correctAnswers: [
          'На уровень, обеспечивающий вместимость не менее 50% хранящегося фосфора и возможность залива его слоем воды высотой не менее 0,2 м',
        ],
      },
      {
        code: '63629083',
        text:
          'Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?',
        answers: [
          'Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63629084',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63629085',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63629086',
        text:
          'Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?',
        answers: [
          'Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания',
          'Только в картере',
          'Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63629087',
        text:
          'При каких условиях допускается проводить технологический взрывоопасный процесс в области критических значений температур на объектах, связанных с производством растительных масел?',
        answers: [
          'При наличии у персонала средств индивидуальной защиты',
          'При условии наличия наряда-допуска',
          'В случае принятия мер, исключающих или предотвращающих появление источников зажигания',
          'Не допускается ни при каких условиях',
        ],
        correctAnswers: [
          'В случае принятия мер, исключающих или предотвращающих появление источников зажигания',
        ],
      },
      {
        code: '63629088',
        text:
          'Кто проводит регистрацию изотермического резервуара в установленном порядке?',
        answers: [
          'Организация, эксплуатирующая изотермический резервуар',
          'Проектная организация',
          'Специализированная организация, осуществляющая экспертизу промышленной безопасности',
          'Регистрация изотермического резервуара не требуется',
        ],
        correctAnswers: [
          'Организация, эксплуатирующая изотермический резервуар',
        ],
      },
      {
        code: '63629089',
        text:
          'С какой периодичностью необходимо проверять исправность защитных реле уровня на аппаратах (сосудах)?',
        answers: [
          '1 раз в месяц',
          '1 раз в 3 месяца',
          '1 раз в 6 месяцев',
          '1 раз в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629090',
        text:
          'Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?',
        answers: [
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 допускается ручное дистанционное управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63629091',
        text:
          'По указанию какого лица должны производиться снятие предохранительных клапанов на проверку, их установка и пломбирование?',
        answers: [
          'Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
          'Технического руководителя организации',
          'Представителя проектной организации',
          'Лица, ответственного за проведение ремонтных работ',
        ],
        correctAnswers: [
          'Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
        ],
      },
      {
        code: '63629092',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Критическими параметрами технологического процесса',
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
          'Техническими характеристиками применяемых насосов и компрессоров',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63629093',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63629094',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
        ],
      },
      {
        code: '63629095',
        text: 'Как следует хранить жидкую серу?',
        answers: [
          'Под слоем воды',
          'В герметичной таре',
          'В охлаждаемых емкостях, продуваемых инертным газом',
          'В обогреваемых паром или наружными электрическими устройствами теплоизолированных емкостях, продуваемых инертным газом',
        ],
        correctAnswers: [
          'В обогреваемых паром или наружными электрическими устройствами теплоизолированных емкостях, продуваемых инертным газом',
        ],
      },
      {
        code: '63629096',
        text:
          'Какое из перечисленных требований к образованию, хранению, транспортированию, уничтожению лаков и красок указано неверно?',
        answers: [
          'Приводы аппаратов, расположенных в помещениях, где возможно скапливание пыли, следует выполнять на одном валу с электродвигателем',
          'В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива',
          'В отдельных случаях при установке типового оборудования разрешается применять клиноременные передачи с ремнями из электропроводящей резины',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В отдельных случаях допускается отвод продуктов сгорания в один боров от агрегатов, работающих на разных видах топлива',
        ],
      },
      {
        code: '63629097',
        text:
          'В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?',
        answers: [
          'Объемом до 1000 кг/год',
          'Объемом до 1500 кг/год',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Объемом до 1000 кг/год'],
      },
      {
        code: '63629098',
        text:
          'С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?',
        answers: [
          'Не реже 1 раза в 10 лет',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 3 года',
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
        correctAnswers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63629099',
        text:
          'Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
      },
      {
        code: '63629100',
        text:
          'Куда допускается сброс неорганических жидких кислот и (или) щелочей от предохранительных клапанов?',
        answers: [
          'В рабочую зону',
          'В окружающую среду',
          'В специальные емкости',
          'Допускается во все перечисленные места в обоснованных случаях',
        ],
        correctAnswers: ['В специальные емкости'],
      },
      {
        code: '63629101',
        text:
          'В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Выберите два правильных варианта ответа.',
        answers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
          'Не допускается ни в каком случае',
          'В случае размещения холодильного оборудования над площадками на расстоянии, определенном проектной документацией',
        ],
        correctAnswers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
      },
      {
        code: '63629102',
        text: 'В каком случае допускается запускать аммиачный насос?',
        answers: [
          'При неполном заполнении насоса жидким хладагентом',
          'При отсутствии защитного кожуха муфты',
          'При закрытых клапанах на его входе и выходе',
          'Во всех перечисленных случаях',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629103',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63629104',
        text:
          'В течение какого времени и при каком давлении следует проводить вакуумирование холодильной установки перед пуском в эксплуатацию после пневматических испытаний?',
        answers: [
          'В течение 7 часов при остаточном давлении 0,05 МПа (0,5 кгс/см²)',
          'В течение 10 часов при остаточном давлении 0,015 МПа (0,15 кгс/см²)',
          'В течение 15 часов при остаточном давлении 0,03 МПа (0,3 кгс/см²)',
          'В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)',
        ],
        correctAnswers: [
          'В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)',
        ],
      },
      {
        code: '63629105',
        text:
          'Какие требования к тепловой изоляции аппаратов, технологических трубопроводов для растительных масел и мисцеллы объектов производств растительных масел указаны неверно?',
        answers: [
          'Необходимо предусматривать меры защиты от попаданий масла, мисцеллы, растворителя на изоляцию или под ее слой',
          'Для теплоизоляции используется непористый негорючий материал',
          'Для теплоизоляции используется трудносгораемый материал',
          'Теплоизоляционные изделия из минеральной ваты, базальтового или супертонкого стекла применяются только в обкладках со всех сторон из стеклянной или кремнеземной ткани и под металлическим покрывным слоем',
        ],
        correctAnswers: [
          'Для теплоизоляции используется трудносгораемый материал',
        ],
      },
      {
        code: '63629106',
        text:
          'Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?',
        answers: ['185 °C', '160 °C', '135 °C', '90 °C'],
        correctAnswers: ['160 °C'],
      },
      {
        code: '63629107',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63629108',
        text:
          'Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией',
          'Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63629109',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63629110',
        text:
          'Для каких технологических трубопроводов стилоскопирование проводится выборочно, но не менее двух соединений, выполненных одним сварщиком с использованием сварочных материалов из одной партии, при отсутствии в проекте иных указаний?',
        answers: [
          'Для всех перечисленных технологических трубопроводов',
          'Для технологических трубопроводов опасных веществ 1-го и 2-го классов опасности',
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
          'Для технологических трубопроводов при расчетном давлении выше 10 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
        ],
      },
      {
        code: '63629111',
        text:
          'Какое расстояние устанавливается внутри ограждения между лестницами, применяемыми для переходов через ограждения резервуаров для хранения жидкого аммиака?',
        answers: [
          'Не более 85 м',
          'Не более 80 м',
          'Не более 95 м',
          'Не более 90 м',
        ],
        correctAnswers: ['Не более 80 м'],
      },
      {
        code: '63629112',
        text:
          'Кто устанавливает назначенный срок службы для технологических трубопроводов?',
        answers: [
          'Орган по сертификации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Разработчик документации на химически опасном производственном объекте',
          'Организация-изготовитель',
        ],
        correctAnswers: [
          'Разработчик документации на химически опасном производственном объекте',
        ],
      },
      {
        code: '63629113',
        text:
          'Какой величины должно приниматься расчетное давление при проектировании резервуаров для хранения жидкого аммиака?',
        answers: [
          'Больше рабочего на 15%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
          'Больше рабочего на 25%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
          'Больше рабочего на 20%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
          'Больше рабочего на 10%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
        ],
        correctAnswers: [
          'Больше рабочего на 25%, но не менее чем на 98,06 Па (10 мм вод. ст.)',
        ],
      },
      {
        code: '63629114',
        text:
          'Какая минимальная продолжительность испытаний на плотность всей системы сосудов, аппаратов и трубопроводов?',
        answers: ['8 часов', '12 часов', '15 часов', '3 часа'],
        correctAnswers: ['12 часов'],
      },
      {
        code: '63629115',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63629116',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
          'Руководитель структурного подразделения или лицо, его замещающее',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63629117',
        text:
          'Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчик в задании на проектирование',
          'Разработчик процесса',
          'Разработчик проекта',
          'Разработчики проекта и процесса',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63629118',
        text:
          'Какие из перечисленных электроприемников складов жидкого аммиака являются потребителями II категории надежности?',
        answers: [
          'С изотермическими резервуарами',
          'С шаровыми резервуарами',
          'С резервуарами под избыточным давлением и вместимостью до 100 т включительно',
        ],
        correctAnswers: [
          'С резервуарами под избыточным давлением и вместимостью до 100 т включительно',
        ],
      },
      {
        code: '63629119',
        text:
          'В каких резервуарах не допускается осуществлять хранение жидкого аммиака?',
        answers: [
          'В резервуарах под избыточным давлением до 2,0 МПа включительно без отвода аммиака. Рабочее давление в резервуаре принимается исходя из максимальной температуры окружающего воздуха с учетом солнечной радиации, наличия тепловой изоляции и защитных конструкций',
          'В резервуарах под избыточным давлением до 4,0 МПа включительно без отвода аммиака. Рабочее давление в резервуаре принимается исходя из максимальной температуры окружающего воздуха с учетом солнечной радиации, наличия тепловой изоляции и защитных конструкций',
          'В изотермических резервуарах под давлением, близким к атмосферному, с отводом испаряющегося аммиака, компримированием, конденсацией и возвратом в резервуар или потребителю',
          'В резервуарах под избыточным давлением до 1,0 МПа включительно с отводом аммиака, испаряющегося от теплопритока, с выдачей его потребителю или компримированием испарившегося аммиака с последующей конденсацией и возвратом его в резервуар',
        ],
        correctAnswers: [
          'В резервуарах под избыточным давлением до 4,0 МПа включительно без отвода аммиака. Рабочее давление в резервуаре принимается исходя из максимальной температуры окружающего воздуха с учетом солнечной радиации, наличия тепловой изоляции и защитных конструкций',
        ],
      },
      {
        code: '63629120',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 24 часов',
          'В течение 8 часов',
          'В течение времени, установленного проектной документацией',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63629121',
        text:
          'Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?',
        answers: [
          'Параллельно с рабочими предохранительными клапанами необходимо установить резервные предохранительные клапаны',
          'Применение рычажно-грузовых предохранительных клапанов не допускается',
          'На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом',
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
        ],
        correctAnswers: [
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
        ],
      },
      {
        code: '63629122',
        text:
          'Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?',
        answers: ['На 50%', 'На 70%', 'На 80%', 'На 90%'],
        correctAnswers: ['На 80%'],
      },
      {
        code: '63629123',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 10 лет',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63629124',
        text: 'В каком случае насос должен быть немедленно остановлен?',
        answers: [
          'Только если упало давление напора или разность давлений напора и всасывания (при отсутствии или отказе приборов автоматики)',
          'Только если появились утечки аммиака через неплотности агрегата',
          'Только если обнаружены неисправности манометров, обратных клапанов, средств КИПиА',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63629125',
        text:
          'Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?',
        answers: [
          'Получение разрешения на применение технических устройств, выдаваемого органами Ростехнадзора',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
      },
      {
        code: '63629126',
        text:
          'В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, их локализации и максимальному снижению тяжести последствий?',
        answers: [
          'В техническом регламенте',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'Только в Положении о производственном контроле',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63629127',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63629128',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для производств с новой технологией',
          'Для новых в данной организации производств',
          'Для всех перечисленных производств',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629129',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только заключение о техническом состоянии арматуры',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о качестве сварных стыков',
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63629130',
        text:
          'В каком случае не допускается применение гибких резиновых или пластмассовых шлангов?',
        answers: [
          'При проведении операций слива аммиака (при заполнении системы) из цистерны',
          'Для выполнения вспомогательных операций (освобождение трубопроводов, аппаратов, фильтров от остатков аммиака, масла)',
          'В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака',
        ],
        correctAnswers: [
          'В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака',
        ],
      },
      {
        code: '63629131',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63629132',
        text:
          'В каком положении должны быть опломбированы запорные клапаны на аммиачных газовых нагнетательных трубопроводах?',
        answers: ['В открытом', 'В закрытом', 'Не имеет значения'],
        correctAnswers: ['В открытом'],
      },
      {
        code: '63629133',
        text:
          'В каком случае допускается предусматривать линейный ресивер для холодильных машин с дозированной зарядкой аммиака?',
        answers: [
          'Допускается, если это обосновано проектной документацией',
          'Допускается предусматривать во всех случаях',
          'Допускается если линейный ресивер используется в качестве дренажного или циркуляционного',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629134',
        text:
          'Что учитывается при определении пропускной способности предохранительных устройств для защиты от разрушений сосудов, аппаратов и технологического оборудования, содержащих жидкий аммиак?',
        answers: [
          'Площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 3 раза больше расчетного давления защищаемого аппарата',
          'Скорость теплового потока испарившегося аммиака в случае пожара',
          'Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата',
          'Общий объем аммиака в аппарате',
        ],
        correctAnswers: [
          'Плотность теплового потока через наружные стенки сосуда, площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого аппарата',
        ],
      },
      {
        code: '63629135',
        text:
          'Что происходит с выбросами аммиака при продувках оборудования и трубопроводов, снижении в них давления, сливе (наливе) цистерн, а также со сбросами от предохранительных клапанов?',
        answers: [
          'Выбросы и сбросы утилизируются или направляются в факельную систему',
          'Выбросы и сбросы поступают в резервный резервуар',
          'Выбросы и сбросы поступают обратно в резервуар',
        ],
        correctAnswers: [
          'Выбросы и сбросы утилизируются или направляются в факельную систему',
        ],
      },
      {
        code: '63629136',
        text:
          'Какое из перечисленных требований к хранению фосфора и его соединений указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Расстояние в свету между резервуарами должно быть не менее 0,5 диаметра наибольшего резервуара',
          'Резервуары для хранения фосфора следует размещать не более чем в три ряда',
          'Поддоны склада не нуждаются в усиленной гидроизоляции',
          'Поддоны (отсеки) склада следует выполнять с уклонами в сторону приямка для сбора возможных проливов фосфора и воды',
          'Расстояние в свету от крайних резервуаров до стен склада или стенок поддона (отсека) должно быть не менее 2 м',
        ],
        correctAnswers: [
          'Расстояние в свету между резервуарами должно быть не менее 0,5 диаметра наибольшего резервуара',
          'Поддоны (отсеки) склада следует выполнять с уклонами в сторону приямка для сбора возможных проливов фосфора и воды',
        ],
      },
      {
        code: '63629137',
        text:
          'Какие должны быть здания на территории склада жидкого аммиака по степени огнестойкости?',
        answers: [
          'Не ниже II степени огнестойкости',
          'Не ниже III степени огнестойкости',
          'Не ниже IV степени огнестойкости',
          'Не ниже V степени огнестойкости',
        ],
        correctAnswers: ['Не ниже II степени огнестойкости'],
      },
      {
        code: '63629138',
        text:
          'Какое объемное содержание диоксида (двуокиси) углерода и кислорода допускается в подвальных и полуподвальных помещениях складов масличного сырья, галерей и туннелей, предназначенных для транспортирования маслосемян, а также приямков, в которых размещено оборудование для транспортирования масличного сырья, в случае неисправности вентиляции?',
        answers: [
          'Содержание в воздухе СО2 не должно превышать 2%, а О2 должно быть не менее 25%',
          'Содержание в воздухе СО2 не должно превышать 1,5%, а О2 должно быть не менее 20%',
          'Содержание в воздухе СО2 не должно превышать 0,5%, а О2 должно быть не менее 20%',
          'Содержание в воздухе СО2 не должно превышать 1,0%, а О2 должно быть не менее 30%',
        ],
        correctAnswers: [
          'Содержание в воздухе СО2 не должно превышать 0,5%, а О2 должно быть не менее 20%',
        ],
      },
      {
        code: '63629139',
        text:
          'Какую минимальную температуру следует принимать для сливных, наливных и эвакуационных насосов жидкого аммиака?',
        answers: ['-35 °C', '-34 °C', '-28 °C', '-20 °C'],
        correctAnswers: ['-34 °C'],
      },
      {
        code: '63629140',
        text:
          'Какая вместимость емкостей с фосфором допустима при их установке в производственном помещении?',
        answers: [
          'Количество фосфора не должно превышать 3-суточной потребности',
          'Количество фосфора не должно превышать 2-суточной потребности',
          'Не более 50 м³',
          'Не более 20 м³',
        ],
        correctAnswers: ['Не более 20 м³'],
      },
      {
        code: '63629141',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63629142',
        text:
          'Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Только система водяного отопления',
          'Только система парового отопления',
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63629143',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63629144',
        text:
          'Какой максимальный коэффициент заполнения объема резервуара допускается при хранении жидкого аммиака под избыточным давлением?',
        answers: [
          '0,8 от геометрического объема резервуара',
          '0,75 от геометрического объема резервуара',
          '0,85 от геометрического объема резервуара',
          '0,9 от геометрического объема резервуара',
        ],
        correctAnswers: ['0,85 от геометрического объема резервуара'],
      },
      {
        code: '63629145',
        text:
          'Кто устанавливает сроки проведения ревизии трубопроводов, запорной арматуры и предохранительных клапанов для неорганических жидких кислот и (или) щелочей в зависимости от скорости коррозионно-эрозионного износа?',
        answers: [
          'Научно-исследовательские организации',
          'Предприятие - владелец трубопровода',
          'Ростехнадзор',
          'Проектная организация',
        ],
        correctAnswers: ['Предприятие - владелец трубопровода'],
      },
      {
        code: '63629146',
        text:
          'Какая максимальная вместимость одного отсека на складах предприятий, производящих желтый фосфор?',
        answers: ['50 т', '100 т', '150 т', '200 т'],
        correctAnswers: ['100 т'],
      },
      {
        code: '63629147',
        text:
          'В каком случае допускается применение резиновых и резинометаллических рукавов для слива (налива) цистерн жидкого аммиака?',
        answers: [
          'Если рукава с внутренним диаметром 50 мм',
          'Если это регламентировано внутренней эксплуатационной документацией',
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Если рукава рассчитаны на рабочее давление не менее 2 МПа',
        ],
      },
      {
        code: '63629148',
        text:
          'Какие параметры экстракционной установки, требующие остановки цеха, указаны верно?',
        answers: [
          'Падение давления сжатого воздуха ниже 0,4 МПа (4 кгс/см2) для питания систем контроля и управления системы противоаварийной защиты',
          'Падение давления и температуры пара на коллекторах',
          'Повышение концентрации паров растворителя в воздушной среде цеха до 20% от нижнего концентрационного предела распространения пламени',
          'Повышение давления воды в циркуляционной системе на 10%',
        ],
        correctAnswers: ['Падение давления и температуры пара на коллекторах'],
      },
      {
        code: '63629149',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
          'Требования к обозначению определяются при разработке плана мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63629150',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
          'Технологическим регламентом',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63629151',
        text:
          'На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?',
        answers: [
          'Не более 2,2 м',
          'Не менее 2,2 м',
          'Не более 3,4 м',
          'Не менее 3,4 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63629152',
        text:
          'Чем производится продувка резервуаров для хранения жидкого аммиака перед включением в работу?',
        answers: ['Азотом', 'Кислородом', 'Паром', 'Любым благородным газом'],
        correctAnswers: ['Азотом'],
      },
      {
        code: '63629153',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при деформации фланцев',
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63629154',
        text:
          'В каком случае допускается использование ртутных термометров и ртутных устройств для измерения температуры в контрольных точках аммиачной холодильной системы?',
        answers: [
          'Если используется защита от механических повреждений',
          'Если это обосновано проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63629155',
        text:
          'Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у организации-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63629156',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63629157',
        text:
          'Чем должно быть оснащено оборудование для разделения суспензий и фильтрации?',
        answers: [
          'Манометром, обеспечивающим измерение давления при подаче суспензий',
          'Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
          'Блокировками, обеспечивающими прекращение подачи суспензий при допустимых отклонениях параметров инертной среды',
          'Укрытиями, обеспечивающими прекращение подачи суспензий при отклонениях параметров инертной среды',
        ],
        correctAnswers: [
          'Блокировками, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63629158',
        text:
          'Какие требования к экстракторам объектов производств растительных масел указаны неверно?',
        answers: [
          'Приводы экстракторов обеспечиваются системами защиты от превышения предельно допустимой нагрузки на валы, исключающими их поломку при запрессовках и заклинивании в случае попадания посторонних предметов',
          'Шнековые экстракторы оснащаются приборами контроля и регулирования частоты вращения валов',
          'Шнековые экстракторы оснащаются системами аварийного освобождения',
          'Ленточные экстракторы и экстракторы других типов, имеющие в своем составе конвейеры, оснащаются системами регулирования скорости движения ленты (конвейера) в зависимости от количества подаваемого материала',
        ],
        correctAnswers: [
          'Шнековые экстракторы оснащаются системами аварийного освобождения',
        ],
      },
      {
        code: '63629159',
        text:
          'Для каких производств управление системами подачи флегматизирующих веществ на объектах, связанных с производством растительных масел, осуществляется дистанционно?',
        answers: [
          'Для производств с блоками I категории',
          'Для производств с блоками II категории',
          'Для производств с блоками III категории',
          'Для производств со значением относительного энергетического потенциала Qв меньше 10',
        ],
        correctAnswers: ['Для производств с блоками III категории'],
      },
      {
        code: '63629160',
        text:
          'С какой периодичностью необходимо проверять промежуточный хладоноситель в системах охлаждения на присутствие аммиака?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в полгода',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в неделю',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629161',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, показатели пожароопасности и токсичности',
          'Показатели взрывопожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63629162',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Установка заглушек',
          'Контроль сплошности изоляции и толщины стенок',
          'Продувка',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63629163',
        text:
          'Как осуществляется охлаждение изотермического резервуара с аммиаком?',
        answers: [
          'Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака',
          'Подачей охлаждающей жидкости в змеевик, расположенный в нижней части резервуара',
          'За счет возврата в резервуар под слой сжиженных на холодильной установке отводимых из резервуара паров аммиака',
          'Подачей охлаждающей жидкости в рубашку резервуара',
        ],
        correctAnswers: [
          'Установкой разбрызгивающего устройства, расположенного выше допустимого уровня аммиака',
        ],
      },
      {
        code: '63629164',
        text:
          'Каким документом определяются выбор способа подачи флегматизатора, его количества для обеспечения эффективности продувки и исключения возможности образования застойных зон на объектах, связанных с производством растительных масел?',
        answers: [
          'Техническим регламентом',
          'Инструкцией по безопасному производству работ',
          'Технологическим регламентом',
          'Проектной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63629165',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 5 лет',
          'Не более 3 лет',
          'Не более 10 лет',
          'Не более 1 года',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63629166',
        text:
          'Какое условие при механической очистке труб конденсатора от водяного камня является неверным?',
        answers: [
          'Работа должна выполняться с оформлением наряда-допуска',
          'Работа должна выполняться только после освобождения конденсатора от аммиака',
          'Работа должна выполняться под руководством технического руководителя организации',
        ],
        correctAnswers: [
          'Работа должна выполняться под руководством технического руководителя организации',
        ],
      },
      {
        code: '63629167',
        text:
          'Какие требования установлены для трубопроводов, предназначенных для транспортирования фосфора и фосфорного шлама?',
        answers: [
          'Фосфоропроводы прокладываются в закрытых галереях эстакадного типа',
          'Трубопроводы для транспортирования фосфора и фосфорного шлама не допускается прокладывать с обогревающим спутником в одной изоляции',
          'Трубопроводы фосфора и фосфорного шлама следует продувать инертным газом до и после каждой перекачки фосфора',
          'Расстояние по горизонтали от трубопроводов фосфора, фосфорного шлама до трубопроводов, содержащих пожароопасные и токсичные продукты, не должно быть менее 1,5 м',
        ],
        correctAnswers: [
          'Расстояние по горизонтали от трубопроводов фосфора, фосфорного шлама до трубопроводов, содержащих пожароопасные и токсичные продукты, не должно быть менее 1,5 м',
        ],
      },
      {
        code: '63629168',
        text:
          'Каким образом определяются способ хранения жидкого аммиака, количество, вместимость?',
        answers: [
          'Эксплуатационной документацией',
          'Проектом из условия обеспечения безопасной эксплуатации',
          'Правилами безопасности для наземных складов жидкого аммиака',
          'Технологическими регламентами',
        ],
        correctAnswers: [
          'Проектом из условия обеспечения безопасной эксплуатации',
        ],
      },
      {
        code: '63629169',
        text:
          'Какое устройство должно быть установлено на территории склада жидкого аммиака?',
        answers: [
          'Барометр',
          'Указатель направления ветра',
          'Термометр',
          'Гигрометр',
        ],
        correctAnswers: ['Указатель направления ветра'],
      },
      {
        code: '63629170',
        text:
          'Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?',
        answers: [
          'Не более 2 м с каждой его стороны',
          'Не менее 2 м с каждой его стороны',
          'Не менее 1 м с каждой его стороны',
          'Не более 1 м с каждой его стороны',
        ],
        correctAnswers: ['Не менее 2 м с каждой его стороны'],
      },
      {
        code: '63629171',
        text: 'Каким образом необходимо наполнять цистерны фосфором?',
        answers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °С. Слой воды или раствора должен быть высотой не менее 500 мм',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
          'Перед наполнением цистерн их необходимо продуть инертным газом',
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 25 °С. Слой воды или раствора должен быть высотой не менее 100 мм, свободное пространство цистерны - не менее 10%',
        ],
        correctAnswers: [
          'Перед наполнением цистерн в них необходимо залить воду или незамерзающий раствор с температурой не менее 50 °C с таким расчетом, чтобы после заполнения цистерны над поверхностью фосфора был слой воды или незамерзающего раствора высотой не менее 300 мм и свободное пространство не менее 10% объема цистерны',
        ],
      },
      {
        code: '63629172',
        text:
          'В течение какого срока службы устройства резервуаров для хранения аммиака должны обеспечивать надежную и безопасную эксплуатацию?',
        answers: [
          'В течение 10 лет',
          'В течение 15 лет',
          'В течение 20 лет',
          'В течение срока службы, указанного в паспорте организации-изготовителя',
        ],
        correctAnswers: [
          'В течение срока службы, указанного в паспорте организации-изготовителя',
        ],
      },
      {
        code: '63629173',
        text:
          'Какие требования к системам автоматического контроля и управления технологическими процессами объектов производств растительных масел указаны неверно?',
        answers: [
          'Должны обеспечивать сигнализацию и регистрацию в реальном времени срабатывания средств системы противоаварийной защиты',
          'Должны обеспечивать постоянную связь оператора установки с механиком цеха',
          'Должны обеспечивать сигнализацию и регистрацию в реальном времени отклонений основных технологических параметров, в том числе определяющих безопасность ведения процесса',
          'Должны обеспечивать постоянный контроль за параметрами технологического процесса и управление режимом для поддержания их регламентированных значений',
        ],
        correctAnswers: [
          'Должны обеспечивать постоянную связь оператора установки с механиком цеха',
        ],
      },
      {
        code: '63629174',
        text:
          'Какое из перечисленных требований во избежание попадания водяных паров из окружающего воздуха в теплоизоляционный слой изотермических резервуаров жидкого аммиака с засыпной изоляцией указано верно?',
        answers: [
          'Межстенное пространство должно быть постоянно заполнено аргоном',
          'Избыточное давление в межстенном пространстве определяется проектом',
          'Межстенное пространство должно быть постоянно заполнено осушенным азотом с точкой росы -20 °C',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629175',
        text:
          'Какая должна быть ширина основных проходов (для транспортирования бочек) в складе желтого фосфора при хранении его в бочках?',
        answers: [
          'Не менее 1 м',
          'Не менее 2,2 м',
          'Не менее 1,8 м',
          'Не менее 2 м',
        ],
        correctAnswers: ['Не менее 1,8 м'],
      },
      {
        code: '63629176',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальник производственно-технического (технического) отдела организации',
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
          'Начальник производства',
        ],
        correctAnswers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
      },
      {
        code: '63629177',
        text:
          'При каких погодных условиях производится монтаж изоляции изотермических резервуаров жидкого аммиака?',
        answers: [
          'Только в теплое время года',
          'Только при температуре не ниже предусмотренной техническими условиями',
          'Только при отсутствии атмосферных осадков',
          'При соблюдении всех перечисленных погодных условий',
        ],
        correctAnswers: ['При соблюдении всех перечисленных погодных условий'],
      },
      {
        code: '63629178',
        text:
          'В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?',
        answers: [
          'Допускается для аппаратов вертикального типа',
          'Допускается в обоснованных в проектной документации случаях',
          'Допускается для аппаратов горизонтального типа',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в обоснованных в проектной документации случаях',
        ],
      },
      {
        code: '63629179',
        text:
          'Кто утверждает годовые и месячные графики ремонта холодильного оборудования?',
        answers: [
          'Технический руководитель организации',
          'Руководитель организации',
          'Начальник службы производственного контроля',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63629180',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63629181',
        text:
          'Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах безопасности химически опасных производственных объектов?',
        answers: [
          'Определяется специалистами неразрушающего контроля',
          'Не менее 50% длины сварного шва каждого сварного соединения',
          'Не менее 75% длины сварного шва каждого сварного соединения',
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
        correctAnswers: [
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
      },
      {
        code: '63629182',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '3 месяца',
          '6 месяцев',
          '12 месяцев',
          '24 месяца',
          '36 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63629183',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В техническом регламенте',
          'В технологическом регламенте',
          'В руководстве по безопасности',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63629184',
        text:
          'В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?',
        answers: [
          'В течение слива аммиака',
          'В течение всего времени нахождения',
          'В течение периода приемки цистерны',
          'Наблюдение за цистернами с жидким аммиаком не требуется',
        ],
        correctAnswers: ['В течение всего времени нахождения'],
      },
      {
        code: '63629185',
        text:
          'На какие наземные склады жидкого аммиака не распространяются Правила безопасности химически опасных производственных объектов?',
        answers: [
          'На склады аммиака в баллонах',
          'На раздаточные станции, расположенные в сельскохозяйственных районах, получающих аммиак из аммиакопровода',
          'Правила распространяются на все перечисленные склады жидкого аммиака',
          'На глубинные сельскохозяйственные склады, размещаемые на территории сельскохозяйственного района',
          'На заводские склады, расположенные на территории организаций, производящих аммиак, использующих его в качестве сырья или вспомогательного материала для выпуска товарной продукции, применяющих аммиак в промышленных холодильных установках или для других целей',
        ],
        correctAnswers: ['На склады аммиака в баллонах'],
      },
      {
        code: '63629186',
        text:
          'Какое количество бочек с фосфором должно быть в каждом ярусе по длине и по ширине?',
        answers: [
          'Не более 21 бочки и не более 3 бочек соответственно',
          'Не более 15 бочек и не более 2 бочек соответственно',
          'Не более 20 бочек и не более 4 бочек соответственно',
          'Не более 11 бочек и не более 2 бочек соответственно',
        ],
        correctAnswers: ['Не более 15 бочек и не более 2 бочек соответственно'],
      },
      {
        code: '63629187',
        text:
          'Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Входящие в состав проектной документации или пусковые',
          'Периодически пересматриваемые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63629188',
        text:
          'Каким должно быть расстояние от охлаждающих батарей до грузового штабеля в холодильных камерах?',
        answers: [
          'Не менее 0,1 м',
          'Не менее 0,2 м',
          'Не менее 0,3 м',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее 0,3 м'],
      },
      {
        code: '63629189',
        text:
          'Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?',
        answers: [
          'Разовые (опытные) технологические регламенты',
          'Постоянные технологические регламенты',
          'Лабораторные технологические регламенты',
          'Временные технологические регламенты',
        ],
        correctAnswers: ['Разовые (опытные) технологические регламенты'],
      },
      {
        code: '63629190',
        text:
          'Какие материалы применяются для изготовления, монтажа и ремонта технологического оборудования и трубопроводов для производств, использующих неорганические кислоты и щелочи?',
        answers: [
          'Нержавеющая сталь',
          'Полимерные материалы',
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
          'Композитные материалы',
        ],
        correctAnswers: [
          'Материалы, обеспечивающие коррозионную стойкость к рабочей среде',
        ],
      },
      {
        code: '63629191',
        text:
          'Что необходимо сделать перед включением электропечи после ремонта, выполненного с ее разгерметизацией?',
        answers: [
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%',
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода более 10%',
          'Очистить все аппараты и газоходы и провести осмотр',
          'Продуть все аппараты и газоходы инертным газом до содержания азота не более 2%',
        ],
        correctAnswers: [
          'Продуть все аппараты и газоходы инертным газом до содержания кислорода не более 2%',
        ],
      },
      {
        code: '63629192',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Инспектор территориального органа Ростехнадзора',
          'Комиссия эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63629193',
        text:
          'Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых систем холодоснабжения?',
        answers: [
          'Не более 1,2 м',
          'Не менее 1,5 м',
          'Не менее 1 м',
          'Не более 0,8 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63629194',
        text:
          'Какие требования предъявляются к структуре системы контроля утечек аммиака из резервуаров жидкого аммиака?',
        answers: [
          'Структура системы контроля утечек аммиака должна быть двухконтурной и двухуровневой',
          'Структура системы контроля утечек аммиака не должна быть двухконтурной',
          'Структура системы контроля утечек аммиака не должна быть двухуровневой',
          'Структура системы контроля утечек аммиака должна быть трехконтурной и двухуровневой',
        ],
        correctAnswers: [
          'Структура системы контроля утечек аммиака должна быть двухконтурной и двухуровневой',
        ],
      },
      {
        code: '63629195',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о") допускается устанавливать в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах)',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о") допускается устанавливать в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах)',
        ],
      },
      {
        code: '63629196',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категорий взрывоопасности - не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63629197',
        text:
          'Какое из перечисленных требований не допускается на территории склада жидкого аммиака?',
        answers: [
          'Сопряжение двух наружных ограждений резервуаров для хранения жидкого аммиака',
          'Размещение сливоналивных устройств между расположенными рядом путями',
          'Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака',
          'Планирование территории в ограждении резервуаров для хранения жидкого аммиака с уклоном в сторону приямка',
        ],
        correctAnswers: [
          'Прокладывание транзитных трубопроводов, не относящихся к резервуарам для хранения жидкого аммиака, и кабелей через огражденные территории резервуаров для хранения жидкого аммиака',
        ],
      },
      {
        code: '63629198',
        text:
          'Что из перечисленного не допускается при эксплуатации систем холодоснабжения? Выберите два правильных варианта ответа.',
        answers: [
          'Установка вспрыскивающих устройств, если это предусмотрено инструкцией организации-изготовителя',
          'Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора',
          'Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки',
          'Эксплуатация винтовых компрессоров с впрыском жидкого аммиака, если это предусмотрено инструкцией организации-изготовителя',
        ],
        correctAnswers: [
          'Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора',
          'Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки',
        ],
      },
      {
        code: '63629199',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов химически опасных производственных объектов (далее - ХОПО)?',
        answers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
          'Правилами безопасности химически опасных производственных объектов не регламентируется',
          'Не более 2 независимых датчиков с раздельными точками отбора',
          'Не менее 5 независимых датчиков с раздельными точками отбора',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63629200',
        text:
          'Какие из перечисленных требований к датчикам и пробоотборным устройствам анализаторов объектов производств растительных масел указаны верно?',
        answers: [
          'Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в загрузочном бункере экстрактора с уставками срабатывания 3 г/м³; 3…4 г/м³; 4 г/м³',
          'В случае отсутствия загрузочного бункера места установки, количество датчиков и уставки определяются техническим руководителем организации',
          'Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в разгрузочном винтовом конвейере шрота с уставками срабатывания 5 г/м³; 5…10 г/м³; 10 г/м³',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629201',
        text:
          'Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?',
        answers: [
          'Vз.р больше Vс x 0,2 м³',
          'Vз.р больше Vс x 0,5 м³',
          'Vз.р больше Vс x 0,9 м³',
          'Vз.р больше Vс x 1,5 м³',
        ],
        correctAnswers: ['Vз.р больше Vс x 0,5 м³'],
      },
      {
        code: '63629202',
        text:
          'Как должны быть оборудованы места пересыпки и транспортирования пылящего химически опасного продукта в производстве пигментов?',
        answers: [
          'Места пересыпки и транспортирования должны оборудоваться на открытых площадках, защищенных от ветра',
          'Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам',
          'Пересыпка и транспортирование должны осуществляться в закрытых бункерах и конвейерах',
          'Требования к местам пересыпки и транспортирования устанавливаются в проекте',
        ],
        correctAnswers: [
          'Места пересыпки и транспортирования должны быть герметизированы и снабжены укрытиями, присоединенными к аспирационным вентиляционным установкам',
        ],
      },
      {
        code: '63629203',
        text:
          'Какая должна быть высота решетчатого вертикального ограждения для бункеров и завальных ям объектов производств растительных масел?',
        answers: [
          'Не менее чем на 1,5 м выше уровня загрузки масличного сырья',
          'Не менее чем на 1 м выше уровня загрузки масличного сырья',
          'Не менее чем на 0,5 м выше уровня загрузки масличного сырья',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Не менее чем на 0,5 м выше уровня загрузки масличного сырья',
        ],
      },
      {
        code: '63629204',
        text:
          'Какое количество суховальцованных паст для подколеровки эмалей допускается хранить в помещениях цехов, предназначенных для лаков и эмалей на эфирах целлюлозы?',
        answers: [
          'Не более сменной потребности',
          'Не более 50% сменной потребности',
          'Не более 2% сменной потребности',
          'Не менее 10% сменной потребности',
        ],
        correctAnswers: ['Не более 2% сменной потребности'],
      },
      {
        code: '63629205',
        text:
          'Какая должна быть степень защиты электроприборов и средств автоматического и дистанционного управления, располагающихся в помещениях с аммиачным оборудованием?',
        answers: [
          'Не ниже IP44',
          'Не ниже IP54',
          'Не ниже IP20',
          'Не ниже IP65',
        ],
        correctAnswers: ['Не ниже IP44'],
      },
      {
        code: '63629206',
        text:
          'Какое требование установлено к газосигнализаторам довзрывных концентраций горючих газов в помещениях цеха экстракции, отгонки растворителя из шрота, дистилляции, насосных для перекачки растворителя объектов производств растительных масел?',
        answers: [
          'Газосигнализаторы с сигнализацией превышения 20% уровня от нижнего концентрационного предела распространения пламени',
          'Газосигнализаторы с сигнализацией превышения 10% уровня от нижнего концентрационного предела распространения пламени',
          'Газосигнализаторы с сигнализацией превышения 15% уровня от нижнего концентрационного предела распространения пламени',
          'Газосигнализаторы с сигнализацией превышения 5% уровня от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Газосигнализаторы с сигнализацией превышения 10% уровня от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63629207',
        text:
          'Какие изотермические резервуары дополнительно рассчитываются на сейсмические нагрузки?',
        answers: [
          'Двустенные с подвесной крышей внутреннего резервуара',
          'Все изотермические резервуары рассчитываются на сейсмические нагрузки',
          'Двустенные с двумя самонесущими крышами',
          'Одностенные',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629208',
        text:
          'Какие из функций, которые должна обеспечивать автоматизированная система управления технологическими процессами объектов производств растительных масел, указаны верно? Выберите два правильных варианта ответа.',
        answers: [
          'Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии',
          'Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты',
          'Выдача информации о состоянии безопасности на объекте в надзорный орган',
          'Связь оператора установки с начальником цеха и техническим руководителем организации',
        ],
        correctAnswers: [
          'Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии',
          'Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты',
        ],
      },
      {
        code: '63629209',
        text:
          'На каких из перечисленных участках аммиачных трубопроводов должны быть нанесены три опознавательных кольца?',
        answers: [
          'На парожидкостных и жидкостных линиях стороны низкого давления систем холодоснабжения',
          'На паровых линиях стороны низкого давления систем холодоснабжения',
          'На жидкостных линиях стороны высокого давления',
          'На паровых линиях стороны высокого давления',
        ],
        correctAnswers: ['На жидкостных линиях стороны высокого давления'],
      },
      {
        code: '63629210',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Средствами обеспечения питания инертными газами систем контрольно-измерительных приборов и автоматики',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63629211',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главным инженером эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63629212',
        text:
          'Какое из перечисленных требований к процессам транспортирования и хранения масличного сырья, жмыхов и шротов указано неверно?',
        answers: [
          'Температуру заложенных на хранение жмыха и шрота необходимо проверять по графику, утвержденному в эксплуатирующей организации, с помощью дистанционных стационарных термометров (термоподвесок)',
          'Для вновь строящихся элеваторов для шрота должны предусматриваться устройства, обеспечивающие разрыхление слежавшегося шрота',
          'Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается',
          'Пневмотранспорт и его элементы должны быть заземлены путем установки электропроводящих перемычек в местах фланцевых соединений элементов',
        ],
        correctAnswers: [
          'Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается',
        ],
      },
      {
        code: '63629213',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63629214',
        text:
          'Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?',
        answers: [
          'План работ, утвержденный техническим руководителем организации',
          'Внутреннее распоряжение о производстве указанных работ',
          'Оформление дополнительного документа на проведение указанных работ не требуется',
          'Наряд-допуск на указанные работы',
        ],
        correctAnswers: ['Наряд-допуск на указанные работы'],
      },
      {
        code: '63629215',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок',
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63629216',
        text:
          'Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?',
        answers: [
          'Не ниже 5 °C и не более 35 °C соответственно',
          'Не ниже 15 °C и не более 40 °C соответственно',
          'Не ниже 10 °C и не более 45 °C соответственно',
          'Не ниже 20 °C и не более 50 °C соответственно',
        ],
        correctAnswers: ['Не ниже 10 °C и не более 45 °C соответственно'],
      },
      {
        code: '63629217',
        text:
          'Какой должна быть высота ограждения резервуаров для хранения жидкого аммиака?',
        answers: [
          'Не менее расчетного уровня разлившегося жидкого аммиака',
          'Не менее чем на 0,5 м выше расчетного уровня разлившегося жидкого аммиака',
          'Не менее чем на 0,2 м выше расчетного уровня разлившегося жидкого аммиака',
          'Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м',
        ],
        correctAnswers: [
          'Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м',
        ],
      },
      {
        code: '63629218',
        text:
          'Для какого оборудования процент первичного заполнения жидким аммиаком не превышает 30%?',
        answers: [
          'Для ресиверов линейных',
          'Для батарей холодильных камер с верхней подачей аммиака',
          'Для воздухоохладителей с нижней подачей аммиака',
          'Для испарителей кожухотрубных и вертикально-трубных',
        ],
        correctAnswers: [
          'Для батарей холодильных камер с верхней подачей аммиака',
        ],
      },
      {
        code: '63629219',
        text:
          'Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?',
        answers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
          'Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание',
          'В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание',
          'Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования',
        ],
        correctAnswers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
        ],
      },
      {
        code: '63629220',
        text:
          'Какими типами предохранительных клапанов необходимо оборудовать шроторазгрузитель, микроциклоны и шротопровод объектов производств растительных масел?',
        answers: [
          'Пружинного типа',
          'Рычажно-грузового типа',
          'Магнитно-пружинного типа',
          'Мембранного типа',
        ],
        correctAnswers: ['Мембранного типа'],
      },
      {
        code: '63629221',
        text:
          'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и заканчивая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63629222',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 30 минут',
          'Не менее 15 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63629223',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?',
        answers: [
          'Не менее 12 секунд',
          'Не более 120 секунд',
          'Не менее 300 секунд',
          'Не менее 240 секунд',
        ],
        correctAnswers: ['Не более 120 секунд'],
      },
      {
        code: '63629224',
        text:
          'Какие требования к экстракторам объектов производства масел указаны неверно?',
        answers: [
          'Шнековые экстракторы оснащаются системами аварийного освобождения',
          'Шнековые экстракторы оснащаются приборами контроля и регулирования частоты вращения валов',
          'Ленточные экстракторы и экстракторы других типов, имеющие в своем составе конвейеры, оснащаются системами регулирования скорости движения ленты (конвейера) в зависимости от количества подаваемого материала',
          'Приводы экстракторов обеспечиваются системами защиты от превышения предельно допустимой нагрузки на валы, исключающими их поломку при запрессовках и заклинивании в случае попадания посторонних предметов',
        ],
        correctAnswers: [
          'Шнековые экстракторы оснащаются системами аварийного освобождения',
        ],
      },
      {
        code: '63629225',
        text:
          'Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?',
        answers: [
          'Не менее 2,5 м от стены здания',
          'Не менее 1,0 м от стены здания',
          'Не менее 1,5 м от стены здания',
          'Не менее 2,0 м от стены здания',
        ],
        correctAnswers: ['Не менее 1,0 м от стены здания'],
      },
      {
        code: '63629226',
        text:
          'Какие действия персонала предусмотрены в случае повышения температуры масличного сырья выше допустимой, указанной в технологическом регламенте объектов производств растительных масел?',
        answers: [
          'Следует сообщить главному технологу организации и вызвать пожарную охрану организации',
          'Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку',
          'Следует сообщить главному технологу организации и сделать запись в журнале эксплуатации и ремонта',
          'Следует вызвать пожарную охрану организации',
        ],
        correctAnswers: [
          'Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку',
        ],
      },
      {
        code: '63629227',
        text:
          'Какая минимальная вместимость газгольдера, устанавливаемого на линии подачи азота, определяется для поддержания в межстенном пространстве резервуара с засыпной изоляцией постоянной величины избыточного давления при изменениях барометрического давления и температуры воздуха?',
        answers: [
          '14 - 16% объема межстенного пространства резервуара, с которым соединен газгольдер',
          '4 - 6% объема межстенного пространства резервуара, с которым соединен газгольдер',
          '12 - 14% объема межстенного пространства резервуара, с которым соединен газгольдер',
          '2 - 5% объема межстенного пространства резервуара, с которым соединен газгольдер',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629228',
        text:
          'Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала',
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
          'Посредством изучения наглядных пособий (плакатов) по охране труда',
        ],
        correctAnswers: [
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
      },
      {
        code: '63629229',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующих токсичные, высокотоксичные вещества, органические теплоносители, воспламеняющиеся и горючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 6 лет',
          'Не реже одного раза в год',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63629230',
        text:
          'На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются трубопроводы аммиачных холодильных установок?',
        answers: [
          'На собственную массу трубопровода и массу хладагента',
          'На массу тепловой изоляции и массу хладагента',
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятые с коэффициентом запаса 1,2',
          'На массу тепловой изоляции трубопровода, принятую с коэффициентом запаса 1,5',
        ],
        correctAnswers: [
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятые с коэффициентом запаса 1,2',
        ],
      },
      {
        code: '63629231',
        text:
          'Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?',
        answers: [
          'Первичный пуск компрессора в работу необходимо выполнять вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя',
          'Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты',
          'При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63629232',
        text:
          'Какую скорость паров аммиака в сечении паровой зоны должен обеспечивать размер паровой зоны вертикального сосуда или аппарата, исполняющего функции отделения жидкости?',
        answers: [
          'Не более 0,5 м/с',
          'Не более 1 м/с',
          'Правилами не регламентируется',
          'Не более 1,5 м/с',
        ],
        correctAnswers: ['Не более 0,5 м/с'],
      },
      {
        code: '63629233',
        text:
          'С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?',
        answers: [
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 10 дней',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63629234',
        text:
          'Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?',
        answers: [
          'Запрещено для защиты технологического оборудования использовать неметаллические покрытия',
          'Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже одного раза в месяц',
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
          'Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями',
        ],
        correctAnswers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
      },
      {
        code: '63629235',
        text:
          'Каким забором следует ограждать территорию склада жидкого аммиака, расположенного на предприятии?',
        answers: [
          'Бетонным забором высотой более 2,5 м',
          'Деревянным забором высотой не менее 1,5 м',
          'Сетчатым забором',
          'Забором из несгораемых материалов высотой не менее 2 м',
        ],
        correctAnswers: ['Сетчатым забором'],
      },
      {
        code: '63629236',
        text:
          'Какие требования к технологическим трубопроводам для растворителя, мисцеллы и паров растворителя указаны неверно?',
        answers: [
          'Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом',
          'Технологические трубопроводы размещаются с учетом возможности выполнения работ по обслуживанию и ремонту',
          'Технологические трубопроводы размещаются с учетом возможности проведения визуального контроля их состояния',
          'На маховиках или рукоятках арматуры должны быть нанесены стрелки, указывающие направления их вращения',
        ],
        correctAnswers: [
          'Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом',
        ],
      },
      {
        code: '63629237',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63629238',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более одного года',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63629239',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Все перечисленные требования указаны верно',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63629240',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
          'До окончания работ на конкретном месте',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63629241',
        text:
          'Каким образом разрешается размещать штуцера на резервуарах для хранения жидкого аммиака?',
        answers: [
          'Штуцера для выдачи жидкого аммиака разрешается размещать в нижней части резервуаров, штуцера для выдачи жидкого дренажа - в верхней части резервуаров',
          'Штуцера для дренажа разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
          'Штуцера для контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
          'Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
        ],
        correctAnswers: [
          'Штуцера для выдачи жидкого аммиака, дренажа, промывки и контрольно-измерительных приборов и автоматизации разрешается размещать в нижней части резервуаров, остальные штуцера - в верхней части резервуаров',
        ],
      },
      {
        code: '63629242',
        text:
          'Какие требования к системам противоаварийной защиты, обеспечивающим защиту резервуаров жидкого аммиака от переполнения, указаны неверно?',
        answers: [
          'Для резервуаров вместимостью до 10 м³ (включительно) защита обеспечивается дублированием систем контроля параметров',
          'Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров',
          'Для резервуаров вместимостью до 50 м³ защита обеспечивается дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния',
          'Для резервуаров вместимостью 50 м³ и более защита обеспечивается дублированием систем контроля, наличием систем самодиагностики и сопоставлением технологически связанных параметров',
        ],
        correctAnswers: [
          'Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров',
        ],
      },
      {
        code: '63629243',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случае износа боковых граней головок болтов и гаек',
          'В случаях изгиба болтов и шпилек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63629244',
        text:
          'Каким должен быть процент первичного заполнения жидким аммиаком внутреннего объема воздухоохладителей с верхней подачей аммиака?',
        answers: ['30%', '50%', '15%', '80%'],
        correctAnswers: ['50%'],
      },
      {
        code: '63629245',
        text:
          'Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?',
        answers: [
          'Не менее четырех диаметров трубы',
          'Не менее трех диаметров трубы',
          'Не менее двух диаметров трубы',
          'Соответствовать диаметру трубы',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63629246',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только обследование участка трубопровода, на котором производилась замена',
          'Должны выполняться все перечисленные мероприятия',
          'Только испытание на прочность пробным давлением',
          'Только испытание на плотность',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63629247',
        text:
          'Какой должна быть вместимость поддона, на который следует устанавливать производственные емкости с фосфором?',
        answers: [
          'Объемом не менее емкости резервуара с фосфором',
          'Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм',
          'Объемом, равным сумме объемов всех производственных емкостей с фосфором',
          'Объемом не менее вместимости одного наибольшего резервуара и слоя воды не более 100 мм',
        ],
        correctAnswers: [
          'Объемом не менее вместимости одного наибольшего резервуара и слоя воды не менее 200 мм',
        ],
      },
      {
        code: '63629248',
        text:
          'С какой периодичностью проводится термографирование наружной поверхности резервуара жидкого аммиака в целях выявления участков с нарушенной теплоизоляцией?',
        answers: [
          'Один раз в квартал',
          'Один раз в полгода',
          'Один раз в год',
          'Один раз в месяц',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63629249',
        text:
          'Под каким избыточным давлением должна постоянно находиться вся система электровозгонки фосфора?',
        answers: [
          'Не менее 2 мм водяного столба',
          'Не более 2 мм водяного столба',
          'Не более 3 мм водяного столба',
          'Не менее 3 мм водяного столба',
        ],
        correctAnswers: ['Не менее 3 мм водяного столба'],
      },
      {
        code: '63629250',
        text:
          'По какой категории надежности должно осуществляться электроснабжение электроприемников объектов производств масел?',
        answers: [
          'Электроснабжение должно осуществляться только по 1 категории надежности',
          'Для блоков II, III категорий взрывопожароопасности должно предусматриваться электроснабжение не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения контрольно-измерительных приборов и автоматики сжатым воздухом, систем противопожарной защиты - не ниже 1 категории',
          'Электроснабжение должно осуществляться не ниже 2 категории надежности',
        ],
        correctAnswers: [
          'Для блоков II, III категорий взрывопожароопасности должно предусматриваться электроснабжение не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения контрольно-измерительных приборов и автоматики сжатым воздухом, систем противопожарной защиты - не ниже 1 категории',
        ],
      },
      {
        code: '63629251',
        text:
          'В каком случае допускается определение толщин стенок трубопроводов иным способом, отличным от метода неразрушающего контроля?',
        answers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
          'Допускается в соответствии с документацией на ХОПО',
          'Допускается в присутствии инспектора Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
        ],
      },
      {
        code: '63629252',
        text:
          'Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?',
        answers: [
          'По наружным стенам зданий, не связанных с обращением кислот и щелочей',
          'Через вспомогательные помещения',
          'В местах пересечения железных и автомобильных дорог',
          'Через бытовые помещения',
        ],
        correctAnswers: ['В местах пересечения железных и автомобильных дорог'],
      },
      {
        code: '63629253',
        text:
          'В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?',
        answers: [
          'В желтый цвет',
          'В красный цвет',
          'В черный цвет',
          'В синий цвет',
        ],
        correctAnswers: ['В желтый цвет'],
      },
      {
        code: '63629254',
        text:
          'Какое максимальное разрежение поддерживается для предотвращения попадания фосфорного ангидрида в атмосферу цеха в башне сжигания?',
        answers: [
          '3 мм вод. ст.',
          '5 мм вод. ст.',
          '6 мм вод. ст.',
          '10 мм вод. ст.',
        ],
        correctAnswers: ['5 мм вод. ст.'],
      },
      {
        code: '63629255',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'На факельную установку',
          'В специальные контейнеры',
          'В закрытые системы для дальнейшей утилизации',
          'В централизованную систему водоотведения',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63629256',
        text:
          'Какие требования к тепловой изоляции резервуаров жидкого аммиака указаны неверно?',
        answers: [
          'Тепловая изоляция выполняется из негорючих или трудносгораемых материалов',
          'Для изоляции внутренних стен и крыши изотермических резервуаров рекомендуется применять вспученный перлитовый песок стандартного гранулометрического состава с влажностью не более 0,8% мас.',
          'При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке',
          'Не допускается увлажнение теплоизоляционных материалов и устройств в процессе их хранения, транспортировки и монтажа',
        ],
        correctAnswers: [
          'При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке',
        ],
      },
      {
        code: '63629257',
        text:
          'В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Выберите два правильных варианта ответа.',
        answers: [
          'Если такое решение принято технологической службой организации, производства, отделения, установки',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'При сроке освоения производства более года',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если в тексте технологического регламента обнаружены подчистки и поправки',
        ],
        correctAnswers: [
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
        ],
      },
      {
        code: '63629258',
        text:
          'В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?',
        answers: [
          'Не допускается ни в каком случае',
          'Если это определено в проектной документации',
          'Если клапан рассчитан на полную пропускную способность',
          'Если геометрический объем сосуда не превышает 0,3 м³',
        ],
        correctAnswers: [
          'Если геометрический объем сосуда не превышает 0,3 м³',
        ],
      },
      {
        code: '63629259',
        text:
          'В случае какой остановки сосуды, аппараты и трубопроводы холодильных установок должны подвергаться техническому освидетельствованию?',
        answers: [
          'В случае остановки более трех месяцев',
          'В случае остановки более одного месяца',
          'Длительность остановки не влияет на необходимость проведения технического освидетельствования',
          'В случае остановки более шести месяцев',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629260',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63629261',
        text:
          'Кем должна определяться готовность холодильной системы к заполнению хладагентом после завершения монтажных работ и проведения испытаний на прочность и плотность?',
        answers: [
          'Техническим руководителем эксплуатирующей организации',
          'Проектной организацией',
          'Комиссией эксплуатирующей организации',
          'Комиссией под председательством представителя Ростехнадзора',
        ],
        correctAnswers: ['Комиссией эксплуатирующей организации'],
      },
      {
        code: '63629262',
        text:
          'Какое из перечисленных требований к манометрам, контролирующим давление при испытании на прочность сосудов (аппаратов), трубопроводов, указано неверно?',
        answers: [
          'Один манометр должен устанавливаться у источника давления (воздушный компрессор, баллон с инертным газом), другой - на сосуде (аппарате) и трубопроводе в точке, наименее удаленной от воздушного компрессора',
          'Давление при испытании должно контролироваться двумя манометрами, опломбированными и прошедшими поверку',
          'Манометры должны быть одинакового класса точности, не ниже 2,5',
          'Манометры должны быть с диаметром корпуса не менее 160 мм и шкалой на номинальное давление, равное 4/3 от измеряемого давления',
        ],
        correctAnswers: [
          'Манометры должны быть одинакового класса точности, не ниже 2,5',
        ],
      },
      {
        code: '63629263',
        text:
          'Какова периодичность осмотра технического состояния трубок конденсаторов объектов производства масел?',
        answers: [
          'Согласно графику, определенному руководителем организации, но не реже 1 раза в год',
          'Определяется технологическим регламентом',
          'Не реже 1 раза в 1,5 года',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: [
          'Согласно графику, определенному руководителем организации, но не реже 1 раза в год',
        ],
      },
      {
        code: '63629264',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63629265',
        text:
          'Чем должно быть обеспечено технологическое оборудование, в котором возможно образование взрывоопасных смесей на объектах, связанных с производством растительных масел?',
        answers: [
          'Системами подачи флегматизатора (азота)',
          'Средствами пожаротушения',
          'Предупреждающими ограждениями',
          'Защитными кожухами',
        ],
        correctAnswers: ['Системами подачи флегматизатора (азота)'],
      },
      {
        code: '63629266',
        text:
          'В каком месте может размещаться оборудование, работающее на аммиаке?',
        answers: [
          'Только в машинном или аппаратном отделении',
          'Только в помещении потребителей холода',
          'Только на открытой площадке',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63629267',
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ['3 месяца', '6 месяцев', '1 месяц', 'Один год'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63629268',
        text:
          'Какой вид временных соединений может применяться для стыковки железнодорожной цистерны к стационарным узлам холодильной установки?',
        answers: [
          'Шарнирные поворотные соединения',
          'Гибкие рукава из неметаллических материалов',
          'Гибкие металлические рукава',
          'Все перечисленные виды соединений',
        ],
        correctAnswers: ['Все перечисленные виды соединений'],
      },
      {
        code: '63629269',
        text:
          'Что необходимо учитывать при размещении технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках?',
        answers: [
          'Возможность быстрого демонтажа оборудования',
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Минимизацию контакта с коррозионно-активными веществами',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
        ],
      },
      {
        code: '63629270',
        text:
          'Каким образом определяется выбор метода измерения (объемный, весовой) жидкого аммиака?',
        answers: [
          'Указаниями территориального органа Ростехнадзора',
          'Внутренней эксплуатационной документацией',
          'Правилами безопасности складов жидкого аммиака',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63629271',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное, дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное по месту',
          'Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное, дистанционное',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление',
        ],
      },
      {
        code: '63629272',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'По I или II категории надежности',
          'Только по I категории надежности',
          'По II или III категории надежности',
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
        ],
        correctAnswers: ['По I или II категории надежности'],
      },
      {
        code: '63629273',
        text:
          'Какая устанавливается максимальная температура масличного сырья в бункерах складов и силосных ячейках элеваторов объектов производств растительных масел?',
        answers: ['40 °С', '50 °С', '35 °С', '30 °С'],
        correctAnswers: ['40 °С'],
      },
      {
        code: '63629274',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Только на один производственный поток или на мощность производства в целом',
          'Только на единицу выпускаемой продукции',
          'Только на единицу времени (час)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63629275',
        text:
          'Каким должен быть объем контроля сварных швов резервуаров для хранения жидкого аммиака?',
        answers: [
          'Длина контролируемого участка швов - не менее 25% от длины каждого шва',
          'Длина контролируемого участка швов - не менее 50% от длины каждого шва',
          'Длина контролируемого участка швов - не менее 75% от длины каждого шва',
          '100%',
        ],
        correctAnswers: ['100%'],
      },
      {
        code: '63629276',
        text:
          'В каком случае разрешается установка запорного устройства на вытяжной трубе объектов производств растительных масел?',
        answers: [
          'На вытяжной трубе дефлегматора',
          'Если вытяжная труба оборудована пароэжектором или вентилятором',
          'На вытяжной трубе конденсатора',
          'На вытяжной трубе абсорбера',
        ],
        correctAnswers: [
          'Если вытяжная труба оборудована пароэжектором или вентилятором',
        ],
      },
      {
        code: '63629277',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63629278',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63629279',
        text:
          'Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?',
        answers: [
          'Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций',
          'Только включение светового и звукового сигналов при превышении предельно допустимых концентраций',
          'Только регистрация всех случаев загазованности приборами',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63629280',
        text:
          'С какой периодичностью необходимо проверять отходящую из конденсатора воду на присутствие аммиака?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63629281',
        text:
          'Какое из перечисленных требований при складировании фосфида цинка указано верно?',
        answers: [
          'Высота штабелей банок с фосфидом цинка не должна превышать двух банок',
          'Укладывать банки с фосфидом цинка необходимо так, чтобы верхняя опиралась дном на две банки нижнего ряда',
          'Расстояние между штабелями банок должно быть не менее 1 м',
        ],
        correctAnswers: [
          'Укладывать банки с фосфидом цинка необходимо так, чтобы верхняя опиралась дном на две банки нижнего ряда',
        ],
      },
      {
        code: '63629282',
        text:
          'Какую потребность не должна превышать вместимость резервуаров в дозаторном отделении цехов, потребляющих фосфор?',
        answers: [
          'Трехсуточной потребности производства в фосфоре',
          'Пятисуточной потребности производства в фосфоре',
          'Суточной потребности производства в фосфоре',
          'Двухсуточной потребности производства в фосфоре',
        ],
        correctAnswers: ['Двухсуточной потребности производства в фосфоре'],
      },
      {
        code: '63629283',
        text:
          'Какое из перечисленных требований к поддонам (приямкам) для сбора жидкого аммиака в случае разгерметизации сосуда указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу',
          'Количество пролитого аммиака из защитного ресивера должно определяться по рабочему заполнению сосуда',
          'Глубина приямка должна быть не более 3 м',
          'Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)',
          'Количество пролитого аммиака из циркуляционного ресивера должно определяться по максимально допустимому заполнению сосуда',
        ],
        correctAnswers: [
          'Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу',
          'Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)',
        ],
      },
      {
        code: '63629284',
        text:
          'Какие требования установлены к отделениям, в которых производят дробление пека, приготовление или разогрев электродной массы, и к оборудованию в этих отделениях?',
        answers: [
          'Отделения должны быть изолированы от остальных рабочих помещений, к оборудованию специальных требований не установлено',
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть герметичным',
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть снабжено средствами местного отсоса',
        ],
        correctAnswers: [
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
        ],
      },
      {
        code: '63629285',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
          'Провести экспертизу промышленной безопасности',
          'Провести реконструкцию химически опасного производственного объекта',
          'Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
        ],
      },
      {
        code: '63629286',
        text:
          'Какие устройства, оснащаемые фильтр для мисцеллы, указаны неверно?',
        answers: [
          'Предохранительный клапан с отводом мисцеллы в сборник нефильтрованной мисцеллы',
          'Смотровой фонарь на сливной трубе фильтрованной мисцеллы',
          'Вакуумметр на сливной трубе фильтрованной мисцеллы',
          'Штуцер с манометром для продувки фильтра инертным газом',
        ],
        correctAnswers: ['Вакуумметр на сливной трубе фильтрованной мисцеллы'],
      },
      {
        code: '63629287',
        text:
          'На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?',
        answers: [
          'На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
        ],
        correctAnswers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
        ],
      },
      {
        code: '63629288',
        text:
          'Каким документом обосновывается достаточность мер по максимальному снижению уровня взрывоопасности технологических блоков, предотвращению взрывов и загораний внутри технологического оборудования, производственных помещений и наружных установок объектов, связанных с производством растительных масел?',
        answers: [
          'Декларацией промышленной безопасности',
          'Обоснованием безопасности опасного производственного объекта',
          'Проектной документацией',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63629289',
        text:
          'С какой периодичностью проводится только наружный осмотр при техническом освидетельствовании трубопроводов?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 8 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 2 года'],
      },
      {
        code: '63629290',
        text:
          'Каким из перечисленных способов не допускается прокладка кабелей по территории предприятий и установок?',
        answers: [
          'В галереях',
          'На кабельных конструкциях технологических эстакад',
          'В каналах, засыпанных песком',
          'Допускаются все перечисленные способы прокладки',
        ],
        correctAnswers: ['Допускаются все перечисленные способы прокладки'],
      },
      {
        code: '63629291',
        text:
          'Какие требования к барабанам и контейнерам, заполненным пятисернистым фосфором, установлены Правилами безопасности химически опасных производственных объектов?',
        answers: [
          'Барабаны и контейнеры следует хранить в сухих, непроветриваемых помещениях',
          'Барабаны и контейнеры должны быть герметично закрыты и храниться в сухих проветриваемых складах',
          'Барабаны и контейнеры должны храниться при температуре не выше +10 °С',
          'Барабаны и контейнеры должны быть оборудованы приборами, сигнализирующими об их переполнении',
        ],
        correctAnswers: [
          'Барабаны и контейнеры должны быть герметично закрыты и храниться в сухих проветриваемых складах',
        ],
      },
      {
        code: '63629292',
        text:
          'Что должна обеспечивать система электрического управления механизмами поточно-транспортных систем при производстве фосфора и его соединений?',
        answers: [
          'Только электрическую блокировку всех механизмов от завала транспортируемых веществ с применением реле скорости для элеваторов и транспортеров',
          'Только аварийное отключение транспортеров с помощью троса, соединенного с выключателем',
          'Только предпусковую звуковую сигнализацию',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63629293',
        text:
          'Какие условия должны соблюдаться при перемещении по трубопроводам застывающих продуктов и расплавов, способных кристаллизоваться?',
        answers: [
          'Перемещения должны осуществляться по обогреваемым трубопроводам типа "труба в трубе" или со спутниками-теплоносителями в режиме, исключающем забивку трубопроводов',
          'Перемещения должны осуществляться по охлаждаемым трубопроводам',
          'Перемещения в трубопроводе должны осуществляться в среде соответствующего растворителя',
          'Для застывающих продуктов и расплавов, способных кристаллизоваться, перемещения по трубопроводам не допускаются',
        ],
        correctAnswers: [
          'Перемещения должны осуществляться по обогреваемым трубопроводам типа "труба в трубе" или со спутниками-теплоносителями в режиме, исключающем забивку трубопроводов',
        ],
      },
      {
        code: '63629294',
        text:
          'Какой показатель необходимо контролировать для предотвращения попадания фосфорной кислоты в оборотную систему водоснабжения?',
        answers: [
          'Температуру раствора',
          'Давление в системе',
          'рН нагретой воды',
          'Все перечисленные',
        ],
        correctAnswers: ['рН нагретой воды'],
      },
      {
        code: '63629295',
        text:
          'Какова предельно допустимая величина концентрации взрывоопасной парогазовой фазы сигнализации средств автоматического газового анализа в производственных помещениях на открытых наружных установках?',
        answers: [
          'Не более 30% от нижнего концентрационного предела распространения пламени',
          'Не более 40% от нижнего концентрационного предела распространения пламени',
          'Не более 20% от нижнего концентрационного предела распространения пламени',
          'Не более 25% от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Не более 20% от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63629296',
        text:
          'На основе каких данных составляется материальный баланс для действующих производств?',
        answers: [
          'На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента',
          'На основе данных проектной документации',
          'На основе данных проектной документации с учетом внесенных в нее изменений, включения или исключения дополнительных операций или стадий',
        ],
        correctAnswers: [
          'На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента',
        ],
      },
      {
        code: '63629297',
        text:
          'Какая ширина должна быть у автомобильных дорог для подъезда к складу жидкого аммиака и проезду по его территории к зданиям и сооружениям?',
        answers: [
          'Не менее 3 м',
          'Не менее 3,5 м',
          'Не менее 2 м',
          'Не менее 2,5 м',
        ],
        correctAnswers: ['Не менее 3,5 м'],
      },
      {
        code: '63629298',
        text: 'В каком случае аппарат (сосуд) подлежит немедленной остановке?',
        answers: [
          'Только в случае неисправности предусмотренных проектной документацией контрольно-измерительных приборов и средств автоматики',
          'Только в случае неисправности предохранительных клапанов',
          'Только в случае неисправности указателя уровня жидкости',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63629299',
        text:
          'Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?',
        answers: [
          'Организация-изготовитель',
          'Орган по сертификации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Разработчик документации',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63629300',
        text:
          'Кем определяются порядок, способы и периодичность уборки пыли в производственных помещениях объектов производств растительных масел?',
        answers: [
          'Проектной организацией',
          'Сторонней специализированной организацией',
          'Техническим руководителем организации',
          'Руководителем организации',
        ],
        correctAnswers: ['Руководителем организации'],
      },
      {
        code: '63629301',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы в кДж',
          'Радиусом зон разрушения в м',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Категорией взрывоопасности технологических блоков',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63629302',
        text:
          'Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Выберите два правильных варианта ответа.',
        answers: [
          'В машинном отделении',
          'В аппаратном отделении',
          'На открытой площадке',
          'Над машинным отделением',
        ],
        correctAnswers: ['В машинном отделении', 'В аппаратном отделении'],
      },
      {
        code: '63629303',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?',
        answers: [
          'Должно быть организовано только управление по месту',
          'Должно быть организовано только управление дистанционно (из безопасного места)',
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63629304',
        text:
          'В каком случае допускается неавтоматическое включение технических устройств, задействованных в системе локализации аварийных ситуаций на складах жидкого аммиака?',
        answers: [
          'Если это обосновано проектом',
          'Если это установлено внутренним регламентом организации',
          'Если нет угрозы распространения химического заражения за территорию объекта',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Если это обосновано проектом'],
      },
      {
        code: '63629305',
        text:
          'Какое из перечисленных требований к испытанию на прочность после монтажа до пуска в эксплуатацию сосуда (аппарата), трубопровода (его участка) указано верно?',
        answers: [
          'Использование запорной арматуры для отключения испытываемого сосуда (аппарата) и трубопровода допускается в присутствии лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
          'Толщина заглушки должна быть рассчитана на условия работы при давлении выше пробного в 2,5 раза',
          'При периодическом освидетельствовании и техническом диагностировании с использованием метода АЭ и аммиака в качестве нагружающей среды не допускается испытывать отдельные технологические линии блоком',
        ],
        correctAnswers: [
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
        ],
      },
      {
        code: '63629306',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода только после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода только после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63629307',
        text:
          'Какая минимальная ширина прохода должна быть предусмотрена в складе между штабелями мешков с серой?',
        answers: ['1 м', '2 м', '3 м', '4 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63629308',
        text:
          'Допускается ли применение мерных стекол на резервуарах для хранения жидкого аммиака?',
        answers: [
          'Допускается',
          'Не допускается',
          'Допускается только при специальном обосновании в проектной документации',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63629309',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'Все перечисленные требования указаны верно',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63629310',
        text:
          'В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?',
        answers: [
          'В справочной литературе',
          'В эксплуатационной документации организации-изготовителя',
          'В руководствах по безопасности',
          'В технологических регламентах',
        ],
        correctAnswers: [
          'В эксплуатационной документации организации-изготовителя',
        ],
      },
      {
        code: '63629311',
        text:
          'Какие номинальные величины загазованности аммиаком должны контролироваться в помещениях машинных и аппаратных отделений аммиачных холодильных установок?',
        answers: [
          '10, 125 и 500 мг/м³',
          '15, 70 и 1000 мг/м³',
          '20, 60 и 500 мг/м³',
          '5, 300 и 1500 мг/м³',
        ],
        correctAnswers: ['20, 60 и 500 мг/м³'],
      },
    ],
    63641: [
      {
        code: '63641000',
        text:
          'С какой скоростью следует снижать уровень воды в оборудовании после заполнения для обеспечения медленного окисления пирофорных отложений при очистке оборудования от пирофорных соединений?',
        answers: [
          'Не более 0,5 м в час',
          'Не более 0,8 м в час',
          'Не более 1 м в час',
          'Не более 1,2 м в час',
        ],
        correctAnswers: ['Не более 0,5 м в час'],
      },
      {
        code: '63641001',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'Только П-образные компенсаторы',
          'Только Г-образные компенсаторы',
          'Только Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63641002',
        text:
          'При какой единичной производительности центробежных или поршневых компрессоров, работающих на один коллектор нагнетания кислорода, и давлении кислорода в трубопроводе нагнетания кислорода, устанавливают отключающую арматуру?',
        answers: [
          'Более 2100 м3/ч и свыше 1,8 МПа соответственно',
          'Более 2000 м3/ч и свыше 1,6 МПа соответственно',
          'Более 2200 м3/ч и свыше 2,0 МПа соответственно',
          'Более 2500 м3/ч и свыше 2,6 МПа соответственно',
        ],
        correctAnswers: ['Более 2000 м3/ч и свыше 1,6 МПа соответственно'],
      },
      {
        code: '63641003',
        text:
          'Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?',
        answers: [
          'Получение разрешения на применения технических устройств, выдаваемого органами Ростехнадзора',
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
      },
      {
        code: '63641004',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
          'Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования',
        ],
        correctAnswers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
        ],
      },
      {
        code: '63641005',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63641006',
        text:
          'Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
          'Насосы и компрессоры должны оснащаться маховиками для облегчения повторного самозапуска электродвигателей',
          'Токи самозапуска электродвигателей не должны превышать номинальные более чем в 2 раза',
          'Время срабатывания систем самозапуска должно определяться нагрузкой насосов и компрессоров, но не должно превышать 5 минут',
        ],
        correctAnswers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
        ],
      },
      {
        code: '63641007',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '6 месяцев',
          '24 месяца',
          '36 месяцев',
          '12 месяцев',
          '3 месяца',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63641008',
        text:
          'Какое требование не соответствует предъявляемым требованиям к специальным системам аварийного освобождения при проектировании технологических схем для новых производств для аварийного освобождения технологических блоков от обращающихся продуктов?',
        answers: [
          'Специальные системы аварийного освобождения должны находиться в постоянной готовности и исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Специальные системы аварийного освобождения могут использоваться для других целей',
          'Специальные системы аварийного освобождения должны находиться в постоянной готовности и оснащаться средствами контроля и управления',
          'Специальные системы аварийного освобождения должны находиться в постоянной готовности и обеспечивать минимально возможное время освобождения',
        ],
        correctAnswers: [
          'Специальные системы аварийного освобождения могут использоваться для других целей',
        ],
      },
      {
        code: '63641009',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
          'Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
          'Во всех указанных местах',
        ],
        correctAnswers: ['Во всех указанных местах'],
      },
      {
        code: '63641010',
        text:
          'Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?',
        answers: [
          'Правилами безопасности химически опасных производственных объектов',
          'Технологическим регламентом, технологическими инструкциями',
          'Техническим руководителем эксплуатирующей организации',
          'Сторонней специализированной организацией',
        ],
        correctAnswers: [
          'Технологическим регламентом, технологическими инструкциями',
        ],
      },
      {
        code: '63641011',
        text:
          'Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?',
        answers: [
          'Исходными данными на проектирование',
          'Технологическим регламентом',
          'Проектной документацией',
          'Технической документацией производителя',
        ],
        correctAnswers: ['Технической документацией производителя'],
      },
      {
        code: '63641012',
        text:
          'Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?',
        answers: [
          'Только защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Только бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Должны быть обеспечены все указанные требования',
        ],
        correctAnswers: ['Должны быть обеспечены все указанные требования'],
      },
      {
        code: '63641013',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.',
        answers: [
          'Клапаны, отсекающие и другие отключающие устройства',
          'Запорную и запорно-регулирующую арматуру',
          'Мануальные системы подавления взрыва',
          'Средства, регистрирующие превышение давления',
        ],
        correctAnswers: [
          'Клапаны, отсекающие и другие отключающие устройства',
          'Запорную и запорно-регулирующую арматуру',
        ],
      },
      {
        code: '63641014',
        text:
          'Какое время срабатывания системы защиты установлено в федеральных нормах и правилах?',
        answers: [
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
          'Не более 60 секунд',
        ],
        correctAnswers: [
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
        ],
      },
      {
        code: '63641015',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту',
          'Определяется разработчиком проекта',
          'Должно быть организовано управление дистанционно',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63641016',
        text:
          'Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?',
        answers: [
          'Первичный пуск компрессора в работу необходимо выполнять вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя',
          'При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя',
          'Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63641017',
        text:
          'Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63641018',
        text:
          'Какими из перечисленных приборов могут быть оснащены сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора?',
        answers: [
          'Только средствами блокировки',
          'Только сигнализацией по максимальному уровню',
          'Только приборами контроля уровня',
          'Всеми перечисленными приборами',
        ],
        correctAnswers: ['Всеми перечисленными приборами'],
      },
      {
        code: '63641019',
        text:
          'Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?',
        answers: [
          'Дистиллят, полученный из воды питьевого качества',
          'Конденсат, полученный из заводской системы пароснабжения',
          'Масла, рекомендованные разработчиками компрессора',
          'Умягченную воду, полученную из заводской системы пароснабжения',
        ],
        correctAnswers: ['Дистиллят, полученный из воды питьевого качества'],
      },
      {
        code: '63641020',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену',
        ],
        correctAnswers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63641021',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
          'Время срабатывания систем защиты должно равняться времени, необходимому для перехода параметра от предупредительного до предельно допустимого значения',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63641022',
        text:
          'Какой должна быть свободная высота эстакад для технологических трубопроводов над проездами и проходами для железнодорожных путей (над головкой рельса)?',
        answers: [
          'Не менее 5,55 м',
          'Не менее 5,0 м',
          'Не менее 6,0 м',
          'Не менее 4,5 м',
        ],
        correctAnswers: ['Не менее 5,55 м'],
      },
      {
        code: '63641023',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 10% НКПР',
          'При 18% НКПР',
          'При 20% НКПР',
          'При 15% НКПР',
        ],
        correctAnswers: ['При 20% НКПР'],
      },
      {
        code: '63641024',
        text:
          'В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: [
          'Время должно быть подтверждено расчетом, но не менее 20 минут',
          'Время должно быть подтверждено расчетом, но не более 45 минут',
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Время должно быть подтверждено расчетом, но не менее 35 минут',
        ],
        correctAnswers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63641025',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Эксплуатирующими организациями',
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Ростехнадзором',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63641026',
        text:
          'Чем подтверждаются эффективность и надежность средств взрывозащиты и локализации пламени и других противоаварийных устройств, направленных на взрывозащищенность оборудования?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63641027',
        text:
          'При достижении какого уровня заполнения газгольдера автоматически производится отключение компрессоров (газодувок), подключенных к газгольдеру?',
        answers: [
          'Максимального',
          'Минимального',
          'Предминимального',
          'Предмаксимального',
        ],
        correctAnswers: ['Минимального'],
      },
      {
        code: '63641028',
        text:
          'В каком случае допускаются отклонения от действующего проекта, на основании которого проводятся работы, связанные с восстановлением работоспособного (исправного) состояния технологических трубопроводов?',
        answers: [
          'По решению комиссии эксплуатирующей организации',
          'При наличии письменного распоряжения технического руководителя эксплуатирующей организации',
          'В случае согласования необходимого отклонения с территориальным органом Ростехнадзора',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: ['Не допускаются ни в каком случае'],
      },
      {
        code: '63641029',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Только при появлении трещин, срыва или коррозионного износа резьбы',
          'Только в случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'Только в случаях изгиба болтов и шпилек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63641030',
        text:
          'Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?',
        answers: ['90 °C', '160 °C', '185 °C', '135 °C'],
        correctAnswers: ['160 °C'],
      },
      {
        code: '63641031',
        text:
          'Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
          'Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63641032',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63641033',
        text:
          'Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?',
        answers: [
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд',
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 120 секунд',
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
        ],
        correctAnswers: [
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
        ],
      },
      {
        code: '63641034',
        text:
          'Чем должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей? Выберите два правильных варианта ответа.',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
          'Средствами контроля температуры перемещаемой жидкости',
          'Системами измерения плотности и вязкости перекачиваемых сред',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
        ],
      },
      {
        code: '63641035',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'Только в технологических блоках II категории взрывоопасности',
          'Только в технологических блоках I категории взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
          'В технологических блоках всех категорий взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63641036',
        text:
          'С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 10 лет',
          'Не реже 1 раза в 5 лет',
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
        correctAnswers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63641037',
        text:
          'Какие из перечисленных мер по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Предотвращение взрывов и травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
        ],
        correctAnswers: [
          'Предотвращение взрывов и травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
      },
      {
        code: '63641038',
        text:
          'Чем должны оснащаться насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Автономной электрической подстанцией',
          'Системами самозапуска электродвигателей',
          'Устройствами для сбора и удаления жидкой фазы',
          'Блокировками',
        ],
        correctAnswers: ['Системами самозапуска электродвигателей'],
      },
      {
        code: '63641039',
        text:
          'Как производится включение компрессора, отключенного из-за прекращения подачи охлаждающей воды?',
        answers: [
          'Только после возобновления подачи воды',
          'Только после его охлаждения',
          'Только после проведения отбора проб для анализа газа и последующего возобновления подачи воды',
          'Только после его охлаждения и возобновления подачи воды',
        ],
        correctAnswers: [
          'Только после его охлаждения и возобновления подачи воды',
        ],
      },
      {
        code: '63641040',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63641041',
        text:
          'При какой периодичности использования трубопроводной арматуры ручной привод следует располагать на высоте не более 1,6 м от уровня пола или площадки, с которой ведется управление?',
        answers: [
          'Не реже одного раза в смену',
          'Расположение привода не зависит от частоты использования арматуры',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63641042',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63641043',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63641044',
        text: 'Где следует устанавливать компрессорные агрегаты?',
        answers: [
          'Под эстакадами технологических трубопроводов с горючими, едкими и взрывоопасными продуктами',
          'На открытых площадках насосных и компрессорных установок',
          'В помещении машинного (аппаратного) отделения',
          'Допускается установка во всех перечисленных местах',
        ],
        correctAnswers: ['В помещении машинного (аппаратного) отделения'],
      },
      {
        code: '63641045',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63641046',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над автодорогами',
          'На трубопроводах, проложенных по эстакадами',
          'На трубопроводах, идущих по стенам зданий',
          'Над местами, предназначенными для прохода людей и рабочими площадками',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей и рабочими площадками',
        ],
      },
      {
        code: '63641047',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Всем перечисленным требованиям',
          'Иметь документы о прохождении аттестации',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63641048',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой дополнительных буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63641049',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в проектной документации',
          'Только в технологическом регламенте на производство продукции',
          'Только в исходных данных на проектирование',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63641050',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Критическими параметрами технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Техническими характеристиками применяемых насосов и компрессоров',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63641051',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63641052',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63641053',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63641054',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63641055',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'Только в проектной документации',
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'Только в технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63641056',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63641057',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: [
          'III категории',
          'II категории',
          'На одну выше',
          'I категории',
        ],
        correctAnswers: ['На одну выше'],
      },
      {
        code: '63641058',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
        ],
      },
      {
        code: '63641059',
        text:
          'Каким документом устанавливаются места расположения запорных и (или) отсекающих устройств?',
        answers: [
          'Планом ликвидации аварий',
          'Проектной документацией или документацией на техническое перевооружение',
          'Проектом производства работ',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63641060',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 8 часов',
          'В течение 24 часов',
          'Время устанавливается в проектной документации',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63641061',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'Только для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Только для обеспечения энергоэффективности',
          'Только в случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63641062',
        text:
          'Чем должны оснащаться сушильные установки, имеющие непосредственный контакт высушиваемого продукта с сушильным агентом?',
        answers: [
          'Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки',
          'Звуковой сигнализацией',
          'Блокировками',
          'Световой сигнализацией',
          'Сепараторами',
        ],
        correctAnswers: [
          'Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки',
        ],
      },
      {
        code: '63641063',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
          'После проведения расчета на прочность',
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63641064',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только испытание на прочность пробным давлением',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63641065',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Нарушение энергообеспечения',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63641066',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых согласно Плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Требования к обозначению определяются при разработке Плана мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63641067',
        text:
          'На каких технологических трубопроводах не допускается применять сальниковые компенсаторы?',
        answers: [
          'На трубопроводах со средами 1-ой группы',
          'На трубопроводах для трудногорючих веществ',
          'На трубопроводах для негорючих веществ',
          'Допускается применять на всех перечисленных трубопроводах',
        ],
        correctAnswers: ['На трубопроводах со средами 1-ой группы'],
      },
      {
        code: '63641068',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
          'В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий и их развития',
          'В соответствии со сценариями возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63641069',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Только средствами автоматического регулирования',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Только средствами противоаварийной защиты',
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63641070',
        text:
          'Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?',
        answers: [
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами, устанавливающими требования к технологическим трубопроводам с учетом условий эксплуатации',
          'При прокладке трубопроводов через строительные конструкции зданий и другие препятствия принимаются меры, исключающие возможность передачи дополнительных нагрузок на трубы',
          'Трубопроводы не должны иметь фланцевых или других разъемных соединений',
        ],
        correctAnswers: [
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
        ],
      },
      {
        code: '63641071',
        text:
          'Какие из перечисленных требований к компрессорным установкам указаны неверно?',
        answers: [
          'Порядок срабатывания систем блокировок насосов и компрессоров должен быть определен программой (алгоритмом) срабатывания системы ПАЗ технологической установки',
          'Запорная отсечная арматура, устанавливаемая на всасывающем трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'Запорная отсечная арматура, устанавливаемая на нагнетательном трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
        ],
      },
      {
        code: '63641072',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63641073',
        text:
          'Для каких технологических трубопроводов стилоскопирование проводится выборочно, но не менее двух соединений, выполненных одним сварщиком с использованием сварочных материалов из одной партии, при отсутствии в проекте иных указаний?',
        answers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
          'Для технологических трубопроводов при расчетном давлении выше 10 МПа',
          'Для технологических трубопроводов опасных веществ 1-го и 2-го классов опасности',
          'Для всех перечисленных технологических трубопроводов',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
        ],
      },
      {
        code: '63641074',
        text:
          'Каким из перечисленных требований должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Полы в помещении должны быть нескользкими, с повышенной механической стойкостью',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63641075',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
          'Комиссия эксплуатирующей организации',
          'Инспектор территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63641076',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите два правильных варианта ответа.',
        answers: [
          'Возможность проведения ремонтных работ',
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
          'Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ',
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
        ],
      },
      {
        code: '63641077',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
        ],
        correctAnswers: [
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
        ],
      },
      {
        code: '63641078',
        text:
          'Какие из перечисленных действий допускаются при эксплуатации компрессоров?',
        answers: [
          'Попадание масла на фундамент компрессора',
          'Сброс в атмосферу продувок компрессоров без очистки от масла',
          'Пуск компрессоров, работающих на воздухе со щелочной очисткой, при отключенных аппаратах щелочной очистки и открытом байпасе',
        ],
        correctAnswers: [
          'Пуск компрессоров, работающих на воздухе со щелочной очисткой, при отключенных аппаратах щелочной очистки и открытом байпасе',
        ],
      },
      {
        code: '63641079',
        text:
          'Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных складских (расходных) емкостей?',
        answers: [
          'На путях необщего пользования',
          'На технологических путях организации',
          'В местах проведения погрузочно-разгрузочных работ',
          'Нигде не разрешается',
        ],
        correctAnswers: ['Нигде не разрешается'],
      },
      {
        code: '63641080',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Возможные инциденты в работе и способы их ликвидации',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Технологические схемы производства',
          'Характеристика производимой продукции',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63641081',
        text:
          'В каком случае допускается укладка технологических трубопроводов в два яруса и более?',
        answers: [
          'Диаметром до 300 мм включительно',
          'Диаметром до 400 мм включительно',
          'Диаметром до 500 мм включительно',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Диаметром до 300 мм включительно'],
      },
      {
        code: '63641082',
        text:
          'Какой категории взрывоопасности технологических блоков не существует?',
        answers: [
          'II категории',
          'I категории',
          'III категории',
          'IV категории',
        ],
        correctAnswers: ['IV категории'],
      },
      {
        code: '63641083',
        text:
          'Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?',
        answers: [
          'Не ниже 20 °C и не более 50 °C соответственно',
          'Не ниже 5 °C и не более 35 °C соответственно',
          'Не ниже 10 °C и не более 45 °C соответственно',
          'Не ниже 15 °C и не более 40 °C соответственно',
        ],
        correctAnswers: ['Не ниже 10 °C и не более 45 °C соответственно'],
      },
      {
        code: '63641084',
        text:
          'Чем должны быть оснащены подводящие к смесителям коммуникации, проектируемые с целью обеспечения максимально возможного уровня эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
          'Фильтрами',
          'Автоматическим газоанализатором',
          'Автоматическими датчиками давления',
        ],
        correctAnswers: [
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
        ],
      },
      {
        code: '63641085',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На технологическую службу организации, производства, отделения, установки',
          'На организацию - разработчика процесса',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63641086',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63641087',
        text:
          'Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Только с пластмассовым уплотнением в затворе',
          'Только с резиновым уплотнением в затворе',
          'Только с тканевым уплотнением в затворе',
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
        correctAnswers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63641088',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Испытание трубопровода на прочность и плотность',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Осуществляется все перечисленное',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63641089',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63641090',
        text:
          'В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите два правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков повышения температуры',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63641091',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от вида взрывозащиты',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63641092',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Проектной организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63641093',
        text:
          'Каким должно быть содержание масла в кислороде, поступающем в компрессоры и газодувки?',
        answers: [
          'Содержание масла не должно превышать 0,1 мг/м3',
          'Содержание масла не должно превышать 0,04 мг/м3',
          'Содержание масла не должно превышать 0,02 мг/м3',
          'Содержание масла не должно превышать 0,2 мг/м3',
        ],
        correctAnswers: ['Содержание масла не должно превышать 0,02 мг/м3'],
      },
      {
        code: '63641094',
        text:
          'Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?',
        answers: [
          'Риски срабатывания автоматических систем противоаварийной защиты',
          'Требования территориальных органов Ростехнадзора',
          'Требования организации - изготовителя реакционной аппаратуры',
          'Указания технического руководителя организации',
        ],
        correctAnswers: [
          'Риски срабатывания автоматических систем противоаварийной защиты',
        ],
      },
      {
        code: '63641095',
        text:
          'В каком случае допускается стационарное применение цельносварных гофрированных стальных труб, включая конструкции с теплоизоляционными и (или) защитными слоями?',
        answers: [
          'При обосновании в проекте',
          'По решению технической комиссии эксплуатирующей организации',
          'При согласовании с территориальным органом Ростехнадзора',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['При обосновании в проекте'],
      },
      {
        code: '63641096',
        text:
          'Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией',
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63641097',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности соотношение давлений не регламентируется',
          'Соотношение давлений негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса',
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности соотношение давлений не регламентируется',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63641098',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 1 часа',
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63641099',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Гидравлический расчет',
          'Статический и динамический прочностный расчет',
          'Теплотехнический расчет',
          'Расчет на прочность и устойчивость',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63641100',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2 ) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На сети водоснабжения и канализации',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2 ) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63641101',
        text:
          'С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом диаметра и протяженности трубопровода',
          'С учетом марки стали трубопровода',
          'С учетом характеристики транспортируемой среды',
          'С учетом толщины стенки трубопровода',
        ],
        correctAnswers: [
          'С учетом диаметра и протяженности трубопровода',
          'С учетом характеристики транспортируемой среды',
        ],
      },
      {
        code: '63641102',
        text:
          'Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Ручное управление по месту',
          'Автоматическое',
          'Дистанционное, неавтоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63641103',
        text:
          'Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?',
        answers: [
          'Для технологических блоков I категории взрывоопасности',
          'Для технологических блоков II категории взрывоопасности',
          'Для технологических блоков III категории взрывоопасности',
        ],
        correctAnswers: [
          'Для технологических блоков I категории взрывоопасности',
        ],
      },
      {
        code: '63641104',
        text:
          'В каких случаях на трубопроводах следует применять арматуру под приварку?',
        answers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности',
        ],
        correctAnswers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
        ],
      },
      {
        code: '63641105',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система парового отопления',
          'Система водяного отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63641106',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы в кДж',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Радиусом зон разрушения в м',
          'Категорией взрывоопасности технологических блоков',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63641107',
        text:
          'Какие технологические блоки относятся к первой категории взрывоопасности?',
        answers: [
          'Qв меньше 27, m, кг меньше 2000',
          'Qв 27 - 37, m, кг 2000 - 5000',
          'Qв больше 37, m, кг больше 5000',
        ],
        correctAnswers: ['Qв больше 37, m, кг больше 5000'],
      },
      {
        code: '63641108',
        text:
          'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальника цеха',
          'Начальника производства',
          'Начальника центральной лаборатории организации',
          'Заместителя руководителя организации по охране окружающей среды',
          'Главного метролога организации',
        ],
        correctAnswers: ['Начальника цеха', 'Начальника производства'],
      },
      {
        code: '63641109',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63641110',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск и (или) прекращающими работу насоса при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу насоса при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63641111',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
        correctAnswers: [
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63641112',
        text:
          'Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчик процесса',
          'Заказчик в задании на проектирование',
          'Разработчик проекта',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63641113',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
          'Только при деформации фланцев',
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63641114',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63641115',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63641116',
        text:
          'Кем определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'Разработчиком проекта по расчетным данным',
          'Разработчиком проекта по литературным (справочным) данным',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63641117',
        text:
          'С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?',
        answers: [
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 10 дней',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63641118',
        text:
          'Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?',
        answers: [
          'Из сталеалюминиевых сплавов',
          'Из чугуна',
          'Из неметаллических конструкционных материалов',
          'Из стали',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63641119',
        text:
          'Что не соответствует установленным требованиям безопасности при обслуживании компрессора?',
        answers: [
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть снабжены знаками безопасности, запрещающими вход в камеры',
          'Во время работы воздушного центробежного компрессора и воздушного фильтра вход обслуживающего персонала в помещение камеры после воздушного фильтра (камера чистого воздуха) не допускается',
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть закрыты',
        ],
        correctAnswers: [
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
        ],
      },
      {
        code: '63641120',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63641121',
        text: 'Чем должно быть оснащено оборудование для разделения суспензий?',
        answers: [
          'Фильтрами',
          'Обратным клапаном',
          'Гидрозатвором',
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
        correctAnswers: [
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63641122',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63641123',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63641124',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Перила должны быть высотой не менее 1,3 м',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63641125',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63641126',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
          'Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
        ],
        correctAnswers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
        ],
      },
      {
        code: '63641127',
        text:
          'Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?',
        answers: [
          'Монтажно-наладочная организация',
          'Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов',
          'Проектная организация',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Проектная организация'],
      },
      {
        code: '63641128',
        text:
          'В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?',
        answers: [
          'В соответствии с требованиями Общих правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии с требованиями территориальных органов Ростехнадзора',
          'В соответствии с требованиями законодательства о градостроительной деятельности',
          'В соответствии с техническими регламентами',
        ],
        correctAnswers: [
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
      },
      {
        code: '63641129',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'Если это определено и обосновано научно-исследовательской организацией',
          'По решению территориального органа Ростехнадзора',
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
          'Если это определено и обосновано разработчиком проекта',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63641130',
        text:
          'От какого вида электричества должны предусматриваться меры защиты при проектировании сливоналивных эстакад сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только от статического электричества',
          'Только от атмосферного электричества',
          'От атмосферного и статического электричества',
        ],
        correctAnswers: ['От атмосферного и статического электричества'],
      },
      {
        code: '63641131',
        text:
          'Что не должна исключать энергетическая устойчивость технологической системы с учетом категории взрывоопасности входящих в нее блоков, особенностей технологического процесса?',
        answers: [
          'Возможность образования в системе взрывоопасной среды (за счет увеличения времени пребывания продуктов в реакционной зоне, нарушения соотношения поступающих в нее продуктов, развития неуправляемых процессов)',
          'Возможность нарушения герметичности системы (разгерметизации уплотнений подвижных соединений, разрушения оборудования от превышения давления)',
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
        ],
        correctAnswers: [
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
        ],
      },
      {
        code: '63641132',
        text:
          'С какими подразделениями должна быть оборудована система двусторонней громкоговорящей связи для объектов с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками',
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками',
        ],
      },
      {
        code: '63641133',
        text:
          'Какого показателя категорий взрывоопасности технологических блоков не существует?',
        answers: ['II', 'III', 'I', 'IV'],
        correctAnswers: ['IV'],
      },
      {
        code: '63641134',
        text:
          'Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите два правильных варианта ответа.',
        answers: [
          'На наружных установках',
          'В помещении диспетчера предприятия',
          'На контрольно-пропускном пункте объекта',
          'На ограждении по периметру объекта через каждые 50 м',
        ],
        correctAnswers: [
          'На наружных установках',
          'В помещении диспетчера предприятия',
        ],
      },
      {
        code: '63641135',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от датчиков повышения температуры',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
        ],
        correctAnswers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
        ],
      },
      {
        code: '63641136',
        text:
          'В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование и технологическом регламенте',
          'В технологическом регламенте',
          'В проектной документации',
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63641137',
        text:
          'На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?',
        answers: [
          'На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа',
          'На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
        ],
        correctAnswers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
        ],
      },
      {
        code: '63641138',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с технологическими регламентами на производство продукции',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63641139',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63641140',
        text:
          'Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?',
        answers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности',
        ],
        correctAnswers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
        ],
      },
      {
        code: '63641141',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63641142',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63641143',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63641144',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63641145',
        text:
          'Каких значений не должна превышать максимальная температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?',
        answers: [
          '95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63641146',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Организацией - разработчиком процесса',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
          'Специализированной сторонней организацией',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63641147',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура в соответствии с проектной документацией',
          'Арматура с тканевым уплотнением в затворе',
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с пластиковым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63641148',
        text:
          'Каким требованиям должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Полы в помещении должны быть холодными, с повышенной механической стойкостью',
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63641149',
        text:
          'Когда должно происходить автоматическое включение аварийной вентиляции, установленной в анализаторных помещениях?',
        answers: [
          'Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 18% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 15% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 10% НКПР',
        ],
        correctAnswers: [
          'Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР',
        ],
      },
      {
        code: '63641150',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 10 минут',
          'Не менее 5 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63641151',
        text:
          'При каком давлении во всасывающих коммуникациях компрессоров, сжимающих продукты разделения воздуха, данные компрессоры должны автоматически отключаться?',
        answers: [
          'При снижении давления ниже 1,5 кПа',
          'При снижении давления ниже 1,0 кПа',
          'При снижении давления ниже 0,5 кПа',
          'При снижении давления ниже 0,6 кПа',
        ],
        correctAnswers: ['При снижении давления ниже 0,5 кПа'],
      },
      {
        code: '63641152',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Установка заглушек',
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63641153',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление дистанционно (из безопасного места)',
          'Должно быть организовано управление по месту',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63641154',
        text:
          'В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63641155',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63641156',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Обратный клапан',
          'Запорный клапан',
          'Предохранительный клапан',
          'Регулирующий клапан',
          'Гидроклапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63641157',
        text:
          'Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений',
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
        correctAnswers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
      },
      {
        code: '63641158',
        text:
          'Какими приборами могут быть оснащены сепараторы, устанавливаемые на всасывающей линии компрессора, вакуум-насоса, газодувки для отделения жидкой фазы из перемещаемой газовой среды?',
        answers: [
          'Только приборами контроля уровня',
          'Только сигнализацией по максимальному уровню',
          'Только средствами блокировки',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63641159',
        text:
          'Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Скорость распространения горения обращающихся в процессе веществ',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
          'Класс опасности обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
      },
      {
        code: '63641160',
        text:
          'Какие линии электроснабжения от внешних источников независимо от класса напряжения не должны оборудоваться устройствами автоматической частной разгрузки?',
        answers: [
          'Питающие потребителей особой группы I категории надежности электроснабжения',
          'Питающие потребителей II категории надежности электроснабжения',
          'Питающие потребителей III категории надежности электроснабжения',
          'Питающие потребителей любых категорий надежности электроснабжения',
        ],
        correctAnswers: [
          'Питающие потребителей особой группы I категории надежности электроснабжения',
        ],
      },
      {
        code: '63641161',
        text:
          'Где должно осуществляться измельчение, смешивание измельченных твердых горючих продуктов для исключения образования в системе взрывоопасных смесей?',
        answers: [
          'В естественной среде',
          'В среде, богатой углекислым газом',
          'В среде, богатой кислородом',
          'В среде инертного газа',
        ],
        correctAnswers: ['В среде инертного газа'],
      },
      {
        code: '63641162',
        text:
          'В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?',
        answers: [
          'Только в соответствии с требованиями технических регламентов',
          'Только в соответствии с требованиями градостроительной деятельности',
          'Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии со всеми перечисленными требованиями',
        ],
        correctAnswers: ['В соответствии со всеми перечисленными требованиями'],
      },
      {
        code: '63641163',
        text:
          'Какая аппаратура оснащается средствами автоматического контроля, регулирования и сигнализации уровня хладагента в теплообменных элементах?',
        answers: [
          'Реакционные аппараты взрывоопасных технологических процессов с перемешивающими устройствами',
          'Аппаратура для ведения жидкофазных процессов',
          'Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости',
        ],
        correctAnswers: [
          'Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости',
        ],
      },
      {
        code: '63641164',
        text:
          'Выполнение какого условия, относящегося к трубопроводам и компрессорам продуктов разделения воздуха, допускается?',
        answers: [
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
          'Объединение дренажных трубопроводов',
          'Возможность попадания кислорода во всасывающую линию при обкатке компрессоров',
          'Все перечисленные условия',
        ],
        correctAnswers: [
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
        ],
      },
      {
        code: '63641165',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63641166',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать его автоматическую остановку при снижении давления газа, подаваемого в лабиринтные уплотнения?',
        answers: [
          'С давлением нагнетания 0,5 МПа',
          'С давлением нагнетания свыше 0,6 МПа',
          'С давлением нагнетания 0,6 МПа',
          'С давлением нагнетания свыше 1,6 МПа',
        ],
        correctAnswers: ['С давлением нагнетания свыше 0,6 МПа'],
      },
      {
        code: '63641167',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др. нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчиком проекта',
          'Разработчиком процесса и проекта',
          'Заказчиком в задании на проектирование',
          'Разработчиком процесса',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63641168',
        text:
          'Какое из перечисленных требований к прокладке и устройству технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Сварные и разъемные соединения трубопроводов внутри футляров или гильз допускаются в соответствии с проектом',
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы в производственных помещениях должны прокладываться закрыто',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
        ],
        correctAnswers: [
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
        ],
      },
      {
        code: '63641169',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63641170',
        text:
          'Какой шириной допускается предусматривать проход между выступающими частями компрессорных агрегатов для вновь строящихся и реконструируемых систем холодоснабжения?',
        answers: [
          'Не менее 1 м',
          'Не менее 1,5 м',
          'Не менее 1,2 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 1 м'],
      },
      {
        code: '63641171',
        text:
          'Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?',
        answers: [
          'Разработчиком проекта',
          'Разработчиком проекта по исходным данным',
          'Разработчиком проекта по расчетным данным',
          'Разработчиком проекта по литературным (справочным) данным',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63641172',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'Со средой 1-ой группы',
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63641173',
        text:
          'На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?',
        answers: [
          'На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди',
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия',
        ],
        correctAnswers: [
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
        ],
      },
      {
        code: '63641174',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63641175',
        text:
          'Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?',
        answers: [
          'Без предохранительного клапана',
          'Без регулируемого клапана',
          'Без фильтра',
          'Без гидрозатвора',
        ],
        correctAnswers: ['Без гидрозатвора'],
      },
      {
        code: '63641176',
        text:
          'Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?',
        answers: [
          'Резиновые гибкие шланги',
          'Пластмассовые гибкие шланги',
          'Съемные участки трубопроводов',
          'Гибкие металлические шланги',
        ],
        correctAnswers: ['Съемные участки трубопроводов'],
      },
      {
        code: '63641177',
        text:
          'С учетом каких критериев выбираются насосы и компрессоры, используемые для перемещения газов, легковоспламеняющихся и горючих жидкостей, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом регламентированных параметров технологического процесса',
          'С учетом диаметра и толщины стенки трубопроводов',
          'С учетом физико-химических свойств перемещаемых продуктов',
          'С учетом протяженности и способа прокладки трубопроводов',
        ],
        correctAnswers: [
          'С учетом регламентированных параметров технологического процесса',
          'С учетом физико-химических свойств перемещаемых продуктов',
        ],
      },
      {
        code: '63641178',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63641179',
        text:
          'Какие требования к эксплуатации поршневых компрессоров указаны неверно?',
        answers: [
          'Для смазки поршневой группы компрессоров, подающих воздух в воздухоразделительные установки, следует использовать масла, рекомендованные разработчиками компрессора',
          'Необходимо контролировать работу маслоочищающих сальников. Попадание машинного масла в цилиндры не допускается',
          'Наличие нагара в клапанных коробках и трубопроводах поршневых компрессоров не допускается',
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
          'Не допускается использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей',
        ],
        correctAnswers: [
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
        ],
      },
      {
        code: '63641180',
        text:
          'Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63641181',
        text:
          'Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений',
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
        correctAnswers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
      },
      {
        code: '63641182',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63641183',
        text:
          'На каких отдельных участках трассы допускается прокладка технологических трубопроводов в полупроходных каналах?',
        answers: [
          'Протяженностью не более 100 м',
          'Протяженностью не более 200 м',
          'Протяженностью не более 250 м',
          'Протяженностью не более 150 м',
        ],
        correctAnswers: ['Протяженностью не более 100 м'],
      },
      {
        code: '63641184',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
        ],
        correctAnswers: [
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
        ],
      },
      {
        code: '63641185',
        text:
          'Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала',
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
          'Посредством наглядных пособий (плакатов) по охране труда',
          'Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
        ],
        correctAnswers: [
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
      },
      {
        code: '63641186',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63641187',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63641188',
        text:
          'Чем оснащаются системы разделения газожидкостных смесей в целях обеспечения высокой эффективности разделения фаз?',
        answers: [
          'Гидрозатвором',
          'Фильтрами',
          'Фазоразделителями',
          'Обратным клапаном',
        ],
        correctAnswers: ['Фазоразделителями'],
      },
      {
        code: '63641189',
        text:
          'Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?',
        answers: [
          'Только под эстакадами технологических трубопроводов',
          'Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
        correctAnswers: [
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
      },
      {
        code: '63641190',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.',
        answers: [
          'Средства, регистрирующие повышение давления',
          'Запорную и запорно-регулирующую арматуру',
          'Системы аварийной вытяжной вентиляции',
          'Клапаны, отсекающие и другие отключающие устройства',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру',
          'Клапаны, отсекающие и другие отключающие устройства',
        ],
      },
      {
        code: '63641191',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 50 кг',
          'Массой более 40 кг',
          'Массой более 30 кг',
          'Массой более 35 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63641192',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
          'По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63641193',
        text:
          'Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?',
        answers: [
          'Оценку эффективности технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности технологических блоков и в целом всей технологической схемы',
          'Оценку энергетического уровня каждого технологического блока и определение категории его взрывоопасности',
          'Оценку эффективности и надежности мер, обеспечивающих взрывобезопасность каждого технологического блока',
          'В проектной документации производится оценка всех перечисленных параметров',
        ],
        correctAnswers: [
          'В проектной документации производится оценка всех перечисленных параметров',
        ],
      },
      {
        code: '63641194',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Отключение систем в случае переключений на резервный или аварийный источник электропитания не позднее 0,5 секунды',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63641195',
        text:
          'Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?',
        answers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
          'Научно-технической литературой',
          'Справочной литературой',
          'Данными, запрашиваемыми у научно-исследовательской организации',
        ],
        correctAnswers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
        ],
      },
      {
        code: '63641196',
        text:
          'Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?',
        answers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при QВ ≤ 10 допускается ручное дистанционное управление',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63641197',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Базальтовое супертонкое волокно',
          'Вату из непрерывного стеклянного волокна',
          'Допускаются все перечисленные материалы',
          'Порошкообразные теплоизоляционные материалы',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63641198',
        text:
          'Каким образом должны подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств для оборудования (аппаратов и трубопроводов) до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63641199',
        text:
          'Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите два правильных варианта ответа.',
        answers: [
          'Эффективное разделение этих продуктов и возможность образования застойных зон',
          'Разработка методов отвода тепла',
          'Разработка методов приостановки реакции путем увеличения подачи одного из смешиваемых веществ',
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
        ],
        correctAnswers: [
          'Разработка методов отвода тепла',
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
        ],
      },
      {
        code: '63641200',
        text:
          'Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Периодически пересматриваемые',
          'Входящие в состав проектной документации или пусковые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63641201',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63641202',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать помещения управления к зданиям',
          'Помещения управления должны быть отдельно стоящими',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63641203',
        text:
          'В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?',
        answers: [
          'Для ступени низкого давления двухступенчатых компрессоров',
          'Для одноступенчатых компрессоров',
          'Для ступени высокого давления двухступенчатых компрессоров',
        ],
        correctAnswers: [
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
      },
      {
        code: '63641204',
        text:
          'Где не располагается узел ввода теплоносителя на взрывопожароопасных производствах?',
        answers: [
          'В электропомещениях и помещениях контрольно-измерительных приборов и автоматики',
          'В помещениях систем приточной вентиляции (в вентиляционной камере)',
          'В самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'В производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
        ],
        correctAnswers: [
          'В электропомещениях и помещениях контрольно-измерительных приборов и автоматики',
        ],
      },
      {
        code: '63641205',
        text:
          'В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?',
        answers: [
          'Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам',
          'Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам',
          'В любом из указанных случаев',
        ],
        correctAnswers: ['В любом из указанных случаев'],
      },
      {
        code: '63641206',
        text:
          'Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами контроля за плотностью поступающих на разделение продукта и флегмы',
          'Средствами контроля за компонентным составом поступающего на разделение продукта',
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
        ],
        correctAnswers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
        ],
      },
      {
        code: '63641207',
        text:
          'Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Класс опасности обращающихся в процессе веществ',
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63641208',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63641209',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63641210',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Разработчик проекта',
          'Разработчик технологического процесса',
          'Представитель организации - изготовителя систем противоаварийной автоматической защиты',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63641211',
        text:
          'Что должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
          'Только показатели надежности и конструктивные особенности',
          'Показатели надежности, конструктивные особенности и параметры работы',
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов, исполнение по взрывозащите',
        ],
        correctAnswers: [
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
        ],
      },
      {
        code: '63641212',
        text:
          'Какие требования предъявляются к частоте продувки влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?',
        answers: [
          'Продувка должна производиться через каждые 60 минут',
          'Продувка должна производиться через каждые 40 минут',
          'Продувка должна производиться через каждые 20 минут',
          'Продувка должна производиться через каждые 30 минут',
        ],
        correctAnswers: ['Продувка должна производиться через каждые 30 минут'],
      },
      {
        code: '63641213',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Предотвращение взрывов внутри технологического оборудования',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Периодический контроль за состоянием воздушной среды',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63641214',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63641215',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Переход из режима ожидания в рабочее состояние должен осуществляться в течение 30 секунд',
          'Быть мобильными, иметь небольшие габариты и вес',
        ],
        correctAnswers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
        ],
      },
      {
        code: '63641216',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63641217',
        text:
          'С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?',
        answers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
          'С учетом возможности проведения эффективной и безопасной очистки системы',
          'С учетом химического анализа проб горючих веществ на содержание кислорода',
        ],
        correctAnswers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
      },
      {
        code: '63641218',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Во всех перечисленных случаях производятся диагностические работы',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63641219',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Только схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Только расчет на прочность',
          'Только регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63641220',
        text:
          'Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?',
        answers: [
          'Изменения вносятся при наличии положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
          'Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
        ],
        correctAnswers: [
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
        ],
      },
      {
        code: '63641221',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие токсичные, высокотоксичные вещества, органические теплоносители, воспламеняющиеся и горючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 6 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63641222',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63641223',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В техническом задании на изготовление оборудования',
          'В паспортах оборудования и трубопроводной арматуры',
          'В технологическом регламенте',
          'В проектной документации',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63641224',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть изолировано от действующей системы, а нанесенное на нем обозначение номера по технологической схеме закрашено',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63641225',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'В грунте',
          'Надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'В каналах (закрытых или с засыпкой песком)',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63641226',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63641227',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Разработчиком проекта',
          'Комиссией эксплуатирующей организации',
          'Специализированной сторонней организацией',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63641228',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63641229',
        text:
          'Арматура с каким классом герметичности затвора должна устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'АА',
          'В',
          'А',
          'СС',
          'Определяется в проектной документации (документации на техническое перевооружение)',
        ],
        correctAnswers: [
          'Определяется в проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63641230',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на ОПО химических, нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с технологическими регламентами на производство продукции',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63641231',
        text:
          'Как должны располагаться внутрицеховые технологические трубопроводы с тяжелыми газами, прокладываемые по несгораемой поверхности несущих стен производственных зданий с оконными и дверными проемами?',
        answers: [
          'На 0,5 м ниже оконных и дверных проемов',
          'На 1 м выше оконных и дверных проемов',
          'На 0,5 м выше оконных и дверных проемов',
          'На 1 м ниже оконных и дверных проемов',
        ],
        correctAnswers: ['На 0,5 м ниже оконных и дверных проемов'],
      },
      {
        code: '63641232',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63641233',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63641234',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 24 часов',
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63641235',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63641236',
        text:
          'Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
          'Иметь компьютерные тренажеры, включающие приближенные к реальным динамические модели процессов и средства управления',
          'Допускать к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Иметь специализированные центры обучения и подготовки для производственного персонала',
        ],
        correctAnswers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
      },
      {
        code: '63641237',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 8 часов',
          'В течение 24 часов',
          'В течение 2 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63641238',
        text:
          'Каким требованиям должны соответствовать вновь проектируемые здания взрывопожароопасных объектов, в которых располагаются помещения управления (операторные), с постоянным пребыванием в них людей? Выберите два правильных варианта ответа.',
        answers: [
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
          'Быть устойчивыми к воздействию ударной волны',
          'Обеспечивать безопасность находящегося в них персонала',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
        ],
        correctAnswers: [
          'Быть устойчивыми к воздействию ударной волны',
          'Обеспечивать безопасность находящегося в них персонала',
        ],
      },
      {
        code: '63641239',
        text: 'Что относится к технологическим трубопроводам?',
        answers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы, предназначенные для транспортирования веществ, необходимых для ведения технологического процесса или эксплуатации оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
        correctAnswers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
      },
      {
        code: '63641240',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
          'Блокировками по отключению подачи топлива при превышении значения температуры нагреваемого жидкого продукта',
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
        ],
        correctAnswers: [
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
        ],
      },
      {
        code: '63641241',
        text:
          'На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'На основании задания на проектирование',
          'На основании справочных данных на проектирование',
          'На основании литературных данных на проектирование',
          'На основании расчетных данных на проектирование',
        ],
        correctAnswers: ['На основании задания на проектирование'],
      },
      {
        code: '63641242',
        text:
          'Как требуется размещать технологическое оборудование взрывопожароопасных производств?',
        answers: [
          'Только с учетом обеспечения безопасности обслуживания и эксплуатации',
          'Только с учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене',
          'Только с учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'С учетом всех перечисленных требований',
        ],
        correctAnswers: ['С учетом всех перечисленных требований'],
      },
      {
        code: '63641243',
        text:
          'Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только физико-химические свойства перемещаемых продуктов',
          'Только параметры технологического процесса',
          'Только показатели надежности и конструктивные особенности с учетом критических параметров',
          'Должны быть учтены все перечисленные характеристики',
        ],
        correctAnswers: ['Должны быть учтены все перечисленные характеристики'],
      },
      {
        code: '63641244',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'В соответствии с проектной документацией',
          'В соответствии с рабочей документацией',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63641245',
        text:
          'Какими приборами и средствами автоматизации должны оснащаться сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
        ],
        correctAnswers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
        ],
      },
      {
        code: '63641246',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63641247',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63641248',
        text:
          'Каким оборудованием оснащаются цистерны, предназначенные для перевозки по железным дорогам сжиженного углеводородного газа и легковоспламеняющихся жидкостей?',
        answers: [
          'Только предохранительными клапанами и другой арматурой',
          'Только сливоналивными устройствами',
          'Только средствами контроля уровня налива',
          'Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами',
        ],
        correctAnswers: [
          'Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами',
        ],
      },
      {
        code: '63641249',
        text:
          'Сколько в процентном отношении должна составлять температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?',
        answers: [
          'Не более 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63641250',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Только устройство звуковой сигнализации',
          'Только устройство световой сигнализации',
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63641251',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63641252',
        text:
          'Кем принимается решение о выводе объекта (блока, установки) из эксплуатации на длительный период и вводе этих объектов (блоков, установок) в эксплуатацию после длительных остановок?',
        answers: [
          'Эксплуатирующей организацией',
          'Проектной организацией',
          'Эксплуатирующей организацией при согласовании с Ростехнадзором',
          'Сторонней специализированной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63641253',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Дистанционное, неавтоматическое',
          'Автоматическое',
          'Ручное управление по месту',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63641254',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63641255',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?',
        answers: [
          'На любом из компрессоров с давлением нагнетания 0,6 МПа',
          'На каждом с давлением нагнетания более 0,6 МПа',
          'На одном из компрессоров с давлением нагнетания более 0,6 МПа',
          'С давлением нагнетания 0,6 МПа',
        ],
        correctAnswers: ['На каждом с давлением нагнетания более 0,6 МПа'],
      },
      {
        code: '63641256',
        text:
          'Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных складских емкостей?',
        answers: [
          'На технологических путях организации',
          'На путях необщего пользования',
          'В местах проведения погрузочно-выгрузочных работ',
          'Нигде не разрешается',
        ],
        correctAnswers: ['Нигде не разрешается'],
      },
      {
        code: '63641257',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над автодорогами',
          'На трубопроводах, проложенных по эстакадам',
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'На трубопроводах, идущих по стенам зданий',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63641258',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?',
        answers: [
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63641259',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальник производства',
          'Начальник производственно-технического (технического) отдела организации',
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
        correctAnswers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
      },
      {
        code: '63641260',
        text:
          'На какой стадии предусматриваются средства контроля и регулирования уровня разделения фаз горючих паров (газов) и жидкостей?',
        answers: [
          'На стадии эксплуатации',
          'На стадии проектирования процесса',
          'На стадии ликвидации',
          'На стадии строительства',
        ],
        correctAnswers: ['На стадии проектирования процесса'],
      },
      {
        code: '63641261',
        text:
          'Что должно проектироваться и выбираться с учетом анализа возможных рисков образования взрывоопасных веществ вследствие взаимного проникновения и взаимодействия теплоносителя с технологической средой для того, чтобы предотвратить возможность возникновения аварийных ситуаций?',
        answers: [
          'Только теплообменные процессы',
          'Только теплообменное оборудование',
          'Только тип теплоносителя (хладагента)',
          'Теплообменные процессы и теплообменное оборудование',
        ],
        correctAnswers: ['Теплообменные процессы и теплообменное оборудование'],
      },
      {
        code: '63641262',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха КИПиА на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63641263',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчиком проекта',
          'Заказчиком в задании на проектирование',
          'Разработчиком процесса',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63641264',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63641265',
        text:
          'Какими функциями должна обладать противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта',
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Блокировками по отключению подачи топлива при превышении предельно допустимого значения температуры нагреваемого жидкого продукта',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
        ],
        correctAnswers: [
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
        ],
      },
      {
        code: '63641266',
        text:
          'Кем определяется степень разделения материальных сред и меры обеспечения взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Разработчиком процесса и проекта',
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63641267',
        text:
          'Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?',
        answers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
          'Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
        ],
        correctAnswers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
        ],
      },
      {
        code: '63641268',
        text:
          'Для каких целей печи с открытым огневым процессом должны быть оборудованы паровой завесой?',
        answers: [
          'Для локализации пожара на печи при установке ее вне зданий',
          'Для локализации пожаров на печи при установке ее внутри помещений',
          'Для пожаротушения при аварии на печи',
          'Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи',
        ],
        correctAnswers: [
          'Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи',
        ],
      },
      {
        code: '63641269',
        text:
          'Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'Общеобменная вентиляция должна включаться при срабатывании установленных в помещении сигнализаторов довзрывных концентраций',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции в случае недостаточности воздухообмена',
        ],
        correctAnswers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
        ],
      },
      {
        code: '63641270',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в проектной документации',
          'Только в технологическом регламенте на производство продукции',
          'Только в исходных данных на проектирование и технологическом регламенте на производство продукции',
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63641271',
        text:
          'В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: ['30 минут', '15 минут', '1 часа', '2 часов'],
        correctAnswers: ['1 часа'],
      },
      {
        code: '63641272',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 60 минут',
          'Через 10 минут',
          'Через 30 минут',
          'Через 5 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63641273',
        text:
          'Какие из перечисленных требований к компрессорным установкам указаны неверно?',
        answers: [
          'Порядок срабатывания систем блокировок насосов и компрессоров должен быть определен программой (алгоритмом) срабатывания системы ПАЗ технологической установки',
          'Запорная отсечная арматура, устанавливаемая на всасывающем трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'Запорная отсечная арматура, устанавливаемая на нагнетательном трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
        ],
      },
      {
        code: '63641274',
        text:
          'На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?',
        answers: [
          'На три категории',
          'На пять категорий',
          'На четыре категории',
          'На две категории',
        ],
        correctAnswers: ['На три категории'],
      },
      {
        code: '63641275',
        text:
          'Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
          'Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут',
          'Средствами контроля за температурой измельченных твердых горючих продуктов',
          'Средствами контроля за фракционным составом измельченных твердых горючих продуктов',
        ],
        correctAnswers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
        ],
      },
      {
        code: '63641276',
        text:
          'Чем должны быть оснащены колонны ректификации горючих жидкостей?',
        answers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса',
          'Только средствами сигнализации об опасных отклонениях значений параметров',
          'Только средствами контроля уровня и температуры жидкости в кубовой части',
          'Только средствами ручного регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
        ],
        correctAnswers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса',
        ],
      },
      {
        code: '63641277',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики',
          'Средствами ручного управления подачей водяного пара в топочное пространство и змеевики при прогаре труб',
          'Средствами контроля за температурой в топочном пространстве',
        ],
        correctAnswers: [
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
          'Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики',
        ],
      },
      {
        code: '63641278',
        text:
          'Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Только система парового отопления',
          'Только система водяного отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63641279',
        text:
          'Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
        ],
        correctAnswers: [
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
      },
      {
        code: '63641280',
        text:
          'Каким документам должны соответствовать объемно-планировочные решения, конструкция зданий, помещений и вспомогательных сооружений для систем контроля, управления, противоаварийной автоматической защиты и газового анализа, их размещение на территории опасного производственного объекта нефтехимических производств?',
        answers: [
          'Только проектной документации',
          'Только требованиям законодательства о градостроительной деятельности',
          'Только требованиям технических регламентов',
          'Только требованиям Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'Всем перечисленным документам',
        ],
        correctAnswers: ['Всем перечисленным документам'],
      },
      {
        code: '63641281',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63641282',
        text:
          'В каком случае допускается подключение азотных компрессоров к ВРУ без газгольдеров?',
        answers: [
          'Если подключение производится через обратный клапан',
          'В соответствии с проектной документацией',
          'При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота',
          'Не допускается ни в каких случаях',
        ],
        correctAnswers: [
          'При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота',
        ],
      },
      {
        code: '63641283',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63641284',
        text:
          'Какое количество запорных арматур следует устанавливать на технологических трубопроводах взрывопожароопасных сред для герметичного отключения от коллектора агрегатов (технологических аппаратов) с рабочим давлением (далее - Pр) ≥ 4 МПа (40 кгс/см²)?',
        answers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
          'Одну единицу запорной арматуры и дренажную арматуру с заглушкой',
          'Три единицы запорной арматуры',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
        ],
      },
      {
        code: '63641285',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63641286',
        text:
          'Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?',
        answers: [
          'Правилами безопасности',
          'Проектной документацией',
          'Техническим регламентом',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63641287',
        text:
          'В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Выберите два правильных варианта ответа.',
        answers: [
          'В случае размещения холодильного оборудования над площадками на расстоянии, определенном проектной документацией',
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
      },
      {
        code: '63641288',
        text:
          'При какой скорости падения давления результаты дополнительного пневматического испытания на герметичность признаются удовлетворительными для технологических трубопроводов внутренним диаметром до 250 мм включительно со средами, относящимися к опасным веществам 1-го и 2-го классов опасности?',
        answers: [
          'Не более 0,1% за 1 час',
          'Не более 0,2% за 1 час',
          'Не более 0,8% за 1 час',
          'Не более 0,4% за 1 час',
        ],
        correctAnswers: ['Не более 0,1% за 1 час'],
      },
      {
        code: '63641289',
        text:
          'Какие требования предъявляются к очистке масла воздуха, выходящего из поршневого детандера?',
        answers: [
          'Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом',
          'Очистка выполняется в детандерных фильтрах в соответствии с инструкцией по эксплуатации разработчика оборудования',
          'Очистка выполняется в основных детандерных фильтрах в соответствии с рабочей инструкцией по ведению технологического процесса очистки воздуха',
          'Очистка выполняется в основных детандерных фильтрах в соответствии с технологической инструкцией. При загрязнении контрольного фильтра должна быть проверена работа основных фильтров',
        ],
        correctAnswers: [
          'Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом',
        ],
      },
      {
        code: '63641290',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63641291',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
          'Устройствами для подключения линий водорода',
          'Циклонами',
          'Виброситом',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63641292',
        text:
          'Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?',
        answers: [
          'Проектной документацией или документацией на техническое перевооружение',
          'Эксплуатационной документацией',
          'Паспортом технического устройства',
          'Планом ликвидации аварий',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63641293',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Только наличие природных оврагов, выемок, низин',
          'Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63641294',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63641295',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Определяется разработчиком проекта',
          'Дистанционно (из безопасного места)',
          'По месту и дистанционно (из безопасного места)',
          'По месту',
        ],
        correctAnswers: ['По месту и дистанционно (из безопасного места)'],
      },
      {
        code: '63641296',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - маслом, продувка - паром',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
    ],
    63631: [
      {
        code: '63631000',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63631001',
        text:
          'Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?',
        answers: [
          'Риски срабатывания автоматических систем противоаварийной защиты',
          'Требования территориальных органов Ростехнадзора',
          'Указания технического руководителя организации',
          'Требования организации - изготовителя реакционной аппаратуры',
        ],
        correctAnswers: [
          'Риски срабатывания автоматических систем противоаварийной защиты',
        ],
      },
      {
        code: '63631002',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др., нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчиком в задании на проектирование',
          'Разработчиком процесса',
          'Разработчиком проекта',
          'Разработчиками процесса и проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63631003',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случаях изгиба болтов и шпилек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63631004',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63631005',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту',
          'Должно быть организовано управление дистанционно',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63631006',
        text:
          'В каких нормативных правовых актах установлены требования к качеству изготовления технологического оборудования, машин, трубопроводов и трубопроводной арматуры?',
        answers: [
          'В технических регламентах',
          'В паспортах оборудования, машин, трубопроводов и трубопроводной арматуры',
          'В правилах устройства и безопасной эксплуатации соответствующих видов оборудования',
          'В ГОСТах',
        ],
        correctAnswers: ['В технических регламентах'],
      },
      {
        code: '63631007',
        text:
          'С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С Ростехнадзором',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и Ростехнадзором',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63631008',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В технологическом регламенте на производство продукции',
          'В исходных данных на проектирование',
          'В проектной документации',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63631009',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 6',
          'Не менее 15',
          'Не менее 10',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63631010',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 3 лет',
          'Не более 10 лет',
          'Не более 5 лет',
          'Не более 20 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63631011',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63631012',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63631013',
        text:
          'Какое из перечисленных требований к регазификационным установкам СПГ указано неверно?',
        answers: [
          'Высота свечи рассеивания паров СПГ должна определяться из условия обеспечения допустимой плотности теплового потока на оборудование и персонал при возможном воспламенении сброса и его безопасном рассеивании без воспламенения',
          'Не допускается осуществлять сброс в атмосферу',
          'Регазификационные установки должны оборудоваться приборами контроля и регулирования и предохранительными устройствами',
          'Сброс от предохранительных устройств следует направлять в факельную систему',
        ],
        correctAnswers: ['Не допускается осуществлять сброс в атмосферу'],
      },
      {
        code: '63631014',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63631015',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63631016',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63631017',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите 2 правильных варианта ответа.',
        answers: [
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта',
          'Блокировками по отключению подачи топлива при превышении значения температуры нагреваемого жидкого продукта',
        ],
        correctAnswers: [
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
        ],
      },
      {
        code: '63631018',
        text:
          'Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?',
        answers: [
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
          'Изменения вносятся при наличии положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
          'Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
        ],
        correctAnswers: [
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
        ],
      },
      {
        code: '63631019',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите 2 правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63631020',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          'Наибольший срок, предусмотренный для этих объектов',
          '3 года',
          '5 лет',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63631021',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите 2 правильных варианта ответа.',
        answers: [
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводится не реже двух раз в смену',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
        ],
        correctAnswers: [
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63631022',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '1 год', '3 года', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63631023',
        text:
          'В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?',
        answers: [
          'В справочной литературе',
          'В руководствах по безопасности',
          'В эксплуатационной документации организации-изготовителя',
          'В технологических регламентах',
        ],
        correctAnswers: [
          'В эксплуатационной документации организации-изготовителя',
        ],
      },
      {
        code: '63631024',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите 2 правильных варианта ответа.',
        answers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Приборами измерения перепада давления на сепараторе',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
        ],
        correctAnswers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
        ],
      },
      {
        code: '63631025',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
          'Только средствами противоаварийной защиты',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Только средствами автоматического регулирования',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63631026',
        text:
          'Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?',
        answers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности',
        ],
        correctAnswers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
        ],
      },
      {
        code: '63631027',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: [
          '5 лет',
          '2 года',
          '3 года',
          'Определяются руководством объекта',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63631028',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В паспортах оборудования и трубопроводной арматуры',
          'В техническом задании на изготовление оборудования',
          'В проектной документации',
          'В технологическом регламенте',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63631029',
        text:
          'Какое оборудование ОПО СПГ следует располагать в корпусе основания гравитационного типа?',
        answers: [
          'Резервуары хранения хладагента',
          'Оборудование отгрузки СПГ',
          'Оборудование производства СПГ',
          'Факельные системы отпарного газа',
        ],
        correctAnswers: ['Резервуары хранения хладагента'],
      },
      {
        code: '63631030',
        text:
          'На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?',
        answers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
          'На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
          'На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении',
        ],
        correctAnswers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
        ],
      },
      {
        code: '63631031',
        text: 'Где следует устанавливать компрессорные агрегаты?',
        answers: [
          'Над площадками открытых насосных и компрессорных установок',
          'Под эстакадами технологических трубопроводов с горючими, едкими и взрывоопасными продуктами',
          'В помещении машинного (аппаратного) отделения',
          'Допускается установка во всех перечисленных местах',
        ],
        correctAnswers: ['В помещении машинного (аппаратного) отделения'],
      },
      {
        code: '63631032',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите 2 правильных варианта ответа.',
        answers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
          'Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
        ],
        correctAnswers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
        ],
      },
      {
        code: '63631033',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
        correctAnswers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63631034',
        text:
          'Какой шириной допускается предусматривать проход между выступающими частями компрессорных агрегатов для вновь строящихся и реконструируемых систем холодоснабжения?',
        answers: [
          'Не более 1,2 м',
          'Не менее 1,5 м',
          'Не менее 1 м',
          'Не более 0,8 м',
        ],
        correctAnswers: ['Не менее 1 м'],
      },
      {
        code: '63631035',
        text:
          'Для оборудования, работающего под каким избыточным давлением, должны быть предусмотрены меры защиты от превышения давления в случае, если в этом оборудовании не исключена возможность повышения давления?',
        answers: [
          '0,1 МПа и ниже',
          '0,09 МПа и ниже',
          '0,07 МПа и ниже',
          '0,08 МПа и ниже',
        ],
        correctAnswers: ['0,07 МПа и ниже'],
      },
      {
        code: '63631036',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 8 часов',
          'В течение 24 часов',
          'Время устанавливается в проектной документации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63631037',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63631038',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Испытание трубопровода на прочность и плотность',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Осуществляется все перечисленное',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63631039',
        text:
          'Каким образом должны быть оформлены результаты анализа риска аварии на ОПО СПГ?',
        answers: [
          'Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены специалистами, которые не участвовали при первоначальной процедуре оценки риска аварии',
          'Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены только теми специалистами, которые участвовали при первоначальной процедуре оценки риска аварии',
          'Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены любыми специалистами, которые не участвовали при первоначальной процедуре оценки риска аварии',
        ],
        correctAnswers: [
          'Так, чтобы выполненные расчеты и выводы могли быть проверены и повторены специалистами, которые не участвовали при первоначальной процедуре оценки риска аварии',
        ],
      },
      {
        code: '63631040',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: [
          'На одну выше',
          'I категории',
          'II категории',
          'III категории',
        ],
        correctAnswers: ['На одну выше'],
      },
      {
        code: '63631041',
        text:
          'Какое из перечисленных требований к технологическим процессам, связанным с обращением сжиженного природного газа, указано неверно?',
        answers: [
          'Требования к резервированию систем и устройств (оборудования) определяются в руководствах по безопасности с учетом результатов анализа опасностей технологических процессов',
          'Выбор оборудования должен осуществляться при проектировании исходя из обеспечения заданных технологических параметров процессов',
          'В проектной документации должны быть предусмотрены решения по локализации аварийных проливов СПГ и хладагентов на ОПО СПГ',
          'При проектировании технологических систем следует учитывать возможное снижение температуры среды при аварийном сбросе, дросселировании газового потока, испарении жидкой фазы',
        ],
        correctAnswers: [
          'Требования к резервированию систем и устройств (оборудования) определяются в руководствах по безопасности с учетом результатов анализа опасностей технологических процессов',
        ],
      },
      {
        code: '63631042',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63631043',
        text:
          'Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
          'Время срабатывания систем самозапуска должно определяться нагрузкой насосов и компрессоров, но не должно превышать 5 минут',
          'Насосы и компрессоры должны оснащаться маховиками для облегчения повторного самозапуска электродвигателей',
          'Токи самозапуска электродвигателей не должны превышать номинальные более чем в два раза',
        ],
        correctAnswers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
        ],
      },
      {
        code: '63631044',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'В соответствии с рабочей документацией',
          'В соответствии с проектной документацией',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63631045',
        text:
          'Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?',
        answers: [
          'Для технологических блоков I категории взрывоопасности',
          'Для технологических блоков II категории взрывоопасности',
          'Для технологических блоков III категории взрывоопасности',
        ],
        correctAnswers: [
          'Для технологических блоков I категории взрывоопасности',
        ],
      },
      {
        code: '63631046',
        text:
          'Из какого материала должен быть выполнен внешний резервуар (вторичный контейнер), предназначенный для хранения СПГ, объемом хранения более 60000 м³?',
        answers: [
          'Из полимера',
          'Из нержавеющей стали',
          'Из криогенной хладостойкой стали',
          'Из предварительно напряженного железобетона',
        ],
        correctAnswers: ['Из предварительно напряженного железобетона'],
      },
      {
        code: '63631047',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 3 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63631048',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В технологическом регламенте',
          'В руководствах по безопасности',
          'В проектной документации',
          'В техническом регламенте',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63631049',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63631050',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Иметь документы о прохождении аттестации',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63631051',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63631052',
        text:
          'В какой документации должно определяться время срабатывания отсечных клапанов на трубопроводах приема/отгрузки СПГ при аварийных ситуациях?',
        answers: [
          'В проектной документации',
          'В эксплуатационной документации',
          'В паспорте безопасности ОПО',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63631053',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите 2 правильных варианта ответа.',
        answers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
          'Прокладка кабелей в каналах и траншеях запрещается',
        ],
        correctAnswers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
        ],
      },
      {
        code: '63631054',
        text:
          'Что необходимо учитывать при размещении технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках?',
        answers: [
          'Возможность быстрого демонтажа оборудования',
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Минимизацию контакта с коррозионно-активными веществами',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
        ],
      },
      {
        code: '63631055',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Эксплуатирующими организациями',
          'Ростехнадзором',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63631056',
        text:
          'Какую систему продувки должна предусматривать конструкция стендера, включая шарнирные соединения на трубопроводах для перекачки сжиженного природного газа (далее - СПГ)?',
        answers: [
          'Воздухом',
          'Аргоном',
          'Аммиаком',
          'Любым инертным газом',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63631057',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63631058',
        text:
          'Каким требованиям должны соответствовать помещения управления? Выберите 2 правильных варианта ответа.',
        answers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
          'Полы в помещении должны быть холодными с повышенной механической стойкостью',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63631059',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, меры взрывобезопасности, показатели пожароопасности и токсичности',
          'Показатели взрыво- пожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63631060',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии со сценариями возможных аварийных ситуаций и способами перевода объекта в безопасное состояние',
          'В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий и их развития',
          'В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63631061',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите 2 правильных варианта ответа.',
        answers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
          'Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования',
        ],
        correctAnswers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
        ],
      },
      {
        code: '63631062',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63631063',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63631064',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации',
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'В технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63631065',
        text:
          'На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Выберите 2 правильных варианта ответа.',
        answers: [
          'На объектах ХОПО II класса опасности',
          'На объектах ХОПО I класса опасности',
          'На объектах ХОПО IV класса опасности',
          'На объектах ХОПО III класса опасности',
          'На всех объектах ХОПО',
        ],
        correctAnswers: [
          'На объектах ХОПО IV класса опасности',
          'На объектах ХОПО III класса опасности',
        ],
      },
      {
        code: '63631066',
        text:
          'Какое из перечисленных требований допускается при проведении испытаний резервуаров СПГ?',
        answers: [
          'Подвергать мембранные внешние резервуары испытаниям на прочность и герметичность',
          'Проводить испытания внешнего резервуара двустенного изотермического резервуара (двухоболочечного с полной герметизацией) и его облицовки гидравлическим способом на прочность и герметичность',
          'Проводить испытания двустенных изотермических резервуаров (двухоболочечных с полной герметизацией) пневматическим способом',
        ],
        correctAnswers: [
          'Подвергать мембранные внешние резервуары испытаниям на прочность и герметичность',
        ],
      },
      {
        code: '63631067',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63631068',
        text:
          'На какой срок составляется план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся горные работы в подземных условиях?',
        answers: ['На 6 месяцев', 'На 1 год', 'На 1 квартал', 'На 1,5 года'],
        correctAnswers: ['На 6 месяцев'],
      },
      {
        code: '63631069',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от вида взрывозащиты',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63631070',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63631071',
        text:
          'Как необходимо составлять описание схемы процесса при наличии нескольких аналогичных технологических ниток?',
        answers: [
          'Необходимо представить описание всех технологических ниток',
          'Допускается делать описание схемы процесса по одной технологической нитке',
          'Допускается делать описание схемы процесса не менее, чем по двум технологическим ниткам',
        ],
        correctAnswers: [
          'Допускается делать описание схемы процесса по одной технологической нитке',
        ],
      },
      {
        code: '63631072',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с проектной документацией',
          'В соответствии с декларацией промышленной безопасности',
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63631073',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Нарушение энергообеспечения',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63631074',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63631075',
        text:
          'Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?',
        answers: [
          'Справочной литературой',
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
          'Данными, запрашиваемыми у научно-исследовательской организации',
          'Научно-технической литературой',
        ],
        correctAnswers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
        ],
      },
      {
        code: '63631076',
        text:
          'Для какого оборудования объектов сжиженного природного газа аварийное освобождение допускается осуществлять за счет испарения среды в факельную систему?',
        answers: [
          'Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 40 °С',
          'Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 30 °С',
          'Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 50 °С',
          'Для любого оборудования',
        ],
        correctAnswers: [
          'Для оборудования, содержащего жидкости, температура кипения которых при абсолютном давлении 0,1013 МПа не превышает минус 40 °С',
        ],
      },
      {
        code: '63631077',
        text:
          'Кем определяется порядок организации и проведения работ по техническому обслуживанию и ремонту технологического оборудования ОПО СПГ?',
        answers: [
          'Эксплуатирующей организацией',
          'Проектной организацией',
          'Ростехнадзором',
          'Комиссией под председательством инспектора Ростехнадзора',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63631078',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63631079',
        text:
          'Какие меры должны быть предусмотрены для насосов, предназначенных для перекачки сжиженного горючего газа?',
        answers: [
          'Меры по защите от превышения давления',
          'Меры по обеспечению бескавитационных режимов работы',
          'Меры по предотвращению образования газовых пробок внутри корпуса насоса',
          'Все перечисленные меры',
        ],
        correctAnswers: ['Все перечисленные меры'],
      },
      {
        code: '63631080',
        text:
          'В каком случае допускается транзитная прокладка технологических трубопроводов через помещения?',
        answers: [
          'В соответствии с требованиями технических регламентов',
          'В соответствии с требованиями законодательства о градостроительной деятельности',
          'В соответствии с требованиями проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63631081',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 1 час',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63631082',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63631083',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Предотвращение взрывов внутри технологического оборудования',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Периодический контроль за состоянием воздушной среды',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63631084',
        text:
          'Каким образом допускается проводить испытания на герметичность мембраны резервуара?',
        answers: [
          'Всеми перечисленными способами',
          'Продувкой изолирующего пространства смесью аммиака с азотом',
          'Продувкой изолирующего пространства смесью гелия с азотом',
          'Продувкой изолирующего пространства смесью гелия с воздухом',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63631085',
        text:
          'Чем должна быть проведена продувка и осушка изотермического резервуара перед захолаживанием?',
        answers: ['Азотом', 'Любым инертным газом', 'Воздухом', 'Аммиаком'],
        correctAnswers: ['Азотом'],
      },
      {
        code: '63631086',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63631087',
        text:
          'Применение каких соединений технологических трубопроводов, транспортирующих криогенные среды, и трубопроводной арматуры, установленной на них, не допускается?',
        answers: [
          'Резьбовых соединений',
          'Сварных соединений',
          'Фланцевых соединений',
        ],
        correctAnswers: ['Резьбовых соединений'],
      },
      {
        code: '63631088',
        text:
          'Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?',
        answers: [
          'Разработчиком проекта по расчетным данным',
          'Разработчиком проекта по литературным (справочным) данным',
          'Разработчиком проекта',
          'Разработчиком проекта по исходным данным',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63631089',
        text:
          'Какие существуют типы технологических регламентов в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Входящие в состав проектной документации или пусковые',
          'Периодически пересматриваемые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63631090',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63631091',
        text:
          'В каком случае допускается применение фланцевых соединений с гладкой уплотнительной поверхностью (соединительный выступ)?',
        answers: [
          'При использовании спирально-навитых прокладок с двумя ограничительными кольцами на давление до 4,0 МПа включительно',
          'При использовании спирально-навитых прокладок с двумя ограничительными кольцами на давление до 8,0 МПа включительно',
          'Для технологических трубопроводов со взрывоопасными средами в составе блоков I категории взрывоопасности',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При использовании спирально-навитых прокладок с двумя ограничительными кольцами на давление до 4,0 МПа включительно',
        ],
      },
      {
        code: '63631092',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 5 минут',
          'Через 10 минут',
          'Через 30 минут',
          'Через 60 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63631093',
        text:
          'В какой документации определяется защита оборудования, несущих конструкций и фундаментов от криогенного воздействия при возможных аварийных утечках СПГ?',
        answers: [
          'Все ответы неверны',
          'В техническом регламенте',
          'В инструкции по эксплуатации',
          'В руководстве по безопасности',
          'В Правилах безопасности объектов сжиженного природного газа',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63631094',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система парового отопления',
          'Система водяного отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63631095',
        text:
          'Какое значение не должно превышать соотношение расчетной скорости сброса к скорости звука в факельных системах?',
        answers: ['0,95', '1,0', '1,15', '0,85'],
        correctAnswers: ['0,95'],
      },
      {
        code: '63631096',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Наличие природных оврагов, выемок, низин',
          'Траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63631097',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '1 год', '3 года', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63631098',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63631099',
        text:
          'Что следует использовать при захолаживании изотермического резервуара? Выберите 2 правильных варианта ответа.',
        answers: ['Жидкий азот', 'Охлажденный воздух', 'CПГ', 'Углекислый газ'],
        correctAnswers: ['Жидкий азот', 'CПГ'],
      },
      {
        code: '63631100',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
          'Техническими характеристиками применяемых насосов и компрессоров',
          'Критическими параметрами технологического процесса',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63631101',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'На единицу времени (час)',
          'На единицу выпускаемой продукции',
          'На один производственный поток или на мощность производства в целом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63631102',
        text:
          'Какая факельная система для сбросов газов от технологических установок не предусматривается на ОПО СПГ?',
        answers: [
          'Факельная система высокого давления',
          'Факельная система "холодных" газов',
          'Факельная система "теплых" газов',
          'Факельная система отпарного газа изотермического хранения и отгрузки СПГ',
        ],
        correctAnswers: ['Факельная система высокого давления'],
      },
      {
        code: '63631103',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '6 месяцев',
          '24 месяца',
          '36 месяцев',
          '12 месяцев',
          '3 месяца',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63631104',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На технологическую службу организации, производства, отделения, установки',
          'На организацию - разработчика процесса',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63631105',
        text:
          'Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите 2 правильных варианта ответа.',
        answers: [
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений',
        ],
        correctAnswers: [
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
      },
      {
        code: '63631106',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63631107',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Должностным лицом территориального управления Ростехнадзора',
          'Главными техническими специалистами организаций, эксплуатирующих объекты',
          'Специалистом военизированной газоспасательной службы',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63631108',
        text:
          'Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Разработчиком процесса',
          'Разработчиком проекта',
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63631109',
        text:
          'Какие из перечисленных процедур должны быть проведены при количественном анализе риска аварий на ОПО СПГ?',
        answers: [
          'Расчет индивидуального, коллективного, социального риска, частоты разрушения зданий, сооружений и иных показателей риска аварий',
          'Расчет зон действия поражающих факторов',
          'Построение сценариев развития возможных аварий и определение вероятности (частоты) реализации каждого сценария',
          'Оценка последствий аварий',
          'Все перечисленные процедуры',
        ],
        correctAnswers: ['Все перечисленные процедуры'],
      },
      {
        code: '63631110',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите 2 правильных варианта ответа.',
        answers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
          'Отключение систем в случае переключений на резервный или аварийный источник электропитания не позднее 0,5 с',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63631111',
        text:
          'Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?',
        answers: [
          'Технической документацией производителя',
          'Проектной документацией',
          'Исходными данными на проектирование',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Технической документацией производителя'],
      },
      {
        code: '63631112',
        text:
          'Как требуется размещать технологическое оборудование взрывопожароопасных производств?',
        answers: [
          'С учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'С учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене',
          'С учетом обеспечения безопасности обслуживания и эксплуатации',
          'С учетом всех перечисленных требований',
        ],
        correctAnswers: ['С учетом всех перечисленных требований'],
      },
      {
        code: '63631113',
        text:
          'Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала',
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
          'Посредством наглядных пособий (плакатов) по охране труда',
        ],
        correctAnswers: [
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
      },
      {
        code: '63631114',
        text:
          'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Выберите 2 правильных варианта ответа.',
        answers: [
          'Заместителя руководителя организации по охране окружающей среды',
          'Начальника производства',
          'Начальника центральной лаборатории организации',
          'Начальника цеха',
          'Главного метролога организации',
        ],
        correctAnswers: ['Начальника производства', 'Начальника цеха'],
      },
      {
        code: '63631115',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63631116',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Характеристика производимой продукции',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Возможные инциденты в работе и способы их ликвидации',
          'Технологические схемы производства',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63631117',
        text:
          'Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?',
        answers: [
          'Разработчик документации',
          'Организация-изготовитель',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Орган по сертификации',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63631118',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Для всех ОПО без исключения',
          'Только для ОПО I и II классов опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63631119',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Все перечисленные требования указаны верно',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63631120',
        text:
          'Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?',
        answers: [
          'В местах проведения погрузочно-разгрузочных работ',
          'На путях необщего пользования',
          'На технологических путях организации',
          'Нигде не разрешается',
        ],
        correctAnswers: ['Нигде не разрешается'],
      },
      {
        code: '63631121',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63631122',
        text:
          'В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите 2 правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63631123',
        text:
          'Из какого материала должен быть выполнен внутренний резервуар (первичный контейнер), предназначенный для хранения СПГ?',
        answers: [
          'Из хладостойкой стали',
          'Из бетона',
          'Из железобетона',
          'Из полимера',
        ],
        correctAnswers: ['Из хладостойкой стали'],
      },
      {
        code: '63631124',
        text:
          'Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров, обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
      },
      {
        code: '63631125',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для новых в данной организации производств',
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для производств с новой технологией',
          'Для всех перечисленных производств',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63631126',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите 2 правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63631127',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.',
        answers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Быть мобильными, иметь небольшие габариты и вес',
          'Переход из режима ожидания в рабочее состояние должен осуществляться в течение 30 с',
        ],
        correctAnswers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
        ],
      },
      {
        code: '63631128',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Обратный клапан',
          'Регулирующий клапан',
          'Гидроклапан',
          'Предохранительный клапан',
          'Запорный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63631129',
        text:
          'Что должны обеспечивать системы контроля, автоматического и дистанционного управления (системы управления), системы оповещения об аварийных ситуациях в разделе технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Контроль за параметрами процесса, надежность применяемого оборудования, контроль за действиями персонала',
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
          'Надежность и безопасность проведения технологических процессов, мониторинг входных параметров процесса',
        ],
        correctAnswers: [
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
        ],
      },
      {
        code: '63631130',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Во всех перечисленных случаях производятся диагностические работы',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63631131',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите 2 правильных варианта ответа.',
        answers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
          'Начальник производственно-технического (технического) отдела организации',
          'Начальник производства',
        ],
        correctAnswers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
      },
      {
        code: '63631132',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите 2 правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63631133',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63631134',
        text:
          'Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?',
        answers: [
          'Съемные участки трубопроводов',
          'Резиновые гибкие шланги',
          'Гибкие металлические шланги',
          'Пластмассовые гибкие шланги',
        ],
        correctAnswers: ['Съемные участки трубопроводов'],
      },
      {
        code: '63631135',
        text:
          'В каком документе организация, эксплуатирующая химически опасный производственный объект I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, локализации и ликвидации их последствий?',
        answers: [
          'В плане по локализации аварийных ситуаций',
          'В Положении о производственном контроле',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63631136',
        text:
          'Как производится описание технологической схемы в разделе "Описание химико-технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63631137',
        text:
          'Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке оборудования?',
        answers: [
          'Наличие оросительных систем',
          'Возможность изоляции соседнего оборудования',
          'Наличие средств гидравлической, механической или химической очистки',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
        ],
        correctAnswers: [
          'Наличие средств гидравлической, механической или химической очистки',
        ],
      },
      {
        code: '63631138',
        text:
          'В каком случае допускается размещение помещений автоматических анализаторных, электропомещений, контроллерных, помещений для вентиляционного оборудования под технологическими трубопроводами и технологическим оборудованием?',
        answers: [
          'Если разработаны меры по предотвращению поступления в них взрывопожароопасных веществ',
          'Не допускается ни в каком случае',
          'При условии защиты помещений от возможных проливов и попаданий внутрь криогенных жидкостей и их паров',
          'По решению комиссии эксплуатирующей организации',
        ],
        correctAnswers: [
          'При условии защиты помещений от возможных проливов и попаданий внутрь криогенных жидкостей и их паров',
        ],
      },
      {
        code: '63631139',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'При срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'При деформации фланцев',
          'При неудовлетворительном состоянии уплотнительных поверхностей',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63631140',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Комиссией организации под председательством представителя проектной организции',
          'Технологическим руководителем организации',
          'Лицом, утверждающим технологический регламент',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63631141',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Разработчик проекта',
          'Разработчик технологического процесса',
          'Представитель организации-изготовителя систем противоаварийной автоматической защиты',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63631142',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63631143',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены на мнемосхемах',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63631144',
        text:
          'На какое воздействие должны быть рассчитаны конструкция и материалы ограждающей стены или обвалования вокруг каждого резервуара для хранения сжиженного горючего газа (СГГ) и легковоспламеняющихся жидкостей (ЛВЖ)?',
        answers: [
          'На гидростатическое (не менее 110 % от максимального объема хранения жидкости) воздействие СГГ и ЛВЖ',
          'На гидродинамическое воздействие СГГ и ЛВЖ',
          'На низкотемпературное воздействие СГГ и ЛВЖ',
          'На все перечисленные виды воздействия СГГ и ЛВЖ',
        ],
        correctAnswers: ['На все перечисленные виды воздействия СГГ и ЛВЖ'],
      },
      {
        code: '63631145',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности соотношение давлений не регламентируется',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности соотношение давлений не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Соотношение давлений негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63631146',
        text:
          'Что из перечисленного не входит в общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
        ],
        correctAnswers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63631147',
        text:
          'Какая предусматривается прокладка технологических трубопроводов для криогенных, взрывопожароопасных и токсичных сред?',
        answers: [
          'Надземная',
          'Наземная',
          'Подземная',
          'Любая из перечисленных',
        ],
        correctAnswers: ['Надземная'],
      },
      {
        code: '63631148',
        text:
          'От какого вида электричества должны предусматриваться меры защиты при проектировании сливоналивных эстакад сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только от атмосферного электричества',
          'Только от статического электричества',
          'От атмосферного и статического электричества',
        ],
        correctAnswers: ['От атмосферного и статического электричества'],
      },
      {
        code: '63631149',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность технологических процессов ХОПО?',
        answers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
          'Правилами безопасности химически опасных производственных объектов не регламентируется',
          'Не менее 5 независимых датчиков с раздельными точками отбора',
          'Не менее 3 независимых датчиков с раздельными точками отбора',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63631150',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63631151',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Дистанционное, неавтоматическое',
          'Ручное управление по месту',
          'Автоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63631152',
        text:
          'Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
          'Только с резиновым уплотнением в затворе',
          'Только с тканевым уплотнением в затворе',
          'Только с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63631153',
        text:
          'Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?',
        answers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
          'Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями',
          'Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц',
          'Запрещено для защиты технологического оборудования использовать неметаллические покрытия',
        ],
        correctAnswers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
      },
      {
        code: '63631154',
        text:
          'На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?',
        answers: [
          'На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'На алгоритмах, разработанных по сценариям всех возможных аварий и их развития',
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
          'На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
        ],
        correctAnswers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
        ],
      },
      {
        code: '63631155',
        text:
          'На каких трубопроводах должен быть предусмотрен контроль значения температуры СПГ?',
        answers: [
          'На трубопроводах приема СПГ в резервуар',
          'На трубопроводах нагнетания насосов',
          'На трубопроводах линии рециркуляции',
          'На всех перечисленных трубопроводах',
        ],
        correctAnswers: ['На всех перечисленных трубопроводах'],
      },
      {
        code: '63631156',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите 2 правильных варианта ответа.',
        answers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
        ],
        correctAnswers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
        ],
      },
      {
        code: '63631157',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Организации-разработчике технологического процесса',
          'Организации, выполнивший функции генерального проектировщика',
          'Организации, выполнивший проектную документацию',
          'Организации-разработчике технологической части проектной документации',
          'О всех перечисленных организациях',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63631158',
        text:
          'Какое устройство выходов должны иметь площадки обслуживания факельных стволов, расположенных в составе единой конструкции?',
        answers: [
          'Не менее двух выходов в противоположных направлениях',
          'Не менее двух выходов в одном направлении',
          'Не менее четырех выходов в противоположных направлениях',
          'Не менее одного выхода',
        ],
        correctAnswers: [
          'Не менее двух выходов в противоположных направлениях',
        ],
      },
      {
        code: '63631159',
        text:
          'Каким образом определяется полный перечень испытаний стендеров на площадке?',
        answers: [
          'Программой, разработанной организацией-изготовителем (поставщиком) стендеров',
          'Правилами безопасности объектов сжиженного природного газа',
          'Указаниями, разработанными Ростехнадзором',
          'Эксплуатационной документацией',
        ],
        correctAnswers: [
          'Программой, разработанной организацией-изготовителем (поставщиком) стендеров',
        ],
      },
      {
        code: '63631160',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63631161',
        text:
          'Какое время срабатывания системы защиты установлено в федеральных нормах и правилах?',
        answers: [
          'Не более 12 с',
          'Не более 120 с',
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
          'Не более 60 с',
        ],
        correctAnswers: [
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
        ],
      },
      {
        code: '63631162',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63631163',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
          'Необходимо разработать планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
        ],
        correctAnswers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63631164',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Только испытание на прочность пробным давлением',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63631165',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Организацией-разработчиком процесса',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
          'Специализированной сторонней организацией',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63631166',
        text:
          'Какие условия должны быть выполнены при проведении испытания технологических трубопроводов объектов сжиженного природного газа пневматическим способом?',
        answers: [
          'Величина давления пневматического испытания трубопровода устанавливается в проектной документации, но должна составлять не менее 1,1 Ррасч',
          'Испытания труб в организации-изготовителе должны быть выполнены гидравлическим способом на давление не более 0,95 δтек',
          'Испытания трубопроводов следует проводить с применением метода акустико-эмиссионного контроля',
          'Все перечисленные условия',
        ],
        correctAnswers: ['Все перечисленные условия'],
      },
      {
        code: '63631167',
        text:
          'Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у организации-потребителя с электроснабжающей системой',
          'Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться устройствами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63631168',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63631169',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
          'В течение 2 часов',
          'В течение 8 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63631170',
        text: 'Какие из перечисленных паров СПГ относятся к "холодным"?',
        answers: [
          'Плотностью более 0,6 по отношению к воздуху',
          'Плотностью более 0,7 по отношению к воздуху',
          'Плотностью более 0,8 по отношению к воздуху',
          'Плотностью более 0,5 по отношению к воздуху',
        ],
        correctAnswers: ['Плотностью более 0,8 по отношению к воздуху'],
      },
      {
        code: '63631171',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63631172',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Порядок действий в случае аварий на объекте',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63631173',
        text:
          'На основе каких данных составляется материальный баланс для действующих производств?',
        answers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
          'Материальный баланс для действующих производств составляется по данным проекта с учетом внесенных в проект изменений, включения или исключения дополнительных операций или стадий',
          'Материальный баланс для действующих производств составляется по данным технологического регламента',
        ],
        correctAnswers: [
          'Материальный баланс для действующих производств составляется по достигнутым показателям работы производств в последний год перед составлением технологического регламента',
        ],
      },
      {
        code: '63631174',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'В закрытые системы для дальнейшей утилизации',
          'В специальные контейнеры',
          'В централизованную систему водоотведения',
          'На факельную установку',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63631175',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?',
        answers: [
          'Не менее 12 секунд',
          'Не более 120 секунд',
          'Не менее 300 секунд',
          'Не менее 240 секунд',
        ],
        correctAnswers: ['Не более 120 секунд'],
      },
      {
        code: '63631176',
        text:
          'Когда должно происходить автоматическое включение аварийной вентиляции, установленной в анализаторных помещениях?',
        answers: [
          'Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 10% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 15% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 18% НКПР',
        ],
        correctAnswers: [
          'Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР',
        ],
      },
      {
        code: '63631177',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63631178',
        text:
          'В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?',
        answers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'После назначения нового руководителя организации, эксплуатирующей объект',
          'Не позднее 10 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63631179',
        text:
          'В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?',
        answers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63631180',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'В случае использования специальных систем аварийного освобождения',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63631181',
        text:
          'В каком случае допускается выполнение внешнего резервуара (вторичного контейнера) из криогенной хладостойкой стали, предназначенный для хранения СПГ?',
        answers: [
          'Для изотермических резервуаров СПГ объемом хранения до 60000 м³',
          'В случае опасности, связанной с разгерметизацией внутреннего резервуара',
          'В соответствии с проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Для изотермических резервуаров СПГ объемом хранения до 60000 м³',
        ],
      },
      {
        code: '63631182',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 5 лет',
          'Не более 1 года',
          'Не более 3 лет',
          'Не более 10 лет',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63631183',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите 2 правильных варианта ответа.',
        answers: [
          'Запорную и запорно-регулирующую арматуру',
          'Мануальные системы подавления взрыва',
          'Клапаны, отсекающие и другие отключающие устройства',
          'Средства, регистрирующие превышение давления',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру',
          'Клапаны, отсекающие и другие отключающие устройства',
        ],
      },
      {
        code: '63631184',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63631185',
        text:
          'Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите 2 правильных варианта ответа.',
        answers: [
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами контроля за компонентным составом поступающего на разделение продукта',
          'Средствами контроля за плотностью поступающих на разделение продукта и флегмы',
        ],
        correctAnswers: [
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
        ],
      },
      {
        code: '63631186',
        text:
          'Какой документ должен быть разработан на проведение пусконаладочных работ (включая осушку, продувку, захолаживание изотермического резервуара) и эксплуатацию изотермического резервуара ОПО СПГ?',
        answers: [
          'Технологический регламент',
          'Технический регламент',
          'Руководство по безопасности',
          'Акт-наряд',
        ],
        correctAnswers: ['Технологический регламент'],
      },
      {
        code: '63631187',
        text:
          'Какие из перечисленных требований к компрессорным установкам указаны неверно?',
        answers: [
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
          'Запорная отсечная арматура, устанавливаемая на нагнетательном трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'Запорная отсечная арматура, устанавливаемая на всасывающем трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'Все перечисленные требования указаны верно',
          'Порядок срабатывания систем блокировок насосов и компрессоров должен быть определен программой (алгоритмом) срабатывания системы ПАЗ технологической установки',
        ],
        correctAnswers: [
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
        ],
      },
      {
        code: '63631188',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Рпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63631189',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Технический руководитель предприятия',
          'Главный инженер организации и работник службы охраны труда',
          'Главный механик и работник службы охраны труда',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: ['Технический руководитель предприятия'],
      },
      {
        code: '63631190',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Заключение о качестве сварных стыков',
          'Заключение о техническом состоянии арматуры',
          'Акты испытания технологического трубопровода на прочность и плотность',
          'Акты периодического наружного осмотра технологического трубопровода',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63631191',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Категорией взрывоопасности технологических блоков',
          'Радиусом зон разрушения в м',
          'Энергией сгорания парогазовой фазы в кДж',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63631192',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63631193',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Только устройство световой сигнализации',
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Только устройство звуковой сигнализации',
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63631194',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
          'Провести экспертизу промышленной безопасности',
          'Провести реконcтрукцию химически опасного производственного объекта',
          'Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
        ],
      },
      {
        code: '63631195',
        text:
          'Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?',
        answers: [
          'Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание',
          'В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание',
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
          'Системы ПАЗ должны реализоваться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования',
        ],
        correctAnswers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
        ],
      },
      {
        code: '63631196',
        text:
          'В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?',
        answers: [
          'Не менее чем за 15 календарных дней',
          'Не менее чем за 7 календарных дней',
          'Не менее чем за 10 календарных дней',
          'Не менее чем за 30 календарных дней',
        ],
        correctAnswers: ['Не менее чем за 15 календарных дней'],
      },
      {
        code: '63631197',
        text:
          'Какой документацией должна обосновываться максимальная безопасная скорость движения жидких и газообразных сред по технологическим трубопроводам?',
        answers: [
          'Техническим регламентом',
          'Внутренней распорядительной документацией',
          'Проектной документацией',
          'Правилами безопасности объектов сжиженного природного газа',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63631198',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63631199',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом руководителя организации',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом комиссии организации',
          'Приказом главного инженера организации (технического директора, директора по производству)',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63631200',
        text:
          'Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите 2 правильных варианта ответа.',
        answers: [
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Разработка методов отвода тепла',
          'Эффективное разделение этих продуктов и возможность образования застойных зон',
          'Разработка методов приостановки реакции путем увеличения подачи одного из смешиваемых веществ',
        ],
        correctAnswers: [
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Разработка методов отвода тепла',
        ],
      },
      {
        code: '63631201',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Устройствами для подключения линий водорода',
          'Циклонами',
          'Устройствами для подключения линий воды, пара, инертного газа',
          'Виброситом',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63631202',
        text:
          'В каком случае допускается учитывать сбросы жидкой фазы рабочих сред в факельные системы?',
        answers: [
          'При расчетном обосновании при проектировании факельной системы',
          'Только в резервную факельную установку',
          'В соответствии с эксплуатационной документацией',
          'Допускается в любом случае',
        ],
        correctAnswers: [
          'При расчетном обосновании при проектировании факельной системы',
        ],
      },
      {
        code: '63631203',
        text:
          'В какой документации определяются технологическая схема производства СПГ и основные параметры технологического процесса, влияющие на его безопасность? Выберите 2 правильных варианта ответа.',
        answers: [
          'В исходных данных на проектирование',
          'В проектной документации',
          'В руководстве по безопасности',
          'В инструкции по эксплуатации',
          'В техническом регламенте',
        ],
        correctAnswers: [
          'В исходных данных на проектирование',
          'В проектной документации',
        ],
      },
      {
        code: '63631204',
        text:
          'Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?',
        answers: [
          'Из стали',
          'Из стале-алюминиевых сплавов',
          'Из неметаллических конструкционных материалов',
          'Из чугуна',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63631205',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'Помещения управления должны быть отдельно стоящими',
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать их к зданиям',
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63631206',
        text:
          'Что необходимо учитывать при размещении технологического оборудования в помещениях и на открытых площадках?',
        answers: [
          'Возможность быстрого демонтажа оборудования',
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'Соблюдение расстояний, предусмотренных требованиями пожарной безопасности',
          'Минимизация контакта с коррозионно-активными веществами',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
        ],
      },
      {
        code: '63631207',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 8 часов',
          'В течение 24 часов',
          'Время устанавливается в проектной документации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63631208',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите 2 правильных варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63631209',
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ['1 месяц', '6 месяцев', '3 месяца', 'Один год'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63631210',
        text:
          'Чем должна обеспечиваться продувка трубопроводной обвязки насосных колонн резервуаров СПГ?',
        answers: ['Инертным газом', 'Паром', 'Воздухом', 'Всем перечисленным'],
        correctAnswers: ['Инертным газом'],
      },
    ],
    63635: [
      {
        code: '63635000',
        text:
          'Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?',
        answers: [
          'Из стали',
          'Из фторопласта',
          'Из винипласта',
          'Из полиэтилена',
          'Из стекла',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63635001',
        text:
          'Кто утверждает график проведения периодической проверки стендеров?',
        answers: [
          'Ростехнадзор',
          'Проектная организация',
          'Комиссия под председательством инспектора Ростехнадзора',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63635002',
        text:
          'Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?',
        answers: [
          'На открытых площадках',
          'В пунктах разлива и фасовки нефтепродуктов',
          'В складских помещениях для хранения нефтепродуктов в таре',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['В пунктах разлива и фасовки нефтепродуктов'],
      },
      {
        code: '63635003',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Рпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Рпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63635004',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '3 месяца',
          '24 месяца',
          '36 месяцев',
          '12 месяцев',
          '6 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63635005',
        text:
          'В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?',
        answers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 81 °C',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
        ],
      },
      {
        code: '63635006',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Комиссия эксплуатирующей организации',
          'Инспектор территориального органа Ростехнадзора',
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63635007',
        text:
          'В одежде из каких материалов не допускается находиться водителям автомобильных цистерн, выполняющим операции слива-налива нефтепродуктов, на сливоналивных станциях и пунктах слива-налива нефти и светлых нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Из синтетических материалов',
          'Из льняных материалов',
          'Из шерстяных материалов',
          'Из хлопчатобумажных материалов',
        ],
        correctAnswers: [
          'Из синтетических материалов',
          'Из шерстяных материалов',
        ],
      },
      {
        code: '63635008',
        text:
          'При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?',
        answers: [
          'При разработке обоснования безопасности и реализации компенсирующих мероприятий',
          'При обосновании в проектной документации',
          'При величине допустимого риска меньше чем 10-6',
        ],
        correctAnswers: ['При обосновании в проектной документации'],
      },
      {
        code: '63635009',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63635010',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          '5 лет',
          '3 года',
          'Наибольший срок, предусмотренный для этих объектов',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63635011',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'Только в технологических блоках I категории взрывоопасности',
          'Только в технологических блоках II категории взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
          'В технологических блоках всех категорий взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63635012',
        text:
          'Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?',
        answers: [
          'Для технологических блоков I категории взрывоопасности',
          'Для технологических блоков II категории взрывоопасности',
          'Для технологических блоков III категории взрывоопасности',
        ],
        correctAnswers: [
          'Для технологических блоков I категории взрывоопасности',
        ],
      },
      {
        code: '63635013',
        text:
          'Какими документами определяются объем, периодичность и порядок организации и проведения работ по техническому обслуживанию и ремонту оборудования, резервуаров и технологических трубопроводов, систем инженерно-технического обеспечения с учетом конкретных условий эксплуатации опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Нормативно-техническими документами, разработанными эксплуатирующей организацией',
          'Проектной документацией',
          'Федеральными нормами и правилами в области промышленной безопасности',
        ],
        correctAnswers: [
          'Нормативно-техническими документами, разработанными эксплуатирующей организацией',
        ],
      },
      {
        code: '63635014',
        text:
          'В каком случае допускается сбрасывать взрывопожароопасные и пожароопасные нефтепродукты в канализацию?',
        answers: [
          'Не допускается ни в каком случае',
          'После предварительной локальной очистки',
          'По решению технического руководителя организации',
          'При авариях',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63635015',
        text:
          'Для налива каких ЛВЖ сливоналивные устройства должны снабжаться устройствами отвода паров?',
        answers: [
          'С упругостью паров от 56,65 кПа',
          'С упругостью паров от 60,65 кПа',
          'С упругостью паров от 66,65 кПа',
          'С упругостью паров от 61,65 кПа',
        ],
        correctAnswers: ['С упругостью паров от 66,65 кПа'],
      },
      {
        code: '63635016',
        text:
          'Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?',
        answers: [
          'Только вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал',
          'Всеми перечисленными способами',
          'Только дистанционно с учетом нажатия кнопки на центральном пульте управления',
          'Только автоматически, когда стендер достигает обусловленного граничного положения',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63635017',
        text:
          'Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?',
        answers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 300 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 500 мм',
        ],
        correctAnswers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
        ],
      },
      {
        code: '63635018',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Иметь документы о прохождении аттестации',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63635019',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся и горючих жидкостей поршневых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
        correctAnswers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63635020',
        text:
          'В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?',
        answers: [
          'Не допускается ни в каком случае',
          'Допускается в случае, если данное условие их применения определено в технической документации организации-изготовителя',
          'Допускается при согласовании с организацией-изготовителем и федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Допускается при обосновании принятого технического решения в проектной документации',
        ],
        correctAnswers: [
          'Допускается при обосновании принятого технического решения в проектной документации',
        ],
      },
      {
        code: '63635021',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63635022',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На сети водоснабжения и канализации',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63635023',
        text:
          'За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?',
        answers: [
          'За счет установки на технологических трубопроводах для транспортировки мазута сальниковых компенсаторов',
          'За счет установки на технологических трубопроводах для транспортировки мазута линзовых компенсаторов',
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
          'За счет установки на технологических трубопроводах для транспортировки мазута волнистых компенсаторов',
        ],
        correctAnswers: [
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
        ],
      },
      {
        code: '63635024',
        text:
          'Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?',
        answers: [
          'Требуемая скорость перемещения нефтепродукта',
          'Физико-химические свойства перемещаемой среды',
          'Параметры давления и температуры перемещаемой среды',
          'Размер судового трубопровода',
        ],
        correctAnswers: ['Требуемая скорость перемещения нефтепродукта'],
      },
      {
        code: '63635025',
        text:
          'Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?',
        answers: [
          'Подтоварные воды от отстоя нефти и нефтепродуктов',
          'Сточные воды от зачистки и пропарки резервуаров для нефти и нефтепродуктов',
          'Нефтешлам',
          'Все перечисленное',
        ],
        correctAnswers: ['Подтоварные воды от отстоя нефти и нефтепродуктов'],
      },
      {
        code: '63635026',
        text:
          'С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом толщины стенки трубопровода',
          'С учетом диаметра и протяженности трубопровода',
          'С учетом характеристики транспортируемой среды',
          'С учетом марки стали трубопровода',
        ],
        correctAnswers: [
          'С учетом диаметра и протяженности трубопровода',
          'С учетом характеристики транспортируемой среды',
        ],
      },
      {
        code: '63635027',
        text:
          'В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?',
        answers: [
          'При расположении береговых насосов более чем в 100 м от причала',
          'При расположении береговых насосов более чем в 50 м от причала',
          'Если это обосновано в проектной документации',
          'В любом случае',
        ],
        correctAnswers: [
          'При расположении береговых насосов более чем в 100 м от причала',
        ],
      },
      {
        code: '63635028',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'В технологическом регламенте на производство продукции',
          'В проектной документации',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63635029',
        text:
          'При какой минимальной скорости ветра запрещается проведение сливоналивных операций с легковоспламеняющимися жидкостями?',
        answers: [
          'Более 20 м/с',
          'Более 10 м/с',
          'Более 8 м/с',
          'Более 15 м/с',
        ],
        correctAnswers: ['Более 15 м/с'],
      },
      {
        code: '63635030',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63635031',
        text:
          'В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?',
        answers: [
          'В технологическом регламенте',
          'В проектной документации',
          'В техническом регламенте',
          'В эксплуатационной документации',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63635032',
        text:
          'С какой периодичностью следует визуально проверять целостность заземления элементов сливоналивных устройств, соединенных шарнирами с сальниковыми уплотнениями, изготовленными из неметаллических материалов, с регистрацией (записью) результатов осмотра в журнале приема-передачи смены?',
        answers: [
          'Каждую смену',
          'Еженедельно',
          'Ежемесячно',
          'Каждые три смены',
        ],
        correctAnswers: ['Каждую смену'],
      },
      {
        code: '63635033',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63635034',
        text:
          'От какого вида электричества должны предусматриваться меры защиты при проектировании сливоналивных эстакад сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только от атмосферного электричества',
          'Только от статического электричества',
          'От атмосферного и статического электричества',
        ],
        correctAnswers: ['От атмосферного и статического электричества'],
      },
      {
        code: '63635035',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63635036',
        text:
          'Какой электрифицированный транспорт во взрывозащищенном исполнении допускается применять на территории опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Только самоходные аккумуляторные тележки (электрокары)',
          'Только электропогрузчики',
          'Только тягачи',
          'Любой из перечисленных видов транспорта',
        ],
        correctAnswers: ['Любой из перечисленных видов транспорта'],
      },
      {
        code: '63635037',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63635038',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63635039',
        text:
          'Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?',
        answers: [
          'Высоковязкие нефтепродукты',
          'Авиационное топливо',
          'Светлые нефтепродукты',
          'Нефтепродукты с низкой вязкостью',
        ],
        correctAnswers: ['Светлые нефтепродукты'],
      },
      {
        code: '63635040',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Определяются места размещения пробоотборников и способы контроля',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
        ],
        correctAnswers: [
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Определяются места размещения пробоотборников и способы контроля',
        ],
      },
      {
        code: '63635041',
        text:
          'Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?',
        answers: [
          'Заглубленные не менее чем на 1 м',
          'В соответствии с проектной документацией',
          'Заглубленные более чем на 0,5 м',
          'Все насосные станции',
        ],
        correctAnswers: ['Заглубленные более чем на 0,5 м'],
      },
      {
        code: '63635042',
        text:
          'Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?',
        answers: [
          'Сальниковых компенсаторов',
          'Допускается применение всех перечисленных компенсаторов',
          'Линзовых компенсаторов',
          'Волнистых компенсаторов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63635043',
        text:
          'На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?',
        answers: ['2 м', '1 м', '1,5 м', '3 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63635044',
        text:
          'Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?',
        answers: [
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 300 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 200 мм',
        ],
        correctAnswers: [
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
        ],
      },
      {
        code: '63635045',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите два правильных варианта ответа.',
        answers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
          'Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
        ],
        correctAnswers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
        ],
      },
      {
        code: '63635046',
        text:
          'Что необходимо обеспечивать при хранении ЛВЖ в таре в складских помещениях?',
        answers: [
          'Только наличие погрузочно-разгрузочных устройств',
          'Только контроль загазованности помещения',
          'Только необходимую кратность обмена воздуха',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63635047',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63635048',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
          'Устройствами для подключения линий водорода',
          'Циклонами',
          'Виброситом',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63635049',
        text:
          'Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?',
        answers: [
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
          'Измерение указанного сопротивления должно проводиться при относительной влажности окружающего воздуха не выше 40%',
          'При измерениях электрод должен располагаться в точках поверхности оборудования, наименее удаленных от точек контакта поверхности с заземленными металлическими элементами, деталями, арматурой',
          'Площадь соприкосновения измерительного электрода с поверхностью оборудования не должна превышать 30 см²',
        ],
        correctAnswers: [
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
        ],
      },
      {
        code: '63635050',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Только при деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63635051',
        text:
          'Какие из перечисленных требований при разливе нефтепродуктов в тару указаны неверно?',
        answers: [
          'Допускается производить налив ЛВЖ и ГЖ в бочки, установленные непосредственно на автомобилях. При этом бочки и кузов автомобиля должны быть заземлены',
          'Разлив в мелкую тару жидкой продукции должен осуществляться на автоматических установках и автоматических линиях, обеспечивающих герметичный налив',
          'Мерные устройства, а также фасовочные агрегаты (камеры) разлива в тару жидкой продукции должны быть оборудованы местными отсосами',
          'При наливе ЛВЖ в металлические бочки патрубок наливного шланга должен быть опущен до дна. Патрубок, шланг и бочка должны быть заземлены',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Допускается производить налив ЛВЖ и ГЖ в бочки, установленные непосредственно на автомобилях. При этом бочки и кузов автомобиля должны быть заземлены',
        ],
      },
      {
        code: '63635052',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Разработчик технологического процесса',
          'Представитель организации-изготовителя систем противоаварийной автоматической защиты',
          'Разработчик проекта',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63635053',
        text:
          'При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещения на опасных производственных объектах складов нефти и нефтепродуктов?',
        answers: [
          'Не допускается ни в каком случае',
          'Если это утверждено внутренней документацией',
          'Если предусмотрены меры и средства, обеспечивающие безопасность эксплуатации таких приборов',
          'Если это обусловлено в проектной документации',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63635054',
        text:
          'В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?',
        answers: [
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
          'В соответствии с требованиями градостроительного законодательства',
          'В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов',
        ],
        correctAnswers: [
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
        ],
      },
      {
        code: '63635055',
        text:
          'Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 0,5 м',
          'Не менее 0,4 м',
          'Не менее 0,6 м',
        ],
        correctAnswers: ['Не менее 0,2 м'],
      },
      {
        code: '63635056',
        text:
          'При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?',
        answers: [
          '50% объемных от НКПРП',
          '20% объемных от НКПРП',
          '40% объемных от НКПРП',
          '30% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63635057',
        text:
          'В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Выберите два правильных варианта ответа.',
        answers: [
          'Руководств по безопасной эксплуатации',
          'Проектной документации',
          'Технической документации организации-изготовителя',
          'Внутренних инструкций эксплуатирующей организации',
        ],
        correctAnswers: [
          'Проектной документации',
          'Технической документации организации-изготовителя',
        ],
      },
      {
        code: '63635058',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 3 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63635059',
        text:
          'Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?',
        answers: ['Бетонным', 'Глинобитным', 'Асфальтированным', 'Деревянным'],
        correctAnswers: ['Бетонным'],
      },
      {
        code: '63635060',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63635061',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В паспортах оборудования и трубопроводной арматуры',
          'В технологическом регламенте',
          'В техническом задании на изготовление оборудования',
          'В проектной документации',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63635062',
        text:
          'Что должно проводиться для подтверждения соответствия Ех-оборудования стандартам на определенный вид взрывозащиты?',
        answers: [
          'Оценка научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Получение заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Испытания промышленных образцов оборудования на взрывозащищенность',
          'Опытные работы, проводимые разработчиком данного оборудования, и экспертиза промышленной безопасности',
        ],
        correctAnswers: [
          'Испытания промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63635063',
        text:
          'До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?',
        answers: [
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
          'До момента срабатывания блокировки ограничения скорости заполнения резервуара',
          'До момента заполнения резервуара до верхней проектной отметки',
        ],
        correctAnswers: [
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
        ],
      },
      {
        code: '63635064',
        text:
          'С какой периодичностью заземлители, токоотводы подвергаются периодическому контролю?',
        answers: [
          'Один раз в 2 года',
          'Один раз в 5 лет',
          'Один раз в 3 года',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63635065',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63635066',
        text:
          'Что из перечисленного допускается на ОПО складов нефти и нефтепродуктов?',
        answers: [
          'Установка средств контроля и сигнализации за содержанием пожаровзрывоопасных и токсичных веществ на входе в коллектор очистных сооружений',
          'Прямое соединение канализации загрязненных стоков с бытовой канализацией без гидрозатворов при наличии в местах соединения средств контроля за содержанием предельно допустимой нормы',
          'Располагать колодцы на сетях канализации под эстакадами технологических трубопроводов',
          'Располагать колодцы в пределах отбортовок и обвалований отдельно стоящего оборудования, содержащих взрывоопасные продукты',
          'Допускается все перечисленное',
        ],
        correctAnswers: [
          'Установка средств контроля и сигнализации за содержанием пожаровзрывоопасных и токсичных веществ на входе в коллектор очистных сооружений',
        ],
      },
      {
        code: '63635067',
        text:
          'Какие из перечисленных требований к полам в закрытых насосных станциях нефти и нефтепродуктов ОПО складов нефти и нефтепродуктов указаны неверно?',
        answers: [
          'В полах следует располагать дренажные лотки, которые должны быть непроницаемыми для воды и нефтепродуктов и закрытыми',
          'Полы должны быть выполнены из негорючих и стойких к воздействию нефтепродуктов материалов',
          'Дренажные лотки в полах следует соединять с канализацией через гидрозатворы с постоянным уклоном в сторону канализации',
          'Все перечисленное указано верно',
        ],
        correctAnswers: ['Все перечисленное указано верно'],
      },
      {
        code: '63635068',
        text:
          'Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?',
        answers: ['6 м/ч', '8 м/ч', '2 м/ч', '4 м/ч'],
        correctAnswers: ['6 м/ч'],
      },
      {
        code: '63635069',
        text:
          'Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?',
        answers: [
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
          'Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из операторной и по месту ее установки',
          'Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования',
          'Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации',
        ],
        correctAnswers: [
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
        ],
      },
      {
        code: '63635070',
        text:
          'Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?',
        answers: [
          'Съемные участки трубопроводов',
          'Пластмассовые гибкие шланги',
          'Гибкие металлические шланги',
          'Резиновые гибкие шланги',
        ],
        correctAnswers: ['Съемные участки трубопроводов'],
      },
      {
        code: '63635071',
        text:
          'Какие из перечисленных данных по каждому шлангу не должны иметь лица, ответственные за проведение сливоналивных операций с нефтепродуктами?',
        answers: [
          'Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться',
          'Название нефтепродукта, для которого он предназначен',
          'Значение величины рабочего давления',
          'Значение величины разрывного давления',
        ],
        correctAnswers: [
          'Дата следующего испытания с указанием необходимого давления, при котором он должен испытываться',
        ],
      },
      {
        code: '63635072',
        text:
          'Каким образом определяется класс опасности ОПО складов нефти и нефтепродуктов?',
        answers: [
          'Определяется экспертными расчетами организацией, имеющей лицензию на проведение экспертизы промышленной безопасности, на основании эксплуатационной и проектной документации (документации на техническое перевооружение или реконструкцию)',
          'Класс опасности определяется Ростехнадзором в рамках процедуры регистрации опасных производственных объектов в государственном реестре опасных производственных объектов',
          'В зависимости от количества горючих жидкостей, фактически находящихся в резервуарах, в соответствии с документацией на поставку нефти и нефтепродуктов и соответствии с Федеральным законом "Об охране окружающей среды"',
          'В зависимости от количества горючих жидкостей, находящихся в резервуарах, согласно проектной документации (документации на техническое перевооружение) и в соответствии с Федеральным законом "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'В зависимости от количества горючих жидкостей, находящихся в резервуарах, согласно проектной документации (документации на техническое перевооружение) и в соответствии с Федеральным законом "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63635073',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от всех перечисленных факторов',
          'В зависимости от вида взрывозащиты',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63635074',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63635075',
        text:
          'Каким образом не должен производиться разогрев застывающих и высоковязких нефтепродуктов в железнодорожных цистернах, сливоналивных устройствах?',
        answers: [
          'Горячей водой',
          'Нефтепродуктом, нагретым циркуляционным способом',
          'Паром',
          'Электроподогревом не выше 90 °C',
        ],
        correctAnswers: ['Горячей водой'],
      },
      {
        code: '63635076',
        text:
          'В каком случае допускается торможение цистерн башмаками, изготовленными из материала, дающего искрение, на участках слива-налива?',
        answers: [
          'Если прием и отгрузка нефти и нефтепродуктов осуществляется через специально обрудованные сливоналивные устройства',
          'Если цистерны имеют прокладки из неискрящего материала',
          'Не допускается ни в каком случае',
          'Если цистерны имеют прокладки устойчивые к разрушению парами нефтепродуктов',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63635077',
        text:
          'При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?',
        answers: [
          '20% объемных от нижнего концентрационного предела распространения пламени',
          '30% объемных от нижнего концентрационного предела распространения пламени',
          '50% объемных от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          '50% объемных от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63635078',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'Только в соответствии с проектной документацией',
          'Только в соответствии с рабочей документацией',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63635079',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Должно быть организовано управление дистанционно',
          'Должно быть организовано управление по месту',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63635080',
        text:
          'На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?',
        answers: [
          'Не менее 10 м до и после нефтеловушек',
          'В непосредственной близости от нефтеловушек',
          'Не менее 15 м после нефтеловушек',
        ],
        correctAnswers: ['Не менее 10 м до и после нефтеловушек'],
      },
      {
        code: '63635081',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
        ],
        correctAnswers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
        ],
      },
      {
        code: '63635082',
        text:
          'Какие требования предъявляются к температуре подогрева мазута в резервуарах?',
        answers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
        ],
        correctAnswers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
        ],
      },
      {
        code: '63635083',
        text:
          'Какими клапанами должны быть оборудованы резервуары для аварийного сброса нефти и нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Дыхательными клапанами',
          'Предохранительными клапанами',
          'Обратными клапанами',
          'Регулирующими клапанами',
        ],
        correctAnswers: [
          'Дыхательными клапанами',
          'Предохранительными клапанами',
        ],
      },
      {
        code: '63635084',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63635085',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63635086',
        text:
          'Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Любым из перечисленных способом',
          'Посредством запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде',
          'Установкой частотно-регулируемого электропривода насоса',
          'Перепуском части продукта во всасывающий трубопровод насоса',
        ],
        correctAnswers: ['Любым из перечисленных способом'],
      },
      {
        code: '63635087',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
          'Над автодорогами',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63635088',
        text:
          'Какое из перечисленных требований к использованию переносных подогревателей на железнодорожных сливоналивных эстакадах указано неверно?',
        answers: [
          'При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом',
          'Разогрев нефтепродуктов в железнодорожных цистернах переносными электрическими подогревателями должен производиться только в сочетании с циркуляционным нагревом в выносном подогревателе (теплообменнике)',
          'При использовании переносных пароподогревателей давление пара в подогревателе должно соответствовать показателям, установленным в технической документации (паспорте) пароподогревателя',
          'При использовании переносных электрических подогревателей последние должны быть оснащены блокировочными устройствами, отключающими их при снижении уровня жидкости над нагревательным устройством ниже 500 мм',
        ],
        correctAnswers: [
          'При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом',
        ],
      },
      {
        code: '63635089',
        text:
          'В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?',
        answers: [
          'В соответствии с техническими регламентами',
          'В соответствии с требованиями территориальных органов Ростехнадзора',
          'В соответствии с требованиями Общих правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
        correctAnswers: [
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
      },
      {
        code: '63635090',
        text:
          'Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Насосы и компрессоры должны оснащаться маховиками для облегчения повторного самозапуска электродвигателей',
          'Токи самозапуска электродвигателей не должны превышать номинальные более чем в два раза',
          'Время срабатывания систем самозапуска должно определяться нагрузкой насосов и компрессоров, но не должно превышать 5 минут',
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
        ],
        correctAnswers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
        ],
      },
      {
        code: '63635091',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63635092',
        text:
          'Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?',
        answers: [
          'Эксплуатация сливоналивных устройств при обнаружении нарушения целостности единого контура заземления при обосновании в проектной документации',
          'Запуск двигателей автомобильных цистерн, находящихся на площадке, в случаях пролива нефтепродукта',
          'Применение гибких шлангов для налива при обосновании в проектной документации',
          'Нахождение водителей автомобильных цистерн, выполняющих операции слива-налива нефтепродуктов, в одежде, способной накапливать заряды статического электричества',
        ],
        correctAnswers: [
          'Применение гибких шлангов для налива при обосновании в проектной документации',
        ],
      },
      {
        code: '63635093',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.',
        answers: [
          'Клапаны, отсекающие и другие отключающие устройства',
          'Запорную и запорно-регулирующую арматуру',
          'Мануальные системы подавления взрыва',
          'Средства, регистрирующие превышение давления',
        ],
        correctAnswers: [
          'Клапаны, отсекающие и другие отключающие устройства',
          'Запорную и запорно-регулирующую арматуру',
        ],
      },
      {
        code: '63635094',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Проектной организацией',
          'Технической комиссией эксплуатирующей организации',
          'Монтажной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63635095',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63635096',
        text:
          'Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: ['2%', '3%', '5%', '1%'],
        correctAnswers: ['2%'],
      },
      {
        code: '63635097',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 5 минут',
          'Через 10 минут',
          'Через 60 минут',
          'Через 30 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63635098',
        text:
          'Какие из перечисленных требований к автомобильным сливоналивным станциям указаны неверно?',
        answers: [
          'Автомобильные цистерны, стоящие под сливом-наливом на автомобильных наливных станциях, пунктах, должны быть заземлены. При отсутствии замкнутой электрической цепи "заземляющее устройство - автомобильная цистерна" автомобильные сливоналивные станции и пункты должны быть оборудованы блокировкой, исключающей возможность запуска насосов для перекачки нефтепродуктов',
          'Автомобильные наливные станции должны быть оборудованы специальными устройствами (светофорами, шлагбаумами или другими средствами, ограничивающими несогласованное движение транспорта) для предотвращения выезда заполненных нефтепродуктами автомобильных цистерн с опущенными в их горловины наливными устройствами',
          'Все перечисленные требования указаны верно',
          'Допускается кратковременный запуск двигателей автомобильных цистерн, находящихся на площадке, в целях освобождения места для полной уборки пролитого нефтепродукта в случаях его пролива',
        ],
        correctAnswers: [
          'Допускается кратковременный запуск двигателей автомобильных цистерн, находящихся на площадке, в целях освобождения места для полной уборки пролитого нефтепродукта в случаях его пролива',
        ],
      },
      {
        code: '63635099',
        text:
          'В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?',
        answers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
          'Допускается при условии разработки эксплуатирующей организацией компенсационных мер обеспечения требований пожарной и промышленной безопасности',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63635100',
        text:
          'В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?',
        answers: [
          'Не допускается ни в каком случае',
          'Для определения качества нефти и нефтепродуктов, хранящихся в резервуарах',
          'Для коммерческого учета нефти и нефтепродуктов, хранящихся в резервуарах',
          'Для вновь строящихся и реконструируемых резервуаров',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63635101',
        text:
          'Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?',
        answers: [
          'Все перечисленные конструкции',
          'Металлические оголовки и патрубки гибких шлангов для слива и налива нефти и нефтепродуктов',
          'Наземные трубопроводы через каждые 200 м и дополнительно на каждом ответвлении с присоединением каждого ответвления к заземлителю',
          'Железнодорожные рельсы сливоналивных участков, электрически соединенные между собой, а также металлические конструкции сливоналивных эстакад с двух сторон по длине',
        ],
        correctAnswers: ['Все перечисленные конструкции'],
      },
      {
        code: '63635102',
        text:
          'В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?',
        answers: [
          'Только при достижении предельного уровня заполнения железнодорожной цистерны',
          'Только при нарушении целостности цепи заземления железнодорожной цистерны',
          'Только при выдаче заданной нормы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63635103',
        text:
          'Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?',
        answers: [
          'При оснащении резервуарных парков газоуравнительной системой допускается объединять ею резервуары с авиационными и автомобильными бензинами',
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
          'Производительность наполнения (опорожнения) резервуаров может превышать суммарную пропускную способность установленных на резервуаре дыхательных и предохранительных устройств, если это обусловлено в проектной документации',
          'При обнаружении нарушения герметичности основного стального резервуара с защитной стенкой необходимо немедленно устранить причину утечки продукта',
        ],
        correctAnswers: [
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
        ],
      },
      {
        code: '63635104',
        text:
          'Какая устанавливается максимальная скорость движения понтона (плавающей крыши) для резервуаров емкостью свыше 30 000 м³?',
        answers: ['4 м/ч', '5 м/ч', '6 м/ч', '3 м/ч'],
        correctAnswers: ['4 м/ч'],
      },
      {
        code: '63635105',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 6 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63635106',
        text:
          'В каком случае при использовании переносных подогревателей допускается непосредственный контакт теплоносителя с нефтепродуктом?',
        answers: [
          'Если подогреватели имеют взрывозащищенное исполнение',
          'Если давление пара в подогревателе соответствует показателям, установленным в технической документации (паспорте) пароподогревателя',
          'Не допускается ни в каком случае',
          'Если это обусловлено проектной документацией',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63635107',
        text:
          'В какой срок после внесения изменений в системы управления технологическими процессами на объекте пересматриваются планы мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'По предписанию территориального органа Ростехнадзора',
          'Не позднее 15 календарных дней',
          'Не позднее двух недель',
          'Не позднее 30 календарных дней',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63635108',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63635109',
        text:
          'Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Технологическим регламентом',
          'Руководствами по безопасности',
          'Эксплуатационной документацией',
          'Проектной документацией (документацией на техническое перевооружение)',
        ],
        correctAnswers: [
          'Проектной документацией (документацией на техническое перевооружение)',
        ],
      },
      {
        code: '63635110',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63635111',
        text:
          'На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?',
        answers: ['4 м', '3 м', '5 м', '10 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63635112',
        text:
          'Как требуется размещать технологическое оборудование взрывопожароопасных производств?',
        answers: [
          'С учетом всех перечисленных требований',
          'С учетом обеспечения безопасности обслуживания и эксплуатации',
          'С учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'С учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене',
        ],
        correctAnswers: ['С учетом всех перечисленных требований'],
      },
      {
        code: '63635113',
        text:
          'В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?',
        answers: [
          'В проектной документации',
          'В производственной инструкции по наливу нефти и нефтепродуктов',
          'В нормативных правовых актах в области охраны труда',
          'В нормативных правовых актах в области промышленной безопасности',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63635114',
        text: 'Какой должна быть температура подогрева мазута в резервуарах?',
        answers: [
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не превышать 90 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 10 °C и не превышать 95 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 5 °C и не превышать 85 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не превышать 100 °C',
        ],
        correctAnswers: [
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не превышать 90 °C',
        ],
      },
      {
        code: '63635115',
        text:
          'Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?',
        answers: [
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
          'Не менее 1 раза в год - для авиационного топлива и не менее 2-х раз в год - для остальных светлых нефтепродуктов и масел',
          'Не менее 2-х раз в год для любых нефтепродуктов',
        ],
        correctAnswers: [
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
        ],
      },
      {
        code: '63635116',
        text:
          'Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?',
        answers: [
          'Физико-химические свойства хранимых нефтепродуктов',
          'Годовое число циклов заполнений-опорожнений резервуара',
          'Показатели взрывоопасности хранимых нефтепродуктов',
        ],
        correctAnswers: [
          'Годовое число циклов заполнений-опорожнений резервуара',
        ],
      },
      {
        code: '63635117',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Осуществляется все перечисленное',
          'Испытание трубопровода на прочность и плотность',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63635118',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Порядок действий в случае аварий на объекте',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63635119',
        text:
          'Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Проектной документацией',
          'Технологическим регламентом',
          'Техническим регламентом',
          'Эксплуатационной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63635120',
        text:
          'Какой документ должен быть оформлен для проведения земляных работ на территории опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Проект производства работ',
          'Акт-допуск',
          'Письменное разрешение технического руководителя организации',
          'Наряд-допуск',
        ],
        correctAnswers: ['Наряд-допуск'],
      },
      {
        code: '63635121',
        text:
          'На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?',
        answers: ['8 м', '5 м', '10 м', '15 м', '12 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63635122',
        text:
          'В каком случае допускается сбрасывать пожаровзрывоопасные и пожароопасные нефтепродукты в канализацию?',
        answers: [
          'В случае аварии',
          'Если в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков',
          'Если канализация закрытого типа и выполнена из негорючих материалов',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63635123',
        text:
          'Какой клапан должен быть установлен на береговом трубопроводе, предназначенном для выгрузки из судна нефти, нефтепродукта или балласта, в районе шлангоприемников?',
        answers: [
          'Редукционный клапан',
          'Предохранительный клапан',
          'Обратный клапан',
          'Запорный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63635124',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63635125',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63635126',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Поддержание положительной (выше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63635127',
        text:
          'В какой срок должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах до истечения срока действия предыдущего плана мероприятий?',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не менее чем за 5 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не менее чем за 10 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'В течение 5 календарных дней после срока истечения действия предыдущего плана мероприятий',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
      },
      {
        code: '63635128',
        text:
          'Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?',
        answers: [
          'Не менее 0,25 м',
          'Не менее 1,0 м',
          'Не менее 0,75 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 0,25 м'],
      },
      {
        code: '63635129',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63635130',
        text:
          'Каким должно быть максимальное сопротивление заземляющего устройства, предназначенного только для защиты от статического электричества?',
        answers: ['100 Ом', '120 Ом', '150 Ом', '80 Ом'],
        correctAnswers: ['100 Ом'],
      },
      {
        code: '63635131',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
        ],
        correctAnswers: [
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
        ],
      },
      {
        code: '63635132',
        text:
          'На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?',
        answers: ['500 мм', '300 мм', '800 мм', '600 мм'],
        correctAnswers: ['500 мм'],
      },
      {
        code: '63635133',
        text:
          'Каким способом на стендерах должна срабатывать система аварийного разъединения для предотвращения пролива нефтепродуктов?',
        answers: [
          'Автоматически, когда стендер достигает обусловленного граничного положения',
          'Дистанционно с учетом нажатия кнопки на центральном пульте управления',
          'Вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63635134',
        text:
          'Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?',
        answers: [
          'Разработчиком проекта',
          'Разработчиком проекта по расчетным данным',
          'Разработчиком проекта по исходным данным',
          'Разработчиком проекта по литературным (справочным) данным',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63635135',
        text:
          'Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?',
        answers: [
          'Не более 2,5 м/ч',
          'Не более 3,5 м/ч',
          'Не более 2,0 м/ч',
          'Не более 1,5 м/ч',
        ],
        correctAnswers: ['Не более 2,5 м/ч'],
      },
      {
        code: '63635136',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63635137',
        text:
          'Кем утверждается перечень уставок срабатывания блокировок и сигнализации для осуществления технологических операций, предусмотренных проектной документацией?',
        answers: [
          'Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов',
          'Организацией, осуществляющей техническое обслуживание и ремонт автоматизированных систем управления на опасных производственных объектах складов нефти и нефтепродуктов',
          'Разработчиком проектной документации или организацией, специализирующейся на проектировании аналогичных объектов',
          'Разработчиком технологического процесса по согласованию с разработчиком проектной документации',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов',
        ],
      },
      {
        code: '63635138',
        text:
          'При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?',
        answers: [
          'При превышении концентрации паров более 20%',
          'При превышении концентрации паров более 30%',
          'При превышении концентрации паров более 50%',
        ],
        correctAnswers: ['При превышении концентрации паров более 20%'],
      },
      {
        code: '63635139',
        text:
          'В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?',
        answers: [
          'В течение четырех лет',
          'В течение пяти лет',
          'В течение года',
          'В течение шести лет',
        ],
        correctAnswers: ['В течение четырех лет'],
      },
      {
        code: '63635140',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Специалистом военизированной газоспасательной службы',
          'Главными техническими специалистами организаций, эксплуатирующих объекты',
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Должностным лицом территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63635141',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63635142',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63635143',
        text:
          'Каким давлением испытываются грузовые шланги, находящиеся в эксплуатации, на сливоналивных причалах для выявления утечки содержимого шланга или смещения его концевых соединительных устройств?',
        answers: [
          'Давлением, значение которого составляет 1,5 номинального рабочего давления',
          'Давлением, значение которого составляет 2,5 номинального рабочего давления',
          'Давлением, значение которого составляет 2,0 номинального рабочего давления',
          'Номинальным рабочим давлением',
        ],
        correctAnswers: [
          'Давлением, значение которого составляет 1,5 номинального рабочего давления',
        ],
      },
      {
        code: '63635144',
        text:
          'При каком снижении уровня жидкости над нагревательным устройством должны быть отключены переносные электрические подогреватели блокировочными устройствами?',
        answers: ['Ниже 500 мм', 'Ниже 600 мм', 'Ниже 700 мм', 'Ниже 800 мм'],
        correctAnswers: ['Ниже 500 мм'],
      },
      {
        code: '63635145',
        text:
          'Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
          'Только с тканевым уплотнением в затворе',
          'Только с пластмассовым уплотнением в затворе',
          'Только с резиновым уплотнением в затворе',
        ],
        correctAnswers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63635146',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63635147',
        text:
          'Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварийных ситуаций',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте и их соответствие задачам по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
        ],
      },
      {
        code: '63635148',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63635149',
        text:
          'Каким документам должны соответствовать объемно-планировочные решения, конструкция зданий, помещений и вспомогательных сооружений для систем контроля, управления, противоаварийной автоматической защиты и газового анализа, их размещение на территории опасного производственного объекта нефтехимических производств?',
        answers: [
          'Всем перечисленным документам',
          'Только требованиям проектной документации',
          'Только требованиям технических регламентов',
          'Только требованиям законодательства о градостроительной деятельности',
          'Только требованиям Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
        ],
        correctAnswers: ['Всем перечисленным документам'],
      },
      {
        code: '63635150',
        text:
          'В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'На каждый объект должен быть разработан свой план в любом случае',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63635151',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63635152',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63635153',
        text:
          'Какая должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['34 °C', '40 °C', '60 °C'],
        correctAnswers: ['40 °C'],
      },
      {
        code: '63635154',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Поддержание избыточного давления инертного газа в технологической системе в период остановки оборудования',
        ],
        correctAnswers: [
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
        ],
      },
      {
        code: '63635155',
        text:
          'В соответствии с требованиями какой документации проводятся все технологические операции по приему, хранению и разливу нефтепродуктов в тару? Выберите два правильных варианта ответа.',
        answers: [
          'Правил промышленной безопасности складов нефти и нефтепродуктов',
          'Производственных инструкций, утвержденных эксплуатирующей организацией',
          'Проектной документации',
          'Технических регламентов',
        ],
        correctAnswers: [
          'Правил промышленной безопасности складов нефти и нефтепродуктов',
          'Производственных инструкций, утвержденных эксплуатирующей организацией',
        ],
      },
      {
        code: '63635156',
        text:
          'В каких резервуарах допускается хранение нефти и нефтепродуктов для вновь строящихся и реконструируемых опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'В надземных резервуарах',
          'В заглубленных резервуарах',
          'В подземных резервуарах',
          'Во всех перечисленных',
        ],
        correctAnswers: ['В надземных резервуарах'],
      },
      {
        code: '63635157',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63635158',
        text:
          'Какой связью должен быть обеспечен персонал для безопасного проведения технологических операций с нефтью и нефтепродуктами?',
        answers: [
          'Системами двусторонней громкоговорящей связи',
          'Системами телефонной связи',
          'Радиосвязью',
          'Любой связью из перечисленных',
        ],
        correctAnswers: ['Любой связью из перечисленных'],
      },
      {
        code: '63635159',
        text:
          'Какие предъявляемые требования к металлическим перемычкам, которые привариваются или припаиваются для защиты от электромагнитной индукции между трубопроводами и другими протяженными металлическими предметами (каркас сооружения, металлические оболочки кабелей без наружного покрова), проложенными во взрывоопасной зоне внутри здания (сооружения), в местах их взаимного сближения на расстоянии 10 см и менее, указаны неверно?',
        answers: [
          'Перемычки должны быть изготовлены из стальной ленты сечением не менее 24 мм²',
          'Перемычки привариваются или припаиваются через каждые 20 м длины трубопровода',
          'Перемычки должны быть изготовлены из стальной проволоки диаметром не менее 2 мм',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Перемычки должны быть изготовлены из стальной проволоки диаметром не менее 2 мм',
        ],
      },
      {
        code: '63635160',
        text:
          'При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?',
        answers: [
          '20% объемных от НКПРП',
          '30% объемных от НКПРП',
          '40% объемных от НКПРП',
          '50% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63635161',
        text:
          'Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?',
        answers: [
          'Не более 0,03 Ом на каждый контакт',
          'Не более 0,1 Ом на каждый контакт',
          'Не более 0,5 Ом на каждый контакт',
          'Не более 0,05 Ом на каждый контакт',
        ],
        correctAnswers: ['Не более 0,03 Ом на каждый контакт'],
      },
      {
        code: '63635162',
        text:
          'Чем должны быть оснащены подводящие к смесителям коммуникации, проектируемые с целью обеспечения максимально возможного уровня эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Фильтрами',
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
          'Автоматическими датчиками давления',
          'Автоматическим газоанализатором',
        ],
        correctAnswers: [
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
        ],
      },
      {
        code: '63635163',
        text:
          'Что из перечисленного не требует защиты от прямых ударов молнии?',
        answers: [
          'Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней',
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
          'Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м',
        ],
        correctAnswers: [
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
        ],
      },
      {
        code: '63635164',
        text:
          'Каким образом должны включаться системы аварийной вентиляции производственных помещений ОПО складов нефти и нефтепродуктов?',
        answers: [
          'Дистанционным включением из помещения управления',
          'Местным ручным включением',
          'Автоматически от систем контроля загазованности воздушной среды в помещении',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63635165',
        text:
          'Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?',
        answers: [
          'Технической документацией производителя',
          'Проектной документацией',
          'Технологическим регламентом',
          'Исходными данными на проектирование',
        ],
        correctAnswers: ['Технической документацией производителя'],
      },
      {
        code: '63635166',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Главный механик и работник службы охраны труда',
          'Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Главный инженер организации и работник службы охраны труда',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: [
          'Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63635167',
        text:
          'На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?',
        answers: [
          'На 1,0 м',
          'На 0,3 м',
          'На 0,5 м',
          'Должны полностью доходить до пола и покрытия (перекрытия) насосной станции',
        ],
        correctAnswers: ['На 0,3 м'],
      },
      {
        code: '63635168',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63635169',
        text:
          'В каком случае разрешается выполнение болтовых соединений молниеприемников с токоотводами и токоотводов с заземлителями с переходным сопротивлением не более 0,05 Ом?',
        answers: [
          'Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода',
          'Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области пожарной безопасности',
          'Не разрешается ни в каком случае',
        ],
        correctAnswers: [
          'Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода',
        ],
      },
      {
        code: '63635170',
        text:
          'Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?',
        answers: [
          'Нигде не разрешается',
          'На технологических путях организации',
          'На путях необщего пользования',
          'В местах проведения погрузочно-разгрузочных работ',
        ],
        correctAnswers: ['Нигде не разрешается'],
      },
      {
        code: '63635171',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63635172',
        text:
          'Какие из перечисленных требований к сливным лоткам приемно-сливной эстакады для мазутов, гудронов и битумов указаны верно?',
        answers: [
          'Лотки должны быть выполнены из негорючих материалов',
          'Лотки должны быть перекрыты металлическими решетками, съемными крышками',
          'Лотки должны быть оборудованы средствами подогрева слитого топлива',
          'Для вновь проектируемых эстакад слива вязких нефтепродуктов (мазутов, гудронов и битумов) следует предусматривать закрытые системы слива',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63635173',
        text:
          'На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?',
        answers: [
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
          'На товарно-сырьевые парки центральных пунктов сбора нефтяных месторождений',
          'На наливные станции магистральных нефтепроводов и нефтепродуктопроводов',
          'На объекты, предназначенные для приема и выдачи нефти и нефтепродуктов',
        ],
        correctAnswers: [
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
        ],
      },
      {
        code: '63635174',
        text:
          'В каком случае допускается сброс химически загрязненных, технологических, смывных и других сточных вод без предварительной очистки?',
        answers: [
          'В случае когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков',
          'Не допускается ни в каком случае',
          'В случае, когда это утверждено инспектором Ростехнадзора',
          'В случае, когда это утверждено внутренней документацией эксплуатирующей организации',
        ],
        correctAnswers: [
          'В случае когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков',
        ],
      },
      {
        code: '63635175',
        text:
          'Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Выберите два правильных варианта ответа.',
        answers: [
          'Из круглой стали диаметром более 6 мм',
          'Из полосовой стали сечением не менее 48 мм²',
          'Из стальной проволоки диаметром не менее 5 мм',
          'Из стальной ленты сечением не менее 24 мм²',
        ],
        correctAnswers: [
          'Из круглой стали диаметром более 6 мм',
          'Из полосовой стали сечением не менее 48 мм²',
        ],
      },
      {
        code: '63635176',
        text:
          'С какой температурой вспышки не допускается хранение легковоспламеняющихся нефтепродуктов на открытых площадках?',
        answers: [
          '45 ºC и ниже',
          '55 ºC и ниже',
          '60 ºC и ниже',
          '70 ºC и ниже',
        ],
        correctAnswers: ['45 ºC и ниже'],
      },
      {
        code: '63635177',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Только испытание на прочность пробным давлением',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63635178',
        text:
          'С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?',
        answers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
          'С учетом химического анализа проб горючих веществ на содержание кислорода',
          'С учетом возможности проведения эффективной и безопасной очистки системы',
        ],
        correctAnswers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
      },
      {
        code: '63635179',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63635180',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Для всех ОПО без исключения',
          'Только для ОПО I и II классов опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63635181',
        text:
          'В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Допускается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны',
          'Не допускается ни в каком случае',
          'Допускается в любом случае',
        ],
        correctAnswers: [
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны',
        ],
      },
      {
        code: '63635182',
        text:
          'Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?',
        answers: [
          'Все перечисленное',
          'Только пар',
          'Только электрообогрев',
          'Только промтеплофикационная вода',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63635183',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63635184',
        text:
          'В каком месяце должны быть проведены все ремонты молниезащитных устройств на объектах складов нефти и нефтепродуктов?',
        answers: ['В апреле', 'В мае', 'В марте', 'В сентябре'],
        correctAnswers: ['В апреле'],
      },
      {
        code: '63635185',
        text:
          'Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['Производственная', 'Аварийная', 'Дождевая'],
        correctAnswers: ['Аварийная'],
      },
      {
        code: '63635186',
        text:
          'Что из перечисленного допускается в отношении резервуарных парков?',
        answers: [
          'При хранении нефтепродуктов в резервуарах наличие подтоварной воды выше минимального уровня, обеспечиваемого устройством для дренажа воды',
          'Ручной отбор проб светлых нефтепродуктов во время закачки или откачки продукта',
          'Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара',
          'Все перечисленное не допускается',
        ],
        correctAnswers: ['Все перечисленное не допускается'],
      },
      {
        code: '63635187',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'В случаях изгиба болтов и шпилек',
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случае износа боковых граней головок болтов и гаек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63635188',
        text:
          'Какие из перечисленных требований к резиновым шлангам с металлическими наконечниками, используемым для налива жидкостей в железнодорожные и автомобильные цистерны, наливные суда и другие передвижные сосуды и аппараты, указаны неверно?',
        answers: [
          'Один конец проволоки (или тросика) соединяется пайкой (или под болт) с металлическими заземленными частями трубопровода, а другой - с металлическим наконечником шланга',
          'Шланги должны быть обвиты медной проволокой диаметром не менее 1 мм или медным тросиком сечением не менее 2 мм² с шагом витка не более 200 мм',
          'Наконечники шланга должны быть изготовлены из металла, не создающего искры при ударе',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Шланги должны быть обвиты медной проволокой диаметром не менее 1 мм или медным тросиком сечением не менее 2 мм² с шагом витка не более 200 мм',
        ],
      },
      {
        code: '63635189',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63635190',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
    ],
    63638: [
      {
        code: '63638000',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63638001',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63638002',
        text:
          'Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Разработчиком процесса',
          'Разработчиком проекта',
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63638003',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10 % для всех кольцевых сварных швов',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63638004',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63638005',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчиком в задании на проектирование',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63638006',
        text:
          'С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С Ростехнадзором',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и Ростехнадзором',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63638007',
        text:
          'Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?',
        answers: [
          'Только наземные трубопроводы через каждые 200 м и дополнительно на каждом ответвлении с присоединением каждого ответвления к заземлителю',
          'Только железнодорожные рельсы сливоналивных участков, электрически соединенные между собой, а также металлические конструкции сливоналивных эстакад с двух сторон по длине',
          'Только металлические оголовки и патрубки гибких шлангов для слива и налива нефти и нефтепродуктов',
          'Все перечисленные конструкции',
        ],
        correctAnswers: ['Все перечисленные конструкции'],
      },
      {
        code: '63638008',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: [
          'На одну выше',
          'I категории',
          'II категории',
          'III категории',
        ],
        correctAnswers: ['На одну выше'],
      },
      {
        code: '63638009',
        text:
          'Какой клапан должен быть установлен на береговом трубопроводе, предназначенном для выгрузки из судна нефти, нефтепродукта или балласта, в районе шлангоприемников?',
        answers: [
          'Обратный клапан',
          'Запорный клапан',
          'Редукционный клапан',
          'Предохранительный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63638010',
        text:
          'Какое из перечисленных требований к складским помещениям для хранения нефтепродуктов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Поверхность пола в складских зданиях для хранения нефтепродуктов в таре должна иметь шероховатости',
          'Площадка для хранения нефтепродуктов в таре должна быть оборудована отмостками и водоотводными каналами с уклоном для стока воды',
          'Допускается осуществлять заземление бочек, бидонов и других передвижных емкостей путем присоединения их к заземляющему устройству медным тросиком с наконечником под болт',
          'В складских помещениях для хранения нефтепродуктов в таре допускается расфасовывать нефтепродукты с соблюдением дополнительных мер безопасности',
        ],
        correctAnswers: [
          'Площадка для хранения нефтепродуктов в таре должна быть оборудована отмостками и водоотводными каналами с уклоном для стока воды',
          'Допускается осуществлять заземление бочек, бидонов и других передвижных емкостей путем присоединения их к заземляющему устройству медным тросиком с наконечником под болт',
        ],
      },
      {
        code: '63638011',
        text:
          'Что необходимо предусматривать на складах, пунктах слива-налива, расположенных на открытых площадках, где в условиях эксплуатации возможно поступление в воздух рабочей зоны паров кислот и щелочей, для обеспечения требований безопасности?',
        answers: [
          'Только автоматический контроль с сигнализацией превышения предельно допустимых концентраций',
          'Только включение светового и звукового сигналов при превышении предельно допустимых концентраций',
          'Только регистрацию всех случаев загазованности приборами',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63638012',
        text:
          'Кем утверждается перечень уставок срабатывания блокировок и сигнализации для осуществления технологических операций, предусмотренных проектной документацией?',
        answers: [
          'Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов',
          'Разработчиком технологического процесса по согласованию с разработчиком проектной документации',
          'Разработчиком проектной документации или организацией, специализирующейся на проектировании аналогичных объектов',
          'Организацией, осуществляющей техническое обслуживание и ремонт автоматизированных систем управления на опасных производственных объектах складов нефти и нефтепродуктов',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей опасные производственные объекты складов нефти и нефтепродуктов',
        ],
      },
      {
        code: '63638013',
        text:
          'Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?',
        answers: [
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 300 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 200 мм',
        ],
        correctAnswers: [
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
        ],
      },
      {
        code: '63638014',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63638015',
        text:
          'В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?',
        answers: [
          'Только при выдаче заданной нормы',
          'Только при нарушении целостности цепи заземления железнодорожной цистерны',
          'Только при достижении предельного уровня заполнения железнодорожной цистерны',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63638016',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту',
          'Должно быть организовано управление дистанционно (из безопасного места)',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63638017',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63638018',
        text:
          'Какой категории взрывоопасности технологических блоков не существует?',
        answers: [
          'I категории',
          'II категории',
          'III категории',
          'IV категории',
        ],
        correctAnswers: ['IV категории'],
      },
      {
        code: '63638019',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63638020',
        text:
          'Что из перечисленного не требует защиты от прямых ударов молнии?',
        answers: [
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
          'Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м',
          'Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней',
        ],
        correctAnswers: [
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
        ],
      },
      {
        code: '63638021',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены на мнемосхемах',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их размещения в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63638022',
        text:
          'При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещания на опасных производственных объектах складов нефти и нефтепродуктов?',
        answers: [
          'Если это обусловлено в проектной документации',
          'Если предусмотрены меры и средства, обеспечивающие безопасность эксплуатации таких приборов',
          'Если это утверждено внутренней документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63638023',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          'Наибольший срок, предусмотренный для этих объектов',
          '3 года',
          '5 лет',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63638024',
        text:
          'Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
          'Средствами контроля за фракционным составом измельченных твердых горючих продуктов',
          'Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут',
          'Средствами контроля за температурой измельченных твердых горючих продуктов',
        ],
        correctAnswers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
        ],
      },
      {
        code: '63638025',
        text:
          'При каких погодных условиях производится монтаж изоляции изотермических резервуаров жидкого аммиака?',
        answers: [
          'Только при температуре не ниже предусмотренной техническими условиями',
          'Только в теплое время года',
          'Только при отсутствии атмосферных осадков',
          'При соблюдении всех перечисленных погодных условий',
        ],
        correctAnswers: ['При соблюдении всех перечисленных погодных условий'],
      },
      {
        code: '63638026',
        text:
          'В каком случае необходимо контролировать величину и равномерность осадки фундаментов шаровых резервуаров в эксплуатации?',
        answers: [
          'Только до гидравлического испытания резервуара',
          'Только после гидравлического испытания резервуара',
          'Только перед подачей в него жидкого аммиака',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63638027',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63638028',
        text:
          'Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?',
        answers: [
          'Светлые нефтепродукты',
          'Нефтепродукты с низкой вязкостью',
          'Авиационное топливо',
          'Высоковязкие нефтепродукты',
        ],
        correctAnswers: ['Светлые нефтепродукты'],
      },
      {
        code: '63638029',
        text:
          'В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?',
        answers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
          'Допускается при условии разработки эксплуатирующей организацией компенсационных мер обеспечения требований пожарной и промышленной безопасности',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63638030',
        text:
          'Какие требования к техническим устройствам и инженерным коммуникациям не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
          'На все вентиляционные системы составляются соответствующие эксплуатационные документы (паспорта или формуляры)',
          'При изменении технологического процесса или реконструкции производственного участка действующие на этом участке вентиляционные системы приводятся в соответствие с новыми производственными условиями',
          'Система продувок кислородных, азотных и аргонных технических устройств и коммуникаций должна исключать возможность поступления продуктов разделения воздуха в помещение',
        ],
        correctAnswers: [
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63638031',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63638032',
        text:
          'При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?',
        answers: [
          'При обосновании в проектной документации',
          'При разработке обоснования безопасности и реализации компенсирующих мероприятий',
          'При величине допустимого риска меньше, чем 106',
        ],
        correctAnswers: ['При обосновании в проектной документации'],
      },
      {
        code: '63638033',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63638034',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 24 часов',
          'Не менее 12 часов',
          'Не менее 8 часов',
          'Не менее 4 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63638035',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63638036',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63638037',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Все перечисленные требования указаны верно',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается.',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63638038',
        text:
          'Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?',
        answers: [
          'Только пар',
          'Только промтеплофикационная вода',
          'Только электрообогрев',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63638039',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Специалистом военизированной газоспасательной службы',
          'Должностным лицом территориального управления Ростехнадзора',
          'Главными техническими специалистами организаций, эксплуатирующих объекты',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63638040',
        text:
          'Что должно проектироваться и выбираться с учетом анализа возможных рисков образования взрывоопасных веществ вследствие взаимного проникновения и взаимодействия теплоносителя с технологической средой для того, чтобы предотвратить возможность возникновения аварийных ситуаций?',
        answers: [
          'Теплообменные процессы и теплообменное оборудование',
          'Только тип теплоносителя (хладагента)',
          'Только теплообменные процессы',
          'Только теплообменное оборудование',
        ],
        correctAnswers: ['Теплообменные процессы и теплообменное оборудование'],
      },
      {
        code: '63638041',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63638042',
        text:
          'Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?',
        answers: [
          'Из стали',
          'Из фторопласта',
          'Из полиэтилена',
          'Из стекла',
          'Из винипласта',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63638043',
        text:
          'В течение какого срока службы устройства резервуаров для хранения аммиака должны обеспечивать надежную и безопасную эксплуатацию?',
        answers: [
          'В течение срока службы, указанного в паспорте организации-изготовителя',
          'В течение 10 лет',
          'В течение 15 лет',
          'В течение 20 лет',
        ],
        correctAnswers: [
          'В течение срока службы, указанного в паспорте организации-изготовителя',
        ],
      },
      {
        code: '63638044',
        text:
          'Для каких блоков объектов производств растительных масел допускается применение автоматических средств контроля параметров, значения которых определяют взрывоопасность процесса, и ручного регулирования?',
        answers: [
          'Для блоков, имеющих относительный энергетический потенциал Qв меньше 10',
          'Для блоков II категории взрывоопасности',
          'Для блоков I категории взрывоопасности',
          'Для блоков III категории взрывоопасности',
        ],
        correctAnswers: [
          'Для блоков, имеющих относительный энергетический потенциал Qв меньше 10',
        ],
      },
      {
        code: '63638045',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы в кДж',
          'Категорией взрывоопасности технологических блоков',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Радиусом зон разрушения в м',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63638046',
        text:
          'Каким образом не должен производиться разогрев застывающих и высоковязких нефтепродуктов в железнодорожных цистернах, сливоналивных устройствах?',
        answers: [
          'Горячей водой',
          'Паром',
          'Электроподогревом не выше 90 °C',
          'Нефтепродуктом, нагретым циркуляционным способом',
        ],
        correctAnswers: ['Горячей водой'],
      },
      {
        code: '63638047',
        text:
          'На каком расстоянии со стороны зданий и открытых установок, примыкающих к ограждению резервуаров для хранения жидкого аммиака, допускается располагать объезд?',
        answers: [
          'Не более 39 м от ограждения резервуаров',
          'Не более 40 м от ограждения резервуаров',
          'Не более 45 м от ограждения резервуаров',
          'Не более 50 м от ограждения резервуаров',
        ],
        correctAnswers: ['Не более 39 м от ограждения резервуаров'],
      },
      {
        code: '63638048',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63638049',
        text:
          'На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?',
        answers: [
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
          'На товарно-сырьевые парки центральных пунктов сбора нефтяных месторождений',
          'На наливные станции магистральных нефтепроводов и нефтепродуктопроводов',
          'На объекты, предназначенные для приема и выдачи нефти и нефтепродуктов',
        ],
        correctAnswers: [
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
        ],
      },
      {
        code: '63638050',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
        ],
        correctAnswers: [
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
        ],
      },
      {
        code: '63638051',
        text:
          'В каком случае допускается торможение цистерн башмаками, изготовленными из материала, дающего искрение, на участках слива-налива?',
        answers: [
          'Не допускается ни в каком случае',
          'Если цистерны имеют прокладки из неискрящего материала',
          'Если прием и отгрузка нефти и нефтепродуктов осуществляется через специально оборудованные сливоналивные устройства',
          'Если цистерны имеют прокладки устойчивые к разрушению парами нефтепродуктов',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63638052',
        text:
          'Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?',
        answers: [
          'Годовое число циклов заполнений-опорожнений резервуара',
          'Показатели взрывоопасности хранимых нефтепродуктов',
          'Физико-химические свойства хранимых нефтепродуктов',
        ],
        correctAnswers: [
          'Годовое число циклов заполнений-опорожнений резервуара',
        ],
      },
      {
        code: '63638053',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63638054',
        text:
          'При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?',
        answers: [
          '20% объемных от НКПРП',
          '30% объемных от НКПРП',
          '40% объемных от НКПРП',
          '50% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63638055',
        text:
          'При какой вместимости резервуаров, работающих под избыточным внутренним давлением, допускается применение подогревательных устройств, размещаемых внутри или на наружной поверхности резервуаров?',
        answers: [
          'Не более 50 т',
          'Не более 60 т',
          'Не более 80 т',
          'Не более 100 т',
        ],
        correctAnswers: ['Не более 50 т'],
      },
      {
        code: '63638056',
        text:
          'Какое из перечисленных требований к испытанию на прочность после монтажа до пуска в эксплуатацию сосуда (аппарата), трубопровода (его участка) указано верно?',
        answers: [
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
          'Толщина заглушки должна быть рассчитана на условия работы при давлении выше пробного в 1,25 раза',
          'Использование запорной арматуры для отключения испытываемого сосуда и трубопровода допускается в присутствии работника, ответственного за исправное состояние и безопасную работу сосудов',
          'При периодическом освидетельствовании и техническом диагностировании с использованием метода акустической эмиссии и аммиака в качестве нагружающей среды не допускается испытывать отдельные технологические линии блоком',
        ],
        correctAnswers: [
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
        ],
      },
      {
        code: '63638057',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
        ],
      },
      {
        code: '63638058',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
          'Необходимо разработать планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
        ],
        correctAnswers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63638059',
        text:
          'Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?',
        answers: [
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
          'Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования',
          'Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации',
          'Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из операторной и по месту ее установки',
        ],
        correctAnswers: [
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
        ],
      },
      {
        code: '63638060',
        text:
          'Чем оснащаются системы разделения газожидкостных смесей в целях обеспечения высокой эффективности разделения фаз?',
        answers: [
          'Фильтрами',
          'Фазоразделителями',
          'Обратным клапаном',
          'Гидрозатвором',
        ],
        correctAnswers: ['Фазоразделителями'],
      },
      {
        code: '63638061',
        text:
          'Что из перечисленного не входит в общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
        ],
        correctAnswers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63638062',
        text:
          'Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?',
        answers: [
          'Заглубленные более чем на 0,5 м',
          'Заглубленные не менее чем на 1 м',
          'В соответствии с проектной документацией',
          'Все насосные станции',
        ],
        correctAnswers: ['Заглубленные более чем на 0,5 м'],
      },
      {
        code: '63638063',
        text:
          'На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?',
        answers: ['10 м', '15 м', '5 м', '8 м', '12 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63638064',
        text:
          'Каким образом определяется минимально допустимое расстояние от складов кислот и щелочей до взрывоопасных объектов?',
        answers: [
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
          'Расстояние определяется по нормам пожарной безопасности',
          'Расстояние должно быть обусловлено устойчивостью здания склада к воздействию ударной волны',
          'Расстояние определяется в соответствии с требованиями строительных норм и правил и с учетом расчетного радиуса опасной зоны',
        ],
        correctAnswers: [
          'Расстояние устанавливается с учетом радиусов интенсивного воздействия ударной взрывной волны и теплового излучения, должно обеспечивать устойчивость зданий складов к воздействию данных факторов',
        ],
      },
      {
        code: '63638065',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63638066',
        text:
          'Что необходимо обеспечивать при хранении ЛВЖ в таре в складских помещениях?',
        answers: [
          'Только необходимую кратность обмена воздуха',
          'Только наличие погрузочно-разгрузочных устройств',
          'Только контроль загазованности помещения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63638067',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63638068',
        text:
          'Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?',
        answers: ['Бетонным', 'Асфальтированным', 'Деревянным', 'Глинобитным'],
        correctAnswers: ['Бетонным'],
      },
      {
        code: '63638069',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63638070',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '1 год', '3 года', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63638071',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей и рабочими площадками',
        ],
      },
      {
        code: '63638072',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '1 год', '3 года', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63638073',
        text:
          'Что из перечисленного допускается в отношении резервуарных парков?',
        answers: [
          'Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара',
          'Ручной отбор проб светлых нефтепродуктов во время закачки или откачки продукта',
          'При хранении нефтепродуктов в резервуарах наличие подтоварной воды выше минимального уровня, обеспечиваемого устройством для дренажа воды',
          'Все перечисленное не допускается',
        ],
        correctAnswers: ['Все перечисленное не допускается'],
      },
      {
        code: '63638074',
        text:
          'Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?',
        answers: [
          'Наряд-допуск на указанные работы',
          'План работ, утвержденный техническим руководителем организации',
          'Оформление дополнительного документа на проведение указанных работ не требуется',
          'Внутреннее распоряжение о производстве указанных работ',
        ],
        correctAnswers: ['Наряд-допуск на указанные работы'],
      },
      {
        code: '63638075',
        text:
          'Какой электрифицированный транспорт во взрывозащищенном исполнении допускается применять на территории опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Только электропогрузчики',
          'Только самоходные аккумуляторные тележки (электрокары)',
          'Только тягачи',
          'Любой из перечисленных видов транспорта',
        ],
        correctAnswers: ['Любой из перечисленных видов транспорта'],
      },
      {
        code: '63638076',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Приборами измерения перепада давления на сепараторе',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
        ],
        correctAnswers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
        ],
      },
      {
        code: '63638077',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
          'По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'В присутствии начальника производства и начальника службы контрольно-измерительных приборов и автоматики (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63638078',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридического лица (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Главный инженер организации и работник службы охраны труда',
          'Главный механик и работник службы охраны труда',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: [
          'Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридического лица (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63638079',
        text:
          'На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?',
        answers: [
          'Не менее 10 м до и после нефтеловушек',
          'В непосредственной близости от нефтеловушек',
          'Не менее 15 м после нефтеловушек',
        ],
        correctAnswers: ['Не менее 10 м до и после нефтеловушек'],
      },
      {
        code: '63638080',
        text:
          'В каком случае допускается сброс химически загрязненных, технологических, смывных и других сточных вод без предварительной очистки?',
        answers: [
          'В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков',
          'В случае, когда это утверждено инспектором Ростехнадзора',
          'В случае, когда это утверждено внутренней документацией эксплуатирующей организации',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае, когда в эксплуатирующей организации имеются собственные очистные сооружения и магистральная сеть, предназначенная для приема таких стоков',
        ],
      },
      {
        code: '63638081',
        text:
          'Какие должны быть здания на территории склада жидкого аммиака по степени огнестойкости?',
        answers: [
          'Не ниже II степени огнестойкости',
          'Не ниже III степени огнестойкости',
          'Не ниже IV степени огнестойкости',
          'Не ниже V степени огнестойкости',
        ],
        correctAnswers: ['Не ниже II степени огнестойкости'],
      },
      {
        code: '63638082',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63638083',
        text:
          'С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?',
        answers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
          'С учетом химического анализа проб горючих веществ на содержание кислорода',
          'С учетом возможности проведения эффективной и безопасной очистки системы',
        ],
        correctAnswers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
      },
      {
        code: '63638084',
        text:
          'В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?',
        answers: [
          'При расположении береговых насосов более чем в 100 м от причала',
          'При расположении береговых насосов более чем в 50 м от причала',
          'Если это обосновано в проектной документации',
          'В любом случае',
        ],
        correctAnswers: [
          'При расположении береговых насосов более чем в 100 м от причала',
        ],
      },
      {
        code: '63638085',
        text:
          'Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?',
        answers: [
          'В пунктах разлива и фасовки нефтепродуктов',
          'В складских помещениях для хранения нефтепродуктов в таре',
          'На открытых площадках',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['В пунктах разлива и фасовки нефтепродуктов'],
      },
      {
        code: '63638086',
        text:
          'На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?',
        answers: ['4 м', '3 м', '10 м', '5 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63638087',
        text:
          'Какое из перечисленных требований к использованию переносных подогревателей на железнодорожных сливоналивных эстакадах указано неверно?',
        answers: [
          'При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом',
          'При использовании переносных пароподогревателей давление пара в подогревателе должно соответствовать показателям, установленным в технической документации (паспорте) пароподогревателя',
          'Разогрев нефтепродуктов в железнодорожных цистернах переносными электрическими подогревателями должен производиться только в сочетании с циркуляционным нагревом в выносном подогревателе (теплообменнике)',
          'При использовании переносных электрических подогревателей последние должны быть оснащены блокировочными устройствами, отключающими их при снижении уровня жидкости над нагревательным устройством ниже 500 мм',
        ],
        correctAnswers: [
          'При использовании переносных подогревателей допускается непосредственный непродолжительный контакт теплоносителя с нефтепродуктом',
        ],
      },
      {
        code: '63638088',
        text:
          'Для налива каких ЛВЖ сливоналивные устройства должны снабжаться устройствами отвода паров?',
        answers: [
          'С упругостью паров от 66,65 кПа',
          'С упругостью паров от 60,65 кПа',
          'С упругостью паров от 56,65 кПа',
          'С упругостью паров от 61,65 кПа',
        ],
        correctAnswers: ['С упругостью паров от 66,65 кПа'],
      },
      {
        code: '63638089',
        text:
          'В каких случаях на трубопроводах следует применять арматуру под приварку?',
        answers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа',
        ],
        correctAnswers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
        ],
      },
      {
        code: '63638090',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63638091',
        text:
          'На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?',
        answers: [
          'На три категории',
          'На четыре категории',
          'На две категории',
          'На пять категорий',
        ],
        correctAnswers: ['На три категории'],
      },
      {
        code: '63638092',
        text:
          'За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?',
        answers: [
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
          'За счет установки на технологических трубопроводах для транспортировки мазута линзовых компенсаторов',
          'За счет установки на технологических трубопроводах для транспортировки мазута сальниковых компенсаторов',
          'За счет установки на технологических трубопроводах для транспортировки мазута волнистых компенсаторов',
        ],
        correctAnswers: [
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
        ],
      },
      {
        code: '63638093',
        text:
          'В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?',
        answers: [
          'Не допускается ни в каком случае',
          'Для определения качества нефти и нефтепродуктов, хранящихся в резервуарах',
          'Для коммерческого учета нефти и нефтепродуктов, хранящихся в резервуарах',
          'Для вновь строящихся и реконструируемых резервуаров',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63638094',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'Только в технологическом регламенте на производство продукции',
          'Только в проектной документации',
          'Только в исходных данных на проектирование и технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63638095',
        text:
          'Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?',
        answers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 300 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 500 мм',
        ],
        correctAnswers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
        ],
      },
      {
        code: '63638096',
        text:
          'Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?',
        answers: [
          'Проектной документацией или документацией на техническое перевооружение',
          'Планом ликвидации аварий',
          'Эксплуатационной документацией',
          'Паспортом технического устройства',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63638097',
        text:
          'В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?',
        answers: [
          'В течение четырех лет',
          'В течение пяти лет',
          'В течение шести лет',
          'В течение года',
        ],
        correctAnswers: ['В течение четырех лет'],
      },
      {
        code: '63638098',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63638099',
        text:
          'При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?',
        answers: [
          '50% объемных от нижнего концентрационного предела распространения пламени',
          '20% объемных от нижнего концентрационного предела распространения пламени',
          '30% объемных от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          '50% объемных от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63638100',
        text:
          'Какие требования необходимо соблюдать при устройстве под блоками разделения воздуха подвального помещения?',
        answers: [
          'Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции',
          'Поддерживать температуру воздуха не ниже 15 °С, а также предусматривать систему приточно-вытяжной вентиляции',
          'Поддерживать температуру воздуха не ниже 14 °С, а также предусматривать систему кондиционирования воздуха',
          'Поддерживать температуру воздуха не ниже 4 °С, а также предусматривать систему пожаротушения',
        ],
        correctAnswers: [
          'Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции',
        ],
      },
      {
        code: '63638101',
        text:
          'На какой высоте должны быть ограждения в местах прохода людей и проезда транспорта под подвесными конвейерами и транспортерами при производстве фосфора и его соединений?',
        answers: [
          'Не менее 2,2 м',
          'Не более 2,2 м',
          'Не более 3,4 м',
          'Не менее 3,4 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63638102',
        text:
          'В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?',
        answers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 81 °C',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
        ],
      },
      {
        code: '63638103',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'Только в технологических блоках I категории взрывоопасности',
          'Только в технологических блоках II категории взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
          'В технологических блоках всех категорий взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63638104',
        text:
          'В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?',
        answers: [
          'В проектной документации',
          'В технологическом регламенте',
          'В эксплуатационной документации',
          'В техническом регламенте',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63638105',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'Помещения управления должны быть отдельно стоящими',
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать их к зданиям',
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63638106',
        text:
          'Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Проектной документацией (документацией на техническое перевооружение)',
          'Эксплуатационной документацией',
          'Технологическим регламентом',
          'Руководствами по безопасности',
        ],
        correctAnswers: [
          'Проектной документацией (документацией на техническое перевооружение)',
        ],
      },
      {
        code: '63638107',
        text:
          'Каким должен быть радиус кривизны отвода при изготовлении отводов способом гиба на специальных станках?',
        answers: [
          'Не менее трех диаметров трубы',
          'Не менее диаметра трубы',
          'Не менее двух диаметров трубы',
          'Не менее четырех диаметров трубы',
        ],
        correctAnswers: ['Не менее трех диаметров трубы'],
      },
      {
        code: '63638108',
        text:
          'Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?',
        answers: [
          'Все ответы неверны',
          'Только линзовых компенсаторов',
          'Только сальниковых компенсаторов',
          'Только волнистых компенсаторов',
          'Допускается применение всех перечисленных компенсаторов',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63638109',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Для всех ОПО без исключения',
          'Только для ОПО I и II классов опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63638110',
        text:
          'Каким образом определяется класс опасности ОПО складов нефти и нефтепродуктов?',
        answers: [
          'В зависимости от количества горючих жидкостей, находящихся в резервуарах, согласно проектной документации (документации на техническое перевооружение) и в соответствии с Федеральным законом "О промышленной безопасности опасных производственных объектов"',
          'В зависимости от количества горючих жидкостей, фактически находящихся в резервуарах, в соответствии с документацией на поставку нефти и нефтепродуктов и соответствии с Федеральным законом "Об охране окружающей среды"',
          'Определяется экспертными расчетами организацией, имеющей лицензию на проведение экспертизы промышленной безопасности, на основании эксплуатационной и проектной документации (документации на техническое перевооружение или реконструкцию)',
          'Класс опасности определяется Ростехнадзором в рамках процедуры регистрации опасных производственных объектов в государственном реестре опасных производственных объектов',
        ],
        correctAnswers: [
          'В зависимости от количества горючих жидкостей, находящихся в резервуарах, согласно проектной документации (документации на техническое перевооружение) и в соответствии с Федеральным законом "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63638111',
        text:
          'При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?',
        answers: [
          'При превышении концентрации паров более 20%',
          'При превышении концентрации паров более 30%',
          'При превышении концентрации паров более 50%',
        ],
        correctAnswers: ['При превышении концентрации паров более 20%'],
      },
      {
        code: '63638112',
        text:
          'Для каких технологических трубопроводов стилоскопирование проводится выборочно, но не менее двух соединений, выполненных одним сварщиком с использованием сварочных материалов из одной партии, при отсутствии в проекте иных указаний?',
        answers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
          'Для технологических трубопроводов опасных веществ 1-го и 2-го классов опасности',
          'Для технологических трубопроводов при расчетном давлении выше 10 МПа',
          'Для всех перечисленных технологических трубопроводов',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
        ],
      },
      {
        code: '63638113',
        text:
          'Каким образом должны включаться системы аварийной вентиляции производственных помещений ОПО складов нефти и нефтепродуктов?',
        answers: [
          'Автоматически от систем контроля загазованности воздушной среды в помещении',
          'Местным ручным включением',
          'Дистанционным включением из помещения управления',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63638114',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
        ],
        correctAnswers: [
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
        ],
      },
      {
        code: '63638115',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63638116',
        text:
          'Какое расстояние устанавливается внутри ограждения между лестницами, применяемыми для переходов через ограждения резервуаров для хранения жидкого аммиака?',
        answers: [
          'Не более 80 м',
          'Не более 95 м',
          'Не более 85 м',
          'Не более 90 м',
        ],
        correctAnswers: ['Не более 80 м'],
      },
      {
        code: '63638117',
        text:
          'С какой периодичностью заземлители, токоотводы подвергаются периодическому контролю?',
        answers: [
          'Один раз в 5 лет',
          'Один раз в год',
          'Один раз в 2 года',
          'Один раз в 3 года',
        ],
        correctAnswers: ['Один раз в 5 лет'],
      },
      {
        code: '63638118',
        text:
          'Каким из перечисленных требований должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
          'Полы в помещении должны быть нескользкими, с повышенной механической стойкостью',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63638119',
        text:
          'На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?',
        answers: ['2 м', '1 м', '3 м', '1,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63638120',
        text:
          'Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 0,4 м',
          'Не менее 0,5 м',
          'Не менее 0,6 м',
        ],
        correctAnswers: ['Не менее 0,2 м'],
      },
      {
        code: '63638121',
        text:
          'Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только показатели надежности и конструктивные особенности с учетом критических параметров',
          'Только физико-химические свойства перемещаемых продуктов',
          'Только параметры технологического процесса',
          'Должны быть учтены все перечисленные характеристики',
        ],
        correctAnswers: ['Должны быть учтены все перечисленные характеристики'],
      },
      {
        code: '63638122',
        text:
          'Какой категории обеспечения надежности электроснабжения должны быть электроприемники, обеспечивающие непрерывность технологического процесса производства на объектах производства и потребления продуктов разделения воздуха?',
        answers: [
          'К I категории надежности',
          'К III категории надежности',
          'К II категории надежности',
          'Требования к категории надежности не установлены',
        ],
        correctAnswers: ['К I категории надежности'],
      },
      {
        code: '63638123',
        text:
          'Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?',
        answers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,25 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,15 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,1 м',
        ],
        correctAnswers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
        ],
      },
      {
        code: '63638124',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии со сценариями возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий и их развития',
          'В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63638125',
        text:
          'Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений',
        ],
        correctAnswers: [
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
      },
      {
        code: '63638126',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только заключение о качестве сварных стыков',
          'Только заключение о техническом состоянии арматуры',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63638127',
        text:
          'На каком максимальном расстоянии от верха опоры должны быть расположены обслуживающие площадки высоких опор вращающихся печей и сушилок в лакокрасочном производстве?',
        answers: ['300 мм', '200 мм', '400 мм', '500 мм'],
        correctAnswers: ['300 мм'],
      },
      {
        code: '63638128',
        text:
          'Какой документ должен быть оформлен для проведения земляных работ на территории опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Наряд-допуск',
          'Акт-допуск',
          'Письменное разрешение технического руководителя организации',
          'Проект производства работ',
        ],
        correctAnswers: ['Наряд-допуск'],
      },
      {
        code: '63638129',
        text:
          'Какие из перечисленных требований к резиновым шлангам с металлическими наконечниками, используемым для налива жидкостей в железнодорожные и автомобильные цистерны, наливные суда и другие передвижные сосуды и аппараты, указаны неверно?',
        answers: [
          'Один конец проволоки (или тросика) соединяется пайкой (или под болт) с металлическими заземленными частями трубопровода, а другой - с металлическим наконечником шланга',
          'Наконечники шланга должны быть изготовлены из металла, не создающего искры при ударе',
          'Шланги должны быть обвиты медной проволокой диаметром не менее 1 мм или медным тросиком сечением не менее 2 мм2 с шагом витка не более 200 мм',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Шланги должны быть обвиты медной проволокой диаметром не менее 1 мм или медным тросиком сечением не менее 2 мм2 с шагом витка не более 200 мм',
        ],
      },
      {
        code: '63638130',
        text:
          'В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Выберите два правильных варианта ответа.',
        answers: [
          'Технической документации организации-изготовителя',
          'Проектной документации',
          'Руководств по безопасной эксплуатации',
          'Внутренних инструкций эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технической документации организации-изготовителя',
          'Проектной документации',
        ],
      },
      {
        code: '63638131',
        text:
          'Какой должна быть высота защитного ограждения каждой группы резервуаров относительно уровня расчетного объема разлившейся жидкости?',
        answers: [
          'На 0,2 м выше',
          'На 0,1 м выше',
          'На 0,15 м выше',
          'На 0,05 м выше',
        ],
        correctAnswers: ['На 0,2 м выше'],
      },
      {
        code: '63638132',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности не регламентируется',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63638133',
        text:
          'В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?',
        answers: [
          'Допускается при обосновании принятого технического решения в проектной документации',
          'Допускается при согласовании с организацией-изготовителем и федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Допускается в случае, если данное условие их применения определено в технической документации организации-изготовителя',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается при обосновании принятого технического решения в проектной документации',
        ],
      },
      {
        code: '63638134',
        text:
          'Что рекомендуется применять для изоляции внутренних стен и крыши изотермических резервуаров для хранения жидкого аммиака?',
        answers: [
          'Вспученный перлитовый песок',
          'Стеклянное волокно',
          'Минеральную вату',
          'Пенополиуретан',
        ],
        correctAnswers: ['Вспученный перлитовый песок'],
      },
      {
        code: '63638135',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Обратный клапан',
          'Предохранительный клапан',
          'Регулирующий клапан',
          'Гидроклапан',
          'Запорный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63638136',
        text:
          'Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?',
        answers: [
          'Требуемая скорость перемещения нефтепродукта',
          'Физико-химические свойства перемещаемой среды',
          'Размер судового трубопровода',
          'Параметры давления и температуры перемещаемой среды',
        ],
        correctAnswers: ['Требуемая скорость перемещения нефтепродукта'],
      },
      {
        code: '63638137',
        text:
          'Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров, обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63638138',
        text:
          'Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?',
        answers: [
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
          'Не менее 1 раза в год - для авиационного топлива и не менее 2-х раз в год - для остальных светлых нефтепродуктов и масел',
          'Не менее 2-х раз в год для любых нефтепродуктов',
        ],
        correctAnswers: [
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
        ],
      },
      {
        code: '63638139',
        text:
          'Каким требованиям должны соответствовать вновь проектируемые склады кислот, в которых возможно образование первичного кислотного облака? Выберите два правильных варианта ответа.',
        answers: [
          'На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках',
          'На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада',
          'Склады должны располагаться с наветренной стороны преобладающих направлений ветров относительно места расположения ближайших населенных пунктов',
          'Склады должны располагаться в более высоких местах по отношению к другим зданиям и сооружениям',
        ],
        correctAnswers: [
          'На территории складов должен быть обеспечен автоматический контроль за уровнем загазованности и сигнализация об аварийных утечках',
          'На территории складов должен быть установлен указатель направления ветра, видимый из любой точки территории склада',
        ],
      },
      {
        code: '63638140',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Дистанционное, неавтоматическое',
          'Ручное управление по месту',
          'Автоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63638141',
        text:
          'Какие из перечисленных требований к цистернам, находящимся под сливом (наливом), указаны неверно?',
        answers: [
          'Для слива растворителя из железнодорожных цистерн используются установки, предназначенные для нижнего слива-налива нефти и нефтепродуктов из железнодорожных цистерн',
          'Цистерны, находящиеся под сливом (наливом) должны быть заземлены',
          'Использование сливоналивных механизированных стояков со стационарной площадкой, лестницей и откидным мостиком для обслуживания горловины цистерны не допускается',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Использование сливоналивных механизированных стояков со стационарной площадкой, лестницей и откидным мостиком для обслуживания горловины цистерны не допускается',
        ],
      },
      {
        code: '63638142',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 24 часов',
          'В течение 2 часов',
          'В течение 8 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63638143',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Контроль сплошности изоляции и толщины стенок',
          'Продувка',
          'Установка заглушек',
          'Промывка (пропарка)',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63638144',
        text:
          'Где допускается прокладка трубопроводов неорганических жидких кислот и (или) щелочей при условии, что трубопроводы должны быть заключены в специальные желоба или короба (коллекторы) с отводом утечек кислот и щелочей в безопасные места, определяемые проектом?',
        answers: [
          'В местах пересечения железных и автомобильных дорог',
          'По наружным стенам зданий, не связанных с обращением кислот и щелочей',
          'Через бытовые помещения',
          'Через вспомогательные помещения',
        ],
        correctAnswers: ['В местах пересечения железных и автомобильных дорог'],
      },
      {
        code: '63638145',
        text:
          'С какой периодичностью проводится термографирование наружной поверхности резервуара жидкого аммиака в целях выявления участков с нарушенной теплоизоляцией?',
        answers: [
          'Один раз в год',
          'Один раз в полгода',
          'Один раз в месяц',
          'Один раз в квартал',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63638146',
        text:
          'При каком снижении уровня жидкости над нагревательным устройством должны быть отключены переносные электрические подогреватели блокировочными устройствами?',
        answers: ['Ниже 500 мм', 'Ниже 800 мм', 'Ниже 700 мм', 'Ниже 600 мм'],
        correctAnswers: ['Ниже 500 мм'],
      },
      {
        code: '63638147',
        text:
          'Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
        ],
        correctAnswers: [
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
        ],
      },
      {
        code: '63638148',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура в соответствии с проектной документацией',
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с тканевым уплотнением в затворе',
          'Арматура с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63638149',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63638150',
        text:
          'Какая должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['40 °C', '34 °C', '60 °C'],
        correctAnswers: ['40 °C'],
      },
      {
        code: '63638151',
        text:
          'Какие из перечисленных требований к сливным лоткам приемно-сливной эстакады для мазутов, гудронов и битумов указаны верно?',
        answers: [
          'Лотки должны быть оборудованы средствами подогрева слитого топлива',
          'Лотки должны быть перекрыты металлическими решетками, съемными крышками',
          'Лотки должны быть выполнены из негорючих материалов',
          'Для вновь проектируемых эстакад слива вязких нефтепродуктов (мазутов, гудронов и битумов) следует предусматривать закрытые системы слива',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63638152',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63638153',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 10 минут',
          'Через 5 минут',
          'Через 60 минут',
          'Через 30 минуты',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63638154',
        text:
          'Какие требования к сетям водопроводов и канализации и устройствам, подключаемым к сетям, не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
          'Осмотр и очистка канализационных сетей и колодцев производится по графикам и в соответствии с порядком проведения газоопасных работ',
          'В производственных помещениях, где возможно повышенное содержание кислорода, которое может привести к воспламенению одежды или наличие опасных веществ, которые могут вызвать химические ожоги, должны устанавливаться фонтанчики, краны, раковины или ванны самопомощи, аварийные души',
          'Соединение сетей хозяйственно-питьевого водопровода с сетями водопроводов, подающих воду технического качества, не допускается',
          'Устройства (фонтанчики, краны, раковины или ванны самопомощи, аварийные души) должны располагаться в легкодоступных местах и подключаться к хозяйственно-питьевому водопроводу',
        ],
        correctAnswers: [
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
        ],
      },
      {
        code: '63638155',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 10 минут',
          'Не менее 5 минут',
          'Не менее 30 минут',
          'Не менее 15 минуты',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63638156',
        text:
          'Какие из перечисленных видов арматур должны быть установлены совместно или по отдельности на технологических трубопроводах для транспортировки нефти и нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Запорная арматура',
          'Отсекающая арматура',
          'Распределительно-смесительная арматура',
          'Предохранительная арматура',
        ],
        correctAnswers: ['Запорная арматура', 'Отсекающая арматура'],
      },
      {
        code: '63638157',
        text:
          'Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Проектной документацией',
          'Техническим регламентом',
          'Технологическим регламентом',
          'Эксплуатационной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63638158',
        text:
          'В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?',
        answers: [
          'Не менее чем за 15 календарных дней',
          'Не менее чем за 7 календарных дней',
          'Не менее чем за 10 календарных дней',
          'Не менее чем за 30 календарных дней',
        ],
        correctAnswers: ['Не менее чем за 15 календарных дней'],
      },
      {
        code: '63638159',
        text:
          'Какой высоты должно быть ограждение площадок с техническими устройствами при размещении площадок на территории, имеющей общее ограждение?',
        answers: [
          'Не менее 1,2 м',
          'Не менее 2,0 м',
          'Не менее 0,2 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63638160',
        text:
          'В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?',
        answers: [
          'В соответствии с требованиями законодательства о градостроительной деятельности',
          'В соответствии с требованиями территориальных органов Ростехнадзора',
          'В соответствии с требованиями Общих правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии с техническими регламентами',
        ],
        correctAnswers: [
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
      },
      {
        code: '63638161',
        text:
          'Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?',
        answers: [
          'Применение гибких шлангов для налива при обосновании в проектной документации',
          'Нахождение водителей автомобильных цистерн, выполняющих операции слива-налива нефтепродуктов, в одежде, способной накапливать заряды статического электричества',
          'Эксплуатация сливоналивных устройств при обнаружении нарушения целостности единого контура заземления при обосновании в проектной документации',
          'Запуск двигателей автомобильных цистерн, находящихся на площадке, в случаях пролива нефтепродукта',
        ],
        correctAnswers: [
          'Применение гибких шлангов для налива при обосновании в проектной документации',
        ],
      },
      {
        code: '63638162',
        text:
          'Какая ширина верха земляного вала устанавливается для отдельно стоящего резервуара для хранения жидкого аммиака?',
        answers: [
          'Не менее 1 м',
          'Не менее 0,5 м',
          'Не менее 0,6 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 1 м'],
      },
      {
        code: '63638163',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63638164',
        text:
          'Каким должно быть максимальное сопротивление заземляющего устройства, предназначенного только для защиты от статического электричества?',
        answers: ['100 Ом', '150 Ом', '120 Ом', '80 Ом'],
        correctAnswers: ['100 Ом'],
      },
      {
        code: '63638165',
        text:
          'На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?',
        answers: ['500 мм', '300 мм', '600 мм', '800 мм'],
        correctAnswers: ['500 мм'],
      },
      {
        code: '63638166',
        text:
          'Из какого материала должны выполняться линии отбора кислорода на анализы независимо от параметров кислорода? Выберите два правильных варианта ответа.',
        answers: [
          'Из медных сплавов',
          'Из коррозионностойкой стали',
          'Из чугуна',
          'Из сплавов алюминия',
        ],
        correctAnswers: ['Из медных сплавов', 'Из коррозионностойкой стали'],
      },
      {
        code: '63638167',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите два правильных варианта ответа.',
        answers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
          'Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности',
        ],
        correctAnswers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
        ],
      },
      {
        code: '63638168',
        text:
          'На какой срок составляется план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся горные работы в подземных условиях?',
        answers: ['На 6 месяцев', 'На 1 год', 'На 1 квартал', 'На 1,5 года'],
        correctAnswers: ['На 6 месяцев'],
      },
      {
        code: '63638169',
        text:
          'Какое устройство должно быть установлено на территории склада жидкого аммиака?',
        answers: [
          'Указатель направления ветра',
          'Барометр',
          'Термометр',
          'Гигрометр',
        ],
        correctAnswers: ['Указатель направления ветра'],
      },
      {
        code: '63638170',
        text:
          'В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Время должно быть подтверждено расчетом, но не менее 20 минут',
          'Время должно быть подтверждено расчетом, но не менее 35 минут',
          'Время должно быть подтверждено расчетом, но не более 45 минут',
        ],
        correctAnswers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63638171',
        text:
          'Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?',
        answers: [
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
          'Изменения вносятся при наличии положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
          'Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
        ],
        correctAnswers: [
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
        ],
      },
      {
        code: '63638172',
        text:
          'Какой должна быть высота ограждения резервуаров для хранения жидкого аммиака?',
        answers: [
          'Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м',
          'Не менее чем на 0,2 м выше расчетного уровня разлившегося жидкого аммиака',
          'Не менее расчетного уровня разлившегося жидкого аммиака',
          'Не менее чем на 0,5 м выше расчетного уровня разлившегося жидкого аммиака',
        ],
        correctAnswers: [
          'Не менее чем на 0,3 м выше расчетного уровня разлившегося жидкого аммиака, но не менее 1 м, а для изотермических резервуаров - не менее 1,5 м',
        ],
      },
      {
        code: '63638173',
        text:
          'На какие наземные склады жидкого аммиака не распространяются Правила безопасности химически опасных производственных объектов?',
        answers: [
          'На склады аммиака в баллонах',
          'Правила распространяются на все перечисленные склады жидкого аммиака',
          'На раздаточные станции, расположенные в сельскохозяйственных районах, получающих аммиак из аммиакопровода',
          'На заводские склады, расположенные на территории организаций, производящих аммиак, использующих его в качестве сырья или вспомогательного материала для выпуска товарной продукции, применяющих аммиак в промышленных холодильных установках или для других целей',
          'На глубинные сельскохозяйственные склады, размещаемые на территории сельскохозяйственного района',
        ],
        correctAnswers: ['На склады аммиака в баллонах'],
      },
      {
        code: '63638174',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63638175',
        text: 'Чем должно быть оснащено оборудование для разделения суспензий?',
        answers: [
          'Обратным клапаном',
          'Гидрозатвором',
          'Фильтрами',
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
        correctAnswers: [
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63638176',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63638177',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63638178',
        text:
          'Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?',
        answers: [
          'Не более 2,5 м/ч',
          'Не более 1,5 м/ч',
          'Не более 2,0 м/ч',
          'Не более 3,5 м/ч',
        ],
        correctAnswers: ['Не более 2,5 м/ч'],
      },
      {
        code: '63638179',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Только средствами автоматического регулирования',
          'Только средствами противоаварийной защиты',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63638180',
        text:
          'Какие требования к тепловой изоляции резервуаров жидкого аммиака указаны неверно?',
        answers: [
          'При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке',
          'Устройство теплоизоляции днища изотермического резервуара должно исключать попадание влаги, сплошное промерзание грунта под фундаментом от хранимого аммиака и деформацию элементов конструкции резервуара',
          'Тепловая изоляция должна выполняться из негорючих или трудносгораемых материалов',
          'Изоляция днища изотермического резервуара в зоне окраек должна быть сплошной, из прочных твердых материалов, которые должны лежать на бетонном фундаменте одностенного резервуара и на окрайках наружного днища двухстенного резервуара',
        ],
        correctAnswers: [
          'При применении в качестве наружной изоляции материалов, подверженных горению, обеспечиваются меры, исключающие возможность воспламенения изоляции (орошение, защита негорючими покрытиями и т. п.), согласованные в установленном порядке',
        ],
      },
      {
        code: '63638181',
        text:
          'Какие из перечисленных требований к автомобильным сливоналивным станциям указаны неверно?',
        answers: [
          'Допускается кратковременный запуск двигателей автомобильных цистерн, находящихся на площадке, в целях освобождения места для полной уборки пролитого нефтепродукта в случаях его пролива',
          'Автомобильные наливные станции должны быть оборудованы специальными устройствами (светофорами, шлагбаумами или другими средствами, ограничивающими несогласованное движение транспорта) для предотвращения выезда заполненных нефтепродуктами автомобильных цистерн с опущенными в их горловины наливными устройствами',
          'Автомобильные цистерны, стоящие под сливом-наливом на автомобильных наливных станциях, пунктах, должны быть заземлены. При отсутствии замкнутой электрической цепи "заземляющее устройство - автомобильная цистерна" автомобильные сливоналивные станции и пункты должны быть оборудованы блокировкой, исключающей возможность запуска насосов для перекачки нефтепродуктов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Допускается кратковременный запуск двигателей автомобильных цистерн, находящихся на площадке, в целях освобождения места для полной уборки пролитого нефтепродукта в случаях его пролива',
        ],
      },
      {
        code: '63638182',
        text:
          'В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?',
        answers: [
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
          'В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов',
          'В соответствии с требованиями градостроительного законодательства',
        ],
        correctAnswers: [
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
        ],
      },
      {
        code: '63638183',
        text:
          'До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?',
        answers: [
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
          'До момента заполнения резервуара до верхней проектной отметки',
          'До момента срабатывания блокировки ограничения скорости заполнения резервуара',
        ],
        correctAnswers: [
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
        ],
      },
      {
        code: '63638184',
        text:
          'В каком случае при использовании переносных подогревателей допускается непосредственный контакт теплоносителя с нефтепродуктом?',
        answers: [
          'Если давление пара в подогревателе соответствует показателям, установленным в технической документации (паспорте) пароподогревателя',
          'Если подогреватели имеют взрывозащищенное исполнение',
          'Если это обусловлено проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63638185',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более 3 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63638186',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63638187',
        text:
          'При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?',
        answers: [
          '50% объемных от НКПРП',
          '20% объемных от НКПРП',
          '30% объемных от НКПРП',
          '40% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63638188',
        text:
          'Какой ширины предусматривается охранная зона межзаводского трубопровода кислот или щелочей, прокладываемого вне территории предприятия?',
        answers: [
          'Не менее 2 м с каждой его стороны',
          'Не менее 1 м с каждой его стороны',
          'Не менее 5 м с каждой его стороны',
          'Не менее 10 м с каждой его стороны',
        ],
        correctAnswers: ['Не менее 2 м с каждой его стороны'],
      },
      {
        code: '63638189',
        text: 'Какой должна быть температура подогрева мазута в резервуарах?',
        answers: [
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не превышать 90 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 10 °C и не превышать 95 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не превышать 100 °C',
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 5 °C и не превышать 85 °C',
        ],
        correctAnswers: [
          'Ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не превышать 90 °C',
        ],
      },
      {
        code: '63638190',
        text:
          'В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?',
        answers: [
          'При расположении береговых насосов более чем в 100 м от причала',
          'При расположении береговых насосов более чем в 50 м от причала',
          'Если это обосновано в проектной документации',
          'В любом случае',
        ],
        correctAnswers: [
          'При расположении береговых насосов более чем в 100 м от причала',
        ],
      },
      {
        code: '63638191',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63638192',
        text:
          'Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к ограждению и габаритам трапов ливневой канализации, приямков и подвалов, расположенных за пределами площадок с сосудами и сливоналивными устройствами жидких продуктов разделения воздуха на расстоянии менее 10,0 м?',
        answers: [
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем 1,0 м',
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,1 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем 1,0 м',
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем 0,1 м',
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,1 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем 0,5 м',
        ],
        correctAnswers: [
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем 1,0 м',
        ],
      },
      {
        code: '63638193',
        text:
          'В каком месте может размещаться оборудование, работающее на аммиаке?',
        answers: [
          'Только в помещении потребителей холода',
          'Только в машинном или аппаратном отделении',
          'Только на открытой площадке',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63638194',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Предотвращение взрывов внутри технологического оборудования',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Периодический контроль за состоянием воздушной среды',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63638195',
        text:
          'На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?',
        answers: [
          'На 0,3 м',
          'На 1,0 м',
          'На 0,5 м',
          'Должны полностью доходить до пола и покрытия (перекрытия) насосной станции',
        ],
        correctAnswers: ['На 0,3 м'],
      },
      {
        code: '63638196',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63638197',
        text:
          'Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Выберите два правильных варианта ответа.',
        answers: [
          'Из полосовой стали сечением не менее 48 мм²',
          'Из круглой стали диаметром более 6 мм',
          'Из стальной проволоки диаметром не менее 5 мм',
          'Из стальной ленты сечением не менее 24 мм²',
        ],
        correctAnswers: [
          'Из полосовой стали сечением не менее 48 мм²',
          'Из круглой стали диаметром более 6 мм',
        ],
      },
      {
        code: '63638198',
        text:
          'В каких резервуарах допускается хранение нефти и нефтепродуктов для вновь строящихся и реконструируемых опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Во всех перечисленных',
          'В подземных резервуарах',
          'В надземных резервуарах',
          'В заглубленных резервуарах',
        ],
        correctAnswers: ['В надземных резервуарах'],
      },
      {
        code: '63638199',
        text:
          'В каком случае допускается сбрасывать взрывопожароопасные и пожароопасные нефтепродукты в канализацию?',
        answers: [
          'Не допускается ни в каком случае',
          'По решению технического руководителя организации',
          'При авариях',
          'После предварительной локальной очистки',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63638200',
        text:
          'Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?',
        answers: [
          'Подтоварные воды от отстоя нефти и нефтепродуктов',
          'Сточные воды от зачистки и пропарки резервуаров для нефти и нефтепродуктов',
          'Нефтешлам',
          'Все перечисленное',
        ],
        correctAnswers: ['Подтоварные воды от отстоя нефти и нефтепродуктов'],
      },
      {
        code: '63638201',
        text:
          'Какая ширина должна быть у автомобильных дорог для подъезда к складу жидкого аммиака и проезду по его территории к зданиям и сооружениям?',
        answers: [
          'Не менее 3,5 м',
          'Не менее 3 м',
          'Не менее 2,5 м',
          'Не менее 2 м',
        ],
        correctAnswers: ['Не менее 3,5 м'],
      },
      {
        code: '63638202',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63638203',
        text:
          'Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: ['2%', '1%', '3%', '5%'],
        correctAnswers: ['2%'],
      },
      {
        code: '63638204',
        text:
          'Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?',
        answers: [
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
          'Измерение указанного сопротивления должно проводиться при относительной влажности окружающего воздуха не выше 40%',
          'Площадь соприкосновения измерительного электрода с поверхностью оборудования не должна превышать 30 см²',
          'При измерениях электрод должен располагаться в точках поверхности оборудования, наименее удаленных от точек контакта поверхности с заземленными металлическими элементами, деталями, арматурой',
        ],
        correctAnswers: [
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
        ],
      },
      {
        code: '63638205',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система парового отопления',
          'Система водяного отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63638206',
        text:
          'Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?',
        answers: [
          'Не менее 0,25 м',
          'Не менее 0,75 м',
          'Не менее 0,5 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 0,25 м'],
      },
      {
        code: '63638207',
        text:
          'Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?',
        answers: [
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
          'Производительность наполнения (опорожнения) резервуаров может превышать суммарной пропускной способности установленных на резервуаре дыхательных и предохранительных устройств, если это обусловлено в проектной документации',
          'При обнаружении нарушения герметичности основного стального резервуара с защитной стенкой необходимо немедленно устранить причину утечки продукта',
          'При оснащении резервуарных парков газоуравнительной системой допускается объединять ею резервуары с авиационными и автомобильными бензинами',
        ],
        correctAnswers: [
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
        ],
      },
      {
        code: '63638208',
        text:
          'Кем может проводиться зачистка резервуаров и тары на опасных производственных объектах складов нефти и нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Персоналом эксплуатирующей организации',
          'Специализированной организацией',
          'Подрядной организацией, занимающейся экспертизой промышленной безопасности',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Персоналом эксплуатирующей организации',
          'Специализированной организацией',
        ],
      },
      {
        code: '63638209',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63638210',
        text:
          'Что из перечисленного допускается на ОПО складов нефти и нефтепродуктов?',
        answers: [
          'Установка средств контроля и сигнализации за содержанием пожаровзрывоопасных и токсичных веществ на входе в коллектор очистных сооружений',
          'Располагать колодцы в пределах отбортовок и обвалований отдельно стоящего оборудования, содержащих взрывоопасные продукты',
          'Прямое соединение канализации загрязненных стоков с бытовой канализацией без гидрозатворов при наличии в местах соединения средств контроля за содержанием предельно допустимой нормы',
          'Располагать колодцы на сетях канализации под эстакадами технологических трубопроводов',
          'Допускается все перечисленное',
        ],
        correctAnswers: [
          'Установка средств контроля и сигнализации за содержанием пожаровзрывоопасных и токсичных веществ на входе в коллектор очистных сооружений',
        ],
      },
      {
        code: '63638211',
        text:
          'Что не соответствует требованиям, предъявляемым к освещению в производственных и вспомогательных зданиях?',
        answers: [
          'Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа',
          'Естественное и искусственное освещение в производственных и вспомогательных зданиях и помещениях объектов производств и потребления продуктов разделения воздуха, включая аварийное освещение основных рабочих мест, должно соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов',
          'Организациями, эксплуатирующими объекты производства и потребления продуктов разделения воздуха, должны осуществляться систематические проверки исправности сети аварийного освещения в порядке и с периодичностью, установленной эксплуатирующей организацией',
          'Не допускается загромождать световые проемы помещений',
        ],
        correctAnswers: [
          'Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа',
        ],
      },
      {
        code: '63638212',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Только наличие природных оврагов, выемок, низин',
          'Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63638213',
        text:
          'Какие требования предъявляются к температуре подогрева мазута в резервуарах?',
        answers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C',
        ],
        correctAnswers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
        ],
      },
      {
        code: '63638214',
        text:
          'Какая арматура применяется на трубопроводах жидкого и газообразного аммиака?',
        answers: [
          'Стальная арматура и фасонные части',
          'Стальная арматура и фасонные части, а также допускается применение чугунной запорно-регулирующей арматуры',
          'Стальная арматура и фасонные части, а также арматура и фитинги с деталями из меди, цинка и их сплавов',
          'Стальная арматура и фасонные части, а также допускается применение запорной арматуры из ковкого и высокопрочного чугуна',
        ],
        correctAnswers: ['Стальная арматура и фасонные части'],
      },
      {
        code: '63638215',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 12 часов',
          'Не менее 4 часов',
          'Не менее 8 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63638216',
        text:
          'Для каких реципиентов в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?',
        answers: [
          'Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа',
          'Вместимостью 200 м³ с рабочим давлением 1,6 МПа',
          'Вместимостью более 10 м³ с рабочим давлением более 1,3 МПа',
          'Вместимостью более 20 м³ с рабочим давлением более 1,5 МПа',
        ],
        correctAnswers: [
          'Вместимостью более 200 м³ с рабочим давлением более 1,6 МПа',
        ],
      },
      {
        code: '63638217',
        text:
          'Какими документами должны сопровождаться технологические трубопроводы после монтажа и ремонта?',
        answers: [
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
          'Документами о стоимости материалов и работ по проведению монтажа и ремонта',
          'Лицензиями и другими учредительными документами организации, проводившей монтаж и ремонт',
          'Разрешениями организации, проводившей монтаж и ремонт на проведение этих видов работ',
        ],
        correctAnswers: [
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
        ],
      },
      {
        code: '63638218',
        text:
          'Где размещают лаковыпускные отделения в производстве лаков на конденсационных смолах?',
        answers: [
          'Только на открытых площадках',
          'Только в отдельно стоящем корпусе',
          'Только в изолированном помещении корпуса синтеза',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63638219',
        text:
          'В каком случае разрешается выполнение болтовых соединений молниеприемников с токоотводами и токоотводов с заземлителями с переходным сопротивлением не более 0,05 Ом?',
        answers: [
          'Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода',
          'Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области пожарной безопасности',
          'Разрешается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Не разрешается ни в каком случае',
        ],
        correctAnswers: [
          'Разрешается при недопустимости проведения огневых работ и при условии обязательного ежегодного контроля сопротивления перед началом грозового периода',
        ],
      },
      {
        code: '63638220',
        text:
          'Какие требования предъявляются к трубопроводам для транспортировки кислот и щелочей, прокладываемым по эстакадам? Выберите два правильных варианта ответа.',
        answers: [
          'Трубопроводы должны быть защищены от падающих предметов',
          'Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств',
          'Трубопроводы для транспортировки кислот и щелочей следует располагать на самых верхних ярусах',
          'Трубопроводы для транспортировки кислот и щелочей необходимо изготавливать из композиционных материалов',
        ],
        correctAnswers: [
          'Трубопроводы должны быть защищены от падающих предметов',
          'Трубопроводы должны быть защищены от возможных ударов со стороны транспортных средств',
        ],
      },
      {
        code: '63638221',
        text:
          'Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?',
        answers: [
          'Проектная организация',
          'Эксплуатирующая организация',
          'Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов',
          'Монтажно-наладочная организация',
        ],
        correctAnswers: ['Проектная организация'],
      },
      {
        code: '63638222',
        text:
          'Какие требования к предохранительным клапанам, устанавливаемым на резервуары жидкого аммиака, указаны неверно?',
        answers: [
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
          'На наружных оболочках изотермических резервуаров с засыпной изоляцией разрешается не устанавливать предохранительные клапаны, если такие клапаны имеются на буферном сосуде (газгольдере) азота или на трубопроводе, который соединяет наружную оболочку с буферным сосудом',
          'Применение рычажно-грузовых предохранительных клапанов не допускается',
          'Параллельно с рабочими предохранительными клапанами необходимо установить резервные предохранительные клапаны',
        ],
        correctAnswers: [
          'Не допускается изготовление предохранительных и вакуумных клапанов для изотермических резервуаров из алюминиевых сплавов',
        ],
      },
      {
        code: '63638223',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводится не реже двух раз в смену',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
        ],
        correctAnswers: [
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63638224',
        text:
          'В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?',
        answers: [
          'В проектной документации',
          'В производственной инструкции по наливу нефти и нефтепродуктов',
          'В нормативных правовых актах в области охраны труда',
          'В нормативных правовых актах в области промышленной безопасности',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63638225',
        text:
          'В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны',
          'Допускается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Допускается в любом случае',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащитные обратные клапаны',
        ],
      },
      {
        code: '63638226',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: [
          '5 лет',
          '2 года',
          '3 года',
          'Определяются руководством объекта',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63638227',
        text:
          'При каких условиях допускается проводить технологический взрывоопасный процесс в области критических значений температур на объектах, связанных с производством растительных масел?',
        answers: [
          'В случае принятия мер, исключающих или предотвращающих появление источников зажигания',
          'При наличии у персонала средств индивидуальной защиты',
          'При условии наличия наряда-допуска',
          'Не допускается ни при каких условиях',
        ],
        correctAnswers: [
          'В случае принятия мер, исключающих или предотвращающих появление источников зажигания',
        ],
      },
      {
        code: '63638228',
        text:
          'Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?',
        answers: [
          'Не более 0,03 Ом на каждый контакт',
          'Не более 0,05 Ом на каждый контакт',
          'Не более 0,5 Ом на каждый контакт',
          'Не более 0,1 Ом на каждый контакт',
        ],
        correctAnswers: ['Не более 0,03 Ом на каждый контакт'],
      },
      {
        code: '63638229',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63638230',
        text:
          'Какие требования установлены к отделениям, в которых производят дробление пека, приготовление или разогрев электродной массы, и к оборудованию в этих отделениях?',
        answers: [
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть снабжено средствами местного отсоса',
          'Отделения должны быть совмещены с остальными рабочими помещениями, оборудование должно быть герметичным',
          'Отделения должны быть изолированы от остальных рабочих помещений, к оборудованию специальных требований не установлено',
        ],
        correctAnswers: [
          'Отделения должны быть изолированы от остальных рабочих помещений, а оборудование должно быть герметизировано или надежно укрыто и снабжено средствами местного отсоса',
        ],
      },
      {
        code: '63638231',
        text:
          'Какими клапанами должны быть оборудованы резервуары для аварийного сброса нефти и нефтепродуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Дыхательными клапанами',
          'Предохранительными клапанами',
          'Обратными клапанами',
          'Регулирующими клапанами',
        ],
        correctAnswers: [
          'Дыхательными клапанами',
          'Предохранительными клапанами',
        ],
      },
      {
        code: '63638232',
        text:
          'Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?',
        answers: [
          'Разработчиком проекта по исходным данным',
          'Разработчиком проекта по литературным (справочным) данным',
          'Разработчиком проекта',
          'Разработчиком проекта по расчетным данным',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63638233',
        text:
          'Чем должны быть оснащены подводящие к смесителям коммуникации, проектируемые с целью обеспечения максимально возможного уровня эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
          'Фильтрами',
          'Автоматическим газоанализатором',
          'Автоматическими датчиками давления',
        ],
        correctAnswers: [
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
        ],
      },
      {
        code: '63638234',
        text:
          'Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?',
        answers: [
          'Только автоматически, когда стендер достигает обусловленного граничного положения',
          'Только вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал',
          'Только дистанционно с учетом нажатия кнопки на центральном пульте управления',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63638235',
        text:
          'Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Только посредством запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде',
          'Только перепуском части продукта во всасывающий трубопровод насоса',
          'Только установкой частотно-регулируемого электропривода насоса',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63638236',
        text:
          'Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Обеспечение селективности защит на устройствах электроснабжающей и электропотребляющей организации',
          'Наличие возможности синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63638237',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63638238',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Порядок действий в случае аварий на объекте',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63638239',
        text:
          'Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['Аварийная', 'Производственная', 'Дождевая', 'Бытовая'],
        correctAnswers: ['Аварийная'],
      },
      {
        code: '63638240',
        text:
          'Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Выберите два правильных варианта ответа.',
        answers: [
          'В аппаратном отделении',
          'В машинном отделении',
          'На открытой площадке',
          'Над машинным отделением',
        ],
        correctAnswers: ['В аппаратном отделении', 'В машинном отделении'],
      },
      {
        code: '63638241',
        text:
          'В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?',
        answers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 10 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
          'После назначения нового руководителя организации, эксплуатирующей объект',
          'Не менее чем за 30 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63638242',
        text:
          'Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?',
        answers: ['6 м/ч', '4 м/ч', '8 м/ч', '2 м/ч'],
        correctAnswers: ['6 м/ч'],
      },
      {
        code: '63638243',
        text:
          'Какие требования к системам противоаварийной защиты, обеспечивающим защиту резервуаров жидкого аммиака от переполнения, указаны неверно?',
        answers: [
          'Для резервуаров вместимостью до 10 м³ (включительно) защита обеспечивается дублированием систем контроля параметров',
          'Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров',
          'Для резервуаров вместимостью 50 м³ и более защита обеспечивается дублированием систем контроля, наличием систем самодиагностики и сопоставлением технологически связанных параметров',
          'Для резервуаров вместимостью до 50 м³ защита обеспечивается дублированием систем контроля и наличием систем самодиагностики с индикацией исправного состояния',
        ],
        correctAnswers: [
          'Для резервуаров вместимостью до 25 м³ защита обеспечивается дублированием систем контроля и сопоставлением технологически связанных параметров',
        ],
      },
    ],
    63645: [
      {
        code: '63645000',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'Если выбросы рассчитываются, в количествах определяемых условиями безопасной остановки технологического процесса',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'В случае использования специальных систем аварийного освобождения',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63645001',
        text:
          'Какие требования предъявляются к температурному режиму работы регенераторов?',
        answers: [
          'Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха',
          'Температурный режим следует поддерживать строго в пределах, оговоренных технологической инструкцией',
          'Температурный режим должен обеспечить работу регенераторов в оптимальном режиме',
          'Температурный режим должен регулироваться автоматически для исключения колебания температурного режима регенераторов',
        ],
        correctAnswers: [
          'Температурный режим должен исключать возможность заноса углеводородов в блок разделения воздуха',
        ],
      },
      {
        code: '63645002',
        text:
          'Какие требования безопасности предъявляются к клапанным коробкам регенераторов?',
        answers: [
          'Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать',
          'Необходимо контролировать отсутствие жидкого кислорода в клапанных коробках продувкой клапанных коробок через соответствующие продувочные вентили по мере необходимости',
          'Допускается частичное попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать',
          'Контроль за отсутствием жидкого кислорода в клапанных коробках кислородных регенераторов должен осуществляться ежесуточно продувкой через соответствующую арматуру',
        ],
        correctAnswers: [
          'Не допускается попадание жидкого кислорода в клапанные коробки регенераторов. Отсутствие жидкого кислорода в клапанных коробках необходимо контролировать',
        ],
      },
      {
        code: '63645003',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63645004',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'При деформации фланцев',
          'При срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'При неудовлетворительном состоянии уплотнительных поверхностей',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63645005',
        text:
          'Что должно контролироваться перед заполнением адсорбера адсорбентом?',
        answers: [
          'Цвет адсорбента и концентрация двуокиси углерода в нем',
          'Концентрация и состав взрывоопасных примесей в перерабатываемом воздухе',
          'Температура и влажность адсорбента',
          'Влажность и насыпная масса адсорбента',
        ],
        correctAnswers: ['Влажность и насыпная масса адсорбента'],
      },
      {
        code: '63645006',
        text:
          'В соответствии с каким документом выполняется защита от статического электричества технических устройств и коммуникаций газообразного кислорода и жидких продуктов разделения воздуха?',
        answers: [
          'В соответствии с распорядительными документами эксплуатирующей организации',
          'В соответствии с проектной документацией (документацией)',
          'В соответствии с внутренними инструкциями эксплуатирующей организации',
          'В соответствии с технологическими регламентами',
        ],
        correctAnswers: [
          'В соответствии с проектной документацией (документацией)',
        ],
      },
      {
        code: '63645007',
        text:
          'Из какого материала должны выполняться линии отбора кислорода на анализы независимо от параметров кислорода? Выберите 2 правильных варианта ответа.',
        answers: [
          'Из медных сплавов',
          'Из чугуна',
          'Из коррозионно-стойкой стали',
          'Из сплавов алюминия',
        ],
        correctAnswers: ['Из медных сплавов', 'Из коррозионно-стойкой стали'],
      },
      {
        code: '63645008',
        text:
          'Что необходимо делать для обеспечения взрывобезопасной эксплуатации воздухоразделительных установок организации, эксплуатирующей объекты производства продуктов разделения воздуха?',
        answers: [
          'Контролировать герметичность установок',
          'Проводить периодическое обследование состояния установок в соответствии с требованиями технологического регламента',
          'Допускать к работе на установках обученный и аттестованный персонал',
          'Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента',
        ],
        correctAnswers: [
          'Производить контроль содержания взрывоопасных примесей в технологических потоках установки в соответствии с требованиями технологического регламента',
        ],
      },
      {
        code: '63645009',
        text:
          'В соответствии с требованиями какого документа осуществляется освидетельствование и эксплуатация баллонов с продуктами разделения воздуха?',
        answers: [
          'В соответствии с требованиями документации на оборудование',
          'В соответствии с требованиями федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением',
          'В соответствии с требованиями Технического регламента Таможенного союза "О безопасности машин и оборудования"',
        ],
        correctAnswers: [
          'В соответствии с требованиями федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением',
        ],
      },
      {
        code: '63645010',
        text:
          'В каком случае помещение управления соседних кислородно-распределительных (регуляторных) пунктов необходимо удалять от помещения линии регулирования на расстояние не менее 15,0 м? Выберите 2 правильных варианта ответа.',
        answers: [
          'При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в отдельно стоящем здании',
          'При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в помещении, пристроенном к другим зданиям',
          'При подводе кислорода по трубопроводам диаметром 100 мм и более к КРП, размещенному в отдельно стоящем здании или в помещении, пристроенном к другим зданиям',
          'При подводе кислорода по трубопроводам диаметром 200 мм и более к КРП, размещенному в отдельно стоящем здании или в помещении, пристроенном к другим зданиям',
        ],
        correctAnswers: [
          'При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в отдельно стоящем здании',
          'При подводе кислорода по трубопроводам диаметром 300 мм и более к КРП, размещенному в помещении, пристроенном к другим зданиям',
        ],
      },
      {
        code: '63645011',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63645012',
        text: 'Какие ремонтные работы выполняются по наряду-допуску?',
        answers: [
          'Ремонтные работы на низкотемпературных технических устройствах без их полного отогрева',
          'Ремонтные работы на оборудовании, отключенном от работающей воздухоразделительной установки',
          'Ремонтные работы на участке трубопровода, отключенном от работающей воздухоразделительной установки',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63645013',
        text: 'Какое оборудование подлежит обезжириванию?',
        answers: [
          'Средства измерений, работающие при давлении до 0,6 МПа, конструкция которых позволяет устанавливать их в положении, обеспечивающем свободное стекание масла с внутренних поверхностей, соприкасающихся с кислородом',
          'Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
          'Арматура перед ее монтажом, предназначенная для работы с кислородом, обезжиривание которой было проведено на заводе-изготовителе (что подтверждается сопроводительными документами или соответствующим клеймением) и не нарушена упаковка',
        ],
        correctAnswers: [
          'Аппараты и коммуникации на потоке газообразного кислорода высокого давления',
        ],
      },
      {
        code: '63645014',
        text:
          'Какое основное требование предъявляется к осуществлению ремонта электроприводов арматуры подачи воздуха (азота) в турбодетандеры, блоки комплексной очистки, подачи воздуха в воздухоразделительные установки, выдачи продукционных кислорода и азота, а также арматуры азотно-водяного охлаждения и регулирующих клапанов?',
        answers: [
          'Ремонт осуществляется во время остановок арматуры',
          'В соответствии с руководством по эксплуатации на арматуру',
          'В соответствии с локальным нормативным документом организации в зависимости от вида ремонта',
          'В соответствии с графиком ремонта',
        ],
        correctAnswers: [
          'В соответствии с руководством по эксплуатации на арматуру',
        ],
      },
      {
        code: '63645015',
        text:
          'В соответствии с каким документом осуществляется эксплуатация криогенных сосудов и технических устройств различного назначения, оснащенных сосудами Дьюара?',
        answers: [
          'Только в соответствии с технологической инструкцией, учитывающей требования проектной документации (документации)',
          'Только в соответствии с Правилами безопасности химически опасных производственных объектов',
          'Только в соответствии с эксплуатационной документацией разработчика',
          'Со всеми перечисленными документами',
        ],
        correctAnswers: ['Со всеми перечисленными документами'],
      },
      {
        code: '63645016',
        text:
          'Какие требования безопасности следует выполнить перед осмотром внутренних узлов адсорберов блоков комплексной очистки или их ремонтом?',
        answers: [
          'Обеспечивать защиту персонала от термических воздействий',
          'Обеспечить надежный и эффективный воздухообмен вентиляционной системы',
          'Адсорбент следует подвергнуть регенерации, а затем продуть воздухом во избежание отравлений выделяющимся азотом',
          'Выгрузить адсорбент перед осмотром или ремонтом узлов адсорберов',
        ],
        correctAnswers: [
          'Адсорбент следует подвергнуть регенерации, а затем продуть воздухом во избежание отравлений выделяющимся азотом',
        ],
      },
      {
        code: '63645017',
        text:
          'В соответствии с требованиями какого документа производится эксплуатация технических устройств, входящих в состав воздухоразделительных установок, криогенных комплексов и систем хранения жидких продуктов разделения воздуха?',
        answers: [
          'Инструкции по эксплуатации технических устройств',
          'Технологического регламента',
          'Документации организации-изготовителя технических устройств',
          'Стандарта организации',
        ],
        correctAnswers: ['Технологического регламента'],
      },
      {
        code: '63645018',
        text:
          'Чем определяется размещение на объектах производства продуктов разделения воздуха мест воздухозабора относительно производств, являющихся источниками загрязнения, а также мест переработки (сжигания) бытовых и промышленных отходов и других возможных источников загрязнения воздуха?',
        answers: [
          'Требованиями федеральных норм и правил в области промышленной безопасности',
          'Требованиями технической документации заводов - изготовителей оборудования',
          'Нормами проектирования объектов и результатами мониторинга',
          'Требованиями локальных нормативных документов организации',
        ],
        correctAnswers: [
          'Нормами проектирования объектов и результатами мониторинга',
        ],
      },
      {
        code: '63645019',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63645020',
        text:
          'При выполнении какого условия допускается сброс продувочного газа (воздуха, азота), содержащего пары растворителя, в производственное помещение при обезжиривании технических устройств и трубопроводов?',
        answers: [
          'После поглощения паров растворителя',
          'В присутствии лица, ответственного за проведение обезжиривания',
          'Не допускается ни в каком случае',
          'После очистки',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63645021',
        text:
          'С какой скоростью необходимо обеспечивать равномерное охлаждение тепломассообменных аппаратов в период остановки воздухораспределительной установки?',
        answers: [
          'Со скоростью, определенной в технологической инструкции',
          'Со скоростью, определенной в документации завода-изготовителя',
          'Со скоростью, определенной в инструкции по ремонту оборудования',
          'Со скоростью, определенной технологическим процессом',
        ],
        correctAnswers: [
          'Со скоростью, определенной в документации завода-изготовителя',
        ],
      },
      {
        code: '63645022',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, при производстве ремонта турбодетандеров или при замене фильтрующих элементов детандерных фильтров, указаны неверно?',
        answers: [
          'Должна быть закрыта арматура на входе воздуха в турбодетандер и на выходе из него',
          'На азотных турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера. Перед ремонтом эти турбодетандеры продуваются воздухом',
          'Должно быть отключено электропитание',
          'На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера',
        ],
        correctAnswers: [
          'На всех турбодетандерах необходимо устанавливать заглушки на подаче и выходе газа из турбодетандера',
        ],
      },
      {
        code: '63645023',
        text:
          'Какие требования к техническим устройствам и инженерным коммуникациям не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Система продувок кислородных, азотных и аргонных технических устройств и коммуникаций должна исключать возможность поступления продуктов разделения воздуха в помещение',
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
          'При изменении технологического процесса или реконструкции производственного участка действующие на этом участке вентиляционные системы приводятся в соответствие с новыми производственными условиями',
          'На все вентиляционные системы составляются соответствующие эксплуатационные документы (паспорта или формуляры)',
        ],
        correctAnswers: [
          'При нормативной (регламентной) утечке или сбросе продуктов разделения воздуха в помещение (в отдельных случаях, определенных проектной документацией (документацией)) не предусматривается их удаление системой приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63645024',
        text:
          'Какие требования безопасности необходимо выполнить при остановках воздухораспределительных установок продолжительностью более 8 часов?',
        answers: [
          'Слить кубовую жидкость из адсорберов и заменить адсорбент',
          'Жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент - подвергнуть регенерации',
          'Слить жидкий кислород, а кубовую жидкость из адсорберов не сливать',
          'Жидкий кислород и кубовую жидкость из адсорберов не сливать',
        ],
        correctAnswers: [
          'Жидкий кислород и кубовую жидкость из адсорберов необходимо слить, а адсорбент - подвергнуть регенерации',
        ],
      },
      {
        code: '63645025',
        text:
          'Что должна обеспечивать система контроля и автоматики для обеспечения взрывобезопасности воздухоразделительной установки?',
        answers: [
          'Постоянный контроль количества взрывоопасных примесей в жидком кислороде',
          'Отключение установки при отклонении технологических параметров, определенных инструкцией',
          'Постоянный контроль количества сливаемого жидкого криогенного продукта',
          'Автоматический отбор жидкого кислорода или жидкого воздуха для анализа',
        ],
        correctAnswers: [
          'Постоянный контроль количества сливаемого жидкого криогенного продукта',
        ],
      },
      {
        code: '63645026',
        text:
          'Что должно исключать остаточное давление в кислородных баллонах при их разрядке?',
        answers: [
          'Возможность перетекания кислорода в системы коммуникаций, заполненные горючими газами',
          'Возможность перетекания кислорода в системы, не связанные с осуществляемым технологическим процессом',
          'Перетечку горючих газов из подключенной системы обратно в баллоны',
          'Возможность перетекания кислорода в технические устройства, заполненные горючими газами',
        ],
        correctAnswers: [
          'Перетечку горючих газов из подключенной системы обратно в баллоны',
        ],
      },
      {
        code: '63645027',
        text:
          'Работы в каких помещениях и местах для объектов производства и потребления продуктов разделения воздуха не относятся к работам повышенной опасности?',
        answers: [
          'В подвальных помещениях низкотемпературных технических устройств (воздухоразделительных установках, криогенных комплексах, хранилищах жидких продуктов разделения воздуха)',
          'Во внутриблочном пространстве и отсеках низкотемпературных технических устройств',
          'В трубопроводах, клапанах, колодцах, закрытых траншеях, приямках газгольдеров',
          'На площадках с оборудованием, расположенным вне здания, и сооружениях вблизи сбросных трубопроводов или на расстоянии 15,0 м от систем азотно-водяного охлаждения',
        ],
        correctAnswers: [
          'На площадках с оборудованием, расположенным вне здания, и сооружениях вблизи сбросных трубопроводов или на расстоянии 15,0 м от систем азотно-водяного охлаждения',
        ],
      },
      {
        code: '63645028',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63645029',
        text:
          'Как следует производить досыпку адсорбентов в адсорберы блоков комплексной очистки?',
        answers: [
          'Следует производить на работающем блоке разделения воздуха по наряду-допуску',
          'Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухораспределительной установки по наряду-допуску',
          'Следует производить на неработающем блоке разделения воздуха перед началом смены',
          'Следует производить при плановой остановке воздухоразделительной установки по наряду-допуску',
        ],
        correctAnswers: [
          'Следует производить на неработающем блоке разделения воздуха при плановой остановке воздухораспределительной установки по наряду-допуску',
        ],
      },
      {
        code: '63645030',
        text:
          'Какие требования, предъявляемые Правилами безопасности химически опасных производственных объектов к транспортным сосудам для жидких продуктов разделения воздуха при нахождении их в гараже, указаны неверно?',
        answers: [
          'Транспортные сосуды в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам',
          'Сбросные трубопроводы, подключенные к транспортным сосудам, должны выводиться на 2,0 м выше конька крыши гаража',
          'Для каждого сосуда предусматривается отдельный сброс',
          'Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража',
        ],
        correctAnswers: [
          'Транспортные сосуды для жидких продуктов разделения воздуха в течение всего времени нахождения их в гараже должны подключаться к сбросным трубопроводам, выведенным не более чем на 2,0 м выше конька крыши гаража',
        ],
      },
      {
        code: '63645031',
        text:
          'При каких условиях производится отключение мотор-генератора турбодетандера от электропитания?',
        answers: [
          'Только при закрытых отсечных клапанах',
          'Только при закрытой запорной арматуре на трубопроводе подачи газа в турбодетандер',
          'Только при закрытых отсечных клапанах и запорной арматуре на трубопроводе подачи газа в турбодетандер',
        ],
        correctAnswers: [
          'Только при закрытых отсечных клапанах и запорной арматуре на трубопроводе подачи газа в турбодетандер',
        ],
      },
      {
        code: '63645032',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63645033',
        text:
          'Что необходимо проводить в целях обеспечения эффективной очистки технологических потоков?',
        answers: [
          'Периодическую регенерацию адсорбента в соответствии с технологическим регламентом',
          'Периодическую замену адсорбента в соответствии с технологическим регламентом',
          'Периодическую досыпку адсорбента в соответствии с технологическим регламентом',
          'Периодическое просеивание адсорбента в соответствии с технологическим регламентом',
        ],
        correctAnswers: [
          'Периодическую регенерацию адсорбента в соответствии с технологическим регламентом',
        ],
      },
      {
        code: '63645034',
        text:
          'Какие технические требования необходимо обеспечивать конденсаторам-испарителям при работе воздухораспределительных установок?',
        answers: [
          'Проточность конденсаторов-испарителей в соответствии с технологическим регламентом',
          'Оптимальный расход воздуха, перерабатываемого установкой во избежание перехода конденсаторов-испарителей в режим работы без циркуляции',
          'Своевременное обслуживание в соответствии с требованиями инструкции по эксплуатации',
          'Своевременный ремонт и обслуживание для поддержания эффективности работы',
        ],
        correctAnswers: [
          'Проточность конденсаторов-испарителей в соответствии с технологическим регламентом',
        ],
      },
      {
        code: '63645035',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
          'Перила должны быть высотой не менее 1,3 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63645036',
        text:
          'При какой концентрации масла в кислороде воздухоразделительная установка должна подвергаться обезжириванию?',
        answers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,20 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм, подтвержденной тремя последовательно проведенными анализами',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
        correctAnswers: [
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
      },
      {
        code: '63645037',
        text:
          'Что не соответствует требованиям, предъявляемым к освещению в производственных и вспомогательных зданиях?',
        answers: [
          'Естественное и искусственное освещение в производственных и вспомогательных зданиях и помещениях объектов производств и потребления продуктов разделения воздуха, включая аварийное освещение основных рабочих мест, должно соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов',
          'Организациями, эксплуатирующими объекты производства и потребления продуктов разделения воздуха, должны осуществляться систематические проверки исправности сети аварийного освещения в порядке и с периодичностью, установленной эксплуатирующей организацией',
          'Не допускается загромождать световые проемы помещений',
          'Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа',
        ],
        correctAnswers: [
          'Во взрывоопасных помещениях следует предусматривать системы освещения люминесцентными лампами или лампами накаливания открытого типа',
        ],
      },
      {
        code: '63645038',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'В закрытые системы для дальнейшей утилизации',
          'На факельную установку',
          'В специальные контейнеры',
          'В централизованную систему водоотведения',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63645039',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63645040',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к отогреву трубопроводной арматуры, указаны неверно?',
        answers: [
          'Отогрев трубопроводной арматуры производится снаружи горячим воздухом',
          'Отогрев трубопроводной арматуры производится снаружи горячим паром',
          'Отогрев трубопроводной арматуры производится снаружи горячей водой',
          'Отогрев трубопроводной арматуры производится снаружи открытым пламенем',
        ],
        correctAnswers: [
          'Отогрев трубопроводной арматуры производится снаружи открытым пламенем',
        ],
      },
      {
        code: '63645041',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63645042',
        text:
          'Что следует выполнить организации, эксплуатирующей объекты производства продуктов разделения воздуха, в случае, когда в процессе эксплуатации технического устройства степень загрязнения воздуха превысит допустимую норму?',
        answers: [
          'Продолжить работу с проведением периодического мониторинга степени загрязнения перерабатываемого воздуха',
          'Следует выполнить мероприятия по оснащению такого технического устройства дополнительными средствами очистки воздуха, обеспечивающими безопасность его дальнейшей работы',
          'Остановить работу технического устройства',
          'Заменить или самостоятельно модернизировать техническое устройство',
        ],
        correctAnswers: [
          'Следует выполнить мероприятия по оснащению такого технического устройства дополнительными средствами очистки воздуха, обеспечивающими безопасность его дальнейшей работы',
        ],
      },
      {
        code: '63645043',
        text:
          'Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к оснащению помещений, в которых установлены технические устройства, предназначенные для переработки и хранения криптоноксеноновой смеси, криптона и ксенона?',
        answers: [
          'Помещения следует оснащать системой кондиционирования воздуха',
          'Помещения следует оснащать общеобменной системой вентиляции',
          'Помещения следует оснащать постоянно действующей системой приточно-вытяжной вентиляции',
          'Помещения следует оснащать местными отсосами и аварийной системой вентиляции',
        ],
        correctAnswers: [
          'Помещения следует оснащать постоянно действующей системой приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63645044',
        text:
          'Что необходимо обеспечивать при эксплуатации блоков комплексной очистки?',
        answers: [
          'Соблюдение технологической инструкции по регенерации адсорбента',
          'Соблюдение рабочих параметров процесса очистки воздуха и температурного режима регенерации и охлаждения адсорбента',
          'Соблюдение качества используемого адсорбента',
          'Контроль степени загрязнения воздуха',
        ],
        correctAnswers: [
          'Соблюдение рабочих параметров процесса очистки воздуха и температурного режима регенерации и охлаждения адсорбента',
        ],
      },
      {
        code: '63645045',
        text:
          'Какие требования безопасности к баллонам для постоянных потребителей небольших количеств продуктов разделения воздуха (газоанализаторы, хроматографы) указаны неверно?',
        answers: [
          'У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа',
          'Расстояние между каждой парой баллонов должно быть не менее 12 м на каждом уровне размещения баллонов',
          'Баллоны следует размещать в металлических шкафах и закреплять. Шкафы с баллонами должны иметь запорные устройства',
          'У каждого места потребления допускается размещать не более двух баллонов вместимостью 40 л, заполненных продуктами разделения воздуха под давлением до 20 МПа',
        ],
        correctAnswers: [
          'У каждого места потребления допускается размещать не более двух баллонов вместимостью 50 л, заполненных продуктами разделения воздуха под давлением до 25 МПа',
        ],
      },
      {
        code: '63645046',
        text:
          'Какие требования безопасности предъявляются к деталям, подвергаемым охлаждению в ваннах с жидким азотом?',
        answers: [
          'Детали необходимо предварительно вымыть и высушить',
          'Детали необходимо предварительно обезжирить и высушить',
          'Детали необходимо предварительно очистить от грязи и посторонних примесей',
          'Детали необходимо предварительно обезжирить моющим раствором и по возможности вытереть',
        ],
        correctAnswers: [
          'Детали необходимо предварительно обезжирить и высушить',
        ],
      },
      {
        code: '63645047',
        text:
          'Что не соответствует требованиям безопасности при транспортировании, перемещении, погрузке и разгрузке баллонов с продуктами разделения воздуха?',
        answers: [
          'Перемещение баллонов грузоподъемными устройствами и транспортными средствами должно осуществляться в соответствии с технологической инструкцией',
          'При погрузке и разгрузке баллонов не допускается их сбрасывание, соударение',
          'Допускается транспортирование баллона с кислородом и баллона с горючим газом на тележке к рабочему месту',
          'Допускается транспортирование и крепление баллонов за колпак',
        ],
        correctAnswers: [
          'Допускается транспортирование и крепление баллонов за колпак',
        ],
      },
      {
        code: '63645048',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать его автоматическую остановку при снижении давления газа, подаваемого в лабиринтные уплотнения?',
        answers: [
          'С давлением нагнетания 0,5 МПа',
          'С давлением нагнетания свыше 1,6 МПа',
          'С давлением нагнетания 0,6 МПа',
          'С давлением нагнетания свыше 0,6 МПа',
        ],
        correctAnswers: ['С давлением нагнетания свыше 0,6 МПа'],
      },
      {
        code: '63645049',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 50 кг',
          'Массой более 40 кг',
          'Массой более 35 кг',
          'Массой более 30 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63645050',
        text:
          'Какие устанавливаются сроки обезжиривания ВРУ, работающих по схеме высокого и среднего давлений с блоками комплексной очистки на цеолитах и турбодетандерами, а также по схеме низкого давления?',
        answers: [
          'Не регламентируются',
          'Один раз в год',
          'Один раз в полгода',
          'Один раз в квартал',
        ],
        correctAnswers: ['Не регламентируются'],
      },
      {
        code: '63645051',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63645052',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Комиссия эксплуатирующей организации',
          'Инспектор территориального органа Ростехнадзора',
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63645053',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к работе оборудования, контрольно-техническим приборам, средствам автоматизации и системам сигнализации и защиты, указаны неверно?',
        answers: [
          'Не допускается работа оборудования при неисправных системах сигнализации и защиты',
          'Работа с отключенными системами автоматического регулирования допускается только в том случае, если это предусмотрено технологическими регламентами',
          'Не допускается применение неисправных контрольно-технических приборов и средств автоматизации',
          'Допускается применение контрольно-технических приборов и средств автоматизации, не соответствующих требованиям законодательства Российской Федерации об обеспечении единства изменений, включая требования к проведению поверки и калибровки при просрочке поверки приборов не более чем на 1 месяц',
          'Не допускается работа оборудования при отключенных системах сигнализации и защиты',
        ],
        correctAnswers: [
          'Допускается применение контрольно-технических приборов и средств автоматизации, не соответствующих требованиям законодательства Российской Федерации об обеспечении единства изменений, включая требования к проведению поверки и калибровки при просрочке поверки приборов не более чем на 1 месяц',
        ],
      },
      {
        code: '63645054',
        text:
          'Какие из случаев, предусматривающих дистанционное управление арматурой технологических трубопроводов, указаны неверно?',
        answers: [
          'При включении арматуры в систему автоматического регулирования или управления',
          'Для включения резервных линий регулирования и резервных источников снабжения продуктов разделения воздуха',
          'На вводах трубопроводов кислорода и азота в здание и выходе из здания при расходах более 5000 м3/ч',
          'На трубопроводах кислорода при давлении менее 1,6 МПа и диаметром 100 мм и менее',
          'При необходимости дистанционного управления арматурой по условиям безопасности',
        ],
        correctAnswers: [
          'На трубопроводах кислорода при давлении менее 1,6 МПа и диаметром 100 мм и менее',
        ],
      },
      {
        code: '63645055',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 8 часов',
          'В течение 24 часов',
          'В течение времени, установленного проектной документацией',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63645056',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случаях изгиба болтов и шпилек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63645057',
        text:
          'Какая минимальная продолжительность продувки кислородопровода давлением 0,6 МПа и выше перед началом эксплуатации воздухом или азотом со скоростью на выходе не менее 40 м/с?',
        answers: ['2 часа', '3 часа', '1 час', '30 минут'],
        correctAnswers: ['2 часа'],
      },
      {
        code: '63645058',
        text:
          'В каком случае следует устанавливать защитные экраны для защиты персонала при возгорании арматуры?',
        answers: [
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 0,6 МПа, расположена на расстоянии менее 5,0 м от рабочих мест',
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест',
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 5,0 м от рабочих мест',
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 0,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест',
        ],
        correctAnswers: [
          'Если дистанционно управляемая запорная и регулирующая арматура, установленная на трубопроводе кислорода с давлением выше 1,6 МПа, расположена на расстоянии менее 3,0 м от рабочих мест',
        ],
      },
      {
        code: '63645059',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите 2 варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63645060',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
          'В соответствии с проектной документацией',
          'В соответствии с декларацией промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63645061',
        text:
          'Какой высоты должно быть ограждение реципиентов, расположенных вне зданий?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 1,0 м',
          'Не менее 1,2 м',
          'Не менее 2,0 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63645062',
        text:
          'Какие требования предъявляются к очистке масла воздуха, выходящего из поршневого детандера?',
        answers: [
          'Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом',
          'Очистка выполняется в основных детандерных фильтрах в соответствии с рабочей инструкцией по ведению технологического процесса очистки воздуха',
          'Очистка выполняется в основных детандерных фильтрах в соответствии с технологической инструкцией. При загрязнении контрольного фильтра должна быть проверена работа основных фильтров',
          'Очистка выполняется в детандерных фильтрах в соответствии с инструкцией по эксплуатации разработчика оборудования',
        ],
        correctAnswers: [
          'Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом',
        ],
      },
      {
        code: '63645063',
        text:
          'Какое из требований безопасности к баллонам на объектах потребления продуктов разделения воздуха, указано неверно?',
        answers: [
          'Не допускается выполнять ремонт баллонов',
          'Не допускается производить разборку вентилей',
          'Не допускается транспортирование баллона с кислородом и баллона с горючим газом на тележке к рабочему месту',
          'Не допускается снижение давления в баллоне с кислородом ниже 0,05 МПа',
        ],
        correctAnswers: [
          'Не допускается транспортирование баллона с кислородом и баллона с горючим газом на тележке к рабочему месту',
        ],
      },
      {
        code: '63645064',
        text:
          'При каких условиях допускается отключение выносных конденсаторов для планового отогрева?',
        answers: [
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение 8 часов',
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение 12 часов',
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущей смены',
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток',
        ],
        correctAnswers: [
          'При отсутствии в расположенных перед ними конденсаторах ацетилена в течение предыдущих суток',
        ],
      },
      {
        code: '63645065',
        text:
          'Как должна производиться инструментальная проверка эффективности работы вентиляционных систем?',
        answers: [
          'Не реже одного раза в полгода с оформлением акта проверки',
          'Только после каждого капитального ремонта с оформлением акта проверки',
          'Только после реконструкции вентиляционных систем с оформлением акта проверки',
          'Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации',
        ],
        correctAnswers: [
          'Не реже одного раза в год, а также после каждого капитального ремонта или реконструкции этих систем. Акты проверки утверждает технический руководитель организации',
        ],
      },
      {
        code: '63645066',
        text:
          'Какие требования предъявляются к частоте продувки влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?',
        answers: [
          'Продувка должна производиться через каждые 20 минут',
          'Продувка должна производиться через каждые 30 минут',
          'Продувка должна производиться через каждые 40 минут',
          'Продувка должна производиться через каждые 60 минут',
        ],
        correctAnswers: ['Продувка должна производиться через каждые 30 минут'],
      },
      {
        code: '63645067',
        text:
          'Какой категории обеспечения надежности электроснабжения должны быть электроприемники, обеспечивающие непрерывность технологического процесса производства на объектах производства и потребления продуктов разделения воздуха?',
        answers: [
          'К I категории надежности',
          'Ко II категории надежности',
          'К III категории надежности',
          'Требования к категории надежности не установлены',
        ],
        correctAnswers: ['К I категории надежности'],
      },
      {
        code: '63645068',
        text:
          'С какими параметрами рабочего давления и вместимости вместе с реципиентами необходимо оснащать кислородопроводы автоматически действующей системой защиты, прекращающей поступление кислорода из реципиентов в трубопровод при нарушении его целостности?',
        answers: [
          'С рабочим давлением 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3',
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3',
          'С рабочим давлением более 0,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3',
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 2000 м3',
        ],
        correctAnswers: [
          'С рабочим давлением более 1,6 МПа, вместимость которых вместе с подключенными к ним сосудами (реципиентами) превышает 200 м3',
        ],
      },
      {
        code: '63645069',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Поддержание положительной (выше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63645070',
        text:
          'Как необходимо выполнять ремонтные работы внутри трубопроводов и арматуры теплого и холодного концов регенераторов в период остановки блоков без слива жидкости?',
        answers: [
          'По устному разрешению руководителя смены',
          'Только в выходные дни по письменному разрешению руководителя организации',
          'Только по графикам в соответствии со сроками, установленными техническими условиями разработчика оборудования',
          'По наряду-допуску',
        ],
        correctAnswers: ['По наряду-допуску'],
      },
      {
        code: '63645071',
        text:
          'Как необходимо производить слив жидких криогенных продуктов из аппаратов при кратковременных остановках воздухораспределительных установок по производственной необходимости (до 8 часов)?',
        answers: [
          'В порядке, предусмотренном стандартом организации',
          'В порядке, предусмотренном отраслевым стандартом',
          'В порядке, предусмотренном типовой инструкцией',
          'В порядке, предусмотренном технологическим регламентом',
        ],
        correctAnswers: [
          'В порядке, предусмотренном технологическим регламентом',
        ],
      },
      {
        code: '63645072',
        text:
          'Какие требования безопасности предъявляются к эксплуатации криогенных турбодетандеров?',
        answers: [
          'Эксплуатация производится в соответствии с технологическим регламентом после настройки автоматической системы контроля и управления и системы противопожарной защиты',
          'Эксплуатация производится в соответствии с инструкцией по эксплуатации оборудования после регулировки системы противоаварийной защиты',
          'Эксплуатация производится в соответствии с технологической инструкцией после настройки автоматической системы управления и системы противоаварийной защиты',
          'Эксплуатация в соответствии с технологическим регламентом после настройки автоматической системы контроля и управления и системы противоаварийной защиты',
        ],
        correctAnswers: [
          'Эксплуатация в соответствии с технологическим регламентом после настройки автоматической системы контроля и управления и системы противоаварийной защиты',
        ],
      },
      {
        code: '63645073',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'В каналах (закрытых или с засыпкой песком)',
          'В грунте',
          'Надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63645074',
        text:
          'Какой высоты должно быть ограждение площадок с техническими устройствами при размещении площадок на территории, имеющей общее ограждение?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 1,2 м',
          'Не менее 1,0 м',
          'Не менее 2,0 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63645075',
        text:
          'В каком случае допускается подключение азотных компрессоров к ВРУ без газгольдеров?',
        answers: [
          'В соответствии с проектной документацией',
          'При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота',
          'Если подключение производится через обратный клапан',
          'Не допускается ни в каких случаях',
        ],
        correctAnswers: [
          'При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота',
        ],
      },
      {
        code: '63645076',
        text:
          'Какие требования предъявляются к сбросу газообразных продуктов разделения воздуха при наполнении сосудов, размещенных в помещениях?',
        answers: [
          'Сброс производится за пределы здания с соблюдением требований Правил безопасности химически опасных производственных объектов',
          'Сброс производится за пределы здания при пропускной способности трубопроводов не более 100 м3/ч',
          'Сброс производится за пределы здания при объединении трубопроводов сбросов от защитных устройств, установленных на разных технических устройствах',
          'Сброс производится за пределы здания при соблюдении объемной доли кислорода в воздухе в пределах от 17% до 24% в местах возможного нахождения обслуживающего персонала и забора воздуха для вентиляции и технологических нужд',
        ],
        correctAnswers: [
          'Сброс производится за пределы здания с соблюдением требований Правил безопасности химически опасных производственных объектов',
        ],
      },
      {
        code: '63645077',
        text:
          'В какие временные интервалы отбираются анализы на содержание кислорода в жидкости при работах с жидким азотом в стационарных ваннах?',
        answers: [
          'Должны производиться ежечасно автоматическим газоанализатором',
          'Должны производиться не реже 1 раза в смену автоматическим газоанализатором',
          'Должны производиться не реже 1 раза в сутки автоматическим газоанализатором',
          'Должны производиться непрерывно автоматическим газоанализатором',
        ],
        correctAnswers: [
          'Должны производиться непрерывно автоматическим газоанализатором',
        ],
      },
      {
        code: '63645078',
        text:
          'Какие требования к эксплуатации поршневых компрессоров указаны неверно?',
        answers: [
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
          'Для смазки поршневой группы компрессоров, подающих воздух в воздухоразделительные установки, следует использовать масла, рекомендованные разработчиками компрессора',
          'Не допускается использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей',
          'Необходимо контролировать работу маслоочищающих сальников. Попадание машинного масла в цилиндры не допускается',
          'Наличие нагара в клапанных коробках и трубопроводах поршневых компрессоров не допускается',
        ],
        correctAnswers: [
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
        ],
      },
      {
        code: '63645079',
        text:
          'С какой пропускной способностью трубопроводы сброса продуктов разделения воздуха от предохранительных клапанов и других защитных устройств необходимо выводить за пределы здания?',
        answers: [
          'С пропускной способностью 100 м3/ч',
          'С пропускной способностью более 100 м3/ч',
          'С пропускной способностью более 50 м3/ч',
          'С пропускной способностью более 10 м3/ч',
        ],
        correctAnswers: ['С пропускной способностью более 100 м3/ч'],
      },
      {
        code: '63645080',
        text:
          'Какое из перечисленных требований должно соблюдаться при обезжиривании сосудов (емкостных аппаратов) способом протирки? Выберите 2 правильных варианта ответа.',
        answers: [
          'Технический руководитель организации должен осмотреть место работы и убедиться, что сосуд отогрет и подготовлен к проведению работ',
          'Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20 °С',
          'Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, продувается инертным газом',
          'Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19% и не более 23%',
        ],
        correctAnswers: [
          'Перед проведением работ по обезжириванию сосуд, бывший в эксплуатации, отогревается до температуры не ниже +20 °С',
          'Начинать работы следует только при объемной доле кислорода в воздухе внутри сосуда не менее 19% и не более 23%',
        ],
      },
      {
        code: '63645081',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к баллонам с криптоноксеноновой смесью, криптоном и ксеноном после их наполнения, необходимо выполнить?',
        answers: [
          'Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или в складе баллонов',
          'В суточный срок произвести первичный отбор проб газа для анализа после наполнения баллонов',
          'В течение суток после наполнения баллонов осуществить их транспортировку к месту применения на тележках или другом транспорте',
          'Произвести взвешивание баллонов после их наполнения в течение суток',
        ],
        correctAnswers: [
          'Баллоны с криптоноксеноновой смесью, криптоном и ксеноном после наполнения выдержать не менее 14 суток в помещении наполнительной или в складе баллонов',
        ],
      },
      {
        code: '63645082',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к технологическому процессу очистки сырого аргона, указаны неверно?',
        answers: [
          'Установку очистки сырого аргона от кислорода необходимо оснащать блокировкой, прекращающей подачу водорода при повышении температуры в реакторе выше допустимой, а также при содержании кислорода в аргоне, поступающем на очистку, более нормативных величин',
          'В технологическом процессе должна быть предусмотрена система автоматического измерения содержания кислорода в сыром аргоне, поступающем в реактор',
          'Во время пуска установки допускается повышение объемной доли кислорода в аргоне не более 8% на входе в узел смешения реактора. При этом расход подаваемого водорода не должен превышать 2,5% от расхода поступающего на дистанционное управление. Местное управление допускается при наличии аргона',
          'Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8%',
        ],
        correctAnswers: [
          'Объемная доля водорода в техническом аргоне, поступающем в воздухоразделительные установки на очистку от азота, не должна превышать 8%',
        ],
      },
      {
        code: '63645083',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Эксплуатирующими организациями',
          'Ростехнадзором',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63645084',
        text:
          'Какие требования безопасности предъявляются на период регенерации адсорбента к эксплуатации блока разделения воздуха при наличии в воздухораспределительной установке только одного адсорбера на потоке кубовой жидкости?',
        answers: [
          'Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать не менее чем за 5 минут до начала проведения высокотемпературной регенерации адсорбента. Не допускать работу таких установок через обводную линию',
          'Блок разделения воздуха необходимо останавливать не менее чем за 10 минут до начала проведения высокотемпературной регенерации адсорбента и предварительного проведения замеров концентрации ацетилена в жидком кислороде. Не допускать работу таких установок через обводную линию',
        ],
        correctAnswers: [
          'Блок разделения воздуха необходимо останавливать. Не допускать работу таких установок через обводную линию',
        ],
      },
      {
        code: '63645085',
        text:
          'В соответствии с какими документами производится пуск объектов производства продуктов разделения воздуха (воздухоразделительных установок и криогенных комплексов)?',
        answers: [
          'В соответствии с технологическим регламентом, разработанным на основании требований технологического регламента и Правил безопасности химически опасных производственных объектов',
          'Только в соответствии с технической документацией разработчика технического устройства',
          'Только в соответствии с технологическим регламентом, разработанным на основании требований проектной документации',
        ],
        correctAnswers: [
          'В соответствии с технологическим регламентом, разработанным на основании требований технологического регламента и Правил безопасности химически опасных производственных объектов',
        ],
      },
      {
        code: '63645086',
        text:
          'При выполнении каких условий обезжиривание ВРУ в сборе после монтажа или капитального ремонта может не производиться? Выберите 2 правильных варианта ответа.',
        answers: [
          'Если все детали, узлы трубопроводов и инструмент, применявшийся при работах, были предварительно очищены от консервирующей смазки и обезжирены',
          'Если работы проводились в условиях, исключающих загрязнение внутренних поверхностей оборудования жировыми веществами',
          'Обезжиривание должно производиться в любом случае',
          'При достижении концентрации масла в жидком кислороде 0,40 мг/дм и выше, подтвержденной пятью последовательно проведенными анализами',
        ],
        correctAnswers: [
          'Если все детали, узлы трубопроводов и инструмент, применявшийся при работах, были предварительно очищены от консервирующей смазки и обезжирены',
          'Если работы проводились в условиях, исключающих загрязнение внутренних поверхностей оборудования жировыми веществами',
        ],
      },
      {
        code: '63645087',
        text:
          'Какими документами должны сопровождаться технологические трубопроводы после монтажа и ремонта?',
        answers: [
          'Документами о стоимости материалов (например, на трубы, фитинги, арматуру, опоры, сварочные материалы и др.) и работ по проведению монтажа и ремонта',
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
          'Лицензиями и другими учредительными документами организации, проводившей монтаж и ремонт',
          'Разрешениями организации, проводившей монтаж и ремонт, на проведение этих видов работ',
        ],
        correctAnswers: [
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
        ],
      },
      {
        code: '63645088',
        text: 'Когда производится ремонт пневмоприводов арматуры?',
        answers: [
          'В перерывах между сменами',
          'В любое время в случае необходимости',
          'По графику в соответствии с руководством по эксплуатации на арматуру',
          'Только во время остановок',
        ],
        correctAnswers: ['Только во время остановок'],
      },
      {
        code: '63645089',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к размещению реакторов установок очистки сырого аргона от кислорода методом каталитического гидрирования, указаны неверно?',
        answers: [
          'Реакторы установок должны быть размещены вне здания',
          'В случаях, обоснованных в проектной документации, допускается установка реактора в отдельном помещении',
          'Установка реактора в отдельном помещении не допускается',
          'Устройство, размещение и эксплуатация установок получения аргона и установок очистки сырого аргона от кислорода методом каталитического гидрирования должны соответствовать проектной документации (документации), требованиям настоящих Правил и технологическому регламенту',
        ],
        correctAnswers: [
          'Установка реактора в отдельном помещении не допускается',
        ],
      },
      {
        code: '63645090',
        text:
          'Какими автоматическими устройствами необходимо оснащать системы азотно-водяного охлаждения воздухораспределительных установок?',
        answers: [
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 10%',
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере',
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере более чем на 5%',
          'Исключающими подачу продукционного азота',
        ],
        correctAnswers: [
          'Отключающими подачу воды при повышении допустимого уровня воды в воздушном скруббере',
        ],
      },
      {
        code: '63645091',
        text:
          'Кем должны разрабатываться схемы движения транспортных средств и пешеходов по территории производства объекта производства и потребления продуктов разделения воздуха (ПРВ)?',
        answers: [
          'Органами по обеспечению безопасности дорожного движения',
          'Территориальными органами Ростехнадзора',
          'Проектной организацией',
          'Организацией, эксплуатирующей объекты производства и потребления ПРВ',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей объекты производства и потребления ПРВ',
        ],
      },
      {
        code: '63645092',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Только испытание на прочность пробным давлением',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63645093',
        text:
          'До какой величины допускается повышение объемной доли кислорода в аргоне на входе в узел смешения реактора во время пуска установки?',
        answers: [
          'Не более 2,5%',
          'Не более 8%',
          'Не более 19%',
          'Не более 23%',
        ],
        correctAnswers: ['Не более 8%'],
      },
      {
        code: '63645094',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На сети водоснабжения и канализации',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63645095',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63645096',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к управлению оборудованием, предназначенным для переработки и хранения криптоноксеноновой смеси, криптона и ксенона, указаны неверно?',
        answers: [
          'Должно быть предусмотрено дистанционное управление',
          'Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления',
          'Местное управление допускается при наличии ограждения вокруг оборудования на расстоянии 1 м от него',
          'Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м',
        ],
        correctAnswers: [
          'Местное управление допускается при наличии защитного экрана между оборудованием и щитом управления высотой не менее 1 м',
        ],
      },
      {
        code: '63645097',
        text:
          'При каком условии и с какой суммарной вместимостью допускается размещать сосуды с жидкими продуктами разделения воздуха в производственных помещениях потребителей продуктов разделения воздуха?',
        answers: [
          'Суммарной вместимостью не более 30 м3 при условии, если указанные сосуды технологически связаны с техническими устройствами, расположенными в данном производственном помещении',
          'Суммарной вместимостью не более 10 м3 при условии размещения сосудов в помещениях, отнесенных к категориям В1 - В4 по взрывопожарной и пожарной опасности',
          'Суммарной вместимостью не более 10 м3 при условии размещения сосудов в помещениях, отнесенных к категориям А, Б по взрывопожарной и пожарной опасности',
          'Суммарной вместимостью не более 10 м3 при условии, если указанные сосуды технологически связаны с техническими устройствами, расположенными в данном производственном помещении',
        ],
        correctAnswers: [
          'Суммарной вместимостью не более 10 м3 при условии, если указанные сосуды технологически связаны с техническими устройствами, расположенными в данном производственном помещении',
        ],
      },
      {
        code: '63645098',
        text:
          'Каким должно быть содержание масла в кислороде, поступающем в компрессоры и газодувки?',
        answers: [
          'Содержание масла не должно превышать 0,02 мг/м3',
          'Содержание масла не должно превышать 0,04 мг/м3',
          'Содержание масла не должно превышать 0,1 мг/м3',
          'Содержание масла не должно превышать 0,2 мг/м3',
        ],
        correctAnswers: ['Содержание масла не должно превышать 0,02 мг/м3'],
      },
      {
        code: '63645099',
        text:
          'Какие из перечисленных требований к сосудам газификаторов и другим стационарным сосудам с жидкими ПРВ, установленным снаружи зданий потребителей и в которых производится непосредственный слив жидких ПРВ из транспортных цистерн, указаны верно?',
        answers: [
          'Сосуды следует располагать около стен, не имеющих проемов на расстоянии не менее 2,0 м от габаритов сосуда',
          'Оконные проемы на расстоянии 7,0 м в каждую сторону от габаритов сосудов не должны иметь открывающихся элементов',
          'Оконные проемы на расстоянии 3,0 м вверх от габаритов сосудов могут иметь открывающиеся элементы',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63645100',
        text:
          'Как должен производиться отогрев смерзшейся изоляции для обеспечения доступа к адсорберам?',
        answers: [
          'За счет тепла, подводимого к отогреваемым аппаратам',
          'Непосредственно сухим подогретым воздухом',
          'Непосредственно подогретой водой',
          'Открытым пламенем',
        ],
        correctAnswers: ['Непосредственно сухим подогретым воздухом'],
      },
      {
        code: '63645101',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63645102',
        text:
          'Какие аппараты и коммуникации не подлежат обезжириванию в процессе эксплуатации?',
        answers: [
          'Аппараты и коммуникации на потоке жидкого кислорода',
          'Аппараты и коммуникации на потоке воздуха от поршневого детандера и от дожимающего поршневого компрессора',
          'Колонны высокого и низкого давлений',
          'Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
        correctAnswers: [
          'Средства измерений общего назначения, предназначенные для работы в кислородсодержащей среде с объемной долей кислорода до 40% и давлением до 1,6 МПа',
        ],
      },
      {
        code: '63645103',
        text:
          'Какие контрольно-измерительные приборы, применяемые для измерения параметров кислорода и газовых смесей, подлежат защите от жировых загрязнений?',
        answers: [
          'С объемной долей кислорода менее 19%',
          'С объемной долей кислорода 19%',
          'С объемной долей кислорода 23%',
          'С объемной долей кислорода более 23%',
        ],
        correctAnswers: ['С объемной долей кислорода более 23%'],
      },
      {
        code: '63645104',
        text:
          'В каких системах кислородоснабжения операции по снижению и поддержанию давления кислорода, регулированию его расхода следует производить в кислородно-распределительных (регуляторных) пунктах?',
        answers: [
          'С расходами более 2000 м3/ч и давлением свыше 0,6 МПа',
          'С расходами свыше 4000 м3/ч и давлением от 0,4 МПа до 2,0 МПа',
          'С расходами свыше 5000 м3/ч и давлением от 0,2 МПа до 4,0 МПа',
          'С расходами свыше 6000 м3/ч и давлением от 0,6 МПа до 4,0 МПа',
        ],
        correctAnswers: [
          'С расходами свыше 6000 м3/ч и давлением от 0,6 МПа до 4,0 МПа',
        ],
      },
      {
        code: '63645105',
        text:
          'Какое из перечисленных требований к оборудованию и коммуникациям продуктов разделения воздуха указано неверно?',
        answers: [
          'Хранение, газификация и транспортирование жидких (криогенных) ПРВ необходимо производить только в технических устройствах, специально предназначенных для данного продукта',
          'Все сосуды, заполняемые жидкими криогенными продуктами разделения воздуха, необходимо оснащать указателями уровня заполнения',
          'На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп',
          'Холодные участки низкотемпературного оборудования и коммуникаций, находящиеся в зоне обслуживания, подлежат изоляции или ограждению',
        ],
        correctAnswers: [
          'На участки трубопроводов жидких продуктов разделения воздуха, заключенные между двумя отключающими органами, должен быть нанесен штамп',
        ],
      },
      {
        code: '63645106',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите 2 варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63645107',
        text:
          'При какой температуре кислорода после регулятора давления срабатывает блокировка, прекращающая подачу кислорода для линии регулирования кислородно-распределительных (регуляторных) пунктов?',
        answers: [
          'Свыше плюс 70 °С',
          'Свыше плюс 80 °С',
          'Свыше плюс 90 °С',
          'Свыше плюс 100 °С',
        ],
        correctAnswers: ['Свыше плюс 100 °С'],
      },
      {
        code: '63645108',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к адсорбенту в адсорбционных блоках осушки, указаны неверно?',
        answers: [
          'Если адсорбент загрязнен (замаслен или пожелтел), его необходимо заменить',
          'Состояние адсорбента требуется проверять не реже одного раза в год',
          'В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °С), замену адсорбента в блоке осушки производить два раза в год согласно технологическому регламенту',
          'В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °С), замену адсорбента в блоке осушки производить один раз в год согласно технологической инструкции',
        ],
        correctAnswers: [
          'В установках, в которых температура сжатия воздуха в любой ступени компрессора выше 433 °К (160 °С), замену адсорбента в блоке осушки производить один раз в год согласно технологической инструкции',
        ],
      },
      {
        code: '63645109',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'В случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Для обеспечения энергоэффективности',
          'Для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63645110',
        text:
          'При какой остановке эксплуатации кислородопровод давлением 0,6 МПа и выше должен быть продут воздухом или азотом со скоростью на выходе не менее 40 м/с?',
        answers: [
          'Не регламентируется',
          'Более двух недель',
          'Более недели',
          'Более месяца',
        ],
        correctAnswers: ['Более месяца'],
      },
      {
        code: '63645111',
        text:
          'Каким документом определяется продолжительность эксплуатации воздухораспределительной установки между двумя полными отогревами установки?',
        answers: [
          'Техническим регламентом Таможенного союза',
          'Технологическим регламентом',
          'Правилами промышленной безопасности',
          'Заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Технологическим регламентом'],
      },
      {
        code: '63645112',
        text:
          'Какую объемную долю кислорода в воздухе в местах возможного нахождения обслуживающего персонала и забора воздуха для вентиляции и технологических нужд должны обеспечить конструкция и размещение устройств сброса в атмосферу азота и кислорода?',
        answers: [
          'В пределах от 19% до 23%',
          'Не менее 18%',
          'Не более 24%',
          'Не менее 17% и не более  22%',
        ],
        correctAnswers: ['В пределах от 19% до 23%'],
      },
      {
        code: '63645113',
        text:
          'Допускается ли сброс продуктов разделения воздуха в производственное помещение при продувке технических устройств и коммуникаций?',
        answers: [
          'Допускается в помещении с постоянно действующей приточно-вытяжной вентиляцией',
          'Допускается в помещении с работающей вентиляцией, обеспечивающей объемную долю кислорода в воздухе помещений не менее 19% и не более 23%',
          'Допускается в помещении с естественной вентиляцией, если объем помещения в кубических метрах превышает объем сброса продуктов разделения воздуха в литрах не менее чем в 7 раз',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63645114',
        text:
          'В каком случае замена адсорбента должна производиться немедленно?',
        answers: [
          'В случаях, определенных документацией разработчика оборудования',
          'Если необходимо произвести пересеивание адсорбента',
          'Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм',
          'Если адсорбент загрязнен (замаслен)',
        ],
        correctAnswers: [
          'Если при нормальном режиме регенерации и соблюдении рабочих параметров процесса очистки наблюдается фиксация превышения концентрации двуокиси углерода сверх допустимых норм',
        ],
      },
      {
        code: '63645115',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите 2 варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63645116',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 24 часов',
          'Не менее 12 часов',
          'Не менее 8 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63645117',
        text: 'В какие сроки производится замена адсорбента?',
        answers: [
          'В сроки, установленные отраслевым стандартом',
          'В сроки, установленные технологическим регламентом',
          'В сроки, установленные технической документацией разработчика установки',
          'В сроки, установленные технологической инструкцией',
        ],
        correctAnswers: ['В сроки, установленные технологическим регламентом'],
      },
      {
        code: '63645118',
        text:
          'При каких условиях жидкий азот в качестве хладоагента может применяться без специальных мер по предупреждению загорания и взрыва?',
        answers: [
          'Если объемная доля кислорода в жидком азоте 32%',
          'Если объемная доля кислорода в жидком азоте не превышает 33%',
          'Если объемная доля кислорода в жидком азоте 31%',
          'Если объемная доля кислорода в жидком азоте не превышает 30%',
        ],
        correctAnswers: [
          'Если объемная доля кислорода в жидком азоте не превышает 30%',
        ],
      },
      {
        code: '63645119',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Иметь документы о прохождении аттестации',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Всем перечисленным требованиям',
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63645120',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'Если это определено и обосновано разработчиком проекта',
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
          'Если это определено и обосновано научно-исследовательской организацией',
          'По решению территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63645121',
        text:
          'Какому уровню заполнения соответствует показание дистанционного указателя степени заполнения газгольдера с сигнализацией по уровню заполнения 20% полного объема?',
        answers: [
          'Минимальному',
          'Предминимальному',
          'Предмаксимальному',
          'Максимальному',
        ],
        correctAnswers: ['Предминимальному'],
      },
      {
        code: '63645122',
        text:
          'Какое из перечисленных требований к газгольдерам и реципиентам ПРВ указано неверно?',
        answers: [
          'Внутренняя поверхность мокрых газгольдеров постоянного давления не окрашивается красками',
          'На отдельном сосуде-реципиенте или на группе сосудов реципиента, отключаемых одним запорным устройством, следует предусматривать арматуру для сброса газа в атмосферу',
          'Наружная поверхность стальных газгольдеров и реципиентов, расположенных вне здания, окрашивается в целях снижения нагреваемости их солнечными лучами',
          'Водяные затворы сливных баков газгольдеров должны быть постоянно заполнены водой',
        ],
        correctAnswers: [
          'Внутренняя поверхность мокрых газгольдеров постоянного давления не окрашивается красками',
        ],
      },
      {
        code: '63645123',
        text:
          'Какие требования безопасности, установленные Правилами безопасности химически опасных производственных объектов к проведению ремонта в отсеках клапанных коробок (обратноповоротных клапанов) регенераторов блока разделения воздуха, указаны неверно?',
        answers: [
          'Вход в отсеки для ремонта клапанов разрешается только после снятия давления из аппаратов блока',
          'Вход в отсеки для ремонта клапанов разрешается только после местного отогрева отсеков клапанных коробок (обратноповоротных клапанов)',
          'Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску',
          'Объемная доля кислорода внутри кожуха блока разделения воздуха должна быть 19% - 23%',
        ],
        correctAnswers: [
          'Вход в отсеки для ремонта клапанов разрешается без сброса избыточного давления из аппаратов блока по наряду-допуску',
        ],
      },
      {
        code: '63645124',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Порошкообразные теплоизоляционные материалы',
          'Допускаются все перечисленные материалы',
          'Базальтовое супертонкое волокно',
          'Вату из непрерывного стеклянного волокна',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63645125',
        text:
          'Какие требования предъявляются к работе адсорбера воздухораспределительной установки?',
        answers: [
          'Адсорберы должны заполняться только адсорбентом, прошедшим периодическую регенерацию в соответствии с технологической инструкцией',
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технологической инструкцией',
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки',
          'Адсорберы должны заполняться любым адсорбентом, имеющимся в наличии',
        ],
        correctAnswers: [
          'Адсорберы должны заполняться только адсорбентом, предусмотренным технической документацией разработчика воздухораспределительной установки',
        ],
      },
      {
        code: '63645126',
        text:
          'Какие требования безопасности, установленные Правилами безопасности химически опасных производственных объектов к работам с перлитовым песком, указаны неверно?',
        answers: [
          'Ремонтные работы в отсеках технических устройств, заполняемых перлитовым песком, а также в перлитохранилищах должны производиться после полного удаления перлитового песка',
          'Допускается проведение ремонтных работ без полного удаления перлитового песка при наличии соответствующих обоснований в проектной документации',
          'Допускается выгрузка перлитового песка из отсеков блока разделения воздуха в открытые сосуды или в помещение машинного зала',
          'Перегрузка перлитового песка из хранилищ в блоки разделения воздуха и обратно должна осуществляться с использованием системы пневмотранспорта, предусмотренной проектом',
        ],
        correctAnswers: [
          'Допускается выгрузка перлитового песка из отсеков блока разделения воздуха в открытые сосуды или в помещение машинного зала',
        ],
      },
      {
        code: '63645127',
        text:
          'Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке оборудования?',
        answers: [
          'Наличие средств гидравлической, механической или химической очистки',
          'Наличие оросительных систем',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Возможность изоляции соседнего оборудования',
        ],
        correctAnswers: [
          'Наличие средств гидравлической, механической или химической очистки',
        ],
      },
      {
        code: '63645128',
        text:
          'При какой единичной производительности центробежных или поршневых компрессоров, работающих на один коллектор нагнетания кислорода, и давлении кислорода в трубопроводе нагнетания кислорода устанавливают отключающую арматуру?',
        answers: [
          'Более 2500 м3/ч и свыше 2,6 МПа соответственно',
          'Более 2200 м3/ч и свыше 2 МПа соответственно',
          'Более 2100 м3/ч и свыше 1,8 МПа соответственно',
          'Более 2000 м3/ч и свыше 1,6 МПа соответственно',
        ],
        correctAnswers: ['Более 2000 м3/ч и свыше 1,6 МПа соответственно'],
      },
      {
        code: '63645129',
        text:
          'В каком случае допускается эксплуатация воздухоразделительных технических устройств, применяемых на опасных производственных объектах производств продуктов разделения воздуха?',
        answers: [
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают предельно допустимой нормы',
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства',
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной технологическим процессом',
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают 10% нормы, установленной разработчиком такого технического устройства',
        ],
        correctAnswers: [
          'Если концентрация и состав взрывоопасных примесей в перерабатываемом воздухе не превышают нормы, установленной разработчиком такого технического устройства',
        ],
      },
      {
        code: '63645130',
        text:
          'Кем должен быть утвержден перечень помещений и мест, в которых содержание кислорода по объемной доле может быть менее 19% или более 23% (в аварийной ситуации)?',
        answers: [
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается только первым руководителем организации',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается региональным отделением Ростехнадзора',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации',
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается территориальным отделом Роспотребнадзора',
        ],
        correctAnswers: [
          'Перечень с указанием видов и периодичности контроля и мер по нормализации состава воздуха утверждается техническим руководителем организации',
        ],
      },
      {
        code: '63645131',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63645132',
        text:
          'Что должна обеспечивать система контроля и автоматизации воздухораспределительной установки при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом?',
        answers: [
          'Звуковую сигнализацию при отклонении технологических параметров, определенных технологическим регламентом',
          'Автоматическое отключение устройств установки при отклонении технологических параметров, определенных технологическим регламентом',
          'Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом',
          'Включение резервных (дополнительных) модернизированных устройств, обеспечивающих безопасность дальнейшей работы систем и устройств',
        ],
        correctAnswers: [
          'Световую и звуковую сигнализации при нарушении работы систем и устройств установки и отклонении технологических параметров, определенных технологическим регламентом',
        ],
      },
      {
        code: '63645133',
        text:
          'Какими автоматизированными устройствами необходимо оснащать воздухораспределительные установки, вырабатывающие газообразный азот?',
        answers: [
          'Устройствами, не допускающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией) более чем на 10%',
          'Устройствами, которые должны обеспечивать световую и звуковую сигнализации при подаче продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)',
          'Автоматизированными устройствами, допускающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией), не более чем на 10%',
          'Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)',
        ],
        correctAnswers: [
          'Автоматизированными устройствами, исключающими подачу продукционного азота с содержанием кислорода, превышающим величины, определенные проектной документацией (документацией)',
        ],
      },
      {
        code: '63645134',
        text:
          'В каком случае допускаются отклонения от действующего проекта, на основании которого проводятся работы, связанные с восстановлением работоспособного (исправного) состояния технологических трубопроводов?',
        answers: [
          'По решению комиссии эксплуатирующей организации',
          'При наличии письменного распоряжения технического руководителя эксплуатирующей организации',
          'В случае согласования необходимого отклонения с территориальным органом Ростехнадзора',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: ['Не допускаются ни в каком случае'],
      },
      {
        code: '63645135',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63645136',
        text:
          'Какие требования к стационарным обводным линиям предусматриваются Правилами безопасности химически опасных производственных объектов?',
        answers: [
          'Должны размещаться на расстоянии не менее 10 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки',
          'Должны размещаться на расстоянии не менее 50 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки',
          'Должны размещаться на расстоянии не менее 100 м от помещений кислородно-распределительных (регуляторных) пунктов и иметь съемные патрубки и заглушки',
          'Должны иметь съемные патрубки и заглушки, при этом данные обводные линии следует размещать за пределами помещений кислородно-распределительных (регуляторных) пунктов',
        ],
        correctAnswers: [
          'Должны иметь съемные патрубки и заглушки, при этом данные обводные линии следует размещать за пределами помещений кислородно-распределительных (регуляторных) пунктов',
        ],
      },
      {
        code: '63645137',
        text:
          'Требованиям каких документов должны соответствовать устройство и размещение оборудования с жидкими продуктами разделения воздуха?',
        answers: [
          'Должны соответствовать проектной документации, технической документации организации-изготовителя и требованиям правил пожарной безопасности и всех федеральных норм и правил в области промышленной безопасности',
          'Должны соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов',
          'Должны соответствовать инструкции по проектированию производства продуктов разделения воздуха',
          'Должны соответствовать проекту и требованиям Правил безопасности химически опасных производственных объектов',
        ],
        correctAnswers: [
          'Должны соответствовать проектной документации (документации) и требованиям Правил безопасности химически опасных производственных объектов',
        ],
      },
      {
        code: '63645138',
        text:
          'Может ли быть продолжена работа воздухораспределительной установки с перлитовой изоляцией при обнаружении утечки газообразных или жидких продуктов разделения воздуха во внутриблочном пространстве установки?',
        answers: [
          'Работа установки может быть продолжена при небольших утечках, когда исключается опасность для обслуживающего персонала или возможность возникновения инцидентов и аварий с разрешения технического руководителя при условии разработки мероприятий, обеспечивающих безопасность персонала, контроля за содержанием кислорода в воздухе рабочей зоны и наличии в местах повышенной опасности знаков безопасности',
          'Работа установки может быть продолжена при немедленной замене прокладок в месте утечки',
          'Работа установки может быть продолжена только при незначительной утечке и немедленного установления мест утечек газообразных или жидких продуктов проверкой открытым пламенем (зажигалкой) или тлеющим предметом (спичкой)',
          'Работа установки должна быть прекращена для устранения утечки',
        ],
        correctAnswers: [
          'Работа установки должна быть прекращена для устранения утечки',
        ],
      },
      {
        code: '63645139',
        text:
          'С какой периодичностью необходимо промывать витые конденсаторы-испарители с внутритрубным кипением кислорода в установках, не производящих криптоновый концентрат?',
        answers: [
          'Не реже одного раза в сутки',
          'По мере необходимости, но не реже одного раза в смену',
          'Не реже одного раза в неделю',
          'С периодичностью, установленной технологическим регламентом',
        ],
        correctAnswers: [
          'С периодичностью, установленной технологическим регламентом',
        ],
      },
      {
        code: '63645140',
        text:
          'Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к ограждению и габаритам трапов ливневой канализации, приямков и подвалов, расположенных за пределами площадок с сосудами и сливоналивными устройствами жидких продуктов разделения воздуха на расстоянии менее 10,0 м?',
        answers: [
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,1 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м',
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 0,1 м',
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м',
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,1 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 0,5 м',
        ],
        correctAnswers: [
          'Должны иметь бетонное ограждение (порог) высотой не менее 0,2 м со стороны, обращенной к площадке, и выступать за габариты ограждаемых объектов не менее чем на 1,0 м',
        ],
      },
      {
        code: '63645141',
        text:
          'Для каких реципиентов в месте их подключения к межцеховым кислородопроводам необходимо устанавливать отключающие задвижки с дистанционным управлением?',
        answers: [
          'Вместимостью более 10 м3 с рабочим давлением более 1,3 МПа',
          'Вместимостью более 20 м3 с рабочим давлением более 1,5 МПа',
          'Вместимостью 200 м3 с рабочим давлением 1,6 МПа',
          'Вместимостью более 200 м3 с рабочим давлением более 1,6 МПа',
        ],
        correctAnswers: [
          'Вместимостью более 200 м3 с рабочим давлением более 1,6 МПа',
        ],
      },
      {
        code: '63645142',
        text:
          'Какую объемную долю кислорода в воздухе должна обеспечивать постоянно действующая приточно-вытяжная вентиляция в помещениях, объем которых в метрах кубических превышает объем жидкости, находящейся в сосудах, в литрах менее чем в 7 раз?',
        answers: [
          'Не менее 20% и не более 24%',
          'Не менее 19% и не более 23%',
          'Не менее 15% и не более 19%',
          'Не менее 23% и не более 27%',
        ],
        correctAnswers: ['Не менее 19% и не более 23%'],
      },
      {
        code: '63645143',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'После проведения расчета на прочность',
          'Не допускается ни в каком случае',
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63645144',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
          'Со средой 1-ой группы',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63645145',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Проектной организацией',
          'Технической комиссией эксплуатирующей организации',
          'Монтажной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63645146',
        text:
          'Какое должно быть расстояние между помещениями соседних кислородно-распределительных (регуляторных) пунктов?',
        answers: [
          'Не менее 3,0 м',
          'Не менее 5,0 м',
          'Не менее 6,0 м',
          'Не менее 10,0 м',
        ],
        correctAnswers: ['Не менее 6,0 м'],
      },
      {
        code: '63645147',
        text:
          'Какие сосуды допускается размещать в производственных помещениях потребителей ПРВ при условии, если сосуды технологически связаны с техническими устройствами, расположенными в данном производственном помещении?',
        answers: [
          'С жидкими ПРВ суммарной вместимостью не более 10 м³',
          'С жидкими ПРВ суммарной вместимостью не более 15 м³',
          'С жидкими ПРВ суммарной вместимостью не более 25 м³',
          'С жидкими ПРВ суммарной вместимостью не более 20 м³',
        ],
        correctAnswers: ['С жидкими ПРВ суммарной вместимостью не более 10 м³'],
      },
      {
        code: '63645148',
        text:
          'Какие требования к сетям водопроводов и канализации и устройствам, подключаемым к сетям, не соответствуют требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Соединение сетей хозяйственно-питьевого водопровода с сетями водопроводов, подающих воду технического качества, не допускается',
          'В производственных помещениях, где возможно повышенное содержание кислорода, которое может привести к воспламенению одежды или наличие опасных веществ, которые могут вызвать химические ожоги, должны устанавливаться фонтанчики, краны, раковины или ванны самопомощи, аварийные души',
          'Устройства (фонтанчики, краны, раковины или ванны самопомощи, аварийные души) должны располагаться в легкодоступных местах и подключаться к хозяйственно-питьевому водопроводу',
          'Осмотр и очистка канализационных сетей и колодцев производится по графикам и в соответствии с порядком проведения газоопасных работ',
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
        ],
        correctAnswers: [
          'В случае крайней необходимости допускается располагать устройства, такие как фонтанчики, краны, раковины или ванны самопомощи, аварийные души в помещениях, в которых обращаются или хранятся вещества, которые при контакте с водой разлагаются с взрывом или воспламеняются, а также выделяют взрывоопасные или токсичные газы',
        ],
      },
      {
        code: '63645149',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Рпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Рпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63645150',
        text:
          'В каких случаях работу насосов жидких продуктов разделения воздуха требуется остановить для ремонта?',
        answers: [
          'В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 23 % или уменьшилась до 19%',
          'Только в случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа',
          'В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 22% или уменьшилась до 19%',
          'Только при повышении объемной доли кислорода в помещении размещения насосов до 23% или уменьшении до 20%',
        ],
        correctAnswers: [
          'В случае появления обмерзания стояночных и динамических уплотнителей и сальников насосов при пропуске газа и если объемная доля кислорода в помещении размещения насосов повысилась до 23 % или уменьшилась до 19%',
        ],
      },
      {
        code: '63645151',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к установкам очистки сырого аргона от кислорода и водородным коммуникациям перед подачей водорода в них и после остановки, указаны неверно?',
        answers: [
          'Установки и водородные коммуникации необходимо продувать сырым аргоном или азотом с объемной долей кислорода не более 8%. Окончание продувки должно быть определено анализом состава продувочного газа',
          'Установки необходимо оснащать блокировкой, прекращающей подачу водорода при повышении температуры в реакторе выше допустимой, а также при содержании кислорода в аргоне, поступающем на очистку, более нормативных величин, определенных технологическим регламентом',
          'Объемная доля кислорода в продувочном газе (перед пуском) не должна превышать 4%, а водород (после остановки) должен отсутствовать',
          'Установки и водородные коммуникации необходимо продувать сырым аргоном или азотом с объемной долей кислорода не более 4%. Окончание продувки должно быть определено анализом состава продувочного газа',
        ],
        correctAnswers: [
          'Установки и водородные коммуникации необходимо продувать сырым аргоном или азотом с объемной долей кислорода не более 8%. Окончание продувки должно быть определено анализом состава продувочного газа',
        ],
      },
      {
        code: '63645152',
        text:
          'В каком случае допускается объединение трубопроводов для слива жидких продуктов из воздухоразделительных установок?',
        answers: [
          'Не допускается ни в каком случае',
          'Если воздухоразделительные установки малой производительности',
          'Если получено такое заключение от территориального органа Ростехнадзора в каждом конкретном случае',
          'Если это обосновывается в проектной документации (документации) в каждом конкретном случае',
        ],
        correctAnswers: [
          'Если это обосновывается в проектной документации (документации) в каждом конкретном случае',
        ],
      },
      {
        code: '63645153',
        text:
          'Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?',
        answers: [
          'Сторонней специализированной организацией',
          'Технологическим регламентом, технологическими инструкциями',
          'Правилами безопасности химически опасных производственных объектов',
          'Техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технологическим регламентом, технологическими инструкциями',
        ],
      },
      {
        code: '63645154',
        text:
          'Куда производится сброс первичного криптонового концентрата и криптоноксеноновой смеси?',
        answers: [
          'Только в газгольдер первичного криптонового концентрата',
          'В хранилище жидкого первичного концентрата',
          'В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)',
          'В специальный резервуар транспортной системы',
        ],
        correctAnswers: [
          'В газгольдер первичного криптонового концентрата либо на всас компрессора (если газгольдер отсутствует в схеме)',
        ],
      },
      {
        code: '63645155',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Осуществляется все перечисленное',
          'Испытание трубопровода на прочность и плотность',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63645156',
        text:
          'Какие требования предъявляются к объемной доле кислорода в жидкости при осуществлении работы с жидким азотом в открытых ваннах при проведении замеров в паровой фазе над зеркалом жидкости?',
        answers: [
          'Объемная доля кислорода не должна превышать 10%',
          'Объемная доля кислорода не должна превышать 19%',
          'Объемная доля кислорода не должна превышать 23%',
          'Объемная доля кислорода не должна превышать 30%',
        ],
        correctAnswers: ['Объемная доля кислорода не должна превышать 10%'],
      },
      {
        code: '63645157',
        text:
          'Какие требования устанавливаются Правилами безопасности химически опасных производственных объектов к хранению баллонов в помещении хроматографии?',
        answers: [
          'Не допускается хранить более двух баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 20 МПа',
          'Не допускается хранить более трех баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 20 л, а давление - более 40 МПа',
          'Не допускается хранить более двух баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 40 МПа',
          'Не допускается хранить более четырех баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 20 МПа',
        ],
        correctAnswers: [
          'Не допускается хранить более двух баллонов с эталонными и поверочными газовыми смесями. Вместимость каждого баллона не должна быть более 40 л, а давление - более 20 МПа',
        ],
      },
      {
        code: '63645158',
        text:
          'Какие требования безопасности предъявляются к пуску воздухораспределительной установки при уровне жидкого кислорода (жидкого воздуха) в основных конденсаторах-испарителях меньше номинального?',
        answers: [
          'Пуск воздухораспределительной установки должен осуществляться в рабочем режиме',
          'Пуск воздухораспределительной установки должен осуществляться в режиме накопления жидкости',
          'Пуск воздухораспределительной установки должен осуществляться в соответствии с инструкцией по эксплуатации установки',
          'Пуск воздухораспределительной установки должен осуществляться в соответствии с картой технологического процесса',
        ],
        correctAnswers: [
          'Пуск воздухораспределительной установки должен осуществляться в режиме накопления жидкости',
        ],
      },
      {
        code: '63645159',
        text:
          'Какая концентрация взрывоопасных примесей допускается в жидком кислороде?',
        answers: [
          'Не превышающая нормы, установленной технологическим процессом',
          'Не превышающая предельно допустимой нормы',
          'Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса',
          'Не превышающая 19% нормы, установленной разработчиком воздухоразделительной установки',
        ],
        correctAnswers: [
          'Не превышающая пределов, установленных разработчиком воздухоразделительной установки для различных стадий технологического процесса',
        ],
      },
      {
        code: '63645160',
        text:
          'Какие требования безопасности предъявляются к эксплуатации турбодетандера при обмерзании изоляционного кожуха и привода механизма регулирования производительности?',
        answers: [
          'Не допускается эксплуатация турбодетандера при появлении обмерзания изоляционного кожуха и привода механизма регулирования производительности',
          'Не допускается эксплуатация турбодетандера при обнаружении обмерзания изоляционного кожуха и привода механизма регулирования производительности',
          'Не допускается эксплуатация турбодетандера при росте обмерзания изоляционного кожуха и привода механизма регулирования производительности',
          'Не допускается эксплуатация турбодетандера при появлении прогрессирующего обмерзания изоляционного кожуха, фундамента или конструкций, изготовленных из черных металлов',
        ],
        correctAnswers: [
          'Не допускается эксплуатация турбодетандера при росте обмерзания изоляционного кожуха и привода механизма регулирования производительности',
        ],
      },
      {
        code: '63645161',
        text:
          'На каком расстоянии необходимо устанавливать отключающую задвижку с дистанционным управлением на каждом кислородопроводе перед КРП?',
        answers: [
          'Не менее 10,0 м и не более 50,0 м',
          'Не менее 5,0 м и не более 40,0 м',
          'Не менее 20,0 м и не более 60,0 м',
          'Не менее 15,0 м и не более 55,0 м',
        ],
        correctAnswers: ['Не менее 10,0 м и не более 50,0 м'],
      },
      {
        code: '63645162',
        text:
          'Где должно регистрироваться отключение сигнализации или блокировки для ревизии аппаратуры и калибровки газоанализаторов?',
        answers: [
          'В журнале приема и сдачи смены',
          'В наряде-допуске',
          'В журнале ремонта оборудования',
          'В технологическом журнале',
        ],
        correctAnswers: ['В технологическом журнале'],
      },
      {
        code: '63645163',
        text:
          'Как производится включение компрессора, отключенного из-за прекращения подачи охлаждающей воды?',
        answers: [
          'Только после его охлаждения',
          'Только после возобновления подачи воды',
          'Только после его охлаждения и возобновления подачи воды',
          'Только после проведения отбора проб для анализа газа и последующего возобновления подачи воды',
        ],
        correctAnswers: [
          'Только после его охлаждения и возобновления подачи воды',
        ],
      },
      {
        code: '63645164',
        text:
          'Какие криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими продуктами разделения воздуха, следует оснащать предохранительными устройствами?',
        answers: [
          'Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ, кроме сосудов вместимостью до 15 л',
          'Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими продуктами разделения воздуха вместимостью 14 л',
          'Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими продуктами разделения воздуха, кроме сосудов вместимостью до 17 л',
          'Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими продуктами разделения воздуха вместимостью 13 л',
        ],
        correctAnswers: [
          'Криогенные сосуды (сосуды Дьюара), предназначенные для хранения или работы с жидкими ПРВ, кроме сосудов вместимостью до 15 л',
        ],
      },
      {
        code: '63645165',
        text:
          'При достижении какого уровня заполнения газгольдера автоматически производится отключение компрессоров (газодувок), подключенных к газгольдеру?',
        answers: [
          'Максимального',
          'Предмаксимального',
          'Предминимального',
          'Минимального',
        ],
        correctAnswers: ['Минимального'],
      },
      {
        code: '63645166',
        text:
          'Что должно быть предусмотрено на производстве продуктов разделения воздуха для безопасного слива и испарения небольшого количества жидких продуктов разделения воздуха после выполнения анализов жидких продуктов разделения воздуха и других работ?',
        answers: [
          'Только ограждение места слива жидких продуктов разделения воздуха',
          'Предусмотрено устройство для безопасного слива и испарения небольшого количества жидких продуктов разделения воздуха (до 10 литров) из переносных сосудов при отогревах воздухоразделительной установки малой производительности (30 - 300 м3/ч азота или кислорода при нормальных условиях)',
          'Предусмотрен трубопровод слива жидких продуктов разделения воздуха непосредственно в лаборатории с выводом вне помещения',
          'Предусмотрены безопасные дренажные коммуникации, обеспечивающие слив продуктов после выполнения анализов жидких продуктов разделения воздуха и других работ',
        ],
        correctAnswers: [
          'Предусмотрено устройство для безопасного слива и испарения небольшого количества жидких продуктов разделения воздуха (до 10 литров) из переносных сосудов при отогревах воздухоразделительной установки малой производительности (30 - 300 м3/ч азота или кислорода при нормальных условиях)',
        ],
      },
      {
        code: '63645167',
        text:
          'Какое содержание масел в воде, используемой для гидроиспытаний кислородопроводов, установлено Правилами безопасности химически опасных производственных объектов?',
        answers: [
          'Не должно превышать 15,0 мг/л',
          'Не должно превышать 10,0 мг/л',
          'Не должно превышать 5,0 мг/л',
          'Не должно превышать 0,5 мг/л',
        ],
        correctAnswers: ['Не должно превышать 5,0 мг/л'],
      },
      {
        code: '63645168',
        text:
          'На каких кислородопроводах перед кислородно-распределительными (регуляторными) пунктами необходимо устанавливать фильтры?',
        answers: [
          'Работающих под давлением более 1,2 МПа',
          'Работающих под давлением более 1,4 МПа',
          'Работающих под давлением более 1,5 МПа',
          'Работающих под давлением более 1,6 МПа',
        ],
        correctAnswers: ['Работающих под давлением более 1,6 МПа'],
      },
      {
        code: '63645169',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63645170',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 3 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63645171',
        text:
          'Какой отличительный знак должен быть у инструмента, предназначенного для разборки и ремонта узлов, работающих в кислородной среде?',
        answers: [
          'Инструмент должен иметь маркировку на рукоятке в виде голубого треугольника',
          'Инструмент должен иметь отличительную желтую полосу',
          'Инструмент должен иметь отличительную голубую полосу',
          'Инструмент должен иметь отличительную белую полосу',
        ],
        correctAnswers: [
          'Инструмент должен иметь отличительную голубую полосу',
        ],
      },
      {
        code: '63645172',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63645173',
        text:
          'Какие требования безопасности необходимо предусматривать в помещениях при наполнении баллонов?',
        answers: [
          'Должен вестись автоматический контроль воздушной среды',
          'В среде кислорода не допускается использование прокладочных и уплотнительных материалов органического происхождения',
          'Объемная доля кислорода в воздухе помещений наполнения баллонов должна быть не менее 19% и не более 23%',
          'Между наполнительной и помещением, из которого производится управление оборудованием (компрессоры, газификаторы, жидкостные насосы воздухоразделительных установок), подающим сжатые продукты разделения воздуха на наполнение баллонов, необходимо предусматривать двухстороннюю связь',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63645174',
        text:
          'Что не соответствует установленным требованиям безопасности при обслуживании компрессора?',
        answers: [
          'Во время работы воздушного центробежного компрессора и воздушного фильтра вход обслуживающего персонала в помещение камеры после воздушного фильтра (камеры чистого воздуха) не допускается',
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть закрыты',
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть снабжены знаками безопасности, запрещающими вход в камеры',
        ],
        correctAnswers: [
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
        ],
      },
      {
        code: '63645175',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Расчет на прочность',
          'Схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63645176',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'П-образные компенсаторы',
          'Г-образные компенсаторы',
          'Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63645177',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '24 месяца',
          '3 месяца',
          '36 месяцев',
          '12 месяцев',
          '6 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63645178',
        text:
          'Каким документом определяется порядок отключения электроприводов арматуры на ремонт или ревизию на работающей воздухораспределительной установке?',
        answers: [
          'Технологической инструкцией с учетом технической документации разработчика устройства',
          'Технологическим регламентом',
          'Инструкцией по эксплуатации электрооборудования, инструкцией по проведению ремонта оборудования и руководством по эксплуатации на арматуру',
          'Локальной инструкцией по организации с учетом технологического процесса и руководством по эксплуатации на арматуру',
        ],
        correctAnswers: ['Технологическим регламентом'],
      },
      {
        code: '63645179',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов, которые должны соблюдаться при обоснованной необходимости проведения обезжиривания растворителями, указаны неверно?',
        answers: [
          'Перелив растворителей из одного сосуда в другой допускается только закрытым способом при наличии у работников средств индивидуальной защиты от химических факторов',
          'Тару из-под растворителей необходимо плотно закрывать и хранить только в предназначенном для этого помещении или на открытом воздухе',
          'При неработающей вентиляции случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов (например, опилки, песок)',
          'Специальные помещения, в которых проводится обезжиривание и хранятся растворители, оснащаются постоянно действующей приточно-вытяжной вентиляцией',
        ],
        correctAnswers: [
          'При неработающей вентиляции случайно пролитый растворитель должен быть немедленно убран с помощью сухих материалов (например, опилки, песок)',
        ],
      },
      {
        code: '63645180',
        text:
          'Каким документом определяются сроки проверки состояния адсорбента в блоке комплексной очистки при условии сохранения его работоспособности?',
        answers: [
          'Общепроизводственной инструкцией',
          'Руководством по эксплуатации блока комплексной очистки',
          'Технологическим регламентом',
          'Технологической инструкцией',
        ],
        correctAnswers: ['Технологическим регламентом'],
      },
      {
        code: '63645181',
        text:
          'На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?',
        answers: [
          'На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия',
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди',
        ],
        correctAnswers: [
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
        ],
      },
      {
        code: '63645182',
        text:
          'Какие условия, установленные требованиями Правил безопасности химически опасных производственных объектов к размещению кислородно-распределительных (регуляторных) пунктов в отдельно стоящих зданиях объектов производства и потребления продуктов разделения воздуха, указаны неверно?',
        answers: [
          'Размещать все линии регулирования с отсечным и регулирующим клапанами в одном помещении',
          'Оснащать системой вентиляции каждое помещение, в котором размещается линия регулирования',
          'Предусматривать автоматическое управление регулирующими и отсечными клапанами и дистанционное управление отключающими электрозадвижками',
          'Помещение управления кислородно-распределительных (регуляторных) пунктов не должно иметь непосредственного прохода (входа) в помещение линий регулирования. Проход в помещение линий регулирования кислородно-распределительных (регуляторных) пунктов должен осуществляться только через наружные входы (выходы), размещаемые на противоположных концах каждого помещения',
        ],
        correctAnswers: [
          'Размещать все линии регулирования с отсечным и регулирующим клапанами в одном помещении',
        ],
      },
      {
        code: '63645183',
        text:
          'В каком случае допускается попеременное использование технических устройств и коммуникаций, работающих с кислородом, для работы с воздухом и инертными газами?',
        answers: [
          'Для регенерации',
          'Для отогрева',
          'Для продувки',
          'Допускается во всех перечисленных случаях',
        ],
        correctAnswers: ['Допускается во всех перечисленных случаях'],
      },
      {
        code: '63645184',
        text:
          'Какое из перечисленных требований к центробежным кислородным компрессорам указано неверно?',
        answers: [
          'Пуск, управление и контроль за работой центробежных кислородных компрессоров необходимо производить дистанционно из отдельного помещения',
          'При размещении маслобака центробежного кислородного компрессора в пределах фундамента компрессора или непосредственно рядом с ним контроль уровня масла в баке и управление операциями по доливу масла производятся вручную',
          'Не допускается попадание масла на фундамент компрессора',
          'При подключении кислородного компрессора к двум коллекторам нагнетания подключение к каждому коллектору необходимо производить через отдельный обратный клапан, исключающий возможность перетекания кислорода из одного коллектора в другой',
        ],
        correctAnswers: [
          'При размещении маслобака центробежного кислородного компрессора в пределах фундамента компрессора или непосредственно рядом с ним контроль уровня масла в баке и управление операциями по доливу масла производятся вручную',
        ],
      },
      {
        code: '63645185',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение времени, достаточного для исключения опасной ситуации',
          'Время устанавливается в проектной документации',
          'В течение 8 часов',
          'В течение 24 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63645186',
        text:
          'Какие требования, установленные Правилами безопасности химически опасных производственных объектов к отключающей арматуре для присоединения гибких трубопроводов (шлангам, рукавам) при периодическом отборе продуктов разделения воздуха из трубопроводов, указаны неверно?',
        answers: [
          'Отключающая арматура для присоединения гибких трубопроводов (шланги, рукава) должна размещаться в металлическом шкафу с отверстиями для вентиляции',
          'После прекращения работ (периодическом отборе продуктов разделения воздуха из трубопроводов) шкаф следует запирать',
          'Сигнально-предупредительные надписи и опознавательная окраска шкафов выполняются в соответствии с проектной документацией (документацией)',
          'Сигнально-предупредительные надписи и опознавательная окраска шкафов выполняются в соответствии с рекомендациями разработчика',
        ],
        correctAnswers: [
          'Сигнально-предупредительные надписи и опознавательная окраска шкафов выполняются в соответствии с рекомендациями разработчика',
        ],
      },
      {
        code: '63645187',
        text:
          'В каком случае допускается одновременное проведение ремонта технических устройств и трубопроводов?',
        answers: [
          'Все ответы неверны',
          'Ремонт трубопроводов и арматуры теплого и холодного концов регенераторов',
          'Ремонт трубопроводов и арматуры холодного конца регенераторов и системы "приказного" воздуха переключения регенераторов или механизма переключения',
          'Ремонт принудительных клапанов переключения регенераторов и трехходовых заслонок после регенераторов',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63645188',
        text:
          'Какое требование предъявляется к продолжительности непрерывной работы аппаратов воздухораспределительных установок, в которых испаряется жидкий кислород и отогрев которых при работающей установке предусмотрен технологическим процессом?',
        answers: [
          'Продолжительность непрерывной работы не устанавливается при условии их исправности и проведения всех видов технических осмотров',
          'Не должна превышать срока, установленного техническими условиями разработчика установки',
          'Не должна превышать срока, установленного документацией завода-изготовителя',
          'Не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде',
        ],
        correctAnswers: [
          'Не должна превышать сроков, определенных технологическим регламентом, а также должна учитывать результаты анализов на содержание углеводородов в жидком кислороде',
        ],
      },
      {
        code: '63645189',
        text:
          'Как должны быть установлены все металлические конструкции, расположенные в пределах площадок, на которых размещены сосуды и сливоналивные устройства жидких продуктов разделения воздуха?',
        answers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,1 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,25 м',
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,15 м',
        ],
        correctAnswers: [
          'На бетонные фундаменты с отметкой верха, превышающей отметку площадки не менее чем на 0,2 м',
        ],
      },
      {
        code: '63645190',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?',
        answers: [
          'На каждом с давлением нагнетания более 0,6 МПа',
          'С давлением нагнетания 0,6 МПа',
          'На одном из компрессоров с давлением нагнетания более 0,6 МПа',
          'На любом из компрессоров с давлением нагнетания 0,6 МПа',
        ],
        correctAnswers: ['На каждом с давлением нагнетания более 0,6 МПа'],
      },
      {
        code: '63645191',
        text:
          'В каком случае допускается устройство смотровых канав и других приямков в помещениях, предназначенных для стоянки автомобилей с сосудами жидких продуктов разделения воздуха?',
        answers: [
          'При согласовании с Ростехнадзором',
          'По решению проектной организации',
          'По решению технической комиссии эксплуатирующей организации',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63645192',
        text:
          'В каких случаях следует немедленно прекратить подачу газа в турбодетандер?',
        answers: [
          'При пропадании напряжения на мотор-генераторе работающего турбодетандера',
          'При скачке напряжения на мотор-генераторе работающего турбодетандера',
          'При падении напряжения на мотор-генераторе работающего турбодетандера',
          'При низком напряжении на мотор-генераторе работающего турбодетандера',
        ],
        correctAnswers: [
          'При пропадании напряжения на мотор-генераторе работающего турбодетандера',
        ],
      },
      {
        code: '63645193',
        text:
          'На каком расстоянии на каждом кислородопроводе перед кислородно-распределительными (регуляторными) пунктами необходимо устанавливать отключающую задвижку с дистанционным управлением?',
        answers: [
          'Не менее 100,0 м и не более 150,0 м',
          'Не менее 10,0 м и не более 50,0 м',
          'Не менее 50,0 м и не более 100,0 м',
          'Не менее 5,0 м и не более 100,0 м',
        ],
        correctAnswers: ['Не менее 10,0 м и не более 50,0 м'],
      },
      {
        code: '63645194',
        text:
          'В какие временные интервалы в зависимости от температуры воздуха, выходящего из аппаратов, следует производить отогрев воздухораспределительных установок?',
        answers: [
          'До достижения температуры воздуха, выходящего из аппаратов в течение одного часа, до температуры, определенной технологическим регламентом',
          'До достижения температуры воздуха, выходящего из аппаратов в течение полутора часов, до температуры, определенной технологическим регламентом',
          'До достижения температуры воздуха, выходящего из аппаратов в течение двух часов, до температуры, определенной технологическим регламентом',
          'До достижения температуры воздуха, выходящего из аппаратов в течение получаса, до температуры, определенной технологическим регламентом',
        ],
        correctAnswers: [
          'До достижения температуры воздуха, выходящего из аппаратов в течение двух часов, до температуры, определенной технологическим регламентом',
        ],
      },
      {
        code: '63645195',
        text:
          'Где необходимо устанавливать фильтры на кислородопроводах, изготовленных из углеродистых или низколегированных сталей, работающих под давлением более 1,6 МПа?',
        answers: [
          'На вводах трубопроводов кислорода в здание',
          'Только перед регулирующей арматурой',
          'По ходу кислорода перед регулирующей арматурой, запорной арматурой при длине трубопроводов более 250 м',
          'Перед регулирующей арматурой и запорной арматурой при длине трубопроводов более 300 м',
        ],
        correctAnswers: [
          'По ходу кислорода перед регулирующей арматурой, запорной арматурой при длине трубопроводов более 250 м',
        ],
      },
      {
        code: '63645196',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующих токсичные, высокотоксичные вещества, органические теплоносители, воспламеняющиеся и горючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 6 лет',
          'Не реже одного раза в год',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63645197',
        text:
          'Как должны располагаться внутрицеховые технологические трубопроводы с тяжелыми газами, прокладываемые по несгораемой поверхности несущих стен производственных зданий с оконными и дверными проемами?',
        answers: [
          'На 1 м выше оконных и дверных проемов',
          'На 0,5 м выше оконных и дверных проемов',
          'На 0,5 м ниже оконных и дверных проемов',
          'На 1 м ниже оконных и дверных проемов',
        ],
        correctAnswers: ['На 0,5 м ниже оконных и дверных проемов'],
      },
      {
        code: '63645198',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
        ],
      },
      {
        code: '63645199',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63645200',
        text:
          'Какое из перечисленных требований при производстве работ по ремонту технических устройств и трубопроводов указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машину) на период ремонта клапанов (трубопроводов) необходимо остановить',
          'До выполнения работ на обеих трехходовых заслонках, связанных с доступом в подводящие к ним трубопроводы, устанавливается заглушка (по ходу газа) после обеих трехходовых заслонок',
          'На весь период ремонта арматура на сбросе газа из блока в атмосферу должна быть закрыта',
        ],
        correctAnswers: [
          'Перед ремонтом электрозадвижка на входе воздуха в блок должна быть закрыта и поджата вручную',
          'Механизм переключения регенераторов (шальт-машину) на период ремонта клапанов (трубопроводов) необходимо остановить',
        ],
      },
      {
        code: '63645201',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Гидравлический расчет',
          'Статический и динамический прочностный расчет',
          'Теплотехнический расчет',
          'Расчет на прочность и устойчивость',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63645202',
        text:
          'Какие требования предъявляются к специфическим техническим требованиям для отдельных технологических процессов, устанавливаемых стандартами и иными внутренними документами организаций?',
        answers: [
          'Не должны противоречить требованиям только отраслевых стандартов',
          'Не должны противоречить требованиям отраслевых типовых инструкций',
          'Не должны противоречить требованиям Правил безопасности химически опасных производственных объектов',
          'Не должны противоречить требованиям только технической (эксплуатационной) документации организации - изготовителя технологического оборудования, применяемого для отдельных технологических процессов',
        ],
        correctAnswers: [
          'Не должны противоречить требованиям Правил безопасности химически опасных производственных объектов',
        ],
      },
      {
        code: '63645203',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Нарушение энергообеспечения',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63645204',
        text:
          'Каким должно быть остаточное давление в кислородном баллоне при его разрядке?',
        answers: [
          'Не ниже 0,05 МПа',
          'Не ниже 0,04 МПа',
          'Не ниже 0,03 МПа',
          'Не ниже 0,01 МПа',
        ],
        correctAnswers: ['Не ниже 0,05 МПа'],
      },
      {
        code: '63645205',
        text:
          'Кем утверждается акт перевода технического устройства, используемого для работы с одним ПРВ, на работу с другим продуктом, подтверждающий выполнение всех необходимых для этого требований?',
        answers: [
          'Техническим руководителем организации, эксплуатирующей объекты потребления и производства ПРВ',
          'Представителем сторонней организации',
          'Представителем территориального органа Ростехнадзора',
          'Представителем проектной организации',
        ],
        correctAnswers: [
          'Техническим руководителем организации, эксплуатирующей объекты потребления и производства ПРВ',
        ],
      },
      {
        code: '63645206',
        text:
          'Какой категории обеспечения надежности электроснабжения должны быть электроприемники, обеспечивающие технологические процессы на объектах производства и потребления продуктов разделения воздуха?',
        answers: [
          'К I категории надежности',
          'Ко II категории надежности',
          'К III категории надежности',
          'Требования к категории надежности не установлены',
        ],
        correctAnswers: ['Ко II категории надежности'],
      },
      {
        code: '63645207',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Разработчиком проекта',
          'Комиссией эксплуатирующей организации',
          'Специализированной сторонней организацией',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63645208',
        text:
          'Выполнение какого условия, относящегося к трубопроводам и компрессорам продуктов разделения воздуха, допускается?',
        answers: [
          'Объединение дренажных трубопроводов',
          'Возможность попадания кислорода во всасывающую линию при обкатке компрессоров',
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
          'Все перечисленные условия',
        ],
        correctAnswers: [
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
        ],
      },
      {
        code: '63645209',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63645210',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63645211',
        text:
          'Что не соответствует требованиям, установленным Правилами безопасности химически опасных производственных объектов к транспортированию и хранению баллонов с продуктами разделения воздуха?',
        answers: [
          'Транспортирование баллонов с продуктами разделения воздуха производится в вертикальном положении в контейнерах',
          'Работы по погрузке и выгрузке баллонов с продуктами разделения воздуха осуществляются только вручную',
          'Работы по погрузке и выгрузке контейнеров необходимо механизировать',
          'Наполненные и пустые баллоны необходимо хранить в контейнерах или клетках',
        ],
        correctAnswers: [
          'Работы по погрузке и выгрузке баллонов с продуктами разделения воздуха осуществляются только вручную',
        ],
      },
      {
        code: '63645212',
        text:
          'Какие требования предъявляются к испарению жидких криогенных продуктов разделения воздуха, сливаемых из отдельных аппаратов воздухораспределительных установок перед их отогревом?',
        answers: [
          'Испарение производится в испарителях, предусмотренных проектной документацией (документацией) разработчика установки',
          'Испарение производится в специальных испарителях медленного слива, предусмотренных проектной документацией отдельно для каждой установки',
          'Испарение производится в устройствах для слива и испарения жидких продуктов разделения воздуха, предусмотренных проектной документацией (документацией) на установки',
          'Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки',
        ],
        correctAnswers: [
          'Испарение производится в специальных испарителях быстрого слива, предусмотренных проектной документацией (документацией) отдельно для каждой установки',
        ],
      },
      {
        code: '63645213',
        text:
          'Какие требования к ваннам для охлаждения деталей жидким азотом, установленные Правилами безопасности химически опасных производственных объектов, указаны неверно?',
        answers: [
          'Подлежат отогреву при объемной доле кислорода в азоте более 30%',
          'Подлежат отогреву при объемной доле кислорода в азоте более 23%',
          'Подлежат периодическому обезжириванию',
          'Над ваннами необходимо предусматривать местные отсосы',
        ],
        correctAnswers: [
          'Подлежат отогреву при объемной доле кислорода в азоте более 23%',
        ],
      },
      {
        code: '63645214',
        text:
          'Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?',
        answers: [
          'Умягченную воду, полученную из заводской системы пароснабжения',
          'Конденсат, полученный из заводской системы пароснабжения',
          'Дистиллят, полученный из воды питьевого качества',
          'Масла, рекомендованные разработчиками компрессора',
        ],
        correctAnswers: ['Дистиллят, полученный из воды питьевого качества'],
      },
      {
        code: '63645215',
        text:
          'В каких из перечисленных категорий помещений по взрывопожарной и пожарной опасности допускается размещение сосудов с жидким кислородом?',
        answers: [
          'В помещениях, отнесенных к категории А',
          'В помещениях, отнесенных к категории Б',
          'В помещениях, отнесенных к категории В1 - В4',
          'Размещение сосудов с жидким кислородом не допускается в помещениях, отнесенных ко всем перечисленным категориям',
        ],
        correctAnswers: [
          'Размещение сосудов с жидким кислородом не допускается в помещениях, отнесенных ко всем перечисленным категориям',
        ],
      },
      {
        code: '63645216',
        text:
          'Какие меры безопасности необходимо предпринять, если при забросе жидкости в турбодетандер или при понижении температуры газа на входе ниже температуры, указанной в технологической инструкции, не сработала автоматическая защита?',
        answers: [
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера',
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы после турбодетандера',
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до турбодетандера',
          'Немедленно продуть трубопроводы до и после турбодетандера',
        ],
        correctAnswers: [
          'Немедленно отключить турбодетандер (прекратить подачу газа в турбодетандер) и продуть трубопроводы до и после турбодетандера',
        ],
      },
      {
        code: '63645217',
        text:
          'В каком документе указываются регламентированные параметры технологического процесса?',
        answers: [
          'В технологическом регламенте',
          'В руководствах по безопасности',
          'В техническом регламенте',
          'В проектной документации',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63645218',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 5 минут',
          'Через 10 минут',
          'Через 60 минут',
          'Через 30 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63645219',
        text:
          'Какую кислородную арматуру допускается размещать в помещениях щитов управления (щитовая)?',
        answers: [
          'Кислородную аппаратуру с давлением 0,6 МПа и выше',
          'Кислородную арматуру с давлением 1,6 МПа и выше',
          'Кислородную арматуру с давлением 2,6 МПа и выше',
          'Не допускается размещать кислородную арматуру (независимо от давления)',
        ],
        correctAnswers: [
          'Не допускается размещать кислородную арматуру (независимо от давления)',
        ],
      },
      {
        code: '63645220',
        text:
          'Что из перечисленного может не учитываться для определения рисков при проектировании технологических трубопроводов?',
        answers: [
          'Усталость при переменных нагрузках',
          'Вибрация',
          'Охлаждение от температуры окружающего воздуха',
          'Наличие защищенных подвижных элементов',
        ],
        correctAnswers: ['Наличие защищенных подвижных элементов'],
      },
      {
        code: '63645221',
        text:
          'Какие требования необходимо соблюдать при устройстве под блоками разделения воздуха подвального помещения?',
        answers: [
          'Поддерживать температуру воздуха не ниже 4 °С, а также предусматривать систему пожаротушения',
          'Поддерживать температуру воздуха не ниже 15 °С, а также предусматривать систему приточно-вытяжной вентиляции',
          'Поддерживать температуру воздуха не ниже 14 °С, а также предусматривать систему кондиционирования воздуха',
          'Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции',
        ],
        correctAnswers: [
          'Поддерживать температуру воздуха не ниже 5 °С, а также предусматривать систему вытяжной вентиляции',
        ],
      },
      {
        code: '63645222',
        text:
          'При каком давлении во всасывающих коммуникациях компрессоров, сжимающих продукты разделения воздуха, данные компрессоры должны автоматически отключаться?',
        answers: [
          'При снижении давления ниже 1,5 кПа',
          'При снижении давления ниже 1,0 кПа',
          'При снижении давления ниже 0,6 кПа',
          'При снижении давления ниже 0,5 кПа',
        ],
        correctAnswers: ['При снижении давления ниже 0,5 кПа'],
      },
    ],
    63644: [
      {
        code: '63644000',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Непосредственный руководитель работ и лица ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63644001',
        text:
          'Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
      },
      {
        code: '63644002',
        text:
          'Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала',
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
          'Посредством наглядных пособий (плакатов) по охране труда',
        ],
        correctAnswers: [
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
      },
      {
        code: '63644003',
        text:
          'Какова предельно допустимая величина концентрации взрывоопасной парогазовой фазы сигнализации средств автоматического газового анализа в производственных помещениях на открытых наружных установках?',
        answers: [
          'Не более 30% от нижнего концентрационного предела распространения пламени',
          'Не более 40% от нижнего концентрационного предела распространения пламени',
          'Не более 20% от нижнего концентрационного предела распространения пламени',
          'Не более 25% от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Не более 20% от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63644004',
        text:
          'Что используется для питания пневматических систем контроля, управления и противоаварийной защиты?',
        answers: ['Сжатый воздух', 'Азот', 'Любой инертный газ', 'Неон'],
        correctAnswers: ['Сжатый воздух'],
      },
      {
        code: '63644005',
        text:
          'Какими устройствами не должны оснащаться насосы, применяемые для нагнетания легковоспламеняющихся и горючих жидкостей (масло растительное и минеральное, мисцелла, растворитель)?',
        answers: [
          'Устройствами, регулирующими частоту вращения вала',
          'Блокировками, исключающими пуск и работу насоса "всухую" или прекращающими работу насоса при падении давления перемещаемой жидкости в нагнетательном патрубке насоса ниже установленного регламентом или паспортными данными или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений (верхний и нижний уровни)',
          'Средствами предупредительной сигнализации о нарушении параметров работы, влияющих на безопасность',
          'Средствами местного и дистанционного отключения, расположенными в легкодоступных местах',
        ],
        correctAnswers: ['Устройствами, регулирующими частоту вращения вала'],
      },
      {
        code: '63644006',
        text:
          'В каком случае разрешается установка запорного устройства на вытяжной трубе объектов производств растительных масел?',
        answers: [
          'Если вытяжная труба оборудована пароэжектором или вентилятором',
          'На вытяжной трубе дефлегматора',
          'На вытяжной трубе конденсатора',
          'На вытяжной трубе абсорбера',
        ],
        correctAnswers: [
          'Если вытяжная труба оборудована пароэжектором или вентилятором',
        ],
      },
      {
        code: '63644007',
        text:
          'В каком из перечисленных случаев гибкие шланги не применяются при проведении операций слива, налива, транспортирования и хранения растворителя и масла?',
        answers: [
          'Для проведения операций слива и налива в железнодорожные цистерны и другое нестандартное оборудование',
          'Для выполнения вспомогательных операций',
          'Для продувки аппаратов',
          'Во взрывопожароопасных производствах в качестве стационарных трубопроводов для транспортирования растворителя, мисцеллы',
        ],
        correctAnswers: [
          'Во взрывопожароопасных производствах в качестве стационарных трубопроводов для транспортирования растворителя, мисцеллы',
        ],
      },
      {
        code: '63644008',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63644009',
        text:
          'Какие требования к тепловой изоляции аппаратов, технологических трубопроводов для растительных масел и мисцеллы объектов производств растительных масел указаны неверно?',
        answers: [
          'Для теплоизоляции используется непористый негорючий материал',
          'Необходимо предусматривать меры защиты от попаданий масла, мисцеллы, растворителя на изоляцию или под ее слой',
          'Для теплоизоляции используется трудносгораемый материал',
          'Теплоизоляционные изделия из минеральной ваты, базальтового или супертонкого стекла применяются только в обкладках со всех сторон из стеклянной или кремнеземной ткани и под металлическим покрывным слоем',
        ],
        correctAnswers: [
          'Для теплоизоляции используется трудносгораемый материал',
        ],
      },
      {
        code: '63644010',
        text:
          'Кто и в какой форме выдает распоряжение на подключение электрооборудования подвижных конвейеров к распределительной коробке и к заземляющим устройствам объектов производств растительных масел?',
        answers: [
          'Начальник цеха (участка) выдает письменное распоряжение',
          'Начальник цеха (участка) выдает устное распоряжение',
          'Технический руководитель организации выдает письменное разрешение начальнику цеха (участка) и непосредственным исполнителям',
          'Лицо, ответственное за электрохозяйство, выдает устное распоряжение',
        ],
        correctAnswers: [
          'Начальник цеха (участка) выдает письменное распоряжение',
        ],
      },
      {
        code: '63644011',
        text:
          'Какие помещения объектов производств растительных масел относятся к неотапливаемым помещениям?',
        answers: [
          'Только здания элеваторов масличного сырья и шрота',
          'Только здания складов',
          'Только надсилосные и подсилосные этажи',
          'Все перечисленные помещения',
        ],
        correctAnswers: ['Все перечисленные помещения'],
      },
      {
        code: '63644012',
        text:
          'Какое объемное содержание диоксида (двуокиси) углерода и кислорода допускается в подвальных и полуподвальных помещениях складов масличного сырья, галерей и туннелей, предназначенных для транспортирования маслосемян, а также приямков, в которых размещено оборудование для транспортирования масличного сырья в случае неисправности вентиляции?',
        answers: [
          'Содержание в воздухе СО2 не должно превышать 0,5%, а О2 должно быть не менее 20%',
          'Содержание в воздухе СО2 не должно превышать 2%, а О2 должно быть не менее 25%',
          'Содержание в воздухе СО2 не должно превышать 1,0%, а О2 должно быть не менее 30%',
          'Содержание в воздухе СО2 не должно превышать 1,5%, а О2 должно быть не менее 20%',
        ],
        correctAnswers: [
          'Содержание в воздухе СО2 не должно превышать 0,5%, а О2 должно быть не менее 20%',
        ],
      },
      {
        code: '63644013',
        text:
          'Какое требование к газоходу, соединяющему тостер и мокрую шротоловушку, указано неверно?',
        answers: [
          'Должен быть оборудован показывающим манометром',
          'Должен иметь лючки в торцах для ревизии и очистки внутренней поверхности',
          'Должен иметь уклон в сторону мокрой шротоловушки не менее 2 см на 1 м трубы',
          'Должен иметь подвод пара для пропаривания газохода и шротоловушки перед вскрытием их для осмотра или ремонта',
        ],
        correctAnswers: ['Должен быть оборудован показывающим манометром'],
      },
      {
        code: '63644014',
        text:
          'С кем согласовывается применение в производстве растительных масел новых веществ (растворителей, абсорбентов, а также маслосодержащих материалов)?',
        answers: [
          'С научно-исследовательской организацией',
          'С организацией - производителем данных веществ',
          'С представителем территориального органа Ростехнадзора',
          'Со специализированной организацией, разрабатывающей нормативно-техническую документацию на процесс, в котором это вещество применяется',
        ],
        correctAnswers: [
          'Со специализированной организацией, разрабатывающей нормативно-техническую документацию на процесс, в котором это вещество применяется',
        ],
      },
      {
        code: '63644015',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63644016',
        text:
          'Какая арматура должна применяться в экстракционном цехе объектов производств растительных масел?',
        answers: [
          'Стальная',
          'Чугунная',
          'Из неметаллических конструкционных материалов',
          'Не регламентируется',
        ],
        correctAnswers: ['Стальная'],
      },
      {
        code: '63644017',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "Листа регистрации изменений и дополнений"?',
        answers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента',
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под подпись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63644018',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для новых в данной организации производств',
          'Для производств с новой технологией',
          'Все ответы неверны',
          'Для всех перечисленных производств',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63644019',
        text:
          'Кем утверждается периодичность замеров температуры хранящихся веществ (масличного сырья, шрота)?',
        answers: [
          'Производственной организацией',
          'Организацией, эксплуатирующей опасный производственный объект',
          'Организацией, специализирующейся на разработке подобной документации',
          'Комиссией организации, эксплуатирующей опасный производственный объект, под председательством инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63644020',
        text:
          'Какое из перечисленных требований к процессам транспортирования и хранения масличного сырья, жмыхов и шротов указано неверно?',
        answers: [
          'Температуру заложенных на хранение жмыха и шрота необходимо проверять по графику, утвержденному в эксплуатирующей организации, с помощью дистанционных стационарных термометров (термоподвесок)',
          'Для вновь строящихся элеваторов для шрота должны предусматриваться устройства, обеспечивающие разрыхление слежавшегося шрота',
          'Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается',
          'Пневмотранспорт и его элементы должны быть заземлены путем установки электропроводящих перемычек в местах фланцевых соединений элементов',
        ],
        correctAnswers: [
          'Элеваторы для хранения масличного сырья и шрота должны снабжаться передвижными лебедками с люльками для спуска людей внутрь ячейки силоса в случае необходимости. Установка внутри лестниц (скоб) не допускается',
        ],
      },
      {
        code: '63644021',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Только на единицу времени (час)',
          'Только на один производственный поток или на мощность производства в целом',
          'Только на единицу выпускаемой продукции',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63644022',
        text:
          'Кто определяет график контроля воздушной среды в подвальных и полуподвальных помещениях масличного сырья, галереях, туннелях и приямках, связанных с транспортированием масличного сырья?',
        answers: [
          'Начальник складского помещения',
          'Технический руководитель организации',
          'Ответственный за охрану труда',
          'Руководитель структурного подразделения',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63644023',
        text:
          'Каким образом допускается отогревать замерзшие трубопроводы с растворителем (мисцеллой)?',
        answers: [
          'Паром или водой',
          'С помощью кислородной горелки',
          'С применением теплоизолирующих материалов',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Паром или водой'],
      },
      {
        code: '63644024',
        text:
          'Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные и разовые и лабораторные',
          'Периодически пересматриваемые',
          'Входящие в состав проектной документации или пусковые',
        ],
        correctAnswers: ['Постоянные, временные и разовые и лабораторные'],
      },
      {
        code: '63644025',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
          'Промывка (пропарка)',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63644026',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
          'По решению территориального органа Ростехнадзора',
          'Если это определено и обосновано разработчиком проекта',
          'Если это определено и обосновано научно-исследовательской организацией.',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63644027',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63644028',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63644029',
        text:
          'Какие требования установлены к люку-лазу, предназначенному для осмотра и ремонтных работ внутри силосных ячеек (бункеров, завальных ям)?',
        answers: [
          'Люк размером не менее 500 x 500 мм, оборудованный съемной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой',
          'Люк размером не менее 400 x 400 мм, оборудованный съемной прочной решеткой с размером ячеек не более 80 x 80 мм',
          'Люк размером не менее 500 x 500 мм, оборудованный съемной прочной решеткой с размером ячеек не более 50 x 50 мм и герметичной крышкой',
          'Люк размером не менее 600 x 600 мм, оборудованный съемной прочной решеткой с размером ячеек не более 150 x 150 мм и герметичной крышкой',
        ],
        correctAnswers: [
          'Люк размером не менее 500 x 500 мм, оборудованный съемной решеткой с размером ячеек не более 100 x 100 мм и герметичной крышкой',
        ],
      },
      {
        code: '63644030',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Комиссия эксплуатирующей организации',
          'Руководитель проектной организации',
          'Руководитель эксплуатирующей организации',
          'Инспектор территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63644031',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63644032',
        text:
          'Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?',
        answers: [
          'Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ',
          'Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ',
          'Произвести запись в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен) и наряде-допуске на проведение газоопасных работ',
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
        correctAnswers: [
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
      },
      {
        code: '63644033',
        text:
          'Что предусматривается для максимального снижения выбросов в окружающую среду растворителя в виде паров и жидкой фазы при аварийной разгерметизации системы в технологических системах объектов производств растительных масел для технологических блоков I категории взрывоопасности?',
        answers: [
          'Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 с',
          'Установка запорных и (или) отсекающих устройств с дистанционным управлением и временем срабатывания не более 120 с',
          'Установка запорных устройств с ручным приводом, при этом предусматривается минимальное время приведения их в действие за счет рационального размещения (максимально допустимого приближения к рабочему месту оператора), но не более 300 с',
        ],
        correctAnswers: [
          'Установка автоматических быстродействующих запорных и (или) отсекающих устройств со временем срабатывания не более 12 с',
        ],
      },
      {
        code: '63644034',
        text:
          'Какие требования к системам автоматического контроля и управления технологическими процессами объектов производств растительных масел указаны неверно?',
        answers: [
          'Должны обеспечивать сигнализацию и регистрацию в реальном времени срабатывания средств системы противоаварийной защиты',
          'Должны обеспечивать постоянную связь оператора установки с механиком цеха',
          'Должны обеспечивать сигнализацию и регистрацию в реальном времени отклонений основных технологических параметров, в том числе определяющих безопасность ведения процесса',
          'Должны обеспечивать постоянный контроль за параметрами технологического процесса и управление режимом для поддержания их регламентированных значений',
        ],
        correctAnswers: [
          'Должны обеспечивать постоянную связь оператора установки с механиком цеха',
        ],
      },
      {
        code: '63644035',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей технологической системы, и нанесенное на нем обозначение номера по технологической документации должно быть на схеме закрашено',
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих технологических систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
        ],
      },
      {
        code: '63644036',
        text:
          'Каким документом обосновывается достаточность мер по максимальному снижению уровня взрывоопасности технологических блоков, предотвращению взрывов и загораний внутри технологического оборудования, производственных помещений и наружных установок объектов, связанных с производством растительных масел?',
        answers: [
          'Проектной документацией',
          'Декларацией промышленной безопасности',
          'Обоснованием безопасности опасного производственного объекта',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63644037',
        text:
          'Каким должно быть количество деблокирующих ключей в схемах противоаварийной защиты объектов производств масел?',
        answers: [
          'Минимальным',
          'Максимальным',
          'Не больше 10-ти',
          'Не меньше 8-ми',
        ],
        correctAnswers: ['Минимальным'],
      },
      {
        code: '63644038',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63644039',
        text:
          'Кем рассчитывается и подбирается гидрозатвор для каждого типа водоотделителя объектов производств растительных масел?',
        answers: [
          'Проектной организацией',
          'Эксплуатирующей организацией',
          'Научно-исследовательской организацией',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Проектной организацией'],
      },
      {
        code: '63644040',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63644041',
        text:
          'Какое допускается содержание пыли и паров растворителя в воздухе, подаваемом в системы приточной вентиляции, объектов производств растительных масел?',
        answers: [
          'Не должно превышать 25% предельно допустимой концентрации их в воздухе рабочей зоны производственных помещений',
          'Не должно превышать 30% предельно допустимой концентрации их в воздухе рабочей зоны производственных помещений',
          'Не допускается содержание пыли и паров в воздухе, подаваемом в системы приточной вентиляции',
          'Регулируется внутренними документами организации',
        ],
        correctAnswers: [
          'Не должно превышать 30% предельно допустимой концентрации их в воздухе рабочей зоны производственных помещений',
        ],
      },
      {
        code: '63644042',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63644043',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для напорных трубопроводов',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63644044',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'Не допускается ни в каком случае',
          'После проведения расчета на прочность',
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63644045',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 10',
          'Не менее 15',
          'Не менее 6',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63644046',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Начальнику цеха',
          'Пожарной службе эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63644047',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63644048',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливоналивных эстакадах?',
        answers: [
          'Должно быть организовано только управление по месту',
          'Должно быть организовано только управление дистанционно (из безопасного места)',
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63644049',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63644050',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '3 месяца со дня его окончания',
          '6 месяцев со дня его окончания',
          '1 год со дня его окончания',
          '3 года со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63644051',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Производить подготовку объекта к проведению газоопасных работ',
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Непосредственно участвовать в выполнении газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63644052',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'С руководителями аварийно-спасательных служб',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'С руководителями службы производственного контроля',
          'С руководителями службы охраны труда и санитарными службами',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63644053',
        text:
          'При какой минимальной концентрации паров растворителя в воздухе должна автоматически включаться аварийная вентиляция?',
        answers: [
          '10% от нижнего концентрационного предела распространения пламени',
          '5% от нижнего концентрационного предела распространения пламени',
          '18% от нижнего концентрационного предела распространения пламени',
          '15% от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          '10% от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63644054',
        text:
          'С какой периодичностью должны проверяться огнепреградители при температуре ниже 0 °C на объектах производств растительных масел?',
        answers: [
          'Не реже 1 раза в 10 дней',
          'Не реже 1 раза в сутки',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 15 дней',
        ],
        correctAnswers: ['Не реже 1 раза в 10 дней'],
      },
      {
        code: '63644055',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Вату из непрерывного стеклянного волокна',
          'Порошкообразные теплоизоляционные материалы',
          'Базальтовое супертонкое волокно',
          'Допускаются все перечисленные материалы',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63644056',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63644057',
        text:
          'В каких случаях допускается помещения управления и анализаторные помещения пристраивать к зданиям с взрывоопасными зонами?',
        answers: [
          'При соответствующем обосновании проектной организацией',
          'В случае размещения в этих зданиях парового или водяного отопления',
          'В случае прохождения через помещения управления трубопроводов, воздуховодов, кабелей и т. п.',
          'При размещении в них оборудования и других устройств, не связанных с системой управления технологическим процессом',
        ],
        correctAnswers: [
          'При соответствующем обосновании проектной организацией',
        ],
      },
      {
        code: '63644058',
        text:
          'Какое напряжение применяется для питания ручных светильников в помещениях, отнесенных к помещениям с повышенной опасностью и особо опасным, объектов производств растительных масел?',
        answers: [
          'Не выше 42 В',
          'Не выше 60 В',
          'Не выше 125 В',
          'Не выше 36 В',
        ],
        correctAnswers: ['Не выше 42 В'],
      },
      {
        code: '63644059',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63644060',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63644061',
        text:
          'Какое из перечисленных утверждений, относящихся к трубопроводной арматуре объектов производств растительных масел, допускается?',
        answers: [
          'Использование пробковых чугунных кранов в качестве арматуры противоаварийного назначения',
          'Изготовление лючков и задвижек на перепускных течках (экстрактора, испарителя растворителя из шрота) из нержавеющей стали',
          'Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств',
          'Все перечисленные утверждения',
        ],
        correctAnswers: [
          'Установка запорной арматуры с ручным управлением при использовании регулирующей арматуры с дистанционным управлением в качестве отсекающих устройств',
        ],
      },
      {
        code: '63644062',
        text:
          'Что необходимо немедленно предпринять при внезапной остановке жаровни объектов производств растительных масел?',
        answers: [
          'Прекратить подачу воды в жаровню',
          'Выгрузить мезгу и очистить чаны',
          'Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню',
          'Сообщить ответственному за безопасное производство работ',
        ],
        correctAnswers: [
          'Обесточить электродвигатели жаровни, электродвигатели инактиватора или пропарочно-увлажнительного винтового конвейера и всех транспортных элементов, питающих жаровню, прекратить подачу глухого пара в жаровню',
        ],
      },
      {
        code: '63644063',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63644064',
        text:
          'Какие параметры экстракционной установки, требующие остановки цеха, указаны верно?',
        answers: [
          'Падение давления и температуры пара на коллекторах',
          'Падение давления сжатого воздуха ниже 0,4 МПа (4 кгс/см²) для питания систем контроля и управления системы противоаварийной защиты',
          'Повышение концентрации паров растворителя в воздушной среде цеха до 20% от нижнего концентрационного предела распространения пламени',
          'Повышение давления воды в циркуляционной системе на 10%',
        ],
        correctAnswers: ['Падение давления и температуры пара на коллекторах'],
      },
      {
        code: '63644065',
        text:
          'Каким образом должны выполняться участки перекрытий под оборудованием экстракционного цеха во избежание растекания (розлива) растворителя (мисцеллы)?',
        answers: [
          'Без проемов и ограждаться бортом высотой не менее 0,15 м',
          'С проемами в виде поддонов с бортиками не менее 0,2 м',
          'Без проемов и ограждаться бортом высотой не менее 0,25 м',
          'С проемами в виде поддонов с бортиками не менее 0,1 м',
        ],
        correctAnswers: [
          'Без проемов и ограждаться бортом высотой не менее 0,15 м',
        ],
      },
      {
        code: '63644066',
        text:
          'Какая периодичность осмотра технического состояния трубок конденсаторов объектов производств растительных масел путем осмотра со вскрытием крышек?',
        answers: [
          'Не реже 1 раза в год',
          'Определяется технологическим регламентом',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63644067',
        text:
          'Какое требование установлено к газосигнализаторам довзрывных концентраций горючих газов в помещениях цеха экстракции, отгонки растворителя из шрота, дистилляции, насосных для перекачки растворителя объектов производств растительных масел?',
        answers: [
          'Газосигнализаторы с сигнализацией превышения 10% уровня от нижнего концентрационного предела распространения пламени',
          'Газосигнализаторы с сигнализацией превышения 20% уровня от нижнего концентрационного предела распространения пламени',
          'Газосигнализаторы с сигнализацией превышения 5% уровня от нижнего концентрационного предела распространения пламени',
          'Газосигнализаторы с сигнализацией превышения 15% уровня от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Газосигнализаторы с сигнализацией превышения 10% уровня от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63644068',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы находились на консервации более одного года',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63644069',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Руководства по эксплуатации технологического оборудования и их ксерокопии',
          'Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах',
          'Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов',
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63644070',
        text:
          'Каким образом определяется порядок срабатывания систем блокировок технологического оборудования и насосов объектов производств растительных масел? Выберите 2 правильных варианта ответа.',
        answers: [
          'Правилами безопасности химически опасных производственных объектов',
          'Обслуживающим персоналом на основании инструкции по эксплуатации',
          'Схемой блокировок, представленной в технологическом регламенте и (или) проектной документации',
          'Программой (алгоритмом) срабатывания системы противоаварийной защиты технологической установки',
        ],
        correctAnswers: [
          'Схемой блокировок, представленной в технологическом регламенте и (или) проектной документации',
          'Программой (алгоритмом) срабатывания системы противоаварийной защиты технологической установки',
        ],
      },
      {
        code: '63644071',
        text:
          'С какой периодичностью должно контролироваться содержание растворителя в сточных водах из бензоловушки?',
        answers: [
          '1 раз в сутки',
          'Ежесменно',
          'В соответствии с требованиями, установленными в технологическом регламенте',
          'В соответствии с требованиями, установленными в производственной инструкции',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63644072',
        text:
          'При каком виде остановки аппараты и трубопроводы, содержащие растворитель или мисцеллу, должны продуваться азотом или водяным паром после опорожнения?',
        answers: [
          'При аварии',
          'При любых видах остановки',
          'При профилактике',
          'При ремонте',
        ],
        correctAnswers: ['При любых видах остановки'],
      },
      {
        code: '63644073',
        text:
          'В каких документах должна быть установлена схема обработки бензожиросодержащих сточных вод?',
        answers: [
          'В технологическом регламенте и проектной документации',
          'В санитарном паспорте и в технологическом регламенте',
          'В производственной инструкции',
          'В положении об экологическом контроле и в проектной документации',
        ],
        correctAnswers: [
          'В технологическом регламенте и проектной документации',
        ],
      },
      {
        code: '63644074',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами организации',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63644075',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
          'Перила должны быть высотой не менее 1,3 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63644076',
        text:
          'Какие размеры мостков с решетчатым настилом должны устраиваться в случае отбора проб масличного сырья или шрота вручную из напольных складов?',
        answers: [
          'Шириной не менее 0,5 м и перилами высотой не менее 0,7 м',
          'Шириной не менее 1 м и перилами высотой не менее 1 м',
          'Шириной не менее 0,7 м и перилами высотой не менее 0,9 м',
          'Шириной не менее 0,9 м и перилами высотой не менее 1,2 м',
        ],
        correctAnswers: [
          'Шириной не менее 0,7 м и перилами высотой не менее 0,9 м',
        ],
      },
      {
        code: '63644077',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На технологическую службу организации, производства, отделения, установки',
          'На организацию - разработчика процесса',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63644078',
        text:
          'Какие предусматриваются меры к обеспечению взрывобезопасности технологических процессов, в случае обоснованной в проектной документации необходимости проведения процесса в области критических значений температур?',
        answers: [
          'Только по обеспечению персонала индивидуальными средствами защиты',
          'Исключающие наличие или предотвращающие появление источников зажигания, способных воспламенить смесь паров растворителя с воздухом',
          'Только по обеспечению наличия наряда-допуска',
          'Обеспечивающие дополнительный контроль непосредственно руководителем подразделения незначительных превышений значений параметров процесса, способных создать опасные отклонения и стать причиной аварийной ситуации или аварии',
        ],
        correctAnswers: [
          'Исключающие наличие или предотвращающие появление источников зажигания, способных воспламенить смесь паров растворителя с воздухом',
        ],
      },
      {
        code: '63644079',
        text:
          'Из какого материала допускается изготовление наконечников резиновых шлангов, предназначенных для сливоналивных операций, на объектах производств растительных масел? Выберите 2 правильных варианта ответа.',
        answers: ['Из титана', 'Из меди', 'Из бронзы', 'Из нержавеющей стали'],
        correctAnswers: ['Из меди', 'Из бронзы'],
      },
      {
        code: '63644080',
        text:
          'Какие требования к экстракторам объектов производств растительных масел указаны неверно?',
        answers: [
          'Шнековые экстракторы оснащаются системами аварийного освобождения',
          'Шнековые экстракторы оснащаются приборами контроля и регулирования частоты вращения валов',
          'Ленточные экстракторы и экстракторы других типов, имеющие в своем составе конвейеры, оснащаются системами регулирования скорости движения ленты (конвейера) в зависимости от количества подаваемого материала',
          'Приводы экстракторов обеспечиваются системами защиты от превышения предельно допустимой нагрузки на валы, исключающими их поломку при запрессовках и заклинивании в случае попадания посторонних предметов',
        ],
        correctAnswers: [
          'Шнековые экстракторы оснащаются системами аварийного освобождения',
        ],
      },
      {
        code: '63644081',
        text:
          'В каком случае необходимо выгрузить и очистить чаны жаровни после их охлаждения от остатков мезги?',
        answers: [
          'При превышении температуры мезги',
          'Если длительность остановки жаровни превышает 10 мин',
          'При отсутствии контроля со стороны рабочего персонала',
          'Если длительность остановки жаровни превышает 1 ч',
        ],
        correctAnswers: ['Если длительность остановки жаровни превышает 1 ч'],
      },
      {
        code: '63644082',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63644083',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Не допускается',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63644084',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63644085',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Непрерывный контроль состояния воздушной среды',
          'Наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
          'Наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63644086',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63644087',
        text:
          'Какой свободный объем для демпфирования температурного расширения растворителя необходимо оставлять при заполнении резервуаров на объектах производств растительных масел?',
        answers: [
          'Не менее 10%',
          'Не менее 15%',
          'Не более10 %',
          'Устанавливается в зависимости от растворителя',
        ],
        correctAnswers: ['Не менее 10%'],
      },
      {
        code: '63644088',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания первой помощи',
          'О самочувствии',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63644089',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Опасно для жизни!", "Внимание!"',
          '"Газоопасные работы", "Газ"',
          '"Работают люди!", "Опасно для жизни!"',
          '"Осторожно! Опасная зона", "Работают люди!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63644090',
        text:
          'В соответствии с каким документом проводятся опытные работы, опробование нового оборудования, систем автоматизации в действующих взрывопожароопасных производствах с технологическими блоками II и III категорий взрывоопасности на объектах, связанных с производством растительных масел?',
        answers: [
          'В соответствии с разовым (опытным) технологическим регламентом',
          'В соответствии с технологической инструкцией',
          'В соответствии с Правилами безопасности химически опасных производственных объектов',
          'В соответствии с декларацией промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с разовым (опытным) технологическим регламентом',
        ],
      },
      {
        code: '63644091',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Категорией взрывоопасности технологических блоков',
          'Радиусом зон разрушения в м',
          'Энергией сгорания парогазовой фазы в кДж',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63644092',
        text:
          'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Выберите 2 правильных варианта ответа.',
        answers: [
          'Начальника цеха',
          'Начальника центральной лаборатории организации',
          'Заместителя руководителя организации по охране окружающей среды',
          'Главного метролога организации',
          'Начальника производства',
        ],
        correctAnswers: ['Начальника цеха', 'Начальника производства'],
      },
      {
        code: '63644093',
        text:
          'Какое требование предъявляется к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией',
          'Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63644094',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'До окончания работ на конкретном месте',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63644095',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Характеристика производимой продукции',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Технологические схемы производства',
          'Возможные инциденты в работе и способы их ликвидации',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63644096',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63644097',
        text:
          'В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?',
        answers: [
          'Не допускается ни в каком случае',
          'Объемом до 1000 кг/год',
          'Объемом до 1500 кг/год',
        ],
        correctAnswers: ['Объемом до 1000 кг/год'],
      },
      {
        code: '63644098',
        text:
          'Какое содержание кислорода допускается при анализе продувочного газа после продувки экстракционной линии?',
        answers: [
          'Не более 5%',
          'Не более 7%',
          'Не более 10%',
          'Требование к содержанию кислорода устанавливается в технологическом регламенте',
        ],
        correctAnswers: ['Не более 7%'],
      },
      {
        code: '63644099',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Испытание трубопровода на прочность и плотность',
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Осуществляется все перечисленное',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63644100',
        text:
          'Какие из требований к анализаторным помещениям объектов производств растительных масел указаны неверно?',
        answers: [
          'Должны иметь предохраняющие конструкции',
          'Должна быть предусмотрена аварийная вентиляция, которая автоматически включается в случае, когда концентрация обращающихся веществ в воздухе помещения достигает 5 % нижнего концентрационного предела распространения пламени',
          'Объем анализаторного помещения и технические характеристики систем вентиляции определяются исходя из условий, при которых в помещении в течение 1 ч должна быть исключена возможность образования взрывоопасной концентрации анализируемых продуктов',
        ],
        correctAnswers: [
          'Должна быть предусмотрена аварийная вентиляция, которая автоматически включается в случае, когда концентрация обращающихся веществ в воздухе помещения достигает 5 % нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63644101',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['10 минут', '15 минут', '30 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63644102',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63644103',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'В каналах (закрытых или с засыпкой песком)',
          'В грунте',
          'Надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63644104',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
          'Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63644105',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63644106',
        text:
          'В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?',
        answers: [
          'В наряде-допуске на проведение газоопасных работ',
          'В журнале ведения технологического процесса',
          'В технологическом регламенте и производственных инструкциях по рабочим местам',
          'В специально разрабатываемой эксплуатирующей организацией инструкции',
        ],
        correctAnswers: ['В наряде-допуске на проведение газоопасных работ'],
      },
      {
        code: '63644107',
        text:
          'Какие из документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63644108',
        text:
          'Что должно предшествовать пуску транспортных систем при транспортировке семян, жмыхов и шротов?',
        answers: [
          'Включение звуковой и световой сигнализаций',
          'Включение вентиляции',
          'Отключение звуковой сигнализации при включенной световой',
          'Голосовое оповещение',
        ],
        correctAnswers: ['Включение звуковой и световой сигнализаций'],
      },
      {
        code: '63644109',
        text:
          'В каком случае допускается постоянное пребывание людей в анализаторных помещениях объектов производств растительных масел?',
        answers: [
          'Если ограничители расхода и давления на пробоотборных устройствах размещаются вне анализаторного помещения',
          'Если анализаторы имеют защиту от воспламенения и взрыва по газовым линиям',
          'Если анализаторные помещения имеют предохраняющие конструкции',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63644110',
        text:
          'Какое требование к подшипниковым узлам шнековых питателей, ленточных конвейеров, норий, скребковых конвейеров, валов, винтовых конвейеров и перемешивающих устройств экстракторов, тостеров объектов производств растительных масел указано верно?',
        answers: [
          'Должны находиться в зоне видимости оператора установки',
          'Должны смазываться перед началом смены',
          'Должны проходить ежесменный контроль состояния',
          'Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов',
        ],
        correctAnswers: [
          'Должны выноситься из зоны, в которую возможно попадание и накопление перемещаемых продуктов, или должны иметь защиту от попадания в них этих продуктов',
        ],
      },
      {
        code: '63644111',
        text:
          'В каких местах не допускается размещать фланцевые соединения объектов производств растительных масел?',
        answers: [
          'В местах установки арматуры',
          'В местах подсоединения трубопроводов к аппаратам',
          'Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности',
          'На участках, на которых по условиям технологии требуется периодическая разборка для проведения чистки и ремонта трубопроводов',
        ],
        correctAnswers: [
          'Над местами постоянного прохода людей к рабочим площадкам при обеспечении достаточной степени герметичности',
        ],
      },
      {
        code: '63644112',
        text:
          'Какие требования установлены к блокировкам, устанавливаемым на электродвигателях технологического оборудования и транспортных элементах объектов производств растительных масел?',
        answers: [
          'При остановке технологического оборудования или транспортных элементов должна прекращаться подача всех материалов',
          'При остановке технологического оборудования или транспортных элементов должна срабатывать звуковая сигнализация',
          'При остановке технологического оборудования или транспортных элементов они должны все отключаться с одновременной подачей звукового и (или) светового сигнала',
        ],
        correctAnswers: [
          'При остановке технологического оборудования или транспортных элементов они должны все отключаться с одновременной подачей звукового и (или) светового сигнала',
        ],
      },
      {
        code: '63644113',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Защитными очками и защитной каской',
          'Воздушными изолирующими аппаратами',
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Всеми перечисленными средствами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63644114',
        text:
          'По какой категории надежности должно осуществляться электроснабжение электроприемников объектов производств растительных масел?',
        answers: [
          'Для блоков II, III категорий взрывопожароопасности предусматривается электроснабжение не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения КИПиА сжатым воздухом, систем противопожарной защиты - не ниже 1 категории',
          'Электроснабжение должно осуществляться только по 1 категории надежности',
          'Электроснабжение должно осуществляться по категории надежности не ниже 2',
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
        ],
        correctAnswers: [
          'Для блоков II, III категорий взрывопожароопасности предусматривается электроснабжение не ниже 2 категории надежности, а электроприемников систем оборотного водоснабжения, аварийной вентиляции, аварийного освещения, обеспечения КИПиА сжатым воздухом, систем противопожарной защиты - не ниже 1 категории',
        ],
      },
      {
        code: '63644115',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите 2 правильных варианта ответа.',
        answers: [
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63644116',
        text:
          'В каком случае температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений объектов производств растительных масел, должна быть не более 35 °C? Выберите 2 правильных варианта ответа.',
        answers: [
          'При температуре вспышки паров растворителя не выше 45 °C',
          'При температуре среды 100 °C и ниже',
          'При металлическом покрывном слое',
          'При температуре среды выше 100 °C',
        ],
        correctAnswers: [
          'При температуре вспышки паров растворителя не выше 45 °C',
          'При температуре среды 100 °C и ниже',
        ],
      },
      {
        code: '63644117',
        text:
          'Чем должно быть обеспечено технологическое оборудование, в котором возможно образование взрывоопасных смесей на объектах, связанных с производством растительных масел?',
        answers: [
          'Средствами пожаротушения',
          'Системами подачи флегматизатора (азота)',
          'Защитными кожухами',
          'Предупреждающими ограждениями',
        ],
        correctAnswers: ['Системами подачи флегматизатора (азота)'],
      },
      {
        code: '63644118',
        text:
          'При какой температуре шрота в четвертом чане чанного испарителя (тостера) отключается электропривод тостера или разгрузочного винтового конвейера тостера и все предшествующие транспортные элементы и оборудование, включая насосы растворителя?',
        answers: [
          'При падении температуры до 85 - 80 °С',
          'При температуре ниже 60 °С',
          'При падении температуры до 100 - 90 °С',
          'При температуре 70 °С',
        ],
        correctAnswers: ['При падении температуры до 85 - 80 °С'],
      },
      {
        code: '63644119',
        text:
          'Какое количество запорных арматур следует устанавливать на технологических трубопроводах взрывопожароопасных сред для герметичного отключения от коллектора агрегатов (технологических аппаратов) с рабочим давлением (далее - Pр) ≥ 4 МПа (40 кгс/см²)?',
        answers: [
          'Одну единицу запорной арматуры и дренажную арматуру с заглушкой',
          'Не регламентируется',
          'Две единицы запорной арматуры с дренажным устройством между ними',
          'Три единицы запорной арматуры',
        ],
        correctAnswers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
        ],
      },
      {
        code: '63644120',
        text:
          'Какие требования установлены к входам в сырьевые и шротовые склады напольного типа?',
        answers: [
          'Входы устраиваются через двери, сблокированные с электродвигателями конвейеров, обслуживающих выпускные самотеки, таким образом, чтобы при открытии дверей электродвигатель отключался',
          'Входы устраиваются через проемы, оснащенные световой и звуковой сигнализациями',
          'В соответствии с внутренней документацией, утвержденной руководителем организации',
        ],
        correctAnswers: [
          'Входы устраиваются через двери, сблокированные с электродвигателями конвейеров, обслуживающих выпускные самотеки, таким образом, чтобы при открытии дверей электродвигатель отключался',
        ],
      },
      {
        code: '63644121',
        text:
          'Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?',
        answers: [
          'Разовые (опытные) технологические регламенты',
          'Постоянные технологические регламенты',
          'Временные технологические регламенты',
          'Лабораторные технологические регламенты',
        ],
        correctAnswers: ['Разовые (опытные) технологические регламенты'],
      },
      {
        code: '63644122',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63644123',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63644124',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертиз промышленной безопасности',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63644125',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 12 часов',
          'Не менее 8 часов',
          'Не менее 4 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63644126',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63644127',
        text:
          'На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?',
        answers: [
          'На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа',
        ],
        correctAnswers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
        ],
      },
      {
        code: '63644128',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Главный инженер эксплуатирующей организации',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63644129',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63644130',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 10 минут',
          'Через 5 минут',
          'Через 30 минут',
          'Через 60 минуту',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63644131',
        text:
          'Какие действия, осуществление которых необходимо при остановке чанного испарителя (тостера) объектов производств растительных масел, указаны неверно?',
        answers: [
          'При разгрузке тостера необходимо производить тщательную зачистку чанов от остатков шрота',
          'Перед открытием люков тостера необходимо подать острый пар во все чаны',
          'Проверяется целостность контура заземления',
          'Отключается подача глухого пара',
        ],
        correctAnswers: ['Проверяется целостность контура заземления'],
      },
      {
        code: '63644132',
        text:
          'Какие требования к технологическим трубопроводам для растворителя, мисцеллы и паров растворителя указаны неверно?',
        answers: [
          'Технологические трубопроводы размещаются с учетом возможности проведения визуального контроля их состояния',
          'Технологические трубопроводы размещаются с учетом возможности выполнения работ по обслуживанию и ремонту',
          'Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом',
          'На маховиках или рукоятках арматуры должны быть нанесены стрелки, указывающие направления их вращения',
        ],
        correctAnswers: [
          'Технологические трубопроводы не должны иметь цифровых обозначений, маркировка трубопроводов осуществляется различной окраской, выполненной в соответствии с государственным стандартом',
        ],
      },
      {
        code: '63644133',
        text:
          'Что должно предшествовать пуску транспортных систем при транспортировке семян, жмыхов и шротов?',
        answers: [
          'Включение звуковой и световой сигнализаций',
          'Включение вентиляции',
          'Отключение звуковой сигнализации при включенной световой',
        ],
        correctAnswers: ['Включение звуковой и световой сигнализаций'],
      },
      {
        code: '63644134',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'В случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случаях изгиба болтов и шпилек',
          'При появления трещин, срыва или коррозионного износа резьбы',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63644135',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Средствами обеспечения питания инертными газами систем контрольно-измерительных приборов и автоматики',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63644136',
        text:
          'Какая должна быть высота решетчатого вертикального ограждения для бункеров и завальных ям объектов производств растительных масел?',
        answers: [
          'Не менее чем на 1,5 м выше уровня загрузки масличного сырья',
          'Не менее чем на 0,5 м выше уровня загрузки масличного сырья',
          'Не менее чем на 1 м выше уровня загрузки масличного сырья',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Не менее чем на 0,5 м выше уровня загрузки масличного сырья',
        ],
      },
      {
        code: '63644137',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
        ],
      },
      {
        code: '63644138',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К I группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63644139',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63644140',
        text:
          'Какое из обязательных требований к проектированию, техническому перевооружению или реконструкции действующих объектов производства растительных масел указано неверно?',
        answers: [
          'Установление категории помещений, зданий и наружных установок по взрывопожарной и пожарной опасности',
          'Осуществление оценки взрывоопасности технологических блоков',
          'Определение мест расположения насосного и компрессорного оборудования',
          'Установление вида системы защиты автоматическими установками тушения и обнаружения пожара в соответствии с нормами пожарной безопасности',
        ],
        correctAnswers: [
          'Определение мест расположения насосного и компрессорного оборудования',
        ],
      },
      {
        code: '63644141',
        text:
          'Какой флегматизатор используется для тостера при продувке экстракционной линии?',
        answers: [
          'Двуокись углерода',
          'Водяной пар',
          'Оксид углерода',
          'Хлор - и бромзамещенные углеводороды',
        ],
        correctAnswers: ['Водяной пар'],
      },
      {
        code: '63644142',
        text:
          'Какие из перечисленных требований к датчикам и пробоотборным устройствам анализаторов объектов производств растительных масел указаны верно?',
        answers: [
          'Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в загрузочном бункере экстрактора с уставками срабатывания 3 г/м³; 3...4 г/м³; 4 г/м³',
          'В случае отсутствия загрузочного бункера места установки, количество датчиков и уставки определяются техническим руководителем организации',
          'Для экстракторов карусельного типа должен устанавливаться датчик на пары растворителя в разгрузочном винтовом конвейере шрота с уставками срабатывания 5 г/м³; 5…10 г/м³; 10 г/м³',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63644143',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Только об организации, выполнившей функции генерального проектировщика',
          'Только об организации - разработчике технологического процесса',
          'Только об организации, выполнившей проектную документацию',
          'Только об организации - разработчике технологической части проектной документации',
          'Обо всех перечисленных организациях',
        ],
        correctAnswers: ['Обо всех перечисленных организациях'],
      },
      {
        code: '63644144',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63644145',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
          'Организацией - разработчиком процесса',
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Специализированной сторонней организацией',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63644146',
        text:
          'Какой клапан должен устанавливаться на трубопроводах, подводящих острый водяной пар в аппараты для отгонки растворителя, пропаривания, барботирования?',
        answers: [
          'Только редукционный клапан',
          'Только предохранительный клапан',
          'Только обратный клапан',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63644147',
        text:
          'В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: ['15 минут', '2 часов', '1 часа', '30 минут'],
        correctAnswers: ['1 часа'],
      },
      {
        code: '63644148',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Инспектором территориального органа Ростехнадзора',
          'Комиссией эксплуатирующей организации',
          'Разработчиком проекта',
          'Специализированной сторонней организацией',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63644149',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений при температуре среды выше 100 °C?',
        answers: [
          'Не более 45 °С',
          'Не более 100 °С',
          'Не более 55 °С',
          '60 °С',
        ],
        correctAnswers: ['Не более 45 °С'],
      },
      {
        code: '63644150',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63644151',
        text:
          'В каких теплообменных аппаратах объектов производств растительных масел допускается возможность взаимного проникновения пара (воды) и нагреваемого (охлаждаемого) продукта?',
        answers: [
          'Во всех перечисленных аппаратах',
          'В дистилляторах',
          'В подогревателях',
          'В оборудовании, где это предусматривается технологией',
        ],
        correctAnswers: [
          'В оборудовании, где это предусматривается технологией',
        ],
      },
      {
        code: '63644152',
        text:
          'Кто устанавливает значения параметров процесса и допустимых диапазонов их изменения, исключающих возможность возникновения опасных отклонений, способных стать причиной аварийной ситуации или аварии на объектах, связанных с производством растительных масел?',
        answers: [
          'Разработчик процесса',
          'Проектная организация',
          'Главный технолог',
          'Технический руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63644153',
        text:
          'Какое из перечисленных требований к процессам экстракции, отгонки растворителя из шрота и мисцеллы указано верно?',
        answers: [
          'Откачка экстракционного масла из цеховых емкостей должна производиться после проверки температуры вспышки',
          'Периодичность контроля за содержанием влаги в растворителе, растворителя в шроте, в масле и в сточной воде, сбрасываемой из бензоловушки, должна быть установлена в эксплуатационной инструкции, утвержденной руководителем организации',
          'Дублирующее управление задвижкой на магистрали подачи пара в экстракционный цех должно осуществляться внутри цеха',
          'Винтовые конвейеры, предназначенные для транспортирования шрота из тостера (шнекового испарителя) в пределах экстракционного цеха, во время работы должны быть открыты',
        ],
        correctAnswers: [
          'Откачка экстракционного масла из цеховых емкостей должна производиться после проверки температуры вспышки',
        ],
      },
      {
        code: '63644154',
        text:
          'В каком случае должна производиться чистка трубок конденсаторов объектов производств растительных масел?',
        answers: [
          'По графику, определенному руководителем организации',
          'По решению лица, ответственного за безопасное производство работ',
          'В установленных проектной документацией и документацией завода-изготовителя случаях',
        ],
        correctAnswers: [
          'В установленных проектной документацией и документацией завода-изготовителя случаях',
        ],
      },
      {
        code: '63644155',
        text:
          'Какое из перечисленных требований к нориям производств растительных масел указано неверно?',
        answers: [
          'Нории должны быть оснащены устройством, предотвращающим обратный ход ленты, цепи',
          'Нории должны быть оборудованы электроблокировкой, обеспечивающей автоматическое отключение электродвигателя при перегрузках нории',
          'Ковши норий для шрота должны выполняться из нержавеющей стали',
          'Все перечисленные ответы верны',
        ],
        correctAnswers: [
          'Ковши норий для шрота должны выполняться из нержавеющей стали',
        ],
      },
      {
        code: '63644156',
        text:
          'При каких параметрах экстракционной установки остановка цеха не требуется?',
        answers: [
          'При падении давления и температуры пара на коллекторах',
          'При падении давления воды в циркуляционной системе',
          'При падении давления сжатого воздуха ниже 0,2 МПа (2 кгс/см2) для питания систем контроля и управления системы противоаварийной защиты',
          'При повышении концентрации паров растворителя в воздушной среде цеха до 20% от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'При повышении концентрации паров растворителя в воздушной среде цеха до 20% от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63644157',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 3 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63644158',
        text:
          'Какими документами определяется и кем устанавливается периодичность проведения анализов загрязненности сжатого воздуха?',
        answers: [
          'Определяется проектом, устанавливается проектной организацией',
          'Определяется инструкцией по безопасности, устанавливается техническим руководителем организации',
          'Определяется нормативно-технической документацией, устанавливается организацией',
          'Определяется внутренней распорядительной документацией, устанавливается организацией',
        ],
        correctAnswers: [
          'Определяется нормативно-технической документацией, устанавливается организацией',
        ],
      },
      {
        code: '63644159',
        text:
          'Чем должно быть снабжено емкостное оборудование экстракционного цеха для исключения возможности переполнения растворителем, мисцеллой, маслом, на случай выхода из строя автоматических устройств?',
        answers: [
          'Реле уровня жидкости',
          'Переливным трубопроводом, не имеющим запорных устройств и оснащенным смотровым фонарем',
          'Поплавковым клапаном для емкостного оборудования',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'Переливным трубопроводом, не имеющим запорных устройств и оснащенным смотровым фонарем',
        ],
      },
      {
        code: '63644160',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны пройти инструктаж',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63644161',
        text:
          'Каким образом должна проводиться опрессовка системы с сильфонной арматурой?',
        answers: [
          'Давлением, не превышающим указанное в эксплуатационной документации, при температуре 20 °C, при этом арматура должна быть в открытом положении',
          'Сжатым воздухом при температуре 10 °C, при этом арматура должна быть в открытом положении',
          'Давлением, указанным в эксплуатационной документации при температуре 20 °C, при этом арматура должна быть в закрытом положении',
          'Сжатым воздухом при температуре 20 °C, при этом арматура должна быть в открытом положении',
        ],
        correctAnswers: [
          'Давлением, не превышающим указанное в эксплуатационной документации, при температуре 20 °C, при этом арматура должна быть в открытом положении',
        ],
      },
      {
        code: '63644162',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Только при деформации фланцев',
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63644163',
        text:
          'Каким документом определяется выбор способа подачи флегматизатора, его количества для обеспечения эффективности продувки и исключения возможности образования застойных зон на объектах, связанных с производством растительных масел?',
        answers: [
          'Технологическим регламентом',
          'Инструкцией по безопасному производству работ',
          'Техническим регламентом',
          'Проектной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63644164',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['40 °C', '45 °C', '50 °C', '55 °C'],
        correctAnswers: ['50 °C'],
      },
      {
        code: '63644165',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63644166',
        text:
          'Какое требования к условиям устойчивой работы объектов производств масел указано неверно?',
        answers: [
          'Обеспечение электроэнергией, паром, водой, сжатым воздухом, инертным газом (азотом)',
          'Проведение своевременного диагностирования технического состояния технологического оборудования',
          'Проведение своевременной диспансеризации технического персонала',
          'Обеспечение производства системами контроля и регулирования технологических параметров в заданном диапазоне',
        ],
        correctAnswers: [
          'Проведение своевременной диспансеризации технического персонала',
        ],
      },
      {
        code: '63644167',
        text:
          'Какое количество тормозных башмаков для закрепления различных типов цистерн должно быть установлено для закрепления железнодорожных цистерн при операциях слива, налива растворителя и масла?',
        answers: [
          'Не менее 6 на каждой цистерне',
          'Не менее 4 на каждой цистерне',
          'От 4 до 8 в зависимости от типа цистерны',
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
        ],
        correctAnswers: [
          'Определяется расчетом и указывается в инструкции по проведению сливоналивных работ',
        ],
      },
      {
        code: '63644168',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения лица, ответственного за проведение работ, и в соответствующих средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования',
          'Только с разрешения представителя газоспасательной службы, и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в соответствующих средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63644169',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите 2 правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63644170',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63644171',
        text:
          'Какое напряжение применяется для питания переносных светильников, работающих от аккумуляторных батарей, выполненных во взрывозащищенном исполнении, для внутреннего освещения аппаратов (резервуаров), заполненных растворителем, мисцеллой или парами растворителя?',
        answers: [
          'Не более 36 В',
          'Не более 42 В',
          'Не более 24 В',
          'Не более 12 В',
        ],
        correctAnswers: ['Не более 12 В'],
      },
      {
        code: '63644172',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Теплотехнический расчет',
          'Гидравлический расчет',
          'Расчет на прочность и устойчивость',
          'Статический и динамический прочностный расчет',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63644173',
        text:
          'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63644174',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 10 лет',
          'Не более 5 лет',
          'Не более 3 лет',
          'Не более 1 года',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63644175',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63644176',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 30 кг',
          'Массой более 35 кг',
          'Массой более 40 кг',
          'Массой более 50 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63644177',
        text:
          'Для каких производств допускается ручное управление по месту системами подачи флегматизирующих веществ?',
        answers: [
          'Для производств с блоками III категории',
          'Для производств со значением относительного энергетического потенциала Qв меньше 10',
          'Для производств с блоками I и II категорий',
        ],
        correctAnswers: [
          'Для производств со значением относительного энергетического потенциала Qв меньше 10',
        ],
      },
      {
        code: '63644178',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'П-образные компенсаторы',
          'Г-образные компенсаторы',
          'Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63644179',
        text:
          'Каким образом не допускается прокладывать трубопроводы растворителя и мисцеллы? Выберите 2 правильных варианта ответа.',
        answers: [
          'Под землей при наличии соответствующего обоснована проектной документацией',
          'Через строительные конструкции зданий в случае принятия мер, исключающих передачу дополнительных нагрузок на трубы',
          'Через бытовые, подсобные и административно-хозяйственные помещения',
          'Через распределительные электрические устройства, помещения щитов и пультов автоматизации, вентиляционные камеры',
        ],
        correctAnswers: [
          'Через бытовые, подсобные и административно-хозяйственные помещения',
          'Через распределительные электрические устройства, помещения щитов и пультов автоматизации, вентиляционные камеры',
        ],
      },
      {
        code: '63644180',
        text:
          'При какой периодичности использования трубопроводной арматуры ручной привод следует располагать на высоте не более 1,6 м от уровня пола или площадки, с которой ведется управление?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже одного раза в смену',
          'Не реже раз в месяц',
          'Расположение привода не зависит от частоты использования арматуры',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63644181',
        text:
          'Какая система отопления должна применяться в производственных помещениях объектов производств растительных масел?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система водяного отопления',
          'Система парового отопления',
          'В зависимости от проектной документации',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63644182',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63644183',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63644184',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63644185',
        text:
          'Какие действия должны быть выполнены при остановке чанного испарителя (тостера) во избежание возможного обугливания в нем шрота и возникновения аварийной ситуации?',
        answers: [
          'Отключение подачи глухого пара',
          'Загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)',
          'Подача острого пара во все чаны и загрузка чанного испарителя непроэкстрагированным материалом (лепестком, крупкой)',
        ],
        correctAnswers: ['Отключение подачи глухого пара'],
      },
      {
        code: '63644186',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Увеличить число наблюдающих и работающих',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Установить систему подачи условных сигналов',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63644187',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники подрядной организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Правилами не регламентируется',
          'Работники, указанные в наряде-допуске',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63644188',
        text:
          'Какой должна быть максимальная температура наружных поверхностей оборудования и (или) теплоизоляционных покрытий, расположенных в рабочей или обслуживаемой зоне помещений объектов производств растительных масел, при металлическом покрывном слое?',
        answers: ['55 °С', '45 °С', '60 °С', '50 °С'],
        correctAnswers: ['55 °С'],
      },
      {
        code: '63644189',
        text:
          'В каком случае допускается сброс сточных вод, содержащих растворитель, в производственную канализацию?',
        answers: [
          'В случае, если это предусмотрено технологическим регламентом и проектной документацией',
          'В аварийном случае',
          'Если сброс производится через гидравлические затворы',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63644190',
        text:
          'Какие из перечисленных требований, которым должны удовлетворять помещения управления объектов производств растительных масел, указаны неверно?',
        answers: [
          'Помещения должны иметь воздушное отопление и установки для кондиционирования воздуха',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации устанавливаемого оборудования и санитарным нормам',
          'Полы в помещениях управления должны быть холодными и электропроводными',
          'Средства или системы пожаротушения должны соответствовать требованиям нормативно-технической документации',
        ],
        correctAnswers: [
          'Полы в помещениях управления должны быть холодными и электропроводными',
        ],
      },
      {
        code: '63644191',
        text:
          'При какой минимальной глубине приямков, в которых размещено оборудование для транспортирования масличного сырья, они должны оборудоваться механической вентиляцией, закрываться крышками или ограждаться?',
        answers: ['0,3 м', '1 м', '0,5 м', '0,7 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63644192',
        text:
          'Какое из перечисленных пылеулавливающих оборудований объектов производств растительных масел допускается размещать вне зданий?',
        answers: [
          'Пылеулавливающее оборудование для очистки воздуха от масличной пыли после семеновеечных машин',
          'Пылеулавливающее оборудование для очистки воздуха от минеральной пыли после сепараторов',
          'Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси',
          'Пылеулавливающее оборудование для сухой очистки воздуха',
        ],
        correctAnswers: [
          'Пылеулавливающее оборудование для мокрой очистки пылевоздушной смеси',
        ],
      },
      {
        code: '63644193',
        text:
          'Что должно быть предусмотрено для обслуживания оборудования объектов производств растительных масел, требующего нахождения или перемещения лиц, работающих выше уровня пола?',
        answers: [
          'Стационарные площадки и лестницы к ним',
          'Подъемники',
          'Правилами не регламентируется, определяется проектом',
          'Приставные лестницы',
        ],
        correctAnswers: ['Стационарные площадки и лестницы к ним'],
      },
      {
        code: '63644194',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии главного инженера',
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии начальника цеха',
          'В присутствии рабочего, который будет спускаться в емкость, и наблюдающего',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63644195',
        text:
          'С учетом какой документации должны осуществляться выбор систем контроля, управления и противоаварийной автоматической защиты, а также систем связи и оповещения об аварийных ситуациях объектов производств растительных масел?',
        answers: [
          'Только правил безопасности химически опасных производственных объектов',
          'Только документации на ХОПО',
          'Только действующей нормативно-технической документации',
          'С учетом всей перечисленной документации',
        ],
        correctAnswers: ['С учетом всей перечисленной документации'],
      },
      {
        code: '63644196',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Проектной организацией',
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63644197',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Расчет на прочность',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63644198',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Лицом, утверждающим технологический регламент',
          'Комиссией организации под председательством представителя проектной организации',
          'Технологическим руководителем организации',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63644199',
        text:
          'Какие устройства, оснащаемые фильтр для мисцеллы, указаны неверно?',
        answers: [
          'Предохранительный клапан с отводом мисцеллы в сборник нефильтрованной мисцеллы',
          'Штуцер с манометром для продувки фильтра инертным газом',
          'Смотровой фонарь на сливной трубе фильтрованной мисцеллы',
          'Вакуумметр на сливной трубе фильтрованной мисцеллы',
        ],
        correctAnswers: ['Вакуумметр на сливной трубе фильтрованной мисцеллы'],
      },
      {
        code: '63644200',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63644201',
        text:
          'Какие требования к использованию железнодорожных цистерн с растворителями на объектах производств растительных масел указаны неверно?',
        answers: [
          'Для слива растворителя из цистерн могут быть использованы сливоналивные механизированные стояки со стационарной площадкой, лестницей и откидным мостиком для обслуживания горловины цистерны',
          'Для слива растворителя из цистерн должны использоваться установки, предназначенные для нижнего слива-налива нефти и нефтепродуктов из железнодорожных цистерн',
          'Допускается использование железнодорожных цистерн с растворителями, находящихся на железнодорожных путях, в качестве стационарных складских (расходных) емкостей в качестве складских расходных емкостей',
          'Цистерны, находящиеся под сливом (наливом), должны быть заземлены',
        ],
        correctAnswers: [
          'Допускается использование железнодорожных цистерн с растворителями, находящихся на железнодорожных путях, в качестве стационарных складских (расходных) емкостей в качестве складских расходных емкостей',
        ],
      },
      {
        code: '63644202',
        text:
          'Что не допускается при остановках экстракционной линии объектов производств растительных масел?',
        answers: [
          'Только выключать принудительную вентиляцию цеха',
          'Только прекращать циркуляцию охлаждающей воды, рассола или абсорбента в технологических аппаратах',
          'Только сбрасывать воду из водоотделителя в бензоловушку без дополнительной обработки ее в шламовыпаривателе и охлаждения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63644203',
        text:
          'Какие из указанных действий, которые должны быть предприняты при появлении металлического стука и скрежета в чанах жаровни, указаны верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Сообщить ответственному за безопасное производство работ',
          'Прекратить подачу пара',
          'Установить контроль за температурой мезги',
          'Немедленно остановить жаровню',
          'Немедленно провести ревизию чанов',
        ],
        correctAnswers: [
          'Прекратить подачу пара',
          'Немедленно остановить жаровню',
        ],
      },
      {
        code: '63644204',
        text:
          'Какие из указанных действий, которые должны быть предприняты при появлении металлического стука и скрежета в чанах жаровни, указаны неверно?',
        answers: [
          'Немедленно остановить жаровню',
          'Немедленно провести ревизию чанов',
          'Освободить жаровню от мезги',
          'Прекратить подачу пара',
        ],
        correctAnswers: ['Немедленно провести ревизию чанов'],
      },
      {
        code: '63644205',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
        ],
        correctAnswers: [
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
        ],
      },
      {
        code: '63644206',
        text:
          'При каких условиях допускается проводить технологический взрывоопасный процесс в области критических значений температур на объектах, связанных с производством растительных масел?',
        answers: [
          'В случае принятия мер, исключающих или предотвращающих появление источников зажигания',
          'При наличии у персонала средств индивидуальной защиты',
          'При условии наличия наряда-допуска',
          'Не допускается ни при каких условиях',
        ],
        correctAnswers: [
          'В случае принятия мер, исключающих или предотвращающих появление источников зажигания',
        ],
      },
      {
        code: '63644207',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63644208',
        text:
          'Какие действия персонала предусмотрены в случае повышения температуры масличного сырья выше допустимой, указанной в технологическом регламенте объектов производств растительных масел?',
        answers: [
          'Следует сообщить главному технологу организации и вызвать пожарную охрану организации',
          'Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку',
          'Следует сообщить главному технологу организации и сделать запись в журнале эксплуатации и ремонта',
          'Следует вызвать пожарную охрану организации',
        ],
        correctAnswers: [
          'Следует применить активное вентилирование, произвести перекачку семян из одного силоса (бункера) в другой, с площадки на площадку',
        ],
      },
      {
        code: '63644209',
        text: 'Какой устанавливается срок действия "Накопительной ведомости"?',
        answers: ['6 месяцев', 'Один год', '1 месяц', '3 месяца'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63644210',
        text:
          'В каком количестве должно осуществляться хранение жмыха в прессовом цехе при внезапной остановке экстракционного цеха?',
        answers: [
          'Не превышающем рабочий объем жаровен',
          'Предусмотренным технологическим регламентом',
          'Не превышающем 2-кратный рабочий объем жаровен',
          'По усмотрению лица, ответственного за безопасное производство работ',
        ],
        correctAnswers: ['Не превышающем рабочий объем жаровен'],
      },
      {
        code: '63644211',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Только прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Только прошедшие обучение приемам и методам проведения работ',
          'Только не моложе 18 лет',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63644212',
        text:
          'Под каким разрежением должен находиться экстрактор многократного орошения?',
        answers: [
          '0,05-0,10 кПа (5-10 мм вод. ст.)',
          '0,10-0,15 кПа (10-15 мм вод. ст.)',
          '0,02-0,05 кПа (2-5 мм вод. ст.)',
          '0,15-0,30 кПа (15-30 мм вод. ст.)',
        ],
        correctAnswers: ['0,05-0,10 кПа (5-10 мм вод. ст.)'],
      },
      {
        code: '63644213',
        text:
          'Для каких блоков объектов производств растительных масел допускается применение автоматических средств контроля параметров, значения которых определяют взрывоопасность процесса, и ручного регулирования?',
        answers: [
          'Для блоков, имеющих относительный энергетический потенциал Qв меньше 10',
          'Для блоков III категории',
          'Для блоков I категории',
          'Для блоков II категории',
        ],
        correctAnswers: [
          'Для блоков, имеющих относительный энергетический потенциал Qв меньше 10',
        ],
      },
      {
        code: '63644214',
        text:
          'К какой категории взрывопожароопасности относятся помещения, в которых размещаются емкости для промежуточного хранения экстракционного масла, объектов производств растительных масел?',
        answers: ['А', 'Б', 'В', 'Г'],
        correctAnswers: ['А'],
      },
      {
        code: '63644215',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Должны выполняться все перечисленные мероприятия',
          'Только испытание на прочность пробным давлением',
          'Только испытания на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
        ],
        correctAnswers: ['Только испытания на плотность'],
      },
      {
        code: '63644216',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом главного инженера организации (технического директора, директора по производству)',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом комиссии организации',
          'Приказом руководителя организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63644217',
        text:
          'Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63644218',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '36 месяцев',
          '3 месяца',
          '24 месяца',
          '12 месяцев',
          '6 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63644219',
        text:
          'Какие из функций, которые должна обеспечивать автоматизированная система управления технологическими процессами объектов производств растительных масел, указаны верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты',
          'Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии',
          'Связь оператора установки с начальником цеха и техническим руководителем организации',
          'Выдача информации о состоянии безопасности на объекте в надзорный орган',
        ],
        correctAnswers: [
          'Регистрация срабатывания и контроль за работоспособным состоянием средств противоаварийной защиты',
          'Постоянный анализ изменения параметров в сторону критических значений и прогнозирование возможной аварии',
        ],
      },
      {
        code: '63644220',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведения освидетельствования трубопровода',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63644221',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'Только для обеспечения энергоэффективности',
          'Только в случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Только для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63644222',
        text:
          'Какое из перечисленных требований к прокладке и устройству технологических трубопроводов указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Технологические трубопроводы в производственных помещениях должны прокладываться закрыто',
          'Сварные и разъемные соединения трубопроводов внутри футляров или гильз допускаются в соответствии с проектом',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
        ],
        correctAnswers: [
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
        ],
      },
      {
        code: '63644223',
        text:
          'Какими типами предохранительных клапанов необходимо оборудовать шроторазгрузитель, микроциклоны и шротопровод объектов производств растительных масел?',
        answers: [
          'Пружинного типа',
          'Рычажно-грузового типа',
          'Магнитно-пружинного типа',
          'Мембранного типа',
        ],
        correctAnswers: ['Мембранного типа'],
      },
      {
        code: '63644224',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите 2 правильных варианта ответа.',
        answers: [
          'Начальник производственно-технического (технического) отдела организации',
          'Начальник производства',
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
        correctAnswers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
      },
      {
        code: '63644225',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 20 лет',
          'Не более 3 лет',
          'Не более 5 лет',
          'Не более 10 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63644226',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63644227',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63644228',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала (дочернего общества)',
          'С газоспасательной службой и службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63644229',
        text:
          'За какими устройствами (системами) необходимо осуществлять постоянный контроль в процессе эксплуатации резервуаров хранилищ растворителя на объектах производств растительных масел?',
        answers: [
          'За системой рекуперации растворителя экстракционного цеха',
          'За исправностью дыхательных клапанов и огнепреградителей',
          'За средствами пожаротушения и сигнализацией',
        ],
        correctAnswers: [
          'За исправностью дыхательных клапанов и огнепреградителей',
        ],
      },
      {
        code: '63644230',
        text:
          'С какой целью предусматривается подача пара в месте расположения градирен на объектах производств растительных масел?',
        answers: [
          'Только с целью продувки сопел',
          'Только с целью размораживания льда в зимнее время',
          'Только с целью продувки трубопроводов',
          'Для достижения всех перечисленных целей',
        ],
        correctAnswers: ['Для достижения всех перечисленных целей'],
      },
      {
        code: '63644231',
        text:
          'В каком случае допускается укладка технологических трубопроводов в два яруса и более?',
        answers: [
          'Диаметром до 500 мм включительно',
          'Диаметром до 400 мм включительно',
          'Диаметром до 300 мм включительно',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Диаметром до 300 мм включительно'],
      },
      {
        code: '63644232',
        text:
          'Какая устанавливается максимальная температура масличного сырья в бункерах складов и силосных ячейках элеваторов объектов производств растительных масел?',
        answers: ['40 °С', '50 °С', '35 °С', '30 °С'],
        correctAnswers: ['40 °С'],
      },
      {
        code: '63644233',
        text:
          'Каким документом определяются минимально и максимально допустимые уровни экстрагируемого материала в зависимости от типа экстрактора, вида экстрагируемого материала и конкретных условий?',
        answers: [
          'Проектной документацией и технологическим регламентом',
          'Правилами безопасности химически опасных производственных объектов',
          'Проектом систем противоаварийной защиты и автоматизированной системы управления технологическими процессами',
          'Стандартом организации',
        ],
        correctAnswers: [
          'Проектной документацией и технологическим регламентом',
        ],
      },
      {
        code: '63644234',
        text:
          'Каким из перечисленных требований должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Иметь документы о прохождении аттестации',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63644235',
        text:
          'Какое из перечисленных требований к устройству площадок и лестниц для обслуживания оборудования объектов производств растительных масел, требующего нахождения или перемещения лиц, работающих выше уровня пола указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'На высоте 0,5 м от пола (площадки) перила должны иметь дополнительное продольное ограждение',
          'Площадки, расположенные на высоте 0,8 м и более над уровнем пола, открытые приямки, а также открытые монтажные проемы должны иметь по периметру перила высотой не менее 1,0 м',
          'Вертикальные стойки перил должны иметь шаг не более 1,0 м',
          'Настилы площадок должны иметь сплошную бортовую полосу высотой не менее 0,5 м',
        ],
        correctAnswers: [
          'На высоте 0,5 м от пола (площадки) перила должны иметь дополнительное продольное ограждение',
          'Площадки, расположенные на высоте 0,8 м и более над уровнем пола, открытые приямки, а также открытые монтажные проемы должны иметь по периметру перила высотой не менее 1,0 м',
        ],
      },
      {
        code: '63644236',
        text:
          'В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Выберите 2 правильных варианта ответа.',
        answers: [
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если такое решение принято технологической службой организации, производства, отделения, установки',
          'Если в тексте технологического регламента обнаружены подчистки и поправки',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'При сроке освоения производства более года',
        ],
        correctAnswers: [
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
        ],
      },
      {
        code: '63644237',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск не более чем на 1 дневную смену',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63644238',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63644239',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63644240',
        text:
          'Для каких производств управление системами подачи флегматизирующих веществ на объектах, связанных с производством растительных масел, осуществляется дистанционно?',
        answers: [
          'Для производств с блоками I категории',
          'Для производств с блоками III категории',
          'Для производств со значением относительного энергетического потенциала Qв меньше 10',
          'Для производств с блоками II категории',
        ],
        correctAnswers: ['Для производств с блоками III категории'],
      },
      {
        code: '63644241',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'Со средой 1-ой группы',
          'С температурой стенки ниже минус 40 °C',
          'С температурой стенки выше 300 °C',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63644242',
        text:
          'Кем определяются порядок, способы и периодичность уборки пыли в производственных помещениях объектов производств растительных масел?',
        answers: [
          'Техническим руководителем организации',
          'Проектной организацией',
          'Сторонней специализированной организацией',
          'Руководителем организации',
        ],
        correctAnswers: ['Руководителем организации'],
      },
      {
        code: '63644243',
        text:
          'Какой характеристикой должна обеспечиваться необходимая степень герметичности разъемного соединения в течение межремонтного периода эксплуатации технологической схемы объектов производств растительных масел?',
        answers: [
          'Только монтажом фланцевых соединений',
          'Только материалом прокладок',
          'Только конструкцией уплотнения',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63644244',
        text:
          'При какой минимальной остановке шнекового пресса необходимо очистить питатель и пресс от мезги?',
        answers: [
          'На 10 минут',
          'На 5 минут',
          'На 30 минут',
          'При любой внезапной остановке',
        ],
        correctAnswers: ['На 10 минут'],
      },
      {
        code: '63644245',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63644246',
        text:
          'Какое из перечисленных требований к обслуживанию и ремонту технологического оборудования и трубопроводов противоречит Правилам безопасности химически опасных производственных объектов?',
        answers: [
          'Все аппараты экстракционного цеха должны отключаться от системы технологических трубопроводов с помощью стандартных пронумерованных заглушек с видимыми хвостовиками',
          'После завершения указанных операций с аппаратов снимаются крышки люков-лазов и проводятся операции проветривания',
          'Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом',
          'Теплообменные поверхности аппаратов (дистилляторов, конденсаторов, теплообменников) должны очищаться от нагара путем щелочения и промывки водой',
        ],
        correctAnswers: [
          'Перед началом ремонтных работ аппараты и технологические трубопроводы экстракционного цеха должны продуваться инертным газом',
        ],
      },
      {
        code: '63644247',
        text:
          'Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?',
        answers: [
          'Лицо, ответственное за проведение газоопасных работ',
          'Лицо, ответственное за осуществление производственного контроля',
          'Начальник цеха',
          'Главный инженер',
        ],
        correctAnswers: ['Лицо, ответственное за проведение газоопасных работ'],
      },
      {
        code: '63644248',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
    ],
    63643: [
      {
        code: '63643000',
        text:
          'Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Периодический, не реже двух раз в смену, контроль состояния воздушной среды в пределах объекта',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Цикличный контроль параметров процесса и управление режимом для поддержания их регламентированных значений',
        ],
        correctAnswers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
      },
      {
        code: '63643001',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'П- образные',
          'Г- образные компенсаторы',
          'Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63643002',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63643003',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система водяного отопления',
          'Система парового отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63643004',
        text:
          'Какому из приведенных ниже требований может не соответствовать орган аварийной остановки технического устройства?',
        answers: [
          'Останавливать техническое устройство быстро, не создавая опасности',
          'Быть ясно идентифицируемым и легко доступным',
          'Сопровождать остановку технического устройства включением звукового или светового сигнала',
          'Возвращаться в исходное положение, не приводя к пуску технического устройства',
          'Быть красного цвета, отличаться формой и размерами от других органов управления',
        ],
        correctAnswers: [
          'Сопровождать остановку технического устройства включением звукового или светового сигнала',
        ],
      },
      {
        code: '63643005',
        text:
          'К каким последствиям не должно приводить временное отключение источника энергоснабжения технического устройства?',
        answers: [
          'Невыполнению уже выданной команды на остановку',
          'Падению и выбрасыванию подвижных частей технического устройства и закрепленных на нем предметов, заготовок, инструмента',
          'Снижению эффективности защитных устройств',
          'Самопроизвольному пуску технического устройства при восстановлении энергоснабжения',
          'Невыполнению хотя бы одной из перечисленных функций',
        ],
        correctAnswers: ['Невыполнению хотя бы одной из перечисленных функций'],
      },
      {
        code: '63643006',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны быть оборудованы устройствами для улавливания аварийных стоков',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63643007',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Акты испытания технологического трубопровода на прочность и плотность',
          'Акты периодического наружного осмотра технологического трубопровода',
          'Заключение о техническом состоянии арматуры',
          'Заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63643008',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты',
          'Средствами автоматического управления объектом по действующим программам',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63643009',
        text:
          'Просеивание каких из перечисленных взрывчатых веществ должно быть механизировано и проводиться под водой?',
        answers: [
          'Тетразена',
          'Всех перечисленных взрывчатых веществ',
          'Азида натрия',
          'Гремучей ртути',
          'ТНРС',
        ],
        correctAnswers: ['Гремучей ртути'],
      },
      {
        code: '63643010',
        text:
          'В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте',
          'Только в исходных данных на проектирование и технологическом регламенте',
          'Только в проектной документации',
          'Только в технологическом регламенте',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте',
        ],
      },
      {
        code: '63643011',
        text:
          'Какая должна быть высота штабеля и верхней полки стеллажа для ВВ и изделий, их содержащих?',
        answers: [
          'Не более 1,65 м от пола',
          'Не более 1,7 м от пола',
          'Не более 1,75 м от пола',
          'Не более 1,8 м от пола',
        ],
        correctAnswers: ['Не более 1,65 м от пола'],
      },
      {
        code: '63643012',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 45 °C внутри помещений и 60 °C на наружных установках',
          'Не более 50 °C внутри помещений и 60 °C на наружных установках',
          'Не более 50 °C внутри помещений и 65 °C на наружных установках',
          'Не более 55 °C внутри помещений и 65 °C на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °C внутри помещений и 60 °C на наружных установках',
        ],
      },
      {
        code: '63643013',
        text:
          'Техническая документация, которая прилагается при поставке оборудования потребителю, не включает:',
        answers: [
          'Требования к персоналу',
          'Перечень критических отказов, возможных ошибок персонала (пользователя), приводящих к аварийным режимам оборудования, и действий, предотвращающих указанные ошибки',
          'Назначенные показатели срока службы и (или) назначенный ресурс',
          'Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах',
        ],
        correctAnswers: [
          'Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах',
        ],
      },
      {
        code: '63643014',
        text:
          'С какой периодичностью должен быть установлен визуальный или автоматический контроль температуры теплоносителя с записью показаний температуры в соответствующем журнале при сушке и полимеризации пиротехнических составов и изделий?',
        answers: [
          'Не реже одного раза в два часа',
          'Не реже одного раза в час',
          'Не реже двух раз в сутки',
          'Не реже одного раза в сутки',
        ],
        correctAnswers: ['Не реже одного раза в час'],
      },
      {
        code: '63643015',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Нарушение энергообеспечения',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63643016',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности - не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63643017',
        text:
          'Какие существуют виды ремонтных работ согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: [
          'Только внеплановые (неплановые) ремонтные работы',
          'Только плановые ремонтные работы',
          'Только аварийно-восстановительные ремонтные работы',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63643018',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект в целях приведения его в соответствие требованиям Федеральных норм и правил в области промышленной безопасности химически опасных производственных объектов?',
        answers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в документацию или разработку документации вновь',
          'Провести экспертизу промышленной безопасности',
          'Провести реконструкцию химически опасных производственных объектов',
          'Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в документацию или разработку документации вновь',
        ],
      },
      {
        code: '63643019',
        text:
          'Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Обеспечение селективности защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Наличие возможности синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63643020',
        text:
          'Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством допуска к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Посредством создания специализированных центров обучения и подготовки для производственного персонала',
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
          'Посредством приобретения компьютерных тренажеров, включающих приближенные к реальным динамические модели процессов и средств управления',
        ],
        correctAnswers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
      },
      {
        code: '63643021',
        text:
          'Что является идентификационным признаком оборудования для работы во взрывоопасных средах?',
        answers: [
          'Только наличие маркировки взрывозащиты',
          'Только наличие сертификата взрывозащиты, выданного аккредитованным органом по сертификации',
          'Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование',
        ],
        correctAnswers: [
          'Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование',
        ],
      },
      {
        code: '63643022',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'По решению территориального органа Ростехнадзора',
          'Если это определено и обосновано научно-исследовательской организацией',
          'Если это определено и обосновано разработчиком проекта',
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63643023',
        text:
          'В каком случае резку баллиститного порохового заряда допускается проводить только дистанционно автоматическим ножом при отсутствии людей?',
        answers: [
          'Если пороховой заряд диаметром 90 мм и более',
          'Если пороховой заряд диаметром 105 мм и более',
          'Во всех перечисленных случаях',
          'Если пороховой заряд диаметром 85 мм и более',
        ],
        correctAnswers: ['Если пороховой заряд диаметром 105 мм и более'],
      },
      {
        code: '63643024',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды и требований действующих нормативных правовых актов',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от вида взрывозащиты',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: [
          'В зависимости от свойств перемещаемой среды и требований действующих нормативных правовых актов',
        ],
      },
      {
        code: '63643025',
        text:
          'Какую относительную влажность воздуха необходимо поддерживать в помещениях, связанных с переработкой сухих ВВ, если другое не предусмотрено проектной и технологической документацией?',
        answers: [
          'Не менее 75%',
          'Не менее 65%',
          'Не менее 40%',
          'Не менее 55%',
        ],
        correctAnswers: ['Не менее 65%'],
      },
      {
        code: '63643026',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63643027',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на него обозначение номера по технологической схеме должно быть закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63643028',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63643029',
        text:
          'Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?',
        answers: [
          'Технической документацией производителя',
          'Проектной документацией',
          'Исходными данными на проектирование',
          'Технологическим регламентом',
        ],
        correctAnswers: ['Технической документацией производителя'],
      },
      {
        code: '63643030',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63643031',
        text:
          'В каких случаях при проведении ремонтных работ наряд-допуск подлежит переоформлению, а работы должны быть приостановлены?',
        answers: [
          'Только если произведена замена непосредственного руководителя работ подрядной организации',
          'Только если нарушены меры, обеспечивающие безопасность проведения работ',
          'Только если изменены объемы и характер работы, влекущие за собой изменение схем отключения и условия работы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63643032',
        text:
          'Какое из перечисленных требований допускается при снаряжении, сборке и разборке боеприпасов с пороховыми реактивными двигателями, пороховых реактивных двигателей, газогенераторов и ракет? Выберите два правильных варианта ответа.',
        answers: [
          'Проведение сборочных операций, включая операции вставки зарядов твердого ракетного топлива и воспламенителя, на потоке сборки, расположенном в одном помещении, если это предусмотрено технологической документацией',
          'Одновременное проведение работ по снаряжению и утилизации в одном помещении, если это предусмотрено технологической документацией',
          'Проведение сборочных операций на одном потоке, расположенном в одном помещении, включая операции вставки порохового заряда и воспламенителя, технологические операции по сборке комплектующих элементов, содержащих ВВ, пиротехнические составы и пороховые заряды, при соблюдении специальных мер безопасности, изложенных в технологической документации',
          'Операции подготовки зарядов ракетного твердого топлива и воспламенителей в одном помещении, если это предусмотрено технологической документацией',
        ],
        correctAnswers: [
          'Проведение сборочных операций, включая операции вставки зарядов твердого ракетного топлива и воспламенителя, на потоке сборки, расположенном в одном помещении, если это предусмотрено технологической документацией',
          'Проведение сборочных операций на одном потоке, расположенном в одном помещении, включая операции вставки порохового заряда и воспламенителя, технологические операции по сборке комплектующих элементов, содержащих ВВ, пиротехнические составы и пороховые заряды, при соблюдении специальных мер безопасности, изложенных в технологической документации',
        ],
      },
      {
        code: '63643033',
        text:
          'Что должна включать маркировка, нанесенная на оборудование, предназначенное для работы во взрывоопасных средах?',
        answers: [
          'Обозначение типа оборудования',
          'Номер сертификата соответствия',
          'Все перечисленное',
          'Заводской номер',
          'Наименование изготовителя или его зарегистрированный товарный знак',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63643034',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63643035',
        text:
          'Какие действия следует выполнить для обеспечения безопасного проведения подготовительных работ и газоопасных работ? Выберите два правильных варианта ответа.',
        answers: [
          'Провести инструктаж работникам подрядных организаций об основных опасных факторах производства',
          'Лицу, ответственному за проведение газоопасной работы, проверить полноту и качество выполненных подготовительных работ после их окончания',
          'Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)',
          'Во время проведения подготовительных работ выявить участки возможного выделения в зону работ взрывопожароопасных веществ с целью дальнейшего совмещения газоопасных и огневых работ в одном помещении',
        ],
        correctAnswers: [
          'Провести инструктаж работникам подрядных организаций об основных опасных факторах производства',
          'Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)',
        ],
      },
      {
        code: '63643036',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63643037',
        text:
          'Может ли руководство (инструкция) по эксплуатации технического устройства представляться его изготовителем на электронном носителе?',
        answers: [
          'Может, если техническое устройство не бытового назначения',
          'Не может',
          'Может, если это предусмотрено Федеральными нормами и правилами по промышленной безопасности, распространяющимися на данный тип технических устройств',
          'Не может, если на этом настаивает будущий владелец технического устройства',
          'Может, если комплект поставки технического устройства включает ее наличие и на бумажном носителе',
        ],
        correctAnswers: [
          'Может, если техническое устройство не бытового назначения',
        ],
      },
      {
        code: '63643038',
        text: 'Кто утверждает наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель структурного подразделения',
          'Руководитель газоспасательной службы',
          'Руководитель службы производственного контроля',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63643039',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и подобных им сооружениях?',
        answers: [
          'C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'C руководителями службы производственного контроля',
          'C руководителями аварийно-спасательных служб',
          'C руководителями службы охраны труда и санитарными службами',
        ],
        correctAnswers: [
          'C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63643040',
        text:
          'Что является единственным документом, подтверждающим соответствие технического устройства требованиям ТР ТС 010/2011?',
        answers: [
          'Декларация о соответствии',
          'Сертификат соответствия',
          'Сертификат или декларация о соответствии',
          'Заключение экспертизы промышленной безопасности',
          'Сертификат или декларация о соответствии и заключение экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Сертификат или декларация о соответствии'],
      },
      {
        code: '63643041',
        text:
          'При какой периодичности использования трубопроводной арматуры ручной привод следует располагать на высоте не более 1,6 м от уровня пола или площадки, с которой ведется управление?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Расположение привода не зависит от частоты использования арматуры',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63643042',
        text:
          'Какие должны быть проходы при размещении взрывчатых веществ и изделий, их содержащих, в штабелях и на стеллажах?',
        answers: [
          'Для осмотра должны быть не менее 0,9 м, для погрузки и разгрузки не менее 1,3 м',
          'Для осмотра должны быть не менее 0,5 м, для погрузки и разгрузки не менее 1,2 м',
          'Для осмотра должны быть не менее 0,7 м, для погрузки и разгрузки не менее 1,5 м',
          'Для осмотра должны быть не менее 1 м, для погрузки и разгрузки не менее 2 м',
        ],
        correctAnswers: [
          'Для осмотра должны быть не менее 0,7 м, для погрузки и разгрузки не менее 1,5 м',
        ],
      },
      {
        code: '63643043',
        text:
          'Какое время срабатывания системы защиты установлено в федеральных нормах и правилах?',
        answers: [
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
          'Не более 60 секунд',
        ],
        correctAnswers: [
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
        ],
      },
      {
        code: '63643044',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63643045',
        text:
          'На какое оборудование распространяется ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'На оборудование, при эксплуатации которого опасность взрыва возникает только из-за наличия взрывоопасных веществ и нестойких химических соединений',
          'На оборудование для бытового и непроизводственного применения в условиях, когда взрывоопасная среда образуется вследствие непредвиденной утечки горючего газа',
          'Только на электрическое (электрооборудование), включая Ex-компонентные',
          'На электрическое (электрооборудование), включая Ex-компоненты, и неэлектрическое оборудование для работы во взрывоопасных средах',
        ],
        correctAnswers: [
          'На электрическое (электрооборудование), включая Ex-компоненты, и неэлектрическое оборудование для работы во взрывоопасных средах',
        ],
      },
      {
        code: '63643046',
        text:
          'Какие из перечисленных действий допускаются во время работы кислотоотжимной центрифуги?',
        answers: [
          'Открывание крышки центрифуги',
          'Нахождение непосредственно у работающей центрифуги',
          'Все ответы неверны',
          'Превышение предусмотренного технологическим регламентом времени отжима на центрифуге',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63643047',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 лет',
          'Не реже одного раза в 10 лет',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63643048',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63643049',
        text:
          'В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах параллельно с рычажно-грузовым или пружинным предохранительным клапаном?',
        answers: [
          'Если необходимо увеличение пропускной способности систем сброса давления рычажно-грузового (пружинного) предохранительного клапана',
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
          'Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительного клапана',
          'Установка мембранных предохранительных устройств параллельно с рычажно-грузовыми или пружинными предохранительными клапанами не допускается',
        ],
        correctAnswers: [
          'Если необходимо увеличение пропускной способности систем сброса давления рычажно-грузового (пружинного) предохранительного клапана',
        ],
      },
      {
        code: '63643050',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63643051',
        text: 'Что указывается на схеме места выполнения огневых работ?',
        answers: [
          'Все перечисленное',
          'Только места размещения сварочного и другого оборудования для проведения огневых работ, места установки предупредительных знаков',
          'Только место выполнения огневых работ и границы опасной зоны, места отбора проб воздуха',
          'Только месторасположение автомобильной техники',
          'Только места расположения запорной арматуры и установки заглушек на технологическом оборудовании и трубопроводах',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63643052',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63643053',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'Над дверными и оконными проемами в случае применения спирально-навитых прокладок',
          'В местах ввода в технологические здания и сооружения',
          'Над местами, предназначенными для проезда транспорта на высоте не менее 5 м',
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63643054',
        text: 'Кто разрабатывает перечень газоопасных работ?',
        answers: [
          'Каждое структурное подразделение эксплуатирующей организации',
          'Служба производственного контроля эксплуатирующей организации',
          'Газоспасательная служба',
          'Подразделения, которые обязаны готовить объекты к газоопасным работам',
        ],
        correctAnswers: [
          'Каждое структурное подразделение эксплуатирующей организации',
        ],
      },
      {
        code: '63643055',
        text:
          'В каком случае наряд-допуск на проведение ремонтных работ подлежит переоформлению, а ремонтные работы должны быть приостановлены?',
        answers: [
          'Нарушены меры, обеспечивающие безопасность проведения работ',
          'Изменены объемы и характер работы, влекущие за собой изменение схем отключения и условия работы',
          'Произведена замена непосредственного руководителя работ подрядной организации',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63643056',
        text:
          'Какие меры обеспечения безопасности при проведении ремонтных работ указаны неверно?',
        answers: [
          'При выполнении ремонтных работ не следует допускать загромождения проездов к пожарным гидрантам, а также к территории ремонтируемого объекта оборудованием, материалами, металлоломом',
          'При изменении обстановки в ремонтной зоне (возникновение парений, утечки газа или жидких продуктов) или на расположенном вблизи действующем объекте исполнители ремонтных работ должны доложить непосредственному руководителю работ или лицу, ответственному за подготовку объекта к ремонту. Приостанавливать работу допускается только с разрешения непосредственного руководителя работ',
          'Не допускается использовать металлические и железобетонные конструкции зданий, сооружений в качестве опор при подъеме и перемещении оборудования и его частей (узлов) без проверки расчетом прочности конструкций',
          'Все перечисленные',
        ],
        correctAnswers: [
          'При изменении обстановки в ремонтной зоне (возникновение парений, утечки газа или жидких продуктов) или на расположенном вблизи действующем объекте исполнители ремонтных работ должны доложить непосредственному руководителю работ или лицу, ответственному за подготовку объекта к ремонту. Приостанавливать работу допускается только с разрешения непосредственного руководителя работ',
        ],
      },
      {
        code: '63643057',
        text:
          'Кем из перечисленных лиц подтверждается ежедневный допуск ремонтных бригад подрядной организации к выполнению ремонтных работ с продлением наряда-допуска?',
        answers: [
          'Всеми перечисленными лицами',
          'Только непосредственным руководителем работ подрядной организации',
          'Только лицом, ответственным за подготовку объекта',
          'Только руководителем структурного подразделения ремонтируемого объекта',
        ],
        correctAnswers: ['Всеми перечисленными лицами'],
      },
      {
        code: '63643058',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63643059',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63643060',
        text:
          'Какой вид классификации оборудования для работы во взрывоопасных средах не устанавливает ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Классификация оборудования по давлению взрывоопасной среды',
          'Классификация взрывоопасных зон',
          'Классификация оборудования по группам (в зависимости от области применения)',
          'Классификация оборудования по уровням и видам взрывозащиты',
        ],
        correctAnswers: [
          'Классификация оборудования по давлению взрывоопасной среды',
        ],
      },
      {
        code: '63643061',
        text:
          'При какой скорости падения давления результаты дополнительного пневматического испытания на герметичность признаются удовлетворительными для технологических трубопроводов внутренним диаметром до 250 мм включительно со средами, относящимися к опасным веществам 1-го и 2-го классов опасности?',
        answers: [
          'Не более 0,2% за 1 час',
          'Не более 0,1% за 1 час',
          'Не более 0,8% за 1 час',
          'Не более 0,4% за 1 час',
        ],
        correctAnswers: ['Не более 0,1% за 1 час'],
      },
      {
        code: '63643062',
        text:
          'Какой должен быть уровень влажности измельченного нитрата целлюлозы, предназначенного для хранения?',
        answers: [
          'Должен быть определен технологическим регламентом, но не менее 35%',
          'Должен быть определен конструкторской документацией, но не менее 15%',
          'Должен быть определен технологическим регламентом, но не менее 25%',
          'Должен быть определен Основными требованиями безопасности для объектов производств боеприпасов и спецхимии',
        ],
        correctAnswers: [
          'Должен быть определен технологическим регламентом, но не менее 25%',
        ],
      },
      {
        code: '63643063',
        text:
          'В каком случае допускается укладка технологических трубопроводов в два яруса и более?',
        answers: [
          'Диаметром до 300 мм включительно',
          'Диаметром до 400 мм включительно',
          'Диаметром до 500 мм включительно',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Диаметром до 300 мм включительно'],
      },
      {
        code: '63643064',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 4 часов',
          'Не менее 12 часов',
          'Не менее 8 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63643065',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В проектной документации',
          'В технологическом регламенте',
          'В техническом задании на изготовление оборудования',
          'В паспортах оборудования и трубопроводной арматуры',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63643066',
        text:
          'В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах перед рычажно-грузовым или пружинным предохранительным клапаном?',
        answers: [
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
          'Если необходимо увеличение пропускной способности систем сброса давления рычажно-грузового (пружинного) предохранительного клапана',
          'Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительного клапана',
          'Установка мембранных предохранительных устройств перед рычажно-грузовыми и пружинными предохранительными клапанами не допускается',
        ],
        correctAnswers: [
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
        ],
      },
      {
        code: '63643067',
        text:
          'На каких технологических трубопроводах не допускается применять сальниковые компенсаторы?',
        answers: [
          'На трубопроводах со средами 1-ой группы',
          'На трубопроводах для трудногорючих веществ',
          'На трубопроводах для негорючих веществ',
          'Допускается применять на всех перечисленных трубопроводах',
        ],
        correctAnswers: ['На трубопроводах со средами 1-ой группы'],
      },
      {
        code: '63643068',
        text:
          'Какое определение соответствует термину "аварийный режим" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации',
          'Режим, при котором отклонение от установленных характеристик оборудования может привести к развитию аварии',
          'Разрушение оборудования, сопровождающееся выбросом опасных веществ и отключением электроэнергии',
          'Возникновение риска воспламенения окружающей взрывоопасной среды, связанного с возможностью причинения вреда и (или) нанесения ущерба',
        ],
        correctAnswers: [
          'Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации',
        ],
      },
      {
        code: '63643069',
        text:
          'В каких нормативных правовых актах установлены требования к качеству изготовления технологического оборудования, машин, трубопроводов и трубопроводной арматуры?',
        answers: [
          'В правилах устройства и безопасной эксплуатации соответствующих видов оборудования',
          'В технических регламентах',
          'В паспортах оборудования, машин, трубопроводов и трубопроводной арматуры',
          'В ГОСТах',
        ],
        correctAnswers: ['В технических регламентах'],
      },
      {
        code: '63643070',
        text:
          'Какие технологические блоки относятся к первой категории взрывоопасности?',
        answers: [
          'Qв 27 - 37, m, кг 2000 - 5000',
          'Qв &lt; 27, m, кг &lt; 2000',
          'Qв &gt; 37, m, кг &gt; 5000',
        ],
        correctAnswers: ['Qв &gt; 37, m, кг &gt; 5000'],
      },
      {
        code: '63643071',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации и технологическом регламенте на производство продукции',
          'В технологическом регламенте на производство продукции',
          'В проектной документации',
        ],
        correctAnswers: [
          'В проектной документации и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63643072',
        text:
          'Что должно быть учтено в системах управления и защит электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Должен быть обеспечен автоматический ввод резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Должна быть обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63643073',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '3 месяцев',
          '24 месяца',
          '36 месяцев',
          '12 месяцев',
          '6 месяцев',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63643074',
        text:
          'С учетом каких исследований должна проводиться разработка технологических процессов?',
        answers: [
          'С учетом результатов анализа физико-химических и взрывчатых характеристик веществ, применяемых в технологических процессах, идентификации опасностей, оценки риска, разработки мер, направленных на снижение уровня риска',
          'С учетом результатов токсикологических исследований',
          'С учетом результатов расчета взрывоустойчивости объектов производств боеприпасов и спецхимии',
        ],
        correctAnswers: [
          'С учетом результатов анализа физико-химических и взрывчатых характеристик веществ, применяемых в технологических процессах, идентификации опасностей, оценки риска, разработки мер, направленных на снижение уровня риска',
        ],
      },
      {
        code: '63643075',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63643076',
        text:
          'В каком документе указываются регламентированные параметры технологического процесса?',
        answers: [
          'В техническом регламенте',
          'В технологическом регламенте',
          'В проектной документации',
          'В руководствах по безопасности',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63643077',
        text:
          'В какой форме осуществляется подтверждение соответствия оборудования требованиям технического регламента ТР ТС 012/2011?',
        answers: [
          'Обязательной сертификацией',
          'Обязательной сертификацией или декларированием соответствия',
          'Обязательной или добровольной сертификацией',
          'Экспертизой промышленной безопасности',
        ],
        correctAnswers: ['Обязательной сертификацией'],
      },
      {
        code: '63643078',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых согласно Плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
          'Требования к обозначению определяются при разработке Плана мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63643079',
        text:
          'Вода какой температуры подается в трубчатку и ее межтрубное пространство при срабатывании аварийной сигнализации трубчатого автоклава?',
        answers: ['70 °C', '50 °C', '30 °C', '60 °C'],
        correctAnswers: ['70 °C'],
      },
      {
        code: '63643080',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
          'В соответствии с декларацией промышленной безопасности',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63643081',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
          'Перила должны быть высотой не менее 1,3 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63643082',
        text:
          'Какие требования при проведении ремонтных работ на технологическом оборудовании, где возможно выделение в ремонтную зону опасных веществ, указаны неверно?',
        answers: [
          'Ремонтные работы следует проводить при наличии в ремонтной зоне не более 20 мг/м³ опасных веществ',
          'Следует проводить анализ состояния воздушной среды, результаты которого должны быть внесены в наряд-допуск',
          'Анализ состояния воздушной среды в ремонтной зоне должен проводиться по требованию непосредственного руководителя работ подрядной организации',
        ],
        correctAnswers: [
          'Ремонтные работы следует проводить при наличии в ремонтной зоне не более 20 мг/м³ опасных веществ',
        ],
      },
      {
        code: '63643083',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63643084',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Критическими параметрами технологического процесса',
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Физико-химическими свойствами перемещаемых продуктов',
          'Техническими характеристиками применяемых насосов и компрессоров',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63643085',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'Не допускается ни в каком случае',
          'Если выбросы рассчитываются в количествах определяемых условиями безопасной остановки технологического процесса',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'В случае использования специальных систем аварийного освобождения',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63643086',
        text:
          'Какие дополнительные требования установлены при использовании технологического оборудования и трубопроводов, в которых обращаются коррозионно-активные вещества?',
        answers: [
          'Технологическое оборудование и трубопроводы должны быть защищены металлическими коррозионно-стойкими покрытиями',
          'Запрещается для защиты технологического оборудования использовать неметаллические покрытия',
          'Контроль за технологическим оборудованием и трубопроводами, контактирующими с коррозионно-активными веществами, должен осуществляться не реже чем 1 раз в месяц',
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
        correctAnswers: [
          'Технологическое оборудование и трубопроводы должны быть изготовлены из материалов, устойчивых к коррозии',
        ],
      },
      {
        code: '63643087',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Быть мобильными, иметь небольшие габариты и вес',
          'Обеспечивать минимально возможное время освобождения',
          'Осуществлять переход из режима ожидания в рабочее состояние в течение 30 с',
        ],
        correctAnswers: [
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Обеспечивать минимально возможное время освобождения',
        ],
      },
      {
        code: '63643088',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Испытание трубопровода на прочность и плотность',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Осуществляется все перечисленное',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63643089',
        text:
          'В каком случае запрещается объединять сбросы от предохранительных клапанов сосудов?',
        answers: [
          'Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
          'Если сбросы направляются в открытые системы для дальнейшей утилизации или в системы организованного сжигания',
          'Если по крайней мере один из сосудов работает под давлением среды, отнесенной к группе 1',
        ],
        correctAnswers: [
          'Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
        ],
      },
      {
        code: '63643090',
        text:
          'В соответствии с требованиями какой документации должна производиться подготовка материалов для снаряжения боеприпасов прессованием?',
        answers: [
          'В соответствии с требованиями технологической документации',
          'В соответствии с Основными требованиями безопасности для объектов производств боеприпасов и спецхимии',
          'В соответствии с требованиями конструкторской документации',
          'В соответствии с требованиями инструкции по охране труда',
        ],
        correctAnswers: [
          'В соответствии с требованиями технологической документации',
        ],
      },
      {
        code: '63643091',
        text:
          'Что должно проводиться для подтверждения соответствия Ех-оборудования стандартам на определенный вид взрывозащиты?',
        answers: [
          'Испытания промышленных образцов оборудования на взрывозащищенность',
          'Получение заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Опытные работы, проводимые разработчиком данного оборудования, и экспертиза промышленной безопасности',
          'Оценка научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
        ],
        correctAnswers: [
          'Испытания промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63643092',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'В случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Для обеспечения энергоэффективности',
          'Для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63643093',
        text:
          'Допускается ли проведение огневых работ на действующих взрывопожароопасных производственных объектах?',
        answers: [
          'Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах',
          'Не допускается',
          'Допускается при соблюдении дополнительных требований безопасности',
          'Допускается при положительном заключении противопожарной службы',
        ],
        correctAnswers: [
          'Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах',
        ],
      },
      {
        code: '63643094',
        text:
          'Что такое "назначенный срок службы" технического устройства опасного производственного объекта, после которого может осуществляться ликвидация технического устройства?',
        answers: [
          'Календарная продолжительность эксплуатации технического устройства, при достижении которой эксплуатация должна быть прекращена независимо от его технического состояния',
          'Продолжительность эксплуатации технического устройства, при котором его дальнейшая эксплуатация недопустима или нецелесообразна',
          'Суммарная наработка, при достижении которой эксплуатация технического устройства должна быть прекращена независимо от его технического состояния',
          'Наличие трещин металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима',
          'Продолжительность эксплуатации в машино-часах или циклах, при достижении которой необходимо принять решение по его ликвидации',
        ],
        correctAnswers: [
          'Календарная продолжительность эксплуатации технического устройства, при достижении которой эксплуатация должна быть прекращена независимо от его технического состояния',
        ],
      },
      {
        code: '63643095',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Проектной организацией',
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Технической комиссией эксплуатирующей организации',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63643096',
        text:
          'В каком случае допускается приготовление азида свинца, тринитрорезорцината свинца и тетразена проводить на одном и том же оборудовании?',
        answers: [
          'В случае осуществления управления загрузкой, разгрузкой и ходом реакции продуктов дистанционно и после разрешения технического руководителя предприятия',
          'С предварительной тщательной промывкой оборудования перед использованием его для получения другого продукта в соответствии с технологическим регламентом (технологическим процессом) и инструкцией по охране труда',
          'С разрешения территориального органа Ростехнадзора',
          'Не допускается ни в каком случае',
          'С разрешения проектной организации',
        ],
        correctAnswers: [
          'С предварительной тщательной промывкой оборудования перед использованием его для получения другого продукта в соответствии с технологическим регламентом (технологическим процессом) и инструкцией по охране труда',
        ],
      },
      {
        code: '63643097',
        text:
          'В соответствии с чем должны осуществляться контроль и регулирование технологических процессов? Выберите два правильных варианта ответа.',
        answers: [
          'В соответствии с технологическими регламентами',
          'В соответствии с Основными требованиями безопасности для объектов производств боеприпасов и спецхимии',
          'В соответствии с проектной, конструкторской документацией',
          'В соответствии с предписаниями инспектора Ростехнадзора',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами',
          'В соответствии с проектной, конструкторской документацией',
        ],
      },
      {
        code: '63643098',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Только устройство звуковой сигнализации',
          'Только устройство световой сигнализации',
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63643099',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Комиссия эксплуатирующей организации',
          'Инспектор территориального органа Ростехнадзора',
          'Руководитель проектной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63643100',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На сети водоснабжения и канализации',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см²) до избыточного давления 320 МПа (3200 кгс/см²) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63643101',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
          'Во всех вышеуказанных местах',
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
        ],
        correctAnswers: ['Во всех вышеуказанных местах'],
      },
      {
        code: '63643102',
        text:
          'Какие требования к подготовительным работам при проведении земляных работ указаны неверно?',
        answers: [
          'До начала проведения земляных работ исполнители земляных работ должны пройти инструктаж на рабочем месте о мерах промышленной безопасности',
          'Перед разработкой грунта на месте работ следует выставить предупредительные знаки',
          'Перед разработкой грунта место работ следует оградить по всему периметру',
          'Все требования указаны верно',
        ],
        correctAnswers: ['Все требования указаны верно'],
      },
      {
        code: '63643103',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Только средствами противоаварийной защиты',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Только средствами автоматического регулирования',
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63643104',
        text:
          'При какой максимальной скорости ветра допускается транспортирование инициирующих взрывчатых веществ и составов на их основе?',
        answers: ['12 м/с', '15 м/с', '10 м/с', '7 м/с'],
        correctAnswers: ['12 м/с'],
      },
      {
        code: '63643105',
        text:
          'Какая должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий?',
        answers: [
          'В местах, доступных для обслуживания персонала не более 40 °С внутри помещений и 55 °С на наружных установках',
          'В местах, доступных для обслуживания персонала не более 55 °С внутри помещений и 60 °С на наружных установках',
          'В местах, доступных для обслуживания персонала не более 45 °С внутри помещений и 60 °С на наружных установках',
          'В местах, доступных для обслуживания персонала не более 50 °С внутри помещений и 65 °С на наружных установках',
        ],
        correctAnswers: [
          'В местах, доступных для обслуживания персонала не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63643106',
        text:
          'Какое из перечисленных должностных лиц имеет право ставить подписи в наряде-допуске на проведение газоопасных работ, подтверждающие его закрытие и выполнение работ в полном объеме?',
        answers: [
          'Лицо, ответственное за проведение газоопасных работ',
          'Руководитель структурного подразделения',
          'Все перечисленные',
          'Должностное лицо, ответственное за безопасное ведение технологического процесса на объекте',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63643107',
        text:
          'Что необходимо выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей (аппаратов) для оценки качества выполнения подготовительных мероприятий?',
        answers: [
          'Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)',
          'Все перечисленное',
          'Вывесить предупреждающие плакаты "Газ", "Газоопасные работы"',
          'Произвести продувку инертным газом и воздухом с записью результатов в наряде-допуске',
        ],
        correctAnswers: [
          'Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)',
        ],
      },
      {
        code: '63643108',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Эксплуатирующими организациями',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Ростехнадзором',
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63643109',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63643110',
        text:
          'Какие противогазы или аппараты не допускается использовать для защиты органов дыхания работников внутри емкостей при проведении газоопасных работ?',
        answers: [
          'Фильтрующие противогазы',
          'Шланговые противогазы',
          'Кислородно-изолирующие противогазы',
          'Воздушные изолирующие аппараты',
        ],
        correctAnswers: ['Фильтрующие противогазы'],
      },
      {
        code: '63643111',
        text:
          'Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?',
        answers: [
          'Справочной литературой',
          'Научно-технической литературой',
          'Данными, запрашиваемыми у научно-исследовательской организации',
          'Исходными данными на проектирование',
        ],
        correctAnswers: ['Исходными данными на проектирование'],
      },
      {
        code: '63643112',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'На факельную установку',
          'В специальные контейнеры',
          'В закрытые системы для дальнейшей утилизации',
          'В централизованную систему водоотведения',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63643113',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63643114',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63643115',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже двух раз в 8 лет',
          'Не реже одного раза в 6 лет',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63643116',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Обратный клапан',
          'Предохранительный клапан',
          'Регулирующий клапан',
          'Гидроклапан',
          'Запорный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63643117',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63643118',
        text:
          'Какое минимальное количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Два',
          'Четыре',
          'Один',
          'Определяется ответственным за проведение газоопасных работ',
        ],
        correctAnswers: ['Два'],
      },
      {
        code: '63643119',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
          'Исходные данные на проектирование, требования действующих нормативных документов с учетом категории взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Исходные данные на проектирование, требования действующих нормативных документов с учетом категории взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63643120',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Комиссией эксплуатирующей организации',
          'Специализированной сторонней организацией',
          'Разработчиком проекта',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63643121',
        text:
          'В какие емкости допускается помещать влажные инициирующие взрывчатые вещества, хранимые в хлопчатобумажных или резиновых мешках?',
        answers: [
          'В эмалированные емкости',
          'В фарфоровые емкости',
          'В винипластовые емкости',
          'Во все перечисленные емкости',
        ],
        correctAnswers: ['Во все перечисленные емкости'],
      },
      {
        code: '63643122',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения трубопровода на консервации более двух лет',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более 6 месяцев',
        ],
        correctAnswers: [
          'После нахождения трубопровода на консервации более двух лет',
        ],
      },
      {
        code: '63643123',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Иметь документы о прохождении аттестации',
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Всем перечисленным требованиям',
          'Соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63643124',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие токсичные, высокотоксичные вещества, органические теплоносители, воспламеняющиеся и горючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 6 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63643125',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63643126',
        text:
          'Какое определение соответствует термину "Ex-компонент" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Взрывозащищенное техническое устройство, необходимое для безопасного функционирования оборудования во взрывоопасных средах, предназначенное для самостоятельного применения',
          'Устанавливаемое на оборудование взрывозащищенное техническое устройство, необходимое для безопасного функционирования оборудования во взрывоопасных средах, но не предназначенное для самостоятельного применения',
          'Любое техническое устройство, которое допускается для применения во взрывоопасных средах и имеет соответствующую маркировку',
        ],
        correctAnswers: [
          'Устанавливаемое на оборудование взрывозащищенное техническое устройство, необходимое для безопасного функционирования оборудования во взрывоопасных средах, но не предназначенное для самостоятельного применения',
        ],
      },
      {
        code: '63643127',
        text:
          'Какие действия требуется выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей при наличии в них перемешивающих устройств с электроприводом?',
        answers: [
          'Отключить электропривод от источников питания видимым разрывом',
          'Вывесить плакаты на емкостях "Газ", "Газоопасные работы"',
          'Вывесить плакаты у емкостей в электрораспределительных устройствах "Не включать: работают люди!"',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63643128',
        text:
          'В течение какого времени должна сохраняться маркировка взрывозащиты, нанесенная на поверхность оборудования или табличку?',
        answers: [
          'В течение 20 лет',
          'В течение 10 лет',
          'В течение 5 лет',
          'В течение всего срока службы этого оборудования',
        ],
        correctAnswers: ['В течение всего срока службы этого оборудования'],
      },
      {
        code: '63643129',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение тридцати рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63643130',
        text:
          'Какая информация из приведенной не включается в маркировку, наносимую на оборудование, работающее под давлением?',
        answers: [
          'Наименование и (или) обозначение оборудования',
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
          'Параметры и характеристики, влияющие на безопасность',
          'Наименование материала, из которого изготовлено оборудование',
          'Вся приведенная информация включается в маркировку, наносимую на оборудование',
        ],
        correctAnswers: [
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63643131',
        text:
          'В каких случаях огневые работы могут проводиться в ночное время суток?',
        answers: [
          'В случаях когда наряд-допуск подписан руководителем эксплуатирующей организации, на объекте которой будут проводиться огневые работы',
          'В случаях ликвидации или локализации возможных аварий',
          'Огневые работы должны проводиться только в дневное время суток',
          'В случаях когда наряд-допуск подписан руководителем структурного подразделения, на объекте которого будут проводиться огневые работы',
        ],
        correctAnswers: [
          'В случаях ликвидации или локализации возможных аварий',
        ],
      },
      {
        code: '63643132',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'В технологических блоках всех категорий взрывоопасности',
          'Только в технологических блоках II категории взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
          'Только в технологических блоках I категории взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63643133',
        text:
          'Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?',
        answers: [
          'Гибкие металлические шланги',
          'Съемные участки трубопроводов',
          'Пластмассовые гибкие шланги',
          'Резиновые гибкие шланги',
        ],
        correctAnswers: ['Съемные участки трубопроводов'],
      },
      {
        code: '63643134',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
        ],
        correctAnswers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
        ],
      },
      {
        code: '63643135',
        text:
          'Какая из перечисленных сборок боеприпасов допускается в одном помещении?',
        answers: [
          'Все ответы неверны',
          'Одновременная сборка вкладных элементов различных категорий опасности',
          'Сборка окончательно снаряженных вкладных элементов кассетных боеприпасов и сборка кассетных боеприпасов',
          'Сборка кассетных боеприпасов по различным чертежам',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63643136',
        text:
          'При соблюдении какого условия оборудование, работающее под избыточным давлением, выпускается в обращение на рынок?',
        answers: [
          'Оборудование должно иметь соответствующее разрешение на его обращение на рынке, выданное в порядке, предусмотренном Евразийской экономической комиссией',
          'Оборудование должно иметь документ о прохождении оценки соответствия требованиям ТР ТС 032/2013',
          'Оборудование должно иметь разрешение на его применение, выданное Федеральной службой по экологическому, технологическому и атомному надзору',
          'Оборудование должно иметь документ о подтверждении соответствия требованиям государственных и международных стандартов, выданный в порядке, предусмотренном Росстандартом',
        ],
        correctAnswers: [
          'Оборудование должно иметь документ о прохождении оценки соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63643137',
        text:
          'В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия персонала по предупреждению аварий, локализации и ликвидации их последствий?',
        answers: [
          'В плане по локализации аварийных ситуаций',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'В Положении о производственном контроле',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63643138',
        text:
          'Кто устанавливает назначенный срок службы для технологических трубопроводов?',
        answers: [
          'Организация-изготовитель',
          'Орган по сертификации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Разработчик документации',
        ],
        correctAnswers: ['Разработчик документации'],
      },
      {
        code: '63643139',
        text:
          'До какой температуры должен быть охлажден порох для отбора образцов, перемешивания и выгрузки пороха?',
        answers: [
          'Не выше 25 °C',
          'Не выше 27 °C',
          'Не выше 30 °C',
          'Не выше 35 °C',
        ],
        correctAnswers: ['Не выше 25 °C'],
      },
      {
        code: '63643140',
        text:
          'Какие виды работ относятся к подготовительным для проведения газоопасных работ?',
        answers: [
          'Работы, связанные с подготовкой технологического оборудования и трубопроводов',
          'Работы, связанные с подготовкой коммуникаций',
          'Все перечисленные виды работ',
          'Работы, связанные с подготовкой емкостей',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63643141',
        text:
          'Каким образом должны крепиться неподвижные защитные ограждения согласно требованиям ТР ТС 010/2011?',
        answers: [
          'Чтобы доступ в ограждаемую зону был возможен по мере необходимости',
          'Чтобы доступ в ограждаемую зону был возможен только с использованием инструментов',
          'Чтобы доступ в ограждаемую зону был возможен только после отключения блокировок',
          'Чтобы доступ в ограждаемую зону был возможен только после отключения энергоснабжения',
          'Чтобы доступ в ограждаемую зону был возможен только персоналу, имеющему оформленный допуск',
        ],
        correctAnswers: [
          'Чтобы доступ в ограждаемую зону был возможен только с использованием инструментов',
        ],
      },
      {
        code: '63643142',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Порошкообразные теплоизоляционные материалы',
          'Базальтовое супертонкое волокно',
          'Вата из непрерывного стеклянного волокна',
          'Допускается применять все перечисленные материалы',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63643143',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63643144',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 8 лет',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63643145',
        text:
          'Кем должны быть утверждены схемы стационарных подрывных линий и линий поджигания?',
        answers: [
          'Техническим руководителем',
          'Комиссиями, назначаемыми руководителем или главным инженером организации',
          'Начальником испытательной станции',
          'Главным энергетиком организации',
        ],
        correctAnswers: ['Техническим руководителем'],
      },
      {
        code: '63643146',
        text:
          'Каким образом объект, ремонт которого закончен, принимается в эксплуатацию?',
        answers: [
          'По акту сдачи-приемки в эксплуатацию',
          'После закрытия наряда-допуска',
          'На основании положительного заключения экспертизы промышленной безопасности',
          'Совместным приказом руководителей эксплуатирующей и подрядной организаций',
        ],
        correctAnswers: ['По акту сдачи-приемки в эксплуатацию'],
      },
      {
        code: '63643147',
        text:
          'Каков срок действия сертификата соответствия выпущенной партии технических устройств требованиям ТС 010/2011?',
        answers: [
          'Не устанавливается',
          '3 года',
          '4 года',
          '5 лет',
          'В течение срока службы технического устройства',
        ],
        correctAnswers: ['Не устанавливается'],
      },
      {
        code: '63643148',
        text:
          'Арматура с каким классом герметичности затвора должна устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: ['В', 'СС', 'Определяется проектной документацией', 'АА', 'А'],
        correctAnswers: ['Определяется проектной документацией'],
      },
      {
        code: '63643149',
        text:
          'Какое количество боеприпасов, переданных на утилизацию, подвергается контролю на безопасность при транспортировании и в служебном обращении?',
        answers: [
          '100% боеприпасов',
          '95% боеприпасов',
          '90% боеприпасов',
          '85% боеприпасов',
        ],
        correctAnswers: ['100% боеприпасов'],
      },
      {
        code: '63643150',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63643151',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63643152',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее одного участка каждого блока установки независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63643153',
        text:
          'Чем не должны быть обеспечены помещения или площадки для проведения испытаний взрывчатых веществ и составов, порохов, твердых ракетных топлив, пиротехнических составов и снаряженных ими изделий?',
        answers: [
          'Предупреждающей сигнализацией о начале и окончании испытаний',
          'Телефонной, а также радио-, двухсторонней громкоговорящей или иным видом связи',
          'Укрытиями для обслуживающего персонала',
          'Аварийной сигнализацией',
        ],
        correctAnswers: ['Аварийной сигнализацией'],
      },
      {
        code: '63643154',
        text:
          'На сколько этапов разделяются ремонтные работы согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: ['Четыре', 'Три', 'Два', 'Один'],
        correctAnswers: ['Два'],
      },
      {
        code: '63643155',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок',
          'Руководитель структурного подразделения, не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63643156',
        text:
          'На какие виды оборудования не распространяется требования Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза',
          'Котлы, имеющие вместимость более 0,002 м³, предназначенные для получения пара, избыточное давление которого свыше 0,05 МПа',
          'Котлы, имеющие вместимость более 0,002 м³, предназначенные для получения горячей воды, температура которой свыше 110 °С',
          'Сосуды, предназначенные для сжатых, сжиженных, растворенных под давлением газов и паров, используемые для рабочих сред группы 1 и имеющие расчетное давление свыше 20 МПа, вместимость свыше 0,0001 м³ до 0,001 м³ включительно',
          'Сосуды, предназначенные для жидкостей, используемые для рабочих сред группы 2 и имеющие расчетное давление свыше 100 МПа, вместимость свыше 0,0001 м³ до 0,01 м³ включительно',
        ],
        correctAnswers: [
          'Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза',
        ],
      },
      {
        code: '63643157',
        text:
          'Чему должна быть равна ширина смотровой щели указателя уровня жидкости в сосудах?',
        answers: [
          'Определяется проектом оборудования',
          '20 мм',
          '4 мм',
          '8 мм',
        ],
        correctAnswers: ['Определяется проектом оборудования'],
      },
      {
        code: '63643158',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасных и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63643159',
        text:
          'Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?',
        answers: [
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
          'Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
          'Только под эстакадами технологических трубопроводов',
        ],
        correctAnswers: [
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
      },
      {
        code: '63643160',
        text:
          'Какие из перечисленных ниже сведений не включают в руководство (инструкцию) по эксплуатации технического устройства?',
        answers: [
          'Сведения о квалификации обслуживающего персонала и удостоверениях, подтверждающих его квалификацию',
          'Указания по монтажу или сборке, наладке или регулировке, техническому обслуживанию и ремонту машины и (или) оборудования',
          'Указания по выводу из эксплуатации и утилизации',
          'Сведения о конструкции, принципе действия, характеристиках (свойствах) машин и/или оборудования',
          'Назначенные показатели (назначенный срок хранения, назначенный срок службы и (или) назначенный ресурс)',
        ],
        correctAnswers: [
          'Сведения о квалификации обслуживающего персонала и удостоверениях, подтверждающих его квалификацию',
        ],
      },
      {
        code: '63643161',
        text:
          'В каком документе даются указания по выводу из эксплуатации и утилизации оборудования, работающего под давлением?',
        answers: [
          'Руководстве (инструкции) по эксплуатации',
          'Заключении по результатам технического диагностирования оборудования',
          'Типовой инструкции по утилизации оборудования, работающего под давлением, используемого на опасном производственном объекте',
          'Документации эксплуатирующей организации, прошедшей экспертизу промышленной безопасности',
        ],
        correctAnswers: ['Руководстве (инструкции) по эксплуатации'],
      },
      {
        code: '63643162',
        text:
          'Какой обогрев зданий не допускается при производстве пиротехнических составов, металлизированных огнесмесей, прессовании, снаряжении пиротехнических изделий и изготовлении зарядов твердого пиротехнического топлива?',
        answers: [
          'С помощью водяного отопления',
          'Нагретым воздухом, поступающим через калориферы, которые должны быть установлены в отдельном помещении, выгороженном стенами',
          'С помощью воздушного отопления с применением рециркуляции воздуха',
          'Допускается любой из перечисленных обогревов зданий',
        ],
        correctAnswers: [
          'С помощью воздушного отопления с применением рециркуляции воздуха',
        ],
      },
      {
        code: '63643163',
        text:
          'В каких случаях на трубопроводах следует применять арматуру под приварку?',
        answers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа',
        ],
        correctAnswers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
        ],
      },
      {
        code: '63643164',
        text:
          'Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?',
        answers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
          'В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание',
          'Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание',
          'Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно с одновременной защитой отдельных единиц оборудования',
        ],
        correctAnswers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
        ],
      },
      {
        code: '63643165',
        text:
          'Какой документ является основным для идентификации оборудования, работающего под избыточным давлением?',
        answers: [
          'Сертификат соответствия оборудования требованиям ТР ТС 032/2013',
          'Обоснование безопасности оборудования',
          'Паспорт оборудования',
          'Декларация соответствия оборудования требованиям промышленной безопасности',
        ],
        correctAnswers: ['Паспорт оборудования'],
      },
      {
        code: '63643166',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63643167',
        text:
          'Какой должна быть скорость срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?',
        answers: [
          'С автоматическим управлением - не более 12 секунд',
          'С автоматическим управлением - не более 120 секунд',
          'С автоматическим управлением - не более 300 секунд',
          'С автоматическим управлением - не более 350 секунд',
        ],
        correctAnswers: ['С автоматическим управлением - не более 12 секунд'],
      },
      {
        code: '63643168',
        text:
          'На каких отдельных участках трассы допускается прокладка технологических трубопроводов в полупроходных каналах?',
        answers: [
          'Протяженностью не более 100 м',
          'Протяженностью не более 200 м',
          'Протяженностью не более 250 м',
          'Протяженностью не более 150 м',
        ],
        correctAnswers: ['Протяженностью не более 100 м'],
      },
      {
        code: '63643169',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63643170',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования должно определяться конкретными условиями и потребностями объекта',
          'Для пневматических систем контроля, управления и противоаварийной защиты должны предусматриваться единые установки и единые сети сжатого воздуха',
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63643171',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений, должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 20% НКПР',
          'При 18% НКПР',
          'При 15% НКПР',
          'При 10% НКПР',
        ],
        correctAnswers: ['При 20% НКПР'],
      },
      {
        code: '63643172',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
          'Со средой 1-ой группы',
          'С номинальным давлением более 5 МПа',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63643173',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: ['На 1 выше', 'I категории', 'II категории', 'III категории'],
        correctAnswers: ['На 1 выше'],
      },
      {
        code: '63643174',
        text:
          'В каком случае допускается стационарное применение цельносварных гофрированных стальных труб, включая конструкции с теплоизоляционными и (или) защитными слоями?',
        answers: [
          'По решению технической комиссии эксплуатирующей организации',
          'При согласовании с территориальным органом Ростехнадзора',
          'Не допускается ни в каком случае',
          'При обосновании в проекте',
        ],
        correctAnswers: ['При обосновании в проекте'],
      },
      {
        code: '63643175',
        text:
          'В каком случае допускается временное хранение инициирующих взрывчатых веществ в производственных помещениях?',
        answers: [
          'По решению мастера цеха',
          'В случаях, указанных в технологической документации',
          'В аварийных ситуациях',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63643176',
        text:
          'Что из приведенного не подлежит обязательному включению в состав технической документации, прилагаемой к поставляемому и подлежащему установке оборудованию, работающему под избыточным давлением?',
        answers: [
          'Паспорта предохранительных устройств',
          'Паспорт сосуда',
          'Расчет на прочность оборудования (для арматуры, деталей трубопроводов и фланцев выписка из расчета)',
          'Руководство (инструкция) по эксплуатации (кроме элементов (сборочных единиц, деталей) оборудования и комплектующих изделий)',
        ],
        correctAnswers: ['Паспорт сосуда'],
      },
      {
        code: '63643177',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчиком в задании на проектирование',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63643178',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Расчет на прочность',
          'Схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63643179',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту',
          'Должно быть организовано управление дистанционно',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63643180',
        text: 'Что такое "предельное состояние" технического устройства?',
        answers: [
          'Продолжительность эксплуатации технического устройства, при котором его дальнейшая эксплуатация недопустима или нецелесообразна',
          'Состояние машины и (или) оборудования, при котором их дальнейшая эксплуатация недопустима или нецелесообразна либо восстановление их работоспособного состояния невозможно или нецелесообразно',
          'Наличие остаточных деформаций металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима',
          'Наличие трещин металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима',
          'Состояние машины и (или) оборудования, при котором их дальнейшая эксплуатация недопустима и их следует отправить в ремонт или в утилизацию (ликвидацию)',
        ],
        correctAnswers: [
          'Состояние машины и (или) оборудования, при котором их дальнейшая эксплуатация недопустима или нецелесообразна либо восстановление их работоспособного состояния невозможно или нецелесообразно',
        ],
      },
      {
        code: '63643181',
        text:
          'Какой должна быть температура поверхности оборудования и (или) его частей с уровнем взрывозащиты "особовзрывобезопасный" ("очень высокий") и "взрывобезопасный" ("высокий")?',
        answers: [
          'Температура определяется в технической документации с учетом характера принимаемых изготовителем мер по защите указанного оборудования',
          'Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации',
          'Температура не должна быть выше максимальной температуры поверхности в нормальном режиме эксплуатации',
        ],
        correctAnswers: [
          'Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации',
        ],
      },
      {
        code: '63643182',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 9 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 7 МПа',
          'Под давлением, равным рабочему, но не более 5 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63643183',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'В каналах (закрытых или с засыпкой песком)',
          'В грунте',
          'Надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63643184',
        text:
          'Какая относительная влажность воздуха должна поддерживаться в производственных помещениях, где ведутся работы со взрывопожароопасными материалами, для уменьшения удельного электрического сопротивления?',
        answers: [
          'Не менее 55%',
          'Не менее 75%',
          'Не менее 85%',
          'Не менее 65%',
        ],
        correctAnswers: ['Не менее 65%'],
      },
      {
        code: '63643185',
        text:
          'Каким из перечисленных требований должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
          'Полы в помещении должны быть нескользкими, с повышенной механической стойкостью',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63643186',
        text:
          'Каким из перечисленных способов не допускается прокладка кабелей по территории предприятий и установок?',
        answers: [
          'В галереях',
          'Все ответы неверны',
          'В каналах, засыпанных песком',
          'На кабельных конструкциях технологических эстакад',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63643187',
        text:
          'К какой группе газоопасных работ относятся работы по установке (снятию) заглушек, и кто их проводит?',
        answers: [
          'К II группе, проводит эксплуатационный персонал',
          'К I группе, проводит бригада, определенная нарядом-допуском',
          'К I группе, проводит эксплуатационный персонал',
        ],
        correctAnswers: ['К II группе, проводит эксплуатационный персонал'],
      },
      {
        code: '63643188',
        text:
          'Какие фазы при производстве гремучей ртути допускается проводить в одном помещении последовательно?',
        answers: [
          'Приготовление растворов и получение продукта',
          'Просеивание и промывку гремучей ртути',
          'Получение продукта и просеивание',
        ],
        correctAnswers: ['Просеивание и промывку гремучей ртути'],
      },
      {
        code: '63643189',
        text:
          'В какой документации указывается необходимость и методы закрепления изделий в технологическом цикле при производстве баллиститного ракетного твердого топлива, баллиститных порохов и зарядов из них?',
        answers: [
          'В конструкторской документации на заряд',
          'Во внутренней распорядительной документации',
          'В Основных требованиях безопасности для объектов производств боеприпасов и спецхимии',
        ],
        correctAnswers: ['В конструкторской документации на заряд'],
      },
      {
        code: '63643190',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'Электроснабжение химически опасных производственных объектов должно осуществляться по I или II категории надежности',
          'Электроснабжение химически опасных производственных объектов должно осуществляться только по I категории надежности',
          'Электроснабжение химически опасных производственных объектов должно осуществляться по II или III категории надежности',
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
        ],
        correctAnswers: [
          'Электроснабжение химически опасных производственных объектов должно осуществляться по I или II категории надежности',
        ],
      },
      {
        code: '63643191',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 4 часов',
          'Не менее 24 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63643192',
        text:
          'Каков срок действия декларации о соответствии требованиям ТС 010/2011 технического устройства, применяемого на опасном производственном объекте?',
        answers: [
          '2 года',
          '4 года',
          'Не более 3 лет',
          'Не более 5 лет',
          'В течение срока службы технического устройства',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63643193',
        text:
          'Что означает термин "температура рабочей среды" согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Максимальная (минимальная) температура стенки сосуда, при которой допускается эксплуатация оборудования',
          'Температура, равная 20°С, используемая при расчете на прочность стандартных сосудов (узлов, деталей, арматуры)',
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
          'Температура, при которой определяются физико-механические характеристики, допускаемое напряжение материала и проводится расчет на прочность элементов оборудования',
        ],
        correctAnswers: [
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
        ],
      },
      {
        code: '63643194',
        text:
          'В каких случаях на техническом устройстве должен включаться предупреждающий сигнал?',
        answers: [
          'В случаях, изложенных в Федеральных нормах и правилах по промышленной безопасности',
          'При каждом новом пуске технического устройства в работу',
          'В случаях, изложенных в руководстве по эксплуатации технического устройства',
          'При каждом новом пуске технического устройства в работу и его остановке',
          'В случаях, изложенных в проекте производства работ, в котором используется техническое устройство',
        ],
        correctAnswers: [
          'При каждом новом пуске технического устройства в работу',
        ],
      },
      {
        code: '63643195',
        text:
          'С какой периодичностью должны проводиться тренировки работников для отработки последовательности действий по предупреждению аварий и ликвидации их последствий в соответствии с утвержденными планами мероприятий по локализации и ликвидации аварийных ситуаций на ОПО в цехах, мастерских, производственных помещениях ОПО?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63643196',
        text:
          'Какие из перечисленных мероприятий при выполнении подготовительных работ к проведению огневых работ указаны неверно?',
        answers: [
          'При выполнении огневых работ в помещении следует предусмотреть меры защиты от разлета и попадания искр в проемы межэтажных перекрытий, а также лотков и приямков',
          'Сливные воронки, выходы из лотков и другие устройства, связанные с канализацией, в которых могут быть горючие газы и пары, должны быть герметизированы',
          'Место выполнения огневых работ должно быть обеспечено огнетушителем и другими первичными средствами пожаротушения, указанными в наряде-допуске на выполнение огневых работ',
          'В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ',
          'Все перечисленные',
        ],
        correctAnswers: [
          'В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ',
        ],
      },
      {
        code: '63643197',
        text:
          'Какое количество запорных арматур следует устанавливать на технологических трубопроводах взрывопожароопасных сред для герметичного отключения от коллектора агрегатов (технологических аппаратов) с рабочим давлением (далее - Pр) ≥ 4 МПа (40 кгс/см²)?',
        answers: [
          'Одну единицу запорной арматуры и дренажную арматуру с заглушкой',
          'Две единицы запорной арматуры с дренажным устройством между ними',
          'Три единицы запорной арматуры',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
        ],
      },
      {
        code: '63643198',
        text:
          'Какое минимальное количество датчиков должно устанавливаться на химически опасных производственных объектах I и II классов опасности для осуществления контроля за текущими показателями параметров, определяющими химическую опасность процессов?',
        answers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
          'Правилами не регламентируется',
          'Не менее 3 независимых датчиков с раздельными точками отбора',
          'Не менее 5 независимых датчиков с раздельными точками отбора',
        ],
        correctAnswers: [
          'Не менее 2 независимых датчиков с раздельными точками отбора',
        ],
      },
      {
        code: '63643199',
        text:
          'В какой документации должны быть определены порядок контроля за степенью коррозионного износа оборудования и трубопроводов с использованием методов неразрушающего контроля, способы, периодичность и места проведения контрольных замеров?',
        answers: [
          'В справочной литературе',
          'В технологических регламентах',
          'В эксплуатационной документации организации-изготовителя',
          'В руководствах по безопасности',
        ],
        correctAnswers: [
          'В эксплуатационной документации организации-изготовителя',
        ],
      },
      {
        code: '63643200',
        text:
          'При соблюдении какого требования выдается наряд-допуск на проведение ремонтных работ?',
        answers: [
          'После оформления акта сдачи-приемки объекта в ремонт',
          'После выполнения всех мероприятий, предусмотренных планом подготовительных работ',
          'После проверки выполнения всех мероприятий, предусмотренных планом подготовительных работ',
        ],
        correctAnswers: [
          'После оформления акта сдачи-приемки объекта в ремонт',
        ],
      },
      {
        code: '63643201',
        text:
          'Какие действия допускается выполнять с емкостями (аппаратами), подлежащими вскрытию, осмотру, чистке или ремонту?',
        answers: [
          'Отключать от действующего оборудования, систем трубопроводов и коммуникаций с помощью заглушек',
          'Промывать и пропаривать',
          'Все перечисленные',
          'Продувать инертным газом или воздухом',
          'Освобождать от опасных веществ',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63643202',
        text:
          'Какое минимальное количество редуцирующих устройств с манометром и предохранительным клапаном допускается устанавливать на общем подводящем присоединительном трубопроводе для группы сосудов, работающих при одном и том же давлении?',
        answers: [
          'Три',
          'Два',
          'Определяется заказчиком проектной документации',
          'Одно',
        ],
        correctAnswers: ['Одно'],
      },
      {
        code: '63643203',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
          'По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63643204',
        text:
          'Кем определяется порядок сверления отверстий в изделиях из баллиститного ракетного твердого топлива?',
        answers: [
          'Научно-исследовательской организацией',
          'Экспертной организацией',
          'Организацией-разработчиком',
          'Ростехнадзором',
        ],
        correctAnswers: ['Организацией-разработчиком'],
      },
      {
        code: '63643205',
        text:
          'В каких местах допускается газоопасная работа без изолирующих средств защиты органов дыхания?',
        answers: [
          'Не допускается во всех перечисленных местах',
          'В колодцах',
          'В тоннелях',
          'В коллекторах',
        ],
        correctAnswers: ['Не допускается во всех перечисленных местах'],
      },
      {
        code: '63643206',
        text:
          'В каких местах могут быть допущены фланцевые соединения трубопроводов?',
        answers: [
          'В местах установки арматуры',
          'Во всех перечисленных местах',
          'На участках, где по условиям технологии требуется периодическая разборка для проведения чистки и ремонта трубопроводов',
          'Трубопроводы не должны иметь фланцевых соединений',
          'В местах подсоединения трубопроводов к аппаратам',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63643207',
        text:
          'При рытье котлованов и траншей на какую глубину следует принимать меры, препятствующие отвисанию и обвалу грунта (образование откосов, крепление стенок)?',
        answers: ['0,7 м', '0,5 м', '0,6 м', '0,9 м', 'Все ответы неверны'],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63643208',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Проектный расчет',
          'Проверочный расчет на прочность',
          'Гидравлический расчет',
          'Расчет рабочей температуры',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63643209',
        text:
          'Что необходимо предусматривать в химико-технологических системах для эффективного проведения периодических работ по очистке оборудования?',
        answers: [
          'Наличие средств гидравлической, механической или химической очистки',
          'Наличие оросительных систем',
          'Наличие специального персонала для очистки оборудования, имеющего необходимые допуски',
          'Возможность изоляции соседнего оборудования',
        ],
        correctAnswers: [
          'Наличие средств гидравлической, механической или химической очистки',
        ],
      },
      {
        code: '63643210',
        text:
          'Какие факторы, представляющие собой основные виды опасности, должны учитываться с целью определения рисков для оборудования?',
        answers: [
          'Коррозия и иные виды износа материала элементов оборудования, прекращение действия вспомогательного оборудования, погасание факелов в топке при камерном сжигании топлива',
          'Наличие незащищенных подвижных элементов, вибрация, наличие взрывопожароопасных элементов',
          'Недопустимые отклонения параметров конструкции, сборочных единиц и устройств безопасности, влияющие на безопасность',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63643211',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся и горючих жидкостей поршневых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
        correctAnswers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63643212',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывопожароопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Q10 управление - ручное дистанционное управление',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв≤10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв≤10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63643213',
        text:
          'По каким уровням взрывозащиты не классифицируется оборудование в зависимости от опасности стать источником воспламенения и условий его применения во взрывоопасных средах?',
        answers: [
          'Повышенная надежность против взрыва (повышенный)',
          'Низковзрывоопасный (умеренный)',
          'Особовзрывобезопасный (очень высокий)',
          'Взрывобезопасный (высокий)',
        ],
        correctAnswers: ['Низковзрывоопасный (умеренный)'],
      },
      {
        code: '63643214',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'При неудовлетворительном состоянии уплотнительных поверхностей',
          'При срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'При деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63643215',
        text:
          'Какой срок действия сертификата соответствия оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" при использовании схемы 1с?',
        answers: [
          '1 год',
          'В течение назначенного срока службы или назначенного ресурса',
          '5 лет',
          '3 года',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63643216',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 7 минут',
          'Не менее 3 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63643217',
        text:
          'Какие обязанности руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указаны неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63643218',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Если трубопроводы находились на консервации более одного года',
          'Во всех перечисленных случаях производятся диагностические работы',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63643219',
        text:
          'Какое из перечисленных требований к прокладке и устройству технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Технологические трубопроводы в производственных помещениях должны прокладываться закрыто',
          'Сварные и разъемные соединения трубопроводов внутри футляров или гильз допускаются в соответствии с проектом',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
        ],
        correctAnswers: [
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
        ],
      },
      {
        code: '63643220',
        text:
          'Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?',
        answers: [
          'Организация-изготовитель',
          'Орган по сертификации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Разработчик документации',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63643221',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63643222',
        text:
          'Какой объем неразрушающего контроля сварных соединений технологических трубопроводов, транспортирующих токсичные и высокотоксичные вещества, предусмотрен в Правилах?',
        answers: [
          'Не менее 50% длины сварного шва каждого сварного соединения',
          'Не менее 75% длины сварного шва каждого сварного соединения',
          'Не менее 100% длины сварного шва каждого сварного соединения',
          'Определяется специалистами неразрушающего контроля',
        ],
        correctAnswers: [
          'Не менее 100% длины сварного шва каждого сварного соединения',
        ],
      },
      {
        code: '63643223',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Наличие природных оврагов, выемок, низин',
          'Траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63643224',
        text:
          'Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?',
        answers: [
          'Без предохранительного клапана',
          'Без фильтра',
          'Без регулируемого клапана',
          'Без гидрозатвора',
        ],
        correctAnswers: ['Без гидрозатвора'],
      },
      {
        code: '63643225',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 30 минут',
          'Через 10 минут',
          'Через 5 минуту',
          'Через 60 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63643226',
        text:
          'Чем допускается перемещать огнесмеси из смесительного оборудования (или емкости) в корпусы и другие емкости при производстве пиротехнических составов, металлизированных огнесмесей, прессовании, снаряжении пиротехнических изделий и изготовлении зарядов твердого пиротехнического топлива?',
        answers: [
          'Любым газом, предусмотренным проектной документацией, при исправных редукторах на линии подачи газа',
          'Только паром при исправных редукторах на линии подачи газа',
          'Только инертным газом, предусмотренным технологической документацией, при исправных редукторах на линии подачи газа',
          'Только воздухом при исправных редукторах на линии подачи газа',
        ],
        correctAnswers: [
          'Только инертным газом, предусмотренным технологической документацией, при исправных редукторах на линии подачи газа',
        ],
      },
      {
        code: '63643227',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63643228',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Периодический контроль за состоянием воздушной среды',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Предотвращение взрывов внутри технологического оборудования',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63643229',
        text:
          'Какое допускается заполнение емкостей для хранения и транспортирования легковоспламеняющихся и горючих жидкостей?',
        answers: [
          'Не более чем на 85% объема',
          'Не более чем на 95% объема',
          'Не более чем на 90% объема',
          'Не более чем на 75% объема',
        ],
        correctAnswers: ['Не более чем на 85% объема'],
      },
      {
        code: '63643230',
        text:
          'В каком случае допускается проведение работ по испытанию изделий и уничтожению отходов одновременно?',
        answers: [
          'По решению ответственного руководителя работ',
          'По решению технического руководителя организации',
          'В случаях, определенных технологической документации',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63643231',
        text:
          'Как должны располагаться внутрицеховые технологические трубопроводы с тяжелыми газами, прокладываемые по несгораемой поверхности несущих стен производственных зданий с оконными и дверными проемами?',
        answers: [
          'На 1 м выше оконных и дверных проемов',
          'На 1 м ниже оконных и дверных проемов',
          'На 0,5 м ниже оконных и дверных проемов',
          'На 0,5 м выше оконных и дверных проемов',
        ],
        correctAnswers: ['На 0,5 м ниже оконных и дверных проемов'],
      },
      {
        code: '63643232',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 50 кг',
          'Массой более 35 кг',
          'Массой более 30 кг',
          'Массой более 40 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63643233',
        text:
          'Каким из перечисленных ниже методов нельзя оценивать риск технического устройства на этапе его проектирования для идентифицированных видов опасностей?',
        answers: [
          'По нормативам, действующим в аналогичных отраслях промышленности',
          'Экспериментальным',
          'Экспертным',
          'По данным эксплуатации аналогичных машин и (или) оборудования',
          'Расчетным',
        ],
        correctAnswers: [
          'По нормативам, действующим в аналогичных отраслях промышленности',
        ],
      },
      {
        code: '63643234',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
        ],
        correctAnswers: [
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
        ],
      },
      {
        code: '63643235',
        text:
          'Какое определение соответствует термину "взрывоопасная зона" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Зона, в которой произошло разрушение оборудования, сопровождающееся выбросом опасных веществ и отключением электроэнергии',
          'Зона с высоким риском воспламенения окружающей взрывоопасной среды, связанным с возможностью причинения вреда и (или) нанесения ущерба',
          'Замкнутое пространство, в котором образовалась взрывоопасная среда и требуется незамедлительное применение средств взрывозащиты',
          'Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования',
        ],
        correctAnswers: [
          'Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования',
        ],
      },
      {
        code: '63643236',
        text:
          'Какая допускается максимальная температура подаваемого пара в мастерские плавки тротила и намотки сгорающих гильз?',
        answers: ['115 °C', '165 °C', '150 °C', '135 °C'],
        correctAnswers: ['135 °C'],
      },
      {
        code: '63643237',
        text:
          'Выполнение каких требований безопасности к разработке и изготовлению оборудования для работы во взрывоопасных средах не предусмотрено в ТР ТС 012/2011?',
        answers: [
          'Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.',
          'Оборудование должно обеспечивать взрывобезопасность при эксплуатации в течение всего предполагаемого (расчетного) срока службы',
          'Оборудование должно функционировать в фактических или прогнозируемых условиях окружающей среды',
          'Оборудование должно сохранять взрывобезопасность в изменяющихся условиях окружающей среды и при наличии внешних воздействий (влажность, вибрация, загрязнения, грозовые и коммутационные перенапряжения и др.) с учетом ограничений рабочих условий, установленных изготовителем',
        ],
        correctAnswers: [
          'Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.',
        ],
      },
      {
        code: '63643238',
        text:
          'С какой периодичностью должна проверяться правильность учета, хранения и наличия взрывчатых материалов на складах лицами, назначенными распорядительным документом организации?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Дважды в месяц'],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63643239',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 8 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 2 часов',
          'В течение 24 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63643240',
        text:
          'В каких случаях проводятся испытания технического устройства во время его изготовления?',
        answers: [
          'Если это предусмотрено Федеральными нормами и правилами по промышленной безопасности, распространяющимися на данный тип технических устройств',
          'Если на этом настаивает будущий владелец технического устройства',
          'Если монтаж технического устройства будет выполняться на месте его последующей эксплуатации',
          'Если это предусмотрено проектной (конструкторской) документацией',
          'Если изготовление технического устройства осуществляется за рубежом',
        ],
        correctAnswers: [
          'Если это предусмотрено проектной (конструкторской) документацией',
        ],
      },
      {
        code: '63643241',
        text:
          'В каком случае допускается определение толщин стенок трубопроводов иным способом, отличным от метода неразрушающего контроля?',
        answers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
          'Допускается в присутствии инспектора Ростехнадзора',
          'Допускается в соответствии с документацией на ХОПО',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается в местах, где применение неразрушающего контроля затруднено или невозможно',
        ],
      },
      {
        code: '63643242',
        text:
          'Какой документ о подтверждении соответствия требованиям ТР ТС 032/2013 должно иметь оборудование и элементы оборудования, отнесенные к 3-й категории опасности?',
        answers: [
          'Сертификат',
          'Декларация соответствия',
          'Заключение экспертизы промышленной безопасности',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Сертификат'],
      },
      {
        code: '63643243',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63643244',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 24 часов',
          'В течение 8 часов',
          'Время устанавливается в проектной документации',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63643245',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63643246',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'После проведения расчета на прочность',
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63643247',
        text:
          'Из какого материала должны быть изготовлены плавильные котлы аппаратов для приготовления взрывчатых составов, содержащих высокомощные взрывчатые вещества? Выберите два правильных варианта ответа.',
        answers: [
          'Из низкоуглеродистой стали',
          'Из чугуна',
          'Из цветного металла',
          'Из нержавеющей стали',
        ],
        correctAnswers: ['Из цветного металла', 'Из нержавеющей стали'],
      },
      {
        code: '63643248',
        text:
          'При каком условии должна проводиться очистка внутренней поверхности полировального барабана периодической флегматизации от остатков налипшего пороха после его выгрузки?',
        answers: [
          'С оформлением наряд-допуска под руководством мастера цеха',
          'С оформлением наряд-допуска под руководством мастера смены',
          'С оформлением внутреннего разрешения руководителя организации',
          'Под руководством технического руководителя',
        ],
        correctAnswers: [
          'С оформлением наряд-допуска под руководством мастера смены',
        ],
      },
      {
        code: '63643249',
        text: 'В соответствии с чем должны проверяться змеевики нитраторов?',
        answers: [
          'Только в соответствии с графиками технического осмотра оборудования',
          'В соответствии со всем перечисленным',
          'Только в соответствии с инструкциями',
          'Только в соответствии с графиками планово-предупредительного ремонта',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63643250',
        text:
          'Какая относительная влажность воздуха должна поддерживаться в помещениях, где технологический процесс связан с пересыпанием или перетеканием электризующихся взрывопожароопасных веществ?',
        answers: [
          '55%',
          '75%',
          'Установленная техническими условиями на эти вещества и регламентом технологического процесса',
          '50%',
        ],
        correctAnswers: [
          'Установленная техническими условиями на эти вещества и регламентом технологического процесса',
        ],
      },
      {
        code: '63643251',
        text:
          'Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?',
        answers: [
          'Для технологических блоков I категории взрывоопасности',
          'Для технологических блоков II категории взрывоопасности',
          'Для технологических блоков III категории взрывоопасности',
        ],
        correctAnswers: [
          'Для технологических блоков I категории взрывоопасности',
        ],
      },
      {
        code: '63643252',
        text:
          'Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?',
        answers: [
          'Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности',
        ],
        correctAnswers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
        ],
      },
      {
        code: '63643253',
        text:
          'Кто из перечисленных лиц определяет технические и организационные мероприятия, обеспечивающие безопасность ведения газоопасных, огневых и ремонтных работ? Выберите два правильных варианта ответа.',
        answers: [
          'Руководитель структурного подразделения ремонтируемого объекта',
          'Руководитель подрядной организации',
          'Непосредственный руководитель работ подрядной организации',
          'Технический руководитель ремонтируемого объекта',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения ремонтируемого объекта',
          'Непосредственный руководитель работ подрядной организации',
        ],
      },
      {
        code: '63643254',
        text:
          'Что из перечисленного допускается при производстве промышленных взрывчатых веществ?',
        answers: [
          'Патронирование аммонитов на автоматах типа АПК в кабинах',
          'Применение элеваторов для транспортирования аммонитов',
          'Применение в производстве промышленных ВВ непросеянных сыпучих компонентов',
          'Влажная уборка в помещении темперирования и дозировки алюминиевой пудры',
          'Применение ременной передачи для барабанов смешения',
        ],
        correctAnswers: [
          'Патронирование аммонитов на автоматах типа АПК в кабинах',
        ],
      },
      {
        code: '63643255',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
          'Требования к обозначению определяются при разработке плана мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63643256',
        text:
          'При каком условии допускается производить слив содержимого нитраторов в аварийную емкость при производстве нитроэфирсодержащих взрывчатых веществ? Выберите два правильных варианта ответа.',
        answers: [
          'С одновременным поступлением в нее сжатого воздуха для перемешивания и включением подачи охлаждающего агента в змеевики',
          'С одновременным поступлением в нее пара для перемешивания и включением подачи охлаждающего агента в змеевики',
          'С одновременным поступлением в нее азота для перемешивания и включением подачи охлаждающего агента в змеевики',
          'С одновременным поступлением в нее углекислого газа для перемешивания и включением подачи охлаждающего агента в змеевики',
        ],
        correctAnswers: [
          'С одновременным поступлением в нее сжатого воздуха для перемешивания и включением подачи охлаждающего агента в змеевики',
          'С одновременным поступлением в нее азота для перемешивания и включением подачи охлаждающего агента в змеевики',
        ],
      },
      {
        code: '63643257',
        text:
          'Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите два правильных варианта ответа.',
        answers: [
          'В помещении диспетчера предприятия',
          'На наружных установках',
          'На ограждении по периметру объекта через каждые 50 м',
          'На контрольно-пропускном пункте объекта',
        ],
        correctAnswers: [
          'В помещении диспетчера предприятия',
          'На наружных установках',
        ],
      },
      {
        code: '63643258',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей и рабочими площадками',
        ],
      },
      {
        code: '63643259',
        text:
          'Кем определяются технические и организационные мероприятия, обеспечивающие безопасность ремонтных работ?',
        answers: [
          'Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации',
          'Руководителем эксплуатирующей организации, объект которой подлежит ремонту, совместно с руководителем структурного подразделения ремонтируемого объекта',
          'Непосредственным руководителем работ подрядной организации по согласованию с руководителем эксплуатирующей организации, объект которой подлежит ремонту',
        ],
        correctAnswers: [
          'Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации',
        ],
      },
      {
        code: '63643260',
        text:
          'Через какое время должны протираться влажной тканью корпус шнек-дозатора и загрузочное устройство для загрузки пироксилина?',
        answers: [
          'Через каждый час',
          'Дважды в сутки',
          'Раз в сутки',
          'Через каждые три часа',
        ],
        correctAnswers: ['Через каждый час'],
      },
      {
        code: '63643261',
        text:
          'Какой максимальный срок действия декларации о соответствии серийно выпускаемого оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" со дня ее регистрации в Едином реестре выданных сертификатов соответствия и зарегистрированных деклараций о соответствии?',
        answers: ['4 года', '3 года', '5 лет', '1 год'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63643262',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63643263',
        text:
          'На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'На основании задания на проектирование',
          'На основании расчетных данных на проектирование',
          'На основании справочных данных на проектирование',
          'На основании литературных данных на проектирование',
        ],
        correctAnswers: ['На основании задания на проектирование'],
      },
      {
        code: '63643264',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случаях изгиба болтов и шпилек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случае износа боковых граней головок болтов и гаек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63643265',
        text:
          'Какие из перечисленных ниже технических устройств подлежат оценке соответствия согласно требованиям ТР ТС 010/2011?',
        answers: [
          'Изготовленные для собственных нужд их изготовителей',
          'Комплектующие изделия к техническим устройствам',
          'Бывшие ранее в эксплуатации',
          'Технические устройства, выпускаемые в обращение на единой таможенной территории Таможенного союза',
          'Запасные части к техническим устройствам, используемые для ремонта (технического обслуживания)',
        ],
        correctAnswers: [
          'Технические устройства, выпускаемые в обращение на единой таможенной территории Таможенного союза',
        ],
      },
      {
        code: '63643266',
        text:
          'Кто проводит испытания оборудования, предусмотренные проектной документацией?',
        answers: [
          'Изготовитель',
          'Проектировщик',
          'Предприятие-владелец',
          'Поставщик',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63643267',
        text:
          'На какой максимальный процент допускается превышение давления в сосуде от максимально допустимого рабочего давления при работающих предохранительных клапанах при условии, что это превышение предусмотрено руководством (инструкцией) по эксплуатации сосуда?',
        answers: ['15%', '25%', '20%', '35%'],
        correctAnswers: ['25%'],
      },
      {
        code: '63643268',
        text:
          'В какой из приведенных форм не проводится оценка соответствия оборудования, работающего под давлением, на которое распространяется действие ТР ТС 032/2013?',
        answers: [
          'Экспертизы промышленной безопасности',
          'Сертификации',
          'Декларирования соответствия',
          'Государственного контроля (надзора)',
        ],
        correctAnswers: ['Экспертизы промышленной безопасности'],
      },
      {
        code: '63643269',
        text:
          'В каком случае допускается вручную загружать в сушилки и выгружать из них ВВ?',
        answers: [
          'При условии обеспечения безопасности работников в случаях, предусмотренных технологическим регламентом, утвержденным руководителем организации (его заместителем), эксплуатирующей ОПО',
          'В исключительном случае, на усмотрение работника и в присутствии наблюдающего',
          'Ни в каком случае',
          'В случае выполнения данных работ не менее двумя работниками в присутствии наблюдающего',
        ],
        correctAnswers: [
          'При условии обеспечения безопасности работников в случаях, предусмотренных технологическим регламентом, утвержденным руководителем организации (его заместителем), эксплуатирующей ОПО',
        ],
      },
      {
        code: '63643270',
        text:
          'При каких режимах работы должна обеспечиваться взрывобезопасность оборудования?',
        answers: [
          'Только при соблюдении условий, установленных в технической документации изготовителя',
          'Только в нормальных режимах работы',
          'В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя',
          'Взрывобезопасность должна быть обеспечена независимо от режимов работы',
        ],
        correctAnswers: [
          'В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя',
        ],
      },
      {
        code: '63643271',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Энергией сгорания парогазовой фазы в кДж',
          'Категорией взрывоопасности',
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Радиусом зон разрушения в м',
        ],
        correctAnswers: ['Категорией взрывоопасности'],
      },
      {
        code: '63643272',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63643273',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Разработчик проекта',
          'Технический руководитель организации',
          'Разработчик технологического процесса',
          'Представитель организации - изготовителя систем противоаварийной автоматической защиты',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63643274',
        text:
          'Какие требования к обязанностям непосредственного руководителя ремонтных работ указаны неверно?',
        answers: [
          'Должен знать безопасные методы и приемы ведения ремонтных работ',
          'Должен знать возможные опасности и характер их проявления при производстве ремонтных работ',
          'При выполнении ремонтных работ должен назначать ответственного за ходом работ из состава ремонтной бригады',
        ],
        correctAnswers: [
          'При выполнении ремонтных работ должен назначать ответственного за ходом работ из состава ремонтной бригады',
        ],
      },
      {
        code: '63643275',
        text:
          'Чем должны быть оснащены нитрационные аппараты на особо опасных операциях?',
        answers: [
          'Приборами и датчиками автоматического и дистанционного контроля',
          'Системами противоаварийной защиты',
          'Аварийными спусками или устройствами для залива водой, управляемыми вручную по месту, автоматически или дистанционно с пульта управления',
          'Устройствами, предусмотренными в технологических регламентах',
        ],
        correctAnswers: [
          'Аварийными спусками или устройствами для залива водой, управляемыми вручную по месту, автоматически или дистанционно с пульта управления',
        ],
      },
      {
        code: '63643276',
        text:
          'Что необходимо учитывать при размещении технологического оборудования в помещениях и на открытых площадках?',
        answers: [
          'Возможность быстрого демонтажа оборудования',
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'Соблюдение расстояний, предусмотренных требованиями пожарной безопасности',
          'Минимизацию контакта с коррозионно-активными веществами',
        ],
        correctAnswers: [
          'Возможность проведения ремонтных работ, визуального контроля и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
        ],
      },
      {
        code: '63643277',
        text:
          'Чем должны оснащаться указатели уровня жидкости на оборудовании, давление которого превышает 4,5 МПа?',
        answers: [
          'Двумя дублирующими указателями уровня жидкости непрямого действия',
          'Двумя дополнительными сниженными дистанционными указателями уровня жидкости',
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
          'Дополнительными звуковыми, световыми и другими сигнализаторами и блокировками по предельным уровням жидкости',
        ],
        correctAnswers: [
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
        ],
      },
      {
        code: '63643278',
        text:
          'Какие из указанных факторов, которые необходимо учитывать при оценке риска аварий для объектов производств боеприпасов и спецхимии, указан неверно?',
        answers: [
          'Уровень подготовки персонала',
          'Действие блокировок, систем и средств автоматического контроля и регулирования',
          'Влияние степени быстродействия и эффективности систем ПАЗ',
          'Защитные мероприятия по эвакуации людей',
        ],
        correctAnswers: ['Уровень подготовки персонала'],
      },
      {
        code: '63643279',
        text:
          'Какая из схем декларирования соответствия применяется для новых технических устройств, применяемых на опасном производственном объекте?',
        answers: ['5Д', '4Д', '3Д', '2Д', '1Д'],
        correctAnswers: ['5Д'],
      },
      {
        code: '63643280',
        text:
          'Какой документ о подтверждении соответствия требованиям ТР ТС 032/2013 должно иметь оборудование и элементы оборудований, отнесенные ко 2-й категории опасности, изготовление или окончательное изготовление которых с применением неразъемных соединений осуществляется по месту эксплуатации?',
        answers: [
          'Сертификат',
          'Заключение экспертизы промышленной безопасности',
          'Декларация соответствия',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Декларация соответствия'],
      },
      {
        code: '63643281',
        text:
          'Какое количество экземпляров наряд-накладной должно выписываться при передаче взрывчатых веществ и составов на их основе, а также изделий, их содержащих, с одного склада на другой, принадлежащий одной и той же организации?',
        answers: [
          'Один экземпляр',
          'Четыре экземпляра',
          'Три экземпляра',
          'Два экземпляра',
        ],
        correctAnswers: ['Три экземпляра'],
      },
      {
        code: '63643282',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха КИПиА на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63643283',
        text:
          'Какие действия должны выполнить непосредственный руководитель работ подрядной организации совместно с руководителем структурного подразделения ремонтируемого объекта после окончания ремонтных работ?',
        answers: [
          'Закрыть наряд-допуск на проведение ремонтных работ и подписать его',
          'Все перечисленные',
          'Оформить акт сдачи-приемки объекта в эксплуатацию по форме, установленной внутренними документами эксплуатирующей организации',
          'Проверить полноту выполнения ремонтных работ в целях безопасного ввода в эксплуатацию объекта',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63643284',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Должны выполняться все перечисленные мероприятия',
          'Только испытание на прочность пробным давлением',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63643285',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
          'Время срабатывания систем защиты должно равняться времени, необходимому для перехода параметра от предупредительного до предельно допустимого значения',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63643286',
        text:
          'При наличии в зоне проведения огневых работ сгораемых конструкций, каким образом последние должны быть защищены от возгораний?',
        answers: [
          'Только пролиты водой',
          'Только очищены от взрывопожароопасных продуктов',
          'Всеми перечисленными способами',
          'Только закрыты металлическими или асбестовыми экранами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63643287',
        text:
          'Какими документами определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63643288',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63643289',
        text:
          'На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Выберите два правильных варианта ответа.',
        answers: [
          'На всех объектах ХОПО',
          'На объектах ХОПО II класса',
          'На объектах ХОПО IV класса',
          'На объектах ХОПО III класса',
          'На объектах ХОПО I класса',
        ],
        correctAnswers: [
          'На объектах ХОПО IV класса',
          'На объектах ХОПО III класса',
        ],
      },
      {
        code: '63643290',
        text:
          'Что такое "назначенный ресурс" технического устройства опасного производственного объекта, после которого может осуществляться ликвидация технического устройства?',
        answers: [
          'Продолжительность эксплуатации технического устройства, при котором его дальнейшая эксплуатация недопустима или нецелесообразна',
          'Наличие остаточных деформаций металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима',
          'Наличие трещин металлоконструкции технического устройства, при котором его дальнейшая эксплуатация недопустима',
          'Суммарная наработка, при достижении которой эксплуатация технического устройства должна быть прекращена независимо от его технического состояния',
          'Продолжительность эксплуатации в машино-часах или циклах, при достижении которой необходимо принять решение по его ликвидации',
        ],
        correctAnswers: [
          'Суммарная наработка, при достижении которой эксплуатация технического устройства должна быть прекращена независимо от его технического состояния',
        ],
      },
      {
        code: '63643291',
        text:
          'Из какого материала допускается применять инструмент для всех работ с СТРТ?',
        answers: [
          'Только из текстолита',
          'Только из фторопласта',
          'Из всех перечисленных материалов',
          'Только из резины',
        ],
        correctAnswers: ['Из всех перечисленных материалов'],
      },
      {
        code: '63643292',
        text:
          'Если сосуд работает с газообразной средой группы 1, то в каком случае этот сосуд оборудуется трубопроводами, отводящими рабочую среду от предохранительных клапанов в атмосферу?',
        answers: [
          'Если плотность отводимых газов по отношению к воздуху не превышает 0,8',
          'Если рабочее давление в сосуде не превышает 1,6 МПа и при выводе в атмосферу обеспечивается безопасное рассеивание рабочей среды',
          'Если рабочее давление в сосуде не превышает 1,2 МПа и при выводе в атмосферу обеспечивается безопасное рассеивание рабочей среды',
          'Если при отводе рабочей среды обеспечиваются безопасность обслуживающего сосуд персонала и нормы пожарной безопасности',
        ],
        correctAnswers: [
          'Если плотность отводимых газов по отношению к воздуху не превышает 0,8',
        ],
      },
      {
        code: '63643293',
        text:
          'Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?',
        answers: [
          'Получение разрешения на применение технических устройств, выдаваемого органами Ростехнадзора',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок, указанных в технической документации организации-изготовителя и предусмотренных конструкцией установки в соответствии с требованиями Технического регламента "О безопасности машин и оборудования"',
          'Все вышеперечисленное',
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок, указанных в технической документации организации-изготовителя и предусмотренных конструкцией установки в соответствии с требованиями Технического регламента "О безопасности машин и оборудования"',
        ],
      },
      {
        code: '63643294',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Пневматическое испытание должно проводиться только в условиях освещенности площадки проведения работ, достаточной для наблюдения за испытываемым оборудованием',
          'Поддержание положительной (выше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63643295',
        text:
          'Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Общеобменная вентиляция должна включаться при срабатывании установленных в помещении сигнализаторов довзрывных концентраций',
          'Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции в случае недостаточности воздухообмена',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой ОПО, в том числе у стационарных источников зажигания',
        ],
        correctAnswers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой ОПО, в том числе у стационарных источников зажигания',
        ],
      },
      {
        code: '63643296',
        text:
          'В каком случае допускаются отклонения от действующего проекта, на основании которого проводятся работы, связанные с восстановлением работоспособного (исправного) состояния технологических трубопроводов?',
        answers: [
          'По решению комиссии эксплуатирующей организации',
          'При наличии письменного распоряжения технического руководителя эксплуатирующей организации',
          'В случае согласования необходимого отклонения с территориальным органом Ростехнадзора',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: ['Не допускаются ни в каком случае'],
      },
    ],
    63634: [
      {
        code: '63634000',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'Только в исходных данных на проектирование',
          'Только в проектной документации',
          'Только в технологическом регламенте',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63634001',
        text:
          'Что должны обеспечивать системы контроля, автоматического и дистанционного управления (системы управления), системы оповещения об аварийных ситуациях в разделе технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
          'Надежность и безопасность проведения технологических процессов, мониторинг входных параметров процесса',
          'Контроль за параметрами процесса, надежность применяемого оборудования, контроль за действиями персонала',
        ],
        correctAnswers: [
          'Точность поддержания технологических параметров, надежность и безопасность проведения технологических процессов',
        ],
      },
      {
        code: '63634002',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии со сценариями возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
          'В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий и их развития',
          'В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63634003',
        text:
          'С какой периодичностью необходимо проверять отходящую из конденсатора воду на присутствие аммиака?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в полгода',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63634004',
        text:
          'С какой периодичностью необходимо проверять промежуточный хладоноситель в системах охлаждения на присутствие аммиака?',
        answers: [
          'Не реже одного раза в полгода',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в неделю',
          'Не реже одного раза в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634005',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах I и II классов опасности?',
        answers: [
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Не более 300 секунд',
          'Не более 240 секунд',
        ],
        correctAnswers: ['Не более 12 секунд'],
      },
      {
        code: '63634006',
        text:
          'Какого цвета должен быть сигнал об опасном повышении верхнего уровня жидкого аммиака в сосудах и аппаратах (предупредительная сигнализация)?',
        answers: ['Белого', 'Желтого', 'Красного', 'Синего'],
        correctAnswers: ['Желтого'],
      },
      {
        code: '63634007',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
          'Во всех перечисленных случаях производятся диагностические работы',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63634008',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальник производственно-технического (технического) отдела организации',
          'Начальник производства',
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
        correctAnswers: [
          'Главный механик и главный энергетик организации',
          'Главный метролог организации',
        ],
      },
      {
        code: '63634009',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63634010',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63634011',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ взрывопожароопасных веществ',
          'Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек',
          'Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ взрывопожароопасных веществ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63634012',
        text:
          'Каким должно быть расстояние от охлаждающих батарей до грузового штабеля в холодильных камерах?',
        answers: [
          'Не менее 0,1 м',
          'Не менее 0,2 м',
          'Не менее 0,3 м',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее 0,3 м'],
      },
      {
        code: '63634013',
        text:
          'Кем должна определяться готовность холодильной системы к заполнению хладагентом после завершения монтажных работ и проведения испытаний на прочность и плотность?',
        answers: [
          'Комиссией под председательством представителя Ростехнадзора',
          'Техническим руководителем эксплуатирующей организацией',
          'Комиссией эксплуатирующей организации',
          'Проектной организацией',
        ],
        correctAnswers: ['Комиссией эксплуатирующей организации'],
      },
      {
        code: '63634014',
        text:
          'Какой устанавливается срок действия разовых (опытных) технологических регламентов, в соответствии с которыми проводится наработка опытной продукции в течение нескольких лет?',
        answers: [
          'Не более 5 лет',
          'Не более 1 года',
          'Не более 3 лет',
          'Не более 10 лет',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63634015',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63634016',
        text:
          'Каким должно быть время срабатывания автоматических быстродействующих запорных и (или) отсекающих устройств на объектах III класса опасности?',
        answers: [
          'Не менее 12 секунд',
          'Не более 120 секунд',
          'Не менее 300 секунд',
          'Не менее 240 секунд',
        ],
        correctAnswers: ['Не более 120 секунд'],
      },
      {
        code: '63634017',
        text: 'Какой устанавливается срок действия "накопительной ведомости"?',
        answers: ['1 месяц', '6 месяцев', '3 месяца', 'Один год'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63634018',
        text:
          'Какие технологические регламенты разрабатываются при выпуске товарной продукции на опытных и опытно-промышленных установках (цехах), а также для опытных и опытно-промышленных работ, проводимых на действующих производствах?',
        answers: [
          'Постоянные технологические регламенты',
          'Разовые (опытные) технологические регламенты',
          'Временные технологические регламенты',
          'Лабораторные технологические регламенты',
        ],
        correctAnswers: ['Разовые (опытные) технологические регламенты'],
      },
      {
        code: '63634019',
        text: 'В каком случае насос должен быть немедленно остановлен?',
        answers: [
          'Только если упало давление напора или разность давлений напора и всасывания (при отсутствии или отказе приборов автоматики)',
          'Только если появились утечки аммиака через неплотности агрегата',
          'Только если обнаружены неисправности манометров, обратных клапанов, средств КИПиА',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63634020',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Запорный клапан',
          'Предохранительный клапан',
          'Гидроклапан',
          'Регулирующий клапан',
          'Обратный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63634021',
        text:
          'Информацию о каких организациях должен содержать раздел технологического регламента "Общая характеристика производства"?',
        answers: [
          'Организации-разработчике технологического процесса',
          'Организации, выполнившей проектную документацию',
          'Организации, выполнившей функции генерального проектировщика',
          'Организации-разработчике технологической части проектной документации',
          'О всех перечисленных организациях',
        ],
        correctAnswers: ['О всех перечисленных организациях'],
      },
      {
        code: '63634022',
        text:
          'Кто утверждает годовые и месячные графики ремонта холодильного оборудования?',
        answers: [
          'Руководитель организации',
          'Начальник службы производственного контроля',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63634023',
        text:
          'Какой вид временных соединений может применяться для стыковки железнодорожной цистерны к стационарным узлам холодильной установки?',
        answers: [
          'Только гибкие рукава из неметаллических материалов',
          'Только гибкие металлические рукава',
          'Только шарнирные поворотные соединения',
          'Все перечисленные виды соединений',
        ],
        correctAnswers: ['Все перечисленные виды соединений'],
      },
      {
        code: '63634024',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Характеристика производимой продукции',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Возможные инциденты в работе и способы их ликвидации',
          'Технологические схемы производства',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63634025',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63634026',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Любым из перечисленных лиц',
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63634027',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На организацию - разработчика процесса',
          'На технологическую службу организации, производства, отделения, установки',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63634028',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63634029',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63634030',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения представителя газоспасательной службы, и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования',
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63634031',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63634032',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'На трубопроводах, идущих по стенам зданий',
          'Над автодорогами',
          'На трубопроводах, проложенных по эстакадам',
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63634033',
        text:
          'Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Класс опасности обращающихся в процессе веществ',
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
      },
      {
        code: '63634034',
        text:
          'Какое действие необходимо предпринять в случае перерыва слива аммиака?',
        answers: [
          'Цистерна остается присоединенной к системе под наблюдением работников, назначенных приказом по организации',
          'Съемные участки трубопроводов должны быть отсоединены от цистерн',
          'На время перерыва допускается оставлять цистерну присоединенной к системе',
          'По усмотрению лица, ответственного за производство работ по сливу аммиака',
        ],
        correctAnswers: [
          'Съемные участки трубопроводов должны быть отсоединены от цистерн',
        ],
      },
      {
        code: '63634035',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63634036',
        text:
          'В каком документе организация, эксплуатирующая химически опасные производственные объекты I, II и III классов опасности, должна предусматривать действия работников по предупреждению аварий, их локализации и максимальному снижению тяжести последствий?',
        answers: [
          'В техническом регламенте',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'Только в Положении о производственном контроле',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63634037',
        text:
          'Что учитывается при определении пропускной способности предохранительных устройств для защиты от разрушений сосудов, аппаратов и технологического оборудования, содержащих жидкий аммиак?',
        answers: [
          'Площадь наружной поверхности аппарата и удельная теплота парообразования аммиака при давлении насыщения в 3 раза больше расчетного давления защищаемого аппарата',
          'Скорость теплового потока испарившегося аммиака в случае пожара',
          'Плотность теплового потока через наружные стенки сосуда или аппарата, площадь наружной поверхности аппарата или сосуда и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого сосуда (аппарата)',
          'Общий объем аммиака в аппарате',
        ],
        correctAnswers: [
          'Плотность теплового потока через наружные стенки сосуда или аппарата, площадь наружной поверхности аппарата или сосуда и удельная теплота парообразования аммиака при давлении насыщения в 1,15 раза больше расчетного давления защищаемого сосуда (аппарата)',
        ],
      },
      {
        code: '63634038',
        text:
          'В случае какой остановки сосуды, аппараты и трубопроводы холодильных установок должны подвергаться техническому освидетельствованию?',
        answers: [
          'Длительность остановки не влияет на необходимость проведения технического освидетельствования',
          'В случае остановки более одного месяца',
          'В случае остановки более трех месяцев',
          'В случае остановки более шести месяцев',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634039',
        text:
          'В какой цвет должны быть окрашены участки аммиачных трубопроводов, на которые наносятся опознавательные кольца?',
        answers: [
          'В синий цвет',
          'В черный цвет',
          'В желтый цвет',
          'В красный цвет',
        ],
        correctAnswers: ['В желтый цвет'],
      },
      {
        code: '63634040',
        text:
          'В каком случае допускается наработка товарной продукции по лабораторным регламентам (пусковым запискам, производственным методикам)?',
        answers: [
          'Объемом до 1000 кг/год',
          'Объемом до 1500 кг/год',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Объемом до 1000 кг/год'],
      },
      {
        code: '63634041',
        text: 'В каком случае допускается запускать аммиачный насос?',
        answers: [
          'Только при отсутствии защитного кожуха муфты',
          'Только при закрытых клапанах на его входе и выходе',
          'Только при неполном заполнении насоса жидким хладагентом',
          'Во всех перечисленных случаях',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634042',
        text:
          'Какая минимальная продолжительность испытаний на плотность всей системы сосудов, аппаратов и трубопроводов?',
        answers: ['12 часов', '15 часов', '8 часов', '3 часа'],
        correctAnswers: ['12 часов'],
      },
      {
        code: '63634043',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С газоспасательной службой и службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63634044',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63634045',
        text:
          'Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Постоянные, временные, разовые и лабораторные',
          'Периодически пересматриваемые',
          'Входящие в состав проектной документации или пусковые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63634046',
        text:
          'Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией',
          'Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63634047',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только специалист, обученный по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63634048',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63634049',
        text:
          'Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?',
        answers: [
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 допускается ручное дистанционное управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63634050',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63634051',
        text:
          'С какой периодичностью проводятся наружный осмотр и испытание пробным давлением при техническом освидетельствовании трубопроводов? Выберите два правильных варианта ответа.',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 8 лет',
          'По окончании монтажных работ перед пуском в эксплуатацию',
        ],
        correctAnswers: [
          'Не реже 1 раза в 8 лет',
          'По окончании монтажных работ перед пуском в эксплуатацию',
        ],
      },
      {
        code: '63634052',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Периодический контроль за состоянием воздушной среды',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в объеме производственных зданий, сооружений',
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Предотвращение взрывов внутри технологического оборудования',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63634053',
        text:
          'Где допускается размещать насосы оборотного водоснабжения в обоснованных в проектной документации случаях? Выберите два правильных варианта ответа.',
        answers: [
          'На открытой площадке',
          'Над машинным отделением',
          'В машинном отделении',
          'В аппаратном отделении',
        ],
        correctAnswers: ['В машинном отделении', 'В аппаратном отделении'],
      },
      {
        code: '63634054',
        text:
          'Какое из перечисленных требований к линейным ресиверам не допускается?',
        answers: [
          'Допускается предусматривать для систем холодоснабжения с количеством заправленного аммиака до 1000 кг один линейный ресивер, объем которого должен рассчитываться на годовой запас аммиака',
          'Допускается предусматривать дополнительные линейные ресиверы для хранения годового запаса аммиака',
          'Допускается предусматривать ресиверы для хранения аммиака с вместимостью, обоснованной проектной документацией и позволяющей принять аммиак из одной транспортной единицы',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634055',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны уметь пользоваться СИЗ',
          'Исполнители должны пройти инструктаж',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63634056',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63634057',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований Правил противопожарного режима в Российской Федерации',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63634058',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['40 °C', '45 °C', '50 °C', '55 °C'],
        correctAnswers: ['50 °C'],
      },
      {
        code: '63634059',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63634060',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63634061',
        text:
          'В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?',
        answers: [
          'Для ступени низкого давления двухступенчатых компрессоров',
          'Для ступени высокого давления двухступенчатых компрессоров',
          'Для одноступенчатых компрессоров',
        ],
        correctAnswers: [
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
      },
      {
        code: '63634062',
        text:
          'Какое допускается максимальное заполнение геометрического объема дренажного ресивера для аварийного (ремонтного) освобождения от жидкого аммиака охлаждающих устройств, аппаратов, сосудов и блоков?',
        answers: ['На 50%', 'На 80%', 'На 90%', 'На 70%'],
        correctAnswers: ['На 80%'],
      },
      {
        code: '63634063',
        text:
          'Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?',
        answers: [
          'Не ниже 20 °C и не более 50 °C соответственно',
          'Не ниже 10 °C и не более 45 °C соответственно',
          'Не ниже 15 °C и не более 40 °C соответственно',
          'Не ниже 5 °C и не более 35 °C соответственно',
        ],
        correctAnswers: ['Не ниже 10 °C и не более 45 °C соответственно'],
      },
      {
        code: '63634064',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
          'Время срабатывания определяется расчетом',
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63634065',
        text:
          'Какие сроки освидетельствования систем холодоснабжения с ограниченной зарядкой аммиаком (не более 50 кг), поставляемых комплектно организациями-изготовителями?',
        answers: [
          'Не реже 1 одного раза в год',
          'Устанавливаются эксплуатационной документацией организаций-изготовителей',
          'Устанавливаются Правилами безопасности химически опасных производственных объектов',
          'Устанавливаются лицом, ответственным за исправное состояние и безопасную работу сосудов (аппаратов)',
        ],
        correctAnswers: [
          'Устанавливаются эксплуатационной документацией организаций-изготовителей',
        ],
      },
      {
        code: '63634066',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63634067',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63634068',
        text:
          'С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?',
        answers: [
          'Не реже 1 раза в 10 лет',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
        correctAnswers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63634069',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'Во время грозы',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63634070',
        text:
          'В соответствии с какими документами осуществляют ведение технологических процессов на химически опасных производственных объектах?',
        answers: [
          'В соответствии с декларацией промышленной безопасности',
          'В соответствии с проектной документацией',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с руководствами (инструкциями) по эксплуатации технических устройств',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63634071',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63634072',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Только наличие природных оврагов, выемок, низин',
          'Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63634073',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
          'Руководитель эксплуатирующей организации',
          'Руководитель структурного подразделения или лицо, его замещающее',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63634074',
        text:
          'По какой категории надежности должно осуществляться электроснабжение химически опасных производственных объектов?',
        answers: [
          'По II или III категории надежности',
          'По I или II категории надежности',
          'Допустимая категория надежности устанавливается разработчиком проекта в зависимости от применяемой технологии',
          'Только по I категории надежности',
        ],
        correctAnswers: ['По I или II категории надежности'],
      },
      {
        code: '63634075',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'Только в технологическом регламенте на производство продукции',
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'Только в проектной документации',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63634076',
        text:
          'Какие действия должны быть предприняты при обнаружении нарушений требований Правил безопасности химически опасных производственных объектов в отношении цистерн с жидким аммиаком?',
        answers: [
          'Аммиак должен быть немедленно слит',
          'Немедленно сообщить в территориальный орган Ростехнадзора',
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
          'Необходимо поставить круглосуточное наблюдение за цистернами до устранения нарушений',
        ],
        correctAnswers: [
          'Должен быть составлен акт и необходимо сообщить об этом организации-наполнителю',
        ],
      },
      {
        code: '63634077',
        text:
          'Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством допуска персонала к самостоятельной работе без прохождения стажировки',
          'Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала',
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
          'Посредством наглядных пособий (плакатов) по охране труда',
        ],
        correctAnswers: [
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
      },
      {
        code: '63634078',
        text:
          'В течение какого времени нахождения цистерн с жидким аммиаком на территории организации должно быть организовано наблюдение за ними?',
        answers: [
          'В течение слива аммиака',
          'В течение всего времени нахождения',
          'В течение периода приемки цистерны',
          'Наблюдение за цистернами с жидким аммиаком не требуется',
        ],
        correctAnswers: ['В течение всего времени нахождения'],
      },
      {
        code: '63634079',
        text:
          'Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?',
        answers: [
          'Первичный пуск компрессора в работу необходимо выполнять вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя',
          'Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты',
          'При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63634080',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О знании действий при пожаре',
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания первой помощи',
          'О самочувствии',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63634081',
        text:
          'В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите два правильных варианта ответа.',
        answers: [
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
          'При поступлении сигнала от датчиков повышения температуры',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63634082',
        text:
          'Каким образом устанавливается и оформляется срок продления действия временного технологического регламента?',
        answers: [
          'Приказом руководителя организации',
          'Приказом главного инженера организации (технического директора, директора по производству)',
          'Приказом технологической службы организации, производства, отделения, установки',
          'Приказом комиссии организации',
        ],
        correctAnswers: ['Приказом руководителя организации'],
      },
      {
        code: '63634083',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['20 °C', '35 °C', '25 °C', '30 °C'],
        correctAnswers: ['30 °C'],
      },
      {
        code: '63634084',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 15% НКПР',
          'При 10% НКПР',
          'При 18% НКПР',
          'При 20% НКПР',
        ],
        correctAnswers: ['При 20% НКПР'],
      },
      {
        code: '63634085',
        text:
          'Какие номинальные величины загазованности аммиаком должны контролироваться в помещениях машинных и аппаратных отделений аммиачных холодильных установок?',
        answers: [
          '10, 125 и 500 мг/м³',
          '5, 300 и 1500 мг/м³',
          '20, 60 и 500 мг/м³',
          '15, 70 и 1000 мг/м³',
        ],
        correctAnswers: ['20, 60 и 500 мг/м³'],
      },
      {
        code: '63634086',
        text:
          'Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?',
        answers: [
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63634087',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '6 месяцев',
          '24 месяца',
          '36 месяцев',
          '12 месяцев',
          '3 месяца',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63634088',
        text:
          'Каким из перечисленных требованиям должны соответствовать работники организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) технологических трубопроводов в процессе его эксплуатации?',
        answers: [
          'Только применять контрольные средства, приборы, устройства при проверке, наладке и испытаниях',
          'Только иметь документы о прохождении аттестации',
          'Только соблюдать порядок и методы выполнения работ по наладке и регулированию элементов технологического трубопровода',
          'Только иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63634089',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63634090',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Воздушными изолирующими аппаратами',
          'Защитными очками и защитной каской',
          'Всеми перечисленными средствами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63634091',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Циклонами',
          'Устройствами для подключения линий водорода',
          'Устройствами для подключения линий воды, пара, инертного газа',
          'Виброситом',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63634092',
        text:
          'С какой периодичностью проводится только наружный осмотр при техническом освидетельствовании трубопроводов?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 2 года'],
      },
      {
        code: '63634093',
        text:
          'На каком расстоянии от опор и подвесок следует располагать сварные стыки трубопроводов для труб диаметром менее 50 мм?',
        answers: [
          'Не менее 250 мм',
          'Не менее 100 мм',
          'Не менее 200 мм',
          'Не менее 150 мм',
        ],
        correctAnswers: ['Не менее 100 мм'],
      },
      {
        code: '63634094',
        text:
          'Какое из перечисленных требований к манометрам, контролирующим давление при испытании на прочность сосудов (аппаратов), трубопроводов, указано неверно?',
        answers: [
          'Давление при испытании должно контролироваться двумя манометрами, опломбированными и прошедшими поверку',
          'Один манометр должен устанавливаться у источника давления (воздушный компрессор, баллон с инертным газом), другой - на сосуде (аппарате) и трубопроводе в точке, наименее удаленной от воздушного компрессора',
          'Манометры должны быть одинакового класса точности, не ниже 2,5',
          'Манометры должны быть с диаметром корпуса не менее 160 мм и шкалой на номинальное давление, равное 4/3 от измеряемого давления',
        ],
        correctAnswers: [
          'Манометры должны быть одинакового класса точности, не ниже 2,5',
        ],
      },
      {
        code: '63634095',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более 6 месяцев',
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более одного года',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63634096',
        text:
          'В каком случае допускается объединение выбросов химически опасных веществ, содержащих вещества, способные при смешивании образовывать более опасные по воздействиям химические соединения?',
        answers: [
          'Если выбросы рассчитываются в количествах, определяемых условиями безопасной остановки технологического процесса',
          'В случае использования специальных систем аварийного освобождения',
          'В случае разработки необходимых мер, предусмотренных документацией на ХОПО',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63634097',
        text:
          'Кто устанавливает назначенный срок службы для технологических трубопроводов?',
        answers: [
          'Орган по сертификации',
          'Организация-изготовитель',
          'Разработчик документации',
          'Орган по сертификации на основании заключения испытательной лаборатории',
        ],
        correctAnswers: ['Разработчик документации'],
      },
      {
        code: '63634098',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
          'Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категории взрывоопасности предусматривается автоматическое управление подачей инертных сред, с технологическими блоками III категории - управление дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление',
        ],
      },
      {
        code: '63634099',
        text:
          'На каких объектах ХОПО технические решения по обеспечению надежности контроля параметров, имеющих критические значения, обосновываются разработчиком документации на ХОПО? Выберите два правильных варианта ответа.',
        answers: [
          'На всех объектах ХОПО',
          'На объектах ХОПО II класса опасности',
          'На объектах ХОПО I класса опасности',
          'На объектах ХОПО IV класса опасности',
          'На объектах ХОПО III класса опасности',
        ],
        correctAnswers: [
          'На объектах ХОПО IV класса опасности',
          'На объектах ХОПО III класса опасности',
        ],
      },
      {
        code: '63634100',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Организацией-разработчиком процесса',
          'Комиссией под председательством инспектора территориального органа Ростехнадзора',
          'Специализированной сторонней организацией',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63634101',
        text:
          'С какой периодичностью должно проводиться техническое освидетельствование сосудов и аппаратов холодильных установок?',
        answers: [
          'Один раз перед пуском в работу',
          'Не реже одного раза в пятнадцать лет',
          'Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением',
          'Периодичность устанавливается только распорядительными документами эксплуатирующей организации',
        ],
        correctAnswers: [
          'Периодичность определяется с учетом требований федеральных норм и правил в области промышленной безопасности, устанавливающих правила промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением',
        ],
      },
      {
        code: '63634102',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии главного инженера и начальника службы охраны труда',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63634103',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63634104',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену',
        ],
        correctAnswers: [
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63634105',
        text:
          'Какой устанавливается срок действия постоянного технологического регламента?',
        answers: [
          'Не более 3 лет',
          'Не более 10 лет',
          'Не более 5 лет',
          'Не более 20 лет',
        ],
        correctAnswers: ['Не более 10 лет'],
      },
      {
        code: '63634106',
        text: 'Что относится к технологическим трубопроводам?',
        answers: [
          'Трубопроводы, предназначенные для транспортирования различных веществ, необходимых для ведения технологического процесса или эксплуатации оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
        correctAnswers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
      },
      {
        code: '63634107',
        text:
          'Каким образом определяется срок действия временного технологического регламента при отсутствии установленных планами норм освоения производства?',
        answers: [
          'Комиссией организации под председательством представителя проектной организации',
          'Технологическим руководителем организации',
          'Лицом, утверждающим технологический регламент',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['Лицом, утверждающим технологический регламент'],
      },
      {
        code: '63634108',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63634109',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63634110',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63634111',
        text:
          'Кто должен лично убедиться после окончания работ внутри емкости, что в емкости не остались люди, убран инструмент, материалы, не осталось посторонних предметов, и сделать об этом запись в наряде-допуске?',
        answers: [
          'Лицо, ответственное за проведение газоопасных работ',
          'Лицо, ответственное за осуществление производственного контроля',
          'Начальник цеха',
          'Главный инженер',
        ],
        correctAnswers: ['Лицо, ответственное за проведение газоопасных работ'],
      },
      {
        code: '63634112',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63634113',
        text:
          'Какой из перечисленных документов не содержит документация, поставляемая потребителю с холодильными компрессорами, насосами, компрессорно-аппаратными агрегатами, комплектными установками (станциями) и машинами?',
        answers: [
          'Паспорта предохранительных клапанов и приборов',
          'Инструкцию по эксплуатации',
          'Паспорт с техническими характеристиками оборудования и примененных материалов для его изготовления',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634114',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'Только в соответствии с проектной документацией',
          'Только в соответствии с рабочей документацией',
          'В соответствии со всем перечисленным',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63634115',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Аварийно-спасательной службе организации',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63634116',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Разработать проект производства работ',
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63634117',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63634118',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Рациональный подбор взаимодействующих компонентов исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Надежное энергообеспечение',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63634119',
        text:
          'Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Только с пластмассовым уплотнением в затворе',
          'Соответствующая проектной документации (документации на техническое перевооружение)',
          'Только с тканевым уплотнением в затворе',
          'Только с резиновым уплотнением в затворе',
        ],
        correctAnswers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63634120',
        text:
          'В течение какого минимального отрезка времени сосуд (аппарат), трубопровод должен находиться под пробным давлением, после чего давление постепенно должно быть снижено до расчетного, при котором проводится осмотр наружной поверхности сосуда (аппарата, трубопровода) с проверкой плотности его швов и разъемных соединений мыльным раствором или другим способом?',
        answers: [
          'В течение 10 минут',
          'В течение 20 минут',
          'В течение 15 минут',
          'Не регламентируется',
        ],
        correctAnswers: ['В течение 15 минут'],
      },
      {
        code: '63634121',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано только управление по месту',
          'Должно быть организовано только управление дистанционно (из безопасного места)',
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление и по месту, и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63634122',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63634123',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К группе работ средней степени опасности',
          'К II группе',
          'К I группе',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63634124',
        text:
          'С какой периодичностью необходимо проверять исправность защитных реле уровня на аппаратах (сосудах)?',
        answers: [
          '1 раз в 3 месяца',
          '1 раз в месяц',
          '1 раз в 6 месяцев',
          '1 раз в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634125',
        text:
          'Какой документ необходимо оформлять при выполнении монтажных работ в помещении и на участках действующей холодильной системы, а также в условиях недействующих узлов, находящихся под аммиаком или не отсоединенных от остальной части системы?',
        answers: [
          'План работ, утвержденный техническим руководителем организации',
          'Внутреннее распоряжение о производстве указанных работ',
          'Наряд-допуск на указанные работы',
          'Оформление дополнительного документа на проведение указанных работ не требуется',
        ],
        correctAnswers: ['Наряд-допуск на указанные работы'],
      },
      {
        code: '63634126',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63634127',
        text:
          'На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?',
        answers: [
          'На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
          'На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа',
        ],
        correctAnswers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды, при регламентированном давлении',
        ],
      },
      {
        code: '63634128',
        text:
          'В каком случае не допускается применение гибких резиновых или пластмассовых шлангов?',
        answers: [
          'При проведении операций слива аммиака (при заполнении системы) из цистерны',
          'В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака',
          'Для выполнения вспомогательных операций (освобождение трубопроводов, аппаратов, фильтров от остатков аммиака, масла)',
        ],
        correctAnswers: [
          'В качестве стационарных трубопроводов для отсоса паров или подачи жидкого аммиака',
        ],
      },
      {
        code: '63634129',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Газоопасные работы", "Газ"',
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Опасно для жизни!", "Внимание!"',
          '"Работают люди!", "Опасно для жизни!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63634130',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 5 минут',
          'Через 10 минут',
          'Через 60 минут',
          'Через 30 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63634131',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: [
          'I категории',
          'На одну выше',
          'II категории',
          'III категории',
        ],
        correctAnswers: ['На одну выше'],
      },
      {
        code: '63634132',
        text:
          'В каком положении должны быть опломбированы запорные клапаны на аммиачных газовых нагнетательных трубопроводах?',
        answers: ['В открытом', 'В закрытом', 'Не имеет значения'],
        correctAnswers: ['В открытом'],
      },
      {
        code: '63634133',
        text:
          'В каком из перечисленных документов должны быть определены место нахождения работающего и наблюдающего в процессе выполнения работы и их действия в случае возникновения ситуаций, связанных с ухудшением самочувствия работающего или наблюдающего при проведении газоопасных работ внутри емкости (аппарата)?',
        answers: [
          'В журнале ведения технологического процесса',
          'В наряде-допуске на проведение газоопасных работ',
          'В специально разрабатываемой эксплуатирующей организацией инструкции',
          'В технологическом регламенте и производственных инструкциях по рабочим местам',
        ],
        correctAnswers: ['В наряде-допуске на проведение газоопасных работ'],
      },
      {
        code: '63634134',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 10 лет',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63634135',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'Нужно в любом случае',
          'По решению руководителя эксплуатирующей организации',
          'Правилами не регламентируется',
          'Решение принимается совместно руководителем эксплуатирующей организации и руководителем газоспасательной службы',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63634136',
        text:
          'Как производится описание технологической схемы в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование',
          'По стадиям технологического процесса, начиная с загрузки сырья в технологическое оборудование и кончая отгрузкой готового продукта',
        ],
        correctAnswers: [
          'По стадиям технологического процесса, начиная с поступления и подготовки сырья и кончая отгрузкой готового продукта',
        ],
      },
      {
        code: '63634137',
        text:
          'Каким следует принимать расстояние в свету от аппаратов (сосудов), расположенных снаружи машинного (аппаратного) отделения?',
        answers: [
          'Не менее 2,5 м от стены здания',
          'Не менее 2,0 м от стены здания',
          'Не менее 1,0 м от стены здания',
          'Не менее 1,5 м от стены здания',
        ],
        correctAnswers: ['Не менее 1,0 м от стены здания'],
      },
      {
        code: '63634138',
        text:
          'Какая должна быть степень защиты электроприборов и средств автоматического и дистанционного управления, располагающихся в помещениях с аммиачным оборудованием?',
        answers: [
          'Не ниже IP65',
          'Не ниже IP44',
          'Не ниже IP54',
          'Не ниже IP20',
        ],
        correctAnswers: ['Не ниже IP44'],
      },
      {
        code: '63634139',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
          'Обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63634140',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63634141',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63634142',
        text:
          'Куда следует направлять сбрасываемые химически опасные вещества?',
        answers: [
          'На факельную установку',
          'В специальные контейнеры',
          'В закрытые системы для дальнейшей утилизации',
          'В централизованную систему водоотведения',
        ],
        correctAnswers: ['В закрытые системы для дальнейшей утилизации'],
      },
      {
        code: '63634143',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В паспортах оборудования и трубопроводной арматуры',
          'В техническом задании на изготовление оборудования',
          'В технологическом регламенте',
          'В проектной документации',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63634144',
        text:
          'Для какого оборудования процент первичного заполнения жидким аммиаком не превышает 30%?',
        answers: [
          'Для испарителей кожухотрубных и вертикально-трубных',
          'Для воздухоохладителей с нижней подачей аммиака',
          'Для батарей холодильных камер с верхней подачей аммиака',
          'Для ресиверов линейных',
        ],
        correctAnswers: [
          'Для батарей холодильных камер с верхней подачей аммиака',
        ],
      },
      {
        code: '63634145',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63634146',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случаях изгиба болтов и шпилек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63634147',
        text:
          'Для постоянного обслуживания какого оборудования (арматуры) должна быть устроена металлическая площадка с ограждением и лестницей?',
        answers: [
          'Расположенного на уровне не ниже 2,0 м от пола',
          'Расположенного на уровне выше 1,8 м от пола',
          'Расположенного на уровне выше 1,5 м от пола',
          'Расположенного на уровне, определенном проектной документацией',
        ],
        correctAnswers: ['Расположенного на уровне выше 1,8 м от пола'],
      },
      {
        code: '63634148',
        text:
          'В каком случае допускается оснащать сосуды холодильных систем одним предохранительным клапаном?',
        answers: [
          'Не допускается ни в каком случае',
          'Если геометрический объем сосуда не превышает 0,3 м³',
          'Если клапан рассчитан на полную пропускную способность',
          'Если это определено в проектной документации',
        ],
        correctAnswers: [
          'Если геометрический объем сосуда не превышает 0,3 м³',
        ],
      },
      {
        code: '63634149',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
          'Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок',
          'Руководитель структурного подразделения, не более чем на 1 рабочую смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63634150',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 24 часов',
          'В течение 8 часов',
          'В течение 2 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63634151',
        text:
          'Что из перечисленного не допускается при эксплуатации систем холодоснабжения? Выберите два правильных варианта ответа.',
        answers: [
          'Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора',
          'Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки',
          'Эксплуатация винтовых компрессоров с впрыском жидкого аммиака, если это предусмотрено инструкцией организации-изготовителя',
          'Установка вспрыскивающих устройств, если это предусмотрено инструкцией организации-изготовителя',
        ],
        correctAnswers: [
          'Впрыск жидкого аммиака во всасывающий трубопровод (полость) поршневого компрессора',
          'Использование переносных приборов в местах регулярного контроля работы аммиачной холодильной установки',
        ],
      },
      {
        code: '63634152',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63634153',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'С руководителями службы охраны труда и санитарными службами',
          'С руководителями аварийно-спасательных служб',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'С руководителями службы производственного контроля',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63634154',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности - не регламентируется',
          'На установках с технологическими блоками I и II категории взрывоопасности давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категорий взрывоопасности - не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) не должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63634155',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63634156',
        text:
          'В каком документе указываются регламентированные значения параметров по ведению технологического процесса?',
        answers: [
          'В техническом регламенте',
          'В технологическом регламенте',
          'В руководстве по безопасности',
        ],
        correctAnswers: ['В технологическом регламенте'],
      },
      {
        code: '63634157',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии начальника цеха',
          'В присутствии рабочего, который будет спускаться в емкость, и наблюдающего',
          'В присутствии главного инженера',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63634158',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только при срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Только при неудовлетворительном состоянии уплотнительных поверхностей',
          'Только при деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63634159',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63634160',
        text:
          'Для каких из перечисленных производств разрабатываются постоянные технологические регламенты?',
        answers: [
          'Для новых в данной организации производств',
          'Для действующих химико-технологических производств, в технологию которых внесены принципиальные изменения',
          'Для производств с новой технологией',
          'Для всех перечисленных производств',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634161',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63634162',
        text:
          'В каком из перечисленных случаев должен быть составлен временный технологический регламент на новый срок? Выберите два правильных варианта ответа.',
        answers: [
          'Если такое решение принято технологической службой организации, производства, отделения, установки',
          'Если в тексте технологического регламента обнаружены подчистки и поправки',
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
          'При сроке освоения производства более года',
        ],
        correctAnswers: [
          'Если в технологию производства организацией-разработчиком были внесены уточнения, связанные с изменением мощности, объемов расхода сырья, улучшением качества продукции, безопасностью процесса',
          'Если к концу срока действия временного технологического регламента производство не достигло проектных технико-экономических показателей',
        ],
      },
      {
        code: '63634163',
        text:
          'Какую скорость паров аммиака допускается принимать в сечении паровой зоны вертикального сосуда или аппарата, исполняющего функции отделителя жидкости?',
        answers: [
          'Не более 0,5 м/с',
          'Не более 1,5 м/с',
          'Не более 1 м/с',
          'Не регламентируется',
        ],
        correctAnswers: ['Не более 0,5 м/с'],
      },
      {
        code: '63634164',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Прошедшие обучение приемам и методам проведения работ',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
          'Не моложе 18 лет',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63634165',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '3 месяца со дня его окончания',
          '6 месяцев со дня его окончания',
          '1 год со дня его окончания',
          '3 года со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63634166',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63634167',
        text:
          'Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчик проекта',
          'Разработчик процесса',
          'Заказчик в задании на проектирование',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63634168',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Средствами обеспечения питания инертными газами систем контрольно-измерительных приборов и автоматики',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63634169',
        text:
          'Какое из перечисленных требований к выполнению управляющих функций систем ПАЗ указано неверно?',
        answers: [
          'Команды управления, сформированные алгоритмами защит (блокировок), должны иметь приоритет по отношению к любым другим командам управления технологическим оборудованием, в том числе к командам, формируемым оперативным персоналом АСУТП, если иное не оговорено в техническом задании на ее создание',
          'В алгоритмах срабатывания защит следует предусматривать возможность включения блокировки команд управления оборудованием, технологически связанным с аппаратом, агрегатом или иным оборудованием, вызвавшим такое срабатывание',
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
          'Системы ПАЗ должны реализовываться на принципах приоритетности защиты технологических процессов комплектно, с одновременной защитой отдельных единиц оборудования',
        ],
        correctAnswers: [
          'Срабатывание одной системы ПАЗ может приводить к созданию на объекте ситуации, требующей срабатывания другой такой системы',
        ],
      },
      {
        code: '63634170',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Испытание трубопровода на прочность и плотность',
          'Выборочная разборка резьбовых соединений на трубопроводе, осмотр и измерение их резьбовыми калибрами',
          'Радиографический или ультразвуковой контроль сварных стыков на основании результатов визуально-измерительного контроля',
          'Измерение толщины стенок элементов технологического трубопровода, работающих в наиболее тяжелых условиях',
          'Осуществляется все перечисленное',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63634171',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63634172',
        text:
          'На какую массовую нагрузку должны быть рассчитаны специальные опоры или подвески, на которые монтируются трубопроводы аммиачных холодильных установок?',
        answers: [
          'На собственную массу трубопровода и массу хладагента',
          'На массу тепловой изоляции и массу хладагента',
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2',
          'На массу тепловой изоляции трубопровода, принятую с коэффициентом запаса 1,5',
        ],
        correctAnswers: [
          'На собственную массу трубопровода, массу хладагента и тепловой изоляции, принятых с коэффициентом запаса 1,2',
        ],
      },
      {
        code: '63634173',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Непрерывный контроль состояния воздушной среды',
          'Наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
          'Все перечисленное',
          'Наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63634174',
        text:
          'При какой температуре допускается вскрывать аппараты, освобожденные от аммиака?',
        answers: [
          'Не ниже -45 °C',
          'Не ниже -25 °C',
          'Не ниже -35 °C',
          'Не ниже -15 °C',
        ],
        correctAnswers: ['Не ниже -35 °C'],
      },
      {
        code: '63634175',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63634176',
        text:
          'Какое условие при механической очистке труб конденсатора от водяного камня является неверным?',
        answers: [
          'Работа должна выполняться с оформлением наряда-допуска',
          'Работа должна выполняться под руководством технического руководителя организации',
          'Работа должна выполняться только после освобождения конденсатора от аммиака',
        ],
        correctAnswers: [
          'Работа должна выполняться под руководством технического руководителя организации',
        ],
      },
      {
        code: '63634177',
        text:
          'В каком месте может размещаться оборудование, работающее на аммиаке?',
        answers: [
          'Только на открытой площадке',
          'Только в машинном или аппаратном отделении',
          'Только в помещении потребителей холода',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63634178',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Только система водяного отопления',
          'Только система парового отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63634179',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'До окончания работ на конкретном месте',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63634180',
        text:
          'На каких из перечисленных участках аммиачных трубопроводов должны быть нанесены три опознавательных кольца?',
        answers: [
          'На паровых линиях стороны низкого давления систем холодоснабжения',
          'На паровых линиях стороны высокого давления',
          'На парожидкостных и жидкостных линиях стороны низкого давления систем холодоснабжения',
          'На жидкостных линиях стороны высокого давления',
        ],
        correctAnswers: ['На жидкостных линиях стороны высокого давления'],
      },
      {
        code: '63634181',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее одного',
          'Не менее двух',
          'Не менее четырех',
          'Определяется ответственным за проведение газоопасных работ',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63634182',
        text:
          'Какого показателя категорий взрывоопасности технологических блоков не существует?',
        answers: [
          'I категории',
          'II категории',
          'III категории',
          'IV категории',
        ],
        correctAnswers: ['IV категории'],
      },
      {
        code: '63634183',
        text:
          'Кто устанавливает назначенный срок службы для технологического оборудования, машин и трубопроводной арматуры?',
        answers: [
          'Разработчик документации',
          'Организация-изготовитель',
          'Орган по сертификации на основании заключения испытательной лаборатории',
          'Орган по сертификации',
        ],
        correctAnswers: ['Организация-изготовитель'],
      },
      {
        code: '63634184',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Непосредственно участвовать в выполнении газоопасных работ',
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Производить подготовку объекта к проведению газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63634185',
        text:
          'Какое из перечисленных требований соответствует нормам заполнения и хранения "листа регистрации изменений и дополнений"?',
        answers: [
          'Лист регистрации изменений и дополнений размещается в начале технологического регламента',
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
          'Запись в регистрационном листе выполняется чернилами красного цвета',
          'Регистрация изменений и дополнений выполняется главным инженером организации (техническим директором, директором по производству)',
        ],
        correctAnswers: [
          'Зарегистрированные изменения и дополнения, заверенные печатью, хранятся с первым (контрольным) экземпляром технологического регламента, а также рассылаются под роспись в подразделения, где находятся копии регламента',
        ],
      },
      {
        code: '63634186',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только испытание на прочность пробным давлением',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63634187',
        text:
          'Где должны быть установлены манометры (мановакуумметры) в целях обеспечения безопасности ведения технологических процессов в системах холодоснабжения на холодильном оборудовании и машинах?',
        answers: [
          'Только на жидкостных и оттаивательных коллекторах распределительных аммиачных устройств, соединенных трубопроводами с оборудованием холодильных камер',
          'Только в картере',
          'Только на компрессоре для наблюдения за рабочими давлениями всасывания, нагнетания',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63634188',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63634189',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих технологических систем',
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
          'Оборудование должно быть изолировано от действующей технологической системы, и нанесенное на нем обозначение номера по технологической документации должно быть на схеме закрашено',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно расположено в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих технологических систем',
        ],
      },
      {
        code: '63634190',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Увеличить число наблюдающих и работающих',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Установить систему подачи условных сигналов',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63634191',
        text:
          'Какое из перечисленных требований к поддонам (приямкам) для сбора жидкого аммиака в случае разгерметизации сосуда указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Количество пролитого аммиака из циркуляционного ресивера должно определяться по максимально допустимому заполнению сосуда',
          'Глубина приямка должна быть не более 3 м',
          'Количество пролитого аммиака из защитного ресивера должно определяться по рабочему заполнению сосуда',
          'Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу',
          'Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)',
        ],
        correctAnswers: [
          'Приямки должны иметь не менее двух лестниц, а при глубине приямка более 2 м - выход непосредственно наружу',
          'Расчетный уровень жидкого аммиака в случае аварийного вытекания хладагента из наиболее аммиакоемкого сосуда в поддон (приямок) должен быть ниже бортика поддона (края приямка)',
        ],
      },
      {
        code: '63634192',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Технологический регламент',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63634193',
        text:
          'Какое из перечисленных требований не соответствует разделу технологического регламента "Контроль производства и управление технологическим процессом"?',
        answers: [
          'Периодичность испытаний запорной регулирующей арматуры, исполнительных механизмов, периодичность проверок приборов и испытаний других технических средств, участвующих в схемах контроля, управления и противоаварийной защиты технологических процессов, должны быть взаимоувязаны',
          'Допускается разделять в отдельные таблицы перечень систем сигнализации и блокировок и (или) перечень аналитического контроля',
          'Средства автоматики, используемые по плану мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, должны быть определены особо',
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
        correctAnswers: [
          'Необходимо составить перечень параметров стадий процесса, управление которыми в автоматическом режиме запрещается',
        ],
      },
      {
        code: '63634194',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Технологический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63634195',
        text:
          'Что из перечисленного должно быть приложено к наряду-допуску на проведение газоопасных работ при проведении работ в емкостях, а также работ, связанных с разгерметизацией технологического оборудования и трубопроводов, коммуникаций?',
        answers: [
          'Копии деклараций о соответствии или сертификаты соответствия применяемых при проведении газоопасных работ машин и механизмов',
          'Руководства по эксплуатации технологического оборудования и их ксерокопии',
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
          'Технологические планировки подразделения и паспорта технических устройств, применяемых на опасных производственных объектах',
        ],
        correctAnswers: [
          'Схемы(а) расположения запорной арматуры, освобождения от продукта, промывки, продувки, пропарки и мест установки заглушек, подписанные руководителем структурного подразделения или лицом, его замещающим',
        ],
      },
      {
        code: '63634196',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63634197',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63634198',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'По устному разрешению технического руководителя организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
          'В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63634199',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63634200',
        text:
          'По указанию какого лица должны производиться снятие предохранительных клапанов на проверку, их установка и пломбирование?',
        answers: [
          'Представителя проектной организации',
          'Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
          'Лица, ответственного за проведение ремонтных работ',
          'Технического руководителя организации',
        ],
        correctAnswers: [
          'Лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
        ],
      },
      {
        code: '63634201',
        text:
          'Что из перечисленного не входит в обязанности лица, ответственного за проведение газоопасных работ, по окончании работ внутри емкости (аппарата)?',
        answers: [
          'Поставить в известность работников, занятых ведением технологического процесса, об окончании газоопасных работ',
          'Произвести запись в наряде-допуске на проведение газоопасных работ об окончании газоопасных работ',
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
          'Произвести запись в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен) и наряде-допуске на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Проверить состояние фильтрующих противогазов у каждого работающего внутри емкости (аппарата)',
        ],
      },
      {
        code: '63634202',
        text:
          'В каком случае допускается предусматривать линейный ресивер для холодильных машин с дозированной зарядкой аммиака?',
        answers: [
          'Допускается предусматривать во всех случаях',
          'Допускается, если это обосновано проектной документацией',
          'Допускается если линейный ресивер используется в качестве дренажного или циркуляционного',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634203',
        text:
          'Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?',
        answers: [
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
          'Получение разрешения на применения технических устройств, выдаваемого органами Ростехнадзора',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
      },
      {
        code: '63634204',
        text:
          'Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63634205',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63634206',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'При наработке компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях компенсаторы подлежат отбраковке',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63634207',
        text:
          'В каком случае допускается предусматривать отдельные отделители жидкости, соединенные трубопроводами с циркуляционными (защитными) ресиверами, не совмещающими функции отделителя жидкости, для отделения жидкой фазы из перемещаемой парожидкостной смеси в системах холодоснабжения?',
        answers: [
          'Допускается для аппаратов горизонтального типа',
          'Допускается для аппаратов вертикального типа',
          'Не допускается ни в каком случае',
          'Допускается в обоснованных в проектной документации случаях',
        ],
        correctAnswers: [
          'Допускается в обоснованных в проектной документации случаях',
        ],
      },
      {
        code: '63634208',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводится не реже двух раз в смену',
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
        ],
        correctAnswers: [
          'Определяются места размещения пробоотборников и способы контроля',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
        ],
      },
      {
        code: '63634209',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Pпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Pпр), и при рабочем давлении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Pпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63634210',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС (Ф)',
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС (Ф)',
          'В ПАСС (Ф), аварийно-спасательную службу организации',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС (Ф)',
        ],
      },
      {
        code: '63634211',
        text:
          'Какую скорость паров аммиака в сечении паровой зоны должен обеспечивать размер паровой зоны вертикального сосуда или аппарата, исполняющего функции отделения жидкости?',
        answers: [
          'Не более 0,5 м/с',
          'Не более 1,0 м/с',
          'Правилами не регламентируется',
          'Не более 1,5 м/с',
        ],
        correctAnswers: ['Не более 0,5 м/с'],
      },
      {
        code: '63634212',
        text: 'В каком случае аппарат (сосуд) подлежит немедленной остановке?',
        answers: [
          'Только в случае неисправности предохранительных клапанов',
          'Только в случае неисправности предусмотренных проектной документацией контрольно-измерительных приборов и средств автоматики',
          'Только в случае неисправности указателя уровня жидкости',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63634213',
        text:
          'В каком случае допускается использование ртутных термометров и ртутных устройств для измерения температуры в контрольных точках аммиачной холодильной системы?',
        answers: [
          'Если используется защита от механических повреждений',
          'Если это обосновано проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63634214',
        text:
          'Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?',
        answers: [
          'Лицом, ответственным за подготовку газоопасных работ',
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
          'Представителем Ростехнадзора',
          'Лицом, ответственным за осуществление производственного контроля',
        ],
        correctAnswers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
        ],
      },
      {
        code: '63634215',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63634216',
        text: 'О чем сигнализирует красная лампа световой сигнализации?',
        answers: [
          'Сигнал об аварийной остановке аппарата',
          'Сигнал об опасном повышении верхнего уровня жидкого аммиака в сосуде (предупредительная сигнализация)',
          'Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)',
          'Сигнал об опасном понижении верхнего уровня жидкого аммиака в сосуде (предаварийная сигнализация)',
        ],
        correctAnswers: [
          'Сигнал о предельно допустимом уровне жидкого аммиака в сосуде (предаварийная сигнализация)',
        ],
      },
      {
        code: '63634217',
        text:
          'Каким должно быть минимальное остаточное избыточное давление в транспортировочных емкостях аммиака при их полном опорожнении?',
        answers: ['0,02 МПа', '0,05 МПа', '0,03 МПа', '0,01 МПа'],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63634218',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63634219',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
          'Организационно-распорядительными документами организации',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63634220',
        text:
          'Какое количество копий технологических регламентов устанавливается требованиями Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Не менее 6',
          'Не менее 15',
          'Не менее 10',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63634221',
        text:
          'В течение какого времени и при каком давлении следует проводить вакуумирование холодильной установки перед пуском в эксплуатацию после пневматических испытаний?',
        answers: [
          'В течение 7 часов при остаточном давлении 0,05 МПа (0,5 кгс/см²)',
          'В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)',
          'В течение 15 часов при остаточном давлении 0,03 МПа (0,3 кгс/см²)',
          'В течение 10 часов при остаточном давлении 0,015 МПа (0,15 кгс/см²)',
        ],
        correctAnswers: [
          'В течение 18 часов при остаточном давлении 0,01 МПа (0,1 кгс/см²)',
        ],
      },
      {
        code: '63634222',
        text:
          'На чем основаны оптимальные методы и средства противоаварийной автоматической защиты?',
        answers: [
          'На методиках и программных продуктах, применяемых для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'На алгоритмах, разработанных по сценариям всех возможных аварий и их развития',
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
          'На сценариях возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
        ],
        correctAnswers: [
          'На основе анализа опасностей технологического объекта, условий возникновения и развития возможных аварийных ситуаций, особенностей технологических процессов и аппаратурного оформления',
        ],
      },
      {
        code: '63634223',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Главный инженер эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63634224',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только акты испытания технологического трубопровода на прочность и плотность',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63634225',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63634226',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На сети водоснабжения и канализации',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63634227',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Инспектор территориального органа Ростехнадзора',
          'Комиссия эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63634228',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
          'На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63634229',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Эксплуатирующими организациями',
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Ростехнадзором',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63634230',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Должна быть обеспечена селективность защит на устройствах электроснабжающей и электропотребляющей организаций',
          'Обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63634231',
        text:
          'Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Средствами контроля за температурой измельченных твердых горючих продуктов',
          'Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут',
          'Средствами контроля за фракционным составом измельченных твердых горючих продуктов',
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
        ],
        correctAnswers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
        ],
      },
      {
        code: '63634232',
        text:
          'Что необходимо указывать в описании процессов разделения химических продуктов (горючих или их смесей с негорючими) в разделе технологического регламента "Описание технологического процесса и схемы"?',
        answers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
          'Степень разделения сред, показатели пожароопасности и токсичности',
          'Показатели взрывопожароопасности, а также токсичные свойства всех веществ, участвующих в процессе на всех стадиях',
        ],
        correctAnswers: [
          'Степень разделения сред и меры взрывобезопасности, предотвращающие образование взрывоопасных смесей на всех стадиях процесса',
        ],
      },
      {
        code: '63634233',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63634234',
        text:
          'В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Выберите два правильных варианта ответа.',
        answers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
          'В случае размещения холодильного оборудования над площадками на расстоянии, определенном проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
      },
      {
        code: '63634235',
        text:
          'Какой должна быть ширина центрального прохода для обслуживания оборудования у вновь строящихся и реконструируемых аммиачных систем холодоснабжения?',
        answers: [
          'Не более 0,8 м',
          'Не более 1,2 м',
          'Не менее 1,5 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63634236',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Категорией взрывоопасности технологических блоков',
          'Радиусом зон разрушения, в м',
          'Энергией сгорания парогазовой фазы, в кДж',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63634237',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 50% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 70% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63634238',
        text:
          'В течение какого времени наряд-допуск на проведение огневых работ действителен?',
        answers: [
          'В течение одной дневной смены',
          'До окончания работ на конкретном месте',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной дневной смены'],
      },
      {
        code: '63634239',
        text:
          'На основе каких данных составляется материальный баланс для действующих производств?',
        answers: [
          'На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента',
          'На основе данных проектной документации',
          'На основе данных проектной документации с учетом внесенных в нее изменений, включения или исключения дополнительных операций или стадий',
        ],
        correctAnswers: [
          'На основе достигнутых показателей работы производств в последний год перед составлением технологического регламента',
        ],
      },
      {
        code: '63634240',
        text:
          'Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?',
        answers: ['185 °C', '135 °C', '160 °C', '90 °C'],
        correctAnswers: ['160 °C'],
      },
      {
        code: '63634241',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['15 минут', '30 минут', '10 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63634242',
        text:
          'Какие устройства применяются в качестве предохранительных на аммиачных холодильных установках?',
        answers: [
          'Предохранительные клапаны прямого действия',
          'Предохранительный клапан с мембранным чувствительным элементом',
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
          'Любые из перечисленных',
        ],
        correctAnswers: [
          'Пружинные предохранительные клапаны и мембранные предохранительные устройства',
        ],
      },
      {
        code: '63634243',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Правилами не регламентируется',
          'Работники, указанные в наряде-допуске',
          'Работники подрядной организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63634244',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63634245',
        text:
          'Какие из документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Технологический регламент',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63634246',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63634247',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63634248',
        text:
          'Что необходимо предпринять организации, эксплуатирующей химически опасный производственный объект, в целях приведения его в соответствие требованиям Правил безопасности химически опасных производственных объектов?',
        answers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
          'Провести экспертизу промышленной безопасности',
          'Провести реконструкцию химически опасного производственного объекта',
          'Немедленно сообщить в Ростехнадзор о выявленных в рамках проведения производственного контроля несоответствиях Правилам безопасности химически опасных производственных объектов',
        ],
        correctAnswers: [
          'Провести комплексное обследование фактического состояния химически опасного производственного объекта и при выявлении отклонений разработать комплекс компенсационных мер по дальнейшей безопасной эксплуатации таких объектов, организовать внесение изменений в проектную документацию, документацию на техническое перевооружение, консервацию и ликвидацию ХОПО или ее разработку вновь',
        ],
      },
      {
        code: '63634249',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63634250',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63634251',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
          'Требования к обозначению определяются при разработке планов мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63634252',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63634253',
        text:
          'Какое из перечисленных требований к расположению цистерн с жидким аммиаком в организации указано верно?',
        answers: [
          'До и во время проведения слива цистерна должна ограждаться переносными сигналами и должен быть установлен знак размером 300 x 400 мм с надписью "Осторожно! Слив аммиака"',
          'Колеса цистерны на рельсовом пути должны быть закреплены и с одной стороны подклинены тормозными башмаками',
          'Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны',
          'На внутренних железнодорожных путях организации, не имеющих стрелочных переводов, должен устанавливаться затворный предохранительный брус на расстоянии не менее 5 м от цистерны',
        ],
        correctAnswers: [
          'Перед сливом аммиака цистерна должна быть заземлена и подключена к блокировке сдвига цистерны',
        ],
      },
      {
        code: '63634254',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63634255',
        text: 'Чем должно быть оснащено оборудование для разделения суспензий?',
        answers: [
          'Обратным клапаном',
          'Фильтрами',
          'Гидрозатвором',
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
        correctAnswers: [
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63634256',
        text:
          'Как рассчитывается геометрический объем защитных ресиверов (Vз.р.) вертикального типа, совмещающих функцию отделителя жидкости аммиачных холодильных установок, для каждой температуры кипения аммиака?',
        answers: [
          'Vз.р. больше Vс х 0,9 м³',
          'Vз.р. больше Vс х 0,2 м³',
          'Vз.р. больше Vс х 0,5 м³',
          'Vз.р. больше Vс х 1,5 м³',
        ],
        correctAnswers: ['Vз.р. больше Vс х 0,5 м³'],
      },
      {
        code: '63634257',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Не включать: не в фазе!"',
          '"Не включать: работают люди!"',
          '"Не включать: работа на линии!"',
          '"Высокое напряжение. Опасно для жизни!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63634258',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите два правильных варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63634259',
        text:
          'Какие разновидности материального баланса допускается составлять в разделе технологического регламента "Материальный баланс"?',
        answers: [
          'Только на единицу выпускаемой продукции',
          'Только на единицу времени (час)',
          'Только на один производственный поток или на мощность производства в целом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63634260',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63634261',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Главным инженером эксплуатирующей организации',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63634262',
        text:
          'Какое из перечисленных требований к испытанию на прочность после монтажа до пуска в эксплуатацию сосуда (аппарата), трубопровода (его участка) указано верно?',
        answers: [
          'Использование запорной арматуры для отключения испытываемого сосуда (аппарата) и трубопровода допускается в присутствии лица, ответственного за исправное состояние и безопасную работу сосудов (аппаратов)',
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
          'Толщина заглушки должна быть рассчитана на условия работы при давлении выше пробного в 2,5 раза',
          'При периодическом освидетельствовании и техническом диагностировании с использованием метода АЭ и аммиака в качестве нагружающей среды не допускается испытывать отдельные технологические линии блоком',
        ],
        correctAnswers: [
          'При испытании на прочность после монтажа до пуска в эксплуатацию испытываемый сосуд (аппарат), трубопровод (его участок) должны быть отсоединены от других сосудов, аппаратов и других трубопроводов с использованием металлических заглушек с прокладками, имеющих хвостовики, выступающие за пределы фланцев не менее 20 мм',
        ],
      },
      {
        code: '63634263',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63634264',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63634265',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Пожарной службе эксплуатирующей организации',
          'Руководителю эксплуатирующей организации',
          'Начальнику цеха',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63634266',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на ОПО химических, нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63634267',
        text:
          'Каким должен быть процент первичного заполнения жидким аммиаком внутреннего объема воздухоохладителей с верхней подачей аммиака?',
        answers: ['50%', '30%', '15%', '80%'],
        correctAnswers: ['50%'],
      },
      {
        code: '63634268',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63634269',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63634270',
        text:
          'С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?',
        answers: [
          'Не реже 1 раза в 10 дней',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63634271',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Физико-химическими свойствами перемещаемых продуктов',
          'Критическими параметрами технологического процесса',
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Техническими характеристиками применяемых насосов и компрессоров',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63634272',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
    ],
    63640: [
      {
        code: '63640000',
        text:
          'Кем определяется степень разделения материальных сред и меры взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63640001',
        text:
          'Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?',
        answers: [
          'При прокладке трубопроводов через строительные конструкции зданий и другие препятствия принимаются меры, исключающие возможность передачи дополнительных нагрузок на трубы',
          'Трубопроводы не должны иметь фланцевых или других разъемных соединений',
          'Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами, устанавливающими требования к технологическим трубопроводам с учетом условий эксплуатации',
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
        ],
        correctAnswers: [
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
        ],
      },
      {
        code: '63640002',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасном производственном объекте химической, нефтехимической промышленности?',
        answers: [
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63640003',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену',
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
        ],
        correctAnswers: [
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Проектной организацией определяются места размещения пробоотборников и способы контроля за содержанием кислорода в горючем газе',
        ],
      },
      {
        code: '63640004',
        text:
          'Какое требование к первичному пуску компрессоров в работу после длительной остановки, ремонта, профилактики, а также после остановки компрессора при срабатывании приборов предаварийной защиты указано верно?',
        answers: [
          'При пуске компрессора с использованием встроенного байпаса нагнетательный вентиль компрессора должен быть закрыт, а клапан байпаса открыт, если это предусмотрено инструкцией организации-изготовителя',
          'Первичный пуск компрессора в работу необходимо выполнять вручную с закрытыми всасывающими клапанами в соответствии с инструкцией организации-изготовителя',
          'Перед пуском компрессора в работу следует убедиться, что все запорные клапаны на нагнетательном трубопроводе от компрессора до конденсатора открыты',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63640005',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'Над автодорогами',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63640006',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63640007',
        text:
          'В каком случае системы аварийной вентиляции должны включаться автоматически? Выберите два правильных варианта ответа.',
        answers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков повышения температуры',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе помещения',
        ],
        correctAnswers: [
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
        ],
      },
      {
        code: '63640008',
        text:
          'В каком случае допускается подключение азотных компрессоров к ВРУ без газгольдеров?',
        answers: [
          'В соответствии с проектной документацией',
          'При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота',
          'Не допускается ни в каком случае',
          'Если подключение производится через обратный клапан',
        ],
        correctAnswers: [
          'При наличии автоматических устройств и блокировок, исключающих увеличение отбора азота',
        ],
      },
      {
        code: '63640009',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др., нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчиком процесса',
          'Заказчиком в задании на проектирование',
          'Разработчиками процесса и проекта',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63640010',
        text:
          'Какого показателя категорий взрывоопасности технологических блоков не существует?',
        answers: ['III', 'IV', 'II', 'I'],
        correctAnswers: ['IV'],
      },
      {
        code: '63640011',
        text:
          'Какие требования предъявляются к очистке масла воздуха, выходящего из поршневого детандера?',
        answers: [
          'Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом',
          'Очистка выполняется в детандерных фильтрах в соответствии с инструкцией по эксплуатации завода - изготовителя оборудования',
          'Очистка выполняется в основных детандерных фильтрах в соответствии с технологической инструкцией. При загрязнении контрольного фильтра должна быть проверена работа основных фильтров',
          'Очистка выполняется в основных детандерных фильтрах в соответствии с инструкцией по безопасному ведению технологического процесса очистки воздуха',
        ],
        correctAnswers: [
          'Очистка выполняется в детандерных фильтрах в соответствии с технологическим регламентом',
        ],
      },
      {
        code: '63640012',
        text:
          'При достижении какой концентрации обращающихся веществ в воздухе анализаторных помещений, должно происходить автоматическое включение аварийной вентиляции?',
        answers: [
          'При 20% НКПР',
          'При 18% НКПР',
          'При 10% НКПР',
          'При 15% НКПР',
        ],
        correctAnswers: ['При 20% НКПР'],
      },
      {
        code: '63640013',
        text:
          'Какие сведения являются основополагающими для выбора оборудования при разработке технологических процессов?',
        answers: [
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование и требования действующих нормативных документов',
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и показатели надежности',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63640014',
        text:
          'С какими подразделениями должна быть оборудована система двусторонней громкоговорящей связи для объектов с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками',
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью и технологически связанными производственными участками',
        ],
      },
      {
        code: '63640015',
        text:
          'Чем должно оснащаться оборудование для измельчения и смешивания измельченных твердых горючих продуктов для обеспечения эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Средствами контроля за температурой измельченных твердых горючих продуктов',
          'Средствами контроля за фракционным составом измельченных твердых горючих продуктов',
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
          'Автоматическими блокировками, не допускающими пуск в работу оборудования без предварительной продувки инертным газом в течение 5 минут',
        ],
        correctAnswers: [
          'Сигнализацией об отклонении давления подаваемого инертного газа от регламентированных значений',
        ],
      },
      {
        code: '63640016',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Поддержание избыточного давления инертного газа в технологической системе после остановки оборудования',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки работы технологического оборудования',
        ],
        correctAnswers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
        ],
      },
      {
        code: '63640017',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования, на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63640018',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'В исключительных случаях при малых объемных скоростях подачи, в том числе в системах дозирования',
        ],
      },
      {
        code: '63640019',
        text:
          'Что является критерием взрывоопасности согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Класс опасности обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63640020',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предотвращения аварий и предупреждения их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63640021',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
        ],
      },
      {
        code: '63640022',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать их к зданиям',
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'Помещения управления должны быть отдельно стоящими',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63640023',
        text:
          'Какие сведения являются основополагающими при выборе технологического оборудования для обеспечения технологических процессов?',
        answers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
          'Расчетные данные, которым должны соответствовать параметры оборудования, и требования действующих нормативных документов',
          'Требования действующих нормативных документов, расчетные данные, которым должны соответствовать параметры оборудования, задание на проектирование',
        ],
        correctAnswers: [
          'Задание на проектирование, требования нормативных правовых актов в области промышленной безопасности, категория взрывоопасности технологических блоков',
        ],
      },
      {
        code: '63640024',
        text:
          'Чем должны быть оснащены подводящие к смесителям коммуникации, проектируемые с целью обеспечения максимально возможного уровня эксплуатационной безопасности в отношении риска взрыва?',
        answers: [
          'Автоматическим газоанализатором',
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
          'Автоматическими датчиками давления',
          'Фильтрами',
        ],
        correctAnswers: [
          'Обратными клапанами или другими устройствами, исключающими (при отклонениях от регламентированных параметров процесса) поступление обратным ходом в эти коммуникации подаваемых на смешивание горючих веществ, окислителей или смесей',
        ],
      },
      {
        code: '63640025',
        text:
          'Какими документами должны сопровождаться технологические трубопроводы после монтажа и ремонта?',
        answers: [
          'Разрешениями организации, проводившей монтаж и ремонт на проведение этих видов работ',
          'Лицензиями и другими учредительными документами организации, проводившей монтаж и ремонт',
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
          'Документами о стоимости материалов (например, на трубы, фитинги, арматуру, опоры, сварочные материалы и др.) и работ по проведению монтажа и ремонта',
        ],
        correctAnswers: [
          'Удостоверениями о качестве монтажа с соответствующими приложениями (например, сертификатами на трубы, фитинги, арматуру, опоры, сварочные материалы, копиями удостоверений сварщиков, документами по результатам контроля качества работ)',
        ],
      },
      {
        code: '63640026',
        text:
          'На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'На основании литературных данных на проектирование',
          'На основании задания на проектирование',
          'На основании расчетных данных на проектирование',
          'На основании справочных данных на проектирование',
        ],
        correctAnswers: ['На основании задания на проектирование'],
      },
      {
        code: '63640027',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Технической комиссией эксплуатирующей организации',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63640028',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Все перечисленное',
          'Расчет на прочность',
          'Схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63640029',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'В соответствии со всем перечисленным',
          'Только в соответствии с рабочей документацией',
          'Только в соответствии с проектной документацией',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63640030',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
          'В технологическом регламенте на производство продукции',
          'В проектной документации',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63640031',
        text:
          'Где не допускается располагать колодцы на сетях канализации во взрывопожароопасных производствах?',
        answers: [
          'Только в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
          'Только под эстакадами технологических трубопроводов',
        ],
        correctAnswers: [
          'Под эстакадами технологических трубопроводов и в пределах отбортовок и обвалований оборудования наружных установок, содержащих взрывоопасные продукты',
        ],
      },
      {
        code: '63640032',
        text:
          'Что должно быть учтено при проектировании программного обеспечения противоаварийной защиты технологической аппаратуры реакционных процессов?',
        answers: [
          'Указания технического руководителя организации',
          'Требования организации - изготовителя реакционной аппаратуры',
          'Требования территориальных органов Ростехнадзора',
          'Риски срабатывания автоматических систем противоаварийной защиты',
        ],
        correctAnswers: [
          'Риски срабатывания автоматических систем противоаварийной защиты',
        ],
      },
      {
        code: '63640033',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
          'Только средствами противоаварийной защиты',
          'Только средствами автоматического регулирования',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63640034',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Наличие природных оврагов, выемок, низин',
          'Траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63640035',
        text:
          'Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?',
        answers: [
          'Проектной документацией',
          'Техническим регламентом',
          'Правилами безопасности',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63640036',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха (КИПиА) на заводскую сеть сжатого воздуха через осушитель',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63640037',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Должно быть организовано управление дистанционно',
          'Должно быть организовано управление по месту',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63640038',
        text:
          'Какие требования к эксплуатации поршневых компрессоров указаны неверно?',
        answers: [
          'Наличие нагара в клапанных коробках и трубопроводах поршневых компрессоров не допускается',
          'Необходимо контролировать работу маслоочищающих сальников. Попадание машинного масла в цилиндры не допускается',
          'Не допускается использовать для смазки поршневой группы компрессоров масло, извлеченное из масловлагоотделителей',
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
          'Для смазки поршневой группы компрессоров, подающих воздух в воздухоразделительные установки, следует использовать масла, рекомендованные разработчиками компрессора',
        ],
        correctAnswers: [
          'Допускается применять поршневые бескрейцкопфные компрессоры для подачи воздуха на разделение и для сжатия продуктов разделения воздуха',
        ],
      },
      {
        code: '63640039',
        text:
          'Чем должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей? Выберите два правильных варианта ответа.',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Системами измерения плотности и вязкости перекачиваемых сред',
          'Средствами контроля температуры перемещаемой жидкости',
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
        ],
      },
      {
        code: '63640040',
        text:
          'Чем должны быть оснащены колонны ректификации горючих жидкостей?',
        answers: [
          'Только средствами контроля уровня и температуры жидкости в кубовой части',
          'Только средствами сигнализации об опасных отклонениях значений параметров',
          'Только средствами ручного регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса',
        ],
        correctAnswers: [
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы, а также средствами сигнализации об опасных отклонениях значений параметров, в том числе перепада давления между нижней и верхней частями колонны, определяющих взрывобезопасность процесса',
        ],
      },
      {
        code: '63640041',
        text: 'Где следует устанавливать компрессорные агрегаты?',
        answers: [
          'На открытых площадках насосных и компрессорных установок',
          'В помещении машинного (аппаратного) отделения',
          'Допускается установка во всех перечисленных местах',
          'Под эстакадами технологических трубопроводов с горючими, едкими и взрывоопасными продуктами',
        ],
        correctAnswers: ['В помещении машинного (аппаратного) отделения'],
      },
      {
        code: '63640042',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'На установках с технологическими блоками I категории взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками II и III категорий взрывоопасности соотношение давлений не регламентируется',
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'На установках с технологическими блоками I и II категорий взрывоопасности давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ. На установках с технологическими блоками III категории взрывоопасности соотношение давлений не регламентируется',
          'Соотношение давлений негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63640043',
        text:
          'При каком давлении во всасывающих коммуникациях компрессоров, сжимающих продукты разделения воздуха, данные компрессоры должны автоматически отключаться?',
        answers: [
          'При снижении давления ниже 1,5 кПа',
          'При снижении давления ниже 0,6 кПа',
          'При снижении давления ниже 1,0 кПа',
          'При снижении давления ниже 0,5 кПа',
        ],
        correctAnswers: ['При снижении давления ниже 0,5 кПа'],
      },
      {
        code: '63640044',
        text:
          'Что должно обеспечивать размещение технологического оборудования, трубопроводной арматуры в производственных зданиях и на открытых площадках? Выберите два правильных варианта ответа.',
        answers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
          'Использование строительных конструкций зданий и сооружений в несущих элементах технологического оборудования',
          'Уменьшение взрывоопасности объекта путем равномерного распределения технологических блоков I категории взрывоопасности',
        ],
        correctAnswers: [
          'Возможность принятия оперативных мер по предотвращению аварийных ситуаций или локализации аварий',
          'Возможность проведения ремонтных работ',
        ],
      },
      {
        code: '63640045',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Разработчиком проекта',
          'Комиссией эксплуатирующей организации',
          'Специализированной сторонней организацией',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63640046',
        text:
          'При каких условиях допускается отключение защит (единовременно не более одного параметра) для непрерывных процессов?',
        answers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
          'В присутствии начальника производства и начальника службы КИПиА (главного прибориста) только в дневную смену при наличии утвержденных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ',
          'По устному разрешению технического руководителя (главного инженера) организации только в дневную смену при наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, и в присутствии начальника производства',
        ],
        correctAnswers: [
          'При наличии разработанных организационно-технических мероприятий по обеспечению безопасности технологического процесса и производства работ на время, определенное планом организации работ, в дневную смену - по письменному разрешению должностного лица организации, а при работах в иные смены - с обеспечением присутствия и контроля соответствующими службами',
        ],
      },
      {
        code: '63640047',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита топочного пространства нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами ручного управления подачей водяного пара в топочное пространство и змеевики при прогаре труб',
          'Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики',
          'Средствами контроля за температурой в топочном пространстве',
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
        ],
        correctAnswers: [
          'Блокировками, прекращающими поступление газообразного топлива и воздуха при снижении их давления ниже установленных параметров (автономно), а также при прекращении электро- (пневмо-) снабжения контрольно-измерительных приборов и средств автоматики',
          'Системами регулирования заданного соотношения топлива, воздуха и водяного пара',
        ],
      },
      {
        code: '63640048',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах ввода в технологические здания и сооружения',
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63640049',
        text:
          'Чем определяется порядок испытаний, контроль за состоянием и эксплуатацией теплообменных устройств?',
        answers: [
          'Исходными данными на проектирование',
          'Технической документацией производителя',
          'Технологическим регламентом',
          'Проектной документацией',
        ],
        correctAnswers: ['Технической документацией производителя'],
      },
      {
        code: '63640050',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только обследование участка трубопровода, на котором производилась замена',
          'Только испытание на прочность пробным давлением',
          'Должны выполняться все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63640051',
        text:
          'Что в технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной автоматической защиты, включая исполнительные механизмы',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63640052',
        text:
          'Что устанавливается на линию подачи инертных газов (пар, азот, и другие среды) в процессах, при которых в результате отклонения от заданных технологических режимов возможно попадание взрывопожароопасных продуктов в нее?',
        answers: [
          'Гидроклапан',
          'Обратный клапан',
          'Предохранительный клапан',
          'Запорный клапан',
          'Регулирующий клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63640053',
        text:
          'Каким из перечисленных требований должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
          'Полы в помещении должны быть нескользкими, повышенной механической стойкостью',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63640054',
        text:
          'Какое требование к системам вентиляции не соответствует ФНП "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63640055',
        text:
          'Что используется для соединения оборудования и технологических трубопроводов со стационарными линиями во взрывопожароопасных технологических системах?',
        answers: [
          'Пластмассовые гибкие шланги',
          'Гибкие металлические шланги',
          'Резиновые гибкие шланги',
          'Съемные участки трубопроводов',
        ],
        correctAnswers: ['Съемные участки трубопроводов'],
      },
      {
        code: '63640056',
        text:
          'Какой порядок установлен для внесения изменений в технологическую схему, аппаратурное оформление, в системы контроля, связи, оповещения и противоаварийной автоматической защиты?',
        answers: [
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
          'Изменения вносятся при наличии положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
          'Изменения вносятся путем разработки обоснования безопасности опасного производственного объекта, получения на него положительного заключения экспертизы промышленной безопасности и внесения его в реестр заключений экспертизы',
        ],
        correctAnswers: [
          'Изменения вносятся после внесения изменений в проектную документацию или документацию на техническое перевооружение, согласованную с разработчиком проектной документации или с организацией, специализирующейся на проектировании аналогичных объектов, или при наличии положительного заключения экспертиз по проектной документации (документации)',
        ],
      },
      {
        code: '63640057',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам',
          'Выполняется обслуживающим персоналом по инструкции',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63640058',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два правильных варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 350 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 200 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63640059',
        text:
          'Что не соответствует установленным требованиям безопасности при обслуживании компрессора?',
        answers: [
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть закрыты',
          'Камеры, расположенные по ходу воздуха перед фильтром и после него, должны быть снабжены знаками безопасности, запрещающими вход в камеры',
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
          'Во время работы воздушного центробежного компрессора и воздушного фильтра вход обслуживающего персонала в помещение камеры после воздушного фильтра (камера чистого воздуха) не допускается',
        ],
        correctAnswers: [
          'Работы в камере перед фильтром при работающем фильтре и компрессоре необходимо выполнять бригадой не менее чем из трех человек, один из которых является наблюдающим',
        ],
      },
      {
        code: '63640060',
        text:
          'При какой скорости падения давления результаты дополнительного пневматического испытания на герметичность признаются удовлетворительными для технологических трубопроводов внутренним диаметром до 250 мм включительно со средами, относящимися к опасным веществам 1-го и 2-го классов опасности?',
        answers: [
          'Не более 0,1% за 1 час',
          'Не более 0,2% за 1 час',
          'Не более 0,8% за 1 час',
          'Не более 0,4% за 1 час',
        ],
        correctAnswers: ['Не более 0,1% за 1 час'],
      },
      {
        code: '63640061',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Акты испытания технологического трубопровода на прочность и плотность',
          'Акты периодического наружного осмотра технологического трубопровода',
          'Заключение о техническом состоянии арматуры',
          'Заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63640062',
        text:
          'Какое время срабатывания системы защиты установлено в федеральных нормах и правилах?',
        answers: [
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
          'Не более 12 секунд',
          'Не более 120 секунд',
          'Не более 60 секунд',
        ],
        correctAnswers: [
          'Время срабатывания системы защиты должно быть таким, чтобы исключалось опасное развитие возможной аварии',
        ],
      },
      {
        code: '63640063',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63640064',
        text:
          'Какая максимальная температура нагнетания должна быть для поршневых компрессоров, если инструкцией организации-изготовителя не предусмотрено иное значение?',
        answers: ['135 °C', '160 °C', '90 °C', '185 °C'],
        correctAnswers: ['160 °C'],
      },
      {
        code: '63640065',
        text:
          'Какой из перечисленных разделов не относится к постоянным, временным и разовым технологическим регламентам, связанным с необходимостью обеспечения промышленной безопасности технологических процессов?',
        answers: [
          'Характеристика производимой продукции',
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
          'Технологические схемы производства',
          'Возможные инциденты в работе и способы их ликвидации',
        ],
        correctAnswers: [
          'Описание схемы контрольно-измерительных приборов и автоматики, блокировок и предохранительных устройств',
        ],
      },
      {
        code: '63640066',
        text:
          'Каким образом определяются методы и сроки очистки водяных полостей холодильников и рубашек цилиндров компрессоров от отложений?',
        answers: [
          'Правилами безопасности химически опасных производственных объектов',
          'Техническим руководителем эксплуатирующей организации',
          'Технологическим регламентом, технологическими инструкциями',
          'Сторонней специализированной организацией',
        ],
        correctAnswers: [
          'Технологическим регламентом, технологическими инструкциями',
        ],
      },
      {
        code: '63640067',
        text:
          'На каких кислородопроводах необходимо устанавливать переключающиеся фильтры перед их подключением к коллектору всасывания кислородных компрессоров?',
        answers: [
          'На кислородопроводах длиной более 300 м, изготовленных из сплавов алюминия',
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 150 м, изготовленных из углеродистых сталей',
          'На кислородопроводах длиной более 200 м, изготовленных из меди и сплавов на основе меди',
        ],
        correctAnswers: [
          'На кислородопроводах длиной более 250 м, изготовленных из углеродистых сталей',
        ],
      },
      {
        code: '63640068',
        text:
          'Сколько в процентном отношении должна составлять температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?',
        answers: [
          'Не более 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Не более 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Не более 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63640069',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63640070',
        text:
          'Каким образом осуществляется регулирование массообменных процессов, в которых при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений?',
        answers: [
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 допускается ручное дистанционное управление',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - при помощи средств автоматического регулирования, для установок III категории взрывоопасности предусматривается выполнение операций регулирования в ручном режиме (производственным персоналом) при обеспечении автоматического контроля указанных параметров процесса и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63640071',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63640072',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Разработчиком процесса',
          'Заказчиком в задании на проектирование',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63640073',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых согласно Плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Требования к обозначению определяются при разработке Плана мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
          'Средства автоматики должны быть обозначены только в технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указываются в технологическом регламенте на производство продукции и инструкциях',
        ],
      },
      {
        code: '63640074',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Только устройство световой сигнализации',
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
          'Только устройство звуковой сигнализации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63640075',
        text:
          'Кто делает обоснование по применению эффективности и надежности мер и технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности отдельного блока и в целом всей технологической системы?',
        answers: [
          'Проектная организация',
          'Монтажно-наладочная организация',
          'Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Проектная организация'],
      },
      {
        code: '63640076',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63640077',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность после ремонта, связанного со сваркой и разборкой технологического трубопровода?',
        answers: [
          'Не менее 24 часов',
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 4 часов'],
      },
      {
        code: '63640078',
        text:
          'Каким требованиям должны соответствовать насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
          'Токи самозапуска электродвигателей не должны превышать номинальные более чем в два раза',
          'Насосы и компрессоры должны оснащаться маховиками для обеспечения повторного самозапуска электродвигателей',
          'Время срабатывания систем самозапуска должно определяться нагрузкой насосов и компрессоров, но не должно превышать 5 минут',
        ],
        correctAnswers: [
          'Насосы и компрессоры должны выбираться с учетом возможности их повторного автоматического пуска',
        ],
      },
      {
        code: '63640079',
        text:
          'Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?',
        answers: [
          'Паспортом технического устройства',
          'Эксплуатационной документацией',
          'Проектной документацией или документацией на техническое перевооружение',
          'Планом ликвидации аварий',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63640080',
        text:
          'Кем принимается решение о выводе объекта (блока, установки) из эксплуатации на длительный период и вводе этих объектов (блоков, установок) в эксплуатацию после длительных остановок?',
        answers: [
          'Эксплуатирующей организацией',
          'Проектной организацией',
          'Эксплуатирующей организацией при согласовании с Ростехнадзором',
          'Сторонней специализированной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63640081',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Использование в зависимости от особенностей технологического процесса эффективных систем пожаротушения',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров в производственных зданиях, сооружениях',
          'Предотвращение взрывов внутри технологического оборудования',
          'Периодический контроль за состоянием воздушной среды',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63640082',
        text:
          'Какие из перечисленных действий допускаются при эксплуатации компрессоров?',
        answers: [
          'Пуск компрессоров, работающих на воздухе со щелочной очисткой, при отключенных аппаратах щелочной очистки и открытом байпасе',
          'Попадание масла на фундамент компрессора',
          'Сброс в атмосферу продувок компрессоров без очистки от масла',
        ],
        correctAnswers: [
          'Пуск компрессоров, работающих на воздухе со щелочной очисткой, при отключенных аппаратах щелочной очистки и открытом байпасе',
        ],
      },
      {
        code: '63640083',
        text:
          'Чем определяется количество насосов и компрессоров, используемых для перемещения химически опасных веществ в технологическом процессе?',
        answers: [
          'Физико-химическими свойствами перемещаемых продуктов',
          'Техническими характеристиками применяемых насосов и компрессоров',
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
          'Критическими параметрами технологического процесса',
        ],
        correctAnswers: [
          'Необходимостью и условиями обеспечения непрерывности технологического процесса',
        ],
      },
      {
        code: '63640084',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Время срабатывания систем защиты должно равняться времени, необходимому для перехода параметра от предупредительного до предельно допустимого значения',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться автоматически после устранения причины срабатывания',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63640085',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на ОПО химических, нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63640086',
        text:
          'В массообменных процессах при отклонении технологических параметров от регламентированных значений возможно образование неустойчивых взрывоопасных соединений. Как в таком случае должно осуществляться регулирование этих параметров?',
        answers: [
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматически, а при Qв ≤ 10 допускается ручное дистанционное управление',
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматически, с технологическими блоками II категории взрывоопасности - ручное дистанционное управление при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений, с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматически, для установок III категории взрывоопасности допускается управление вручную при обеспечении автоматического контроля указанных параметров и сигнализации о превышении их допустимых значений',
        ],
      },
      {
        code: '63640087',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63640088',
        text:
          'Кто обосновывает достаточность аппаратурного резервирования и его тип?',
        answers: [
          'Разработчик проекта',
          'Представитель организации-изготовителя систем противоаварийной автоматической защиты',
          'Разработчик технологического процесса',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Разработчик проекта'],
      },
      {
        code: '63640089',
        text:
          'В течение какого минимального времени буферные емкости (реципиенты) должны обеспечивать питание сжатым воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: ['2 часов', '1 часа', '30 минут', '15 минут'],
        correctAnswers: ['1 часа'],
      },
      {
        code: '63640090',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'При деформации фланцев',
          'При неудовлетворительном состоянии уплотнительных поверхностей',
          'При срыве, смятии и износе резьбы в резьбовых фланцах с номинальным давлением более 10 МПа, а также при наличии люфта в резьбе, превышающего допустимый нормативно-технической документацией',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63640091',
        text:
          'Где не располагается узел ввода теплоносителя на взрывопожароопасных производствах?',
        answers: [
          'В производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
          'В электропомещениях и помещениях контрольно-измерительных приборов и автоматики',
          'В помещениях систем приточной вентиляции (в вентиляционной камере)',
          'В самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
        ],
        correctAnswers: [
          'В электропомещениях и помещениях контрольно-измерительных приборов и автоматики',
        ],
      },
      {
        code: '63640092',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Автоматическое',
          'Дистанционное, неавтоматическое',
          'Ручное управление по месту',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63640093',
        text:
          'Чем должны оснащаться насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Системами самозапуска электродвигателей',
          'Автономной электрической подстанцией',
          'Устройствами для сбора и удаления жидкой фазы',
          'Блокировками',
        ],
        correctAnswers: ['Системами самозапуска электродвигателей'],
      },
      {
        code: '63640094',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
          'Не допускается ни в каком случае',
          'После проведения расчета на прочность',
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63640095',
        text:
          'Где должно осуществляться измельчение, смешивание измельченных твердых горючих продуктов для исключения образования в системе взрывоопасных смесей?',
        answers: [
          'В среде инертного газа',
          'В среде, богатой кислородом',
          'В среде, богатой углекислым газом',
          'В естественной среде',
        ],
        correctAnswers: ['В среде инертного газа'],
      },
      {
        code: '63640096',
        text:
          'Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность и надежность технологического процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63640097',
        text:
          'Кто определяет выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и других нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчик в задании на проектирование',
          'Разработчик процесса',
          'Разработчик проекта',
        ],
        correctAnswers: ['Разработчик процесса'],
      },
      {
        code: '63640098',
        text:
          'Какие требования предъявляются к системам канализации технологических объектов при сбросе химически загрязненных стоков в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны быть оборудованы звуковой и световой сигнализациями',
          'Системы канализации технологических объектов должны осуществлять сброс стоков в магистральную сеть в порядке, установленном организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
          'Системы канализации эксплуатируемых технологических объектов должны исключать залповые и аварийные сбросы стоков в магистральную сеть',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63640099',
        text:
          'Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?',
        answers: [
          'Научно-технической литературой',
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
          'Данными, запрашиваемыми у научно-исследовательской организации',
          'Справочной литературой',
        ],
        correctAnswers: [
          'Заданием на проектирование, на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
        ],
      },
      {
        code: '63640100',
        text: 'Какое требование к системам вентиляции указано неверно?',
        answers: [
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63640101',
        text:
          'В какой документации приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в исходных данных на проектирование и технологическом регламенте на производство продукции',
          'Только в проектной документации',
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'Только в технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63640102',
        text:
          'Какой следует принимать категорию взрывоопасности блоков, определяемую расчетом, если обращающиеся в технологическом блоке опасные вещества относятся к токсичным, высокотоксичным веществам?',
        answers: [
          'III категории',
          'На одну выше',
          'II категории',
          'I категории',
        ],
        correctAnswers: ['На одну выше'],
      },
      {
        code: '63640103',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'В случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Для обеспечения энергоэффективности',
          'Для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63640104',
        text:
          'Каким оборудованием оснащаются цистерны, предназначенные для перевозки по железным дорогам сжиженного углеводородного газа и легковоспламеняющихся жидкостей?',
        answers: [
          'Только средствами контроля уровня налива',
          'Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами',
          'Только предохранительными клапанами и другой арматурой',
          'Только сливоналивными устройствами',
        ],
        correctAnswers: [
          'Арматурой, средствами контроля, сливоналивными, защитными и другими устройствами',
        ],
      },
      {
        code: '63640105',
        text:
          'Какой параметр является критерием установления категории взрывоопасности технологических блоков согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств?',
        answers: [
          'Температура самовоспламенения паров обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
          'Класс опасности обращающихся в процессе веществ',
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
        correctAnswers: [
          'Расчетное значение относительных энергетических потенциалов технологических блоков, входящих в технологическую систему',
        ],
      },
      {
        code: '63640106',
        text:
          'Для трубопроводов каких технологических блоков при подключении к коллектору в обоснованных случаях для повышения надежности предусматривается установка дублирующих отключающих устройств?',
        answers: [
          'Для технологических блоков III категории взрывоопасности',
          'Для технологических блоков II категории взрывоопасности',
          'Для технологических блоков I категории взрывоопасности',
        ],
        correctAnswers: [
          'Для технологических блоков I категории взрывоопасности',
        ],
      },
      {
        code: '63640107',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: [
          '6 месяцев',
          '24 месяца',
          '36 месяцев',
          '12 месяцев',
          '3 месяца',
        ],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63640108',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'Если это определено и обосновано разработчиком проекта',
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
          'Если это определено и обосновано научно-исследовательской организацией',
          'По решению территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63640109',
        text:
          'Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?',
        answers: [
          'Оценку эффективности технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности технологических блоков и в целом всей технологической схемы',
          'Оценку энергетического уровня каждого технологического блока и определение категории его взрывоопасности',
          'В проектной документации производится оценка всех перечисленных параметров',
          'Оценку эффективности и надежности мер, обеспечивающих взрывобезопасность каждого технологического блока',
        ],
        correctAnswers: [
          'В проектной документации производится оценка всех перечисленных параметров',
        ],
      },
      {
        code: '63640110',
        text:
          'С учетом каких критериев выбираются насосы и компрессоры, используемые для перемещения газов, легковоспламеняющихся и горючих жидкостей, согласно Общим правилам взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом протяженности и способа прокладки трубопроводов',
          'С учетом физико-химических свойств перемещаемых продуктов',
          'С учетом диаметра и толщины стенки трубопроводов',
          'С учетом регламентированных параметров технологического процесса',
        ],
        correctAnswers: [
          'С учетом физико-химических свойств перемещаемых продуктов',
          'С учетом регламентированных параметров технологического процесса',
        ],
      },
      {
        code: '63640111',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
          'Время срабатывания устанавливается для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63640112',
        text:
          'Какие линии электроснабжения от внешних источников независимо от класса напряжения не должны оборудоваться устройствами автоматической частной разгрузки?',
        answers: [
          'Питающие потребителей любых категорий надежности электроснабжения',
          'Питающие потребителей II категории надежности электроснабжения',
          'Питающие потребителей особой группы I категории надежности электроснабжения',
          'Питающие потребителей III категории надежности электроснабжения',
        ],
        correctAnswers: [
          'Питающие потребителей особой группы I категории надежности электроснабжения',
        ],
      },
      {
        code: '63640113',
        text:
          'Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Ручное управление по месту',
          'Дистанционное, неавтоматическое',
          'Автоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63640114',
        text:
          'В каком случае допускается стационарное применение цельносварных гофрированных стальных труб, включая конструкции с теплоизоляционными и (или) защитными слоями?',
        answers: [
          'При обосновании в проекте',
          'Не допускается ни в каком случае',
          'По решению технической комиссии эксплуатирующей организации',
          'При согласовании с территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['При обосновании в проекте'],
      },
      {
        code: '63640115',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее трех участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63640116',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 1,8 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 1,2 МПа',
          'Под давлением, равным рабочему, но не более 6 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63640117',
        text:
          'На каких технологических трубопроводах не допускается применять сальниковые компенсаторы?',
        answers: [
          'На трубопроводах со средами 1-ой группы',
          'На трубопроводах для трудногорючих веществ',
          'На трубопроводах для негорючих веществ',
          'Допускается применять на всех перечисленных трубопроводах',
        ],
        correctAnswers: ['На трубопроводах со средами 1-ой группы'],
      },
      {
        code: '63640118',
        text:
          'Какими приборами могут быть оснащены сепараторы, устанавливаемые на всасывающей линии компрессора, вакуум-насоса, газодувки для отделения жидкой фазы из перемещаемой газовой среды?',
        answers: [
          'Только сигнализацией по максимальному уровню',
          'Всеми перечисленными',
          'Только приборами контроля уровня',
          'Только средствами блокировки',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63640119',
        text:
          'Что должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
          'Показатели надежности, конструктивные особенности и параметры работы',
          'Показатели надежности, конструктивные особенности компрессоров и насосов с учетом физико-химических свойств перемещаемых продуктов, исполнение по взрывозащите',
          'Показатели надежности и конструктивные особенности',
        ],
        correctAnswers: [
          'Показатели надежности, конструктивные особенности с учетом физико-химических свойств перемещаемых продуктов и регламентированные параметры технологического процесса',
        ],
      },
      {
        code: '63640120',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.',
        answers: [
          'Запорную и запорно-регулирующую арматуру',
          'Мануальные системы подавления взрыва',
          'Клапаны, отсекающие и другие отключающие устройства',
          'Средства, регистрирующие повышение давления',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру',
          'Клапаны, отсекающие и другие отключающие устройства',
        ],
      },
      {
        code: '63640121',
        text:
          'При какой периодичности использования трубопроводной арматуры ручной привод следует располагать на высоте не более 1,6 м от уровня пола или площадки, с которой ведется управление?',
        answers: [
          'Не реже одного раза в смену',
          'Расположение привода не зависит от частоты использования арматуры',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63640122',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы аварийного опорожнения аппаратов',
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63640123',
        text:
          'Где предусматривается установка постов управления и технических средств связи и оповещения для извещения об опасных выбросах химически опасных веществ на объектах, имеющих в своем составе блоки I категории взрывоопасности? Выберите два правильных варианта ответа.',
        answers: [
          'На ограждении по периметру объекта через каждые 50 м',
          'На контрольно-пропускном пункте объекта',
          'В помещении диспетчера предприятия',
          'На наружных установках',
        ],
        correctAnswers: [
          'В помещении диспетчера предприятия',
          'На наружных установках',
        ],
      },
      {
        code: '63640124',
        text:
          'Какие требования предъявляются к частоте продувки влагомаслоотделителей поршневых компрессоров при отсутствии автоматической продувки?',
        answers: [
          'Продувка должна производиться через каждые 40 минут',
          'Продувка должна производиться через каждые 20 минут',
          'Продувка должна производиться через каждые 60 минут',
          'Продувка должна производиться через каждые 30 минут',
        ],
        correctAnswers: ['Продувка должна производиться через каждые 30 минут'],
      },
      {
        code: '63640125',
        text:
          'В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?',
        answers: [
          'Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам',
          'В любом из указанных случаев',
          'Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам',
        ],
        correctAnswers: ['В любом из указанных случаев'],
      },
      {
        code: '63640126',
        text:
          'Каким документом устанавливаются места расположения запорных и (или) отсекающих устройств?',
        answers: [
          'Проектной документацией или документацией на техническое перевооружение',
          'Планом ликвидации аварий',
          'Проектом производства работ',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63640127',
        text:
          'Каким требованиям должны соответствовать помещения управления? Выберите два правильных варианта ответа.',
        answers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Полы в помещении должны быть холодными, с повышенной механической стойкостью',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
          'Защита от механических повреждений проложенных по полу кабелей должна осуществляться с помощью швеллеров и уголков',
        ],
        correctAnswers: [
          'Помещения должны иметь средства или системы пожаротушения и световую и звуковую сигнализацию о загазованности',
          'Воздух, подаваемый в помещения управления, должен быть очищен от газов, паров и пыли и соответствовать требованиям по эксплуатации установленного оборудования и санитарным нормам',
        ],
      },
      {
        code: '63640128',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию системы противоаварийной защиты',
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Обслуживающим персоналом по инструкции',
        ],
        correctAnswers: ['Обслуживающим персоналом по инструкции'],
      },
      {
        code: '63640129',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные горючие газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Должно быть организовано управление только по месту',
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
          'Должно быть организовано управление только дистанционно (из безопасного места)',
        ],
        correctAnswers: [
          'Должно быть организовано управление по месту и дистанционно (из безопасного места)',
        ],
      },
      {
        code: '63640130',
        text:
          'Кем разрабатываются исходные данные на разработку документации на химически опасных производственных объектах?',
        answers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
          'Организациями, осуществляющими проведение экспертизы промышленной безопасности',
          'Ростехнадзором',
          'Эксплуатирующими организациями',
        ],
        correctAnswers: [
          'Научно-исследовательскими организациями или организациями, специализирующимися в соответствующей области',
        ],
      },
      {
        code: '63640131',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от вида взрывозащиты',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63640132',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Дистанционно (из безопасного места)',
          'По месту и дистанционно (из безопасного места)',
          'По месту',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: ['По месту и дистанционно (из безопасного места)'],
      },
      {
        code: '63640133',
        text:
          'В каком случае перегрев паров аммиака, всасываемых компрессором, должен быть не менее 10 К (°C)?',
        answers: [
          'Для одноступенчатых компрессоров',
          'Для ступени высокого давления двухступенчатых компрессоров',
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
        correctAnswers: [
          'Для ступени низкого давления двухступенчатых компрессоров',
        ],
      },
      {
        code: '63640134',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Порошкообразные теплоизоляционные материалы',
          'Допускаются все перечисленные материалы',
          'Базальтовое супертонкое волокно',
          'Вату из непрерывного стеклянного волокна',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63640135',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63640136',
        text:
          'Что необходимо применять для смазки цилиндров поршневых кислородных компрессоров?',
        answers: [
          'Умягченную воду, полученную из заводской системы пароснабжения',
          'Масла, рекомендованные разработчиками компрессора',
          'Дистиллят, полученный из воды питьевого качества',
          'Конденсат, полученный из заводской системы пароснабжения',
        ],
        correctAnswers: ['Дистиллят, полученный из воды питьевого качества'],
      },
      {
        code: '63640137',
        text:
          'Что должно быть учтено в системах управления и защиты электроснабжающих организаций при электроснабжении объектов, отнесенных к особой группе I категории надежности электроснабжения?',
        answers: [
          'Наличие автоматического ввода резерва между каждым из трех самостоятельных источников электроснабжения',
          'Обеспечена возможность синхронизации третьего независимого источника предприятия-потребителя с электроснабжающей системой',
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
          'Обеспечена возможность синхронизации третьего независимого источника у предприятия-потребителя с электроснабжающей системой',
        ],
        correctAnswers: [
          'Линии электроснабжения не должны оборудоваться системами автоматической частотной разгрузки',
        ],
      },
      {
        code: '63640138',
        text:
          'Каких значений не должна превышать максимальная температура поверхностей нагрева систем отопления в помещениях, имеющих взрывопожароопасные зоны?',
        answers: [
          '85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          '90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          '80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63640139',
        text:
          'Чем обеспечивается противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
          'Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта',
          'Блокировками по отключению подачи топлива при превышении значения температуры нагреваемого жидкого продукта',
        ],
        correctAnswers: [
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
        ],
      },
      {
        code: '63640140',
        text:
          'В соответствии с какими требованиями должно выполняться проектирование систем водопровода и канализации взрывопожароопасных производств?',
        answers: [
          'Только в соответствии с требованиями технических регламентов',
          'Только в соответствии с требованиями градостроительной деятельности',
          'Только в соответствии с требованиями Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии со всеми перечисленными требованиями',
        ],
        correctAnswers: ['В соответствии со всеми перечисленными требованиями'],
      },
      {
        code: '63640141',
        text:
          'В каком случае допускается укладка технологических трубопроводов в два яруса и более?',
        answers: [
          'Диаметром до 300 мм включительно',
          'Не допускается ни в каком случае',
          'Диаметром до 500 мм включительно',
          'Диаметром до 400 мм включительно',
        ],
        correctAnswers: ['Диаметром до 300 мм включительно'],
      },
      {
        code: '63640142',
        text: 'Что относится к технологическим трубопроводам?',
        answers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы для транспортирования веществ, необходимых для ведения технологического процесса или эксплуатации оборудования',
          'Трубопроводы для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудованияя',
        ],
        correctAnswers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
      },
      {
        code: '63640143',
        text:
          'В каком документе указываются данные о сроке службы технологического оборудования и трубопроводной арматуры производителем?',
        answers: [
          'В проектной документации',
          'В техническом задании на изготовление оборудования',
          'В технологическом регламенте',
          'В паспортах оборудования и трубопроводной арматуры',
        ],
        correctAnswers: ['В паспортах оборудования и трубопроводной арматуры'],
      },
      {
        code: '63640144',
        text:
          'Каким образом должны подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств для оборудования (аппаратов и трубопроводов) до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63640145',
        text:
          'Что не должна исключать энергетическая устойчивость технологической системы с учетом категории взрывоопасности входящих в нее блоков, особенностей технологического процесса?',
        answers: [
          'Возможность образования в системе взрывоопасной среды (за счет увеличения времени пребывания продуктов в реакционной зоне, нарушения соотношения поступающих в нее продуктов, развития неуправляемых процессов)',
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
          'Возможность нарушения герметичности системы (разгерметизации уплотнений подвижных соединений, разрушения оборудования от превышения давления)',
        ],
        correctAnswers: [
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
        ],
      },
      {
        code: '63640146',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной автоматической защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты в течение 1 часа',
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и автоматики на заводскую сеть сжатого воздуха через осушитель воздуха',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной автоматической защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63640147',
        text:
          'Какими приборами и средствами автоматизации должны оснащаться сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
        ],
        correctAnswers: [
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
        ],
      },
      {
        code: '63640148',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах химических, нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63640149',
        text:
          'Какими приборами и средствами автоматизации оснащаются сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите два правильных варианта ответа.',
        answers: [
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
        ],
        correctAnswers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
        ],
      },
      {
        code: '63640150',
        text:
          'С какой периодичностью необходимо проверять исправность автоматических приборов защиты аммиачных компрессоров и сигнализаторов концентрации паров аммиака в воздухе помещений и наружных площадок?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 10 дней',
          'Не реже 1 раза в 3 месяца',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63640151',
        text:
          'Что в химико-технологических системах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы аварийного опорожнения аппаратов',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, средства защиты от превышения давления и огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие, а также другие отключающие устройства и предохранительные устройства от превышения давления',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие, а также другие отключающие устройства и предохранительные устройства от превышения давления',
        ],
      },
      {
        code: '63640152',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63640153',
        text:
          'Кто утверждает все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Технический руководитель организации, эксплуатирующей химико-технологическое производство',
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
          'Начальник отдела технического контроля организации, эксплуатирующей  химико-технологическое производство',
          'Специально созданная комиссия организации, эксплуатирующей химико-технологическое производство',
        ],
        correctAnswers: [
          'Руководитель (или его заместитель) организации, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63640154',
        text:
          'Что необходимо предусматривать в проектной документации или документации на техническое перевооружение для максимального снижения выбросов горючих и взрывопожароопасных веществ при аварийной разгерметизации системы?',
        answers: [
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 120 секунд',
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
          'Установку запорных и (или) отсекающих устройств с автоматическим управлением и временем срабатывания не более 300 секунд',
        ],
        correctAnswers: [
          'Установку запорных и (или) отсекающих устройств с временем срабатывания, устанавливаемым в проектной документации',
        ],
      },
      {
        code: '63640155',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития? Выберите два правильных варианта ответа.',
        answers: [
          'Системы аварийной вытяжной вентиляции',
          'Средства, регистрирующие повышение давления',
          'Клапаны, отсекающие и другие отключающие устройства',
          'Запорную и запорно-регулирующую арматуру',
        ],
        correctAnswers: [
          'Клапаны, отсекающие и другие отключающие устройства',
          'Запорную и запорно-регулирующую арматуру',
        ],
      },
      {
        code: '63640156',
        text:
          'Каким показателем характеризуется уровень взрывоопасности технологических блоков, входящих в технологическую систему?',
        answers: [
          'Приведенной массой вещества, участвующего во взрыве, в кг',
          'Радиусом зон разрушения, в м',
          'Категорией взрывоопасности технологических блоков',
          'Энергией сгорания парогазовой фазы в кДж',
        ],
        correctAnswers: ['Категорией взрывоопасности технологических блоков'],
      },
      {
        code: '63640157',
        text:
          'Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Должны быть учтены все перечисленные характеристики',
          'Только параметры технологического процесса',
          'Только физико-химические свойства перемещаемых продуктов',
          'Только показатели надежности и конструктивные особенности с учетом критических параметров',
        ],
        correctAnswers: ['Должны быть учтены все перечисленные характеристики'],
      },
      {
        code: '63640158',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 80% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 75% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63640159',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
          'Со средой 1-ой группы',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63640160',
        text:
          'Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?',
        answers: [
          'Разработчиком проекта',
          'Разработчиком проекта по расчетным данным',
          'Разработчиком проекта по литературным (справочным) данным',
          'Разработчиком проекта по исходным данным',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63640161',
        text:
          'В соответствии с чем должно осуществляться размещение организации, имеющей в своем составе взрывоопасные технологические объекты, планировка ее территории, объемно-планировочные решения строительных объектов?',
        answers: [
          'В соответствии с требованиями Общих правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'В соответствии с техническими регламентами',
          'В соответствии с требованиями законодательства о градостроительной деятельности',
          'В соответствии с требованиями территориальных органов Ростехнадзора',
        ],
        correctAnswers: [
          'В соответствии с требованиями законодательства о градостроительной деятельности',
        ],
      },
      {
        code: '63640162',
        text:
          'На какой стадии предусматриваются средства контроля и регулирования уровня разделения фаз горючих паров (газов) и жидкостей?',
        answers: [
          'На стадии строительства',
          'На стадии ликвидации',
          'На стадии эксплуатации',
          'На стадии проектирования процесса',
        ],
        correctAnswers: ['На стадии проектирования процесса'],
      },
      {
        code: '63640163',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Во всех перечисленных случаях производятся диагностические работы',
          'Если трубопроводы, не имеющие записи в паспортах о допустимом числе циклов нагружения, за время эксплуатации накопили более 1000 таких циклов',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы, не имеющие сведений о сроке службы, находились в эксплуатации более 10 лет',
          'Если трубопроводы находились в эксплуатации и на них были проведены ремонтно-сварочные работы, связанные с изменением конструкции и (или) заменой материала',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63640164',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Во всех перечисленных',
          'В технологическом регламенте на производство продукции',
          'В исходных данных на проектирование',
          'В проектной документации',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63640165',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63640166',
        text:
          'Кем определяется степень разделения материальных сред и меры обеспечения взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Разработчиком процесса',
          'Разработчиком проекта',
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
          'Разработчиком процесса и проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63640167',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 30 минут',
          'Через 10 минут',
          'Через 60 минут',
          'Через 5 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63640168',
        text:
          'Какие требования предъявляются к оборудованию, выведенному из действующей технологической системы?',
        answers: [
          'Оборудование должно быть изолировано от действующей системы, и нанесенное на нем обозначение номера по технологической схеме закрашено',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с взрывоопасными технологическими блоками, а при расположении на наружной установке оно должно быть изолировано от действующих систем',
          'Оборудование должно быть демонтировано',
        ],
        correctAnswers: [
          'Оборудование должно быть демонтировано, если оно находится в одном помещении с технологическими блоками I и (или) II категорий взрывоопасности, во всех остальных случаях оно должно быть изолировано от действующих систем',
        ],
      },
      {
        code: '63640169',
        text:
          'Какую температуру должна иметь вода для охлаждения компрессора на входе и на выходе из рубашек цилиндров, если заводом-изготовителем не предусмотрены другие предельные значения?',
        answers: [
          'Не ниже 20 °C и не более 50 °C соответственно',
          'Не ниже 15 °C и не более 40 °C соответственно',
          'Не ниже 10 °C и не более 45 °C соответственно',
          'Не ниже 5 °C и не более 35 °C соответственно',
        ],
        correctAnswers: ['Не ниже 10 °C и не более 45 °C соответственно'],
      },
      {
        code: '63640170',
        text:
          'В соответствии с чем должны определяться оптимальные методы создания системы противоаварийной защиты на стадии формирования требований при проектировании автоматизированной системы управления технологическим процессом?',
        answers: [
          'В соответствии с методиками и программными продуктами, применяемыми для моделирования аварийных ситуаций, утвержденных (согласованных) Ростехнадзором',
          'В соответствии со сценариями возможных аварийных ситуаций и способах перевода объекта в безопасное состояние',
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
          'В соответствии с алгоритмами, разработанными по сценариям всех возможных аварий и их развития',
        ],
        correctAnswers: [
          'В соответствии с требуемым уровнем полноты безопасности (УПБ), определяемым на стадии формирования требований при проектировании АСУТП на основании анализа опасности и работоспособности контуров безопасности с учетом риска, возникающего при отказе контура безопасности',
        ],
      },
      {
        code: '63640171',
        text:
          'В каком случае допускаются отклонения от действующего проекта, на основании которого проводятся работы, связанные с восстановлением работоспособного (исправного) состояния технологических трубопроводов?',
        answers: [
          'По решению комиссии эксплуатирующей организации',
          'При наличии письменного распоряжения технического руководителя эксплуатирующей организации',
          'В случае согласования необходимого отклонения с территориальным органом Ростехнадзора',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: ['Не допускаются ни в каком случае'],
      },
      {
        code: '63640172',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63640173',
        text:
          'Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?',
        answers: [
          'Только защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Только бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Должны быть обеспечены все указанные требования',
        ],
        correctAnswers: ['Должны быть обеспечены все указанные требования'],
      },
      {
        code: '63640174',
        text:
          'Кем определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'Разработчиком проекта',
          'Разработчиком проекта по расчетным данным',
          'Разработчиком процесса',
          'Разработчиком проекта по литературным (справочным) данным',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63640175',
        text:
          'Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных, складских (расходных) емкостей?',
        answers: [
          'На путях необщего пользования',
          'В местах проведения погрузочно-разгрузочных работ',
          'Нигде не разрешается',
          'На технологических путях организации',
        ],
        correctAnswers: ['Нигде не разрешается'],
      },
      {
        code: '63640176',
        text:
          'Выполнение какого условия, относящегося к трубопроводам и компрессорам продуктов разделения воздуха, допускается?',
        answers: [
          'Возможность попадания кислорода во всасывающую линию при обкатке компрессоров',
          'Объединение дренажных трубопроводов',
          'Все перечисленные условия',
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
        ],
        correctAnswers: [
          'Установка дренажного устройства для слива сконденсированных паров масла в нижней точке трубопровода отвода масла',
        ],
      },
      {
        code: '63640177',
        text:
          'Чем должны оснащаться колонны ректификации горючих жидкостей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами контроля за плотностью поступающих на разделение продукта и флегмы',
          'Средствами контроля за компонентным составом поступающего на разделение продукта',
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
        ],
        correctAnswers: [
          'Средствами сигнализации об опасных отклонениях значений параметров, определяющих взрывобезопасность процесса',
          'Средствами контроля и автоматического регулирования уровня и температуры жидкости в кубовой части, температуры поступающих на разделение продукта и флегмы',
        ],
      },
      {
        code: '63640178',
        text:
          'Чем подтверждаются эффективность и надежность средств взрывозащиты и локализации пламени и других противоаварийных устройств, направленных на взрывозащищенность оборудования?',
        answers: [
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63640179',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и систем противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63640180',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После нахождения на консервации более 3 месяцев',
          'После нахождения на консервации более одного года',
          'После нахождения на консервации более двух лет',
          'После нахождения на консервации более 6 месяцев',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63640181',
        text:
          'Какие функции должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Цикличный контроль за параметрами процесса, а также управление технологическим режимом для поддержания их регламентированных значений',
          'Периодический, не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
        correctAnswers: [
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
        ],
      },
      {
        code: '63640182',
        text:
          'Какая максимальная температура поверхностей нагрева систем отопления должна быть в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Максимальная температура не должна превышать 85% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 90% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
          'Максимальная температура не должна превышать 95% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Максимальная температура не должна превышать 80% температуры самовоспламенения вещества, имеющего самую низкую температуру самовоспламенения из обращающихся в процессе веществ',
        ],
      },
      {
        code: '63640183',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной автоматической защиты?',
        answers: [
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной автоматической защиты',
          'Средствами автоматического управления объектом по действующим программам',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63640184',
        text:
          'Чьими подписями оформляется "Лист подписей постоянного (временного, разового, лабораторного) технологического регламента"? Выберите два правильных варианта ответа.',
        answers: [
          'Заместителя руководителя организации по охране окружающей среды',
          'Начальника цеха',
          'Главного метролога организации',
          'Начальника производства',
          'Начальника центральной лаборатории организации',
        ],
        correctAnswers: ['Начальника цеха', 'Начальника производства'],
      },
      {
        code: '63640185',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Только в производственных помещениях, в которых предусмотрено применение водяного или парового отопления',
          'Во всех указанных местах',
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
        ],
        correctAnswers: ['Во всех указанных местах'],
      },
      {
        code: '63640186',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63640187',
        text:
          'Какие из перечисленных требований к компрессорным установкам указаны неверно?',
        answers: [
          'Запорная отсечная арматура, устанавливаемая на нагнетательном трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'Запорная отсечная арматура, устанавливаемая на всасывающем трубопроводе компрессора, должна быть к нему максимально приближена и находиться в зоне, удобной для обслуживания',
          'Все перечисленные требования указаны верно',
          'Порядок срабатывания систем блокировок насосов и компрессоров должен быть определен программой (алгоритмом) срабатывания системы ПАЗ технологической установки',
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
        ],
        correctAnswers: [
          'На нагнетательном трубопроводе компрессора должен быть установлен трехходовой шаровой кран, если нет другого устройства, предотвращающего перемещение транспортируемых веществ обратным ходом',
        ],
      },
      {
        code: '63640188',
        text:
          'В каких случаях должны автоматически включаться системы аварийной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
          'При поступлении сигнала от датчиков контроля за содержанием взрывопожароопасной пыли в воздухе  помещения',
          'При поступлении сигнала от датчиков повышения температуры',
        ],
        correctAnswers: [
          'При срабатывании сигнализаторов довзрывных концентраций вредных веществ',
          'При поступлении сигнала от газоанализаторов при превышении предельно допустимых концентраций вредных веществ',
        ],
      },
      {
        code: '63640189',
        text:
          'Какая система отопления должна применяться в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Система парового отопления',
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Система водяного отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63640190',
        text:
          'Какие из перечисленных мер по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Предотвращение взрывов и травмирования производственного персонала',
          'Уменьшение ущерба от возможной аварии на взрывопожароопасном объекте',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
        correctAnswers: [
          'Предотвращение взрывов и травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
      },
      {
        code: '63640191',
        text:
          'Как производится включение компрессора, отключенного из-за прекращения подачи охлаждающей воды?',
        answers: [
          'Только после его охлаждения и возобновления подачи воды',
          'Только после проведения отбора проб для анализа газа и последующего возобновления подачи воды',
          'Только после его охлаждения',
          'Только после возобновления подачи воды',
        ],
        correctAnswers: [
          'Только после его охлаждения и возобновления подачи воды',
        ],
      },
      {
        code: '63640192',
        text:
          'Как требуется размещать технологическое оборудование взрывопожароопасных производств?',
        answers: [
          'С учетом обеспечения безопасности обслуживания и эксплуатации',
          'С учетом всех перечисленных требований',
          'С учетом возможности проведения ремонтных работ и принятия оперативных мер по предотвращению аварийных ситуаций и локализации аварий',
          'С учетом возможности проведения визуального контроля за их состоянием, выполнения работ по обслуживанию, ремонту и замене',
        ],
        correctAnswers: ['С учетом всех перечисленных требований'],
      },
      {
        code: '63640193',
        text:
          'С какой периодичностью предохранительные устройства компрессорных агрегатов должны проверяться на давление срабатывания?',
        answers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 10 лет',
        ],
        correctAnswers: [
          'Сроки проверки устанавливаются технологическим регламентом и эксплуатационной документацией',
        ],
      },
      {
        code: '63640194',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать стационарные устройства, позволяющие производить отбор проб для анализа газа, выходящего из лабиринтных уплотнений компрессора?',
        answers: [
          'С давлением нагнетания 0,6 МПа',
          'На одном из компрессоров с давлением нагнетания более 0,6 МПа',
          'На любом из компрессоров с давлением нагнетания 0,6 МПа',
          'На каждом с давлением нагнетания более 0,6 МПа',
        ],
        correctAnswers: ['На каждом с давлением нагнетания более 0,6 МПа'],
      },
      {
        code: '63640195',
        text:
          'Какое требование не соответствует предъявляемым требованиям к специальным системам аварийного освобождения при проектировании технологических схем для новых производств для аварийного освобождения технологических блоков от обращающихся продуктов?',
        answers: [
          'Специальные системы аварийного освобождения должны находиться в постоянной готовности и исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Специальные системы аварийного освобождения должны находиться в постоянной готовности и оснащаться средствами контроля и управления',
          'Специальные системы аварийного освобождения должны находиться в постоянной готовности и обеспечивать минимально возможное время освобождения',
          'Специальные системы аварийного освобождения могут использоваться для других целей',
        ],
        correctAnswers: [
          'Специальные системы аварийного освобождения могут использоваться для других целей',
        ],
      },
      {
        code: '63640196',
        text: 'Чем должно быть оснащено оборудование для разделения суспензий?',
        answers: [
          'Фильтрами',
          'Обратным клапаном',
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
          'Гидрозатвором',
        ],
        correctAnswers: [
          'Блокировками, исключающими его пуск, обеспечивающими отключение и прекращение подачи суспензий при недопустимых отклонениях параметров инертной среды',
        ],
      },
      {
        code: '63640197',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
          'Перила должны быть высотой не менее 1,3 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63640198',
        text:
          'Кто подписывается в технологическом регламенте под грифом "согласовано"? Выберите два правильных варианта ответа.',
        answers: [
          'Начальник производственно-технического (технического) отдела организации',
          'Начальник производства',
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
        correctAnswers: [
          'Главный метролог организации',
          'Главный механик и главный энергетик организации',
        ],
      },
      {
        code: '63640199',
        text:
          'При достижении какого уровня заполнения газгольдера автоматически производится отключение компрессоров (газодувок), подключенных к газгольдеру?',
        answers: [
          'Предмаксимального',
          'Минимального',
          'Максимального',
          'Предминимального',
        ],
        correctAnswers: ['Минимального'],
      },
      {
        code: '63640200',
        text:
          'Что применяется при непрерывных процессах смешивания веществ, взаимодействие которых может привести к развитию экзотермических реакций, для исключения их неуправляемого течения? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка методов приостановки реакции путем увеличения подачи одного из смешиваемых веществ',
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Эффективное разделение этих продуктов и возможность образования застойных зон',
          'Разработка методов отвода тепла',
        ],
        correctAnswers: [
          'Определение безопасных объемных скоростей дозирования смешиваемых веществ',
          'Разработка методов отвода тепла',
        ],
      },
      {
        code: '63640201',
        text:
          'На каких трубопроводах следует применять арматуру под приварку для повышения надежности и плотности соединений?',
        answers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды менее 2,5 МПа',
          'На трубопроводах технологических блоков II категории взрывоопасности с температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
          'На трубопроводах технологических блоков III категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
        ],
        correctAnswers: [
          'На трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения среды при регламентированном давлении',
        ],
      },
      {
        code: '63640202',
        text:
          'Какой должна быть температура наружных поверхностей оборудования и кожухов теплоизоляционных покрытий в местах, доступных для обслуживающего персонала?',
        answers: [
          'Не более 35 °С внутри помещений и 65 °С на наружных установках',
          'Не более 50 °С внутри помещений и 55 °С на наружных установках',
          'Не более 40 °С внутри помещений и 70 °С на наружных установках',
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
        correctAnswers: [
          'Не более 45 °С внутри помещений и 60 °С на наружных установках',
        ],
      },
      {
        code: '63640203',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'При появлении трещин, срыва или коррозионного износа резьбы',
          'В случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случаях изгиба болтов и шпилек',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63640204',
        text:
          'Что должны обеспечивать системы противоаварийной автоматической защиты и управления технологическими процессами? Выберите два правильных варианта ответа.',
        answers: [
          'Время срабатывания систем защиты должно равняться времени, необходимому для перехода параметра от предупредительного до предельно допустимого значения',
          'Автоматический возврат технологического объекта в рабочее состояние после срабатывания системы противоаварийной автоматической защиты должен выполняться вручную или автоматически после установления причины срабатывания',
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
        correctAnswers: [
          'Исключение срабатывания систем от кратковременных сигналов нарушения нормального хода технологического процесса',
          'Перевод технологического объекта в безопасное состояние в случае отключения электроэнергии или прекращения подачи сжатого воздуха для питания систем контроля и управления',
        ],
      },
      {
        code: '63640205',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'П-образные компенсаторы',
          'Г-образные компенсаторы',
          'Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63640206',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться только на верхних ярусах технологических эстакад',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
        ],
        correctAnswers: [
          'Прокладку кабелей разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
        ],
      },
      {
        code: '63640207',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Поддержание положительной (свыше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме: 100% для всех продольных сварных швов; не менее 10% для всех кольцевых сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Район, непосредственно прилегающий к зоне испытаний, должен быть закрыт и обеспечен предупреждающими знаками, применяемыми для опасных зон',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63640208',
        text:
          'На кого возлагается ответственность за полноту и качество разработки разделов технологического регламента производства продукции и контроль за обеспечением его исполнения?',
        answers: [
          'На организацию - разработчика процесса',
          'На комиссию организации, эксплуатирующей химико-технологическое производство',
          'На руководителя организации, эксплуатирующей химико-технологическое производство',
          'На технологическую службу организации, производства, отделения, установки',
        ],
        correctAnswers: [
          'На технологическую службу организации, производства, отделения, установки',
        ],
      },
      {
        code: '63640209',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63640210',
        text:
          'Какие типы технологических регламентов предусматриваются в зависимости от степени освоенности производств и целей осуществляемых работ?',
        answers: [
          'Входящие в состав проектной документации или пусковые',
          'Постоянные, временные, разовые и лабораторные',
          'Периодически пересматриваемые',
        ],
        correctAnswers: ['Постоянные, временные, разовые и лабораторные'],
      },
      {
        code: '63640211',
        text:
          'Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?',
        answers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок',
          'Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
        ],
        correctAnswers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
        ],
      },
      {
        code: '63640212',
        text:
          'Без чего не допускается прямое соединение канализации химически загрязненных стоков с хозяйственно-бытовой канализацией на взрывопожароопасных производствах?',
        answers: [
          'Без гидрозатвора',
          'Без фильтра',
          'Без регулируемого клапана',
          'Без предохранительного клапана',
        ],
        correctAnswers: ['Без гидрозатвора'],
      },
      {
        code: '63640213',
        text:
          'Для каких технологических трубопроводов стилоскопирование проводится выборочно, но не менее двух соединений, выполненных одним сварщиком с использованием сварочных материалов из одной партии, при отсутствии в проекте иных указаний?',
        answers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
          'Для технологических трубопроводов при расчетном давлении выше 10 МПа',
          'Для технологических трубопроводов опасных веществ 1-го и 2-го классов опасности',
          'Для всех перечисленных технологических трубопроводов',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
        ],
      },
      {
        code: '63640214',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Системами ручного регулирования (без применения вычислительной техники)',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63640215',
        text:
          'Каков порядок сброса химически загрязненных стоков от отдельных технологических объектов в магистральную сеть канализации?',
        answers: [
          'Системы канализации технологических объектов должны исключать залповые и аварийные сбросы загрязненных стоков в магистральную сеть',
          'Системы канализации технологических объектов должны быть оборудованы устройствами для улавливания аварийных стоков перед их сбросом в магистральную сеть',
          'Порядок сброса стоков в магистральную сеть канализации устанавливается организацией',
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
        correctAnswers: [
          'Системы канализации технологических объектов перед сбросом в магистральную сеть должны обеспечивать удаление и очистку химически загрязненных технологических, смывных и других стоков, образующихся как при регламентированных режимах работы производства, так и в случаях аварийных выбросов',
        ],
      },
      {
        code: '63640216',
        text:
          'Какие требования предъявляются к обозначению средств автоматики, используемых по плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Средства автоматики должны быть обозначены на мнемосхемах',
          'Требования к обозначению определяются в процессе разработки планов мероприятий по локализации и ликвидации последствий аварий',
          'Средства автоматики должны быть обозначены только в технологическом регламенте',
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
        correctAnswers: [
          'Средства автоматики должны быть обозначены по месту их установки и указаны в технологическом регламенте и инструкциях',
        ],
      },
      {
        code: '63640217',
        text:
          'Каким документам должны соответствовать объемно-планировочные решения, конструкция зданий, помещений и вспомогательных сооружений для систем контроля, управления, противоаварийной автоматической защиты и газового анализа, их размещение на территории опасного производственного объекта нефтехимических производств?',
        answers: [
          'Требованиям технических регламентов',
          'Требованиям Правил взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств',
          'Всем перечисленным документам',
          'Требованиям законодательства о градостроительной деятельности',
          'Проектной документации',
        ],
        correctAnswers: ['Всем перечисленным документам'],
      },
      {
        code: '63640218',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'Надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'В грунте',
          'В каналах (закрытых или с засыпкой песком)',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63640219',
        text:
          'В течение какого времени буферные емкости (реципиенты) должны обеспечивать питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров?',
        answers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
          'Время должно быть подтверждено расчетом, но не менее 20 минут',
          'Время должно быть подтверждено расчетом, но не более 45 минут',
          'Время должно быть подтверждено расчетом, но не менее 35 минут',
        ],
        correctAnswers: [
          'В течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63640220',
        text:
          'На каких кислородных центробежных компрессорах необходимо предусматривать его автоматическую остановку при снижении давления газа, подаваемого в лабиринтные уплотнения?',
        answers: [
          'С давлением нагнетания 0,5 МПа',
          'С давлением нагнетания свыше 0,6 МПа',
          'С давлением нагнетания свыше 1,6 МПа',
          'С давлением нагнетания 0,6 МПа',
        ],
        correctAnswers: ['С давлением нагнетания свыше 0,6 МПа'],
      },
      {
        code: '63640221',
        text:
          'Чем оснащаются системы разделения газожидкостных смесей в целях обеспечения высокой эффективности разделения фаз?',
        answers: [
          'Гидрозатвором',
          'Обратным клапаном',
          'Фазоразделителями',
          'Фильтрами',
        ],
        correctAnswers: ['Фазоразделителями'],
      },
      {
        code: '63640222',
        text:
          'Каким образом осуществляется контроль за содержанием кислорода в горючем газе во всасывающих линиях компрессоров, работающих под разрежением? Выберите два правильных варианта ответа.',
        answers: [
          'При остановке компрессора во всасывающие линии насоса закачивается инертный газ',
          'Периодический контроль за содержанием кислорода в горючем газе должен проводиться не реже двух раз в смену',
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Определяются места размещения пробоотборников и способы контроля',
        ],
        correctAnswers: [
          'Предусматриваются блокировки, обеспечивающие отключение привода компрессора или подачу инертного газа в эти линии в случае повышения содержания кислорода в горючем газе выше предельно допустимого значения',
          'Определяются места размещения пробоотборников и способы контроля',
        ],
      },
      {
        code: '63640223',
        text:
          'Какой шириной допускается предусматривать проход между выступающими частями компрессорных агрегатов для вновь строящихся и реконструируемых систем холодоснабжения?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 1,0 м',
          'Не менее 1,2 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 1,0 м'],
      },
      {
        code: '63640224',
        text:
          'Какие условия должны выполняться для допуска к эксплуатации компрессорных установок?',
        answers: [
          'Получение разрешения на применения технических устройств, выдаваемого органами Ростехнадзора',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Все перечисленное',
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
        correctAnswers: [
          'Наличие и исправное состояние средств автоматизации, контроля и системы блокировок',
        ],
      },
      {
        code: '63640225',
        text:
          'Какими из перечисленных приборов могут быть оснащены сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора?',
        answers: [
          'Только средствами блокировки',
          'Только сигнализацией по максимальному уровню',
          'Всеми перечисленными приборами',
          'Только приборами контроля уровня',
        ],
        correctAnswers: ['Всеми перечисленными приборами'],
      },
      {
        code: '63640226',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости химико-технологической системы должны обеспечить способность функционирования средств противоаварийной защиты?',
        answers: [
          'Время устанавливается в проектной документации',
          'В течение 8 часов',
          'В течение 24 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63640227',
        text:
          'Какие из перечисленных функций должна обеспечивать автоматизированная система управления технологическими процессами на базе средств вычислительной техники? Выберите два правильных варианта ответа.',
        answers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Цикличный контроль за параметрами процесса и управление режимом для поддержания их регламентированных значений',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
          'Периодический, но не реже двух раз в смену, контроль за состоянием воздушной среды в пределах объекта',
        ],
        correctAnswers: [
          'Срабатывание средств управления и системы противоаварийной защиты, прекращающих развитие опасной ситуации',
          'Проведение операций безаварийного пуска, остановки и всех необходимых для этого переключений',
        ],
      },
      {
        code: '63640228',
        text:
          'На сколько категорий взрывоопасности подразделяются при проектировании технологические блоки взрывопожароопасных производств и объектов?',
        answers: [
          'На четыре категории',
          'На две категории',
          'На три категории',
          'На пять категорий',
        ],
        correctAnswers: ['На три категории'],
      },
      {
        code: '63640229',
        text:
          'Каким образом предприятие должно обеспечить наработку навыков действий персонала в нештатных (аварийных) ситуациях на установках с технологическими блоками I и II категорий взрывоопасности?',
        answers: [
          'Иметь специализированные центры обучения и подготовки для производственного персонала',
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
          'Допускать к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Иметь компьютерные тренажеры, включающие приближенные к реальным динамические модели процессов и средства управления',
        ],
        correctAnswers: [
          'Посредством обучения персонала на компьютерных тренажерах, включающих максимально приближенные к реальным динамические модели процессов и реальные средства управления',
        ],
      },
      {
        code: '63640230',
        text:
          'Каким образом обеспечивается отработка персоналом практических навыков безопасного выполнения работ, предупреждения аварий и ликвидации их последствий на технологических объектах с блоками I и II категорий взрывоопасности?',
        answers: [
          'Посредством допуска персонала к самостоятельной работе не ранее чем через 6 месяцев после стажировки на объекте',
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
          'Посредством наглядных пособий (плакатов) по охране труда',
          'Посредством наличия собственных специализированных центров обучения и подготовки производственного персонала',
        ],
        correctAnswers: [
          'Посредством прохождения курса подготовки с использованием современных технических средств обучения и отработки навыков (компьютерные тренажеры, учебно-тренировочные полигоны)',
        ],
      },
      {
        code: '63640231',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие токсичные, высокотоксичные вещества, органические теплоносители, воспламеняющиеся и горючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 6 лет',
          'Не реже одного раза в год',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63640232',
        text:
          'Для каких целей печи с открытым огневым процессом должны быть оборудованы паровой завесой?',
        answers: [
          'Для локализации пожаров на печи при установке ее внутри помещений',
          'Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи',
          'Для пожаротушения при аварии на печи',
          'Для локализации пожара на печи при установке ее вне зданий',
        ],
        correctAnswers: [
          'Для изоляции печи от взрывоопасной среды при авариях на наружных установках или в зданиях печи',
        ],
      },
      {
        code: '63640233',
        text:
          'С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом характеристики транспортируемой среды',
          'С учетом марки стали трубопровода',
          'С учетом диаметра и протяженности трубопровода',
          'С учетом толщины стенок трубопровода',
        ],
        correctAnswers: [
          'С учетом характеристики транспортируемой среды',
          'С учетом диаметра и протяженности трубопровода',
        ],
      },
      {
        code: '63640234',
        text:
          'Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Только система парового отопления',
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
          'Только система водяного отопления',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63640235',
        text:
          'Каким образом осуществляется управление подачей инертных сред на установку с технологическими блоками любой категории взрывоопасности там, где при отклонении от регламентированных значений параметров возможно образование взрывоопасных смесей?',
        answers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
          'Для установок с технологическими блоками I категории взрывоопасности - автоматическое управление, для установок с технологическими блоками II категории взрывоопасности - ручное дистанционное, для установок с технологическими блоками III категории взрывоопасности допускается ручное управление по месту',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление, а при Qв ≤ 10 - управление ручное дистанционное',
          'Для установок с технологическими блоками I, II и III категорий взрывоопасности - автоматическое управление',
        ],
        correctAnswers: [
          'Для установок с технологическими блоками I и II категорий взрывоопасности - автоматическое управление, с технологическими блоками III категории - дистанционное, неавтоматическое, а при Qв ≤ 10 допускается ручное управление по месту',
        ],
      },
      {
        code: '63640236',
        text:
          'Какая аппаратура оснащается средствами автоматического контроля, регулирования и сигнализации уровня хладагента в теплообменных элементах?',
        answers: [
          'Аппаратура для ведения жидкофазных процессов',
          'Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости',
          'Реакционные аппараты взрывоопасных технологических процессов с перемешивающими устройствами',
        ],
        correctAnswers: [
          'Реакционная аппаратура, в которой отвод избыточного тепла реакции при теплопередаче через стенку осуществляется за счет испарения охлаждающей жидкости',
        ],
      },
      {
        code: '63640237',
        text:
          'В каком случае допускается размещать холодильное оборудование над площадками открытых насосных и компрессорных установок? Выберите два правильных варианта ответа.',
        answers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'Не допускается ни в каком случае',
          'В случае размещения холодильного оборудования над площадками на расстоянии, определенном проектной документацией',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
        correctAnswers: [
          'В случае применения герметичных (бессальниковых) насосов',
          'При принятии специальных мер безопасности, исключающих попадание аммиака на ниже установленное оборудование',
        ],
      },
      {
        code: '63640238',
        text:
          'Каким должно быть содержание масла в кислороде, поступающем в компрессоры и газодувки?',
        answers: [
          'Содержание масла не должно превышать 0,1 мг/м³',
          'Содержание масла не должно превышать 0,02 мг/м³',
          'Содержание масла не должно превышать 0,2 мг/м³',
          'Содержание масла не должно превышать 0,04 мг/м³',
        ],
        correctAnswers: ['Содержание масла не должно превышать 0,02 мг/м³'],
      },
      {
        code: '63640239',
        text:
          'Каким требованиям должны соответствовать системы общеобменной и аварийной вытяжной вентиляции? Выберите два правильных варианта ответа.',
        answers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Общеобменная вентиляция должна включаться при срабатывании установленных в производственном помещении сигнализаторов довзрывных концентраций',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
          'Включение аварийной вытяжной вентиляции должно производиться при работающей общеобменной вентиляции в случае недостаточности воздухообмена',
        ],
        correctAnswers: [
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
        ],
      },
      {
        code: '63640240',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
          'Циклонами',
          'Виброситом',
          'Устройствами для подключения линий водорода',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63640241',
        text:
          'В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'В технологическом регламенте',
          'В исходных данных на проектирование и технологическом регламенте',
          'В проектной документации',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63640242',
        text:
          'Чем должны оснащаться сушильные установки, имеющие непосредственный контакт высушиваемого продукта с сушильным агентом?',
        answers: [
          'Световой сигнализацией',
          'Сепараторами',
          'Блокировками',
          'Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки',
          'Звуковой сигнализацией',
        ],
        correctAnswers: [
          'Устройствами очистки отработанного сушильного агента от пыли высушиваемого продукта и средствами контроля очистки',
        ],
      },
      {
        code: '63640243',
        text:
          'Кем разрабатываются все виды технологических регламентов, кроме разовых (опытных) регламентов для опытных установок, а также опытных работ, проводимых на действующих производствах?',
        answers: [
          'Организацией - разработчиком процесса',
          'Специализированной сторонней организацией',
          'Организацией, эксплуатирующей химико-технологическое производство',
          'Комиссией под председательством руководителя территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей химико-технологическое производство',
        ],
      },
      {
        code: '63640244',
        text:
          'В каких случаях на трубопроводах следует применять арматуру под приварку?',
        answers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков любой категории взрывоопасности',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I и II категорий взрывоопасности и температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа',
        ],
        correctAnswers: [
          'При повышенных требованиях по надежности и плотности соединений на трубопроводах технологических блоков I категории взрывоопасности с давлением среды более 2,5 МПа, температурой, равной температуре кипения при регламентированном давлении',
        ],
      },
      {
        code: '63640245',
        text:
          'Где разрешается использование железнодорожных цистерн с легковоспламеняющимися жидкостями, находящимися на железнодорожных путях, в качестве стационарных складских емкостей?',
        answers: [
          'В местах проведения погрузочно-разгрузочных работ',
          'Нигде не разрешается',
          'На технологических путях организации',
          'На путях необщего пользования',
        ],
        correctAnswers: ['Нигде не разрешается'],
      },
      {
        code: '63640246',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'Только в технологических блоках I категории взрывоопасности',
          'Только в технологических блоках II категории взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
          'В технологических блоках всех категорий взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63640247',
        text:
          'Какой категории взрывоопасности технологических блоков не существует?',
        answers: [
          'IV категории',
          'III категории',
          'II категории',
          'I категории',
        ],
        correctAnswers: ['IV категории'],
      },
      {
        code: '63640248',
        text:
          'Арматура с каким классом герметичности затвора должна устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'СС',
          'Определяется в проектной документации (документации на техническое перевооружение)',
          'В',
          'А',
          'АА',
        ],
        correctAnswers: [
          'Определяется в проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63640249',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение десяти календарных дней со дня принятия указанного решения',
          'В течение десяти рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати календарных дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63640250',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите два правильных варианта ответа.',
        answers: [
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Обеспечивать минимально возможное время освобождения',
          'Быть мобильными, иметь небольшие габариты и вес',
          'Переход из режима ожидания в рабочее состояние должен осуществляться в течение 30 секунд',
        ],
        correctAnswers: [
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Обеспечивать минимально возможное время освобождения',
        ],
      },
      {
        code: '63640251',
        text:
          'Когда должно происходить автоматическое включение аварийной вентиляции, установленной в анализаторных помещениях?',
        answers: [
          'Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 15% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 10% НКПР',
          'Если концентрация обращающихся веществ в воздухе помещения достигает 18% НКПР',
        ],
        correctAnswers: [
          'Если концентрация обращающихся веществ в воздухе помещения достигает 20% НКПР',
        ],
      },
      {
        code: '63640252',
        text:
          'Какими функциями должна обладать противоаварийная автоматическая защита нагреваемых элементов (змеевиков) нагревательных печей? Выберите два правильных варианта ответа.',
        answers: [
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Средствами контроля и сигнализации за температурой нагреваемого жидкого продукта',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
          'Блокировками по отключению подачи топлива при превышении предельно допустимого значения температуры нагреваемого жидкого продукта',
        ],
        correctAnswers: [
          'Средствами автоматического отключения подачи сырья и топлива в случаях аварий в системах змеевиков',
          'Аварийным освобождением змеевиков печи от нагреваемого жидкого продукта при повреждении труб или прекращении его циркуляции',
        ],
      },
      {
        code: '63640253',
        text:
          'Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?',
        answers: [
          'Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности',
        ],
        correctAnswers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
        ],
      },
      {
        code: '63640254',
        text:
          'Какие требования должны быть выполнены для вновь проектируемых взрывопожароопасных и химически опасных объектов? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
        ],
        correctAnswers: [
          'Обеспечена защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Обеспечено бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
        ],
      },
      {
        code: '63640255',
        text:
          'Какими блокировками должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск или прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
          'Блокировками, исключающими пуск или прекращающими работу при достижении опасных значений в расходной емкости',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63640256',
        text:
          'Какие меры по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите два правильных варианта ответа.',
        answers: [
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
        ],
        correctAnswers: [
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Предупреждение взрывов и предотвращение травмирования производственного персонала',
        ],
      },
      {
        code: '63640257',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Инспектор территориального органа Ростехнадзора',
          'Комиссия эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель проектной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63640258',
        text:
          'Запорная арматура из каких материалов должна применяться в технологических системах с блоками любой категории взрывоопасности?',
        answers: [
          'Из чугуна',
          'Из стале-алюминиевых сплавов',
          'Из неметаллических конструкционных материалов',
          'Из стали',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63640259',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва, а также системы для аварийного опорожнения аппаратов',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорная арматура, средства защиты от превышения давления, а также огнепреградители',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63640260',
        text:
          'От какого вида электричества должны предусматриваться меры защиты при проектировании сливоналивных эстакад сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'От атмосферного и статического электричества',
          'Только от атмосферного электричества',
          'Только от статического электричества',
        ],
        correctAnswers: ['От атмосферного и статического электричества'],
      },
      {
        code: '63640261',
        text:
          'Что не оказывает непосредственного влияния на химическую безопасность проведения отдельного технологического процесса?',
        answers: [
          'Рациональный подбор взаимодействующих компонентов, исходя из условия максимального снижения или исключения образования химически опасных смесей или продуктов',
          'Применение компонентов в фазовом состоянии, затрудняющем или исключающем образование химически опасной смеси',
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
          'Нарушение энергообеспечения',
        ],
        correctAnswers: [
          'Наличие средств индивидуальной защиты у персонала, обслуживающего технологические установки',
        ],
      },
      {
        code: '63640262',
        text:
          'Что в технологических схемах относится к разряду противоаварийных устройств, используемых для предупреждения аварий и предупреждения их развития?',
        answers: [
          'Запорная арматура, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
          'Запорная арматура, средства защиты от превышения давления, огнепреградители',
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
        ],
        correctAnswers: [
          'Запорная и запорно-регулирующая арматура, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63640263',
        text:
          'Каким требованиям должны соответствовать вновь проектируемые здания взрывопожароопасных объектов, в которых располагаются помещения управления (операторные), с постоянным пребыванием в них людей? Выберите два правильных варианта ответа.',
        answers: [
          'Обеспечивать безопасность находящегося в них персонала',
          'Вокруг зданий должна предусматриваться вспаханная полоса земли шириной не менее 3 м',
          'Быть устойчивыми к воздействию ударной волны',
          'Окна зданий должны быть оборудованы жалюзи из прочных материалов',
        ],
        correctAnswers: [
          'Обеспечивать безопасность находящегося в них персонала',
          'Быть устойчивыми к воздействию ударной волны',
        ],
      },
      {
        code: '63640264',
        text:
          'Какие требования предъявляются к пневматическим системам контроля, управления и противоаварийной автоматической защиты?',
        answers: [
          'Качество сжатого воздуха должно проверяться не реже одного раза в квартал',
          'Использование инертного газа для питания систем контрольно-измерительных приборов и устройств автоматического регулирования определяется конкретными условиями и потребностями объекта',
          'Для пневматических систем контроля, управления и противоаварийной защиты предусматриваются единые установки и единые сети сжатого воздуха',
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
        correctAnswers: [
          'Воздух для воздушных компрессоров и систем контрольно-измерительных приборов и устройств автоматического регулирования должен быть очищен от пыли, масла, влаги',
        ],
      },
      {
        code: '63640265',
        text:
          'При какой единичной производительности центробежных или поршневых компрессоров, работающих на один коллектор нагнетания кислорода, и давлении кислорода в трубопроводе нагнетания кислорода, устанавливают отключающую арматуру?',
        answers: [
          'Более 2100 м³/ч и свыше 1,8 МПа соответственно',
          'Более 2500 м³/ч и свыше 2,6 МПа соответственно',
          'Более 2200 м³/ч и свыше 2,0 МПа соответственно',
          'Более 2000 м³/ч и свыше 1,6 МПа соответственно',
        ],
        correctAnswers: ['Более 2000 м³/ч и свыше 1,6 МПа соответственно'],
      },
      {
        code: '63640266',
        text:
          'Чем оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества?',
        answers: [
          'Системами ручного (без применения вычислительной техники) регулирования',
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63640267',
        text:
          'Какие требования необходимо выполнять при прокладке кабелей по территории технологических установок? Выберите два правильных варианта ответа.',
        answers: [
          'Прокладка кабелей в каналах и траншеях запрещается',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Провода и кабели с полиэтиленовой изоляцией или оболочкой должны прокладываться на верхних ярусах технологических эстакад',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
        ],
        correctAnswers: [
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов',
        ],
      },
      {
        code: '63640268',
        text:
          'Что должно устанавливаться на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Арматура с резиновым уплотнением в затворе',
          'Арматура с тканевым уплотнением в затворе',
          'Арматура в соответствии с проектной документацией',
          'Арматура с пластмассовым уплотнением в затворе',
        ],
        correctAnswers: ['Арматура в соответствии с проектной документацией'],
      },
      {
        code: '63640269',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На сети водоснабжения и канализации',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63640270',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей, и рабочими площадками',
        ],
      },
      {
        code: '63640271',
        text:
          'Какие технологические блоки относятся к первой категории взрывоопасности?',
        answers: [
          'Qв меньше 27, m, кг меньше 2000',
          'Qв больше 37, m, кг больше 5000',
          'Qв 27 - 37, m, кг 2000 - 5000',
        ],
        correctAnswers: ['Qв больше 37, m, кг больше 5000'],
      },
      {
        code: '63640272',
        text:
          'Какая арматура устанавливается на трубопроводах для транспортирования взрывопожароопасных продуктов?',
        answers: [
          'Только с пластмассовым уплотнением в затворе',
          'Соответствующая проектной документации (документации на техническое перевооружение)',
          'Только с резиновым уплотнением в затворе',
          'Только с тканевым уплотнением в затворе',
        ],
        correctAnswers: [
          'Соответствующая проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63640273',
        text:
          'Какая установлена продолжительность дополнительных испытаний на герметичность для строящихся межцеховых, внутрицеховых и межзаводских трубопроводов?',
        answers: [
          'Не менее 24 часов',
          'Не менее 4 часов',
          'Не менее 8 часов',
          'Не менее 12 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '63640274',
        text:
          'В соответствии с чем осуществляется ведение технологических процессов на опасных производственных объектах нефтехимических и нефтегазоперерабатывающих производств?',
        answers: [
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с рекомендациями территориального управления Ростехнадзора',
          'В соответствии с технологическими регламентами на производство продукции',
          'В соответствии с заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами на производство продукции',
        ],
      },
      {
        code: '63640275',
        text:
          'В течение какого времени средства обеспечения энергоустойчивости технологической системы должны обеспечивать способность функционирования средств противоаварийной защиты?',
        answers: [
          'В течение 2 часов',
          'В течение времени, достаточного для исключения опасной ситуации',
          'В течение 8 часов',
          'В течение 24 часов',
        ],
        correctAnswers: [
          'В течение времени, достаточного для исключения опасной ситуации',
        ],
      },
      {
        code: '63640276',
        text:
          'С какими подразделениями должна быть обеспечена связь посредством системы двусторонней громкоговорящей связи на объектах с технологическими блоками I категории взрывоопасности?',
        answers: [
          'Только с пожарной частью и сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
          'Только с персоналом диспетчерских пунктов, газоспасательной службой и штабом гражданской обороны',
        ],
        correctAnswers: [
          'С персоналом диспетчерских пунктов, штабом гражданской обороны промышленного объекта, газоспасательной службой, пожарной частью, сливоналивными пунктами, складами и насосными горючих, сжиженных и вредных продуктов',
        ],
      },
      {
        code: '63640277',
        text:
          'Что должно проектироваться и выбираться с учетом анализа возможных рисков образования взрывоопасных веществ вследствие взаимного проникновения и взаимодействия теплоносителя с технологической средой для того, чтобы предотвратить возможность возникновения аварийных ситуаций?',
        answers: [
          'Только теплообменное оборудование',
          'Только теплообменные процессы',
          'Только тип теплоносителя (хладагента)',
          'Теплообменные процессы и теплообменное оборудование',
        ],
        correctAnswers: ['Теплообменные процессы и теплообменное оборудование'],
      },
      {
        code: '63640278',
        text:
          'С какой скоростью следует снижать уровень воды в оборудовании после заполнения для обеспечения медленного окисления пирофорных отложений при очистке оборудования от пирофорных соединений?',
        answers: [
          'Не более 0,5 м в час',
          'Не более 0,8 м в час',
          'Не более 1 м в час',
          'Не более 1,2 м в час',
        ],
        correctAnswers: ['Не более 0,5 м в час'],
      },
      {
        code: '63640279',
        text:
          'Какое из перечисленных требований к дренажам и продувкам трубопроводов указано неверно?',
        answers: [
          'Для технологических трубопроводов со средами 1-ой группы должны быть предусмотрены в начальных и конечных точках штуцера с арматурой и заглушкой для продувки их инертным газом или водяным паром и (или) промывки водой либо специальными растворами',
          'Для дренажа опасных веществ 1-го и 2-го классов опасности и сжиженных газов использование устройств для опорожнения с применением гибких шлангов не допускается',
          'Дренажные устройства для аварийного опорожнения проектируют стационарными конструкциями',
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
        correctAnswers: [
          'Для заполнения и опорожнения нестационарного оборудования (слив и налив железнодорожных цистерн, автоцистерн, контейнеров, бочек и баллонов) не допускается применение гибких шлангов',
        ],
      },
      {
        code: '63640280',
        text:
          'С учетом чего должна проектироваться, изготавливаться и эксплуатироваться система транспорта сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей посредством насосов?',
        answers: [
          'С учетом химического анализа проб горючих веществ на содержание кислорода',
          'С учетом возможности проведения эффективной и безопасной очистки системы',
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
        correctAnswers: [
          'С учетом анализа эксплуатационных отказов для того, чтобы предотвратить возможность возникновения аварийных режимов',
        ],
      },
      {
        code: '63640281',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
    ],
  },
  4: {
    63674: [
      {
        code: '63674000',
        text:
          'Когда могут быть допущены работники шахты на место проведенного массового взрыва?',
        answers: [
          'Только после приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 2 часа после взрыва',
          'Только после восстановления нормальной рудничной атмосферы и проверки начальником участка состояния действующих выработок',
          'После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва',
        ],
        correctAnswers: [
          'После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва',
        ],
      },
      {
        code: '63674001',
        text:
          'Разрешается ли одновременное производство работ в наклонных выработках на различных отметках?',
        answers: [
          'Разрешается по письменному наряду',
          'Разрешается по согласованию с надзорными органами',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63674002',
        text:
          'Какое минимальное количество независимых сигнальных устройств должна иметь каждая подъемная установка при проходке и углублении стволов согласно "Правилам безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: ['Два', 'Три', 'Четыре'],
        correctAnswers: ['Два'],
      },
      {
        code: '63674003',
        text:
          'При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?',
        answers: [
          'При обрушении в горной выработке',
          'При пожаре в тупиковой выработке',
          'При взрыве метана и (или) угольной пыли',
          'При внезапном выбросе угля (породы)',
        ],
        correctAnswers: ['При обрушении в горной выработке'],
      },
      {
        code: '63674004',
        text:
          'В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?',
        answers: [
          'Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха',
          'Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород',
          'Только в случае тушения пожара водой',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха',
        ],
      },
      {
        code: '63674005',
        text:
          'В какие сроки проводится проверка маркшейдерской службой положения стенок шахтного ствола и проводников в нем?',
        answers: [
          'Срок устанавливается в проектной документации, но не реже 1 раза в год',
          'Срок устанавливается в зависимости от горно-геологических условий, но не реже 1 раза в 5 лет',
          'Срок устанавливается техническим руководителем эксплуатирующей организации для каждого ствола, но не реже 1 раза в 3 года',
        ],
        correctAnswers: [
          'Срок устанавливается техническим руководителем эксплуатирующей организации для каждого ствола, но не реже 1 раза в 3 года',
        ],
      },
      {
        code: '63674006',
        text:
          'В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?',
        answers: [
          'В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
          'В случае, если продолжительность подачи инертного газа превышает допустимую',
          'В случае, если это определено оперативным планом',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
        ],
      },
      {
        code: '63674007',
        text: 'Какие блокировки должна иметь защитная аппаратура?',
        answers: [
          'От перебоев напряжения',
          'Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока',
          'Против срабатывания защиты отключения магнитных пускателей',
        ],
        correctAnswers: [
          'Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока',
        ],
      },
      {
        code: '63674008',
        text: 'Где должны быть расположены выходы из мест ожидания?',
        answers: [
          'В непосредственной близости от ствола шахты',
          'В местах, определяемых расчетами',
          'В местах с благоприятными горно-геологическими условиями',
        ],
        correctAnswers: ['В непосредственной близости от ствола шахты'],
      },
      {
        code: '63674009',
        text:
          'Кому запрещается находиться на командном пункте, организованном на период ведения работ по локализации и ликвидации последствий аварии на опасном производственном объекте?',
        answers: [
          'Лицам, назначенным для ведения оперативных журналов и другой документации, связанной с ведением работ по локализации и ликвидации последствий аварии и горноспасательных работ',
          'Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)',
          'Руководителю горноспасательных работ',
          'Руководителю работ по ликвидации аварии',
        ],
        correctAnswers: [
          'Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)',
        ],
      },
      {
        code: '63674010',
        text:
          'Какое количество отделений (горноспасательных или отделений вспомогательной горноспасательной команды) направляется по каждому маршруту до границ зон поражения при взрывах, определенных планом локализации и ликвидации последствий аварии при наличии признаков пожара и взрывоопасных концентраций рудничных газов?',
        answers: [
          'Не более одного отделения',
          'Не менее двух отделений',
          'Не менее трех отделений',
          'Не более двух отделений',
        ],
        correctAnswers: ['Не более одного отделения'],
      },
      {
        code: '63674011',
        text:
          'Кто имеет право письменным приказом отстранить ответственного руководителя работ и принять руководство на себя или возложить его на другое лицо из числа должностных лиц шахты?',
        answers: [
          'Начальник шахты',
          'Командир ВГСЧ',
          'Главный энергетик шахты',
          'Главный инженер организации (предприятия)',
        ],
        correctAnswers: ['Главный инженер организации (предприятия)'],
      },
      {
        code: '63674012',
        text:
          'С какой периодичностью механик участка должен проводить осмотр канатов маневровых лебедок вспомогательного транспорта?',
        answers: ['Ежесуточно', 'Еженедельно', 'Раз в полгода', 'Ежемесячно'],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63674013',
        text:
          'Какими средствами пожаротушения должны быть оборудованы магистральные и участковые конвейерные линии, оснащенные трудновоспламеняющейся лентой?',
        answers: [
          'Системами автоматического пожаротушения и автоматической пожарной сигнализацией по всей длине конвейера с выводом к диспетчеру шахты',
          'Системами автоматического пожаротушения на приводных станциях',
          'Автоматической сигнализацией по всей длине конвейера с выводом к диспетчеру шахты',
        ],
        correctAnswers: [
          'Системами автоматического пожаротушения и автоматической пожарной сигнализацией по всей длине конвейера с выводом к диспетчеру шахты',
        ],
      },
      {
        code: '63674014',
        text:
          'При отсутствии проектных регламентов кем могут разрабатываться регламенты на все основные и вспомогательные производственные процессы при ведении горных работ подземным способом?',
        answers: [
          'Эксплуатирующей организацией',
          'Организацией, проводящей экспертизу промышленной безопасности ОПО',
          'Специальной комиссией во главе с инспектором территориального органа Ростехнадзора',
          'Монтажно-наладочной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63674015',
        text:
          'Кто несет персональную ответственность за своевременное размещение в планах горных работ результатов регионального и локального прогноза удароопасности?',
        answers: [
          'Председатель комиссии по горным ударам',
          'Технический руководитель организации',
          'Начальник службы прогноза и предотвращения горных ударов',
          'Руководитель организации',
        ],
        correctAnswers: [
          'Начальник службы прогноза и предотвращения горных ударов',
        ],
      },
      {
        code: '63674016',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'С территориальными органами Ростехнадзора',
          'Со специализированными профессиональными аварийно-спасательными формированиями',
          'Со сторонними организациями, расположенными на территории горного отвода',
          'С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63674017',
        text:
          'Какое расстояние должно быть между осями рельсовых путей в двухпутевых выработках?',
        answers: [
          'Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был не менее 0,2 м',
          'Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был не менее 0,4 м',
          'Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был не менее 0,1 м',
        ],
        correctAnswers: [
          'Расстояние между осями рельсовых путей в двухпутевых выработках должно быть такое, чтобы зазор между наиболее выступающими частями встречных транспортных средств был не менее 0,2 м',
        ],
      },
      {
        code: '63674018',
        text:
          'Кто допускается к техническому руководству работами на объектах ведения горных работ и переработки полезных ископаемых?',
        answers: [
          'Лица, имеющие высшее образование',
          'Лица, имеющие высшее или среднее специальное соответствующее образование',
          'Лица, имеющие стаж работы на подземных объектах более 5 лет',
          'Лица, прошедшие проверку знаний в области охраны труда и промышленной безопасности',
        ],
        correctAnswers: [
          'Лица, имеющие высшее или среднее специальное соответствующее образование',
        ],
      },
      {
        code: '63674019',
        text:
          'Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
          'Только привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой',
          'Только ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания рудничной атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C',
          'Все перечисленные действия',
        ],
        correctAnswers: [
          'Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
        ],
      },
      {
        code: '63674020',
        text:
          'На кого в организации возлагается ответственность за безопасную эксплуатацию электроустановок?',
        answers: [
          'Только на технического руководителя шахты',
          'Только на механика',
          'На лиц, ответственных за электрохозяйство по организации и структурным подразделениям',
          'На специалистов службы промышленной безопасности, имеющих соответствующую группу по электробезопасности',
        ],
        correctAnswers: [
          'На лиц, ответственных за электрохозяйство по организации и структурным подразделениям',
        ],
      },
      {
        code: '63674021',
        text:
          'В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?',
        answers: [
          'Не позднее чем за 10 дней до установленной даты их рассмотрения',
          'Не позднее чем за 7 дней до установленной даты их рассмотрения',
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
          'Не позднее чем за 5 дней до установленной даты их рассмотрения',
        ],
        correctAnswers: [
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
        ],
      },
      {
        code: '63674022',
        text:
          'Сколько подвесок должны иметь клети грузолюдских и людских подъемных установок?',
        answers: [
          'Одну подвеску',
          'По расчету, утвержденному механиком подъема, одну или две',
          'Двойную независимую подвеску - рабочую и предохранительную',
        ],
        correctAnswers: [
          'Двойную независимую подвеску - рабочую и предохранительную',
        ],
      },
      {
        code: '63674023',
        text:
          'Что из перечисленного входит в должностные обязанности руководителя горноспасательных работ (РГСР)?',
        answers: [
          'Только обеспечение прибытия горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА или оперативным планом',
          'Только получение заданий от руководителя работ по ликвидации аварии (РЛА) на проведение горноспасательных работ',
          'Только проведение анализа полученной от РЛА информации о происшедшей аварии',
          'Только организация ведения оперативного журнала ПАСС(Ф)',
          'Только организация ведения горноспасательных работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63674024',
        text: 'Кем утверждается план и схема развития горных работ?',
        answers: [
          'Территориальным органом Ростехнадзора',
          'Руководителем организации - пользователя недр',
          'Главным техническим руководителем организации',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63674025',
        text:
          'Какие из перечисленных условий рекомендуется включать в Регламенты технологических процессов по ведению очистных работ?',
        answers: [
          'По выбору систем разработки и способов выемки полезного ископаемого, начиная со стадии проектирования',
          'По параметрам систем разработки, обеспечивающим безопасность работ и сохранность подрабатываемых территорий и объектов',
          'По особенностям ведения работ в горно-геологических условиях конкретного месторождения и горнотехнических условиях шахты',
          'По способу управления горным давлением в очистном пространстве, включая паспорт крепления и проект организации работ',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63674026',
        text:
          'Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?',
        answers: [
          'Не ранее чем через 30 минут',
          'Не ранее чем через 90 минут',
          'Не ранее чем через 60 минут',
          'Не ранее чем через 10 минут',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63674027',
        text:
          'В какие сроки должны проводиться ревизия, наладка и испытание эксплуатируемых подъемных установок (кроме грузовых лебедок, предназначенных для спуска-подъема оборудования и материалов)?',
        answers: ['1 раз в год', '1 раз в 2 года', '1 раз в 5 лет'],
        correctAnswers: ['1 раз в год'],
      },
      {
        code: '63674028',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63674029',
        text:
          'На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?',
        answers: ['На 1 год', 'На 3 года', 'На 5 лет', 'На 2 года'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63674030',
        text:
          'Какое из перечисленных требований не допускается при комбинированной разработке месторождений?',
        answers: [
          'Располагать фронт ведения горных работ в направлении навстречу фронту развития подземных очистных работ при открытых работах',
          'Располагать фронт ведения горных работ в направлении от массива к карьеру при подземных очистных работах',
          'Добыча руды подземным способом из проектных контуров карьера',
        ],
        correctAnswers: [
          'Добыча руды подземным способом из проектных контуров карьера',
        ],
      },
      {
        code: '63674031',
        text:
          'Разрешается ли применение системы разработки с обрушением руды и вмещающих пород при наличии в налегающих породах плывунов, неосушенных песков, карстов и т. д.?',
        answers: [
          'Разрешается по паспорту   буровзрывных работ',
          'Разрешается при согласовании с органами надзора',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63674032',
        text:
          'Кто является ответственным руководителем работ по спасению людей и ликвидации аварий до момента прибытия главного инженера шахты?',
        answers: [
          'Горный диспетчер',
          'Командир горноспасательного формирования',
          'Начальник участка аэрологической безопасности',
          'Дежурный работник горноспасательного формирования',
        ],
        correctAnswers: ['Горный диспетчер'],
      },
      {
        code: '63674033',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе локального плана проведения работ',
          'На основе технического регламента',
          'На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России',
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63674034',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?',
        answers: [
          'Выявление недостоверных сведений в представленных документах',
          'Отсутствие геологического и маркшейдерского обеспечения горных работ',
          'Отсутствие обоснования соблюдения условий безопасного недропользования',
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
        correctAnswers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63674035',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?',
        answers: [
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
          'Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок',
          'Начальник участка, на котором произошла авария',
          'Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
        ],
        correctAnswers: [
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
        ],
      },
      {
        code: '63674036',
        text:
          'Кто утверждает схемы откаточных путей, маршруты движения самоходного (нерельсового) оборудования по каждому горизонту?',
        answers: [
          'Руководитель шахты',
          'Технический руководитель шахты',
          'Главный технолог',
          'Начальник участка',
        ],
        correctAnswers: ['Технический руководитель шахты'],
      },
      {
        code: '63674037',
        text:
          'Какое максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?',
        answers: ['0,2 МПа', '0,1 МПа', '0,3 МПа', '0,4 МПа'],
        correctAnswers: ['0,2 МПа'],
      },
      {
        code: '63674038',
        text:
          'В каком случае нарушены требования к системам вентиляции при (совмещенной) разработке месторождения?',
        answers: [
          'Способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ',
          'При комбинированной (совмещенной) разработке месторождения и наличии аэродинамических связей подземных выработок с карьерным пространством предусматривается нагнетательный способ общерудничной системы проветривания',
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
          'Вспомогательные нагнетательные вентиляторы должны быть максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках',
        ],
        correctAnswers: [
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
        ],
      },
      {
        code: '63674039',
        text:
          'Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе на открытых горных выработках?',
        answers: [
          'Из пяти человек',
          'Из двух человек',
          'Из трех человек',
          'Из шести человек',
        ],
        correctAnswers: ['Из трех человек'],
      },
      {
        code: '63674040',
        text:
          'Какая разрешенная скорость при перевозке людей в специальных пассажирских вагонетках по горизонтальным выработкам?',
        answers: [
          'Не превышающая 15 км/ч',
          'Не превышающая 20 км/ч',
          'Не превышающая 10 км/ч',
        ],
        correctAnswers: ['Не превышающая 20 км/ч'],
      },
      {
        code: '63674041',
        text:
          'Кем должна осуществляться проверка состояния предусмотренных планом ликвидации аварии материальных и технических средств для осуществления мероприятий по спасению людей и ликвидации аварий перед согласованием планов ликвидации аварий на новое полугодие? Выберите два правильных варианта ответа.',
        answers: [
          'Работниками шахты',
          'Военизированной горноспасательной частью (ВГСЧ)',
          'Инспектором территориального органа Ростехнадзора',
          'Руководителем аварийно-спасательного формирования',
        ],
        correctAnswers: [
          'Работниками шахты',
          'Военизированной горноспасательной частью (ВГСЧ)',
        ],
      },
      {
        code: '63674042',
        text:
          'Когда рабочие, выполняющие работы повышенной опасности, перечень которых устанавливается руководителем организации, должны проходить обязательный медицинский осмотр (освидетельствование) на состояние алкогольного и наркотического опьянения согласно "Правилам безопасности при ведении горных работ и переработке твердых полезных ископаемых"? Выберите два правильных варианта ответа.',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в полгода',
          'Обследование проводится только при поступлении на работу',
          'В начале рабочего дня (смены)',
          'В конце рабочего дня (смены) на основании решения руководителя объекта',
        ],
        correctAnswers: [
          'В начале рабочего дня (смены)',
          'В конце рабочего дня (смены) на основании решения руководителя объекта',
        ],
      },
      {
        code: '63674043',
        text:
          'В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?',
        answers: [
          'Расположенных на любом удалении от плоскости сместителя тектонического нарушения',
          'Расположенных на удалении 5-10 м от плоскости сместителя тектонического нарушения',
          'Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород',
        ],
        correctAnswers: [
          'Расположенных на удалении 5-10 м от плоскости сместителя тектонического нарушения',
        ],
      },
      {
        code: '63674044',
        text:
          'На какой срок составляется план горных работ по всем планируемым видам работ?',
        answers: ['На 1 год', 'На 5 лет', 'На 3 года'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63674045',
        text:
          'В каких случаях должен производиться контроль удароопасности в выработках?',
        answers: [
          'При изменениях горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'При изменениях горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'Во всех перечисленных случаях',
          'При проявлении внешних признаков удароопасности',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63674046',
        text:
          'В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?',
        answers: [
          'Только, если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          'Только, если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях',
          'Только, если имели место микроудары и горные удары',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63674047',
        text:
          'Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?',
        answers: [
          'Специализированной организацией, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Техническим руководителем объекта',
          'Руководителем организации',
          'Комиссией, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
        correctAnswers: ['Техническим руководителем объекта'],
      },
      {
        code: '63674048',
        text:
          'На каком расстоянии от ствола на поверхности может быть установлена вентиляторная установка для проветривания при проходке ствола?',
        answers: ['Не менее 10 м', 'Не менее 15 м', 'Не менее 5 м'],
        correctAnswers: ['Не менее 15 м'],
      },
      {
        code: '63674049',
        text:
          'С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'С Ростехнадзором и Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и аварийно-спасательным формированием',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63674050',
        text:
          'На какой высоте должны быть расположены токоведущие части электроаппаратуры центральных насосных камер и камер центральных подземных подстанций от головки рельсов околоствольного двора?',
        answers: ['Не более 0,5 м', 'Не более 0,2 м', 'Не менее 1 м'],
        correctAnswers: ['Не менее 1 м'],
      },
      {
        code: '63674051',
        text: 'Кем прорабатывается план ликвидации аварий под расписку?',
        answers: [
          'Специализированной сторонней организацией',
          'Главным инженером шахты с командным составом ВГСЧ',
          'Главным инженером шахты с должностными лицами (техническим надзором) шахты',
          'Главным инженером шахты с инспектором Ростехнадзора',
        ],
        correctAnswers: [
          'Главным инженером шахты с должностными лицами (техническим надзором) шахты',
        ],
      },
      {
        code: '63674052',
        text:
          'До какой минимальной температуры разрешается ведение горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'До температуры минус 20 °C',
          'До температуры минус 15 °C',
          'До температуры минус 10 °C',
          'До температуры минус 8 °C',
        ],
        correctAnswers: ['До температуры минус 20 °C'],
      },
      {
        code: '63674053',
        text:
          'В каких случаях в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (рассола, пульпы), работы по поиску и спасению людей не проводятся или прекращаются?',
        answers: [
          'Только в случае если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее отсутствует запасной выход',
          'Только в случае если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке',
          'Только в случае если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более',
          'Только в случае если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного десятикратному сроку времени защитного действия СИЗОД',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63674054',
        text:
          'Какова периодичность проверки действия реверсивных устройств с пропуском опрокинутой воздушной струи в нерабочее время?',
        answers: [
          '1 раз в месяц',
          '1 раз в 3 месяца',
          '1 раз в 6 месяцев',
          '1 раз в год',
        ],
        correctAnswers: ['1 раз в 6 месяцев'],
      },
      {
        code: '63674055',
        text:
          'В каком случае нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?',
        answers: [
          'Горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) должны производиться только после спуска воды из затопленных выработок',
          'Допускается частичная отработка барьерных целиков без предварительного отвода воды по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией',
          'Вода из карьера в подземные выработки должна поступать самотеком через дренажные скважины с обязательным применением забивных или сквозных фильтров',
          'Допускается  использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера',
        ],
        correctAnswers: [
          'Допускается  использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера',
        ],
      },
      {
        code: '63674056',
        text:
          'Какова максимально допустимая продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды (ВКГ) установлена для их пребывания в непригодной для дыхания рудничной атмосфере с применением дыхательных аппаратов?',
        answers: ['4 часа', '3 часа', '2 часа', '5 часов'],
        correctAnswers: ['4 часа'],
      },
      {
        code: '63674057',
        text:
          'Какое требование установлено к коробкам выводов электрических машин и пускорегулирующей аппаратуры "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть защищены ограждениями',
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть оборудованы звуковой и световой сигнализацией',
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой',
        ],
        correctAnswers: [
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой',
        ],
      },
      {
        code: '63674058',
        text: 'Каким образом необходимо составлять вентиляционные планы?',
        answers: [
          '1 раз в год в 2 экземплярах и пополнять ежемесячно',
          '1 раз в месяц в 3 экземплярах',
          '1 раз в полгода в 3 экземплярах и дополнять ежемесячно',
        ],
        correctAnswers: [
          '1 раз в полгода в 3 экземплярах и дополнять ежемесячно',
        ],
      },
      {
        code: '63674059',
        text:
          'Чье решение является обязательным для выполнения в случае разногласия между командиром ВГСЧ и ответственным руководителем работ при организации работ по ликвидации аварии?',
        answers: [
          'Ответственного руководителя работ по ликвидации аварий',
          'Командира ВГСЧ',
          'Экспертной комиссии',
          'Вышестоящего руководства организации',
        ],
        correctAnswers: [
          'Ответственного руководителя работ по ликвидации аварий',
        ],
      },
      {
        code: '63674060',
        text:
          'Каким образом доводятся до специалистов и горнорабочих дополнительные меры безопасности перед производством массового взрыва?',
        answers: [
          'Приказом под подпись',
          'Начальником отдела промышленной безопасности в устной форме',
          'Командиром профессиональной аварийно-спасательной службы (формирования) под подпись в журнале инструктажа',
          'Руководителем карьера под подпись в журнале взрывных работ',
        ],
        correctAnswers: ['Приказом под подпись'],
      },
      {
        code: '63674061',
        text:
          'В каком случае нарушены требования безопасности при выводе людей из аварийных участков выработки?',
        answers: [
          'При определении путей движения людей, выходящих из аварийных участков по загазованным выработкам, следует учитывать только состояние и протяженность этих выработок и время выхода по ним людей',
          'Из горных выработок, расположенных до очага пожара, людей следует выводить навстречу свежей струе к выходу на поверхность',
          'Из горных выработок, расположенных за очагом пожара, людей следует выводить в изолирующих самоспасателях кратчайшим путем в выработки со свежей струей воздуха и далее на поверхность',
          'Места "до очага пожара" и "за очагом пожара" определяются по ходу движения вентиляционной струи при режиме вентиляции, предусмотренном для данной позиции планом ликвидации аварий',
        ],
        correctAnswers: [
          'При определении путей движения людей, выходящих из аварийных участков по загазованным выработкам, следует учитывать только состояние и протяженность этих выработок и время выхода по ним людей',
        ],
      },
      {
        code: '63674062',
        text:
          'Каким образом участки горных выработок категории "Опасно" приводят в неудароопасное состояние?',
        answers: [
          'Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м',
          'Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м',
          'Путем создания в краевой части массива пород защитной зоны шириной n+1 м, но не более 2 м',
          'Путем создания в краевой части массива пород защитной зоны шириной не более 1 м',
        ],
        correctAnswers: [
          'Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м',
        ],
      },
      {
        code: '63674063',
        text:
          'В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?',
        answers: [
          'Общие положения',
          'Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков',
          'Введение',
          'Методы прогноза удароопасности участков массива горных пород и руд',
        ],
        correctAnswers: ['Общие положения'],
      },
      {
        code: '63674064',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами',
          'Наименования организации, объекта недропользования (участка недр)',
          'Дата составления',
          'Все ответы неверны',
          'Название графического документа',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63674065',
        text:
          'Кто определяет места возведения изоляционных перемычек, предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?',
        answers: [
          'Руководитель работ по ликвидации аварии',
          'Руководитель горноспасательных работ',
          'Технический руководитель (главный инженер) ОПО',
          'Лицо, ответственное за пожарную безопасность ОПО',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63674066',
        text:
          'Каким должно быть расстояние между стенками стволов при строительстве шахт на месторождениях, склонных и опасных по горным ударам?',
        answers: [
          'Не менее 50 м',
          'Не менее 45 м',
          'Не менее 40 м',
          'Не менее 35 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63674067',
        text:
          'Каким образом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?',
        answers: [
          'Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно',
          'Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора',
          'Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'На основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы',
        ],
        correctAnswers: [
          'Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно',
        ],
      },
      {
        code: '63674068',
        text:
          'Что из перечисленного не входит в мероприятия по разгазированию выработок?',
        answers: [
          'Снятие напряжения с электрооборудования и электрических сетей и устранение других источников воспламенения в выработках, по которым будет двигаться исходящая струя',
          'Выставление постов (на свежей струе) и запрещающих знаков в местах возможного подхода людей к выработкам, по которым двигается исходящая струя при разгазировании',
          'Выбор способа и порядка разгазирования',
          'Контроль за разгазированием выработки и за свежей струей воздуха, концентрация газов в месте слияния исходящей и свежей струй воздуха не должна превышать: горючих газов - 1 %, ядовитых газов - ПДК',
          'Обследование выработок после разгазирования перед допуском в них людей',
          'Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей',
        ],
        correctAnswers: [
          'Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей',
        ],
      },
      {
        code: '63674069',
        text:
          'Что из перечисленного должно предусматриваться при прорыве в горные выработки воды, пульпы, реагентов?',
        answers: [
          'Вывод людей только из аварийного участка',
          'Вывод всех людей из шахты на поверхность',
          'Вывод людей только из аварийного и угрожаемого участков',
        ],
        correctAnswers: ['Вывод всех людей из шахты на поверхность'],
      },
      {
        code: '63674070',
        text:
          'Какой должна быть скорость движения вагона при работе самоходного вагона в комплексе с передвижными или стационарными бункер-перегружателями в момент подъезда к местам перегрузки?',
        answers: [
          'Не должна превышать 5 км/ч',
          'Не должна превышать 8 км/ч',
          'Не должна превышать 10 км/ч',
        ],
        correctAnswers: ['Не должна превышать 5 км/ч'],
      },
      {
        code: '63674071',
        text:
          'По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?',
        answers: [
          'Только по твердым полезным ископаемым',
          'Только по углеводородному сырью',
          'Только по общераспространенным полезным ископаемым',
          'Только по промышленным подземным водам',
          'По всем перечисленным',
        ],
        correctAnswers: ['По всем перечисленным'],
      },
      {
        code: '63674072',
        text:
          'Какой порядок одновременного ведения очистных работ на смежных этажах предусмотрен "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на безопасное расстояние, определенное проектом',
          'При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 3 м',
          'При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом',
        ],
        correctAnswers: [
          'При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом',
        ],
      },
      {
        code: '63674073',
        text:
          'Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указаны неверно?',
        answers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
          'Оперативные планы разрабатываются до окончания ведения горноспасательных работ',
          'Последующие оперативные планы (№ 2, 3 и т. д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
          'Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов',
        ],
        correctAnswers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
        ],
      },
      {
        code: '63674074',
        text:
          'Кто утверждает специальный план, в соответствии с которым производится вскрытие пожарного участка?',
        answers: [
          'Руководитель территориального органа Ростехнадзора',
          'Руководитель организации',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63674075',
        text:
          'Что запрещается использовать при производстве огневых работ в подземных выработках и стволах (шурфах) шахт?',
        answers: [
          'Только газовую сварку с использованием пропан бутана',
          'Газовую сварку с использованием ацетилена, пропан бутана и других горючих углеводородов',
          'Только газовую сварку с использованием ацетилена',
        ],
        correctAnswers: [
          'Газовую сварку с использованием ацетилена, пропан бутана и других горючих углеводородов',
        ],
      },
      {
        code: '63674076',
        text:
          'Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?',
        answers: [
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
          'Технический руководитель эксплуатирующей объект ведения горных работ организации',
        ],
        correctAnswers: [
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
      },
      {
        code: '63674077',
        text:
          'Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?',
        answers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
          'Техническими условиями, разработанными специализированной организацией',
          'Локальным прогнозом удароопасности',
          'Указаниями научно-исследовательского института',
        ],
        correctAnswers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
        ],
      },
      {
        code: '63674078',
        text: 'Какой способ вентиляции должны иметь шахты?',
        answers: [
          'Искусственную вентиляцию',
          'Только за счет естественной тяги',
          'Только нагнетательную вентиляцию',
        ],
        correctAnswers: ['Искусственную вентиляцию'],
      },
      {
        code: '63674079',
        text:
          'На содержание каких веществ должны быть исследованы пробы воздуха, набираемые в непроветриваемой части затопленных вертикальных и наклонных выработок (до начала откачки воды)?',
        answers: [
          'Только CO, СО2',
          'Только СН4, H2S',
          'Только О2, СО2',
          'CO, СО2, СН4, H2S, О2 и Н2',
        ],
        correctAnswers: ['CO, СО2, СН4, H2S, О2 и Н2'],
      },
      {
        code: '63674080',
        text: 'Где должны быть установлены местные заземлители?',
        answers: [
          'Только в распределительной или трансформаторной подстанции',
          'Только у стационарного или передвижного распределительного пункта',
          'Только у индивидуально установленного выключателя или распределительного устройства',
          'Только у отдельно установленных машин, за исключением случаев заземления на шину главного заземлителя',
          'Только у кабельной муфты, заводская конструкция которой предусматривает ее заземление',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63674081',
        text:
          'В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?',
        answers: [
          'Если данные отсупления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам',
          'В соответствии с требованиями Правил безопасности при ведении горных работ и переработке твердых полезных ископаемых',
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: [
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63674082',
        text:
          'Кто разрабатывает указания по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам?',
        answers: [
          'Технический руководитель организации',
          'Комиссия, состоящая из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
          'Представитель территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63674083',
        text: 'Что запрещено при комбинированной разработке месторождений?',
        answers: [
          'Применение нагнетательного способа проветривания подземных выработок',
          'Ограничение мощности массовых взрывов в карьере и подземных выработках',
          'Применение систем разработки, исключающих сдвижение (разрушение) массива предохранительного целика',
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
        ],
        correctAnswers: [
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
        ],
      },
      {
        code: '63674084',
        text:
          'В какой период, установленный Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых, осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?',
        answers: [
          'С 1 октября по 31 декабря года, предшествующего планируемому',
          'С 1 октября по 15 декабря года, предшествующего планируемому',
          'С 1 сентября по 15 декабря года, предшествующего планируемому',
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
        correctAnswers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
      },
      {
        code: '63674085',
        text:
          'Что из перечисленного входит в перечень совместных мероприятий по обеспечению безопасности при одновременной разработке месторождения подземным и открытым способами?',
        answers: [
          'Только согласование планов и графиков ведения горных и взрывных работ',
          'Только применение нагнетательной схемы проветривания подземных объектов',
          'Только проверка представителями профессионального аварийно-спасательного формирования (службы) состояния атмосферы в подземных объектах после массовых взрывов на объектах открытых горных работ',
          'Только предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ',
          'Только наличие у лиц, входящих в состав сменного надзора, средств контроля за содержанием в атмосфере ядовитых продуктов взрыва',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63674086',
        text:
          'Кем утверждается схема расстановки знаков и указателей для обозначения свободного прохода для людей и проезжей части в откаточных выработках?',
        answers: [
          'Техническим руководителем шахты',
          'Начальником участка',
          'Руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем шахты'],
      },
      {
        code: '63674087',
        text:
          'В течение какого времени после окончания сварочных и газопламенных работ место сварки и резки должно находиться под наблюдением лица технического надзора или специально выделенного и проинструктированного лица?',
        answers: [
          'Не менее двух часов',
          'Не менее одного часа',
          'Не менее полутора часов',
          'Не менее получаса',
        ],
        correctAnswers: ['Не менее двух часов'],
      },
      {
        code: '63674088',
        text:
          'На какие из перечисленных видов производственных процессов рекомендуется разрабатывать регламенты технологических процессов при ведении горных работ подземным способом?',
        answers: [
          'Только на проходку шахтных стволов и подземных горных выработок',
          'Только на ведение очистных работ, закладочных работ',
          'Только на эксплуатацию технологического транспорта',
          'Только на защиту шахты от затопления и охрану объектов на дневной поверхности от вредного влияния горных работ, противопожарную защиту шахты',
          'На все перечисленные виды производственных процессов',
        ],
        correctAnswers: [
          'На все перечисленные виды производственных процессов',
        ],
      },
      {
        code: '63674089',
        text:
          'Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?',
        answers: [
          'Геомеханическими методами',
          'Геофизическими методами',
          'Визуальными наблюдениями за разрушением приконтурного массива выработок',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63674090',
        text:
          'Какой вид лицензии необходимо иметь для права подготовки планов и схем развития горных работ?',
        answers: [
          'Лицензию на добычу полезного ископаемого',
          'Лицензию на геологическое изучение недр',
          'Лицензию на производство маркшейдерских работ',
          'Лицензию на детальное изучение (разведку) месторождений полезных ископаемых',
        ],
        correctAnswers: ['Лицензию на производство маркшейдерских работ'],
      },
      {
        code: '63674091',
        text:
          'В течение какого времени при вводе новых или закрытии отработанных участков, изменении схемы вентиляции или путей выхода людей из шахты в план мероприятий по локализации и ликвидации последствий аварий должен внести изменения и согласовать их с командиром аварийно-спасательного формирования технический руководитель организации?',
        answers: [
          'В течение суток',
          'В течение трех дней',
          'В течение пяти дней',
          'В течение недели',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63674092',
        text:
          'Кто осуществляет руководство работами по локализации и ликвидации последствий аварии?',
        answers: [
          'Руководитель ОПО',
          'Должностное лицо Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Технический руководитель (главный инженер) ОПО',
          'Должностное лицо профессионального аварийно-спасательного формирования, обслуживающего ОПО, назначенное распорядительным документом руководителя профессионального аварийно-спасательного формирования',
        ],
        correctAnswers: ['Технический руководитель (главный инженер) ОПО'],
      },
      {
        code: '63674093',
        text:
          'На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: [
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Изменение параметров и периодичности не осуществляется ни в каком случае',
          'На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'На основе заключения представителя территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63674094',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63674095',
        text:
          'Кем составляется акт проверки исправности противопожарных средств и оборудования плана ликвидации аварий?',
        answers: [
          'Комиссией в составе начальника шахты, начальника ПВС и инспектора Ростехнадзора',
          'Комиссией в составе главного инженера шахты, начальника ПВС и командира ВГСЧ, обслуживающей данную шахту',
          'Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту',
        ],
        correctAnswers: [
          'Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту',
        ],
      },
      {
        code: '63674096',
        text:
          'Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?',
        answers: [
          'Крутопадающих мощных рудных тел',
          'Пологих рудных тел',
          'Наклонных рудных тел',
          'Горизонтальных рудных тел',
        ],
        correctAnswers: ['Крутопадающих мощных рудных тел'],
      },
      {
        code: '63674097',
        text:
          'В каком направлении должны ориентироваться основные горизонтальные и наклонные выработки околоствольных дворов, включая камеры?',
        answers: [
          'В направлении действия минимального горизонтального напряжения нетронутого массива',
          'В направлении действия максимального горизонтального напряжения нетронутого массива',
          'В направлении действия максимального вертикального напряжения нетронутого массива',
          'В направлении действия минимального вертикального напряжения нетронутого массива',
        ],
        correctAnswers: [
          'В направлении действия максимального горизонтального напряжения нетронутого массива',
        ],
      },
      {
        code: '63674098',
        text:
          'Какую температуру должен иметь воздух, поступающий в подземные горные выработки?',
        answers: ['Не менее 1 °С', 'Не менее 2 °С', 'Не менее 0 °С'],
        correctAnswers: ['Не менее 2 °С'],
      },
      {
        code: '63674099',
        text:
          'Куда должен сообщать технический руководитель организации о каждом случае горного удара и микроудара?',
        answers: [
          'В территориальный орган Ростехнадзора',
          'В службу технического надзора',
          'В диспетчерскую службу',
          'Во все перечисленные службы',
        ],
        correctAnswers: ['В территориальный орган Ростехнадзора'],
      },
      {
        code: '63674100',
        text:
          'Какой должна быть навивка каната на барабан согласно "Правилам безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Соответствующей назначению подъемной установки в соответствии с паспортом и руководством по эксплуатации завода-изготовителя',
          'Двухслойной',
          'Многослойной',
          'Трехслойной',
        ],
        correctAnswers: [
          'Соответствующей назначению подъемной установки в соответствии с паспортом и руководством по эксплуатации завода-изготовителя',
        ],
      },
      {
        code: '63674101',
        text:
          'Кем подписывается оперативная часть плана ликвидации аварий в конце последней позиции с указанием даты составления плана?',
        answers: [
          'Руководителем организации (предприятия)',
          'Главным инженером шахты',
          'Командиром ВГСЧ',
          'Начальником ПВС',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63674102',
        text:
          'С каким интервалом в выработках, в которых подвешен контактный провод, должны устанавливаться светящиеся надписи или информационные знаки "Берегись контактного провода"?',
        answers: [
          'На пересечениях с другими выработками и закруглениями и через каждые 200 м',
          'Только на пересечениях с другими выработками',
          'Через каждые 100 м',
        ],
        correctAnswers: [
          'На пересечениях с другими выработками и закруглениями и через каждые 200 м',
        ],
      },
      {
        code: '63674103',
        text:
          'На основании каких данных составляется оперативный план по локализации и ликвидации последствий аварий?',
        answers: [
          'Только поступающей на КП информации',
          'Только анализа аварийной обстановки',
          'Только прогноза развития аварии',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63674104',
        text: 'Какой вентиляционный режим должен выбираться при авариях?',
        answers: [
          'Нормальный',
          'Реверсивный',
          'Исключающий проникновение продуктов горения в шахту',
          'С учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках',
        ],
        correctAnswers: [
          'С учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках',
        ],
      },
      {
        code: '63674105',
        text:
          'Какое из перечисленных требований к рудоспускам указано неверно?',
        answers: [
          'При отработке верхней части рудоспусков они должны быть заполнены до подошвы уступа',
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
          'Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала',
          'При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее двух',
        ],
        correctAnswers: [
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
        ],
      },
      {
        code: '63674106',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте',
          'В случае назначения нового руководителя организации',
          'Не позднее 15 дней после внесения изменений в применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте методики (методы) измерений или типы средств измерений',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63674107',
        text:
          'На какое расстояние от забоев подготовительных выработок должны отстоять концы участковых пожарно-оросительных трубопроводов?',
        answers: [
          'Не более чем на 40 м',
          'Не более чем на 70 м',
          'Не более чем на 90 м',
        ],
        correctAnswers: ['Не более чем на 40 м'],
      },
      {
        code: '63674108',
        text: 'По какому документу осуществляется выемка целиков?',
        answers: [
          'По паспорту буровзрывных работ',
          'По паспорту крепления и управления кровлей',
          'По проекту',
        ],
        correctAnswers: ['По проекту'],
      },
      {
        code: '63674109',
        text:
          'В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?',
        answers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
          'Взрыв, пожар, внезапный выброс, горный удар, обрушение',
          'Взрыв, горный удар, обрушение, внезапный выброс, пожар',
          'Взрыв, горный удар, внезапный выброс, пожар, обрушение',
        ],
        correctAnswers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
        ],
      },
      {
        code: '63674110',
        text:
          'Сколько выходов должно быть в камерах подстанций длиной более 10 м?',
        answers: [
          '1 выход',
          '2 выхода, расположенные в наиболее удаленных друг от друга частях камеры',
          '2 выхода, независимо от расположения',
        ],
        correctAnswers: [
          '2 выхода, расположенные в наиболее удаленных друг от друга частях камеры',
        ],
      },
      {
        code: '63674111',
        text:
          'При какой глубине вертикальных стволов механический подъем в одном из них может отсутствовать, при условии наличия лестниц в обоих стволах?',
        answers: [
          'При глубине до 70 м',
          'При глубине до 80 м',
          'При глубине до 90 м',
          'При глубине до 100 м',
        ],
        correctAnswers: ['При глубине до 70 м'],
      },
      {
        code: '63674112',
        text:
          'При каком расстоянии до места работы для перевозки людей должны применяться специально оборудованные транспортные средства?',
        answers: [
          'При расстоянии до места работ более 300 м',
          'При расстоянии до места работ более 1 км',
          'При расстоянии до места работ более 500 м',
        ],
        correctAnswers: ['При расстоянии до места работ более 1 км'],
      },
      {
        code: '63674113',
        text:
          'Какой практический стаж подземных работ должен иметь горный инженер для назначения на должность начальника службы прогноза и предотвращения горных ударов?',
        answers: [
          'Не менее одного года',
          'Не менее трех лет',
          'Не менее двух лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее трех лет'],
      },
      {
        code: '63674114',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Органом государственного горного надзора',
          'Руководителем организации - пользователя недр',
          'Органом исполнительной власти субъекта Российской Федерации',
          'Техническим руководителем организации - пользователя недр',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63674115',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63674116',
        text:
          'На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?',
        answers: [
          'На 3 группы, в зависимости от степени риска проводимых работ',
          'На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве',
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
        correctAnswers: [
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
      },
      {
        code: '63674117',
        text:
          'В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В течение 15 дней с назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В назначенный день рассмотрения планов и (или) схем развития горных работ',
          'За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
      },
      {
        code: '63674118',
        text:
          'Что из перечисленного необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на месторождениях, склонных и опасных по горным ударам?',
        answers: [
          'Только сокращение применения систем разработки с открытым очистным пространством',
          'Только снижение количества горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления',
          'Только использование опережающей отработки защитных залежей (слоев), в том числе бурение разгрузочных скважин большого диаметра, расположение выработок в защищенных зонах',
          'Только разделение месторождения на шахтные поля и порядок их отработки, обеспечивающие планомерное извлечение запасов по возможности без образования целиков, острых углов и выступов фронта очистных работ',
          'Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород',
          'Только ограничение встречных и догоняющих фронтов очистных работ',
        ],
        correctAnswers: [
          'Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород',
        ],
      },
      {
        code: '63674119',
        text:
          'Где должны находиться лица, на которых возлагается ответственность за руководство ликвидацией аварий в шахте в данную смену?',
        answers: [
          'На поверхности в командном пункте',
          'Непосредственно в шахте',
          'На ближайшем к шахтному стволу участке подземных работ',
          'Не регламентируется',
        ],
        correctAnswers: ['На поверхности в командном пункте'],
      },
      {
        code: '63674120',
        text:
          'Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным горизонтальным выработкам высотой 1,8 - 2,0 м?',
        answers: ['60 м/мин', '45 м/мин', '30 м/мин', '70 м/мин'],
        correctAnswers: ['60 м/мин'],
      },
      {
        code: '63674121',
        text:
          'Какие приложения должны приобщаться к плану мероприятий по локализации и ликвидации последствий аварий при его согласовании и утверждении?',
        answers: [
          'Только акт о расчетном и фактическом времени выхода людей в изолирующих самоспасателях на свежую струю из наиболее отдаленных горных выработок шахты',
          'Только акт о состоянии запасных выходов из очистных забоев, участков и шахты и о пригодности их для выхода людей и прохода горноспасателей в респираторах',
          'Только акт проверки состояния насосных станций, водонепроницаемых перемычек и других средств по предотвращению затопления горных выработок',
          'Только акт проверки исправности действия реверсивных устройств на главных вентиляционных установках с пропуском реверсированной воздушной струи по горным выработкам',
          'Все перечисленные, включая  акт проверки наличия и исправности противопожарных средств и оборудования',
        ],
        correctAnswers: [
          'Все перечисленные, включая  акт проверки наличия и исправности противопожарных средств и оборудования',
        ],
      },
      {
        code: '63674122',
        text:
          'В каком из перечисленных случаев допускается продолжать работы по поиску и спасению людей в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (рассола, пульпы)?',
        answers: [
          'В случае если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного двукратному сроку времени защитного действия средств индивидуальной защиты органов дыхания',
          'В случае, если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более',
          'В случае, если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее отсутствует запасной выход',
          'В случае если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке',
        ],
        correctAnswers: [
          'В случае если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного двукратному сроку времени защитного действия средств индивидуальной защиты органов дыхания',
        ],
      },
      {
        code: '63674123',
        text:
          'Какова периодичность испытания подъемных канатов людских и грузолюдских подъемных установок?',
        answers: [
          'Через каждые полгода, считая со дня последнего испытания',
          'Через 12 месяцев, считая со дня навески, а затем через каждые шесть месяцев',
          'Через каждые 6 месяцев, считая со дня их навески',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Через каждые 6 месяцев, считая со дня их навески'],
      },
      {
        code: '63674124',
        text:
          'Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным наклонным выработкам с углом наклона 10° при подъеме?',
        answers: ['45 м/мин', '30 м/мин', '60 м/мин', '25 м/мин'],
        correctAnswers: ['45 м/мин'],
      },
      {
        code: '63674125',
        text:
          'Кто ежесуточно должен осматривать крепь и армировку вертикальных и наклонных стволов шахт, служащих для подъема, спуска людей и грузов?',
        answers: [
          'Механик подъема',
          'Специально назначенные лица',
          'Лица технического надзора',
        ],
        correctAnswers: ['Специально назначенные лица'],
      },
      {
        code: '63674126',
        text:
          'С какой периодичностью должна проводиться проверка состояния металлических и железобетонных копров?',
        answers: [
          '1 раз в год',
          '1 раз в 3 года',
          '2 раза в 5 лет',
          '1 раз в 2 года',
        ],
        correctAnswers: ['1 раз в год'],
      },
      {
        code: '63674127',
        text:
          'Чему должно быть равно минимальное расстояние между ближайшими боками параллельных выработок и камер различного назначения на удароопасных участках до глубины 1000 м относительно наибольшего размера d поперечного сечения большей выработки?',
        answers: ['3dS', '4dS', '2dS'],
        correctAnswers: ['4dS'],
      },
      {
        code: '63674128',
        text:
          'Может ли "газовый режим" распространяться на отдельные участки, блоки, пласты или на шахту в целом?',
        answers: [
          'Только на шахту в целом',
          'Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)',
          'Только на горные выработки всего месторождения',
        ],
        correctAnswers: [
          'Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)',
        ],
      },
      {
        code: '63674129',
        text:
          'Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?',
        answers: [
          'Заключение договоров необязательно',
          'Заключение договоров обязательно',
          'Заключение договоров не требуется',
        ],
        correctAnswers: ['Заключение договоров обязательно'],
      },
      {
        code: '63674130',
        text:
          'Какие из перечисленных документов должны прилагаться к плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только распределение обязанностей между отдельными лицами, участвующими в ликвидации аварий, и порядок их действий',
          'Обязанности дежурного по шахте и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии, и распределение обязанностей между ними',
          'Только список лиц, участвующих в ликвидации аварий, и аксонометрическая схема проветривания горных выработок',
          'Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии',
        ],
        correctAnswers: [
          'Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии',
        ],
      },
      {
        code: '63674131',
        text:
          'Что из перечисленного наносится на аксонометрических схемах и погоризонтных планах вентиляционных горных выработок в виде кружков диаметром 13 мм?',
        answers: [
          'Позиции, соответствующие оперативной части плана ликвидации аварий',
          'Места размещения самоспасателей, огнетушителей',
          'Места размещения телефонов',
          'Главные вентиляционные установки',
        ],
        correctAnswers: [
          'Позиции, соответствующие оперативной части плана ликвидации аварий',
        ],
      },
      {
        code: '63674132',
        text:
          'Что из перечисленного следует отражать в регламентах технологических процессов для безопасного выполнения работ для каждого производственного процесса в организациях (на предприятиях)?',
        answers: [
          'Мероприятия по обеспечению пожарной безопасности',
          'Мероприятия по обеспечению прогнозирования возникновения и развития аварий, инцидентов, случаев травматизма',
          'Мероприятия по обеспечению локализации и ликвидации аварий и их последствий',
          'Специфику и особенности организации выполнения основных и вспомогательных производственных процессов на каждой шахте или подземном руднике',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63674133',
        text:
          'В каком из перечисленных случаев может разрабатываться регламент технологических процессов для безопасного выполнения работ?',
        answers: [
          'Проектными организациями в качестве неотъемлемой составной части проекта на строительство, эксплуатацию, реконструкцию и модернизацию, ликвидацию и консервацию шахты',
          'Во всех перечисленных случаях',
          'Эксплуатирующими организациями при отсутствии в ранее разработанной проектной документации регламентов путем привлечения научно-исследовательских организаций',
          'Проектными организациями при возобновлении горных работ после вывода объектов горных работ из консервации',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63674134',
        text:
          'При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?',
        answers: [
          'При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при нахождении людей на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека',
          'При отсутствии информации о содержании в ней горючих газов',
          'При отсутствии в выработке пострадавших',
          'При тушении пожара в непроветриваемых тупиковых горных выработках',
        ],
        correctAnswers: [
          'При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при нахождении людей на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека',
        ],
      },
      {
        code: '63674135',
        text:
          'Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды (ВГК) для ведения горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе в подземных горных выработках?',
        answers: ['5 человек', '3 человека', '2 человека', '6 человек'],
        correctAnswers: ['5 человек'],
      },
      {
        code: '63674136',
        text:
          'Где должны располагаться установки автоматического пожаротушения для камер с постоянным дежурством обслуживающего персонала?',
        answers: [
          'Снаружи камеры со стороны поступления свежей струи воздуха не дальше 10 м от входа в камеру',
          'У рабочего места дежурного персонала',
          'Внутри камеры на расстоянии 5 м от рабочего места обслуживающего персонала',
        ],
        correctAnswers: ['У рабочего места дежурного персонала'],
      },
      {
        code: '63674137',
        text:
          'В течение какого срока технический руководитель объекта с инженерными службами рассматривает случаи микроударов, толчков, стреляния, интенсивного заколообразования и шелушения при первом их появлении в целях установления причин и разработки мер борьбы с ними?',
        answers: [
          'В течение суток',
          'В течение 5 рабочих дней',
          'В течение месяца',
          'В течение 10 рабочих дней',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63674138',
        text:
          'В каком случае нарушены требования безопасности при проходке стволов?',
        answers: [
          'При проходке стволов допускается отставание постоянной крепи от забоя на расстояние не более одного диаметра ствола вчерне',
          'Пересечение выработок должно осуществляться под прямым или близким к нему углом. Очередность проведения выработок регламентируется Указаниями',
          'Образующийся при сбойке целик должен быть приведен при категории «Опасно» в неопасное состояние по всей площади',
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями',
        ],
        correctAnswers: [
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями',
        ],
      },
      {
        code: '63674139',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Комиссией пользователя недр (подрядной организации)',
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
          'Техническим руководителем пользователя недр (подрядной организации)',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63674140',
        text:
          'В течение какого срока должны пополняться материалы, израсходованные со складов на ликвидацию аварий?',
        answers: [
          'В течение суток',
          'В течение недели',
          'В течение десяти дней',
          'В течение месяца',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63674141',
        text:
          'Какие из перечисленных данных должны указываться в оперативной части плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только категории шахты (месторождения) по удароопасности',
          'Только категории шахты (месторождения) по выбросоопасности',
          'Только категория региона по природной и техногенной сейсмоопасности (по шкале Рихтера)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63674142',
        text:
          'Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристику объектов, в отношении которых разрабатывается план мероприятий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63674143',
        text:
          'Кем утверждается план ликвидации аварий при ведении работ в подземных условиях?',
        answers: [
          'Техническим руководителем эксплуатирующей организации (главным инженером организации)',
          'Руководителем эксплуатирующей организации',
          'Командиром горноспасательного формирования',
          'Начальником шахты',
        ],
        correctAnswers: [
          'Техническим руководителем эксплуатирующей организации (главным инженером организации)',
        ],
      },
      {
        code: '63674144',
        text:
          'Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников ПАСС(Ф) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          'По решению технического руководителя (главного инженера) ОПО',
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: '63674145',
        text:
          'В каком из перечисленных случаев подземная горноспасательная база не организуется?',
        answers: [
          'В случае если горноспасательные работы выполняются для ликвидации последствий прорыва воды (рассола) или обводненной горной массы при выполнении водолазных спусков и водолазных работ',
          'В случае если горноспасательные работы проводятся в горных выработках с непригодной для дыхания рудничной атмосферой',
          'В случае если все прибывшие на опасный производственный объект горноспасательные отделения привлечены к горноспасательным работам, определенным планом локализации и ликвидации аварии, связанным со спасением людей, застигнутых аварией',
        ],
        correctAnswers: [
          'В случае если все прибывшие на опасный производственный объект горноспасательные отделения привлечены к горноспасательным работам, определенным планом локализации и ликвидации аварии, связанным со спасением людей, застигнутых аварией',
        ],
      },
      {
        code: '63674146',
        text:
          'Кем утверждается структура и количественный состав службы прогноза и предотвращения горных ударов?',
        answers: [
          'Экспертной организацией',
          'Комиссией по горным ударам',
          'Руководителем организации',
          'Техническим руководителем организации',
        ],
        correctAnswers: ['Комиссией по горным ударам'],
      },
      {
        code: '63674147',
        text:
          'Какое из перечисленных требований при тушении пожаров в вертикальных горных выработках указано верно?',
        answers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
          'Пожары в вертикальных горных выработках тушатся активным способом снизу вверх',
          'Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи',
          'При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей воздуха или в надшахтном здании этого ствола возможно изменение направления вентиляционной струи',
        ],
        correctAnswers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
        ],
      },
      {
        code: '63674148',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
          'Прошедшие обучение приемам и методам проведения работ',
          'Не моложе 18 лет',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63674149',
        text:
          'На какое расстояние при одновременной отработке нескольких подэтажей системой подэтажного обрушения каждый верхний подэтаж должен опережать нижний?',
        answers: [
          'На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа',
          'Не менее чем на расстояние, равное высоте двух этажей',
          'Расстояние определяется проектом',
        ],
        correctAnswers: [
          'На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа',
        ],
      },
      {
        code: '63674150',
        text:
          'Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?',
        answers: [
          'Обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения',
          'Параметры карьерного и шахтного полей с запасами руды, намечаемые к отработке открытым и подземным способами',
          'Функциональные обязанности и ответственность инженерно-технических служб рудника',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63674151',
        text:
          'Какой должна быть высота свободного прохода людей в горизонтальных горных выработках, где применяются рельсовые транспортные средства?',
        answers: ['Не менее 1,8 м', 'Не менее 2,0 м', 'Не менее 1,9 м'],
        correctAnswers: ['Не менее 2,0 м'],
      },
      {
        code: '63674152',
        text:
          'С какой периодичностью трансформаторное масло, применяемое в трансформаторах, установленных в подземных выработках, должно проверяться на электрическую прочность?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 9 месяцев',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в полтора года',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63674153',
        text:
          'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Выберите два правильных варианта ответа.',
        answers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи',
          'В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
        ],
      },
      {
        code: '63674154',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К I группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63674155',
        text:
          'Какой категории по степени опасности участков горного массива вокруг выработок не существует?',
        answers: ['Опасно', 'Неопасно', 'Чрезвычайно опасно'],
        correctAnswers: ['Чрезвычайно опасно'],
      },
      {
        code: '63674156',
        text: 'Какими способами осуществляется тушение подземных пожаров?',
        answers: [
          'Только активным тушением',
          'Только изоляцией горных выработок, в которых действует пожар',
          'Только комбинированным способом',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63674157',
        text:
          'Каково минимально допустимое число запасных выходов на шахте, обеспечивающих выезд (выход) людей на поверхность и имеющих разное направление вентиляционных струй?',
        answers: ['2 выхода', '3 выхода', '4 выхода', 'Не нормируется'],
        correctAnswers: ['2 выхода'],
      },
      {
        code: '63674158',
        text:
          'Как машинист должен воспринимать каждый неправильно поданный или непонятный сигнал?',
        answers: [
          'Как сигнал "Вверх"',
          'Как сигнал "Вниз"',
          'Как сигнал "Стоп"',
          'Как сигнал "Назад"',
        ],
        correctAnswers: ['Как сигнал "Стоп"'],
      },
      {
        code: '63674159',
        text:
          'Каким способом производится ликвидация зависаний, образовавшихся сводов в отбитой руде?',
        answers: [
          'Ликвидация зависаний, образовавшихся сводов в отбитой руде (в очистном пространстве) должна производиться любыми методами',
          'Ликвидация зависаний, образовавшихся сводов в отбитой руде (в очистном пространстве) должна производиться под надзором лиц, имеющих право руководства горными работами',
          'Взрыванием зарядов с применением детонирующего шнура, подаваемых на шестах, или другими безопасными способами',
        ],
        correctAnswers: [
          'Взрыванием зарядов с применением детонирующего шнура, подаваемых на шестах, или другими безопасными способами',
        ],
      },
      {
        code: '63674160',
        text:
          'На кого возлагается руководство работами по локализации и ликвидации последствий аварий?',
        answers: [
          'На технического руководителя (главного инженера) опасного производственного объекта',
          'На начальника аварийно-спасательной службы',
          'На начальника участка',
        ],
        correctAnswers: [
          'На технического руководителя (главного инженера) опасного производственного объекта',
        ],
      },
      {
        code: '63674161',
        text: 'Чем оснащается КП (командный пункт)?',
        answers: [
          'Только аппаратурой телефонной и (или) радиосвязи с местами ведения работ по локализации и ликвидации последствий аварии',
          'Только электронно-вычислительными машинами',
          'Только множительной и копировальной техникой',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63674162',
        text:
          'Какая длина должна быть у замерных станций, оборудованных на местах замера количества воздуха?',
        answers: ['Не менее 2 м', 'Не менее 4 м', 'Не менее 1 м'],
        correctAnswers: ['Не менее 4 м'],
      },
      {
        code: '63674163',
        text:
          'На какие виды могут подразделяться прогнозы удароопасности участков массива горных пород? Выберите два правильных варианта ответа.',
        answers: [
          'Федеральный прогноз',
          'Региональный прогноз',
          'Местный прогноз',
          'Локальный прогноз',
        ],
        correctAnswers: ['Региональный прогноз', 'Локальный прогноз'],
      },
      {
        code: '63674164',
        text:
          'Где должны находиться выписки из плана мероприятий по локализации и ликвидации последствий аварий, относящиеся к конкретному участку, указывающие пути выхода людей из шахты?',
        answers: [
          'У заместителя технического руководителя шахты',
          'У горного диспетчера',
          'У руководителя шахты',
          'У начальника соответствующего участка',
        ],
        correctAnswers: ['У начальника соответствующего участка'],
      },
      {
        code: '63674165',
        text:
          'Каким должно быть содержание углекислого газа в рудничном воздухе на рабочих местах?',
        answers: ['Не более 0,75 %', 'Не менее 1 %', 'Не более 0,5 %'],
        correctAnswers: ['Не более 0,5 %'],
      },
      {
        code: '63674166',
        text: 'У кого не должен храниться экземпляр вентиляционного плана?',
        answers: [
          'В профессиональном аварийно-спасательном формировании (аварийно-спасательной службе)',
          'У начальника пылевентиляционной службы шахты',
          'У технического руководителя шахты в комплекте плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах',
          'У механика (энергетика) шахты',
        ],
        correctAnswers: ['У механика (энергетика) шахты'],
      },
      {
        code: '63674167',
        text:
          'В какой цвет окрашивается весь шахтный пожарно-оросительный трубопровод?',
        answers: [
          'В опознавательный желтый цвет',
          'В опознавательный оранжевый цвет',
          'В опознавательный красный цвет',
        ],
        correctAnswers: ['В опознавательный красный цвет'],
      },
      {
        code: '63674168',
        text:
          'На кого возлагается персональная ответственность за наличие и качество материалов, находящихся в противопожарных подземных и поверхностных складах в соответствии с планом ликвидации аварий при ведении работ в подземных условиях?',
        answers: [
          'На начальника шахты',
          'На технического руководителя эксплуатирующей организации',
          'На командира горноспасательного формирования',
          'На специально назначенное лицо технического надзора',
        ],
        correctAnswers: ['На начальника шахты'],
      },
      {
        code: '63674169',
        text:
          'При каком угле наклона наклонной горной выработки пожар в ней тушится активным способом дистанционно?',
        answers: [
          'При угле наклона более 20°',
          'При угле наклона более 15°',
          'При угле наклона более 10°',
          'При любомугле наклона наклонной горной выработки',
        ],
        correctAnswers: ['При угле наклона более 20°'],
      },
      {
        code: '63674170',
        text:
          'В каком случае шахтный канат должен быть снят и заменен другим при проведении повторного испытания?',
        answers: [
          'Если суммарная площадь проволок, не выдержавших испытания на разрыв и перегиб, достигает 25% общей площади поперечного сечения всех проволок каната',
          'Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 3%',
          'Если запас их прочности ниже 3,5-кратного',
        ],
        correctAnswers: [
          'Если суммарная площадь проволок, не выдержавших испытания на разрыв и перегиб, достигает 25% общей площади поперечного сечения всех проволок каната',
        ],
      },
      {
        code: '63674171',
        text:
          'Кто принимает решения об окончании, приостановлении и возобновлении работ по локализации и ликвидации последствий аварий или горноспасательных работ?',
        answers: [
          'Руководитель горноспасательных работ',
          'Должностное лицо профессионального аварийно-спасательного формирования, обслуживающего опасный производственный объект',
          'Руководитель профессионального аварийно-спасательного формирования',
          'Руководитель работ по ликвидации аварии',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63674172',
        text:
          'В каком из перечисленных случаев разрабатываются последующие оперативные планы по локализации и ликвидации последствий аварий (№ 2, 3 и т. д.)?',
        answers: [
          'По окончании ведения горноспасательных работ, если после выполнения всех мероприятий, предусмотренных предыдущим оперативным планом ликвидации аварии, требуется внести корректировку',
          'Когда мероприятия предыдущего оперативного плана не реализованы в полной мере и есть предпосылка возникновения аварии в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта)',
          'Когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
        ],
        correctAnswers: [
          'Когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
        ],
      },
      {
        code: '63674173',
        text:
          'В каких случаях из перечисленных должен устанавливаться "газовый режим" в шахтах?',
        answers: [
          'Только при наличии соответствующих геологических данных - про­ектом разработки месторождения перед началом строительства',
          'Только при обнаружении горючих, ядовитых газов, при установлении опасности взрыва пыли в процессе ведения горных работ или эксплуатации объекта - немедленно техническим руководителем организации с изданием совместного приказа с территориальным органом федерального органа исполнительной власти, уполномоченного в области промышленной безопасности',
          'В любом из перечисленных случаев - в зависимости от конкретных условий. Может быть распространен на отдельные рабочие зоны (участки, блоки, панели, пласты, горизонты) или на шахту в целом',
        ],
        correctAnswers: [
          'В любом из перечисленных случаев - в зависимости от конкретных условий. Может быть распространен на отдельные рабочие зоны (участки, блоки, панели, пласты, горизонты) или на шахту в целом',
        ],
      },
      {
        code: '63674174',
        text:
          'Что предусматривается в плане ликвидации аварий при ведении работ в подземных условиях?',
        answers: [
          'Только мероприятия по спасению людей, пути вывода людей, застигнутых аварией, из зоны опасного воздействия, связанной с аварией',
          'Только мероприятия по ликвидации аварий и предупреждению их развития',
          'Только действия ВГСЧ и вспомогательных горноспасательных команд (ВГК) в начальной стадии возникновения аварий',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63674175',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63674176',
        text:
          'Кем должен тщательно рассматриваться маршрут движения отделений ВГСЧ на вывод людей и ликвидацию аварий в каждом конкретном случае? Выберите два правильных варианта ответа.',
        answers: [
          'Главным инженером шахты',
          'Руководителем подразделения противопожарной службы',
          'Командиром ВГСЧ',
          'Представителем Ростехнадзора',
          'Представителем МЧС России',
        ],
        correctAnswers: ['Главным инженером шахты', 'Командиром ВГСЧ'],
      },
      {
        code: '63674177',
        text:
          'Каков максимально допустимый срок остановки производства горных работ, при котором эта остановка не требует безопасной организации возобновления горных работ?',
        answers: ['1 сутки', '2 суток', '3 суток', '4 суток'],
        correctAnswers: ['3 суток'],
      },
      {
        code: '63674178',
        text: 'Разрешаются ли спуск и подъем людей одновременно с грузом?',
        answers: [
          'Разрешается при двухклетевом подъеме',
          'Запрещается при одноклетевом подъеме',
          'Запрещается как в одной клети при одноклетевом подъеме, так и в разных клетях при двухклетевом подъеме',
        ],
        correctAnswers: [
          'Запрещается как в одной клети при одноклетевом подъеме, так и в разных клетях при двухклетевом подъеме',
        ],
      },
      {
        code: '63674179',
        text:
          'На какие перечисленные виды работ не составляются планы и схемы горных работ?',
        answers: [
          'На вскрышные работы',
          'На работы по добыче полезных ископаемых',
          'На работы, связанные со вторичной переработкой минерального сырья',
          'На маркшейдерские работы',
        ],
        correctAnswers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
        ],
      },
      {
        code: '63674180',
        text: 'Какое количество главных заземлителей должно быть на шахте?',
        answers: [
          'Не менее двух главных заземлителей (основного и резервного)',
          'Только один главный заземлитель',
          'Количество главных заземлителей определяется техническим руководителем шахты',
        ],
        correctAnswers: [
          'Не менее двух главных заземлителей (основного и резервного)',
        ],
      },
      {
        code: '63674181',
        text:
          'С какой периодичностью должна проверяться исправность сигнализации оповещения об аварии с регистрацией результатов проверок?',
        answers: [
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в 2 недели',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
        ],
        correctAnswers: ['Не реже 1 раза в неделю'],
      },
      {
        code: '63674182',
        text:
          'На кого возлагается ответственность за состояние пожарной безопасности шахт?',
        answers: [
          'На руководителя службы промышленной безопасности',
          'На специалиста по пожарной безопасности',
          'На руководителя шахты',
        ],
        correctAnswers: ['На руководителя шахты'],
      },
      {
        code: '63674183',
        text: 'В каком случае разрешается производство огневых работ?',
        answers: [
          'Если в выработках, в которые могут попасть продукты горения, образующиеся при огневых работах, находятся люди',
          'Если огневые работы проводятся в подземных выработках негазовых шахт и в надшахтных зданиях',
          'Если огневые работы проводятся в вертикальных и наклонных выработках с деревянной крепью, имеющих выход на поверхность',
        ],
        correctAnswers: [
          'Если огневые работы проводятся в подземных выработках негазовых шахт и в надшахтных зданиях',
        ],
      },
      {
        code: '63674184',
        text:
          'Через какой минимальный промежуток времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?',
        answers: ['Через час', 'Через 2 часа', 'Через 3 часа', 'Через 4 часа'],
        correctAnswers: ['Через 2 часа'],
      },
      {
        code: '63674185',
        text:
          'Какие из перечисленных задач выполняет служба прогноза и предотвращения горных ударов? Выберите два правильных варианта ответа.',
        answers: [
          'Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов',
          'Безотлагательное отнесение месторождения к удароопасным в случае неожиданных проявлений горных ударов или установления категории "Опасно"',
          'Решение вопросов ежегодного подтверждения отнесения месторождения к склонным или опасным по горным ударам',
          'Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ',
        ],
        correctAnswers: [
          'Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов',
          'Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ',
        ],
      },
      {
        code: '63674186',
        text:
          'Какое напряжение должно применяться для питания ручных электрических машин и инструментов в помещениях без повышенной опасности?',
        answers: [
          'Не выше 42 В',
          'Не выше 127 В',
          'Не выше 36 В',
          'Не выше 220 В',
        ],
        correctAnswers: ['Не выше 220 В'],
      },
      {
        code: '63674187',
        text:
          'Какова минимально допустимая ширина лестниц в вертикальных выработках?',
        answers: ['0,6 м', '0,4 м', '0,7 м', '1 м'],
        correctAnswers: ['0,4 м'],
      },
      {
        code: '63674188',
        text:
          'На каком расстоянии от устья штольни шахты должны располагаться склады для хранения противопожарных материалов?',
        answers: [
          'Не ближе 150 м',
          'Не далее 100 м',
          'Не далее 300 м',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Не далее 100 м'],
      },
      {
        code: '63674189',
        text:
          'Сколько самостоятельных вентиляторных агрегатов должно входить в главную вентиляторную установку?',
        answers: [
          'Один самостоятельный вентиляторный агрегат, обеспечивающий проектную мощность',
          'Два самостоятельных вентиляторных агрегата, и дополнительно должен быть установлен резервный',
          'Два самостоятельных вентиляторных агрегата, причем один из них должен быть резервный',
        ],
        correctAnswers: [
          'Два самостоятельных вентиляторных агрегата, причем один из них должен быть резервный',
        ],
      },
      {
        code: '63674190',
        text:
          'В каком из перечисленных случаев в подземных горных выработках приостанавливаются горноспасательные работы и организуется вывод из зоны аварии людей, задействованных в данных работах?',
        answers: [
          'При пожаре в местах ведения взрывных работ, в складах взрывчатых материалов и других местах их хранения, на транспортных средствах, перевозящих взрывчатые материалы после его ликвидации первичными средствами пожаротушения',
          'В случае если рудничная атмосфера на аварийном участке, в котором действует пожар, находится во взрывоопасном состоянии',
          'В случае если концентрация водорода в зарядных камерах составляет 0,05 %',
          'В случае если в зоне с температурой воздуха не более 25 °C при наличии непригодной для дыхания атмосферы температура воздуха в течение получаса повысилась на 1 °C и более',
        ],
        correctAnswers: [
          'В случае если рудничная атмосфера на аварийном участке, в котором действует пожар, находится во взрывоопасном состоянии',
        ],
      },
      {
        code: '63674191',
        text: 'Как производится посадка кровли?',
        answers: [
          'Посадка кровли производится под непосредственным руководством лица технического надзора в соответствии с проектом организации работ',
          'Посадка кровли производится в соответствии с проектом организации работ',
          'Посадка кровли производится под непосредственным руководством специалиста по производственному контролю',
        ],
        correctAnswers: [
          'Посадка кровли производится под непосредственным руководством лица технического надзора в соответствии с проектом организации работ',
        ],
      },
      {
        code: '63674192',
        text:
          'Где должны быть размещены пожарные краны пожарно-оросительного трубопровода в наклонных выработках, не имеющих пересечений и ответвлений?',
        answers: [
          'Через каждые 200 м',
          'Через каждые 50 м',
          'Через каждые 100 м',
        ],
        correctAnswers: ['Через каждые 100 м'],
      },
      {
        code: '63674193',
        text:
          'Куда запрещается устраивать выходы из восстающих выработок, оборудованных лестницами?',
        answers: [
          'Непосредственно на конвейерный штрек',
          'Непосредственно на околоствольный двор',
          'Непосредственно на откаточные выработки',
        ],
        correctAnswers: ['Непосредственно на откаточные выработки'],
      },
      {
        code: '63674194',
        text:
          'Как часто механиком (энергетиком) и начальником пылевентиляционной службы шахты должна проверяться исправность действия реверсивных устройств вентиляторной установки без опрокидывания струи?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 2 месяца',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63674195',
        text: 'Какая документация должна находиться на командном пункте?',
        answers: [
          'Только оперативные журналы ОПО и ПАСС(Ф)',
          'Только план ликвидации аварий (ПЛА)',
          'Только оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63674196',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 2 дневные смены срок',
          'Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63674197',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63674198',
        text:
          'Какое из перечисленных первоочередных мероприятий плана ликвидации аварий рекомендуется располагать первым в части аварий, связанных с загазованностью выработок?',
        answers: [
          'Установление аварийного режима работы главных вентиляционных установок',
          'Расстановка постов безопасности',
          'Режим электроснабжения шахты',
          'Вызов ВГСЧ',
        ],
        correctAnswers: [
          'Установление аварийного режима работы главных вентиляционных установок',
        ],
      },
      {
        code: '63674199',
        text: 'Какая защита должна осуществляться при напряжении до 1140 В?',
        answers: [
          'Только защита трансформаторов и каждого отходящего от них присоединения от токов короткого замыкания - автоматическими выключателями с максимальной токовой защитой',
          'Только защита электродвигателей и питающих их кабелей от токов короткого замыкания - мгновенная или селективная в пределах до 0,2 с, а от токов перегрузки или от перегрева - нулевая',
          'Только защита от включения напряжения при сниженном сопротивлении изоляции относительно земли',
          'Только защита электрической сети от опасных токов утечки на землю - автоматическими выключателями в комплексе с одним аппаратом защиты на всю электрически связанную сеть (подключенную к одному или группе параллельно работающих трансформаторов)',
          'Все перечисленные виды защиты',
        ],
        correctAnswers: ['Все перечисленные виды защиты'],
      },
      {
        code: '63674200',
        text:
          'Какие требования установлены Инструкцией по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы, к лицам, допускаемым к руководству работами по локализации и ликвидации последствий аварии и горноспасательными работами?',
        answers: [
          'Возраст не менее 21 года, отсутствие медицинских противопоказаний для данного вида работ, наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "прикладная геодезия", прохождение стажировки сроком не менее 3 месяцев',
          'Наличие высшего образования по направлению подготовки (специальности) "техносферная безопасность"',
          'Наличие стажа работы не менее 1 года в организации, эксплуатирующей опасные производственные объекты, на которых ведутся горные работы',
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
        correctAnswers: [
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
      },
      {
        code: '63674201',
        text:
          'Кем утверждается акт о проведении опытно-промышленных испытаний?',
        answers: [
          'Техническим руководителем организации',
          'Лицом, ответственным за проведение ОПИ',
          'Руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63674202',
        text:
          'Какие участки относятся к наиболее нагруженным на месторождениях, склонных к горным ударам?',
        answers: [
          'Участки рудного массива и массива горных пород в зоне опорного давления от очистных работ',
          'Сопряжения выработок и передовые выработки',
          'Участки рудного массива и массива горных пород с дизъюнктивными и пликативными геологическими нарушениями',
          'Опасные зоны, выявленные в результате регионального прогноза',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63674203',
        text:
          'Кто осуществляет научно-методическое сопровождение деятельности cлужбы прогноза и предотвращения горных ударов?',
        answers: [
          'Комиссия по горным ударам',
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
          'Территориальный орган Ростехнадзора',
          'Технический руководитель организации',
        ],
        correctAnswers: [
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63674204',
        text:
          'Как должно выполняться заземление тягового трансформатора, используемого для питания контактных сетей электрифицированных технологических железных дорог?',
        answers: [
          'За пределами контура защитного заземления подстанции',
          'В пределах контура защитного заземления подстанции',
          'Выполнение заземления тягового трансформатора "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" не регламентируется',
        ],
        correctAnswers: [
          'За пределами контура защитного заземления подстанции',
        ],
      },
      {
        code: '63674205',
        text:
          'В каких подземных горных выработках запрещается тушение пожара водой?',
        answers: [
          'Угольных рудников',
          'Асбестовых рудников',
          'Соляных и калийных рудников',
        ],
        correctAnswers: ['Соляных и калийных рудников'],
      },
      {
        code: '63674206',
        text:
          'Какие надписи должен иметь титульный лист плана ликвидации аварии?',
        answers: [
          'Надпись главного инженера организации (предприятия) с датой утверждения',
          'Подпись главного инженера шахты',
          'Надпись командира ВГСЧ с указанием даты',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63674207',
        text:
          'На каких электроустановках запрещается оперативное обслуживание без применения специальных защитных средств?',
        answers: [
          'На электроустановках напряжением выше 220 В',
          'На электроустановках напряжением выше 380 В',
          'На электроустановках напряжением менее 1000 В',
          'На электроустановках напряжением выше 1000 В',
        ],
        correctAnswers: ['На электроустановках напряжением выше 1000 В'],
      },
      {
        code: '63674208',
        text:
          'Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?',
        answers: [
          'Мониторинг концентрации индикаторных пожарных газов',
          'Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ',
          'Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест',
          'Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии',
        ],
        correctAnswers: ['Мониторинг концентрации индикаторных пожарных газов'],
      },
      {
        code: '63674209',
        text:
          'Что из перечисленного допускается не предусматривать в оперативной части соответствующего плана для ликвидации подземных пожаров в начальной стадии?',
        answers: [
          'Использование имеющегося на шахте оборудования для ликвидации возможных аварий',
          'Места установок временных перемычек',
          'Использование насосов и ставов труб',
          'Порядок и способы использования водоотливных, воздухопроводных и противопожарных (противопожарно-оросительных) трубопроводов',
        ],
        correctAnswers: ['Использование насосов и ставов труб'],
      },
      {
        code: '63674210',
        text:
          'В каких из перечисленных случаев руководитель работ по ликвидации аварии и руководитель горноспасательных работ должны разработать оперативный план по локализации и ликвидации последствий аварий? Выберите два правильных варианта ответа.',
        answers: [
          'В случае, если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ',
          'В первоначальный момент возникновения аварии в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта)',
          'В случае, если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария',
          'По окончании ведения горноспасательных работ, если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется их корректировка',
        ],
        correctAnswers: [
          'В случае, если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ',
          'В случае, если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария',
        ],
      },
      {
        code: '63674211',
        text:
          'Какой должна быть ширина свободного прохода для людей в выработках с конвейерным транспортом?',
        answers: ['Не менее 0,5 м', 'Не менее 0,6 м', 'Не менее 0,7 м'],
        correctAnswers: ['Не менее 0,7 м'],
      },
      {
        code: '63674212',
        text:
          'При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?',
        answers: [
          'Крепление выработок, предотвращающее обрушение пород кровли и боков',
          'Создание устойчивых форм',
          'Бурение разгрузочных щелей и скважин',
          'Контурное взрывание',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63674213',
        text:
          'Каким образом должны проветриваться камеры служебного назначения?',
        answers: [
          'За счет диффузии',
          'Комбинированным способом (за счет диффузии и подачи воздуха с исходящей струи)',
          'Обособленной струей свежего воздуха с выбросом отработанного воздуха непосредственно на исходящую струю участка (шахты)',
        ],
        correctAnswers: [
          'Обособленной струей свежего воздуха с выбросом отработанного воздуха непосредственно на исходящую струю участка (шахты)',
        ],
      },
      {
        code: '63674214',
        text:
          'Каким из перечисленных прав не обладает комиссия по горным ударам?',
        answers: [
          'Штрафовать ответственных лиц, в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
          'Получать от руководителей объектов необходимую документацию по описанию мер по борьбе с горными ударами',
          'Останавливать горные работы, если нарушены требования, касающиеся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
          'Приглашать на заседания комиссии представителей организаций, работающих по проблеме борьбы с горными ударами',
        ],
        correctAnswers: [
          'Штрафовать ответственных лиц, в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
        ],
      },
      {
        code: '63674215',
        text:
          'Какой должна быть высота свободного прохода на штреках (ортах) скреперования?',
        answers: [
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте, который определен проектом',
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 1,8 м',
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки',
        ],
        correctAnswers: [
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки',
        ],
      },
      {
        code: '63674216',
        text:
          'Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных предприятий с комбинированной (совмещенной) разработкой?',
        answers: [
          'Только подработанность массивов горных пород, слагающих борта карьера',
          'Только наличие подземных пустот в границах и под дном карьера',
          'Только исключение прорывов в подземные горные выработки паводковых и ливневых вод',
          'Все перечисленные факторы',
        ],
        correctAnswers: ['Все перечисленные факторы'],
      },
      {
        code: '63674217',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
          'Руководитель профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
        ],
      },
      {
        code: '63674218',
        text:
          'Что из перечисленного должно находиться у места производства сварочных работ?',
        answers: [
          'Не менее 2 огнетушителей, пожарный ствол с рукавом или со шлангом, присоединенным к противопожарной магистрали, или емкость с запасом воды не менее 1 м3 и ящик с песком',
          'Один огнетушитель и лопата',
          'Ящик с песком и противопожарное полотно',
        ],
        correctAnswers: [
          'Не менее 2 огнетушителей, пожарный ствол с рукавом или со шлангом, присоединенным к противопожарной магистрали, или емкость с запасом воды не менее 1 м3 и ящик с песком',
        ],
      },
      {
        code: '63674219',
        text:
          'При каких видах аварии необходимо предусматривать вызов служб МЧС России?',
        answers: [
          'При пожарах в надшахтных зданиях и сооружениях',
          'При всех видах аварий, когда требуется оказание помощи людям, и для ведения работ, требующих соответствующего горноспасательного оборудования',
          'При пожарах в стволах, шурфах и других выработках, имеющих выход на поверхность',
          'При землетрясениях',
        ],
        correctAnswers: ['При землетрясениях'],
      },
      {
        code: '63674220',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае, если это регламентировано внутренней документацией организации',
          'В случае, если объекты зарегистрированы в государственном реестре опасных производственных объектов',
          'План мероприятий разрабатывается на каждый опасный объект отдельно',
        ],
        correctAnswers: [
          'В случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63674221',
        text: 'В какие сроки проводится воздушно-депрессионная съемка?',
        answers: [
          'На всех шахтах - не реже 1 раза в 3 года, а на трудно проветриваемых шахтах - не реже 1 раза в год',
          'На всех шахтах - 1 раз в 5 лет',
          'На всех шахтах - при вводе новых горизонтов',
        ],
        correctAnswers: [
          'На всех шахтах - не реже 1 раза в 3 года, а на трудно проветриваемых шахтах - не реже 1 раза в год',
        ],
      },
    ],
    63673: [
      {
        code: '63673000',
        text:
          'Какое из перечисленных действий должен выполнять руководитель работ по ликвидации аварии?',
        answers: [
          'Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания рудничной атмосфере',
          'Организацию ведения оперативного журнала профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Организацию ведения горноспасательных работ',
          'Установление режима работы и отдыха работников профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований при ведении горноспасательных работ',
        ],
        correctAnswers: [
          'Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания рудничной атмосфере',
        ],
      },
      {
        code: '63673001',
        text:
          'На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?',
        answers: ['На 1 год', 'На 2 года', 'На 3 года', 'На 5 лет'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63673002',
        text:
          'На какой срок составляется план развития горных работ по всем планируемым видам работ?',
        answers: ['На 1 год', 'На 3 года', 'На 5 лет'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63673003',
        text:
          'Каким должен быть уклон съездов в грузовом направлении при применении колесных скреперов с тракторной тягой?',
        answers: [
          'Не более 15º',
          'Не более 20º',
          'Не более 25º',
          'Не более 5º',
        ],
        correctAnswers: ['Не более 15º'],
      },
      {
        code: '63673004',
        text:
          'Кем устанавливаются порядок закрепления и количество тормозных средств подвижного состава?',
        answers: [
          'Начальником транспортного цеха',
          'Дежурным по станции',
          'Техническим руководителем объекта',
          'Руководителем работ',
        ],
        correctAnswers: ['Начальником транспортного цеха'],
      },
      {
        code: '63673005',
        text: 'Что определяется планами и схемами развития горных работ?',
        answers: [
          'Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи и первичной переработки полезных ископаемых',
          'Направления развития горных работ, условия, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи полезных ископаемых',
          'Технические решения при консервации объектов ведения горных работ',
        ],
        correctAnswers: [
          'Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи и первичной переработки полезных ископаемых',
        ],
      },
      {
        code: '63673006',
        text:
          'Какими должны быть проходы между ленточными конвейерами, передвигающимися по рельсам, и стенами здания или другим оборудованием?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 0,75 м',
          'Не менее 0,8 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 1,0 м'],
      },
      {
        code: '63673007',
        text:
          'Требуется ли маркшейдерское обеспечение буровзрывных работ при разработке месторождений полезных ископаемых в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Не требуется',
          'Требуется только при проведении массовых взрывов',
          'Требуется только при составлении проекта на массовый взрыв',
          'Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями',
        ],
        correctAnswers: [
          'Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями',
        ],
      },
      {
        code: '63673008',
        text:
          'В соответствии с каким документом осуществляется производство открытых горных работ в зонах ранее выполненных подземных работ и имеющих пустоты?',
        answers: [
          'В соответствии со специальным проектом, согласованным с генеральным проектировщиком',
          'В соответствии с положением о производственном контроле',
          'В соответствии с планом организации строительства',
          'В соответствии с геологической документацией',
        ],
        correctAnswers: [
          'В соответствии со специальным проектом, согласованным с генеральным проектировщиком',
        ],
      },
      {
        code: '63673009',
        text:
          'Какое минимальное расстояние должно быть между концом отвальной консоли транспортно-отвального моста и гребнем отвала?',
        answers: ['3 м', '2 м', '4 м', '1 м'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63673010',
        text:
          'Какой должна быть высота уступа при работе камнерезных машин с механизированной уборкой камня?',
        answers: [
          'Не более 3 м',
          'Не более 6 м',
          'Не более 2,35 м',
          'Не более 4 м',
        ],
        correctAnswers: ['Не более 3 м'],
      },
      {
        code: '63673011',
        text:
          'С какой периодичностью должно производиться измерение сопротивления общего заземляющего устройства передвижных электроустановок?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год, а также после ремонта',
          'Периодичность всех осмотров устанавливается ответственным за электрохозяйство организации',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63673012',
        text:
          'При проектировании, строительстве и эксплуатации горнодобывающих предприятий с комбинированной (совмещенной) разработкой месторождения должен учитываться ряд основных факторов, определяющих специфические условия и представляющих опасность при ведении горных работ. Относится ли к основным факторам вид полезного ископаемого разрабатываемого месторождения?',
        answers: [
          'Относится',
          'Не относится, если отсутствует склонность месторождения или массива горных пород или их части к горным ударам',
          'Относится, если имеется вероятность возникновения пожаров при совмещенной разработке месторождений полезных ископаемых склонных к самовозгоранию',
          'Не относится',
        ],
        correctAnswers: ['Не относится'],
      },
      {
        code: '63673013',
        text:
          'В каком из перечисленных случаев нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?',
        answers: [
          'Горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) должны производиться только после спуска воды из затопленных выработок',
          'Допускается частичная отработка барьерных целиков без предварительного отвода воды - по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией',
          'Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьер',
          'Вода из карьера в подземные выработки должна поступать самотеком через дренажные скважины с обязательным применением забивных или сквозных фильтров',
        ],
        correctAnswers: [
          'Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьер',
        ],
      },
      {
        code: '63673014',
        text:
          'Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?',
        answers: [
          'Заключение договоров обязательно',
          'Заключение договоров необязательно',
          'Заключение договоров необязательно только по согласованию с территориальным органом Ростехнадзора',
          'Заключение договоров обязательно, если рядом с производственным объектом расположены населенные пункты с численностью населения более 10 тыс. человек',
        ],
        correctAnswers: ['Заключение договоров обязательно'],
      },
      {
        code: '63673015',
        text:
          'Организации, эксплуатирующие объекты, на которых ведутся горные работы и переработка полезных ископаемых, обязаны осуществлять:',
        answers: [
          'Геолого-маркшейдерское обеспечение работ',
          'Маркшейдерское обеспечение работ',
          'Геологическое обеспечение работ',
        ],
        correctAnswers: ['Маркшейдерское обеспечение работ'],
      },
      {
        code: '63673016',
        text:
          'По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?',
        answers: [
          'Только по промышленным подземным водам',
          'Только по твердым полезным ископаемым',
          'Только по углеводородному сырью',
          'Только общераспространенным полезным ископаемым',
          'По всем перечисленным видам полезных ископаемых',
        ],
        correctAnswers: ['По всем перечисленным видам полезных ископаемых'],
      },
      {
        code: '63673017',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Не позднее 15 дней после внесения изменений в применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте методики (методы) измерений или типы средств измерений',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'В случае назначения нового руководителя организации',
          'Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
      },
      {
        code: '63673018',
        text:
          'Какая документация составляется перед производством горных работ и утверждается техническим руководителем объекта?',
        answers: [
          'Положение о производственном контроле и геологический отчет',
          'Проект и локальные проекты производства работ (паспорта)',
          'Геологическая документация',
        ],
        correctAnswers: [
          'Проект и локальные проекты производства работ (паспорта)',
        ],
      },
      {
        code: '63673019',
        text:
          'При наличии какого уклона дороги должны устраиваться площадки с уклоном до 20 промилле длиной не менее 50 м и не реже чем через каждые 600 м длины уклона?',
        answers: [
          'Более 60 промилле',
          'Более 40 промилле',
          'Более 30 промилле',
          'Более 50 промилле',
        ],
        correctAnswers: ['Более 60 промилле'],
      },
      {
        code: '63673020',
        text:
          'Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?',
        answers: [
          'Не ранее чем через 10 минут',
          'Не ранее чем через 1 час',
          'Не ранее чем через 30 минут',
          'Не ранее чем через 90 минут',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63673021',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Все перечисленное',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в установленной степени готовности',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63673022',
        text:
          'Чем обуславливаются основные факторы, представляющие опасность при комбинированной (совмещенной) разработке месторождений?',
        answers: [
          'Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки',
          'Степенью взаимного влияния',
          'Технологической взаимностью открытого способа разработки',
        ],
        correctAnswers: [
          'Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки',
        ],
      },
      {
        code: '63673023',
        text:
          'Как должна производиться погрузка горной массы в автомобили экскаватором?',
        answers: [
          'Погрузка в кузов должна производиться сбоку или сзади, перенос экскаваторного ковша над кабиной автомобиля запрещается',
          'Погрузка в кузов может осуществляться с любой стороны автомобиля',
          'Выбор вида погрузки осуществляется машинистом экскаватора в соответствии с местными условиями',
        ],
        correctAnswers: [
          'Погрузка в кузов должна производиться сбоку или сзади, перенос экскаваторного ковша над кабиной автомобиля запрещается',
        ],
      },
      {
        code: '63673024',
        text:
          'В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?',
        answers: [
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке',
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'На каждый объект должен быть разработан свой план в любом случае',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63673025',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
          'До окончания работ на конкретном месте',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63673026',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['30 минут', '60 минут', '15 минут', '10 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63673027',
        text:
          'Кто утверждает планы мероприятий по противолавинной (противоселевой) защите объектов, содержащие соответствующие меры безопасности, при ведении горных работ в лавиноопасных и селеопасных районах (участках)?',
        answers: [
          'Росприроднадзор',
          'Ростехнадзор',
          'Роснедра',
          'Руководитель объекта',
        ],
        correctAnswers: ['Руководитель объекта'],
      },
      {
        code: '63673028',
        text:
          'Каким документом оформляется решение о согласовании либо об отказе в согласовании плана развития горных работ?',
        answers: [
          'Протоколом рассмотрения',
          'Актом рассмотрения',
          'Распоряжением на бланке Ростехнадзора (территориального органа Ростехнадзора)',
          'Уведомлением (письмом) на бланке Ростехнадзора (территориального органа Ростехнадзора)',
        ],
        correctAnswers: ['Протоколом рассмотрения'],
      },
      {
        code: '63673029',
        text:
          'Какое допускается максимальное отклонение от нулевого кислородного баланса при производстве массовых взрывов при комбинированной (совмещенной) разработке рудных и нерудных месторождений полезных ископаемых?',
        answers: ['30%', '20%', '25%', '35%'],
        correctAnswers: ['25%'],
      },
      {
        code: '63673030',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63673031',
        text:
          'На какие перечисленные виды работ не составляются планы и схемы горных работ?',
        answers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
          'На маркшейдерские работы',
          'На работы по добыче полезных ископаемых',
          'На вскрышные работы',
        ],
        correctAnswers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
        ],
      },
      {
        code: '63673032',
        text:
          'Какие требования установлены "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" для безопасного выполнения работ по ручной оборке откосов уступов?',
        answers: [
          'Ручная оборка откосов не допускается',
          'Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора',
          'Ручная оборка допускается при наличии распоряжения под непосредственным наблюдением руководителя смены или бригадира',
        ],
        correctAnswers: [
          'Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора',
        ],
      },
      {
        code: '63673033',
        text:
          'В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?',
        answers: [
          'Не позднее чем за 5 дней до установленной даты их рассмотрения',
          'Не позднее чем за 7 дней до установленной даты их рассмотрения',
          'Не позднее чем за 10 дней до установленной даты их рассмотрения',
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
        ],
        correctAnswers: [
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
        ],
      },
      {
        code: '63673034',
        text:
          'Какие требования безопасности необходимо применять в местах прохода и проезда людей под ленточными конвейерами?',
        answers: [
          'Необходимо только установить защитное ограждение, исключающее возможность прохода или проезда под ленточными конвейерами',
          'Необходимо установить защитные полки, предохраняющие от возможного поражения падающими с ленты кусками транспортируемого материала, а со стороны основного прохода для людей по всей длине конвейера ролики рабочей и холостой ветви ленты должны иметь ограждения, не блокируемые с приводом конвейера',
          'Необходимо установить плакаты, предупреждающие о возможном поражении падающими с ленты кусками транспортируемого материала',
        ],
        correctAnswers: [
          'Необходимо установить защитные полки, предохраняющие от возможного поражения падающими с ленты кусками транспортируемого материала, а со стороны основного прохода для людей по всей длине конвейера ролики рабочей и холостой ветви ленты должны иметь ограждения, не блокируемые с приводом конвейера',
        ],
      },
      {
        code: '63673035',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Руководителем организации - пользователя недр',
          'Органом исполнительной власти субъекта Российской Федерации',
          'Техническим руководителем организации - пользователя недр',
          'Органом государственного горного надзора',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63673036',
        text:
          'В какой раздел плана (схемы) развития горных работ включаются сведения о соблюдении порядка ликвидации (консервации) горных выработок (скважин), участков, блоков, горизонтов на отработанных частях месторождений?',
        answers: [
          'Сводный маркшейдерский план горных работ',
          'Пояснительная записка планов (схем)',
          'Поуступный маркшейдерский план горных работ',
          'План поверхности',
        ],
        correctAnswers: ['Пояснительная записка планов (схем)'],
      },
      {
        code: '63673037',
        text:
          'Каким должно быть расстояние между горнотранспортными машинами по горизонтали и вертикали при ручной разработке?',
        answers: [
          'Не менее 10 м',
          'Не менее 15 м',
          'Не менее 20 м',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 10 м'],
      },
      {
        code: '63673038',
        text:
          'Какие мероприятия должны осуществляться при одновременной разработке месторождений открытым и подземным способами по обеспечению безопасности работающих одновременно на открытых и подземных горных работах?',
        answers: [
          'Совместные мероприятия, включая: согласование планов и графиков горных и взрывных работ, применение нагнетательной схемы проветривания подземных объектов, предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ',
          'Совместные мероприятия, включая только: согласование планов и графиков горных и взрывных работ, применение нагнетательной схемы проветривания подземных объектов',
          'Совместные мероприятия, включая только: применение нагнетательной схемы проветривания подземных объектов, предотвращение опасности прорыва воды в подземные выработки из объекта открытых горных работ',
        ],
        correctAnswers: [
          'Совместные мероприятия, включая: согласование планов и графиков горных и взрывных работ, применение нагнетательной схемы проветривания подземных объектов, предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ',
        ],
      },
      {
        code: '63673039',
        text:
          'Где должны производиться шиномонтажные работы на карьерных автомобилях?',
        answers: [
          'В отдельных помещениях или на специальных участках, оснащенных необходимыми механизмами и ограждениями',
          'На обочине автомобильной дороги с применением подручных средств',
          'В местах, расположенных недалеко от автомобильных дорог',
          'В близлежащих населенных пунктах, имеющих шиномонтажные мастерские',
        ],
        correctAnswers: [
          'В отдельных помещениях или на специальных участках, оснащенных необходимыми механизмами и ограждениями',
        ],
      },
      {
        code: '63673040',
        text: 'Чем из перечисленного оснащается командный пункт (КП)?',
        answers: [
          'Всем перечисленным',
          'Только электронно-вычислительными машинами',
          'Только множительной и копировальной техникой',
          'Только аппаратурой телефонной и (или) радиосвязи с местами ведения работ по локализации и ликвидации последствий аварии',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63673041',
        text:
          'По чьему указанию должны производиться маневры на станционных путях? Выберите два правильных варианта ответа.',
        answers: [
          'Начальника транспортного цеха',
          'Дежурного по станции',
          'Маневрового диспетчера',
          'Поездного диспетчера',
        ],
        correctAnswers: ['Дежурного по станции', 'Маневрового диспетчера'],
      },
      {
        code: '63673042',
        text:
          'Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных объектов с комбинированной (совмещенной) разработкой?',
        answers: [
          'Только исключение прорывов в подземные горные выработки паводковых и ливневых вод',
          'Только подработанность массивов горных пород, слагающих борта карьера',
          'Только наличие подземных пустот в границах и под дном карьера',
          'Все перечисленные факторы',
        ],
        correctAnswers: ['Все перечисленные факторы'],
      },
      {
        code: '63673043',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения лица, ответственного за проведение работ, и в соответствующих средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом исключающим возможность искрообразования',
          'Только с разрешения представителя газоспасательной службы, и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в соответствующих средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63673044',
        text:
          'На какие виды работ распространяются "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63673045',
        text:
          'Какой инструктаж проводится с работниками объекта при изменении характера работы или после допущенных грубых нарушений требований безопасного ведения работ?',
        answers: [
          'Первичный на рабочем месте',
          'Внеплановый',
          'Повторный',
          'Целевой',
        ],
        correctAnswers: ['Внеплановый'],
      },
      {
        code: '63673046',
        text:
          'Каким образом организуется передвижение людей по территории объектов ведения горных работ?',
        answers: [
          'Передвижение разрешается только по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта',
          'Передвижение допускается по ходу движения автотранспорта',
          'Передвижение допускается в сопровождении горного мастера',
        ],
        correctAnswers: [
          'Передвижение разрешается только по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта',
        ],
      },
      {
        code: '63673047',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63673048',
        text:
          'Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?',
        answers: [
          'Объемы добычи и первичной переработки полезных ископаемых',
          'Направления развития горных работ',
          'Штатная численность сотрудников объекта ведения горных работ',
          'Условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ',
        ],
        correctAnswers: [
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
      },
      {
        code: '63673049',
        text:
          'В каком случае пояснительная записка планов (схем) развития горных работ дополнительно может включать иные материалы (документы, сведения), в том числе обосновывающие технические (технологические) решения, параметры и показатели планируемых работ, связанных с пользованием недрами?',
        answers: [
          'По решению пользователя недр',
          'По решению территориального органа Ростехнадзора',
          'По решению научно-исследовательской организации',
        ],
        correctAnswers: ['По решению пользователя недр'],
      },
      {
        code: '63673050',
        text:
          'Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?',
        answers: [
          'Организация наблюдений за состоянием горных отводов',
          'Достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)',
          'Консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при наличии)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63673051',
        text:
          'Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?',
        answers: [
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
          'Лица, ответственные за руководство горными работами пользователя недр',
          'Лица, ответственные за руководство маркшейдерскими работами',
          'Лица, ответственные за руководство геологическими работами, и представитель Ростехнадзора',
        ],
        correctAnswers: [
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63673052',
        text:
          'Какое из перечисленных требований к рудоспускам указано неверно?',
        answers: [
          'При отработке верхней части рудоспусков они должны быть заполнены до подошвы уступа',
          'При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее двух',
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
          'Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала',
        ],
        correctAnswers: [
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
        ],
      },
      {
        code: '63673053',
        text:
          'С какой периодичностью главными специалистами, ответственными за безопасную эксплуатацию электроустановок, должны проверяться все электрические машины, аппараты и трансформаторы?',
        answers: [
          'Еженедельно',
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца',
          'Ежесменно',
        ],
        correctAnswers: ['Не реже одного раза в три месяца'],
      },
      {
        code: '63673054',
        text:
          'Какое минимальное содержание кислорода должно быть в воздухе рабочей зоны ведения открытых горных работ?',
        answers: ['20%', '15%', '25%', '10%'],
        correctAnswers: ['20%'],
      },
      {
        code: '63673055',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63673056',
        text: 'Где устанавливаются главные заземлители на шахте?',
        answers: [
          'Устройство главных заземлителей на шахте определяется проектом',
          'В каждой распределительной или трансформаторной подстанции, а также в каждой электромашинной камере, за исключением центральной подземной подстанции и околоствольных электромашинных камер, заземляющие контуры которых соединены с главными заземлителями заземляющими проводниками',
          'У каждого стационарного или передвижного распределительного пункта',
          'У каждого индивидуально установленного выключателя или распределительного устройства',
        ],
        correctAnswers: [
          'Устройство главных заземлителей на шахте определяется проектом',
        ],
      },
      {
        code: '63673057',
        text:
          'Что из перечисленного допускается при работе на линии технологического автомобильного транспорта?',
        answers: [
          'Движение вдоль железнодорожных путей на расстоянии менее 5 м от ближайшего рельса',
          'Движение автомобиля с поднятым кузовом',
          'В пунктах погрузки движение задним ходом более 30 м при выполнении работ по проведению траншей',
          'Переезд кабелей, уложенных по почве и не огражденных специальными предохранительными устройствами',
        ],
        correctAnswers: [
          'В пунктах погрузки движение задним ходом более 30 м при выполнении работ по проведению траншей',
        ],
      },
      {
        code: '63673058',
        text:
          'Как должна производиться очистка стрелок и железнодорожных путей от снега и горной массы?',
        answers: [
          'Только вручную бригадой в составе не менее 5 человек',
          'Только вручную бригадой в составе не менее 3 человек, один из которых сигналист',
          'Только автоматически механизированным способом',
          'Автоматически механизированным способом или вручную не менее чем двумя работниками, один из которых сигналист',
        ],
        correctAnswers: [
          'Автоматически механизированным способом или вручную не менее чем двумя работниками, один из которых сигналист',
        ],
      },
      {
        code: '63673059',
        text:
          'С каким диаметром устья скважины, после окончания бурения, должны быть перекрыты?',
        answers: [
          'Более 180 мм',
          'Более 200 мм',
          'Более 250 мм',
          'Более 230 мм',
        ],
        correctAnswers: ['Более 250 мм'],
      },
      {
        code: '63673060',
        text:
          'Что из перечисленного входит в должностные обязанности руководителя горноспасательных работ (РГСР)?',
        answers: [
          'Только организация ведения оперативного журнала ПАСС(Ф)',
          'Только организация ведения горноспасательных работ',
          'Только обеспечение прибытия горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА или оперативным планом',
          'Только проведение анализа полученной от РЛА информации о происшедшей аварии',
          'Только получение заданий от руководителя работ по ликвидации аварии (РЛА) на проведение горноспасательных работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63673061',
        text: 'Как осуществляется устранение пробуксовки ленты конвейера?',
        answers: [
          'Устранение пробуксовки проводится после очистки барабанов и ленты и натяжки ленты натяжными устройствами',
          'Устранение пробуксовки проводится с помощью специального устройства при работающем конвейере',
          'Только вручную',
        ],
        correctAnswers: [
          'Устранение пробуксовки проводится после очистки барабанов и ленты и натяжки ленты натяжными устройствами',
        ],
      },
      {
        code: '63673062',
        text:
          'Кем и в какие сроки проверяются исправность и комплектность горных машин, находящихся в эксплуатации?',
        answers: [
          'Ежесменно проверяются машинистом, еженедельно - механиком, энергетиком участка и ежемесячно - главным механиком, главным энергетиком или другими лицами, назначенными распорядительным документом',
          'Ежесменно проверяются машинистом, раз в три дня - механиком и энергетиком участка, раз в десять дней - главным механиком карьера',
          'Ежесменно проверяются машинистом, еженедельно - механиком, ежемесячно - энергетиком участка',
          'Ежесменно проверяются машинистом, энергетиком и механиком участка, еженедельно - главным энергетиком и ежемесячно - главным механиком',
        ],
        correctAnswers: [
          'Ежесменно проверяются машинистом, еженедельно - механиком, энергетиком участка и ежемесячно - главным механиком, главным энергетиком или другими лицами, назначенными распорядительным документом',
        ],
      },
      {
        code: '63673063',
        text:
          'При наличии какого количества порванных проволок на длине шага свивки в подъемном канате бурового станка он подлежит замене?',
        answers: [
          'Более 3% порванных проволок',
          'Более 7% порванных проволок',
          'Более 5% порванных проволок',
          'Более 10% порванных проволок',
        ],
        correctAnswers: ['Более 10% порванных проволок'],
      },
      {
        code: '63673064',
        text:
          'Каким должно быть сопротивление общего заземляющего устройства открытых горных работ?',
        answers: ['Не более 4 Ом', 'Не более 5 Ом', 'Не более 6 Ом'],
        correctAnswers: ['Не более 4 Ом'],
      },
      {
        code: '63673065',
        text:
          'В каком из перечисленных случаев разрабатываются последующие оперативные планы по локализации и ликвидации последствий аварий (№ 2, 3 и т. д.)?',
        answers: [
          'Когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
          'Когда мероприятия предыдущего оперативного плана не реализованы в полной мере и есть предпосылка возникновения аварии в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта)',
          'По окончании ведения горноспасательных работ, если после выполнения всех мероприятий, предусмотренных предыдущим оперативным планом ликвидации аварии, требуется внести корректировку',
        ],
        correctAnswers: [
          'Когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
        ],
      },
      {
        code: '63673066',
        text: 'В чем должны храниться смазочные и обтирочные материалы?',
        answers: [
          'В деревянных ящиках, обработанных специальным огнезащитным составом',
          'В любой приспособленной для этого емкости',
          'В закрытых металлических ящиках',
        ],
        correctAnswers: ['В закрытых металлических ящиках'],
      },
      {
        code: '63673067',
        text:
          'Кому запрещается находиться на командном пункте, организованном на период ведения работ по локализации и ликвидации последствий аварии на опасном производственном объекте?',
        answers: [
          'Руководителю горноспасательных работ',
          'Руководителю работ по ликвидации аварии',
          'Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)',
          'Лицам, назначенным для ведения оперативных журналов и другой документации, связанной с ведением работ по локализации и ликвидации последствий аварии и горноспасательных работ',
        ],
        correctAnswers: [
          'Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)',
        ],
      },
      {
        code: '63673068',
        text:
          'В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?',
        answers: [
          'Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков',
          'Только в виде схем эксплуатации объектов первичной переработки (подготовки) минерального сырья (для месторождений твердых полезных ископаемых, включая общераспространенные)',
          'Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63673069',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'Со специализированными профессиональными аварийно-спасательными формированиями',
          'С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез',
          'С Федеральным агентством по недропользованию',
          'С территориальными органами Ростехнадзора',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63673070',
        text:
          'Какой должна быть ширина временных въездов в траншеи для автомобилей?',
        answers: [
          'Ширина временных въездов должна быть такой, чтобы вдоль них при движении транспорта оставался свободный проход шириной не менее 1,5 м с обеих сторон',
          'Ширина въездов определяется проектом проведения горных работ',
          'Ширина въездов должна быть не меньше ширины автомобиля со свободным проходом по одной стороне не менее 1 м',
        ],
        correctAnswers: [
          'Ширина временных въездов должна быть такой, чтобы вдоль них при движении транспорта оставался свободный проход шириной не менее 1,5 м с обеих сторон',
        ],
      },
      {
        code: '63673071',
        text:
          'В каких из перечисленных случаев руководитель работ по ликвидации аварии и руководитель горноспасательных работ должны разработать оперативный план по локализации и ликвидации последствий аварий? Выберите два правильных варианта ответа.',
        answers: [
          'В случае если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ',
          'В случае если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария',
          'По окончании ведения горноспасательных работ, если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется их корректировка',
          'В первоначальный момент возникновения аварии в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта)',
        ],
        correctAnswers: [
          'В случае если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ',
          'В случае если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария',
        ],
      },
      {
        code: '63673072',
        text:
          'Какой должна быть ширина проезжей дороги переездов через временные железнодорожные пути объекта ведения горных работ?',
        answers: [
          'От 10 до 22 м',
          'От 8 до 15 м',
          'От 13 до 25 м',
          'Ширина проезжей дороги должна устанавливаться в зависимости от применяемых на объекте ведения горных работ самосвалов максимальной грузоподъемности',
        ],
        correctAnswers: [
          'Ширина проезжей дороги должна устанавливаться в зависимости от применяемых на объекте ведения горных работ самосвалов максимальной грузоподъемности',
        ],
      },
      {
        code: '63673073',
        text:
          'По прошествии какого времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?',
        answers: ['Через 4 часа', 'Через 3 часа', 'Через час', 'Через 2 часа'],
        correctAnswers: ['Через 2 часа'],
      },
      {
        code: '63673074',
        text: 'Кто допускается к обслуживанию и ремонту электроустановок?',
        answers: [
          'Специально подготовленный электротехнический и электротехнологический персонал, прошедший обучение по безопасным методам работы и проверку знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности',
          'Персонал, прошедший обучение',
          'Лица, прошедшие обучение и сдавшие экзамен на знание ими обслуживаемого оборудования',
          'Лица, имеющие соответствующее образование',
        ],
        correctAnswers: [
          'Специально подготовленный электротехнический и электротехнологический персонал, прошедший обучение по безопасным методам работы и проверку знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности',
        ],
      },
      {
        code: '63673075',
        text:
          'Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?',
        answers: [
          'Только защиту объектов ведения горных работ и месторождений полезных ископаемых от затопления, обводнения, взрывов, пожаров, горных ударов',
          'Только рекультивацию нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых)',
          'Только применение средств и технологий, повышающих безопасное ведение горных работ и уровень извлечения полезных ископаемых',
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
        correctAnswers: [
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
      },
      {
        code: '63673076',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'План мероприятий разрабатывается на каждый опасный объект отдельно',
          'В случае если это регламентировано внутренней документацией организации',
          'В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63673077',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
          'Комиссией пользователя недр (подрядной организации)',
          'Техническим руководителем пользователя недр (подрядной организации)',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63673078',
        text:
          'Какой должна быть допустимая скорость движения автомобилей, автомобильных и тракторных поездов на технологических дорогах карьера?',
        answers: [
          '40 км/ч',
          '30 км/ч',
          '45 км/ч',
          'Скорость устанавливается техническим руководителем организации',
        ],
        correctAnswers: [
          'Скорость устанавливается техническим руководителем организации',
        ],
      },
      {
        code: '63673079',
        text:
          'При каких погодных условиях работа транспортно-отвального моста должна быть прекращена?',
        answers: [
          'Во время грозы, ливня, тумана и метели при видимости до 25 м, при влажном и сильном снегопаде',
          'Во время тумана при видимости до 30 м',
          'Только во время землетрясения',
          'При скорости ветра более 10 м/с',
        ],
        correctAnswers: [
          'Во время грозы, ливня, тумана и метели при видимости до 25 м, при влажном и сильном снегопаде',
        ],
      },
      {
        code: '63673080',
        text:
          'В течение какого времени должен даваться предпусковой предупредительный сигнал о запуске технологического оборудования?',
        answers: [
          'В течение 3 секунд',
          'В течение 6 секунд',
          'В течение 10 секунд',
          'В течение 5 секунд',
        ],
        correctAnswers: ['В течение 6 секунд'],
      },
      {
        code: '63673081',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Пожарной службе эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
          'Начальнику цеха',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63673082',
        text:
          'Кто принимает решения об окончании, приостановлении и возобновлении работ по локализации и ликвидации последствий аварий или горноспасательных работ?',
        answers: [
          'Руководитель горноспасательных работ',
          'Руководитель профессионального аварийно-спасательного формирования',
          'Руководитель работ по ликвидации аварии',
          'Должностное лицо профессионального аварийно-спасательного формирования, обслуживающего опасный производственный объект',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63673083',
        text: 'Что должны отображать графические материалы?',
        answers: [
          'Границы участка недр, установленные при предоставлении лицензии на пользование недрами (предварительные границы горных отводов)',
          'Уточненные границы горного отвода',
          'Границы опасных зон и зон с особыми условиями использования территорий (при разработке месторождений твердых полезных ископаемых при наличии)',
          'Контуры горных выработок (для твердых полезных ископаемых) и объектов поверхности, включая устья скважин (кусты скважин) различного назначения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63673084',
        text:
          'На основании какого документа могут быть возобновлены открытые горные работы после их прекращения при обнаружении признаков сдвижения пород?',
        answers: [
          'Распоряжения территориального органа Ростехнадзора',
          'Акта, утвержденного комиссией организации',
          'Специального проекта организации работ, утвержденного руководителем предприятия',
          'Специального проекта организации работ, утвержденного командиром подразделения ВГСЧ',
        ],
        correctAnswers: [
          'Специального проекта организации работ, утвержденного руководителем предприятия',
        ],
      },
      {
        code: '63673085',
        text:
          'Кем утверждается проект ведения горных работ с промежуточными отвалами (складами)?',
        answers: [
          'Руководителем подразделения, разработавшего проект',
          'Техническим руководителем организации',
          'Главным геологом организации',
          'Представителем Ростехнадзора',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63673086',
        text: 'Что из перечисленного допускается при отвалообразовании?',
        answers: [
          'Подача самосвала на разгрузку задним ходом',
          'Разгрузка самосвалов и работа бульдозера или фронтального погрузчика в пределах призмы обрушения',
          'Одновременная работа на перегрузочном пункте экскаватора в одном секторе с бульдозером или самосвалом',
          'Устройство контактной сети на эстакаде разгрузочной площадки',
        ],
        correctAnswers: ['Подача самосвала на разгрузку задним ходом'],
      },
      {
        code: '63673087',
        text:
          'Разрешается ли ведение горных работ вблизи затопленных выработок или поверхностных водоемов?',
        answers: [
          'Разрешается по согласованию с владельцем водоема',
          'Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды',
          'Не разрешается',
        ],
        correctAnswers: [
          'Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды',
        ],
      },
      {
        code: '63673088',
        text:
          'С учетом каких факторов проектом должна определяться высота уступа?',
        answers: [
          'Результатов исследований физико-механических свойств горных пород и горно-геологических условий их залегания, а также параметров применяемого оборудования',
          'Результатов горно-геологических условий их залегания и параметров оборудования',
          'Результатов исследований физико-механических свойств горных пород и полезных ископаемых',
        ],
        correctAnswers: [
          'Результатов исследований физико-механических свойств горных пород и горно-геологических условий их залегания, а также параметров применяемого оборудования',
        ],
      },
      {
        code: '63673089',
        text:
          'На основании каких данных составляется оперативный план по локализации и ликвидации последствий аварий?',
        answers: [
          'Только на основании поступающей на командный пункт информации',
          'На основании всех перечисленных данных',
          'Только на основании анализа аварийной обстановки',
          'Только на основании прогноза развития аварии',
        ],
        correctAnswers: ['На основании всех перечисленных данных'],
      },
      {
        code: '63673090',
        text:
          'Что не отображается на планах поверхности при составлении планов и схем развития горных работ?',
        answers: [
          'Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов',
          'Устья выходящих на поверхность действующих, законсервированных и ликвидированных горных выработок',
          'Объекты застройки площади залегания полезных ископаемых (горного отвода) с выделением зданий и сооружений, подлежащих охране от вредного влияния горных работ',
          'Места образования провалов, оползней, прорыва плывунов',
          'Объекты наземных и подземных коммуникаций (при наличии)',
          'Отображается все перечисленное',
        ],
        correctAnswers: [
          'Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов',
        ],
      },
      {
        code: '63673091',
        text:
          'Что устанавливается в лицензиях на пользование недрами, технических проектах и иной проектной документации на выполнение работ, связанных с пользованием участками недр?',
        answers: [
          'Дополнительные мероприятия по прогнозированию и предупреждению опасных ситуаций с соответствующей корректировкой технических и технологических решений',
          'Дополнительные мероприятия, обеспечивающие безопасное пользование недрами',
          'Соблюдение требований по технологии ведения работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63673092',
        text: 'Что запрещается при комбинированной разработке месторождений?',
        answers: [
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
          'Применение систем разработки, исключающих сдвижение (разрушение) массива предохранительного целика',
          'Ограничение мощности массовых взрывов в карьере и подземных выработках',
          'Применение нагнетательного способа проветривания подземных выработок',
        ],
        correctAnswers: [
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
        ],
      },
      {
        code: '63673093',
        text:
          'Кем утверждается паспорт буро-взрывных работ для каждого рабочего уступа - выемочного блока на основе маркшейдерской документации?',
        answers: [
          'Специализированной организацией',
          'Ответственным руководителем работ',
          'Техническим руководителем карьера',
          'Руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем карьера'],
      },
      {
        code: '63673094',
        text:
          'Какие требования к медицинскому обслуживанию на объектах ведения горных работ указаны неверно?',
        answers: [
          'В организациях с количеством работающих менее 500 человек медицинское обслуживание допускается осуществлять в ближайшем лечебном учреждении',
          'На объектах ведения горных работ должен быть организован пункт первой медицинской помощи, оборудованный средствами связи',
          'На каждом участке, в цехах, мастерских, а также на транспортном оборудовании и в санитарно-бытовых помещениях обязательно наличие аптечек для оказания первой помощи',
          'Все перечисленные',
        ],
        correctAnswers: [
          'В организациях с количеством работающих менее 500 человек медицинское обслуживание допускается осуществлять в ближайшем лечебном учреждении',
        ],
      },
      {
        code: '63673095',
        text:
          'Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?',
        answers: [
          'Физические свойства складируемых пород, прочность их в куске',
          'Сдвиговые характеристики отвальной массы',
          'Строение основания (наклон основания, наличие прослоев слабых пород и их мощность, наличие водоносных горизонтов, возможность образования техногенных водоносных горизонтов в нижней части отвала и в породах основания)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63673096',
        text:
          'На какой срок разрабатывается план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся открытые горные работы?',
        answers: [
          'Минимум на 5 лет',
          'На 1 год',
          'На 2 года',
          'На 3 года с учетом фактического состояния объекта горных работ',
        ],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63673097',
        text:
          'Как машинисты горных машин, эксплуатируемых на объектах ведения открытых горных работ, должны предупреждать о начале движения задним ходом?',
        answers: [
          'Включением проблесковых маячков на кабине машины',
          'Звуковым прерывистым сигналом',
          'Любым удобным способом, привлекающим внимание',
        ],
        correctAnswers: ['Звуковым прерывистым сигналом'],
      },
      {
        code: '63673098',
        text:
          'Каким должно быть расстояние от нижнего фазного провода линии до верхней точки автомобиля или груза при пересечении временных линий электропередачи с автомобильной дорогой при напряжении до 20 кВ?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 1,0 м',
          'Не менее 1,5 м',
          'Не менее 2,0 м',
        ],
        correctAnswers: ['Не менее 2,0 м'],
      },
      {
        code: '63673099',
        text:
          'Какие мероприятия должна обеспечить организация, эксплуатирующая объект ведения открытых горных работ?',
        answers: [
          'Контроль за состоянием противодеформационных сооружений и выполнением мероприятий, предотвращающих нарушение устойчивости бортов, уступов, откосов',
          'Проведение визуальных и инструментальных наблюдений за состоянием бортов, уступов и откосов',
          'Контроль за соблюдением проектных параметров бортов и уступов карьера, разреза и откосов отвала',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63673100',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Эксплуатирующая организация должна согласовать с подрядной организации расстановку знаков, обозначающих границы земляных работ',
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63673101',
        text:
          'Какое напряжение необходимо для питания аппаратуры сигнализации?',
        answers: [
          'Не выше 42 В при наличии защиты от токов утечки',
          'Не выше 220 В при наличии защиты от токов утечки',
          'Не выше 380 В при наличии защиты от токов утечки',
          'Не выше 127 В при наличии защиты от токов утечки',
        ],
        correctAnswers: ['Не выше 220 В при наличии защиты от токов утечки'],
      },
      {
        code: '63673102',
        text:
          'Каким транспортом должна осуществляться доставка рабочих к месту работ?',
        answers: [
          'Любыми вагонами или любым автотранспортом',
          'Только специальным транспортом',
          'Только автотранспортом',
        ],
        correctAnswers: ['Только специальным транспортом'],
      },
      {
        code: '63673103',
        text:
          'Какие требования установлены Инструкцией по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы, к лицам, допускаемым к руководству работами по локализации и ликвидации последствий аварии и горноспасательными работами?',
        answers: [
          'Наличие стажа работы не менее 1 года в организации, эксплуатирующей опасные производственные объекты, на которых ведутся горные работы',
          'Наличие высшего образования по направлению подготовки (специальности) "Техносферная безопасность"',
          'Возраст не менее 21 года, отсутствие медицинских противопоказаний для данного вида работ, наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Прикладная геодезия", прохождение стажировки сроком не менее 3 месяцев',
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
        correctAnswers: [
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
      },
      {
        code: '63673104',
        text:
          'Что является коэффициентом запаса устойчивости при детерминированном подходе?',
        answers: [
          'Допустимая вероятность развития деформаций, которые определяются проектной документацией с учетом размещения элементов инфраструктуры объекта недропользования',
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
          'Действие этих сил во всех инженерных методах переносится на потенциальную (наиболее напряженную) поверхность скольжения',
        ],
        correctAnswers: [
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
        ],
      },
      {
        code: '63673105',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          '3 года',
          '5 лет',
          'Наибольший срок, предусмотренных для этих объектов',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63673106',
        text:
          'Какое из перечисленных требований не допускается при комбинированной разработке месторождений?',
        answers: [
          'Располагать фронт ведения горных работ в направлении от массива к карьеру при подземных очистных работах',
          'Располагать фронт ведения горных работ в направлении навстречу фронту развития подземных очистных работ при открытых работах',
          'Добыча руды подземным способом из проектных контуров карьера',
        ],
        correctAnswers: [
          'Добыча руды подземным способом из проектных контуров карьера',
        ],
      },
      {
        code: '63673107',
        text:
          'Сколько выходов должно быть в камерах подстанций длиной более 10 м?',
        answers: [
          'Один выход',
          'Два выхода, расположенных в наиболее удаленных друг от друга частях камеры',
          'Два выхода независимо от расположения',
        ],
        correctAnswers: [
          'Два выхода, расположенных в наиболее удаленных друг от друга частях камеры',
        ],
      },
      {
        code: '63673108',
        text:
          'В каком из перечисленных случаев нарушены требования к системам вентиляции при комбинированной (совмещенной) разработке месторождения?',
        answers: [
          'Вспомогательные нагнетательные вентиляторы должны быть максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках',
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
          'При комбинированной (совмещенной) разработке месторождения и наличии аэродинамических связей подземных выработок с карьерным пространством предусматривается нагнетательный способ общерудничной системы проветривания',
          'Способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ',
        ],
        correctAnswers: [
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
        ],
      },
      {
        code: '63673109',
        text:
          'С какой периодичностью должна пополняться схема транспортных коммуникаций, нанесенная на план горных работ для контроля за вводом в эксплуатацию, эксплуатацией и ликвидацией железнодорожных путей и автодорог, при разработке полезных ископаемых?',
        answers: [
          'Не реже 1 раза в неделю',
          'Ежемесячно',
          'В случае возникших изменений 1 раз в декаду',
          'Периодичность пересмотра схемы определяется техническим руководителем организации',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63673110',
        text:
          'Какими документами необходимо руководствоваться при эксплуатации оборудования?',
        answers: [
          'Руководствами по эксплуатации, техническими паспортами и другими нормативными документами заводов-изготовителей',
          'Только руководствами по их эксплуатации',
          'Только инструкциями',
          'Только техническими паспортами',
        ],
        correctAnswers: [
          'Руководствами по эксплуатации, техническими паспортами и другими нормативными документами заводов-изготовителей',
        ],
      },
      {
        code: '63673111',
        text:
          'Что относится к основным факторам, которые учитываются при оценке устойчивости бортов и уступов карьеров, разрезов в массивах дисперсных горных пород?',
        answers: [
          'Набухание, снижение прочности и развитие локальных деформаций уступов и их групп',
          'Отсутствие дренажа или недостаточная его эффективность',
          'Относительная деформация набухания под нагрузкой (для глинистых пород)',
          'Несоответствие углов наклона бортов, откосов уступов и отвалов или несоответствие их высот геологическим условиям',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Набухание, снижение прочности и развитие локальных деформаций уступов и их групп',
        ],
      },
      {
        code: '63673112',
        text: 'В каком месте допускается наличие только одного поезда?',
        answers: [
          'На межпостовых перегонах',
          'На блок-участках',
          'На межстанционных перегонах',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63673113',
        text:
          'Для хранилищ какого класса длина надводного пляжа в течение всего срока эксплуатации намывных гидроотвалов должна быть не менее 50 м?',
        answers: [
          'Для хранилищ II класса',
          'Для хранилищ I класса',
          'Для хранилищ III класса',
          'Для хранилищ IV класса',
        ],
        correctAnswers: ['Для хранилищ I класса'],
      },
      {
        code: '63673114',
        text:
          'Каким документом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?',
        answers: [
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
          'Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Распорядительным документом на основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы',
          'Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
        ],
      },
      {
        code: '63673115',
        text:
          'С какой периодичностью должны проверяться устройства контроля за изменением ширины рельсовых путей и их уклонов?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в неделю',
          'Не реже одного раза в смену',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63673116',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С газоспасательной службой и службой охраны труда',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63673117',
        text:
          'Где устанавливается периодичность осмотров и инструментальных наблюдений за деформациями бортов, откосов, уступов и отвалов, а также объектов, попадающих в зоны влияния горных работ и расположенных на земной поверхности?',
        answers: [
          'В плане развития горных работ',
          'В проекте производства маркшейдерских работ',
          'В журнале маркшейдерских указаний',
          'В схеме развития горных работ',
        ],
        correctAnswers: ['В проекте производства маркшейдерских работ'],
      },
      {
        code: '63673118',
        text:
          'Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Характеристику объекта, в отношении которого разрабатывается план мероприятий',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63673119',
        text:
          'Какие требования безопасности устанавливаются к организации движения автомобилей на отвалах и перегрузочных пунктах?',
        answers: [
          'На отвалах и перегрузочных пунктах должны устанавливаться схемы движения автомобилей. Зона разгрузки должна быть ограничена с обеих сторон знаками в виде изображения самосвала с поднятым кузовом с указателями направления разгрузки',
          'Автомобили должны двигаться по установленному маршруту',
          'Автомобили должны двигаться, не мешая друг другу',
        ],
        correctAnswers: [
          'На отвалах и перегрузочных пунктах должны устанавливаться схемы движения автомобилей. Зона разгрузки должна быть ограничена с обеих сторон знаками в виде изображения самосвала с поднятым кузовом с указателями направления разгрузки',
        ],
      },
      {
        code: '63673120',
        text:
          'На какой срок может быть продлено рассмотрение планов и (или) схем развития горных работ по решению органа государственного горного надзора, в случае необходимости проведения оценки обоснования соблюдения условий безопасного недропользования?',
        answers: [
          'На срок не более 30 дней с назначенной даты их рассмотрения',
          'На срок не более 40 дней с назначенной даты их рассмотрения',
          'На срок не более 15 дней с назначенной даты их рассмотрения',
          'На срок не более 20 дней с назначенной даты их рассмотрения',
        ],
        correctAnswers: [
          'На срок не более 30 дней с назначенной даты их рассмотрения',
        ],
      },
      {
        code: '63673121',
        text:
          'Кем осуществляются подготовка и утверждение планов и схем развития горных работ?',
        answers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
          'Подготовка - пользователем недр, утверждение - руководителем территориального органа государственного горного надзора',
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - представителем местной исполнительной власти',
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - руководителем территориального органа государственного горного надзора',
        ],
        correctAnswers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
        ],
      },
      {
        code: '63673122',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'Ко II группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой опасности',
        ],
        correctAnswers: ['Ко II группе'],
      },
      {
        code: '63673123',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, но не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63673124',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Название графического документа',
          'Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами',
          'Дата составления',
          'Наименования организации, объекта недропользования (участка недр)',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63673125',
        text:
          'В какой системе координат составляются графические материалы согласно условным обозначениям для горной и (или) маркшейдерской графической документации?',
        answers: [
          'В эллипсоидной системе координат',
          'В прямоугольной системе координат',
          'В зональной системе координат Гаусса–Крюгера',
          'Не регламентируется',
        ],
        correctAnswers: ['В прямоугольной системе координат'],
      },
      {
        code: '63673126',
        text:
          'Какие защитные приспособления необходимо предусматривать на элеваторах, транспортирующих мокрые продукты, во избежание разбрызгивания пульпы?',
        answers: [
          'Они должны иметь защитные ограждения только у мест загрузки и разгрузки элеваторов',
          'Они должны иметь плотное покрытие',
          'Они должны быть закрыты по всей длине предохранительными щитами или кожухами',
          'Никаких дополнительных приспособлений не предусматривается, рабочие должны быть одеты в специальные защитные костюмы',
        ],
        correctAnswers: [
          'Они должны быть закрыты по всей длине предохранительными щитами или кожухами',
        ],
      },
      {
        code: '63673127',
        text: 'Какие блокировки должна иметь защитная аппаратура?',
        answers: [
          'Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока',
          'От перебоев напряжения',
          'Против срабатывания защиты отключения магнитных пускателей',
        ],
        correctAnswers: [
          'Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока',
        ],
      },
      {
        code: '63673128',
        text:
          'Каким должно быть расстояние от гидромониторной установки и другого забойного оборудования (скреперов, бульдозеров) до забоя при гидромеханизированном способе разработки глинистых, плотных и лессовидных пород, способных к обрушению глыбами?',
        answers: [
          'Не менее 0,5 высоты уступа',
          'Не менее 1,0 высоты уступа',
          'Не менее 1,2 высоты уступа',
          'Не менее 0,8 высот уступа',
        ],
        correctAnswers: ['Не менее 1,2 высоты уступа'],
      },
      {
        code: '63673129',
        text:
          'С какой периодичностью подлежат осмотру пульповодосборные установки главным механиком?',
        answers: [
          'Не реже одного раза в сутки',
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
        ],
        correctAnswers: ['Не реже одного раза в сутки'],
      },
      {
        code: '63673130',
        text:
          'Какими преимущественно системами разработки при совместной разработке месторождения следует производить подземные горные работы?',
        answers: [
          'Любыми системами разработки',
          'Только системами разработки с закладкой выработанного пространства',
          'Преимущественно системами с поддержанием выработанного пространства',
          'В каждом конкретном случае возможность применения той или иной системы разработки устанавливается проектной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Преимущественно системами с поддержанием выработанного пространства',
        ],
      },
      {
        code: '63673131',
        text:
          'Что служит защитой электроприводов технологического оборудования при исчезновении или резком снижении напряжения питающей сети?',
        answers: [
          'Защита от колебаний нагрузки',
          'Блокировка, исключающая самозапуск',
          'Защита от перегрузки',
        ],
        correctAnswers: ['Блокировка, исключающая самозапуск'],
      },
      {
        code: '63673132',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
          'На основе локального плана проведения работ',
          'На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России',
          'На основе технического регламента',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63673133',
        text: 'Что относится к комбинированной разработке месторождений?',
        answers: [
          'Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или по горизонтали в пределах одного месторождения; ведение открытых и подземных горных работ в определенные периоды времени в пределах общего срока отработки запасов месторождения с одновременной или последовательной открыто-подземной или подземно-открытой разработкой',
          'Последовательное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения',
          'Одновременное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или по горизонтали в пределах одного месторождения',
        ],
        correctAnswers: [
          'Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или по горизонтали в пределах одного месторождения; ведение открытых и подземных горных работ в определенные периоды времени в пределах общего срока отработки запасов месторождения с одновременной или последовательной открыто-подземной или подземно-открытой разработкой',
        ],
      },
      {
        code: '63673134',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники подрядной организации',
          'Правилами не регламентируется',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, указанные в наряде-допуске',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63673135',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?',
        answers: [
          'Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок',
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
          'Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
          'Начальник участка, на котором произошла авария',
        ],
        correctAnswers: [
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
        ],
      },
      {
        code: '63673136',
        text:
          'Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?',
        answers: [
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
          'Участковым маркшейдером или геологом не реже одного раза в полугодие',
          'Комиссией в составе главного инженера карьера и главного маркшейдера карьера раз в полугодие',
          'Главным инженером карьера ежеквартально',
        ],
        correctAnswers: [
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
        ],
      },
      {
        code: '63673137',
        text:
          'С какой периодичностью проводится проверка знаний инструкций по профессиям у рабочих?',
        answers: [
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63673138',
        text:
          'Какими из перечисленных устройств защиты должны быть снабжены кабины гусеничных и колесных погрузчиков, тракторов, предназначенных для эксплуатации на объекте ведения горных работ?',
        answers: [
          'Устройством защиты оператора при опрокидывании машины и устройством защиты от токов короткого замыкания',
          'Устройством защиты оператора при опрокидывании машины и устройством защиты от падающих кусков горной массы сверху и сбоку',
          'Устройством защиты от падающих кусков горной массы сверху и сбоку и устройством аварийной вытяжной вентиляции',
          'Устройством защиты оператора при опрокидывании машины и устройством защиты от перегрузки и перенапряжения',
        ],
        correctAnswers: [
          'Устройством защиты оператора при опрокидывании машины и устройством защиты от падающих кусков горной массы сверху и сбоку',
        ],
      },
      {
        code: '63673139',
        text:
          'Какое требование установлено к коробкам выводов электрических машин и пускорегулирующей аппаратуры "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой',
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть защищены ограждениями',
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть оборудованы звуковой и световой сигнализацией',
        ],
        correctAnswers: [
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой',
        ],
      },
      {
        code: '63673140',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63673141',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63673142',
        text:
          'Каким минимальным условиям обеспечения проходов для людей должна соответствовать ширина галерей и эстакад при ширине ленты свыше 1400 мм?',
        answers: [
          '700 мм с одной стороны и 750 мм с другой стороны конвейера',
          '800 мм с обеих сторон конвейера',
          '500 мм с одной стороны конвейера и 700 мм с другой',
          '1000 мм с обеих сторон конвейер',
        ],
        correctAnswers: ['800 мм с обеих сторон конвейера'],
      },
      {
        code: '63673143',
        text:
          'Как часто персонал, работающий на электроустановках, обязан производить осмотр защитных заземлений?',
        answers: [
          'Ежесменно',
          'Не реже одного раза в 3 месяца',
          'Еженедельно',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63673144',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63673145',
        text:
          'При каком условии допускается транспортирование машин и оборудования с использованием двух и более тягачей при ведении горных работ открытым способом?',
        answers: [
          'С оформлением наряда-допуск',
          'При участии не менее трех работников',
          'Если это обусловлено проектом производства работ',
          'В присутствии начальника транспортного цеха',
        ],
        correctAnswers: ['С оформлением наряда-допуск'],
      },
      {
        code: '63673146',
        text:
          'На кого возлагается руководство работами по локализации и ликвидации последствий аварий?',
        answers: [
          'На начальника участка',
          'На технического руководителя (главного инженера)',
          'На начальника аварийно-спасательной службы',
        ],
        correctAnswers: ['На технического руководителя (главного инженера)'],
      },
      {
        code: '63673147',
        text:
          'В наклонной горной выработке с каким углом наклона пожар тушится активным способом дистанционно?',
        answers: [
          'С углом наклона более 20°',
          'С углом наклона более 10°',
          'С углом наклона более 15°',
          'В любой наклонной горной выработке',
        ],
        correctAnswers: ['С углом наклона более 20°'],
      },
      {
        code: '63673148',
        text:
          'Что должны включать табличные материалы пояснительной записки планов (схем) по видам горных работ и видам полезных ископаемых?',
        answers: [
          'Основные технические показатели на предстоящий период (план) и ожидаемые на конец текущего года (факт)',
          'Сведения о состоянии и движении вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых по состоянию на начало планируемого периода и ожидаемого на конец этого периода (при добыче твердых полезных ископаемых)',
          'Сведения о состоянии и движении фонда скважин (при разработке месторождений углеводородного сырья)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63673149',
        text:
          'Кто проводит осмотр забоя перед началом разработки новой заходки многоковшовыми экскаваторами?',
        answers: [
          'Лицо технического надзора',
          'Машинист экскаватора',
          'Начальник смены',
          'Горный мастер',
        ],
        correctAnswers: ['Лицо технического надзора'],
      },
      {
        code: '63673150',
        text:
          'На каком расстоянии от высоковольтной линии электропередачи должен быть расположен гидромонитор?',
        answers: [
          'Не менее однократной дальности полета струи',
          'Не менее полуторакратной дальности полета струи',
          'Не менее двукратной дальности полета струи',
          'Не менее трехкратной дальности полета струи',
        ],
        correctAnswers: ['Не менее двукратной дальности полета струи'],
      },
      {
        code: '63673151',
        text:
          'Какой должна быть скорость движения конвейерной ленты при ручной рудоразборке?',
        answers: [
          'Не более 0,5 м/с',
          'Не более 1 м/с',
          'Не более 1,5 м/с',
          'Не более 5 м/с',
        ],
        correctAnswers: ['Не более 0,5 м/с'],
      },
      {
        code: '63673152',
        text:
          'В каком случае допускается очистка думпкаров вручную на приямках?',
        answers: [
          'Только при условии письменного разрешения технического руководителя объекта',
          'Только в присутствии наблюдающего',
          'Не допускается ни в каком случае',
          'Только при условии разработки мероприятий по безопасному производству работ',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63673153',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Не допускается',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63673154',
        text:
          'Чье письменное разрешение требуется для производства работ, требующих ограждения сигналами остановки или уменьшения скорости, на станционных путях?',
        answers: [
          'Руководителя работ',
          'Дежурного по станции',
          'Технического руководителя объекта',
        ],
        correctAnswers: ['Дежурного по станции'],
      },
      {
        code: '63673155',
        text:
          'Какое из перечисленных требований при добыче камня с применением клиновых работ указано верно?',
        answers: [
          'Выкалывание камня на уступе должно производиться снизу вверх',
          'Фронт работ каждого рабочего забоя должен быть не менее 4 м',
          'Расстояние между камнеломами должно быть не менее 10 м',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63673156',
        text:
          'На какие предприятия (организации) не распространяются Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: [
          'На объекты кучного выщелачивания',
          'На гидрометаллургические фабрики',
          'На предприятия по переработке негорючих твердых полезных ископаемых',
          'На предприятия (организации) по переработке твердых горючих полезных ископаемых',
        ],
        correctAnswers: [
          'На предприятия (организации) по переработке твердых горючих полезных ископаемых',
        ],
      },
      {
        code: '63673157',
        text:
          'Какой должна быть высота падения груза при погрузке горной массы в автомобиль?',
        answers: [
          'Не регламентируется',
          'Не более 5 м',
          'Не более 4 м',
          'Минимально возможной и во всех случаях не превышать 3 м',
        ],
        correctAnswers: [
          'Минимально возможной и во всех случаях не превышать 3 м',
        ],
      },
      {
        code: '63673158',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Непосредственный руководитель работ и лица ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63673159',
        text:
          'С какой периодичностью должны обновляться (пополняться) сводные планы горных работ, планы поверхности, в случаях если ситуация местности (объектовый состав) в границах горного отвода и (или) система наблюдений за состоянием горного отвода и расположенных в его границах горных выработок (скважин), зданий, сооружений и иных объектов не претерпели изменений?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 10 лет',
        ],
        correctAnswers: ['Не реже одного раза в 5 лет'],
      },
      {
        code: '63673160',
        text:
          'Какими должны быть предельные углы откосов (углы устойчивости) временно консервируемых участков борта в процессе эксплуатации?',
        answers: [
          'Не должны превышать 70°',
          'Не должны превышать 80°',
          'Предельные углы откосов (углы устойчивости) устанавливаются проектом',
        ],
        correctAnswers: [
          'Предельные углы откосов (углы устойчивости) устанавливаются проектом',
        ],
      },
      {
        code: '63673161',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63673162',
        text:
          'В течение какого времени пересматривается план мероприятий по локализации и ликвидации последствий аварий после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства?',
        answers: [
          'Не позднее 10 календарных дней',
          'Не позднее 15 календарных дней',
          'Не позднее 30 календарных дней',
          'Не позднее 3 месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63673163',
        text:
          'Кто должен утверждать специальный технологический регламент, по которому производится ведение горных работ по безвзрывной или комбинированной технологии подготовки крепких горных массивов к экскавации с использованием разупрочняющих растворов?',
        answers: [
          'Начальник участка',
          'Технический руководитель организации',
          'Руководитель организации',
          'Комиссия организации',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63673164',
        text:
          'Какие сведения должны быть нанесены на маркшейдерские планы и разрезы?',
        answers: [
          'Только зоны обводненных горизонтов, положение зумпфов водоотливных установок с указанием отметок дна зумпфа, водоводов, изоляционных перемычек в дренажных подземных выработках, предотвращающих проникновение ядовитых газов из карьера в подземные выработки',
          'Только все горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры, границы поверхностного контура зоны обрушения, разведочные и контрольные наблюдательные скважины и реперы',
          'Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода',
        ],
        correctAnswers: [
          'Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода',
        ],
      },
      {
        code: '63673165',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63673166',
        text:
          'В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?',
        answers: [
          'Не мельче 1:15000',
          'Не мельче 1:10000',
          'Не мельче 1:35000',
          'Не мельче 1:25000',
        ],
        correctAnswers: ['Не мельче 1:10000'],
      },
      {
        code: '63673167',
        text:
          'Кем подаются разрешающие сигналы для производства подачи и передвижения железнодорожных составов в процессе погрузки (разгрузки) при ведении горных работ открытым способом?',
        answers: [
          'Машинистом экскаватора или оператором погрузочного устройства',
          'Поездным диспетчером',
          'Начальником карьера',
          'Машинистом состава',
        ],
        correctAnswers: [
          'Машинистом экскаватора или оператором погрузочного устройства',
        ],
      },
      {
        code: '63673168',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'Нужно в любом случае',
          'По решению руководителя эксплуатирующей организации',
          'Правилами не регламентируется',
          'Решение принимается совместно руководителем эксплуатирующей организации и руководителем газоспасательной службы',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63673169',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности',
          'Из частных разделов',
          'Из специализированных разделов',
          'Из общих и специальных разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63673170',
        text:
          'Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?',
        answers: [
          'Обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения',
          'Параметры карьерного и шахтного полей с запасами руды, намечаемые к отработке открытым и подземным способами',
          'Функции и ответственность инженерно-технических служб рудника',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63673171',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Производить подготовку объекта к проведению газоопасных работ',
          'Непосредственно участвовать в выполнении газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63673172',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Главный инженер эксплуатирующей организации',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель подрядной организации совместно с руководителем аварийно-спасательной службы',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63673173',
        text:
          'В какой период, установленный "Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых", осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?',
        answers: [
          'С 1 октября по 15 декабря года, предшествующего планируемому',
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
          'С 1 сентября по 15 декабря года, предшествующего планируемому',
          'С 1 октября по 31 декабря года, предшествующего планируемому',
        ],
        correctAnswers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
      },
      {
        code: '63673174',
        text:
          'Какой документ должен находиться на каждой единице горнотранспортного оборудования?',
        answers: [
          'Журнал приема-сдачи смен',
          'Книга учета нарядов',
          'Маршрутный лист',
        ],
        correctAnswers: ['Журнал приема-сдачи смен'],
      },
      {
        code: '63673175',
        text:
          'Какой должна быть скорость движения поездов на железнодорожных путях объекта открытых горных работ?',
        answers: [
          'Не более 20 км/ч',
          'Не более 30 км/ч',
          'Не более 40 км/ч',
          'Скорость устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий',
        ],
        correctAnswers: [
          'Скорость устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий',
        ],
      },
      {
        code: '63673176',
        text:
          'Когда могут быть допущены работники шахты на место проведенного массового взрыва?',
        answers: [
          'Только после приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 2 часа после взрыва',
          'Только после восстановления нормальной рудничной атмосферы и проверки начальником участка состояния действующих выработо',
          'После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва',
        ],
        correctAnswers: [
          'После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва',
        ],
      },
      {
        code: '63673177',
        text:
          'Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
          'Из структурных карт, характерных разрезов, проекций, схем',
          'Из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63673178',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63673179',
        text:
          'Каким должно быть расстояние между двумя одновременно работающими гидромониторами?',
        answers: [
          'Больше дальности максимального полета струи любого из них',
          'Равным дальности максимального полета струи любого из них',
          'Меньше дальности максимального полета струи любого из них',
        ],
        correctAnswers: [
          'Больше дальности максимального полета струи любого из них',
        ],
      },
      {
        code: '63673180',
        text:
          'На каких участках возможных деформаций выполняют закладку наблюдательных станций?',
        answers: [
          'На участках, обусловленных наличием слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал',
          'На участках, обусловленных наличием слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание, близкое к простиранию борта',
          'На всех перечисленных',
        ],
        correctAnswers: ['На всех перечисленных'],
      },
      {
        code: '63673181',
        text: 'В каких местах могут находиться участки для размещения отвалов?',
        answers: [
          'В местах, определенных после проведения инженерно-геологических и гидрогеологических изысканий',
          'Недалеко от площадей месторождений, подлежащих обработке открытым способом',
          'Только на ровных участках месторождений',
          'В местах, которые не подвержены влиянию грунтовых и дождевых вод',
        ],
        correctAnswers: [
          'В местах, определенных после проведения инженерно-геологических и гидрогеологических изысканий',
        ],
      },
      {
        code: '63673182',
        text:
          'Кем должен производиться прием в эксплуатацию горных, транспортных, дорожных машин, технологического оборудования после монтажа и капитального ремонта?',
        answers: [
          'Комиссией организации',
          'Начальником транспортного цеха',
          'Техническим руководителем организации',
          'Главным механиком',
        ],
        correctAnswers: ['Комиссией организации'],
      },
      {
        code: '63673183',
        text:
          'Какой должна быть минимальная ширина свободных проходов между пластинчатыми конвейерами?',
        answers: [
          'Не менее 0,75 м',
          'Не менее 1,2 м',
          'Не менее 1,1 м',
          'Не менее 1,8 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63673184',
        text:
          'Кем должна быть утверждена схема электроснабжения, нанесенная на план горных работ?',
        answers: [
          'Ответственным за электрохозяйство организации',
          'Техническим руководителем организации',
          'Представителем Ростехнадзора',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63673185',
        text:
          'Какой вид контроля должен осуществляться при добыче под действующими блоками карьера за оставленным целиком безопасности, рассчитанным проектной организацией?',
        answers: [
          'Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ',
          'Только оперативный контроль',
          'Только маркшейдерский контроль',
          'Визуальный осмотр в рамках производственного контроля',
        ],
        correctAnswers: [
          'Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ',
        ],
      },
      {
        code: '63673186',
        text:
          'С какой периодичностью должно производиться измерение сопротивления изоляции?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Ежесменно',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '63673187',
        text:
          'Какое из перечисленных требований безопасности к разработке месторождений драгами и плавучими земснарядами указано верно?',
        answers: [
          'Работа драги (земснаряда) должна вестись в соответствии с утвержденным начальником драги (земснаряда) паспортом забоя',
          'Опережающее драгирование (подработка нижней талой части забоя) допускается по решению начальника драги (земснаряда)',
          'Размеры опасной зоны устанавливаются техническим руководителем организации',
          'При наличии мерзлого слоя торфа (сезонной или многолетней мерзлоты) в забое разработка должна осуществляться после предварительной оттайки',
        ],
        correctAnswers: [
          'При наличии мерзлого слоя торфа (сезонной или многолетней мерзлоты) в забое разработка должна осуществляться после предварительной оттайки',
        ],
      },
      {
        code: '63673188',
        text:
          'Какие визуальные и инструментальные наблюдения должны проводиться на объектах ведения открытых горных работ?',
        answers: [
          'За состоянием бортов, уступов и откосов',
          'За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров',
          'За закарстованностью месторождения',
          'За проявлениями удароопасности горных пород, вмещающих полезное ископаемое',
        ],
        correctAnswers: ['За состоянием бортов, уступов и откосов'],
      },
      {
        code: '63673189',
        text:
          'Какими устройствами безопасности должны быть оборудованы цепные элеваторы?',
        answers: [
          'Ограничителями хода',
          'Тормозными устройствами, исключающими обратный ход кольцевой цепи, и ловителями при ее разрыве',
          'Предохранительными устройствами',
          'Специальными устройствами блокировки и аварийных остановок',
        ],
        correctAnswers: [
          'Тормозными устройствами, исключающими обратный ход кольцевой цепи, и ловителями при ее разрыве',
        ],
      },
      {
        code: '63673190',
        text:
          'С каким минимальным номинальным временем защитного действия допускается использование дыхательных аппаратов со сжатым воздухом на объектах открытых горных работ?',
        answers: ['Один час', 'Четыре часа', 'Два часа', 'Три часа'],
        correctAnswers: ['Один час'],
      },
      {
        code: '63673191',
        text:
          'Как должна производиться замена ламп и светильников, расположенных ниже осветительных проводов на деревянных опорах?',
        answers: [
          'При снятии напряжения с осветительной сети',
          'Без снятия напряжения с использованием электрозащитных средств',
          'При снятом с контактного провода напряжении',
        ],
        correctAnswers: ['При снятом с контактного провода напряжении'],
      },
      {
        code: '63673192',
        text:
          'В каком из перечисленных случаев (кроме аварийных случаев) допускается остановка объектов жизнеобеспечения (электростанций, водоотливов, калориферных установок и др.)?',
        answers: [
          'По разрешению главного механика организации, согласованному с руководителем организации',
          'По письменному разрешению технического руководителя эксплуатирующей организации',
          'По соответствующему распоряжению руководителя организации',
        ],
        correctAnswers: [
          'По письменному разрешению технического руководителя эксплуатирующей организации',
        ],
      },
      {
        code: '63673193',
        text:
          'Кем должны определяться границы опасных зон по прорывам воды и газов при комбинированной (совмещенной) разработке с неблагоприятными гидрогеологическими условиями, наличием затопленных горных выработок или других водных объектов, а также горных выработок, в которых возможны скопления ядовитых и горючих газов?',
        answers: [
          'Маркшейдерской и геологической службами предприятия с привлечением специализированных предприятий',
          'Геологической службой предприятия',
          'Маркшейдерской службой предприятия',
        ],
        correctAnswers: [
          'Маркшейдерской и геологической службами предприятия с привлечением специализированных предприятий',
        ],
      },
      {
        code: '63673194',
        text:
          'Кем осуществляется подготовка планов и схем развития горных работ?',
        answers: [
          'Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ',
          'Пользователем недр или проектной организацией',
          'Только пользователем недр',
        ],
        correctAnswers: [
          'Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ',
        ],
      },
      {
        code: '63673195',
        text:
          'Какой должна быть ширина рабочей бермы при бурении перфораторами и электросверлами?',
        answers: [
          'Не менее 6 м',
          'Не менее 4 м',
          'Не менее 3 м',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 4 м'],
      },
      {
        code: '63673196',
        text:
          'Каким образом машинист должен воспринимать каждый неправильно поданный или непонятный сигнал?',
        answers: ['"Вверх"', '"Стоп"', '"Вниз"', '"Назад"'],
        correctAnswers: ['"Стоп"'],
      },
      {
        code: '63673197',
        text:
          'Где должна находиться кабина экскаватора с ковшом вместимостью менее 5 м³ при работе?',
        answers: [
          'На любой стороне откоса уступа',
          'В стороне, противоположной откосу уступа',
          'Порядок работы определяется проектом',
          'На стороне откоса уступа',
        ],
        correctAnswers: ['В стороне, противоположной откосу уступа'],
      },
      {
        code: '63673198',
        text:
          'По какому документу разрешается перегон горных, транспортных и дорожных машин?',
        answers: [
          'По технологическим картам, утвержденным техническим руководителем',
          'По наряду-допуску на производство работ',
          'По проекту производства работ',
        ],
        correctAnswers: [
          'По технологическим картам, утвержденным техническим руководителем',
        ],
      },
      {
        code: '63673199',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны пройти инструктаж',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63673200',
        text:
          'Какое из перечисленных требований к тушению пожаров в вертикальных горных выработках указано верно?',
        answers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
          'Пожары в вертикальных горных выработках тушатся активным способом снизу вверх',
          'Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи',
          'При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола возможно изменение направления вентиляционной струи',
        ],
        correctAnswers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
        ],
      },
      {
        code: '63673201',
        text:
          'Кем устанавливается форма заявления о согласовании планов и (или) схем развития горных работ?',
        answers: [
          'Органом местного самоуправления',
          'Органом государственного горного надзора',
          'Пользователем недр',
          'Росреестром',
        ],
        correctAnswers: ['Органом государственного горного надзора'],
      },
      {
        code: '63673202',
        text:
          'Что при ведении горных работ является объектами открытых горных работ?',
        answers: [
          'Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы',
          'Карьеры, прииски, гидроотвалы',
          'Объекты кучного выщелачивания',
          'Дражные полигоны, шлаковые отвалы',
        ],
        correctAnswers: [
          'Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы',
        ],
      },
      {
        code: '63673203',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
        ],
      },
      {
        code: '63673204',
        text:
          'С какой периодичностью механиком участка должны проводиться осмотры канатов подвески стрелы одноковшового экскаватора?',
        answers: [
          'Не реже 1 раза в смену',
          'Не реже 1 раза в 3 суток',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в 10 дней',
        ],
        correctAnswers: ['Не реже 1 раза в неделю'],
      },
      {
        code: '63673205',
        text:
          'Каким образом доводятся до специалистов и горнорабочих дополнительные меры безопасности перед производством массового взрыва?',
        answers: [
          'Командиром профессиональной аварийно-спасательной службы (формирования) - под подпись в журнале инструктажа',
          'Начальником отдела промышленной безопасности в устной форме',
          'Руководителем карьера - под подпись в журнале взрывных работ',
          'Приказом на каждый взрыв - под подпись',
        ],
        correctAnswers: ['Приказом на каждый взрыв - под подпись'],
      },
      {
        code: '63673206',
        text:
          'Какое должно быть минимальное расстояние между камнерезными машинами при одновременной работе двух и более камнерезных машин на одном рельсовом пути?',
        answers: ['15 м', '5 м', '20 м', '10 м'],
        correctAnswers: ['15 м'],
      },
      {
        code: '63673207',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?',
        answers: [
          'Отсутствие обоснования соблюдения условий безопасного недропользования',
          'Отсутствие геологического и маркшейдерского обеспечения горных работ',
          'Выявление недостоверных сведений в представленных документах',
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
        correctAnswers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63673208',
        text:
          'Какое из перечисленных требований к водоотливной установке указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'В дренажных шахтах водосборник должен иметь одно отделение',
          'Вместимость водосборника при открытом водоотливе должна рассчитываться не менее чем на трехчасовой приток',
          'На стационарных водоотливных установках должно обеспечиваться автоматическое включение резервных насосов, взамен вышедших из строя',
          'Установка должна иметь резервные насосы с суммарной производительностью, равной 10% рабочих насосов',
        ],
        correctAnswers: [
          'Вместимость водосборника при открытом водоотливе должна рассчитываться не менее чем на трехчасовой приток',
          'На стационарных водоотливных установках должно обеспечиваться автоматическое включение резервных насосов, взамен вышедших из строя',
        ],
      },
      {
        code: '63673209',
        text:
          'Какие из документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Технологический регламент',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63673210',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Руководитель отдела промышленной безопасности',
          'Командир профессиональной аварийно-спасательной службы (формирования)',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63673211',
        text: 'Какие требования предъявляются к установке бурового станка?',
        answers: [
          'Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка',
          'Буровой станок должен быть установлен на спланированной площадке на любом расстоянии от верхней бровки',
          'Буровой станок должен быть установлен на спланированной площадке на расстоянии 5 м от верхней бровки',
        ],
        correctAnswers: [
          'Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка',
        ],
      },
      {
        code: '63673212',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
          'Все перечисленные требования',
          'Прошедшие обучение приемам и методам проведения работ',
          'Не моложе 18 лет',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63673213',
        text:
          'Каким должно быть расстояние от нижней бровки уступа и от верхней бровки уступа карьера до оси ближайшего железнодорожного пути?',
        answers: [
          'Не менее 2,5 м',
          'Не менее 4 м',
          'Не менее 1,5 м',
          'Не менее 3,5 м',
        ],
        correctAnswers: ['Не менее 2,5 м'],
      },
      {
        code: '63673214',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Разработать проект производства работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63673215',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Выполнение работ, не включенных в утвержденный перечень газоопасных работ запрещается',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63673216',
        text:
          'На каких электроустановках запрещается оперативное обслуживание без применения специальных защитных средств?',
        answers: [
          'Напряжением выше 380 В',
          'Напряжением выше 220 В',
          'Напряжением выше 1000 В',
          'Напряжением выше 660 В',
        ],
        correctAnswers: ['Напряжением выше 1000 В'],
      },
      {
        code: '63673217',
        text:
          'Что необходимо соблюдать при погашении уступов и постановке их в предельное положение?',
        answers: [
          'Общий угол откоса бортов, установленный проектом',
          'Угол естественного откоса пород, слагающих уступы',
          'Угол внутреннего трения пород, слагающих уступы',
        ],
        correctAnswers: ['Общий угол откоса бортов, установленный проектом'],
      },
      {
        code: '63673218',
        text:
          'С какой периодичностью должна проверяться исправность действия (срабатывания) реле утечки тока в передвижных электроустановках до 1000 В?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 10 дней',
          'Не реже 1 раза в неделю',
          'Ежесменно перед началом работы',
        ],
        correctAnswers: ['Ежесменно перед началом работы'],
      },
      {
        code: '63673219',
        text:
          'Кто допускается к техническому руководству работами на объектах ведения открытых горных работ и переработки полезных ископаемых?',
        answers: [
          'Лица, имеющие высшее техническое образование и аттестованные в области промышленной безопасности',
          'Лица, имеющие среднее образование и опыт работы в горном производстве не менее 3 лет',
          'Лица, имеющие высшее или среднее специальное соответствующее образование',
          'Лица, имеющие среднее техническое образование и опыт руководящей работы',
        ],
        correctAnswers: [
          'Лица, имеющие высшее или среднее специальное соответствующее образование',
        ],
      },
      {
        code: '63673220',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'С руководителями аварийно-спасательных служб',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'С руководителями службы производственного контроля',
          'С руководителями службы охраны труда и санитарными службами',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63673221',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных ПЛА?',
        answers: [
          'Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок',
          'Руководитель энергомеханической службы ОПО',
          'Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
          'Начальник участка, на котором произошла авария',
        ],
        correctAnswers: ['Руководитель энергомеханической службы ОПО'],
      },
      {
        code: '63673222',
        text:
          'В какой период осуществляется рассмотрение планов развития горных работ?',
        answers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 августа по 25 ноября года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 октября по 25 января года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 19 сентября по 30 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
        correctAnswers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
      },
      {
        code: '63673223',
        text:
          'Какое из перечисленных требований к гидромониторному размыву указано неверно?',
        answers: [
          'Территория участка на расстоянии не менее 1,5-кратной дальности действия струи гидромонитора должна быть ограждена предупреждающими знаками',
          'Перед началом работы гидромонитора из зоны действия его струи должен быть удален персонал',
          'Запрещается оставлять без надзора работающий гидромонитор',
          'Высота уступа при гидромониторном размыве при разработке уступов с меловыми отложениями не должна превышать 30 м',
        ],
        correctAnswers: [
          'Высота уступа при гидромониторном размыве при разработке уступов с меловыми отложениями не должна превышать 30 м',
        ],
      },
      {
        code: '63673224',
        text: 'При какой неисправности запрещается использовать вагоны?',
        answers: [
          'Только при неисправности автосцепки поглощающего аппарата или тягового хомута автосцепного устройства',
          'Только при неисправности надрессорной балки или поперечной связи с изломом',
          'Только при неисправности колесной пары или буксы, требующих замены',
          'Только при неисправности кузова, а также запорного люка у полувагона',
          'При наличии любой неисправности из перечисленных',
        ],
        correctAnswers: ['При наличии любой неисправности из перечисленных'],
      },
      {
        code: '63673225',
        text:
          'Какую минимальную квалификационную группу по электробезопасности должен иметь руководитель, ответственный за безопасность работ, в каждой группе работников контактной сети, находящейся под напряжением?',
        answers: [
          'IV квалификационную группу по электробезопасности',
          'V квалификационную группу по электробезопасности',
          'III квалификационную группу по электробезопасности',
          'Не регламентируется',
        ],
        correctAnswers: ['IV квалификационную группу по электробезопасности'],
      },
      {
        code: '63673226',
        text:
          'Каково допустимое количество хранения на рабочих местах горюче-смазочных и обтирочных материалов?',
        answers: [
          'Не более трехсуточной потребности каждого материала',
          'Не более суточной потребности каждого материала',
          'Не более месячной потребности каждого материала',
          'Не нормируется',
        ],
        correctAnswers: ['Не более трехсуточной потребности каждого материала'],
      },
      {
        code: '63673227',
        text:
          'С чьего разрешения должен осуществляться прием груженых поездов для разгрузки породы в отвал после каждой передвижки отвального пути?',
        answers: [
          'Технического руководителя смены',
          'Технического руководителя организации',
          'Руководителя организации',
          'Начальника транспортного цеха',
        ],
        correctAnswers: ['Технического руководителя смены'],
      },
      {
        code: '63673228',
        text:
          'Каким требованиям должны соответствовать светильники с лампами накаливания, устанавливаемые в помещениях с повышенной опасностью ниже 2,5 м от пола?',
        answers: [
          'Должны иметь защиту не ниже IP 44',
          'Должны быть специальной конструкции либо быть рассчитаны на напряжение не выше 50 В с заземлением металлической арматуры',
          'Должны обеспечивать освещенность не менее 300 лк',
        ],
        correctAnswers: [
          'Должны быть специальной конструкции либо быть рассчитаны на напряжение не выше 50 В с заземлением металлической арматуры',
        ],
      },
      {
        code: '63673229',
        text:
          'Какой документ выдает ответственное лицо за выпуск горнотранспортного оборудования на линию, после проверки его технического состояния, водителям (операторам)?',
        answers: [
          'Путевые листы',
          'Бортовой журнал',
          'Акт-допуск',
          'Наряд-допуск',
        ],
        correctAnswers: ['Путевые листы'],
      },
      {
        code: '63673230',
        text:
          'С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и Ростехнадзором',
          'С Ростехнадзором',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63673231',
        text:
          'Что должно входить в состав планов и схем развития горных работ?',
        answers: [
          'Графическая часть и пояснительная записка с табличными материалами',
          'Фотографии мест дальнейшего производства работ',
          'Список с указанием паспортных данных каждого работника',
          'Наряд-допуск на выполнение работ',
        ],
        correctAnswers: [
          'Графическая часть и пояснительная записка с табличными материалами',
        ],
      },
      {
        code: '63673232',
        text:
          'В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?',
        answers: [
          'В отчет о проведенных работах',
          'В планы развития горных работ на предстоящий календарный период',
          'В пояснительную записку с фотографией деформации',
          'В журнал осмотра',
        ],
        correctAnswers: [
          'В планы развития горных работ на предстоящий календарный период',
        ],
      },
      {
        code: '63673233',
        text:
          'Кому должен сообщать работник при обнаружении опасности, угрожающей людям, производственным объектам?',
        answers: [
          'Техническому руководителю смены',
          'Руководителю шахты',
          'Главному инженеру шахты',
          'Главному механику шахты',
        ],
        correctAnswers: ['Техническому руководителю смены'],
      },
      {
        code: '63673234',
        text:
          'В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В течение 15 дней с назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В назначенный день рассмотрения планов и (или) схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
      },
      {
        code: '63673235',
        text:
          'Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано верно?',
        answers: [
          'Оперативный план должен содержать ссылки на пункты ранее разработанных оперативных планов',
          'Оперативный план разрабатывается на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии',
          'Оперативные планы разрабатываются после окончания ведения горноспасательных работ',
          'Последующие оперативные планы (№ 2, 3 и т. д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана не реализованы',
        ],
        correctAnswers: [
          'Оперативный план разрабатывается на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии',
        ],
      },
      {
        code: '63673236',
        text:
          'Кем устанавливаются размеры призмы обрушения (сползания) породы при отвалообразовании, в пределах которой не должны разгружаться автомобили и другие транспортные средства?',
        answers: [
          'Проектной организацией при разработке проекта на отвал',
          'Лицами сменного надзора',
          'Техническим руководителем организации',
          'Работниками маркшейдерской службы',
        ],
        correctAnswers: ['Работниками маркшейдерской службы'],
      },
      {
        code: '63673237',
        text: 'Кто имеет право на проезд в многоместных кабинах автомобилей?',
        answers: [
          'Лица, сопровождающие составы, а также сменный надзор и отдельные работники при наличии у них письменного разрешения технического руководителя',
          'Любой работник карьера при наличии у него письменного разрешения технического руководителя',
          'Только представители надзорных органов',
        ],
        correctAnswers: [
          'Лица, сопровождающие составы, а также сменный надзор и отдельные работники при наличии у них письменного разрешения технического руководителя',
        ],
      },
      {
        code: '63673238',
        text:
          'Какое напряжение должно применяться для питания ручных переносных ламп, используемых на объектах открытых горных работ?',
        answers: [
          'Линейное напряжение - не выше 36 В переменного тока и до 50 В постоянного тока, а при тепловозной тяге - до 75 В постоянного тока',
          'Не выше 42 В переменного тока и до 75 В постоянного тока',
          'Не выше 42 В переменного тока и до 127 В постоянного тока',
          'Не выше 220 В переменного тока',
        ],
        correctAnswers: [
          'Линейное напряжение - не выше 36 В переменного тока и до 50 В постоянного тока, а при тепловозной тяге - до 75 В постоянного тока',
        ],
      },
      {
        code: '63673239',
        text:
          'Что из перечисленного не входит в обязательную комплектацию карьерных автомобилей во время их нахождения на линии?',
        answers: [
          'Упоры (башмаки) для подкладывания под колеса',
          'Проблесковый маячок желтого цвета',
          'Знак аварийной остановки',
          'Медицинская аптечка',
        ],
        correctAnswers: ['Проблесковый маячок желтого цвета'],
      },
      {
        code: '63673240',
        text:
          'В каком из перечисленных случаев разрешается допуск людей в район взрыва при проведении открытых горных работ?',
        answers: [
          'После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований, но не ранее чем через 30 минут после взрыва',
          'После рассеивания пылевого облака',
          'После проверки состояния уступов',
          'После полного восстановления видимости в карьере',
        ],
        correctAnswers: [
          'После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований, но не ранее чем через 30 минут после взрыва',
        ],
      },
      {
        code: '63673241',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 15 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20 % объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
    ],
    63675: [
      {
        code: '63675000',
        text:
          'Каково минимально допустимое расстояние от зоны действующих подземных коммуникаций до рабочей зоны землеройной техники?',
        answers: [
          '1 м по горизонтали или 2 м по вертикали',
          '1,5 м по горизонтали или 1 м по вертикали',
          '2 м по горизонтали или 0,5 м по вертикали',
          '2 м по горизонтали или 1 м по вертикали',
        ],
        correctAnswers: ['2 м по горизонтали или 1 м по вертикали'],
      },
      {
        code: '63675001',
        text:
          'На какие виды могут подразделяться прогнозы удароопасности участков массива горных пород? Выберите два правильных варианта ответа.',
        answers: [
          'Региональный прогноз',
          'Федеральный прогноз',
          'Локальный прогноз',
          'Местный прогноз',
        ],
        correctAnswers: ['Региональный прогноз', 'Локальный прогноз'],
      },
      {
        code: '63675002',
        text:
          'Каким из перечисленных прав не обладает комиссия по горным ударам?',
        answers: [
          'Останавливать горные работы, если нарушены требования Правил и Указаний, касающиеся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
          'Приглашать на заседания Комиссии представителей организаций, работающих по проблеме борьбы с горными ударами',
          'Получать от руководителей объектов необходимую документацию по описанию мер по борьбе с горными ударами',
          'Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
        ],
        correctAnswers: [
          'Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
        ],
      },
      {
        code: '63675003',
        text:
          'Кто определяет места возведения изоляционных перемычек (ИП), предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?',
        answers: [
          'Руководитель ликвидации аварии (РЛА)',
          'Руководитель горноспасательных работ (РГСР)',
          'Руководитель опасного производственного объекта (ОПО)',
          'Лицо, ответственное за пожарную безопасность ОПО',
        ],
        correctAnswers: ['Руководитель ликвидации аварии (РЛА)'],
      },
      {
        code: '63675004',
        text:
          'Что из перечисленного входит в должностные обязанности руководителя горноспасательных работ (РГСР)?',
        answers: [
          'Только получение заданий от РЛА на проведение горноспасательных работ',
          'Только проведение анализа полученной от РЛА информации о происшедшей аварии',
          'Только организация ведения оперативного журнала ПАСС(Ф)',
          'Только организация ведения горноспасательных работ',
          'Только обеспечение прибытия горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675005',
        text:
          'При отсутствии проектных регламентов кем могут разрабатываться регламенты на все основные и вспомогательные производственные процессы при ведении горных работ подземным способом?',
        answers: [
          'Строительно-монтажной организацией',
          'Организацией, проводящей экспертизу промышленной безопасности опасного производственного объекта',
          'Эксплуатирующей организацией',
          'Специальной комиссией во главе с инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63675006',
        text:
          'Какова минимально допустимая температура при ведении горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?',
        answers: ['Минус 20 °C', 'Минус 15 °C', 'Минус 10 °C', 'Минус 8 °C'],
        correctAnswers: ['Минус 20 °C'],
      },
      {
        code: '63675007',
        text:
          'Какие требования предъявляются к расположению на поверхности воздухозаборов вентиляторных установок?',
        answers: [
          'Должны устанавливаться не ближе 25 м от мест хранения и использования горючих и легковоспламеняющихся материалов',
          'Должны устанавливаться не ближе 10 м от ствола шахты',
          'Должны устанавливаться в зонах, не загрязненных пылью, дымом и не ближе 8 м от ствола',
          'Должны устанавливаться вне зоны загазованности на расстоянии более 15 м от проезжей части автомобильных дорог',
        ],
        correctAnswers: [
          'Должны устанавливаться не ближе 25 м от мест хранения и использования горючих и легковоспламеняющихся материалов',
        ],
      },
      {
        code: '63675008',
        text:
          'Каким должно быть расстояние между стенками стволов при строительстве объекта подземного строительства на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Не менее 50 м',
          'Не менее 45 м',
          'Не менее 35 м',
          'Не менее 25 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63675009',
        text:
          'По прошествии какого времени после взрыва может осуществляться допуск постов профессиональной аварийно-спасательной службы (формирования) в пределы опасной зоны?',
        answers: ['15 минут', '20 минут', '45 минут', '30 минут'],
        correctAnswers: ['15 минут'],
      },
      {
        code: '63675010',
        text:
          'Что из перечисленного должен предусматривать раздел "Мероприятия по обеспечению пожарной безопасности" в проектной документации?',
        answers: [
          'Только организационные и технические мероприятия по предотвращению возможности пожара',
          'Только мероприятия по локализации и тушению пожара в начальной стадии его возникновения во всех технологических процессах при эксплуатации горно-шахтного оборудования',
          'Только мероприятия по локализации и тушению пожара в начальной стадии его возникновения во всех технологических процессах в случаях проведения ремонтов и в аварийных ситуациях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675011',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?',
        answers: [
          'Руководитель энергомеханической службы ОПО',
          'Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок',
          'Начальник участка, на котором произошла авария',
          'Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
        ],
        correctAnswers: ['Руководитель энергомеханической службы ОПО'],
      },
      {
        code: '63675012',
        text:
          'Каким должно быть расстояние между разъединителями контактной сети?',
        answers: [
          'Не более 500 м',
          'Не более 350 м',
          'Не более 300 м',
          'Не более 250 м',
        ],
        correctAnswers: ['Не более 250 м'],
      },
      {
        code: '63675013',
        text:
          'На каком расстоянии от места производства работ на откаточных путях должны быть установлены сигнальные знаки?',
        answers: [
          'Не менее 30 м от места производства работ',
          'Не менее 40 м от места производства работ',
          'Не менее 60 м от места производства работ',
          'Не менее 80 м от места производства работ',
        ],
        correctAnswers: ['Не менее 80 м от места производства работ'],
      },
      {
        code: '63675014',
        text:
          'Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?',
        answers: [
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
          'Технический руководитель эксплуатирующей объект ведения горных работ организации',
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
        ],
        correctAnswers: [
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
      },
      {
        code: '63675015',
        text:
          'При какой глубине проходки ствола разрешается установка подвесных лестниц?',
        answers: [
          'Не более 15 м, выход лестничного отделения на поверхность должен оборудоваться предохранительной будкой',
          'Не более 20 м с закреплением лестниц в крепи ствола, вход лестничного отделения должен оборудоваться предохранительной будкой',
          'Не более 25 м с закреплением лестниц в крепи ствола и установкой дуг безопасности',
          'Не более 30 м с закреплением лестниц в крепи ствола и установкой дуг безопасности',
        ],
        correctAnswers: [
          'Не более 15 м, выход лестничного отделения на поверхность должен оборудоваться предохранительной будкой',
        ],
      },
      {
        code: '63675016',
        text:
          'Что из перечисленного следует отражать в регламентах технологических процессов для безопасного выполнения работ для каждого производственного процесса в организациях (на предприятиях)?',
        answers: [
          'Специфику и особенности организации выполнения основных и вспомогательных производственных процессов на каждой шахте или подземном руднике',
          'Мероприятия по обеспечению локализации и ликвидации аварий и их последствий',
          'Мероприятия по обеспечению пожарной безопасности',
          'Мероприятия по обеспечению прогнозирования возникновения и развития аварий, инцидентов, случаев травматизма',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675017',
        text:
          'Какие участки относятся к наиболее нагруженным на месторождениях, склонных к горным ударам?',
        answers: [
          'Опасные зоны, выявленные в результате регионального прогноза',
          'Участки рудного массива и массива горных пород в зоне опорного давления от очистных работ',
          'Участки рудного массива и массива горных пород с дизъюнктивными и пликативными геологическими нарушениями',
          'Сопряжения выработок и передовые выработки',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63675018',
        text:
          'Каким образом участки горных выработок категории "Опасно" приводят в неудароопасное состояние?',
        answers: [
          'Путем создания в краевой части массива пород защитной зоны шириной n+1 м, но не более 2 м',
          'Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м',
          'Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м',
          'Путем создания в краевой части массива пород защитной зоны шириной не более 1 м',
        ],
        correctAnswers: [
          'Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м',
        ],
      },
      {
        code: '63675019',
        text:
          'Какое максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?',
        answers: ['0,2 МПа', '0,1 МПа', '0,3 МПа', '0,4 МПа'],
        correctAnswers: ['0,2 МПа'],
      },
      {
        code: '63675020',
        text:
          'Какую защиту должны иметь трансформаторы подземных электрических сетей до 1000 В?',
        answers: [
          'От токов короткого замыкания, от перегрузки, нулевую',
          'От блуждающих токов',
          'От токов короткого замыкания',
          'От опасных токов утечки на землю',
        ],
        correctAnswers: ['От токов короткого замыкания'],
      },
      {
        code: '63675021',
        text:
          'Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?',
        answers: [
          'Геомеханическими методами',
          'Геофизическими методами',
          'Всеми перечисленными',
          'Визуальными наблюдениями за разрушением приконтурного массива выработок',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63675022',
        text:
          'На каком расстоянии в выработках, где подвешен контактный провод, должны быть вывешены светящиеся надписи, предупреждающие, что провод под напряжением?',
        answers: [
          'Через каждые 500 м',
          'Через каждые 350 м, а также на пересечениях с другими выработками',
          'Через каждые 250 м',
          'Через каждые 100 м, а также на пересечениях с другими выработками',
        ],
        correctAnswers: [
          'Через каждые 100 м, а также на пересечениях с другими выработками',
        ],
      },
      {
        code: '63675023',
        text:
          'В каких подземных горных выработках запрещается тушение пожара водой?',
        answers: [
          'В выработках угольных шахт',
          'В выработках асбестовых рудников',
          'В выработках соляных и калийных рудников',
        ],
        correctAnswers: ['В выработках соляных и калийных рудников'],
      },
      {
        code: '63675024',
        text:
          'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Выберите два правильных варианта ответа.',
        answers: [
          'Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи',
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          'В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
      },
      {
        code: '63675025',
        text:
          'В каком случае нарушены требования безопасности при проходке стволов?',
        answers: [
          'Пересечение выработок должно осуществляться под прямым или близким к нему углом. Очередность проведения выработок регламентируется Указаниями',
          'Образующийся при сбойке целик должен быть приведен при категории "Опасно" в неопасное состояние по всей площади',
          'При проходке стволов допускается отставание постоянной крепи от забоя не более одного диаметра ствола вчерне',
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями',
        ],
        correctAnswers: [
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями',
        ],
      },
      {
        code: '63675026',
        text:
          'Каково максимально допустимое расстояние откатки загруженных вагонеток в призабойной зоне с помощью погрузочных машин, но не более пределов технологического комплекса укладчика или щита?',
        answers: ['25 м', '27 м', '30 м', '34 м'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63675027',
        text:
          'Какие электрические сети разрешается применять для контактной электровозной откатки?',
        answers: [
          'Постоянного тока напряжением до 300 В включительно',
          'Трехфазные переменного тока с изолированной нейтралью линейным напряжением до 1000 В включительно',
          'Переменного тока напряжением до 1000 В включительно',
          'Переменного тока напряжением до 42 В включительно',
        ],
        correctAnswers: ['Постоянного тока напряжением до 300 В включительно'],
      },
      {
        code: '63675028',
        text: 'Кем в организации утверждается перечень опасных зон?',
        answers: [
          'Главным маркшейдером',
          'Главным инженером организации',
          'Начальником службы производственного контроля',
          'Руководителем организации',
        ],
        correctAnswers: ['Главным инженером организации'],
      },
      {
        code: '63675029',
        text:
          'Каково минимально допустимое расстояние на рабочих проходах между машинами и аппаратами и от стен до габаритов оборудования в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?',
        answers: ['1,0 м', '0,9 м', '0,8 м', '1,3 м'],
        correctAnswers: ['1,0 м'],
      },
      {
        code: '63675030',
        text:
          'Какое из перечисленных требований не допускается при комбинированной разработке месторождений?',
        answers: [
          'Добыча руды подземным способом из проектных контуров карьера',
          'Расположение фронта ведения горных работ в направлении от массива к карьеру при подземных очистных работах',
          'Расположение фронта ведения горных работ в направлении навстречу фронту развития подземных очистных работ при открытых работах',
        ],
        correctAnswers: [
          'Добыча руды подземным способом из проектных контуров карьера',
        ],
      },
      {
        code: '63675031',
        text:
          'При каких видах аварии необходимо предусматривать вызов служб МЧС России?',
        answers: [
          'При всех видах аварий, когда требуется оказание помощи людям, и для ведения работ, требующих соответствующего горноспасательного оборудования',
          'При пожарах в стволах, шурфах и других выработках, имеющих выход на поверхность',
          'При землетрясениях',
          'При пожарах в надшахтных зданиях и сооружениях',
        ],
        correctAnswers: ['При землетрясениях'],
      },
      {
        code: '63675032',
        text:
          'В каком случае нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?',
        answers: [
          'Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера',
          'Допускается частичная отработка барьерных целиков без предварительного отвода воды по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией',
          'Вода из карьера в подземные выработки должна поступать самотеком через дренажные скважины с обязательным применением забивных или сквозных фильтров',
          'Горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) должны производиться только после спуска воды из затопленных выработок',
        ],
        correctAnswers: [
          'Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера',
        ],
      },
      {
        code: '63675033',
        text:
          'В каком случае нарушены требования к системам вентиляции при комбинированной (совмещенной) разработке месторождения?',
        answers: [
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
          'Вспомогательные нагнетательные вентиляторы должны быть максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках',
          'При комбинированной (совмещенной) разработке месторождения и наличии аэродинамических связей подземных выработок с карьерным пространством предусматривается нагнетательный способ общерудничной системы проветривания',
          'Способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ',
        ],
        correctAnswers: [
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
        ],
      },
      {
        code: '63675034',
        text: 'Кем прорабатывается план ликвидации аварий под расписку?',
        answers: [
          'Главным инженером шахты с инспектором Ростехнадзора',
          'Специализированной сторонней организацией',
          'Главным инженером шахты с командным составом ВГСЧ',
          'Главным инженером шахты с должностными лицами (техническим надзором) шахты',
        ],
        correctAnswers: [
          'Главным инженером шахты с должностными лицами (техническим надзором) шахты',
        ],
      },
      {
        code: '63675035',
        text:
          'Какой должна быть продолжительность холостого хода предохранительного тормоза подъемной машины?',
        answers: [
          'Не более 0,5 с',
          'Не более 0,8 с',
          'Не более 1 с',
          'Не более 1,5 с',
        ],
        correctAnswers: ['Не более 0,5 с'],
      },
      {
        code: '63675036',
        text:
          'Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды (ВГК) для ведения горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе в подземных горных выработках?',
        answers: [
          'Пять человек',
          'Два человека',
          'Три человека',
          'Шесть человек',
        ],
        correctAnswers: ['Пять человек'],
      },
      {
        code: '63675037',
        text:
          'Каково минимально допустимое расстояние движущихся транспортных средств от верхнего откоса котлована (траншеи) в случае увлажнения или выветривания откосов котлована и траншей, разрабатываемых без крепления?',
        answers: ['1,5 м', '2 м', '3 м', '3,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63675038',
        text:
          'Какое из перечисленных требований при тушении пожаров в вертикальных горных выработках указано верно?',
        answers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
          'Пожары в вертикальных горных выработках тушатся активным способом снизу вверх',
          'Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи',
          'При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола возможно изменение направления вентиляционной струи',
        ],
        correctAnswers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
        ],
      },
      {
        code: '63675039',
        text:
          'Барьером на какую высоту должен быть огражден лесоспуск на поверхности?',
        answers: ['1 м', '1,2 м', '1,5 м', '2 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63675040',
        text:
          'В каком из перечисленных случаев подземная горноспасательная база не организуется?',
        answers: [
          'В случае если все прибывшие на опасный производственный объект горноспасательные отделения привлечены к горноспасательным работам, определенным планом локализации и ликвидации аварии, связанным со спасением людей, застигнутых аварией',
          'В случае если горноспасательные работы проводятся в горных выработках с непригодной для дыхания рудничной атмосферой',
          'В случае если горноспасательные работы выполняются для ликвидации последствий прорыва воды (рассола) или обводненной горной массы при выполнении водолазных спусков и водолазных работ',
        ],
        correctAnswers: [
          'В случае если все прибывшие на опасный производственный объект горноспасательные отделения привлечены к горноспасательным работам, определенным планом локализации и ликвидации аварии, связанным со спасением людей, застигнутых аварией',
        ],
      },
      {
        code: '63675041',
        text:
          'На какие из перечисленных видов производственных процессов рекомендуется разрабатывать регламенты технологических процессов при ведении горных работ подземным способом?',
        answers: [
          'Только на проходку шахтных стволов и подземных горных выработок',
          'Только на ведение очистных работ, закладочных работ',
          'Только на эксплуатацию технологического транспорта',
          'Только на защиту шахты от затопления и охрану объектов на дневной поверхности от вредного влияния горных работ, противопожарную защиту шахты',
          'На все перечисленные виды производственных процессов',
        ],
        correctAnswers: [
          'На все перечисленные виды производственных процессов',
        ],
      },
      {
        code: '63675042',
        text: 'Сколько запасных выходов должно быть на действующей шахте?',
        answers: [
          'На каждой действующей шахте должно быть не менее двух отдельных выходов, обеспечивающих выезд (выход) людей на поверхность и имеющих разное направление вентиляционных струй. Каждый горизонт, этаж (подэтаж), блок должны иметь не менее двух отдельных выходов на смежные горизонты или к стволам',
          'На шахте должно быть не менее двух отдельных выходов, обеспечивающих выезд (выход) людей на поверхность и имеющих одно направление вентиляционных струй',
          'На каждой действующей шахте должен быть отдельный выход, обеспечивающий выезд (выход) людей на поверхность',
        ],
        correctAnswers: [
          'На каждой действующей шахте должно быть не менее двух отдельных выходов, обеспечивающих выезд (выход) людей на поверхность и имеющих разное направление вентиляционных струй. Каждый горизонт, этаж (подэтаж), блок должны иметь не менее двух отдельных выходов на смежные горизонты или к стволам',
        ],
      },
      {
        code: '63675043',
        text:
          'Какими службами организации с привлечением специализированных предприятий определяются границы опасных зон по прорывам воды и газов при комбинированной (совмещенной) разработке с неблагоприятными гидрогеологическими условиями, наличием затопленных горных выработок или других водных объектов, а также горных выработок, в которых возможны скопления ядовитых и горючих газов? Выберите два правильных варианта ответа.',
        answers: [
          'Маркшейдерской службой',
          'Геологической службой',
          'Технологической службой',
          'Службой аэрологической безопасности',
        ],
        correctAnswers: ['Маркшейдерской службой', 'Геологической службой'],
      },
      {
        code: '63675044',
        text:
          'Какое максимально допустимое значение провиса сетки, подвешенной к анкерам и очищенной от лежащей на ней породы?',
        answers: ['10 см', '20 см', '15 см', '17 см'],
        correctAnswers: ['20 см'],
      },
      {
        code: '63675045',
        text:
          'Какое требование безопасности при строительстве сооружений подземным способом изложено неверно?',
        answers: [
          'В слабых и неустойчивых грунтах отставание крепи от забоя не допускается',
          'Раскрытие тоннеля на полный профиль и монтаж обделки при укладке прорезных колец должны осуществляться в соответствии с проектом производства работ ППР (под руководством лица технического надзора)',
          'В неустойчивых породах, требующих поддержания массива непосредственно за разработкой породы, проходка выработок должна производиться с применением специальных способов закрепления грунтов или механизированных проходческих комплексов с закрытым забоем',
          'Разработка породы при проходке выработок должна производиться во всех случаях, начиная с нижней части забоя',
        ],
        correctAnswers: [
          'Разработка породы при проходке выработок должна производиться во всех случаях, начиная с нижней части забоя',
        ],
      },
      {
        code: '63675046',
        text:
          'Каково минимально допустимое значение прочности бетона на захватках свода, смежных с ранее забетонированными участками, для раскрытия калотты?',
        answers: [
          '70% проектной',
          '75% проектной',
          '80% проектной',
          '85% проектной',
        ],
        correctAnswers: ['70% проектной'],
      },
      {
        code: '63675047',
        text: 'Какой должна быть минимальная ширина переходных мостиков?',
        answers: ['1,2 м', '1,1 м', '1,5 м', '1,0 м', '0,9 м'],
        correctAnswers: ['1,0 м'],
      },
      {
        code: '63675048',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
        ],
      },
      {
        code: '63675049',
        text: 'Какова периодичность составления вентиляционных планов?',
        answers: [
          'Один раз в полгода',
          'Один раз в месяц',
          'Один раз в квартал',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в полгода'],
      },
      {
        code: '63675050',
        text:
          'В каком из перечисленных случаев в подземных горных выработках приостанавливаются горноспасательные работы и организуется вывод из зоны аварии людей, задействованных в данных работах?',
        answers: [
          'В случае, если концентрация водорода в зарядных камерах составляет 0,05%',
          'В случае, если при пожаре в местах ведения взрывных работ, в складах взрывчатых материалов и других местах их хранения, на транспортных средствах, перевозящих взрывчатые материалы, пожар был ликвидирован первичными средствами пожаротушения',
          'В случае если рудничная атмосфера на аварийном участке, в котором действует пожар, находится во взрывоопасном состоянии',
          'В случае если в зоне с температурой воздуха не более 25 °C при наличии непригодной для дыхания атмосферы температура воздуха в течение получаса повысилась на 1 °C и более',
        ],
        correctAnswers: [
          'В случае если рудничная атмосфера на аварийном участке, в котором действует пожар, находится во взрывоопасном состоянии',
        ],
      },
      {
        code: '63675051',
        text:
          'В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?',
        answers: [
          'В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
          'В случае, если продолжительность подачи инертного газа превышает допустимую',
          'В случае, если это определено оперативным планом',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
        ],
      },
      {
        code: '63675052',
        text:
          'Чье решение является обязательным для выполнения в случае разногласия между командиром ВГСЧ и ответственным руководителем работ при организации работ по ликвидации аварии?',
        answers: [
          'Ответственного руководителя работ по ликвидации аварий',
          'Вышестоящего руководства',
          'Экспертной комиссии',
          'Командира ВГСЧ',
        ],
        correctAnswers: [
          'Ответственного руководителя работ по ликвидации аварий',
        ],
      },
      {
        code: '63675053',
        text:
          'Кто утверждает технологический регламент, определяющий методы, последовательность операций и приемы безопасного выполнения работ по ликвидации зависания и запуску в работу дробилки?',
        answers: [
          'Руководитель предприятия',
          'Технический руководитель предприятия',
          'Представитель Ростехнадзора',
          'Лицо технического надзора предприятия',
        ],
        correctAnswers: ['Технический руководитель предприятия'],
      },
      {
        code: '63675054',
        text:
          'При какой минимальной глубине котлована спуск и подъем в котлован по лестницам не допускается, при условии, что расстояние между лестницами не превышает 40 м?',
        answers: ['25 м', '30 м', '40 м', 'Не нормируется'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63675055',
        text:
          'Каким документом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?',
        answers: [
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
          'Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора',
          'Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Распорядительным документом на основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы',
        ],
        correctAnswers: [
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
        ],
      },
      {
        code: '63675056',
        text:
          'В каком из перечисленных случаев в камерах электроустановок, расположенных в подземных выработках, должно быть два выхода?',
        answers: [
          'Если камера длиной более 10 м',
          'Если камера длиной более 5 м',
          'Если камера длиной более 8 м',
          'Если камера длиной более 7,5 м',
        ],
        correctAnswers: ['Если камера длиной более 10 м'],
      },
      {
        code: '63675057',
        text:
          'Допускается ли применение грузоподъемных кранов с лебедками для проходки шахтных стволов с целью спуска и подъема грузов?',
        answers: [
          'Допускается при глубине до 40 м',
          'Не допускается',
          'Допускается в любом случае',
        ],
        correctAnswers: ['Допускается при глубине до 40 м'],
      },
      {
        code: '63675058',
        text:
          'Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?',
        answers: [
          'Мониторинг концентрации индикаторных пожарных газов',
          'Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ',
          'Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест',
          'Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии',
        ],
        correctAnswers: ['Мониторинг концентрации индикаторных пожарных газов'],
      },
      {
        code: '63675059',
        text:
          'Когда могут быть допущены работники шахты на место проведенного массового взрыва?',
        answers: [
          'После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва',
          'Только после восстановления нормальной рудничной атмосферы и проверки начальником участка состояния действующих выработок',
          'Только после приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 2 часа после взрыва',
        ],
        correctAnswers: [
          'После восстановления нормальной рудничной атмосферы, приведения выработок в безопасное состояние и проверки аварийно-спасательным формированием, но не ранее чем через 8 часов после взрыва',
        ],
      },
      {
        code: '63675060',
        text:
          'Для каких объектов должен разрабатываться План мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только для тоннелей протяженностью более 2 км',
          'Только для выработок под реками или другими водными преградами',
          'Только для подземных объектов, сооружаемых в неустойчивых грунтах',
          'Для каждого строящегося подземного объекта',
        ],
        correctAnswers: ['Для каждого строящегося подземного объекта'],
      },
      {
        code: '63675061',
        text:
          'Какое из перечисленных требований к рудоспускам указано неверно?',
        answers: [
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
          'При отработке верхней части рудоспусков они должны быть заполнены до подошвы уступа',
          'При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее двух',
          'Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала',
        ],
        correctAnswers: [
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
        ],
      },
      {
        code: '63675062',
        text:
          'На каком расстоянии от поднимаемого груза должны быть расположены лебедки при монтаже и демонтаже обделки?',
        answers: [
          'Не ближе 3 м по горизонтали от поднимаемого груза',
          'Не ближе 5 м и не далее 18 м по горизонтали от поднимаемого груза',
          'Не далее 20 м по горизонтали от поднимаемого груза',
          'Не ближе 5 м и не далее 15 м по горизонтали от поднимаемого груза',
        ],
        correctAnswers: [
          'Не ближе 5 м и не далее 15 м по горизонтали от поднимаемого груза',
        ],
      },
      {
        code: '63675063',
        text:
          'При проектировании, строительстве и эксплуатации горнодобывающих предприятий с комбинированной (совмещенной) разработкой месторождения должен учитываться ряд основных факторов, определяющих специфические условия и представляющих опасность при ведении горных работ. Относится ли к основным факторам вид полезного ископаемого разрабатываемого месторождения?',
        answers: [
          'Относится, если имеется вероятность возникновения пожаров при совмещенной разработке месторождений полезных ископаемых, склонных к самовозгоранию',
          'Относится',
          'Не относится, если отсутствует склонность месторождения или массива горных пород или их части к горным ударам',
          'Не относится',
        ],
        correctAnswers: ['Не относится'],
      },
      {
        code: '63675064',
        text: 'Чем из перечисленного оснащается командный пункт (КП)?',
        answers: [
          'Только аппаратурой телефонной и (или) радиосвязи с местами ведения работ по локализации и ликвидации последствий аварии',
          'Только электронно-вычислительными машинами',
          'Только множительной и копировальной техникой',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63675065',
        text:
          'Какое допускается максимальное отклонение от нулевого кислородного баланса при производстве массовых взрывов при комбинированной (совмещенной) разработке рудных и нерудных месторождений полезных ископаемых?',
        answers: ['25%', '30%', '35%', '10%'],
        correctAnswers: ['25%'],
      },
      {
        code: '63675066',
        text:
          'В каких случаях в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (рассола, пульпы), работы по поиску и спасению людей не проводятся или прекращаются?',
        answers: [
          'Только если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного десятикратному сроку времени защитного действия СИЗОД',
          'Только если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более',
          'Только если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее отсутствует запасной выход',
          'Только если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63675067',
        text:
          'Какие требования безопасности при приемке руды и шихтовых материалов указаны неверно?',
        answers: [
          'Перед корпусом (отделением) приема руды устанавливается светофор, разрешающий или запрещающий подачу составов (самосвалов, скипов) на приемную площадку',
          'В отдельных случаях разгрузка осуществляется по разрешающим сигналам светофоров, сблокированных со шлагбаумом и установленных перед бункером',
          'Рабочие площадки приемных и разгрузочных устройств и бункеров оборудуются звуковой и световой сигнализацией для оповещения обслуживающего персонала о прибытии железнодорожных составов',
          'Сигналы подаются за 0,5 минут до момента прибытия железнодорожных составов, начала работы скиповых подъемников и канатных дорог',
        ],
        correctAnswers: [
          'Сигналы подаются за 0,5 минут до момента прибытия железнодорожных составов, начала работы скиповых подъемников и канатных дорог',
        ],
      },
      {
        code: '63675068',
        text:
          'Какие требования установлены Инструкцией по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы, к лицам, допускаемым к руководству работами по локализации и ликвидации последствий аварии и горноспасательными работами?',
        answers: [
          'Наличие высшего образования по направлению подготовки (специальности) "Техносферная безопасность"',
          'Наличие стажа работы не менее 1 года в организации, эксплуатирующей опасные производственные объекты, на которых ведутся горные работы',
          'Возраст не менее 21 года, отсутствие медицинских противопоказаний для данного вида работ, наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Прикладная геодезия", прохождение стажировки сроком не менее 3 месяцев',
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
        correctAnswers: [
          'Наличие высшего или среднего профессионального образования по направлению подготовки (специальности) "Горное дело" и прохождение обучения по программам подготовки руководителя работ по ликвидации аварий и руководителя горноспасательных работ соответственно',
        ],
      },
      {
        code: '63675069',
        text:
          'Кабели какого вида и с какой изоляцией должны применяться для прокладки по вертикальным и наклонным выработкам?',
        answers: [
          'Бронированные кабели в свинцовой, алюминиевой или поливинилхлоридной оболочке',
          'Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции',
          'Кабели с резиновой оболочкой',
          'Бронированные кабели в поливинилхлоридной оболочке',
        ],
        correctAnswers: [
          'Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции',
        ],
      },
      {
        code: '63675070',
        text:
          'В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?',
        answers: [
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Если данные отступления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам',
          'В соответствии с требованиями Правил безопасности при ведении горных работ и переработке твердых полезных ископаемых',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: [
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63675071',
        text:
          'Чем обуславливаются основные факторы, представляющие опасность при комбинированной (совмещенной) разработке месторождений?',
        answers: [
          'Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки',
          'Степенью взаимного влияния',
          'Технологической взаимностью открытого способа разработки',
        ],
        correctAnswers: [
          'Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки',
        ],
      },
      {
        code: '63675072',
        text:
          'Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным горизонтальным выработкам высотой 1,8 - 2,0 м?',
        answers: ['60 м/мин', '45 м/мин', '30 м/мин', '70 м/мин'],
        correctAnswers: ['60 м/мин'],
      },
      {
        code: '63675073',
        text:
          'Что служит защитой электроприводов технологического оборудования при исчезновении или резком снижении напряжения питающей сети?',
        answers: [
          'Электрическая блокировка, исключающая самозапуск',
          'Защита от перегрузки',
          'Защита от колебаний нагрузки',
        ],
        correctAnswers: ['Электрическая блокировка, исключающая самозапуск'],
      },
      {
        code: '63675074',
        text:
          'Какова минимальная ширина прохода вокруг распределительных устройств в подземных центральных распределительных пунктах?',
        answers: ['0,5 м', '0,6 м', '0,7 м', '0,8 м'],
        correctAnswers: ['0,8 м'],
      },
      {
        code: '63675075',
        text:
          'В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?',
        answers: [
          'В планы развития горных работ на предстоящий календарный период',
          'В отчет о проведенных работах',
          'В пояснительную записку с фотографией деформации',
          'В журнал осмотра',
        ],
        correctAnswers: [
          'В планы развития горных работ на предстоящий календарный период',
        ],
      },
      {
        code: '63675076',
        text:
          'На какую глубину должно быть забетонировано устье траншеи до начала выполнения работ по ее разработке при выполнении работ методом "стена в грунте"?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 1,0 м',
          'Не менее 1,2 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63675077',
        text:
          'Каков максимально допустимый угол наклона лестниц к рабочим площадкам и механизмам, посещаемым 1 - 2 раза в смену?',
        answers: ['80°', '70°', '50°', '60°'],
        correctAnswers: ['60°'],
      },
      {
        code: '63675078',
        text:
          'Какими дверями должны оборудоваться клети, служащие для спуска и подъема людей?',
        answers: [
          'Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм',
          'Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм',
          'Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм',
          'Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм',
        ],
        correctAnswers: [
          'Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм',
        ],
      },
      {
        code: '63675079',
        text:
          'Каким должно быть отставание вентиляционных труб от забоя при проходке горизонтальных выработок при площади сечения забоя не более 16 м²?',
        answers: [
          'Не более 10 м',
          'Не более 15 м',
          'При площади сечения забоя не более 20 м2 не более 10 м, при площади сечения забоя более 20 м2 - не более 15 м',
        ],
        correctAnswers: ['Не более 10 м'],
      },
      {
        code: '63675080',
        text:
          'Какова минимально допустимая ширина лестниц в вертикальных выработках?',
        answers: ['0,6 м', '0,4 м', '0,7 м', '1 м'],
        correctAnswers: ['0,4 м'],
      },
      {
        code: '63675081',
        text: 'Где должны быть расположены выходы из мест ожидания?',
        answers: [
          'В непосредственной близости от ствола шахты',
          'В местах, определяемых расчетами',
          'В местах с благоприятными горно-геологическими условиями',
        ],
        correctAnswers: ['В непосредственной близости от ствола шахты'],
      },
      {
        code: '63675082',
        text:
          'Каков порядок допуска людей в район взрыва при проведении открытых горных работ?',
        answers: [
          'Только после проверки состояния уступов',
          'Не ранее чем через 30 минут после взрыва',
          'После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований',
          'Только после рассеивания пылевого облака',
          'Только после полного восстановления видимости',
        ],
        correctAnswers: [
          'После получения сообщения от аварийно-спасательной службы о снижении концентрации ядовитых продуктов взрыва в воздухе до предельно допустимых норм и выполнении всех перечисленных требований',
        ],
      },
      {
        code: '63675083',
        text:
          'Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных объектов с комбинированной (совмещенной) разработкой?',
        answers: [
          'Только наличие подземных пустот в границах и под дном карьера',
          'Только исключение прорывов в подземные горные выработки паводковых и ливневых вод',
          'При ведении горных работ должны учитываться все перечисленные факторы',
          'Только подработанность массивов горных пород, слагающих борта карьера',
        ],
        correctAnswers: [
          'При ведении горных работ должны учитываться все перечисленные факторы',
        ],
      },
      {
        code: '63675084',
        text:
          'Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?',
        answers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
          'Указаниями научно-исследовательского института',
          'Техническими условиями, разработанными специализированной организацией',
          'Локальным прогнозом удароопасности',
        ],
        correctAnswers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
        ],
      },
      {
        code: '63675085',
        text:
          'Какой запас прочности должны иметь канаты для спасательных лестниц, канаты для подвески полков, насосов, трубопроводов, проходческих агрегатов?',
        answers: [
          '9-кратный запас прочности',
          '8-кратный запас прочности',
          '7-кратный запас прочности',
          '6-кратный запас прочности',
        ],
        correctAnswers: ['6-кратный запас прочности'],
      },
      {
        code: '63675086',
        text:
          'Какова минимально допустимая высота ограждения загрузочного отверстия приемного бункера для ограничения движения задним ходом автомобилей?',
        answers: [
          '0,5 диаметра колеса автомобиля',
          '0,3 диаметра колеса автомобиля',
          '3/4 диаметра колеса автомобиля',
          'Не нормируется',
        ],
        correctAnswers: ['0,5 диаметра колеса автомобиля'],
      },
      {
        code: '63675087',
        text:
          'В каких случаях должен производиться контроль удароопасности в выработках?',
        answers: [
          'При проявлении внешних признаков удароопасности',
          'При изменениях горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'При изменениях горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63675088',
        text:
          'Какова минимально допустимая ширина свободного прохода для людей в горизонтальных выработках, где применяются рельсовые транспортные средства?',
        answers: ['0,7 м', '0,6 м', '0,5 м', '0,45 м'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63675089',
        text:
          'Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?',
        answers: [
          'Крутопадающих мощных рудных тел',
          'Наклонных рудных тел',
          'Горизонтальных рудных тел',
          'Пологих рудных тел',
        ],
        correctAnswers: ['Крутопадающих мощных рудных тел'],
      },
      {
        code: '63675090',
        text:
          'Какое напряжение должно применяться для питания передвижных приемников электроэнергии?',
        answers: ['Не выше 10 000 В', 'Не выше 7200 В', 'Не выше 3300 В'],
        correctAnswers: ['Не выше 3300 В'],
      },
      {
        code: '63675091',
        text:
          'Какова максимально допустимая продолжительность пребывания работников профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований (ПАСС(Ф)) и членов вспомогательной горноспасательной команды (ВКГ) установлена для их пребывания в непригодной для дыхания рудничной атмосфере с применением дыхательных аппаратов?',
        answers: ['Четыре часа', 'Три часа', 'Два часа', 'Пять часов'],
        correctAnswers: ['Четыре часа'],
      },
      {
        code: '63675092',
        text:
          'Куда запрещается устраивать выходы из восстающих выработок, оборудованных лестницами?',
        answers: [
          'Непосредственно на конвейерный штрек',
          'Непосредственно на откаточные выработки',
          'Непосредственно на околоствольный двор',
        ],
        correctAnswers: ['Непосредственно на откаточные выработки'],
      },
      {
        code: '63675093',
        text:
          'В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?',
        answers: [
          'Только в случае тушения пожара водой',
          'Не допускается ни в каком случае',
          'Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха',
          'Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород',
        ],
        correctAnswers: [
          'Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха',
        ],
      },
      {
        code: '63675094',
        text:
          'При какой глубине вертикальных стволов механический подъем в одном из них может отсутствовать, при условии наличия лестниц в обоих стволах?',
        answers: [
          'При глубине до 70 м',
          'При глубине до 80 м',
          'При глубине до 90 м',
          'При глубине до 100 м',
        ],
        correctAnswers: ['При глубине до 70 м'],
      },
      {
        code: '63675095',
        text:
          'Что является коэффициентом запаса устойчивости при детерминированном подходе?',
        answers: [
          'Допустимая вероятность развития деформаций, которые определяются проектной документацией с учетом размещения элементов инфраструктуры объекта недропользования',
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
          'Перенос действия этих сил во всех инженерных методах на потенциальную (наиболее напряженную) поверхность скольжения',
        ],
        correctAnswers: [
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
        ],
      },
      {
        code: '63675096',
        text:
          'Какова минимально допустимая ширина стационарных площадок для обслуживания запорной арматуры, расположенной на высоте более 1,5 м от уровня пола, не имеющей дистанционного управления?',
        answers: ['0,5 м', '0,6 м', '0,7 м', '0,8 м'],
        correctAnswers: ['0,8 м'],
      },
      {
        code: '63675097',
        text:
          'Какие мероприятия должна обеспечить организация, эксплуатирующая объект ведения открытых горных работ?',
        answers: [
          'Контроль за состоянием противодеформационных сооружений и выполнением мероприятий, предотвращающих нарушение устойчивости бортов, уступов, откосов',
          'Проведение визуальных и инструментальных наблюдений за состоянием бортов, уступов и откосов',
          'Контроль за соблюдением проектных параметров бортов и уступов карьера, разреза и откосов отвала',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63675098',
        text:
          'Какую длину должна иметь жесткая сцепка, применяемая для буксировки неисправных машин в подземных выработках?',
        answers: [
          'Не более 1 м',
          'Не более 1,5 м',
          'Не более 2 м',
          'Не более 2,5 м',
        ],
        correctAnswers: ['Не более 1 м'],
      },
      {
        code: '63675099',
        text:
          'В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?',
        answers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
          'Взрыв, пожар, внезапный выброс, горный удар, обрушение',
          'Взрыв, горный удар, обрушение, внезапный выброс, пожар',
          'Взрыв, горный удар, внезапный выброс, пожар, обрушение',
        ],
        correctAnswers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
        ],
      },
      {
        code: '63675100',
        text:
          'Какой предельный срок эксплуатации устанавливается для прицепных устройств бадей?',
        answers: [
          'Не более 2 лет',
          'Не более 3 лет',
          'Не более 4 лет',
          'Не более 5 лет',
        ],
        correctAnswers: ['Не более 2 лет'],
      },
      {
        code: '63675101',
        text:
          'Кто имеет право письменным приказом отстранить ответственного руководителя работ и принять руководство на себя или возложить его на другое лицо из числа должностных лиц шахты?',
        answers: [
          'Главный энергетик шахты',
          'Главный инженер организации (предприятия)',
          'Командир ВГСЧ',
          'Начальник шахты',
        ],
        correctAnswers: ['Главный инженер организации (предприятия)'],
      },
      {
        code: '63675102',
        text:
          'Каким должно быть расстояние от выхода из подземных выработок до места хранения пожароопасных веществ и материалов?',
        answers: [
          'Не менее 50 м',
          'Не менее 45 м',
          'Не менее 30 м',
          'Не менее 25 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63675103',
        text:
          'Каково минимально допустимое расстояние на рабочих проходах между стеной и машинами в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?',
        answers: ['0,8 м', '0,9 м', '0,7 м', '0,6 м'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63675104',
        text:
          'Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе на открытых горных выработках?',
        answers: [
          'Три человека',
          'Два человека',
          'Пять человек',
          'Шесть человек',
        ],
        correctAnswers: ['Три человека'],
      },
      {
        code: '63675105',
        text:
          'В каком из перечисленных положений требования по комплектации первичных средств пожаротушения указаны неверно?',
        answers: [
          'По каждому месту первичные средства пожаротушения должны быть укомплектованы не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,1 м3',
          'В камерах и забоях ящики с песком должны комплектоваться совковой лопатой',
          'При отдельных электроустановках (на участках выработок с негорючей крепью) допускается размещать по одному огнетушителю и иметь ящик с песком вместимостью 0,1 м3',
        ],
        correctAnswers: [
          'По каждому месту первичные средства пожаротушения должны быть укомплектованы не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,1 м3',
        ],
      },
      {
        code: '63675106',
        text:
          'Кем утверждается паспорт буро-взрывных работ для каждого рабочего уступа - выемочного блока на основе маркшейдерской документации?',
        answers: [
          'Техническим руководителем карьера',
          'Ответственным руководителем работ',
          'Специализированной организацией',
          'Руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем карьера'],
      },
      {
        code: '63675107',
        text:
          'Каково номинальное время защитного действия автономных изолирующих дыхательных аппаратов (ДА) со сжатым воздухом на объектах открытых горных работ?',
        answers: [
          'Не менее одного часа',
          'Не менее 50 минут',
          'Не менее 45 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее одного часа'],
      },
      {
        code: '63675108',
        text:
          'Каким должно быть количество воздуха, рассчитываемое по числу людей?',
        answers: [
          'Не менее 3 м3/мин',
          'Не менее 4 м3/мин',
          'Не менее 6 м3/мин',
        ],
        correctAnswers: ['Не менее 6 м3/мин'],
      },
      {
        code: '63675109',
        text:
          'Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Только осуществлять разделение месторождения на шахтные поля и порядок их отработки, чтобы обеспечивалось планомерное извлечение запасов без образования целиков, острых углов и выступов фронта очистных работ',
          'Только ограничивать встречные и догоняющие фронты очистных работ',
          'Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия',
          'Только сокращать применение систем разработки с открытым очистным пространством',
        ],
        correctAnswers: [
          'Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия',
        ],
      },
      {
        code: '63675110',
        text: 'Какой должна быть площадь пола в клети на одного человека?',
        answers: ['Не менее 0,35 м2', 'Не менее 0,25 м2', 'Не менее 0,20 м2'],
        correctAnswers: ['Не менее 0,20 м2'],
      },
      {
        code: '63675111',
        text:
          'Каким требованиям должны соответствовать лестничные отделения шахтных стволов?',
        answers: [
          'Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки',
          'Лестницы через каждые 3 м должны быть скреплены металлическими стяжками',
          'Расстояние между лестничными площадками должно быть не более 5 м',
          'Расстояние от основания лестницы до крепи или обшивки лестничного отделения должно быть не менее 1 м',
        ],
        correctAnswers: [
          'Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки',
        ],
      },
      {
        code: '63675112',
        text:
          'Какими преимущественно системами разработки при совместной разработке месторождения следует производить подземные горные работы?',
        answers: [
          'Только системами разработки с закладкой выработанного пространства',
          'Преимущественно системами с поддержанием выработанного пространства',
          'В каждом конкретном случае возможность применения той или иной системы разработки устанавливается проектной организацией по согласованию с Ростехнадзором',
          'Любыми системами разработки',
        ],
        correctAnswers: [
          'Преимущественно системами с поддержанием выработанного пространства',
        ],
      },
      {
        code: '63675113',
        text:
          'Кто принимает решения об окончании, приостановлении и возобновлении работ по локализации и ликвидации последствий аварий или горноспасательных работ?',
        answers: [
          'Руководитель работ по ликвидации аварии',
          'Руководитель горноспасательных работ',
          'Руководитель опасного производственного объекта',
          'Руководитель профессионального аварийно-спасательного формирования',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63675114',
        text:
          'За какое время рабочие агрегаты главной водоотливной установки должны обеспечивать откачку максимального суточного притока воды?',
        answers: ['За 24 ч', 'За 22 ч', 'За 20 ч', 'За 21 ч'],
        correctAnswers: ['За 20 ч'],
      },
      {
        code: '63675115',
        text:
          'Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?',
        answers: [
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
          'Главным инженером карьера ежеквартально',
          'Специалистами геологической службы не реже двух раз в месяц',
          'Комиссией в составе главного инженера карьера и главного маркшейдера карьера раз в полугодие',
        ],
        correctAnswers: [
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
        ],
      },
      {
        code: '63675116',
        text:
          'Что из перечисленного допускается не предусматривать в оперативной части соответствующего плана для ликвидации подземных пожаров в начальной стадии?',
        answers: [
          'Места установки временных перемычек',
          'Использование насосов и ставов труб',
          'Порядок и способы использования водоотливных, воздухопроводных и противопожарных (противопожарно-оросительных) трубопроводов',
          'Использование имеющегося на шахте оборудования для ликвидации возможных аварий',
        ],
        correctAnswers: ['Использование насосов и ставов труб'],
      },
      {
        code: '63675117',
        text:
          'Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы и переработка полезных ископаемых, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?',
        answers: [
          'Заключение договоров не требуется',
          'Заключение договоров необязательно',
          'Заключение договоров обязательно',
        ],
        correctAnswers: ['Заключение договоров обязательно'],
      },
      {
        code: '63675118',
        text:
          'Каково максимально допустимое напряжение для питания ручных светильников в помещениях с повышенной опасностью?',
        answers: ['12 В', '36 В', '50 В', '127 В'],
        correctAnswers: ['50 В'],
      },
      {
        code: '63675119',
        text:
          'Каково максимально допустимое расстояние между сдвоенными закрывающимися по направлению движения свежей вентиляционной струи пожарными дверями на всех горизонтах вблизи околоствольных дворов и у устьев штолен, подающих свежий воздух?',
        answers: ['10 м', '15 м', '20 м', '5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63675120',
        text:
          'В каком из перечисленных случаев запрещается ручная откатка рельсовых транспортных средств?',
        answers: [
          'Если их вес превышает 1 т',
          'Если требуется приложить усилие более 15 кг',
          'На уклонах более 0,01',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['На уклонах более 0,01'],
      },
      {
        code: '63675121',
        text:
          'Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований (ПАСС(Ф)) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          'По решению технического руководителя (главного инженера) ОПО',
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: '63675122',
        text:
          'В каком из перечисленных случаев может разрабатываться регламент технологических процессов для безопасного выполнения работ?',
        answers: [
          'Проектными организациями при возобновлении горных работ после вывода объектов горных работ из консервации',
          'Эксплуатирующими организациями при отсутствии в ранее разработанной проектной документации регламентов путем привлечения научно-исследовательских организаций',
          'Проектными организациями в качестве неотъемлемой составной части проекта на строительство, эксплуатацию, реконструкцию и модернизацию, ликвидацию и консервацию шахты',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63675123',
        text:
          'В каком из перечисленных случаев запрещается эксплуатировать рельсовые пути?',
        answers: [
          'Только при расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи',
          'Только при износе головки рельса по вертикали более 8 мм для рельсов типа Р-24 и 10 мм - для рельсов типа Р-33',
          'Только при зазорах между торцами рельсов, превышающих 2 мм',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Только при расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи',
        ],
      },
      {
        code: '63675124',
        text:
          'Что относится к основным факторам, которые учитываются при оценке устойчивости бортов и уступов карьеров, разрезов в массивах дисперсных горных пород?',
        answers: [
          'Отсутствие дренажа или недостаточная его эффективность',
          'Относительная деформация набухания под нагрузкой (для глинистых пород)',
          'Набухание, снижение прочности и развитие локальных деформаций уступов и их групп',
          'Несоответствие углов наклона бортов, откосов уступов и отвалов или несоответствие их высот геологическим условиям',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Набухание, снижение прочности и развитие локальных деформаций уступов и их групп',
        ],
      },
      {
        code: '63675125',
        text:
          'Какова минимально допустимая ширина лестниц к рабочим площадкам и механизмам поверхностного комплекса объектов горных работ и переработки полезных ископаемых?',
        answers: ['0,5 м', '0,6 м', '0,7 м', '0,8 м'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63675126',
        text:
          'Каков максимально допустимый срок остановки производства горных работ, при котором эта остановка не требует безопасной организации возобновления горных работ?',
        answers: ['3 суток', '1 сутки', '2 суток', '4 суток'],
        correctAnswers: ['3 суток'],
      },
      {
        code: '63675127',
        text:
          'Каким требованиям электробезопасности должны соответствовать светильники с лампами накаливания, устанавливаемые в помещениях с повышенной опасностью ниже 2,5 м от пола?',
        answers: [
          'Должны обеспечивать освещенность не менее 300 лк',
          'Должны иметь защиту не ниже IP 44',
          'Должны быть специальной конструкции либо использовать напряжение не выше 50 В с заземлением металлической арматуры',
        ],
        correctAnswers: [
          'Должны быть специальной конструкции либо использовать напряжение не выше 50 В с заземлением металлической арматуры',
        ],
      },
      {
        code: '63675128',
        text:
          'При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?',
        answers: [
          'При обрушении в горной выработке',
          'При пожаре в тупиковой выработке',
          'При взрыве метана и (или) угольной пыли',
          'При внезапном выбросе угля (породы)',
        ],
        correctAnswers: ['При обрушении в горной выработке'],
      },
      {
        code: '63675129',
        text:
          'В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?',
        answers: [
          'Общие положения',
          'Введение',
          'Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков',
          'Методы прогноза удароопасности участков массива горных пород и руд',
        ],
        correctAnswers: ['Общие положения'],
      },
      {
        code: '63675130',
        text:
          'В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?',
        answers: [
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
          'Расположенных на любом удалении от плоскости сместителя тектонического нарушения',
          'Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород',
        ],
        correctAnswers: [
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
        ],
      },
      {
        code: '63675131',
        text:
          'Кем составляется акт проверки исправности противопожарных средств и оборудования плана ликвидации аварий?',
        answers: [
          'Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту',
          'Комиссией в составе главного инженера шахты, начальника ПВС и командира ВГСЧ, обслуживающей данную шахту',
          'Комиссией в составе начальника шахты, начальника ПВС и инспектора Ростехнадзора',
        ],
        correctAnswers: [
          'Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту',
        ],
      },
      {
        code: '63675132',
        text: 'Какая документация должна находиться на командном пункте?',
        answers: [
          'Только журналы опасного производственного объекта (ОПО) и профессиональной аварийно-спасательной службы (формирования) и ПАСС(Ф)',
          'Только план ликвидации аварий',
          'Только оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63675133',
        text:
          'По прошествии какого времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?',
        answers: ['Через 2 часа', 'Через час', 'Через 3 часа', 'Через 4 часа'],
        correctAnswers: ['Через 2 часа'],
      },
      {
        code: '63675134',
        text:
          'Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?',
        answers: [
          'Сдвиговые характеристики отвальной массы',
          'Физические свойства складируемых пород, прочность их в куске',
          'Строение основания (наклон основания, наличие прослоев слабых пород и их мощность, наличие водоносных горизонтов, возможность образования техногенных водоносных горизонтов в нижней части отвала и в породах основания)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675135',
        text:
          'В соответствии с каким документом осуществляется производство открытых горных работ в зонах ранее выполненных подземных работ и имеющих пустоты?',
        answers: [
          'В соответствии со специальным проектом, согласованным с генеральным проектировщиком',
          'В соответствии с положением о производственном контроле',
          'В соответствии с планом организации строительства',
          'В соответствии с геологической документацией',
        ],
        correctAnswers: [
          'В соответствии со специальным проектом, согласованным с генеральным проектировщиком',
        ],
      },
      {
        code: '63675136',
        text:
          'Какой вид контроля должен осуществляться при добыче под действующими блоками карьера за оставленным целиком безопасности, рассчитанным проектной организацией?',
        answers: [
          'Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ',
          'Только маркшейдерский контроль',
          'Визуальный осмотр в рамках производственного контроля',
          'Только оперативный контроль',
        ],
        correctAnswers: [
          'Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ',
        ],
      },
      {
        code: '63675137',
        text:
          'Какие нормы сопротивления изоляции установлены для электродвигателей добычных и проходческих машин?',
        answers: ['Не ниже 1 МОм', 'Не ниже 0,5 МОм', 'Не ниже 10 МОм'],
        correctAnswers: ['Не ниже 0,5 МОм'],
      },
      {
        code: '63675138',
        text:
          'Каким должно быть количество самоспасателей в местах группового хранения?',
        answers: [
          'Должно соответствовать численности работающих в рабочей смене',
          'Должно соответствовать численности работающих на объекте в наиболее многочисленной смене',
          'Должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10%',
          'Определяется по согласованию с командиром военизированного газоспасательного отряда',
        ],
        correctAnswers: [
          'Должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10%',
        ],
      },
      {
        code: '63675139',
        text:
          'При каком угле наклона наклонной горной выработки пожар в ней тушится только активным способом и только дистанционно?',
        answers: [
          'С углом наклона более 20°',
          'С углом наклона до 15°',
          'С углом наклона 10°',
          'В любой наклонной горной выработке',
        ],
        correctAnswers: ['С углом наклона более 20°'],
      },
      {
        code: '63675140',
        text:
          'Кем должна осуществляться проверка состояния предусмотренных планом ликвидации аварии материальных и технических средств для осуществления мероприятий по спасению людей и ликвидации аварий перед согласованием планов ликвидации аварий на новое полугодие? Выберите два правильных варианта ответа.',
        answers: [
          'Инспектором территориального органа Ростехнадзора',
          'Работниками шахты',
          'Военизированной горноспасательной частью (ВГСЧ)',
          'Руководителем аварийно-спасательного формирования',
        ],
        correctAnswers: [
          'Работниками шахты',
          'Военизированной горноспасательной частью (ВГСЧ)',
        ],
      },
      {
        code: '63675141',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если это регламентировано внутренней документацией организации',
          'В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов',
          'План мероприятий разрабатывается на каждый опасный объект отдельно',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63675142',
        text:
          'В какой цвет окрашивается шахтный пожарно-оросительный трубопровод?',
        answers: ['В желтый цвет', 'В оранжевый цвет', 'В красный цвет'],
        correctAnswers: ['В красный цвет'],
      },
      {
        code: '63675143',
        text:
          'На каком расстоянии над поверхностью земли должны возвышаться устья наклонных выработок?',
        answers: ['На 1 м', 'На 0,5 м', 'На 0,7 м', 'На 1,5 м'],
        correctAnswers: ['На 0,5 м'],
      },
      {
        code: '63675144',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте',
          'В случае назначения нового руководителя организации',
          'Не позднее 15 дней после внесения изменений в методики (методы) измерений или типы средств измерений, применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63675145',
        text:
          'Что из перечисленного наносится на маркшейдерские планы и разрезы?',
        answers: [
          'Только все горные выработки',
          'Только зоны обводненных горизонтов',
          'Только границы открытого очистного пространства на подземных работах',
          'Только отработанные камеры, границы поверхностного контура зоны обрушения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675146',
        text:
          'Каково минимально допустимое расстояние на основных проходах между машинами и аппаратами и от стен до габаритов оборудования в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?',
        answers: ['1,5 м', '2 м', '0,8 м', '1 м'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63675147',
        text:
          'В каком направлении должны ориентироваться основные горизонтальные и наклонные выработки околоствольных дворов, включая камеры?',
        answers: [
          'В направлении действия минимального горизонтального напряжения нетронутого массива',
          'В направлении действия максимального горизонтального напряжения нетронутого массива',
          'В направлении действия максимального вертикального напряжения нетронутого массива',
          'В направлении действия минимального вертикального напряжения нетронутого массива',
        ],
        correctAnswers: [
          'В направлении действия максимального горизонтального напряжения нетронутого массива',
        ],
      },
      {
        code: '63675148',
        text:
          'Каким образом должны быть расположены вентиляторные установки на поверхности при проходке ствола?',
        answers: [
          'На расстоянии не менее 6 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не менее 20 м',
          'На расстоянии не менее 10 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не более 18 м',
          'На расстоянии не менее 8 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не менее 15 м',
          'На расстоянии не менее 10 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не более 15 м',
        ],
        correctAnswers: [
          'На расстоянии не менее 10 м от ствола, отставание вентиляционных труб от забоя ствола должно определяться проектом и быть не более 15 м',
        ],
      },
      {
        code: '63675149',
        text:
          'Какова минимально допустимая ширина свободного прохода людей в выработках с конвейерным транспортом?',
        answers: ['0,4 м', '0,5 м', '0,6 м', '0,7 м'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63675150',
        text:
          'На каких участках возможных деформаций выполняют закладку наблюдательных станций?',
        answers: [
          'На слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал',
          'На слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание близкое к простиранию борта',
          'На всех перечисленных',
        ],
        correctAnswers: ['На всех перечисленных'],
      },
      {
        code: '63675151',
        text:
          'На основании каких данных составляется оперативный план по локализации и ликвидации последствий аварий?',
        answers: [
          'Только поступающей на командный пункт (КП) информации',
          'Только анализа аварийной обстановки',
          'Только прогноза развития аварии',
          'На основании всех перечисленных данных',
        ],
        correctAnswers: ['На основании всех перечисленных данных'],
      },
      {
        code: '63675152',
        text:
          'На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: [
          'Изменение параметров и периодичности не осуществляется ни в каком случае',
          'На основе заключения представителя территориального управления Ростехнадзора',
          'На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
        correctAnswers: [
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63675153',
        text:
          'Какой категории по степени опасности участков горного массива вокруг выработок не существует?',
        answers: ['"Опасно"', '"Неопасно"', '"Чрезвычайно опасно"'],
        correctAnswers: ['"Чрезвычайно опасно"'],
      },
      {
        code: '63675154',
        text:
          'Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?',
        answers: [
          'Функции и ответственность инженерно-технических служб',
          'Обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения',
          'Параметры карьерного и шахтного полей с запасами полезного ископаемого, намечаемые к отработке открытым и подземным способами',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675155',
        text:
          'На кого возлагается руководство работами по локализации и ликвидации последствий аварий?',
        answers: [
          'На технического руководителя (главного инженера)',
          'На начальника аварийно-спасательной службы',
          'На начальника участка',
        ],
        correctAnswers: ['На технического руководителя (главного инженера)'],
      },
      {
        code: '63675156',
        text:
          'Кем подписывается оперативная часть плана ликвидации аварий в конце последней позиции с указанием даты составления плана?',
        answers: [
          'Главным инженером шахты',
          'Руководителем организации (предприятия)',
          'Руководителем спасательной службы',
          'Командиром ВГСЧ',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63675157',
        text:
          'Как должны храниться горюче-смазочные и обтирочные материалы на рабочих местах?',
        answers: [
          'Должны храниться в деревянных коробах',
          'Должны храниться в закрытых металлических емкостях',
          'Должны храниться в пластиковой упаковке',
          'Должны храниться в стеклянных емкостях на стеллажах',
        ],
        correctAnswers: ['Должны храниться в закрытых металлических емкостях'],
      },
      {
        code: '63675158',
        text:
          'Какие визуальные и инструментальные наблюдения должны проводиться объектах ведения открытых горных работ?',
        answers: [
          'За закарстованностью месторождения',
          'За проявлениями удароопасности горных пород, вмещающих полезное ископаемое',
          'За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров',
          'За состоянием бортов, уступов и откосов',
        ],
        correctAnswers: ['За состоянием бортов, уступов и откосов'],
      },
      {
        code: '63675159',
        text:
          'Кто является ответственным за организацию учета лиц, спустившихся и вышедших из горных выработок?',
        answers: [
          'Руководитель объекта',
          'Технический руководитель шахты',
          'Специалист по промышленной безопасности',
          'Начальник участка',
        ],
        correctAnswers: ['Руководитель объекта'],
      },
      {
        code: '63675160',
        text:
          'Каково минимально допустимое расстояние на проходах для обслуживания и ремонта в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?',
        answers: ['0,5 м', '0,6 м', '1,0 м', '0,8 м'],
        correctAnswers: ['0,6 м'],
      },
      {
        code: '63675161',
        text:
          'Какая минимальная площадь днища должна приходиться на одного человека при спуске и подъеме людей в бадьях?',
        answers: [
          '0,40 м2 площади днища',
          '0,25 м2 площади днища',
          '0,20 м2 площади днища',
        ],
        correctAnswers: ['0,25 м2 площади днища'],
      },
      {
        code: '63675162',
        text:
          'Какие действия обязаны предпринять при пожарах люди, находящиеся на исходящей от источника пожара струе воздуха?',
        answers: [
          'Взять самоспасатели и выходить на поверхность',
          'Оставаться на месте, включившись в самоспасатели',
          'Включиться в самоспасатели, контролировать концентрацию окиси углерода, найти наиболее безопасное место и ждать прибытия горноспасательных отделений',
          'Включиться в самоспасатели и под руководством старшего выходить на поверхность по маршруту, предусмотренному планом мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Включиться в самоспасатели и под руководством старшего выходить на поверхность по маршруту, предусмотренному планом мероприятий по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63675163',
        text:
          'Какое из перечисленных первоочередных мероприятий плана ликвидации аварий рекомендуется располагать первым в части аварий, связанных с загазованностью выработок?',
        answers: [
          'Установление аварийного режима работы главных вентиляционных установок',
          'Вызов ВГСЧ',
          'Режим электроснабжения шахты',
          'Расстановка постов безопасности',
        ],
        correctAnswers: [
          'Установление аварийного режима работы главных вентиляционных установок',
        ],
      },
      {
        code: '63675164',
        text:
          'Какую информацию включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только характеристику объектов, в отношении которых разрабатывается план мероприятий',
          'Только возможные сценарии возникновения и развития аварий на объектах, а также источники (места) возникновения аварий',
          'Только характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63675165',
        text:
          'В каком из перечисленных случаев допускается нахождение локомотива в хвосте состава при электровозной откатке?',
        answers: [
          'Только при сооружении тоннелей механизированными тоннелепроходческими комплексами при условии разработки в разделе "Промышленная (техническая) безопасность" ТЭО (проекта) мероприятий, обеспечивающих безопасность работ',
          'Только в тоннелях диаметром в свету 3,0 м и менее по всей длине выработки',
          'В любом из перечисленных случаев, а также при уклонах до 0,005 на расстояние не более 200 м',
        ],
        correctAnswers: [
          'В любом из перечисленных случаев, а также при уклонах до 0,005 на расстояние не более 200 м',
        ],
      },
      {
        code: '63675166',
        text:
          'При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?',
        answers: [
          'При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека, либо на поверхности',
          'При отсутствии информации о содержании в ней горючих газов',
          'При отсутствии в выработке пострадавших',
          'При тушении пожара в непроветриваемых тупиковых горных выработках',
        ],
        correctAnswers: [
          'При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека, либо на поверхности',
        ],
      },
      {
        code: '63675167',
        text:
          'Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Только привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой',
          'Только ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания рудничной атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C',
          'Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
          'Все перечисленные действия',
        ],
        correctAnswers: [
          'Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
        ],
      },
      {
        code: '63675168',
        text:
          'Каков максимально допустимый угол наклона лестниц в зумпфах и колодцах?',
        answers: ['80°', '90°', '75°'],
        correctAnswers: ['90°'],
      },
      {
        code: '63675169',
        text:
          'Кем должен тщательно рассматриваться маршрут движения отделений ВГСЧ на вывод людей и ликвидацию аварий в каждом конкретном случае? Выберите два правильных варианта ответа.',
        answers: [
          'Руководителем подразделения противопожарной службы',
          'Представителем Ростехнадзора',
          'Представителем МЧС России',
          'Главным инженером шахты',
          'Командиром ВГСЧ',
        ],
        correctAnswers: ['Главным инженером шахты', 'Командиром ВГСЧ'],
      },
      {
        code: '63675170',
        text:
          'В каких из перечисленных случаев руководитель работ по ликвидации аварии и руководитель горноспасательных работ должны разработать оперативный план по локализации и ликвидации последствий аварий? Выберите два правильных варианта ответа.',
        answers: [
          'В случае возникновения аварии в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта) в первоначальный момент',
          'В случае если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ',
          'В случае если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария',
          'В случае окончания ведения горноспасательных работ, если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется их корректировка',
        ],
        correctAnswers: [
          'В случае если после выполнения всех мероприятий, предусмотренных планом ликвидации аварии, требуется дальнейшее ведение горноспасательных работ',
          'В случае если при выполнении мероприятий, предусмотренных планом ликвидации аварии, произошло изменение обстановки в горной выработке или сети горных выработок (здании, сооружении, участке территории опасного производственного объекта), в которых произошла авария',
        ],
      },
      {
        code: '63675171',
        text:
          'На какие сроки разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах для объектов, на которых ведутся открытые горные работы?',
        answers: [
          'На пять лет',
          'На каждое полугодие',
          'На один год',
          'На три года',
        ],
        correctAnswers: ['На один год'],
      },
      {
        code: '63675172',
        text:
          'При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?',
        answers: [
          'Крепление выработок, предотвращающее обрушение пород кровли и боков',
          'Бурение разгрузочных щелей и скважин',
          'Контурное взрывание',
          'Создание устойчивых форм',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63675173',
        text:
          'Для выработок какой протяженностью допускается укладка одного откаточного пути с устройством разъездов?',
        answers: ['Более 200 м', 'Более 300 м', 'Более 400 м', 'Более 500 м'],
        correctAnswers: ['Более 500 м'],
      },
      {
        code: '63675174',
        text: 'Что относится к комбинированной разработке месторождений?',
        answers: [
          'Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения, ведение открытых и подземных горных работ в определенные периоды времени в пределах общего срока отработки запасов месторождения с одновременной или последовательной открыто-подземной или подземно-открытой разработкой',
          'Одновременное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или по горизонтали в пределах одного месторождения',
          'Последовательное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения',
        ],
        correctAnswers: [
          'Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения, ведение открытых и подземных горных работ в определенные периоды времени в пределах общего срока отработки запасов месторождения с одновременной или последовательной открыто-подземной или подземно-открытой разработкой',
        ],
      },
      {
        code: '63675175',
        text:
          'Каким должно быть отставание вентиляционных труб от забоя при проходке ствола?',
        answers: [
          'Не более 15 м',
          'Не более 20 м',
          'Не более 25 м',
          'Не более 30 м',
        ],
        correctAnswers: ['Не более 15 м'],
      },
      {
        code: '63675176',
        text:
          'Какой радиус опасной зоны должен быть установлен при производстве взрывопожароопасных работ?',
        answers: [
          'Не менее 20 м',
          'Не менее 30 м',
          'Не менее 40 м',
          'Не менее 50 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63675177',
        text:
          'Каким должно быть сопротивление общего заземляющего устройства открытых горных работ?',
        answers: ['Не более 4 Ом', 'Не менее 2 Ом', 'Не более 5 Ом'],
        correctAnswers: ['Не более 4 Ом'],
      },
      {
        code: '63675178',
        text:
          'С какой периодичностью должен производиться осмотр всех заземляющих устройств на шахте?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '63675179',
        text:
          'Какое должно быть количество воздуха, проходящее по выработкам после реверсирования?',
        answers: [
          'Не менее 60% его количества в нормальном режиме проветривания',
          'Не менее 20% его количества в нормальном режиме проветривания',
          'Не менее 30% его количества в нормальном режиме проветривания',
          'Не менее 50% его количества в нормальном режиме проветривания',
        ],
        correctAnswers: [
          'Не менее 60% его количества в нормальном режиме проветривания',
        ],
      },
      {
        code: '63675180',
        text:
          'При каких условиях разрешается производство работ при проходке стволов глубиной до 20 м с использованием в качестве средств подъема стреловых, козловых и башенных кранов?',
        answers: [
          'Без перекрытия устья ствола и бермой безопасности вокруг устья ствола не менее 1 м',
          'Без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей',
          'При наличии бермы безопасности не менее 1 м, а обделка ствола должна возвышаться над уровнем спланированной поверхности не менее чем на 0,5 м',
          'Без перекрытия устья ствола и бермой безопасности вокруг устья ствола не менее 3 м',
        ],
        correctAnswers: [
          'Без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей',
        ],
      },
      {
        code: '63675181',
        text:
          'Какова максимально допустимая температура поверхности наиболее нагреваемой части двигателя внутреннего сгорания?',
        answers: ['70 °С', '85 °С', '115 °С', '150 °С'],
        correctAnswers: ['150 °С'],
      },
      {
        code: '63675182',
        text:
          'Кем утверждается акт о проведении опытно-промышленных испытаний?',
        answers: [
          'Техническим руководителем организации',
          'Руководителем организации',
          'Лицом, ответственным за проведение ОПИ',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63675183',
        text:
          'Какое сечение должны иметь стальные полосы, применяемые для выполнения заземляющего контура?',
        answers: ['Не менее 50 мм2', 'Не менее 70 мм2', 'Не менее 100 мм2'],
        correctAnswers: ['Не менее 100 мм2'],
      },
      {
        code: '63675184',
        text:
          'Какова минимально допустимая высота от уровня пола при размещении труб, желобов и других коммуникаций в случае пересечения ими проходов и рабочих площадок?',
        answers: ['2,2 м', '2,1 м', '2,0 м', '1,8 м', '1,7 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63675185',
        text:
          'Где должны располагаться установки автоматического пожаротушения для камер с постоянным дежурством обслуживающего персонала?',
        answers: [
          'Снаружи камеры со стороны поступления свежей струи воздуха не дальше 10 м от входа в камеру',
          'У рабочего места дежурного персонала',
          'Непосредственно у защищаемого оборудования',
        ],
        correctAnswers: ['У рабочего места дежурного персонала'],
      },
      {
        code: '63675186',
        text:
          'Кто выдает разрешение на производство разбивочных и основных маркшейдерских работ вблизи забоев подземных выработок?',
        answers: [
          'Начальник участка',
          'Главный маркшейдер',
          'Горный мастер',
          'Технический руководитель шахты',
        ],
        correctAnswers: ['Горный мастер'],
      },
      {
        code: '63675187',
        text:
          'На каком расстоянии должны быть расположены первичные средства пожаротушения?',
        answers: [
          'Не дальше 50 м от входа в камеры',
          'В горизонтальных выработках с огнестойкой обделкой - через 300 м',
          'При наличии горючей крепи через 300 м',
          'Не дальше 50 м от груди забоя',
        ],
        correctAnswers: [
          'В горизонтальных выработках с огнестойкой обделкой - через 300 м',
        ],
      },
      {
        code: '63675188',
        text:
          'В каком случае месторождение (объект строительства подземных сооружений) относится к опасному по горным ударам?',
        answers: [
          'Только если имели место микроудары и горные удары',
          'Только если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          'Только если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63675189',
        text:
          'Какова максимально допустимая ширина отверстий в решетках, перекрывающих загрузочные отверстия на бункерах и в местах перегрузки конвейерного транспорта, при применении саморазгружающихся тележек или реверсивных конвейеров?',
        answers: [
          '0,1 х 0,1 м',
          '0,3 х 0,3 м',
          '0,2 х 0,2 м',
          'Не нормируется',
        ],
        correctAnswers: ['0,2 х 0,2 м'],
      },
      {
        code: '63675190',
        text:
          'Что из перечисленного должно предусматриваться при прорыве в горные выработки воды, пульпы, реагентов?',
        answers: [
          'Вывод всех людей из шахты на поверхность',
          'Вывод людей только из аварийного участка',
          'Вывод людей только из аварийного и угрожаемого участка',
        ],
        correctAnswers: ['Вывод всех людей из шахты на поверхность'],
      },
      {
        code: '63675191',
        text:
          'Какое из перечисленных действий должен выполнять руководитель работ по ликвидации аварии?',
        answers: [
          'Установление режима работы и отдыха работников профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований при ведении горноспасательных работ',
          'Организация ведения оперативного журнала профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Организация ведения горноспасательных работ',
          'Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания рудничной атмосфере',
        ],
        correctAnswers: [
          'Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания рудничной атмосфере',
        ],
      },
      {
        code: '63675192',
        text:
          'С кем согласовывается план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и Ростехнадзором',
          'С Ростехнадзором',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63675193',
        text:
          'Каким должен быть минимальный уровень содержания кислорода в подземных выработках?',
        answers: [
          '10% по объему',
          '15% по объему',
          '20% по объему',
          '17% по объему',
        ],
        correctAnswers: ['20% по объему'],
      },
      {
        code: '63675194',
        text:
          'Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано неверно?',
        answers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
          'Оперативные планы разрабатываются до окончания ведения горноспасательных работ',
          'Последующие оперативные планы (№ 2, 3 и т. д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
          'Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов',
        ],
        correctAnswers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
        ],
      },
      {
        code: '63675195',
        text:
          'В какой срок необходимо уведомить территориальный орган Ростехнадзора о начале работ по строительству подземных сооружений?',
        answers: [
          'За 1 день до начала работ',
          'За 15 дней до начала работ',
          'За 10 дней до начала работ',
          'За 30 календарных дней до начала работ',
        ],
        correctAnswers: ['За 15 дней до начала работ'],
      },
      {
        code: '63675196',
        text:
          'Каково минимально допустимое расстояние от бровки котлованов до места складирования материалов и оборудования?',
        answers: [
          'Высота складируемого оборудования или материалов плюс 1 м',
          'Высота складируемого оборудования или материалов плюс 0,5 м',
          'Высота складируемого оборудования или материалов плюс 1,5 м',
          'Высота складируемого оборудования или материалов плюс 2 м',
        ],
        correctAnswers: [
          'Высота складируемого оборудования или материалов плюс 1 м',
        ],
      },
      {
        code: '63675197',
        text:
          'Каково минимально допустимое значение проектной прочности бетона в своде для начала разработки ядра (средней штроссы) при проходке способом опертого свода в устойчивых породах?',
        answers: [
          'Не менее 75%',
          'Не менее 70%',
          'Не менее 65%',
          'Не менее 60%',
        ],
        correctAnswers: ['Не менее 75%'],
      },
      {
        code: '63675198',
        text:
          'Что из перечисленного должно быть нанесено условными обозначениями на вентиляционные планы?',
        answers: [
          'Только движение вентиляционной струи воздуха: свежей - красными стрелками и отработанной - синими стрелками',
          'Только вентиляционные устройства: замерные станции с указанием их сечения, количества поступающего воздуха и его скорости, перемычки, кроссинги, вентиляционные двери',
          'Только коммуникации и средства пожаротушения, необходимые для ликвидации аварий',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675199',
        text:
          'Что из перечисленного наносится на аксонометрических схемах и погоризонтных планах вентиляционных горных выработок в виде кружков диаметром 13 мм?',
        answers: [
          'Места размещения телефонов',
          'Позиции, соответствующие оперативной части плана ликвидации аварий',
          'Места размещения самоспасателей, огнетушителей',
          'Главные вентиляторные установки',
        ],
        correctAnswers: [
          'Позиции, соответствующие оперативной части плана ликвидации аварий',
        ],
      },
      {
        code: '63675200',
        text:
          'Кому запрещается находиться на командном пункте, организованном на период ведения работ по локализации и ликвидации последствий аварии на опасном производственном объекте?',
        answers: [
          'Руководителю работ по ликвидации аварии',
          'Лицам, назначенным для ведения оперативных журналов и другой документации, связанной с ведением работ по локализации и ликвидации последствий аварии и горноспасательных работ',
          'Руководителю горноспасательных работ',
          'Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)',
        ],
        correctAnswers: [
          'Службам профессионального аварийно-спасательного формирования (группам инженерного обеспечения, аварийной контрольно-испытательной лаборатории, медицинской службе)',
        ],
      },
      {
        code: '63675201',
        text:
          'Что из перечисленного определяется проектом и технологическим регламентом?',
        answers: [
          'Только порядок эксплуатации и обслуживания машин с двигателями внутреннего сгорания',
          'Только устройство гаражей, складов горюче-смазочных материалов',
          'Только устройство мастерских, пунктов мойки деталей горючей жидкостью, пунктов заправки машин и их временного отстоя',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63675202',
        text:
          'Какие надписи должен иметь титульный лист плана ликвидации аварии?',
        answers: [
          'Подпись главного инженера шахты',
          'Надпись главного инженера организации (предприятия) с датой утверждения',
          'Надпись командира ВГСЧ с указанием даты',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63675203',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наибольший срок, предусмотренный для этих объектов',
          'Наименьший срок, предусмотренный для этих объектов',
          '3 года',
          '5 лет',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63675204',
        text:
          'Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?',
        answers: [
          'Не ранее чем через 30 минут',
          'Не ранее чем через 90 минут',
          'Не ранее чем через 10 минут',
          'Не ранее чем через 60 минут',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63675205',
        text:
          'Чему должно быть равно минимальное расстояние между ближайшими боками параллельных выработок и камер различного назначения на удароопасных участках до глубины 1000 м относительно наибольшего размераd поперечного сечения большей выработки?',
        answers: ['4d', '3dS', '2d', '5d'],
        correctAnswers: ['4d'],
      },
      {
        code: '63675206',
        text:
          'Начиная с какого расстояния между встречными или сближающимися забоями при проведении выработок без применения взрывных работ горнопроходческие работы должны производиться с соблюдением дополнительных мер безопасности по единому согласованному графику, утвержденному главными инженерами организаций, ведущих эти работы?',
        answers: [
          'Начиная с расстояния между ними менее одного диаметра (высоты) максимальной выработки',
          'Начиная с расстояния между ними менее полутора диаметров (высоты) максимальной выработки',
          'Начиная с расстояния между ними менее двух диаметров (высот) максимальной выработки',
          'Начиная с расстояния между ними менее трех диаметров (высот) максимальной выработки',
        ],
        correctAnswers: [
          'Начиная с расстояния между ними менее полутора диаметров (высоты) максимальной выработки',
        ],
      },
      {
        code: '63675207',
        text:
          'Каков максимально допустимый угол наклона постоянно эксплуатируемых лестниц к рабочим площадкам и механизмам поверхностного комплекса объектов горных работ и переработки полезных ископаемых?',
        answers: ['80°', '60°', '55°', '45°'],
        correctAnswers: ['45°'],
      },
      {
        code: '63675208',
        text:
          'Какие сооружения должны быть установлены для защиты работающих в забое от опасности обрыва скипа или падения предметов при проходке наклонных выработок?',
        answers: [
          'Заграждение в устье выработки',
          'Заграждения не дальше 25 м от места работы',
          'Устанавливаются 2 заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы',
          'Стопорные и улавливающие устройства на скипе',
        ],
        correctAnswers: [
          'Устанавливаются 2 заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы',
        ],
      },
      {
        code: '63675209',
        text: 'Что запрещено при комбинированной разработке месторождений?',
        answers: [
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
          'Ограничение мощности массовых взрывов в карьере и подземных выработках',
          'Применение систем разработки, исключающих сдвижение (разрушение) массива предохранительного целика',
          'Применение нагнетательного способа проветривания подземных выработок',
        ],
        correctAnswers: [
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
        ],
      },
      {
        code: '63675210',
        text:
          'Каким образом доводятся до специалистов и горнорабочих дополнительные меры безопасности перед производством массового взрыва?',
        answers: [
          'Командиром профессиональной аварийно-спасательной службы (формирования) - под подпись в журнале инструктажа',
          'Приказом - под подпись',
          'Начальником отдела промышленной безопасности',
          'Руководителем карьера - под подпись в журнале взрывных работ',
        ],
        correctAnswers: ['Приказом - под подпись'],
      },
      {
        code: '63675211',
        text:
          'Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным наклонным выработкам с углом наклона 10° при подъеме?',
        answers: ['45 м/мин', '30 м/мин', '25 м/мин', '60 м/мин'],
        correctAnswers: ['45 м/мин'],
      },
      {
        code: '63675212',
        text: 'В какой цвет должны быть окрашены заземляющие проводники?',
        answers: [
          'В белый цвет',
          'В серый цвет',
          'В черный цвет',
          'В синий цвет',
        ],
        correctAnswers: ['В черный цвет'],
      },
      {
        code: '63675213',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63675214',
        text: 'Какими способами осуществляется тушение подземных пожаров?',
        answers: [
          'Только способом активного тушения',
          'Только способом изоляции горных выработок, в которых действует пожар',
          'Только комбинированным способом',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63675215',
        text:
          'Каково допустимое количество хранения на рабочих местах горюче-смазочных и обтирочных материалов?',
        answers: [
          'Не более трехсуточной потребности каждого материала',
          'Не более суточной потребности каждого материала',
          'Не более месячной потребности каждого материала',
          'Не нормируется',
        ],
        correctAnswers: ['Не более трехсуточной потребности каждого материала'],
      },
    ],
    63671: [
      {
        code: '63671000',
        text:
          'Какие требования предъявляются к лицам, допускаемым к обслуживанию и ремонту электроустановок?',
        answers: [
          'Прохождение обучения по безопасным методам работы и проверка знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности',
          'Только прохождение обучения по промышленной безопасности',
          'Только прохождение обучения по охране труда',
        ],
        correctAnswers: [
          'Прохождение обучения по безопасным методам работы и проверка знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности',
        ],
      },
      {
        code: '63671001',
        text:
          'Как должно производиться оповещение о прекращении работы вентиляторов в реагентном отделении?',
        answers: [
          'С помощью звуковой или световой сигнализации',
          'Сообщением по связи от диспетчера',
          'Поступлением сообщения на центральный пульт управления',
        ],
        correctAnswers: ['С помощью звуковой или световой сигнализации'],
      },
      {
        code: '63671002',
        text:
          'Разрешается ли продолжать эксплуатацию технологического оборудования, которое выделяет пыль и газы, при неисправных системах вентиляции?',
        answers: [
          'Разрешается, если это обусловлено производственной необходимостью',
          'Разрешается по указанию технического руководителя организации',
          'Запрещается',
          'Разрешается после уведомления представителя Ростехнадзора',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63671003',
        text:
          'Кем утверждается акт о проведении опытно-промышленных испытаний (ОПИ)?',
        answers: [
          'Техническим руководителем организации',
          'Лицом, ответственным за проведение ОПИ',
          'Руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63671004',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники структурного подразделения, где будут проводиться работы',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63671005',
        text: 'Кто допускается к обслуживанию и ремонту электроустановок?',
        answers: [
          'Персонал, прошедший обучение',
          'Лица, имеющие соответствующее образование',
          'Лица, прошедшие обучение и сдавшие экзамен на знание ими обслуживаемого оборудования',
          'Специально подготовленный электротехнический и электротехнологический персонал, прошедший обучение по безопасным методам работы и проверку знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности',
        ],
        correctAnswers: [
          'Специально подготовленный электротехнический и электротехнологический персонал, прошедший обучение по безопасным методам работы и проверку знаний в квалификационной комиссии с присвоением соответствующей группы допуска по электробезопасности',
        ],
      },
      {
        code: '63671006',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку техники',
          'Подрядная организация должна согласовать наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63671007',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63671008',
        text:
          'Какой должна быть минимальная высота перил обслуживающих площадок, лестниц, мостиков, монтажных проемов, колодцев, канав?',
        answers: ['1,5 м', '1,2 м', '1,0 м', '1,1 м', '0,8 м', '0,7 м'],
        correctAnswers: ['1,1 м'],
      },
      {
        code: '63671009',
        text:
          'Каким минимальным условиям обеспечения проходов для людей должна соответствовать ширина галерей и эстакад при ширине ленты свыше 1400 мм?',
        answers: [
          '500 мм с одной стороны конвейера и 700 мм с другой',
          '800 мм с обеих сторон конвейера',
          '1000 мм с обеих сторон конвейера',
          '700 мм с одной стороны и 750 мм с другой стороны конвейера',
        ],
        correctAnswers: ['800 мм с обеих сторон конвейера'],
      },
      {
        code: '63671010',
        text:
          'Каким должно быть содержание углекислого газа в рудничном воздухе на рабочих местах?',
        answers: [
          'Не более 0,3%',
          'Не более 0,4%',
          'Не более 0,75%',
          'Не более 0,5%',
        ],
        correctAnswers: ['Не более 0,5%'],
      },
      {
        code: '63671011',
        text:
          'Какими документами необходимо руководствоваться при эксплуатации оборудования?',
        answers: [
          'Только техническими паспортами',
          'Только инструкциями',
          'Руководствами по эксплуатации, техническими паспортами и другими нормативными документами заводов-изготовителей',
          'Только руководствами по эксплуатации',
        ],
        correctAnswers: [
          'Руководствами по эксплуатации, техническими паспортами и другими нормативными документами заводов-изготовителей',
        ],
      },
      {
        code: '63671012',
        text:
          'Какой инструктаж проводится для рабочих при изменении характера работы или в случае выявления грубых нарушений требований безопасного ведения работ?',
        answers: ['Целевой', 'Внеплановый', 'Повторный', 'Первичный'],
        correctAnswers: ['Внеплановый'],
      },
      {
        code: '63671013',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'Не допускается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в тридцатидневный срок',
          'По распоряжению руководителя подразделения',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63671014',
        text:
          'Какая минимальная ширина допустима для переходных мостиков, устанавливаемых над монтажными проемами, приямками, зумпфами, колодцами, канавами, расположенных в зданиях и сооружениях?',
        answers: ['1,2 м', '1,1 м', '1,5 м', '1,0 м', '0,9 м'],
        correctAnswers: ['1,0 м'],
      },
      {
        code: '63671015',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63671016',
        text:
          'В каком из перечисленных случаев (кроме аварийных случаев) допускается остановка объектов жизнеобеспечения (электростанций, водоотливов, калориферных установок и др.)?',
        answers: [
          'Только по разрешению главного механика организации, согласованному с руководителем организации',
          'Только по письменному разрешению руководителя подрядной организации, выполняющей работы на объекте',
          'Только по устному разрешению технического руководителя эксплуатирующей организации',
          'Только по письменному разрешению технического руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'Только по письменному разрешению технического руководителя эксплуатирующей организации',
        ],
      },
      {
        code: '63671017',
        text:
          'Кто допускается к техническому руководству работами на объектах ведения горных работ и переработки полезных ископаемых?',
        answers: [
          'Лица с высшим техническим образованием',
          'Лица, имеющие высшее или среднее специальное соответствующее образование',
          'Лица, прошедшие аттестацию в Центральной аттестационной комиссии Ростехнадзора',
          'Лица, имеющие право ответственного ведения работ',
        ],
        correctAnswers: [
          'Лица, имеющие высшее или среднее специальное соответствующее образование',
        ],
      },
      {
        code: '63671018',
        text:
          'За какое время до момента прибытия железнодорожных составов подаются звуковые и световые сигналы?',
        answers: [
          'За 1 минуту',
          'За 1,5 - 2 минуты',
          'За 3 минуты',
          'За 45 секунд',
        ],
        correctAnswers: ['За 1,5 - 2 минуты'],
      },
      {
        code: '63671019',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63671020',
        text:
          'Организации, эксплуатирующие объекты, на которых ведутся горные работы и переработка полезных ископаемых, обязаны осуществлять:',
        answers: [
          'Геологическое обеспечение работ',
          'Геолого-маркшейдерское обеспечение работ',
          'Маркшейдерское обеспечение работ',
        ],
        correctAnswers: ['Маркшейдерское обеспечение работ'],
      },
      {
        code: '63671021',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Технический руководитель предприятия',
          'Руководитель отдела промышленной безопасности',
          'Руководители профессиональной аварийно-спасательной службы (формирования)',
        ],
        correctAnswers: ['Технический руководитель предприятия'],
      },
      {
        code: '63671022',
        text:
          'Какое минимальное количество рабочих должно быть в бригаде при работах в бункере?',
        answers: [
          'Двое, один из которых должен находиться в надбункерной части',
          'Трое, двое из которых должны находиться в надбункерной части',
          'Трое, один из которых должен находиться в надбункерной части',
          'Пятеро, трое из которых должны находиться в надбункерной части',
        ],
        correctAnswers: [
          'Трое, двое из которых должны находиться в надбункерной части',
        ],
      },
      {
        code: '63671023',
        text:
          'При каком расстоянии до места работ перевозка людей обязательна?',
        answers: [
          'При расстоянии до места работ более 0,5 км',
          'При расстоянии до места работ более 1 км',
          'При расстоянии до места работ более 1,5 км',
          'При расстоянии до места работ более 2 км',
        ],
        correctAnswers: ['При расстоянии до места работ более 1 км'],
      },
      {
        code: '63671024',
        text:
          'В течение какого срока должны пополняться материалы, израсходованные со складов на ликвидацию аварий?',
        answers: [
          'В течение месяца',
          'В течение 10 дней',
          'В течение недели',
          'В течение суток',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63671025',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 1 месяца со дня закрытия наряда-допуска',
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63671026',
        text:
          'С какой периодичностью главными специалистами, ответственными за безопасную эксплуатацию электроустановок, должны осматриваться все электрические машины, аппараты и трансформаторы?',
        answers: [
          'Ежесменно',
          'Еженедельно',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '63671027',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый не более чем на 1 дневную смену срок',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63671028',
        text:
          'С какой периодичностью рабочие, ведущие горные работы, должны проходить инструктаж по безопасным приемам выполнения работ и проверку знаний инструкций по профессиям?',
        answers: [
          'Инструктаж по безопасным приемам выполнения работ - не реже чем каждые 6 месяцев и проверку знаний инструкций по профессиям - не реже одного раза в год',
          'Инструктаж по безопасным приемам выполнения работ - не реже одного раза в год и проверку знаний инструкций по профессиям - не реже чем каждые 6 месяцев',
          'Инструктаж по безопасным приемам выполнения работ - не реже чем каждые 3 месяца и проверку знаний инструкций по профессиям - не реже одного раза в год',
        ],
        correctAnswers: [
          'Инструктаж по безопасным приемам выполнения работ - не реже чем каждые 6 месяцев и проверку знаний инструкций по профессиям - не реже одного раза в год',
        ],
      },
      {
        code: '63671029',
        text:
          'Какие защитные приспособления необходимо предусматривать на элеваторах, транспортирующих мокрые продукты, во избежание разбрызгивания пульпы?',
        answers: [
          'Они должны иметь защитные ограждения только у мест загрузки и разгрузки элеваторов',
          'Они должны быть закрыты по всей длине предохранительными щитами или кожухами',
          'Они должны иметь плотное укрытие',
          'Никаких дополнительных приспособлений не предусматривается, рабочие должны быть одеты в специальные защитные костюмы',
        ],
        correctAnswers: [
          'Они должны быть закрыты по всей длине предохранительными щитами или кожухами',
        ],
      },
      {
        code: '63671030',
        text:
          'Кому должен сообщать работник при обнаружении опасности, угрожающей людям, производственным объектам?',
        answers: [
          'Главному механику производственного объекта',
          'Руководителю производственного объекта',
          'Только людям, которым угрожает опасность',
          'Техническому руководителю смены',
        ],
        correctAnswers: ['Техническому руководителю смены'],
      },
      {
        code: '63671031',
        text:
          'Какое напряжение должно применяться для питания передвижных приемников электроэнергии?',
        answers: [
          'Не выше 10 000 В',
          'Не выше 3300 В',
          'Не выше 220 В',
          'Не выше 50 В',
        ],
        correctAnswers: ['Не выше 3300 В'],
      },
      {
        code: '63671032',
        text:
          'Сколько выходов должно быть в камерах подстанций длиной более 10 м?',
        answers: [
          'Не регламентируется',
          'Один выход',
          'Два выхода независимо от расположения',
          'Два выхода, расположенные в наиболее удаленных друг от друга частях камеры',
        ],
        correctAnswers: [
          'Два выхода, расположенные в наиболее удаленных друг от друга частях камеры',
        ],
      },
      {
        code: '63671033',
        text:
          'На какой высоте должны быть расположены токоведущие части электроаппаратуры центральных насосных камер и камер центральных подземных подстанций от головки рельсов околоствольного двора?',
        answers: [
          'Не менее 1,5 м',
          'Не более 0,2 м',
          'Не более 0,5 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 1 м'],
      },
      {
        code: '63671034',
        text:
          'С кем согласовывается план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'С Ростехнадзором',
          'С владельцами железнодорожного пути',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и Ростехнадзором',
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63671035',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['10 минут', '15 минут', '30 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63671036',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?',
        answers: [
          'Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок',
          'Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
          'Начальник участка, на котором произошла авария',
        ],
        correctAnswers: [
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
        ],
      },
      {
        code: '63671037',
        text:
          'С какой периодичностью персонал, работающий на электроустановках, обязан производить наружный осмотр защитных заземлений?',
        answers: [
          'Ежесменно',
          'Еженедельно',
          'Ежедневно',
          'Не реже 1 раза в месяц',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63671038',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Должны пройти только обучение приемам и методам проведения работ',
          'Должны пройти только медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Должны быть только не моложе 18 лет',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63671039',
        text: 'Какая документация должна находиться на командном пункте?',
        answers: [
          'Только оперативные журналы ОПО и ПАСС(Ф)',
          'Только план ликвидации аварий (ПЛА) и оперативные планы',
          'Только текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63671040',
        text: 'Кем утверждается схема электроснабжения объектов в организации?',
        answers: [
          'Техническим руководителем организации',
          'Руководителем организации',
          'Главным энергетиком',
          'Инспектором Ростехнадзора',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63671041',
        text:
          'Какие требования безопасности устанавливаются Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых к ограждениям вагоноопрокидывателей, расположенных на рабочих площадках приемных устройств?',
        answers: [
          'Высота ограждений должна быть не менее 3 м, ширина ячейки решеток - не более 15 мм',
          'Высота ограждений должна быть не менее 2 м, ширина ячейки решеток - не более 10 мм',
          'Высота ограждений должна быть не менее 1,5 м, размеры ячейки решеток - не более 15 х 15 мм',
          'Высота ограждений должна быть не более 1 м, ширина ячейки решеток - не более 25 мм',
        ],
        correctAnswers: [
          'Высота ограждений должна быть не менее 2 м, ширина ячейки решеток - не более 10 мм',
        ],
      },
      {
        code: '63671042',
        text:
          'Какие требования безопасности должны выполняться при разгрузке вагонов?',
        answers: [
          'Только запрещается разгрузка вагонов при неснятом напряжении в контактной сети, находящейся в надбункерном помещении',
          'Только запрещается нахождение людей на разгрузочной стороне приемного бункера в момент подачи и разгрузки',
          'Только запрещается разгружать неисправные вагоны, а также ремонтировать вагоны на разгрузочной площадке приемных устройств',
          'Необходимо соблюдать все перечисленные требования',
        ],
        correctAnswers: ['Необходимо соблюдать все перечисленные требования'],
      },
      {
        code: '63671043',
        text:
          'Чем должны быть оборудованы рабочие площадки приемных и разгрузочных устройств и бункеров при применении железнодорожного транспорта?',
        answers: [
          'Звуковой и световой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожных составов',
          'Только ограждениями',
          'Ограждениями и шлагбаумами',
        ],
        correctAnswers: [
          'Звуковой и световой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожных составов',
        ],
      },
      {
        code: '63671044',
        text:
          'На каком расстоянии от места ведения работ по кучному выщелачиванию должны быть расположены жилые помещения и пункты питания?',
        answers: [
          'Не менее 1 км',
          'Не менее 1,5 км',
          'Не менее 500 м',
          'Не менее 300 м',
        ],
        correctAnswers: ['Не менее 500 м'],
      },
      {
        code: '63671045',
        text:
          'Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?',
        answers: [
          'Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ',
          'Организация работы по разборке обрушившейся горной массы или проведению поисковых выработок из возможно большего числа мест',
          'Мониторинг концентрации индикаторных пожарных газов',
          'Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии',
        ],
        correctAnswers: ['Мониторинг концентрации индикаторных пожарных газов'],
      },
      {
        code: '63671046',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'По решению руководителя эксплуатирующей организации',
          'Не регламентировано',
          'Только по совместному решению руководителей эксплуатирующей организации и газоспасательной службы',
          'Нужно в любом случае',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63671047',
        text:
          'Каким образом машинист должен воспринимать каждый неправильно поданный или непонятный сигнал?',
        answers: [
          '"Стоп"',
          '"Осторожно"',
          '"Поднять стрелу"',
          '"Опустить стрелу"',
        ],
        correctAnswers: ['"Стоп"'],
      },
      {
        code: '63671048',
        text:
          'Какой должна быть высота ограждения загрузочного отверстия приемного бункера для ограничения движения задним ходом автомобилей?',
        answers: [
          'Не менее 0,5 диаметра колеса автомобиля',
          'Не менее 1,1 м',
          'Не менее 1,0 м',
          'Не менее 0,7 диаметра колеса автомобиля',
        ],
        correctAnswers: ['Не менее 0,5 диаметра колеса автомобиля'],
      },
      {
        code: '63671049',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Правилами безопасного ведения газоопасных, огневых и ремонтных работ',
          'Правилами противопожарного режима в Российской Федерации',
          'Организационно-распорядительными документами организации',
          'Техническим регламентом о требованиях пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63671050',
        text:
          'Когда проверяется исправность действия (срабатывания) реле утечки тока в передвижных электроустановках до 1000 В?',
        answers: [
          'Периодически, не реже 1 раза в неделю',
          'Периодически, не реже 1 раза в месяц',
          'Периодически, не реже 1 раза в 10 дней',
          'Перед началом работы в каждой смене',
        ],
        correctAnswers: ['Перед началом работы в каждой смене'],
      },
      {
        code: '63671051',
        text: 'Какая защита должна осуществляться при напряжении до 1140 В?',
        answers: [
          'Только защита трансформаторов и каждого отходящего от них присоединения от токов короткого замыкания - автоматическими выключателями с максимальной токовой защитой',
          'Только защита электродвигателей и питающих их кабелей от токов короткого замыкания - мгновенная или селективная',
          'Только защита электродвигателей и питающих их кабелей от включения напряжения при сниженном сопротивлении изоляции относительно земли',
          'Только защита электрической сети - от опасных утечек тока на землю автоматическими выключателями или одним отключающим аппаратом в комплексе с одним аппаратом защиты от утечек тока на всю электрически связанную сеть, подключенную к одному или группе параллельно работающих трансформаторов',
          'Все указанные виды защиты',
        ],
        correctAnswers: ['Все указанные виды защиты'],
      },
      {
        code: '63671052',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63671053',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах тепло- и электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на линейных объектах магистрального трубопроводного транспорта нефти, газа',
          'На ведение газоопасных, огневых и ремонтных работ на объектах стационарных автомобильных газозаправочных станций, автомобильных газонаполнительных компрессорных станциях и криогенных автозаправочных станциях газомоторного топлива',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63671054',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О наличии медицинских противопоказаний к работе',
          'О знании порядка действия при пожаре',
          'О самочувствии',
          'О знании правил оказания первой помощи',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63671055',
        text:
          'Какие требования безопасности устанавливаются к организации движения автомобилей на отвалах и перегрузочных пунктах?',
        answers: [
          'На автомобилях и на путях их движения должны устанавливаться предупреждающие знаки и проблесковая сигнализация',
          'На отвалах и перегрузочных пунктах должны устанавливаться схемы движения автомобилей. Зона разгрузки должна быть ограничена с обеих сторон знаками в виде изображения самосвала с поднятым кузовом с указателями направления разгрузки',
          'Пути движения автомобилей должны быть двухполосными',
        ],
        correctAnswers: [
          'На отвалах и перегрузочных пунктах должны устанавливаться схемы движения автомобилей. Зона разгрузки должна быть ограничена с обеих сторон знаками в виде изображения самосвала с поднятым кузовом с указателями направления разгрузки',
        ],
      },
      {
        code: '63671056',
        text:
          'В течение какого времени пересматривается план мероприятий по локализации и ликвидации последствий аварий после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства?',
        answers: [
          'Не позднее 10 дней календарных дней',
          'Не позднее 15 дней календарных дней',
          'Не позднее 30 календарных дней',
          'Не позднее 3 месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63671057',
        text:
          'Какие требования безопасности предъявляются к потенциально опасным местам на производственной площадке при превышении предельно допустимых концентраций (ПДК) цианидов и кислот в воздухе рабочей зоны?',
        answers: [
          'Должны ограждаться металлической сеткой',
          'Должны орошаться',
          'Должны быть оборудованы автоматическими сигнализаторами, подающими звуковые и световые сигналы',
          'Необходимо регулярно отбирать пробы воздуха на наличие цианидов в воздухе',
        ],
        correctAnswers: [
          'Должны быть оборудованы автоматическими сигнализаторами, подающими звуковые и световые сигналы',
        ],
      },
      {
        code: '63671058',
        text:
          'Какой должна быть скорость движения вагона при работе самоходного вагона в комплексе с передвижными или стационарными бункер-перегружателями в момент подъезда к местам перегрузки?',
        answers: [
          'Не должна превышать 10 км/ч',
          'Не должна превышать 4 км/ч',
          'Не должна превышать 3 км/ч',
          'Не должна превышать 5 км/ч',
        ],
        correctAnswers: ['Не должна превышать 5 км/ч'],
      },
      {
        code: '63671059',
        text:
          'Как часто должны проходить медицинский осмотр рабочие, выполняющие работы повышенной опасности, перечень которых установлен руководителем организации?',
        answers: [
          'Каждый раз перед началом смены',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в полугодие',
        ],
        correctAnswers: ['Каждый раз перед началом смены'],
      },
      {
        code: '63671060',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует оформить к наряду-допуску на выполнение огневых работ наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует оформить к наряду-допуску на выполнение огневых работ наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63671061',
        text:
          'На кого возлагается руководство работами по локализации и ликвидации последствий аварий?',
        answers: [
          'На руководителя участка',
          'На должностное лицо аварийно-спасательной службы',
          'На технического руководителя (главного инженера)',
          'На должностное лицо военизированной горноспасательной части',
        ],
        correctAnswers: ['На технического руководителя (главного инженера)'],
      },
      {
        code: '63671062',
        text:
          'Как должно осуществляться передвижение людей по территории объектов переработки полезных ископаемых?',
        answers: [
          'По специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу движущемуся автотранспорту по утвержденному маршруту',
          'Передвижение людей на объекте должно осуществляться только на автомобильном транспорте',
          'Правилами не регламентируется',
        ],
        correctAnswers: [
          'По специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу движущемуся автотранспорту по утвержденному маршруту',
        ],
      },
      {
        code: '63671063',
        text:
          'На какие сроки разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах для объектов, на которых ведутся открытые горные работы?',
        answers: [
          'На шесть месяцев',
          'На один год',
          'На три года',
          'На пять лет',
        ],
        correctAnswers: ['На один год'],
      },
      {
        code: '63671064',
        text:
          'Какой должна быть ширина лестниц, высота ступеней, ширина ступеней лестниц к рабочим площадкам и механизмам поверхностного комплекса объектов горных работ и переработки полезных ископаемых?',
        answers: [
          'Ширина лестницы должна быть не менее 1,0 м, высота ступеней - не более 0,4 м, ширина ступеней - не менее 0,35 м',
          'Ширина лестницы должна быть не менее 0,7 м, высота ступеней - не более 0,3 м, ширина ступеней - не менее 0,25 м',
          'Ширина лестницы должна быть не менее 0,8 м, высота ступеней - не более 0,25 м, ширина ступеней не менее 0,3 м',
          'Ширина лестницы должна быть не менее 0,7 м, высота ступеней - не более 0,25 м, ширина ступеней - не менее 0,25 м',
        ],
        correctAnswers: [
          'Ширина лестницы должна быть не менее 0,7 м, высота ступеней - не более 0,3 м, ширина ступеней - не менее 0,25 м',
        ],
      },
      {
        code: '63671065',
        text:
          'В какой срок пересматривается план мероприятий по локализации и ликвидации последствий аварий до истечения срока действия предыдущего плана мероприятий?',
        answers: [
          'Не менее чем за 7 календарных дней',
          'Не менее чем за 10 календарных дней',
          'Не менее чем за 15 календарных дней',
          'Не менее чем за 30 календарных дней',
        ],
        correctAnswers: ['Не менее чем за 15 календарных дней'],
      },
      {
        code: '63671066',
        text:
          'Какими устройствами безопасности должны быть оборудованы цепные элеваторы?',
        answers: [
          'Ограничителями хода',
          'Тормозными устройствами, исключающими обратный ход кольцевой цепи, и ловителями при ее разрыве',
          'Предохранительными устройствами',
          'Специальными устройствами блокировки и аварийных остановок',
        ],
        correctAnswers: [
          'Тормозными устройствами, исключающими обратный ход кольцевой цепи, и ловителями при ее разрыве',
        ],
      },
      {
        code: '63671067',
        text:
          'Какое напряжение должно быть для питания аппаратуры сигнализации при наличии защиты от токов утечки?',
        answers: [
          'Не выше 42 В',
          'Не выше 127 В',
          'Не выше 220 В',
          'Не выше 380 В',
        ],
        correctAnswers: ['Не выше 220 В'],
      },
      {
        code: '63671068',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в надлежащей степени готовности',
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63671069',
        text:
          'Каким должно быть расстояние от нижнего фазного провода линии до верхней точки автомобиля или груза при пересечении временных линий электропередачи с автомобильной дорогой при напряжении до 20 кВ?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 1 м',
          'Не менее 1,5 м',
          'Не менее 2 м',
          'Не менее 2,5 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63671070',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Технологический регламент',
          'Проект производства ремонтных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63671071',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63671072',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если это регламентировано внутренней документацией организации',
          'В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов',
          'План мероприятий разрабатывается на каждый опасный объект отдельно',
        ],
        correctAnswers: [
          'В случае если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63671073',
        text:
          'Какие защитные приспособления должны быть предусмотрены на грохотах и дробилках?',
        answers: [
          'Для конусных дробилок - глухие съемные ограждения, кроме дробилок крупного дробления 1 стадии, работающих "под завалом"; для щековых дробилок - глухие съемные ограждения со смотровыми окнами, исключающие возможность выброса кусков руды из зева дробилки',
          'Для конусных дробилок - решетчатые несъемные ограждения, кроме дробилок крупного дробления 1 стадии, работающих "под завалом"; для щековых дробилок - глухие съемные ограждения без смотровых окон',
          'Для конусных дробилок и грохотов - глухие съемные ограждения со смотровыми окнами; для щековых дробилок - глухие несъемные ограждения без смотровых окон',
        ],
        correctAnswers: [
          'Для конусных дробилок - глухие съемные ограждения, кроме дробилок крупного дробления 1 стадии, работающих "под завалом"; для щековых дробилок - глухие съемные ограждения со смотровыми окнами, исключающие возможность выброса кусков руды из зева дробилки',
        ],
      },
      {
        code: '63671074',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала (дочернего общества)',
          'С газоспасательной службой и службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С техническим руководителем',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63671075',
        text:
          'Какое минимальное расстояние должно быть между машинами и аппаратами и от стен до габаритов оборудования на основных проходах и на рабочих проходах между машинами в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?',
        answers: [
          'На основных проходах - не менее 2,0 м, на рабочих проходах между машинами - не менее 1,0 м',
          'На основных проходах - не менее 1,5 м, на рабочих проходах между машинами - не менее 1,0 м',
          'На основных проходах - не менее 0,8 м, на рабочих проходах между машинами - не менее 0,8 м',
          'На основных проходах - не менее 1,0 м, на рабочих проходах между машинами - не менее 0,9 м',
        ],
        correctAnswers: [
          'На основных проходах - не менее 1,5 м, на рабочих проходах между машинами - не менее 1,0 м',
        ],
      },
      {
        code: '63671076',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'Работы не включенные в утвержденный перечень газоопасных работ выполнять запрещено',
          'По приказу технического руководителя организации',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63671077',
        text:
          'Какие требования безопасности необходимо применять в местах прохода и проезда людей под ленточными конвейерами?',
        answers: [
          'Необходимо установить плакаты, предупреждающие о возможном поражении падающими с ленты кусками транспортируемого материала',
          'Необходимо установить защитные полки, предохраняющие от возможного поражения падающими с ленты кусками транспортируемого материала, а со стороны основного прохода для людей по всей длине конвейера ролики рабочей и холостой ветви ленты должны иметь ограждения, не блокируемые с приводом конвейера',
          'Необходимо только установить защитное ограждение, исключающее возможность прохода или проезда под ленточными конвейерами',
        ],
        correctAnswers: [
          'Необходимо установить защитные полки, предохраняющие от возможного поражения падающими с ленты кусками транспортируемого материала, а со стороны основного прохода для людей по всей длине конвейера ролики рабочей и холостой ветви ленты должны иметь ограждения, не блокируемые с приводом конвейера',
        ],
      },
      {
        code: '63671078',
        text:
          'Какая длина должна быть у замерных станций, оборудованных на местах замера количества воздуха?',
        answers: [
          'Не менее 1 м',
          'Не менее 2 м',
          'Не менее 3 м',
          'Не менее 4 м',
        ],
        correctAnswers: ['Не менее 4 м'],
      },
      {
        code: '63671079',
        text:
          'Какими средствами пожаротушения должны быть оборудованы магистральные и участковые конвейерные линии, оснащенные трудновоспламеняемыми лентами?',
        answers: [
          'Системами автоматического пожаротушения на приводных станциях',
          'Автоматической сигнализацией по всей длине конвейера с выводом к диспетчеру шахты',
          'Cредствами автоматического пожаротушения и сигнализации на приводных станциях с выводом информации диспетчеру',
          'Системами автоматического пожаротушения и автоматической пожарной сигнализации по всей длине конвейера с выводом к диспетчеру шахты',
        ],
        correctAnswers: [
          'Системами автоматического пожаротушения и автоматической пожарной сигнализации по всей длине конвейера с выводом к диспетчеру шахты',
        ],
      },
      {
        code: '63671080',
        text:
          'Что служит защитой электроприводов технологического оборудования при исчезновении или резком снижении напряжения питающей сети?',
        answers: [
          'Защита от колебаний нагрузки',
          'Защита от перегрузки',
          'Блокировка, исключающая самозапуск',
        ],
        correctAnswers: ['Блокировка, исключающая самозапуск'],
      },
      {
        code: '63671081',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'К II группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63671082',
        text:
          'Как необходимо проводить уборку пыли в производственных помещениях?',
        answers: [
          'Посредством механизированной пневмоуборки и/или гидроуборки',
          'Любыми подручными средствами и способами',
          'Посредством влажной уборки вручную',
        ],
        correctAnswers: [
          'Посредством механизированной пневмоуборки и/или гидроуборки',
        ],
      },
      {
        code: '63671083',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал подрядной организации',
          'Электротехнический персонал эксплуатирующей организации',
          'Ремонтный персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63671084',
        text:
          'Какое напряжение должно использоваться при установке светильников с лампами накаливания над полом ниже 2,5 м в помещениях с повышенной опасностью в случае невозможного использования светильников специальной конструкции?',
        answers: [
          'Не выше 50 В (с заземлением металлической арматуры)',
          'Не выше 110 В (с заземлением металлической арматуры)',
          'Не выше 220 В (с заземлением металлической арматуры)',
        ],
        correctAnswers: ['Не выше 50 В (с заземлением металлической арматуры)'],
      },
      {
        code: '63671085',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Специалистами поднадзорных организаций совместно со специалистами надзорных органов',
          'Руководителем отдела промышленной безопасности',
          'Командиром профессиональной аварийно-спасательной службы (формирования)',
          'Руководителями структурных подразделений объекта',
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63671086',
        text:
          'Кто должен при поступлении на работу проходить инструктаж по безопасным приемам выполнения работ?',
        answers: [
          'Только рабочие, выполняющие работы повышенной опасности',
          'Только студенты высших и средних горнотехнических учебных заведений перед прохождением производственной практики',
          'Только рабочие, занятые на работах, выполнение которых предусматривает совмещение профессий',
          'Рабочие, ведущие работы по переработке полезных ископаемых',
        ],
        correctAnswers: [
          'Рабочие, ведущие работы по переработке полезных ископаемых',
        ],
      },
      {
        code: '63671087',
        text:
          'Какой угол наклона к рабочим площадкам и механизмам должны иметь постоянно эксплуатируемые лестницы?',
        answers: [
          'Не более 80°',
          'Не более 60°',
          'Не более 55°',
          'Не более 45°',
        ],
        correctAnswers: ['Не более 45°'],
      },
      {
        code: '63671088',
        text:
          'Каким должен быть угол наклона лестниц к рабочим площадкам и механизмам, посещаемым 1 - 2 раза в смену, а также в зумпфах и колодцах?',
        answers: [
          'К посещаемым 1 - 2 раза в смену - не более 80°, в зумпфах и колодцах - до 80°',
          'К посещаемым 1 - 2 раза в смену - не более 70°, в зумпфах и колодцах - до 90°',
          'К посещаемым 1 - 2 раза в смену - не более 50°, в зумпфах и колодцах - до 75°',
          'К посещаемым 1 - 2 раза в смену - не более 60°, в зумпфах и колодцах - до 90°',
        ],
        correctAnswers: [
          'К посещаемым 1 - 2 раза в смену - не более 60°, в зумпфах и колодцах - до 90°',
        ],
      },
      {
        code: '63671089',
        text:
          'В каком из перечисленных случаев нарушено требование электробезопасности?',
        answers: [
          'При особо неблагоприятных условиях, когда опасность поражения электрическим током усугубляется теснотой, для питания ручных и переносных светильников должно применяться напряжение не выше 50 В',
          'Напряжение переносного электроинструмента должно быть не выше 220 В в помещениях без повышенной опасности и не выше 50 В в помещениях с повышенной опасностью и вне помещений',
          'Штепсельные соединения (розетки, вилки), применяемые на напряжение 12 и 50 В, по своему конструктивному исполнению должны отличаться от обычных штепсельных соединений, предназначенных для напряжений 127 и 220 В, и исключать возможность включения вилок на 12 и 50 В в штепсельные розетки на 127 и 220 В',
        ],
        correctAnswers: [
          'При особо неблагоприятных условиях, когда опасность поражения электрическим током усугубляется теснотой, для питания ручных и переносных светильников должно применяться напряжение не выше 50 В',
        ],
      },
      {
        code: '63671090',
        text:
          'Какие надписи должны иметь трубопроводы, емкости и оборудование с цианистыми растворами и кислотами?',
        answers: ['"ОСТОРОЖНО!"', '"ЯД"', '"ОПАСНО"', '"ВНИМАНИЕ"'],
        correctAnswers: ['"ЯД"'],
      },
      {
        code: '63671091',
        text:
          'В течение какого времени должно находиться под наблюдением место сварки и резки после окончания сварочных и газопламенных работ?',
        answers: [
          'Не менее часа',
          'Не менее двух часов',
          'Не менее трех часов',
          'Не менее четырех часов',
        ],
        correctAnswers: ['Не менее двух часов'],
      },
      {
        code: '63671092',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63671093',
        text:
          'Какой должна быть минимальная ширина свободных проходов между пластинчатыми конвейерами?',
        answers: [
          'Не менее 0,75 м',
          'Не менее 1,1 м',
          'Не менее 1,2 м',
          'Не менее 1,5 м',
          'Не менее 1,8 м',
        ],
        correctAnswers: ['Не менее 1,2 м'],
      },
      {
        code: '63671094',
        text:
          'Каким документом следует руководствоваться при ликвидации зависаний горной массы над рабочим пространством дробилок, а также запуска аварийно остановленной дробилки под "завалом"?',
        answers: [
          'Технологическим регламентом, утвержденным техническим руководителем объекта, определяющим методы, последовательность операций и приемы безопасного выполнения работ по ликвидации зависания и запуску в работу дробилки',
          'Нарядом-допуском',
          'Приказом руководителя (технического руководителя) предприятия',
        ],
        correctAnswers: [
          'Технологическим регламентом, утвержденным техническим руководителем объекта, определяющим методы, последовательность операций и приемы безопасного выполнения работ по ликвидации зависания и запуску в работу дробилки',
        ],
      },
      {
        code: '63671095',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
        ],
      },
      {
        code: '63671096',
        text:
          'Каким должен быть порядок дистанционного запуска технологической цепи аппаратов оператором?',
        answers: [
          'Перед запуском должна быть проверена сигнализация об аварийной остановке оборудования, поданы звуковой предупредительный сигнал продолжительностью не менее 30 сек., выдержка времени не менее 10 сек., второй сигнал продолжительностью 30 сек. до начала запуска',
          'Перед запуском должно быть проверено отсутствие в опасной зоне людей, поданы звуковой предупредительный сигнал продолжительностью не менее 30 сек., выдержка времени не менее 30 сек., второй сигнал продолжительностью 10 сек. до начала запуска',
          'Перед запуском должны быть поданы два предупредительных звуковых сигнала с выдержкой времени между сигналами не менее 30 сек.',
          'Перед запуском должны быть поданы звуковой предупредительный сигнал продолжительностью не менее 10 сек. После первого сигнала должна предусматриваться выдержка времени не менее 30 сек., после чего должен подаваться второй сигнал продолжительностью 30 сек. до начала запуска первого технологического аппарата',
        ],
        correctAnswers: [
          'Перед запуском должны быть поданы звуковой предупредительный сигнал продолжительностью не менее 10 сек. После первого сигнала должна предусматриваться выдержка времени не менее 30 сек., после чего должен подаваться второй сигнал продолжительностью 30 сек. до начала запуска первого технологического аппарата',
        ],
      },
      {
        code: '63671097',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи пострадавшим',
          'Исполнители должны пройти инструктаж',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны уметь пользоваться средствами индивидуальной защиты',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63671098',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Не позднее 15 календарных дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий',
          'Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'В случае назначения нового руководителя организации',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63671099',
        text:
          'На каких электроустановках запрещается оперативное обслуживание без применения специальных защитных средств?',
        answers: [
          'Напряжением выше 220 В',
          'Напряжением выше 380 В',
          'Напряжением выше 660 В',
          'Напряжением выше 1000 В',
        ],
        correctAnswers: ['Напряжением выше 1000 В'],
      },
      {
        code: '63671100',
        text:
          'На какой минимальной высоте от уровня пола должны быть размещены коммуникации: трубы, желоба над рабочими площадками?',
        answers: ['2,2 м', '2,1 м', '2,0 м', '1,8 м', '1,7 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63671101',
        text: 'Разрешается ли совмещение профессий рабочими?',
        answers: [
          'Не разрешается',
          'Разрешается только при наличии у них соответствующей квалификации',
          'Разрешается только при прохождении дополнительного медицинского обследования',
          'Разрешается только при наличии у них соответствующей квалификации и прохождении обучения безопасным приемам труда и инструктажей по всем видам совмещенных работ',
        ],
        correctAnswers: [
          'Разрешается только при наличии у них соответствующей квалификации и прохождении обучения безопасным приемам труда и инструктажей по всем видам совмещенных работ',
        ],
      },
      {
        code: '63671102',
        text:
          'Какие документы требуются для проведения осмотра или ремонтных и очистных работ в приемных воронках питателей и в бункерах?',
        answers: [
          'Только наряд-допуск',
          'Письменное распоряжение руководителя работ',
          'Не регламентируется',
          'Наряд-допуск и проект производства работ',
        ],
        correctAnswers: ['Наряд-допуск и проект производства работ'],
      },
      {
        code: '63671103',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          'Наибольший срок, предусмотренный для этих объектов',
          '3 года',
          '5 лет',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63671104',
        text:
          'Какое требование установлено к коробкам выводов электрических машин и пускорегулирующей аппаратуры Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: [
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть закрыты специальной заглушкой',
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть оборудованы звуковой и световой сигнализацией',
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть защищены ограждениями',
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой',
        ],
        correctAnswers: [
          'Коробки выводов электрических машин и пускорегулирующей аппаратуры должны быть уплотнены и закрыты крышкой',
        ],
      },
      {
        code: '63671105',
        text:
          'Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристику объектов, в отношении которых разрабатывается план мероприятий',
          'Cценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63671106',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанные в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах тепло- и электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на объектах стационарных автомобильных газозаправочных станций, автомобильных газонаполнительных компрессорных станциях и криогенных автозаправочных станциях газомоторного топлива',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанные в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63671107',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Руководитель службы охраны труда делает запись в журнале проведения инструктажа структурного подразделения ремонтируемого объекта и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в наряде-допуске на проведение ремонтных работ',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63671108',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается 1 план мероприятий для объектов, расположенных на 1 участке, и несколько для объектов на смежных участках',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий для 2 и более объектов',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63671109',
        text: 'На каком транспорте доставляются рабочие к месту работы?',
        answers: [
          'На специальном транспорте',
          'На попутном транспорте',
          'На такси',
          'На личном транспорте',
        ],
        correctAnswers: ['На специальном транспорте'],
      },
      {
        code: '63671110',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Разработать проект производства работ',
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63671111',
        text:
          'Сколько самостоятельных вентиляторных агрегатов должно входить в главную вентиляторную установку?',
        answers: [
          'Один самостоятельный вентиляторный агрегат, обеспечивающий проектную мощность',
          'Два самостоятельных вентиляторных агрегата, причем один из них должен быть резервный',
          'Два самостоятельных вентиляторных агрегата и дополнительно должен быть установлен резервный',
          'Два самостоятельных вентиляторных агрегата и дополнительно должны быть установлены два резервных',
        ],
        correctAnswers: [
          'Два самостоятельных вентиляторных агрегата, причем один из них должен быть резервный',
        ],
      },
      {
        code: '63671112',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю структурного подразделения, на объекте которого будут проводиться огневые работы',
          'Лицу, ответственному за обеспечение пожарной безопасности',
          'Техническому руководителю эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63671113',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Руководитель структурного подразделения, где будут проводиться газоопасная или огневые работы',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники подрядной организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63671114',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63671115',
        text:
          'Какие средства защиты необходимо применять при обслуживании электроустановок?',
        answers: [
          'Только электрозащитные средства',
          'Любые доступные средства защиты',
          'Электрозащитные средства и средства индивидуальной защиты',
          'Только средства индивидуальной защиты',
        ],
        correctAnswers: [
          'Электрозащитные средства и средства индивидуальной защиты',
        ],
      },
      {
        code: '63671116',
        text:
          'Кем осуществляется руководство подачей и передвижением железнодорожных составов в процессе погрузки (разгрузки)?',
        answers: [
          'Машинистом состава',
          'Начальником карьера',
          'Поездным диспетчером',
          'Машинистом экскаватора или оператором погрузочного устройства',
        ],
        correctAnswers: [
          'Машинистом экскаватора или оператором погрузочного устройства',
        ],
      },
      {
        code: '63671117',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63671118',
        text:
          'Какое минимальное расстояние должно быть между машинами и аппаратами и от стен до габаритов оборудования на рабочих проходах между стеной и машинами, на проходах для обслуживания и ремонта в зданиях и сооружениях поверхностного комплекса объектов ведения горных работ и переработки полезных ископаемых?',
        answers: [
          'На рабочих проходах между стеной и машинами - не менее 0,8 м, на проходах для обслуживания и ремонта - не менее 0,5 м',
          'На рабочих проходах между стеной и машинами - не менее 0,7 м, на проходах для обслуживания и ремонта - не менее 0,5 м',
          'На рабочих проходах между стеной и машинами - не менее 0,7 м, на проходах для обслуживания и ремонта - не менее 0,6 м',
          'На рабочих проходах между стеной и машинами - не менее 0,6 м, на проходах для обслуживания и ремонта - не менее 1,0 м',
        ],
        correctAnswers: [
          'На рабочих проходах между стеной и машинами - не менее 0,7 м, на проходах для обслуживания и ремонта - не менее 0,6 м',
        ],
      },
      {
        code: '63671119',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов аппаратов, машин и другого оборудования, а также другие электроприемники, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать! Работают люди"',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63671120',
        text:
          'Кто принимает решения об окончании, приостановлении и возобновлении работ по локализации и ликвидации последствий аварий или горноспасательных работ?',
        answers: [
          'Руководитель горноспасательных работ',
          'Руководитель опасного производственного объекта',
          'Руководитель работ по ликвидации аварии',
          'Руководитель профессионального аварийно-спасательного формирования (ПАСС(Ф)',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63671121',
        text:
          'Какое напряжение должно быть у переносных ламп, применяемых внутри бункера для освещения во время проведения ремонтных работ?',
        answers: [
          'Не выше 12 В',
          'Не выше 24 В',
          'Не выше 36 В',
          'Не выше 42 В',
        ],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '63671122',
        text:
          'В каких количествах на рабочих местах должны храниться горюче-смазочные и обтирочные материалы?',
        answers: [
          'В количествах, необходимых для производства работ',
          'В количествах не более суточной потребности в каждом из материалов',
          'В количествах не более трехсуточной потребности в каждом из материалов',
          'В количествах месячной потребности в каждом из материалов',
          'Хранение горюче-смазочных и обтирочных материалов на рабочих местах запрещается',
        ],
        correctAnswers: [
          'В количествах не более трехсуточной потребности в каждом из материалов',
        ],
      },
      {
        code: '63671123',
        text:
          'Кто определяет места возведения изоляционных перемычек, предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?',
        answers: [
          'Руководитель работ по ликвидации аварии',
          'Руководитель горноспасательных работ',
          'Ответственный за пожарную безопасность ОПО',
          'Все варианты неверны',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63671124',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63671125',
        text:
          'На какие предприятия (организации) не распространяются Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: [
          'На предприятия по переработке негорючих твердых полезных ископаемых',
          'На гидрометаллургические фабрики',
          'На объекты кучного выщелачивания',
          'На предприятия (организации) по переработке твердых горючих полезных ископаемых',
        ],
        correctAnswers: [
          'На предприятия (организации) по переработке твердых горючих полезных ископаемых',
        ],
      },
      {
        code: '63671126',
        text:
          'С какой периодичностью должно производиться измерение сопротивления изоляции?',
        answers: [
          'Ежесменно',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '63671127',
        text: 'Кто имеет право на проезд в многоместных кабинах автомобилей?',
        answers: [
          'Любой работник карьера',
          'Только представители надзорных органов',
          'Только лица, сопровождающие составы',
          'Лица, сопровождающие составы, а также сменный надзор и отдельные работники при наличии у них письменного разрешения технического руководителя',
        ],
        correctAnswers: [
          'Лица, сопровождающие составы, а также сменный надзор и отдельные работники при наличии у них письменного разрешения технического руководителя',
        ],
      },
      {
        code: '63671128',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за обеспечение пожарной безопасности',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии лица, ответственного за подготовку к проведению работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63671129',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '3 года со дня его окончания',
          '1 год со дня его окончания',
          '6 месяцев со дня его окончания',
          '3 месяца со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63671130',
        text:
          'Как должны храниться горюче-смазочные и обтирочные материалы на рабочих местах?',
        answers: [
          'Хранение горюче-смазочных и легковоспламеняющихся материалов на рабочих местах запрещается',
          'Горюче-смазочные материалы на рабочих местах должны храниться в закрытых металлических емкостях, обтирочные материалы - в стеклянных емкостях',
          'Горюче-смазочные и обтирочные материалы на рабочих местах должны храниться в закрытых металлических емкостях',
          'Горюче-смазочные и обтирочные материалы на рабочих местах должны храниться в металлических емкостях в количествах недельной потребности',
        ],
        correctAnswers: [
          'Горюче-смазочные и обтирочные материалы на рабочих местах должны храниться в закрытых металлических емкостях',
        ],
      },
      {
        code: '63671131',
        text: 'Какие блокировки должна иметь защитная аппаратура?',
        answers: [
          'От перебоев напряжения',
          'Против срабатывания защиты отключения магнитных пускателей',
          'Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока',
        ],
        correctAnswers: [
          'Против подачи напряжения на линии и электроустановки с пониженным сопротивлением изоляции относительно земли и после срабатывания защиты максимального тока',
        ],
      },
      {
        code: '63671132',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из частных разделов',
          'Из специализированных разделов',
          'Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности',
          'Из общих и специальных разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63671133',
        text:
          'Какой должна быть максимальная скорость движения конвейерной ленты при ручной рудоразборке?',
        answers: [
          'Не более 0,3 м/с',
          'Не более 0,4 м/с',
          'Не более 0,5 м/с',
          'Не более 0,6 м/с',
          'Не более 0,7 м/с',
        ],
        correctAnswers: ['Не более 0,5 м/с'],
      },
      {
        code: '63671134',
        text:
          'Как необходимо подготовить бункеры и места перегрузки конвейерного транспорта для использования саморазгружающихся тележек или реверсивных конвейеров?',
        answers: [
          'Необходимо оборудовать настилы',
          'Загрузочные отверстия необходимо перекрыть решетками с отверстиями шириной не более 100 х 100 мм',
          'Загрузочные отверстия необходимо оснастить ограждениями высотой не менее 1,5 м',
          'Загрузочные отверстия необходимо перекрыть решетками с отверстиями шириной не более 0,2 х 0,2 м',
        ],
        correctAnswers: [
          'Загрузочные отверстия необходимо перекрыть решетками с отверстиями шириной не более 0,2 х 0,2 м',
        ],
      },
      {
        code: '63671135',
        text:
          'Сколько рабочих должно находиться на поверхности кучи при проведении всех видов работ по кучному выщелачиванию и гидрометаллургических процессов?',
        answers: [
          'Один',
          'Минимум двое',
          'Минимум трое',
          'Количество рабочих не имеет значения',
        ],
        correctAnswers: ['Минимум двое'],
      },
      {
        code: '63671136',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'В течении трех смен',
          'В течении одного дня',
          'В течении трех дней',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63671137',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Для всех опасных производственных объектов (ОПО) без исключения',
          'Только для опасных производственных объектов I и II классов опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
    ],
    63672: [
      {
        code: '63672000',
        text:
          'Кого должна письменно уведомить маркшейдерская служба в случае превышения установленных допустимых величин просадок земной поверхности, деформации зданий и сооружений?',
        answers: [
          'Руководителя организации',
          'Технического руководителя организации',
          'Начальника участка',
          'Территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['Технического руководителя организации'],
      },
      {
        code: '63672001',
        text:
          'Чем должны быть оборудованы места перехода через траншеи, трубопроводы на строительной площадке?',
        answers: [
          'Мостиками шириной не менее 0,8 м с перилами 1,1 м',
          'Мостиками шириной не менее 0,8 м с перилами 1,1 м и бортовыми досками высотой не менее 15 см',
          'Трапами шириной не менее 1 м с перилами 1,1 м и бортовыми досками высотой не менее 15 см',
          'Сходнями шириной не менее 1 м с перилами 1,1 м и световыми приборами для обозначения в темное время суток',
        ],
        correctAnswers: [
          'Мостиками шириной не менее 0,8 м с перилами 1,1 м и бортовыми досками высотой не менее 15 см',
        ],
      },
      {
        code: '63672002',
        text:
          'Кто является ответственным за безопасное выполнение земляных работ, связанных с разрытием и перекладкой коммуникаций?',
        answers: [
          'Технический руководитель объекта подземного строительства',
          'Начальник участка',
          'Специалист, назначенный приказом по организации',
          'Главный энергетик',
        ],
        correctAnswers: ['Специалист, назначенный приказом по организации'],
      },
      {
        code: '63672003',
        text: 'Кем прорабатывается план ликвидации аварий под расписку?',
        answers: [
          'Специализированной сторонней организацией',
          'Главным инженером шахты с должностными лицами (техническим надзором) шахты',
          'Главным инженером шахты с инспектором Ростехнадзора',
          'Главным инженером шахты с командным составом ВГСЧ',
        ],
        correctAnswers: [
          'Главным инженером шахты с должностными лицами (техническим надзором) шахты',
        ],
      },
      {
        code: '63672004',
        text:
          'Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным наклонным выработкам с углом наклона 10° при подъеме?',
        answers: ['45 м/мин', '60 м/мин', '25 м/мин', '30 м/мин'],
        correctAnswers: ['45 м/мин'],
      },
      {
        code: '63672005',
        text:
          'На каком расстоянии от откосов котлованов и траншей, разрабатываемых без крепления, в случае увлажнения или выветривания запрещается движение транспортных средств и механизмов?',
        answers: [
          'Менее расстояния, определенного расчетом, но не менее 1,5 м от верхнего края откоса котлована',
          'Менее 2 м от верхнего края откоса котлована',
          'Менее 1,5 м от верхнего края откоса котлована',
          'Менее 2 м от нижнего края откоса котлована',
        ],
        correctAnswers: ['Менее 2 м от верхнего края откоса котлована'],
      },
      {
        code: '63672006',
        text:
          'Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?',
        answers: [
          'Мониторинг концентрации индикаторных пожарных газов',
          'Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ',
          'Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест',
          'Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии',
        ],
        correctAnswers: ['Мониторинг концентрации индикаторных пожарных газов'],
      },
      {
        code: '63672007',
        text:
          'Кто является ответственным за организацию учета лиц, спустившихся и вышедших из горных выработок?',
        answers: [
          'Руководитель организации',
          'Технический руководитель шахты',
          'Специалист по промышленной безопасности',
          'Начальник участка',
        ],
        correctAnswers: ['Руководитель организации'],
      },
      {
        code: '63672008',
        text:
          'В каком случае допускается разовое посещение подземного объекта лицом, не работающим постоянно на его строительстве?',
        answers: [
          'После проведения проверки знаний требований охраны труда и промышленной безопасности',
          'После получения разрешения руководителя работ',
          'После проведения инструктажа по технике безопасности с отметкой в журнале первичного инструктажа и в сопровождении лица технического надзора',
          'Ни в каком случае',
        ],
        correctAnswers: [
          'После проведения инструктажа по технике безопасности с отметкой в журнале первичного инструктажа и в сопровождении лица технического надзора',
        ],
      },
      {
        code: '63672009',
        text:
          'Какова максимальная продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды в непригодной для дыхания рудничной атмосфере с применением дыхательных аппаратов?',
        answers: ['4 часа', '3 часа', '2 часа', '5 часов'],
        correctAnswers: ['4 часа'],
      },
      {
        code: '63672010',
        text:
          'В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?',
        answers: [
          'В случае если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
          'В случае если продолжительность подачи инертного газа превышает допустимую',
          'В случае если это определено оперативным планом',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
        ],
      },
      {
        code: '63672011',
        text:
          'Какие участки относятся к наиболее нагруженным на месторождениях, склонных к горным ударам?',
        answers: [
          'Участки рудного массива и массива горных пород в зоне опорного давления от очистных работ',
          'Сопряжения выработок и передовые выработки',
          'Участки рудного массива и массива горных пород с дизъюнктивными и пликативными геологическими нарушениями',
          'Все перечисленные',
          'Опасные зоны, выявленные в результате регионального прогноза',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63672012',
        text:
          'В каком случае в камерах электроустановок, расположенных в подземных выработках, должно быть два выхода?',
        answers: [
          'В любом случае',
          'В случае если организовано постоянное дежурство бригады электромонтеров',
          'В случае если камера длиной более 10 м',
          'В случае если камера длиной более 5 м',
        ],
        correctAnswers: ['В случае если камера длиной более 10 м'],
      },
      {
        code: '63672013',
        text:
          'Какова периодичность проведения замеров притока шахтной воды и ее химического анализа?',
        answers: [
          'Не реже 1 раза в 1,5 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63672014',
        text:
          'На кого возлагается общее руководство организацией и осуществлением производственного контроля?',
        answers: [
          'На технического руководителя',
          'На руководителя организации',
          'На специалиста по промышленной безопасности',
          'На ответственного за осуществление производственного контроля',
        ],
        correctAnswers: ['На руководителя организации'],
      },
      {
        code: '63672015',
        text:
          'Какие из перечисленных грузоподъемных машин должны подвергаться ежегодному комиссионному обследованию и дефектоскопии под председательством представителя специализированной наладочной организации?',
        answers: [
          'Грузопассажирские подъемные машины',
          'Постоянно эксплуатируемые подъемные установки',
          'Подъемные установки с истекшим сроком эксплуатации',
          'Грузовые подъемные машины',
        ],
        correctAnswers: ['Подъемные установки с истекшим сроком эксплуатации'],
      },
      {
        code: '63672016',
        text:
          'С какой периодичностью технический руководитель объекта подземного строительства обязан проводить осмотр состояния крепления при выполнении работ в котлованах и траншеях с креплением бортов?',
        answers: [
          '1 раз в неделю',
          '1 раз в квартал',
          '1 раз в месяц',
          '1 раз в сутки',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63672017',
        text:
          'Как должны быть ограждены траншеи и котлованы на территории строительной площадки?',
        answers: [
          'Перилами высотой 1,1 м, в темное время суток на ограждения должны быть выставлены световые сигналы',
          'Перилами высотой 1,5 м, в темное время суток ограждения должны быть достаточно освещены',
          'Перилами высотой 1,2 м и бортовыми досками высотой не менее 15 см',
          'Ограждение не требуется',
        ],
        correctAnswers: [
          'Перилами высотой 1,1 м, в темное время суток на ограждения должны быть выставлены световые сигналы',
        ],
      },
      {
        code: '63672018',
        text:
          'На основании каких документов проводятся работы в подземных условиях?',
        answers: [
          'На основании наряда-допуска на производство работ повышенной опасности',
          'На основании акта-допуска',
          'На основании письменных нарядов, выданных в соответствии с утвержденным руководителем организации положением о нарядной системе (работы должны фиксироваться в книге нарядов)',
          'На основании распоряжения о производстве работ',
        ],
        correctAnswers: [
          'На основании письменных нарядов, выданных в соответствии с утвержденным руководителем организации положением о нарядной системе (работы должны фиксироваться в книге нарядов)',
        ],
      },
      {
        code: '63672019',
        text:
          'Что из перечисленного должно предусматриваться при прорыве в горные выработки воды, пульпы, реагентов?',
        answers: [
          'Вывод всех людей из шахты на поверхность',
          'Вывод всех людей только из угрожаемого участка',
          'Вывод всех людей только из аварийного и угрожаемого участков',
        ],
        correctAnswers: ['Вывод всех людей из шахты на поверхность'],
      },
      {
        code: '63672020',
        text:
          'Для каких объектов должен разрабатываться план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Для тоннелей протяженностью более 2 км',
          'Для выработок под реками или другими водными преградами',
          'Для подземных объектов, сооружаемых в неустойчивых грунтах',
          'Для каждого строящегося подземного объекта',
        ],
        correctAnswers: ['Для каждого строящегося подземного объекта'],
      },
      {
        code: '63672021',
        text:
          'Каким документом регламентируется проведение кратковременных работ с приставных лестниц и стремянок?',
        answers: [
          'Нарядом-допуском',
          'Распоряжением о производстве работ',
          'Проектом производства работ или технологической картой',
        ],
        correctAnswers: [
          'Проектом производства работ или технологической картой',
        ],
      },
      {
        code: '63672022',
        text:
          'Какой ширины должны быть проходы вокруг распределительных устройств?',
        answers: [
          'Не менее 0,7 м',
          'Не менее 0,8 м',
          'Не менее 0,5 м',
          'Не менее 0,6 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63672023',
        text:
          'Кто является ответственным руководителем работ по спасению людей и ликвидации аварий до момента прибытия главного инженера шахты?',
        answers: [
          'Горный диспетчер',
          'Командир горноспасательного формирования',
          'Начальник участка аэрологической безопасности',
          'Дежурный работник горноспасательного формирования',
        ],
        correctAnswers: ['Горный диспетчер'],
      },
      {
        code: '63672024',
        text:
          'В каком из перечисленных случаев запрещается производить работы без защитных настилов, козырьков или фартуков?',
        answers: [
          'В случае если работы ведутся в двух и более ярусах по одной вертикали',
          'В случае если работы ведутся более чем в одном ярусе по вертикали',
          'В любом случае',
        ],
        correctAnswers: [
          'В случае если работы ведутся в двух и более ярусах по одной вертикали',
        ],
      },
      {
        code: '63672025',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок из предусмотренных для этих объектов',
          'Наибольший срок из предусмотренных для этих объектов',
          '3 года',
          '5 лет',
        ],
        correctAnswers: [
          'Наименьший срок из предусмотренных для этих объектов',
        ],
      },
      {
        code: '63672026',
        text:
          'Кто осуществляет научно-методическое сопровождение деятельности службы прогноза и предотвращения горных ударов?',
        answers: [
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
          'Комиссия по горным ударам',
          'Территориальный орган Ростехнадзора',
          'Технический руководитель организации',
        ],
        correctAnswers: [
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63672027',
        text:
          'Какой стаж работы на строительстве подземных сооружений должен иметь работник, ответственный за осуществление производственного контроля?',
        answers: [
          'Не менее 3 лет',
          'Не менее 6 месяцев',
          'Не менее 1 года',
          'Не менее 2 лет',
        ],
        correctAnswers: ['Не менее 3 лет'],
      },
      {
        code: '63672028',
        text:
          'Какое значение продолжительности холостого хода предохранительного тормоза установлено для подъемной машины?',
        answers: [
          'Не более 0,5 с',
          'Не более 0,8 с',
          'Не более 1 с',
          'Не более 1,5 с',
        ],
        correctAnswers: ['Не более 0,5 с'],
      },
      {
        code: '63672029',
        text:
          'Какую группу по электробезопасности должен иметь работник, использующий ручной электроинструмент в сухих помещениях и выработках?',
        answers: [
          'Не ниже II группы',
          'Не ниже III группы',
          'Не ниже IV группы',
        ],
        correctAnswers: ['Не ниже II группы'],
      },
      {
        code: '63672030',
        text:
          'Кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Руководителями муниципальных образований, на территории которых расположен объект',
          'Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору',
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63672031',
        text:
          'С какой периодичностью начальник участка обязан проводить осмотр состояния крепления при выполнении работ в котлованах и траншеях с креплением бортов?',
        answers: [
          '1 раз в неделю',
          '1 раз в квартал',
          '1 раз в месяц',
          '1 раз в сутки',
        ],
        correctAnswers: ['1 раз в неделю'],
      },
      {
        code: '63672032',
        text: 'В каком случае запрещается эксплуатация рельсовых путей?',
        answers: [
          'При расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи',
          'При износе головки рельса по вертикали более 10 мм - для рельсов типа Р-24',
          'При образовании зазоров между торцами рельсов не более 3 мм',
          'При износе головки рельса по вертикали более 12 мм - для рельсов типа Р-33',
        ],
        correctAnswers: [
          'При расширении пути более 4 мм и сужении более 2 мм против нормально установленной ширины рельсовой колеи',
        ],
      },
      {
        code: '63672033',
        text:
          'Кем должны быть осмотрены проходческие комбайны и перегружатель перед началом работ?',
        answers: [
          'Начальником участка',
          'Лицом технического надзора',
          'Машинистом комбайна',
          'Механиком участка',
        ],
        correctAnswers: ['Машинистом комбайна'],
      },
      {
        code: '63672034',
        text:
          'При каком расстоянии до опасной зоны участковый маркшейдер должен делать замеры и оповещения о размерах целика после каждой заходки главному инженеру и начальнику участка?',
        answers: [
          'За 7 м до опасной зоны',
          'За 12 м до опасной зоны',
          'За 20 м до опасной зоны',
          'За 10 м до опасной зоны',
        ],
        correctAnswers: ['За 7 м до опасной зоны'],
      },
      {
        code: '63672035',
        text:
          'Как осуществляется раскрытие тоннеля на полный профиль и монтаж обделки при укладке прорезных колец?',
        answers: [
          'Только в соответствии с планом производства работ',
          'Только под руководством лица технического надзора',
          'В соответствии с планом производства работ под руководством лица технического надзора',
          'В соответствии с заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с планом производства работ под руководством лица технического надзора',
        ],
      },
      {
        code: '63672036',
        text:
          'При достижении бетоном какой прочности допускается раскрытие калотты на захватках свода, смежных с ранее забетонированными участками?',
        answers: [
          'Не ниже 70% проектной',
          'Не ниже 75% проектной',
          'Не ниже 80% проектной',
          'Не ниже 85% проектной',
        ],
        correctAnswers: ['Не ниже 70% проектной'],
      },
      {
        code: '63672037',
        text:
          'На каком расстоянии должны быть расположены первичные средства пожаротушения?',
        answers: [
          'Не дальше 50 м от входа в камеры',
          'Через каждые 300 м',
          'При наличии горючей крепи - через каждые 300 м',
          'Не дальше 50 м от груди забоя',
        ],
        correctAnswers: ['Через каждые 300 м'],
      },
      {
        code: '63672038',
        text:
          'Какое отставание вентиляционных труб от забоя допускается при проходке горизонтальных выработок при площади сечения забоя не более 16 м2?',
        answers: [
          'Не более 16 м',
          'Не более 10 м',
          'Не более 15 м',
          'Не более 12 м',
        ],
        correctAnswers: ['Не более 10 м'],
      },
      {
        code: '63672039',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных ПЛА?',
        answers: [
          'Руководитель энергомеханической службы ОПО',
          'Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок',
          'Начальник участка, на котором произошла авария',
          'Cпециалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
        ],
        correctAnswers: ['Руководитель энергомеханической службы ОПО'],
      },
      {
        code: '63672040',
        text:
          'Какое из перечисленных требований к строительной площадке указано верно?',
        answers: [
          'Строительная площадка должна ограждаться забором высотой не менее 1 м',
          'В случае примыкания пешеходного прохода к проезжей части дороги необходимо выполнить сплошное ограждение со стороны дороги высотой не менее 1 м',
          'Ограждения, примыкающие к местам массового прохода людей, необходимо оборудовать сплошным защитным козырьком',
          'Ширина прохода должна быть не менее 1 м',
        ],
        correctAnswers: [
          'Ограждения, примыкающие к местам массового прохода людей, необходимо оборудовать сплошным защитным козырьком',
        ],
      },
      {
        code: '63672041',
        text:
          'Что из перечисленного наносится на аксонометрических схемах и погоризонтных планах вентиляционных горных выработок в виде кружков диаметром 13 мм?',
        answers: [
          'Позиции, соответствующие оперативной части плана ликвидации аварий',
          'Главные вентиляционные установки',
          'Места размещения самоспасателей, огнетушителей',
          'Места размещения телефонов',
        ],
        correctAnswers: [
          'Позиции, соответствующие оперативной части плана ликвидации аварий',
        ],
      },
      {
        code: '63672042',
        text:
          'Какое отставание вентиляционных труб от забоя ствола допускается при его проходке?',
        answers: [
          'Не более 30 м',
          'Не более 15 м',
          'Не более 20 м',
          'Не более 25 м',
        ],
        correctAnswers: ['Не более 15 м'],
      },
      {
        code: '63672043',
        text:
          'Какие из перечисленных задач выполняет служба прогноза и предотвращения горных ударов? Выберите 2 варианта ответа.',
        answers: [
          'Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов',
          'Решение вопросов ежегодного подтверждения отнесения месторождения к склонным или опасным по горным ударам',
          'Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ',
          'Безотлагательное отнесение месторождения к удароопасным в случае неожиданных проявлений горных ударов или установления категории "Опасно"',
        ],
        correctAnswers: [
          'Разработка решений по предотвращению горных ударов, проведение горно-экспериментальных работ, испытание и внедрение новых методов прогноза и способов предотвращения горных ударов',
          'Проведение регионального прогноза удароопасности шахтных полей с целью выявления удароопасных участков и разработка мероприятий по безопасному ведению горных работ',
        ],
      },
      {
        code: '63672044',
        text:
          'Каким образом участки горных выработок категории "Опасно" приводят в неудароопасное состояние?',
        answers: [
          'Путем создания в краевой части массива пород защитной зоны шириной n + 2 м, но не менее 2 м',
          'Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м',
          'Путем создания в краевой части массива пород защитной зоны шириной n + 1 м, но не более 2 м',
          'Путем создания в краевой части массива пород защитной зоны шириной не более 1 м',
        ],
        correctAnswers: [
          'Путем создания в краевой части массива пород защитной зоны шириной n + 2 м, но не менее 2 м',
        ],
      },
      {
        code: '63672045',
        text:
          'В какой срок необходимо уведомить территориальный орган Ростехнадзора о начале работ по строительству подземных сооружений?',
        answers: [
          'В день начала работ',
          'За 15 дней до начала работ',
          'За 10 дней до начала работ',
          'За календарный месяц до начала работ',
        ],
        correctAnswers: ['За 15 дней до начала работ'],
      },
      {
        code: '63672046',
        text:
          'С какой периодичностью необходимо проводить смазку подъемных канатов?',
        answers: [
          'Не реже 1 раза в сутки',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в полгода',
        ],
        correctAnswers: ['Не реже 1 раза в неделю'],
      },
      {
        code: '63672047',
        text:
          'Кем утверждается структура и количественный состав службы прогноза и предотвращения горных ударов?',
        answers: [
          'Комиссией по горным ударам',
          'Руководителем организации',
          'Техническим руководителем организации',
          'Экспертной организацией',
        ],
        correctAnswers: ['Комиссией по горным ударам'],
      },
      {
        code: '63672048',
        text: 'В каком случае допускается спуск людей в котлован по лестницам?',
        answers: [
          'В случае если глубина котлована менее 25 м при условии, что расстояние между лестницами не превышает 40 м',
          'В случае если глубина котлована  менее 30 м при условии, что расстояние между лестницами не превышает 40 м',
          'В случае если глубина котлована  менее 25 м при условии, что расстояние между лестницами не превышает 50 м',
          'В случае если глубина котлована  менее 30 м при условии, что расстояние между лестницами не превышает 50 м',
        ],
        correctAnswers: [
          'В случае если глубина котлована менее 25 м при условии, что расстояние между лестницами не превышает 40 м',
        ],
      },
      {
        code: '63672049',
        text:
          'С какой периодичностью проводится проверка состояния постоянных шахтных копров?',
        answers: [
          '1 раз в год',
          '2 раза в год',
          '1 раз в 2 года',
          '1 раз в 3 года',
        ],
        correctAnswers: ['1 раз в год'],
      },
      {
        code: '63672050',
        text:
          'С какой периодичностью необходимо испытывать парашюты клетей для спуска и подъема людей в соответствии с требованиями Инструкции по испытанию шахтных парашютов подъемных установок?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63672051',
        text:
          'Как должен быть воспринят машинистом подъема каждый непонятный сигнал?',
        answers: [
          'Как сигнал "Вверх"',
          'Как сигнал "Вниз"',
          'Как сигнал "Стоп"',
          'Как сигнал "Осторожно"',
        ],
        correctAnswers: ['Как сигнал "Стоп"'],
      },
      {
        code: '63672052',
        text:
          'С какой скоростью должны передвигаться самоходные машины с двигателями внутреннего сгорания в подземных выработках?',
        answers: [
          'Не более 50 км/ч',
          'Не более 40 км/ч',
          'Не более 30 км/ч',
          'Не более 20 км/ч',
        ],
        correctAnswers: ['Не более 20 км/ч'],
      },
      {
        code: '63672053',
        text:
          'Какое из перечисленных первоочередных мероприятий плана ликвидации аварий рекомендуется располагать первым в части аварий, связанных с загазованностью выработок?',
        answers: [
          'Расстановка постов безопасности',
          'Режим электроснабжения шахты',
          'Установление аварийного режима работы главных вентиляционных установок',
          'Вызов ВГСЧ',
        ],
        correctAnswers: [
          'Установление аварийного режима работы главных вентиляционных установок',
        ],
      },
      {
        code: '63672054',
        text:
          'Каким должно быть расстояние от выхода из подземных выработок до места хранения пожароопасных веществ и материалов?',
        answers: [
          'Расстояние должно быть рассчитано с учетом господствующего направления ветра',
          'Не менее 50 м',
          'Не менее 25 м',
          'Не менее 10 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63672055',
        text:
          'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи',
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          'В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
      },
      {
        code: '63672056',
        text:
          'Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Только сокращение применения систем разработки с открытым очистным пространством',
          'Только снижение количества горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления',
          'Только ограничение встречных и догоняющих фронтов очистных работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63672057',
        text:
          'При каких видах аварии необходимо предусматривать вызов служб МЧС России?',
        answers: [
          'При всех видах аварий, когда требуется оказание помощи людям, и для ведения работ, требующих соответствующего горноспасательного оборудования',
          'При пожарах в надшахтных зданиях и сооружениях',
          'При землетрясениях',
          'При пожарах в стволах, шурфах и других выработках, имеющих выход на поверхность',
        ],
        correctAnswers: ['При землетрясениях'],
      },
      {
        code: '63672058',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите 2 варианта ответа.',
        answers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц',
        ],
      },
      {
        code: '63672059',
        text:
          'На каком расстоянии от мест хранения и использования горючих и легковоспламеняющихся материалов должны располагаться воздухозаборы вентиляторных установок?',
        answers: [
          'Не ближе 25 м',
          'Не ближе 20 м',
          'Не ближе 18 м',
          'Не ближе 15 м',
        ],
        correctAnswers: ['Не ближе 25 м'],
      },
      {
        code: '63672060',
        text:
          'С какой периодичностью проводится осмотр состояния крепления при выполнении работ в котлованах и траншеях с креплением бортов (стен) техническим руководителем?',
        answers: [
          '2 раза в месяц',
          '1 раз в месяц',
          '1 раз в неделю',
          '1 раз в смену',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63672061',
        text:
          'В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?',
        answers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
          'Взрыв, пожар, внезапный выброс, горный удар, обрушение',
          'Взрыв, горный удар, обрушение, внезапный выброс, пожар',
          'Взрыв, горный удар, внезапный выброс, пожар, обрушение',
        ],
        correctAnswers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
        ],
      },
      {
        code: '63672062',
        text:
          'Какой радиус должен быть у границ опасной зоны при проведении взрывопожароопасных работ?',
        answers: [
          'Не менее 20 м',
          'Не менее 30 м',
          'Не менее 50 м',
          'Не менее 10 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63672063',
        text:
          'На каком расстоянии от ствола объекта подземного строительства должны располагаться воздухозаборы вентиляторных установок?',
        answers: [
          'Не ближе 5 м',
          'Не ближе 2 м',
          'Не ближе 15 м',
          'Не ближе 10 м',
        ],
        correctAnswers: ['Не ближе 15 м'],
      },
      {
        code: '63672064',
        text:
          'Чему должно быть равно минимальное расстояние между ближайшими боками параллельных выработок и камер различного назначения на удароопасных участках до глубины 1000 м относительно наибольшего размера d поперечного сечения большей выработки?',
        answers: ['2dS', '5dS', '4dS', '3dS'],
        correctAnswers: ['4dS'],
      },
      {
        code: '63672065',
        text:
          'Какие меры по обеспечению безопасности работ должны приниматься в местах сдвигов, сбросов, тектонических разломов, а также при повышении степени трещиноватости пород?',
        answers: [
          'Закрепление грунтов',
          'Возведение постоянной обделки без отставания от забоя',
          'Уменьшение длины заходки, увеличение несущей способности временной крепи',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63672066',
        text: 'Чем оснащается КП (командный пункт)?',
        answers: [
          'Только аппаратурой телефонной и (или) радиосвязи с местами ведения работ по локализации и ликвидации последствий аварии',
          'Только электронно-вычислительными машинами',
          'Только множительной и копировальной техникой',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63672067',
        text:
          'При какой глубине ствола на период проходки разрешается установка подвесных лестниц с закреплением их в крепи ствола и установкой дуг безопасности?',
        answers: [
          'Не более 20 м',
          'Не более 25 м',
          'Не более 15 м',
          'Не более 35 м',
        ],
        correctAnswers: ['Не более 15 м'],
      },
      {
        code: '63672068',
        text:
          'С какой периодичностью механик участка должен проводить осмотр проходческих полков и лебедок?',
        answers: [
          'Ежесменно и перед началом каждой спуско-подъемной операции',
          '1 раз в день',
          '1 раз в неделю',
          '1 раз в месяц',
        ],
        correctAnswers: ['1 раз в неделю'],
      },
      {
        code: '63672069',
        text:
          'Какую высоту должен иметь свободный проход для людей на всем протяжении выработки?',
        answers: [
          'Не менее 1,6 м',
          'Не менее 1,7 м',
          'Не менее 1,8 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 1,8 м'],
      },
      {
        code: '63672070',
        text:
          'Каким документом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?',
        answers: [
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
          'Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора',
          'Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Инструкцией по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы',
        ],
        correctAnswers: [
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
        ],
      },
      {
        code: '63672071',
        text:
          'На какую величину над поверхностью земли должны возвышаться устья наклонных выработок?',
        answers: ['На 1 м', 'На 0,5 м', 'На 0,7 м', 'На 1,5 м'],
        correctAnswers: ['На 0,5 м'],
      },
      {
        code: '63672072',
        text:
          'Где должны находиться лица, на которых возлагается ответственность за руководство ликвидацией аварий в шахте в данную смену?',
        answers: [
          'На поверхности в командном пункте',
          'Непосредственно в шахте',
          'На ближайшем к шахтному стволу участке подземных работ',
          'Не регламентируется',
        ],
        correctAnswers: ['На поверхности в командном пункте'],
      },
      {
        code: '63672073',
        text:
          'Каким должно быть содержание кислорода в воздухе в подземных выработках?',
        answers: [
          'Не менее 10% по объему',
          'Не менее 15% по объему',
          'Не менее 20% по объему',
          'Не менее 17% по объему',
        ],
        correctAnswers: ['Не менее 20% по объему'],
      },
      {
        code: '63672074',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из частных разделов',
          'Из специализированных разделов',
          'Из разделов, предусмотренных требованиями федеральных норм и правил в области промышленной безопасности',
          'Из общих и специальных разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63672075',
        text:
          'На какое напряжение должны быть рассчитаны ручные переносные светильники, используемые в подземных условиях?',
        answers: [
          'Не более 220 В',
          'Не более 42 В',
          'Не более 12 В',
          'На более 6 В',
        ],
        correctAnswers: ['Не более 12 В'],
      },
      {
        code: '63672076',
        text:
          'При проведении работ на какой высоте должны применяться площадки со сплошным настилом, выполненным в соответствии с проектом производства работ?',
        answers: [
          '1 м от уровня пола',
          '1,1 м от уровня пола',
          '1,2 м от уровня пола',
          '1,3 м и более от уровня пола',
        ],
        correctAnswers: ['1,3 м и более от уровня пола'],
      },
      {
        code: '63672077',
        text:
          'Каким должно быть расстояние откатки загруженных вагонеток в призабойной зоне с помощью погрузочных машин?',
        answers: [
          'Не должно превышать 25 м',
          'Не должно превышать 50 м',
          'Не должно превышать 30 м',
          'Не должно превышать 20 м',
        ],
        correctAnswers: ['Не должно превышать 25 м'],
      },
      {
        code: '63672078',
        text:
          'Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников ПАСС(Ф) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          'По решению технического руководителя (главного инженера) ОПО',
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: '63672079',
        text:
          'Как следует выводить людей из горных выработок, расположенных до очага пожара?',
        answers: [
          'Навстречу свежей струе воздуха к выходу на поверхность',
          'В изолирующих самоспасателях кратчайшим путем в выработки со свежей струей воздуха и далее на поверхность',
          'В изолирующих самоспасателях к выходу на поверхность',
        ],
        correctAnswers: [
          'Навстречу свежей струе воздуха к выходу на поверхность',
        ],
      },
      {
        code: '63672080',
        text:
          'В каких местах должны размещаться первичные средства пожаротушения в горизонтальных выработках с огнестойкой обделкой?',
        answers: [
          'Не дальше 50 м от входа в камеры',
          'Через каждые 300 м',
          'Через каждые 500 м при наличии горючей крепи',
          'Не дальше 50 м от груди забоя',
        ],
        correctAnswers: ['Через каждые 300 м'],
      },
      {
        code: '63672081',
        text:
          'Какой практический стаж подземных работ должен иметь горный инженер для назначения на должность начальника службы прогноза и предотвращения горных ударов?',
        answers: [
          'Не менее двух лет',
          'Не менее трех лет',
          'Не менее одного года',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее трех лет'],
      },
      {
        code: '63672082',
        text:
          'Что из перечисленного запрещается при проходке вертикальных выработок?',
        answers: [
          'Разбирать предохранительный полок до окончания рассечки околоствольного двора и проходки горизонтальных выработок на длину до 20 м',
          'Одновременно выполнять работы на разных уровнях по высоте при отсутствии предохранительного полка, установленного не выше 2,5 м от рабочего полка',
          'Складировать породу, оборудование и материалы на перекрытии устья ствола, в пределах огражденной зоны, а также на подвесных полках',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63672083',
        text: 'Какой должна быть площадь пола в клети на одного человека?',
        answers: [
          'Не менее 0,4 м2',
          'Не менее 0,35 м2',
          'Не менее 0,25 м2',
          'Не менее 0,2 м2',
        ],
        correctAnswers: ['Не менее 0,2 м2'],
      },
      {
        code: '63672084',
        text:
          'Каким должно быть расстояние между разъединителями контактной сети?',
        answers: [
          'Не более 500 м',
          'Не более 350 м',
          'Не более 250 м',
          'Не более 400 м',
        ],
        correctAnswers: ['Не более 250 м'],
      },
      {
        code: '63672085',
        text:
          'Каким должно быть количество воздуха, проходящее по выработкам после реверсирования?',
        answers: [
          'Не менее 30% его количества в нормальном режиме проветривания',
          'Не менее 50% его количества в нормальном режиме проветривания',
          'Не менее 60% его количества в нормальном режиме проветривания',
          'Не менее 40% его количества в нормальном режиме проветривания',
        ],
        correctAnswers: [
          'Не менее 60% его количества в нормальном режиме проветривания',
        ],
      },
      {
        code: '63672086',
        text:
          'С какой периодичностью должна проводиться проверка состояния средств противоаварийной защиты?',
        answers: [
          'Не реже 1 раза в 1,5 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63672087',
        text: 'Каков предельный срок эксплуатации прицепных устройств бадей?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['2 года'],
      },
      {
        code: '63672088',
        text:
          'В каком случае осуществляется механизированная доставка людей к месту производства работ?',
        answers: [
          'В горизонтальных выработках при расстоянии до места производства работ свыше 1,5 км',
          'По наклонным выработкам при разнице конечных отметок свыше 60 м',
          'В горизонтальных выработках при расстоянии до места производства работ свыше 1 км',
          'По наклонным выработкам при разнице конечных отметок свыше 40 м',
        ],
        correctAnswers: [
          'По наклонным выработкам при разнице конечных отметок свыше 60 м',
        ],
      },
      {
        code: '63672089',
        text:
          'С какой периодичностью механик участка должен осматривать выработки и их обустройство?',
        answers: [
          'Еженедельно',
          'Ежесменно до начала работ',
          'Ежедневно',
          'В соответствии с графиком, утвержденным техническим руководителем объекта подземного строительства',
        ],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63672090',
        text:
          'Какой провис сетки, подвешенной к анкерам и очищаемой от лежащей на ней породы, допускается при установке анкерной крепи в выработках, проводимых по породам, склонным к отслоению и обрушению?',
        answers: [
          'Не более 0,3 м',
          'Не более 0,25 м',
          'Не более 0,2 м',
          'Не более 0,5 м',
        ],
        correctAnswers: ['Не более 0,2 м'],
      },
      {
        code: '63672091',
        text: 'По какому документу допускаются работы повышенной опасности?',
        answers: [
          'По наряду-допуску на производство работ',
          'По акту-допуску',
          'По письменным нарядам, выданным в соответствии с утвержденным руководителем организации положением о нарядной системе (работы должны фиксироваться в книге нарядов)',
          'По наряду на производство работ',
        ],
        correctAnswers: ['По наряду-допуску на производство работ'],
      },
      {
        code: '63672092',
        text:
          'За какое время рабочие агрегаты главной водоотливной установки должны обеспечивать откачку максимального суточного притока воды?',
        answers: ['За 22 ч', 'За 20 ч', 'За 21 ч', 'За 24 ч'],
        correctAnswers: ['За 20 ч'],
      },
      {
        code: '63672093',
        text:
          'Каким должно приниматься количество воздуха на одного человека, считая по наибольшему количеству людей, одновременно работающих в смене?',
        answers: [
          'Не менее 4 м3/мин',
          'Не менее 5 м3/мин',
          'Не менее 6 м3/мин',
          'Не менее 2 м3/мин',
        ],
        correctAnswers: ['Не менее 6 м3/мин'],
      },
      {
        code: '63672094',
        text:
          'С какой периодичностью горный мастер обязан проверять состояние каждого рабочего места?',
        answers: [
          'Не менее 1 раза в сутки',
          'Не менее 1 раза в смену',
          'Не менее 1 раза в неделю',
          'Не менее 2 раз в смену',
        ],
        correctAnswers: ['Не менее 2 раз в смену'],
      },
      {
        code: '63672095',
        text:
          'Какова максимальная скорость движения рельсового подвижного состава по горизонтальным выработкам при электровозной откатке?',
        answers: ['4 км/ч', '3,6 км/ч', '5,4 км/ч', '10 км/ч'],
        correctAnswers: ['10 км/ч'],
      },
      {
        code: '63672096',
        text:
          'При каком условии допускается находиться на опасных рабочих местах при приведении их в безопасное состояние?',
        answers: [
          'При условии нахождения на опасных рабочих местах под руководством представителя технического надзора',
          'При условии оформления наряда-допуска с указанием в нем необходимых мер безопасности',
          'При условии проведения целевого инструктажа по охране труда',
          'Ни при каких условиях',
        ],
        correctAnswers: [
          'При условии оформления наряда-допуска с указанием в нем необходимых мер безопасности',
        ],
      },
      {
        code: '63672097',
        text: 'Какой вентиляционный режим должен выбираться при авариях?',
        answers: [
          'Только нормальный вентиляционный режим',
          'Только реверсивный вентиляционный режим',
          'Вентиляционный режим с учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках',
        ],
        correctAnswers: [
          'Вентиляционный режим с учетом жизнеобеспечения максимального количества людей, находящихся в горных выработках',
        ],
      },
      {
        code: '63672098',
        text:
          'Что из перечисленного допускается не предусматривать в оперативной части соответствующего плана для ликвидации подземных пожаров в начальной стадии?',
        answers: [
          'Использование насосов и ставов труб',
          'Места установок временных перемычек',
          'Порядок и способы использования водоотливных, воздухопроводных и противопожарных (противопожарно-оросительных) трубопроводов',
          'Использование имеющегося на шахте оборудования для ликвидации возможных аварий',
        ],
        correctAnswers: ['Использование насосов и ставов труб'],
      },
      {
        code: '63672099',
        text:
          'На каком расстоянии от мест производства работ должны устанавливаться сигнальные знаки на откаточных путях?',
        answers: [
          'Не менее 40 м',
          'Не менее 60 м',
          'Не менее 80 м',
          'Не менее 10 м',
        ],
        correctAnswers: ['Не менее 80 м'],
      },
      {
        code: '63672100',
        text:
          'Кто разрабатывает указания по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам?',
        answers: [
          'Технический руководитель организации',
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
          'Комиссия, состоящая из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Представитель территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63672101',
        text:
          'В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?',
        answers: [
          'В случае тушения пожара водой',
          'Не допускается ни в каком случае',
          'В случае, когда пожар находится вблизи выработок со свежей струей воздуха',
          'В случае выполнения мер, предотвращающих обрушение (высыпание) горных пород',
        ],
        correctAnswers: [
          'В случае, когда пожар находится вблизи выработок со свежей струей воздуха',
        ],
      },
      {
        code: '63672102',
        text:
          'В каком случае при испытании перед навеской канаты, служащие для подъема и спуска людей, бракуются?',
        answers: [
          'Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 6%',
          'Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 5%',
          'Если запас их прочности ниже 3,5-кратного',
          'Если запас их прочности ниже 2-кратного',
        ],
        correctAnswers: [
          'Если суммарная площадь поперечного сечения проволок, не выдержавших испытания на разрыв и перегиб, составляет 6%',
        ],
      },
      {
        code: '63672103',
        text:
          'При достижении бетоном какой прочности необходимо производить раскрытие калотты на захватках свода, смежных с ранее забетонированными участками?',
        answers: [
          'Не ниже 70% проектной',
          'Не ниже 60% проектной',
          'Не ниже 80% проектной',
          'Не ниже 50% проектной',
        ],
        correctAnswers: ['Не ниже 70% проектной'],
      },
      {
        code: '63672104',
        text:
          'Где при формировании состава должна находиться блокотюбинговозка или "коза"?',
        answers: [
          'В голове состава',
          'В хвосте состава',
          'В середине состава',
          'За буферной вагонеткой',
        ],
        correctAnswers: ['За буферной вагонеткой'],
      },
      {
        code: '63672105',
        text:
          'В каком случае для спуска и подъема грузов допускается применение грузоподъемных кранов с лебедками?',
        answers: [
          'В случае проходки шахтных стволов глубиной до 40 м',
          'В случае проходки шахтных стволов глубиной до 50 м',
          'В случае проходки шахтных стволов глубиной до 60 м',
        ],
        correctAnswers: ['В случае проходки шахтных стволов глубиной до 40 м'],
      },
      {
        code: '63672106',
        text: 'В какой цвет окрашиваются заземляющие проводники?',
        answers: [
          'В белый цвет',
          'В желтый цвет',
          'В черный цвет',
          'В голубой цвет',
        ],
        correctAnswers: ['В черный цвет'],
      },
      {
        code: '63672107',
        text:
          'В течение какого срока технический руководитель объекта с инженерными службами рассматривает случаи микроударов, толчков, стреляния, интенсивного заколообразования и шелушения при первом их появлении в целях установления причин и разработки мер борьбы с ними?',
        answers: [
          'В течение суток',
          'В течение 10 рабочих дней',
          'В течение 5 рабочих дней',
          'В течение месяца',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63672108',
        text:
          'С какой периодичностью главный механик организации должен проводить осмотр главной водоотливной установки?',
        answers: [
          'Не реже 1 раза в две недели',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
        ],
        correctAnswers: ['Не реже 1 раза в неделю'],
      },
      {
        code: '63672109',
        text:
          'Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
          'Только привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой',
          'Только ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C',
          'Все перечисленные действия',
        ],
        correctAnswers: [
          'Только ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
        ],
      },
      {
        code: '63672110',
        text: 'Какими способами осуществляется тушение подземных пожаров?',
        answers: [
          'Только активным тушением',
          'Только изоляцией горных выработок, в которых действует пожар',
          'Только комбинированным способом',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63672111',
        text:
          'Каким должно быть расстояние между вагонетками при ручной откатке на путях с уклоном более 0,005?',
        answers: [
          'Не менее 10 м',
          'Не менее 20 м',
          'Не менее 30 м',
          'Не менее 15 м',
        ],
        correctAnswers: ['Не менее 30 м'],
      },
      {
        code: '63672112',
        text:
          'Кем подписывается оперативная часть плана ликвидации аварий в конце последней позиции с указанием даты составления плана?',
        answers: [
          'Главным инженером шахты',
          'Командиром ВГСЧ',
          'Начальником ПВС',
          'Руководителем организации (предприятия)',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63672113',
        text:
          'Какое из перечисленных требований к тушению пожаров в вертикальных горных выработках указано верно?',
        answers: [
          'Пожары в вертикальных горных выработках тушатся активным способом снизу вверх',
          'При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола допускается изменение направления вентиляционной струи',
          'Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи',
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
        ],
        correctAnswers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
        ],
      },
      {
        code: '63672114',
        text:
          'Каким требованиям должны соответствовать лестничные отделения шахтных стволов?',
        answers: [
          'Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки',
          'Лестницы через каждые 3 м должны быть скреплены металлическими стяжками',
          'Расстояние между лестничными площадками не должно превышать 5 м',
          'Расстояние от основания лестницы до крепи или обшивки лестничного отделения должно быть не менее 1 м',
        ],
        correctAnswers: [
          'Лестницы должны быть установлены под углом не более 80° и выступать на 1 м над устьем выработки',
        ],
      },
      {
        code: '63672115',
        text:
          'Какие надписи должен иметь титульный лист плана ликвидации аварии?',
        answers: [
          'Надпись главного инженера организации (предприятия) с датой утверждения',
          'Надпись командира ВГСЧ с указанием даты',
          'Подпись главного инженера шахты',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63672116',
        text:
          'Каким из перечисленных прав не обладает комиссия по горным ударам?',
        answers: [
          'Останавливать горные работы, если нарушены требования правил и указаний, касающиеся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
          'Приглашать на заседания комиссии представителей организаций, работающих по проблеме борьбы с горными ударами',
          'Получать от руководителей объектов необходимую документацию, в которой описываются меры по борьбе с горными ударами',
          'Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
        ],
        correctAnswers: [
          'Штрафовать ответственных лиц в случае нарушения требований, касающихся вопросов безопасной отработки участков, склонных и опасных по горным ударам',
        ],
      },
      {
        code: '63672117',
        text:
          'Когда проводится ознакомление с планом мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'При проведении вводного инструктажа',
          'Только при проведении первичного инструктажа на рабочем месте',
          'Только при проведении внепланового инструктажа',
          'При проведении первичного, повторного, внепланового инструктажей',
        ],
        correctAnswers: [
          'При проведении первичного, повторного, внепланового инструктажей',
        ],
      },
      {
        code: '63672118',
        text:
          'По окончании каких мероприятий комиссия, назначенная руководителем организации, принимает решение о готовности оборудования тоннелепроходческого комплекса диаметром 3,6 м к эксплуатации?',
        answers: [
          'По окончании монтажа тоннелепроходческого комплекса',
          'По окончании проходки первых 50 м',
          'По окончании проходки первых 25 м без особых затруднений',
        ],
        correctAnswers: ['По окончании проходки первых 50 м'],
      },
      {
        code: '63672119',
        text:
          'Где должна располагаться вентиляторная установка для проветривания при проходке ствола?',
        answers: [
          'На поверхности на расстоянии не менее 5 м от ствола',
          'На поверхности на расстоянии не менее 10 м от ствола',
          'В горных выработках',
          'Расположение вентиляторной установки определяется проектом',
        ],
        correctAnswers: [
          'На поверхности на расстоянии не менее 10 м от ствола',
        ],
      },
      {
        code: '63672120',
        text:
          'Какова периодичность проведения ревизии, наладки и испытания эксплуатируемых подъемных установок специализированной организацией?',
        answers: [
          '2 раза в год',
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
        ],
        correctAnswers: ['1 раз в год'],
      },
      {
        code: '63672121',
        text: 'Как производится разработка породы при проходке выработок?',
        answers: [
          'Начиная с верхней части забоя',
          'Начиная с нижней части забоя',
          'Согласно плану производства работ',
          'Согласно технологической карте',
        ],
        correctAnswers: ['Начиная с верхней части забоя'],
      },
      {
        code: '63672122',
        text:
          'В течение какого срока после реконструкции или технического перевооружения объекта должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Не позднее 3 месяцев',
          'Не позднее6 месяцев',
          'Не позднее 45 календарных дней',
          'Не позднее 30 календарных дней',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63672123',
        text:
          'При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?',
        answers: [
          'Создание устойчивых форм',
          'Бурение разгрузочных щелей и скважин',
          'Контурное взрывание',
          'Всех перечисленных',
          'Крепление выработок, предотвращающее обрушение пород кровли и боков',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63672124',
        text:
          'С какого расстояния должны производиться горнопроходческие работы с соблюдением дополнительных мер безопасности по единому согласованному графику при проходке выработок встречными или сближающимися забоями без применения взрывных работ?',
        answers: [
          'Начиная с расстояния между ними менее 15 м',
          'Начиная с расстояния между ними менее 20 м',
          'Начиная с расстояния между ними менее полутора диаметров максимальной выработки',
        ],
        correctAnswers: [
          'Начиная с расстояния между ними менее полутора диаметров максимальной выработки',
        ],
      },
      {
        code: '63672125',
        text:
          'На сколько бадья должна не догружаться до верхнего края при выдаче породы?',
        answers: ['На 200 мм', 'На 100 мм', 'На 150 мм', 'На 250 мм'],
        correctAnswers: ['На 100 мм'],
      },
      {
        code: '63672126',
        text:
          'Какова минимально допустимая численность горноспасательного отделения или отделения вспомогательной горноспасательной команды (ВГК) для ведения горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе в подземных горных выработках?',
        answers: [
          'Пять человек',
          'Два человека',
          'Три человека',
          'Шесть человек',
        ],
        correctAnswers: ['Пять человек'],
      },
      {
        code: '63672127',
        text:
          'Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?',
        answers: [
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
          'Технический руководитель эксплуатирующей объект ведения горных работ организации',
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
        correctAnswers: [
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
      },
      {
        code: '63672128',
        text:
          'На кого возлагается руководство работами по локализации и ликвидации последствий аварий?',
        answers: [
          'На технического руководителя (главного инженера)',
          'На начальника аварийно-спасательной службы',
          'На начальника участка',
        ],
        correctAnswers: ['На технического руководителя (главного инженера)'],
      },
      {
        code: '63672129',
        text:
          'При каком угле наклона наклонной горной выработки пожар в ней тушится активным способом дистанционно?',
        answers: [
          'При угле наклона более 20°',
          'При угле наклона более 15°',
          'При угле наклона более 10°',
          'При любом угле наклона наклонной горной выработки',
        ],
        correctAnswers: ['При угле наклона более 20°'],
      },
      {
        code: '63672130',
        text:
          'Какого вида кабели должны применяться для прокладки по вертикальным и наклонным выработкам на период строительства?',
        answers: [
          'Бронированные кабели в свинцовой, алюминиевой или поливинилхлоридной оболочке',
          'Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции',
          'Кабели с резиновой оболочкой',
          'Бронированные кабели в поливинилхлоридной оболочке',
        ],
        correctAnswers: [
          'Силовые и контрольные кабели с проволочной броней в свинцовой, алюминиевой или поливинилхлоридной оболочке с обедненной или нестекающей пропиткой изоляции',
        ],
      },
      {
        code: '63672131',
        text:
          'Куда должен сообщать технический руководитель организации о каждом случае горного удара и микроудара?',
        answers: [
          'В службу технического надзора',
          'В территориальный орган Ростехнадзора',
          'В диспетчерскую службу',
          'Во все перечисленные службы',
        ],
        correctAnswers: ['В территориальный орган Ростехнадзора'],
      },
      {
        code: '63672132',
        text:
          'На каком расстоянии от действующих подземных коммуникаций запрещается применять землеройную технику?',
        answers: [
          'Менее 1 м по горизонтали или 2 м по вертикали',
          'Менее 1,5 м по горизонтали или 1 м по вертикали',
          'Менее 2 м по горизонтали или 1,5 м по вертикали',
          'Менее 2 м по горизонтали или 1 м по вертикали',
        ],
        correctAnswers: ['Менее 2 м по горизонтали или 1 м по вертикали'],
      },
      {
        code: '63672133',
        text:
          'Какие электрические сети разрешается применять для контактной электровозной откатки?',
        answers: [
          'Трехфазные электрические сети переменного тока с изолированной нейтралью линейным напряжением до 1000 В включительно',
          'Электрические сети постоянного тока напряжением до 300 В включительно',
          'Электрические сети переменного тока напряжением до 42 В включительно',
          'Электрические сети переменного тока напряжением до 110 В включительно',
        ],
        correctAnswers: [
          'Электрические сети постоянного тока напряжением до 300 В включительно',
        ],
      },
      {
        code: '63672134',
        text:
          'Какие из перечисленных данных должны указываться в оперативной части плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Только категория региона по природной и техногенной сейсмоопасности (по шкале Рихтера)',
          'Только категории шахты (месторождения) по удароопасности',
          'Только категории шахты (месторождения) по выбросоопасности',
          'Все перечисленные данные',
        ],
        correctAnswers: ['Все перечисленные данные'],
      },
      {
        code: '63672135',
        text:
          'Какая должна быть высота и ширина предохранительного вала, которым оборудованы съезды, транспортные бермы, рабочие площадки?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 0,5 м',
          'Не менее 1 м',
          'Не менее 2 м',
        ],
        correctAnswers: ['Не менее 0,5 м'],
      },
      {
        code: '63672136',
        text:
          'Каким должно быть расстояние между вагонетками при откатке бесконечным канатом?',
        answers: [
          'Не менее 5 м',
          'Не менее 4 м',
          'Не менее 3 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 5 м'],
      },
      {
        code: '63672137',
        text:
          'В каком случае допускаются отступления от проектно-технической документации?',
        answers: [
          'После проведения экспертизы промышленной безопасности',
          'После получения разрешения Ростехнадзора',
          'После предварительного письменного согласования с организацией-разработчиком',
          'Ни в каком случае',
        ],
        correctAnswers: [
          'После предварительного письменного согласования с организацией-разработчиком',
        ],
      },
      {
        code: '63672138',
        text:
          'С какой периодичностью должна проверяться исправность сигнализации оповещения об аварии с регистрацией результатов проверок?',
        answers: [
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в две недели',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
        ],
        correctAnswers: ['Не реже 1 раза в неделю'],
      },
      {
        code: '63672139',
        text:
          'В каком направлении должны ориентироваться основные горизонтальные и наклонные выработки околоствольных дворов, включая камеры?',
        answers: [
          'В направлении действия максимального горизонтального напряжения нетронутого массива',
          'В направлении действия максимального вертикального напряжения нетронутого массива',
          'В направлении действия минимального горизонтального напряжения нетронутого массива',
          'В направлении действия минимального вертикального напряжения нетронутого массива',
        ],
        correctAnswers: [
          'В направлении действия максимального горизонтального напряжения нетронутого массива',
        ],
      },
      {
        code: '63672140',
        text:
          'Какие из перечисленных вновь смонтированных комплексов могут быть приняты в эксплуатацию комиссией организации без участия представителей органов государственного надзора?',
        answers: [
          'Главные вентиляционные и водоотливные установки',
          'Горнопроходческие комплексы диаметром менее 3,6 м',
          'Электровозная откатка',
          'Горнопроходческие комплексы диаметром 3,6 м и более',
        ],
        correctAnswers: ['Горнопроходческие комплексы диаметром менее 3,6 м'],
      },
      {
        code: '63672141',
        text:
          'Что должно проводиться при эксплуатации оборудования в соответствии с регламентом завода-изготовителя?',
        answers: [
          'Контрольные испытания',
          'Техническое освидетельствование',
          'Ежедневные обходы',
        ],
        correctAnswers: ['Техническое освидетельствование'],
      },
      {
        code: '63672142',
        text:
          'В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?',
        answers: [
          'Только в случае когда пожар находится вблизи выработок со свежей струей воздуха',
          'Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород',
          'Только в случае тушения пожара водой',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Только в случае когда пожар находится вблизи выработок со свежей струей воздуха',
        ],
      },
      {
        code: '63672143',
        text:
          'Кто имеет право письменным приказом отстранить ответственного руководителя работ и принять руководство на себя или возложить его на другое лицо из числа должностных лиц шахты?',
        answers: [
          'Командир ВГСЧ',
          'Главный энергетик шахты',
          'Начальник шахты',
          'Главный инженер организации (предприятия)',
        ],
        correctAnswers: ['Главный инженер организации (предприятия)'],
      },
      {
        code: '63672144',
        text:
          'В течение какого времени при вводе новых или закрытии отработанных участков, изменении схемы вентиляции или путей выхода людей из шахты в план мероприятий по локализации и ликвидации последствий аварий должен внести изменения и согласовать их с командиром аварийно-спасательного формирования технический руководитель организации?',
        answers: [
          'В течение суток',
          'В течение трех дней',
          'В течение пяти дней',
          'В течение недели',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63672145',
        text:
          'Какую информацию не включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Характеристики аварийности, присущей объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63672146',
        text: 'Что из перечисленного допускается при разработке котлована?',
        answers: [
          'Использование бульдозеров на уклонах с углом более 30° и выдвижение ножа бульдозера за бровку откоса выемки',
          'При использовании экскаваторов с прямой лопатой разрабатывать откос высотой, превышающей высоту черпания',
          'Одновременное выполнение на одном участке котлована других работ в пределах зоны, определенной проектом производства работ, но не ближе 5 м от движущихся частей землеройных механизмов',
          'Выемка грунта с подкопом борта котлована',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63672147',
        text:
          'Какие заграждения должны быть установлены для защиты работающих в забое от опасности обрыва скипа или падения предметов при проходке наклонных выработок?',
        answers: [
          'Только заграждения в устье выработки',
          'Одно заграждение не дальше 25 м от места работы',
          'Два заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы',
          'Стопорные и улавливающие устройства на скипе',
        ],
        correctAnswers: [
          'Два заграждения: одно - в устье выработки, другое - не дальше 20 м от места работы',
        ],
      },
      {
        code: '63672148',
        text:
          'При достижении бетоном какой прочности должна начинаться разработка боковых штросс при проходке способом опертого свода в неустойчивых породах?',
        answers: [
          'Не ниже 85% проектной',
          'Не ниже 80% проектной',
          'Не ниже 75% проектной',
          'Не ниже 70% проектной',
        ],
        correctAnswers: ['Не ниже 70% проектной'],
      },
      {
        code: '63672149',
        text:
          'Что из перечисленного должно быть предусмотрено планом мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только организация материально-технического, инженерного и финансового обеспечения операций по локализации и ликвидации аварий на объекте',
          'Только порядок обеспечения постоянной готовности сил и средств к локализации и ликвидации последствий аварий на объекте с указанием организаций, которые несут ответственность за поддержание этих сил и средств в установленной степени готовности',
          'Только первоочередные действия при получении сигнала об аварии на объекте',
          'Только система взаимного обмена информацией между организациями - участниками локализации и ликвидации последствий аварий на объекте',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63672150',
        text:
          'В каких случаях в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (рассола, пульпы), работы по поиску и спасению людей не проводятся или прекращаются?',
        answers: [
          'Только если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного десятикратному сроку времени защитного действия СИЗОД',
          'Только если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 100 °C и более',
          'Только если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63672151',
        text:
          'Кем утверждается план ликвидации аварий при ведении работ в подземных условиях?',
        answers: [
          'Техническим руководителем эксплуатирующей организации (главным инженером организации)',
          'Руководителем эксплуатирующей организации',
          'Командиром горноспасательного формирования',
          'Начальником шахты',
        ],
        correctAnswers: [
          'Техническим руководителем эксплуатирующей организации (главным инженером организации)',
        ],
      },
      {
        code: '63672152',
        text:
          'Кем должна осуществляться проверка состояния предусмотренных планом ликвидации аварии материальных и технических средств для осуществления мероприятий по спасению людей и ликвидации аварий перед согласованием планов ликвидации аварий на новое полугодие? Выберите 2 варианта ответа.',
        answers: [
          'Работниками шахты',
          'Военизированной горноспасательной частью (ВГСЧ)',
          'Руководителем аварийно-спасательного формирования',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Работниками шахты',
          'Военизированной горноспасательной частью (ВГСЧ)',
        ],
      },
      {
        code: '63672153',
        text:
          'Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?',
        answers: [
          'Руководителем организации',
          'Техническим руководителем объекта',
          'Специализированной организацией, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Комиссией, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
        correctAnswers: ['Техническим руководителем объекта'],
      },
      {
        code: '63672154',
        text:
          'Какая принимается скорость передвижения людей в изолирующих самоспасателях по загазованным горизонтальным выработкам высотой 1,8 - 2,0 м?',
        answers: ['30 м/мин', '45 м/мин', '70 м/мин', '60 м/мин'],
        correctAnswers: ['60 м/мин'],
      },
      {
        code: '63672155',
        text:
          'Каково номинальное время защитного действия автономных изолирующих дыхательных аппаратов (ДА) со сжатым воздухом на объектах открытых горных работ?',
        answers: [
          'Не менее одного часа',
          'Не менее 50 минут',
          'Не менее 45 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее одного часа'],
      },
      {
        code: '63672156',
        text:
          'Какой запас прочности должны иметь канаты для спасательных лестниц, канаты для подвески полков, насосов, трубопроводов, проходческих агрегатов?',
        answers: ['9-кратный', '8-кратный', '7-кратный', '6-кратный'],
        correctAnswers: ['6-кратный'],
      },
      {
        code: '63672157',
        text: 'Какая документация должна находиться на командном пункте?',
        answers: [
          'Только журналы ОПО и ПАСС(Ф)',
          'Только план ликвидации аварий',
          'Только оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63672158',
        text:
          'Что из перечисленного содержится в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущей объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: [
          'Порядок действий в случае аварии на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63672159',
        text:
          'В каком случае разрешается производство работ при проходке стволов глубиной до 20 м с использованием в качестве средств подъема кранов?',
        answers: [
          'В случае производства работ без перекрытия устья ствола с бермой безопасности вокруг устья ствола не менее 1 м',
          'В случае производства работ без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей',
          'В случае наличия бермы безопасности не менее 1 м, обделки ствола, возвышенной над уровнем спланированной поверхности не менее чем на 0,5 м',
          'В случае производства работ без перекрытия устья ствола с бермой безопасности вокруг устья ствола не менее 3 м',
        ],
        correctAnswers: [
          'В случае производства работ без перекрытия устья ствола при устройстве сплошного прочного ограждения высотой не менее 1,5 м по периметру ствола с дверью для прохода людей',
        ],
      },
      {
        code: '63672160',
        text:
          'На выработках какой протяженности допускается укладка одного откаточного пути с устройством разъездов?',
        answers: [
          'Не более 200 м',
          'Не более 300 м',
          'Более 500 м',
          'Более 100 м',
        ],
        correctAnswers: ['Более 500 м'],
      },
      {
        code: '63672161',
        text:
          'Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?',
        answers: [
          'Крутопадающих мощных рудных тел',
          'Пологих рудных тел',
          'Горизонтальных рудных тел',
          'Наклонных рудных тел',
        ],
        correctAnswers: ['Крутопадающих мощных рудных тел'],
      },
      {
        code: '63672162',
        text:
          'Чем должны быть оборудованы на строительной площадке проходы для людей, проложенные по вязкой почве?',
        answers: [
          'Сплошным настилом шириной не менее 1 м',
          'Трапом шириной не менее 0,8 м с перилами высотой 1 м',
          'Решетчатым настилом шириной не менее 1,2 м',
          'Сплошным настилом шириной не менее 1,2 м с перилами высотой 1,1 м',
        ],
        correctAnswers: ['Сплошным настилом шириной не менее 1 м'],
      },
      {
        code: '63672163',
        text:
          'Каким должно быть количество самоспасателей в местах группового хранения?',
        answers: [
          'Количество самоспасателей должно соответствовать численности работающих в рабочей смене',
          'Количество самоспасателей должно соответствовать численности работающих на объекте в наиболее многочисленной смене',
          'Количество самоспасателей должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10%',
          'Количество самоспасателей должно быть согласовано с командиром профессиональной аварийно-спасательной службы (формирования)',
        ],
        correctAnswers: [
          'Количество самоспасателей должно превышать численность работающих на объекте в наиболее многочисленной смене не менее чем на 10%',
        ],
      },
      {
        code: '63672164',
        text:
          'С какой периодичностью лицо технического надзора должно осматривать подвижной состав и рельсовые пути?',
        answers: [
          'Не реже 1 раза в смену',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63672165',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях регламентации действий персонала при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях регламентации действий подразделений муниципальной пожарной охраны при возникновении аварии',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63672166',
        text:
          'Каким требованиям должны отвечать двери c торцевых сторон клети, служащей для спуска и подъема людей?',
        answers: [
          'Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм',
          'Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм',
          'Двери должны открываться внутрь и запираться засовом, расположенным снаружи; верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм',
          'Двери должны открываться наружу и запираться засовом, расположенным внутри, верхняя кромка двери должна быть выше уровня пола не менее чем на 1500 мм, а нижняя - не более 200 мм',
        ],
        correctAnswers: [
          'Двери должны открываться вовнутрь и запираться засовом, расположенным снаружи, верхняя кромка двери должна быть выше уровня пола не менее чем на 1200 мм, а нижняя - не более 150 мм',
        ],
      },
      {
        code: '63672167',
        text:
          'При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?',
        answers: [
          'При условии тушения пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека',
          'При условии отсутствия информации о содержании в выработке горючих газов',
          'При условии отсутствия в выработке пострадавших',
          'При условии тушения пожара в непроветриваемых тупиковых горных выработках',
        ],
        correctAnswers: [
          'При условии тушения пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека',
        ],
      },
      {
        code: '63672168',
        text:
          'В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?',
        answers: [
          'Если данные отсупления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам',
          'Не допускается ни в каком случае',
          'В соответствии с требованиями Правил безопасности при ведении горных работ и переработке твердых полезных ископаемых',
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
        correctAnswers: [
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63672169',
        text:
          'Что производится по окончании монтажа тоннелепроходческого комплекса?',
        answers: [
          'Опробование основных рабочих узлов, систем и технических устройств комплекса, включая устройства обеспечения безопасной эксплуатации',
          'Проходка первых 50 м тоннеля',
          'Приемка оборудования комплекса в эксплуатацию комиссией предприятия',
          'Приемка оборудования комплекса в эксплуатацию комиссией территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Опробование основных рабочих узлов, систем и технических устройств комплекса, включая устройства обеспечения безопасной эксплуатации',
        ],
      },
      {
        code: '63672170',
        text:
          'При какой численности работников организации функции лица, ответственного за осуществление производственного контроля, возлагаются на руководителя службы производственного контроля?',
        answers: [
          'Менее 150 человек',
          'Более 500 человек',
          'От 150 до 500 человек',
          'От 200 до 300 человек',
        ],
        correctAnswers: ['Более 500 человек'],
      },
      {
        code: '63672171',
        text:
          'В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?',
        answers: [
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
          'Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород',
          'Расположенных на любом удалении от плоскости сместителя тектонического нарушения',
        ],
        correctAnswers: [
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
        ],
      },
      {
        code: '63672172',
        text:
          'Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?',
        answers: [
          'Геомеханическими методами',
          'Геофизическими методами',
          'Визуальными наблюдениями за разрушением приконтурного массива выработок',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63672173',
        text:
          'В каком случае допускается отцеплять прицепное устройство от элемента обделки?',
        answers: [
          'В случае если элемент обделки скреплен не менее чем 2 болтами (1 по фланцу и 1 по торцу) с соседним собранным кольцом',
          'В случае если элемент обделки скреплен не менее чем 3 болтами (2 по фланцу и 1 по торцу) с соседним собранным кольцом',
          'В случае если элемент обделки скреплен 1 болтом с соседним собранным кольцом',
        ],
        correctAnswers: [
          'В случае если элемент обделки скреплен не менее чем 3 болтами (2 по фланцу и 1 по торцу) с соседним собранным кольцом',
        ],
      },
      {
        code: '63672174',
        text:
          'До какой минимальной температуры разрешается ведение горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'До температуры минус 20 °C',
          'До температуры минус 15 °C',
          'До температуры минус 10 °C',
          'До температуры минус 8 °C',
        ],
        correctAnswers: ['До температуры минус 20 °C'],
      },
      {
        code: '63672175',
        text:
          'Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано неверно?',
        answers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
          'Оперативные планы разрабатываются до окончания ведения горноспасательных работ',
          'Последующие оперативные планы (№ 2, 3 и т. д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
          'Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов',
        ],
        correctAnswers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
        ],
      },
      {
        code: '63672176',
        text:
          'После какой наработки двигателя или пробега должен проводиться планово-предупредительный ремонт машин с карбюраторным двигателем?',
        answers: [
          'После каждых 150 моточасов работы двигателя или 1500 км пробега',
          'После каждых 250 моточасов работы двигателя или 2500 км пробега',
          'После каждых 2500 моточасов работы двигателя или 25 000 км пробега',
          'После каждых 200 моточасов работы двигателя или 2000 км пробега',
        ],
        correctAnswers: [
          'После каждых 250 моточасов работы двигателя или 2500 км пробега',
        ],
      },
      {
        code: '63672177',
        text:
          'Какая площадь должна приходиться на одного человека при спуске и подъеме людей в бадьях?',
        answers: [
          'Не менее 0,4 м2 площади днища',
          'Не менее 0,35 м2 площади днища',
          'Не менее 0,25 м2 площади днища',
          'Не менее 0,2 м2 площади днища',
        ],
        correctAnswers: ['Не менее 0,25 м2 площади днища'],
      },
      {
        code: '63672178',
        text:
          'Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?',
        answers: [
          'Не ранее чем через 30 минут',
          'Не ранее чем через 60 минут',
          'Не ранее чем через 10 минут',
          'Не ранее чем через 1 час',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63672179',
        text:
          'На основании каких данных составляется оперативный план по локализации и ликвидации последствий аварий?',
        answers: [
          'Только на основании поступающей на командный пункт информации',
          'Только на основании анализа аварийной обстановки',
          'Только на основании прогноза развития аварии',
          'На основании всех перечисленных данных',
        ],
        correctAnswers: ['На основании всех перечисленных данных'],
      },
      {
        code: '63672180',
        text:
          'В каком случае ручная откатка рельсовых транспортных средств запрещается?',
        answers: [
          'В случае если их вес превышает 1,5 т',
          'В случае если необходимо приложить усилие более 15 кг',
          'В случае если уклон составляет более 0,01',
          'В случае если расстояние составляет более 50 м',
        ],
        correctAnswers: ['В случае если уклон составляет более 0,01'],
      },
      {
        code: '63672181',
        text:
          'При какой продолжительности остановки главного вентилятора людей необходимо выводить на поверхность со всех рабочих мест?',
        answers: [
          'Более чем на 30 мин',
          'Более чем на 45 мин',
          'Более чем на 60 мин',
          'Более чем на 120 мин',
        ],
        correctAnswers: ['Более чем на 120 мин'],
      },
      {
        code: '63672182',
        text:
          'Каково максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?',
        answers: ['0,2 МПа', '0,1 МПа', '0,3 МПа', '0,4 МПа'],
        correctAnswers: ['0,2 МПа'],
      },
      {
        code: '63672183',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите 2 варианта ответа.',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 40 дней после внесения изменений в системы управления технологическими процессами на объекте',
          'В случае назначения нового руководителя организации',
          'Не позднее 15 дней после внесения изменений в применяемые при осуществлении производственного контроля за соблюдением требований промышленной безопасности на объекте методики (методы) измерений или типы средств измерений',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63672184',
        text:
          'Кем должен тщательно рассматриваться маршрут движения отделений ВГСЧ на вывод людей и ликвидацию аварий в каждом конкретном случае? Выберите 2 варианта ответа.',
        answers: [
          'Главным инженером шахты',
          'Командиром ВГСЧ',
          'Представителем МЧС России',
          'Представителем Ростехнадзора',
          'Руководителем подразделения противопожарной службы',
        ],
        correctAnswers: ['Главным инженером шахты', 'Командиром ВГСЧ'],
      },
      {
        code: '63672185',
        text:
          'Какую защиту должны иметь трансформаторы подземных электрических сетей до 1000 В?',
        answers: [
          'Защиту от токов короткого замыкания',
          'Защиту от токов короткого замыкания, от перегрузки, нулевую',
          'Защиту от опасных токов утечки на землю',
          'Защиту от блуждающих токов',
        ],
        correctAnswers: ['Защиту от токов короткого замыкания'],
      },
      {
        code: '63672186',
        text:
          'Чье решение является обязательным для выполнения в случае разногласия между командиром ВГСЧ и ответственным руководителем работ при организации работ по ликвидации аварии?',
        answers: [
          'Ответственного руководителя работ по ликвидации аварий',
          'Командира ВГСЧ',
          'Вышестоящего руководства организации',
          'Экспертной комиссии',
        ],
        correctAnswers: [
          'Ответственного руководителя работ по ликвидации аварий',
        ],
      },
      {
        code: '63672187',
        text:
          'В каком случае эксплуатирующая организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах на несколько опасных объектов?',
        answers: [
          'В случае если два и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если это регламентировано внутренней документацией организации',
          'В случае если объекты зарегистрированы в государственном реестре опасных производственных объектов',
          'Ни в каком случае, план мероприятий разрабатывается на каждый опасный объект отдельно',
        ],
        correctAnswers: [
          'В случае если два и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63672188',
        text:
          'В каких местах в выработках, где подвешен контактный провод, должны быть вывешены светящиеся надписи, предупреждающие, что провод под напряжением?',
        answers: [
          'Через каждые 500 м',
          'Через каждые 250 м и на пересечениях с другими выработками',
          'Только на пересечениях с другими выработками',
          'Через каждые 100 м и на пересечении с другими выработками',
        ],
        correctAnswers: [
          'Через каждые 100 м и на пересечении с другими выработками',
        ],
      },
      {
        code: '63672189',
        text: 'Кем в организации утверждается перечень опасных зон?',
        answers: [
          'Главным маркшейдером',
          'Начальником службы производственного контроля',
          'Техническим руководителем шахты',
          'Руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем шахты'],
      },
      {
        code: '63672190',
        text:
          'В каких случаях должен производиться контроль удароопасности в выработках?',
        answers: [
          'При изменениях горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'При изменениях горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'При проявлении внешних признаков удароопасности',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63672191',
        text:
          'Каким должно быть расстояние между стенками стволов при строительстве подземного объекта на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Не менее 35 м',
          'Не менее 40 м',
          'Не менее 45 м',
          'Не менее 50 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63672192',
        text:
          'С какой периодичностью начальник участка обязан проверять состояние каждого рабочего места?',
        answers: [
          'Не менее 1 раза в сутки',
          'Не менее 1 раза в смену',
          'Не менее 1 раза в неделю',
          'Не менее 2 раз в месяц',
        ],
        correctAnswers: ['Не менее 1 раза в сутки'],
      },
      {
        code: '63672193',
        text:
          'С какой периодичностью главный энергетик организации должен проводить проверку исправности аппаратов устройств контроля изоляции и отключающих устройств?',
        answers: [
          '1 раз в день',
          '1 раз в неделю',
          '1 раз в месяц',
          '1 раз в квартал',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63672194',
        text:
          'На какую глубину должно быть забетонировано устье траншеи до начала выполнения работ по ее разработке методом "стена в грунте"?',
        answers: [
          'На глубину не менее 0,5 м',
          'На глубину 2 м',
          'На глубину не менее 1,5 м',
          'На глубину 0,8 м',
        ],
        correctAnswers: ['На глубину не менее 1,5 м'],
      },
      {
        code: '63672195',
        text:
          'С какой периодичностью должна проводиться проверка исправности самоспасателей, закрепленных индивидуально?',
        answers: [
          '1 раз в год',
          'Ежемесячно',
          'Ежеквартально',
          '1 раз в полгода',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63672196',
        text:
          'Тушение каких рудников в подземных горных выработках запрещается производить водой?',
        answers: [
          'Угольных рудников',
          'Асбестовых рудников',
          'Соляных и калийных рудников',
        ],
        correctAnswers: ['Соляных и калийных рудников'],
      },
      {
        code: '63672197',
        text:
          'Какие приложения следует приобщить к плану мероприятий по локализации и ликвидации последствий аварий при его согласовании и утверждении?',
        answers: [
          'Только акт о расчетном и фактическом времени выхода людей в изолирующих самоспасателях на свежую струю из наиболее отдаленных горных выработок шахты',
          'Только акт о состоянии запасных выходов из очистных забоев, участков и шахты и о пригодности их для выхода людей и прохода горноспасателей в респираторах',
          'Только акт проверки состояния насосных станций, водонепроницаемых перемычек и других средств по предотвращению затопления горных выработок',
          'Только акт проверки исправности действия реверсивных устройств на главных вентиляционных установках с пропуском реверсированной воздушной струи по горным выработкам',
          'Все перечисленные документы, включая акт проверки наличия и исправности противопожарных средств и оборудования',
        ],
        correctAnswers: [
          'Все перечисленные документы, включая акт проверки наличия и исправности противопожарных средств и оборудования',
        ],
      },
      {
        code: '63672198',
        text:
          'Какие из перечисленных документов должны прилагаться к плану мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Распределение обязанностей между отдельными лицами, участвующими в ликвидации аварий, и порядок их действий',
          'Обязанности дежурного по шахте',
          'Список лиц, участвующих в ликвидации аварий, и аксонометрическая схема проветривания горных выработок',
          'Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии',
        ],
        correctAnswers: [
          'Распределение обязанностей между участвующими в ликвидации аварий, порядок их действий и список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии',
        ],
      },
      {
        code: '63672199',
        text:
          'Кто несет персональную ответственность за своевременное размещение в планах горных работ результатов регионального и локального прогноза удароопасности?',
        answers: [
          'Технический руководитель организации',
          'Руководитель организации',
          'Председатель комиссии по горным ударам',
          'Начальник службы прогноза и предотвращения горных ударов',
        ],
        correctAnswers: [
          'Начальник службы прогноза и предотвращения горных ударов',
        ],
      },
      {
        code: '63672200',
        text:
          'При каком подходе к опасной зоне главный и участковые маркшейдеры обязаны подготовить письменное уведомление техническому руководителю и начальнику участка?',
        answers: [
          'Не позднее чем за 20 м до нее',
          'Не позднее чем за 30 м до нее',
          'Не позднее чем за 10 м до нее',
          'Не позднее чем за 40 м до нее',
        ],
        correctAnswers: ['Не позднее чем за 20 м до нее'],
      },
      {
        code: '63672201',
        text:
          'С какой периодичностью начальник участка в присутствии главного механика организации должен осматривать подъемные сосуды, подвесные и прицепные устройства и другие элементы подъемной установки?',
        answers: [
          'Ежесуточно',
          'Еженедельно',
          'Не реже 1 раза в 15 дней',
          'Не реже 1 раза в месяц',
        ],
        correctAnswers: ['Не реже 1 раза в 15 дней'],
      },
      {
        code: '63672202',
        text:
          'На какой срок составляется план мероприятий по локализации и ликвидации последствий аварий для объектов, на которых ведутся горные работы в подземных условиях?',
        answers: ['На 1 квартал', 'На 6 месяцев', 'На 1 год', 'На 1,5 года'],
        correctAnswers: ['На 6 месяцев'],
      },
      {
        code: '63672203',
        text:
          'С какой периодичностью механик участка должен проверять техническое состояние буровых установок?',
        answers: ['Ежесменно', 'Еженедельно', 'Ежемесячно', 'Ежеквартально'],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63672204',
        text:
          'У кого должны храниться выписки из плана мероприятий по локализации и ликвидации последствий аварий, указывающие пути выхода с конкретного участка шахты?',
        answers: [
          'У заместителя технического руководителя объекта подземного строительства',
          'У горного диспетчера',
          'У начальника объекта подземного строительства',
          'У начальника соответствующего участка',
        ],
        correctAnswers: ['У начальника соответствующего участка'],
      },
      {
        code: '63672205',
        text:
          'При помощи чего производится буксировка неисправных самоходных машин в подземных выработках?',
        answers: [
          'При помощи жесткой сцепки длиной не более 1 м',
          'При помощи сцепки длиной не более 5 м',
          'При помощи сцепки длиной не более 1,2 м',
          'При помощи гибкой сцепки длиной 5 м',
        ],
        correctAnswers: ['При помощи жесткой сцепки длиной не более 1 м'],
      },
      {
        code: '63672206',
        text:
          'Кто определяет места возведения изоляционных перемычек, предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?',
        answers: [
          'Руководитель работ по ликвидации аварии',
          'Руководитель горноспасательных работ',
          'Технический руководитель (главный инженер) ОПО',
          'Лицо, ответственное за пожарную безопасность ОПО',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63672207',
        text:
          'При каком условии разрешается осмотр забоя и его крепление при производстве работ проходческим комбайном?',
        answers: [
          'Если станция управления комбайном обесточена и вывешен плакат "Не включать - работают люди!"',
          'Если вывешен освещенный транспарант "Внимание! Опасная зона! Ведутся работы"',
          'Если комбайн отведен от забоя на 1,5 - 2 м и смещен к одному из боков выработки',
          'Если комбайн работает',
        ],
        correctAnswers: [
          'Если комбайн отведен от забоя на 1,5 - 2 м и смещен к одному из боков выработки',
        ],
      },
      {
        code: '63672208',
        text:
          'Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе на открытых горных выработках?',
        answers: [
          'Из 3 человек',
          'Из 2 человек',
          'Из 5 человек',
          'Из 6 человек',
        ],
        correctAnswers: ['Из 3 человек'],
      },
      {
        code: '63672209',
        text:
          'На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: [
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Изменение параметров и периодичности не осуществляется ни в каком случае',
          'На основе заключения представителя территориального управления Ростехнадзора',
        ],
        correctAnswers: [
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63672210',
        text: 'Забором какой высоты должна ограждаться стройплощадка?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 2 м',
          'Не менее 1,7 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63672211',
        text:
          'С кем должно быть согласовано проведение естественного проветривания подземных выработок мелкого заложения, имеющих не менее двух выходов и устойчивое направление вентиляционной струи?',
        answers: [
          'С территориальными органами Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'С территориальными органами Ростехнадзора',
          'С вышестоящей организацией',
          'С проектной организацией',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63672212',
        text:
          'На какие виды могут подразделяться прогнозы удароопасности участков массива горных пород? Выберите 2 варианта ответа.',
        answers: [
          'Региональный прогноз',
          'Локальный прогноз',
          'Местный прогноз',
          'Федеральный прогноз',
        ],
        correctAnswers: ['Региональный прогноз', 'Локальный прогноз'],
      },
      {
        code: '63672213',
        text:
          'Кто выдает разрешение на производство разбивочных и основных маркшейдерских работ вблизи забоев подземных выработок?',
        answers: [
          'Главный маркшейдер',
          'Начальник участка',
          'Горный мастер',
          'Технический руководитель шахты',
        ],
        correctAnswers: ['Горный мастер'],
      },
      {
        code: '63672214',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63672215',
        text:
          'В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?',
        answers: [
          'Только если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях',
          'Только если имели место микроудары и горные удары',
          'Только если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63672216',
        text:
          'Какой категории по степени опасности участков горного массива вокруг выработок не существует?',
        answers: ['"Опасно"', '"Неопасно"', '"Чрезвычайно опасно"'],
        correctAnswers: ['"Чрезвычайно опасно"'],
      },
      {
        code: '63672217',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Руководитель отдела промышленной безопасности',
          'Командир профессиональной аварийно-спасательной службы (формирования)',
        ],
        correctAnswers: [
          'Руководитель (заместитель руководителя) организации, эксплуатирующей объект, либо руководитель обособленного подразделения юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63672218',
        text:
          'Кем составляется акт проверки исправности противопожарных средств и оборудования плана ликвидации аварий?',
        answers: [
          'Комиссией в составе начальника шахты, начальника ПВС и инспектора Ростехнадзора',
          'Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту',
          'Комиссией в составе главного инженера шахты, начальника ПВС и командира ВГСЧ, обслуживающей данную шахту',
        ],
        correctAnswers: [
          'Комиссией в составе начальника шахты, начальника ПВС, механика шахты и командира ВГСЧ, обслуживающей данную шахту',
        ],
      },
      {
        code: '63672219',
        text:
          'Для кого предназначены Правила безопасности при строительстве подземных сооружений?',
        answers: [
          'Для рабочих',
          'Для руководителей и специалистов организаций',
          'Для студентов высших учебных заведений, проходящих производственную практику',
          'Для специалистов организаций и органов надзора',
        ],
        correctAnswers: ['Для руководителей и специалистов организаций'],
      },
      {
        code: '63672220',
        text:
          'В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?',
        answers: [
          'Введение',
          'Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков',
          'Методы прогноза удароопасности участков массива горных пород и руд',
          'Общие положения',
        ],
        correctAnswers: ['Общие положения'],
      },
      {
        code: '63672221',
        text:
          'Кто проводит осмотр состояния крепления при выполнении работ в котлованах и траншеях с креплением бортов (стен) один раз в неделю?',
        answers: [
          'Технический руководитель',
          'Начальник участка (прораб)',
          'Мастер',
          'Любой из перечисленных специалистов',
        ],
        correctAnswers: ['Начальник участка (прораб)'],
      },
      {
        code: '63672222',
        text:
          'Какая навивка каната на барабан допускается на машинах шахтного подъема, используемых для подъема и спуска людей?',
        answers: ['Однослойная', 'Двухслойная', 'Многослойная', 'Трехслойная'],
        correctAnswers: ['Однослойная'],
      },
      {
        code: '63672223',
        text:
          'Кто несет ответственность за своевременное и правильное составление плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководитель организации',
          'Технический руководитель организации и командир профессиональной аварийно-спасательной службы (формирования)',
          'Специалист по промышленной безопасности',
          'Начальник участка',
        ],
        correctAnswers: [
          'Технический руководитель организации и командир профессиональной аварийно-спасательной службы (формирования)',
        ],
      },
      {
        code: '63672224',
        text:
          'На кого возлагается персональная ответственность за наличие и качество материалов, находящихся в противопожарных подземных и поверхностных складах, в соответствии с планом ликвидации аварий при ведении работ в подземных условиях?',
        answers: [
          'На начальника шахты',
          'На технического руководителя эксплуатирующей организации',
          'На командира горноспасательного формирования',
          'На специально назначенное лицо технического надзора',
        ],
        correctAnswers: ['На начальника шахты'],
      },
      {
        code: '63672225',
        text:
          'В течение какого времени после внесения изменений в системы управления технологическими процессами на объекте должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее трех месяцев',
          'Не позднее полугода',
          'Не позднее одного года',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63672226',
        text:
          'В какие сроки утверждается план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Не позднее 30 дней до ввода его в действие',
          'В день ввода его в действие',
          'Не позднее 15 дней до ввода его в действие',
          'После проведения учебной тревоги',
        ],
        correctAnswers: ['Не позднее 15 дней до ввода его в действие'],
      },
      {
        code: '63672227',
        text:
          'При каком значении прочности бетона допускается начинать разработку в устойчивых породах?',
        answers: [
          'Не менее 60%',
          'Не менее 65%',
          'Не менее 70%',
          'Не менее 75%',
        ],
        correctAnswers: ['Не менее 75%'],
      },
      {
        code: '63672228',
        text:
          'Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?',
        answers: [
          'Указаниями научно-исследовательского института',
          'Локальным прогнозом удароопасности',
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
          'Техническими условиями, разработанными специализированной организацией',
        ],
        correctAnswers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
        ],
      },
      {
        code: '63672229',
        text:
          'Что из перечисленного входит в должностные обязанности руководителя горноспасательных работ (РГСР)?',
        answers: [
          'Только обеспечение прибытия горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА или оперативным планом',
          'Только получение заданий от РЛА на проведение горноспасательных работ',
          'Только проведение анализа полученной от РЛА информации о происшедшей аварии',
          'Только организация ведения оперативного журнала ПАСС(Ф)',
          'Только организация ведения горноспасательных работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63672230',
        text:
          'Какие данные должны быть указаны на информационном щите строительной площадки?',
        answers: [
          'Наименование объекта, фамилии ответственных производителей работ, сроки начала и окончания работ',
          'Наименование объекта, генподрядчик, заказчик, сроки начала и окончания работ',
          'Наименование объекта, генподрядчик, заказчик, фамилии ответственных производителей работ, номера телефонов, сроки начала и окончания работ',
          'Наименование объекта, генподрядчик, фамилии ответственных производителей работ, сроки начала и окончания работ',
        ],
        correctAnswers: [
          'Наименование объекта, генподрядчик, заказчик, фамилии ответственных производителей работ, номера телефонов, сроки начала и окончания работ',
        ],
      },
      {
        code: '63672231',
        text:
          'Барьером какой высоты должен быть огражден лесоспуск на поверхности?',
        answers: ['1 м', '1,2 м', '1,5 м', '2 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63672232',
        text:
          'Каково максимальное значение тормозного пути состава при перевозке грузов на максимальном уклоне?',
        answers: ['40 м', '50 м', '60 м', '70 м'],
        correctAnswers: ['40 м'],
      },
      {
        code: '63672233',
        text:
          'С какой периодичностью должны испытываться подъемные канаты людских и грузолюдских подъемных установок?',
        answers: [
          'Через каждые 6 месяцев, считая со дня их навески',
          'Через каждые 6 месяцев, считая со дня их последнего испытания',
          'Через 12 месяцев, считая со дня их навески, а затем через каждые 6 месяцев',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Через каждые 6 месяцев, считая со дня их навески'],
      },
      {
        code: '63672234',
        text:
          'На каком расстоянии при монтаже и демонтаже сборной обделки должны быть расположены лебедки от поднимаемого груза?',
        answers: [
          'Не ближе 2,5 м по горизонтали',
          'Не ближе 3 м и не далее 15 м по горизонтали',
          'Не ближе 20 м по горизонтали',
          'Не ближе 5 м и не далее 15 м по горизонтали',
        ],
        correctAnswers: ['Не ближе 5 м и не далее 15 м по горизонтали'],
      },
      {
        code: '63672235',
        text:
          'Какое минимальное количество независимых сигнальных устройств должна иметь каждая подъемная установка при проходке и углублении стволов согласно Правилам безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: ['Два', 'Три', 'Четыре'],
        correctAnswers: ['Два'],
      },
      {
        code: '63672236',
        text:
          'При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?',
        answers: [
          'При обрушении в горной выработке',
          'При пожаре в тупиковой выработке',
          'При взрыве метана и (или) угольной пыли',
          'При внезапном выбросе угля (породы)',
        ],
        correctAnswers: ['При обрушении в горной выработке'],
      },
      {
        code: '63672237',
        text:
          'Чем должно быть укомплектовано каждое место размещения первичных средств пожаротушения?',
        answers: [
          'Одним огнетушителем',
          'Противопожарным полотном',
          'Лопатой и ящиком с песком вместимостью 0,1 м3',
          'Не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,2 м3',
        ],
        correctAnswers: [
          'Не менее чем двумя огнетушителями и ящиком с песком вместимостью 0,2 м3',
        ],
      },
      {
        code: '63672238',
        text:
          'Какое положение не соответствует требованиям безопасности при проходке стволов?',
        answers: [
          'Пересечение выработок должно осуществляться под прямым или близким к нему углом',
          'При проходке стволов допускается отставание постоянной крепи от забоя не более одного диаметра ствола вчерне',
          'Образующийся при сбойке целик должен быть приведен при категории "Опасно" в неопасное состояние по всей площади',
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями',
        ],
        correctAnswers: [
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 15 м должна вестись двумя забоями',
        ],
      },
      {
        code: '63672239',
        text:
          'Что предусматривается в плане ликвидации аварий при ведении работ в подземных условиях?',
        answers: [
          'Только мероприятия по спасению людей, пути вывода людей, застигнутых аварией, из зоны опасного воздействия, связанной с аварией',
          'Только мероприятия по ликвидации аварий и предупреждению их развития',
          'Только действия военизированных горноспасательных частей (ВГСЧ) и вспомогательных горноспасательных команд (ВГК) в начальной стадии возникновения аварий',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63672240',
        text:
          'С какой периодичностью механик участка должен осуществлять контроль за техническим состоянием машин с двигателями внутреннего сгорания с записью в журнале?',
        answers: [
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в полгода',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в месяц',
        ],
        correctAnswers: ['Не реже 1 раза в неделю'],
      },
    ],
  },
  9: {
    63701: [
      {
        code: '63701000',
        text:
          'Как должна распределяться нагрузка на каждое из ПС, если подъем и перемещение груза осуществляют двумя ПС?',
        answers: [
          'Нагрузка, приходящаяся на каждое ПС, должна быть не более 0,45% грузоподъемности ПС, имеющего меньшую грузоподъемность',
          'Нагрузка, приходящаяся на каждое ПС, должна быть по возможности одинаковой',
          'Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС',
          'Нагрузка, приходящаяся на каждое ПС, должна быть выровнена несимметричной строповкой груза',
          'Нагрузка, приходящаяся на каждое ПС, должна быть определена взвешивающими устройствами подъемных сооружений',
        ],
        correctAnswers: [
          'Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС',
        ],
      },
      {
        code: '63701001',
        text:
          'Какое из перечисленных требований к люку (проему) для подъема и опускания грузов, находящегося в перекрытии производственного помещения, указано неверно?',
        answers: [
          'Должен иметь постоянное ограждение высотой не менее 1,1 м',
          'Должен иметь устройство световой сигнализации',
          'Должен иметь сплошное ограждение понизу на высоту 0,15 м',
          'Должен иметь надписи габаритов проема',
        ],
        correctAnswers: ['Должен иметь надписи габаритов проема'],
      },
      {
        code: '63701002',
        text:
          'При каком положении крана на надземном рельсовом пути следует проверять соответствие расстояния от выступающих частей торцов крана до колонн, стен здания и перил проходных галерей?',
        answers: [
          'При положении крана, когда колеса одной из концевых балок максимально смещены в поперечном направлении относительно рельса',
          'При положении крана, которое соответствует наибольшему уширению колеи рельсового пути в зоне, обслуживаемой краном',
          'При фактическом расположении колес крана относительно рельса во время проведения измерений',
          'При симметричном расположении колес крана относительно рельса',
        ],
        correctAnswers: [
          'При симметричном расположении колес крана относительно рельса',
        ],
      },
      {
        code: '63701003',
        text:
          'Какое расстояние установлено от нижней точки крана (не считая грузозахватного органа), передвигающегося по надземному рельсовому пути, до пола цеха или площадок, на которых во время работы крана могут находиться люди (за исключением площадок, предназначенных для ремонта крана)?',
        answers: [
          'Не менее 2000 мм',
          'Не менее 1800 мм',
          'Не менее 1600 мм',
          'Не менее 2500 мм',
        ],
        correctAnswers: ['Не менее 2000 мм'],
      },
      {
        code: '63701004',
        text:
          'В каких случаях внеочередная проверка наземных рельсовых путей проводится в объеме плановой проверки?',
        answers: [
          'По распоряжению территориального органа Ростехнадзора',
          'По окончании летнего периода',
          'После ливней или зимних оттепелей',
          'После длительных морозов или метелей',
          'После 10 смен работы ПС в условиях высоких температур (свыше 36 °C)',
        ],
        correctAnswers: ['После ливней или зимних оттепелей'],
      },
      {
        code: '63701005',
        text:
          'Какие из перечисленных ПС подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны стрелового типа грузоподъемностью до 1 т включительно',
          'Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении',
          'Краны стрелового типа с постоянным вылетом или не снабженные механизмом поворота',
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
        correctAnswers: [
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
      },
      {
        code: '63701006',
        text:
          'С учетом требований какого документа должна выполняться утилизация (ликвидация) ПС? Укажите все правильные ответы.',
        answers: [
          'Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Руководства (инструкции) по эксплуатации ПС',
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
        ],
        correctAnswers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Руководства (инструкции) по эксплуатации ПС',
        ],
      },
      {
        code: '63701007',
        text:
          'Какие из перечисленных сведений должны быть указаны в инструкциях, определяющих действия работников в аварийных ситуациях в организации, эксплуатирующей ОПО с ПС?',
        answers: [
          'Только оперативные действия по предотвращению и локализации аварий',
          'Только порядок использования системы пожаротушения в случае локальных возгораний оборудования ОПО',
          'Только порядок приведения ПС в безопасное положение в нерабочем состоянии, схема и порядок эвакуации крановщика (оператора), покидающего кабину управления ПС',
          'Только места, отведенные в ОПО, для нахождения ПС в нерабочем состоянии',
          'Все перечисленные сведения',
        ],
        correctAnswers: ['Все перечисленные сведения'],
      },
      {
        code: '63701008',
        text:
          'Кем должен быть обустроен переезд транспортных средств через пути ПС, передвигающихся по наземному рельсовому пути?',
        answers: [
          'Эксплуатирующей организацией',
          'Проектной организацией',
          'Экспертной организацией',
          'Администрацией субъекта, на котором расположено ПС',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63701009',
        text:
          'Каким требованиям должны соответствовать стальные цепи, устанавливаемые на ПС?',
        answers: [
          'Стальные цепи должны соответствовать по марке и разрывному усилию указанным в паспорте ПС, иметь сертификат предприятия-изготовителя цепи',
          'Стальные цепи должны быть только испытаны в соответствии с требованиями Правил устройства и безопасной эксплуатации грузоподъемных кранов',
          'Стальные цепи должны только соответствовать диаметру и разрывному усилию, указанным в паспорте ПС',
          'Стальные цепи должны быть только сертифицированы, коэффициент запаса прочности должен быть не менее 6',
        ],
        correctAnswers: [
          'Стальные цепи должны соответствовать по марке и разрывному усилию указанным в паспорте ПС, иметь сертификат предприятия-изготовителя цепи',
        ],
      },
      {
        code: '63701010',
        text:
          'Какие требования, установленные для специализированной организации, осуществляющей деятельность по монтажу (демонтажу), наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО, указаны верно?',
        answers: [
          'Только определять процедуры контроля соблюдения технологических процессов',
          'Только устанавливать ответственность, полномочия и взаимоотношения работников, занятых в управлении, выполнении или проверке выполнения работ',
          'Только располагать необходимым персоналом, а также инженерно-техническими работниками, уполномоченными на выполнение своих обязанностей, в том числе выявления случаев отступлений от требований к качеству работ, от процедур проведения работ и для принятия мер по предупреждению или сокращению таких отступлений',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63701011',
        text:
          'Какие требования к передвижению стрелового самоходного крана указаны неверно?',
        answers: [
          'Стреловым самоходным кранам допускается перемещение с грузом на крюке',
          'Возможность перемещения с грузом на крюке устанавливается в соответствии с руководством (инструкцией) по эксплуатации крана',
          'Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана',
          'Движение крана с места при раскачивающемся грузе запрещается',
          'Основание, по которому перемещается кран с грузом, должно быть ровным и иметь уклон не более указанного в руководстве (инструкции) по эксплуатации крана',
        ],
        correctAnswers: [
          'Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана',
        ],
      },
      {
        code: '63701012',
        text:
          'Какое расстояние установлено от нижних выступающих частей крана (не считая грузозахватного органа), передвигающегося по надземному рельсовому пути, до расположенного в зоне действия оборудования?',
        answers: [
          'Не менее 400 мм',
          'Не менее 350 мм',
          'Не менее 300 мм',
          'Не более 250 мм',
        ],
        correctAnswers: ['Не менее 400 мм'],
      },
      {
        code: '63701013',
        text:
          'На сколько выше встречающихся на пути предметов и оборудования должны находиться стрелы кранов при их повороте или перемещении?',
        answers: [
          'Не менее чем на 300 мм',
          'Не менее чем на 400 мм',
          'Не менее чем на 500 мм',
          'Не менее чем на 200 мм',
        ],
        correctAnswers: ['Не менее чем на 500 мм'],
      },
      {
        code: '63701014',
        text:
          'Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?',
        answers: [
          'Технических условий ПС',
          'Руководства (инструкции) по эксплуатации ПС',
          'Паспорта ПС',
          'Формуляра ПС',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации ПС'],
      },
      {
        code: '63701015',
        text:
          'Что включает в себя проверка состояния рельсового пути, находящегося в эксплуатации? Укажите все правильные ответы.',
        answers: [
          'Ежесменный осмотр',
          'Плановую или внеочередную проверку состояния',
          'Испытания контрольным грузом',
          'Проверку соответствия проектной документации',
        ],
        correctAnswers: [
          'Ежесменный осмотр',
          'Плановую или внеочередную проверку состояния',
        ],
      },
      {
        code: '63701016',
        text:
          'С какой периодичностью должно осуществляться проведение плановых ремонтов подъемных сооружений (ПС)? Укажите все правильные ответы.',
        answers: [
          'После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС',
          'Не реже 1 раза в 3 года',
          'Через установленный инструкцией по эксплуатации ПС интервал времени',
          'Внепланово по распоряжению технического руководителя организации, эксплуатирующей ПС',
          'После наработки 1500 машино-часов (циклов)',
        ],
        correctAnswers: [
          'После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС',
          'Через установленный инструкцией по эксплуатации ПС интервал времени',
        ],
      },
      {
        code: '63701017',
        text:
          'Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?',
        answers: [
          'Допускается только после принятия решения ответственным за осуществление производственного контроля при эксплуатации ПС',
          'Допускается только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях',
          'Допускается только при наличии согласования работ с территориальным органом Ростехнадзора',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях',
        ],
      },
      {
        code: '63701018',
        text:
          'В какой документации устанавливаются нормы, согласно которым определяется качество ремонтных сварных соединений ПС?',
        answers: [
          'Только в проекте ремонта, реконструкции или модернизации ПС',
          'Только в эксплуатационной документации',
          'Во всей перечисленной документации',
        ],
        correctAnswers: ['Во всей перечисленной документации'],
      },
      {
        code: '63701019',
        text:
          'Какие обязанности организации, эксплуатирующей подъемные сооружения (далее – ПС), указаны неверно?',
        answers: [
          'Устанавливать порядок контроля обучения и периодической проверки знаний персонала, работающего с ограничителями, указателями и регистраторами',
          'Обеспечивать соблюдение технологического процесса транспортировки грузов и приостановку работы ПС в случае возникновения угрозы аварийной ситуации',
          'Организовывать считывание данных с регистратора параметров работы ПС не реже сроков, указанных в руководстве (инструкции) по эксплуатации регистратора',
          'При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год',
          'Все перечисленные',
        ],
        correctAnswers: [
          'При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год',
        ],
      },
      {
        code: '63701020',
        text:
          'Кем должны быть утверждены ППР, ТК на погрузочно-разгрузочные работы и выданы на участки, где будут использоваться ПС, до начала ведения работ?',
        answers: [
          'Проектной организацией',
          'Эксплуатирующей ПС организацией',
          'Ростехнадзором',
          'Специализированной организацией',
        ],
        correctAnswers: ['Эксплуатирующей ПС организацией'],
      },
      {
        code: '63701021',
        text:
          'Какой документ подтверждает готовность рельсового пути к эксплуатации, в том числе после ремонта (реконструкции)?',
        answers: [
          'Акт осмотра',
          'Распоряжение на ввод в эксплуатацию',
          'Акт сдачи-приемки',
          'Протокол испытаний',
        ],
        correctAnswers: ['Акт сдачи-приемки'],
      },
      {
        code: '63701022',
        text:
          'Какое расстояние установлено по горизонтали между выступающими частями крана, передвигающегося по наземному крановому пути, и штабелями грузов, расположенными на высоте до 2000 мм от уровня рабочих площадок?',
        answers: [
          'Не более 200 мм',
          'Не менее 400 мм',
          'Не менее 500 мм',
          'Не менее 700 мм',
        ],
        correctAnswers: ['Не менее 700 мм'],
      },
      {
        code: '63701023',
        text:
          'Какой нагрузкой должны проводиться статические испытания ПС всех типов (кроме подъемников и кранов-трубоукладчиков)?',
        answers: [
          '200% по отношению к номинальной паспортной грузоподъемности ПС',
          '145% по отношению к номинальной паспортной грузоподъемности ПС',
          '125% по отношению к номинальной паспортной грузоподъемности ПС',
          '160% по отношению к номинальной паспортной грузоподъемности ПС',
        ],
        correctAnswers: [
          '125% по отношению к номинальной паспортной грузоподъемности ПС',
        ],
      },
      {
        code: '63701024',
        text:
          'Под чьим руководством следует производить кантовку грузов массой более 75% от паспортной грузоподъемности ПС и грузов со смещением центра тяжести?',
        answers: [
          'Инженерно-технического работника, ответственного за безопасное производство работ с применением ПС',
          'Инженерно-технического работника, ответственного за содержание ПС в работоспособном состоянии',
          'Инженерно-технического работника, ответственного за осуществление производственного контроля при эксплуатации ПС',
          'Технического руководителя организации',
        ],
        correctAnswers: [
          'Инженерно-технического работника, ответственного за безопасное производство работ с применением ПС',
        ],
      },
      {
        code: '63701025',
        text:
          'Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка стрелового крана, крана-манипулятора только на две или три выносные опоры?',
        answers: [
          'Если имеется допустимый уклон одной из частей площадки установки',
          'Если отсутствует место на площадке установки для всех четырех опор',
          'Если подъем и перемещение груза будет выполняться только в одном положении стрелы',
          'Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору',
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
        correctAnswers: [
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
      },
      {
        code: '63701026',
        text:
          'Какое расстояние должно соблюдаться между стрелой крана и контактными проводами при работе кранов стрелового типа под включенными контактными проводами городского транспорта при наличии ограничителя (упора)?',
        answers: [
          'Не менее 0,7 м',
          'Не менее 1,0 м',
          'Не менее 0,8 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 1,0 м'],
      },
      {
        code: '63701027',
        text:
          'Кто выдает разрешение на дальнейшую эксплуатацию ПС после проведения периодического планового технического освидетельствования?',
        answers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии, при участии специалиста, ответственного за безопасное производство работ с применением ПС',
          'Руководитель эксплуатирующей организации, которой принадлежит подъемник (вышка)',
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Инспектор территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
        ],
      },
      {
        code: '63701028',
        text:
          'Допускается ли при выполнении строительно-монтажных или погрузочно-разгрузочных работ перемещение грузов с применением ПС над перекрытиями, под которыми размещены производственные, жилые или служебные помещения, где могут находиться люди?',
        answers: [
          'Допускается, если работами руководит специалист, ответственный за безопасное производство работ с применением ПС',
          'Допускается, если место производства работ будет ограждено и обозначено предупредительными знаками',
          'Допускается, если численность возможно находящихся людей не превышает 3 человек',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63701029',
        text:
          'В каком случае проводятся испытания на устойчивость при повторных технических освидетельствованиях для всех кранов стрелового типа и подъемников (вышек), у которых люлька закреплена на оголовке стрелы?',
        answers: [
          'Если это требование территориального органа Ростехнадзора',
          'Если это указано в их руководстве (инструкции) по эксплуатации',
          'Если это требование технического руководителя',
          'Не проводятся ни в каком случае',
        ],
        correctAnswers: [
          'Если это указано в их руководстве (инструкции) по эксплуатации',
        ],
      },
      {
        code: '63701030',
        text:
          'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          'Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС - Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
          'Требованиями Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Федеральным законом "О промышленной безопасности опасных производственных объектов" от 21 июля 1997 г. № 116-ФЗ',
        ],
        correctAnswers: [
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
        ],
      },
      {
        code: '63701031',
        text:
          'Какая периодичность частичного технического освидетельствования установлена для ПС в течение всего срока службы?',
        answers: [
          'Не реже одного раза в 15 месяцев',
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 18 месяцев',
          'Не реже одного раза в 16 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63701032',
        text:
          'Какое расстояние установлено по вертикали от консоли противовеса башенного крана до площадок, на которых могут находиться люди?',
        answers: [
          'Не менее 1000 мм',
          'Не менее 1700 мм',
          'Не менее 1500 мм',
          'Не менее 2000 мм',
        ],
        correctAnswers: ['Не менее 2000 мм'],
      },
      {
        code: '63701033',
        text:
          'Кем назначаются сигнальщики из числа стропальщиков для передачи сигнала оператору (крановщику)?',
        answers: [
          'Техническим руководителем',
          'Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии',
          'Инженерно-техническим работником, ответственным за осуществление производственного контроля при эксплуатации ПС',
          'Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС',
        ],
        correctAnswers: [
          'Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС',
        ],
      },
      {
        code: '63701034',
        text:
          'Кто должен назначать сигнальщика в случаях, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между крановщиком и стропальщиком радио- или телефонной связи?',
        answers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Инженерно-технический работник, ответственный за безопасное выполнение работ с применением ПС',
          'Инспектор территориального органа Ростехнадзора',
          'Руководитель организации, эксплуатирующей ПС',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за безопасное выполнение работ с применением ПС',
        ],
      },
      {
        code: '63701035',
        text:
          'Каким должно быть безопасное расстояние от низа перемещаемого груза до перекрытий и площадок, где могут находиться люди?',
        answers: [
          'Не более 2 м',
          'Не менее 1,5 м',
          'Не менее 1,8 м',
          'Не менее 2,3 м',
        ],
        correctAnswers: ['Не менее 2,3 м'],
      },
      {
        code: '63701036',
        text:
          'Какие требования к статическим испытаниям крана-трубоукладчика или крана-манипулятора указаны неверно?',
        answers: [
          'Кран-трубоукладчик и кран-манипулятор считаются выдержавшими испытания, если в течение 10 минут поднятый груз не опустился, а также не обнаружено трещин, остаточных деформаций и других повреждений',
          'После установки на кран-трубоукладчик (кран-манипулятор) сменного стрелового оборудования испытания проводятся в положении, соответствующем наибольшей грузоподъемности, при установленном оборудовании',
          'Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут',
        ],
        correctAnswers: [
          'Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут',
        ],
      },
      {
        code: '63701037',
        text:
          'С какой нагрузкой по отношению к номинальной паспортной грузоподъемности должна проводиться проверка качества выполненного ремонта грузозахватных приспособлений с проведением статических испытаний?',
        answers: ['1,25', '1,5', '1,1', '2'],
        correctAnswers: ['1,25'],
      },
      {
        code: '63701038',
        text: 'В каких случаях не проводятся динамические испытания ПС?',
        answers: [
          'Если ПС используется только для перемещения крупногабаритных грузов',
          'Если ПС используется только для подъема и опускания груза (подъем затворов на гидроэлектростанции)',
          'Если ПС используется только в технологических процессах',
          'Если ПС используется только для перемещения людей',
        ],
        correctAnswers: [
          'Если ПС используется только для подъема и опускания груза (подъем затворов на гидроэлектростанции)',
        ],
      },
      {
        code: '63701039',
        text:
          'В каких случаях зоны работающих ПС должны быть ограждены и обозначены предупредительными знаками, при этом нахождение в зоне работы людей не допускается?',
        answers: [
          'Во время выполнения работы ПС на краю откосов',
          'Во время работы за пределами срока эксплуатации ПС, указанного в паспорте завода-изготовителя',
          'Во время работы ПС, оснащенных грейфером или электромагнитом',
          'Во время транспортировки крупногабаритных грузов',
        ],
        correctAnswers: [
          'Во время работы ПС, оснащенных грейфером или электромагнитом',
        ],
      },
      {
        code: '63701040',
        text:
          'С какой периодичностью в эксплуатации следует осматривать траверсы, клещи, захваты и тару?',
        answers: [
          'Каждый день',
          'Каждые 10 дней',
          'Каждые 2 недели',
          'Каждый месяц',
          'Каждый год',
        ],
        correctAnswers: ['Каждый месяц'],
      },
      {
        code: '63701041',
        text:
          'С каким грузозахватным органом проводят испытания при повторных периодических технических освидетельствованиях ПС, имеющих несколько грузозахватных органов?',
        answers: [
          'С каждым грузозахватным органом поочередно',
          'С тем, который установлен на момент проведения испытаний',
          'С тем, который наиболее часто применяющимся в технологии перегрузочного процесса',
          'Сменные грузозахватные органы подлежат только осмотру',
        ],
        correctAnswers: [
          'С тем, который установлен на момент проведения испытаний',
        ],
      },
      {
        code: '63701042',
        text:
          'В каких местах должны быть установлены стационарные эстакады или навесные площадки для стропальщиков?',
        answers: [
          'В местах постоянной погрузки и разгрузки автомашин и полувагонов',
          'В любых местах, кроме мест хранения напольного технологического оборудования',
          'В любых местах, кроме мест для прохода персонала',
          'В местах, которые предусмотрены для эвакуации в случае возникновения аварийной ситуации',
          'Установка стационарных эстакад или навесных площадок для стропальщиков не обязательна',
        ],
        correctAnswers: [
          'В местах постоянной погрузки и разгрузки автомашин и полувагонов',
        ],
      },
      {
        code: '63701043',
        text:
          'Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?',
        answers: [
          'Руководства (инструкции) по эксплуатации',
          'Заключения экспертизы промышленной безопасности',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Паспорта',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации'],
      },
      {
        code: '63701044',
        text: 'В каких случаях разрешается разворот поднятого груза руками?',
        answers: [
          'В случаях, когда масса груза составляет не более половины грузоподъемности крана',
          'В случаях, когда поднятый груз удален от стен здания и выступающих частей оборудования',
          'В случаях, когда разворот выполняет специалист, ответственный за безопасную эксплуатацию ПС',
          'В случаях, когда груз разворачивают в кузове автомобиля или полувагона',
          'В случаях, когда груз поднят на высоту не более 1000 мм',
        ],
        correctAnswers: [
          'В случаях, когда груз поднят на высоту не более 1000 мм',
        ],
      },
      {
        code: '63701045',
        text:
          'Кто из специалистов и персонала до начала производства работ ПС в обязательном порядке должен быть ознакомлен под подпись с ППР?',
        answers: [
          'Инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, крановщики (операторы) и стропальщики',
          'Только инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, крановщики (операторы)',
          'Только инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, стропальщики',
          'Только крановщики (операторы) и стропальщики',
          'Ознакомление с ППР под подпись не требуется',
        ],
        correctAnswers: [
          'Инженерно-технические работники, ответственные за безопасное производство работ с применением ПС, крановщики (операторы) и стропальщики',
        ],
      },
      {
        code: '63701046',
        text:
          'На какую высоту следует предварительно поднять груз перед началом перемещения (с последующей остановкой) для проверки правильности строповки и надежности действия тормоза ПС?',
        answers: [
          'Не более 450 - 550 мм',
          'Не более 200 - 300 мм',
          'Не более 550 - 650 мм',
          'Не более 300 - 400 мм',
        ],
        correctAnswers: ['Не более 200 - 300 мм'],
      },
      {
        code: '63701047',
        text: 'Какое требование по безопасной эксплуатации ПС указано неверно?',
        answers: [
          'При управлении подъемным сооружением с пола вдоль всего пути его следования должен быть обеспечен свободный проход для работника, управляющего им',
          'У находящихся в эксплуатации галерей мостовых кранов выходы должны быть закрыты на замок',
          'Эксплуатирующая организация должна разработать мероприятия по безопасному спуску крановщиков из кабины при вынужденной остановке крана не у посадочной площадки',
          'Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте',
        ],
        correctAnswers: [
          'Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте',
        ],
      },
      {
        code: '63701048',
        text:
          'Для каких типов подъемников при проведении статических испытаний часть испытательного груза подвешивают к люльке на гибкой подвеске?',
        answers: [
          'Только для строительных подъемников',
          'Только для подъемников ножничного типа',
          'Для всех подъемников, оборудованных люлькой, кроме строительных',
          'Только для автогидроподъемников',
          'Только для подъемников, применяемых для перемещения людей',
        ],
        correctAnswers: [
          'Для всех подъемников, оборудованных люлькой, кроме строительных',
        ],
      },
      {
        code: '63701049',
        text:
          'В каких случаях рельсовые пути ПС, передвигающихся по рельсам, должны подвергаться ремонту?',
        answers: [
          'При истечении срока эксплуатации ПС',
          'При проведении внеочередных технических освидетельствований',
          'При необходимости',
          'При проведении комплексных обследований',
          'При установке на рельсовых путях дополнительных ПС',
        ],
        correctAnswers: ['При необходимости'],
      },
      {
        code: '63701050',
        text:
          'На какую организацию возлагается ответственность за эксплуатацию ПС, не оборудованного ограничителями, указателями и регистраторами, необходимыми для обеспечения промышленной безопасности технологического процесса, в котором используется ПС?',
        answers: [
          'На экспертную организацию, проводившую экспертизу промышленной безопасности ПС',
          'На сертификационный центр и испытательную лабораторию, выдавших сертификат/декларацию соответствия ПС',
          'На эксплуатирующую ПС организацию',
          'На специализированную организацию, выполнившую ремонт и реконструкцию ПС',
          'На территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['На эксплуатирующую ПС организацию'],
      },
      {
        code: '63701051',
        text:
          'При каком условии допускается нахождение стропальщика возле груза во время его подъема или опускания?',
        answers: [
          'Если груз поднят на высоту не более 1 м от уровня площадки',
          'Если груз поднят на высоту не менее 1,5 м от уровня площадки',
          'Если груз поднят на высоту не менее 2 м от уровня площадки',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Если груз поднят на высоту не более 1 м от уровня площадки',
        ],
      },
      {
        code: '63701052',
        text:
          'В каких случаях разрешены подъем и транспортировка людей с применением ПС, в паспорте которых отсутствует разрешение на транспортировку людей?',
        answers: [
          'Только в случае монтажа и обслуживания отдельно стоящих буровых и иных установок нефтегазодобычи',
          'Только в случае перемещения персонала для крепления и раскрепления контейнеров и грузов',
          'Только в случае проведения диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно',
          'Только в случае аварийной транспортировки людей, которые не в состоянии передвигаться самостоятельно',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63701053',
        text:
          'В какой документ вносится запись о результатах осмотра съемных грузозахватных приспособлений и тары?',
        answers: [
          'Акт осмотра',
          'Протокол осмотра',
          'Журнал осмотра грузозахватных приспособлений',
          'Документальная фиксация осмотра не предусмотрена',
        ],
        correctAnswers: ['Журнал осмотра грузозахватных приспособлений'],
      },
      {
        code: '63701054',
        text:
          'Что служит основанием для решения о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?',
        answers: [
          'Предложение комиссии о возможности пуска',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Акт-разрешение территориального органа Ростехнадзора',
          'Письменное уведомление завода-изготовителя о возможности пуска',
        ],
        correctAnswers: ['Предложение комиссии о возможности пуска'],
      },
      {
        code: '63701055',
        text:
          'Когда выдаются производственные инструкции персоналу, обслуживающему ПС?',
        answers: [
          'Перед допуском к работе, под расписку',
          'Перед прохождения повторного инструктажа',
          'Перед прохождении вводного инструктажа',
          'Перед проведением первичного инструктажа на рабочем месте',
        ],
        correctAnswers: ['Перед допуском к работе, под расписку'],
      },
      {
        code: '63701056',
        text:
          'Сколько высотных засечек положения одного из поясов главной балки делается при проведении статического испытания мостового крана?',
        answers: ['Одна', 'Две', 'Три', 'Четыре'],
        correctAnswers: ['Три'],
      },
      {
        code: '63701057',
        text:
          'С какой перегрузкой проводятся испытания на строительных подъемниках при проведении полного технического освидетельствования и проверки работоспособности ловителей (аварийных остановов)?',
        answers: ['0,25', '0,2', '0,15', '0,1'],
        correctAnswers: ['0,1'],
      },
      {
        code: '63701058',
        text:
          'Какое количество ветвей для стропов с числом ветвей более трех учитывают в расчете их грузоподъемности?',
        answers: [
          'Не более двух ветвей',
          'Не более трех ветвей',
          'Не более четырех ветвей',
          'Все ветви, если груз крупногабаритный и несимметричный',
          'В соответствии с руководством (инструкцией) по эксплуатации стропа',
        ],
        correctAnswers: ['Не более трех ветвей'],
      },
      {
        code: '63701059',
        text:
          'В каких из перечисленных случаев эксплуатирующая организация имеет право допустить ПС в работу?',
        answers: [
          'Если для редко используемого ПС обслуживание ведется неаттестованным персоналом',
          'Если для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования',
          'Если для ПС, отработавшего срок службы, отсутствует экспертиза промышленной безопасности',
          'Если для ПС выявлены трещины в расчетных элементах металлоконструкции',
          'Если для ПС отсутствуют соответствующие массе и виду перемещаемых грузов съемные грузозахватные приспособления и тара',
        ],
        correctAnswers: [
          'Если для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования',
        ],
      },
      {
        code: '63701060',
        text:
          'Каким проверкам должны подвергаться ПС при полном техническом освидетельствовании?',
        answers: [
          'Только осмотру и испытаниям на устойчивость',
          'Только динамическим испытаниям',
          'Только статическим испытаниям',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63701061',
        text:
          'Какие меры промышленной безопасности должны быть приняты для ПС, установленных на открытом воздухе и находящихся в нерабочем состоянии?',
        answers: [
          'Подъемные сооружения должны быть установлены на аутригеры, при этом стрела устанавливается на минимальный вылет',
          'ПС должны быть обесточены и приняты меры по предотвращению их угона ветром',
          'Подъемные сооружения должны быть установлены на аутригеры, если давление в колесах не соответствует норме',
          'Меры определяются ветровым районом установки ПС',
          'Меры определяются высотой установки ПС над уровнем моря',
        ],
        correctAnswers: [
          'ПС должны быть обесточены и приняты меры по предотвращению их угона ветром',
        ],
      },
      {
        code: '63701062',
        text:
          'Кто определяет порядок работы крана вблизи линии электропередачи, выполненной гибким изолированным кабелем?',
        answers: [
          'Разработчик проекта производства работ',
          'Эксплуатирующая организация',
          'Специализированная экспертная организация',
          'Владелец линии',
          'Определение порядка работы не требуется, поскольку изолированный кабель безопасен',
        ],
        correctAnswers: ['Владелец линии'],
      },
      {
        code: '63701063',
        text:
          'Какие из перечисленных ПС не подлежат учету в органах Ростехнадзора?',
        answers: [
          'Автомобильные краны',
          'Краны стрелового типа грузоподъемностью свыше 1,5 т',
          'Краны на железнодорожном ходу',
          'Краны-трубоукладчики',
        ],
        correctAnswers: ['Краны-трубоукладчики'],
      },
      {
        code: '63701064',
        text:
          'Какие требования предъявляются к стальным канатам, устанавливаемым на ПС при замене ранее установленных?',
        answers: [
          'Стальные канаты должны соответствовать по длине, марке, диаметру и разрывному усилию, указанным в паспорте ПС, и иметь сертификат предприятия-изготовителя',
          'Стальные канаты должны иметь разрывное усилие на 15% больше указанного в паспорте ПС для заменяемого каната',
          'Стальные канаты должны соответствовать только технологии применения ПС',
        ],
        correctAnswers: [
          'Стальные канаты должны соответствовать по длине, марке, диаметру и разрывному усилию, указанным в паспорте ПС, и иметь сертификат предприятия-изготовителя',
        ],
      },
      {
        code: '63701065',
        text:
          'Каким грузом следует проводить динамические испытания стреловых самоходных кранов?',
        answers: [
          'Масса которого на 10% превышает его паспортную грузоподъемность',
          'Масса которого на 8% превышает его паспортную грузоподъемность',
          'Масса которого на 6% превышает его паспортную грузоподъемность',
          'Масса которого на 5% превышает его паспортную грузоподъемность',
        ],
        correctAnswers: [
          'Масса которого на 10% превышает его паспортную грузоподъемность',
        ],
      },
      {
        code: '63701066',
        text:
          'Каким документом должна определяться численность инженерно-технических работников эксплуатирующей организации?',
        answers: [
          'Внутренним распорядительным актом эксплуатирующей организации',
          'Проектной документацией',
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Технологическим регламентом',
        ],
        correctAnswers: [
          'Внутренним распорядительным актом эксплуатирующей организации',
        ],
      },
      {
        code: '63701067',
        text:
          'В каком из перечисленных случаев при внеочередном полном техническом освидетельствовании ПС проводятся только статические испытания?',
        answers: [
          'После реконструкции ПС',
          'После установки сменного стрелового оборудования',
          'После капитального ремонта',
          'После замены грузозахватного органа',
          'После замены вантовых канатов кранов кабельного типа',
        ],
        correctAnswers: ['После замены грузозахватного органа'],
      },
      {
        code: '63701068',
        text:
          'Куда вносятся отметки о монтаже и наладке ограничителя, указателя и регистратора подъемного сооружения (ПС)? Укажите все правильные ответы.',
        answers: [
          'В заключение экспертизы промышленной безопасности',
          'В паспорт ограничителя, указателя или регистратора',
          'В эксплуатационный журнал ПС',
          'В паспорт ПС',
          'В руководство по эксплуатации',
        ],
        correctAnswers: [
          'В паспорт ограничителя, указателя или регистратора',
          'В паспорт ПС',
        ],
      },
      {
        code: '63701069',
        text:
          'Какие действия не включает в себя проверка состояния люльки (кабины)?',
        answers: [
          'Ежесменный осмотр',
          'Грузовые испытания',
          'Плановую проверку состояния',
          'Динамические испытания',
          'Внеплановую проверку состояния, если люлька (кабина) не эксплуатировалась более полугода',
        ],
        correctAnswers: ['Динамические испытания'],
      },
      {
        code: '63701070',
        text: 'Кто должен проводить техническое освидетельствование ПС?',
        answers: [
          'Комиссия эксплуатирующей организации, утвержденная ее руководителем',
          'Комиссия эксплуатирующей организации, в состав которой должен входить представитель Ростехнадзора',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии',
          'Инспектор Ростехнадзора совместно со инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за безопасное производство работ',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии',
        ],
      },
      {
        code: '63701071',
        text:
          'Какая периодичность полного технического освидетельствования установлена для ПС в течение всего срока службы?',
        answers: [
          'Не реже одного раза в 6 лет',
          'Не реже одного раза в 5 лет, за исключением редко используемых ПС',
          'Не реже одного раза в 3 года, за исключением редко используемых ПС',
          'Не реже одного раза в 8 лет',
        ],
        correctAnswers: [
          'Не реже одного раза в 3 года, за исключением редко используемых ПС',
        ],
      },
      {
        code: '63701072',
        text:
          'Куда записывается решение о вводе в эксплуатацию грузозахватных приспособлений, тары?',
        answers: [
          'В эксплуатационный паспорт грузозахватного приспособления, тары',
          'В специальный журнал учета и осмотра',
          'В распорядительный акт эксплуатирующей организации',
          'В положение о контроле качества технологических процессов, принимаемое эксплуатирующей организацией',
          'На бирку грузозахватного приспособления, тары',
        ],
        correctAnswers: ['В специальный журнал учета и осмотра'],
      },
      {
        code: '63701073',
        text:
          'Какова продолжительность статических испытаний кабельных кранов?',
        answers: ['10 минут', '15 минут', '25 минут', '30 минут', '40 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63701074',
        text:
          'Кто является председателем комиссии, на основании предложений которой принимается решение о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?',
        answers: [
          'Представитель территориального органа Ростехнадзора',
          'Уполномоченный представитель специализированной организации',
          'Уполномоченный представитель эксплуатирующей организации',
          'Специалист, ответственный за безопасную эксплуатацию ПС',
        ],
        correctAnswers: [
          'Уполномоченный представитель эксплуатирующей организации',
        ],
      },
      {
        code: '63701075',
        text:
          'В каких случаях к акту сдачи-приемки рельсового пути, определяющему его готовность к эксплуатации, должны прикладываться данные планово-высотной съемки?',
        answers: [
          'Только после ремонта',
          'Только после сдачи вновь уложенных путей',
          'Только после реконструкции',
          'Только после проведения комплексного обследования его состояния',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63701076',
        text:
          'Согласно требованиям какого документа следует выполнять браковку стальных канатов в эксплуатации?',
        answers: [
          'Технологического регламента',
          'Технического регламента',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Проекта',
        ],
        correctAnswers: [
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63701077',
        text:
          'Что из перечисленного является условием для браковки каната крана, подвергавшегося поверхностному изнашиванию или коррозии?',
        answers: [
          'Уменьшение диаметра каната на 3% и более по сравнению с номинальным диаметром',
          'Уменьшение диаметра каната на 7% и более по сравнению с номинальным диаметром даже при отсутствии видимых обрывов проволок',
          'Уменьшение диаметра каната на 4% и более по сравнению с номинальным диаметром',
          'Уменьшение диаметра каната на 6% и более по сравнению с номинальным диаметром только при наличии видимых обрывов проволок',
          'Уменьшение диаметра каната на 5% и более по сравнению с номинальным диаметром',
        ],
        correctAnswers: [
          'Уменьшение диаметра каната на 7% и более по сравнению с номинальным диаметром даже при отсутствии видимых обрывов проволок',
        ],
      },
      {
        code: '63701078',
        text:
          'В каких случаях при наличии на ПС двух механизмов подъема их статические испытания следует проводить одновременно?',
        answers: [
          'Если статические испытания проводятся для контейнерных кранов, спредер которых поднимают одновременно два механизма подъема',
          'Если статические испытания проводятся для литейных кранов, у которых вспомогательный подъем осуществляет поворот поднятого ковша',
          'Если это предусмотрено паспортом ПС',
          'Если статические испытания проводятся для кранов, оснащенных двухканатным грейфером',
          'Во всех перечисленных случаях запрещено',
        ],
        correctAnswers: ['Если это предусмотрено паспортом ПС'],
      },
      {
        code: '63701079',
        text:
          'Чему равен допустимый остаточный прогиб пролетного строения кранов мостового типа в вертикальной плоскости, где L - пролет крана?',
        answers: ['0,002L', '0,0035L', '0,005L', '0,0055L'],
        correctAnswers: ['0,0035L'],
      },
      {
        code: '63701080',
        text:
          'В каких случаях разрешается подтаскивание груза по земле, полу или рельсам крюками ПС?',
        answers: [
          'Только в случаях применения ПС, не склонных к опрокидыванию (потере устойчивости)',
          'Только в случаях, если канатный барабан механизма подъема ПС снабжен канатоукладчиком',
          'Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов',
          'Только в случаях перемещения груза (тележки) по рельсам, т. к. нагрузка от трения качения мала и ПС перегрузить невозможно',
          'Не разрешается во всех случаях',
        ],
        correctAnswers: [
          'Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов',
        ],
      },
      {
        code: '63701081',
        text:
          'С какой периодичностью результаты осмотров рельсовых путей заносятся в вахтенные журналы крановщика (оператора) всех ПС, установленных на одном рельсовом пути?',
        answers: [
          'После каждых 18 смен работы',
          'После каждых 14 смен работы',
          'После каждых 24 смен работы',
          'После каждых 16 смен работы',
        ],
        correctAnswers: ['После каждых 24 смен работы'],
      },
      {
        code: '63701082',
        text:
          'Кто разрабатывает проект для реконструкции или модернизации ограничителя, указателя или регистратора в случае, когда изготовителя ПС установить невозможно?',
        answers: [
          'Территориальный орган Ростехнадзора',
          'Специализированная организация',
          'Эксплуатирующая организация',
          'Организация - собственник ПС',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63701083',
        text:
          'В каких случаях проводятся испытания на грузовую устойчивость при первичном техническом освидетельствовании стрелового самоходного крана?',
        answers: [
          'Только в случаях, когда в его паспорте отсутствуют ссылки на результаты таких ранее проведенных испытаний',
          'Только в случаях, когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов или с применением сварки',
          'Только в случаях, когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута изменению первоначальных геометрических размеров',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63701084',
        text:
          'В каком случае допускается движение стрелового самоходного крана с места при раскачивающемся грузе?',
        answers: [
          'Если это предусматривается руководством (инструкцией) по эксплуатации крана',
          'Если имеется разрешение инженерно-технического работника, ответственного за безопасное производство работ с применением ПС',
          'Если это предусматривается ППР',
          'Если это предусматривается Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63701085',
        text:
          'С кем следует согласовывать установку кранов, передвигающихся по рельсовому пути, в охранной зоне воздушных линий (далее – ВЛ) электропередачи?',
        answers: [
          'С органом муниципального управления, по территории которого проходит ВЛ',
          'С потребителем, эксплуатирующим ВЛ',
          'С владельцем линии',
          'С территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['С владельцем линии'],
      },
      {
        code: '63701086',
        text:
          'Какие меры следует принять к установке подъемников (вышек) при невозможности соблюдения безопасных расстояний, указанных в ФНП ПС, если глубина котлована более 5 м?',
        answers: [
          'Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР',
          'Установить подъемник (вышку) для производства работ, если получено письменное распоряжение технического руководителя',
          'Установить подъемник (вышку) для производства работ, если присутствует инженерно-технический работник, ответственный за работоспособное состояние ПС',
          'Установить подъемник (вышку) для производства работ, если они проводятся под руководством технического руководителя организации, эксплуатирующей ПС',
        ],
        correctAnswers: [
          'Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР',
        ],
      },
      {
        code: '63701087',
        text:
          'За сколько дней до начала работы комиссии по пуску ПС в работу эксплуатирующая организация должна письменно уведомить организации, представители которых включены в состав комиссии, о дате работы комиссии?',
        answers: [
          'Не менее чем за 3 дня',
          'Не менее чем за 10 дней',
          'Не менее чем за 7 дней',
          'Не менее чем за 5 дней',
        ],
        correctAnswers: ['Не менее чем за 10 дней'],
      },
      {
        code: '63701088',
        text:
          'Какие требования к проверке ограничителя предельного верхнего положения грузозахватного органа указаны неверно?',
        answers: [
          'Проверка осуществляется путем контроля с замером расстояния между верхней точкой грузозахватного органа и упором',
          'Проверка осуществляется путем контроля с замером расстояния между верхней точкой грузозахватного органа и нижней частью металлоконструкции (после остановки механизма)',
          'Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором',
          'Проверка осуществляется без груза',
        ],
        correctAnswers: [
          'Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором',
        ],
      },
      {
        code: '63701089',
        text:
          'Каким требованиям из перечисленных должны отвечать рельсовый путь ПС (исключая рельсовые пути башенных и железнодорожных кранов) и рельсовый путь грузовых подвесных тележек или электрических талей, оборудованный стрелками или поворотными кругами, а также места перехода ПС или его грузовой тележки с одного пути на другой?',
        answers: [
          'Только обеспечивать управление переводом стрелки или поворотного круга от сигнала системы управления грузовой тележкой',
          'Только иметь автоматически включаемую блокировку, исключающую сход грузовой тележки (электрической тали) с рельса при выезде ее на консоль расстыкованного участка пути',
          'Только быть оборудованными единым выключателем для подачи напряжения на троллеи (или электрический кабель) грузовой тележки (электрической тали), на механизмы управления стрелок и электрические аппараты блокировочных устройств',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63701090',
        text:
          'Какое расстояние установлено от верхней точки крана, передвигающегося по надземному рельсовому пути, до потолка здания или предметов конструкции здания над краном?',
        answers: [
          'Не менее 100 мм',
          'Не менее 80 мм',
          'Не менее 60 мм',
          'Не менее 40 мм',
        ],
        correctAnswers: ['Не менее 100 мм'],
      },
      {
        code: '63701091',
        text:
          'Каким требованиям должны соответствовать устройство и размеры лестниц, посадочных площадок и галерей надземных рельсовых путей?',
        answers: [
          'Требованиям руководства (инструкции) по эксплуатации ПС',
          'Требованиям утвержденного проекта производства работ с применением ПС',
          'Требованиям проектной и эксплуатационной документации на рельсовый путь',
          'Требованиям заключения экспертизы промышленной безопасности ПС',
          'Требованиям строительных норм и правил применения ПС',
        ],
        correctAnswers: [
          'Требованиям проектной и эксплуатационной документации на рельсовый путь',
        ],
      },
      {
        code: '63701092',
        text:
          'Какие краны, не оборудованные координатной защитой, запрещается применять для работы в стесненных условиях?',
        answers: [
          'Только башенные краны',
          'Только грузоподъемные краны на пневмоколесном и гусеничном ходу',
          'Только грузоподъемные краны, установленные на автомобильные шасси, специальные шасси автомобильного типа',
          'Все перечисленные краны',
        ],
        correctAnswers: ['Все перечисленные краны'],
      },
      {
        code: '63701093',
        text:
          'Когда результат статических испытаний крана стрелового типа считается положительным?',
        answers: [
          'Только если отсутствуют трещины, остаточные деформации и другие повреждения стрелы',
          'Если в течение 5 минут поднятый груз не опустится на землю',
          'Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов',
          'Если кран устойчив во время всего периода испытаний, независимо от состояния металлоконструкций',
        ],
        correctAnswers: [
          'Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов',
        ],
      },
      {
        code: '63701094',
        text:
          'На какие из перечисленных ОПО распространяются требования ФНП ПС?',
        answers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
          'На ОПО, где эксплуатируются ПС, применяемые в интересах обороны и безопасности государства',
          'На ОПО, где эксплуатируются ПС, установленные в шахтах и на любых плавучих средствах',
          'На ОПО, где эксплуатируются краны для подъема створов (затворов) плотин без осуществления зацепления их крюками',
        ],
        correctAnswers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
        ],
      },
      {
        code: '63701095',
        text:
          'По каким точкам грузовой характеристики должна проводиться проверка ограничителя грузового момента, если грузоподъемность ПС изменяется в зависимости от вылета, положения грузовой тележки или пространственного положения элемента ПС?',
        answers: [
          'Не менее чем в двух точках его грузовой характеристики',
          'Не менее чем в трех точках его грузовой характеристики',
          'Не менее чем в четырех точках его грузовой характеристики',
          'Проверка ограничителя грузового момента нормативными документами не регламентируется',
        ],
        correctAnswers: [
          'Не менее чем в трех точках его грузовой характеристики',
        ],
      },
      {
        code: '63701096',
        text:
          'Кого относят к работникам специализированных организаций, занимающихся выполнением работ по монтажу (демонтажу), наладке либо ремонту, реконструкции или модернизации в процессе эксплуатации ПС?',
        answers: [
          'Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
          'Аттестованных инженерно-технических работников',
          'Физических лиц основных служб организации, эксплуатирующей ПС, непосредственно занятых на выполнении работ',
          'Всех сотрудников организации, эксплуатирующей ПС',
        ],
        correctAnswers: [
          'Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
        ],
      },
      {
        code: '63701097',
        text:
          'Кто должен выполнять работы на регистраторах, ограничителях и указателях ПС?',
        answers: [
          'Работники, допущенные учебным центром по регистраторам, ограничителям и указателям на основании проверки знаний',
          'Работники организации, эксплуатирующей ПС, прошедшие аттестацию и проверку знаний по промышленной безопасности',
          'Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей',
          'Работники специализированных организаций, имеющие допуск на территорию организации, эксплуатирующей ПС',
        ],
        correctAnswers: [
          'Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей',
        ],
      },
      {
        code: '63701098',
        text:
          'Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.',
        answers: [
          'Проектной документации',
          'Формуляра ПС',
          'Руководства (инструкции) по эксплуатации ПС',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
        correctAnswers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
      },
      {
        code: '63701099',
        text:
          'Кем выдается решение о пуске в работу ПС, отбор мощности у которых для собственного передвижения и работы механизмов осуществляется от собственного источника энергии, после перестановки их на новый объект?',
        answers: [
          'Инженерно-техническим работником, ответственным за осуществление производственного контроля при эксплуатации ПС',
          'Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии',
          'Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС',
          'Инспектором Ростехнадзора',
        ],
        correctAnswers: [
          'Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС',
        ],
      },
      {
        code: '63701100',
        text:
          'Что не указывается на табличках, которыми должны быть снабжены находящиеся в эксплуатации подъемные сооружения (ПС)?',
        answers: [
          'Только заводской номер ПС',
          'Только паспортная грузоподъемность',
          'Только дата следующего полного технического освидетельствования',
          'Только дата следующего частичного технического освидетельствования',
          'Только учетный номер ПС',
          'Указывается все перечисленное',
        ],
        correctAnswers: ['Указывается все перечисленное'],
      },
      {
        code: '63701101',
        text:
          'Кем выдается наряд-допуск на работу вблизи находящихся под напряжением проводов и оборудования в случаях, когда работы с применением кранов стрелового типа, кранов-манипуляторов, подъемников (вышек) ведутся на действующих электростанциях, подстанциях и линиях электропередачи?',
        answers: [
          'Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи',
          'Ростехнадзором',
          'Специализированной организацией',
          'Организацией, эксплуатирующей ПС',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи',
        ],
      },
      {
        code: '63701102',
        text:
          'Каким документом оформляются результаты испытаний грузозахватных приспособлений от приложения статической нагрузкой?',
        answers: [
          'Только актом испытания',
          'Только протоколом испытания',
          'Актом или протоколом',
          'Распоряжением о проведенном полном техническом освидетельствовании',
          'Положительным заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Актом или протоколом'],
      },
      {
        code: '63701103',
        text:
          'В соответствии с требованиями какого документа выполняется проверка работоспособности указателя (сигнализатора) предельной скорости ветра (анемометра) и креномера (указателя угла наклона ПС)?',
        answers: [
          'Руководств (инструкций) по эксплуатации анемометра и креномера',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Руководства (инструкции) по эксплуатации ПС',
          'Руководства по качеству организации и выполнения калибровочных работ',
        ],
        correctAnswers: [
          'Руководств (инструкций) по эксплуатации анемометра и креномера',
        ],
      },
      {
        code: '63701104',
        text:
          'Кого в обязательном порядке должны информировать работники ОПО, непосредственно занимающиеся эксплуатацией ПС, об угрозе возникновения аварийной ситуации?',
        answers: [
          'Специалиста, ответственного за осуществление производственного контроля при эксплуатации ПС',
          'Специалиста, ответственного за содержание ПС в работоспособном состоянии',
          'Своего непосредственного руководителя',
          'Специалиста по охране труда',
          'Представителя территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Своего непосредственного руководителя'],
      },
      {
        code: '63701105',
        text:
          'В каких случаях разрешается подача грузов в проемы (люки) перекрытий?',
        answers: [
          'Если ниже перекрытия исключено присутствие людей',
          'Если у перекрытия находится сигнальщик',
          'Если это предусмотрено специально разработанным ППР',
          'Если это выполняется под руководством лица, ответственного за безопасное выполнение работ с применением ПС',
          'Не разрешается ни в каком случае',
        ],
        correctAnswers: ['Если это предусмотрено специально разработанным ППР'],
      },
      {
        code: '63701106',
        text:
          'В каких случаях стреловым краном не разрешается подъем груза непосредственно с места его установки (с земли, площадки, штабеля)?',
        answers: [
          'Если подъем груза осуществляется на номинальной скорости механизма',
          'Если осуществляется подъем длинномерного груза с применением траверсы',
          'Если подъем груза осуществляется только механизмом телескопирования стрелы',
          'Если кран установлен не на асфальтовой площадке',
        ],
        correctAnswers: [
          'Если подъем груза осуществляется только механизмом телескопирования стрелы',
        ],
      },
      {
        code: '63701107',
        text:
          'Для каких подъемников при проведении технического освидетельствования необходимо проверять точность остановки кабины с нагрузкой и без нагрузки?',
        answers: [
          'Для строительных подъемников',
          'Для ножничных подъемников',
          'Для подъемников с пневматическим приводом',
          'Для гидравлических подъемников',
          'Для подъемников, применяемых на железнодорожных станциях',
        ],
        correctAnswers: ['Для строительных подъемников'],
      },
      {
        code: '63701108',
        text:
          'При установке опорных мостовых кранов с какой фактической группой классификации (режима) в пролетах зданий должны быть устроены галереи для прохода вдоль рельсового пути с обеих сторон пролета?',
        answers: ['A4 и более', 'A6 и более', 'А3 и более', 'А5 и более'],
        correctAnswers: ['A6 и более'],
      },
      {
        code: '63701109',
        text:
          'Какая организация осуществляет периодическое комплексное обследование рельсовых путей ПС?',
        answers: [
          'Эксплуатирующая ПС организация',
          'Экспертная организация',
          'Специализированная организация',
          'Ростехнадзор',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63701110',
        text: 'Что должно проводиться после реконструкции ПС?',
        answers: [
          'Внеочередное частичное техническое освидетельствование',
          'Внеочередное полное техническое освидетельствование',
          'Периодическое частичное техническое освидетельствование ПС',
          'Периодическое техническое освидетельствование',
        ],
        correctAnswers: ['Внеочередное полное техническое освидетельствование'],
      },
      {
        code: '63701111',
        text:
          'В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?',
        answers: [
          'В акте пуска ПС в работу',
          'В протоколе осмотра ПС',
          'В акте-наряде',
          'В служебной записке председателя комиссии',
        ],
        correctAnswers: ['В акте пуска ПС в работу'],
      },
      {
        code: '63701112',
        text:
          'Чем запрещается оснащать краны, в зоне работы которых находятся производственные или другие помещения?',
        answers: [
          'Системой радиоуправления',
          'Тяговой лебедкой',
          'Механизмами блокировки (стабилизаторами) колес заднего моста',
          'Грузовым электромагнитом',
        ],
        correctAnswers: ['Грузовым электромагнитом'],
      },
      {
        code: '63701113',
        text:
          'Имеет ли право организация, эксплуатирующая ОПО с ПС, привлекать специалистов сторонних организаций в качестве: специалиста, ответственного за осуществление производственного контроля при эксплуатации ПС; специалиста, ответственного за содержание ПС в работоспособном состоянии; специалиста, ответственного за безопасное производство работ с применением ПС?',
        answers: [
          'Имеет право привлекать всех указанных специалистов',
          'Имеет право привлекать специалиста, ответственного за содержание ПС в работоспособном состоянии, и специалиста, ответственного за безопасное производство работ с применением ПС',
          'Имеет право привлекать только специалиста, ответственного за безопасное производство работ с применением ПС',
          'Имеет право привлекать только специалиста, ответственного за содержание ПС в работоспособном состоянии',
          'Не имеет права',
        ],
        correctAnswers: ['Не имеет права'],
      },
      {
        code: '63701114',
        text:
          'На каком расстоянии сбоку от кантуемого груза должен находиться стропальщик?',
        answers: [
          'На расстоянии, равном высоте груза плюс 1,5 м',
          'На расстоянии, равном высоте груза плюс 0,5 м',
          'На расстоянии, равном высоте груза плюс 2 м',
          'На расстоянии, равном высоте груза плюс 1 м',
        ],
        correctAnswers: ['На расстоянии, равном высоте груза плюс 1 м'],
      },
      {
        code: '63701115',
        text:
          'Считается ли отрыв одной из опор подъемника при проведении испытаний признаком потери устойчивости?',
        answers: [
          'Считается, если отрыв произошел в течение первых 5 минут испытаний',
          'Не считается, если отрыв произошел в течение первых 5 минут испытаний',
          'Считается, если одна из противоположных опор подъемника во время испытаний погрузилась в грунт',
          'Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения',
          'Не считается, если при отрыве опоры сработал ограничитель грузового момента',
        ],
        correctAnswers: [
          'Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения',
        ],
      },
      {
        code: '63701116',
        text:
          'Кто назначается председателем комиссии, на основании предложений которой принимается решение о пуске в работу ПС, отработавшего срок службы, при смене эксплуатирующей организации?',
        answers: [
          'Представитель территориального органа Ростехнадзора',
          'Уполномоченный представитель специализированной организации',
          'Уполномоченный представитель эксплуатирующей организации',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
        ],
        correctAnswers: [
          'Уполномоченный представитель эксплуатирующей организации',
        ],
      },
      {
        code: '63701117',
        text:
          'Согласно требованиям какого документа должна выполняться оценка работоспособности ограничителя или указателя опасного приближения к линии электропередачи при эксплуатации ПС?',
        answers: [
          'Заключения экспертизы промышленной безопасности',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Паспорта',
          'Руководства (инструкции) по эксплуатации',
        ],
        correctAnswers: [
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63701118',
        text:
          'С помощью чего делается высотная засечка положения одного из поясов главной балки при проведении статического испытания мостового крана?',
        answers: [
          'Только с помощью лазерного дальномера',
          'Только с помощью оптического прибора',
          'Только с помощью металлической струны',
          'Всего перечисленного',
        ],
        correctAnswers: ['Всего перечисленного'],
      },
      {
        code: '63701119',
        text:
          'Какая организация обеспечивает наличие комплекта испытательных (контрольных) грузов с указанием их фактической массы для проведения статических и динамических испытаний ПС на территории специализированной организации, осуществляющей ремонт или реконструкцию?',
        answers: [
          'Контролирующая организация',
          'Эксплуатирующая организация',
          'Экспертная организация',
          'Проектная организация',
          'Данная специализированная организация',
        ],
        correctAnswers: ['Данная специализированная организация'],
      },
      {
        code: '63701120',
        text:
          'Какой износ головки рельса является условием для браковки кранового пути опорных кранов?',
        answers: [
          '15% и более от величины соответствующего размера неизношенного профиля',
          '22% и более от величины соответствующего размера неизношенного профиля',
          '17% и более от величины соответствующего размера неизношенного профиля',
          '30% и более от величины соответствующего размера неизношенного профиля',
          '25% и более от величины соответствующего размера неизношенного профиля',
        ],
        correctAnswers: [
          '15% и более от величины соответствующего размера неизношенного профиля',
        ],
      },
      {
        code: '63701121',
        text:
          'Какое количество тупиковых упоров, ограничивающих рабочую зону, обслуживаемую ПС, должно быть установлено на каждой рельсовой нити рельсового пути?',
        answers: [
          'По одному тупиковому упору',
          'По два тупиковых упора',
          'По три тупиковых упора',
          'По четыре тупиковых упора',
        ],
        correctAnswers: ['По два тупиковых упора'],
      },
      {
        code: '63701122',
        text:
          'В каких организациях, эксплуатирующих подъемники (вышки), должны быть разработаны и доведены под подпись до каждого работника инструкции, определяющие действия работников в аварийных ситуациях?',
        answers: [
          'Только в организациях, эксплуатирующих ОПО, зарегистрированные в государственном реестре',
          'Только в организациях, применяющих подъемники (вышки) в стесненных условиях',
          'Только в организациях, эксплуатирующих подъемники (вышки) одновременно с другими ПС, подлежащими постановке на учет',
          'Только в организациях, стационарно применяющих подъемники (вышки) для обслуживания технологического оборудования, находящегося в эксплуатации',
          'Во всех организациях, эксплуатирующих подъемники (вышки)',
        ],
        correctAnswers: [
          'Во всех организациях, эксплуатирующих подъемники (вышки)',
        ],
      },
      {
        code: '63701123',
        text: 'Какие требования к статическим испытаниям кранов указаны верно?',
        answers: [
          'Испытания кранов, имеющих сменное стреловое оборудование, проводятся с установленным стреловым оборудованием, а также после замены стрелового оборудования',
          'Испытания кранов стрелового типа, не имеющих механизма изменения вылета, проводятся при установленных для испытаний вылетах',
          'Статические испытания крана стрелового типа, имеющего одну или несколько грузовых характеристик, при периодическом или внеочередном техническом освидетельствовании проводятся в положении, соответствующем наибольшей грузоподъемности крана и (или) наибольшему грузовому моменту, если это не противоречит требованиям, изложенным в руководстве (инструкции) по эксплуатации ПС',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63701124',
        text:
          'Какие мероприятия должна выполнять эксплуатирующая организация для содержания ПС в работоспособном состоянии и обеспечения безопасных условий их работы?',
        answers: [
          'Только устанавливать порядок периодических осмотров, технических обслуживаний и ремонтов, обеспечивающих содержание ПС, рельсовых путей, грузозахватных органов, приспособлений и тары в работоспособном состоянии',
          'Только разрабатывать и утверждать журналы, программы, графики выполнения планово-предупредительных ремонтов, ППР, ТК, схемы строповки и складирования, должностные инструкции для инженерно-технических работников, а также производственные инструкции для персонала, на основе паспорта, руководства (инструкции) по эксплуатации конкретного ПС, с учетом особенностей технологических процессов, установленных проектной и технологической документацией',
          'Только обеспечивать наличие у инженерно-технических работников должностных инструкций и руководящих указаний по безопасной эксплуатации ПС, а у персонала - производственных инструкций и создавать условия выполнения инженерно-техническими работниками требований настоящих ФНП, должностных инструкций, а персоналом - производственных инструкций',
          'Только обеспечивать установленный порядок проверки знаний и допуска к самостоятельной работе персонала с выдачей удостоверений, в которых указывается тип ПС, а также виды работ и оборудования, к работам на которых они допущены',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63701125',
        text:
          'Какие требования предъявляются к испытанию стальных цепей, устанавливаемых на ПС, после их сращивания электросваркой?',
        answers: [
          'Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 мин',
          'Цепь должна быть испытана нагрузкой, в 2 раза превышающей ее расчетное натяжение, в течение 5 мин',
          'Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 15 мин',
          'Цепь должна быть испытана нагрузкой, в 1,75 раза превышающей ее расчетное натяжение, в течение 10 мин',
        ],
        correctAnswers: [
          'Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 мин',
        ],
      },
      {
        code: '63701126',
        text:
          'На каком расстоянии от элементов здания, оборудования и штабелей грузов следует устанавливать электрические тали и монорельсовые тележки с автоматическим или полуавтоматическим управлением, если во время движения указанные ПС не сопровождаются оператором?',
        answers: [
          'На расстоянии, указанном в инструкции по эксплуатации',
          'Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов',
          'С учетом максимальных габаритов транспортируемых грузов',
          'На расстоянии, определенном с учетом максимальных габаритов транспортируемых грузов и ширины прохода вдоль цеха для работников ОПО, если такой проход предусмотрен',
          'На расстоянии не менее 1500 мм',
        ],
        correctAnswers: [
          'Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов',
        ],
      },
      {
        code: '63701127',
        text:
          'С какой периодичностью должно проводиться комплексное обследование рельсовых путей ПС?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в семь лет',
          'Не реже одного раза в десять лет',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63701128',
        text:
          'При каком удлинении звена цепи от первоначального размера цепной строп подлежит браковке?',
        answers: [
          'Более 1,5% от первоначального размера',
          'Более 2,0% от первоначального размера',
          'Более 2,5% от первоначального размера',
          'Более 3,0% от первоначального размера',
        ],
        correctAnswers: ['Более 3,0% от первоначального размера'],
      },
      {
        code: '63701129',
        text:
          'Какое допускается максимальное отклонение по массе изделий, являющихся составной частью испытательного груза, при испытании специальных грузозахватных приспособлений?',
        answers: ['0,07', '0,03', '0,05', '0,04'],
        correctAnswers: ['0,03'],
      },
      {
        code: '63701130',
        text:
          'Каким образом должны быть расположены ветви многоветвевых стропов при испытаниях?',
        answers: [
          'Под углом 90º по вертикали друг к другу',
          'Под углом 60º по вертикали друг к другу',
          'Под углом 45º по вертикали друг к другу',
          'Под углом 30º по вертикали друг к другу',
        ],
        correctAnswers: ['Под углом 90º по вертикали друг к другу'],
      },
      {
        code: '63701131',
        text:
          'В каких случаях разрешается строповка пакетов металлопроката или труб за элементы упаковки (скрутки, стяжки)?',
        answers: [
          'В случае выполнения скрутки из мягкой стальной проволоки и при количестве скруток не менее трех',
          'В случае, если по техническим условиям на строповку они являются "хомутами"',
          'В случае подъема и перемещения пакетов металлопроката или труб с использованием траверсы, крюки которой застропованы в скрутки',
          'В случае применения их всего для двух последующих перегрузок: для загрузки в определенное место и последующей разгрузки',
          'Запрещается во всех случаях',
        ],
        correctAnswers: ['Запрещается во всех случаях'],
      },
      {
        code: '63701132',
        text:
          'Какой параметр из паспорта ПС (в виде выписки) в обязательном порядке должен быть включен в раздел ППР и технологической карты (далее – ТК), связанный с организацией безопасного производства работ с применением ПС?',
        answers: [
          'Высота подъема груза',
          'Вылет стрелы',
          'Максимальная грузоподъемность ПС',
          'Справка об установленных указателях, ограничителях и регистраторах',
          'Сила ветра, при которой его работа не допускается',
        ],
        correctAnswers: ['Сила ветра, при которой его работа не допускается'],
      },
      {
        code: '63701133',
        text:
          'Когда проводится осмотр состояния рельсовых путей ПС под руководством специалиста, ответственного за содержание ПС в работоспособном состоянии?',
        answers: [
          'После каждых 24 смен работы',
          'После каждых 12 смен работы',
          'После каждых 32 смен работы',
          'После каждых 48 смен работы',
        ],
        correctAnswers: ['После каждых 24 смен работы'],
      },
      {
        code: '63701134',
        text:
          'Кто дает разрешение на пуск ПС в работу после окончания ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?',
        answers: [
          'Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Руководитель организации, эксплуатирующей ПС',
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
          'Специалист организации, выполнившей работы по ремонту, реконструкции или модернизации ограничителя, указателя или регистратора',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
        ],
      },
      {
        code: '63701135',
        text:
          'Какие требования к статическим испытаниям грузозахватного приспособления указаны верно?',
        answers: [
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимают ПС на высоту 150 - 250 мм',
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с ускорениями поднимается на высоту 20 - 50 мм и выдерживается в таком положении не менее 10 минут',
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимается на высоту 50 - 100 мм',
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением, с возможно меньшими ускорениями поднимается на высоту 50 - 150 мм и выдерживается в таком положении не менее 15 минут',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимается на высоту 50 - 100 мм',
        ],
      },
      {
        code: '63701136',
        text:
          'В течение какого времени груз должен находиться на неподвижном грузонесущем устройстве без смещения грузонесущего устройства и без обнаружения трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов, чтобы строительный подъемник считался выдержавшим статические испытания?',
        answers: [
          'В течение 15 минут',
          'В течение 20 минут',
          'В течение 10 минут',
          'В течение 5 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63701137',
        text:
          'Что из перечисленного допускается при выполнении работ с применением ПС?',
        answers: [
          'Подъем груза непосредственно с места его установки (с земли, площадки, штабеля) только механизмом телескопирования стрелы',
          'Перемещение подъемников и вышек с людьми вдоль контактной сети или конструкций моста на минимальной скорости согласно требованиям разработанного для этого ППР',
          'Освобождение с применением ПС защемленных грузом стропов, канатов или цепей',
          'Перемещение груза, подвешенного за один рог двурогого крюка',
        ],
        correctAnswers: [
          'Перемещение подъемников и вышек с людьми вдоль контактной сети или конструкций моста на минимальной скорости согласно требованиям разработанного для этого ППР',
        ],
      },
      {
        code: '63701138',
        text:
          'В каком случае допускается эксплуатация текстильных стропов на полимерной основе?',
        answers: [
          'Только если имеются продольные порезы или разрывы ленты, суммарная длина которых превышает 10% длины ленты ветви стропа',
          'Только если присутствует выпучивание нитей из ленты стропа на расстояние более 10% ширины ленты',
          'Только если имеются поверхностные обрывы нитей ленты общей длиной более 10% ширины ленты, вызванные механическим воздействием (трением) острых кромок груза',
          'Только если отсутствует клеймо (бирка) или не читаются сведения о стропе, которые содержат информацию об изготовителе, грузоподъемности',
          'Эксплуатация запрещается во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Эксплуатация запрещается во всех перечисленных случаях',
        ],
      },
      {
        code: '63701139',
        text:
          'Какая организация имеет право вносить изменения в разработанный проект производства работ (далее – ППР) ПС для выполнения строительно-монтажных работ?',
        answers: [
          'Только эксплуатирующая организация',
          'Только организация - разработчик ППР',
          'Только проектная организация',
          'Только специализированная экспертная организация',
        ],
        correctAnswers: ['Только организация - разработчик ППР'],
      },
      {
        code: '63701140',
        text:
          'Каким испытаниям подлежат механизмы подъема ПС, если предусмотрена их раздельная работа?',
        answers: [
          'Каждый механизм должен быть испытан только статической нагрузкой',
          'Каждый механизм должен быть испытан только динамической нагрузкой',
          'Каждый механизм должен быть испытан статической и динамической нагрузкой',
        ],
        correctAnswers: [
          'Каждый механизм должен быть испытан статической и динамической нагрузкой',
        ],
      },
      {
        code: '63701141',
        text:
          'С какой периодичностью проводится плановая проверка состояния рельсового пути?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в квартал',
          'Не реже одного раза в год',
          'Не реже одного раза в полугодие',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63701142',
        text:
          'На какие из перечисленных ниже опасные производственные объекты (далее - ОПО) не распространяются требования федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения" (далее - ФНП ПС)?',
        answers: [
          'На ОПО, где эксплуатируются грузоподъемные краны',
          'На ОПО, где эксплуатируются строительные подъемники',
          'На ОПО, где эксплуатируются канатные дороги',
          'На ОПО, где эксплуатируются подъемники (вышки)',
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
        ],
        correctAnswers: ['На ОПО, где эксплуатируются канатные дороги'],
      },
      {
        code: '63701143',
        text:
          'В каких случаях необходимо прекращать работу ПС, установленных на открытом воздухе?',
        answers: [
          'Только при температуре окружающей среды ниже предельно допустимой температуры, указанной в паспорте ПС',
          'Только при скорости ветра, превышающей предельно допустимую скорость, указанную в паспорте ПС',
          'Только при снегопаде, дожде, тумане, в случаях, когда крановщик (машинист, оператор) плохо различает сигналы стропальщика или перемещаемый груз',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63701144',
        text:
          'Каким должно быть безопасное расстояние от низа перемещаемого груза до наиболее выступающих по вертикали частей здания или сооружения?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 0,3 м',
          'Не менее 0,4 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 0,5 м'],
      },
      {
        code: '63701145',
        text:
          'С какой периодичностью производятся частичная разборка, осмотр и ревизия элементов, узлов и соединений грузозахватных приспособлений (клещи, траверсы, захваты) для контроля технического состояния, которое невозможно определить в собранном виде?',
        answers: ['Ежемесячно', 'Ежеквартально', 'Ежегодно', 'Ежесменно'],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63701146',
        text: 'Кем осуществляется ежесменный осмотр рельсового пути ПС?',
        answers: [
          'Начальником производственного цеха или участка',
          'Работниками службы эксплуатации, являющейся ответственной за состояние рельсовых путей',
          'Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией',
          'Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС',
        ],
        correctAnswers: [
          'Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией',
        ],
      },
      {
        code: '63701147',
        text:
          'Какие действия необходимо выполнить для утилизации (ликвидации) подъемника (вышки)?',
        answers: [
          'Согласовать ППР в территориальном органе Ростехнадзора',
          'Получить письменное распоряжение владельца ОПО',
          'Провести диагностику состояния ПС',
          'Демонтировать подъемник (вышку)',
          'Провести внеочередное комплексное обследование рельсовых путей',
        ],
        correctAnswers: ['Демонтировать подъемник (вышку)'],
      },
      {
        code: '63701148',
        text:
          'В соответствии с требованиями каких документов должна проводиться установка ПС в зданиях, на открытых площадках и других участках производства работ? Выберите 2 варианта ответа.',
        answers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Технологического регламента',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Проекта производства работ',
        ],
        correctAnswers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63701149',
        text:
          'Что должно быть предпринято в случае, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между оператором (крановщиком) и стропальщиком радио- или телефонной связи?',
        answers: [
          'Должен быть назначен сигнальщик из числа стропальщиков',
          'Должно быть назначено лицо, ответственное за безопасное производство работ',
          'Должны быть разработаны условные обозначения для передачи сигнала',
          'На месте проведения работ должен присутствовать ответственный за исправное состояние ПС',
        ],
        correctAnswers: [
          'Должен быть назначен сигнальщик из числа стропальщиков',
        ],
      },
      {
        code: '63701150',
        text:
          'В течение какого времени должен находиться в первоначально поднятом состоянии над землей груз при проведении статического испытания кабельных кранов?',
        answers: [
          'В течение 30 минут',
          'В течение 20 минут',
          'В течение 60 минут',
          'В течение 10 минут',
        ],
        correctAnswers: ['В течение 30 минут'],
      },
      {
        code: '63701151',
        text:
          'Каким документом определяется объем работ, порядок и периодичность проведения технических освидетельствований ПС?',
        answers: [
          'Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения", утвержденными приказом Ростехнадзора от 12 ноября 2013 г. № 533',
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Паспортом ПС',
          'Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний - требованиями ФНП ПС',
        ],
        correctAnswers: [
          'Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний - требованиями ФНП ПС',
        ],
      },
      {
        code: '63701152',
        text:
          'Какое из перечисленных требований запрещается при перемещении груза ПС? Выберите 2 варианта ответа.',
        answers: [
          'Горизонтальное перемещение груза на 0,5 м выше встречающихся на пути предметов',
          'Перемещение груза при нахождении под ним людей',
          'Подъем груза, масса которого неизвестна',
          'Кантовка грузов с применением ПС на весу по заранее разработанным ППР или технологической документации',
          'Перемещение кирпича на поддонах без ограждения при разгрузке транспортных средств на землю',
        ],
        correctAnswers: [
          'Перемещение груза при нахождении под ним людей',
          'Подъем груза, масса которого неизвестна',
        ],
      },
      {
        code: '63701153',
        text:
          'Каким оборудованием в эксплуатирующей организации должны быть обеспечены ее стропальщики с целью обеспечения промышленной безопасности технологических процессов строповки?',
        answers: [
          'Ломами, кувалдами, молотками',
          'Цепными стропами, соответствующими массе перемещаемых грузов',
          'Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов',
          'Оборудованием, применяемым в технологических процессах транспортировки грузов, принятых в эксплуатирующей организации',
        ],
        correctAnswers: [
          'Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов',
        ],
      },
      {
        code: '63701154',
        text:
          'При каких величинах суммарной массы тары с перемещаемым грузом допускается применение башенных кранов с тарой, разгружаемой на весу, в пределах группы классификации (режима), указанного в паспорте крана, при числе циклов работы крана не более 8 в час?',
        answers: [
          'Только для тары без вибраторов (исключая грейферы) - в пределах грузоподъемности крана',
          'Только для одноканатных грейферов, не допускающих разгрузку на весу, - не более 50% грузоподъемности крана',
          'Только для тары с вибратором - не более 50% от максимальной грузоподъемности крана',
          'Применение допускается во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Применение допускается во всех перечисленных случаях',
        ],
      },
      {
        code: '63701155',
        text:
          'На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?',
        answers: [
          'Не более 155 мм',
          'Не более 150 мм',
          'Не более 165 мм',
          'Не более 160 мм',
        ],
        correctAnswers: ['Не более 150 мм'],
      },
    ],
    63704: [
      {
        code: '63704000',
        text:
          'Куда вносятся отметки о монтаже и наладке ограничителя, указателя и регистратора ПС? Укажите все правильные ответы.',
        answers: [
          'В паспорт ПС',
          'В паспорт ограничителя, указателя или регистратора',
          'В заключение экспертизы промышленной безопасности',
          'В паспорт безопасности ОПО, на котором эксплуатируется ПС',
        ],
        correctAnswers: [
          'В паспорт ПС',
          'В паспорт ограничителя, указателя или регистратора',
        ],
      },
      {
        code: '63704001',
        text:
          'Какое допускается максимальное отклонение по массе изделий, являющихся составной частью испытательного груза, при испытании специальных грузозахватных приспособлений?',
        answers: ['0,03', '0,05', '0,04', '0,07'],
        correctAnswers: ['0,03'],
      },
      {
        code: '63704002',
        text:
          'Какие из перечисленных ниже нарушений не могут служить причиной остановки эксплуатации подъемника?',
        answers: [
          'Обслуживание подъемника ведется неаттестованным персоналом',
          'Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию',
          'Не выполнены предписания по обеспечению безопасной эксплуатации подъемника, выданные эксплуатирующей организации',
          'Истек срок технического освидетельствования подъемника',
        ],
        correctAnswers: [
          'Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию',
        ],
      },
      {
        code: '63704003',
        text:
          'С учетом требований какого документа должна выполняться утилизация (ликвидация) ПС? Укажите все правильные ответы.',
        answers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Руководства (инструкции) по эксплуатации ПС',
          'Проектной документации',
          'Всех перечисленных документов',
        ],
        correctAnswers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Руководства (инструкции) по эксплуатации ПС',
        ],
      },
      {
        code: '63704004',
        text:
          'Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?',
        answers: [
          'Только входной контроль',
          'Только операционный контроль',
          'Только приемочный контроль',
          'Все перечисленные виды контроля',
        ],
        correctAnswers: ['Все перечисленные виды контроля'],
      },
      {
        code: '63704005',
        text:
          'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          'Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС – Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
          'Такие ПС должны быть приведены в соответствие с требованиями Технического регламента ТР ТС 010/2011 «О безопасности машин и оборудования»',
          'Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для всех стадий жизненного цикла этих ПС',
        ],
        correctAnswers: [
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
        ],
      },
      {
        code: '63704006',
        text:
          'Каким требованиям должны соответствовать такелажная оснастка и вспомогательные механизмы, используемые при выполнении ремонта и реконструкции или модернизации ПС?',
        answers: [
          'Эксплуатационным документам ПС',
          'Должны пройти статические и динамические испытания',
          'Должны быть проведены дополнительные расчеты такелажной оснастки и вспомогательных механизмов',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Эксплуатационным документам ПС'],
      },
      {
        code: '63704007',
        text:
          'Кто разрабатывает проект для реконструкции или модернизации ограничителя, указателя или регистратора в случае, когда изготовителя ПС установить невозможно?',
        answers: [
          'Специализированная организация',
          'Эксплуатирующая организация',
          'Изготовитель аналогичного оборудования',
          'Любая проектная организация',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63704008',
        text:
          'Какие требования к сборке и соединению сборочных единиц ПС указаны неверно?',
        answers: [
          'Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должна выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС',
          'Крупногабаритные сборочные единицы ПС должны укладываться на место последующего монтажа с применением грузоподъемных механизмов, при этом положение стыкуемых элементов по высоте должно регулироваться в соответствии с требованиями руководства (инструкции) по эксплуатации ПС',
          'Фактическая несоосность (непараллельность) стыкуемых сборочных единиц не должна превышать величин соответствующих допусков, приведенных в руководстве (инструкции) по эксплуатации ПС',
          'Сборка и соединение отдельных сборочных единиц ПС должна выполняться согласно требованиям руководства (инструкции) по эксплуатации и другой эксплуатационной документации ПС',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должна выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС',
        ],
      },
      {
        code: '63704009',
        text:
          'С какой периодичностью результаты осмотров рельсовых путей заносятся в вахтенные журналы крановщика (оператора) всех ПС, установленных на одном рельсовом пути?',
        answers: [
          'После каждых 24 смен работы',
          'После каждых 30 смен работы',
          'После каждых 20 смен работы',
          'После каждых 48 смен работы',
        ],
        correctAnswers: ['После каждых 24 смен работы'],
      },
      {
        code: '63704010',
        text:
          'Какие требования к статическим испытаниям подъемников (вышек) (кроме строительных) указаны неверно?',
        answers: [
          'Статические испытания подъемников (вышек) (кроме строительных) должны проводиться при установке подъемника (вышки) на горизонтальной площадке в положении, отвечающем наименьшей расчетной его устойчивости',
          'На подъемниках (вышках), оборудованных люлькой, груз массой, равной 110% от номинальной грузоподъемности, следует располагать в люльке, а второй груз массой, равной 40% от номинальной грузоподъемности, следует подвешивать к люльке на гибкой подвеске',
          'После начала подъема и отрыва второго груза от земли на высоту 50 - 100 мм, следует делать остановку с последующей выдержкой суммарного груза в течение 10 минут',
          'На подъемниках (вышках), оборудованных люлькой, груз массой, равной 125% от номинальной грузоподъемности, располагают в люльке, а второй груз массой, равной 40% от номинальной грузоподъемности, подвешивают к люльке на гибкой подвеске',
        ],
        correctAnswers: [
          'На подъемниках (вышках), оборудованных люлькой, груз массой, равной 125% от номинальной грузоподъемности, располагают в люльке, а второй груз массой, равной 40% от номинальной грузоподъемности, подвешивают к люльке на гибкой подвеске',
        ],
      },
      {
        code: '63704011',
        text:
          'Каким образом должны быть расположены ветви многоветвевых стропов при испытаниях?',
        answers: [
          'Под углом 90° по вертикали друг к другу',
          'Под углом 45° по горизонтали друг к другу',
          'Под углом 90° по горизонтали друг к другу',
          'Под углом 45° по вертикали друг к другу',
        ],
        correctAnswers: ['Под углом 90° по вертикали друг к другу'],
      },
      {
        code: '63704012',
        text:
          'На какие из перечисленных ОПО распространяются требования ФНП ПС?',
        answers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
          'На ОПО, где эксплуатируются подъемные сооружения, установленные в шахтах',
          'На ОПО, где эксплуатируются подъемные сооружения, установленные на любых плавучих средствах',
          'На ОПО, где эксплуатируются манипуляторы, используемые в технологических процессах',
          'На ОПО, где эксплуатируются подъемники (вышки), предназначенные для перемещения людей, людей и груза (подъемники с рабочими платформами) с высотой подъема до 6 м включительно',
        ],
        correctAnswers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
        ],
      },
      {
        code: '63704013',
        text:
          'Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?',
        answers: [
          'Только используемые при сборке приспособления и оборудование',
          'Только порядок и последовательность сборки',
          'Только способы крепления деталей',
          'Только размеры, количество и расположение прихваток',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63704014',
        text:
          'Кто является председателем комиссии, на основании предложений которой принимается решение о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?',
        answers: [
          'Уполномоченный представитель эксплуатирующей организации',
          'Уполномоченный представитель Ростехнадзора',
          'Уполномоченный представитель специализированной организации',
          'Уполномоченный представитель проектной организации',
        ],
        correctAnswers: [
          'Уполномоченный представитель эксплуатирующей организации',
        ],
      },
      {
        code: '63704015',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума',
          'Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ',
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63704016',
        text:
          'Допускается ли при выполнении строительно-монтажных или погрузочно-разгрузочных работ перемещение грузов с применением ПС над перекрытиями, под которыми размещены производственные, жилые или служебные помещения, где могут находиться люди?',
        answers: [
          'Не допускается',
          'Допускается в исключительных случаях в присутствии и под руководством специалиста, ответственного за безопасное производство работ с применением ПС',
          'Допускается в исключительных случаях в присутствии и под руководством специалиста, ответственного за исправное состояние ПС',
          'Допускается при условии, что места производства работ будут ограждены и обозначены предупредительными знаками',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63704017',
        text:
          'Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.',
        answers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Федерального закона "О промышленной безопасности опасных производственных объектов" от 21 июля 1997 г. N 116-ФЗ',
          'Технического регламента ТР ТС 018/2011 "О безопасности колесных транспортных средств"',
        ],
        correctAnswers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
      },
      {
        code: '63704018',
        text:
          'Какой документ подтверждает готовность рельсового пути к эксплуатации, в том числе после ремонта (реконструкции)?',
        answers: [
          'Руководство (инструкция) по эксплуатации ПС с соответствующей отметкой',
          'Протокол проверки готовности рельсового пути',
          'Экспертное заключение',
          'Акт сдачи-приемки',
        ],
        correctAnswers: ['Акт сдачи-приемки'],
      },
      {
        code: '63704019',
        text:
          'Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?',
        answers: [
          'Руководитель независимого аттестационного центра',
          'Представитель Ростехнадзора',
          'Руководитель организации',
          'Технический руководитель организации',
        ],
        correctAnswers: ['Руководитель независимого аттестационного центра'],
      },
      {
        code: '63704020',
        text:
          'С какой перегрузкой проводятся испытания на строительных подъемниках при проведении полного технического освидетельствования и проверки работоспособности ловителей (аварийных остановов)?',
        answers: ['15%', '10%', '5%', '25%'],
        correctAnswers: ['10%'],
      },
      {
        code: '63704021',
        text:
          'Какие требования предъявляются к испытанию стальных цепей, устанавливаемых на ПС, после их сращивания электросваркой?',
        answers: [
          'Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 минут',
          'Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 10 минут',
          'Цепь должна быть испытана нагрузкой, в 2,5 раза превышающей ее расчетное натяжение, в течение 10 минут',
          'Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 15 минут',
        ],
        correctAnswers: [
          'Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 минут',
        ],
      },
      {
        code: '63704022',
        text:
          'Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?',
        answers: [
          'Руководства (инструкции) по эксплуатации',
          'Паспорта ПС',
          'Журнала осмотра',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации'],
      },
      {
        code: '63704023',
        text:
          'Какие требования к средствам измерений, используемым в процессе испытаний ПС, указаны верно?',
        answers: [
          'Средства измерений должны быть поверены или калиброваны',
          'Все перечисленные требования',
          'Средства измерений должны быть аттестованы в установленном порядке',
        ],
        correctAnswers: [
          'Средства измерений должны быть поверены или калиброваны',
        ],
      },
      {
        code: '63704024',
        text:
          'Каким лицом должны быть внесены изменения после реконструкции ПС, связанной с переводом ПС на дистанционное управление (радиоуправление), в паспорт и руководство (инструкцию) по эксплуатации ПС?',
        answers: [
          'Лицом, выполнившим указанные работы',
          'Лицом, которое разработало изменения по переводу ПС на дистанционное управление',
          'Лицом, ответственным за исправное состояние ПС',
          'Лицом, управляющим данным ПС',
        ],
        correctAnswers: ['Лицом, выполнившим указанные работы'],
      },
      {
        code: '63704025',
        text:
          'В течение какого времени допускается временное хранение профильного проката на открытом воздухе?',
        answers: [
          'В течение 3 месяцев со дня поставки',
          'В течение 4 месяцев со дня поставки',
          'В течение 5 месяцев со дня поставки',
          'В течение 7 месяцев со дня поставки',
        ],
        correctAnswers: ['В течение 3 месяцев со дня поставки'],
      },
      {
        code: '63704026',
        text:
          'Кто разрабатывает инструкцию, согласно которой осуществляется обслуживание систем дистанционного управления (радиоуправления) при эксплуатации ПС?',
        answers: [
          'Изготовитель',
          'Монтажная (специализированная) организация',
          'Экспертная организация',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63704027',
        text:
          'На кого возлагается ответственность за приведение в соответствие ПС, изготовленного по ранее разработанным проектам и не оборудованного ограничителями, указателями и регистраторами, необходимыми для обеспечения промышленной безопасности технологического процесса, в котором используется ПС?',
        answers: [
          'На эксплуатирующую организацию',
          'На экспертную организацию',
          'На проектную организацию',
          'На монтажную организацию',
        ],
        correctAnswers: ['На эксплуатирующую организацию'],
      },
      {
        code: '63704028',
        text:
          'Какие из нижеперечисленных ПС подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны стрелового типа грузоподъемностью до 1 т включительно',
          'Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении',
          'Краны стрелового типа с постоянным вылетом или не оборудованные механизмом поворота',
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
        correctAnswers: [
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
      },
      {
        code: '63704029',
        text:
          'Какие из перечисленных требований, предъявляемых к ПС, выбираемым для транспортирования людей при проведении диагностирования и ремонта металлоконструкций других ПС, указаны неверно?',
        answers: [
          'ПС должны обеспечивать скорость перемещения кабины по вертикали не более 40 м в минуту',
          'Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при перемещении персонала для крепления и раскрепления контейнеров и грузов',
          'Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при проведении диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно',
          'Подъем и транспортирование людей с применением подъемных сооружений, в паспорте которых отсутствует разрешение на транспортирование людей, разрешается при монтаже и обслуживании отдельно стоящих буровых и иных установок нефтегазодобычи',
        ],
        correctAnswers: [
          'ПС должны обеспечивать скорость перемещения кабины по вертикали не более 40 м в минуту',
        ],
      },
      {
        code: '63704030',
        text:
          'С какой нагрузкой по отношению к номинальной паспортной грузоподъемности должна проводиться проверка качества выполненного ремонта грузозахватных приспособлений с проведением статических испытаний?',
        answers: ['125%', '105%', '120%', '150%'],
        correctAnswers: ['125%'],
      },
      {
        code: '63704031',
        text:
          'В каких случаях проводятся испытания на грузовую устойчивость при первичном техническом освидетельствовании стрелового самоходного крана?',
        answers: [
          'Только когда в паспорте крана отсутствуют ссылки на результаты ранее проведенных испытаний',
          'Только когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с применением сварки',
          'Только когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута изменению первоначальных геометрических размеров',
          'Только когда конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63704032',
        text:
          'Какие требования к статическим испытаниям крана-трубоукладчика или крана-манипулятора указаны неверно?',
        answers: [
          'Кран-трубоукладчик и кран-манипулятор считаются выдержавшими испытания, если в течение 10 минут поднятый груз не опустился, а также не обнаружено трещин, остаточных деформаций и других повреждений',
          'После установки на кран-трубоукладчик (кран-манипулятор) сменного стрелового оборудования испытания проводятся в положении, соответствующем наибольшей грузоподъемности, при установленном оборудовании',
          'Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут',
        ],
        correctAnswers: [
          'Крюком поднимают груз на высоту 100 - 150 мм от земли и выдерживают в течение не менее 5 минут',
        ],
      },
      {
        code: '63704033',
        text:
          'Каким требованиям из перечисленных должны отвечать рельсовый путь ПС (исключая рельсовые пути башенных и железнодорожных кранов) и рельсовый путь грузовых подвесных тележек или электрических талей, оборудованный стрелками или поворотными кругами, а также места перехода ПС или его грузовой тележки с одного пути на другой?',
        answers: [
          'Только обеспечивать плавный, без заеданий, проезд',
          'Только иметь автоматически включаемую блокировку, исключающую сход грузовой тележки с рельса при выезде ее на консоль расстыкованного участка пути',
          'Только быть оборудованными замками с электрической блокировкой, исключающей переезд при незапертом замке',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63704034',
        text: 'Кто может проводить монтаж временных электрических сетей?',
        answers: [
          'Аттестованный специалист по монтажу',
          'Аттестованный специалист по монтажу и наладке электрооборудования ПС',
          'Аттестованный электромонтер',
          'Электрик организации, эксплуатирующей ПС',
          'Любой электромонтер организации, эксплуатирующей ПС',
        ],
        correctAnswers: ['Аттестованный электромонтер'],
      },
      {
        code: '63704035',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
          'Только журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю',
          'Только протоколы испытаний сварных соединений',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63704036',
        text:
          'Кем выдается наряд-допуск на работу вблизи находящихся под напряжением проводов и оборудования в случаях, когда работы с применением кранов стрелового типа, кранов-манипуляторов, подъемников (вышек) ведутся на действующих электростанциях, подстанциях и линиях электропередачи?',
        answers: [
          'Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи',
          'Организацией, эксплуатирующей кран',
          'Организацией - изготовителем крана',
          'Представителем территориального отделения Ростехнадзора',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи',
        ],
      },
      {
        code: '63704037',
        text:
          'Кем в специализированной организации должен осуществляться контроль соблюдения специализированной организацией требований проекта, ремонтных чертежей и технологии производства ремонтных работ?',
        answers: [
          'Службой отдела технического контроля',
          'Эксплуатирующей организацией',
          'Службой производственного контроля',
          'Производственно-техническим отделом',
        ],
        correctAnswers: ['Службой отдела технического контроля'],
      },
      {
        code: '63704038',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
          'Необходимым сборочно-сварочным оборудованием и наглядными пособиями (плакатами, схемами и т. д.) по вопросу соблюдения противопожарного режима на объекте',
          'Ничем, кроме сварочного оборудования',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63704039',
        text:
          'На основании какой документации должны осуществляться монтаж и наладка системы дистанционного управления (радиоуправления) ПС? Выберите 2 варианта ответа.',
        answers: [
          'Эксплуатационной документации на ПС',
          'Документации изготовителя системы дистанционного управления (радиоуправления)',
          'Ремонтной ведомости на ПС',
          'Сертификата соответствия на систему дистанционного управления (радиоуправления)',
        ],
        correctAnswers: [
          'Эксплуатационной документации на ПС',
          'Документации изготовителя системы дистанционного управления (радиоуправления)',
        ],
      },
      {
        code: '63704040',
        text:
          'Каким документом должно быть подтверждено соответствие проекту рельсового пути (для ПС на рельсовом ходу) по результатам выполнения монтажа?',
        answers: [
          'Актом сдачи-приемки монтажного участка пути или актом сдачи-приемки рельсового пути под монтаж, если к проведению монтажа путь монтировался на всю рабочую длину',
          'Актом освидетельствования скрытых работ',
          'Актом освидетельствования работ рельсового пути и соответствия требованиям руководства (инструкции) по эксплуатации ПС',
        ],
        correctAnswers: [
          'Актом сдачи-приемки монтажного участка пути или актом сдачи-приемки рельсового пути под монтаж, если к проведению монтажа путь монтировался на всю рабочую длину',
        ],
      },
      {
        code: '63704041',
        text:
          'Кем делается запись в паспорте ПС о проведенной работе по завершении выполнения ремонта, реконструкции или модернизации ПС?',
        answers: [
          'Эксплуатирующей организацией',
          'Специализированной организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Специализированной организацией'],
      },
      {
        code: '63704042',
        text:
          'Кого относят к работникам специализированных организаций, занимающихся выполнением работ по монтажу (демонтажу), наладке либо ремонту, реконструкции или модернизации в процессе эксплуатации ПС?',
        answers: [
          'Всех сотрудников организации, включая лиц рабочих профессий',
          'Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
          'Руководителей, специалистов и персонал - лиц рабочих профессий основных служб организации',
          'Физических лиц основных служб организации, непосредственно занятых на выполнении работ',
        ],
        correctAnswers: [
          'Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
        ],
      },
      {
        code: '63704043',
        text:
          'В каких случаях к акту сдачи-приемки рельсового пути, определяющему его готовность к эксплуатации, должны прикладываться данные планово-высотной съемки?',
        answers: [
          'Только после реконструкции',
          'Только после ремонта',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63704044',
        text:
          'В течение какого времени груз должен находиться на неподвижном грузонесущем устройстве без смещения грузонесущего устройства и без обнаружения трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов, чтобы строительный подъемник считался выдержавшим статические испытания?',
        answers: [
          'В течение 10 минут',
          'В течение 5 минут',
          'В течение 7 минут',
          'В течение 15 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63704045',
        text:
          'Каким критериям должен соответствовать выбор оборудования для безопасного выполнения работ по монтажу (демонтажу) ПС?',
        answers: [
          'Только конкретному монтируемому ПС',
          'Только составу работ, предусмотренному руководством (инструкцией) по эксплуатации ПС',
          'Только эксплуатационным документам ПС',
          'Всем перечисленным критериям',
        ],
        correctAnswers: ['Всем перечисленным критериям'],
      },
      {
        code: '63704046',
        text:
          'Результаты каких наладочных работ необходимо отразить в акте по окончании монтажа ПС?',
        answers: [
          'Подтверждающие работоспособность и возможность выполнения полного технического освидетельствования ПС',
          'Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов',
          'Подтверждающие исправность ПС и возможность передачи его в эксплуатацию',
          'Подтверждающие возможность проведения грузовых испытаний смонтированного ПС',
          'Результаты наладочных работ отражению в акте по окончании монтажа не подлежат',
        ],
        correctAnswers: [
          'Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов',
        ],
      },
      {
        code: '63704047',
        text: 'Что должно быть указано в технологических картах сварки?',
        answers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
          'Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, технические приемы контроля качества сварных соединений',
          'Требования к применяемым сварочным технологиям, последовательность операций, технические приемы, особенности процесса сварки, обеспечивающие качество сварных соединений',
        ],
        correctAnswers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
        ],
      },
      {
        code: '63704048',
        text:
          'Кто имеет право вносить изменения в ППР и ТК в процессе монтажа?',
        answers: [
          'Организация, отвечающая за выполнение монтажа',
          'Специалист, ответственный за содержание ПС в работоспособном состоянии',
          'Изготовитель монтируемого ПС',
          'Специалист организации, которая будет эксплуатировать ПС по окончании его монтажа',
          'Специалист, ответственный за безопасное производство работ с применением ПС',
        ],
        correctAnswers: ['Организация, отвечающая за выполнение монтажа'],
      },
      {
        code: '63704049',
        text:
          'В каком случае допускается эксплуатация текстильных стропов на полимерной основе?',
        answers: [
          'Эксплуатация запрещается во всех перечисленных случаях',
          'Если присутствует выпучивание нитей из ленты стропа на расстояние более 10% ширины ленты',
          'Если отсутствует клеймо (бирки), не читаются сведения о стропе, которые содержат информацию об изготовителе, грузоподъемности',
          'Если имеются поверхностные обрывы нитей ленты общей длиной более 10% ширины ленты, вызванные механическим воздействием (трением) острых кромок груза',
          'Если имеются продольные порезы или разрывы ленты, суммарная длина которых превышает 10% длины ленты ветви стропа',
        ],
        correctAnswers: [
          'Эксплуатация запрещается во всех перечисленных случаях',
        ],
      },
      {
        code: '63704050',
        text:
          'Сколько раз допускается повторная сварка на одном и том же участке сварного соединения?',
        answers: [
          'Повторная сварка запрещена',
          'Не более трех раз',
          'Не более четырех раз',
          'Не более двух раз',
        ],
        correctAnswers: ['Не более двух раз'],
      },
      {
        code: '63704051',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
          'Проверку знания теоретических основ сварки',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63704052',
        text:
          'Какие работы относятся к работам в местах действия опасных факторов?',
        answers: [
          'Только монтаж конструкций, имеющих большую парусность и габариты (витражи, фермы, перегородки, стеновые панели)',
          'Только монтаж в зоне примыкания к эксплуатируемым зданиям (сооружениям)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63704053',
        text: 'Какие требования к статическим испытаниям кранов указаны верно?',
        answers: [
          'Испытания кранов стрелового типа, не имеющих механизма изменения вылета (стрела поддерживается растяжкой), проводятся при установленных для испытаний вылетах',
          'Испытания кранов, имеющих сменное стреловое оборудование, проводятся с установленным стреловым оборудованием, а также после замены стрелового оборудования',
          'Статические испытания крана стрелового типа, имеющего одну или несколько грузовых характеристик, при периодическом или внеочередном техническом освидетельствовании проводятся в положении, соответствующем наибольшей грузоподъемности крана и/или наибольшему грузовому моменту, если это не противоречит требованиям, изложенным в руководстве (инструкции) по эксплуатации ПС',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63704054',
        text:
          'Каким требованиям должна отвечать площадка для выполнения монтажа ПС?',
        answers: [
          'Соответствовать геометрическим размерам монтируемого ПС с учетом дополнительных проходов вокруг него для персонала, занятого на монтаже',
          'Соответствовать требованиям площадки установки вспомогательных ПС, используемых на монтаже',
          'Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж',
          'Не иметь в непосредственной близости складируемых горючих материалов и материалов, поддерживающих горение, если монтаж выполняется с применением сварки',
          'Соответствовать установленным требованиям противопожарной безопасности и охраны труда',
        ],
        correctAnswers: [
          'Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж',
        ],
      },
      {
        code: '63704055',
        text:
          'Чему равен допустимый остаточный прогиб пролетного строения кранов мостового типа в вертикальной плоскости, где L - пролет крана?',
        answers: ['0,0035L', '0,002L', '0,003L', '0,0025L'],
        correctAnswers: ['0,0035L'],
      },
      {
        code: '63704056',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
          'Допускается применение клейма сварщика, выполнившего наибольший объем работ',
          'Должны быть поставлены клейма всех сварщиков, участвовавших в сварке',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63704057',
        text:
          'Какие требования к проверке ограничителя предельного верхнего положения грузозахватного органа указаны неверно?',
        answers: [
          'Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором',
          'Проверка ограничителя должна проводиться без груза',
          'Проверка осуществляется путем контроля с замером расстояния между верхней точкой грузозахватного органа и нижней частью металлоконструкции (после остановки механизма)',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором',
        ],
      },
      {
        code: '63704058',
        text:
          'Какая организация должна располагать контрольно-измерительными приборами, позволяющими оценивать работоспособность и регулировку оборудования ПС при проведении ремонта (монтажа)?',
        answers: [
          'Специализированная организация',
          'Организация, осуществляющая эксплуатацию ПС',
          'Организация - поставщик ПС',
          'Организация, имеющая аттестованного электромеханика',
          'Организация, имеющая аттестованных электромеханика и гидравлика',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63704059',
        text:
          'С чем в обязательном порядке должны быть ознакомлены работники, выполняющие работы по монтажу (демонтажу) и наладке ПС?',
        answers: [
          'Только с руководством (инструкцией) по монтажу, регламентирующим порядок операций',
          'Только с производственными инструкциями',
          'Только с должностными инструкциями',
          'Только с рабочими процедурами (характеристикой работ)',
          'Со всем перечисленным',
        ],
        correctAnswers: ['Со всем перечисленным'],
      },
      {
        code: '63704060',
        text:
          'Какая организация имеет право вносить изменения в разработанный проект производства работ (далее – ППР) ПС для выполнения строительно-монтажных работ?',
        answers: [
          'Только организация - разработчик ППР',
          'Только специализированная организация',
          'Только эксплуатирующая организация',
          'Только проектная организация',
          'Только специализированная экспертная организация',
        ],
        correctAnswers: ['Только организация - разработчик ППР'],
      },
      {
        code: '63704061',
        text:
          'Какой документ подтверждает соответствие ПС требованиям технических регламентов?',
        answers: [
          'Паспорт ПС',
          'Протокол испытаний, проведенных изготовителем',
          'Сертификат или декларация соответствия',
          'Акт технического освидетельствования',
        ],
        correctAnswers: ['Сертификат или декларация соответствия'],
      },
      {
        code: '63704062',
        text:
          'В какой документации устанавливаются нормы, согласно которым определяется качество ремонтных сварных соединений ПС?',
        answers: [
          'Только в эксплуатационной документации',
          'Только в проекте ремонта, реконструкции или модернизации ПС',
          'Во всей перечисленной документации',
        ],
        correctAnswers: ['Во всей перечисленной документации'],
      },
      {
        code: '63704063',
        text:
          'При установке опорных мостовых кранов с какой фактической группой классификации (режима) в пролетах зданий должны быть устроены галереи для прохода вдоль рельсового пути с обеих сторон пролета?',
        answers: ['А6 и более', 'А1 и более', 'А4 и более', 'А3 и более'],
        correctAnswers: ['А6 и более'],
      },
      {
        code: '63704064',
        text:
          'Требованиям какого документа должно соответствовать выполнение погрузочно-разгрузочных работ на монтаже с применением подъемных сооружений (далее – ПС)?',
        answers: [
          'Руководства (инструкции) по монтажу ПС',
          'Технических условий',
          'Паспорта ПС',
          'Руководства (инструкции) по эксплуатации ПС',
        ],
        correctAnswers: ['Руководства (инструкции) по монтажу ПС'],
      },
      {
        code: '63704065',
        text:
          'Кто может выполнять работы по неразрушающему контролю, если работы по монтажу, ремонту, реконструкции или модернизации выполнялись с применением сварки?',
        answers: [
          'Только собственная аттестованная лаборатория, имеющая поверенные приборы неразрушающего контроля',
          'Физические лица, аттестованные в установленном порядке по выполнению неразрушающего контроля',
          'Собственная аттестованная лаборатория или аттестованная лаборатория, привлекаемая на договорной основе',
          'Любые специалисты по неразрушающему контролю, если выполнение работ включает необходимость контроля стыковых сварных швов',
          'Аттестованная лаборатория, если необходимость контроля качества сварки указана в чертежах',
        ],
        correctAnswers: [
          'Собственная аттестованная лаборатория или аттестованная лаборатория, привлекаемая на договорной основе',
        ],
      },
      {
        code: '63704066',
        text:
          'Чем запрещается оснащать краны, в зоне работы которых находятся производственные или другие помещения?',
        answers: [
          'Грузовым электромагнитом',
          'Концевым выключателем электромеханического типа',
          'Регистратором параметров',
          'Ограничителем грузоподъемности',
        ],
        correctAnswers: ['Грузовым электромагнитом'],
      },
      {
        code: '63704067',
        text:
          'Какой документ регламентирует интервал проведения плановых ремонтов ПС?',
        answers: [
          'Протокол технического освидетельствования',
          'Паспорт ПС',
          'Ведомость дефектов',
          'Руководство (инструкция) по эксплуатации ПС',
        ],
        correctAnswers: ['Руководство (инструкция) по эксплуатации ПС'],
      },
      {
        code: '63704068',
        text:
          'Какая технология сварки допускается в процессе монтажа и ремонта ПС?',
        answers: [
          'Любая технология сварки, освоенная монтажной (ремонтной) организацией',
          'Технология сварки, аттестованная в установленном порядке',
          'Ручная электросварка',
          'Технология сварки, разработанная владельцем ПС',
          'Ручная электро- и газовая сварка',
        ],
        correctAnswers: [
          'Технология сварки, аттестованная в установленном порядке',
        ],
      },
      {
        code: '63704069',
        text:
          'Какие действия не включает в себя проверка состояния люльки (кабины)?',
        answers: [
          'Плановую проверку состояния',
          'Грузовые испытания',
          'Ежесменный осмотр',
          'Динамические испытания',
        ],
        correctAnswers: ['Динамические испытания'],
      },
      {
        code: '63704070',
        text:
          'Какие мероприятия должна выполнить эксплуатирующая организация для содержания ПС в работоспособном состоянии и обеспечения безопасных условий их работы?',
        answers: [
          'Только обеспечить наличие у инженерно-технических работников должностных инструкций и руководящих указаний по безопасной эксплуатации ПС',
          'Только установить порядок периодических осмотров, технических обслуживаний и ремонтов, обеспечивающих содержание ПС, рельсовых путей, грузозахватных органов, приспособлений и тары в работоспособном состоянии',
          'Только обеспечить наличие у персонала производственных инструкций',
          'Только разработать и утвердить журналы, программы, графики выполнения планово-предупредительных ремонтов, ППР, ТК, схемы строповки и складирования, должностные инструкции для инженерно-технических работников и производственные инструкции для персонала',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63704071',
        text:
          'Кто должен руководить погрузочно-разгрузочными работами при выполнении монтажа ПС?',
        answers: [
          'Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС',
          'Начальник участка, на котором выполняется монтаж данного ПС',
          'Производитель работ',
          'Главный механик',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС',
        ],
      },
      {
        code: '63704072',
        text: 'В каких случаях не проводятся динамические испытания ПС?',
        answers: [
          'Если ПС используется только для подъема и опускания груза (подъем затворов на гидроэлектростанции)',
          'Если паспортная грузоподъемность ПС не превышает 10 т',
          'Если ПС оборудовано не более чем двумя механизмами подъема и если предусмотрена их раздельная работа',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Если ПС используется только для подъема и опускания груза (подъем затворов на гидроэлектростанции)',
        ],
      },
      {
        code: '63704073',
        text:
          'Кем осуществляется проверка готовности к применению аттестованных технологий сварки с целью определения наличия у организации или индивидуального предпринимателя технических, организационных и квалификационных возможностей для выполнения сварочных (наплавочных) работ по применяемым им аттестованным технологиям, а также соответствия качества выполненных при аттестации контрольных сварных соединений (наплавок) требованиям НД и (или) проектной (конструкторской) документации на сварные конструкции?',
        answers: [
          'Независимыми аттестационными центрами',
          'Эксплуатирующей организацией',
          'Научно-исследовательской организацией',
          'Специализированной организацией',
        ],
        correctAnswers: ['Независимыми аттестационными центрами'],
      },
      {
        code: '63704074',
        text:
          'Что необходимо предпринять, если при ремонте регистратора параметров не представляется возможным восстановление информации долговременного хранения?',
        answers: [
          'В этом случае регистратор параметров для дальнейшего применения не допускается',
          'Эксплуатирующей организацией должна быть проведена корректировка программного обеспечения',
          'Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС',
          'Специализированной и эксплуатирующей организациями должен быть составлен и подписан соответствующий протокол',
        ],
        correctAnswers: [
          'Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС',
        ],
      },
      {
        code: '63704075',
        text:
          'Кто обязан сделать запись в паспорте ПС по завершении его ремонта, реконструкции или модернизации о проведенной работе, и предоставить копии сертификатов о примененных материалах, замененных и отремонтированных запчастях, узлах и агрегатах?',
        answers: [
          'Специалист эксплуатирующей организации, ответственный за содержание ПС в работоспособном состоянии и осуществивший его приемку',
          'Представитель Ростехнадзора, разрешающий пуск ПС в работу',
          'Специализированная организация, выполнившая указанные работы',
          'Лицо, руководившее выполнением полного технического освидетельствования',
          'Руководитель службы ОТК организации, выполнявшей работу',
        ],
        correctAnswers: [
          'Специализированная организация, выполнившая указанные работы',
        ],
      },
      {
        code: '63704076',
        text:
          'Кто в ФНП ПС определен термином "работники" специализированной организации?',
        answers: [
          'Все сотрудники организации, включая лиц рабочих профессий',
          'Аттестованные специалисты и лица рабочих профессий',
          'Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
          'Руководители, специалисты и персонал - лица рабочих профессий основных служб организации',
          'Физические лица основных служб организации, непосредственно занятые на выполнении работ',
        ],
        correctAnswers: [
          'Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
        ],
      },
      {
        code: '63704077',
        text:
          'Какие организации не осуществляют ремонт ограничителей, указателей и регистраторов ПС?',
        answers: [
          'Изготовители ПС',
          'Сервисные центры',
          'Специализированные организации',
          'Эксплуатирующие организации',
        ],
        correctAnswers: ['Эксплуатирующие организации'],
      },
      {
        code: '63704078',
        text:
          'Каким из нижеприведенных требований должны отвечать работники, непосредственно выполняющие работы по монтажу?',
        answers: [
          'Знать основные признаки отправки ПС на утилизацию (ликвидацию)',
          'Знать и уметь оценивать остаточный ресурс ПС',
          'Быть аттестованными по экспертизе промышленной безопасности',
          'Быть аттестованными на право управления монтируемого ПС',
          'Знать и уметь применять такелажные и монтажные приспособления',
        ],
        correctAnswers: [
          'Знать и уметь применять такелажные и монтажные приспособления',
        ],
      },
      {
        code: '63704079',
        text:
          'Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД',
          'Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписям сварщиков в применяемых ими технологических картах сварки',
          'Только организовать проведение операционного контроля',
          'Все перечисленные функции',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63704080',
        text:
          'Чем определяется конкретный перечень требований к специализированной организации, которая будет заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?',
        answers: [
          'Наличием необходимого оборудования',
          'Номенклатурой ПС и технологическими процессами, заявленными организацией для своей последующей деятельности',
          'Номенклатурой ПС и квалификацией работников',
          'Наличием аттестованных специалистов',
        ],
        correctAnswers: [
          'Номенклатурой ПС и технологическими процессами, заявленными организацией для своей последующей деятельности',
        ],
      },
      {
        code: '63704081',
        text:
          'Кем должен быть обустроен переезд транспортных средств через пути ПС, передвигающихся по наземному рельсовому пути?',
        answers: [
          'Эксплуатирующей организацией',
          'Строительной организацией',
          'Проектной организацией',
          'Монтажной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63704082',
        text:
          'Что включает в себя проверка состояния рельсового пути, находящегося в эксплуатации? Укажите все правильные ответы.',
        answers: [
          'Ежесменный осмотр',
          'Плановую или внеочередную проверку состояния',
          'Техническое обслуживание',
          'Периодическое комплексное обследование',
        ],
        correctAnswers: [
          'Ежесменный осмотр',
          'Плановую или внеочередную проверку состояния',
        ],
      },
      {
        code: '63704083',
        text:
          'Какие требования, установленные для специализированной организации, осуществляющей деятельность по монтажу (демонтажу), наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО, указаны верно?',
        answers: [
          'Только располагать необходимым персоналом, а также инженерно-техническими работниками, уполномоченными на выполнение своих обязанностей, в том числе выявления случаев отступлений от требований к качеству работ, от процедур проведения работ и для принятия мер по предупреждению или сокращению таких отступлений',
          'Только устанавливать ответственность, полномочия и взаимоотношения работников, занятых в управлении, выполнении или проверке выполнения работ',
          'Только определять процедуры контроля соблюдения технологических процессов',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63704084',
        text:
          'Какие документы должны быть разработаны для выполнения работ по монтажу, демонтажу, ремонту оборудования с применением ПС? Укажите все правильные ответы.',
        answers: [
          'Проект производства работ (ППР)',
          'Технологическая карта (ТК)',
          'Технологические инструкции',
          'Технические условия (ТУ)',
        ],
        correctAnswers: [
          'Проект производства работ (ППР)',
          'Технологическая карта (ТК)',
        ],
      },
      {
        code: '63704085',
        text:
          'Как должна распределяться нагрузка на каждое из ПС, если подъем и перемещение груза осуществляют двумя ПС?',
        answers: [
          'Нагрузка, приходящаяся на каждое из ПС, не должна превышать 0,75% грузоподъемности ПС, имеющего меньшую грузоподъемность',
          'Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС',
          'Нагрузка, приходящаяся на каждое из ПС, должна быть по возможности одинаковой',
          'Нагрузка, приходящаяся на каждое из ПС, должна контролироваться взвешивающими устройствами ПС',
          'Нагрузка, приходящаяся на каждое из ПС, должна быть выровнена несимметричной строповкой груза и быть по возможности одинаковой',
        ],
        correctAnswers: [
          'Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС',
        ],
      },
      {
        code: '63704086',
        text:
          'Кто дает разрешение на пуск в работу ПС по окончании ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?',
        answers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
          'Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Специалист, ответственный за безопасное производство работ с применением ПС',
          'Руководитель ОПО или его заместитель',
          'Руководитель специализированной организации, выполнившей ремонт',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
        ],
      },
      {
        code: '63704087',
        text:
          'Кем определяется набор инструментов и приборов, необходимых для ремонта, реконструкции или модернизации ограничителей, указателей, регистраторов параметров ПС?',
        answers: [
          'Ответственным за содержание ПС в работоспособном состоянии с учетом указаний технологической документации на ремонт (реконструкцию)',
          'Работниками, выполняющими работы по ремонту (реконструкции) с учетом указаний типовых рекомендаций по выбору инструментов и приборов, необходимых для ремонта (реконструкции)',
          'Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов',
          'Ответственным за осуществление производственного контроля за безопасной эксплуатацией ПС с учетом указаний инструкций по эксплуатации ПС',
        ],
        correctAnswers: [
          'Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов',
        ],
      },
      {
        code: '63704088',
        text:
          'В каких из перечисленных случаев эксплуатирующая организация имеет право допустить ПС в работу?',
        answers: [
          'Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования',
          'На ПС выявлены трещины или остаточные деформации металлоконструкций',
          'Обслуживание ПС ведется неаттестованным персоналом',
          'На ПС выявлен недопустимый износ крюков, ходовых колес, канатов, цепей, элементов механизмов и тормозов',
          'Отсутствуют соответствующие массе и виду перемещаемых грузов съемные грузозахватные приспособления и тара',
        ],
        correctAnswers: [
          'Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования',
        ],
      },
      {
        code: '63704089',
        text:
          'Что указывается на ремонтных чертежах элементов металлоконструкции ПС?',
        answers: [
          'Только поврежденные участки, подлежащие ремонту или замене',
          'Только типы сварных соединений и способы их выполнения',
          'Только допускаемые отклонения от номинальных размеров',
          'Только деформированные элементы и участки элементов, подлежащие исправлению правкой',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63704090',
        text:
          'Что служит основанием для решения о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?',
        answers: [
          'Предложение комиссии о возможности пуска',
          'Заключение завода-изготовителя о возможности пуска',
          'Положительное заключение экспертизы промышленной безопасности',
          'Предписание территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Предложение комиссии о возможности пуска'],
      },
      {
        code: '63704091',
        text:
          'Что должно быть указано в акте о приемке противовеса и балласта, если они изготовлены эксплуатирующей организацией?',
        answers: [
          'Габариты плит',
          'Соответствие плит требованиям паспорта ПС',
          'Соответствие плит требованиям руководства (инструкции) по эксплуатации ПС',
          'Данные о материале, из которого изготовлены плиты',
          'Фактическая масса плит',
        ],
        correctAnswers: ['Фактическая масса плит'],
      },
      {
        code: '63704092',
        text:
          'Какие из нижеперечисленных ПС не подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны стрелового типа, оборудованные механизмом поворота',
          'Краны мостового типа грузоподъемностью более 10 т',
          'Краны на железнодорожном ходу',
          'Краны-трубоукладчики',
        ],
        correctAnswers: ['Краны-трубоукладчики'],
      },
      {
        code: '63704093',
        text:
          'В каких случаях при выполнении ремонта должна выполняться замена отдельных элементов ПС, если на них не обнаружено видимых повреждений?',
        answers: [
          'Если их сложно продиагностировать методами неразрушающего контроля',
          'Если в руководстве (инструкции) по эксплуатации ПС указано, что при достижении определенной наработки должна выполняться их замена',
          'Если сборочную единицу ПС разобрать невозможно',
          'Если сборочная единица подверглась термоциклическому нагружению от воздействия либо низких, либо высоких температур, превышающих указанные в паспорте ПС',
          'Если установлено, что сборочная единица эксплуатировалась без необходимой смазки',
        ],
        correctAnswers: [
          'Если в руководстве (инструкции) по эксплуатации ПС указано, что при достижении определенной наработки должна выполняться их замена',
        ],
      },
      {
        code: '63704094',
        text:
          'Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.',
        answers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Федерального закона "О промышленной безопасности опасных производственных объектов" от 21 июля 1997 г. N 116-ФЗ',
          'Технического регламента ТР ТС 018/2011 "О безопасности колесных транспортных средств"',
        ],
        correctAnswers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
      },
      {
        code: '63704095',
        text:
          'С какой периодичностью должно осуществляться проведение плановых ремонтов ПС? Укажите все правильные ответы.',
        answers: [
          'После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС',
          'Через установленный инструкцией по эксплуатации ПС интервал времени',
          'Не регламентируется',
          'После наработки определенного числа машино-часов (циклов) согласно паспорту ПС',
        ],
        correctAnswers: [
          'После наработки определенного числа машино-часов (циклов) согласно руководству по эксплуатации ПС',
          'Через установленный инструкцией по эксплуатации ПС интервал времени',
        ],
      },
      {
        code: '63704096',
        text:
          'За сколько дней до начала работы комиссии по пуску ПС в работу эксплуатирующая организация должна письменно уведомить организации, представители которых включены в состав комиссии, о дате работы комиссии?',
        answers: [
          'Не менее чем за 10 дней',
          'Не менее чем за 3 дня',
          'Не менее чем за 7 дней',
          'Не менее чем за 5 дней',
        ],
        correctAnswers: ['Не менее чем за 10 дней'],
      },
      {
        code: '63704097',
        text:
          'Какие действия до начала работы необходимо выполнить с такелажной оснасткой и вспомогательными механизмами, используемыми при выполнении монтажа ПС?',
        answers: [
          'Проверить их соответствие эксплуатационным документам',
          'Провести статические и динамические испытания',
          'Убедиться в наличии разрешения на применение',
        ],
        correctAnswers: [
          'Проверить их соответствие эксплуатационным документам',
        ],
      },
      {
        code: '63704098',
        text:
          'В соответствии с требованиями каких документов должна проводиться установка ПС в зданиях, на открытых площадках и других участках производства работ? Выберите 2 варианта ответа.',
        answers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Проекта',
          'Должностной инструкции',
        ],
        correctAnswers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63704099',
        text:
          'В каких случаях для контроля качества сварных швов допустимо применение капиллярного неразрушающего контроля?',
        answers: [
          'Применение капиллярного контроля устанавливается в ТУ на ремонт, реконструкцию или модернизацию ПС для любых типов сварных швов',
          'Применение капиллярного контроля при ремонте, реконструкции или модернизации ПС для контроля качества сварных швов запрещается',
          'Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в ТУ на ремонт, реконструкцию или модернизацию ПС',
          'Применение капиллярного контроля сварных швов (кроме стыковых) возможно, если другие методы неразрушающего контроля применить невозможно или нецелесообразно',
          'Применение капиллярного контроля сварных швов предпочтительно, если неразрушающий контроль необходимо выполнить при отрицательных температурах окружающего воздуха',
        ],
        correctAnswers: [
          'Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в ТУ на ремонт, реконструкцию или модернизацию ПС',
        ],
      },
      {
        code: '63704100',
        text:
          'Какой документ подтверждает качество ремонта рельсового пути (для ПС, передвигающихся по рельсам)?',
        answers: [
          'Акт сдачи-приемки рельсового пути',
          'Технический отчет',
          'Экспертное заключение',
        ],
        correctAnswers: ['Акт сдачи-приемки рельсового пути'],
      },
      {
        code: '63704101',
        text:
          'С какой периодичностью производятся частичная разборка, осмотр и ревизия элементов, узлов и соединений грузозахватных приспособлений (клещи, траверсы, захваты) для контроля технического состояния, которое невозможно определить в собранном виде?',
        answers: ['Ежегодно', 'Ежемесячно', 'Ежеквартально', 'Еженедельно'],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63704102',
        text:
          'В каком документе должно быть определено распределение ответственности работников организации, осуществляющей монтаж, наладку, ремонт, реконструкцию или модернизацию ПС в процессе эксплуатации ОПО?',
        answers: [
          'В положении о контроле соблюдения технологических процессов специализированной организации',
          'В положении о производственном контроле',
          'В руководстве (инструкции) по эксплуатации ПС',
          'В проектной документации',
        ],
        correctAnswers: [
          'В положении о контроле соблюдения технологических процессов специализированной организации',
        ],
      },
      {
        code: '63704103',
        text:
          'На кого возлагаются организация и проведение испытаний ПС по завершении выполненных работ по монтажу ПС?',
        answers: [
          'На субподрядную организацию, имеющую в наличии тарированные грузы для проведения контрольных грузовых испытаний',
          'На эксплуатирующую организацию',
          'Не регламентируется',
          'На специализированную организацию, осуществившую монтаж ПС',
        ],
        correctAnswers: [
          'На специализированную организацию, осуществившую монтаж ПС',
        ],
      },
      {
        code: '63704104',
        text:
          'При каких величинах суммарной массы тары с перемещаемым грузом допускается применение башенных кранов с тарой, разгружаемой на весу, в пределах группы классификации (режима), указанного в паспорте крана, при числе циклов работы крана не более 8 в час?',
        answers: [
          'Только для одноканатных грейферов, не допускающих разгрузку на весу, - не более 50% грузоподъемности крана',
          'Только для тары без вибраторов (исключая грейферы) - в пределах грузоподъемности крана',
          'Только для тары с вибратором - не более 50% от максимальной грузоподъемности крана',
          'Применение допускается во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Применение допускается во всех перечисленных случаях',
        ],
      },
      {
        code: '63704105',
        text:
          'Какие из перечисленных видов контроля не осуществляются при подготовке и выполнении сварочных работ?',
        answers: [
          'Входной контроль',
          'Операционный контроль',
          'Приемочный контроль',
          'Эксплуатационный контроль',
        ],
        correctAnswers: ['Эксплуатационный контроль'],
      },
      {
        code: '63704106',
        text:
          'Каким образом необходимо исключать в процессе работы специализированной организации использование материалов и изделий, на которые отсутствуют сертификаты, паспорта и другие документы, подтверждающие их качество?',
        answers: [
          'Наличием квалификации специалистов и персонала',
          'Принятой системой обучения и аттестации работников',
          'Применением неразрушающего контроля',
          'Технологической подготовкой производства и установленным производственным процессом',
        ],
        correctAnswers: [
          'Технологической подготовкой производства и установленным производственным процессом',
        ],
      },
      {
        code: '63704107',
        text:
          'Какие требования к передвижению стрелового самоходного крана указаны неверно?',
        answers: [
          'Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол не более указанного в руководстве (инструкции) по эксплуатации крана',
          'Стреловым самоходным кранам разрешается перемещаться с грузом на крюке',
          'Основание, по которому перемещается кран с грузом, должно иметь твердое покрытие, способное выдержать без просадки удельное давление не менее величин, указанных в паспорте или руководстве (инструкции) по эксплуатации крана',
          'Основание, по которому перемещается кран с грузом, должно быть ровным и иметь уклон не более указанного в руководстве (инструкции) по эксплуатации крана',
        ],
        correctAnswers: [
          'Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол не более указанного в руководстве (инструкции) по эксплуатации крана',
        ],
      },
      {
        code: '63704108',
        text:
          'На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?',
        answers: [
          'Не более 150 мм',
          'Не более 250 мм',
          'Не более 50 мм',
          'Не более 200 мм',
        ],
        correctAnswers: ['Не более 150 мм'],
      },
      {
        code: '63704109',
        text:
          'Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?',
        answers: [
          'Формуляра ПС',
          'Технических условий ПС',
          'Паспорта ПС',
          'Руководства (инструкции) по эксплуатации ПС',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации ПС'],
      },
      {
        code: '63704110',
        text:
          'Какое количество тупиковых упоров, ограничивающих рабочую зону, обслуживаемую ПС, должно быть установлено на каждой рельсовой нити рельсового пути?',
        answers: [
          'По два тупиковых упора',
          'По одному тупиковому упору',
          'По три тупиковых упора',
          'Не регламентируется',
        ],
        correctAnswers: ['По два тупиковых упора'],
      },
      {
        code: '63704111',
        text:
          'Какие работы должны быть проведены после завершения монтажа и наладки системы дистанционного управления (радиоуправления) ПС?',
        answers: [
          'Полное техническое освидетельствование ПС, управляемого системой дистанционного управления (радиоуправления)',
          'Частичное техническое освидетельствование ПС, управляемого системой дистанционного управления (радиоуправления)',
          'Проверка возможности управления ПС с использованием прежней системы управления ПС, если она не демонтирована',
          'Проверка возможности управления ПС с использованием прежней системы управления ПС, если она не демонтирована, и установленной системой дистанционного управления (радиоуправления) ПС',
          'Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС',
        ],
        correctAnswers: [
          'Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС',
        ],
      },
      {
        code: '63704112',
        text:
          'Что не указывается на табличках, которыми должны быть снабжены находящиеся в эксплуатации ПС?',
        answers: [
          'Только учетный номер ПС',
          'Только заводской номер ПС',
          'Только дата следующего полного технического освидетельствования',
          'Только паспортная грузоподъемность',
          'Только дата следующего частичного технического освидетельствования',
          'Указывается все перечисленное',
        ],
        correctAnswers: ['Указывается все перечисленное'],
      },
      {
        code: '63704113',
        text:
          'Что из перечисленного не включает итоговая документация по результатам выполненных ремонтных работ?',
        answers: [
          'Ремонтные рабочие чертежи',
          'Технологические карты',
          'Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций',
        ],
        correctAnswers: [
          'Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций',
        ],
      },
      {
        code: '63704114',
        text:
          'Кто должен выполнять работы на регистраторах, ограничителях и указателях ПС?',
        answers: [
          'Работники, допущенные учебным центром по регистраторам, ограничителям и указателям на основании проверки знаний',
          'Только работники эксплуатирующей ПС организации, прошедшие соответствующую аттестацию и проверку знаний по промышленной безопасности',
          'Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей',
          'Работники специализированных организаций, имеющих допуск на производство этих работ, выданный изготовителями регистраторов, ограничителей и указателей',
        ],
        correctAnswers: [
          'Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей',
        ],
      },
      {
        code: '63704115',
        text:
          'Согласно требованиям какого документа должна выполняться оценка работоспособности ограничителя или указателя опасного приближения к линии электропередачи при эксплуатации ПС?',
        answers: [
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Эксплуатационной документации',
          'Паспорта ПС',
          'Заключения экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63704116',
        text:
          'Какими нормативными документами необходимо руководствоваться при выборе оборудования для безопасного выполнения работ по ремонту, реконструкции или модернизации ПС?',
        answers: [
          'Общими техническими условиями',
          'Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС',
          'Методическими рекомендациями по организации и выполнению работ',
          'Инструкцией по надзору за изготовлением, ремонтом и монтажом подъемных сооружений',
        ],
        correctAnswers: [
          'Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС',
        ],
      },
      {
        code: '63704117',
        text:
          'Как необходимо ограждать зону площадки для выполнения работ по монтажу ПС?',
        answers: [
          'Если доступ работников и третьих лиц на монтажную площадку исключен, ее можно не ограждать',
          'Перед входом в зону должна быть табличка с надписью "Проход запрещен!"',
          'По периметру, а на ограждениях вывешивать знаки безопасности и таблички',
          'Специальными ограждениями, исключающими свободный проход',
        ],
        correctAnswers: [
          'По периметру, а на ограждениях вывешивать знаки безопасности и таблички',
        ],
      },
      {
        code: '63704118',
        text:
          'Кто из работников специализированной организации должен быть аттестован в установленном порядке на знание требований настоящих ФНП ПС, касающихся заявленных видов работ на ПС?',
        answers: [
          'Все работники',
          'Инженерно-технические работники',
          'Крановщик',
          'Сигнальщик',
        ],
        correctAnswers: ['Инженерно-технические работники'],
      },
      {
        code: '63704119',
        text:
          'Что из нижеперечисленного не содержит в себе проект ремонта, реконструкции или модернизации ПС с применением сварки?',
        answers: [
          'Проектно-сметная документацию на ремонтные работы',
          'Указания о применяемых металлах и сварочных материалах',
          'Способы контроля качества сварки',
          'Порядок приемки из ремонта отдельных узлов и готовых изделий',
          'Нормы браковки сварных соединений',
        ],
        correctAnswers: ['Проектно-сметная документацию на ремонтные работы'],
      },
      {
        code: '63704120',
        text:
          'Какие требования к статическим испытаниям грузозахватного приспособления указаны верно? Выберите все правильные варианты ответа.',
        answers: [
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями следует поднимать ПС на высоту 150 - 250 мм',
          'Испытательный груз, поднятый на высоту 200 мм, следует выдерживать в таком положении не менее 5 минут',
          'Испытания следует проводить статической нагрузкой, превышающей его грузоподъемность на 50%',
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимается на высоту 50 - 100 мм',
          'Статические испытания грузозахватного приспособления проводят статической нагрузкой, превышающей его грузоподъемность на 25%',
        ],
        correctAnswers: [
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимается на высоту 50 - 100 мм',
          'Статические испытания грузозахватного приспособления проводят статической нагрузкой, превышающей его грузоподъемность на 25%',
        ],
      },
      {
        code: '63704121',
        text:
          'Что из перечисленного должно контролироваться в процессе сварки, как минимум?',
        answers: [
          'Только соответствие параметров режима сварки и технологических приемов выполнения сварного соединения',
          'Только очередность выполнения сварных швов и участков наплавки',
          'Только отсутствие видимых дефектов',
          'Только контроль параметров, предусмотренных в технологических (операционных) картах сварки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63704122',
        text:
          'При каком условии допускается правка стального проката местным нагревом?',
        answers: [
          'По технологии, разработанной специализированной организацией',
          'При наличии разрешения Ростехнадзора на проведение данной операции',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'По технологии, разработанной специализированной организацией',
        ],
      },
      {
        code: '63704123',
        text:
          'Где указывается суммарная длина контролируемых участков сварных соединений?',
        answers: [
          'В проекте ремонта, реконструкции или модернизации ПС',
          'В технологической карте на реконструкцию',
          'В стандарте организации, разработанном специализированной организацией',
          'В паспорте ПС',
        ],
        correctAnswers: [
          'В проекте ремонта, реконструкции или модернизации ПС',
        ],
      },
      {
        code: '63704124',
        text:
          'В соответствии с какими документами должен проводиться контроль стыковых сварных соединений радиографическим или ультразвуковым методом?',
        answers: [
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Методы ультразвуковые"',
          'В соответствии с указаниями проектно-технологической документации эксплуатирующей организации',
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Радиографический метод"',
          'В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией',
        ],
        correctAnswers: [
          'В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией',
        ],
      },
      {
        code: '63704125',
        text:
          'Кто может осуществлять монтаж и наладку регистраторов, ограничителей и указателей?',
        answers: [
          'Только разработчик приборов',
          'Только изготовитель приборов',
          'Только изготовитель ПС',
          'Только специализированная организация',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63704126',
        text:
          'В каких случаях рельсовые пути ПС, передвигающихся по рельсам, должны подвергаться ремонту?',
        answers: [
          'После проведения плановых и внеочередных технических освидетельствований',
          'При необходимости',
          'После проведения комплексных обследований',
          'После установки на них дополнительных ПС',
        ],
        correctAnswers: ['При необходимости'],
      },
      {
        code: '63704127',
        text:
          'В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?',
        answers: [
          'Производителя сварочного оборудования',
          'Инспектора Ростехнадзора',
          'Руководителя независимого аттестационного центра',
          'Руководителя организации',
        ],
        correctAnswers: ['Производителя сварочного оборудования'],
      },
      {
        code: '63704128',
        text:
          'Кто может заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?',
        answers: [
          'Физические лица и индивидуальные предприниматели',
          'Специализированные организации',
          'Юридические и физические лица',
          'Индивидуальные предприниматели, физические лица, юридические лица',
        ],
        correctAnswers: ['Специализированные организации'],
      },
      {
        code: '63704129',
        text:
          'Какой объем ремонтных сварных соединений элементов металлоконструкций из высокопрочных сталей подвергается ультразвуковому и магнитопорошковому контролю?',
        answers: [
          '55% сварных соединений',
          '75% сварных соединений',
          '60% сварных соединений',
          '100% сварных соединений',
        ],
        correctAnswers: ['100% сварных соединений'],
      },
      {
        code: '63704130',
        text:
          'С какого места должно выполняться управление ПС в период монтажа?',
        answers: [
          'С места, указанного в проекте производства работ',
          'С выносного пульта',
          'Из кабины',
          'По радио',
          'С места, указанного в эксплуатационной документации',
        ],
        correctAnswers: ['С места, указанного в эксплуатационной документации'],
      },
      {
        code: '63704131',
        text:
          'Каким документом определено распределение ответственности работников специализированной организации?',
        answers: [
          'Положением о промышленной безопасности организации',
          'Положением о контроле соблюдения технологических процессов',
          'Положением о действиях в аварийных ситуациях',
          'Положением об отделе технического контроля организации',
          'Положением о требованиях к работникам организации',
        ],
        correctAnswers: [
          'Положением о контроле соблюдения технологических процессов',
        ],
      },
      {
        code: '63704132',
        text:
          'Какие завалы допускаются на торцах деталей из профильного металлопроката?',
        answers: [
          'Не более 2 мм',
          'Не более 1 мм',
          'Не более 3 мм',
          'Не регламентированы',
        ],
        correctAnswers: ['Не более 1 мм'],
      },
      {
        code: '63704133',
        text: 'Что должно проводиться после реконструкции ПС?',
        answers: [
          'Внеочередное частичное техническое освидетельствование',
          'Внеочередное полное техническое освидетельствование',
          'Периодическое техническое освидетельствование',
          'Периодическое частичное техническое освидетельствование',
        ],
        correctAnswers: ['Внеочередное полное техническое освидетельствование'],
      },
      {
        code: '63704134',
        text:
          'Какое из перечисленных требований к люку (проему) для подъема и опускания грузов, находящегося в перекрытии производственного помещения, указано неверно?',
        answers: [
          'Должен иметь постоянное ограждение высотой не менее 1,1 м',
          'Люк в перекрытии должен иметь сплошное ограждение понизу на высоту 0,15 м',
          'Люк в перекрытии должен иметь световую сигнализацию',
          'Должен иметь надписи габаритов проема',
        ],
        correctAnswers: ['Должен иметь надписи габаритов проема'],
      },
      {
        code: '63704135',
        text:
          'Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только соответствие численного состава и квалификации персонала сварочного производства',
          'Только соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям ПТД',
          'Только соответствие основных и сварочных материалов, применяемой технологии сварки требованиям ПТД',
          'Выполнение всех подготовительных мероприятий',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63704136',
        text:
          'На какие из нижеперечисленных ОПО не распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения (далее - ФНП ПС)?',
        answers: [
          'На ОПО, где эксплуатируются грузоподъемные краны',
          'На ОПО, где эксплуатируются строительные подъемники',
          'На ОПО, где эксплуатируются канатные дороги',
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
          'На ОПО, где эксплуатируются подъемники (вышки)',
        ],
        correctAnswers: ['На ОПО, где эксплуатируются канатные дороги'],
      },
      {
        code: '63704137',
        text:
          'Какие краны, не оборудованные координатной защитой, запрещается применять для работы в стесненных условиях?',
        answers: [
          'Только башенные краны, установленные на автомобильные шасси',
          'Только грузоподъемные краны на пневмоколесном ходу',
          'Только грузоподъемные краны на гусеничном ходу',
          'Только грузоподъемные краны, установленные на автомобильные шасси',
          'Все перечисленные краны',
        ],
        correctAnswers: ['Все перечисленные краны'],
      },
      {
        code: '63704138',
        text:
          'Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?',
        answers: [
          'Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласования с организацией, в ведении которой находится организация движения на железнодорожных путях',
          'Допускается, только при согласовании с территориальным органом Ростехнадзора',
          'Допускается, только после выполнения мероприятий по безопасному ведению работ на рельсовых путях',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласования с организацией, в ведении которой находится организация движения на железнодорожных путях',
        ],
      },
      {
        code: '63704139',
        text:
          'Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?',
        answers: [
          'Только идентификацию применяемых сварочных материалов и сварочного оборудования',
          'Только регистрацию сведений о сварщиках, выполняющих сварные соединения',
          'Только регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений',
          'Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений',
          'Обеспечить все перечисленное',
        ],
        correctAnswers: ['Обеспечить все перечисленное'],
      },
      {
        code: '63704140',
        text:
          'Какие требования предъявляются федеральными нормами и правилами в области промышленной безопасности "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
          'Оборудование и материалы должны обеспечивать максимальную производительность работ',
          'Оборудование и материалы должны быть экономичными в использовании и простыми в ремонте',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63704141',
        text:
          'В какой документ вносится запись о результатах осмотра съемных грузозахватных приспособлений и тары?',
        answers: [
          'Журнал осмотра грузозахватных приспособлений',
          'Во все перечисленные документы',
          'Паспорт ПС',
          'Руководство по эксплуатации грузозахватных приспособлений и тары',
        ],
        correctAnswers: ['Журнал осмотра грузозахватных приспособлений'],
      },
      {
        code: '63704142',
        text:
          'Какая организация обеспечивает наличие комплекта испытательных (контрольных) грузов с указанием их фактической массы для проведения статических и динамических испытаний ПС на территории специализированной организации, осуществляющей ремонт или реконструкцию?',
        answers: [
          'Данная специализированная организация',
          'Изготовитель ПС',
          'Эксплуатирующая организация',
          'Экспертная организация',
        ],
        correctAnswers: ['Данная специализированная организация'],
      },
      {
        code: '63704143',
        text:
          'Кто определяет состав необходимого набора инструментов и приборов, необходимых для монтажа ограничителей, указателей и регистраторов параметров ПС?',
        answers: [
          'Работники специализированной экспертной организации',
          'Работники, выполняющие их монтаж',
          'Руководство монтажной организации в соответствии с требованиями, изложенными в эксплуатационной документации',
          'Руководство монтажной организации в соответствии с назначением соответствующего ограничителя, указателя или регистратора параметров',
          'Руководство монтажной организации',
        ],
        correctAnswers: ['Работники, выполняющие их монтаж'],
      },
      {
        code: '63704144',
        text:
          'В каких случаях разрешены подъем и транспортировка людей с применением ПС, в паспорте которых отсутствует разрешение на транспортировку людей?',
        answers: [
          'Только при перемещении персонала для крепления и раскрепления контейнеров и грузов',
          'Только при проведении диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно',
          'Только при монтаже, строительстве и возведении уникальных объектов, когда иные способы доставки рабочих в зону выполнения работ не могут быть применены',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63704145',
        text:
          'В каком случае проводятся испытания на устойчивость при повторных технических освидетельствованиях для всех кранов стрелового типа и подъемников (вышек), у которых люлька закреплена на оголовке стрелы?',
        answers: [
          'Если в паспорте отсутствуют ссылки на результаты ранее проведенных испытаний',
          'Если это указано в их руководстве (инструкции) по эксплуатации',
          'Если конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов',
          'Если люлька была подвергнута ремонту с применением сварки либо изменению первоначальных геометрических размеров',
        ],
        correctAnswers: [
          'Если это указано в их руководстве (инструкции) по эксплуатации',
        ],
      },
      {
        code: '63704146',
        text:
          'Какие из указанных действий допускается предпринимать во время проведения монтажных работ ПС, если на монтажной площадке имеются действующие переходы (проезды) и выходы из прилегающих зданий? Укажите все правильные ответы.',
        answers: [
          'Закрыть переходы (проезды) и выходы из прилегающих зданий',
          'Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)',
          'Перемещать груз при нахождении под ним людей',
          'Установить дополнительные блокировки на подъемные сооружения, исключающие его перемещение при нахождении на действующих переходах людей',
        ],
        correctAnswers: [
          'Закрыть переходы (проезды) и выходы из прилегающих зданий',
          'Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)',
        ],
      },
      {
        code: '63704147',
        text:
          'Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?',
        answers: [
          'Проверку знания теоретических основ сварки',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
          'Проверку путем выполнения и контроля допускного сварного соединения',
        ],
        correctAnswers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
        ],
      },
      {
        code: '63704148',
        text:
          'Кто имеет право разработать проект на монтаж регистратора, ограничителя или указателя ПС при отсутствии необходимых указаний в эксплуатационных документах?',
        answers: [
          'Разработчик или изготовитель ПС, разработчик или изготовитель регистраторов, ограничителей и указателей ПС',
          'Специализированная организация, имеющая аттестованных специалистов для выполнения указанных работ',
          'Разработчик или изготовитель регистраторов, ограничителей и указателей ПС, либо специализированная организация',
          'Авторизованный сервисный центр, имеющий аттестованных специалистов для выполнения указанных работ',
        ],
        correctAnswers: [
          'Разработчик или изготовитель регистраторов, ограничителей и указателей ПС, либо специализированная организация',
        ],
      },
    ],
    63702: [
      {
        code: '63702000',
        text:
          'В каком положении выполняют статические испытания подъемников (кроме строительных)?',
        answers: [
          'В положении, отвечающем его наименьшей расчетной устойчивости',
          'В положении, отвечающем его наибольшей расчетной устойчивости',
          'В положении продольной оси стрелы вдоль продольной оси подъемника',
          'В положении продольной оси стрелы, составляющей с продольной осью подъемника угол в 45°',
          'В положении, приведенном в руководстве (инструкции) по эксплуатации',
        ],
        correctAnswers: [
          'В положении, отвечающем его наименьшей расчетной устойчивости',
        ],
      },
      {
        code: '63702001',
        text:
          'Какова должна быть длина фала страховочного устройства человека, соединяющего его пояс с местом крепления в подвесной люльке при ее транспортировке краном?',
        answers: [
          'Не более 1,5 м',
          'Не более 2 м',
          'Длина фала страховочного устройства должна быть такой, чтобы человек в случае аварии люльки мог беспрепятственно выбраться наружу',
          'Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки',
          'Длина фала определяется требованиями руководства по эксплуатации (инструкции) люльки',
        ],
        correctAnswers: [
          'Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки',
        ],
      },
      {
        code: '63702002',
        text:
          'В каких случаях разрешается нахождение инструментов и материалов совместно с людьми в подвесных люльках, транспортируемых кранами?',
        answers: [
          'Во всех случаях, если инструменты и материалы надежно закреплены',
          'Ни в каком случае',
          'В случае транспортировки людей для проведения диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно',
          'В случае если скорость ветра не превышает 10 м/с',
          'В случае использования люльки (кабины) при работах, предусматривающих возможность ее контакта с обслуживаемой поверхностью оборудования (сооружения), при условии ее оснащения устройствами для снижения динамических нагрузок при контакте с обслуживаемой поверхностью',
        ],
        correctAnswers: [
          'Во всех случаях, если инструменты и материалы надежно закреплены',
        ],
      },
      {
        code: '63702003',
        text:
          'Куда следует заносить результаты плановых осмотров люльки, предназначенной для транспортировки людей кранами?',
        answers: [
          'В паспорт люльки',
          'В паспорт крана',
          'В журнал осмотра люльки',
          'В вахтенный журнал',
        ],
        correctAnswers: ['В журнал осмотра люльки'],
      },
      {
        code: '63702004',
        text:
          'Какой документ подтверждает соответствие ПС требованиям технических регламентов?',
        answers: [
          'Паспорт ПС',
          'Руководство (инструкция) по эксплуатации ПС',
          'Сертификат или декларация соответствия',
          'Акт выполнения монтажных работ',
        ],
        correctAnswers: ['Сертификат или декларация соответствия'],
      },
      {
        code: '63702005',
        text:
          'Какие обязанности эксплуатирующей ПС организации указаны неверно?',
        answers: [
          'Устанавливать порядок контроля обучения и периодической проверки знаний персонала, работающего с ограничителями, указателями и регистраторами',
          'Обеспечивать соблюдение технологического процесса транспортировки грузов и приостановку работы ПС в случае возникновения угрозы аварийной ситуации',
          'При выявлении нарушений требований к эксплуатации ПС, изложенных в ФНП "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения", принимать меры по их устранению и предупреждению, в том числе проводить внеочередную проверку знаний работников, допустивших такие нарушения',
          'При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год',
        ],
        correctAnswers: [
          'При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год',
        ],
      },
      {
        code: '63702006',
        text:
          'Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?',
        answers: [
          'Руководства (инструкции) по эксплуатации',
          'Паспорта ПС',
          'Заключения экспертизы промышленной безопасности',
          'Вахтенного журнала ПС',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации'],
      },
      {
        code: '63702007',
        text:
          'Каким образом должна поддерживаться связь между персоналом в люльке и крановщиком (оператором) при подъеме люльки на высоту более 22 метров?',
        answers: [
          'Способом передачи предупреждающих знаков голосом',
          'Знаковой сигнализацией',
          'Радио- или телефонной связью',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Радио- или телефонной связью'],
      },
      {
        code: '63702008',
        text:
          'В каких случаях между крановщиком и людьми, транспортируемыми в подвесной люльке краном, должна быть установлена постоянная телефонная или радиосвязь?',
        answers: [
          'Во всех случаях',
          'Только если крановщик видит люльку с людьми во время всей операции транспортировки, но ему недоступна для обзора зона начала подъема или зона опускания люльки',
          'Только если это дополнительно указано в ППР с перемещением люльки',
          'Только если транспортировка людей в люльке осуществляется при неблагоприятных погодных условиях',
        ],
        correctAnswers: ['Во всех случаях'],
      },
      {
        code: '63702009',
        text:
          'На какие из перечисленных ОПО не распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения (далее - ПС)?',
        answers: [
          'На ОПО, где эксплуатируются мостовые краны-штабелеры',
          'На ОПО, где эксплуатируются краны-манипуляторы',
          'На ОПО, где эксплуатируются канатные дороги',
          'На ОПО, где эксплуатируются подъемники (вышки), предназначенные для перемещения людей, людей и груза (подъемники с рабочими платформами)',
          'На ОПО, где эксплуатируются электрические тали',
        ],
        correctAnswers: ['На ОПО, где эксплуатируются канатные дороги'],
      },
      {
        code: '63702010',
        text:
          'В каких организациях, эксплуатирующих подъемники (вышки), должны быть разработаны и доведены под подпись до каждого работника инструкции, определяющие действия работников в аварийных ситуациях?',
        answers: [
          'Только в организациях, эксплуатирующих подъемники (вышки) в охранной зоне воздушных линий электропередачи',
          'Только в организациях, эксплуатирующих ПС, подлежащие регистрации в Ростехнадзоре',
          'Только в организациях, эксплуатирующих подъемники (вышки) в стесненных условиях',
          'Во всех организациях, эксплуатирующих подъемники (вышки)',
          'Только в организациях, эксплуатирующих ОПО, зарегистрированные в государственном реестре',
        ],
        correctAnswers: [
          'Во всех организациях, эксплуатирующих подъемники (вышки)',
        ],
      },
      {
        code: '63702011',
        text:
          'Требованиям какого документа должны соответствовать общие требования к утилизации (ликвидации) ПС? Укажите все правильные ответы.',
        answers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Руководства (инструкции) по эксплуатации ПС',
          'Паспорта ПС',
          'Проекта производства работ (ППР)',
        ],
        correctAnswers: [
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Руководства (инструкции) по эксплуатации ПС',
        ],
      },
      {
        code: '63702012',
        text:
          'Какие из перечисленных ПС не подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны стрелового типа грузоподъемностью более 1 т',
          'Краны мостового типа грузоподъемностью более 10 т',
          'Краны на железнодорожном ходу',
          'Краны-трубоукладчики',
        ],
        correctAnswers: ['Краны-трубоукладчики'],
      },
      {
        code: '63702013',
        text:
          'Согласно требованиям какого документа следует выполнять браковку стальных канатов в эксплуатации?',
        answers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Паспорта ПС',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Сертификата предприятия-изготовителя каната',
        ],
        correctAnswers: [
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63702014',
        text:
          'В каких случаях должна поддерживаться радио- или телефонная связь между оператором подъемника и персоналом в люльке?',
        answers: [
          'При подъеме люльки на высоту более 22 метров',
          'При работе подъемника в стесненных условиях',
          'При работе подъемника в темное время суток',
          'При работе подъемника вблизи воздушной линии электропередачи',
        ],
        correctAnswers: ['При подъеме люльки на высоту более 22 метров'],
      },
      {
        code: '63702015',
        text:
          'Каким проверкам должны подвергаться ПС при полном техническом освидетельствовании?',
        answers: [
          'Только осмотру',
          'Только статическим испытаниям',
          'Только динамическим испытаниям',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63702016',
        text:
          'Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?',
        answers: [
          'Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях',
          'Допускается, только после согласования с территориальным органом Ростехнадзора',
          'Не допускается',
          'Допускается, только после выполнения внепланового технического обслуживания ПС',
        ],
        correctAnswers: [
          'Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласованию с организацией, в ведении которой находится организация движения на железнодорожных путях',
        ],
      },
      {
        code: '63702017',
        text:
          'Каким грузом следует проверять действие ловителей на строительных подъемниках?',
        answers: [
          'Масса которого на 10% превышает паспортную грузоподъемность',
          'Масса которого на 5% превышает паспортную грузоподъемность',
          'Масса которого на 20% превышает паспортную грузоподъемность',
          'Номинальным грузом без перегрузки',
        ],
        correctAnswers: [
          'Масса которого на 10% превышает паспортную грузоподъемность',
        ],
      },
      {
        code: '63702018',
        text:
          'Для каких типов подъемников при проведении статических испытаний часть испытательного груза подвешивают к люльке на гибкой подвеске?',
        answers: [
          'Только для подъемников, имеющих специальный крюк',
          'Для строительных подъемников',
          'Для всех подъемников, оборудованных люлькой, кроме строительных',
          'Для подъемников ножничного типа',
          'Для телескопических подъемников',
        ],
        correctAnswers: [
          'Для всех подъемников, оборудованных люлькой, кроме строительных',
        ],
      },
      {
        code: '63702019',
        text:
          'На какие из перечисленных ниже опасные производственные объекты (далее - ОПО) не распространяются требования Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения" (далее - ФНП ПС)?',
        answers: [
          'На ОПО, где эксплуатируются канатные дороги',
          'На ОПО, где эксплуатируются краны-манипуляторы',
          'На ОПО, где эксплуатируются краны-экскаваторы, предназначенные для работы с крюком',
          'На ОПО, где эксплуатируются электрические тали',
        ],
        correctAnswers: ['На ОПО, где эксплуатируются канатные дороги'],
      },
      {
        code: '63702020',
        text:
          'Какие из перечисленных ниже нарушений не могут служить причиной остановки эксплуатации подъемника?',
        answers: [
          'ПС не соответствует технологическому процессу, в котором задействовано',
          'Не выполнены предписания по обеспечению безопасной эксплуатации подъемника, выданные эксплуатирующей организацией',
          'Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию',
          'Не проведено техническое освидетельствование ПС',
        ],
        correctAnswers: [
          'Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию',
        ],
      },
      {
        code: '63702021',
        text:
          'При какой скорости ветра запрещается использовать люльки (кабины) с целью перемещения в них людей?',
        answers: ['Более 5 м/с', 'Более 10 м/с', 'Более 7 м/с', 'Более 8 м/с'],
        correctAnswers: ['Более 10 м/с'],
      },
      {
        code: '63702022',
        text:
          'Какое из перечисленных требований к люку (проему) для подъема и опускания грузов, находящегося в перекрытии производственного помещения, указано неверно?',
        answers: [
          'Должен иметь постоянное ограждение высотой не менее 1,1 м',
          'Ограждение люка должно иметь световую сигнализацию',
          'Должен иметь надписи габаритов проема',
          'Должен иметь надписи, запрещающие нахождение людей под перемещаемым грузом',
        ],
        correctAnswers: ['Должен иметь надписи габаритов проема'],
      },
      {
        code: '63702023',
        text:
          'Какие из перечисленных требований, предъявляемых к ПС, выбираемым для транспортирования людей при проведении диагностирования и ремонта металлоконструкций других ПС, указаны неверно?',
        answers: [
          'ПС должно иметь систему управления механизмами, обеспечивающую их плавный пуск',
          'ПС должно иметь систему управления механизмами, обеспечивающую их плавную остановку',
          'ПС должны обеспечивать скорость перемещения кабины по вертикали не более 40 м в минуту',
          'ПС должны иметь не менее чем двукратный запас по грузоподъемности по сравнению с суммой массы самой люльки (кабины), массы устройства, предназначенного для подвешивания люльки (кабины) и паспортной номинальной грузоподъемности люльки (кабины)',
        ],
        correctAnswers: [
          'ПС должны обеспечивать скорость перемещения кабины по вертикали не более 40 м в минуту',
        ],
      },
      {
        code: '63702024',
        text:
          'Считается ли отрыв одной из опор подъемника при проведении испытаний признаком потери устойчивости?',
        answers: [
          'Не считается, если в металлоконструкциях не обнаружены повреждения',
          'Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения',
          'Не считается, если в течение 5 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения',
          'Считается, если остальные опоры подъемника при этом погрузились в грунт',
          'Считается, если опора при отрыве переместилась горизонтально',
        ],
        correctAnswers: [
          'Не считается, если в течение 10 минут поднятый груз не опустился, а также если в металлоконструкциях не обнаружены повреждения',
        ],
      },
      {
        code: '63702025',
        text:
          'Для каких подъемников при проведении технического освидетельствования необходимо проверять точность остановки кабины с нагрузкой и без нагрузки?',
        answers: [
          'Для подъемников, предназначенных для осмотра контактной сети железных дорог',
          'Для строительных подъемников',
          'Для автогидроподъемников',
          'Для подъемников ножничного типа, управление которыми осуществляется из люльки',
        ],
        correctAnswers: ['Для строительных подъемников'],
      },
      {
        code: '63702026',
        text:
          'Кто назначается председателем комиссии, на основании предложений которой принимается решение о пуске в работу ПС, отработавшего срок службы, при смене эксплуатирующей организации?',
        answers: [
          'Уполномоченный представитель Ростехнадзора',
          'Уполномоченный представитель проектной организации',
          'Уполномоченный представитель эксплуатирующей организации',
          'Уполномоченный представитель завода-изготовителя ПС',
        ],
        correctAnswers: [
          'Уполномоченный представитель эксплуатирующей организации',
        ],
      },
      {
        code: '63702027',
        text:
          'Кем должны быть утверждены ППР, ТК на погрузочно-разгрузочные работы и выданы на участки, где будут использоваться ПС, до начала ведения работ?',
        answers: [
          'Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС',
          'Эксплуатирующей ПС организацией',
          'Представителем территориального органа Ростехнадзора',
          'Специализированной организацией',
        ],
        correctAnswers: ['Эксплуатирующей ПС организацией'],
      },
      {
        code: '63702028',
        text:
          'Какая периодичность частичного технического освидетельствования установлена для ПС в течение всего срока службы?',
        answers: [
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 24 месяца',
          'Не реже одного раза в 36 месяцев',
          'Не реже одного раза в 60 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63702029',
        text:
          'Когда выдаются производственные инструкции персоналу, обслуживающему ПС?',
        answers: [
          'Перед допуском к работе, под расписку',
          'Перед прохождением повторного инструктажа',
          'Перед прохождением вводного инструктажа',
          'Перед прохождением первичного инструктажа на рабочем месте',
        ],
        correctAnswers: ['Перед допуском к работе, под расписку'],
      },
      {
        code: '63702030',
        text:
          'Какая организация осуществляет периодическое комплексное обследование рельсовых путей ПС?',
        answers: [
          'Специализированная организация',
          'Организация, эксплуатирующая ПС',
          'Организация-изготовитель',
          'Проектная организация',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63702031',
        text:
          'Каким должно быть безопасное расстояние от низа перемещаемого груза до перекрытий и площадок, где могут находиться люди?',
        answers: [
          'Не менее 2,3 м',
          'Не менее 2 м',
          'Не менее 1,5 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 2,3 м'],
      },
      {
        code: '63702032',
        text:
          'С какой периодичностью проводится плановая проверка состояния люльки (кабины)?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63702033',
        text:
          'При установке опорных мостовых кранов с какой фактической группой классификации (режима) в пролетах зданий должны быть устроены галереи для прохода вдоль рельсового пути с обеих сторон пролета?',
        answers: ['A4 и более', 'A6 и более', 'A6 и менее', 'A4 и менее'],
        correctAnswers: ['A6 и более'],
      },
      {
        code: '63702034',
        text: 'Что должно проводиться после реконструкции ПС?',
        answers: [
          'Внеочередное частичное техническое освидетельствование',
          'Внеочередное полное техническое освидетельствование',
          'Периодическое частичное техническое освидетельствование',
          'Периодическое техническое освидетельствование',
        ],
        correctAnswers: ['Внеочередное полное техническое освидетельствование'],
      },
      {
        code: '63702035',
        text:
          'В каких случаях зоны работающих ПС должны быть ограждены и обозначены предупредительными знаками, при этом нахождение в зоне работы людей не допускается?',
        answers: [
          'Во время работы ПС, оснащенных грейфером или электромагнитом',
          'Во время перемещения грузов стреловыми кранами',
          'Во время работы ПС в охранной зоне линии электропередачи',
          'Во время перемещения грузов любыми ПС',
          'Во время работы ПС в темное время суток',
        ],
        correctAnswers: [
          'Во время работы ПС, оснащенных грейфером или электромагнитом',
        ],
      },
      {
        code: '63702036',
        text:
          'На какие из перечисленных ОПО распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения?',
        answers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
          'На ОПО, где эксплуатируются ПС, установленные в шахтах и на любых плавучих средствах',
          'На ОПО, где эксплуатируются домкраты',
          'На ОПО, где эксплуатируются манипуляторы, используемые в технологических процессах',
          'На ОПО, где эксплуатируются ПС, предназначенные для работы только с навесным оборудованием (вибропогружателями, шпунтовыдергивателями, буровым оборудованием)',
        ],
        correctAnswers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
        ],
      },
      {
        code: '63702037',
        text:
          'В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?',
        answers: [
          'В акте пуска ПС в работу',
          'В руководстве (инструкции) по эксплуатации ПС',
          'В паспорте ПС',
          'В сертификате соответствия',
        ],
        correctAnswers: ['В акте пуска ПС в работу'],
      },
      {
        code: '63702038',
        text:
          'Кого в обязательном порядке должны информировать работники ОПО, непосредственно занимающиеся эксплуатацией ПС, об угрозе возникновения аварийной ситуации?',
        answers: [
          'Инженерно-технического работника, ответственного за осуществление производственного контроля при эксплуатации ПС',
          'Инженерно-технического работника, ответственного за содержание ПС в работоспособном состоянии',
          'Своего непосредственного руководителя',
          'Специалиста по ОТ эксплуатирующей организации',
          'Инженерно-технического работника, ответственного за безопасное производство работ с применением ПС',
        ],
        correctAnswers: ['Своего непосредственного руководителя'],
      },
      {
        code: '63702039',
        text:
          'Каким требованиям должны отвечать перила ограждения по всему периметру пола люльки для подъема и транспортировки людей кранами?',
        answers: [
          'Перила ограждения должны быть высотой не менее 1100 мм и гибкими, чтобы предотвратить травмирование персонала при раскачке люльки ветром во время транспортировки',
          'Перила ограждения должны быть гибкими и выдерживать (на разрыв) горизонтальную нагрузку не менее половины паспортной грузоподъемности люльки',
          'Конструкция перил люльки должна определяться разработчиком и согласовываться с Ростехнадзором при согласовании проекта производства работ по транспортировке персонала',
          'Перила ограждения должны быть жесткими, высотой не менее 1100 мм по всему периметру пола люльки, исключающие случайное выскальзывание персонала при раскачивании люльки во время транспортировки. До высоты 0,5 м ограждение должно быть сплошным',
        ],
        correctAnswers: [
          'Перила ограждения должны быть жесткими, высотой не менее 1100 мм по всему периметру пола люльки, исключающие случайное выскальзывание персонала при раскачивании люльки во время транспортировки. До высоты 0,5 м ограждение должно быть сплошным',
        ],
      },
      {
        code: '63702040',
        text:
          'С какой периодичностью проводятся грузовые испытания люльки (кабины)?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полугодие',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в полугодие'],
      },
      {
        code: '63702041',
        text:
          'Кем определяется время действия наряда-допуска на работу подъемника (вышки) вблизи линий электропередачи (далее - ЛЭП)?',
        answers: [
          'Организацией, эксплуатирующей ЛЭП',
          'Организацией, его выдавшей',
          'Крановщиком (машинистом)',
          'Инженерно-техническим работником, ответственным за безопасное производство работ с применением ПС',
          'Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии',
        ],
        correctAnswers: ['Организацией, его выдавшей'],
      },
      {
        code: '63702042',
        text:
          'Каким требованиям промышленной безопасности должна отвечать люлька для подъема и транспортировки людей кранами в случаях, когда транспортируемый персонал занимает положение у одной из сторон люльки, создавая наибольший опрокидывающий момент?',
        answers: [
          'Исключать возможность крена',
          'Исключать возможность опрокидывания',
          'Иметь специальные устройства для сохранения горизонтального положения пола',
          'Ограничивать положение персонала своей центральной частью после начала ее подъема краном',
        ],
        correctAnswers: ['Исключать возможность опрокидывания'],
      },
      {
        code: '63702043',
        text:
          'В каком случае допускается эксплуатация текстильных стропов на полимерной основе?',
        answers: [
          'В случае если имеются поверхностные обрывы нитей ленты общей длиной более 10% ширины ленты, вызванные механическим воздействием (трением) острых кромок груза',
          'В случае если имеются сквозные отверстия диаметром 15% ширины ленты от воздействия острых предметов',
          'В случае если имеются продольные порезы или разрывы ленты, суммарная длина которых превышает 10% длины ленты ветви стропа',
          'Эксплуатация запрещается во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Эксплуатация запрещается во всех перечисленных случаях',
        ],
      },
      {
        code: '63702044',
        text: 'Кем осуществляется ежесменный осмотр рельсового пути ПС?',
        answers: [
          'Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией',
          'Специалистом, ответственным за осуществление производственного контроля при эксплуатации ПС',
          'Инженерно-техническим работником, ответственным за содержание ПС в работоспособном состоянии',
          'Начальником участка',
        ],
        correctAnswers: [
          'Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией',
        ],
      },
      {
        code: '63702045',
        text:
          'Когда результат статических испытаний крана стрелового типа считается положительным?',
        answers: [
          'Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов',
          'Если в течение 5 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов',
          'Если кран за весь период испытаний не потеряет устойчивость',
          'Только если не обнаружено трещин на металлоконструкциях и механизмах',
        ],
        correctAnswers: [
          'Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов',
        ],
      },
      {
        code: '63702046',
        text:
          'В какой документ заносятся результаты грузовых испытаний люльки (кабины)?',
        answers: [
          'В вахтенный журнал',
          'В журнал учета и осмотра грузозахватных приспособлений',
          'В журнал осмотра люльки (кабины)',
          'В паспорт люльки (кабины)',
        ],
        correctAnswers: ['В паспорт люльки (кабины)'],
      },
      {
        code: '63702047',
        text:
          'Как следует поступить, если во время грузовых испытаний люльки для транспортировки людей кранами выявлены дефекты и повреждения?',
        answers: [
          'Утилизировать люльку',
          'Запретить дальнейшую эксплуатацию люльки',
          'Продолжить эксплуатацию до запланированного ремонта',
          'Поставить в известность (письменно) руководителя эксплуатирующей организации о выявленных дефектах и повреждениях люльки и продолжить ее эксплуатацию',
          'Поставить в известность (под подпись) руководителя ремонтной службы эксплуатирующей организации о выявленных дефектах и повреждениях люльки и продолжить ее эксплуатацию',
        ],
        correctAnswers: ['Запретить дальнейшую эксплуатацию люльки'],
      },
      {
        code: '63702048',
        text:
          'Кто выдает разрешение на дальнейшую эксплуатацию подъемника (вышки) по завершении выполнения периодического планового технического освидетельствования?',
        answers: [
          'Специалист, ответственный за содержание ПС в работоспособном состоянии',
          'Специалист, ответственный за безопасное производство работ с применением ПС',
          'Специалист, ответственный за содержание ПС в работоспособном состоянии, при участии специалиста, ответственного за безопасное производство работ с применением ПС',
          'Руководитель эксплуатирующей организации, которой принадлежит подъемник (вышка)',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
        ],
      },
      {
        code: '63702049',
        text:
          'Какие меры промышленной безопасности должны быть приняты для ПС, установленных на открытом воздухе и находящихся в нерабочем состоянии?',
        answers: [
          'ПС должны быть обесточены и приняты меры по предотвращению их угона ветром',
          'Если давление в колесах не соответствует норме, ПС должны быть установлены на аутригеры',
          'Меры промышленной безопасности определяются ветровым районом установки ПС',
          'Меры промышленной безопасности определяются ветровым районом и высотой установки ПС над уровнем моря',
          'ПС должны быть установлены на аутригеры, а стрела на - минимальный вылет',
        ],
        correctAnswers: [
          'ПС должны быть обесточены и приняты меры по предотвращению их угона ветром',
        ],
      },
      {
        code: '63702050',
        text:
          'Какие из перечисленных ПС подлежат учету в органах Ростехнадзора?',
        answers: [
          'Мостовые краны-штабелеры',
          'ПС, используемые в учебных целях на полигонах учебных заведений',
          'Краны стрелового типа с постоянным вылетом или не оборудованные механизмом поворота',
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
        correctAnswers: [
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
      },
      {
        code: '63702051',
        text:
          'Имеет ли право организация, эксплуатирующая ОПО с ПС, привлекать специалистов сторонних организаций в качестве: специалистов, ответственных за осуществление производственного контроля при эксплуатации ПС; специалистов, ответственных за содержание ПС в работоспособном состоянии; специалистов, ответственных за безопасное производство работ с применением ПС?',
        answers: [
          'Имеет право привлекать всех перечисленных специалистов',
          'Имеет право привлекать специалиста, ответственного за содержание ПС в работоспособном состоянии, и специалиста, ответственного за безопасное производство работ с применением ПС',
          'Имеет право привлекать только специалиста, ответственного за безопасное производство работ с применением ПС',
          'Не имеет права',
          'Имеет право привлекать только специалиста, ответственного за содержание ПС в работоспособном состоянии',
        ],
        correctAnswers: ['Не имеет права'],
      },
      {
        code: '63702052',
        text:
          'Каким должен быть запас по грузоподъемности ПС для его возможного использования при транспортировке людей, по сравнению с суммой массы самой люльки (кабины), массы устройства, предназначенного для подвешивания люльки (кабины), и паспортной номинальной грузоподъемности люльки (кабины) согласно требованиям, установленным ФНП ПС?',
        answers: [
          'Не менее чем двукратный запас',
          'Не менее чем трехкратный запас',
          'Не менее чем полуторакратный запас, в том числе и для тормозов его механизма подъема',
          'Не регламентируется, если кран оснащен ограничителем грузоподъемности (грузового момента)',
          'Не менее чем девятикратный запас',
        ],
        correctAnswers: ['Не менее чем двукратный запас'],
      },
      {
        code: '63702053',
        text:
          'Каким документом определяется объем работ, порядок и периодичность проведения технических освидетельствований ПС?',
        answers: [
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Паспортом ПС',
          'Проектом производства работ (ППР)',
          'Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в нем указаний - требованиями ФНП ПС',
        ],
        correctAnswers: [
          'Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в нем указаний - требованиями ФНП ПС',
        ],
      },
      {
        code: '63702054',
        text:
          'С кем следует согласовывать установку кранов, передвигающихся по рельсовому пути, в охранной зоне воздушных линий (далее - ВЛ) электропередачи?',
        answers: [
          'С владельцем линии',
          'С главным энергетиком организации, эксплуатирующей ВЛ',
          'С территориальным органом Ростехнадзора',
          'С органом муниципального управления, по территории которого проходит ВЛ',
        ],
        correctAnswers: ['С владельцем линии'],
      },
      {
        code: '63702055',
        text:
          'Согласно требованиям какого документа должна выполняться оценка работоспособности ограничителя или указателя опасного приближения к линии электропередачи при эксплуатации ПС?',
        answers: [
          'Проекта производства работ, разработанного эксплуатирующей организацией',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Руководства (инструкции) по эксплуатации ПС',
          'Паспорта ПС',
        ],
        correctAnswers: [
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63702056',
        text:
          'Какие меры следует принять к установке подъемников (вышек) при невозможности соблюдения безопасных расстояний, указанных в ФНП ПС, если глубина котлована более 5 м?',
        answers: [
          'Не устанавливать подъемник (вышку) для производства работ',
          'Установить подъемник (вышку) для производства работ, если получено письменное разрешение специалиста, ответственного за безопасное производство работ с применением ПС',
          'Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР',
          'Установить подъемник (вышку) для производства работ, если присутствует специалист, ответственный за безопасное производство работ с применением ПС',
          'Установить подъемник (вышку) для производства работ, если на площадке находится сигнальщик, освобожденный от выполнения других работ',
        ],
        correctAnswers: [
          'Установить подъемник (вышку) для производства работ, если откос дополнительно укреплен в соответствии с ППР',
        ],
      },
      {
        code: '63702057',
        text:
          'Каким способом должны быть закреплены концы канатных стропов подвески люльки, используемой для подъема и транспортировки кранами людей?',
        answers: [
          'Одним из способов, разрешенных нормативными документами по изготовлению стропов',
          'При помощи заплетенных коушей или коушей с зажимами',
          'При помощи обжимных втулок',
          'При помощи коушей с зажимами или обжимных втулок',
        ],
        correctAnswers: ['При помощи заплетенных коушей или коушей с зажимами'],
      },
      {
        code: '63702058',
        text:
          'В соответствии с требованиями каких документов должна проводиться установка ПС в зданиях, на открытых площадках и других участках производства работ? Выберите 2 варианта ответа.',
        answers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Проекта производства работ, разработанного эксплуатирующей организацией',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
          'Паспорта ПС',
        ],
        correctAnswers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
      },
      {
        code: '63702059',
        text:
          'Что должно быть предпринято в случае, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между оператором (крановщиком) и стропальщиком радио- или телефонной связи?',
        answers: [
          'Должен быть назначен сигнальщик из числа стропальщиков',
          'Должен быть оформлен дополнительный наряд-допуск на работы повышенной опасности',
          'Должны быть переданы сигналы голосом',
          'Должен быть разработан план действий для крановщика и стропальщика',
        ],
        correctAnswers: [
          'Должен быть назначен сигнальщик из числа стропальщиков',
        ],
      },
      {
        code: '63702060',
        text:
          'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          'Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для всех стадий жизненного цикла этих ПС',
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
          'Ранее действующими правилами устройства и безопасной эксплуатации ПС Госгортехнадзора России для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС - Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения',
        ],
        correctAnswers: [
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
        ],
      },
      {
        code: '63702061',
        text:
          'Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка подъемников (вышек) только на две или три выносные опоры?',
        answers: [
          'Не разрешается, ПС устанавливается на все выносные опоры',
          'Если одна из частей площадки установки ПС находится под допустимым уклоном',
          'Если отсутствует место на площадке установки ПС для всех четырех опор',
          'Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору',
          'Если подъем и перемещение груза будут выполняться только в одном положении стрелы',
        ],
        correctAnswers: [
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
      },
      {
        code: '63702062',
        text:
          'Грузом какой массы следует выполнять грузовые испытания люльки (кабины) для транспортировки кранами людей?',
        answers: [
          'В 1,25 раза превышающей грузоподъемность люльки',
          'Равной грузоподъемности люльки',
          'В два раза превышающей грузоподъемность люльки',
          'В три раза превышающей грузоподъемность люльки',
        ],
        correctAnswers: ['В два раза превышающей грузоподъемность люльки'],
      },
      {
        code: '63702063',
        text:
          'Каким документом должна определяться численность инженерно-технических работников эксплуатирующей организации?',
        answers: [
          'Внутренним распорядительным актом эксплуатирующей организации',
          'Приказом территориального органа Ростехнадзора',
          'Распоряжением уполномоченного представителя Ростехнадзора',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Внутренним распорядительным актом эксплуатирующей организации',
        ],
      },
      {
        code: '63702064',
        text:
          'Какие действия необходимо выполнить для утилизации (ликвидации) подъемника (вышки)?',
        answers: [
          'Направить письменное обращение в Ростехнадзор',
          'Направить письменное обращение в проектную организацию',
          'Демонтировать подъемник (вышку)',
          'Отказаться от ремонта подъемника (вышки), предписанного его экспертизой промышленной безопасности',
        ],
        correctAnswers: ['Демонтировать подъемник (вышку)'],
      },
      {
        code: '63702065',
        text:
          'С какой нагрузкой следует выполнять статические испытания грузопассажирских и фасадных строительных подъемников?',
        answers: [
          '125% по отношению к номинальной паспортной грузоподъемности',
          '140% по отношению к номинальной паспортной грузоподъемности',
          '150% по отношению к номинальной паспортной грузоподъемности',
          '175% по отношению к номинальной паспортной грузоподъемности',
          '200% по отношению к номинальной паспортной грузоподъемности',
        ],
        correctAnswers: [
          '200% по отношению к номинальной паспортной грузоподъемности',
        ],
      },
      {
        code: '63702066',
        text:
          'В соответствии с требованиями какого документа выполняется проверка работоспособности указателя (сигнализатора) предельной скорости ветра (анемометра) и креномера (указателя угла наклона ПС)?',
        answers: [
          'Руководств (инструкций) по эксплуатации анемометра и креномера',
          'Технического регламента ТР ТС 010/2011',
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          'Паспорта ПС',
        ],
        correctAnswers: [
          'Руководств (инструкций) по эксплуатации анемометра и креномера',
        ],
      },
      {
        code: '63702067',
        text:
          'В каких случаях необходимо прекращать работу ПС, установленных на открытом воздухе?',
        answers: [
          'Только в случае превышения предельно допустимой скорости ветра, указанной в паспорте ПС',
          'Только в случае понижения температуры окружающей среды ниже предельно допустимой температуры, указанной в паспорте ПС',
          'Только в случае снегопада, дождя, тумана, когда крановщик (машинист, оператор) плохо различает сигналы стропальщика или перемещаемый груз',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63702068',
        text:
          'Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка стрелового крана, крана-манипулятора только на две или три выносные опоры?',
        answers: [
          'Если одна из частей площадки установки находится под допустимым уклоном',
          'Если отсутствует место на площадке установки ПС для всех четырех опор',
          'Если подъем и перемещение груза будут выполняться только в одном положении стрелы',
          'Если отсутствует одна из инвентарных подкладок, устанавливаемых под опору',
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
        correctAnswers: [
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
      },
      {
        code: '63702069',
        text:
          'В каких случаях разрешено использовать тару для транспортировки людей?',
        answers: [
          'Если это указано в паспорте ПС или тары',
          'Если произошла авария и необходимо транспортировать пострадавшего, который не может самостоятельно передвигаться, с верхних ярусов здания',
          'Запрещено во всех случаях',
          'Если проводится осмотр или экспертиза промышленной безопасности металлоконструкций ПС',
          'Если есть письменное разрешение эксплуатирующей организации',
        ],
        correctAnswers: ['Запрещено во всех случаях'],
      },
      {
        code: '63702070',
        text:
          'Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?',
        answers: [
          'Паспорта ПС',
          'Технических условий ПС',
          'Руководства (инструкции) по эксплуатации ПС',
          'Формуляра ПС',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации ПС'],
      },
      {
        code: '63702071',
        text:
          'Каким грузом следует проводить динамические испытания подъемника (вышки)?',
        answers: [
          'Масса которого на 10% превышает его паспортную грузоподъемность',
          'Масса которого на 5% превышает его паспортную грузоподъемность',
          'Масса которого на 25% превышает его паспортную грузоподъемность',
          'Масса которого на 20% превышает его паспортную грузоподъемность',
        ],
        correctAnswers: [
          'Масса которого на 10% превышает его паспортную грузоподъемность',
        ],
      },
      {
        code: '63702072',
        text:
          'Какие из перечисленных сведений должны быть указаны в инструкциях, определяющих действия работников в аварийных ситуациях в организации, эксплуатирующей ОПО с ПС?',
        answers: [
          'Только оперативные действия по предотвращению и локализации аварий',
          'Только способы и методы ликвидации аварий',
          'Только схемы эвакуации в случае возникновения взрыва, пожара, выброса токсичных веществ в помещении или на площадке, обслуживаемой ПС, если аварийная ситуация не может быть локализована или ликвидирована',
          'Все перечисленные сведения',
        ],
        correctAnswers: ['Все перечисленные сведения'],
      },
      {
        code: '63702073',
        text:
          'Куда записывается решение о вводе в эксплуатацию специальных съемных кабин и люлек (для подъема и перемещения людей кранами)?',
        answers: [
          'В журнал учета и периодического осмотра СГП и тары',
          'В распорядительный акт эксплуатирующей организации',
          'На бирку люльки',
          'В паспорт кабины или люльки',
        ],
        correctAnswers: ['В паспорт кабины или люльки'],
      },
      {
        code: '63702074',
        text:
          'Кем выдается наряд-допуск на работу вблизи находящихся под напряжением проводов и оборудования в случаях, когда работы с применением кранов стрелового типа, кранов-манипуляторов, подъемников (вышек) ведутся на действующих электростанциях, подстанциях и линиях электропередачи?',
        answers: [
          'Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи',
          'Специализированной организацией',
          'Ростехнадзором',
          'Специалистом, ответственным за осуществление производственного контроля при эксплуатации ПС',
        ],
        correctAnswers: [
          'Организацией, эксплуатирующей электростанцию, подстанцию, линию электропередачи',
        ],
      },
      {
        code: '63702075',
        text:
          'Какое должно быть минимальное расстояние между поворотной частью ПС при любом его положении, в том числе в нагруженном состоянии, и строениями, штабелями грузов и другими предметами?',
        answers: ['1000 мм', '800 мм', '600 мм', '1200 мм'],
        correctAnswers: ['1000 мм'],
      },
      {
        code: '63702076',
        text:
          'Кто должен проводить техническое освидетельствование подъемника (вышки)?',
        answers: [
          'Комиссия эксплуатирующей организации, состав которой утверждает ее руководитель',
          'Комиссия эксплуатирующей организации, в состав которой должен входить представитель Ростехнадзора',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии',
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за безопасное производство работ с применением ПС',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии',
        ],
      },
    ],
    63705: [
      {
        code: '63705000',
        text:
          'Какое количество пунктов управления должна иметь канатная дорога, с которых должны осуществляться ее управление и остановка, а также все виды контроля?',
        answers: [
          'Не менее четырех пунктов управления',
          'Не менее трех пунктов управления',
          'Не менее одного пункта управления',
          'Не менее двух пунктов управления',
        ],
        correctAnswers: ['Не менее одного пункта управления'],
      },
      {
        code: '63705001',
        text:
          'Каким образом запрещается производить сращивание концов тягового каната ББКД?',
        answers: [
          'С применением винтового зажима',
          'Счаливанием',
          'С использованием специальных приспособлений с клиновыми замками',
        ],
        correctAnswers: ['С применением винтового зажима'],
      },
      {
        code: '63705002',
        text: 'В каком случае проводится дефектоскопия каната?',
        answers: [
          'При остаточном удлинении каната более чем на 0,5% рабочей длины после первоначальной вытяжки в начальный период эксплуатации и выполненной пересчалки',
          'При обнаружении в канате одной или нескольких оборванных прядей',
          'При уменьшении диаметра каната двойной свивки в результате поверхностного износа или коррозии на 7% и более по сравнению с номинальным диаметром',
          'После любого внешнего воздействия (удар молнии, сход, деформирование каната) перед началом эксплуатации',
        ],
        correctAnswers: [
          'После любого внешнего воздействия (удар молнии, сход, деформирование каната) перед началом эксплуатации',
        ],
      },
      {
        code: '63705003',
        text:
          'Какое из перечисленных дополнительных требований для отцепляемых зажимов буксировочных канатных дорог указано неверно?',
        answers: [
          'Диаметр отверстия втулки зажима должен быть в пределах от 1,18d до 1,28d',
          'Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте',
          'Для каната диаметром от 12 до 18 мм наружный диаметр втулки зажима должен составлять 40 - 46 мм',
          'Для каната диаметром от 12 до 18 мм длина втулки зажима должна быть равна 40 ± 1 мм',
        ],
        correctAnswers: [
          'Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте',
        ],
      },
      {
        code: '63705004',
        text:
          'Какие из перечисленных требований необходимо соблюдать при установке направляющих подвижного состава пассажирской подвесной канатной дороги?',
        answers: [
          "Направляющие на станциях должны быть установлены таким образом, чтобы кабины при продольных колебаниях 14°20' и одновременных поперечных колебаниях 14°20' не могли соприкасаться",
          'Направляющие на линейном оборудовании трассы устанавливают для ограничения поперечного раскачивания кабин вне диапазона досягаемости рукой и на высоте нахождения центра тяжести кабины',
          "Направляющие на станциях устанавливают для ограничения поперечного раскачивания кресел на 28°30'",
          'Направляющие на станциях или в непосредственной близости от них необходимо устанавливать вне диапазона досягаемости рукой',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63705005',
        text:
          'Какое устройство на наземных канатных дорогах (далее - НКД) и маятниковых ППКД должно срабатывать при переезде регулировочной точки?',
        answers: [
          'Аварийный тормоз',
          'Устройство контроля превышения скорости',
          'Рабочий тормоз',
          'Аварийный привод',
        ],
        correctAnswers: ['Аварийный тормоз'],
      },
      {
        code: '63705006',
        text:
          'Какой вид проверки проводится после ремонта приводов и расчетных элементов металлоконструкций с применением сварки?',
        answers: [
          'Экспертиза промышленной безопасности',
          'Внеочередное техническое освидетельствование',
          'Ежегодная ревизия',
          'Динамические испытания',
        ],
        correctAnswers: ['Внеочередное техническое освидетельствование'],
      },
      {
        code: '63705007',
        text:
          'Какая ревизия должна быть проведена, если КД не эксплуатировали более полугода?',
        answers: [
          'Ежемесячная ревизия',
          'Еженедельная ревизия',
          'Ежегодная ревизия',
          'Полугодовая ревизия',
        ],
        correctAnswers: ['Ежегодная ревизия'],
      },
      {
        code: '63705008',
        text:
          'Какой угол отклонения в плане на опоре допускается для несуще-тяговых канатов пассажирских подвесных канатных дорог (далее - ППКД)?',
        answers: ['30’', '17’', '14°30’', '45’'],
        correctAnswers: ['30’'],
      },
      {
        code: '63705009',
        text:
          'Какую величину составляет максимально допустимая скорость для БКД с промежуточными опорами?',
        answers: ['4 м/с', '5 м/с', '6 м/с', '4,5 м/с'],
        correctAnswers: ['4 м/с'],
      },
      {
        code: '63705010',
        text:
          'В каком случае должен приводиться в действие тормоз, предотвращающий самопроизвольный обратный ход дороги, автоматически?',
        answers: [
          'Только при прекращении подачи электроэнергии к главному двигателю',
          'Только при падении давления в гидравлических или пневматических тормозах ниже допустимого значения',
          'Только при размыкании в цепи безопасности',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63705011',
        text:
          'В какой срок пассажиры должны быть оповещены об обстоятельствах сложившейся нештатной ситуации?',
        answers: [
          'В течение 30 минут с момента ее возникновения',
          'В течение 15 минут с момента ее возникновения',
          'В течение 4 часов с момента ее возникновения',
          'В течение 10 минут с момента ее возникновения',
        ],
        correctAnswers: ['В течение 15 минут с момента ее возникновения'],
      },
      {
        code: '63705012',
        text:
          'Какому требованию должен отвечать оператор, допускаемый к самостоятельной работе?',
        answers: [
          'Должен пройти стажировку на рабочем месте сроком не менее двух недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД',
          'Должен иметь высшее образование и пройти стажировку в течение трех месяцев на действующей КД',
          'Должен иметь допуск к работам на высоте и опыт таких работ не менее трех лет',
          'Должен иметь высшее образование и стаж работы ИТР не менее трех лет',
        ],
        correctAnswers: [
          'Должен пройти стажировку на рабочем месте сроком не менее двух недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД',
        ],
      },
      {
        code: '63705013',
        text:
          'Какая максимальная скорость движения наземных канатных дорог составляет для систем с автоматическим управлением?',
        answers: ['15 м/с', '10 м/с', '5 м/с', '20 м/с'],
        correctAnswers: ['15 м/с'],
      },
      {
        code: '63705014',
        text:
          'Что необходимо предпринять, если буксировочная дорожка проходит рядом с местом катания лыжников?',
        answers: [
          'Буксировочную дорожку необходимо выделить (обозначить)',
          'Установить силовое ограждение вдоль всего участка приближения',
          'Установить указатели направления к буксировочной дорожке',
          'Перенести зону катания в другое место',
        ],
        correctAnswers: [
          'Буксировочную дорожку необходимо выделить (обозначить)',
        ],
      },
      {
        code: '63705015',
        text:
          'Какую ширину дверного проема должны иметь кабины, предназначенные для перевозки пассажиров в креслах-каталках?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 0,7 м',
          'Не менее 0,6 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63705016',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами одноместных БКД?',
        answers: ['4 секунды', '5 секунд', '6 секунд', '3 секунды'],
        correctAnswers: ['4 секунды'],
      },
      {
        code: '63705017',
        text:
          'Каким должно быть минимальное расстояние между двумя соседними счалками тяговых и несуще-тяговых канатов относительно диаметра каната (dk)?',
        answers: ['3000 dk', '1200 dk', '1300 dk', '5000 dk'],
        correctAnswers: ['3000 dk'],
      },
      {
        code: '63705018',
        text:
          'Кто утверждает правила перевозки пассажиров и багажа канатной дорогой?',
        answers: [
          'Эксплуатирующая организация',
          'Проектная организация',
          'Ростехнадзор',
          'Организация, имеющая лицензию на проведение экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63705019',
        text: 'Кем проводится техническое освидетельствование КД?',
        answers: [
          'Ростехнадзором',
          'Эксплуатирующей организацией',
          'Проектной организацией',
          'Специализированной организацией',
        ],
        correctAnswers: ['Специализированной организацией'],
      },
      {
        code: '63705020',
        text:
          'Какое из перечисленных условий для начала движения КД указано неверно?',
        answers: [
          'Электрооборудование управления движением установлено в исходное положение',
          'Получены сигналы готовности со всех станций',
          'Канатная дорога остановлена',
          'Рабочий тормоз открыт',
        ],
        correctAnswers: ['Рабочий тормоз открыт'],
      },
      {
        code: '63705021',
        text:
          'Какое максимальное расстояние допускается между краем посадочной платформы и подвижным составом на канатных дорогах с кабинами или вагонами?',
        answers: ['0,2 м', '0,15 м', '0,1 м', '0,05 м'],
        correctAnswers: ['0,05 м'],
      },
      {
        code: '63705022',
        text:
          'Кто принимает решение о возобновлении дальнейшей эксплуатации или введении в действие плана мероприятий по эвакуации в результате рассмотрения причин остановки КД?',
        answers: [
          'Ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД',
          'Руководитель организации, эксплуатирующей КД',
          'Ответственный за исправное состояние и безопасную эксплуатацию КД',
          'Специализированная организация, выполняющая ремонт и обслуживание КД',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию КД',
        ],
      },
      {
        code: '63705023',
        text:
          'В какие сроки проводится первое полное техническое освидетельствование?',
        answers: [
          'Через год после ввода в эксплуатацию',
          'После отработки 22 500 моточасов с момента первого ввода в эксплуатацию, но не позднее, чем через 15 лет',
          'После отработки 15 000 моточасов, но не позднее, чем через 10 лет',
          'После отработки 7 500 моточасов, но не позднее, чем через 5 лет',
        ],
        correctAnswers: [
          'После отработки 22 500 моточасов с момента первого ввода в эксплуатацию, но не позднее, чем через 15 лет',
        ],
      },
      {
        code: '63705024',
        text:
          'Как должны оборудоваться проходы и площадки для пассажиров, которые находятся выше уровня земли?',
        answers: [
          'Их необходимо выделить (обозначить)',
          'Их ограждают перилами, минимальная высота которых должна быть на 1 м выше снежного покрова',
          'Их ограждают маркировочными сетками',
          'Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м',
        ],
        correctAnswers: [
          'Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м',
        ],
      },
      {
        code: '63705025',
        text:
          'Какие требования предъявляются к трассе КД при прохождении ее в лесистой местности с целью обеспечения промышленной безопасности ОПО с КД?',
        answers: [
          'При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на линию КД',
          'При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на элементы, оборудование и подвижной состав КД',
          'Просека под линией канатной дороги должна иметь ширину не менее колеи КД с учетом поперечных колебаний канатов и подвижного состава, за исключением случаев, когда проектом КД не предусмотрена вертикальная эвакуация пассажиров с подвижного состава',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63705026',
        text:
          'Какое требование соответствует проходам для персонала и эвакуации пассажиров вдоль трассы наземных канатных дорог?',
        answers: [
          'Должны иметь ширину не менее 0,4 м и высоту не более 1,8 м',
          'Должны иметь ширину не более 0,8 м и высоту не менее 2,2 м',
          'Должны иметь ширину не более 1,0 м и высоту не менее 2,4 м',
          'Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м',
        ],
        correctAnswers: [
          'Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м',
        ],
      },
      {
        code: '63705027',
        text:
          'Какая скорость устанавливается при обратном ходе пассажирских подвесных канатных дорог и наземных канатных дорог?',
        answers: [
          'Не менее 1,5 м/с',
          'Не более 2,5 м/с',
          'Не более 1,5 м/с',
          'Не менее 1,0 м/с',
        ],
        correctAnswers: ['Не более 1,5 м/с'],
      },
      {
        code: '63705028',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами двухместных БКД?',
        answers: ['3 секунды', '4 секунды', '5 секунд', '6 секунд'],
        correctAnswers: ['6 секунд'],
      },
      {
        code: '63705029',
        text:
          'Какие из перечисленных специальных требований для фиксированных зажимов КД указаны неверно?',
        answers: [
          'Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 30% при выходе из строя отдельных тарельчатых пружин',
          'Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 0,75d',
          'Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 15% при выходе из строя отдельных тарельчатых пружин',
          'При отклонениях номинального диаметра каната от -10% до +6% величина закрытия зажимных губок до их соприкосновения должна составлять не менее 1 мм',
        ],
        correctAnswers: [
          'Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 30% при выходе из строя отдельных тарельчатых пружин',
        ],
      },
      {
        code: '63705030',
        text:
          'Какое количество полных витков должно быть предусмотрено для крепления концов несущих и натяжных канатов на тумбе?',
        answers: [
          'Не менее двух',
          'Один',
          'Не менее трех',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее трех'],
      },
      {
        code: '63705031',
        text:
          'В каком случае допускается не устанавливать вертикальные ролики и шкивы перед отклонением тягового каната и после него на буксировочных канатных дорогах?',
        answers: [
          'У самоустанавливающихся роликов и шкивов',
          'Для тяговых канатов диаметром до 18 мм',
          'Если это обосновано проектной документацией',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['У самоустанавливающихся роликов и шкивов'],
      },
      {
        code: '63705032',
        text:
          'Чем не должны оснащаться вагоны с сопровождающим персоналом наземных канатных дорог? Укажите все правильные ответы.',
        answers: [
          'Правилами перевозки пассажиров',
          'Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе',
          'Пунктом управления',
          'Указаниями о допустимом количестве пассажиров',
        ],
        correctAnswers: [
          'Правилами перевозки пассажиров',
          'Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе',
        ],
      },
      {
        code: '63705033',
        text:
          'Какая максимальная скорость движения кольцевых канатных дорог с отцепляемым подвижным составом на трассе для открытых кабин и кресел?',
        answers: ['7 м/с', '5 м/с', '6 м/с', '8 м/с'],
        correctAnswers: ['6 м/с'],
      },
      {
        code: '63705034',
        text:
          'В каком месте должны быть расположены выключатели для проведения профилактических работ на пассажирских подвесных канатных дорогах и наземных канатных дорогах?',
        answers: [
          'Только в машинном помещении',
          'Только на профилактических площадках и платформах вблизи от подвижных механических устройств на всех станциях и промежуточных остановках',
          'Только на пультах управления подвижного состава',
          'Только на посту управления',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63705035',
        text:
          'Какая установлена максимальная скорость ускорения при разгоне и торможении на КД на участках прицепления и отцепления?',
        answers: ['2 м/с²', '2,5 м/с²', '3 м/с²', '3,5 м/с²', '1,5 м/c²'],
        correctAnswers: ['1,5 м/c²'],
      },
      {
        code: '63705036',
        text: 'В каком случае проводятся динамические испытания?',
        answers: [
          'При проведении ежегодного технического освидетельствования',
          'При проведении ежемесячной ревизии',
          'При проведении еженедельной проверки',
          'При проведении пробного пуска',
        ],
        correctAnswers: [
          'При проведении ежегодного технического освидетельствования',
        ],
      },
      {
        code: '63705037',
        text:
          'Сращивание каких канатов не допускается? Укажите все правильные ответы.',
        answers: [
          'Несуще-тяговых канатов',
          'Спасательного каната',
          'Несущего каната',
          'Натяжных канатов',
          'Тяговых канатов',
        ],
        correctAnswers: ['Несущего каната', 'Натяжных канатов'],
      },
      {
        code: '63705038',
        text:
          'Какой документ устанавливает методы контроля и диагностики во время эксплуатации КД и при техническом освидетельствовании?',
        answers: [
          'Паспорт пассажирской КД',
          'Заключение экспертизы промышленной безопасности',
          'Руководство по эксплуатации КД',
          'Правила безопасности КД и фуникулеров',
        ],
        correctAnswers: ['Руководство по эксплуатации КД'],
      },
      {
        code: '63705039',
        text:
          'В каких случаях за зоной высадки устанавливают устройства контроля, автоматически останавливающие дорогу? Укажите все правильные ответы.',
        answers: [
          'Не сработало предварительное оповещение о месте высадки',
          'Буксировочное устройство не вернулось в исходное состояние',
          'Пассажир отпустил буксировочное устройство раньше времени',
          'Пассажир не успел отпустить буксировочное устройство',
        ],
        correctAnswers: [
          'Буксировочное устройство не вернулось в исходное состояние',
          'Пассажир не успел отпустить буксировочное устройство',
        ],
      },
      {
        code: '63705040',
        text:
          'Кто должен разработать план мероприятий по эвакуации пассажиров для КД и обеспечить его исполнение?',
        answers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
          'Ответственный за осуществление производственного контроля при эксплуатации канатной дороги',
          'Специалист по промышленной безопасности',
          'Оператор канатной дороги',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
        ],
      },
      {
        code: '63705041',
        text:
          'Кто несет ответственность за содержание канатной дороги в исправном состоянии и соблюдение безопасных условий работы?',
        answers: [
          'Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у нее во владении на праве собственности или ином законном основании',
          'Специализированная организация',
          'Организация по ремонту и обслуживанию канатных дорог',
        ],
        correctAnswers: [
          'Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у нее во владении на праве собственности или ином законном основании',
        ],
      },
      {
        code: '63705042',
        text:
          'Какой класс прочности должны иметь болты для соединения ответственных деталей подвижного состава КД?',
        answers: [
          'Не менее 8,8',
          'Не менее 7,8',
          'Не менее 8,7',
          'Не менее 8,6',
        ],
        correctAnswers: ['Не менее 8,8'],
      },
      {
        code: '63705043',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?',
        answers: ['3 секунды', '4 секунды', '5 секунд', '6 секунд'],
        correctAnswers: ['5 секунд'],
      },
      {
        code: '63705044',
        text:
          'В каких местах устанавливаются кнопки аварийной остановки на буксировочных канатных дорогах?',
        answers: [
          'На пунктах управления',
          'На лестницах к приводному и обводному шкивам',
          'В машинном помещении',
          'На приводе',
        ],
        correctAnswers: ['На пунктах управления'],
      },
      {
        code: '63705045',
        text:
          "В каком случае максимальное значение углов поперечного раскачивания подвижного состава для закрытых кабин и открытого подвижного состава без пассажиров, равное 19°30', может быть уменьшено?",
        answers: [
          'В случае установления данной возможности в технической документации',
          'В случае установки на оборудовании направляющих без проводника',
          'В случае учета ветровых нагрузок',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'В случае установки на оборудовании направляющих без проводника',
        ],
      },
      {
        code: '63705046',
        text:
          'В каком случае отношение между минимальным натяжением каната и весом единицы груженого подвижного состава рассчитывают с минимальным коэффициентом 10?',
        answers: [
          'В случае несущего каната с натяжным устройством',
          'В случае несущего каната с заякоренными концами',
          'В случае тягового каната',
          'В случае несуще-тягового каната',
        ],
        correctAnswers: ['В случае несущего каната с натяжным устройством'],
      },
      {
        code: '63705047',
        text:
          'Какое из перечисленных требований к органам управления КД указано верно?',
        answers: [
          'Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив',
          'Одновременно может быть задействовано лишь два вида управления',
          'Переключение из одного режима движения в другой и от одного вида управления к другому должно производиться кратковременно на запущенной КД',
          'Орган управления аварийной остановкой должен возвращаться в исходное положение персоналом, приводя к запуску КД',
        ],
        correctAnswers: [
          'Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив',
        ],
      },
      {
        code: '63705048',
        text:
          'В каких целях эксплуатирующей организацией организуется проведение технического освидетельствования КД?',
        answers: [
          'Только в целях подтверждения соответствия КД и ее устройства паспортным характеристикам',
          'Только в целях подтверждения технического состояния КД, обеспечивающего ее безопасное использование по назначению',
          'Только в целях подтверждения соответствия эксплуатации, содержания и обслуживания КД требованиям Правил безопасности пассажирских канатных дорог и фуникулеров',
          'Во всех перечисленных целях',
        ],
        correctAnswers: ['Во всех перечисленных целях'],
      },
      {
        code: '63705049',
        text:
          'Кто принимает решение о возобновлении дальнейшей эксплуатации канатной дороги в результате рассмотрения причин ее остановки?',
        answers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
          'Начальник ремонтной бригады подрядной организации',
          'Комиссия эксплуатирующей организации',
          'Ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации канатной дороги',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
        ],
      },
      {
        code: '63705050',
        text:
          'С какой периодичностью должна проводиться учебная эвакуация в месте КД, где эвакуация пассажиров наиболее затруднена?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в полтора года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63705051',
        text:
          'Какой из перечисленных параметров КД может контролироваться перед, во время и после процесса сцепления зажимов?',
        answers: [
          'Отделение зажима от каната',
          'Открытое положение губок зажима',
          'Зажимное усилие',
          'Правильное охватывание каната губками зажима',
        ],
        correctAnswers: ['Зажимное усилие'],
      },
      {
        code: '63705052',
        text: 'Что из перечисленного не относится к регламентным работам?',
        answers: [
          'Профилактическое обслуживание',
          'Проверки и ревизии',
          'Текущий ремонт',
          'Модернизация и реконструкция',
        ],
        correctAnswers: ['Модернизация и реконструкция'],
      },
      {
        code: '63705053',
        text:
          'Кому в своей работе подчиняется вспомогательный персонал канатной дороги (дежурные по станции, контролеры, проводники)?',
        answers: [
          'Оператору канатной дороги',
          'Ответственному за исправное состояние и безопасную эксплуатацию канатной дороги',
          'Ответственному за соблюдение требований промышленной безопасности на канатной дороге',
        ],
        correctAnswers: ['Оператору канатной дороги'],
      },
      {
        code: '63705054',
        text:
          'В каком случае тормоза должны приводиться в действие автоматически на пассажирских подвесных канатных дорогах и наземных канатных дорогах?',
        answers: [
          'Только в случае падения давления в гидравлических или пневматических тормозах ниже допустимого значения',
          'Только в случае размыкания в цепи безопасности',
          'Только в случае прекращения подачи электроэнергии к главному двигателю',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63705055',
        text:
          'При каком уменьшении диаметра несуще-тяговых канатов в результате повреждения сердечника, внутреннего износа, обмятия по сравнению с номинальным диаметром канат подлежит браковке даже при отсутствии видимых обрывов проволок?',
        answers: ['На 5%', 'На 7%', 'На 3%', 'На 10%'],
        correctAnswers: ['На 10%'],
      },
      {
        code: '63705056',
        text:
          'Какая из перечисленных обязанностей относится к вспомогательному персоналу канатной дороги? Выберите два правильных варианта ответа.',
        answers: [
          'Знать порядок ремонта и обслуживания канатной дороги',
          'Делать записи в вахтенном журнале учета работы канатной дороги',
          'Замедлять или останавливать движение КД в случае возникновения аварий и инцидентов',
          'Знать устройство и принципы работы канатной дороги и ее отдельных узлов и механизмов',
          'Поддерживать в надлежащем состоянии площадки посадки и высадки пассажиров, свою рабочую зону, а также проходы для персонала на станции',
        ],
        correctAnswers: [
          'Замедлять или останавливать движение КД в случае возникновения аварий и инцидентов',
          'Поддерживать в надлежащем состоянии площадки посадки и высадки пассажиров, свою рабочую зону, а также проходы для персонала на станции',
        ],
      },
      {
        code: '63705057',
        text:
          'Каким требованиям должен отвечать персонал КД по ремонту, обслуживанию и надзору за пассажирами?',
        answers: [
          'Персонал должен быть старше возраста 18 лет, не имеющий медицинских противопоказаний и прошедший проверку знаний в объеме соответствующих производственных инструкций',
          'Персонал должен иметь высшее образование и стаж работы ИТР не менее 1 года',
          'Персонал должен иметь высшее техническое образование',
          'Персонал должен иметь среднее специальное образование и пройти стажировку в течение 3 месяцев на действующей канатной дороге',
        ],
        correctAnswers: [
          'Персонал должен быть старше возраста 18 лет, не имеющий медицинских противопоказаний и прошедший проверку знаний в объеме соответствующих производственных инструкций',
        ],
      },
      {
        code: '63705058',
        text:
          'Каким методом допускается информировать пассажиров о сложившейся нештатной ситуации?',
        answers: [
          'Только с поверхности земли персоналом, направленным для этого и имеющим ручной громкоговоритель',
          'Только громкоговорителями на линейных опорах',
          'Только системами оповещения в подвижном составе или через проводников в кабинах и вагонах с сопровождением',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63705059',
        text:
          'Каким минимальным количеством двигателей с независимыми источниками питания - основным и аварийным - оборудуются пассажирские подвесные дороги и наземные канатные дороги?',
        answers: ['Тремя', 'Двумя', 'Одним', 'Не регламентируется'],
        correctAnswers: ['Двумя'],
      },
      {
        code: '63705060',
        text:
          'На каком расстоянии от подвижного состава должны быть размещены неподвижные конструкции устройств регулировки входов (турникеты) у КД?',
        answers: [
          'Не менее 0,6 м',
          'Не менее 0,5 м',
          'Не менее 0,4 м',
          'Не менее 0,3 м',
        ],
        correctAnswers: ['Не менее 0,6 м'],
      },
      {
        code: '63705061',
        text:
          'Какая максимально допустимая разность скорости зажима и движущегося каната при прицеплении зажима к канату на канатных дорогах с отцепляемыми зажимами?',
        answers: ['0,2 м/с', '0,4 м/с', '0,3 м/с', '0,5 м/с'],
        correctAnswers: ['0,3 м/с'],
      },
      {
        code: '63705062',
        text:
          'Какая установлена максимальная длина безопорных буксировочных канатных дорог в плане?',
        answers: ['500 м', '200 м', '400 м', '300 м'],
        correctAnswers: ['400 м'],
      },
      {
        code: '63705063',
        text:
          'Какой диапазон досягаемости рукой пассажира составляет при полностью открывающихся окнах и в открытых вагонах наземных канатных дорог?',
        answers: ['0,2 м', '0,5 м', '1,2 м', '1 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63705064',
        text:
          'В какие сроки проводится повторная магнитная дефектоскопия канатов?',
        answers: [
          'Ежегодно, во время технического освидетельствования',
          'Во время проведения экспертизы промышленной безопасности перед вводом в эксплуатацию, а затем после окончания установленного срока службы КД',
          'Через 10 лет после начала эксплуатации и далее через каждые 2 года',
          'Раз в 3 года в первые 15 лет эксплуатации и далее - ежегодно',
        ],
        correctAnswers: [
          'Раз в 3 года в первые 15 лет эксплуатации и далее - ежегодно',
        ],
      },
      {
        code: '63705065',
        text:
          'Какая должна быть минимальная длина счалки при сращивании тяговых и несуще-тяговых канатов, где dk - номинальный диаметр каната?',
        answers: ['1000dk', '1200dk', '1500dk', '3000dk'],
        correctAnswers: ['1200dk'],
      },
      {
        code: '63705066',
        text:
          'В какие сроки проводится проверка на наличие трещин всех зажимов подвижного состава КД неразрушающими методами контроля?',
        answers: [
          'Ежегодно, во время технического освидетельствования',
          'Через 5 лет после начала эксплуатации и далее - ежегодно',
          'Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые два года',
          'Раз в два года в первые 15 лет эксплуатации и далее - ежегодно',
        ],
        correctAnswers: [
          'Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые два года',
        ],
      },
      {
        code: '63705067',
        text:
          'Какое условие должно быть выполнено при эксплуатации БКД в темное время суток?',
        answers: [
          'Лыжная дорожка БКД должна быть освещена на всем протяжении',
          'Должны быть полностью освещены фундаменты станций и опор',
          'Станции посадки и высадки пассажиров должны быть обеспечены эксплуатационным освещением и, в случае его отказа, аварийным освещением',
          'Подвижной состав должен иметь внешнее освещение',
        ],
        correctAnswers: [
          'Лыжная дорожка БКД должна быть освещена на всем протяжении',
        ],
      },
      {
        code: '63705068',
        text:
          'Какое из перечисленных требований к процессу эвакуации пассажиров с подвижного состава КД указано неверно?',
        answers: [
          'КД должна быть оснащена осветительным оборудованием, позволяющим проводить эвакуацию в условиях плохой видимости',
          'Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей',
          'Во время снятия пассажиров КД должна быть остановлена',
        ],
        correctAnswers: [
          'Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей',
        ],
      },
      {
        code: '63705069',
        text:
          'Какое из перечисленных требований к двигателям, редукторам, трансмиссиям, применяемым на канатных дорогах, указано неверно?',
        answers: [
          'В трансмиссии для передачи усилий должны использоваться плоские ремни',
          'Конечные положения переключающей (сцепной) муфты должны блокироваться механически и контролироваться системой управления КД',
          'Двигатели внутреннего сгорания должны быть жестко установлены на раме и должны иметь электростартерную систему пуска с питанием от аккумуляторной батареи',
          'В помещении станции, где установлен двигатель внутреннего сгорания, должен быть обеспечен приток воздуха',
        ],
        correctAnswers: [
          'В трансмиссии для передачи усилий должны использоваться плоские ремни',
        ],
      },
      {
        code: '63705070',
        text:
          'Какой величины не должна превышать высота прохождения тягового каната над землей с учетом снежного покрова на БКД с буксировочными устройствами штангового типа?',
        answers: [
          'Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н',
          'Высота прохождения тягового каната не должна превышать длины собранного буксировочного устройства',
          'Высота прохождения тягового каната не должна превышать длины полностью вытянутого буксировочного устройства',
          'Высота прохождения тягового каната не должна превышать 4 м',
        ],
        correctAnswers: [
          'Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н',
        ],
      },
      {
        code: '63705071',
        text:
          'Для какой канатной дороги минимальный коэффициент запаса прочности тяговых канатов соответствует значению 4?',
        answers: [
          'Для маятниковой КД без тормоза ловителя на несущем канате',
          'Для маятниковой КД с тормозом ловителя на несущем канате',
          'Для наземной КД',
          'Для двухканатной КД замкнутого цикла только для одного направления',
        ],
        correctAnswers: [
          'Для двухканатной КД замкнутого цикла только для одного направления',
        ],
      },
      {
        code: '63705072',
        text:
          'Какие показатели не должны контролироваться ежедневно во время эксплуатации КД?',
        answers: [
          'Функционирование всех кнопок остановки',
          'Состояние зон посадки и высадки',
          'Плавность работы привода, шкивов и роликов на станциях',
          'Наличие шумов, скрежета и звуков, не характерных для нормальной работы дороги',
        ],
        correctAnswers: ['Функционирование всех кнопок остановки'],
      },
      {
        code: '63705073',
        text:
          'Что из перечисленного необходимо контролировать обслуживающему КД персоналу? Выберите 2 варианта ответа.',
        answers: [
          'Минимально допустимое расстояние между единицами подвижного состава на трассе',
          'Достаточное безопасное расстояние между единицами подвижного состава на станциях',
          'Состояние путей',
          'Расписание движения составов',
        ],
        correctAnswers: [
          'Минимально допустимое расстояние между единицами подвижного состава на трассе',
          'Достаточное безопасное расстояние между единицами подвижного состава на станциях',
        ],
      },
      {
        code: '63705074',
        text:
          'В каких вагонах сопровождающий персонал должен находиться у пульта управления на возвышении по отношению к уровню пола вагона маятниковых канатных дорог?',
        answers: [
          'Вместимостью более 50 человек',
          'Вместимостью более 80 человек',
          'Вместимостью более 30 человек',
          'Вместимостью более 60 человек',
        ],
        correctAnswers: ['Вместимостью более 80 человек'],
      },
      {
        code: '63705075',
        text:
          'Какое устанавливается максимальное значение угла поперечного раскачивания буксировочных устройств на буксировочных канатных дорогах?',
        answers: ["19°30'", "11°30'", "6°50'", "8°35'"],
        correctAnswers: ["11°30'"],
      },
      {
        code: '63705076',
        text:
          'Какой минимальный коэффициент запаса прочности тяговых канатов соответствует наземной канатной дороге?',
        answers: ['4,5', '3,8', '4,2', '4'],
        correctAnswers: ['4,2'],
      },
      {
        code: '63705077',
        text:
          'Что не требуется при работе комиссии, принимающей решение о возможности ввода в эксплуатацию ББКД?',
        answers: [
          'Присутствие представителя владельца ОПО',
          'Присутствие представителя органов исполнительной власти в области промышленной безопасности',
          'Проведение экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Присутствие представителя органов исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63705078',
        text:
          'В течение какого срока ответственный за исправное состояние и безопасную эксплуатацию КД должен проходить стажировку на действующей КД того же типа при отсутствии опыта работы на КД?',
        answers: [
          'В течение срока не менее 12 месяцев',
          'В течение срока не менее 3 месяцев',
          'В течение срока не менее 9 месяцев',
          'В течение срока не менее 6 месяцев',
        ],
        correctAnswers: ['В течение срока не менее 3 месяцев'],
      },
      {
        code: '63705079',
        text:
          'На сколько должна быть увеличена ширина буксировочной дорожки на мостах и во впадинах?',
        answers: ['На 1,5 м', 'На 1 м', 'На 0,5 м', 'На 2 м'],
        correctAnswers: ['На 0,5 м'],
      },
      {
        code: '63705080',
        text:
          'Какие из перечисленных требований к башмакам несущего каната ППКД указаны неверно?',
        answers: [
          'Концы башмаков каната должны иметь радиус скругления, соответствующий трехкратному диаметру несущего каната',
          'Концы башмаков каната должны иметь радиус скругления, соответствующий пятикратному диаметру несущего каната',
          'Длина радиуса скругления концов башмака должна соответствовать трехкратному диаметру каната',
          'На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 500 Па',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Концы башмаков каната должны иметь радиус скругления, соответствующий трехкратному диаметру несущего каната',
        ],
      },
      {
        code: '63705081',
        text:
          'Чему должен быть равен коэффициент запаса надежности сцепления тягового и несуще-тягового канатов с приводным шкивом?',
        answers: [
          'Не менее 1,25',
          'Не менее 1,1',
          'Не менее 1,2',
          'Не менее 0,75',
        ],
        correctAnswers: ['Не менее 1,25'],
      },
      {
        code: '63705082',
        text:
          'Какую добавочную величину провеса несущих канатов следует учитывать при равномерном движении груженого подвижного состава?',
        answers: ['0,1', '0,05', '0,3', '0,2'],
        correctAnswers: ['0,1'],
      },
      {
        code: '63705083',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?',
        answers: ['3 секунды', '4 секунды', '5 секунд', '6 секунд'],
        correctAnswers: ['5 секунд'],
      },
      {
        code: '63705084',
        text:
          'Какой максимальный продольный уклон буксировочной дорожки допускается в общем случае для дорог с двухместными буксировочными устройствами?',
        answers: ['26°', '30°', '22°', '14° у шкивов и 22° на линии'],
        correctAnswers: ['26°'],
      },
      {
        code: '63705085',
        text:
          'Каким должен оставаться угол между вытянутым вытяжным канатом и нормалью при всех условиях эксплуатации?',
        answers: [
          'Не менее 17°',
          'Не менее 20°',
          'Не менее 23°',
          'Не менее 25°',
        ],
        correctAnswers: ['Не менее 17°'],
      },
      {
        code: '63705086',
        text: 'Где проводится проверка знаний персонала канатной дороги?',
        answers: [
          'В центральной или территориальной комиссии Ростехнадзора',
          'В комиссии предприятия, эксплуатирующего канатную дорогу',
          'В комиссии специализированной организации',
          'В комиссии учебного комбината',
        ],
        correctAnswers: [
          'В комиссии предприятия, эксплуатирующего канатную дорогу',
        ],
      },
      {
        code: '63705087',
        text:
          'В каком случае допускается прохождение линии ППКД над территорией детских дошкольных и образовательных организаций?',
        answers: [
          'Прохождение линии ППКД над территорией таких организаций запрещено',
          'Если расстояние от низшей точки подвижного состава до земли с учетом снежного покрова составляет не менее 3 м',
          'Если расстояние от низшей точки подвижного состава до высшей точки зданий и сооружений составляет не менее 2 м',
          'Если исключена возможность присутствия людей',
        ],
        correctAnswers: [
          'Прохождение линии ППКД над территорией таких организаций запрещено',
        ],
      },
      {
        code: '63705088',
        text:
          'Какие упоры должен иметь узел соединения буксировочного устройства с тяговым канатом?',
        answers: [
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали',
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 70° от вертикали',
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 50° от вертикали',
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 60° от вертикали',
        ],
        correctAnswers: [
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали',
        ],
      },
      {
        code: '63705089',
        text:
          'На какое минимальное расстояние допускается приближение строений или естественных препятствий к габаритам БКД?',
        answers: ['2 м', '1 м', '1,5 м', '2,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63705090',
        text:
          'Вагоны маятниковых КД с какой вместимостью должны сопровождаться проводником?',
        answers: [
          'Вместимостью более 40 человек',
          'Вместимостью более 20 человек',
          'Вместимостью более 30 человек',
          'Вместимостью более 35 человек',
        ],
        correctAnswers: ['Вместимостью более 40 человек'],
      },
      {
        code: '63705091',
        text:
          'В каком случае КД не подлежит экспертизе промышленной безопасности?',
        answers: [
          'При отсутствии в технической документации данных о сроке службы КД, если фактический срок ее службы превышает десять лет',
          'До начала применения КД на ОПО',
          'В случае истечения срока службы или при превышении количества циклов нагрузки КД, установленных организацией-изготовителем',
          'После проведения работ, связанных с изменением конструкции, заменой материала несущих элементов КД',
        ],
        correctAnswers: [
          'При отсутствии в технической документации данных о сроке службы КД, если фактический срок ее службы превышает десять лет',
        ],
      },
      {
        code: '63705092',
        text:
          'Чему должно быть равно отношение между минимальным натяжением несуще-тягового или тягового каната ППКД и нагрузкой на ролик балансира?',
        answers: ['Не менее 15', 'Не менее 5', 'Не менее 10', 'Не менее 8'],
        correctAnswers: ['Не менее 15'],
      },
      {
        code: '63705093',
        text:
          'Какая цветовая индикация индикаторных устройств, за исключением определенных случаев, имеет значение "нейтрально"? Укажите все правильные ответы.',
        answers: ['Белый', 'Зеленый', 'Синий', 'Желтый', 'Черный'],
        correctAnswers: ['Белый', 'Черный'],
      },
      {
        code: '63705094',
        text:
          'Какие требования к расстоянию от защитного элемента до реборды ролика на трассе БКД указаны верно? Выберите 2  варианта ответа.',
        answers: [
          'Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната',
          'Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм',
          'Расстояние от защитного элемента до реборды ролика не должно превышать 1/2 диаметра каната',
          'Расстояние от защитного элемента до реборды ролика должно составлять не более 10 мм',
        ],
        correctAnswers: [
          'Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната',
          'Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм',
        ],
      },
      {
        code: '63705095',
        text:
          'В каком случае разрешается выход на линию средства подвижного состава с недостаточным усилием зацепления после прохождения участка прицепления на наземной канатной дороге?',
        answers: [
          'В случае установки необходимых направляющих для каната и зажима',
          'В случае установки механических контрольных устройств, препятствующих отцеплению',
          'Не разрешается ни в каком случае',
          'В случае установки автоматического блокирующего устройства',
        ],
        correctAnswers: ['Не разрешается ни в каком случае'],
      },
      {
        code: '63705096',
        text:
          'Чему должно быть равно расстояние между двумя следующими друг за другом буксировочными устройствами? Выберите 2 варианта ответа.',
        answers: [
          'Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства',
          'На 30% больше, чем длина полностью вытянутой буксировочной штанги',
          'Не менее чем на 5% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства',
          'На 20% больше, чем длина полностью вытянутой буксировочной штанги',
        ],
        correctAnswers: [
          'Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства',
          'На 30% больше, чем длина полностью вытянутой буксировочной штанги',
        ],
      },
      {
        code: '63705097',
        text:
          'Какое утверждение, относящееся к ходу натяжной тележки КД, указано верно?',
        answers: [
          'Натяжные устройства при любых условиях нормальной эксплуатации КД должны достигать упоров',
          'При срабатывании устройства безопасности должно происходить замедление КД',
          'При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации',
          'Возврат в рабочее положение устройства безопасности должен производиться автоматически',
        ],
        correctAnswers: [
          'При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации',
        ],
      },
      {
        code: '63705098',
        text: 'Кем проводится ревизия при эксплуатации КД?',
        answers: [
          'Эксплуатирующей организацией',
          'Проектной организацией',
          'Ростехнадзором',
          'Специализированной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63705099',
        text:
          'В каком месте допускается не располагать кнопки аварийной остановки в непосредственной близости от персонала на пассажирских подвесных канатных дорогах и наземных канатных дорогах?',
        answers: [
          'На промежуточных остановках',
          'На пультах управления подвижного состава',
          'На станциях',
          'В машинном помещении',
        ],
        correctAnswers: ['В машинном помещении'],
      },
      {
        code: '63705100',
        text:
          'Какая максимальная скорость движения двухканатных маятниковых канатных дорог с проводником при прохождении опор?',
        answers: ['12 м/с', '10 м/с', '8,5 м/с', '6 м/с'],
        correctAnswers: ['10 м/с'],
      },
      {
        code: '63705101',
        text:
          'Какое допускается значение замедления на приводном шкиве при аварийной остановке маятниковой КД?',
        answers: [
          'Не более 3,0 м/с2',
          'Не более 4,0 м/с2',
          'Не более 3,5 м/с2',
          'Не более 5,0 м/с2',
        ],
        correctAnswers: ['Не более 3,0 м/с2'],
      },
      {
        code: '63705102',
        text:
          "Какой минимальный просвет должен быть на линии ППКД при отклонении подвижного состава в поперечном направлении на 11°30' между крайней точкой подвижного состава и иными элементами КД внутри колеи КД?",
        answers: ['0,3 м', '0,25 м', '0,15 м', '0,2 м'],
        correctAnswers: ['0,3 м'],
      },
      {
        code: '63705103',
        text:
          'Каким должен быть коэффициент запаса надежности сцепления для винтовых зажимов КД?',
        answers: ['3', '2,5', '1,5', '1,75'],
        correctAnswers: ['3'],
      },
      {
        code: '63705104',
        text:
          'В каких местах канатных дорог должны быть установлены индикаторные устройства?',
        answers: [
          'Только на пункте управления',
          'Только на наблюдательных пунктах КД',
          'Только на пульте оператора',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63705105',
        text: 'С чем допускаются пересечения БКД на одном уровне?',
        answers: [
          'Пересечение БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускается',
          'Пересечение БКД на одном уровне не допускается только с трассами для катания',
          'Пересечение БКД на одном уровне не допускается только с путями передвижения людей',
          'Пересечение БКД на одном уровне не допускается только с путями передвижения транспорта',
        ],
        correctAnswers: [
          'Пересечение БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускается',
        ],
      },
      {
        code: '63705106',
        text:
          'Распространяется ли действие Правил безопасности пассажирских канатных дорог и фуникулеров на безопорные буксировочные канатные дороги?',
        answers: [
          'Да, распространяется',
          'Нет, действие Правил распространяется только на подвесные канатные дороги',
          'Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами и подвесные канатные дороги',
          'Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами, подвесные канатные дороги и фуникулеры',
        ],
        correctAnswers: ['Да, распространяется'],
      },
      {
        code: '63705107',
        text:
          'С какой периодичностью осуществляют перестановку зажимов КД с неотцепляемым подвижным составом?',
        answers: [
          'Каждые 500 моточасов функционирования КД',
          'Каждые 300 моточасов функционирования КД',
          'Каждые 200 моточасов функционирования КД',
          'Каждые 100 моточасов функционирования КД',
        ],
        correctAnswers: ['Каждые 200 моточасов функционирования КД'],
      },
      {
        code: '63705108',
        text:
          'Какую величину составляет максимальное значение угла поперечного раскачивания для кабин с проводником и кабин, оснащенных дистанционным управлением, с помощью которого можно остановить дорогу или регулировать скорость движения, при скорости меньше или равной 7 м/с?',
        answers: ["6°50'", "11°30'", "8°35'", "14°20'"],
        correctAnswers: ["6°50'"],
      },
      {
        code: '63705109',
        text: 'Какой знак не устанавливается в зоне посадки кресельных ППКД?',
        answers: [
          '"Поднять носки лыж"',
          '"Опустить скобу безопасности или защитный колпак"',
          '"Не прыгать из кресла"',
          '"Не раскачиваться"',
        ],
        correctAnswers: ['"Поднять носки лыж"'],
      },
      {
        code: '63705110',
        text:
          'Какое из перечисленных требований в зоне посадки лыжников с посадочным конвейером на кресельных канатных дорогах с неотцепляемым подвижным составом указано верно?',
        answers: [
          'Необходимо установить длину посадочного конвейера и устройства регулировки входов и их взаимодействие так, чтобы посадка заканчивалась не дальше чем за 2 м до конца ленты конвейера',
          'Скорость конвейера должна зависеть от скорости несуще-тягового каната и не должна превышать 1,5 м/с',
          'Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру',
          'При неисправности автоматического управления входами использование посадочного конвейера допускается с разрешения лица, ответственного за безопасное производство работ',
        ],
        correctAnswers: [
          'Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру',
        ],
      },
      {
        code: '63705111',
        text:
          'Какие требования к размерам и размещению окон в кабине кольцевых КД указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Открывающиеся окна в кабине должны быть расположены на высоте не менее 1,1 м от пола',
          'Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,2 м',
          'Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,3 м',
          'Окна в кабине должны быть открывающимися и расположены на высоте не менее 0,8 м от пола',
        ],
        correctAnswers: [
          'Открывающиеся окна в кабине должны быть расположены на высоте не менее 1,1 м от пола',
          'Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,2 м',
        ],
      },
      {
        code: '63705112',
        text: 'Каким устройством должны быть оснащены все типы канатных дорог?',
        answers: [
          'Счетчиком моточасов',
          'Анемометром или анеморумбометром',
          'Аварийным приводом',
          'Аварийным тормозом',
        ],
        correctAnswers: ['Счетчиком моточасов'],
      },
      {
        code: '63705113',
        text:
          'Чему равен минимальный коэффициент запаса прочности для спасательных и эвакуационных канатов на канатных лебедках?',
        answers: ['5', '3', '2,75', '3,75'],
        correctAnswers: ['5'],
      },
      {
        code: '63705114',
        text:
          'Какая устанавливается минимальная скорость пассажирских подвесных канатных дорог и фуникулеров при плавном регулировании скорости в пределах ее паспортных величин?',
        answers: ['0,75 м/с', '0,5 м/с', '1,5 м/с', '1,0 м/с'],
        correctAnswers: ['0,5 м/с'],
      },
      {
        code: '63705115',
        text:
          'Какая устанавливается максимальная рабочая скорость движения буксировочных устройств?',
        answers: ['6 м/с', '5 м/с', '4 м/с', '3 м/с'],
        correctAnswers: ['4 м/с'],
      },
      {
        code: '63705116',
        text:
          'Какие действия из перечисленных включает в себя ревизия при эксплуатации КД? Укажите все правильные ответы.',
        answers: [
          'Чистка, консервация, смазка деталей',
          'Регулировка',
          'Замена деталей',
          'Контроль и определение фактического состояния в целом КД',
          'Измерение фактического состояния деталей, узлов, конструкций КД',
        ],
        correctAnswers: [
          'Контроль и определение фактического состояния в целом КД',
          'Измерение фактического состояния деталей, узлов, конструкций КД',
        ],
      },
      {
        code: '63705117',
        text:
          'На каких канатных дорогах не требуется установка системы информирования пассажиров?',
        answers: [
          'На канатных дорогах с кольцевым движением',
          'На маятниковых канатных дорогах',
          'На фуникулерах',
          'На буксировочных канатных дорогах',
        ],
        correctAnswers: ['На буксировочных канатных дорогах'],
      },
      {
        code: '63705118',
        text:
          'В каких местах БКД должны быть установлены ограждения для предотвращения травмирования персонала?',
        answers: [
          'Только со стороны буксировочных устройств',
          'Только со стороны натяжного груза',
          'Только со стороны подвижных частей, механизмов и элементов БКД при их неконтролируемом движении в зонах станций БКД',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63705119',
        text:
          'На каком минимальном расстоянии над поверхностью снежного покрова по всей длине трассы должна обеспечить прохождение опорных тарелок и траверс высота прохождения тягового каната у БКД с барабанными буксировочными устройствами?',
        answers: ['2,3 м', '2,1 м', '1,8 м', '2,2 м'],
        correctAnswers: ['2,3 м'],
      },
      {
        code: '63705120',
        text:
          'Каким должно быть боковое безопасное расстояние на станциях кресельных канатных дорог между свободно висящим креслом без пассажиров и неподвижными деталями пассажирской подвесной канатной дороги на уровне сиденья со стороны оси дороги?',
        answers: [
          'Не более 0,9 м от края кресла',
          'Не более 1,2 м от края кресла',
          'Не менее 0,6 м от края кресла',
          'Не менее 0,8 м от края кресла',
        ],
        correctAnswers: ['Не менее 0,8 м от края кресла'],
      },
      {
        code: '63705121',
        text:
          'Какое из перечисленных требований к наклонному съезду, устраиваемому при перевозке лыжников после зоны высадки, указано верно? Выберите все правильные варианты ответа.',
        answers: [
          'Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,7 м',
          'Для КД с креслами с неотцепляемыми зажимами наклонный спуск должен начинаться не менее чем за 2,5 с до прохождения креслом оси обводного шкива',
          'Уклон съезда должен составлять от 10% до 15%',
          'Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,5 м',
          'Для КД с креслами с неотцепляемыми зажимами наклонный спуск должен начинаться не менее чем за 1,5 с до прохождения креслом оси обводного шкива',
        ],
        correctAnswers: [
          'Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,5 м',
          'Для КД с креслами с неотцепляемыми зажимами наклонный спуск должен начинаться не менее чем за 1,5 с до прохождения креслом оси обводного шкива',
        ],
      },
      {
        code: '63705122',
        text:
          'В течение какого времени должен приводиться в действие аварийный привод?',
        answers: [
          'В течение 15 минут',
          'В течение 5 минут',
          'В течение 30 минут',
          'В течение 60 минут',
        ],
        correctAnswers: ['В течение 15 минут'],
      },
      {
        code: '63705123',
        text:
          'Какие перечисленные размеры проходов и рабочих зон канатных дорог указаны верно? Укажите все правильные ответы.',
        answers: [
          'Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м',
          'Контрольные зоны для предъявления проездных документов и входы для КД с креслами должны быть шириной не менее 1,2 м',
          'Высота проходов для пассажиров под транспортными путями и рабочими зонами должна быть не менее 3,0 м',
          'На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м',
        ],
        correctAnswers: [
          'Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м',
          'На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м',
        ],
      },
      {
        code: '63705124',
        text:
          'Какой максимальный фактический продольный уклон рельсового пути допускается для фуникулеров?',
        answers: ['11°30’', '30’', '60°', '17’'],
        correctAnswers: ['60°'],
      },
      {
        code: '63705125',
        text:
          'В каком случае оператор должен присутствовать у пульта управления КД?',
        answers: [
          'Оператор должен постоянно присутствовать у пульта управления КД',
          'Оператор должен присутствовать у пульта управления только во время запуска КД',
          'Оператор должен присутствовать у пульта управления только во время проведения спасательной операции',
          'Оператор должен присутствовать у пульта управления, только когда на дороге находятся пассажиры',
        ],
        correctAnswers: [
          'Оператор должен постоянно присутствовать у пульта управления КД',
        ],
      },
      {
        code: '63705126',
        text:
          'Каким требованиям должно соответствовать оборудование канатных дорог, приобретаемых за рубежом?',
        answers: [
          'Требованиям Правил безопасности пассажирских канатных дорог и фуникулеров',
          'Требованиям Европейских норм, действующих в области канатных дорог',
          'Требованиям норм Таможенного союза',
          'Требованиям норм страны-производителя',
        ],
        correctAnswers: [
          'Требованиям Правил безопасности пассажирских канатных дорог и фуникулеров',
        ],
      },
      {
        code: '63705127',
        text:
          'В каких случаях при эксплуатации КД устанавливаются защитные сетки? Выберите два варианта ответа.',
        answers: [
          'При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка',
          'При расстоянии до земли более 3 м',
          'При перепаде высот в зоне высадки более чем на 0,8 м в начале наклонного участка',
          'При расстоянии до земли более 2 м',
        ],
        correctAnswers: [
          'При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка',
          'При расстоянии до земли более 3 м',
        ],
      },
      {
        code: '63705128',
        text:
          'В каком случае допускается ручное управление гидравлической системой натяжения для поддержания давления?',
        answers: [
          'На аварийном приводе',
          'На вспомогательном приводе',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['На аварийном приводе'],
      },
      {
        code: '63705129',
        text:
          'В каких случаях допускается увеличивать высоту от низа подвижного состава ППКД с открытыми креслами до земли на 10 м от максимально допустимой?',
        answers: [
          'На отдельных участках протяженностью не более 1/3 пролета',
          'В пролетах длиной до 100 м',
          'В пролетах длиной более 200 м',
          'Ни в каких случаях',
        ],
        correctAnswers: [
          'На отдельных участках протяженностью не более 1/3 пролета',
        ],
      },
      {
        code: '63705130',
        text:
          'Какое минимальное расстояние от низшей точки подвижного состава ППКД до земли (с учетом снежного покрова) допускается в зонах, где исключено присутствие людей?',
        answers: ['2 м', '1,5 м', '2,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63705131',
        text:
          'В каком случае разрешено использовать навесные или приставные лестницы для обслуживания балансиров вместо устройства стационарных лестниц и площадок у опор буксировочных канатных дорог?',
        answers: [
          'У опор БКД с высотой прохождения тягового каната менее 5 м',
          'В случае проведения работ в присутствии лица, ответственного за безопасное производство работ',
          'У опор БКД с высотой прохождения тягового каната менее 4 м',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'У опор БКД с высотой прохождения тягового каната менее 4 м',
        ],
      },
      {
        code: '63705132',
        text:
          'Какой максимальный срок установлен для проведения эвакуации на канатной дороге?',
        answers: ['30 минут', '3 часа', '4 часа', '5 часов'],
        correctAnswers: ['4 часа'],
      },
      {
        code: '63705133',
        text:
          'С каким ускорением главный привод должен обеспечить пуск дороги, как порожней, так и загруженной, для всех ППКД и НКД с ручным управлением?',
        answers: [
          'Не более 0,5 м/с2',
          'Не более 0,75 м/с2',
          'Не более 0,55 м/с2',
          'Не более 0,6 м/с2',
        ],
        correctAnswers: ['Не более 0,5 м/с2'],
      },
      {
        code: '63705134',
        text:
          'В какие сроки проводятся испытания на стягивание для отцепляемых зажимов?',
        answers: [
          'После отработки 15 000 моточасов, но не позднее чем через 10 лет',
          'После отработки 9 000 моточасов, но не позднее чем через 10 лет',
          'После отработки 9 000 моточасов, но не позднее чем через 5 лет',
          'После отработки 5 000 моточасов, но не позднее чем через 3 года',
        ],
        correctAnswers: [
          'После отработки 9 000 моточасов, но не позднее чем через 5 лет',
        ],
      },
      {
        code: '63705135',
        text:
          'Какое из перечисленных требований к направляющим роликам пассажирских подвесных канатных дорог указано верно? Укажите все правильные ответы.',
        answers: [
          'Выступание реборды ролика над его бандажом должно быть не менее 2 мм и составлять 1/10 от диаметра несуще-тягового каната',
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
          'Глубина канавки в бандаже ролика должна быть не менее 1/15 от диаметра несуще-тягового каната',
          'Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм',
        ],
        correctAnswers: [
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
          'Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм',
        ],
      },
      {
        code: '63705136',
        text:
          'Какие из перечисленных значений соответствуют тяговому канату диаметром свыше 16 мм на буксировочных канатных дорогах?',
        answers: [
          "Минимальный диаметр роликов составляет 150 мм, а перегиб каната на одном ролике - 9°30'",
          "Минимальный диаметр роликов составляет 200 мм, а перегиб каната на одном ролике - 8°30'",
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике - 5°30'",
          "Минимальный диаметр роликов составляет 300 мм, а перегиб каната на одном ролике - 3°30'",
        ],
        correctAnswers: [
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике - 5°30'",
        ],
      },
      {
        code: '63705137',
        text:
          'С какой периодичностью проводятся повторные проверки знаний персонала?',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63705138',
        text:
          'Какое допускается максимальное значение угла поперечного раскачивания для груженых открытых кабин и кресел с учетом диапазона досягаемости рукой, ногой пассажира и лыжами?',
        answers: ["11°30'", "12°30'", "11°50'", "13°30'"],
        correctAnswers: ["11°30'"],
      },
      {
        code: '63705139',
        text:
          'Куда заносятся сведения о результатах и сроке следующего технического освидетельствования?',
        answers: [
          'В вахтенный журнал',
          'В журнал ремонта и осмотров',
          'В паспорт дороги',
          'В приказ о вводе КД в эксплуатацию',
        ],
        correctAnswers: ['В паспорт дороги'],
      },
      {
        code: '63705140',
        text:
          'Какой из перечисленных документов не должен храниться на рабочем месте оператора канатных дорог (далее - КД)?',
        answers: [
          'Журнал ремонта и осмотров или контрольные листы осмотров',
          'Указания по действию персонала в экстренных ситуациях',
          'Производственные инструкции персонала КД',
          'Руководство (руководства) по эксплуатации КД',
        ],
        correctAnswers: ['Руководство (руководства) по эксплуатации КД'],
      },
      {
        code: '63705141',
        text:
          'Какой блок считается как один отцепляемый зажим на пассажирских подвесных канатных дорогах?',
        answers: [
          'Если между центрами двух зажимов расстояние меньше 30 диаметров каната',
          'Если между центрами двух зажимов расстояние меньше 15 диаметров каната',
          'Если между центрами двух зажимов расстояние меньше 25 диаметров каната',
          'Никакой блок не может считаться за один зажим',
        ],
        correctAnswers: [
          'Если между центрами двух зажимов расстояние меньше 15 диаметров каната',
        ],
      },
      {
        code: '63705142',
        text:
          'На каких БКД должно быть установлено устройство контроля скорости, останавливающее КД при превышении номинальной скорости на 10%?',
        answers: [
          'На БКД при номинальной скорости свыше 3 м/с',
          'На БКД, расположенных на 5 м и выше над землей',
          'На БКД при номинальной скорости свыше 5 м/с',
          'На БКД, угол наклона которых составляет более 5°',
        ],
        correctAnswers: ['На БКД при номинальной скорости свыше 3 м/с'],
      },
      {
        code: '63705143',
        text:
          'Какие требования предъявляются к опорным элементам несущих канатов ППКД?',
        answers: [
          'Опорные элементы должны быть регулируемыми',
          'Опорные элементы не должны препятствовать продольному раскачиванию подвижного состава',
          'Опорные элементы не должны препятствовать поперечному раскачиванию подвижного состава',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63705144',
        text: 'В каком случае проводятся статические испытания?',
        answers: [
          'При проведении ежегодного технического освидетельствования',
          'При проведении ежемесячной ревизии',
          'При проведении еженедельной проверки',
          'При проведении полного технического освидетельствования',
        ],
        correctAnswers: [
          'При проведении полного технического освидетельствования',
        ],
      },
      {
        code: '63705145',
        text:
          'В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?',
        answers: [
          'На участке длиной, равной 30dк, одна десятая часть проволок наружного слоя замка оборвана',
          'Имеется выход из замка одной и более проволок наружного слоя без ее обрыва',
          'На участке длиной, равной 6dк, имеются разрывы двух и более смежных проволок наружного слоя',
          'Оборванные концы наружных проволок каната выступают',
        ],
        correctAnswers: [
          'На участке длиной, равной 30dк, одна десятая часть проволок наружного слоя замка оборвана',
        ],
      },
      {
        code: '63705146',
        text:
          'Какие из перечисленных видимых признаков повреждения или ухудшения состояния тягового каната ББКД, выполненного из синтетических материалов, являются критерием браковки каната?',
        answers: [
          'Только изменение диаметра прядей',
          'Только изменение формы прядей',
          'Только изменение расположения прядей',
          'Все перечисленные изменения',
        ],
        correctAnswers: ['Все перечисленные изменения'],
      },
      {
        code: '63705147',
        text:
          'Какой минимальной величине должна соответствовать длина счаленного участка тяговых и несуще-тяговых канатов относительно диаметра каната (dk)?',
        answers: ['1200dk', '1000dk', '1300dk', '3000dk'],
        correctAnswers: ['1200dk'],
      },
      {
        code: '63705148',
        text:
          'Какое максимальное расстояние необходимо выдерживать до земли в зоне стабилизации и в зоне безопасности?',
        answers: [
          'В зоне стабилизации - не менее 2 м, в зоне безопасности - не более 3,5 м',
          'В зоне стабилизации - не более 1 м, в зоне безопасности - не более 2,5 м',
          'В зоне стабилизации - не менее 2,5 м, в зоне безопасности - не менее 4 м',
          'В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м',
        ],
        correctAnswers: [
          'В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м',
        ],
      },
      {
        code: '63705149',
        text:
          'Какие из перечисленных требований к индикаторному устройству, обеспечивающему индикацию нахождения средств подвижного состава на трассе маятниковых КД, указаны неверно?',
        answers: [
          'Индикаторное устройство должно иметь шкалу с соответствующими делениями, где расстояние от средства подвижного состава до приводной станции выражено в метрах',
          'Индикаторное устройство должно распознавать направление движения, в том числе при его изменении',
          'Все перечисленные требования указаны верно',
          'Индикаторное устройство положения подвижного состава должно работать вне зависимости от типа используемого привода',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63705150',
        text:
          'Какое из перечисленных требований к приводам пассажирских подвесных дорог и наземных канатных дорог указано верно? Укажите все правильные ответы.',
        answers: [
          'Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации',
          'Переключение приводов должно происходить при включенной КД',
          'Одновременная работа аварийного и главного приводов допускается требованиями технической документации',
          'Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку',
          'Вспомогательный привод должен обеспечивать начало движения с ускорением не более 0,3 м/c² при максимальной нагрузке',
        ],
        correctAnswers: [
          'Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации',
          'Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку',
        ],
      },
      {
        code: '63705151',
        text:
          'В какие сроки проводится проверка работоспособности аварийного двигателя, контроль уровней охлаждающей жидкости, масла, топлива?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежегодно'],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63705152',
        text:
          'Каким должно быть расстояние по вертикали от середины передней кромки нагруженного кресла до земли с учетом снежного покрова в зоне посадки-высадки у кресельных канатных дорог?',
        answers: ['40 ± 5 см', '50 ± 5 см', '37 ± 5 см', '46 ± 5 см'],
        correctAnswers: ['50 ± 5 см'],
      },
      {
        code: '63705153',
        text:
          'Какой угол отклонения в плане на опоре допускается для несущих канатов ППКД?',
        answers: ['17’', '30’', '25’', '14°17’'],
        correctAnswers: ['17’'],
      },
      {
        code: '63705154',
        text:
          'При каком уклоне проходов для пассажиров и персонала, используемых пешеходами, они должны быть оборудованы лестницами?',
        answers: [
          'Более 3°',
          'Проходы для пассажиров и персонала, используемые пешеходами, должны быть оборудованы лестницами при любом уклоне',
          'Более 4°',
          'Более 5°',
        ],
        correctAnswers: ['Более 5°'],
      },
      {
        code: '63705155',
        text:
          'Какое допускается значение замедления на приводном шкиве при остановке ППКД в нормальном (рабочем) режиме?',
        answers: [
          'Не более 1,0 м/с2',
          'Не более 2,0 м/с2',
          'Не более 1,5 м/с2',
          'Не более 1,8 м/с2',
        ],
        correctAnswers: ['Не более 1,0 м/с2'],
      },
      {
        code: '63705156',
        text:
          'В какие сроки проводится проверка средств связи на канатной дороге?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежегодно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63705157',
        text:
          'Какое условие может не выполняться при эксплуатации ППКД и НКД в темное время суток?',
        answers: [
          'Станции посадки и высадки пассажиров должны быть обеспечены эксплуатационным освещением и, в случае его отказа, аварийным освещением',
          'Эксплуатационное и аварийное освещение должно обеспечивать освещение подвижного состава на подходах к станции и в зонах замедления подвижного состава',
          'Подвижной состав ППКД, кроме кресел, должен иметь внутреннее освещение',
          'Должны быть освещены фундаменты станций и опор',
        ],
        correctAnswers: ['Должны быть освещены фундаменты станций и опор'],
      },
      {
        code: '63705158',
        text:
          'В каком случае контроль расстояния между единицами подвижного состава на пассажирских подвесных канатных дорогах с кольцевым движением отцепляемого на станциях подвижного состава осуществляет обслуживающий персонал?',
        answers: [
          'В случае отсутствия в зонах станции автоматических устройств контроля',
          'В случае движения подвижного состава со скоростью не более 5 м/с',
          'Ни в каком случае, расстояние между единицами подвижного состава контролируется только автоматически',
          'В случае неисправности посадочного конвейера',
        ],
        correctAnswers: [
          'В случае отсутствия в зонах станции автоматических устройств контроля',
        ],
      },
      {
        code: '63705159',
        text:
          'Сколько должна составлять длина зоны посадки пешеходов и лыжников у КД?',
        answers: [
          'От 1,5 до 2,5 м',
          'От 2,5 до 3,5 м',
          'От 3,5 до 4,0 м',
          'От 2,5 до 5,0 м',
        ],
        correctAnswers: ['От 2,5 до 3,5 м'],
      },
      {
        code: '63705160',
        text:
          'Какие требования к участкам отцепления-прицепления зажима и каната у НКД указаны неверно?',
        answers: [
          'Участки отцепления-прицепления зажима и каната должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 30%',
          'Участники должны обеспечивать отцепление и прицепление зажима вне зависимости от скорости движения',
          'Участники должны иметь защиту от атмосферного воздействия',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Участки отцепления-прицепления зажима и каната должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 30%',
        ],
      },
      {
        code: '63705161',
        text:
          'Какой стаж работы специалистом на канатной дороге должен иметь ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД?',
        answers: [
          'Не менее трех лет',
          'Не менее шести лет',
          'Не менее одного года',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее трех лет'],
      },
      {
        code: '63705162',
        text:
          'С каким ускорением должно осуществляться начало движения БКД вне зависимости от нагрузки?',
        answers: [
          'Не более 0,4 м/с2',
          'Не более 0,5 м/с2',
          'Не более 0,6 м/с2',
          'Не более 0,7 м/с2',
        ],
        correctAnswers: ['Не более 0,4 м/с2'],
      },
      {
        code: '63705163',
        text:
          'Какому значению должна соответствовать дополнительная (резервная) длина каната для возможности перемещения несущих канатов?',
        answers: [
          'Пятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 3 м',
          'Трехкратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м',
          'Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м',
          'Десятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 10 м',
        ],
        correctAnswers: [
          'Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м',
        ],
      },
      {
        code: '63705164',
        text:
          'В каком случае допустимый коэффициент трения для приводных шкивов с резиновой футеровкой буксировочных канатных дорог составляет 0,22?',
        answers: [
          'Только при использовании отцепляемых зажимов с учетом кручения каната (втулки поводковых патронов)',
          'Только при использовании фиксированных зажимов без учета кручения каната',
          'Только при диаметре тягового каната до 18 мм и при использовании фиксированных зажимов без учета кручения каната',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Только при использовании фиксированных зажимов без учета кручения каната',
        ],
      },
      {
        code: '63705165',
        text:
          'Какое из перечисленных требований к посадочному конвейеру является верным?',
        answers: [
          'При движении КД в обратном направлении посадочный конвейер должен двигаться с замедлением не более 0,5 м/с²',
          'Ширина посадочного конвейера должна быть меньше ширины подвижного состава',
          'Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с2',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с2',
        ],
      },
      {
        code: '63705166',
        text:
          'В каком случае работа сигнала готовности на пульте управления при эксплуатации ППКД и НКД неисправна?',
        answers: [
          'Сигнал гаснет самостоятельно, если по истечении 60 секунд не поступило сигнала начала движения',
          'Сигнал готовности действует в системе управления в случае готовности КД к работе',
          'Сигнал готовности гаснет самостоятельно, если по истечении 30 секунд не поступило сигнала начала движения',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Сигнал гаснет самостоятельно, если по истечении 60 секунд не поступило сигнала начала движения',
        ],
      },
      {
        code: '63705167',
        text:
          'Какое должно быть номинальное значение интервала времени прибытия на станцию подвижного состава у ППКД с отцепляемым составом?',
        answers: [
          'Не менее 5 секунд',
          'Не менее 4 секунд',
          'Не менее 3 секунд',
          'Не нормируется',
        ],
        correctAnswers: ['Не менее 5 секунд'],
      },
      {
        code: '63705168',
        text:
          'При каком условии КД допускается к работе по перевозке пассажиров?',
        answers: [
          'Только при выполнении проверки и пробного пуска КД',
          'Только при наличии персонала на рабочих местах',
          'Только при выполнении условий безопасной перевозки пассажиров на КД',
          'При всех перечисленных условиях',
        ],
        correctAnswers: ['При всех перечисленных условиях'],
      },
      {
        code: '63705169',
        text: 'В каком случае должна быть проведена ежемесячная ревизия КД?',
        answers: [
          'Если КД не эксплуатировали более недели',
          'Если КД не эксплуатировали более 10 дней',
          'Если КД не эксплуатировали более одного месяца',
          'Если КД не эксплуатировали более двух недель',
        ],
        correctAnswers: ['Если КД не эксплуатировали более одного месяца'],
      },
      {
        code: '63705170',
        text:
          'В каком случае в плане мероприятий по эвакуации пассажиров может не предусматриваться спасение людей методом эвакуации с подвижного состава?',
        answers: [
          'Если в кабине или вагоне находится проводник',
          'Если расстояние от низа подвижного состава до земли не превышает 3 м',
          'Если в кабине или вагоне имеются эвакуационные парашюты',
          'Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий',
        ],
        correctAnswers: [
          'Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий',
        ],
      },
      {
        code: '63705171',
        text:
          'Какое минимальное расстояние между буксировочными дорожками допускается при параллельном прохождении линий БКД и ББКД?',
        answers: ['2 м', '3,2 м', '8 м', '15 м'],
        correctAnswers: ['15 м'],
      },
      {
        code: '63705172',
        text:
          'В каком из перечисленных случаев максимальная скорость движения двухканатных кольцевых дорог без проводника составляет 6 м/с?',
        answers: [
          'При прохождении опор с одним несущим канатом',
          'При прохождении опор с двумя несущими канатами',
          'При посадке/высадке на станциях',
          'При прохождении пролетов',
        ],
        correctAnswers: ['При прохождении опор с одним несущим канатом'],
      },
      {
        code: '63705173',
        text:
          'Какие характеристики фиксированного зажима КД должны быть указаны в руководстве по эксплуатации?',
        answers: [
          'Только номинальный диаметр каната',
          'Только величина момента затяжки зажима',
          'Только допустимый износ зажимных губок',
          'Все перечисленные характеристики',
        ],
        correctAnswers: ['Все перечисленные характеристики'],
      },
      {
        code: '63705174',
        text:
          'При каком увеличении скорости относительно номинальной должно срабатывать устройство контроля превышения скорости при работе на аварийном приводе на пассажирских подвесных канатных дорогах и фуникулерах?',
        answers: ['На 5%', 'На 20%', 'На 15%', 'На 10%'],
        correctAnswers: ['На 20%'],
      },
      {
        code: '63705175',
        text:
          'Сколько должен составлять минимальный габарит от зон досягаемости рукой до препятствий по горизонтали и вертикали, а также между зонами досягаемости рукой движущихся навстречу вагонов при эксплуатации НКД?',
        answers: ['0,1 м', '0,2 м', '0,3 м', '0,4 м'],
        correctAnswers: ['0,1 м'],
      },
      {
        code: '63705176',
        text:
          'Кем принимается решение и вносится запись в паспорт КД о вводе ее в эксплуатацию?',
        answers: [
          'Оператором канатной дороги',
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
          'Ответственным за исправное состояние канатной дороги',
          'Представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
        ],
      },
      {
        code: '63705177',
        text:
          'Чем должны быть оборудованы подъездные пути для доступа пассажиров в креслах-каталках?',
        answers: [
          'Проходами в контрольных зонах для предъявления проездных документов и входами для КД шириной не менее 1,9 м',
          'Пандусом с уклоном до 10° с горизонтальными площадками длиной 2 м через каждые 10 м',
          'Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок',
          'Порогами высотой не более 0,2 м',
        ],
        correctAnswers: [
          'Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок',
        ],
      },
      {
        code: '63705178',
        text:
          'Какой минимальный коэффициент устойчивости подвижного состава во всех направлениях с учетом неблагоприятного действия на него нагрузок должен быть с учетом силы ветра при движении подвижного состава?',
        answers: ['2,2', '1,4', '2', '1'],
        correctAnswers: ['2'],
      },
      {
        code: '63705179',
        text:
          'Какие из перечисленных требований к нанесению надписей и информационным знакам на КД указаны неверно?',
        answers: [
          'Опоры КД нумеруются по порядку, начиная с верхней опоры',
          'На элементах КД должны быть нанесены четкие и нестираемые надписи о видах опасности',
          'Информационные знаки следует устанавливать в местах КД, где существует угроза опасности',
          'Опоры КД нумеруются по порядку, начиная с нижней опоры',
        ],
        correctAnswers: [
          'Опоры КД нумеруются по порядку, начиная с верхней опоры',
        ],
      },
      {
        code: '63705180',
        text:
          'В каком случае максимальная скорость движения для кольцевых канатных дорог (КД) с неотцепляемыми креслами составляет 2 м/с?',
        answers: [
          'При перевозке пешеходов для КД с более чем двухместными креслами',
          'При перевозке пешеходов для КД с двухместными креслами',
          'При перевозке лыжников для КД с трех- или четырехместными креслами',
          'При перевозке лыжников для КД с двухместными креслами',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63705181',
        text: 'Какой знак не устанавливается в зоне высадки кресельных ППКД?',
        answers: [
          '"Езда "слаломом" запрещена"',
          '"Поднять носки лыж"',
          '"Открыть скобу безопасности или защитный колпак"',
          '"Место высадки"',
        ],
        correctAnswers: ['"Езда "слаломом" запрещена"'],
      },
      {
        code: '63705182',
        text:
          'Какие из перечисленных обязанностей возлагаются на лицо, ответственное за исправное состояние и безопасную эксплуатацию КД?',
        answers: [
          'Только проведение тренировок вспомогательного персонала, привлекаемого при проведении эвакуации и борьбы с пожаром',
          'Только обеспечение выполнения персоналом своих обязанностей согласно инструкции',
          'Только обеспечение обслуживающего персонала нормативно-технической документацией',
          'Все перечисленные обязанности',
        ],
        correctAnswers: ['Все перечисленные обязанности'],
      },
      {
        code: '63705183',
        text:
          'Кто должен возобновлять движение дороги после ее остановки вследствие срабатывания устройства безопасности?',
        answers: [
          'Оператор с пульта управления',
          'Дежурный по станции или проводник в вагоне',
          'Ответственный за безопасную эксплуатацию КД',
          'Руководитель предприятия - владелец КД',
        ],
        correctAnswers: ['Оператор с пульта управления'],
      },
      {
        code: '63705184',
        text:
          'Что из перечисленного должны обеспечивать устройства безопасности контроля скорости подвижного состава, если они установлены, для маятниковых КД и КД с пульсирующим режимом работы?',
        answers: [
          'Только контроль фиксированных точек',
          'Только контроль равномерного (синхронного) хода, параллельного хода',
          'Только контроль установки в нулевое положение',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63705185',
        text:
          'Какой должен быть минимальный коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната?',
        answers: ['2', '4', '3', '5'],
        correctAnswers: ['3'],
      },
      {
        code: '63705186',
        text:
          'Какое значение не должно превышать максимальное расстояние от низшей точки подвижного состава до поверхности земли или воды для ППКД с закрытым подвижным составом?',
        answers: ['60 м', '70 м', '80 м', '75 м'],
        correctAnswers: ['60 м'],
      },
      {
        code: '63705187',
        text: 'Какой знак не устанавливается в зоне посадки БКД?',
        answers: [
          '"При падении немедленно покинуть буксировочную дорожку"',
          '"Взять лыжные палки в одну руку"',
          '"Запрещено применять стиль "слалом" при движении по дорожке"',
          '"Отпустить буксировочное устройство"',
        ],
        correctAnswers: ['"Отпустить буксировочное устройство"'],
      },
      {
        code: '63705188',
        text:
          'При перевозке двух детей, рост которых не превышает 1,25 м, как они должны располагаться на кресельной КД относительно сопровождающего?',
        answers: [
          'Перевозка двух детей с одним сопровождающим запрещается',
          'Дети должны располагаться справа от сопровождающего, при этом между ними не должно быть пустого места',
          'Дети должны располагаться слева от сопровождающего, при этом между ними не должно быть пустого места',
          'Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места',
        ],
        correctAnswers: [
          'Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места',
        ],
      },
      {
        code: '63705189',
        text:
          'Какая из перечисленных документаций поставляется (прилагается) с паспортом канатной дороги?',
        answers: [
          'Только ведомость на запчасти и инструменты',
          'Только паспорта на приборы и устройства безопасности',
          'Только руководство по эксплуатации КД',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63705190',
        text:
          'Какие меры безопасности предпринимаются при прохождении трассы для перемещения пассажиров БКД?',
        answers: [
          'Трассу необходимо оградить сеткой, высота которой должна быть на 1 м выше снежного покрова',
          'Трасса на мостиках должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия',
          'Трассу необходимо выделить (обозначить)',
        ],
        correctAnswers: [
          'Трасса на мостиках должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия',
        ],
      },
      {
        code: '63705191',
        text:
          'Какое значение имеет синяя цветовая индикация индикаторных устройств, за исключением определенных случаев?',
        answers: [
          'Аварийный случай',
          'Норма, безопасно',
          'Вынужденное отключение функций безопасности',
          'Нейтрально',
        ],
        correctAnswers: ['Вынужденное отключение функций безопасности'],
      },
      {
        code: '63705192',
        text:
          'В каком из перечисленных случаев максимальная скорость движения пульсирующих дорог с закрытыми неотцепляемыми кабинами составляет 1,3 м/с?',
        answers: [
          'На трассе с одним несуще-тяговым канатом',
          'Для полуоткрытых кабин и кресел на станциях',
          'Для полуоткрытых кабин и кресел на трассе',
          'На станциях при посадке-высадке',
        ],
        correctAnswers: ['Для полуоткрытых кабин и кресел на трассе'],
      },
      {
        code: '63705193',
        text:
          'Автоматический контроль каких режимов должен быть обеспечен на ППКД и НКД?',
        answers: [
          'Только отключение при превышении скорости',
          'Только срабатывание рабочих тормозов',
          'Всех перечисленных',
          'Только срабатывание аварийных тормозов',
          'Только отслеживание замедления движения',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63705194',
        text:
          'На какие дороги действие Правил безопасности пассажирских канатных дорог и фуникулеров не распространяется?',
        answers: [
          'На безопорные буксировочные канатные дороги',
          'На грузовые подвесные канатные дороги',
          'На канатные дороги, предназначенные для перемещения пассажиров в вагонах по наклонному рельсовому пути тяговым канатом',
          'На комбинированные канатные дороги, совмещающие свойства подвесных и буксировочных',
        ],
        correctAnswers: ['На грузовые подвесные канатные дороги'],
      },
      {
        code: '63705195',
        text: 'Какой допускается поперечный уклон буксировочной дорожки?',
        answers: [
          'Установленный технической документацией',
          'С одноместными буксировочными устройствами - 30°',
          'С двухместными буксировочными устройствами - 26°',
          'Поперечный уклон не допускается',
        ],
        correctAnswers: ['Поперечный уклон не допускается'],
      },
      {
        code: '63705196',
        text:
          'Какое из перечисленных требований к трассе наземных канатных дорог указано верно?',
        answers: [
          'Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²',
          'Радиус поворота пути на линии вне зон разъезда и приближения к станциям должен составлять не менее 30 м',
          'Динамические габариты трассы, определяемые габаритами подвижного состава, должны составлять +/- 0,5 м',
          'В аварийном режиме замедление не должно превышать 4 м/c²',
        ],
        correctAnswers: [
          'Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²',
        ],
      },
      {
        code: '63705197',
        text:
          'Какое из перечисленных условий для обеспечения надежности прилегания каната к балансиру на пассажирских подвесных канатных дорогах является неверным?',
        answers: [
          'Допускается уменьшение значений величин нагрузки в три раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов',
          "Для тяговых и несуще-тяговых канатов ППКД перегиб каната на одном ролике балансира должен быть не более 4°30'",
          'Нагрузка от несуще-тягового каната на балансир при наиболее неблагоприятных условиях нормальной работы ППКД должна быть не менее 2000 Н, а на опорный ролик - не менее 500 Н',
          'При превышении натяжения несуще-тягового каната на 40% нагрузка на нижний балансир должна оставаться положительной',
        ],
        correctAnswers: [
          'Допускается уменьшение значений величин нагрузки в три раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов',
        ],
      },
      {
        code: '63705198',
        text:
          'В какие сроки проводится проверка соединений канатов, крепление концов канатов?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежегодно'],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63705199',
        text:
          'Какое значение должен составлять диаметр счаленных (замкнутых) спасательных канатов?',
        answers: [
          'Не более 20 мм',
          'Не менее 5 мм',
          'Не менее 15 мм',
          'Не менее 10 мм',
        ],
        correctAnswers: ['Не менее 15 мм'],
      },
      {
        code: '63705200',
        text: 'В каком случае допускается эксплуатация канатной дороги?',
        answers: [
          'В случае если истек срок ежегодного или полного технического освидетельствования',
          'В случае если истек назначенный срок эксплуатации',
          'В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию',
          'В случае отсутствия ответственных лиц и (или) персонала, прошедших соответственно аттестацию в области промышленной безопасности и проверку знаний, необходимых для эксплуатации КД, ее осмотра и технического обслуживания',
        ],
        correctAnswers: [
          'В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию',
        ],
      },
      {
        code: '63705201',
        text:
          'Где должны храниться паспорт КД и иная техническая документация по КД?',
        answers: [
          'На рабочем месте ответственного за безопасную эксплуатацию КД',
          'На рабочем месте лица, ответственного за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД',
          'На рабочем месте ответственного за исправное состояние КД',
          'На рабочем месте оператора КД',
        ],
        correctAnswers: [
          'На рабочем месте ответственного за безопасную эксплуатацию КД',
        ],
      },
      {
        code: '63705202',
        text:
          'При какой минимальной скорости ветра запрещается посадка пассажиров и на станциях КД осуществляется высадка всех пассажиров из подвижного состава, если такие сведения отсутствуют в паспорте?',
        answers: ['15 м/с', '25 м/с', '22 м/с', '20 м/с'],
        correctAnswers: ['15 м/с'],
      },
      {
        code: '63705203',
        text:
          'При выполнении каких условий разрешается возобновление движения БКД после остановки?',
        answers: [
          'БКД остановлена и все электрическое оборудование для предварительного задания скорости находится в исходном положении',
          'Рабочий тормоз наложен (в случаях, когда его наличие предусмотрено конструкцией БКД)',
          'Получены сигналы готовности к эксплуатации со всех станций',
          'При выполнении всех перечисленных условий',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63705204',
        text: 'Какой знак не устанавливается в зоне высадки БКД?',
        answers: [
          '"Не выходить за пределы буксировочной дорожки"',
          '"Предварительное оповещение о месте высадки"',
          '"Отпустить буксировочное устройство"',
          '"Не задерживаться в месте высадки"',
        ],
        correctAnswers: ['"Не выходить за пределы буксировочной дорожки"'],
      },
      {
        code: '63705205',
        text:
          'Какая длина зажимных губок для фиксированных зажимов подвесных канатных дорог?',
        answers: ['Не более 1,5d', 'Не менее 2d', 'Не более 3d', 'Не более d'],
        correctAnswers: ['Не менее 2d'],
      },
      {
        code: '63705206',
        text:
          'Что должен сделать владелец опасного производственного объекта до ввода КД в эксплуатацию?',
        answers: [
          'Обеспечить проведение экспертизы промышленной безопасности КД',
          'Обеспечить проведение полного технического освидетельствования КД',
          'Обеспечить проведение ежегодного технического освидетельствования КД',
          'Обеспечить отработку КД в тестовом режиме с полной загрузкой не менее 1 месяца',
        ],
        correctAnswers: [
          'Обеспечить проведение экспертизы промышленной безопасности КД',
        ],
      },
      {
        code: '63705207',
        text: 'Какие требования к натяжным устройствам КД указаны неверно?',
        answers: [
          'Реборды натяжных шкивов должны выступать за футеровку не менее чем на 1/4 диаметра каната',
          'Коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната должен быть не менее 3',
          'Конструкции контргрузов и натяжных тележек должны исключать возможность схода, зажатия, опрокидывания, наклона, закручивания',
          'Для крепления концов несущих и натяжных канатов на тумбе должно быть предусмотрено не менее трех полных витков',
        ],
        correctAnswers: [
          'Реборды натяжных шкивов должны выступать за футеровку не менее чем на 1/4 диаметра каната',
        ],
      },
      {
        code: '63705208',
        text:
          'Какое из перечисленных требований к зонам посадки и высадки буксировочных канатных дорог (далее - БКД) и безопорных буксировочных канатных дорог (далее - ББКД) указано верно?',
        answers: [
          'В зоне высадки БКД с буксировочными устройствами барабанного типа барабаны должны проходить на высоте не менее 2,5 м от поверхности снежного покрова',
          'На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м',
          'После высадки пассажира вытяжной канат должен перехлестываться с конструкциями дороги и буксировочными устройствами на стороне спуска',
          'Расстояние от тягового каната БКД со штанговыми буксировочными устройствами до поверхности снежного покрова должно быть меньше длины собранного буксировочного устройства в соответствии с технической документацией',
        ],
        correctAnswers: [
          'На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м',
        ],
      },
      {
        code: '63705209',
        text:
          'Кем определяются численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется КД и ее структура?',
        answers: [
          'Проектной организацией',
          'Организацией, эксплуатирующей КД',
          'Организацией, имеющей лицензию на проведение экспертизы промышленной безопасности',
          'Ростехнадзором',
        ],
        correctAnswers: ['Организацией, эксплуатирующей КД'],
      },
      {
        code: '63705210',
        text:
          'Кто выдает документ о проверке сварных соединений, а также литых и кованых деталей канатных дорог на наличие внутренних дефектов материала неразрушающими методами контроля? Укажите все правильные ответы.',
        answers: [
          'Национальное агентство контроля сварки',
          'Лаборатория неразрушающего контроля',
          'Организация-изготовитель',
          'Эксплуатирующая организация',
        ],
        correctAnswers: [
          'Лаборатория неразрушающего контроля',
          'Организация-изготовитель',
        ],
      },
      {
        code: '63705211',
        text:
          'Какое требование должно быть выполнено при пересечении и сближении высоковольтной линии электропередачи с пассажирской подвесной канатной дорогой?',
        answers: [
          'Крепление мостков на опорах высоковольтной линии допускается при согласовании с владельцем ВЛ',
          'Расстояние от подвижных частей пассажирской подвесной канатной дороги до опор ВЛ не должно быть меньше 5 м',
          'Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД',
          'Угол пересечения в плане линии пассажирской подвесной канатной дороги и линии ВЛ не должен быть больше 20°',
        ],
        correctAnswers: [
          'Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД',
        ],
      },
      {
        code: '63705212',
        text:
          'На какие типы дорог распространяются требования к организации и проведению эвакуационных мероприятий?',
        answers: [
          'На маятниковые дороги',
          'На ППКД и НКД',
          'На фуникулеры',
          'На БКД и ББКД',
        ],
        correctAnswers: ['На ППКД и НКД'],
      },
      {
        code: '63705213',
        text:
          'С кем согласовывается изменение конструкции отдельных элементов или паспортных характеристик КД?',
        answers: [
          'С территориальным органом Ростехнадзора',
          'С экспертной организацией',
          'С организацией, выполнившей монтаж КД',
          'С разработчиком проекта, а в случае его отсутствия - со специализированной организацией',
        ],
        correctAnswers: [
          'С разработчиком проекта, а в случае его отсутствия - со специализированной организацией',
        ],
      },
      {
        code: '63705214',
        text:
          'Какое действие должно быть осуществлено первым при возникновении аварии или инцидента на КД?',
        answers: [
          'Дорога должна быть немедленно остановлена',
          'Должен быть проинформирован ответственный за исправное состояние и безопасную эксплуатацию КД',
          'Должны быть проинформированы пассажиры, находящиеся на канатной дороге',
          'Должен быть введен в действие план спасательной операции',
        ],
        correctAnswers: ['Дорога должна быть немедленно остановлена'],
      },
    ],
    63700: [
      {
        code: '63700000',
        text:
          'Какая должна быть ширина свободного прохода между выступающими частями смежных эскалаторов и их ограждениями, а также ширина боковых проходов у крайних эскалаторов при высоте не менее 1800 мм?',
        answers: [
          'Не менее 500 мм',
          'Не менее 300 мм',
          'Не менее 700 мм',
          'Не менее 900 мм',
        ],
        correctAnswers: ['Не менее 500 мм'],
      },
      {
        code: '63700001',
        text:
          'Какие требования к лестничному полотну эскалатора указаны неверно?',
        answers: [
          'Для обеспечения постоянного натяжения лестничного полотна следует предусматривать натяжное устройство',
          'Рабочую поверхность ступени необходимо выполнять в виде выступов и впадин с расположением их вдоль оси эскалатора',
          'Допускается применение пружин растяжения для натяжения цепей',
        ],
        correctAnswers: [
          'Допускается применение пружин растяжения для натяжения цепей',
        ],
      },
      {
        code: '63700002',
        text:
          'Какое из перечисленных требований к балюстраде эскалатора указано неверно?',
        answers: [
          'Конструкция балюстрады в местах, требующих технического обслуживания, должна выполняться в легкоразборном исполнении',
          'Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху',
          'Расстояние между верхними кромками боковых щитов балюстрады не должно превышать ширину ступени более чем на 200 мм',
          'Стыки фартуков не должны иметь перепадов более 0,5 мм и должно быть исключено их взаимное смещение',
        ],
        correctAnswers: [
          'Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху',
        ],
      },
      {
        code: '63700003',
        text:
          'Каким должно быть расстояние по вертикали от уровня настила ступеней эскалатора до потолка галереи, тоннеля или выступающих частей (балок, архитектурных украшений, осветительной арматуры) по всей длине и ширине эскалатора по наружным кромкам поручня?',
        answers: [
          'Не менее 2300 мм',
          'Не менее 2150 мм',
          'Не менее 2100 мм',
          'Не менее 2000 мм',
        ],
        correctAnswers: ['Не менее 2300 мм'],
      },
      {
        code: '63700004',
        text:
          'Что не входит в перечень документации, поставляемой с эскалатором изготовителем?',
        answers: [
          'Нормы оценки качества сварных соединений',
          'Руководство по эксплуатации',
          'Методика грузовых испытаний',
          'Техническое описание',
        ],
        correctAnswers: ['Нормы оценки качества сварных соединений'],
      },
      {
        code: '63700005',
        text:
          'Какие сведения не содержат эксплуатационные документы эскалатора метрополитена?',
        answers: [
          'Программу специальной подготовки персонала',
          'Критерии предельных состояний',
          'Назначенные показатели, срок службы, ресурс эскалатора',
          'Действия персонала по ликвидации последствий инцидента или аварии',
          'Указания по монтажу или сборке, наладке или регулировке, техническому обслуживанию и ремонту',
        ],
        correctAnswers: ['Программу специальной подготовки персонала'],
      },
      {
        code: '63700006',
        text:
          'Что запрещается организации, эксплуатирующей ОПО, на котором используется эскалатор, в целях обеспечения безопасных условий его эксплуатации?',
        answers: [
          'Организовывать проведение периодических осмотров, технического обслуживания и ремонтов эскалаторов',
          'Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО',
          'Обеспечивать специалистов должностными инструкциями, нормативными правовыми актами, устанавливающими требования промышленной безопасности, правилами ведения работ на опасных производственных объектах (ОПО)',
          'Назначать лиц, ответственных за содержание эскалаторов в исправном состоянии и за безопасную эксплуатацию',
          'Назначать лиц, ответственных за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации эскалатора',
        ],
        correctAnswers: [
          'Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО',
        ],
      },
      {
        code: '63700007',
        text:
          'В каком случае после срабатывания блокировочного устройства повторный пуск эскалатора в работу возможен без принудительного приведения блокировочного устройства в исходное положение?',
        answers: [
          'При подъеме или опускании ступеней перед входными площадками',
          'При срабатывании рабочего тормоза и блокировок входных площадок',
          'При нерасторможенном рабочем или дополнительном (аварийном) тормозе',
          'При сходе поручня с направляющих на нижнем криволинейном участке',
        ],
        correctAnswers: [
          'При срабатывании рабочего тормоза и блокировок входных площадок',
        ],
      },
      {
        code: '63700008',
        text:
          'Какой минимальный коэффициент запаса прочности должен быть для тяговых и приводных цепей?',
        answers: ['5', '6', '7', '8', '9'],
        correctAnswers: ['7'],
      },
      {
        code: '63700009',
        text:
          'Что не относится к основным техническим данным и характеристикам, указываемым в паспорте эскалатора?',
        answers: [
          'Тип передачи к главному валу эскалатора',
          'Скорость движения лестничного полотна м/с',
          'Фактическая производительность чел/час',
          'Характеристика помещений для установки эскалатора',
        ],
        correctAnswers: ['Характеристика помещений для установки эскалатора'],
      },
      {
        code: '63700010',
        text:
          'Какое из перечисленных требований к определению фактического запаса прочности тяговой цепи указано верно?',
        answers: [
          'Длина отобранных для испытаний отрезков цепи должна быть не менее 400 мм',
          'Остаточные деформации или распрессовка деталей цепи после испытаний допускаются в пределах, указанных в руководстве (инструкции) по эксплуатации',
          'Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке qмэ',
          'Разрешается не доводить цепи до разрушения, если нагрузка при испытаниях превысила на 10% расчетную или указанную в проектной документации',
        ],
        correctAnswers: [
          'Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке qмэ',
        ],
      },
      {
        code: '63700011',
        text:
          'Какая величина ускорения, независимо от пассажирской нагрузки, установлена для лестничного полотна эскалатора?',
        answers: [
          'Не более 0,60 м/с²',
          'Не более 0,65 м/с²',
          'Не более 0,70 м/с²',
          'Не более 0,75 м/с²',
        ],
        correctAnswers: ['Не более 0,75 м/с²'],
      },
      {
        code: '63700012',
        text: 'Какие данные не указываются в паспорте эскалатора?',
        answers: [
          'Характеристика электродвигателей',
          'Сведения об устройствах безопасности',
          'Характеристика тормозов',
          'Квалификация представителя организации, выполнившей монтаж',
        ],
        correctAnswers: [
          'Квалификация представителя организации, выполнившей монтаж',
        ],
      },
      {
        code: '63700013',
        text:
          'С какой скоростью должны двигаться, а затем ускоряться эскалаторы, работающие в режиме ожидания, при пересечении пассажиром гребенки?',
        answers: [
          'Двигаться - не менее 20% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,5 м/с²',
          'Двигаться - не менее 10% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,5 м/с²',
          'Двигаться - не менее 20% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 1,5 м/с²',
          'Двигаться - не менее 15% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 1,0 м/с²',
        ],
        correctAnswers: [
          'Двигаться - не менее 20% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,5 м/с²',
        ],
      },
      {
        code: '63700014',
        text:
          'В каком случае допускается осуществлять электропитание дополнительного (аварийного) тормоза (тормозов) от одного источника?',
        answers: [
          'Если тормоз установлен на главном валу',
          'В случаях, предусмотренных проектной документацией',
          'При использовании нормального замкнутого тормоза',
          'При использовании нормального разомкнутого тормоза',
        ],
        correctAnswers: ['При использовании нормального замкнутого тормоза'],
      },
      {
        code: '63700015',
        text:
          'Какие нормативные документы регламентируют порядок и объем работ периодического технического освидетельствования эскалаторов в метрополитенах?',
        answers: [
          'Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах" и технический регламент "О безопасности машин и оборудования"',
          'ГОСТ 33966.1-2016 "Межгосударственный стандарт. Эскалаторы и пассажирские конвейеры. Требования безопасности к устройству и установке" и технический регламент "О безопасности машин и оборудования"',
          'Инструкция по монтажу и руководство по эксплуатации эскалатора',
          'Руководство по эксплуатации эскалатора и федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
        ],
        correctAnswers: [
          'Руководство по эксплуатации эскалатора и федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
        ],
      },
      {
        code: '63700016',
        text:
          'Для чего не предназначается дополнительный (аварийный) тормоз эскалатора?',
        answers: [
          'Для остановки лестничного полотна эскалатора при отключении электродвигателя',
          'Для остановки эскалатора при увеличении номинальной скорости движения лестничного полотна более чем в 1,2 раза',
          'Для остановки эскалатора при срабатывании блокировки превышения допустимых тормозных путей или расчетного времени рабочего торможения более чем на 20%',
          'Для остановки эскалатора при самопроизвольном изменении направления движения лестничного полотна работающего на подъем эскалатора',
        ],
        correctAnswers: [
          'Для остановки лестничного полотна эскалатора при отключении электродвигателя',
        ],
      },
      {
        code: '63700017',
        text:
          'Какая устанавливается ширина свободного прохода в машинном помещении с одной стороны для эскалаторов с высотой подъема до 15 м при установке трех эскалаторов?',
        answers: ['До 750 мм', 'До 850 мм', 'До 950 мм'],
        correctAnswers: ['До 750 мм'],
      },
      {
        code: '63700018',
        text:
          'Сколько должен составлять перепад по высоте двух смежных ступеней на горизонтальном участке эскалатора?',
        answers: [
          'Не должен превышать 7 мм',
          'Не должен превышать 4 мм',
          'Не должен превышать 5 мм',
          'Не должен превышать 6 мм',
        ],
        correctAnswers: ['Не должен превышать 4 мм'],
      },
      {
        code: '63700019',
        text:
          'Кем проводятся грузовые испытания на эскалаторе? Выберите 2 варианта ответа.',
        answers: [
          'Разработчиком/изготовителем эскалатора',
          'Испытательной лабораторией',
          'Организацией, выполнившей монтаж эскалатора',
          'Эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Разработчиком/изготовителем эскалатора',
          'Испытательной лабораторией',
        ],
      },
      {
        code: '63700020',
        text:
          'Какая должна быть минимальная ширина свободного прохода между натяжными устройствами и в местах между приводами (у главного приводного вала и между фундаментами) эскалатора?',
        answers: ['400 мм', '500 мм', '700 мм', '900 мм'],
        correctAnswers: ['400 мм'],
      },
      {
        code: '63700021',
        text:
          'Что не проверяется при грузовых испытаниях для эскалаторов высотой подъема 6 м и менее?',
        answers: [
          'Разгон лестничного полотна выше номинальной скорости',
          'Ускорение несущего полотна при пуске',
          'Возможность пуска и работа на номинальной скорости в любом направлении с максимальной эксплуатационной нагрузкой',
          'Тормозные пути рабочего тормоза, замедление при торможении, требование по равномерному распределению тормозного момента между тормозами при использовании двух и более рабочих тормозов',
        ],
        correctAnswers: [
          'Разгон лестничного полотна выше номинальной скорости',
        ],
      },
      {
        code: '63700022',
        text:
          'С кем владелец ОПО, на котором используется эскалатор в метрополитенах, должен согласовать изменения, вносимые при изготовлении, модернизации или реконструкции эскалатора в его конструкцию?',
        answers: [
          'С экспертной организацией',
          'С организацией-разработчиком или специализированной проектной организацией',
          'С организацией по монтажно-наладочным работам',
          'С территориальным органом Ростехнадзора',
        ],
        correctAnswers: [
          'С организацией-разработчиком или специализированной проектной организацией',
        ],
      },
      {
        code: '63700023',
        text:
          'Сколько должна составлять норма межремонтного пробега для капитального ремонта, если она не установлена изготовителем эскалатора?',
        answers: [
          'Не более 175 000 км',
          'Не более 165 000 км',
          'Не более 150 000 км',
          'Не более 170 000 км',
        ],
        correctAnswers: ['Не более 150 000 км'],
      },
      {
        code: '63700024',
        text:
          'Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров более 6 м и при номинальной скорости более 0,5 м/с (но не более 0,65 м/с для эскалаторов)?',
        answers: [
          'Не менее 1200 мм',
          'Не менее 1000 мм',
          'Не менее 800 мм',
          'Не менее 600 мм',
        ],
        correctAnswers: ['Не менее 1200 мм'],
      },
      {
        code: '63700025',
        text:
          'В каком случае на балюстраде эскалатора разрешается установка решеток для громкоговорящей связи, осветителей и розеток?',
        answers: [
          'По согласованию с разработчиком эскалатора',
          'По согласованию с организацией, выполняющей монтаж эскалатора',
          'По согласованию с эксплуатирующей организацией',
          'По согласованию с испытательной лабораторией',
        ],
        correctAnswers: ['По согласованию с разработчиком эскалатора'],
      },
      {
        code: '63700026',
        text:
          'Какой должна быть освещенность входных площадок эскалатора на уровне пола, измеренная по оси лестничного полотна?',
        answers: [
          'Не менее 50 лк',
          'Не менее 30 лк',
          'Не менее 70 лк',
          'Не менее 100 лк',
        ],
        correctAnswers: ['Не менее 50 лк'],
      },
      {
        code: '63700027',
        text:
          'Каким должен быть коэффициент запаса прочности поручня эскалатора?',
        answers: ['Не менее 5', 'Не менее 3', 'Не менее 7', 'Не менее 9'],
        correctAnswers: ['Не менее 5'],
      },
      {
        code: '63700028',
        text:
          'Какое положение по эксплуатации эскалатора не соответствует требованиям Правил безопасности эскалаторов в метрополитенах?',
        answers: [
          'Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора',
          'Техническое обслуживание должно включать в себя осмотр, смазку, замер износа, очистку, регулировку и замену узлов и деталей (по результатам осмотра и замеров)',
          'Ввод эскалатора в эксплуатацию после капитального ремонта, а также по окончании срока работы, установленного предыдущим техническим освидетельствованием, должен осуществлять владелец опасного производственного объекта при наличии квалифицированного персонала или специализированная организация после осмотра и проверки в объеме технического освидетельствования',
          'Техническое обслуживание должно проводиться в сроки, устанавливаемые в руководстве по эксплуатации или в инструкции по техническому обслуживанию',
        ],
        correctAnswers: [
          'Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора',
        ],
      },
      {
        code: '63700029',
        text:
          'Каким образом необходимо проводить обкатку модернизированного или реконструированного эскалатора, или эскалатора после проведенного капитального (капитально-восстановительного) ремонта?',
        answers: [
          'В течение первых 6 часов от вспомогательного привода, а следующих 6 часов - от главного привода',
          'В течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении',
          'В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону',
          'В течение 24 часов непрерывной работы от главного привода, по 12 часов в каждую сторону',
        ],
        correctAnswers: [
          'В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону',
        ],
      },
      {
        code: '63700030',
        text:
          'Каким должен быть угол наклона входной лестницы в машинное помещение?',
        answers: [
          'Не более 45°',
          'Не более 30°',
          'Не более 60°',
          'Не более 25°',
        ],
        correctAnswers: ['Не более 45°'],
      },
      {
        code: '63700031',
        text:
          'Какие действия необходимо предпринять при возникновении неисправностей, представляющих опасность при пользовании эскалатором?',
        answers: [
          'Эскалатор должен быть остановлен только после спуска (подъема) оставшихся на нем пассажиров',
          'Эскалатор должен быть остановлен, пассажиры не должны покидать эскалатор до устранения неисправности',
          'Эскалатор должен быть остановлен, а пассажиры с него удалены',
        ],
        correctAnswers: [
          'Эскалатор должен быть остановлен, а пассажиры с него удалены',
        ],
      },
      {
        code: '63700032',
        text:
          'Каким приложением однократного усилия проверяют прочность изготовленных ступеней эскалатора выборочно или каждой отремонтированной ступени?',
        answers: ['3000 Н', '1000 Н', '2000 Н', '5000 Н'],
        correctAnswers: ['3000 Н'],
      },
      {
        code: '63700033',
        text:
          'Каким должно быть напряжение цепей штепсельных розеток для питания переносных ламп?',
        answers: [
          'Не более 110 В',
          'Не более 50 В',
          'Не более 127 В',
          'Не более 120 В',
        ],
        correctAnswers: ['Не более 50 В'],
      },
      {
        code: '63700034',
        text:
          'Какое из перечисленных эскалаторных помещений может не предусматриваться?',
        answers: [
          'Должны быть предусмотрены все перечисленные помещения',
          'Только помещение для установки привода (машинное помещение), электрооборудования, аппаратуры управления и телемеханики',
          'Только помещение для установки натяжного устройства (натяжная камера)',
          'Только демонтажные проходы (демонтажные шахты и демонтажная камера)',
        ],
        correctAnswers: [
          'Должны быть предусмотрены все перечисленные помещения',
        ],
      },
      {
        code: '63700035',
        text:
          'Какую величину составляет минимальное расстояние от холостой ветви поручня эскалатора до вспомогательных коммуникаций, не относящихся к эскалаторам?',
        answers: ['400 мм', '300 мм', '200 мм', '100 мм'],
        correctAnswers: ['200 мм'],
      },
      {
        code: '63700036',
        text: 'Какая величина угла наклона установлена для эскалатора?',
        answers: [
          'Не более 35°',
          'Не более 45°',
          'Не более 30°',
          'Не более 40°',
        ],
        correctAnswers: ['Не более 30°'],
      },
      {
        code: '63700037',
        text: 'Какой документ составляется по результатам работы комиссии?',
        answers: [
          'Акт о возможности ввода в эксплуатацию эскалатора',
          'Акт о положительном заключении готовности эскалатора к вводу в эксплуатацию',
          'Акт приемо-сдаточных испытаний эскалатора',
          'Акт технической готовности эскалатора',
        ],
        correctAnswers: ['Акт о возможности ввода в эксплуатацию эскалатора'],
      },
      {
        code: '63700038',
        text:
          'Какая величина замедления установлена для лестничного полотна эскалатора, загруженного максимальной эксплуатационной нагрузкой, при нарушении кинематической связи между приводом и главным валом и торможении аварийным тормозом?',
        answers: [
          'Не более 2,5 м/с²',
          'Не более 1,5 м/с²',
          'Не более 2 м/с²',
          'Не более 1 м/с²',
        ],
        correctAnswers: ['Не более 2 м/с²'],
      },
      {
        code: '63700039',
        text:
          'В каком из перечисленных случаев допускается одновременная работа главного и вспомогательного приводов?',
        answers: [
          'Только в случае превышения максимально допустимой нагрузки эскалатора',
          'Только в случае проведения работ по обкатке эскалатора',
          'Только в случае снижения мощности двигателя главного привода',
          'Одновременная работа главного и вспомогательного привода не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Одновременная работа главного и вспомогательного привода не допускается ни в каком случае',
        ],
      },
      {
        code: '63700040',
        text:
          'При перемещении на какое расстояние натяжного устройства в сторону привода или в обратном направлении эскалатор должен останавливаться?',
        answers: [
          'Не более 30 мм',
          'Не более 40 мм',
          'Не более 50 мм',
          'Не более 60 мм',
        ],
        correctAnswers: ['Не более 30 мм'],
      },
      {
        code: '63700041',
        text:
          'Сколько должна составлять общая тормозная нагрузка для обеспечения пути разгона и торможения лестничного полотна при испытаниях тормозной системы эскалатора?',
        answers: [
          'Не более 300 кг на ступень',
          'Не более 200 кг на ступень',
          'Не более 400 кг на ступень',
          'Не более 500 кг на ступень',
        ],
        correctAnswers: ['Не более 300 кг на ступень'],
      },
      {
        code: '63700042',
        text:
          'В какие сроки должно проводиться периодическое техническое освидетельствование эскалатора после ввода его в эксплуатацию?',
        answers: [
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 24 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63700043',
        text:
          'В каком объеме должны проводиться осмотр и проверка эскалатора при вводе его в эксплуатацию после капитального ремонта?',
        answers: [
          'Внеочередного технического обслуживания',
          'Планового технического осмотра',
          'Технического освидетельствования',
        ],
        correctAnswers: ['Технического освидетельствования'],
      },
      {
        code: '63700044',
        text:
          'Допускаются ли при обкатке вновь установленного эскалатора остановки?',
        answers: [
          'Допускаются остановки только для проверки правильности регулировки и действия рабочего и дополнительного тормозов, входных площадок, блокировочных устройств с замером регламентированных зазоров и размеров составных частей',
          'Допускаются остановки для наладки и регулирования общей продолжительностью не более 45 минут',
          'Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут',
          'Допускаются остановки для регулирования после проведенного капитально-восстановительного ремонта',
        ],
        correctAnswers: [
          'Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут',
        ],
      },
      {
        code: '63700045',
        text: 'Кем проводятся осмотр, проверка и испытания эскалатора?',
        answers: [
          'Организацией, имеющей лицензию на право проведения экспертизы промышленной безопасности',
          'Организацией, эксплуатирующей эскалаторы',
          'Специализированной организацией по обслуживанию подъемно-транспортного оборудования',
          'Организацией, выполнившей монтаж или реконструкцию эскалатора',
        ],
        correctAnswers: [
          'Организацией, выполнившей монтаж или реконструкцию эскалатора',
        ],
      },
      {
        code: '63700046',
        text:
          'Какое значение не должна превышать стрела упругого прогиба направляющих бегунков под действием нагрузки qмэ?',
        answers: [
          '1/1000 величины пролета',
          '1/800 величины пролета',
          '1/600 величины пролета',
          '1/500 величины пролета',
        ],
        correctAnswers: ['1/1000 величины пролета'],
      },
      {
        code: '63700047',
        text:
          'Какую величину, независимо от пассажирской нагрузки, не должно превышать замедление лестничного полотна эскалатора при торможении рабочими тормозами при работе на спуск?',
        answers: ['1,00 м/с²', '0,90 м/с²', '0,75 м/с²', '0,60 м/с²'],
        correctAnswers: ['0,60 м/с²'],
      },
      {
        code: '63700048',
        text:
          'На какие процессы в области промышленной безопасности на ОПО не распространяется действие федеральных норм и правил "Правила безопасности эскалаторов в метрополитенах"?',
        answers: [
          'Связанные с изготовлением, монтажом, наладкой, обслуживанием и ремонтом эскалаторов',
          'Связанные с эксплуатацией, капитальным ремонтом, техническим перевооружением, консервацией и ликвидацией',
          'Связанные с перевозкой и хранением',
          'Связанные с проведением экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Связанные с перевозкой и хранением'],
      },
      {
        code: '63700049',
        text:
          'Какие из перечисленных представителей должны включаться в состав комиссии при проведении грузовых испытаний эскалатора?',
        answers: [
          'Все перечисленные представители',
          'Представитель организации, выполнившей монтаж эскалатора, и организации, выполнившей пусконаладочные работы',
          'Представитель эксплуатирующей организации',
          'Представитель федерального органа исполнительной власти в области промышленной безопасности',
        ],
        correctAnswers: ['Все перечисленные представители'],
      },
      {
        code: '63700050',
        text:
          'Какие из перечисленных документов должны быть в наличии у владельца ОПО до начала применения эскалатора?',
        answers: [
          'Только протокол осмотра и проверки элементов заземления (зануления) оборудования, включая балюстраду, выполненную из металлических листов',
          'Только протокол проверки сопротивления изоляции силового электрооборудования, цепей управления и сигнализации, силовой и осветительной электропроводки',
          'Только паспорт эскалатора и руководство по эксплуатации',
          'Только протокол маркшейдерских замеров установки направляющих лестничного полотна',
          'Все перечисленные, а также акт освидетельствования скрытых работ и акт технической готовности',
        ],
        correctAnswers: [
          'Все перечисленные, а также акт освидетельствования скрытых работ и акт технической готовности',
        ],
      },
      {
        code: '63700051',
        text: 'Какие требования к эскалаторам указаны неверно?',
        answers: [
          'Неподвижные оси, служащие опорой для блоков, роликов и прочих вращающихся деталей, должны быть надежно укреплены и снабжены приспособлениями, обеспечивающими их фиксацию',
          'Составные части эскалатора должны быть защищены от коррозии в соответствии с условиями их эксплуатации, хранения и транспортирования в части воздействия климатических факторов внешней среды',
          'Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения',
          'К механизмам, предохранительным устройствам, электрооборудованию, требующим обслуживания, должен быть обеспечен безопасный доступ. Для этой цели должны быть устроены площадки, съемные ограждения, лестницы и специальные приспособления',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения',
        ],
      },
      {
        code: '63700052',
        text:
          'Когда машинисту (дежурному у эскалатора) необходимо проводить контроль за состоянием эскалатора?',
        answers: [
          'Ежедневно перед началом работы',
          'Один раз в неделю',
          'Один раз в три дня',
          'Ежесменно: до начала и по окончании работы',
        ],
        correctAnswers: ['Ежедневно перед началом работы'],
      },
      {
        code: '63700053',
        text:
          'От какого количества независимых источников питания должно осуществляться электроснабжение комплекса (блока) эскалаторов?',
        answers: [
          'Не менее чем от двух',
          'Не менее чем от одного',
          'Не менее чем от трех',
          'Не менее чем от четырех',
        ],
        correctAnswers: ['Не менее чем от двух'],
      },
      {
        code: '63700054',
        text:
          'В каком случае эскалатор метрополитена не подлежит экспертизе промышленной безопасности, если техническим регламентом не установлена иная форма оценки соответствия эскалатора?',
        answers: [
          'До начала применения на опасном производственном объекте (ОПО)',
          'По истечении срока службы или при превышении количества циклов нагрузки эскалатора, установленных организацией-изготовителем',
          'При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет',
          'После проведения работ, связанных с изменением конструкции, заменой материала несущих элементов эскалатора, либо восстановительного ремонта после аварии на ОПО, в результате которых был поврежден эскалатор',
        ],
        correctAnswers: [
          'При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет',
        ],
      },
      {
        code: '63700055',
        text:
          'Какой документ составляется по окончании устранения неисправностей, выявленных в ходе осмотра, проверок и испытаний эскалаторов?',
        answers: [
          'Акт ввода в эксплуатацию эскалатора',
          'Акт приемо-сдаточных испытаний',
          'Акт технической готовности',
          'Протокол осмотра эскалаторов',
        ],
        correctAnswers: ['Акт технической готовности'],
      },
      {
        code: '63700056',
        text:
          'Какой должен быть расчетный запас прочности тяговой цепи эскалатора?',
        answers: ['Не менее 7', 'Не менее 5', 'Не менее 3', 'Не менее 9'],
        correctAnswers: ['Не менее 7'],
      },
      {
        code: '63700057',
        text:
          'Какая из перечисленных записей не ставится в паспорте эскалатора уполномоченным лицом владельца ОПО, проводившим освидетельствование?',
        answers: [
          'Запись о результатах полного технического освидетельствования',
          'Запись о вводе эскалатора в эксплуатацию',
          'Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора',
          'Запись о сроке очередного периодического технического освидетельствования',
        ],
        correctAnswers: [
          'Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора',
        ],
      },
      {
        code: '63700058',
        text:
          'Какой нагрузкой нагружают две ступени эскалатора от изготавливаемой партии для проверки фактического запаса прочности?',
        answers: [
          'Не менее 5qмэ',
          'Не менее 3qмэ',
          'Не менее 2qмэ',
          'Не менее 7qмэ',
        ],
        correctAnswers: ['Не менее 5qмэ'],
      },
      {
        code: '63700059',
        text: 'Какое требование к управлению эскалатором указано неверно?',
        answers: [
          'Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение',
          'Управление эскалатором, оборудованным системой дистанционного управления, должен осуществлять дежурный оператор у эскалатора с пульта, установленного в кабине персонала, при обеспечении постоянного визуального наблюдения за пассажирами на лестничном полотне',
          'В зоне нижней площадки схода эскалатора должно обеспечиваться постоянное наблюдение за пассажирами, находящимися на лестничном полотне',
          'Работник, осуществляющий наблюдение за пассажирами на эскалаторе, обязан останавливать эскалатор в случае падения пассажира или возникновения опасности нанесения травм пассажирам',
        ],
        correctAnswers: [
          'Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение',
        ],
      },
      {
        code: '63700060',
        text:
          'В каком случае допускается пуск эскалатора с пассажирами на полотне с пульта дистанционного управления?',
        answers: [
          'Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске',
          'Если исключен пуск эскалатора в обратном направлении',
          'Если установлены несамовозвратные выключатели для экстренной остановки эскалатора',
          'Ни в каком случае',
        ],
        correctAnswers: [
          'Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске',
        ],
      },
      {
        code: '63700061',
        text:
          'Каким образом должны быть устроены входные вертикальные лестницы натяжных камер эскалатора?',
        answers: [
          'Должны быть шириной не менее 650 мм и иметь расстояние между ступенями не менее 350 мм, шаг ступеней должен быть выдержан по всей высоте лестницы',
          'Должны быть шириной не менее 550 мм и иметь расстояние между ступенями не более 250 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм',
          'Должны быть шириной не менее 500 мм и иметь расстояние между ступенями не более 200 мм, шаг ступеней должен быть выдержан по всей высоте лестницы',
          'Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм',
        ],
        correctAnswers: [
          'Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм',
        ],
      },
      {
        code: '63700062',
        text:
          'Каким требованиям должен соответствовать персонал, допущенный к управлению эскалатором в метрополитене?',
        answers: [
          'Персонал должен иметь опыт работы в сфере управления эскалатором не менее 3 лет',
          'Персонал должен иметь на руках производственную инструкцию и инструкцию по эксплуатации',
          'Персонал должен быть не моложе 16 лет',
          'Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний',
        ],
        correctAnswers: [
          'Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний',
        ],
      },
      {
        code: '63700063',
        text:
          'Кто не входит в состав комиссии, принимающей решение о возможности ввода эскалатора в эксплуатацию?',
        answers: [
          'Уполномоченный представитель страховой организации',
          'Уполномоченный представитель эксплуатирующей организации',
          'Уполномоченный представитель организации, выполнившей строительно-монтажные работы',
          'Уполномоченный представитель федерального органа исполнительной власти в области промышленной безопасности',
          'Уполномоченный представитель организации, выполнившей монтаж эскалатора',
        ],
        correctAnswers: ['Уполномоченный представитель страховой организации'],
      },
      {
        code: '63700064',
        text:
          'Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров не более 6 м и при номинальной скорости не более 0,5 м/с?',
        answers: [
          'Не менее 800 мм',
          'Не менее 700 мм',
          'Не менее 600 мм',
          'Не менее 500 мм',
        ],
        correctAnswers: ['Не менее 800 мм'],
      },
      {
        code: '63700065',
        text: 'Какие требования предъявляются к входным площадкам эскалатора?',
        answers: [
          'Освещенность у входных площадок, не включая гребенки, должна соответствовать освещенности, создаваемой освещением в этой зоне',
          'Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки',
          'При возникновении опасности повреждения входной площадки (ступени) оператор должен остановить эскалатор',
          'Поверхность входных площадок не должна быть рифленой',
        ],
        correctAnswers: [
          'Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки',
        ],
      },
      {
        code: '63700066',
        text:
          'Какое должно быть общее время электрического торможения до срабатывания рабочего тормоза эскалатора?',
        answers: [
          'Не более 4 секунд',
          'Не более 7 секунд',
          'Не более 9 секунд',
          'Не более 12 секунд',
        ],
        correctAnswers: ['Не более 4 секунд'],
      },
      {
        code: '63700067',
        text:
          'Какая номинальная скорость движения установлена для лестничного полотна эскалатора?',
        answers: [
          'Не более 0,9 м/с',
          'Не более 1,0 м/с',
          'Не более 1,2 м/с',
          'Не более 0,75 м/с',
        ],
        correctAnswers: ['Не более 0,75 м/с'],
      },
      {
        code: '63700068',
        text:
          'Какое должно быть расстояние по вертикали от уровня настила ступеней эскалатора до потолка галереи, тоннеля или выступающих частей (балок, архитектурных украшений, осветительной арматуры), измеряемое у края ступени со стороны, примыкающей к стене тоннеля, для круглых наклонных тоннелей?',
        answers: [
          'Не менее 2000 мм',
          'Не менее 1500 мм',
          'Не менее 2500 мм',
          'Не менее 3000 мм',
        ],
        correctAnswers: ['Не менее 2000 мм'],
      },
      {
        code: '63700069',
        text:
          'В каких целях не проводится полное техническое освидетельствование эскалатора после изготовления (монтажа), реконструкции, модернизации?',
        answers: [
          'В целях подтверждения соответствия эксплуатации, содержания и обслуживания эскалатора требованиям федеральных норм и правил в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
          'В целях подтверждения технического состояния эскалатора, обеспечивающего его безопасное использование по назначению',
          'В целях подтверждения соответствия эскалатора и его устройства требованиям федеральных норм и правил в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах" и паспортным данным',
        ],
        correctAnswers: [
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
        ],
      },
      {
        code: '63700070',
        text:
          'Что должен включать комплект документации на каждый изготовленный эскалатор?',
        answers: [
          'Должностную инструкцию лица, ответственного за содержание эскалатора в исправном состоянии и его безопасную эксплуатацию',
          'Ведомость комплекта запасных изделий для пусконаладочных работ',
          'Производственные инструкции обслуживающего персонала',
          'План мероприятий по действиям персонала в случае аварийной остановки эскалатора',
        ],
        correctAnswers: [
          'Ведомость комплекта запасных изделий для пусконаладочных работ',
        ],
      },
      {
        code: '63700071',
        text:
          'Каким образом в процессе эксплуатации эскалаторов должно определяться их соответствие требованиям промышленной безопасности?',
        answers: [
          'Проведением сертификации',
          'Проведением декларирования',
          'Проведением экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Проведением экспертизы промышленной безопасности'],
      },
      {
        code: '63700072',
        text:
          'Какие эскалаторы тяжелого режима работы должны использоваться в метрополитенах?',
        answers: [
          'С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°',
          'С номинальной скоростью не более 0,50 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°',
          'С номинальной скоростью не более 1,05 м/с, шириной лестничного полотна 900 ± 10 мм и углом наклона не более 30°',
          'С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 900 ± 10 мм и углом наклона не более 30°',
        ],
        correctAnswers: [
          'С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°',
        ],
      },
      {
        code: '63700073',
        text:
          'На каком расстоянии должны быть установлены штепсельные розетки по эскалаторному тоннелю?',
        answers: [
          'Не более 20 м друг от друга',
          'Не более 30 м друг от друга',
          'Не более 40 м друг от друга',
          'Не более 50 м друг от друга',
        ],
        correctAnswers: ['Не более 20 м друг от друга'],
      },
      {
        code: '63700074',
        text:
          'Какое значение не должна превышать стрела упругого прогиба плит перекрытия между входными площадками эскалатора и строительными конструкциями под действием нагрузки qс и массы строительного покрытия?',
        answers: [
          '1/600 пролета',
          '1/500 пролета',
          '1/800 пролета',
          '1/1000 пролета',
        ],
        correctAnswers: ['1/600 пролета'],
      },
      {
        code: '63700075',
        text:
          'Какая ремонтная скорость движения установлена для лестничного полотна эскалатора?',
        answers: [
          'Не более 0,08 м/с',
          'Не более 0,06 м/с',
          'Не более 1,0 м/с',
          'Не более 0,04 м/с',
        ],
        correctAnswers: ['Не более 0,04 м/с'],
      },
      {
        code: '63700076',
        text:
          'Каким образом должна проводиться обкатка каждого вновь установленного эскалатора?',
        answers: [
          'На месте применения в течение 24 часов непрерывной работы от вспомогательного привода, по 12 часов в каждом направлении от постоянных источников питания',
          'На месте применения в течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону',
          'На месте применения в течение 24 часов от вспомогательного привода, следующие 24 часа от главного привода',
          'На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания',
        ],
        correctAnswers: [
          'На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания',
        ],
      },
      {
        code: '63700077',
        text:
          'Какие из перечисленных конструкций относятся к ответственным (расчетным) сварным конструкциям?',
        answers: [
          'Только каркас ступени',
          'Только конструкции подъемно-транспортного оборудования машинного помещения',
          'Только рычаги и рамы тормозов',
          'Только входная площадка',
          'Все перечисленные конструкции',
        ],
        correctAnswers: ['Все перечисленные конструкции'],
      },
      {
        code: '63700078',
        text:
          'Кто принимает решение о вводе в эксплуатацию эскалатора метрополитена?',
        answers: [
          'Лицо, ответственное за безопасную эксплуатацию эскалатора',
          'Лицо, ответственное за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации эскалатора',
          'Лицо, ответственное за содержание эскалатора в исправном состоянии',
        ],
        correctAnswers: [
          'Лицо, ответственное за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации эскалатора',
        ],
      },
      {
        code: '63700079',
        text:
          'Какой должна быть максимальная стрела упругого прогиба под действием нагрузки qc для пролетных металлоконструкций, включая плиты входных площадок, эскалаторов?',
        answers: [
          '1/1000 пролета',
          '1/750 пролета',
          '1/1500 пролета',
          '1/2000 пролета',
        ],
        correctAnswers: ['1/750 пролета'],
      },
      {
        code: '63700080',
        text:
          'В каком случае повторная (внеочередная) проверка знаний персонала, обслуживающего эскалаторы, не проводится?',
        answers: [
          'По требованию работника, ответственного за осуществление производственного контроля',
          'При переводе на обслуживание эскалаторов других типов',
          'При перерыве в работе по должности 3 месяца',
          'При переходе из одной организации в другую',
        ],
        correctAnswers: ['При перерыве в работе по должности 3 месяца'],
      },
      {
        code: '63700081',
        text:
          'При каком замедлении лестничного полотна при торможении допускается совместное срабатывание дополнительного (аварийного) и рабочего тормозов?',
        answers: [
          'Не более 1 м/с²',
          'Не более 3 м/с²',
          'Не более 0,75 м/с²',
          'Не более 0,5 м/с²',
        ],
        correctAnswers: ['Не более 1 м/с²'],
      },
      {
        code: '63700082',
        text: 'Что запрещается при обустройстве балюстрады?',
        answers: [
          'Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну',
          'Выполнять конструкцию балюстрады в легкоразборном исполнении в местах, требующих технического обслуживания',
          'Устанавливать дефлекторы, препятствующие затягиванию элементов одежды и обуви в зазор между ступенью и фартуком эскалатора',
          'Устанавливать решетки для громкоговорящей связи, осветителей и розеток (по согласованию с разработчиком эскалатора)',
        ],
        correctAnswers: [
          'Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну',
        ],
      },
      {
        code: '63700083',
        text:
          'В каком случае в процессе монтажа эскалаторов допускаются отступления от требований конструкторской документации?',
        answers: [
          'При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией',
          'При согласовании этих изменений только со специализированной по эскалаторам проектной организацией',
          'При согласовании этих изменений только с организацией - изготовителем эскалатора',
        ],
        correctAnswers: [
          'При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией',
        ],
      },
      {
        code: '63700084',
        text: 'Для каких целей предназначен главный привод эскалатора?',
        answers: [
          'Для передвижения лестничного полотна эскалатора с номинальной скоростью',
          'Для обеспечения ремонтной скорости при монтажных и демонтажных работах',
          'Для движения лестничного полотна с ремонтной скоростью при проведении технического обслуживания',
          'Для растормаживания аварийного тормоза',
        ],
        correctAnswers: [
          'Для передвижения лестничного полотна эскалатора с номинальной скоростью',
        ],
      },
      {
        code: '63700085',
        text:
          'В каком случае допускается эксплуатация эскалатора метрополитена?',
        answers: [
          'При условиях окружающей среды, не соответствующих требованиям эксплуатационной документации',
          'При истекшем сроке технического освидетельствования',
          'При отсутствии персонала, прошедшего проверку знаний в установленном порядке',
          'При отсутствии трещин в металлоконструкции и элементах оборудования',
        ],
        correctAnswers: [
          'При отсутствии трещин в металлоконструкции и элементах оборудования',
        ],
      },
      {
        code: '63700086',
        text:
          'Какая допускается остаточная деформация ступеней эскалатора, измеренной у поверхности настила, после испытаний?',
        answers: [
          'Не более 4 мм',
          'Не более 8 мм',
          'Не более 10 мм',
          'Не более 15 мм',
        ],
        correctAnswers: ['Не более 4 мм'],
      },
      {
        code: '63700087',
        text:
          'Какой организацией определяется численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется эскалатор, и ее структура?',
        answers: [
          'Организацией, проводившей модернизацию (реконструкцию) или монтаж эскалатора',
          'Эксплуатирующей организацией',
          'Специализированной проектной организацией',
          'Лицензированной организацией, имеющей право на проведение экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63700088',
        text:
          'Каким образом не может быть установлен объем текущего, среднего и капитального ремонта?',
        answers: [
          'В порядке технического освидетельствования',
          'В порядке нерегламентированного технического обслуживания',
          'В порядке планового технического осмотра',
          'В порядке экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В порядке нерегламентированного технического обслуживания',
        ],
      },
      {
        code: '63700089',
        text:
          'В соответствии с каким документом должно проводиться техническое обслуживание и ремонт эскалатора?',
        answers: [
          'В соответствии с заключением специализированной организации, проводившей экспертизу промышленной безопасности',
          'В соответствии с проектной документацией завода-изготовителя',
          'В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации',
          'В соответствии с инструкцией по охране труда при выполнении работ по обслуживанию эскалатора',
        ],
        correctAnswers: [
          'В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации',
        ],
      },
      {
        code: '63700090',
        text:
          'В каком случае допускается применение посадок с гарантированным натягом без дополнительного крепления в составных частях привода эскалатора, передающих крутящий момент?',
        answers: [
          'Не допускается ни в каком случае',
          'Только если это соответствует требованиям проектной документации',
          'Только если это соответствует требованиям "Правил безопасности эскалаторов в метрополитенах"',
          'Только если обеспечен контроль безопасности выполнения технологических операций',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63700091',
        text:
          'Сколько должна составлять скорость движения поручневого устройства эскалатора?',
        answers: [
          'Не должна отличаться от скорости движения лестничного полотна более чем на 5%',
          'Не должна отличаться от скорости движения лестничного полотна более чем на 4%',
          'Не должна отличаться от скорости движения лестничного полотна более чем на 2%',
          'Не должна отличаться от скорости движения лестничного полотна более чем на 3%',
        ],
        correctAnswers: [
          'Не должна отличаться от скорости движения лестничного полотна более чем на 2%',
        ],
      },
      {
        code: '63700092',
        text:
          'Какая допускается остаточная деформация подступенка эскалатора при испытании?',
        answers: [
          'Остаточная деформация не допускается',
          '1 мм',
          '4 мм',
          '2 мм',
        ],
        correctAnswers: ['Остаточная деформация не допускается'],
      },
      {
        code: '63700093',
        text:
          'Какая устанавливается минимальная ширина свободного прохода между фундаментами или выступающими частями привода эскалатора и стенами машинного помещения (или оборудованием и коммуникациями, размещенными на стенах машинного помещения), торцевой стеной натяжной камеры (или оборудованием и коммуникациями, размещенными на стенах натяжной камеры)?',
        answers: ['900 мм', '700 мм', '500 мм', '1000 мм'],
        correctAnswers: ['900 мм'],
      },
      {
        code: '63700094',
        text:
          'Какой допускается максимальный упругий прогиб фартука балюстрады под воздействием нагрузки 1500 H, приложенной между опорами фартука перпендикулярно к его поверхности на площади 25 см²?',
        answers: ['4 мм', '3 мм', '5 мм', '7 мм'],
        correctAnswers: ['4 мм'],
      },
      {
        code: '63700095',
        text:
          'В соответствии с какими нормативными документами должна осуществляться эксплуатация эскалатора?',
        answers: [
          'В соответствии с проектной документацией и инструкциями по охране труда',
          'В соответствии с "Правилами безопасности эскалаторов в метрополитенах" и эксплуатационной документацией',
          'В соответствии с эксплуатационной и проектной документацией',
          'В соответствии с производственными инструкциями и "Правилами безопасности эскалаторов в метрополитенах"',
        ],
        correctAnswers: [
          'В соответствии с "Правилами безопасности эскалаторов в метрополитенах" и эксплуатационной документацией',
        ],
      },
      {
        code: '63700096',
        text:
          'Как должны быть выполнены ступени по наклонной части эскалаторного тоннеля в проходах между эскалаторами, а также между крайним эскалатором и строительными конструкциями?',
        answers: [
          'Шириной не менее 250 мм и высотой не более 250 мм с постоянным углом наклона',
          'Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона',
          'Шириной не менее 300 мм и высотой не более 300 мм с постоянным углом наклона',
          'Шириной не менее 400 мм и высотой не более 150 мм с постоянным углом наклона',
        ],
        correctAnswers: [
          'Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона',
        ],
      },
      {
        code: '63700097',
        text:
          'Начиная с какой высоты, на вертикальных лестницах натяжной камеры при высоте лестницы более 5 м должны быть установлены ограждения в виде дуг?',
        answers: [
          'С высоты 1,3 м',
          'С высоты 2,0 м',
          'С высоты 1,5 м',
          'С высоты 3,0 м',
        ],
        correctAnswers: ['С высоты 3,0 м'],
      },
      {
        code: '63700098',
        text:
          'Какая организация обязана обеспечить содержание эскалатора в исправном состоянии путем организации обслуживания, ремонта, технического освидетельствования?',
        answers: [
          'Организация, выполнившая изготовление эскалатора',
          'Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации',
          'Организация, занимающаяся модернизацией или реконструкцией эскалатора',
          'Организация, выполнившая монтаж эскалатора',
        ],
        correctAnswers: [
          'Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации',
        ],
      },
      {
        code: '63700099',
        text:
          'Какие требования не должны устанавливаться в эксплуатационных документах эскалатора метрополитена?',
        answers: [
          'Требования к изготовлению',
          'Требования к условиям перевозки и хранения',
          'Требования к консервации',
          'Требования к утилизации',
        ],
        correctAnswers: ['Требования к изготовлению'],
      },
      {
        code: '63700100',
        text:
          'Какая площадка должна быть предусмотрена перед входом на эскалатор от выступающего оборудования (барьеров, кабин)?',
        answers: [
          'Шириной не менее расстояния между наружными краями поручней плюс 80 мм с каждой стороны и глубиной не менее 4,50 м от конца балюстрады',
          'Шириной не менее расстояния между наружными краями поручней плюс 60 мм с каждой стороны и глубиной не менее 4 м от конца балюстрады',
          'Шириной не менее расстояния между наружными краями поручней плюс 80 мм с каждой стороны и глубиной не менее 2,50 м от конца балюстрады',
          'Шириной не менее расстояния между наружными краями поручней плюс 100 мм с каждой стороны и глубиной не менее 5,50 м от конца балюстрады',
        ],
        correctAnswers: [
          'Шириной не менее расстояния между наружными краями поручней плюс 80 мм с каждой стороны и глубиной не менее 4,50 м от конца балюстрады',
        ],
      },
      {
        code: '63700101',
        text:
          'В каком случае допускается пуск эскалатора с пассажирами с любого пульта управления в обратном направлении?',
        answers: [
          'Не допускается ни в каком случае',
          'В случае возникновения аварийной ситуации',
          'В случае перегруза ступеней эскалатора',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63700102',
        text:
          'При каком торможении на спуск с максимальной нагрузкой qмэ на пути (в метрах) каждый из тормозов должен останавливать лестничное полотно при применении двух и более рабочих тормозов?',
        answers: ['Не более 6V', 'Не более 5V', 'Не более 7V', 'Не более 8V'],
        correctAnswers: ['Не более 6V'],
      },
      {
        code: '63700103',
        text:
          'Какой допускается зазор в стыках щитов и фартуков балюстрады эскалатора?',
        answers: [
          'Не более 4 мм',
          'Не более 2 мм',
          'Не более 6 мм',
          'Не более 10 мм',
        ],
        correctAnswers: ['Не более 4 мм'],
      },
      {
        code: '63700104',
        text: 'Кто осуществляет пуск эскалатора в работу?',
        answers: [
          'Работник, ответственный за содержание эскалаторов в исправном состоянии',
          'Мастер специализированной проектной организации',
          'Специалист, ответственный за осуществление производственного контроля в эксплуатирующей организации',
          'Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией',
        ],
        correctAnswers: [
          'Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией',
        ],
      },
      {
        code: '63700105',
        text:
          'С какой периодичностью должна проводиться проверка знаний персонала, обслуживающего эскалаторы, в объеме производственных инструкций?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в полугодие',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63700106',
        text: 'Для чего предназначен рабочий тормоз эскалатора?',
        answers: [
          'Для регулирования скорости движения лестничного полотна и его остановки при отключении электродвигателя',
          'Для блокирования движения эскалатора, двигающегося вниз, в случае увеличения скорости лестничного полотна на 20% номинальной скорости',
          'Для остановки лестничного полотна при самопроизвольном изменении направления его движения при подъеме эскалатора',
          'Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления',
        ],
        correctAnswers: [
          'Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления',
        ],
      },
      {
        code: '63700107',
        text:
          'Каким должен быть тормозной путь (в метрах) движущегося на спуск лестничного полотна эскалатора при торможении рабочим(и) тормозом (тормозами) с нагрузкой qмэ?',
        answers: [
          'Не более 2,4V',
          'Не более 1,2V',
          'Не более 3,6V',
          'Не более 4,5V',
        ],
        correctAnswers: ['Не более 2,4V'],
      },
      {
        code: '63700108',
        text:
          'Каким образом определяются численность и структура службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется эскалатор?',
        answers: [
          'Численность и структура службы производственного контроля определяются исходя из квалификационных характеристик специалистов',
          'Численность и структура службы производственного контроля определяются исходя из класса опасности ОПО',
          'Численность и структура службы производственного контроля определяются организацией, эксплуатирующей эскалатор',
          'Численность и структура службы производственного контроля определяются исходя из результатов целевых проверок состояния промышленной безопасности и опасных производственных факторов на рабочих местах',
        ],
        correctAnswers: [
          'Численность и структура службы производственного контроля определяются организацией, эксплуатирующей эскалатор',
        ],
      },
    ],
    63703: [
      {
        code: '63703000',
        text:
          'Кем делается запись в паспорте ПС о проведенной работе по завершении выполнения ремонта, реконструкции или модернизации ПС?',
        answers: [
          'Эксплуатирующей организацией',
          'Специализированной организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Специализированной организацией'],
      },
      {
        code: '63703001',
        text:
          'С какого места должно выполняться управление ПС в период монтажа?',
        answers: [
          'Только с монтажной площадки',
          'Только из кабины машиниста',
          'С места, указанного в эксплуатационной документации',
          'Не регламентируется',
        ],
        correctAnswers: ['С места, указанного в эксплуатационной документации'],
      },
      {
        code: '63703002',
        text:
          'Кто определяет состав необходимого набора инструментов и приборов, необходимых для монтажа ограничителей, указателей и регистраторов параметров ПС?',
        answers: [
          'Руководитель организации, эксплуатирующей ПС',
          'Работники подрядной организации, обслуживающей ПС',
          'Работники, выполняющие их монтаж',
          'Не регламентируется',
        ],
        correctAnswers: ['Работники, выполняющие их монтаж'],
      },
      {
        code: '63703003',
        text:
          'В течение какого времени груз должен находиться на неподвижном грузонесущем устройстве без смещения грузонесущего устройства и без обнаружения трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов, чтобы строительный подъемник считался выдержавшим статические испытания?',
        answers: [
          'В течение 20 минут',
          'В течение 5 минут',
          'В течение 10 минут',
          'В течение 15 минут',
        ],
        correctAnswers: ['В течение 10 минут'],
      },
      {
        code: '63703004',
        text:
          'Согласно требованиям какого документа должна выполняться проверка работоспособности регистратора параметров работы ПС?',
        answers: [
          'Заключения экспертизы',
          'Методического пособия',
          'Руководства (инструкции) по эксплуатации',
          'Правил безопасности опасных производственных объектов',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации'],
      },
      {
        code: '63703005',
        text:
          'Какие работы должны быть проведены после завершения монтажа и наладки системы дистанционного управления (радиоуправления) ПС?',
        answers: [
          'Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС',
          'Наладка тормозов, ограничителей, указателей и регистраторов параметров',
          'Обновление информации ограничителя или указателя со встроенным регистратором либо автономного регистратора',
          'Полное техническое освидетельствование ПС, управляемого системой дистанционного управления (радиоуправления)',
        ],
        correctAnswers: [
          'Проверка всех команд управления и аварийной защиты при работе системы дистанционного управления (радиоуправления) ПС',
        ],
      },
      {
        code: '63703006',
        text:
          'На кого возлагаются организация и проведение испытаний ПС по завершении выполненных работ по монтажу ПС?',
        answers: [
          'На эксплуатирующую организацию',
          'На специализированную организацию, осуществившую монтаж ПС',
          'На субподрядную организацию, имеющую в наличии тарированные грузы для проведения контрольных грузовых испытаний',
          'На специализированную экспертную организацию',
          'Не регламентируется',
        ],
        correctAnswers: [
          'На специализированную организацию, осуществившую монтаж ПС',
        ],
      },
      {
        code: '63703007',
        text:
          'С какой периодичностью проводятся грузовые испытания люльки (кабины)?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полугодие',
        ],
        correctAnswers: ['Не реже одного раза в полугодие'],
      },
      {
        code: '63703008',
        text:
          'Какое из перечисленных состояний должно быть принято за предельное состояние строительных конструкций и основания по прочности и устойчивости?',
        answers: [
          'Только разрушение любого характера',
          'Только потеря устойчивости формы и (или) положения',
          'Только нарушение эксплуатационной пригодности и иные явления, связанные с угрозой причинения вреда жизни и здоровью людей, имуществу физических или юридических лиц, государственному или муниципальному имуществу, окружающей среде, жизни и здоровью животных и растений',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63703009',
        text:
          'На каком расстоянии от элементов здания, оборудования и штабелей грузов следует устанавливать электрические тали и монорельсовые тележки с автоматическим или полуавтоматическим управлением, если во время движения указанные ПС не сопровождаются оператором?',
        answers: [
          'В соответствии с требованиями руководства (инструкции) по эксплуатации',
          'Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов',
          'С учетом максимальных габаритов транспортируемых грузов',
          'С учетом максимальных габаритов транспортируемых грузов и ширины прохода вдоль цеха для работников ОПО, если такой проход предусмотрен',
        ],
        correctAnswers: [
          'Таким образом, чтобы во время движения исключить возможность задевания грузом элементов здания, оборудования и штабелей грузов',
        ],
      },
      {
        code: '63703010',
        text:
          'Какова продолжительность статических испытаний кабельных кранов?',
        answers: ['40 минут', '30 минут', '20 минут', '10 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63703011',
        text:
          'Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только соответствие основных и сварочных материалов, применяемой технологии сварки требованиям ПТД',
          'Только соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям ПТД',
          'Только соответствие численного состава и квалификации персонала сварочного производства',
          'Выполнение всех подготовительных мероприятий',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63703012',
        text:
          'Кем определяется время действия наряда-допуска на работу подъемника (вышки) вблизи линий электропередачи (далее - ЛЭП)?',
        answers: [
          'Организацией, его выдавшей',
          'Организацией, эксплуатирующей линию электропередачи',
          'Специалистом, ответственным за безопасное производство работ с применением ПС',
          'Разработчиком ППР на данный вид работ',
        ],
        correctAnswers: ['Организацией, его выдавшей'],
      },
      {
        code: '63703013',
        text:
          'В каких случаях между крановщиком и людьми, транспортируемыми в подвесной люльке краном, должна быть установлена постоянная телефонная или радиосвязь?',
        answers: [
          'Только если крановщик видит люльку с людьми во время всей операции транспортировки, но ему недоступна для обзора зона начала подъема или зона опускания люльки',
          'Только если это дополнительно указано в ППР с перемещением люльки',
          'Только если транспортировка людей в люльке осуществляется при неблагоприятных погодных условиях',
          'Только если люлька находится вне зоны видимости во время операции транспортировки',
          'Во всех случаях',
        ],
        correctAnswers: ['Во всех случаях'],
      },
      {
        code: '63703014',
        text:
          'Какое минимальное значение коэффициента надежности по ответственности устанавливается в отношении особо опасных и технически сложных объектов?',
        answers: ['1,1', '1,0', '0,8', '1,2'],
        correctAnswers: ['1,1'],
      },
      {
        code: '63703015',
        text:
          'Какой нагрузкой должны проводиться статические испытания ПС всех типов (кроме подъемников и кранов-трубоукладчиков)?',
        answers: [
          '200 процентов по отношению к номинальной паспортной грузоподъемности ПС',
          '150 процентов по отношению к номинальной паспортной грузоподъемности ПС',
          '140 процентов по отношению к номинальной паспортной грузоподъемности ПС',
          '125 процентов по отношению к номинальной паспортной грузоподъемности ПС',
        ],
        correctAnswers: [
          '125 процентов по отношению к номинальной паспортной грузоподъемности ПС',
        ],
      },
      {
        code: '63703016',
        text:
          'Результаты каких наладочных работ необходимо отразить в акте по окончании монтажа ПС?',
        answers: [
          'Подтверждающие выполнение монтажа и наладки ограничителя, указателя и регистратора',
          'Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов',
          'Подтверждающие возможность проведения грузовых испытаний смонтированного ПС',
          'Подтверждающие возможность выполнения полного технического освидетельствования ПС',
        ],
        correctAnswers: [
          'Подтверждающие работоспособность систем управления ПС, электро-, пневмо-, гидрооборудования, механизмов, а также имеющихся в наличии ограничителей, указателей и регистраторов',
        ],
      },
      {
        code: '63703017',
        text:
          'Кто разрабатывает инструкцию, согласно которой осуществляется обслуживание систем дистанционного управления (радиоуправления) при эксплуатации ПС?',
        answers: [
          'Эксплуатирующая организация',
          'Изготовитель',
          'Руководитель подрядной организации',
          'Монтажная (специализированная) организация',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63703018',
        text:
          'Кем осуществляется обязательная оценка соответствия зданий и сооружений, а также связанных со зданиями и сооружениями процессов строительства, монтажа, наладки в форме заявления о соответствии построенного, реконструированного или отремонтированного здания или сооружения проектной документации?',
        answers: [
          'Председателем экспертной комиссии',
          'Лицом, осуществившим строительство',
          'Инспектором Ростехнадзора',
          'Лицом, подготовившим проектную документацию',
        ],
        correctAnswers: ['Лицом, осуществившим строительство'],
      },
      {
        code: '63703019',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Только журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю',
          'Только протоколы испытаний сварных соединений',
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63703020',
        text:
          'Какие из перечисленных ниже нарушений не могут служить причиной остановки эксплуатации подъемника?',
        answers: [
          'Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию',
          'Не выполнено предписание по обеспечению безопасной эксплуатации подъемника, выданное контролирующей организацией',
          'Обслуживание подъемника ведется необученным персоналом',
          'На ПС выявлено ослабление креплений в соединениях металлоконструкций',
        ],
        correctAnswers: [
          'Отсутствует экспертиза промышленной безопасности нового подъемника, введенного в эксплуатацию',
        ],
      },
      {
        code: '63703021',
        text:
          'На какие из перечисленных ОПО не распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения?',
        answers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
          'На ОПО, где эксплуатируются подъемники (вышки), предназначенные для перемещения людей, груза и людей (подъемники с рабочими платформами) с высотой подъема более 6 м',
          'На ОПО, где эксплуатируются строительные подъемники',
          'На ОПО, где эксплуатируются канатные дороги',
        ],
        correctAnswers: ['На ОПО, где эксплуатируются канатные дороги'],
      },
      {
        code: '63703022',
        text:
          'Для каких подъемников при проведении технического освидетельствования необходимо проверять точность остановки кабины с нагрузкой и без нагрузки?',
        answers: [
          'Для автогидроподъемников',
          'Для строительных подъемников',
          'Для подъемников ножничного типа, управление которыми осуществляется из люльки',
          'Для подъемников, предназначенных для осмотра контактной сети железных дорог',
        ],
        correctAnswers: ['Для строительных подъемников'],
      },
      {
        code: '63703023',
        text:
          'Какие требования предъявляются к испытанию стальных цепей, устанавливаемых на ПС, после их сращивания электросваркой?',
        answers: [
          'Цепь должна быть испытана нагрузкой, в 2 раза превышающей ее расчетное натяжение, в течение 5 мин',
          'Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 мин',
          'Цепь должна быть испытана нагрузкой, в 3 раза превышающей ее расчетное натяжение, в течение 15 мин',
          'Цепь должна быть испытана нагрузкой, в 1,5 раза превышающей ее расчетное натяжение, в течение 15 мин',
        ],
        correctAnswers: [
          'Цепь должна быть испытана нагрузкой, в 1,25 раза превышающей ее расчетное натяжение, в течение 10 мин',
        ],
      },
      {
        code: '63703024',
        text:
          'Из какого количества разделов состоит проектная документация на объекты капитального строительства производственного назначения?',
        answers: [
          'Из 8 разделов',
          'Из 10 разделов',
          'Из 12 разделов',
          'Из 14 разделов',
        ],
        correctAnswers: ['Из 12 разделов'],
      },
      {
        code: '63703025',
        text:
          'Каким образом должна обеспечиваться безопасность здания или сооружения в процессе эксплуатации?',
        answers: [
          'Только посредством периодических осмотров строительных конструкций',
          'Только посредством проведения экспертизы промышленной безопасности',
          'Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения',
          'Только посредством технического обслуживания систем инженерно-технического обеспечения',
          'Только посредством мониторинга состояния основания',
        ],
        correctAnswers: [
          'Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения',
        ],
      },
      {
        code: '63703026',
        text:
          'Какая периодичность частичного технического освидетельствования установлена для ПС в течение всего срока службы?',
        answers: [
          'Не реже одного раза в 24 месяца',
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 18 месяцев',
          'Не реже одного раза в 16 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63703027',
        text:
          'Каким должен быть запас по грузоподъемности ПС для его возможного использования при транспортировке людей по сравнению с суммой массы самой люльки (кабины), массы устройства, предназначенного для подвешивания люльки (кабины), и паспортной номинальной грузоподъемности люльки (кабины) согласно требованиям, установленным ФНП ПС?',
        answers: [
          'Не менее чем двукратный запас',
          'Не менее чем трехкратный запас',
          'Не менее чем полуторакратный, в том числе и для тормозов его механизма подъема',
          'Не регламентируется, если кран оснащен ограничителем грузоподъемности (грузового момента)',
        ],
        correctAnswers: ['Не менее чем двукратный запас'],
      },
      {
        code: '63703028',
        text:
          'Каким требованиям должны соответствовать такелажная оснастка и вспомогательные механизмы, используемые при выполнении ремонта и реконструкции или модернизации ПС?',
        answers: [
          'Эксплуатационным документам ПС',
          'Должны быть проведены статические и динамические испытания',
          'Должны быть проведены дополнительные расчеты такелажной оснастки и вспомогательных механизмов',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Эксплуатационным документам ПС'],
      },
      {
        code: '63703029',
        text:
          'Когда выдаются производственные инструкции персоналу, обслуживающему ПС?',
        answers: [
          'Перед прохождением периодического инструктажа',
          'Перед допуском к работе, под расписку',
          'После прохождения целевого инструктажа',
          'Перед проведением первичного инструктажа на рабочем месте',
        ],
        correctAnswers: ['Перед допуском к работе, под расписку'],
      },
      {
        code: '63703030',
        text:
          'Что должно быть указано в акте о приемке противовеса и балласта, если они изготовлены эксплуатирующей организацией?',
        answers: [
          'Фактическая масса плит',
          'Данные о материале, из которого изготовлены плиты',
          'Габариты плит',
          'Соответствие плит требованиям руководства (инструкции) по эксплуатации ПС',
        ],
        correctAnswers: ['Фактическая масса плит'],
      },
      {
        code: '63703031',
        text:
          'Чем определяется конкретный перечень требований к специализированной организации, которая будет заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?',
        answers: [
          'Номенклатурой ПС и технологией сварки, заявленными организацией для своей последующей деятельности',
          'Номенклатурой ПС и технологическими процессами, заявленными организацией для своей последующей деятельности',
          'Номенклатурой ПС, наличием необходимого оборудования и аттестованных специалистов',
          'Номенклатурой ПС, наличием необходимого оборудования и квалификацией работников',
        ],
        correctAnswers: [
          'Номенклатурой ПС и технологическими процессами, заявленными организацией для своей последующей деятельности',
        ],
      },
      {
        code: '63703032',
        text:
          'На какую организацию возлагается ответственность за эксплуатацию ПС, не оборудованного ограничителями, указателями и регистраторами, необходимыми для обеспечения промышленной безопасности технологического процесса, в котором используется ПС?',
        answers: [
          'На эксплуатирующую ПС организацию',
          'На экспертную организацию, проводившую экспертизу промышленной безопасности ПС',
          'На сертификационный центр и испытательную лабораторию, выдавших сертификат/декларацию соответствия ПС',
          'На специализированную организацию, выполнившую ремонт ПС',
        ],
        correctAnswers: ['На эксплуатирующую ПС организацию'],
      },
      {
        code: '63703033',
        text:
          'Требованиям какого документа должно соответствовать выполнение погрузочно-разгрузочных работ на монтаже с применением ПС?',
        answers: [
          'Руководства (инструкции) по монтажу ПС',
          'Проекта производства работ',
          'Паспорта ПС',
          'Технологической карты',
        ],
        correctAnswers: ['Руководства (инструкции) по монтажу ПС'],
      },
      {
        code: '63703034',
        text:
          'Кто может выполнять работы по неразрушающему контролю, если работы по монтажу, ремонту, реконструкции или модернизации выполнялись с применением сварки?',
        answers: [
          'Собственная аттестованная лаборатория или аттестованная лаборатория, привлекаемая на договорной основе',
          'Собственная аттестованная лаборатория, имеющая поверенные приборы неразрушающего контроля',
          'Любые специалисты по неразрушающему контролю, если выполнение работ включает необходимость контроля стыковых сварных швов',
          'Физические лица, аттестованные в установленном порядке по выполнению неразрушающего контроля',
        ],
        correctAnswers: [
          'Собственная аттестованная лаборатория или аттестованная лаборатория, привлекаемая на договорной основе',
        ],
      },
      {
        code: '63703035',
        text:
          'Какова должна быть длина фала страховочного устройства человека, соединяющего его пояс с местом крепления в подвесной люльке при ее транспортировке краном?',
        answers: [
          'Не более 1,5 метров',
          'Не более 2,5 метров',
          'Длина фала страховочного устройства должна быть такой, чтобы человек в случае аварии люльки мог беспрепятственно выбраться наружу',
          'Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки',
        ],
        correctAnswers: [
          'Длина фала страховочного устройства должна быть такой, чтобы человек в любом случае оставался в пределах люльки',
        ],
      },
      {
        code: '63703036',
        text:
          'Какая организация должна располагать контрольно-измерительными приборами, позволяющими оценивать работоспособность и регулировку оборудования ПС при проведении ремонта (монтажа)?',
        answers: [
          'Специализированная организация',
          'Эксплуатирующая организация',
          'Надзорная организация',
          'Организация – поставщик ПС',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63703037',
        text:
          'Какими нормативными документами необходимо руководствоваться при выборе оборудования для безопасного выполнения работ по ремонту, реконструкции или модернизации ПС?',
        answers: [
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Постановлением Правительства РФ от 30.07.2004 N 401 "О Федеральной службе по экологическому, технологическому и атомному надзору"',
          'Паспортом ПС',
          'Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС',
        ],
        correctAnswers: [
          'Указаниями по ремонту, а также требованиями к составу работ, приведенными в руководстве (инструкции) по эксплуатации данного ПС',
        ],
      },
      {
        code: '63703038',
        text:
          'Чему равен допустимый остаточный прогиб пролетного строения кранов мостового типа в вертикальной плоскости, где L - пролет крана?',
        answers: ['0,0025L', '0,0055L', '0,0045L', '0,0035L'],
        correctAnswers: ['0,0035L'],
      },
      {
        code: '63703039',
        text:
          'В каких случаях должна поддерживаться радио- или телефонная связь между оператором подъемника и персоналом в люльке?',
        answers: [
          'При подъеме люльки на высоту более 10 метров',
          'При подъеме люльки на высоту более 22 метров',
          'При работе подъемника на краю откоса или вблизи ЛЭП',
          'При работе подъемника в условиях плохой видимости',
        ],
        correctAnswers: ['При подъеме люльки на высоту более 22 метров'],
      },
      {
        code: '63703040',
        text:
          'Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в производственно-технологической документации по сварке (далее – ПТД)?',
        answers: [
          'Только приспособления и оборудование, используемые при сборке',
          'Только способы крепления деталей',
          'Только размеры, количество и расположение прихваток',
          'Только порядок и последовательность сборки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63703041',
        text:
          'На каком этапе осуществляется оценка соответствия зданий и сооружений, а также связанных со зданиями и сооружениями процессов проектирования (включая изыскания) в форме заявления о соответствии проектной документации требованиям Технического регламента о безопасности зданий и сооружений?',
        answers: [
          'На усмотрение лица, подготовившего проектную документацию',
          'После утверждения проектной документации в соответствии с законодательством о градостроительной деятельности',
          'До утверждения проектной документации в соответствии с законодательством о градостроительной деятельности',
          'Не регламентируется',
        ],
        correctAnswers: [
          'До утверждения проектной документации в соответствии с законодательством о градостроительной деятельности',
        ],
      },
      {
        code: '63703042',
        text:
          'Какие из нижеперечисленных ПС не подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны стрелового типа грузоподъемностью более 1 т',
          'Консольные краны грузоподъемностью более 10 т',
          'Краны на железнодорожном ходу',
          'Краны-трубоукладчики',
        ],
        correctAnswers: ['Краны-трубоукладчики'],
      },
      {
        code: '63703043',
        text:
          'Кто имеет право вносить изменения в ППР и ТК в процессе монтажа?',
        answers: [
          'Организация, отвечающая за выполнение монтажа',
          'Эксплуатирующая организация',
          'Проектная организация',
          'Изготовитель монтируемого ПС',
        ],
        correctAnswers: ['Организация, отвечающая за выполнение монтажа'],
      },
      {
        code: '63703044',
        text:
          'Какой параметр из паспорта ПС (в виде выписки) в обязательном порядке должен быть включен в раздел ППР и ТК, связанный с организацией безопасного производства работ?',
        answers: [
          'Максимальная грузоподъемность или максимальный грузовой момент',
          'Справка об установленных указателях, ограничителях и регистраторах',
          'Сила ветра, при которой его работа не допускается',
          'Высота подъема',
          'Вылет стрелы',
        ],
        correctAnswers: ['Сила ветра, при которой его работа не допускается'],
      },
      {
        code: '63703045',
        text:
          'Каким требованиям должны соответствовать стальные цепи, устанавливаемые на ПС?',
        answers: [
          'Стальные цепи должны соответствовать по марке, диаметру и разрывному усилию указанным в паспорте ПС',
          'Стальные цепи должны соответствовать по марке и разрывному усилию, указанным в паспорте ПС, иметь сертификат предприятия-изготовителя цепи',
          'Стальные цепи должны иметь сертификат и пройти испытание в соответствии с требованиями ГОСТ Р 56944-2016 "Краны грузоподъемные"',
          'Стальные цепи должны иметь сертификат и иметь коэффициент запаса прочности не менее 4',
        ],
        correctAnswers: [
          'Стальные цепи должны соответствовать по марке и разрывному усилию, указанным в паспорте ПС, иметь сертификат предприятия-изготовителя цепи',
        ],
      },
      {
        code: '63703046',
        text:
          'Каким документом определяется объем работ, порядок и периодичность проведения технических освидетельствований ПС?',
        answers: [
          'Техническим регламентом Таможенного союза ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Положением о Федеральной службе по экологическому, технологическому и атомному надзору',
          'Паспортом ПС',
          'Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний, требованиями ФНП ПС',
        ],
        correctAnswers: [
          'Руководством (инструкцией) по эксплуатации ПС, а при отсутствии в ней указаний, требованиями ФНП ПС',
        ],
      },
      {
        code: '63703047',
        text:
          'Допускается ли пересечение путей козловых, башенных и портальных кранов с рельсовыми путями заводского транспорта?',
        answers: [
          'Допускается, только после выполнения мероприятий по безопасному ведению работ на рельсовых путях',
          'Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласования с организацией, в ведении которой находится организация движения на железнодорожных путях',
          'Допускается, только при согласовании с территориальным органом Ростехнадзора',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, только после разработки мероприятий по предупреждению столкновения работающих кранов с подвижным составом и согласования с организацией, в ведении которой находится организация движения на железнодорожных путях',
        ],
      },
      {
        code: '63703048',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного нормативной документацией (далее – НД), независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку знания теоретических основ сварки',
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63703049',
        text:
          'Какие требования к статическим испытаниям грузозахватного приспособления указаны верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Испытания проводятся статической нагрузкой, превышающей его грузоподъемность на 50 процентов',
          'Статические испытания грузозахватного приспособления проводят статической нагрузкой, превышающей его грузоподъемность на 25 процентов',
          'Испытательный груз, поднятный на высоту 200 мм выдерживают в таком положении не менее 5 минут',
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимается на высоту 50-100 мм',
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимают ПС на высоту 150-250 мм',
        ],
        correctAnswers: [
          'Статические испытания грузозахватного приспособления проводят статической нагрузкой, превышающей его грузоподъемность на 25 процентов',
          'Испытательный груз, зацепленный (охваченный, обвязанный) испытываемым грузозахватным приспособлением или подвешенный к нему, с возможно меньшими ускорениями поднимается на высоту 50-100 мм',
        ],
      },
      {
        code: '63703050',
        text:
          'Что из перечисленного должно контролироваться в процессе сварки, как минимум?',
        answers: [
          'Только соответствие параметров режима сварки и технологических приемов выполнения сварного соединения',
          'Только очередность выполнения сварных швов и участков наплавки',
          'Только отсутствие видимых дефектов',
          'Только параметры, предусмотренные в технологических (операционных) картах сварки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63703051',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
          'Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение мерам пожарной безопасности',
          'Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63703052',
        text:
          'Какое расстояние установлено по вертикали от консоли противовеса башенного крана до площадок, на которых могут находиться люди?',
        answers: [
          'Не менее 1500 мм',
          'Не менее 1700 мм',
          'Не менее 1000 мм',
          'Не менее 2000 мм',
        ],
        correctAnswers: ['Не менее 2000 мм'],
      },
      {
        code: '63703053',
        text:
          'Кто выдает разрешение на дальнейшую эксплуатацию подъемника (вышки) по завершению выполнения периодического планового технического освидетельствования?',
        answers: [
          'Специалист, ответственный за содержание ПС в работоспособном состоянии',
          'Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС',
          'Работник технического подразделения, ответственный за содержание ПС в работоспособном состоянии при участии специалиста, ответственного за безопасное производство работ с применением ПС',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Руководитель эксплуатирующей организации, которой принадлежит подъемник (вышка)',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС',
        ],
      },
      {
        code: '63703054',
        text:
          'Какие требования к сборке и соединению сборочных единиц ПС указаны неверно?',
        answers: [
          'Сборка и соединение отдельных сборочных единиц ПС должны выполняться согласно требованиям руководства (инструкции) по эксплуатации и другой эксплуатационной документации ПС',
          'Крупногабаритные сборочные единицы ПС должны укладываться на место последующего монтажа с применением грузоподъемных механизмов',
          'Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должны выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС',
          'Фактическая несоосность (непараллельность) стыкуемых сборочных единиц не должна превышать величин соответствующих допусков, приведенных в руководстве (инструкции) по эксплуатации ПС',
        ],
        correctAnswers: [
          'Сборка и монтаж металлоконструкций самомонтируемых козловых и башенных кранов должны выполняться на участке подготовленного наземного рельсового пути согласно указаниям специалиста, ответственного за безопасное производство работ с применением ПС',
        ],
      },
      {
        code: '63703055',
        text:
          'Кого относят к работникам специализированных организаций, занимающихся выполнением работ по монтажу (демонтажу), наладке либо ремонту, реконструкции или модернизации в процессе эксплуатации ПС?',
        answers: [
          'Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
          'Аттестованных специалистов и лиц рабочих профессий',
          'Руководителей, специалистов и персонал - лиц рабочих профессий основных служб организации',
          'Физических лиц основных служб организации, непосредственно занятых выполнением работ',
        ],
        correctAnswers: [
          'Инженерно-технических работников, имеющих высшее или среднее профессиональное образование, и персонал - лиц рабочих профессий, непосредственно занятых на выполнении работ',
        ],
      },
      {
        code: '63703056',
        text:
          'В каких случаях разрешается строповка пакетов металлопроката или труб за элементы упаковки (скрутки, стяжки)?',
        answers: [
          'Если они применяются только для двух последующих перегрузок: например, для загрузки в полувагон и последующей разгрузки',
          'Если подъем и перемещение пакетов металлопроката или труб выполняют с помощью траверсы, крюки которой застропованы в скрутки',
          'Если скрутки выполнены из мягкой стальной проволоки и их не менее четырех',
          'Запрещается во всех случаях',
        ],
        correctAnswers: ['Запрещается во всех случаях'],
      },
      {
        code: '63703057',
        text:
          'В каком положении выполняют статические испытания подъемников (кроме строительных)?',
        answers: [
          'В положении, отвечающем его наименьшей расчетной устойчивости',
          'В положении, отвечающем его наибольшей расчетной устойчивости',
          'В положении продольной оси стрелы вдоль продольной оси подъемника',
          'В положении продольной оси стрелы, составляющей угол 45° с продольной осью подъемника',
        ],
        correctAnswers: [
          'В положении, отвечающем его наименьшей расчетной устойчивости',
        ],
      },
      {
        code: '63703058',
        text:
          'Что из нижеперечисленного не содержит в себе проект ремонта, реконструкции или модернизации ПС с применением сварки?',
        answers: [
          'Нормы браковки сварных соединений',
          'Порядок приемки из ремонта отдельных узлов и готовых изделий',
          'Проектно-сметную документацию на ремонтные работы',
          'Указания о применяемых металлах и сварочных материалах',
          'Способы проверки качества сварочных работ',
        ],
        correctAnswers: ['Проектно-сметную документацию на ремонтные работы'],
      },
      {
        code: '63703059',
        text:
          'Какой документ регламентирует интервал проведения плановых ремонтов ПС?',
        answers: [
          'Протокол технического освидетельствования',
          'Паспорт ПС',
          'Ведомость дефектов',
          'Руководство (инструкция) по эксплуатации ПС',
        ],
        correctAnswers: ['Руководство (инструкция) по эксплуатации ПС'],
      },
      {
        code: '63703060',
        text:
          'В каких случаях разрешены подъем и транспортировка людей с применением ПС, в паспорте которых отсутствует разрешение на транспортировку людей?',
        answers: [
          'Только в случаях монтажа, строительства и возведения уникальных объектов, когда иные способы доставки рабочих в зону выполнения работ не могут быть применены',
          'Только в случаях проведения диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно',
          'Только в случаях перемещения персонала для крепления и раскрепления контейнеров и грузов',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63703061',
        text:
          'Какие из перечисленных ниже ПС не подлежат экспертизе промышленной безопасности?',
        answers: [
          'Не отработавшие срок службы',
          'Краны стрелового типа грузоподъемностью более 1 т',
          'Электрические тали грузоподъемностью более 10 т',
          'Не подлежащие учету в органах Ростехнадзора',
        ],
        correctAnswers: ['Не подлежащие учету в органах Ростехнадзора'],
      },
      {
        code: '63703062',
        text: 'Что должно проводиться после реконструкции ПС?',
        answers: [
          'Внеочередное частичное техническое освидетельствование',
          'Внеочередное полное техническое освидетельствование',
          'Периодическое частичное техническое освидетельствование',
          'Периодическое полное техническое освидетельствование',
        ],
        correctAnswers: ['Внеочередное полное техническое освидетельствование'],
      },
      {
        code: '63703063',
        text:
          'Какие из указанных действий допускается предпринимать во время проведения монтажных работ ПС, если на монтажной площадке имеются действующие переходы (проезды) и выходы из прилегающих зданий? Укажите все правильные ответы.',
        answers: [
          'Запретить работать с ПС',
          'Закрыть переходы (проезды) и выходы из прилегающих зданий',
          'Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)',
          'Назначить сигнальщика',
        ],
        correctAnswers: [
          'Закрыть переходы (проезды) и выходы из прилегающих зданий',
          'Оборудовать переходы (проезды) и выходы из прилегающих зданий средствами, обеспечивающими безопасность (козырьками, галереями)',
        ],
      },
      {
        code: '63703064',
        text:
          'Какие требования к передвижению стрелового самоходного крана указаны неверно?',
        answers: [
          'Основание, по которому перемещается кран с грузом, должно быть ровным и иметь уклон не более указанного в руководстве (инструкции) по эксплуатации крана',
          'Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана',
          'Основание, по которому перемещается кран с грузом, должно иметь твердое покрытие, выдерживающее без просадки удельное давление не менее величин, указанных в паспорте или руководстве (инструкции) по эксплуатации крана',
          'Стреловым самоходным кранам разрешается перемещаться с грузом на крюке',
        ],
        correctAnswers: [
          'Движение крана с места при раскачивающемся грузе разрешается при отклонении последнего на угол, не более указанного в руководстве (инструкции) по эксплуатации крана',
        ],
      },
      {
        code: '63703065',
        text:
          'Какие требования к проверке ограничителя предельного верхнего положения грузозахватного органа указаны неверно?',
        answers: [
          'Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором',
          'Проверка ограничителя должна проводиться без груза',
          'Проверка осуществляется путем контроля с замером расстояния между верхней точкой грузозахватного органа и нижней частью металлоконструкции',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Проверка осуществляется путем контроля с замером расстояния между нижней точкой грузозахватного органа и упором',
        ],
      },
      {
        code: '63703066',
        text:
          'По каким точкам грузовой характеристики должна проводиться проверка ограничителя грузового момента, если грузоподъемность ПС изменяется в зависимости от вылета, положения грузовой тележки или пространственного положения элемента ПС?',
        answers: [
          'Не менее чем в трех точках его грузовой характеристики',
          'Не менее чем в двух точках его грузовой характеристики',
          'Только в двух точках его грузовой характеристики',
          'Правилами не регламентируется',
        ],
        correctAnswers: [
          'Не менее чем в трех точках его грузовой характеристики',
        ],
      },
      {
        code: '63703067',
        text:
          'Какой запас по грузоподъемности должны иметь канатные стропы, используемые для подвеса люльки?',
        answers: ['Не менее 10', 'Не менее 8', 'Не менее 6', 'Не менее 5'],
        correctAnswers: ['Не менее 10'],
      },
      {
        code: '63703068',
        text:
          'Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?',
        answers: [
          'Только входной контроль',
          'Только операционный контроль',
          'Только приемочный контроль',
          'Все перечисленные виды контроля',
        ],
        correctAnswers: ['Все перечисленные виды контроля'],
      },
      {
        code: '63703069',
        text:
          'С какой перегрузкой проводятся испытания на строительных подъемниках при проведении полного технического освидетельствования и проверки работоспособности ловителей (аварийных остановов)?',
        answers: ['0,1', '0,15', '0,25', '0,2'],
        correctAnswers: ['0,1'],
      },
      {
        code: '63703070',
        text:
          'Кем определяется необходимость разработки требований к содержанию разделов проектной документации, наличие которых согласно Положению о составе разделов проектной документации и требованиях к их содержанию не является обязательным?',
        answers: [
          'По согласованию с представителями Ростехнадзора',
          'По согласованию между заказчиком и Ростехнадзором',
          'По согласованию между проектной организацией и заказчиком такой документации',
          'По согласованию между представителем Ростехнадзора и проектной организацией',
        ],
        correctAnswers: [
          'По согласованию между проектной организацией и заказчиком такой документации',
        ],
      },
      {
        code: '63703071',
        text:
          'Каким документом должно быть подтверждено соответствие проекту рельсового пути (для ПС на рельсовом ходу) по результатам выполнения монтажа?',
        answers: [
          'Актом освидетельствования скрытых работ',
          'Актом сдачи-приемки монтажного участка пути или актом сдачи-приемки рельсового пути под монтаж, если к проведению монтажа путь монтировался на всю рабочую длину',
          'Экспертным заключением соответствия требованиям по эксплуатации ПС',
        ],
        correctAnswers: [
          'Актом сдачи-приемки монтажного участка пути или актом сдачи-приемки рельсового пути под монтаж, если к проведению монтажа путь монтировался на всю рабочую длину',
        ],
      },
      {
        code: '63703072',
        text:
          'Кто должен выполнять работы на регистраторах, ограничителях и указателях ПС?',
        answers: [
          'Работники, допущенные учебным центром к работе на регистраторах, ограничителях и указателях на основании проверки знаний',
          'Только работники эксплуатирующей ПС организации, прошедшие соответствующую аттестацию и проверку знаний по промышленной безопасности',
          'Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей',
          'Работники специализированных организаций, имеющих допуск на производство этих работ, выданный изготовителями регистраторов, ограничителей и указателей',
        ],
        correctAnswers: [
          'Работники специализированных организаций, квалификация которых соответствует требованиям изготовителей (разработчиков), изложенным в эксплуатационных документах ПС, регистраторов, ограничителей и указателей',
        ],
      },
      {
        code: '63703073',
        text:
          'Какие краны, не оборудованные координатной защитой, запрещается применять для работы в стесненных условиях?',
        answers: [
          'Только башенные краны, установленные на автомобильные шасси',
          'Только грузоподъемные краны на пневмоколесном ходу',
          'Только грузоподъемные краны на гусеничном ходу',
          'Все перечисленные краны',
        ],
        correctAnswers: ['Все перечисленные краны'],
      },
      {
        code: '63703074',
        text:
          'Каким оборудованием в эксплуатирующей организации должны быть обеспечены ее стропальщики, с целью обеспечения промышленной безопасности технологических процессов строповки?',
        answers: [
          'Канатными и цепными стропами, соответствующими массе перемещаемых грузов',
          'Определяется технологическими процессами транспортировки грузов, принятыми в эксплуатирующей организации',
          'Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов',
          'Ручным инструментом, включая ножовку по дереву, лом или монтировку',
        ],
        correctAnswers: [
          'Испытанными и маркированными грузозахватными приспособлениями и тарой, соответствующими массе и характеру перемещаемых грузов',
        ],
      },
      {
        code: '63703075',
        text:
          'Какой износ головки рельса является условием для браковки кранового пути опорных кранов?',
        answers: [
          'Более 13% от соответствующего размера неизношенного профиля',
          'Более 10% от соответствующего размера неизношенного профиля',
          'Более 8% от соответствующего размера неизношенного профиля',
          'Более 15% от соответствующего размера неизношенного профиля',
        ],
        correctAnswers: [
          'Более 15% от соответствующего размера неизношенного профиля',
        ],
      },
      {
        code: '63703076',
        text:
          'В каких случаях при наличии на ПС двух механизмов подъема их статические испытания следует проводить одновременно?',
        answers: [
          'Только для кранов, оснащенных двухканатным грейфером (с механизмами подъема и замыкания грейфера)',
          'Только для литейных кранов, у которых вспомогательный подъем осуществляет поворот поднятого ковша',
          'Если это предусмотрено паспортом ПС',
          'Только для контейнерных кранов, спредер которых поднимают одновременно два механизма подъема',
        ],
        correctAnswers: ['Если это предусмотрено паспортом ПС'],
      },
      {
        code: '63703077',
        text:
          'Каким требованиям из перечисленных должны отвечать рельсовый путь ПС (исключая рельсовые пути башенных и железнодорожных кранов) и рельсовый путь грузовых подвесных тележек или электрических талей, оборудованный стрелками или поворотными кругами, а также места перехода ПС или его грузовой тележки с одного пути на другой?',
        answers: [
          'Обеспечивать плавный, без заеданий, проезд',
          'Быть оборудованными замками с электрической блокировкой, исключающей переезд при незапертом замке',
          'Иметь автоматически включаемую блокировку, исключающую сход грузовой тележки (электрической тали) с рельса при выезде ее на консоль расстыкованного участка пути',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63703078',
        text:
          'С какой периодичностью проводится плановая проверка состояния люльки (кабины)?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полугодие',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63703079',
        text:
          'Кто определяет порядок работы крана вблизи линии электропередачи, выполненной гибким изолированным кабелем?',
        answers: [
          'Эксплуатирующая организация',
          'Разработчик ППР',
          'Владелец линии',
          'Специализированная организация',
          'Определение порядка работы не требуется, так как изолированный кабель безопасен',
        ],
        correctAnswers: ['Владелец линии'],
      },
      {
        code: '63703080',
        text:
          'В каких из перечисленных случаев эксплуатирующая организация имеет право допустить ПС в работу?',
        answers: [
          'Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования',
          'Отсутствует экспертиза промышленной безопасности ПС, отработавшего срок службы',
          'На ПС выявлены трещины в расчетных элементах металлоконструкции',
          'Отсутствуют соответствующие массе и виду перемещаемых грузов съемные грузозахватные приспособления и тара',
        ],
        correctAnswers: [
          'Для редко используемого ПС прошло более трех лет с момента проведения предыдущего технического освидетельствования',
        ],
      },
      {
        code: '63703081',
        text:
          'Что служит основанием для решения о пуске в работу после установки на объекте кранов мостового типа и портальных кранов?',
        answers: [
          'Предложение комиссии о возможности пуска',
          'Положительное заключение экспертизы промышленной безопасности',
          'Предписание территориального органа Ростехнадзора',
          'Заключение завода-изготовителя о возможности пуска',
        ],
        correctAnswers: ['Предложение комиссии о возможности пуска'],
      },
      {
        code: '63703082',
        text:
          'Кто разрабатывает проект для реконструкции или модернизации ограничителя, указателя или регистратора в случае, когда изготовителя ПС установить невозможно?',
        answers: [
          'Специализированная организация',
          'Эксплуатирующая организация',
          'Ростехнадзор',
          'Изготовитель аналогичного оборудования, характеристики которого соответствуют заводским',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63703083',
        text:
          'Кто должен руководить погрузочно-разгрузочными работами при выполнении монтажа ПС?',
        answers: [
          'Производитель погрузочно-разгрузочных работ',
          'Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС',
          'Специалист, ответственный за содержание ПС в работоспособном состоянии',
          'Начальник участка, на котором выполняется монтаж данного ПС',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС',
        ],
      },
      {
        code: '63703084',
        text:
          'К какому уровню ответственности относятся здания и сооружения, отнесенные в соответствии с Градостроительным кодексом Российской Федерации к особо опасным, технически сложным или уникальным объектам?',
        answers: ['К повышенному', 'К нормальному', 'К пониженному'],
        correctAnswers: ['К повышенному'],
      },
      {
        code: '63703085',
        text:
          'Каким грузом следует проводить динамические испытания подъемника (вышки)?',
        answers: [
          'Грузом, масса которого на 20% превышает его паспортную грузоподъемность',
          'Грузом, масса которого на 10% превышает его паспортную грузоподъемность',
          'Грузом, масса которого на 5% превышает его паспортную грузоподъемность',
          'Грузом, масса которого на 25% превышает его паспортную грузоподъемность',
        ],
        correctAnswers: [
          'Грузом, масса которого на 10% превышает его паспортную грузоподъемность',
        ],
      },
      {
        code: '63703086',
        text:
          'В каких случаях разрешается нахождение инструментов и материалов совместно с людьми в подвесных люльках, транспортируемых кранами?',
        answers: [
          'Во всех случаях, если инструменты и материалы надежно закреплены',
          'Во всех случаях запрещено',
          'Только в случаях, если инструменты и материалы находятся в руках транспортируемых людей',
          'Только в случаях транспортировки людей для проведения диагностирования и ремонта металлоконструкций ПС, когда применение других средств подмащивания невозможно',
        ],
        correctAnswers: [
          'Во всех случаях, если инструменты и материалы надежно закреплены',
        ],
      },
      {
        code: '63703087',
        text:
          'В какой форме осуществляется обязательная оценка соответствия зданий и сооружений, а также связанных со зданиями и с сооружениями процессов эксплуатации?',
        answers: [
          'В форме государственной экспертизы результатов инженерных изысканий и проектной документации',
          'В форме эксплуатационного и государственного контроля (надзора)',
          'В форме заявления о соответствии построенного, реконструированного или отремонтированного здания или сооружения проектной документации',
          'В форме строительного контроля',
        ],
        correctAnswers: [
          'В форме эксплуатационного и государственного контроля (надзора)',
        ],
      },
      {
        code: '63703088',
        text:
          'Какие из нижеперечисленных ПС подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны стрелового типа грузоподъемностью до 1 т включительно',
          'Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении',
          'Краны стрелового типа с постоянным вылетом или не снабженные механизмом поворота',
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
        correctAnswers: [
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
      },
      {
        code: '63703089',
        text:
          'В соответствии с требованиями какого документа должна осуществляться эксплуатация дымовых и вентиляционных промышленных труб?',
        answers: [
          'В соответствии с Федеральным законом от 30.12.2009 N 384-ФЗ "Технический регламент о безопасности зданий и сооружений"',
          'В соответствии с Правилами технической эксплуатации тепловых энергоустановок',
          'В соответствии с Правилами техники безопасности при эксплуатации тепломеханического оборудования электростанций и тепловых сетей',
        ],
        correctAnswers: [
          'В соответствии с Федеральным законом от 30.12.2009 N 384-ФЗ "Технический регламент о безопасности зданий и сооружений"',
        ],
      },
      {
        code: '63703090',
        text:
          'С какой периодичностью в эксплуатации следует осматривать траверсы, клещи, захваты и тару?',
        answers: [
          'Каждый месяц',
          'Один раз в год',
          'Каждую неделю',
          'Перед началом каждой рабочей смены',
          'Каждые 10 дней',
        ],
        correctAnswers: ['Каждый месяц'],
      },
      {
        code: '63703091',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'Необходимым сварочным оборудованием и наглядными пособиями (плакатами, схемами и т. д.) по вопросу соблюдения противопожарного режима на объекте',
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
          'На рабочем месте не должно быть ничего, кроме исправного сварочного оборудования',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63703092',
        text:
          'Какие из перечисленных ПС не подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны-трубоукладчики',
          'Краны на железнодорожном ходу',
          'Краны стрелового типа грузоподъемностью более 1 т',
          'Краны мостового типа грузоподъемностью более 10 т',
        ],
        correctAnswers: ['Краны-трубоукладчики'],
      },
      {
        code: '63703093',
        text:
          'Где указывается суммарная длина контролируемых участков сварных соединений?',
        answers: [
          'В паспорте ПС',
          'В проекте ремонта, реконструкции или модернизации ПС',
          'В стандарте организации, разработанном специализированной организацией',
          'В технологической карте на реконструкцию',
        ],
        correctAnswers: [
          'В проекте ремонта, реконструкции или модернизации ПС',
        ],
      },
      {
        code: '63703094',
        text:
          'Кто назначается председателем комиссии, на основании предложений которой принимается решение о пуске в работу ПС, отработавшего срок службы, при смене эксплуатирующей организации?',
        answers: [
          'Уполномоченный представитель Ростехнадзора',
          'Уполномоченный представитель специализированной организации',
          'Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Уполномоченный представитель эксплуатирующей организации',
        ],
        correctAnswers: [
          'Уполномоченный представитель эксплуатирующей организации',
        ],
      },
      {
        code: '63703095',
        text:
          'Когда проводится осмотр состояния рельсовых путей ПС под руководством специалиста, ответственного за содержание ПС в работоспособном состоянии?',
        answers: [
          'После каждых 24 смен работы',
          'После каждых 32 смен работы',
          'После каждых 46 смен работы',
          'После каждых 50 смен работы',
        ],
        correctAnswers: ['После каждых 24 смен работы'],
      },
      {
        code: '63703096',
        text:
          'Какая организация осуществляет периодическое комплексное обследование рельсовых путей ПС?',
        answers: [
          'Эксплуатирующая организация',
          'Экспертная организация',
          'Специализированная организация',
          'Проектная организация',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63703097',
        text:
          'Какие работы относятся к работам в местах действия опасных факторов?',
        answers: [
          'Только монтаж конструкций, имеющих большую парусность и габариты (витражи, фермы, перегородки, стеновые панели)',
          'Только монтаж в зоне примыкания к эксплуатируемым зданиям',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63703098',
        text:
          'Какие действия необходимо выполнить для утилизации (ликвидации) подъемника (вышки)?',
        answers: [
          'Получить отметку Ростехнадзора в паспорте подъемника (вышки) и отправить в металлолом',
          'Демонтировать подъемник (вышку)',
          'Получить письменное указание владельца ОПО и отправить в металлолом',
          'Не продлевать срок эксплуатации подъемника (вышки) по истечению срока службы, указанного в его паспорте',
        ],
        correctAnswers: ['Демонтировать подъемник (вышку)'],
      },
      {
        code: '63703099',
        text:
          'Сколько раз допускается повторная сварка на одном и том же участке сварного соединения?',
        answers: [
          'Не более трех раз',
          'Не более двух раз',
          'Не более четырех раз',
          'Повторная сварка запрещена',
        ],
        correctAnswers: ['Не более двух раз'],
      },
      {
        code: '63703100',
        text:
          'Грузом какой массы следует выполнять грузовые испытания люльки (кабины) для транспортировки кранами людей?',
        answers: [
          'Равной грузоподъемности люльки',
          'В полтора раза превышающей грузоподъемность люльки',
          'В два раза превышающей грузоподъемность люльки',
          'В три раза превышающей грузоподъемность люльки',
        ],
        correctAnswers: ['В два раза превышающей грузоподъемность люльки'],
      },
      {
        code: '63703101',
        text:
          'В каких случаях разрешается перемещение грузов, находящихся в неустойчивом положении?',
        answers: [
          'Только в случаях выравнивая несимметрично уложенного груза, при задевании им о борта кузова автомобиля или полувагона',
          'Только в аварийных ситуациях, когда необходимо как можно быстрее переместить груз от источника возникновения аварии',
          'В случае частичного подъема и разворота груза без полного его отрыва от земли',
          'В случае, когда осуществляется кантовка этого груза',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63703102',
        text:
          'С какой периодичностью производятся частичная разборка, осмотр и ревизия элементов, узлов и соединений грузозахватных приспособлений (клещи, траверсы, захваты) для контроля технического состояния, которое невозможно определить в собранном виде?',
        answers: ['Еженедельно', 'Ежемесячно', 'Ежеквартально', 'Ежегодно'],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63703103',
        text:
          'В каком из перечисленных случаев при внеочередном полном техническом освидетельствовании ПС проводятся только статические испытания?',
        answers: [
          'После установки сменного стрелового оборудования или замены стрелы',
          'После замены грузозахватного органа',
          'После капитального ремонта или замены грузовой или стреловой лебедки',
          'После замены несущих или вантовых канатов кранов кабельного типа',
        ],
        correctAnswers: ['После замены грузозахватного органа'],
      },
      {
        code: '63703104',
        text:
          'В каком случае проводятся испытания на устойчивость при повторных технических освидетельствованиях для всех кранов стрелового типа и подъемников (вышек), у которых люлька закреплена на оголовке стрелы?',
        answers: [
          'После проведения ремонта расчетных элементов металлоконструкций с заменой элементов',
          'После проведения ремонта с применением сварки',
          'По требованию представителя Ростехнадзора',
          'Если это указано в их руководстве (инструкции) по эксплуатации',
        ],
        correctAnswers: [
          'Если это указано в их руководстве (инструкции) по эксплуатации',
        ],
      },
      {
        code: '63703105',
        text:
          'Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка стрелового крана, крана-манипулятора только на две или три выносные опоры?',
        answers: [
          'Только если подъем и перемещение груза будет выполняться только в одном положении стрелы',
          'Только если отсутствуют места на площадке установки для всех четырех опор',
          'Только если отсутствует одна из инвентарных подкладок, устанавливаемых под опору',
          'Не разрешается, ПС устанавливается на все выносные опоры',
          'Только если имеется минимальный уклон одной из частей площадки установки',
        ],
        correctAnswers: [
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
      },
      {
        code: '63703106',
        text:
          'В соответствии с требованиями какого документа выполняется проверка работоспособности указателя (сигнализатора) предельной скорости ветра (анемометра) и креномера (указателя угла наклона ПС)?',
        answers: [
          'Руководств (инструкций) по эксплуатации анемометра и креномера',
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения"',
          'Руководства по эксплуатации ПС',
          'Руководств по качеству организации и выполнения калибровочных работ',
        ],
        correctAnswers: [
          'Руководств (инструкций) по эксплуатации анемометра и креномера',
        ],
      },
      {
        code: '63703107',
        text:
          'В течение какого времени допускается временное хранение профильного проката на открытом воздухе?',
        answers: [
          'В течение 4 месяцев со дня поставки',
          'В течение 5 месяцев со дня поставки',
          'В течение 7 месяцев со дня поставки',
          'В течение 3 месяцев со дня поставки',
        ],
        correctAnswers: ['В течение 3 месяцев со дня поставки'],
      },
      {
        code: '63703108',
        text:
          'Кто должен проводить техническое освидетельствование подъемника (вышки)?',
        answers: [
          'Специалист, ответственный за содержание ПС в работоспособном состоянии',
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии',
          'Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за безопасное производство работ с применением ПС',
          'Комиссия эксплуатирующей организации, в состав которой должен входить представитель органов Ростехнадзора',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за осуществление производственного контроля при эксплуатации ПС, при участии специалиста, ответственного за содержание ПС в работоспособном состоянии',
        ],
      },
      {
        code: '63703109',
        text:
          'На какие из перечисленных ниже опасные производственные объекты (далее - ОПО) не распространяются требования Федеральных норм и правил в области промышленной безопасности "Правила безопасности опасных производственных объектов, на которых используются подъемные сооружения" (далее - ФНП ПС)?',
        answers: [
          'На ОПО, где эксплуатируются подъемники (вышки), предназначенные для перемещения людей, груза и людей (подъемники с рабочими платформами) с высотой подъема более 6 м',
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
          'На ОПО, где эксплуатируются грузоподъемные краны',
          'На ОПО, где эксплуатируются канатные дороги',
          'На ОПО, где эксплуатируются строительные подъемники',
        ],
        correctAnswers: ['На ОПО, где эксплуатируются канатные дороги'],
      },
      {
        code: '63703110',
        text:
          'Для каких типов подъемников при проведении статических испытаний часть испытательного груза подвешивают к люльке на гибкой подвеске?',
        answers: [
          'Для строительных подъемников',
          'Для всех подъемников, оборудованных люлькой, кроме строительных',
          'Для подъемников ножничного типа',
          'Для подъемников, имеющих специальный крюк',
        ],
        correctAnswers: [
          'Для всех подъемников, оборудованных люлькой, кроме строительных',
        ],
      },
      {
        code: '63703111',
        text:
          'Что не указывается на табличках, которыми должны быть снабжены находящиеся в эксплуатации подьемные сооружения (ПС)?',
        answers: [
          'Только учетный номер ПС',
          'Только заводской номер ПС и паспортная грузоподъемность',
          'Только дата следующего полного и частичного технического освидетельствований',
          'Указывается все перечисленное',
        ],
        correctAnswers: ['Указывается все перечисленное'],
      },
      {
        code: '63703112',
        text:
          'Каким критериям должен соответствовать выбор оборудования для безопасного выполнения работ по монтажу (демонтажу) ПС?',
        answers: [
          'Только конкретному монтируемому ПС',
          'Только эксплуатационным документам ПС',
          'Только составу работ, предусмотренному руководством (инструкцией) по эксплуатации ПС',
          'Всем перечисленным критериям',
        ],
        correctAnswers: ['Всем перечисленным критериям'],
      },
      {
        code: '63703113',
        text:
          'Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?',
        answers: [
          'Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений',
          'Только регистрацию сведений о сварщиках, выполняющих сварные соединения',
          'Только регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений',
          'Только идентификацию применяемых сварочных материалов и сварочного оборудования',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63703114',
        text:
          'Как необходимо ограждать зону площадки для выполнения работ по монтажу ПС?',
        answers: [
          'Специальными ограждениями, исключающими свободный проход, без предупреждающих табличек',
          'Перед входом в зону вывесить табличку с надписью "Проход запрещен!"',
          'По периметру, а на ограждениях вывешивать знаки безопасности и таблички',
          'Зону площадки можно не ограждать, если исключена возможность присутствия третьих лиц на монтажной площадке',
        ],
        correctAnswers: [
          'По периметру, а на ограждениях вывешивать знаки безопасности и таблички',
        ],
      },
      {
        code: '63703115',
        text:
          'Какие из перечисленных ПС подлежат учету в органах Ростехнадзора?',
        answers: [
          'Краны стрелового типа грузоподъемностью до 1 т включительно',
          'Переставные краны для монтажа мачт, башен, труб, устанавливаемые на монтируемом сооружении',
          'Краны стрелового типа с постоянным вылетом или не снабженные механизмом поворота',
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
        correctAnswers: [
          'Подъемники и вышки, предназначенные для перемещения людей',
        ],
      },
      {
        code: '63703116',
        text:
          'В каком документе должно быть определено распределение ответственности работников организации, осуществляющей монтаж, наладку, ремонт, реконструкцию или модернизацию ПС в процессе эксплуатации ОПО?',
        answers: [
          'В положении об отделе технического контроля организации',
          'В положении о контроле соблюдения технологических процессов специализированной организации',
          'В проектной документации',
          'В требованиях руководства (инструкции) по эксплуатации ПС',
        ],
        correctAnswers: [
          'В положении о контроле соблюдения технологических процессов специализированной организации',
        ],
      },
      {
        code: '63703117',
        text:
          'В каких случаях для контроля качества сварных швов допустимо применение капиллярного неразрушающего контроля?',
        answers: [
          'Применение капиллярного контроля сварных швов предпочтительно, если неразрушающий контроль необходимо выполнить при отрицательных температурах окружающего воздуха',
          'Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в ТУ на ремонт, реконструкцию или модернизацию ПС',
          'Применение капиллярного контроля сварных швов (кроме стыковых) возможно, если другие методы неразрушающего контроля применить невозможно или нецелесообразно',
          'Применение капиллярного контроля при ремонте, реконструкции или модернизации ПС для контроля качества сварных швов запрещается',
        ],
        correctAnswers: [
          'Применение капиллярного контроля сварных швов (кроме стыковых) устанавливается специализированной организацией в ТУ на ремонт, реконструкцию или модернизацию ПС',
        ],
      },
      {
        code: '63703118',
        text:
          'В каких случаях проводятся испытания на грузовую устойчивость при первичном техническом освидетельствовании стрелового самоходного крана?',
        answers: [
          'Только если конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с применением сварки',
          'Только если в паспорте крана отсутствуют ссылки на протоколы ранее проведенных испытаний на грузовую устойчивость',
          'Только если конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута ремонту расчетных элементов металлоконструкций с заменой элементов',
          'Только если конструкция крана (стрела, гусек, аутригеры или опорно-поворотное устройство) была подвергнута изменению первоначальных геометрических размеров',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63703119',
        text:
          'Каким должно быть минимальное расстояние по горизонтали между ПС, их стрелами, стрелой одного ПС и перемещаемым грузом на стреле другого ПС, а также перемещаемыми грузами при совместной работе ПС на строительном объекте?',
        answers: ['2 м', '3 м', '10 м', '5 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63703120',
        text:
          'Какие требования к статическим испытаниям подъемников (вышек) (кроме строительных) указаны неверно?',
        answers: [
          'Статические испытания проводят при установке подъемника (вышки) на горизонтальной площадке в положении, отвечающем наименьшей расчетной его устойчивости',
          'На подъемниках (вышках), оборудованных люлькой, груз массой, равной 110% от номинальной грузоподъемности, располагается в люльке, а второй груз массой, равной 40% от номинальной грузоподъемности, подвешивается к люльке на гибкой подвеске',
          'После начала подъема и отрыва второго груза от земли на высоту 50 - 100 мм, подъем останавливается с последующей выдержкой суммарного груза в течение 10 минут',
          'На подъемниках (вышках), оборудованных люлькой, груз массой, равной 125% от номинальной грузоподъемности, располагают в люльке, а второй груз массой, равной 40% от номинальной грузоподъемности, подвешивают к люльке на гибкой подвеске',
        ],
        correctAnswers: [
          'На подъемниках (вышках), оборудованных люлькой, груз массой, равной 125% от номинальной грузоподъемности, располагают в люльке, а второй груз массой, равной 40% от номинальной грузоподъемности, подвешивают к люльке на гибкой подвеске',
        ],
      },
      {
        code: '63703121',
        text:
          'В какой документации устанавливаются нормы, согласно которым определяется качество ремонтных сварных соединений ПС?',
        answers: [
          'Только в проекте ремонта, реконструкции или модернизации ПС',
          'Только в эксплуатационной документации',
          'Во всей перечисленной документации',
        ],
        correctAnswers: ['Во всей перечисленной документации'],
      },
      {
        code: '63703122',
        text:
          'С чем в обязательном порядке должны быть ознакомлены работники, выполняющие работы по монтажу (демонтажу) и наладке ПС?',
        answers: [
          'Только с рабочими процедурами (характеристикой работ)',
          'Только с руководством (инструкцией) по монтажу, регламентирующим технологическую последовательность операций (технологическим регламентом)',
          'Только с проектом производства работ или технологической картой на монтаж (демонтаж) ПС',
          'Только с дополнительными требованиями промышленной безопасности всего комплекса работ, связанных с монтажом (демонтажем) либо наладкой конкретного ПС',
          'Со всем перечисленным',
        ],
        correctAnswers: ['Со всем перечисленным'],
      },
      {
        code: '63703123',
        text:
          'Каким требованиям промышленной безопасности должна отвечать люлька для подъема и транспортировки людей кранами в случаях, когда транспортируемый персонал занимает положение у одной из сторон люльки, создавая наибольший опрокидывающий момент?',
        answers: [
          'Исключать возможность крена',
          'Исключать возможность опрокидывания',
          'Исключать возможность раскачивания',
          'Ограничивать положение персонала центральной частью люльки после начала ее подъема краном',
        ],
        correctAnswers: ['Исключать возможность опрокидывания'],
      },
      {
        code: '63703124',
        text:
          'Что из перечисленного не включает итоговая документация по результатам выполненных ремонтных работ?',
        answers: [
          'Ремонтные рабочие чертежи',
          'Технологические карты на выполнение ответственных операций',
          'Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций',
        ],
        correctAnswers: [
          'Протокол испытания механических свойств контрольных образцов для каждого сварщика, выполняющего сварку несущих элементов металлоконструкций',
        ],
      },
      {
        code: '63703125',
        text:
          'В каких случаях к акту сдачи-приемки рельсового пути, определяющему его готовность к эксплуатации, должны прикладываться данные планово-высотной съемки?',
        answers: [
          'Только после ремонта',
          'Только после реконструкции',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63703126',
        text:
          'Что входит в обязанности лица, осуществляющего строительство здания или сооружения, в соответствии с законодательством о градостроительной деятельности?',
        answers: [
          'Все перечисленное',
          'Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства',
          'Оценка соответствия здания или сооружения в процессе строительства и при его окончании',
          'Оценка соответствия результатов инженерных изысканий',
        ],
        correctAnswers: [
          'Контроль за соответствием применяемых строительных материалов и изделий, в том числе строительных материалов, производимых на территории, на которой осуществляется строительство, требованиям проектной документации в течение всего процесса строительства',
        ],
      },
      {
        code: '63703127',
        text:
          'Каким документом оформляются результаты испытаний грузозахватных приспособлений от приложения статической нагрузкой?',
        answers: [
          'Заключением экспертизы промышленной безопасности',
          'Документом о проведении полного технического освидетельствования',
          'Актом или протоколом',
        ],
        correctAnswers: ['Актом или протоколом'],
      },
      {
        code: '63703128',
        text:
          'Какой объем ремонтных сварных соединений элементов металлоконструкций из высокопрочных сталей подвергается ультразвуковому и магнитопорошковому контролю?',
        answers: [
          '50% сварных соединений',
          '80% сварных соединений',
          '100% сварных соединений',
          '70% сварных соединений',
        ],
        correctAnswers: ['100% сварных соединений'],
      },
      {
        code: '63703129',
        text:
          'Какое из перечисленных требований безопасности для пользователей зданиями и сооружениями, согласно Техническому регламенту о безопасности зданий и сооружений, указано верно? Укажите все правильные ответы.',
        answers: [
          'Для обеспечения свободного перемещения людей должна быть предусмотрена достаточная ширина незаполняемых проемов в стенах',
          'В пешеходных зонах зданий и сооружений высотой более 20 м должны быть предусмотрены защитные приспособления для обеспечения безопасности пребывания людей в этих зонах при действии ветра',
          'В проектной документации зданий и сооружений должны быть предусмотрены устройства для предупреждения случайного движения подвижных элементов оборудования здания или сооружения при отказе устройств автоматического торможения',
          'Для обеспечения безопасности в аварийных ситуациях в проектной документации должно быть предусмотрено дежурное освещение',
        ],
        correctAnswers: [
          'Для обеспечения свободного перемещения людей должна быть предусмотрена достаточная ширина незаполняемых проемов в стенах',
          'В проектной документации зданий и сооружений должны быть предусмотрены устройства для предупреждения случайного движения подвижных элементов оборудования здания или сооружения при отказе устройств автоматического торможения',
        ],
      },
      {
        code: '63703130',
        text:
          'Какие здания и сооружения могут предусматривать необходимость научного сопровождения инженерных изысканий и (или) проектирования и строительства здания или сооружения?',
        answers: [
          'Нормального уровня ответственности',
          'Повышенного уровня ответственности',
          'Экстремально высокого уровня ответственности',
        ],
        correctAnswers: ['Повышенного уровня ответственности'],
      },
      {
        code: '63703131',
        text:
          'Кто может заниматься деятельностью по монтажу, наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО?',
        answers: [
          'Специализированные организации',
          'Физические лица и индивидуальные предприниматели',
          'Специализированные организации и физические лица',
          'Юридические и физические лица',
        ],
        correctAnswers: ['Специализированные организации'],
      },
      {
        code: '63703132',
        text:
          'Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки',
          'Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД',
          'Только организовать проведение операционного контроля',
          'Все перечисленные функции',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63703133',
        text:
          'Какое расстояние установлено от верхней точки крана, передвигающегося по надземному рельсовому пути, до потолка здания или предметов конструкции здания над краном?',
        answers: [
          'Не менее 80 мм',
          'Не менее 40 мм',
          'Не менее 60 мм',
          'Не менее 100 мм',
        ],
        correctAnswers: ['Не менее 100 мм'],
      },
      {
        code: '63703134',
        text:
          'В каких случаях при выполнении ремонта должна выполняться замена отдельных элементов ПС, если на них не обнаружено видимых повреждений?',
        answers: [
          'Если установлено, что сборочная единица эксплуатировалась без необходимой смазки',
          'Если в руководстве (инструкции) по эксплуатации ПС указано, что при достижении определенной наработки должна выполняться их замена',
          'Если сборочную единицу ПС разобрать невозможно',
          'Если сборочная единица подверглась термоциклическому нагружению от воздействия либо низких, либо высоких температур, превышающих указанные в паспорте ПС',
        ],
        correctAnswers: [
          'Если в руководстве (инструкции) по эксплуатации ПС указано, что при достижении определенной наработки должна выполняться их замена',
        ],
      },
      {
        code: '63703135',
        text:
          'Кем определяется набор инструментов и приборов, необходимых для ремонта, реконструкции или модернизации ограничителей, указателей, регистраторов параметров ПС?',
        answers: [
          'Руководителем подрядной организации, обслуживающей ПС',
          'Руководителем организации, эксплуатирующей ПС',
          'Работниками, выполняющими монтаж ПС',
          'Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов',
        ],
        correctAnswers: [
          'Работниками организаций, выполняющими указанные работы с учетом требований эксплуатационных документов',
        ],
      },
      {
        code: '63703136',
        text:
          'Куда вносятся отметки о монтаже и наладке ограничителя, указателя и регистратора подъемного сооружения (ПС)? Укажите все правильные ответы.',
        answers: [
          'В паспорт ПС',
          'В акт выполненных работ',
          'В паспорт ограничителя, указателя или регистратора',
          'В руководство по эксплуатации',
          'В паспорт безопасности ОПО, на котором эксплуатируется ПС',
        ],
        correctAnswers: [
          'В паспорт ПС',
          'В паспорт ограничителя, указателя или регистратора',
        ],
      },
      {
        code: '63703137',
        text:
          'Кто должен назначать сигнальщика в случаях, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между крановщиком и стропальщиком радио- или телефонной связи?',
        answers: [
          'Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Специалист, ответственный за безопасное выполнение работ с применением ПС',
          'Руководитель эксплуатирующей организации',
          'Специалист, ответственный за содержание ПС в работоспособном состоянии',
        ],
        correctAnswers: [
          'Специалист, ответственный за безопасное выполнение работ с применением ПС',
        ],
      },
      {
        code: '63703138',
        text:
          'В каких случаях стреловым краном не разрешается подъем груза непосредственно с места его установки (с земли, площадки, штабеля)?',
        answers: [
          'Если осуществляется подъем длинномерного груза с применением траверсы',
          'Если подъем груза осуществляется на номинальной скорости механизма',
          'Если угол между стропами при подъеме груза составляет 80°',
          'Если подъем груза осуществляется только механизмом телескопирования стрелы',
          'Если кран установлен не на бетонной или асфальтовой площадке',
        ],
        correctAnswers: [
          'Если подъем груза осуществляется только механизмом телескопирования стрелы',
        ],
      },
      {
        code: '63703139',
        text:
          'Кто в ФНП ПС определен термином "работники" специализированной организации?',
        answers: [
          'Все сотрудники организации, включая лиц рабочих профессий',
          'Аттестованные специалисты и лица рабочих профессий',
          'Руководители, специалисты и персонал - лица рабочих профессий основных служб организации',
          'Физические лица основных служб организации, непосредственно занятые на выполнении работ',
          'Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лица рабочих профессий, непосредственно занятые в производстве работ',
        ],
        correctAnswers: [
          'Инженерно-технические работники, имеющие высшее или среднее профессиональное образование, и персонал - лица рабочих профессий, непосредственно занятые в производстве работ',
        ],
      },
      {
        code: '63703140',
        text:
          'Что необходимо предпринять, если при ремонте регистратора параметров не представляется возможным восстановление информации долговременного хранения?',
        answers: [
          'Эксплуатирующей организацией должна быть проведена корректировка программного обеспечения',
          'Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС',
          'Специализированной и эксплуатирующей организациями должен быть составлен соответствующий протокол',
          'Не допускать дальнейшего применения в работе регистратора параметров',
        ],
        correctAnswers: [
          'Специализированной организацией должна быть сделана соответствующая запись в паспорте ПС',
        ],
      },
      {
        code: '63703141',
        text:
          'В каком случае допускается эксплуатация текстильных стропов на полимерной основе?',
        answers: [
          'Только если имеются прожженные сквозные отверстия диаметром более 10% ширины ленты от воздействия брызг расплавленного металла или наличие трех и более отверстий при расстоянии между ними менее 10% ширины ленты независимо от диаметра отверстий',
          'Только если имеется загрязнение лент (нефтепродуктами, смолами, красками, цементом, грунтом) более 50% длины стропа',
          'Только если имеются продольные порезы или разрывы ленты, суммарная длина которых превышает 10% длины ленты ветви стропа, а также единичные порезы или разрывы длиной более 50 мм',
          'Эксплуатация запрещается во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Эксплуатация запрещается во всех перечисленных случаях',
        ],
      },
      {
        code: '63703142',
        text:
          'Как должна распределяться нагрузка на каждое из ПС, если подъем и перемещение груза осуществляют двумя ПС?',
        answers: [
          'Нагрузка, приходящаяся на каждое ПС, не должна превышать 0,75% грузоподъемности ПС, имеющего меньшую грузоподъемность',
          'Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС',
          'Нагрузка, приходящаяся на каждое ПС, должна быть выровнена несимметричной строповкой груза и быть по возможности одинаковой',
          'Нагрузка, приходящаяся на каждое из ПС, должна контролироваться взвешивающими устройствами',
        ],
        correctAnswers: [
          'Нагрузка, приходящаяся на каждое ПС, не должна превышать грузоподъемность ПС',
        ],
      },
      {
        code: '63703143',
        text:
          'С учетом требований какого документа должна выполняться утилизация (ликвидация) ПС? Укажите все правильные ответы.',
        answers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Распоряжения Ростехнадзора',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
          'Проектной документации',
        ],
        correctAnswers: [
          'Руководства (инструкции) по эксплуатации ПС',
          'Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
      },
      {
        code: '63703144',
        text:
          'Какие требования, установленные для специализированной организации, осуществляющей деятельность по монтажу (демонтажу), наладке, ремонту, реконструкции или модернизации ПС в процессе эксплуатации ОПО, указаны верно?',
        answers: [
          'Располагать необходимым персоналом, а также инженерно-техническими работниками, уполномоченными на выполнение своих обязанностей, в том числе выявления случаев отступлений от требований к качеству работ, от процедур проведения работ и для принятия мер по предупреждению или сокращению таких отступлений',
          'Определять процедуры контроля соблюдения технологических процессов',
          'Устанавливать ответственность, полномочия и взаимоотношения работников, занятых в управлении, выполнении или проверке выполнения работ',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63703145',
        text:
          'Что из перечисленного в составе подъемного сооружения не требуется опломбировать после монтажа, наладки, реконструкции или модернизации?',
        answers: [
          'Ограничитель',
          'Указатель',
          'Регистратор',
          'Концевые выключатели электромеханического типа, применяемые в ограничителях рабочих движений',
        ],
        correctAnswers: [
          'Концевые выключатели электромеханического типа, применяемые в ограничителях рабочих движений',
        ],
      },
      {
        code: '63703146',
        text:
          'В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?',
        answers: [
          'В плане производства работ',
          'В паспорте ПС',
          'В акте пуска ПС в работу',
          'В руководстве (инструкции) по эксплуатации ПС',
        ],
        correctAnswers: ['В акте пуска ПС в работу'],
      },
      {
        code: '63703147',
        text:
          'Кого в обязательном порядке должны информировать работники ОПО, непосредственно занимающиеся эксплуатацией ПС, об угрозе возникновения аварийной ситуации?',
        answers: [
          'Специалиста по охране труда эксплуатирующей организации',
          'Своего непосредственного руководителя',
          'Специалиста, ответственного за безопасную эксплуатацию ПС',
          'Специалиста, ответственного за содержание ПС в работоспособном состоянии',
        ],
        correctAnswers: ['Своего непосредственного руководителя'],
      },
      {
        code: '63703148',
        text: 'Какие требования к статическим испытаниям кранов указаны верно?',
        answers: [
          'Испытания кранов стрелового типа, не имеющих механизма изменения вылета, проводятся при установленных для испытаний вылетах',
          'Статические испытания крана стрелового типа, имеющего одну или несколько грузовых характеристик, при периодическом или внеочередном техническом освидетельствовании проводятся в положении, соответствующем наибольшей грузоподъемности крана и (или) наибольшему грузовому моменту, если это не противоречит требованиям, изложенным в руководстве (инструкции) по эксплуатации ПС',
          'Испытания кранов, имеющих сменное стреловое оборудование, проводятся с установленным стреловым оборудованием, а также после замены стрелового оборудования',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63703149',
        text:
          'Каким проверкам должны подвергаться ПС при полном техническом освидетельствовании?',
        answers: [
          'Только осмотру',
          'Только статическим испытаниям',
          'Только динамическим испытаниям',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63703150',
        text: 'Какое требование по безопасной эксплуатации ПС указано неверно?',
        answers: [
          'При эксплуатации ПС, управляемых с пола, вдоль всего пути следования ПС должен быть обеспечен свободный проход для работника, управляющего ПС',
          'Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте',
          'Выходы на галереи мостовых кранов, находящихся в работе, должны быть закрыты на замок',
          'Эксплуатирующая организация должна разработать для каждого цеха (пролета), не оборудованного проходными галереями вдоль рельсового пути, мероприятия по безопасному спуску крановщиков из кабины при вынужденной остановке крана не у посадочной площадки',
        ],
        correctAnswers: [
          'Мероприятия по безопасному спуску крановщиков должны быть указаны в технологической карте',
        ],
      },
      {
        code: '63703151',
        text:
          'Насколько выше встречающихся на пути предметов и оборудования должны находиться стрелы кранов при их повороте или перемещении?',
        answers: [
          'Не менее чем на 400 мм',
          'Не менее чем на 1000 мм',
          'Не менее чем на 500 мм',
          'Не менее чем на 300 мм',
        ],
        correctAnswers: ['Не менее чем на 500 мм'],
      },
      {
        code: '63703152',
        text:
          'На какие из перечисленных ОПО распространяются требования Правил безопасности опасных производственных объектов, на которых используются подъемные сооружения?',
        answers: [
          'На ОПО, где эксплуатируются ПС, установленные на суднах и других плавучих средствах',
          'На ОПО, где эксплуатируются краны для подъема и опускания затворов гидротехнических сооружений без осуществления зацепления их крюками, оборудованные единственным механизмом подъема и не имеющие механизма передвижения крана',
          'На ОПО, где эксплуатируются подъемные сооружения, установленные в шахтах',
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
          'На ОПО, где используются эскалаторы',
        ],
        correctAnswers: [
          'На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления',
        ],
      },
      {
        code: '63703153',
        text:
          'Что из перечисленного должно быть предусмотрено в проектной документации лицом, осуществляющим подготовку проектной документации? Укажите все правильные ответы.',
        answers: [
          'Оценка риска возникновения опасных природных процессов и явлений и (или) техногенных воздействий',
          'Минимальная периодичность осуществления проверок, осмотров и освидетельствований состояния строительных конструкций, основания, сетей инженерно-технического обеспечения и систем инженерно-технического обеспечения здания или сооружения',
          'Расчеты и (или) испытания, выполненные по сертифицированным или апробированным иным способом методикам',
          'Необходимость проведения мониторинга компонентов окружающей среды, состояния основания, строительных конструкций и систем инженерно-технического обеспечения в процессе эксплуатации здания или сооружения',
        ],
        correctAnswers: [
          'Минимальная периодичность осуществления проверок, осмотров и освидетельствований состояния строительных конструкций, основания, сетей инженерно-технического обеспечения и систем инженерно-технического обеспечения здания или сооружения',
          'Необходимость проведения мониторинга компонентов окружающей среды, состояния основания, строительных конструкций и систем инженерно-технического обеспечения в процессе эксплуатации здания или сооружения',
        ],
      },
      {
        code: '63703154',
        text: 'Кто может проводить монтаж временных электрических сетей?',
        answers: [
          'Аттестованный электромонтер',
          'Технический специалист организации, эксплуатирующей ПС',
          'Любой электрик монтажной организации',
          'Аттестованный специалист по монтажу и наладке электрооборудования ПС',
        ],
        correctAnswers: ['Аттестованный электромонтер'],
      },
      {
        code: '63703155',
        text:
          'В каких местах должны быть установлены стационарные эстакады или навесные площадки для стропальщиков?',
        answers: [
          'В любых местах, кроме мест скопления напольного технологического оборудования',
          'В местах, которые отвечают требованиям промышленной безопасности во время возникновения аварийных ситуаций',
          'В любых местах, кроме проходов для персонала',
          'В местах постоянной погрузки и разгрузки автомашин и полувагонов',
        ],
        correctAnswers: [
          'В местах постоянной погрузки и разгрузки автомашин и полувагонов',
        ],
      },
      {
        code: '63703156',
        text:
          'Допускается ли при выполнении строительно-монтажных или погрузочно-разгрузочных работ перемещение грузов с применением ПС над перекрытиями, под которыми размещены производственные, жилые или служебные помещения, где могут находиться люди?',
        answers: [
          'Допускается в исключительных случаях в присутствии и под руководством специалиста, ответственного за безопасное производство работ с применением ПС',
          'Допускается при условии, что место производства работ будут ограждены и обозначены предупредительными знаками',
          'Допускается',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63703157',
        text: 'Кем осуществляется ежесменный осмотр рельсового пути ПС?',
        answers: [
          'Начальником цеха (участка)',
          'Службой эксплуатации, отвечающей за состояние рельсовых путей',
          'Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией',
          'Специалистом, ответственным за безопасное производство работ с применением ПС',
        ],
        correctAnswers: [
          'Крановщиком (оператором) в объеме, предусмотренном производственной инструкцией',
        ],
      },
      {
        code: '63703158',
        text:
          'Какие мероприятия должна выполнять эксплуатирующая организация для содержания ПС в работоспособном состоянии и обеспечения безопасных условий их работы?',
        answers: [
          'Только устанавливать порядок периодических осмотров, технических обслуживаний и ремонтов, обеспечивающих содержание ПС, рельсовых путей, грузозахватных органов, приспособлений и тары в работоспособном состоянии',
          'Только обеспечивать наличие у инженерно-технических работников должностных инструкций и руководящих указаний по безопасной эксплуатации ПС, а у персонала - производственных инструкций',
          'Только разрабатывать и утверждать журналы, программы, графики выполнения планово-предупредительных ремонтов, ППР, ТК, схемы строповки и складирования, должностные инструкции для инженерно-технических работников, а также производственные инструкции для персонала, с учетом особенностей технологических процессов, установленных проектной и технологической документацией',
          'Только устанавливать порядок проверки знаний и допуска к самостоятельной работе персонала с выдачей удостоверений, в которых указывается тип ПС, а также виды работ и оборудования, к работам на которых они допущены',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63703159',
        text:
          'Когда результат статических испытаний крана стрелового типа считается положительным?',
        answers: [
          'Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов',
          'Если кран не потеряет устойчивость за весь период испытаний',
          'Если не обнаружено трещин, остаточных деформаций и других повреждений стрелы',
          'Если в течение 5 минут поднятый груз не опустится на землю',
        ],
        correctAnswers: [
          'Если в течение 10 минут поднятый груз не опустится на землю, а также не обнаружено трещин, остаточных деформаций и других повреждений металлоконструкций и механизмов',
        ],
      },
      {
        code: '63703160',
        text:
          'При каком положении крана на надземном рельсовом пути следует проверять соответствие расстояния от выступающих частей торцов крана до колонн, стен здания и перил проходных галерей?',
        answers: [
          'При положении крана, когда колеса одной из концевых балок максимально смещены в поперечном направлении относительно рельса',
          'В положении крана, которое соответствует наибольшему уширению колеи рельсового пути в зоне, обслуживаемой краном',
          'При фактическом расположении колес крана относительно рельса во время проведения измерений',
          'При симметричном расположении колес крана относительно рельса',
        ],
        correctAnswers: [
          'При симметричном расположении колес крана относительно рельса',
        ],
      },
      {
        code: '63703161',
        text:
          'В какой документ заносятся результаты грузовых испытаний люльки (кабины)?',
        answers: [
          'В вахтенный журнал',
          'В журнал осмотра люльки (кабины)',
          'В журнал учета и осмотра грузозахватных приспособлений',
          'В паспорт люльки (кабины)',
        ],
        correctAnswers: ['В паспорт люльки (кабины)'],
      },
      {
        code: '63703162',
        text:
          'Каким грузом следует проводить динамические испытания стреловых самоходных кранов?',
        answers: [
          'Масса которого на 10% превышает его паспортную грузоподъемность',
          'Масса которого на 15% превышает его паспортную грузоподъемность',
          'Масса которого на 7% превышает его паспортную грузоподъемность',
          'Масса которого на 20% превышает его паспортную грузоподъемность',
        ],
        correctAnswers: [
          'Масса которого на 10% превышает его паспортную грузоподъемность',
        ],
      },
      {
        code: '63703163',
        text:
          'Каким образом необходимо исключать в процессе работы специализированной организации использование материалов и изделий, на которые отсутствуют сертификаты, паспорта и другие документы, подтверждающие их качество?',
        answers: [
          'Наличием квалификации специалистов и персонала',
          'Принятой системой обучения и аттестации работников',
          'Технологической подготовкой производства и установленным производственным процессом',
          'Ответственностью специалистов и персонала',
        ],
        correctAnswers: [
          'Технологической подготовкой производства и установленным производственным процессом',
        ],
      },
      {
        code: '63703164',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Должны быть поставлены клейма всех сварщиков, участвовавших в сварке',
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
          'Допускается применение клейма сварщика, выполнившего наибольший объем работ',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63703165',
        text:
          'Куда следует заносить результаты плановых осмотров люльки, предназначенной для транспортировки людей кранами?',
        answers: [
          'В вахтенный журнал',
          'В паспорт крана',
          'В журнал осмотра люльки',
          'В паспорт люльки',
        ],
        correctAnswers: ['В журнал осмотра люльки'],
      },
      {
        code: '63703166',
        text:
          'Что должно быть указано в технологических картах (далее – ТК) сварки?',
        answers: [
          'Требования к применяемым сварочным технологиям, последовательность операций, технические приемы, особенности процесса сварки, обеспечивающие качество сварных соединений',
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
          'Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, технические приемы контроля качества сварных соединений',
        ],
        correctAnswers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
        ],
      },
      {
        code: '63703167',
        text:
          'На какую высоту следует предварительно поднять груз перед началом перемещения (с последующей остановкой) для проверки правильности строповки и надежности действия тормоза ПС?',
        answers: [
          'Не более 200-300 мм',
          'Не более 600 мм',
          'Не более 800 мм',
          'Не более 400-500 мм',
        ],
        correctAnswers: ['Не более 200-300 мм'],
      },
      {
        code: '63703168',
        text:
          'С какой периодичностью результаты осмотров рельсовых путей заносятся в вахтенные журналы крановщика (оператора) всех ПС, установленных на одном рельсовом пути?',
        answers: [
          'После каждых 10 смен работы',
          'После каждых 15 смен работы',
          'После каждых 12 смен работы',
          'После каждых 24 смен работы',
        ],
        correctAnswers: ['После каждых 24 смен работы'],
      },
      {
        code: '63703169',
        text:
          'Каким документом определено распределение ответственности работников специализированной организации?',
        answers: [
          'Положением о требованиях к работникам организации',
          'Положением о промышленной безопасности организации',
          'Положением о контроле соблюдения технологических процессов',
          'Положением об отделе технического контроля организации',
          'Положением о порядке действий в случаях аварийных ситуаций',
        ],
        correctAnswers: [
          'Положением о контроле соблюдения технологических процессов',
        ],
      },
      {
        code: '63703170',
        text:
          'В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?',
        answers: [
          'Представителя Ростехнадзора',
          'Руководителя независимого аттестационного центра',
          'Производителя сварочного оборудования',
          'Руководителя организации',
        ],
        correctAnswers: ['Производителя сварочного оборудования'],
      },
      {
        code: '63703171',
        text:
          'Кем указываются такие идентификационные признаки зданий и сооружений, как назначение, принадлежность к опасным производственным объектам, пожарная и взрывопожарная опасность, уровень ответственности? Укажите все правильные ответы.',
        answers: [
          'Лицом, ответственным за безопасную эксплуатацию здания (сооружения)',
          'Застройщиком (заказчиком)',
          'Лицом, осуществляющим подготовку проектной документации',
          'Представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Застройщиком (заказчиком)',
          'Лицом, осуществляющим подготовку проектной документации',
        ],
      },
      {
        code: '63703172',
        text:
          'Какие действия не включает в себя проверка состояния люльки (кабины)?',
        answers: [
          'Ежесменный осмотр',
          'Грузовые испытания',
          'Плановую проверку состояния',
          'Динамические испытания',
        ],
        correctAnswers: ['Динамические испытания'],
      },
      {
        code: '63703173',
        text:
          'Каким требованиям должна отвечать площадка для выполнения монтажа ПС?',
        answers: [
          'Соответствовать геометрическим размерам монтируемого ПС, с учетом дополнительных проходов вокруг него для персонала, занятого на монтаже',
          'Соответствовать требованиям площадки установки вспомогательных ПС, используемых на монтаже',
          'Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж',
          'Не иметь в непосредственной близости складируемых горючих материалов и материалов, поддерживающих горение, если монтаж выполняется с применением сварки',
        ],
        correctAnswers: [
          'Соответствовать руководству (инструкции) по монтажу ПС, а также ППР или ТК на монтаж',
        ],
      },
      {
        code: '63703174',
        text:
          'Каким образом должны быть расположены ветви многоветвевых стропов при испытаниях?',
        answers: [
          'Под углом 90° по вертикали друг к другу',
          'Под углом 45° по вертикали друг к другу',
          'Под углом 120° по горизонтали друг к другу',
          'Под углом 45° по горизонтали друг к другу',
        ],
        correctAnswers: ['Под углом 90° по вертикали друг к другу'],
      },
      {
        code: '63703175',
        text:
          'Если в руководстве (инструкции) по эксплуатации ПС отсутствуют требования к его установке на выносные опоры, то в каком случае разрешается установка подъемников (вышек) только на две или три выносные опоры?',
        answers: [
          'Только при наличии допустимого уклона одной из частей площадки установки',
          'Только если подъем и перемещение груза будет выполняться только в одном положении стрелы',
          'Только если отсутствует одна из инвентарных подкладок, устанавливаемых под опору',
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
        correctAnswers: [
          'Не разрешается, ПС устанавливается на все выносные опоры',
        ],
      },
      {
        code: '63703176',
        text:
          'На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?',
        answers: [
          'Не более 50 мм',
          'Не более 250 мм',
          'Не более 200 мм',
          'Не более 150 мм',
        ],
        correctAnswers: ['Не более 150 мм'],
      },
      {
        code: '63703177',
        text:
          'Что должно быть предпринято в случае, когда зона, обслуживаемая ПС, полностью не просматривается из кабины управления (с места управления), и при отсутствии между оператором (крановщиком) и стропальщиком радио- или телефонной связи?',
        answers: [
          'Должен быть оформлен дополнительный наряд-допуск на производство работ повышенной опасности',
          'Должны быть разработаны условные обозначения для передачи сигнала',
          'Должен быть назначен сигнальщик из числа стропальщиков',
          'Должен быть разработан план действий для крановщика и стропальщика',
        ],
        correctAnswers: [
          'Должен быть назначен сигнальщик из числа стропальщиков',
        ],
      },
      {
        code: '63703178',
        text:
          'Что указывается на ремонтных чертежах элементов металлоконструкции ПС?',
        answers: [
          'Только поврежденные участки, подлежащие ремонту или замене и материалы, применяемые при замене',
          'Только типы сварных соединений, способы их выполнения, виды обработки швов после сварки',
          'Только способы и нормы контроля сварных соединений (места, подлежащие контролю или проверке), допускаемые отклонения от номинальных размеров',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63703179',
        text:
          'Какая организация обеспечивает наличие комплекта испытательных (контрольных) грузов с указанием их фактической массы для проведения статических и динамических испытаний ПС на территории специализированной организации, осуществляющей ремонт или реконструкцию?',
        answers: [
          'Данная специализированная организация',
          'Ростехнадзор',
          'Эксплуатирующая организация',
          'Любая сторонняя организация',
        ],
        correctAnswers: ['Данная специализированная организация'],
      },
      {
        code: '63703180',
        text:
          'Что необходимо предпринять в случае, когда зона, обслуживаемая подъемником (вышкой), не просматривается с места управления оператора (машиниста подъемника)?',
        answers: [
          'Для передачи сигналов оператору (машинисту подъемника или персоналу, находящемуся в люльке подъемника) должны быть назначены сигнальщики',
          'Осуществлять работу подъемника (вышки) под непосредственным руководством специалиста, ответственного за безопасное производство работ с применением ПС',
          'Для передачи сигналов оператору (машинисту подъемника или персоналу, находящемуся в люльке подъемника) должна использоваться радио- или телефонная связь',
          'Осуществлять работу подъемника (вышки) с использованием координатной защиты',
        ],
        correctAnswers: [
          'Для передачи сигналов оператору (машинисту подъемника или персоналу, находящемуся в люльке подъемника) должна использоваться радио- или телефонная связь',
        ],
      },
      {
        code: '63703181',
        text:
          'Каким нормативным правовым актом регламентируются обязательные для применения требования для ПС, введенных в обращение до вступления в силу Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"?',
        answers: [
          'Ранее действующими правилами устройства и безопасной эксплуатации ПС Ростехнадзора для всех стадий жизненного цикла этих ПС',
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
          'Ранее действующими правилами устройства и безопасной эксплуатации ПС Ростехнадзора для проектирования и изготовления этих ПС, а для остальных стадий жизненного цикла ПС - ФНП ПС',
          'Такие ПС должны быть приведены в соответствие только с требованиями Технического регламента ТР ТС 010/2011 "О безопасности машин и оборудования"',
        ],
        correctAnswers: [
          'Правилами безопасности опасных производственных объектов, на которых используются подъемные сооружения, для всех стадий жизненного цикла этих ПС',
        ],
      },
      {
        code: '63703182',
        text:
          'Куда записывается решение о вводе в эксплуатацию грузозахватных приспособлений, тары?',
        answers: [
          'В специальный журнал учета и осмотра',
          'На бирку грузозахватного приспособления, тары',
          'В паспорт грузозахватного приспособления, тары',
        ],
        correctAnswers: ['В специальный журнал учета и осмотра'],
      },
      {
        code: '63703183',
        text:
          'Что включает в себя проверка состояния рельсового пути, находящегося в эксплуатации? Укажите все правильные ответы.',
        answers: [
          'Ежесменный осмотр',
          'Техническое обслуживание',
          'Плановую или внеочередную проверку состояния',
          'Периодическое комплексное обследование',
        ],
        correctAnswers: [
          'Ежесменный осмотр',
          'Плановую или внеочередную проверку состояния',
        ],
      },
      {
        code: '63703184',
        text:
          'Какое количество ветвей для стропов с числом ветвей более трех, учитывают в расчете их грузоподъемности?',
        answers: [
          'Не более двух ветвей',
          'Не более четырех ветвей',
          'Не более трех ветвей',
          'Все ветви, если груз несимметричен',
        ],
        correctAnswers: ['Не более трех ветвей'],
      },
      {
        code: '63703185',
        text:
          'Какие требования к ограничителям, указателям и регистраторам указаны неверно?',
        answers: [
          'После монтажа или реконструкции ограничителя, указателя или регистратора должна проводиться наладка и проверка его работоспособности с подтверждением соответствия его характеристик паспортным данным',
          'Информационные табло (элементы визуального контроля) указателей, ограничителей и регистраторов должно быть установлено в поле зрения крановщика (оператора), при этом оно не должно затруднять управление ПС и наблюдение за грузозахватным органом и грузом',
          'Ограничители, указатели и регистраторы, а также их составные части должны устанавливаться в доступных для осмотра и обслуживания местах, защищенных от внешних воздействий',
          'При перестановке ограничителя или указателя со встроенным регистратором на другое ПС должна быть осуществлена замена микропроцессорного блока устройства',
        ],
        correctAnswers: [
          'При перестановке ограничителя или указателя со встроенным регистратором на другое ПС должна быть осуществлена замена микропроцессорного блока устройства',
        ],
      },
      {
        code: '63703186',
        text:
          'В каких случаях необходимо прекращать работу ПС, установленных на открытом воздухе?',
        answers: [
          'Только в случае показателя температуры окружающей среды ниже предельно допустимой температуры, указанной в паспорте ПС',
          'Только в случаях снегопада, дождя, тумана, когда крановщик (машинист, оператор) плохо различает сигналы стропальщика или перемещаемый груз',
          'Только в случае если скорость ветра, превышает предельно допустимую скорость, указанную в паспорте ПС',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63703187',
        text:
          'Требованиям какого документа должны соответствовать общие требования к транспортировке и хранению ПС, их отдельных сборочных единиц, материалов и комплектующих для их ремонта, реконструкции и (или) модернизации?',
        answers: [
          'Паспорта ПС',
          'Руководства (инструкции) по эксплуатации ПС',
          'Технических условий ПС',
          'Формуляра ПС',
        ],
        correctAnswers: ['Руководства (инструкции) по эксплуатации ПС'],
      },
      {
        code: '63703188',
        text:
          'Куда записывается решение о вводе в эксплуатацию специальных съемных кабин и люлек (для подъема и перемещения людей кранами)?',
        answers: [
          'В паспорт кабины или люльки',
          'В специальный журнал учета и осмотра',
          'В положение о контроле качества технологических процессов, принимаемом эксплуатирующей организацией',
          'На бирку кабины или люльки',
        ],
        correctAnswers: ['В паспорт кабины или люльки'],
      },
      {
        code: '63703189',
        text:
          'Какие обязанности эксплуатирующей ПС организации указаны неверно?',
        answers: [
          'При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год',
          'Обеспечивать соблюдение технологического процесса транспортировки грузов и приостановку работы ПС в случае возникновения угрозы аварийной ситуации',
          'Устанавливать порядок контроля обучения и периодической проверки знаний специалистов и персонала, работающих с ограничителями, указателями и регистраторами',
          'При выявлении нарушений требований к эксплуатации ПС, изложенных в ФНП, принимать меры по их устранению и предупреждению, в том числе проводить внеочередную проверку знаний работников, допустивших такие нарушения.',
        ],
        correctAnswers: [
          'При отсутствии в эксплуатационных документах регистраторов указаний о сроках считывания данных выполнять такие операции не реже одного раза в год',
        ],
      },
      {
        code: '63703190',
        text:
          'Какие из перечисленных видов контроля не осуществляются при подготовке и выполнении сварочных работ?',
        answers: [
          'Входной контроль',
          'Операционный контроль',
          'Приемочный контроль',
          'Эксплуатационный контроль',
        ],
        correctAnswers: ['Эксплуатационный контроль'],
      },
      {
        code: '63703191',
        text:
          'В соответствии с какими документами должен проводиться контроль стыковых сварных соединений радиографическим или ультразвуковым методом?',
        answers: [
          'В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией',
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Методы ультразвуковые"',
          'В соответствии с ГОСТ "Контроль неразрушающий. Соединения сварные. Радиографический метод"',
          'В соответствии с указаниями проектно-технологической документации эксплуатирующей организации',
        ],
        correctAnswers: [
          'В соответствии с проектом ремонта, реконструкции или модернизации ПС, разработанным специализированной организацией',
        ],
      },
      {
        code: '63703192',
        text:
          'Как следует поступить, если во время грузовых испытаний люльки для транспортировки людей кранами выявлены дефекты и повреждения?',
        answers: [
          'Поставить в известность ремонтную службу эксплуатирующей организации о выявленных дефектах и повреждениях люльки',
          'Эксплуатировать люльку до выполнения ремонта с ограничением по грузоподъемности',
          'Поставить в известность руководителя эксплуатирующей организации о выявленных дефектах и повреждениях люльки',
          'Запретить дальнейшую эксплуатацию люльки',
        ],
        correctAnswers: ['Запретить дальнейшую эксплуатацию люльки'],
      },
      {
        code: '63703193',
        text:
          'Какие требования предъявляются федеральными нормами и правилами "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
          'Оборудование и материалы должны быть экономичными в использовании, простыми в ремонте',
          'Оборудование и материалы должны обеспечивать максимальную производительность работ',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63703194',
        text:
          'Кто должен принять меры, предупреждающие причинение вреда населению и окружающей среде, при прекращении эксплуатации здания или сооружения согласно Техническому регламенту о безопасности зданий и сооружений?',
        answers: [
          'Лицо, осуществившее подготовку проектной документации',
          'Руководитель подрядной организации, осуществляющей оценку соответствия зданий и сооружений',
          'Собственник здания или сооружения',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Собственник здания или сооружения'],
      },
      {
        code: '63703195',
        text:
          'Кто дает разрешение на пуск в работу ПС по окончании ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?',
        answers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
          'Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Инженерно-технический работник, ответственный за безопасное производство работ с применением ПС',
          'Специалист, выполнивший указанные работы',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
        ],
      },
      {
        code: '63703196',
        text:
          'Какие требования предъявляются к стальным канатам, устанавливаемым на ПС при замене ранее установленных?',
        answers: [
          'Стальные канаты должны иметь сертификат предприятия - изготовителя каната и пройти испытание в соответствии с требованиями Правил устройства и безопасной эксплуатации грузоподъемных кранов',
          'Стальные канаты должны соответствовать по длине, марке, диаметру и разрывному усилию, указанным в паспорте ПС, и иметь сертификат предприятия-изготовителя',
          'Стальные канаты должны иметь разрывное усилие на 10% больше указанного в паспорте ПС для заменяемого каната',
          'Стальные канаты должны соответствовать только технологии использования ПС',
        ],
        correctAnswers: [
          'Стальные канаты должны соответствовать по длине, марке, диаметру и разрывному усилию, указанным в паспорте ПС, и иметь сертификат предприятия-изготовителя',
        ],
      },
      {
        code: '63703197',
        text:
          'При каких величинах суммарной массы тары с перемещаемым грузом допускается применение башенных кранов с тарой, разгружаемой на весу, в пределах группы классификации (режима), указанного в паспорте крана, при числе циклов работы крана не более 8 в час?',
        answers: [
          'Только для тары с вибратором - не более 50% от максимальной грузоподъемности крана',
          'Только для одноканатных грейферов, не допускающих разгрузку на весу, - не более 50% грузоподъемности крана',
          'Только для тары без вибраторов (исключая грейферы) - в пределах грузоподъемности крана',
          'Применение допускается во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Применение допускается во всех перечисленных случаях',
        ],
      },
      {
        code: '63703198',
        text:
          'Каким должно быть безопасное расстояние от низа перемещаемого груза до наиболее выступающих по вертикали частей здания или сооружения?',
        answers: [
          'Не менее 2,5 м',
          'Не менее 1,0 м',
          'Не менее 0,5 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 0,5 м'],
      },
      {
        code: '63703199',
        text:
          'Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?',
        answers: [
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель независимого аттестационного центра',
          'Представитель Ростехнадзора',
        ],
        correctAnswers: ['Руководитель независимого аттестационного центра'],
      },
      {
        code: '63703200',
        text:
          'Каким грузом следует проверять действие ловителей на строительных подъемниках?',
        answers: [
          'Масса которого на 20% превышает паспортную грузоподъемность',
          'Масса которого на 5% превышает паспортную грузоподъемность',
          'Масса которого на 10% превышает паспортную грузоподъемность',
          'Масса которого на 25% превышает паспортную грузоподъемность',
        ],
        correctAnswers: [
          'Масса которого на 10% превышает паспортную грузоподъемность',
        ],
      },
      {
        code: '63703201',
        text:
          'Чем запрещается оснащать краны, в зоне работы которых находятся производственные или другие помещения?',
        answers: [
          'Грузовым электромагнитом',
          'Концевым выключателем электромеханического типа',
          'Ограничителем грузоподъемности',
          'Регистратором параметров',
        ],
        correctAnswers: ['Грузовым электромагнитом'],
      },
      {
        code: '63703202',
        text: 'Что из перечисленного обозначает личный шифр клейм сварщиков?',
        answers: [
          'Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и возрастающий при последующих аттестациях',
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий шестизначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
        correctAnswers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
      },
      {
        code: '63703203',
        text:
          'Какие требования к статическим испытаниям крана-трубоукладчика или крана-манипулятора указаны неверно?',
        answers: [
          'Статические испытания должны проводиться при установке крана-трубоукладчика (крана-манипулятора) на горизонтальной площадке',
          'Испытания должны проводиться в положении, соответствующем наибольшей грузоподъемности',
          'Крюком поднимают груз на высоту 100-150 мм от земли и выдерживают в течение не менее 5 мин',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Крюком поднимают груз на высоту 100-150 мм от земли и выдерживают в течение не менее 5 мин',
        ],
      },
      {
        code: '63703204',
        text:
          'Имеет ли право организация, эксплуатирующая ОПО с ПС, привлекать специалистов сторонних организаций в качестве: специалиста, ответственного за осуществление производственного контроля при эксплуатации ПС; специалиста, ответственного за содержание ПС в работоспособном состоянии; специалиста, ответственного за безопасное производство работ с применением ПС?',
        answers: [
          'Имеет право привлекать всех перечисленных специалистов',
          'Имеет право привлекать специалистов, ответственных за содержание ПС в работоспособном состоянии; специалистов, ответственных за безопасное производство работ с применением ПС',
          'Имеет право привлекать только специалистов, ответственных за безопасное производство работ с применением ПС',
          'Не имеет права',
        ],
        correctAnswers: ['Не имеет права'],
      },
      {
        code: '63703205',
        text:
          'Кто дает разрешение на пуск ПС в работу после окончания ремонта, реконструкции или модернизации ограничителя, указателя или регистратора?',
        answers: [
          'Специалист, ответственный за осуществление производственного контроля при эксплуатации ПС',
          'Руководитель организации, эксплуатирующей ПС',
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
          'Специалист организации, выполнившей работы по ремонту, реконструкции или модернизации ограничителя, указателя или регистратора',
        ],
        correctAnswers: [
          'Инженерно-технический работник, ответственный за содержание ПС в работоспособном состоянии',
        ],
      },
      {
        code: '63703206',
        text:
          'С кем следует согласовывать установку кранов, передвигающихся по рельсовому пути, в охранной зоне воздушных линий (далее - ВЛ) электропередачи?',
        answers: [
          'С органом муниципального управления, по территории которого проходит ВЛ',
          'С организацией, эксплуатирующей воздушные линии',
          'С владельцем линии',
          'С территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['С владельцем линии'],
      },
      {
        code: '63703207',
        text:
          'С каким грузозахватным органом проводят испытания при повторных периодических технических освидетельствованиях ПС, имеющих несколько грузозахватных органов?',
        answers: [
          'Поочередно, с каждым из них',
          'С тем, который установлен на момент проведения испытаний',
          'С тем, который наиболее часто применяется в технологии перегрузочного процесса эксплуатирующей организации',
          'Сменные грузозахватные органы испытываются одновременно',
        ],
        correctAnswers: [
          'С тем, который установлен на момент проведения испытаний',
        ],
      },
      {
        code: '63703208',
        text:
          'К каким возможным последствиям не должен приводить любой отказ (поломка) смонтированного ограничителя, указателя или регистратора в процессе эксплуатации?',
        answers: [
          'К аварии ПС, в том числе к падению ПС, его частей и/или груза',
          'К невозможности опускания поднятого груза, если его масса выше паспортной грузоподъемности ПС',
          'К случайным перегрузкам ПС',
          'К остановке работы ПС',
        ],
        correctAnswers: [
          'К аварии ПС, в том числе к падению ПС, его частей и/или груза',
        ],
      },
      {
        code: '63703209',
        text:
          'Кто обязан сделать запись в паспорте ПС по завершении его ремонта, реконструкции или модернизации о проведенной работе, и предоставить копии сертификатов о примененных материалах, замененных и отремонтированных запчастях, узлах и агрегатах?',
        answers: [
          'Специализированная организация, выполнившая указанные работы',
          'Работники эксплуатирующей организации',
          'Лицо, ответственное за содержание ПС в работоспособном состоянии',
          'Специалист, ответственный за выполнение полного технического освидетельствования',
          'Представитель надзорного органа, разрешающий пуск ПС в работу',
        ],
        correctAnswers: [
          'Специализированная организация, выполнившая указанные работы',
        ],
      },
      {
        code: '63703210',
        text:
          'Каким из нижеприведенных требований должны отвечать работники, непосредственно выполняющие работы по монтажу?',
        answers: [
          'Знать и уметь оценивать остаточный ресурс ПС',
          'Быть аттестованными по экспертизе промышленной безопасности',
          'Быть аттестованными на право управления монтируемого ПС',
          'Знать и уметь применять такелажные и монтажные приспособления',
          'Знать основные признаки отправки ПС на утилизацию (ликвидацию)',
        ],
        correctAnswers: [
          'Знать и уметь применять такелажные и монтажные приспособления',
        ],
      },
      {
        code: '63703211',
        text:
          'В каких случаях разрешается подтаскивание груза по земле, полу или рельсам крюками ПС?',
        answers: [
          'Только в случаях применения ПС, не склонных к опрокидыванию (потере общей устойчивости)',
          'Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов',
          'Только в случаях, если канатный барабан механизма подъема ПС снабжен канатоукладчиком',
          'Только в случаях перемещения груза (тележки) по рельсам с соблюдением перегрузки ПС',
          'Не разрешается ни в каком случае',
        ],
        correctAnswers: [
          'Только в случаях применения направляющих блоков, обеспечивающих вертикальное положение грузовых канатов',
        ],
      },
      {
        code: '63703212',
        text:
          'Кем осуществляется проверка готовности к применению аттестованных технологий сварки с целью определения наличия у организации или индивидуального предпринимателя технических, организационных и квалификационных возможностей для выполнения сварочных (наплавочных) работ по применяемым им аттестованным технологиям, а также соответствия качества выполненных при аттестации контрольных сварных соединений (наплавок) требованиям НД и (или) проектной (конструкторской) документации на сварные конструкции?',
        answers: [
          'Эксплуатирующей организацией',
          'Независимыми аттестационными центрами',
          'Ростехнадзором',
          'Специализированной организацией',
        ],
        correctAnswers: ['Независимыми аттестационными центрами'],
      },
      {
        code: '63703213',
        text:
          'С какой нагрузкой по отношению к номинальной паспортной грузоподъемности должна проводиться проверка качества выполненного ремонта грузозахватных приспособлений с проведением статических испытаний?',
        answers: ['1,5', '1,25', '1,1', '2'],
        correctAnswers: ['1,25'],
      },
      {
        code: '63703214',
        text:
          'Какой документ подтверждает готовность рельсового пути к эксплуатации, в том числе после ремонта (реконструкции)?',
        answers: [
          'Акт сдачи-приемки',
          'Заключение комиссии',
          'Протокол проверки готовности рельсового пути',
          'Руководство (инструкция) по эксплуатации ПС с соответствующей отметкой',
        ],
        correctAnswers: ['Акт сдачи-приемки'],
      },
    ],
    63707: [
      {
        code: '63707000',
        text:
          'С какой периодичностью необходимо контролировать и корректировать величину натяжения несущего каната при заякоривании обоих концов каната?',
        answers: [
          'Не менее одного раза в 12 месяцев',
          'Не менее одного раза в шесть месяцев',
          'Не менее одного раза в месяц',
          'Не менее одного раза в три месяца',
        ],
        correctAnswers: ['Не менее одного раза в шесть месяцев'],
      },
      {
        code: '63707001',
        text:
          'Какая может быть наибольшая величина ревизионной скорости тягового каната?',
        answers: ['1 м/с', '1,1 м/с', '2 м/с', '1,5 м/с'],
        correctAnswers: ['1 м/с'],
      },
      {
        code: '63707002',
        text:
          'При каком процентном увеличении скорости тягового каната при работе дороги в тормозном режиме должна обеспечиваться автоматическая остановка ГПКД?',
        answers: ['0,02', '0,1', '0,2', '0,15'],
        correctAnswers: ['0,2'],
      },
      {
        code: '63707003',
        text:
          'Какой наибольший размер транспортируемого груза должны удерживать предохранительные устройства при его просыпании из кузова вагонетки?',
        answers: ['3 мм', '10 мм', '20 мм', '15 мм'],
        correctAnswers: ['20 мм'],
      },
      {
        code: '63707004',
        text:
          'Какой должна быть минимальная ширина полосы, свободной от зданий, сооружений, растительности и других препятствий, в каждую сторону от оси канатной дороги при отсутствии предохранительных устройств и ширине колеи 4 м?',
        answers: ['4,0 м', '4,5 м', '5,0 м', '6,0 м'],
        correctAnswers: ['4,0 м'],
      },
      {
        code: '63707005',
        text:
          'Какой конструкции должны быть натяжные канаты несущих канатов ГПКД?',
        answers: [
          'Прядевыми двойной свивки с металлическим сердечником',
          'Прядевыми тройной свивки с органическим сердечником',
          'Закрытой конструкции',
          'Закрытой конструкции с двумя слоями клиновидной проволоки',
        ],
        correctAnswers: ['Прядевыми тройной свивки с органическим сердечником'],
      },
      {
        code: '63707006',
        text:
          'Какой запас прочности должны иметь зажимы на ГПКД с учетом возможного уменьшения диаметра каната?',
        answers: [
          'Не менее 1,5',
          'Не менее 1,1',
          'Не менее 2,0',
          'Не менее 1,7',
        ],
        correctAnswers: ['Не менее 1,5'],
      },
      {
        code: '63707007',
        text:
          'Каким образом должен производиться повторный запуск ГПКД после устранения причин аварийного отключения?',
        answers: [
          'Вручную, оператором грузовой подвесной канатной дороги',
          'Автоматически, после подачи определенных сигналов',
          'Пуск может быть произведен как автоматически, так и вручную',
          'Только вручную, любым работником ремонтных подразделений грузовой подвесной канатной дороги',
        ],
        correctAnswers: [
          'Вручную, оператором грузовой подвесной канатной дороги',
        ],
      },
      {
        code: '63707008',
        text:
          'Какое должно быть минимальное соотношение диаметра огибаемого шкива, ролика или барабана к диаметру несущего каната?',
        answers: ['10', '30', '40', '50'],
        correctAnswers: ['50'],
      },
      {
        code: '63707009',
        text:
          'Какой величины должен быть зазор между габаритом вагонетки (с учетом поперечного и продольного качания и полного круга вращения ее кузова) и полом станции?',
        answers: [
          'Не менее 0,1 м',
          'Не менее 0,15 м',
          'Не менее 0,2 м',
          'Не менее 0,25 м',
        ],
        correctAnswers: ['Не менее 0,1 м'],
      },
      {
        code: '63707010',
        text:
          'Каким способом не должны закрепляться концы натяжных, сетевых и расчалочных канатов?',
        answers: [
          'Муфтами',
          'Коушами с зажимами',
          'Многоболтовыми зажимами',
          'Якорными барабанами',
        ],
        correctAnswers: ['Якорными барабанами'],
      },
      {
        code: '63707011',
        text: 'Каким способом не должны закрепляться концы несущего каната?',
        answers: [
          'Муфтами',
          'Коушами с зажимами',
          'Якорными барабанами и зажимами',
          'Многоболтовыми зажимами',
        ],
        correctAnswers: ['Коушами с зажимами'],
      },
      {
        code: '63707012',
        text:
          'Для каких целей предназначена предупредительная сигнализация, устанавливаемая на ГПКД?',
        answers: [
          'Для оповещения персонала о возникновении короткого замыкания',
          'Для оповещения персонала о превышении установленной скорости движения',
          'Для оповещения персонала о предстоящем включении дороги в работу',
        ],
        correctAnswers: [
          'Для оповещения персонала о предстоящем включении дороги в работу',
        ],
      },
      {
        code: '63707013',
        text:
          'При каком минимальном зазоре между встречными вагонетками при поперечном раскачивании канатов с вагонетками внутрь колеи от воздействия ветра, допустимого для эксплуатации грузовых подвесных канатных дорог, расстояние между двумя ветвями дороги будет удовлетворять данному условию?',
        answers: ['0,5 м', '1 м', '1,5 м', '0,3 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63707014',
        text: 'Кем составляется руководство по эксплуатации ГПКД?',
        answers: [
          'Организацией, эксплуатирующей грузовые подвесные канатные дороги',
          'Только организацией, разработавшей проект грузовой подвесной канатной дороги',
          'Только специализированной организацией, аккредитованной Ростехнадзором',
          'Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии - специализированной организацией',
        ],
        correctAnswers: [
          'Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии - специализированной организацией',
        ],
      },
      {
        code: '63707015',
        text:
          'Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика?',
        answers: [
          'Поверхности шва и кромки разделки должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
          'Поверхности шва и кромки разделки должны быть подвержены вакуумному контролю',
          'Поверхности шва и кромки разделки должны быть смочены керосином, проверены на предмет пор и обезжирены',
        ],
        correctAnswers: [
          'Поверхности шва и кромки разделки должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
        ],
      },
      {
        code: '63707016',
        text:
          'Выберите обязательные конструктивные особенности полов на станциях в местах прохода людей, имеющих уклон свыше 10%. Укажите все правильные ответы.',
        answers: [
          'Ступенчатым',
          'Ребристым',
          'Металлическим',
          'Диэлектрическим',
        ],
        correctAnswers: ['Ступенчатым', 'Ребристым'],
      },
      {
        code: '63707017',
        text:
          'В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр несущего каната?',
        answers: [
          'Не превышающие 30 дней',
          'Не превышающие 40 дней',
          'Не превышающие 50 дней',
          'Не превышающие 60 дней',
        ],
        correctAnswers: ['Не превышающие 30 дней'],
      },
      {
        code: '63707018',
        text:
          'Какой документ регламентирует сроки проведения технического обслуживания?',
        answers: [
          'Заключение экспертизы промышленной безопасности',
          'Руководство по эксплуатации',
          'Технологическая карта',
          'Паспорт канатной дороги',
        ],
        correctAnswers: ['Руководство по эксплуатации'],
      },
      {
        code: '63707019',
        text:
          'Какие сетки должны быть установлены в местах входа и выхода вагонеток при превышении уровня пола станции над уровнем земли более чем 0,5 м?',
        answers: [
          'Шириной не менее 3 м',
          'Шириной не менее 2 м',
          'Не регламентируется',
          'Шириной не менее 1 м',
        ],
        correctAnswers: ['Шириной не менее 1 м'],
      },
      {
        code: '63707020',
        text:
          'Какая максимальная скорость движения вагонеток на линии установлена для двухканатной кольцевой ГПКД при наличии на линии линейных муфт?',
        answers: ['1,5 м/с', '2,0 м/с', '3,0 м/с', '3,5 м/с'],
        correctAnswers: ['3,5 м/с'],
      },
      {
        code: '63707021',
        text:
          'Какие требования предъявляются федеральными нормами и правилами в области промышленной безопасности "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
          'Оборудование и материалы должны быть задекларированы',
          'Оборудование и материалы должны иметь сертификаты безопасности',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63707022',
        text: 'Какие факторы не влияют на запрет эксплуатации ГПКД?',
        answers: [
          'Отсутствие на рабочем месте технического руководителя',
          'Наличие трещин в расчетных элементах металлоконструкций',
          'Отсутствие персонала, прошедшего проверку знаний в установленном порядке, для обслуживания ГПКД',
          'Неблагоприятные погодные условия, оговоренные в паспорте грузовой подвесной канатной дороги',
        ],
        correctAnswers: [
          'Отсутствие на рабочем месте технического руководителя',
        ],
      },
      {
        code: '63707023',
        text:
          'В ходе каких мероприятий устанавливаются сроки и объем текущего, среднего и капитального ремонта оборудования, металлоконструкций, сооружений? Выберите несколько вариантов ответа.',
        answers: [
          'Экспертиза промышленной безопасности',
          'Техническое освидетельствование',
          'Плановый технический осмотр',
          'Техническое обслуживание',
          'Проведение аварийно-спасательных работ',
        ],
        correctAnswers: [
          'Экспертиза промышленной безопасности',
          'Техническое освидетельствование',
          'Плановый технический осмотр',
        ],
      },
      {
        code: '63707024',
        text:
          'Кто должен по результатам экспертизы промышленной безопасности обеспечивать выполнение мероприятий по повышению уровня безопасности ГПКД в установленные сроки либо принимать решение о выводе ее из эксплуатации?',
        answers: [
          'Эксплуатирующая организация',
          'Организация, проводящая экспертизу промышленной безопасности',
          'Инспектор территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63707025',
        text:
          'На какие виды грузовых канатных дорог не распространяются требования Правил безопасности грузовых подвесных канатных дорог?',
        answers: [
          'Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом',
          'Одноканатные кольцевые грузовые подвесные канатные дороги с неотцепляемым на станциях подвижным составом',
          'Двухканатные подвесные канатные дороги с отцепляемым на станциях подвижным составом',
          'Двухканатные грузовые подвесные канатные дороги с реверсивным (маятниковым) движением подвижного состава',
        ],
        correctAnswers: [
          'Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом',
        ],
      },
      {
        code: '63707026',
        text:
          'Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?',
        answers: [
          'Руководитель независимого аттестационного центра',
          'Руководитель организации, владелец оборудования',
          'Продавец сварочного оборудования',
          'Производитель сварочного оборудования',
        ],
        correctAnswers: ['Руководитель независимого аттестационного центра'],
      },
      {
        code: '63707027',
        text:
          'Каким образом должна осуществляться возможность останова привода грузовых подвесных канатных дорог?',
        answers: [
          'Только с пульта управления',
          'Всеми перечисленными способами',
          'Только от кнопок "СТОП", установленных на каждой станции',
          'Только от аварийных выключателей "Аварийный стоп"',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63707028',
        text:
          'Кем проводится техническое освидетельствование грузовых подвесных канатных дорог? Укажите все правильные ответы.',
        answers: [
          'Эксплуатирующей организацией при наличии квалифицированного персонала',
          'Инспектором Ростехнадзора',
          'Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог',
          'Специализированной организацией совместно с инспектором Ростехнадзора',
        ],
        correctAnswers: [
          'Эксплуатирующей организацией при наличии квалифицированного персонала',
          'Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог',
        ],
      },
      {
        code: '63707029',
        text:
          'Какое утверждение соответствует требованиям к ширине предохранительных мостов?',
        answers: [
          'Должна обеспечивать перекрытие пространства на 2 м в каждую сторону от оси несущего каната',
          'Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната',
          'Должна обеспечивать перекрытие пространства на 2,25 м в каждую сторону от оси несущего каната',
          'Должна обеспечивать перекрытие пространства на 1,5 м в каждую сторону от оси несущего каната',
        ],
        correctAnswers: [
          'Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната',
        ],
      },
      {
        code: '63707030',
        text:
          'Какие требования установлены Правилами безопасности грузовых подвесных канатных дорог к площадкам для обслуживания оборудования ГПКД, расположенного на высоте более 2 м?',
        answers: [
          'Площадки должны иметь ограждение высотой не менее 0,5 м и сплошную зашивку по верху',
          'Площадки должны иметь ограждение высотой не менее 0,8 м',
          'Площадки должны иметь ограждение высотой не менее 0,8 м и сплошную зашивку',
          'Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м',
        ],
        correctAnswers: [
          'Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м',
        ],
      },
      {
        code: '63707031',
        text:
          'При обнаружении каких из перечисленных видов деформаций канаты могут быть допущены к работе?',
        answers: [
          'При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции',
          'При обнаружении местного уменьшения диаметра каната',
          'При обнаружении корзинообразной деформации',
          'При обнаружении выдавливания проволок или расслоения прядей',
        ],
        correctAnswers: [
          'При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции',
        ],
      },
      {
        code: '63707032',
        text:
          'Какое допускается максимальное центростремительное ускорение тележки вагонетки?',
        answers: ['3,0 м/c²', '1,5 м/c²', '2,5 м/c²', '3,5 м/c²'],
        correctAnswers: ['2,5 м/c²'],
      },
      {
        code: '63707033',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности натяжного каната для тяговых канатов?',
        answers: ['1,5', '2,5', '3', '4'],
        correctAnswers: ['4'],
      },
      {
        code: '63707034',
        text:
          'В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?',
        answers: [
          'В случае если на участке длиной, равной 6dk, имеются разрывы двух и более смежных проволок наружного слоя',
          'В случае если имеется выход из замка одной и более проволок наружного слоя без ее обрыва',
          'В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборвана',
          'В случае если выступают оборванные концы наружных проволок каната',
        ],
        correctAnswers: [
          'В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборвана',
        ],
      },
      {
        code: '63707035',
        text:
          'Каким должно быть свободное боковое пространство между вагонеткой (с учетом поперечного качания каната и вагонеток) и сооружениями или естественными препятствиями в местах, где возможен проход людей?',
        answers: [
          'Не менее 2 м',
          'Не более 1,5 м',
          'Не более 1 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63707036',
        text: 'Какими должны быть тяговые и несуще-тяговые канаты?',
        answers: [
          'Прядевыми двойной свивки с органическим сердечником',
          'Закрытой конструкции с двумя слоями клиновидной проволоки',
          'Тройной свивки с металлическим сердечником',
          'Двойной свивки с металлическим сердечником',
        ],
        correctAnswers: ['Прядевыми двойной свивки с органическим сердечником'],
      },
      {
        code: '63707037',
        text:
          'Кем осуществляется проверка готовности к применению аттестованных технологий сварки с целью определения наличия у организации или индивидуального предпринимателя технических, организационных и квалификационных возможностей для выполнения сварочных (наплавочных) работ по применяемым им аттестованным технологиям, а также соответствия качества выполненных при аттестации контрольных сварных соединений (наплавок) требованиям НД и (или) проектной (конструкторской) документации на сварные конструкции?',
        answers: [
          'Независимыми аттестационными центрами',
          'Проектной организацией',
          'Контролирующей организацией',
          'Монтажной организацией',
        ],
        correctAnswers: ['Независимыми аттестационными центрами'],
      },
      {
        code: '63707038',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Только заключения по неразрушающему контролю',
          'Только протоколы сварочных работ',
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63707039',
        text:
          'Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только соответствие основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации',
          'Только соответствие численного состава и квалификации персонала сварочного производства требованиям производственно-технологической документации',
          'Только соответствие сборочного и сварочного оборудования требованиям производственно-технологической документации',
          'Выполнение всех подготовительных мероприятий',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63707040',
        text:
          'Каким условиям должна соответствовать длина натяжного участка несущих канатов (канат, по которому перемещается подвижной состав)?',
        answers: [
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 40% от расчетного',
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 10% от расчетного',
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного',
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 20% от расчетного',
        ],
        correctAnswers: [
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного',
        ],
      },
      {
        code: '63707041',
        text: 'Что не включает в себя техническое обслуживание?',
        answers: [
          'Замер износа узлов и деталей',
          'Дефектоскопию канатов',
          'Регулировку узлов и деталей',
          'Смазку узлов и деталей',
        ],
        correctAnswers: ['Дефектоскопию канатов'],
      },
      {
        code: '63707042',
        text:
          'Какое минимальное расстояние по вертикали от низшей точки вагонетки на трассе ГПКД должно быть при прохождении над зданиями и сооружениями?',
        answers: ['0,5 м', '1,0 м', '1,5 м', '2,0 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63707043',
        text: 'Какой конструкции должны быть несущие канаты ГПКД?',
        answers: [
          'Прядевыми двойной свивки',
          'Прядевыми тройной свивки с металлическим сердечником',
          'Спиральными закрытой конструкции',
          'Прядевыми с органическим сердечником',
        ],
        correctAnswers: ['Спиральными закрытой конструкции'],
      },
      {
        code: '63707044',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку теоретических знаний только способов нагрева металлического изделия',
          'Проверку путем выполнения контрольного сварного соединения и внеочередную проверку знаний по охране труда',
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63707045',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности натяжного каната для несущих канатов?',
        answers: ['1,5', '2,5', '3,0', '3,5'],
        correctAnswers: ['3,5'],
      },
      {
        code: '63707046',
        text:
          'К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?',
        answers: [
          'К выполнению любых сварочных работ при наличии действующего удостоверения сварщика',
          'К выполнению сварочных работ, указанных в действующих документах',
          'К выполнению любых сварочных работ при наличии удостоверения сварщика и после выполнения контрольного сварного соединения',
        ],
        correctAnswers: [
          'К выполнению сварочных работ, указанных в действующих документах',
        ],
      },
      {
        code: '63707047',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Допускается маркировка нанесением краски (символы и цвет определяются распорядительным документом организации)',
          'Допускается маркировка нанесением металлической таблички с номерами клейм сварщиков',
          'Допускается применение клейма сварщика, имеющего наибольший опыт работы',
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63707048',
        text:
          'Кто может быть председателем комиссии по принятию решения о возможности ввода ГПКД в эксплуатацию?',
        answers: [
          'Представитель организации-застройщика',
          'Представитель собственника (владельца)',
          'Представитель генерального подрядчика',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Представитель собственника (владельца)'],
      },
      {
        code: '63707049',
        text: 'Что из перечисленного обозначает личный шифр клейм сварщиков?',
        answers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий двухзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий пятизначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
        correctAnswers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
      },
      {
        code: '63707050',
        text:
          'Какой повышающий коэффициент нагрузок соответствует требованиям по надежности для нагрузок от натяжения тягового каната?',
        answers: ['1', '1,2', '1,3', '1,4', '1,1'],
        correctAnswers: ['1,4'],
      },
      {
        code: '63707051',
        text: 'Что должно быть указано в технологических картах сварки?',
        answers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
          'Требования к работнику, выполняющему сварку, используемые материалы и необходимое оборудование',
          'Требования к оборудованию, режимы сварки и последовательность действий',
        ],
        correctAnswers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
        ],
      },
      {
        code: '63707052',
        text:
          'К какой деятельности в области промышленной безопасности Правила безопасности грузовых подвесных канатных дорог требования не устанавливают?',
        answers: [
          'К изготовлению, монтажу, наладке, обслуживанию и ремонту',
          'К проектированию грузовых подвесных канатных дорог',
          'К эксплуатации, капитальному ремонту, техническому перевооружению, консервации и ликвидации',
          'К проведению экспертизы промышленной безопасности',
        ],
        correctAnswers: ['К проектированию грузовых подвесных канатных дорог'],
      },
      {
        code: '63707053',
        text: 'Какая должна быть минимальная длина счалки тягового каната?',
        answers: [
          '500 диаметров каната',
          '700 диаметров каната',
          '900 диаметров каната',
          '1000 диаметров каната',
        ],
        correctAnswers: ['1000 диаметров каната'],
      },
      {
        code: '63707054',
        text:
          'Какой должен быть коэффициент запаса сцепления тягового каната с приводным шкивом?',
        answers: [
          'Не менее 1,1',
          'Не менее 1,25',
          'Не менее 1,5',
          'Не менее 2,15',
        ],
        correctAnswers: ['Не менее 1,1'],
      },
      {
        code: '63707055',
        text:
          'Какие виды работ могут выполнять работники неспециализированных организаций и ремонтных служб?',
        answers: [
          'Ремонт несущих металлоконструкций',
          'Смазка вагонетки',
          'Ремонт приборов безопасности',
          'Все виды сварочных работ',
        ],
        correctAnswers: ['Смазка вагонетки'],
      },
      {
        code: '63707056',
        text:
          'В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр приборов и устройств безопасности, сигнализации, связи?',
        answers: [
          'Не превышающие 7 дней',
          'Не превышающие 10 дней',
          'Не превышающие 14 дней',
          'Не превышающие 30 дней',
        ],
        correctAnswers: ['Не превышающие 7 дней'],
      },
      {
        code: '63707057',
        text:
          'Какое из перечисленных требований к безопасности технологических процессов грузовых подвесных канатных дорог указано неверно?',
        answers: [
          'Минимальное усилие со стороны каната на каждый поддерживающий ролик одноканатных грузовых подвесных канатных дорог должно быть не менее 500 Н',
          'Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры',
          'Роликовые балансиры на опорах одноканатных грузовых подвесных канатных дорог должны состоять не менее чем из 2 роликов',
          'Поддерживающие ролики не должны вызывать отклонение несуще-тяговых и тяговых канатов более чем на 10%',
        ],
        correctAnswers: [
          'Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры',
        ],
      },
      {
        code: '63707058',
        text:
          'Какое из перечисленных требований, установленных к ГПКД с маятниковым движением подвижного состава, указано неверно?',
        answers: [
          'Грузовые подвесные канатные дороги с маятниковым движением подвижного состава должны автоматически останавливаться при возникновении неисправности в системе контроля скорости',
          'Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с',
          'Привод грузовой подвесной канатной дороги с маятниковым движением подвижного состава должен быть оснащен системой регулирования и контроля скорости с учетом ее снижения при подходе к станции',
          'На грузовых подвесных канатных дорогах с маятниковым движением подвижного состава конечные положения вагонетки должны контролироваться автоматически',
        ],
        correctAnswers: [
          'Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с',
        ],
      },
      {
        code: '63707059',
        text:
          'Какое минимальное соотношение между натяжением несущего каната и весом груженой вагонетки?',
        answers: ['9', '10', '6', '7', '8'],
        correctAnswers: ['8'],
      },
      {
        code: '63707060',
        text:
          'Что из перечисленного должно контролироваться в процессе сварки, как минимум?',
        answers: [
          'Только соответствие параметров режима сварки и технологических приемов выполнения сварного соединения',
          'Только очередность выполнения сварных швов и участков наплавки, отсутствие видимых дефектов',
          'Только параметры, предусмотренные в технологических (операционных) картах сварки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63707061',
        text:
          'Какая максимальная скорость движения груженых вагонеток на линии установлена для двухканатных кольцевых ГПКД при наличии на их станциях горизонтальных обводных шкивов?',
        answers: ['1,0 м/с', '1,6 м/с', '2,0 м/с', '2,5 м/с'],
        correctAnswers: ['1,6 м/с'],
      },
      {
        code: '63707062',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности для несущего каната?',
        answers: ['1,8', '2,0', '2,5', '2,8'],
        correctAnswers: ['2,8'],
      },
      {
        code: '63707063',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности для тягового каната?',
        answers: ['1,5', '2,5', '3,0', '4,0'],
        correctAnswers: ['4,0'],
      },
      {
        code: '63707064',
        text:
          'Для каких нагрузок учитывается повышающий коэффициент нагрузок 1,1, соответствующий требованиям по надежности грузовых подвесных канатных дорог?',
        answers: [
          'Для нагрузок от трения канатов по башмаку',
          'Для нагрузок от веса оборудования',
          'Для нагрузок от натяжения расчалочных канатов',
          'Для нагрузок от натяжения сетевых канатов',
        ],
        correctAnswers: ['Для нагрузок от веса оборудования'],
      },
      {
        code: '63707065',
        text:
          'Какой ширины должны быть безопасные проходы для людей, обслуживающих оборудование ГПКД?',
        answers: [
          'От 0,5 м до 0,8 м',
          'Не менее 0,8 м',
          'Не менее 0,5 м',
          'От 0,6 м до 0,7 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63707066',
        text: 'В какие сроки осуществляется техническое обслуживание ГПКД?',
        answers: [
          'В срок не более 70 дней',
          'В срок не более 60 дней',
          'В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги',
          'В срок не более 90 дней',
        ],
        correctAnswers: [
          'В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги',
        ],
      },
      {
        code: '63707067',
        text:
          'В каких случаях допускается транспортировка людей в вагонетках при эксплуатации ГПКД?',
        answers: [
          'Только если транспортируются работники, выполняющие проверку состояния канатов',
          'Только если транспортируются работники для выполнения регламентных работ',
          'Только если транспортируются работники, вынужденные использовать ГПКД для проезда к своему месту работы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63707068',
        text:
          'При каком наклоне к горизонту рельсовые пути галерей и станций должны быть оборудованы ловителями, препятствующими обратному ходу вагонеток при движении их на подъем?',
        answers: ['Свыше 10%', 'Свыше 7%', 'Свыше 5%', 'Свыше 3%'],
        correctAnswers: ['Свыше 10%'],
      },
      {
        code: '63707069',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
          'Только сварочным оборудованием',
          'Только сварочным оборудованием и аптечкой первой помощи',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63707070',
        text:
          'Какие документы регламентируют закрепление несущих канатов в муфтах?',
        answers: [
          'Рекомендации заводов - изготовителей канатов',
          'Декларации промышленной безопасности',
          'Технологии работ',
          'Инструкции по монтажу',
        ],
        correctAnswers: ['Рекомендации заводов - изготовителей канатов'],
      },
      {
        code: '63707071',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Только сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ',
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
          'Любые работники, аттестованные для соответствующих способов сварки',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63707072',
        text: 'Какой документ на ГПКД не является обязательным?',
        answers: [
          'Должностные инструкции для специалистов',
          'Производственные инструкции для персонала',
          'Журнал работы ГПКД и передачи смен',
          'График внепланового осмотра оборудования',
        ],
        correctAnswers: ['График внепланового осмотра оборудования'],
      },
      {
        code: '63707073',
        text:
          'Какое может быть наибольшее число соединений тягового каната на 1 км его длины при частичной замене во время эксплуатации?',
        answers: ['5', '7', '9', '10'],
        correctAnswers: ['5'],
      },
      {
        code: '63707074',
        text:
          'Что из перечисленного должно быть предусмотрено на станциях ГПКД?',
        answers: [
          'Устройства для автоматического выпуска вагонеток на линию',
          'Механизация загрузки и разгрузки вагонеток',
          'Механизация возвращения кузова в первоначальное положение после разгрузки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63707075',
        text:
          'Что из перечисленного входит в перечень работ, выполняемых специализированными организациями или ремонтными службами (подразделениями) эксплуатирующей организации?',
        answers: [
          'Только работы, связанные с наплавкой, напылением и последующей механической обработкой деталей',
          'Только работы, связанные с нагревом (охлаждением) деталей и узлов',
          'Только монтаж приборов безопасности, средств сигнализации и связи',
          'Только ремонт натяжных и анкерных устройств для всех видов канатов',
          'Все перечисленные виды работ',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63707076',
        text:
          'Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?',
        answers: [
          'Только регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений',
          'Только идентификацию применяемых сварочных материалов и сварочного оборудования, выполнение сварных соединений в соответствии с технологическими (операционными) картами сварки',
          'Только регистрацию сведений о сварщиках, выполняющих сварные соединения',
          'Все перечисленные мероприятия',
          'Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63707077',
        text:
          'В каком документе указывается срок очередного технического освидетельствования?',
        answers: [
          'В инструкции по эксплуатации',
          'В приказе о вводе в эксплуатацию',
          'В паспорте канатной дороги',
          'В плане-графике обслуживания грузовых подвесных канатных дорог',
        ],
        correctAnswers: ['В паспорте канатной дороги'],
      },
      {
        code: '63707078',
        text:
          'Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только организация проведения операционного контроля',
          'Только проверка и обеспечение соответствия численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации',
          'Только ознакомление сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки',
          'Все перечисленные функции',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63707079',
        text:
          'Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?',
        answers: [
          'Только входной контроль',
          'Только операционный контроль',
          'Только приемочный контроль',
          'Все перечисленные виды контроля',
        ],
        correctAnswers: ['Все перечисленные виды контроля'],
      },
      {
        code: '63707080',
        text:
          'В каких местах не должны быть установлены кнопки аварийный СТОП?',
        answers: [
          'У линейных опор',
          'В помещениях приводов',
          'Вблизи включателей и выключателей вагонеток',
          'У мест загрузки и разгрузки вагонеток',
        ],
        correctAnswers: ['У линейных опор'],
      },
      {
        code: '63707081',
        text:
          'Каким документом регламентируется ежедневный контроль состояния дороги?',
        answers: [
          'Технологией работ',
          'Приказом по предприятию',
          'Актом осмотра',
          'Руководством по эксплуатации',
        ],
        correctAnswers: ['Руководством по эксплуатации'],
      },
      {
        code: '63707082',
        text:
          'Какое из перечисленных утверждений к лестницам для подъема на опоры и станции грузовых подвесных канатных дорог указано верно?',
        answers: [
          'Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг',
          'Лестницы высотой более 30 м должны иметь ограждения, начиная с высоты 1,8 м',
          'Лестницы высотой менее 30 м должны быть выполнены в виде маршей с площадками',
          'Все перечисленные утверждения верны',
        ],
        correctAnswers: [
          'Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг',
        ],
      },
      {
        code: '63707083',
        text:
          'Какое из перечисленных требований к грузовым подвесным канатным дорогам указано верно?',
        answers: [
          'В машинном отделении должно быть устроено аварийное освещение',
          'Канат не требуется заземлять, если у него органический сердечник',
          'Не обязательно освещать в темное время суток участки входа и выхода вагонеток, если они двигаются на открытой местности',
          'Нумерация опор обязательна только при условии, что их количество больше 10',
        ],
        correctAnswers: [
          'В машинном отделении должно быть устроено аварийное освещение',
        ],
      },
      {
        code: '63707084',
        text:
          'Каким способом должен соединяться натяжной канат с несущим канатом?',
        answers: [
          'Коушами с зажимом',
          'Переходной муфтой',
          'Якорными барабанами',
          'Концевой муфтой',
        ],
        correctAnswers: ['Переходной муфтой'],
      },
      {
        code: '63707085',
        text:
          'Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?',
        answers: [
          'Только способы подготовки поверхностей деталей под сварку и используемые при сборке приспособления и оборудование',
          'Только порядок и последовательность сборки, способы крепления деталей, размеры, количество и расположение прихваток, методы контроля качества сборки',
          'Только способы сварки, сварочные материалы и режимы сварки при выполнении прихваток и приварке временных технологических креплений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63707086',
        text:
          'В каком из перечисленных случаев стальной канат двойной свивки ГПКД не подлежит браковке?',
        answers: [
          'При износе высоты наружной фасонной проволоки замка на 50% и более у каната закрытой конструкции',
          'При уменьшении первоначального диаметра наружных проволок в результате износа на 40% и более, если их количество равно либо превышает браковочные показатели по обрывам',
          'При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов',
          'При уменьшении диаметра каната в результате поверхностного износа или коррозии на 7% и более, по сравнению с номинальным диаметром, даже при отсутствии видимых обрывов проволок',
        ],
        correctAnswers: [
          'При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов',
        ],
      },
      {
        code: '63707087',
        text:
          'Какое минимальное число витков каната должно быть на якорном барабане?',
        answers: ['1,5', '2', '2,5', '3'],
        correctAnswers: ['3'],
      },
      {
        code: '63707088',
        text:
          'В соответствии с требованиями каких из перечисленных документов должны осуществляться транспортировка к месту монтажа, а также хранение оборудования ГПКД, узлов и деталей?',
        answers: [
          'Федеральные нормы и правила "Правила безопасности грузовых подвесных канатных дорог"',
          'Внутренних правил перевозчика',
          'Инструкции изготовителя',
          'Проектной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63707089',
        text:
          'Какую информацию должно содержать руководство по эксплуатации грузовых подвесных канатных дорог?',
        answers: [
          'Только методы контроля и диагностики состояния элементов и узлов грузовых подвесных канатных дорог',
          'Всю перечисленную информацию',
          'Только указания по выводу из эксплуатации и утилизации',
          'Только программы и методики испытаний',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63707090',
        text:
          'Кем принимается решение о вводе в эксплуатацию грузовых подвесных канатных дорог?',
        answers: [
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
          'Ответственным за исправное состояние грузовых подвесных канатных дорог',
          'Территориальным органом Ростехнадзора',
          'Ответственным за безопасную эксплуатацию грузовых подвесных канатных дорог',
        ],
        correctAnswers: [
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
        ],
      },
      {
        code: '63707091',
        text:
          'Каким должно быть минимальное натяжение тягового каната кольцевых ГПКД?',
        answers: [
          'От 500q до 600q (q - погонный вес тягового каната)',
          'Не должно быть меньше 500q (q - погонный вес тягового каната)',
          'Не должно быть меньше 600q (q - погонный вес тягового каната)',
          'От 400q до 700q (q - погонный вес тягового каната)',
        ],
        correctAnswers: [
          'Не должно быть меньше 600q (q - погонный вес тягового каната)',
        ],
      },
      {
        code: '63707092',
        text:
          'Какая устанавливается минимальная высота бортов предохранительных сетей?',
        answers: ['1,8 м', '1,2 м', '1 м', '1,5 м'],
        correctAnswers: ['1,2 м'],
      },
      {
        code: '63707093',
        text:
          'В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?',
        answers: [
          'Производителя сварочного оборудования',
          'Владельца сварочного оборудования',
          'Органа по сертификации сварочного оборудования',
          'Территориального управления Ростехнадзора',
        ],
        correctAnswers: ['Производителя сварочного оборудования'],
      },
      {
        code: '63707094',
        text: 'С какой целью проводится техническое освидетельствование ГКПД?',
        answers: [
          'Только с целью проверки соответствия ГКПД и ее устройства Правилам безопасности грузовых подвесных канатных дорог и паспортным данным',
          'Только с целью установления, что ГКПД находится в технически исправном состоянии, обеспечивающем ее безопасную работу',
          'Только с целью установления, что эксплуатация, содержание и обслуживание ГКПД соответствуют требованиям Правил безопасности грузовых подвесных канатных дорог',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63707095',
        text:
          'Кто входит в состав комиссии, принимающей решение о проведении ремонта канатов?',
        answers: [
          'Представители эксплуатирующей и специализированной организаций',
          'Представители эксплуатирующей организации',
          'Представители эксплуатирующей организации и представители территориального органа Ростехнадзора',
          'Представители владельца, организации, разработавшей проект, и (или) специализированной организации',
        ],
        correctAnswers: [
          'Представители владельца, организации, разработавшей проект, и (или) специализированной организации',
        ],
      },
      {
        code: '63707096',
        text:
          'Что из перечисленного, согласно Правилам безопасности грузовых подвесных канатных дорог, не проводится при техническом освидетельствовании ГПКД?',
        answers: [
          'Проверка технического состояния оборудования путем осмотра и измерений',
          'Статические и динамические испытания',
          'Поверка контрольных (испытательных) грузов',
          'Учебная операция по отработке действий работников грузовых подвесных канатных дорог в случае возникновения возможной чрезвычайной ситуации',
          'Проверка эксплуатационной документации',
        ],
        correctAnswers: ['Поверка контрольных (испытательных) грузов'],
      },
      {
        code: '63707097',
        text:
          'Какие из перечисленных видов контроля не осуществляются при подготовке и выполнении сварочных работ?',
        answers: [
          'Входной контроль',
          'Операционный контроль',
          'Приемочный контроль',
          'Эксплуатационный контроль',
        ],
        correctAnswers: ['Эксплуатационный контроль'],
      },
    ],
    63706: [
      {
        code: '63706000',
        text:
          'Какой минимальный коэффициент запаса прочности тяговых канатов соответствует наземной канатной дороге?',
        answers: ['3,8', '4', '4,2', '4,5'],
        correctAnswers: ['4,2'],
      },
      {
        code: '63706001',
        text:
          'Каким должно быть боковое безопасное расстояние на станциях кресельных канатных дорог между свободно висящим креслом без пассажиров и неподвижными деталями пассажирской подвесной канатной дороги на уровне сиденья со стороны оси дороги?',
        answers: [
          'Не менее 0,8 м от края кресла',
          'Не менее 1,0 м от края кресла',
          'Не менее 1,2 м от края кресла',
          'Не менее 1,5 м от края кресла',
        ],
        correctAnswers: ['Не менее 0,8 м от края кресла'],
      },
      {
        code: '63706002',
        text:
          'Какие из перечисленных требований необходимо соблюдать при установке направляющих подвижного состава пассажирской подвесной канатной дороги?',
        answers: [
          'Направляющие на станциях или в непосредственной близости от них необходимо устанавливать вне диапазона досягаемости рукой',
          "Направляющие на станциях должны быть установлены таким образом, чтобы кабины при продольных колебаниях 14°20' и одновременных поперечных колебаниях 14°20' не могли соприкасаться",
          'Направляющие на линейном оборудовании трассы устанавливают для ограничения поперечного раскачивания кабин вне диапазона досягаемости рукой и на высоте нахождения центра тяжести кабины',
          "Направляющие на станциях устанавливают для ограничения поперечного раскачивания кресел на 28°30'",
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63706003',
        text:
          'Какая ревизия должна быть проведена, если КД не эксплуатировали более полугода?',
        answers: [
          'Ежедневная ревизия',
          'Еженедельная ревизия',
          'Ежемесячная ревизия',
          'Ежегодная ревизия',
        ],
        correctAnswers: ['Ежегодная ревизия'],
      },
      {
        code: '63706004',
        text:
          'В какие сроки проводится повторная магнитная дефектоскопия канатов?',
        answers: [
          'Ежегодно, во время технического освидетельствования',
          'Во время проведения экспертизы промышленной безопасности перед вводом в эксплуатацию, а затем после окончания установленного срока службы КД',
          'Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые 2 года',
          'Раз в 3 года в первые 15 лет эксплуатации и далее – ежегодно',
        ],
        correctAnswers: [
          'Раз в 3 года в первые 15 лет эксплуатации и далее – ежегодно',
        ],
      },
      {
        code: '63706005',
        text:
          'В каких случаях допускается увеличивать высоту от низа подвижного состава ППКД с открытыми креслами до земли на 10 м от максимально допустимой?',
        answers: [
          'На участках протяженностью не более 1/3 пролета',
          'В пролетах длиной до 100 м',
          'В пролетах длиной свыше 200 м',
          'Высоту от низа подвижного состава до земли на таких ППКД увеличивать нельзя',
        ],
        correctAnswers: ['На участках протяженностью не более 1/3 пролета'],
      },
      {
        code: '63706006',
        text:
          'В каких случаях при эксплуатации КД устанавливаются защитные сетки? Выберите 2 варианта ответа.',
        answers: [
          'При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка',
          'При расстоянии до земли более 3 м',
          'При перепаде высот в зоне высадки более чем на 0,8 м в начале наклонного участка',
          'При расстоянии до земли более 2 м',
        ],
        correctAnswers: [
          'При перепаде высот в зоне высадки более чем на 1 м в начале наклонного участка',
          'При расстоянии до земли более 3 м',
        ],
      },
      {
        code: '63706007',
        text:
          'Чему равен минимальный коэффициент запаса прочности для спасательных и эвакуационных канатов на канатных лебедках?',
        answers: ['5', '3', '2,75', '3,75'],
        correctAnswers: ['5'],
      },
      {
        code: '63706008',
        text:
          'Какое из перечисленных требований к трассе наземных канатных дорог указано верно?',
        answers: [
          'Радиус поворота пути на линии вне зон разъезда и приближения к станциям должен составлять не менее 100 м',
          'Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²',
          'Динамические габариты трассы, определяемые габаритами подвижного состава, составляют +/- 0,5 м',
          'В аварийном режиме замедление не должно превышать 4 м/c²',
        ],
        correctAnswers: [
          'Боковое ускорение не должно превышать 0,65 м/c². В рабочем режиме движения ускорение/замедление не должно превышать 0,35 м/c²',
        ],
      },
      {
        code: '63706009',
        text:
          'Какому требованию должен отвечать оператор, допускаемый к самостоятельной работе?',
        answers: [
          'Иметь высшее образование и пройти стажировку в течение трех месяцев на действующей канатной дороге',
          'Иметь высшее образование и стаж работы на КД того же типа не менее трех лет',
          'Должен пройти стажировку на рабочем месте сроком не менее двух недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД',
          'Должен иметь допуск к работам на высоте и иметь опыт таких работ не менее трех лет',
        ],
        correctAnswers: [
          'Должен пройти стажировку на рабочем месте сроком не менее двух недель. При этом во время стажировки должны быть отработаны все возможные виды отказов КД',
        ],
      },
      {
        code: '63706010',
        text:
          'Какая из перечисленных обязанностей относится к вспомогательному персоналу канатной дороги? Выберите 2 варианта ответа.',
        answers: [
          'Поддерживать в надлежащем состоянии площадки посадки и высадки пассажиров, свою рабочую зону, а также проходы для персонала на станции',
          'Замедлять или останавливать движение КД в случае возникновения аварий и инцидентов',
          'Делать записи в вахтенном журнале учета работы канатной дороги',
          'Знать устройство и принципы работы канатной дороги и ее отдельных узлов и механизмов',
          'Знать порядок ремонта и обслуживания канатной дороги',
        ],
        correctAnswers: [
          'Поддерживать в надлежащем состоянии площадки посадки и высадки пассажиров, свою рабочую зону, а также проходы для персонала на станции',
          'Замедлять или останавливать движение КД в случае возникновения аварий и инцидентов',
        ],
      },
      {
        code: '63706011',
        text:
          'В каком случае КД не подлежит экспертизе промышленной безопасности?',
        answers: [
          'При отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает десять лет',
          'До начала применения КД на ОПО',
          'В случае истечения срока службы или при превышении количества циклов нагрузки КД, установленных организацией-изготовителем',
          'В случае отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает двадцать лет',
        ],
        correctAnswers: [
          'При отсутствия в технической документации данных о сроке службы КД, если фактический срок ее службы превышает десять лет',
        ],
      },
      {
        code: '63706012',
        text: 'Какие требования к натяжным устройствам КД указаны неверно?',
        answers: [
          'Реборды натяжных шкивов должны выступать за футеровку не менее чем на 1/4 диаметра каната',
          'Коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната должен быть не менее 3',
          'Конструкции контргрузов и натяжных тележек должны исключать возможность схода, зажатия, опрокидывания, наклона, закручивания',
          'Для крепления концов несущих и натяжных канатов на тумбе должно быть предусмотрено не менее трех полных витков',
        ],
        correctAnswers: [
          'Реборды натяжных шкивов должны выступать за футеровку не менее чем на 1/4 диаметра каната',
        ],
      },
      {
        code: '63706013',
        text:
          'Автоматический контроль каких режимов должен быть обеспечен на ППКД и НКД?',
        answers: [
          'Только отключения при превышении скорости',
          'Только срабатывания рабочих тормозов',
          'Только срабатывания аварийных тормозов',
          'Всех перечисленных',
          'Только отслеживания замедления движения',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63706014',
        text:
          'Какой угол отклонения в плане на опоре допускается для несущих канатов ППКД?',
        answers: ['17’', '30’', '11о 30’', '19о 30’'],
        correctAnswers: ['17’'],
      },
      {
        code: '63706015',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?',
        answers: ['3 секунды', '4 секунды', '5 секунд', '7 секунд'],
        correctAnswers: ['5 секунд'],
      },
      {
        code: '63706016',
        text:
          'На какое минимальное расстояние допускается приближение строений или естественных препятствий к габаритам БКД?',
        answers: ['1 м', '1,5 м', '2 м', '2,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63706017',
        text:
          'Какое требование соответствует проходам для персонала и эвакуации пассажиров вдоль трассы наземных канатных дорог?',
        answers: [
          'Должны иметь ширину не менее 1,0 м и высоту не менее 2,4 м',
          'Должны иметь ширину не менее 0,8 м и высоту не менее 2,2 м',
          'Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м',
          'Должны иметь ширину не менее 0,4 м и высоту не менее 1,8 м',
        ],
        correctAnswers: [
          'Должны иметь ширину не менее 0,6 м и высоту не менее 2,0 м',
        ],
      },
      {
        code: '63706018',
        text:
          'Кто несет ответственность за содержание канатной дороги в исправном состоянии и соблюдение безопасных условий работы?',
        answers: [
          'Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у него во владении на праве собственности или ином законном основании',
          'Юридическое лицо',
          'Специализированная организация',
          'Организация по ремонту и обслуживанию канатных дорог',
        ],
        correctAnswers: [
          'Юридическое лицо или индивидуальный предприниматель, осуществляющие эксплуатацию КД в составе ОПО, находящуюся у него во владении на праве собственности или ином законном основании',
        ],
      },
      {
        code: '63706019',
        text:
          'С кем согласовывается изменение конструкции отдельных элементов или паспортных характеристик КД?',
        answers: [
          'С территориальным органом Ростехнадзора',
          'С экспертной организацией',
          'С организацией, выполнившей монтаж КД',
          'С разработчиком проекта, а в случае его отсутствия - со специализированной организацией',
        ],
        correctAnswers: [
          'С разработчиком проекта, а в случае его отсутствия - со специализированной организацией',
        ],
      },
      {
        code: '63706020',
        text:
          'В каком случае должен приводиться в действие тормоз, предотвращающий самопроизвольный обратный ход дороги, автоматически?',
        answers: [
          'Только при прекращении подачи электроэнергии к главному двигателю',
          'Только при падении давления в гидравлических или пневматических тормозах ниже допустимого значения',
          'Только при размыкании в цепи безопасности',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63706021',
        text: 'Какой допускается поперечный уклон буксировочной дорожки?',
        answers: [
          'Установленный технической документацией',
          'С одноместными буксировочными устройствами - 30°',
          'С двухместными буксировочными устройствами - 26°',
          'Поперечный уклон не допускается',
        ],
        correctAnswers: ['Поперечный уклон не допускается'],
      },
      {
        code: '63706022',
        text:
          'Какие требования к участкам отцепления-прицепления зажима и каната у НКД указаны неверно?',
        answers: [
          'Участки отцепления-прицепления зажима и каната должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 30 %',
          'Участки должны обеспечивать отцепление и прицепление зажима вне зависимости от скорости движения',
          'Участки должны иметь защиту от атмосферного воздействия',
          'Все перечисленное верно',
        ],
        correctAnswers: [
          'Участки отцепления-прицепления зажима и каната должны обеспечивать отцепление и прицепление зажима при увеличении нагрузки на единицу подвижного состава на 30 %',
        ],
      },
      {
        code: '63706023',
        text:
          'Какую величину составляет максимально допустимая скорость для БКД с промежуточными опорами?',
        answers: ['4 м/с', '5 м/с', '6 м/с', '4,5 м/с'],
        correctAnswers: ['4 м/с'],
      },
      {
        code: '63706024',
        text:
          'Какой максимальный продольный уклон буксировочной дорожки допускается в общем случае для дорог с двухместными буксировочными устройствами?',
        answers: ['30°', '26°', '22°', '14° у шкивов и 22° на линии'],
        correctAnswers: ['26°'],
      },
      {
        code: '63706025',
        text:
          'Какой минимальный коэффициент устойчивости подвижного состава во всех направлениях с учетом неблагоприятного действия на него нагрузок должен быть с учетом силы ветра при движении подвижного состава?',
        answers: ['1', '2', '1,4', '2,2'],
        correctAnswers: ['2'],
      },
      {
        code: '63706026',
        text:
          'В каком из перечисленных случаев максимальная скорость движения пульсирующих дорог с закрытыми неотцепляемыми кабинами составляет 1,3 м/с?',
        answers: [
          'Для полуоткрытых кабин и кресел на станциях',
          'Для полуоткрытых кабин и кресел на трассе',
          'На станциях при посадке-высадке',
          'На трассе с одним несуще-тяговым канатом',
        ],
        correctAnswers: ['Для полуоткрытых кабин и кресел на трассе'],
      },
      {
        code: '63706027',
        text:
          'Какие из перечисленных требований к башмакам несущего каната ППКД указаны неверно?',
        answers: [
          'Концы башмаков каната должны иметь радиус скругления, соответствующий трехкратному диаметру несущего каната',
          'Концы башмаков каната должны иметь радиус скругления, соответствующий пятикратному диаметру несущего каната',
          'Длина радиуса скругления концов башмака должна соответствовать трехкратному диаметру каната',
          'На двухканатных дорогах нагрузка от несущего каната на опорный башмак должна оставаться положительной с учетом давления ветра снизу до 500 Па',
        ],
        correctAnswers: [
          'Концы башмаков каната должны иметь радиус скругления, соответствующий трехкратному диаметру несущего каната',
        ],
      },
      {
        code: '63706028',
        text:
          'Что необходимо предпринять, если буксировочная дорожка проходит рядом с местом катания лыжников?',
        answers: [
          'Буксировочную дорожку необходимо выделить (обозначить)',
          'Установить силовое ограждение вдоль всего участка приближения',
          'Установить информационные и запрещающие знаки',
          'Перенести зону катания в другое место',
        ],
        correctAnswers: [
          'Буксировочную дорожку необходимо выделить (обозначить)',
        ],
      },
      {
        code: '63706029',
        text:
          'В каком случае разрешается выход на линию средства подвижного состава с недостаточным усилием зацепления после прохождения участка прицепления на наземной канатной дороге?',
        answers: [
          'В случае присутствия обслуживающего персонала',
          'В случае установки необходимых направляющих для каната и зажима',
          'В случае установки механических контрольных устройств, препятствующих отцеплению',
          'Не разрешается ни в каком случае',
        ],
        correctAnswers: ['Не разрешается ни в каком случае'],
      },
      {
        code: '63706030',
        text: 'Какой знак не устанавливается в зоне высадки кресельных ППКД?',
        answers: [
          '"Езда "слаломом" запрещена"',
          '"Поднять носки лыж"',
          '"Открыть скобу безопасности или защитный колпак"',
          '"Место высадки"',
        ],
        correctAnswers: ['"Езда "слаломом" запрещена"'],
      },
      {
        code: '63706031',
        text: 'Кем проводится ревизия при эксплуатации КД?',
        answers: [
          'Эксплуатирующей организацией',
          'Специализированной организацией',
          'Проектной организацией',
          'Ростехнадзором',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63706032',
        text:
          'Какие перечисленные размеры проходов и рабочих зон канатных дорог указаны верно? Укажите все правильные ответы.',
        answers: [
          'Высота проходов для пассажиров под транспортными путями и рабочими зонами должна быть не менее 3,0 м',
          'На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м',
          'Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м',
          'Контрольные зоны для предъявления проездных документов и входы для КД с креслами должны быть шириной не менее 1,2 м',
        ],
        correctAnswers: [
          'На рабочих площадках обслуживания подвижного состава и в местах расположения проходов для персонала, где нависают детали конструкции, достаточно прохода высотой 2 м',
          'Минимальная ширина проходов для пассажиров должна соответствовать пропускной способности дороги и быть не менее 1,25 м',
        ],
      },
      {
        code: '63706033',
        text:
          'Какое максимальное расстояние допускается между краем посадочной платформы и подвижным составом на канатных дорогах с кабинами или вагонами?',
        answers: ['0,2 м', '0,15 м', '0,1 м', '0,05 м'],
        correctAnswers: ['0,05 м'],
      },
      {
        code: '63706034',
        text:
          'Какие показатели не должны контролироваться ежедневно во время эксплуатации КД?',
        answers: [
          'Плавность работы привода, шкивов и роликов на станциях',
          'Прохождение подвижного состава по станции',
          'Изменение климатических условий',
          'Отсутствие повреждений подвижного состава',
          'Функционирование всех кнопок остановки',
        ],
        correctAnswers: ['Функционирование всех кнопок остановки'],
      },
      {
        code: '63706035',
        text: 'Каким устройством должны быть оснащены все типы канатных дорог?',
        answers: [
          'Счетчиком моточасов',
          'Анемометром или анеморумбометром',
          'Аварийным приводом',
          'Аварийным тормозом',
        ],
        correctAnswers: ['Счетчиком моточасов'],
      },
      {
        code: '63706036',
        text:
          'Чему должен быть равен коэффициент запаса надежности сцепления тягового и несуще-тягового канатов с приводным шкивом?',
        answers: [
          'Не менее 1,25',
          'Не менее 1,1',
          'Не менее 1,2',
          'Не менее 0,75',
        ],
        correctAnswers: ['Не менее 1,25'],
      },
      {
        code: '63706037',
        text:
          'Какое значение должен составлять диаметр счаленных (замкнутых) спасательных канатов?',
        answers: [
          'Не менее 10 мм',
          'Не менее 15 мм',
          'Не менее 20 мм',
          'Не менее 25 мм',
        ],
        correctAnswers: ['Не менее 15 мм'],
      },
      {
        code: '63706038',
        text:
          'В какие сроки проводятся испытания на стягивание для отцепляемых зажимов?',
        answers: [
          'После отработки 18000 моточасов, но не позднее чем через 10 лет',
          'После отработки 13000 моточасов, но не позднее чем через 10 лет',
          'После отработки 5000 моточасов, но не позднее чем через 3 года',
          'После отработки 9000 моточасов, но не позднее чем через 5 лет',
        ],
        correctAnswers: [
          'После отработки 9000 моточасов, но не позднее чем через 5 лет',
        ],
      },
      {
        code: '63706039',
        text:
          'В каких местах устанавливаются кнопки аварийной остановки на буксировочных канатных дорогах?',
        answers: [
          'На пунктах управления',
          'На лестницах к приводному и обводному шкивам',
          'На приводе',
          'В машинном помещении',
        ],
        correctAnswers: ['На пунктах управления'],
      },
      {
        code: '63706040',
        text:
          'Каким требованиям должно соответствовать оборудование канатных дорог, приобретаемых за рубежом?',
        answers: [
          'Требованиям Правил безопасности пассажирских канатных дорог и фуникулеров',
          'Требованиям Европейских норм, действующих в области канатных дорог',
          'Требованиям норм Таможенного союза',
          'Требованиям норм страны-производителя',
        ],
        correctAnswers: [
          'Требованиям Правил безопасности пассажирских канатных дорог и фуникулеров',
        ],
      },
      {
        code: '63706041',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами двухместных БКД?',
        answers: ['3 секунды', '4 секунды', '5 секунд', '6 секунд'],
        correctAnswers: ['6 секунд'],
      },
      {
        code: '63706042',
        text:
          'Каким должен оставаться угол между вытянутым вытяжным канатом и нормалью при всех условиях эксплуатации?',
        answers: [
          'Не менее 17°',
          'Не менее 20°',
          'Не менее 23°',
          'Не менее 25°',
        ],
        correctAnswers: ['Не менее 17°'],
      },
      {
        code: '63706043',
        text:
          'Какая максимальная скорость движения наземных канатных дорог составляет для систем с автоматическим управлением?',
        answers: ['5 м/с', '10 м/с', '15 м/с', '20 м/с'],
        correctAnswers: ['15 м/с'],
      },
      {
        code: '63706044',
        text:
          'В каком случае допустимый коэффициент трения для приводных шкивов с резиновой футеровкой буксировочных канатных дорог составляет 0,22?',
        answers: [
          'При использовании фиксированных зажимов без учета кручения каната',
          'При использовании отцепляемых зажимов с учетом кручения каната (втулки поводковых патронов)',
          'При диаметре тягового каната до 18 мм и при использовании фиксированных зажимов без учета кручения каната',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'При использовании фиксированных зажимов без учета кручения каната',
        ],
      },
      {
        code: '63706045',
        text:
          'Какая максимальная скорость движения двухканатных маятниковых канатных дорог с проводником при прохождении опор?',
        answers: ['6 м/с', '8,5 м/с', '10 м/с', '12 м/с'],
        correctAnswers: ['10 м/с'],
      },
      {
        code: '63706046',
        text:
          'Какой блок считается как один отцепляемый зажим на пассажирских подвесных канатных дорогах?',
        answers: [
          'Никакой блок не может считаться за один зажим',
          'Если между центрами двух зажимов расстояние меньше 25 диаметров каната',
          'Если между центрами двух зажимов расстояние меньше 20 диаметров каната',
          'Если между центрами двух зажимов расстояние меньше 15 диаметров каната',
        ],
        correctAnswers: [
          'Если между центрами двух зажимов расстояние меньше 15 диаметров каната',
        ],
      },
      {
        code: '63706047',
        text: 'В каком случае проводятся динамические испытания?',
        answers: [
          'При проведении ежегодного технического освидетельствования',
          'При проведении ежемесячной ревизии',
          'При проведении еженедельной проверки',
          'При проведении пробного пуска',
        ],
        correctAnswers: [
          'При проведении ежегодного технического освидетельствования',
        ],
      },
      {
        code: '63706048',
        text:
          'Что должен сделать владелец опасного производственного объекта (далее - ОПО) до ввода КД в эксплуатацию?',
        answers: [
          'Обеспечить проведение экспертизы промышленной безопасности КД',
          'Обеспечить проведение полного технического освидетельствования',
          'Обеспечить проведение ежегодного технического освидетельствования',
          'Отработать в тестовом режиме с полной загрузкой не менее 1 месяца',
        ],
        correctAnswers: [
          'Обеспечить проведение экспертизы промышленной безопасности КД',
        ],
      },
      {
        code: '63706049',
        text:
          'Какое из перечисленных требований к процессу эвакуации пассажиров с подвижного состава КД указано неверно?',
        answers: [
          'КД должна быть оснащена осветительным оборудованием, позволяющим проводить эвакуацию в условиях плохой видимости',
          'Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей',
          'Во время снятия пассажиров КД должна быть остановлена',
        ],
        correctAnswers: [
          'Снятие пассажиров с подвижного состава на землю осуществляют только одной бригадой спасателей',
        ],
      },
      {
        code: '63706050',
        text:
          'Какой документ устанавливает методы контроля и диагностики во время эксплуатации КД и при техническом освидетельствовании?',
        answers: [
          'Паспорт КД',
          'Руководство по эксплуатации КД',
          'Правила безопасности пассажирских канатных дорог и фуникулеров',
          'Правила проведения экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Руководство по эксплуатации КД'],
      },
      {
        code: '63706051',
        text:
          'Кто принимает решение о возобновлении дальнейшей эксплуатации канатной дороги в результате рассмотрения причин ее остановки?',
        answers: [
          'Начальник смены',
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
          'Начальник ремонтной бригады',
          'Комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
        ],
      },
      {
        code: '63706052',
        text:
          'Какой из перечисленных параметров КД может контролироваться перед, во время и после процесса сцепления зажимов?',
        answers: [
          'Правильное охватывание каната губками зажима',
          'Открытое положение губок зажима',
          'Отделение зажима от каната',
          'Зажимное усилие',
        ],
        correctAnswers: ['Зажимное усилие'],
      },
      {
        code: '63706053',
        text:
          'В каком случае отношение между минимальным натяжением каната и весом единицы груженого подвижного состава рассчитывают с минимальным коэффициентом 10?',
        answers: [
          'В случае несущего каната с заякоренными концами',
          'В случае несущего каната с натяжным устройством',
          'В случае несуще-тягового каната',
          'В случае тягового каната',
        ],
        correctAnswers: ['В случае несущего каната с натяжным устройством'],
      },
      {
        code: '63706054',
        text:
          'Сколько должна составлять длина зоны посадки пешеходов и лыжников у КД?',
        answers: [
          'От 1,5 до 2,5 м',
          'От 2,5 до 3,5 м',
          'От 3,5 до 4,0 м',
          'От 2,5 до 5,0 м',
        ],
        correctAnswers: ['От 2,5 до 3,5 м'],
      },
      {
        code: '63706055',
        text:
          'Что не требуется при работе комиссии, принимающей решение о возможности ввода в эксплуатацию ББКД?',
        answers: [
          'Присутствие представителя владельца ОПО',
          'Присутствие представителя органов исполнительной власти в области промышленной безопасности',
          'Наличие положительного заключения экспертизы промышленной безопасности КД',
        ],
        correctAnswers: [
          'Присутствие представителя органов исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63706056',
        text:
          'Какая установлена максимальная скорость ускорения при разгоне и торможении на КД на участках прицепления и отцепления?',
        answers: ['1,5 м/с²', '2,0 м/с²', '2,5 м/с²', '3,0 м/с²', '3,5 м/с²'],
        correctAnswers: ['1,5 м/с²'],
      },
      {
        code: '63706057',
        text:
          'Какие из перечисленных специальных требований для фиксированных зажимов КД указаны неверно?',
        answers: [
          'Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 30 % при выходе из строя отдельных тарельчатых пружин',
          'Зажимы должны охватывать канат настолько, чтобы расстояние между обоими концами зажимных губок не превышало 0,75d',
          'Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 15% при выходе из строя отдельных тарельчатых пружин',
          'При отклонениях номинального диаметра каната от -10% до +6% величина закрытия зажимных губок до их соприкосновения должна составлять не менее 1 мм',
        ],
        correctAnswers: [
          'Зажимное усилие губок, создаваемое тарельчатыми пружинами, не должно снижаться более чем на 30 % при выходе из строя отдельных тарельчатых пружин',
        ],
      },
      {
        code: '63706058',
        text:
          'Сращивание каких канатов не допускается? Укажите все правильные ответы.',
        answers: [
          'Натяжных канатов',
          'Несуще-тяговых канатов',
          'Несущего каната',
          'Тяговых канатов',
          'Спасательного каната',
        ],
        correctAnswers: ['Натяжных канатов', 'Несущего каната'],
      },
      {
        code: '63706059',
        text: 'В каком случае допускается эксплуатация канатной дороги?',
        answers: [
          'В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию',
          'В случае отсутствия ответственных лиц и (или) персонала, прошедших соответственно аттестацию в области промышленной безопасности и проверку знаний, необходимых для эксплуатации КД, ее осмотра и технического обслуживания',
          'Если истек срок ежегодного или полного технического освидетельствования',
          'Если истек назначенный срок эксплуатации',
        ],
        correctAnswers: [
          'В случае отсутствия на рабочем месте ответственного за исправное состояние и безопасную эксплуатацию',
        ],
      },
      {
        code: '63706060',
        text:
          'Какое из перечисленных требований к зонам посадки и высадки БКД и ББКД указано верно?',
        answers: [
          'Расстояние от тягового каната БКД со штанговыми буксировочными устройствами до поверхности снежного покрова может быть меньше длины собранного буксировочного устройства в соответствии с технической документацией',
          'В зоне высадки БКД с буксировочными устройствами барабанного типа барабаны должны проходить на высоте не менее 2,5 м от поверхности снежного покрова',
          'На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м',
          'После высадки пассажира вытяжной канат может перехлестываться с конструкциями дороги и буксировочными устройствами на стороне спуска',
        ],
        correctAnswers: [
          'На БКД с буксировочными устройствами барабанного типа и самообслуживанием расстояние между опорной тарелкой или траверсой и поверхностью снежного покрова в зоне посадки должно составлять 0,6 - 1,0 м',
        ],
      },
      {
        code: '63706061',
        text:
          'Какая максимальная скорость движения кольцевых канатных дорог с отцепляемым подвижным составом на трассе для открытых кабин и кресел?',
        answers: ['9 м/с', '7 м/с', '6 м/с', '5 м/с'],
        correctAnswers: ['6 м/с'],
      },
      {
        code: '63706062',
        text:
          'Какое максимальное расстояние необходимо выдерживать до земли в зоне стабилизации и в зоне безопасности?',
        answers: [
          'В зоне стабилизации - не более 1 м, в зоне безопасности - не более 2,5 м',
          'В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м',
          'В зоне стабилизации - не более 2 м, в зоне безопасности - не более 3,5 м',
          'В зоне стабилизации - не более 2,5 м, в зоне безопасности - не более 4 м',
        ],
        correctAnswers: [
          'В зоне стабилизации - не более 1,5 м, в зоне безопасности - не более 3 м',
        ],
      },
      {
        code: '63706063',
        text:
          'Чему должно быть равно расстояние между двумя следующими друг за другом буксировочными устройствами? Выберите 2 варианта ответа.',
        answers: [
          'Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства',
          'На 30% больше, чем длина полностью вытянутой буксировочной штанги',
          'Не менее чем на 5% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства',
          'На 20% больше, чем длина полностью вытянутой буксировочной штанги',
        ],
        correctAnswers: [
          'Не менее чем на 10% больше, чем длина полностью вытянутого буксировочного каната барабанного устройства',
          'На 30% больше, чем длина полностью вытянутой буксировочной штанги',
        ],
      },
      {
        code: '63706064',
        text:
          'Какой минимальной величине должна соответствовать длина счаленного участка тяговых и несуще-тяговых канатов относительно диаметра каната dk?',
        answers: ['1000 dk', '1200 dk', '1300 dk', '3000 dk'],
        correctAnswers: ['1200 dk'],
      },
      {
        code: '63706065',
        text:
          'Какое допускается значение замедления на приводном шкиве при остановке ППКД в нормальном (рабочем) режиме?',
        answers: [
          'Не более 1,0 м/с﻿2',
          'Не более 2,0 м/с﻿2',
          'Не более 1,5 м/с﻿2',
          'Не более 1,8 м/с﻿2',
        ],
        correctAnswers: ['Не более 1,0 м/с﻿2'],
      },
      {
        code: '63706066',
        text:
          'Чем не должны оснащаться вагоны с сопровождающим персоналом наземных канатных дорог? Укажите все правильные ответы.',
        answers: [
          'Правилами перевозки пассажиров',
          'Указаниями о допустимом количестве пассажиров',
          'Указаниями о полезной нагрузке в килограммах',
          'Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе',
          'Пунктом управления',
        ],
        correctAnswers: [
          'Правилами перевозки пассажиров',
          'Устройствами, автоматически останавливающими КД при столкновении с препятствиями на трассе',
        ],
      },
      {
        code: '63706067',
        text:
          'С какой периодичностью должна проводиться учебная эвакуация в месте КД, где эвакуация пассажиров наиболее затруднена?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в полтора года',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63706068',
        text:
          'Какие требования предъявляются к опорным элементам несущих канатов ППКД?',
        answers: [
          'Опорные элементы должны быть регулируемыми',
          'Опорные элементы не должны препятствовать продольному раскачиванию подвижного состава',
          'Опорные элементы не должны препятствовать поперечному раскачиванию подвижного состава',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63706069',
        text:
          'Какая установлена максимальная длина безопорных буксировочных канатных дорог в плане?',
        answers: ['200 м', '300 м', '400 м', '500 м'],
        correctAnswers: ['400 м'],
      },
      {
        code: '63706070',
        text:
          'Какое из перечисленных требований в зоне посадки лыжников с посадочным конвейером на кресельных канатных дорогах с неотцепляемым подвижным составом указано верно?',
        answers: [
          'Скорость конвейера должна зависеть от скорости несуще-тягового каната и не должна превышать 2 м/с',
          'Необходимо установить длину посадочного конвейера и устройства регулировки входов и их взаимодействие так, чтобы посадка заканчивалась не дальше, чем за 1,5 м до конца ленты конвейера',
          'Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру',
          'При неисправности автоматического управления входами использование посадочного конвейера допускается лицом, ответственным за безопасное производство работ',
        ],
        correctAnswers: [
          'Вход лыжников на посадку должен быть расположен по направлению к посадочному конвейеру',
        ],
      },
      {
        code: '63706071',
        text:
          'На каком минимальном расстоянии над поверхностью снежного покрова по всей длине трассы должна обеспечить прохождение опорных тарелок и траверс высота прохождения тягового каната у БКД с барабанными буксировочными устройствами?',
        answers: ['2,3 м', '2,1 м', '1,8 м', '2,2 м'],
        correctAnswers: ['2,3 м'],
      },
      {
        code: '63706072',
        text:
          'В каком случае оператор должен присутствовать у пульта управления КД?',
        answers: [
          'Оператор должен постоянно присутствовать у пульта управления КД',
          'Оператор должен присутствовать у пульта управления только во время запуска КД',
          'Оператор должен присутствовать у пульта управления только во время проведения спасательной операции',
          'Оператор должен присутствовать у пульта управления только когда на дороге находятся пассажиры',
        ],
        correctAnswers: [
          'Оператор должен постоянно присутствовать у пульта управления КД',
        ],
      },
      {
        code: '63706073',
        text:
          'Кем принимается решение и вносится запись в паспорт КД о вводе ее в эксплуатацию?',
        answers: [
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
          'Представителем территориального органа Ростехнадзора',
          'Ответственным за исправное состояние и безопасную эксплуатацию КД',
          'Оператором КД',
        ],
        correctAnswers: [
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
        ],
      },
      {
        code: '63706074',
        text:
          'Каким минимальным количеством двигателей с независимыми источниками питания - основным и аварийным - оборудуются пассажирские подвесные дороги и наземные канатные дороги?',
        answers: ['Одним', 'Двумя', 'Тремя', 'Четырьмя'],
        correctAnswers: ['Двумя'],
      },
      {
        code: '63706075',
        text:
          'Какое устройство на наземных канатных дорогах (далее - НКД) и маятниковых ППКД должно срабатывать при переезде регулировочной точки?',
        answers: [
          'Устройство контроля превышения скорости',
          'Рабочий тормоз',
          'Аварийный привод',
          'Аварийный тормоз',
        ],
        correctAnswers: ['Аварийный тормоз'],
      },
      {
        code: '63706076',
        text:
          'Какое количество полных витков должно быть предусмотрено для крепления концов несущих и натяжных канатов на тумбе?',
        answers: [
          'Не менее двух',
          'Не менее трех',
          'Не менее четырех',
          'Не менее пяти',
        ],
        correctAnswers: ['Не менее трех'],
      },
      {
        code: '63706077',
        text:
          'В каком месте должны быть расположены выключатели для проведения профилактических работ на пассажирских подвесных канатных дорогах и наземных канатных дорогах?',
        answers: [
          'Только в машинном помещении',
          'Только на профилактических площадках и платформах вблизи от подвижных механических устройств на всех станциях и промежуточных остановках',
          'Только на пультах управления подвижного состава',
          'Только на посту управления',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63706078',
        text:
          'Что из перечисленного необходимо контролировать обслуживающему КД персоналу? Выберите 2 варианта ответа.',
        answers: [
          'Минимально допустимое расстояние между единицами подвижного состава на трассе',
          'Достаточное безопасное расстояние между единицами подвижного состава на станциях',
          'Состояние путей',
          'Расписание движения составов',
        ],
        correctAnswers: [
          'Минимально допустимое расстояние между единицами подвижного состава на трассе',
          'Достаточное безопасное расстояние между единицами подвижного состава на станциях',
        ],
      },
      {
        code: '63706079',
        text:
          'В каком случае тормоза должны приводиться в действие автоматически на пассажирских подвесных канатных дорогах и наземных канатных дорогах?',
        answers: [
          'При прекращении подачи электроэнергии к главному двигателю',
          'При падении давления в гидравлических или пневматических тормозах ниже допустимого значения',
          'При размыкании в цепи безопасности',
          'Во всех перечисленных случаях',
          'Все ответы неверны',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63706080',
        text:
          'Какие из перечисленных значений соответствуют тяговому канату диаметром свыше 16 мм на буксировочных канатных дорогах?',
        answers: [
          "Минимальный диаметр роликов составляет 150 мм, а перегиб каната на одном ролике 9°30'",
          "Минимальный диаметр роликов составляет 200 мм, а перегиб каната на одном ролике 8°30'",
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике 5°30'",
          "Минимальный диаметр роликов составляет 300 мм, а перегиб каната на одном ролике 3°30'",
        ],
        correctAnswers: [
          "Минимальный диаметр роликов составляет 250 мм, а перегиб каната на одном ролике 5°30'",
        ],
      },
      {
        code: '63706081',
        text:
          'Каким должно быть минимальное расстояние между двумя соседними счалками тяговых и несуще-тяговых канатов относительно диаметра каната dk?',
        answers: ['1200 dk', '1300 dk', '3000 dk', '5000 dk'],
        correctAnswers: ['3000 dk'],
      },
      {
        code: '63706082',
        text:
          'Как должны оборудоваться проходы и площадки для пассажиров, которые находятся выше уровня земли?',
        answers: [
          'Их необходимо выделить (обозначить)',
          'Их ограждают перилами, минимальная высота которых должна быть на 2 м выше снежного покрова',
          'Их ограждают маркировочными сетками',
          'Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м',
        ],
        correctAnswers: [
          'Их ограждают перилами высотой не менее 1 м и сплошным ограждением по низу на высоту не менее 0,1 м',
        ],
      },
      {
        code: '63706083',
        text:
          'На каких канатных дорогах не требуется установка системы информирования пассажиров?',
        answers: [
          'На фуникулерах',
          'На канатных дорогах с кольцевым движением',
          'На маятниковых канатных дорогах',
          'На буксировочных канатных дорогах',
        ],
        correctAnswers: ['На буксировочных канатных дорогах'],
      },
      {
        code: '63706084',
        text:
          'Какое допускается максимальное значение угла поперечного раскачивания для груженых открытых кабин и кресел с учетом диапазона досягаемости рукой, ногой пассажира и лыжами?',
        answers: ["11°30'", "12°30'", "11°50'", "13°30'"],
        correctAnswers: ["11°30'"],
      },
      {
        code: '63706085',
        text:
          'Какое количество пунктов управления должна иметь канатная дорога, с которых должны осуществляться ее управление и остановка, а также все виды контроля?',
        answers: [
          'Не менее одного пункта управления',
          'Не менее двух пунктов управления',
          'Не менее трех пунктов управления',
          'Не менее четырех пунктов управления',
        ],
        correctAnswers: ['Не менее одного пункта управления'],
      },
      {
        code: '63706086',
        text:
          'Какой угол отклонения в плане на опоре допускается для несуще-тяговых канатов пассажирских подвесных канатных дорог (далее - ППКД)?',
        answers: ['17’', '30’', '11о 30’', '19о 30’'],
        correctAnswers: ['30’'],
      },
      {
        code: '63706087',
        text:
          'Какое из перечисленных условий для обеспечения надежности прилегания каната к балансиру на пассажирских подвесных канатных дорогах является неверным?',
        answers: [
          'При превышении натяжения несуще-тягового каната на 40% нагрузка на нижний балансир должна оставаться положительной',
          'Нагрузка от несуще-тягового каната на балансир при наиболее неблагоприятных условиях нормальной работы ППКД должна быть не менее 2000 Н, а на опорный ролик - не менее 500 Н',
          "Для тяговых и несуще-тяговых канатов ППКД перегиб каната на одном ролике балансира должен быть не более 4°30'",
          'Допускается уменьшение значений величин нагрузки в три раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Допускается уменьшение значений величин нагрузки в три раза при наличии в балансире прижимных роликов, удерживающих канат от выпадения из роликов',
        ],
      },
      {
        code: '63706088',
        text:
          'Какую величину составляет максимальное значение угла поперечного раскачивания для кабин с проводником и кабин, оснащенных дистанционным управлением, с помощью которого можно остановить дорогу или регулировать скорость движения, при скорости меньше или равной 7 м/с?',
        answers: ["6°50'", "8°35'", "11°30'", "14°20'"],
        correctAnswers: ["6°50'"],
      },
      {
        code: '63706089',
        text:
          'Кто должен возобновлять движение дороги после ее остановки вследствие срабатывания устройства безопасности?',
        answers: [
          'Оператор с пульта управления',
          'Дежурный по станции или проводник в вагоне',
          'Ответственный за безопасную эксплуатацию КД',
          'Руководитель предприятия-владельца КД',
        ],
        correctAnswers: ['Оператор с пульта управления'],
      },
      {
        code: '63706090',
        text: 'В каком случае проводятся статические испытания?',
        answers: [
          'При проведении ежегодного технического освидетельствования',
          'При проведении ежемесячной ревизии',
          'При проведении еженедельной проверки',
          'При проведении полного технического освидетельствования',
        ],
        correctAnswers: [
          'При проведении полного технического освидетельствования',
        ],
      },
      {
        code: '63706091',
        text:
          'В каком случае допускается не устанавливать вертикальные ролики и шкивы перед отклонением тягового каната и после него на буксировочных канатных дорогах?',
        answers: [
          'Если это обосновано проектной документацией',
          'Для тяговых канатов диаметром до 16 мм',
          'У самоустанавливающихся роликов и шкивов',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['У самоустанавливающихся роликов и шкивов'],
      },
      {
        code: '63706092',
        text:
          'В каком случае в плане мероприятий по эвакуации пассажиров может не предусматриваться спасение людей методом эвакуации с подвижного состава?',
        answers: [
          'Если расстояние от низа подвижного состава до земли не превышает 3 м',
          'Если в кабине или вагоне находится сопровождающий',
          'Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий',
          'Если в кабине или вагоне имеются эвакуационные парашюты',
        ],
        correctAnswers: [
          'Если на КД предусмотрена доставка пассажиров на конечные станции при любом виде отказов или аварий',
        ],
      },
      {
        code: '63706093',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами ББКД?',
        answers: ['3 секунды', '4 секунды', '5 секунд', '6 секунд'],
        correctAnswers: ['5 секунд'],
      },
      {
        code: '63706094',
        text:
          'Какое из перечисленных требований к двигателям, редукторам, трансмиссиям, применяемым на канатных дорогах, указано неверно?',
        answers: [
          'Двигатели внутреннего сгорания должны быть жестко установлены на раме и должны иметь электростартерную систему пуска с питанием от аккумуляторной батареи',
          'В помещении станции, где установлен двигатель внутреннего сгорания, должен быть обеспечен приток воздуха',
          'В трансмиссии для передачи усилий должны использоваться плоские ремни',
          'Конечные положения переключающей (сцепной) муфты должны блокироваться механически и контролироваться системой управления КД',
        ],
        correctAnswers: [
          'В трансмиссии для передачи усилий должны использоваться плоские ремни',
        ],
      },
      {
        code: '63706095',
        text:
          'Какой должен быть минимальный коэффициент запаса надежности сцепления каждого клеммного зажима несущего каната?',
        answers: ['2', '3', '4', '5'],
        correctAnswers: ['3'],
      },
      {
        code: '63706096',
        text:
          'Какая устанавливается минимальная скорость пассажирских подвесных канатных дорог и фуникулеров при плавном регулировании скорости в пределах ее паспортных величин?',
        answers: ['0,5 м/с', '0,75 м/с', '1,0 м/с', '1,5 м/с'],
        correctAnswers: ['0,5 м/с'],
      },
      {
        code: '63706097',
        text:
          'Какие требования к расстоянию от защитного элемента до реборды ролика на трассе БКД указаны верно? Выберите 2  варианта ответа.',
        answers: [
          'Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната',
          'Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм',
          'Расстояние от защитного элемента до реборды ролика не должно превышать 1/2 диаметра каната',
          'Расстояние от защитного элемента до реборды ролика должно составлять не более 10 мм',
        ],
        correctAnswers: [
          'Расстояние от защитного элемента до реборды ролика не должно превышать 1/4 диаметра каната',
          'Расстояние от защитного элемента до реборды ролика должно составлять не более 8 мм',
        ],
      },
      {
        code: '63706098',
        text:
          'Каким должен быть коэффициент запаса надежности сцепления для винтовых зажимов КД?',
        answers: ['3,0', '2,5', '1,5', '1,75'],
        correctAnswers: ['3,0'],
      },
      {
        code: '63706099',
        text:
          'Чему равна нагрузка на пол кабины для перевозки пассажиров стоя?',
        answers: ['4500 Н/м²', '4600 Н/м²', '5500 Н/м²', '4700 Н/м²'],
        correctAnswers: ['4500 Н/м²'],
      },
      {
        code: '63706100',
        text:
          'Какое значение имеет синяя цветовая индикация индикаторных устройств, за исключением определенных случаев?',
        answers: [
          'Вынужденное отключение функций безопасности',
          'Аварийный случай',
          'Отклонение от нормы',
          'Норма, безопасно',
          'Нейтрально',
        ],
        correctAnswers: ['Вынужденное отключение функций безопасности'],
      },
      {
        code: '63706101',
        text:
          'Какие действия из перечисленных включает в себя ревизия при эксплуатации КД? Укажите все правильные ответы.',
        answers: [
          'Чистку, консервацию, смазку деталей',
          'Замену деталей',
          'Измерение фактического состояния деталей, узлов, конструкций КД',
          'Контроль и определение фактического состояния в целом КД',
          'Регулировку',
        ],
        correctAnswers: [
          'Измерение фактического состояния деталей, узлов, конструкций КД',
          'Контроль и определение фактического состояния в целом КД',
        ],
      },
      {
        code: '63706102',
        text:
          'Какое из перечисленных требований к посадочному конвейеру является верным?',
        answers: [
          'Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с²',
          'Ширина посадочного конвейера должна быть меньше ширины подвижного состава',
          'При движении канатной дороги в обратном направлении посадочный конвейер должен двигаться с замедлением не более 0,8 м/с²',
          'Количество зон посадочного конвейера, обозначенных маркировкой, не должно соответствовать количеству посадочных мест единицы подвижного состава КД',
        ],
        correctAnswers: [
          'Остановка посадочного конвейера осуществляется с замедлением не более 0,5 м/с²',
        ],
      },
      {
        code: '63706103',
        text:
          'С какой периодичностью осуществляют перестановку зажимов КД с неотцепляемым подвижным составом?',
        answers: [
          'Каждые 100 моточасов функционирования КД',
          'Каждые 200 моточасов функционирования КД',
          'Каждые 300 моточасов функционирования КД',
          'Каждые 500 моточасов функционирования КД',
        ],
        correctAnswers: ['Каждые 200 моточасов функционирования КД'],
      },
      {
        code: '63706104',
        text:
          'Какой стаж работы специалистом на канатной дороге должен иметь ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД?',
        answers: [
          'Не менее одного года',
          'Не менее трех лет',
          'Не менее пяти лет',
          'Не менее шести лет',
        ],
        correctAnswers: ['Не менее трех лет'],
      },
      {
        code: '63706105',
        text: 'Какой знак не устанавливается в зоне высадки БКД?',
        answers: [
          'Не выходить за пределы буксировочной дорожки',
          'Предварительное оповещение о месте высадки',
          'Отпустить буксировочное устройство',
          'Не задерживаться в месте высадки',
        ],
        correctAnswers: ['Не выходить за пределы буксировочной дорожки'],
      },
      {
        code: '63706106',
        text: 'В каком случае должна быть проведена ежемесячная ревизия КД?',
        answers: [
          'Если КД не эксплуатировали более недели',
          'Если КД не эксплуатировали более одного месяца',
          'Если КД не эксплуатировали более полугода',
          'Если КД не эксплуатировали более года',
        ],
        correctAnswers: ['Если КД не эксплуатировали более одного месяца'],
      },
      {
        code: '63706107',
        text:
          'Чему должно быть равно отношение между минимальным натяжением несуще-тягового или тягового каната ППКД и нагрузкой на ролик балансира?',
        answers: ['Не менее 15', 'Не менее 5', 'Не менее 10', 'Не менее 8'],
        correctAnswers: ['Не менее 15'],
      },
      {
        code: '63706108',
        text:
          'Какое устанавливается максимальное значение угла поперечного раскачивания буксировочных устройств на буксировочных канатных дорогах?',
        answers: ["6°50'", "8°35'", "11°30'", "19°30'"],
        correctAnswers: ["11°30'"],
      },
      {
        code: '63706109',
        text:
          'В каком месте допускается не располагать кнопки аварийной остановки в непосредственной близости от персонала на пассажирских подвесных канатных дорогах и наземных канатных дорогах?',
        answers: [
          'На пультах управления подвижного состава',
          'На промежуточных остановках',
          'На станциях',
          'В машинном помещении',
        ],
        correctAnswers: ['В машинном помещении'],
      },
      {
        code: '63706110',
        text:
          'Какая скорость устанавливается при обратном ходе пассажирских подвесных канатных дорог и наземных канатных дорог?',
        answers: [
          'Не более 0,5 м/с',
          'Не более 1,0 м/с',
          'Не более 1,5 м/с',
          'Не более 2,5 м/с',
        ],
        correctAnswers: ['Не более 1,5 м/с'],
      },
      {
        code: '63706111',
        text:
          'При каком уклоне проходов для пассажиров и персонала, используемых пешеходами, они должны быть оборудованы лестницами?',
        answers: [
          'Более 3°',
          'Более 4°',
          'Более 5°',
          'Проходы для пассажиров и персонала, используемые пешеходами, должны быть оборудованы лестницами при любом уклоне',
        ],
        correctAnswers: ['Более 5°'],
      },
      {
        code: '63706112',
        text:
          'Чем должны быть оборудованы подъездные пути для доступа пассажиров в креслах-каталках?',
        answers: [
          'Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок',
          'Пандусом с уклоном до 10° с горизонтальными площадками длиной 2 м через каждые 10 м',
          'Порогами высотой не более 0,2 м',
          'Проходами в контрольных зонах для предъявления проездных документов и входами для КД шириной не менее 1,9 м',
        ],
        correctAnswers: [
          'Полом с горизонтальной поверхностью или поверхностью с уклоном не более 3°, исключающим скольжение кресел-каталок',
        ],
      },
      {
        code: '63706113',
        text:
          'При каком увеличении скорости относительно номинальной должно срабатывать устройство контроля превышения скорости при работе на аварийном приводе на пассажирских подвесных канатных дорогах и фуникулерах?',
        answers: ['На 5%', 'На 10%', 'На 15%', 'На 20%'],
        correctAnswers: ['На 20%'],
      },
      {
        code: '63706114',
        text:
          'Какие упоры должен иметь узел соединения буксировочного устройства с тяговым канатом?',
        answers: [
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали',
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 70° от вертикали',
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 50° от вертикали',
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 60° от вертикали',
        ],
        correctAnswers: [
          'Упоры, ограничивающие продольное качание жесткой части устройства на угол +/- 80° от вертикали',
        ],
      },
      {
        code: '63706115',
        text:
          'Кто должен разработать план мероприятий по эвакуации пассажиров для КД и обеспечить его исполнение?',
        answers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
          'Ответственный за соблюдение требований промышленной безопасности на ОПО',
          'Комиссия эксплуатирующей организации',
          'Оператор канатной дороги',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию канатной дороги',
        ],
      },
      {
        code: '63706116',
        text:
          'Кто принимает решение о возобновлении дальнейшей эксплуатации или введении в действие плана мероприятий по эвакуации в результате рассмотрения причин остановки КД?',
        answers: [
          'Руководитель организации, эксплуатирующей КД',
          'Специализированная организация, выполняющая ремонт и обслуживание КД',
          'Ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД',
          'Ответственный за исправное состояние и безопасную эксплуатацию КД',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию КД',
        ],
      },
      {
        code: '63706117',
        text:
          'Какое из перечисленных требований к органам управления КД указано верно?',
        answers: [
          'Орган управления аварийной остановкой должен возвращаться в исходное положение персоналом, приводя к запуску КД',
          'Переключение из одного режима движения в другой и от одного вида управления к другому должно производиться кратковременно на запущенной КД',
          'Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив',
          'Одновременно может быть задействовано лишь 2 вида управления',
        ],
        correctAnswers: [
          'Орган управления аварийной остановкой должен останавливать КД путем наложения тормоза на приводной шкив',
        ],
      },
      {
        code: '63706118',
        text:
          'Какую ширину дверного проема должны иметь кабины, предназначенные для перевозки пассажиров в креслах-каталках?',
        answers: [
          'Не менее 0,8 м',
          'Не менее 0,7 м',
          'Не менее 0,6 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63706119',
        text:
          'На какие типы дорог распространяются требования к организации и проведению эвакуационных мероприятий?',
        answers: [
          'На маятниковые дороги и фуникулеры',
          'На все типы канатных дорог',
          'На БКД и ББКД',
          'На ППКД и НКД',
        ],
        correctAnswers: ['На ППКД и НКД'],
      },
      {
        code: '63706120',
        text:
          'Какое минимальное расстояние от низшей точки подвижного состава ППКД до земли (с учетом снежного покрова) допускается в зонах, где исключено присутствие людей?',
        answers: ['3 м', '2 м', '1,5 м', '1 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63706121',
        text:
          'Какие требования к размерам и размещению окон в кабине кольцевых КД указаны верно? Выберите 2 варианта ответа.',
        answers: [
          'Открывающиеся окна в кабине должны быть расположены на высоте не менее 1,1 м от пола',
          'Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,2 м',
          'Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,3 м',
          'Окна в кабине должны быть открывающимися и расположены на высоте не менее 0,8 м от пола',
        ],
        correctAnswers: [
          'Открывающиеся окна в кабине должны быть расположены на высоте не менее 1,1 м от пола',
          'Открывающиеся окна в кабине должны иметь конструкцию и размеры, исключающие прохождение через них шара диаметром 0,2 м',
        ],
      },
      {
        code: '63706122',
        text:
          'В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?',
        answers: [
          'На участке длиной, равной 6 dk, имеются разрывы двух и более смежных проволок наружного слоя',
          'Оборванные концы наружных проволок каната выступают',
          'На участке длиной, равной 30 dк, одна десятая часть проволок наружного слоя замка оборвана',
          'Имеется выход из замка одной и более проволок наружного слоя без ее обрыва',
        ],
        correctAnswers: [
          'На участке длиной, равной 30 dк, одна десятая часть проволок наружного слоя замка оборвана',
        ],
      },
      {
        code: '63706123',
        text:
          'Какая длина зажимных губок для фиксированных зажимов подвесных канатных дорог?',
        answers: ['Не менее 2d', 'Не менее 3d', 'Не более 3d', 'Не менее 4d'],
        correctAnswers: ['Не менее 2d'],
      },
      {
        code: '63706124',
        text:
          'На какие дороги действие Правил безопасности пассажирских канатных дорог и фуникулеров не распространяется?',
        answers: [
          'На безопорные буксировочные канатные дороги',
          'На грузовые подвесные канатные дороги',
          'На канатные дороги, предназначенные для перемещения пассажиров в вагонах по наклонному рельсовому пути тяговым канатом',
          'На комбинированные канатные дороги, совмещающие свойства подвесных и буксировочных',
        ],
        correctAnswers: ['На грузовые подвесные канатные дороги'],
      },
      {
        code: '63706125',
        text:
          'Какое действие должно быть осуществлено первым при возникновении аварии или инцидента на КД?',
        answers: [
          'Дорога должна быть немедленно остановлена',
          'Должен быть проинформирован ответственный за исправное состояние и безопасную эксплуатацию КД',
          'Должны быть проинформированы пассажиры, находящиеся на КД',
          'Должен быть введен в действие план спасательной операции',
        ],
        correctAnswers: ['Дорога должна быть немедленно остановлена'],
      },
      {
        code: '63706126',
        text:
          'Кто утверждает правила перевозки пассажиров и багажа канатной дорогой?',
        answers: [
          'Организация, имеющая лицензию на проведение экспертизы промышленной безопасности',
          'Эксплуатирующая организация',
          'Проектная организация',
          'Ростехнадзор',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63706127',
        text:
          'Какую добавочную величину провеса несущих канатов следует учитывать при равномерном движении груженого подвижного состава?',
        answers: ['0,05', '0,1', '0,2', '0,3'],
        correctAnswers: ['0,1'],
      },
      {
        code: '63706128',
        text:
          "В каком случае максимальное значение углов поперечного раскачивания подвижного состава для закрытых кабин и открытого подвижного состава без пассажиров, равное 19°30', может быть уменьшено?",
        answers: [
          'В случае установления данной возможности в технической документации',
          'В случае установки на оборудовании направляющих без проводника',
          'В случае учета ветровых нагрузок',
          'Ни в каком случае',
        ],
        correctAnswers: [
          'В случае установки на оборудовании направляющих без проводника',
        ],
      },
      {
        code: '63706129',
        text: 'С чем допускаются пересечения БКД на одном уровне?',
        answers: [
          'Пересечения БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускаются',
          'Пересечения БКД на одном уровне не допускаются только с трассами для катания',
          'Пересечения БКД на одном уровне не допускаются только с путями передвижения людей',
          'Пересечения БКД на одном уровне не допускаются только с путями передвижения транспорта',
        ],
        correctAnswers: [
          'Пересечения БКД с трассами для катания и путями передвижения людей и транспорта на одном уровне не допускаются',
        ],
      },
      {
        code: '63706130',
        text:
          'Куда заносятся сведения о результатах и сроке следующего технического освидетельствования?',
        answers: [
          'В вахтенный журнал',
          'В журнал ремонта и осмотров',
          'В паспорт дороги',
          'В приказ о вводе канатной дороги в эксплуатацию',
        ],
        correctAnswers: ['В паспорт дороги'],
      },
      {
        code: '63706131',
        text:
          'Какие из перечисленных обязанностей возлагаются на лицо, ответственное за исправное состояние и безопасную эксплуатацию КД?',
        answers: [
          'Только обеспечение выполнения персоналом своих обязанностей согласно инструкции',
          'Только обеспечение обслуживающего персонала нормативно-технической документацией',
          'Только проведение тренировок вспомогательного персонала, привлекаемого при проведении эвакуации и борьбы с пожаром',
          'Все перечисленные обязанности',
        ],
        correctAnswers: ['Все перечисленные обязанности'],
      },
      {
        code: '63706132',
        text: 'В каком случае проводится дефектоскопия каната?',
        answers: [
          'При остаточном удлинении каната более чем на 0,5% рабочей длины после первоначальной вытяжки в начальный период эксплуатации и выполненной пересчалки',
          'При обнаружении в канате одной или нескольких оборванных прядей',
          'При уменьшении диаметра каната двойной свивки в результате поверхностного износа или коррозии на 7% и более по сравнению с номинальным диаметром',
          'После любого внешнего воздействия (удар молнии, сход, деформирование каната) перед началом эксплуатации',
        ],
        correctAnswers: [
          'После любого внешнего воздействия (удар молнии, сход, деформирование каната) перед началом эксплуатации',
        ],
      },
      {
        code: '63706133',
        text:
          'Какая должна быть минимальная длина счалки при сращивании тяговых и несуще-тяговых канатов, где dk - номинальный диаметр каната?',
        answers: ['1000 dk', '1200 dk', '1300 dk', '3000 dk'],
        correctAnswers: ['1200 dk'],
      },
      {
        code: '63706134',
        text:
          'Каким должно быть расстояние по вертикали от середины передней кромки нагруженного кресла до земли с учетом снежного покрова в зоне посадки-высадки у кресельных канатных дорог?',
        answers: ['37 +/- 5 см', '40 +/- 5 см', '46 +/- 5 см', '50 +/- 5 см'],
        correctAnswers: ['50 +/- 5 см'],
      },
      {
        code: '63706135',
        text:
          'При каком условии КД допускается к работе по перевозке пассажиров?',
        answers: [
          'Только при выполнении проверки и пробного пуска КД',
          'Только при наличии персонала на рабочих местах',
          'Только при выполнении условии безопасной перевозки пассажиров на КД',
          'Только при всех перечисленных условиях',
        ],
        correctAnswers: ['Только при всех перечисленных условиях'],
      },
      {
        code: '63706136',
        text:
          'Какое условие должно быть выполнено при эксплуатации БКД в темное время суток?',
        answers: [
          'Лыжная дорожка БКД должна быть освещена на всем протяжении',
          'Станции посадки и высадки пассажиров должны быть обеспечены естественным освещением, а в случае его отсутствия - искусственным освещением',
          'Должны быть освещены фундаменты станций и опор',
          'Должны быть освещены части опор, мимо которых проходит подвижной состав',
        ],
        correctAnswers: [
          'Лыжная дорожка БКД должна быть освещена на всем протяжении',
        ],
      },
      {
        code: '63706137',
        text:
          'На каком расстоянии от подвижного состава должны быть размещены неподвижные конструкции устройств регулировки входов (турникеты) у КД?',
        answers: [
          'Не менее 0,6 м',
          'Не менее 0,5 м',
          'Не менее 0,4 м',
          'Не менее 0,3 м',
        ],
        correctAnswers: ['Не менее 0,6 м'],
      },
      {
        code: '63706138',
        text:
          'В какие сроки проводится проверка соединений канатов, крепление концов канатов?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежегодно'],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63706139',
        text:
          'В какие сроки проводится проверка на наличие трещин всех зажимов подвижного состава КД неразрушающими методами контроля?',
        answers: [
          'Ежегодно, во время технического освидетельствования',
          'Во время проведения экспертизы промышленной безопасности перед вводом в эксплуатацию, а затем после окончания установленного срока службы',
          'Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые два года',
          'Через 3 года в первые 15 лет эксплуатации и далее ежегодно',
        ],
        correctAnswers: [
          'Через 10 лет после начала эксплуатации (с учетом предшествующей эксплуатации зажима) и далее через каждые два года',
        ],
      },
      {
        code: '63706140',
        text:
          'При перевозке двух детей, рост которых не превышает 1,25 м, как они должны располагаться на кресельной КД относительно сопровождающего?',
        answers: [
          'Перевозка двух детей с одним сопровождающим запрещена',
          'Дети должны располагаться справа от сопровождающего, при этом между ними не должно быть пустого места',
          'Дети должны располагаться слева от сопровождающего, при этом между ними не должно быть пустого места',
          'Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места',
        ],
        correctAnswers: [
          'Дети должны располагаться по обе стороны от сопровождающего, при этом между ними не должно быть пустого места',
        ],
      },
      {
        code: '63706141',
        text:
          'В каком случае контроль расстояния между единицами подвижного состава на пассажирских подвесных канатных дорогах с кольцевым движением отцепляемого на станциях подвижного состава осуществляет обслуживающий персонал?',
        answers: [
          'При скорости движения подвижного состава не более 5 м/с',
          'При неисправности посадочного конвейера',
          'Контроль расстояния между единицами подвижного состава контролируется только автоматически',
          'В случае отсутствия в зонах станции автоматических устройств контроля',
        ],
        correctAnswers: [
          'В случае отсутствия в зонах станции автоматических устройств контроля',
        ],
      },
      {
        code: '63706142',
        text:
          'С каким ускорением должно осуществляться начало движения БКД вне зависимости от нагрузки?',
        answers: [
          'Не более 0,4 м/с2',
          'Не более 0,5 м/с2',
          'Не более 0,6 м/с2',
          'Не более 0,7 м/с2',
        ],
        correctAnswers: ['Не более 0,4 м/с2'],
      },
      {
        code: '63706143',
        text:
          'В каких случаях за зоной высадки устанавливают устройства контроля, автоматически останавливающие дорогу? Укажите все правильные ответы.',
        answers: [
          'Не сработало предварительное оповещение о месте высадки',
          'Пассажир задержался в месте высадки',
          'Пассажир не успел отпустить буксировочное устройство',
          'Буксировочное устройство не вернулось в исходное состояние',
        ],
        correctAnswers: [
          'Пассажир не успел отпустить буксировочное устройство',
          'Буксировочное устройство не вернулось в исходное состояние',
        ],
      },
      {
        code: '63706144',
        text:
          'В каком случае разрешено использовать навесные или приставные лестницы для обслуживания балансиров вместо устройства стационарных лестниц и площадок у опор буксировочных канатных дорог?',
        answers: [
          'В случае проведения работ в присутствии лица, ответственного за безопасное производство работ',
          'У опор БКД с высотой прохождения тягового каната менее 4 м',
          'У опор БКД с высотой прохождения тягового каната менее 5 м',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'У опор БКД с высотой прохождения тягового каната менее 4 м',
        ],
      },
      {
        code: '63706145',
        text:
          'Какое требование должно быть выполнено при пересечении и сближении высоковольтной линии электропередачи с пассажирской подвесной канатной дорогой?',
        answers: [
          'Расстояние от подвижных частей ППКД до опор высоковольтной линии (ВЛ) не должно быть меньше 5 м',
          'Угол пересечения в плане линии ППКД и линии ВЛ не должен быть меньше 20°',
          'Крепление мостков на опорах ВЛ допускается при согласовании с владельцем ВЛ',
          'Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД',
        ],
        correctAnswers: [
          'Провода ВЛ напряжением свыше 1 кВ должны располагаться над ППКД и иметь заземленные защитные устройства, предотвращающие падение проводов на элементы ППКД',
        ],
      },
      {
        code: '63706146',
        text:
          'Сколько должен составлять минимальный габарит от зон досягаемости рукой до препятствий по горизонтали и вертикали, а также между зонами досягаемости рукой движущихся навстречу вагонов при эксплуатации НКД?',
        answers: ['0,1 м', '0,2 м', '0,3 м', '0,4 м'],
        correctAnswers: ['0,1 м'],
      },
      {
        code: '63706147',
        text:
          'Какое из перечисленных требований к направляющим роликам пассажирских подвесных канатных дорог указано верно? Укажите все правильные ответы.',
        answers: [
          'Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм',
          'Глубина канавки в бандаже ролика должна быть не менее 1/15 от диаметра несуще-тягового каната',
          'Выступание реборды ролика над его бандажом должно быть не менее 2 мм и составлять 1/10 от диаметра несуще-тягового каната',
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
        ],
        correctAnswers: [
          'Общая глубина канавки направляющих роликов ППКД (D1 - D2) / 2 для несуще-тяговых канатов составляет 1/3 диаметра каната, но не менее 10 мм',
          "Реборды роликов для несуще-тяговых канатов одноканатных дорог с кольцевым движением при наличии новых бандажей роликов должны допускать поперечное раскачивание зажимов подвесок не менее 11°30'",
        ],
      },
      {
        code: '63706148',
        text:
          'Какое из перечисленных требований к приводам пассажирских подвесных дорог и наземных канатных дорог указано верно? Укажите все правильные ответы.',
        answers: [
          'Переключение приводов должно происходить при включенной КД',
          'Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку',
          'Одновременная работа аварийного и главного приводов допускается требованиями технической документации',
          'Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации',
          'Вспомогательный привод должен обеспечивать начало движения с ускорением не более 0,3 м/c² при максимальной нагрузке',
        ],
        correctAnswers: [
          'Гидропривод должен плавно приводить в движение ППКД и НКД в обоих направлениях и также их остановку',
          'Вспомогательный привод должен обеспечивать скорость не менее чем 1/2 скорости при нормальных условиях эксплуатации',
        ],
      },
      {
        code: '63706149',
        text:
          'В каком случае работа сигнала готовности на пульте управления при эксплуатации ППКД и НКД неисправна?',
        answers: [
          'Сигнал гаснет самостоятельно, если по истечении 60 секунд не поступило сигнала начала движения',
          'Только если сигнал готовности действует в системе управления при готовности КД к работе',
          'Только если сигнал готовности гаснет самостоятельно при не поступлении по истечении 30 секунд сигнала начала движения',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Сигнал гаснет самостоятельно, если по истечении 60 секунд не поступило сигнала начала движения',
        ],
      },
      {
        code: '63706150',
        text:
          'На сколько должна быть увеличена ширина буксировочной дорожки на мостах и во впадинах?',
        answers: [
          'На 0,5 м',
          'На 1,0 м',
          'На 1,5 м',
          'Ширина буксировочной дорожки на мостах и во впадинах не увеличивается',
        ],
        correctAnswers: ['На 0,5 м'],
      },
      {
        code: '63706151',
        text:
          'Какой диапазон досягаемости рукой пассажира составляет при полностью открывающихся окнах и в открытых вагонах наземных канатных дорог?',
        answers: ['0,2 м', '0,5 м', '1,0 м', '1,2 м'],
        correctAnswers: ['1,0 м'],
      },
      {
        code: '63706152',
        text:
          'Какое из перечисленных требований к наклонному съезду, устраиваемому при перевозке лыжников после зоны высадки, указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Уклон съезда должен составлять от 10% до 15%',
          'Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,5 м',
          'Для КД с креслами с неотцепляемыми зажимами наклонный спуск должен начинаться не менее чем за 1,5 секунду до прохождения креслом оси обводного шкива',
          'Для КД с креслами с отцепляемыми зажимами наклонный спуск должен начинаться за 1,0 секунду до изменения направления движения кресел',
          'Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,8 м',
        ],
        correctAnswers: [
          'Длина наклонного съезда должна быть достаточной для ухода лыжников из зоны возможного контакта с креслом до обеспечения безопасного расстояния с ним и составлять не менее 0,5 м',
          'Для КД с креслами с неотцепляемыми зажимами наклонный спуск должен начинаться не менее чем за 1,5 секунду до прохождения креслом оси обводного шкива',
        ],
      },
      {
        code: '63706153',
        text:
          'Какая цветовая индикация индикаторных устройств, за исключением определенных случаев, имеет значение "нейтрально"? Укажите все правильные ответы.',
        answers: ['Белый', 'Синий', 'Черный', 'Зеленый', 'Желтый'],
        correctAnswers: ['Белый', 'Черный'],
      },
      {
        code: '63706154',
        text:
          'Какие из перечисленных требований к нанесению надписей и информационным знакам на КД указаны неверно?',
        answers: [
          'Опоры КД нумеруются по порядку, начиная с верхней опоры',
          'На элементах КД должны быть нанесены четкие и нестираемые надписи о видах опасности',
          'Информационные знаки следует устанавливать в местах КД, где существует угроза опасности',
          'Опоры КД нумеруются по порядку, начиная с нижней опоры',
        ],
        correctAnswers: [
          'Опоры КД нумеруются по порядку, начиная с верхней опоры',
        ],
      },
      {
        code: '63706155',
        text:
          'В каких вагонах сопровождающий персонал должен находиться у пульта управления на возвышении по отношению к уровню пола вагона маятниковых канатных дорог?',
        answers: [
          'Вместимостью более 30 человек',
          'Вместимостью более 50 человек',
          'Вместимостью более 80 человек',
          'Не регламентируется',
        ],
        correctAnswers: ['Вместимостью более 80 человек'],
      },
      {
        code: '63706156',
        text:
          'В течение какого времени должен приводиться в действие аварийный привод?',
        answers: [
          'В течение 5 минут',
          'В течение 15 минут',
          'В течение 30 минут',
          'В течение 3 часов',
        ],
        correctAnswers: ['В течение 15 минут'],
      },
      {
        code: '63706157',
        text:
          'Какое условие может не выполняться при эксплуатации ППКД и НКД в темное время суток?',
        answers: [
          'Станции посадки и высадки пассажиров должны быть обеспечены эксплуатационным освещением и, в случае его отказа, аварийным освещением',
          'Эксплуатационное и аварийное освещение должно обеспечивать освещение подвижного состава на подходах к станции и в зонах замедления подвижного состава',
          'Подвижной состав ППКД и НКД, кроме кресел, должен иметь внутреннее освещение',
          'Должны быть освещены фундаменты станций и опор',
        ],
        correctAnswers: ['Должны быть освещены фундаменты станций и опор'],
      },
      {
        code: '63706158',
        text:
          'Какое утверждение, относящееся к ходу натяжной тележки КД, указано верно?',
        answers: [
          'Возврат в рабочее положение устройства безопасности должен производиться автоматически',
          'При срабатывании устройства безопасности должно происходить замедление КД',
          'При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации',
          'Натяжные устройства при любых условиях нормальной эксплуатации КД должны достигать упоров',
        ],
        correctAnswers: [
          'При заякоривании несущего каната с обеих сторон натяжение каната в зоне анкерных устройств необходимо контролировать с периодичностью, предусмотренной руководством по эксплуатации',
        ],
      },
      {
        code: '63706159',
        text:
          'Какая максимально допустимая разность скорости зажима и движущегося каната при прицеплении зажима к канату на канатных дорогах с отцепляемыми зажимами?',
        answers: ['0,2 м/с', '0,3 м/с', '0,4 м/с', '0,5 м/с'],
        correctAnswers: ['0,3 м/с'],
      },
      {
        code: '63706160',
        text:
          'В течение какого срока ответственный за исправное состояние и безопасную эксплуатацию КД должен проходить стажировку на действующей КД того же типа при отсутствии опыта работы на КД?',
        answers: [
          'В течение срока не менее 3 месяцев',
          'В течение срока не менее 6 месяцев',
          'В течение срока не менее 9 месяцев',
          'В течение срока не 12 месяцев',
        ],
        correctAnswers: ['В течение срока не менее 3 месяцев'],
      },
      {
        code: '63706161',
        text:
          'Какое значение не должно превышать максимальное расстояние от низшей точки подвижного состава до поверхности земли или воды для ППКД с закрытым подвижным составом?',
        answers: ['60 м', '70 м', '80 м', '75 м'],
        correctAnswers: ['60 м'],
      },
      {
        code: '63706162',
        text:
          'В какие сроки проводится первое полное техническое освидетельствование?',
        answers: [
          'Через год после ввода в эксплуатацию',
          'После отработки 22500 моточасов с момента первого ввода в эксплуатацию, но не позднее чем через 15 лет',
          'После отработки 15000 моточасов после первого, но не позднее чем через 10 лет',
          'После отработки 7500 моточасов, но не позднее чем через 5 лет',
        ],
        correctAnswers: [
          'После отработки 22500 моточасов с момента первого ввода в эксплуатацию, но не позднее чем через 15 лет',
        ],
      },
      {
        code: '63706163',
        text:
          'Какой класс прочности должны иметь болты для соединения ответственных деталей подвижного состава КД?',
        answers: [
          'Не менее 8,8',
          'Не менее 7,8',
          'Не менее 8,7',
          'Не менее 8,6',
        ],
        correctAnswers: ['Не менее 8,8'],
      },
      {
        code: '63706164',
        text: 'Кем проводится техническое освидетельствование КД?',
        answers: [
          'Специализированной организацией',
          'Проектной организацией',
          'Эксплуатирующей организацией',
          'Ростехнадзором',
        ],
        correctAnswers: ['Специализированной организацией'],
      },
      {
        code: '63706165',
        text:
          'Кому в своей работе подчиняется вспомогательный персонал канатной дороги (дежурные по станции, контролеры, проводники)?',
        answers: [
          'Ответственному за соблюдение требований промышленной безопасности на ОПО',
          'Ответственному за исправное состояние и безопасную эксплуатацию канатной дороги',
          'Оператору канатной дороги',
        ],
        correctAnswers: ['Оператору канатной дороги'],
      },
      {
        code: '63706166',
        text:
          'В каком случае допускается ручное управление гидравлической системой натяжения для поддержания давления?',
        answers: [
          'На вспомогательном приводе',
          'На аварийном приводе',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['На аварийном приводе'],
      },
      {
        code: '63706167',
        text: 'Что из перечисленного не относится к регламентным работам?',
        answers: [
          'Профилактическое обслуживание',
          'Проверки и ревизии',
          'Текущий ремонт',
          'Модернизация и реконструкция',
        ],
        correctAnswers: ['Модернизация и реконструкция'],
      },
      {
        code: '63706168',
        text:
          'Каким методом допускается информировать пассажиров о сложившейся нештатной ситуации?',
        answers: [
          'Только с поверхности земли персоналом, направленным для этого и имеющим ручной громкоговоритель',
          'Только громкоговорителями на линейных опорах',
          'Только системами оповещения в подвижном составе или проводниками в кабинах и вагонах с сопровождением',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63706169',
        text:
          'Какой максимальный фактический продольный уклон рельсового пути допускается для фуникулеров?',
        answers: ["17'", "30'", "11°30'", '60°'],
        correctAnswers: ['60°'],
      },
      {
        code: '63706170',
        text:
          'Какие из перечисленных требований к индикаторному устройству, обеспечивающему индикацию нахождения средств подвижного состава на трассе маятниковых КД, указаны неверно?',
        answers: [
          'Индикаторное устройство должно иметь шкалу с соответствующими делениями, где расстояние от средства подвижного состава до приводной станции выражено в метрах',
          'Индикаторное устройство положения подвижного состава должно работать вне зависимости от типа используемого привода',
          'Индикаторное устройство должно распознавать направление движения, в том числе при его изменении',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63706171',
        text:
          'Какой величины не должна превышать высота прохождения тягового каната над землей, с учетом снежного покрова на БКД с буксировочными устройствами штангового типа?',
        answers: [
          'Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н',
          'Высота прохождения тягового каната не должна превышать длины собранного буксировочного устройства',
          'Высота прохождения тягового каната не должна превышать длины полностью вытянутого буксировочного устройства',
          'Высота прохождения тягового каната не должна превышать 4 м',
        ],
        correctAnswers: [
          'Высота прохождения тягового каната не должна превышать длины буксировочного устройства с нагрузкой на опорную тарелку или траверсу 200 Н',
        ],
      },
      {
        code: '63706172',
        text:
          "Какой минимальный просвет должен быть на линии ППКД при отклонении подвижного состава в поперечном направлении на 11°30' между крайней точкой подвижного состава и иными элементами КД внутри колеи КД?",
        answers: ['0,3 м', '0,25 м', '0,15 м', '0,2 м'],
        correctAnswers: ['0,3 м'],
      },
      {
        code: '63706173',
        text: 'Где проводится проверка знаний персонала канатной дороги?',
        answers: [
          'В центральной или территориальной комиссии Ростехнадзора',
          'В комиссии предприятия эксплуатирующего канатную дорогу',
          'В комиссии специализированной организации',
          'В комиссии учебного комбината',
        ],
        correctAnswers: [
          'В комиссии предприятия эксплуатирующего канатную дорогу',
        ],
      },
      {
        code: '63706174',
        text:
          'Какой из перечисленных документов не должен храниться на рабочем месте оператора КД?',
        answers: [
          'Вахтенный журнал',
          'Журнал ремонта и осмотров или контрольные листы осмотров (акты осмотров)',
          'Производственные инструкции персонала КД',
          'Указания по действию персонала в экстренных ситуациях',
          'Руководство (руководства) по эксплуатации КД',
        ],
        correctAnswers: ['Руководство (руководства) по эксплуатации КД'],
      },
      {
        code: '63706175',
        text:
          'Кем определяются численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется КД, и ее структура?',
        answers: [
          'Организацией, имеющей лицензию на проведение экспертизы промышленной безопасности',
          'Организацией, эксплуатирующей КД',
          'Проектной организацией',
          'Ростехнадзором',
        ],
        correctAnswers: ['Организацией, эксплуатирующей КД'],
      },
      {
        code: '63706176',
        text:
          'Для какой канатной дороги минимальный коэффициент запаса прочности тяговых канатов соответствует значению 4?',
        answers: [
          'Для маятниковой КД без тормоза ловителя на несущем канате',
          'Для маятниковой КД с тормозом ловителя на несущем канате',
          'Для двухканатной КД замкнутого цикла только для одного направления',
          'Для наземной КД',
        ],
        correctAnswers: [
          'Для двухканатной КД замкнутого цикла только для одного направления',
        ],
      },
      {
        code: '63706177',
        text:
          'В каком случае максимальная скорость движения для кольцевых канатных дорог (КД) с неотцепляемыми креслами составляет 2 м/с?',
        answers: [
          'При перевозке пешеходов для КД с более чем двухместными креслами',
          'При перевозке пешеходов для КД с двухместными креслами',
          'При перевозке лыжников для КД с двухместными креслами',
          'При перевозке лыжников для КД с трех- или четырехместными креслами',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63706178',
        text:
          'Что из перечисленного должны обеспечивать устройства безопасности контроля скорости подвижного состава, если они установлены, для маятниковых КД и КД с пульсирующим режимом работы?',
        answers: [
          'Только контроль фиксированных точек',
          'Только контроль равномерного (синхронного) хода, параллельного хода',
          'Только контроль установки в нулевое положение',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63706179',
        text:
          'Какие требования предъявляются к трассе канатных дорог (далее - КД) при прохождении ее в лесистой местности с целью обеспечения промышленной безопасности ОПО с КД?',
        answers: [
          'При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на линию КД',
          'При прохождении КД в лесистой местности должна исключаться возможность падения деревьев на элементы, оборудование и подвижной состав КД',
          'Просека под линией канатной дороги должна иметь ширину не менее колеи КД с учетом поперечных колебаний канатов и подвижного состава, за исключением случаев, когда проектом КД не предусмотрена вертикальная эвакуация пассажиров с подвижного состава',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63706180',
        text:
          'Где должны храниться паспорт КД и иная техническая документация по КД?',
        answers: [
          'На рабочем месте ответственного за безопасную эксплуатацию КД',
          'На рабочем месте оператора КД',
          'На рабочем месте лица, ответственного за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации КД',
          'У руководителя организации, эксплуатирующей КД',
        ],
        correctAnswers: [
          'На рабочем месте ответственного за безопасную эксплуатацию КД',
        ],
      },
      {
        code: '63706181',
        text:
          'Какие характеристики неотцепляемого зажима должны быть указаны в руководстве по эксплуатации?',
        answers: [
          'Только номинальный диаметр каната',
          'Только величина момента затяжки зажима',
          'Только допустимый износ зажимных губок',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63706182',
        text:
          'Какое из перечисленных условий для начала движения КД указано неверно?',
        answers: [
          'Канатная дорога остановлена',
          'Электрооборудование управления движением установлено в исходное положение',
          'Рабочий тормоз открыт',
          'Получены сигналы готовности со всех станций',
        ],
        correctAnswers: ['Рабочий тормоз открыт'],
      },
      {
        code: '63706183',
        text: 'Какой знак не устанавливается в зоне посадки БКД?',
        answers: [
          '"При падении немедленно покинуть буксировочную дорожку"',
          '"Взять лыжные палки в одну руку"',
          '"Запрещено применять стиль "слалом" при движении по дорожке"',
          '"Отпустить буксировочное устройство"',
        ],
        correctAnswers: ['"Отпустить буксировочное устройство"'],
      },
      {
        code: '63706184',
        text:
          'Каким требованиям должен отвечать персонал КД по ремонту, обслуживанию и надзору за пассажирами?',
        answers: [
          'Персонал должен иметь высшее образование и пройти стажировку в течение 3-х месяцев на действующей КД',
          'Персонал должен быть старше возраста 18 лет, не имеющий медицинских противопоказаний и прошедший проверку знаний в объеме соответствующих производственных инструкций',
          'Персонал должен иметь высшее образование и стаж работы ИТР не менее 3 лет',
          'Персонал должен иметь среднее техническое образование',
        ],
        correctAnswers: [
          'Персонал должен быть старше возраста 18 лет, не имеющий медицинских противопоказаний и прошедший проверку знаний в объеме соответствующих производственных инструкций',
        ],
      },
      {
        code: '63706185',
        text:
          'Какое минимальное расстояние между буксировочными дорожками допускается при параллельном прохождении линий БКД и ББКД?',
        answers: ['2 м', '3,2 м', '8 м', '15 м'],
        correctAnswers: ['15 м'],
      },
      {
        code: '63706186',
        text:
          'В каких местах канатных дорог должны быть установлены индикаторные устройства?',
        answers: [
          'Только на пульте оператора',
          'Только на пункте управления',
          'Только на наблюдательных пунктах канатных дорог',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63706187',
        text:
          'В какие сроки проводится проверка средств связи на канатной дороге?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежегодно'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63706188',
        text:
          'Какой максимальный срок установлен для проведения эвакуации на канатной дороге?',
        answers: ['15 минут', '3 часа', '4 часа', '5 часов'],
        correctAnswers: ['4 часа'],
      },
      {
        code: '63706189',
        text:
          'С каким ускорением главный привод должен обеспечить пуск дороги, как порожней, так и загруженной, для всех ППКД и НКД с ручным управлением?',
        answers: [
          'Не более 0,5 м/с2',
          'Не более 0,75 м/с2',
          'Не более 0,55 м/с2',
          'Не более 0,6 м/с2',
        ],
        correctAnswers: ['Не более 0,5 м/с2'],
      },
      {
        code: '63706190',
        text:
          'Какой вид проверки проводится после ремонта приводов и расчетных элементов металлоконструкций с применением сварки?',
        answers: [
          'Экспертиза промышленной безопасности',
          'Внеочередное техническое освидетельствование',
          'Ежегодная ревизия',
          'Динамические испытания',
        ],
        correctAnswers: ['Внеочередное техническое освидетельствование'],
      },
      {
        code: '63706191',
        text:
          'Распространяется ли действие Правил безопасности пассажирских канатных дорог и фуникулеров на безопорные буксировочные канатные дороги?',
        answers: [
          'Да, распространяется',
          'Нет, действие Правил распространяется только на подвесные канатные дороги',
          'Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами и подвесные канатные дороги',
          'Нет, действие Правил распространяется только на буксировочные дороги с промежуточными опорами, подвесные канатные дороги и фуникулеры',
        ],
        correctAnswers: ['Да, распространяется'],
      },
      {
        code: '63706192',
        text:
          'При каком уменьшении диаметра несуще-тяговых канатов в результате повреждения сердечника, внутреннего износа, обмятия по сравнению с номинальным диаметром канат подлежит браковке даже при отсутствии видимых обрывов проволок?',
        answers: ['На 3%', 'На 5%', 'На 7%', 'На 10%'],
        correctAnswers: ['На 10%'],
      },
      {
        code: '63706193',
        text:
          'В каком из перечисленных случаев максимальная скорость движения двухканатных кольцевых дорог без проводника составляет 6 м/с?',
        answers: [
          'При прохождении опор с одним несущим канатом',
          'При прохождении опор с двумя несущими канатами',
          'На станциях при посадке - высадке',
          'В пролетах',
        ],
        correctAnswers: ['При прохождении опор с одним несущим канатом'],
      },
      {
        code: '63706194',
        text:
          'Кто выдает документ о проверке сварных соединений, а также литых и кованых деталей канатных дорог на наличие внутренних дефектов материала неразрушающими методами контроля? Укажите все правильные ответы.',
        answers: [
          'Организация-изготовитель',
          'Эксплуатирующая организация',
          'Лаборатория неразрушающего контроля',
          'Ростехнадзор',
          'Национальное агенство контроля сварки',
        ],
        correctAnswers: [
          'Организация-изготовитель',
          'Лаборатория неразрушающего контроля',
        ],
      },
      {
        code: '63706195',
        text:
          'Какое допускается значение замедления на приводном шкиве при аварийной остановке маятниковой КД?',
        answers: [
          'Не более 3,0 м/с2',
          'Не более 4,0 м/с2',
          'Не более 3,5 м/с﻿2',
          'Не более 5,0 м/с2',
        ],
        correctAnswers: ['Не более 3,0 м/с2'],
      },
      {
        code: '63706196',
        text:
          'Какая из перечисленных документаций поставляется (прилагается) с паспортом канатной дороги?',
        answers: [
          'Только ведомость на запчасти и инструменты',
          'Только чертежи быстроизнашивающихся деталей',
          'Только инструкции по счалке и заливке легкоплавким сплавом в муфты канатов',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63706197',
        text:
          'На каких БКД должно быть установлено устройство контроля скорости, останавливающее КД при превышении номинальной скорости на 10%?',
        answers: [
          'На БКД при номинальной скорости свыше 3 м/с',
          'На БКД, расположенных на 5 м и выше над землей',
          'На БКД при номинальной скорости свыше 5 м/с',
          'На БКД, угол наклона которых составляет более 5°',
        ],
        correctAnswers: ['На БКД при номинальной скорости свыше 3 м/с'],
      },
      {
        code: '63706198',
        text:
          'В какие сроки проводится проверка работоспособности аварийного двигателя, контроль уровней охлаждающей жидкости, масла, топлива?',
        answers: ['Ежедневно', 'Еженедельно', 'Ежемесячно', 'Ежегодно'],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63706199',
        text:
          'В какой срок пассажиры должны быть оповещены об обстоятельствах сложившейся нештатной ситуации?',
        answers: [
          'В течение 10 минут с момента ее возникновения',
          'В течение 15 минут с момента ее возникновения',
          'В течение 30 минут с момента ее возникновения',
          'В течение 4 часов с момента ее возникновения',
        ],
        correctAnswers: ['В течение 15 минут с момента ее возникновения'],
      },
      {
        code: '63706200',
        text:
          'С какой периодичностью проводятся повторные проверки знаний персонала?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63706201',
        text:
          'В каком случае допускается прохождение линии ППКД над территорией детских дошкольных и образовательных организаций?',
        answers: [
          'Прохождение линии ППКД над территорией таких организаций запрещено',
          'Там, где расстояние от низшей точки подвижного состава до земли с учетом снежного покрова составляет не менее 3 м',
          'Там, где расстояние от низшей точки подвижного состава до высшей точки зданий и сооружений составляет не менее 2 м',
          'Там, где исключена возможность присутствия людей',
        ],
        correctAnswers: [
          'Прохождение линии ППКД над территорией таких организаций запрещено',
        ],
      },
      {
        code: '63706202',
        text:
          'Какая устанавливается максимальная рабочая скорость движения буксировочных устройств?',
        answers: ['3 м/с', '4 м/с', '5 м/с', '6 м/с'],
        correctAnswers: ['4 м/с'],
      },
      {
        code: '63706203',
        text:
          'Какому значению должна соответствовать дополнительная (резервная) длина каната для возможности перемещения несущих канатов?',
        answers: [
          'Трехкратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м',
          'Пятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 3 м',
          'Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м',
          'Десятикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 10 м',
        ],
        correctAnswers: [
          'Шестикратной длине самого длинного башмака несущего каната или седловины роликовой цепи плюс 5 м',
        ],
      },
      {
        code: '63706204',
        text:
          'Каким должен быть минимальный интервал времени между движущимися буксировочными устройствами одноместных БКД?',
        answers: ['4 секунды', '5 секунд', '6 секунд', '3 секунды'],
        correctAnswers: ['4 секунды'],
      },
      {
        code: '63706205',
        text: 'Какой знак не устанавливается в зоне посадки кресельных ППКД?',
        answers: [
          '"Поднять носки лыж"',
          '"Опустить скобу безопасности или защитный колпак"',
          '"Не прыгать из кресла"',
          '"Не раскачиваться"',
        ],
        correctAnswers: ['"Поднять носки лыж"'],
      },
      {
        code: '63706206',
        text:
          'Каким образом запрещается производить сращивание концов тягового каната ББКД?',
        answers: [
          'С применением винтового зажима',
          'Счаливанием',
          'С использованием специальных приспособлений с клиновыми замками',
        ],
        correctAnswers: ['С применением винтового зажима'],
      },
      {
        code: '63706207',
        text:
          'В каких местах БКД должны быть установлены ограждения для предотвращения травмирования персонала?',
        answers: [
          'Только со стороны буксировочных устройств',
          'Только со стороны натяжного груза',
          'Только со стороны других подвижных частей, механизмов и элементов БКД при их неконтролируемом движении в зонах станций БКД',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63706208',
        text:
          'При какой минимальной скорости ветра запрещается посадка пассажиров и на станциях КД осуществляется высадка всех пассажиров из подвижного состава, если такие сведения отсутствуют в паспорте?',
        answers: ['15 м/с', '20 м/с', '22 м/с', '25 м/с'],
        correctAnswers: ['15 м/с'],
      },
      {
        code: '63706209',
        text:
          'Какие меры безопасности предпринимаются при прохождении трассы для перемещения пассажиров БКД?',
        answers: [
          'На мостиках должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия',
          'Трассу необходимо оградить сеткой, высота которой должна быть на 1 м выше снежного покрова',
          'Трассу необходимо выделить (обозначить)',
          'Ничего не предпринимается',
        ],
        correctAnswers: [
          'На мостиках должна иметь сплошное ограждение в виде стенки высотой не менее 1,1 м от снежного покрытия',
        ],
      },
      {
        code: '63706210',
        text:
          'Какое должно быть номинальное значение интервала времени прибытия на станцию подвижного состава у ППКД с отцепляемым составом?',
        answers: [
          'Не менее 5 секунд',
          'Не менее 4 секунд',
          'Не менее 3 секунд',
          'Не нормируется',
        ],
        correctAnswers: ['Не менее 5 секунд'],
      },
      {
        code: '63706211',
        text:
          'Какое из перечисленных дополнительных требований для отцепляемых зажимов буксировочных канатных дорог указано неверно?',
        answers: [
          'Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте',
          'Диаметр отверстия втулки зажима должен быть в пределах от 1,18d до 1,28d',
          'Для каната диаметром от 12 до 18 мм наружный диаметр втулки зажима должен составлять 40 - 46 мм',
          'Для каната диаметром от 12 до 18 мм длина втулки зажима должна быть равна 40 ± 1 мм',
        ],
        correctAnswers: [
          'Допустимые отклонения диаметра каната и нормы износа втулок зажима должны быть указаны в технологическом регламенте',
        ],
      },
    ],
    63708: [
      {
        code: '63708000',
        text:
          'С какой периодичностью необходимо контролировать и корректировать величину натяжения несущего каната при заякоривании обоих концов каната?',
        answers: [
          'Не менее одного раза в год',
          'Не менее одного раза в шесть месяцев',
          'Не менее одного раза в месяц',
          'Не менее одного раза в три месяца',
        ],
        correctAnswers: ['Не менее одного раза в шесть месяцев'],
      },
      {
        code: '63708001',
        text:
          'Какая может быть наибольшая величина ревизионной скорости тягового каната?',
        answers: ['1 м/с', '1,1 м/с', '1,2 м/с', '1,5 м/с'],
        correctAnswers: ['1 м/с'],
      },
      {
        code: '63708002',
        text:
          'При каком процентном увеличении скорости тягового каната при работе дороги в тормозном режиме должна обеспечиваться автоматическая остановка ГПКД?',
        answers: ['0,02', '0,01', '0,2', '0,15'],
        correctAnswers: ['0,2'],
      },
      {
        code: '63708003',
        text:
          'Какой наибольший размер транспортируемого груза должны удерживать предохранительные устройства при его просыпании из кузова вагонетки?',
        answers: ['3 мм', '10 мм', '20 мм', '15 мм'],
        correctAnswers: ['20 мм'],
      },
      {
        code: '63708004',
        text:
          'Какой должна быть минимальная ширина полосы свободной от зданий, сооружений, растительности и других препятствий, в каждую сторону от оси канатной дороги отсутствии предохранительных устройств и ширине колеи 4 м?',
        answers: ['4,0 м', '4,5 м', '5,0 м', '6,0 м'],
        correctAnswers: ['4,0 м'],
      },
      {
        code: '63708005',
        text:
          'Какой конструкции должны быть натяжные канаты несущих канатов ГПКД?',
        answers: [
          'Прядевыми двойной свивки с металлическим сердечником',
          'Прядевыми тройной свивки с органическим сердечником',
          'Закрытой конструкции',
          'Закрытой конструкции с двумя слоями клиновидной проволоки',
        ],
        correctAnswers: ['Прядевыми тройной свивки с органическим сердечником'],
      },
      {
        code: '63708006',
        text:
          'Какой запас прочности должны иметь зажимы на ГПКД с учетом возможного уменьшения диаметра каната?',
        answers: [
          'Не менее 1,5',
          'Не менее 1,2',
          'Не менее 1,3',
          'Не менее 0,75',
        ],
        correctAnswers: ['Не менее 1,5'],
      },
      {
        code: '63708007',
        text:
          'Каким образом должен производиться повторный запуск ГПКД после устранения причин аварийного отключения?',
        answers: [
          'Вручную, оператором грузовой подвесной канатной дороги',
          'Автоматически',
          'Как автоматически, так и вручную',
          'Только вручную, любым работником ремонтных подразделений грузовой подвесной канатной дороги',
        ],
        correctAnswers: [
          'Вручную, оператором грузовой подвесной канатной дороги',
        ],
      },
      {
        code: '63708008',
        text:
          'Какое должно быть минимальное соотношение диаметра огибаемого шкива, ролика или барабана к диаметру несущего каната?',
        answers: ['10', '30', '40', '50'],
        correctAnswers: ['50'],
      },
      {
        code: '63708009',
        text:
          'Какой величины должен быть зазор между габаритом вагонетки (с учетом поперечного и продольного качания и полного круга вращения ее кузова) и полом станции?',
        answers: [
          'Не менее 0,1 м',
          'Не менее 0,15 м',
          'Не менее 0,2 м',
          'Не менее 0,25 м',
        ],
        correctAnswers: ['Не менее 0,1 м'],
      },
      {
        code: '63708010',
        text:
          'Каким способом не должны закрепляться концы натяжных, сетевых и расчалочных канатов?',
        answers: [
          'Муфтами',
          'Коушами с зажимами',
          'Многоболтовыми зажимами',
          'Якорными барабанами',
        ],
        correctAnswers: ['Якорными барабанами'],
      },
      {
        code: '63708011',
        text: 'Каким способом не должны закрепляться концы несущего каната?',
        answers: [
          'Муфтами',
          'Коушами с зажимами',
          'Якорными барабанами и зажимами',
          'Установкой многоболтовых зажимов',
        ],
        correctAnswers: ['Коушами с зажимами'],
      },
      {
        code: '63708012',
        text:
          'Для каких целей предназначена предупредительная сигнализация, устанавливаемая на ГПКД?',
        answers: [
          'Для оповещения персонала об аварийной ситуации',
          'Для оповещения персонала о превышении установленной скорости движения',
          'Для оповещения персонала о предстоящем включении дороги в работу',
        ],
        correctAnswers: [
          'Для оповещения персонала о предстоящем включении дороги в работу',
        ],
      },
      {
        code: '63708013',
        text:
          'При каком минимальном зазоре между встречными вагонетками при поперечном раскачивании канатов с вагонетками внутрь колеи от воздействия ветра, допустимого для эксплуатации грузовых подвесных канатных дорог, расстояние между двумя ветвями дороги будет удовлетворять данному условию?',
        answers: ['0,5 м', '1 м', '1,5 м', '0,3 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63708014',
        text: 'Кем составляется руководство по эксплуатации ГПКД?',
        answers: [
          'Организацией, эксплуатирующей грузовые подвесные канатные дороги',
          'Только организацией, разработавшей проект грузовой подвесной канатной дороги',
          'Только специализированной организацией, аккредитованной Ростехнадзором',
          'Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии - специализированной организацией',
        ],
        correctAnswers: [
          'Предприятием-изготовителем или организацией, разработавшей проект грузовой подвесной канатной дороги, а при их отсутствии - специализированной организацией',
        ],
      },
      {
        code: '63708015',
        text: 'Что должно быть указано в технологических картах сварки?',
        answers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
          'Требования к работнику, выполняющему сварку, используемые материалы и необходимое оборудование',
          'Особенности процесса сварки, обеспечивающие качество сварных соединений',
        ],
        correctAnswers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
        ],
      },
      {
        code: '63708016',
        text:
          'Выберите обязательные конструктивные особенности полов на станциях в местах прохода людей, имеющих уклон свыше 10%. Укажите все правильные ответы.',
        answers: [
          'Ступенчатым',
          'Ребристым',
          'Диэлектрическим',
          'Нескользящим',
        ],
        correctAnswers: ['Ступенчатым', 'Ребристым'],
      },
      {
        code: '63708017',
        text:
          'В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр несущего каната?',
        answers: [
          'Не превышающие 30 дней',
          'Не превышающие 40 дней',
          'Не превышающие 50 дней',
          'Не превышающие 60 дней',
        ],
        correctAnswers: ['Не превышающие 30 дней'],
      },
      {
        code: '63708018',
        text:
          'Какой документ регламентирует сроки проведения технического обслуживания?',
        answers: [
          'Заключение экспертизы промышленной безопасности',
          'Руководство по эксплуатации',
          'Руководство по монтажу',
          'Паспорт канатной дороги',
        ],
        correctAnswers: ['Руководство по эксплуатации'],
      },
      {
        code: '63708019',
        text:
          'Какие сетки должны быть установлены в местах входа и выхода вагонеток при превышении уровня пола станции над уровнем земли более чем 0,5 м?',
        answers: [
          'Шириной не менее 0,7 м',
          'Шириной не менее 0,9 м',
          'Не регламентируется',
          'Шириной не менее 1 м',
        ],
        correctAnswers: ['Шириной не менее 1 м'],
      },
      {
        code: '63708020',
        text:
          'Какая максимальная скорость движения вагонеток на линии установлена для двухканатной кольцевой ГПКД при наличии на линии линейных муфт?',
        answers: ['1,5 м/с', '2,0 м/с', '3,0 м/с', '3,5 м/с'],
        correctAnswers: ['3,5 м/с'],
      },
      {
        code: '63708021',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Допускается маркировка нанесением краски (символы и цвет определяются распорядительным документом организации)',
          'Допускается применение клейма сварщика, имеющего наибольший опыт работы',
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63708022',
        text: 'Какие факторы не влияют на запрет эксплуатации ГПКД?',
        answers: [
          'Отсутствие на рабочем месте технического руководителя',
          'Наличие трещин в расчетных элементах металлоконструкций и узлах',
          'Отсутствие работников, прошедших проверку знаний в установленном порядке, для ее обслуживания',
          'Неблагоприятные погодные условия, оговоренные в паспорте грузовой подвесной канатной дороги',
        ],
        correctAnswers: [
          'Отсутствие на рабочем месте технического руководителя',
        ],
      },
      {
        code: '63708023',
        text:
          'В ходе каких мероприятий устанавливаются сроки и объем текущего, среднего и капитального ремонта оборудования, металлоконструкций, сооружений? Выберите несколько вариантов ответа.',
        answers: [
          'Экспертиза промышленной безопасности',
          'Техническое освидетельствование',
          'Плановый технический осмотр',
          'Техническое обслуживание',
        ],
        correctAnswers: [
          'Экспертиза промышленной безопасности',
          'Техническое освидетельствование',
          'Плановый технический осмотр',
        ],
      },
      {
        code: '63708024',
        text:
          'Кто должен по результатам экспертизы промышленной безопасности обеспечивать выполнение мероприятий по повышению уровня безопасности ГПКД в установленные сроки либо принимать решение о выводе ее из эксплуатации?',
        answers: [
          'Эксплуатирующая организация',
          'Организация, проводящая экспертизу промышленной безопасности',
          'Инспектор территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63708025',
        text:
          'На какие виды грузовых канатных дорог не распространяются требования Правил безопасности грузовых подвесных канатных дорог?',
        answers: [
          'Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом',
          'Одноканатные кольцевые грузовые подвесные канатные дороги с неотцепляемым на станциях подвижным составом',
          'Двухканатные подвесные канатные дороги с отцепляемым на станциях подвижным составом',
          'Двухканатные грузовые подвесные канатные дороги с реверсивным (маятниковым) движением подвижного состава',
        ],
        correctAnswers: [
          'Трехканатные кольцевые подвесные канатные дороги с отцепляемым на станциях подвижным составом',
        ],
      },
      {
        code: '63708026',
        text:
          'Каким образом должна осуществляться возможность останова привода грузовых подвесных канатных дорог?',
        answers: [
          'Только с пульта управления',
          'Всеми перечисленными способами',
          'Только от кнопок "СТОП", установленных на каждой станции',
          'Только от аварийных выключателей "Аварийный стоп"',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63708027',
        text:
          'Кем проводится техническое освидетельствование грузовых подвесных канатных дорог? Укажите все правильные ответы.',
        answers: [
          'Эксплуатирующей организацией при наличии квалифицированного персонала',
          'Инспектором Ростехнадзора',
          'Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог',
          'Специализированной организацией совместно с инспектором Ростехнадзора',
        ],
        correctAnswers: [
          'Эксплуатирующей организацией при наличии квалифицированного персонала',
          'Специализированной организацией в присутствии специалиста, ответственного за исправное состояние и безопасную эксплуатацию грузовых подъемных канатных дорог',
        ],
      },
      {
        code: '63708028',
        text:
          'Какое утверждение соответствует требованиям к ширине предохранительных мостов?',
        answers: [
          'Должна обеспечивать перекрытие пространства на 2 м в каждую сторону от оси несущего каната',
          'Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната',
          'Должна обеспечивать перекрытие пространства на 2,25 м в каждую сторону от оси несущего каната',
          'Должна обеспечивать перекрытие пространства на 1,5 м в каждую сторону от оси несущего каната',
        ],
        correctAnswers: [
          'Должна обеспечивать перекрытие пространства на 1,25 м в каждую сторону от оси несущего каната',
        ],
      },
      {
        code: '63708029',
        text:
          'Какие требования установлены Правилами безопасности грузовых подвесных канатных дорог к площадкам для обслуживания оборудования ГПКД, расположенным на высоте более 2 м?',
        answers: [
          'Площадки должны иметь ограждение высотой не менее 0,5 м и сплошную зашивку по верху',
          'Площадки должны иметь ограждение высотой не менее 0,8 м',
          'Площадки должны иметь ограждение высотой не менее 0,8 м и сплошную зашивку',
          'Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м',
        ],
        correctAnswers: [
          'Площадки должны иметь ограждение высотой не менее 1 м и сплошную зашивку понизу на высоте не менее 0,1 м',
        ],
      },
      {
        code: '63708030',
        text:
          'При обнаружении каких из перечисленных видов деформаций канаты могут быть допущены к работе?',
        answers: [
          'При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции',
          'При обнаружении местного уменьшения диаметра каната',
          'При обнаружении корзинообразной деформации',
          'При обнаружении выдавливания проволок или расслоения прядей',
        ],
        correctAnswers: [
          'При обнаружении износа высоты наружной фасонной проволоки замка на 10% в канате закрытой конструкции',
        ],
      },
      {
        code: '63708031',
        text:
          'Какое допускается максимальное центростремительное ускорение тележки вагонетки?',
        answers: ['3,0 м/c²', '1,5 м/c²', '2,5 м/c²', '3,5 м/c²'],
        correctAnswers: ['2,5 м/c²'],
      },
      {
        code: '63708032',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности натяжного каната для тяговых канатов?',
        answers: ['1,5', '2,5', '3,0', '4,0'],
        correctAnswers: ['4,0'],
      },
      {
        code: '63708033',
        text:
          'В каком из перечисленных случаев несущие канаты закрытой конструкции не подлежат замене?',
        answers: [
          'В случае если на участке длиной, равной 6dk, имеются разрывы двух и более смежных проволок наружного слоя',
          'В случае если имеется выход из замка одной и более проволок наружного слоя без ее обрыва',
          'В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборваны',
          'В случае если оборванные концы наружных проволок каната выступают',
        ],
        correctAnswers: [
          'В случае если на участке длиной, равной 10dk, одна шестая часть (16,6%) проволок наружного слоя замка оборваны',
        ],
      },
      {
        code: '63708034',
        text:
          'Каким должно быть свободное боковое пространство между вагонеткой (с учетом поперечного качания каната и вагонеток) и сооружениями или естественными препятствиями в местах, где возможен проход людей?',
        answers: [
          'Не менее 2 м',
          'Не более 1,5 м',
          'Не более 1 м',
          'Не менее 1 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63708035',
        text: 'Какими должны быть тяговые и несуще-тяговые канаты?',
        answers: [
          'Прядевыми двойной свивки с органическим сердечником',
          'Закрытой конструкции с двумя слоями клиновидной проволоки',
          'Тройной свивки с металлическим сердечником',
          'Одинарной свивки с органическим сердечником',
        ],
        correctAnswers: ['Прядевыми двойной свивки с органическим сердечником'],
      },
      {
        code: '63708036',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже II и прошедшие обучение мерам пожарной безопасности',
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
          'Любые работники, аттестованные для соответствующих способов сварки',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63708037',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
          'Только сварочным оборудованием',
          'Только необходимым сварочным оборудованием и наглядными пособиями (плакатами, схемами и т. д.) по вопросу соблюдения противопожарного режима на объекте',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63708038',
        text:
          'Каким условиям должна соответствовать длина натяжного участка несущих канатов (канат, по которому перемещается подвижной состав)?',
        answers: [
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 40% от расчетного',
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 50% от расчетного',
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного',
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 35% от расчетного',
        ],
        correctAnswers: [
          'Условиям, при которых увеличение или уменьшение натяжения любого из несущих канатов от трения на шкивах и башмаках (башмак (опорный, отклоняющий) - закрепленное жестко или качающееся устройство на опоре или станции, обеспечивающее поддержку или отклонение несущего каната) не превышает 30% от расчетного',
        ],
      },
      {
        code: '63708039',
        text: 'Что не включает в себя техническое обслуживание?',
        answers: [
          'Замер износа узлов и деталей',
          'Дефектоскопию канатов',
          'Регулировку узлов и деталей',
          'Смазку узлов и деталей',
        ],
        correctAnswers: ['Дефектоскопию канатов'],
      },
      {
        code: '63708040',
        text:
          'Какое минимальное расстояние по вертикали от низшей точки вагонетки на трассе ГПКД должно быть при прохождении над зданиями и сооружениями?',
        answers: ['0,5 м', '1,0 м', '1,5 м', '2,0 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63708041',
        text: 'Какой конструкции должны быть несущие канаты ГПКД?',
        answers: [
          'Прядевыми двойной свивки',
          'Прядевыми тройной свивки с металлическим сердечником',
          'Спиральными закрытой конструкции',
          'Прядевыми с органическим сердечником',
        ],
        correctAnswers: ['Спиральными закрытой конструкции'],
      },
      {
        code: '63708042',
        text:
          'К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?',
        answers: [
          'К любым сварочным работам при наличии действующих аттестационных удостоверений по какому-либо способу сварки',
          'К выполнению сварочных работ, указанных в действующих документах',
          'К выполнению любых сварочных работ при наличии удостоверения сварщика и после выполнения контрольного сварного соединения',
        ],
        correctAnswers: [
          'К выполнению сварочных работ, указанных в действующих документах',
        ],
      },
      {
        code: '63708043',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности натяжного каната для несущих канатов?',
        answers: ['1,5', '2,5', '3,0', '3,5'],
        correctAnswers: ['3,5'],
      },
      {
        code: '63708044',
        text:
          'Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только соответствие основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации',
          'Только соответствие численного состава и квалификации персонала сварочного производства',
          'Только соответствие сборочного и сварочного оборудования',
          'Выполнение всех подготовительных мероприятий',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63708045',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Только заключения по неразрушающему контролю',
          'Только протоколы испытаний сварочных соединений',
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63708046',
        text:
          'Кто может быть председателем комиссии по принятию решения о возможности ввода ГПКД в эксплуатацию?',
        answers: [
          'Представитель проектной организации',
          'Представитель собственника (владельца)',
          'Представитель организации-изготовителя',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Представитель собственника (владельца)'],
      },
      {
        code: '63708047',
        text:
          'Какой повышающий коэффициент нагрузок соответствует требованиям по надежности для нагрузок от натяжения тягового каната?',
        answers: ['1', '1,2', '1,3', '1,4', '1,1'],
        correctAnswers: ['1,4'],
      },
      {
        code: '63708048',
        text:
          'Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только организовать проведение операционного контроля',
          'Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации',
          'Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии) с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки',
          'Все перечисленные функции',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63708049',
        text:
          'К какой деятельности в области промышленной безопасности Правила безопасности грузовых подвесных канатных дорог требования не устанавливают?',
        answers: [
          'К изготовлению, монтажу, наладке, обслуживанию и ремонту',
          'К проектированию грузовых подвесных канатных дорог',
          'К эксплуатации, капитальному ремонту, техническому перевооружению, консервации и ликвидации',
          'К проведению экспертизы промышленной безопасности',
        ],
        correctAnswers: ['К проектированию грузовых подвесных канатных дорог'],
      },
      {
        code: '63708050',
        text: 'Какая должна быть минимальная длина счалки тягового каната?',
        answers: [
          '500 диаметров каната',
          '700 диаметров каната',
          '900 диаметров каната',
          '1000 диаметров каната',
        ],
        correctAnswers: ['1000 диаметров каната'],
      },
      {
        code: '63708051',
        text: 'С какой целью проводится техническое освидетельствование ГПКД?',
        answers: [
          'Только с целью установления соответствия ГПКД и ее устройства требованиям Правил безопасности грузовых подвесных канатных дорог и паспортным данным',
          'Только с целью установления соответствия эксплуатации, содержания и обслуживания ГПКД требованиям Правил безопасности грузовых подвесных канатных дорог',
          'Только с целью установления технически исправного состояния ГПКД, обеспечивающего ее безопасную работу',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63708052',
        text:
          'Какие виды работ могут выполнять работники неспециализированных организаций и ремонтных служб?',
        answers: [
          'Экспертиза промышленной безопасности',
          'Смазку вагонетки',
          'Поверка приборов безопасности',
          'Оценка соответствия сварочных швов',
        ],
        correctAnswers: ['Смазку вагонетки'],
      },
      {
        code: '63708053',
        text:
          'В какие сроки должен производиться работниками, обслуживающими канатную дорогу, плановый осмотр приборов и устройств безопасности, сигнализации, связи?',
        answers: [
          'Не превышающие 7 дней',
          'Не превышающие 10 дней',
          'Не превышающие 14 дней',
          'Не превышающие 20 дней',
        ],
        correctAnswers: ['Не превышающие 7 дней'],
      },
      {
        code: '63708054',
        text:
          'Какое из перечисленных требований к безопасности технологических процессов грузовых подвесных канатных дорог указано неверно?',
        answers: [
          'Минимальное усилие со стороны каната на каждый поддерживающий ролик одноканатных грузовых подвесных канатных дорог должно быть не менее 500 Н',
          'Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры',
          'Роликовые балансиры на опорах одноканатных грузовых подвесных канатных дорог должны состоять не менее чем из двух роликов',
          'Поддерживающие ролики не должны вызывать отклонение несуще-тяговых и тяговых канатов более чем на 10%',
        ],
        correctAnswers: [
          'Увеличение натяжения каната на 40% приводит к отрыву каната и потере его контакта с роликом на опоре, наивысшая точка которой находится ниже хорды, соединяющей две соседние с ней опоры',
        ],
      },
      {
        code: '63708055',
        text:
          'Какое из перечисленных требований, установленных к ГПКД с маятниковым движением подвижного состава, указано неверно?',
        answers: [
          'Грузовые подвесные канатные дороги с маятниковым движением подвижного состава должны автоматически останавливаться при возникновении неисправности в системе контроля скорости',
          'Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с',
          'Привод грузовой подвесной канатной дороги с маятниковым движением подвижного состава должен быть оснащен системой регулирования и контроля скорости с учетом ее снижения при подходе к станции',
          'На грузовых подвесных канатных дорогах с маятниковым движением подвижного состава конечные положения вагонетки должны контролироваться автоматически',
        ],
        correctAnswers: [
          'Приводы грузовых подвесных канатных дорог с маятниковым движением подвижного состава должны обеспечивать возможность работы канатной дороги с ревизионной скоростью не более 3 м/с',
        ],
      },
      {
        code: '63708056',
        text:
          'Какое минимальное соотношение между натяжением несущего каната и весом груженой вагонетки?',
        answers: ['9', '10', '6', '7', '8'],
        correctAnswers: ['8'],
      },
      {
        code: '63708057',
        text:
          'Какая максимальная скорость движения груженых вагонеток на линии установлена для двухканатных кольцевых ГПКД при наличии на их станциях горизонтальных обводных шкивов?',
        answers: ['1,0 м/с', '1,6 м/с', '2,0 м/с', '2,5 м/с'],
        correctAnswers: ['1,6 м/с'],
      },
      {
        code: '63708058',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности для несущего каната?',
        answers: ['1,8', '2,0', '2,5', '2,8'],
        correctAnswers: ['2,8'],
      },
      {
        code: '63708059',
        text:
          'Каким должен быть минимальный коэффициент запаса прочности для тягового каната?',
        answers: ['1,5', '2,5', '3,0', '4,0'],
        correctAnswers: ['4,0'],
      },
      {
        code: '63708060',
        text:
          'Для каких нагрузок учитывается повышающий коэффициент нагрузок 1,1, соответствующий требованиям по надежности грузовых подвесных канатных дорог?',
        answers: [
          'Для нагрузок от трения канатов по башмаку',
          'Для нагрузок от веса оборудования',
          'Для нагрузок от натяжения расчалочных канатов',
          'Для нагрузок от натяжения сетевых канатов',
        ],
        correctAnswers: ['Для нагрузок от веса оборудования'],
      },
      {
        code: '63708061',
        text:
          'Какой ширины должны быть безопасные проходы для людей, обслуживающих оборудование ГПКД?',
        answers: [
          'От 0,6 м до 0,7 м',
          'Не менее 0,8 м',
          'Не менее 0,5 м',
          'От 0,5 м до 0,6 м',
        ],
        correctAnswers: ['Не менее 0,8 м'],
      },
      {
        code: '63708062',
        text: 'В какие сроки осуществляется техническое обслуживание ГПКД?',
        answers: [
          'В срок не более 30 дней',
          'В срок не более 60 дней',
          'В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги',
          'В срок не более 90 дней',
        ],
        correctAnswers: [
          'В сроки, устанавливаемые в руководстве по эксплуатации грузовой подвесной канатной дороги',
        ],
      },
      {
        code: '63708063',
        text:
          'Какой должен быть коэффициент запаса сцепления тягового каната с приводным шкивом?',
        answers: [
          'Не менее 1,1',
          'Не менее 1,0',
          'Не менее 0,9',
          'Не менее 0,7',
        ],
        correctAnswers: ['Не менее 1,1'],
      },
      {
        code: '63708064',
        text:
          'При каком наклоне к горизонту рельсовые пути галерей и станций должны быть оборудованы ловителями, препятствующими обратному ходу вагонеток при движении их на подъем?',
        answers: ['Свыше 10%', 'Свыше 8%', 'Свыше 5%', 'Свыше 7%'],
        correctAnswers: ['Свыше 10%'],
      },
      {
        code: '63708065',
        text:
          'Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?',
        answers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
          'Должны быть проверены рентгеновским излучением',
          'Должны быть промыты керосином и обезжирены',
        ],
        correctAnswers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
        ],
      },
      {
        code: '63708066',
        text:
          'Какие документы регламентируют закрепление несущих канатов в муфтах?',
        answers: [
          'Рекомендации заводов - изготовителей канатов',
          'Проектная документация',
          'Сертификаты безопасности',
          'Правила безопасности грузовых подвесных канатных дорог',
        ],
        correctAnswers: ['Рекомендации заводов - изготовителей канатов'],
      },
      {
        code: '63708067',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
          'Проверку знания теоретических основ сварки',
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63708068',
        text: 'Какой документ на ГПКД не является обязательным?',
        answers: [
          'Должностные инструкции для специалистов',
          'Производственные инструкции для персонала',
          'Журнал работы ГПКД и передачи смен',
          'График внепланового осмотра оборудования',
        ],
        correctAnswers: ['График внепланового осмотра оборудования'],
      },
      {
        code: '63708069',
        text:
          'Какое может быть наибольшее число соединений тягового каната на 1 км его длины при частичной замене во время эксплуатации?',
        answers: ['5', '7', '9', '10'],
        correctAnswers: ['5'],
      },
      {
        code: '63708070',
        text:
          'Что из перечисленного должно быть предусмотрено на станциях ГПКД?',
        answers: [
          'Только устройства для автоматического выпуска вагонеток на линию',
          'Только механизация загрузки и разгрузки вагонеток',
          'Только механизация возвращения кузова в первоначальное положение после разгрузки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63708071',
        text:
          'Что из перечисленного входит в перечень работ, выполняемых специализированными организациями или ремонтными службами (подразделениями) эксплуатирующей организации?',
        answers: [
          'Только работы, связанные с наплавкой, напылением и последующей механической обработкой деталей',
          'Только работы, связанные с нагревом (охлаждением) деталей и узлов',
          'Только монтаж приборов безопасности, средств сигнализации и связи',
          'Только ремонт натяжных и анкерных устройств для всех видов канатов',
          'Все перечисленные виды работ',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63708072',
        text:
          'В каком документе указывается срок очередного технического освидетельствования?',
        answers: [
          'В инструкции по эксплуатации',
          'В приказе о вводе в эксплуатацию',
          'В паспорте канатной дороги',
          'В плане-графике обслуживания грузовых подвесных канатных дорог',
        ],
        correctAnswers: ['В паспорте канатной дороги'],
      },
      {
        code: '63708073',
        text:
          'В каких местах не должны быть установлены кнопки "Аварийный СТОП"?',
        answers: [
          'У линейных опор',
          'В помещениях приводов',
          'Вблизи включателей и выключателей вагонеток',
          'У мест загрузки и разгрузки вагонеток',
        ],
        correctAnswers: ['У линейных опор'],
      },
      {
        code: '63708074',
        text:
          'Каким документом регламентируется ежедневный контроль состояния дороги?',
        answers: [
          'Сертификатом безопасности',
          'Приказом по предприятию',
          'Паспортом оборудования',
          'Руководством по эксплуатации',
        ],
        correctAnswers: ['Руководством по эксплуатации'],
      },
      {
        code: '63708075',
        text:
          'Какое из перечисленных утверждений к лестницам для подъема на опоры и станции грузовых подвесных канатных дорог указано верно?',
        answers: [
          'Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг',
          'Лестницы высотой более 10 м должны быть выполнены в виде маршей с площадками через каждые 5 м',
          'Лестницы высотой менее 30 м должны быть выполнены в виде маршей с площадками',
          'Все перечисленные утверждения верны',
        ],
        correctAnswers: [
          'Вертикальные лестницы опор высотой до 30 м и линейных станций при высоте более 5 м должны иметь, начиная с высоты 3 м, ограждения в виде дуг',
        ],
      },
      {
        code: '63708076',
        text:
          'Какое из перечисленных требований к грузовым подвесным канатным дорогам указано верно?',
        answers: [
          'В машинном отделении должно быть устроено аварийное освещение',
          'Канат не требуется заземлять, если у него органический сердечник',
          'Допускается небольшое скапливание воды в элементах опор',
          'Нумерация опор обязательна только при условии, что их количество больше 10',
        ],
        correctAnswers: [
          'В машинном отделении должно быть устроено аварийное освещение',
        ],
      },
      {
        code: '63708077',
        text:
          'Каким способом должен соединяться натяжной канат с несущим канатом?',
        answers: [
          'Коушами с зажимом',
          'Переходной муфтой',
          'Многоболтовыми зажимами',
          'Концевой муфтой',
        ],
        correctAnswers: ['Переходной муфтой'],
      },
      {
        code: '63708078',
        text:
          'В каком из перечисленных случаев стальной канат двойной свивки ГПКД не подлежит браковке?',
        answers: [
          'При износе высоты наружной фасонной проволоки замка на 50% и более у каната закрытой конструкции',
          'При уменьшении первоначального диаметра наружных проволок в результате износа на 40% и более, если их количество равно либо превышает браковочные показатели по обрывам',
          'При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов',
          'При уменьшении диаметра каната в результате поверхностного износа или коррозии на 7% и более по сравнению с номинальным диаметром даже при отсутствии видимых обрывов проволок',
        ],
        correctAnswers: [
          'При уменьшении диаметра каната в результате повреждения сердечника (внутреннего износа, обмятия, разрыва) на 2% от номинального диаметра у малокрутящихся канатов',
        ],
      },
      {
        code: '63708079',
        text:
          'Какое минимальное число витков каната должно быть на якорном барабане?',
        answers: ['1,5', '2', '2,5', '3'],
        correctAnswers: ['3'],
      },
      {
        code: '63708080',
        text:
          'В соответствии с требованиями каких из перечисленных документов должны осуществляться транспортировка к месту монтажа, а также хранение оборудования ГПКД, узлов и деталей?',
        answers: [
          'ФНП "Правила безопасности грузовых подвесных канатных дорог"',
          'Внутренних правил перевозчика',
          'Инструкций изготовителя',
          'Проектной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63708081',
        text:
          'Какую информацию должно содержать руководство по эксплуатации грузовых подвесных канатных дорог?',
        answers: [
          'Только методы контроля и диагностики состояния элементов и узлов грузовых подвесных канатных дорог',
          'Всю перечисленную информацию',
          'Только указания по выводу из эксплуатации и утилизации',
          'Только программы и методики испытаний',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63708082',
        text:
          'Кем принимается решение о вводе в эксплуатацию грузовых подвесных канатных дорог?',
        answers: [
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
          'Руководителем эксплуатирующей организации',
          'Уполномоченным представителем Ростехнадзора',
          'Техническим руководителем',
        ],
        correctAnswers: [
          'Ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО',
        ],
      },
      {
        code: '63708083',
        text:
          'Каким должно быть минимальное натяжение тягового каната кольцевых ГПКД?',
        answers: [
          'От 400 q до 500 q (q - погонный вес тягового каната)',
          'Не должно быть меньше 500 q (q - погонный вес тягового каната)',
          'Не должно быть меньше 600 q (q - погонный вес тягового каната)',
          'От 200 q до 300 q (q - погонный вес тягового каната)',
        ],
        correctAnswers: [
          'Не должно быть меньше 600 q (q - погонный вес тягового каната)',
        ],
      },
      {
        code: '63708084',
        text:
          'Какая устанавливается минимальная высота бортов предохранительных сетей?',
        answers: ['1,8 м', '1,2 м', '1 м', '1,5 м'],
        correctAnswers: ['1,2 м'],
      },
      {
        code: '63708085',
        text:
          'Какие требования предъявляются федеральными нормами и правилами в области промышленной безопасности "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
          'Оборудование и материалы должны быть экономичными в использовании и простыми в ремонте',
          'Оборудование и материалы должны обеспечивать максимальную производительность работ и быть эргономичными',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63708086',
        text:
          'Кто входит в состав комиссии, принимающей решение о проведении ремонта канатов?',
        answers: [
          'Только представители специализированной организации',
          'Только представители эксплуатирующей организации',
          'Представители эксплуатирующей организации и представители территориального органа Ростехнадзора',
          'Представители владельца, организации, разработавшей проект, и (или) представители специализированной организации',
        ],
        correctAnswers: [
          'Представители владельца, организации, разработавшей проект, и (или) представители специализированной организации',
        ],
      },
      {
        code: '63708087',
        text:
          'Что из перечисленного согласно Правилам безопасности грузовых подвесных канатных дорог не проводится при техническом освидетельствовании ГПКД?',
        answers: [
          'Проверка технического состояния оборудования путем осмотра и измерений',
          'Статические и динамические испытания',
          'Поверка контрольных (испытательных) грузов',
          'Учебная операция по отработке действий работников грузовых подвесных канатных дорог в случае возникновения возможной чрезвычайной ситуации',
          'Проверка эксплуатационной документации',
        ],
        correctAnswers: ['Поверка контрольных (испытательных) грузов'],
      },
    ],
    63699: [
      {
        code: '63699000',
        text:
          'Какие эскалаторы тяжелого режима работы должны использоваться в метрополитенах?',
        answers: [
          'С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°',
          'С номинальной скоростью не более 0,65 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°',
          'С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 40°',
          'С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 800 ± 10 мм и углом наклона не более 30°',
        ],
        correctAnswers: [
          'С номинальной скоростью не более 0,75 м/с, шириной лестничного полотна 1000 ± 10 мм и углом наклона не более 30°',
        ],
      },
      {
        code: '63699001',
        text:
          'Какое из перечисленных требований к балюстраде эскалатора указано неверно?',
        answers: [
          'Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху',
          'Зазор в стыках щитов и фартуков балюстрады должен быть не более 4 мм',
          'Внутренние плоскости балюстрады должны быть вертикальными или расширенными кверху',
          'Расстояние между верхними кромками боковых щитов балюстрады не должно превышать ширину ступени более чем на 200 мм',
        ],
        correctAnswers: [
          'Перепады плоскостей между элементами балюстрады (щиты, планки, штапики) со стороны лестничного полотна допускаются более 3 мм, если плоскости балюстрады выполнены вертикальными или расширенными кверху',
        ],
      },
      {
        code: '63699002',
        text:
          'В каком из перечисленных случаев допускается одновременная работа главного и вспомогательного приводов?',
        answers: [
          'Одновременная работа главного и вспомогательного приводов не допускается ни в каком случае',
          'Только в случае снижения мощности главного привода',
          'Только в случае обкатки эскалатора',
          'Только в случае перегрузки эскалатора',
        ],
        correctAnswers: [
          'Одновременная работа главного и вспомогательного приводов не допускается ни в каком случае',
        ],
      },
      {
        code: '63699003',
        text:
          'Сколько должен составлять перепад по высоте двух смежных ступеней на горизонтальном участке эскалатора?',
        answers: [
          'Не должен превышать 4 мм',
          'Не должен превышать 5 мм',
          'Не должен превышать 6 мм',
          'Не должен превышать 7 мм',
        ],
        correctAnswers: ['Не должен превышать 4 мм'],
      },
      {
        code: '63699004',
        text: 'Для каких целей предназначен главный привод эскалатора?',
        answers: [
          'Для передвижения лестничного полотна эскалатора с номинальной скоростью',
          'Для передвижения лестничного полотна эскалатора с ремонтной скоростью при монтажных и демонтажных работах',
          'Для растормаживания аварийного тормоза',
          'Для передвижения лестничного полотна эскалатора с ремонтной скоростью при проведении работ по техническому обслуживанию',
        ],
        correctAnswers: [
          'Для передвижения лестничного полотна эскалатора с номинальной скоростью',
        ],
      },
      {
        code: '63699005',
        text:
          'Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров не более 6 м и при номинальной скорости не более 0,5 м/с?',
        answers: [
          'Не менее 800 мм',
          'Не менее 500 мм',
          'Не менее 600 мм',
          'Не менее 700 мм',
        ],
        correctAnswers: ['Не менее 800 мм'],
      },
      {
        code: '63699006',
        text:
          'Какой организацией определяется численность службы производственного контроля за соблюдением требований промышленной безопасности при эксплуатации ОПО, на котором используется эскалатор, и ее структура?',
        answers: [
          'Контролирующей организацией',
          'Эксплуатирующей организацией',
          'Специализированной организацией',
          'Проектной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63699007',
        text: 'Какая величина угла наклона установлена для эскалатора?',
        answers: [
          'Не более 30°',
          'Не более 38°',
          'Не более 35°',
          'Не более 45°',
        ],
        correctAnswers: ['Не более 30°'],
      },
      {
        code: '63699008',
        text: 'Что запрещается при обустройстве балюстрады?',
        answers: [
          'Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну',
          'Выполнять конструкцию балюстрады в местах, требующих технического обслуживания, в легкоразборном исполнении',
          'Устанавливать решетки для громкоговорящей связи, осветителей и розеток',
          'Предусматривать дефлекторы в конструкции балюстрады, препятствующие затягиванию элементов одежды и обуви в зазор между ступенью и фартуком эскалатора',
        ],
        correctAnswers: [
          'Устанавливать на фартуках планки и штапики, обращенные к лестничному полотну',
        ],
      },
      {
        code: '63699009',
        text:
          'В каком случае после срабатывания блокировочного устройства повторный пуск эскалатора в работу возможен без принудительного приведения блокировочного устройства в исходное положение?',
        answers: [
          'При срабатывании рабочего тормоза и блокировок входных площадок',
          'При сходе поручня с направляющих на нижнем криволинейном участке',
          'При сползании обода основного или вспомогательного бегунка',
          'При подъеме или опускании ступеней перед входными площадками',
        ],
        correctAnswers: [
          'При срабатывании рабочего тормоза и блокировок входных площадок',
        ],
      },
      {
        code: '63699010',
        text:
          'В каком случае в процессе монтажа эскалаторов допускаются отступления от требований конструкторской документации?',
        answers: [
          'При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией',
          'Только при согласовании этих изменений с организацией-изготовителем или организацией-разработчиком',
          'Только при согласовании этих изменений с владельцем ОПО',
        ],
        correctAnswers: [
          'При согласовании этих изменений с владельцем ОПО, а также организацией - изготовителем эскалатора или организацией - разработчиком данной документации, либо со специализированной по эскалаторам проектной организацией',
        ],
      },
      {
        code: '63699011',
        text:
          'Какая номинальная скорость движения установлена для лестничного полотна эскалатора?',
        answers: [
          'Не более 0,75 м/с',
          'Не более 1,0 м/с',
          'Не более 1,25 м/с',
          'Не более 0,9 м/с',
        ],
        correctAnswers: ['Не более 0,75 м/с'],
      },
      {
        code: '63699012',
        text:
          'Начиная с какой высоты, на вертикальных лестницах натяжной камеры при высоте лестницы более 5 м должны быть установлены ограждения в виде дуг?',
        answers: [
          'С высоты 3,0 м',
          'С высоты 1,5 м',
          'С высоты 2,0 м',
          'С высоты 1,3 м',
        ],
        correctAnswers: ['С высоты 3,0 м'],
      },
      {
        code: '63699013',
        text:
          'Каким образом не может быть установлен объем текущего, среднего и капитального ремонта?',
        answers: [
          'В порядке нерегламентированного технического обслуживания',
          'В порядке экспертизы промышленной безопасности',
          'В порядке технического освидетельствования',
          'В порядке планового технического осмотра',
        ],
        correctAnswers: [
          'В порядке нерегламентированного технического обслуживания',
        ],
      },
      {
        code: '63699014',
        text:
          'Каким образом в процессе эксплуатации эскалаторов должно определяться их соответствие требованиям промышленной безопасности?',
        answers: [
          'Проведением экспертизы промышленной безопасности',
          'Проведением декларирования',
          'Проведением сертификации',
        ],
        correctAnswers: ['Проведением экспертизы промышленной безопасности'],
      },
      {
        code: '63699015',
        text:
          'Какая из перечисленных записей не ставится в паспорте эскалатора уполномоченным лицом владельца ОПО, проводившим освидетельствование?',
        answers: [
          'Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора',
          'Запись о сроке очередного периодического технического освидетельствования',
          'Запись о результатах полного технического освидетельствования',
          'Запись о вводе эскалатора в эксплуатацию',
        ],
        correctAnswers: [
          'Запись о методах контроля и диагностики, применяемых в процессе эксплуатации эскалатора',
        ],
      },
      {
        code: '63699016',
        text:
          'От какого количества независимых источников питания должно осуществляться электроснабжение комплекса (блока) эскалаторов?',
        answers: [
          'Не менее чем от двух',
          'Не менее чем от трех',
          'Не менее чем от четырех',
          'Не нормируется',
        ],
        correctAnswers: ['Не менее чем от двух'],
      },
      {
        code: '63699017',
        text:
          'При каком торможении на спуск с максимальной нагрузкой  на пути (в метрах) каждый из тормозов должен останавливать лестничное полотно при применении двух и более рабочих тормозов?',
        answers: ['Не более 6V', 'Не более 7V', 'Не более 8V', 'Не более 5V'],
        correctAnswers: ['Не более 6V'],
      },
      {
        code: '63699018',
        text:
          'В каком случае допускается осуществлять электропитание дополнительного (аварийного) тормоза (тормозов) от одного источника?',
        answers: [
          'При использовании нормального замкнутого тормоза',
          'При установке тормоза на главном валу',
          'В случаях, определенных инструкцией по эксплуатации',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['При использовании нормального замкнутого тормоза'],
      },
      {
        code: '63699019',
        text:
          'Что должен включать комплект документации на каждый изготовленный эскалатор?',
        answers: [
          'Ведомость комплекта запасных изделий для пусконаладочных работ',
          'Должностную инструкцию работника, ответственного за содержание эскалатора в исправном состоянии и его безопасную эксплуатацию',
          'Рекомендуемую штатную расстановку обслуживающего персонала',
          'Инструкцию по действию персонала в случае пожара',
        ],
        correctAnswers: [
          'Ведомость комплекта запасных изделий для пусконаладочных работ',
        ],
      },
      {
        code: '63699020',
        text:
          'В каком случае допускается пуск эскалатора с пассажирами на полотне с пульта дистанционного управления?',
        answers: [
          'Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске',
          'Ни в каком случае',
          'Если исключен пуск эскалатора в обратном направлении',
          'Если установлены несамовозвратные выключатели для экстренной остановки эскалатора',
        ],
        correctAnswers: [
          'Если обеспечена возможность наблюдения за пассажирами на эскалаторе или обеспечена переговорная связь с персоналом, находящимся у эскалатора, с выдачей информации, предупреждающей пассажиров о пуске',
        ],
      },
      {
        code: '63699021',
        text: 'Какое требование к управлению эскалатором указано неверно?',
        answers: [
          'Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение',
          'Управление эскалатором, оборудованным системой дистанционного управления, должен осуществлять дежурный оператор у эскалатора с пульта, установленного в кабине персонала, при обеспечении постоянного визуального наблюдения за пассажирами на лестничном полотне',
          'В зоне нижней площадки схода эскалатора необходимо обеспечивать постоянное наблюдение за пассажирами, находящимися на лестничном полотне',
          'Работник, осуществляющий наблюдение за пассажирами на эскалаторе, обязан останавливать эскалатор в случае падения пассажира или возникновения опасности нанесения травм пассажирам',
        ],
        correctAnswers: [
          'Устанавливать пульт управления эскалатором в отдельно вынесенное помещение допускается только для эскалаторов с высотой подъема от 6 м, при этом должны быть предусмотрены видеокамеры по наклонному ходу, на верхних и нижних площадках, с интеграцией изображений на мониторы в данное помещение',
        ],
      },
      {
        code: '63699022',
        text:
          'Какая величина замедления установлена для лестничного полотна эскалатора, загруженного максимальной эксплуатационной нагрузкой, при нарушении кинематической связи между приводом и главным валом и торможении аварийным тормозом?',
        answers: [
          'Не более 2 м/с²',
          'Не более 2,5 м/с²',
          'Не более 1,5 м/с²',
          'Не более 1 м/с²',
        ],
        correctAnswers: ['Не более 2 м/с²'],
      },
      {
        code: '63699023',
        text:
          'В каком объеме должны проводиться осмотр и проверка эскалатора при вводе его в эксплуатацию после капитального ремонта?',
        answers: [
          'Технического освидетельствования',
          'Планового технического осмотра',
          'Экспертизы промышленной безопасности',
        ],
        correctAnswers: ['Технического освидетельствования'],
      },
      {
        code: '63699024',
        text:
          'В каком случае допускается эксплуатация эскалатора метрополитена?',
        answers: [
          'При отсутствии трещин в металлоконструкции и элементах оборудования',
          'При истекшем сроке технического освидетельствования',
          'При условиях окружающей среды, не соответствующих требованиям эксплуатационной документации',
          'При отсутствии персонала, прошедшего проверку знаний в установленном порядке',
        ],
        correctAnswers: [
          'При отсутствии трещин в металлоконструкции и элементах оборудования',
        ],
      },
      {
        code: '63699025',
        text:
          'Где должна быть сделана запись о выполнении ежедневного контроля за состоянием эскалатора?',
        answers: [
          'В журнале суточного учета работы эскалаторов',
          'В паспорте эскалатора',
          'В акте технической готовности эскалатора',
          'В сменном журнале',
        ],
        correctAnswers: ['В журнале суточного учета работы эскалаторов'],
      },
      {
        code: '63699026',
        text:
          'Сколько должна составлять общая тормозная нагрузка для обеспечения пути разгона и торможения лестничного полотна при испытаниях тормозной системы эскалатора?',
        answers: [
          'Не более 400 кг на ступень',
          'Не более 350 кг на ступень',
          'Не более 300 кг на ступень',
          'Не более 250 кг на ступень',
        ],
        correctAnswers: ['Не более 300 кг на ступень'],
      },
      {
        code: '63699027',
        text: 'Какие данные не указываются в паспорте эскалатора?',
        answers: [
          'Квалификация представителя организации, выполнившей монтаж',
          'Характеристика поручней',
          'Характеристика тормозов',
          'Род электрического тока и напряжение',
        ],
        correctAnswers: [
          'Квалификация представителя организации, выполнившей монтаж',
        ],
      },
      {
        code: '63699028',
        text:
          'С какой скоростью должны двигаться, а затем ускоряться эскалаторы, работающие в режиме ожидания, при пересечении пассажиром гребенки?',
        answers: [
          'Двигаться - не менее 20% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,5 м/с²',
          'Двигаться - не менее 15% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,5 м/с²',
          'Двигаться - 20% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,7 м/с²',
          'Двигаться - 15% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,6 м/с²',
        ],
        correctAnswers: [
          'Двигаться - не менее 20% номинальной скорости, ускоряться - до номинальной скорости с ускорением не более 0,5 м/с²',
        ],
      },
      {
        code: '63699029',
        text:
          'В каком случае на балюстраде эскалатора разрешается установка решеток для громкоговорящей связи, осветителей и розеток?',
        answers: [
          'По согласованию с разработчиком эскалатора',
          'Установка таких элементов не допускается',
          'По согласованию с организацией-изготовителем',
          'По согласованию с Ростехнадзором',
        ],
        correctAnswers: ['По согласованию с разработчиком эскалатора'],
      },
      {
        code: '63699030',
        text:
          'В соответствии с какими нормативными документами должна осуществляться эксплуатация эскалатора?',
        answers: [
          'В соответствии с Правилами безопасности эскалаторов в метрополитенах и эксплуатационной документацией',
          'Только в соответствии с эксплуатационной документацией',
          'В соответствии с проектной документацией',
          'Только в соответствии с Правилами безопасности эскалаторов в метрополитенах',
        ],
        correctAnswers: [
          'В соответствии с Правилами безопасности эскалаторов в метрополитенах и эксплуатационной документацией',
        ],
      },
      {
        code: '63699031',
        text:
          'Какой привод эскалатора не может использоваться для обеспечения ремонтной скорости при выполнении монтажных и демонтажных работ и техническом обслуживании эскалатора?',
        answers: [
          'Ремонтный привод',
          'Главный привод',
          'Отдельный вспомогательный привод',
        ],
        correctAnswers: ['Ремонтный привод'],
      },
      {
        code: '63699032',
        text:
          'В каком случае допускается пуск эскалатора с пассажирами с любого пульта управления в обратном направлении?',
        answers: [
          'В случаях, обоснованных проектом',
          'В аварийных ситуациях',
          'Не допускается ни в каком случае',
          'По решению технического руководителя',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63699033',
        text:
          'Как должны быть выполнены ступени по наклонной части эскалаторного тоннеля в проходах между эскалаторами, а также между крайним эскалатором и строительными конструкциями?',
        answers: [
          'Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона',
          'Шириной не менее 400 мм и высотой не более 1500 мм с постоянным углом наклона',
          'Шириной не менее 250 мм и высотой не более 250 мм с постоянным углом наклона',
          'Шириной не менее 300 мм и высотой не более 300 мм с постоянным углом наклона',
        ],
        correctAnswers: [
          'Шириной не менее 350 мм и высотой не более 200 мм с постоянным углом наклона',
        ],
      },
      {
        code: '63699034',
        text:
          'Какие из перечисленных конструкций относятся к ответственным (расчетным) сварным конструкциям?',
        answers: [
          'Только каркас ступени',
          'Только входная площадка',
          'Только рычаги и рамы тормозов',
          'Только конструкции подъемно-транспортного оборудования машинного помещения',
          'Все перечисленные конструкции',
        ],
        correctAnswers: ['Все перечисленные конструкции'],
      },
      {
        code: '63699035',
        text:
          'Кто принимает решение о вводе в эксплуатацию эскалатора метрополитена?',
        answers: [
          'Лицо, ответственное за осуществление производственного контроля',
          'Лицо, ответственное за содержание эскалатора в исправном состоянии',
          'Лицо, ответственное за безопасную эксплуатацию эскалатора',
        ],
        correctAnswers: [
          'Лицо, ответственное за осуществление производственного контроля',
        ],
      },
      {
        code: '63699036',
        text:
          'Кто не входит в состав комиссии, принимающей решение о возможности ввода эскалатора в эксплуатацию?',
        answers: [
          'Уполномоченный представитель страховой организации',
          'Уполномоченный представитель организации, выполнившей пусконаладочные работы',
          'Уполномоченный представитель организации - изготовителя эскалатора',
          'Уполномоченный представитель эксплуатирующей организации',
          'Уполномоченный представитель организации, выполнившей монтаж эскалатора',
        ],
        correctAnswers: ['Уполномоченный представитель страховой организации'],
      },
      {
        code: '63699037',
        text:
          'Выберите допустимое значение остаточной деформации, измеренной у поверхности настила, после испытаний ступеней эскалатора.',
        answers: [
          'Не более 4 мм',
          'Не более 4,5 мм',
          'Не более 5 мм',
          'Не более 5,5 мм',
        ],
        correctAnswers: ['Не более 4 мм'],
      },
      {
        code: '63699038',
        text: 'Кто осуществляет пуск эскалатора в работу?',
        answers: [
          'Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией',
          'Работник, ответственный за осуществление производственного контроля в эксплуатирующей организации',
          'Работник, ответственный за содержание эскалаторов в исправном состоянии',
          'Дежурный оператор',
        ],
        correctAnswers: [
          'Машинист эскалатора или работник, имеющий право управления эскалатором в соответствии с производственной инструкцией',
        ],
      },
      {
        code: '63699039',
        text:
          'Каким образом необходимо проводить обкатку модернизированного или реконструированного эскалатора, или эскалатора после проведенного капитального (капитально-восстановительного) ремонта?',
        answers: [
          'В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону',
          'В течение 24 часов непрерывной работы от главного привода, по 12 часов в каждую сторону',
          'В течение 48 часов непрерывной работы от главного привода, по 24 часа в каждую сторону',
          'В течение 6 часов непрерывной работы от главного привода, по 2 часа в каждую сторону',
        ],
        correctAnswers: [
          'В течение 12 часов непрерывной работы от главного привода, по 6 часов в каждую сторону',
        ],
      },
      {
        code: '63699040',
        text:
          'Какую величину составляет минимальное расстояние от холостой ветви поручня эскалатора до вспомогательных коммуникаций, не относящихся к эскалаторам?',
        answers: ['500 мм', '300 мм', '200 мм', '150 мм'],
        correctAnswers: ['200 мм'],
      },
      {
        code: '63699041',
        text:
          'Каким должно быть напряжение цепей штепсельных розеток для питания переносных ламп?',
        answers: [
          'Не более 50 В',
          'Не более 110 В',
          'Не более 127 В',
          'Не более 220 В',
        ],
        correctAnswers: ['Не более 50 В'],
      },
      {
        code: '63699042',
        text:
          'Каким образом должны быть устроены входные вертикальные лестницы натяжных камер эскалатора?',
        answers: [
          'Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм',
          'Должны быть шириной не менее 500 мм и иметь расстояние между ступенями не более 250 мм, шаг ступеней должен быть выдержан по всей высоте лестницы',
          'Должны быть шириной не менее 400 мм и иметь расстояние между ступенями не более 350 мм, шаг ступеней должен быть выдержан по всей высоте лестницы',
          'Должны быть шириной не менее 300 мм и иметь расстояние между ступенями не более 150 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 100 мм',
        ],
        correctAnswers: [
          'Должны быть шириной не менее 600 мм и иметь расстояние между ступенями не более 300 мм, шаг ступеней должен быть выдержан по всей высоте лестницы. Ступени вертикальной лестницы должны отстоять от стен и других строительных конструкций не менее чем на 150 мм',
        ],
      },
      {
        code: '63699043',
        text:
          'Какое из перечисленных требований к определению фактического запаса прочности тяговой цепи указано верно?',
        answers: [
          'Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке',
          'Во время испытаний цепи необходимо доводить до разрушения при любой нагрузке',
          'При перемещении натяжного устройства в сторону привода или в обратном направлении на расстояние не более 50 мм эскалатор должен останавливаться',
          'После испытаний допускается остаточная деформация цепи или распрессовка деталей цепи',
        ],
        correctAnswers: [
          'Каждый отрезок цепи должен быть проверен на стенде под нагрузкой, превышающей в два раза наибольшее расчетное натяжение при нагрузке',
        ],
      },
      {
        code: '63699044',
        text:
          'Что запрещается организации, эксплуатирующей ОПО, на котором используется эскалатор, в целях обеспечения безопасных условий его эксплуатации?',
        answers: [
          'Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО',
          'Обеспечивать специалистов должностными инструкциями, нормативными правовыми актами, устанавливающими требования промышленной безопасности, правилами ведения работ на опасном производственном объекте (ОПО)',
          'Назначать лиц, ответственных за содержание эскалаторов в исправном состоянии и за безопасную эксплуатацию',
          'Организовывать проведение периодических осмотров, технического обслуживания и ремонтов эскалаторов',
          'Назначать лиц, ответственных за осуществление производственного контроля за соблюдением требований промышленной безопасности при эксплуатации эскалатора',
        ],
        correctAnswers: [
          'Отступать от требований конструкторской документации в процессе монтажа, ремонта, модернизации и реконструкции эскалаторов без согласования с организацией-изготовителем или организацией - разработчиком данной конструкторской документации и владельцем ОПО',
        ],
      },
      {
        code: '63699045',
        text:
          'Каким должно быть расстояние по вертикали от уровня настила ступеней эскалатора до потолка галереи, тоннеля или выступающих частей (балок, архитектурных украшений, осветительной арматуры) по всей длине и ширине эскалатора по наружным кромкам поручня?',
        answers: [
          'Не менее 2300 мм',
          'Не менее 2100 мм',
          'Не менее 2000 мм',
          'Не менее 2150 мм',
        ],
        correctAnswers: ['Не менее 2300 мм'],
      },
      {
        code: '63699046',
        text: 'Кем проводятся осмотр, проверка и испытания эскалатора?',
        answers: [
          'Организацией, выполнившей монтаж или реконструкцию эскалатора',
          'Эксплуатирующей организацией',
          'Организацией-изготовителем',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Организацией, выполнившей монтаж или реконструкцию эскалатора',
        ],
      },
      {
        code: '63699047',
        text:
          'Какой документ составляется по окончании устранения неисправностей, выявленных в ходе осмотра, проверок и испытаний эскалаторов?',
        answers: [
          'Акт технической готовности',
          'Декларация пожарной безопасности',
          'Акт выполненных работ',
          'Свидетельство об обкатке',
        ],
        correctAnswers: ['Акт технической готовности'],
      },
      {
        code: '63699048',
        text:
          'Какое положение по эксплуатации эскалатора не соответствует требованиям Правил безопасности эскалаторов в метрополитенах?',
        answers: [
          'Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора',
          'Ввод эскалатора в эксплуатацию после капитального ремонта, а также по окончании срока работы, установленного предыдущим техническим освидетельствованием, осуществляет владелец ОПО при наличии квалифицированного персонала или специализированная организация после осмотра и проверки в объеме технического освидетельствования',
          'Техническое обслуживание должно включать в себя осмотр, смазку, замер износа, очистку, регулировку и замену узлов и деталей (по результатам осмотра и замеров)',
          'Техническое обслуживание проводится в сроки, устанавливаемые в руководстве по эксплуатации или в инструкции по техническому обслуживанию',
        ],
        correctAnswers: [
          'Основанием для остановки на капитальный ремонт эскалатора является только фактическое техническое состояние эскалатора',
        ],
      },
      {
        code: '63699049',
        text:
          'При перемещении на какое расстояние натяжного устройства в сторону привода или в обратном направлении эскалатор должен останавливаться?',
        answers: [
          'Не более 30 мм',
          'Не более 25 мм',
          'Не более 20 мм',
          'Не нормируется',
        ],
        correctAnswers: ['Не более 30 мм'],
      },
      {
        code: '63699050',
        text:
          'В соответствии с каким документом должно проводиться техническое обслуживание и ремонт эскалатора?',
        answers: [
          'В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации',
          'В соответствии с инструкцией по охране труда',
          'В соответствии с рекомендациями организации, выполнившей монтаж эскалатора',
          'В соответствии с рекомендациями специализированной организации, проводившей экспертизу промышленной безопасности',
        ],
        correctAnswers: [
          'В соответствии с руководством по эксплуатации эскалатора организации-изготовителя или специализированной организации',
        ],
      },
      {
        code: '63699051',
        text:
          'С какой периодичностью должна проводиться проверка знаний персонала, обслуживающего эскалаторы, в объеме производственных инструкций?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63699052',
        text:
          'Какой допускается зазор в стыках щитов и фартуков балюстрады эскалатора?',
        answers: [
          'Не более 4 мм',
          'Не более 5 мм',
          'Не более 6 мм',
          'Не более 7 мм',
        ],
        correctAnswers: ['Не более 4 мм'],
      },
      {
        code: '63699053',
        text:
          'На какие процессы в области промышленной безопасности на ОПО не распространяется действие Федеральных норм и правил "Правила безопасности эскалаторов в метрополитенах"?',
        answers: [
          'Связанные с перевозкой и хранением',
          'Связанные с проведением экспертизы промышленной безопасности',
          'Связанные с эксплуатацией, капитальным ремонтом, техническим перевооружением, консервацией и ликвидацией',
          'Связанные с изготовлением, монтажом, наладкой, обслуживанием и ремонтом эскалаторов',
        ],
        correctAnswers: ['Связанные с перевозкой и хранением'],
      },
      {
        code: '63699054',
        text:
          'Каким требованиям должен соответствовать персонал, допущенный к управлению эскалатором в метрополитене?',
        answers: [
          'Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний',
          'Персонал должен уметь оказывать медицинскую помощь пострадавшим',
          'Персонал должен быть не моложе 17 лет',
          'Персонал должен иметь высшее образование',
        ],
        correctAnswers: [
          'Персонал должен быть квалифицированным, достигшим возраста 18 лет, не имеющим медицинских противопоказаний и прошедшим проверку знаний',
        ],
      },
      {
        code: '63699055',
        text:
          'Сколько должна составлять скорость движения поручневого устройства эскалатора?',
        answers: [
          'Не должна отличаться от скорости движения лестничного полотна более чем на 2%',
          'Не должна отличаться от скорости движения лестничного полотна более чем на 3%',
          'Не должна отличаться от скорости движения лестничного полотна более чем на 4%',
          'Не должна отличаться от скорости движения лестничного полотна более чем на 5%',
        ],
        correctAnswers: [
          'Не должна отличаться от скорости движения лестничного полотна более чем на 2%',
        ],
      },
      {
        code: '63699056',
        text:
          'Какой должна быть максимальная стрела упругого прогиба под действием нагрузки  для пролетных металлоконструкций, включая плиты входных площадок, эскалаторов?',
        answers: [
          '1/750 пролета',
          '1/1000 пролета',
          '1/1500 пролета',
          '1/2000 пролета',
        ],
        correctAnswers: ['1/750 пролета'],
      },
      {
        code: '63699057',
        text:
          'В каком случае эскалатор метрополитена не подлежит экспертизе промышленной безопасности, если техническим регламентом не установлена иная форма оценки соответствия эскалатора?',
        answers: [
          'При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет',
          'После проведения работ, связанных с изменением конструкции, заменой материала несущих элементов эскалатора, либо восстановительного ремонта после аварии на ОПО, в результате которых был поврежден эскалатор',
          'По истечении срока службы или при превышении количества циклов нагрузки эскалатора, установленных организацией - изготовителем',
          'До начала применения на опасном производственном объекте (ОПО)',
        ],
        correctAnswers: [
          'При отсутствии в технической документации данных о сроке службы эскалатора, если фактический срок его службы составляет 10 лет',
        ],
      },
      {
        code: '63699058',
        text: 'Какие требования предъявляются к входным площадкам эскалатора?',
        answers: [
          'Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки',
          'Освещенность входных площадок на уровне пола должна быть не менее 30 лк',
          'Освещенность у входных площадок, за исключением гребенок, должна соответствовать освещенности, создаваемой освещением в этой зоне',
          'Поверхность входных площадок должна быть гладкой',
        ],
        correctAnswers: [
          'Конструкция входной площадки должна иметь устройство, обеспечивающее правильное направление настила ступени относительно зубьев гребенки',
        ],
      },
      {
        code: '63699059',
        text:
          'В какие сроки должно проводиться периодическое техническое освидетельствование эскалатора после ввода его в эксплуатацию?',
        answers: [
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 24 месяца',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63699060',
        text:
          'При каком замедлении лестничного полотна при торможении допускается совместное срабатывание дополнительного (аварийного) и рабочего тормозов?',
        answers: [
          'Не более 1 м/с²',
          'Не более 1,5 м/с²',
          'Не более 2 м/с²',
          'Не более 2,5 м/с²',
        ],
        correctAnswers: ['Не более 1 м/с²'],
      },
      {
        code: '63699061',
        text: 'Для чего предназначен рабочий тормоз эскалатора?',
        answers: [
          'Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления',
          'Для регулирования скорости движения лестничного полотна',
          'Для остановки лестничного полотна эскалатора при срабатывании блокировки скорости и самопроизвольном изменении направления движения лестничного полотна эскалатора, который работает на подъем',
          'Для остановки эскалатора, работающего на спуск, в случае увеличения скорости лестничного полотна на 40% номинальной скорости',
        ],
        correctAnswers: [
          'Для остановки лестничного полотна при каждом отключении главного или вспомогательного привода, а также при обесточивании цепи управления',
        ],
      },
      {
        code: '63699062',
        text:
          'Какую величину, независимо от пассажирской нагрузки, не должно превышать замедление лестничного полотна эскалатора при торможении рабочими тормозами при работе на спуск?',
        answers: ['0,60 м/с²', '1,00 м/с²', '0,75 м/с²', '0,90 м/с²'],
        correctAnswers: ['0,60 м/с²'],
      },
      {
        code: '63699063',
        text:
          'Какая устанавливается ширина свободного прохода в машинном помещении с одной стороны для эскалаторов с высотой подъема до 15 м при установке трех эскалаторов?',
        answers: ['До 750 мм', 'До 850 мм', 'До 950 мм'],
        correctAnswers: ['До 750 мм'],
      },
      {
        code: '63699064',
        text:
          'Какие нормативные документы регламентируют порядок и объем работ периодического технического освидетельствования эскалаторов в метрополитенах?',
        answers: [
          'Руководство по эксплуатации эскалатора и Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
          'Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах" и технический регламент "О безопасности машин и оборудования"',
          'Руководство (инструкция) по монтажу, пуску, регулированию и обкатке и обоснование безопасности и руководство (инструкция) по ремонту',
          'Технический регламент "О безопасности машин и оборудования" и руководство (инструкция) по монтажу, пуску, регулированию',
        ],
        correctAnswers: [
          'Руководство по эксплуатации эскалатора и Федеральные нормы и правила в области промышленной безопасности "Правила безопасности эскалаторов в метрополитенах"',
        ],
      },
      {
        code: '63699065',
        text:
          'Какое из перечисленных эскалаторных помещений может не предусматриваться?',
        answers: [
          'Только машинное помещение',
          'Только бытовое помещение',
          'Только помещение для хранения запасных частей',
          'Должны быть предусмотрены все перечисленные помещения',
        ],
        correctAnswers: [
          'Должны быть предусмотрены все перечисленные помещения',
        ],
      },
      {
        code: '63699066',
        text:
          'Какой должна быть длина горизонтальных участков ступеней в зоне входных площадок при высоте транспортирования пассажиров более 6 м и при номинальной скорости более 0,5 м/с (но не более 0,65 м/с для эскалаторов)?',
        answers: [
          'Не менее 1200 мм',
          'Не менее 1000 мм',
          'Не менее 400 мм',
          'Не менее 600 мм',
        ],
        correctAnswers: ['Не менее 1200 мм'],
      },
      {
        code: '63699067',
        text:
          'Сколько должна составлять норма межремонтного пробега для капитального ремонта, если она не установлена изготовителем эскалатора?',
        answers: [
          'Не более 150 000 км',
          'Не более 175 000 км',
          'Не более 160 000 км',
          'Не более 165 000 км',
        ],
        correctAnswers: ['Не более 150 000 км'],
      },
      {
        code: '63699068',
        text:
          'Какой должна быть освещенность входных площадок эскалатора на уровне пола, измеренная по оси лестничного полотна?',
        answers: [
          'Не менее 50 лк',
          'Не менее 45 лк',
          'Не менее 40 лк',
          'Не менее 35 лк',
        ],
        correctAnswers: ['Не менее 50 лк'],
      },
      {
        code: '63699069',
        text:
          'Какие действия необходимо предпринять при возникновении неисправностей, представляющих опасность при пользовании эскалатором?',
        answers: [
          'Эскалатор должен быть остановлен, а пассажиры с него удалены',
          'Эскалатор должен быть остановлен, пассажиры не должны покидать его до выяснения причины неисправности',
          'Эскалатор должен по возможности произвести спуск (подъем) оставшихся на нем пассажиров и только после этого должен быть остановлен',
        ],
        correctAnswers: [
          'Эскалатор должен быть остановлен, а пассажиры с него удалены',
        ],
      },
      {
        code: '63699070',
        text:
          'В каком из приведенных случаев дополнительный (аварийный) тормоз должен останавливать эскалатор?',
        answers: [
          'Только в случае увеличения номинальной скорости движения лестничного полотна более чем в 1,2 раза',
          'Только в случае самопроизвольного изменения направления движения лестничного полотна работающего на подъем эскалатора',
          'Только в случае срабатывания блокировки превышения допустимых тормозных путей или расчетного времени рабочего торможения более чем на 20%',
          'В любом из приведенных случаев',
        ],
        correctAnswers: ['В любом из приведенных случаев'],
      },
      {
        code: '63699071',
        text:
          'Какая организация обязана обеспечить содержание эскалатора в исправном состоянии путем организации обслуживания, ремонта, технического освидетельствования?',
        answers: [
          'Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации',
          'Завод-изготовитель',
          'Проектная организация',
          'Организация, выполнившая монтаж эскалатора',
        ],
        correctAnswers: [
          'Организация, эксплуатирующая эскалатор, самостоятельно либо с привлечением специализированной организации',
        ],
      },
      {
        code: '63699072',
        text:
          'Какие сведения не содержат эксплуатационные документы эскалатора метрополитена?',
        answers: [
          'Программу специальной подготовки персонала',
          'Указания по монтажу или сборке, наладке или регулировке',
          'Действия персонала по ликвидации последствий инцидента или аварии',
          'Возможные ошибочные действия (бездействие) персонала, приводящие к инциденту или аварии',
          'Указания по выводу из эксплуатации перед утилизацией',
        ],
        correctAnswers: ['Программу специальной подготовки персонала'],
      },
      {
        code: '63699073',
        text:
          'Для чего не предназначается дополнительный (аварийный) тормоз эскалатора?',
        answers: [
          'Для остановки лестничного полотна эскалатора при отключении электродвигателя',
          'Для остановки эскалатора при увеличении номинальной скорости движения лестничного полотна более чем в 1,2 раза',
          'Для остановки эскалатора при срабатывании блокировки превышения допустимых тормозных путей или расчетного времени рабочего торможения более чем на 20%',
          'Для остановки эскалатора при самопроизвольном изменении направления движения лестничного полотна работающего на подъем эскалатора',
        ],
        correctAnswers: [
          'Для остановки лестничного полотна эскалатора при отключении электродвигателя',
        ],
      },
      {
        code: '63699074',
        text:
          'Какая величина ускорения, независимо от пассажирской нагрузки, установлена для лестничного полотна эскалатора?',
        answers: [
          'Не более 0,75 м/с²',
          'Не более 0,85 м/с²',
          'Не более 0,95 м/с²',
          'Не более 0,80 м/с²',
        ],
        correctAnswers: ['Не более 0,75 м/с²'],
      },
      {
        code: '63699075',
        text:
          'В каком случае допускается применение посадок с гарантированным натягом без дополнительного крепления в составных частях привода эскалатора, передающих крутящий момент?',
        answers: [
          'Если это соответствует требованиям проектной (конструкторской) документации',
          'Если обеспечен контроль выполнения технологических операций',
          'Если это соответствует требованиям Правил безопасности эскалаторов в метрополитенах',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63699076',
        text:
          'В каких целях не проводится полное техническое освидетельствование эскалатора после изготовления (монтажа), реконструкции, модернизации?',
        answers: [
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
          'В целях подтверждения соответствия эксплуатации, содержания и обслуживания эскалатора требованиям Правил безопасности эскалаторов в метрополитенах',
          'В целях подтверждения технического состояния эскалатора, обеспечивающего его безопасное использование по назначению',
          'В целях подтверждения соответствия эскалатора и его устройства требованиям Правил безопасности эскалаторов в метрополитенах и паспортным данным',
        ],
        correctAnswers: [
          'В целях подтверждения соответствия эскалатора и его устройства требованиям технического регламента "О безопасности машин и оборудования"',
        ],
      },
      {
        code: '63699077',
        text:
          'В каком случае повторная (внеочередная) проверка знаний персонала, обслуживающего эскалаторы, не проводится?',
        answers: [
          'При перерыве в работе по должности 3 месяца',
          'По требованию лица, ответственного за осуществление производственного контроля',
          'При переводе на обслуживание эскалаторов других типов',
          'При переходе из одной организации в другую',
        ],
        correctAnswers: ['При перерыве в работе по должности 3 месяца'],
      },
      {
        code: '63699078',
        text:
          'Какое должно быть общее время электрического торможения до срабатывания рабочего тормоза эскалатора?',
        answers: [
          'Не более 4 с',
          'Не более 4,5 с',
          'Не более 5 с',
          'Не более 5,5 с',
        ],
        correctAnswers: ['Не более 4 с'],
      },
      {
        code: '63699079',
        text:
          'Какие требования к лестничному полотну эскалатора указаны неверно?',
        answers: [
          'Допускается применение пружин растяжения для натяжения цепей',
          'Для обеспечения постоянного натяжения лестничного полотна предусматривается натяжное устройство',
          'Лестничное полотно эскалатора должно состоять из ступеней, соединенных с помощью не менее чем 2 тяговых цепей, причем с каждой стороны ступени должно быть не менее 1 тяговой цепи',
          'Рабочая поверхность ступени выполняется в виде выступов и впадин с расположением их вдоль оси эскалатора',
        ],
        correctAnswers: [
          'Допускается применение пружин растяжения для натяжения цепей',
        ],
      },
      {
        code: '63699080',
        text:
          'Допускаются ли при обкатке вновь установленного эскалатора остановки?',
        answers: [
          'Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут',
          'Допускаются остановки для наладки и регулирования общей продолжительностью не более 120 минут',
          'Допускаются остановки только для регулирования после проведенного капитально-восстановительного ремонта',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются остановки для наладки и регулирования общей продолжительностью не более 90 минут',
        ],
      },
      {
        code: '63699081',
        text:
          'В каком из перечисленных документов должен содержаться перечень неисправностей эскалатора, а также других причин, при которых эксплуатация эскалатора запрещается? Выберите 2 варианта ответа.',
        answers: [
          'В руководстве по эксплуатации или в инструкции по техническому обслуживанию',
          'В перечне критических отказов эскалатора',
          'В перечне быстро изнашиваемых элементов эскалатора',
          'В производственной инструкции машиниста эскалатора',
        ],
        correctAnswers: [
          'В руководстве по эксплуатации или в инструкции по техническому обслуживанию',
          'В производственной инструкции машиниста эскалатора',
        ],
      },
      {
        code: '63699082',
        text:
          'Какие требования не должны устанавливаться в эксплуатационных документах эскалатора метрополитена?',
        answers: [
          'Требования к изготовлению',
          'Требования к утилизации',
          'Требования к условиям перевозки',
          'Требования к условиям хранения',
        ],
        correctAnswers: ['Требования к изготовлению'],
      },
      {
        code: '63699083',
        text: 'Какие требования к эскалаторам указаны неверно?',
        answers: [
          'Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения',
          'Составные части эскалатора должны быть защищены от коррозии в соответствии с условиями их эксплуатации, хранения и транспортирования в части воздействия климатических факторов внешней среды',
          'Неподвижные оси, служащие опорой для блоков, роликов и прочих вращающихся деталей, должны быть надежно укреплены и снабжены приспособлениями, обеспечивающими их фиксацию',
          'К механизмам, предохранительным устройствам, электрооборудованию, требующим обслуживания, должен быть обеспечен безопасный доступ',
        ],
        correctAnswers: [
          'Легкодоступные части эскалатора, находящиеся в движении, должны быть закрыты ограждениями, обеспечивающими безопасность обслуживающего персонала. Запрещается устанавливать съемные ограждения',
        ],
      },
      {
        code: '63699084',
        text:
          'Кто из перечисленных лиц может оказывать первую помощь при наличии соответствующей подготовки и/или навыков при эксплуатации эскалатора?',
        answers: [
          'Только машинист',
          'Только мастер',
          'Только дежурный у эскалатора',
          'Все перечисленные лица',
        ],
        correctAnswers: ['Все перечисленные лица'],
      },
      {
        code: '63699085',
        text:
          'Какой должен быть расчетный запас прочности тяговой цепи эскалатора?',
        answers: ['Не менее 9', 'Не менее 8', 'Не менее 7', 'Не менее 6'],
        correctAnswers: ['Не менее 7'],
      },
      {
        code: '63699086',
        text:
          'Какой минимальный коэффициент запаса прочности должен быть для тяговых и приводных цепей?',
        answers: ['7', '8', '9', '6', '5'],
        correctAnswers: ['7'],
      },
      {
        code: '63699087',
        text:
          'Какой допускается максимальный упругий прогиб фартука балюстрады под воздействием нагрузки 1500 H, приложенной между опорами фартука перпендикулярно к его поверхности на площади 25 см²?',
        answers: ['4 мм', '5 мм', '3 мм', '7 мм'],
        correctAnswers: ['4 мм'],
      },
      {
        code: '63699088',
        text: 'Какой документ составляется по результатам работы комиссии?',
        answers: [
          'Акт о возможности ввода в эксплуатацию эскалатора',
          'Акт технической готовности',
          'Акт приемо-сдаточных испытаний',
          'Свидетельство об обкатке',
        ],
        correctAnswers: ['Акт о возможности ввода в эксплуатацию эскалатора'],
      },
      {
        code: '63699089',
        text:
          'Каким образом должна проводиться обкатка каждого вновь установленного эскалатора?',
        answers: [
          'На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания',
          'На месте применения в течение 24 часов непрерывной работы от главного привода, по 12 часов в каждом направлении от постоянных источников питания',
          'На месте применения в течение 12 часов непрерывной работы от главного привода, по 6 часов в каждом направлении от постоянных источников питания',
          'На месте применения в течение 18 часов непрерывной работы от главного привода, по 9 часов в каждом направлении от постоянных источников питания',
        ],
        correctAnswers: [
          'На месте применения в течение 48 часов непрерывной работы от главного привода, по 24 часа в каждом направлении от постоянных источников питания',
        ],
      },
      {
        code: '63699090',
        text:
          'Какая ремонтная скорость движения установлена для лестничного полотна эскалатора?',
        answers: [
          'Не более 0,1 м/с',
          'Не более 0,04 м/с',
          'Не более 0,08 м/с',
          'Не более 0,06 м/с',
        ],
        correctAnswers: ['Не более 0,04 м/с'],
      },
      {
        code: '63699091',
        text:
          'Что не относится к основным техническим данным и характеристикам, указываемым в паспорте эскалатора?',
        answers: [
          'Характеристика помещений для установки эскалатора',
          'Скорость движения лестничного полотна, м/с',
          'Тип передачи к главному валу эскалатора',
          'Фактическая производительность, человеко-час',
        ],
        correctAnswers: ['Характеристика помещений для установки эскалатора'],
      },
      {
        code: '63699092',
        text:
          'Какой нагрузкой нагружают две ступени эскалатора от изготавливаемой партии для проверки фактического запаса прочности?',
        answers: [
          'Не менее 5qмэ',
          'Не менее 4,5qмэ',
          'Не менее 4qмэ',
          'Не менее 3,5qмэ',
        ],
        correctAnswers: ['Не менее 5qмэ'],
      },
      {
        code: '63699093',
        text:
          'Когда машинисту (дежурному у эскалатора) необходимо проводить контроль за состоянием эскалатора?',
        answers: [
          'Ежедневно перед началом работы',
          '2 раза в смену - до начала и по окончании работы',
          '1 раз в 3 дня',
          '1 раз в неделю',
        ],
        correctAnswers: ['Ежедневно перед началом работы'],
      },
      {
        code: '63699094',
        text:
          'С кем владелец ОПО, на котором используется эскалатор в метрополитенах, должен согласовать изменения, вносимые при изготовлении, модернизации или реконструкции эскалатора в его конструкцию?',
        answers: [
          'С организацией-разработчиком или специализированной проектной организацией',
          'С организацией-изготовителем',
          'С монтажно-наладочной организацией',
          'Со всеми перечисленными организациями',
        ],
        correctAnswers: [
          'С организацией-разработчиком или специализированной проектной организацией',
        ],
      },
      {
        code: '63699095',
        text:
          'Какая устанавливается минимальная ширина свободного прохода между фундаментами или выступающими частями привода эскалатора и стенами машинного помещения (или оборудованием и коммуникациями, размещенными на стенах машинного помещения), торцевой стеной натяжной камеры (или оборудованием и коммуникациями, размещенными на стенах натяжной камеры)?',
        answers: ['900 мм', '800 мм', '700 мм', '500 мм'],
        correctAnswers: ['900 мм'],
      },
    ],
  },
  5: {
    63678: [
      {
        code: '63678000',
        text:
          'В каком случае для проведения огневых работ на временных местах допускается применение оборудования для кислородной резки металлов, работающего на жидком горючем (керосин, бензин)?',
        answers: [
          'Не допускается ни в каком случае',
          'При проведении работ в зданиях и сооружениях УОФ, имеющих пониженную пожароопасность и умеренную пожароопасность',
          'При технической невозможности проведения огневых работ с помощью электросварочного оборудования по решению главного инженера шахты (фабрики)',
          'Допускается в любом случае наравне с электросварочным оборудованием',
        ],
        correctAnswers: [
          'При технической невозможности проведения огневых работ с помощью электросварочного оборудования по решению главного инженера шахты (фабрики)',
        ],
      },
      {
        code: '63678001',
        text:
          'Какой длительности должен быть звуковой сигнал, подаваемый перед дистанционным пуском механизмов?',
        answers: [
          'Не менее 5 секунд',
          'Не менее 10 секунд',
          'Не менее 7 секунд',
          'Не менее 2 секунд',
        ],
        correctAnswers: ['Не менее 5 секунд'],
      },
      {
        code: '63678002',
        text:
          'Какой минимальный уклон должны иметь верхние горизонтальные плоскости зданий, сооружений и отдельных помещений фабрики, в которых предусмотрена уборка угольной пыли мокрым способом для обеспечения стока воды?',
        answers: ['5 %', '10 %', '15 %', '20 %'],
        correctAnswers: ['10 %'],
      },
      {
        code: '63678003',
        text:
          'Каково максимально допустимое процентное содержание кислорода в дымовых газах в пересчете на сухой газ должно поддерживаться в трактах сушильных установок для предотвращения взрыва при сушке сланцев?',
        answers: ['10 %', '16 %', '12 %', '20 %'],
        correctAnswers: ['16 %'],
      },
      {
        code: '63678004',
        text:
          'На какую минимальную высоту должны ограждаться места подвески контргрузов и канаты натяжных устройств конвейеров сеткой с ячейками размером не более 50 x 50 мм?',
        answers: ['1,2 м', '1,5 м', '2 м', '2,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63678005',
        text:
          'Какова минимально допустимая продолжительность вентиляции железнодорожной цистерны или емкости после обработки их паром или промывки горячей водой через верхний люк и охлаждения?',
        answers: ['5 минут', '15 минут', '30 минут', '40 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63678006',
        text:
          'Какой должна быть ширина проезжей части дороги для автосамосвалов грузоподъемностью до 10 т при двухполосном движении?',
        answers: [
          'Не менее 10 м',
          'Не менее 20 м',
          'Не менее 15 м',
          'Не менее 3 м',
        ],
        correctAnswers: ['Не менее 10 м'],
      },
      {
        code: '63678007',
        text:
          'Какой ширины устанавливаются противопожарные разрывы и проезды от основания штабеля углей до сгораемых зданий и сооружений?',
        answers: [
          'Не менее 20 м',
          'Не менее 17 м',
          'Не менее 12 м',
          'Не менее 10 м',
        ],
        correctAnswers: ['Не менее 20 м'],
      },
      {
        code: '63678008',
        text:
          'С какой периодичностью проводят проверку эффективности работы приточно-вытяжных систем?',
        answers: [
          'Два раза в год',
          'Один раз в три года',
          'Один раз в два года',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в три года'],
      },
      {
        code: '63678009',
        text:
          'В каких случаях должны проводиться режимные испытания (РИ) технологических схем обогащения и компоновок оборудования сушильных установок (СУ) (техническое диагностирование) фабрик?',
        answers: [
          'Только перед их вводом в эксплуатацию',
          'Только после реконструкции и (или) капитального ремонта',
          'Только при изменении сырьевой базы фабрики',
          'Только по графику не реже одного раза в два года в период эксплуатации',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63678010',
        text:
          'Какова максимально допустимая температура газов перед дымососами термических сушильных установок для каменных углей с выходом летучих веществ менее 35 %, антрацитов и полуантрацитов?',
        answers: ['90 °С', '110 °С', '120 °С', '130 °С'],
        correctAnswers: ['120 °С'],
      },
      {
        code: '63678011',
        text:
          'Что ежесменно контролируется на обогатительных фабриках, где установлен пылегазовый режим?',
        answers: [
          'Только содержание метана в воздухе помещений',
          'Только содержание пыли в воздухе помещений',
          'Только содержание CO и CO₂ в воздухе помещений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63678012',
        text:
          'Какие разделы не включает в себя Положение о системе управления промышленной безопасностью и охраной труда (СУПБиОТ)?',
        answers: [
          'Система управления охраной труда',
          'Проведение расследований причин инцидентов',
          'Система управления промышленной безопасностью',
          'Все перечисленные разделы',
        ],
        correctAnswers: ['Проведение расследований причин инцидентов'],
      },
      {
        code: '63678013',
        text:
          'Что из перечисленного не соответствует условиям погрузки горной массы в автомобили экскаваторами?',
        answers: [
          'Ожидающий погрузку автомобиль находится за пределами радиуса действия экскаваторного ковша и становится под погрузку только после разрешающего сигнала машиниста экскаватора',
          'Погрузка в кузов автомобиля производится только сзади или осуществляя перенос экскаваторного ковша над кабиной',
          'Высота падения груза должна быть минимально возможной и во всех случаях не превышать 5 м',
          'Запрещается односторонняя или сверхгабаритная загрузка, а также превышающая проектную грузоподъемность автомобиля',
        ],
        correctAnswers: [
          'Погрузка в кузов автомобиля производится только сзади или осуществляя перенос экскаваторного ковша над кабиной',
        ],
      },
      {
        code: '63678014',
        text:
          'При каком минимальном содержании оксида углерода в воздухе производственных помещений должны быть прекращены все работы и приняты меры по проветриванию загазованного производственного помещения?',
        answers: ['0,0017 %', '0,05 %', '1 %', '0,017 %'],
        correctAnswers: ['0,0017 %'],
      },
      {
        code: '63678015',
        text:
          'Каков минимально допустимый угол пересечения проезжей дороги с временными железнодорожными путями на переезде?',
        answers: ['20°', '30°', '45°', '55°'],
        correctAnswers: ['45°'],
      },
      {
        code: '63678016',
        text:
          'С какой периодичностью проводятся повторные испытания взрывчатости угольной пыли?',
        answers: [
          'Один раз в три года',
          'Один раз в два года',
          'Один раз в пять лет',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в три года'],
      },
      {
        code: '63678017',
        text:
          'В какие помещения допускается вход работников во время работы приточно-вытяжных систем?',
        answers: [
          'Запрещается вход во все перечисленные и другие подсобные помещения',
          'В вентиляционные камеры',
          'В увлажнительные камеры',
          'В калориферные камеры',
        ],
        correctAnswers: [
          'Запрещается вход во все перечисленные и другие подсобные помещения',
        ],
      },
      {
        code: '63678018',
        text:
          'В каком случае должны аварийно останавливаться в автоматическом режиме ленточный фильтр-пресс и вспомогательное оборудование?',
        answers: [
          'Только в случае снижения уровня пульпы в зумпфе до допустимого нижнего уровня',
          'Только в случае схода фильтровальной ленты',
          'Только в случае остановки привода фильтра-пресса',
          'Только в случае снижения давления воздуха от компрессора механизма натяжения фильтровальных сеток',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63678019',
        text:
          'Каковы максимально допустимые уклоны дорог на подходах к переезду на временных железнодорожных путях?',
        answers: ['30 промилле', '40 промилле', '50 промилле', '60 промилле'],
        correctAnswers: ['50 промилле'],
      },
      {
        code: '63678020',
        text:
          'Какие термометры используются для измерения температуры газов перед дымососами термических сушильных установок?',
        answers: [
          'Газовые термометры',
          'Механические термометры',
          'Оптические термометры (пирометры)',
          'Электронные термометры',
        ],
        correctAnswers: ['Электронные термометры'],
      },
      {
        code: '63678021',
        text:
          'С какой периодичностью должна производиться проверка, а при необходимости и промывка диспергационных решеток, бака рециркуляции, турбинок, шламоотводящих труб мокрых пылеуловителей?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже пяти раз в две недели',
          'Не реже трех раз в месяц',
          'По мере необходимости',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63678022',
        text:
          'Кто осуществляет руководство проведением учений по плану ликвидации аварий с работниками фабрики и подрядных организаций?',
        answers: [
          'Главный инженер фабрики',
          'Командир профессиональной аварийно-спасательной службы',
          'Представитель территориального органа Ростехнадзора',
          'Руководитель фабрики',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678023',
        text:
          'На какой максимальный срок разрабатывается план ликвидации аварий (ПЛА) на обогатительной фабрике?',
        answers: [
          'ПЛА разрабатывается на 3 года',
          'ПЛА разрабатывается на 5 лет',
          'ПЛА разрабатывается на 2 года',
          'ПЛА разрабатывается на 12 месяцев',
        ],
        correctAnswers: ['ПЛА разрабатывается на 5 лет'],
      },
      {
        code: '63678024',
        text: 'Что должен включать план ликвидации аварий (ПЛА)?',
        answers: [
          'Только оперативную часть',
          'Только распределение обязанностей между отдельными лицами, участвующими в ликвидации аварий, и порядок их действий',
          'Только правила поведения работников фабрики и подрядных организаций при аварии',
          'Только список должностных лиц и учреждений, которые должны быть немедленно извещены об аварии',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63678025',
        text:
          'На каком максимальном расстоянии одной трубы от другой производится установка труб в штабель по верхнему основанию штабеля в шахматном порядке?',
        answers: ['7 м', '12 м', '30 м', '25 м'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63678026',
        text:
          'В каких случаях запрещается применение газовой сварки в горных выработках, во взрывопожароопасных и пожароопасных надшахтных зданиях и сооружениях шахт (фабрик)?',
        answers: [
          'Только в случаях использования ацетилена',
          'Только в случаях использования пропан-бутана',
          'Только в случаях использования каких-либо горючих углеводородов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63678027',
        text:
          'Какова минимально допустимая емкость резервных площадок от полезной емкости складов для освежения угля длительного хранения, охлаждения разогревшегося и складирования некондиционных углей?',
        answers: ['5 - 10 %', '11 - 15 %', '3 - 4 %', '2 - 3 %'],
        correctAnswers: ['5 - 10 %'],
      },
      {
        code: '63678028',
        text:
          'Какие задачи должна указывать эксплуатирующая организация в подразделе "Задачи эксплуатирующей организации в области ПБ" при разработке положения о единой системе управления промышленной безопасностью и охраной труда для организаций по добыче (переработке) угля (горючих сланцев)?',
        answers: [
          'Управление основными производственными рисками',
          'Обеспечение противоаварийной устойчивости и готовности к локализации и ликвидации последствий аварии',
          'Выявление опасных технологий, участков, рабочих мест и предупреждение несчастных случаев',
          'Финансирование мероприятий по обеспечению безопасной эксплуатации опасных производственных объектов',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63678029',
        text:
          'При каких скоростях газового потока в горизонтальных газоходах с углом наклона менее 45° допускается их эксплуатация?',
        answers: [
          'При обеспечении в них скорости газового потока не менее 20 м/с, а для вновь проектируемых - 25 м/с',
          'При обеспечении в них скорости газового потока не менее 15 м/с, а для вновь проектируемых - 20 м/с',
          'При обеспечении в них скорости газового потока не менее 12 м/с, а для вновь проектируемых - 18 м/с',
          'При обеспечении в них скорости газового потока не менее 10 м/с, а для вновь проектируемых - 15 м/с',
        ],
        correctAnswers: [
          'При обеспечении в них скорости газового потока не менее 20 м/с, а для вновь проектируемых - 25 м/с',
        ],
      },
      {
        code: '63678030',
        text:
          'С какой периодичностью должен производиться отбор проб воздуха на запыленность и загазованность в зоне пребывания работников?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в 4 месяца',
          'Не реже двух раз в 9 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63678031',
        text:
          'При возникновении какой аварийной ситуации допускается отключение центрифуги под нагрузкой?',
        answers: [
          'Только при поломке узлов центрифуги',
          'Только при поломке или остановке аппаратов за центрифугой, следующих по технологической цепочке',
          'Только при необходимости предотвращения аварии или несчастного случая',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63678032',
        text:
          'Какой должна быть ширина проходов вдоль камер флотационных машин?',
        answers: [
          'Не менее 1,0 м',
          'Не менее 0,9 м',
          'Не менее 0,2 м',
          'Не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 1,0 м'],
      },
      {
        code: '63678033',
        text:
          'Какие приборы применяются для контроля и обеспечения процесса фильтрования?',
        answers: [
          'Только вакуумметры на вакуум-насосах, ресиверах и распределительной головке',
          'Только манометры на распределительной головке',
          'Только приборы сигнализации уровня в ресиверах',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63678034',
        text:
          'Какова максимальная периодичность режимных испытаний (РИ) технологических схем обогащения и компоновок оборудования сушильных установок (СУ) фабрик?',
        answers: [
          '1 раз в год',
          '1 раз в 5 лет',
          '1 раз в 2 года',
          'Не регламентируется',
        ],
        correctAnswers: ['1 раз в 2 года'],
      },
      {
        code: '63678035',
        text:
          'Какая минимальная ширина проходов должна быть для обслуживания конвейеров всех видов (кроме пластинчатых)?',
        answers: ['0,5 м', '0,7 м', '1 м', '1,5 м'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63678036',
        text:
          'Какое минимально допустимое время для измерения температуры в штабеле угля при его хранении?',
        answers: ['40 минут', '35 минут', '20 минут', '25 минут'],
        correctAnswers: ['20 минут'],
      },
      {
        code: '63678037',
        text:
          'Что запрещается к применению при проведении огневых работ на временных местах в любых случаях?',
        answers: [
          'Электросварочные установки',
          'Ацетиленовые генераторы',
          'Керосинорезы',
          'Сжиженные и сжатые газы',
          'Ацетилен в баллонах',
        ],
        correctAnswers: ['Ацетиленовые генераторы'],
      },
      {
        code: '63678038',
        text:
          'Каково максимально допустимое отсутствие поддерживающих роликов при эксплуатации ленточного конвейера?',
        answers: ['3 %', '10 %', '20 %', 'Не нормируется'],
        correctAnswers: ['10 %'],
      },
      {
        code: '63678039',
        text:
          'При какой минимальной температуре нагрева угля должны приниматься меры для ликвидации очагов самовозгорания?',
        answers: ['30 °С', '35 °С', '45 °С', '50 °С', '60 °С'],
        correctAnswers: ['60 °С'],
      },
      {
        code: '63678040',
        text:
          'Что необходимо сделать, если огневые работы не закончены в установленный в наряде-допуске срок?',
        answers: [
          'Главный инженер шахты (фабрики) продлевает срок проведения огневых работ до полного их выполнения',
          'Мастер структурного подразделения шахты (фабрики), где проводятся огневые работы, выписывает новый наряд-допуск в любом случае',
          'Руководитель структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на две смены',
          'ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на одну смену',
        ],
        correctAnswers: [
          'ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на одну смену',
        ],
      },
      {
        code: '63678041',
        text:
          'На каких объектах по категориям пожарной и взрывопожарной опасности оборудуются временные места проведения огневых работ?',
        answers: [
          'Только в горных выработках шахт, надшахтных зданиях и сооружениях шахт, имеющих пониженную пожароопасность',
          'Только в надшахтных зданиях и сооружениях шахт, имеющих умеренную пожароопасность',
          'В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности',
          'Только в надшахтных зданиях и сооружениях шахт с пониженной пожароопасностью',
        ],
        correctAnswers: [
          'В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности',
        ],
      },
      {
        code: '63678042',
        text:
          'С каким минимальным напором воды к разгрузочным отверстиям сгустителя должны подводиться водопроводные трубы для промывки отверстий при зашламовывании?',
        answers: ['5 Н/см2', '20 Н/см2', '15 Н/см2', 'Не нормируется'],
        correctAnswers: ['20 Н/см2'],
      },
      {
        code: '63678043',
        text:
          'Кто из перечисленных лиц является руководителем работ по ликвидации аварий на обогатительной фабрике?',
        answers: [
          'Главный инженер фабрики',
          'Командир профессиональной аварийно-спасательной службы (формирования)',
          'Руководитель территориального органа Ростехнадзора',
          'Руководитель фабрики',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678044',
        text:
          'Какие документы необходимы для проведения огневых работ на временных местах?',
        answers: [
          'Наряд-допуск на проведение огневых работ и инструкция по выполнению огневых работ',
          'Наряд-допуск на проведение огневых работ и план проведения огневых работ',
          'План проведения огневых работ и ведомость дефектов',
          'Распоряжение о проведении огневых работ и инструкция по выполнению огневых работ',
        ],
        correctAnswers: [
          'Наряд-допуск на проведение огневых работ и план проведения огневых работ',
        ],
      },
      {
        code: '63678045',
        text:
          'На какой высоте прокладываются открыто шины отрицательного полюса тока высокого напряжения в помещении выпрямительной подстанции?',
        answers: [
          'Не менее 2,5 м',
          'Не менее 2,4 м',
          'Не менее 2,3 м',
          'Не менее 2,2 м',
        ],
        correctAnswers: ['Не менее 2,5 м'],
      },
      {
        code: '63678046',
        text:
          'Что учитывается руководителем фабрики при установлении скорости движения поездов на железнодорожных путях?',
        answers: [
          'Только применяемый подвижной состав',
          'Только верхнее строение и профиль пути',
          'Только местные условия',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63678047',
        text:
          'В каких помещениях следует предусматривать приточную вентиляцию с подачей наружного воздуха, обеспечивающую постоянный его подпор круглогодично? Укажите все правильные ответы.',
        answers: [
          'В помещениях категорий "Г" и "Д"',
          'В тамбур-шлюзах',
          'В помещениях машинных отделений лифтов зданий категорий "А" и "Б"',
          'В помещениях с выделением вредных газов и паров любого класса опасности',
        ],
        correctAnswers: [
          'В тамбур-шлюзах',
          'В помещениях машинных отделений лифтов зданий категорий "А" и "Б"',
        ],
      },
      {
        code: '63678048',
        text:
          'Какова минимальная периодичность проведения в организации учебных тревог под руководством главного инженера фабрики?',
        answers: [
          'Один раз в год',
          'Один раз в 4 месяца',
          'Один раз в 2 года',
          'Не нормируется',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63678049',
        text: 'Какова максимально допустимая высота приставной лестницы?',
        answers: ['2 м', '5 м', '7 м', '15 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63678050',
        text:
          'В какой срок должны быть отражены на схеме все происшедшие изменения в электроснабжении за подписью работника, ответственного за электрохозяйство, с указанием его должности и даты внесения изменения?',
        answers: [
          'В суточный срок',
          'В пятидневный срок',
          'В трехдневный срок',
          'В семидневный срок',
        ],
        correctAnswers: ['В суточный срок'],
      },
      {
        code: '63678051',
        text: 'Что из перечисленного запрещается при эксплуатации грохотов?',
        answers: [
          'Только пуск грохота при отсутствии или неисправности защитных ограждений и отсутствии крепления и натяжения просеивающих поверхностей',
          'Только ремонт, смазка подшипников, натяжение сит, закрепление болтов во время работы грохота',
          'Только регулировка работающего грохота посредством изменения параметров его движения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63678052',
        text:
          'Какова периодичность проверки исправности сигнализации заполнения реагентных бачков, устройств автоматического выключения двигателей насосов, подающих реагенты из реагентного отделения, вентиляции реагентных площадок?',
        answers: [
          'При сдаче-приемке смены',
          'Не реже двух раз в сутки',
          'Не реже одного раза в три дня',
          'Не реже трех раз в неделю',
        ],
        correctAnswers: ['При сдаче-приемке смены'],
      },
      {
        code: '63678053',
        text:
          'Какова максимально допустимая температура подаваемого на прессование угля на брикетном производстве, при которой допускается аварийная остановка пресса?',
        answers: ['30 °С', '50 °С', '100 °С', '120 °С'],
        correctAnswers: ['100 °С'],
      },
      {
        code: '63678054',
        text:
          'Каков максимально допустимый уклон подъездных железнодорожных путей к бункеру при погрузке материала?',
        answers: ['10 промилле', '25 промилле', '15 промилле', '20 промилле'],
        correctAnswers: ['15 промилле'],
      },
      {
        code: '63678055',
        text:
          'Какой должна быть высота прохода вдоль конвейеров с постоянными рабочими местами, установленных в производственных помещениях?',
        answers: [
          'Не менее 2,1 м',
          'Не менее 2,5 м',
          'Не менее 0,8 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 2,1 м'],
      },
      {
        code: '63678056',
        text:
          'При какой начальной температуре (на входе в аппарат) и температуре отработавших газов или воздуха (перед или после дымососа) при сушке антрацита и полуантрацита содержание в отработавших газах кислорода не ограничивается?',
        answers: [
          'При начальной температуре (на входе в аппарат) до 400 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 70 °C',
          'При начальной температуре (на входе в аппарат) до 450 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 75 °C',
          'При начальной температуре (на входе в аппарат) до 500 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 80 °C',
          'Не регламентируется',
        ],
        correctAnswers: [
          'При начальной температуре (на входе в аппарат) до 400 °C и температуре отработавших газов или воздуха (перед или после дымососа) до 70 °C',
        ],
      },
      {
        code: '63678057',
        text:
          'Каков минимально допустимый угол наклона к горизонту патрубков для отвода взрывных газов?',
        answers: ['30°', '45°', '60°', 'Не нормируется'],
        correctAnswers: ['45°'],
      },
      {
        code: '63678058',
        text:
          'Кем оформляется наряд-допуск при проведении огневых работ работниками подрядных организаций?',
        answers: [
          'Техническим руководителем шахты (фабрики)',
          'ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)',
          'Руководителем структурного подразделения организации',
          'Лицом, ответственным за безопасное выполнение работ на шахте (фабрике)',
        ],
        correctAnswers: [
          'ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)',
        ],
      },
      {
        code: '63678059',
        text:
          'Кто подготавливает заключение о противоаварийной готовности фабрики?',
        answers: [
          'Руководитель фабрики',
          'Главный инженер фабрики',
          'Работник профессиональных аварийно-спасательных служб и (или) профессиональных аварийно-спасательных формирований ПАСС(Ф), назначенный руководителем ПАСС(Ф)',
          'Главный энергетик фабрики',
        ],
        correctAnswers: [
          'Работник профессиональных аварийно-спасательных служб и (или) профессиональных аварийно-спасательных формирований ПАСС(Ф), назначенный руководителем ПАСС(Ф)',
        ],
      },
      {
        code: '63678060',
        text:
          'Какова минимально допустимая численность работников для производства работ по сливу из железнодорожных цистерн химически токсичных веществ: антраценового и каменноугольного масел, флотационных реагентов и очистка от них железнодорожных цистерн и хранилищ?',
        answers: [
          '3 работника',
          '2 работника',
          '4 работника',
          'Не нормируется',
        ],
        correctAnswers: ['2 работника'],
      },
      {
        code: '63678061',
        text:
          'С какой периодичностью проводят измерение температуры для углей I группы?',
        answers: [
          'Через каждые 10 дней',
          'Через каждые 5 дней',
          'Через каждые 3 дня',
          'Через каждые 7 дней',
        ],
        correctAnswers: ['Через каждые 10 дней'],
      },
      {
        code: '63678062',
        text:
          'Какова минимально допустимая температура паровоздушной среды для включения электрофильтров в работу после получения сигналов о нормальном расходе пара?',
        answers: ['78 °С', '80 °С', '55 °С', '70 °С'],
        correctAnswers: ['78 °С'],
      },
      {
        code: '63678063',
        text:
          'Какова максимально допустимая концентрация взвешенной угольной пыли относительно нижнего предела взрывчатости (НПВ) угольной пыли, установленного для углей, перерабатываемых фабрикой (УОФ) (кроме фабрик, обогащающих антрациты)?',
        answers: ['15 %', '20 %', '30 %', '40 %'],
        correctAnswers: ['30 %'],
      },
      {
        code: '63678064',
        text:
          'Как должны быть окрашены все трубы, подводящие пар в СУ и отводящие конденсат от нее?',
        answers: [
          'В желтый цвет с зелеными кольцами (пар) и в красный цвет с синими кольцами (конденсат)',
          'В зеленый цвет с синими кольцами (пар) и в серый цвет с желтыми кольцами (конденсат)',
          'В красный цвет с желтыми кольцами (пар) и в зеленый цвет с синими кольцами (конденсат)',
          'В серый цвет с коричневыми кольцами (пар) и в желтый цвет с зелеными кольцами (конденсат)',
        ],
        correctAnswers: [
          'В красный цвет с желтыми кольцами (пар) и в зеленый цвет с синими кольцами (конденсат)',
        ],
      },
      {
        code: '63678065',
        text:
          'Какое допускается максимальное содержание пыли и токсичных веществ в приточном воздухе, подаваемом системами принудительной вентиляции в помещения производственных и административно-бытовых зданий фабрик?',
        answers: [
          '30 % ПДК для воздуха рабочей зоны',
          '10 % ПДК для воздуха рабочей зоны',
          '15 % ПДК для воздуха рабочей зоны',
          '40 % ПДК для воздуха рабочей зоны',
        ],
        correctAnswers: ['30 % ПДК для воздуха рабочей зоны'],
      },
      {
        code: '63678066',
        text:
          'Какова минимально допустимая нагрузка, воспринимаемая поддерживающей решеткой или сеткой, на которые с внутренней стороны должны опираться предохранительные клапаны?',
        answers: ['20 кг', '80 кг', '100 кг', '150 кг'],
        correctAnswers: ['100 кг'],
      },
      {
        code: '63678067',
        text:
          'Какова периодичность проверки исправности предохранительных клапанов работником, назначенным руководителем фабрики?',
        answers: [
          'Один раз в сутки',
          'Один раз в квартал',
          'Один раз в месяц',
          'Один раз в неделю',
        ],
        correctAnswers: ['Один раз в сутки'],
      },
      {
        code: '63678068',
        text:
          'При каком минимальном содержании метана в бункере запрещается проведение работ по его зачистке?',
        answers: ['0,5 %', '1,0 %', '1,5 %', '2,0 %'],
        correctAnswers: ['1,0 %'],
      },
      {
        code: '63678069',
        text:
          'Какова минимальная периодичность уборки угольной пыли с оборудования в течение смены?',
        answers: ['1 раз', '3 раза', '5 раз', 'Не регламентируется'],
        correctAnswers: ['1 раз'],
      },
      {
        code: '63678070',
        text:
          'В каких ситуациях проводится отключение дробильной установки под нагрузкой?',
        answers: [
          'Только при внезапном появлении постороннего стука',
          'Только при поломке узлов дробилки или механизмов дробильной установки, необходимости предотвратить аварию или несчастный случай',
          'Только при необходимости предотвратить аварию или несчастный случай',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63678071',
        text:
          'Каким должно быть нормальное положение автоматических и неавтоматических шлагбаумов переездов?',
        answers: [
          'Открытое у автоматического и неавтоматического шлагбаумов',
          'Закрытое у автоматического и открытое у неавтоматического шлагбаумов',
          'Открытое у автоматического и закрытое у неавтоматического шлагбаумов',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Открытое у автоматического и закрытое у неавтоматического шлагбаумов',
        ],
      },
      {
        code: '63678072',
        text:
          'При каких условиях необходимо немедленно принимать меры по ликвидации очага самовозгорания?',
        answers: [
          'При обнаружении в штабеле углей с температурой 60 °С и более или при повышении температуры со скоростью 5 °С в сутки',
          'При обнаружении в штабеле углей с температурой 50 °С или при повышении температуры со скоростью 3 °С в сутки',
          'При обнаружении в штабеле углей с температурой 40 °С или при повышении температуры со скоростью 2 °С в сутки',
          'При обнаружении в штабеле углей с температурой 30 °С или при повышении температуры со скоростью 1 °С в сутки',
        ],
        correctAnswers: [
          'При обнаружении в штабеле углей с температурой 60 °С и более или при повышении температуры со скоростью 5 °С в сутки',
        ],
      },
      {
        code: '63678073',
        text:
          'Какой должна быть температура подшипников во время работы дымососа?',
        answers: [
          'Не должна превышать 60 °С',
          'Не должна превышать 65 °С',
          'Не должна превышать 70 °С',
          'Не должна превышать 80 °С',
        ],
        correctAnswers: ['Не должна превышать 60 °С'],
      },
      {
        code: '63678074',
        text:
          'Какие категории по пожарной и взрывопожарной опасности установлены для надшахтных зданий и сооружений шахт и фабрик для возможного оборудования в них постоянных мест проведения огневых работ? Выберите два правильных варианта ответа.',
        answers: [
          'Пониженная пожароопасность',
          'Пониженная взрывопожароопасность',
          'Взрывопожароопасность',
          'Повышенная взрывопожароопасность',
          'Умеренная пожароопасность',
        ],
        correctAnswers: [
          'Пониженная пожароопасность',
          'Умеренная пожароопасность',
        ],
      },
      {
        code: '63678075',
        text:
          'Какова минимально допустимая длительность звукового сигнала перед пуском конвейера?',
        answers: ['3 секунды', '5 секунд', '10 секунд', 'Не нормируется'],
        correctAnswers: ['5 секунд'],
      },
      {
        code: '63678076',
        text: 'Из каких помещений допускается рециркуляция воздуха?',
        answers: [
          'Из помещений категорий "А" и "Б", которые оборудованы воздушными и воздушно-тепловыми завесами у наружных ворот и дверей',
          'Из 5-метровых зон вокруг оборудования, расположенного в помещениях категорий "В1" - "В4", "Г" и "Д", если в этих зонах могут образовываться взрывоопасные смеси из горючих газов, паров, аэрозолей с воздухом',
          'Из систем местных отсосов вредных веществ и взрывоопасных смесей с воздухом',
          'Из тамбур-шлюзов',
          'Из помещений, в которых максимальный расход наружного воздуха определяется массой выделяемых вредных веществ первого и второго классов опасности',
        ],
        correctAnswers: [
          'Из помещений категорий "А" и "Б", которые оборудованы воздушными и воздушно-тепловыми завесами у наружных ворот и дверей',
        ],
      },
      {
        code: '63678077',
        text: 'На какие группы разделяются угли по склонности к окислению?',
        answers: [
          'Наиболее устойчивые к окислению, устойчивые, средней устойчивости к окислению, неустойчивые',
          'С повышенной устойчивостью к окислению, слабоокисляемые, средней устойчивости к окислению',
          'Неокисляемые, с устойчивостью к окислению',
          'С повышенной устойчивостью к окислению, устойчивые к окислению',
        ],
        correctAnswers: [
          'Наиболее устойчивые к окислению, устойчивые, средней устойчивости к окислению, неустойчивые',
        ],
      },
      {
        code: '63678078',
        text:
          'Кто утверждает программу проведения режимных испытаний (РИ) сушильных установок (СУ)?',
        answers: [
          'Главный инженер фабрики',
          'Мастер участка',
          'Руководитель фабрики',
          'Главный механик',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678079',
        text:
          'Не позднее какого срока должен быть согласован и утвержден план ликвидации аварий (ПЛА) до ввода его в действие?',
        answers: ['За 15 дней', 'За 25 дней', 'За 35 дней', 'За 40 дней'],
        correctAnswers: ['За 15 дней'],
      },
      {
        code: '63678080',
        text:
          'В какой максимальный срок должны быть внесены изменения в текстовую и графическую часть ПЛА при изменении технологии производства, а также путей вывода работников в безопасное место?',
        answers: [
          'В суточный срок',
          'В течение 3 дней',
          'В течение 5 дней',
          'В течение 7 дней',
        ],
        correctAnswers: ['В суточный срок'],
      },
      {
        code: '63678081',
        text:
          'Какова максимально допустимая общая (рабочая) влажность угля, подаваемого на пневматические сепараторы и пневматические отсадочные машины?',
        answers: ['8 %', '10 %', '5 %', '15 %'],
        correctAnswers: ['8 %'],
      },
      {
        code: '63678082',
        text:
          'Каким должно быть расстояние от головок крайних к штабелям рельсов железнодорожных путей и от бровки автодороги до подошвы штабеля топлива?',
        answers: [
          'Соответственно не ближе 2,5 м и не ближе 1,5 м',
          'Соответственно не ближе 1,5 м и не ближе 0,5 м',
          'Соответственно не ближе 2,2 м и не ближе 1,2 м',
          'Соответственно не ближе 1,8 м и не ближе 0,9 м',
        ],
        correctAnswers: ['Соответственно не ближе 2,5 м и не ближе 1,5 м'],
      },
      {
        code: '63678083',
        text:
          'Каким должен быть объем отсасываемого воздуха, необходимый для поддержания разрежения под зонтом пневматического сепаратора в производственных помещениях фабрики с пневматическими методами обогащения?',
        answers: [
          'На 5 - 10 % больше объема, нагнетаемого под деку пневматического сепаратора',
          'На 8 - 15 % больше объема, нагнетаемого под деку пневматического сепаратора',
          'На 10 - 20 % больше объема, нагнетаемого под деку пневматического сепаратора',
          'На 20 - 25 % больше объема, нагнетаемого под деку пневматического сепаратора',
        ],
        correctAnswers: [
          'На 20 - 25 % больше объема, нагнетаемого под деку пневматического сепаратора',
        ],
      },
      {
        code: '63678084',
        text:
          'До какой температуры должны быть охлаждены брикеты перед погрузкой в вагоны?',
        answers: [
          'До температуры не более 45 °С',
          'До температуры не более 50 °С',
          'До температуры не более 60 °С',
          'До температуры не более 30 °С',
        ],
        correctAnswers: ['До температуры не более 45 °С'],
      },
      {
        code: '63678085',
        text:
          'Что входит в технические решения по пылеулавливанию и пылеподавлению подземной части складов?',
        answers: [
          'Только аспирация',
          'Только гидрообеспыливание',
          'Только пылеулавливание',
          'Только вентиляция',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63678086',
        text:
          'В каких случаях остановка пресса производится в нормальном порядке?',
        answers: [
          'Только в случае если температура охлаждающей воды выше 90 - 100 °С',
          'Только в случае если температура масла в системе смазки подшипников выше 80 °С',
          'Только в случае резкого падения давления масла в системе смазки или отсутствия сигнала о наличии потока масла',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63678087',
        text:
          'Кто утверждает перечень работ повышенной опасности и список руководителей этих работ?',
        answers: [
          'Руководитель территориального органа Ростехнадзора',
          'Командир профессиональной аварийно-спасательной службы и (или) профессионального аварийно-спасательного формирования',
          'Руководитель фабрики',
          'Главный инженер фабрики',
        ],
        correctAnswers: ['Руководитель фабрики'],
      },
      {
        code: '63678088',
        text:
          'С какой периодичностью проводится определение содержания в пыли свободного диоксида кремния на фабриках?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63678089',
        text:
          'Кто определяет периодичность и места проведения замеров воздуха на запыленность и загазованность производственных помещений фабрик?',
        answers: [
          'Начальник участка аэрологической безопасности',
          'Главный инженер фабрики',
          'Руководитель службы производственного контроля',
          'Начальник производства (цеха)',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678090',
        text:
          'При какой максимальной длине подземной тупиковой части на вновь проектируемых и реконструируемых фабриках в помещениях углеприемных ям подачи угля со склада запасной выход не предусматривается?',
        answers: ['15 м', '20 м', '25 м', '30 м'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63678091',
        text:
          'Кто утверждает перечень ИТР, имеющих право оформления нарядов-допусков?',
        answers: [
          'Командир пожарной части',
          'Лица, ответственные за безопасное выполнение работ на шахте (фабрике)',
          'Главный инженер шахты (фабрики)',
          'Руководитель шахты (фабрики)',
        ],
        correctAnswers: ['Руководитель шахты (фабрики)'],
      },
      {
        code: '63678092',
        text:
          'Где должен находиться план ликвидации аварий (ПЛА) со всеми приложениями?',
        answers: [
          'Только у главного инженера',
          'Только в диспетчерской фабрики',
          'Только в ПАСС(Ф)',
          'У всех перечисленных лиц и во всех перечисленных местах',
        ],
        correctAnswers: [
          'У всех перечисленных лиц и во всех перечисленных местах',
        ],
      },
      {
        code: '63678093',
        text:
          'В какие сроки проводится измерение сопротивления стационарных электроустановок?',
        answers: [
          'Только в период наибольшего промерзания грунта зимой',
          'Только в период наибольшего высыхания грунта летом',
          'В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой',
          'В грозовые периоды весной и летом',
        ],
        correctAnswers: [
          'В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой',
        ],
      },
      {
        code: '63678094',
        text:
          'Кто утверждает комиссию по приему в эксплуатацию сушильных установок после их капитального ремонта?',
        answers: [
          'Главный инженер фабрики',
          'Представитель территориального органа Ростехнадзора',
          'Главный энергетик фабрики',
          'Главный механик фабрики',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678095',
        text:
          'Кем определяется расположение постоянных мест проведения огневых работ?',
        answers: [
          'Мастером шахты (фабрики)',
          'Руководителем шахты (фабрики)',
          'Главным инженером шахты (фабрики)',
          'Командиром пожарной части, обслуживающей организацию',
        ],
        correctAnswers: ['Руководителем шахты (фабрики)'],
      },
      {
        code: '63678096',
        text:
          'Каким устройством должны быть снабжены дымовые и растопочные трубы топок?',
        answers: [
          'Только клапаном (шибером) растопочной трубы, обеспечивающим герметичность перекрывания поперечного сечения трубы',
          'Только лазами для осмотра трубы, предусмотренными в нижней части дымовой трубы, фундаменте или подводящих газоходах, а в необходимых случаях - устройствами, обеспечивающими отвод конденсата и очистку от шлама',
          'Только перекрывающим клапаном (шибером), имеющим дистанционный привод, позволяющий вручную открыть клапан при внезапном отключении электроэнергии',
          'Всеми перечисленными устройствами',
        ],
        correctAnswers: ['Всеми перечисленными устройствами'],
      },
      {
        code: '63678097',
        text:
          'В какие сроки на фабриках, отнесенных к опасным по взрывам пыли и газа, должны быть направлены результаты испытаний взрывчатости угольной пыли?',
        answers: [
          'В 30-дневный срок с момента получения пробы на испытания',
          'В 10-дневный срок с момента получения пробы на испытания',
          'В 15-дневный срок с момента получения пробы на испытания',
          'В 40-дневный срок с момента получения пробы на испытания',
        ],
        correctAnswers: [
          'В 30-дневный срок с момента получения пробы на испытания',
        ],
      },
      {
        code: '63678098',
        text:
          'С какой скоростью должна перемещаться конвейерная лента для ручной выборки породы и посторонних предметов?',
        answers: [
          'Скорость движения не должна превышать 0,40 м/с',
          'Скорость движения не должна превышать 0,15 м/с',
          'Скорость движения не должна превышать 0,20 м/с',
          'Скорость движения не должна превышать 0,50 м/с',
        ],
        correctAnswers: ['Скорость движения не должна превышать 0,40 м/с'],
      },
      {
        code: '63678099',
        text:
          'В каких пределах устанавливают показания газоанализатора во время длительной остановки сушильного агрегата?',
        answers: [
          '(17 ± 0,1) %',
          '(19 ± 0,1) %',
          '(21 ± 0,1) %',
          '(23 ±0,1) %',
        ],
        correctAnswers: ['(21 ± 0,1) %'],
      },
      {
        code: '63678100',
        text:
          'С какой периодичностью должны очищаться отверстия дек пневматических отсадочных машин от угля и должна проверяться засыпка дек фарфоровыми шарами?',
        answers: [
          'Не реже двух раз в неделю',
          'Не реже одного раза в неделю',
          'Не реже трех раз в две недели',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже двух раз в неделю'],
      },
      {
        code: '63678101',
        text:
          'Каким способом должны применяться поверхностно-активные вещества с целью связывания осевшей пыли?',
        answers: [
          'Любым из перечисленных способов',
          'Комбинированным способом',
          'Механизированным способом',
          'Ручным способом',
        ],
        correctAnswers: ['Механизированным способом'],
      },
      {
        code: '63678102',
        text:
          'На каком минимальном расстоянии от хранилища при сливе и очистке цистерн разрешается пользоваться открытым огнем и курить?',
        answers: ['5 м', '10 м', '20 м', '35 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63678103',
        text:
          'Кто осуществляет руководство проведением учебных тревог в организации?',
        answers: [
          'Главный инженер фабрики',
          'Командир профессиональной аварийно-спасательной службы',
          'Представитель территориального органа Ростехнадзора',
          'Руководитель организации',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678104',
        text:
          'Какое сечение должно быть у стальной шины, предназначенной для заземления шины положительного полюса тока высокого напряжения выпрямительных подстанций?',
        answers: [
          'Не менее 100 мм²',
          'Не менее 70 мм²',
          'Не менее 85 мм²',
          'Не менее 65 мм²',
        ],
        correctAnswers: ['Не менее 100 мм²'],
      },
      {
        code: '63678105',
        text:
          'Какова минимально допустимая численность работников для очистки внутренних поверхностей корпусов отсадочной машины от шлама и осевшего материала?',
        answers: [
          'Один работник',
          'Два работника',
          'Четыре работника',
          'Не нормируется',
        ],
        correctAnswers: ['Два работника'],
      },
      {
        code: '63678106',
        text:
          'Какова максимально допустимая температура газов перед дымососом и для установок без ограничения содержания кислорода в сушильных установках (СУ)?',
        answers: [
          '120 °С - перед дымососом, 70 °С - для установок без ограничения содержания кислорода',
          '115 °С - перед дымососом, 45 °С - для установок без ограничения содержания кислорода',
          '110 °С - перед дымососом, 80 °С - для установок без ограничения содержания кислорода',
          '105 °С - перед дымососом, 75 °С - для установок без ограничения содержания кислорода',
        ],
        correctAnswers: [
          '120 °С - перед дымососом, 70 °С - для установок без ограничения содержания кислорода',
        ],
      },
      {
        code: '63678107',
        text:
          'Какова максимально допустимая величина угла откоса складируемого материала при работе бульдозера на подъем?',
        answers: ['10°', '35°', '25°', '30°'],
        correctAnswers: ['25°'],
      },
      {
        code: '63678108',
        text:
          'Какой должна быть ширина проезжей части дороги для автосамосвалов грузоподъемностью до 10 т при однополосном движении?',
        answers: [
          'Не менее 7,5 м',
          'Не менее 5 м',
          'Не менее 3,5 м',
          'Не менее 4 м',
        ],
        correctAnswers: ['Не менее 7,5 м'],
      },
      {
        code: '63678109',
        text:
          'Какова максимально допустимая температура стенок матричного канала на брикетном производстве, при которой допускается аварийная остановка пресса?',
        answers: ['80 °С', '40 °С', '100 °С', '120 °С'],
        correctAnswers: ['120 °С'],
      },
      {
        code: '63678110',
        text:
          'Какова предельно допустимая концентрация метана в производственных помещениях фабрики?',
        answers: ['1 %', '2,5 %', '1,5 %', '2 %'],
        correctAnswers: ['1 %'],
      },
      {
        code: '63678111',
        text:
          'Какой постоянный воздухообмен должен обеспечиваться в укрытых угольных складах напольного типа за счет естественной протяжки воздуха?',
        answers: [
          '8-кратный воздухообмен',
          '3-кратный воздухообмен',
          '10-кратный воздухообмен',
          '5-кратный воздухообмен',
        ],
        correctAnswers: ['8-кратный воздухообмен'],
      },
      {
        code: '63678112',
        text:
          'Кто утверждает план ликвидации аварий (ПЛА) на обогатительной фабрике?',
        answers: [
          'Руководитель фабрики',
          'Руководитель подразделения ПАСС(Ф), обслуживающего фабрику',
          'Руководитель Ростехнадзора',
          'Главный инженер фабрики',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678113',
        text:
          'На каком расстоянии друг от друга должны размещаться постоянные металлические мостики для перехода через конвейеры в производственных помещениях, в галереях и на эстакадах?',
        answers: [
          'Не более 50 м - в производственных помещениях и не более 100 м - в галереях и на эстакадах',
          'Не более 60 м - в производственных помещениях и не более 150 м - в галереях и на эстакадах',
          'Не более 80 м - в производственных помещениях и не более 170 м - в галереях и на эстакадах',
          'Не более 90 м - в производственных помещениях и не более 190 м - в галереях и на эстакадах',
        ],
        correctAnswers: [
          'Не более 50 м - в производственных помещениях и не более 100 м - в галереях и на эстакадах',
        ],
      },
      {
        code: '63678114',
        text:
          'С какой периодичностью на всех аппаратах защиты должны проводиться испытания максимальной токовой защиты?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63678115',
        text:
          'С какой периодичностью необходимо производить проверку реле утечки тока в комплекте с автоматом на время отключения?',
        answers: [
          'Один раз в шесть месяцев, а также перед вводом защиты в работу после ее монтажа и в случае отказа',
          'Один раз в четыре месяца, а также после монтажа и в случае отказа',
          'Один раз в год, а также перед вводом защиты в работу',
          'Один раз в два года, а также перед вводом защиты в работу',
        ],
        correctAnswers: [
          'Один раз в шесть месяцев, а также перед вводом защиты в работу после ее монтажа и в случае отказа',
        ],
      },
      {
        code: '63678116',
        text:
          'В каком количестве и на какой срок выдается наряд-допуск на проведение огневых работ, связанных со строительством, реконструкцией, капитальным ремонтом, техническим перевооружением и с ремонтными работами, предусматривающими остановку производственного процесса и выполняемыми по графикам производства ремонтных работ?',
        answers: [
          'Один наряд-допуск на весь срок проведения работ',
          'По одному наряду-допуску на каждую смену проведения работ',
          'По одному наряду-допуску на каждый календарный месяц проведения работ',
          'По количеству временных мест проведения работ на каждую смену проведения работ',
        ],
        correctAnswers: ['Один наряд-допуск на весь срок проведения работ'],
      },
      {
        code: '63678117',
        text:
          'Кто утверждает инструкции по очистке от шлама радиальных, пирамидальных сгустителей и шламовых отстойников?',
        answers: [
          'Руководитель фабрики',
          'Мастер участка',
          'Главный инженер фабрики',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Главный инженер фабрики'],
      },
      {
        code: '63678118',
        text:
          'Какова высота предохранительного вала разгрузочных площадок погрузочно-разгрузочных пунктов автомобильного транспорта?',
        answers: [
          'Не менее одного диаметра колеса самого маленького по грузоподъемности автомобиля',
          'Не менее одной трети диаметра колеса самого большого по грузоподъемности автомобиля',
          'Не менее половины диаметра колеса самого большого по грузоподъемности автомобиля',
          'Не нормируется',
        ],
        correctAnswers: [
          'Не менее половины диаметра колеса самого большого по грузоподъемности автомобиля',
        ],
      },
      {
        code: '63678119',
        text:
          'С какой периодичностью должны очищаться стены в помещениях, не связанных с интенсивным пылением?',
        answers: [
          'Не реже четырех раз в месяц',
          'Не реже двух раз в месяц',
          'Не реже одного раза в месяц',
          'Один раз в смену',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63678120',
        text:
          'С какой периодичностью должен проводиться работником наружный осмотр всей заземляющей сети объекта?',
        answers: [
          'Не реже одного раза в месяц, а также после монтажа, реконструкции (переустройства) и ремонта заземляющих устройств',
          'Не реже одного раза в квартал, а также после монтажа, реконструкции (переустройства) заземляющих устройств',
          'Не реже одного раза в полугодие, а также после ремонта заземляющих устройств',
          'Не реже одного раза в год, а также после монтажа, реконструкции (переустройства) заземляющих устройств',
        ],
        correctAnswers: [
          'Не реже одного раза в месяц, а также после монтажа, реконструкции (переустройства) и ремонта заземляющих устройств',
        ],
      },
      {
        code: '63678121',
        text:
          'При каком рабочем давлении пара проводятся испытания магистралей и механизмов парового, нефтебитумного и водяного хозяйства брикетного пресса после их монтажа?',
        answers: [
          '6 - 13 кг/см²',
          '5 - 10 кг/см²',
          '3 - 4 кг/см²',
          '14 - 18 кг/см²',
        ],
        correctAnswers: ['6 - 13 кг/см²'],
      },
      {
        code: '63678122',
        text:
          'Какие производственные операции, связанные с применением открытого огня, искрообразованием и нагреванием до температуры, способной вызвать воспламенение материалов и конструкций, относятся к огневым работам?',
        answers: [
          'Только электросварочные, газосварочные и паяльные работы',
          'Только бензо- и керосинорезка',
          'Только механическая обработка металла',
          'Только работы с применением ацетиленовых генераторов',
          'Только работы с применением газопламенной обработки металлов с использованием кислорода и сжиженных (сжатых) газов',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63678123',
        text:
          'С какой периодичностью должен проводиться контроль запыленности и загазованности производственных помещений?',
        answers: [
          'Один раз в месяц',
          'Один раз в 2 месяца',
          'Один раз в квартал',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в месяц'],
      },
      {
        code: '63678124',
        text:
          'С какой периодичностью осматриваются и протираются опорные изоляторы коронирующих электродов и верхние концевые муфты кабеля электрофильтров, опорные изоляторы в преобразовательной подстанции трансформаторов?',
        answers: [
          'Не реже двух раз в месяц',
          'Не реже одного раза в месяц',
          'Не реже трех раз в два месяца',
          'Не реже четырех раз в квартал',
        ],
        correctAnswers: ['Не реже двух раз в месяц'],
      },
      {
        code: '63678125',
        text:
          'Какова минимально допустимая длина ограждений участков лент, набегающих на барабан?',
        answers: ['0,5 м', '0,8 м', '1 м', '1,5 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63678126',
        text: 'Какие виды работ осуществляются на обогатительной фабрике?',
        answers: [
          'Только прием, складирование, хранение угля',
          'Только механическое и физико-химическое обогащение угля',
          'Только брикетирование, сушка, погрузка (отгрузка) готовой продукции и отходов переработки и обогащения',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63678127',
        text:
          'На каком минимальном расстоянии от места проведения огневых работ оборудование и механизмы не останавливаются при проведении огневых работ во взрывопожароопасных и пожароопасных надшахтных зданиях шахт (фабрик), в которых применяются аспирационные системы и (или) технические устройства, предназначенные для удаления запыленного воздуха из-под укрытий транспортно-технологического оборудования и рабочей зоны, или проводится мокрая уборка рабочих мест?',
        answers: ['15 метров', '20 метров', '35 метров', 'Не нормируется'],
        correctAnswers: ['20 метров'],
      },
      {
        code: '63678128',
        text: 'Что необходимо проверить перед включением гипербарфильтра?',
        answers: [
          'Только состояние ограждений и отсутствие протеков на рабочих площадках',
          'Только отключение давления подвергающихся ремонтным работам системных узлов и проводок (гидравлика, сжатый воздух)',
          'Только закрытие люка гипербарфильтра на замок',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63678129',
        text:
          'Как устраиваются трапы для безопасного движения людей по наклонным галереям с углом наклона более 7°?',
        answers: [
          'На полу должны укладываться трапы с планками, прикрепленными через 0,4 - 0,5 м, и устанавливаться металлические поручни вдоль става конвейера',
          'На полу должны укладываться трапы с планками, прикрепленными через 0,1 - 0,3 м, и устанавливаться сетчатые ограждения вдоль става конвейера',
          'На полу должны укладываться трапы с планками, прикрепленными через 0,2 м, и устанавливаться сплошные ограждения вдоль става конвейера',
          'На полу должны укладываться трапы с планками, прикрепленными через 0,5 м, и устанавливаться деревянные перила вдоль става конвейера',
        ],
        correctAnswers: [
          'На полу должны укладываться трапы с планками, прикрепленными через 0,4 - 0,5 м, и устанавливаться металлические поручни вдоль става конвейера',
        ],
      },
      {
        code: '63678130',
        text:
          'Каково максимально допустимое расстояние при движении автомобиля задним ходом в пунктах погрузки?',
        answers: ['15 м', '50 м', '30 м', 'Не нормируется'],
        correctAnswers: ['30 м'],
      },
      {
        code: '63678131',
        text:
          'За сколько минут до пуска оборудования и после прекращения его работы должна включаться и выключаться аспирационная система технологического и транспортного оборудования?',
        answers: [
          'Соответственно за 3 минуты до пуска и через 3 минуты после прекращения работы',
          'Соответственно за 1 минуту до пуска и через 3 минуты после прекращения работы',
          'Соответственно за 2 минуты до пуска и через 2 минуты после прекращения работы',
          'Соответственно за 3 минуты до пуска и через 1 минуту после прекращения работы',
        ],
        correctAnswers: [
          'Соответственно за 3 минуты до пуска и через 3 минуты после прекращения работы',
        ],
      },
      {
        code: '63678132',
        text:
          'Какой должна быть высота ванны флотационной машины от обслуживающей площадки?',
        answers: [
          'Не менее 1,0 м',
          'Не менее 1,2 м',
          'Не менее 0,3 м',
          'Не менее 0,7 м',
        ],
        correctAnswers: ['Не менее 0,7 м'],
      },
      {
        code: '63678133',
        text:
          'Какова максимально допустимая температура нагрева подшипников прессов на брикетном производстве при отсутствии нормальной смазки во время их работы?',
        answers: ['30 °С', '80 °С', '45 °С', '35 °С'],
        correctAnswers: ['80 °С'],
      },
      {
        code: '63678134',
        text:
          'Какие требования предъявляются к изготовлению быстроизнашивающихся участков сушильных установок (СУ)? Выберите два правильных варианта ответа.',
        answers: [
          'Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из цветного металла',
          'Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из чугуна',
          'Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из нержавеющей стали',
          'Быстроизнашивающиеся участки сушильных установок (СУ) должны быть футерованы износостойкими материалами',
        ],
        correctAnswers: [
          'Быстроизнашивающиеся участки сушильных установок (СУ) должны изготавливаться из нержавеющей стали',
          'Быстроизнашивающиеся участки сушильных установок (СУ) должны быть футерованы износостойкими материалами',
        ],
      },
      {
        code: '63678135',
        text:
          'Какова минимальная периодичность проведения в организации учений по плану ликвидации аварий (ПЛА) с руководителями и работниками фабрики и подрядных организаций?',
        answers: [
          'Один раз в 3 месяца',
          'Один раз в 9 месяцев',
          'Один раз в год',
          'Не нормируется',
        ],
        correctAnswers: ['Один раз в 3 месяца'],
      },
      {
        code: '63678136',
        text:
          'В скольких экземплярах оформляется наряд-допуск на проведение огневых работ на шахте (фабрике)?',
        answers: [
          'В одном экземпляре',
          'В двух экземплярах',
          'В четырех экземплярах',
          'В трех экземплярах',
        ],
        correctAnswers: ['В двух экземплярах'],
      },
      {
        code: '63678137',
        text:
          'Когда должна проверяться исправность действия (срабатывания) защиты?',
        answers: [
          'После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в 10 дней',
          'После каждого переключения электроустановки и во время технологических остановок, но не реже двух раз в шесть месяцев',
          'После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в пять месяцев',
          'После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в девять месяцев',
        ],
        correctAnswers: [
          'После каждого переключения электроустановки и во время технологических остановок, но не реже одного раза в 10 дней',
        ],
      },
      {
        code: '63678138',
        text:
          'При каком рабочем давлении воды проводятся испытания магистралей и механизмов парового, нефтебитумного и водяного хозяйства брикетного пресса после их монтажа?',
        answers: ['3 кг/см²', '8 кг/см²', '4 кг/см²', '2,5 кг/см²'],
        correctAnswers: ['4 кг/см²'],
      },
    ],
    63679: [
      {
        code: '63679000',
        text:
          'В течение какого времени подача каждого насоса или группы одновременно работающих насосов, не считая резервных, должна обеспечивать откачку максимального суточного притока воды?',
        answers: [
          'Не более чем за 20 часов',
          'Не более чем за 21 час',
          'Не более чем за 22 часа',
          'Не более чем за 24 часа',
        ],
        correctAnswers: ['Не более чем за 20 часов'],
      },
      {
        code: '63679001',
        text:
          'Кто определяет места возведения изоляционных перемычек, предназначенных для изоляции пожарного участка, их конструкцию, последовательность и сроки возведения?',
        answers: [
          'Лицо, ответственное за пожарную безопасность ОПО',
          'Технический руководитель (главный инженер) ОПО',
          'Руководитель горноспасательных работ',
          'Руководитель работ по ликвидации аварии (РЛА)',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии (РЛА)'],
      },
      {
        code: '63679002',
        text:
          'Через какое время после проведения взрывных работ отбираются первые пробы рудничной атмосферы при сотрясательном взрывании?',
        answers: [
          'Не ранее чем через 5 минут',
          'Не ранее чем через 15 минут',
          'Не ранее чем через 30 минут',
          'Не ранее чем через 45 минут',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63679003',
        text:
          'На каких из перечисленных шахтах устанавливаются датчики стационарных автоматических приборов контроля метана?',
        answers: [
          'На шахтах I категории',
          'На шахтах II категории',
          'На шахтах III категории',
          'На всех перечисленных шахтах, а также на шахтах IV (сверхкатегорийные) категории',
        ],
        correctAnswers: ['На шахтах III категории'],
      },
      {
        code: '63679004',
        text:
          'Кто организует изучение ПЛА инженерно-техническими работниками шахты?',
        answers: [
          'Руководитель ПАСС(Ф)',
          'Руководитель работ по локализации и ликвидации последствий аварии',
          'Начальник участка (службы)',
          'Главный инженер шахты',
          'Все ответы неверны',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679005',
        text:
          'Каково максимально допустимое расширение рельсовой колеи на прямолинейных и криволинейных участках?',
        answers: [
          '15 мм - на прямолинейных участках и 10 мм - на криволинейных',
          '17 мм - на прямолинейных участках и 12 мм - на криволинейных',
          '20 мм - на прямолинейных участках и 15 мм - на криволинейных',
          '25 мм - на прямолинейных участках и 20 мм - на криволинейных',
        ],
        correctAnswers: [
          '15 мм - на прямолинейных участках и 10 мм - на криволинейных',
        ],
      },
      {
        code: '63679006',
        text:
          'При какой концентрации метана в процессе проведения плановой практической проверки аварийных вентиляционных режимов одновременно с замерами должен проводиться отбор проб шахтного воздуха для проверки его состава?',
        answers: [
          'При концентрации метана 0,8 %',
          'При концентрации метана 1,2 %',
          'При концентрации метана 1,3 %',
          'При концентрации метана 1,7 %',
        ],
        correctAnswers: ['При концентрации метана 1,7 %'],
      },
      {
        code: '63679007',
        text:
          'На каком расстоянии в местах пересечения пластов угля, склонного к самовозгоранию, вскрывающие горные выработки должны быть обработаны в обе стороны герметизирующим инертным материалом, исключающим проникновение воздуха к угольному массиву?',
        answers: [
          'На расстоянии 5 м',
          'На расстоянии 4 м',
          'На расстоянии 3 м',
          'На расстоянии 2 м',
        ],
        correctAnswers: ['На расстоянии 5 м'],
      },
      {
        code: '63679008',
        text:
          'На кого возлагается ответственность за безопасную перевозку людей ленточными конвейерами?',
        answers: [
          'На технического руководителя организации',
          'На горного диспетчера',
          'На лицо, обслуживающее конвейер',
          'На начальника участка, в ведении которого находится конвейер',
        ],
        correctAnswers: [
          'На начальника участка, в ведении которого находится конвейер',
        ],
      },
      {
        code: '63679009',
        text:
          'У кого находятся оригиналы планов ликвидации аварий шахты со всеми приложениями?',
        answers: [
          'Один экземпляр находится у горного диспетчера шахты, другой в подразделении ПАСС(Ф), обслуживающем шахту',
          'Один экземпляр находится у горного диспетчера шахты, другой - у технического руководителя (главного инженера) шахты',
          'Один экземпляр находится у технического руководителя (главного инженера) шахты, другой - у начальника участка (службы)',
          'Один экземпляр находится у начальника участка (службы), другой - в подразделении ПАСС(Ф), обслуживающем шахту',
        ],
        correctAnswers: [
          'Один экземпляр находится у горного диспетчера шахты, другой в подразделении ПАСС(Ф), обслуживающем шахту',
        ],
      },
      {
        code: '63679010',
        text:
          'При каком контрольном времени загазирования не проводятся опытные загазирования в подготовительных выработках?',
        answers: [
          'Менее 40 минут',
          'Менее 30 минут',
          'Менее 25 минут',
          'Менее 20 минут',
        ],
        correctAnswers: ['Менее 20 минут'],
      },
      {
        code: '63679011',
        text:
          'Какое из перечисленных требований к предупреждению и ликвидации загазирований в горных выработках указано верно?',
        answers: [
          'Результаты анализа причин загазирования горных выработок утверждаются руководителем шахты',
          'Мероприятия по предупреждению загазирований горных выработок разрабатываются техническим руководителем (главным инженером) шахты',
          'Порядок применения способов предупреждения и ликвидации загазирований утверждает главный инженер шахты',
          'Технический руководитель (главный инженер) шахты 1 раз в год производит анализ причин загазирования горных выработок',
        ],
        correctAnswers: [
          'Порядок применения способов предупреждения и ликвидации загазирований утверждает главный инженер шахты',
        ],
      },
      {
        code: '63679012',
        text: 'Какие сведения запрещается включать в оперативную часть ПЛА?',
        answers: [
          'Маршруты движения отделений ПАСС(Ф) и последовательность выполняемых ими работ',
          'Мероприятия по спасению людей',
          'Направления вентиляционных струй',
          'Указания о восстановительных работах',
        ],
        correctAnswers: ['Указания о восстановительных работах'],
      },
      {
        code: '63679013',
        text:
          'С какой периодичностью должно контролироваться качество инертной пыли, применяемой в сланцевом заслоне?',
        answers: [
          '1 раз в квартал',
          '1 раз в 6 месяцев',
          '1 раз в год',
          '1 раз в 1,5 года',
        ],
        correctAnswers: ['1 раз в квартал'],
      },
      {
        code: '63679014',
        text: 'Как характеризуется слоевое загазирование горных выработок?',
        answers: [
          'Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или другими материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (АГК) в действующих выработках шахты',
          'Наличием скопления метана в виде слоя в выработках на участках длиной свыше 2 м с концентрацией более 2 %',
          'Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка',
        ],
        correctAnswers: [
          'Наличием скопления метана в виде слоя в выработках на участках длиной свыше 2 м с концентрацией более 2 %',
        ],
      },
      {
        code: '63679015',
        text: 'Кто утверждает акт ликвидации очага самонагревания угля?',
        answers: [
          'Руководитель геологической службы шахты (разреза)',
          'Территориальный орган Ростехнадзора',
          'Главный инженер шахты (разреза)',
          'Руководитель шахты (разреза)',
        ],
        correctAnswers: ['Руководитель шахты (разреза)'],
      },
      {
        code: '63679016',
        text:
          'С какой периодичностью руководителям структурных подразделений необходимо проводить проверку состава рудничного воздуха индивидуальными приборами контроля газов у изолирующих перемычек (ИП), изолирующих неиспользуемые горные выработки и выработанное пространство на пластах, склонных к самовозгоранию?',
        answers: [
          'Не реже 1 раза в 30 календарных дней',
          'Не реже 1 раза в 20 календарных дней',
          'Не реже 1 раза в 15 календарных дней',
          'Не реже 1 раза в 10 календарных дней',
        ],
        correctAnswers: ['Не реже 1 раза в 10 календарных дней'],
      },
      {
        code: '63679017',
        text:
          'Кто утверждает документацию по ведению горных работ, после внесения в нее дополнений, учитывающих происшедшие изменения горно-геологических и горнотехнических условий?',
        answers: [
          'Проектная организация',
          'Руководитель шахты',
          'Комиссия, назначенная распорядительным документом руководителя шахты',
          'Главный инженер шахты',
          'Все ответы неверны',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679018',
        text:
          'Куда не требуется записывать безопасную глубину выемки угля, определенную специалистом службы прогноза динамических явлений после проведения прогноза динамических явлений и контроля эффективности мер по их предотвращению?',
        answers: [
          'В наряд-путевку',
          'В участковую книгу нарядов',
          'На аншлаги прогноза динамических явлений',
          'В общешахтную книгу нарядов',
          'Все ответы неверны',
        ],
        correctAnswers: ['В общешахтную книгу нарядов'],
      },
      {
        code: '63679019',
        text:
          'При каком из перечисленных условий должен предусматриваться перерасчет количества воздуха по данным фактической метанообильности выемочного участка (очистной забой или выработанное пространство) при отработке выемочного участка в разделе "Проветривание выемочного участка" документации по ведению работ на выемочном участке?',
        answers: [
          'При превышении фактической метанообильности выемочного участка над проектной более чем на 3 % в течение суток',
          'При двукратном в течение смены превышении концентрации метана относительно установленных норм',
          'Еженедельно',
          'После первичной посадки основной кровли',
        ],
        correctAnswers: ['После первичной посадки основной кровли'],
      },
      {
        code: '63679020',
        text:
          'На протяжении какого срока хранятся расчеты газообильностей выемочных участков, крыльев, панелей, блоков, горизонтов, шахтопластов и шахты и приказы главного инженера шахты об установлении категорий шахты по метану и (или) диоксиду углерода?',
        answers: [
          'На протяжении 10 лет',
          'На протяжении 25 лет',
          'На протяжении 50 лет',
          'На протяжении всего срока эксплуатации шахты на участке аэрологической безопасности (АБ)',
        ],
        correctAnswers: [
          'На протяжении всего срока эксплуатации шахты на участке аэрологической безопасности (АБ)',
        ],
      },
      {
        code: '63679021',
        text:
          'Кто утверждает маркшейдерскую документацию по ведению горных работ в границах незащищенных зон и (или) опасных зон?',
        answers: [
          'Начальник службы аэрологической безопасности',
          'Главный механик угледобывающей организации',
          'Руководитель угледобывающей организации',
          'Технический руководитель (главный инженер) угледобывающей организации',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угледобывающей организации',
        ],
      },
      {
        code: '63679022',
        text:
          'Кем принимается решение об остановке вентиляторов главного проветривания (ВГП)?',
        answers: [
          'Командиром аварийно-спасательной части, обслуживающей шахту',
          'Руководителем специализированной организации по ремонту и наладке вентиляторных установок',
          'Начальником участка аэрологической безопасности (АБ) шахты',
          'Главным инженером шахты',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679023',
        text:
          'Каков максимально допустимый угол наклона наклонных горных выработок для извлечения крепи?',
        answers: ['18°', '30°', '40°', '60°'],
        correctAnswers: ['30°'],
      },
      {
        code: '63679024',
        text:
          'На каком минимальном расстоянии от отработанной части угольного пласта возводятся изолирующие перемычки в зоне разгрузки и вне зоны повышенного горного давления?',
        answers: ['7 м', '4 м', '2,5 м', '1,5 м', 'Все ответы неверны'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63679025',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Техническим руководителем организации - пользователя недр',
          'Руководителем организации - пользователя недр',
          'Органом государственного горного надзора',
          'Органом исполнительной власти субъекта Российской Федерации',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63679026',
        text:
          'Во время производства каких работ допускается нахождение людей в забое вертикальной горной выработки угольной шахты?',
        answers: [
          'Во время производства работ по креплению каната',
          'Во время производства работ по замене каната',
          'Во время производства работ по ликвидации в бетонопроводе участков затвердевшего бетона',
          'Во время производства работ по замене подъемного сосуда',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63679027',
        text:
          'При каких из перечисленных видов аварий в оперативной части планов ликвидации аварий (ПЛА) должны предусматриваться способы увеличения подачи воздуха на аварийные участки?',
        answers: [
          'Только при горном ударе',
          'Только при внезапном выбросе угля и газа',
          'Только при загазировании',
          'При всех перечисленных видах аварий',
        ],
        correctAnswers: ['При всех перечисленных видах аварий'],
      },
      {
        code: '63679028',
        text:
          'В какие сроки испытывают повторно канаты вертикальных подъемов и наклонных людских, грузолюдских подъемов (с углом наклона свыше 60°), проверяемые канатными дефектоскопами?',
        answers: [
          'Через 6 месяцев после навески, а затем через каждые 3 месяца',
          'Через каждые 6 месяцев',
          'Через 12 месяцев после навески и затем через каждые 6 месяцев',
          'Через 18 месяцев после навески, а затем через каждые 6 месяцев',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Через 18 месяцев после навески, а затем через каждые 6 месяцев',
        ],
      },
      {
        code: '63679029',
        text:
          'Какие мероприятия проводятся на шахте перед каждым согласованием ПЛА?',
        answers: [
          'Проверка готовности членов вспомогательных горноспасательных команд (ВГК) шахты (не менее 10% списочного состава ВГК) и их расстановки, расстановки и состояния пунктов ВГК, организации деятельности ВГК и ее оснащенности',
          'Расчет устойчивости вентиляционных режимов в горных выработках при возникновении тепловой депрессии пожара',
          'Расчет времени загазирования забоев тупиковых выработок в случае остановки вентиляторов местного проветривания',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679030',
        text:
          'С какой периодичностью контролируется состояние горных выработок угольной шахты ИТР шахты?',
        answers: [
          'Еженедельно',
          'Через сутки',
          'Дважды в сутки',
          'Ежесуточно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63679031',
        text:
          'Какие действия допускаются при электроснабжении, выборе и проверке электрических аппаратов, кабелей и устройств релейной защиты в участковых сетях шахт напряжением до 1200 В?',
        answers: [
          'Электроснабжение участков с поверхности через скважины',
          'Подключение к одному комплектному распределительному устройству несколько передвижных подстанции или трансформаторов, питающих электроэнергией технологически связанные машины участка',
          'Электроснабжение участков от стационарных участковых подстанций',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63679032',
        text:
          'Как осуществляется дистанционное управление группового аппарата в тупиковой выработке?',
        answers: [
          'По двухпроводной схеме',
          'По трехпроводной схеме',
          'По четырехпроводной схеме',
          'Не осуществляется дистанционное управление группового аппарата',
        ],
        correctAnswers: ['По трехпроводной схеме'],
      },
      {
        code: '63679033',
        text:
          'При выполнении какого из перечисленных условий допускается перемещение людей в бадьях без направляющих рамок при производстве аварийных работ?',
        answers: [
          'Направляющую рамку надежно закрепляют на разгрузочной площадке, а разгрузочные ляды закрыты',
          'Скорость движения бадьи по стволу не превышает 0,3 м/с',
          'Зазоры между кромкой бадьи и выступающими конструкциями элементов ствола не менее 400 мм',
          'Всех перечисленных',
          'Все ответы неверны',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63679034',
        text:
          'Каково максимально допустимое расстояние между смежными лавами при последовательном проветривании?',
        answers: ['100 м', '200 м', '300 м', '500 м'],
        correctAnswers: ['300 м'],
      },
      {
        code: '63679035',
        text:
          'Какие взрыволокализующие заслоны устанавливаются в выработках, оборудованных ленточными конвейерами, по которым транспортируется только порода?',
        answers: [
          'В выработках, оборудованных ленточными конвейерами, по которым транспортируется только порода, заслоны не устанавливаются',
          'Сланцевые или водяные заслоны',
          'Сланцевые заслоны',
          'Водяные заслоны',
        ],
        correctAnswers: [
          'В выработках, оборудованных ленточными конвейерами, по которым транспортируется только порода, заслоны не устанавливаются',
        ],
      },
      {
        code: '63679036',
        text:
          'Кто устанавливает проверку состава рудничной атмосферы во время аварии?',
        answers: [
          'Начальник газоаналитической лаборатории',
          'Главный инженер шахты',
          'Начальник участка аэрологической безопасности',
          'Руководитель работ по локализации и ликвидации последствий аварии',
        ],
        correctAnswers: [
          'Руководитель работ по локализации и ликвидации последствий аварии',
        ],
      },
      {
        code: '63679037',
        text:
          'Каким образом должны соединяться конвейерные ленты при углах наклона выработки более 10° в соответствии с Инструкцией по безопасной перевозке людей ленточными конвейерами в подземных выработках угольных (сланцевых) шахт?',
        answers: [
          'Скобами',
          'Путем склеивания',
          'При помощи медных или алюминиевых соединителей',
          'Методами горячей или холодной вулканизации',
          'Все ответы неверны',
        ],
        correctAnswers: ['Методами горячей или холодной вулканизации'],
      },
      {
        code: '63679038',
        text:
          'Какой документ оформляется в случае, если применяемые меры по ликвидации очага самонагревания угля не дают положительного эффекта?',
        answers: [
          'Акт ликвидации очага самонагревания угля',
          'Акт обнаружения признаков самонагревания угля в шахте (разрезе)',
          'Акт списания эндогенного пожара',
          'Акт регистрации эндогенного пожара',
          'Все ответы неверны',
        ],
        correctAnswers: ['Акт регистрации эндогенного пожара'],
      },
      {
        code: '63679039',
        text:
          'Какое из перечисленных полномочий принадлежит главному инженеру шахты?',
        answers: [
          'Создание комиссии, осуществляющей контроль выполнения мер по изоляции неиспользуемых горных выработок и выработанных пространств',
          'Принятие решения о ведении очистных работ в выемочных столбах, над которыми на земной поверхности есть неликвидированные провалы и трещины',
          'Утверждение акта заиловочных работ',
          'Назначение лиц, которые должны контролировать соответствие работ, выполняемых при возведении изолирующих перемычек',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679040',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Наименования организации, объекта недропользования (участка недр)',
          'Дата составления',
          'Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами, и иных составителей графических материалов',
          'Название графического документа',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63679041',
        text:
          'Кто производит прием в эксплуатацию вновь смонтированного электрооборудования для проведения тупиковой выработки?',
        answers: [
          'Электротехнический персонал',
          'Представитель организации-изготовителя',
          'Инспектор территориального органа Ростехнадзора',
          'Комиссия, назначенная техническим руководителем шахты',
        ],
        correctAnswers: [
          'Комиссия, назначенная техническим руководителем шахты',
        ],
      },
      {
        code: '63679042',
        text:
          'В каких местах допускается отсутствие парашютов на клетях для перемещения людей и противовесах людских и грузолюдских подъемных установок?',
        answers: [
          'На клетях и противовесах многоканатных подъемных установок с числом канатов два и более',
          'На противовесах действующих подъемных установок вертикальных стволов, если отделения клети и противовеса не отделены друг от друга перегородкой из рельсов или канатами',
          'На клетях и противовесах аварийно-ремонтных и грузовых подъемных установок',
          'Не допускается ни в каком случае',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'На клетях и противовесах аварийно-ремонтных и грузовых подъемных установок',
        ],
      },
      {
        code: '63679043',
        text:
          'Как осуществляется включение и отключение группового аппарата при применении аппаратуры контроля расхода воздуха и содержания метана в тупиковой выработке? Укажите все правильные ответы.',
        answers: [
          'Автоматически',
          'Вручную по месту',
          'Дистанционно с помощью кнопочного поста',
          'Телемеханически',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Дистанционно с помощью кнопочного поста',
          'Телемеханически',
        ],
      },
      {
        code: '63679044',
        text:
          'Какое из перечисленных требований к бурению и оборудованию контрольных скважин при производстве контроля за тушением эндогенного и (или) экзогенного пожара, указано неверно?',
        answers: [
          'Верхняя труба обсадной колонны должна быть закрыта металлической заглушкой на резьбе и выходить из устья скважины на 0,5 - 1,0 м',
          'Спирали с шагом 0,75 м в количестве 5 штук на 1 м длины спирали',
          'Нижняя труба обсадной колонны на длине не менее 10 м должна иметь перфорацию отверстиями диаметром не более 10 мм, расположенными по спирали с шагом 0,75 м в количестве 5 штук на 1 м длины спирали',
          'Глубина контрольных скважин должна соответствовать Инструкции по предупреждению экзогенной и эндогенной пожароопасности на объектах ведения горных работ угольной промышленности',
          'Устье скважины вокруг колонны обсадных труб должно быть затампонировано песчано-цементной смесью',
        ],
        correctAnswers: [
          'Глубина контрольных скважин должна соответствовать Инструкции по предупреждению экзогенной и эндогенной пожароопасности на объектах ведения горных работ угольной промышленности',
        ],
      },
      {
        code: '63679045',
        text:
          'Где возводятся изолирующие перемычки для изоляции горных выработок, выходящих на земную поверхность?',
        answers: [
          'В изолируемой горной выработке у ее сопряжения с действующими горными выработками',
          'В горных выработках, примыкающих к изолируемой горной выработке',
          'В устье изолируемой горной выработки у земной поверхности',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63679046',
        text:
          'Какое из перечисленных требований к станциям посадки и схода, расположенных на участках ленточных конвейеров, используемых для перевозки людей, указано верно?',
        answers: [
          'По всей длине посадочных площадок и за ними на расстоянии не менее 12 м над площадками и лентой (или над находящейся на ней горной массой) должно быть свободное пространство высотой не менее 1,5 м',
          'Станции посадки на верхнюю ветвь ленты должны иметь боковое ограждение высотой не менее 2 м, препятствующее случайному падению пассажира с площадок',
          'Станции посадки с нерабочей стороны должны иметь ограждения длиной не менее 5 м и высотой не менее 1 м, препятствующие случайному смещению пассажиров за пределы ленты при посадке',
          'Станции посадки должны оборудоваться площадками шириной не менее 0,6 м, расположенными сбоку конвейера или над конвейером',
        ],
        correctAnswers: [
          'Станции посадки должны оборудоваться площадками шириной не менее 0,6 м, расположенными сбоку конвейера или над конвейером',
        ],
      },
      {
        code: '63679047',
        text:
          'Какие перечисленные горные выработки и скважины угольных шахт не подлежат изоляции?',
        answers: [
          'Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа',
          'Горные выработки, используемые для отвода метана из выработанных пространств, со стороны действующих горных выработок',
          'Неиспользуемые горные выработки и скважины',
          'Отработанные выемочные участки (поля)',
          'Все перечисленные горные выработки и скважины подлежат изоляции',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа',
        ],
      },
      {
        code: '63679048',
        text:
          'Из каких документов должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Из структурных карт, характерных разрезов, проекций, схем',
          'Из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63679049',
        text:
          'В каком количестве и на какой срок выдается наряд-допуск на проведение огневых работ, связанных со строительством, реконструкцией, капитальным ремонтом, техническим перевооружением и с ремонтными работами, предусматривающими остановку производственного процесса и выполняемыми по графикам производства ремонтных работ?',
        answers: [
          'Один наряд-допуск на весь срок проведения работ',
          'По одному наряду-допуску на каждую смену проведения работ',
          'По одному наряду-допуску на каждый календарный месяц проведения работ',
          'По количеству временных мест проведения работ на каждую смену проведения работ',
        ],
        correctAnswers: ['Один наряд-допуск на весь срок проведения работ'],
      },
      {
        code: '63679050',
        text:
          'Кому сообщают работники шахты, находящиеся в горных выработках, оборудованных стационарными датчиками оксида углерода, о срабатывании местной сигнализации и об отказах датчиков?',
        answers: [
          'Начальнику шахты',
          'Командиру профессиональной аварийно-спасательной службы',
          'Техническому руководителю организации',
          'Горному диспетчеру или оператору АГК',
        ],
        correctAnswers: ['Горному диспетчеру или оператору АГК'],
      },
      {
        code: '63679051',
        text:
          'При каком превышении содержания метана в рудничной атмосфере в газоотсасывающих трубопроводах подземных и поверхностных газоотсасывающих установок происходит автоматическое отключение электроэнергии, подаваемое на оборудование в контролируемых (защищаемых) выработках?',
        answers: [
          '0,75 % объемной доли',
          '1,0 % объемной доли',
          '2,0 % объемной доли',
          '3,5 % объемной доли',
        ],
        correctAnswers: ['3,5 % объемной доли'],
      },
      {
        code: '63679052',
        text:
          'Кем проводится ежеквартальная ревизия рудничного взрывобезопасного электрооборудования?',
        answers: [
          'Механиком участка или его заместителем под контролем главного энергетика (главного механика) шахты или назначенного им лица',
          'Лицом, специально назначенным для проведения ежеквартальной ревизии',
          'Руководителем энергомеханической службы и главным энергетиком (главным механиком) шахты',
          'Группой электрослесарей с привлечением работников энергомеханической службы участка под контролем главного энергетика (главного механика) шахты или назначенного им лица',
        ],
        correctAnswers: [
          'Группой электрослесарей с привлечением работников энергомеханической службы участка под контролем главного энергетика (главного механика) шахты или назначенного им лица',
        ],
      },
      {
        code: '63679053',
        text:
          'На какие виды работ составляются планы и схемы развития горных работ?',
        answers: [
          'Только на работы по добыче полезных ископаемых',
          'Только на работы, связанные с первичной переработкой минерального сырья',
          'Только на рекультивационные работы',
          'Только на подготовительные работы',
          'Только на маркшейдерские работы',
          'На все перечисленные виды работ',
        ],
        correctAnswers: ['На все перечисленные виды работ'],
      },
      {
        code: '63679054',
        text:
          'Какое из перечисленных требований к лебедкам, применяемых для транспортирования материалов и оборудования по горизонтальным и наклонным горным выработкам, указано неверно?',
        answers: [
          'Отношение диаметра барабана (шкива) к диаметру каната - не менее 30',
          'Допускается многослойная навивка каната на барабан',
          'Скорость движения каната на среднем радиусе навивки не должна превышать 1,8 м/с',
          'Лебедки оборудованы двумя тормозами, один из которых воздействует на барабан (шкив)',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Отношение диаметра барабана (шкива) к диаметру каната - не менее 30',
        ],
      },
      {
        code: '63679055',
        text:
          'На каком основании производят плановые остановки вентиляторов местного проветривания, в том числе и в связи с ремонтом электрооборудования, в тупиковых выработках?',
        answers: [
          'По решению остановочной комиссии',
          'По письменному распорядительному документу руководителя организации',
          'По письменному разрешению территориального органа Ростехнадзора',
          'По письменному разрешению технического руководителя шахты или лица, его замещающего',
        ],
        correctAnswers: [
          'По письменному разрешению технического руководителя шахты или лица, его замещающего',
        ],
      },
      {
        code: '63679056',
        text:
          'Какие из перечисленных расстояний, относящихся к лестницам в лестничных отделениях угольных шахт, указаны верно?',
        answers: [
          'Расстояние между крепью горной выработки и лестницей у ее основания должно быть не менее 0,4 м',
          'Лестницы должны выступать не менее чем на 2 м над горизонтальными полками',
          'В лестничных отделениях не более чем через 10 м устраивают горизонтальные полки',
          'Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м',
        ],
      },
      {
        code: '63679057',
        text:
          'Какое из перечисленных требований при изоляции скважин указано верно?',
        answers: [
          'На земной поверхности над устьем изолируемой скважины отсыпается слой глины высотой не менее 3 м',
          'Обсадная труба на земной поверхности должна быть закрыта пластмассовой заглушкой',
          'Верхний конец трубы для отвода рудничного воздуха из скважины в атмосферу должен находиться на 2 м выше уровня глины',
          'Вокруг обсадной трубы должна быть залита бетонная тумба диаметром на 0,5 м больше диаметра скважины',
        ],
        correctAnswers: [
          'Вокруг обсадной трубы должна быть залита бетонная тумба диаметром на 0,5 м больше диаметра скважины',
        ],
      },
      {
        code: '63679058',
        text:
          'Какое из перечисленных технических мероприятий при обслуживании электроустановок напряжением 3300 В указано верно?',
        answers: [
          'Оболочки взрывобезопасного электрооборудования, подключенного к цепи тестирования изоляции, остаются открытыми в течение всего времени проведения проверки',
          'Технологическому персоналу, закрепленному за участком, на котором электроснабжение машин очистного или проходческо-очистного комплекса осуществляется напряжением 3300 В, в порядке текущей эксплуатации с полным снятием напряжения не допускается выполнять ремонт контактных групп, служащих для присоединения отходящих кабелей',
          'Осмотр всех электроустановок напряжением 3300 В проводится визуально со снятием напряжения',
          'Работы по проведению высоковольтного тестирования производятся по наряду или распоряжению, а результаты тестирования заносятся в оперативный журнал',
        ],
        correctAnswers: [
          'Работы по проведению высоковольтного тестирования производятся по наряду или распоряжению, а результаты тестирования заносятся в оперативный журнал',
        ],
      },
      {
        code: '63679059',
        text:
          'Каким должно быть давление воды, подаваемой на взрывозащитное орошение?',
        answers: [
          'Не менее 1,5 МПа',
          'Не менее 1,2 МПа',
          'Не менее 1 МПа',
          '1 - 1,5 МПа',
        ],
        correctAnswers: ['Не менее 1,5 МПа'],
      },
      {
        code: '63679060',
        text:
          'Какой минимальный воздухообмен должно обеспечить аварийное проветривание помещений дегазационных станций и дегазационной установки за счет принудительной вентиляции в течение 1 часа при превышении допустимого уровня концентрации метана?',
        answers: [
          'Двукратный воздухообмен',
          'Трехкратный воздухообмен',
          'Четырехкратный воздухообмен',
          'Пятикратный воздухообмен',
        ],
        correctAnswers: ['Трехкратный воздухообмен'],
      },
      {
        code: '63679061',
        text:
          'Какие действия, предусмотренные ПЛА, относительно режима энергоснабжения шахт при возникновении аварийных ситуаций указаны верно?',
        answers: [
          'При реверсивном режиме проветривания подача электроэнергии в шахту прекращается. Электроэнергия не отключается в выработках, по которым производится вывод людей из шахты с использованием механических средств, при условии, что концентрация метана в этих выработках менее 5%',
          'При виде аварии «взрыв» подача электроэнергии в шахту прекращается только при наличии возгорания',
          'При изменении направления движения вентиляционной струи в горных выработках решение о прекращении подачи электроэнергии принимает начальника участка (службы)',
          'При пожарах, внезапных выбросах угля и газа, загазированиях в ПЛА предусматривается отключение электроэнергии в аварийных выработках и по пути движения исходящей из них струи. В мероприятиях по отключению электроэнергии перечисляются все выработки с исходящей из аварийного участка вентиляционной струей и указывается способ отключения электроэнергии в этих выработках',
        ],
        correctAnswers: [
          'При пожарах, внезапных выбросах угля и газа, загазированиях в ПЛА предусматривается отключение электроэнергии в аварийных выработках и по пути движения исходящей из них струи. В мероприятиях по отключению электроэнергии перечисляются все выработки с исходящей из аварийного участка вентиляционной струей и указывается способ отключения электроэнергии в этих выработках',
        ],
      },
      {
        code: '63679062',
        text:
          'Какими выполняют сборные заземляющие проводники (шины) при установке одного заземлителя на группу заземляемых объектов? Укажите все правильные ответы.',
        answers: [
          'Из свинца с минимальным сечением 70 мм²',
          'Из нержавеющей стали с минимальным сечением 35 мм²',
          'Из стали с минимальным сечением 50 мм²',
          'Из меди с минимальным сечением 25 мм²',
          'Из алюминия с минимальным сечением 25 мм²',
        ],
        correctAnswers: [
          'Из стали с минимальным сечением 50 мм²',
          'Из меди с минимальным сечением 25 мм²',
        ],
      },
      {
        code: '63679063',
        text:
          'Кем организуется прогноз динамических явлений, проведение мер по предотвращению динамических явлений и контроль их эффективности?',
        answers: [
          'Главным инженером шахты',
          'Руководителем горнодобывающей организации',
          'Командиром аварийно-спасательной части, обслуживающей шахту',
          'Начальником участка или его заместителем',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679064',
        text:
          'Каково минимально допустимое свободное расстояние от наиболее выступающих частей механических и ручных приводов стрелочных переводов откаточных путей до кромки подвижного состава?',
        answers: ['0,7 м', '0,65 м', '0,6 м', '0,5 м'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63679065',
        text: 'Какая документация должна находиться на командном пункте?',
        answers: [
          'План ликвидации аварий',
          'Журналы ОПО и ПАСС(Ф)',
          'Оперативные планы, текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63679066',
        text:
          'В течение какого времени после реализации изменений схемы проветривания выработок или схемы энергоснабжения, предусмотренной паспортом, вносятся соответствующие дополнения в мероприятия по разгазированию горных выработок?',
        answers: [
          'По усмотрению начальника участка аэрологической безопасности',
          'В течение 7 суток',
          'В течение 3 суток',
          'В течение суток',
          'Все ответы неверны',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63679067',
        text:
          'Какие из перечисленных обязанностей не должен выполнять руководитель горноспасательных работ? Укажите все правильные ответы.',
        answers: [
          'Обеспечивает прибытие горноспасательных отделений и других сил и средств ПАСС(Ф) для ведения горноспасательных работ в количестве и в сроки, предусмотренные ПЛА или оперативным планом',
          'Организует разработку оперативных планов',
          'Устанавливает режим работы и отдыха работников ПАСС(Ф)',
          'Организует оказание первой и медицинской помощи пострадавшим',
          'Организует ведение оперативного журнала ПАСС(Ф)',
        ],
        correctAnswers: [
          'Организует разработку оперативных планов',
          'Организует оказание первой и медицинской помощи пострадавшим',
        ],
      },
      {
        code: '63679068',
        text:
          'В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?',
        answers: [
          'Взрыв, горный удар, внезапный выброс, пожар, обрушение',
          'Взрыв, горный удар, обрушение, внезапный выброс, пожар',
          'Взрыв, пожар, внезапный выброс, горный удар, обрушение',
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
        ],
        correctAnswers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
        ],
      },
      {
        code: '63679069',
        text:
          'Под чьим руководством проводятся разгазирование очистных выработок, выработок выемочного участка, а также подготовительных выработок, не проветриваемых более 6 часов?',
        answers: [
          'Под руководством начальника участка аэрологической безопасности (заместителя, помощника)',
          'Под руководством начальника проходческого (добычного) участка или его заместителя (помощника)',
          'Под руководством главного инженера шахты',
          'Под руководством всех перечисленных лиц',
        ],
        correctAnswers: ['Под руководством главного инженера шахты'],
      },
      {
        code: '63679070',
        text:
          'Как часто должны осматриваться крепление и освещение выработки, проходы для людей, конвейер, ленточное полотно, станции посадки и схода, сигнализация, устройства отключения и защиты горным мастером или лицом, обслуживающим конвейер?',
        answers: ['Ежесменно', 'Ежесуточно', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63679071',
        text:
          'Когда должны проводиться температурные съемки на недействующих горящих породных отвалах?',
        answers: [
          '1 раз в год: сентябрь',
          '1 раз в год: июль',
          '2 раза в год: июнь и сентябрь',
          '3 раза в год: май, июль и сентябрь',
        ],
        correctAnswers: ['1 раз в год: сентябрь'],
      },
      {
        code: '63679072',
        text:
          'Каково минимально допустимое сопротивление изоляции работающих в шахте электродвигателей угледобывающих и проходческих машин?',
        answers: ['0,5 МОм', '0,4 МОм', '0,3 МОм', '0,2 МОм'],
        correctAnswers: ['0,5 МОм'],
      },
      {
        code: '63679073',
        text:
          'Куда заносятся результаты проверки состава рудничной атмосферы и расход воздуха?',
        answers: [
          'В план проверки состава рудничной атмосферы',
          'В журнал учета работы дегазационных скважин',
          'В книгу наблюдений за пожарными участками и проверки состояния изоляционных сооружений',
          'В вентиляционный журнал',
        ],
        correctAnswers: ['В вентиляционный журнал'],
      },
      {
        code: '63679074',
        text:
          'Какое требование устанавливается в случаях, когда изоляцию отработанного выемочного участка невозможно выполнить в срок, не превышающий инкубационный период самовозгорания угля?',
        answers: [
          'Следует обеспечить проветривание тупиковых горных выработок',
          'Обоснованный срок окончания работ по изоляции отработанного выемочного участка согласовывается с инспектором территориального органа Ростехнадзора',
          'Работы по изоляции следует вести с выполнением мер, исключающих возникновение аварии, утвержденных техническим руководителем (главным инженером) угледобывающей организации',
          'Технический руководитель (главный инженер) угледобывающей организации распорядительным документом устанавливает обоснованный срок окончания работ по изоляции отработанного выемочного участка, превышающий инкубационный период самовозгорания угля, и утверждает меры по профилактике и предупреждению самовозгорания угля в отработанной части выемочного участка при отсутствии подвигания очистного забоя',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угледобывающей организации распорядительным документом устанавливает обоснованный срок окончания работ по изоляции отработанного выемочного участка, превышающий инкубационный период самовозгорания угля, и утверждает меры по профилактике и предупреждению самовозгорания угля в отработанной части выемочного участка при отсутствии подвигания очистного забоя',
        ],
      },
      {
        code: '63679075',
        text:
          'В какой документ главный энергетик (главный механик) шахты вносит запись осмотра электрических машин, аппаратов, трансформаторов, их взрывобезопасных оболочек, кабелей, заземления?',
        answers: [
          'В акт осмотра электрооборудования',
          'В журнал учета проверок электроснабжения шахты',
          'В книгу регистрации состояния электрооборудования и заземления',
          'В оперативный журнал участка',
        ],
        correctAnswers: [
          'В книгу регистрации состояния электрооборудования и заземления',
        ],
      },
      {
        code: '63679076',
        text:
          'Кто осуществляет контроль и надзор за соблюдением требований промышленной безопасности при проведении плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?',
        answers: [
          'Командир подразделения аварийно-спасательного формирования, обслуживающего шахту',
          'Главный инженер шахты',
          'Представители территориального органа Ростехнадзора',
          'Представители службы производственного контроля эксплуатирующей организации',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Представители службы производственного контроля эксплуатирующей организации',
        ],
      },
      {
        code: '63679077',
        text: 'Как характеризуется местное загазирование горных выработок?',
        answers: [
          'Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (АГК) в действующих выработках шахты',
          'Наличием скопления метана в виде слоя в выработках на участках длиной свыше 2 м с концентрацией более 2 %',
          'Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка',
        ],
        correctAnswers: [
          'Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (АГК) в действующих выработках шахты',
        ],
      },
      {
        code: '63679078',
        text:
          'В каком случае инертизация рудничной атмосферы в изолируемом пространстве может быть начата до окончания возведения взрывоустойчивых изоляционных перемычек?',
        answers: [
          'В случае, если это определено оперативным планом',
          'В случае, если продолжительность подачи инертного газа превышает допустимую',
          'В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'В случае, если объем подаваемого инертного газа превышает расход воздуха, поступающего на пожарный участок',
        ],
      },
      {
        code: '63679079',
        text:
          'С какой периодичностью на шахте проводится плановая воздушно-депрессионная съемка?',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 4 года',
          'Не реже 1 раза в 3 года',
        ],
        correctAnswers: ['Не реже 1 раза в 3 года'],
      },
      {
        code: '63679080',
        text:
          'Кем разрабатывается проектная документация, содержащая изменения технических решений по вентиляции на период строительства новой, реконструкции или подготовки горизонтов действующей шахты?',
        answers: [
          'Организацией, осуществляющей экспертизу промышленной безопасности объекта',
          'Эксплуатирующей организацией по согласованию с Ростехнадзором',
          'Эксплуатирующей организацией по согласованию с проектной организацией',
          'Проектной организацией',
          'Все ответы неверны',
        ],
        correctAnswers: ['Проектной организацией'],
      },
      {
        code: '63679081',
        text:
          'Каков максимально допустимый срок для изоляции отработанных выемочных участков на пластах угля, не склонных к самовозгоранию после окончания демонтажных работ?',
        answers: ['3 суток', '5 суток', '10 суток', '15 суток'],
        correctAnswers: ['10 суток'],
      },
      {
        code: '63679082',
        text:
          'На каком расстоянии друг от друга устанавливаются полки с сосудами и несущие конструкции водяных карманов?',
        answers: [
          'Не менее 500 мм друг от друга',
          'Не более 400 мм друг от друга',
          'Не менее 350 мм друг от друга',
          'Не более 300 мм друг от друга',
        ],
        correctAnswers: ['Не менее 500 мм друг от друга'],
      },
      {
        code: '63679083',
        text:
          'С какой периодичностью должна проводиться проверка уставок максимальной токовой защиты шахтных аппаратов напряжением до 3300 В?',
        answers: [
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 10 месяцев',
          'Не реже 1 раза в год',
          'Не реже 2 раз в 1,5 года',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63679084',
        text:
          'При каком максимальном содержании метана (% объемной доли) в исходящей струе крыла, шахты обеспечивается безопасное аэрогазовое состояние по метану?',
        answers: ['0,5 %', '0,75 %', '1 %', '2 %'],
        correctAnswers: ['0,75 %'],
      },
      {
        code: '63679085',
        text:
          'В течение какого времени схемы проветривания шахты в реверсивных и аварийных режимах хранятся на участке аэрологической безопасности?',
        answers: [
          'В течение 6 месяцев',
          'В течение 5 лет',
          'В течение 2 лет',
          'В течение года',
          'Все ответы неверны',
        ],
        correctAnswers: ['В течение года'],
      },
      {
        code: '63679086',
        text:
          'Какая максимально допустимая концентрация оксид углерода в рудничной атмосфере действующих горных выработках?',
        answers: [
          '2 мг/м3',
          '5 мг/м3',
          '10 мг/м3',
          '20 мг/м3',
          'Все ответы неверны',
        ],
        correctAnswers: ['20 мг/м3'],
      },
      {
        code: '63679087',
        text:
          'С какой периодичностью работники шахты должны проходить проверку знаний инструкций по профессиям?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63679088',
        text:
          'Каков максимально допустимый угол падения пласта при выемке надштрековых целиков у вентиляционных штреков одновременно с отработкой лав нижележащего этажа и при наличии оконтуривающих горных выработок?',
        answers: ['18°', '30°', '45°', '60°'],
        correctAnswers: ['30°'],
      },
      {
        code: '63679089',
        text:
          'Какова минимальная периодичность отбора проб воздуха после обнаружения превышений фоновых значений концентраций индикаторных пожарных газов в течение первых двух суток?',
        answers: [
          '1 раз в сутки',
          '2 раза в сутки',
          '3 раза в сутки',
          '4 раза в сутки',
        ],
        correctAnswers: ['2 раза в сутки'],
      },
      {
        code: '63679090',
        text:
          'В течение какого времени резервный автономный источник должен обеспечивать работу устройства связи с сетевым питанием при отключении напряжения питающей сети?',
        answers: [
          'Не менее 3 часов',
          'Не менее 2 часов',
          'Не менее 1 часа',
          'Не менее 0,5 часа',
        ],
        correctAnswers: ['Не менее 3 часов'],
      },
      {
        code: '63679091',
        text:
          'Что должна обеспечивать аппаратура автоматического контроля состава и расхода воздуха в горных выработках (АГК)?',
        answers: [
          'Только непрерывное измерение состава и расхода воздуха в действующих горных выработках',
          'Только телепередачу информации на диспетчерский пункт шахты и ее регистрацию',
          'Только дистанционную сигнализацию о превышении допустимых норм содержания метана и выдачу команд на автоматическое отключение электрооборудования',
          'Только КВШ выемочного участка',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63679092',
        text:
          'Какое максимально возможное время срабатывания автоматической газовой защиты по метану для стационарных метанометров?',
        answers: ['20 с', '25 с', '30 с', '15 с', 'Все ответы неверны'],
        correctAnswers: ['15 с'],
      },
      {
        code: '63679093',
        text:
          'Где применяется система аэрогазового контроля (АГК) на угольных шахтах?',
        answers: [
          'Только в подземных выработках угольных шахт, опасных по газу и (или) пыли',
          'Только в наземных помещениях шахт',
          'Только в поверхностных технологических комплексах шахт, связанных с приемкой, хранением и погрузкой угля',
          'Во всех перечисленных областях',
        ],
        correctAnswers: ['Во всех перечисленных областях'],
      },
      {
        code: '63679094',
        text:
          'Кем производится оповещение об аварии на шахте в соответствии с ПЛА? Укажите все правильные ответы.',
        answers: [
          'Главным инженером шахты',
          'Руководителем работ по локализации и ликвидации последствий аварии (РЛА)',
          'Лицом, назначенным руководителем работ по локализации и ликвидации последствий аварии',
          'Руководителем профессиональной аварийно-спасательной службы (формирования)',
          'Работником профессиональной аварийно-спасательной службы (формирования)',
        ],
        correctAnswers: [
          'Руководителем работ по локализации и ликвидации последствий аварии (РЛА)',
          'Лицом, назначенным руководителем работ по локализации и ликвидации последствий аварии',
        ],
      },
      {
        code: '63679095',
        text:
          'При какой глубине двух центрально расположенных стволов с лестничными отделениями можно не оборудовать подъемную установку в одном из них?',
        answers: [
          'При глубине до 70 м',
          'При глубине до 100 м',
          'При глубине до 500 м',
          'Независимо от глубины',
        ],
        correctAnswers: ['При глубине до 70 м'],
      },
      {
        code: '63679096',
        text:
          'Какие задачи должна указывать эксплуатирующая организация в подразделе "Задачи эксплуатирующей организации в области ПБ" при разработке положения о единой системе управления промышленной безопасностью и охраной труда для организаций по добыче (переработке) угля (горючих сланцев)?',
        answers: [
          'Обеспечение противоаварийной устойчивости и готовности к локализации и ликвидации последствий аварии',
          'Управление основными производственными рисками',
          'Выявление опасных технологий, участков, рабочих мест и предупреждение несчастных случаев',
          'Финансирование мероприятий по обеспечению безопасной эксплуатации ОПО',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679097',
        text:
          'Какова минимально допустимая ширина части выработки, предназначенной для передвижения людей, в горизонтальных и наклонных горных выработках?',
        answers: ['0,7 м', '0,6 м', '0,5 м', '0,9 м'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63679098',
        text:
          'С какой периодичностью измеряется производительность вентиляторов местного проветривания?',
        answers: [
          '1 раз в месяц',
          '1 раз в 2 месяца',
          '1 раз в 3 месяца',
          '2 раза в год',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63679099',
        text:
          'К какому виду прогноза динамических явлений относится прогноз по данным, полученным при ведении геолого-разведочных работ, и прогноз по непрерывным сейсмоакустическим наблюдениям?',
        answers: [
          'Прогноз перед вскрытием угольных пластов горными выработками',
          'Локальный прогноз',
          'Текущий прогноз',
          'Региональный прогноз',
          'Прогноз выбросоопасности и удароопасности пород',
        ],
        correctAnswers: ['Региональный прогноз'],
      },
      {
        code: '63679100',
        text:
          'Какова минимально допустимая высота ограждающих решеток перед колесом вентилятора в каналах вентиляторов главного проветривания (ВГП) и вспомогательных вентиляторных установок (ВВУ)?',
        answers: ['1,2 м', '1,5 м', '1,8 м', '2,1 м'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63679101',
        text:
          'На каком основании производится выключение поверхностных газоотсасывающих установок для проведения профилактических осмотров и ремонтов?',
        answers: [
          'По письменному распоряжению руководителя организации',
          'По письменному распоряжению начальника участка аэрологической безопасности',
          'По письменному распоряжению территориального органа Ростехнадзора',
          'По письменному распоряжению главного инженера шахты с уведомлением не позднее чем за сутки, начальника участка аэрологической безопасности',
        ],
        correctAnswers: [
          'По письменному распоряжению главного инженера шахты с уведомлением не позднее чем за сутки, начальника участка аэрологической безопасности',
        ],
      },
      {
        code: '63679102',
        text:
          'Какая должна быть скорость движения составов монорельсовых дизельных дорог при перевозке длинномерных и крупногабаритных грузов по горизонтальным и наклонным горным выработкам угольных шахт?',
        answers: [
          'Не более 1 м/с',
          'Не более 2 м/с',
          'Не более 3 м/с',
          'Не более 4 м/с',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не более 1 м/с'],
      },
      {
        code: '63679103',
        text:
          'Где находятся журнал эксплуатации системы аэрогазового контроля, машинные (иные) носители с архивной информацией об аэрогазовом режиме?',
        answers: [
          'На рабочем месте дежурных электрослесарей',
          'На рабочем месте руководителя группы аэрогазового комплекса',
          'На рабочем месте начальника участка аэрологической безопасности',
          'На рабочем месте оператора аэрогазового контроля (АГК)',
          'На рабочем месте главного механика',
        ],
        correctAnswers: [
          'На рабочем месте оператора аэрогазового контроля (АГК)',
        ],
      },
      {
        code: '63679104',
        text:
          'На каком максимальном расстоянии от станций посадки и схода, расположенных на участках ленточных конвейеров, используемых для перевозки людей, должны быть установлены переговорные устройства, обеспечивающие связь пассажиров с лицами, управляющими конвейером?',
        answers: ['12 м', '10 м', '5 м', '3 м', 'Все ответы неверны'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63679105',
        text:
          'Какое из перечисленных требований к отработке пластов угля, склонных к самовозгоранию, указано неверно?',
        answers: [
          'При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь',
          'Отработку крутых и крутонаклонных пластов угля, склонного к самовозгоранию, ведут отдельными выемочными блоками с оставлением между ними противопожарных целиков, прорезаемых только на уровне откаточного и вентиляционного горизонтов',
          'Места и размеры целиков угля должны быть определены техническим проектом и (или) проектной документацией',
          'Размер целика по простиранию равен мощности пласта, но не менее 6 м',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь',
        ],
      },
      {
        code: '63679106',
        text:
          'Каково максимально допустимое расстояние установки анкерной крепи от забоя горной выработки, при наличии в кровле горной выработки неустойчивых пород обрушающихся в проходческом забое, до установки анкерной крепи?',
        answers: ['0,5 м', '1 м', '2 м', '3 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63679107',
        text:
          'Какое из перечисленнных лиц может быть назначено машинистом подъемных машин?',
        answers: [
          'Прошедшее специальное обучение, получившее соответствующее удостоверение и прошедшее трехмесячную стажировку',
          'Проработавшее не менее 1 года в угледобывающей организации',
          'С общим стажем работы на шахте не менее 1 года, прошедшее специальное обучение, получившее соответствующее удостоверение, прошедшее двухмесячную стажировку',
          'С общим стажем работы на шахте не менее 6 месяцев, прошедшее специальное обучение, получившее соответствующее удостоверение, прошедшее трехмесячную стажировку.',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'С общим стажем работы на шахте не менее 1 года, прошедшее специальное обучение, получившее соответствующее удостоверение, прошедшее двухмесячную стажировку',
        ],
      },
      {
        code: '63679108',
        text:
          'С какой периодичностью рассматривается и утверждается раздел проекта системы аэрогазового контроля главным инженером шахты независимо от наличия корректировок?',
        answers: [
          '2 раза в год до 1 марта и 31 декабря следующего за текущим годом',
          '1 раз в год до 1 марта следующего за текущим годом',
          '1 раз в год до 1 января следующего за текущим годом',
          'При отсутствии корректировок раздел проекта аэрогазового контроля не рассматривается',
        ],
        correctAnswers: ['1 раз в год до 1 января следующего за текущим годом'],
      },
      {
        code: '63679109',
        text:
          'При какой максимальной концентрации метана в газодренажных выработках, имеющих сопряжения с действующими горными выработками, и регулируемую подачу свежего воздуха для подсвежения метановоздушной смеси, в газодренажных скважинах, в расположенных на поверхности газоотсасывающих трубопроводах и на поверхностных ГОУ обеспечивается безопасная эксплуатация выемочных участков с использованием схем проветривания с изолированным отводом метана из выработанного пространства?',
        answers: ['1,5 %', '2 %', '3,5 %', '5 %'],
        correctAnswers: ['3,5 %'],
      },
      {
        code: '63679110',
        text:
          'С какой периодичностью должны проверяться путь, путевые устройства, водоотводные канавы, стрелочные переводы, путевые сигналы и знаки лицом, назначенным распорядительным документом руководителя шахты?',
        answers: [
          'Не менее 2 раз в месяц',
          'Не менее 1 раза в месяц',
          'Не менее 1 раза в квартал',
          'Не менее 2 раз в квартал',
        ],
        correctAnswers: ['Не менее 2 раз в месяц'],
      },
      {
        code: '63679111',
        text:
          'Каким должно быть время срабатывания защиты трансформаторов передвижных участковых понизительных подстанций (ПУПП) напряжением 3300 В и каждого отходящего от них присоединения от токов короткого замыкания?',
        answers: [
          'В пределах 0,2 с',
          'В пределах 0,5 с',
          'В пределах 0,8 с',
          'В пределах 1,5 с',
        ],
        correctAnswers: ['В пределах 0,2 с'],
      },
      {
        code: '63679112',
        text:
          'Какую защиту устанавливают в электрической сети напряжением 1200 В, от которой питается передвижная участковая подземная подстанция (трансформатор), в тупиковой выработке?',
        answers: [
          'От двухфазных замыканий',
          'От трехфазных замыканий',
          'От однофазных замыканий на землю',
          'От двухфазных замыканий на землю',
          'Все ответы неверны',
        ],
        correctAnswers: ['От однофазных замыканий на землю'],
      },
      {
        code: '63679113',
        text:
          'В каком месте устанавливаются датчики стационарной аппаратуры аэрогазового контроля оксида углерода? Укажите все правильные ответы.',
        answers: [
          'В выработке с исходящей из очистного забоя струей воздуха, в верхней ее части, на расстоянии 10 - 20 м от забоя',
          'В газоотсасывающих трубопроводах подземных и поверхностных газоотсасывающих установок (ГОУ)',
          'В обособленно проветриваемых выработках выемочного участка на расстоянии 10 - 20 м от сопряжения с выработкой, по которой исходящая струя воздуха выдается за пределы выемочного участка',
          'В камерах газоотсасывающих установок над газоотсасывающими агрегатами',
          'В выработке выемочного участка с исходящей струей воздуха на расстоянии 10 - 20 м от сопряжения с выработкой, по которой исходящая струя выдается за пределы выемочного участка',
        ],
        correctAnswers: [
          'В выработке с исходящей из очистного забоя струей воздуха, в верхней ее части, на расстоянии 10 - 20 м от забоя',
          'В газоотсасывающих трубопроводах подземных и поверхностных газоотсасывающих установок (ГОУ)',
        ],
      },
      {
        code: '63679114',
        text:
          'В каких случаях запрещается применение газовой сварки в горных выработках, во взрывопожароопасных и пожароопасных надшахтных зданиях и сооружениях шахт (фабрик)?',
        answers: [
          'Только с использованием ацетилена',
          'Только с использованием пропан-бутана',
          'Только с использованием каких-либо горючих углеводородов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63679115',
        text:
          'В каком случае допускается производить отключение электроэнергии ниже предаварийных уставок, указанных в Инструкции по аэрологической безопасности угольных шахт?',
        answers: [
          'По решению начальника участка аэрологической безопасности',
          'По решению государственного метрологического контроля и надзора',
          'По решению главного инженера шахты',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['По решению главного инженера шахты'],
      },
      {
        code: '63679116',
        text:
          'Кем выполняется перерасчет параметров проветривания выемочного участка угольных шахт?',
        answers: [
          'Главным механиком шахты',
          'Главным инженером шахты',
          'Лицом, ответственным за проведение работ на данном участке',
          'Начальником участка аэрологической безопасности',
        ],
        correctAnswers: ['Начальником участка аэрологической безопасности'],
      },
      {
        code: '63679117',
        text:
          'Кем определяется расположение постоянных мест проведения огневых работ?',
        answers: [
          'Командиром профессиональной аварийно-спасательной службы',
          'Руководителем шахты (фабрики)',
          'Главным инженером шахты (фабрики)',
          'Командиром пожарной части, обслуживающей организацию',
        ],
        correctAnswers: ['Руководителем шахты (фабрики)'],
      },
      {
        code: '63679118',
        text:
          'В какие сроки должен осматриваться каждый локомотив, находящийся в эксплуатации, начальником электровозного депо или механиком структурного подразделения шахтного транспорта?',
        answers: [
          'Еженедельно',
          '1 раз в 10 дней',
          '1 раз в 2 недели',
          'Ежесменно',
        ],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63679119',
        text:
          'С какой периодичностью проводится замер метана в местах установки электрооборудования в шахтах, опасных по газу и пыли?',
        answers: [
          'Ежесменно',
          'Еженедельно',
          'Периодичность устанавливается проектом, утвержденным территориальным органом Ростехнадзора',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63679120',
        text:
          'При какой концентрации метана возле буровых станков и комбайнов допускается возобновление их работы после остановки?',
        answers: [
          'После снижения концентрации менее 2 %',
          'После снижения концентрации менее 1,5 %',
          'После снижения концентрации менее 1,2 %',
          'После снижения концентрации менее 1 %',
        ],
        correctAnswers: ['После снижения концентрации менее 1 %'],
      },
      {
        code: '63679121',
        text:
          'Кто осуществляет контроль состояния реверсивных, переключающих и герметизирующих устройств вентиляторов главного проветривания при проведении плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?',
        answers: [
          'Начальник службы аэрологической безопасности',
          'Главный инженер',
          'Специалист аварийно-спасательного формирования',
          'Главный механик шахты',
        ],
        correctAnswers: ['Главный механик шахты'],
      },
      {
        code: '63679122',
        text:
          'При каком минимально допустимом значении пористости угля производится увлажнение угольного пласта?',
        answers: ['2 %', '5 %', '8 %', 'Не нормируется'],
        correctAnswers: ['5 %'],
      },
      {
        code: '63679123',
        text:
          'Какова максимально допустимая скорость грузового состава с пассажирской вагонеткой при перевозке в ней людей?',
        answers: ['10 км/ч', '12 км/ч', '15 км/ч', '20 км/ч'],
        correctAnswers: ['12 км/ч'],
      },
      {
        code: '63679124',
        text:
          'Какова периодичность проверки главным механиком (заместителем главного механика) шахты правильности работы предохранительного тормоза и защитных устройств подъемной установки?',
        answers: [
          'Не реже 1 раза в 15 дней',
          'Не реже 1 раза в 20 дней',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 1,5 месяца',
        ],
        correctAnswers: ['Не реже 1 раза в 15 дней'],
      },
      {
        code: '63679125',
        text:
          'В каком случае применяются однокнопочные посты для управления магнитными пускателями в участковых сетях угольных шахт напряжением до 1200 В?',
        answers: [
          'Если магнитные пускатели с искробезопасными схемами управления',
          'Только в шахтах, неопасных по газу',
          'Если их применение установлено проектной документацией',
          'Если эти посты применяются для отключения',
        ],
        correctAnswers: ['Если эти посты применяются для отключения'],
      },
      {
        code: '63679126',
        text:
          'Какова минимально допустимая ширина проемов, в которых устраивают двери для прохода людей?',
        answers: ['0,7 м', '1 м', '1,2 м', 'Не нормируется'],
        correctAnswers: ['0,7 м'],
      },
      {
        code: '63679127',
        text:
          'Какая из перечисленных характеристик соответствует мелкокусковатому типу нарушенности угля?',
        answers: [
          'Заметно выраженная слоистость, уголь в массиве монолитный, устойчивый, не осыпается. Разламывается на куски, ограниченные слоистостью и трещинами',
          'Слоистость и трещиноватость затушеваны. Состоит из тонкоперетертого угля (угольная мука). Неустойчив, осыпается интенсивно. Между пальцами раздавливается легко',
          'Слоистость и трещиноватость затушеваны. Основная масса состоит из мелких зерен угля, между которыми располагается землистый уголь (угольная мука). Достаточно спрессован, между пальцами раздавливается с трудом. Уголь слабоустойчив и склонен к осыпанию',
          'Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом',
          'Слоистость и трещиноватость затушеваны. Уголь слагается из отдельных линз, поверхность их зеркально отполирована и несет на себе борозды и штрихи скольжения. При механическом воздействии превращается иногда в угольную мелочь',
        ],
        correctAnswers: [
          'Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом',
        ],
      },
      {
        code: '63679128',
        text: 'На основе чего выполняется геодинамическое районирование?',
        answers: [
          'Анализа геологических, геофизических, геохимических и картографических материалов',
          'Дешифрирования космических и аэрофотоснимков, морфометрического анализа земной поверхности',
          'Геодезических, геофизических и геохимических полевых инструментальных наблюдений',
          'Всего перечисленного',
        ],
        correctAnswers: ['Всего перечисленного'],
      },
      {
        code: '63679129',
        text:
          'Какое из перечисленных мероприятий не проводится при ликвидации последствий горного удара, обрушения пород, оползня?',
        answers: [
          'Определение возможности развития обрушения (оползня) и безопасных способов выполнения горноспасательных работ',
          'Организация работы по разборке обрушившейся горной массы и (или) проведение поисковых выработок из возможно большего числа мест',
          'Установление связи с застигнутыми аварией людьми, не имеющими возможности выйти из зоны аварии',
          'Мониторинг концентрации индикаторных пожарных газов',
        ],
        correctAnswers: ['Мониторинг концентрации индикаторных пожарных газов'],
      },
      {
        code: '63679130',
        text:
          'При помощи чего допускается присоединение к сети забойных машин?',
        answers: [
          'При помощи магнитных станций или переключателей, управляемых вручную',
          'При помощи специальных магнитных станций, управляемых дистанционно или вручную',
          'При помощи магнитных пускателей, управляемыми однокнопочными постами для включения и отключения забойных машин',
          'При помощи магнитных пускателей или специальных магнитных станций, управляемых дистанционно',
        ],
        correctAnswers: [
          'При помощи магнитных пускателей или специальных магнитных станций, управляемых дистанционно',
        ],
      },
      {
        code: '63679131',
        text: 'Какое из перечисленных требований безопасности указано неверно?',
        answers: [
          'Питание аппаратуры контроля расхода воздуха осуществляется от пускателя вентилятора',
          'Питание аппаратуры контроля содержания метана осуществляется с ввода общего выключателя',
          'Пускатель вентилятора местного проветривания (ВМП) подключают к вводу общего автоматического выключателя распределительного пункта участка с помощью отдельного автоматического выключателя, не имеющего нулевой защиты',
          'Для подсоединения аппаратуры контроля расхода воздуха и средств автоматизации в сети напряжением до 42 В применяются тройниковые муфты и аналогичные устройства',
        ],
        correctAnswers: [
          'Для подсоединения аппаратуры контроля расхода воздуха и средств автоматизации в сети напряжением до 42 В применяются тройниковые муфты и аналогичные устройства',
        ],
      },
      {
        code: '63679132',
        text:
          'Кто назначает ИТР для выполнения мероприятий по безопасному разгазированию горных выработок и расследованию причин загазирования?',
        answers: [
          'Начальник участка аэрологической безопасности',
          'Руководитель шахты',
          'Инспектор Ростехнадзора',
          'Главный инженер шахты',
          'Все ответы неверны',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679133',
        text:
          'На каком максимальном расстоянии допускается установка автоматического выключателя с аппаратом защиты от утечек тока под скважиной при питании подземных электроприемников с поверхности через скважины?',
        answers: [
          '20 м от скважины',
          '15 м от скважины',
          '10 м от скважины',
          '8 м от скважины',
        ],
        correctAnswers: ['10 м от скважины'],
      },
      {
        code: '63679134',
        text:
          'Кем определяются конкретные места в горных выработках, в которых необходимо провести проверку состава рудничной атмосферы?',
        answers: [
          'Главным инженером шахты',
          'Работником военизированных горноспасательных частей, обслуживающих шахту',
          'Работником газоаналитической лаборатории',
          'ИТР участка аэрологической безопасности (АБ)',
        ],
        correctAnswers: ['ИТР участка аэрологической безопасности (АБ)'],
      },
      {
        code: '63679135',
        text:
          'С какой периодичностью и с каким интервалом проводят оценку несущей способности применяемых анкеров в анкерной крепи при проведении или восстановлении выработок?',
        answers: [
          'В начале и в конце каждой смены через каждые 100 м длины горной выработки',
          'Не реже 1 раза в месяц через каждые 200 м длины горной выработки',
          'Не реже 1 раза в 2 месяца через каждые 100 м длины горной выработки',
          'Не реже 1 раза в квартал через каждые 200 м длины горной выработки',
        ],
        correctAnswers: [
          'Не реже 1 раза в месяц через каждые 200 м длины горной выработки',
        ],
      },
      {
        code: '63679136',
        text:
          'Под чьим руководством должны проводиться профилактические осмотры и ремонты подземных газоотсасывающих установок в выходные дни или нерабочие смены?',
        answers: [
          'Под руководством специалиста участка аэрологической безопасности',
          'Под руководством горного диспетчера',
          'Под руководством главного инженера шахты',
          'Под руководством механика участка',
        ],
        correctAnswers: ['Под руководством механика участка'],
      },
      {
        code: '63679137',
        text:
          'Кем определяются места расположения скважин и периодичность их бурения при проведении прогноза динамических явлений в месте вскрытия угольных пластов?',
        answers: [
          'Ростехнадзором',
          'Главным инженером шахты',
          'Персоналом службы прогноза динамических явлений',
          'ИТР геологической службы угледобывающей организации',
        ],
        correctAnswers: ['ИТР геологической службы угледобывающей организации'],
      },
      {
        code: '63679138',
        text:
          'Какие из перечисленных условий для обеспечения безопасности ведения горных работ должны быть соблюдены при применении вентиляторов местного проветривания (ВМП) с пневматическими двигателями для проветривания проводимых или погашаемых вентиляционных горных выработок, примыкающих к лаве?',
        answers: [
          'Только то, что ВМП должен быть расположен не менее 15 м от сопряжения с лавой, считая по ходу вентиляционной струи',
          'Только то, что длина тупиковой части выработки не должна превышать 30 м',
          'Только то, что содержание метана в исходящей из тупиковой части выработки струе не должно превышать 1 %',
          'Только то, что конструкция ВМП исключает возможность воспламенения метана при ударах и трении вращающихся частей о корпус вентилятора',
          'Все перечисленные условия',
        ],
        correctAnswers: ['Все перечисленные условия'],
      },
      {
        code: '63679139',
        text:
          'Какое из перечисленных требований при тушении пожаров в вертикальных горных выработках указано верно?',
        answers: [
          'При тушении активным способом и локализации пожара в вертикальном стволе с исходящей вентиляционной струей или в надшахтном здании этого ствола возможно изменение направления вентиляционной струи',
          'Пожары в вертикальных горных выработках тушатся активным способом снизу вверх',
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
          'Нахождение людей в вертикальных горных выработках во время тушения в них пожара допускается при условии выполнения мер, исключающих опрокидывание вентиляционной струи',
        ],
        correctAnswers: [
          'При тушении активным способом и локализации пожаров в вертикальных горных выработках с восходящей струей воздуха с применением воды, подаваемой с поверхности, выполняются меры, исключающие опрокидывание вентиляционной струи',
        ],
      },
      {
        code: '63679140',
        text:
          'С какой периодичностью следует проводить испытания парашютов на клетях для перемещения людей и противовесах людских и грузолюдских подъемных установок?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63679141',
        text:
          'С какой периодичностью производится ревизия ручных электросверл с их разборкой в механической мастерской?',
        answers: [
          'Не реже 1 раза в полгода',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 2 месяца',
          'Не реже 1 раза в месяц',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63679142',
        text:
          'Какие предохранители разрешается применять на трансформаторах, находящихся на поверхности и питающих подземные электрические сети, снабженные защитой от утечек тока, в участковых сетях угольных шахт напряжением до 1200 В?',
        answers: [
          'Предохранители без патронов',
          'Пробивные предохранители',
          'Указанные главным механиком в письменном распоряжении по шахте',
          'Предохранители с калиброванными плавкими вставками',
          'Все ответы неверны',
        ],
        correctAnswers: ['Предохранители с калиброванными плавкими вставками'],
      },
      {
        code: '63679143',
        text:
          'Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней винтового профиля из стали А400С, А500С №25?',
        answers: ['120 кН', '140 кН', '160 кН', '240 кН'],
        correctAnswers: ['240 кН'],
      },
      {
        code: '63679144',
        text:
          'Каким образом распределяется максимальная продолжительность (время) непрерывного пребывания работников ПАСС(Ф) в зоне высоких температур при передвижении по подземным горным выработкам с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'По решению технического руководителя (главного инженера) ОПО',
          'Половина на передвижение в направлении "туда" и половина - в направлении "обратно"',
          'Две трети на передвижение в направлении "туда" и одна треть - в направлении "обратно"',
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
        correctAnswers: [
          'Одна треть на передвижение в направлении "туда" и две трети - в направлении "обратно"',
        ],
      },
      {
        code: '63679145',
        text:
          'С какой периодичностью осматриваются устья ликвидированных горных выработок, имеющих выход на земную поверхность, комиссией, назначенной распорядительным документом руководителя шахты?',
        answers: [
          '1 раз в год',
          'Не менее 1 раза в год',
          'Не менее 2 раз в год',
        ],
        correctAnswers: ['Не менее 2 раз в год'],
      },
      {
        code: '63679146',
        text:
          'Какую величину не должна превышать мощность короткого замыкания в подземной сети шахты?',
        answers: ['50 МВА', '75 МВА', '90 МВА', '100 МВА'],
        correctAnswers: ['100 МВА'],
      },
      {
        code: '63679147',
        text:
          'Какой должна быть высота подвески контактного провода от головки рельса в выработке околоствольного двора на участках передвижения людей до места посадки?',
        answers: [
          'Не менее 2,2 м',
          'Не менее 2,1 м',
          'Не менее 2 м',
          'Не менее 1,8 м',
        ],
        correctAnswers: ['Не менее 2,2 м'],
      },
      {
        code: '63679148',
        text:
          'Какова максимально допустимая масса груза, перевозимого одним человеком на конвейере?',
        answers: ['25 кг', '28 кг', '30 кг', '32 кг'],
        correctAnswers: ['25 кг'],
      },
      {
        code: '63679149',
        text: 'Чем оборудуются газоотсасывающие установки?',
        answers: [
          'Стационарными метанометрами',
          'Датчиками депрессии для контроля работы вентиляторной установки и датчиками оксида углерода (датчиками индикаторных газов) для обнаружения признаков ранних стадий возникновения пожаров',
          'Средствами контроля расхода газовой смеси',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63679150',
        text:
          'Кто осуществляет перевод подземного пожара из действующего в потушенный на угольной шахте?',
        answers: [
          'Комиссия, созданная распорядительным документом руководителя шахты',
          'Главным инженером шахты',
          'Инспектором территориального органа Ростехнадзора',
          'Руководителем ПАСС(Ф)',
          'Все ответы неверны.',
        ],
        correctAnswers: [
          'Комиссия, созданная распорядительным документом руководителя шахты',
        ],
      },
      {
        code: '63679151',
        text:
          'С какой периодичностью утверждается план работ по профилактике самовозгорания склонных к самовозгоранию пластов угля, главным инженером шахты (разреза)?',
        answers: [
          'Дважды в год',
          'Ежегодно',
          'Ежеквартально',
          'Ежемесячно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежеквартально'],
      },
      {
        code: '63679152',
        text:
          'В каком случае допускается протягивать один предохранительный канат между крайними секциями щитового перекрытия при отработке мощных пластов?',
        answers: [
          'При мощности пласта менее 6 м',
          'Если данная мера обоснована документацией по ведению горных работ',
          'В случае постоянного присутствия наблюдающего персонала',
          'Не допускается ни в каком случае',
          'Все ответы неверны',
        ],
        correctAnswers: ['При мощности пласта менее 6 м'],
      },
      {
        code: '63679153',
        text:
          'Кем производится ревизия и проверка взрывобезопасности электрооборудования в участковых сетях угольных шахт напряжением до 1200 В?',
        answers: [
          'Только главным энергетиком (главным механиком) шахты или назначенным им лицом',
          'Дежурными электрослесарями участка',
          'Механиком участка или его заместителем',
          'Группой электрослесарей под контролем главного энергетика (главного механика) шахты или лица, им назначенного',
        ],
        correctAnswers: [
          'Группой электрослесарей под контролем главного энергетика (главного механика) шахты или лица, им назначенного',
        ],
      },
      {
        code: '63679154',
        text:
          'Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе в подземных горных выработках?',
        answers: [
          'Из 2 человек',
          'Из 3 человек',
          'Из 5 человек',
          'Из 6 человек',
          'Все ответы неверны',
        ],
        correctAnswers: ['Из 5 человек'],
      },
      {
        code: '63679155',
        text:
          'Какие мероприятия должны выполняться при обнаружении порезов или признаков значительного абразивного износа на оболочке кабелей напряжением 3300 В?',
        answers: [
          'Замена всего кабеля',
          'Замена поврежденного участка с установкой линейного электрического соединителя (штепсельного разъема)',
          'Ремонт оболочки с применением вулканизации или установкой ремонтных муфт из композиционных материалов, имеющих соответствующие разрешительные документы на применение в шахтах',
          'Любые из перечисленных',
        ],
        correctAnswers: ['Любые из перечисленных'],
      },
      {
        code: '63679156',
        text:
          'В каких горных выработках угольных шахт минимальная средняя по сечению скорость воздуха составляет 0,25 м/с?',
        answers: [
          'В тупиковых горных выработках, проводимых по пластам, опасным по внезапным выбросам угля (породы) и газа, опасным по суфлярным проявлениям',
          'В тупиковых горных выработках, проводимых по угольным пластам мощностью более 2 м, при разности между природной и остаточной метаноносностью пласта на участке их проведения 5 м³/т и выше',
          'В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт',
          'В призабойном пространстве очистных выработок негазовых шахт и газовых шахт',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт',
        ],
      },
      {
        code: '63679157',
        text:
          'Какое из перечисленных действий допускается при ведении горноспасательных работ в подземных горных выработках в зоне высоких температур с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Ведение горноспасательных работ, не связанных со спасением людей, в зоне высоких температур с непригодной для дыхания рудничной атмосферой без средств противотепловой индивидуальной защиты при температуре выше 50 °C',
          'Ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
          'Привлечение членов вспомогательной горноспасательной команды для ведения горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой',
          'Все перечисленные действия',
        ],
        correctAnswers: [
          'Ведение горноспасательных работ в зоне высоких температур с непригодной для дыхания рудничной атмосферой при отсутствии связи горноспасательного отделения, ведущего горноспасательные работы, с руководителем горноспасательных работ или с горноспасательным отделением, находящимся на подземной горноспасательной базе, при условии, что эти работы связаны со спасением людей',
        ],
      },
      {
        code: '63679158',
        text:
          'Кого должны поставить в известность люди при появлении в горных выработках, проводимых в границах опасных зон, признаков возможного прорыва воды?',
        answers: [
          'Технического руководителя шахты',
          'Горного диспетчера',
          'Руководителя территориального органа Ростехнадзора',
          'Командира профессиональной аварийно-спасательной службы',
        ],
        correctAnswers: ['Горного диспетчера'],
      },
      {
        code: '63679159',
        text:
          'Каково максимально допустимое содержание метана в горных выработках при котором допускается проверка изоляции и поиск повреждений в силовых кабелях, проложенных в этих выработках?',
        answers: ['1 %', '1,5 %', '2 %', '2,5 %'],
        correctAnswers: ['1 %'],
      },
      {
        code: '63679160',
        text:
          'Какова минимально допустимая площадь главных заземлителей из стальной полосы в зумпфе, водосборнике или специальном колодце?',
        answers: ['0,75 м2', '1 м2', '1,2 м2', '1,5 м2'],
        correctAnswers: ['0,75 м2'],
      },
      {
        code: '63679161',
        text:
          'В течение какого времени следует отбирать пробы воздуха после обнаружения превышений фоновых значений концентраций индикаторных пожарных газов во всех контрольных точках выемочного участка?',
        answers: [
          'В течение первых суток - с периодичностью не менее 3 раз в сутки, в течение следующей недели - с периодичностью 2 раза в сутки',
          'В течение первых двух суток - с периодичностью не менее 2 раз в сутки, в течение следующей недели - с периодичностью 1 раз в сутки',
          'В течение первой недели - с периодичностью не менее 2 раз в сутки, в течение следующей недели - с периодичностью 1 раз в сутки',
          'В течение первых 12 часов через каждые 2 часа',
        ],
        correctAnswers: [
          'В течение первых двух суток - с периодичностью не менее 2 раз в сутки, в течение следующей недели - с периодичностью 1 раз в сутки',
        ],
      },
      {
        code: '63679162',
        text:
          'Какая максимальная скорость воздуха в стволах, предназначенных для спуска и подъема грузов и используемых при аварии для вывода людей?',
        answers: ['5 м/с', '8 м/с', '10 м/с', '15 м/с', 'Все ответы неверны'],
        correctAnswers: ['10 м/с'],
      },
      {
        code: '63679163',
        text:
          'В течение какого времени должны расследоваться события, предшествующие динамическим явлениям при первом их проявлении?',
        answers: [
          'В течение 24 часов',
          'В течение 3 дней',
          'В течение 10 дней',
          'В течение недели',
        ],
        correctAnswers: ['В течение 24 часов'],
      },
      {
        code: '63679164',
        text: 'Кто составляет вентиляционный план угольной шахты?',
        answers: [
          'Главный инженер шахты',
          'Начальник участка аэрологической безопасности (АБ)',
          'Начальник проходческого (добычного) участка',
        ],
        correctAnswers: ['Начальник участка аэрологической безопасности (АБ)'],
      },
      {
        code: '63679165',
        text:
          'В каком случае допускается перевозка людей ленточными конвейерами в выработках с углами наклона до 18° при номинальной скорости ленты более 3,15 м/с?',
        answers: [
          'При ширине ленты более 1000 мм',
          'По нижней ветви ленты конвейера',
          'По верхней ветви ленты конвейера',
          'По специальным проектам, согласованным с Ростехнадзором в случае отсутствия других средств механизированной доставки людей к месту ведения работ',
        ],
        correctAnswers: [
          'По специальным проектам, согласованным с Ростехнадзором в случае отсутствия других средств механизированной доставки людей к месту ведения работ',
        ],
      },
      {
        code: '63679166',
        text:
          'Какова минимальная периодичность отбора проб воздуха в течение следующей недели после отбора проб воздуха в первые двое суток после обнаружения превышений фоновых значений концентраций индикаторных пожарных газов?',
        answers: [
          '1 раз в сутки',
          '2 раза в сутки',
          '3 раза в сутки',
          '4 раза в сутки',
        ],
        correctAnswers: ['1 раз в сутки'],
      },
      {
        code: '63679167',
        text:
          'Какое минимальное давление жидкости в трубопроводе должно быть для туманообразующей завесы?',
        answers: ['0,5 МПа', '1 МПа', '1,5 МПа', '2 МПа'],
        correctAnswers: ['1 МПа'],
      },
      {
        code: '63679168',
        text:
          'Какие шахты     относятся к I категории шахт по газу (метану и (или) диоксиду углерода)?',
        answers: [
          'С относительной газообильностью до 5 м3/т',
          'С относительной газообильностью от 5 до 10 м3/т',
          'С относительной газообильностью от 10 до 15 м3/т',
          'С относительной газообильностью 15 м3/т и более',
        ],
        correctAnswers: ['С относительной газообильностью до 5 м3/т'],
      },
      {
        code: '63679169',
        text:
          'С какой периодичностью главный инженер угледобывающей организации должен организовывать обследования земной поверхности в целях выявления на ней провалов и трещин, образовавшихся в результате ведения подземных горных работ, и контроля состояния ликвидированных провалов и трещин?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не менее 1 раза в год (весной)',
          'Не менее 2 раз в год (весной и осенью)',
          'Не менее 2 раз в год (летом и зимой)',
        ],
        correctAnswers: ['Не менее 2 раз в год (весной и осенью)'],
      },
      {
        code: '63679170',
        text:
          'В какой период осуществляется рассмотрение планов развития горных работ?',
        answers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 августа по 25 ноября года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 октября по 25 января года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 19 сентября по 30 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
        correctAnswers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
      },
      {
        code: '63679171',
        text:
          'Каково минимально допустимое сечение главных заземлителей из стальной полосы (троса)?',
        answers: ['50 мм2', '100 мм2', '120 мм2', '150 мм2'],
        correctAnswers: ['100 мм2'],
      },
      {
        code: '63679172',
        text:
          'С какой периодичностью измеряется общее сопротивление заземляющей сети у каждого заземлителя?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в месяц',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '63679173',
        text:
          'Какое из перечисленных требований к оборудованию, эксплуатации и контролю работы поверхностных газоотсасывающих установок указано верно?',
        answers: [
          'Для объектов поверхностной газоотсасывающей установки предусматривается I категория устройства молниезащиты',
          'Гибкие соединения, уплотнения, виброгасящие прокладки в газоотводящем тракте должны быть шунтированы не менее чем двумя гибкими проводниками сечением не менее 15 мм²',
          'Техническое обслуживание газоотсасывающих установок проводится в соответствии с инструкцией по эксплуатации газоотсасывающих установок, утвержденной начальником участка аэрологической безопасности',
          'Выхлопной патрубок газоотсасывающей установки должен располагаться вертикально и иметь высоту не менее 3 м от верхней кромки корпуса газоотсасывающей установки',
        ],
        correctAnswers: [
          'Выхлопной патрубок газоотсасывающей установки должен располагаться вертикально и иметь высоту не менее 3 м от верхней кромки корпуса газоотсасывающей установки',
        ],
      },
      {
        code: '63679174',
        text:
          'В каком из перечисленных случаев руководитель горноспасательных работ не проводит организацию подземной горноспасательной базы?',
        answers: [
          'Если горноспасательные работы проводятся в горных выработках с непригодной для дыхания рудничной атмосферой',
          'Если горноспасательные работы выполняются для ликвидации последствий прорыва воды при выполнении водолазных спусков',
          'Если все прибывшие на опасный производственный объект (ОПО) горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий (ПЛА), связанным со спасением людей, застигнутых аварией',
          'Если горноспасательные работы выполняются для ликвидации последствий прорыва обводненной горной массы при выполнении водолазных работ',
        ],
        correctAnswers: [
          'Если все прибывшие на опасный производственный объект (ОПО) горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий (ПЛА), связанным со спасением людей, застигнутых аварией',
        ],
      },
      {
        code: '63679175',
        text:
          'При какой продолжительности аварийных загазирований горных выработок их расследование проводится под руководством начальника участка аэрологической безопасности (АБ) или его заместителя (помощника)?',
        answers: [
          'Более 2 часов, но менее 6 часов',
          'Более 1 часа',
          'Более 30 минут',
          'Менее 30 минут',
        ],
        correctAnswers: ['Менее 30 минут'],
      },
      {
        code: '63679176',
        text:
          'С какой периодичностью ИТР участка аэрологической безопасности должен осуществлять контроль концентрации метана и режимов работы рабочего и резервного агрегатов?',
        answers: [
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в месяц',
          'Не реже 2 раз в месяц',
          'Не реже 3 раз в месяц',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 3 раз в месяц'],
      },
      {
        code: '63679177',
        text:
          'Какой должна быть требуемая минимальная плотность установки анкеров в классе неустойчивой кровли?',
        answers: [
          'Не меньше 1 анк/м2',
          'Не меньше 0,7 анк/м2',
          'Не меньше 0,5 анк/м2',
          'Не меньше 0,3 анк/м2',
        ],
        correctAnswers: ['Не меньше 1 анк/м2'],
      },
      {
        code: '63679178',
        text:
          'Какова величина среднего замедления подъемной установки при спуске расчетного груза при предохранительном торможении и углах наклона выработок до 30°?',
        answers: [
          'Не менее 0,75 м/с2',
          'Не менее 0,6 м/с2',
          'Не менее 0,5 м/с2',
          'Не менее 0,45 м/с2',
        ],
        correctAnswers: ['Не менее 0,75 м/с2'],
      },
      {
        code: '63679179',
        text:
          'На каком расстоянии от лавы в горных выработках с исходящей струей воздуха устанавливаются обеспыливающие завесы?',
        answers: [
          'Не более 50 м',
          'Не более 60 м',
          'Не более 70 м',
          'Не более 80 м',
        ],
        correctAnswers: ['Не более 50 м'],
      },
      {
        code: '63679180',
        text:
          'В каком из перечисленных случаев подготовка пологих и наклонных пластов угля горными выработками по углю должна осуществляться с оставлением целиков не менее 40 м? Укажите все правильные ответы.',
        answers: [
          'Между горными выработками смежных столбов',
          'Между воздухопроводящей выработкой и монтажными и демонтажными камерами',
          'Между горными выработками с различным направлением воздушных струй',
          'Между воздухопроводящими выработками и атмосферой открытых горных работ и земной атмосферой',
          'Между горными выработками с сонаправленным движением струй',
        ],
        correctAnswers: [
          'Между горными выработками с различным направлением воздушных струй',
          'Между воздухопроводящими выработками и атмосферой открытых горных работ и земной атмосферой',
        ],
      },
      {
        code: '63679181',
        text:
          'В каком случае проводится оценка несущей способности анкеров при проведении (восстановлении) выработок?',
        answers: [
          'Только при изменении прочностных свойств и обводненности пород кровли горной выработки',
          'Только при изменениях конструкции анкерной крепи',
          'Только при применении новых видов или составов ампул',
          'Только при применении новой технологии установки анкерной крепи',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63679182',
        text:
          'При каком максимальном содержании метана (% объемной доли) в камере подземной дегазационной станции обеспечивается безопасное аэрогазовое состояние по метану?',
        answers: ['0,5 %', '0,75 %', '1 %', '2 %'],
        correctAnswers: ['1 %'],
      },
      {
        code: '63679183',
        text:
          'Что необходимо сделать, если огневые работы не закончены в установленный в наряде-допуске срок?',
        answers: [
          'Главный инженер шахты (фабрики) продлевает срок проведения огневых работ до полного их выполнения',
          'ИТР структурного подразделения, где проводятся огневые работы, выписывает новый наряд-допуск в любом случае',
          'ИТР структурного подразделения, где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на 2 смены',
          'ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на 1 смену',
        ],
        correctAnswers: [
          'ИТР структурного подразделения шахты (фабрики), где проводятся огневые работы, продлевает срок проведения огневых работ, но не более чем на 1 смену',
        ],
      },
      {
        code: '63679184',
        text:
          'При какой продолжительности остановки вентиляторов главного проветривания (ВГП) или вспомогательной вентиляторной установки (ВВУ) при нарушении проветривания горных выработок люди из них выходят в соответствии с ПЛА в горные выработки со свежей струей воздуха?',
        answers: ['Более 30 минут', '20 минут', '15 минут', '10 минут'],
        correctAnswers: ['Более 30 минут'],
      },
      {
        code: '63679185',
        text:
          'В каких случаях проводится плановая практическая проверка аварийных вентиляционных режимов?',
        answers: [
          'Только при разработке планов ликвидации аварий (ПЛА)',
          'Только при изменениях схем проветривания шахты, крыла, горизонта',
          'Только при замене вентиляторов главного проветривания (ВГП)',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63679186',
        text: 'Что должно быть проверено при проведении учебных тревог?',
        answers: [
          'Только возможность осуществления в организации мероприятий по спасению людей, локализации аварии и ликвидации ее последствий',
          'Только знание работников шахты своих действий при авариях и инцидентах',
          'Только состояние систем связи, оповещения и определения местоположения людей, застигнутых аварией',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63679187',
        text:
          'В каких местах следует организовывать контроль эндогенной пожароопасности выемочных участков?',
        answers: [
          'На исходящей из лавы струе воздуха в 10 - 20 м от очистного забоя',
          'В трубопроводах подземных и поверхностных газоотсасывающих установок',
          'В смесительных камерах',
          'В контрольных скважинах, пробуренных в выработанное пространство',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63679188',
        text:
          'Как часто выполняются замеры концентрации метана в соответствии с перечнем участков выработок, опасных по слоевым скоплениям метана ИТР технологических участков?',
        answers: [
          'Не реже 1 раза в сутки',
          'Не менее 1 раза в смену',
          'Не менее 2 раз в смену',
          'Не менее 3 раз в смену',
        ],
        correctAnswers: ['Не менее 3 раз в смену'],
      },
      {
        code: '63679189',
        text:
          'Каково минимально допустимое расстояние между находящимися на смежных платформах длинномерными материалами или оборудованием при доставке их в составах, предназначенных для этих целей вагонеток или платформ, сцепленных между собой жесткими сцепками?',
        answers: ['200 мм', '250 мм', '300 мм', '500 мм'],
        correctAnswers: ['300 мм'],
      },
      {
        code: '63679190',
        text:
          'Кто организаует выполнение мер по борьбе с пылью и пылевзрывозащите?',
        answers: [
          'Начальник участка аэрологической безопасности',
          'Руководитель угледобывающей организации',
          'Сторонняя организация',
          'Главный инженер шахты',
          'Все ответы неверны',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679191',
        text:
          'Какой из перечисленных способов борьбы с пылью применяется в очистных забоях на пологих угольных пластах?',
        answers: [
          'Только увлажнение угольного пласта',
          'Только взрывозащитное орошение',
          'Только орошение в зоне разрушения и выгрузки угля или подача пены',
          'Только орошение при передвижке секций механизированных крепей',
          'Только установка завес в горных выработках',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679192',
        text:
          'Какова минимально допустимая длина главных заземлителей из стальной полосы в зумпфе, водосборнике или специальном колодце?',
        answers: ['1,5 м', '2 м', '2,5 м', '3 м'],
        correctAnswers: ['2,5 м'],
      },
      {
        code: '63679193',
        text:
          'Какие разделы не включает в себя Положение о системе управления промышленной безопасностью и охраной труда (СУПБиОТ)?',
        answers: [
          'Система управления промышленной безопасностью',
          'Система управления охраной труда',
          'Проведение расследований причин инцидентов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Проведение расследований причин инцидентов'],
      },
      {
        code: '63679194',
        text:
          'На какую максимальную величину выше места ремонта вертикальной горной выработки устраивается перекрытие, обеспечивающее защиту работникам, выполняющим работы по ремонту, от падающих предметов?',
        answers: ['На 2 м', 'На 3 м', 'На 5 м', 'На 10 м'],
        correctAnswers: ['На 5 м'],
      },
      {
        code: '63679195',
        text:
          'В течение какого времени главный инженер шахты организует проверку состава, температуры, влажности рудничной атмосферы и расхода воздуха ИТР шахты и работниками ПАСС(Ф) после разгазирования вскрытых горных выработок?',
        answers: [
          'В течение суток',
          'В течение не менее 2 суток',
          'В течение не менее 3 суток',
          'В течение не менее 5 суток',
          'Все ответы неверны',
        ],
        correctAnswers: ['В течение не менее 3 суток'],
      },
      {
        code: '63679196',
        text:
          'Какое утверждение, относящееся к порядку проведения локального прогноза удароопасности угольных пластов, указано неверно?',
        answers: [
          'На удароопасных угольных пластах локальный прогноз удароопасности проводится до начала ведения горных работ на участке горной выработки, подлежащем перекреплению',
          'На удароопасных угольных пластах локальный прогноз удароопасности проводится на участках горных выработок, подлежащих погашению',
          'Локальный прогноз в поддерживаемых выработках проводится в поддерживаемых горных выработках, пройденных вне зон опорного давления очистных выработок, в каждый борт горной выработки с интервалом не более 100 м, в горных выработках, охраняемых целиками угля, с интервалом не более 25 м',
          'Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя 3 года после окончания их проведения с периодичностью не реже 1 раза в 3 года',
        ],
        correctAnswers: [
          'Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя 3 года после окончания их проведения с периодичностью не реже 1 раза в 3 года',
        ],
      },
      {
        code: '63679197',
        text:
          'С какой периодичностью главный инженер шахты разрабатывает график ремонта изолирующих перемычек (ИП) и улучшения качества изоляции неиспользуемых горных выработок и выработанных пространств?',
        answers: [
          'Только при возведении новой изолирующей перемычки',
          'Только 1 раз в 3 года',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63679198',
        text:
          'После выполнения каких работ разрешается опускать щитовое перекрытие при разработке мощных пластов?',
        answers: [
          'После обрушения потолочины над щитом для создания предохранительной подушки высотой не менее мощности пласта',
          'После оборудования щитового перекрытия предохранительными средствами (канатами, трапами, решетками)',
          'После монтажа не менее одной секции следующего щитового перекрытия (за исключением последнего щитового столба на выемочном участке)',
          'После выполнения всех перечисленных работ',
          'Все ответы неверны',
        ],
        correctAnswers: ['После выполнения всех перечисленных работ'],
      },
      {
        code: '63679199',
        text:
          'Каково максимально допустимое расстояние от гидромонитора до задвижки, вмонтированной в водоподводящий трубопровод?',
        answers: ['30 м', '50 м', '100 м', '150 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63679200',
        text:
          'При каком содержании взрывоопасных газов (метана) в рудничной атмосфере действующих горных выработок шахты не обязательно проведение дегазации при работающей вентиляции?',
        answers: ['До 5 %', 'До 3 %', 'До 2 %', 'До 1 %', 'Все ответы неверны'],
        correctAnswers: ['До 1 %'],
      },
      {
        code: '63679201',
        text:
          'С какой периодичностью главный инженер шахты организует проведение работ по определению герметичности изолирующих перемычек, перепадов давлений на изолирующих перемычках, учетек воздуха через выработанное пространство и распределения воздуха по шахтной сети горных выработок?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в месяц',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в квартал'],
      },
      {
        code: '63679202',
        text:
          'В каком случае допускается нахождение людей, занятых тушением подземного пожара активным способом, в горных выработках с исходящей от пожара струей воздуха?',
        answers: [
          'Только в случае тушения пожара водой',
          'Только в случае выполнения мер, предотвращающих обрушение (высыпание) горных пород',
          'Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Только в случае, когда пожар находится вблизи выработок со свежей струей воздуха',
        ],
      },
      {
        code: '63679203',
        text:
          'На каком расстоянии от вентиляционного става должны устанавливаться полки сланцевого заслона?',
        answers: [
          'Не более чем на 100 мм',
          'Не менее чем на 150 мм',
          'Не более чем на 200 мм',
          'Не менее чем на 230 мм',
        ],
        correctAnswers: ['Не более чем на 100 мм'],
      },
      {
        code: '63679204',
        text:
          'В каком из перечисленных мест негазовых шахт не осуществляется контроль концентрации метана, оксида углерода, диоксида углерода и кислорода групповыми переносными или индивидуальными приборами контроля?',
        answers: [
          'В призабойных пространствах подготовительных выработок',
          'У выемочных машин в случаях, если выемочные машины не оборудованы встроенными автоматическими приборами контроля концентрации метана',
          'В погашаемых тупиках вентиляционных выработок',
          'У буровых станков при бурении скважин',
        ],
        correctAnswers: ['У буровых станков при бурении скважин'],
      },
      {
        code: '63679205',
        text:
          'Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указаны неверно?',
        answers: [
          'Оперативный план не должен содержать ссылок на пункты ранее разработанных оперативных планов',
          'Последующие оперативные планы (№ 2, 3 и т.д.) разрабатываются в случаях, когда мероприятия предыдущего оперативного плана реализованы или требуется их корректировка',
          'Оперативные планы разрабатываются до окончания ведения горноспасательных работ',
          'Оперативный план утверждает руководитель горноспасательных работ',
        ],
        correctAnswers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
        ],
      },
      {
        code: '63679206',
        text:
          'Каково максимально допустимое расстояние между секционными выключателями контактной сети?',
        answers: ['500 м', '600 м', '700 м', '800 м'],
        correctAnswers: ['500 м'],
      },
      {
        code: '63679207',
        text:
          'Кем осуществляется отбор проб после изоляции эндогенного и (или) экзогенного пожарного участка?',
        answers: [
          'Специалистами участка аэрогазовой безопасности',
          'Специалистами службы прогноза динамических явлений',
          'Главным инженером шахты (разреза)',
          'Профессионально аварийно-спасательной службой (формированием) (ПАСС(Ф))',
        ],
        correctAnswers: [
          'Профессионально аварийно-спасательной службой (формированием) (ПАСС(Ф))',
        ],
      },
      {
        code: '63679208',
        text:
          'Какие действия разрешены Инструкцией по электроснабжению, выбору угольных шахт в участковых сетях шахт напряжением до 1200 В?',
        answers: [
          'Изменять заводскую конструкцию и схему электрооборудования, схемы аппаратуры управления, защиты и контроля, а также градуировку устройств защиты',
          'Включать электрическую сеть при повреждении кабелей и электрооборудования',
          'Проводить оперативное обслуживание и управлять электроустановками напряжением до 42 В, не защищенными аппаратами защиты от утечек тока, без диэлектрических перчаток',
          'Обслуживать и ремонтировать электрооборудование и сети без приборов и инструмента, предназначенных для этих целей',
          'Иметь под напряжением неиспользуемые электрические сети',
        ],
        correctAnswers: [
          'Проводить оперативное обслуживание и управлять электроустановками напряжением до 42 В, не защищенными аппаратами защиты от утечек тока, без диэлектрических перчаток',
        ],
      },
      {
        code: '63679209',
        text:
          'Куда направляют перечень пластов, склонных к самовозгоранию, после его утверждения? Укажите все правильные ответы.',
        answers: [
          'В орган местного самоуправления',
          'В государственный пожарный надзор',
          'В ПАСС(Ф), обслуживающее шахту',
          'В территориальный орган Ростехнадзора',
          'В Росгидромет',
        ],
        correctAnswers: [
          'В ПАСС(Ф), обслуживающее шахту',
          'В территориальный орган Ростехнадзора',
        ],
      },
      {
        code: '63679210',
        text:
          'С кем согласуется наряд-допуск на производство работ в электроустановках напряжением до 1200 В, когда исключена возможность их выполнения со снятым напряжением, в шахтах, опасных по газу?',
        answers: [
          'С техническим руководителем шахты',
          'С начальником шахты',
          'С главным механиком шахты',
          'С начальником (заместителем) участка аэрологической безопасности (АБ)',
        ],
        correctAnswers: [
          'С начальником (заместителем) участка аэрологической безопасности (АБ)',
        ],
      },
      {
        code: '63679211',
        text:
          'Кем утверждается схема размещения передвижных трансформаторных подстанций, устанавливаемых в выработках с исходящей струей воздуха, непосредственно примыкающих к очистным забоям пологих и наклонных пластов, опасных по внезапным выбросам, в участковых сетях угольных шахт напряжением до 1200 В?',
        answers: [
          'Инспектором Ростехнадзора',
          'Главным механиком шахты',
          'Начальником шахты',
          'Техническим руководителем шахты',
          'Специализированной бригадой',
        ],
        correctAnswers: ['Техническим руководителем шахты'],
      },
      {
        code: '63679212',
        text:
          'Кто утверждает документацию на выполнение горных работ, связанных с проведением, креплением, поддержанием, ликвидацией горных выработок и ведением очистных работ?',
        answers: [
          'Руководитель шахты',
          'Главный механик шахты',
          'Начальник участка аэрологической безопасности',
          'Главный инженер шахты',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679213',
        text:
          'Кто проверяет конструктивные решения и параметры изолирующих перемычек, которые невозможно будет проверить после возведения изолирующих перемычек, при приемке скрытых работ?',
        answers: [
          'Технический руководитель (главный инженер) угледобывающей организации',
          'Инспектор Ростехнадзора',
          'Специализированная организация',
          'Комиссия угледобывающей организации',
        ],
        correctAnswers: ['Комиссия угледобывающей организации'],
      },
      {
        code: '63679214',
        text:
          'Что из перечисленного допускается при перевозке людей по горизонтальным и наклонным горным выработкам угольных шахт?',
        answers: [
          'Перевозка людей в поездах со взрывчатыми, легковоспламеняющимися и едкими материалами',
          'Нахождение людей между вагонетками во время движения состава',
          'Перевозка людей в поездах с инструментами и запасными частями, выступающими за борт вагонеток',
          'Прицепка грузовых вагонеток к составам поездов, перевозящих людей',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63679215',
        text:
          'При каком содержании метана в процессе монтажа и ремонта электрооборудования в шахтах, опасных по газу, работы прекращают, а напряжение снимают?',
        answers: [
          'Более 0,2 %',
          'Более 0,5 %',
          'Более 0,8 %',
          'Более 1 %',
          'Все ответы неверны',
        ],
        correctAnswers: ['Более 1 %'],
      },
      {
        code: '63679216',
        text:
          'В соответствии с какими документами производится сращивание отдельных длин кабеля на напряжение 3300 В?',
        answers: [
          'В соответствии с техническими регламентами',
          'В соответствии с внутренними инструкциями эксплуатирующей организации',
          'В соответствии с Инструкцией по электроснабжению угольных шахт',
          'В соответствии с инструкциями заводов-изготовителей',
          'Все ответы неверны',
        ],
        correctAnswers: ['В соответствии с инструкциями заводов-изготовителей'],
      },
      {
        code: '63679217',
        text:
          'Каково максимально допустимое напряжение для цепей дистанционного управления стационарными и передвижными машинами и механизмами?',
        answers: ['42 В', '60 В', '110 В', '220 В'],
        correctAnswers: ['42 В'],
      },
      {
        code: '63679218',
        text:
          'Какие тупиковые выработки в газовых шахтах оборудуют резервными вентиляторами местного проветривания?',
        answers: [
          'Все тупиковые выработки независимо от их длины',
          'Длиной более 100 м',
          'Длиной менее 100 м',
          'Тупиковые выработки в газовых шахтах не оборудуются резервными вентиляторами местного проветривания',
          'Все ответы неверны',
        ],
        correctAnswers: ['Длиной более 100 м'],
      },
      {
        code: '63679219',
        text:
          'Какие мероприятия должны проводиться для оценки состояния эндогенного и (или) экзогенного пожара?',
        answers: [
          'Только осмотр изолирующих перемычек (ИП)',
          'Только отбор проб и измерение температуры воздуха и воды',
          'Только измерение влагосодержания, давления и его перепада через контрольные скважины и изолирующие перемычки (ИП)',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63679220',
        text:
          'По какой категории электроснабжения должно осуществляться электроснабжение рабочего и резервного поверхностных газоотсасывающих агрегатов угольных шахт?',
        answers: [
          'По третьей категории электроснабжения',
          'По второй категории электроснабжения',
          'По первой категории электроснабжения',
        ],
        correctAnswers: ['По первой категории электроснабжения'],
      },
      {
        code: '63679221',
        text:
          'Каким образом определяется порядок контроля концентрации газов рудничной атмосферы индивидуальными приборами в горных выработках шахт?',
        answers: [
          'На основании распоряжения лабораторий, выполняющих отбор проб и проверку состава рудничной атмосферы',
          'На основании письменного распоряжения начальника аэрологической безопасности',
          'На основании проекта дегазации шахты',
          'Главным инженером шахты и Инструкцией по аэрологической безопасности угольных шахт',
        ],
        correctAnswers: [
          'Главным инженером шахты и Инструкцией по аэрологической безопасности угольных шахт',
        ],
      },
      {
        code: '63679222',
        text:
          'При каком условии разрешается возобновление электроснабжения шахты после остановки вентиляторов главного проветривания (ВГП) или вспомогательной вентиляторной установки (ВВУ)?',
        answers: [
          'Только после восстановления проветривания горных выработок',
          'Только после замеров содержания метана ИТР структурных подразделений в местах производства работ, у электрических машин, аппаратов и на расстоянии не менее 20 м от мест их установки во всех прилегающих горных выработках',
          'При выполнении всех перечисленных условий',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63679223',
        text: 'Что относится к признакам внезапного выброса угля и газа?',
        answers: [
          'Только быстропротекающее разрушение призабойной части угольного пласта, образование в угольном пласте полости, ширина которой меньше ее глубины',
          'Только отброс угля от забоя на расстояние, превышающее протяженность его размещения под углом естественного откоса',
          'Только относительное газовыделение из разрушенного угля, которое превысило его природную газоносность',
          'Только воздушный толчок и звуковые эффекты, повреждение и (или) перемещение оборудования, наличие угольной пыли на откосе выброшенного угля и крепи горной выработки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63679224',
        text:
          'На какой максимальный срок составляется схема развития горных работ?',
        answers: ['На 1 год', 'На 3 года', 'На 5 лет', 'На 7 лет'],
        correctAnswers: ['На 5 лет'],
      },
      {
        code: '63679225',
        text:
          'При каком содержании пыли в рудничном воздухе в подготовительной горной выработке после применения обеспылевающей завесы запрещается ведение горных работ по проведению выработки?',
        answers: [
          'Не регламентируется',
          'Более 50 мг/м³',
          'Более 100 мг/м³',
          'Более 150 мг/м³',
          'Все ответы неверны',
        ],
        correctAnswers: ['Более 150 мг/м³'],
      },
      {
        code: '63679226',
        text:
          'В каком случае газовые шахты, разрабатывающие антрациты с объемным выходом летучих веществ менее 110 мл/т сухой беззольной массы и отнесенные к опасным по газу, по решению технического руководителя угледобывающей организации переводятся в категорию негазовых?',
        answers: [
          'Газовые шахты не переводятся в категорию негазовых техническим руководителем угледобывающей организации',
          'При условии письменного разрешения Ростехнадзора',
          'При условии проведения контроля состояния рудничной атмосферы и отсутствия выделения метана',
          'При условии, если в течение 3 лет применяемыми средствами контроля в них не обнаруживалось выделения метана',
        ],
        correctAnswers: [
          'При условии, если в течение 3 лет применяемыми средствами контроля в них не обнаруживалось выделения метана',
        ],
      },
      {
        code: '63679227',
        text:
          'В каком случае участковые станции напорного гидротранспорта располагают в нишах при гидравлическом способе добычи угля?',
        answers: [
          'Участковые станции напорного гидротранспорта располагают только в камерах',
          'При сроках службы до 1 года',
          'При сроках службы до 3 лет',
          'В случае обоснования в проектной документации',
          'Все ответы неверны',
        ],
        correctAnswers: ['При сроках службы до 1 года'],
      },
      {
        code: '63679228',
        text:
          'В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?',
        answers: [
          'Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков',
          'Только в виде схем эксплуатации объектов первичной переработки (подготовки) минерального сырья (для месторождений твердых полезных ископаемых, включая общераспространенные)',
          'Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63679229',
        text:
          'В каком месте горной выработки располагаются групповые приборы для контроля диоксида углерода при проверке состава рудничной атмосферы?',
        answers: [
          'Место расположения определяет главный инженер шахты',
          'В середине выработки',
          'У кровли выработок',
          'В нижней части выработки',
          'Все ответы неверны',
        ],
        correctAnswers: ['В нижней части выработки'],
      },
      {
        code: '63679230',
        text:
          'С какой периодичностью проводятся проверка состава рудничной атмосферы и замер ее расхода на газовых шахтах?',
        answers: [
          '1 раз в месяц',
          '2 раза в месяц',
          '3 раза в месяц',
          '1 раз в 3 месяца',
        ],
        correctAnswers: ['3 раза в месяц'],
      },
      {
        code: '63679231',
        text:
          'Какое условие при проведении контроля ширины щели (зазора) в плоских взрывонепроницаемых соединениях рудничного взрывобезопасного электрооборудования указано верно?',
        answers: [
          'Щуп должен входить в проверяемую фланцевую щель',
          'Проверка производится не менее чем в пяти точках, расположенных равномерно по периметру соединения',
          'Для контроля щели (зазора) выбирается щуп толщиной, равной величине ширины щели (зазора), указанной в инструкции по эксплуатации электрооборудования (на чертеже средств взрывозащиты)',
          'Все перечисленное указано верно',
        ],
        correctAnswers: [
          'Для контроля щели (зазора) выбирается щуп толщиной, равной величине ширины щели (зазора), указанной в инструкции по эксплуатации электрооборудования (на чертеже средств взрывозащиты)',
        ],
      },
      {
        code: '63679232',
        text:
          'Какой из перечисленных способов борьбы с пылью не применяется для предотвращения распространения пыли у опрокидывателей и стационарных погрузочных пунктов?',
        answers: [
          'Аспирационные укрытия технологического оборудования',
          'Орошение мест перегрузки горной массы',
          'Исключение свободного падения горной массы с большой высоты',
          'Системы очистки запыленной рудничной атмосферы',
        ],
        correctAnswers: [
          'Исключение свободного падения горной массы с большой высоты',
        ],
      },
      {
        code: '63679233',
        text:
          'К какому типу кровли (по обрушаемости) будет относиться однородная кровля из слоистых преимущественно глинистых, песчано-глинистых и песчаных сланцев?',
        answers: [
          'К I типу кровли',
          'К II типу кровли',
          'К III типу кровли',
          'К IV типу кровли',
        ],
        correctAnswers: ['К I типу кровли'],
      },
      {
        code: '63679234',
        text:
          'Как регламентируется Инструкцией по электроснабжению угольных шахт применение схем, допускающих пуск машин или подачу напряжения на них одновременно с двух и более пультов управления, в участковых сетях угольных шахт напряжением до 1200 В?',
        answers: [
          'Применение данных схем запрещается в любых случаях',
          'Применение данных схем разрешается без ограничений',
          'Применение данных схем возможно только по согласованию с разработчиком проекта',
          'Применение данных схем запрещается, кроме схем управления вентиляторами местного проветривания',
        ],
        correctAnswers: [
          'Применение данных схем запрещается, кроме схем управления вентиляторами местного проветривания',
        ],
      },
      {
        code: '63679235',
        text:
          'Каково минимально допустимое давление жидкости в трубопроводе у водяной обеспыливающей завесы?',
        answers: ['0,3 МПа', '0,5 МПа', '1 МПа', 'Не нормируется'],
        correctAnswers: ['0,5 МПа'],
      },
      {
        code: '63679236',
        text: 'Какие шахты относятся к опасным по нефтегазопроявлениям?',
        answers: [
          'Только шахты, в которых выделяются жидкие углеводороды',
          'Только шахты, в которых выделяются парообразные углеводороды',
          'Только шахты, в которых выделяются газообразные углеводороды (кроме метана), если их содержание превышает 10 % общего объема горючих газов',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679237',
        text:
          'На какое расстояние поверхностные газоотсасывающие установки удаляются от негорящих отвалов?',
        answers: [
          'За пределы механической защитной зоны',
          'На 300 м',
          'На 100 м',
          'На 30 м',
          'Все ответы неверны',
        ],
        correctAnswers: ['За пределы механической защитной зоны'],
      },
      {
        code: '63679238',
        text:
          'Кто из перечисленных лиц не входит в состав комиссии по приему системы аэрогазового контроля в эксплуатацию?',
        answers: [
          'Работник территориального органа Ростехнадзора',
          'Главный механик',
          'Администратор системы аэрогазового комплекса',
          'Главный инженер',
          'Все ответы неверны',
        ],
        correctAnswers: ['Администратор системы аэрогазового комплекса'],
      },
      {
        code: '63679239',
        text: 'Кто определяет порядок проверки состава рудничной атмосферы?',
        answers: [
          'Специально сформированная комиссия шахты',
          'Руководитель военизированной горноспасательной части, обслуживающей шахту',
          'Начальник участка аэрологической безопасности',
          'Главный инженер шахты',
          'Начальник газоаналитической лаборатории',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679240',
        text:
          'Кем составляется перечень участковых выработок, опасных по слоевым скоплениям метана на газовых шахтах? Укажите все правильные ответы.',
        answers: [
          'Начальником газоаналитической лаборатории',
          'Руководителем военизированных горноспасательных частей, обслуживающих шахту',
          'Начальником участка аэрологической безопасности',
          'Геологом шахты',
          'Главным инженером шахты',
        ],
        correctAnswers: [
          'Начальником участка аэрологической безопасности',
          'Геологом шахты',
        ],
      },
      {
        code: '63679241',
        text:
          'Кем утверждается схема установки в горных выработках средств локализации и предупреждения взрывов пылегазовоздушных смесей?',
        answers: [
          'С руководителем аварийно-спасательной службы (формирования)',
          'Руководителем (директором) шахты',
          'Начальником участка аэрологической безопасности',
          'Главным инженером шахты',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679242',
        text:
          'Кто принимает решение по изменению направления движения и расхода воздуха в горных выработках угольных шахт?',
        answers: [
          'Руководитель шахты',
          'Главный инженер шахты',
          'Специалисты структурного подразделения, в ведении которых находится горная выработка',
          'Начальник участка аэрологической безопасности (АБ)',
          'Все ответы неверны',
        ],
        correctAnswers: ['Начальник участка аэрологической безопасности (АБ)'],
      },
      {
        code: '63679243',
        text:
          'С какой периодичностью должна производиться ИТР (механиком) структурного подразделения проверка парашютных устройств включением ручного привода?',
        answers: [
          'Ежесуточно',
          '1 раз в неделю',
          '1 раз в 2 недели',
          '1 раз в месяц',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63679244',
        text:
          'До какого максимального давления воды разрешается ручное управление гидромониторами при гидравлическом способе добычи угля?',
        answers: ['До 3 МПа', 'До2,5 МПа', 'До 2 МПа', 'До1,5 МПа'],
        correctAnswers: ['До 3 МПа'],
      },
      {
        code: '63679245',
        text:
          'Куда делается запись результатов проверки аппарата защиты от утечек тока на срабатывание перед началом каждой смены?',
        answers: [
          'В книгу регистрации состояния машин и оборудования',
          'В личный график осмотра аппарата защиты от утечек тока',
          'В акт осмотра электроустановок',
          'На доски контроля исправности аппарата защиты от утечек тока',
        ],
        correctAnswers: [
          'На доски контроля исправности аппарата защиты от утечек тока',
        ],
      },
      {
        code: '63679246',
        text: 'Какой дегазационный газопровод считается выдержавшим испытание?',
        answers: [
          'Если снижение разрежения в нем за первые 120 минут после его перекрытия не превышает 15 мм рт. ст.',
          'Если снижение разрежения в нем за первые 90 минут после его перекрытия не превышает 20 мм рт. ст.',
          'Если снижение разрежения в нем за первые 60 минут после его перекрытия не превышает 15 мм рт. ст.',
          'Если снижение разрежения в нем за первые 30 минут после его перекрытия не превышает 10 мм рт. ст.',
        ],
        correctAnswers: [
          'Если снижение разрежения в нем за первые 30 минут после его перекрытия не превышает 10 мм рт. ст.',
        ],
      },
      {
        code: '63679247',
        text:
          'При каких условиях обеспечивается надежность болтового соединения заземляющих проводников?',
        answers: [
          'Только при диаметре зажима не менее 8 мм',
          'Только при зачищенной контактной поверхности и не менее площади шайбы для принятого болта',
          'Только при наличии в болтовом соединении пружинных шайб или контргаек',
          'При всех перечисленных',
        ],
        correctAnswers: ['При всех перечисленных'],
      },
      {
        code: '63679248',
        text:
          'Кто определяет порядок измерения расхода воздуха в горных выработках шахты с указанием мест замеров и учет результатов этих замеров?',
        answers: [
          'Начальник проходческого (добычного) участка',
          'Руководитель горнодобывающей организации',
          'Главный механик шахты',
          'Главный инженер шахты',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679249',
        text:
          'Какое из перечисленных требований к электроснабжению электрических аппаратов, кабелей и устройств релейной защиты в участковых сетях шахт напряжением до 1200 В указано неверно?',
        answers: [
          'Схема управления забойными машинами и механизмами обеспечивает нулевую защиту',
          'В очистных забоях предусматривается возможность остановки конвейера с пульта управления комбайном и со специальных пультов',
          'Не применяются схемы управления вентиляторами местного проветривания, допускающие подачу напряжения на них одновременно с двух и более пультов управления',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Не применяются схемы управления вентиляторами местного проветривания, допускающие подачу напряжения на них одновременно с двух и более пультов управления',
        ],
      },
      {
        code: '63679250',
        text:
          'В какой срок после начала проведения горных выработок, оконтуривающих выемочный участок, определяется фрикционная опасность горных пород для каждого выемочного участка?',
        answers: [
          'Не более 1 недели',
          'Не более 2 недель',
          'Не более 1 месяца',
          'Не более 2 месяцев',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не более 1 месяца'],
      },
      {
        code: '63679251',
        text:
          'Какое условие при нанесении противокоррозийной смазки на взрывозащитные поверхности рудничного взрывобезопасного электрооборудования во время проведения ежеквартальной ревизии указано неверно?',
        answers: [
          'Для удаления следов коррозии зачищать взрывозащитные поверхности необходимо неметаллическим скребком или очищающей жидкостью, не вызывающей коррозию',
          'Удалять излишки смазки с внутренней и внешней поверхностей электрооборудования требуется с помощью ветоши',
          'Перед нанесением смазки необходимо произвести визуальный осмотр подготовленной поверхности',
          'На глухие резьбовые отверстия смазка наносится равномерным слоем кистью',
        ],
        correctAnswers: [
          'На глухие резьбовые отверстия смазка наносится равномерным слоем кистью',
        ],
      },
      {
        code: '63679252',
        text:
          'Каким должно быть отставание лавы каждого нижележащего слоя от границы обрушенного или заложенного пространства лавы вышележащего слоя на пластах с углом падения более 30°?',
        answers: [
          'Не менее 20 м',
          'Не менее 15 м',
          'От 10 до 15 м',
          'Не нормируется',
        ],
        correctAnswers: ['Не менее 20 м'],
      },
      {
        code: '63679253',
        text: 'Что проверяется при проведении учебной тревоги?',
        answers: [
          'Последовательность выполнения мероприятий по ликвидации аварии, предусмотренных ПЛА;',
          'Последовательность действий при оповещении руководителей и работников шахты в соответствии с ПЛА',
          'Возможность организовать подачу воды к месту тушения пожара с расчетными параметрами',
          'Последовательность и время оповещения людей, застигнутых аварией',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63679254',
        text:
          'С какой периодичностью осуществляют профильную съемку армировки и замер зазоров безопасности на угольных шахтах?',
        answers: [
          'Определяется главным инженером шахты',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в 2 года'],
      },
      {
        code: '63679255',
        text:
          'В течение какого времени должны заполняться водосборники участковых водоотливов при максимальном притоке воды?',
        answers: [
          'Не менее чем за 4 часа',
          'Не менее чем за 3 часа',
          'Не менее чем за 2 часа',
          'Не менее чем за 1 час',
        ],
        correctAnswers: ['Не менее чем за 2 часа'],
      },
      {
        code: '63679256',
        text:
          'В соответствии с чем осуществляются монтаж и эксплуатация электрооборудования в рудничном нормальном исполнении и общего назначения в шахтах, опасных по газу и пыли?',
        answers: [
          'В соответствии с проектной документацией',
          'В соответствии с проектом, утвержденным руководителем организации',
          'В соответствии с проектом, утвержденным территориальным органом Ростехнадзора',
          'В соответствии с проектом, утвержденным техническим руководителем шахты',
        ],
        correctAnswers: [
          'В соответствии с проектом, утвержденным техническим руководителем шахты',
        ],
      },
      {
        code: '63679257',
        text:
          'С какой периодичностью должны проводиться учебные тревоги по плану ликвидации аварий на шахте?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63679258',
        text:
          'Чем обеспечивают надежность болтового соединения заземляющих проводников в шахтах?',
        answers: [
          'Диаметр зажима должен быть не менее 8 мм',
          'Контактные поверхности - не менее площади шайбы для принятого болта и должны быть зачищены',
          'Болты и гайки снабжаются пружинными шайбами или контргайками',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63679259',
        text:
          'Какое из перечисленных действий не относится к работам по изоляции вертикальных выработок, имеющих выход на земную поверхность?',
        answers: [
          'Возведение изолирующей перемычки на балках или рельсах над засыпанной частью изолируемой выработки',
          'До возведения изолирующей перемычки следует выполнить работы по усилению крепи горной выработки ниже места возведения изолирующей перемычки',
          'Засыпка оставшейся части изолируемой выработки негорючими материалами до уровня земной поверхности',
          'Возведение изолирующей перемычки в горных выработках на расстоянии не менее 5 м от сопряжения с вертикальной горной выработкой',
        ],
        correctAnswers: [
          'До возведения изолирующей перемычки следует выполнить работы по усилению крепи горной выработки ниже места возведения изолирующей перемычки',
        ],
      },
      {
        code: '63679260',
        text:
          'Что запрещается применять при проведении огневых работ на временных местах в любых случаях?',
        answers: [
          'Электросварочные установки',
          'Ацетиленовые генераторы',
          'Керосинорезы',
          'Сжиженные и сжатые газы',
          'Ацетилен в баллонах',
        ],
        correctAnswers: ['Ацетиленовые генераторы'],
      },
      {
        code: '63679261',
        text:
          'С какой периодичностью следует определять склонность пластов угля к самовозгоранию для всех разрабатываемых подземным (открытым) способом пластов угля?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в 3 года'],
      },
      {
        code: '63679262',
        text:
          'На каком расстоянии от конца вентиляционного трубопровода до забоя в газовых и негазовых шахтах организуется проветривание тупиковых горных выработок?',
        answers: [
          'В газовых шахтах не более 3 м, а в негазовых - 10 м',
          'В газовых шахтах не более 7 м, а в негазовых - 15 м',
          'В газовых шахтах не более 10 м, а в негазовых - 15 м',
          'В газовых шахтах не более 5 м, а в негазовых - 12 м',
          'Все ответы неверны',
        ],
        correctAnswers: ['В газовых шахтах не более 5 м, а в негазовых - 12 м'],
      },
      {
        code: '63679263',
        text:
          'На какие случаи не распространяется запрет передавать сигнал из околоствольного двора непосредственно машинисту, минуя рукоятчика?',
        answers: [
          'На одноклетьевые подъемные установки с подачей сигнала из клети',
          'На установки с опрокидными клетями при подъеме только груза',
          'На скиповые подъемные установки',
          'На все перечисленные случаи',
          'Все ответы неверны',
        ],
        correctAnswers: ['На все перечисленные случаи'],
      },
      {
        code: '63679264',
        text:
          'Каково максимально допустимое число людей, находящихся одновременно на каждом этаже клети из расчета на 1 м2 пола?',
        answers: [
          '3 работника',
          '4 работника',
          '5 работников',
          'Не нормируется',
        ],
        correctAnswers: ['5 работников'],
      },
      {
        code: '63679265',
        text:
          'При использовании какого привода продолжительность холостого хода предохранительного тормоза действующих подъемных машин не должна превышать 0,6 с?',
        answers: [
          'Пневмопружинного привода',
          'Гидропружинного привода',
          'Пневмогрузового привода',
          'Гидрогрузового привода',
          'Все ответы неверны',
        ],
        correctAnswers: ['Гидрогрузового привода'],
      },
      {
        code: '63679266',
        text:
          'В каком случае допускается возводить изолирующие перемычки в границах зон повышенного горного давления и зон влияния геологических нарушений в выработках, пройденных по угольным пластам?',
        answers: [
          'Не допускается ни в каком случае',
          'Если есть письменное распоряжение, утвержденное на основании проведенной приемке скрытых работ',
          'Если отсутствует гальваническая связь между изолированной частью выработки и действующими выработками',
          'Если выполнены меры, обеспечивающие сохранность изолирующих перемычек (ИП) в условиях повышенного горного давления (ПГД) или в условиях влияния геологических нарушений',
        ],
        correctAnswers: [
          'Если выполнены меры, обеспечивающие сохранность изолирующих перемычек (ИП) в условиях повышенного горного давления (ПГД) или в условиях влияния геологических нарушений',
        ],
      },
      {
        code: '63679267',
        text:
          'Какая из перечисленных обязанностей дежурного машиниста поверхностной газоотсасывающей установки указана верно?',
        answers: [
          'Осуществлять осмотр вентиляторов (без его остановки) и трубопроводов в случае письменного распоряжения горного диспетчера',
          'Проводить не реже 4 раз в сутки замеры содержания окиси углерода в отсасываемой метановоздушной смеси',
          'Сообщать о возникновении аварийной ситуации и обо всех замеченных недостатках в работе газоотсасывающей установки специалисту аэрологической безопасности',
          'Проводить 1 раз в смену замеры концентрации метана и депрессии рабочего агрегата',
        ],
        correctAnswers: [
          'Проводить не реже 4 раз в сутки замеры содержания окиси углерода в отсасываемой метановоздушной смеси',
        ],
      },
      {
        code: '63679268',
        text:
          'В течение какого времени должны заполняться водосборники главного водоотлива при максимальном притоке воды?',
        answers: [
          'Не менее чем за 4 часа',
          'Не менее чем за 3 часа',
          'Не менее чем за 2 часа',
          'Не менее чем за 1 час',
        ],
        correctAnswers: ['Не менее чем за 4 часа'],
      },
      {
        code: '63679269',
        text:
          'В соответствии с какой документацией проводится оценка геодинамического состояния массива горных пород?',
        answers: [
          'В соответствии с документацией по проведению разведочных и (или) контрольных наблюдений',
          'В соответствии с документацией по определению выбросоопасности по локальному прогнозу',
          'В соответствии с документацией по прогнозу по параметрам аэрогазового контроля',
          'В соответствии с документацией по проведению геофизического мониторинга',
          'В соответствии с документацией по расчету пороговых значений прогностических параметров',
        ],
        correctAnswers: [
          'В соответствии с документацией по проведению геофизического мониторинга',
        ],
      },
      {
        code: '63679270',
        text:
          'На протяжении какого периода времени собирается информация, на основании которой определяются пороговые значения параметров сейсмических событий применяемого метода регионального прогноза для каждого пласта?',
        answers: [
          'Не менее года',
          'Не менее 3 месяцев',
          'Не менее 6 месяцев',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее 6 месяцев'],
      },
      {
        code: '63679271',
        text:
          'Какова минимально допустимая глубина колодцев для размещения главных заземлителей?',
        answers: ['1,5 м', '2,5 м', '3,5 м', '4,5 м'],
        correctAnswers: ['3,5 м'],
      },
      {
        code: '63679272',
        text:
          'Какое напряжение допускается для питания подземных осветительных установок и для ручных переносных светильников, питаемых от искробезопасных источников, на угольных шахтах?',
        answers: [
          'Не выше 180 В и не выше 12 В соответственно',
          'Не выше 127 В и не выше 50 В соответственно',
          'Не выше 380 В и не выше 36 В соответственно',
          'Не выше 220 В и не выше 42 В соответственно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не выше 220 В и не выше 42 В соответственно'],
      },
      {
        code: '63679273',
        text:
          'Кто принимает решение о возобновлении горных работ после приведения горного массива в неопасное состояние?',
        answers: [
          'Руководитель шахты',
          'Главный инженер шахты',
          'Представитель Ростехнадзора',
          'Командир профессиональных аварийно-спасательных служб (формирований) (ПАСС (Ф), обслуживающих шахту',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679274',
        text: 'В каком случае породный отвал относится к категории горящих?',
        answers: [
          'Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 3,0 м более 80 °С',
          'Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 2,0 м более 70 °С',
          'Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 2,5 м более 80 °С',
          'Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 3,5 м более 70 °С',
        ],
        correctAnswers: [
          'Если на нем имеется хотя бы один очаг горения с температурой пород на глубине до 2,5 м более 80 °С',
        ],
      },
      {
        code: '63679275',
        text:
          'Какой должна быть температура воздуха, поступающего в горные выработки шахты?',
        answers: [
          'Не ниже 2 оС',
          'Не ниже 1 оС',
          'Не ниже 0 оС',
          'Не ниже -1 оС',
        ],
        correctAnswers: ['Не ниже 2 оС'],
      },
      {
        code: '63679276',
        text:
          'В каких наклонных подземных горных выработках устанавливаются сланцевые заслоны?',
        answers: [
          'Не регламентируется',
          'С углом наклона до 25°',
          'С углом наклона до 20°',
          'С углом наклона до 18°',
        ],
        correctAnswers: ['С углом наклона до 18°'],
      },
      {
        code: '63679277',
        text:
          'Кто утверждает перечень ИТР, имеющих право оформления нарядов-допусков?',
        answers: [
          'Командир пожарной части, обслуживающей организацию',
          'Лица, ответственные за безопасное выполнение работ на шахте (фабрике)',
          'Главный инженер шахты (фабрики)',
          'Руководитель шахты (фабрики)',
        ],
        correctAnswers: ['Руководитель шахты (фабрики)'],
      },
      {
        code: '63679278',
        text:
          'Какой цвет стрелки принят для обозначения направления движения свежей вентиляционной струи по горным выработкам на схеме вентиляции шахты?',
        answers: ['Красный', 'Синий', 'Зеленый', 'Черный'],
        correctAnswers: ['Красный'],
      },
      {
        code: '63679279',
        text:
          'В каком случае вносятся исправления в документах об аэрогазовом режиме и работе системы аэрогазового контроля?',
        answers: [
          'В случае, если внесение исправлений обозначено в проекте аэрогазового контроля',
          'Внесение исправлений не допускается ни в каком случае',
          'Исправления вносятся только в документы, формируемые вручную',
          'Только по письменному указанию начальника (заместителя начальника) участка аэрологической безопасности',
        ],
        correctAnswers: [
          'Только по письменному указанию начальника (заместителя начальника) участка аэрологической безопасности',
        ],
      },
      {
        code: '63679280',
        text:
          'Каково максимально допустимое напряжение для ручных машин и инструментов?',
        answers: ['42 В', '60 В', '110 В', '220 В'],
        correctAnswers: ['220 В'],
      },
      {
        code: '63679281',
        text:
          'Какая из перечисленных мер не проводится при вскрытии угольных пластов вертикальными стволами для предотвращения внезапных выбросов согласно Рекомендациям по безопасному ведению горных работ на склонных к динамическим явлениям пластах?',
        answers: [
          'Гидрорыхление угольного пласта',
          'Возведение каркасной крепи',
          'Бурение дренажных скважин',
          'Увлажнение угольного массива',
          'Все ответы неверны',
        ],
        correctAnswers: ['Увлажнение угольного массива'],
      },
      {
        code: '63679282',
        text:
          'С какой периодичностью производится наружный осмотр общей заземляющей сети шахты с измерением общего сопротивления заземляющей сети у каждого заземлителя?',
        answers: [
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 10 месяцев',
          'Не реже 1 раза в 12 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '63679283',
        text:
          'Кто на шахте утверждает периодичность проведения контроля содержания метана, оксида углерода, диоксида углерода и кислорода в рудничной атмосфере индивидуальными и групповыми переносными приборами?',
        answers: [
          'Главный технолог шахты',
          'Представитель Ростехнадзора',
          'Руководитель шахты',
          'Главный инженер шахты',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679284',
        text:
          'Какая устанавливается максимальная продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды в непригодной для дыхания рудничной атмосфере с применением дыхательных аппаратов?',
        answers: [
          '2 часа',
          '3 часа',
          '4 часа',
          '5 часов',
          'Все ответы неверны',
        ],
        correctAnswers: ['4 часа'],
      },
      {
        code: '63679285',
        text:
          'С какой периодичностью устанавливается контрольное время загазирования подготовительной выработки, проветриваемой вентиляторами местного проветривания?',
        answers: [
          'Еженедельно',
          'Ежеквартально',
          'Ежемесячно',
          'Ежегодно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63679286',
        text:
          'При соблюдении каких условий по обеспечению промышленной безопасности допускается транспортирование метановоздушной смеси в дегазационных трубопроводах с содержанием метана менее 25 %?',
        answers: [
          'При установке электрооборудования на одной стороне с местом прокладки трубопровода',
          'При использовании открытого огня для размораживания газопровода в зимний период времени',
          'При назначении лиц, персонально ответственных за состояние выработок, в которых проложен дегазационный трубопровод',
          'При соблюдении всех перечисленных условий',
        ],
        correctAnswers: [
          'При назначении лиц, персонально ответственных за состояние выработок, в которых проложен дегазационный трубопровод',
        ],
      },
      {
        code: '63679287',
        text:
          'Какова максимально допустимая периодичность визуального контроля изолирующих перемычек (ИП)?',
        answers: [
          'Ежесуточно',
          '1 раз в смену',
          '1 раз в 10 дней',
          'Ежемесячно',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63679288',
        text:
          'Кто при выявлении фактов нахождения горных выработок шахты в пылевзрывоопасном состоянии до возобновления горных работ в этих горных выработках принимает меры, обеспечивающие приведение их в пылевзрывобезопасное состояние?',
        answers: [
          'Начальник проходческого (добычного) участка',
          'Главный инженер шахты',
          'Главный механик шахты',
          'Руководитель горнодобывающей организации',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679289',
        text:
          'В каком из перечисленных случаев допускается посадка с нависающей над ленточным полотном боковой площадки в положении лежа ногами по направлению движения ленточного полотна путем перекатывания на ленту?',
        answers: [
          'На ленту, движущуюся горизонтально',
          'На ленту, движущуюся вверх',
          'На ленту, движущуюся вниз',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['На ленту, движущуюся вниз'],
      },
      {
        code: '63679290',
        text:
          'На какой срок разрабатывается план ликвидации аварий (ПЛА) на угольных шахтах?',
        answers: [
          'На 6 месяцев',
          'На 10 месяцев',
          'На 1 год',
          'План составляется без ограничения срока действия, при возникновении изменений в план вносятся соответствующие исправления',
        ],
        correctAnswers: ['На 6 месяцев'],
      },
      {
        code: '63679291',
        text:
          'При какой минимальной температуре рудничной атмосферы в горных выработках проводятся замеры его относительной влажности?',
        answers: ['10 °C', '15 °C', '20 °C', '30 °C'],
        correctAnswers: ['20 °C'],
      },
      {
        code: '63679292',
        text:
          'Кто дает разрешение на перевозку людей по горизонтальным и наклонным горным выработкам угольных шахт с записью в путевом листе?',
        answers: [
          'Машинист локомотива',
          'Специалист (механик) структурного подразделения',
          'Главный инженер шахты',
          'Лицо сменного надзора',
          'Все ответы неверны',
        ],
        correctAnswers: ['Лицо сменного надзора'],
      },
      {
        code: '63679293',
        text:
          'Какими изолирующимиперемычками (ИП) следует изолировать выработанное пространство выемочных участков газовых шахт, проветриваемых по схемам с изолированным отводом метана из выработанного пространства с помощью газоотсасывающих установок?',
        answers: [
          'Водоупорными изолирующими перемычками',
          'Взрывоустойчивыми изолирующими перемычками',
          'Динамически устойчивыми изолирующими перемычками',
          'Безврубовыми изолирующими перемычками',
        ],
        correctAnswers: ['Взрывоустойчивыми изолирующими перемычками'],
      },
      {
        code: '63679294',
        text:
          'Каким должно быть опережение верхнего уступа относительно нижнего при проведении горных выработок уступами по мощным пластам?',
        answers: [
          'Не более 1,5 м',
          'От 1 м до 1,3 м',
          'Не более 2,5 м',
          'От 3 м до 3,1 м',
        ],
        correctAnswers: ['Не более 1,5 м'],
      },
      {
        code: '63679295',
        text:
          'Какую квалификационную группу по электробезопасности должен иметь персонал, производящий управление групповыми аппаратами при напряжениях сети до 1200 В, в тупиковых выработках?',
        answers: [
          'Не ниже пятой',
          'Не ниже четвертой',
          'Не ниже третьей',
          'Не ниже второй',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не ниже второй'],
      },
      {
        code: '63679296',
        text:
          'Кем проводится вскрытие эндогенных и (или) экзогенных пожарных участков?',
        answers: [
          'Специалистами участка аэрогазовой безопасности',
          'Главным инженером шахты (разреза)',
          'Специалистами службы прогноза динамических явлений',
          'Профессионально аварийно-спасательной службой (формированием)',
        ],
        correctAnswers: [
          'Профессионально аварийно-спасательной службой (формированием)',
        ],
      },
      {
        code: '63679297',
        text:
          'В какие периоды должны проводиться температурные съемки на действующих горящих породных отвалах?',
        answers: [
          '1 раз в год: июнь',
          '1 раз в год: сентябрь',
          '2 раза в год: май и сентябрь',
          '3 раза в год: май, июль и сентябрь',
        ],
        correctAnswers: ['2 раза в год: май и сентябрь'],
      },
      {
        code: '63679298',
        text:
          'Кто осуществляет руководство работами по локализации и ликвидации последствий аварии?',
        answers: [
          'Руководитель ОПО',
          'Должностное лицо профессионального аварийно-спасательного формирования, обслуживающего ОПО, назначенное распорядительным документом руководителя профессионального аварийно-спасательного формирования',
          'Должностное лицо Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Технический руководитель (главный инженер) ОПО',
        ],
        correctAnswers: ['Технический руководитель (главный инженер) ОПО'],
      },
      {
        code: '63679299',
        text:
          'Какая минимальная высота ограды, которой обносится территория дегазационных станций (ДС) и дегазационных установок (ДУ) и территория наземных скважин, расположенных за территорией ДС и ДУ?',
        answers: ['1 м', '1,2 м', '1,5 м', '1,8 м', 'Все ответы неверны'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63679300',
        text:
          'Какой должна быть высота реборды над верхним слоем навивки при наличии более одного слоя навивки каната на барабан?',
        answers: [
          '2,5 диаметра каната',
          '2 диаметра каната',
          '1,5 диаметра каната',
          '1 диаметр каната',
        ],
        correctAnswers: ['2,5 диаметра каната'],
      },
      {
        code: '63679301',
        text:
          'Какая максимально допустимая скорость воздуха в вентиляционных скважинах угольных шахт?',
        answers: [
          'Скорость воздуха не ограничена',
          '10 м/с',
          '15 м/с',
          '20 м/с',
          'Все ответы неверны',
        ],
        correctAnswers: ['Скорость воздуха не ограничена'],
      },
      {
        code: '63679302',
        text:
          'Когда после окончания заряжания и прекращения газовыделения из аккумуляторов   разрешается закрывать  аккумуляторы и батарейный ящик?',
        answers: [
          'Не раньше, чем через час',
          'Не раньше, чем через полчаса',
          'Не раньше, чем через 20 минут',
          'Не раньше, чем через 10 минут',
        ],
        correctAnswers: ['Не раньше, чем через час'],
      },
      {
        code: '63679303',
        text:
          'Каким образом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностное лицо?',
        answers: [
          'На основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы',
          'Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора',
          'Распорядительным документом, утверждаемым представителем Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
        ],
        correctAnswers: [
          'Распорядительными документами, утверждаемыми руководителями ОПО и ПАСС(Ф) соответственно',
        ],
      },
      {
        code: '63679304',
        text:
          'На каком максимальном расстоянии от забоя допускается установка датчиков контроля скорости (расхода) воздуха в тупиковых выработках, проводимых с применением взрывчатых материалов, в случае невозможности обеспечения защиты от механических повреждений?',
        answers: ['10 м', '15 м', '20 м', '30 м'],
        correctAnswers: ['30 м'],
      },
      {
        code: '63679305',
        text:
          'В какой срок должен составляться акт по результатам проведения учебной тревоги и учения по ПЛА?',
        answers: [
          'В двухнедельный срок',
          'В недельный срок',
          'В пятидневный срок',
          'Не нормируется',
        ],
        correctAnswers: ['В пятидневный срок'],
      },
      {
        code: '63679306',
        text:
          'Какова периодичность осмотра проходческих лебедок главным механиком (заместителем главного механика) шахты?',
        answers: [
          'Ежесменно',
          'Перед каждой спуско-подъемной операцией',
          '1 раз в сутки',
          '1 раз в неделю',
          '1 раз в месяц',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63679307',
        text:
          'Какое из перечисленных требований к учету загазирований горных выработок указано неверно?',
        answers: [
          'Превышения концентрации метана в выработках выемочного участка принимаются к учету как одно загазирование',
          'Причины нарушения нормальной работы системы АГК и требований по ее эксплуатации заносятся в журнал эксплуатации системы аэрогазового контроля',
          'Случаи нарушения нормальной работы системы аэрогазового контроля и требований по ее эксплуатации расследуются специалистами шахты',
          'Случаи превышения концентрации метана, обусловленные выполнением работ по техническому и метрологическому обслуживанию системы аэрогазового контроля, принимаются к учету как загазирования горных выработок и расследуются',
        ],
        correctAnswers: [
          'Случаи превышения концентрации метана, обусловленные выполнением работ по техническому и метрологическому обслуживанию системы аэрогазового контроля, принимаются к учету как загазирования горных выработок и расследуются',
        ],
      },
      {
        code: '63679308',
        text:
          'На каком расстоянии от устья тупиковой горной выработки, проводимой по пластам, опасным по внезапным выбросам угля (породы) и газа, или по выбросоопасным породам необходимо устанавливать вентиляторы местного проветривания (ВМП) с электрическими двигателями для ее проветривания?',
        answers: [
          'Не менее 150 м',
          'От 130 до 150 м',
          'Не менее 100 м',
          'От 100 до 130 м',
        ],
        correctAnswers: ['Не менее 150 м'],
      },
      {
        code: '63679309',
        text:
          'В соответствии с какими документами проводятся локализация и тушение подземного пожара после его обнаружения?',
        answers: [
          'В соответствии с ПЛА и оперативными планами по локализации и ликвидации последствий аварий',
          'В соответствии с проектом тушения, согласованным с аварийно-спасательной частью',
          'В соответствии с проектом противопожарной защиты (ППЗ)',
          'В соответствии с ПЛА и ППЗ',
        ],
        correctAnswers: [
          'В соответствии с ПЛА и оперативными планами по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63679310',
        text:
          'К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли от 50 до 200 мм?',
        answers: [
          'К I категории (небольшое горное давление)',
          'К II категории (горное давление средней интенсивности)',
          'К III категории (интенсивное горное давление)',
        ],
        correctAnswers: [
          'К II категории (горное давление средней интенсивности)',
        ],
      },
      {
        code: '63679311',
        text:
          'В скважинах какой минимальной длины устанавливаются геофоны на выемочных участках для проведения текущего прогноза по акустической эмиссии?',
        answers: ['1 м', '2 м', '3 м', '4 м', '5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63679312',
        text:
          'При какой из перечисленных неисправностях допускается перевозка людей конвейерными лентами?',
        answers: [
          'При наличии поврежденных стыковых соединений',
          'При наличии продольных сквозных повреждений длиной более 100 мм',
          'При наличии износа или расслоения боковой кромки на 3 % от номинальной ширины ленты',
          'При наличии более 10 повреждений тросов на длине 1 м резинотросовой ленты',
        ],
        correctAnswers: [
          'При наличии износа или расслоения боковой кромки на 3 % от номинальной ширины ленты',
        ],
      },
      {
        code: '63679313',
        text:
          'На каком расстоянии от изолирующей перемычки (ИП) следует размещать электрооборудование и электрические кабели?',
        answers: [
          'Не более 4 м',
          'Не более 3 м',
          'Не менее 3 м',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 5 м'],
      },
      {
        code: '63679314',
        text:
          'Каким образом проверяется высота защитного слоя в оболочке с кварцевым заполнением рудничного взрывобезопасного электрооборудования? Укажите все правильные ответы.',
        answers: [
          'Методом косвенных измерений',
          'Инструментальным методом',
          'Визуально',
          'Посредством смотрового окна',
          'Методом прямых измерений',
        ],
        correctAnswers: ['Визуально', 'Посредством смотрового окна'],
      },
      {
        code: '63679315',
        text:
          'Какие документы необходимы для проведения огневых работ на временных местах?',
        answers: [
          'Только наряд-допуск на проведение огневых работ',
          'Наряд-допуск на проведение огневых работ и план проведения огневых работ',
          'Только план проведения огневых работ',
          'Только распоряжение на проведении огневых работ',
        ],
        correctAnswers: [
          'Наряд-допуск на проведение огневых работ и план проведения огневых работ',
        ],
      },
      {
        code: '63679316',
        text:
          'На каком расстоянии от устья необходимо размещать вентиляторные установки для проветривания вертикальных горных выработок, проводимых с поверхности?',
        answers: [
          'Не менее 20 м',
          'От 15 м до 20 м',
          'Не менее 10 м',
          'От 10 м до 15 м',
        ],
        correctAnswers: ['Не менее 20 м'],
      },
      {
        code: '63679317',
        text:
          'Какова максимально допустимая длина груза, перевозимого работниками шахты на конвейере?',
        answers: ['1,8 м', '2 м', '2,2 м', '2,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63679318',
        text:
          'Какие мероприятия предусматриваются первыми в плане ликвидации аварий (ПЛА)?',
        answers: [
          'Мероприятия, направленные на спасение людей и уменьшение числа возможных жертв',
          'Мероприятия по аварийной подаче воды в шахту',
          'Мероприятия по проведению восстановительных работ в шахте',
          'Мероприятия по изменению режимов проветривания аварийного участка',
        ],
        correctAnswers: [
          'Мероприятия, направленные на спасение людей и уменьшение числа возможных жертв',
        ],
      },
      {
        code: '63679319',
        text:
          'Какие меры должны быть выполнены при возведении изолирующих перемычек в зонах трещиноватости горных пород?',
        answers: [
          'Меры по снижению напора воды',
          'Меры по сохранности изолирующих перемычек при высоких температурах',
          'Меры по снижению сейсмических нагрузок',
          'Меры по снижению фильтрационных свойств угольного пласта и вмещающих пород',
        ],
        correctAnswers: [
          'Меры по снижению фильтрационных свойств угольного пласта и вмещающих пород',
        ],
      },
      {
        code: '63679320',
        text:
          'До какого минимального значения скорости фильтрации воздуха, определенной геофизическими методами, мероприятия по ее снижению на участках целиков угля не выполняются?',
        answers: ['0,001 м/с', '0,002 м/с', '0,003 м/с', '0,004 м/с'],
        correctAnswers: ['0,001 м/с'],
      },
      {
        code: '63679321',
        text:
          'С какой периодичностью определяются абсолютная и относительная газообильности шахты?',
        answers: [
          'Ежесуточно',
          'Еженедельно',
          'Ежемесячно',
          'Ежеквартально',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63679322',
        text:
          'В каком случае разрешаются включение и работа подземной передвижной компрессорной установки?',
        answers: [
          'При засоренных воздушном и масляном фильтрах',
          'При отсутствии тепловой защиты',
          'При содержании метана в месте расположения установки более 1,0 %',
          'При течи масла',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63679323',
        text:
          'Кто имеет неограниченный доступ к просмотру хранящихся данных в устройстве долговременного хранения данных от основных измерительных каналов?',
        answers: [
          'Начальник участка аэрологической безопасности',
          'Работники шахты, назначенные приказом по организации',
          'Главный инженер шахты',
          'Работники территориального органа Ростехнадзора',
          'Все перечисленные',
        ],
        correctAnswers: ['Работники территориального органа Ростехнадзора'],
      },
      {
        code: '63679324',
        text:
          'С какой периодичностью следует контролировать по результатам лабораторного анализа проб отложившейся в горных выработках угольной пыли в местах интенсивного пылеотложения?',
        answers: [
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в 3 месяца',
          'Не реже 2 раз в месяц',
          'Не реже 1 раза в месяц',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63679325',
        text:
          'В каком случае допускается перевозка персонала по горизонтальным и наклонным горным выработкам на грузовых тележках?',
        answers: [
          'Если машинист находится в кабине управления, расположенной в голове поезда',
          'Если перевозится ремонтный персонал',
          'Если персонал использует необходимые средства индивидуальной защиты',
          'Не допускается ни в каком случае',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63679326',
        text:
          'Кем определяется периодичность проведения визуального осмотра и инструментального контроля герметичности изолирующих сооружений?',
        answers: [
          'Главным инженером шахты',
          'Начальником службы охраны труда и промышленной безопасности',
          'Специалистом, ответственным за производство работ',
          'Руководителем горнодобывающей организации',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679327',
        text:
          'Какие из перечисленных шахтных канатов подъемных установок и проходческие канаты для подвески полков, испытанные перед навеской, испытывают повторно через каждые шесть месяцев? Укажите все правильные ответы.',
        answers: [
          'Для спасательных лестниц',
          'На грузовых подъемных установках',
          'Для проходческих люлек',
          'На грузолюдских подъемных установках',
          'На аварийно-ремонтных подъемных установках',
        ],
        correctAnswers: [
          'Для проходческих люлек',
          'На грузолюдских подъемных установках',
        ],
      },
      {
        code: '63679328',
        text:
          'Кто назначается председателем комиссии по проведению учебной тревоги при проверке готовности организации к действиям по спасению людей, локализации и ликвидации последствий аварии?',
        answers: [
          'Руководитель организации',
          'Руководитель подразделения военизированной горноспасательной части',
          'Работник территориального органа Ростехнадзора',
          'Главный инженер шахты',
          'Все ответы неверны',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679329',
        text:
          'Какие угольные пласты относятся к категории "особовыбросоопасные угольные пласты"?',
        answers: [
          'Угольные пласты (горные породы), угрожаемые по горным ударам, на которых в пределах данного шахтного поля произошел горный удар',
          'Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля произошел внезапный выброс угля (породы) и газа',
          'Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля при проведении прогноза выбросоопасности выявлена категория "опасно"',
          'Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений',
        ],
        correctAnswers: [
          'Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений',
        ],
      },
      {
        code: '63679330',
        text:
          'На какое время допускается отключение разъединителей комплектных распределительных устройств (КРУ), если эта операция приводит к нарушению взрывозащиты остающихся под напряжением токоведущих частей разъединителей?',
        answers: [
          'Только на время производства работ по ремонту КРУ',
          'Только на время включения аварийного резерва электрооборудования',
          'Не более 1 часа',
          'Не более 5 часов',
        ],
        correctAnswers: ['Только на время производства работ по ремонту КРУ'],
      },
      {
        code: '63679331',
        text:
          'С какой периодичностью проводится плановая практическая проверка аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?',
        answers: [
          '1 раз в год',
          '1 раз в 3 года',
          '2 раза в год в весенний и осенний периоды',
          '2 раза в год в летний и зимний периоды',
          'Все ответы неверны',
        ],
        correctAnswers: ['2 раза в год в летний и зимний периоды'],
      },
      {
        code: '63679332',
        text:
          'С какой периодичностью проводятся испытания парашютов вагонеток, предназначенных для перевозки людей по наклонным выработкам, в соответствии с документацией организации-изготовителя?',
        answers: [
          '1 раз в 6 месяцев',
          '1 раз в 9 месяцев',
          '1 раз в 10 месяцев',
          '1 раз в 12 месяцев',
        ],
        correctAnswers: ['1 раз в 6 месяцев'],
      },
      {
        code: '63679333',
        text:
          'С какой периодичностью работники шахты должны проходить повторный инструктаж по безопасному ведению горных работ?',
        answers: [
          'Не реже чем каждые 6 месяцев',
          'Не реже чем каждые 9 месяцев',
          'Не реже одного раза в год',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже чем каждые 6 месяцев'],
      },
      {
        code: '63679334',
        text:
          'Кто должен осуществлять контроль концентрации метана и оксида углерода на выходе из смесительной камеры не реже одного раза в смену?',
        answers: [
          'Дежурный машинист',
          'Механик участка',
          'ИТР участка аэрологической безопасности (АБ)',
          'ИТР добычного участка',
        ],
        correctAnswers: ['ИТР добычного участка'],
      },
      {
        code: '63679335',
        text:
          'Какова максимально допустимая скорость подъема и спуска людей по вертикальным выработкам, оборудованным клетями?',
        answers: ['10 м/с', '12 м/с', '15 м/с', '17 м/с', '20 м/с'],
        correctAnswers: ['12 м/с'],
      },
      {
        code: '63679336',
        text:
          'Какое из перечисленных требований к установке обеспыливающих лабиринтных завес указано верно?',
        answers: [
          'В одной лабиринтной завесе устанавливаются не менее шести перегородок',
          'Перегородки устанавливаются на расстоянии не менее 1 м друг от друга',
          'Факелы форсунок должны обеспечивать орошение всей площади перегородки',
          'Перегородки устанавливаются в произвольном порядке',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Факелы форсунок должны обеспечивать орошение всей площади перегородки',
        ],
      },
      {
        code: '63679337',
        text:
          'Какова максимально допустимая концентрации метана в месте проведения измерений сопротивления заземления?',
        answers: ['1 %', '0,8 %', '0,5 %', '0,3 %'],
        correctAnswers: ['1 %'],
      },
      {
        code: '63679338',
        text:
          'К какому типу кровли по обрушаемости относится кровля при сопротивлении пород одноосному сжатию Rc⩽30 МПа?',
        answers: [
          'К I типу кровли',
          'К II типу кровли',
          'К III типу кровли',
          'К IV типу кровли',
        ],
        correctAnswers: ['К III типу кровли'],
      },
      {
        code: '63679339',
        text:
          'Проветривание каких тупиковых горных выработок допускается за счет диффузии?',
        answers: [
          'Длиной до 5 м при условии, что горные работы в них не ведутся',
          'Длиной до 6 м при условии, что горные работы в них не ведутся',
          'Длиной до 7 м при условии, что горные работы в них не ведутся',
          'Длиной до 10 м при условии, что горные работы в них не ведутся',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Длиной до 6 м при условии, что горные работы в них не ведутся',
        ],
      },
      {
        code: '63679340',
        text:
          'В какой срок пользователи недр представляют заявление о согласовании планов развития горных работ в орган государственного горного надзора?',
        answers: [
          'До 1 августа текущего года (года, предшествующего планируемому)',
          'До 15 августа текущего года (года, предшествующего планируемому)',
          'До 1 сентября текущего года (года, предшествующего планируемому)',
          'До 15 сентября текущего года (года, предшествующего планируемому)',
        ],
        correctAnswers: [
          'До 1 сентября текущего года (года, предшествующего планируемому)',
        ],
      },
      {
        code: '63679341',
        text:
          'Кто утверждает ежегодно перечень отрабатываемых пластов угля с результатами оценки их склонности к самовозгоранию?',
        answers: [
          'Территориальный орган Ростехнадзора',
          'Специалист службы прогноза динамических явлений',
          'Профессионально аварийно-спасательная служба (формирование)',
          'Главный инженер шахты (разреза)',
          'Все ответы неверны',
        ],
        correctAnswers: ['Главный инженер шахты (разреза)'],
      },
      {
        code: '63679342',
        text:
          'С какой периодичностью проводится проверка точности показаний датчиков и срабатывания автоматической газовой защиты с помощью контрольных смесей?',
        answers: [
          'Ежедневно',
          'Еженедельно',
          'Ежеквартально',
          'Ежемесячно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63679343',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Комиссией пользователя недр (подрядной организации)',
          'Техническим руководителем пользователя недр (подрядной организации)',
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63679344',
        text:
          'Плакат с какой надписью вывешивается на заблокированную в выключенном положении рукоятку разъединителя аппарата при нарушении проветривания тупиковой выработки?',
        answers: [
          '"Опасно для жизни!"',
          '"Не включать - ремонт!"',
          '"Не включать - работа на линии!"',
          '"Не включать - выработка загазована!"',
        ],
        correctAnswers: ['"Не включать - выработка загазована!"'],
      },
      {
        code: '63679345',
        text:
          'В течение какого времени должны расследоваться аварийные загазирования горных выработок продолжительностью менее 6 часов?',
        answers: [
          'В течение суток',
          'В течение первых 2 дней',
          'В течение первых 3 дней',
          'В течение недели',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63679346',
        text:
          'С какой периодичностью должны производиться ревизия, наладка и аэродинамическое обследование вентиляторов главного проветривания (ВГП) и вспомогательных вентиляторных установок (ВВУ)?',
        answers: [
          'В соответствии с документацией организации-изготовителя и эксплуатационной документацией шахты не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'После случаев возникновения отказов в работе               вентиляторных установок',
          'В соответствии с графиком планово-предупредительного ремонта',
        ],
        correctAnswers: [
          'В соответствии с документацией организации-изготовителя и эксплуатационной документацией шахты не реже 1 раза в 2 года',
        ],
      },
      {
        code: '63679347',
        text:
          'С какой периодичностью проводится контроль состояния заслонов ИТР технологического участка?',
        answers: [
          'Раз в двое суток',
          'Не реже 3 раз за смену',
          'Еженедельно',
          'Ежесменно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63679348',
        text:
          'Какая из перечисленных мер, исключающая рецидив эндогенного и (или) экзогенного пожара, прорыв газов, глины и пульпы в действующие выработки, указана верно?',
        answers: [
          'Ежесменный отбор проб воздуха для определения его состава в газоаналитической лаборатории',
          'Контроль за утечками воздуха через выработанное пространство - не реже одного раза в десять суток, в том числе с применением газов-трассеров',
          'Ведение горных работ не более чем в двух очистных забоях',
          'Увеличение размеров выемочных блоков',
        ],
        correctAnswers: [
          'Контроль за утечками воздуха через выработанное пространство - не реже одного раза в десять суток, в том числе с применением газов-трассеров',
        ],
      },
      {
        code: '63679349',
        text:
          'Какой из перечисленных газов является основным индикаторным газом, используемым при обнаружении начальных стадий возникновения пожаров?',
        answers: [
          'Диоксид углерода',
          'Метан',
          'Оксид углерода',
          'Все перечисленные газы',
        ],
        correctAnswers: ['Оксид углерода'],
      },
      {
        code: '63679350',
        text:
          'Каким методом проводится прогноз удароопасности в месте вскрытия склонного к горным ударам угольного пласта подготовительной выработкой?',
        answers: [
          'Методом регионального прогноза по непрерывным сейсмоакустическим наблюдениям',
          'Методом текущего прогноза по параметрам выбросоопасности',
          'Методом локального прогноза по выходу буровой мелочи',
          'На усмотрение специалиста геологической службы угледобывающей организации',
        ],
        correctAnswers: [
          'Методом локального прогноза по выходу буровой мелочи',
        ],
      },
      {
        code: '63679351',
        text: 'Какие действия при проведении учебных тревог указаны верно?',
        answers: [
          'Местонахождение членов комиссии в горных выработках, в помещении горного диспетчера, в наземных зданиях и сооружениях шахты определяется горным диспетчером',
          'Производится остановка технологического процесса ведения горных работ',
          'Организуется прибытие на шахту подразделения ПАСС(Ф), обслуживающего шахту, в соответствии с мероприятиями и в порядке, предусмотренными ПЛА',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Организуется прибытие на шахту подразделения ПАСС(Ф), обслуживающего шахту, в соответствии с мероприятиями и в порядке, предусмотренными ПЛА',
        ],
      },
      {
        code: '63679352',
        text:
          'При какой температуре запрещается ведение горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'Ниже минус 8 °C',
          'Ниже минус 10 °C',
          'Ниже минус 15 °C',
          'Ниже минус 20 °C',
        ],
        correctAnswers: ['Ниже минус 20 °C'],
      },
      {
        code: '63679353',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных ПЛА?',
        answers: [
          'Руководитель энергомеханической службы ОПО',
          'Начальник участка, на котором произошла авария',
          'Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
          'Начальник службы (участка), обеспечивающей (обеспечивающего) аэрологическую безопасность подземных горных выработок',
        ],
        correctAnswers: ['Руководитель энергомеханической службы ОПО'],
      },
      {
        code: '63679354',
        text:
          'Какова максимально допустимая температура сжатого воздуха для компрессора сухого сжатия в подземных передвижных компрессорных установках?',
        answers: ['182 °С', '160 °С', '140 °С', '120 °С'],
        correctAnswers: ['182 °С'],
      },
      {
        code: '63679355',
        text: 'К какому классу по устойчивости относится устойчивая кровля?',
        answers: ['К I классу', 'К II классу', 'К III классу'],
        correctAnswers: ['К III классу'],
      },
      {
        code: '63679356',
        text:
          'В каких горных выработках применяются анкеры с закреплением стержней минеральной композицией?',
        answers: [
          'Только в горных выработках, пройденных по пластам весьма склонных к самовозгоранию',
          'Только в горных выработках с обводненными породами',
          'Только в горных выработках со сроком службы более 10 лет',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63679357',
        text:
          'Кому должен сообщить горный диспетчер информацию о загазировании или нарушении проветривания?',
        answers: [
          'Главному инженеру шахты',
          'Начальнику участка аэрологической безопасности',
          'Начальнику технологического участка, в выработках которого произошло загазирование',
          'Всем перечисленным лицам',
        ],
        correctAnswers: ['Всем перечисленным лицам'],
      },
      {
        code: '63679358',
        text:
          'С какой периодичностью проводится осмотр главных заземлителей, расположенных в зумпфе и водосборнике?',
        answers: [
          'Не реже 1 раза в 1,5 года',
          'Не реже 1 раза в 12 месяцев',
          'Не реже 1 раза в 10 месяцев',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63679359',
        text:
          'Что должно быть установлено в начале площадки схода, расположенной на участках ленточных конвейеров, используемых для перевозки людей?',
        answers: [
          'Светильник красного цвета',
          'Датчик проезда для автоматического отключения конвейера',
          'Лунно-белый мигающий сигнал',
          'Светильник желтого цвета',
        ],
        correctAnswers: ['Светильник желтого цвета'],
      },
      {
        code: '63679360',
        text:
          'При каком условии сход с ленточного конвейера с грузом может осуществляться только после остановки конвейера?',
        answers: [
          'Сход с ленточного конвейера при любых условиях осуществляться только после остановки конвейера',
          'Если груз перевозится в руках',
          'Если груз размещается в навешенных на сотрудника сумках',
          'Если груз массой более 10 кг',
          'Все ответы неверны',
        ],
        correctAnswers: ['Если груз массой более 10 кг'],
      },
      {
        code: '63679361',
        text:
          'Как должны быть направлены факелы туманообразователей в первой туманообразующей завесе в горной выработке?',
        answers: [
          'При скорости воздуха более 2 м/с - по направлению движения вентиляционной струи, при скорости воздуха 2 м/с и менее - навстречу движению вентиляционной струи',
          'При скорости воздуха более 2 м/с - навстречу движению вентиляционной струи, при скорости воздуха 2 м/с и менее - по направлению движения вентиляционной струи',
          'При любой скорости воздуха - по направлению движения вентиляционной струи',
          'При любой скорости воздуха - навстречу движению вентиляционной струи',
        ],
        correctAnswers: [
          'При скорости воздуха более 2 м/с - навстречу движению вентиляционной струи, при скорости воздуха 2 м/с и менее - по направлению движения вентиляционной струи',
        ],
      },
      {
        code: '63679362',
        text:
          'В каком случае изменяется режим работы газоотсасывающих установок?',
        answers: [
          'Режим работы газоотсасывающих установок не изменяется',
          'В соответствии с инструкцией по эксплуатации применяемых газоотсасывающих установок, утвержденной техническим руководителем (главным инженером) шахты',
          'При возникновении аварийной ситуации в выработках выемочного участка',
          'При принятии такого решения ответственным руководителем ликвидации аварии',
        ],
        correctAnswers: [
          'При принятии такого решения ответственным руководителем ликвидации аварии',
        ],
      },
      {
        code: '63679363',
        text:
          'В каком случае разгазирование горных выработок проводится под руководством ИТР проходческого участка?',
        answers: [
          'При разгазировании подготовительных выработок, не проветриваемых менее 30 минут',
          'При разгазировании очистных выработок, выработок выемочного участка, а также подготовительных выработок, не проветриваемых более 30 минут, но менее 6 часов',
          'При одновременном или последовательном разгазировании нескольких очистных и (или) подготовительных выработок, не проветриваемых более 6 часов',
          'При ликвидации последствий внезапных выделений метана и суфляров',
        ],
        correctAnswers: [
          'При разгазировании подготовительных выработок, не проветриваемых менее 30 минут',
        ],
      },
      {
        code: '63679364',
        text:
          'Какова минимально допустимая толщина главных заземлителей из стальной полосы в зумпфе, водосборнике или специальном колодце?',
        answers: ['5 мм', '10 мм', '12 мм', '15 мм'],
        correctAnswers: ['5 мм'],
      },
      {
        code: '63679365',
        text: 'Какой должна быть минимальная освещенность помещений ДУ?',
        answers: ['10 лк', '20 лк', '25 лк', '30 лк'],
        correctAnswers: ['30 лк'],
      },
      {
        code: '63679366',
        text:
          'При какой концентрации метана разрешается включение вентилятора местного проветривания (ВМП) в месте его установки при разгазировании подготовительных выработок?',
        answers: ['До 1 %', 'До 0,5 %', 'До 1,5 %', 'До 2 %'],
        correctAnswers: ['До 0,5 %'],
      },
      {
        code: '63679367',
        text:
          'В каком случае применяется поэтапное бурение разгрузочных скважин для предотвращения внезапных выбросов?',
        answers: [
          'В случае, если это предусмотрено проектной документацией',
          'В случае, если скважины бурятся в очистных выработках угольных пластов',
          'В случае интенсивного газовыделения',
          'Не применяется ни в каком случае',
        ],
        correctAnswers: ['В случае интенсивного газовыделения'],
      },
      {
        code: '63679368',
        text:
          'Где должны храниться планы работ по профилактике самовозгорания склонных к самовозгоранию угля и отчеты об их выполнении?',
        answers: [
          'У главного инженера шахты (разреза)',
          'У руководителя службы прогноза динамических явлений шахты (разреза)',
          'У руководителя геологической службы шахты (разреза)',
          'На участке аэрологической безопасности (АБ)',
        ],
        correctAnswers: ['На участке аэрологической безопасности (АБ)'],
      },
      {
        code: '63679369',
        text:
          'Какова минимально допустимая высота ограждения проемов площадок размещения технологического оборудования в копрах?',
        answers: ['1400 мм', '1500 мм', '1600 мм', '1800 мм'],
        correctAnswers: ['1600 мм'],
      },
      {
        code: '63679370',
        text: 'Какими выполняют главные шахтные заземлители?',
        answers: [
          'Из медной полосы не менее 65 мм²',
          'Из медной полосы не менее 60 мм²',
          'Из стальной полосы сечением не менее 0,75 м²',
          'Из стальной полосы (троса) сечением не менее 100 мм2',
        ],
        correctAnswers: [
          'Из стальной полосы (троса) сечением не менее 100 мм2',
        ],
      },
      {
        code: '63679371',
        text:
          'Какие из перечисленных участков являются местами интенсивного пылеотложения при разработке пластов, опасных по взрывам угольной пыли?',
        answers: [
          'Подготовительные выработки, проводимые по углю и породе, на протяжении 50 м от их забоев',
          'В районе погрузочных пунктов и на протяжении 25 м от них по направлению вентиляционной струи',
          'Участки откаточных выработок на протяжении 25 м в обе стороны от опрокидывателей',
          'Все перечисленные',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679372',
        text: 'Как характеризуется общее загазирование горных выработок?',
        answers: [
          'Наличием скопления метана с концентрацией 2 % и более в отдельных местах выработок, в том числе у буровых станков, комбайнов и врубовых машин, в открытых, не заложенных породой или другими материалами куполах, превышения нормы концентрации метана, зафиксированного одним датчиком системы аэрогазового контроля (далее - АГК) в действующих выработках шахты',
          'Наличием скопления метана в виде слоя в выработках на участках длиной свыше двух метров с концентрацией более 2 %',
          'Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка',
        ],
        correctAnswers: [
          'Превышением нормы концентрации метана в сечении выработки, превышением нормы концентрации метана, зафиксированным двумя и более датчиками системы АГК в подготовительной выработке или в выработках выемочного участка',
        ],
      },
      {
        code: '63679373',
        text:
          'Каким должно быть содержание метана в скважине при дегазации выработанных пространств ликвидированных шахт?',
        answers: [
          'Не менее 5 %',
          'Не менее 10 %',
          'Не менее 15 %',
          'Не менее 25 %',
        ],
        correctAnswers: ['Не менее 25 %'],
      },
      {
        code: '63679374',
        text:
          'С каким минимальным номинальным временем защитного действия допускается использование дыхательных аппаратов со сжатым воздухом на объектах открытых горных работ?',
        answers: ['4 часа', '3 часа', '2 часа', '1 час', 'Все ответы неверны'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63679375',
        text:
          'На каком минимальном расстоянии выше уровня почвы выемочной печи (штрека) должен быть расположен нижний уровень вентиляционной скважины на пластах средней мощности и мощных пластах?',
        answers: ['1,5 м', '1,0 м', '0,8 м', '0,5 м', 'Все ответы неверны'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63679376',
        text:
          'Каким образом осуществляется заземление агрегатированных машин очистного забоя напряжением 3300 В?',
        answers: [
          'Не менее чем четырьмя заземляющими жилами разных силовых кабелей',
          'Не менее чем тремя заземляющими жилами силовых кабелей',
          'Не менее чем двумя заземляющими жилами разных силовых кабелей',
          'Одной жилой заземления силового кабеля',
        ],
        correctAnswers: [
          'Не менее чем двумя заземляющими жилами разных силовых кабелей',
        ],
      },
      {
        code: '63679377',
        text:
          'Какой минимальный стаж работы в газовых шахтах должно иметь лицо, назначенное для организации работ по дегазации шахт?',
        answers: ['5 лет', '3 года', '2 года', '1 год', 'Все ответы неверны'],
        correctAnswers: ['1 год'],
      },
      {
        code: '63679378',
        text:
          'С какой периодичностью механик участка или его заместитель осматривают электрические машины, аппараты, трансформаторы, их взрывобезопасные оболочки, кабели, заземления?',
        answers: [
          'Ежесменно',
          'Еженедельно',
          'Ежемесячно',
          '1 раз в 3 месяца',
          'Все ответы неверны',
        ],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63679379',
        text:
          'С какой периодичностью и при каком давлении должны испытываться на     герметичность водоотливные трубопроводы главных водоотливных установок после их монтажа и при эксплуатации?',
        answers: [
          '1 раз в 5 лет при давлении, в 1,25 раза превышающем давление, создаваемое насосами при откачке воды',
          '1 раз в 6 лет при давлении, в 1,3 раза превышающем давление, создаваемое насосами при откачке воды',
          '1 раз в 7 лет при давлении, в 1,4 раза превышающем давление, создаваемое насосами при откачке воды',
          '1 раз в 8 лет при давлении, в 1,5 раза превышающем давление, создаваемое насосами при откачке воды',
        ],
        correctAnswers: [
          '1 раз в 5 лет при давлении, в 1,25 раза превышающем давление, создаваемое насосами при откачке воды',
        ],
      },
      {
        code: '63679380',
        text:
          'В течение какого времени при изменении технологии производства, вводе новых и изоляции отработанных участков, изменении схемы вентиляции и путей выхода людей при аварии главным инженером шахты в ПЛА или в его отдельные позиции должны быть внесены соответствующие изменения?',
        answers: [
          'В течение месяца',
          'В течение недели',
          'В течение 3 рабочих дней',
          'В течение суток',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63679381',
        text:
          'Как часто ИТР шахты должны проводить проверку состава рудничной атмосферы у изолирующих перемычек (ИП) индивидуальными переносными приборами контроля газов, замер температуры вытекающей из-за ИП воды и температуру рудничной атмосферы за ИП?',
        answers: [
          'Не реже 4 раз в сутки',
          'Не реже 3 раз в сутки',
          'Не реже 2 раз в сутки',
          'Не реже 1 раза в сутки',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в сутки'],
      },
      {
        code: '63679382',
        text:
          'На каких пластах угольных шахт допускается в качестве вентиляционных печей использовать скважины, пробуренные диаметром не менее 0,7 м?',
        answers: [
          'Не допускается ни на каких пластах',
          'Мощностью менее 7 м',
          'Мощностью менее 6 м',
          'Мощностью менее 5 м',
          'Все ответы неверны',
        ],
        correctAnswers: ['Мощностью менее 5 м'],
      },
      {
        code: '63679383',
        text:
          'Кем принимается решение о прекращении дегазации на выемочном участке или в подготовительной выработке?',
        answers: [
          'Любым из перечисленных лиц',
          'Руководителем профессиональной аварийно-спасательной службы (формирования)',
          'Руководителем работ по локализации и ликвидации последствий аварии',
          'Главным инженером шахты',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679384',
        text:
          'Кто определяет перечень мест обнаружения ранних признаков возникновения пожаров?',
        answers: [
          'Специально назначенное лицо, проводившее обследование выработок',
          'Начальник участка аэрологической безопасности',
          'Руководитель организации',
          'Главный инженер шахты',
          'Все ответы неверны',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679385',
        text:
          'Кем проводится ежесменный осмотр всех шахтных заземляющих устройств в начале каждой смены? Укажите все правильные ответы.',
        answers: [
          'Ответственным за электробезопасность',
          'Начальником участка',
          'Лицами, обслуживающими электрооборудование',
          'Дежурными электрослесарями участка',
          'Специалистом по охране труда',
        ],
        correctAnswers: [
          'Лицами, обслуживающими электрооборудование',
          'Дежурными электрослесарями участка',
        ],
      },
      {
        code: '63679386',
        text:
          'Каким должен быть суммарный зазор между направляющими башмаками скольжения подъемного сосуда (противовеса) и проводниками при их установке на базовой отметке в лобовом и боковом направлениях для рельсовых и деревянных проводников?',
        answers: [
          'Соответственно 10 мм и 20 мм',
          'Соответственно 11 мм и 22 мм',
          'Соответственно 12 мм и 24 мм',
          'Соответственно 14 мм и 26 мм',
        ],
        correctAnswers: ['Соответственно 10 мм и 20 мм'],
      },
      {
        code: '63679387',
        text:
          'С какой минимальной выдержкой времени должны срабатывать устройства автоматического повторного включения (АПВ) и автоматического включения резерва (АВР), если выдержка на включение выключателя не обеспечивается другими устройствами?',
        answers: [
          'Устройства АПВ и АВР срабатывают без выдержки времени',
          '0,5 с',
          '1 с',
          '2 с',
        ],
        correctAnswers: ['2 с'],
      },
      {
        code: '63679388',
        text:
          'Кем устанавливается порядок контроля средств взрывозащиты горных выработок и выполнение мероприятий по предупреждению взрывов угольной пыли?',
        answers: [
          'Главным инженером шахты',
          'Руководителем горнодобывающей организации',
          'Начальником проходческого (добычного) участка',
          'Специалистом по охране труда и промышленной безопасности',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679389',
        text:
          'Что применяется для уплотнения кабеля при проведении ежеквартальной ревизии рудничного взрывобезопасного электрооборудования?',
        answers: [
          'Обрезки оболочки гибких резиновых кабелей',
          'Изоляционная лента',
          'Сырая резина',
          'Уплотнительное кольцо',
          'Все ответы неверны',
        ],
        correctAnswers: ['Уплотнительное кольцо'],
      },
      {
        code: '63679390',
        text:
          'Как часто дежурный электрослесарь участка производит осмотр рудничного взрывобезопасного электрооборудования?',
        answers: [
          'Ежемесячно',
          'Ежеквартально',
          'Еженедельно',
          'Ежесменно',
          'По просьбе лица, обслуживающего электрооборудование',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63679391',
        text:
          'Каким должно быть содержание диоксида углерода в рудничном воздухе на рабочих местах, в исходящих струях выемочных участков и тупиковых выработок?',
        answers: ['0,5 %', 'От 0,3 % до 0,75 %', '0,75 %', 'От 0,5 % до 1,0 %'],
        correctAnswers: ['0,5 %'],
      },
      {
        code: '63679392',
        text:
          'Какие мероприятия выполняет угледобывающая организация в целях организации и осуществления работ по дегазации?',
        answers: [
          'Производит или организует бурение дегазационных скважин',
          'Производит замену вакуум-насосов и прокладку газопроводов',
          'Контролирует качество бурения и герметизации дегазационных скважин',
          'Осуществляет периодические измерения концентрации и дебита метана, разрежения на скважинах и газопроводах под контролем участка аэрологической безопасности',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679393',
        text:
          'Для кого предназначена Инструкция по порядку разработки планов ликвидации аварий на угольных шахтах, ознакомления, проведения учебных тревог и учений по ликвидации аварий, проведения плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?',
        answers: [
          'Для организаций, осуществляющих добычу угля подземным способом',
          'Для профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Для надзорных и контролирующих органов',
          'Для всех перечисленных',
        ],
        correctAnswers: ['Для всех перечисленных'],
      },
      {
        code: '63679394',
        text:
          'Кто утверждает акт плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий (ПЛА)?',
        answers: [
          'Главный инженер шахты',
          'Начальник проходческого (добычного) участка',
          'Главный механик шахты',
          'Начальник участка аэрологической безопасности',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63679395',
        text:
          'При какой максимальной температуре пород разбираемого слоя разрешается производить работы по тушению или разборке горящих породных отвалов?',
        answers: ['80 °С', '85 °С', '90 °С', '95 °С'],
        correctAnswers: ['80 °С'],
      },
      {
        code: '63679396',
        text:
          'С какой периодичностью проводятся инструктажи по промышленной безопасности и применению СИЗОД изолирующего типа в угледобывающей организации?',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в месяц',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63679397',
        text:
          'С какой периодичностью проводится проверка знаний требований Инструкции по аэрологической безопасности угольных шахт у руководителей и ИТР экзаменационными комиссиями?',
        answers: [
          '1 раз в 12 месяцев',
          '1 раз в 3 года',
          '1 раз в 5 лет',
          'Периодичность проверки знаний устанавливает главный инженер шахты',
          'Все ответы неверны',
        ],
        correctAnswers: ['1 раз в 5 лет'],
      },
      {
        code: '63679398',
        text:
          'Какое требование должно соответствовать оборудованной камере (сбойке или нише), в которую монтируется подземная газоотсасывающая установка?',
        answers: [
          'Крепление камеры должно быть выполнено из негорючего материала',
          'Подход к установке должен быть обеспечен с обеих сторон',
          'Камера должна проветриваться свежей струей воздуха',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679399',
        text:
          'Где устанавливают аппаратуру аварийной связи и оповещения на угольных шахтах?',
        answers: [
          'У диспетчера',
          'У главного инженера шахты',
          'У абонентов',
          'У всех перечисленных',
          'Все ответы неверны',
        ],
        correctAnswers: ['У всех перечисленных'],
      },
      {
        code: '63679400',
        text:
          'Какие требования к монтажу, оснащению и эксплуатации дегазационных газопроводов указаны неверно?',
        answers: [
          'Для уплотнения фланцевых соединений применяются прокладки из трудносгораемого материала, внутренний диаметр которых на 2 - 3 мм больше внутреннего диаметра трубы',
          'Трубы в дегазационном газопроводе испытываются на давление 1,2 МПа при его прокладке по горизонтальным или наклонным выработкам и 2,0 МПа - при прокладке по вертикальным выработкам',
          'Минимальный диаметр участкового трубопровода принимается не менее 150 мм, минимальный диаметр магистрального трубопровода - не менее 300 мм',
          'Трубы газопровода в скважинах и стволах соединяются сваркой. Для увеличения прочности на сварные швы накладываются пластины или бандажи длиной 150 - 200 мм',
        ],
        correctAnswers: [
          'Трубы в дегазационном газопроводе испытываются на давление 1,2 МПа при его прокладке по горизонтальным или наклонным выработкам и 2,0 МПа - при прокладке по вертикальным выработкам',
        ],
      },
      {
        code: '63679401',
        text:
          'В выработках какого сечения устанавливаются фиксированные полки сланцевого заслона?',
        answers: [
          'В выработках площадью сечения более 15 м2',
          'В выработках площадью сечения до 15 м2',
          'В выработках площадью сечения до 10 м2',
          'В выработках площадью сечения более 10 м2',
        ],
        correctAnswers: ['В выработках площадью сечения до 10 м2'],
      },
      {
        code: '63679402',
        text:
          'При какой минимальной температуре в течение не менее 24 часов в местах предполагаемого нахождения людей работы по поиску и спасению людей не проводятся или прекращаются?',
        answers: ['40 °C', '60 °C', '80 °C', '100 °C'],
        correctAnswers: ['100 °C'],
      },
      {
        code: '63679403',
        text: 'Какие сведения указываются на схеме вентиляции шахт?',
        answers: [
          'Расчетный и фактический расход воздуха, поступающего в шахту, на крылья, панели, блоки и горизонты',
          'Расчетный и фактический расход воздуха, поступающего на выемочные участки и в очистные забои',
          'Расчетные и фактические внешние утечки воздуха',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679404',
        text:
          'С какой задержкой времени выполняют защиту минимального напряжения на питающих линиях центральной подземной подстанции?',
        answers: ['2 с', '4 с', '5 с', '10 с', 'Все ответы неверны'],
        correctAnswers: ['10 с'],
      },
      {
        code: '63679405',
        text:
          'Какое минимальное количество администраторов системы аэрогазового контроля, на которых возлагается персональная ответственность за функционирование наземного компьютерного комплекса, непрерывную работу регистратора, назначается приказом по шахте?',
        answers: ['Один', 'Двое', 'Трое', 'Четверо', 'Все ответы неверны'],
        correctAnswers: ['Двое'],
      },
      {
        code: '63679406',
        text:
          'Каков максимально допустимый период времени для отбора проб угля на каждом отрабатываемом пласте угля после его обнажения?',
        answers: ['6 часов', '12 часов', '18 часов', '24 часа'],
        correctAnswers: ['24 часа'],
      },
      {
        code: '63679407',
        text:
          'Кем утверждаются пороговые значения прогностических параметров, установленные по результатам текущего прогноза выбросоопасности по параметрам искусственного акустического сигнала?',
        answers: [
          'Работником специализированной организации',
          'Работником службы прогноза динамических явлений',
          'Работником геологической службы угледобывающей организации',
          'Техническим руководителем (главным инженером) угледобывающей организации',
        ],
        correctAnswers: [
          'Техническим руководителем (главным инженером) угледобывающей организации',
        ],
      },
      {
        code: '63679408',
        text:
          'Кто несет персональную ответственность за работоспособность и правильность настройки датчиков аэрогазового контроля и работоспособность автоматической газовой защиты?',
        answers: [
          'Горный диспетчер',
          'Электрослесарь по обслуживанию',
          'Главный инженер шахты',
          'Механик группы аэрогазового контроля (руководитель группы аэрогазового контроля или механик участка)',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Механик группы аэрогазового контроля (руководитель группы аэрогазового контроля или механик участка)',
        ],
      },
      {
        code: '63679409',
        text:
          'Каким (по объему) должно быть содержание кислорода в рудничной атмосфере горных выработок, в которых находится или может находиться человек?',
        answers: [
          'Не менее 12 %',
          'Не менее 15 %',
          'Не менее 18 %',
          'Не менее 20 %',
        ],
        correctAnswers: ['Не менее 20 %'],
      },
      {
        code: '63679410',
        text:
          'Какова периодичность контроля эндогенной пожароопасности целиков у наклонных воздухоподающих выработок, пройденных по углю, на пластах бурого угля, геофизическими методами и (или) по температуре угля в бортах, кровле и почве?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 2 раз в месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63679411',
        text:
          'С какой периодичностью распорядительным документом руководителя угледобывающей организации угольные пласты и горные породы относятся к категориям по динамическим явлениям, утверждаются опасные зоны, и определяется комплекс мер по прогнозу и предотвращению динамических явлений?',
        answers: [
          'Раз в 5 лет',
          'Раз в 3 года',
          'Раз в полгода',
          'Ежегодно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63679412',
        text:
          'Какие радиусы закругления рельсовых путей и переводных кривых в действующих горных выработках устанавливаются для колеи 600 мм и 900 мм?',
        answers: [
          'Не менее 8 м и не менее 12 м соответственно',
          'Не менее 15 м и не менее 23 м соответственно',
          'Не менее 12 м и не менее 20 м соответственно',
          'Не менее 5 м и не менее 8 м соответственно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не менее 8 м и не менее 12 м соответственно'],
      },
      {
        code: '63679413',
        text: 'На какой срок составляется график учебных тревог на шахте?',
        answers: [
          'На 3 месяца',
          'На 6 месяцев',
          'На 9 месяцев',
          'На календарный год',
        ],
        correctAnswers: ['На календарный год'],
      },
      {
        code: '63679414',
        text:
          'Какое из перечисленных требований к контролю горных выработок, оборудованных ленточными конвейерными установками, указано неверно?',
        answers: [
          'Датчики оксида углерода устанавливаются на расстоянии не более 25 м от приводной, натяжной станций, мест перегрузки угля и изменения угла наклона конвейера в направлении движения вентиляционной струи',
          'В линейной части конвейера датчики оксида углерода размещаются с учетом скорости движения воздуха так, чтобы время движения воздуха между датчиками не превышало 10 минут',
          'Если в выработке с общей исходящей струей необходимо установить несколько датчиков, удаленных друг от друга на расстояние не более 100 м, то рекомендуется объединить точки контроля и использовать единственный датчик, который устанавливается в последней из объединяемых точек по ходу движения вентиляционной струи',
          'Датчики монтируются в нижней части выработки',
        ],
        correctAnswers: ['Датчики монтируются в нижней части выработки'],
      },
      {
        code: '63679415',
        text:
          'В каких местах предусматривается установка электрических аппаратов, обеспечивающих отключение токов короткого замыкания?',
        answers: [
          'Только на всех вводных, секционных, резервных и отходящих присоединениях центральной подземной подстанции (ЦПП)',
          'Только на всех вводных, секционных, резервных и отходящих присоединениях распределительной подземной подстанции (РПП)',
          'Только на ответвлениях от магистрали, а также в конце линий, питающих силовые трансформаторы или другие электроприемники, не имеющие встроенных разъединителей',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63679416',
        text:
          'Какая установлена максимальная длительность эксплуатации резинотканевых конвейерных лент с прочностью прокладок 3 кН/см?',
        answers: [
          '4,5 года',
          '3,5 года',
          '2,5 года',
          '1,5 года',
          'Все ответы неверны',
        ],
        correctAnswers: ['2,5 года'],
      },
      {
        code: '63679417',
        text:
          'Какова периодичность осмотра проходческих лебедок электрослесарем? Выберите два правильных варианта ответов.',
        answers: [
          'Ежесменно',
          'Перед каждой спуско-подъемной операцией',
          '1 раз в сутки',
          '1 раз в неделю',
          '1 раз в месяц',
        ],
        correctAnswers: [
          'Ежесменно',
          'Перед каждой спуско-подъемной операцией',
        ],
      },
      {
        code: '63679418',
        text:
          'К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли свыше 200 мм?',
        answers: [
          'К I категории (небольшое горное давление)',
          'К II категории (горное давление средней интенсивности)',
          'К III категории (интенсивное горное давление)',
        ],
        correctAnswers: ['К III категории (интенсивное горное давление)'],
      },
      {
        code: '63679419',
        text:
          'Какой должна быть навивка на барабаны машин на вертикальных и наклонных поверхностных, а также подземных с углом наклона более 60° грузолюдских и людских подъемах?',
        answers: [
          'Должна быть однослойной',
          'Должна быть двухслойной',
          'Должна быть трехслойной',
          'Должна быть многослойной',
        ],
        correctAnswers: ['Должна быть однослойной'],
      },
      {
        code: '63679420',
        text:
          'С какой периодичностью проводится проверка состава рудничной атмосферы и замер ее расхода на шахтах, разрабатывающих пласты угля, склонного к самовозгоранию?',
        answers: [
          '1 раз в месяц',
          '2 раза в месяц',
          '3 раза в месяц',
          '1 раз в 3 месяца',
        ],
        correctAnswers: ['3 раза в месяц'],
      },
      {
        code: '63679421',
        text:
          'В течение какого времени после после начала проведения горной выработки определяется технически достижимый уровень запыленности рудничной атмосферы для подготовительных выработок?',
        answers: [
          'В течение 35 рабочих дней',
          'В течение 20 рабочих дней',
          'В течение 15 рабочих дней',
          'В течение 10 рабочих дней',
          'Все ответы неверны',
        ],
        correctAnswers: ['В течение 10 рабочих дней'],
      },
      {
        code: '63679422',
        text:
          'На каких объектах по категориям пожарной и взрывопожарной опасности оборудуются временные места проведения огневых работ?',
        answers: [
          'Только в надшахтных зданиях и сооружениях шахт (фабрик), имеющих пожароопасность и умеренную пожароопасность',
          'Только в надшахтных зданиях и сооружениях шахт (фабрик) с пониженной пожароопасностью',
          'Только в горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик), имеющих пониженную пожароопасность и умеренную пожароопасность',
          'В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности',
        ],
        correctAnswers: [
          'В горных выработках шахт, надшахтных зданиях и сооружениях шахт (фабрик) независимо от их категории по пожарной и взрывопожарной опасности',
        ],
      },
      {
        code: '63679423',
        text:
          'С какой периодичностью ИТР структурного подразделения, в ведении которых находится горная выработка, должны осматривать крепь и армировку вертикальных и наклонных стволов?',
        answers: [
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в неделю',
          'Ежесуточно',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63679424',
        text:
          'В какой форме главный инженер шахты сообщает о затоплении горных выработок шахты главным инженерам смежных шахт?',
        answers: ['В письменной форме', 'В устной форме', 'В любой форме'],
        correctAnswers: ['В письменной форме'],
      },
      {
        code: '63679425',
        text:
          'В каких горных выработках проводится локальный прогноз удароопасности угольных пластов?',
        answers: [
          'В подготовительных горных выработках, проводимых по угольным пластам',
          'В горных выработках действующих выемочных участков',
          'В поддерживаемых горных выработках',
          'Во всех перечисленных горных выработках',
        ],
        correctAnswers: ['Во всех перечисленных горных выработках'],
      },
      {
        code: '63679426',
        text:
          'Какое расстояние от контактного провода до верхняка крепи горной выработки на угольных шахтах?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 0,4 м',
          'Не менее 0,3 м',
          'Не менее 0,2 м',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не менее 0,2 м'],
      },
      {
        code: '63679427',
        text:
          'В каком случае следует относить пласты угля к категории склонных к самовозгоранию по продолжительности инкубационного периода самовозгорания угля?',
        answers: [
          'При продолжительности инкубационного периода менее 40 суток включительно',
          'При продолжительности инкубационного периода от 41 до 80 суток включительно',
          'При продолжительности инкубационного периода более 80 суток включительно',
          'При продолжительности инкубационного периода менее 100 суток включительно',
        ],
        correctAnswers: [
          'При продолжительности инкубационного периода от 41 до 80 суток включительно',
        ],
      },
      {
        code: '63679428',
        text:
          'Какие способы управления кровлей применяются на склонных к горным ударам угольных пластах?',
        answers: [
          'Частичное обрушение пород в выработанном пространстве',
          'Закладка выработанного пространства',
          'Полное обрушение пород в выработанном пространстве',
          'Все перечисленные способы',
        ],
        correctAnswers: ['Все перечисленные способы'],
      },
      {
        code: '63679429',
        text:
          'С какой периодичностью должна составляться пояснительная записка вентиляционного плана шахты?',
        answers: [
          'Ежемесячно',
          'Ежеквартально',
          '1 раз в полугодие',
          'Ежегодно при подготовке шахтой производственной программы развития горных работ',
        ],
        correctAnswers: [
          'Ежегодно при подготовке шахтой производственной программы развития горных работ',
        ],
      },
      {
        code: '63679430',
        text:
          'Какой вид пылевзрывозащиты применяется в шахтах для локализации и предупреждения взрывов угольной пыли?',
        answers: [
          'Только гидропылевзрывозащита',
          'Только комбинированная пылевзрывозащита',
          'Только сланцевая пылевзрывозащита',
          'Возможно применение всех перечисленных видов пылевзрывозащиты',
        ],
        correctAnswers: [
          'Возможно применение всех перечисленных видов пылевзрывозащиты',
        ],
      },
      {
        code: '63679431',
        text:
          'В течение какого времени должны храниться акты расследования загазирования горных выработок?',
        answers: [
          'В течение 1 года',
          'В течение 2 лет',
          'В течение 3 лет',
          'В течение 5 лет',
        ],
        correctAnswers: ['В течение 3 лет'],
      },
      {
        code: '63679432',
        text:
          'В каком из приведенных случаев проверка состава рудничной атмосферы должна проводиться 3 раза в месяц?',
        answers: [
          'После взрывных работ в стволах независимо от их глубины',
          'После взрывных работ в подготовительных выработках при их длине 300 м и более',
          'При проходке стволов, переведенных на газовый режим',
          'При изменении паспорта буровзрывных работ',
        ],
        correctAnswers: ['При проходке стволов, переведенных на газовый режим'],
      },
      {
        code: '63679433',
        text:
          'Какова минимально допустимая высота проемов, в которых устраивают двери для прохода людей?',
        answers: ['1,6 м', '1,8 м', '2 м', '2,2 м'],
        correctAnswers: ['1,8 м'],
      },
      {
        code: '63679434',
        text:
          'Каким требованиям должна соответствовать горная выработка , пройденная по пласту угля в обе стороны на расстояние не менее 5 м от изолирующей перемычки (ИП)?',
        answers: [
          'Должна бытьтолько закреплена негорючей крепью',
          'Должна быть только очищена на боках и в кровле от отслоившихся угля и породы',
          'Должна быть толькозакреплена дополнительной крепью',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63679435',
        text:
          'С какой периодичностью проводится инструментальная проверка работоспособности водоотливной установки?',
        answers: [
          'Не реже 1 раза в год',
          '1 раз в 1,5 года',
          'Не реже 1 раза в 2 года',
          '1 раз в 2,5 года',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63679436',
        text:
          'С какой периодичностью и кем должно проверяться состояние вентиляторов главного проветривания (ВГП) и вспомогательных вентиляторных установок (ВВУ) на шахтах?',
        answers: [
          'Не реже 1 раза в сутки - обсуживающим персоналом и не менее 2 раз в месяц - главным механиком шахты и (или) ИТР шахты, им определенным',
          'Не реже 1 раза в сутки - горным мастером и не реже 1 раза в месяц - начальником участка или его заместителем',
          'Не реже 1 раза в трое суток - горным мастером и не реже 1 раза в месяц - представителем территориального органа Ростехнадзора',
          'Не реже 1 раза в сутки - работниками, специально назначенными главным механиком шахты, и не менее 1 раза в месяц - представителем аварийно-спасательной службы, обслуживающей организацию',
        ],
        correctAnswers: [
          'Не реже 1 раза в сутки - обсуживающим персоналом и не менее 2 раз в месяц - главным механиком шахты и (или) ИТР шахты, им определенным',
        ],
      },
      {
        code: '63679437',
        text:
          'Какие из перечисленных мер по предотвращению динамических явлений относятся к региональным мерам?',
        answers: [
          'Бурение разгрузочных скважин',
          'Камуфлетное взрывание',
          'Опережающая отработка защитных угольных пластов, в том числе локальная отработка защитного угольного пласта',
          'Все перечисленные меры',
        ],
        correctAnswers: [
          'Опережающая отработка защитных угольных пластов, в том числе локальная отработка защитного угольного пласта',
        ],
      },
      {
        code: '63679438',
        text:
          'С какой периодичностью ИТР шахты в порядке, утвержденном распорядительным документом руководителя шахты, должен выборочно контролировать головные светильники на шахте?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в полугодие',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63679439',
        text:
          'Какова минимально допустимая высота стенок или металлической сетки, ограждающих с нерабочих сторон устья действующих и находящихся в проходке вертикальных и наклонных горных выработок, оборудованных подъемными установками?',
        answers: ['2,5 м', '2,2 м', '1,8 м', '1,5 м'],
        correctAnswers: ['2,5 м'],
      },
      {
        code: '63679440',
        text:
          'Кем разрабатываются мероприятия по разгазированию горных выработок до начала проведения подготовительных выработок и отработки выемочных участков?',
        answers: [
          'Техническим руководителем (главным инженером) шахты',
          'Специализированной организацией',
          'Начальником проходческого (добычного) участка',
          'Начальником участка аэрологической безопасности (АБ)',
        ],
        correctAnswers: [
          'Начальником участка аэрологической безопасности (АБ)',
        ],
      },
      {
        code: '63679441',
        text:
          'От каких явлений в подземных электрических сетях при напряжении до 1200 В осуществляется защита электродвигателей и питающих их кабелей?',
        answers: [
          'От токов короткого замыкания: мгновенная или селективная',
          'От включения напряжения при сниженном сопротивлении изоляции относительно земли',
          'От перегрузки, перегрева, опрокидывания и несостоявшегося пуска электродвигателей, работающих в режиме экстремальных перегрузок',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63679442',
        text:
          'Каков максимально допустимый износ на сторону в лобовом и боковом направлениях у рельсовых проводников?',
        answers: ['8 мм', '7 мм', '6 мм', '5 мм'],
        correctAnswers: ['8 мм'],
      },
      {
        code: '63679443',
        text: 'В каких случаях дегазация в угольных шахтах обязательна?',
        answers: [
          'Когда извлечение и утилизация шахтного метана экономически выгодны',
          'Когда концентрация метана в газопроводах и газодренажных выработках превышает 3,5 %',
          'Когда природная метаноносность пласта превышает 13 м³/т сухой беззольной массы и работами по вентиляции невозможно обеспечить содержание метана в исходящей струе очистной горной выработки в размере менее 1 %',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63679444',
        text:
          'Какие устанавливаются нормы замедления при торможении порожних клетей парашютами и клетей с максимальным числом людей?',
        answers: [
          'При торможении порожних клетей парашютами не должно превышать 30 м/c², при торможении клетей с максимальным числом людей - не менее 5 м/c²',
          'При торможении порожних клетей парашютами не должно превышать 70 м/c², при торможении клетей с максимальным числом людей - не менее 8 м/c²',
          'При торможении порожних клетей парашютами не должно превышать 100 м/c², при торможении клетей с максимальным числом людей - не менее 10 м/c²',
          'При торможении порожних клетей парашютами не должно превышать 50 м/c², при торможении клетей с максимальным числом людей - не менее 6 м/c²',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'При торможении порожних клетей парашютами не должно превышать 50 м/c², при торможении клетей с максимальным числом людей - не менее 6 м/c²',
        ],
      },
      {
        code: '63679445',
        text:
          'Что необходимо предусматривать при выборе способов и схем проветривания на шахтах, разрабатывающих склонные к самовозгоранию пласты угля?',
        answers: [
          'Только снижение действующих напоров (депрессии) в районе очистных работ',
          'Только уменьшение величины и продолжительности притока воздуха в выработанное пространство',
          'Только надежное управление вентиляционными режимами в аварийной обстановке',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63679446',
        text:
          'На какой срок составляется план развития горных работ по всем планируемым видам горных работ?',
        answers: ['На 6 месяцев', 'На 1 год', 'На 2 года', 'На 3 года'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63679447',
        text:
          'В течение какого времени после изоляции эндогенного и (или) экзогенного пожарного участка необходимо осуществлять отбор проб ежесуточно?',
        answers: [
          'В течение последующих 5 суток',
          'В течение последующих 10 суток',
          'В течение последующих 15 суток',
          'В течение последующих 25 суток',
          'Все ответы неверны',
        ],
        correctAnswers: ['В течение последующих 15 суток'],
      },
      {
        code: '63679448',
        text:
          'При каком расстоянии от сопряжения горных выработок до возводимой изолирующей перемычки должно быть выполнено усиление крепи горной выработки на участке от изолирующей перемычки до сопряжения горных выработок?',
        answers: [
          'Не менее 10 м',
          'Не менее 7 м',
          'Не менее 6 м',
          'Менее 5 м',
          'Все ответы неверны',
        ],
        correctAnswers: ['Менее 5 м'],
      },
      {
        code: '63679449',
        text:
          'Какие действия предусматриваются ПЛА для предупреждения развития аварии при пожаре?',
        answers: [
          'Включение водяных завес и водоразбрызгивателей на путях возможного развития пожара',
          'Мероприятия по предупреждению падения подъемных устройств в вертикальных и наклонных горных выработках при перегорании или обрыве канатов подъемных установок',
          'Закрытие противопожарных дверей и ляд в горных выработках',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63679450',
        text:
          'Кем утверждается акт обследования газодренажных выработок угольных шахт?',
        answers: [
          'Лицом, проводившим обследование газодренажных выработок',
          'Начальником участка аэрологической безопасности',
          'Руководителем организации',
          'Главным инженером шахты',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679451',
        text:
          'С какой периодичностью комиссия, осуществляющая контроль выполнения мер по изоляции неиспользуемых горных выработок и выработанных пространств, проводит визуальный контроль на земной поверхности изоляции устьев изолированных неиспользуемых горных выработок?',
        answers: [
          'Не менее 1 раза в квартал',
          'Не менее 1 раза в год',
          'Не менее 2 раз в год',
          'Не менее 4 раз в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не менее 2 раз в год'],
      },
      {
        code: '63679452',
        text:
          'Через какое максимальное расстояние подвигания забоя подготовительной или очистной выработки пороговые значения прогностических параметров переутверждаются?',
        answers: ['100 м', '200 м', '300 м', '400 м', 'Все ответы неверны'],
        correctAnswers: ['300 м'],
      },
      {
        code: '63679453',
        text: 'К какому классу по устойчивости относится неустойчивая кровля?',
        answers: ['К I классу', 'К II классу', 'К III классу'],
        correctAnswers: ['К I классу'],
      },
      {
        code: '63679454',
        text:
          'Через сколько лет эксплуатации анкерной крепи в выработках необходимо производить научно-исследовательские работы с оценкой несущей способности, коррозионного износа и работоспособности анкеров?',
        answers: ['Через 5 лет', 'Через 8 лет', 'Через 3 года', 'Через 10 лет'],
        correctAnswers: ['Через 5 лет'],
      },
      {
        code: '63679455',
        text:
          'С какой периодичностью проверяется общее время отключения сети напряжением 1200 В под действием аппарата защиты от утечек тока?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63679456',
        text:
          'При каком максимальном содержании метана (% объемной доли) в исходящей струе воздуха из очистной или тупиковой выработки, камеры, выемочного участка, поддерживаемой выработки в них обеспечивается безопасное аэрогазовое состояние по метану?',
        answers: ['0,5 %', '0,75 %', '1 %', '2 %'],
        correctAnswers: ['1 %'],
      },
      {
        code: '63679457',
        text:
          'При каких условиях допускается ведение горноспасательных работ в тупиковой выработке?',
        answers: [
          'При отсутствии в выработке пострадавших',
          'При тушении пожара в непроветриваемых тупиковых горных выработках',
          'При отсутствии информации о содержании в ней горючих газов',
          'При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека',
        ],
        correctAnswers: [
          'При тушении пожара в выработанном пространстве подачей огнетушащих веществ дистанционно, при этом люди должны находиться на расстоянии от места возникновения аварии, далее которого отсутствует вероятность воздействия опасных факторов аварии на человека',
        ],
      },
      {
        code: '63679458',
        text:
          'Какова минимальная площадь поперечного сечения главных откаточных и вентиляционных горных выработок?',
        answers: ['9 м2', '6 м2', '4,5 м2', '3,7 м2'],
        correctAnswers: ['9 м2'],
      },
      {
        code: '63679459',
        text:
          'Каково минимально допустимое содержании пыли в рудничном воздухе в горных выработках с исходящей струей воздуха после обеспыливающей завесы?',
        answers: ['150 мг/м3', '120 мг/м3', '100 мг/м3', '50 мг/м3'],
        correctAnswers: ['150 мг/м3'],
      },
      {
        code: '63679460',
        text:
          'Какова максимально допустимая периодичность определения качества изоляции изолирующих перемычек (ИП) после первичной проверки качества изоляции при их возведении, для ИП, изолирующих неиспользуемые горные выработки и выработанное пространство на пластах угля, склонных к самовозгоранию?',
        answers: ['3 месяца', '6 месяцев', '9 месяцев', '1 год'],
        correctAnswers: ['1 год'],
      },
      {
        code: '63679461',
        text:
          'Сколько планов ликвидации аварий (ПЛА) разрабатывается для шахт, имеющих единую вентиляционную систему?',
        answers: [
          'Количество ПЛА определяет технический руководитель (главный инженер) угледобывающей организации',
          'В зависимости от количества шахт - на каждую отдельно',
          'Не менее двух ПЛА',
          'Один',
        ],
        correctAnswers: ['Один'],
      },
      {
        code: '63679462',
        text:
          'К какому классу по устойчивости относится кровля средней устойчивости?',
        answers: ['К I классу', 'К II классу', 'К III классу'],
        correctAnswers: ['К II классу'],
      },
      {
        code: '63679463',
        text:
          'В соответствии с чем должен проводиться мониторинг массива горных пород геофизическими методами?',
        answers: [
          'В соответствии с распорядительным документом руководителя угледобывающей организации',
          'В соответствии с Правилами безопасности в угольных шахтах',
          'В соответствии с Инструкцией по прогнозу динамических явлений и мониторингу массива горных пород при отработке угольных месторождений',
          'В соответствии с проектной документацией, утвержденной главным инженером',
        ],
        correctAnswers: [
          'В соответствии с проектной документацией, утвержденной главным инженером',
        ],
      },
      {
        code: '63679464',
        text:
          'Какие категории по пожарной и взрывопожарной опасности установлены для надшахтных зданий и сооружений шахт и фабрик для возможного оборудования в них постоянных мест проведения огневых работ? Выберите 2 варианта ответов.',
        answers: [
          'Пониженная пожароопасность',
          'Умеренная пожароопасность',
          'Взрывопожароопасность',
          'Повышенная взрывопожароопасность',
          'Пожароопасность',
        ],
        correctAnswers: [
          'Пониженная пожароопасность',
          'Умеренная пожароопасность',
        ],
      },
      {
        code: '63679465',
        text:
          'Какие радиусы закругления рельсовых путей и переводных кривых во вновь вводимых горных выработках устанавливаются для колеи 600 мм и 900 мм?',
        answers: [
          'Не менее 5 м и не менее 8 м соответственно',
          'Не менее 8 м и не менее 12 м соответственно',
          'Не менее 10 м и не менее 15 м соответственно',
          'Не менее 12 м и не менее 20 м соответственно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не менее 12 м и не менее 20 м соответственно'],
      },
      {
        code: '63679466',
        text:
          'В течение какого времени расследуются события, предшествующие динамическим явлениям при первом их проявлении?',
        answers: [
          'В течение 2 недель',
          'В течение 7 суток',
          'В течение 3 суток',
          'В течение суток',
          'Все ответы неверны',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63679467',
        text:
          'При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?',
        answers: [
          'При взрыве метана и (или) угольной пыли',
          'При пожаре в тупиковой выработке',
          'При обрушении в горной выработке',
          'При внезапном выбросе угля (породы)',
        ],
        correctAnswers: ['При обрушении в горной выработке'],
      },
      {
        code: '63679468',
        text:
          'Кто осматривает подвесные устройства проходческого оборудования и все узлы крепления канатов в стволе один раз в месяц?',
        answers: [
          'Механик проходки',
          'Дежурный слесарь',
          'Главный инженер шахты',
          'Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок',
        ],
      },
      {
        code: '63679469',
        text:
          'Кем утверждается документация на изоляцию неиспользуемых горных выработок и выработанных пространств от действующих горных выработок и от земной поверхности?',
        answers: [
          'Комиссией угледобывающей организации',
          'Инспектором Ростехнадзора',
          'Руководителем угледобывающей организации',
          'Главным инженером шахты',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679470',
        text:
          'Какая минимальная толщина стенок должна быть у дегазационных газопроводов из стальных труб?',
        answers: ['1,5 мм', '2 мм', '2,5 мм', '3 мм', '3,5 мм'],
        correctAnswers: ['2,5 мм'],
      },
      {
        code: '63679471',
        text:
          'Какая допускается максимальная предельная общая площадь повреждений защитного покрытия при ревизии электрооборудования, оболочка которого выполнена из алюминиевых сплавов и фрикционная искробезопасность которого обеспечивается защитным покрытием?',
        answers: [
          '10 % от общей площади оболочки оборудования',
          '15 % от общей площади оболочки оборудования',
          '20 % от общей площади оболочки оборудования',
          '25 % от общей площади оболочки оборудования',
          'Все ответы неверны',
        ],
        correctAnswers: ['15 % от общей площади оболочки оборудования'],
      },
      {
        code: '63679472',
        text:
          'Когда ﻿должен быть утвержден план ликвидации аварий (ПЛА) на угольных шахтах?',
        answers: [
          'Не позднее чем за 15 календарных дней до ввода ПЛА в действие',
          'Не позднее чем за 21 календарный день до ввода ПЛА в действие',
          'Не позднее чем за 30 календарных дней до ввода ПЛА в действие',
          'Не позднее чем за 60 календарных дней до ввода ПЛА в действие',
        ],
        correctAnswers: [
          'Не позднее чем за 15 календарных дней до ввода ПЛА в действие',
        ],
      },
      {
        code: '63679473',
        text:
          'При каких условиях по разрешению главного энергетика допускается производить вблизи и на токоведущих частях, находящихся под напряжением, работы, когда исключена возможность их выполнения со снятым напряжением?',
        answers: [
          'При наличии наряда-допуска на производство работ с указанием мероприятий по электробезопасности, в том числе мер, исключающих непосредственное прикосновение к токоведущим частям искроопасных цепей напряжением выше 42 В',
          'При обеспечении непрерывного надзора за работающими',
          'При наличии в удостоверениях лиц, производящих работы, записи о допуске к проведению работ без снятия напряжения согласно квалификационной группе',
          'При всех перечисленных',
        ],
        correctAnswers: ['При всех перечисленных'],
      },
      {
        code: '63679474',
        text: 'Какие из событий предшествуют горному удару?',
        answers: [
          'Только повышенное горное давление на крепь горной выработки',
          'Только удары, трески, толчки в массиве горных пород различной силы и частоты',
          'Только стреляние отслоившихся кусков угля (породы)',
          'Только повышенный выход буровой мелочи и зажатие бурового инструмента',
          'Все перечисленные события',
        ],
        correctAnswers: ['Все перечисленные события'],
      },
      {
        code: '63679475',
        text:
          'На каких участках горных выработок системой аэрогазового контроля (АГК) должен осуществляться контроль содержания оксида углерода?',
        answers: [
          'Только в тупиках вентиляционных выработок, погашаемых вслед за очистными забоями',
          'Только в исходящих струях шахт',
          'Только в исходящих струях смесительных камер',
          'Только в воздухопроводах газоотсасывающих установок',
          'На всех перечисленных участках выработок',
        ],
        correctAnswers: ['На всех перечисленных участках выработок'],
      },
      {
        code: '63679476',
        text:
          'С какой периодичностью технический руководитель (главный инженер) угледобывающей организации утверждает документацию по борьбе с пылью и пылевзрывозащите, содержащую расчет необходимого оборудования и материалов?',
        answers: [
          'Раз в три года',
          'Раз в пять лет',
          'Два раза в год',
          'Ежегодно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63679477',
        text:
          'Эксплуатация каких из перечисленных стальных прядевых канатов шахтных подъемных установок запрещается при наличии на каком-либо участке обрывов проволок, число которых на шаге свивки от общего их числа в канате достигает 5 %?',
        answers: [
          'Уравновешивающих канатов',
          'Отбойных канатов',
          'Канатов грузовых наклонных подъемов с углом наклона до 30°',
          'Канатов для подвески полков и механических грузчиков (грейферов)',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Канатов для подвески полков и механических грузчиков (грейферов)',
        ],
      },
      {
        code: '63679478',
        text:
          'На основании чего производится временная блокировка автоматического отключения электроснабжения при закорачивании вентиляционных струй воздуха в шлюзах?',
        answers: [
          'На основании эксплуатационной документации',
          'Письменного указания руководителя организации',
          'Письменного указания главного инженера шахты',
          'Письменного указания начальника участка аэрологической безопасности (АБ)',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Письменного указания начальника участка аэрологической безопасности (АБ)',
        ],
      },
      {
        code: '63679479',
        text:
          'Кем утверждается план проведения плановой практической проверки аварийных вентиляционных режимов, предусмотренных планом ликвидации аварий?',
        answers: [
          'Главным механиком',
          'Инспектором территориального органа Ростехнадзора',
          'Командиром подразделения аварийно-спасательного формирования, обслуживающего шахту',
          'Главным инженером шахты',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679480',
        text:
          'Через какое время после вскрытия потушенных эндогенных и (или) экзогенных пожарных участков следует проводить ремонтно-восстановительные работы?',
        answers: [
          'Не ранее чем через 10 дней',
          'Не ранее чем через неделю',
          'Не ранее чем через 5 суток',
          'Не ранее чем через 3 суток',
        ],
        correctAnswers: ['Не ранее чем через 3 суток'],
      },
      {
        code: '63679481',
        text:
          'У каких лиц находится по одному экземпляру мероприятий по разгазированию горных выработок? Укажите все правильные ответы.',
        answers: [
          'У начальника проходческого (добычного) участка',
          'У специалиста по промышленной безопасности',
          'У диспетчера шахты',
          'У начальника участка аэрологической безопасности (АБ)',
          'У руководителя шахты',
        ],
        correctAnswers: [
          'У диспетчера шахты',
          'У начальника участка аэрологической безопасности (АБ)',
        ],
      },
      {
        code: '63679482',
        text:
          'В какие сроки производится замена вышедших из строя дегазационных станций (ДС) и дегазационных установок (ДУ), предназначенных для предварительной пластовой дегазации разрабатываемых пластов и выработанных пространств ранее отработанных выемочных столбов?',
        answers: [
          'Не нормируется',
          'В течение недели',
          'В течение 3 суток',
          'В течение 1 суток',
        ],
        correctAnswers: ['В течение 1 суток'],
      },
      {
        code: '63679483',
        text:
          'Кем оформляется наряд-допуск при проведении огневых работ работниками подрядных организаций?',
        answers: [
          'Главным инженером шахты (фабрики)',
          'Лицом, ответственным за безопасное выполнение работ на шахте (фабрике)',
          'ИТР структурного подразделения шахты (фабрики)',
          'ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)',
        ],
        correctAnswers: [
          'ИТР подрядной организации или структурного подразделения, не входящего в состав шахты (фабрики)',
        ],
      },
      {
        code: '63679484',
        text:
          'Максимально допустимая скорость воздуха в каких горных выработках угольных шахт составляет 4 м/с?',
        answers: [
          'В главных откаточных штреках',
          'В вентиляционных скважинах',
          'В очистных и тупиковых горных выработках',
          'В стволах, предназначенных только для спуска и подъема грузов',
          'Все ответы неверны',
        ],
        correctAnswers: ['В очистных и тупиковых горных выработках'],
      },
      {
        code: '63679485',
        text:
          'С какой периодичностью проводится контроль выполнения работ по борьбе с пылью и пылевзрывозащите ИТР структурных подразделений?',
        answers: [
          'Дважды в месяц',
          'Еженедельно',
          'Дважды в неделю',
          'Ежесменно',
          'Все ответы неверны',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63679486',
        text:
          'Каким должно быть давление на форсунках (оросителях) выемочных и проходческих комбайнов на угольных шахтах?',
        answers: [
          'Не менее 0,5 МПа',
          'Не менее 1,0 МПа',
          'Не менее 1,5 МПа',
          'Определяется проектной документацией',
          'Все ответы неверны',
        ],
        correctAnswers: ['Определяется проектной документацией'],
      },
      {
        code: '63679487',
        text:
          'Каков максимально допустимый срок определения качества изоляции изолирующих перемычек (ИП) после их возведения, для ИП, изолирующих неиспользуемые горные выработки и выработанное пространство на пластах угля, склонных к самовозгоранию?',
        answers: [
          '5 календарных дней',
          '10 календарных дней',
          '15 календарных дней',
          '20 календарных дней',
        ],
        correctAnswers: ['10 календарных дней'],
      },
      {
        code: '63679488',
        text:
          'Какова минимально допустимая периодичность проверки исправности реверсивных, переключающих и герметизирующих устройств главным механиком шахты и начальником участка аэрологической безопасности (АБ)?',
        answers: [
          '1 раз в месяц',
          '1 раз в 2 месяца',
          '1 раз в квартал',
          '1 раз в полугодие',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63679489',
        text:
          'В каком случае резервный испытанный шахтный канат перед навеской можно вторично не испытывать?',
        answers: [
          'Необходимо всегда вторично испытывать канат перед навеской',
          'По усмотрению старшего механика шахты',
          'Если число оборванных проволок не превышает 2 % общего числа проволок каната',
          'Если срок хранения его не превышает 12 месяцев',
          'Все ответы неверны',
        ],
        correctAnswers: ['Если срок хранения его не превышает 12 месяцев'],
      },
      {
        code: '63679490',
        text:
          'Кто распорядительным документом устанавливает порядок выдачи заданий (нарядов) на производство работ и порядок допуска работников к выполнению нарядов?',
        answers: [
          'Руководитель угледобывающей организации',
          'Главный инженер шахты',
          'Руководитель службы (участка) аэрологической безопасности (АБ)',
          'Руководитель Ростехнадзора',
        ],
        correctAnswers: ['Руководитель угледобывающей организации'],
      },
      {
        code: '63679491',
        text:
          'Куда передает сведения угледобывающая организация о превышении пороговых значений концентрации метана (выше предаварийных уставок) и срабатывании систем защиты по каналам связи?',
        answers: [
          'В Росздравнадзор',
          'В МЧС России',
          'В Росгидромет',
          'В территориальный орган Ростехнадзора',
          'В Росприроднадзор',
        ],
        correctAnswers: ['В территориальный орган Ростехнадзора'],
      },
      {
        code: '63679492',
        text:
          'Какое максимальное допустимое избыточное давление в кессоне при ведении горноспасательных работ в автономных изолирующих дыхательных аппаратах?',
        answers: [
          '0,4 МПа',
          '0,3 МПа',
          '0,2 МПа',
          '0,1 МПа',
          'Все ответы неверны',
        ],
        correctAnswers: ['0,2 МПа'],
      },
      {
        code: '63679493',
        text:
          'Какова минимально допустимая высота части выработки, предназначенной для передвижения людей, в горизонтальных и наклонных горных выработках?',
        answers: ['1,6 м', '1,8 м', '2,2 м', 'Не нормируется'],
        correctAnswers: ['1,8 м'],
      },
      {
        code: '63679494',
        text:
          'Для каких горно-геологических и горнотехнических условий в Инструкции по расчету и применению анкерной крепи на угольных шахтах не содержится порядок расчета анкерной крепи для горизонтальных и наклонных (до 35 градусов) подземных горных выработок и их сопряжений?',
        answers: [
          'При максимальной расчетной ширине выработки или сопряжения не более 12 м',
          'При отношении глубины проведения горной выработки или сопряжения от дневной поверхности Н, м к расчетному сопротивлению пород и угля в кровле на одноосное сжатие Rc, МПа не более 25',
          'При прочности на одноосное сжатие оставляемой угольной пачки в кровле горной выработки или сопряжения не менее 6 МПа',
          'При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород',
        ],
        correctAnswers: [
          'При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород',
        ],
      },
      {
        code: '63679495',
        text:
          'Какие действия следует предпринять при выявлении несоответствия состава вредных газов в рудничной атмосфере действующих горных выработок требованиям, установленным Правилами безопасности в угольных шахтах?',
        answers: [
          'Люди из этих горных выработок должны выйти в горные выработки с пригодной для дыхания рудничной атмосферой или на поверхность и сообщить об этом горному диспетчеру шахты',
          'Работы должны быть прекращены, персонал должен оставаться на рабочих местах, дожидаясь дальнейших указаний от горного диспетчера шахты',
          'Работы должны быть временно приостановлены, а персонал должен незамедлительно покинуть горную выработку, никого не оповещая',
          'Работы должны быть временно приостановлены, а персонал должен быть оповещен о выявлении несоответствия горным диспетчером шахты',
        ],
        correctAnswers: [
          'Люди из этих горных выработок должны выйти в горные выработки с пригодной для дыхания рудничной атмосферой или на поверхность и сообщить об этом горному диспетчеру шахты',
        ],
      },
      {
        code: '63679496',
        text:
          'Какое требование, предъявляемое к ДС дегазационных систем, указано верно?',
        answers: [
          'При концентрации метана выше предаварийной уставки - 0,5 % объемной доли, метанометр формирует команду на включение принудительного проветривания, звуковой и световой сигнализации',
          'Ежесуточную проверку метанометров и устройств контроля и управления, установленных в ДС, производит группа аэрогазового контроля',
          'ДС дегазационных систем оборудуются стационарными метанометрами, которые устанавливаются в машинном зале в нижней части помещений',
          'Совместно со стационарными метанометрами используются устройства контроля и управления и сигнализирующие устройства с соответствующим видом и уровнем взрывозащиты',
        ],
        correctAnswers: [
          'Совместно со стационарными метанометрами используются устройства контроля и управления и сигнализирующие устройства с соответствующим видом и уровнем взрывозащиты',
        ],
      },
      {
        code: '63679497',
        text:
          'В каком случае допускается выполнение грузовых рейсов техническими устройствами по подвесным монорельсовым дорогам в горных выработках с углами наклона свыше 18°?',
        answers: [
          'Не допускается ни в каком случае',
          'При условии оборудования конвейера ловителями ленты',
          'При условии оборудования конвейера устройствами контроля целостности тросов (для резинотросовых лент)',
          'Только при выключенном конвейере',
          'Все ответы неверны',
        ],
        correctAnswers: ['Только при выключенном конвейере'],
      },
      {
        code: '63679498',
        text:
          'Каким должно быть максимальное расстояние между туманообразующими завесами в горной выработке с исходящей вентиляционной струей при скорости воздуха менее 1 м/с?',
        answers: ['150 м', '120 м', '100 м', '80 м'],
        correctAnswers: ['80 м'],
      },
      {
        code: '63679499',
        text:
          'Какие показатели проверяются при проведении ежеквартальной ревизии электрооборудования с видом взрывозащиты "масляное заполнение оболочки "о"?',
        answers: [
          'Высота слоя масла в оболочке, которая должна соответствовать данным завода-изготовителя',
          'Цвет масла и отсутствие его течи',
          'Температура верхнего слоя масла',
          'Все перечисленные показатели',
        ],
        correctAnswers: ['Все перечисленные показатели'],
      },
      {
        code: '63679500',
        text:
          'Каково минимально допустимое давление жидкости в трубопроводе у лабиринтной завесы?',
        answers: ['0,5 МПа', '0,75 МПа', '1 МПа', '1,5 МПа'],
        correctAnswers: ['1 МПа'],
      },
      {
        code: '63679501',
        text:
          'Какова максимально допустимая концентрация метана в трубопроводах для изолированного отвода метана и в газодренажных горных выработках?',
        answers: [
          '3,5 % по объему',
          '0,75 % по объему',
          '0,5 % по объему',
          '2 % по объему',
        ],
        correctAnswers: ['3,5 % по объему'],
      },
      {
        code: '63679502',
        text:
          'Когда должны отбираться пробы для определения прочностных характеристик затвердевшего материала, используемого при возведении взрывоустойчивой изолирующей перемычки (ИП)?',
        answers: [
          '1 раз - до начала возведения изолирующей перемычки',
          'Не менее 2 раз за время возведения изолирующей перемычки - когда возведено от 40 до 60 % объема ИП, и перед окончанием возведения ИП',
          'Не менее 3 раз за время возведения ИП - в начале возведения, когда возведено от 40 до 60 % объема ИП, и перед окончанием возведения ИП',
        ],
        correctAnswers: [
          'Не менее 3 раз за время возведения ИП - в начале возведения, когда возведено от 40 до 60 % объема ИП, и перед окончанием возведения ИП',
        ],
      },
      {
        code: '63679503',
        text:
          'При каком минимальном времени прекращения проветривания непроветриваемых подготовительных выработок длиной более 6 м в газовых шахтах при отсутствии информации о концентрации метана в них эти выработки относятся к загазированным?',
        answers: ['3 минуты', '5 минут', '15 минут', '30 минут'],
        correctAnswers: ['5 минут'],
      },
      {
        code: '63679504',
        text:
          'Какой способ проветривания должен применяться на негазовых и газовых шахтах при метанообильности шахты не более 10 м﻿3/т, при отработке верхних горизонтов и на шахтах, имеющих аэродинамическую связь горных выработок и выработанного пространства с поверхностью?',
        answers: [
          'Всасывающий способ',
          'Комбинированный способ',
          'Нагнетательный способ',
        ],
        correctAnswers: ['Нагнетательный способ'],
      },
      {
        code: '63679505',
        text:
          'Какое максимальное время срабатывания предохранительного тормоза действующих подъемных машин независимо от типа привода тормоза только при спуске груза (противовеса)?',
        answers: [
          '0,5 с',
          '0,6 с',
          '0,7 с',
          'Не регламентируется',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63679506',
        text:
          'В течение какого времени с момента отработки защитного пласта склонные к горным ударам угольные пласты в пределах защищенных зон считаются неопасными по горным ударам?',
        answers: [
          'В течение 1 года',
          'В течение 3 лет',
          'В течение 5 лет',
          'В течение 10 лет',
          'Все ответы неверны',
        ],
        correctAnswers: ['В течение 5 лет'],
      },
      {
        code: '63679507',
        text:
          'В каких местах не проводится контроль концентрации метана групповыми переносными приборами при наличии у рабочих, работающих в указанных местах, индивидуальных сигнализаторов метана, совмещенных с головным светильником и индивидуальных приборов контроля концентрации метана? Укажите все правильные ответы.',
        answers: [
          'У буровых станков при бурении скважин',
          'В призабойных пространствах подготовительных выработок',
          'В выработках с исходящей струей воздуха',
          'На электровозах',
        ],
        correctAnswers: [
          'В выработках с исходящей струей воздуха',
          'На электровозах',
        ],
      },
      {
        code: '63679508',
        text:
          'Какое из перечисленных требований Инструкции по электроснабжению угольных шахт указано неверно?',
        answers: [
          'Электроснабжение участка осуществляется от передвижных трансформаторных подстанций, присоединяемых к распределительной сети с помощью комплектных распределительных устройств',
          'Допускается осуществлять электроснабжение участка осуществляется от стационарных участковых подстанций',
          'К одному комплектному распределительному устройству подключается несколько передвижных подстанций или трансформаторов, питающих электроэнергией технологически связанные машины участка',
          'Электроснабжение участков с поверхности через скважины не допускается',
        ],
        correctAnswers: [
          'Электроснабжение участков с поверхности через скважины не допускается',
        ],
      },
      {
        code: '63679509',
        text:
          'Какое из перечисленных требований к шахтной подъемной установке указано неверно? Укажите все правильные ответы.',
        answers: [
          'На верхних горизонтах запрещается работа в людском и грузовом режимах без посадочных кулаков',
          'Допускается переход людей через подъемные отделения ствола',
          'Допускается отсутствие стационарной аварийно-ремонтной подъемной установки при наличии на вооружении отряда аварийно-спасательных частей, обслуживающего шахту, передвижной подъемной установки',
          'При наличии в одном стволе двух подъемных установок или одной подъемной установки и лестничного отделения дополнительная аварийно-ремонтная установка может отсутствовать',
          'При проходке стволов глубиной до 100 м лебедки для подвески аварийно-спасательных лестниц могут иметь ручной привод и быть оборудованы тормозами и храповичным остановом',
        ],
        correctAnswers: [
          'На верхних горизонтах запрещается работа в людском и грузовом режимах без посадочных кулаков',
          'Допускается переход людей через подъемные отделения ствола',
        ],
      },
      {
        code: '63679510',
        text:
          'Кто из перечисленных специалистов представляет заключение о противоаварийной готовности шахты?',
        answers: [
          'Руководитель шахты',
          'Технический руководитель (главный инженер) шахты',
          'Работник профессиональных аварийно-спасательных служб (формирований) ПАСС(Ф), назначенный руководителем ПАСС(Ф)',
          'Представитель Ростехнадзора',
        ],
        correctAnswers: [
          'Работник профессиональных аварийно-спасательных служб (формирований) ПАСС(Ф), назначенный руководителем ПАСС(Ф)',
        ],
      },
      {
        code: '63679511',
        text:
          'Какое из перечисленных требований по ведению горных работ в угольных шахтах является верным?',
        answers: [
          'Горные работы должны выполнять не менее чем 3 работника',
          'Стаж работы по профессии одного из работников, выполняющего горные работы, должен быть не менее 6 месяцев',
          'Запрещается ведение горных работ без утвержденной руководителем шахты документации по ведению горных работ',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63679512',
        text:
          'Какие пласты относятся к пластам, опасным по взрывам угольной пыли?',
        answers: [
          'Пласты с выходом летучих веществ угля 15% и более, а также пласты угля с меньшим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость',
          'Пласты с выходом летучих веществ угля 12 % и более, а также пласты угля с большим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость',
          'Пласты с выходом летучих веществ угля 10 % и более, а также пласты угля с большим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость',
          'Пласты с выходом летучих веществ угля 8 % и более, а также пласты угля с меньшим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость',
        ],
        correctAnswers: [
          'Пласты с выходом летучих веществ угля 15% и более, а также пласты угля с меньшим выходом летучих веществ, взрывчатость пыли которых установлена при проведении лабораторных исследований и испытаний угольной пыли на взрывчатость',
        ],
      },
      {
        code: '63679513',
        text:
          'Каково минимально допустимое расстояние от распредпункта до забоя тупиковой выработки?',
        answers: ['8 м', '10 м', '15 м', '20 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63679514',
        text:
          'В каких горных выработках угольных шахт запрещается проводить ремонтные работы одновременно более чем в одном месте?',
        answers: [
          'С углом наклона более 10°',
          'С углом наклона более 12°',
          'С углом наклона более 15°',
          'С углом наклона более 18°',
          'Все ответы неверны',
        ],
        correctAnswers: ['С углом наклона более 18°'],
      },
      {
        code: '63679515',
        text:
          'Кем утверждаются меры, обеспечивающие безопасность ведения горных работ, которые обязательны при очистных работах до первичной посадки основной кровли?',
        answers: [
          'Главным инженером шахты',
          'Представителем территориального органа Ростехнадзора',
          'Начальником участка или его заместителем',
          'Представителем специализированной аварийно-спасательной части',
        ],
        correctAnswers: ['Главным инженером шахты'],
      },
      {
        code: '63679516',
        text:
          'При какой минимальной концентрации сернистого газа в рудничной атмосфере в горных выработках горноспасательные работы приостанавливаются, и организуется вывод из зоны аварии людей, задействованных в данных работах?',
        answers: ['0,3 %', '0,5 %', '0,75 %', '1 %', '2 %'],
        correctAnswers: ['0,5 %'],
      },
      {
        code: '63679517',
        text:
          'Какова периодичность осмотра проходческих лебедок механиком структурного подразделения?',
        answers: [
          'Ежесменно',
          'Перед каждой спуско-подъемной операцией',
          '1 раз в сутки',
          '1 раз в неделю',
          '1 раз в месяц',
        ],
        correctAnswers: ['1 раз в неделю'],
      },
      {
        code: '63679518',
        text:
          'При какой максимальной концентрации метана на выходе из смесительной камеры обеспечивается безопасная эксплуатация выемочных участков с использованием схем проветривания с изолированным отводом метана из выработанного пространства?',
        answers: ['5 %', '3,5 %', '2 %', '1,5 %'],
        correctAnswers: ['2 %'],
      },
      {
        code: '63679519',
        text:
          'В каком случае допускается эксплуатация стрелочных переводов рельсовых путей?',
        answers: [
          'При разъединенных стрелочных тягах',
          'При закрытых канавках для тяг приводов стрелочных переводов',
          'При замыкании стрелок с зазором более 4 мм между прижатым остряком (пером) и рамным рельсом',
          'При изогнутых в продольном и поперечном направлениях остряках (перьях)',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'При закрытых канавках для тяг приводов стрелочных переводов',
        ],
      },
      {
        code: '63679520',
        text:
          'В наклонной горной выработке с каким углом наклона пожар тушится активным способом дистанционно?',
        answers: [
          'В любом наклонной горной выработке',
          'С углом наклона более 10°',
          'С углом наклона более 15°',
          'С углом наклона более 20°',
        ],
        correctAnswers: ['С углом наклона более 20°'],
      },
      {
        code: '63679521',
        text:
          'При какой номинальной скорости ленты допускается перевозка людей ленточными конвейерами?',
        answers: [
          'Не более 1,5 м/с',
          'Не более 2 м/с',
          'Не более 2,5 м/с',
          'Не более 3,15 м/с',
        ],
        correctAnswers: ['Не более 3,15 м/с'],
      },
      {
        code: '63679522',
        text:
          'Какова минимальная продолжительность ﻿аварийного загазирования горных выработок для расследования причин его возникновения в соответствии с порядком проведения технического расследования причин аварий, инцидентов и случаев утраты взрывчатых материалов промышленного назначения на объектах, поднадзорных Ростехнадзору?',
        answers: ['2 часа', '3,5 часа', '5 часов', '6 часов'],
        correctAnswers: ['6 часов'],
      },
      {
        code: '63679523',
        text:
          'Какова периодичность визуального контроля работоспособности анкерной крепи вне зоны влияния очистных работ?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 2 месяца',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в полгода',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63679524',
        text:
          'Какова максимально допустимая температура сжатого воздуха для маслонаполненного компрессора сжатия в подземных передвижных компрессорных установках?',
        answers: ['100 °С', '125 °С', '157 °С', '182 °С'],
        correctAnswers: ['125 °С'],
      },
      {
        code: '63679525',
        text:
          'В скольких экземплярах оформляется наряд-допуск на проведение огневых работ и на протяжении какого срока он подлежит хранению на шахте (фабрике)?',
        answers: [
          'Наряд-допуск оформляется одном экземпляре и хранится в течение шести месяцев',
          'Наряд-допуск оформляется в двух экземплярах и хранится в течение календарного года',
          'Наряд-допуск оформляется втрех экземплярах и хранится в течение двух лет',
          'Наряд-допуск оформляется в одном экземпляре и хранится в течение месяца',
        ],
        correctAnswers: [
          'Наряд-допуск оформляется в двух экземплярах и хранится в течение календарного года',
        ],
      },
      {
        code: '63679526',
        text:
          'Что из перечисленного не должна содержать документация по борьбе с пылью и пылевзрывозащите?',
        answers: [
          'Перечень и расчет параметров мероприятий по борьбе с пылью при всех процессах, сопровождающихся пылевыделением',
          'Обоснование выбора противопылевых мероприятий для подготовительных горных выработок и выемочных участков',
          'Определение возможности и эффективности проведения работ по увлажнению угольного пласта',
          'Параметры и режим работы технических устройств, используемых для борьбы с пылью и пылевзрывозащиты',
        ],
        correctAnswers: [
          'Параметры и режим работы технических устройств, используемых для борьбы с пылью и пылевзрывозащиты',
        ],
      },
      {
        code: '63679527',
        text:
          'Какие показатели проверяются при проведении ежеквартальной ревизии электрооборудования с защитой вида "е"?',
        answers: [
          'Разделение между искробезопасными и искроопасными цепями в соединительных коробках и во внутреннем монтаже',
          'Целостность заливки эпоксидным компаундом доступных блоков искрозащиты',
          'Заземление кабельных экранов',
          'Состояние вентиляторов электродвигателей',
          'Все ответы неверны',
        ],
        correctAnswers: ['Состояние вентиляторов электродвигателей'],
      },
      {
        code: '63679528',
        text:
          'Каково минимально допустимое расстояние между вагонетками при ручной подкатке на путях с уклоном до 5 %?',
        answers: ['10 м', '7 м', '6 м', '5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63679529',
        text:
          'Какие исходные сведения должен представить главный инженер шахты техническому руководителю (главному инженеру) угледобывающей организации для установления категории шахты по метану и (или) диоксиду углерода?',
        answers: [
          'Расчеты газообильностей выемочных участков, крыльев, панелей, блоков, горизонтов, шахтопластов и шахты',
          'Сведения о категории шахты по метану и (или) диоксиду углерода и ее абсолютная, и относительная газообильности в предыдущем году, опасность шахты по взрывчатости угольной пыли',
          'Сведения о суфлярных выделениях метана в выработках шахты, в том числе за предшествующий год, сведения о внезапных выбросах угля (породы) и газа',
          'Все перечисленные данные',
        ],
        correctAnswers: ['Все перечисленные данные'],
      },
      {
        code: '63679530',
        text:
          'В соответствии с какой документацией проводится объединение угольных шахт с независимым проветриванием в одну вентиляционную систему?',
        answers: [
          'В соответствии с технологическим регламентом',
          'В соответствии с Правилами безопасности в угольных шахтах',
          'В соответствии с предписанием Ростехнадзора',
          'В соответствии с проектной документацией',
          'Все ответы неверны',
        ],
        correctAnswers: ['В соответствии с проектной документацией'],
      },
      {
        code: '63679531',
        text:
          'С какой периодичностью работниками подразделений ПАСС(Ф)), обслуживающих шахту, или работниками испытательных лабораторий в присутствии ИТР участка аэрологической безопасности выполняется проверка состава рудничной атмосферы?',
        answers: [
          '1раз в неделю',
          '1 раз в квартал',
          '1 раз в месяц',
          'Ежесменно',
        ],
        correctAnswers: ['1 раз в квартал'],
      },
      {
        code: '63679532',
        text:
          'Какое требование к пружинным шайбам, крепежным гайкам и болтам, другим крепежным элементам рудничного взрывобезопасного электрооборудования указано неверно?',
        answers: [
          'Запрещается эксплуатация электрооборудования при отсутствии шайб, болтов, крепежных элементов или неполной затяжке хотя бы одного болта или другого крепежного элемента',
          'Головки болтов выступают из охранных колец в соответствии с эксплуатационной документацией изготовителя',
          'Фланцы крышки и корпуса взрывонепроницаемой оболочки плотно прилегают по всему периметру',
          'Фланцы крышки и корпуса взрывонепроницаемой оболочки обеспечивают надежное уплотнение прокладками',
        ],
        correctAnswers: [
          'Головки болтов выступают из охранных колец в соответствии с эксплуатационной документацией изготовителя',
        ],
      },
      {
        code: '63679533',
        text:
          'В какие периоды должны проводиться температурные съемки действующих негорящих породных отвалов?',
        answers: [
          '1 раз в год: сентябрь',
          '2 раза в год: май и сентябрь',
          '3 раза в год: май, июль и сентябрь',
          '3 раза в год: май, июнь и сентябрь',
        ],
        correctAnswers: ['3 раза в год: май, июль и сентябрь'],
      },
      {
        code: '63679534',
        text:
          'Какое из перечисленных требований, относящихся к борьбе с пылью в подготовительных выработках, указано верно?',
        answers: [
          'На шахтах, опасных по газу и разрабатывающих пласты, опасные по взрывам пыли, при взрывных работах в подготовительных горных выработках за 20 - 30 минут перед взрыванием зарядов взрывчатых веществ проводится орошение (обмывка) забоя и выработки на расстоянии не менее 20 м от взрываемых зарядов',
          'Обеспыливающие завесы могут не перекрывать все сечение выработки',
          'Удельный расход воды или раствора смачивателя должен составлять 3 л/м² поверхности выработки',
          'Во время взрывных работ применяются водяные (водовоздушные) завесы, устанавливаемые в 10 м от забоя',
        ],
        correctAnswers: [
          'На шахтах, опасных по газу и разрабатывающих пласты, опасные по взрывам пыли, при взрывных работах в подготовительных горных выработках за 20 - 30 минут перед взрыванием зарядов взрывчатых веществ проводится орошение (обмывка) забоя и выработки на расстоянии не менее 20 м от взрываемых зарядов',
        ],
      },
      {
        code: '63679535',
        text:
          'Кем выполняются прогноз динамических явлений и контроль эффективности применения мер по предотвращению динамических явлений на шахтах, разрабатывающих угольные пласты, склонные к динамическим явлениям?',
        answers: [
          'Техническим руководителем (главным инженером) угледобывающей организации',
          'Профессионально аварийно-спасательной службой (формированием)',
          'Работниками специализированной организации',
          'Инженерно-техническими работниками (ИТР) службы прогноза динамических явлений (ДЯ)',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Инженерно-техническими работниками (ИТР) службы прогноза динамических явлений (ДЯ)',
        ],
      },
      {
        code: '63679536',
        text:
          'Кем осуществляется обслуживание устройства долговременного хранения данных от основных измерительных каналов? Укажите все правильные ответы.',
        answers: [
          'Поставщиком оборудования',
          'Сервисными центрами',
          'Территориальным органом Ростехнадзора',
          'Эксплуатирующей организацией',
          'Проектной организацией',
        ],
        correctAnswers: ['Поставщиком оборудования', 'Сервисными центрами'],
      },
      {
        code: '63679537',
        text:
          'С какой периодичностью угледобывающие организации определяют склонность отрабатываемых пластов к самовозгоранию?',
        answers: [
          'Периодичность проверки утверждает главный инженер шахты',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже 1 раза в 3 года'],
      },
    ],
    63677: [
      {
        code: '63677000',
        text:
          'В какой срок планы и схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 15 дней до назначенной в графике даты рассмотрения планов и схем развития горных работ',
          'За 5 дней до назначенной в графике даты рассмотрения планов и схем развития горных работ',
          'В течение 15 дней с назначенной в графике даты рассмотрения планов и схем развития горных работ',
          'В назначенный в графике день рассмотрения планов и схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной в графике даты рассмотрения планов и схем развития горных работ',
        ],
      },
      {
        code: '63677001',
        text:
          'Кто осуществляет руководство работами по локализации и ликвидации последствий аварии?',
        answers: [
          'Технический руководитель (главный инженер) ОПО',
          'Руководитель горноспасательных работ (РГСР) - должностное лицо ПАСС(Ф), обслуживающей ОПО, назначенное распорядительным документом руководителя ПАСС(Ф)',
          'Руководитель угольного разреза',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Технический руководитель (главный инженер) ОПО'],
      },
      {
        code: '63677002',
        text: 'Какая документация должна находиться на командном пункте?',
        answers: [
          'Только оперативные журналы ОПО и ПАСС(Ф)',
          'Только план ликвидации аварий (ПЛА) и оперативные планы',
          'Только текстовая и графическая документация, разрабатываемая в период локализации и ликвидации последствий аварии',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63677003',
        text:
          'В каких случаях допускается применение сетей напряжением до 1 кВ с глухозаземленной нейтралью?',
        answers: [
          'Для питания стационарных потребителей, расположенных за пределами открытых горных работ',
          'Для питающихся от отдельных трансформаторов установок освещения стационарных перегрузочных пунктов и отвалов',
          'Для въездных (выездных) траншей',
          'Для осветительных установок и сетей СЦБ',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63677004',
        text:
          'С какой периодичностью должна производиться проверка реле утечки тока на время срабатывания для реле утечки внутри экскаватора?',
        answers: [
          'Не реже                       1 раза в 10 дней',
          'Не реже       1 раза в 2 недели',
          'Не реже       1 раза в месяц',
          'Не реже       1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже                       1 раза в 10 дней'],
      },
      {
        code: '63677005',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Руководителем органа государственного горного надзора',
          'Руководителем организации - пользователя недр',
          'Руководителем органа исполнительной власти субъекта Российской Федерации',
          'Техническим руководителем организации - пользователя недр',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63677006',
        text:
          'Какой минимальной ширины должен быть свободный проход с обеих сторон транспортного средства при его движении через временные въезды в траншеи?',
        answers: ['0,8 м', '1 м', '1,5 м', '2,0'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63677007',
        text:
          'Что не соответствует требованиям к состоянию горнотранспортных и строительно-дорожных машин в нерабочее время?',
        answers: [
          'Машины должны быть выведены из забоя в безопасное место',
          'Ковш должен быть опущен на грунт',
          'При отсутствии экипажа кабина должна быть заперта',
          'С питающего кабеля напряжение не снимается',
        ],
        correctAnswers: ['С питающего кабеля напряжение не снимается'],
      },
      {
        code: '63677008',
        text:
          'Куда должен быть направлен план реализации мероприятий, обосновывающих и обеспечивающих безопасную эксплуатацию угольного разреза в соответствии с требованиями    Правил безопасности при разработке угольных месторождений открытым способом, до их реализации?',
        answers: [
          'В органы местного самоуправления, на территории которого располагается разрез',
          'В территориальный орган Ростехнадзора',
          'В профессиональные аварийно-спасательные службы и (или) профессиональные аварийно-спасательные формирования (ПАСС(Ф)',
          'Руководителю угольного разреза',
        ],
        correctAnswers: ['В территориальный орган Ростехнадзора'],
      },
      {
        code: '63677009',
        text:
          'Какая организация должна согласовать проект на выполнение ремонтных работ, связанных с изменением несущих металлоконструкций основного технологического оборудования?',
        answers: [
          'Проектная организация',
          'Завод-изготовитель',
          'Территориальный орган Ростехнадзора',
          'Специализированная организация по диагностике оборудования',
        ],
        correctAnswers: ['Завод-изготовитель'],
      },
      {
        code: '63677010',
        text:
          'На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте выше 1200 мм?',
        answers: [
          'На расстоянии не менее 2,5 м',
          'На расстоянии не менее 2,25 м',
          'На расстоянии не менее 2,0 м',
          'На расстоянии не менее 1,75 м',
        ],
        correctAnswers: ['На расстоянии не менее 2,5 м'],
      },
      {
        code: '63677011',
        text:
          'В каком случае нарушены требования по прокладке и переноске (перетаскиванию) гибкого кабеля?',
        answers: [
          'Гибкие кабели, питающие передвижные машины, необходимо прокладывать так, чтобы исключалась возможность их повреждения, примерзания, завала породой, наезда на них транспортных средств и механизмов',
          'На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы',
          'Переноска (перетаскивание) гибкого кабеля должна производиться с помощью механизмов с применением приспособлений, обеспечивающих ограничение радиуса изгиба кабеля, или вручную',
          'При переноске (подноске) экскаваторного кабеля, находящегося под напряжением, обслуживающий персонал обязан пользоваться диэлектрическими перчатками или специальными устройствами с изолирующими рукоятками',
        ],
        correctAnswers: [
          'На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы',
        ],
      },
      {
        code: '63677012',
        text:
          'Каковы допустимые масса проб угля и размер кусков угля при отборе проб угля из горных выработок шахт и на разрезах?',
        answers: [
          'Пробы отбираются массой не менее 4 кг, размер кусков угля в пробе 30 - 50 мм',
          'Пробы отбираются массой не более 4 кг, размер кусков угля в пробе 80 - 100 мм',
          'Пробы отбираются массой не менее 2 кг, размер кусков угля в пробе 30 - 50 мм',
          'Пробы отбираются массой не более 2 кг, размер кусков угля в пробе 80 - 100 мм',
        ],
        correctAnswers: [
          'Пробы отбираются массой не менее 4 кг, размер кусков угля в пробе 30 - 50 мм',
        ],
      },
      {
        code: '63677013',
        text:
          'Чем должно оформляться изменение теплового состояния отвала, перевод в категорию горящих или негорящих?',
        answers: [
          'Записью в журнале регистрации температуры породного отвала',
          'Записью в паспорте отвала',
          'Актом',
          'Протоколом',
        ],
        correctAnswers: ['Актом'],
      },
      {
        code: '63677014',
        text:
          'Какое из перечисленных действий должен выполнять руководитель работ по ликвидации аварии?',
        answers: [
          'Организацию ведения оперативного журнала профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Организацию ведения горноспасательных работ',
          'Установление режима работы и отдыха работников профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований при ведении горноспасательных работ',
          'Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания атмосфере',
        ],
        correctAnswers: [
          'Определение общего количества и местонахождения работников, застигнутых аварией, в том числе оказавшихся в непригодной для дыхания атмосфере',
        ],
      },
      {
        code: '63677015',
        text:
          'Каким должно быть минимальное расстояние по горизонтали между экскаваторами, расположенными на одном горизонте?',
        answers: [
          'Равным сумме их наибольших радиусов действия',
          'Равным полуторной сумме их наибольших радиусов действия',
          'Равным двойной сумме их наибольших радиусов действия',
          'Равным утроенной сумме их наибольших радиусов действия',
        ],
        correctAnswers: ['Равным сумме их наибольших радиусов действия'],
      },
      {
        code: '63677016',
        text:
          'Какие разделы не включает в себя Положение о системе управления промышленной безопасностью и охраной труда (СУПБиОТ)?',
        answers: [
          '"Система управления промышленной безопасностью"',
          '"Система управления охраной труда"',
          '"Проведение расследований причин инцидентов"',
        ],
        correctAnswers: ['"Проведение расследований причин инцидентов"'],
      },
      {
        code: '63677017',
        text:
          'В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?',
        answers: [
          'Не мельче 1 : 15000',
          'Не мельче 1 : 25000',
          'Не мельче 1 : 10000',
          'Не мельче 1 : 35000',
        ],
        correctAnswers: ['Не мельче 1 : 10000'],
      },
      {
        code: '63677018',
        text:
          'Кто устанавливает сроки приведения действующего угольного разреза в соответствие с требованиями    Правил безопасности при разработке угольных месторождений открытым способом? Выберите все варианты ответов.',
        answers: [
          'Представитель органа местного самоуправления, на территории которого располагается разрез',
          'Представитель территориального органа Ростехнадзора',
          'Технический руководитель (главный инженер) угольного разреза',
          'Руководитель угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
          'Руководитель угольного разреза',
        ],
      },
      {
        code: '63677019',
        text:
          'Какие их перечисленных металлических частей электроустановок, нормально не находящиеся под напряжением, но которые могут в случае повреждения изоляции оказаться под ним, не подлежат заземлению?',
        answers: [
          'Корпуса электрических экскаваторов, буровых станков, насосов, конвейеров и других машин, станины и кожухи электрических машин, трансформаторов, выключателей',
          'Барьеры, металлические решетчатые и сплошные ограждения частей, находящихся под напряжением, металлические части, могущие оказаться под напряжением',
          'Металлические корпуса кабельных муфт, металлические оболочки кабелей и проводов, стальные трубы электропроводок',
          'Оборудование, установленное на заземленных металлических конструкциях, при этом на опорных поверхностях должны быть предусмотрены зачищенные и незакрашенные места для обеспечения электрического контакта',
        ],
        correctAnswers: [
          'Оборудование, установленное на заземленных металлических конструкциях, при этом на опорных поверхностях должны быть предусмотрены зачищенные и незакрашенные места для обеспечения электрического контакта',
        ],
      },
      {
        code: '63677020',
        text:
          'С какой периодичностью должна проверяться исправность и комплектность машин главным механиком, главным энергетиком разреза или другим назначаемым лицом?',
        answers: [
          'Ежемесячно',
          '1 раз в 2 месяца',
          '1 раз в квартал',
          '1 раз в полугодие',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63677021',
        text:
          'При какой температуре запрещается ведение горноспасательных работ в горных выработках с непригодной для дыхания рудничной атмосферой?',
        answers: [
          'При температуре ниже -20 °C',
          'При температуре ниже -10 °C',
          'При температуре ниже -25 °C',
          'При температуре ниже -15 °C',
        ],
        correctAnswers: ['При температуре ниже -20 °C'],
      },
      {
        code: '63677022',
        text:
          'На каком расстоянии должны отбираться пробы угля из керна в угольных прослойках и пропластках углистого сланца мощностью более 0,2 м, расположенных в кровле пласта?',
        answers: [
          'На расстоянии однократной мощности пласта',
          'На расстоянии двукратной мощности пласта',
          'На расстоянии трехкратной мощности пласта',
          'На расстоянии четырехкратной мощности пласта',
        ],
        correctAnswers: ['На расстоянии трехкратной мощности пласта'],
      },
      {
        code: '63677023',
        text:
          'Каким должно быть расстояние от оси крайнего железнодорожного пути до внутреннего края опор контактной сети на перегонах и железнодорожных станциях?',
        answers: [
          'Не менее 3100 мм',
          'Не менее 3000 мм',
          'Не менее 2900 мм',
          'Не менее 2800 мм',
        ],
        correctAnswers: ['Не менее 3100 мм'],
      },
      {
        code: '63677024',
        text:
          'Кто из перечисленных лиц обеспечивает подачу сжатого воздуха или воды к месту аварии в случаях, предусмотренных планом ликвидации аварий?',
        answers: [
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
          'Начальник службы (участка), обеспечивающей аэрологическую безопасность подземных горных выработок',
          'Начальник участка, на котором произошла авария',
          'Специалист ОПО, ответственный за учет и выдачу индивидуальных головных светильников, самоспасателей, газоанализаторов',
        ],
        correctAnswers: [
          'Руководитель энергомеханической службы опасного производственного объекта (ОПО)',
        ],
      },
      {
        code: '63677025',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'Со специализированными профессиональными аварийно-спасательными формированиями',
          'С территориальными органами Ростехнадзора',
          'С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез',
          'Со сторонними организациями, расположенными на территории горного отвода',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63677026',
        text:
          'Какое из требований к ведению горных работ по проведению траншей, разработке уступов и отсыпке отвалов указано неверно?',
        answers: [
          'Формирование временно нерабочих бортов угольного разреза и возобновление горных работ на них должно производиться по проектам производства работ, предусматривающим необходимые меры безопасности',
          'Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м',
          'Расстояние между смежными бермами при погашении уступов и постановке их в предельное положение, ширина, конструкция и порядок обслуживания предохранительных берм должны определяться проектом с учетом обеспечения устойчивости конструкции борта угольного разреза, безопасной механизированной их очистки',
          'В процессе эксплуатации параметры уступов и предохранительных берм должны при необходимости уточняться по результатам исследований физико-механических свойств горных пород и должны быть не менее установленных проектом',
        ],
        correctAnswers: [
          'Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м',
        ],
      },
      {
        code: '63677027',
        text:
          'Кто из перечисленных лиц назначает комиссию по приемке в эксплуатацию строительно-дорожных машин, горнотранспортного и технологического оборудования после монтажа?',
        answers: [
          'Представитель исполнительной власти муниципального образования, на территории которого находится разрез',
          'Руководитель территориального органа Ростехнадзора',
          'Представитель государственной инспекции труда',
          'Командир специализированного профессионального аварийно-спасательного формирования',
          'Технический руководитель (главный инженер) угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63677028',
        text:
          'В каком приоритетном порядке предварительно определяется вид происшедшей аварии при отсутствии информации, позволяющей достоверно определить ее вид, в подземных горных выработках?',
        answers: [
          'Внезапный выброс, взрыв, пожар, горный удар, обрушение',
          'Горный удар, обрушение, взрыв, внезапный выброс, пожар',
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
          'Пожар, взрыв, внезапный выброс, горный удар, обрушение',
        ],
        correctAnswers: [
          'Взрыв, внезапный выброс, пожар, горный удар, обрушение',
        ],
      },
      {
        code: '63677029',
        text:
          'Что из перечисленного влияет на результаты расчетов, устанавливающих проектные высоту отвала и отвальных ярусов, углы откоса и призмы возможного обрушения, скорость продвижения фронта отвальных работ?',
        answers: [
          'Только физико-механические свойства пород отвала и его основания',
          'Только способы отвалообразования',
          'Только рельеф местности и несущая способность нагруженных отвалов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63677030',
        text:
          'С какой периодичностью должно осматриваться каждое рабочее место начальником участка или его заместителем?',
        answers: [
          'В течение смены',
          'В течение суток',
          '1 раз в 2 дня',
          '1 раз в 2 недели',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63677031',
        text:
          'С какой периодичностью следует проверять тормоза приводов конвейеров?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 2 месяца',
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в полугодие',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63677032',
        text:
          'Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?',
        answers: [
          'Направления развития горных работ',
          'Условия, технические и технологические решения при эксплуатации объектов ведения горных работ',
          'Объемы добычи и переработки полезных ископаемых',
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
        correctAnswers: [
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
      },
      {
        code: '63677033',
        text:
          'В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?',
        answers: [
          'До 1 августа текущего года (года, предшествующего планируемому)',
          'До 15 августа текущего года (года, предшествующего планируемому)',
          'До 1 сентября текущего года (года, предшествующего планируемому)',
          'До 15 сентября текущего года (года, предшествующего планируемому)',
        ],
        correctAnswers: [
          'До 1 сентября текущего года (года, предшествующего планируемому)',
        ],
      },
      {
        code: '63677034',
        text:
          'Что из перечисленного не соответствует требованиям безопасности к расположению гибкого кабеля, питающего передвижные механизмы?',
        answers: [
          'Гибкие кабели должны подвешиваться с провисом',
          'Высота подвески кабеля должна исключать возможность его повреждения',
          'Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м',
          'Расположение кабеля на почве должно исключать возможность его повреждения движущейся машиной',
        ],
        correctAnswers: [
          'Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м',
        ],
      },
      {
        code: '63677035',
        text:
          'Когда должны проводиться температурные съемки на недействующих горящих породных отвалах?',
        answers: [
          '1 раз в год: в сентябре',
          '2 раза в год: в июне и сентябре',
          '1 раз в год: в июле',
          '3 раза в год: в мае, июле и сентябре',
        ],
        correctAnswers: ['1 раз в год: в сентябре'],
      },
      {
        code: '63677036',
        text:
          'На какую величину меньше высоты подвески контактного провода должна быть минимальная высота габаритных ворот около переездов с обеих сторон на всех пересечениях электрифицированных путей с автомобильными дорогами и пунктами, где проводятся погрузочно-разгрузочные работы?',
        answers: ['На 0,3 м', 'На 0,4 м', 'На 0,5 м', 'На 0,6 м'],
        correctAnswers: ['На 0,5 м'],
      },
      {
        code: '63677037',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана или схемы развития горных работ?',
        answers: [
          'Отсутствие геологического и маркшейдерского обеспечения горных работ',
          'Выявление недостоверных сведений в представленных документах',
          'Наличие в представленной документации сведений о планировании производства работ подрядными организациями',
          'Отсутствие обоснования соблюдения условий безопасного недропользования',
        ],
        correctAnswers: [
          'Наличие в представленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63677038',
        text:
          'Каков минимальный период проверки и контрольной наладки (испытания) основной селективной защиты от замыкания на землю на подстанциях, от которых получают питание передвижные карьерные электроустановки?',
        answers: ['3 месяца', '6 месяцев', '12 месяцев', 'Не нормируется'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63677039',
        text:
          'В какие периоды должны проводиться температурные съемки на действующих горящих породных отвалах?',
        answers: [
          '3 раза в год: в мае, июле и сентябре',
          '2 раза в год: в мае и сентябре',
          '1 раз в год: в сентябре',
          '1 раз в год: в августе',
        ],
        correctAnswers: ['2 раза в год: в мае и сентябре'],
      },
      {
        code: '63677040',
        text:
          'Какие меры принимаются для снижения пылеобразования при положительной температуре воздуха? Укажите все правильные ответы',
        answers: [
          'Отбитая при взрыве горная масса смачивается водой (растворами смачивающих веществ)',
          'На автомобильных дорогах проводится полив дорог водой с применением при необходимости связующих добавок',
          'При сдувании пыли с обнаженных поверхностей в разрезе и на отвале производятся обработка обнаженных поверхностей связующими растворами и их озеленение',
          'Все перечисленные меры',
        ],
        correctAnswers: [
          'На автомобильных дорогах проводится полив дорог водой с применением при необходимости связующих добавок',
          'При сдувании пыли с обнаженных поверхностей в разрезе и на отвале производятся обработка обнаженных поверхностей связующими растворами и их озеленение',
        ],
      },
      {
        code: '63677041',
        text:
          'Что из перечисленного является обязательными условиями принятия решения о консервации и ликвидации разреза? Выберите два правильных варианта ответов.',
        answers: [
          'Наличие проектной документации на консервацию и ликвидацию разреза',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Наличие согласованного и утвержденного плана работ',
          'Наличие договоров с подрядными организациями',
        ],
        correctAnswers: [
          'Наличие проектной документации на консервацию и ликвидацию разреза',
          'Наличие положительного заключения экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63677042',
        text:
          'Что из перечисленного должно быть обозначено предупредительными знаками, ограждениями или предохранительными валами?',
        answers: [
          'Только водоемы и затопленные выработки',
          'Только очаги самонагревания породных отвалов',
          'Только участки высокого борта и горные выработки с признаками деформации',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63677043',
        text:
          'Из какого минимального количества человек должно состоять горноспасательное отделение или отделение вспомогательной горноспасательной команды при ведении горноспасательных работ в непригодной для дыхания рудничной атмосфере при работе на открытых горных выработках?',
        answers: [
          'Из 5 человек',
          'Из 2 человек',
          'Из 3 человек',
          'Из 6 человек',
          'Все ответы неверны',
        ],
        correctAnswers: ['Из 3 человек'],
      },
      {
        code: '63677044',
        text:
          'По каким данным могут быть скорректированы в процессе эксплуатации предельные углы откосов уступов и бортов угольного разреза (углы устойчивого борта), в том числе временно консервируемых участков бортов? Выберите два правильных варианта ответов.',
        answers: [
          'По данным геолого-маркшейдерской службы угольного разреза',
          'По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвалам',
          'По требованиям дополнений к технической документации ведения горных работ на угольных разрезах, если это не выполнено в проекте на отработку месторождения',
          'По данным планов горных работ масштабов 1 : 5000 или 1 : 2000',
        ],
        correctAnswers: [
          'По данным геолого-маркшейдерской службы угольного разреза',
          'По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвалам',
        ],
      },
      {
        code: '63677045',
        text:
          'В каком случае углы откосов рабочих уступов не должны превышать угла естественного откоса разрабатываемых пород? Выберите два правильных варианта ответа.',
        answers: [
          'При работе экскаваторов типа механической лопаты и гидравлических экскаваторов',
          'При работе многоковшовых цепных экскаваторов нижним черпанием',
          'При работе погрузчиков, драглайнов и роторных экскаваторов',
          'При разработке вручную рыхлых и сыпучих пород',
        ],
        correctAnswers: [
          'При работе многоковшовых цепных экскаваторов нижним черпанием',
          'При разработке вручную рыхлых и сыпучих пород',
        ],
      },
      {
        code: '63677046',
        text:
          'Каким документом определяется ширина рабочих площадок с учетом их назначения, а также расположения на них горнотранспортного оборудования, транспортных коммуникаций, линий электроснабжения и связи?',
        answers: [
          'Локальным проектом, утвержденным техническим руководителем (главным инженером) угольного разреза',
          'Техническим проектом разработки месторождения и уточняется в документации на производство работ',
          'Планом мероприятий по локализации и ликвидации последствий аварий угольного разреза (ПЛА)',
        ],
        correctAnswers: [
          'Техническим проектом разработки месторождения и уточняется в документации на производство работ',
        ],
      },
      {
        code: '63677047',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'При разгрузке думпкаров люди должны находиться вне зоны развала горной массы. Вдоль железнодорожного пути в месте разгрузки состава с противоположной от приямка стороны должна быть спланирована площадка для обслуживающего состав персонала',
          'Для безопасной разгрузки думпкаров, груженных смерзающимися, налипающими породами и крупногабаритными кусками, должны быть разработаны мероприятия, утвержденные техническим руководителем (главным инженером) угольного разреза',
          'Опрокидывание кузовов думпкаров и возвращение их в транспортное положение после разгрузки должны производиться без помощи подставок, шпал, рельсов',
          'Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза',
        ],
        correctAnswers: [
          'Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза',
        ],
      },
      {
        code: '63677048',
        text:
          'При каком минимальном расстоянии по воздуху от подъемной или выдвижной части в любом ее положении, в том числе и при наибольшем допускаемом конструкцией подъеме или боковом вылете, до ближайшего провода воздушной линии электропередачи (ВЛ), находящейся под напряжением 35 кВ, допускается работа экскаваторов, погрузчиков, буровых станков?',
        answers: [
          'При расстоянии 1 м',
          'При расстоянии 2 м',
          'При расстоянии 3 м',
          'При расстоянии 4 м',
        ],
        correctAnswers: ['При расстоянии 4 м'],
      },
      {
        code: '63677049',
        text:
          'Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к перегону экскаваторов указано неверно?',
        answers: [
          'Перегон должен осуществляться по трассе, расположенной вне призм обрушения',
          'Уклон трассы не должен превышать величины, допустимой по техническим характеристикам экскаватора, и ширина трассы должна быть достаточной для маневров',
          'Перегон экскаватора должен производиться по сигналам помощника машиниста или специально назначенного лица, при этом должна быть обеспечена постоянная видимость между ними и машинистом экскаватора',
          'Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады',
        ],
        correctAnswers: [
          'Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады',
        ],
      },
      {
        code: '63677050',
        text:
          'Какое из требований безопасности к определению мест и периодичности отбора проб воздуха рабочей зоны угольного разреза указано неверно?',
        answers: [
          'Места отбора проб пыли и вредных газов и их периодичность устанавливаются графиком, утвержденным техническим руководителем (главным инженером) угольного разреза',
          'Отбор проб пыли и вредных газов производится не реже 1 раза в 6 месяцев для угольных разрезов глубиной отработки менее 150 м',
          'Отбор проб пыли и вредных газов производится не реже 1 раза в 5 месяцев для угольных разрезов глубиной отработки более 150 м',
          'Отбор проб пыли и вредных газов производится после каждого изменения технологии работ',
        ],
        correctAnswers: [
          'Отбор проб пыли и вредных газов производится не реже 1 раза в 5 месяцев для угольных разрезов глубиной отработки более 150 м',
        ],
      },
      {
        code: '63677051',
        text:
          'Каким должно быть максимальное сопротивление общего заземляющего устройства разреза?',
        answers: ['4 Ом', '6 Ом', '8 Ом', '10 Ом'],
        correctAnswers: ['4 Ом'],
      },
      {
        code: '63677052',
        text:
          'Каким должно быть нормальное положение шлагбаумов автоматизированного и неавтоматизированного переездов?',
        answers: [
          'Открытое у автоматизированного и неавтоматизированного переездов',
          'Закрытое у автоматизированного и неавтоматизированного переездов',
          'Открытое у автоматизированного, и закрытое у неавтоматизированного переездов',
          'Закрытое у автоматизированного, и открытое у неавтоматизированного переездов',
        ],
        correctAnswers: [
          'Открытое у автоматизированного, и закрытое у неавтоматизированного переездов',
        ],
      },
      {
        code: '63677053',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по организации обслуживания намыва гидроотвала?',
        answers: [
          'Участки намытого гидроотвала должны быть ограждены, и на них должны быть установлены предупредительные плакаты и знаки',
          'Для обслуживания намыва гидроотвала должны устраиваться мостики с перилами',
          'Для обеспечения безаварийной работы должны проводиться натурные наблюдения и инструментальный контроль с использованием контрольно-измерительной аппаратуры',
          'Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности',
        ],
        correctAnswers: [
          'Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности',
        ],
      },
      {
        code: '63677054',
        text:
          'Какое из приведенных положений требований безопасности указаны неверно?',
        answers: [
          'На угольном разрезе запрещается нахождение людей в опасной зоне работающих механизмов',
          'При остановке работ на угольном разрезе запрещается нахождение на его территории лиц, не связанных с обеспечением его жизнедеятельности',
          'В случае невозможности произвести ликвидацию заколов или оборку уступа (борта) все работы в опасной зоне должны быть остановлены',
          'При невозможности вывода техники в случае угрозы ее повреждения, техника должна быть ограждена и установлены знаки, предупреждающие об опасности',
        ],
        correctAnswers: [
          'При невозможности вывода техники в случае угрозы ее повреждения, техника должна быть ограждена и установлены знаки, предупреждающие об опасности',
        ],
      },
      {
        code: '63677055',
        text:
          'Какой минимальной длины должны быть площадки при уклонах дорог длиной более 600 м и более 60 ‰?',
        answers: ['10 м', '25 м', '50 м', '100 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63677056',
        text:
          'Каким должно быть расстояние между откосом уступа, отвала или транспортным средством и контргрузом экскаватора?',
        answers: [
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,5 м',
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,6 м',
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,75 м',
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м',
        ],
        correctAnswers: [
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м',
        ],
      },
      {
        code: '63677057',
        text:
          'Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом по профилактике эндогенных пожаров указано верно?',
        answers: [
          'На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза',
          'Способы обнаружения очагов пожаров и наблюдений за внешними признаками самонагревания угля определяются представителем пожарной части',
          'Способы замера температуры, концентрации СО         определяются инспектором Ростехнадзора',
          'Места установки датчиков определяются представителем подразделения специализированного профессионального аварийно-спасательного формирования',
          'При обнаружении признаков самонагревания угля должны быть приняты меры, предусмотренные планом по профилактике и тушению экзогенных пожаров на угольном разрезе',
        ],
        correctAnswers: [
          'На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза',
        ],
      },
      {
        code: '63677058',
        text:
          'Какими должны быть размеры укрытий  (труб, коробов и т. п.),  применяемых в целях защиты от повреждений кабелей, в местах пересечения с железнодорожными путями и автомобильными дорогами?',
        answers: [
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,2 м в каждую сторону',
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,5 м в каждую сторону',
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,7 м в каждую сторону',
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону',
        ],
        correctAnswers: [
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону',
        ],
      },
      {
        code: '63677059',
        text:
          'В какой период осуществляется рассмотрение планов развития горных работ?',
        answers: [
          'С 10 января по 1 марта планируемого года',
          'С 1 по 30 декабря года, предшествующего планируемому',
          'С 20 января по 25 марта планируемого года',
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
        correctAnswers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
      },
      {
        code: '63677060',
        text:
          'На каком минимальном расстоянии от воздушных линий электропередачи (ЛЭП) и линий связи разрешается укладывать пульпопроводы и водоводы без согласования с владельцами коммуникаций и организациями, осуществляющими контроль за их безопасной эксплуатацией?',
        answers: [
          'На расстоянии 10 м',
          'На расстоянии 15 м',
          'На расстоянии 20 м',
          'На расстоянии 25 м',
        ],
        correctAnswers: ['На расстоянии 25 м'],
      },
      {
        code: '63677061',
        text:
          'Какое максимальное расстояние допускается между горизонтальными площадками маршевых лестниц, устраиваемых на высоте более 10 м для сообщения между уступами разреза?',
        answers: ['30 м', '20 м', '15 м', '10 м'],
        correctAnswers: ['15 м'],
      },
      {
        code: '63677062',
        text:
          'Какое из перечисленных требований безопасности предъявляется к выполнению работ по ручной оборке уступов от нависей и козырьков?',
        answers: [
          'Ручная оборка уступов ложна проводиться в дневное время с применением соответствующих мер безопасности под непосредственным наблюдением руководителя смены или бригадира',
          'Ручная оборка уступов не допускается',
          'Ручную оборку допускается проводить по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира',
          'Ручную оборку допускается проводить при наличии распоряжения под непосредственным наблюдением руководителя смены или бригадира',
        ],
        correctAnswers: [
          'Ручную оборку допускается проводить по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира',
        ],
      },
      {
        code: '63677063',
        text:
          'В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработке минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?',
        answers: [
          'Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков',
          'Только в виде схем эксплуатации объектов первичной переработки (подготовки) минерального сырья (для месторождений твердых полезных ископаемых, включая общераспространенные)',
          'Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)',
          'Только в виде схем эксплуатации объектов и водозаборных сооружений в отношении месторождений минеральных, теплоэнергетических (термальных), технических, промышленных вод (далее - подземные воды)',
          'В виде всех перечисленных схем',
        ],
        correctAnswers: ['В виде всех перечисленных схем'],
      },
      {
        code: '63677064',
        text:
          'Какой должна быть высота подвески контактного провода на постоянных путях над головкой рельса на станциях и на перегонах соответственно?',
        answers: [
          'Соответственно не менее 6250 мм и не менее 5750 мм',
          'Соответственно не менее 5750 мм и не менее 5250 мм',
          'Соответственно не менее 5250 мм и не менее 4750 мм',
          'Соответственно не менее 4750 мм и не менее 4250 мм',
        ],
        correctAnswers: ['Соответственно не менее 6250 мм и не менее 5750 мм'],
      },
      {
        code: '63677065',
        text:
          'Какой должна быть минимальная ширина переходных мостиков через ленточные конвейеры?',
        answers: ['1,2 м', '1,0 м', '0,8 м', '0,7 м'],
        correctAnswers: ['0,8 м'],
      },
      {
        code: '63677066',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к следованию поездов вагонами вперед?',
        answers: [
          'Следование поездов вагонами вперед разрешается при наличии вагонов с тормозной площадкой',
          'Следование поездов вагонами вперед разрешается при наличии на переднем вагоне исправной подножки, обращенной в сторону движения поезда, на которой должен находиться составитель (кондуктор)',
          'Допускается следование специализированных технологических поездов вагонами вперед без составителя (кондуктора), при обязательном наличии на переднем вагоне (думпкаре) соответствующих звуковых, а в темное время суток и световых сигналов',
          'Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24',
        ],
        correctAnswers: [
          'Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24',
        ],
      },
      {
        code: '63677067',
        text:
          'На каком минимальном расстоянии от работающих механизмов на территории складирования горной массы (пород), на разгрузочных площадках, перегрузочных пунктах (складах) должны находиться люди?',
        answers: [
          'На расстоянии 5,0 м',
          'На расстоянии 4,0 м',
          'На расстоянии 3,0 м',
          'Не нормируется',
        ],
        correctAnswers: ['На расстоянии 5,0 м'],
      },
      {
        code: '63677068',
        text: 'Какие требования предъявляются к воздуху рабочей зоны разреза?',
        answers: [
          'Воздух рабочей зоны должен содержать по объему около 12 % кислорода и не более 2,0 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
          'Воздух рабочей зоны должен содержать по объему около 15 % кислорода и не более 1,0 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
          'Воздух рабочей зоны должен содержать по объему около 18 % кислорода и не более 0,8 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
          'Воздух рабочей зоны должен содержать по объему около 20 % кислорода и не более 0,5 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
        ],
        correctAnswers: [
          'Воздух рабочей зоны должен содержать по объему около 20 % кислорода и не более 0,5 % углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
        ],
      },
      {
        code: '63677069',
        text:
          'С какой периодичностью должна проверяться исправность и комплектность машин механиком, энергетиком участка?',
        answers: [
          'Еженедельно',
          '1 раз в 2 недели',
          '1 раз в месяц',
          '1 раз в квартал',
        ],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63677070',
        text:
          'Каким образом устанавливаются порядок и обстоятельства, при которых допускается отстранить руководителя работ по ликвидации аварии и руководителя горноспасательных работ и назначить другое должностного лица?',
        answers: [
          'Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно',
          'Распорядительным документом, утверждаемым представителем территориального органа Ростехнадзора',
          'Распорядительным документом, утверждаемым представителем МЧС России',
          'На основании Инструкции по локализации и ликвидации последствий аварий на опасных производственных объектах, на которых ведутся горные работы',
        ],
        correctAnswers: [
          'Распорядительным документом, утверждаемым руководителями ОПО и ПАСС(Ф) соответственно',
        ],
      },
      {
        code: '63677071',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Комиссией пользователя недр (подрядной организации)',
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
          'Техническим руководителем пользователя недр (подрядной организации)',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63677072',
        text:
          'Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации технологического автомобильного транспорта указано неверно?',
        answers: [
          'Допускается использование автомобилей, не имеющих технической документации, выданной заводом-изготовителем и содержащей его основные технические и эксплуатационные характеристики при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии',
          'На линию автомобили могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения транспортных средств, находятся в технически исправном состоянии',
          'Автомобили должны также иметь необходимый запас горючего и комплект инструмента, предусмотренный заводом-изготовителем',
        ],
        correctAnswers: [
          'Допускается использование автомобилей, не имеющих технической документации, выданной заводом-изготовителем и содержащей его основные технические и эксплуатационные характеристики при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии',
        ],
      },
      {
        code: '63677073',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации скреперов, бульдозеров и погрузчиков?',
        answers: [
          'На всю самоходную технику (грейдеры, скреперы, бульдозеры, погрузчики) должны иметься паспорта, руководства по эксплуатации, техническому обслуживанию и ремонту, выданные организацией-изготовителем, содержащие их основные технические и эксплуатационные характеристики',
          'На линию транспортные средства могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения, находятся в технически исправном состоянии',
          'Во всех случаях при движении транспортного средства и самоходной техники задним ходом должен подаваться звуковой сигнал',
          'Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос',
        ],
        correctAnswers: [
          'Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос',
        ],
      },
      {
        code: '63677074',
        text:
          'Кем осуществляется подача сигналов при погрузке экскаваторами в железнодорожные вагоны и разгрузке их на экскаваторных отвалах?',
        answers: [
          'Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта',
          'Машинистом поезда в соответствии с сигналами, установленными при эксплуатации угольного разреза',
          'Лицом, назначенным приказом технического руководителя (главного инженера) угольного разреза',
          'Лицом из числа поездной бригады, назначенным машинистом поезда',
        ],
        correctAnswers: [
          'Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта',
        ],
      },
      {
        code: '63677075',
        text:
          'Что из перечисленного не должна превышать высота уступа при применении канатных экскаваторов? Выберите два правильных варианта ответов.',
        answers: [
          'Максимальную высоту черпания экскаватора',
          'Высоту траектории движения рабочего органа (ковша) экскаватора (погрузчика)',
          'Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов',
          'Полуторную высоту черпания экскаватора',
        ],
        correctAnswers: [
          'Максимальную высоту черпания экскаватора',
          'Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов',
        ],
      },
      {
        code: '63677076',
        text:
          'Каким должен быть максимальный угол откосов рабочих уступов при работе экскаваторов типа механической лопаты, драглайна и роторных экскаваторов?',
        answers: ['70°', '75°', '80°', '85°'],
        correctAnswers: ['80°'],
      },
      {
        code: '63677077',
        text:
          'Какое превышение должен иметь внешний рельс разгрузочного пути по отношению к внутреннему?',
        answers: [
          'На 100 - 150 мм',
          'На 80 - 100 мм',
          'На 60 - 80 мм',
          'На 40 - 60 мм',
        ],
        correctAnswers: ['На 100 - 150 мм'],
      },
      {
        code: '63677078',
        text:
          'Что из перечисленного не подлежит профилактической обработке антипирогенами?',
        answers: [
          'Угольные уступы по рабочему борту, имеющие геологические включения или нарушения от взрывных работ, сроки обработки которых в процессе технологического цикла превышают продолжительность инкубационного периода их самовозгорания',
          'Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве автотракторных съездов',
          'Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве насыпей под временные железнодорожные пути и конвейерные линии',
          'Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационного периода',
        ],
        correctAnswers: [
          'Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационного периода',
        ],
      },
      {
        code: '63677079',
        text:
          'Как должны производиться перегон горнотранспортных и строительно-дорожных машин (экскаваторов, буровых станков) и перевозки их на транспортных средствах на расстояние более 1 км, а также при необходимости отключения пересекаемых воздушных линий электропередачи (ВЛ)?',
        answers: [
          'В соответствии с планом мероприятий по локализации и ликвидации последствий аварий',
          'С обязательным уведомлением территориального органа Ростехнадзора, осуществляющего федеральный государственный надзор в области промышленной безопасности за угольным разрезом',
          'В соответствии с проектом производства работ или технологическими картами, утвержденными техническим руководителем (главным инженером) угольного разреза',
          'В соответствии с планом развития горных работ',
          'В соответствии с техническими инструкциями завода-изготовителя',
        ],
        correctAnswers: [
          'В соответствии с проектом производства работ или технологическими картами, утвержденными техническим руководителем (главным инженером) угольного разреза',
        ],
      },
      {
        code: '63677080',
        text:
          'Какие из перечисленных участков ведения открытых горных работ должны относиться к зонам, опасным по геомеханическим условиям?',
        answers: [
          'Только участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами',
          'Только участок бортов карьера и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)',
          'Только участок борта, нагруженные отвалами, размещенными в пределах призмы возможного обрушения',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63677081',
        text:
          'Что не соответствует требованиям по выбору и контролю за состоянием подъемного каната бурового станка?',
        answers: [
          'Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности',
          'Не менее одного раза в неделю механик участка или другое назначенное лицо должны проводить наружный осмотр каната и делать запись о результатах осмотра',
          'При наличии в подъемном канате более 10 % порванных проволок на длине шага свивки его следует заменить',
          'Выступающие концы проволок должны быть обрезаны',
        ],
        correctAnswers: [
          'Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности',
        ],
      },
      {
        code: '63677082',
        text:
          'Что должно входить в состав планов и схем развития горных работ?',
        answers: [
          'Графическая часть и пояснительная записка с табличными материалами',
          'Фотографии мест дальнейшего производства работ',
          'Список с указанием паспортных данных каждого работника',
          'Наряд-допуск на выполнение работ',
        ],
        correctAnswers: [
          'Графическая часть и пояснительная записка с табличными материалами',
        ],
      },
      {
        code: '63677083',
        text: 'Что должны отображать графические материалы?',
        answers: [
          'Только границы участка недр, установленные при предоставлении лицензии',
          'Только границы опасных зон и зон с особыми условиями использования территорий',
          'Только контуры горных выработок',
          'Только ситуацию местности на площади горного отвода',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63677084',
        text:
          'Кто разрабатывает мероприятия, обосновывающие и обеспечивающие безопасную эксплуатацию угольного разреза до приведения действующего угольного разреза в соответствие с требованиями Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Главный технолог угольного разреза',
          'Главный маркшейдер угольного разреза',
          'Технический руководитель (главный инженер) угольного разреза',
          'Руководитель угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63677085',
        text:
          'В каких из перечисленных случаев температурные съемки не проводятся?',
        answers: [
          'Только при грозах и ливневых осадках',
          'Только при обнаружении признаков деформации поверхности породного отвала',
          'Только при обнаружении выгоревших участков',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63677086',
        text:
          'Каким должно быть превышение гребня гидроотвала у верхового откоса над пляжем при выпуске пульпы на пляж для исключения перелива на гребень и низовой откос дамбы?',
        answers: [
          'Равным диаметру пульповыпуска',
          'Не менее диаметра пульповыпуска, но не менее 0,5 м',
          'Не менее диаметра пульповыпуска, но не менее 1 м',
          'Не менее диаметра пульповыпуска, но не менее 1,5 м',
        ],
        correctAnswers: ['Не менее диаметра пульповыпуска, но не менее 0,5 м'],
      },
      {
        code: '63677087',
        text:
          'Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано неверно?',
        answers: [
          'Оперативные планы не должны содержать ссылки на пункты ранее разработанных оперативных планов',
          'Оперативные планы должны разрабатываться на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии',
          'Последующие оперативные планы должны разрабатываться в случаях, когда мероприятия предыдущего оперативного плана реализованы',
          'Оперативный план утверждает руководитель горноспасательных работ',
        ],
        correctAnswers: [
          'Оперативный план утверждает руководитель горноспасательных работ',
        ],
      },
      {
        code: '63677088',
        text:
          'На каком минимальном безопасном расстоянии от верхней бровки уступа, определяемом расчетами или проектом, до ближайшей точки опоры станка на спланированной площадке должен устанавливаться буровой станок?',
        answers: ['1 м', '1,5 м', '2 м', '3 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63677089',
        text:
          'В каком из перечисленных случаев в подземных горных выработках, в которых имеется взрывоопасная среда при наличии очагов горения или существует угроза прорыва воды (пульпы), работы по поиску и спасению людей не проводятся или прекращаются?',
        answers: [
          'Если рудничная атмосфера в выработках аварийного участка находилась в состоянии, непригодном для дыхания, в течение времени, равного пятикратному сроку времени защитного действия средств индивидуальной защиты органов дыхания',
          'Если в местах предполагаемого нахождения людей в течение не менее 24 часов температура воздуха составляла 50 °C и более',
          'Если в местах предполагаемого нахождения людей горная выработка затоплена водой и из нее есть запасной выход',
          'Если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке',
        ],
        correctAnswers: [
          'Если люди, находящиеся в аварийных горных выработках, признаны погибшими в установленном порядке',
        ],
      },
      {
        code: '63677090',
        text:
          'Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации бульдозера, скрепера или погрузчика?',
        answers: [
          'Допускается работа техники поперек крутых склонов при углах, предусмотренных инструкцией завода-изготовителя',
          'Запрещается эксплуатация бульдозера (трактора) при отсутствии или неисправности блокировки, исключающей запуск двигателя при включенной коробке передач, или устройства для запуска двигателя из кабины',
          'Запрещается находиться под поднятым рабочим органом (ножом или ковшом) самоходной техники',
          'Для осмотра рабочего органа (ножа или ковша) снизу его необходимо опустить на надежные подкладки, а двигатель выключить',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63677091',
        text:
          'В какие сроки проводится измерение сопротивления стационарных электроустановок?',
        answers: [
          'В периоды наибольшего высыхания (летом) и наибольшего промерзания (зимой) грунта',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
          'Только после монтажа, реконструкции (переустройства) и ремонта заземляющих устройств',
        ],
        correctAnswers: [
          'В периоды наибольшего высыхания (летом) и наибольшего промерзания (зимой) грунта',
        ],
      },
      {
        code: '63677092',
        text:
          'Под каким углом к бровке уступа должна быть продольная ось бурового станка при бурении первого ряда скважин?',
        answers: ['30°', '45°', '60°', '90°'],
        correctAnswers: ['90°'],
      },
      {
        code: '63677093',
        text:
          'Что не соответствует требованиям по обеспечению разрезов связью?',
        answers: [
          'Угольный разрез должен быть оборудован комплексом технических средств связи и сигнализации, обеспечивающих эффективное управление технологическими процессами и безопасностью работ, включающих два канала связи с подразделениями профессиональной аварийно-спасательной службы и (или) профессионального аварийно-спасательного формирования ПАСС(Ф), обслуживающим угольный разрез',
          'Для сигнальных устройств, кроме систем централизованных блокировок (СЦБ), питаемых напряжением не выше 24 В, допускается выполнение линий голыми проводами',
          'Установки связи должны обеспечиваться защитой от мешающего и опасного влияния линий высокого напряжения контактной сети, грозовых разрядов и блуждающих токов',
          'Питание устройств связи и сигнализации разреза, для исключения помех от осветительной сети, должно производиться только от специальных аккумуляторных батарей',
        ],
        correctAnswers: [
          'Питание устройств связи и сигнализации разреза, для исключения помех от осветительной сети, должно производиться только от специальных аккумуляторных батарей',
        ],
      },
      {
        code: '63677094',
        text:
          'Что из перечисленного используется для обозначения границ опасных зон на местности в процессе ведения горных работ на разрезе?',
        answers: [
          'Только предупредительные знаки',
          'Только ограждения',
          'Только предохранительные валы',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63677095',
        text:
          'В каком из перечисленных случаев основным критерием определения безопасной высоты уступа являются расчеты с учетом траектории движения рабочего органа (ковша) экскаватора (погрузчика)?',
        answers: [
          'При применении гидравлических экскаваторов и погрузчиков',
          'При применении канатных экскаваторов',
          'При применении экскаваторов с удлиненным рабочим оборудованием',
          'При разработке уступа вручную',
          'При разработке пород с применением буровзрывных работ',
        ],
        correctAnswers: [
          'При применении гидравлических экскаваторов и погрузчиков',
        ],
      },
      {
        code: '63677096',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для нормальной колеи?',
        answers: ['1300 мм', '1400 мм', '1500 мм', '1600 мм', '1700 мм'],
        correctAnswers: ['1600 мм'],
      },
      {
        code: '63677097',
        text:
          'Что из перечисленного должна обеспечивать высота уступа для экскаваторов с удлиненным рабочим оборудованием?',
        answers: [
          'Видимость всех формируемых временно неработающих бортов',
          'Видимость транспортных сосудов из кабины машиниста экскаватора',
          'Видимость соседних рабочих уступов',
          'Возможность контроля ширины рабочих площадок и углов откоса уступов',
        ],
        correctAnswers: [
          'Видимость транспортных сосудов из кабины машиниста экскаватора',
        ],
      },
      {
        code: '63677098',
        text:
          'При каком выходе керна допускается производить отбор проб при колонковом бурении скважины для определения инкубационного периода самовозгорания угля?',
        answers: [
          'Не менее 35 %',
          'Не менее 50 %',
          'Не менее 60 %',
          'Не менее 80 %',
        ],
        correctAnswers: ['Не менее 80 %'],
      },
      {
        code: '63677099',
        text:
          'Какое максимальное количество соединений допускается в каждом пролете на один провод или трос?',
        answers: [
          '3 соединения',
          '4 соединения',
          '5 соединений',
          '6 соединений',
        ],
        correctAnswers: ['3 соединения'],
      },
      {
        code: '63677100',
        text:
          'При какой максимальной температуре пород разбираемого слоя разрешается производить работы по тушению или разборке горящих породных отвалов?',
        answers: ['При 80 °С', 'При 83 °С', 'При 85 °С', 'При 90 °С'],
        correctAnswers: ['При 80 °С'],
      },
      {
        code: '63677101',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к устройству переездов на временных железнодорожных путях? Выберете все варианты ответов',
        answers: [
          'Перелом профиля устраивается на расстоянии 5 м от крайнего рельса',
          'Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°',
          'На расстоянии не менее длины тормозного пути в обе стороны от переезда должны быть установлены предупредительные знаки о подаче свистка машинистом локомотива',
          'Продольные уклоны дорог на подходах к переезду должны быть не менее 60 ‰',
        ],
        correctAnswers: [
          'Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°',
          'Продольные уклоны дорог на подходах к переезду должны быть не менее 60 ‰',
        ],
      },
      {
        code: '63677102',
        text:
          'Как должны быть устроены мостки для обслуживания трубопровода и водопроводных лотков, уложенных на эстакадах?',
        answers: [
          'Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м',
          'Мостки должны быть шириной 0,6 м, огражденные перилами высотой 1,0 м и обшитые снизу на высоту не менее 0,15 м',
          'Мостки должны быть шириной 0,4 м, огражденные перилами высотой 1,3 м и обшитые снизу на высоту не менее 0,25 м',
          'Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,1 м и обшитые снизу на высоту не менее 0,1 м',
        ],
        correctAnswers: [
          'Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м',
        ],
      },
      {
        code: '63677103',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара до 60 т?',
        answers: ['1300 мм', '1400 мм', '1500 мм', '1600 мм', '1700 мм'],
        correctAnswers: ['1600 мм'],
      },
      {
        code: '63677104',
        text:
          'Электроприводы каких установок не оборудуются электрической блокировкой, исключающей самозапуск механизмов после подачи напряжения питания?',
        answers: [
          'Электроприводы экскаваторов, буровых станков',
          'Электроприводы отвалообразователей, конвейеров',
          'Электроприводы насосов',
          'Электроприводы автоматизированных компрессорных установок, водоотливов',
        ],
        correctAnswers: [
          'Электроприводы автоматизированных компрессорных установок, водоотливов',
        ],
      },
      {
        code: '63677105',
        text:
          'Кто из перечисленных лиц назначает комиссию по оценке качества проведенного планового текущего и капитального ремонта?',
        answers: [
          'Представитель органов государственного надзора за техническим состоянием самоходных машин',
          'Представитель завода-изготовителя',
          'Представитель территориального органа Ростехнадзора',
          'Технический руководитель (главный инженер) угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63677106',
        text:
          'Какой минимальной ширины должны быть маршевые лестницы, устраиваемые на высоте более 10 м для сообщения между уступами разреза?',
        answers: ['0,6 м', '0,7 м', '0,8 м', '0,9 м', '1,0 м'],
        correctAnswers: ['0,8 м'],
      },
      {
        code: '63677107',
        text:
          'Что из перечисленного не соответствует требованиям организации безопасного обслуживания электроустановок и сетей?',
        answers: [
          'При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение 3 суток',
          'Для организации безопасного обслуживания электроустановок и сетей должны быть определены и оформлены распоряжениями технического руководителя (главного инженера) угольного разреза границы обслуживания электротехническим персоналом, назначены лица, ответственные за безопасную эксплуатацию электроустановок, по организации и структурным подразделениям',
          'Лица, ответственные за безопасную эксплуатацию электроустановок, должны быть обучены и аттестованы на знание правил безопасной эксплуатации электроустановок',
          'Лица, работающие в электроустановках и на линиях электропередач (ЛЭП) должны выполнять организационные и технические мероприятия, предусмотренные требованиями Инструкции по безопасной эксплуатации электроустановок в горнорудной промышленности',
        ],
        correctAnswers: [
          'При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение 3 суток',
        ],
      },
      {
        code: '63677108',
        text:
          'Какие из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны верно?',
        answers: [
          'К управлению горнотранспортными машинами, связанному с оперативным включением и отключением электроустановок, допускаются машинисты и помощники машинистов только после их обучения, аттестации и присвоения им квалификационных групп по электробезопасности',
          'Оперативные переключения кабельных линий в пределах закрепленной за машинистом и помощником машиниста горнотранспортной машины и ее переключательного пункта проводятся по наряду-допуску',
          'При временном переводе машинистов и помощников машинистов на другое горное оборудование обслуживание данного оборудования допускается после их ознакомления со схемой электроснабжения эксплуатируемого оборудования под подпись',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63677109',
        text:
          'Какие задачи должна указывать эксплуатирующая организация в подразделе "Задачи эксплуатирующей организации в области ПБ" при разработке положения о единой системе управления промышленной безопасностью и охраной труда для организаций по добыче (переработке) угля (горючих сланцев)?',
        answers: [
          'Только задачи по выявлению опасных технологий, участков, рабочих мест и предупреждению несчастных случаев;  управлению основными производственными рисками',
          'Только задачи по обеспечению противоаварийной устойчивости и готовности к локализации и ликвидации последствий аварии',
          'Только задачи по финансированию мероприятий по обеспечению безопасной эксплуатации ОПО',
          'Все перечисленные задачи',
        ],
        correctAnswers: ['Все перечисленные задачи'],
      },
      {
        code: '63677110',
        text:
          'В какие периоды должны проводиться температурные съемки действующих негорящих породных отвалов?',
        answers: [
          '3 раза в год: в мае, июле и сентябре',
          '2 раза в год: в мае и сентябре',
          '1 раз в год: в сентябре',
          '3 раза в год: в мае, июне и сентябре',
        ],
        correctAnswers: ['3 раза в год: в мае, июле и сентябре'],
      },
      {
        code: '63677111',
        text:
          'Каким должно быть минимальное расстояние по горизонтали между рабочими местами или механизмами, расположенными на двух смежных по вертикали уступах, при экскаваторной разработке?',
        answers: [
          'Равным максимальному радиусу черпания',
          'Равным полуторной сумме максимальных радиусов черпания',
          'Равным двойной сумме максимальных радиусов черпания',
          'Равным утроенной сумме максимальных радиусов черпания',
        ],
        correctAnswers: [
          'Равным полуторной сумме максимальных радиусов черпания',
        ],
      },
      {
        code: '63677112',
        text:
          'Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?',
        answers: [
          'Только защиту объектов ведения горных работ и месторождений полезных ископаемых от затопления, обводнения, взрывов, пожаров, горных ударов',
          'Только рекультивацию нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых)',
          'Только применение средств и технологий, повышающих безопасное ведение горных работ и уровень извлечения полезных ископаемых',
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
        correctAnswers: [
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
      },
      {
        code: '63677113',
        text:
          'Каким должно быть расстояние между пробоотборными (наблюдательными) скважинами, располагаемыми по периметру гидроотвала и по направлению потока грунтовых вод для контроля уровня радиоактивности грунтовых вод?',
        answers: [
          'Не менее 300 м',
          'Не менее 400 м',
          'Не менее 500 м',
          'Не менее 800 м',
        ],
        correctAnswers: ['Не менее 300 м'],
      },
      {
        code: '63677114',
        text:
          'Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?',
        answers: [
          'Консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при наличии)',
          'Организация наблюдений за состоянием горных отводов',
          'Достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63677115',
        text:
          'Какой должна быть максимальная высота уступа при разработке вручную рыхлых неустойчивых плотных пород?',
        answers: ['3 м', '4 м', '5 м', '6 м'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63677116',
        text:
          'Какой минимальной ширины должен быть проход для людей в галереях и эстакадах вновь проектируемых угольных разрезов у конвейера при ширине ленты до 1400 мм?',
        answers: ['700 мм', '800 мм', '1000 мм', '1400 мм'],
        correctAnswers: ['800 мм'],
      },
      {
        code: '63677117',
        text:
          'В каком случае требования по подвеске проводов электрического освещения и светильников на стационарных и передвижных опорах контактной сети постоянного тока напряжением до 1650 В включительно указаны неверно?',
        answers: [
          'Подвеска проводов электрического освещения и светильников допускается выше контактного провода на противоположной от него стороне опоры',
          'Расстояние от контактного провода до проводов освещения по вертикали должно быть не менее 1,5 м',
          'Изоляторы осветительной сети принимаются на напряжение 1650 В',
          'Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м',
        ],
        correctAnswers: [
          'Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м',
        ],
      },
      {
        code: '63677118',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом перед началом работы гидромонитора?',
        answers: [
          'Из зоны действия струи гидромонитора должны быть удалены все люди',
          'С места работы гидромонитора должны быть удалены лица, не имеющие отношения к его работе',
          'Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками',
          'Во время пуска выходное отверстие гидромонитора должно быть всегда направлено в безопасное для окружающих место',
        ],
        correctAnswers: [
          'Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками',
        ],
      },
      {
        code: '63677119',
        text:
          'Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при двухполосном движении?',
        answers: [
          'Не менее 7,5 м',
          'Не менее 8,0 м',
          'Не менее 9,0 м',
          'Не менее 10,0 м',
        ],
        correctAnswers: ['Не менее 10,0 м'],
      },
      {
        code: '63677120',
        text:
          'На каком расстоянии друг от друга должны находиться места отбора проб для определения инкубационного периода самовозгорания угля?',
        answers: [
          'На расстоянии 30 - 50 м',
          'На расстоянии 20 - 25 м',
          'На расстоянии 10 - 20 м',
          'На расстоянии 5 - 10 м',
        ],
        correctAnswers: ['На расстоянии 30 - 50 м'],
      },
      {
        code: '63677121',
        text:
          'Где разрешается применение напряжения 380/220 В в сети с заземленной нейтралью для освещения?',
        answers: [
          'Для осветительных сетей на угольном разрезе',
          'Для стационарных световых точек на передвижных машинах',
          'Для стационарных световых точек на механизмах и агрегатах',
          'Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций',
        ],
        correctAnswers: [
          'Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций',
        ],
      },
      {
        code: '63677122',
        text:
          'На какие перечисленные виды работ не составляются планы и схемы горных работ?',
        answers: [
          'На вскрышные работы',
          'На рекультивационные работы',
          'На работы, связанные со вторичной переработкой минерального сырья',
          'На маркшейдерские работы',
        ],
        correctAnswers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
        ],
      },
      {
        code: '63677123',
        text: 'Чем регламентируются работы по разборке породных отвалов?',
        answers: [
          'Проектной документацией',
          'Техническими условиями',
          'Планом производства работ',
          'Типовой инструкцией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63677124',
        text:
          'В соответствии с каким документом должны осуществляться горные работы по проведению траншей, разработке уступов, отсыпке отвалов?',
        answers: [
          'В соответствии с локальными проектами производства работ (паспортами), утвержденными техническим руководителем разреза',
          'В соответствии с утвержденными планами производства работ, согласованными c органами исполнительной власти муниципального образования, на территории которого эксплуатируется разрез',
          'В соответствии с планами по разработке разрезов, согласованными с Ростехнадзором',
          'В соответствии с планами работ по разработке разрезов, согласованными с аварийно-спасательными формированиями',
          'В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ',
        ],
        correctAnswers: [
          'В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ',
        ],
      },
      {
        code: '63677125',
        text:
          'На какой высоте от оси пути следует устанавливать указатели путевого заграждения, располагая их со стороны машиниста локомотива?',
        answers: [
          'На высоте 1,5 м',
          'На высоте 2,0 м',
          'На высоте 2,5 м',
          'Не нормируется',
        ],
        correctAnswers: ['На высоте 1,5 м'],
      },
      {
        code: '63677126',
        text:
          'На что направлены требования Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Только на обеспечение промышленной безопасности',
          'Только на предупреждение аварий и инцидентов на угольных разрезах',
          'Только на обеспечение готовности к локализации и ликвидации последствий аварий',
          'На все перечисленное',
        ],
        correctAnswers: ['На все перечисленное'],
      },
      {
        code: '63677127',
        text:
          'Каким должно быть расстояние от гидромониторной установки и другого забойного оборудования (скреперов, бульдозеров) до забоя при гидромеханизированном способе разработки глинистых, плотных и лессовидных пород, способных к обрушению глыбами?',
        answers: [
          'Не менее 1,2 высоты уступа',
          'Не менее высоты уступа',
          'Не менее 0,8 высоты уступа',
          'Не менее 0,4 высоты уступа',
        ],
        correctAnswers: ['Не менее 1,2 высоты уступа'],
      },
      {
        code: '63677128',
        text:
          'Какая электрическая система должна применяться для осветительных сетей на разрезе, а также для стационарных световых точек на передвижных машинах, механизмах и агрегатах?',
        answers: [
          'Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В',
          'Электрическая система с заземленной нейтралью напряжением 380 В',
          'Электрическая система с изолированной нейтралью при линейном напряжении не выше 127 В',
          'Электрическая система с заземленной нейтралью напряжением 127 В',
        ],
        correctAnswers: [
          'Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В',
        ],
      },
      {
        code: '63677129',
        text:
          'В каком из перечисленных случаев руководитель горноспасательных работ не проводит организацию подземной горноспасательной базы?',
        answers: [
          'Если горноспасательные работы проводятся в горных выработках с непригодной для дыхания атмосферой',
          'Если горноспасательные работы выполняются для ликвидации последствий прорыва воды при выполнении водолазных спусков',
          'Если все прибывшие в организацию, эксплуатирующую ОПО, горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий, связанным со спасением людей, застигнутых аварией',
          'Если горноспасательные работы выполняются для ликвидации последствий прорыва обводненной горной массы при выполнении водолазных работ',
        ],
        correctAnswers: [
          'Если все прибывшие в организацию, эксплуатирующую ОПО, горноспасательные отделения привлечены к горноспасательным работам, определенным планом мероприятий по локализации и ликвидации последствий аварий, связанным со спасением людей, застигнутых аварией',
        ],
      },
      {
        code: '63677130',
        text:
          'В каком из перечисленных случаев в подземных горных выработках горноспасательные работы приостанавливаются, и организуется вывод из зоны аварии людей, задействованных в данных работах?',
        answers: [
          'Если концентрация метана у места тушения пожара на поступающей к очагу пожара или исходящей от пожара вентиляционной струе составляет 0,5 %',
          'Если концентрация сернистого газа в рудничной атмосфере в горных выработках составляет 1 %',
          'Если температура воздуха в горных выработках с непригодной для дыхания атмосферой в зоне высокой температуры повысилась на 1 °C в течение 5 минут',
          'Если рудничная атмосфера на аварийном участке, на котором действует пожар, находится во взрывобезопасном состоянии',
        ],
        correctAnswers: [
          'Если концентрация сернистого газа в рудничной атмосфере в горных выработках составляет 1 %',
        ],
      },
      {
        code: '63677131',
        text:
          'Что из перечисленного не соответствует условиям погрузки горной массы в автомобили экскаваторами?',
        answers: [
          'Ожидающий погрузки автомобиль должен находиться за пределами радиуса действия экскаватора (погрузчика) и становиться под погрузку только после звукового и светового сигналов машиниста экскаватора (погрузчика) или оператора погрузочного устройства',
          'Погрузка в кузов автомобиля должна производиться только сзади или сбоку, перенос экскаваторного рабочего органа (ковша) над кабиной автомобиля запрещается',
          'Высота падения груза должна быть минимально возможной и во всех случаях не превышать 3,5 м',
          'Нагруженный автомобиль может следовать к пункту разгрузки только после звукового и светового сигналов машиниста экскаватора (погрузчика)',
        ],
        correctAnswers: [
          'Высота падения груза должна быть минимально возможной и во всех случаях не превышать 3,5 м',
        ],
      },
      {
        code: '63677132',
        text: 'В каком случае выполняется одинарное крепление проводов?',
        answers: [
          'При пересечении воздушных линий электропередачи (ВЛ) с контактной сетью',
          'При пересечении воздушных линий электропередачи с воздушной линией электропередач (ВЛ с ВЛ)',
          'При пересечении воздушных линий электропередачи (ВЛ) с постоянными технологическими дорогами',
          'При пересечении воздушных линий электропередачи (ВЛ) с автомобильными дорогами, проложенными по уступам и отвалам',
        ],
        correctAnswers: [
          'При пересечении воздушных линий электропередачи (ВЛ) с автомобильными дорогами, проложенными по уступам и отвалам',
        ],
      },
      {
        code: '63677133',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к проведению ремонта технологического оборудования?',
        answers: [
          'При выполнении ремонтных работ подрядной организацией ответственные представители заказчика и подрядчика должны оформить на весь период выполнения работ наряд-допуск (акт-допуск, наряд-заказ), разработать и осуществить организационно-технические мероприятия, направленные на повышение безопасного ведения ремонтных работ',
          'Запрещается проведение ремонтных работ в непосредственной близости от открытых движущихся частей механических установок, а также вблизи электрических проводов и токоведущих частей, находящихся под напряжением, при отсутствии их надлежащего ограждения',
          'Ремонт и замену частей механизмов допускается проводить только после полной остановки машины, снятия давления в гидравлических и пневматических системах, блокировки пусковых аппаратов, приводящих в движение механизмы, на которых производятся ремонтные работы. Допускается при выполнении ремонтных работ подача электроэнергии по проекту организации работ',
          'Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ',
        ],
        correctAnswers: [
          'Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ',
        ],
      },
      {
        code: '63677134',
        text:
          'Что не учитывается проектом при определении высоты уступа разреза?',
        answers: [
          'Результаты исследований физико-механических свойств горных пород и полезного ископаемого',
          'Горно-геологические условия залегания горных пород и полезного ископаемого',
          'Параметры оборудования',
          'Квалификация персонала угольного разреза',
        ],
        correctAnswers: ['Квалификация персонала угольного разреза'],
      },
      {
        code: '63677135',
        text:
          'Какой должна быть минимальная высота перил переходных мостиков через ленточные конвейеры?',
        answers: ['1,2 м', '1,1 м', '0,8 м', '0,7 м'],
        correctAnswers: ['1,1 м'],
      },
      {
        code: '63677136',
        text:
          'Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, схем вскрытия месторождения',
          'Из геологических и структурных карт, характерных разрезов, проекций, схем',
          'Из обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок, геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок, геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63677137',
        text:
          'Каким должно быть расстояние между концом консольного ленточного отвалообразователя с периодическим перемещением и гребнем отвала?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 0,7 м',
          'Не менее 1,0 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63677138',
        text:
          'На каком расстоянии от воздушных линий (ВЛ) должен быть расположен гидромонитор без разработки и осуществления мероприятий, обеспечивающих безопасность работ, утвержденных техническим руководителем (главным инженером) угольного разреза?',
        answers: [
          'На расстоянии не менее 1,25 дальности полета струи',
          'На расстоянии не менее 1,5 дальности полета струи',
          'На расстоянии не менее 1,75 дальности полета струи',
          'На расстоянии не менее двукратной дальности полета струи',
        ],
        correctAnswers: [
          'На расстоянии не менее двукратной дальности полета струи',
        ],
      },
      {
        code: '63677139',
        text:
          'С каким максимальным уклоном должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 ‰?',
        answers: [
          'С уклоном 10 ‰',
          'С уклоном 15 ‰',
          'С уклоном 20 ‰',
          'С уклоном 25 ‰',
        ],
        correctAnswers: ['С уклоном 20 ‰'],
      },
      {
        code: '63677140',
        text:
          'Какие схемы электроснабжения должны быть в наличии на угольном разрезе?',
        answers: [
          'Только схема электроснабжения, нанесенная на план горных работ, утвержденная техническим руководителем (главным инженером) угольного разреза',
          'Только принципиальная однолинейная схема',
          'Только отдельная схема электроснабжения для сезонных электроустановок перед вводом их в работу',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63677141',
        text:
          'В какие сроки должна проверяться исправность действия (срабатывания) реле утечки тока?',
        answers: [
          'Каждую смену перед началом работы',
          '1 раз в сутки',
          '1 раз в 3 дня',
          '1 раз в 10 дней',
        ],
        correctAnswers: ['Каждую смену перед началом работы'],
      },
      {
        code: '63677142',
        text:
          'Как часто должна обновляться схема транспортных коммуникаций на угольных разрезах, нанесенная на план горных работ?',
        answers: [
          '1 раз в месяц',
          '1 раз в 2 месяца',
          '1 раз в квартал',
          '1 раз в полугодие',
          'Должна обновляться при изменении схемы',
        ],
        correctAnswers: ['Должна обновляться при изменении схемы'],
      },
      {
        code: '63677143',
        text:
          'Каким должно быть минимальное расстояние по горизонтали между экскаваторами и драглайнами (с учетом величины заброса ковша) или драглайнами (с учетом величины заброса ковшей), расположенными на одном горизонте?',
        answers: [
          'Равным сумме их наибольших радиусов действия',
          'Равным полуторной сумме их наибольших радиусов действия',
          'Равным двойной сумме их наибольших радиусов действия',
          'Равным утроенной сумме их наибольших радиусов действия',
        ],
        correctAnswers: ['Равным сумме их наибольших радиусов действия'],
      },
      {
        code: '63677144',
        text:
          'При каком расстоянии металлических конструкций (мостов, путепроводов, светофоров и т. п.) от частей контактной сети, находящихся под напряжением, металлические опоры контактной сети и детали крепления изоляторов контактной сети на железобетонных и каменных искусственных сооружениях и железобетонных опорах, а также приводы секционных разъединителей, нерабочие анкеровочные ветки и грузы компенсаторов, установленные на деревянных опорах, должны быть заземлены?',
        answers: [
          'На расстоянии менее 5 м',
          'На расстоянии 5 м',
          'На расстоянии 5 м и более',
          'На расстоянии более 5 м',
        ],
        correctAnswers: ['На расстоянии менее 5 м'],
      },
      {
        code: '63677145',
        text:
          'С каким максимальным углом наклона должны устраиваться съезды, предназначенные для сообщения между уступами угольного разреза?',
        answers: ['30°', '25°', '20°', '15°', '10°'],
        correctAnswers: ['20°'],
      },
      {
        code: '63677146',
        text:
          'Какой должна быть минимальная ширина рабочей бермы при бурении шпуров перфораторами и электросверлами?',
        answers: ['3,0 м', '3,5 м', '4,0 м', '4,5 м'],
        correctAnswers: ['4,0 м'],
      },
      {
        code: '63677147',
        text:
          'Какое из перечисленных требований к оперативным планам по локализации и ликвидации последствий аварий указано верно?',
        answers: [
          'Оперативный план должен содержать ссылки на пункты ранее разработанных оперативных планов',
          'Оперативный план разрабатывается на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии',
          'Последующий оперативный план должен разрабатываться в случаях, когда мероприятия предыдущего оперативного плана не реализованы',
          'Оперативный план должен разрабатываться после окончания ведения горноспасательных работ',
        ],
        correctAnswers: [
          'Оперативный план разрабатывается на основании поступающей на контрольный пункт информации, анализа аварийной обстановки и прогноза развития аварии',
        ],
      },
      {
        code: '63677148',
        text:
          'Кому из перечисленных лиц разрешается проезд в многоместных кабинах транспортных средств, в железнодорожных составах и кабинах локомотивов?',
        answers: [
          'Только лицам, сопровождающим составы',
          'Только сменному надзору',
          'Только отдельным работникам при наличии у них письменного разрешения технического руководителя (главного инженера) угольного разреза',
          'Всем перечисленным лицам',
        ],
        correctAnswers: ['Всем перечисленным лицам'],
      },
      {
        code: '63677149',
        text:
          'Какая из перечисленных зон при ведении открытых горных работ не должна относиться к зонам, опасным по геомеханическим условиям?',
        answers: [
          'Горный массив с наклонным и пологим залеганием слоистости в сторону выработанного пространства при наличии в призме возможного обрушения тектонических трещин, секущих уступ, протяженностью более 0,25 - 0,30 высоты уступа или ослабленных поверхностей',
          'Участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами',
          'Участок бортов карьера и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)',
          'Участок борта, нагруженный отвалами, размещенными в пределах призмы возможного обрушения',
          'Участок экзогенных пожаров',
        ],
        correctAnswers: ['Участок экзогенных пожаров'],
      },
      {
        code: '63677150',
        text:
          'Что из перечисленного допускается при разработке пород с применением буровзрывных работ при условии, что высота забоя по развалу не превышает максимальную высоту черпания экскаватора?',
        answers: [
          'Только увеличение высоты уступа до полуторной высоты черпания экскаватора',
          'Только разделение развала по высоте на слои (подуступы)',
          'Только разработка мероприятий по безопасному обрушению козырьков и нависей',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63677151',
        text:
          'Что из перечисленного учитывается  инструкцией по эксплуатации железнодорожного транспорта угольного разреза при установлении скорости движения поездов на железнодорожных путях угольного разреза?',
        answers: [
          'Только применяемый подвижной состав',
          'Только верхнее строение и профиль пути',
          'Только местные условия',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63677152',
        text:
          'Какие из перечисленных обязанностей не должен выполнять руководитель горноспасательных работ? Укажите все правильные ответы.',
        answers: [
          'Организует разработку оперативных планов',
          'Организует ведение оперативного журнала ПАСС(Ф)',
          'Организует оказание первой и медицинской помощи пострадавшим',
          'Устанавливает режим работы и отдыха работников ПАСС(Ф) при ведении горноспасательных работ',
        ],
        correctAnswers: [
          'Организует разработку оперативных планов',
          'Организует оказание первой и медицинской помощи пострадавшим',
        ],
      },
      {
        code: '63677153',
        text:
          'На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?',
        answers: ['На 1 год', 'На 2 года', 'На 3 года', 'На 5 лет'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63677154',
        text:
          'С какой периодичностью должно осматриваться каждое рабочее место?',
        answers: [
          'В течение смены - горным мастером, а в течение суток - начальником участка или его заместителем',
          'В течение суток - горным мастером, 1 раз в 2 дня - начальником участка или его заместителем',
          '1 раз в 2 дня - горным мастером, 1 раз в неделю - начальником участка или его заместителем',
          '1 раз в неделю - горным мастером, 1 раз в 2 недели - начальником участка или его заместителем',
        ],
        correctAnswers: [
          'В течение смены - горным мастером, а в течение суток - начальником участка или его заместителем',
        ],
      },
      {
        code: '63677155',
        text:
          'Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью более 10 т при однополосном и двухполосном движении соответственно?',
        answers: [
          'Соответственно не менее 7 м и не менее 16 м',
          'Соответственно не менее 8 м и не менее 18 м',
          'Соответственно не менее 9 м и не менее 20 м',
          'Соответственно не менее 10 м и не менее 22 м',
        ],
        correctAnswers: ['Соответственно не менее 10 м и не менее 22 м'],
      },
      {
        code: '63677156',
        text:
          'Что из перечисленного не соответствует требованиям по обеспечению безопасности движения подвижного состава при ремонте сооружений и устройств?',
        answers: [
          'Запрещается приступать к работам до ограждения сигналами мест производства работ, опасных для следования подвижного состава',
          'Запрещается снимать сигналы, ограждающие места работ, до полного их окончания, а также до проверки состояния пути, контактной сети и соблюдения габарита',
          'Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда',
          'Перед началом путевых ремонтных работ руководитель обязан проинструктировать работников об условиях безопасного производства этих работ и указать места, куда работники должны уходить во время прохода поездов, а также предупредить дежурного по станции и согласовать с ним условия работы',
        ],
        correctAnswers: [
          'Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда',
        ],
      },
      {
        code: '63677157',
        text:
          'Какое требование Правил безопасности при разработке угольных месторождений открытым способом к проведению разгрузочных работ в одном секторе указано верно?',
        answers: [
          'Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами',
          'Расстояние между стоящими на разгрузке, проезжающими транспортными средствами и работающим бульдозером должно быть не менее 3 м',
          'Одновременная работа в одном секторе на отвале бульдозера и автосамосвалов, а на перегрузочном пункте - бульдозера, автосамосвала и экскаватора (погрузчика) должна проводиться по утвержденному регламенту',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами',
        ],
      },
      {
        code: '63677158',
        text:
          'Какой должна быть максимальная высота уступа при разработке вручную рыхлых устойчивых плотных пород?',
        answers: ['3 м', '4 м', '5 м', '6 м', '7 м'],
        correctAnswers: ['6 м'],
      },
      {
        code: '63677159',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом с использованием гидромеханизированного способа разработки?',
        answers: [
          'Проведение в забое вспомогательных работ допускается только после осмотра забоя, ликвидации козырьков и нависей',
          'Углы откоса уступов отработанных угольных разрезов не должны превышать углов естественного откоса пород',
          'Зумпфы землесосов и гидроэлеваторов должны быть закрыты или ограждены в целях исключения падения людей в зумпф',
          'При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора',
        ],
        correctAnswers: [
          'При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора',
        ],
      },
      {
        code: '63677160',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара более 60 т?',
        answers: ['1500 мм', '1600 мм', '1700 мм', '1800 мм', '1900 мм'],
        correctAnswers: ['1800 мм'],
      },
      {
        code: '63677161',
        text:
          'Кто утверждает мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров на угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию?',
        answers: [
          'Командование военизированной горноспасательной части',
          'Руководитель территориального органа Ростехнадзора',
          'Командир пожарной части, на территории обслуживания которой находится разрез',
          'Представитель органов исполнительной власти муниципального образования, на территории которого находится разрез',
          'Технический руководитель (главный инженер) угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63677162',
        text:
          'В каком случае нарушены требования Правил безопасности при разработке угольных месторождений открытым способом по организации ремонта технологического оборудования?',
        answers: [
          'Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает руководитель угольного разреза',
          'На все виды ремонтов основного технологического оборудования в соответствии с действующим на предприятии положением о планово-предупредительных ремонтах должны быть разработаны инструкции (технологические карты, руководства, проекты организации работ)',
          'В технологических картах, руководствах, проектах организации работ указывается количество приспособлений и инструментов, определяются порядок и последовательность работ, обеспечивающих безопасность их проведения',
          'Работники, занятые на ремонте, должны быть ознакомлены с инструкциями, технологическими картами и проектами организации работ под подпись',
        ],
        correctAnswers: [
          'Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает руководитель угольного разреза',
        ],
      },
      {
        code: '63677163',
        text:
          'Что из перечисленного разрешается при работе автомобиля на линии?',
        answers: [
          'Переезд кабелей, уложенных по почве и не огражденных специальными предохранительными устройствами',
          'Перевозка посторонних людей в кабине без разрешения администрации',
          'Выход из кабины автомобиля до полного подъема или опускания кузова',
          'Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса',
          'Нахождение персонала под поднятым, незастопоренным кузовом автосамосвала',
        ],
        correctAnswers: [
          'Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса',
        ],
      },
      {
        code: '63677164',
        text:
          'Какова минимальная периодичность измерения сопротивления общего заземляющего устройства передвижных электроустановок?',
        answers: [
          'Ежемесячно',
          '1 раз в квартал',
          '1 раз в 6 месяцев',
          '1 раз в год',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63677165',
        text:
          'Каков максимально допустимый период времени для отбора проб угля на каждом отрабатываемом пласте угля после его обнажения?',
        answers: ['24 часа', '20 часов', '12 часов', '5 часов'],
        correctAnswers: ['24 часа'],
      },
      {
        code: '63677166',
        text:
          'Каким должно быть расстояние между передвижными опорами временных технологических воздушных линий электропередачи (ВЛ) напряжением до 35 кВ?',
        answers: [
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м',
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 60 м',
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 75 м',
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 90 м',
        ],
        correctAnswers: [
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м',
        ],
      },
      {
        code: '63677167',
        text:
          'На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте до 1200 мм?',
        answers: [
          'На расстоянии не менее 2,0 м',
          'На расстоянии не менее 1,75 м',
          'На расстоянии не менее 1,5 м',
          'На расстоянии не менее 1,25 м',
        ],
        correctAnswers: ['На расстоянии не менее 2,0 м'],
      },
      {
        code: '63677168',
        text:
          'На какое минимальное расстояние от оси пути следует выносить указатели путевого заграждения, располагая их со стороны машиниста локомотива?',
        answers: ['На 1,5 м', 'На 2,5 м', 'На 3,5 м', 'Не нормируется'],
        correctAnswers: ['На 2,5 м'],
      },
      {
        code: '63677169',
        text:
          'До какой максимальной высоты над уровнем земли разрешается использование конвейеров, не оборудованных по всей длине с обеих сторон непрерывными боковыми площадками шириной не менее 0,3 м?',
        answers: [
          'До высоты 0,8 м',
          'До высоты 1,1 м',
          'До высоты 1,3 м',
          'До высоты 1,5 м',
          'До высоты 1,8 м',
        ],
        correctAnswers: ['До высоты 1,8 м'],
      },
      {
        code: '63677170',
        text:
          'Чем из перечисленного в обязательном порядке должен быть оборудован пункт первой медицинской помощи на каждом угольном разрезе?',
        answers: [
          'Телефонной связью',
          'Системами видеонаблюдения',
          'Сатураторной установкой',
          'Не регламентируется',
        ],
        correctAnswers: ['Телефонной связью'],
      },
      {
        code: '63677171',
        text:
          'Что из перечисленного не соответствует требованиям к организации осушения разреза?',
        answers: [
          'Осушение месторождения должно производиться по проекту, утвержденному техническим руководителем (главным инженером) угольного разреза',
          'Каждый угольный разрез, не имеющий естественного стока поверхностных и почвенных вод, должен быть обеспечен водоотливом',
          'Устья стволов дренажных шахт, штолен, шурфов, буровых скважин и других выработок должны быть защищены от проникновения через них в горные выработки поверхностных вод',
          'При наличии на территории угольного разреза оползней поверхность оползневого массива должна быть ограждена нагорными канавами или предохранительными валами, защищающим массив от проникновения в него поверхностных и талых вод, снега, грязевых потоков',
          'Все перечисленное соответствует требованиям к организации осушения разреза',
        ],
        correctAnswers: [
          'Все перечисленное соответствует требованиям к организации осушения разреза',
        ],
      },
      {
        code: '63677172',
        text:
          'Кто осуществляет руководство работами по локализации и ликвидации последствий аварии на разрезе?',
        answers: [
          'Технический руководитель (главный инженер) ОПО',
          'Руководитель горноспасательных работ (РГСР) - должностное лицо ПАСС(Ф), обслуживающего ОПО, назначенное распорядительным документом руководителя ПАСС(Ф)',
          'Руководитель угольного разреза',
          'Представитель территориального органа Ростехнадзора',
          'Должностное лицо ВГСЧ',
        ],
        correctAnswers: ['Технический руководитель (главный инженер) ОПО'],
      },
      {
        code: '63677173',
        text:
          'Каким составом бригады должны выполняться работы по тушению горящего породного отвала?',
        answers: [
          'Не менее чем 2 работниками',
          'Не менее чем 2 работниками под наблюдением ответственного руководителя',
          '3 работников и наблюдающим',
          '1 работником, наблюдающим и ответственным руководителем',
        ],
        correctAnswers: ['Не менее чем 2 работниками'],
      },
      {
        code: '63677174',
        text:
          'В отношении каких видов горных работ планы и схемы развития горных работ не составляются?',
        answers: [
          'Вскрышных и подготовительных',
          'Работ по добыче полезных ископаемых и первичной переработке минерального сырья',
          'Рекультивационных и маркшейдерских',
          'Работ по обустройству транспортной структуры места проведения горных работ',
        ],
        correctAnswers: [
          'Работ по обустройству транспортной структуры места проведения горных работ',
        ],
      },
      {
        code: '63677175',
        text:
          'Что не соответствует требованиям безопасности при эксплуатации горнотранспортных и строительно-дорожных машин?',
        answers: [
          'Работы с использованием горнотранспортных и строительно-дорожных машин должны вестись по документации производства работ, копия которого должна находиться в кабинах этих машин',
          'Копия проекта производства работ должны находиться только у горного мастера смены',
          'Запрещается присутствие посторонних лиц в кабине и на наружных площадках экскаватора и бурового станка при их работе, кроме руководителя смены и лиц, имеющих разрешение технического руководителя (главного инженера) угольного разреза',
          'В случае внезапного прекращения подачи электроэнергии персонал, обслуживающий механизмы, обязан остановить запуск электродвигателей и прекратить передвижение механизмов',
        ],
        correctAnswers: [
          'Копия проекта производства работ должны находиться только у горного мастера смены',
        ],
      },
      {
        code: '63677176',
        text:
          'Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к прокладке трубопроводов?',
        answers: [
          'Трубопроводы укладываются на выровненном основании на подкладках',
          'При прокладке по откосу уступа или борту разреза трубопровод должен быть закреплен анкерами не реже чем через каждые 35 - 40 м по высоте',
          'На каждом прямолинейном участке трубопровода необходимо не реже чем через каждые 750 м устанавливать компенсатор',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Трубопроводы укладываются на выровненном основании на подкладках',
        ],
      },
      {
        code: '63677177',
        text:
          'С какой периодичностью должны проверяться устройства контроля за изменением ширины рельсовых путей и их уклонов?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 2 месяца',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63677178',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации экскаваторов?',
        answers: [
          'В случае выявления угрозы подтопления, обрушения или оползания горных выработок во время работы экскаватора или при обнаружении отказавших зарядов ВМ машинист экскаватора обязан прекратить работу, отвести экскаватор в безопасное место и поставить в известность руководителя смены',
          'Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в 2-3 слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке',
          'При работе экскаватора на грунтах, не выдерживающих давления гусениц, должны приниматься меры, отражаемые в проекте, обеспечивающие его устойчивое положение',
          'Погрузка горной массы экскаватором в забоях с контактными сетями электрифицированного транспорта в думпкары или другие емкости допускается при условии осуществления мероприятий по безопасным методам работы, включая защиту от прикосновения рабочим органом (ковшом) к контактному проводу',
        ],
        correctAnswers: [
          'Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в 2-3 слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке',
        ],
      },
      {
        code: '63677179',
        text:
          'Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?',
        answers: [
          'Лица, ответственные за руководство горными работами пользователя недр',
          'Лица, ответственные за руководство маркшейдерскими работами',
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
          'Лица, ответственные за руководство геологическими работами, и представитель Ростехнадзора',
        ],
        correctAnswers: [
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63677180',
        text:
          'Какое минимальное расстояние по горизонтали должно быть между рабочими местами, расположенными на двух смежных по вертикали уступах, при ручной разработке?',
        answers: ['4 м', '6 м', '8 м', '10 м', '12 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63677181',
        text:
          'Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации и обслуживанию технологического оборудования, технических устройств, машин и механизмов, используемых на разрезе?',
        answers: [
          'Эксплуатация, обслуживание технологического оборудования, технических устройств, транспортирование и хранение машин и оборудования, их узлов и деталей, а также их монтаж и демонтаж должны производиться в соответствии с заводской документацией и технологическими картами (при наличии), утвержденными техническим руководителем (главным инженером) угольного разреза',
          'Технические устройства, в том числе иностранного производства, должны иметь паспорта, руководства (инструкции) по монтажу, эксплуатации, техническому обслуживанию и ремонту, оформленные на русском языке, с указанием метрической системы измерений',
          'Нормируемые заводами-изготовителями технические характеристики должны выдерживаться на протяжении всего периода эксплуатации оборудования',
          'Все перечисленные утверждения',
        ],
        correctAnswers: ['Все перечисленные утверждения'],
      },
      {
        code: '63677182',
        text:
          'При каком виде аварии члены вспомогательной горноспасательной команды устанавливают связь с застигнутыми аварией людьми, организуют их спасение, предварительно усилив крепь, тем самым исключив возможные обрушения?',
        answers: [
          'При обрушении в горной выработке',
          'При пожаре в тупиковой выработке',
          'При взрыве метана и (или) угольной пыли',
          'При внезапном выбросе угля (породы)',
          'Все ответы неверны',
        ],
        correctAnswers: ['При обрушении в горной выработке'],
      },
      {
        code: '63677183',
        text:
          'Каким должно быть расстояние между концом отвальной консоли транспортно-отвального моста и гребнем отвала?',
        answers: [
          'Не менее 1,0 м',
          'Не менее 1,5 м',
          'Не менее 2,0 м',
          'Не менее 3,0 м',
        ],
        correctAnswers: ['Не менее 3,0 м'],
      },
      {
        code: '63677184',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Горнотранспортное оборудование должно разгружаться на отвале в местах, предусмотренных проектом, вне призмы обрушения (сползания) породы',
          'Размеры призмы обрушения породы устанавливаются визуально водителями автомобилей',
          'На отвалах должны устанавливаться схемы движения транспортных средств',
          'Зона разгрузки должна быть обозначена с обеих сторон знаками в виде изображения автосамосвала с поднятым кузовом с указателями направления разгрузки',
        ],
        correctAnswers: [
          'Размеры призмы обрушения породы устанавливаются визуально водителями автомобилей',
        ],
      },
      {
        code: '63677185',
        text:
          'Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при однополосном движении?',
        answers: [
          'Не менее 4,5 м',
          'Не менее 5,5 м',
          'Не менее 6,5 м',
          'Не менее 7,5 м',
        ],
        correctAnswers: ['Не менее 7,5 м'],
      },
      {
        code: '63677186',
        text:
          'Каков максимальный угол наклона предусмотрен для лестниц, предназначенных для сообщения между уступами угольного разреза?',
        answers: ['75°', '70°', '65°', '60°', '55°'],
        correctAnswers: ['60°'],
      },
      {
        code: '63677187',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по созданию безопасных условий труда при эксплуатации водосбросных и водосборных сооружений?',
        answers: [
          'Помещения насосных и землесосных установок должны иметь средства связи с местом установки гидромониторов и быть оборудованы аварийной сигнализацией',
          'Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м',
          'На землесосных установках пульповоды и водоводы должны быть оборудованы обратными клапанами',
          'Все водосбросные и водосборные сооружения гидроотвалов следует рассчитывать на максимально возможный приток. По окончании работ с использованием средств гидромеханизации должны быть осмотрены все водосбросные сооружения и составлены документы об их состоянии',
        ],
        correctAnswers: [
          'Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м',
        ],
      },
      {
        code: '63677188',
        text:
          'С каким минимальным номинальным временем защитного действия допускается использование дыхательных аппаратов со сжатым воздухом на объектах открытых горных работ?',
        answers: ['1 час', '2 часа', '4 часа', '3 часа'],
        correctAnswers: ['1 час'],
      },
      {
        code: '63677189',
        text:
          'Какой должна быть длина надводного пляжа в течение всего срока эксплуатации намывных гидроотвалов?',
        answers: [
          'Не менее 50 м',
          'Не менее 40 м',
          'Не менее 30 м',
          'Должна соответствовать проекту разработки месторождения',
          'Должна определяться опытным путем',
        ],
        correctAnswers: [
          'Должна соответствовать проекту разработки месторождения',
        ],
      },
      {
        code: '63677190',
        text:
          'Какие их перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны неверно? Выберите два правильных варианта ответа.',
        answers: [
          'Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м',
          'Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м',
          'Рабочие площадки приемных и разгрузочных устройств и бункеров обязательно оборудуются звуковой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожного состава',
          'Сигналы о прибытии железнодорожного состава подаются за 1,5 - 2 минуты до момента прибытия транспортных средств.',
          'На приемных бункерах должен быть установлен светофор, разрешающий или запрещающий въезд железнодорожного состава или транспортного средства на площадку бункера под разгрузку',
        ],
        correctAnswers: [
          'Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м',
          'Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м',
        ],
      },
      {
        code: '63677191',
        text:
          'Какие действия не соответствуют требованиям безопасности при разработке полезных ископаемых с повышенным радиационным фоном?',
        answers: [
          'На угольных разрезах, разрабатывающих сопутствующие полезные ископаемые с повышенным радиационным фоном, должен осуществляться радиационный контроль. Результаты замеров радиационного фона фиксируются в журнале учета радиационного фона',
          'Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки',
          'Для контроля уровня радиоактивности грунтовых вод должны быть предусмотрены пробоотборные (наблюдательные) скважины по периметру гидроотвала и по направлению потока грунтовых вод',
          'Местоположение и число пробоотборных (наблюдательных) скважин определяются в зависимости от гидрогеологических условий с таким расчетом, чтобы расстояние между скважинами было не менее 300 м. При этом 2 скважины должны быть за пределами санитарно-защитной зоны',
        ],
        correctAnswers: [
          'Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки',
        ],
      },
      {
        code: '63677192',
        text:
          'Решение каких задач не преследуется при проведении работ по осушению территории производства работ?',
        answers: [
          'Обеспечение устойчивости откосов горных выработок и отвалов',
          'Снижение влажности полезных ископаемых и вскрышных пород',
          'Создание безопасных условий работы горно-транспортного оборудования',
          'Создание благоприятных условий для последующей рекультивации земель',
        ],
        correctAnswers: [
          'Создание благоприятных условий для последующей рекультивации земель',
        ],
      },
      {
        code: '63677193',
        text:
          'Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указано неверно?',
        answers: [
          'Движущиеся части оборудования, представляющие собой источник опасности для людей, должны быть ограждены, за исключением частей, ограждение которых невозможно из-за их функционального назначения',
          'Перед началом работы или движения машины (механизма) машинист обязан убедиться в безопасности членов бригады и находящихся поблизости лиц',
          'Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне',
          'Перед началом движения машин, железнодорожных составов, транспортных средств, погрузочной техники обязательна подача звуковых или световых предупредительных сигналов, разработанных угольным разрезом, со значением которых должны быть ознакомлены все работающие. При этом сигналы должны быть слышны (видны) всем работникам в зоне действия машин (механизмов)',
          'Таблица предупредительных сигналов должна располагаться на работающем механизме или вблизи него. При неправильно поданном или непонятном предупредительном сигнале машины (механизмы) должны быть остановлены до устранения данного нарушения',
        ],
        correctAnswers: [
          'Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне',
        ],
      },
      {
        code: '63677194',
        text:
          'Каким должно быть расстояние от нижнего фазного провода воздушной линии электропередачи (ВЛ) напряжением 35 кВ до верхней точки машин или груза при пересечении (сближении) воздушной линии электропередачи (ВЛ) с автомобильными дорогами?',
        answers: [
          'Не менее 2,5 м',
          'Не менее 2,0 м',
          'Не менее 1,5 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 2,5 м'],
      },
      {
        code: '63677195',
        text:
          'На какую деятельность на опасных производственных объектах юридических лиц и индивидуальных предпринимателей распространяются Правила безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Только на деятельность, связанную с разработкой угольных месторождений открытым способом (далее - угольный разрез)',
          'Только на проектирование, строительство и эксплуатацию угольного разреза',
          'Только на конструирование, изготовление, монтаж, эксплуатацию и ремонт технических устройств',
          'На все перечисленные виды деятельности',
        ],
        correctAnswers: ['На все перечисленные виды деятельности'],
      },
      {
        code: '63677196',
        text:
          'С кем согласовываются графики проведения учебных тревог на угольном разрезе?',
        answers: [
          'С руководителем территориального органа Ростехнадзора',
          'С руководителем органа местного самоуправления, на территории которого располагается разрез',
          'С руководителем профессионального аварийно-спасательного формирования (ПАСС(Ф)), обслуживающего угольный разрез',
          'С руководителем профсоюзной организации угольного разреза',
        ],
        correctAnswers: [
          'С руководителем профессионального аварийно-спасательного формирования (ПАСС(Ф)), обслуживающего угольный разрез',
        ],
      },
      {
        code: '63677197',
        text:
          'Каким должно быть расстояние от нижней бровки уступа (развала горной массы) и от верхней бровки уступа до оси ближайшего железнодорожного пути?',
        answers: [
          'Не менее 2,5 м',
          'Не менее 2,2 м',
          'Не менее 1,8 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 2,5 м'],
      },
      {
        code: '63677198',
        text:
          'Как должен располагаться привод ходовой тележки при передвижении гусеничного экскаватора по горизонтальному участку или на подъем и при спуске?',
        answers: [
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди',
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться впереди, а при спусках с уклона - сзади',
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться сзади',
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться впереди',
        ],
        correctAnswers: [
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди',
        ],
      },
      {
        code: '63677199',
        text:
          'На каком расстоянии от конца рельсов устанавливаются предохранительные упоры на забойных и отвальных железнодорожных путях?',
        answers: [
          'На расстоянии не менее 4 м от конца рельсов',
          'На расстоянии не менее 6 м от конца рельсов',
          'На расстоянии не менее 8 м от конца рельсов',
          'На расстоянии не менее 10 м от конца рельсов',
        ],
        correctAnswers: ['На расстоянии не менее 10 м от конца рельсов'],
      },
      {
        code: '63677200',
        text:
          'Какими средствами индивидуальной защиты органов дыхания (СИЗОД) должен быть обеспечен обслуживающий персонал на рабочих местах, где концентрация пыли превышает установленные предельно допустимые концентрации (ПДК) содержание пыли и вредных газов?',
        answers: [
          'Респираторами',
          'Фильтрующими противогазами',
          'Изолирующими противогазами',
          'Шланговыми противогазами',
        ],
        correctAnswers: ['Изолирующими противогазами'],
      },
      {
        code: '63677201',
        text:
          'С какой периодичностью должна проверяться исправность и комплектность машин машинистом (оператором, водителем)?',
        answers: [
          'Ежемесячно',
          '1 раз в 2 месяца',
          '1 раз в квартал',
          '1 раз в полугодие',
          'Ежесменно',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63677202',
        text:
          'Каким должно быть минимальное расстояние между стоящими на разгрузке и проезжающими транспортными средствами?',
        answers: ['2 м', '3 м', '4 м', '5 м', '6 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63677203',
        text:
          'Какие технические решения должны предусматриваться в проекте для реализации мер по осушению территории производства работ?',
        answers: [
          'Только решения по понижению уровня подземных вод (при необходимости)',
          'Только решения по строительству сооружений для отвода воды за пределы зоны влияния дренажной системы',
          'Только решения по ограждению сооружений, горных выработок и отвалов от поверхностных вод и атмосферных осадков',
          'Все перечисленные решения',
        ],
        correctAnswers: ['Все перечисленные решения'],
      },
      {
        code: '63677204',
        text:
          'Кем утверждается документация, по которой должны производиться ремонты по восстановлению несущих металлоконструкций?',
        answers: [
          'Техническим руководителем завода-изготовителя',
          'Представителем территориального органа Ростехнадзора',
          'Техническим руководителем (главным инженером) угольного разреза',
          'Руководителем проектной организации',
        ],
        correctAnswers: [
          'Техническим руководителем (главным инженером) угольного разреза',
        ],
      },
      {
        code: '63677205',
        text:
          'Какая устанавливается максимальная продолжительность пребывания работников ПАСС(Ф) и членов вспомогательной горноспасательной команды в непригодной для дыхания рудничной атмосфере с применением дыхательных аппаратов?',
        answers: ['4 часа', '5 часов', '2 часа', '3 часа'],
        correctAnswers: ['4 часа'],
      },
      {
        code: '63677206',
        text:
          'Каким образом устанавливаются предельные углы откосов уступов и бортов угольного разреза (углы устойчивого борта), в том числе временно консервируемых участков бортов?',
        answers: [
          'Техническим проектом разработки месторождения',
          'Планом мероприятий по локализации и ликвидации последствий аварий угольного разреза (ПЛА)',
          'Локальным проектом, утвержденным техническим руководителем (главным инженером) угольного разреза',
        ],
        correctAnswers: ['Техническим проектом разработки месторождения'],
      },
      {
        code: '63677207',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для колеи 900 мм?',
        answers: ['1300 мм', '1400 мм', '1500 мм', '1600 мм', '1700 мм'],
        correctAnswers: ['1300 мм'],
      },
      {
        code: '63677208',
        text:
          'По какому из перечисленных проектов следует проводить горные работы вблизи затопленных выработок или водоемов ниже зеркала воды при их максимальном наполнении?',
        answers: [
          'По техническому проекту разработки месторождения и нормативными требованиями к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды',
          'По проекту, предусматривающему сооружение вокруг       затопленных выработок или водоемов       обваловки',
          'По проекту, предусматривающему сооружение в пониженных местах дамб',
          'По проекту, предусматривающему установку откачивающих насосов достаточной производительности',
        ],
        correctAnswers: [
          'По техническому проекту разработки месторождения и нормативными требованиями к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды',
        ],
      },
      {
        code: '63677209',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами',
          'Наименования организации, объекта недропользования (участка недр)',
          'Дата составления',
          'Все ответы неверны',
          'Название графического документа',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63677210',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе локального плана проведения работ',
          'На основе технического регламента',
          'На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России',
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63677211',
        text:
          'С каким максимальным интервалом должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 ‰?',
        answers: [
          'Через каждые 300 м длины затяжного уклона',
          'Через каждые 400 м длины затяжного уклона',
          'Через каждые 500 м длины затяжного уклона',
          'Через каждые 600 м длины затяжного уклона',
          'Через каждые 700 м длины затяжного уклона',
        ],
        correctAnswers: ['Через каждые 600 м длины затяжного уклона'],
      },
      {
        code: '63677212',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к канатам подвески стрелы экскаваторов, их осмотру и допуску к эксплуатации?',
        answers: [
          'Канаты подлежат осмотру не реже 1 раза в месяц механиком участка',
          'На длине шага свивки допускается не более 15 % порванных проволок от их общего числа в канате',
          'Торчащие концы оборванных проволок должны быть отрезаны',
          'Подъемные, тяговые и напорные канаты должны осматриваться в сроки, установленные заводом-изготовителем',
        ],
        correctAnswers: [
          'Канаты подлежат осмотру не реже 1 раза в месяц механиком участка',
        ],
      },
      {
        code: '63677213',
        text:
          'Что не соответствует требованиям к сбросу воды, удаляемой из разреза, а также сбросу воды, полученной в результате осушения месторождения?',
        answers: [
          'Вода, удаляемая из разреза, должна сбрасываться в ближайший водоток или в место, исключающее возможность ее обратного проникновения через трещины, провалы или водопроницаемые породы в выработки и заболачивание прилегающих территорий',
          'Сброс вод, полученных в результате осушения месторождения, должен производиться только после их осветления, а в необходимых случаях - после очистки от вредных примесей',
          'Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы должны быть окрашены в яркий цвет и на них должны быть нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом',
        ],
        correctAnswers: [
          'Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы должны быть окрашены в яркий цвет и на них должны быть нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом',
        ],
      },
      {
        code: '63677214',
        text:
          'Какой должна быть высота подвески контактного провода над уровнем головки рельса на передвижных железнодорожных путях при боковой подвеске?',
        answers: [
          'Не менее 3800 мм',
          'Не менее 4000 мм',
          'Не менее 4200 мм',
          'Не менее 4400 мм',
        ],
        correctAnswers: ['Не менее 4400 мм'],
      },
      {
        code: '63677215',
        text:
          'В каком случае при работе на отвале нарушены требования Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Подача автосамосвала на разгрузку должна осуществляться задним ходом',
          'Работа бульдозера должна производиться перпендикулярно верхней бровке откоса площадки',
          'Движение бульдозера во время работы должно производиться только ножом вперед с одновременным формированием перед отвалом бульдозера предохранительного вала в соответствии с проектом',
          'При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса',
        ],
        correctAnswers: [
          'При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса',
        ],
      },
    ],
  },
  8: {
    63695: [
      {
        code: '63695000',
        text:
          'Кому должно направить лицо, проводившее техническое освидетельствование, информацию о необходимости проведения внеочередного технического освидетельствования всего оборудования под давлением, эксплуатируемого в аналогичном режиме, в случае если при анализе (оценке характера, размеров и причин возникновения) дефектов, выявленных при техническом освидетельствовании оборудования под давлением, установлено, что их возникновение обусловлено режимом эксплуатации оборудования в данной эксплуатирующей организации или особенностями (недостатками) конструкции данного типа оборудования?',
        answers: [
          'Руководителю эксплуатирующей организации',
          'В территориальный орган Ростехнадзора',
          'В организацию-изготовителя этого оборудования',
          'В организацию, оформившую документ о подтверждении соответствия этого оборудования требованиям ТР ТС 032/2013',
        ],
        correctAnswers: ['Руководителю эксплуатирующей организации'],
      },
      {
        code: '63695001',
        text:
          'Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте менее 2 м от уровня площадки наблюдения?',
        answers: ['50 мм', '100 мм', '160 мм', '200 мм'],
        correctAnswers: ['100 мм'],
      },
      {
        code: '63695002',
        text:
          'Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте от 2 до 3 м включительно от уровня площадки наблюдения?',
        answers: ['50 мм', '100 мм', '160 мм', '200 мм'],
        correctAnswers: ['160 мм'],
      },
      {
        code: '63695003',
        text:
          'В каком из приведенных случаев манометр может быть допущен к применению на сосуде?',
        answers: [
          'Если отсутствует информация о проведении поверки (пломба или клеймо, или документ о проведении поверки)',
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
          'Если разбито стекло или имеются другие повреждения манометра, которые могут отразиться на правильности его показаний',
          'Во всех приведенных случаях манометр не допускается к применению',
        ],
        correctAnswers: [
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
        ],
      },
      {
        code: '63695004',
        text:
          'Какие размеры внутреннего пространства (по диаметру или высоте в зависимости от геометрической формы корпуса) должны иметь многоместные медицинские барокамеры, применяемые в МО?',
        answers: [
          'Не менее 1800 мм',
          'Не менее 2000 мм',
          'Не менее 2200 мм',
          'Не менее 2500 мм',
        ],
        correctAnswers: ['Не менее 1800 мм'],
      },
      {
        code: '63695005',
        text:
          'В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся ответственными лицами или комиссией с их участием?',
        answers: [
          'При передаче сосуда для использования другой эксплуатирующей организации',
          'После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде',
          'После капитального ремонта сосуда, связанного с заменой основных элементов',
          'После монтажа сосуда, для которого ТР ТС 032/2013 не предусмотрена процедура подтверждения соответствия',
        ],
        correctAnswers: [
          'После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде',
        ],
      },
      {
        code: '63695006',
        text: 'Кто осуществляет проверку барокамеры в действии после монтажа?',
        answers: [
          'Комиссия, состав которой определяется распорядительным документом эксплуатирующей организации',
          'Специализированная организация',
          'Ответственные специалисты эксплуатирующей организации',
          'Монтажная организация',
          'Изготовитель и монтажная организация совместно',
        ],
        correctAnswers: [
          'Комиссия, состав которой определяется распорядительным документом эксплуатирующей организации',
        ],
      },
      {
        code: '63695007',
        text:
          'Какое из требований к установке на водолазной барокамере предохранительного клапана указано неверно?',
        answers: [
          'Предохранительные клапаны отсеков водолазной барокамеры должны быть подключены с помощью запорного клапана, обеспечивающего мгновенное запирание барокамеры в случае отказа предохранительного клапана (неправильного срабатывания)',
          'Предохранительные клапаны отсеков водолазной барокамеры должны быть присоединены напрямую к сбросным патрубкам, установка запорной арматуры до и после предохранительного клапана не допускается',
          'Для сброса газов из барокамеры в барозале должны быть проложены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала',
        ],
        correctAnswers: [
          'Предохранительные клапаны отсеков водолазной барокамеры должны быть присоединены напрямую к сбросным патрубкам, установка запорной арматуры до и после предохранительного клапана не допускается',
        ],
      },
      {
        code: '63695008',
        text:
          'На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?',
        answers: [
          'На основании приказа руководителя эксплуатирующей организации',
          'На основании письменного распоряжения ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
        ],
        correctAnswers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
        ],
      },
      {
        code: '63695009',
        text:
          'На каком сосуде установка манометра и предохранительного клапана необязательна?',
        answers: [
          'На сосуде, включенном в технологический процесс, в котором давление рабочей среды поддерживается на постоянном уровне и при условии исключения возможности повышения давления в сосуде',
          'На сосуде, у которого рабочее давление равно или больше давления питающего источника, и при условии исключения возможности повышения давления в сосуде',
          'На сосуде, включенном в группу сосудов, при эксплуатации которой манометр и предохранительный клапан установлены на подводящем трубопроводе до первого ответвления к одному из сосудов и при условии исключения возможности повышения давления в сосуде (обогрев, химическая реакция пожар).',
          'Манометр и предохранительный клапан обязательно должны быть установлены на каждом сосуде',
        ],
        correctAnswers: [
          'На сосуде, у которого рабочее давление равно или больше давления питающего источника, и при условии исключения возможности повышения давления в сосуде',
        ],
      },
      {
        code: '63695010',
        text:
          'Какое требование к проверке исправности манометра, установленного на сосуде, указано неверно?',
        answers: [
          'Проверку исправности манометра производят с помощью трехходового крана или заменяющих его запорных вентилей путем установки стрелки манометра на нуль',
          'Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности',
          'Не реже одного раза в 12 месяцев (если иные сроки не установлены документацией на конкретный манометр) манометры должны быть поверены в установленном порядке',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности',
        ],
      },
      {
        code: '63695011',
        text:
          'Какое из приведенных требований к манометрам, устанавливаемым на сосудах, указано неверно?',
        answers: [
          'На шкале манометра сосуда должна быть нанесена красная черта, указывающая расчетное давление в сосуде',
          'Взамен красной черты разрешается прикреплять к корпусу манометра пластину (скобу), окрашенную в красный цвет и плотно прилегающую к стеклу манометра',
          'Манометр должен быть выбран с такой шкалой, чтобы предел измерения рабочего давления находился во второй трети шкалы',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'На шкале манометра сосуда должна быть нанесена красная черта, указывающая расчетное давление в сосуде',
        ],
      },
      {
        code: '63695012',
        text:
          'Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?',
        answers: [
          'Уполномоченный представитель Ростехнадзора',
          'Уполномоченный представитель Ростехнадзора принимает решение о вводе в эксплуатацию сосудов, подлежащих учету в органах Ростехнадзора, в остальных случаях решение принимает ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
          'Ответственный за исправное состояние и безопасную эксплуатацию сосуда',
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
        ],
        correctAnswers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
        ],
      },
      {
        code: '63695013',
        text:
          'В каком случае не предусматривается устройство (регулятор), обеспечивающее достаточный противоподпор (сопротивление) на выдохе для недопущения травмы водолазов, в случае использования в барокамерах дыхательных масок?',
        answers: [
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере до 0,1 МПа',
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере от 0,2 МПа до 0,6 МПа',
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере выше 0,3 МПа',
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере выше 0,4 МПа',
          'Должно быть предусмотрено во всех случаях',
        ],
        correctAnswers: [
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере до 0,1 МПа',
        ],
      },
      {
        code: '63695014',
        text:
          'Чему равно минимальное значение времени выдержки сосуда под пробным давлением при пневматическом испытании?',
        answers: [
          '5 минут',
          '15 минут',
          '10 минут',
          'Время выдержки сосуда определяется временем естественного падения давления, определяемого по манометру, от значения пробного давления до значения рабочего давления',
        ],
        correctAnswers: ['15 минут'],
      },
      {
        code: '63695015',
        text:
          'Кто должен представить специалистам организации, проводящей техническое диагностирование медицинской барокамеры, полный комплект технической документации на барокамеру?',
        answers: [
          'Ответственный за исправное состояние барокамеры',
          'Дежурный персонал',
          'Руководитель (технический руководитель) организации-изготовителя',
          'Руководитель эксплуатирующей организации.',
        ],
        correctAnswers: ['Ответственный за исправное состояние барокамеры'],
      },
      {
        code: '63695016',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см²? Где в формулах: Рраб – рабочее давление сосуда, Р расч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,3 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,3 Рраcч ([σ]20 / [σ]t)',
          'Рпр = 1,6 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,6 Рраб',
        ],
        correctAnswers: ['Рпр = 1,3 Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63695017',
        text:
          'В каком случае в состав комиссии по проверке готовности сосуда к пуску в работу и организации надзора за его эксплуатацией включаются уполномоченный (уполномоченные) представитель (представители) Ростехнадзора или его территориального органа?',
        answers: [
          'При осуществлении проверок сосудов с огневым обогревом и сосудов, работающих с рабочей средой, отнесенной в соответствии с ТР ТС 032/2013 к группе 1',
          'Исключительно по инициативе руководителя эксплуатирующей организации',
          'При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора',
          'Не регламентируется',
        ],
        correctAnswers: [
          'При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора',
        ],
      },
      {
        code: '63695018',
        text:
          'Какая организация может осуществлять монтаж, наладку, техническое обслуживание и ремонт медицинских барокамер?',
        answers: [
          'Специализированная организация, имеющая лицензию на техническое обслуживание медицинской техники',
          'Специализированная организация, соответствующая требованиям раздела IV ФНП ОРПД и имеющая лицензию на право проведения экспертизы промышленной безопасности барокамер',
          'Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники',
          'Специализированная организация, соответствующая требованиям раздела IV ФНП ОРПД',
          'Специализированная организация, имеющая лицензию на право проведения экспертизы промышленной безопасности барокамер',
        ],
        correctAnswers: [
          'Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники',
        ],
      },
      {
        code: '63695019',
        text:
          'Каким классом точности должны быть манометры (пневмоглубиномеры) барокамеры?',
        answers: ['Не ниже 0,6', 'Не ниже 1,0', 'Не ниже 1,5', 'Не ниже 0,4'],
        correctAnswers: ['Не ниже 0,6'],
      },
      {
        code: '63695020',
        text:
          'Какое из приведенных требований к оснащению сосуда, рассчитанного на давление, меньше давления питающего его источника указано неверно?',
        answers: [
          'На подводящем трубопроводе необходима установка автоматического редуцирующего устройства с манометром и предохранительным устройством, установленными на стороне сниженного давления',
          'На общем подводящем трубопроводе для группы сосудов, работающих при одном и том же давлении, допускается установка редуцирующего устройства с манометром и предохранительным клапаном до первого ответвления к одному из сосудов',
          'На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов',
        ],
        correctAnswers: [
          'На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов',
        ],
      },
      {
        code: '63695021',
        text:
          'Где должен размещаться пульт управления подачей газа многоместной барокамеры?',
        answers: [
          'Внутри одного из отсеков, в случае если назначением многоместной барокамеры предусмотрено совместное нахождение в ней врача и пациентов',
          'Вне многоместной барокамеры',
          'В месте, определенном проектом',
        ],
        correctAnswers: ['Вне многоместной барокамеры'],
      },
      {
        code: '63695022',
        text:
          'Выполнение каких из перечисленных процедур не предусматривается при проверке знаний и допуске работника к самостоятельной работе?',
        answers: [
          'Предусматривается выполнение всех перечисленных процедур',
          'Проведение вводного инструктажа',
          'Проведение первичного инструктажа на рабочем месте',
          'Допуск к самостоятельной работе с выдачей удостоверения',
          'Проверка знаний инструкций',
        ],
        correctAnswers: [
          'Предусматривается выполнение всех перечисленных процедур',
        ],
      },
      {
        code: '63695023',
        text:
          'Какие из приведенных мест не подлежат оборудованию аварийным освещением?',
        answers: [
          'Щиты и пульты управления',
          'Вентиляторные площадки',
          'Места установки насосного оборудования',
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
        correctAnswers: [
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
      },
      {
        code: '63695024',
        text:
          'Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением менее 0,3 МПа?',
        answers: [
          'Превышающее разрешенное давление на 0,05 МПа',
          'Превышающее разрешенное давление на 10 %',
          'Превышающее разрешенное давление на 15 %',
          'Превышающее разрешенное давление на 25 %',
        ],
        correctAnswers: ['Превышающее разрешенное давление на 0,05 МПа'],
      },
      {
        code: '63695025',
        text:
          'В каких случаях допускается предусматривать сброс газов кислорода или его смесей из барокамеры в помещение барозала?',
        answers: [
          'Если суммарная вместимость (м³) барокамер не превышает 30 % объема помещения барозала, в котором они установлены',
          'Если суммарная вместимость (м³) барокамер не превышает 10 % объема помещения барозала, в котором они установлены',
          'Если в помещении барозала установлено не более двух одноместных барокамер или одна многоместная вместимостью не более 10 м³.',
          'При условии, если проектом предусмотрены легкосбрасываемые конструкции, обеспечивающие сброс сжатого газа, или произведен соответствующий расчет площади оконных проемов',
          'Для сброса газов из барокамеры в барозале должны быть проложены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала',
        ],
        correctAnswers: [
          'Для сброса газов из барокамеры в барозале должны быть проложены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала',
        ],
      },
      {
        code: '63695026',
        text:
          'Кто может записывать в паспорт оборудования сведения о результатах и причинах проведения технического диагностирования, неразрушающего и разрушающего контроля? Выберите 2 варианта ответа.',
        answers: [
          'Уполномоченный представитель организации, их проводившей',
          'Специалист эксплуатирующей организации, ответственный за исправное состояние и безопасную эксплуатацию оборудования',
          'Инспектор Ростехнадзора',
          'Технический руководитель эксплуатирующей организации',
        ],
        correctAnswers: [
          'Уполномоченный представитель организации, их проводившей',
          'Специалист эксплуатирующей организации, ответственный за исправное состояние и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63695027',
        text:
          'Какое из требований к помещению для установки водолазных барокамер указано неверно?',
        answers: [
          'Помещение установки барокамер должно обеспечивать возможность нахождения в нем водолазов и обслуживающего персонала, исходя из вместимости барокамеры и штатного расписания обслуживающего персонала',
          'Все окна и двери в помещении барокамеры (барозале) должны открываться наружу, при этом необходимо производить расчет площади окон и дверей в случае, если проектом предусмотрен сброс сжатого газа из барокамер в помещение барозала',
          'Барозалы должны оснащаться системами связи, пожарной сигнализации, необходимыми системами газового анализа (сигнализаторами)',
          'Для сброса газов из барокамеры в барозале должны быть проложены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала, при этом не допускается совмещать сбросные трубопроводы воздуха и кислорода',
        ],
        correctAnswers: [
          'Все окна и двери в помещении барокамеры (барозале) должны открываться наружу, при этом необходимо производить расчет площади окон и дверей в случае, если проектом предусмотрен сброс сжатого газа из барокамер в помещение барозала',
        ],
      },
      {
        code: '63695028',
        text:
          'На какой из приведенных сосудов, работающих под давлением свыше 0,07 МПа, распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Сосуд вместимостью 10 м³ с радиоактивной средой давлением 1,5 МПа',
          'Прибор парового отопления',
          'Барокамера вместимостью 0,8 м³, работающая с воздухом под давлением 0,1 МПа',
          'Сосуд, установленный на самолете',
        ],
        correctAnswers: [
          'Барокамера вместимостью 0,8 м³, работающая с воздухом под давлением 0,1 МПа',
        ],
      },
      {
        code: '63695029',
        text:
          'С какой целью в ФНП установлены требования к геометрическим размерам одноместной медицинской барокамеры?',
        answers: [
          'Требования к размерам барокамеры установлены для обеспечения типовых проектных решений к устройству помещений, в которых устанавливается барокамеры',
          'Геометрические размеры барокамеры должны обеспечивать безопасное нахождение размещаемого внутри камеры человека',
          'Геометрические размеры барокамеры определяются технологическими особенностями предприятия изготовителя',
        ],
        correctAnswers: [
          'Геометрические размеры барокамеры должны обеспечивать безопасное нахождение размещаемого внутри камеры человека',
        ],
      },
      {
        code: '63695030',
        text:
          'При каком минимальном избыточном давлении в сосуде (барокамере) допускается открытие ее люка и проведение ремонта сосуда и его элементов?',
        answers: [
          'Не допускается открывание люка барокамеры и ремонт при наличии в ней давления',
          '0,05 МПа',
          'Для сосудов, подлежащих учету в территориальных органах Ростехнадзора, не допускается проведение ремонта сосудов и их элементов, находящихся под давлением, для остальных сосудов – 0,05 МПа',
          '0,025 МПа',
        ],
        correctAnswers: [
          'Не допускается открывание люка барокамеры и ремонт при наличии в ней давления',
        ],
      },
      {
        code: '63695031',
        text:
          'Требования какого документа должны обеспечиваться при изготовлении одноместных медицинских барокамер?',
        answers: [
          'Одноместные медицинские барокамеры должны соответствовать требованиям технического регламента ТР ТС 032/2013',
          'Одноместные медицинские барокамеры должны быть изготовлены в соответствии с требованиями ФНП',
          'Одноместные медицинские барокамеры должны быть изготовлены в соответствии с требованиями технического регламента ТР ТС 032/2013 и ФНП',
        ],
        correctAnswers: [
          'Одноместные медицинские барокамеры должны быть изготовлены в соответствии с требованиями ФНП',
        ],
      },
      {
        code: '63695032',
        text:
          'Кем подписывается удостоверение (свидетельство) о качестве монтажа?',
        answers: [
          'Всеми перечисленными лицами',
          'Руководителями (техническим руководителями) или уполномоченными должностными лицами монтажной организации',
          'Руководителями (техническим руководителями) или уполномоченными должностными лицами эксплуатирующей организации (или ее обособленного структурного подразделения)',
          'Уполномоченным представителем организации разработчика проекта или организации-изготовителя, осуществлявшего авторский надзор (шефмонтаж) за выполнением работ',
        ],
        correctAnswers: ['Всеми перечисленными лицами'],
      },
      {
        code: '63695033',
        text:
          'Порядок применения многоместных медицинских барокамер, выпущенных до вступления в силу технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'При эксплуатации барокамер, изготовленных и введенных в эксплуатацию до вступления в силу ТР ТС 032/2013, должно обеспечиваться их соответствие требованиям технической документации предприятия-изготовителя',
          'Барокамеры, изготовленные и введенные в эксплуатацию до вступления в силу ТР ТС 032/2013, должны пройти подтверждение соответствия требованиям ТР ТС 032/2013',
          'Барокамеры, изготовленные и введенные в эксплуатацию до вступления в силу ТР ТС 032/2013, должны пройти экспертизу промышленной безопасности для подтверждения соответствия требованиям ТР ТС 032/2013',
          'Барокамеры, изготовленные и введенные в эксплуатацию до вступления в силу ТР ТС 032/2013, должны быть выведены из эксплуатации и направлены на завод-изготовитель для приведения их в соответствие требованиям ТР ТС 032/2013',
        ],
        correctAnswers: [
          'При эксплуатации барокамер, изготовленных и введенных в эксплуатацию до вступления в силу ТР ТС 032/2013, должно обеспечиваться их соответствие требованиям технической документации предприятия-изготовителя',
        ],
      },
      {
        code: '63695034',
        text:
          'Какая из перечисленных обязанностей организации, эксплуатирующей медицинские барокамеры, указана неверно?',
        answers: [
          'Укомплектовать подразделение ГБО персоналом',
          'Установить порядок безопасного допуска пациентов в помещение барозала и их нахождения непосредственно в барокамере',
          'Обеспечить разработку и наличие инструкций по эксплуатации и техническому обслуживанию барокамер',
          'Назначить приказом ответственного за безопасную эксплуатацию барокамеры (из числа технических специалистов подразделения ГБО или подразделения технической службы)',
        ],
        correctAnswers: [
          'Назначить приказом ответственного за безопасную эксплуатацию барокамеры (из числа технических специалистов подразделения ГБО или подразделения технической службы)',
        ],
      },
      {
        code: '63695035',
        text:
          'С какой периодичностью должна проводиться поверка манометров медицинских барокамер с их опломбированием или клеймением?',
        answers: [
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 18 месяцев',
          'Не реже одного раза в 3 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63695036',
        text:
          'С какой периодичностью ответственный за исправное техническое состояние барокамеры должен проводить плановый (периодический) контроль технического состояния и исправности барокамеры?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
          'По утвержденному графику',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63695037',
        text:
          'Какие из перечисленных сведений о результатах экспертизы промышленной безопасности, записываемые в паспорт оборудования, указаны неверно?',
        answers: [
          'ФИО экспертов, проводивших экспертизу промышленной безопасности',
          'Дату подписания заключения экспертизы промышленной безопасности',
          'Наименование организации, проводившей экспертизу промышленной безопасности',
          'Регистрационный номер по реестру заключений экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'ФИО экспертов, проводивших экспертизу промышленной безопасности',
        ],
      },
      {
        code: '63695038',
        text:
          'Какая информация не указывается в журнале учета медицинских барокамер при наличии двух и более эксплуатируемых барокамер в МО?',
        answers: [
          'Список лиц, имеющих допуск к самостоятельной работе на барокамере, с указанием сроков очередной проверки знаний',
          'Время и место ее установки',
          'Сроки технического диагностирования',
          'Паспортные данные барокамеры',
        ],
        correctAnswers: [
          'Список лиц, имеющих допуск к самостоятельной работе на барокамере, с указанием сроков очередной проверки знаний',
        ],
      },
      {
        code: '63695039',
        text:
          'Что из перечисленных работ, выполняемых ответственным за исправное техническое состояние барокамеры при плановом (периодическом) контроле технического состояния и исправности барокамеры, указано неверно?',
        answers: [
          'Проверка герметичности барокамеры',
          'Проверка исправности систем и узлов барокамеры, в том числе запорной и запорно-регулирующей арматуры и контрольно-измерительных приборов',
          'Технический сеанс при выдержке барокамеры без пациента при рабочем давлении в течение 15 - 20 минут и кратковременном повышении давления до величины срабатывания предохранительного клапана',
        ],
        correctAnswers: [
          'Технический сеанс при выдержке барокамеры без пациента при рабочем давлении в течение 15 - 20 минут и кратковременном повышении давления до величины срабатывания предохранительного клапана',
        ],
      },
      {
        code: '63695040',
        text:
          'Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосуда?',
        answers: [
          'Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации',
          'Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 2,5) и цены деления',
          'При значении пробного давления не более 0,5 МПа допускается использование сжатого воздуха или другого газа для подъема давления в сосуде, заполненном водой',
          'Общее время подъема давления (до значения пробного) должно быть не менее одного часа',
        ],
        correctAnswers: [
          'Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации',
        ],
      },
      {
        code: '63695041',
        text:
          'Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы сосуда?',
        answers: [
          'Руководством (инструкцией) по эксплуатации и требованиями ФНП ОРПД',
          'Программой проведения технического освидетельствования сосуда, разработанной специализированной организацией до начала проведения освидетельствования',
          'Инструкцией по режиму работы и безопасному обслуживанию сосудов, утвержденной главным техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Руководством (инструкцией) по эксплуатации и требованиями ФНП ОРПД',
        ],
      },
      {
        code: '63695042',
        text:
          'Кто проводит проверку готовности одноместной медицинской барокамеры к работе после монтажа?',
        answers: [
          'Специализированная организация, имеющая лицензию на техническое обслуживание медицинской техники',
          'Специалисты эксплуатирующей организации',
          'Специализированная организация, имеющая лицензию на право проведения экспертизы промышленной безопасности барокамер',
          'Комиссия, назначенная руководителем эксплуатирующей организации с участием представителя Ростехнадзора',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Специалисты эксплуатирующей организации'],
      },
      {
        code: '63695043',
        text:
          'Кем определяются требования к монтажу и эксплуатации барокамер в контейнерах различных конструкций, перевозимых на различных шасси или устанавливаемых стационарно?',
        answers: [
          'Организацией-изготовителем таких комплексов',
          'Экспертной организацией',
          'Научно-исследовательской организацией',
          'Эксплуатирующей организацией по согласованию с территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Организацией-изготовителем таких комплексов'],
      },
      {
        code: '63695044',
        text:
          'Какие условия должны соблюдаться при установлении срока следующего периодического технического освидетельствования сосуда?',
        answers: [
          'Срок следующего освидетельствования устанавливается только экспертной организацией, если он не установлен изготовителем в руководстве по эксплуатации',
          'Срок следующего освидетельствования может превышать, но не более чем на 2 года, срок службы сосуда, установленный либо изготовителем, либо по результатам технического диагностирования сосуда',
          'Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда',
        ],
        correctAnswers: [
          'Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда',
        ],
      },
      {
        code: '63695045',
        text:
          'В соответствии с какой документацией осуществляется эксплуатация барокамеры?',
        answers: [
          'В соответствии с инструкцией по эксплуатации, разрабатываемой на основании требований технической документации на каждый конкретный тип барокамеры, с учетом местных условий',
          'В соответствии с проектной документацией барозала',
          'В соответствии с типовой инструкцией по эксплуатации барокамер',
        ],
        correctAnswers: [
          'В соответствии с инструкцией по эксплуатации, разрабатываемой на основании требований технической документации на каждый конкретный тип барокамеры, с учетом местных условий',
        ],
      },
      {
        code: '63695046',
        text:
          'При каком достижении концентрации кислорода должен срабатывать звуковой сигнал газоанализатора барокамер?',
        answers: ['Более 23%.', 'Более 15%', 'Более 20%', 'Более 10%'],
        correctAnswers: ['Более 23%.'],
      },
      {
        code: '63695047',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) литых и кованых металлических сосудов? Где в формулах: Рраб – рабочее давление сосуда, Р расч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,5 Рраб ([σ]20 / [σ]t).',
          'Рпр = 1,25 Рраcч ([σ]20 / [σ]t)',
          'Рпр = 1,25 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,25 Рраб',
        ],
        correctAnswers: ['Рпр = 1,5 Рраб ([σ]20 / [σ]t).'],
      },
      {
        code: '63695048',
        text:
          'Что может применяться для обеспечения доступа к площадкам обслуживания оборудования под давлением, предназначенным для периодического проведения работ (плановое техническое обслуживание, ремонт) в местах расположения люков, арматуры и иных устройств, оборудованных автоматическим дистанционным приводом, не требующих постоянного (неоднократного) присутствия персонала?',
        answers: [
          'Переносные площадки',
          'Передвижные лестницы',
          'Стационарные лестницы с углом наклона к горизонтали более 50°',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63695049',
        text:
          'Кем должно проводиться техническое обслуживание медицинской барокамеры?',
        answers: [
          'Любым перечисленным лицом',
          'Техническим специалистом подразделения ГБО',
          'Техническим специалистом специализированной организации, имеющей лицензию на техническое обслуживание данного вида медицинской техники',
          'Техническим специалистом специализированного подразделения МО',
        ],
        correctAnswers: ['Любым перечисленным лицом'],
      },
      {
        code: '63695050',
        text:
          'В каком случае манометр медицинских барокамер допускается к применению?',
        answers: [
          'Все ответы неверны',
          'Отсутствует информация о проведении поверки',
          'Стрелка при его отключении не возвращается к нулевому показанию шкалы на величину, превышающую половину допускаемой погрешности для данного прибора',
          'Разбито стекло',
          'Просрочен срок поверки',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63695051',
        text:
          'В каком случае необходимо проводить металлографические исследования?',
        answers: [
          'Все ответы неверны',
          'Для сварных соединений сосудов и их элементов, изготовленных из сталей аустенитного класса, толщиной не более 20 мм',
          'Для сварных соединений котлов и трубопроводов, изготовленных из стали перлитного класса, при условии проведения ультразвукового или радиографического контроля этих соединений в объеме 100%',
          'Для сварных соединений труб поверхностей нагрева котлов и трубопроводов, выполненных контактной сваркой на специальных машинах для контактной стыковой сварки с автоматизированным циклом работ при ежесменной проверке качества наладки машины путем испытания контрольных образцов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63695052',
        text:
          'Сколько раз разрешается проводить исправление дефектов на одном и том же участке сварного соединения?',
        answers: [
          'Не более трех раз',
          'Не более двух раз',
          'Не более четырех раз',
          'Один раз',
        ],
        correctAnswers: ['Не более трех раз'],
      },
      {
        code: '63695053',
        text: 'Какое из перечисленных требований указано неверно?',
        answers: [
          'Все окна и двери в барозале должны открываться наружу',
          'Ширина дверных проемов эвакуационных выходов из барозалов, а также проходов в барозале между установленным оборудованием должны обеспечивать возможность беспрепятственного перемещения одноместных барокамер',
          'Площадь окон и дверей определяется расчетом при проектировании и должна обеспечивать сброс максимально возможного количества сжатого газа при его аварийном сбросе в случае аварии барокамеры',
          'Должно быть обеспечено наличие не менее двух эвакуационных выходов для барозалов, в которых предусмотрено размещение двух и более одноместных барокамер, и барозалов с многоместными барокамерами',
        ],
        correctAnswers: [
          'Должно быть обеспечено наличие не менее двух эвакуационных выходов для барозалов, в которых предусмотрено размещение двух и более одноместных барокамер, и барозалов с многоместными барокамерами',
        ],
      },
      {
        code: '63695054',
        text:
          'Какой из перечисленных случаев проведения внеочередного технического освидетельствования барокамеры указан неверно?',
        answers: [
          'Перед пуском в работу, если барокамера не эксплуатировалась более 12 месяцев',
          'Если барокамера была демонтирована и установлена в новом месте',
          'По требованию ответственных лиц эксплуатирующей организации',
          'При наличии повреждений, полученных в процессе эксплуатации, влияющих на безопасность эксплуатации барокамеры',
        ],
        correctAnswers: [
          'При наличии повреждений, полученных в процессе эксплуатации, влияющих на безопасность эксплуатации барокамеры',
        ],
      },
      {
        code: '63695055',
        text:
          'С какой периодичностью проводится профилактическая проверка качества соединений и шлангов медицинской барокамеры визуальным осмотром?',
        answers: [
          'Ежедневно',
          'Еженедельно',
          'Ежемесячно',
          'Не регламентируется',
        ],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63695056',
        text:
          'Для каких барокамер в ФНП допускается внутренний диаметр менее 700 мм?',
        answers: [
          'Для барокамер, предназначенных для размещения в них пациентов весом не более 60 кг',
          'Для барокамер, предназначенных для размещения в них пациентов весом не более 75 кг',
          'Для барокамер, предназначенных для размещения в них пациентов весом не более 80 кг',
          'Внутренний диаметр барокамеры определяется технологическими особенностями предприятия изготовителя',
          'Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП',
        ],
        correctAnswers: [
          'Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП',
        ],
      },
      {
        code: '63695057',
        text:
          'Чем должны быть оснащены устройства подачи воздуха в барокамеру или сброса воздуха из многоместной барокамеры для предотвращения травмирования пациента?',
        answers: [
          'Устройства подачи или сброса воздуха должны размещаться в недоступных местах',
          'Предохранительным клапаном',
          'Быстрозапорным клапаном',
          'Пневмоглушителями и защитными сетками',
        ],
        correctAnswers: ['Пневмоглушителями и защитными сетками'],
      },
      {
        code: '63695058',
        text:
          'Каким документом определяются объем, методы и периодичность технических освидетельствований сосудов (за исключением баллонов)?',
        answers: [
          'ФНП ОРПД',
          'Руководством (инструкцией) по эксплуатации',
          'Инструкцией по режиму работы и безопасному обслуживанию сосуда',
          'Техническим регламентом Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"',
        ],
        correctAnswers: ['Руководством (инструкцией) по эксплуатации'],
      },
      {
        code: '63695059',
        text:
          'Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением от 0,3 до 6 МПа включительно?',
        answers: [
          'Превышающее разрешенное давление на 15 %',
          'Превышающее разрешенное давление более чем на 0,05 МПа',
          'Превышающее разрешенное давление на 10 %',
          'Превышающее разрешенное давление на 25 %',
        ],
        correctAnswers: ['Превышающее разрешенное давление на 15 %'],
      },
      {
        code: '63695060',
        text:
          'На каком основании принимается решение о вводе в эксплуатацию сосуда, работающего под давлением?',
        answers: [
          'На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда',
          'На основании результатов первичного освидетельствования сосуда и проверки организации его обслуживания',
          'На основании предписания уполномоченного представителя территориального органа Ростехнадзора',
          'На основании экспертизы промышленной безопасности. проведенной перед пуском сосуда в работу',
        ],
        correctAnswers: [
          'На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда',
        ],
      },
      {
        code: '63695061',
        text:
          'Какое из требований к арматуре, установленной на водолазной барокамере, указано неверно?',
        answers: [
          'Барокамеры диаметром 1200 мм и более должны быть оборудованы запорной арматурой, устанавливаемой непосредственно на корпусе барокамеры, как снаружи, так и внутри барокамеры',
          'Арматура должна быть установлена снаружи непосредственно на корпусе барокамеры или на подводящих трубопроводах и опломбирована в рабочем положении',
          'Перечень арматуры барокамеры и трубопроводов барозала, подлежащих опломбированию, и ее рабочее положение (открыто - закрыто) должны быть указаны в эксплуатационной документации',
          'Арматура систем подачи кислорода высокого давления, применяемая для подачи кислорода, должна быть выполнена из материалов, исключающих ее возгорание и горение в среде кислорода (повышенного его содержания)',
        ],
        correctAnswers: [
          'Арматура должна быть установлена снаружи непосредственно на корпусе барокамеры или на подводящих трубопроводах и опломбирована в рабочем положении',
        ],
      },
      {
        code: '63695062',
        text:
          'Что из перечисленных работ, выполняемых медицинским работником ежедневно перед проведением первого лечебного сеанса с пациентом, указано неверно?',
        answers: [
          'Проверка записей в журнале регистрации сеансов ГБО',
          'Осмотр барокамеры',
          'Проверка исходного состояния барокамеры',
          'Технический сеанс (в течение 35 - 40 минут без пациента с проверкой исправности предохранительного клапана на отсутствие заклинивания)',
          'Проверка исправности системы связи при открытой крышке барокамеры',
        ],
        correctAnswers: [
          'Технический сеанс (в течение 35 - 40 минут без пациента с проверкой исправности предохранительного клапана на отсутствие заклинивания)',
        ],
      },
      {
        code: '63695063',
        text:
          'Каким образом должны оформляться результаты проверок готовности сосуда к пуску в работу и организации надзора за его эксплуатацией?',
        answers: [
          'Приказом (распорядительным документом) эксплуатирующей организации',
          'Актом готовности сосуда к вводу в эксплуатацию',
          'Записью в паспорт сосуда',
          'Протоколом, который является основанием для ввода сосуда в эксплуатацию. Протокол прилагается к паспорту сосуда',
        ],
        correctAnswers: ['Актом готовности сосуда к вводу в эксплуатацию'],
      },
      {
        code: '63695064',
        text:
          'Чем должна быть оснащена барокамера для обеспечения контроля состояния находящегося в ней пациента?',
        answers: [
          'Автоматической системой мониторинга состояния пациента с передачей сигнала на пульт управления барокамерой',
          'Барокамера должна быть оснащена системой связи пациента с оператором в постоянном (фоновом) режиме, а также прозрачным куполом или иллюминаторами',
          'Конструкция барокамеры должна предусматривать возможность периодического открытия крышки или иллюминатора с целью контроля состояния находящегося в ней пациента',
          'Манометром и предохранительным клапаном',
        ],
        correctAnswers: [
          'Барокамера должна быть оснащена системой связи пациента с оператором в постоянном (фоновом) режиме, а также прозрачным куполом или иллюминаторами',
        ],
      },
      {
        code: '63695065',
        text:
          'Куда направляет эксплуатирующая оборудование под давлением организация копии документов, содержащих основание и подтверждение факта передачи оборудования другой эксплуатирующей организации?',
        answers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности',
          'В органы местного самоуправления',
          'Другой эксплуатирующей организации',
          'Заводу-изготовителю',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63695066',
        text:
          'Какие отсеки многоместной барокамеры допускается не оснащать предохранительным клапаном?',
        answers: [
          'Отсеки барокамеры, в случае если расчетное давление стенки их корпуса на 15 % выше максимального рабочего давления',
          'Отсеки барокамеры, в случае если расчетное давление стенки их корпуса на 10 % выше максимального рабочего давления',
          'Каждый отсек барокамеры должен быть оснащен предохранительным клапаном',
          'Предохранительный клапан может не устанавливаться в случаях, предусмотренных проектом барокамеры',
        ],
        correctAnswers: [
          'Каждый отсек барокамеры должен быть оснащен предохранительным клапаном',
        ],
      },
      {
        code: '63695067',
        text:
          'Для каких барокамер в ФНП допускается внутренний диаметр 600 мм?',
        answers: [
          'Внутренний диаметр 600 мм допускается для барокамер, предназначенных для установки в помещении площадью менее 10 м²',
          'Внутренний диаметр 600 мм допускается для барокамер предназначенных для установки в помещении площадью менее 15 м²',
          'Внутренний диаметр 600 мм допускается для барокамер предназначенных для установки в помещении площадью менее 20 м²',
          'Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП',
          'Внутренний диаметр барокамеры определяется технологическими особенностями предприятия изготовителя',
        ],
        correctAnswers: [
          'Для барокамер, изготовленных и введенных в эксплуатацию до принятия ФНП',
        ],
      },
      {
        code: '63695068',
        text:
          'Какой документацией устанавливаются необходимость, объем и порядок механических испытаний сварных соединений литых и кованых элементов, труб с литыми деталями, элементов из сталей различных классов, а также других единичных сварных соединений?',
        answers: [
          'Проектной и технологической документацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Производственными инструкциями',
          'Распорядительной документацией эксплуатирующей организации',
        ],
        correctAnswers: ['Проектной и технологической документацией'],
      },
      {
        code: '63695069',
        text: 'Какое из перечисленных требований указано неверно?',
        answers: [
          'Установка медицинских барокамер производится стационарно в зданиях, предназначенных и оборудованных для размещения пациентов, а также в специальных транспортабельных контейнерах',
          'Стационарные медицинские барокамеры устанавливаются в специально оборудованных помещениях (барозалах) на основании проекта',
          'Эксплуатация медицинских барокамер, установленных с нарушением проектной документации, допускается на основании обоснования безопасности, разработанного в соответствии с законодательством о промышленной безопасности',
          'Требования к размещению барокамеры в транспортабельных контейнерах, а также к конструкции контейнера определяются разработчиком проекта и изготовителем',
          'Размещение многоместных медицинских барокамер должно обеспечивать удобство их монтажа и установки на первом этаже здания, за исключением случаев, обоснованных технологией их применения и проектной документацией',
        ],
        correctAnswers: [
          'Эксплуатация медицинских барокамер, установленных с нарушением проектной документации, допускается на основании обоснования безопасности, разработанного в соответствии с законодательством о промышленной безопасности',
        ],
      },
      {
        code: '63695070',
        text:
          'В каком из перечисленных случаев должно быть вырезано три образца из каждого контрольного стыкового сварного соединения?',
        answers: [
          'Для испытания на ударный изгиб',
          'Для испытания на статическое растяжение',
          'Для испытаний на статический изгиб или сплющивание',
          'Для испытаний на стойкость против межкристаллитной коррозии',
        ],
        correctAnswers: ['Для испытания на ударный изгиб'],
      },
      {
        code: '63695071',
        text: 'Где не могут размещаться стационарные водолазные барокамеры?',
        answers: [
          'В жилых зданиях, при условии обеспечения их соответствия проектной документации, разработанной согласно законодательству Российской Федерации о градостроительной деятельности',
          'В помещениях капитальных зданий (строений), предназначенных для стационарной установки барокамер',
          'В помещениях легковозводимых зданий (строений), предназначенных для стационарной установки барокамер',
        ],
        correctAnswers: [
          'В жилых зданиях, при условии обеспечения их соответствия проектной документации, разработанной согласно законодательству Российской Федерации о градостроительной деятельности',
        ],
      },
      {
        code: '63695072',
        text:
          'Какое из требований к арматуре, установленной на водолазной барокамере, указано неверно?',
        answers: [
          'Вентили, устанавливаемые на кислородные трубопроводы, должны обеспечивать плавное повышение давления после их открывания, использование шаровых кранов не допускается',
          'Использование шаровых кранов допускается только в местах аварийного перекрытия подачи или сброса кислорода из отсеков барокамеры',
          'Все перепускные вентили должны иметь отличительный красный цвет ручек или выделяться красным квадратом на мнемосхеме панели во избежание случайного открывания',
        ],
        correctAnswers: [
          'Вентили, устанавливаемые на кислородные трубопроводы, должны обеспечивать плавное повышение давления после их открывания, использование шаровых кранов не допускается',
        ],
      },
      {
        code: '63695073',
        text:
          'Какими приборами для контроля газовой среды должны оснащаться многоместные барокамеры?',
        answers: [
          'Приборы контроля содержания кислорода и углекислого газа',
          'Приборы контроля содержания кислорода',
          'Приборы контроля содержания углекислого газа',
        ],
        correctAnswers: [
          'Приборы контроля содержания кислорода и углекислого газа',
        ],
      },
      {
        code: '63695074',
        text:
          'Какие из перечисленных сведений для работников, занятых эксплуатацией оборудования под давлением, не должны быть указаны в инструкциях, устанавливающих действия работников в аварийных ситуациях (в том числе при аварии)?',
        answers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
          'Места отключения вводов электропитания',
          'Места расположения аптечек первой помощи',
          'Способы и методы ликвидации аварий',
        ],
        correctAnswers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
        ],
      },
      {
        code: '63695075',
        text:
          'С каким избыточным рабочим давлением применяются многоместные медицинские барокамеры в зависимости от режимов работы, для которых они предназначены?',
        answers: ['До 1 МПа', 'До 2 МПа', 'До 3 МПа', 'До 4 МПа'],
        correctAnswers: ['До 1 МПа'],
      },
      {
        code: '63695076',
        text:
          'С какой периодичностью проводится проверка знаний рабочих, обслуживающих сосуды?',
        answers: [
          'Периодичность устанавливается эксплуатирующей организацией',
          'Один раз в 12 месяцев',
          'Один раз в 4 месяца',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63695077',
        text:
          'Какой минимальный объем работ выполняется при периодическом техническом освидетельствовании барокамеры?',
        answers: [
          'Визуальный осмотр, проверка барокамеры и систем жизнеобеспечения в действии, проверка герметичности барокамеры рабочим давлением среды',
          'Визуальный осмотр, гидравлические или пневматические испытания',
          'Техническое диагностирование и проверка барокамеры в действии',
        ],
        correctAnswers: [
          'Визуальный осмотр, проверка барокамеры и систем жизнеобеспечения в действии, проверка герметичности барокамеры рабочим давлением среды',
        ],
      },
      {
        code: '63695078',
        text:
          'Каким должно быть значение расчетного давления одноместной медицинской барокамеры?',
        answers: [
          'Расчетное давление должно быть на 10 % выше, чем рабочее давление',
          'Расчетное давление должно быть не менее, чем максимальное рабочее давление',
          'Расчетное давление должно быть не менее, чем 1,15 максимального рабочего давления',
          'Расчетное давление должно быть равно максимальному рабочему давлению',
          'Расчетное давление должно составлять 1,25 рабочего давления',
        ],
        correctAnswers: [
          'Расчетное давление должно быть на 10 % выше, чем рабочее давление',
        ],
      },
      {
        code: '63695079',
        text:
          'Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией сосудов?',
        answers: [
          'Выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения',
          'Проверка записи в сменном журнале с росписью в нем',
          'Контроль проведения противоаварийных тренировок',
          'Отстранение от работ работников, нарушающих требования промышленной безопасности',
        ],
        correctAnswers: ['Проверка записи в сменном журнале с росписью в нем'],
      },
      {
        code: '63695080',
        text:
          'Что из приведенного в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется производственной инструкцией по режиму работы и безопасному обслуживанию сосудов?',
        answers: [
          'Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора',
          'Порядок проверки исправности обслуживаемых сосудов и относящегося к ним оборудования в рабочем состоянии',
          'Порядок, сроки и способы проверки арматуры, предохранительных устройств, приборов автоматики защиты и сигнализации',
          'Порядок пуска в работу и остановки (прекращения работы) сосуда',
        ],
        correctAnswers: [
          'Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора',
        ],
      },
      {
        code: '63695081',
        text:
          'В каком случае из перечисленных проводят гидравлическое испытание пробным давлением в целях проверки плотности и прочности оборудования под давлением, а также всех сварных и других соединений?',
        answers: [
          'Во всех перечисленных случаях',
          'После окончательной сборки (изготовления, доизготовления) при монтаже оборудования, транспортируемого на место его установки отдельными деталями, элементами или блоками',
          'После реконструкции (модернизации), ремонта оборудования с применением сварки элементов, работающих под давлением',
          'При проведении технических освидетельствований и технического диагностирования оборудования в случаях, установленных Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63695082',
        text:
          'При каком значении пробного давления проводятся гидравлические испытания водолазной барокамеры при проведении периодического технического освидетельствования?',
        answers: [
          '1,25 от рабочего давления',
          '1,1 от рабочего давления',
          '1,15 от рабочего давления',
          '1,5 от рабочего давления',
        ],
        correctAnswers: ['1,25 от рабочего давления'],
      },
      {
        code: '63695083',
        text:
          'Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте более 3 м от уровня площадки наблюдения?',
        answers: [
          '160 мм',
          '200 мм',
          '250 мм',
          'Установка манометра на такой высоте не разрешается',
        ],
        correctAnswers: ['Установка манометра на такой высоте не разрешается'],
      },
      {
        code: '63695084',
        text:
          'В какие сроки проводится периодическое техническое освидетельствование водолазной барокамеры?',
        answers: [
          'С периодичностью, установленной в руководстве по эксплуатации или иной технической документации изготовителя конкретного типа барокамеры, но не позднее 5 лет с начала эксплуатации',
          'С периодичностью, установленной в руководстве по эксплуатации или иной технической документации изготовителя конкретного типа барокамеры, но не позднее 10 лет с начала эксплуатации',
          'С периодичностью, установленной в руководстве по эксплуатации или иной технической документации изготовителя конкретного типа барокамеры, но не позднее 15 лет с начала эксплуатации',
        ],
        correctAnswers: [
          'С периодичностью, установленной в руководстве по эксплуатации или иной технической документации изготовителя конкретного типа барокамеры, но не позднее 10 лет с начала эксплуатации',
        ],
      },
      {
        code: '63695085',
        text:
          'Какая ширина поверхности сварного шва и прилегающих к нему участков основного металла должны быть зачищены от шлака и других загрязнений перед визуальным контролем?',
        answers: [
          'Не менее 20 мм в обе стороны от шва',
          'Не менее 10 мм в обе стороны от шва',
          'Не менее 30 мм в обе стороны от шва',
          'Не менее 40 мм в обе стороны от шва',
        ],
        correctAnswers: ['Не менее 20 мм в обе стороны от шва'],
      },
      {
        code: '63695086',
        text: 'Какое из перечисленных требований указано неверно?',
        answers: [
          'Пол в барозале должен иметь антистатическое покрытие',
          'Барозалы должны оснащаться системами связи, пожарной сигнализации и газового анализа (сигнализаторами) для контроля содержания кислорода в атмосфере барозала',
          'При подаче кислорода на барокамеры от общей системы кислородоснабжения необходимо обеспечить оперативную связь с дежурным персоналом службы, обеспечивающим кислородоснабжение',
          'Отопление барозалов должно осуществляться с применением водяного теплоносителя, температура которого не превышает 95 °C, а также электрических нагревательных приборов при условии их заземления',
          'Для сброса газов из барокамеры в барозале должны быть предусмотрены специальные трубопроводы, обеспечивающие отвод газов за пределы барозала',
        ],
        correctAnswers: [
          'Отопление барозалов должно осуществляться с применением водяного теплоносителя, температура которого не превышает 95 °C, а также электрических нагревательных приборов при условии их заземления',
        ],
      },
      {
        code: '63695087',
        text:
          'На какой из приведенных сосудов не распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Воздушный ресивер, объем которого составляет 270 литров, работающий под давлением 1,6 МПа',
          'Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа',
          'Барокамера, вместимостью 0,8 м³, работающая с кислородом под давлением 0,3 МПа.',
          'Сосуд, вместимостью 50 литров, работающий под давлением 0,5 МПа, установленный на плавучей буровой установке',
        ],
        correctAnswers: [
          'Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа',
        ],
      },
      {
        code: '63695088',
        text:
          'Что из приведенного не указывается на табличке или не наносится на сосудах (кроме транспортируемых баллонов вместимостью до 100 литров) перед пуском их в работу?',
        answers: [
          'Дата ввода в эксплуатацию',
          'Номер оборудования по нумерации, принятой в эксплуатирующей организации',
          'Разрешенные параметры (давление, температура рабочей среды)',
          'Даты следующих осмотров и гидравлического испытания',
        ],
        correctAnswers: ['Дата ввода в эксплуатацию'],
      },
      {
        code: '63695089',
        text:
          'Какой из приведенных сосудов подлежит учету в территориальных органах Ростехнадзора?',
        answers: [
          'Сосуд, находящийся под давлением 0,5 МПа периодически при его опорожнении',
          'Газовый баллон вместимостью 120 литров, установленный на транспортном средстве для обеспечения топливом его двигателя',
          'Барокамера вместимостью 0,8 м³, работающая с кислородом давлением 0,3 МПа',
          'Барокамера, вместимостью 0,85 м³, работающая с воздухом под давлением 10 кгс/см',
        ],
        correctAnswers: [
          'Барокамера вместимостью 0,8 м³, работающая с кислородом давлением 0,3 МПа',
        ],
      },
      {
        code: '63695090',
        text:
          'Какое из перечисленных требований к капиллярному и магнитопорошковому контролю указано верно?',
        answers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
          'При применении нескольких видов контроля в отношении одного объекта капиллярный контроль должен выполняться после проведения ультразвукового и магнитопорошкового контроля',
          'По результатам капиллярного контроля на поверхности сварных соединений и наплавок допускаются единичные и групповые индикаторные рисунки округлой или удлиненной форм',
          'По результатам контроля магнитопорошковым методом на поверхности сварных соединений и наплавок допускаются индикаторные рисунки осаждений магнитного порошка',
        ],
        correctAnswers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
        ],
      },
      {
        code: '63695091',
        text:
          'Что необходимо предпринять, если при освидетельствовании сосуда будут обнаружены дефекты?',
        answers: [
          'Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля',
          'Организация, проводившая техническое освидетельствование, дает предписание о выводе сосуда из эксплуатации',
          'Эксплуатирующая организация должна перевести сосуд в режим эксплуатации на пониженных параметрах, рекомендованных организацией, проводившей техническое освидетельствование',
        ],
        correctAnswers: [
          'Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля',
        ],
      },
      {
        code: '63695092',
        text:
          'В какой документ заносятся результаты проверки исправности предохранительных устройств, установленных на сосуде, и сведения об их настройке?',
        answers: [
          'В паспорт сосуда',
          'В паспорт предохранительного клапана',
          'В сменный (оперативный) журнал или иные эксплуатационные документы',
          'В соответствующее приложение (проверка исправности и сведения о настройках) к производственной инструкции по эксплуатации предохранительных устройств',
        ],
        correctAnswers: [
          'В сменный (оперативный) журнал или иные эксплуатационные документы',
        ],
      },
      {
        code: '63695093',
        text:
          'Что из перечисленного включает в себя первичное техническое освидетельствование барокамеры (если нет иных указаний в технической документации)?',
        answers: [
          'Все перечисленное',
          'Проверку технической документации',
          'Гидравлические испытания на прочность',
          'Пневматические испытания на герметичность и плотность',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63695094',
        text:
          'Каким должно быть значение давления испытания на прочность одноместной медицинской барокамеры?',
        answers: [
          'Давление испытания барокамеры на прочность должно составлять 1,5 рабочего давления',
          'Давление испытания барокамеры на прочность должно быть не менее, чем максимальное рабочее давление',
          'Давление испытания барокамеры на прочность не менее, чем 1,15 максимального рабочего давления',
          'Давление испытания барокамеры на прочность должно быть равно максимальному рабочему давлению',
          'Давление испытания барокамеры на прочность должно составлять 1,25 рабочего давления',
        ],
        correctAnswers: [
          'Давление испытания барокамеры на прочность должно составлять 1,5 рабочего давления',
        ],
      },
      {
        code: '63695095',
        text:
          'Кто утверждает график объема и периодичности плановых работ по техническому обслуживанию и ремонту оборудования под давлением и его элементов?',
        answers: [
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Техническая комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технический руководитель эксплуатирующей организации',
        ],
      },
      {
        code: '63695096',
        text:
          'Какое напряжение электрического тока допускается для питания электрического оборудования, применяемого внутри многоместной барокамеры?',
        answers: [
          'Не более 42 В',
          'Не более 12 В',
          'Не более 36 В',
          'До 220 В',
        ],
        correctAnswers: ['Не более 42 В'],
      },
      {
        code: '63695097',
        text:
          'Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением более 2,5 МПа?',
        answers: ['Не ниже 4,0', 'Не ниже 2,5', 'Не ниже 1,5', 'Не ниже 1,0'],
        correctAnswers: ['Не ниже 1,5'],
      },
      {
        code: '63695098',
        text:
          'Требованиям какой документации должен соответствовать объем и порядок проведения технического диагностирования барокамеры?',
        answers: [
          'Всей перечисленной',
          'Руководства по эксплуатации',
          'Технической документации на барокамеру конкретного типа',
          'Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: ['Всей перечисленной'],
      },
      {
        code: '63695099',
        text:
          'Какие барокамеры должны оборудоваться устройствами оптической и звуковой сигнализации о превышении концентрации кислорода?',
        answers: [
          'Все медицинские барокамеры',
          'Барокамеры, работающие в среде сжатого кислорода',
          'Барокамеры, работающие со средой сжатого воздуха',
          'Только многоместные медицинские барокамеры',
        ],
        correctAnswers: ['Барокамеры, работающие со средой сжатого воздуха'],
      },
      {
        code: '63695100',
        text:
          'Какое из перечисленных требований при проведении гидравлического испытания сосудов указано верно?',
        answers: [
          'Все ответы неверны',
          'Сосуды, имеющие защитное покрытие или изоляцию, подвергают гидравлическому испытанию после наложения покрытия или изоляции',
          'Сосуды, имеющие наружный кожух, подвергают гидравлическому испытанию после установки кожуха',
          'Не допускается эмалированные сосуды подвергать гидравлическому испытанию рабочим давлением после эмалирования',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63695101',
        text:
          'В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?',
        answers: [
          'Начало и конец комплексного опробования оборудования, работающего под давлением, устанавливаются совместным приказом эксплуатирующей организации и организации, проводящей наладочные работы',
          'Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
          'Котлы – в течение 72 часов, трубопроводы пара и горячей воды – в течение 36 часов, время комплексного опробования сосудов устанавливается совместным приказом эксплуатирующей и наладочной организаций',
        ],
        correctAnswers: [
          'Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
        ],
      },
      {
        code: '63695102',
        text:
          'В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся только комиссией, назначаемой приказом эксплуатирующей организации?',
        answers: [
          'До начала применения транспортабельного сосуда',
          'После монтажа без применения сварки сосуда, демонтированного и установленного на новом месте',
          'После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации',
          'Во всех приведенных случаях проверки осуществляются комиссией, назначаемой приказом эксплуатирующей организации',
        ],
        correctAnswers: [
          'После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации',
        ],
      },
      {
        code: '63695103',
        text:
          'Кем определяется достаточная для обеспечения безопасной эксплуатации ОПО численность инженерно-технических работников?',
        answers: [
          'Эксплуатирующей организацией',
          'Ростехнадзором',
          'Проектной организацией',
          'Научно-исследовательской организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63695104',
        text: 'Какое из перечисленных требований к персоналу указано неверно?',
        answers: [
          'К обслуживанию барокамеры приказом руководителя ЛПУ допускается медицинский и технический персонал организации, имеющий квалификацию, соответствующую выполняемой ими работе',
          'Проверка знаний, стажировка и допуск медицинского персонала к работе по обслуживанию барокамеры и проведению сеансов с ее применением осуществляются в порядке, установленном распорядительными документами ЛПУ в соответствии с Положением о проверке знаний и требованиями настоящих ФНП',
          'Периодическая проверка знаний проводится 1 раз в 12 месяцев',
          'Первичную проверку знаний медицинского персонала проводят перед допуском к стажировке',
          'Внеочередная проверка знаний должна проводиться в случаях установки барокамеры нового типа',
        ],
        correctAnswers: [
          'Первичную проверку знаний медицинского персонала проводят перед допуском к стажировке',
        ],
      },
      {
        code: '63695105',
        text:
          'Какое количество образцов должно быть вырезано из каждого контрольного стыкового сварного соединения для металлографических исследований при контроле сварных соединений из углеродистой и низколегированной стали?',
        answers: ['Один', 'Не менее двух', 'Не менее трех', 'Не менее четырех'],
        correctAnswers: ['Один'],
      },
      {
        code: '63695106',
        text:
          'В каких случаях может быть предусмотрен периодический контроль газовой среды в многоместной барокамере?',
        answers: [
          'В случаях предусмотренных инструкцией изготовителя',
          'Контроль процентного содержания кислорода и углекислого газа должен быть непрерывным',
          'В случае если проектом барокамеры не предусмотрена сигнализация',
          'В случае превышения или понижения пороговых значений процентного содержания кислорода и углекислого газа',
        ],
        correctAnswers: [
          'Контроль процентного содержания кислорода и углекислого газа должен быть непрерывным',
        ],
      },
      {
        code: '63695107',
        text:
          'Какое из перечисленных требований к проведению первичного технического освидетельствования барокамеры указано неверно?',
        answers: [
          'Первичное техническое освидетельствование поставляемой в сборе барокамеры проводится организацией-изготовителем или специализированной организацией',
          'Первичное техническое освидетельствование включает проверку качества изготовления, осмотр, гидравлические испытания на прочность и пневматические испытания на герметичность и плотность',
          'Результаты первичного технического освидетельствования записывают в паспорте барокамеры',
          'После установки барокамеры на месте ее применения проводят проверку технической документации, правильности установки и подключения барокамеры, осмотр барокамеры и гидравлические испытания',
        ],
        correctAnswers: [
          'После установки барокамеры на месте ее применения проводят проверку технической документации, правильности установки и подключения барокамеры, осмотр барокамеры и гидравлические испытания',
        ],
      },
      {
        code: '63695108',
        text:
          'Какие среды могут использоваться при проведении пневматических испытаний на герметичность и плотность барокамеры?',
        answers: ['Воздух', 'Азот', 'Гелий', 'Все перечисленное'],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63695109',
        text: 'Какое из перечисленных требований указано неверно?',
        answers: [
          'Размещение барокамер в помещении барозала должно определяться проектом и обеспечивать удобство, безопасность их обслуживания, возможность свободного и беспрепятственного перемещения, эвакуацию пациентов и персонала',
          'Минимальное расстояние от выступающих частей барокамеры до стены или стационарно установленной медицинской аппаратуры должно быть 1 метр',
          'Расстояние между барокамерами должно составлять не менее 1 метра, а от отопительных приборов и других источников тепла до барокамер - не менее 1,5 метра',
        ],
        correctAnswers: [
          'Расстояние между барокамерами должно составлять не менее 1 метра, а от отопительных приборов и других источников тепла до барокамер - не менее 1,5 метра',
        ],
      },
      {
        code: '63695110',
        text:
          'Что контролируется при проведении проверки готовности сосуда к пуску в работу? Выберите 2 варианта ответа.',
        answers: [
          'Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию сосуда',
          'Соответствие требованиям промышленной безопасности установки сосуда и правильность его включения в соответствии с руководством (инструкцией) по эксплуатации',
          'Наличие положительных результатов технического освидетельствования сосуда',
          'Наличие в соответствии с проектом и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит',
        ],
        correctAnswers: [
          'Наличие положительных результатов технического освидетельствования сосуда',
          'Наличие в соответствии с проектом и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит',
        ],
      },
      {
        code: '63695111',
        text:
          'Какие работы выполняются при первичном техническом освидетельствовании барокамеры?',
        answers: [
          'Проверка технической документации, наружный и внутренний осмотр, гидравлические испытания на прочность, пневматические испытания на герметичность и плотность, проверка барокамеры в действии',
          'Наружный и внутренний осмотр, гидравлические испытания на прочность',
          'Проверка технической документации, наружный осмотр, гидравлические испытания на прочность',
          'Наружный и внутренний осмотр, пневматические испытания на герметичность и плотность, проверка барокамеры в действии',
        ],
        correctAnswers: [
          'Проверка технической документации, наружный и внутренний осмотр, гидравлические испытания на прочность, пневматические испытания на герметичность и плотность, проверка барокамеры в действии',
        ],
      },
      {
        code: '63695112',
        text:
          'Каким документом определяется порядок действия в случае инцидента при эксплуатации сосуда?',
        answers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Инструкцией (руководством) по эксплуатации организации-изготовителя сосуда',
        ],
        correctAnswers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
        ],
      },
      {
        code: '63695113',
        text:
          'Какую минимальную высоту и ширину должны иметь дверные проемы многоместных медицинских барокамер, выполненные в виде плоских дверей?',
        answers: [
          'Не менее 1,55 м и ширину не менее 0,7 м',
          'Не менее 1,75 м и ширину не менее 0,9 м',
          'Не менее 1,35 м и ширину не менее 0,5 м',
          'Не менее 2,0 м и ширину не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 1,55 м и ширину не менее 0,7 м'],
      },
      {
        code: '63695114',
        text:
          'Где отмечается заключение о возможности продолжения эксплуатации медицинской барокамеры?',
        answers: [
          'В журнале регистрации сеансов',
          'В журнале технического обслуживания',
          'В журнале ремонта',
          'В паспорте барокамеры',
        ],
        correctAnswers: ['В журнале регистрации сеансов'],
      },
      {
        code: '63695115',
        text:
          'В каком случае разрешается менять конструкцию и технологическую схему медицинской барокамеры при проведении ремонта?',
        answers: [
          'С разрешения организации-изготовителя или проектной организации',
          'С разрешения территориального органа Ростехнадзора',
          'С разрешения специализированной организации, имеющей лицензию на техническое обслуживание данного вида медицинской техники',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'С разрешения организации-изготовителя или проектной организации',
        ],
      },
      {
        code: '63695116',
        text:
          'Кого должна уведомлять организация, проводившая техническое освидетельствование, о факте выявления дефектов, возникновение которых обусловлено особенностями (недостатками) конструкции оборудования?',
        answers: [
          'Всех перечисленных',
          'Организацию-изготовителя этого оборудования или уполномоченное организацией-изготовителем лицо',
          'Ростехнадзор',
          'Организацию, оформившую документ о подтверждении соответствия этого оборудования требованиям ТР ТС 032/2013',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63695117',
        text:
          'Кто принимает решение о вводе в эксплуатацию одноместной медицинской барокамеры после монтажа?',
        answers: [
          'Специализированная организация, имеющая лицензию на техническое обслуживание медицинской техники',
          'Специалисты эксплуатирующей организации',
          'Специализированная организация, имеющая лицензию на право проведения экспертизы промышленной безопасности барокамер',
          'Комиссия, назначенная руководителем эксплуатирующей организации с участием представителя Ростехнадзора',
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
          'Представитель Ростехнадзора с записью разрешения в паспорт барокамеры',
        ],
        correctAnswers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
        ],
      },
      {
        code: '63695118',
        text:
          'Наличие и работоспособность какого поглотителя должно быть обеспечено во всех барокамерах?',
        answers: [
          'Поглотителя углекислого газа',
          'Поглотителя кислорода',
          'Поглотителя азота',
          'Поглотителя аргона',
        ],
        correctAnswers: ['Поглотителя углекислого газа'],
      },
      {
        code: '63695119',
        text:
          'Какое из приведенных требований к проверке знаний рабочих, обслуживающих сосуды, указано неверно?',
        answers: [
          'Результаты проверки знаний рабочих оформляют протоколом с отметкой в удостоверении о допуске к самостоятельной работе',
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации персонала, обслуживающего барокамеры, работающие под давлением кислорода',
          'Внеочередная проверка знаний проводится при принятии на работу рабочего из другой организации',
          'В случае реконструкции (модернизации) сосуда должна быть проведена внеочередная проверка знаний',
        ],
        correctAnswers: [
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации персонала, обслуживающего барокамеры, работающие под давлением кислорода',
        ],
      },
      {
        code: '63695120',
        text:
          'Куда записывают причины внеочередного технического диагностирования медицинской барокамеры?',
        answers: [
          'В паспорт барокамеры',
          'В журнал регистрации сеансов',
          'В журнал технического обслуживания',
          'В журнал ремонта',
        ],
        correctAnswers: ['В паспорт барокамеры'],
      },
      {
        code: '63695121',
        text:
          'Что из перечисленных обязанностей ответственного за безопасную эксплуатацию барокамеры указано неверно?',
        answers: [
          'Ежедневно проверять записи персонала в журнале регистрации сеансов ГБО с занесением в него записи о результатах проверки',
          'Контролировать своевременность проведения регламентных работ по техническому обслуживанию, ремонту и техническому освидетельствованию оборудования',
          'Останавливать работу барокамеры при выявлении нарушений требований безопасной эксплуатации',
          'Не допускать к работе на барокамере лиц (медицинский персонал отделения ГБО, технических специалистов ЛПУ и сторонних организаций), не имеющих соответствующего допуска, не прошедших обучение (проверку знаний) или нарушающих требования безопасной эксплуатации барокамеры и режим проведения лечебных сеансов',
          'Ежедневно проводить технический сеанс на барокамере с выдачей персоналу разрешения на допуск к работе',
        ],
        correctAnswers: [
          'Ежедневно проводить технический сеанс на барокамере с выдачей персоналу разрешения на допуск к работе',
        ],
      },
      {
        code: '63695122',
        text:
          'Кем должны быть разработаны и утверждены документы при отсутствии в комплекте технической документации, прилагаемой организацией-изготовителем к оборудованию под давлением, документов (в виде разделов паспорта либо отдельных формуляров, журналов), обеспечивающих возможность внесения информации об истории эксплуатации оборудования под давлением?',
        answers: [
          'Эксплуатирующей организацией',
          'Научно-исследовательской организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63695123',
        text:
          'В каком из приведенных случаев до проверки знаний рабочий, обслуживающий сосуды, должен пройти стажировку?',
        answers: [
          'Перед первичным допуском к самостоятельной работе после профессионального обучения',
          'Перед допуском к самостоятельной работе после внеочередной проверки знаний',
          'При перерыве в работе по специальности более 12 месяцев',
          'Во всех приведенных случаях проводится стажировка',
        ],
        correctAnswers: ['Во всех приведенных случаях проводится стажировка'],
      },
      {
        code: '63695124',
        text:
          'Какой из приведенных случаев, при которых не допускается работа барокамеры, указан неверно?',
        answers: [
          'Наличие утечек газа вследствие негерметичности барокамеры, шлангов, арматуры или стыковочных узлов',
          'Нарушение заземления',
          'Неисправность системы связи с пациентом',
          'Отсутствие, повреждение или неисправность контрольно-измерительных приборов',
          'Неисправность всех предохранительных клапанов, установленных на барокамере и подводящих трубопроводах',
        ],
        correctAnswers: [
          'Неисправность всех предохранительных клапанов, установленных на барокамере и подводящих трубопроводах',
        ],
      },
      {
        code: '63695125',
        text: 'Кто производит установку и монтаж барокамеры?',
        answers: [
          'Специализированные организации',
          'Организации-изготовители',
          'Эксплуатирующие организации',
        ],
        correctAnswers: ['Специализированные организации'],
      },
      {
        code: '63695126',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Техническое перевооружение опасного производственного объекта, на котором используются сосуды, работающие под давлением.',
          'Техническое освидетельствование сосудов, работающих под давлением',
          'Проектирование и конструирование сосудов, работающих под давлением',
          'Техническое диагностирование и освидетельствование сосудов, работающих под давлением',
        ],
        correctAnswers: [
          'Проектирование и конструирование сосудов, работающих под давлением',
        ],
      },
      {
        code: '63695127',
        text:
          'Какие виды контроля качества сварных соединений и материалов должны предшествовать контролю другими методами? Выберите 2 варианта ответа.',
        answers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
          'Проникающими веществами',
          'Магнитопорошковый контроль',
          'Вихретоковый контроль',
        ],
        correctAnswers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
        ],
      },
      {
        code: '63695128',
        text:
          'В соответствии с каким документом должна осуществляться эксплуатация медицинских стационарных барокамер?',
        answers: [
          'В соответствии требованиям технического регламента ТР ТС 032/2013',
          'В соответствии с технической документацией изготовителя и требованиями ФНП',
          'В соответствии с требованиями ФНП и инструкцией, разработанной специализированной организацией',
        ],
        correctAnswers: [
          'В соответствии с технической документацией изготовителя и требованиями ФНП',
        ],
      },
      {
        code: '63695129',
        text:
          'Какой класс точности должны иметь манометры, установленные на подводящих трубопроводах к медицинской барокамере?',
        answers: ['Не ниже 2,5', 'Не ниже 1,5', 'Не ниже 1,0', 'Не ниже 4,0'],
        correctAnswers: ['Не ниже 2,5'],
      },
      {
        code: '63695130',
        text:
          'В каком случае допускается подключение линий подачи газов высокого давления напрямую к барокамере, минуя редукционные устройства?',
        answers: [
          'Не допускается ни в каком случае',
          'Если установлены предохранительные клапаны, предотвращающие повышение давления подаваемых газов сверх установленного эксплуатационной документацией значения',
          'Если барокамеры оснащены системой автоматического управления',
          'Если присутствует ответственный за безопасное производство работ',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63695131',
        text:
          'Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением до 2,5 МПа включительно?',
        answers: ['Не ниже 4,0', 'Не ниже 2,5', 'Не ниже 1,5', 'Не ниже 1,0'],
        correctAnswers: ['Не ниже 2,5'],
      },
      {
        code: '63695132',
        text:
          'Какие из перечисленных партий материалов для дефектоскопии до начала их использования должны быть подвергнуты входному контролю?',
        answers: [
          'Все перечисленные партии материалов',
          'Пенетранты',
          'Суспензии',
          'Радиографическая пленка',
        ],
        correctAnswers: ['Все перечисленные партии материалов'],
      },
      {
        code: '63695133',
        text:
          'В каких случаях допускается размещение барокамер в цокольных этажах?',
        answers: [
          'При условии оснащения помещения автоматической системой контроля содержания кислорода',
          'Допускается размещение в цокольных этажах барокамер вместимостью не более 1,5 м³.',
          'В случаях, предусмотренных инструкцией изготовителя',
          'Размещение барокамер в подвальных и цокольных этажах не допускается',
          'В случае если проектом предусмотрена сигнализация о превышении или понижении пороговых значений процентного содержания кислорода и углекислого газа',
        ],
        correctAnswers: [
          'Размещение барокамер в подвальных и цокольных этажах не допускается',
        ],
      },
      {
        code: '63695134',
        text:
          'На какое значение давления срабатывания должен быть настроен предохранительный клапан одноместной медицинской барокамеры?',
        answers: [
          'Предохранительный клапан должен быть настроен на давление срабатывания не более 10 % от рабочего давления барокамеры',
          'Предохранительный клапан должен быть настроен на давление срабатывания не более 15% от рабочего давления барокамеры',
          'Давление срабатывания предохранительного клапана барокамеры должно быть не менее 10% от рабочего давления барокамеры',
          'Давление срабатывания предохранительного клапана барокамеры определяется расчетом по формулам, указанным изготовителем в руководстве по эксплуатации клапана',
        ],
        correctAnswers: [
          'Предохранительный клапан должен быть настроен на давление срабатывания не более 10 % от рабочего давления барокамеры',
        ],
      },
      {
        code: '63695135',
        text:
          'С какой периодичностью проводится проверка знаний требований производственных инструкций и (или) инструкций для данной профессии у рабочих?',
        answers: [
          'Один раз в 12 месяцев',
          'Один раз в 6 месяцев',
          'Один раз в месяц',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63695136',
        text:
          'Какая из приведенных операций подлежит обязательному включению в объем работ по первичному техническому освидетельствованию сосудов, смонтированных на месте эксплуатации?',
        answers: [
          'Контроль толщины стенок элементов сосудов',
          'Гидравлическое испытание',
          'Проверка соответствия монтажа, обвязки трубопроводами, оснащения контрольно-измерительными приборами и предохранительными устройствами',
          'Все приведенные операции подлежат обязательному включению при проведении первичного освидетельствования сосуда',
        ],
        correctAnswers: [
          'Все приведенные операции подлежат обязательному включению при проведении первичного освидетельствования сосуда',
        ],
      },
      {
        code: '63695137',
        text:
          'Как контролируется работоспособность барокамеры длительного пребывания и ее систем и средств жизнеобеспечения при работе по прямому назначению при проверке барокамеры в действии?',
        answers: [
          'Верны все ответы',
          'На воздухе при нормальном атмосферном давлении',
          'На воздухе под давлением газовой среды, соответствующим рабочему давлению в барокамере',
          'Газовой средой (кислородно-гелиевой) при рабочем давлении в барокамере',
        ],
        correctAnswers: ['Верны все ответы'],
      },
      {
        code: '63695138',
        text:
          'Что из перечисленных обязанностей ответственного за исправное техническое состояние барокамеры указано неверно?',
        answers: [
          'Вести учет наработки рабочих циклов барокамеры',
          'Обеспечивать подготовку барокамеры к техническому освидетельствованию и (или) техническому диагностированию',
          'Организовывать проведение технического обслуживания, ремонта барокамеры',
          'Периодически (не реже 1 раза в месяц) контролировать проведение ежедневного технического сеанса на барокамере',
          'Составлять планы проведения профилактических регламентных работ (технического обслуживания) барокамеры',
        ],
        correctAnswers: [
          'Периодически (не реже 1 раза в месяц) контролировать проведение ежедневного технического сеанса на барокамере',
        ],
      },
    ],
    63697: [
      {
        code: '63697000',
        text:
          'Какие системы трубопроводов должны быть предусмотрены проектом их размещения для безопасной эксплуатации котлов?',
        answers: [
          'Отбора проб воды и пара',
          'Подвода топлива к горелочным устройствам котла',
          'Подвода питательной или сетевой воды',
          'Продувки котла и спуска воды при остановке котла',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63697001',
        text:
          'Каково минимальное значение расстояния между фронтом электрокотлов электрической мощностью 5 МВт, расположенных друг против друга?',
        answers: ['1 м', '2 м', '3 м', 'Устанавливается проектом'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63697002',
        text:
          'Каково минимальное значение ширины свободных проходов вдоль фронта котла при установке котельного вспомогательного оборудования и щитов управления перед фронтом котлов?',
        answers: ['800 мм', '1,0 м', '1,5 м', '2,0 м'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63697003',
        text:
          'На каком удалении от зоны контроля может вестись электродуговая сварка во время магнитного контроля тепловой неравномерности и магнитной ферритометрии?',
        answers: [
          'Не менее чем на 20 м',
          'Не менее чем на 15 м',
          'Не менее чем на 10 м',
          'Не менее чем на 25 м',
        ],
        correctAnswers: ['Не менее чем на 10 м'],
      },
      {
        code: '63697004',
        text:
          'Какое значение должна составлять длина контрольного участка для проведения магнитной ферритометрии?',
        answers: ['≈ 100 мм', '≈ 300 мм', '≈ 400 мм', '≈ 200 мм'],
        correctAnswers: ['≈ 100 мм'],
      },
      {
        code: '63697005',
        text:
          'Что должно предусматриваться проектом котельного помещения, если расстояние от нулевой отметки котельного помещения до верхней площадки котлов превышает 20 м?',
        answers: [
          'Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг',
          'Грузоподъемный кран, грузоподъемность которого должна быть не менее массы барабана или наиболее тяжелого элемента безбарабанного котла',
          'Грузоподъемный кран для подъема грузов грузоподъемностью не менее 5 тонн и пассажирский лифт, минимальная грузоподъемность которого составляет 800 кг',
        ],
        correctAnswers: [
          'Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг',
        ],
      },
      {
        code: '63697006',
        text:
          'Кем определяется порядок эксплуатационного контроля и продления срока службы (ресурса) элементов оборудования, изготовленных из новых отечественных сталей или сталей иностранного производства, которые не указаны в Правилах осуществления эксплуатационного контроля металла и продления срока службы основных элементов котлов и трубопроводов тепловых электростанций?',
        answers: [
          'Эксплуатирующей организацией',
          'Территориальным органом Ростехнадзора',
          'Монтажной организацией',
          'Проектной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63697007',
        text:
          'Что кроме мер безопасности должно быть предусмотрено программой наладочных работ при проведении наладки оборудования, работающего с применением опасных веществ или во взрывоопасных зонах?',
        answers: [
          'Должно быть предусмотрено предварительное опробование стадий технологического процесса на инертных средах с последующей наладкой на рабочих средах',
          'Должно быть предусмотрено предварительное опробование стадий технологического процесса на пониженных параметрах (50% от рабочих режимов) с последующей наладкой на рабочих режимах',
          'Должны быть предусмотрены предварительное опробование стадий технологического процесса на инертных средах, предварительная наладка на инертных средах и окончательная наладка на рабочих средах',
        ],
        correctAnswers: [
          'Должно быть предусмотрено предварительное опробование стадий технологического процесса на инертных средах с последующей наладкой на рабочих средах',
        ],
      },
      {
        code: '63697008',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'При реконструкции (модернизации) тепловой сети ОПО, отнесенного к III классу опасности',
          'При техническом освидетельствовании трубопровода горячей воды',
          'При пусконаладочных работах на трубопроводе пара',
          'При проектировании оборудования, изготовленного (произведенного) из неметаллической гибкой (эластичной) оболочки',
        ],
        correctAnswers: [
          'При проектировании оборудования, изготовленного (произведенного) из неметаллической гибкой (эластичной) оболочки',
        ],
      },
      {
        code: '63697009',
        text:
          'Чему равно минимальное значение времени выдержки сосуда под пробным давлением при пневматическом испытании?',
        answers: [
          '15 минут',
          '10 минут',
          '5 минут',
          'Время выдержки сосуда определяется временем естественного падения давления, определяемого по манометру, от значения пробного давления до значения рабочего давления',
        ],
        correctAnswers: ['15 минут'],
      },
      {
        code: '63697010',
        text:
          'Каким образом должны оформляться результаты проверок готовности котла к пуску в работу и организации надзора за его эксплуатацией?',
        answers: [
          'Актом готовности котла к вводу в эксплуатацию',
          'Приказом (распорядительным документом) эксплуатирующей организации',
          'Записью в паспорте котла',
          'Протоколом, который является основанием для ввода котла в эксплуатацию. Протокол прилагается к паспорту котла',
        ],
        correctAnswers: ['Актом готовности котла к вводу в эксплуатацию'],
      },
      {
        code: '63697011',
        text:
          'Чем проводят предварительную и конечную обработку образца для получения чистой травленой поверхности при исследовании микроповрежденности структуры?',
        answers: [
          'Водой',
          'Чистым этиловым спиртом',
          'Слабым раствором азотной кислоты',
          'Чистящим средством',
        ],
        correctAnswers: ['Чистым этиловым спиртом'],
      },
      {
        code: '63697012',
        text:
          'В каком из приведенных случаев должны проводиться механические испытания?',
        answers: [
          'При входном контроле сварочных материалов, используемых при газовой или контактной сварке',
          'При аттестации технологии сварки',
          'При контроле сварных стыковых соединений, выполненных сваркой под флюсом или электрошлаковой сваркой',
        ],
        correctAnswers: ['При аттестации технологии сварки'],
      },
      {
        code: '63697013',
        text:
          'В каких целях проводится магнитопорошковый контроль сварных соединений?',
        answers: [
          'Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях',
          'Для подтверждения соответствия легирования металла сварных швов и элементов оборудования',
          'Для проверки качества выполнения термической обработки сварных соединений',
        ],
        correctAnswers: [
          'Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях',
        ],
      },
      {
        code: '63697014',
        text:
          'Какие из приведенных требований к площадкам и лестницам для обслуживания, осмотра и ремонта оборудования указаны неверно?',
        answers: [
          'Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали',
          'Площадки и лестницы для обслуживания, осмотра, ремонта оборудования под давлением должны ограждаться перилами высотой не менее 900 мм со сплошной обшивкой по низу на высоту не менее 100 мм',
          'Переходные площадки и лестницы должны иметь перила с обеих сторон. Площадки котлов длиной более 5 м должны иметь не менее двух лестниц (двух выходов), расположенных в противоположных концах',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали',
        ],
      },
      {
        code: '63697015',
        text:
          'В каком случае допускается подключение линий подачи газов высокого давления напрямую к барокамере, минуя редукционные устройства?',
        answers: [
          'В присутствии ответственного за безопасное производство работ',
          'Если барокамеры оснащены системой автоматического управления',
          'Если установлены предохранительные клапаны, предотвращающие повышение давления подаваемых газов сверх установленного эксплуатационной документацией значения',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63697016',
        text:
          'В каком случае допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением?',
        answers: [
          'Если разность избыточных давлений элемента, подлежащего продувке, и емкости составляет не более 0,2 МПа',
          'Если барабан котла не имеет солевых отсеков и рабочее давление котла не превышает 1,3 МПа',
          'Если подтверждены надежность и эффективность продувки соответствующими расчетами',
          'Не допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением',
        ],
        correctAnswers: [
          'Если подтверждены надежность и эффективность продувки соответствующими расчетами',
        ],
      },
      {
        code: '63697017',
        text:
          'Каковы должны быть минимально допустимые расстояния между фронтом котлов и выступающими частями топок котлов, расположенных друг против друга, а также расстояние между горелочными устройствами при установке котлов, работающих на газообразном топливе?',
        answers: [
          'Устанавливается проектом',
          'Расстояние между фронтом котлов и выступающими частями топок - 4 м, расстояние между горелочными устройствами - 2 м',
          'Расстояние между фронтом котлов и выступающими частями топок - 3 м, расстояние между горелочными устройствами - 1,5 м',
          'Расстояние между фронтом котлов и выступающими частями топок - 3 м, расстояние между горелочными устройствами - 2 м',
        ],
        correctAnswers: [
          'Расстояние между фронтом котлов и выступающими частями топок - 4 м, расстояние между горелочными устройствами - 2 м',
        ],
      },
      {
        code: '63697018',
        text:
          'На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На техническое перевооружение опасного производственного объекта, на котором используются трубопроводы пара и горячей воды',
          'На монтаж паропровода',
          'На изготовление труб, тройников, отводов',
        ],
        correctAnswers: ['На изготовление труб, тройников, отводов'],
      },
      {
        code: '63697019',
        text:
          'Какое количество измерений должно быть проведено на каждой контрольной площадке при измерении твердости металла?',
        answers: [
          'Не менее трех измерений',
          'Не менее двух измерений',
          'Не менее четырех измерений',
          'Одно измерение',
        ],
        correctAnswers: ['Не менее трех измерений'],
      },
      {
        code: '63697020',
        text:
          'Когда на оборудовании, работающем под давлением, проводятся предусмотренные руководством (инструкцией) по эксплуатации пусконаладочные работы?',
        answers: [
          'После окончания монтажных работ с оформлением удостоверения о качестве монтажа',
          'После составления акта о вводе оборудования в эксплуатацию',
          'После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования',
        ],
        correctAnswers: [
          'После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования',
        ],
      },
      {
        code: '63697021',
        text:
          'Какое из приведенных требований к размещению котлов внутри производственных помещений указано неверно?',
        answers: [
          'Место установки котлов и обеспечивающих их работу систем, устройств и вспомогательного оборудования (насосов, электрических щитов, и иного оборудования в соответствии с проектом) внутри производственных помещений должно быть отделено от остальной части помещения несгораемыми перегородками по всей высоте котла, но не ниже 2 м с устройством дверей',
          'Котлы-утилизаторы могут быть отделены от остальной части производственного помещения вместе с печами или агрегатами, с которыми они связаны технологическим процессом',
          'Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений',
        ],
      },
      {
        code: '63697022',
        text:
          'Какое из приведенных требований должно выполняться при исправлении дефектов сварных соединений?',
        answers: [
          'Методы и качество устранения дефектов должны обеспечивать необходимую надежность и безопасность работы оборудования',
          'Технология устранения дефектов устанавливается технологической документацией. Отклонения от принятой технологии исправления дефектов не допускаются',
          'Все дефекты, обнаруженные в процессе реконструкции (модернизации), испытаний, должны быть устранены (исправлены), необходимость последующего контроля должна определяться технологической документацией',
        ],
        correctAnswers: [
          'Методы и качество устранения дефектов должны обеспечивать необходимую надежность и безопасность работы оборудования',
        ],
      },
      {
        code: '63697023',
        text:
          'Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?',
        answers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
          'Должны быть просвечены рентгеном',
          'Должны быть обезжирены водными смывками',
        ],
        correctAnswers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
        ],
      },
      {
        code: '63697024',
        text:
          'Какой из приведенных котлов допускается устанавливать внутри производственных помещений?',
        answers: [
          'Водогрейный электрокотел электрической мощностью 10 МВт',
          'Барабанный водогрейный котел теплопроизводительностью 1,5 Гкал/час',
          'Паровой котел-утилизатор производительностью 10 тонн пара в час',
          'Прямоточный котел паропроизводительностью 8 тонн пара в час',
        ],
        correctAnswers: [
          'Паровой котел-утилизатор производительностью 10 тонн пара в час',
        ],
      },
      {
        code: '63697025',
        text:
          'Какой следует принимать расчетную температуру стенки деталей котлов и трубопроводов в пределах котла?',
        answers: [
          'Не менее 250 °C',
          'Не менее 150 °C',
          'Не менее 350 °C',
          'Не менее 200 °C',
        ],
        correctAnswers: ['Не менее 250 °C'],
      },
      {
        code: '63697026',
        text:
          'В каком случае принимаются в расчет гидростатическое давление и потери гидравлического сопротивления элементов, заполненных водой, согласно Нормам расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Если их сумма равна или более 2% расчетного давления',
          'Если их сумма равна или более 1% расчетного давления',
          'Если их сумма равна или более 3% расчетного давления',
          'В любом случае',
        ],
        correctAnswers: [
          'Если их сумма равна или более 3% расчетного давления',
        ],
      },
      {
        code: '63697027',
        text:
          'На какие из приведенных ОПО распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На ОПО подводного применения',
          'На ОПО, на которых используются сосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом',
          'На плавучую буровую установку, на которой установлен котел на органическом теплоносителе с рабочим давлением 0,5 МПа',
          'На ОПО, на которых используются сосуды и трубопроводы, работающие под вакуумом',
        ],
        correctAnswers: [
          'На плавучую буровую установку, на которой установлен котел на органическом теплоносителе с рабочим давлением 0,5 МПа',
        ],
      },
      {
        code: '63697028',
        text:
          'Что может применяться для обеспечения доступа к площадкам обслуживания оборудования под давлением, предназначенным для периодического проведения работ (плановое техническое обслуживание, ремонт) в местах расположения люков, арматуры и иных устройств, оборудованных автоматическим дистанционным приводом, не требующих постоянного (неоднократного) присутствия персонала?',
        answers: [
          'Переносные площадки',
          'Передвижные лестницы',
          'Стационарные лестницы с углом наклона к горизонтали более 50°',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697029',
        text:
          'Что не включается в состав итоговой документации, подтверждающей контроль качества ремонта оборудования с применением сварки и термической обработки?',
        answers: [
          'Протоколы, заключения, отчеты и акты по результатам проведения неразрушающего, разрушающего контроля и гидравлических или пневматических испытаний',
          'Ремонтные рабочие чертежи и формуляры',
          'Технологическая документация и протокол производственной аттестации сварки',
        ],
        correctAnswers: [
          'Технологическая документация и протокол производственной аттестации сварки',
        ],
      },
      {
        code: '63697030',
        text:
          'Что должно контролироваться при входном контроле сварочных материалов?',
        answers: [
          'Наличие соответствующей сопроводительной документации',
          'Сварочно-технологические свойства каждой партии порошковой проволок',
          'Наличие основных легирующих элементов каждой бухты (мотка, катушки) легированной сварочной проволоки путем стилоскопирования или другим спектральным методом, обеспечивающим подтверждение наличия в металле легирующих элементов',
          'Механические свойства металла шва каждой партии проволоки с каждой партией флюса, которые будут использованы совместно для автоматической сварки под флюсом',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697031',
        text:
          'Какое из приведенных требований по установке запорных органов на питательном тракте котла указано неверно?',
        answers: [
          'На питательном трубопроводе котла должны быть установлены обратный клапан и запорный орган',
          'После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган',
          'У экономайзера, отключаемого по воде, обратный клапан и запорный орган устанавливаются как до, так и после экономайзера',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган',
        ],
      },
      {
        code: '63697032',
        text:
          'Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?',
        answers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
          'Проектной (конструкторской) и технической документации оборудования под давлением, монтаж, ремонт, реконструкция (модернизация) которого осуществляется',
          'Нормативных документов, необходимость применения которых для обеспечения требований промышленной безопасности, установленных законодательством в области промышленной безопасности при выполнении соответствующих работ установлена специализированной организацией в виде утвержденного перечня или иного распорядительного документа',
          'Технологической документации на производство заявленных видов работ, разработанной до начала этих работ',
        ],
        correctAnswers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
        ],
      },
      {
        code: '63697033',
        text:
          'Каково минимальное значение высоты тоннеля (коллектора) и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в проходных тоннелях (коллекторах)?',
        answers: [
          'Высота тоннеля (коллектора) в свету 1,8 метра, ширина прохода не нормируется',
          'Высота тоннеля (коллектора) в свету 1,5 метра, ширина прохода 0,5 метра',
          'Высота тоннеля (коллектора) в свету 2 метра, ширина прохода 0,7 метра',
          'Высота тоннеля (коллектора) не нормируется, ширина прохода 0,7 метра',
        ],
        correctAnswers: [
          'Высота тоннеля (коллектора) в свету 2 метра, ширина прохода 0,7 метра',
        ],
      },
      {
        code: '63697034',
        text:
          'На каких деталях теплосилового оборудования ТЭС проводится капиллярный контроль?',
        answers: [
          'Изготовленных только из сплавов на основе стали',
          'Изготовленных только из углеродистой стали',
          'Изготовленных только из легированной стали',
          'Изготовленных из любых конструкционных металлов',
        ],
        correctAnswers: ['Изготовленных из любых конструкционных металлов'],
      },
      {
        code: '63697035',
        text:
          'Где должен быть установлен обратный клапан при установке нескольких питательных насосов, имеющих общие всасывающие и нагнетательные трубопроводы?',
        answers: [
          'На стороне всасывания каждого центробежного насоса до запорного органа',
          'На стороне нагнетания каждого центробежного насоса до запорного органа',
          'На стороне всасывания и на стороне нагнетания каждого насоса до запорного органа',
          'На общем нагнетательном трубопроводе после запорных органов на стороне нагнетания центробежных насосов',
        ],
        correctAnswers: [
          'На стороне нагнетания каждого центробежного насоса до запорного органа',
        ],
      },
      {
        code: '63697036',
        text:
          'Какое из приведенных требований к установке воздухосборников указано неверно?',
        answers: [
          'Если воздухосборник размещается в одном здании с питающим его источником, то расстояние между воздухосборником и стеной здания должно быть не менее 1 метра',
          'При установке нескольких воздухосборников расстояние между ними должно быть не менее 1,5 метров',
          'Ограждение воздухосборника должно находиться на расстоянии не менее 2 метров от воздухосборника в сторону проезда или прохода',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Если воздухосборник размещается в одном здании с питающим его источником, то расстояние между воздухосборником и стеной здания должно быть не менее 1 метра',
        ],
      },
      {
        code: '63697037',
        text:
          'Какой класс чувствительности принят в качестве оптимального при капиллярном контроле (цветном или люминесцентном) теплосилового оборудования ТЭС?',
        answers: [
          'II класс, соответствующий предельной ширине выявляемой несплошности от 1,0 до 10,0 мкм',
          'III класс, соответствующий предельной ширине выявляемой несплошности от 10,0 до 100,0 мкм',
          'IV класс, соответствующий предельной ширине выявляемой несплошности от 100,0 до 500,0 мкм',
          'I класс, соответствующий предельной ширине выявляемой несплошности до 1,0 мкм',
          'Технологический класс',
        ],
        correctAnswers: [
          'II класс, соответствующий предельной ширине выявляемой несплошности от 1,0 до 10,0 мкм',
        ],
      },
      {
        code: '63697038',
        text:
          'Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?',
        answers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
          'В случае невозможности контроля сварного соединения с двух сторон его контроль должен быть проведен в порядке, предусмотренном технологической документацией на сварку',
          'Перед визуальным контролем поверхности сварного шва и прилегающих к нему участков основного металла шириной не менее 10 мм в обе стороны от шва должны быть зачищены от шлака и других загрязнений',
        ],
        correctAnswers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
        ],
      },
      {
        code: '63697039',
        text:
          'Кто проводит техническое диагностирование в объеме ЭПБ, на основании которого устанавливаются возможность и срок дальнейшей безопасной эксплуатации оборудования ТЭС после выработки им своего назначенного ресурса (срока службы)?',
        answers: [
          'Проектная организация',
          'Эксплуатирующая организация',
          'Экспертная организация, обладающая лицензией на осуществление деятельности по проведению экспертизы промышленной безопасности',
          'Территориальный орган Ростехнадзора',
        ],
        correctAnswers: [
          'Экспертная организация, обладающая лицензией на осуществление деятельности по проведению экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63697040',
        text:
          'В каком случае допускается расположение групповых баллонных установок и хранение баллонов с горючими газами в помещении, где осуществляется технологический процесс использования находящегося в них горючего газа?',
        answers: [
          'При согласовании с проектной организацией',
          'Если размещаются на расстоянии не менее 1 м от радиаторов отопления и других отопительных приборов, печей и не менее 5 м от источников тепла с открытым огнем',
          'При согласовании с техническим руководителем организации',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63697041',
        text:
          'Какое из перечисленных требований при измерении твердости металла указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Контроль твердости следует проводить при температуре металла, не выходящей за пределы от 0 °C до +50 °C',
          'При использовании прибора динамического вдавливания толщина стенки должна составлять не менее значения, указанного в паспорте на конкретный переносной твердомер',
          'Расстояние между центрами двух соседних отпечатков при использовании приборов механического действия должно быть не менее 6d, а расстояние от центра отпечатка до края изделия - не менее 4d, где d - диаметр или диагональ отпечатка',
          'При измерении твердости с помощью прибора статического вдавливания толщина стенки испытуемого элемента должна быть не менее 6-кратной глубины отпечатка при использовании сферического или конусного индентора и трехкратной величины диагонали отпечатка при использовании четырехгранной пирамиды',
          'Допускается незначительная вибрация объекта контроля',
        ],
        correctAnswers: [
          'Контроль твердости следует проводить при температуре металла, не выходящей за пределы от 0 °C до +50 °C',
          'При использовании прибора динамического вдавливания толщина стенки должна составлять не менее значения, указанного в паспорте на конкретный переносной твердомер',
        ],
      },
      {
        code: '63697042',
        text:
          'Где должны быть установлены методы и объемы контроля сварных соединений приварных деталей, не работающих под внутренним давлением?',
        answers: [
          'В руководстве (инструкции) по эксплуатации',
          'Сварные соединения приварных деталей, не работающих под внутренним давлением, не подлежат контролю',
          'В технологической документации',
          'Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется',
        ],
        correctAnswers: ['В технологической документации'],
      },
      {
        code: '63697043',
        text:
          'Что из перечисленного не приводится в Нормах расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Методика поверочного расчета',
          'Значения номинальных допускаемых напряжений',
          'Основные расчетные формулы',
          'Приводится все перечисленное',
        ],
        correctAnswers: ['Методика поверочного расчета'],
      },
      {
        code: '63697044',
        text:
          'В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?',
        answers: [
          'Руководителя организации',
          'Производителя сварочного оборудования',
          'Руководителя независимого аттестационного центра',
          'Инспектора Ростехнадзора',
        ],
        correctAnswers: ['Производителя сварочного оборудования'],
      },
      {
        code: '63697045',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'При техническом перевооружении ОПО, на котором используются водогрейные котлы',
          'При размещении паровых котлов в здании котельного помещения ОПО',
          'При разработке (проектировании) прямоточного котла',
        ],
        correctAnswers: ['При разработке (проектировании) прямоточного котла'],
      },
      {
        code: '63697046',
        text:
          'Каким классом точности должны быть манометры (пневмоглубиномеры) барокамеры?',
        answers: ['Не ниже 1,5', 'Не ниже 0,6', 'Не ниже 0,4', 'Не ниже 1,0'],
        correctAnswers: ['Не ниже 0,6'],
      },
      {
        code: '63697047',
        text:
          'Что из перечисленного должно быть указано в режимной карте по химическому контролю за ВХР и ВПУ?',
        answers: [
          'Погрешности применяемых методов определения с указанием правил округления результатов измерений',
          'Единицы измерения контролируемых показателей работы ВПУ и ВХР',
          'Периодичность выполнения химических анализов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697048',
        text:
          'Для каких котлов продувочные и дренажные трубопроводы, трубопроводы отбора рабочей среды должны оборудоваться не менее чем двумя запорными органами или одним запорным и одним регулирующим органом?',
        answers: [
          'Для всех барабанных паровых котлов',
          'Для котлов с рабочим давлением более 0,8 МПа',
          'Для котлов с паропроизводительностью более 4 тонн в час',
          'Если условный проход таких трубопроводов более 20 мм',
        ],
        correctAnswers: ['Для котлов с рабочим давлением более 0,8 МПа'],
      },
      {
        code: '63697049',
        text:
          'В каком случае допускается смещение сроков эксплуатационного контроля металла оборудования или групп элементов оборудования в большую сторону?',
        answers: [
          'На 20% от соответствующего назначенного ресурса, указанного в технической документации',
          'На 15% от соответствующего назначенного ресурса, указанного в технической документации',
          'На 5% от соответствующего назначенного ресурса, указанного в технической документации',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'На 5% от соответствующего назначенного ресурса, указанного в технической документации',
        ],
      },
      {
        code: '63697050',
        text:
          'Что не контролируется при проведении проверки готовности котла к пуску в работу?',
        answers: [
          'Исправность питательных приборов котла и соответствие их проекту',
          'Наличие документации по результатам пуско-наладочных испытаний и комплексного опробования',
          'Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию котла',
          'Соответствие водно-химического режима котла требованиям Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: [
          'Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию котла',
        ],
      },
      {
        code: '63697051',
        text:
          'Кем принимается решение о смещении сроков эксплуатационного контроля металла для групп элементов оборудования, не отработавших назначенный ресурс, или элементов, для которых назначенный ресурс не устанавливается?',
        answers: [
          'Комиссией эксплуатирующей организации',
          'Руководителем эксплуатирующей организации',
          'Инспектором территориального органа Ростехнадзора',
          'Руководителем проектной организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63697052',
        text:
          'Какие требования, касающиеся приварки и удаления вспомогательных элементов, а также прихватки собранных под сварку элементов, должна предусматривать технологическая документация на сварку?',
        answers: [
          'Прихватки при дальнейшем проведении сварочных работ удаляют или переплавляют основным швом',
          'Прихватка собранных под сварку элементов должна быть выполнена с использованием сварочных материалов, указанных в технологической документации для данной операции',
          'При приварке вспомогательных элементов размеры закалочных зон в металле оборудования не должны превышать минимальных значений, указанных в технологической документации, а также должно быть исключено образование трещин в металле оборудования под давлением',
        ],
        correctAnswers: [
          'Прихватки при дальнейшем проведении сварочных работ удаляют или переплавляют основным швом',
        ],
      },
      {
        code: '63697053',
        text:
          'Каким максимальным диаметром следует производить вырезку пробы металла (пробки) из барабана?',
        answers: ['50 мм', '100 мм', '200 мм', '150 мм'],
        correctAnswers: ['100 мм'],
      },
      {
        code: '63697054',
        text:
          'Как контролируется работоспособность барокамеры длительного пребывания и ее систем и средств жизнеобеспечения при работе по прямому назначению при проверке барокамеры в действии?',
        answers: [
          'На воздухе под давлением газовой среды, соответствующим рабочему давлению в барокамере',
          'На воздухе при нормальном атмосферном давлении',
          'Газовой средой (кислородно-гелиевой) при рабочем давлении в барокамере',
          'Верны все ответы',
        ],
        correctAnswers: ['Верны все ответы'],
      },
      {
        code: '63697055',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) криогенных сосудов при наличии вакуума в изоляционном пространстве? Где в формулах: Рраб - рабочее давление сосуда, Ррасч - расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,25 Рраб - 0,1',
          'Рпр = 1,5 Рраб - 0,1',
          'Рпр = 1,25 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,25 Рраб + 0,1',
        ],
        correctAnswers: ['Рпр = 1,25 Рраб - 0,1'],
      },
      {
        code: '63697056',
        text:
          'Что из перечисленного включает в себя первичное техническое освидетельствование барокамеры (если нет иных указаний в технической документации)?',
        answers: [
          'Гидравлические испытания на прочность',
          'Пневматические испытания на герметичность и плотность',
          'Проверку технической документации',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697057',
        text:
          'Кем должны определяться необходимость превышения расчетного давления над рабочим, а также размеры этого превышения, согласно Нормам расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Ростехнадзором',
          'Конструкторской организацией',
          'Специализированной научно-исследовательской организацией',
        ],
        correctAnswers: ['Конструкторской организацией'],
      },
      {
        code: '63697058',
        text: 'Что из перечисленного обозначает личные шифры клейм сварщиков?',
        answers: [
          'Уникальный шифр, содержащий шестизначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и меняющийся при последующих аттестациях',
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
        correctAnswers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
      },
      {
        code: '63697059',
        text:
          'Какими методами выполняется металлографическое исследование металла в рамках неразрушающего контроля?',
        answers: [
          'Приготовлением металлографических шлифов непосредственно на поверхности контролируемых элементов оборудования с последующим просмотром, фотографированием и анализом микроструктуры с помощью переносных мобильных металлографических микроскопов',
          'Снятием реплик (оттисков) с подготовленных на поверхности деталей металлографических шлифов и последующим анализом микроструктуры на репликах в металлографической лаборатории',
          'Отбором локальных выборок металла (сколов, спилов, срезов), не нарушающих целостность и работоспособность элемента, с последующим приготовлением шлифа и анализом микроструктуры в металлографической лаборатории',
          'Всеми перечисленными методами',
        ],
        correctAnswers: ['Всеми перечисленными методами'],
      },
      {
        code: '63697060',
        text:
          'Для каких трубопроводов эксплуатирующая организация обязана обеспечить проведение систематических наблюдений (контроля) за ростом остаточных деформаций?',
        answers: [
          'Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С',
          'Паропровод из легированной хромомолибденовой стали, работающий при температуре пара свыше 400 °С до 500 °С',
          'Паропровод из высоколегированной хромистой стали, работающий при температуре пара свыше 300 °С до 540 °С',
          'Для всех указанных трубопроводов устанавливается систематическое наблюдение за ростом остаточных деформаций',
        ],
        correctAnswers: [
          'Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С',
        ],
      },
      {
        code: '63697061',
        text:
          'Что должна дополнительно предусматривать программа производственной аттестации технологии газовой сварки для деталей из аустенитных сталей и высокохромистых сталей мартенситного и мартенситно-ферритного классов?',
        answers: [
          'Для деталей из аустенитных сталей и высокохромистых сталей мартенситного и мартенситно-ферритного классов не допускается применение газовой сварки',
          'Определение механических свойств при нормальной (20 ± 10 °С) и рабочей температуре, в том числе временное сопротивление разрыву, предел текучести, относительное удлинение и относительное сужение металла шва, ударная вязкость металла шва и зоны термического влияния сварки',
          'Дополнительные испытания на определение интенсивности окисляемости в рабочей среде',
          'Проведение испытаний на стойкость против межкристаллитной коррозии',
        ],
        correctAnswers: [
          'Для деталей из аустенитных сталей и высокохромистых сталей мартенситного и мартенситно-ферритного классов не допускается применение газовой сварки',
        ],
      },
      {
        code: '63697062',
        text:
          'Какое минимальное количество взаимозаменяемых циркуляционных сетевых насосов должно быть установлено в котельных с водогрейными котлами паропроизводительностью более 4 т/ч?',
        answers: ['Два', 'Четыре', 'Три', 'Не регламентируется'],
        correctAnswers: ['Два'],
      },
      {
        code: '63697063',
        text:
          'Какой бригадой должен выполняться контроль за металлом непосредственно на оборудовании?',
        answers: [
          'В составе не менее трех человек',
          'В составе не менее двух человек',
          'В составе не менее четырех человек',
          'В составе от одного человека и более',
        ],
        correctAnswers: ['В составе не менее двух человек'],
      },
      {
        code: '63697064',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов 20 Дж/см² и менее? Где в формулах: Рраб - рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.',
        answers: [
          'Рпр = [1,25 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,25 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,5 Км + 1,6 (1 - Км)] Рраб',
          'Рпр = 1,5 Рраб ([σ]20 / [σ]t)',
        ],
        correctAnswers: ['Рпр = [1,25 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63697065',
        text:
          'Какой расчетный срок службы трубопровода рекомендуется принимать при оценке циклической прочности, согласно Нормам расчета на прочность трубопроводов тепловых сетей?',
        answers: [
          'Не менее 30 лет, если в задании на проектирование не оговорено иного срока',
          'Не менее 25 лет, если в задании на проектирование не оговорено иного срока',
          'Не менее 15 лет, если в задании на проектирование не оговорено иного срока',
          'Не менее 20 лет, если в задании на проектирование не оговорено иного срока',
        ],
        correctAnswers: [
          'Не менее 25 лет, если в задании на проектирование не оговорено иного срока',
        ],
      },
      {
        code: '63697066',
        text:
          'Какое требование к выполнению сварки в условиях отрицательной температуры указано неверно?',
        answers: [
          'При отрицательной температуре окружающего воздуха должны быть созданы необходимые условия для защиты места сварки и сварщика от воздействий ветра и атмосферных осадков',
          'При отрицательной температуре окружающего воздуха металл в районе сварного соединения перед сваркой должен быть просушен и прогрет с доведением температуры до положительного значения',
          'При отрицательной температуре окружающего воздуха подогрев производят в тех же случаях, что и при положительной, при этом температура подогрева должна быть выше на 30 °С',
        ],
        correctAnswers: [
          'При отрицательной температуре окружающего воздуха подогрев производят в тех же случаях, что и при положительной, при этом температура подогрева должна быть выше на 30 °С',
        ],
      },
      {
        code: '63697067',
        text:
          'Что из перечисленного служит участком исследования микроповрежденности сварных соединений?',
        answers: [
          'Металл шва',
          'Основной металл',
          'Зона термического влияния',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697068',
        text:
          'На каких теплосетях задвижки и затворы должны иметь обводные трубопроводы (байпасы) с запорной арматурой?',
        answers: [
          'На водяных тепловых сетях внутренним диаметром 500 мм и более при рабочем давлении 1,6 МПа и более',
          'На паровых сетях внутренним диаметром 200 мм и более при рабочем давлении 1,6 МПа и более',
          'На водяных тепловых сетях внутренним диаметром 300 мм и более при рабочем давлении 2,5 МПа и более',
          'На всех перечисленных',
        ],
        correctAnswers: ['На всех перечисленных'],
      },
      {
        code: '63697069',
        text:
          'Исходя из какого условия выбирается количество питательных насосов при групповой схеме питания паровых котлов, работающих с высокотемпературными органическими и неорганическими теплоносителями?',
        answers: [
          'Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110% номинальной паропроизводительности всех рабочих котлов',
          'Чтобы суммарная подача питательных насосов обеспечивалась не менее чем тремя питательными насосами, один из которых должен быть резервным',
          'Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была равна 100% номинальной паропроизводительности всех рабочих котлов',
          'Должно быть установлено не менее двух питательных насосов с электрическим приводом, из которых один должен быть резервным',
        ],
        correctAnswers: [
          'Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110% номинальной паропроизводительности всех рабочих котлов',
        ],
      },
      {
        code: '63697070',
        text:
          'Для какой арматуры трубопроводов пара и горячей воды должен быть предусмотрен электро-, гидро- или пневмопривод?',
        answers: [
          'Для задвижек и затворов диаметром 500 мм и более',
          'Для задвижек и затворов диаметром до 300 мм',
          'Для задвижек и затворов трубопроводов тепловых сетей, проложенных в непроходных и полупроходных каналах независимо от диаметра',
          'Для задвижек трубопроводов при наземной прокладке тепловых сетей диаметром более 300 мм',
        ],
        correctAnswers: ['Для задвижек и затворов диаметром 500 мм и более'],
      },
      {
        code: '63697071',
        text: 'Кто производит установку и монтаж барокамеры?',
        answers: [
          'Специализированные организации',
          'Организации-изготовители',
          'Эксплуатирующие организации',
        ],
        correctAnswers: ['Специализированные организации'],
      },
      {
        code: '63697072',
        text:
          'В каком случае не предусматривается устройство (регулятор), обеспечивающее достаточный противоподпор (сопротивление) на выдохе для недопущения травмы водолазов, в случае использования в барокамерах дыхательных масок?',
        answers: [
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере выше 0,3 МПа',
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере от 0,2 МПа до 0,6 МПа',
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере до 0,1 МПа',
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере выше 0,4 МПа',
          'Должно быть предусмотрено во всех случаях',
        ],
        correctAnswers: [
          'В случае использования в барокамерах дыхательных масок, работающих при давлении в барокамере до 0,1 МПа',
        ],
      },
      {
        code: '63697073',
        text:
          'В каком случае проектом размещения сосуда допускается его установка в производственных помещениях?',
        answers: [
          'Если объем производственного помещения не менее чем в 27 раз больше вместимости сосуда',
          'Если рабочее давление сосуда не превышает 1,5 МПа',
          'Если по условиям технологического процесса или условиям эксплуатации невозможна установка сосуда вне производственных помещений',
          'Если установка сосуда в производственных помещениях предусмотрена отраслевыми правилами безопасности',
        ],
        correctAnswers: [
          'Если по условиям технологического процесса или условиям эксплуатации невозможна установка сосуда вне производственных помещений',
        ],
      },
      {
        code: '63697074',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
          'Только протоколы испытаний сварных соединений',
          'Только журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63697075',
        text:
          'Какое требование к обеспечению прогрева и продувки паропроводов указано неверно?',
        answers: [
          'Паропроводы на давление 20 МПа и выше должны быть обеспечены штуцерами с последовательно расположенными запорным и регулирующим вентилями и дроссельной шайбой',
          'Все участки паропроводов, которые могут быть отключены запорными органами, для возможности их прогрева и продувки должны быть снабжены в концевых точках штуцером с вентилем',
          'В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода',
          'Паропроводы при давлении свыше 2,2 МПа должны быть снабжены штуцером и двумя последовательно расположенными вентилями: запорным и регулирующим',
        ],
        correctAnswers: [
          'В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода',
        ],
      },
      {
        code: '63697076',
        text:
          'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
          'Оборудование и материалы должны быть экономичными в использовании, простыми в ремонте',
          'Оборудование и материалы должны обеспечивать максимальную производительность работ',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63697077',
        text:
          'Отсутствие какого из приведенных требований не является препятствием для допуска к применению технологии сварки при монтаже, ремонте, реконструкции (модернизации) трубопровода?',
        answers: [
          'Аттестация технологии сварки',
          'Подтверждение технологичности сварки на реальных изделиях',
          'Наличие системы качества в соответствии с международными стандартами серии ISO 9000',
          'Проверка всего комплекса требуемых свойств (характеристик) сварных соединений и освоение эффективных методов контроля их качества',
        ],
        correctAnswers: [
          'Наличие системы качества в соответствии с международными стандартами серии ISO 9000',
        ],
      },
      {
        code: '63697078',
        text:
          'Какая допускается выпуклость (усиление) сварных швов при толщине стенки труб менее 10 мм по результатам визуально-измерительного контроля?',
        answers: [
          'В пределах 0,5 - 4,5 мм',
          'В пределах 0,5 - 4,0 мм',
          'В пределах 0,5 - 3,5 мм',
          'В пределах 0,5 - 3,0 мм',
        ],
        correctAnswers: ['В пределах 0,5 - 3,0 мм'],
      },
      {
        code: '63697079',
        text:
          'На кого возлагается контроль за соблюдением требований ремонтных рабочих чертежей и технологической документации на ремонт?',
        answers: [
          'Контроль осуществляется совместно эксплуатирующей организацией и представителем Ростехнадзора',
          'На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации',
          'На ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: [
          'На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации',
        ],
      },
      {
        code: '63697080',
        text:
          'Каково минимальное значение свободной высоты от уровня земли, пола здания (помещения), площадок (мостиков) и ступеней лестниц обслуживания?',
        answers: ['2,5 м', '2,0 м', '1,7 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63697081',
        text:
          'С какой периодичностью должен быть подтвержден уровень расчетных характеристик используемых металлов и полуфабрикатов периодическим контролем качества продукции, согласно Нормам расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 7 лет',
        ],
        correctAnswers: ['Не реже одного раза в 5 лет'],
      },
      {
        code: '63697082',
        text:
          'В каком случае допускается не оснащать обратным клапаном линию подвода рабочей среды, отнесенной к группе 1 в соответствии с техническим регламентом Таможенного союза "О безопасности оборудования, работающего под избыточным давлением", к сосуду?',
        answers: [
          'Если на линии подвода между насосом (компрессором) и сосудом установлено не менее двух запорных органов',
          'Если рабочее давление в сосуде не превышает 0,5 МПа',
          'Если рабочей средой в сосуде является сжиженный природный газ',
          'Не допускается во всех случаях: подводящая линия таких сосудов должна оснащаться обратным клапаном',
        ],
        correctAnswers: [
          'Если рабочей средой в сосуде является сжиженный природный газ',
        ],
      },
      {
        code: '63697083',
        text:
          'Какой метод неразрушающего контроля проводят до проведения контроля металла и сварных соединений (наплавок) другими методами неразрушающего контроля?',
        answers: [
          'Радиографический контроль',
          'Ультразвуковой контроль',
          'Визуально-измерительный контроль',
          'Металлографический контроль',
        ],
        correctAnswers: ['Визуально-измерительный контроль'],
      },
      {
        code: '63697084',
        text:
          'Какая рекомендуемая величина монтажной растяжки, согласно Нормам расчета на прочность трубопроводов тепловых сетей?',
        answers: [
          'Не более 60% воспринимаемого температурного расширения',
          'Не более 50% воспринимаемого температурного расширения',
          'Не более 70% воспринимаемого температурного расширения',
          'Не более 80% воспринимаемого температурного расширения',
        ],
        correctAnswers: [
          'Не более 60% воспринимаемого температурного расширения',
        ],
      },
      {
        code: '63697085',
        text:
          'Кем устанавливается периодичность отбора проб исходной, химочищенной, котловой, сетевой, питательной и подпиточной воды, конденсата и пара?',
        answers: [
          'Наладочной организацией',
          'Эксплуатирующей организацией',
          'Организацией-изготовителем котла',
          'Экспертной организацией',
        ],
        correctAnswers: ['Наладочной организацией'],
      },
      {
        code: '63697086',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов более 20 Дж/см²? Где в формулах: Рраб - рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.',
        answers: [
          'Рпр = [1,25 Км + 1,6 (1 - Км)] Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,5 Км + 1,6 (1 - Км)] Рраб',
          'Рпр = [1,6 Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,25 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)',
        ],
        correctAnswers: ['Рпр = [1,25 Км + 1,3 (1 - Км)] Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63697087',
        text:
          'Кем должен производиться выбор порядка расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Ростехнадзором',
          'Специализированной научно-исследовательской организацией',
          'Организацией, выполняющей расчет',
          'Не регламентируется',
        ],
        correctAnswers: ['Организацией, выполняющей расчет'],
      },
      {
        code: '63697088',
        text:
          'Какая должна быть минимальная ширина прохода между электрокотлами и задней стеной котельного помещения?',
        answers: ['0,7 м', '1 м', '2 м', '1,5 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63697089',
        text:
          'Установка каких котлов не допускается внутри производственных помещений?',
        answers: [
          'Котлов-утилизаторов',
          'Прямоточных котлов паропроизводительностью каждого не более 4 тонн пара в час (т/ч)',
          'Водогрейных электрокотлов при электрической мощности каждого более 5 МВт',
          'Водогрейных котлов теплопроизводительностью каждого не более 10,5 ГДж/ч (2,5 Гкал/ч), не имеющих барабанов',
          'Допускается установка всех перечисленных котлов',
        ],
        correctAnswers: [
          'Водогрейных электрокотлов при электрической мощности каждого более 5 МВт',
        ],
      },
      {
        code: '63697090',
        text:
          'Какая процедура из указанных при холодном натяге трубопроводов проводится только в случае ее необходимости?',
        answers: [
          'Окончательное закрепление неподвижных опор на концах участка, подлежащего холодному натягу',
          'Термическая обработка сварных соединений',
          'Контроль качества сварных соединений, расположенных по всей длине участка, на котором необходимо произвести холодный натяг',
          'Выполнение всех сварных соединений за исключением замыкающего',
        ],
        correctAnswers: ['Термическая обработка сварных соединений'],
      },
      {
        code: '63697091',
        text:
          'Каким образом должны выбираться методы и объем контроля качества сварных соединений при монтаже, ремонте, реконструкции (модернизации) оборудования, работающего под давлением?',
        answers: [
          'ВВ соответствии с требованиями ТР ТС 032/2013',
          'В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'В соответствии с требованиями государственных стандартов',
          'По решению руководителя организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования под давлением',
        ],
        correctAnswers: [
          'В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
      },
      {
        code: '63697092',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью 20 Дж/см² и менее? Где в формулах: Рраб - рабочее давление сосуда, Ррасч - расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,6 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,3 Ррасч ([σ]20 / [σ]t)',
          'Рпр = 1,3 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,6 Рраб',
        ],
        correctAnswers: ['Рпр = 1,6 Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63697093',
        text:
          'В каком случае манометр медицинских барокамер допускается к применению?',
        answers: [
          'Разбито стекло',
          'Просрочен срок поверки',
          'Стрелка при его отключении не возвращается к нулевому показанию шкалы на величину, превышающую половину допускаемой погрешности для данного прибора',
          'Отсутствует информация о проведении поверки',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63697094',
        text:
          'Какой организацией определяются процедуры контроля соблюдения технологических процессов при осуществлении работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением?',
        answers: [
          'Организацией, эксплуатирующей оборудование, работающее под давлением',
          'Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования',
          'Совместно с организацией, эксплуатирующей оборудование, работающее под давлением, и специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования',
          'Организацией, осуществляющей экспертизу промышленной безопасности',
        ],
        correctAnswers: [
          'Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования',
        ],
      },
      {
        code: '63697095',
        text:
          'Каким образом должен осуществляться контроль исправности пружинного предохранительного клапана, если принудительное его открывание недопустимо по условиям технологического процесса?',
        answers: [
          'Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на испытательном стенде',
          'Исправность пружинного предохранительного клапана контролируется путем кратковременного повышения давления в сосуде до значения срабатывания предохранительного клапана',
          'Исправность пружинного предохранительного клапана контролируется путем установки на сосуде двух клапанов, один из которых находится в резерве и подключается при проверке основного клапана',
          'Исправность пружинного предохранительного клапана контролируется при его отключении от сосуда с помощью запорной арматуры, установленной между сосудом и предохранительным клапаном',
        ],
        correctAnswers: [
          'Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на испытательном стенде',
        ],
      },
      {
        code: '63697096',
        text:
          'Какое из приведенных требований к выбору напора питательного насоса при групповом питании котлов указано неверно?',
        answers: [
          'Напор, создаваемый насосом, должен обеспечивать питание котла водой при рабочем давлении за котлом с учетом гидростатической высоты и потерь давления в тракте котла, регулирующем устройстве и тракте питательной воды',
          'Характеристика насоса должна также обеспечивать отсутствие перерывов в питании котла при срабатывании предохранительных клапанов с учетом наибольшего повышения давления при их полном открывании',
          'Напор насоса должен выбираться исходя из условия обеспечения питания котла с наибольшим рабочим давлением или наибольшей потерей напора в питательном трубопроводе',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: ['Все приведенные требования указаны верно'],
      },
      {
        code: '63697097',
        text:
          'На какие из приведенных ОПО не распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На ОПО, на которых используются паровые и жидкостные котлы, работающие с высокотемпературными органическими и неорганическими теплоносителями',
          'На ОПО, на которых используются сосуды вместимостью не более 0,025 м3 (независимо от давления) для научно-экспериментальных целей',
          'На ОПО, на которых используются котлы передвижных и транспортабельных установок',
          'На ОПО, на которых используются барокамеры',
        ],
        correctAnswers: [
          'На ОПО, на которых используются сосуды вместимостью не более 0,025 м3 (независимо от давления) для научно-экспериментальных целей',
        ],
      },
      {
        code: '63697098',
        text:
          'В какой документации указывается необходимость проведения и объем ультразвукового и радиографического контроля, типы и размеры несплошностей (дефектов), подлежащих обнаружению, для конкретного вида (типа) конструкции оборудования под давлением и сварного соединения?',
        answers: [
          'Руководстве (инструкции) по эксплуатации',
          'Проектной (конструкторской) и технологической документацией',
          'Паспорте оборудования',
        ],
        correctAnswers: [
          'Проектной (конструкторской) и технологической документацией',
        ],
      },
      {
        code: '63697099',
        text:
          'Из какого количества этапов состоит полный поверочный расчет, согласно Нормам расчета на прочность трубопроводов тепловых сетей?',
        answers: [
          'Из шести этапов',
          'Из четырех этапов',
          'Из пяти этапов',
          'Из трех этапов',
        ],
        correctAnswers: ['Из четырех этапов'],
      },
      {
        code: '63697100',
        text:
          'В каких целях проводится производственная аттестация технологии сварки?',
        answers: [
          'Для подтверждения того, что организация, занимающаяся монтажом, ремонтом, реконструкцией (модернизацией) оборудования под давлением, обладает техническими, организационными возможностями и квалифицированными кадрами для производства сварки по аттестуемым технологиям, а также проверки того, что сварные соединения (наплавки), выполненные в условиях конкретного производства по аттестуемой технологии, обеспечивают соответствие требованиям Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, нормативных документов и технологической документации по сварке',
          'Для выдачи рекомендаций, необходимых для практического применения данной технологии другими специализированными организациями, осуществляющими монтаж, ремонт, реконструкцию (модернизацию) аналогичного оборудования, работающего под давлением',
          'Для определения характеристик сварных соединений, необходимых для расчетов при проектировании и выдаче технологических рекомендаций',
          'Для проверки соответствия применяемой технологии сварки результатам и рекомендациям, полученным при исследовательской аттестации технологии сварки',
        ],
        correctAnswers: [
          'Для подтверждения того, что организация, занимающаяся монтажом, ремонтом, реконструкцией (модернизацией) оборудования под давлением, обладает техническими, организационными возможностями и квалифицированными кадрами для производства сварки по аттестуемым технологиям, а также проверки того, что сварные соединения (наплавки), выполненные в условиях конкретного производства по аттестуемой технологии, обеспечивают соответствие требованиям Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, нормативных документов и технологической документации по сварке',
        ],
      },
      {
        code: '63697101',
        text:
          'В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?',
        answers: [
          'Отклонения от проектной документации не допускаются',
          'В технически обоснованных случаях отклонение от проектной документации согласуется с ее разработчиком или со специализированной экспертной организацией',
          'Отклонения от проектной документации оформляются в виде специальных технических условий, подлежащих утверждению в установленном порядке',
        ],
        correctAnswers: ['Отклонения от проектной документации не допускаются'],
      },
      {
        code: '63697102',
        text:
          'В каком случае допускается применение при монтаже, ремонте и реконструкции (модернизации) оборудования под давлением полуфабрикатов, изготовленных из новых материалов?',
        answers: [
          'Допускается на основании результатов исследований (исследовательской аттестации), выполненных научно-исследовательской организацией, подтверждающих обеспечение безопасных эксплуатационных параметров, а также положительного опыта их применения при изготовлении оборудования под давлением',
          'Допускается на основании согласования с проектной организацией или изготовителем оборудования применение полуфабрикатов, изготовленных из новых материалов',
          'Допускается на основании документов, подтверждающих соответствие полуфабрикатов, изготовленных из новых материалов, требованиям ТР ТС 032/2013 и на основании положительного опыта их применения при изготовлении оборудования под давлением',
          'Категорически не допускается',
        ],
        correctAnswers: [
          'Допускается на основании результатов исследований (исследовательской аттестации), выполненных научно-исследовательской организацией, подтверждающих обеспечение безопасных эксплуатационных параметров, а также положительного опыта их применения при изготовлении оборудования под давлением',
        ],
      },
      {
        code: '63697103',
        text:
          'Каковы предельно допустимые значения ширины, высоты между ступенями и ширины ступеней лестниц для обслуживания, осмотра и ремонта оборудования под давлением?',
        answers: [
          'Лестницы должны иметь ширину не менее 500 мм, высоту между ступенями не более 100 мм, ширину ступеней не менее 50 мм',
          'Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм',
          'Лестницы должны иметь ширину не менее 800 мм, ширину ступеней не более 80 мм; высота ступеней лестницы должна быть не более 250 мм',
        ],
        correctAnswers: [
          'Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм',
        ],
      },
      {
        code: '63697104',
        text:
          'При каком условии допускается установка переключающего устройства перед мембранными предохранительными устройствами?',
        answers: [
          'Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства',
          'Если мембранные предохранительные устройства соединены последовательно с пружинными предохранительными клапанами, а также с переключающими устройствами, при условии контроля исправности мембран с помощью сигнальных манометров',
          'Установка переключающего устройства перед мембранными предохранительными устройствами не допускается',
        ],
        correctAnswers: [
          'Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства',
        ],
      },
      {
        code: '63697105',
        text:
          'В каких трубопроводах при расчете деформаций компенсаторов следует учитывать внутреннее давление, согласно Нормам расчета на прочность трубопроводов тепловых сетей?',
        answers: [
          'С сальниковыми компенсаторами',
          'С сильфонными компенсаторами',
          'С линзовыми компенсаторами',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63697106',
        text:
          'Кто должен представить специалистам организации, проводящей техническое диагностирование медицинской барокамеры, полный комплект технической документации на барокамеру?',
        answers: [
          'Руководитель (технический руководитель) организации-изготовителя',
          'Дежурный персонал',
          'Ответственный за исправное состояние барокамеры',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Ответственный за исправное состояние барокамеры'],
      },
      {
        code: '63697107',
        text:
          'Для определения содержания в металле каких перечисленных элементов могут применяться рентгенофлуоресцентные анализаторы?',
        answers: [
          'Углерода',
          'Фосфора',
          'Кремния',
          'Серы',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63697108',
        text:
          'Каким документом устанавливается численность персонала специализированной организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования под давлением?',
        answers: [
          'Договором, заключаемым специализированной и эксплуатирующей организациями',
          'Технологическим регламентом на проведение соответствующих работ в целях обеспечения выполнения технологических процессов при их производстве',
          'Распорядительным документом специализированной организации',
          'Требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется',
        ],
        correctAnswers: [
          'Распорядительным документом специализированной организации',
        ],
      },
      {
        code: '63697109',
        text:
          'Какие из перечисленных сведений не указываются в обязательном порядке на бирке, закрепленной на каждой части сварочной проволоки, отделенной от бухты (мотка)?',
        answers: [
          'Марка проволоки',
          'Номер плавки',
          'Длина части проволоки',
          'Диаметр проволоки',
        ],
        correctAnswers: ['Длина части проволоки'],
      },
      {
        code: '63697110',
        text:
          'Какое значение назначенного ресурса составляет для элементов паропроводов из углеродистой стали, работающих при температурах от 390 до 450 °C?',
        answers: [
          '100 тысяч часов',
          '150 тысяч часов',
          '250 тысяч часов',
          '50 тысяч часов',
        ],
        correctAnswers: ['150 тысяч часов'],
      },
      {
        code: '63697111',
        text:
          'Какой класс точности должны иметь манометры, установленные на подводящих трубопроводах к медицинской барокамере?',
        answers: ['Не ниже 1,0', 'Не ниже 2,5', 'Не ниже 1,5', 'не ниже 4,0'],
        correctAnswers: ['Не ниже 2,5'],
      },
      {
        code: '63697112',
        text:
          'Какое должно быть расстояние по вертикали от площадки для обслуживания водоуказательных приборов до середины водоуказательного стекла (шкалы)?',
        answers: [
          'Не менее 0,7 м и не более 2 м',
          'Не менее 1 м и не более 1,5 м',
          'Не менее 1,5 м и не более 2,5 м',
          'Не менее 1,2 м и не более 3 м',
        ],
        correctAnswers: ['Не менее 1 м и не более 1,5 м'],
      },
      {
        code: '63697113',
        text:
          'К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?',
        answers: [
          'К выполнению сварочных работ, указанных в действующих документах',
          'К сварочным работам, на которые они когда-либо были аттестованы независимо от того, истек ли срок действия соответствующих аттестационных удостоверений',
          'К любым сварочным работам при наличии действующих аттестационных удостоверений по какому-либо способу сварки',
        ],
        correctAnswers: [
          'К выполнению сварочных работ, указанных в действующих документах',
        ],
      },
      {
        code: '63697114',
        text:
          'Каким документом определяются конкретные решения по установке сосуда, способу крепления и устройству фундамента?',
        answers: [
          'Инструкцией по монтажу',
          'Проектной документацией',
          'Руководством (инструкцией) по эксплуатации',
          'Чертежами сосуда',
          'Учитываются все перечисленные документы',
        ],
        correctAnswers: ['Учитываются все перечисленные документы'],
      },
      {
        code: '63697115',
        text:
          'Какое из приведенных требований должно выполняться при исправлении дефектов сварных соединений?',
        answers: [
          'Удаление дефектов следует проводить только способом термической резки (строжки) с обеспечением плавных переходов в местах выборок',
          'Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией',
          'Полнота удаления дефектов должна быть проконтролирована ультразвуковой дефектоскопией',
        ],
        correctAnswers: [
          'Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией',
        ],
      },
      {
        code: '63697116',
        text:
          'Какие из перечисленных испытаний, по которым определяются механические свойства материалов, проводятся при комнатной, повышенной и пониженной температурах? Выберите 2 варианта ответа.',
        answers: [
          'Статические испытания на растяжение образцов труб',
          'Статические испытания на растяжение стандартных образцов металла',
          'Испытания на ударный изгиб стандартных образцов металла',
          'Статические испытания на растяжение и испытания на ударный изгиб образцов сварных соединений',
        ],
        correctAnswers: [
          'Испытания на ударный изгиб стандартных образцов металла',
          'Статические испытания на растяжение и испытания на ударный изгиб образцов сварных соединений',
        ],
      },
      {
        code: '63697117',
        text:
          'Какое из приведенных требований должно выполняться в случае обнаружения дефектов при ультразвуковом и радиографическом контроле сварных соединений?',
        answers: [
          'Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений',
          'При заварке по всей толщине стенки ультразвуковая дефектоскопия или радиографический контроль поверхности должен быть проведен с обеих сторон',
          'Ремонтные заварки выборок металла должны быть проверены методом магнитопорошковой или капиллярной дефектоскопии по всему участку заварки, за исключением зоны термического влияния сварки, которая контролируется ультразвуковой дефектоскопией или радиографическим контролем',
        ],
        correctAnswers: [
          'Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений',
        ],
      },
      {
        code: '63697118',
        text: 'Какое требование к подпитке котлов сырой водой указано неверно?',
        answers: [
          'Когда проектом предусмотрена в аварийных ситуациях подпитка водогрейного котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С',
          'Подпитка сырой водой котлов, оборудованных устройствами для докотловой обработки воды, не допускается',
          'Каждый случай подпитки котлов сырой водой должен фиксироваться в журнале по водоподготовке (водно-химическому режиму) с указанием длительности подпитки и качества питательной воды в этот период',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Когда проектом предусмотрена в аварийных ситуациях подпитка водогрейного котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С',
        ],
      },
      {
        code: '63697119',
        text:
          'При каком давлении проводится проверка на плотность вентиля баллона после ремонта, связанного с его разборкой?',
        answers: [
          'При рабочем давлении',
          'При давлении, равном 1,25 рабочего давления',
          'При давлении, равном 1,5 рабочего давления',
          'Проверка на плотность не проводится',
        ],
        correctAnswers: ['При рабочем давлении'],
      },
      {
        code: '63697120',
        text:
          'Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?',
        answers: [
          'Совместно специализированной организацией, выполняющей эти работы, и эксплуатирующей организацией',
          'Эксплуатирующей организацией на основании руководства (инструкции) по эксплуатации оборудования, работающего под давлением, с последующим согласованием со специализированной организацией, выполняющей эти работы',
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
          'Организацией - изготовителем оборудования, работающего под давлением',
        ],
        correctAnswers: [
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
        ],
      },
      {
        code: '63697121',
        text:
          'Какое качество поверхности по шероховатости следует обеспечивать для измерения твердости металла?',
        answers: [
          'Не хуже Ra 1,6',
          'Не хуже Ra 3,2',
          'Не хуже Ra 6,3',
          'Не хуже Ra 12,5',
        ],
        correctAnswers: ['Не хуже Ra 3,2'],
      },
      {
        code: '63697122',
        text:
          'Кем определяются требования к монтажу и эксплуатации барокамер в контейнерах различных конструкций, перевозимых на различных шасси или устанавливаемых стационарно?',
        answers: [
          'Организацией-изготовителем таких комплексов',
          'Экспертной организацией',
          'Научно-исследовательской организацией',
          'Эксплуатирующей организацией по согласованию с территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Организацией-изготовителем таких комплексов'],
      },
      {
        code: '63697123',
        text:
          'В каком из приведенных случаев трубопровод считается выдержавшим гидравлическое испытание?',
        answers: [
          'Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание',
          'Если обнаружены единичные трещины не в сварных соединениях, а на основном металле',
          'Если обнаружено падение давления по манометру, не превышающее 20 % от значения пробного давления',
          'Если в сварном соединении обнаружено появление отдельных капель воды',
        ],
        correctAnswers: [
          'Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание',
        ],
      },
      {
        code: '63697124',
        text:
          'Какое требование к установке запорной арматуры на тепловых сетях указано неверно?',
        answers: [
          'Установка запорной арматуры предусматривается в водяных и паровых тепловых сетях в узлах на трубопроводах ответвлений условным диаметром более 100 мм',
          'Установка запорной арматуры предусматривается на трубопроводах водяных сетей условным диаметром 100 мм и более на расстоянии не более 1000 метров',
          'Установка запорной арматуры предусматривается на конденсатопроводах условным диаметром свыше 100 мм на вводе к сборному баку конденсата',
          'Установка запорной арматуры предусматривается на всех трубопроводах выводов тепловых сетей от источников теплоты независимо от параметров теплоносителей',
        ],
        correctAnswers: [
          'Установка запорной арматуры предусматривается на конденсатопроводах условным диаметром свыше 100 мм на вводе к сборному баку конденсата',
        ],
      },
      {
        code: '63697125',
        text:
          'В каком из перечисленных случаях применяются магнитный контроль тепловой неравномерности и магнитная ферритометрия?',
        answers: [
          'На трубах, заглушенных, испытавших после ремонта менее трех температурных циклов типа "пуск-останов" котла',
          'На плавниковых трубах',
          'На трубах, новых, испытавших после монтажа менее трех температурных циклов типа "пуск-останов" котла',
          'На оребренных (ошипованных) трубах',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63697126',
        text:
          'Кто устанавливает полный перечень предельно допустимых показателей качества исходной воды, приведенных в режимной карте по ВПУ?',
        answers: [
          'Проектная организация',
          'Пусконаладочная организация',
          'Эксплуатирующая организация',
          'Ростехнадзор',
        ],
        correctAnswers: ['Пусконаладочная организация'],
      },
      {
        code: '63697127',
        text:
          'Какая из приведенных операций не подлежит обязательному включению в программу наладочных работ, а проводится в случаях, предусмотренных проектом и руководством по эксплуатации?',
        answers: [
          'Промывка и продувка оборудования и трубопроводов',
          'Проверка измерительных приборов, настройка и проверка работоспособности систем автоматизации управления, сигнализации, аварийных защит и блокировок, а также регулировка предохранительных клапанов',
          'Опробование оборудования, включая резервное, наладка циркуляции рабочих сред, проверка работы запорной арматуры и регулирующих устройств в ручном режиме',
          'Все приведенные операции подлежат обязательному включению в перечень наладочных работ',
        ],
        correctAnswers: ['Промывка и продувка оборудования и трубопроводов'],
      },
      {
        code: '63697128',
        text:
          'Какие из приведенных мест не подлежат оборудованию аварийным освещением?',
        answers: [
          'Вентиляторные площадки',
          'Щиты и пульты управления',
          'Места установки насосного оборудования',
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
        correctAnswers: [
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
      },
      {
        code: '63697129',
        text:
          'Каким образом оформляются результаты проводимого контроля качества сварных соединений?',
        answers: [
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется. На усмотрение организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования',
          'Общие результаты по контролю качества сварных соединений оформляются отчетом, прилагаемым к паспорту монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
        ],
        correctAnswers: [
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
        ],
      },
      {
        code: '63697130',
        text:
          'Наличие и работоспособность какого поглотителя должно быть обеспечено во всех барокамерах?',
        answers: [
          'Поглотителя кислорода',
          'Поглотителя углекислого газа',
          'Поглотителя азота',
          'Поглотителя аргона',
        ],
        correctAnswers: ['Поглотителя углекислого газа'],
      },
      {
        code: '63697131',
        text:
          'Для каких котлов регулирующая арматура на питательной линии не устанавливается?',
        answers: [
          'Для котлов с рабочим давлением менее 0,8 МПа',
          'На питательной линии всех котлов обязательна установка регулирующей арматуры',
          'Для котлов с паропроизводительностью более 4 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды подачей и напором питательного насоса',
          'Для котлов с паропроизводительностью не более 2,5 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды включением и выключением насоса',
        ],
        correctAnswers: [
          'Для котлов с паропроизводительностью не более 2,5 тонн в час, у которых проектом котла предусмотрено автоматическое регулирование уровня воды включением и выключением насоса',
        ],
      },
      {
        code: '63697132',
        text:
          'Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?',
        answers: [
          'Идентификацию применяемых сварочных материалов и сварочного оборудования',
          'Регистрацию сведений о сварщиках, выполняющих сварные соединения',
          'Идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений',
          'Регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697133',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'Необходимым сварочным оборудованием и наглядными пособиями (плакатами, схемами и т. д.) по вопросу соблюдения противопожарного режима на объекте',
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
          'На рабочем месте не должно быть ничего, кроме исправного сварочного оборудования',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63697134',
        text:
          'Для какого котла допускается работа с одним электроприводным питательным насосом?',
        answers: [
          'Для котла паропроизводительностью не более 4 тонны в час, если котел оснащен автоматикой безопасности по предельным уровням воды',
          'Для котла паропроизводительностью не более 5 тонн в час, если котел оснащен автоматикой безопасности по предельным уровням воды',
          'Для всех водогрейных котлов',
          'Не допускается работа котлов с одним питательным насосом с электроприводом',
        ],
        correctAnswers: [
          'Для котла паропроизводительностью не более 4 тонны в час, если котел оснащен автоматикой безопасности по предельным уровням воды',
        ],
      },
      {
        code: '63697135',
        text:
          'При каком количестве общего выхода шлака и золы от всех котлов (независимо от их производительности) должно быть механизировано удаление золы и шлака?',
        answers: [
          '75 кг/ч и более',
          '100 кг/ч и более',
          '150 кг/ч и более',
          'Удаление золы и шлака должно быть механизировано во всех котлах',
        ],
        correctAnswers: ['150 кг/ч и более'],
      },
      {
        code: '63697136',
        text:
          'По какой системе оценивают качество сварных соединений при ультразвуковом контроле?',
        answers: [
          'По трехбалльной системе',
          'По двухбалльной системе',
          'По четырехбалльной системе',
          'По пятибалльной системе',
        ],
        correctAnswers: ['По двухбалльной системе'],
      },
      {
        code: '63697137',
        text:
          'После наработки какого количества часов устанавливается ресурс надежной эксплуатации труб поверхностей нагрева котла, работающих при 450 °C и выше, при проведении планового обследования?',
        answers: [
          '50 тысяч часов',
          '70 тысяч часов',
          '100 тысяч часов',
          '30 тысяч часов',
        ],
        correctAnswers: ['50 тысяч часов'],
      },
      {
        code: '63697138',
        text:
          'Какое требование к отключению трубопровода до начала производства ремонтных работ указано неверно?',
        answers: [
          'Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
          'Трубопровод должен быть отделен от всех трубопроводов заглушками, если на них установлена фланцевая арматура, или отсоединен от действующего оборудования',
          'Если арматура трубопроводов бесфланцевая, то отключение котла должно быть произведено двумя запорными устройствами при наличии между ними дренажного устройства с номинальным диаметром не менее 32 мм, имеющего прямое соединение с атмосферой',
          'Приводы закрытых задвижек, а также запорной арматуры открытых дренажей должны быть блокированы запирающим устройством так, чтобы исключалась возможность их открытия или закрытия',
        ],
        correctAnswers: [
          'Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
        ],
      },
      {
        code: '63697139',
        text:
          'Каким образом должно осуществляться расхолаживание котлов после остановки при выводе их в ремонт?',
        answers: [
          'Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации',
          'Режим расхолаживания котлов определяется требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Расхолаживание котлов должно осуществляться не ранее чем через 12 часов после остановки при скорости расхолаживания не выше 10 °С за 10 минут',
          'Расхолаживание осуществляется тягодутьевыми машинами при обеспечении допустимой разности температур металла между верхней и нижней образующими барабана',
        ],
        correctAnswers: [
          'Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации',
        ],
      },
      {
        code: '63697140',
        text:
          'Какое требование к складам для хранения баллонов с углекислотой указано неверно?',
        answers: [
          'В складах должны быть вывешены инструкции, правила и плакаты по обращению с баллонами, находящимися на складе',
          'Склады для баллонов, наполненных газом, должны иметь естественную или искусственную вентиляцию',
          'Склады для баллонов должны находиться в зоне молниезащиты',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Склады для баллонов должны находиться в зоне молниезащиты',
        ],
      },
      {
        code: '63697141',
        text:
          'Каким способом проводится измерение твердости металла по Бринеллю?',
        answers: [
          'Расстояние от центра отпечатка до края соседнего отпечатка или до края образца должно быть не менее 2,5 длины диагонали отпечатка',
          'Расстояние между краями двух соседних отпечатков должно быть не менее трех диаметров отпечатков, а расстояние от края отпечатка до края образца - не менее 2,5 диаметра отпечатка',
          'Расстояние между центрами двух соседних отпечатков или от центра отпечатка до края образца должно быть не менее 3,0 мм',
        ],
        correctAnswers: [
          'Расстояние между краями двух соседних отпечатков должно быть не менее трех диаметров отпечатков, а расстояние от края отпечатка до края образца - не менее 2,5 диаметра отпечатка',
        ],
      },
      {
        code: '63697142',
        text:
          'Каково минимальное значение ширины бокового прохода при установке парового котла паропроизводительностью 8 тонн в час, для которого требуется боковое обслуживание?',
        answers: ['2 м', '1,5 м', '1 м', 'Устанавливается проектом'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63697143',
        text:
          'В каких целях проводится измерение твердости при контроле сварных соединений?',
        answers: [
          'Для проверки качества выполнения термической обработки сварных соединений',
          'Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях',
          'Для подтверждения соответствия металла сварных швов и деталей, элементов оборудования под давлением из легированной стали требованиям проектной (конструкторской) и технологической документации',
        ],
        correctAnswers: [
          'Для проверки качества выполнения термической обработки сварных соединений',
        ],
      },
      {
        code: '63697144',
        text:
          'Какие требования к антикоррозионной, тепловой и гидроизоляционной защите, предусматриваемой на всех трубопроводах тепловых сетей вне зависимости от типа прокладки, указаны неверно?',
        answers: [
          'Тип и способы защиты должны определяться проектной документацией в зависимости от условий эксплуатации, конструктивного исполнения, с учетом скорости коррозионного износа применяемых материалов',
          'Антикоррозионные покрытия трубопроводов тепловых сетей и их несущих металлических конструкций должны выполняться с защитными свойствами, обеспечивающими установленный срок службы трубопровода (конструкции), и гарантированным сроком службы покрытия не менее 5 лет',
          'Порядок контроля степени коррозионного износа оборудования и трубопроводов с использованием неразрушающих методов, способы, периодичность и места проведения контрольных замеров должны определяться в проектной, технической и эксплуатационной документации с учетом конкретных условий эксплуатации',
          'Ввод в эксплуатацию тепловых сетей без наружного антикоррозионного покрытия труб и металлических конструкций не допускается',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Антикоррозионные покрытия трубопроводов тепловых сетей и их несущих металлических конструкций должны выполняться с защитными свойствами, обеспечивающими установленный срок службы трубопровода (конструкции), и гарантированным сроком службы покрытия не менее 5 лет',
        ],
      },
      {
        code: '63697145',
        text:
          'При каком условии для питания котлов водой может применяться водопроводная сеть?',
        answers: [
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,05 МПа',
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,15 МПа',
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,5 МПа',
          'Запрещается применять водопроводную сеть для питания котлов',
        ],
        correctAnswers: [
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,15 МПа',
        ],
      },
      {
        code: '63697146',
        text:
          'Какие требования, касающиеся подготовительных работ, должна предусматривать технологическая документация на сварку?',
        answers: [
          'При сборке стыковых соединений труб с односторонней разделкой кромок, свариваемых без подкладных колец и подварки корня шва, не допускается смещение (несовпадение) внутренних кромок',
          'Перед началом сварки должно быть проверено качество сборки соединяемых элементов, а также состояние стыкуемых ударным способом кромок и прилегающих к ним поверхностей, подвергающихся местному нагреву',
          'Глубина механической обработки после термической резки (строжки) при подготовке кромок под сварку должна быть не более 5% от толщины свариваемых элементов',
          'Подготовка кромок и поверхностей под сварку должна быть выполнена механической обработкой либо путем термической резки или строжки с последующей механической обработкой',
        ],
        correctAnswers: [
          'Подготовка кромок и поверхностей под сварку должна быть выполнена механической обработкой либо путем термической резки или строжки с последующей механической обработкой',
        ],
      },
      {
        code: '63697147',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'При ремонте сосудов, работающих под давлением',
          'При изготовлении сосуда, работающего под давлением',
          'При проектировании размещения сосудов, работающих под давлением, на ОПО',
          'При наладочных работах на технологическом оборудовании, включающем сосуды, работающие под давлением',
        ],
        correctAnswers: ['При изготовлении сосуда, работающего под давлением'],
      },
      {
        code: '63697148',
        text:
          'При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?',
        answers: [
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
          'Для сосудов, подлежащих учету в территориальных органах Ростехнадзора, не допускается проведение ремонта сосудов и их элементов, находящихся под давлением, для остальных сосудов – 0,05 МПа',
          '0,025 МПа',
          '0,05 МПа',
        ],
        correctAnswers: [
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
        ],
      },
      {
        code: '63697149',
        text:
          'Какое количество измерений должно быть проведено для получения представительной характеристики твердости металла (в том числе для конкретной зоны образца)?',
        answers: [
          'Не менее пяти измерений',
          'Не менее трех измерений',
          'Не менее двух измерений',
          'Не менее четырех измерений',
        ],
        correctAnswers: ['Не менее трех измерений'],
      },
      {
        code: '63697150',
        text:
          'Каково минимальное значение ширины свободного прохода площадок для обслуживания арматуры, контрольно-измерительных приборов и другого оборудования?',
        answers: ['600 мм', '700 мм', '800 мм', '1 м'],
        correctAnswers: ['800 мм'],
      },
      {
        code: '63697151',
        text:
          'Где отмечается заключение о возможности продолжения эксплуатации медицинской барокамеры?',
        answers: [
          'В журнале технического обслуживания',
          'В журнале регистрации сеансов',
          'В журнале ремонта',
          'В паспорте барокамеры',
        ],
        correctAnswers: ['В журнале регистрации сеансов'],
      },
      {
        code: '63697152',
        text:
          'Применение каких площадок и ступеней лестниц на оборудовании, работающем под избыточным давлением, запрещается?',
        answers: [
          'Из просечно-вытяжного листа',
          'Из рифленой листовой стали',
          'Из прутковой круглой стали или гладких ступеней лестниц',
          'Из сотовой или полосовой (на ребро) стали',
        ],
        correctAnswers: [
          'Из прутковой круглой стали или гладких ступеней лестниц',
        ],
      },
      {
        code: '63697153',
        text:
          'Какое из перечисленных требований к паропроводам, работающим в условиях ползучести, указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Реперы располагают по двум взаимно перпендикулярным диаметрам в средней части каждой прямой трубы, прямого участка каждого гнутого отвода на расстоянии не менее 150 мм от сварного соединения или начала гнутого участка',
          'Технический руководитель эксплуатирующей организации должен убедиться в правильности установки реперов и изображении этих мест на исполнительной схеме-формуляре',
          'Остаточную деформацию ползучести измеряют микрометром с точностью шкалы не хуже 0,05 мм по реперам, установленным на прямых трубах длиной 500 мм и более, а также на гнутых отводах, имеющих прямые участки длиной не менее 500 мм',
          'При невозможности установки реперов в двух взаимно перпендикулярных направлениях допускается установка только одной пары реперов (в диаметрально противоположных точках)',
          'Измерение остаточной деформации ползучести проводится при температуре стенки трубы (отвода) не выше 60 °C',
        ],
        correctAnswers: [
          'Остаточную деформацию ползучести измеряют микрометром с точностью шкалы не хуже 0,05 мм по реперам, установленным на прямых трубах длиной 500 мм и более, а также на гнутых отводах, имеющих прямые участки длиной не менее 500 мм',
          'При невозможности установки реперов в двух взаимно перпендикулярных направлениях допускается установка только одной пары реперов (в диаметрально противоположных точках)',
        ],
      },
      {
        code: '63697154',
        text:
          'При каком увеличении микроскопа, по шкале графитизации, оценивают стадию процесса графитизации в основном металле и сварных соединениях трубопроводов, работающих при температуре выше 390 °C и изготовленных из углеродистых и молибденовых (15М, 16М) сталей?',
        answers: [
          'При 300-кратном увеличении',
          'При 1000-кратном увеличении',
          'При 500-кратном увеличении',
          'При 1500-кратном увеличении',
        ],
        correctAnswers: ['При 500-кратном увеличении'],
      },
      {
        code: '63697155',
        text:
          'Что из перечисленного не подлежит контролю при проведении ультразвуковой дефектоскопии основных элементов и деталей оборудования?',
        answers: [
          'Наплавки на участках ремонта сварных соединений или на основном металле элементов',
          'Кольцевые угловые сварные соединения с полным проплавлением с толщиной стенки от 4,5 мм',
          'Основной металл и резьбовая поверхность деталей крепежа (шпильки, болты) диаметром М10 и более',
          'Стыковые кольцевые и продольные сварные соединения обечаек и днищ барабанов',
          'Все перечисленное подлежит контролю при проведении ультразвуковой дефектоскопии',
        ],
        correctAnswers: [
          'Основной металл и резьбовая поверхность деталей крепежа (шпильки, болты) диаметром М10 и более',
        ],
      },
      {
        code: '63697156',
        text:
          'При выполнении каких условий допускается заменять гидравлическое испытание сосуда пневматическим испытанием?',
        answers: [
          'Если пневматическое испытание одновременно контролируется методом акустической эмиссии',
          'Если при пневматическом испытании будет использована в качестве нагружающей среды газообразная рабочая среда объекта испытаний',
          'Если рабочее давление в сосуде не превышает 0,5 МПа',
          'Если проведение гидравлического испытания сопряжено с вероятностью хрупкого разрушения металла сосуда',
        ],
        correctAnswers: [
          'Если пневматическое испытание одновременно контролируется методом акустической эмиссии',
        ],
      },
      {
        code: '63697157',
        text:
          'При каком условии допускается включение котлов в одну группу по питанию?',
        answers: [
          'Если разница рабочих давлений в разных котлах не превышает 25%',
          'Если разница рабочих давлений в разных котлах не превышает 40%',
          'Если разница рабочих давлений в разных котлах не превышает 15%',
          'Если разница рабочих давлений в разных котлах не превышает 50%',
        ],
        correctAnswers: [
          'Если разница рабочих давлений в разных котлах не превышает 15%',
        ],
      },
      {
        code: '63697158',
        text:
          'Кем вносятся сведения в ремонтный журнал котла о выполненных ремонтных работах, примененных материалах, сварке и сварщиках, об остановке котлов на чистку и промывку?',
        answers: [
          'Ответственным за исправное состояние и безопасную эксплуатацию котла',
          'Ответственным за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
          'Уполномоченным представителем специализированной ремонтной организации',
          'Лицо, которое вносит сведения в ремонтный журнал котла, определяется распорядительными документами эксплуатирующей организации',
        ],
        correctAnswers: [
          'Ответственным за исправное состояние и безопасную эксплуатацию котла',
        ],
      },
      {
        code: '63697159',
        text:
          'В каком из приведенных случаев запрещается установка сосудов, работающих под давлением, подлежащих учету в территориальных органах Ростехнадзора?',
        answers: [
          'Во всех приведенных случаях допускается установка таких сосудов',
          'Установка сосуда в помещениях, примыкающих к общественным и бытовым зданиям, при условии отделения их капитальной стеной, конструктивная прочность которой определена проектной документацией',
          'Установка сосуда на открытой площадке, на которой исключается скопление людей',
          'Установка сосуда с заглублением в грунт при условии обеспечения доступа к арматуре и защиты стенок сосуда от коррозии',
        ],
        correctAnswers: [
          'Установка сосуда в помещениях, примыкающих к общественным и бытовым зданиям, при условии отделения их капитальной стеной, конструктивная прочность которой определена проектной документацией',
        ],
      },
      {
        code: '63697160',
        text:
          'Какое из приведенных требований должно выполняться при проведении гидравлического испытания трубопровода?',
        answers: [
          'Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления',
          'При появлении в период подъема давления шума, стуков следует снизить скорость подъема давления, при которой шумы и стуки прекратятся',
          'Подъем давления до значения пробного давления должен быть медленным и плавным, без толчков. Время подъема давления должно быть не менее 5 мин',
          'Если в результате заполнения трубопровода водой на его стенках появится роса, то испытание следует немедленно прекратить',
        ],
        correctAnswers: [
          'Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления',
        ],
      },
      {
        code: '63697161',
        text:
          'Что необходимо предпринять после вскрытия барабана, коллектора или ремонта котла, если характер и объем ремонта не вызывают необходимости проведения внеочередного технического освидетельствования?',
        answers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением',
          'Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла пробным давлением на 25% превышающем рабочее давление',
          'Уполномоченная специализированная организация должна провести гидравлическое испытание котла пробным давлением',
          'Уполномоченный представитель организации, проводившей ремонт (вскрытие барабана, коллектора), проводит наружный и внутренний осмотр котла',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением',
        ],
      },
      {
        code: '63697162',
        text:
          'Кем осуществляется организация работ по подготовке оборудования к эксплуатационному контролю металла и обеспечению его проведения?',
        answers: [
          'Начальником участка',
          'Руководителем эксплуатирующей организации',
          'Ответственным за производственный контроль',
          'Техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Техническим руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63697163',
        text:
          'Кто принимает решение о применении магнитного контроля тепловой неравномерности и магнитной ферритометрии?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Технический руководитель эксплуатирующей организации или ее обособленного подразделения (ТЭС)',
          'Экспертная организация',
          'Техническая комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технический руководитель эксплуатирующей организации или ее обособленного подразделения (ТЭС)',
        ],
      },
      {
        code: '63697164',
        text:
          'Какие из перечисленных действий не допускаются перед началом сварки деталей оборудования, работающего под давлением? Выберите 2 варианта ответа.',
        answers: [
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
          'Подготовка кромок и поверхностей механической обработкой',
          'Проверка качества сборки соединяемых элементов',
          'Проверка состояния стыкуемых кромок и прилегающих к ним поверхностей',
        ],
        correctAnswers: [
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
        ],
      },
      {
        code: '63697165',
        text:
          'Какую относительную погрешность измерения должны иметь магнитометры и ферритометры?',
        answers: [
          'Не более ± 7%',
          'Не более ± 10%',
          'Не более ± 3%',
          'Не более ± 5%',
        ],
        correctAnswers: ['Не более ± 5%'],
      },
      {
        code: '63697166',
        text:
          'На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?',
        answers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
          'На основании приказа руководителя эксплуатирующей организации',
          'На основании письменного распоряжения ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
        ],
        correctAnswers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
        ],
      },
      {
        code: '63697167',
        text:
          'С какой периодичностью проводится профилактическая проверка качества соединений и шлангов медицинской барокамеры визуальным осмотром?',
        answers: [
          'Еженедельно',
          'Ежедневно',
          'Ежемесячно',
          'Не регламентируется',
        ],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63697168',
        text:
          'Кто является ответственным за организацию и выполнение эксплуатационного контроля металла в объемах и в сроки, указанные в Правилах осуществления эксплуатационного контроля металла и продления срока службы основных элементов котлов и трубопроводов тепловых электростанций?',
        answers: [
          'Ответственный за производственный контроль',
          'Руководитель эксплуатирующей организации',
          'Технический руководитель эксплуатирующей организации',
          'Руководитель специализированной научно-исследовательской организации',
        ],
        correctAnswers: [
          'Технический руководитель эксплуатирующей организации',
        ],
      },
      {
        code: '63697169',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
          'Проверку знания теоретических основ сварки',
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63697170',
        text:
          'Какие трубопроводы должны быть оснащены указателями перемещений?',
        answers: [
          'Паропроводы диаметром 150 мм и более и температурой пара 300 °С и выше',
          'Все паропроводы должны оснащаться указателями перемещений',
          'Трубопроводы пара и горячей воды диаметром 150 мм и более и давлением среды свыше 0,8 МПа',
        ],
        correctAnswers: [
          'Паропроводы диаметром 150 мм и более и температурой пара 300 °С и выше',
        ],
      },
      {
        code: '63697171',
        text:
          'В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?',
        answers: [
          'Если срок эксплуатации стальных труб не превышает половины расчетного срока службы технического устройства, на котором эти трубы употреблялись',
          'В случае применения таких труб только при монтаже, ремонте, реконструкции (модернизации) водогрейных котлов и трубопроводов горячей воды',
          'Если на стальные трубы оформлены документы, подтверждающие их соответствие и качество (сертификаты, декларации соответствия)',
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов',
        ],
        correctAnswers: [
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов',
        ],
      },
      {
        code: '63697172',
        text:
          'Кем должны составляться инструкции по ведению ВХР паровых и водогрейных котлов и инструкции по эксплуатации установок докотловой обработки воды?',
        answers: [
          'Ростехнадзором',
          'Технической комиссией предприятия-владельца котла и оборудования ВПУ',
          'Специализированной пуско-наладочной организацией',
          'Проектной организацией',
        ],
        correctAnswers: ['Специализированной пуско-наладочной организацией'],
      },
      {
        code: '63697173',
        text:
          'Каково минимально допустимое значение расстояния от противоположной стены котельной до фронта электрокотла мощностью 0,75 МВт?',
        answers: ['1 м', '2 м', '3 м', 'Устанавливается проектом'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63697174',
        text:
          'В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?',
        answers: [
          'Котлы - в течение 72 часов, трубопроводы пара и горячей воды - в течение 36 часов, время комплексного опробования сосудов устанавливается совместным приказом эксплуатирующей и наладочной организаций',
          'Начало и конец комплексного опробования оборудования, работающего под давлением, устанавливаются совместным приказом эксплуатирующей организации и организации, проводящей наладочные работы',
          'Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Котлы - в течение 72 часов, трубопроводы тепловых сетей - в течение 24 часов, остальное оборудование - по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
        ],
      },
      {
        code: '63697175',
        text:
          'С кем должно согласовываться применение при ремонте оборудования под давлением материалов, не установленных требованиями технической документации изготовителя и проектной документации?',
        answers: [
          'Согласование на применение таких материалов не требуется, если ремонтной организацией в технической документации приведены доказательственные материалы по определению их механических свойств, химического состава, гарантирующих уровень принятых расчетных и технологических характеристик',
          'Применение при ремонте оборудования материалов, не установленных требованиями технической документации изготовителя и проектной документации, не допускается',
          'Применение таких материалов допускается на основании заключения научно-исследовательской организации, специализирующейся в области материаловедения',
          'Применение таких материалов допускается при условии согласования возможности их применения с разработчиком проекта и (или) изготовителем, а в случае их отсутствия - на основании заключения научно-исследовательской организации, специализирующейся в области материаловедения',
        ],
        correctAnswers: [
          'Применение таких материалов допускается при условии согласования возможности их применения с разработчиком проекта и (или) изготовителем, а в случае их отсутствия - на основании заключения научно-исследовательской организации, специализирующейся в области материаловедения',
        ],
      },
      {
        code: '63697176',
        text:
          'Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?',
        answers: [
          'Технический руководитель организации',
          'Руководитель независимого аттестационного центра',
          'Руководитель организации',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: ['Руководитель независимого аттестационного центра'],
      },
      {
        code: '63697177',
        text:
          'Какие из перечисленных мероприятий, предусматриваемых для защиты обслуживающего персонала от соприкосновения с элементами электрокотла, находящимися под напряжением, указаны неверно?',
        answers: [
          'Все перечисленное указано верно',
          'Несгораемые перегородки (ограждения) - сетчатые с размером ячейки не более 25 x 25 мм',
          'Перегородки (ограждения) высотой не менее 1 м',
          'Сплошные перегородки с остекленными проемами',
        ],
        correctAnswers: ['Перегородки (ограждения) высотой не менее 1 м'],
      },
      {
        code: '63697178',
        text:
          'Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям ПТД',
          'Ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки',
          'Организовать проведение операционного контроля',
          'Все перечисленные функции',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63697179',
        text:
          'На какой срок должны составляться режимные карты по ведению водно-химического режима?',
        answers: ['На пять лет', 'На год', 'На три года', 'На два года'],
        correctAnswers: ['На три года'],
      },
      {
        code: '63697180',
        text:
          'В каком случае допускается обустройство площадки для установки котла ниже планировочной отметки территории, прилегающей к зданию, в котором установлены котлы?',
        answers: [
          'Не допускается',
          'В случае размещения в здании, в котором установлены котлы, узлов ввода и вывода теплотрасс',
          'В случае, обоснованном технологической необходимостью, по решению организации - разработчика проектной документации',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63697181',
        text:
          'На какие из приведенных ОПО не распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На ОПО, на которых используются трубопроводы пара и горячей воды с наружным диаметром менее 51 мм, у которых температура рабочей среды не превышает 450 °С при давлении рабочей среды более 8,0 МПа',
          'На ОПО, на которых используются трубопроводы пара и горячей воды с наружным диаметром менее 76 мм, у которых параметры рабочей среды не превышают температуру 450 °С и давление 8 МПа',
          'На ОПО, на которых используются трубопроводы пара и горячей воды с наружным диаметром менее 51 мм, у которых температура рабочей среды превышает 450 °С без ограничения давления рабочей среды',
          'На все приведенные ОПО действие ФНП не распространяется',
        ],
        correctAnswers: [
          'На все приведенные ОПО действие ФНП не распространяется',
        ],
      },
      {
        code: '63697182',
        text:
          'На какие котлы следует обращать особое внимание при составлении режимных карт по ВПУ и ВХР?',
        answers: [
          'На котлы, имевшие за период своей эксплуатации более 100 остановок',
          'На котлы, имевшие за период своей эксплуатации более 50 остановок',
          'На котлы, имевшие за период своей эксплуатации более 200 остановок',
          'На котлы, имевшие за период своей эксплуатации более 150 остановок',
        ],
        correctAnswers: [
          'На котлы, имевшие за период своей эксплуатации более 200 остановок',
        ],
      },
      {
        code: '63697183',
        text:
          'Кем устанавливаются величины допускаемых нагрузок на присоединенное к трубопроводу оборудование, согласно Нормам расчета на прочность трубопроводов тепловых сетей?',
        answers: [
          'Научно-исследовательскими организациями',
          'Заводами-изготовителями',
          'Эксплуатирующими организациями',
        ],
        correctAnswers: ['Заводами-изготовителями'],
      },
      {
        code: '63697184',
        text:
          'Какое количество инструкций с режимными картами по ведению водно-химического режима и по эксплуатации установок докотловой обработки воды должны иметь владельцы котлов в каждой котельной?',
        answers: [
          'Одну общую инструкцию',
          'Четыре отдельные инструкции',
          'Две отдельные инструкции',
          'Три отдельные инструкции',
          'Не регламентируется',
        ],
        correctAnswers: ['Две отдельные инструкции'],
      },
      {
        code: '63697185',
        text:
          'Каково минимальное значение высоты каналов и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в полупроходных каналах?',
        answers: [
          'Высота канала 1 метр, ширина прохода 0,4 метра',
          'Высота канала 1,5 метра, ширина прохода 0,6 метра',
          'Высота канала не нормируется, ширина прохода 0,6 метра',
          'Высота канала 1 метр, ширина прохода не нормируется',
        ],
        correctAnswers: ['Высота канала 1,5 метра, ширина прохода 0,6 метра'],
      },
      {
        code: '63697186',
        text:
          'В каком случае разрешается менять конструкцию и технологическую схему медицинской барокамеры при проведении ремонта?',
        answers: [
          'Не допускается ни в каком случае',
          'С разрешения специализированной организации, имеющей лицензию на техническое обслуживание данного вида медицинской техники',
          'С разрешения территориального органа Ростехнадзора',
          'С разрешения организации-изготовителя или проектной организации',
        ],
        correctAnswers: [
          'С разрешения организации-изготовителя или проектной организации',
        ],
      },
      {
        code: '63697187',
        text:
          'Какие из приведенных котлов не оборудуются установками для докотловой обработки воды?',
        answers: [
          'Паровые котлы с естественной циркуляцией и паропроизводительностью менее 0,7 т/час',
          'Прямоточные котлы паропроизводительностью менее 0,7 т/час',
          'Водогрейные котлы тепловой мощностью до 12 ГДж/час',
          'Все приведенные котлы не оборудуются установками докотловой обработки воды',
        ],
        correctAnswers: [
          'Паровые котлы с естественной циркуляцией и паропроизводительностью менее 0,7 т/час',
        ],
      },
      {
        code: '63697188',
        text:
          'Куда записывают причины внеочередного технического диагностирования медицинской барокамеры?',
        answers: [
          'В паспорт барокамеры',
          'В журнал регистрации сеансов',
          'В журнал технического обслуживания',
          'В журнал ремонта',
        ],
        correctAnswers: ['В паспорт барокамеры'],
      },
      {
        code: '63697189',
        text: 'Какое из приведенных требований к манометру указано неверно?',
        answers: [
          'Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы',
          'На шкале манометра должна быть нанесена красная черта, указывающая допустимое давление',
          'Взамен красной черты разрешается в качестве указателя значения максимально допустимого давления прикреплять к корпусу манометра пластину (скобу) из металла или иного материала достаточной прочности, окрашенную в красный цвет и плотно прилегающую к стеклу манометра',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы',
        ],
      },
      {
        code: '63697190',
        text:
          'Что необходимо предпринять, если при техническом освидетельствовании будет установлено, что трубопровод вследствие имеющихся дефектов или нарушений находится в состоянии, опасном для дальнейшей его эксплуатации?',
        answers: [
          'Работа такого трубопровода должна быть запрещена',
          'До устранения дефектов и нарушений перевести трубопровод в режим работы на пониженных параметрах (давление, температура)',
          'Перевести трубопровод в режим работы на пониженных параметрах (давление, температура) и провести экспертизу промышленной безопасности трубопровода',
        ],
        correctAnswers: ['Работа такого трубопровода должна быть запрещена'],
      },
      {
        code: '63697191',
        text:
          'Для каких котлов, работающих на твердом топливе, должна быть обеспечена механизированная подача топлива в котельную и топку котла?',
        answers: [
          'Для котлов паропроизводительностью до 1,5 т/ч',
          'Для котлов паропроизводительностью 2,5 т/ч и выше',
          'Для котлов паропроизводительностью от 1,5 т/ч до 3,5 т/ч',
          'Для любых котлов, работающих на твердом топливе',
        ],
        correctAnswers: ['Для котлов паропроизводительностью 2,5 т/ч и выше'],
      },
      {
        code: '63697192',
        text:
          'В каком случае допускается принимать расчетную температуру стенки необогреваемых деталей котлов и трубопроводов ниже 250 °C при выполнении расчета на прочность?',
        answers: [
          'По согласованию с Ростехнадзором',
          'По согласованию с проектной организацией',
          'По согласованию со специализированными научно-исследовательскими организациями',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'По согласованию со специализированными научно-исследовательскими организациями',
        ],
      },
      {
        code: '63697193',
        text:
          'По какому документу выполняются ремонт трубопроводов, арматуры и элементов дистанционного управления арматурой, установка и снятие заглушек, отделяющих ремонтируемый участок трубопровода?',
        answers: [
          'Все работы по наряду-допуску',
          'Ремонт - по приказу эксплуатирующей организации; установка и снятие заглушек - по наряду допуску',
          'Все работы выполняются по регламенту, разработанному и утвержденному эксплуатирующей организацией',
          'Ремонт - по наряду-допуску; установка и снятие заглушек - по распоряжению ответственного за исправное состояние и безопасную эксплуатацию трубопроводов',
        ],
        correctAnswers: ['Все работы по наряду-допуску'],
      },
      {
        code: '63697194',
        text:
          'Чем должно быть обеспечено соответствие выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования с применением сварки и термической обработки требованиям технологической документации?',
        answers: [
          'Декларированием специализированной организацией политики качества, обеспечивающей выполнение работ в соответствии с Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, и технологической документацией',
          'Системой подтверждения соответствия выполняемых работ требованиям технологической документации и Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, разработанной и утвержденной специализированной организацией',
          'Разработкой в специализированной организации в соответствии с международными стандартами ISO системой качества',
          'Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)',
        ],
        correctAnswers: [
          'Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)',
        ],
      },
      {
        code: '63697195',
        text:
          'Что должно учитываться в технологической документации в случае необходимости проведения термической обработки элементов оборудования при его монтаже, ремонте, реконструкции (модернизации)?',
        answers: [
          'Рекомендации организации-изготовителя, указанные в руководстве (инструкции) по эксплуатации',
          'Требования по режиму и технологии термической обработки элементов оборудования, указанные в Правилах промышленной безопасности при использовании оборудования, работающего под избыточным давлением.',
          'Рекомендации научно-исследовательской организации, указанные в документе по исследовательской аттестации технологии сварки',
          'Данные эксплуатационной документации на данное оборудование',
        ],
        correctAnswers: [
          'Рекомендации организации-изготовителя, указанные в руководстве (инструкции) по эксплуатации',
        ],
      },
      {
        code: '63697196',
        text:
          'В каком из приведенных случаев сосуд считается выдержавшим гидравлическое испытание?',
        answers: [
          'Если обнаружены лишь единичные трещины в сварном соединении, а на основном металле трещины не обнаружены',
          'Если обнаружены потения в развальцованных соединениях',
          'Если видимая остаточная деформация не превышает 10%',
          'Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание',
        ],
        correctAnswers: [
          'Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание',
        ],
      },
      {
        code: '63697197',
        text:
          'Что из перечисленного не является измеряемыми характеристиками несплошности при проведении ультразвукового контроля?',
        answers: [
          'Эквивалентная площадь',
          'Условное расстояние между несплошностями',
          'Условные размеры',
          'Координаты',
          'Все перечисленное является измеряемыми характеристиками несплошности при проведении УЗК',
        ],
        correctAnswers: [
          'Все перечисленное является измеряемыми характеристиками несплошности при проведении УЗК',
        ],
      },
      {
        code: '63697198',
        text:
          'Какой должна быть максимальная глубина выемки в месте отбора локальных проб для элементов с толщиной стенки до 30 мм при металлографическом исследовании?',
        answers: [
          'Не должна превышать 2,0 мм, но не должна быть более 25% от толщины стенки элемента на участке отбора',
          'Не должна превышать 1,5 мм, но не должна быть более 15% от толщины стенки элемента на участке отбора',
          'Не должна превышать 2,5 мм, но не должна быть более 20% от толщины стенки элемента на участке отбора',
          'Не должна превышать 3,0 мм, но не должна быть более 30% от толщины стенки элемента на участке отбора',
        ],
        correctAnswers: [
          'Не должна превышать 2,5 мм, но не должна быть более 20% от толщины стенки элемента на участке отбора',
        ],
      },
      {
        code: '63697199',
        text:
          'Какое из приведенных испытаний не является обязательным видом механических испытаний котлов (трубопроводов)?',
        answers: [
          'Испытание на ударный изгиб',
          'Испытание на статический изгиб',
          'Испытание на статическое растяжение',
        ],
        correctAnswers: ['Испытание на ударный изгиб'],
      },
      {
        code: '63697200',
        text: 'С какой целью проводится исследование микроструктуры металла?',
        answers: [
          'Для изучения формы и природы отдельных кристаллитов',
          'Для определения размеров зерна и рекристаллизации',
          'Для выявления неметаллических включений',
          'Для всего перечисленного',
        ],
        correctAnswers: ['Для всего перечисленного'],
      },
      {
        code: '63697201',
        text:
          'В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся ответственными лицами или комиссией с их участием?',
        answers: [
          'После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде',
          'При передаче сосуда для использования другой эксплуатирующей организации',
          'После монтажа сосуда, для которого ТР ТС 032/2013 не предусмотрена процедура подтверждения соответствия',
          'После капитального ремонта сосуда, связанного с заменой основных элементов',
        ],
        correctAnswers: [
          'После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде',
        ],
      },
      {
        code: '63697202',
        text:
          'Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?',
        answers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
          'Проверку знания теоретических основ сварки',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
        ],
        correctAnswers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
        ],
      },
      {
        code: '63697203',
        text:
          'Какое из перечисленных требований при магнитопорошковом контроле (дефектоскопии) указано неверно?',
        answers: [
          'Для контроля теплосилового оборудования ТЭС принят условный уровень чувствительности "Б" с предельными размерами выявляемых несплошностей: ширина составляет 10,0 мкм, минимальная протяженность - 0,5 мм',
          'Методом МПК (МПД) могут быть выявлены подповерхностные несплошности на глубине до 7 - 8 мм',
          'Магнитопорошковый контроль может применяться на различных элементах теплосилового оборудования ТЭС, изготовленных из ферромагнитных материалов с относительной магнитной проницаемостью не менее 40',
          'Определение размеров несплошности и оценка ее допустимости производятся по результатам визуально-измерительного контроля с применением оптических средств и измерительного инструмента',
        ],
        correctAnswers: [
          'Методом МПК (МПД) могут быть выявлены подповерхностные несплошности на глубине до 7 - 8 мм',
        ],
      },
      {
        code: '63697204',
        text:
          'Что из перечисленного должно контролироваться в процессе сварки, как минимум?',
        answers: [
          'Контроль параметров, предусмотренных в технологических (операционных) картах сварки',
          'Отсутствие видимых дефектов',
          'Соответствие параметров режима сварки и технологических приемов выполнения сварного соединения',
          'Очередность выполнения сварных швов и участков наплавки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697205',
        text:
          'Что допускается по результатам визуального контроля металла труб (в котле и на вырезке)?',
        answers: [
          'Равномерное увеличение наружного диаметра относительно номинального для труб из легированных марок сталей более чем на 2,5%',
          'Равномерное увеличение наружного диаметра относительно номинального для труб из углеродистых сталей более 3,5%',
          'Выход труб из ранжира на величину диаметра трубы и более',
          'Отдулины и макротрещины',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63697206',
        text:
          'На какие из приведенных ОПО распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На ОПО, на которых используются водотрубные котлы с естественной циркуляцией (котлы-бойлеры) и рабочим давлением пара до 4 МПа',
          'На ОПО, на которых используются электрокотлы вместимостью не более 0,025 м3',
          'На ОПО, на которых используется оборудование под давлением, входящее в состав вооружения и военной техники',
          'На ОПО атомных электростанций, на которых используются сосуды, работающие с радиоактивной средой',
        ],
        correctAnswers: [
          'На ОПО, на которых используются водотрубные котлы с естественной циркуляцией (котлы-бойлеры) и рабочим давлением пара до 4 МПа',
        ],
      },
      {
        code: '63697207',
        text:
          'В каких целях проводится контроль сварных швов стилоскопированием?',
        answers: [
          'Для подтверждения соответствия металла сварных швов и деталей, элементов оборудования под давлением из легированной стали требованиям проектной (конструкторской) и технологической документации',
          'Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях',
          'Для проверки качества выполнения термической обработки сварных соединений',
        ],
        correctAnswers: [
          'Для подтверждения соответствия металла сварных швов и деталей, элементов оборудования под давлением из легированной стали требованиям проектной (конструкторской) и технологической документации',
        ],
      },
      {
        code: '63697208',
        text:
          'Главные парозапорные органы каких котлов должны быть оборудованы дистанционным управлением с рабочего места обслуживающего котел персонала?',
        answers: [
          'Если условный проход такой арматуры более 320 мм',
          'Котлов с рабочим давлением более 0,8 МПа',
          'Котлов с паропроизводительностью более 4 тонн в час',
          'Всех барабанных паровых котлов',
        ],
        correctAnswers: ['Котлов с паропроизводительностью более 4 тонн в час'],
      },
      {
        code: '63697209',
        text:
          'Какое следует принимать расчетное давление, согласно Нормам расчета на прочность трубопроводов тепловых сетей?',
        answers: [
          'Ниже максимального рабочего давления',
          'Равным максимальному рабочему давлению',
          'Выше максимального рабочего давления',
          'Не регламентируется',
        ],
        correctAnswers: ['Равным максимальному рабочему давлению'],
      },
      {
        code: '63697210',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) литых и кованых металлических сосудов? Где в формулах: Рраб - рабочее давление сосуда, Ррасч - расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,5 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,25 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,25 Ррасч ([σ]20 / [σ]t)',
          'Рпр = 1,25 Рраб',
        ],
        correctAnswers: ['Рпр = 1,5 Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63697211',
        text:
          'Каково минимальное значение температуры воды, используемой для гидравлического испытания трубопровода (если конкретное значение не указано в технической документации организации-изготовителя)?',
        answers: ['5 °C', '20 °C', '10 °C', '80 °C'],
        correctAnswers: ['5 °C'],
      },
      {
        code: '63697212',
        text:
          'Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки, не превышающую 50 мм (если отсутствуют другие указания в руководстве по эксплуатации)?',
        answers: ['10 минут', '5 минут', '20 минут', '30 минут'],
        correctAnswers: ['10 минут'],
      },
      {
        code: '63697213',
        text:
          'Чему равна минимальная величина пробного давления при гидравлическом испытании трубопроводов пара и горячей воды?',
        answers: [
          '1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа',
          '1,25 расчетного давления',
          '1,5 рабочего давления',
          '0,5 МПа',
        ],
        correctAnswers: [
          '1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа',
        ],
      },
      {
        code: '63697214',
        text:
          'Кем должна быть подтверждена прочность деталей, методы расчета которых не приводятся в Нормах расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Ростехнадзором',
          'Специализированной научно-исследовательской организацией',
          'Изготовителем',
          'Технической комиссией эксплуатирующей организации',
        ],
        correctAnswers: ['Изготовителем'],
      },
      {
        code: '63697215',
        text: 'В каких местах допускается установка запорной арматуры?',
        answers: [
          'На дренаже отводящего трубопровода предохранительного клапана',
          'На прямом участке паропровода',
          'На отводящем трубопроводе предохранительного клапана',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['На прямом участке паропровода'],
      },
      {
        code: '63697216',
        text:
          'При каком достижении концентрации кислорода должен срабатывать звуковой сигнал газоанализатора барокамер?',
        answers: ['Более 20%', 'Более 23%', 'Более 15%', 'Более 10%'],
        correctAnswers: ['Более 23%'],
      },
      {
        code: '63697217',
        text:
          'Каков минимальный срок проведения пусконаладочных работ на оборудовании, работающем под давлением?',
        answers: [
          'Срок проведения пусконаладочных работ определяется эксплуатирующей организацией исходя из сроков ввода оборудования в эксплуатацию',
          'Не менее 30 дней',
          'Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования',
        ],
        correctAnswers: [
          'Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования',
        ],
      },
      {
        code: '63697218',
        text:
          'Каково минимально допустимое значение расстояния от фронта котла паропроизводительностью 10 тонн в час до противоположной стены котельного помещения?',
        answers: [
          'Устанавливается проектом',
          '1,5 м',
          '3 м',
          '2 м - при условии отсутствия необходимости обслуживания топки с фронта',
        ],
        correctAnswers: ['3 м'],
      },
      {
        code: '63697219',
        text:
          'Чему равно минимальное время выдержки трубопроводов пара и горячей воды под пробным давлением?',
        answers: [
          '10 минут',
          '5 минут',
          '30 минут',
          'Соответствует времени снижения давления в испытуемом трубопроводе (по манометру) на 0,1 МПа',
        ],
        correctAnswers: ['10 минут'],
      },
      {
        code: '63697220',
        text: 'Что должно быть указано в технологических картах сварки?',
        answers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
          'Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, технические приемы контроля качества сварных соединений',
          'Требования к применяемым сварочным технологиям, последовательность операций, технические приемы, особенности процесса сварки, обеспечивающие качество сварных соединений',
        ],
        correctAnswers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
        ],
      },
      {
        code: '63697221',
        text:
          'Что из приведенного не указывается на табличке или не наносится на котле перед пуском его в работу?',
        answers: [
          'Регистрационный номер и даты проведенных осмотров и гидравлических испытаний',
          'Даты следующего осмотра и гидравлического испытания',
          'Номер котла по системе, принятой в эксплуатирующей организации',
          'Разрешенное давление',
        ],
        correctAnswers: [
          'Регистрационный номер и даты проведенных осмотров и гидравлических испытаний',
        ],
      },
      {
        code: '63697222',
        text:
          'Какое из приведенных требований должно выполняться при оснащении проходных каналов для трубопроводов пара и горячей воды входными люками?',
        answers: [
          'Расстояние между люками должно быть не более 100 метров; для всех трубопроводов люки предусматриваются в узлах установки арматуры; для паропроводов люки предусматриваются в конечных точках тупиковых участков, а для трубопроводов горячей воды - на поворотах трассы',
          'Расстояние между люками должно быть не более 50 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры',
          'Расстояние между люками должно быть не более 300 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры',
        ],
        correctAnswers: [
          'Расстояние между люками должно быть не более 300 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры',
        ],
      },
      {
        code: '63697223',
        text:
          'В каком из приведенных случаев при проведении гидравлического испытания при эксплуатации сосудов допускается использовать не воду, а другую жидкость?',
        answers: [
          'В технически обоснованных случаях, предусмотренных организацией-изготовителем',
          'Не допускается, при гидравлическом испытании сосудов используется только вода',
          'В технически обоснованных случаях, предусмотренных организацией-изготовителем, проводящей гидравлическое испытание',
          'Если другая жидкость не загрязняет сосуд и не вызывает интенсивную коррозию',
        ],
        correctAnswers: [
          'В технически обоснованных случаях, предусмотренных организацией-изготовителем',
        ],
      },
      {
        code: '63697224',
        text:
          'Что из перечисленного допускается для обеспечения сопряжения поперечных стыков труб?',
        answers: [
          'Расточка',
          'Раздача',
          'Обжатие концов труб',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697225',
        text:
          'Каково минимально допустимое значение расстояния от фронта котла, работающего на газообразном топливе, до противоположной стены котельного помещения?',
        answers: ['3 м', '2 м', '1 м', 'Не регламентируется'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63697226',
        text:
          'Какой документ определяет порядок проведения производственной аттестации технологии сварки?',
        answers: [
          'Технологическая документация, разработанная научно-исследовательской организацией',
          'Технологическая документация, разработанная специализированной организацией',
          'ФНП ОРПД',
          'Руководство (инструкция) по эксплуатации оборудования, разработанная изготовителем оборудования',
        ],
        correctAnswers: [
          'Технологическая документация, разработанная специализированной организацией',
        ],
      },
      {
        code: '63697227',
        text:
          'Какой должна приниматься величина расчетного давления во всех случаях, согласно Нормам расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Не менее 0,7 МПа',
          'Не менее 0,5 МПа',
          'Не менее 0,2 МПа',
          'Не менее 0,3 МПа',
        ],
        correctAnswers: ['Не менее 0,2 МПа'],
      },
      {
        code: '63697228',
        text:
          'Какой должна быть шероховатость поверхности при капиллярном контроле?',
        answers: [
          'Не хуже Rz 40 мкм',
          'Не хуже Rz 20 мкм',
          'Не хуже Rz 160 мкм',
          'Не хуже Rz 80 мкм',
        ],
        correctAnswers: ['Не хуже Rz 20 мкм'],
      },
      {
        code: '63697229',
        text:
          'Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?',
        answers: [
          'Используемые при сборке приспособления и оборудование',
          'Способы крепления деталей',
          'Размеры, количество и расположение прихваток',
          'Порядок и последовательность сборки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697230',
        text:
          'Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?',
        answers: [
          'Только входной контроль',
          'Только операционный контроль',
          'Только приемочный контроль',
          'Все перечисленные виды контроля',
        ],
        correctAnswers: ['Все перечисленные виды контроля'],
      },
      {
        code: '63697231',
        text:
          'Кто привлекается для контроля металла с целью продления ресурса (срока службы) при достижении оборудованием или группой элементов оборудования назначенного ресурса (срока службы)?',
        answers: [
          'Проектная организация',
          'Специализированная экспертная организация, имеющая лицензию на право экспертизы промышленной безопасности оборудования',
          'Инспектор Росаккредитации',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Специализированная экспертная организация, имеющая лицензию на право экспертизы промышленной безопасности оборудования',
        ],
      },
      {
        code: '63697232',
        text:
          'Каким документом определяется ответственность за безопасность обслуживания оборудования под давлением в период проведения наладочных работ?',
        answers: [
          'Программой наладочных работ',
          'Договором на оказание услуг по проведению наладочных работ, заключаемым эксплуатирующей и наладочной организациями',
          'Совместным приказом руководителей эксплуатирующей и специализированной пусконаладочной организаций',
        ],
        correctAnswers: ['Программой наладочных работ'],
      },
      {
        code: '63697233',
        text:
          'При каких увеличениях проводят исследование макроструктуры металла и сварных соединений?',
        answers: [
          'Не более чем в 40 раз',
          'Не более чем в 60 раз',
          'Не более чем в 30 раз',
          'Не более чем в 50 раз',
        ],
        correctAnswers: ['Не более чем в 30 раз'],
      },
      {
        code: '63697234',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'При техническом перевооружении ОПО, на котором используются сосуды, работающие под давлением',
          'При техническом освидетельствовании сосудов, работающих под давлением',
          'При техническом диагностировании и освидетельствовании сосудов, работающих под давлением',
          'При проектировании и конструировании сосудов, работающих под давлением',
        ],
        correctAnswers: [
          'При проектировании и конструировании сосудов, работающих под давлением',
        ],
      },
      {
        code: '63697235',
        text:
          'Каким должно приниматься расчетное давление в трубопроводах воды после насосов, согласно Нормам расчета на прочность стационарных котлов и трубопроводов пара и горячей воды?',
        answers: [
          'Равным 80% максимального давления, создаваемого насосами при закрытых задвижках',
          'Равным 90% максимального давления, создаваемого насосами при закрытых задвижках',
          'Равным 85% максимального давления, создаваемого насосами при закрытых задвижках',
          'Равным 95% максимального давления, создаваемого насосами при закрытых задвижках',
        ],
        correctAnswers: [
          'Равным 90% максимального давления, создаваемого насосами при закрытых задвижках',
        ],
      },
      {
        code: '63697236',
        text: 'Какие насосы могут применяться для питания котлов водой?',
        answers: [
          'Центробежные, поршневые и плунжерные насосы с электрическим приводом',
          'Паровые инжекторы (пароструйный инжектор относится к насосу с паровым приводом)',
          'Центробежные, поршневые и плунжерные насосы с паровым приводом',
          'Насосы с ручным приводом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63697237',
        text:
          'Какую минимальную высоту и ширину должны иметь дверные проемы многоместных медицинских барокамер, выполненные в виде плоских дверей?',
        answers: [
          'Не менее 2,0 м и ширину не менее 1,0 м',
          'Не менее 1,75 м и ширину не менее 0,9 м',
          'Не менее 1,55 м и ширину не менее 0,7 м',
          'Не менее 1,35 м и ширину не менее 0,5 м',
        ],
        correctAnswers: ['Не менее 1,55 м и ширину не менее 0,7 м'],
      },
      {
        code: '63697238',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см²? Где в формулах: Рраб - рабочее давление сосуда, Ррасч - расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,3 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,6 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,6 Рраб',
          'Рпр = 1,3 Ррасч ([σ]20 / [σ]t)',
        ],
        correctAnswers: ['Рпр = 1,3 Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63697239',
        text:
          'Какая организация разрабатывает исполнительную схему (чертеж) трубопровода?',
        answers: [
          'Организация, производившая монтаж трубопровода',
          'Для трубопроводов, подлежащих учету в территориальном органе Ростехнадзора, исполнительная схема разрабатывается экспертной организацией, а в остальных случаях – эксплуатирующей организацией',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Организация, производившая монтаж трубопровода'],
      },
      {
        code: '63697240',
        text:
          'Кем осуществляется проверка готовности к применению аттестованных технологий сварки с целью определения наличия у организации или индивидуального предпринимателя технических, организационных и квалификационных возможностей для выполнения сварочных (наплавочных) работ по применяемым им аттестованным технологиям, а также соответствия качества выполненных при аттестации контрольных сварных соединений (наплавок) требованиям НД и (или) проектной (конструкторской) документации на сварные конструкции?',
        answers: [
          'Научно-исследовательской организацией',
          'Независимыми аттестационными центрами',
          'Ростехнадзором',
        ],
        correctAnswers: ['Независимыми аттестационными центрами'],
      },
      {
        code: '63697241',
        text:
          'Какое из приведенных требований к программе проведения наладочных работ на оборудовании под давлением указано неверно?',
        answers: [
          'Программу разрабатывает организация, выполняющая соответствующие работы до начала их производства',
          'В программе должны быть отражены содержание и порядок выполнения всех технологических и контрольных операций с обеспечением наладки на всех режимах работы, установленных проектом',
          'Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013',
        ],
        correctAnswers: [
          'Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013',
        ],
      },
      {
        code: '63697242',
        text:
          'Какие трещины (несплошности) позволяет выявлять вихретоковый контроль?',
        answers: [
          'Глубиной от 0,2 мм и длиной от 3,0 мм при раскрытии более 1,0 мкм',
          'Глубиной от 0,1 мм и длиной от 1,0 мм при раскрытии более 2,0 мкм',
          'Глубиной от 0,4 мм и длиной от 2,0 мм при раскрытии более 2,0 мкм',
          'Глубиной от 0,3 мм и длиной от 1,0 мм при раскрытии более 1,0 мкм',
        ],
        correctAnswers: [
          'Глубиной от 0,2 мм и длиной от 3,0 мм при раскрытии более 1,0 мкм',
        ],
      },
      {
        code: '63697243',
        text:
          'Для каких из перечисленных элементов котла устанавливается назначенный ресурс?',
        answers: [
          'Для барабанов',
          'Для коллекторов пароперегревателей с температурой эксплуатации 450°C и выше',
          'Для крепежных деталей М42 и более с температурой эксплуатации 450°C и выше',
          'Для всех перечисленных',
        ],
        correctAnswers: ['Для всех перечисленных'],
      },
      {
        code: '63697244',
        text:
          'Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?',
        answers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
          'Ответственный за исправное состояние и безопасную эксплуатацию сосуда',
          'Уполномоченный представитель Ростехнадзора',
          'Уполномоченный представитель Ростехнадзора принимает решение о вводе в эксплуатацию сосудов, подлежащих учету в органах Ростехнадзора, в остальных случаях решение принимает ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
        ],
        correctAnswers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
        ],
      },
      {
        code: '63697245',
        text:
          'Каково минимально допустимое значение расстояния от выступающих частей горелочных устройств котла, работающего на жидком топливе, до стены котельного помещения?',
        answers: ['3 м', '2 м', '1 м', 'Не регламентируется'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63697246',
        text:
          'Что включает в себя программа экспериментальных испытаний на прочность оборудования?',
        answers: [
          'Испытания на ползучесть и усталость материалов, которые проводятся с учетом процессов эксплуатации оборудования',
          'Дополнительные испытания, которые учитывают действия других факторов и проводятся при необходимости',
          'Испытания давлением на герметичность и прочность для подтверждения отсутствия утечки рабочей среды или остаточных деформаций, превышающих допустимые значения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63697247',
        text:
          'Каким способом может производиться резка листов, труб и других полуфабрикатов, а также вырезка отверстий при монтаже, ремонте, реконструкции (модернизации) оборудования под давлением?',
        answers: [
          'Вырезка отверстий производится любым способом (механическим, газопламенным, электродуговым, плазменным). Способ резки устанавливается технологической документацией в зависимости от классов сталей',
          'Резка листов, труб и других полуфабрикатов, а также вырезка отверстий могут быть произведены любым способом (механическим, газопламенным, электродуговым, плазменным), применяемым специализированной организацией',
          'Резка листов, труб и других полуфабрикатов, а также вырезка отверстий при монтаже, ремонте, реконструкции (модернизации) котлов могут осуществляться только электродуговым и газопламенным способами',
        ],
        correctAnswers: [
          'Вырезка отверстий производится любым способом (механическим, газопламенным, электродуговым, плазменным). Способ резки устанавливается технологической документацией в зависимости от классов сталей',
        ],
      },
      {
        code: '63697248',
        text:
          'С какой периодичностью должна проводиться поверка манометров медицинских барокамер с их опломбированием или клеймением?',
        answers: [
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 18 месяца',
          'Не реже одного раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63697249',
        text:
          'Каково минимальное значение уклона горизонтальных участков труб тепловых сетей?',
        answers: [
          '0,002',
          '0,004',
          'Для участков тепловых сетей длиной до 300 метров - 0,002, а для остальных участков - 0,004',
          'Уклон горизонтальных участков трубопроводов нормируется только для паропроводов, он должен быть не менее 0,004',
        ],
        correctAnswers: ['0,002'],
      },
      {
        code: '63697250',
        text:
          'Требованиям какой документации должен соответствовать объем и порядок проведения технического диагностирования барокамеры?',
        answers: [
          'Руководства по эксплуатации',
          'Технической документации на барокамеру конкретного типа',
          'Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Всей перечисленной',
        ],
        correctAnswers: ['Всей перечисленной'],
      },
      {
        code: '63697251',
        text:
          'Какая организация может осуществлять монтаж, наладку, техническое обслуживание и ремонт медицинских барокамер?',
        answers: [
          'Специализированная организация, имеющая лицензию на техническое обслуживание медицинской техники',
          'Специализированная организация, соответствующая требованиям раздела IV ФНП ОРПД',
          'Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники',
          'Специализированная организация, соответствующая требованиям раздела IV ФНП ОРПД и имеющая лицензию на право проведения экспертизы промышленной безопасности барокамер',
          'Специализированная организация, имеющая лицензию на право проведения экспертизы промышленной безопасности барокамер',
        ],
        correctAnswers: [
          'Специализированная организация, соответствующая требованиям главы III ФНП ОРПД и имеющая лицензию на техническое обслуживание данного вида медицинской техники',
        ],
      },
      {
        code: '63697252',
        text:
          'Какие из перечисленных методов относятся к неразрушающему контролю металла и сварных соединений?',
        answers: [
          'Визуально-измерительный, ультразвуковой, радиографический',
          'Магнитопорошковый, капиллярный или как разновидность капиллярного - цветной',
          'Вихретоковый, измерение твердости, стилоскопирование',
          'Магнитный контроль тепловой неравномерности и магнитная ферритометрия, металлографический контроль',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63697253',
        text:
          'Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосудов?',
        answers: [
          'Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания',
          'В комбинированных сосудах с двумя и более рабочими полостями, рассчитанными на разные давления, гидравлическому испытанию должна быть подвергнута каждая полость пробным давлением, равным максимальному пробному давлению из определяемых для каждой полости',
          'В комбинированных сосудах с четным количеством рабочих полостей, рассчитанных на разные давления, гидравлическому испытанию должна быть подвергнута каждая пара полостей пробным давлением, определяемым в зависимости от расчетного давления полостей',
          'Гидравлическое испытание сосудов, устанавливаемых вертикально, проводить в горизонтальном положении не допускается',
        ],
        correctAnswers: [
          'Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания',
        ],
      },
      {
        code: '63697254',
        text:
          'Каким должно быть утонение стенки прямых труб и гибов питательных трубопроводов в нейтральных зонах?',
        answers: [
          'Не должно превышать 5% от номинальной толщины стенки элемента',
          'Не должно превышать 15% от номинальной толщины стенки элемента',
          'Не должно превышать 10% от номинальной толщины стенки элемента',
          'Не должно превышать 20% от номинальной толщины стенки элемента',
        ],
        correctAnswers: [
          'Не должно превышать 10% от номинальной толщины стенки элемента',
        ],
      },
      {
        code: '63697255',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
          'Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума',
          'Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63697256',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
          'Допускается применение клейма сварщика, выполнившего наибольший объем работ',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63697257',
        text:
          'Кем должно проводиться техническое обслуживание медицинской барокамеры?',
        answers: [
          'Техническим специалистом подразделения ГБО',
          'Любым перечисленным лицом',
          'Техническим специалистом специализированной организации, имеющей лицензию на техническое обслуживание данного вида медицинской техники',
          'Техническим специалистом специализированного подразделения МО',
        ],
        correctAnswers: ['Любым перечисленным лицом'],
      },
    ],
    63692: [
      {
        code: '63692000',
        text:
          'Какое требование к проверке исправности манометра указано неверно?',
        answers: [
          'Проверку исправности манометра производят с помощью трехходового крана или заменяющих его запорных вентилей путем установки стрелки манометра на нуль',
          'Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности',
          'Не реже одного раза в 12 месяцев (если иные сроки не установлены документацией на конкретный тип манометра) манометры должны быть поверены в установленном порядке',
        ],
        correctAnswers: [
          'Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности',
        ],
      },
      {
        code: '63692001',
        text:
          'В каком случае в здания и помещения, в которых эксплуатируются котлы, могут быть допущены посторонние лица, не имеющие отношения к эксплуатации котлов и иного взаимосвязанного с ними основного и вспомогательного оборудования?',
        answers: [
          'При наличии производственной необходимости допуск таких лиц осуществляется только с разрешения эксплуатирующей организации и в сопровождении ее представителя',
          'Допуск таких лиц осуществляется только после инструктажа по безопасности и в сопровождении представителя эксплуатирующей организации',
          'Допуск таких лиц в здания и помещения, в которых эксплуатируются котлы, не допускается',
        ],
        correctAnswers: [
          'При наличии производственной необходимости допуск таких лиц осуществляется только с разрешения эксплуатирующей организации и в сопровождении ее представителя',
        ],
      },
      {
        code: '63692002',
        text:
          'Какие условия должна обеспечивать тепловая изоляция участков элементов котлов и трубопроводов с повышенной температурой поверхности?',
        answers: [
          'Максимальное снижение потерь тепла от поверхностей с повышенной температурой в окружающую среду',
          'Температуру наружной поверхности изоляции не превышающую 45 °С',
          'Температуру наружной поверхности изоляции не более 55 °С при температуре окружающей среды не более 25 °С',
          'Максимальную компенсацию тепловых потерь здания (помещения), в котором эксплуатируются котлы',
        ],
        correctAnswers: [
          'Температуру наружной поверхности изоляции не более 55 °С при температуре окружающей среды не более 25 °С',
        ],
      },
      {
        code: '63692003',
        text:
          'Какие виды топлива не должны применяться в качестве растопочного для пылеугольных горелок?',
        answers: [
          'Природный газ',
          'Топочный мазут',
          'Жидкое топливо с температурой вспышки 85 °﻿С',
          'Легковоспламеняющиеся виды жидкого топлива с температурой вспышки выше 61 °﻿С',
        ],
        correctAnswers: [
          'Легковоспламеняющиеся виды жидкого топлива с температурой вспышки выше 61 °﻿С',
        ],
      },
      {
        code: '63692004',
        text:
          'Когда должно проводиться измерение сопротивления изоляции котла без воды в соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов?',
        answers: [
          'После монтажа, профилактического испытания, текущего ремонта, капитального ремонта',
          'Только после профилактического испытания и капитального ремонта',
          'Только после монтажа, профилактического испытания',
          'Только после монтажа',
        ],
        correctAnswers: [
          'Только после профилактического испытания и капитального ремонта',
        ],
      },
      {
        code: '63692005',
        text:
          'Каким образом должны оформляться результаты проверок готовности котла к пуску в работу и организации надзора за его эксплуатацией?',
        answers: [
          'Приказом (распорядительным документом) эксплуатирующей организации',
          'Актом готовности котла к вводу в эксплуатацию',
          'Записью в паспорт котла',
          'Протоколом, который является основанием для ввода котла в эксплуатацию. Протокол прилагается к паспорту котла',
        ],
        correctAnswers: ['Актом готовности котла к вводу в эксплуатацию'],
      },
      {
        code: '63692006',
        text:
          'В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?',
        answers: [
          'В случае применения таких труб только при монтаже, ремонте, реконструкции (модернизации) водогрейных котлов и трубопроводов горячей воды',
          'Если на стальные трубы оформлены документы, подтверждающие их соответствие и качество (сертификаты, декларации соответствия)',
          'Если срок эксплуатации стальных труб не превышает половины расчетного срока службы технического устройства, на котором эти трубы употреблялись',
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов.',
        ],
        correctAnswers: [
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов.',
        ],
      },
      {
        code: '63692007',
        text:
          'Каким образом оформляются результаты проводимого контроля качества сварных соединений?',
        answers: [
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется. На усмотрение организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования',
          'Общие результаты по контролю качества сварных соединений оформляются отчетом, прилагаемым к паспорту монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
        ],
        correctAnswers: [
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
        ],
      },
      {
        code: '63692008',
        text:
          'Какое из требований к оснащению предохранительными клапанами котла, работающего с высокотемпературными органическими и неорганическими теплоносителями, указано неверно?',
        answers: [
          'Суммарная пропускная способность предохранительных клапанов, устанавливаемых на жидкостном котле, должна быть достаточной для отвода прироста объема расширившегося теплоносителя при номинальной теплопроизводительности котла',
          'На каждом котле должно быть установлено не менее двух предохранительных клапанов',
          'Условный проход предохранительного клапана должен быть не менее 25 мм и не более 150 мм',
          'На жидкостных котлах допускается установка рычажно-грузовых предохранительных клапанов',
        ],
        correctAnswers: [
          'На жидкостных котлах допускается установка рычажно-грузовых предохранительных клапанов',
        ],
      },
      {
        code: '63692009',
        text:
          'Каким путем проводится оценка риска для идентифицированных видов опасности при проектировании оборудования согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Расчетным',
          'Экспериментальным',
          'Экспертным',
          'По данным эксплуатации аналогичного оборудования',
          'Всеми перечисленными путями',
        ],
        correctAnswers: ['Всеми перечисленными путями'],
      },
      {
        code: '63692010',
        text:
          'Какие из приведенных котлов подлежат учету в органах Ростехнадзора?',
        answers: [
          'Водогрейный котел с температурой нагрева воды не более 150 °C',
          'Котлы, эксплуатируемые на ОПО IV класса опасности',
          'Котлы, у которых произведения вместимости (м³) на давление (МПа) не превышает 1,0',
          'Учету в органах Ростехнадзора подлежат все котлы, на которые распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: [
          'Учету в органах Ростехнадзора подлежат все котлы, на которые распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
      },
      {
        code: '63692011',
        text:
          'На каком из перечисленных устройств не требуется установка не менее 2 указателей уровня жидкости прямого действия?',
        answers: [
          'На прямоточном паровом котле',
          'На цилиндрическом паровом котле',
          'На жаротрубном паровом котле',
          'На всех перечисленных',
        ],
        correctAnswers: ['На прямоточном паровом котле'],
      },
      {
        code: '63692012',
        text:
          'Какое из приведенных требований по установке запорных органов на питательном тракте котла указано неверно?',
        answers: [
          'На питательном трубопроводе котла должны быть установлены обратный клапан и запорный орган',
          'После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган',
          'У экономайзера, отключаемого по воде, обратный клапан и запорный орган устанавливаются как до, так и после экономайзера',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'После неотключаемого по воде экономайзера должны быть установлены обратный клапан и запорный орган',
        ],
      },
      {
        code: '63692013',
        text:
          'В каком из приведенных случаев до проверки знаний рабочий, обслуживающий котлы, должен пройти стажировку?',
        answers: [
          'Перед первичным допуском к самостоятельной работе после профессионального обучения',
          'Перед допуском к самостоятельной работе после внеочередной проверки знаний',
          'При перерыве в работе по специальности более 12 месяцев',
          'Во всех приведенных случаях проводится стажировка',
        ],
        correctAnswers: ['Во всех приведенных случаях проводится стажировка'],
      },
      {
        code: '63692014',
        text:
          'Кто и на основании чего принимает решение о вводе в эксплуатацию котла?',
        answers: [
          'Уполномоченный представитель Ростехнадзора на основании проверки готовности котла к пуску в работу и проверки организации надзора за эксплуатацией котла',
          'Уполномоченный представитель Ростехнадзора после проведения пусконаладочных работ на основании результатов первичного освидетельствования котла и осмотра котла во время парового опробования',
          'Ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением на основании проверки организации надзора за эксплуатацией котла',
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности котла к пуску в работу и проверки организации надзора за эксплуатацией котла',
        ],
        correctAnswers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности котла к пуску в работу и проверки организации надзора за эксплуатацией котла',
        ],
      },
      {
        code: '63692015',
        text:
          'В каком из приведенных случаев содорегенерационный котел должен быть немедленно остановлен и отключен действиями защит или персоналом?',
        answers: [
          'При возникновения опасности поступления воды или разбавленного щелока в топку',
          'При прекращении подачи воды на охлаждение леток',
          'При выходе из строя всех перекачивающих насосов зеленого щелока',
          'При течи плава помимо леток или через неплотности топки и невозможности ее устранения',
        ],
        correctAnswers: [
          'При течи плава помимо леток или через неплотности топки и невозможности ее устранения',
        ],
      },
      {
        code: '63692016',
        text:
          'Какое требование к специалистам и рабочим, обслуживающим котлы, указано неверно?',
        answers: [
          'Рабочие должны быть не моложе 18 летнего возраста и не иметь медицинских противопоказаний для выполнения работ по обслуживанию котлов',
          'Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации',
          'Рабочие должны быть допущены в установленном порядке к самостоятельной работе',
          'Рабочие должны соответствовать квалификационным требованиям',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации',
        ],
      },
      {
        code: '63692017',
        text:
          'Исходя из какого условия выбирается количество питательных насосов при групповой схеме питания паровых котлов, работающих с высокотемпературными органическими и неорганическими теплоносителями?',
        answers: [
          'Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110 % номинальной паропроизводительности всех рабочих котлов',
          'Чтобы суммарная подача питательных насосов обеспечивалась не менее чем тремя питательными насосами, один из которых должен быть резервным',
          'Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была равна 100 % номинальной паропроизводительности всех рабочих котлов',
          'Должно быть установлено не менее двух питательных насосов с электрическим приводом, из которых один должен быть резервным',
        ],
        correctAnswers: [
          'Чтобы в случае остановки самого мощного насоса суммарная подача оставшихся насосов была не менее 110 % номинальной паропроизводительности всех рабочих котлов',
        ],
      },
      {
        code: '63692018',
        text:
          'В каком из приведенных случаев допускается одному специалисту совмещать ответственность за осуществление производственного контроля за безопасной эксплуатацией котлов и ответственность за их исправное состояние и безопасную эксплуатацию?',
        answers: [
          'Случаи совмещения обязанностей определяются самостоятельно эксплуатирующей организацией в соответствии с ее распорядительными документами',
          'Если котлы эксплуатируются не более чем на двух производственных площадках',
          'Если это совмещение согласовано с территориальным органом Ростехнадзора',
          'Совмещение не допускается',
        ],
        correctAnswers: ['Совмещение не допускается'],
      },
      {
        code: '63692019',
        text:
          'На основании чего осуществляется пуск (включение) в работу и штатная остановка котла?',
        answers: [
          'На основании приказа руководителя эксплуатирующей организации',
          'На основании письменного распоряжения ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию котла',
        ],
        correctAnswers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию котла',
        ],
      },
      {
        code: '63692020',
        text:
          'В какие сроки проводится проверка исправности действия манометров, предохранительных клапанов, указателей уровня воды и питательных насосов при эксплуатации котлов с рабочим давлением более 1,4 до 4,0 МПа (кроме котлов, установленных на тепловых электростанциях)?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в сутки',
          'Сроки устанавливаются эксплуатирующей организацией и указываются в графике проверки, который утверждается техническим руководителем эксплуатирующей организации',
          'Проверка манометров и предохранительных клапанов проводится не реже одного раза в смену; проверка указателей уровня воды – не реже одного раза в сутки; проверка резервных питательных насосов – перед их пуском',
        ],
        correctAnswers: ['Не реже одного раза в сутки'],
      },
      {
        code: '63692021',
        text:
          'В какие сроки должны проводиться технический осмотр и очистка поверхностей нагрева в котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже чем через 8000 часов работы котла',
          'Не реже одного раза в 8 лет',
          'Не реже чем через 20000 часов работы котла',
        ],
        correctAnswers: ['Не реже чем через 8000 часов работы котла'],
      },
      {
        code: '63692022',
        text:
          'Какое требование к эксплуатации манометров на котлах указано неверно?',
        answers: [
          'Шкалу манометра выбирают исходя из условия, что при рабочем давлении стрелка манометра должна находиться во второй трети шкалы',
          'На шкале манометра должна быть нанесена красная черта на уровне деления, соответствующего рабочему давлению для данного элемента с учетом добавочного давления от веса столба жидкости',
          'Взамен красной черты разрешается в качестве указателя значения максимально допустимого давления прикреплять к корпусу манометра пластину (скобу) из металла или иного материала достаточной прочности, окрашенную в красный цвет и плотно прилегающую к стеклу манометра',
          'Манометр должен быть установлен так, чтобы его показания были видны обслуживающему персоналу, при этом шкала его должна быть расположена только вертикально',
        ],
        correctAnswers: [
          'Манометр должен быть установлен так, чтобы его показания были видны обслуживающему персоналу, при этом шкала его должна быть расположена только вертикально',
        ],
      },
      {
        code: '63692023',
        text:
          'При установке средства измерения давления на какой высоте должно предусматриваться дублирующее средство измерения давления?',
        answers: ['Более 5 м', 'Более 3 м', 'Более 2 м', 'Более 2,5 м'],
        correctAnswers: ['Более 5 м'],
      },
      {
        code: '63692024',
        text:
          'Каким образом должна осуществляться вентиляция топки и газоходов перед растопкой котла из неостывшего состояния при сохранившемся избыточном давлении в пароводяном тракте котла?',
        answers: [
          'В данном случае вентиляция перед растопкой котла не проводится',
          'Вентиляция должна начинаться не ранее чем за 30 минут до розжига горелок',
          'Вентиляция должна начинаться не ранее чем за 15 минут до розжига горелок',
          'Режим розжига и время начала вентиляции определяется ответственным за исправное состояние и безопасную эксплуатацию котла, исходя из времени простоя котла и температуры поверхностей нагрева',
        ],
        correctAnswers: [
          'Вентиляция должна начинаться не ранее чем за 15 минут до розжига горелок',
        ],
      },
      {
        code: '63692025',
        text:
          'Главные парозапорные органы каких котлов должны быть оборудованы дистанционным управлением с рабочего места обслуживающего котел персонала?',
        answers: [
          'Котлов с рабочим давлением более 0,8 МПа',
          'Всех барабанных паровых котлов',
          'Котлов с паропроизводительностью более 4 тонн в час',
          'Если условный проход такой арматуры более 320 мм',
        ],
        correctAnswers: ['Котлов с паропроизводительностью более 4 тонн в час'],
      },
      {
        code: '63692026',
        text:
          'В каком случае перед манометром на котле должна устанавливаться сифонная трубка?',
        answers: [
          'Если манометр предназначен для измерения давления пара',
          'Если манометр не оснащается трехходовым краном',
          'Каждый манометр обязательно должен оснащаться сифонной трубкой',
          'Если манометр предназначен для измерения давления воды',
        ],
        correctAnswers: [
          'Если манометр предназначен для измерения давления пара',
        ],
      },
      {
        code: '63692027',
        text:
          'Что дополнительно проводится при техническом освидетельствовании электрокотлов?',
        answers: [
          'Испытания электрической части',
          'При техническом освидетельствовании электрокотлов дополнительных испытаний не проводится',
          'Измерение удельного электрического сопротивления питательной (сетевой) воды',
          'Контроль разрушающими методами',
        ],
        correctAnswers: ['Испытания электрической части'],
      },
      {
        code: '63692028',
        text:
          'С какой периодичностью осуществляется проверка исправности сигнализации и автоматических защит на котле?',
        answers: [
          'Не реже одного раза в смену',
          'Для паровых котлов проверка осуществляется не реже одного раза в месяц, а для водогрейных котлов – не реже одного раза в 3 месяца',
          'Периодичность устанавливается эксплуатирующей организацией и отражается в утвержденном этой организацией графике и инструкции',
          'Периодичность устанавливается Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: [
          'Периодичность устанавливается эксплуатирующей организацией и отражается в утвержденном этой организацией графике и инструкции',
        ],
      },
      {
        code: '63692029',
        text:
          'Что необходимо предпринять после вскрытия барабана, коллектора или ремонта котла, если характер и объем ремонта не вызывают необходимости проведения внеочередного технического освидетельствования?',
        answers: [
          'Уполномоченная специализированная организация должна провести гидравлическое испытание котла пробным давлением',
          'Уполномоченный представитель организации, проводившей ремонт (вскрытие барабана, коллектора), проводит наружный и внутренний осмотр котла',
          'Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла пробным давлением, на 25 % превышающем рабочее давление',
          'Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением',
        ],
        correctAnswers: [
          'Ответственный за исправное состояние и безопасную эксплуатацию котла должен провести гидравлическое испытание котла рабочим давлением',
        ],
      },
      {
        code: '63692030',
        text:
          'На какое оборудование распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Паровозный котел',
          'Пароперегреватель трубчатой печи',
          'Автономный экономайзер',
          'Продувочный трубопровод парового котла, соединенный с атмосферой',
        ],
        correctAnswers: ['Автономный экономайзер'],
      },
      {
        code: '63692031',
        text:
          'Кем должны составляться инструкции по ведению водно-химического режима паровых и водогрейных котлов и инструкции по эксплуатации установок докотловой обработки воды?',
        answers: [
          'Специализированной пуско-наладочной организацией',
          'Технической комиссией предприятия-владельца котла и оборудования ВПУ',
          'Ростехнадзором',
          'Проектной организацией',
        ],
        correctAnswers: ['Специализированной пуско-наладочной организацией'],
      },
      {
        code: '63692032',
        text:
          'Для каких котлов продувочные и дренажные трубопроводы, трубопроводы отбора рабочей среды должны оборудоваться не менее чем двумя запорными органами или одним запорным и одним регулирующим органом?',
        answers: [
          'Для всех барабанных паровых котлов',
          'Для котлов с рабочим давлением более 0,8 МПа',
          'Для котлов с паропроизводительностью более 4 тонн в час',
          'Если условный проход таких трубопроводов более 20 мм',
        ],
        correctAnswers: ['Для котлов с рабочим давлением более 0,8 МПа'],
      },
      {
        code: '63692033',
        text:
          'В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, в каком из приведенных случаев котел не подлежит аварийной остановке?',
        answers: [
          'В случае понижения уровня воды ниже низшего допустимого уровня',
          'В случае прекращения работы одного из питательных насосов',
          'В случае снижения расхода воды через водогрейный котел ниже минимально допустимого значения',
          'В случае возникновения пожара, угрожающего обслуживающему персоналу, котлу и технологически взаимосвязанному с ним оборудованию',
        ],
        correctAnswers: [
          'В случае прекращения работы одного из питательных насосов',
        ],
      },
      {
        code: '63692034',
        text:
          'Какое требование к отключению котла до начала производства работ внутри барабана или коллектора котла, соединенного с другими работающими котлами трубопроводами, указано неверно?',
        answers: [
          'Котел должен быть отсоединен от всех трубопроводов заглушками, если на них установлена фланцевая арматура',
          'Если арматура трубопроводов пара и воды бесфланцевая, отключение котла должно быть произведено двумя запорными устройствами при наличии между ними дренажного устройства с номинальным диаметром не менее 32 мм, имеющего прямое соединение с атмосферой',
          'Приводы задвижек, а также запорной арматуры открытых дренажей и линии аварийного слива воды из барабана должны быть заперты на замок так, чтобы исключалась возможность ослабления их плотности при запертом замке',
          'Ключи от замков должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением, если на предприятии не установлен другой порядок их хранения',
        ],
        correctAnswers: [
          'Ключи от замков должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением, если на предприятии не установлен другой порядок их хранения',
        ],
      },
      {
        code: '63692035',
        text:
          'В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, в каком из приведенных случаев котел подлежит аварийной остановке?',
        answers: [
          'В случае прекращения действия одного из указателей уровня воды прямого действия',
          'В случае повышения температуры воды на выходе из водогрейного котла до значения на 20 °С ниже температуры насыщения, соответствующей рабочему давлению воды в выходном коллекторе котла',
          'В случае недопустимого повышения или понижения давления в тракте прямоточного котла после встроенных задвижек',
          'В случае срабатывания взрывного предохранительного клапана',
        ],
        correctAnswers: [
          'В случае повышения температуры воды на выходе из водогрейного котла до значения на 20 °С ниже температуры насыщения, соответствующей рабочему давлению воды в выходном коллекторе котла',
        ],
      },
      {
        code: '63692036',
        text:
          'Какой срок действия сертификата соответствия оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" при использовании схемы 1с?',
        answers: [
          '5 лет',
          '3 года',
          '1 год',
          'В течение назначенного срока службы или назначенного ресурса',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63692037',
        text:
          'Какой из приведенных котлов допускается устанавливать внутри производственных помещений?',
        answers: [
          'Водогрейный электрокотел электрической мощностью 10 МВт',
          'Барабанный водогрейный котел теплопроизводительностью 1,5 Гкал/час',
          'Паровой котел-утилизатор паропроизводительностью 10 тонн пара в час',
          'Прямоточный котел паропроизводительностью 8 тонн пара в час',
        ],
        correctAnswers: [
          'Паровой котел-утилизатор паропроизводительностью 10 тонн пара в час',
        ],
      },
      {
        code: '63692038',
        text:
          'Какое из приведенных требований к программе проведения наладочных работ на оборудовании под давлением указано неверно?',
        answers: [
          'Программу разрабатывает организация, выполняющая соответствующие работы до начала их производства',
          'В программе должны быть отражены содержание и порядок выполнения всех технологических и контрольных операций с обеспечением наладки на всех режимах работы, установленных проектом',
          'Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013',
        ],
        correctAnswers: [
          'Программа проведения наладочных работ подлежит согласованию с эксплуатирующей организацией в случае, если наладка осуществляется на паровых котлах и сосудах, работающих со средой, отнесенной к группе 1 в соответствии с ТР ТС 032/2013',
        ],
      },
      {
        code: '63692039',
        text:
          'С какой периодичностью проводится проверка знаний требований производственных инструкций и (или) инструкций для данной профессии у рабочих?',
        answers: [
          'Один раз в 12 месяцев',
          'Один раз в 6 месяцев',
          'Один раз в месяц',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63692040',
        text:
          'В каком из приведенных случаев не проводятся эксплуатационные испытания (режимная наладка) котла?',
        answers: [
          'При вводе котла в эксплуатацию в объеме пуско-наладочных работ',
          'После внесения изменений в его конструкцию',
          'При переводе котла на другой вид или марку топлива',
          'При пуске котла после планового ремонта',
          'Во всех приведенных случаях проводятся эксплуатационные испытания котла',
        ],
        correctAnswers: ['При пуске котла после планового ремонта'],
      },
      {
        code: '63692041',
        text:
          'Какое из приведенных требований к размещению котлов внутри производственных помещений указано неверно?',
        answers: [
          'Место установки котлов и обеспечивающих их работу систем, устройств и вспомогательного оборудования (насосов, электрических щитов, и иного оборудования в соответствии с проектом) внутри производственных помещений должно быть отделено от остальной части помещения несгораемыми перегородками по всей высоте котла, но не ниже 2 м с устройством дверей',
          'Котлы-утилизаторы могут быть отделены от остальной части производственного помещения вместе с печами или агрегатами, с которыми они связаны технологическим процессом',
          'Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Двери для выхода из помещения, в котором установлены котлы, должны открываться вовнутрь, а двери служебных, бытовых, а также вспомогательных производственных помещений должны открываться в сторону этих помещений',
        ],
      },
      {
        code: '63692042',
        text:
          'Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы котла?',
        answers: [
          'Инструкция (руководство) по эксплуатации предприятия-изготовителя котла и ФНП ОРПД',
          'Программа проведения технического освидетельствования котла, разработанная специализированной организацией до начала проведения освидетельствования',
          'Производственная инструкция по эксплуатации котла, утвержденная главным техническим руководителем эксплуатационной организации',
        ],
        correctAnswers: [
          'Инструкция (руководство) по эксплуатации предприятия-изготовителя котла и ФНП ОРПД',
        ],
      },
      {
        code: '63692043',
        text:
          'В какие сроки должен проводиться контроль плотности ограждающих поверхностей котла и газоходов и исправность взрывных предохранительных клапанов (при их наличии)?',
        answers: [
          'Не реже одного раза в год',
          'До и после ремонта котла',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63692044',
        text:
          'В каких местах на паровых котлах с естественной циркуляцией без пароперегревателя устанавливаются предохранительные устройства?',
        answers: [
          'На верхнем барабане или сухопарнике',
          'На выходных коллекторах или выходном паропроводе',
          'На выходных коллекторах или барабане',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['На верхнем барабане или сухопарнике'],
      },
      {
        code: '63692045',
        text:
          'При выполнении какого условия допускается ускоренное расхолаживание котла при его останове?',
        answers: [
          'Если скорость охлаждения при останове котла не превышает 30 °С за 10 минут',
          'Если перепад температур между верхней и нижней образующими барабана не превышает 90 °С',
          'Режимные параметры ускоренного расхолаживания указываются в руководстве (инструкции) по эксплуатации',
          'На котлах ускоренное расхолаживание не допускается',
        ],
        correctAnswers: ['На котлах ускоренное расхолаживание не допускается'],
      },
      {
        code: '63692046',
        text:
          'В каком случае на паровом котле устанавливаются два сниженных дистанционных указателя уровня?',
        answers: [
          'Если расстояние от площадки, с которой производят наблюдение за уровнем воды в паровом котле, до указателей уровня воды прямого действия более 3 м',
          'Если в котельном помещении плохая видимость приборов',
          'Если отсутствует звуковая или световая сигнализация, срабатывающая при достижении предельных значений уровня воды',
          'Если котел работает с давлением более 4,0 МПа',
        ],
        correctAnswers: [
          'Если в котельном помещении плохая видимость приборов',
        ],
      },
      {
        code: '63692047',
        text:
          'Какое требование к заполнению котла водой перед растопкой указано неверно?',
        answers: [
          'Перед растопкой барабанный котел должен быть заполнен химически очищенной и деаэрированной питательной водой',
          'Перед растопкой прямоточный котел должен быть заполнен деаэрированной водой',
          'При отсутствии в котельной деаэрационной установки перед растопкой допускается заполнять чугунные котлы химически очищенной водой',
          'Заполнение неостывшего барабанного котла разрешается при температуре металла верха опорожненного барабана не более 160 °C (если иное не указано в руководстве (инструкции) по эксплуатации организации-изготовителя котла)',
        ],
        correctAnswers: [
          'Перед растопкой прямоточный котел должен быть заполнен деаэрированной водой',
        ],
      },
      {
        code: '63692048',
        text:
          'Каким из перечисленных требований должны отвечать работники специализированных организаций, непосредственно выполняющие работы по монтажу (демонтажу), наладке либо ремонту или реконструкции (модернизации) оборудования под давлением?',
        answers: [
          'Иметь документы, подтверждающие прохождение профессионального обучения по соответствующим видам рабочих специальностей, а также документ о допуске к самостоятельной работе (для рабочих), оформленный в порядке, установленном распорядительными документами организации',
          'Иметь документы о прохождении аттестации (для руководителей и инженерно-технических работников)',
          'Знать основные источники опасностей при проведении выполняемых работ, знать и применять на практике способы защиты от них, а также безопасные методы выполнения работ',
          'Знать и уметь применять способы выявления и технологию устранения дефектов в процессе монтажа, ремонта, реконструкции (модернизации)',
          'Всем перечисленным требованиям',
        ],
        correctAnswers: ['Всем перечисленным требованиям'],
      },
      {
        code: '63692049',
        text:
          'Если осуществляется пуск котла после кратковременного простоя, то при каком минимальном значении тепловой нагрузки на растопочном топливе допускается переход на сжигание твердого топлива с выходом летучих масс менее 15 %?',
        answers: [
          '15 % номинальной тепловой нагрузки',
          '30 % номинальной тепловой нагрузки',
          'Значение тепловой нагрузки должно определяться только производственной инструкцией, исходя из обеспечения устойчивого воспламенения пыли',
          '10 % номинальной тепловой нагрузки',
        ],
        correctAnswers: ['15 % номинальной тепловой нагрузки'],
      },
      {
        code: '63692050',
        text:
          'Кто проводит испытания оборудования, предусмотренные проектной документацией?',
        answers: [
          'Изготовитель',
          'Поставщик',
          'Проектировщик',
          'Предприятие-владелец',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63692051',
        text:
          'При каком условии для питания котлов водой может применяться водопроводная сеть?',
        answers: [
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,15 МПа',
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,05 МПа',
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,5 МПа',
          'Запрещается применять водопроводную сеть для питания котлов',
        ],
        correctAnswers: [
          'Если минимальное давление воды в водопроводной сети перед регулирующим органом питания котла превышает расчетное или разрешенное давление в котле не менее чем на 0,15 МПа',
        ],
      },
      {
        code: '63692052',
        text:
          'В каком из приведенных случаев содорегенерационный котел должен быть переведен на сжигание вспомогательного топлива?',
        answers: [
          'При поступлении воды в топку',
          'В случае прекращения действия устройств дробления струи плава и остановки мешалок в растворителе плава',
          'В случае выхода из строя всех перекачивающих насосов, или одного из дымососов, или одного из вентиляторов',
        ],
        correctAnswers: [
          'В случае выхода из строя всех перекачивающих насосов, или одного из дымососов, или одного из вентиляторов',
        ],
      },
      {
        code: '63692053',
        text:
          'Какой документ о подтверждении соответствия требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" должно иметь оборудование, отнесенное ко 2-й категории опасности, доизготовление которого с применением неразъемных соединений осуществляется по месту эксплуатации?',
        answers: [
          'Сертификат',
          'Заключение экспертизы промышленной безопасности',
          'Декларация соответствия',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Декларация соответствия'],
      },
      {
        code: '63692054',
        text:
          'Какое требование к заполнению прямоточного котла перед его растопкой должно выполняться?',
        answers: [
          'Заполнение водой прямоточного котла, удаление из него воздуха, а также операции при промывке от загрязнений должны быть произведены на участке до встроенных в тракт котла задвижек при прямоточном режиме растопки',
          'Заполнение водой прямоточного котла, удаление из него воздуха, а также операции при промывке от загрязнений должны быть произведены по всему тракту при сепараторном режиме растопки',
          'Растопочный расход воды должен быть определен на основании результатов наладочных испытаний',
          'Растопочный расход воды должен быть равен 30 % номинального расхода',
        ],
        correctAnswers: [
          'Растопочный расход воды должен быть равен 30 % номинального расхода',
        ],
      },
      {
        code: '63692055',
        text:
          'Каково минимальное значение расстояния между фронтом электрокотлов электрической мощностью 5 МВт, расположенных друг против друга?',
        answers: ['1 м', '2 м', '3 м', 'Устанавливается проектом'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63692056',
        text:
          'В каком случае допускается обустройство площадки для установки котла ниже планировочной отметки территории, прилегающей к зданию, в котором установлены котлы?',
        answers: [
          'В случае размещения в здании, в котором установлены котлы, узлов ввода и вывода теплотрасс',
          'В случае, обоснованном технологической необходимостью, по решению организации - разработчика проектной документации',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63692057',
        text:
          'Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?',
        answers: [
          'Совместно специализированной организацией, выполняющей эти работы, и эксплуатирующей организацией',
          'Эксплуатирующей организацией на основании руководства (инструкции) по эксплуатации оборудования, работающего под давлением, с последующим согласованием со специализированной организацией, выполняющей эти работы',
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
          'Организацией - изготовителем оборудования, работающего под давлением',
        ],
        correctAnswers: [
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
        ],
      },
      {
        code: '63692058',
        text:
          'Кем вносятся сведения в ремонтный журнал котла о выполненных ремонтных работах, примененных материалах, сварке и сварщиках, об остановке котлов на чистку и промывку?',
        answers: [
          'Ответственным за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
          'Ответственным за исправное состояние и безопасную эксплуатацию котла',
          'Уполномоченным представителем специализированной ремонтной организации',
          'Лицо, которое вносит сведения в ремонтный журнал котла, определяется распорядительными документами эксплуатирующей организации',
        ],
        correctAnswers: [
          'Ответственным за исправное состояние и безопасную эксплуатацию котла',
        ],
      },
      {
        code: '63692059',
        text:
          'Какие требования к обоснованию безопасности согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" указаны неверно?',
        answers: [
          'Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя',
          'Обоснование безопасности оборудования готовится на этапе разработки (проектирования) оборудования',
          'В обосновании безопасности приводятся анализ рисков для оборудования, а также минимально необходимые меры по обеспечению безопасности',
          'Оригинал обоснования безопасности оборудования хранится у разработчика (проектировщика)',
        ],
        correctAnswers: [
          'Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя',
        ],
      },
      {
        code: '63692060',
        text:
          'В какие сроки проводятся наружный и внутренний осмотры котла, если иные сроки не предусмотрены руководством (инструкцией) по эксплуатации?',
        answers: [
          'Один раз в четыре года наружный и внутренний осмотры',
          'Наружный осмотр - один раз в четыре года, а внутренний осмотр - один раз в восемь лет',
          'В сроки, установленные эксплуатирующей котлы организацией',
          'В сроки, установленные ФНП ОРПД и указанные в соответствующем приложении ФНП ОРПД',
        ],
        correctAnswers: [
          'Один раз в четыре года наружный и внутренний осмотры',
        ],
      },
      {
        code: '63692061',
        text:
          'Каким образом при эксплуатации паровых электрокотлов поддерживается необходимое значение величины удельного электрического сопротивления котловой воды?',
        answers: [
          'Путем автоматизированной непрерывной продувки',
          'Путем введения легкорастворимых солей в питательную и котловую воду',
          'Путем автоматизированной непрерывной продувки, а также периодической продувки',
        ],
        correctAnswers: [
          'Путем автоматизированной непрерывной продувки, а также периодической продувки',
        ],
      },
      {
        code: '63692062',
        text:
          'Кто устанавливает полный перечень предельно допустимых показателей качества исходной воды, приведенных в режимной карте по эксплуатации установок докотловой обработки воды?',
        answers: [
          'Пусконаладочная организация',
          'Эксплуатирующая организация',
          'Ростехнадзор',
          'Проектная организация',
        ],
        correctAnswers: ['Пусконаладочная организация'],
      },
      {
        code: '63692063',
        text:
          'Какой должен быть класс точности средства измерения давления при рабочем давлении от 2,5 до 14 МПа включительно?',
        answers: ['Не ниже 1,5', 'Не ниже 2,5', 'Не ниже 1'],
        correctAnswers: ['Не ниже 1,5'],
      },
      {
        code: '63692064',
        text:
          'Каково нормируемое максимальное значение присосов воздуха в топку парового газомазутного котла, оснащенного цельносварными экранами?',
        answers: [
          'Для котлов паропроизводительностью до 420 т/ч присосы должны быть не более 5 % от теоретически необходимого количества воздуха для номинальной нагрузки котлов',
          'Для котлов паропроизводительностью выше 420 т/ч присосы должны быть не более 3 % от теоретически необходимого количества воздуха для номинальной нагрузки котлов',
          'Независимо от паропроизводительности котлов присосы должны быть не более 8 % от теоретически необходимого количества воздуха для номинальной нагрузки котлов',
          'Топки с цельносварными экранами должны быть бесприсосными',
        ],
        correctAnswers: [
          'Топки с цельносварными экранами должны быть бесприсосными',
        ],
      },
      {
        code: '63692065',
        text:
          'Для какого котла допускается работа с одним электроприводным питательным насосом?',
        answers: [
          'Для котла паропроизводительностью не более 4 тонны в час, если котел оснащен автоматикой безопасности по предельным уровням воды',
          'Для котла паропроизводительностью не более 5 тонн в час, если котел оснащен автоматикой безопасности по предельным уровням воды',
          'Для всех водогрейных котлов',
          'Не допускается работа котлов с одним питательным насосом с электроприводом.',
        ],
        correctAnswers: [
          'Для котла паропроизводительностью не более 4 тонны в час, если котел оснащен автоматикой безопасности по предельным уровням воды',
        ],
      },
      {
        code: '63692066',
        text:
          'Что не контролируется при проведении проверки готовности котла к пуску в работу?',
        answers: [
          'Наличие документации по результатам пуско-наладочных испытаний и комплексного опробования',
          'Исправность питательных приборов котла и соответствие их проекту',
          'Соответствие водно-химического режима котла требованиям Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию котла',
        ],
        correctAnswers: [
          'Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию котла',
        ],
      },
      {
        code: '63692067',
        text:
          'В каком из приведенных случаев проводится техническое диагностирование, разрушающий и неразрушающий контроль котла в процессе его эксплуатации в пределах назначенного срока службы?',
        answers: [
          'При проведении эксплуатационного контроля металла элементов котла в случаях, установленных руководством по эксплуатации',
          'Периодически через каждые 15 лет эксплуатации котла',
          'Техническое диагностирование, разрушающий и неразрушающий контроль котла в процессе его эксплуатации в пределах назначенного срока службы не проводится',
          'Каждый раз при проведении технического освидетельствования котла',
        ],
        correctAnswers: [
          'При проведении эксплуатационного контроля металла элементов котла в случаях, установленных руководством по эксплуатации',
        ],
      },
      {
        code: '63692068',
        text:
          'Для каких котлов, работающих на твердом топливе, должна быть обеспечена механизированная подача топлива в котельную и топку котла?',
        answers: [
          'Для котлов паропроизводительностью 2,5 т/ч и выше',
          'Для котлов паропроизводительностью от 1,5 т/ч до 3,5 т/ч',
          'Для котлов паропроизводительностью до 1,5 т/ч',
          'Для любых котлов, работающих на твердом топливе',
        ],
        correctAnswers: ['Для котлов паропроизводительностью 2,5 т/ч и выше'],
      },
      {
        code: '63692069',
        text:
          'Каким образом осуществляется учет котлов транспортабельных (передвижных) котельных установок в органах Ростехнадзора?',
        answers: [
          'По месту нахождения (государственной регистрации) эксплуатирующей организации',
          'Как по месту регистрации эксплуатирующей организации, так и по месту их эксплуатации (временный учет) при сроках их эксплуатации на этом месте более трех месяцев',
          'Эти котлы не подлежат учету в органах Ростехнадзора',
        ],
        correctAnswers: [
          'По месту нахождения (государственной регистрации) эксплуатирующей организации',
        ],
      },
      {
        code: '63692070',
        text:
          'На каких котлах на выходной части змеевиков пароперегревателей устанавливаются средства измерений с непрерывной регистрацией величины температуры пара?',
        answers: [
          'На паровых котлах с производительностью пара более 400 т/ч',
          'На паровых котлах с производительностью пара более 300 т/ч',
          'На паровых котлах с производительностью пара до 200 т/ч',
          'На паровых котлах с производительностью пара до 150 т/ч',
        ],
        correctAnswers: [
          'На паровых котлах с производительностью пара более 400 т/ч',
        ],
      },
      {
        code: '63692071',
        text:
          'Какой документ является основным для идентификации оборудования, работающего под избыточным давлением?',
        answers: [
          'Сертификат соответствия оборудования требованиям ТР ТС 032/2013',
          'Обоснование безопасности оборудования',
          'Паспорт оборудования',
          'Декларация соответствия оборудования требованиям промышленной безопасности',
        ],
        correctAnswers: ['Паспорт оборудования'],
      },
      {
        code: '63692072',
        text:
          'Каково минимальное значение ширины бокового прохода при установке парового котла паропроизводительностью 8 тонн в час, для которого требуется боковое обслуживание?',
        answers: ['Устанавливается проектом', '1 м', '1,5 м', '2 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63692073',
        text:
          'Какие из перечисленных котлов должны быть оборудованы регистрирующим средством измерения давления? Выберите 2 варианта ответа.',
        answers: [
          'Паровые котлы с производительностью пара более 10 т/ч',
          'Водогрейные котлы с производительностью пара более 21 ГДж/ч',
          'Паровые котлы с производительностью пара более 5 т/ч',
          'Водогрейные котлы с производительностью пара более 15 ГДж/ч',
        ],
        correctAnswers: [
          'Паровые котлы с производительностью пара более 10 т/ч',
          'Водогрейные котлы с производительностью пара более 21 ГДж/ч',
        ],
      },
      {
        code: '63692074',
        text:
          'На какие котлы следует обращать особое внимание при составлении режимных карт по эксплуатации установок докотловой обработки воды и водно-химического режима?',
        answers: [
          'На котлы, имевшие за период своей эксплуатации более 200 остановок',
          'На котлы, имевшие за период своей эксплуатации более 50 остановок',
          'На котлы, имевшие за период своей эксплуатации более 100 остановок',
          'На котлы, имевшие за период своей эксплуатации более 150 остановок',
        ],
        correctAnswers: [
          'На котлы, имевшие за период своей эксплуатации более 200 остановок',
        ],
      },
      {
        code: '63692075',
        text:
          'Каким образом должно осуществляться расхолаживание котлов после остановки при выводе их в ремонт?',
        answers: [
          'Расхолаживание осуществляется тягодутьевыми машинами при обеспечении допустимой разности температур металла между верхней и нижней образующими барабана',
          'Расхолаживание котлов должно осуществляться не ранее чем через 12 часов после остановки при скорости расхолаживания не выше 10 °С за 10 минут',
          'Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации',
          'Режим расхолаживания котлов определяется требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: [
          'Режим расхолаживания котлов должен быть определен руководством (инструкцией) по эксплуатации',
        ],
      },
      {
        code: '63692076',
        text:
          'В какой из приведенных форм не проводится оценка соответствия оборудования, работающего под давлением, на которое распространяется действие технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Экспертизы промышленной безопасности',
          'Сертификации',
          'Декларирования соответствия',
          'Государственного контроля (надзора)',
        ],
        correctAnswers: ['Экспертизы промышленной безопасности'],
      },
      {
        code: '63692077',
        text:
          'Какие из приведенных котлов не оборудуются установками для докотловой обработки воды?',
        answers: [
          'Паровые котлы с естественной циркуляцией и паропроизводительностью менее 0,7 т/час',
          'Водогрейные котлы тепловой мощностью до 12 ГДж/час',
          'Прямоточные котлы паропроизводительностью менее 0,7 т/час',
          'Все приведенные котлы не оборудуются установками докотловой обработки воды',
        ],
        correctAnswers: [
          'Паровые котлы с естественной циркуляцией и паропроизводительностью менее 0,7 т/час',
        ],
      },
      {
        code: '63692078',
        text:
          'Исходя из какого условия должна проводиться регенерация теплоносителя в котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями?',
        answers: [
          'Содержание продуктов разложения в теплоносителе не должно превышать 50 %',
          'Содержание продуктов разложения в теплоносителе не должно превышать 25 %',
          'Содержание продуктов разложения в теплоносителе не должно превышать 10 %',
          'Содержание продуктов разложения в теплоносителе не должно превышать 5 %',
        ],
        correctAnswers: [
          'Содержание продуктов разложения в теплоносителе не должно превышать 10 %',
        ],
      },
      {
        code: '63692079',
        text:
          'Какие из перечисленных требований указаны неверно согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'На оборудование, предназначенное для транспортировки сжиженных (баллоны и автоцистерны), наносятся отличительная окраска и идентификационная информация',
          'Элементы и комплектующие оборудования маркируются в соответствии с договором на поставку (контрактом)',
          'Техническая документация на оборудование хранится у изготовителя (уполномоченного изготовителем лица) в течение расчетного срока службы со дня прекращения производства этого оборудования или прекращения его производства',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63692080',
        text:
          'В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?',
        answers: [
          'Начало и конец комплексного опробования оборудования, работающего под давлением, устанавливаются совместным приказом эксплуатирующей организации и организации, проводящей наладочные работы',
          'Котлы – в течение 72 часов, трубопроводы пара и горячей воды – в течение 36 часов, время комплексного опробования сосудов устанавливается совместным приказом эксплуатирующей и наладочной организаций',
          'Котлы – в течение 72 часов, трубопроводы тепловых сетей – в течение 24 часов, остальное оборудование – по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с  эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Котлы – в течение 72 часов, трубопроводы тепловых сетей – в течение 24 часов, остальное оборудование – по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с  эксплуатирующей организацией',
        ],
      },
      {
        code: '63692081',
        text:
          'В каких местах на паровых прямоточных котлах, а также на котлах с принудительной циркуляцией устанавливаются предохранительные устройства?',
        answers: [
          'На верхнем барабане или сухопарнике',
          'На выходных коллекторах или выходном паропроводе',
          'На выходных коллекторах или барабане',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['На выходных коллекторах или выходном паропроводе'],
      },
      {
        code: '63692082',
        text:
          'Кем определяются тип арматуры, ее количество и место установки согласно требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Разработчиком проекта оборудования',
          'Монтажной организацией',
          'Эксплуатирующей организацией',
          'Специализированной экспертной организацией',
        ],
        correctAnswers: ['Разработчиком проекта оборудования'],
      },
      {
        code: '63692083',
        text:
          'Какое должно быть расстояние по вертикали от площадки для обслуживания водоуказательных приборов до середины водоуказательного стекла (шкалы)?',
        answers: [
          'Не менее 1 м и не более 1,5 м',
          'Не менее 0,7 м и не более 2 м',
          'Не менее 1,5 м и не более 2,5 м',
          'Не менее 1,2 м и не более 3 м',
        ],
        correctAnswers: ['Не менее 1 м и не более 1,5 м'],
      },
      {
        code: '63692084',
        text: 'Какое требование к растопке котла указано неверно?',
        answers: [
          'Растопка котла из различных тепловых состояний должна быть выполнена в соответствии с производственными инструкциями и графиками пуска',
          'В процессе растопки котла из холодного состояния после ремонта, но не реже одного раза в год должно проверяться по реперам тепловое перемещение экранов, барабанов, паропроводов и коллекторов',
          'Если до пуска котла на нем производили работы, связанные с разборкой фланцевых соединений и лючков, то при избыточном давлении 0,7 – 1,0 МПа должны быть подтянуты болтовые соединения',
          'Скорость прогрева нижней образующей барабана и перепад температур между верхней и нижней образующими барабана не должны превышать значений, установленных руководством (инструкцией) по эксплуатации',
          'Должны выполняться все указанные требования',
        ],
        correctAnswers: [
          'Если до пуска котла на нем производили работы, связанные с разборкой фланцевых соединений и лючков, то при избыточном давлении 0,7 – 1,0 МПа должны быть подтянуты болтовые соединения',
        ],
      },
      {
        code: '63692085',
        text:
          'Когда должны проводиться испытания повышенным напряжением промышленной частоты изолирующих вставок в соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов?',
        answers: [
          'После монтажа, профилактического испытания, текущего ремонта, капитального ремонта',
          'Только после профилактического испытания и капитального ремонта',
          'Только после монтажа, профилактического испытания',
          'Только после монтажа',
        ],
        correctAnswers: [
          'Только после профилактического испытания и капитального ремонта',
        ],
      },
      {
        code: '63692086',
        text:
          'Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией котлов?',
        answers: [
          'Выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения',
          'Проверка записи в сменном журнале с росписью в нем',
          'Контроль проведения противоаварийных тренировок',
          'Отстранение от работ работников, нарушающих требования промышленной безопасности',
        ],
        correctAnswers: ['Проверка записи в сменном журнале с росписью в нем'],
      },
      {
        code: '63692087',
        text:
          'В какие сроки проводится гидравлическое испытание котла, если иные сроки не предусмотрены руководством (инструкцией) по эксплуатации?',
        answers: [
          'Один раз в четыре года',
          'Один раз в восемь лет',
          'В сроки, установленные эксплуатирующей котлы организацией',
          'В сроки, установленные ФНП ОРПД и указанные в соответствующем приложении ФНП ОРПД',
        ],
        correctAnswers: ['Один раз в восемь лет'],
      },
      {
        code: '63692088',
        text: 'Какие насосы могут применяться для питания котлов водой?',
        answers: [
          'Центробежные, поршневые и плунжерные насосы с электрическим приводом',
          'Центробежные, поршневые и плунжерные насосы с паровым приводом',
          'Паровые инжекторы (пароструйный инжектор относится к насосу с паровым приводом)',
          'Насосы с ручным приводом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63692089',
        text:
          'Каково минимальное значение номинального диаметра корпуса манометра, установленного на высоте более 5 м от уровня площадки наблюдения?',
        answers: ['Не нормируется', '100 мм', '160 мм', '250 мм'],
        correctAnswers: ['250 мм'],
      },
      {
        code: '63692090',
        text:
          'В каком случае на котле помимо рабочего манометра должен устанавливаться сниженный манометр в качестве дублирующего?',
        answers: [
          'Если в котельном помещении плохая видимость приборов',
          'Если рабочий манометр используется как датчик наличия давления в котле',
          'Если рабочее место машиниста (оператора) котла расположено непосредственно у фронта котла',
          'Если рабочий манометр установлен на высоте более 5 м',
        ],
        correctAnswers: [
          'Если рабочий манометр установлен на высоте более 5 м',
        ],
      },
      {
        code: '63692091',
        text: 'Какое требование к подпитке котлов сырой водой указано неверно?',
        answers: [
          'Подпитка сырой водой котлов, оборудованных устройствами для докотловой обработки воды, не допускается',
          'Каждый случай подпитки котлов сырой водой должен фиксироваться в журнале по водоподготовке (водно-химическому режиму) с указанием длительности подпитки и качества питательной воды в этот период',
          'Когда проектом предусмотрена в аварийных ситуациях подпитка водогрейного котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Когда проектом предусмотрена в аварийных ситуациях подпитка водогрейного котла сырой водой, котлы должны работать на сниженных температурных параметрах с температурой теплоносителя на выходе из котла не более 90 °С',
        ],
      },
      {
        code: '63692092',
        text: 'В каких местах допускается установка запорной арматуры?',
        answers: [
          'На прямом участке паропровода',
          'На отводящем трубопроводе предохранительного клапана',
          'На дренаже отводящего трубопровода предохранительного клапана',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['На прямом участке паропровода'],
      },
      {
        code: '63692093',
        text:
          'В каком из приведенных случаев должны проводиться механические испытания?',
        answers: [
          'При входном контроле сварочных материалов, используемых при газовой или контактной сварке',
          'При контроле сварных стыковых соединений, выполненных сваркой под флюсом или электрошлаковой сваркой',
          'При аттестации технологии сварки',
        ],
        correctAnswers: ['При аттестации технологии сварки'],
      },
      {
        code: '63692094',
        text:
          'При каких условиях допускается применение сальниковой арматуры на котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями?',
        answers: [
          'Применение сальниковой арматуры на котлах допускается без ограничений',
          'Применяемая на котлах арматура должна быть только сильфонного типа, применение сальниковой арматуры не допускается',
          'Допускается применение сальниковой арматуры на давление не более 1,6 МПа',
          'Допускается применение сальниковой арматуры на спускной линии теплоносителя в непосредственной близости от котла на расстоянии не более 1 м',
        ],
        correctAnswers: [
          'Допускается применение сальниковой арматуры на давление не более 1,6 МПа',
        ],
      },
      {
        code: '63692095',
        text:
          'Какое из приведенных требований должно выполняться при эксплуатации паровых котлов с чугунными экономайзерами?',
        answers: [
          'Чугунные экономайзеры должны быть неотключаемыми по воде',
          'Температура воды на выходе из экономайзера должна быть не менее чем на 20 °С ниже температуры насыщенного пара',
          'Паровые котлы должны эксплуатироваться только со стальными экономайзерами',
          'Температура воды на выходе из чугунного экономайзера не должна превышать 150 °С',
        ],
        correctAnswers: [
          'Температура воды на выходе из экономайзера должна быть не менее чем на 20 °С ниже температуры насыщенного пара',
        ],
      },
      {
        code: '63692096',
        text:
          'При каком условии в котлах, работающих с высокотемпературными органическими и неорганическими теплоносителями, допускается применение теплоносителей, отличных от указанных в паспорте котла?',
        answers: [
          'Применение теплоносителей, не указанных в паспорте котла, не допускается',
          'Если проведена экспертиза промышленной безопасности, по результатам которой допущено применение не указанного в паспорте котла теплоносителя',
          'Применение теплоносителей, не указанных в паспорте котла, допускается только по согласованию с разработчиком проекта котла',
          'Применение теплоносителей, не указанных в паспорте котла, допускается только по согласованию с организацией-изготовителем котла',
        ],
        correctAnswers: [
          'Применение теплоносителей, не указанных в паспорте котла, допускается только по согласованию с организацией-изготовителем котла',
        ],
      },
      {
        code: '63692097',
        text:
          'При каких условиях разрешается спуск воды из остановленного парового котла с естественной циркуляцией (кроме энергетических котлов, установленных на тепловых электростанциях)?',
        answers: [
          'При понижении избыточного давления в котле до 1,0 МПа',
          'При понижении избыточного давления в котле до 0,1 МПа',
          'При понижении давления в котле до атмосферного давления',
          'При снижении температуры воды в котле до 70 °С',
        ],
        correctAnswers: [
          'При понижении давления в котле до атмосферного давления',
        ],
      },
      {
        code: '63692098',
        text:
          'Что означает термин "температура рабочей среды" согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
          'Температура, при которой определяются физико-механические характеристики, допускаемое напряжение материала и проводится расчет на прочность элементов оборудования',
          'Максимальная (минимальная) температура стенки сосуда, при которой допускается эксплуатация оборудования',
          'Температура, равная 20°С, используемая при расчете на прочность оборудования (арматуры, деталей и соединений трубопроводов и т. п.)',
        ],
        correctAnswers: [
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
        ],
      },
      {
        code: '63692099',
        text:
          'На какие котлы распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Котел на органическом теплоносителе, с рабочим давлением 0,5 МПа, установленный на плавучей буровой установке',
          'Электрокотел вместимостью 20 литров с рабочим давлением 0,5 МПа',
          'Котел вместимостью 1 литр с рабочим давлением 1,5 МПа',
          'Отопительный котел железнодорожного подвижного состава',
        ],
        correctAnswers: [
          'Котел на органическом теплоносителе, с рабочим давлением 0,5 МПа, установленный на плавучей буровой установке',
        ],
      },
      {
        code: '63692100',
        text:
          'Что должно предусматриваться проектом котельного помещения, если расстояние от нулевой отметки котельного помещения до верхней площадки котлов превышает 20 м?',
        answers: [
          'Грузоподъемный кран, грузоподъемность которого должна быть не менее массы барабана или наиболее тяжелого элемента безбарабанного котла',
          'Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг',
          'Грузоподъемный кран для подъема грузов грузоподъемностью не менее 5 тонн и пассажирский лифт, минимальная грузоподъемность которого составляет 800 кг',
        ],
        correctAnswers: [
          'Подъемные устройства для подъема людей и грузов грузоподъемностью, соответствующей весу перемещаемых грузов и людей (в случае совместного подъема), но не менее 1000 кг',
        ],
      },
      {
        code: '63692101',
        text: 'Какую информацию должен включать в себя паспорт трубопровода?',
        answers: [
          'Наименование и адрес предприятия-владельца',
          'Рабочая среда',
          'Расчетное количество пусков',
          'Расчетный срок службы',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63692102',
        text:
          'Какая информация из приведенной не включается в маркировку, наносимую на оборудование, работающее под давлением?',
        answers: [
          'Наименование и (или) обозначение оборудования',
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
          'Параметры и характеристики, влияющие на безопасность',
          'Наименование материала, из которого изготовлено оборудование',
          'Вся приведенная информация включается в маркировку, наносимую на оборудование',
        ],
        correctAnswers: [
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63692103',
        text:
          'Какое минимальное количество взаимозаменяемых циркуляционных сетевых насосов должно быть установлено в котельных с водогрейными котлами паропроизводительностью более 4 т/ч?',
        answers: ['Два', 'Три', 'Четыре', 'Не регламентируется'],
        correctAnswers: ['Два'],
      },
      {
        code: '63692104',
        text:
          'При каких условиях разрешается спуск воды из остановленного водогрейного котла?',
        answers: [
          'После охлаждения воды в нем до температуры, равной температуре воды в обратном трубопроводе, но не выше 70 °С',
          'После охлаждения воды в нем до температуры, равной температуре воды в обратном трубопроводе',
          'После охлаждения воды в нем до температуры не выше 95 °С',
          'Из остановленного водогрейного котла разрешается спускать воду при давлении выше атмосферного, верхний предел этого давления должен быть установлен руководством (инструкцией) по эксплуатации',
        ],
        correctAnswers: [
          'После охлаждения воды в нем до температуры, равной температуре воды в обратном трубопроводе, но не выше 70 °С',
        ],
      },
      {
        code: '63692105',
        text:
          'Каково предельное значение разницы в номинальной производительности отдельных форсунок в комплекте, устанавливаемом на мазутный котел?',
        answers: [
          'Не более 1 %',
          'Не более 1,5 %',
          'Не более 3 %',
          'Не более 5 %',
        ],
        correctAnswers: ['Не более 1,5 %'],
      },
      {
        code: '63692106',
        text:
          'Какие котлы перед вводом в эксплуатацию после монтажа подвергаются очистке совместно с основными трубопроводами и другими элементами водопарового тракта?',
        answers: [
          'Все паровые котлы независимо от давления',
          'Паровые котлы с рабочим давлением 10 МПа и более',
          'Прямоточные котлы с рабочим давлением 10 МПа и более',
          'Водогрейные котлы',
        ],
        correctAnswers: ['Паровые котлы с рабочим давлением 10 МПа и более'],
      },
      {
        code: '63692107',
        text:
          'Каково минимально допустимое значение расстояния от фронта котла паропроизводительностью 10 тонн в час до противоположной стены котельного помещения?',
        answers: [
          '1,5 м',
          '2 м при условии отсутствия необходимости обслуживания топки с фронта',
          '3 м',
          'Устанавливается проектом',
        ],
        correctAnswers: ['3 м'],
      },
      {
        code: '63692108',
        text:
          'Что из перечисленного может применяться в качестве предохранительных устройств на оборудовании, работающим под избыточным давлением?',
        answers: [
          'Рычажно-грузовые предохранительные клапаны прямого действия',
          'Пружинные предохранительные клапаны прямого действия',
          'Импульсные предохранительные устройства, состоящие из импульсного клапана и главного предохранительного клапана',
          'Предохранительные устройства с разрушающимися мембранами (мембранные предохранительные устройства)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63692109',
        text:
          'Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?',
        answers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
          'В случае невозможности контроля сварного соединения с двух сторон его контроль должен быть проведен в порядке, предусмотренном технологической документацией на сварку',
          'Перед визуальным контролем поверхности сварного шва и прилегающих к нему участков основного металла шириной не менее 10 мм в обе стороны от шва должны быть зачищены от шлака и других загрязнений',
        ],
        correctAnswers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
        ],
      },
      {
        code: '63692110',
        text:
          'Какое количество инструкций с режимными картами по ведению водно-химического режима и по эксплуатации установок докотловой обработки воды должны иметь владельцы котлов в каждой котельной?',
        answers: [
          'Две отдельные инструкции',
          'Три отдельные инструкции',
          'Одну общую инструкцию',
          'Четыре отдельные инструкции',
          'Не регламентируется',
        ],
        correctAnswers: ['Две отдельные инструкции'],
      },
      {
        code: '63692111',
        text:
          'Каким образом должна выполняться продувка верхних водоуказательных приборов при контроле уровня воды в барабане, осуществляемом с момента начала растопки котла с рабочим давлением более 4,0 МПа?',
        answers: [
          'Продувка должна выполняться при избыточном давлении в котле 0,3 МПа и перед включением котла в главный паропровод',
          'Продувка должна выполняться непрерывно до включения котла в главный паропровод',
          'Продувка должна выполняться при избыточном давлении в котле 0,3 МПа и при избыточном давлении 1,5–3,0 МПа',
          'Продувка должна выполняться при избыточном давлении в котле 0,1 МПа и при избыточном давлении 3,0 МПа',
        ],
        correctAnswers: [
          'Продувка должна выполняться при избыточном давлении в котле 0,3 МПа и при избыточном давлении 1,5–3,0 МПа',
        ],
      },
      {
        code: '63692112',
        text:
          'Какой должен быть класс точности средства измерения давления при рабочем давлении более 14 МПа?',
        answers: ['Не ниже 1,5', 'Не ниже 2,5', 'Не ниже 1'],
        correctAnswers: ['Не ниже 1'],
      },
      {
        code: '63692113',
        text:
          'В каком из приведенных случаев манометр может быть допущен к применению?',
        answers: [
          'Если отсутствует информация о проведении поверки (пломба или клеймо, или документ о проведении поверки)',
          'Если разбито стекло или имеются другие повреждения манометра, которые могут отразиться на правильности его показаний',
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
          'Во всех приведенных случаях манометр не допускается к применению',
        ],
        correctAnswers: [
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
        ],
      },
      {
        code: '63692114',
        text:
          'Когда должна проводиться проверка действия защитной аппаратуры котла в соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов?',
        answers: [
          'После монтажа, профилактического испытания, текущего ремонта, капитального ремонта',
          'Только после профилактического испытания и капитального ремонта',
          'Только после монтажа, профилактического испытания',
          'Только после монтажа',
        ],
        correctAnswers: [
          'После монтажа, профилактического испытания, текущего ремонта, капитального ремонта',
        ],
      },
      {
        code: '63692115',
        text:
          'Что из перечисленного должно быть указано в режимной карте по химическому контролю за водно-химическим режимом и эксплуатацией установок докотловой обработки воды?',
        answers: [
          'Все перечисленное',
          'Единицы измерения контролируемых показателей работы ВПУ и ВХР',
          'Периодичность выполнения химических анализов',
          'Погрешности применяемых методов определения с указанием правил округления результатов измерений',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63692116',
        text:
          'Кем устанавливается периодичность отбора проб исходной, химочищенной, котловой, сетевой, питательной и подпиточной воды, конденсата и пара?',
        answers: [
          'Организацией - изготовителем котла',
          'Наладочной организацией',
          'Эксплуатирующей организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Наладочной организацией'],
      },
      {
        code: '63692117',
        text:
          'Какое из приведенных испытаний не является обязательным видом механических испытаний котлов (трубопроводов)?',
        answers: [
          'Испытание на ударный изгиб',
          'Испытание на статический изгиб',
          'Испытание на статическое растяжение',
        ],
        correctAnswers: ['Испытание на ударный изгиб'],
      },
      {
        code: '63692118',
        text:
          'Какие инструкции не разрабатываются в организации, эксплуатирующей котлы?',
        answers: [
          'Инструкция для ответственного за осуществление производственного контроля',
          'Инструкция для ответственного за исправное состояние и безопасную эксплуатацию котлов',
          'Производственные инструкции для персонала, осуществляющего обслуживание и ремонт котлов, определяющие его обязанности, порядок безопасного производства работ и ответственность',
          'Инструкция (руководство) по эксплуатации котла',
        ],
        correctAnswers: ['Инструкция (руководство) по эксплуатации котла'],
      },
      {
        code: '63692119',
        text:
          'На какой период руководителем эксплуатирующей организации может быть принято решение о возможности эксплуатации котла в режиме опытного применения?',
        answers: [
          'Эксплуатация котла в режиме опытного применения не допускается',
          'Не более 1 года',
          'Не более 6 месяцев',
          'Период эксплуатации котла в режиме опытного применения устанавливается эксплуатирующей организацией с уведомлением об этом территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Не более 6 месяцев'],
      },
      {
        code: '63692120',
        text:
          'Чему равны минимальные значения расхода воздуха и времени вентилирования при вентиляции газоходов и топки котла перед его растопкой и после его остановки, если иные значения не определены организацией-изготовителем или наладочной организацией?',
        answers: [
          'Время вентилирования 5 минут, расход воздуха 50 %',
          'Время вентилирования 10 минут, расход воздуха 25 %',
          'Время вентилирования 10 минут, расход воздуха не регламентируется',
          'Время вентилирования 20 минут, расход воздуха 40 %',
        ],
        correctAnswers: ['Время вентилирования 10 минут, расход воздуха 25 %'],
      },
      {
        code: '63692121',
        text:
          'В какой документации указывается необходимость проведения и объем ультразвукового и радиографического контроля, типы и размеры несплошностей (дефектов), подлежащих обнаружению, для конкретного вида (типа) конструкции оборудования под давлением и сварного соединения?',
        answers: [
          'Руководстве (инструкции) по эксплуатации',
          'Проектной (конструкторской) и технологической документацией',
          'Паспорте оборудования',
        ],
        correctAnswers: [
          'Проектной (конструкторской) и технологической документацией',
        ],
      },
      {
        code: '63692122',
        text:
          'Каков минимальный срок проведения пусконаладочных работ на оборудовании, работающем под давлением?',
        answers: [
          'Не менее 30 дней',
          'Срок проведения пусконаладочных работ определяется эксплуатирующей организацией исходя из сроков ввода оборудования в эксплуатацию',
          'Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования',
        ],
        correctAnswers: [
          'Продолжительность проведения пусконаладочных работ определяется программой в зависимости от сложности оборудования',
        ],
      },
      {
        code: '63692123',
        text:
          'Какие из перечисленных мероприятий, предусматриваемых для защиты обслуживающего персонала от соприкосновения с элементами электрокотла, находящимися под напряжением, указаны неверно?',
        answers: [
          'Несгораемые перегородки (ограждения) - сетчатые с размером ячейки не более 25 x 25 мм',
          'Сплошные перегродки с остекленными проемами',
          'Перегородки (ограждения) высотой не менее 1 м',
          'Все перечисленное указано верно',
        ],
        correctAnswers: ['Перегородки (ограждения) высотой не менее 1 м'],
      },
      {
        code: '63692124',
        text:
          'Какое условие должно выполняться для обеспечения безопасности котлов, не оборудованных установками докотловой обработки воды?',
        answers: [
          'Повышенный расход непрерывной продувки котла, определяемый инструкцией по эксплуатации',
          'Проведение чисток котла с периодичностью при условии, чтобы толщина отложений на наиболее теплонапряженных участках к моменту остановки котла не превышала 1 мм',
          'Проведение периодических чисток котла в зависимости от показателей жесткости питательной и щелочности котловой воды',
          'Проведение чисток котла с периодичностью при условии, чтобы толщина отложений на наиболее теплонапряженных участках к моменту остановки котла не превышала 0,5 мм',
        ],
        correctAnswers: [
          'Проведение чисток котла с периодичностью при условии, чтобы толщина отложений на наиболее теплонапряженных участках к моменту остановки котла не превышала 0,5 мм',
        ],
      },
      {
        code: '63692125',
        text:
          'Какие системы трубопроводов должны быть предусмотрены проектом их размещения для безопасной эксплуатации котлов?',
        answers: [
          'Подвода питательной или сетевой воды',
          'Продувки котла и спуска воды при остановке котла',
          'Отбора проб воды и пара',
          'Подвода топлива к горелочным устройствам котла',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63692126',
        text:
          'Чем должны оснащаться указатели уровня жидкости на оборудовании, давление которого превышает 4,5 МПа?',
        answers: [
          'Двумя дублирующими указателями уровня жидкости непрямого действия',
          'Двумя дополнительными сниженными дистанционными указателями уровня жидкости',
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
          'Дополнительными звуковыми, световыми и другими сигнализаторами и блокировками по предельным уровням жидкости',
        ],
        correctAnswers: [
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
        ],
      },
      {
        code: '63692127',
        text:
          'В каком случае техническое освидетельствование котла проводится ответственным за осуществление производственного контроля за безопасной эксплуатацией оборудования и ответственным за исправное состояние и безопасную эксплуатацию котла?',
        answers: [
          'Техническое освидетельствование котла (первичное, периодическое и внеочередное) проводит только уполномоченная специализированная организация',
          'Только технические освидетельствования (первичное, периодическое и внеочередное) котла, не подлежащего учету в территориальном органе Ростехнадзора',
          'Только периодическое техническое освидетельствование котла, первичное и внеочередное техническое освидетельствование котла проводится специализированной уполномоченной организацией',
          'Только первичное техническое освидетельствование котлов, прибывших на место установки в собранном виде, которые подвергались осмотру и испытаниям организацией-изготовителем',
        ],
        correctAnswers: [
          'Только первичное техническое освидетельствование котлов, прибывших на место установки в собранном виде, которые подвергались осмотру и испытаниям организацией-изготовителем',
        ],
      },
      {
        code: '63692128',
        text:
          'При каком условии допускается включение котлов в одну группу по питанию?',
        answers: [
          'Если разница рабочих давлений в разных котлах не превышает 15 %',
          'Если разница рабочих давлений в разных котлах не превышает 25 %',
          'Если разница рабочих давлений в разных котлах не превышает 40 %',
          'Если разница рабочих давлений в разных котлах не превышает 50 %',
        ],
        correctAnswers: [
          'Если разница рабочих давлений в разных котлах не превышает 15 %',
        ],
      },
      {
        code: '63692129',
        text:
          'Какое из требований к оборудованию указателем уровня жидкости котла, работающего с высокотемпературными органическими и неорганическими теплоносителями, указано неверно?',
        answers: [
          'Элементы указателя уровня, соприкасающиеся с теплоносителем, в особенности его прозрачный элемент, должны быть выполнены из негорючих материалов, устойчивых против воздействия на них теплоносителя при рабочих температуре и давлении',
          'В указателях уровня жидкости прямого действия внутренний диаметр арматуры, служащей для отключения указателя уровня от котла, должен быть не менее 8 мм',
          'Установка пробных кранов или клапанов взамен указателей уровня жидкости допускается только в паровом котле',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Установка пробных кранов или клапанов взамен указателей уровня жидкости допускается только в паровом котле',
        ],
      },
      {
        code: '63692130',
        text:
          'Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?',
        answers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
          'Проектной (конструкторской) и технической документации оборудования под давлением, монтаж, ремонт, реконструкция (модернизация) которого осуществляется',
          'Нормативных документов, необходимость применения которых для обеспечения требований промышленной безопасности, установленных законодательством в области промышленной безопасности при выполнении соответствующих работ установлена специализированной организацией в виде утвержденного перечня или иного распорядительного документа',
          'Технологической документации на производство заявленных видов работ, разработанной до начала этих работ',
        ],
        correctAnswers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
        ],
      },
      {
        code: '63692131',
        text:
          'Какой максимальный срок действия декларации о соответствии серийно выпускаемого оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" со дня ее регистрации в Едином реестре выданных сертификатов соответствия и зарегистрированных деклараций о соответствии?',
        answers: ['5 лет', '4 года', '3 года', '1 год'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63692132',
        text:
          'Что из приведенного не указывается на табличке или не наносится на котле перед пуском его в работу?',
        answers: [
          'Номер котла по системе, принятой в эксплуатирующей организации',
          'Разрешенные давление',
          'Регистрационный номер и даты проведенных осмотров и гидравлических испытаний',
          'Дата следующего осмотра и гидравлического испытания',
        ],
        correctAnswers: [
          'Регистрационный номер и даты проведенных осмотров и гидравлических испытаний',
        ],
      },
      {
        code: '63692133',
        text:
          'Какой маркировке подлежит оборудование, соответствие которого не подтверждено требованиям технического регламента "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Брак',
          'Неликвид',
          'Ex',
          'HE EAC',
          'Оборудование не подлежит маркировке',
        ],
        correctAnswers: ['Оборудование не подлежит маркировке'],
      },
      {
        code: '63692134',
        text:
          'Каково минимальное значение номинального диаметра корпуса манометра, установленного на высоте от 2 до 5 м от уровня площадки наблюдения?',
        answers: ['Не нормируется', '100 мм', '160 мм', '250 мм'],
        correctAnswers: ['160 мм'],
      },
      {
        code: '63692135',
        text:
          'В каком случае проводится внеочередное определение удельного сопротивления воды при эксплуатации электрокотлов?',
        answers: [
          'При резком изменении мощности котлов на 20 % и более от нормальной',
          'Внеочередное определение удельного сопротивления воды при эксплуатации электрокотлов проводится при внеочередной чистке котла',
          'Внеочередное определение удельного сопротивления воды при эксплуатации электрокотлов ФНП ОРПД не предусмотрено',
        ],
        correctAnswers: [
          'При резком изменении мощности котлов на 20 % и более от нормальной',
        ],
      },
      {
        code: '63692136',
        text:
          'В каких перечисленных местах должны предусматриваться средства измерения величины температуры питательной воды?',
        answers: [
          'На входе воды в экономайзер',
          'На выходе воды из экономайзера',
          'На трубопроводах питательной воды паровых котлов без экономайзеров',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63692137',
        text:
          'Каково минимально допустимое значение расстояния от фронта котла, работающего на газообразном топливе, до противоположной стены котельного помещения?',
        answers: ['3 м', '2 м', '1 м', 'Не регламентируется'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63692138',
        text:
          'Каково минимально допустимое значение расстояния от выступающих частей горелочных устройств котла, работающего на жидком топливе, до стены котельного помещения?',
        answers: ['3 м', '2 м', '1 м', 'Не регламентируется'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63692139',
        text:
          'В какие сроки проводится проверка исправности действия манометров, предохранительных клапанов, указателей уровня воды и питательных насосов при эксплуатации котлов с рабочим давлением до 1,4 МПа?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в сутки',
          'Сроки устанавливаются эксплуатирующей организацией и указываются в графике проверки, который утверждается техническим руководителем эксплуатирующей организации',
          'Проверка манометров и предохранительных клапанов проводится не реже одного раза в смену; проверка указателей уровня воды – не реже одного раза в сутки; проверка резервных питательных насосов – перед их пуском',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63692140',
        text:
          'Какой из перечисленных терминов соответствует определению "устройство, в котором в качестве источника тепла используются горючие газы или другие технологические потоки"?',
        answers: [
          'Котел-утилизатор',
          'Котел энерготехнологический',
          'Котел электродный',
          'Котел с электрообогревом',
          'Котел водогрейный',
        ],
        correctAnswers: ['Котел-утилизатор'],
      },
      {
        code: '63692141',
        text:
          'Когда должно проводиться измерение сопротивления столба воды изолирующей вставки в соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов?',
        answers: [
          'После монтажа, профилактического испытания, текущего ремонта, капитального ремонта',
          'Только после профилактического испытания и капитального ремонта',
          'Только после монтажа, профилактического испытания',
          'Только после монтажа',
        ],
        correctAnswers: [
          'После монтажа, профилактического испытания, текущего ремонта, капитального ремонта',
        ],
      },
      {
        code: '63692142',
        text:
          'Что из приведенного не входит в должностные обязанности ответственного за исправное состояние и безопасную эксплуатацию котлов?',
        answers: [
          'Контроль своевременности и полноты проведения ремонта котлов',
          'Осмотр котлов с определенной должностной инструкцией периодичностью',
          'Проведение противоаварийных тренировок с обслуживающим персоналом',
          'Подготовка котла к техническому освидетельствованию',
        ],
        correctAnswers: [
          'Контроль своевременности и полноты проведения ремонта котлов',
        ],
      },
      {
        code: '63692143',
        text:
          'Каково минимально допустимое значение расстояния от противоположной стены котельной до фронта электрокотла мощностью 0,75 МВт?',
        answers: ['3 м', '2 м', '1 м', 'Устанавливается проектом'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63692144',
        text:
          'Что может применяться для обеспечения доступа к площадкам обслуживания оборудования под давлением, предназначенным для периодического проведения работ (плановое техническое обслуживание, ремонт) в местах расположения люков, арматуры и иных устройств, оборудованных автоматическим дистанционным приводом, не требующих постоянного (неоднократного) присутствия персонала?',
        answers: [
          'Переносные площадки',
          'Передвижные лестницы',
          'Стационарные лестницы с углом наклона к горизонтали более 50°',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63692145',
        text:
          'Какое из приведенных требований должно выполняться в случае обнаружения дефектов при ультразвуковом и радиографическом контроле сварных соединений?',
        answers: [
          'Ремонтные заварки выборок металла должны быть проверены методом магнитопорошковой или капиллярной дефектоскопии по всему участку заварки, за исключением зоны термического влияния сварки, которая контролируется ультразвуковой дефектоскопией или радиографическим контролем',
          'При заварке по всей толщине стенки ультразвуковая дефектоскопия или радиографический контроль поверхности должен быть проведен с обеих сторон',
          'Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений',
        ],
        correctAnswers: [
          'Стыковые сварные соединения, которые были подвергнуты устранению дефекта сварного шва, должны быть проверены ультразвуковой дефектоскопией или радиографическим контролем по всей длине сварных соединений',
        ],
      },
      {
        code: '63692146',
        text:
          'Каким образом должна выполняться продувка верхних водоуказательных приборов при контроле уровня воды в барабане, осуществляемом с момента начала растопки котла с рабочим давлением 4,0 МПа и менее?',
        answers: [
          'Продувка должна выполняться при избыточном давлении в котле 0,1 МПа и перед включением котла в главный паропровод',
          'Продувка должна выполняться непрерывно до включения котла в главный паропровод',
          'Продувка должна выполняться при избыточном давлении в котле 0,3 МПа и за 15 минут до включения котла в главный паропровод',
          'Продувка должна выполняться при избыточном давлении в котле 0,1 МПа и при избыточном давлении 3,0 МПа',
        ],
        correctAnswers: [
          'Продувка должна выполняться при избыточном давлении в котле 0,1 МПа и перед включением котла в главный паропровод',
        ],
      },
      {
        code: '63692147',
        text:
          'Какая должна быть минимальная ширина прохода между электрокотлами и задней стеной котельного помещения?',
        answers: ['1 м', '2 м', '1,5 м', '0,7 м'],
        correctAnswers: ['1 м'],
      },
      {
        code: '63692148',
        text:
          'С какой периодичностью проводится проверка знаний рабочих, обслуживающих котлы?',
        answers: [
          'Периодичность устанавливается эксплуатирующей организацией',
          'Один раз в 12 месяцев',
          'Один раз в 4 месяца',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63692149',
        text:
          'При каком количестве общего выхода шлака и золы от всех котлов (независимо от их производительности) должно быть механизировано удаление золы и шлака?',
        answers: [
          '150 кг/ч и более',
          '100 кг/ч и более',
          '75 кг/ч и более',
          'Удаление золы и шлака должно быть механизировано во всех котлах',
        ],
        correctAnswers: ['150 кг/ч и более'],
      },
      {
        code: '63692150',
        text:
          'Когда должно проводиться измерение удельного электрического сопротивления питательной (сетевой) воды в соответствии с нормами проведения электрических испытаний электрооборудования электрических котлов?',
        answers: [
          'После монтажа, профилактического испытания, текущего ремонта, капитального ремонта',
          'Только после профилактического испытания и капитального ремонта',
          'Только после профилактического испытания',
          'Только после монтажа',
        ],
        correctAnswers: [
          'Только после профилактического испытания и капитального ремонта',
        ],
      },
      {
        code: '63692151',
        text:
          'На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На техническое перевооружение опасного производственного объекта, на котором используются водогрейные котлы',
          'На размещение паровых котлов в здании котельного помещения опасного производственного объекта',
          'На разработку (проектирование) прямоточного котла',
        ],
        correctAnswers: ['На разработку (проектирование) прямоточного котла'],
      },
      {
        code: '63692152',
        text:
          'В каком из приведенных случаев допускается подпитывать остановленный котел с дренированием воды в целях ускорения охлаждения барабана?',
        answers: [
          'При температуре стенки барабана котла не более 180°С',
          'Допускается только для барабанных котлов с рабочим давлением до 1,6 МПа',
          'Подпитка котла в данных целях проводится в случаях, предусмотренных инструкцией по эксплуатации котла',
          'Подпитка котла в данных целях не допускается',
        ],
        correctAnswers: ['Подпитка котла в данных целях не допускается'],
      },
      {
        code: '63692153',
        text:
          'Каким документом определяется порядок действий в случае инцидента при эксплуатации котла?',
        answers: [
          'Производственная инструкция, утвержденная эксплуатирующей организацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Инструкция (руководство) по эксплуатации организации-изготовителя котла',
        ],
        correctAnswers: [
          'Производственная инструкция, утвержденная эксплуатирующей организацией',
        ],
      },
      {
        code: '63692154',
        text:
          'В каком из приведенных случаев при эксплуатации электрокотлов не допускается снижение удельного электрического сопротивления воды путем введения легкорастворимых солей в питательную и котловую воду?',
        answers: [
          'Для водогрейных котлов напряжением до 1 кВ, работающих по замкнутой схеме теплоснабжения (без водозабора)',
          'Для паровых котлов при их эксплуатации',
          'Для паровых котлов при их запуске для форсирования набора и поддержания мощности',
        ],
        correctAnswers: ['Для паровых котлов при их эксплуатации'],
      },
      {
        code: '63692155',
        text:
          'Каким документом определяется ответственность за безопасность обслуживания оборудования под давлением в период проведения наладочных работ?',
        answers: [
          'Программой наладочных работ',
          'Договором на оказание услуг по проведению наладочных работ, заключаемым эксплуатирующей и наладочной организациями',
          'Совместным приказом руководителей эксплуатирующей и специализированной пусконаладочной организаций',
        ],
        correctAnswers: ['Программой наладочных работ'],
      },
      {
        code: '63692156',
        text: 'Какое из приведенных требований к электрокотлу указано неверно?',
        answers: [
          'На котлах мощностью более 6 МВт обязательна установка регистрирующего манометра',
          'В качестве предохранительных устройств при эксплуатации электрических котлов категорически не допускается применение предохранительных устройств с разрушающимися мембранами',
          'В котельных с водогрейными котлами суммарной мощностью более 1 МВт прибор для измерения температуры среды должен быть регистрирующим',
          'Электрокотельные с электрическими котлами должны быть оснащены средствами определения удельного электросопротивления питательной (сетевой) воды',
        ],
        correctAnswers: [
          'В качестве предохранительных устройств при эксплуатации электрических котлов категорически не допускается применение предохранительных устройств с разрушающимися мембранами',
        ],
      },
      {
        code: '63692157',
        text:
          'В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?',
        answers: [
          'Отклонения от проектной документации не допускаются',
          'В технически обоснованных случаях отклонение от проектной документации согласуется с ее разработчиком или со специализированной экспертной организацией',
          'Отклонения от проектной документации оформляются в виде специальных технических условий, подлежащих утверждению в установленном порядке',
        ],
        correctAnswers: ['Отклонения от проектной документации не допускаются'],
      },
      {
        code: '63692158',
        text:
          'Какое из приведенных требований к проверке знаний рабочих, обслуживающих котлы, указано неверно?',
        answers: [
          'Результаты проверки знаний рабочих оформляют протоколом с отметкой в удостоверении о допуске к самостоятельной работе',
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих котлы',
          'Внеочередная проверка знаний проводится при переходе рабочего в другую организацию',
          'В случае перевода рабочих на обслуживание котлов другого типа, а также при переводе обслуживаемого ими котла на сжигание другого вида топлива должна быть проведена внеочередная проверка знаний',
        ],
        correctAnswers: [
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих котлы',
        ],
      },
      {
        code: '63692159',
        text:
          'В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, в каком из приведенных случаев котел не подлежит аварийной остановке?',
        answers: [
          'В случае неисправности предохранительного клапана',
          'В случае погасания факелов в топке при камерном сжигании топлива',
          'В случае повышения давления в барабане котла выше разрешенного на 10 % при полностью открытых предохранительных клапанах',
          'В случае исчезновения напряжения в устройствах автоматики безопасности или аварийной сигнализации',
        ],
        correctAnswers: [
          'В случае повышения давления в барабане котла выше разрешенного на 10 % при полностью открытых предохранительных клапанах',
        ],
      },
      {
        code: '63692160',
        text:
          'В каком случае запрещается объединять сбросы от предохранительных клапанов сосудов?',
        answers: [
          'Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
          'Если сбросы направляются в открытые системы для дальнейшей утилизации или в системы организованного сжигания',
          'Если, по крайней мере, один из сосудов работает под давлением среды, отнесенной к группе 1',
        ],
        correctAnswers: [
          'Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
        ],
      },
      {
        code: '63692161',
        text:
          'На какой срок должны составляться режимные карты по ведению водно-химического режима?',
        answers: ['На три года', 'На год', 'На два года', 'На пять лет'],
        correctAnswers: ['На три года'],
      },
      {
        code: '63692162',
        text:
          'В каком случае допускается растопка прямоточных котлов на скользящем давлении?',
        answers: [
          'Допускается только для котлов с рабочим давлением 14 МПа',
          'Допускается только для прямоточных котлов на сверхкритическое давление на основе специальных испытаний',
          'Допускается по согласованию с организацией-изготовителем на основе специальных испытаний',
          'Категорически не допускается',
        ],
        correctAnswers: [
          'Допускается по согласованию с организацией-изготовителем на основе специальных испытаний',
        ],
      },
      {
        code: '63692163',
        text:
          'Каково нормируемое максимальное значение присосов воздуха в топку водогрейного газомазутного котла?',
        answers: [
          'Не более 8 % от теоретически необходимого количества воздуха для номинальной нагрузки котла',
          'Не более 5 % от теоретически необходимого количества воздуха для номинальной нагрузки котла',
          'Не более 25 % от теоретически необходимого количества воздуха для номинальной нагрузки котла',
          'Топки водогрейных котлов должны быть бесприсосными',
        ],
        correctAnswers: [
          'Не более 5 % от теоретически необходимого количества воздуха для номинальной нагрузки котла',
        ],
      },
      {
        code: '63692164',
        text:
          'Какие из приведенных мест не подлежат оборудованию аварийным освещением?',
        answers: [
          'Щиты и пульты управления',
          'Вентиляторные площадки',
          'Места установки насосного оборудования',
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
        correctAnswers: [
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
      },
      {
        code: '63692165',
        text:
          'Какие из перечисленных действий не допускаются перед началом сварки деталей оборудования, работающего под давлением? Выберите 2 варианта ответа.',
        answers: [
          'Проверка качества сборки соединяемых элементов',
          'Проверка состояния стыкуемых кромок и прилегающих к ним поверхностей',
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
          'Подготовка кромок и поверхностей механической обработкой',
        ],
        correctAnswers: [
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
        ],
      },
      {
        code: '63692166',
        text:
          'Установка каких котлов не допускается внутри производственных помещений?',
        answers: [
          'Прямоточных котлов паропроизводительностью каждого не более 4 тонн пара в час (т/ч)',
          'Водогрейных котлов теплопроизводительностью каждого не более 10,5 ГДж/ч (2,5 Гкал/ч), не имеющих барабанов',
          'Водогрейных электрокотлов при электрической мощности каждого более 5 МВт',
          'Котлов-утилизаторов',
          'Допускается установка всех перечисленных котлов',
        ],
        correctAnswers: [
          'Водогрейных электрокотлов при электрической мощности каждого более 5 МВт',
        ],
      },
    ],
    63696: [
      {
        code: '63696000',
        text:
          'С применением каких материалов должны ввертываться запорные клапаны в баллонах для кислорода?',
        answers: [
          'Уплотняющих материалов, рассчитанных на давление от 8 до 24 МПа, не имеющих следов загрязнений, масла, коррозии, дополнительно обезжиренных специальным составом',
          'Уплотняющих материалов, сертифицированных в соответствии с техническим регламентом Таможенного союза "О безопасности оборудования для работы во взрывоопасных средах"',
          'Уплотняющих материалов, возгорание которых в среде кислорода исключено',
        ],
        correctAnswers: [
          'Уплотняющих материалов, возгорание которых в среде кислорода исключено',
        ],
      },
      {
        code: '63696001',
        text:
          'Где может быть проверено состояние пористой массы в баллонах для растворенного ацетилена?',
        answers: [
          'На любой наполнительной станции',
          'На ацетиленовой наполнительной станции',
          'Только в организации, занимающейся изготовлением таких баллонов',
          'В любой специализированной организации, имеющей испытательный пункт',
        ],
        correctAnswers: ['На ацетиленовой наполнительной станции'],
      },
      {
        code: '63696002',
        text:
          'Какие данные выбиваются на ацетиленовых баллонах при удовлетворительном состоянии пористой массы?',
        answers: [
          'Год и месяц проверки пористой массы; индивидуальное клеймо наполнительной станции; клеймо диаметром 12 мм с изображением букв "Пм", удостоверяющее проверку пористой массы',
          'Год проверки пористой массы; индивидуальное клеймо наполнительной станции; клеймо диаметром 12 мм с изображением букв "МП", удостоверяющее проверку пористой массы',
          'Только год и месяц проверки пористой массы; клеймо диаметром 16 мм с изображением букв "Пм", удостоверяющее проверку пористой массы',
        ],
        correctAnswers: [
          'Год и месяц проверки пористой массы; индивидуальное клеймо наполнительной станции; клеймо диаметром 12 мм с изображением букв "Пм", удостоверяющее проверку пористой массы',
        ],
      },
      {
        code: '63696003',
        text:
          'Под каким давлением азота проводится испытание баллона для ацетилена, наполненного пористой массой?',
        answers: [
          '2,5 МПа',
          '3,5 МПа',
          '2,0 МПа',
          'Устанавливается организацией-изготовителем',
        ],
        correctAnswers: ['3,5 МПа'],
      },
      {
        code: '63696004',
        text:
          'В каком случае допускается расположение групповых баллонных установок и хранение баллонов с горючими газами в помещении, где осуществляется технологический процесс использования находящегося в них горючего газа?',
        answers: [
          'Не допускается ни в каком случае',
          'Если размещаются на расстоянии не менее 1 м от радиаторов отопления и других отопительных приборов, печей и не менее 5 м от источников тепла с открытым огнем',
          'При согласовании с техническим руководителем организации',
          'При согласовании с проектной организацией',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63696005',
        text:
          'Какие действия должны выполняться ответственными лицами перед каждой заправкой баллонов, подлежащих учету в территориальных органах Ростехнадзора или иных органах исполнительной власти, уполномоченных в области промышленной безопасности, установленных стационарно, а также установленных постоянно на передвижных средствах, в которых хранятся сжатый воздух, кислород, азот, аргон и гелий с температурой точки росы минус 35°С и ниже, замеренной при давлении 15 МПа (150 кгс/см ) и выше, а также баллонов с обезвоженной углекислотой?',
        answers: [
          'Наружный осмотр',
          'Наружный и внутренний осмотр',
          'Гидравлическое испытание пробным давлением',
          'Все перечисленное',
        ],
        correctAnswers: ['Наружный осмотр'],
      },
      {
        code: '63696006',
        text:
          'Какие виды контроля качества сварных соединений и материалов должны предшествовать контролю другими методами? Выберите 2 варианта ответа.',
        answers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
          'Проникающими веществами',
          'Магнитопорошковый контроль',
          'Вихретоковый контроль.',
        ],
        correctAnswers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
        ],
      },
      {
        code: '63696007',
        text:
          'Каким документом устанавливаются дополнительные требования безопасности при эксплуатации, наполнении, хранении и транспортировании баллонов, изготовленных из металлокомпозитных и композитных материалов?',
        answers: [
          'Руководством (инструкцией) по эксплуатации',
          'Соответствующими государственными стандартами и сводами правил',
          'Для данных баллонов дополнительные требования безопасности не устанавливаются',
          'Техническим регламентом Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"',
        ],
        correctAnswers: ['Руководством (инструкцией) по эксплуатации'],
      },
      {
        code: '63696008',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см²? Где в формулах: Рраб – рабочее давление сосуда, Р расч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,3 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,3 Рраcч ([σ]20 / [σ]t)',
          'Рпр = 1,6 Рраб',
          'Рпр = 1,6 Рраб ([σ]20 / [σ]t)',
        ],
        correctAnswers: ['Рпр = 1,3 Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63696009',
        text: 'Какие требования к окраске надписей и баллонов указаны неверно?',
        answers: [
          'Окраску баллонов и нанесение надписей при эксплуатации производят организации-изготовители, наполнительные станции (пункты наполнения) или испытательные пункты (пункты проверки) в соответствии с требованиями ТР ТС 032/2013',
          'Требования к окраске и надписям, не установленные ТР ТС 032/2013, устанавливаются проектной документацией и (или) техническими условиями на продукцию, для хранения которой предназначены эти баллоны, и указываются в распорядительных документах',
          'Стационарно установленные баллоны вместимостью более 100 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации',
          'Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации',
        ],
        correctAnswers: [
          'Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации',
        ],
      },
      {
        code: '63696010',
        text:
          'Какие данные не указываются наполнительной станцией, производящей наполнение баллонов сжатыми, сжиженными и растворимыми газами, в журнале наполнения баллонов?',
        answers: [
          'Дата наполнения и номер баллона',
          'Эксплуатирующая организация',
          'Дата освидетельствования',
          'Масса газа (сжиженного) в баллоне, кг',
          'Подпись, фамилия и инициалы лица, наполнившего баллон',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63696011',
        text:
          'Какой должна быть чистота азота, применяемого для испытания баллонов для растворенного ацетилена?',
        answers: [
          'Не ниже 90 % по объему',
          'Не ниже 95 % по объему',
          'Не ниже 97 % по объему',
          'Устанавливается организацией-изготовителем',
        ],
        correctAnswers: ['Не ниже 97 % по объему'],
      },
      {
        code: '63696012',
        text:
          'С какой периодичностью должно проверяться на ацетиленовых наполнительных станциях состояние пористой массы в баллонах для растворенного ацетилена?',
        answers: [
          'Не реже чем через 3 года',
          'Не реже чем через 5 лет',
          'Не реже чем через 24 месяца',
          'Не реже чем через 18 месяцев',
        ],
        correctAnswers: ['Не реже чем через 24 месяца'],
      },
      {
        code: '63696013',
        text:
          'Какую резьбу должны иметь боковые штуцера вентилей для баллонов, наполняемых водородом и кислородом?',
        answers: [
          'Для баллонов, наполняемых водородом, - правую, для баллонов, наполняемых кислородом, - левую',
          'Для баллонов, наполняемых водородом, - левую, для баллонов, наполняемых кислородом, - правую',
          'Для всех баллонов, независимо от наполняемого газа, боковые штуцера вентилей должны иметь левую резьбу',
          'Для всех баллонов, независимо от наполняемого газа, боковые штуцера вентилей должны иметь правую резьбу',
        ],
        correctAnswers: [
          'Для баллонов, наполняемых водородом, - левую, для баллонов, наполняемых кислородом, - правую',
        ],
      },
      {
        code: '63696014',
        text:
          'Кем должны быть разработаны и утверждены документы при отсутствии в комплекте технической документации, прилагаемой организацией-изготовителем к оборудованию под давлением, документов (в виде разделов паспорта либо отдельных формуляров, журналов), обеспечивающих возможность внесения информации об истории эксплуатации оборудования под давлением?',
        answers: [
          'Эксплуатирующей организацией',
          'Научно-исследовательской организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63696015',
        text:
          'Допускается ли эксплуатация баллонов вместимостью менее 50 литров за пределами назначенного срока службы?',
        answers: [
          'Допускается на основании результатов экспертизы промышленной безопасности',
          'Не допускается',
          'Допускается, но только для баллонов специального назначения, конструкция которых определена индивидуальным проектом и не отвечает типовым конструкциям баллонов и экспертизу (техническое диагностирование) которых проводят по истечении срока службы, а также в случаях, установленных руководством (инструкцией) по эксплуатации оборудования, в составе которого они используются',
          'Допускается по результатам экспертизы промышленной безопасности, за исключением баллонов специального назначения, конструкция которых определена индивидуальным проектом и не отвечает типовым конструкциям баллонов и экспертизу (техническое диагностирование) которых не проводят по истечении срока службы',
        ],
        correctAnswers: [
          'Допускается, но только для баллонов специального назначения, конструкция которых определена индивидуальным проектом и не отвечает типовым конструкциям баллонов и экспертизу (техническое диагностирование) которых проводят по истечении срока службы, а также в случаях, установленных руководством (инструкцией) по эксплуатации оборудования, в составе которого они используются',
        ],
      },
      {
        code: '63696016',
        text:
          'Кем устанавливаются дополнительные требования при эксплуатации, наполнении, хранении и транспортировании баллонов, изготовленных из металлокомпозитных и композитных материалов? Выберите 2 правильных ответа.',
        answers: [
          'Организацией - изготовителем баллона',
          'Разработчиком проекта',
          'Газонаполнительной станцией',
          'Эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Организацией - изготовителем баллона',
          'Разработчиком проекта',
        ],
      },
      {
        code: '63696017',
        text:
          'Какое подключаемое оборудование, трубопроводы и (или) гибкие рукава используются для подключения баллонов с горючими газами и кислородом с целью недопущения возгорания и взрыва?',
        answers: [
          'Взрывозащищенное оборудование, прошедшее оценку соответствия',
          'Оборудование, изготовленное из материала, отношение временного сопротивления к пределу текучести которого более 2, выдерживающее давление 1,25 от рабочего',
          'Соответствующее по материалам и прочности используемому газу в подключаемых баллонах',
          'Оборудование, соответствующее всему перечисленному',
        ],
        correctAnswers: [
          'Соответствующее по материалам и прочности используемому газу в подключаемых баллонах',
        ],
      },
      {
        code: '63696018',
        text:
          'Что включает в себя масса баллона для ацетилена, указываемая на его верхней сферической части?',
        answers: [
          'Указывается только масса пустого баллона',
          'Масса баллона без колпака, но с пористой массой и растворителем, башмаком, кольцом и вентилем',
          'Масса баллона с пористой массой и растворителем, башмаком, кольцом, но без учета массы вентиля и колпака',
        ],
        correctAnswers: [
          'Масса баллона без колпака, но с пористой массой и растворителем, башмаком, кольцом и вентилем',
        ],
      },
      {
        code: '63696019',
        text:
          'В каком случае при отсутствии указаний организации-изготовителя на браковку стальные бесшовные стандартные баллоны вместимостью от 12 до 55 литров бракуют и изымают из эксплуатации?',
        answers: [
          'Только при уменьшении массы на 7,5 % и выше',
          'При уменьшении массы на 7,5 % и выше, а также при увеличении их вместимости более чем на 1 %',
          'Только при увеличении их вместимости более чем на 5 %',
        ],
        correctAnswers: [
          'При уменьшении массы на 7,5 % и выше, а также при увеличении их вместимости более чем на 1 %',
        ],
      },
      {
        code: '63696020',
        text:
          'Какие из перечисленных сведений для работников, занятых эксплуатацией оборудования под давлением, не должны быть указаны в инструкциях, устанавливающих действия работников в аварийных ситуациях (в том числе при аварии)?',
        answers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
          'Места отключения вводов электропитания',
          'Места расположения аптечек первой помощи',
          'Способы и методы ликвидации аварий',
        ],
        correctAnswers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
        ],
      },
      {
        code: '63696021',
        text:
          'Каким образом должны быть подготовлены баллоны, предназначенные для сред, отнесенных к группе 1 в соответствии с ТР ТС 032/2013, к проведению осмотра?',
        answers: [
          'Должны быть очищены и промыты водой',
          'Должны быть промыты соответствующим растворителем или дегазированы',
          'Должны быть продуты воздухом',
        ],
        correctAnswers: [
          'Должны быть промыты соответствующим растворителем или дегазированы',
        ],
      },
      {
        code: '63696022',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, находящихся в эксплуатации для наполнения газами, вызывающими разрушение и физико-химическое превращение материала со скоростью не более 0,1 мм/год?',
        answers: [
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 5 лет'],
      },
      {
        code: '63696023',
        text:
          'В какой из приведенных форм не проводится оценка соответствия оборудования, работающего под давлением, на которое распространяется действие ТР ТС 032/2013?',
        answers: [
          'Экспертизы промышленной безопасности',
          'Сертификации',
          'Декларирования соответствия',
          'Государственного контроля (надзора)',
        ],
        correctAnswers: ['Экспертизы промышленной безопасности'],
      },
      {
        code: '63696024',
        text:
          'В каком количестве наполненные газом баллоны, находящиеся на длительном складском хранении, при наступлении очередных сроков периодического освидетельствования подвергают освидетельствованию в выборочном порядке из партии до 500 баллонов?',
        answers: [
          'В количестве не менее 5 штук',
          'В количестве не менее 10 штук',
          'В количестве не менее 15 штук',
          'В количестве не менее 20 штук',
        ],
        correctAnswers: ['В количестве не менее 10 штук'],
      },
      {
        code: '63696025',
        text:
          'В каком количестве при неудовлетворительных результатах первого периодического освидетельствования наполненные газом баллоны, находящиеся на длительном складском хранении, подвергают повторному освидетельствованию?',
        answers: [
          'В таком же количестве, что и при первом освидетельствовании',
          'Количество выборочно отобранных баллонов увеличивается в 2 раза',
          'Повторное освидетельствование не проводится, дальнейшее хранение баллонов не допускается',
        ],
        correctAnswers: [
          'В таком же количестве, что и при первом освидетельствовании',
        ],
      },
      {
        code: '63696026',
        text:
          'Какое из приведенных требований при подаче газа из баллонов в сосуд, который работает с меньшим давлением, указано неверно?',
        answers: [
          'На камере низкого давления редуктора должен быть установлен пружинный предохранительный клапан, отрегулированный на соответствующее разрешенное давление в сосуде, а также соответствующий данному давлению манометр',
          'На входе в редуктор должен быть установлен манометр со шкалой, обеспечивающей возможность измерения максимального рабочего давления в баллоне',
          'Подача газов должна производиться через редуктор, предназначенный для данного газа и окрашенный в соответствующий цвет',
          'Все приведенные требования верны',
        ],
        correctAnswers: ['Все приведенные требования верны'],
      },
      {
        code: '63696027',
        text:
          'Какие сведения не указывает в своем заявлении о присвоении шифра клейма организация, планирующая осуществление освидетельствования баллонов?',
        answers: [
          'Сведения об организационно-технической готовности к данному виду деятельности в соответствии с требованиями ФНП "Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением"',
          'Характеристики баллонов, освидетельствование которых готова осуществлять организация (тип или марка баллонов, вместимость баллонов, наименование и назначение газов, для которых они предназначены)',
          'Перечень лиц, непосредственно участвующих в освидетельствовании баллонов',
          'Указываются все перечисленные сведения',
        ],
        correctAnswers: [
          'Перечень лиц, непосредственно участвующих в освидетельствовании баллонов',
        ],
      },
      {
        code: '63696028',
        text:
          'Чем должно быть обеспечено соответствие выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования с применением сварки и термической обработки требованиям технологической документации?',
        answers: [
          'Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)',
          'Декларированием специализированной организацией политики качества, обеспечивающей выполнение работ в соответствии с Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, и технологической документацией',
          'Разработанной в специализированной организации в соответствии с международными стандартами ISO системой качества',
          'Системой подтверждения соответствия выполняемых работ требованиям технологической документации и Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, разработанной и утвержденной специализированной организацией',
        ],
        correctAnswers: [
          'Установленной распорядительными документами специализированной организации системой контроля качества (входной, операционный, приемочный)',
        ],
      },
      {
        code: '63696029',
        text:
          'На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На разработку (проектирование) прямоточного котла',
          'На размещение паровых котлов в здании котельного помещения опасного производственного объекта',
          'На техническое перевооружение опасного производственного объекта, на котором используются водогрейные котлы',
        ],
        correctAnswers: ['На разработку (проектирование) прямоточного котла'],
      },
      {
        code: '63696030',
        text:
          'В каком случае из указанных допускается наполнение баллонов газом?',
        answers: [
          'Если поврежден корпус баллона',
          'Если до истечения срока технического освидетельствования остается 1 месяц',
          'Если отсутствует избыточное давление газа',
          'Если отсутствуют установленные клейма',
        ],
        correctAnswers: [
          'Если до истечения срока технического освидетельствования остается 1 месяц',
        ],
      },
      {
        code: '63696031',
        text:
          'Кем устанавливаются требования к освидетельствованию, браковке и маркировке баллонов, изготовленных из металлокомпозитных и композитных материалов?',
        answers: [
          'Организацией, осуществляющей освидетельствование баллонов, или испытательным пунктом (пунктом проверки)',
          'Наполнительной станцией',
          'Разработчиком проекта и (или) изготовителем баллона',
        ],
        correctAnswers: ['Разработчиком проекта и (или) изготовителем баллона'],
      },
      {
        code: '63696032',
        text:
          'Какие сведения из указанных не заносятся в журнал испытаний по результатам освидетельствования баллонов для растворенного ацетилена?',
        answers: [
          'Номер баллона и товарный знак организации-изготовителя',
          'Эксплуатирующая организация',
          'Дата (месяц и год) изготовления баллона',
          'Фамилия, инициалы и подпись представителя организации (индивидуального предпринимателя), проводившей освидетельствование',
          'Дата проведенного и следующего освидетельствования баллона',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63696033',
        text:
          'Какой должна быть максимальная масса пропана на 1 л вместимости баллона?',
        answers: ['0,716 кг', '0,425 кг', '0,570 кг', '0,800 кг'],
        correctAnswers: ['0,425 кг'],
      },
      {
        code: '63696034',
        text:
          'При выявлении каких дефектов во время осмотра наружной и внутренней поверхности баллоны не подлежат отбраковке?',
        answers: [
          'Наличие трещин, плен, вмятин, отдулин, раковин и рисок глубиной более 10 % номинальной толщины стенки',
          'Наличие надрывов и выщерблений',
          'Износ резьбы горловины',
          'Ослабление кольца на горловине баллона',
          'Выявление всех указанных дефектов влечет за собой отбраковку баллона',
        ],
        correctAnswers: ['Ослабление кольца на горловине баллона'],
      },
      {
        code: '63696035',
        text:
          'В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?',
        answers: [
          'Отклонения от проектной документации не допускаются',
          'Отклонения от проектной документации оформляются в виде специальных технических условий, подлежащих утверждению в установленном порядке',
          'В технически обоснованных случаях отклонение от проектной документации согласуется с ее разработчиком или со специализированной экспертной организацией',
        ],
        correctAnswers: ['Отклонения от проектной документации не допускаются'],
      },
      {
        code: '63696036',
        text:
          'В каком документе даются указания по выводу из эксплуатации и утилизации оборудования, работающего под давлением?',
        answers: [
          'Руководстве (инструкции) по эксплуатации',
          'Заключении по результатам технического диагностирования оборудования',
          'Типовой инструкции по утилизации оборудования, работающего под давлением, используемого на опасном производственном объекте',
          'Документации эксплуатирующей организации, прошедшей экспертизу промышленной безопасности',
        ],
        correctAnswers: ['Руководстве (инструкции) по эксплуатации'],
      },
      {
        code: '63696037',
        text:
          'Какое требование к складам для хранения баллонов с углекислотой указано неверно?',
        answers: [
          'Склады для баллонов должны находиться в зоне молниезащиты',
          'В складах должны быть вывешены инструкции, правила и плакаты по обращению с баллонами, находящимися на складе',
          'Склады для баллонов, наполненных газом, должны иметь естественную или искусственную вентиляцию',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Склады для баллонов должны находиться в зоне молниезащиты',
        ],
      },
      {
        code: '63696038',
        text:
          'Чем должны быть укомплектованы баллоны? Укажите все правильные ответы.',
        answers: [
          'Вентилями, плотно ввернутыми в расходно-наполнительные штуцера у специальных баллонов, не имеющих горловины',
          'Вентилями, плотно ввернутыми в отверстия горловины',
          'Баллоны вместимостью более 50 л должны иметь предохранительные клапаны',
        ],
        correctAnswers: [
          'Вентилями, плотно ввернутыми в расходно-наполнительные штуцера у специальных баллонов, не имеющих горловины',
          'Вентилями, плотно ввернутыми в отверстия горловины',
        ],
      },
      {
        code: '63696039',
        text:
          'Какие требования к обоснованию безопасности согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" указаны неверно?',
        answers: [
          'Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя',
          'Обоснование безопасности оборудования готовится на этапе разработки (проектирования) оборудования',
          'В обосновании безопасности приводятся анализ рисков для оборудования, а также минимально необходимые меры по обеспечению безопасности',
          'Оригинал обоснования безопасности оборудования хранится у разработчика (проектировщика)',
        ],
        correctAnswers: [
          'Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя',
        ],
      },
      {
        code: '63696040',
        text:
          'В каком из приведенных случаев размещение баллонов с газом на местах потребления должно осуществляться в соответствии с планом (проектом) размещения оборудования?',
        answers: [
          'При размещении баллона на расстоянии менее 5 метров от источников открытого огня',
          'При размещении групповой баллонной установки',
          'При размещении баллонов в местах проезда транспортных средств',
        ],
        correctAnswers: ['При размещении групповой баллонной установки'],
      },
      {
        code: '63696041',
        text:
          'На каком этапе разрабатывается руководство (инструкция) по эксплуатации оборудования?',
        answers: [
          'На этапе разработки (проектирования) оборудования',
          'На этапе передачи оборудования поставщику (потребителю)',
          'На этапе монтажа оборудования и проведения пусконаладочных работ',
        ],
        correctAnswers: ['На этапе разработки (проектирования) оборудования'],
      },
      {
        code: '63696042',
        text:
          'Какое количество образцов должно быть вырезано из каждого контрольного стыкового сварного соединения для металлографических исследований при контроле сварных соединений из углеродистой и низколегированной стали?',
        answers: ['Один', 'Не менее двух', 'Не менее трех', 'Не менее четырех'],
        correctAnswers: ['Один'],
      },
      {
        code: '63696043',
        text:
          'Какой документ о подтверждении соответствия требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" должно иметь оборудование, отнесенное ко 2-й категории опасности, доизготовление которого с применением неразъемных соединений осуществляется по месту эксплуатации?',
        answers: [
          'Сертификат',
          'Заключение экспертизы промышленной безопасности',
          'Декларация соответствия',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Декларация соответствия'],
      },
      {
        code: '63696044',
        text: 'Каким способом определяется фактическая вместимость баллона?',
        answers: [
          'Только с помощью мерных бачков',
          'Способом, указанным в руководстве по эксплуатации завода-изготовителя',
          'По разности между массой баллона, наполненного водой, и массой порожнего баллона; с помощью мерных бачков или иным, установленным в производственной инструкции способом, обеспечивающим необходимую точность измерения',
          'Только по разности между массой баллона, наполненного водой, и массой порожнего баллона',
        ],
        correctAnswers: [
          'По разности между массой баллона, наполненного водой, и массой порожнего баллона; с помощью мерных бачков или иным, установленным в производственной инструкции способом, обеспечивающим необходимую точность измерения',
        ],
      },
      {
        code: '63696045',
        text:
          'Каким образом баллоны, из которых невозможно выпустить газ из-за неисправности вентилей на месте потребления, возвращаются на наполнительную станцию?',
        answers: [
          'Вместе с пустыми (порожними) баллонами с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона',
          'Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона',
          'Любым способом, обеспечивающим безопасность их транспортирования',
        ],
        correctAnswers: [
          'Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона',
        ],
      },
      {
        code: '63696046',
        text:
          'Каким документом подтверждается соответствие оборудования требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"? Выберите 2 варианта ответа.',
        answers: [
          'Декларация о соответствии',
          'Сертификат соответствия',
          'Протокол приемочных испытаний',
          'Паспорт оборудования, имеющий соответствующую отметку',
        ],
        correctAnswers: [
          'Декларация о соответствии',
          'Сертификат соответствия',
        ],
      },
      {
        code: '63696047',
        text:
          'Каким документом определяется порядок действий в случае инцидента при эксплуатации сосуда?',
        answers: [
          'Инструкцией (руководством) по эксплуатации организации - изготовителя сосуда',
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
        ],
      },
      {
        code: '63696048',
        text:
          'Кто утверждает график объема и периодичности плановых работ по техническому обслуживанию и ремонту оборудования под давлением и его элементов?',
        answers: [
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Техническая комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технический руководитель эксплуатирующей организации',
        ],
      },
      {
        code: '63696049',
        text:
          'Какой срок службы устанавливается для баллонов при отсутствии указаний организации-изготовителя?',
        answers: ['10 лет', '30 лет', '20 лет', '40 лет', '50 лет'],
        correctAnswers: ['20 лет'],
      },
      {
        code: '63696050',
        text:
          'При соблюдении какого условия оборудование, работающее под избыточным давлением, выпускается в обращение на рынок?',
        answers: [
          'Оборудование должно иметь соответствующее разрешение на его обращение на рынке, выданное в порядке, предусмотренном Евразийской экономической комиссией',
          'Оборудование должно иметь документ о прохождении оценки соответствия требованиям ТР ТС 032/2013',
          'Оборудование должно иметь разрешение на его применение, выданное Федеральной службой по экологическому, технологическому и атомному надзору',
          'Оборудование должно иметь документ о подтверждении соответствия требованиям государственных и международных стандартов, выданный в порядке, предусмотренном Росстандартом',
        ],
        correctAnswers: [
          'Оборудование должно иметь документ о прохождении оценки соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63696051',
        text:
          'Какие из приведенных требований к площадкам и лестницам для обслуживания, осмотра и ремонта оборудования указаны неверно?',
        answers: [
          'Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали',
          'Площадки и лестницы для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены с ограждением перилами высотой не менее 900 мм со сплошной обшивкой по низу на высоту не менее 100 мм',
          'Переходные площадки и лестницы должны иметь перила с обеих сторон. Площадки котлов длиной более 5 м должны иметь не менее двух лестниц (двух выходов), расположенных в противоположных концах',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Площадки и ступени лестниц для обслуживания, осмотра, ремонта оборудования под давлением должны быть выполнены из гладких или рифленых листов стали, прутковой (круглой) или полосовой стали',
        ],
      },
      {
        code: '63696052',
        text:
          'Какая информация из приведенной не включается в маркировку, наносимую на оборудование, работающее под давлением?',
        answers: [
          'Наименование и (или) обозначение оборудования',
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
          'Параметры и характеристики, влияющие на безопасность',
          'Наименование материала, из которого изготовлено оборудование',
          'Вся приведенная информация включается в маркировку, наносимую на оборудование',
        ],
        correctAnswers: [
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63696053',
        text:
          'Какой орган осуществляет контроль за соблюдением требований Правил промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением при проведении технического освидетельствования, ремонта и наполнения баллонов?',
        answers: [
          'Ростехнадзор',
          'Росаккредитация',
          'Росстандарт',
          'Орган по сертификации в рамках проведения инспекционного контроля',
        ],
        correctAnswers: ['Ростехнадзор'],
      },
      {
        code: '63696054',
        text:
          'Каково минимальное значение свободной высоты от уровня земли, пола здания (помещения), площадок (мостиков) и ступеней лестниц обслуживания?',
        answers: ['2,0 м', '2,5 м', '1,7 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63696055',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, установленных стационарно, в которых хранятся сжатый воздух, кислород, аргон, азот, гелий с температурой точки росы - 35 °С и ниже, замеренной при давлении 15 МПа и выше?',
        answers: [
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 10 лет'],
      },
      {
        code: '63696056',
        text:
          'Какой баллон из приведенных допускается использовать в горизонтальном положении?',
        answers: [
          'Допускается использовать все баллоны в горизонтальном положении',
          'Баллон с кислородом',
          'Баллон с ацетиленом',
          'Баллон с пропан-бутаном',
        ],
        correctAnswers: ['Баллон с кислородом'],
      },
      {
        code: '63696057',
        text:
          'Какой срок действия сертификата соответствия оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" при использовании схемы 1с?',
        answers: [
          '5 лет',
          '3 года',
          '1 год',
          'В течение назначенного срока службы или назначенного ресурса',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63696058',
        text:
          'Где может проводиться восстановление окраски и надписей на баллонах?',
        answers: [
          'На наполнительной станции',
          'В эксплуатирующей организации',
          'На пунктах освидетельствования баллонов',
          'В любой специализированной ремонтной организации',
        ],
        correctAnswers: ['На пунктах освидетельствования баллонов'],
      },
      {
        code: '63696059',
        text: 'Где должны храниться баллоны с ядовитыми газами?',
        answers: [
          'На открытом воздухе, если они защищены от атмосферных осадков и солнечных лучей',
          'В специальных складских помещениях совместно с кислородными баллонами',
          'В специальных закрытых помещениях',
        ],
        correctAnswers: ['В специальных закрытых помещениях'],
      },
      {
        code: '63696060',
        text:
          'Каким путем проводится оценка риска для идентифицированных видов опасности при проектировании оборудования согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Расчетным',
          'Экспериментальным',
          'Экспертным',
          'По данным эксплуатации аналогичных видов оборудования',
          'Всеми перечисленными путями',
        ],
        correctAnswers: ['Всеми перечисленными путями'],
      },
      {
        code: '63696061',
        text:
          'Какой устанавливается срок хранения наполненных газом баллонов, находящихся на длительном складском хранении, при удовлетворительных результатах периодического освидетельствования?',
        answers: [
          'Устанавливается лицом, производившим освидетельствование, но не более чем 2 года',
          'Не более чем 6 месяцев',
          'Не более чем год',
          'Устанавливается организацией-изготовителем, но не более чем 18 месяцев',
        ],
        correctAnswers: [
          'Устанавливается лицом, производившим освидетельствование, но не более чем 2 года',
        ],
      },
      {
        code: '63696062',
        text:
          'Какие сведения из указанных не наносятся на верхнюю сферическую часть баллона?',
        answers: [
          'Сведения, подлежащие нанесению в соответствии с требованиями ТР ТС 032/2013',
          'Сведения о проведенном техническом освидетельствовании баллона: дата проведения; клеймо организации (индивидуального предпринимателя), проводившей техническое освидетельствование',
          'Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона',
          'Масса пустого баллона',
          'Максимальное разрешенное давление',
        ],
        correctAnswers: [
          'Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона',
        ],
      },
      {
        code: '63696063',
        text:
          'Каким образом оформляются результаты проводимого контроля качества сварных соединений?',
        answers: [
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется. На усмотрение организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования',
          'Общие результаты по контролю качества сварных соединений оформляются отчетом, прилагаемым к паспорту монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
        ],
        correctAnswers: [
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
        ],
      },
      {
        code: '63696064',
        text:
          'В каком из приведенных случаев сосуд считается выдержавшим гидравлическое испытание?',
        answers: [
          'Если обнаружены потения в развальцованных соединениях',
          'Если видимая остаточная деформация не превышает 10 %',
          'Если обнаружены лишь единичные трещины в сварном соединении, а на основном металле трещины не обнаружены',
          'Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание',
        ],
        correctAnswers: [
          'Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание',
        ],
      },
      {
        code: '63696065',
        text:
          'Перегородками какой высоты допускается отделять отсеки для хранения баллонов с негорючими и неядовитыми газами?',
        answers: [
          'Не менее 1,8 м',
          'Не менее 1,5 м',
          'Не менее 2,5 м',
          'Не более 2,0 м',
        ],
        correctAnswers: ['Не менее 2,5 м'],
      },
      {
        code: '63696066',
        text:
          'Какие требования безопасности необходимо соблюдать при очистке и окраске наполненных газом баллонов?',
        answers: [
          'Баллоны должны находиться только в горизонтальном положении',
          'Очистка и окраска баллонов должна производиться только на открытом воздухе',
          'Очистка и окраска наполненных газом баллонов запрещается',
          'Процедура должна проводиться в специально оборудованном помещении, защищенном от проникновения солнечных лучей',
        ],
        correctAnswers: [
          'Очистка и окраска наполненных газом баллонов запрещается',
        ],
      },
      {
        code: '63696067',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования подлежащих учету в органах Ростехнадзора металлических баллонов, установленных стационарно, а также установленных постоянно на передвижных средствах, в которых хранится сжатый природный газ (компримированный)?',
        answers: [
          '1 раз в 5 лет',
          '1 раз в 2 года',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 5 лет'],
      },
      {
        code: '63696068',
        text:
          'Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?',
        answers: [
          'Эксплуатирующей организацией на основании руководства (инструкции) по эксплуатации оборудования, работающего под давлением, с последующим согласованием со специализированной организацией, выполняющей эти работы',
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
          'Организацией - изготовителем оборудования, работающего под давлением',
          'Совместно специализированной организацией, выполняющей эти работы, и эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
        ],
      },
      {
        code: '63696069',
        text: 'Где могут храниться наполненные баллоны с газами?',
        answers: [
          'Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей',
          'Только в специально оборудованном помещении',
          'ФНП ОРПД не устанавливает дополнительных требований к помещениям для хранения баллонов',
        ],
        correctAnswers: [
          'Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей',
        ],
      },
      {
        code: '63696070',
        text:
          'Где проводится перенасадка башмаков и колец для колпаков, а также замена вентилей на баллонах?',
        answers: [
          'На наполнительной станции',
          'В эксплуатирующей организации',
          'На пунктах освидетельствования баллонов',
          'В любой специализированной ремонтной организации',
        ],
        correctAnswers: ['На пунктах освидетельствования баллонов'],
      },
      {
        code: '63696071',
        text:
          'Какое требование Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением к шифру клейма указано неверно?',
        answers: [
          'Шифры клейм состоят из цифровой части - арабских цифр в виде чисел от 01 до 98, и буквенной части - заглавных букв русского или латинского алфавитов, кроме букв русского алфавита "З", "О", "Ч", "Е", "И", "X", "Ь", "Ъ", "Ы", с применением заглавных букв латинского алфавита "W", "U", "S", "F", "L", "Z", "V", "N"',
          'Шифр клейма имеет три знака одного размера (высотой 6 мм), располагаемых в ряд в круге диаметром 12 мм, и состоит из двух цифр (цифровая часть шифра) и одной заглавной буквы (буквенная часть шифра)',
          'Допускается включение в шифр тире, точек, замена цифры 0 буквой О',
          'Перестановка в шифре цифр местами (например, замена цифровой части шифра 12 числом 21) не допускается',
        ],
        correctAnswers: [
          'Допускается включение в шифр тире, точек, замена цифры 0 буквой О',
        ],
      },
      {
        code: '63696072',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов для сжатого газа, изготовленных из углеродистых сталей и металлокомпозитных материалов и предназначенных для обеспечения топливом двигателей, на которых они установлены?',
        answers: [
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 3 года'],
      },
      {
        code: '63696073',
        text:
          'Какие требования предъявляются Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением к клейму для выбраковки баллонов?',
        answers: [
          'Клеймо круглой формы диаметром 12 мм с буквой "X"',
          'Клеймо круглой формы диаметром 20 мм с буквой "X"',
          'Клеймо квадратной формы диаметром 12 мм с цифрами "00"',
          'Клеймо круглой формы диаметром 12 мм с буквой "X" и цифрами "00"',
        ],
        correctAnswers: ['Клеймо круглой формы диаметром 12 мм с буквой "X"'],
      },
      {
        code: '63696074',
        text:
          'Куда направляет эксплуатирующая оборудование под давлением организация копии документов, содержащих основание и подтверждение факта передачи оборудования другой эксплуатирующей организации?',
        answers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности.',
          'В органы местного самоуправления',
          'Другой эксплуатирующей организации',
          'Заводу-изготовителю',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности.',
        ],
      },
      {
        code: '63696075',
        text: 'Какие требования к хранению баллонов указаны неверно?',
        answers: [
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
          'При хранении на открытых площадках разрешается укладывать баллоны с башмаками в штабеля с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами',
          'При укладке баллонов в штабеля высота последних не должна превышать 1,5 метра, вентили баллонов должны быть обращены в одну сторону',
        ],
        correctAnswers: [
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
        ],
      },
      {
        code: '63696076',
        text:
          'В каком из перечисленных случаев должно быть вырезано три образца из каждого контрольного стыкового сварного соединения?',
        answers: [
          'Для испытания на ударный изгиб',
          'Для испытания на статическое растяжение',
          'Для испытаний на статический изгиб или сплющивание',
          'Для испытаний на стойкость против межкристаллитной коррозии',
        ],
        correctAnswers: ['Для испытания на ударный изгиб'],
      },
      {
        code: '63696077',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, находящихся в эксплуатации для наполнения газами, вызывающими разрушение и физико-химическое превращение материала со скоростью более 0,1 мм/год?',
        answers: [
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 2 года'],
      },
      {
        code: '63696078',
        text:
          'Какое из перечисленных требований к капиллярному и магнитопорошковому контролю указано верно?',
        answers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
          'При применении нескольких видов контроля в отношении одного объекта капиллярный контроль должен выполняться после проведения ультразвукового и магнитопорошкового контроля',
          'По результатам капиллярного контроля на поверхности сварных соединений и наплавок допускаются единичные и групповые индикаторные рисунки округлой или удлиненной форм',
          'По результатам контроля магнитопорошковым методом на поверхности сварных соединений и наплавок допускаются индикаторные рисунки осаждений магнитного порошка',
        ],
        correctAnswers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
        ],
      },
      {
        code: '63696079',
        text:
          'На какие виды оборудования не распространяется требования Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза',
          'Сосуды, предназначенные для газов, сжиженных газов, растворенных под давлением, и паров, используемые для рабочих сред группы 1 и имеющие максимально допустимое рабочее давление свыше 20 МПа, вместимость свыше 0,0001 м до 0,001 м включительно',
          'Сосуды, предназначенные для жидкостей, используемые для рабочих сред группы 2 и имеющие максимально допустимое рабочее давление свыше 100 МПа, вместимость свыше 0,0001 м до 0,01 м включительно',
          'Котлы, имеющие вместимость более 0,002 м, предназначенные для получения горячей воды, температура которой свыше 110°С',
          'Котлы, имеющие вместимость более 0,002 м, предназначенные для получения пара, избыточное давление которого свыше 0,05 МПа',
        ],
        correctAnswers: [
          'Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза',
        ],
      },
      {
        code: '63696080',
        text:
          'Как должны храниться наполненные баллоны с насаженными на них башмаками, а также баллоны, имеющие специальную конструкцию с вогнутым днищем?',
        answers: [
          'В вертикальном положении',
          'В горизонтальном положении',
          'В штабелях с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами',
          'Не регламентируется',
        ],
        correctAnswers: ['В вертикальном положении'],
      },
      {
        code: '63696081',
        text:
          'Какая процедура из указанных не проводится при освидетельствовании стальных бесшовных баллонов до 12 литров включительно и свыше 55 литров, а также сварных баллонов независимо от вместимости?',
        answers: [
          'Осмотр внутренней поверхности баллона',
          'Проверка массы и вместимости баллона',
          'Гидравлическое испытание баллона',
        ],
        correctAnswers: ['Проверка массы и вместимости баллона'],
      },
      {
        code: '63696082',
        text:
          'Какое минимальное значение избыточного давления должно оставаться в баллонах (если иное не предусмотрено техническими условиями на газ)?',
        answers: [
          'ФНП ОРПД не регламентировано',
          'Газ в баллонах должен срабатываться полностью',
          '0,05 МПа',
          '0,07 МПа',
        ],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63696083',
        text:
          'До какой величины может быть снижено пробное давление для баллонов, изготовленных из материала, отношение временного сопротивления к пределу текучести которого более 2?',
        answers: [
          'До 1,3 рабочего давления',
          'До 1,25 рабочего давления',
          'До 1,1 рабочего давления',
          'До 1,2 рабочего давления',
        ],
        correctAnswers: ['До 1,25 рабочего давления'],
      },
      {
        code: '63696084',
        text:
          'В каком количестве наполненные газом баллоны, находящиеся на длительном складском хранении, при наступлении очередных сроков периодического освидетельствования подвергают освидетельствованию в выборочном порядке из партии до 100 баллонов?',
        answers: [
          'В количестве не менее 5 штук',
          'В количестве не менее 10 штук',
          'В количестве не менее 15 штук',
          'В количестве не менее 20 штук',
        ],
        correctAnswers: ['В количестве не менее 5 штук'],
      },
      {
        code: '63696085',
        text:
          'В каком количестве наполненные газом баллоны, находящиеся на длительном складском хранении, при наступлении очередных сроков периодического освидетельствования подвергают освидетельствованию в выборочном порядке из партии свыше 500 баллонов?',
        answers: [
          'В количестве не менее 5 штук',
          'В количестве не менее 10 штук',
          'В количестве не менее 15 штук',
          'В количестве не менее 20 штук',
        ],
        correctAnswers: ['В количестве не менее 20 штук'],
      },
      {
        code: '63696086',
        text:
          'Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?',
        answers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
          'В случае невозможности контроля сварного соединения с двух сторон его контроль должен быть проведен в порядке, предусмотренном технологической документацией на сварку',
          'Перед визуальным контролем поверхность сварного шва и прилегающие к нему участки основного металла шириной не менее 10 мм в обе стороны от шва должны быть зачищены от шлака и других загрязнений',
        ],
        correctAnswers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
        ],
      },
      {
        code: '63696087',
        text:
          'Сколько раз разрешается проводить исправление дефектов на одном и том же участке сварного соединения?',
        answers: [
          'Не более трех раз',
          'Не более двух раз',
          'Не более четырех раз',
          'Один раз',
        ],
        correctAnswers: ['Не более трех раз'],
      },
      {
        code: '63696088',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов 20 Дж/см² и менее? Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.',
        answers: [
          'Рпр = 1,6 Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,25 Км + 1,3 (1 - Км )] Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,5 Км + 1,6 (1 - Км )] Рраб',
        ],
        correctAnswers: ['Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63696089',
        text:
          'Чем испытывают баллоны для ацетилена, наполненные пористой массой, при освидетельствовании?',
        answers: ['Воздухом', 'Азотом', 'Гелием', 'Любым инертным газом'],
        correctAnswers: ['Азотом'],
      },
      {
        code: '63696090',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью 20 Дж/см² и менее? Где в формулах: Рраб – рабочее давление сосуда, Р расч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 °С и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,6 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,3 Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,3 Рраcч ([σ]20 / [σ]t)',
          'Рпр = 1,6 Рраб',
        ],
        correctAnswers: ['Рпр = 1,6 Рраб ([σ]20 / [σ]t)'],
      },
      {
        code: '63696091',
        text:
          'Куда должна направить организация, прекратившая освидетельствование баллонов, один экземпляр акта по уничтожению оставшихся клейм с шифрами?',
        answers: [
          'ФНП ОРПД таких требований не устанавливает',
          'В территориальный орган Ростехнадзора, присвоивший шифр клейма',
          'В аккредитованный орган по сертификации, присвоивший шифр клейма',
          'В аккредитованную испытательную лабораторию, присвоившую шифр клейма',
        ],
        correctAnswers: [
          'В территориальный орган Ростехнадзора, присвоивший шифр клейма',
        ],
      },
      {
        code: '63696092',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования подлежащих учету в органах Ростехнадзора баллонов со средой, вызывающей разрушение и физико-химическое превращение материалов (коррозия и т.п.) со скоростью не более 0,1 мм/год?',
        answers: [
          '1 раз в 8 лет',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 8 лет'],
      },
      {
        code: '63696093',
        text:
          'На какое оборудование, работающее под избыточным давлением, не распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Сосуды вместимостью не более 0,025 м³, у которых произведение значений рабочего давления (МПа) и вместимости (м³) не превышает 0,02',
          'Цистерны и сосуды для сжатых, сжиженных газов, жидкостей и сыпучих тел, в которых избыточное давление создается периодически для их опорожнения',
          'Баллоны, предназначенные для сжатых, сжиженных и растворенных под давлением газов',
          'Цистерны и бочки для сжатых и сжиженных газов',
        ],
        correctAnswers: [
          'Сосуды вместимостью не более 0,025 м³, у которых произведение значений рабочего давления (МПа) и вместимости (м³) не превышает 0,02',
        ],
      },
      {
        code: '63696094',
        text:
          'Какая должна быть минимальная ширина свободного прохода площадок (мостиков) для обслуживания арматуры, контрольно-измерительных приборов и другого оборудования?',
        answers: ['800 мм', '600 мм', '400 мм', '1200 мм'],
        correctAnswers: ['800 мм'],
      },
      {
        code: '63696095',
        text:
          'Кто обязан вести учет присвоенных шифров в журнале учета шифров клейм?',
        answers: [
          'Организации - изготовители баллонов',
          'Территориальный орган Ростехнадзора',
          'Уполномоченные в установленном порядке специализированные организации, имеющие наполнительные станции (пункты наполнения) и (или) испытательные пункты (пункты проверки)',
          'Назначенное приказом лицо, ответственное за проведение освидетельствования, из числа специалистов, аттестованных в установленном порядке',
        ],
        correctAnswers: ['Территориальный орган Ростехнадзора'],
      },
      {
        code: '63696096',
        text:
          'Существует ли разница в нанесении надписей на баллонах вместимостью более 12 литров и до 12 литров?',
        answers: [
          'Существует, размеры надписей и полос на баллонах вместимостью до 12 литров должны определяться в зависимости от величины боковой поверхности баллонов',
          'Разница только в высоте букв: на баллонах вместимостью до 12 литров высота букв должна быть 40 мм, а на баллонах более 12 литров - 60 мм',
          'Разницы нет, надписи наносятся по окружности на длину не менее 1/3 окружности, а полосы - по всей окружности',
          'Существует, размеры надписей на баллонах вместимостью до 12 литров наносят на длину не менее 1/2 окружности, а для баллонов более 12 литров - на длину не менее 1/3 окружности',
        ],
        correctAnswers: [
          'Существует, размеры надписей и полос на баллонах вместимостью до 12 литров должны определяться в зависимости от величины боковой поверхности баллонов',
        ],
      },
      {
        code: '63696097',
        text:
          'Какие из перечисленных партий материалов для дефектоскопии до начала их использования должны быть подвергнуты входному контролю?',
        answers: [
          'Все перечисленные партии материалов',
          'Пенетранты',
          'Суспензии',
          'Радиографическая пленка',
        ],
        correctAnswers: ['Все перечисленные партии материалов'],
      },
      {
        code: '63696098',
        text:
          'Каким документом должна руководствоваться организация при осуществлении деятельности по эксплуатации (наполнению, хранению, транспортированию и использованию) баллонов?',
        answers: [
          'Собственной инструкцией',
          'Руководством наполнительной станции',
          'Правилами промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под повышенным давлением.',
          'Всеми перечисленными документами.',
        ],
        correctAnswers: ['Собственной инструкцией'],
      },
      {
        code: '63696099',
        text:
          'Какой маркировке подлежит оборудование, соответствие которого не подтверждено требованиям технического регламента "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Брак',
          'Неликвид',
          'Ex',
          'HE EAC',
          'Оборудование не подлежит маркировке',
        ],
        correctAnswers: ['Оборудование не подлежит маркировке'],
      },
      {
        code: '63696100',
        text:
          'Какие меры принимаются при неудовлетворительных результатах повторного периодического освидетельствования наполненных газом баллонов, находящихся на длительном складском хранении?',
        answers: [
          'Дальнейшее хранение всей партии баллонов не допускается, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты техническому освидетельствованию каждый в отдельности',
          'Дальнейшее хранение всей партии баллонов не допускается, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты утилизации',
          'Баллоны, не прошедшие освидетельствование, дальнейшему хранению не подлежат, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты утилизации, остальные баллоны из этой партии подлежат освидетельствованию в выборочном порядке через три месяца',
        ],
        correctAnswers: [
          'Дальнейшее хранение всей партии баллонов не допускается, газ из баллонов должен быть удален в срок, указанный лицом, производившим освидетельствование, после чего баллоны должны быть подвергнуты техническому освидетельствованию каждый в отдельности',
        ],
      },
      {
        code: '63696101',
        text:
          'Какое количество категорий оборудования существует в зависимости от вместимости или номинального диаметра, а также максимально допустимого рабочего давления согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: ['4', '3', '2', '6'],
        correctAnswers: ['4'],
      },
      {
        code: '63696102',
        text:
          'В каком случае из перечисленных проводят гидравлическое испытание пробным давлением в целях проверки плотности и прочности оборудования под давлением, а также всех сварных и других соединений?',
        answers: [
          'Во всех перечисленных случаях',
          'После окончательной сборки (изготовления, доизготовления) при монтаже оборудования, транспортируемого на место его установки отдельными деталями, элементами или блоками',
          'После реконструкции (модернизации), ремонта оборудования с применением сварки элементов, работающих под давлением',
          'При проведении технических освидетельствований и технического диагностирования оборудования в случаях, установленных Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63696103',
        text:
          'Как оформляется журнал наполнения, если наполнительная станция производит наполнение баллонов различными газами?',
        answers: [
          'Должен вестись отдельный журнал наполнения',
          'Должен вестись единый журнал наполнения',
          'В отдельный журнал должны заноситься сведения только о наполнении баллонов взрывоопасными газами',
        ],
        correctAnswers: ['Должен вестись отдельный журнал наполнения'],
      },
      {
        code: '63696104',
        text:
          'Какой документ является основным для идентификации оборудования, работающего под избыточным давлением?',
        answers: [
          'Сертификат соответствия оборудования требованиям ТР ТС 032/2013',
          'Обоснование безопасности оборудования',
          'Паспорт оборудования',
          'Декларация соответствия оборудования требованиям промышленной безопасности',
        ],
        correctAnswers: ['Паспорт оборудования'],
      },
      {
        code: '63696105',
        text:
          'Какие требования к хранению баллонов на наполнительной станции указаны неверно?',
        answers: [
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
          'При хранении на открытых площадках разрешается укладывать баллоны с башмаками в штабеля с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами',
          'При укладке баллонов в штабеля высота последних не должна превышать 1,5 м, вентили баллонов должны быть обращены в одну сторону',
        ],
        correctAnswers: [
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
        ],
      },
      {
        code: '63696106',
        text:
          'Какая процедура из указанных не проводится при освидетельствовании баллонов для ацетилена?',
        answers: [
          'Осмотр наружной поверхности баллона',
          'Проверка пористой массы баллона',
          'Гидравлическое испытание баллона',
          'Пневматическое испытание баллона',
        ],
        correctAnswers: ['Гидравлическое испытание баллона'],
      },
      {
        code: '63696107',
        text:
          'Что может применяться для обеспечения доступа к площадкам обслуживания оборудования под давлением, предназначенным для периодического проведения работ (плановое техническое обслуживание, ремонт) в местах расположения люков, арматуры и иных устройств, оборудованных автоматическим дистанционным приводом, не требующих постоянного (неоднократного) присутствия персонала?',
        answers: [
          'Переносные площадки',
          'Передвижные лестницы',
          'Стационарные лестницы с углом наклона к горизонтали более 50°',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63696108',
        text:
          'При каком давлении проводится проверка на плотность вентиля после ремонта, связанного с его разборкой?',
        answers: [
          'При рабочем давлении',
          'При давлении, равном 1,25 рабочего давления',
          'При давлении, равном 1,5 рабочего давления',
          'Г) Проверка на плотность не проводится',
        ],
        correctAnswers: ['При рабочем давлении'],
      },
      {
        code: '63696109',
        text: 'Какое определение соответствует термину "границы сосуда"?',
        answers: [
          'Входные и выходные штуцера, а также присоединенные к ним патрубки (трубопроводы обвязки) с установленными на них арматурой, предохранительными и иными устройствами (при их наличии в случаях, установленных проектом), входящие в состав конструкции сосуда и указанные организацией-изготовителем в паспорте и чертежах общего вида сосуда',
          'Запорные устройства питательных, дренажных и других трубопроводов, а также предохранительные и другие клапаны и задвижки, ограничивающие внутренние полости элементов сосуда и присоединенных к ним трубопроводов',
          'Запорная арматура, предохранительные и другие устройства, отделяющие (дистанцирующие) сосуд на входе и выходе от подключенного к нему оборудования и (или) трубопроводов',
        ],
        correctAnswers: [
          'Входные и выходные штуцера, а также присоединенные к ним патрубки (трубопроводы обвязки) с установленными на них арматурой, предохранительными и иными устройствами (при их наличии в случаях, установленных проектом), входящие в состав конструкции сосуда и указанные организацией-изготовителем в паспорте и чертежах общего вида сосуда',
        ],
      },
      {
        code: '63696110',
        text:
          'Какие требования к организациям, осуществляющим освидетельствование (испытания) баллонов, являются избыточными?',
        answers: [
          'Организация должна располагать производственными помещениями, а также техническими средствами, обеспечивающими возможность проведения освидетельствования баллонов',
          'В организации должны быть назначены приказом лица, ответственные за проведение освидетельствования, из числа специалистов, аттестованных в установленном порядке, и рабочих соответствующей квалификации',
          'Организация должна иметь клеймо с индивидуальным шифром',
          'Организация должна иметь не менее трех специалистов с соответствующим высшим профессиональным образованием',
          'В организации должна быть разработана производственная инструкция по проведению технического освидетельствования баллонов, устанавливающая объем и порядок проведения работ, составленная на основании методик разработчика проекта и (или) изготовителя конкретного типа баллонов',
        ],
        correctAnswers: [
          'Организация должна иметь не менее трех специалистов с соответствующим высшим профессиональным образованием',
        ],
      },
      {
        code: '63696111',
        text:
          'Что включает в себя масса баллона, за исключением баллона для ацетилена, указываемая на его верхней сферической части?',
        answers: [
          'Указывается только масса пустого баллона',
          'Масса пустого баллона с учетом массы нанесенной краски, кольца для колпака и башмака, если таковые предусмотрены конструкцией, но без массы вентиля и колпака',
          'Массу пустого баллона с учетом массы нанесенной краски, кольца для колпака и башмака, если таковые предусмотрены конструкцией, массы вентиля и колпака',
        ],
        correctAnswers: [
          'Масса пустого баллона с учетом массы нанесенной краски, кольца для колпака и башмака, если таковые предусмотрены конструкцией, но без массы вентиля и колпака',
        ],
      },
      {
        code: '63696112',
        text:
          'Какой документ о подтверждении соответствия требованиям ТР ТС 032/2013 должно иметь оборудование, отнесенное к 3-й категории опасности?',
        answers: [
          'Сертификат',
          'Декларация соответствия',
          'Заключение экспертизы промышленной безопасности',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Сертификат'],
      },
      {
        code: '63696113',
        text:
          'При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?',
        answers: [
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
          'Для сосудов, подлежащих учету в территориальных органах Ростехнадзора, не допускается проведение ремонта сосудов и их элементов, находящихся под давлением, для остальных сосудов – 0,05 МПа',
          '0,05 МПа',
          '0,025 МПа',
        ],
        correctAnswers: [
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
        ],
      },
      {
        code: '63696114',
        text:
          'Каково минимальное значение ширины свободного прохода площадок для обслуживания арматуры, контрольно-измерительных приборов и другого оборудования?',
        answers: ['600 мм', '1 м', '700 мм', '800 мм'],
        correctAnswers: ['800 мм'],
      },
      {
        code: '63696115',
        text:
          'Какое из перечисленных требований при проведении гидравлического испытания сосудов указано верно?',
        answers: [
          'Все ответы неверны',
          'Сосуды, имеющие защитное покрытие или изоляцию, подвергают гидравлическому испытанию после наложения покрытия или изоляции',
          'Сосуды, имеющие наружный кожух, подвергают гидравлическому испытанию после установки кожуха',
          'Не допускается эмалированные сосуды подвергать гидравлическому испытанию рабочим давлением после эмалирования',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63696116',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов для сжиженного газа, предназначенных для обеспечения топливом двигателей транспортных средств?',
        answers: [
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 2 года'],
      },
      {
        code: '63696117',
        text:
          'Какое из приведенных требований к размещению баллонов при их использовании указано неверно?',
        answers: [
          'Допускается установка баллонов на расстоянии не менее 1 метра от радиаторов отопления и других отопительных приборов',
          'Не допускается установка баллонов в местах перемещения грузов',
          'Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем',
        ],
      },
      {
        code: '63696118',
        text:
          'Какая ширина поверхности сварного шва и прилегающих к нему участков основного металла должны быть зачищены от шлака и других загрязнений перед визуальным контролем?',
        answers: [
          'Не менее 20 мм в обе стороны от шва',
          'Не менее 10 мм в обе стороны от шва',
          'Не менее 30 мм в обе стороны от шва',
          'Не менее 40 мм в обе стороны от шва',
        ],
        correctAnswers: ['Не менее 20 мм в обе стороны от шва'],
      },
      {
        code: '63696119',
        text:
          'Какое требование к перемещению баллонов на объектах их применения указано неверно?',
        answers: [
          'Перемещение баллонов должно производиться на специально приспособленных для этого тележках или с помощью других устройств, обеспечивающих безопасность транспортирования',
          'Перемещение наполненных баллонов в пределах производственной площадки должно производиться на рессорном транспорте или на автокарах',
          'Перемещение на автокарах наполненных баллонов, расположенных вертикально, не допускается, даже при наличии контейнеров',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Перемещение на автокарах наполненных баллонов, расположенных вертикально, не допускается, даже при наличии контейнеров',
        ],
      },
      {
        code: '63696120',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводятся наружный и внутренний осмотры в процессе технического освидетельствования подлежащих учету в органах Ростехнадзора баллонов, установленных стационарно, в которых хранятся сжатый воздух, кислород, аргон, азот, гелий с температурой точки росы - 35 °С и ниже, замеренной при давлении 15 МПа и выше?',
        answers: [
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 10 лет'],
      },
      {
        code: '63696121',
        text:
          'Какое максимальное количество баллонов с негорючими и неядовитыми газами объемом 40 л допускается хранить в одном отсеке складского помещения?',
        answers: [
          '1000 баллонов',
          '1200 баллонов',
          '2000 баллонов',
          '500 баллонов',
        ],
        correctAnswers: ['1000 баллонов'],
      },
      {
        code: '63696122',
        text:
          'Какие требования к складам хранения баллонов, наполненных газами, указаны неверно?',
        answers: [
          'Здание склада должно быть одноэтажным с покрытиями легкого типа и не иметь чердачных помещений',
          'Оконные и дверные стекла должны быть матовыми или закрашены белой краской',
          'Стены, перегородки, покрытия складов для хранения газов должны быть из несгораемых материалов, соответствующих проекту',
          'Окна и двери должны открываться внутрь',
          'Высота складских помещений для баллонов должна быть не менее 3,25 м от пола до нижних выступающих частей кровельного покрытия',
        ],
        correctAnswers: ['Окна и двери должны открываться внутрь'],
      },
      {
        code: '63696123',
        text:
          'Каким требованиям должно отвечать помещение, используемое для освидетельствования баллонов?',
        answers: [
          'Оно должно быть отдельным и специально оборудованным в соответствии с проектом, температура воздуха в этих помещениях должна быть не ниже +12 °С',
          'Освидетельствование баллонов проводится только на открытом воздухе при температуре окружающего воздуха не ниже -5 °С',
          'ФНП ОРПД не устанавливает дополнительных требований к помещениям для освидетельствования баллонов',
        ],
        correctAnswers: [
          'Оно должно быть отдельным и специально оборудованным в соответствии с проектом, температура воздуха в этих помещениях должна быть не ниже +12 °С',
        ],
      },
      {
        code: '63696124',
        text:
          'Кем определяются тип арматуры, ее количество и место установки согласно требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Разработчиком проекта оборудования',
          'Монтажной организацией',
          'Эксплуатирующей организацией',
          'Специализированной экспертной организацией',
        ],
        correctAnswers: ['Разработчиком проекта оборудования'],
      },
      {
        code: '63696125',
        text:
          'В каком случае необходимо проводить металлографические исследования?',
        answers: [
          'Все ответы неверны',
          'Для сварных соединений сосудов и их элементов, изготовленных из сталей аустенитного класса, толщиной не более 20 мм',
          'Для сварных соединений котлов и трубопроводов, изготовленных из стали перлитного класса, при условии проведения ультразвукового или радиографического контроля этих соединений в объеме 100%',
          'Для сварных соединений труб поверхностей нагрева котлов и трубопроводов, выполненных контактной сваркой на специальных машинах для контактной стыковой сварки с автоматизированным циклом работ при ежесменной проверке качества наладки машины путем испытания контрольных образцов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63696126',
        text:
          'В каких сосудах не должно создаваться избыточное давление, превышающее максимально допустимое рабочее давление более чем на 0,05 МПа?',
        answers: [
          'В сосудах, в которых избыточное давление составляет менее 0,3 МПа',
          'В сосудах, в которых избыточное давление составляет от 0,3 до 6 МПа включительно',
          'В сосудах, в которых избыточное давление составляет более 6 МПа',
        ],
        correctAnswers: [
          'В сосудах, в которых избыточное давление составляет менее 0,3 МПа',
        ],
      },
      {
        code: '63696127',
        text:
          'Что из перечисленного может применяться в качестве предохранительных устройств на оборудовании, работающим под избыточным давлением?',
        answers: [
          'Рычажно-грузовые предохранительные клапаны прямого действия',
          'Пружинные предохранительные клапаны прямого действия',
          'Импульсные предохранительные устройства, состоящие из импульсного клапана и главного предохранительного клапана',
          'Предохранительные устройства с разрушающимися мембранами (мембранные предохранительные устройства)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63696128',
        text: 'Кем присваивается шифр клейма?',
        answers: [
          'Центральным аппаратом Ростехнадзора',
          'Территориальным органом Ростехнадзора',
          'Аккредитованным органом по сертификации',
          'Аккредитованной испытательной лабораторией',
        ],
        correctAnswers: ['Территориальным органом Ростехнадзора'],
      },
      {
        code: '63696129',
        text:
          'При какой величине пробного давления проводится гидравлическое испытание баллонов?',
        answers: [
          'Величину пробного давления устанавливает изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление',
          'Пробное давление должно быть не менее, чем рабочее давление',
          'Величину пробного давления устанавливает изготовитель, при этом пробное давление должно быть не менее чем 1,25 рабочего давления',
        ],
        correctAnswers: [
          'Величину пробного давления устанавливает изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление',
        ],
      },
      {
        code: '63696130',
        text:
          'Кем определяется достаточная для обеспечения безопасной эксплуатации ОПО численность инженерно-технических работников?',
        answers: [
          'Эксплуатирующей организацией',
          'Ростехнадзором',
          'Проектной организацией',
          'Научно-исследовательской организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63696131',
        text:
          'Какие данные выбивает (наносит) на баллоне организация, проводившая освидетельствование, при удовлетворительных результатах?',
        answers: [
          'Клеймо круглой формы диаметром 12 мм, дату проведенного и следующего освидетельствования',
          'Клеймо круглой формы диаметром 12 мм, дату проведенного освидетельствования',
          'Клеймо круглой формы диаметром 12 мм, дату следующего освидетельствования',
        ],
        correctAnswers: [
          'Клеймо круглой формы диаметром 12 мм, дату проведенного и следующего освидетельствования',
        ],
      },
      {
        code: '63696132',
        text:
          'Какой из перечисленных терминов соответствует определению "избыточное давление, при котором производится испытание оборудования на прочность и плотность"?',
        answers: [
          'Давление пробное',
          'Давление рабочее',
          'Давление расчетное',
          'Давление разрешенное',
        ],
        correctAnswers: ['Давление пробное'],
      },
      {
        code: '63696133',
        text:
          'Каковы предельно допустимые значения ширины, высоты между ступенями и ширины ступеней лестниц для обслуживания, осмотра и ремонта оборудования под давлением?',
        answers: [
          'Лестницы должны иметь ширину не менее 800 мм, ширину ступеней не более 80 мм; высота ступеней лестницы должна быть не более 250 мм',
          'Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм',
          'Лестницы должны иметь ширину не менее 500 мм, высоту между ступенями не более 100 мм, ширину ступеней не менее 50 мм',
        ],
        correctAnswers: [
          'Лестницы должны иметь ширину не менее 600 мм, высоту между ступенями не более 200 мм, ширину ступеней не менее 80 мм',
        ],
      },
      {
        code: '63696134',
        text:
          'Какие факторы, представляющие собой основные виды опасности, должны учитываться с целью определения рисков для оборудования?',
        answers: [
          'Все перечисленные',
          'Наличие незащищенных подвижных элементов, вибрация, наличие взрывопожароопасных элементов',
          'Недопустимые отклонения параметров конструкции, сборочных единиц и устройств безопасности, влияющие на безопасность',
          'Коррозия и иные виды износа материала элементов оборудования, прекращение действия вспомогательного оборудования, погасание факелов в топке при камерном сжигании топлива',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63696135',
        text:
          'Какой документацией устанавливаются необходимость, объем и порядок механических испытаний сварных соединений литых и кованых элементов, труб с литыми деталями, элементов из сталей различных классов, а также других единичных сварных соединений?',
        answers: [
          'Проектной и технологической документацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Производственными инструкциями',
          'Распорядительной документацией эксплуатирующей организации',
        ],
        correctAnswers: ['Проектной и технологической документацией'],
      },
      {
        code: '63696136',
        text:
          'При какой вместимости баллонов результаты освидетельствования баллонов заносятся в паспорт баллона?',
        answers: [
          'Более 100 литров',
          'Свыше 55 до 100 литров',
          'Результаты освидетельствования в паспорт баллона не заносятся, ставится только клеймо на баллоне',
        ],
        correctAnswers: ['Более 100 литров'],
      },
      {
        code: '63696137',
        text:
          'Каким образом допускается хранить наполненные баллоны до выдачи их потребителям?',
        answers: [
          'Без предохранительных колпаков',
          'С навернутыми колпаками',
          'С навернутыми колпаками или иной защитой запорного органа баллона',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63696138',
        text:
          'Каким документом определяются разрывы между складами для баллонов, наполненных газами, между складами и смежными производственными зданиями, общественными помещениями, жилыми домами?',
        answers: [
          'Проектом',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Внутренней распорядительной документацией организации',
          'Техническим регламентом',
        ],
        correctAnswers: ['Проектом'],
      },
      {
        code: '63696139',
        text:
          'Какие требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением к оснащению баллонов предохранительным клапаном указаны неверно?',
        answers: [
          'Баллоны вместимостью более 100 литров должны быть оснащены предохранительными клапанами',
          'При групповой установке баллонов допускается установка предохранительного клапана на всю группу баллонов',
          'Пропускная способность предохранительного клапана подтверждается расчетом',
          'При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон',
        ],
        correctAnswers: [
          'При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон',
        ],
      },
      {
        code: '63696140',
        text:
          'Какие требования по порядку выдачи и возвращения клейм с шифрами противоречат требованиям Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'В организации должно быть обеспечено ведение журнала учета выдачи и возвращения клейм с шифрами',
          'Клеймо с шифром выдается лицу, прошедшему подготовку и аттестацию по промышленной безопасности в установленном порядке и назначенному приказом (распоряжением) руководителя организации для проведения освидетельствования баллонов',
          'Клейма одного шифра закрепляются за одним лицом на все время выполнения им освидетельствования баллонов',
          'Разовые или временные передачи клейм для клеймения баллонов другим лицам без соответствующего приказа (распоряжения) руководителя организации (индивидуального предпринимателя) не допускаются',
          'Порядок, обеспечивающий сохранность клейм и журнала учета выдачи и возвращения клейм с шифрами, определяется методическими указаниями Ростехнадзора',
        ],
        correctAnswers: [
          'Порядок, обеспечивающий сохранность клейм и журнала учета выдачи и возвращения клейм с шифрами, определяется методическими указаниями Ростехнадзора',
        ],
      },
      {
        code: '63696141',
        text:
          'При отсутствии каких данных на верхней сферической части баллоны могут быть допущены к эксплуатации?',
        answers: [
          'При отсутствии клейма организации (индивидуального предпринимателя), проводившей техническое освидетельствование',
          'При отсутствии указания массы пустого баллона',
          'При отсутствии даты проведения технического освидетельствования',
          'Эксплуатация баллонов запрещается при отсутствии любых из перечисленных данных',
        ],
        correctAnswers: [
          'Эксплуатация баллонов запрещается при отсутствии любых из перечисленных данных',
        ],
      },
      {
        code: '63696142',
        text:
          'Какой максимальный срок действия декларации о соответствии серийно выпускаемого оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" со дня ее регистрации в Едином реестре выданных сертификатов соответствия и зарегистрированных деклараций о соответствии?',
        answers: ['5 лет', '4 года', '3 года', '1 год'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63696143',
        text:
          'Выполнение каких из перечисленных процедур не предусматривается при проверке знаний и допуске работника к самостоятельной работе?',
        answers: [
          'Предусматривается выполнение всех перечисленных процедур',
          'Проведение вводного инструктажа',
          'Проведение первичного инструктажа на рабочем месте',
          'Проверка знаний инструкций',
          'Допуск к самостоятельной работе с выдачей удостоверения',
        ],
        correctAnswers: [
          'Предусматривается выполнение всех перечисленных процедур',
        ],
      },
      {
        code: '63696144',
        text:
          'В какие сроки проводится освидетельствование баллонов для ацетилена на ацетиленовых наполнительных станциях?',
        answers: [
          'В сроки, установленные изготовителем, но не реже одного раза в три года',
          'В сроки, установленные изготовителем, но не реже чем через 5 лет',
          'Не реже чем через 24 месяца',
          'Не реже чем через 18 месяцев',
        ],
        correctAnswers: [
          'В сроки, установленные изготовителем, но не реже чем через 5 лет',
        ],
      },
      {
        code: '63696145',
        text:
          'Какие графы не содержатся в журнале испытаний при освидетельствовании баллонов?',
        answers: [
          'Давление баллона при испытаниях, МПа; нормы отбраковки баллона',
          'Товарный знак или наименование изготовителя; номер баллона',
          'Фамилия, инициалы и подпись представителя организации (индивидуального предпринимателя), проводившей освидетельствование',
          'Дата (месяц, год) изготовления баллона; дата произведенного и следующего освидетельствования',
          'Масса, выбитая на баллоне, кг; масса баллона, установленная при освидетельствовании, кг',
        ],
        correctAnswers: [
          'Давление баллона при испытаниях, МПа; нормы отбраковки баллона',
        ],
      },
      {
        code: '63696146',
        text:
          'Если изготовителем не установлено, то с какой периодичностью проводится гидравлическое испытание пробным давлением в процессе технического освидетельствования не подлежащих учету в органах Ростехнадзора баллонов, находящихся в эксплуатации для наполнения газами, вызывающими разрушение и физико-химическое превращение материала со скоростью менее 0,1 мм/год, в которых давление выше 0,07 МПа создается периодически для их опорожнения?',
        answers: [
          '1 раз в год',
          '1 раз в 2 года',
          '1 раз в 5 лет',
          '1 раз в 3 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['1 раз в 10 лет'],
      },
      {
        code: '63696147',
        text: 'Какое требование к наполнению баллонов газами указано неверно?',
        answers: [
          'Наполнение баллонов газами должно быть произведено по инструкции, разработанной и утвержденной наполнительной организацией (индивидуальным предпринимателем) в установленном порядке с учетом свойств газа, местных условий и требований руководства (инструкции) по эксплуатации и иной документации изготовителя баллона',
          'Перед наполнением кислородных баллонов должен быть проведен контроль отсутствия в них примеси горючих газов газоанализатором в порядке, установленном инструкцией',
          'Наполнение баллонов сжиженными газами должно соответствовать нормам, установленным изготовителем баллонов и (или) техническими условиями на сжиженные газы',
          'При отсутствии норм наполнения баллонов, установленных организацией-изготовителем и (или) техническими условиями на сжиженные газы, наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013',
        ],
        correctAnswers: [
          'При отсутствии норм наполнения баллонов, установленных организацией-изготовителем и (или) техническими условиями на сжиженные газы, наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013',
        ],
      },
      {
        code: '63696148',
        text:
          'Кто проводит испытания оборудования, предусмотренные проектной документацией?',
        answers: [
          'Изготовитель',
          'Поставщик',
          'Проектировщик',
          'Предприятие-владелец',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63696149',
        text:
          'Какие из перечисленных требований указаны неверно согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'На оборудование, предназначенное для транспортировки газов (баллоны и автоцистерны), наносятся отличительная окраска и идентификационная информация',
          'Элементы и комплектующие оборудования маркируются в соответствии с договором на поставку (контрактом)',
          'Техническая документация на оборудование хранится у изготовителя (уполномоченного изготовителем лица) в течение расчетного срока службы со дня прекращения производства этого оборудования',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63696150',
        text:
          'На какой максимальный процент допускается превышение давления в сосуде от максимально допустимого рабочего давления при работающих предохранительных клапанах, при условии, что это превышение предусмотрено руководством (инструкцией) по эксплуатации сосуда?',
        answers: ['25%', '15%', '35%', '20%'],
        correctAnswers: ['25%'],
      },
      {
        code: '63696151',
        text:
          'Применение каких площадок и ступеней лестниц на оборудовании, работающем под избыточным давлением, запрещается?',
        answers: [
          'Из прутковой круглой стали или гладких ступеней лестниц',
          'Из просечно-вытяжного листа',
          'Из рифленой листовой стали',
          'Из сотовой или полосовой (на ребро) стали',
        ],
        correctAnswers: [
          'Из прутковой круглой стали или гладких ступеней лестниц',
        ],
      },
    ],
    63693: [
      {
        code: '63693000',
        text:
          'Кем подписывается удостоверение (свидетельство) о качестве монтажа?',
        answers: [
          'Всеми перечисленными лицами',
          'Руководителями (техническим руководителями) или уполномоченными должностными лицами монтажной организации',
          'Руководителями (техническим руководителями) или уполномоченными должностными лицами эксплуатирующей организации (или ее обособленного структурного подразделения)',
          'Уполномоченным представителем организации разработчика проекта или организации-изготовителя, осуществлявшего авторский надзор (шефмонтаж) за выполнением работ',
        ],
        correctAnswers: ['Всеми перечисленными лицами'],
      },
      {
        code: '63693001',
        text:
          'Какие надписи должны быть нанесены на магистральных линиях трубопроводов пара и горячей воды? Укажите неправильный ответ.',
        answers: [
          'Номер магистрали',
          'Направление движения рабочей среды',
          'Номера агрегатов, к которым направлена рабочая среда',
        ],
        correctAnswers: [
          'Номера агрегатов, к которым направлена рабочая среда',
        ],
      },
      {
        code: '63693002',
        text:
          'Какое из приведенных требований по нанесению надписей на трубопроводы должно выполняться?',
        answers: [
          'Надписи на одном и том же трубопроводе должны повторяться через каждые 25 м',
          'Надписи должны быть видимы с мест управления арматурой и иными устройствами в составе конкретного трубопровода',
          'В местах входа трубопроводов из других помещений надпись обязательна',
          'Все приведенные требования должны выполняться',
        ],
        correctAnswers: [
          'Надписи должны быть видимы с мест управления арматурой и иными устройствами в составе конкретного трубопровода',
        ],
      },
      {
        code: '63693003',
        text:
          'Что означает термин "температура рабочей среды" согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
          'Температура, при которой определяются физико-механические характеристики, допускаемое напряжение материала и проводится расчет на прочность элементов оборудования',
          'Максимальная (минимальная) температура стенки сосуда, при которой допускается эксплуатация оборудования',
          'Температура, равная 20°С, используемая при расчете на прочность стандартных сосудов (узлов, деталей, арматуры)',
        ],
        correctAnswers: [
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
        ],
      },
      {
        code: '63693004',
        text:
          'При проведении гидравлического испытания трубопровода, каким давлением проводится испытание сосудов, являющихся неотъемлемой частью трубопровода и не имеющих запорных органов?',
        answers: [
          'Пробное давление при гидравлическом испытании трубопровода определяется также, как и для сосудов, работающих под давлением',
          'Пробное давление увеличивают на 15 %',
          'Испытывают тем же давлением, что и трубопроводы',
        ],
        correctAnswers: ['Испытывают тем же давлением, что и трубопроводы'],
      },
      {
        code: '63693005',
        text:
          'Каково минимальное значение высоты каналов и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в полупроходных каналах?',
        answers: [
          'Высота канала не нормируется, ширина прохода 0,6 метра',
          'Высота канала 1,5 метра, ширина прохода 0,6 метра',
          'Высота канала 1 метр, ширина прохода 0,4 метра',
          'Высота канала 1 метр, ширина прохода не нормируется',
        ],
        correctAnswers: ['Высота канала 1,5 метра, ширина прохода 0,6 метра'],
      },
      {
        code: '63693006',
        text:
          'В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?',
        answers: [
          'Начало и конец комплексного опробования оборудования, работающего под давлением, устанавливаются совместным приказом эксплуатирующей организации и организации, проводящей наладочные работы',
          'Котлы – в течение 72 часов, трубопроводы пара и горячей воды – в течение 36 часов, время комплексного опробования сосудов устанавливается совместным приказом эксплуатирующей и наладочной организаций',
          'Котлы – в течение 72 часов, трубопроводы тепловых сетей – в течение 24 часов, остальное оборудование – по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Котлы – в течение 72 часов, трубопроводы тепловых сетей – в течение 24 часов, остальное оборудование – по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
        ],
      },
      {
        code: '63693007',
        text:
          'Какие из перечисленных партий материалов для дефектоскопии до начала их использования должны быть подвергнуты входному контролю?',
        answers: [
          'Все перечисленные партии материалов',
          'Пенетранты',
          'Суспензии',
          'Радиографическая пленка',
        ],
        correctAnswers: ['Все перечисленные партии материалов'],
      },
      {
        code: '63693008',
        text:
          'Кем проводятся первичное, периодическое и внеочередное технические освидетельствования трубопроводов пара и горячей воды, подлежащих учету в территориальных органах Ростехнадзора?',
        answers: [
          'Первичное, периодическое и внеочередное техническое освидетельствование проводит специализированная уполномоченная организация',
          'Первичное и внеочередное техническое освидетельствование проводит специализированная уполномоченная организация, а периодическое техническое освидетельствование – ответственный за исправное состояние и безопасную эксплуатацию трубопровода',
          'Первичное, периодическое и внеочередное техническое освидетельствование проводят ответственный за исправное состояние и безопасную эксплуатацию трубопровода совместно с ответственным за производственный контроль',
          'Первичное, периодическое техническое освидетельствование проводит специализированная уполномоченная организация, а внеочередное освидетельствование – представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Первичное, периодическое и внеочередное техническое освидетельствование проводит специализированная уполномоченная организация',
        ],
      },
      {
        code: '63693009',
        text:
          'Что необходимо предпринять, если при техническом освидетельствовании трубопровода будут обнаружены дефекты?',
        answers: [
          'Для установления характера и размеров дефектов должно быть проведено техническое диагностирование трубопровода с применением методов неразрушающего контроля',
          'Организация, проводившая техническое освидетельствование, дает предписание о выводе трубопровода из эксплуатации',
          'Эксплуатирующая организация должна перевести режим эксплуатации трубопровода на пониженные параметры, рекомендованные организацией, проводившей техническое освидетельствование',
        ],
        correctAnswers: [
          'Для установления характера и размеров дефектов должно быть проведено техническое диагностирование трубопровода с применением методов неразрушающего контроля',
        ],
      },
      {
        code: '63693010',
        text:
          'Какое требование к обеспечению прогрева и продувки паропроводов указано неверно?',
        answers: [
          'Паропроводы на давление 20 МПа и выше должны быть обеспечены штуцерами с последовательно расположенными запорным и регулирующим вентилями и дроссельной шайбой',
          'Все участки паропроводов, которые могут быть отключены запорными органами, для возможности их прогрева и продувки должны быть снабжены в концевых точках штуцером с вентилем',
          'В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода',
          'Паропроводы при давлении свыше 2,2 МПа должны быть снабжены штуцером и двумя последовательно расположенными вентилями: запорным и регулирующим',
        ],
        correctAnswers: [
          'В случаях прогрева участка паропровода в обоих направлениях продувка должна быть предусмотрена в середине участка паропровода',
        ],
      },
      {
        code: '63693011',
        text:
          'Какое из приведенных требований к арматуре трубопроводов указано неверно?',
        answers: [
          'На арматуре или на специальной бирке должны быть нанесены названия и номера согласно технологическим схемам трубопроводов, а также указатели направления вращения штурвала (маховика)',
          'Регулирующие клапаны должны быть снабжены указателями степени открытия регулирующего органа, а запорная арматура – указателями положения её запорного органа (в открытом и закрытом состоянии)',
          'Для трубопроводов горячей воды допускается использование запорной арматуры в качестве регулирующей',
          'Должны выполняться все приведенные требования',
        ],
        correctAnswers: [
          'Для трубопроводов горячей воды допускается использование запорной арматуры в качестве регулирующей',
        ],
      },
      {
        code: '63693012',
        text:
          'При соблюдении какого условия оборудование, работающее под избыточным давлением, выпускается в обращение на рынок?',
        answers: [
          'Оборудование должно иметь соответствующее разрешение на его обращение на рынке, выданное в порядке, предусмотренном Евразийской экономической комиссией',
          'Оборудование должно иметь документ о прохождении оценки (подтверждения) соответствия требованиям ТР ТС 032/2013',
          'Оборудование должно иметь разрешение на его применение, выданное Федеральной службой по экологическому, технологическому и атомному надзору',
          'Оборудование должно иметь документ о подтверждении соответствия требованиям государственных и международных стандартов, выданный в порядке, предусмотренном Росстандартом',
        ],
        correctAnswers: [
          'Оборудование должно иметь документ о прохождении оценки (подтверждения) соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63693013',
        text:
          'Куда направляет эксплуатирующая оборудование под давлением организация копии документов, содержащих основание и подтверждение факта передачи оборудования другой эксплуатирующей организации?',
        answers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности',
          'В органы местного самоуправления',
          'Другой эксплуатирующей организации',
          'Заводу-изготовителю',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63693014',
        text:
          'Чему равно минимальное значение уклона, который должны иметь горизонтальные участки трубопроводов пара и горячей воды (за исключением трубопроводов тепловых сетей)?',
        answers: ['0,001', '0,002', '0,003', '0,004'],
        correctAnswers: ['0,004'],
      },
      {
        code: '63693015',
        text:
          'Какие из перечисленных участков относятся к участкам, работающим в особо сложных условиях, при проведении технического освидетельствования трубопроводов?',
        answers: [
          'Все перечисленные',
          'Тупиковые и временно неработающие участки',
          'Дренажные устройства',
          'Тройники',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63693016',
        text:
          'В каком из приведенных случаев проверку готовности трубопровода к пуску в работу и проверку организации надзора за эксплуатацией трубопровода осуществляют ответственные лица или комиссия с их участием?',
        answers: [
          'После монтажа без применения неразъемных соединений трубопровода, демонтированного и установленного на новом месте',
          'После монтажа трубопровода, подтверждение соответствия которого не предусмотрено ТР ТС 032/2013',
          'При передаче ОПО и (или) трубопровода для использования другой эксплуатирующей организации',
          'После реконструкции (модернизации) трубопровода',
        ],
        correctAnswers: [
          'После монтажа без применения неразъемных соединений трубопровода, демонтированного и установленного на новом месте',
        ],
      },
      {
        code: '63693017',
        text:
          'Что включает в себя техническое освидетельствование трубопровода, проводимое после его реконструкции и ремонта, связанного со сваркой и термической обработкой?',
        answers: [
          'Только наружный осмотр',
          'Наружный осмотр, гидравлическое испытание и механические испытания металла',
          'Наружный осмотр и гидравлическое испытание',
          'В данном случае техническое освидетельствование трубопровода не проводится',
        ],
        correctAnswers: ['Наружный осмотр и гидравлическое испытание'],
      },
      {
        code: '63693018',
        text:
          'Кто и на основании чего принимает решение о вводе в эксплуатацию трубопроводов пара и горячей воды?',
        answers: [
          'Уполномоченный представитель Ростехнадзора на основании проверки готовности трубопровода к пуску в работу и проверки организации надзора за эксплуатацией трубопровода',
          'Специалист, ответственный за исправное состояние и безопасную эксплуатацию трубопровода на основании проверки документации и результатов произведенного им технического освидетельствования трубопровода',
          'Ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением на основании проверки организации надзора за эксплуатацией трубопровода',
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности трубопровода к пуску в работу и проверки организации надзора за эксплуатацией трубопровода',
        ],
        correctAnswers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения) на основании проверки готовности трубопровода к пуску в работу и проверки организации надзора за эксплуатацией трубопровода',
        ],
      },
      {
        code: '63693019',
        text:
          'Какое из приведенных требований должно выполняться при оснащении проходных каналов для трубопроводов пара и горячей воды входными люками?',
        answers: [
          'Расстояние между люками должно быть не более 50 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры',
          'Расстояние между люками должно быть не более 100 метров; для всех трубопроводов люки предусматриваются в узлах установки арматуры; для паропроводов люки предусматриваются в конечных точках тупиковых участков, а для трубопроводов горячей воды - на поворотах трассы',
          'Расстояние между люками должно быть не более 300 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры',
        ],
        correctAnswers: [
          'Расстояние между люками должно быть не более 300 метров; для всех трубопроводов люки также предусматриваются в конечных точках тупиковых участков, на поворотах трассы и в узлах установки арматуры',
        ],
      },
      {
        code: '63693020',
        text:
          'Какие из приведенных трубопроводов должны подвергаться техническому диагностированию, неразрушающему, разрушающему контролю до выработки ими назначенного ресурса?',
        answers: [
          'Техническое диагностирование трубопроводов должно проводиться только после выработки ими назначенного ресурса',
          'Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С',
          'Паропровод из легированной хромомолибденовой стали, работающий при температуре пара свыше 400 °С до 500 °С',
          'Паропровод из высоколегированной хромистой стали, работающий при температуре пара свыше 300 °С до 540 °С',
        ],
        correctAnswers: [
          'Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С',
        ],
      },
      {
        code: '63693021',
        text:
          'С какой периодичностью ответственный за исправное состояние и безопасную эксплуатацию оборудования совместно с ответственным за производственный контроль за безопасной эксплуатацией оборудования проводят периодическое освидетельствование трубопроводов пара и горячей воды, подлежащих учету в территориальных органах Ростехнадзора?',
        answers: [
          'Не реже 1 раза в 12 месяцев',
          'Не реже 1 раза в 24 месяца',
          'Не реже 1 раза в 18 месяцев',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 12 месяцев'],
      },
      {
        code: '63693022',
        text:
          'Какой маркировке подлежит оборудование, соответствие которого не подтверждено требованиям технического регламента "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Брак',
          'Неликвид',
          'Ex',
          'HE EAC',
          'Оборудование не подлежит маркировке',
        ],
        correctAnswers: ['Оборудование не подлежит маркировке'],
      },
      {
        code: '63693023',
        text:
          'Каково минимальное значение номинального диаметра корпуса манометра, установленного на трубопроводе на высоте более 3 до 5 м от уровня площадки наблюдения за манометром?',
        answers: ['50 мм', '100 мм', '150 мм', '250 мм'],
        correctAnswers: ['250 мм'],
      },
      {
        code: '63693024',
        text:
          'Чем должны оснащаться указатели уровня жидкости на оборудовании, давление которого превышает 4,5 МПа?',
        answers: [
          'Двумя дублирующими указателями уровня жидкости непрямого действия',
          'Двумя дополнительными сниженными дистанционными указателями уровня жидкости',
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
          'Дополнительными звуковыми, световыми и другими сигнализаторами и блокировками по предельным уровням жидкости',
        ],
        correctAnswers: [
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
        ],
      },
      {
        code: '63693025',
        text:
          'Кто проводит испытания оборудования, предусмотренные проектной документацией?',
        answers: [
          'Изготовитель',
          'Поставщик',
          'Проектировщик',
          'Предприятие-владелец',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63693026',
        text:
          'На каком этапе разрабатывается руководство (инструкция) по эксплуатации оборудования?',
        answers: [
          'На этапе разработки (проектирования) оборудования',
          'На этапе передачи оборудования поставщику (потребителю)',
          'На этапе монтажа оборудования и проведения пусконаладочных работ',
        ],
        correctAnswers: ['На этапе разработки (проектирования) оборудования'],
      },
      {
        code: '63693027',
        text:
          'Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы трубопровода?',
        answers: [
          'Инструкцией (руководством) по эксплуатации трубопровода и ФНП ОРПД',
          'Программой проведения технического освидетельствования трубопровода, разработанной специализированной организацией до начала проведения освидетельствования',
          'Производственной инструкцией по эксплуатации трубопровода, утвержденной главным техническим руководителем эксплуатационной организации',
        ],
        correctAnswers: [
          'Инструкцией (руководством) по эксплуатации трубопровода и ФНП ОРПД',
        ],
      },
      {
        code: '63693028',
        text:
          'Какая организация разрабатывает исполнительную схему (чертеж) трубопровода?',
        answers: [
          'Организация, производившая монтаж трубопровода',
          'Эксплуатирующая организация',
          'Для трубопроводов, подлежащих учету в территориальном органе Ростехнадзора, исполнительная схема разрабатывается экспертной организацией, а в остальных случаях – эксплуатирующей организацией',
        ],
        correctAnswers: ['Организация, производившая монтаж трубопровода'],
      },
      {
        code: '63693029',
        text:
          'В каком из приведенных случаев в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, трубопровод подлежит аварийной остановке?',
        answers: [
          'Если давление в трубопроводе поднялось выше разрешенного и не снижается, несмотря на меры, принятые персоналом',
          'При защемлении и повышенной вибрации трубопровода',
          'При неисправности дренажных устройств для непрерывного удаления жидкости',
          'Во всех приведенных случаях трубопровод подлежит аварийной остановке',
        ],
        correctAnswers: [
          'Во всех приведенных случаях трубопровод подлежит аварийной остановке',
        ],
      },
      {
        code: '63693030',
        text:
          'В каком случае допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением?',
        answers: [
          'Если разность избыточных давлений элемента, подлежащего продувке, и емкости составляет не более 0,2 МПа',
          'Если барабан котла не имеет солевых отсеков и рабочее давление котла не превышает 1,3 МПа',
          'Если подтверждены надежность и эффективность продувки соответствующими расчетами',
          'Не допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением',
        ],
        correctAnswers: [
          'Если подтверждены надежность и эффективность продувки соответствующими расчетами',
        ],
      },
      {
        code: '63693031',
        text:
          'В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов с рабочим давлением свыше 4,0 МПа?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в сутки',
          'Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации',
          'Проверка манометров проводится не реже одного раза в смену; проверка предохранительных клапанов – не реже одного раза в сутки',
        ],
        correctAnswers: [
          'Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63693032',
        text:
          'Какие факторы, представляющие собой основные виды опасности, должны учитываться с целью определения рисков для оборудования?',
        answers: [
          'Все перечисленные',
          'Наличие незащищенных подвижных элементов, вибрация, наличие взрывопожароопасных элементов',
          'Недопустимые отклонения параметров конструкции, сборочных единиц и устройств безопасности, влияющие на безопасность',
          'Коррозия и иные виды износа материала элементов оборудования, прекращение действия вспомогательного оборудования, погасание факелов в топке при камерном сжигании топлива',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63693033',
        text:
          'Какое требование необходимо выполнять при установке на одном патрубке (трубопроводе) нескольких предохранительных клапанов?',
        answers: [
          'Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем',
          'Площадь поперечного сечения патрубка (трубопровода) должна быть равна суммарной площади сечения клапанов, установленных на нем',
          'Площадь поперечного сечения патрубка (трубопровода) должна быть не более 1,25 суммарной площади сечения клапанов, установленных на нем',
        ],
        correctAnswers: [
          'Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем',
        ],
      },
      {
        code: '63693034',
        text:
          'Кем должны быть разработаны и утверждены документы при отсутствии в комплекте технической документации, прилагаемой организацией-изготовителем к оборудованию под давлением, документов (в виде разделов паспорта либо отдельных формуляров, журналов), обеспечивающих возможность внесения информации об истории эксплуатации оборудования под давлением?',
        answers: [
          'Эксплуатирующей организацией',
          'Научно-исследовательской организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63693035',
        text:
          'Какое из приведенных требований к организации отвода токсичных, взрыво- и пожароопасных технологических сред, выходящих из предохранительных устройств, указано неверно?',
        answers: [
          'Сбрасываемые среды должны направляться в закрытые системы для дальнейшей утилизации или в системы организованного сжигания',
          'В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды',
          'Запрещается объединять сбросы, содержащие вещества, которые способны при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды',
        ],
      },
      {
        code: '63693036',
        text:
          'В каком случае допускается превышение разрешенного давления в трубопроводе при полном открывании предохранительного клапана более чем на 10%?',
        answers: [
          'Если на защищаемом элементе установлено не менее двух предохранительных клапанов',
          'Если регулировка предохранительного клапана допускает превышение разрешенного давления не более чем на 15 %',
          'Если предохранительные клапаны установлены на трубопроводе горячей воды',
          'Если это превышение предусмотрено расчетом на прочность трубопровода',
        ],
        correctAnswers: [
          'Если это превышение предусмотрено расчетом на прочность трубопровода',
        ],
      },
      {
        code: '63693037',
        text:
          'Какой документ о подтверждении соответствия требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" должно иметь оборудование, отнесенное ко 2-й категории опасности, доизготовление которого с применением неразъемных соединений осуществляется по месту эксплуатации?',
        answers: [
          'Сертификат',
          'Заключение экспертизы промышленной безопасности',
          'Декларация соответствия',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Декларация соответствия'],
      },
      {
        code: '63693038',
        text:
          'По какому документу выполняются ремонт трубопроводов, арматуры и элементов дистанционного управления арматурой, установка и снятие заглушек, отделяющих ремонтируемый участок трубопровода?',
        answers: [
          'Ремонт – по наряду-допуску; установка и снятие заглушек – по распоряжению ответственного за исправное состояние и безопасную эксплуатацию трубопроводов',
          'Ремонт – по приказу эксплуатирующей организации; установка и снятие заглушек – по наряду допуску',
          'Все работы выполняются по регламенту, разработанному и утвержденному эксплуатирующей организацией',
          'Все работы по наряду-допуску',
        ],
        correctAnswers: ['Все работы по наряду-допуску'],
      },
      {
        code: '63693039',
        text:
          'Что из перечисленного не указывается в исполнительной схеме (чертеже) трубопровода?',
        answers: [
          'Материалы (марки, стандарты или технические условия), наружные диаметры, толщины труб и деталей из труб, длину трубопровода',
          'Расположение опор, компенсаторов, подвесок, арматуры, приборов (располагаемых непосредственно на трубопроводе), фильтров, воздушников и дренажных устройств',
          'Расположение сварных соединений (при их наличии) с раздельным обозначением сварных соединений, выполняемых при монтаже трубопровода и выполняемых в организации-изготовителе элементов трубопровода',
          'Расчетный срок службы и расчетное количество пусков трубопровода',
        ],
        correctAnswers: [
          'Расчетный срок службы и расчетное количество пусков трубопровода',
        ],
      },
      {
        code: '63693040',
        text:
          'Какой документ о подтверждении соответствия требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" должно иметь оборудование, отнесенное к 3-й категории опасности?',
        answers: [
          'Сертификат',
          'Декларация соответствия',
          'Заключение экспертизы промышленной безопасности',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Сертификат'],
      },
      {
        code: '63693041',
        text:
          'Каким давлением проводится испытание на герметичность арматуры после ее ремонта?',
        answers: [
          'Давлением, равным 1,25 рабочего давления для любой арматуры',
          'Давлением, равным 1,25 рабочего давления, но не менее 0,2 МПа для арматуры, ремонтируемой без снятия с места установки и рабочим давлением – для снимаемой с места арматуры',
          'Давлением, равным 1,25 рабочего давления – для арматуры, снимаемой с места и рабочим давлением – для арматуры, ремонтируемой без снятия с места установки',
          'Отдельные испытания отремонтированной арматуры на герметичность не производятся, арматура испытывается на плотность и прочность при гидравлическом испытании трубопровода',
        ],
        correctAnswers: [
          'Давлением, равным 1,25 рабочего давления – для арматуры, снимаемой с места и рабочим давлением – для арматуры, ремонтируемой без снятия с места установки',
        ],
      },
      {
        code: '63693042',
        text:
          'Что из перечисленного допускается для обеспечения сопряжения поперечных стыков труб?',
        answers: [
          'Расточка',
          'Раздача',
          'Обжатие концов труб',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63693043',
        text:
          'В каком случае из перечисленных проводят гидравлическое испытание пробным давлением в целях проверки плотности и прочности оборудования под давлением, а также всех сварных и других соединений?',
        answers: [
          'Во всех перечисленных случаях',
          'После окончательной сборки (изготовления, доизготовления) при монтаже оборудования, транспортируемого на место его установки отдельными деталями, элементами или блоками',
          'После реконструкции (модернизации), ремонта оборудования с применением сварки элементов, работающих под давлением',
          'При проведении технических освидетельствований и технического диагностирования оборудования в случаях, установленных Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63693044',
        text:
          'Кем определяются тип арматуры, ее количество и место установки согласно требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Разработчиком проекта оборудования',
          'Монтажной организацией',
          'Эксплуатирующей организацией',
          'Специализированной экспертной организацией',
        ],
        correctAnswers: ['Разработчиком проекта оборудования'],
      },
      {
        code: '63693045',
        text:
          'С какой периодичностью проводится контроль степени затяжки пружин подвесок и опор трубопроводов в рабочем и холодном состоянии?',
        answers: [
          'Периодичность контроля устанавливается эксплуатирующей организацией',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в два года',
          'Степень затяжки пружин подвесок и опор должна контролироваться только при пуске трубопровода',
        ],
        correctAnswers: ['Не реже одного раза в два года'],
      },
      {
        code: '63693046',
        text:
          'Какая информация из приведенной не включается в маркировку, наносимую на оборудование, работающее под давлением?',
        answers: [
          'Наименование и (или) обозначение оборудования',
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
          'Параметры и характеристики, влияющие на безопасность',
          'Наименование материала, из которого изготовлено оборудование',
          'Вся приведенная информация включается в маркировку, наносимую на оборудование',
        ],
        correctAnswers: [
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63693047',
        text:
          'Что может применяться для обеспечения доступа к площадкам обслуживания оборудования под давлением, предназначенным для периодического проведения работ (плановое техническое обслуживание, ремонт) в местах расположения люков, арматуры и иных устройств, оборудованных автоматическим дистанционным приводом, не требующих постоянного (неоднократного) присутствия персонала?',
        answers: [
          'Переносные площадки',
          'Передвижные лестницы',
          'Стационарные лестницы с углом наклона к горизонтали более 50°',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63693048',
        text:
          'Кто осуществляет ведение ремонтного журнала (ремонтных журналов) в эксплуатирующей трубопроводы организации?',
        answers: [
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентировано; лицо, осуществляющее ведение ремонтного журнала, определяется распорядительным документом эксплуатирующей организации',
          'Уполномоченный представитель специализированной организации, осуществляющей ремонт трубопровода',
          'Лицо, ответственное за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
          'Лицо, ответственное за исправное состояние и безопасную эксплуатацию трубопроводов',
        ],
        correctAnswers: [
          'Лицо, ответственное за исправное состояние и безопасную эксплуатацию трубопроводов',
        ],
      },
      {
        code: '63693049',
        text:
          'Кем проводится техническое освидетельствование трубопроводов, не подлежащих учету в органах Ростехнадзора?',
        answers: [
          'Первичное и внеочередное техническое освидетельствование проводит специализированная уполномоченная организация, а периодическое техническое освидетельствование – ответственный за исправное состояние и безопасную эксплуатацию трубопровода',
          'Первичное, периодическое и внеочередное техническое освидетельствование проводит специализированная уполномоченная организация',
          'Первичное, периодическое и внеочередное техническое освидетельствование проводят ответственный за исправное состояние и безопасную эксплуатацию трубопровода',
          'Первичное, периодическое техническое освидетельствование проводит специализированная уполномоченная организация, а внеочередное освидетельствование – комиссия, назначенная эксплуатирующей организацией, с участием представителя территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Первичное, периодическое и внеочередное техническое освидетельствование проводят ответственный за исправное состояние и безопасную эксплуатацию трубопровода',
        ],
      },
      {
        code: '63693050',
        text:
          'Какому требованию должна отвечать тепловая изоляция трубопроводов, расположенных на открытом воздухе и вблизи масляных баков, маслопроводов, мазутопроводов?',
        answers: [
          'Тепловая изоляция на этих участках должна иметь металлическое или другое покрытие для предохранения ее от пропитывания влагой или горючими нефтепродуктами',
          'Тепловая изоляция на этих участках должна быть съемной',
          'Трубопроводы на этих участках должны кроме тепловой изоляции иметь гидроизоляцию',
          'На этих участках температура поверхности тепловой изоляции не должна превышать температуру 40 °С',
          'Тепловая изоляция должна соответствовать всем приведенным требованиям',
        ],
        correctAnswers: [
          'Тепловая изоляция на этих участках должна иметь металлическое или другое покрытие для предохранения ее от пропитывания влагой или горючими нефтепродуктами',
        ],
      },
      {
        code: '63693051',
        text:
          'Что из перечисленного может применяться в качестве предохранительных устройств на оборудовании, работающим под избыточным давлением?',
        answers: [
          'Рычажно-грузовые предохранительные клапаны прямого действия',
          'Пружинные предохранительные клапаны прямого действия',
          'Импульсные предохранительные устройства, состоящие из импульсного клапана и главного предохранительного клапана',
          'Предохранительные устройства с разрушающимися мембранами (мембранные предохранительные устройства)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63693052',
        text:
          'Какое из перечисленных требований к капиллярному и магнитопорошковому контролю указано верно?',
        answers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
          'При применении нескольких видов контроля в отношении одного объекта капиллярный контроль должен выполняться после проведения ультразвукового и магнитопорошкового контроля',
          'По результатам капиллярного контроля на поверхности сварных соединений и наплавок допускаются единичные и групповые индикаторные рисунки округлой или удлиненной форм',
          'По результатам контроля магнитопорошковым методом на поверхности сварных соединений и наплавок допускаются индикаторные рисунки осаждений магнитного порошка',
        ],
        correctAnswers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
        ],
      },
      {
        code: '63693053',
        text:
          'Каким документом подтверждается соответствие оборудования требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"? Выберите 2 варианта ответа.',
        answers: [
          'Декларация о соответствии',
          'Сертификат соответствия',
          'Протокол приемочных испытаний',
          'Паспорт оборудования, имеющий соответствующую отметку',
        ],
        correctAnswers: [
          'Декларация о соответствии',
          'Сертификат соответствия',
        ],
      },
      {
        code: '63693054',
        text:
          'Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией трубопроводов?',
        answers: [
          'Выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения',
          'Проверка записи в сменном журнале с росписью в нем',
          'Контроль проведения противоаварийных тренировок',
          'Отстранение от работ работников, нарушающих требования промышленной безопасности',
        ],
        correctAnswers: ['Проверка записи в сменном журнале с росписью в нем'],
      },
      {
        code: '63693055',
        text:
          'В каком из приведенных случаев до проверки знаний рабочий, обслуживающий трубопроводы, должен пройти стажировку?',
        answers: [
          'Перед первичным допуском к самостоятельной работе после профессионального обучения',
          'Перед допуском к самостоятельной работе после внеочередной проверки знаний',
          'При перерыве в работе по специальности более 12 месяцев',
          'Во всех приведенных случаях проводится стажировка',
          'Стажировка проводится во всех приведенных случаях, кроме перерыва в работе по специальности более 12 месяцев',
        ],
        correctAnswers: ['Во всех приведенных случаях проводится стажировка'],
      },
      {
        code: '63693056',
        text:
          'В каком случае исправность установленных на трубопроводе предохранительных клапанов осуществляется путем проверки срабатывания клапана на испытательных стендах?',
        answers: [
          'Исправность предохранительных клапанов проверяют только принудительным кратковременным их подрывом (открыванием); проверка на испытательных стендах проводится перед установкой клапанов на трубопроводе',
          'Если давление в трубопроводе превышает 3,5 МПа',
          'Если принудительное открывание клапана нежелательно по условиям технологического процесса',
          'Если предохранительные клапаны установлены на паропроводе высокого давления',
        ],
        correctAnswers: [
          'Если принудительное открывание клапана нежелательно по условиям технологического процесса',
        ],
      },
      {
        code: '63693057',
        text:
          'Каким документом определяется порядок действий в случае инцидента при эксплуатации трубопровода?',
        answers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Инструкцией (руководством) по эксплуатации трубопровода',
        ],
        correctAnswers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
        ],
      },
      {
        code: '63693058',
        text:
          'Какие из перечисленных сведений о результатах экспертизы промышленной безопасности, записываемые в паспорт оборудования, указаны неверно?',
        answers: [
          'ФИО экспертов, проводивших экспертизу промышленной безопасности',
          'Дату подписания заключения экспертизы промышленной безопасности',
          'Наименование организации, проводившей экспертизу промышленной безопасности',
          'Регистрационный номер по реестру заключений экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'ФИО экспертов, проводивших экспертизу промышленной безопасности',
        ],
      },
      {
        code: '63693059',
        text:
          'Манометры какого класса точности необходимо применять при эксплуатации трубопроводов с рабочим давлением не более 2,5 МПа?',
        answers: ['Не ниже 1,0', 'Не ниже 1,5', 'Не ниже 2,5', 'Не ниже 4,0'],
        correctAnswers: ['Не ниже 2,5'],
      },
      {
        code: '63693060',
        text: 'Каким образом должен устанавливаться манометр на трубопроводе?',
        answers: [
          'Шкала манометра должна располагаться вертикально или с наклоном вперед до 45°',
          'Шкала манометра должна располагаться с наклоном вперед от 30° до 45°',
          'Произвольно, так, чтобы показания манометра были отчетливо видны обслуживающему персоналу',
          'Шкала манометра должна быть расположена вертикально или с наклоном вперед до 30°',
        ],
        correctAnswers: [
          'Шкала манометра должна быть расположена вертикально или с наклоном вперед до 30°',
        ],
      },
      {
        code: '63693061',
        text:
          'В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?',
        answers: [
          'В случае применения таких труб только при монтаже, ремонте, реконструкции (модернизации) водогрейных котлов и трубопроводов горячей воды',
          'Если на стальные трубы оформлены документы, подтверждающие их соответствие и качество (сертификаты, декларации соответствия)',
          'Если срок эксплуатации стальных труб не превышает половины расчетного срока службы технического устройства, на котором эти трубы употреблялись',
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов',
        ],
        correctAnswers: [
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов',
        ],
      },
      {
        code: '63693062',
        text: 'Какую информацию должен включать в себя паспорт трубопровода?',
        answers: [
          'Наименование и адрес предприятия-владельца',
          'Рабочая среда',
          'Расчетное количество пусков',
          'Расчетный срок службы',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63693063',
        text:
          'Чему равно минимальное значение уклона, который должны иметь горизонтальные участки трубопроводов тепловых сетей?',
        answers: ['0,001', '0,002', '0,003', '0,004'],
        correctAnswers: ['0,002'],
      },
      {
        code: '63693064',
        text:
          'В каком из приведенных случаев должно проводиться внеочередное техническое освидетельствование паропровода?',
        answers: [
          'Если паропровод не эксплуатируется более 24 месяцев',
          'Если проведен восстановительный ремонт паропровода после аварии с заменой поврежденного в результате аварии участка паропровода',
          'По решению руководителя экспертной организации, согласованному с руководителем эксплуатирующей паропровод организации',
          'Во всех указанных случаях',
        ],
        correctAnswers: ['Если паропровод не эксплуатируется более 24 месяцев'],
      },
      {
        code: '63693065',
        text:
          'Какие из приведенных сведений вносятся в ремонтный журнал (ремонтные журналы)?',
        answers: [
          'Сведения о всех выполненных ремонтных работах, в том числе не вызывающих необходимости внеочередного технического освидетельствования',
          'Сведения о выполненных ремонтных работах, вызывающих необходимость проведения внеочередного освидетельствования',
          'Сведения о материалах, использованных при ремонтных работах',
          'Сведения о документах, подтверждающих качество сварки',
          'Все приведенные сведения вносятся в ремонтный журнал',
        ],
        correctAnswers: [
          'Сведения о всех выполненных ремонтных работах, в том числе не вызывающих необходимости внеочередного технического освидетельствования',
        ],
      },
      {
        code: '63693066',
        text:
          'Для какой арматуры трубопроводов пара и горячей воды должен быть предусмотрен электро-, гидро- или пневмопривод?',
        answers: [
          'Для задвижек и затворов диаметром 500 мм и более',
          'Для задвижек паропроводов диаметром 100 мм и более',
          'Для задвижек и затворов трубопроводов тепловых сетей, проложенных в непроходных и полупроходных каналах независимо от диаметра',
          'Для задвижек трубопроводов при наземной прокладке тепловых сетей диаметром более 300 мм',
        ],
        correctAnswers: ['Для задвижек и затворов диаметром 500 мм и более'],
      },
      {
        code: '63693067',
        text:
          'Манометры какого класса точности необходимо применять при эксплуатации трубопроводов с рабочим давлением более 14 МПа?',
        answers: ['Не ниже 1,0', 'Не ниже 1,5', 'Не ниже 2,5', 'Не ниже 4,0'],
        correctAnswers: ['Не ниже 1,0'],
      },
      {
        code: '63693068',
        text:
          'Какое из приведенных требований к оснащению трубопроводов съемной тепловой изоляцией указано неверно?',
        answers: [
          'Тепловая изоляция фланцевых соединений, арматуры, подвергающихся периодическому контролю (сварные соединения, бобышки для измерения ползучести), должна быть съёмной',
          'Съемную изоляцию должны иметь участки трубопровода, на которых проведены сварные соединения',
          'Съемную изоляцию должны иметь трубопроводы, расположенные на открытом воздухе',
          'Все указанные требования верны',
        ],
        correctAnswers: [
          'Съемную изоляцию должны иметь трубопроводы, расположенные на открытом воздухе',
        ],
      },
      {
        code: '63693069',
        text:
          'Какое из приведенных испытаний не является обязательным видом механических испытаний котлов (трубопроводов)?',
        answers: [
          'Испытание на ударный изгиб',
          'Испытание на статический изгиб',
          'Испытание на статическое растяжение',
        ],
        correctAnswers: ['Испытание на ударный изгиб'],
      },
      {
        code: '63693070',
        text: 'Какое из приведенных требований к манометру указано неверно?',
        answers: [
          'Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы',
          'На шкале манометра должна быть нанесена красная черта, указывающая допустимое давление',
          'Взамен красной черты разрешается в качестве указателя значения максимально допустимого давления прикреплять к корпусу манометра пластину (скобу) из металла или иного материала достаточной прочности, окрашенную в красный цвет и плотно прилегающую к стеклу манометра',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Шкалу манометров выбирают из условия, чтобы при рабочем давлении стрелка манометра находилась либо в первой, либо во второй трети шкалы',
        ],
      },
      {
        code: '63693071',
        text:
          'В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов, установленных на тепловых электростанциях?',
        answers: [
          'Для трубопроводов с рабочим давлением не более 1,4 МПа – не реже одного раза в смену, а для остальных трубопроводов - не реже одного раза в сутки',
          'Для трубопроводов горячей воды – не реже одного раза в сутки, а для паропроводов – не реже одного раза в смену',
          'Для всех трубопроводов, установленных на тепловых электростанциях, проверка осуществляется каждые два часа',
          'Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Сроки устанавливаются эксплуатирующей организацией и указываются в инструкции, которая утверждается техническим руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63693072',
        text:
          'В каком из приведенных случаев допускается одному специалисту совмещать ответственность за осуществление производственного контроля за безопасной эксплуатацией трубопроводов и ответственность за их исправное состояние и безопасную эксплуатацию?',
        answers: [
          'Случаи совмещения обязанностей определяются самостоятельно эксплуатирующей организацией в соответствии с ее распорядительными документами',
          'Если трубопроводы эксплуатируются не более чем на двух производственных площадках',
          'Если это совмещение согласовано с территориальным органом Ростехнадзора',
          'Совмещение не допускается',
        ],
        correctAnswers: ['Совмещение не допускается'],
      },
      {
        code: '63693073',
        text:
          'Какое требование к проведению проверки манометров, установленных на трубопроводе, указано неверно?',
        answers: [
          'Проверку исправности манометра обслуживающий персонал в процессе эксплуатации трубопровода производит с периодичностью, установленной в производственной инструкции, с помощью трехходового крана или заменяющих его запорных устройств путем установки стрелки манометра на нуль',
          'Эксплуатирующая организация обязана не реже одного раза в шесть месяцев проводить дополнительную проверку рабочих манометров контрольным с записью результатов в журнал контрольных проверок манометров',
          'Не реже одного раза в 12 месяцев (если иные сроки не установлены документацией на манометр) манометры должны быть поверены, и на каждом из них должны быть установлены клеймо или пломба',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Эксплуатирующая организация обязана не реже одного раза в шесть месяцев проводить дополнительную проверку рабочих манометров контрольным с записью результатов в журнал контрольных проверок манометров',
        ],
      },
      {
        code: '63693074',
        text:
          'Что из приведенного не входит в должностные обязанности ответственного за исправное состояние и безопасную эксплуатацию трубопроводов?',
        answers: [
          'Контроль своевременности и полноты проведения ремонта трубопроводов',
          'Осмотр трубопроводов с определенной должностной инструкцией периодичностью',
          'Проведение противоаварийных тренировок с обслуживающим персоналом',
          'Подготовка трубопровода к техническому освидетельствованию',
        ],
        correctAnswers: [
          'Контроль своевременности и полноты проведения ремонта трубопроводов',
        ],
      },
      {
        code: '63693075',
        text:
          'С какой периодичностью уполномоченная специализированная организация проводит периодическое освидетельствование трубопроводов пара и горячей воды, подлежащих учету в территориальных органах Ростехнадзора?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63693076',
        text:
          'Какие условия должны соблюдаться при установлении срока следующего периодического технического освидетельствования котла и трубопровода?',
        answers: [
          'Срок следующего периодического технического освидетельствования устанавливается только экспертной организацией, если этот срок не установлен изготовителем в руководстве (инструкции) по эксплуатации',
          'Срок следующего периодического технического освидетельствования котла может превышать, но не более чем на 2 года, срок его службы, установленный изготовителем или заключением экспертизы промышленной безопасности, оформленным по результатам технического диагностирования при продлении срока службы трубопровода',
          'Срок следующего периодического технического освидетельствования не должен превышать срока службы, установленного изготовителем или заключением экспертизы промышленной безопасности, оформленным по результатам технического диагностирования при продлении срока службы трубопровода',
        ],
        correctAnswers: [
          'Срок следующего периодического технического освидетельствования не должен превышать срока службы, установленного изготовителем или заключением экспертизы промышленной безопасности, оформленным по результатам технического диагностирования при продлении срока службы трубопровода',
        ],
      },
      {
        code: '63693077',
        text:
          'Чему равна минимальная величина пробного давления при гидравлическом испытании трубопроводов пара и горячей воды?',
        answers: [
          '1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа',
          '1,5 рабочего давления',
          '1,25 расчетного давления',
          '0,5 МПа',
        ],
        correctAnswers: [
          '1,25 рабочего давления (указанного в паспорте организацией-изготовителем или по результатам первичного технического освидетельствования), но не менее 0,2 МПа',
        ],
      },
      {
        code: '63693078',
        text:
          'Каково минимальное значение номинального диаметра корпуса манометра, установленного на трубопроводе более 5 м от уровня площадки наблюдения за манометром?',
        answers: [
          '250 мм',
          '300 мм',
          '350 мм',
          'Не регламентируется, при этом должен быть установлен сниженный манометр в качестве дублирующего',
        ],
        correctAnswers: [
          'Не регламентируется, при этом должен быть установлен сниженный манометр в качестве дублирующего',
        ],
      },
      {
        code: '63693079',
        text:
          'Какое из приведенных требований должно выполняться при проведении гидравлического испытания трубопровода?',
        answers: [
          'При появлении в период подъема давления шума, стуков следует снизить скорость подъема давления, при которой шумы и стуки прекратятся',
          'Подъем давления до значения пробного давления должен быть медленным и плавным, без толчков. Время подъема давления должно быть не менее 5 мин',
          'Если в результате заполнения трубопровода водой на его стенках появится роса, то испытание следует немедленно прекратить',
          'Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления',
        ],
        correctAnswers: [
          'Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 1,5) и цены деления',
        ],
      },
      {
        code: '63693080',
        text:
          'При установке средства измерения давления на какой высоте должно предусматриваться дублирующее средство измерения давления?',
        answers: ['Более 5 м', 'Более 3 м', 'Более 2 м', 'Более 2,5 м'],
        correctAnswers: ['Более 5 м'],
      },
      {
        code: '63693081',
        text:
          'При применении какого минимального количества методов неразрушающего контроля не подвергаются гидравлическому испытанию пароперепускные трубопроводы в пределах турбин и трубопроводы отбора пара от турбины до задвижки?',
        answers: ['Двух', 'Трех', 'Четырех', 'Не регламентируется'],
        correctAnswers: ['Двух'],
      },
      {
        code: '63693082',
        text:
          'Что контролируется при проведении проверки готовности трубопровода к пуску в работу? Укажите все правильные ответы.',
        answers: [
          'Наличие документации, удостоверяющей качество монтажа трубопровода',
          'Наличие в соответствии с проектом и исправность арматуры, контрольно-измерительных приборов, приборов безопасности',
          'Наличие производственных инструкций для обслуживающего персонала, а также эксплуатационной документации',
          'Наличие обученного и допущенного в установленном порядке к работе обслуживающего персонала и аттестованных в установленном порядке специалистов',
        ],
        correctAnswers: [
          'Наличие документации, удостоверяющей качество монтажа трубопровода',
          'Наличие в соответствии с проектом и исправность арматуры, контрольно-измерительных приборов, приборов безопасности',
        ],
      },
      {
        code: '63693083',
        text:
          'В каком из приведенных случаев в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, трубопровод не подлежит аварийной остановке?',
        answers: [
          'При выявлении неисправности предохранительного устройства трубопровода горячей воды',
          'При неисправности манометра на трубопроводе насыщенного пара, оснащенном прибором для измерения температуры',
          'При неисправности предохранительных блокировочных устройств',
          'При возникновении пожара, непосредственно угрожающего трубопроводу горячей воды',
        ],
        correctAnswers: [
          'При неисправности манометра на трубопроводе насыщенного пара, оснащенном прибором для измерения температуры',
        ],
      },
      {
        code: '63693084',
        text:
          'Какое из приведенных требований к дренажным системам трубопроводов пара и горячей воды указано неверно?',
        answers: [
          'При объединении дренажных линий нескольких трубопроводов допускается установка запорной арматуры на общем дренажном трубопроводе',
          'Система дренажей должна обеспечивать полное удаление влаги при прогреве, остывании и опорожнении трубопроводов',
          'При прокладке дренажных линий должно быть учтено направление тепловых перемещений во избежание защемления трубопроводов',
          'Все приведенные требования верны',
        ],
        correctAnswers: [
          'При объединении дренажных линий нескольких трубопроводов допускается установка запорной арматуры на общем дренажном трубопроводе',
        ],
      },
      {
        code: '63693085',
        text:
          'Какое количество категорий оборудования существует в зависимости от вместимости или номинального диаметра, а также максимально допустимого рабочего давления согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: ['4', '3', '2', '6'],
        correctAnswers: ['4'],
      },
      {
        code: '63693086',
        text:
          'Какой документ является основным для идентификации оборудования, работающего под избыточным давлением?',
        answers: [
          'Сертификат соответствия оборудования требованиям ТР ТС 032/2013',
          'Обоснование безопасности оборудования',
          'Паспорт оборудования',
          'Декларация соответствия оборудования требованиям промышленной',
        ],
        correctAnswers: ['Паспорт оборудования'],
      },
      {
        code: '63693087',
        text:
          'С какой периодичностью проводится проверка знаний рабочих, обслуживающих трубопроводы?',
        answers: [
          'Периодичность устанавливается эксплуатирующей организацией',
          'Один раз в 12 месяцев',
          'Один раз в 6 месяцев',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63693088',
        text:
          'В каком случае необходимо проводить металлографические исследования?',
        answers: [
          'Все ответы неверны',
          'Для сварных соединений сосудов и их элементов, изготовленных из сталей аустенитного класса, толщиной не более 20 мм',
          'Для сварных соединений котлов и трубопроводов, изготовленных из стали перлитного класса, при условии проведения ультразвукового или радиографического контроля этих соединений в объеме 100%',
          'Для сварных соединений труб поверхностей нагрева котлов и трубопроводов, выполненных контактной сваркой на специальных машинах для контактной стыковой сварки с автоматизированным циклом работ при ежесменной проверке качества наладки машины путем испытания контрольных образцов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63693089',
        text:
          'Сколько раз разрешается проводить исправление дефектов на одном и том же участке сварного соединения?',
        answers: [
          'Не более трех раз',
          'Не более двух раз',
          'Не более четырех раз',
          'Один раз',
        ],
        correctAnswers: ['Не более трех раз'],
      },
      {
        code: '63693090',
        text:
          'Кого должна уведомлять организация, проводившая техническое освидетельствование, о факте выявления дефектов, возникновение которых обусловлено особенностями (недостатками) конструкции оборудования?',
        answers: [
          'Всех перечисленных',
          'Организацию-изготовителя этого оборудования или уполномоченное организацией-изготовителем лицо',
          'Ростехнадзор',
          'Организацию, оформившую документ о подтверждении соответствия этого оборудования требованиям ТР ТС 032/2013',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63693091',
        text:
          'Какой из перечисленных терминов соответствует определению "избыточное давление, при котором производится испытание оборудования на прочность и плотность"?',
        answers: [
          'Давление пробное',
          'Давление рабочее',
          'Давление разрешенное',
          'Давление расчетное',
        ],
        correctAnswers: ['Давление пробное'],
      },
      {
        code: '63693092',
        text:
          'Что необходимо предпринять, если при техническом освидетельствовании будет установлено, что трубопровод вследствие имеющихся дефектов или нарушений находится в состоянии, опасном для дальнейшей его эксплуатации?',
        answers: [
          'До устранения дефектов и нарушений перевести трубопровод в режим работы на пониженных параметрах (давление, температура)',
          'Работа такого трубопровода должна быть запрещена',
          'Перевести трубопровод в режим работы на пониженных параметрах (давление, температура) и провести экспертизу промышленной безопасности трубопровода',
        ],
        correctAnswers: ['Работа такого трубопровода должна быть запрещена'],
      },
      {
        code: '63693093',
        text:
          'Какое требование к специалистам и рабочим, обслуживающим трубопроводы, указано неверно?',
        answers: [
          'Рабочие должны быть не моложе 18 летнего возраста и не иметь медицинских противопоказаний для выполнения работ по обслуживанию трубопроводов',
          'Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации',
          'Рабочие должны быть допущены в установленном порядке к самостоятельной работе',
          'Рабочие должны соответствовать квалификационным требованиям',
        ],
        correctAnswers: [
          'Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации',
        ],
      },
      {
        code: '63693094',
        text:
          'Каким образом должны быть рассчитаны и отрегулированы предохранительные устройства, установленные на трубопроводе с разрешенным давлением свыше 0,5 МПа?',
        answers: [
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем на 10 %',
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем на 15 %',
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,05 МПа',
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,1 МПа',
        ],
        correctAnswers: [
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем на 10 %',
        ],
      },
      {
        code: '63693095',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'При реконструкции (модернизации) тепловой сети ОПО, отнесенного к III классу опасности',
          'При техническом освидетельствовании трубопровода горячей воды',
          'При пусконаладочных работах на трубопроводе пара',
          'При проектировании пароперегревателей трубчатых печей',
        ],
        correctAnswers: [
          'При проектировании пароперегревателей трубчатых печей',
        ],
      },
      {
        code: '63693096',
        text:
          'Какой документацией устанавливаются необходимость, объем и порядок механических испытаний сварных соединений литых и кованых элементов, труб с литыми деталями, элементов из сталей различных классов, а также других единичных сварных соединений?',
        answers: [
          'Проектной и технологической документацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Производственными инструкциями',
          'Распорядительной документацией эксплуатирующей организации',
        ],
        correctAnswers: ['Проектной и технологической документацией'],
      },
      {
        code: '63693097',
        text:
          'На каких теплосетях задвижки и затворы должны иметь обводные трубопроводы (байпасы) с запорной арматурой?',
        answers: [
          'На водяных тепловых сетях внутренним диаметром 500 мм и более при рабочем давлении 1,6 МПа и более',
          'На водяных тепловых сетях внутренним диаметром 300 мм и более при рабочем давлении 2,5 МПа и более',
          'На паровых сетях внутренним диаметром 200 мм и более при рабочем давлении 1,6 МПа и более',
          'На всех перечисленных',
        ],
        correctAnswers: ['На всех перечисленных'],
      },
      {
        code: '63693098',
        text:
          'Какие условия должна обеспечивать тепловая изоляция трубопроводов и арматуры?',
        answers: [
          'Максимальное снижение потерь тепла от поверхностей с повышенной температурой в окружающую среду',
          'Температуру поверхности изоляции, не превышающую 60 °С',
          'Температуру поверхности изоляции не более 55 °С при температуре окружающего воздуха 25 °С',
        ],
        correctAnswers: [
          'Температуру поверхности изоляции не более 55 °С при температуре окружающего воздуха 25 °С',
        ],
      },
      {
        code: '63693099',
        text:
          'В каком из приведенных случаев трубопровод считается выдержавшим гидравлическое испытание?',
        answers: [
          'Если обнаружены единичные трещины не в сварных соединениях, а на основном металле',
          'Если обнаружено падение давления по манометру, не превышающее 20 % от значения пробного давления',
          'Если в сварном соединении обнаружено появление отдельных капель воды',
          'Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание',
        ],
        correctAnswers: [
          'Во всех приведенных случаях трубопровод не считается выдержавшим гидравлическое испытание',
        ],
      },
      {
        code: '63693100',
        text:
          'Кем определяется достаточная для обеспечения безопасной эксплуатации ОПО численность инженерно-технических работников?',
        answers: [
          'Эксплуатирующей организацией',
          'Ростехнадзором',
          'Проектной организацией',
          'Научно-исследовательской организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63693101',
        text:
          'С какой периодичностью проводится проверка знаний требований производственных инструкций и (или) инструкций для данной профессии у рабочих?',
        answers: [
          'Один раз в 12 месяцев',
          'Один раз в 6 месяцев',
          'Один раз в месяц',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63693102',
        text:
          'В каком документе даются указания по выводу из эксплуатации и утилизации оборудования, работающего под давлением?',
        answers: [
          'Руководстве (инструкции) по эксплуатации',
          'Заключении по результатам технического диагностирования оборудования',
          'Типовой инструкции по утилизации оборудования, работающего под давлением, используемого на опасном производственном объекте',
          'Документации эксплуатирующей организации, прошедшей экспертизу промышленной безопасности',
        ],
        correctAnswers: ['Руководстве (инструкции) по эксплуатации'],
      },
      {
        code: '63693103',
        text:
          'В каких целях проводится магнитопорошковый контроль сварных соединений?',
        answers: [
          'Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях',
          'Для подтверждения соответствия легирования металла сварных швов и элементов оборудования',
          'Для проверки качества выполнения термической обработки сварных соединений',
        ],
        correctAnswers: [
          'Для определения поверхностных и подповерхностных дефектов в стальных ферромагнитных конструкциях и деталях',
        ],
      },
      {
        code: '63693104',
        text:
          'Какое количество образцов должно быть вырезано из каждого контрольного стыкового сварного соединения для металлографических исследований при контроле сварных соединений из углеродистой и низколегированной стали?',
        answers: ['Один', 'Не менее двух', 'Не менее трех', 'Не менее четырех'],
        correctAnswers: ['Один'],
      },
      {
        code: '63693105',
        text:
          'Где должны быть установлены методы и объемы контроля сварных соединений приварных деталей, не работающих под внутренним давлением?',
        answers: [
          'Сварные соединения приварных деталей, не работающих под внутренним давлением, не подлежат контролю',
          'В руководстве (инструкции) по эксплуатации',
          'Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется',
          'В технологической документации',
        ],
        correctAnswers: ['В технологической документации'],
      },
      {
        code: '63693106',
        text:
          'В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов с рабочим давлением до 1,4 МПа?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в сутки',
          'Сроки устанавливаются эксплуатирующей организацией и указываются в графике проверки, который утверждается техническим руководителем эксплуатирующей организации',
          'Проверка манометров проводится не реже одного раза в смену; проверка предохранительных клапанов – не реже одного раза в сутки',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63693107',
        text:
          'Какой должен быть класс точности средства измерения давления при рабочем давлении от 2,5 до 14 МПа включительно?',
        answers: ['Не ниже 1,5', 'Не ниже 2,5', 'Не ниже 1'],
        correctAnswers: ['Не ниже 1,5'],
      },
      {
        code: '63693108',
        text:
          'Каково минимальное значение номинального диаметра корпуса манометра, установленного на трубопроводе на высоте от 2 до 3 м от уровня площадки наблюдения за манометром?',
        answers: ['50 мм', '100 мм', '160 мм', '4.250 мм'],
        correctAnswers: ['160 мм'],
      },
      {
        code: '63693109',
        text:
          'Какой трубопровод из приведенных не подлежит учету в органах Ростехнадзора?',
        answers: [
          'Трубопровод горячей воды тепловой сети в составе ОПО III класса опасности',
          'Паропровод давлением не более 1,6 МПа с условным проходом более 100 мм, расположенный в пределах здания производственного помещения',
          'Трубопроводы пара и горячей воды, у которых параметры рабочей среды не превышают температуру 250 °С и давление 1,6 МПа, с внутренним диаметром 100 мм и менее',
          'Все приведенные трубопроводы не подлежат учету в органах Ростехнадзора',
        ],
        correctAnswers: [
          'Трубопроводы пара и горячей воды, у которых параметры рабочей среды не превышают температуру 250 °С и давление 1,6 МПа, с внутренним диаметром 100 мм и менее',
        ],
      },
      {
        code: '63693110',
        text:
          'Для проведения испытания трубопроводов, расположенных на какой высоте от уровня земли (пола) и стационарных площадок, должны применяться леса, подмостки, иные приспособления и устройства, обеспечивающие возможность безопасного осмотра трубопровода?',
        answers: [
          'На высоте свыше 3 м',
          'На высоте свыше 1,5 м',
          'На высоте свыше 2 м',
          'На высоте свыше 2,5 м',
        ],
        correctAnswers: ['На высоте свыше 3 м'],
      },
      {
        code: '63693111',
        text:
          'Манометры какого класса точности необходимо применять при эксплуатации трубопроводов с рабочим давлением более 2,5 до 14 МПа?',
        answers: ['Не ниже 1,0', 'Не ниже 1,5', 'Не ниже 2,5', 'Не ниже 4,0'],
        correctAnswers: ['Не ниже 1,5'],
      },
      {
        code: '63693112',
        text:
          'Какое из приведенных требований к проверке знаний рабочих, обслуживающих трубопроводы, указано неверно?',
        answers: [
          'Результаты проверки знаний рабочих оформляют протоколом с отметкой в удостоверении о допуске к самостоятельной работе',
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих трубопроводы',
          'Внеочередная проверка знаний проводится при переходе рабочего в другую организацию',
          'В случае внесения изменений в технологический процесс и инструкции должна быть проведена внеочередная проверка знаний',
        ],
        correctAnswers: [
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих трубопроводы',
        ],
      },
      {
        code: '63693113',
        text:
          'В каком из приведенных случаев проверка готовности трубопровода к пуску в работу и проверка организации надзора за эксплуатацией котла осуществляется только комиссией, назначаемой приказом эксплуатирующей организации?',
        answers: [
          'До начала применения транспортабельного трубопровода',
          'После монтажа трубопровода, который подлежит подтверждению соответствия требованиям ТР ТС 032/2013',
          'После капитального ремонта трубопровода с заменой его участков',
          'После монтажа без применения неразъемных соединений трубопровода, демонтированного и установленного на новом месте',
        ],
        correctAnswers: [
          'После капитального ремонта трубопровода с заменой его участков',
        ],
      },
      {
        code: '63693114',
        text:
          'Каково минимальное значение высоты тоннеля (коллектора) и ширины прохода между изолированными трубопроводами пара и горячей воды при их прокладке в проходных тоннелях (коллекторах)?',
        answers: [
          'Высота тоннеля (коллектора) в свету 1,8 метра, ширина прохода не нормируется',
          'Высота тоннеля (коллектора) не нормируется, ширина прохода 0,7 метра',
          'Высота тоннеля (коллектора) в свету 2 метра, ширина прохода 0,7 метра',
          'Высота тоннеля (коллектора) в свету 1,5 метра, ширина прохода 0,5 метра',
        ],
        correctAnswers: [
          'Высота тоннеля (коллектора) в свету 2 метра, ширина прохода 0,7 метра',
        ],
      },
      {
        code: '63693115',
        text:
          'В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах параллельно с рычажно-грузовым или пружинным предохранительным клапаном?',
        answers: [
          'Если необходимо увеличение пропускной способности систем сброса давления - рычажно-грузового (пружинного) предохранительного клапана',
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
          'Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительных клапанов',
          'Установка мембранных предохранительных устройств параллельно с рычажно-грузовыми или пружинными предохранительными клапанами не допускается',
        ],
        correctAnswers: [
          'Если необходимо увеличение пропускной способности систем сброса давления - рычажно-грузового (пружинного) предохранительного клапана',
        ],
      },
      {
        code: '63693116',
        text:
          'Какие меры для обеспечения безопасности должны приниматься при эксплуатации трубопровода, расчетное давление и разрешенное рабочее давление которого меньше давления питающего его источника?',
        answers: [
          'Установка перед защищаемым элементом дополнительно манометра и пружинного предохранительного клапана, отрегулированного на давление, на 10 % превышающее разрешенное',
          'Установка автоматического редуцирующего устройства с манометром и предохранительным клапаном на стороне высокого давления',
          'Установка редуцирующего устройства или редукционно-охладительной установки (при необходимости регулирования давления и температуры) с манометром и предохранительным устройством, установленными на стороне меньшего давления после редуцирующего устройства',
          'Не допускается эксплуатация трубопровода, расчетное давление и разрешенное рабочее давление которого меньше давления питающего его источника, без предварительного снижения параметров рабочей среды до разрешенных параметров перед направлением ее в трубопровод',
        ],
        correctAnswers: [
          'Установка редуцирующего устройства или редукционно-охладительной установки (при необходимости регулирования давления и температуры) с манометром и предохранительным устройством, установленными на стороне меньшего давления после редуцирующего устройства',
        ],
      },
      {
        code: '63693117',
        text:
          'Выполнение каких из перечисленных процедур не предусматривается при проверке знаний и допуске работника к самостоятельной работе?',
        answers: [
          'Предусматривается выполнение всех перечисленных процедур',
          'Проведение вводного инструктажа',
          'Проведение первичного инструктажа на рабочем месте',
          'Проверка знаний инструкций',
          'Допуск к самостоятельной работе с выдачей удостоверения',
        ],
        correctAnswers: [
          'Предусматривается выполнение всех перечисленных процедур',
        ],
      },
      {
        code: '63693118',
        text:
          'В каком из перечисленных случаев должно быть вырезано три образца из каждого контрольного стыкового сварного соединения?',
        answers: [
          'Для испытания на ударный изгиб',
          'Для испытания на статическое растяжение',
          'Для испытаний на статический изгиб или сплющивание',
          'Для испытаний на стойкость против межкристаллитной коррозии',
        ],
        correctAnswers: ['Для испытания на ударный изгиб'],
      },
      {
        code: '63693119',
        text:
          'В каком случае перед манометром на трубопроводе должна устанавливаться сифонная трубка?',
        answers: [
          'Если манометр предназначен для измерения давления пара',
          'Если манометр не оснащается трехходовым краном',
          'Каждый манометр обязательно должен оснащаться сифонной трубкой',
          'Если манометр предназначен для измерения давления воды',
        ],
        correctAnswers: [
          'Если манометр предназначен для измерения давления пара',
        ],
      },
      {
        code: '63693120',
        text:
          'Какие из перечисленных сведений для работников, занятых эксплуатацией оборудования под давлением, не должны быть указаны в инструкциях, устанавливающих действия работников в аварийных ситуациях (в том числе при аварии)?',
        answers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
          'Места отключения вводов электропитания',
          'Места расположения аптечек первой помощи',
          'Способы и методы ликвидации аварий',
        ],
        correctAnswers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
        ],
      },
      {
        code: '63693121',
        text:
          'Чему равно минимальное время выдержки трубопроводов пара и горячей воды под пробным давлением?',
        answers: [
          '10 минут',
          '5 минут',
          '30 минут',
          'Соответствует времени снижения давления в испытуемом трубопроводе (по манометру) на 0,1 МПа',
        ],
        correctAnswers: ['10 минут'],
      },
      {
        code: '63693122',
        text:
          'Кому должно направить лицо, проводившее техническое освидетельствование, информацию о необходимости проведения внеочередного технического освидетельствования всего оборудования под давлением, эксплуатируемого в аналогичном режиме, в случае если при анализе (оценке характера, размеров и причин возникновения) дефектов, выявленных при техническом освидетельствовании оборудования под давлением, установлено, что их возникновение обусловлено режимом эксплуатации оборудования в данной эксплуатирующей организации или особенностями (недостатками) конструкции данного типа оборудования?',
        answers: [
          'Руководителю эксплуатирующей организации',
          'В территориальный орган Ростехнадзора',
          'В организацию-изготовителя этого оборудования',
          'В организацию, оформившую документ о подтверждении соответствия этого оборудования требованиям ТР ТС 032/2013',
        ],
        correctAnswers: ['Руководителю эксплуатирующей организации'],
      },
      {
        code: '63693123',
        text:
          'Какие виды контроля качества сварных соединений и материалов должны предшествовать контролю другими методами? Выберите 2 варианта ответа.',
        answers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
          'Проникающими веществами',
          'Магнитопорошковый контроль',
          'Вихретоковый контроль',
        ],
        correctAnswers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
        ],
      },
      {
        code: '63693124',
        text:
          'Каким образом должны быть рассчитаны и отрегулированы предохранительные устройства, установленные на трубопроводе с разрешенным давлением до 0,5 МПа?',
        answers: [
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем на 10 %',
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем на 15 %',
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,05 МПа',
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,1 МПа',
        ],
        correctAnswers: [
          'Чтобы давление в защищаемом элементе не превышало разрешенное более чем 0,05 МПа',
        ],
      },
      {
        code: '63693125',
        text:
          'Какие требования к антикоррозионной, тепловой и гидроизоляционной защите, предусматриваемой на всех трубопроводах тепловых сетей вне зависимости от типа прокладки, указаны неверно?',
        answers: [
          'Тип и способы защиты должны определяться проектной документацией в зависимости от условий эксплуатации, конструктивного исполнения, с учетом скорости коррозионного износа применяемых материалов',
          'Порядок контроля степени коррозионного износа оборудования и трубопроводов с использованием неразрушающих методов, способы, периодичность и места проведения контрольных замеров должны определяться в проектной, технической и эксплуатационной документации с учетом конкретных условий эксплуатации',
          'Антикоррозионные покрытия трубопроводов тепловых сетей и их несущих металлических конструкций должны выполняться с защитными свойствами, обеспечивающими установленный срок службы трубопровода (конструкции), и гарантированным сроком службы покрытия не менее 5 лет',
          'Ввод в эксплуатацию тепловых сетей без наружного антикоррозионного покрытия труб и металлических конструкций не допускается',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Антикоррозионные покрытия трубопроводов тепловых сетей и их несущих металлических конструкций должны выполняться с защитными свойствами, обеспечивающими установленный срок службы трубопровода (конструкции), и гарантированным сроком службы покрытия не менее 5 лет',
        ],
      },
      {
        code: '63693126',
        text:
          'Если при проведении технического диагностирования установлено, что выявленные при техническом освидетельствовании дефекты снижают прочность трубопровода, то в каком из приведенных случаев допускается эксплуатация трубопровода на пониженных параметрах (давление, температура)?',
        answers: [
          'При условии, что возможность безопасной эксплуатации на пониженных параметрах до устранения дефектов (ремонт, замена) допускается технологическим процессом, подтверждена расчетом на прочность с учетом характера и размеров дефектов, а также проведена проверка пропускной способности предохранительных клапанов соответствующим расчетом и их перенастройка (с учетом пониженных параметров)',
          'Возможность безопасной эксплуатации до следующего освидетельствования должна быть подтверждена расчетом на прочность с учетом характера и размеров дефектов, а также должна быть проведена перенастройка предохранительных клапанов (с учетом пониженных параметров)',
          'Возможность безопасной эксплуатации до устранения дефектов (ремонт, замена) должна быть подтверждена проверкой пропускной способности предохранительных клапанов соответствующим расчетом и их перенастройкой (с учетом пониженных параметров)',
          'В данном случае эксплуатация трубопровода на пониженных параметрах (давление, температура) до полного устранения выявленных дефектов не допускается',
        ],
        correctAnswers: [
          'При условии, что возможность безопасной эксплуатации на пониженных параметрах до устранения дефектов (ремонт, замена) допускается технологическим процессом, подтверждена расчетом на прочность с учетом характера и размеров дефектов, а также проведена проверка пропускной способности предохранительных клапанов соответствующим расчетом и их перенастройка (с учетом пониженных параметров)',
        ],
      },
      {
        code: '63693127',
        text:
          'Каким путем проводится оценка риска для идентифицированных видов опасности при проектировании оборудования согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Экспериментальным',
          'Экспертным',
          'По данным эксплуатации аналогичных видов оборудования',
          'Расчетным',
          'Всеми перечисленными путями',
        ],
        correctAnswers: ['Всеми перечисленными путями'],
      },
      {
        code: '63693128',
        text:
          'Кто может записывать в паспорт оборудования сведения о результатах и причинах проведения технического диагностирования, неразрушающего и разрушающего контроля? Выберите 2 варианта ответа.',
        answers: [
          'Уполномоченный представитель организации, их проводившей',
          'Специалист эксплуатирующей организации, ответственный за исправное состояние и безопасную эксплуатацию оборудования',
          'Инспектор Ростехнадзора',
          'Технический руководитель эксплуатирующей организации',
        ],
        correctAnswers: [
          'Уполномоченный представитель организации, их проводившей',
          'Специалист эксплуатирующей организации, ответственный за исправное состояние и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63693129',
        text:
          'Каково минимальное значение температуры воды, используемой для гидравлического испытания трубопровода (если конкретное значение не указано в технической документации организации-изготовителя)?',
        answers: ['80 °С', '5 °C', '10 °С', '20 °С'],
        correctAnswers: ['5 °C'],
      },
      {
        code: '63693130',
        text:
          'Какое из приведенных требований должно выполняться при проведении визуального и измерительного контроля?',
        answers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
          'В случае невозможности контроля сварного соединения с двух сторон его контроль должен быть проведен в порядке, предусмотренном технологической документацией на сварку',
          'Перед визуальным контролем поверхности сварного шва и прилегающих к нему участков основного металла шириной не менее 10 мм в обе стороны от шва должны быть зачищены от шлака и других загрязнений',
        ],
        correctAnswers: [
          'Поверхностные дефекты, выявленные при визуальном и измерительном контроле, должны быть исправлены до проведения контроля другими неразрушающими методами',
        ],
      },
      {
        code: '63693131',
        text:
          'Какой срок действия сертификата соответствия оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" при использовании схемы 1с?',
        answers: [
          '5 лет',
          '3 года',
          '1 год',
          'В течение назначенного срока службы или назначенного ресурса',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63693132',
        text:
          'Какая процедура из указанных при холодном натяге трубопроводов проводится только в случае ее необходимости?',
        answers: [
          'Окончательное закрепление неподвижных опор на концах участка, подлежащего холодному натягу',
          'Термическая обработка сварных соединений',
          'Выполнение всех сварных соединений за исключением замыкающего',
          'Контроль качества сварных соединений, расположенных по всей длине участка, на котором необходимо произвести холодный натяг',
        ],
        correctAnswers: ['Термическая обработка сварных соединений'],
      },
      {
        code: '63693133',
        text:
          'При заполнении каких трубопроводов должен осуществляться контроль разности температур стенок трубопровода и рабочей среды?',
        answers: [
          'При заполнении всех трубопроводов, на которые распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'При заполнении неостывших трубопроводов горячей воды',
          'При заполнении трубопроводов тепловых сетей',
          'При заполнении неостывших паропроводов',
        ],
        correctAnswers: ['При заполнении неостывших паропроводов'],
      },
      {
        code: '63693134',
        text:
          'Для каких трубопроводов наружный осмотр может быть произведен без снятия изоляции?',
        answers: [
          'Трубопроводы, проложенные открытым способом или в проходных и полупроходных каналах',
          'Трубопроводы, проложенные в непроходных каналах или при их бесканальной прокладке',
          'Не допускается проведение наружного осмотра трубопровода без снятия изоляции',
        ],
        correctAnswers: [
          'Трубопроводы, проложенные открытым способом или в проходных и полупроходных каналах',
        ],
      },
      {
        code: '63693135',
        text:
          'Каким образом должны оформляться результаты проверок готовности трубопровода к пуску в работу и организации надзора за его эксплуатацией?',
        answers: [
          'Приказом (распорядительным документом) эксплуатирующей организации',
          'Актом готовности трубопровода к вводу в эксплуатацию',
          'Записью в паспорт трубопровода',
          'Протоколом, который является основанием для ввода трубопровода в эксплуатацию. Протокол прилагается к паспорту трубопровода',
        ],
        correctAnswers: [
          'Актом готовности трубопровода к вводу в эксплуатацию',
        ],
      },
      {
        code: '63693136',
        text:
          'В какой документации указывается необходимость проведения и объем ультразвукового и радиографического контроля, типы и размеры несплошностей (дефектов), подлежащих обнаружению, для конкретного вида (типа) конструкции оборудования под давлением и сварного соединения?',
        answers: [
          'Руководстве (инструкции) по эксплуатации',
          'Проектной (конструкторской) и технологической документацией',
          'В паспорте оборудования',
        ],
        correctAnswers: [
          'Проектной (конструкторской) и технологической документацией',
        ],
      },
      {
        code: '63693137',
        text:
          'Если эксплуатация трубопровода разрешена на пониженном давлении, то каким образом осуществляется регулировка предохранительных устройств?',
        answers: [
          'Установленные на трубопроводе предохранительные клапаны подлежат замене',
          'Регулировка предохранительных устройств должна быть произведена по пониженному давлению без проверки пропускной способности предохранительных клапанов, если давление снижено не более чем на 30 % от ранее разрешенного',
          'Регулировка предохранительных устройств должна быть произведена по пониженному давлению, и пропускная способность их должна быть проверена расчетом',
        ],
        correctAnswers: [
          'Регулировка предохранительных устройств должна быть произведена по пониженному давлению, и пропускная способность их должна быть проверена расчетом',
        ],
      },
      {
        code: '63693138',
        text:
          'Какая ширина поверхности сварного шва и прилегающих к нему участков основного металла должны быть зачищены от шлака и других загрязнений перед визуальным контролем?',
        answers: [
          'Не менее 20 мм в обе стороны от шва',
          'Не менее 10 мм в обе стороны от шва',
          'Не менее 30 мм в обе стороны от шва',
          'Не менее 40 мм в обе стороны от шва',
        ],
        correctAnswers: ['Не менее 20 мм в обе стороны от шва'],
      },
      {
        code: '63693139',
        text:
          'Какой должен быть класс точности средства измерения давления при рабочем давлении более 14 МПа?',
        answers: ['Не ниже 1,5', 'Не ниже 2,5', 'Не ниже 1'],
        correctAnswers: ['Не ниже 1'],
      },
      {
        code: '63693140',
        text:
          'Какое из приведенных требований должно выполняться при гидравлическом испытании паропроводов с рабочим давлением 10 МПа и более?',
        answers: [
          'Верхний предел температуры воды может быть увеличен по согласованию с проектной организацией до 80 °С',
          'Гидравлическое испытание паропровода проводится только при значении температуры стенки равном 120 °С',
          'При гидравлическом испытании паропровода температура его стенок должна быть не менее 10 °С',
          'Для данных трубопроводов допускается замена гидравлического испытания пневматическим испытанием с обязательным контролем проведения этого испытания методом акустической эмиссии',
        ],
        correctAnswers: [
          'При гидравлическом испытании паропровода температура его стенок должна быть не менее 10 °С',
        ],
      },
      {
        code: '63693141',
        text:
          'На какой период руководителем эксплуатирующей организации может быть принято решение о возможности эксплуатации трубопровода в режиме опытного применения?',
        answers: [
          'Эксплуатация трубопровода в режиме опытного применения не допускается',
          'Не более 1 года',
          'Не более 6 месяцев',
          'Период эксплуатации трубопровода в режиме опытного применения устанавливается эксплуатирующей организацией с уведомлением об этом территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Не более 6 месяцев'],
      },
      {
        code: '63693142',
        text:
          'Какое требование к отключению трубопровода до начала производства ремонтных работ указано неверно?',
        answers: [
          'Трубопровод должен быть отделен от всех трубопроводов заглушками, если на них установлена фланцевая арматура, или отсоединен от действующего оборудования',
          'Если арматура трубопроводов бесфланцевая, то отключение котла должно быть произведено двумя запорными устройствами при наличии между ними дренажного устройства с номинальным диаметром не менее 32 мм, имеющего прямое соединение с атмосферой',
          'Приводы закрытых задвижек, а также запорной арматуры открытых дренажей должны быть блокированы запирающим устройством так, чтобы исключалась возможность их открытия или закрытия',
          'Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
        ],
        correctAnswers: [
          'Ключи от запирающих устройств должны храниться у ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
        ],
      },
      {
        code: '63693143',
        text:
          'Каким образом проводится наружный осмотр трубопроводов при прокладке в непроходных каналах или при бесканальной прокладке, если иное не предусмотрено в проектной документации и руководстве (инструкции) по эксплуатации трубопровода?',
        answers: [
          'Путем вскрытия грунта отдельных участков без снятия изоляции не реже чем через каждые пять километров трубопровода',
          'Путем вскрытия грунта отдельных участков и снятия изоляции не реже чем через каждые два километра трубопровода',
          'Путем вскрытия грунта всего участка и снятия изоляции',
        ],
        correctAnswers: [
          'Путем вскрытия грунта отдельных участков и снятия изоляции не реже чем через каждые два километра трубопровода',
        ],
      },
      {
        code: '63693144',
        text:
          'На какие процессы не распространяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'На техническое перевооружение опасного производственного объекта, на котором используются трубопроводы пара и горячей воды',
          'На монтаж паропровода',
          'На изготовление труб, тройников, отводов',
        ],
        correctAnswers: ['На изготовление труб, тройников, отводов'],
      },
      {
        code: '63693145',
        text:
          'Для каких трубопроводов в исполнительной схеме (чертеже) указывается расположение указателей для контроля тепловых перемещений с указанием проектных величин перемещений?',
        answers: [
          'Для трубопроводов, которые работают при температурах, вызывающих ползучесть металла',
          'Для всех трубопроводов',
          'Для всех паропроводов, подлежащих учету в территориальном органе Ростехнадзора',
        ],
        correctAnswers: [
          'Для трубопроводов, которые работают при температурах, вызывающих ползучесть металла',
        ],
      },
      {
        code: '63693146',
        text:
          'Для каких трубопроводов эксплуатирующая организация обязана обеспечить проведение систематических наблюдений (контроля) за ростом остаточных деформаций?',
        answers: [
          'Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С',
          'Паропровод из легированной хромомолибденовой стали, работающий при температуре пара свыше 400 °С до 500 °С',
          'Паропровод из высоколегированной хромистой стали, работающий при температуре пара свыше 300 °С до 540 °С',
          'Для всех указанных трубопроводов устанавливается систематическое наблюдение за ростом остаточных деформаций',
        ],
        correctAnswers: [
          'Паропровод из углеродистой стали, работающий при температуре пара свыше 420 °С',
        ],
      },
      {
        code: '63693147',
        text:
          'В какой из приведенных форм не проводится оценка соответствия оборудования, работающего под давлением, на которое распространяется действие технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Экспертизы промышленной безопасности',
          'Сертификации',
          'Декларирования соответствия',
          'Государственного контроля (надзора)',
        ],
        correctAnswers: ['Экспертизы промышленной безопасности'],
      },
      {
        code: '63693148',
        text: 'Во сколько этапов проводится наружный осмотр трубопроводов?',
        answers: [
          'В два этапа',
          'В три этапа',
          'В один этап',
          'В четыре этапа',
        ],
        correctAnswers: ['В два этапа'],
      },
      {
        code: '63693149',
        text:
          'Какие из перечисленных действий не допускаются перед началом сварки деталей оборудования, работающего под давлением? Выберите 2 варианта ответа.',
        answers: [
          'Проверка качества сборки соединяемых элементов',
          'Проверка состояния стыкуемых кромок и прилегающих к ним поверхностей',
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
          'Подготовка кромок и поверхностей механической обработкой',
        ],
        correctAnswers: [
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
        ],
      },
      {
        code: '63693150',
        text:
          'На основании чего осуществляется пуск (включение) в работу и штатная остановка трубопроводов пара и горячей воды?',
        answers: [
          'На основании приказа руководителя эксплуатирующей организации',
          'На основании письменного распоряжения ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию трубопровода',
        ],
        correctAnswers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию трубопровода',
        ],
      },
      {
        code: '63693151',
        text:
          'Кто утверждает график объема и периодичности плановых работ по техническому обслуживанию и ремонту оборудования под давлением и его элементов?',
        answers: [
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Техническая комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технический руководитель эксплуатирующей организации',
        ],
      },
      {
        code: '63693152',
        text:
          'В каком из приведенных случаев манометр может быть допущен к применению?',
        answers: [
          'Если отсутствует информация о проведении поверки (пломба или клеймо, или документ о проведении поверки)',
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
          'Если разбито стекло или имеются другие повреждения манометра, которые могут отразиться на правильности его показаний',
          'Во всех приведенных случаях манометр не допускается к применению',
        ],
        correctAnswers: [
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
        ],
      },
      {
        code: '63693153',
        text:
          'В какие сроки проводится проверка исправности действия манометров и предохранительных клапанов при эксплуатации трубопроводов с рабочим давлением свыше 1,4 до 4,0 МПа включительно?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в сутки',
          'Сроки устанавливаются эксплуатирующей организацией и указываются в графике проверки, который утверждается техническим руководителем эксплуатирующей организации',
          'Проверка манометров проводится не реже одного раза в смену; проверка предохранительных клапанов – не реже одного раза в сутки',
        ],
        correctAnswers: ['Не реже одного раза в сутки'],
      },
      {
        code: '63693154',
        text:
          'В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах перед рычажно-грузовым или пружинным предохранительным клапаном?',
        answers: [
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
          'Если необходимо увеличение пропускной способности систем сброса давления - рычажно-грузового (пружинного) предохранительного клапана',
          'Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительных клапанов',
          'Установка мембранных предохранительных устройств перед рычажно-грузовыми и пружинными предохранительными клапанами не допускается',
        ],
        correctAnswers: [
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
        ],
      },
      {
        code: '63693155',
        text:
          'Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?',
        answers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
          'Проектной (конструкторской) и технической документации оборудования под давлением, монтаж, ремонт, реконструкция (модернизация) которого осуществляется',
          'Нормативных документов, необходимость применения которых для обеспечения требований промышленной безопасности, установленных законодательством в области промышленной безопасности при выполнении соответствующих работ установлена специализированной организацией в виде утвержденного перечня или иного распорядительного документа',
          'Технологической документации на производство заявленных видов работ, разработанной до начала этих работ',
        ],
        correctAnswers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
        ],
      },
      {
        code: '63693156',
        text:
          'Какой максимальный срок действия декларации о соответствии серийно выпускаемого оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" со дня ее регистрации в Едином реестре выданных сертификатов соответствия и зарегистрированных деклараций о соответствии?',
        answers: ['5 лет', '4 года', '3 года', '1 год'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63693157',
        text:
          'Какое из приведенных требований к эксплуатации предохранительных клапанов, установленных на трубопроводе, указано неверно?',
        answers: [
          'Для отбора среды от патрубка, на котором установлено предохранительное устройство, не допускается установка более одного запорного устройства',
          'Предохранительные клапаны должны иметь защищенные от замерзания отводящие трубопроводы, предохраняющие персонал от ожогов при срабатывании клапанов',
          'Отводящие трубопроводы должны быть оборудованы дренажами для слива, скапливающегося в них конденсата. Установка запорных устройств на дренажах не допускается',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Для отбора среды от патрубка, на котором установлено предохранительное устройство, не допускается установка более одного запорного устройства',
        ],
      },
    ],
    63694: [
      {
        code: '63694000',
        text:
          'Каким путем проводится оценка риска для идентифицированных видов опасности при проектировании оборудования согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Расчетным',
          'Экспериментальным',
          'Экспертным',
          'По данным эксплуатации аналогичных видов оборудования',
          'Всеми перечисленными путями',
        ],
        correctAnswers: ['Всеми перечисленными путями'],
      },
      {
        code: '63694001',
        text:
          'Какое из приведенных требований к проверке знаний рабочих, обслуживающих сосуды, указано неверно?',
        answers: [
          'Результаты проверки знаний рабочих оформляют протоколом с отметкой в удостоверении о допуске к самостоятельной работе',
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих сосуды с быстросъемными крышками',
          'Внеочередная проверка знаний проводится при принятии на работу рабочего из другой организации',
          'В случае реконструкции (модернизации) сосуда должна быть проведена внеочередная проверка знаний',
        ],
        correctAnswers: [
          'Участие представителя Ростехнадзора обязательно при проведении первичной аттестации рабочих, обслуживающих сосуды с быстросъемными крышками',
        ],
      },
      {
        code: '63694002',
        text:
          'В каком из приведенных случаев до проверки знаний рабочий, обслуживающий сосуды, должен пройти стажировку?',
        answers: [
          'Перед первичным допуском к самостоятельной работе после профессионального обучения',
          'Перед допуском к самостоятельной работе после внеочередной проверки знаний',
          'При перерыве в работе по специальности более 12 месяцев',
          'Стажировка проводится во всех приведенных случаях',
        ],
        correctAnswers: ['Стажировка проводится во всех приведенных случаях'],
      },
      {
        code: '63694003',
        text:
          'Что из приведенного, в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется производственной инструкцией по режиму работы и безопасному обслуживанию сосудов?',
        answers: [
          'Порядок проверки исправности обслуживаемых сосудов и относящегося к ним оборудования в рабочем состоянии',
          'Порядок, сроки и способы проверки арматуры, предохранительных устройств, приборов автоматики защиты и сигнализации',
          'Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора',
          'Порядок пуска в работу и остановки (прекращения работы) сосуда',
        ],
        correctAnswers: [
          'Порядок проведения технического освидетельствования сосудов, не подлежащих учету в территориальном органе Ростехнадзора',
        ],
      },
      {
        code: '63694004',
        text:
          'Сколько раз разрешается проводить исправление дефектов на одном и том же участке сварного соединения?',
        answers: [
          'Не более трех раз',
          'Не более двух раз',
          'Не более четырех раз',
          'Один раз',
        ],
        correctAnswers: ['Не более трех раз'],
      },
      {
        code: '63694005',
        text:
          'Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки свыше 50 до 100 мм включительно (если отсутствуют другие указания в руководстве по эксплуатации)?',
        answers: ['5 минут', '10 минут', '20 минут', '30 минут'],
        correctAnswers: ['20 минут'],
      },
      {
        code: '63694006',
        text:
          'На каком основании принимается решение о вводе в эксплуатацию сосуда, работающего под давлением?',
        answers: [
          'На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда',
          'На основании результатов первичного освидетельствования сосуда и проверки организации обслуживания сосуда и надзора за его работой',
          'На основании предписания уполномоченного представителя территориального органа Ростехнадзора',
          'На основании экспертизы промышленной безопасности. проведенной перед пуском сосуда в работу',
        ],
        correctAnswers: [
          'На основании результатов проверки готовности сосуда к пуску в работу и проверки организации надзора за эксплуатацией сосуда',
        ],
      },
      {
        code: '63694007',
        text:
          'Какое из приведенных требований должно выполняться при исправлении дефектов сварных соединений?',
        answers: [
          'Удаление дефектов следует проводить только способом термической резки (строжки) с обеспечением плавных переходов в местах выборок',
          'Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией',
          'Полнота удаления дефектов должна быть проконтролирована ультразвуковой дефектоскопией',
        ],
        correctAnswers: [
          'Максимальные размеры и форма подлежащих заварке выборок устанавливаются технологической документацией',
        ],
      },
      {
        code: '63694008',
        text:
          'Каким документом определяется ответственность за безопасность обслуживания оборудования под давлением в период проведения наладочных работ?',
        answers: [
          'Программой наладочных работ',
          'Договором на оказание услуг по проведению наладочных работ, заключаемым эксплуатирующей и наладочной организациями',
          'Совместным приказом руководителей эксплуатирующей и специализированной пусконаладочной организаций',
        ],
        correctAnswers: ['Программой наладочных работ'],
      },
      {
        code: '63694009',
        text:
          'Кто может записывать в паспорт оборудования сведения о результатах и причинах проведения технического диагностирования, неразрушающего и разрушающего контроля? Выберите 2 варианта ответа.',
        answers: [
          'Уполномоченный представитель организации, их проводившей',
          'Специалист эксплуатирующей организации, ответственный за исправное состояние и безопасную эксплуатацию оборудования',
          'Инспектор Ростехнадзора',
          'Технический руководитель эксплуатирующей организации',
        ],
        correctAnswers: [
          'Уполномоченный представитель организации, их проводившей',
          'Специалист эксплуатирующей организации, ответственный за исправное состояние и безопасную эксплуатацию оборудования',
        ],
      },
      {
        code: '63694010',
        text:
          'Необходимо ли полностью снимать наружную изоляцию сосуда при проведении его внеочередного технического освидетельствования после ремонта с применением сварки и термической обработки?',
        answers: [
          'Допускается снимать наружную изоляцию частично только в месте, подвергнутом ремонту',
          'Нет, в данном случае наружная изоляция не снимается',
          'Да, наружная изоляция сосуда должна быть снята полностью',
        ],
        correctAnswers: [
          'Допускается снимать наружную изоляцию частично только в месте, подвергнутом ремонту',
        ],
      },
      {
        code: '63694011',
        text:
          'Каким образом должны оформляться результаты проверок готовности сосуда к пуску в работу и организации надзора за его эксплуатацией?',
        answers: [
          'Приказом (распорядительным документом) эксплуатирующей организации',
          'Актом готовности сосуда к вводу в эксплуатацию',
          'Записью в паспорт сосуда',
          'Протоколом, который является основанием для ввода сосуда в эксплуатацию. Протокол прилагается к паспорту сосуда',
        ],
        correctAnswers: ['Актом готовности сосуда к вводу в эксплуатацию'],
      },
      {
        code: '63694012',
        text:
          'Кто проводит испытания оборудования, предусмотренные проектной документацией?',
        answers: [
          'Изготовитель',
          'Поставщик',
          'Проектировщик',
          'Предприятие-владелец',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63694013',
        text:
          'Какие указания должны быть дополнительно включены в производственные инструкции по режиму работы и безопасному обслуживанию автоклавов с быстросъемными крышками?',
        answers: [
          'Порядок пользования ключ-маркой и замком; контроль за непрерывным отводом конденсата',
          'Допустимые скорости прогрева и охлаждения автоклава и методы их контроля',
          'Порядок наблюдения за тепловыми перемещениями автоклава; контроль за отсутствием защемлений подвижных опор',
          'Все приведенные указания должны включаться в производственную инструкцию',
          'Все приведенные указания, кроме контроля за непрерывным отводом конденсата, должны включаться в производственную инструкцию',
        ],
        correctAnswers: [
          'Все приведенные указания должны включаться в производственную инструкцию',
        ],
      },
      {
        code: '63694014',
        text:
          'С какой периодичностью проводится проверка знаний рабочих, обслуживающих сосуды?',
        answers: [
          'Периодичность устанавливается эксплуатирующей организацией',
          'Один раз в 12 месяцев',
          'Один раз в 4 месяца',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63694015',
        text:
          'На какой из приведенных сосудов, работающих под давлением свыше 0,07 МПа, распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Сосуд с радиоактивной средой',
          'Прибор парового отопления',
          'Сосуд, установленный на плавучей драге',
          'Сосуд, установленный на самолете',
        ],
        correctAnswers: ['Сосуд, установленный на плавучей драге'],
      },
      {
        code: '63694016',
        text:
          'Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосудов?',
        answers: [
          'В комбинированных сосудах с двумя и более рабочими полостями, рассчитанными на разные давления, гидравлическому испытанию должна быть подвергнута каждая полость пробным давлением, равным максимальному пробному давлению из определяемых для каждой полости',
          'В комбинированных сосудах с четным количеством рабочих полостей, рассчитанных на разные давления, гидравлическому испытанию должна быть подвергнута каждая пара полостей пробным давлением, определяемым в зависимости от расчетного давления полостей',
          'Гидравлическое испытание сосудов, устанавливаемых вертикально, проводить в горизонтальном положении не допускается',
          'Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания',
        ],
        correctAnswers: [
          'Гидравлическое испытание сосудов, устанавливаемых вертикально, разрешается проводить в горизонтальном положении, при этом должен быть выполнен расчет на прочность корпуса сосуда с учетом принятого способа опирания для проведения гидравлического испытания',
        ],
      },
      {
        code: '63694017',
        text:
          'Какой из приведенных сосудов не подлежит учету в территориальных органах Ростехнадзора?',
        answers: [
          'Сосуд, работающий со средой 1-й группы (согласно ТР ТС 032/2013), при температуре стенки не более 200 °С, у которого произведение значений рабочего давления (МПа) и вместимости (м³) равно 0,1',
          'Сосуд, работающий со средой 2-й группы (согласно ТР ТС 032/2013), при температуре стенки не более 200 °С, у которого произведение значений рабочего давления (МПа) и вместимости (м³) равно 1,2',
          'Бочка для перевозки сжиженных газов, вместимостью 18 м³',
          'Сосуд вместимостью 36 м3 и с давлением 0,1 МПа, установленный в подземной горной выработке',
        ],
        correctAnswers: [
          'Сосуд вместимостью 36 м3 и с давлением 0,1 МПа, установленный в подземной горной выработке',
        ],
      },
      {
        code: '63694018',
        text:
          'Какие из перечисленных сведений о результатах экспертизы промышленной безопасности, записываемые в паспорт оборудования, указаны неверно?',
        answers: [
          'ФИО экспертов, проводивших экспертизу промышленной безопасности',
          'Дату подписания заключения экспертизы промышленной безопасности',
          'Наименование организации, проводившей экспертизу промышленной безопасности',
          'Регистрационный номер по реестру заключений экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'ФИО экспертов, проводивших экспертизу промышленной безопасности',
        ],
      },
      {
        code: '63694019',
        text:
          'В каком из перечисленных случаев должно быть вырезано три образца из каждого контрольного стыкового сварного соединения?',
        answers: [
          'Для испытания на ударный изгиб',
          'Для испытания на статическое растяжение',
          'Для испытаний на статический изгиб или сплющивание',
          'Для испытаний на стойкость против межкристаллитной коррозии',
        ],
        correctAnswers: ['Для испытания на ударный изгиб'],
      },
      {
        code: '63694020',
        text:
          'Какой из приведенных сосудов подлежит учету в территориальных органах Ростехнадзора?',
        answers: [
          'Сосуд со сжиженным газом, находящийся под давлением 0,5 МПа, работающий периодически при его опорожнении',
          'Газовый баллон вместимостью 120 литров, установленный на транспортном средстве для обеспечения топливом его двигателя',
          'Воздушный ресивер вместимостью 550 литров, работающий с давлением 2,0 МПа',
          'Отбойный сепаратор давлением 2,0 МПа, включенный в закрытую систему добычи нефти',
        ],
        correctAnswers: [
          'Воздушный ресивер вместимостью 550 литров, работающий с давлением 2,0 МПа',
        ],
      },
      {
        code: '63694021',
        text:
          'В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся только комиссией, назначаемой приказом эксплуатирующей организации?',
        answers: [
          'До начала применения транспортабельного сосуда',
          'После монтажа без применения сварки сосуда, демонтированного и установленного на новом месте',
          'После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации',
          'Во всех приведенных случаях проверки осуществляются комиссией, назначаемой приказом эксплуатирующей организации',
        ],
        correctAnswers: [
          'После монтажа сосуда, поставляемого отдельными блоками, окончательную сборку которого с применением сварных соединений производят при монтаже на месте его эксплуатации',
        ],
      },
      {
        code: '63694022',
        text:
          'Чем должны оснащаться указатели уровня жидкости на оборудовании, давление которого превышает 4,5 МПа?',
        answers: [
          'Двумя дублирующими указателями уровня жидкости непрямого действия',
          'Двумя дополнительными сниженными дистанционными указателями уровня жидкости',
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
          'Дополнительными звуковыми, световыми и другими сигнализаторами и блокировками по предельным уровням жидкости',
        ],
        correctAnswers: [
          'Двумя последовательно расположенными комплектами запорных арматур для отключения указателей уровня жидкости',
        ],
      },
      {
        code: '63694023',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) криогенных сосудов при наличии вакуума в изоляционном пространстве?   Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 оС и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,25 Р﻿раб – 0,1',
          'Рпр = 1,5 Рраб – 0,1',
          'Рпр = 1,25 Рраб ([σ]20 / [σ]t)',
          'Рпр= 1,25 Рраб + 0,1',
        ],
        correctAnswers: ['Рпр = 1,25 Р﻿раб – 0,1'],
      },
      {
        code: '63694024',
        text:
          'Что необходимо обеспечить при эксплуатации сосудов, обогреваемых горячими газами?',
        answers: [
          'Максимальное снижение потерь тепла от поверхности сосуда с повышенной температурой в окружающую среду',
          'Температуру наружной поверхности оборудования не более 55 °С при температуре окружающей среды не более 25 °С',
          'Надежное охлаждение стенок, находящихся под давлением, не допуская превышение температуры стенки выше допустимых значений',
        ],
        correctAnswers: [
          'Надежное охлаждение стенок, находящихся под давлением, не допуская превышение температуры стенки выше допустимых значений',
        ],
      },
      {
        code: '63694025',
        text:
          'Кем определяются тип арматуры, ее количество и место установки согласно требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Разработчиком проекта оборудования',
          'Монтажной организацией',
          'Эксплуатирующей организацией',
          'Специализированной экспертной организацией',
        ],
        correctAnswers: ['Разработчиком проекта оборудования'],
      },
      {
        code: '63694026',
        text:
          'Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением более 6 МПа?',
        answers: [
          'Превышающее разрешенное давление более чем на 0,05 МПа',
          'Превышающее разрешенное давление более чем на 15 %',
          'Превышающее разрешенное давление более чем на 10 %',
          'Превышающее разрешенное давление более чем на 25 %',
        ],
        correctAnswers: ['Превышающее разрешенное давление более чем на 10 %'],
      },
      {
        code: '63694027',
        text:
          'Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте менее 2 м от уровня площадки наблюдения?',
        answers: ['50 мм', '100 мм', '160 мм', '200 мм'],
        correctAnswers: ['100 мм'],
      },
      {
        code: '63694028',
        text:
          'Для какого из приведенных сосудов необязательна установка трехходового крана или заменяющего его устройства между манометром и сосудом?',
        answers: [
          'Для сосудов, работающих со средой, отнесенной к группе 2 (в соответствии с ТР ТС 032/2013)',
          'Для сосудов, работающих со средой, не оказывающей непосредственного температурного воздействия на надежность работы манометра',
          'Для сосудов, у которых имеется возможность проверки манометра путем снятия его с места установки',
          'Для всех сосудов обязательна установка трехходового крана или заменяющего его устройства между манометром и сосудом',
        ],
        correctAnswers: [
          'Для сосудов, у которых имеется возможность проверки манометра путем снятия его с места установки',
        ],
      },
      {
        code: '63694029',
        text:
          'В каком случае при первичном техническом освидетельствовании допускается не проводить осмотр внутренней поверхности и гидравлическое испытание сосуда?',
        answers: [
          'Если это установлено в требованиях руководства (инструкции) по эксплуатации сосуда, поставляемого в собранном виде, и при этом не нарушены указанные в руководстве сроки и условия консервации',
          'Если сосуд предназначен для работы под давлением рабочих сред, отнесенных к группе 2',
          'При первичном техническом освидетельствовании сосуда осмотр внутренней поверхности и гидравлическое испытание проводится всегда',
          'Если на сосуд не распространяется действие Технического регламента Таможенного союза «О безопасности оборудования, работающего под избыточным давлением»',
        ],
        correctAnswers: [
          'Если это установлено в требованиях руководства (инструкции) по эксплуатации сосуда, поставляемого в собранном виде, и при этом не нарушены указанные в руководстве сроки и условия консервации',
        ],
      },
      {
        code: '63694030',
        text:
          'В каком случае из перечисленных проводят гидравлическое испытание пробным давлением в целях проверки плотности и прочности оборудования под давлением, а также всех сварных и других соединений?',
        answers: [
          'Во всех перечисленных случаях',
          'После окончательной сборки (изготовления, доизготовления) при монтаже оборудования, транспортируемого на место его установки отдельными деталями, элементами или блоками',
          'После реконструкции (модернизации), ремонта оборудования с применением сварки элементов, работающих под давлением',
          'При проведении технических освидетельствований и технического диагностирования оборудования в случаях, установленных Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63694031',
        text:
          'Какой маркировке подлежит оборудование, соответствие которого не подтверждено требованиям технического регламента "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Брак',
          'Неликвид',
          'Ex',
          'HE EAC',
          'Оборудование не подлежит маркировке',
        ],
        correctAnswers: ['Оборудование не подлежит маркировке'],
      },
      {
        code: '63694032',
        text:
          'Какая из приведенных операций не подлежит обязательному включению в объем работ по первичному техническому освидетельствованию сосудов, смонтированных на месте эксплуатации?',
        answers: [
          'Визуальный и измерительный контроль',
          'Контроль толщины стенок элементов сосудов',
          'Гидравлическое испытание',
          'Проверка соответствия монтажа, обвязки трубопроводами, оснащения контрольно-измерительными приборами и предохранительными устройствами',
          'Все приведенные операции подлежат обязательному включению при проведении первичного освидетельствования сосуда',
        ],
        correctAnswers: ['Контроль толщины стенок элементов сосудов'],
      },
      {
        code: '63694033',
        text:
          'На какой из приведенных сосудов не распространяется действие Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Воздушный ресивер, объем которого составляет 270 литров, работающий под давлением 1,6 МПа',
          'Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа',
          'Воздушный резервуар, объем которого составляет 170 литров, работающий под давлением 1,0 МПа',
          'Сосуд вместимостью 50 литров, работающий под давлением 0,5 МПа, установленный на плавучей буровой установке',
        ],
        correctAnswers: [
          'Сосуд, объем которого составляет 25 литров, работающий под давлением среды, равным 0,8 МПа',
        ],
      },
      {
        code: '63694034',
        text:
          'Кем подписывается удостоверение (свидетельство) о качестве монтажа?',
        answers: [
          'Всеми перечисленными лицами',
          'Руководителями (техническим руководителями) или уполномоченными должностными лицами монтажной организации',
          'Руководителями (техническим руководителями) или уполномоченными должностными лицами эксплуатирующей организации (или ее обособленного структурного подразделения)',
          'Уполномоченным представителем организации разработчика проекта или организации-изготовителя, осуществлявшего авторский надзор (шефмонтаж) за выполнением работ',
        ],
        correctAnswers: ['Всеми перечисленными лицами'],
      },
      {
        code: '63694035',
        text:
          'Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением до 2,5 МПа включительно?',
        answers: ['Не ниже 4,0', 'Не ниже 2,5', 'Не ниже 1,5', 'Не ниже 1,0'],
        correctAnswers: ['Не ниже 2,5'],
      },
      {
        code: '63694036',
        text:
          'В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах перед рычажно-грузовым или пружинным предохранительным клапаном?',
        answers: [
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
          'Если необходимо увеличение пропускной способности систем сброса давления - рычажно-грузового (пружинного) предохранительного клапана',
          'Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительных клапанов',
          'Установка мембранных предохранительных устройств перед рычажно-грузовыми и пружинными предохранительными клапанами не допускается',
        ],
        correctAnswers: [
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
        ],
      },
      {
        code: '63694037',
        text:
          'На какие виды оборудования не распространяется требования Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза',
          'Сосуды, предназначенные для газов, сжиженных газов, растворенных под давлением, и паров, используемые для рабочих сред группы 1 и имеющие максимально допустимое рабочее давление свыше 20 МПа, вместимость свыше 0,0001 м до 0,001 м включительно',
          'Сосуды, предназначенные для жидкостей, используемые для рабочих сред группы 2 и имеющие максимально допустимое рабочее давление свыше 100 МПа, вместимость свыше 0,0001 м до 0,01 м включительно',
          'Котлы, имеющие вместимость более 0,002 м, предназначенные для получения горячей воды, температура которой свыше 110°С',
          'Котлы, имеющие вместимость более 0,002 м, предназначенные для получения пара, избыточное давление которого свыше 0,05 МПа',
        ],
        correctAnswers: [
          'Cосуды, работающие под давлением, создающимся при взрыве внутри них в соответствии с технологическим процессом или при горении в режиме самораспространяющегося высокотемпературного синтеза',
        ],
      },
      {
        code: '63694038',
        text:
          'Что из приведенного не подлежит обязательному включению в состав технической документации, прилагаемой к поставляемому и подлежащему установке оборудованию, работающему под избыточным давлением?',
        answers: [
          'Паспорта предохранительных устройств',
          'Паспорт сосуда',
          'Расчет на прочность оборудования',
          'Руководство (инструкция) по эксплуатации',
        ],
        correctAnswers: ['Паспорт сосуда'],
      },
      {
        code: '63694039',
        text:
          'Кто определяет нормы наполнения цистерн и бочек для перевозки сжиженных газов?',
        answers: [
          'Изготовитель',
          'Ростехнадзор',
          'Научно-исследовательская организация',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Изготовитель'],
      },
      {
        code: '63694040',
        text:
          'Чему равно минимальное значение времени выдержки сосуда под пробным давлением при пневматическом испытании?',
        answers: [
          '5 минут',
          '10 минут',
          '15 минут',
          'Время выдержки сосуда определяется временем естественного падения давления, определяемого по манометру, от значения пробного давления до значения рабочего давления',
        ],
        correctAnswers: ['15 минут'],
      },
      {
        code: '63694041',
        text:
          'Какое из перечисленных требований при проведении гидравлического испытания сосудов указано верно?',
        answers: [
          'Все ответы неверны',
          'Сосуды, имеющие защитное покрытие или изоляцию, подвергают гидравлическому испытанию после наложения покрытия или изоляции',
          'Сосуды, имеющие наружный кожух, подвергают гидравлическому испытанию после установки кожуха',
          'Не допускается эмалированные сосуды подвергать гидравлическому испытанию рабочим давлением после эмалирования',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63694042',
        text:
          'Каким документом (документами) устанавливается объем работ, порядок и периодичность проведения технических освидетельствований в пределах срока службы сосуда?',
        answers: [
          'Руководством (инструкцией) по эксплуатации предприятия-изготовителя сосуда и ФНП ОРПД',
          'Программой проведения технического освидетельствования сосуда, разработанной специализированной организацией до начала проведения освидетельствования',
          'Инструкцией по режиму работы и безопасному обслуживанию сосудов, утвержденной главным техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Руководством (инструкцией) по эксплуатации предприятия-изготовителя сосуда и ФНП ОРПД',
        ],
      },
      {
        code: '63694043',
        text:
          'Какие факторы, представляющие собой основные виды опасности, должны учитываться с целью определения рисков для оборудования?',
        answers: [
          'Все перечисленные',
          'Наличие незащищенных подвижных элементов, вибрация, наличие взрывопожароопасных элементов',
          'Недопустимые отклонения параметров конструкции, сборочных единиц и устройств безопасности, влияющие на безопасность',
          'Коррозия или иные виды износа поверхности элементов оборудования, прекращение действия вспомогательного оборудования, погасание факелов в топке при камерном сжигании топлива',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63694044',
        text: 'Какие цистерны должны иметь термоизоляцию или теневую защиту?',
        answers: [
          'Все цистерны',
          'Цистерны, заполняемые сжиженным кислородом и азотом при температуре, не превышающей в момент окончания наполнения минус 25 °С',
          'Цистерны, наполняемые жидким аммиаком, при температуре, не превышающей в момент окончания наполнения минус 25 оС',
        ],
        correctAnswers: [
          'Цистерны, наполняемые жидким аммиаком, при температуре, не превышающей в момент окончания наполнения минус 25 оС',
        ],
      },
      {
        code: '63694045',
        text:
          'В каком из приведенных случаев не проводится внеочередное техническое освидетельствование сосуда?',
        answers: [
          'Если сосуд не эксплуатировался более 12 месяцев',
          'Если передвижной сосуд установлен на другой площадке эксплуатирующей организации',
          'Если проведен плановый ремонт сосуда с применением сварных соединений',
          'Во всех случаях проводится внеочередное техническое освидетельствование сосуда',
        ],
        correctAnswers: [
          'Если передвижной сосуд установлен на другой площадке эксплуатирующей организации',
        ],
      },
      {
        code: '63694046',
        text:
          'Какие из приведенных мест не подлежат оборудованию аварийным освещением?',
        answers: [
          'Щиты и пульты управления',
          'Вентиляторные площадки',
          'Места установки насосного оборудования',
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
        correctAnswers: [
          'Все приведенные места подлежат оборудованию аварийным освещением',
        ],
      },
      {
        code: '63694047',
        text:
          'Какой организацией должна быть разработана технологическая документация, регламентирующая содержание и порядок выполнения работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением, с применением сварки и термической обработки?',
        answers: [
          'Совместно специализированной организацией, выполняющей эти работы, и эксплуатирующей организацией',
          'Эксплуатирующей организацией на основании руководства (инструкции) по эксплуатации оборудования, работающего под давлением, с последующим согласованием со специализированной организацией, выполняющей эти работы',
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
          'Организацией - изготовителем оборудования, работающего под давлением',
        ],
        correctAnswers: [
          'Специализированной организацией, выполняющей эти работы, до начала их производства',
        ],
      },
      {
        code: '63694048',
        text:
          'В какой документ заносятся результаты проверки исправности предохранительных устройств, установленных на сосуде, и сведения об их настройке?',
        answers: [
          'В паспорт сосуда',
          'В паспорт предохранительного клапана',
          'В сменный (оперативный) журнал или иные эксплуатационные документы',
          'В соответствующее приложение (проверка исправности и сведения о настройках) к производственной инструкции по эксплуатации предохранительных устройств',
        ],
        correctAnswers: [
          'В сменный (оперативный) журнал или иные эксплуатационные документы',
        ],
      },
      {
        code: '63694049',
        text:
          'В какой из приведенных форм не проводится оценка соответствия оборудования, работающего под давлением, на которое распространяется действие технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Экспертизы промышленной безопасности',
          'Сертификации',
          'Декларирования соответствия',
          'Государственного контроля (надзора)',
        ],
        correctAnswers: ['Экспертизы промышленной безопасности'],
      },
      {
        code: '63694050',
        text:
          'Какое требование к проверке исправности манометра, установленного на сосуде, указано неверно?',
        answers: [
          'Проверку исправности манометра производят с помощью трехходового крана или заменяющих его запорных вентилей путем установки стрелки манометра на нуль',
          'Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности',
          'Не реже одного раза в 12 месяцев (если иные сроки не установлены документацией на конкретный манометр) манометры должны быть поверены в установленном порядке',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Эксплуатирующая организация обязана не реже одного раза в 6 месяцев проводить проверку рабочих манометров контрольным манометром или рабочим манометром, имеющим одинаковые с проверяемым манометром шкалу и класс точности',
        ],
      },
      {
        code: '63694051',
        text:
          'Какой документацией устанавливаются необходимость, объем и порядок механических испытаний сварных соединений литых и кованых элементов, труб с литыми деталями, элементов из сталей различных классов, а также других единичных сварных соединений?',
        answers: [
          'Проектной и технологической документацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Производственными инструкциями',
          'Распорядительной документацией эксплуатирующей организации',
        ],
        correctAnswers: ['Проектной и технологической документацией'],
      },
      {
        code: '63694052',
        text:
          'Что не требуется обеспечивать при эксплуатации сосудов, имеющих границу раздела сред, у которых необходим контроль за уровнем жидкости?',
        answers: [
          'Осуществление контроля уровня по двум указателям прямого действия на сосудах, обогреваемых пламенем или горячими газами при возможности понижения уровня жидкости ниже допустимого',
          'Наличие на указателе уровня жидкости обозначения допустимых верхнего и нижнего уровней при этом высота прозрачного указателя уровня жидкости должна быть не менее чем на 25 мм соответственно ниже нижнего и выше верхнего допустимых уровней жидкости, если иное не установлено в руководстве по эксплуатации сосуда',
          'Работоспособное состояние звуковых, световых и других сигнализаторов и блокировок по уровню, предусмотренных проектом дополнительно к указателям уровня прямого действия',
          'При проведении продувки арматуры, установленной на указателе уровня, отвод рабочей среды, не отнесенной к группе 1 (ТР ТС 032/2013) в емкость, соединенную с атмосферой, для остальных сред отвод в безопасное место',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'При проведении продувки арматуры, установленной на указателе уровня, отвод рабочей среды, не отнесенной к группе 1 (ТР ТС 032/2013) в емкость, соединенную с атмосферой, для остальных сред отвод в безопасное место',
        ],
      },
      {
        code: '63694053',
        text:
          'Какое из перечисленных требований к капиллярному и магнитопорошковому контролю указано верно?',
        answers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
          'При применении нескольких видов контроля в отношении одного объекта капиллярный контроль должен выполняться после проведения ультразвукового и магнитопорошкового контроля',
          'По результатам капиллярного контроля на поверхности сварных соединений и наплавок допускаются единичные и групповые индикаторные рисунки округлой или удлиненной форм',
          'По результатам контроля магнитопорошковым методом на поверхности сварных соединений и наплавок допускаются индикаторные рисунки осаждений магнитного порошка',
        ],
        correctAnswers: [
          'Приемочный капиллярный контроль должен проводиться после исправления дефектных участков поверхности и окончательной термообработки',
        ],
      },
      {
        code: '63694054',
        text:
          'Выполнение каких из перечисленных процедур не предусматривается при проверке знаний и допуске работника к самостоятельной работе?',
        answers: [
          'Предусматривается выполнение всех перечисленных процедур',
          'Проведение вводного инструктажа',
          'Проведение первичного инструктажа на рабочем месте',
          'Проверка знаний инструкций',
          'Допуск к самостоятельной работе с выдачей удостоверения',
        ],
        correctAnswers: [
          'Предусматривается выполнение всех перечисленных процедур',
        ],
      },
      {
        code: '63694055',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) металлопластиковых сосудов, у которых ударная вязкость неметаллических материалов 20 Дж/см2 и менее?  Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 оС и расчетной температуре, МПа, Км - отношение массы металлоконструкции к общей массе сосуда.',
        answers: [
          'Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб([σ]20 / [σ]t)',
          'Рпр = [1,25 Км + 1,3 (1 - Км )] Рраб ([σ]20 / [σ]t)',
          'Рпр = [1,5 Км + 1,6 (1 - Км )] Рраб',
          'Рпр = 1,6 Рраб ([σ]20 / [σ]t)',
        ],
        correctAnswers: ['Рпр = [1,25 Км + 1,6 (1 - Км )] Рраб([σ]20 / [σ]t)'],
      },
      {
        code: '63694056',
        text:
          'Каким образом осуществляется учет транспортируемых сосудов (цистерн) в территориальных органах Ростехнадзора?',
        answers: [
          'Цистерны подлежат учету в органе Ростехнадзора по месту нахождения (регистрации) организации, эксплуатирующей эти цистерны',
          'Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию этих цистерн',
          'Цистерны подлежат учету в органе Ростехнадзора как по месту нахождения (регистрации) организации, эксплуатирующей эти цистерны, так и по месту их использования (временный учет) при сроках их использования на этом месте более трех месяцев',
          'Транспортируемые сосуды (цистерны) не подлежат учету в территориальных органах Ростехнадзора',
        ],
        correctAnswers: [
          'Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию этих цистерн',
        ],
      },
      {
        code: '63694057',
        text:
          'Каким образом должен осуществляться контроль исправности пружинного предохранительного клапана, если принудительное его открывание недопустимо по условиям технологического процесса?',
        answers: [
          'Исправность пружинного предохранительного клапана контролируется путем кратковременного повышения давления в сосуде до значения срабатывания предохранительного клапана',
          'Исправность пружинного предохранительного клапана контролируется при его отключении от сосуда с помощью запорной арматуры, установленной между сосудом и предохранительным клапаном',
          'Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на испытательном стенде',
          'Исправность пружинного предохранительного клапана контролируется путем установки на сосуде двух клапанов, один из которых находится в резерве и подключается при проверке основного клапана',
        ],
        correctAnswers: [
          'Исправность пружинного предохранительного клапана контролируется путем проверки его срабатывания на испытательном стенде',
        ],
      },
      {
        code: '63694058',
        text:
          'Какой баллон из приведенных допускается использовать в горизонтальном положении?',
        answers: [
          'Баллон с кислородом',
          'Баллон с ацетиленом',
          'Баллон с пропан-бутаном',
          'Допускается для всех баллонов без ограничений',
        ],
        correctAnswers: ['Баллон с кислородом'],
      },
      {
        code: '63694059',
        text:
          'Какое из приведенных требований к предохранительному клапану, установленному на цистерне, указано неверно?',
        answers: [
          'Предохранительный клапан, установленный на цистерне, должен сообщаться с газовой фазой цистерны',
          'Предохранительный клапан, установленный на цистерне, должен иметь колпак с отверстиями для выпуска газа в случае открывания клапана',
          'Площадь отверстий в колпаке должна быть равной площади рабочего сечения предохранительного клапана',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Площадь отверстий в колпаке должна быть равной площади рабочего сечения предохранительного клапана',
        ],
      },
      {
        code: '63694060',
        text:
          'В каких сосудах не должно создаваться избыточное давление, превышающее максимально допустимое рабочее давление более чем на 0,05 МПа?',
        answers: [
          'В сосудах, в которых избыточное давление составляет менее 0,3 МПа',
          'В сосудах, в которых избыточное давление составляет от 0,3 до 6 МПа включительно',
          'В сосудах, в которых избыточное давление составляет более 6 МПа',
        ],
        correctAnswers: [
          'В сосудах, в которых избыточное давление составляет менее 0,3 МПа',
        ],
      },
      {
        code: '63694061',
        text:
          'Какой документ о подтверждении соответствия требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" должно иметь оборудование, отнесенное ко 2-й категории опасности, доизготовление которого с применением неразъемных соединений осуществляется по месту эксплуатации?',
        answers: [
          'Сертификат',
          'Заключение экспертизы промышленной безопасности',
          'Декларация соответствия',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Декларация соответствия'],
      },
      {
        code: '63694062',
        text:
          'Какие из перечисленных данных не указываются в журнале наполнения цистерн и бочек для перевозки сжиженных газов?',
        answers: [
          'Уровень жидкости',
          'Дата наполнения',
          'Наименование организации-изготовителя цистерны и бочек',
          'Подпись лица, производившего наполнение',
        ],
        correctAnswers: ['Уровень жидкости'],
      },
      {
        code: '63694063',
        text:
          'Что необходимо предпринять, если при освидетельствовании сосуда будут обнаружены дефекты?',
        answers: [
          'Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля',
          'Организация, проводившая техническое освидетельствование, дает предписание о выводе сосуда из эксплуатации',
          'Эксплуатирующая организация должна перевести сосуд в режим эксплуатации на пониженных параметрах, рекомендованных организацией, проводившей техническое освидетельствование',
        ],
        correctAnswers: [
          'Для установления характера и размеров дефектов должно быть проведено техническое диагностирование сосуда с применением методов неразрушающего контроля',
        ],
      },
      {
        code: '63694064',
        text:
          'При выполнении каких условий допускается заменять гидравлическое испытание сосуда пневматическим испытанием?',
        answers: [
          'Если пневматическое испытание одновременно контролируется методом акустической эмиссии',
          'Если при пневматическом испытании будет использована в качестве нагружающей среды газообразная рабочая среда объекта испытаний',
          'Если рабочее давление сосуда не превышает 0.5 МПа',
          'Если проведение гидравлического испытания сопряжено с вероятностью хрупкого разрушения металла сосуда',
        ],
        correctAnswers: [
          'Если пневматическое испытание одновременно контролируется методом акустической эмиссии',
        ],
      },
      {
        code: '63694065',
        text:
          'Какой максимальный срок действия декларации о соответствии серийно выпускаемого оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" со дня ее регистрации в Едином реестре выданных сертификатов соответствия и зарегистрированных деклараций о соответствии?',
        answers: ['5 лет', '4 года', '3 года', '1 год'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63694066',
        text:
          'Какой срок действия сертификата соответствия оборудования требованиям Технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" при использовании схемы 1с?',
        answers: [
          '5 лет',
          '3 года',
          '1 год',
          'В течение назначенного срока службы или назначенного ресурса',
        ],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63694067',
        text:
          'В каком из приведенных случаев в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, сосуд не подлежит аварийной остановке?',
        answers: [
          'При снижении уровня жидкости ниже минимально допустимого или снижении расхода теплоносителя ниже минимально допустимого значения в сосудах с огневым обогревом',
          'При выходе из строя одного из указателей уровня жидкости',
          'При выявлении неисправности предохранительного устройства от повышения давления',
          'Во всех приведенных случаях сосуд подлежит аварийной остановке',
        ],
        correctAnswers: [
          'При выходе из строя одного из указателей уровня жидкости',
        ],
      },
      {
        code: '63694068',
        text:
          'Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте более 3 м от уровня площадки наблюдения?',
        answers: [
          '160 мм',
          '200 мм',
          '250 мм',
          'Установка манометра на такой высоте не разрешается',
        ],
        correctAnswers: ['Установка манометра на такой высоте не разрешается'],
      },
      {
        code: '63694069',
        text:
          'Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?',
        answers: [
          'Уполномоченный представитель Ростехнадзора',
          'Уполномоченный представитель Ростехнадзора принимает решение о вводе в эксплуатацию сосудов, подлежащих учету в органах Ростехнадзора, в остальных случаях решение принимает ответственный за осуществление производственного контроля за безопасной эксплуатацией оборудования под давлением',
          'Ответственный за исправное состояние и безопасную эксплуатацию сосуда',
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
        ],
        correctAnswers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
        ],
      },
      {
        code: '63694070',
        text:
          'Чем определяется объем внеочередного технического освидетельствования?',
        answers: [
          'Объем внеочередного технического освидетельствования такой же, как и при первичном освидетельствовании',
          'Объем внеочередного технического освидетельствования определяется изготовителем сосуда',
          'Объем внеочередного технического освидетельствования определяется причинами, вызвавшими его проведение',
          'Объем внеочередного технического освидетельствования определяется эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Объем внеочередного технического освидетельствования определяется причинами, вызвавшими его проведение',
        ],
      },
      {
        code: '63694071',
        text:
          'Кем должны быть разработаны и утверждены документы при отсутствии в комплекте технической документации, прилагаемой организацией-изготовителем к оборудованию под давлением, документов (в виде разделов паспорта либо отдельных формуляров, журналов), обеспечивающих возможность внесения информации об истории эксплуатации оборудования под давлением?',
        answers: [
          'Эксплуатирующей организацией',
          'Научно-исследовательской организацией',
          'Экспертной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63694072',
        text:
          'Что контролируется при проведении проверки готовности сосуда к пуску в работу? Выберите 2 варианта ответа.',
        answers: [
          'Наличие должностных инструкций для ответственных лиц и специалистов, осуществляющих эксплуатацию сосуда',
          'Наличие в соответствии с проектом и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит',
          'Соответствие требованиям промышленной безопасности установки сосуда и правильность его включения в соответствии с руководством (инструкции) по эксплуатации',
          'Наличие положительных результатов технического освидетельствования сосуда',
        ],
        correctAnswers: [
          'Наличие в соответствии с проектом и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит',
          'Наличие положительных результатов технического освидетельствования сосуда',
        ],
      },
      {
        code: '63694073',
        text:
          'Чему должна быть равна ширина смотровой щели указателя уровня жидкости в сосудах?',
        answers: [
          'Определяется проектом оборудования',
          '4 мм',
          '8 мм',
          '20 мм',
        ],
        correctAnswers: ['Определяется проектом оборудования'],
      },
      {
        code: '63694074',
        text:
          'Что из приведенного не указывается на табличке или не наносится на сосудах (кроме транспортируемых баллонов вместимостью до 100 литров) перед пуском их в работу?',
        answers: [
          'Дата ввода в эксплуатацию',
          'Номер оборудования по нумерации, принятой в эксплуатирующей организации',
          'Разрешенные параметры (давление, температура рабочей среды)',
          'Даты следующих осмотров и гидравлических испытаний',
        ],
        correctAnswers: ['Дата ввода в эксплуатацию'],
      },
      {
        code: '63694075',
        text:
          'Какое минимальное значение избыточного давления должно оставаться в баллонах (если иное не предусмотрено техническими условиями на газ)?',
        answers: [
          'Газ в баллонах должен срабатываться полностью',
          '0,07 МПа',
          '0,05 МПа',
          'ФНП ОРПД не регламентировано',
        ],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63694076',
        text:
          'Что означает термин "температура рабочей среды" согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: [
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
          'Температура, при которой определяются физико-механические характеристики, допускаемое напряжение материала и проводится расчет на прочность элементов оборудования',
          'Максимальная (минимальная) температура стенки сосуда, при которой допускается эксплуатация оборудования',
          'Температура, равная 20°С, используемая при расчете на прочность стандартных сосудов (узлов, деталей, арматуры)',
        ],
        correctAnswers: [
          'Минимальная (максимальная) температура среды при нормальном протекании технологического процесса',
        ],
      },
      {
        code: '63694077',
        text:
          'В каком документе даются указания по выводу из эксплуатации и утилизации оборудования, работающего под давлением?',
        answers: [
          'Руководстве (инструкции) по эксплуатации',
          'Заключении по результатам технического диагностирования оборудования',
          'Типовой инструкции по утилизации оборудования, работающего под давлением, используемого на опасном производственном объекте',
          'Документации эксплуатирующей организации, прошедшей экспертизу промышленной безопасности',
        ],
        correctAnswers: ['Руководстве (инструкции) по эксплуатации'],
      },
      {
        code: '63694078',
        text:
          'Какой из перечисленных терминов соответствует определению "избыточное давление, при котором производится испытание оборудования на прочность и плотность"?',
        answers: [
          'Давление пробное',
          'Давление рабочее',
          'Давление разрешенное',
          'Давление расчетное',
        ],
        correctAnswers: ['Давление пробное'],
      },
      {
        code: '63694079',
        text:
          'Чем должен быть снабжен каждый наливной и спускной вентиль цистерны и бочки для сжиженного газа?',
        answers: ['Заглушкой', 'Клапаном', 'Штуцером', 'Всем перечисленным'],
        correctAnswers: ['Заглушкой'],
      },
      {
        code: '63694080',
        text:
          'Что из перечисленного может применяться в качестве предохранительных устройств на оборудовании, работающим под избыточным давлением?',
        answers: [
          'Рычажно-грузовые предохранительные клапаны прямого действия',
          'Пружинные предохранительные клапаны прямого действия',
          'Импульсные предохранительные устройства, состоящие из импульсного клапана и главного предохранительного клапана',
          'Предохранительные устройства с разрушающимися мембранами (мембранные предохранительные устройства)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63694081',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при гидравлическом испытании (периодическое техническое освидетельствование) сосудов, изготовленных из неметаллических материалов с ударной вязкостью более 20 Дж/см2?   Где в формулах: Рраб – рабочее давление сосуда, Ррасч – расчетное давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 oС и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,3Рраб([σ]20 / [σ]t)',
          'Рпр = 1,3Рраcч ([σ]20 / [σ]t)',
          'Рпр = 1,6Рраб ([σ]20 / [σ]t)',
          'Рпр = 1,6Рраб',
        ],
        correctAnswers: ['Рпр = 1,3Рраб([σ]20 / [σ]t)'],
      },
      {
        code: '63694082',
        text:
          'Чем осуществляется продувка сосуда, работающего под давлением воздуха или инертных газов, до начала выполнения работ внутри его корпуса?',
        answers: [
          'Только воздухом',
          'Только инертным газом',
          'Для таких сосудов продувка до начала выполнения работ внутри их корпуса не предусматривается',
        ],
        correctAnswers: ['Только воздухом'],
      },
      {
        code: '63694083',
        text:
          'На какое напряжение должны применяться безопасные светильники при работе внутри сосуда (внутренний осмотр, ремонт, чистка)?',
        answers: [
          'Не выше 12 В',
          'Не выше 24 В',
          'Не выше 36 В',
          'Не выше 48 В',
        ],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '63694084',
        text:
          'В каком случае и кем допускаются отклонения от проектной документации при строительстве, реконструкции, капитальном ремонте и эксплуатации ОПО, на которых используется (применяется) оборудование под давлением?',
        answers: [
          'Отклонения от проектной документации не допускаются',
          'В технически обоснованных случаях отклонение от проектной документации согласуется с ее разработчиком или со специализированной экспертной организацией',
          'Отклонения от проектной документации оформляются в виде специальных технических условий, подлежащих утверждению в установленном порядке',
        ],
        correctAnswers: ['Отклонения от проектной документации не допускаются'],
      },
      {
        code: '63694085',
        text:
          'При установке средства измерения давления на какой высоте должно предусматриваться дублирующее средство измерения давления?',
        answers: ['Более 5 м', 'Более 3 м', 'Более 2 м', 'Более 2,5 м'],
        correctAnswers: ['Более 5 м'],
      },
      {
        code: '63694086',
        text:
          'Каким образом должны выбираться методы и объем контроля качества сварных соединений при монтаже, ремонте, реконструкции (модернизации) оборудования, работающего под давлением?',
        answers: [
          'В соответствии с требованиями ТР ТС 032/2013',
          'В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'По решению руководителя организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования под давлением',
          'В соответствии с требованиями государственных стандартов',
        ],
        correctAnswers: [
          'В соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
        ],
      },
      {
        code: '63694087',
        text:
          'При каком условии допускается установка переключающего устройства перед мембранными предохранительными устройствами?',
        answers: [
          'Если мембранные предохранительные устройства соединены последовательно с пружинными предохранительными клапанами, а также с переключающими устройствами, при условии контроля исправности мембран с помощью сигнальных манометров',
          'Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства',
          'Установка переключающего устройства перед мембранными предохранительными устройствами не допускается',
        ],
        correctAnswers: [
          'Если установлено удвоенное число мембранных устройств с обеспечением при этом защиты сосуда от превышения давления при любом положении переключающего устройства',
        ],
      },
      {
        code: '63694088',
        text:
          'Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки, не превышающую 50 мм (если отсутствуют другие указания в руководстве по эксплуатации)?',
        answers: ['5 минут', '10 минут', '20 минут', '30 минут'],
        correctAnswers: ['10 минут'],
      },
      {
        code: '63694089',
        text:
          'В каком из приведенных случаев допускается одному специалисту совмещать ответственность за осуществление производственного контроля за безопасной эксплуатацией сосудов и ответственность за их исправное состояние и безопасную эксплуатацию?',
        answers: [
          'Случаи совмещения обязанностей определяются самостоятельно эксплуатирующей организацией в соответствии с ее распорядительными документами',
          'Если сосуды эксплуатируются не более чем на двух производственных площадках',
          'Если это совмещение согласовано с территориальным органом Ростехнадзора',
          'Совмещение не допускается',
        ],
        correctAnswers: ['Совмещение не допускается'],
      },
      {
        code: '63694090',
        text:
          'Каким документом определяется объем, методы и периодичность технических освидетельствований сосудов (за исключением баллонов)?',
        answers: [
          'ФНП ОРПД',
          'Руководство (инструкция по эксплуатации)',
          'Инструкция по режиму работы и безопасному обслуживанию сосуда',
          'Технический регламент Таможенного союза «О безопасности оборудования, работающего под избыточным давлением»',
        ],
        correctAnswers: ['Руководство (инструкция по эксплуатации)'],
      },
      {
        code: '63694091',
        text:
          'В каком случае запрещается объединять сбросы от предохранительных клапанов сосудов?',
        answers: [
          'Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
          'Если сбросы направляются в открытые системы для дальнейшей утилизации или в системы организованного сжигания',
          'Если, по крайней мере, один из сосудов работает под давлением среды, отнесенной к группе 1',
        ],
        correctAnswers: [
          'Если сбросы содержат вещества, способные при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
        ],
      },
      {
        code: '63694092',
        text:
          'Какое из приведенных требований к манометрам, устанавливаемым на сосудах, указано неверно?',
        answers: [
          'На шкале манометра сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде',
          'Взамен красной черты разрешается прикреплять к корпусу манометра пластину (скобу), окрашенную в красный цвет и плотно прилегающую к стеклу манометра',
          'Манометр должен быть подобран с такой шкалой, чтобы предел измерения рабочего давления находился во второй трети шкалы',
          'Все приведенные требования верны',
        ],
        correctAnswers: [
          'На шкале манометра сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде',
        ],
      },
      {
        code: '63694093',
        text:
          'Каково минимальное значение номинального диаметра манометра, устанавливаемого на сосуде на высоте от 2 до 3 м включительно от уровня площадки наблюдения?',
        answers: ['50 мм', '100 мм', '160 мм', '200 мм'],
        correctAnswers: ['160 мм'],
      },
      {
        code: '63694094',
        text:
          'Кого должна уведомлять организация, проводившая техническое освидетельствование, о факте выявления дефектов, возникновение которых обусловлено особенностями (недостатками) конструкции оборудования?',
        answers: [
          'Всех перечисленных',
          'Организацию-изготовителя этого оборудования или уполномоченное организацией-изготовителем лицо',
          'Ростехнадзор',
          'Организацию, оформившую документ о подтверждении соответствия этого оборудования требованиям ТР ТС 032/2013',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63694095',
        text: 'Какие требования к хранению баллонов указаны неверно?',
        answers: [
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
          'При хранении на открытых площадках разрешается укладывать баллоны с башмаками в штабеля с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами',
          'При укладке баллонов в штабеля высота последних не должна превышать 1,5 метра, вентили баллонов должны быть обращены в одну сторону',
        ],
        correctAnswers: [
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
        ],
      },
      {
        code: '63694096',
        text:
          'Какие из перечисленных партий материалов для дефектоскопии до начала их использования должны быть подвергнуты входному контролю?',
        answers: [
          'Все перечисленные партии материалов',
          'Пенетранты',
          'Суспензии',
          'Радиографическая пленка',
        ],
        correctAnswers: ['Все перечисленные партии материалов'],
      },
      {
        code: '63694097',
        text:
          'Чем должны подвергаться испытанию на герметичность сосуды, работающие под давлением сред, отнесенных к 1-й группе согласно технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"? Выберите 2 варианта ответа.',
        answers: ['Воздухом', 'Инертным газом', 'Углекислым газом', 'Паром'],
        correctAnswers: ['Воздухом', 'Инертным газом'],
      },
      {
        code: '63694098',
        text:
          'Что из приведенного в соответствии с требованиями Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется производственной инструкцией по режиму работы и безопасному обслуживанию сосудов?',
        answers: [
          'Обязанности персонала во время дежурства по наблюдению и контролю за работой сосуда',
          'Порядок действия персонала в случае аварии или инцидента',
          'Меры безопасности при выводе оборудования в ремонт',
          'Действия персонала и меры безопасности при подготовке сосуда к техническому освидетельствованию',
        ],
        correctAnswers: [
          'Действия персонала и меры безопасности при подготовке сосуда к техническому освидетельствованию',
        ],
      },
      {
        code: '63694099',
        text:
          'В каком случае допускается заменять гидравлическое испытание сосудов пневматическим испытанием?',
        answers: [
          'Во всех перечисленных случаях',
          'При больших нагрузках от веса воды на фундамент',
          'При трудности удаления воды',
          'При наличии внутри сосуда футеровки',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63694100',
        text: 'Какую информацию должен включать в себя паспорт трубопровода?',
        answers: [
          'Наименование и адрес эксплуатирующей организации, а также изготовителя трубопровода',
          'Рабочая среда',
          'Расчетное количество пусков',
          'Расчетный срок службы',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63694101',
        text:
          'С какой периодичностью проводится проверка знаний требований производственных инструкций и (или) инструкций для данной профессии у рабочих?',
        answers: [
          'Один раз в 12 месяцев',
          'Один раз в 6 месяцев',
          'Один раз в месяц',
          'Один раз в 5 лет',
        ],
        correctAnswers: ['Один раз в 12 месяцев'],
      },
      {
        code: '63694102',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Изготовление сосуда, работающего под давлением',
          'Проектирование размещения сосудов, работающих под давлением, на ОПО',
          'Ремонт сосудов, работающих под давлением',
          'Наладочные работы на технологическом оборудовании, включающем сосуды, работающие под давлением',
        ],
        correctAnswers: ['Изготовление сосуда, работающего под давлением'],
      },
      {
        code: '63694103',
        text:
          'Какое из приведенных требований при подаче газа из баллонов в сосуд, который работает с меньшим давлением, указано неверно?',
        answers: [
          'Подача газов должна производиться через редуктор, предназначенный для данного газа и окрашенный в соответствующий цвет',
          'На входе в редуктор должен быть установлен манометр со шкалой, обеспечивающей возможность измерения максимального рабочего давления в баллоне',
          'На камере низкого давления редуктора должен быть установлен пружинный предохранительный клапан, отрегулированный на соответствующее разрешенное давление в сосуде, а так же соответствующий данному давлению манометр',
          'Все приведенные требования верны',
        ],
        correctAnswers: ['Все приведенные требования верны'],
      },
      {
        code: '63694104',
        text:
          'На какой период руководителем эксплуатирующей организации может быть принято решение о возможности эксплуатации сосуда в режиме опытного применения?',
        answers: [
          'Не более одного года',
          'Период эксплуатации сосуда в режиме опытного применения устанавливается эксплуатирующей организацией с уведомлением об этом территориального органа Ростехнадзора',
          'Не более 6 месяцев',
          'Эксплуатация сосуда в режиме опытного применения не допускается',
        ],
        correctAnswers: ['Не более 6 месяцев'],
      },
      {
        code: '63694105',
        text:
          'На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?',
        answers: [
          'На основании приказа руководителя эксплуатирующей организации',
          'На основании письменного распоряжения ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
        ],
        correctAnswers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
        ],
      },
      {
        code: '63694106',
        text:
          'Каково минимальное значение температуры воды, используемой для гидравлического испытания трубопровода (если конкретное значение не указано в технической документации организации-изготовителя)?',
        answers: ['80 °C', '5 °C', '10 °C', '20 °C'],
        correctAnswers: ['5 °C'],
      },
      {
        code: '63694107',
        text:
          'Какое количество категорий оборудования существует в зависимости от вместимости или номинального диаметра, а также максимально допустимого рабочего давления согласно техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"?',
        answers: ['4', '3', '2', '6'],
        correctAnswers: ['4'],
      },
      {
        code: '63694108',
        text:
          'При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?',
        answers: [
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
          '0,05 МПа',
          'Для сосудов, подлежащих учету в территориальных органах Ростехнадзора, не допускается проведение ремонта сосудов и их элементов, находящихся под давлением, для остальных сосудов – 0,05 МПа',
          '0,025 МПа',
        ],
        correctAnswers: [
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
        ],
      },
      {
        code: '63694109',
        text:
          'В каком из приведенных случаев манометр может быть допущен к применению на сосуде?',
        answers: [
          'Если отсутствует информация о проведении поверки (пломба или клеймо, или документ о проведении поверки)',
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
          'Если разбито стекло или имеются другие повреждения манометра, которые могут отразиться на правильности его показаний',
          'Во всех приведенных случаях манометр не допускается к применению',
        ],
        correctAnswers: [
          'Если стрелка манометра при его отключении не возвращается к нулевой отметке шкалы на величину, не превышающую половины допускаемой погрешности для манометра',
        ],
      },
      {
        code: '63694110',
        text:
          'Какое из приведенных требований к размещению баллонов при их использовании указано неверно?',
        answers: [
          'Допускается установка баллонов на расстоянии не менее 1 метра от радиаторов отопления и других отопительных приборов',
          'Не допускается установка баллонов в местах перемещения грузов',
          'Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Не допускается установка баллона в помещениях, в которых имеются источники тепла с открытым огнем',
        ],
      },
      {
        code: '63694111',
        text:
          'В каком из приведенных случаев допускается использование при монтаже, ремонте, реконструкции (модернизации) стальных труб и иных материалов, ранее бывших в употреблении?',
        answers: [
          'В случае применения таких труб только при монтаже, ремонте, реконструкции (модернизации) водогрейных котлов и трубопроводов горячей воды',
          'Если на стальные трубы оформлены документы, подтверждающие их соответствие и качество (сертификаты, декларации соответствия)',
          'Если срок эксплуатации стальных труб не превышает половины расчетного срока службы технического устройства, на котором эти трубы употреблялись',
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов.',
        ],
        correctAnswers: [
          'В случае применения таких труб в составе обводных (байпасных) и продувочных линий, временно обустраиваемых на ограниченный период времени, определенный проектом монтажа, ремонта, реконструкции (модернизации) системы трубопроводов.',
        ],
      },
      {
        code: '63694112',
        text:
          'Что может применяться для обеспечения доступа к площадкам обслуживания оборудования под давлением, предназначенным для периодического проведения работ (плановое техническое обслуживание, ремонт) в местах расположения люков, арматуры и иных устройств, оборудованных автоматическим дистанционным приводом, не требующих постоянного (неоднократного) присутствия персонала?',
        answers: [
          'Переносные площадки',
          'Передвижные лестницы',
          'Стационарные лестницы с углом наклона к горизонтали более 50°',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63694113',
        text:
          'При соблюдении какого условия оборудование, работающее под избыточным давлением, выпускается в обращение на рынок?',
        answers: [
          'Оборудование должно иметь соответствующее разрешение на его обращение на рынке, выданное в порядке, предусмотренном Евразийской экономической комиссией',
          'Оборудование должно иметь документ о прохождении оценки (подтверждения) соответствия требованиям ТР ТС 032/2013',
          'Оборудование должно иметь разрешение на его применение, выданное Федеральной службой по экологическому, технологическому и атомному надзору',
          'Оборудование должно иметь документ о подтверждении соответствия требованиям государственных и международных стандартов, выданный в порядке, предусмотренном Росстандартом',
        ],
        correctAnswers: [
          'Оборудование должно иметь документ о прохождении оценки (подтверждения) соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63694114',
        text:
          'Какое количество образцов должно быть вырезано из каждого контрольного стыкового сварного соединения для металлографических исследований при контроле сварных соединений из углеродистой и низколегированной стали?',
        answers: ['Один', 'Не менее двух', 'Не менее трех', 'Не менее четырех'],
        correctAnswers: ['Один'],
      },
      {
        code: '63694115',
        text:
          'Какое из приведенных требований к организации отвода токсичных, взрыво- и пожароопасных технологических сред, выходящих из предохранительных устройств, указано неверно?',
        answers: [
          'Сбрасываемые среды должны направляться в закрытые системы для дальнейшей утилизации или в системы организованного сжигания',
          'В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды',
          'Запрещается объединять сбросы, содержащие вещества, которые способны при смешивании образовывать взрывоопасные смеси или нестабильные соединения',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'В обоснованных проектной документацией случаях допускается сброс сред в атмосферу через сбросные трубопроводы при обеспечении безопасного рассеивания сбрасываемой среды',
        ],
      },
      {
        code: '63694116',
        text:
          'Если сосуд работает с газообразной средой группы 1, то в каком случае этот сосуд оборудуется трубопроводами, отводящими рабочую среду от предохранительных клапанов в атмосферу?',
        answers: [
          'Если плотность отводимых газов по отношению к воздуху не превышает 0,8',
          'Если рабочее давление в сосуде не превышает 1,6 МПа и при выводе в атмосферу обеспечивается безопасное рассеивание рабочей среды',
          'Если рабочее давление в сосуде не превышает 1,2 МПа и при выводе в атмосферу обеспечивается безопасное рассеивание рабочей среды',
          'Если при отводе рабочей среды обеспечиваются безопасность обслуживающего сосуд персонала и нормы пожарной безопасности',
        ],
        correctAnswers: [
          'Если плотность отводимых газов по отношению к воздуху не превышает 0,8',
        ],
      },
      {
        code: '63694117',
        text:
          'Что из приведенного не входит в должностные обязанности ответственного за исправное состояние и безопасную эксплуатацию сосудов?',
        answers: [
          'Осуществление контроля за соблюдением требований Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, и законодательства Российской Федерации в области промышленной безопасности при эксплуатации оборудования под давлением, при выявлении нарушений требований промышленной безопасности выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения',
          'Осмотр сосудов с определенной должностной инструкцией периодичностью',
          'Проведение противоаварийных тренировок с обслуживающим персоналом',
          'Подготовка сосуда к техническому освидетельствованию',
        ],
        correctAnswers: [
          'Осуществление контроля за соблюдением требований Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, и законодательства Российской Федерации в области промышленной безопасности при эксплуатации оборудования под давлением, при выявлении нарушений требований промышленной безопасности выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения',
        ],
      },
      {
        code: '63694118',
        text:
          'Каково минимальное значение времени выдержки под пробным давлением сосуда, имеющего толщину стенки свыше 100 мм (если отсутствуют другие указания в руководстве по эксплуатации)?',
        answers: ['5 минут', '10 минут', '30 минут', '20 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63694119',
        text:
          'В каком случае мембранные предохранительные устройства устанавливаются на сосудах и трубопроводах параллельно с рычажно-грузовым или пружинным предохранительным клапаном?',
        answers: [
          'Если необходимо увеличение пропускной способности систем сброса давления - рычажно-грузового (пружинного) предохранительного клапана',
          'Если предохранительные клапаны не могут надежно работать вследствие вредного воздействия среды или возможных утечек через закрытый клапан рабочих сред, отнесенных к 1 группе',
          'Если необходимо исключить влияние колебаний противодавления со стороны сбросной системы на надежность срабатывания рычажно-грузового или пружинного предохранительных клапанов',
          'Установка мембранных предохранительных устройств параллельно с рычажно-грузовыми или пружинными предохранительными клапанами не допускается',
        ],
        correctAnswers: [
          'Если необходимо увеличение пропускной способности систем сброса давления - рычажно-грузового (пружинного) предохранительного клапана',
        ],
      },
      {
        code: '63694120',
        text:
          'На какой максимальный процент допускается превышение давления в сосуде от максимально допустимого рабочего давления при работающих предохранительных клапанах, при условии, что это превышение предусмотрено руководством (инструкцией) по эксплуатации сосуда?',
        answers: ['25%', '15%', '35%', '20%'],
        correctAnswers: ['25%'],
      },
      {
        code: '63694121',
        text:
          'Какие виды контроля качества сварных соединений и материалов должны предшествовать контролю другими методами? Выберите 2 варианта ответа.',
        answers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
          'Проникающими веществами',
          'Магнитопорошковый контроль',
          'Вихретоковый контроль',
        ],
        correctAnswers: [
          'Визуальный и измерительный контроль',
          'Стилоскопирование (или другой спектральный метод)',
        ],
      },
      {
        code: '63694122',
        text:
          'Куда направляет эксплуатирующая оборудование под давлением организация копии документов, содержащих основание и подтверждение факта передачи оборудования другой эксплуатирующей организации?',
        answers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности',
          'В органы местного самоуправления',
          'Другой эксплуатирующей организации',
          'Заводу-изготовителю',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'В территориальный орган Ростехнадзора или иной федеральный орган исполнительной власти в области промышленной безопасности',
        ],
      },
      {
        code: '63694123',
        text:
          'В каком случае проверки готовности сосуда к пуску в работу и организации надзора за эксплуатацией сосуда проводятся ответственными лицами или комиссией с их участием?',
        answers: [
          'При передаче сосуда под давлением для использования другой эксплуатирующей организации',
          'После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде',
          'После капитального ремонта сосуда, связанного с заменой основных элементов',
          'После монтажа сосуда, для которого ТР ТС 032/2013 не предусмотрена процедура подтверждения соответствия',
        ],
        correctAnswers: [
          'После монтажа без применения сварных соединений сосуда, поставленного на объект эксплуатации в собранном виде',
        ],
      },
      {
        code: '63694124',
        text:
          'Какое из приведенных требований к оснащению сосуда, рассчитанного на давление меньше давления питающего его источника, указано неверно?',
        answers: [
          'На подводящем трубопроводе необходима установка автоматического редуцирующего устройства с манометром и предохранительным устройством, установленными на стороне сниженного давления',
          'На общем подводящем трубопроводе для группы сосудов, работающих при одном и том же давлении, допускается установка редуцирующего устройства с манометром и предохранительным клапаном до первого ответвления к одному из сосудов',
          'На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов',
        ],
        correctAnswers: [
          'На подводящем трубопроводе, включая ответвления от общего трубопровода к каждому сосуду и байпасные линии, должны устанавливаться регуляторы расхода и предохранительные клапаны, отрегулированные на рабочие параметры сосудов',
        ],
      },
      {
        code: '63694125',
        text:
          'Какие работы должны быть произведены перед проведением осмотра (визуального и измерительного контроля) внутренней поверхности сосуда, иных работ внутри сосуда и его гидравлического испытания?',
        answers: [
          'Все перечисленные работы',
          'Сосуд должен быть охлажден (отогрет)',
          'Сосуд должен быть освобожден от заполняющей его рабочей среды',
          'Должно быть проведено вентилирование (продувка)',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63694126',
        text:
          'Кто утверждает график объема и периодичности плановых работ по техническому обслуживанию и ремонту оборудования под давлением и его элементов?',
        answers: [
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Техническая комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технический руководитель эксплуатирующей организации',
        ],
      },
      {
        code: '63694127',
        text:
          'Кем определяется достаточная для обеспечения безопасной эксплуатации ОПО численность инженерно-технических работников?',
        answers: [
          'Эксплуатирующей организацией',
          'Ростехнадзором',
          'Проектной организацией',
          'Научно-исследовательской организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63694128',
        text:
          'Каким документом подтверждается соответствие оборудования требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"? Выберите 2 варианта ответа.',
        answers: [
          'Декларация о соответствии',
          'Сертификат соответствия',
          'Протокол приемочных испытаний',
          'Паспорт оборудования, имеющий соответствующую отметку',
        ],
        correctAnswers: [
          'Декларация о соответствии',
          'Сертификат соответствия',
        ],
      },
      {
        code: '63694129',
        text:
          'Каким документом устанавливаются дополнительные требования безопасности при эксплуатации, наполнении, хранении и транспортировании баллонов, изготовленных из металлокомпозитных и композитных материалов?',
        answers: [
          'Для данных баллонов дополнительные требования безопасности не устанавливаются',
          'Технический регламент Таможенного союза "О безопасности оборудования, работающего под избыточным давлением"',
          'Руководство (инструкция) по эксплуатации',
          'Соответствующими государственными стандартами и сводами правил',
        ],
        correctAnswers: ['Руководство (инструкция) по эксплуатации'],
      },
      {
        code: '63694130',
        text:
          'В каком случае допускается не устанавливать помост около люка в верхней части железнодорожных цистерн?',
        answers: [
          'Во всех перечисленных случаях',
          'На железнодорожных цистернах для сжиженного кислорода',
          'На железнодорожных цистернах для азота',
          'На железнодорожных цистернах для криогенных жидкостей, за исключением сжиженного кислорода и азота',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63694131',
        text:
          'Какие условия должны соблюдаться при установлении срока следующего периодического технического освидетельствования сосуда?',
        answers: [
          'Срок следующего освидетельствования устанавливается только экспертной организацией, если он не установлен изготовителем в руководстве по эксплуатации',
          'Срок следующего освидетельствования может превышать, но не более чем на 2 года, срок службы сосуда, установленный либо изготовителем, либо по результатам технического диагностирования сосуда',
          'Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда',
        ],
        correctAnswers: [
          'Срок следующего освидетельствования не должен превышать срока службы сосуда, установленного либо изготовителем, либо по результатам технического диагностирования сосуда',
        ],
      },
      {
        code: '63694132',
        text:
          'Каким образом оформляются результаты проводимого контроля качества сварных соединений?',
        answers: [
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не регламентируется. На усмотрение организации, осуществляющей монтаж, ремонт, реконструкцию (модернизацию) оборудования',
          'Общие результаты по контролю качества сварных соединений оформляются отчетом, прилагаемым к паспорту монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
        ],
        correctAnswers: [
          'Результаты по каждому виду проводимого контроля и месту контроля должны фиксироваться в отчетной документации (журналы, формуляры, протоколы, маршрутные паспорта)',
        ],
      },
      {
        code: '63694133',
        text:
          'Кому должно направить лицо, проводившее техническое освидетельствование, информацию о необходимости проведения внеочередного технического освидетельствования всего оборудования под давлением, эксплуатируемого в аналогичном режиме, в случае если при анализе (оценке характера, размеров и причин возникновения) дефектов, выявленных при техническом освидетельствовании оборудования под давлением, установлено, что их возникновение обусловлено режимом эксплуатации оборудования в данной эксплуатирующей организации или особенностями (недостатками) конструкции данного типа оборудования?',
        answers: [
          'Руководителю эксплуатирующей организации',
          'В территориальный орган Ростехнадзора',
          'В организацию-изготовителя этого оборудования',
          'В организацию, оформившую документ о подтверждении соответствия этого оборудования требованиям ТР ТС 032/2013',
        ],
        correctAnswers: ['Руководителю эксплуатирующей организации'],
      },
      {
        code: '63694134',
        text:
          'Когда на оборудовании, работающем под давлением, проводятся предусмотренные руководством (инструкцией) по эксплуатации пусконаладочные работы?',
        answers: [
          'После окончания монтажных работ с оформлением удостоверения о качестве монтажа',
          'После составления акта о вводе оборудования в эксплуатацию',
          'После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования',
        ],
        correctAnswers: [
          'После оформления удостоверения о качестве монтажа и проведения первичного технического освидетельствования',
        ],
      },
      {
        code: '63694135',
        text:
          'В каком случае допускается наполнять газом цистерны и бочки для перевозки сжиженных газов?',
        answers: [
          'Все ответы неверны',
          'Если отсутствует арматура',
          'В цистернах или бочках находится не тот газ, для которого они предназначены',
          'Если отсутствуют контрольно-измерительные приборы',
          'Если отсутствуют надписи',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63694136',
        text:
          'В каком случае в состав комиссии по проверке готовности сосуда к пуску в работу и организации надзора за его эксплуатацией включаются уполномоченный (уполномоченные) представитель (представители) Ростехнадзора или его территориального органа?',
        answers: [
          'При осуществлении проверок сосудов с огневым обогревом и сосудов, работающих с рабочей средой, отнесенной в соответствии с ТР ТС 032/2013 к группе 1',
          'Исключительно по инициативе руководителя эксплуатирующей организации',
          'При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора',
          'Не регламентируется',
        ],
        correctAnswers: [
          'При осуществлении проверок сосудов, подлежащих учету в территориальных органах Ростехнадзора',
        ],
      },
      {
        code: '63694137',
        text:
          'В течение какого времени проводится комплексное опробование котлов, сосудов и трубопроводов пара и горячей воды?',
        answers: [
          'Начало и конец комплексного опробования оборудования, работающего под давлением, устанавливаются совместным приказом эксплуатирующей организации и организации, проводящей наладочные работы',
          'Котлы – в течение 72 часов, трубопроводы пара и горячей воды – в течение 36 часов, время комплексного опробования сосудов устанавливается совместным приказом эксплуатирующей и наладочной организаций',
          'Котлы – в течение 72 часов, трубопроводы тепловых сетей – в течение 24 часов, остальное оборудование – по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Котлы – в течение 72 часов, трубопроводы тепловых сетей – в течение 24 часов, остальное оборудование – по программе комплексного опробования, разработанной организацией, проводящей соответствующие работы, и согласованной с эксплуатирующей организацией',
        ],
      },
      {
        code: '63694138',
        text:
          'Манометры какого класса точности необходимо применять при эксплуатации сосудов с рабочим давлением более 2,5 МПа?',
        answers: ['Не ниже 4,0', 'Не ниже 2,5', 'Не ниже 1,5', 'Не ниже 1,0'],
        correctAnswers: ['Не ниже 1,5'],
      },
      {
        code: '63694139',
        text:
          'В каком из приведенных случаев сосуд считается выдержавшим гидравлическое испытание?',
        answers: [
          'Если обнаружены лишь единичные трещины в сварном соединении, а на основном металле трещины не обнаружены',
          'Если обнаружены потения в развальцованных соединениях',
          'Если видимая остаточная деформация не превышает 10 %',
          'Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание',
        ],
        correctAnswers: [
          'Во всех приведенных случаях сосуд считается не выдержавшим гидравлическое испытание',
        ],
      },
      {
        code: '63694140',
        text:
          'Какой организацией определяются процедуры контроля соблюдения технологических процессов при осуществлении работ по монтажу, ремонту, реконструкции (модернизации) оборудования, работающего под давлением?',
        answers: [
          'Организацией, эксплуатирующей оборудование, работающее под давлением',
          'Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования',
          'Совместно с организацией, эксплуатирующей оборудование, работающее под давлением, и специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования',
          'Организацией, осуществляющей экспертизу промышленной безопасности',
        ],
        correctAnswers: [
          'Специализированной организацией, выполняющей работы по монтажу, ремонту, реконструкции (модернизации) оборудования',
        ],
      },
      {
        code: '63694141',
        text:
          'Какое из приведенных требований к эксплуатации транспортных цистерн и бочек указано неверно?',
        answers: [
          'При эксплуатации транспортных цистерн регистрации в государственном реестре ОПО подлежат только те объекты эксплуатирующих организаций, на которых осуществляют хранение и использование цистерн под давлением газов в технологическом процессе',
          'Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом является деятельностью в области промышленной безопасности',
          'Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом не регламентируется требованиями ФНП ОРПД и осуществляется в соответствии с требованиями иных нормативных правовых актов и международных соглашений, действующих на территории Российской Федерации',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'Транспортирование цистерн, а также перевозка бочек под давлением газов по дорогам общего пользования автомобильным (железнодорожным) транспортом является деятельностью в области промышленной безопасности',
        ],
      },
      {
        code: '63694142',
        text:
          'Каково минимальное значение остаточного избыточного давления, которое должно оставаться в опорожняемых потребителем цистернах и бочках?',
        answers: [
          '0,07 МПа',
          '0,05 МПа',
          'Потребитель, опорожняя цистерны и бочки, обязан срабатывать давление полностью, не оставляя в порожнем оборудовании избыточное давление',
          'Значение остаточного избыточного давления указывается в производственной инструкции организации, осуществляющей наполнение цистерн и бочек',
        ],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63694143',
        text:
          'В каком случае допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением?',
        answers: [
          'Если разность избыточных давлений элемента, подлежащего продувке, и емкости составляет не более 0,2 МПа',
          'Если барабан котла не имеет солевых отсеков и рабочее давление котла не превышает 1,3 МПа',
          'Если подтверждены надежность и эффективность продувки соответствующими расчетами',
          'Не допускается отвод воды продувочным трубопроводом в емкость, работающую под давлением',
        ],
        correctAnswers: [
          'Если подтверждены надежность и эффективность продувки соответствующими расчетами',
        ],
      },
      {
        code: '63694144',
        text:
          'Какой документ о подтверждении соответствия требованиям технического регламента Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" должно иметь оборудование, отнесенное к 3-й категории опасности?',
        answers: [
          'Сертификат',
          'Декларация соответствия',
          'Заключение экспертизы промышленной безопасности',
          'Разрешение на применение сосуда',
        ],
        correctAnswers: ['Сертификат'],
      },
      {
        code: '63694145',
        text:
          'В каком из приведенных случаев при проведении гидравлического испытания при эксплуатации сосудов допускается использовать не воду, а другую жидкость?',
        answers: [
          'В технически обоснованных случаях, предусмотренных организацией-изготовителем, проводящей гидравлическое испытание',
          'Если другая жидкость не загрязняет сосуд и не вызывает интенсивную коррозию',
          'В технически обоснованных случаях, предусмотренных организацией-изготовителем',
          'Не допускается, при гидравлическом испытании сосудов используется только вода',
        ],
        correctAnswers: [
          'В технически обоснованных случаях, предусмотренных организацией-изготовителем',
        ],
      },
      {
        code: '63694146',
        text:
          'Какое требование к специалистам и рабочим, обслуживающим сосуды, указано неверно?',
        answers: [
          'Рабочие должны быть не моложе 18-летнего возраста и не иметь медицинских противопоказаний для выполнения работ по обслуживанию сосудов',
          'Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации',
          'Рабочие должны быть допущены в установленном порядке к самостоятельной работе',
          'Рабочие должны соответствовать квалификационным требованиям',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Рабочие должны пройти аттестацию по промышленной безопасности в аттестационной комиссии эксплуатирующей организации',
        ],
      },
      {
        code: '63694147',
        text:
          'В каком из приведенных случаев размещение баллонов с газом на местах потребления должно осуществляться в соответствии с планом (проектом) размещения оборудования?',
        answers: [
          'При размещении групповой баллонной установки',
          'При размещении баллонов в местах проезда транспортных средств',
          'При размещении баллона на расстоянии менее 5 метров от источников тепла с открытым огнем',
        ],
        correctAnswers: ['При размещении групповой баллонной установки'],
      },
      {
        code: '63694148',
        text:
          'Какие из перечисленных данных наносятся клеймением на цистерны и бочки для перевозки сжиженного газа?',
        answers: [
          'Все перечисленные',
          'Клеймо отдела технического контроля',
          'Вместимость',
          'Год изготовления',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63694149',
        text:
          'Какие из перечисленных действий не допускаются перед началом сварки деталей оборудования, работающего под давлением? Выберите 2 варианта ответа.',
        answers: [
          'Проверка качества сборки соединяемых элементов',
          'Проверка состояния стыкуемых кромок и прилегающих к ним поверхностей',
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
          'Подготовка кромок и поверхностей механической обработкой',
        ],
        correctAnswers: [
          'Подгонка кромок ударным способом',
          'Подгонка кромок местным нагревом',
        ],
      },
      {
        code: '63694150',
        text:
          'Какое требование необходимо выполнять при установке на одном патрубке (трубопроводе) нескольких предохранительных клапанов?',
        answers: [
          'Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем',
          'Площадь поперечного сечения патрубка (трубопровода) должна быть равна суммарной площади сечения клапанов, установленных на нем',
          'Площадь поперечного сечения патрубка (трубопровода) должна быть не более 1,25 суммарной площади сечения клапанов, установленных на нем',
        ],
        correctAnswers: [
          'Площадь поперечного сечения патрубка (трубопровода) должна быть не менее 1,25 суммарной площади сечения клапанов, установленных на нем',
        ],
      },
      {
        code: '63694151',
        text:
          'Отсутствие какой документации не является препятствием для осуществления монтажа, ремонта, реконструкции (модернизации) оборудования, работающего под давлением, специализированной организацией?',
        answers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
          'Проектной (конструкторской) и технической документации оборудования под давлением, монтаж, ремонт, реконструкция (модернизация) которого осуществляется',
          'Нормативных документов, необходимость применения которых для обеспечения требований промышленной безопасности, установленных законодательством в области промышленной безопасности при выполнении соответствующих работ установлена специализированной организацией в виде утвержденного перечня или иного распорядительного документа',
          'Технологической документации на производство заявленных видов работ, разработанной до начала этих работ',
        ],
        correctAnswers: [
          'Эксплуатационной документации монтируемого, ремонтируемого, реконструируемого (модернизируемого) оборудования',
        ],
      },
      {
        code: '63694152',
        text:
          'Какие инструкции не разрабатываются в организации, эксплуатирующей сосуды?',
        answers: [
          'Инструкция для ответственного за осуществление производственного контроля',
          'Инструкция для ответственного за исправное состояние и безопасную эксплуатацию сосудов',
          'Производственные инструкции для персонала, осуществляющего обслуживание и ремонт сосудов, определяющие его обязанности, порядок безопасного производства работ и ответственность',
          'Инструкция (руководство) по эксплуатации сосуда',
        ],
        correctAnswers: ['Инструкция (руководство) по эксплуатации сосуда'],
      },
      {
        code: '63694153',
        text:
          'Какие из перечисленных сведений для работников, занятых эксплуатацией оборудования под давлением, не должны быть указаны в инструкциях, устанавливающих действия работников в аварийных ситуациях (в том числе при аварии)?',
        answers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
          'Места отключения вводов электропитания',
          'Места расположения аптечек первой помощи',
          'Способы и методы ликвидации аварий',
        ],
        correctAnswers: [
          'Перечень лиц, имеющих право на оповещение работников ОПО и специализированных служб, привлекаемых к осуществлению действий по локализации аварий',
        ],
      },
      {
        code: '63694154',
        text:
          'Какое из приведенных требований должно выполняться при проведении гидравлического испытания сосуда?',
        answers: [
          'Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации',
          'Давление воды при гидравлическом испытании следует контролировать не менее чем двумя манометрами. Оба манометра выбирают одного типа, предела измерения, одинаковых классов точности (не ниже 2,5) и цены деления',
          'При значении пробного давления не более 0,5 МПа допускается использование сжатого воздуха или другого газа для подъема давления в сосуде, заполненном водой',
          'Общее время подъема давления (до значения пробного) должно быть не менее одного часа',
        ],
        correctAnswers: [
          'Время выдержки под пробным давлением сосуда, находящегося в эксплуатации, должно определяться руководством по эксплуатации',
        ],
      },
      {
        code: '63694155',
        text:
          'При осуществлении каких процессов на ОПО не применяются требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением?',
        answers: [
          'Техническое перевооружение опасного производственного объекта, на котором используются сосуды, работающие под давлением',
          'Техническое освидетельствование сосудов, работающих под давлением',
          'Проектирование и конструирование сосудов, работающих под давлением',
          'Техническое диагностирование и освидетельствование сосудов, работающих под давлением',
        ],
        correctAnswers: [
          'Проектирование и конструирование сосудов, работающих под давлением',
        ],
      },
      {
        code: '63694156',
        text:
          'На каком этапе разрабатывается руководство (инструкция) по эксплуатации оборудования?',
        answers: [
          'На этапе разработки (проектирования) оборудования',
          'На этапе передачи оборудования поставщику (потребителю)',
          'На этапе монтажа оборудования и проведения пусконаладочных работ',
        ],
        correctAnswers: ['На этапе разработки (проектирования) оборудования'],
      },
      {
        code: '63694157',
        text:
          'Какой документ является основным для идентификации оборудования, работающего под избыточным давлением?',
        answers: [
          'Сертификат соответствия оборудования требованиям ТР ТС 032/2013',
          'Обоснование безопасности оборудования',
          'Паспорт оборудования',
          'Декларация соответствия оборудования требованиям промышленной безопасности',
        ],
        correctAnswers: ['Паспорт оборудования'],
      },
      {
        code: '63694158',
        text:
          'По какой из приведенных формул определяется значение пробного давления (Рпр) при пневматическом испытании сосудов?  Где в формулах: Рраб – рабочее давление сосуда, [σ]20, [σ]t - допускаемые напряжения для материала сосуда или его элементов соответственно при 20 оС и расчетной температуре, МПа.',
        answers: [
          'Рпр = 1,15 Рраб([σ]20 / [σ]t)',
          'Рпр = Pраб',
          'Рпр = 1,25 Рраб([σ]20 / [σ]t)',
          'Рпр = 1,5 Рраб',
        ],
        correctAnswers: ['Рпр = 1,15 Рраб([σ]20 / [σ]t)'],
      },
      {
        code: '63694159',
        text:
          'Какая ширина поверхности сварного шва и прилегающих к нему участков основного металла должны быть зачищены от шлака и других загрязнений перед визуальным контролем?',
        answers: [
          'Не менее 20 мм в обе стороны от шва',
          'Не менее 10 мм в обе стороны от шва',
          'Не менее 30 мм в обе стороны от шва',
          'Не менее 40 мм в обе стороны от шва',
        ],
        correctAnswers: ['Не менее 20 мм в обе стороны от шва'],
      },
      {
        code: '63694160',
        text:
          'Каково минимальное значение температуры воды, используемой для гидравлического испытания сосуда (если конкретное значение не указано в технической документации организации-изготовителя)?',
        answers: ['4 °C', '5 °C', '10 °C', '80 °C'],
        correctAnswers: ['5 °C'],
      },
      {
        code: '63694161',
        text:
          'На кого возлагается контроль за соблюдением требований ремонтных рабочих чертежей и технологической документации на ремонт?',
        answers: [
          'На ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под избыточным давлением',
          'На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации',
          'Контроль осуществляется совместно эксплуатирующей организацией и представителем Ростехнадзора',
        ],
        correctAnswers: [
          'На подразделение технического контроля организации, выполняющей работы по ремонту, реконструкции (модернизации) оборудования, а также на уполномоченного представителя эксплуатирующей организации',
        ],
      },
      {
        code: '63694162',
        text:
          'Что из приведенного не входит в должностные обязанности ответственного за осуществление производственного контроля за безопасной эксплуатацией сосудов?',
        answers: [
          'Выдача обязательных для исполнения предписаний по устранению нарушений и контроль их выполнения',
          'Проверка записи в сменном журнале с росписью в нем',
          'Контроль проведения противоаварийных тренировок',
          'Отстранение от работ работников, нарушающих требования промышленной безопасности',
        ],
        correctAnswers: ['Проверка записи в сменном журнале с росписью в нем'],
      },
      {
        code: '63694163',
        text:
          'Какая информация из приведенной не включается в маркировку, наносимую на оборудование, работающее под давлением?',
        answers: [
          'Наименование и (или) обозначение оборудования',
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
          'Параметры и характеристики, влияющие на безопасность',
          'Наименование материала, из которого изготовлено оборудование',
          'Вся приведенная информация включается в маркировку, наносимую на оборудование',
        ],
        correctAnswers: [
          'Сведения о подтверждении соответствия требованиям ТР ТС 032/2013',
        ],
      },
      {
        code: '63694164',
        text:
          'Какое количество жидкого кислорода, азота (криогенной жидкости) в килограммах принимают за расчетную испаряемость?',
        answers: [
          'Которое может испаряться в течение часа под действием тепла, получаемого цистерной из окружающей среды при температуре наружного воздуха 50°C',
          'Которое может испаряться в течение часа под действием тепла, получаемого цистерной из окружающей среды при температуре наружного воздуха 30°C',
          'Которое может испаряться в течение двух часов под действием тепла, получаемого цистерной из окружающей среды при температуре наружного воздуха 50°C',
          'Которое может испаряться в течение двух часов под действием тепла, получаемого цистерной из окружающей среды при температуре наружного воздуха 30°C',
        ],
        correctAnswers: [
          'Которое может испаряться в течение часа под действием тепла, получаемого цистерной из окружающей среды при температуре наружного воздуха 50°C',
        ],
      },
      {
        code: '63694165',
        text:
          'Какие требования к обоснованию безопасности согласно Техническому регламенту Таможенного союза "О безопасности оборудования, работающего под избыточным давлением" указаны неверно?',
        answers: [
          'Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя',
          'Обоснование безопасности оборудования готовится на этапе разработки (проектирования) оборудования',
          'В обосновании безопасности приводятся анализ рисков для оборудования, а также минимально необходимые меры по обеспечению безопасности',
          'Оригинал обоснования безопасности оборудования хранится у разработчика (проектировщика)',
        ],
        correctAnswers: [
          'Обоснование безопасности содержит сведения о проведенных испытаниях (измерениях), сертификаты (декларации) соответствия, а также документы, подтверждающие квалификацию специалистов и персонала изготовителя',
        ],
      },
      {
        code: '63694166',
        text:
          'На каком сосуде установка манометра и предохранительного клапана необязательна?',
        answers: [
          'На сосуде, включенном в технологический процесс, в котором давление рабочей среды поддерживается на постоянном уровне и при условии исключения возможности повышения давления в сосуде.',
          'На сосуде, включенном в группу сосудов, при эксплуатации которой манометр и предохранительный клапан установлены на подводящем трубопроводе до первого ответвления к одному из сосудов и при условии исключения возможности повышения давления в сосуде (обогрев, химическая реакция пожар)',
          'На сосуде, у которого рабочее давление равно или больше давления питающего источника и при условии исключения возможности повышения давления в сосуде',
          'Манометр и предохранительный клапан обязательно должны быть установлены на каждом сосуде',
        ],
        correctAnswers: [
          'На сосуде, у которого рабочее давление равно или больше давления питающего источника и при условии исключения возможности повышения давления в сосуде',
        ],
      },
      {
        code: '63694167',
        text:
          'Каким документом определяется порядок действия в случае инцидента при эксплуатации сосуда?',
        answers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
          'Правилами промышленной безопасности при использовании оборудования, работающего под избыточным давлением',
          'Инструкцией (руководством) по эксплуатации организации-изготовителя сосуда',
        ],
        correctAnswers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
        ],
      },
      {
        code: '63694168',
        text:
          'Для каких бочек наливной и сливной вентили должны оснащаться сифоном?',
        answers: [
          'Бочек, предназначенных для горючих газов',
          'Бочек, предназначенных для хлора и фосгена',
          'Бочек, предназначенных для рабочих сред, отнесенных к группе 1 в соответствии с ТР ТС 032/2013',
          'Для всех бочек, на которые распространяется ФНП ОРПД',
        ],
        correctAnswers: ['Бочек, предназначенных для хлора и фосгена'],
      },
      {
        code: '63694169',
        text:
          'В каком случае необходимо проводить металлографические исследования?',
        answers: [
          'Все ответы неверны',
          'Для сварных соединений сосудов и их элементов, изготовленных из сталей аустенитного класса, толщиной не более 20 мм',
          'Для сварных соединений котлов и трубопроводов, изготовленных из стали перлитного класса, при условии проведения ультразвукового или радиографического контроля этих соединений в объеме 100%',
          'Для сварных соединений труб поверхностей нагрева котлов и трубопроводов, выполненных контактной сваркой на специальных машинах для контактной стыковой сварки с автоматизированным циклом работ при ежесменной проверке качества наладки машины путем испытания контрольных образцов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63694170',
        text:
          'Что из перечисленного допускается при эксплуатации сосудов под давлением?',
        answers: [
          'Все ответы неверны',
          'Отбор рабочей среды из патрубков (и на участках присоединительных трубопроводов от сосуда до клапанов), на которых установлены предохранительные устройства',
          'Установка запорной арматуры между сосудом и предохранительным устройством',
          'Установка запорной арматуры за предохранительным устройством',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
    ],
  },
  2: {
    63650: [
      {
        code: '63650000',
        text:
          'В каком случае допускается ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара?',
        answers: [
          'Для определения качества нефти и нефтепродуктов, хранящихся в резервуарах',
          'Для вновь строящихся и реконструируемых резервуаров',
          'Для коммерческого учета нефти и нефтепродуктов, хранящихся в резервуарах',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63650001',
        text:
          'Кем утверждается проектная документация на разработку месторождений полезных ископаемых?',
        answers: [
          'Росприроднадзором',
          'Ростехнадзором',
          'Роснедрами',
          'Пользователем недр',
        ],
        correctAnswers: ['Пользователем недр'],
      },
      {
        code: '63650002',
        text:
          'В какой период, установленный Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых, осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?',
        answers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
          'С 1 октября по 31 декабря года, предшествующего планируемому',
          'С 1 сентября по 15 декабря года, предшествующего планируемому',
          'С 1 октября по 15 декабря года, предшествующего планируемому',
        ],
        correctAnswers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
      },
      {
        code: '63650003',
        text:
          'В каком случае допускаются отклонения от действующего проекта, на основании которого проводятся работы, связанные с восстановлением работоспособного (исправного) состояния технологических трубопроводов?',
        answers: [
          'По решению комиссии эксплуатирующей организации',
          'При наличии письменного распоряжения технического руководителя эксплуатирующей организации',
          'В случае согласования необходимого отклонения с территориальным органом Ростехнадзора',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: ['Не допускаются ни в каком случае'],
      },
      {
        code: '63650004',
        text:
          'В соответствии с каким документом должен быть разработан рабочий проект на производство буровых работ?',
        answers: [
          'В соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
          'В соответствии с Правилами устройства и безопасной эксплуатации сосудов, работающих под давлением',
          'В соответствии с федеральными нормами и правилами в области промышленной безопасности "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"',
          'В соответствии с Правилами промышленной безопасности для нефтеперерабатывающих производств',
        ],
        correctAnswers: [
          'В соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
        ],
      },
      {
        code: '63650005',
        text:
          'В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?',
        answers: [
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
          'Не позднее чем за 10 дней до установленной даты их рассмотрения',
          'Не позднее чем за 7 дней до установленной даты их рассмотрения',
          'Не позднее чем за 5 дней до установленной даты их рассмотрения',
        ],
        correctAnswers: [
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
        ],
      },
      {
        code: '63650006',
        text:
          'Какое из перечисленных требований к подземным технологическим трубопроводам, прокладываемым непосредственно в грунте, в местах пересечения автомобильных дорог и железных дорог, указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Концы защитных труб, в которых размещаются подземные технологические трубопроводы, должны отстоять от головки рельсов или от бровки обочины дороги не менее чем на 2 м',
          'Должны быть размещены в защитных пластиковых трубах',
          'Расстояние от верхней образующей защитной трубы до подошвы шпалы железнодорожного пути должно быть не менее 1 м',
          'Расстояние от верхней образующей защитной трубы до бровки полотна автодороги должно быть не менее 1 м',
        ],
        correctAnswers: [
          'Концы защитных труб, в которых размещаются подземные технологические трубопроводы, должны отстоять от головки рельсов или от бровки обочины дороги не менее чем на 2 м',
          'Расстояние от верхней образующей защитной трубы до подошвы шпалы железнодорожного пути должно быть не менее 1 м',
        ],
      },
      {
        code: '63650007',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'В помещениях управления не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
          'В отдельных случаях при соответствующем обосновании в проекте разрешено пристраивать их к зданиям',
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'Помещения управления должны быть отдельно стоящими',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63650008',
        text:
          'Какова величина нормативной санитарно-защитной зоны для промышленных объектов по добыче природного газа с высоким содержанием сероводорода (более 1,5 - 3%) и меркаптанов?',
        answers: [
          'До 8000 м',
          'Не менее 5000 м',
          '1000 м',
          '100 м',
          '300 м',
          '500 м',
        ],
        correctAnswers: ['Не менее 5000 м'],
      },
      {
        code: '63650009',
        text:
          'С кем согласовываются изменения в проекте, потребность в которых выявляется в процессе изготовления, монтажа, реконструкции и ремонта технологических трубопроводов?',
        answers: [
          'С территориальным органом Ростехнадзора',
          'С научно-исследовательской организацией',
          'Изменения в проекте не допускаются',
          'С разработчиком проекта или с другой проектной организацией, имеющей право на проведение указанной работы',
        ],
        correctAnswers: [
          'С разработчиком проекта или с другой проектной организацией, имеющей право на проведение указанной работы',
        ],
      },
      {
        code: '63650010',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Газопроводы, транспортирующие газ на факельные установки, испытанию на прочность и плотность не подлежат',
          'Давление при испытании должно контролироваться средством измерения давления с классом точности не ниже 2,5',
        ],
        correctAnswers: [
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
        ],
      },
      {
        code: '63650011',
        text:
          'На какую высоту укладки труб в штабель рассчитываются стеллажи приемного моста?',
        answers: [
          'Высотой не более 1000 мм',
          'Высотой не более 1250 мм',
          'Высотой не более 1750 мм',
          'Высотой не более 1500 мм',
        ],
        correctAnswers: ['Высотой не более 1250 мм'],
      },
      {
        code: '63650012',
        text:
          'Какова периодичность зачистки металлических резервуаров для хранения нефтепродуктов?',
        answers: [
          'Не менее 1 раза в год - для авиационного топлива и не менее 2-х раз в год - для остальных светлых нефтепродуктов и масел',
          'Не менее 2-х раз в год для любых нефтепродуктов',
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
        ],
        correctAnswers: [
          'Не менее 2-х раз в год - для авиационного топлива и не менее 1 раза в 2 года - для остальных светлых нефтепродуктов и масел',
        ],
      },
      {
        code: '63650013',
        text:
          'При каком достижении горючих газов и паров нефтепродуктов осуществляется включение аварийной вентиляции в помещениях насосных станций нефти и нефтепродуктов?',
        answers: [
          '50% объемных от НКПРП',
          '40% объемных от НКПРП',
          '30% объемных от НКПРП',
          '20% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63650014',
        text:
          'Каким образом определяется время срабатывания запорных и (или) отсекающих устройств для каждого технологического блока?',
        answers: [
          'Время срабатывания определяется расчетом для технологических блоков III категории взрывоопасности и установлено для блоков I и II категорий',
          'Время срабатывания определяется расчетом для технологических блоков I и II категорий взрывоопасности и установлено для блоков III категории',
          'Время срабатывания установлено для каждого технологического блока в соответствии с категорией взрывоопасности',
          'Время срабатывания определяется расчетом',
        ],
        correctAnswers: ['Время срабатывания определяется расчетом'],
      },
      {
        code: '63650015',
        text:
          'Каким способом срабатывает система аварийного разъединения стендеров для предотвращения пролива нефтепродуктов?',
        answers: [
          'Дистанционно с учетом нажатия кнопки на центральном пульте управления',
          'Автоматически, когда стендер достигает обусловленного граничного положения',
          'Вручную посредством управления гидравлическими клапанами в случае прекращения подачи электроэнергии на терминал',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63650016',
        text:
          'В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В течение 15 дней с назначенной в графике даты рассмотрения планов и схем развития горных работ',
          'В назначенный день рассмотрения планов и (или) схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
      },
      {
        code: '63650017',
        text:
          'Каким органом определяются требования к структуре и оформлению технических проектов строительства и эксплуатации подземных сооружений, технических проектов ликвидации и консервации горных выработок, буровых скважин и иных сооружений, связанных с пользованием недрами?',
        answers: [
          'Министерством природных ресурсов и экологии Российской Федерации',
          'Федеральной службой по экологическому, технологическому и атомному надзору',
          'Министерством энергетики Российской Федерации',
          'Министерством строительства и жилищно-коммунального хозяйства',
        ],
        correctAnswers: [
          'Министерством природных ресурсов и экологии Российской Федерации',
        ],
      },
      {
        code: '63650018',
        text:
          'Какая санитарно-защитная зона устанавливается для установок сжижения природного газа, расположенных на газопроводах, месторождениях и газораспределительных станциях магистральных газопроводов, с объемом хранения сжиженного природного газа от 1000 м³, II класса опасности?',
        answers: ['300 м', '500 м', '100 м', '1000 м'],
        correctAnswers: ['500 м'],
      },
      {
        code: '63650019',
        text:
          'Какое наименьшее расстояние от устья нефтяных скважин со станками-качалками, устья нагнетательных скважин до общественных зданий?',
        answers: ['150 м', '200 м', '350 м', '250 м', '300 м'],
        correctAnswers: ['250 м'],
      },
      {
        code: '63650020',
        text:
          'На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'На основании задания на проектирование',
          'На основании справочных данных на проектирование',
          'На основании литературных данных на проектирование',
          'На основании расчетных данных на проектирование',
        ],
        correctAnswers: ['На основании задания на проектирование'],
      },
      {
        code: '63650021',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.',
        answers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Быть мобильными, иметь небольшие габариты и вес',
          'Переход из режима ожидания в рабочее состояние должен осуществляться в течение 30 с',
        ],
        correctAnswers: [
          'Обеспечивать минимально возможное время освобождения',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
        ],
      },
      {
        code: '63650022',
        text:
          'В течение какого времени после ввода резервуара в эксплуатацию необходимо ежегодно проводить нивелирование окрайки днища в абсолютных отметках?',
        answers: [
          'В течение года',
          'В течение пяти лет',
          'В течение шести лет',
          'В течение четырех лет',
        ],
        correctAnswers: ['В течение четырех лет'],
      },
      {
        code: '63650023',
        text:
          'В каком случае допускается укладка технологических трубопроводов в два яруса и более?',
        answers: [
          'Диаметром до 300 мм включительно',
          'Не допускается ни в каком случае',
          'Диаметром до 500 мм включительно',
          'Диаметром до 400 мм включительно',
        ],
        correctAnswers: ['Диаметром до 300 мм включительно'],
      },
      {
        code: '63650024',
        text:
          'Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?',
        answers: [
          'Оценку эффективности технических средств противоаварийной защиты, направленных на обеспечение взрывобезопасности технологических блоков и в целом всей технологической схемы',
          'Оценку энергетического уровня каждого технологического блока и определение категории его взрывоопасности',
          'В проектной документации производится оценка всех перечисленных параметров',
          'Оценку эффективности и надежности мер, обеспечивающих взрывобезопасности каждого технологического блока',
        ],
        correctAnswers: [
          'В проектной документации производится оценка всех перечисленных параметров',
        ],
      },
      {
        code: '63650025',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите 2 правильных варианта ответа.',
        answers: [
          'Регламентация режимов и порядка пуска и остановки оборудования',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
          'Поддержание избыточного давления инертного газа в системе во время остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающих образование застойных зон',
        ],
        correctAnswers: [
          'Регламентация режимов и порядка пуска и остановки оборудования',
          'Разработка способов продувки оборудования инертными газами, исключающих образование застойных зон',
        ],
      },
      {
        code: '63650026',
        text:
          'Каким должно быть покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков, а также для смыва пролитых нефтепродуктов?',
        answers: ['Деревянным', 'Глинобитным', 'Асфальтированным', 'Бетонным'],
        correctAnswers: ['Бетонным'],
      },
      {
        code: '63650027',
        text: 'Что относится к технологическим трубопроводам?',
        answers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
          'Трубопроводы, предназначенные для транспортирования различных веществ, необходимых для ведения технологического процесса или эксплуатации оборудования',
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия сырья, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
        correctAnswers: [
          'Трубопроводы, предназначенные для перемещения в пределах промышленного предприятия или группы этих предприятий сырья, полуфабрикатов, готового продукта, вспомогательных материалов, включающих в том числе пар, воду, воздух, газы, хладагенты, смазки, эмульсии, и обеспечивающие ведение технологического процесса и эксплуатацию оборудования',
        ],
      },
      {
        code: '63650028',
        text:
          'Какие из перечисленных требований к буровым лебедкам указаны неверно?',
        answers: [
          'Конструкция барабана лебедки должна обеспечивать крепление подвижной ветви талевого каната, исключающее возможность его смятия или перегиба, самопроизвольного ослабления или отсоединения в месте крепления',
          'В конструкции барабана лебедки необходимо предусматривать специальные накладки с канавками для плотной и равномерной намотки первого ряда талевого каната. Накладки должны быть съемными и выполняться под различные диаметры применяемых талевых канатов',
          'Тормозной механизм лебедки должен иметь не менее трех независимых систем управления, одна из которых (основная) должна обеспечивать плавное регулирование тормозного момента',
          'Все перечисленные требования указаны верно',
          'Конструкция тормоза должна исключать возможность самопроизвольного торможения или растормаживания барабана лебедки',
        ],
        correctAnswers: [
          'Тормозной механизм лебедки должен иметь не менее трех независимых систем управления, одна из которых (основная) должна обеспечивать плавное регулирование тормозного момента',
        ],
      },
      {
        code: '63650029',
        text:
          'На какой срок может быть продлено рассмотрение планов и (или) схем развития горных работ по решению органа государственного горного надзора, в случае необходимости проведения оценки обоснования соблюдения условий безопасного недропользования?',
        answers: [
          'На срок не более 30 дней с назначенной даты их рассмотрения',
          'На срок не более 20 дней с назначенной даты их рассмотрения',
          'На срок не более 40 дней с назначенной даты их рассмотрения',
          'На срок не более 15 дней с назначенной даты их рассмотрения',
        ],
        correctAnswers: [
          'На срок не более 30 дней с назначенной даты их рассмотрения',
        ],
      },
      {
        code: '63650030',
        text:
          'На каком минимальном расстоянии необходимо находиться от молниеотводов во время грозы?',
        answers: ['3 м', '4 м', '5 м', '10 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63650031',
        text:
          'Какая высота столба жидкости в гидравлическом затворе, установленном на колодце, за пределами обвалования?',
        answers: [
          'Не менее 0,25 м',
          'Не менее 0,75 м',
          'Не менее 0,5 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 0,25 м'],
      },
      {
        code: '63650032',
        text:
          'Какие из перечисленных компенсаторов допускается применять для технологических трубопроводов всех категорий?',
        answers: [
          'П-образные компенсаторы',
          'Г-образные компенсаторы',
          'Z-образные компенсаторы',
          'Все перечисленные компенсаторы',
        ],
        correctAnswers: ['Все перечисленные компенсаторы'],
      },
      {
        code: '63650033',
        text:
          'На какой срок составляется план развития горных работ по всем планируемым видам работ?',
        answers: ['На 1 год', 'На 3 года', 'На 5 лет'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63650034',
        text:
          'Кем осуществляется подготовка планов и схем развития горных работ?',
        answers: [
          'Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ',
          'Только пользователем недр',
          'Пользователем недр или проектной организацией',
        ],
        correctAnswers: [
          'Пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ',
        ],
      },
      {
        code: '63650035',
        text:
          'Какой из перечисленных материалов допускается применять для технологических трубопроводов, подверженных ударным нагрузкам и вибрации?',
        answers: [
          'Порошкообразные теплоизоляционные материалы',
          'Допускаются все перечисленные материалы',
          'Базальтовое супертонкое волокно',
          'Вату из непрерывного стеклянного волокна',
        ],
        correctAnswers: ['Базальтовое супертонкое волокно'],
      },
      {
        code: '63650036',
        text:
          'Какой документацией определяется электроснабжение электроприемников по категории надежности опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Технологическим регламентом',
          'Руководствами по безопасности',
          'Эксплуатационной документацией',
          'Проектной документацией (документацией на техническое перевооружение)',
        ],
        correctAnswers: [
          'Проектной документацией (документацией на техническое перевооружение)',
        ],
      },
      {
        code: '63650037',
        text:
          'Какое из перечисленных требований к прокладке и устройству технологических трубопроводов указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
          'Технологические трубопроводы в производственных помещениях должны прокладываться закрыто',
          'Сварные и разъемные соединения трубопроводов внутри футляров или гильз допускаются в соответствии с проектом',
        ],
        correctAnswers: [
          'На трубопроводах выброса в атмосферу от аппаратов, содержащих взрыво- и пожароопасные среды, должны устанавливаться огнепреградители',
          'Технологические трубопроводы, проходящие через стены или перекрытия зданий, следует заключать в специальные гильзы или футляры',
        ],
      },
      {
        code: '63650038',
        text:
          'Бортиками какой высоты должно ограждаться по периметру покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: [
          'Не менее 0,2 м',
          'Не менее 0,4 м',
          'Не менее 0,5 м',
          'Не менее 0,6 м',
        ],
        correctAnswers: ['Не менее 0,2 м'],
      },
      {
        code: '63650039',
        text:
          'Что из нижеперечисленного не содержится в рабочем проекте на производство буровых работ?',
        answers: [
          'Ф. И. О. ответственного лица за производство буровых работ',
          'Географическая и климатическая характеристики района работ',
          'Объем исследования стратиграфического разреза в процессе бурения для уточнения пластовых давлений и состава флюида',
          'Обоснование плотности бурового раствора и диапазон колебаний других параметров промывочной жидкости',
        ],
        correctAnswers: [
          'Ф. И. О. ответственного лица за производство буровых работ',
        ],
      },
      {
        code: '63650040',
        text:
          'На какое минимальное расстояние должны не доходить защитные боковые ограждения открытых насосных станций до пола и покрытия (перекрытия) насосной станции?',
        answers: [
          'На 1 м',
          'На 0,5 м',
          'На 0,3 м',
          'Должны полностью доходить до пола и покрытия (перекрытия) насосной станции',
        ],
        correctAnswers: ['На 0,3 м'],
      },
      {
        code: '63650041',
        text:
          'Какие требования к оборудованию циркуляционной системы бурового оборудования указаны верно?',
        answers: [
          'Гидромониторы и сопла гидромешалок должны иметь скрытую установку с применением специального инструмента',
          'Емкости должны иметь люки для слива жидкости и обслуживания',
          'Люк для обслуживания должен иметь размеры не менее 500 x 500 мм. Нижняя кромка сливного люка должна быть у самого дна емкости',
          'Углы поворота гидравлических перемешивающих устройств (гидромониторов) в горизонтальной и вертикальной плоскости не должны менять направление потока жидкости более чем на 60°',
        ],
        correctAnswers: [
          'Емкости должны иметь люки для слива жидкости и обслуживания',
        ],
      },
      {
        code: '63650042',
        text:
          'Какие данные не указываются в рабочем проекте на бурение скважин?',
        answers: [
          'Типы нейтрализаторов, методы и технология нейтрализации сернистого водорода в буровом растворе, а также расход реагентов для этих целей на весь процесс бурения скважины',
          'Мероприятия по предупреждению и раннему обнаружению газонефтеводопроявлений',
          'Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн',
          'Методы и периодичность проверки износа и контроля коррозионного состояния бурильных, ведущих, насосно-компрессорных труб и элементов трубных колонн',
          'Условия расчета обсадных и насосно-компрессорных (лифтовых) колонн исходя из порогового напряжения сталей труб, принимаемых не выше 0,75 от предела текучести',
        ],
        correctAnswers: [
          'Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн',
        ],
      },
      {
        code: '63650043',
        text:
          'Из какого материала следует выполнять соединение между собой неподвижных металлических конструкций (резервуары, трубопроводы), а также присоединение их к заземлителям? Выберите 2 правильных варианта ответа.',
        answers: [
          'Из полосовой стали сечением не менее 48 мм²',
          'Из стальной ленты сечением не менее 24 мм²',
          'Из стальной проволоки диаметром не менее 5 мм',
          'Из круглой стали диаметром более 6 мм',
        ],
        correctAnswers: [
          'Из полосовой стали сечением не менее 48 мм²',
          'Из круглой стали диаметром более 6 мм',
        ],
      },
      {
        code: '63650044',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?',
        answers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
          'Отсутствие геологического и маркшейдерского обеспечения горных работ',
          'Выявление недостоверных сведений в представленных документах',
          'Отсутствие обоснования соблюдения условий безопасного недропользования',
        ],
        correctAnswers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63650045',
        text:
          'В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?',
        answers: [
          'Автоматические предохранительные клапаны устанавливаются на причале в любом случае',
          'При расположении береговых насосов более чем в 100 м от стендера',
          'При расположении береговых насосов более чем в 50 м от стендера',
          'Если это предусмотрено проектной документацией',
        ],
        correctAnswers: [
          'При расположении береговых насосов более чем в 100 м от стендера',
        ],
      },
      {
        code: '63650046',
        text:
          'В каком из перечисленных случаев должны срабатывать быстродействующие отключающие системы (автоматические устройства) на сливоналивных эстакадах?',
        answers: [
          'При выдаче заданной нормы',
          'При нарушении целостности цепи заземления железнодорожной цистерны',
          'При достижении предельного уровня заполнения железнодорожной цистерны',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63650047',
        text:
          'На каком расстоянии от нефтеловушек необходимо устраивать на канализационной сети колодцы с гидравлическим затвором?',
        answers: [
          'В непосредственной близости от нефтеловушек',
          'Не менее 15 м после нефтеловушек',
          'Не менее 10 м до и после нефтеловушек',
        ],
        correctAnswers: ['Не менее 10 м до и после нефтеловушек'],
      },
      {
        code: '63650048',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений пересечение трубопроводов с токсичными жидкостями и газами с железнодорожными подъездными путями?',
        answers: [
          'Допускается при условии их прокладки по глухой стене не ниже II степени огнестойкости',
          'Допускается',
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
          'Не допускается',
        ],
        correctAnswers: [
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
        ],
      },
      {
        code: '63650049',
        text:
          'Какие требования к органам систем управления бурового оборудования для нефтяной и газовой промышленности указаны неверно?',
        answers: [
          'Толкатели кнопок должны выполняться заподлицо с панелью; кнопка "Пуск" должна быть утоплена от 3 до 5 мм от поверхности, аварийная кнопка "Стоп" должна иметь грибовидную форму, увеличенный размер и выступать над панелью',
          'При размещении органов управления на одной панели, а связанных с ними индикаторов - на другой относительное расположение элементов на обеих панелях должно быть одинаковым',
          'У рукояток (кнопок) органов управления должны быть четкие и несмываемые надписи, указывающие назначение и направление вызываемых движений; при расстоянии надписей от оператора до 900 мм высота цифр должна быть 6 мм, при расстоянии более 900 мм - 8 мм',
          'При необходимости одновременного включения двух соседних переключателей обеими руками расстояние между ними должно быть не менее 55 мм, а при маневрировании в каждый момент только одним переключателем - не менее 15 мм',
          'Показывающие приборы и органы управления основным оборудованием (лебедкой, ротором и др.), необходимые для оперативного контроля и управления оборудованием, должны быть сосредоточены на пультах управления бурильщика',
        ],
        correctAnswers: [
          'При необходимости одновременного включения двух соседних переключателей обеими руками расстояние между ними должно быть не менее 55 мм, а при маневрировании в каждый момент только одним переключателем - не менее 15 мм',
        ],
      },
      {
        code: '63650050',
        text:
          'Чем должны быть оборудованы аппараты со взрывопожароопасными веществами?',
        answers: [
          'Виброситом',
          'Циклонами',
          'Устройствами для подключения линий водорода',
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
        correctAnswers: [
          'Устройствами для подключения линий воды, пара, инертного газа',
        ],
      },
      {
        code: '63650051',
        text:
          'Какие требования к нагнетательному трубопроводу буровых насосов указаны неверно?',
        answers: [
          'При нагнетательном трубопроводе должен быть предусмотрен отвод с запорным устройством для закачивания жидкости в затрубное пространство через крестовину превентора',
          'Нагнетательный трубопровод должен иметь пусковое запорное устройство, позволяющее производить запуск насосов без нагрузки и их постепенный вывод на рабочий режим. На буровых установках с регулируемым приводом бурового насоса установка пусковых задвижек не обязательна',
          'Трубопроводы должны быть проложены с минимальным числом поворотов и изгибов. Поворот трубопровода не должен менять направление потока жидкости более чем на 60°',
          'Конструкции соединений нагнетательного трубопровода должны исключать возможность травмирования персонала в случае повреждения уплотнения',
        ],
        correctAnswers: [
          'Трубопроводы должны быть проложены с минимальным числом поворотов и изгибов. Поворот трубопровода не должен менять направление потока жидкости более чем на 60°',
        ],
      },
      {
        code: '63650052',
        text:
          'На какие категории подразделяются скважины, подлежащие ликвидации?',
        answers: [
          'I - скважины, ликвидируемые по геологическим причинам; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по технологическим, экономическим и другим причинам',
          'I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по технологическим, экологическим и другим причинам; IV - скважины, ликвидируемые по причине нерентабельности (низкодебитные)',
          'I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам',
        ],
        correctAnswers: [
          'I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам',
        ],
      },
      {
        code: '63650053',
        text:
          'Каким образом применяются материалы, сборочные единицы и детали трубопроводов, изготовленные по нормативным документам иностранных государств?',
        answers: [
          'Если это определено и обосновано разработчиком проекта',
          'Применение данных материалов, сборочных единиц и деталей трубопроводов не допускается',
          'Если это определено и обосновано научно-исследовательской организацией',
          'По решению территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Если это определено и обосновано разработчиком проекта',
        ],
      },
      {
        code: '63650054',
        text:
          'В каком случае пользователь недр имеет право принимать оперативные решения по рациональному использованию фонда скважин всех назначений без внесения изменений в ТСР, ТПР и дополнения к ним?',
        answers: [
          'В случае изменения местоположения, назначения, конструкции проектных скважин на локальных (не более 10 % от проектного фонда скважин) участках продуктивного пласта, входящих в единый эксплуатационный объект по результатам уточнения геологического строения или изысканий на местности',
          'В случае отмены проектных скважин на участках сокращения площади залежи',
          'В случае перевода скважин, выполнивших проектное назначение, на другой эксплуатационный объект',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63650055',
        text:
          'Какая допускается скорость понтона (плавающей крыши) резервуаров при сдвиге?',
        answers: [
          'Не более 1,5 м/ч',
          'Не более 2,5 м/ч',
          'Не более 3,5 м/ч',
          'Не более 2,0 м/ч',
        ],
        correctAnswers: ['Не более 2,5 м/ч'],
      },
      {
        code: '63650056',
        text:
          'Каким должно быть расстояние по горизонтали от грани наземной части ближайшей опоры эстакады до оси железнодорожного пути нормальной колеи и до бордюра автомобильной дороги при пересечении высокими эстакадами железнодорожных путей и автомобильных дорог?',
        answers: [
          'Не менее 2,65 м и не менее 1,35 м соответственно',
          'Не менее 2,2 м и не менее 1,2 м соответственно',
          'Не менее 2,45 м и не менее 1,0 м соответственно',
          'Не менее 2,5 м и не менее 1,5 м соответственно',
        ],
        correctAnswers: ['Не менее 2,45 м и не менее 1,0 м соответственно'],
      },
      {
        code: '63650057',
        text:
          'Что из перечисленного должно быть учтено при выборе компрессоров и насосов для перемещения горючих, сжатых и сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Только параметры технологического процесса',
          'Только физико-химические свойства перемещаемых продуктов',
          'Только показатели надежности и конструктивные особенности с учетом критических параметров',
          'Должны быть учтены все перечисленные характеристики',
        ],
        correctAnswers: ['Должны быть учтены все перечисленные характеристики'],
      },
      {
        code: '63650058',
        text:
          'Что используется в качестве теплоносителей на технологических трубопроводах складов нефти и нефтепродуктов?',
        answers: [
          'Только пар',
          'Только электрообогрев',
          'Только промтеплофикационная вода',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63650059',
        text:
          'Какое из нижеперечисленных положений не соответствует требованиям, предъявляемым к талевой системе?',
        answers: [
          'Конструкция крюка и талевого блока должна обеспечивать равномерное распределение нагрузки на подвешенные к нему штропы',
          'Резьбовое соединение ствола крюка с упорной гайкой должно иметь устройство, исключающее самопроизвольное отворачивание гайки',
          'Ствол крюка должен иметь устройство для принудительного стопорения вращения при технологической необходимости',
          'Конструкция крюка должна обеспечивать самопроизвольное выпадение элеваторных штропов из боковых рогов',
        ],
        correctAnswers: [
          'Конструкция крюка должна обеспечивать самопроизвольное выпадение элеваторных штропов из боковых рогов',
        ],
      },
      {
        code: '63650060',
        text:
          'При достижении какой концентрации горючих газов и паров нефтепродуктов предусматривается автоматическое включение аварийной вентиляции?',
        answers: [
          '40% объемных от НКПРП',
          '30% объемных от НКПРП',
          '50% объемных от НКПРП',
          '20% объемных от НКПРП',
        ],
        correctAnswers: ['20% объемных от НКПРП'],
      },
      {
        code: '63650061',
        text:
          'Что не должна исключать энергетическая устойчивость технологической системы с учетом категории взрывоопасности входящих в нее блоков, особенностей технологического процесса?',
        answers: [
          'Возможность образования в системе взрывоопасной среды (за счет увеличения времени пребывания продуктов в реакционной зоне, нарушения соотношения поступающих в нее продуктов, развития неуправляемых процессов)',
          'Возможность нарушения герметичности системы (разгерметизации уплотнений подвижных соединений, разрушения оборудования от превышения давления)',
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
        ],
        correctAnswers: [
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
        ],
      },
      {
        code: '63650062',
        text:
          'Что из перечисленного должно отводиться в производственную канализацию на объектах складов нефти и нефтепродуктов?',
        answers: [
          'Пожароопасные нефтепродукты',
          'Сточные воды от зачистки и пропарки резервуаров для нефти и нефтепродуктов',
          'Нефтешламы',
          'Подтоварные воды от отстоя нефти и нефтепродуктов',
        ],
        correctAnswers: ['Подтоварные воды от отстоя нефти и нефтепродуктов'],
      },
      {
        code: '63650063',
        text:
          'Что из перечисленного допускается в отношении резервуарных парков?',
        answers: [
          'Ручной отбор проб светлых нефтепродуктов во время закачки или откачки продукта',
          'При хранении нефтепродуктов в резервуарах наличие подтоварной воды меньше минимального уровня, обеспечиваемого устройством для дренажа воды',
          'Ручной отбор проб светлых нефтепродуктов через люк на крыше резервуара',
          'Все перечисленное не допускается',
        ],
        correctAnswers: ['Все перечисленное не допускается'],
      },
      {
        code: '63650064',
        text:
          'Что из перечисленного не содержит заявление, передаваемое организацией-пользователем недр в Федеральное агентство по недропользованию, его территориальный орган или в уполномоченный орган, на согласование проектной документации?',
        answers: [
          'Реквизиты декларации промышленной безопасности',
          'Наименование и организационно-правовую форму, основной государственный регистрационный номер записи о государственной регистрации, идентификационный номер налогоплательщика, адрес места нахождения, телефон, факс и адрес электронной почты пользователя недр',
          'Наименование представляемой на согласование проектной документации',
          'Реквизиты лицензии на пользование участком недр с реквизитами всех изменений',
          'Содержит все перечисленное',
        ],
        correctAnswers: ['Реквизиты декларации промышленной безопасности'],
      },
      {
        code: '63650065',
        text:
          'Как должны располагаться внутрицеховые технологические трубопроводы с тяжелыми газами, прокладываемые по несгораемой поверхности несущих стен производственных зданий с оконными и дверными проемами?',
        answers: [
          'На 1 м выше оконных и дверных проемов',
          'На 0,5 м выше оконных и дверных проемов',
          'На 0,5 м ниже оконных и дверных проемов',
          'На 1 м ниже оконных и дверных проемов',
        ],
        correctAnswers: ['На 0,5 м ниже оконных и дверных проемов'],
      },
      {
        code: '63650066',
        text:
          'Какое утверждение, относящееся к резервуарным паркам для нефти и нефтепродуктов, указано неверно?',
        answers: [
          'Запорные устройства, установленные непосредственно у резервуара, должны дублироваться установкой запорных устройств на технологических трубопроводах вне обвалования',
          'Применение арматуры с дистанционным управлением (электро-, пневмо- или гидроприводной) определяется условиями технологического процесса перекачки с обоснованием в проектной документации',
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
          'Для вновь проектируемых резервуаров управление приводами запорной арматуры должно быть дистанционным из операторной и по месту ее установки',
        ],
        correctAnswers: [
          'Транзитная прокладка электрокабельных линий производится внутри обвалования резервуаров',
        ],
      },
      {
        code: '63650067',
        text:
          'На каких отдельных участках трассы допускается прокладка технологических трубопроводов в полупроходных каналах?',
        answers: [
          'Протяженностью не более 100 м',
          'Протяженностью не более 200 м',
          'Протяженностью не более 250 м',
          'Протяженностью не более 150 м',
        ],
        correctAnswers: ['Протяженностью не более 100 м'],
      },
      {
        code: '63650068',
        text:
          'Какой устанавливается срок проекта пробной эксплуатации месторождения (залежи) или дополнения к нему для средних месторождений при наличии пяти и более эксплуатационных объектов?',
        answers: [
          'Не более 10 лет',
          'Не более 3 лет',
          'Не более 5 лет',
          'Не более 7 лет',
        ],
        correctAnswers: ['Не более 7 лет'],
      },
      {
        code: '63650069',
        text:
          'Что должна предусматривать конструкция подвышечного основания бурового оборудования?',
        answers: [
          'Возможность монтажа колонных головок и превенторной установки на устье скважины выше уровня земли без производства дополнительных работ с металлоконструкциями основания',
          'Возможность демонтажа основания при установленной фонтанной арматуре или ее части',
          'Возможность установки стола ротора на уровне пола буровой',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63650070',
        text:
          'Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?',
        answers: [
          'При прокладке трубопроводов через строительные конструкции зданий и другие препятствия должны приниматься меры, исключающие возможность передачи дополнительных нагрузок на трубы',
          'Трубопроводы не должны иметь фланцевых или других разъемных соединений',
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
          'Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами, устанавливающими требования к технологическим трубопроводам с учетом условий эксплуатации',
        ],
        correctAnswers: [
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
        ],
      },
      {
        code: '63650071',
        text:
          'Какая санитарно-защитная зона устанавливается для объектов производств по переработке нефти, попутного нефтяного и природного газа I класса опасности?',
        answers: ['1000 м', '500 м', '300 м', '100 м'],
        correctAnswers: ['1000 м'],
      },
      {
        code: '63650072',
        text:
          'В течение какого срока осуществляется рассмотрение проектов строительства и эксплуатации подземных сооружений комиссией или уполномоченным органом со дня представления материалов пользователем недр?',
        answers: [
          'В течение 50 рабочих дней',
          'В течение 45 рабочих дней',
          'В течение 25 рабочих дней',
          'В течение 10 рабочих дней',
        ],
        correctAnswers: ['В течение 25 рабочих дней'],
      },
      {
        code: '63650073',
        text:
          'Какое из перечисленных требований к запорной арматуре, устанавливаемой на вводах (и выводах) технологических трубопроводов в цеха, в технологические узлы и в установки, указано неверно?',
        answers: [
          'На вводах технологических трубопроводов для горючих газов (в том числе сжиженных), легковоспламеняющихся и горючих жидкостей номинальным диаметром DN ≥ 400 должна быть установлена запорная арматура с дистанционным управлением и ручным дублированием',
          'Запорная арматура вводов технологических трубопроводов с дистанционным управлением должна располагаться вне здания на расстоянии не менее 3 м и не более 50 м от стены здания или ближайшего аппарата, расположенного вне здания',
          'Дистанционное управление запорной арматурой следует располагать в пунктах управления, операторных и других безопасных местах с постоянным присутствием персонала',
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
        correctAnswers: [
          'Управление запорной арматурой с дистанционным управлением, предназначенной для аварийного сброса газа, следует осуществлять из производственных помещений',
        ],
      },
      {
        code: '63650074',
        text:
          'Какие опасные производственные объекты должны оснащаться автоматическими и (или) автоматизированными системами управления, построенными на базе электронных средств контроля и автоматики, включая средства вычислительной техники?',
        answers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только II категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только I категории взрывоопасности',
          'Имеющие в своем составе объекты с технологическими блоками только III категории взрывоопасности',
        ],
        correctAnswers: [
          'Имеющие в своем составе объекты с технологическими блоками I и II категорий взрывоопасности',
        ],
      },
      {
        code: '63650075',
        text:
          'Чем должны оснащаться насосы и компрессоры технологических блоков взрывопожароопасных производств, остановка которых при падении напряжения или кратковременном отключении электроэнергии может привести к отклонениям технологических параметров процесса до критических значений и развитию аварий?',
        answers: [
          'Системами самозапуска электродвигателей',
          'Автономной электрической подстанцией',
          'Устройствами для сбора и удаления жидкой фазы',
          'Блокировками',
        ],
        correctAnswers: ['Системами самозапуска электродвигателей'],
      },
      {
        code: '63650076',
        text:
          'Для налива каких ЛВЖ сливоналивные устройства должны снабжаться устройствами отвода паров?',
        answers: [
          'С упругостью паров от 56,65 кПа',
          'С упругостью паров от 66,65 кПа',
          'С упругостью паров от 60,65 кПа',
          'С упругостью паров от 61,65 кПа',
        ],
        correctAnswers: ['С упругостью паров от 66,65 кПа'],
      },
      {
        code: '63650077',
        text:
          'В каком случае допускается установка оборудования приточных систем вентиляции обычного исполнения в помещениях взрывоопасных категорий опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: [
          'Допускается в любом случае',
          'Допускается при согласовании с федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащищенные обратные клапаны',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается, если на воздуховодах при выходе из венткамеры предусмотрены взрывозащищенные обратные клапаны',
        ],
      },
      {
        code: '63650078',
        text:
          'Через какое расстояние эстакады для трубопроводов при обустройстве нефтяных, газовых и газоконденсатных месторождений должны быть электрически соединены с проходящими по ним трубопроводами и заземлены?',
        answers: [
          'Через 450 - 500 м, а также в начале и в конце',
          'Через 300 - 400 м, а также в начале и в конце',
          'Через 200 - 300 м, а также в начале и в конце',
          'Через 150 - 200 м, а также в начале и в конце',
        ],
        correctAnswers: ['Через 200 - 300 м, а также в начале и в конце'],
      },
      {
        code: '63650079',
        text:
          'Из какого материала должны применяться трубопроводы для транспортировки нефти и нефтепродуктов?',
        answers: [
          'Из стали',
          'Из полиэтилена',
          'Из фторопласта',
          'Из стекла',
          'Из винипласта',
        ],
        correctAnswers: ['Из стали'],
      },
      {
        code: '63650080',
        text:
          'Как производителем должна подтверждаться эффективность и надежность средств взрывозащиты, локализации пламени и других противоаварийных устройств до начала их применения на опасном производственном объекте?',
        answers: [
          'Посредством получения заключения научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования, и экспертизы промышленной безопасности',
          'Посредством опытных работ, проводимых разработчиком данного оборудования, и экспертизы промышленной безопасности',
          'Посредством оценки научно-исследовательской организации, специализирующейся в области разработки аналогичного оборудования',
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
        correctAnswers: [
          'Посредством испытаний промышленных образцов оборудования на взрывозащищенность',
        ],
      },
      {
        code: '63650081',
        text:
          'В каком случае не допускается применение электроподогрева при проведении сливоналивных операций нефтепродуктов?',
        answers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 81 °C',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
          'Не допускается ни в каком случае',
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 71 °C',
        ],
        correctAnswers: [
          'При проведении сливоналивных операций с нефтепродуктами, температура вспышки паров которых ниже 61 °C',
        ],
      },
      {
        code: '63650082',
        text:
          'Что не должно учитываться при выборе шлангов для обеспечения безопасности грузовых (сливоналивных) операций?',
        answers: [
          'Размер судового трубопровода',
          'Требуемая скорость перемещения нефтепродукта',
          'Физико-химические свойства перемещаемой среды',
          'Параметры давления и температуры перемещаемой среды',
        ],
        correctAnswers: ['Требуемая скорость перемещения нефтепродукта'],
      },
      {
        code: '63650083',
        text:
          'Какое из нижеуказанных свойств, показателей или условий не влияет на выбор типа резервуара для хранения нефти и нефтепродуктов?',
        answers: [
          'Годовое число циклов заполнений-опорожнений резервуара',
          'Показатели взрывоопасности хранимых нефтепродуктов',
          'Физико-химические свойства хранимых нефтепродуктов',
        ],
        correctAnswers: [
          'Годовое число циклов заполнений-опорожнений резервуара',
        ],
      },
      {
        code: '63650084',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
          'На основе локального плана проведения работ',
          'На основе технического регламента',
          'На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63650085',
        text:
          'Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?',
        answers: [
          'Проектной документацией',
          'Правилами безопасности',
          'Техническим регламентом',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63650086',
        text:
          'При каком условии допускается использовать приборы, отработавшие назначенный срок службы, в системах автоматизации, связи и оповещения на опасных производственных объектах складов нефти и нефтепродуктов?',
        answers: [
          'Если это утверждено внутренней документацией',
          'Если это обусловлено в проектной документации',
          'Не допускается ни в каком случае',
          'Если предусмотрены меры и средства, обеспечивающие безопасность эксплуатации таких приборов',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63650087',
        text:
          'Какое количество начальных извлекаемых запасов, числящихся на государственном балансе запасов полезных ископаемых, может добываться на этапе пробной эксплуатации средних, крупных и уникальных месторождений?',
        answers: [
          'Не более 30%',
          'Не более 25%',
          'Не более 15%',
          'Не более 20%',
        ],
        correctAnswers: ['Не более 20%'],
      },
      {
        code: '63650088',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в исходных данных на проектирование',
          'Только в технологическом регламенте на производство продукции',
          'Только в проектной документации',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63650089',
        text:
          'Каким образом должно обеспечиваться ограничение максимальной скорости налива нефти и нефтепродуктов до безопасных пределов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Перепуском части продукта во всасывающий трубопровод насоса',
          'Установкой частотно-регулируемого электропривода насоса',
          'Посредством запорно-регулирующей арматуры на линии подачи нефти или нефтепродукта к железнодорожной эстакаде',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63650090',
        text:
          'В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?',
        answers: [
          'Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам',
          'Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам',
          'В любом из указанных случаев',
        ],
        correctAnswers: ['В любом из указанных случаев'],
      },
      {
        code: '63650091',
        text:
          'Исходя из чего осуществляется проектирование системы противоаварийной автоматической защиты и выбор ее элементов?',
        answers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
          'Исходя из условий обеспечения работы системы только в процессе обслуживания в течение 1 года',
          'Исходя из условий обеспечения работы системы только в процессе эксплуатации в течение 5 лет',
          'Исходя из условий обеспечения работы системы только в процессе ремонта',
        ],
        correctAnswers: [
          'Исходя из условий обеспечения работы системы в процессе эксплуатации, обслуживания и ремонта в течение всего жизненного цикла защищаемого объекта',
        ],
      },
      {
        code: '63650092',
        text:
          'Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки и основания плавучих буровых установок?',
        answers: [
          'Условиям проектирования вышки',
          'Должны быть проведены с учетом усилий, возникающих в условиях морского перехода',
          'Всем вышеперечисленным условиям',
          'Условиям проектирования подвышечного основания',
        ],
        correctAnswers: [
          'Должны быть проведены с учетом усилий, возникающих в условиях морского перехода',
        ],
      },
      {
        code: '63650093',
        text:
          'В каких документах должны быть обоснованы технические решения по герметизации налива нефтепродуктов в железнодорожные цистерны?',
        answers: [
          'В проектной документации',
          'В нормативных правовых актах в области промышленной безопасности',
          'В нормативных правовых актах в области охраны труда',
          'В производственной инструкции по наливу нефти и нефтепродуктов',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63650094',
        text:
          'Какой из перечисленных терминов соответствует определению "динамическая нагрузка" при эксплуатации бурового оборудования?',
        answers: [
          'Сумма статических и динамических нагрузок на крюке, которая может быть приложена к крюку буровой установки в процессе строительства скважины в пределах нормативов, установленных Правилами безопасности в нефтяной и газовой промышленности',
          'Максимальный вес труб, который может выдерживаться основанием подсвечника без превышения предельно допустимых напряжений',
          'Нагрузка, воздействующая на конструкции или оборудование буровой установки в отсутствие движения воздействующих на них грузов',
          'Нагрузка, воздействующая на конструкцию или оборудование буровой установки в результате процессов ускорения или замедления движения поднимаемых (опускаемых) грузов',
        ],
        correctAnswers: [
          'Нагрузка, воздействующая на конструкцию или оборудование буровой установки в результате процессов ускорения или замедления движения поднимаемых (опускаемых) грузов',
        ],
      },
      {
        code: '63650095',
        text:
          'Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки без растяжек буровых установок?',
        answers: [
          'Максимальному количеству свечей бурильных труб на подсвечнике',
          'Максимальной скорости ветра, для которой рассчитано сопротивление вышки или мачты силе ветра при отсутствии комплекта труб на подсвечнике',
          'Максимальной статической нагрузке на крюке',
          'Максимальной скорости ветра, для которой рассчитано сопротивление вышки или мачты силе ветра при наличии полного комплекта труб на подсвечнике',
          'Всем вышеперечисленным условиям',
        ],
        correctAnswers: ['Всем вышеперечисленным условиям'],
      },
      {
        code: '63650096',
        text:
          'На каком расстоянии от сплошной (без проемов) стены помещения пунктов разлива и фасовки размещаются раздаточные резервуары с нефтепродуктами единичной вместимостью до 25 м³ включительно при общей вместимости до 200 м³ в зависимости от вида отпускаемых нефтепродуктов?',
        answers: ['1 м', '1,5 м', '3 м', '2 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63650097',
        text:
          'До какого момента при заполнении порожнего резервуара должны подаваться нефть или нефтепродукты со скоростью не более 1 м/с?',
        answers: [
          'До момента заполнения резервуара до верхней проектной отметки',
          'До момента срабатывания блокировки ограничения скорости заполнения резервуара',
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
        ],
        correctAnswers: [
          'До момента заполнения приемного патрубка или до всплытия понтона (плавающей крыши)',
        ],
      },
      {
        code: '63650098',
        text:
          'Что должно предусматриваться в проектной документации на консервацию или ликвидацию опасного производственного объекта?',
        answers: [
          'Мероприятия по локализации и ликвидации последствий аварий по завершении консервации объекта',
          'Мероприятия по предупреждению аварий',
          'Должны предусматриваться все перечисленные условия',
        ],
        correctAnswers: ['Должны предусматриваться все перечисленные условия'],
      },
      {
        code: '63650099',
        text:
          'Какие минимальные уклоны для стока жидкости к приемным устройствам (лоткам, колодцам, приямкам) должно иметь покрытие зоны слива и налива для сбора и отвода загрязненных нефтепродуктами атмосферных осадков?',
        answers: ['1%', '2%', '3%', '5%'],
        correctAnswers: ['2%'],
      },
      {
        code: '63650100',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Наличие природных оврагов, выемок, низин',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63650101',
        text:
          'Какие требования предъявляются к конструкции всасывающих линий буровых насосов?',
        answers: [
          'Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 200 мм, а длина - не более 5 м',
          'Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 150 мм, а длина - не более 6 м',
          'Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 180 мм, а длина - не более 5,5 м',
          'Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 130 мм, а длина - не более 6,5 м',
        ],
        correctAnswers: [
          'Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 200 мм, а длина - не более 5 м',
        ],
      },
      {
        code: '63650102',
        text:
          'В соответствии с требованиями какой документации насосные агрегаты должны оснащаться системами автоматизации, обеспечивающими их безопасную эксплуатацию? Выберите 2 правильных варианта ответа.',
        answers: [
          'Технической документации организации-изготовителя',
          'Внутренних инструкций эксплуатирующей организации',
          'Руководств по безопасной эксплуатации',
          'Проектной документации',
        ],
        correctAnswers: [
          'Технической документации организации-изготовителя',
          'Проектной документации',
        ],
      },
      {
        code: '63650103',
        text:
          'В каком случае допускается использовать технологические трубопроводы из неметаллических материалов?',
        answers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
          'После проведения расчета на прочность',
          'Не допускается ни в каком случае',
          'Если они имеют защитные покрытия, обеспечивающие стойкость к рабочим средам',
        ],
        correctAnswers: [
          'При обосновании такого решения в проекте и принятии всех необходимых мер безопасности, связанных с жизненным циклом применяемых материалов',
        ],
      },
      {
        code: '63650104',
        text:
          'Какое требование к системам вентиляции не соответствует Федеральным нормам и правилам в области промышленной безопасности "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
          'Система местных отсосов, удаляющая взрывопожароопасные пыль и газы, должна быть оборудована блокировками, исключающими пуск и работу конструктивно связанного с ней технологического оборудования при неработающем отсосе',
          'Воздухозабор для приточных систем вентиляции необходимо предусматривать из мест, исключающих попадание в систему вентиляции взрывоопасных и химически опасных паров и газов при всех режимах работы производства',
          'Устройство выбросов от систем общеобменной и аварийной вытяжной вентиляции должно обеспечивать эффективное рассеивание и исключать возможность взрыва в зоне выброса и образования взрывоопасных смесей над площадкой опасного производственного объекта, в том числе у стационарных источников зажигания',
        ],
        correctAnswers: [
          'Электрооборудование вентиляционных систем, устанавливаемое в производственных помещениях, снаружи здания и в помещениях вентиляционного оборудования (вентиляционных камерах), должно быть только с видом взрывозащиты "масляное или негорючей жидкостью заполнение оболочки" ("о")',
        ],
      },
      {
        code: '63650105',
        text:
          'На какую минимальную глубину от уровня верхней кромки подогревателя должны погружаться в нефтепродукт переносные паровые змеевики и переносные электрические подогреватели?',
        answers: ['300 мм', '500 мм', '600 мм', '800 мм'],
        correctAnswers: ['500 мм'],
      },
      {
        code: '63650106',
        text:
          'Какие разделы входят в состав документации на ликвидацию скважин?',
        answers: [
          'Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин), технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья,- порядок организации работ по ликвидации скважин, мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений',
          'Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин), технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья, порядок организации работ по ликвидации скважин, мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений, экспертиза промышленной безопасности ликвидации скважин, оборудования ствола скважин и устья',
          'Состав документации на ликвидацию скважин зависит от причин ликвидации (по какой категории ликвидируется скважина)',
        ],
        correctAnswers: [
          'Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин), технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья,- порядок организации работ по ликвидации скважин, мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений',
        ],
      },
      {
        code: '63650107',
        text:
          'Кем определяются предельные значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывоопасных характеристик, физико-химических свойств транспортируемых веществ, свойств конструкционных материалов и характеристик технических устройств, применяемых для перемещения горючих продуктов?',
        answers: [
          'Разработчиком проекта',
          'Разработчиком проекта по расчетным данным',
          'Разработчиком проекта по литературным (справочным) данным',
          'Разработчиком проекта по исходным данным',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63650108',
        text:
          'При каком минимальном превышении концентрации паров нефтепродуктов на площадках сливоналивных станций и пунктов слива-налива должны быть установлены блокировки по прекращению операций слива-налива и сигнализация, оповещающая о запрете запуска двигателей автомобилей?',
        answers: [
          'При превышении концентрации паров более 50%',
          'При превышении концентрации паров более 20%',
          'При превышении концентрации паров более 30%',
        ],
        correctAnswers: ['При превышении концентрации паров более 20%'],
      },
      {
        code: '63650109',
        text:
          'В составе каких трубопроводов, транспортирующих рабочие среды, следует применять арматуру из углеродистых и легированных сталей?',
        answers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 2 мм/год',
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 1 мм/год',
        ],
        correctAnswers: [
          'В составе трубопроводов, транспортирующих рабочие среды, вызывающие коррозию со скоростью не более 0,5 мм/год',
        ],
      },
      {
        code: '63650110',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'Только в технологических блоках I категории взрывоопасности',
          'Только в технологических блоках II категории взрывоопасности',
          'В технологических блоках всех категорий взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63650111',
        text:
          'Какова величина нормативной санитарно-защитной зоны для промышленных объектов по добыче нефти при выбросе сероводорода от 0,5 до 1 т/сутки, а также с высоким содержанием летучих углеводородов?',
        answers: ['1000 м', '500 м', '300 м', '100 м'],
        correctAnswers: ['1000 м'],
      },
      {
        code: '63650112',
        text:
          'Какая запорная арматура, установленная на технологических трубопроводах, должна иметь механический привод (электро-, пневмо- или гидропривод) с дистанционным управлением и ручным дублированием?',
        answers: [
          'Установленная на технологических трубопроводах с условным диаметром более 200 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 300 мм',
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
        ],
        correctAnswers: [
          'Установленная на технологических трубопроводах с условным диаметром более 400 мм',
        ],
      },
      {
        code: '63650113',
        text:
          'Где допускается осуществлять затаривание и расфасовку нефтепродуктов (масел, смазок) в бочки и мелкую тару?',
        answers: [
          'В складских помещениях для хранения нефтепродуктов в таре',
          'На открытых площадках',
          'Во всех перечисленных местах',
          'В пунктах разлива и фасовки нефтепродуктов',
        ],
        correctAnswers: ['В пунктах разлива и фасовки нефтепродуктов'],
      },
      {
        code: '63650114',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Техническим руководителем организации - пользователя недр',
          'Органом государственного горного надзора',
          'Органом исполнительной власти субъекта Российской Федерации',
          'Руководителем организации - пользователя недр',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63650115',
        text:
          'Какая величина зазора должна быть предусмотрена между кожухом и ребордами шкивов талевой системы?',
        answers: [
          'Не более 0,25 диаметра каната',
          'Не более 0,75 диаметра каната',
          'Не более 0,5 диаметра каната',
          'Не более 1 диаметра каната',
        ],
        correctAnswers: ['Не более 0,25 диаметра каната'],
      },
      {
        code: '63650116',
        text: 'Какие требования к буровым насосам указаны неверно?',
        answers: [
          'Пуск буровых насосов в работу должен осуществляться с пульта бурильщика, а регулирование их работы и остановка - с местного поста управления',
          'Всасывающие линии буровых насосов не должны иметь изгибов и поворотов, их диаметр должен быть не менее 200 мм, а длина - не более 5 м',
          'Конструкция пневмокомпенсатора должна позволять установку манометра для измерения давления в газовой полости и обеспечивать возможность сбрасывания давления до нуля',
          'Уплотнения в гидравлической части насоса, в корпусах предохранительного устройства и пневмокомпенсатора должны быть рассчитаны на давление, равное 1,5-кратному максимальному рабочему давлению насоса',
          'Конструкция элементов гидравлической части насоса должна исключать возможность травмирования обслуживающего персонала струей жидкости при повреждении уплотнений',
        ],
        correctAnswers: [
          'Пуск буровых насосов в работу должен осуществляться с пульта бурильщика, а регулирование их работы и остановка - с местного поста управления',
        ],
      },
      {
        code: '63650117',
        text:
          'На какой срок разрабатывается план развития горных работ по всем планируемым видам горных работ?',
        answers: ['На 1 год', 'На 2 года', 'На 3 года', 'На 5 лет'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63650118',
        text:
          'Какая из перечисленных систем канализации не предусматривается на площадках опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['Аварийная', 'Производственная', 'Дождевая', 'Бытовая'],
        correctAnswers: ['Аварийная'],
      },
      {
        code: '63650119',
        text:
          'При какой периодичности использования трубопроводной арматуры ручной привод следует располагать на высоте не более 1,6 м от уровня пола или площадки, с которой ведется управление?',
        answers: [
          'Не реже одного раза в смену',
          'Расположение привода не зависит от частоты использования арматуры',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63650120',
        text:
          'Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки с растяжками буровых установок?',
        answers: [
          'Максимальной скорости ветра при условии отсутствия труб на подсвечнике',
          'Максимальному количеству труб при полном комплекте на подсвечнике',
          'Максимальной скорости ветра при наличии полного комплекта труб на подсвечнике',
          'Максимальной статической нагрузке на крюке',
          'Всем вышеперечисленным условиям',
        ],
        correctAnswers: ['Всем вышеперечисленным условиям'],
      },
      {
        code: '63650121',
        text:
          'Каким образом должен осуществляться возврат технологического объекта в рабочее состояние после срабатывания противоаварийной защиты?',
        answers: [
          'Средствами автоматического управления объектом по действующим программам после устранения причин, приведших к срабатыванию противоаварийной защиты',
          'Выполняется обслуживающим персоналом по инструкции',
          'Средствами автоматического управления объектом по действующим программам',
          'Средствами автоматического управления объектом по действующим программам после производства персоналом всех необходимых по инструкции переключений',
        ],
        correctAnswers: ['Выполняется обслуживающим персоналом по инструкции'],
      },
      {
        code: '63650122',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов в системах с подогревателями',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для всех перечисленных трубопроводов',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63650123',
        text:
          'В каком случае допускается на опасных производственных объектах складов нефти и нефтепродуктов прокладка кабельных трасс и технологических трубопроводов на общих строительных конструкциях?',
        answers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
          'Допускается при условии разработки эксплуатирующей организацией компенсационных мер обеспечения требований пожарной и промышленной безопасности',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается при обосновании принятых технических решений в проектной документации (документации на техническое перевооружение)',
        ],
      },
      {
        code: '63650124',
        text: 'Какой диаметр должны иметь всасывающие линии буровых насосов?',
        answers: [
          'Не менее 200 мм',
          'Не менее 150 мм',
          'Не менее 100 мм',
          'Не менее 250 мм',
        ],
        correctAnswers: ['Не менее 200 мм'],
      },
      {
        code: '63650125',
        text:
          'Кем устанавливается форма заявления о согласовании планов и (или) схем развития горных работ?',
        answers: [
          'Пользователем недр',
          'Органом местного самоуправления',
          'Росреестром',
          'Органом государственного горного надзора',
        ],
        correctAnswers: ['Органом государственного горного надзора'],
      },
      {
        code: '63650126',
        text:
          'Какое отключение должны иметь насосные агрегаты, перекачивающие нефть и нефтепродукты?',
        answers: [
          'Дистанционное отключение из операторной',
          'Дистанционное отключение из операторной и по месту',
          'Отключение по месту',
        ],
        correctAnswers: ['Дистанционное отключение из операторной и по месту'],
      },
      {
        code: '63650127',
        text:
          'Какое из перечисленных требований допускается к автомобильным сливоналивным станциям?',
        answers: [
          'Запуск двигателей автомобильных цистерн, находящихся на площадке, в случаях пролива нефтепродукта',
          'Применение гибких шлангов для налива при обосновании в проектной документации',
          'Эксплуатация сливоналивных устройств при обнаружении нарушения целостности единого контура заземления при обосновании в проектной документации',
          'Нахождение водителей автомобильных цистерн, выполняющих операции слива-налива нефтепродуктов, в одежде, способной накапливать заряды статического электричества',
        ],
        correctAnswers: [
          'Применение гибких шлангов для налива при обосновании в проектной документации',
        ],
      },
      {
        code: '63650128',
        text:
          'Разрешается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений последовательное соединение заземляющим проводником нескольких аппаратов или резервуаров?',
        answers: [
          'Допускается при условии, что общее сопротивление заземляющего проводника не более 20 Ом',
          'Допускается только для аппаратов или резервуаров, установленных в одном обваловании',
          'Допускается',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63650129',
        text:
          'В каком исполнении должны быть агрегаты, применяемые во взрывопожароопасных зонах?',
        answers: [
          'Во взрывонепроницаемом',
          'В брызгозащищенном',
          'Во взрывозащищенном',
          'В пылевлагонепроницаемом',
        ],
        correctAnswers: ['Во взрывозащищенном'],
      },
      {
        code: '63650130',
        text:
          'Где приводятся конкретные значения уставок систем защиты по опасным параметрам?',
        answers: [
          'В проектной документации',
          'В технологическом регламенте на производство продукции',
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
        correctAnswers: [
          'В проектной документации (документации на техническое перевооружение) и технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63650131',
        text:
          'На основе каких критериев осуществляется выбор буровых установок?',
        answers: [
          'По параметру "допускаемая нагрузка на крюке"',
          'Технические характеристики оборудования буровой установки соответствуют классу буровой установки',
          'Технические характеристики оборудования буровой установки соответствуют условиям эксплуатации буровой установки',
          'По всем вышеперечисленным критериям',
        ],
        correctAnswers: ['По всем вышеперечисленным критериям'],
      },
      {
        code: '63650132',
        text:
          'В каком исполнении должны быть технические устройства, применяемые во взрывопожароопасных зонах?',
        answers: [
          'Во взрывозащищенном',
          'Во взрывонепроницаемом',
          'В пылевлагонепроницаемом',
          'В брызгозащищенном',
        ],
        correctAnswers: ['Во взрывозащищенном'],
      },
      {
        code: '63650133',
        text:
          'Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Дистанционное, неавтоматическое',
          'Ручное управление по месту',
          'Автоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63650134',
        text:
          'В местах установки какой арматуры в границах предприятий проектом должны быть предусмотрены переносные (передвижные) или стационарные средства механизации для монтажа и демонтажа?',
        answers: [
          'Массой более 50 кг',
          'Массой более 40 кг',
          'Массой более 35 кг',
          'Массой более 30 кг',
        ],
        correctAnswers: ['Массой более 50 кг'],
      },
      {
        code: '63650135',
        text: 'Какой должна быть присоединительная резьба ствола вертлюга?',
        answers: [
          'Правой',
          'Зависит от типа проектируемой вышки',
          'Не имеет значения',
          'Левой',
        ],
        correctAnswers: ['Левой'],
      },
      {
        code: '63650136',
        text:
          'Чем должны оснащаться насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся и горючих жидкостей? Выберите 2 правильных варианта ответа.',
        answers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
          'Средствами контроля температуры перемещаемой жидкости',
          'Системами измерения плотности и вязкости перекачиваемых сред',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск или прекращающими работу насоса при отсутствии перемещаемой жидкости в его корпусе или отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Средствами предупредительной сигнализации при достижении опасных значений параметров в приемных и расходных емкостях',
        ],
      },
      {
        code: '63650137',
        text:
          'В соответствии с какими нормами и требованиями должны быть обустроены сливоналивные причалы для осуществления операций с нефтью и нефтепродуктами?',
        answers: [
          'В соответствии с нормами морского регистра и требованиями законодательства по перевозке опасных грузов',
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
          'В соответствии с требованиями градостроительного законодательства',
        ],
        correctAnswers: [
          'В соответствии с нормами технологического проектирования морских и речных портов и требованиями законодательства в области промышленной безопасности',
        ],
      },
      {
        code: '63650138',
        text:
          'Какие переходные сопротивления должны быть в соединениях элементов трубопроводов или других протяженных металлических предметов для защиты зданий (сооружений) и электрооборудования от вторичных проявлений молнии?',
        answers: [
          'Не более 0,5 Ом на каждый контакт',
          'Не более 0,03 Ом на каждый контакт',
          'Не более 0,05 Ом на каждый контакт',
          'Не более 0,1 Ом на каждый контакт',
        ],
        correctAnswers: ['Не более 0,03 Ом на каждый контакт'],
      },
      {
        code: '63650139',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'Со сторонними организациями, расположенными на территории горного отвода',
          'С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез',
          'Со специализированными профессиональными аварийно-спасательными формированиями',
          'С территориальными органами Ростехнадзора',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63650140',
        text:
          'Что предусматривается во взрывоопасных помещениях и вне их перед входными дверями?',
        answers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
          'Устройство информационного стенда о действиях персонала в аварийной ситуации',
          'Только устройство световой сигнализации',
          'Только устройство звуковой сигнализации',
        ],
        correctAnswers: [
          'Устройство световой и звуковой сигнализации загазованности воздушной среды',
        ],
      },
      {
        code: '63650141',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с включением его автоматически при остановке рабочего',
          'Переключением сети воздуха КИПиА на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63650142',
        text:
          'Какой должна быть свободная высота эстакад для технологических трубопроводов над проездами и проходами для железнодорожных путей (над головкой рельса)?',
        answers: [
          'Не менее 6,0 м',
          'Не менее 4,5 м',
          'Не менее 5,0 м',
          'Не менее 5,55 м',
        ],
        correctAnswers: ['Не менее 5,55 м'],
      },
      {
        code: '63650143',
        text:
          'Какое из перечисленных требований к трубопроводной арматуре указано верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'Арматуру из серого и ковкого чугуна допускается применять на трубопроводах, подверженных вибрации',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
          'Применение запорной арматуры в качестве регулирующей (дросселирующей) допускается с учетом требований технических регламентов',
        ],
        correctAnswers: [
          'Арматуру из цветных металлов и их сплавов допускается применять в тех случаях, когда стальная и чугунная арматура не могут быть использованы',
          'В гидроприводе арматуры следует применять незамерзающие жидкости, соответствующие условиям эксплуатации',
        ],
      },
      {
        code: '63650144',
        text:
          'Для каких буровых установок высота вышки рассчитывается с учетом возможности применения верхнего привода?',
        answers: [
          'Для буровых установок, начиная с 5-го класса и выше (грузоподъемностью 200 т и выше)',
          'Для буровых установок, начиная с 6-го класса и выше (грузоподъемностью 250 т и выше)',
          'Для буровых установок, начиная с 7-го класса и выше (грузоподъемностью 320 т и выше)',
          'Для буровых установок, начиная с 3-го класса и выше (грузоподъемностью 125 т и выше)',
        ],
        correctAnswers: [
          'Для буровых установок, начиная с 7-го класса и выше (грузоподъемностью 320 т и выше)',
        ],
      },
      {
        code: '63650145',
        text:
          'В каком из перечисленных случаев допускается применение литой арматуры для технологических трубопроводов?',
        answers: [
          'Для технологических трубопроводов с номинальным давлением не более 40 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 50 МПа',
          'Для технологических трубопроводов с номинальным давлением не более 45 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с номинальным давлением не более 35 МПа',
        ],
      },
      {
        code: '63650146',
        text:
          'Что из перечисленного может не учитываться для определения рисков при проектировании технологических трубопроводов?',
        answers: [
          'Усталость при переменных нагрузках',
          'Вибрация',
          'Охлаждение от температуры окружающего воздуха',
          'Наличие защищенных подвижных элементов',
        ],
        correctAnswers: ['Наличие защищенных подвижных элементов'],
      },
      {
        code: '63650147',
        text:
          'Какой длины должно быть наливное устройство во избежание налива нефти и нефтепродуктов свободно падающей струей?',
        answers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 500 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 300 мм',
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
        ],
        correctAnswers: [
          'Должно быть такой длины, чтобы расстояние от его конца до нижней образующей цистерны не превышало 200 мм',
        ],
      },
      {
        code: '63650148',
        text:
          'К каким технологическим трубопроводам допускается крепление к ним других трубопроводов меньшего диаметра в случаях, если расчетом на прочность и устойчивость подтверждена несущая способность технологического трубопровода?',
        answers: [
          'С номинальным давлением более 5 МПа',
          'С температурой стенки выше 300 °C',
          'С температурой стенки ниже минус 40 °C',
          'Со средой 1-ой группы',
        ],
        correctAnswers: ['С номинальным давлением более 5 МПа'],
      },
      {
        code: '63650149',
        text:
          'В каких случаях следует контролировать соответствие оборудования требованиям безопасности?',
        answers: [
          'При экспертизе технического задания и конструкторской документации',
          'При испытании оборудования серийного производства и сертификационных испытаниях (если они проводятся)',
          'При испытании опытных образцов (партий)',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63650150',
        text:
          'Какой документацией обосновывается максимальная безопасная скорость налива нефти и нефтепродуктов на железнодорожных сливоналивных эстакадах?',
        answers: [
          'Эксплуатационной документацией',
          'Технологическим регламентом',
          'Техническим регламентом',
          'Проектной документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63650151',
        text:
          'На каком минимальном расстоянии от резервуаров устанавливаются прожекторные мачты вне обвалования или ограждающих стен?',
        answers: ['10 м', '15 м', '12 м', '8 м', '5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63650152',
        text:
          'Кем осуществляется подготовка проектной документации на разработку месторождений полезных ископаемых?',
        answers: [
          'Только организацией, привлекаемой для подготовки проектной документации',
          'Только пользователем недр',
          'Только организациями, имеющими лицензию на право разработки проектной документации в области недропользования',
          'Пользователем недр или организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование',
        ],
        correctAnswers: [
          'Пользователем недр или организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование',
        ],
      },
      {
        code: '63650153',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в производственных помещениях, в которых предусматривается применение водяного или парового отопления',
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
          'Во всех указанных местах',
        ],
        correctAnswers: ['Во всех указанных местах'],
      },
      {
        code: '63650154',
        text:
          'Какое из перечисленных требований к молниезащите и защите от статического электричества указано верно?',
        answers: [
          'Измерение указанного сопротивления должно проводиться при относительной влажности окружающего воздуха не выше 40%',
          'При измерениях электрод должен располагаться в точках поверхности оборудования, наименее удаленных от точек контакта поверхности с заземленными металлическими элементами, деталями и арматурой',
          'Площадь соприкосновения поверхности оборудования с измерительным электродом должна быть не более 30 см²',
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
        ],
        correctAnswers: [
          'Заземленное металлическое оборудование, покрытое лакокрасочными материалами, считается электростатически заземленным, если сопротивление любой точки его внутренней и внешней поверхности относительно магистрали заземления не превышает 10 Ом',
        ],
      },
      {
        code: '63650155',
        text:
          'Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании вышки и основания буровых установок для работы в районе сейсмической активности?',
        answers: [
          'Условиям проектирования вышки',
          'Условиям проектирования подвышечного основания',
          'Условиям сейсмичности района',
          'Всем вышеперечисленным условиям',
        ],
        correctAnswers: ['Условиям сейсмичности района'],
      },
      {
        code: '63650156',
        text:
          'Какие требования предъявляются к температуре подогрева мазута в резервуарах?',
        answers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 120 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 20 °C и не должна превышать 80 °C',
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
        ],
        correctAnswers: [
          'Температура подогрева мазута в резервуарах должна быть ниже температуры вспышки его паров в закрытом тигле не менее чем на 15 °C и не должна превышать 90 °C',
        ],
      },
      {
        code: '63650157',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объекта',
          'На сети водоснабжения и канализации',
          'На промысловые трубопроводы, на которые распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа (0,0067 кгс/см2) до избыточного давления 320 МПа (3200 кгс/см2) и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объекта',
        ],
      },
      {
        code: '63650158',
        text:
          'Какие из перечисленных требований к защитным ограждениям бурового оборудования указаны неверно?',
        answers: [
          'Все перечисленные требования указаны верно',
          'Наружные поверхности защитных ограждений должны быть гладкими (не считая сетки)',
          'С внешней стороны шкивов приводных ремней на случай разрыва ремня устанавливаются металлические лобовые щиты',
          'Сетчатые ограждения в оправе устанавливаются на расстоянии от движущихся частей не менее 100 мм с размерами ячеек 20х20 мм и диаметром проволоки не менее 1 мм',
          'Высота ограждений определяется размерами движущихся частей механизмов. При высоте вращающихся частей механизмов менее 1,8 м последние ограждаются полностью',
        ],
        correctAnswers: [
          'Сетчатые ограждения в оправе устанавливаются на расстоянии от движущихся частей не менее 100 мм с размерами ячеек 20х20 мм и диаметром проволоки не менее 1 мм',
        ],
      },
      {
        code: '63650159',
        text:
          'На какой срок на стадии проектирования ОПО разрабатывается технологический регламент (ТР)?',
        answers: ['На 5 лет', 'На 3 года', 'На 2 года', 'На 1 год'],
        correctAnswers: ['На 5 лет'],
      },
      {
        code: '63650160',
        text:
          'Какой рекомендуемый минимальный разрыв от трубопроводов для сжиженных углеводородных газов до городов и населенных пунктов при диаметре трубы от 150 до 300 мм?',
        answers: ['500 м', '350 м', '250 м', '150 м'],
        correctAnswers: ['250 м'],
      },
      {
        code: '63650161',
        text: 'Какое ограничение поворота должен иметь штроп вертлюга?',
        answers: [
          'В пределах от 30 до 70° в сторону по направлению горловины вертлюга',
          'В пределах от 15 до 75° в сторону, противоположную горловине вертлюга',
          'В пределах от 25 до 50° в сторону, противоположную горловине вертлюга',
          'В пределах от 20 до 60° в сторону по направлению горловины вертлюга',
        ],
        correctAnswers: [
          'В пределах от 25 до 50° в сторону, противоположную горловине вертлюга',
        ],
      },
      {
        code: '63650162',
        text:
          'Какие насосные станции производственной канализации должны оснащаться датчиками загазованности с выводом сигнала на пульт помещения управления?',
        answers: [
          'Заглубленные более чем на 0,5 м',
          'Заглубленные не менее чем на 1 м',
          'В соответствии с проектной документацией',
          'Все насосные станции',
        ],
        correctAnswers: ['Заглубленные более чем на 0,5 м'],
      },
      {
        code: '63650163',
        text:
          'Что следует выполнять для определения оптимальных сочетаний диаметров, расходов и технологических параметров сред, транспортируемых по технологическим трубопроводам и их участкам или ответвлениям, подбора динамического оборудования и оптимизации конструкции с целью обеспечения безопасных условий эксплуатации?',
        answers: [
          'Гидравлический расчет',
          'Статический и динамический прочностный расчет',
          'Теплотехнический расчет',
          'Расчет на прочность и устойчивость',
        ],
        correctAnswers: ['Гидравлический расчет'],
      },
      {
        code: '63650164',
        text:
          'В каком случае допускается стационарное применение цельносварных гофрированных стальных труб, включая конструкции с теплоизоляционными и (или) защитными слоями?',
        answers: [
          'При обосновании в проекте',
          'По решению технической комиссии эксплуатирующей организации',
          'Не допускается ни в каком случае',
          'При согласовании с территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['При обосновании в проекте'],
      },
      {
        code: '63650165',
        text:
          'На какой срок утверждается недропользователем, начиная с года начала его реализации, проект пробной эксплуатации месторождения (залежи) или дополнения к нему для мелких и очень мелких месторождений?',
        answers: ['На один год', 'На семь лет', 'На три года', 'На пять лет'],
        correctAnswers: ['На три года'],
      },
      {
        code: '63650166',
        text:
          'Какое значение не должна превышать скорость движения понтона (плавающей крыши) для резервуаров емкостью до 30 000 м³?',
        answers: ['6 м/ч', '8 м/ч', '4 м/ч', '2 м/ч'],
        correctAnswers: ['6 м/ч'],
      },
      {
        code: '63650167',
        text:
          'Каким образом обеспечивается надежность обеспечения средств управления и системы противоаварийной защиты сжатым воздухом?',
        answers: [
          'Установкой резервного компрессора с автоматическим включением при остановке рабочего',
          'Переключением сети воздуха контрольно-измерительных приборов и устройств автоматического регулирования на заводскую сеть сжатого воздуха через осушитель',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты в течение 1 часа',
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
        correctAnswers: [
          'Установкой буферных емкостей (реципиентов), обеспечивающих питание воздухом систем контроля, управления и противоаварийной защиты при остановке компрессоров в течение времени, достаточного для безаварийной остановки объекта, что должно быть подтверждено расчетом',
        ],
      },
      {
        code: '63650168',
        text:
          'Какое из перечисленных требований к резервуарам для хранения нефти и нефтепродуктов указано верно?',
        answers: [
          'При обнаружении нарушения герметичности основного стального резервуара с защитной стенкой необходимо немедленно устранить причину утечки продукта',
          'При оснащении резервуарных парков газоуравнительной системой допускается объединять ею резервуары с авиационными и автомобильными бензинами',
          'Производительность наполнения (опорожнения) резервуаров может превышать суммарной пропускной способности установленных на резервуаре дыхательных и предохранительных устройств, если это обусловлено в проектной документации',
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
        ],
        correctAnswers: [
          'Полный комплект устанавливаемых на резервуаре устройств и оборудования и схема их расположения обосновываются в проектной документации',
        ],
      },
      {
        code: '63650169',
        text:
          'За счет чего должны компенсироваться температурные деформации трубопроводов для транспортировки мазута?',
        answers: [
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
          'За счет установки на технологических трубопроводах для транспортировки мазута линзовых компенсаторов',
          'За счет установки на технологических трубопроводах для транспортировки мазута сальниковых компенсаторов',
          'За счет установки на технологических трубопроводах для транспортировки мазута волнистых компенсаторов',
        ],
        correctAnswers: [
          'За счет поворотов и изгибов трассы трубопроводов (самокомпенсация) или установки специальных компенсирующих устройств (П-образных компенсаторов)',
        ],
      },
      {
        code: '63650170',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от вида взрывозащиты',
          'В зависимости от протяженности трубопровода и его конструктивных особенностей',
          'В зависимости от всех перечисленных факторов',
          'В зависимости от свойств перемещаемой среды',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63650171',
        text:
          'Какое количество запорных арматур следует устанавливать на технологических трубопроводах взрывопожароопасных сред для герметичного отключения от коллектора агрегатов (технологических аппаратов) с рабочим давлением (далее - Pр) ≥ 4 МПа (40 кгс/см²)?',
        answers: [
          'Одну единицу запорной арматуры и дренажную арматуру с заглушкой',
          'Две единицы запорной арматуры с дренажным устройством между ними',
          'Три единицы запорной арматуры',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Две единицы запорной арматуры с дренажным устройством между ними',
        ],
      },
      {
        code: '63650172',
        text:
          'При каком уклоне сход с приемного моста на землю и вход на основание вышки должны быть оборудованы лестницей с перилами с одной стороны (наружной по отношению к настилу)?',
        answers: ['Более 20°', 'Более 10°', 'Более 15°', 'Более 18°'],
        correctAnswers: ['Более 20°'],
      },
      {
        code: '63650173',
        text:
          'Применение каких компенсаторов на технологических трубопроводах допускается для транспортировки мазута?',
        answers: [
          'Линзовых компенсаторов',
          'Волнистых компенсаторов',
          'Сальниковых компенсаторов',
          'Допускается применение всех перечисленных компенсаторов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63650174',
        text:
          'Буровые установки какой грузоподъемностью должны быть оснащены специальными кабинами для размещения в них рабочего места бурильщика?',
        answers: [
          '320 т и выше',
          '400 т и выше',
          '200 т и выше',
          '50 т и выше',
        ],
        correctAnswers: ['400 т и выше'],
      },
      {
        code: '63650175',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с Ростехнадзором',
          'Проектной организацией',
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Технической комиссией эксплуатирующей организации',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63650176',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений хранение токсичных жидкостей в резервуарах с "атмосферным" дыханием?',
        answers: [
          'Допускается при условии оборудования резервуаров сигнализатором предельного верхнего уровня заполнения, сблокированным с насосным оборудованием, и системой аварийного слива избытка жидкости в дренажную систему',
          'Допускается',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63650177',
        text:
          'На каких технологических трубопроводах не допускается применять сальниковые компенсаторы?',
        answers: [
          'На трубопроводах со средами 1-ой группы',
          'На трубопроводах для трудногорючих веществ',
          'На трубопроводах для негорючих веществ',
          'Допускается применять на всех перечисленных трубопроводах',
        ],
        correctAnswers: ['На трубопроводах со средами 1-ой группы'],
      },
      {
        code: '63650178',
        text:
          'При каком повышении давления в нагнетательном трубопроводе буровых установок должно быть предусмотрено автоматическое отключение приводов буровых насосов?',
        answers: [
          'При повышении давления на 5% выше допускаемого',
          'При повышении давления на 15% выше допускаемого',
          'При повышении давления на 10% выше допускаемого',
          'При повышении давления на 20% выше допускаемого',
        ],
        correctAnswers: ['При повышении давления на 10% выше допускаемого'],
      },
      {
        code: '63650179',
        text:
          'Какие требования к площадкам и лестницам бурового оборудования для нефтяной и газовой промышленности указаны верно?',
        answers: [
          'Не предназначенные для верхового рабочего площадки для обслуживания элементов оборудования на высоте от 1800 мм и более должны быть шириной не менее 450 мм с полезной площадью не менее 0,4 м²',
          'Для подъема на площадки, находящиеся на высоте от 250 до 750 мм, должны быть предусмотрены трапы и ступени, а на высоту более 750 мм - маршевые лестницы с перильными ограждениями',
          'Площадка верхового рабочего, выступающая во внутреннее пространство вышки или мачты, оборудованная козырьком, должна быть шириной не менее 550 мм с бортами не менее 100 мм',
          'Площадка верхового рабочего должна быть оснащена тремя стропами',
        ],
        correctAnswers: [
          'Для подъема на площадки, находящиеся на высоте от 250 до 750 мм, должны быть предусмотрены трапы и ступени, а на высоту более 750 мм - маршевые лестницы с перильными ограждениями',
        ],
      },
      {
        code: '63650180',
        text:
          'Какова величина нормативной санитарно-защитной зоны для промышленных объектов по добыче нефти при выбросе сероводорода до 0,5 т/сутки, а также с малым содержанием летучих углеводородов?',
        answers: ['100 м', '500 м', '300 м', '1000 м'],
        correctAnswers: ['300 м'],
      },
      {
        code: '63650181',
        text:
          'В каком случае допускается применение на складах нефти и нефтепродуктов поршневых насосов для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей при малых объемных скоростях подачи, в том числе в системах дозирования?',
        answers: [
          'Допускается при обосновании принятого технического решения в проектной документации',
          'Допускается при согласовании с организацией-изготовителем и федеральным органом исполнительной власти, уполномоченным в области промышленной безопасности',
          'Допускается в случае, если данное условие их применения определено в технической документации организации-изготовителя',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается при обосновании принятого технического решения в проектной документации',
        ],
      },
      {
        code: '63650182',
        text:
          'По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?',
        answers: [
          'Только по промышленным подземным водам',
          'Только по общераспространенным полезным ископаемым',
          'Только по углеводородному сырью',
          'Только по твердым полезным ископаемым',
          'По всем перечисленным',
        ],
        correctAnswers: ['По всем перечисленным'],
      },
      {
        code: '63650183',
        text:
          'Какими блокировками на отключение должны быть оснащены насосы, применяемые для нагнетания сжиженных горючих газов, легковоспламеняющихся жидкостей и горючих жидкостей?',
        answers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости в корпусе насоса, достижении опасных значений в приемной емкости',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отклонениях от опасных значений в расходной и приемной емкостях',
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой среды и достижении опасных значений параметров в расходной и приемной емкостях',
        ],
        correctAnswers: [
          'Блокировками, исключающими пуск и (или) прекращающими работу при отсутствии перемещаемой жидкости внутри корпуса насоса или при отклонениях ее уровней в приемной и расходной емкостях от предельно допустимых значений',
        ],
      },
      {
        code: '63650184',
        text:
          'Какая система отопления предусматривается в помещениях, имеющих взрывоопасные зоны?',
        answers: [
          'Только система парового отопления',
          'Только система водяного отопления',
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
        correctAnswers: [
          'Система воздушного отопления, совмещенная с приточной вентиляцией',
        ],
      },
      {
        code: '63650185',
        text:
          'Какие противоаварийные устройства необходимо применять в технологических системах для предупреждения аварий и предотвращения их развития?',
        answers: [
          'Все устройства, задействованные в системе противоаварийной защиты, включая исполнительные механизмы',
          'Запорную арматуру, средства защиты от превышения давления, огнепреградители',
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
          'Запорную арматуру, предохранительные устройства от превышения давления, огнепреградители, автоматические системы подавления взрыва и системы для аварийного опорожнения аппаратов',
        ],
        correctAnswers: [
          'Запорную и запорно-регулирующую арматуру, клапаны, отсекающие и другие отключающие устройства, предохранительные устройства от превышения давления, средства подавления и локализации пламени, автоматические системы подавления взрыва',
        ],
      },
      {
        code: '63650186',
        text:
          'В каких местах не допускается размещать фланцевые соединения трубопроводов с взрывопожароопасными, токсичными и едкими веществами?',
        answers: [
          'Над местами, предназначенными для прохода людей и рабочими площадками',
          'Над автодорогами',
          'На трубопроводах, идущих по стенам зданий',
          'На трубопроводах, проложенных по эстакадам',
        ],
        correctAnswers: [
          'Над местами, предназначенными для прохода людей и рабочими площадками',
        ],
      },
      {
        code: '63650187',
        text:
          'Какие нефтепродукты допускается сливать через герметичные верхние сливные устройства?',
        answers: [
          'Нефтепродукты с низкой вязкостью',
          'Авиационное топливо',
          'Светлые нефтепродукты',
          'Высоковязкие нефтепродукты',
        ],
        correctAnswers: ['Светлые нефтепродукты'],
      },
      {
        code: '63650188',
        text:
          'При каком уровне загазованности воздушной среды должен автоматически прекращаться слив и налив нефти и светлых нефтепродуктов на сливоналивных железнодорожных эстакадах?',
        answers: [
          '20% объемных от нижнего концентрационного предела распространения пламени',
          '30% объемных от нижнего концентрационного предела распространения пламени',
          '50% объемных от нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          '50% объемных от нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63650189',
        text:
          'В каких случаях допускается применение для нагнетания легковоспламеняющихся жидкостей и горючих жидкостей поршневых, плунжерных, мембранных, винтовых и шестеренчатых насосов?',
        answers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости, а также блокировок, срабатывающих автоматически при превышении значений критических уровней в расходной и приемной емкостях',
          'При наличии сигнализации по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
          'При наличии блокировок по предельно допустимому нижнему уровню в расходной емкости и предельно допустимому верхнему уровню в приемной емкости',
        ],
        correctAnswers: [
          'При малых объемных скоростях подачи, в том числе в системах дозирования. При обосновании в проектной документации',
        ],
      },
      {
        code: '63650190',
        text: 'Что не должно быть отражено в проекте санитарно-защитной зоны?',
        answers: [
          'Мероприятия по защите населения от воздействия выбросов вредных химических примесей в атмосферный воздух и физического воздействия',
          'Мероприятия по ликвидации аварийных выбросов вредных примесей',
          'Размер и границы санитарно-защитной зоны',
          'Функциональное зонирование территории санитарно-защитной зоны и режим ее использования',
        ],
        correctAnswers: [
          'Мероприятия по ликвидации аварийных выбросов вредных примесей',
        ],
      },
      {
        code: '63650191',
        text:
          'Что является критерием взрывоопасности согласно Федеральным нормам и правилам "Общие правила взрывобезопасности для взрывопожароопасных химических, нефтехимических и нефтеперерабатывающих производств"?',
        answers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
          'Класс опасности обращающихся в процессе веществ',
          'Температура самовоспламенения паров, обращающихся в процессе веществ',
          'Скорость распространения горения обращающихся в процессе веществ',
        ],
        correctAnswers: [
          'Количественное значение энергетического потенциала технологических блоков, входящих в технологическую систему, определяемое расчетом',
        ],
      },
      {
        code: '63650192',
        text:
          'Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих жидкостей и жидкостей, содержащих сернистый водород?',
        answers: [
          'Запрещается',
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
          'Разрешается по согласованию с проектной организацией',
          'Разрешается',
        ],
        correctAnswers: [
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
        ],
      },
      {
        code: '63650193',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
          'Все перечисленные требования указаны верно',
          'Использование сжатого воздуха или другого газа для подъема давления при проведении гидравлического испытания не допускается',
          'Гидравлическое испытание производится при положительной температуре окружающего воздуха',
          'Для проведения гидравлического испытания следует использовать воду. Температура воды должна быть не ниже 5 °C и не выше 40 °C, если в технической документации организации - изготовителя оборудования не указано конкретное значение температуры',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63650194',
        text:
          'Что из перечисленного не требует защиты от прямых ударов молнии?',
        answers: [
          'Пространство над срезом горловины цистерн с легковоспламеняющимися жидкостями при открытом наливе продукта на наливной эстакаде, ограниченное цилиндром высотой 2,5 м и радиусом 5 м',
          'Дыхательная арматура резервуаров с легковоспламеняющимися жидкостями и пространство над ней',
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
        ],
        correctAnswers: [
          'Насосные установки (станции) нефти и нефтепродуктов и пространство над ними',
        ],
      },
      {
        code: '63650195',
        text:
          'Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?',
        answers: [
          'Проектной документацией или документацией на техническое перевооружение',
          'Эксплуатационной документацией',
          'Паспортом технического устройства',
          'Планом ликвидации аварий',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63650196',
        text: 'Что должно быть предусмотрено в конструкциях вышек и мачт?',
        answers: [
          'Места для крепления блоков для канатов подвески машинных ключей, грузового каната вспомогательной лебедки, каната подвески пневмо(гидро) ключей для свинчивания обсадных труб',
          'Площадка для обслуживания кронблока',
          'Устройство для крепления ролика для монтажа, демонтажа кронблока и его секций (для стационарных буровых установок)',
          'Все перечисленное',
          'Площадка для обслуживания соединения горловины стояка с буровым шлангом',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63650197',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Только средствами автоматического регулирования',
          'Только средствами противоаварийной защиты',
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63650198',
        text:
          'Для каких месторождений составляется технологический проект разработки месторождения и дополнения к нему?',
        answers: [
          'С долей начальных геологических запасов категории В2 по основному полезному ископаемому более 65%',
          'С долей начальных геологических запасов категории В1 по основному полезному ископаемому более 50%',
          'С долей начальных геологических запасов категории A по основному полезному ископаемому более 50%',
          'С долей начальных геологических запасов категории A по основному полезному ископаемому более 75%',
        ],
        correctAnswers: [
          'С долей начальных геологических запасов категории A по основному полезному ископаемому более 75%',
        ],
      },
      {
        code: '63650199',
        text:
          'В каких случаях подлежат обязательной тепловой изоляции технологические трубопроводы?',
        answers: [
          'В случае необходимости обеспечения температурных условий в помещении (ограничение общего теплового потока)',
          'Для обеспечения энергоэффективности',
          'Для исключения конденсации влаги на внутренней поверхности технологического трубопровода, транспортирующего газообразный продукт, который при конденсации может оказывать агрессивное воздействие на материал трубы',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63650200',
        text:
          'На какое давление должны быть рассчитаны нагнетательный трубопровод и его элементы при рабочем давлении до 20 МПа?',
        answers: [
          'На давление, равное 1,4-кратному максимальному рабочему давлению',
          'На давление, равное 2-кратному максимальному рабочему давлению',
          'На давление, равное 1,5-кратному максимальному рабочему давлению',
          'На давление, равное 2,5-кратному максимальному рабочему давлению',
        ],
        correctAnswers: [
          'На давление, равное 1,5-кратному максимальному рабочему давлению',
        ],
      },
      {
        code: '63650201',
        text:
          'Какие размеры настила должны быть предусмотрены при наличии в открытой емкости для бурового раствора встроенного в нее циркуляционного желоба?',
        answers: [
          'Не менее 650 мм с перильным ограждением с двух сторон',
          'Не менее 750 мм с перильным ограждением с двух сторон',
          'Не менее 500 мм с перильным ограждением с одной стороны',
          'Не менее 700 мм с перильным ограждением с двух сторон',
        ],
        correctAnswers: [
          'Не менее 750 мм с перильным ограждением с двух сторон',
        ],
      },
      {
        code: '63650202',
        text:
          'Какие габариты должен иметь горизонтальный участок приемного моста, установленный у вышки со стороны ворот?',
        answers: [
          'Длина не менее 12 м и ширина не менее 1 м',
          'Длина не менее 14 м и ширина не менее 2 м',
          'Длина не менее 18 м и ширина не менее 3 м',
          'Длина не менее 16 м и ширина не менее 1,5 м',
        ],
        correctAnswers: ['Длина не менее 14 м и ширина не менее 2 м'],
      },
      {
        code: '63650203',
        text:
          'Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?',
        answers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок',
          'Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто: по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
        ],
        correctAnswers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
        ],
      },
      {
        code: '63650204',
        text:
          'На какое давление должны быть рассчитаны уплотнения в гидравлической части насоса, корпусах предохранительного устройства и пневмокомпенсатора?',
        answers: [
          'На давление, равное 2,5-кратному максимальному рабочему давлению насоса',
          'На давление, равное 2,0-кратному максимальному рабочему давлению насоса',
          'На давление, равное 1,25-кратному максимальному рабочему давлению насоса',
          'На давление, равное 1,5-кратному максимальному рабочему давлению насоса',
        ],
        correctAnswers: [
          'На давление, равное 1,5-кратному максимальному рабочему давлению насоса',
        ],
      },
      {
        code: '63650205',
        text:
          'Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Эффективными быстродействующими системами, обеспечивающими непрерывность технологического процесса',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
          'Системами ручного (без применения вычислительной техники) регулирования',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63650206',
        text:
          'Какие из мер, направленных на недопущение гидравлического удара при перекачке нефтепродуктов из берегового трубопровода в танкер, указаны неверно?',
        answers: [
          'Регулирование линейной скорости потока, то есть интенсивности перекачки продукта, до величины, смягчающей воздействие гидравлического удара',
          'Уменьшение времени закрытия регулирующего клапана',
          'Использование систем сброса давления в уравнительные резервуары для снижения воздействия возможного гидравлического удара',
        ],
        correctAnswers: ['Уменьшение времени закрытия регулирующего клапана'],
      },
      {
        code: '63650207',
        text:
          'В какой документации устанавливаются места установки приборов, их количество и параметры контроля процесса перекачки нефти и нефтепродукта по трубопроводу у насосной станции и стендеров?',
        answers: [
          'В эксплуатационной документации',
          'В техническом регламенте',
          'В проектной документации',
          'В технологическом регламенте',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63650208',
        text:
          'В соответствии с чем следует производить монтаж технологического оборудования и трубопроводов?',
        answers: [
          'В соответствии со всем перечисленным',
          'В соответствии с рабочей документацией',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['В соответствии со всем перечисленным'],
      },
      {
        code: '63650209',
        text:
          'Какой должна быть прокладка технологических трубопроводов взрывопожароопасных веществ в границах опасного производственного объекта?',
        answers: [
          'В каналах (закрытых или с засыпкой песком)',
          'В грунте',
          'Надземной на несгораемых конструкциях - эстакадах, этажерках, стойках, опорах',
          'Любой из перечисленных',
        ],
        correctAnswers: ['Любой из перечисленных'],
      },
      {
        code: '63650210',
        text:
          'Какие блокировки должны быть предусмотрены в конструкции систем управления оборудованием буровых установок?',
        answers: [
          'Ограничитель грузоподъемности лебедки',
          'Ограничитель высоты подъема талевого блока',
          'Блокировки подъема пневмоклиньев при вращающемся роторе и включения ротора при поднятых клиньях',
          'Блокировка, исключающая одновременное включение главного и вспомогательного приводов лебедки',
          'Все вышеперечисленные блокировки',
        ],
        correctAnswers: ['Все вышеперечисленные блокировки'],
      },
      {
        code: '63650211',
        text:
          'Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?',
        answers: [
          'Только бесперебойное функционирование автоматизированных систем контроля, управления, ПАЗ для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Должны быть обеспечены все указанные требования',
          'Только защита персонала, постоянно находящегося в помещении управления (операторные), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
        ],
        correctAnswers: ['Должны быть обеспечены все указанные требования'],
      },
      {
        code: '63650212',
        text:
          'Кем устанавливается категория технологического трубопровода для каждого технологического трубопровода?',
        answers: [
          'Разработчиком проекта',
          'Комиссией эксплуатирующей организации',
          'Специализированной сторонней организацией',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Разработчиком проекта'],
      },
      {
        code: '63650213',
        text:
          'На какое давление должны быть рассчитаны уплотнительные элементы в гидравлической части вертлюга?',
        answers: [
          'На давление, равное 2,5-кратному максимальному рабочему давлению насоса',
          'На давление, равное 2,0-кратному максимальному рабочему давлению насоса',
          'На давление, равное 1,25-кратному максимальному рабочему давлению насоса',
          'На давление, равное 1,5-кратному максимальному рабочему давлению насоса',
        ],
        correctAnswers: [
          'На давление, равное 1,5-кратному максимальному рабочему давлению насоса',
        ],
      },
      {
        code: '63650214',
        text:
          'Что из перечисленного прикладывается к паспортам технологических трубопроводов?',
        answers: [
          'Расчет на прочность',
          'Схемы (чертежи) технологического трубопровода с указанием размеров участков, номинального диаметра, исходной и отбраковочной толщины элементов технологического трубопровода (при наличии указанных выше сведений непосредственно в паспорте технологического трубопровода допускается на схеме их не приводить), мест установки опор, арматуры, фланцев, заглушек и других деталей, мест спускных, продувочных и дренажных устройств, сварных стыков, контрольных засверловок (если они имеются) и их нумерации',
          'Регламент проведения в зимнее время пуска (остановки) технологического трубопровода (для технологических трубопроводов, расположенных на открытом воздухе или в неотапливаемом помещении, в случае если проект и (или) эксплуатационная документация предусматривает пуск при температурах ниже минимальной температуры стенки технологического трубопровода)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63650215',
        text:
          'На какие опасные производственные объекты не распространяются Правила промышленной безопасности складов нефти и нефтепродуктов?',
        answers: [
          'На наливные станции магистральных нефтепроводов и нефтепродуктопроводов',
          'На товарно-сырьевые парки центральных пунктов сбора нефтяных месторождений',
          'На объекты, предназначенные для приема и выдачи нефти и нефтепродуктов',
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
        ],
        correctAnswers: [
          'На объекты хранения нефтепродуктов с упругостью паров выше 93,3 кПа',
        ],
      },
      {
        code: '63650216',
        text:
          'В каком случае автоматические предохранительные клапаны должны быть установлены на причале, чтобы исключить возможное повышение давления потоком нефти и нефтепродукта?',
        answers: [
          'При расположении береговых насосов более чем в 50 м от причала',
          'Если это обосновано в проектной документации',
          'В любом случае',
          'При расположении береговых насосов более чем в 100 м от причала',
        ],
        correctAnswers: [
          'При расположении береговых насосов более чем в 100 м от причала',
        ],
      },
      {
        code: '63650217',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений размещение инженерных сетей с токсичными жидкостями и газами под зданиями и сооружениями?',
        answers: [
          'Допускается',
          'Допускается при условии прокладки их в герметичном стальном кожухе, длина которого превышает габариты помещения или сооружения не менее чем на 5 м',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63650218',
        text:
          'При каких условиях допускается налив нефтепродуктов в автомобильные цистерны с применением гибких шлангов?',
        answers: [
          'При обосновании в проектной документации',
          'При разработке обоснования безопасности и реализации компенсирующих мероприятий',
          'При величине допустимого риска меньше, чем 10-6',
        ],
        correctAnswers: ['При обосновании в проектной документации'],
      },
      {
        code: '63650219',
        text:
          'Какая должна быть максимальная температура производственных сточных вод при сбросе в канализацию опасных производственных объектов складов нефти и нефтепродуктов?',
        answers: ['40 °C', '60 °C', '34 °C'],
        correctAnswers: ['40 °C'],
      },
      {
        code: '63650220',
        text:
          'Какие из перечисленных конструкций подлежат заземлению для защиты от проявлений статического электричества?',
        answers: [
          'Металлические оголовки и патрубки гибких шлангов для слива и налива нефти и нефтепродуктов',
          'Железнодорожные рельсы сливоналивных участков, электрически соединенные между собой, а также металлические конструкции сливоналивных эстакад с двух сторон по длине',
          'Наземные трубопроводы через каждые 200 м и дополнительно на каждом ответвлении с присоединением каждого ответвления к заземлителю',
          'Все перечисленные конструкции',
        ],
        correctAnswers: ['Все перечисленные конструкции'],
      },
      {
        code: '63650221',
        text:
          'Какая должна быть минимальная глубина заложения подземных технологических трубопроводов от поверхности земли до верхней части трубы или теплоизоляции в тех местах, где не предусмотрено движение транспорта?',
        answers: ['0,7 м', '0,8 м', '0,9 м', '0,6 м'],
        correctAnswers: ['0,6 м'],
      },
      {
        code: '63650222',
        text:
          'Где допускается размещение фланцевых соединений на трубопроводах с пожаровзрывоопасными, токсичными и едкими веществами?',
        answers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
          'Над местами, предназначенными для прохода людей',
          'Над рабочими площадками',
          'В местах ввода в технологические здания и сооружения',
        ],
        correctAnswers: [
          'В местах установки арматуры или подсоединения трубопроводов к аппаратам',
        ],
      },
      {
        code: '63650223',
        text:
          'Каким условиям должны соответствовать расчеты на прочность и устойчивость при проектировании подвышечных оснований буровых установок?',
        answers: [
          'Максимальной статической нагрузке на крюке',
          'Максимальной статической нагрузке на ротор',
          'Максимальной статической нагрузке на подсвечник',
          'Всем вышеперечисленным условиям',
        ],
        correctAnswers: ['Всем вышеперечисленным условиям'],
      },
      {
        code: '63650224',
        text:
          'Какое из перечисленных требований к проходным мостикам, устанавливаемым при прокладке на эстакадах технологических трубопроводов, требующих регулярного обслуживания (не менее одного раза в смену), указано верно? Выберите 2 правильных варианта ответа',
        answers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
          'Мостики должны быть изготовлены из несгораемых материалов шириной не менее 0,8 м',
          'Перила должны быть высотой не менее 1,3 м',
        ],
        correctAnswers: [
          'Количество их определяется проектом',
          'Через каждые 200 м и в торцах эстакады при расстоянии менее 200 м должны быть вертикальные лестницы с шатровым ограждением или маршевые лестницы',
        ],
      },
      {
        code: '63650225',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений размещение инженерных сетей транзитных внутриплощадочных трубопроводов с токсичными жидкостями по стенам и кровлям зданий?',
        answers: [
          'Допускается при условии их прокладки по глухой стене не ниже II степени огнестойкости',
          'Допускается',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
    ],
    63656: [
      {
        code: '63656000',
        text:
          'Какие трубы должны применяться для магистральных аммиакопроводов (МАП)? Выберите 2 правильных варианта ответа.',
        answers: [
          'Горячекатаные бесшовные с содержанием углерода не более 0,2%',
          'Сварные трубы из спокойной стали с содержанием меди не более 0,3%',
          'Горячекатаные бесшовные с содержанием углерода не более 0,5%',
          'Сварные трубы из спокойной стали с содержанием меди не более 0,6%',
        ],
        correctAnswers: [
          'Горячекатаные бесшовные с содержанием углерода не более 0,2%',
          'Сварные трубы из спокойной стали с содержанием меди не более 0,3%',
        ],
      },
      {
        code: '63656001',
        text:
          'Какая трубопроводная арматура, предназначенная для соединений с трубами сваркой встык, должна применяться для линейной части магистральных аммиакопроводов (МАП)? Выберите 2 правильных варианта ответа.',
        answers: [
          'Пластмассовая трубопроводная арматура',
          'Литая трубопроводная арматура',
          'Чугунная трубопроводная арматура',
          'Кованая трубопроводная арматура',
          'Керамическая трубопроводная арматура',
        ],
        correctAnswers: [
          'Литая трубопроводная арматура',
          'Кованая трубопроводная арматура',
        ],
      },
      {
        code: '63656002',
        text:
          'Какой документацией определяется защита оборудования и трубопроводов площадочных сооружений от избыточного давления, в том числе при гидроударе?',
        answers: [
          'Проектной документацией (документацией)',
          'Эксплуатационной документацией',
          'Документационным обеспечением системы управления промышленной безопасностью',
          'Техническим регламентом',
        ],
        correctAnswers: ['Проектной документацией (документацией)'],
      },
      {
        code: '63656003',
        text:
          'Какие меры необходимо предпринять при обнаружении на месте производства работ в охранной зоне подземных коммуникаций и сооружений, не указанных в проектной документации? Выберите 2 правильных варианта ответа.',
        answers: [
          'Вызвать аварийно-восстановительную бригаду',
          'Немедленно остановить работы',
          'Немедленно вывести персонал и технические средства за пределы этой зоны',
          'Вызвать представителя эксплуатационной организации',
        ],
        correctAnswers: [
          'Немедленно остановить работы',
          'Вызвать представителя эксплуатационной организации',
        ],
      },
      {
        code: '63656004',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63656005',
        text:
          'Кто утверждает оптимальные маршруты следования персонала и техники к объектам и участкам ОПО магистральных аммиакопроводов (МАП), разрабатываемые в территориальных подразделениях?',
        answers: [
          'Руководитель подразделения',
          'Главный механик подразделения',
          'Главный инженер подразделения',
          'Руководитель, ответственный за локализацию и ликвидацию аварии',
        ],
        correctAnswers: ['Руководитель подразделения'],
      },
      {
        code: '63656006',
        text:
          'Что обязана предпринять эксплуатирующая организация ОПО магистральных трубопроводов (МТ) в случае угрозы аварии и возможного возникновения риска поражения для населения?',
        answers: [
          'Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ',
          'Продолжать эксплуатацию ОПО МТ в обычном порядке',
          'Изолировать территорию места аварии или инцидента для доступа работников',
        ],
        correctAnswers: [
          'Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ',
        ],
      },
      {
        code: '63656007',
        text:
          'Какие из перечисленных степеней со среднестатистическим уровнем риска аварий при анализе результатов расчетов на участках и составляющих ОПО магистральных трубопроводов (МТ) указаны неверно?',
        answers: [
          'Высокая',
          'Низкая',
          'Средняя',
          'Чрезвычайно высокая',
          'Чрезвычайно низкая',
        ],
        correctAnswers: ['Чрезвычайно низкая'],
      },
      {
        code: '63656008',
        text:
          'В каком случае допускается объединять между собой системы продувочных, сбросных линий и линий сброса газа с предохранительных клапанов ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Не допускается ни в каком случае',
          'Если это согласовано с территориальным органом Ростехнадзора',
          'Если это обосновано проектной документацией (документацией)',
          'Если обеспечены безопасные условия рассеивания газа с учетом местных климатических условий, включая розу ветров',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63656009',
        text:
          'Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика поверхности шва и кромки разделки?',
        answers: [
          'Должны быть обезжирены водными смывками',
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
          'Должны быть просвечены рентгеном',
        ],
        correctAnswers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
        ],
      },
      {
        code: '63656010',
        text:
          'На сколько часов работы должно быть предусмотрено резервное питание центрального пункта управления в случае отключения от основного источника электропитания?',
        answers: ['На 2 часа', 'На 3 часа', 'На 4 часа', 'На 5 часов'],
        correctAnswers: ['На 4 часа'],
      },
      {
        code: '63656011',
        text: 'Какое значение составляет смертельная токсодоза аммиака?',
        answers: [
          '15,0 мг x мин/л',
          '50,0 мг x мин/л',
          '100,0 мг x мин/л',
          '150,0 мг x мин/л',
        ],
        correctAnswers: ['150,0 мг x мин/л'],
      },
      {
        code: '63656012',
        text:
          'В каких случаях не применяются Правила безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'При проведении экспертизы промышленной безопасности внутризаводских трубопроводов организаций, производящих и потребляющих жидкий аммиак',
          'При разработке технологических процессов, проектировании, строительстве, эксплуатации, реконструкции ОПО МАП',
          'При диагностировании и ремонте технических устройств, применяемых на ОПО МАП',
        ],
        correctAnswers: [
          'При проведении экспертизы промышленной безопасности внутризаводских трубопроводов организаций, производящих и потребляющих жидкий аммиак',
        ],
      },
      {
        code: '63656013',
        text:
          'С какой периодичностью должна проводиться информационная работа с населением близлежащих населенных пунктов по правилам поведения в зоне безопасных расстояний магистральных аммиакопроводов (МАП) и действиям в случае обнаружения запаха аммиака?',
        answers: [
          'Ежеквартально',
          'Дважды в год',
          'Ежегодно',
          'Один раз в три года',
        ],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63656014',
        text:
          'В какие сроки эксплуатирующая организация обязана пересматривать инструкции на рабочих местах?',
        answers: [
          'Сроки регламентируются внутренними документами организации',
          'Ежегодно',
          'Один раз в пять лет',
          'Один раз в три года',
        ],
        correctAnswers: ['Один раз в пять лет'],
      },
      {
        code: '63656015',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Только лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Только лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63656016',
        text:
          'Что не учитывается при проведении количественного анализа риска ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Квалификация производственного персонала',
          'Давление в трубопроводе',
          'Метеоусловия',
          'Параметры истечения аммиака',
          'Рельеф местности',
        ],
        correctAnswers: ['Квалификация производственного персонала'],
      },
      {
        code: '63656017',
        text:
          'Чем следует предусматривать возможность продувки газопроводов и оборудования на компрессорных станциях ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Инертным газом (паром)',
          'Только аргоном',
          'Воздухом',
          'Углекислым газом',
        ],
        correctAnswers: ['Инертным газом (паром)'],
      },
      {
        code: '63656018',
        text:
          'Для реализации каких механизмов правового регулирования не предусмотрена необходимость проведения анализа опасностей технологических процессов, количественный анализ риска и иные методы анализа риска аварий, связанных с выбросом транспортируемых углеводородов?',
        answers: [
          'Риск-менеджмента',
          'Производственного контроля',
          'Обоснования безопасности',
          'Декларирования промышленной безопасности',
        ],
        correctAnswers: ['Производственного контроля'],
      },
      {
        code: '63656019',
        text:
          'Какие документы должны быть оформлены на установки системы электрохимической защиты?',
        answers: ['Инструкции', 'Паспорта', 'Декларации соответствия'],
        correctAnswers: ['Паспорта'],
      },
      {
        code: '63656020',
        text:
          'Куда должны быть переданы материалы фактического положения трубопровода (исполнительная съемка) с привязкой охранных зон входящих в его состав коммуникаций и объектов?',
        answers: [
          'В территориальные органы Ростехнадзора',
          'В территориальные органы Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Заинтересованным органам исполнительной власти',
          'В местные органы власти и управления',
        ],
        correctAnswers: ['В местные органы власти и управления'],
      },
      {
        code: '63656021',
        text:
          'Каким образом должна определяться температура жидкого аммиака, поступающего в магистральные аммиакопроводы (МАП)?',
        answers: [
          'Проектным решением с учетом максимально возможной температуры грунта на глубине залегания трубопровода',
          'На усмотрение эксплуатирующей организации',
          'Требованиями правил безопасности для опасных производственных объектов магистральных трубопроводов',
        ],
        correctAnswers: [
          'Проектным решением с учетом максимально возможной температуры грунта на глубине залегания трубопровода',
        ],
      },
      {
        code: '63656022',
        text:
          'Что не относится к подготовительным операциям перед обследованием оборудования с выводом его из эксплуатации?',
        answers: ['Дегазация', 'Дефектоскопия', 'Опорожнение', 'Очистка'],
        correctAnswers: ['Дефектоскопия'],
      },
      {
        code: '63656023',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '3 года', '1 год', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63656024',
        text:
          'Оповещение населения каких населенных пунктов обязана обеспечить эксплуатирующая организация при возникновении аварий с применением средств локальной системы оповещения?',
        answers: [
          'Расположенных на расстоянии 2,5 км от оси МАП',
          'Расположенных на расстоянии 3,5 км от оси МАП',
          'Расположенных на расстоянии 5 км от оси МАП',
          'Расположенных на расстоянии 7 км от оси МАП',
        ],
        correctAnswers: ['Расположенных на расстоянии 2,5 км от оси МАП'],
      },
      {
        code: '63656025',
        text:
          'Кого должны поставить в известность о начале и сроках проведения работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО магистральных аммиакопроводов (МАП) производители работ?',
        answers: [
          'Территориальный орган Ростехнадзора',
          'Проектную организацию',
          'Организацию, осуществляющую экспертизу промышленной безопасности',
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МАП',
          'Органы местного самоуправления',
        ],
        correctAnswers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МАП',
        ],
      },
      {
        code: '63656026',
        text:
          'Какое выбирается минимальное количество маршрутов подъезда к месту аварии на магистральных аммиакопроводах (МАП) в периоды паводков при отсутствии точных сведений о состоянии сезонных переправ?',
        answers: [
          'Три',
          'Два',
          'Четыре',
          'Количество определяется руководителем территориального подразделения МАП',
        ],
        correctAnswers: ['Два'],
      },
      {
        code: '63656027',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'Необходимым сварочным оборудованием и наглядными пособиями (плакатами, схемами и т. д.) по вопросу соблюдения противопожарного режима на объекте',
          'На рабочем месте не должно быть ничего, кроме исправного сварочного оборудования',
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63656028',
        text:
          'Какой может быть минимальная глубина заложения магистрального трубопровода (МТ) в скальных грунтах, выходящих на поверхность дна на судоходных реках, считая от верха забалластированного трубопровода?',
        answers: ['1,4 м', '1,0 м', '0,5 м', '0,8 м'],
        correctAnswers: ['0,8 м'],
      },
      {
        code: '63656029',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного требованиями нормативной документации, устанавливающей в определенной области применения комплекс норм, правил, требований, применяемых при производстве сварочных работ и контроле их качества (далее – НД), независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
          'Проверку знания теоретических основ сварки',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63656030',
        text:
          'Какие случаи пересмотра технологических регламентов указаны неверно?',
        answers: [
          'Изменение проектной документации',
          'Внесение изменений в документацию системы управления промышленной безопасностью',
          'Изменение требований промышленной безопасности',
          'Изменение параметров технологического процесса',
        ],
        correctAnswers: [
          'Внесение изменений в документацию системы управления промышленной безопасностью',
        ],
      },
      {
        code: '63656031',
        text:
          'Чье письменное разрешение необходимо для производства работ по реконструкции, техническому перевооружению и капитальному ремонту ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Территориального органа Ростехнадзора',
          'Организации, осуществляющей экспертизу промышленной безопасности',
          'Эксплуатирующей организации',
          'Проектной организации',
        ],
        correctAnswers: ['Эксплуатирующей организации'],
      },
      {
        code: '63656032',
        text:
          'Какая должна быть ударная вязкость на образцах Менаже размером 5 х 10 х 55 мм при температуре минус 40 °С для толщины стенки менее 10 мм?',
        answers: ['4 кгс/см²', '5 кгс/см²', '3 кгс/см²', '2 кгс/см²'],
        correctAnswers: ['4 кгс/см²'],
      },
      {
        code: '63656033',
        text:
          'На каком расстоянии производится установка опознавательных знаков обозначения трассы магистрального трубопровода?',
        answers: [
          'В пределах прямой видимости',
          'В пределах прямой видимости, но не реже чем через 400 м и на углах поворота',
          'В пределах прямой видимости, но не реже чем через 500 м и на углах поворота',
          'В пределах прямой видимости, но не реже чем через 200 м',
        ],
        correctAnswers: [
          'В пределах прямой видимости, но не реже чем через 500 м и на углах поворота',
        ],
      },
      {
        code: '63656034',
        text:
          'Какие обозначения не должна иметь в обязательном порядке разделительная запорная арматура на трубопроводе магистральных аммиакопроводов (МАП)?',
        answers: [
          'Указатель направления потока',
          'Указатели "Открыто" и "Закрыто"',
          'Присвоенный запорной арматуре номер',
          'Границы обслуживания участка МАП территориальным подразделением',
        ],
        correctAnswers: [
          'Границы обслуживания участка МАП территориальным подразделением',
        ],
      },
      {
        code: '63656035',
        text:
          'Какое количество запасных коробок к каждому фильтрующему противогазу должен иметь эксплуатационный персонал, выполняющий работы по осмотру и обслуживанию ОПО магистральных аммиакопроводов (МАП), исходя из особенностей технологического процесса?',
        answers: [
          'Один',
          'Не менее трех',
          'Не менее двух',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63656036',
        text:
          'Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Организацию взаимодействия сил и средств',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте',
          'Организацию управления, связи и оповещения при аварии на объекте, первоочередные действия при получении сигнала об авариях на объекте',
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
          'Мероприятия, направленные на обеспечение безопасности населения, сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Состав и дислокацию сил и средств',
        ],
        correctAnswers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
        ],
      },
      {
        code: '63656037',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны уметь пользоваться СИЗ',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны пройти инструктаж',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63656038',
        text:
          'Применение какой маркировки допускается при выполнении одного сварного соединения несколькими сварщиками?',
        answers: [
          'Допускается применение клейма, определенного распорядительным документом организации, выполняющей сварочные работы',
          'Должны быть поставлены клейма всех сварщиков, участвовавших в сварке',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
          'Допускается применение клейма сварщика, выполнившего наибольший объем работ',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного распорядительным документом организации, выполняющей сварочные работы',
        ],
      },
      {
        code: '63656039',
        text:
          'Каким образом производятся работы, связанные с временным затоплением земель, находящихся в охранных зонах трубопроводов?',
        answers: [
          'По согласованию между землепользователем, предприятием трубопроводного транспорта и Ростехнадзором',
          'По согласованию между землепользователем и Ростехнадзором',
          'По согласованию между землепользователем и предприятием трубопроводного транспорта',
        ],
        correctAnswers: [
          'По согласованию между землепользователем и предприятием трубопроводного транспорта',
        ],
      },
      {
        code: '63656040',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О знании действий при пожаре',
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания первой помощи',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63656041',
        text:
          'Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в производственно-технологической документации по сварке (далее –ПТД)?',
        answers: [
          'Только порядок и последовательность сборки',
          'Только размеры, количество и расположение прихваток',
          'Только способы крепления деталей',
          'Все перечисленное',
          'Только используемые при сборке приспособления и оборудование',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63656042',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'К II группе',
          'К группе работ высокой степени опасности',
          'К группе работ средней степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63656043',
        text:
          'С учетом каких факторов определяются сроки и методы технического диагностирования опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Только с учетом опасности и технического состояния участков линейной части опасных производственных объектов магистральных трубопроводов',
          'Только с учетом показателей эксплуатации (срок службы, ресурс), установленных проектной и/или нормативно-технической документацией',
          'Только с учетом опасности и технического состояния сооружений и технических устройств площадочных сооружений опасных производственных объектов магистральных трубопроводов',
          'Всех перечисленных факторов',
        ],
        correctAnswers: ['Всех перечисленных факторов'],
      },
      {
        code: '63656044',
        text:
          'Какие факторы следует учитывать при определении периодичности, мест и методов контроля, применяемых при техническом диагностировании?',
        answers: [
          'Характеристики наиболее опасных участков, особенности расположения',
          'Данные о строительстве МАП',
          'Условия эксплуатации (продолжительность, технологические режимы)',
          'Все перечисленные',
          'Данные о режимах работы системы электрохимической защиты',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63656045',
        text:
          'Какой документацией определяются объем и периодичность выполняемых работ при техническом обслуживании и ремонте ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Только проектной документацией',
          'Только технологическим регламентом на эксплуатацию ОПО МАП',
          'Только нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию и результатами контроля технического состояния',
          'Всеми перечисленными документами',
        ],
        correctAnswers: ['Всеми перечисленными документами'],
      },
      {
        code: '63656046',
        text:
          'Какой должна быть минимальная глубина заложения магистрального трубопровода на болотах или торфяных грунтах, подлежащих осушению?',
        answers: ['1,2 м', '1,4 м', '1,7 м', '1,0 м'],
        correctAnswers: ['1,7 м'],
      },
      {
        code: '63656047',
        text:
          'Каким образом следует предусматривать пересечения магистральных аммиакопроводов (МАП) с другими трубопроводами и кабелями?',
        answers: [
          'Ниже этих трубопроводов и кабелей',
          'Выше этих трубопроводов и кабелей',
          'Пересечение МАП с другими трубопроводами и кабелями не допускается',
          'Способ пересечения МАП с другими трубопроводами и кабелями определяется заказчиком проектной документации',
        ],
        correctAnswers: ['Ниже этих трубопроводов и кабелей'],
      },
      {
        code: '63656048',
        text:
          'Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?',
        answers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
          'Проверку знания теоретических основ сварки',
        ],
        correctAnswers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
        ],
      },
      {
        code: '63656049',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица ответственные за подготовку к ремонтным работам',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63656050',
        text:
          'На каком максимальном расстоянии от оси аммиакопровода расположены населенные пункты, жители которых должны быть ознакомлены с опасными свойствами аммиака и простейшими методами защиты от него?',
        answers: ['До 1 км', 'До 2 км', 'До 2,5 км', 'До 3,5 км'],
        correctAnswers: ['До 2,5 км'],
      },
      {
        code: '63656051',
        text:
          'На кого возлагается планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО магистральных аммиакопроводов МАП?',
        answers: [
          'На территориальный орган Ростехнадзора',
          'На подразделение аварийно-спасательного формирования, с которым у эксплуатирующей ОПО МАП организации заключен договор',
          'На специализированную стороннюю организацию',
          'На эксплуатирующую организацию',
        ],
        correctAnswers: ['На эксплуатирующую организацию'],
      },
      {
        code: '63656052',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты «Не включать: работают люди!»',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63656053',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения представителя газоспасательной службы, и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом исключающим возможность искрообразования',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63656054',
        text:
          'Где устанавливаются требования к обозначению мест пересечений с водными объектами, железными и автомобильными дорогами?',
        answers: [
          'В проектной документации, разработанной для конкретного ОПО МАП',
          'В инструкциях, разработанных организацией, эксплуатирующей ОПО МАП',
          'В нормативных документах, регламентирующих эксплуатацию ОПО МАП',
        ],
        correctAnswers: [
          'В инструкциях, разработанных организацией, эксплуатирующей ОПО МАП',
        ],
      },
      {
        code: '63656055',
        text:
          'Какие действия должны быть организованы на всех этапах выполнения работ по строительству, реконструкции, техническому перевооружению и капитальному ремонту ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Только контроль качества выполнения работ',
          'Только контроль качества выполнения всех технологических операций',
          'Только входной контроль конструкций, изделий, материалов, оборудования и технических устройств',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63656056',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц',
          'Главный инженер организации и работник службы охраны труда',
          'Главный механик и работник службы охраны труда',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц',
        ],
      },
      {
        code: '63656057',
        text:
          'В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?',
        answers: [
          'Производителя сварочного оборудования',
          'Руководителя организации',
          'Руководителя независимого аттестационного центра',
          'Инспектора Ростехнадзора',
        ],
        correctAnswers: ['Производителя сварочного оборудования'],
      },
      {
        code: '63656058',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С газоспасательной службой и службой охраны труда',
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63656059',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63656060',
        text:
          'В течение какого времени после внесения изменений в системы управления технологическими процессами на объекте должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее одного года',
          'Не позднее полугода',
          'Не позднее трех месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63656061',
        text:
          'В каких местах допускается размещение промежуточных насосных станций ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'В местах расположения гидротехнических сооружений',
          'Перед переходами через реки, каналы и водоемы, используемые для хозяйственно-питьевого водоснабжения',
          'В местах расположения мостов с движением транспорта',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63656062',
        text:
          'Какие наиболее опасные сценарии аварий, при которых возможны максимальные размеры зоны воздействия (поражения), определяются для прогнозирования наиболее масштабного химического заражения на ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Только метеорологические условия: класс устойчивости атмосферы - F, скорость ветра на высоте 10 м - 1 м/с',
          'Только сценарий с полным разрушением емкости (технологической, складской, транспортной и иных), содержащей опасные вещества (ОВ) в максимальном количестве',
          'Только сценарий "гильотинного" разрыва трубопровода с максимальным расходом при максимальной длительности выброса',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63656063',
        text:
          'С какой периодичностью осуществляется проверка продольного профиля линейной части магистральных аммиакопроводов (МАП)?',
        answers: [
          'Один раз в три года',
          'Один раз в год',
          'Один раз в два года',
          'При проведении плановых обследований в соответствии с проектной документацией (документацией)',
        ],
        correctAnswers: [
          'При проведении плановых обследований в соответствии с проектной документацией (документацией)',
        ],
      },
      {
        code: '63656064',
        text:
          'Какие из перечисленных видов контроля не осуществляются при подготовке и выполнении сварочных работ?',
        answers: [
          'Входной контроль',
          'Операционный контроль',
          'Приемочный контроль',
          'Эксплуатационный контроль',
        ],
        correctAnswers: ['Эксплуатационный контроль'],
      },
      {
        code: '63656065',
        text:
          'Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?',
        answers: [
          'Руководитель независимого аттестационного центра',
          'Инспектор Ростехнадзора',
          'Технический руководитель организации',
          'Руководитель организации',
        ],
        correctAnswers: ['Руководитель независимого аттестационного центра'],
      },
      {
        code: '63656066',
        text:
          'Какое из указанных видов обследований площадочных объектов ОПО магистральных аммиакопроводов (МАП) указано неверно?',
        answers: [
          'Полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)',
          'Оценка фактического состояния строительных конструкций',
          'Наружное обследование в режиме эксплуатации',
        ],
        correctAnswers: [
          'Оценка фактического состояния строительных конструкций',
        ],
      },
      {
        code: '63656067',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Должностным лицом территориального управления Ростехнадзора',
          'Главными техническими специалистами организаций, эксплуатирующих объекты',
          'Специалистом военизированной газоспасательной службы',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63656068',
        text:
          'Выполнение каких действий по выводу ОПО магистральных аммиакопроводов (МАП) из консервации и ввода в эксплуатацию не предусмотрены Правилами безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Получение разрешения Ростехнадзора',
          'Разработка рабочей программы с указанием перечня работ, порядка и сроков их выполнения',
          'Ревизия, проверка, опробование и испытание трубопроводов и оборудования',
          'Составление акта о вводе объекта в действие',
        ],
        correctAnswers: ['Получение разрешения Ростехнадзора'],
      },
      {
        code: '63656069',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 10% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63656070',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите 2 правильных варианта ответа.',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'В случае назначения нового руководителя организации',
          'Не позднее 15 календарных дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63656071',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист, обученный по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63656072',
        text:
          'Какие данные должна иметь запорная арматура на трубопроводе транспортирования жидкого аммиака?',
        answers: [
          'Только указатель направления потока',
          'Только указатели "Открыто" и "Закрыто"',
          'Только обозначение присвоенного ей номера',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63656073',
        text:
          'В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке',
          'На каждый объект должен быть разработан свой план в любом случае',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63656074',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главный инженер эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63656075',
        text:
          'Какое из перечисленных требований промышленной безопасности при эксплуатации объектов линейной части магистральных аммиакопроводов (МАП) указано неверно?',
        answers: [
          'Наземные сооружения и выступающие из земли элементы МАП должны быть ограждены',
          'Каждому участку трассы МАП, посту секционирования, насосной и раздаточной станции ОПО МАП в соответствии с проектной документацией (документацией) присваиваются номера',
          'Дренажи на постах секционирования и обратных клапанах должны иметь контакт с грунтом',
          'В соответствии с проектной документацией (документацией) линейная часть МАП должна быть разделена на участки постами секционирования',
        ],
        correctAnswers: [
          'Дренажи на постах секционирования и обратных клапанах должны иметь контакт с грунтом',
        ],
      },
      {
        code: '63656076',
        text:
          'Что из перечисленного должно контролироваться в процессе сварки, как минимум?',
        answers: [
          'Только соответствие параметров режима сварки и технологических приемов выполнения сварного соединения',
          'Только очередность выполнения сварных швов и участков наплавки',
          'Только отсутствие видимых дефектов',
          'Только контроль параметров, предусмотренных в технологических (операционных) картах сварки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63656077',
        text:
          'На основании каких данных определяют величину разрешенного (допустимого) рабочего давления?',
        answers: [
          'На основании решения эксплуатирующей организации, оформленного протоколом',
          'На основании результатов технического диагностирования и в соответствии с нормативно-технической документацией по эксплуатации опасных производственных объектов магистральных трубопроводов',
          'На основании решения Ростехнадзора',
        ],
        correctAnswers: [
          'На основании результатов технического диагностирования и в соответствии с нормативно-технической документацией по эксплуатации опасных производственных объектов магистральных трубопроводов',
        ],
      },
      {
        code: '63656078',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '2 года', '1 год', '3 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63656079',
        text:
          'Где должен храниться формуляр (паспорт), на основании которого была установлена величина разрешенного (допустимого) рабочего давления?',
        answers: [
          'В архиве эксплуатирующей организации',
          'В архиве проектной организации',
          'В архиве организации, выполнявшей строительство ОПО',
        ],
        correctAnswers: ['В архиве эксплуатирующей организации'],
      },
      {
        code: '63656080',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Правилами пожарной безопасности',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Организационно-распорядительными документами руководителя',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63656081',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главным инженером эксплуатирующей организации',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63656082',
        text:
          'Какие виды работ могут проводиться в охранных зонах трубопроводов без получения разрешения от предприятия трубопроводного транспорта?',
        answers: [
          'Строительные работы не ближе 100 м от оси трубопровода в каждую сторону',
          'Ремонтно-восстановительные и полевые сельскохозяйственные работы',
          'Размещение автотранспорта на специально подготовленной площадке',
          'Оборудование временного полевого стана',
        ],
        correctAnswers: [
          'Ремонтно-восстановительные и полевые сельскохозяйственные работы',
        ],
      },
      {
        code: '63656083',
        text:
          'Что обязано проверить и обеспечить лицо, осуществляющее руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только соответствие основных и сварочных материалов, применяемой технологии сварки требованиям ПТД',
          'Только соответствие численного состава и квалификации персонала сварочного производства',
          'Только соответствие сборочного и сварочного оборудования, применяемой технологии сварки требованиям ПТД',
          'Выполнение всех подготовительных мероприятий',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63656084',
        text:
          'Какие требования промышленной безопасности при проектировании площадочных сооружений ОПО магистральных аммиакопроводов (МАП) указаны верно?',
        answers: [
          'Насосные станции перекачки аммиака должны иметь безопасные системы сброса аммиака с предклапанных, дренажных и продувочных линий, обоснованные в проектной документации (документации)',
          'Проектной документацией/документацией для насосных и газоперекачивающих агрегатов насосных и компрессорных станций должны быть предусмотрены технические решения, учитывающие компенсацию температурных, динамических и вибрационных нагрузок',
          'Для контроля загазованности воздушной среды во взрывоопасных зонах производственных помещений, а также на открытых площадках сливо-наливных эстакад, на открытых площадках стендеров причальных сооружений должны быть предусмотрены средства автоматического дистанционного непрерывного газового контроля с сигнализацией, срабатывающей при достижении предельно допустимых величин и выдающей сигналы в систему управления соответствующим технологическим процессом, реализующую соответствующие противоаварийные автоматические защиты',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63656085',
        text:
          'Какие металлы допускается использовать во всех конструктивных элементах магистральных аммиакопроводов (МАП), работающих в присутствии жидкого аммиака?',
        answers: ['Цинк', 'Медь', 'Алюминий', 'Серебро', 'Все ответы неверны'],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63656086',
        text:
          'Работа в течение какого времени после заполнения линейных сооружений ОПО магистральных аммиакопроводов (МАП) жидким аммиаком считается комплексным опробованием линейного сооружения ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В течение 96 ч',
          'В течение 72 ч',
          'В течение 48 ч',
          'В течение 120 ч',
        ],
        correctAnswers: ['В течение 72 ч'],
      },
      {
        code: '63656087',
        text:
          'Какие требования к эксплуатирующей организации по предупреждению и ликвидации аварий на ОПО магистральных аммиакопроводов (МАП) указаны неверно?',
        answers: [
          'Планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО МАП возлагается на эксплуатирующую организацию',
          'В эксплуатационных подразделениях должны быть созданы и укомплектованы службы аварийно-восстановительных бригад и нештатные аварийно-спасательные формирования',
          'Разработка организационных и технических мероприятий для безопасной эксплуатации ОПО МАП и подготовка персонала к действиям по локализации и ликвидации последствий аварий возлагается на эксплуатирующую организацию',
          'Все перечисленное указано верно',
        ],
        correctAnswers: ['Все перечисленное указано верно'],
      },
      {
        code: '63656088',
        text:
          'В каком случае ремонтные работы на ОПО магистральных трубопроводов (МТ) проводят в средствах индивидуальной защиты органов дыхания?',
        answers: [
          'При осуществлении ремонтных работ одним рабочим',
          'При превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта',
          'При скорости ветра более 10 м/с',
          'При срабатывании системы автоматической сигнализации',
        ],
        correctAnswers: [
          'При превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта',
        ],
      },
      {
        code: '63656089',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Только протоколы испытаний сварных соединений',
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
          'Только журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63656090',
        text:
          'На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?',
        answers: [
          'На 3 группы, в зависимости от степени риска проводимых работ',
          'На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве',
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
        correctAnswers: [
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
      },
      {
        code: '63656091',
        text:
          'Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?',
        answers: [
          'Только регистрацию результатов качества сварных соединений, включая результаты контроля исправлений дефектов сварных соединений',
          'Только идентификацию применяемых сварочных материалов и сварочного оборудования',
          'Только регистрацию сведений о сварщиках, выполняющих сварные соединения',
          'Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63656092',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Не моложе 18 лет',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Прошедшие обучение приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63656093',
        text:
          'Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63656094',
        text:
          'Наличие каких из указанных разделов технологического регламента на эксплуатацию ОПО магистральных аммиакопроводов (МАП) не предусмотрено Правилами безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Анализ опасности ОПО МАП',
          'Технические характеристики ОПО МАП, оборудования площадочных объектов и свойства перекачиваемых продуктов',
          'Порядок приема, сдачи и учета перекачиваемых продуктов',
          'Паспортные характеристики технических устройств, применяемых на ОПО МАП',
        ],
        correctAnswers: ['Анализ опасности ОПО МАП'],
      },
      {
        code: '63656095',
        text:
          'К какому устройству ОПО магистральных трубопроводов (МТ) и ОПО магистральных аммиакопроводов (МАП) допускается несанкционированный доступ в аварийной ситуации?',
        answers: [
          'К предохранительной арматуре',
          'К регулирующей арматуре',
          'К запорной арматуре',
          'К автоматизированным системам управления технологическими процессами',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63656096',
        text:
          'Каким требованиям должны соответствовать средства индивидуальной защиты персонала?',
        answers: [
          'Требованиям технического регламента "О безопасности средств индивидуальной защиты"',
          'Требованиям трудового законодательства',
          'Требованиям Правил безопасности для опасных производственных объектов магистральных трубопроводов',
          'Требованиям, установленным службой производственного контроля организации',
        ],
        correctAnswers: [
          'Требованиям технического регламента "О безопасности средств индивидуальной защиты"',
        ],
      },
      {
        code: '63656097',
        text:
          'На основании каких документов могут проводиться мероприятия по консервации и ликвидации ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'На основании документов, согласованных с Ростехнадзором',
          'На основании документации, на которую получено положительное заключение экспертизы промышленной безопасности',
          'На основании проектной документации',
        ],
        correctAnswers: [
          'На основании документации, на которую получено положительное заключение экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63656098',
        text:
          'Кто составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе ОПО магистральных трубопроводов (МТ) из консервации?',
        answers: [
          'Эксплуатирующая организация',
          'Территориальный орган Ростехнадзора',
          'Экспертная организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63656099',
        text:
          'Какие факторы следует учитывать при выборе трассы магистральных аммиакопроводов (МАП) и размещении объектов линейной части и площадочных объектов?',
        answers: [
          'Только природно-климатические особенности территории строительства, гидрогеологические свойства грунтов',
          'Только наличие близко расположенных производственных объектов и населенных пунктов, которые могут оказывать негативное влияние на безопасность ОПО МАП',
          'Только специфические свойства жидкого аммиака',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63656100',
        text:
          'Какую функцию должна обеспечивать автоматизированная система управления технологическими процессами объектов магистрального аммиакопровода?',
        answers: [
          'Только дистанционное управление шаровыми кранами постов секционирования и сигнализацию их состояния',
          'Только местное и дистанционное управление исполнительными устройствами насосных и раздаточных станций согласно проектной документации и технологическому регламенту',
          'Только выдерживание заданных параметров перекачки жидкого аммиака по МАП согласно технологическому регламенту',
          'Все перечисленные функции',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63656101',
        text:
          'Кому обязан немедленно сообщить о повреждении трубопровода производственный персонал, выполняющий осмотр или обслуживание инженерных коммуникаций и объектов, находящихся в районе прохождения трубопровода?',
        answers: [
          'Владельцам этих объектов',
          'Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода',
          'Аварийно-восстановительной бригаде',
          'Соответствующим органам власти и управления',
        ],
        correctAnswers: [
          'Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода',
        ],
      },
      {
        code: '63656102',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, указанные в наряде-допуске',
          'Работники подрядной организации',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63656103',
        text:
          'В каких целях проводят периодическое техническое диагностирование опасных производственных объектов магистральных трубопроводов в процессе эксплуатации?',
        answers: [
          'В целях определения фактического технического состояния опасных производственных объектов магистральных трубопроводов',
          'В целях определения возможности дальнейшей эксплуатации на проектных технологических режимах',
          'В целях расчета допустимого давления',
          'В целях обеспечения безопасности',
          'Во всех перечисленных целях',
        ],
        correctAnswers: ['Во всех перечисленных целях'],
      },
      {
        code: '63656104',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '6 месяцев со дня его окончания',
          '3 года со дня его окончания',
          '3 месяца со дня его окончания',
          '1 год со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63656105',
        text:
          'В какой срок должны сообщать предприятия, эксплуатирующие инженерные коммуникации, проходящие в техническом коридоре, всем заинтересованным предприятиям вносимые изменения в схему объектов с точным указанием их взаиморасположения?',
        answers: [
          'В месячный срок',
          'В десятидневный срок',
          'В трехдневный срок',
          'В недельный срок',
        ],
        correctAnswers: ['В месячный срок'],
      },
      {
        code: '63656106',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          '5 лет',
          '3 года',
          'Наибольший срок, предусмотренный для этих объектов',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63656107',
        text:
          'Насосные станции ОПО магистральных аммиакопроводов (МАП), размещенные на каком расстоянии от зданий и сооружений, должны располагаться на более низких отметках по отношению к этим объектам?',
        answers: [
          'Менее 3500 м',
          'Менее 3000 м',
          'Менее 2500 м',
          'Менее 2000 м',
        ],
        correctAnswers: ['Менее 2000 м'],
      },
      {
        code: '63656108',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск не более чем на 1 дневную смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63656109',
        text:
          'С кем согласовывается план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С Ростехнадзором',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и Ростехнадзором',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63656110',
        text:
          'Какая информация не должна быть обозначена на карте территориальных подразделений эксплуатирующей организации ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'Маршруты следования персонала и техники к объектам и участкам ОПО МАП',
          'Главный пост секционирования, обратный клапан, сателлитный пост секционирования, необслуживаемый усилительный пункт, раздаточная станция, насосная станция',
          'Населенные пункты',
          'Границы обслуживания участка МАП территориальным подразделением',
          'Линии связи',
        ],
        correctAnswers: [
          'Маршруты следования персонала и техники к объектам и участкам ОПО МАП',
        ],
      },
      {
        code: '63656111',
        text:
          'На каком расстоянии от оси аммиакопровода без письменного разрешения предприятия трубопроводного транспорта запрещается возводить любые постройки и сооружения, строить коллективные сады с жилыми домами, устраивать массовые спортивные соревнования, соревнования с участием зрителей, купания, массовый отдых людей, любительское рыболовство, расположение временных полевых жилищ и станов любого назначения, загоны для скота?',
        answers: [
          '500 м от оси трубопровода с каждой стороны',
          '700 м от оси трубопровода с каждой стороны',
          '1000 м от оси трубопровода с каждой стороны',
          '1500 м от оси трубопровода с каждой стороны',
        ],
        correctAnswers: ['1000 м от оси трубопровода с каждой стороны'],
      },
      {
        code: '63656112',
        text:
          'Допускается ли остановка работы станций электрохимической защиты от коррозии магистральных аммиакопроводов (МАП)? Если да, то в каких целях и в каком порядке?',
        answers: [
          'Допускается для проведения ремонтов в порядке, установленном инструкциями',
          'Не допускается, система электрохимической защиты должна работать непрерывно',
          'Допускается в порядке, установленном Правилами безопасности для опасных производственных объектов магистральных трубопроводов',
          'Допускается по согласованию с территориальными органами Ростехнадзора',
        ],
        correctAnswers: [
          'Допускается для проведения ремонтов в порядке, установленном инструкциями',
        ],
      },
      {
        code: '63656113',
        text:
          'Что не требуется в обязательном порядке предусматривать на отдельно стоящих раздаточных станциях, кроме технологического оборудования, позволяющего вести процесс выдачи жидкого аммиака, в целях обеспечения безопасности технологического процесса?',
        answers: [
          'Аварийный душ и фонтанчик для промывания глаз',
          'Факельную установку',
          'Дренажную емкость для приема дренажей',
          'Систему автоматического газового пожаротушения',
        ],
        correctAnswers: ['Систему автоматического газового пожаротушения'],
      },
      {
        code: '63656114',
        text:
          'Что перед началом сварочных работ руководитель сварочных работ обязан проверить?',
        answers: [
          'Только исправность сварочного оборудования',
          'Только наличие на месте сварки огнетушителя',
          'Выполнение всех подготовительных мероприятий',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63656115',
        text:
          'Какая из перечисленных документаций должна храниться в эксплуатирующей ОПО магистральных аммиакопроводов (МАП) организации?',
        answers: [
          'Только эксплуатационная и проектная',
          'Только исполнительная',
          'Только проектная',
          'Вся перечисленная',
        ],
        correctAnswers: ['Вся перечисленная'],
      },
      {
        code: '63656116',
        text:
          'В какую документацию должны быть своевременно внесены все конструктивные изменения линейных сооружений ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В эксплуатационную и исполнительную документацию',
          'Только в исполнительную документацию',
          'Только в эксплуатационную документацию',
          'В проектную документацию',
        ],
        correctAnswers: ['В эксплуатационную и исполнительную документацию'],
      },
      {
        code: '63656117',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '2 года', '1 год', '3 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63656118',
        text:
          'Какие требования предъявляются федеральными нормами и правилами в области промышленной безопасности "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны быть экономичными в использовании, простыми в ремонте',
          'Оборудование и материалы должны обеспечивать максимальную производительность работ',
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63656119',
        text:
          'На основании чего составляется график ремонта (включая капитальный ремонт) опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Результатов технического обследования',
          'Проведения экспертизы промышленной безопасности',
          'Проведения неразрушающего контроля',
        ],
        correctAnswers: ['Результатов технического обследования'],
      },
      {
        code: '63656120',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
          'Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III и прошедшие обучение по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63656121',
        text:
          'Что не относится к основным этапам процесса проведения количественного анализа риска аварии на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Количественная оценка риска аварий на ОПО МТ',
          'Идентификация опасностей аварий',
          'Разработка рекомендаций по снижению риска аварий',
          'Оценка риска методом НАZOP',
        ],
        correctAnswers: ['Оценка риска методом НАZOP'],
      },
      {
        code: '63656122',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Для всех ОПО без исключения',
          'Только для ОПО I и II классов опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63656123',
        text:
          'Какая минимальная длина труб и кабеля связи в аварийном запасе должна быть предусмотрена эксплуатирующей организацией?',
        answers: [
          '0,1% от протяженности трубопроводной части и кабеля',
          '0,2% от протяженности трубопроводной части и кабеля',
          '0,3% от протяженности трубопроводной части и кабеля',
          '0,5% от протяженности трубопроводной части и кабеля',
        ],
        correctAnswers: ['0,2% от протяженности трубопроводной части и кабеля'],
      },
      {
        code: '63656124',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Должны быть поставлены клейма всех сварщиков, участвовавших в сварке',
          'Допускается применение клейма сварщика, выполнившего наибольший объем работ',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63656125',
        text:
          'Какой должна быть минимальная глубина заложения магистрального трубопровода при переходах судоходных рек, каналов и других водных препятствий от отметки дна, не подверженного переформированию, до верха магистральных аммиакопроводов (МАП)?',
        answers: ['1,2 м', '1,4 м', '1,0 м', '0,8 м'],
        correctAnswers: ['1,4 м'],
      },
      {
        code: '63656126',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63656127',
        text:
          'Какой документ подтверждает величину разрешенного (допустимого) рабочего давления на основании результатов технического диагностирования ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Паспорт технического устройства (отметка в паспорте)',
          'Формуляр подтверждения величины разрешенного (допустимого) рабочего давления',
          'Заключение экспертизы промышленной безопасности',
          'Свидетельство по итогам технического диагностирования',
        ],
        correctAnswers: [
          'Формуляр подтверждения величины разрешенного (допустимого) рабочего давления',
        ],
      },
      {
        code: '63656128',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
          'До окончания работ на конкретном месте',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63656129',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63656130',
        text:
          'На каких участках должна быть предусмотрена система дренажной защиты?',
        answers: [
          'На всех участках распространения блуждающих токов',
          'На всех участках, где после половодья возможно скопление паводковых вод',
          'На всех заболоченных участках вдоль трассы аммиакопровода',
        ],
        correctAnswers: ['На всех участках распространения блуждающих токов'],
      },
      {
        code: '63656131',
        text:
          'Кем должны быть разработаны и утверждены схемы и карты трассы магистральных аммиакопроводов (МАП), маршрутные карты с обозначением кратчайших путей подъездов к основным объектам трассы в эксплуатационных подразделениях МАП?',
        answers: [
          'Техническим руководителем организации',
          'Руководителем, ответственным за проведение работ по локализации и ликвидации последствий аварий',
          'Руководителем организации',
          'Специально утвержденной комиссией эксплуатирующей организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63656132',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63656133',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63656134',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
        ],
      },
      {
        code: '63656135',
        text:
          'К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?',
        answers: [
          'К выполнению сварочных работ, указанных в действующих документах',
          'К любым сварочным работам при наличии действующих аттестационных удостоверений по какому-либо способу сварки',
          'К сварочным работам, на которые они когда-либо были аттестованы независимо от того, истек ли срок действия соответствующих аттестационных удостоверений',
        ],
        correctAnswers: [
          'К выполнению сварочных работ, указанных в действующих документах',
        ],
      },
      {
        code: '63656136',
        text:
          'Кто должен устанавливать продолжительность периода, на который ОПО магистральных аммиакопроводов (МАП) выводят из эксплуатации?',
        answers: [
          'Проектная организация',
          'Территориальный орган Ростехнадзора',
          'Эксплуатирующая организация',
          'Организация, проводящая экспертизу промышленной безопасности опасных производственных объектов',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63656137',
        text:
          'Какие сведения может не содержать формуляр (паспорт) подтверждения величины разрешенного (допустимого) рабочего давления на ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'О величине разрешенного давления',
          'О сроках проведения следующего технического диагностирования',
          'О необходимости обеспечения ОПО МТ предохранительными устройствами для ограничения величины (допустимого) рабочего давления',
          'Об участке (номере участка) ОПО МТ',
        ],
        correctAnswers: [
          'О сроках проведения следующего технического диагностирования',
        ],
      },
      {
        code: '63656138',
        text:
          'На основе чего осуществляется определение нагрузок и воздействий, возникающих на этапах строительства, эксплуатации, реконструкции, при техническом перевооружении, капитальном ремонте, консервации, ликвидации ОПО магистральных аммиакопроводов (МАП)?',
        answers: [
          'На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации',
          'На основе результатов анализа опасностей технологических процессов и анализа риска аварий',
          'На основе соответствующих нормативных документов',
          'На основе предписания территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации',
        ],
      },
      {
        code: '63656139',
        text: 'Что должно быть указано в технологических картах сварки?',
        answers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
          'Требования к сварочным материалам и сварочному оборудованию, режимы сварки, последовательность операций, технические приемы контроля качества сварных соединений',
          'Требования к применяемым сварочным технологиям, последовательность операций, технические приемы, особенности процесса сварки, обеспечивающие качество сварных соединений',
        ],
        correctAnswers: [
          'Конструктивные элементы сварных соединений, режимы сварки, последовательность операций, технические и технологические особенности процесса сварки, методы и объемы контроля, обеспечивающие качество сварных соединений',
        ],
      },
      {
        code: '63656140',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63656141',
        text:
          'Какой документ, определяющий порядок организации надежного и безопасного ведения технологического процесса, разрабатывается после ввода в эксплуатацию опасных производственных объектов (далее - ОПО) магистральных аммиакопроводов (МАП)?',
        answers: [
          'Технологический регламент на эксплуатацию',
          'Паспорт безопасности',
          'Заключение экспертизы промышленной безопасности',
          'Экологический паспорт',
        ],
        correctAnswers: ['Технологический регламент на эксплуатацию'],
      },
      {
        code: '63656142',
        text:
          'Каким способом следует предусматривать прокладку магистрального трубопровода (МТ) через крупные глубоководные, судоходные реки, водохранилища, при сложных грунтовых условиях дна пересекаемых водных преград, на мостовых переходах и пересечениях подрабатываемых территорий?',
        answers: [
          'Способом "труба в трубе"',
          'Способом прокола',
          'Способом продавливания',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Способом "труба в трубе"'],
      },
      {
        code: '63656143',
        text:
          'На основании какой документации определяется возможность очистки полости трубопроводов после строительства, реконструкции, технического перевооружения и капитального ремонта ОПО магистральных аммиакопроводов (МАП)? Выберите 2 правильных варианта ответа.',
        answers: [
          'Технологического регламента',
          'Паспорта безопасности',
          'Проектной документации (документации)',
          'Заключения экспертизы промышленной безопасности',
          'Эксплуатационной документации',
        ],
        correctAnswers: [
          'Технологического регламента',
          'Проектной документации (документации)',
        ],
      },
      {
        code: '63656144',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63656145',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Разработать проект производства работ',
          'Составить план подготовительных работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
          'Организовать изготовление необходимых узлов и деталей для замены',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63656146',
        text: 'В чем заключается основная задача анализа риска?',
        answers: [
          'В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках опасных производственных объектов магистральных трубопроводов',
          'В определении сумм потенциального ущерба в случае возникновения аварии на опасных производственных объектах',
          'В информировании федерального органа исполнительной власти в области промышленной безопасности о существующих рисках на опасных производственных объектах',
          'В информировании населения о существующих рисках на опасных производственных объектах',
        ],
        correctAnswers: [
          'В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках опасных производственных объектов магистральных трубопроводов',
        ],
      },
      {
        code: '63656147',
        text:
          'В каких документах определен порядок и последовательность ликвидации разлива жидкого аммиака? Выберите 2 правильных варианта ответа.',
        answers: [
          'В положении о производственном контроле',
          'В проектной документации',
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'В технологическом регламенте',
        ],
        correctAnswers: [
          'В плане мероприятий по локализации и ликвидации последствий аварий',
          'В технологическом регламенте',
        ],
      },
      {
        code: '63656148',
        text:
          'Кто утверждает паспорта линейных участков, подводных и мостовых переходов аммиакопровода?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Технический руководитель территориального подразделения МАП',
          'Главный инженер эксплуатирующей организации',
          'Руководитель технологической службы (службы эксплуатации) управления',
        ],
        correctAnswers: [
          'Технический руководитель территориального подразделения МАП',
        ],
      },
      {
        code: '63656149',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63656150',
        text:
          'В каком случае допускается выброс жидкого аммиака в окружающую среду при освобождении трубопроводов перед началом осуществления работ по выводу из эксплуатации ОПО магистральных аммиакопроводов (МАП), подлежащих ликвидации?',
        answers: [
          'В случае письменного согласования с Росприроднадзором',
          'В случае письменного согласования с Роснедрами',
          'В случае установления данного требования Ростехнадзором',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63656151',
        text:
          'Какие требования, предусмотренные Правилами безопасности для опасных производственных объектов магистральных трубопроводов к секциям магистральных трубопроводов, указаны верно? Выберите 2 правильных варианта ответа.',
        answers: [
          'Длина каждой секции должна быть не более 5 км при условном диаметре трубопровода до 450 мм включительно',
          'Длина каждой секции должна быть не более 10 км при условном диаметре трубопровода до 350 мм включительно',
          'Длина каждой секции должна быть не более 15 км при условном диаметре трубопровода до 350 мм включительно',
          'Длина каждой секции должна быть не более 10 км при условном диаметре трубопровода до 500 мм включительно',
        ],
        correctAnswers: [
          'Длина каждой секции должна быть не более 15 км при условном диаметре трубопровода до 350 мм включительно',
          'Длина каждой секции должна быть не более 10 км при условном диаметре трубопровода до 500 мм включительно',
        ],
      },
      {
        code: '63656152',
        text:
          'Кто утверждает перечень аварийного запаса средств индивидуальной защиты?',
        answers: [
          'Технический руководитель организации',
          'Руководитель подразделения аварийно-спасательного формирования, с которым согласован план мероприятий по локализации и ликвидации последствий аварии на ОПО МАП',
          'Руководитель организации',
          'Специалист по промышленной безопасности',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63656153',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии главного инженера и начальника службы охраны труда',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63656154',
        text: 'Что из перечисленного обозначает личные шифры клейм сварщиков?',
        answers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий шестизначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и меняющийся при последующих аттестациях',
        ],
        correctAnswers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
      },
    ],
    63648: [
      {
        code: '63648000',
        text:
          'Кому выдается наряд на выполнение работ на горных выработках нефтяной шахты, определенных организационно-распорядительным документом обособленного структурного подразделения?',
        answers: [
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 6 месяцев',
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 4 месяцев',
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 3 месяцев',
        ],
        correctAnswers: [
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 6 месяцев',
        ],
      },
      {
        code: '63648001',
        text:
          'Кто осуществляет ежегодный контроль за состоянием устьев ликвидированных скважин?',
        answers: [
          'Пользователь недр',
          'Территориальные органы Росприроднадзора',
          'Минприроды России',
        ],
        correctAnswers: ['Пользователь недр'],
      },
      {
        code: '63648002',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены на опасных производственных объектах III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63648003',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '3 года', '5 лет', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63648004',
        text:
          'Какие способы используются для ликвидации гидратных пробок в газопроводах, арматуре, оборудовании и приборах УКПГ?',
        answers: [
          'Введение ингибиторов гидратообразования, теплоносителей (пара, горячей воды), понижение давления в системе',
          'Введение специальных добавок, повышение давления в системе',
          'Введение поверхностно-активных веществ, понижение давления в системе',
          'Введение окислителей, повышение давления в системе',
        ],
        correctAnswers: [
          'Введение ингибиторов гидратообразования, теплоносителей (пара, горячей воды), понижение давления в системе',
        ],
      },
      {
        code: '63648005',
        text:
          'Какие меры безопасности должны быть предусмотрены для насосов (группы насосов), перекачивающих горючие продукты?',
        answers: [
          'Ручное управление и установка на линии нагнетания насоса предохранительного клапана',
          'Дистанционное отключение и установка на линиях входа и нагнетания запорных или отсекающих устройств',
          'Дистанционное отключение и установка на линии нагнетания предохранительного клапана',
          'Ручное управление и установка на линиях входа и нагнетания обратных клапанов',
        ],
        correctAnswers: [
          'Дистанционное отключение и установка на линиях входа и нагнетания запорных или отсекающих устройств',
        ],
      },
      {
        code: '63648006',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          'Наибольший срок, предусмотренный для этих объектов',
          '5 лет',
          '3 года',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63648007',
        text:
          'В каких случаях допускается оборудование устья ликвидированных скважин без установки тумбы?',
        answers: [
          'При нахождении скважины на сельскохозяйственных землях',
          'Ни в каких случаях',
          'При нахождении скважины на территории подземного газового хранилища',
        ],
        correctAnswers: [
          'При нахождении скважины на территории подземного газового хранилища',
        ],
      },
      {
        code: '63648008',
        text:
          'В каких случаях персонал должен быть обеспечен необходимыми средствами индивидуальной защиты?',
        answers: [
          'При наличии в продукции, технологических аппаратах, резервуарах и других емкостях сероводорода или возможности образования вредных веществ при пожарах, взрывах, нарушении герметичности емкостей и других аварийных ситуациях',
          'При обнаружении вредных веществ и примесей в продукции',
          'При возможном образовании вредных веществ при смешении продукции',
          'При частом срабатывании датчиков загазованности',
        ],
        correctAnswers: [
          'При наличии в продукции, технологических аппаратах, резервуарах и других емкостях сероводорода или возможности образования вредных веществ при пожарах, взрывах, нарушении герметичности емкостей и других аварийных ситуациях',
        ],
      },
      {
        code: '63648009',
        text:
          'Где следует размещать силовой кабель электронасоса при свинчивании и развинчивании труб?',
        answers: [
          'Внутри мачты агрегата для ремонта',
          'За пределами рабочей зоны',
          'Силовой кабель следует крепить к оттяжкам агрегата',
          'В пределах рабочей зоны',
        ],
        correctAnswers: ['За пределами рабочей зоны'],
      },
      {
        code: '63648010',
        text:
          'Какими светильниками должны быть обеспечены опасные производственные объекты нефтегазодобывающих производств?',
        answers: [
          'Стационарными светильниками напряжением 12 В во взрывозащищенном исполнении',
          'Стационарными светильниками напряжением 36 В во взрывозащищенном исполнении',
          'Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В, - в особо опасных помещениях, а в наружных установках - не выше 12 В',
          'Переносными светильниками напряжением 24 В во взрывозащищенном исполнении',
        ],
        correctAnswers: [
          'Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В, - в особо опасных помещениях, а в наружных установках - не выше 12 В',
        ],
      },
      {
        code: '63648011',
        text: 'Каким образом следует поступать с остатками химреагентов?',
        answers: [
          'Собирать и доставлять в специально отведенное место, оборудованное для утилизации или уничтожения',
          'Утилизировать на месте производства работ при наличии нейтрализующих веществ',
          'Помещать в специальные могильники на глубину не менее 3 м, но выше уровня грунтовых вод',
          'Любым из перечисленных способов',
        ],
        correctAnswers: [
          'Собирать и доставлять в специально отведенное место, оборудованное для утилизации или уничтожения',
        ],
      },
      {
        code: '63648012',
        text:
          'На каком расстоянии от скважины или участка нагнетательного трубопровода запрещается находиться при их продувке?',
        answers: ['Ближе 35 м', 'Ближе 30 м', 'Ближе 25 м', 'Ближе 20 м'],
        correctAnswers: ['Ближе 20 м'],
      },
      {
        code: '63648013',
        text:
          'Чем должны оснащаться воздухопроводы при работе нескольких компрессоров в общую сеть?',
        answers: [
          'Запорной арматурой',
          'Обратным клапаном и отсекающей задвижкой или вентилем',
          'Обратным и предохранительным клапанами',
          'Только отсекающей задвижкой',
          'Только обратным клапаном',
        ],
        correctAnswers: [
          'Обратным клапаном и отсекающей задвижкой или вентилем',
        ],
      },
      {
        code: '63648014',
        text:
          'Как часто следует производить осмотр всех внутриплощадочных технологических трубопроводов, сепараторов, емкостей, запорно-регулирующей арматуры в процессе работы компрессорной станции газлифтной системы?',
        answers: ['Ежемесячно', 'Ежесменно', 'Еженедельно', 'Ежедекадно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63648015',
        text:
          'Кем утверждается проект и план перевода скважины на газлифтную эксплуатацию?',
        answers: [
          'Руководителем проектной организации',
          'Руководителем организации',
          'Инспектором Ростехнадзора',
          'Мастером добычи участка',
          'Техническим руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63648016',
        text:
          'Сколько человек может работать в замкнутом пространстве одновременно?',
        answers: ['Два', 'Три', 'Один'],
        correctAnswers: ['Один'],
      },
      {
        code: '63648017',
        text:
          'Какое управление подачей инертных сред в технологические установки должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Автоматическое',
          'Ручное управление по месту',
          'Дистанционное, неавтоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63648018',
        text:
          'В каком из перечисленных случаев план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах должен быть пересмотрен?',
        answers: [
          'Не позднее 30 рабочих дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 45 рабочих дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 45 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
        ],
      },
      {
        code: '63648019',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатываются планы мероприятий для каждого объекта',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 50 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько - для объектов на смежных участках',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63648020',
        text:
          'Какие требования предъявляются к техническим устройствам, которые вводятся в эксплуатацию на опасных производственных объектах (далее - ОПО) после капитального ремонта, связанного с конструктивными изменениями?',
        answers: [
          'Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с разработчиком этого оборудования',
          'Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с надзорными органами',
          'Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации',
        ],
      },
      {
        code: '63648021',
        text:
          'Куда должен отводиться сбрасываемый продукт при освобождении и продувке насосов?',
        answers: [
          'За пределы помещений: жидкий - по трубопроводам в дренажную систему, а пары и газы - в сепаратор',
          'За пределы помещений: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - на факел или свечу',
          'За пределы помещений: жидкий - по трубопроводам в закрытую дренажную систему, а пары и газы - на факел или свечу',
          'В пределах помещения: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - в сепаратор',
          'За пределы помещений: жидкий - через скруббер в предназначенную емкость, а пары и газы - на факел или свечу',
        ],
        correctAnswers: [
          'За пределы помещений: жидкий - по трубопроводам в специально предназначенную емкость, а пары и газы - на факел или свечу',
        ],
      },
      {
        code: '63648022',
        text:
          'Что необходимо предпринять при ликвидации скважин с нарушенной колонной из-за аварии или коррозии эксплуатационной колонны вследствие длительных сроков эксплуатации?',
        answers: [
          'Провести исследование по определению наличия и качества цементного камня за колонной, цементирование в интервалах его отсутствия и установку цементного моста в интервале на 20 м выше и ниже части колонны, подверженной коррозии или нарушениям из-за аварии, с последующим испытанием оставшейся части колонны и моста снижением уровня или заменой на жидкость меньшей плотности. Верхняя часть ствола заполняется нейтральной незамерзающей жидкостью',
          'Провести исследование по определению наличия и качества цементного камня за колонной, цементирование в интервалах его отсутствия и установку цементного моста в интервале на 10 м выше и ниже части колонны, подверженной коррозии или нарушениям из-за аварии',
          'Выявить интервалы нарушения цементного камня за колонной и произвести установку цементных мостов во всех интервалах нарушения',
        ],
        correctAnswers: [
          'Провести исследование по определению наличия и качества цементного камня за колонной, цементирование в интервалах его отсутствия и установку цементного моста в интервале на 20 м выше и ниже части колонны, подверженной коррозии или нарушениям из-за аварии, с последующим испытанием оставшейся части колонны и моста снижением уровня или заменой на жидкость меньшей плотности. Верхняя часть ствола заполняется нейтральной незамерзающей жидкостью',
        ],
      },
      {
        code: '63648023',
        text:
          'Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?',
        answers: [
          'На пульт групповой замерной установки',
          'На диспетчерский пункт',
          'На пульт насосной станции',
          'На центральный пульт нефтегазодобывающего управления',
        ],
        correctAnswers: ['На диспетчерский пункт'],
      },
      {
        code: '63648024',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий (ПЛА)?',
        answers: [
          'Технический руководитель предприятия',
          'Руководитель службы охраны труда',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
          'Главный механик',
        ],
        correctAnswers: ['Технический руководитель предприятия'],
      },
      {
        code: '63648025',
        text:
          'Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?',
        answers: [
          'Запорной арматурой и сальниковым устройством для герметизации штока',
          'Только запорной арматурой',
          'Перфорационной задвижкой',
          'Запорной арматурой и обратным клапаном',
          'Шаровым клапаном и сальниковым устройством для герметизации штока',
        ],
        correctAnswers: [
          'Запорной арматурой и сальниковым устройством для герметизации штока',
        ],
      },
      {
        code: '63648026',
        text:
          'В каком положении должна находиться центральная задвижка при установке гирлянды порохового заряда в лубрикатор?',
        answers: [
          'В открытом',
          'В полузакрытом',
          'В закрытом',
          'Не регламентируется',
        ],
        correctAnswers: ['В закрытом'],
      },
      {
        code: '63648027',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководитель службы охраны труда',
          'Технический руководитель предприятия',
          'Главный механик',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: ['Технический руководитель предприятия'],
      },
      {
        code: '63648028',
        text:
          'Под каким давлением следует проводить продувку технологических трубопроводов, работающих под избыточным давлением свыше 0,1 МПа?',
        answers: [
          'Под давлением, равным рабочему, но не более 0,1 МПа',
          'Под давлением, равным рабочему, но не более 4 МПа',
          'Под давлением, равным рабочему, но не более 10 МПа',
          'Под давлением, равным рабочему, но не более 5 МПа',
        ],
        correctAnswers: ['Под давлением, равным рабочему, но не более 4 МПа'],
      },
      {
        code: '63648029',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность, указаны неверно?',
        answers: [
          'При расчетном давлении до 0,2 МПа осмотр проводят при давлении, равном 0,6 пробного давления (Рпр), и при рабочем давлении',
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
          'При расчетном давлении более 0,2 МПа осмотр проводят при давлении, равном 0,3 и 0,6 пробного давления (Рпр), и при рабочем давлении',
        ],
        correctAnswers: [
          'При пневматическом испытании трубопроводов на прочность необходимо плавно поднимать давление, со скоростью не более 10% от пробного давления (Рпр) в минуту, но не более 0,2 МПа в минуту',
        ],
      },
      {
        code: '63648030',
        text:
          'Какие требования предъявляются к работникам, осуществляющим непосредственное руководство и выполнение работ по бурению, освоению, ремонту, реконструкции, консервации и ликвидации скважин, ведению геофизических и прострелочно-взрывных работ (ПВР) на скважинах?',
        answers: [
          'Должны ежегодно проходить аттестацию в области промышленной безопасности',
          'Должны иметь разрешение на право руководства работами от надзорных органов',
          'Должны раз в 2 года дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП',
        ],
        correctAnswers: [
          'Должны раз в 2 года дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП',
        ],
      },
      {
        code: '63648031',
        text:
          'Какими источниками информации следует руководствоваться при разработке технологических процессов для определения регламентированных значений параметров, определяющих взрывоопасность процесса, допустимые диапазоны их измерений, критические значения параметров?',
        answers: [
          'Заданием на проектирование на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
          'Справочной литературой',
          'Нормативно-технической документацией',
          'Всеми перечисленными источниками информации',
        ],
        correctAnswers: [
          'Заданием на проектирование на основании данных о критических значениях параметров или их совокупности для участвующих в процессе веществ',
        ],
      },
      {
        code: '63648032',
        text:
          'Какие плакаты должны быть постоянно укреплены на пусковом устройстве и вблизи него на скважинах с автоматическим и дистанционным управлением станков-качалок?',
        answers: [
          '"Внимание! Пуск автоматический"',
          '"Не включать! Работают люди"',
          '"Испытание! Опасно для жизни"',
          '"Посторонним вход запрещен!"',
        ],
        correctAnswers: ['"Внимание! Пуск автоматический"'],
      },
      {
        code: '63648033',
        text:
          'Каким образом могут производиться измерения уровня нефти и нефтепродукта и отбор проб в резервуарах с избыточным давлением в газовом пространстве до 200 мм вод. ст.?',
        answers: [
          'Вручную через поршневой клапан',
          'Вручную через открытый замерный люк',
          'Вручную через шаровой клапан',
          'Вручную через дренажный кран',
        ],
        correctAnswers: ['Вручную через открытый замерный люк'],
      },
      {
        code: '63648034',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два варианта ответа.',
        answers: [
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Руководитель территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
        ],
      },
      {
        code: '63648035',
        text:
          'В зависимости от чего осуществляется выбор конструкции и конструкционных материалов, уплотнительных устройств для насосов и компрессоров?',
        answers: [
          'В зависимости от протяженности трубопровода',
          'В зависимости от свойств перемещаемой среды',
          'В зависимости от конструктивных особенностей трубопровода',
          'В зависимости от всех перечисленных факторов',
        ],
        correctAnswers: ['В зависимости от свойств перемещаемой среды'],
      },
      {
        code: '63648036',
        text:
          'Какие требования предъявляются Правилами безопасности в нефтяной и газовой промышленности к работникам, выполняющим горные работы?',
        answers: [
          'Горные работы выполняются не менее чем тремя работниками, причем стаж работы по профессии одного из них должен быть не менее 3 месяцев',
          'Горные работы выполняются не менее чем тремя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев',
          'Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев',
          'Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 12 месяцев',
        ],
        correctAnswers: [
          'Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев',
        ],
      },
      {
        code: '63648037',
        text:
          'Какие работники должны обслуживать электрическую часть электрообессоливающей установки УПН?',
        answers: [
          'Электротехнический персонал, имеющий допуск на проведение работ с электроустановками напряжением выше 1000 В',
          'Рабочий персонал, обслуживающий установку',
          'Звено старшего оператора в присутствии мастера, имеющего группу IV по электробезопасности',
          'Персонал подрядной организации',
        ],
        correctAnswers: [
          'Электротехнический персонал, имеющий допуск на проведение работ с электроустановками напряжением выше 1000 В',
        ],
      },
      {
        code: '63648038',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на необходимый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 2 рабочих смены',
          'Лицо, зарегистрировавшее наряд-допуск не более чем на 3 дневных смены',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63648039',
        text:
          'С какой периодичностью осуществляется осмотр и проверка аварийно-ремонтных и вспомогательных подъемных установок работником обособленного структурного подразделения?',
        answers: [
          'Ежедневно',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63648040',
        text:
          'Что необходимо сделать после заполнения электродегидратора продуктом перед подачей напряжения?',
        answers: [
          'Проверить герметичность соединений',
          'Удалить скопившиеся в электродегидраторе газы и пары',
          'Проверить заземляющие устройства',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Удалить скопившиеся в электродегидраторе газы и пары',
        ],
      },
      {
        code: '63648041',
        text:
          'Какие мероприятия необходимо провести при обнаружении недостатков (устьевое давление, межколонные проявления, грифоны и др.) в ходе проверок (или в других случаях) законсервированных скважин?',
        answers: [
          'Скважина должна быть ликвидирована',
          'Скважина должна быть выведена из консервации. Пользователь недр обязан выяснить причины недостатков, разработать и реализовать мероприятия по их устранению',
          'Скважина должна быть выведена из консервации и поставлена на капитальный ремонт',
        ],
        correctAnswers: [
          'Скважина должна быть выведена из консервации. Пользователь недр обязан выяснить причины недостатков, разработать и реализовать мероприятия по их устранению',
        ],
      },
      {
        code: '63648042',
        text:
          'Разрешается ли устранение неисправностей, замена быстроизнашивающихся и сменных деталей фонтанной арматуры под давлением?',
        answers: [
          'Разрешается',
          'Разрешается только в отдельных случаях при аварийных ситуациях работниками штатных или внештатных аварийно-спасательных формирований',
          'Разрешается, если давление снижено до атмосферного',
          'Разрешается при наличии приказа о проведении опасных работ в присутствии лица, ответственного за их выполнение',
          'Запрещается',
        ],
        correctAnswers: [
          'Разрешается только в отдельных случаях при аварийных ситуациях работниками штатных или внештатных аварийно-спасательных формирований',
        ],
      },
      {
        code: '63648043',
        text:
          'Какие документы должны быть на объектах сбора и подготовки нефти и газа (центральный пункт сбора (далее - ЦПС), установка подготовки нефти (далее - УПН), установка комплексной подготовки газа (далее - УКПГ), насосных и компрессорных станциях (дожимная насосная станция (далее - ДНС), компрессорная станция (далее - КС)?',
        answers: [
          'Технические паспорта и документация на оборудование насосных и компрессорных станций',
          'Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной технологической схеме',
          'Приказ о назначении лица, ответственного за проведение работ',
          'План аварийно-спасательных мероприятий',
          'Все перечисленные документы',
        ],
        correctAnswers: [
          'Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной технологической схеме',
        ],
      },
      {
        code: '63648044',
        text:
          'Какое минимальное значение величины среднего замедления подъемной установки при спуске при предохранительном торможении в случае угла наклона горной выработки до 30°?',
        answers: ['0,55 м/с²', '0,75 м/с²', '0,65 м/с²'],
        correctAnswers: ['0,75 м/с²'],
      },
      {
        code: '63648045',
        text:
          'Какой клапан должен быть установлен на напорном (нагнетательном) трубопроводе центробежного насоса?',
        answers: [
          'Обратный и предохранительный клапаны',
          'Обратный клапан',
          'Шаровой и обратный клапаны',
          'Предохранительный клапан',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63648046',
        text:
          'Что необходимо устанавливать на выкидных линиях и манифольдах скважин, работающих с температурой рабочего тела 80 °С и более?',
        answers: [
          'Демпферы',
          'Аппараты воздушного охлаждения',
          'Датчики температуры',
          'Запорную арматуру с учетом ожидаемой температуры',
          'Температурные компенсаторы',
        ],
        correctAnswers: ['Температурные компенсаторы'],
      },
      {
        code: '63648047',
        text:
          'Какие действия необходимо предпринять при аварии с колонной бурильных труб, когда ее верхняя часть осталась в интервале ствола, перекрытого технической колонной или кондуктором?',
        answers: [
          'Производятся извлечение части бурильной колонны, находящейся выше башмака технической колонны или кондуктора, цементирование под давлением с установкой цементного моста на уровне не менее 50 м над башмаком технической колонны',
          'Производятся извлечение части бурильной колонны и установка цементных мостов в интервалах залегания пресных и минерализованных вод и продуктивных горизонтов',
          'Производятся извлечение части бурильной колонны, находящейся выше башмака технической колонны или кондуктора, цементирование под давлением с установкой цементного моста на уровне не менее 100 м над башмаком технической колонны. Оставшаяся часть технической колонны заполняется глинистым раствором. Верхняя часть колонны заполняется нейтральной незамерзающей жидкостью',
        ],
        correctAnswers: [
          'Производятся извлечение части бурильной колонны, находящейся выше башмака технической колонны или кондуктора, цементирование под давлением с установкой цементного моста на уровне не менее 100 м над башмаком технической колонны. Оставшаяся часть технической колонны заполняется глинистым раствором. Верхняя часть колонны заполняется нейтральной незамерзающей жидкостью',
        ],
      },
      {
        code: '63648048',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении гидравлического испытания технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Испытание производится при положительной температуре окружающего воздуха',
          'Используемые для гидравлического испытания жидкости не должны загрязнять оборудование или вызывать коррозию, превышающую значения, предусмотренные проектом для рабочих сред',
          'Разница температур металла и окружающего воздуха во время гидравлического испытания не должна приводить к конденсации влаги на поверхности стенок оборудования',
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
        correctAnswers: [
          'В случаях, обоснованных в проекте или изготовителем (монтажной организацией), для проведения гидравлического испытания допускается использовать коррозионно-активные жидкости',
        ],
      },
      {
        code: '63648049',
        text:
          'Какие меры необходимо принять при обнаружении участков изоляции, пропитанной горючим веществом?',
        answers: [
          'Снять пропитанную изоляцию, подвести водяной пар',
          'Разместить рядом с участком огнетушитель',
          'Обтереть изоляцию ветошью',
          'Оградить и вывесить предупредительные плакаты',
        ],
        correctAnswers: ['Снять пропитанную изоляцию, подвести водяной пар'],
      },
      {
        code: '63648050',
        text:
          'Требования какого документа обеспечивают безопасность технологических процессов на объектах добычи, сбора и подготовки нефти, газа и газового конденсата?',
        answers: [
          'Производственной инструкции по эксплуатации оборудования',
          'Проектной документации на эксплуатацию опасного производственного объекта',
          'Федеральных норм и правил в области промышленной безопасности',
          'Технологического регламента (ТР) на каждый технологический процесс опасного производственного объекта',
        ],
        correctAnswers: [
          'Технологического регламента (ТР) на каждый технологический процесс опасного производственного объекта',
        ],
      },
      {
        code: '63648051',
        text:
          'Что не должна исключать энергетическая устойчивость технологической системы с учетом категории взрывоопасности входящих в нее блоков, особенностей технологического процесса?',
        answers: [
          'Возможность образования в системе взрывоопасной среды (за счет увеличения времени пребывания продуктов в реакционной зоне, нарушения соотношения поступающих в нее продуктов, развития неуправляемых процессов)',
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
          'Возможность нарушения герметичности системы (разгерметизации уплотнений подвижных соединений, разрушения оборудования от превышения давления)',
        ],
        correctAnswers: [
          'Возможность выбора рациональной схемы энергоснабжения, количества источников электропитания (основных и резервных), их надежность',
        ],
      },
      {
        code: '63648052',
        text:
          'Какие разделы входят в состав документации на ликвидацию скважин?',
        answers: [
          'Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин), технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья, порядок организации работ по ликвидации скважин, мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений',
          'Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации и (или) консервации скважин, вариант ликвидации и (или) консервации (в зависимости от этапа бурения или эксплуатации скважин), технологические и технические решения по ликвидации и (или) консервации скважин, оборудования ствола скважин и устья, порядок организации работ по ликвидации и (или) консервации скважин',
          'Технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья, порядок организации работ по ликвидации скважин, мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений, экспертиза промышленной безопасности ликвидации скважин, оборудования ствола скважин и устья',
        ],
        correctAnswers: [
          'Общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин), технологические и технические решения по ликвидации скважин, оборудования ствола скважин и устья, порядок организации работ по ликвидации скважин, мероприятия по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды, зданий и сооружений',
        ],
      },
      {
        code: '63648053',
        text:
          'Кем принимается решение о готовности технологического трубопровода к проведению испытаний?',
        answers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
          'Руководителем подрядной организации, осуществившей монтаж трубопровода',
          'Эксплуатирующей организацией',
          'Инспектором Ростехнадзора',
        ],
        correctAnswers: [
          'Монтажной организацией по согласованию с эксплуатирующей организацией',
        ],
      },
      {
        code: '63648054',
        text:
          'Кем должны согласовываться планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'Руководителями территориальных органов Федеральной службы по экологическому, технологическому и атомному надзору',
          'Руководителями территориальных органов Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Всеми перечисленными лицами',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63648055',
        text:
          'Каким документом обосновывается повышение категории взрывоопасности технологических блоков, определяемое количеством токсичных, высокотоксичных веществ, опасностью причинения ими вреда обслуживающему персоналу при вероятных сценариях развития аварийной ситуации?',
        answers: [
          'Проектной документацией',
          'Техническим регламентом',
          'Инструкцией по эксплуатации',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63648056',
        text:
          'Какие технологические блоки относятся к первой категории взрывоопасности?',
        answers: [
          'Qв 27 - 37, m, кг 2000 - 5000',
          'Qв меньше 27, m, кг меньше 2000',
          'Qв больше 37, m, кг больше 5000',
        ],
        correctAnswers: ['Qв больше 37, m, кг больше 5000'],
      },
      {
        code: '63648057',
        text:
          'В каком из перечисленных случаев категорию взрывоопасности блоков, определяемую расчетом, следует рассматривать на одну выше?',
        answers: [
          'Только если обращающиеся в технологическом блоке вещества относятся к токсичным веществам',
          'Только если обращающиеся в технологическом блоке вещества относятся к высокотоксичным веществам',
          'В любом из указанных случаев',
        ],
        correctAnswers: ['В любом из указанных случаев'],
      },
      {
        code: '63648058',
        text: 'Какие организации осуществляют учет актов о ликвидации скважин?',
        answers: [
          'Территориальные органы Ростехнадзора',
          'МЧС России',
          'Территориальные органы Росприроднадзора',
        ],
        correctAnswers: ['Территориальные органы Ростехнадзора'],
      },
      {
        code: '63648059',
        text:
          'В технологических блоках какой категории взрывоопасности должны быть предусмотрены технические средства, обеспечивающие в автоматическом режиме оповещение об обнаружении, локализации и ликвидации выбросов опасных веществ?',
        answers: [
          'Только в технологических блоках I категории взрывоопасности',
          'Только в технологических блоках II категории взрывоопасности',
          'Только в технологических блоках III категории взрывоопасности',
          'В технологических блоках всех категорий взрывоопасности',
        ],
        correctAnswers: [
          'В технологических блоках всех категорий взрывоопасности',
        ],
      },
      {
        code: '63648060',
        text:
          'Какими устройствами необходимо оборудовать отделители жидкости (сепараторы)?',
        answers: [
          'Термопарой и расходомером',
          'Индикатором уровня жидкости в сепараторе и манометром',
          'Световой и звуковой сигнализацией, блокировкой, производящей остановку компрессора при достижении предельно допустимого уровня жидкости в сепараторе',
          'Всеми перечисленными устройствами',
        ],
        correctAnswers: [
          'Световой и звуковой сигнализацией, блокировкой, производящей остановку компрессора при достижении предельно допустимого уровня жидкости в сепараторе',
        ],
      },
      {
        code: '63648061',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с номинальным давлением не более 10 МПа?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 6 лет',
        ],
        correctAnswers: ['Не реже одного раза в 8 лет'],
      },
      {
        code: '63648062',
        text:
          'Какими контрольно-измерительными приборами должна быть оборудована каждая нагнетательная линия установки гидропоршневых и струйных насосов?',
        answers: [
          'Регулятором давления и температуры',
          'Только манометром',
          'Манометром и регулятором давления рабочей жидкости',
          'Манометром и регулятором расхода рабочей жидкости',
        ],
        correctAnswers: ['Манометром и регулятором расхода рабочей жидкости'],
      },
      {
        code: '63648063',
        text:
          'Какая установлена периодичность испытания на прочность и плотность технологических трубопроводов с расчетным давлением более 10 МПа и расчетной температурой выше 200 °C?',
        answers: [
          'Не реже одного раза в 4 года',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 6 лет',
        ],
        correctAnswers: ['Не реже одного раза в 4 года'],
      },
      {
        code: '63648064',
        text:
          'В каком случае ликвидация и консервация скважин считаются завершенными?',
        answers: [
          'После подписания акта ликвидации или консервации организацией, эксплуатирующей ОПО и территориальным органом Ростехнадзора',
          'После окончания всех работ, предусмотренных проектом',
          'После принятия работ комиссией, в которую входят представители пользователя недр и субъекта федерации, в котором расположен объект',
        ],
        correctAnswers: [
          'После подписания акта ликвидации или консервации организацией, эксплуатирующей ОПО и территориальным органом Ростехнадзора',
        ],
      },
      {
        code: '63648065',
        text:
          'Для каких технологических трубопроводов за расчетное давление в трубопроводе принимают максимальное давление, развиваемое машиной динамического действия при закрытой задвижке со стороны нагнетания (с учетом максимального давления на линии всасывания)?',
        answers: [
          'Для напорных трубопроводов',
          'Для трубопроводов, защищенных предохранительными клапанами',
          'Для трубопроводов в системах с подогревателями',
        ],
        correctAnswers: ['Для напорных трубопроводов'],
      },
      {
        code: '63648066',
        text:
          'Допускается ли на территории охранной зоны нефтегазопроводов устройство колодцев, котлованов и других заглублений?',
        answers: [
          'Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации',
          'Допускается',
          'Допускается, если они предусмотрены проектной организацией',
          'Допускается, если их обустройство согласовано с территориальным органом Ростехнадзора',
        ],
        correctAnswers: [
          'Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации',
        ],
      },
      {
        code: '63648067',
        text:
          'Что следует использовать для отключения резервного насоса от всасывающих и напорных коллекторов?',
        answers: [
          'Задвижки',
          'Заглушки',
          'Обратный клапан',
          'Заглушки и задвижку',
          'Обратный клапан и заглушку',
        ],
        correctAnswers: ['Задвижки'],
      },
      {
        code: '63648068',
        text:
          'В каком положении должна находиться задвижка на отводе от затрубного пространства при закачке теплоносителя (с установкой пакера)?',
        answers: [
          'В закрытом',
          'В полуоткрытом',
          'В открытом',
          'Не регламентируется',
        ],
        correctAnswers: ['В открытом'],
      },
      {
        code: '63648069',
        text:
          'Какое из перечисленных требований к объектам сбора, транспорта, подготовки нефти, попутного нефтяного газа, воды в горных выработках, указано верно?',
        answers: [
          'Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород',
          'Нефтеловушки и нефтеперекачивающие станции размещаются в тупиковых горных выработках',
          'При бурении скважин станками с гидроприводом или с промывкой в полевых штреках сброс воды в водоотливные канавки категорически запрещен',
          'Допускается оставлять застой нефти на фундаментах насосов и под фундаментной рамой оборудования и механизмов, если это регламентировано локальной инструкцией организации',
        ],
        correctAnswers: [
          'Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород',
        ],
      },
      {
        code: '63648070',
        text:
          'Как Правила безопасности в нефтяной и газовой промышленности регламентируют посещение работниками горных выработок, температура рудничной атмосферы в которых превышает значения 25 °C при относительной влажности свыше 90%?',
        answers: [
          'Посещение допускается не менее чем двумя работниками',
          'Посещение допускается при скорости воздуха не менее 0,05 м/с',
          'Посещение не допускается',
          'Посещение допускается не более чем на 30 минут',
        ],
        correctAnswers: [
          'Посещение допускается не менее чем двумя работниками',
        ],
      },
      {
        code: '63648071',
        text:
          'Что необходимо предпринять в случае обнаружения загазованности воздуха рабочей зоны?',
        answers: [
          'Незамедлительно подать сигнал тревоги и предупредить ответственного руководителя',
          'Незамедлительно предупредить обслуживающий персонал и покинуть загазованный участок',
          'Незамедлительно покинуть загазованный участок и сообщить непосредственному руководителю',
          'Незамедлительно предупредить обслуживающий персонал о возможной опасности',
          'Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности',
        ],
        correctAnswers: [
          'Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности',
        ],
      },
      {
        code: '63648072',
        text:
          'Как необходимо производить установку цементного моста при ликвидации скважины с аварийным оборудованием в стволе скважины?',
        answers: [
          'Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 30 м',
          'Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 15 м',
          'Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 20 м',
        ],
        correctAnswers: [
          'Под давлением в интервалах перфорации и с перекрытием головы оставшегося инструмента на 20 м',
        ],
      },
      {
        code: '63648073',
        text:
          'С какой периодичностью необходимо проверять исправность системы автоматики и предохранительных устройств гидропоршневых и струйных насосов?',
        answers: [
          'Исправность системы автоматики и предохранительных устройств проверяется в сроки, установленные инструкцией по эксплуатации',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Исправность системы автоматики и предохранительных устройств проверяется в сроки, установленные правилами Ростехнадзора',
        ],
        correctAnswers: [
          'Исправность системы автоматики и предохранительных устройств проверяется в сроки, установленные инструкцией по эксплуатации',
        ],
      },
      {
        code: '63648074',
        text:
          'На какие трубопроводы из перечисленных распространяется действие Правил безопасной эксплуатации технологических трубопроводов?',
        answers: [
          'На технологические трубопроводы, специально сконструированные для применения в области использования атомной энергии и относящихся к области действия федеральных норм и правил в области использования атомной энергии',
          'На технологические трубопроводы, являющиеся неотъемлемой частью машин и оборудования (систем подачи смазки, охлаждающей жидкости, корпуса, части сосудов и аппаратов)',
          'На сети водоснабжения и канализации',
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа до избыточного давления 320 МПа и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
        correctAnswers: [
          'На технологические трубопроводы, предназначенные для транспортирования газообразных, парообразных и жидких сред в диапазоне расчетных давлений от остаточного (абсолютного) давления (вакуума) 0,000665 МПа до избыточного давления 320 МПа и рабочих температур от минус 196 °C до 700 °C и эксплуатирующиеся на опасных производственных объектах',
        ],
      },
      {
        code: '63648075',
        text:
          'На какое давление следует производить опрессовку фонтанной арматуры в собранном виде до и после установки на устье?',
        answers: [
          'До установки на устье - на рабочее давление, указанное в паспорте, после установки - на давление опрессовки эксплуатационной колонны',
          'До установки на устье - на пробное давление, превышающее на 25% давление опрессовки эксплуатационной колонны, после установки - на давление на 10% выше давления опрессовки эксплуатационной колонны',
          'До установки на устье - на давление опрессовки эксплуатационной колонны, указанное в паспорте, после установки - на расчетное давление',
        ],
        correctAnswers: [
          'До установки на устье - на рабочее давление, указанное в паспорте, после установки - на давление опрессовки эксплуатационной колонны',
        ],
      },
      {
        code: '63648076',
        text:
          'Чем должны фиксироваться колеса железнодорожных цистерн при сливе и наливе?',
        answers: ['Тормозом', 'Башмаками', 'Подложками'],
        correctAnswers: ['Башмаками'],
      },
      {
        code: '63648077',
        text:
          'У каких лиц устанавливается аппаратура системы общешахтного аварийного оповещения?',
        answers: [
          'Только у горного диспетчера нефтяной шахты',
          'Только у технического руководителя обособленного структурного подразделения',
          'Только у абонентов общешахтной и диспетчерской телефонной связи, определенных техническим руководителем обособленного структурного подразделения',
          'У всех перечисленных лиц',
        ],
        correctAnswers: ['У всех перечисленных лиц'],
      },
      {
        code: '63648078',
        text:
          'В чем должен убедиться руководитель работ перед началом технологического процесса на скважине с применением передвижных агрегатов?',
        answers: [
          'В наличии средств световой сигнализации',
          'В наличии двусторонней переговорной связи',
          'В наличии средств звуковой сигнализации',
          'В наличии средств контроля давления и температуры',
          'Во всем перечисленном',
        ],
        correctAnswers: ['В наличии двусторонней переговорной связи'],
      },
      {
        code: '63648079',
        text: 'В каких случаях допускается эксплуатация стрелочных переводов?',
        answers: [
          'При сбитых, выкрошенных и изогнутых в продольном и поперечном направлениях или неплотно прилегающих к рамному рельсу и башмакам стрелочных перьях',
          'При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм',
          'При отсутствии фиксации положения стрелочных переводов',
          'При открытых канавах стрелочных переводов',
        ],
        correctAnswers: [
          'При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм',
        ],
      },
      {
        code: '63648080',
        text:
          'В каких случаях крепежные детали технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'В случае появления трещин, срыва или коррозионного износа резьбы',
          'В случае износа боковых граней головок болтов и гаек',
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
          'В случае снижения механических свойств металла ниже допустимого уровня',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях крепежные детали подлежат отбраковке',
        ],
      },
      {
        code: '63648081',
        text:
          'В каких документах приводятся способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'Только в проектной документации',
          'Только в исходных данных на проектирование',
          'Только в технологическом регламенте на производство продукции',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63648082',
        text:
          'Какой должна быть длина переподъема для одноканатных подъемных установок со скоростью подъема свыше 3 м/с вертикальных и наклонных выработок (с углом наклона свыше 30°)?',
        answers: [
          'Не менее 2 м',
          'Не менее 3 м',
          'Не менее 6 м',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 6 м'],
      },
      {
        code: '63648083',
        text:
          'Когда пороховые генераторы (аккумуляторы) давления должны устанавливаться в спускаемую гирлянду зарядов?',
        answers: [
          'После установки перфорационной задвижки',
          'Перед началом работ',
          'Перед вводом в лубрикатор',
          'После открытия устьевой задвижки',
          'После извлечения устьевого пакера',
        ],
        correctAnswers: ['Перед вводом в лубрикатор'],
      },
      {
        code: '63648084',
        text: 'Чем укомплектовывается в резерве главная подъемная установка?',
        answers: [
          'Только испытанным канатом, прошедшим инструментальный контроль',
          'Только подъемным сосудом с прицепным устройством',
          'Только электродвигателем или полным комплектом запасных частей к нему',
          'Только компрессором с электродвигателем при отсутствии подвода от общешахтной пневмосистемы',
          'Только комплектом тормозных колодок',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63648085',
        text:
          'При каких условиях допускается подключать сетевой кабель к пусковому оборудованию электронагревателя?',
        answers: [
          'Только после подключения кабель-троса к трансформатору',
          'Только после заземления электрооборудования',
          'Только после проведения всех подготовительных работ в скважине и на устье',
          'Только после удаления людей',
          'После выполнения всех условий',
        ],
        correctAnswers: ['После выполнения всех условий'],
      },
      {
        code: '63648086',
        text:
          'Какие требования должны быть выполнены на вновь проектируемых взрывопожароопасных и химически опасных производственных объектах?',
        answers: [
          'Только бесперебойное функционирование автоматизированных систем контроля, управления, противоаварийной автоматической защиты (ПАЗ) для перевода технологических процессов в безопасное состояние и аварийного останова технологических объектов',
          'Только защита персонала, постоянно находящегося в помещении управления (операторных), административных и других зданиях, в которых предусмотрено постоянное пребывание людей, от воздействия ударной волны (травмирования) при возможных аварийных взрывах на технологических объектах с учетом зон разрушения, а также от термического воздействия',
          'Должны быть обеспечены все указанные требования',
        ],
        correctAnswers: ['Должны быть обеспечены все указанные требования'],
      },
      {
        code: '63648087',
        text:
          'На какой срок возможна временная приостановка скважин (без консервации) в связи с экономическими причинами (до строительства системы сбора и подготовки добываемой жидкости, отсутствие спроса на сырье, нерентабельность эксплуатации)?',
        answers: [
          'На срок до 6 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин',
          'На срок до 12 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин',
          'На срок до 18 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин',
        ],
        correctAnswers: [
          'На срок до 6 месяцев с последующим продлением при условии выполнения мероприятий по безопасному пользованию недрами, безопасности жизни и здоровья населения, охране окружающей среды на срок приостановки скважин',
        ],
      },
      {
        code: '63648088',
        text:
          'Как должен проводиться подвод инертного газа или пара к трубопроводам для продувки?',
        answers: [
          'С помощью съемных участков трубопроводов или гибких шлангов с установкой запорной арматуры с обеих сторон съемного участка',
          'С помощью съемных участков трубопроводов или гибких шлангов с установкой запорной арматуры с одной стороны съемного участка',
          'С помощью шлангов, имеющих металлическую оплетку, предотвращающую перегиб шланга',
          'С помощью гибкого шланга с установкой на его конце заглушки',
          'С помощью гибкого шланга с установкой на его конце обратного клапана',
        ],
        correctAnswers: [
          'С помощью съемных участков трубопроводов или гибких шлангов с установкой запорной арматуры с обеих сторон съемного участка',
        ],
      },
      {
        code: '63648089',
        text:
          'Какие узлы и устройства станка-качалки должны иметь ограждения и быть окрашены?',
        answers: [
          'Вращающиеся узлы и детали',
          'Весь станок-качалка',
          'Только площадка для обслуживания электропривода и площадка для обслуживания пускового устройства',
          'Кривошипно-шатунный механизм, площадка для обслуживания электропривода и площадка для обслуживания пускового устройства',
          'Только кривошипно-шатунный механизм и пусковое устройство',
        ],
        correctAnswers: [
          'Кривошипно-шатунный механизм, площадка для обслуживания электропривода и площадка для обслуживания пускового устройства',
        ],
      },
      {
        code: '63648090',
        text:
          'Что должно быть предусмотрено в оперативной части плана мероприятий по локализации и ликвидации последствий аварий (ПЛА)?',
        answers: [
          'Только режим работы вентиляции при возникновении аварии, в том числе включение аварийной вентиляции (при наличии)',
          'Только действия работников, ответственных за эвакуацию людей и проведение предусмотренных мероприятий',
          'Только способы оповещения об аварии (сирена, световая сигнализация, громкоговорящая связь, телефон), пути выхода людей из опасных мест и участков в зависимости от характера аварии',
          'Только необходимость и последовательность выключения электроэнергии, остановки оборудования, аппаратов, перекрытия источников поступления вредных и опасных веществ',
          'Только способы ликвидации аварий в начальной стадии',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63648091',
        text:
          'Какие из перечисленных мероприятий должны выполняться при разборке фланцевых соединений с целью замены прокладок, арматуры или отдельных элементов на идентичные?',
        answers: [
          'Только испытание на плотность',
          'Только пневматическое испытание',
          'Только испытание на прочность пробным давлением',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Только испытание на плотность'],
      },
      {
        code: '63648092',
        text:
          'Какие виды канатов перед их навеской могут не испытываться на канатно-испытательных станциях?',
        answers: [
          'Тяговые канаты для монорельсовых дорог',
          'Канаты на грузовых наклонных подъемах с углом наклона менее 30°',
          'Тяговые и несущие канаты для подземных канатных дорог',
          'Подъемные канаты вертикальных и наклонных шахтных подъемов',
        ],
        correctAnswers: [
          'Канаты на грузовых наклонных подъемах с углом наклона менее 30°',
        ],
      },
      {
        code: '63648093',
        text:
          'С какой периодичностью проводят проверку исправности предохранительной, регулирующей и запорной арматуры и как оформляют результаты проверки?',
        answers: [
          'Ежесменно с занесением результатов проверок в вахтовый журнал или соответствующую базу данных',
          'Ежесуточно с занесением результатов проверок в вахтовый журнал',
          'По графику, утвержденному эксплуатирующей организацией',
          'Еженедельно с занесением результатов проверок в соответствующую базу данных',
        ],
        correctAnswers: [
          'По графику, утвержденному эксплуатирующей организацией',
        ],
      },
      {
        code: '63648094',
        text:
          'В каком случае требуется проведение обследования технологического трубопровода при его расконсервации?',
        answers: [
          'После замены прокладок, арматуры или отдельных элементов трубопровода при вводе в эксплуатацию',
          'После отбраковки элементов технологического трубопровода',
          'После нахождения на консервации более двух лет',
          'После удаления заглушек с трубопровода',
        ],
        correctAnswers: ['После нахождения на консервации более двух лет'],
      },
      {
        code: '63648095',
        text:
          'С какой периодичностью на нефтяной шахте проводится плановая депрессионная съемка?',
        answers: [
          'Не реже одного раза в полгода',
          'Не реже одного раза в год',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63648096',
        text:
          'До какой величины должно быть снижено давление в нагнетательном трубопроводе при остановке силового насоса?',
        answers: [
          'До значения, не превышающего 0,5 рабочего давления',
          'До атмосферного давления',
          'До минимального значения избыточного давления, указанного в инструкции',
          'Давление не должно снижаться',
        ],
        correctAnswers: ['До атмосферного давления'],
      },
      {
        code: '63648097',
        text:
          'Какой должна приниматься величина пробного давления при проведении гидравлических испытаний технологических трубопроводов на прочность и плотность?',
        answers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 80% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 75% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 60% предела текучести (условного предела текучести) материала при температуре испытания',
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
        correctAnswers: [
          'Такой, чтобы максимальные напряжения в стенке трубопровода при пробном давлении не превышали 90% предела текучести (условного предела текучести) материала при температуре испытания',
        ],
      },
      {
        code: '63648098',
        text:
          'На какие категории подразделяются скважины, подлежащие ликвидации?',
        answers: [
          'I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам',
          'I - скважины, ликвидируемые по геологическим причинам; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по технологическим, экономическим и другим причинам',
          'I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по техническим причинам; III - скважины, ликвидируемые по причине нерентабельности (низкодебитные)',
        ],
        correctAnswers: [
          'I - скважины, выполнившие свое назначение; II - скважины, ликвидируемые по геологическим причинам; III - скважины, ликвидируемые по техническим причинам; IV - скважины, ликвидируемые по технологическим, экологическим и другим причинам',
        ],
      },
      {
        code: '63648099',
        text:
          'Какие дополнительные требования предъявляются при ликвидации скважин на месторождениях с высоким содержанием сернистого водорода (более 6%)?',
        answers: [
          'Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 70 м выше кровли',
          'Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 50 м выше кровли',
          'Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 100 м выше кровли',
        ],
        correctAnswers: [
          'Продуктивный пласт должен перекрываться цементным мостом по всей его мощности и на 100 м выше кровли',
        ],
      },
      {
        code: '63648100',
        text:
          'Какие требования к атмосфере в горных выработках нефтяных шахт указаны верно?',
        answers: [
          'Максимально допустимая концентрация диоксида углерода в рудничном воздухе в горных выработках с исходящей струей крыла, горизонта и шахты составляет 0,75%',
          'Максимально допустимая концентрация водорода в зарядных камерах составляет 0,5%',
          'Концентрация кислорода в воздухе в горных выработках нефтяных шахт должна составлять не менее 20% (по объему)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63648101',
        text:
          'Кем из должностных лиц проверяются канаты для перемещения забойного оборудования ежесменно перед началом работы?',
        answers: [
          'Машинистом подъемной машины или его помощником',
          'Работником обособленного структурного подразделения',
          'Главным механиком',
          'Работником подразделения профессионального аварийно-спасательного формирования',
        ],
        correctAnswers: ['Машинистом подъемной машины или его помощником'],
      },
      {
        code: '63648102',
        text:
          'Как должно быть организовано управление задвижками на трубопроводах, транспортирующих сжиженные газы, легковоспламеняющиеся жидкости и горючие жидкости на сливо-наливных эстакадах?',
        answers: [
          'Только по месту',
          'Только дистанционно (из безопасного места)',
          'По месту и дистанционно (из безопасного места)',
          'Определяется разработчиком проекта',
        ],
        correctAnswers: ['По месту и дистанционно (из безопасного места)'],
      },
      {
        code: '63648103',
        text:
          'Какие фонтанные скважины должны оснащаться внутрискважинным оборудованием (пакер и клапан-отсекатель, циркуляционный клапан, станция управления)?',
        answers: [
          'Фонтанные скважины с дебитом 350 т/сут нефти и более',
          'Фонтанные скважины с дебитом 300 т/сут нефти или 400 тыс. м³/сут газа и более, расположенные на расстоянии менее 700 м от населенного пункта',
          'Фонтанные скважины, расположенные на расстоянии менее 1000 м от населенного пункта',
          'Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта',
        ],
        correctAnswers: [
          'Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта',
        ],
      },
      {
        code: '63648104',
        text:
          'Что необходимо сделать с эксплуатационной колонной перед спуском в нее пакера?',
        answers: [
          'Прошаблонировать, при необходимости прорайбировать, промыть до забоя, опрессовать',
          'Определить остаточную прочность эксплуатационной колонны',
          'Провести комплекс геофизических исследований',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Прошаблонировать, при необходимости прорайбировать, промыть до забоя, опрессовать',
        ],
      },
      {
        code: '63648105',
        text:
          'Чем из перечисленного оснащаются производства, имеющие в своем составе технологические блоки III категории взрывоопасности, для предупреждения выбросов горючих продуктов в окружающую среду или максимального ограничения их количества?',
        answers: [
          'Эффективными системами, обеспечивающими непрерывность технологического процесса',
          'Системами ручного регулирования (без применения вычислительной техники)',
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
        correctAnswers: [
          'Средствами контроля параметров, значения которых определяют взрывоопасность процесса',
        ],
      },
      {
        code: '63648106',
        text:
          'На основании каких данных определяются допустимые значения скоростей, давлений, температур перемещаемых горючих продуктов с учетом их взрывопожароопасных характеристик, физико-химических свойств?',
        answers: [
          'На основании задания на проектирование',
          'На основании справочных данных',
          'На основании геодезической съемки',
          'На основании расчетных данных',
        ],
        correctAnswers: ['На основании задания на проектирование'],
      },
      {
        code: '63648107',
        text:
          'Кем утверждаются инструкции, устанавливающие требования к организации и безопасному ведению работ повышенной опасности на нефтяной шахте?',
        answers: [
          'Начальником участка',
          'Специалистом структурного подразделения, ведущего горные работы или работы по добыче нефти',
          'Техническим руководителем обособленного структурного подразделения',
          'Руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Техническим руководителем обособленного структурного подразделения',
        ],
      },
      {
        code: '63648108',
        text:
          'Какой высоты должен быть цементный мост, который устанавливается над кровлей верхнего пласта с минерализованной водой, а также на границе залегания пластов с пресными и минерализованными водами (если они не перекрыты технической колонной) при ликвидации скважин без эксплуатационной колонны?',
        answers: ['50 м', '70 м', '100 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63648109',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральным законом в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63648110',
        text:
          'Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?',
        answers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
          'Определяются территориальным органом Ростехнадзора на основании экспертизы промышленной безопасности',
          'Определяются эксплуатирующей организацией на основании их испытаний',
        ],
        correctAnswers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
        ],
      },
      {
        code: '63648111',
        text:
          'На какое давление опрессовывается манифольд после монтажа и соединения его с отводами фонтанной арматуры и трубной головки?',
        answers: [
          'На давление, превышающее рабочее на 25%',
          'На рабочее давление',
          'На пробное давление',
          'На максимальное давление',
        ],
        correctAnswers: ['На рабочее давление'],
      },
      {
        code: '63648112',
        text:
          'Что из перечисленного в обязательном порядке должна иметь организация, эксплуатирующая технологические трубопроводы?',
        answers: [
          'Только паспорт технологического трубопровода',
          'Только акты периодического наружного осмотра технологического трубопровода',
          'Только заключение о техническом состоянии арматуры',
          'Только заключение о качестве сварных стыков',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63648113',
        text:
          'В соответствии с какими требованиями осуществляются оборудование устья и ствола, срок консервации, порядок контроля за техническим состоянием законсервированных скважин?',
        answers: [
          'Только в соответствии с документацией, разработанной пользователями недр',
          'В соответствии с требованиями федеральных норм и правил в области промышленной безопасности и документации, разработанной пользователями недр или их уполномоченными представителями исходя из конкретных горно-геологических условий',
          'В соответствии с требованиями проектной документации на консервацию скважин',
        ],
        correctAnswers: [
          'В соответствии с требованиями федеральных норм и правил в области промышленной безопасности и документации, разработанной пользователями недр или их уполномоченными представителями исходя из конкретных горно-геологических условий',
        ],
      },
      {
        code: '63648114',
        text:
          'Допускается ли ликвидация скважин с негерметичными обсадными колоннами, заколонными перетоками, грифонами?',
        answers: [
          'Не регламентируется',
          'Допускается только после их устранения',
          'Допускается в любом случае',
        ],
        correctAnswers: ['Допускается только после их устранения'],
      },
      {
        code: '63648115',
        text:
          'Какой должна быть скорость воздуха в горных выработках нефтяных шахт длиной менее 30 м?',
        answers: [
          'Не менее 0,1 м/с',
          'Не менее 0,25 м/с',
          'Не менее 0,3 м/с',
          'Не менее 0,4 м/с',
        ],
        correctAnswers: ['Не менее 0,1 м/с'],
      },
      {
        code: '63648116',
        text:
          'Кто проводит отбор проб воздуха в замкнутом пространстве перед допуском лиц, осуществляющих работу?',
        answers: [
          'Руководитель работ',
          'Работники, уполномоченные руководителем работ',
          'Работники, имеющие допуск и обученные в этих целях',
        ],
        correctAnswers: ['Работники, имеющие допуск и обученные в этих целях'],
      },
      {
        code: '63648117',
        text:
          'Каким документом определяется время срабатывания запорных и (или) отсекающих устройств при аварийной разгерметизации системы?',
        answers: [
          'Проектной документацией или документацией на техническое перевооружение',
          'Паспортом технического устройства',
          'Только проектной документацией',
          'Эксплуатационной документацией',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63648118',
        text:
          'Какими организациями разрабатываются и утверждаются технологические регламенты на работы по добыче, сбору и подготовке нефти, газа и газового конденсата?',
        answers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящихся в эксплуатации, может разрабатываться эксплуатирующей организацией',
          'Разрабатываются специализированными организациями, а утверждаются территориальным органом Ростехнадзора',
          'Разрабатываются и утверждаются компанией-оператором',
          'Разрабатываются проектной организацией, а утверждаются эксплуатирующей организацией',
          'Разрабатываются проектной организацией, а утверждаются территориальным органом Ростехнадзора',
        ],
        correctAnswers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящихся в эксплуатации, может разрабатываться эксплуатирующей организацией',
        ],
      },
      {
        code: '63648119',
        text:
          'Что является основанием для подготовки плана изоляционно-ликвидационных работ на конкретную скважину?',
        answers: [
          'Результаты исследовательских работ на данной скважине',
          'Требование Ростехнадзора',
          'Решение комиссии о ликвидации группы скважин (скважины)',
        ],
        correctAnswers: [
          'Решение комиссии о ликвидации группы скважин (скважины)',
        ],
      },
      {
        code: '63648120',
        text:
          'Какое из перечисленных требований промышленной безопасности допускается при строительстве и эксплуатации подземных скважин?',
        answers: [
          'Допускается устанавливать запорные приспособления между предохранительными клапанами и оборудованием',
          'Допускается устанавливать задвижки без специальных запоров на продувочных стояках, конденсатосборных и конденсатоотводных линиях',
          'Допускается использовать общешахтную сеть сжатого воздуха и (или) передвижные компрессорные установки в качестве источников сжатого воздуха',
          'Допускается устанавливать предохранительные клапаны и контрольно-измерительные приборы на работающем оборудовании',
          'Допускается применять смазочные масла, не соответствующие эксплуатационной документации на оборудование',
        ],
        correctAnswers: [
          'Допускается использовать общешахтную сеть сжатого воздуха и (или) передвижные компрессорные установки в качестве источников сжатого воздуха',
        ],
      },
      {
        code: '63648121',
        text:
          'Чем заполняется ствол скважины между цементными мостами и выше последнего моста при их ликвидации?',
        answers: [
          'Заполняется нейтральной жидкостью',
          'Заполняется нейтральной незамерзающей жидкостью',
          'Заполняется любой жидкостью',
        ],
        correctAnswers: ['Заполняется нейтральной жидкостью'],
      },
      {
        code: '63648122',
        text:
          'Какие из перечисленных мероприятий, выполняемых в отношении технологических трубопроводов, не являются обязательными при остановке и консервации опасного производственного объекта?',
        answers: [
          'Промывка (пропарка)',
          'Продувка',
          'Контроль сплошности изоляции и толщины стенок',
          'Установка заглушек',
        ],
        correctAnswers: ['Контроль сплошности изоляции и толщины стенок'],
      },
      {
        code: '63648123',
        text:
          'Какой установлен объем выборочного освидетельствования технологических трубопроводов с номинальным давлением более 10 МПа? Выберите два варианта ответа.',
        answers: [
          'Не менее двух участков каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
        correctAnswers: [
          'Не менее двух участков каждого блока установки независимо от температуры рабочей среды',
          'Не менее одного участка каждого общецехового коллектора или межцехового трубопровода независимо от температуры рабочей среды',
        ],
      },
      {
        code: '63648124',
        text:
          'С какой глубины ниже дна реки извлекаются колонна, кондуктор и направление при расположении скважин на затапливаемой территории и в русле больших (судоходных) рек?',
        answers: ['С глубины 5 м', 'С глубины 15 м', 'С глубины 10 м'],
        correctAnswers: ['С глубины 10 м'],
      },
      {
        code: '63648125',
        text:
          'Какими документами устанавливаются сроки по проверке на герметичность соединений компрессоров и их газопроводов?',
        answers: [
          'Планом работ, утвержденным главным инженером организации',
          'Инструкциями завода-изготовителя и технологическим регламентом',
          'Инструкциями, утвержденными территориальным органом Ростехнадзора',
          'Приказом, утвержденным техническим руководителем организации',
        ],
        correctAnswers: [
          'Инструкциями завода-изготовителя и технологическим регламентом',
        ],
      },
      {
        code: '63648126',
        text:
          'Каким требованиям должны соответствовать специальные системы аварийного освобождения технологических блоков от обращающихся продуктов? Выберите 2 правильных варианта ответа.',
        answers: [
          'Быть мобильными, иметь небольшие габариты и вес',
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Обеспечивать минимально возможное время освобождения',
          'Осуществлять переход из режима ожидания в рабочее состояние в течение 30 секунд',
        ],
        correctAnswers: [
          'Исключать образование взрывоопасных смесей как в самих системах, так и в окружающей их атмосфере, а также развитие аварий',
          'Обеспечивать минимально возможное время освобождения',
        ],
      },
      {
        code: '63648127',
        text: 'Чем должен быть снабжен замерный люк на резервуарах?',
        answers: [
          'Герметичной крышкой с запорным устройством',
          'Герметичной крышкой с педалью для открывания ногой',
          'Герметичной крышкой с приспособлением для открывания рукой',
          'Герметичной крышкой с уплотнительной прокладкой',
          'Фиксатором',
        ],
        correctAnswers: ['Герметичной крышкой с педалью для открывания ногой'],
      },
      {
        code: '63648128',
        text:
          'На какие глубины устанавливаются цементные мосты по скважинам, ликвидированным по III категории, а также скважинам всех категорий, пробуренным в пределах внешнего контура нефтегазоносности и максимального размера искусственной залежи газохранилища?',
        answers: [
          'В интервале на 10 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища',
          'В интервале на 15 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища',
          'В интервале на 20 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища',
        ],
        correctAnswers: [
          'В интервале на 20 м ниже и выше мощности всех продуктивных горизонтов, продуктивность которых установлена в процессе бурения скважин, разработки месторождения, эксплуатации хранилища',
        ],
      },
      {
        code: '63648129',
        text:
          'При каком остаточном давлении паров продукта не допускается налив в цистерны?',
        answers: [
          'При давлении менее 0,05 МПа',
          'При давлении менее 0,5 МПа',
          'При давлении менее 0,1 МПа',
          'При давлении менее 0,07 МПа',
        ],
        correctAnswers: ['При давлении менее 0,05 МПа'],
      },
      {
        code: '63648130',
        text:
          'Какие из перечисленных мер по обеспечению взрывобезопасности предусматриваются проектной организацией для каждого технологического блока с учетом его энергетического потенциала? Выберите 2 варианта ответа.',
        answers: [
          'Предотвращение взрывов и травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
          'Повышение квалификации обслуживающего персонала на курсах переподготовки',
          'Уменьшение ущерба от аварии на взрывопожароопасном объекте',
        ],
        correctAnswers: [
          'Предотвращение взрывов и травмирования производственного персонала',
          'Предупреждение выбросов горючих продуктов в окружающую среду или максимальное ограничение их количества',
        ],
      },
      {
        code: '63648131',
        text:
          'В соответствии с какими документами осуществляется проведение и крепление горных выработок?',
        answers: [
          'Проектной документацией',
          'Технологическими регламентами',
          'Документацией по ведению горных работ',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63648132',
        text:
          'Как должны соотноситься давления негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ в поверхностных теплообменниках?',
        answers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) должно быть равным давлению нагреваемых (охлаждаемых) горючих веществ',
          'Давление теплоносителя (хладагента) должно быть меньше давления нагреваемых (охлаждаемых) горючих веществ',
          'Соотношение давлений негорючего теплоносителя (хладагента) и нагреваемых (охлаждаемых) горючих веществ устанавливается разработчиком процесса',
        ],
        correctAnswers: [
          'Давление теплоносителя (хладагента) должно превышать давление нагреваемых (охлаждаемых) горючих веществ',
        ],
      },
      {
        code: '63648133',
        text:
          'С какой периодичностью необходимо проводить осмотр крепи и армировки вертикальных горных выработок?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в неделю',
          'Не реже одного раза в месяц',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63648134',
        text:
          'В каких случаях при отключении на пусковом устройстве электродвигателя периодически работающей скважины с автоматическим, дистанционным или ручным пуском вывешивается плакат "Не включать, работают люди!"?',
        answers: [
          'Ни в каких из перечисленных случаях',
          'Перед началом ремонтных работ и осмотром оборудования',
          'При техническом освидетельствовании станка-качалки',
          'Перед началом прострелочно-взрывных работ и геофизическом исследовании скважины',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Перед началом ремонтных работ и осмотром оборудования',
        ],
      },
      {
        code: '63648135',
        text:
          'На каком расстоянии от эстакады разрешается выполнение огневых работ во время подачи железнодорожных цистерн и слива и налива горючих продуктов?',
        answers: [
          'На расстоянии более 25 м',
          'На расстоянии 50 и более м',
          'На расстоянии более 10 м',
          'На расстоянии 100 и более м',
        ],
        correctAnswers: ['На расстоянии 100 и более м'],
      },
      {
        code: '63648136',
        text:
          'Какие требования предъявляются к станции управления фонтанной арматурой газлифтной скважины?',
        answers: [
          'Станцию следует устанавливать на расстоянии 30 - 35 м от устья скважины',
          'Станцию следует размещать в специальном помещении',
          'Станцию следует надежно укреплять и заземлять',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63648137',
        text:
          'При каких отклонениях должна срабатывать автоматическая защита, прекращающая подачу топлива в парогенератор?',
        answers: [
          'Только при повышении давления в теплопроводе выше допустимого',
          'Только при понижении давления в теплопроводе ниже допустимого',
          'Только при прекращении подачи воды',
          'При изменении давления в теплопроводе ниже или выше допустимого, а также при прекращении подачи воды',
        ],
        correctAnswers: [
          'При изменении давления в теплопроводе ниже или выше допустимого, а также при прекращении подачи воды',
        ],
      },
      {
        code: '63648138',
        text:
          'Какой радиус опасной зоны должен быть установлен вокруг скважины и применяемого оборудования на период тепловой и комплексной обработки?',
        answers: [
          'Не менее 10 м',
          'Не менее 20 м',
          'Не менее 30 м',
          'Не менее 50 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63648139',
        text:
          'Какими приборами и средствами автоматизации должны оснащаться сепараторы, устанавливаемые для отделения жидкой фазы из перемещаемой газовой среды на всасывающей линии компрессора? Выберите 2 варианта ответа.',
        answers: [
          'Приборами контроля температуры и плотности отделяемой жидкой фазы',
          'Приборами измерения перепада давления на сепараторе',
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
        ],
        correctAnswers: [
          'Блокировками отключения компрессора при превышении предельно допустимого значения уровня',
          'Приборами контроля уровня, сигнализацией по максимальному уровню и средствами автоматизации',
        ],
      },
      {
        code: '63648140',
        text:
          'Чем должен быть оборудован насос, подающий масло на торцевые уплотнения?',
        answers: [
          'Блокировочным устройством, включающим резервный масляный насос при падении давления масла',
          'Контактным манометром, включающим предупредительный сигнал о падении давления масла',
          'Дифференциальным преобразователем давления, подающим сигнал о падении давления масла на диспетчерский пульт',
          'Предохранительным устройством, предотвращающим превышение давления масла сверх установленного заводом-изготовителем',
        ],
        correctAnswers: [
          'Блокировочным устройством, включающим резервный масляный насос при падении давления масла',
        ],
      },
      {
        code: '63648141',
        text:
          'Как проверяется наличие мостов при ликвидации скважин без эксплуатационной колонны?',
        answers: [
          'Методом гидравлической опрессовки',
          'Разгрузкой бурильного инструмента или насосно-компрессорных труб с усилием, не превышающим предельно допустимую удельную нагрузку на цементный камень',
          'Путем проведения исследовательских работ',
        ],
        correctAnswers: [
          'Разгрузкой бурильного инструмента или насосно-компрессорных труб с усилием, не превышающим предельно допустимую удельную нагрузку на цементный камень',
        ],
      },
      {
        code: '63648142',
        text:
          'Какой установлен срок консервации скважин после эксплуатации без установки консервационного моста над интервалом перфорации?',
        answers: ['3 года', '5 лет', '1 год', '7 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63648143',
        text:
          'В каких случаях фланцы технологических трубопроводов подлежат отбраковке?',
        answers: [
          'Только в случае неудовлетворительного состояния уплотнительных поверхностей',
          'Только в случае наличия трещин, раковин и других дефектов',
          'Только в случае деформации фланцев',
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях фланцы подлежат отбраковке',
        ],
      },
      {
        code: '63648144',
        text:
          'Какие требования предъявляются к выкидной линии от предохранительного устройства насоса?',
        answers: [
          'Выкидная линия должна быть жестко закреплена и выведена в сбросную емкость для сбора жидкости или на прием насоса',
          'Выкидная линия должна быть оборудована обратным клапаном и выведена в сбросную емкость для сбора жидкости',
          'Выкидная линия должна быть оборудована предохранительным клапаном',
          'Выкидная линия должна иметь надежную запорную арматуру',
          'Выкидная линия должна быть оборудована манометром',
        ],
        correctAnswers: [
          'Выкидная линия должна быть жестко закреплена и выведена в сбросную емкость для сбора жидкости или на прием насоса',
        ],
      },
      {
        code: '63648145',
        text:
          'Где и в течение какого срока хранятся свидетельства об испытаниях каната?',
        answers: [
          'В обособленном структурном подразделении в течение всего срока службы каната',
          'На видимой стороне подъемной установки в течение всего ее срока службы',
          'На рабочем месте обслуживающего персонала подъемной установки в течение всего ее срока службы',
          'В Ростехнадзоре в течение 20 лет',
        ],
        correctAnswers: [
          'В обособленном структурном подразделении в течение всего срока службы каната',
        ],
      },
      {
        code: '63648146',
        text:
          'Какими документами следует руководствоваться при эксплуатации УКПГ, газосборных пунктов, головных сооружений?',
        answers: [
          'Инструкциями завода-изготовителя',
          'Эксплуатационным руководством, утвержденным техническим директором организации',
          'Технологическими регламентами',
          'Планом производства работ',
          'Всеми перечисленными документами',
        ],
        correctAnswers: ['Технологическими регламентами'],
      },
      {
        code: '63648147',
        text:
          'Для каких технологических трубопроводов стилоскопирование проводится выборочно, но не менее двух соединений, выполненных одним сварщиком с использованием сварочных материалов из одной партии, при отсутствии в проекте иных указаний?',
        answers: [
          'Для технологических трубопроводов при расчетном давлении выше 10 МПа',
          'Для технологических трубопроводов опасных веществ 1-го и 2-го классов опасности',
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
        ],
        correctAnswers: [
          'Для технологических трубопроводов с расчетным давлением не более 10 МПа',
        ],
      },
      {
        code: '63648148',
        text:
          'Каким образом допускается подвешивать кабельный ролик на мачте подъемного агрегата?',
        answers: [
          'Только при помощи специальной цепи',
          'Только при помощи специальной канатной подвески',
          'При помощи стального каната диаметром 12 мм с 4 зажимами',
          'При помощи цепи или на специальной канатной подвеске и страховаться тросом диаметром не менее 8 мм',
          'При помощи стальной комплектной вилки',
        ],
        correctAnswers: [
          'При помощи цепи или на специальной канатной подвеске и страховаться тросом диаметром не менее 8 мм',
        ],
      },
      {
        code: '63648149',
        text:
          'В каком случае создаются изоляционные экраны в подошвенных водоупорах и ниже интервала залегания многолетнемерзлых пород в процессе ликвидации скважин?',
        answers: [
          'При наличии в разрезе осадочного чехла месторождения зоны слабоминерализованных и питьевых верхних вод или многолетнемерзлых пород',
          'Изоляционные экраны создаются во всех проблемных геологических зонах',
          'Создание изоляционных экранов не требуется',
        ],
        correctAnswers: [
          'При наличии в разрезе осадочного чехла месторождения зоны слабоминерализованных и питьевых верхних вод или многолетнемерзлых пород',
        ],
      },
      {
        code: '63648150',
        text:
          'Какую информацию включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Только действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварий',
          'Только количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63648151',
        text:
          'Что необходимо предпринять при ликвидации скважин в результате аварии с внутрискважинным оборудованием (категория III-а) и невозможности его извлечения?',
        answers: [
          'Необходимо произвести торпедирование или отворот неприхваченной части инструмента',
          'Необходимо произвести установку цементного моста под давлением с перекрытием головы оставшегося инструмента на 50 м',
          'Необходимо произвести установку цементного моста в интервалах продуктивности',
        ],
        correctAnswers: [
          'Необходимо произвести торпедирование или отворот неприхваченной части инструмента',
        ],
      },
      {
        code: '63648152',
        text:
          'На основании чего проводится периодическая проверка клапана-отсекателя на срабатывание в процессе его эксплуатации?',
        answers: [
          'На основании распоряжения главного механика организации',
          'На основании инструкции завода-изготовителя',
          'На основании приказа технического руководителя организации',
          'На основании требований Ростехнадзора',
        ],
        correctAnswers: ['На основании инструкции завода-изготовителя'],
      },
      {
        code: '63648153',
        text:
          'Какое управление системами подачи инертных сред в технологические системы должно предусматриваться для производств, имеющих в своем составе технологические блоки I и II категории взрывоопасности?',
        answers: [
          'Ручное управление по месту',
          'Автоматическое',
          'Дистанционное, неавтоматическое',
        ],
        correctAnswers: ['Автоматическое'],
      },
      {
        code: '63648154',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По распоряжению на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ',
          'Выполнение работ, не включенных в утвержденный перечень газоопасных работ, не допускается',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63648155',
        text:
          'Какое устройство должно быть предусмотрено для парового змеевика, расположенного внутри резервуара?',
        answers: [
          'Устройство для предотвращения замерзания в зимнее время при прекращении подачи пара',
          'Устройство для спуска из него конденсата',
          'Устройство, сигнализирующее о превышении температуры выше допустимых норм',
          'Устройство, сигнализирующее о прекращении подачи пара',
        ],
        correctAnswers: ['Устройство для спуска из него конденсата'],
      },
      {
        code: '63648156',
        text: 'Какими клапанами должен быть оснащен каждый резервуар?',
        answers: [
          'Предохранительными и шаровыми',
          'Только дыхательными',
          'Дыхательными и предохранительными',
          'Только предохранительными',
        ],
        correctAnswers: ['Дыхательными и предохранительными'],
      },
      {
        code: '63648157',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 2 месяцев со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63648158',
        text:
          'При какой скорости движения осматриваются поврежденные участки канатов, а также стыковые соединения резинотросовых канатов?',
        answers: [
          'При неподвижном канате',
          'При скорости движения канатов 0,3 м/с',
          'При скорости движения канатов 1 м/с',
          'При скорости движения канатов, обеспечивающей 100%-й визуальный контроль за целостностью каната',
        ],
        correctAnswers: ['При неподвижном канате'],
      },
      {
        code: '63648159',
        text:
          'Каким проверкам должны подвергаться разъемные соединения компрессоров и их газопроводов?',
        answers: [
          'На герметичность',
          'На коррозионный износ',
          'На целостность уплотнений',
          'На отсутствие дефектов',
        ],
        correctAnswers: ['На герметичность'],
      },
      {
        code: '63648160',
        text:
          'Разрешается ли применение гибких шлангов в качестве стационарных трубопроводов?',
        answers: [
          'Запрещается',
          'Разрешается',
          'Разрешается при согласовании с Ростехнадзором',
          'Разрешается в технически обоснованных случаях и при соответствии качества шлангов условиям эксплуатации',
          'Разрешается, если это предусмотрено регламентом работ',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63648161',
        text:
          'Какое устройство должно быть установлено на нагнетательной линии у устья скважины при закачке в нее химреагентов, пара, горячей воды?',
        answers: [
          'Обратный клапан',
          'Предохранительный клапан',
          'Автоматически регулируемая задвижка',
          'Шаровой кран',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63648162',
        text:
          'Какое минимальное расстояние должно быть между парораспределительным пунктом и устьем нагнетательной скважины?',
        answers: ['10 м', '15 м', '20 м', '25 м'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63648163',
        text:
          'Что должна предусматривать подготовка рабочего агента (газа) при газлифтной эксплуатации?',
        answers: [
          'Ввод ингибитора',
          'Очистку от примесей',
          'Осушку от водяных паров до точки росы минус 10 °С для южных районов и минус 20 °С для средних и северных широт',
          'Фильтрацию и удаление твердых взвешенных частиц',
        ],
        correctAnswers: [
          'Осушку от водяных паров до точки росы минус 10 °С для южных районов и минус 20 °С для средних и северных широт',
        ],
      },
      {
        code: '63648164',
        text:
          'С какой периодичностью рукава для слива и налива сжиженных газов, легковоспламеняющиеся жидкости (ЛВЖ) и горючие жидкости (ГЖ) должны подвергаться гидравлическому испытанию на прочность?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в два года',
          'Не реже одного раза в год',
          'Не реже одного раза в шесть месяцев',
        ],
        correctAnswers: ['Не реже одного раза в три месяца'],
      },
      {
        code: '63648165',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['1 год', '5 лет', '3 года', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63648166',
        text:
          'Что необходимо сделать с газораспределительными трубопроводами после их монтажа?',
        answers: [
          'Продуть азотом и опрессовать жидкостью на давление, превышающее на 20% максимальное расчетное',
          'Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25% максимальное рабочее',
          'Продуть инертным газом и провести пневматическое испытание на давление, превышающее на 30% расчетное',
          'Продуть кислородом и опрессовать жидкостью на давление, превышающее на 10% максимальное рабочее',
        ],
        correctAnswers: [
          'Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25% максимальное рабочее',
        ],
      },
      {
        code: '63648167',
        text:
          'На каких технологических трубопроводах должно проводиться наблюдение за ростом остаточной деформации? Выберите два варианта ответа.',
        answers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из высоколегированной аустенитной стали с рабочей температурой 300 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
          'Из кремнемарганцовистой стали с рабочей температурой 250 °C и выше',
        ],
        correctAnswers: [
          'Из углеродистой стали с рабочей температурой 400 °C и выше',
          'Из хромомолибденовой стали с рабочей температурой 500 °C и выше',
        ],
      },
      {
        code: '63648168',
        text:
          'Какие данные указываются на металлической таблице, устанавливаемой на устье скважины при ее ликвидации?',
        answers: [
          'Данные о технических характеристиках скважины',
          'Номер скважины, дата ее ликвидации, месторождение (площадь), организация - пользователь недр',
          'Все перечисленные данные',
        ],
        correctAnswers: [
          'Номер скважины, дата ее ликвидации, месторождение (площадь), организация - пользователь недр',
        ],
      },
      {
        code: '63648169',
        text:
          'Что не входит в комплектацию вспомогательных подъемных установок?',
        answers: [
          'Подъемные сосуды вместимостью не менее двух человек',
          'Канаты длиной, соответствующей глубине ствола и запасом прочности не менее 9-кратного по отношению к максимальной расчетной статической нагрузке',
          'Подъемные сосуды вместимостью не менее шести человек',
          'Сигнализация, позволяющая подавать сигналы из подъемного сосуда и с горизонтов, с которых предполагается выезд людей',
        ],
        correctAnswers: ['Подъемные сосуды вместимостью не менее двух человек'],
      },
      {
        code: '63648170',
        text:
          'Какое из перечисленных положений не соответствует требованиям по размещению и устройству помещений управления взрывоопасных производств?',
        answers: [
          'Помещения управления должны быть отдельно стоящими',
          'В помещениях не должны размещаться оборудование и другие устройства, не связанные с системой управления технологическим процессом',
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
          'В отдельных случаях при соответствующем обосновании в проекте разрешается пристраивать помещения управления к зданиям',
        ],
        correctAnswers: [
          'Помещения управления должны располагаться над (под) взрывопожароопасными помещениями, помещениями с химически активной и вредной средой, приточными и вытяжными венткамерами, помещениями с мокрыми процессами',
        ],
      },
      {
        code: '63648171',
        text:
          'Кто утверждает перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право руководить этими работами?',
        answers: [
          'Руководитель вышестоящей организации',
          'Представитель территориального органа Ростехнадзора',
          'Руководитель организации или уполномоченное им лицо',
          'Руководитель регионального центра МЧС России',
          'Технический руководитель (главный инженер) организации',
        ],
        correctAnswers: ['Руководитель организации или уполномоченное им лицо'],
      },
      {
        code: '63648172',
        text:
          'Кто обеспечивает ликвидацию скважин, не подлежащих использованию, а также сохранность скважин, которые могут быть использованы при разработке месторождения и (или) в иных хозяйственных целях?',
        answers: [
          'Пользователь недр',
          'Пользователь недр по согласованию с Ростехнадзором',
          'Специализированная организация',
        ],
        correctAnswers: ['Пользователь недр'],
      },
      {
        code: '63648173',
        text:
          'Что из нижеперечисленного должно быть указано в плане производства работ по нагнетанию в скважину газа, пара, химических и других агентов?',
        answers: [
          'Только порядок подготовительных работ и схема размещения оборудования',
          'Только технология проведения процесса',
          'Только меры безопасности',
          'Только ответственный руководитель работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63648174',
        text:
          'На какое расстояние от эстакады должен быть удален локомотив перед началом операции по сливу и наливу железнодорожных цистерн?',
        answers: [
          'На расстояние не менее 30 м',
          'На расстояние не менее 50 м',
          'На расстояние не менее 80 м',
          'На расстояние не менее 100 м',
        ],
        correctAnswers: ['На расстояние не менее 100 м'],
      },
      {
        code: '63648175',
        text:
          'Какой персонал имеет право на проведение ремонта электрооборудования и освещения?',
        answers: [
          'Персонал, получивший целевой инструктаж',
          'Персонал, назначенный руководителем работ',
          'Электротехнический персонал',
        ],
        correctAnswers: ['Электротехнический персонал'],
      },
      {
        code: '63648176',
        text:
          'Какая допускается максимальная отсрочка в проведении освидетельствования технологических трубопроводов с учетом результатов предыдущего освидетельствования и технического состояния трубопровода, обеспечивающего его дальнейшую надежную эксплуатацию?',
        answers: ['18 месяцев', '24 месяца', '36 месяцев', '12 месяцев'],
        correctAnswers: ['12 месяцев'],
      },
      {
        code: '63648177',
        text:
          'Какой персонал имеет право на проведение ремонта электрообезвоживающей и обессоливающей установок?',
        answers: [
          'Электротехнологический персонал',
          'Персонал, получивший целевой инструктаж',
          'Электротехнический персонал, допущенный к работам на электроустановках напряжением выше 1000 В',
        ],
        correctAnswers: [
          'Электротехнический персонал, допущенный к работам на электроустановках напряжением выше 1000 В',
        ],
      },
      {
        code: '63648178',
        text:
          'Какие требования предъявляются к электрическим датчикам систем контроля и управления технологическим процессом?',
        answers: [
          'Должны быть во взрывозащищенном исполнении',
          'Должны быть рассчитаны на применение в условиях вибрации',
          'Должны быть рассчитаны на применение в условиях газовых гидратов',
          'Все вышеперечисленные требования',
        ],
        correctAnswers: ['Все вышеперечисленные требования'],
      },
      {
        code: '63648179',
        text: 'Откуда должен производиться забор воздуха компрессором?',
        answers: [
          'Из безопасной зоны на расстоянии 50 м от помещения компрессорной',
          'Из зоны (вне помещения компрессорной), не содержащей примеси горючих газов и пыли',
          'Из помещения компрессорной',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Из зоны (вне помещения компрессорной), не содержащей примеси горючих газов и пыли',
        ],
      },
      {
        code: '63648180',
        text:
          'Допускается ли принимать консервационные цементные мосты в качестве ликвидационных?',
        answers: [
          'Допускается',
          'Допускается по скважинам, вскрывшим малодебитные, низконапорные пласты с коэффициентом аномалии давления менее 1,1, принимать консервационные цементные мосты в качестве ликвидационных и при условии, что мост перекрывает весь интервал перфорации и не менее чем на 50 м выше его',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается по скважинам, вскрывшим малодебитные, низконапорные пласты с коэффициентом аномалии давления менее 1,1, принимать консервационные цементные мосты в качестве ликвидационных и при условии, что мост перекрывает весь интервал перфорации и не менее чем на 50 м выше его',
        ],
      },
      {
        code: '63648181',
        text:
          'Сколько времени должна составлять продолжительность продувки технологических трубопроводов, если нет специальных указаний в проекте?',
        answers: [
          'Не менее 5 минут',
          'Не менее 10 минут',
          'Не менее 7 минут',
          'Не менее 3 минут',
        ],
        correctAnswers: ['Не менее 10 минут'],
      },
      {
        code: '63648182',
        text:
          'Что из нижеперечисленного должно находиться на месте производства работ по закачке агрессивных химреагентов в скважину?',
        answers: [
          'Аварийный запас средств индивидуальной защиты, запас технической воды и нейтрализующие элементы для раствора',
          'Аварийные средства пожаротушения и запас технической воды',
          'Специальная аппаратура для оповещения о разгерметизации запорной арматуры',
          'Аварийный запас спецодежды, спецобуви и других средств индивидуальной защиты, запас чистой пресной воды и нейтрализующие компоненты для раствора',
          'Аварийный запас воды и нейтрализующие компоненты для раствора',
        ],
        correctAnswers: [
          'Аварийный запас спецодежды, спецобуви и других средств индивидуальной защиты, запас чистой пресной воды и нейтрализующие компоненты для раствора',
        ],
      },
      {
        code: '63648183',
        text:
          'Какая максимальная температура воздуха допускается в добычных, подготовительных и других действующих выработках у мест, где работают люди при относительной влажности до 90%?',
        answers: ['24 °С', '25 °С', '26 °С', '28 °С'],
        correctAnswers: ['26 °С'],
      },
      {
        code: '63648184',
        text:
          'Какой запас прочности должны иметь тяговые канаты для перемещения забойного оборудования по отношению к номинальному усилию на их рабочих барабанах?',
        answers: [
          'Не менее 4-кратного',
          'Не менее 2-кратного',
          'Не менее 3-кратного',
          'Не менее 1,5-кратного',
        ],
        correctAnswers: ['Не менее 3-кратного'],
      },
      {
        code: '63648185',
        text:
          'Чем оборудуются наклонные горные выработки, предназначенные для передвижения людей, при углах наклона от 31° до 45°?',
        answers: [
          'Трапами с перилами',
          'Лестницами с горизонтальными ступенями и перилами',
          'Трапами',
          'Сходнями со ступенями и перилами',
        ],
        correctAnswers: ['Лестницами с горизонтальными ступенями и перилами'],
      },
      {
        code: '63648186',
        text: 'Кем утверждается документация по ведению горных работ?',
        answers: [
          'Руководителем обособленного структурного подразделения',
          'Специалистом, ответственным за осуществление производственного контроля',
          'Главным механиком',
          'Главным инженером проекта',
        ],
        correctAnswers: [
          'Руководителем обособленного структурного подразделения',
        ],
      },
      {
        code: '63648187',
        text:
          'Какие меры безопасности должны быть выполнены обслуживающим персоналом при производстве гидравлических испытаний нагнетательной системы?',
        answers: [
          'Обслуживающий персонал должен быть удален за пределы опасной зоны. Ликвидация утечек под давлением в системе запрещается',
          'Обслуживающий персонал должен занять места в укрытии',
          'Обслуживающий персонал должен быть отведен на расстояние не менее 50 м от рабочей зоны',
          'Обслуживающий персонал должен быть удален за пределы рабочей зоны. Возвращение допускается только для ликвидации пропусков',
        ],
        correctAnswers: [
          'Обслуживающий персонал должен быть удален за пределы опасной зоны. Ликвидация утечек под давлением в системе запрещается',
        ],
      },
      {
        code: '63648188',
        text:
          'В соответствии с какими документами должна устанавливаться скорость изменения технологических параметров?',
        answers: [
          'В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования',
          'Только в соответствии с технологическим регламентом',
          'Только в соответствии с заводскими инструкциями по эксплуатации оборудования',
          'В соответствии с Правилами безопасности в нефтяной и газовой промышленности',
          'В соответствии с планами проведения работ',
        ],
        correctAnswers: [
          'В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования',
        ],
      },
      {
        code: '63648189',
        text:
          'Кто осуществляет контроль за состоянием устьев ликвидированных и законсервированных скважин?',
        answers: [
          'Пользователь недрами или уполномоченный им представитель в соответствии с действующими лицензиями на пользование недрами',
          'Территориальный орган Ростехнадзора',
          'Минприроды России',
        ],
        correctAnswers: [
          'Пользователь недрами или уполномоченный им представитель в соответствии с действующими лицензиями на пользование недрами',
        ],
      },
      {
        code: '63648190',
        text:
          'Какие требования предъявляются к процессу дренирования воды из электродегидраторов и отстойников?',
        answers: [
          'Процесс должен быть автоматизированным и осуществляться закрытым способом',
          'Процесс должен быть автоматизированным и осуществляться открытым способом',
          'Процесс должен быть с ручным управлением и осуществляться закрытым способом',
          'Процесс должен быть с ручным управлением и осуществляться открытым способом',
        ],
        correctAnswers: [
          'Процесс должен быть автоматизированным и осуществляться закрытым способом',
        ],
      },
      {
        code: '63648191',
        text:
          'С какой стороны следует становиться при открывании замерного люка, измерении уровня, отборе проб, а также при дренировании резервуаров?',
        answers: [
          'С подветренной стороны',
          'С наветренной стороны',
          'Над замерным люком',
          'Не регламентируется',
        ],
        correctAnswers: ['С наветренной стороны'],
      },
      {
        code: '63648192',
        text:
          'С какой периодичностью проводится проверка на герметичность игольчатых вентилей пробоотборников?',
        answers: [
          'Не реже одного раза в пять лет',
          'Не реже одного раза в два года',
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в шесть месяцев'],
      },
      {
        code: '63648193',
        text:
          'При какой предельно допустимой концентрации содержания диоксида углерода в воздухе закрытого помещения работы в нем должны быть прекращены?',
        answers: [
          '0,1 (объемных) %',
          '0,2 (объемных) %',
          '0,3 (объемных) %',
          '0,4 (объемных) %',
          '0,5 (объемных) %',
        ],
        correctAnswers: ['0,5 (объемных) %'],
      },
      {
        code: '63648194',
        text:
          'Какие из перечисленных мер предусматриваются проектной документацией или документацией на техническое перевооружение для обеспечения взрывобезопасности технологической системы при пуске в работу и остановке оборудования? Выберите 2 варианта ответа.',
        answers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
          'Поддержание рабочего давления инертного газа в технологической системе в период остановки оборудования',
          'Проведение контроля за содержанием горючих веществ в технологической системе после остановки технологического оборудования',
        ],
        correctAnswers: [
          'Разработка способов продувки оборудования инертными газами, исключающими образование застойных зон',
          'Разработка режимов и порядка пуска и остановки оборудования',
        ],
      },
      {
        code: '63648195',
        text:
          'Как оборудуются скважины, расположенные на землях, используемых для сельскохозяйственных целей, и на землях непромышленных категорий при их ликвидации?',
        answers: [
          'Устья скважины углубляются не менее чем на 0,8 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне), и таблицей с указанием номера скважины, месторождения (площади), организации – пользователя недр и даты ее ликвидации',
          'Устья скважины углубляются не менее чем на 0,5 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне), и таблицей с указанием номера скважины, месторождения (площади), организации - пользователя недр и даты ее ликвидации',
          'Устья скважины углубляются не менее чем на 0,3 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне), и таблицей с указанием номера скважины, месторождения (площади)',
        ],
        correctAnswers: [
          'Устья скважины углубляются не менее чем на 0,8 м от поверхности, оборудуются заглушкой, установленной на кондукторе (технической колонне), и таблицей с указанием номера скважины, месторождения (площади), организации – пользователя недр и даты ее ликвидации',
        ],
      },
      {
        code: '63648196',
        text:
          'Какие требования предъявляются ФНП к системе контроля состояния воздушной среды для закрытых помещений объектов сбора, подготовки и транспортировки нефти, газа и конденсата?',
        answers: [
          'В системе контроля состояния воздушной среды должно быть предусмотрено дублирование датчиков аварийного включения оборудования и системы приточно-вытяжной вентиляции',
          'Система должна иметь блокировку от включения оборудования, все помещения должны иметь постоянно действующую противопожарную систему',
          'Система должна быть сблокирована с системой выключения оборудования, включая перекрытие клапанов, все помещения должны иметь постоянно действующую систему оповещения и сигнализации',
          'Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции',
          'Система должна иметь блокировку от выключения оборудования, включая перекрытие запорной арматуры, все помещения должны иметь постоянно действующую систему вытяжной вентиляции',
        ],
        correctAnswers: [
          'Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63648197',
        text:
          'Какие требования предъявляются к территориям резервуарных парков и площадкам внутри обвалования?',
        answers: [
          'Территория и площадки должны быть чистыми и иметь ров, предотвращающий распространение пожара',
          'Территория и площадки должны быть чистыми и иметь дренажный ров, предотвращающий распространение непреднамеренного разлива нефтепродукта',
          'Территория и площадки должны быть чистыми и не иметь в зоне резервуарных парков зеленых насаждений',
          'Территория и площадки должны быть чистыми и очищенными от сухой травы',
          'Территория и площадки должны быть чистыми, очищенными от земли, пропитанной продуктами хранения, и сухой травы',
        ],
        correctAnswers: [
          'Территория и площадки должны быть чистыми, очищенными от земли, пропитанной продуктами хранения, и сухой травы',
        ],
      },
      {
        code: '63648198',
        text:
          'Какие средства могут применяться для контроля состава рудничной атмосферы при абсолютной газообильности горных выработок менее 3 м³/мин?',
        answers: [
          'Универсальные средства контроля',
          'Переносные и (или) стационарные средства контроля',
          'Выбор средств контроля суммарного содержания углеводородных газов и паров жидких углеводородов в горных выработках осуществляет технический руководитель обособленного структурного подразделения',
          'Только стационарные средства контроля',
          'Только переносные средства контроля',
        ],
        correctAnswers: ['Переносные и (или) стационарные средства контроля'],
      },
      {
        code: '63648199',
        text:
          'С учетом каких параметров в каждом конкретном случае в проектной документации (документации на техническое перевооружение) обосновывается решение о типе арматуры и месте ее установки на линиях всасывания и нагнетания, а также способе ее отключения, в том числе дистанционном? Выберите 2 варианта ответа.',
        answers: [
          'С учетом характеристики транспортируемой среды',
          'С учетом диаметра и протяженности трубопровода',
          'С учетом толщины стенки трубопровода',
          'С учетом марки стали трубопровода',
        ],
        correctAnswers: [
          'С учетом характеристики транспортируемой среды',
          'С учетом диаметра и протяженности трубопровода',
        ],
      },
      {
        code: '63648200',
        text:
          'В течение какого времени решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода оформляется на бумажном носителе или в форме электронного документа?',
        answers: [
          'В течение двадцати рабочих дней со дня принятия указанного решения',
          'В течение пятнадцати рабочих дней со дня принятия указанного решения',
          'В течение тридцати рабочих дней со дня принятия указанного решения',
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
        correctAnswers: [
          'В течение четырнадцати рабочих дней со дня принятия указанного решения',
        ],
      },
      {
        code: '63648201',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемые одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Допускается разработка единого плана мероприятий только для объектов, находящихся на расстоянии менее 50 м друг от друга',
          'Разрабатываются планы мероприятий на каждый объект',
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько - для объектов на смежных участках',
        ],
        correctAnswers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63648202',
        text:
          'Какова максимальная скорость спуска (подъема) погружного электронасоса в вертикальную скважину?',
        answers: ['0,25 м/с', '0,30 м/с', '0,15 м/с', 'Не регламентируется'],
        correctAnswers: ['0,25 м/с'],
      },
      {
        code: '63648203',
        text:
          'Каким документом устанавливаются места расположения запорных и (или) отсекающих устройств?',
        answers: [
          'Планом производства работ',
          'Только проектной документацией',
          'Проектной документацией или документацией на техническое перевооружение',
        ],
        correctAnswers: [
          'Проектной документацией или документацией на техническое перевооружение',
        ],
      },
      {
        code: '63648204',
        text:
          'Какими светильниками разрешается пользоваться на территории резервуарного парка?',
        answers: [
          'Светильниками напряжением не выше 42 В',
          'Взрывозащищенными переносными светильниками (аккумуляторными и батарейными)',
          'Стационарными светодиодными светильниками напряжением 24 В',
          'Переносными светильниками напряжением 36 В',
        ],
        correctAnswers: [
          'Взрывозащищенными переносными светильниками (аккумуляторными и батарейными)',
        ],
      },
      {
        code: '63648205',
        text:
          'Какой максимальный уклон должны иметь горизонтальные горные выработки, по которым проводится перевозка людей и грузов локомотивами при благоприятных горногеологических условиях?',
        answers: ['5%', '10%', '15%', '50%'],
        correctAnswers: ['5%'],
      },
      {
        code: '63648206',
        text:
          'Каким документом подтверждается выполнение работ по ликвидации скважин?',
        answers: [
          'Протоколом, составленным по факту проведения работ',
          'Техническим отчетом с результатами исследований по проверке надежности этих работ и выводами о непригодности скважины к ее дальнейшей безопасной эксплуатации',
          'Актом на ликвидацию скважины',
        ],
        correctAnswers: ['Актом на ликвидацию скважины'],
      },
      {
        code: '63648207',
        text:
          'На каком расстоянии и каким образом следует устанавливать емкость с горячим нефтепродуктом для обработки скважины?',
        answers: [
          'На расстоянии не более 5 м от устья скважины со стороны подъездных путей',
          'На расстоянии не менее 30 м от обваловки скважины с видимой стороны устья',
          'На расстоянии не менее 10 м от устья скважины с подветренной стороны',
          'На расстоянии не более 15 м от устья скважины с наветренной стороны',
        ],
        correctAnswers: [
          'На расстоянии не менее 10 м от устья скважины с подветренной стороны',
        ],
      },
      {
        code: '63648208',
        text:
          'Кто принимает решение о возможности эксплуатации (продлении срока эксплуатации) технологического трубопровода, выработавшего срок службы или при превышении допустимого количества циклов нагрузки?',
        answers: [
          'Руководитель регионального отделения Ростехнадзора',
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель монтажной организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63648209',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только из общих разделов',
          'Только из специальных разделов',
          'Из общих и специальных разделов',
          'Из общих разделов, введения и приложений',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63648210',
        text:
          'Разрешается ли последовательно включать в заземляющее устройство несколько заземляемых объектов?',
        answers: [
          'Разрешается по согласованию с главным энергетиком организации',
          'Разрешается',
          'Разрешается в исключительных случаях и по согласованию с территориальным органом Ростехнадзора',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63648211',
        text:
          'При какой температуре конца деформации допускается не проводить термическую обработку гнутых участков труб после горячей гибки для углеродистых и низколегированных сталей и для аустенитных сталей?',
        answers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
          'Не ниже 650 °C для углеродистых и низколегированных сталей и не ниже 800 °C для аустенитных сталей',
          'Не ниже 600 °C для углеродистых и низколегированных сталей и не ниже 750 °C для аустенитных сталей',
          'Не ниже 550 °C для углеродистых и низколегированных сталей и не ниже 700 °C для аустенитных сталей',
        ],
        correctAnswers: [
          'Не ниже 700 °C для углеродистых и низколегированных сталей и не ниже 850 °C для аустенитных сталей',
        ],
      },
      {
        code: '63648212',
        text:
          'Какие из перечисленных требований при проведении освидетельствования подземных технологических трубопроводов указаны неверно?',
        answers: [
          'При освидетельствовании проводится выборочный неразрушающий контроль качества металла сварных соединений и основного металла элементов трубопроводов',
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
          'При отсутствии средств инструментального контроля подземных технологических трубопроводов вскрытие проводят из расчета один участок на длину трубопровода не более 250 м',
          'При наличии на трассе подземного технологического трубопровода колодцев и камер допускается производить освидетельствование подземных трубопроводов в колодцах и камерах, по решению специалиста, ответственного за проведение освидетельствования трубопровода',
        ],
        correctAnswers: [
          'Для освидетельствования производят вскрытие и выемку грунта на отдельных участках длиной не менее 1,5 м каждый с последующим снятием изоляции',
        ],
      },
      {
        code: '63648213',
        text:
          'Что из перечисленного допускается при монтаже технологического трубопровода? Выберите 2 варианта ответа.',
        answers: [
          'Выравнивание перекосов фланцевых соединений натяжением болтов (шпилек), а также применением клиновых прокладок',
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
          'Нагрузка на сварной стык до его полного остывания после сварки и термообработки (если она предусмотрена проектом) при сборке технологических трубопроводов под сварку',
        ],
        correctAnswers: [
          'Проверка работоспособности привода трубопроводной арматуры, имеющей механический или электрический привод, до передачи ее в монтаж',
          'Монтаж технологического трубопровода после установки и закрепления опорных конструкций и подвесок в соответствии с требованиями проекта',
        ],
      },
      {
        code: '63648214',
        text:
          'Как производится ликвидация скважины без эксплуатационной колонны в зависимости от горно-геологических условий вскрытого разреза?',
        answers: [
          'Путем установки в открытом стволе цементных мостов в интервалах залегания высоконапорных минерализованных вод с коэффициентом аномальности больше 1,1 и низкопродуктивных горизонтов не имеющих промышленного значения залежей углеводородов',
          'Путем установки цементных мостов во всех продуктивных горизонтах',
          'Путем установки в открытом стволе цементных мостов в интервалах залегания горизонтов с аномальным пластовым давлением',
        ],
        correctAnswers: [
          'Путем установки в открытом стволе цементных мостов в интервалах залегания высоконапорных минерализованных вод с коэффициентом аномальности больше 1,1 и низкопродуктивных горизонтов не имеющих промышленного значения залежей углеводородов',
        ],
      },
      {
        code: '63648215',
        text:
          'С какой периодичностью проводятся проверка износа рельсов и нивелирование профиля откаточных путей?',
        answers: [
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63648216',
        text:
          'Что необходимо предпринять в скважинах, эксплуатирующих два и более горизонта с разными пластовыми давлениями?',
        answers: [
          'Следует отрабатывать горизонты последовательно',
          'Следует провести необходимые разобщения этих горизонтов',
          'Следует изолировать малопродуктивный горизонт',
        ],
        correctAnswers: [
          'Следует провести необходимые разобщения этих горизонтов',
        ],
      },
      {
        code: '63648217',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие трудногорючие и негорючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 6 лет',
          'Не реже одного раза в 8 лет',
          'Не реже одного раза в 3 года',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63648218',
        text:
          'Каким образом должно осуществляться управление запорной арматурой скважины, оборудованной под нагнетание пара или горячей воды?',
        answers: [
          'Только дистанционно',
          'Вручную с использованием средств защиты',
          'Дистанционно, но с возможностью ручного управления с применением средств защиты',
        ],
        correctAnswers: ['Только дистанционно'],
      },
      {
        code: '63648219',
        text:
          'Что из перечисленного не осуществляется при техническом освидетельствовании технологических трубопроводов?',
        answers: [
          'Измерение толщины стенок элементов технологического трубопровода',
          'Проведение ревизии воротников фланцев внутренним осмотром (при разборке трубопровода) либо измерением толщины неразрушающими методами контроля',
          'Проведение радиографического или ультразвукового контроля сварных стыков',
          'Осуществляется все перечисленное',
        ],
        correctAnswers: ['Осуществляется все перечисленное'],
      },
      {
        code: '63648220',
        text:
          'На каком минимальном расстоянии от емкости с горячим нефтепродуктом должна располагаться установка для подогрева?',
        answers: ['15 м', '10 м', '25 м', '20 м'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63648221',
        text:
          'Что из перечисленного является недопустимым на территории предприятия, имеющего в своем составе взрывопожароопасные производства?',
        answers: [
          'Только наличие природных оврагов, выемок, низин',
          'Только траншейная и наземная прокладка трасс трубопроводов со сжиженными горючими газами, легковоспламеняющимися и горючими жидкостями в искусственных или естественных углублениях',
          'Только устройство открытых траншей, котлованов, приямков, в которых возможно скопление взрывопожароопасных паров и газов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63648222',
        text:
          'На основании какого документа осуществляются работы по строительству, эксплуатации и ремонту подземных скважин?',
        answers: [
          'На основании плана работ',
          'На основании технологической инструкции',
          'На основании распоряжения руководителя работ',
          'На основании наряда',
        ],
        correctAnswers: ['На основании наряда'],
      },
      {
        code: '63648223',
        text:
          'Чем может осуществляться промывка и продувка технологических трубопроводов?',
        answers: [
          'Промывка - маслом, продувка - паром',
          'Промывка - водой, продувка - сжатым воздухом',
          'Промывка - химическими реагентами, продувка - инертным газом',
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
        correctAnswers: [
          'Любой средой из перечисленных в соответствии с указаниями проекта',
        ],
      },
      {
        code: '63648224',
        text:
          'На какое значение давления должна быть опрессована нагнетательная система после сборки до начала закачки?',
        answers: [
          'На ожидаемое давление закачки',
          'На расчетное давление',
          'На полуторакратное рабочее давление',
          'На максимальное давление',
          'На двукратное рабочее давление',
        ],
        correctAnswers: ['На полуторакратное рабочее давление'],
      },
      {
        code: '63648225',
        text:
          'Представители каких служб входят в состав комиссии, созданной пользователем недр (или его представителем) для оформления комплекта документов на ликвидацию скважины?',
        answers: [
          'Представители геологической службы, главного инженера, службы промышленной и экологической безопасности, представители территориального органа Ростехнадзора',
          'Представители геологической службы, службы бурения, главного инженера, службы промышленной и экологической безопасности, дополнительно в комиссию могут привлекаться необходимые специалисты (геолог, экономист, главный бухгалтер)',
          'Представители службы бурения, главного инженера, службы промышленной и экологической безопасности, дополнительно в комиссию могут привлекаться необходимые специалисты (геолог, экономист, главный бухгалтер), представители территориального органа Росприроднадзора',
        ],
        correctAnswers: [
          'Представители геологической службы, службы бурения, главного инженера, службы промышленной и экологической безопасности, дополнительно в комиссию могут привлекаться необходимые специалисты (геолог, экономист, главный бухгалтер)',
        ],
      },
      {
        code: '63648226',
        text:
          'Кем определяется степень разделения материальных сред и меры обеспечения взрывобезопасности на всех стадиях процесса?',
        answers: [
          'Разработчиками процесса и проекта',
          'Разработчиком процесса',
          'Разработчиком проекта',
          'Степень разделения определяется заказчиком в задании на проектирование, а меры взрывобезопасности - разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63648227',
        text: 'Чем завод-изготовитель должен оснащать фонтанную арматуру?',
        answers: [
          'Устройством, обеспечивающим установку и снятие манометра при наличии давления в арматуре',
          'Регулируемыми дросселями с ручным управлением',
          'Обратными и шаровыми клапанами с ручным управлением, трехходовым краном для замены манометров',
          'Дросселями с ручным, а по требованию заказчика - с дистанционным управлением, запорной арматурой с дистанционным и/или ручным управлением',
          'Запорной арматурой с дистанционным управлением',
        ],
        correctAnswers: [
          'Дросселями с ручным, а по требованию заказчика - с дистанционным управлением, запорной арматурой с дистанционным и/или ручным управлением',
        ],
      },
      {
        code: '63648228',
        text:
          'Допускаются ли вибрация и гидравлические удары в нагнетательных коммуникациях?',
        answers: [
          'Не допускаются',
          'Допускаются в пределах, установленных в планах работ',
          'Допускаются при наличии в системе компенсаторов',
          'Допускаются',
        ],
        correctAnswers: [
          'Допускаются в пределах, установленных в планах работ',
        ],
      },
      {
        code: '63648229',
        text:
          'В каких из перечисленных случаев производится консервация скважин в процессе бурения?',
        answers: [
          'В случае недостаточной изученности геологического разреза скважин',
          'В случае экономической целесообразности',
          'В случае несоответствия фактических геолого-технических условий проектным',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'В случае несоответствия фактических геолого-технических условий проектным',
        ],
      },
      {
        code: '63648230',
        text:
          'При каких условиях проводится установка флюидоупорных экранов, покрышек и цементных мостов, направленных на ликвидацию МКД, ликвидацию и предупреждение возникновения МПП, восстановление герметичности геологического разреза?',
        answers: [
          'Проводится под избыточным давлением',
          'Проводится только после согласования этих работ с природоохранными организациями',
          'При всех перечисленных условиях',
        ],
        correctAnswers: ['Проводится под избыточным давлением'],
      },
      {
        code: '63648231',
        text:
          'С какой целью насосы, перекачивающие сернистую нефть, должны быть заполнены перекачиваемой жидкостью?',
        answers: [
          'С целью постоянной готовности к пуску',
          'С целью избегания образования пирофорных отложений',
          'С целью предотвращения попадания воды в насос',
          'С целью равномерной подачи жидкости',
        ],
        correctAnswers: ['С целью избегания образования пирофорных отложений'],
      },
      {
        code: '63648232',
        text:
          'Где хранятся все материалы по ликвидированной скважине, включая подписанный сторонами акт на ликвидацию?',
        answers: [
          'У пользователя недр',
          'В территориальных органах Росприроднадзора',
          'В территориальных органах Ростехнадзора',
        ],
        correctAnswers: ['У пользователя недр'],
      },
      {
        code: '63648233',
        text:
          'В каком положении должны находиться задвижки на всасывающем и нагнетательном продуктопроводах при необходимости сдвига поршня парового насоса с мертвого положения вручную?',
        answers: [
          'На всасывающем продуктопроводе - в закрытом положении, на нагнетательном - в открытом',
          'На всасывающем продуктопроводе - в открытом положении, на нагнетательном - в закрытом',
          'На всасывающем и нагнетательном продуктопроводах - в закрытом положении',
          'На всасывающем и нагнетательном продуктопроводах - в открытом положении',
        ],
        correctAnswers: [
          'На всасывающем и нагнетательном продуктопроводах - в закрытом положении',
        ],
      },
      {
        code: '63648234',
        text:
          'Каким образом необходимо производить ликвидацию гидратных пробок в газопроводах?',
        answers: [
          'Снизить давление в газопроводе до рабочего и продуть его паром',
          'Снизить давление в газопроводе до расчетного и продуть его инертным газом',
          'Снизить давление в газопроводе до атмосферного и произвести подогрев участков газопровода паром',
          'Не снижая давления осуществить подогрев участков газопровода',
        ],
        correctAnswers: [
          'Снизить давление в газопроводе до атмосферного и произвести подогрев участков газопровода паром',
        ],
      },
      {
        code: '63648235',
        text:
          'Кто дает разрешение на пуск компрессора после ревизии, ремонта и длительного вынужденного отключения (кроме резервного)?',
        answers: [
          'Руководитель объекта (мастер, начальник компрессорной станции) или механик',
          'Главный энергетик организации',
          'Технический руководитель организации',
          'Главный инженер или главный механик компрессорной станции',
        ],
        correctAnswers: [
          'Руководитель объекта (мастер, начальник компрессорной станции) или механик',
        ],
      },
      {
        code: '63648236',
        text:
          'В какой момент следует производить загрузку термореактора магнием?',
        answers: [
          'Не ранее чем за час до спуска термореактора в скважину',
          'Непосредственно перед спуском термореактора в скважину',
          'Не позднее чем за 2 часа до спуска термореактора в скважину',
          'За день до начала работ в лаборатории газоспасательной службы',
        ],
        correctAnswers: [
          'Непосредственно перед спуском термореактора в скважину',
        ],
      },
      {
        code: '63648237',
        text:
          'Какое из перечисленных положений нарушает требования, предъявляемые к прокладке трубопроводов на объектах нефтегазодобычи?',
        answers: [
          'Трубопроводы не должны иметь фланцевых или других разъемных соединений',
          'Материал фланцев, конструкция уплотнения применяются в соответствии с нормативными техническими документами, устанавливающими требования к технологическим трубопроводам с учетом условий эксплуатации',
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
          'При прокладке трубопроводов через строительные конструкции зданий и другие препятствия принимаются меры, исключающие возможность передачи дополнительных нагрузок на трубы',
        ],
        correctAnswers: [
          'Прокладка трубопроводов должна обеспечивать максимально возможную протяженность коммуникаций, исключать провисания и образование застойных зон',
        ],
      },
      {
        code: '63648238',
        text:
          'В соответствии с каким документом проводится перед пуском проверка исправности комплекса УПН?',
        answers: [
          'В соответствии с планом, утвержденным техническим руководителем организации',
          'В соответствии с технологическим регламентом',
          'В соответствии с Правилами безопасности в нефтяной и газовой промышленности',
          'В соответствии с приказом, изданным техническим руководителем организации',
        ],
        correctAnswers: [
          'В соответствии с планом, утвержденным техническим руководителем организации',
        ],
      },
      {
        code: '63648239',
        text:
          'Какие требования предъявляются к сплошным земляным валам, которыми должен ограждаться каждый одностенный резервуар?',
        answers: [
          'Высота вала должна быть не менее 1 м',
          'Вал должен быть рассчитан на гидростатическое давление разлившейся из резервуара жидкости',
          'Расстояние между валом и емкостью должно быть не менее 5 м',
          'Поверхность вала должна быть оборудована непроницаемым покрытием',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Вал должен быть рассчитан на гидростатическое давление разлившейся из резервуара жидкости',
        ],
      },
      {
        code: '63648240',
        text:
          'Какие процедуры производятся обслуживающим персоналом перед вводом в эксплуатацию участка трубопровода или всего трубопровода?',
        answers: [
          'Очистка полости, испытание на прочность и герметичность',
          'Продувка и неразрушающий контроль',
          'Осмотр наружной поверхности',
          'Испытание на коррозионную стойкость',
          'Все перечисленные процедуры',
        ],
        correctAnswers: [
          'Очистка полости, испытание на прочность и герметичность',
        ],
      },
      {
        code: '63648241',
        text:
          'На основании какого документа разрешается проведение работ в замкнутом пространстве?',
        answers: [
          'На основании письменного разрешения руководителя работ',
          'На основании наряда-допуска',
          'На основании утвержденного плана работ',
        ],
        correctAnswers: ['На основании наряда-допуска'],
      },
      {
        code: '63648242',
        text:
          'Какие способы соединений труб используются для обвязки скважины и аппаратуры, а также для газопроводов при фонтанной и газлифтной эксплуатации скважин?',
        answers: [
          'Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры',
          'Только сварные соединения',
          'Соединения на хомутах и сварные соединения',
          'Фланцевые и резьбовые соединения',
          'Резьбовые соединения с последующей изоляцией',
        ],
        correctAnswers: [
          'Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры',
        ],
      },
      {
        code: '63648243',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на линейных объектах магистрального трубопроводного транспорта нефти, газа и газового конденсата, нефтепродуктопроводов',
          'На ведение газоопасных, огневых и ремонтных работ на стационарных автомобильных газозаправочных станциях, автомобильных газонаполнительных компрессорных станциях и криогенных автозаправочных станциях газомоторного топлива',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63648244',
        text:
          'В каких из перечисленных случаях не производятся диагностические работы с целью возможности продления срока (назначенного ресурса) безопасной эксплуатации технологических трубопроводов в пределах остаточного срока службы (ресурса)?',
        answers: [
          'Если трубопроводы отработали срок службы или выработали допустимое число циклов нагружения',
          'Если трубопроводы находились в эксплуатации более десяти лет (для всех трубопроводов, не имеющих сведений о сроке службы)',
          'Если трубопроводы находились на консервации более одного года',
          'Если трубопроводы не имеют паспортов и (или) требуется их восстановление',
        ],
        correctAnswers: [
          'Если трубопроводы находились на консервации более одного года',
        ],
      },
      {
        code: '63648245',
        text:
          'Каким требованиям должно отвечать помещение технологического блока установки гидропоршневых и струйных насосов?',
        answers: [
          'Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую четырехкратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 1 °С, уровень шума не более 95 дБ, скорость вибрации не более 5 мм/с',
          'Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую восьмикратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 5 °С, уровень шума не более 80 дБ, скорость вибрации не более 2 мм/с',
          'Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую двукратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 3 °С, уровень шума не более 90 дБ, скорость вибрации не более 3 мм/с',
        ],
        correctAnswers: [
          'Помещение должно иметь постоянную принудительную вентиляцию, обеспечивающую восьмикратный воздухообмен по полному внутреннему объему помещения в течение часа, температуру в блоках не ниже 5 °С, уровень шума не более 80 дБ, скорость вибрации не более 2 мм/с',
        ],
      },
      {
        code: '63648246',
        text:
          'Какие требования предъявляются к размещению передвижных насосных установок у устья скважины?',
        answers: [
          'Передвижные насосные установки необходимо располагать не менее чем за 10 м от устья скважины',
          'Расстояние между передвижными насосными установками должно быть не менее 1 м',
          'Вспомогательные установки (компрессор, парогенераторная установка и др.) должны располагаться на расстоянии не менее 25 м от устья скважины',
          'Агрегаты следует устанавливать кабинами от устья скважины',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63648247',
        text:
          'Кем выполняется вскрытие перемычек и разгазирование изолированных горных выработок?',
        answers: [
          'Эксплуатирующей организацией',
          'Профессиональным аварийно-спасательным формированием',
          'Службой аэрологической безопасности',
          'Монтажной организацией',
        ],
        correctAnswers: [
          'Профессиональным аварийно-спасательным формированием',
        ],
      },
      {
        code: '63648248',
        text:
          'Разрешается ли исследование разведочных и эксплуатационных скважин в случае отсутствия утилизации жидкого продукта?',
        answers: [
          'Запрещается во всех случаях',
          'Разрешается',
          'Разрешается по согласованию с противофонтанной службой',
          'Разрешается по решению территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Запрещается во всех случаях'],
      },
      {
        code: '63648249',
        text:
          'Какое из перечисленных требований к испытанию технологических трубопроводов указано верно? Выберите 2 варианта ответа.',
        answers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Испытания на прочность и плотность проводят при опорожненном технологическом трубопроводе от содержимого',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
          'Допускается использование запорной арматуры для отключения испытываемого трубопровода',
        ],
        correctAnswers: [
          'В технически обоснованных случаях для технологических трубопроводов с номинальным давлением не более 50 МПа допускается замена гидравлического испытания на пневматическое при условии контроля этого испытания методом акустической эмиссии',
          'Допускается проводить испытание технологического трубопровода отдельными участками, при этом разбивку на участки проводит монтажная организация по согласованию с эксплуатирующей организацией и (или) проектная организация',
        ],
      },
      {
        code: '63648250',
        text:
          'С какой периодичностью осуществляется контроль мест ведения работ в горных выработках нефтяной шахты руководителем или заместителем руководителя структурного подразделения, за которым закреплены эти горные выработки?',
        answers: [
          'Ежесуточно',
          'Еженедельно',
          'Каждые 10 часов',
          'Каждые 3 часа',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63648251',
        text:
          'В какой документации должны быть приведены способы и средства, исключающие выход параметров за установленные пределы?',
        answers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
          'Только в технологическом регламенте',
          'Только в исходных данных на проектирование',
          'Только в проектной документации',
        ],
        correctAnswers: [
          'В исходных данных на проектирование, проектной документации, технологическом регламенте на производство продукции',
        ],
      },
      {
        code: '63648252',
        text:
          'Какие требования предъявляются к заземлению кондуктора (технической колонны) и рамы станка-качалки?',
        answers: [
          'Кондуктор и рама станка-качалки должны быть связаны не менее чем двумя заземляющими стальными проводниками, приваренными в разных местах к кондуктору и раме',
          'В качестве заземляющих проводников может применяться сталь любых профилей. Сечение прямоугольного проводника должно быть не менее 48 мм², толщина стенок угловой стали не менее 4 мм, диаметр круглых заземлителей - 10 мм',
          'Заземляющие проводники, соединяющие раму с качалкой, должны заглубляться в землю не менее чем на 0,5 м',
          'Соединения заземляющих проводников должны быть доступны для осмотра',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63648253',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Порядок действий в случае аварий на объекте',
          'Первоочередные действия при получении сигнала об авариях на объекте',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Состав и дислокация сил и средств',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63648254',
        text:
          'Что требуется для остановки технических устройств, обеспечивающих проветривание и кондиционирование горных выработок, водоснабжение, откачку воды, спуск и подъем работников, работу МФСБ для выполнения ремонтных работ?',
        answers: [
          'Письменное разрешение технического руководителя обособленного структурного подразделения',
          'Письменное или устное распоряжение технического руководителя обособленного структурного подразделения',
          'Письменное разрешение руководителя эксплуатирующей организации',
          'Письменное разрешение технического руководителя обособленного структурного подразделения, согласованное с Ростехнадзором',
        ],
        correctAnswers: [
          'Письменное разрешение технического руководителя обособленного структурного подразделения',
        ],
      },
      {
        code: '63648255',
        text:
          'Какое положение не соответствует установленным требованиям к электрообеспечению и электрооборудованию взрывоопасных технологических схем?',
        answers: [
          'Прокладку кабелей по территории предприятий и установок разрешается выполнять открыто по эстакадам, в галереях и на кабельных конструкциях технологических эстакад',
          'Размещать кабельные сооружения на технологических эстакадах следует с учетом обеспечения возможности проведения монтажа и демонтажа трубопроводов в соответствии с требованиями нормативно-технических документов по устройству электроустановок',
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
          'Кабели, прокладываемые по территории технологических установок и производств, должны иметь изоляцию и оболочку из материалов, не распространяющих горение',
        ],
        correctAnswers: [
          'Запрещается прокладка кабелей в каналах, засыпанных песком, и траншеях',
        ],
      },
      {
        code: '63648256',
        text:
          'Чем должны быть оснащены парогенераторные и водонагревательные установки?',
        answers: [
          'Средствами сигнализации для обнаружения нарушения технологического процесса',
          'Системами автоматического оповещения в случаях нарушения технологического процесса и пенного пожаротушения',
          'Системами регистрации параметров и локализации возгорания в случаях нарушения технологического процесса и пенного пожаротушения',
          'Приборами контроля и регулирования процессов приготовления и закачки теплоносителя, средствами по прекращению подачи топливного газа',
          'Контрольно-измерительной аппаратурой для регулирования расхода',
        ],
        correctAnswers: [
          'Приборами контроля и регулирования процессов приготовления и закачки теплоносителя, средствами по прекращению подачи топливного газа',
        ],
      },
      {
        code: '63648257',
        text:
          'Каким образом организуется проветривание тупиковых горных выработок?',
        answers: [
          'Расстояние от конца вентиляционных труб до тупиковой горной выработки, проветриваемой вентиляторной установкой, должно быть не более 30 м',
          'Суммарная концентрация углеводородных газов не должна превышать 15% от НКПР смеси или 0,5% (по объему)',
          'Струя воздуха, исходящая из тупиковой горной выработки, должна попадать в горные выработки со свежей струей воздуха, проветривающей горные выработки, в которых проводятся закачка теплоносителя в пласт',
          'Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводятся закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин',
        ],
        correctAnswers: [
          'Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводятся закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин',
        ],
      },
      {
        code: '63648258',
        text:
          'За счет чего должна исключаться возможность образования взрывоопасных смесей внутри аппаратов и трубопроводов?',
        answers: [
          'За счет технологических режимов ведения работ и конструктивного исполнения агрегатов и установок',
          'За счет производственного контроля и применения сертификационного оборудования в организации',
          'За счет использования пожаро- и взрывозащищенного оборудования',
          'За счет всего перечисленного',
        ],
        correctAnswers: [
          'За счет технологических режимов ведения работ и конструктивного исполнения агрегатов и установок',
        ],
      },
      {
        code: '63648259',
        text: 'Кто имеет право на проведение диагностики резервуаров?',
        answers: [
          'Специализированная организация',
          'Организация - собственник резервуара',
          'Ростехнадзор',
          'Любая подрядная организация, выигравшая тендер',
        ],
        correctAnswers: ['Специализированная организация'],
      },
      {
        code: '63648260',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
          'В целях определения действий подразделений организации при возникновении аварии',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях определения действий персонала при ликвидации последствий аварий',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63648261',
        text:
          'С какой периодичностью проверяется состояние горных выработок, служащих запасными выходами, лицами, назначенными организационно-распорядительным документом обособленного структурного подразделения?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в месяц',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63648262',
        text:
          'По истечении какого времени после того, как давление будет снижено до расчетного, допускается окончательный осмотр специальными лицами технологического трубопровода при проведении пневматических испытаний?',
        answers: [
          'Через 5 минут',
          'Через 10 минут',
          'Через 1 минуту',
          'Через 8 минут',
        ],
        correctAnswers: ['Через 10 минут'],
      },
      {
        code: '63648263',
        text: 'Какое устройство должен иметь электродегидратор?',
        answers: [
          'Сигнализирующее о понижении уровня продукта в аппарате',
          'Сигнализирующее о превышении давления',
          'Регулирующее уровень продукта в аппарате',
          'Отключающее подачу продукта в аппарат при отключении электроэнергии',
          'Отключающее напряжение при понижении уровня продукта в аппарате',
        ],
        correctAnswers: [
          'Отключающее напряжение при понижении уровня продукта в аппарате',
        ],
      },
      {
        code: '63648264',
        text:
          'В каких случаях разрешается одновременное автоматическое переключение задвижек в резервуарном парке?',
        answers: [
          'Только при максимальном уровне продукта в резервуаре',
          'Только при отказе в работе одной из задвижек',
          'Только при условии защиты трубопроводов от превышения давления',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Только при условии защиты трубопроводов от превышения давления',
        ],
      },
      {
        code: '63648265',
        text:
          'Что необходимо устанавливать вдоль трассы подземного технологического трубопровода сжиженного газа?',
        answers: [
          'Опознавательные знаки через 50 м на прямых участках трубопровода и на каждом его повороте',
          'Опознавательные знаки через 25 м на прямых участках трубопровода',
          'Опознавательные знаки через 100 м на прямых участках трубопровода и на каждом его повороте',
        ],
        correctAnswers: [
          'Опознавательные знаки через 50 м на прямых участках трубопровода и на каждом его повороте',
        ],
      },
      {
        code: '63648266',
        text:
          'Какие условия должны выполняться при пересечении подземных промысловых трубопроводов с автомобильными и железными дорогами?',
        answers: [
          'Должны быть установлены предупредительные знаки',
          'Трубопроводы должны быть уложены в защитные кожухи из стальных или железобетонных труб',
          'Высота перехода над дорогами должна быть не менее 10 м',
          'Все перечисленные условия',
        ],
        correctAnswers: [
          'Трубопроводы должны быть уложены в защитные кожухи из стальных или железобетонных труб',
        ],
      },
      {
        code: '63648267',
        text:
          'Как должен прокладываться силовой кабель от станции управления к устью скважины при ее эксплуатации погружным электронасосом?',
        answers: [
          'На эстакаде или на специальных стойках-опорах',
          'В заглубленных лотках',
          'Только на специальных стойках-опорах',
          'На бетонных подкладках или на специальных стойках',
          'Только на эстакаде',
        ],
        correctAnswers: ['На эстакаде или на специальных стойках-опорах'],
      },
      {
        code: '63648268',
        text:
          'Что и с какой целью необходимо осмотреть после каждой остановки компрессора?',
        answers: [
          'Недоступные к осмотру во время его работы движущиеся детали и убедиться в их целостности',
          'Недоступные к осмотру во время его работы движущиеся детали и убедиться в отсутствии превышения допустимых температур нагрева',
          'Недоступные к осмотру во время его работы движущиеся детали и проверить уровень масла',
          'Недоступные к осмотру во время его работы движущиеся детали и проверить их крепление',
          'Недоступные к осмотру во время его работы движущиеся детали и проверить их ограждения',
        ],
        correctAnswers: [
          'Недоступные к осмотру во время его работы движущиеся детали и убедиться в отсутствии превышения допустимых температур нагрева',
        ],
      },
      {
        code: '63648269',
        text:
          'В каком порядке осуществляется допуск подрядных организаций на опасные производственные объекты нефтегазодобывающих производств?',
        answers: [
          'В соответствии с Положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств',
          'В соответствии с приказом, утвержденным руководителем организации, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств',
          'В соответствии с производственной инструкцией, устанавливающей требования к организации работ утвержденной организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств',
          'В соответствии с производственным заданием, выданным руководителем организации, эксплуатирующей опасные производственные объекты',
        ],
        correctAnswers: [
          'В соответствии с Положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств',
        ],
      },
      {
        code: '63648270',
        text:
          'Какие из перечисленных требований, которые необходимо учитывать при проведении пневматических испытаний технологических трубопроводов на прочность и плотность, указаны неверно?',
        answers: [
          'Пневматическое испытание должно проводиться только в условиях освещенности площадки проведения работ, достаточной для наблюдения за испытываемым оборудованием',
          'Поддержание положительной (выше 5 °C) температуры испытания, при температурах ниже 5 °C проведение испытания допускается при уровне такой температуры не менее чем на 15 °C выше температуры, для которой определены критерии пластичности',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме не менее 10% для всех кольцевых сварных швов',
        ],
        correctAnswers: [
          'Наличие положительных результатов радиографического или ультразвукового контроля в объеме 50% для всех продольных сварных швов',
        ],
      },
      {
        code: '63648271',
        text:
          'С какой периодичностью проводится ревизия и наладка электрической части и аппаратуры автоматизированной подъемной установки после ввода ее в эксплуатацию?',
        answers: [
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 1,5 года',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 6 месяцев'],
      },
      {
        code: '63648272',
        text:
          'Какая из перечисленных мер должна предусматриваться для блоков технологической системы по максимальному снижению взрывоопасности?',
        answers: [
          'Предотвращение взрывов внутри технологического оборудования',
          'Использование вентиляционных систем для исключения возможности взрывов и пожаров',
          'Использование эффективных систем пожаротушения',
          'Все перечисленные меры',
        ],
        correctAnswers: [
          'Предотвращение взрывов внутри технологического оборудования',
        ],
      },
      {
        code: '63648273',
        text:
          'Каким образом необходимо производить монтаж и демонтаж лубрикатора?',
        answers: [
          'С использованием гидросъемника при открытой центральной задвижке',
          'С использованием мачты при закрытой центральной задвижке',
          'С использованием мачты и канатной техники',
          'С использованием мачты при открытой центральной задвижке',
          'С использованием гидродомкрата расчетной грузоподъемности при закрытой центральной задвижке',
        ],
        correctAnswers: [
          'С использованием мачты при закрытой центральной задвижке',
        ],
      },
      {
        code: '63648274',
        text:
          'Какие требования предъявляются к спуску забойного электронагревателя в скважину?',
        answers: [
          'Операции должны быть механизированы, с использованием специального лубрикатора при герметизированном устье',
          'Операции должны выполняться дистанционно, с использованием специального герметизатора при герметизированном устье',
          'Операции должны быть автоматизированы, должны выполняться с использованием специального обратного клапана',
          'Операции должны выполняться дистанционно',
        ],
        correctAnswers: [
          'Операции должны быть механизированы, с использованием специального лубрикатора при герметизированном устье',
        ],
      },
      {
        code: '63648275',
        text:
          'Что запрещается выполнять обходчикам при профилактических осмотрах нефтегазопроводов?',
        answers: [
          'Подходить к станции катодной защиты на расстояние ближе чем на 10 м',
          'Спускаться в колодцы и другие углубления на территории охранной зоны',
          'Выходить из операторской без средств индивидуальной защиты органов дыхания',
          'Передвигаться в одиночку по территории',
        ],
        correctAnswers: [
          'Спускаться в колодцы и другие углубления на территории охранной зоны',
        ],
      },
      {
        code: '63648276',
        text:
          'Что должно устанавливаться для обслуживания тормоза станка-качалки?',
        answers: [
          'Площадка с ограждением',
          'Эстакада',
          'Лестница туннельного типа',
          'Съемное ограждение с креплением, предотвращающим несанкционированный доступ к тормозу',
          'Все перечисленное',
        ],
        correctAnswers: ['Площадка с ограждением'],
      },
      {
        code: '63648277',
        text:
          'Кем осуществляется подача напряжения на электрообессоливающую установку УПН?',
        answers: [
          'Дежурным электроперсоналом по указанию начальника установки или лица, его заменяющего',
          'Начальником установки или лицом, его заменяющим',
          'Главным энергетиком',
          'Главным инженером',
        ],
        correctAnswers: [
          'Дежурным электроперсоналом по указанию начальника установки или лица, его заменяющего',
        ],
      },
      {
        code: '63648278',
        text:
          'Какова функция запорных устройств на выкидных трубопроводах, непосредственно соединенных со скважинами?',
        answers: [
          'Образование вихрей и создание условий для выпадения солей и механических примесей',
          'Регулирование давления на выкиде фонтанной скважины',
          'Перекрытие потока жидкости из скважины при аварийной разгерметизации нефтегазоконденсатопровода',
          'Изменение дросселированием расхода пластовой жидкости или газа',
        ],
        correctAnswers: [
          'Перекрытие потока жидкости из скважины при аварийной разгерметизации нефтегазоконденсатопровода',
        ],
      },
      {
        code: '63648279',
        text:
          'Какими техническими средствами должна быть обеспечена бригада для определения концентрации паров серной кислоты и серного ангидрида?',
        answers: [
          'Индивидуальными счетчиками концентрации паров',
          'Газоанализаторами',
          'Газосигнализаторами',
          'Газоиндикаторами',
        ],
        correctAnswers: ['Газоанализаторами'],
      },
      {
        code: '63648280',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I класса опасности',
          'Только для ОПО II класса опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63648281',
        text:
          'Оценку каких параметров необходимо произвести в проектной документации при разработке технологического процесса?',
        answers: [
          'Только оценку эффективности технических средств противоаварийной защиты, обеспечивающих взрывобезопасность технологических блоков и в целом всей технологической схемы',
          'Только оценку энергетического уровня каждого технологического блока и определение категории его взрывоопасности',
          'Только оценку эффективности и надежности мер, направленных на обеспечение взрывобезопасности каждого технологического блока',
          'В проектной документации производится оценка всех перечисленных параметров',
        ],
        correctAnswers: [
          'В проектной документации производится оценка всех перечисленных параметров',
        ],
      },
      {
        code: '63648282',
        text:
          'С какой части ствола скважины ликвидируются межпластовые перетоки (далее - МПП) и межколонные давления (далее - МКД) пластовых флюидов в процессе проведения работ по ликвидации?',
        answers: [
          'Начиная с верхней части ствола скважины',
          'Начиная с нижней части ствола скважины',
          'Не регламентируется',
        ],
        correctAnswers: ['Начиная с нижней части ствола скважины'],
      },
      {
        code: '63648283',
        text:
          'Кем осуществляется выбор необходимых и достаточных условий организации реакционных процессов, протекающих с возможным образованием промежуточных перекисных соединений, побочных взрывоопасных продуктов осмоления и уплотнения (полимеризации, поликонденсации) и др., нестабильных веществ с вероятным их отложением в аппаратуре и трубопроводах?',
        answers: [
          'Заказчиком в задании на проектирование',
          'Разработчиками процесса и проекта',
          'Разработчиком процесса',
          'Разработчиком проекта',
        ],
        correctAnswers: ['Разработчиком процесса'],
      },
      {
        code: '63648284',
        text:
          'Чем должны оснащаться технологические системы для обеспечения безопасности ведения технологических процессов?',
        answers: [
          'Только средствами противоаварийной защиты',
          'Только средствами автоматического регулирования',
          'Только средствами контроля за параметрами, определяющими взрывоопасность процесса',
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
        correctAnswers: [
          'Средствами контроля за параметрами, определяющими взрывоопасность процесса, с регистрацией показаний и предаварийной сигнализацией их значений, а также средствами автоматического регулирования и противоаварийной защиты, в том числе ПАЗ',
        ],
      },
      {
        code: '63648285',
        text: 'В каком случае проводниковые канаты допускаются к эксплуатации?',
        answers: [
          'При износе наружных проволок более 50% их высоты',
          'Если на 100 м длины каната закрытой конструкции обнаружено два обрыва наружных проволок',
          'При наличии спайки обрыва наружной проволоки в канате закрытой конструкции, вышедшей из замка',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'При наличии спайки обрыва наружной проволоки в канате закрытой конструкции, вышедшей из замка',
        ],
      },
      {
        code: '63648286',
        text:
          'В каких случаях сильфонные и линзовые компенсаторы технологических трубопроводов не подлежат отбраковке?',
        answers: [
          'Толщина стенки сильфона или линзы достигла расчетной величины, указанной в паспорте компенсатора',
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
          'В случае наработки компенсаторами расчетного числа циклов, указанного в документации завода-изготовителя',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'Толщина стенки сильфона достигла 0,75 мм при расчетной толщине сильфона менее 0,5 мм',
        ],
      },
      {
        code: '63648287',
        text:
          'Чему должна соответствовать документация по ведению горных работ и документация по ведению работ по добыче нефти?',
        answers: [
          'Проектной документации, а также горно-геологическим и горнотехническим условиям',
          'Инструкции по охране окружающей среды при строительстве скважин на нефть и газ',
          'Инструкции по производству маркшейдерских работ',
          'Техническим регламентам таможенного союза',
        ],
        correctAnswers: [
          'Проектной документации, а также горно-геологическим и горнотехническим условиям',
        ],
      },
      {
        code: '63648288',
        text: 'Кем должно обслуживаться электрооборудование установки?',
        answers: [
          'Электротехническим персоналом, имеющим соответствующую квалификацию и допуск к работе',
          'Эксплуатационным персоналом, прошедшим инструктаж',
          'Рабочим персоналом, обслуживающим установку, в присутствии представителя электротехнического персонала',
          'Звеном старшего оператора в присутствии мастера, имеющего соответствующую группу по электробезопасности',
          'Персоналом подрядной организации',
        ],
        correctAnswers: [
          'Электротехническим персоналом, имеющим соответствующую квалификацию и допуск к работе',
        ],
      },
      {
        code: '63648289',
        text:
          'С какой периодичностью и в каком объеме проводятся исследования эксплуатационных скважин на нефтегазодобывающих объектах?',
        answers: [
          'В соответствии с инструкцией по исследованию скважин, утвержденной Минприроды России',
          'Каждые полгода в полном объеме и ежемесячно в объеме, необходимом геологической службе организации',
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
        ],
        correctAnswers: [
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
        ],
      },
      {
        code: '63648290',
        text:
          'Какие меры безопасности должны быть выполнены перед началом продувки и испытанием трубопровода газом или воздухом?',
        answers: [
          'Необходимо определить опасные зоны и выставить наблюдающих',
          'Необходимо обозначить знаками опасные зоны, в которых запрещено находиться людям',
          'Необходимо установить видеокамеры в опасных зонах',
          'Необходимо удалить персонал в безопасную зону',
          'Необходимо установить заградительные сооружения вдоль трассы',
        ],
        correctAnswers: [
          'Необходимо обозначить знаками опасные зоны, в которых запрещено находиться людям',
        ],
      },
      {
        code: '63648291',
        text:
          'Какие требования предъявляются к месту хранения ящиков с пороховыми зарядами?',
        answers: [
          'Ящики должны храниться в специальном помещении на расстоянии не менее 30 м от устья скважины',
          'Ящики должны храниться во взрывозащищенной камере, запираемой на замок, расположенной на расстоянии не менее 25 м от устья скважины',
          'Ящики должны храниться на открытом воздухе под охраной ответственного лица на расстоянии не менее 10 м от устья скважины',
          'Ящики должны храниться в запираемом на замок помещении, расположенном на расстоянии не менее 50 м от устья скважины',
        ],
        correctAnswers: [
          'Ящики должны храниться в запираемом на замок помещении, расположенном на расстоянии не менее 50 м от устья скважины',
        ],
      },
      {
        code: '63648292',
        text:
          'Каким давлением проводится опрессовка межколонного пространства после установки верхнего моста при ликвидации скважин со спущенной эксплуатационной колонной?',
        answers: ['3 МПа', '5 МПа', '7 МПа'],
        correctAnswers: ['5 МПа'],
      },
      {
        code: '63648293',
        text: 'Где допускается расположение узла ввода теплоносителя?',
        answers: [
          'Только в самостоятельном помещении с отдельным входом с лестничной клетки или из невзрывопожароопасных производственных помещений',
          'Только в помещениях систем приточной вентиляции (в вентиляционной камере)',
          'Только в производственных помещениях, в которых предусматривается применение водяного или парового отопления',
          'Во всех указанных местах',
        ],
        correctAnswers: ['Во всех указанных местах'],
      },
      {
        code: '63648294',
        text:
          'Какие установлены сроки проведения освидетельствования технологических трубопроводов, транспортирующие токсичные, высокотоксичные вещества, органические теплоносители, воспламеняющиеся и горючие вещества при скорости коррозии более 0,5 мм/год?',
        answers: [
          'Не реже одного раза в 2 года',
          'Не реже одного раза в 6 лет',
          'Не реже одного раза в год',
          'Не реже одного раза в 4 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
    ],
    63651: [
      {
        code: '63651000',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Технологическими регламентами',
          'Организационно-распорядительными документами организации',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63651001',
        text:
          'Каким давлением производится испытание пневматической системы буровой установки на месте производства работ (после монтажа, ремонта)?',
        answers: [
          'После проведения испытаний пневматической системы буровой установки на заводе-изготовителе испытания на месте производства работ не проводятся',
          'Давление испытания должно быть 1,15 рабочего давления',
          'Давление испытания должно быть 1,5 рабочего давления',
          'Давление испытания должно быть 1,25 рабочего давления, но не менее чем на 0,3 МПа превышающим рабочее',
        ],
        correctAnswers: [
          'Давление испытания должно быть 1,25 рабочего давления, но не менее чем на 0,3 МПа превышающим рабочее',
        ],
      },
      {
        code: '63651002',
        text:
          'При каком превышении давления должны срабатывать предохранительные устройства насоса?',
        answers: [
          'При давлении, превышающем на 3% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок',
          'При давлении, превышающем на 20% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок',
          'При давлении, превышающем на 15% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок',
          'При давлении, превышающем на 10% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок',
        ],
        correctAnswers: [
          'При давлении, превышающем на 10% рабочее давление насоса, соответствующее диаметру установленных цилиндровых втулок',
        ],
      },
      {
        code: '63651003',
        text:
          'В каком исполнении должны быть агрегаты, применяемые во взрывопожароопасных зонах?',
        answers: [
          'Во взрывозащищенном',
          'В брызгозащищенном',
          'В пылевлагонепроницаемом',
          'Во взрывонепроницаемом',
        ],
        correctAnswers: ['Во взрывозащищенном'],
      },
      {
        code: '63651004',
        text:
          'Какие требования предъявляются к условиям установки подвесного и герметизирующего устройства потайной колонны?',
        answers: [
          'Требования не нормируются',
          'Требования к условиям установки подвесного и герметизирующего устройства потайной колонны (хвостовика) определяется расчетным путем при проектировании конструкции скважины',
          'Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин',
        ],
        correctAnswers: [
          'Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин',
        ],
      },
      {
        code: '63651005',
        text:
          'При каком условии рабочие бригады допускаются к выполнению специальных работ (передвижке буровой установки, монтажу мобильных буровых установок, ремонтным работам повышенной сложности)?',
        answers: [
          'При прохождении стажировки у опытных специалистов и проверке знаний по специальности и охране труда',
          'При прохождении дополнительного обучения и получении допуска к самостоятельной работе по основной и совмещаемой профессиям',
          'При прохождении стажировки у опытных специалистов и получении письменного разрешения на допуск к работам у технического руководителя предприятия',
          'При прохождении обучения, проводящегося по месту основной работы, и сдаче соответствующего экзамена',
        ],
        correctAnswers: [
          'При прохождении дополнительного обучения и получении допуска к самостоятельной работе по основной и совмещаемой профессиям',
        ],
      },
      {
        code: '63651006',
        text:
          'Каким документом устанавливаются требования к технологии и порядок проведения перфорации продуктивного пласта при сниженном уровне или в среде, отличающейся от установленных требований?',
        answers: [
          'Стандартами буровой организации, согласованными с противофонтанной службой (противофонтанной военизированной частью)',
          'Рабочим проектом на производство буровых работ',
          'Специальным планом, утвержденным организацией, эксплуатирующей ОПО (заказчиком), и согласованным ПАСФ',
        ],
        correctAnswers: [
          'Специальным планом, утвержденным организацией, эксплуатирующей ОПО (заказчиком), и согласованным ПАСФ',
        ],
      },
      {
        code: '63651007',
        text:
          'В каких случаях не устанавливается превенторная сборка при бурении скважин для разведки и добычи метана угольных месторождений (площадей, участков)?',
        answers: [
          'В случаях, предусмотренных в рабочем проекте',
          'По решению буровой организации',
          'В случае вскрытия изученного разреза с аномально низким пластовым давлением (далее - АНПД), представленного обводненными угольными пластами',
        ],
        correctAnswers: [
          'В случае вскрытия изученного разреза с аномально низким пластовым давлением (далее - АНПД), представленного обводненными угольными пластами',
        ],
      },
      {
        code: '63651008',
        text:
          'Каким давлением необходимо опрессовывать цементировочную головку?',
        answers: [
          'Давлением, в 1,25 раза превышающим ожидаемое рабочее давление',
          'Давлением, в 1,5 раза превышающим максимальное расчетное рабочее давление',
          'Давлением, в 1,75 раза превышающим ожидаемое рабочее давление',
          'Давлением, в 2 раза превышающим максимальное расчетное рабочее давление',
        ],
        correctAnswers: [
          'Давлением, в 1,5 раза превышающим максимальное расчетное рабочее давление',
        ],
      },
      {
        code: '63651009',
        text: 'В каком случае разрешается проводить спуско-подъемные операции?',
        answers: [
          'В случае неисправности спуско-подъемного оборудования и инструмента',
          'В случае скорости ветра, равной 15 м/с',
          'В случае отсутствия или неисправности ограничителя подъема талевого блока, ограничителя допускаемой нагрузки на крюке',
          'В случае неполного состава вахты для работ на конкретной установке',
          'В случае тумана и снегопада при потере видимости более 20 м',
          'Во всех перечисленных случаях запрещается проводить спуско-подъемные операции',
        ],
        correctAnswers: ['В случае скорости ветра, равной 15 м/с'],
      },
      {
        code: '63651010',
        text:
          'Какими организациями разрабатываются и утверждаются ТР на работы по добыче, сбору и подготовке нефти, газа и газового конденсата?',
        answers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящемся в эксплуатации, может разрабатываться эксплуатирующей организацией',
          'Разрабатываются проектной организацией, а утверждаются территориальными органами Ростехнадзора',
          'Разрабатываются и утверждаются компанией-оператором',
          'Разрабатываются специализированными организациями, а утверждаются компанией-оператором',
          'Разрабатываются проектной организацией, а утверждаются подрядной организацией',
        ],
        correctAnswers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящемся в эксплуатации, может разрабатываться эксплуатирующей организацией',
        ],
      },
      {
        code: '63651011',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63651012',
        text:
          'Какие данные должны быть указаны на металлической табличке, укрепляемой на видном месте мачты агрегата по ремонту скважин?',
        answers: [
          'Наименование организации, владеющей данным агрегатом, и его заводской номер',
          'Грузоподъемность и дата очередного технического освидетельствования',
          'Грузоподъемность и дата последнего технического освидетельствования',
        ],
        correctAnswers: [
          'Грузоподъемность и дата очередного технического освидетельствования',
        ],
      },
      {
        code: '63651013',
        text:
          'Что должно быть указано на корпусах оборудования, входящего в состав талевой системы (кронблок, талевый блок, крюк)?',
        answers: [
          'Дата изготовления',
          'Допускаемая грузоподъемность',
          'Материал изготовления',
          'Сроки следующего испытания',
        ],
        correctAnswers: ['Допускаемая грузоподъемность'],
      },
      {
        code: '63651014',
        text: 'Кто должен обслуживать электрооборудование установки?',
        answers: [
          'Электротехнический персонал с группой по электробезопасности не ниже III',
          'Электротехнический персонал с группой по электробезопасности не ниже II',
          'Электротехнический персонал, имеющий соответствующую квалификацию и допуск к работе',
        ],
        correctAnswers: [
          'Электротехнический персонал, имеющий соответствующую квалификацию и допуск к работе',
        ],
      },
      {
        code: '63651015',
        text:
          'Какое количество шаровых кранов должно быть установлено при вскрытии газовых пластов с аномально высоким давлением, сероводородсодержащих горизонтов на буровой установке?',
        answers: [
          'Один, устанавливаемый между рабочей трубой и ее предохранительным переводником',
          'Два, один из которых устанавливается между рабочей трубой и вертлюгом, второй - между рабочей трубой и ее предохранительным переводником',
          'Два, один из которых устанавливается между рабочей трубой и предохранительным переводником, второй является запасным',
          'Три, один из которых устанавливается между рабочей трубой и вертлюгом, второй - между рабочей трубой и ее предохранительным переводником, третий является запасным',
        ],
        correctAnswers: [
          'Три, один из которых устанавливается между рабочей трубой и вертлюгом, второй - между рабочей трубой и ее предохранительным переводником, третий является запасным',
        ],
      },
      {
        code: '63651016',
        text:
          'Каким документом устанавливаются периодичность и способы проверки состояния обсадных колонн по мере их износа и необходимые мероприятия по обеспечению безопасной проводки и эксплуатации скважин?',
        answers: [
          'Методическими указаниями проектной организации',
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
          'Регламентом, разработанным в соответствии с проектом',
          'Правилами безопасности в нефтяной и газовой промышленности',
        ],
        correctAnswers: [
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
        ],
      },
      {
        code: '63651017',
        text:
          'С учетом каких параметров производятся выбор обсадных труб и расчет обсадных колонн на стадиях строительства и эксплуатации скважин?',
        answers: [
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении раствора газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении бурового раствора пластовым флюидом и агрессивности флюида',
          'С учетом минимальных ожидаемых наружных и внутренних давлений при полном замещении раствора пластовым флюидом, снижении уровня осевых нагрузок на трубы',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
        ],
        correctAnswers: [
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
        ],
      },
      {
        code: '63651018',
        text:
          'Каким образом происходит передвижение транспортных средств на кустовых площадках?',
        answers: [
          'В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ',
          'По имеющимся в наличии дорогам со скоростью не более 40 км/час',
          'В соответствии с графиком передвижения',
          'Правилами не регламентировано',
        ],
        correctAnswers: [
          'В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ',
        ],
      },
      {
        code: '63651019',
        text:
          'Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?',
        answers: [
          'Определяются Ростехнадзором или его территориальным органом на основании экспертизы промышленной безопасности',
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
          'Определяются эксплуатирующей организацией или ее структурным подразделением на основании диагностирования',
        ],
        correctAnswers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
        ],
      },
      {
        code: '63651020',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63651021',
        text:
          'В каком случае запрещается производить спуск технических и эксплуатационных колонн в скважину?',
        answers: [
          'Если скважина осложнена поглощениями бурового раствора с одновременным флюидопроявлением',
          'Если скважина осложнена осыпями и обвалами',
          'Если скважина осложнена затяжками и посадками бурильной колонны',
          'Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений',
        ],
        correctAnswers: [
          'Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений',
        ],
      },
      {
        code: '63651022',
        text:
          'Чем должны оснащаться передвижные насосные установки, предназначенные для работы на скважинах?',
        answers: [
          'Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления',
          'Приборами, контролирующими основные параметры технологического процесса',
          'Запорными, предохранительными и регулирующими устройствами',
        ],
        correctAnswers: [
          'Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления',
        ],
      },
      {
        code: '63651023',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Начальнику цеха',
          'Пожарной службе эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Руководителю эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63651024',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63651025',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Лица, допущенные к выполнению газоопасных работ, должны пройти обучение приемам и методам проведения работ',
          'Лица, допущенные к выполнению газоопасных работ, должны пройти медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Лица, допущенные к выполнению газоопасных работ, должны быть не моложе 18 лет',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63651026',
        text:
          'На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?',
        answers: [
          'На 3 группы, в зависимости от степени риска проводимых работ',
          'На 2 группы, в зависимости от того, проводятся работы в закрытом или открытом пространстве',
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
        correctAnswers: [
          'На 2 группы, в зависимости от того, проводятся газоопасные работы с оформлением наряда-допуска или без оформления',
        ],
      },
      {
        code: '63651027',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63651028',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О знании правил оказания первой помощи',
          'О наличии медицинских противопоказаний к работе',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63651029',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Разработать проект производства работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
          'Составить план подготовительных работ',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63651030',
        text: 'Что допускается в пределах территории буферной зоны?',
        answers: [
          'Функционирование спортивных сооружений, дошкольных, школьных, лечебно-профилактических и оздоровительных учреждений',
          'Остановка и стоянка транзитного пассажирского железнодорожного и любого автомобильного транспорта на дорогах общего пользования',
          'Строительство производственных и иных объектов, не связанных с разработкой месторождения',
          'Размещение в вахтовых поселках рабочих, работающих на месторождении, при условии выполнения всех проектных решений по обустройству месторождения',
        ],
        correctAnswers: [
          'Размещение в вахтовых поселках рабочих, работающих на месторождении, при условии выполнения всех проектных решений по обустройству месторождения',
        ],
      },
      {
        code: '63651031',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['30 минут', '10 минут', '60 минут', '15 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63651032',
        text:
          'Чем должны быть оборудованы и оснащены колтюбинговые установки с гибкими непрерывными трубами?',
        answers: [
          'Только комплектом устройств на устье скважины для спуска труб под давлением, рассчитанным на максимально возможное устьевое давление',
          'Только системой контроля и регистрации нагрузок, возникающих при спускоподъемных операциях',
          'Только системой контроля и регистрации давления при прокачивании через гибкую трубу жидкостей в процессе технологических операций',
          'Всеми перечисленными устройствами',
        ],
        correctAnswers: ['Всеми перечисленными устройствами'],
      },
      {
        code: '63651033',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Технологический регламент',
          'Инструкция по эксплуатации и безопасному проведению ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63651034',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
          'До окончания работ на конкретном месте',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63651035',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Непосредственно участвовать в выполнении газоопасных работ',
          'Производить подготовку объекта к проведению газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63651036',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63651037',
        text:
          'С какой периодичностью проводится профилактический осмотр подъемного оборудования (лебедки, талевого блока, крюка, крюкоблока, вертлюга, штропов, талевого каната и устройств для его крепления, элеваторов, спайдеров, предохранительных устройств, блокировок и другого оборудования)?',
        answers: [
          'Ежедневно',
          'Еженедельно',
          'Ежесменно с записью в журнале проверки оборудования',
        ],
        correctAnswers: ['Ежесменно с записью в журнале проверки оборудования'],
      },
      {
        code: '63651038',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Главным инженером эксплуатирующей организации',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63651039',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63651040',
        text:
          'Можно ли повышать плотность бурового раствора, находящегося в скважине?',
        answers: [
          'Повышать плотность бурового раствора, находящегося в скважине, путем закачивания отдельных порций утяжеленного раствора разрешается',
          'Повышать плотность бурового раствора, находящегося в скважине, путем закачивания отдельных порций утяжеленного раствора запрещается',
          'Правилами не регламентируется',
        ],
        correctAnswers: [
          'Повышать плотность бурового раствора, находящегося в скважине, путем закачивания отдельных порций утяжеленного раствора запрещается',
        ],
      },
      {
        code: '63651041',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ передает наряд-допуск после его закрытия?',
        answers: [
          'Руководителю структурного подразделения или его заместителю, а так же в ГСС',
          'В ГСС и аварийно-спасательную службу организации',
          'Главному инженеру, руководителю службы производственного контроля, а так же в ГСС',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а так же в ГСС',
        ],
      },
      {
        code: '63651042',
        text:
          'Какие из документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63651043',
        text:
          'Какое необходимое количество шаровых кранов на буровой при вскрытии газовых пластов с аномально высоким давлением сероводородсодержащих горизонтов?',
        answers: ['Один', 'Два', 'Три', 'Четыре'],
        correctAnswers: ['Три'],
      },
      {
        code: '63651044',
        text:
          'Что должна обеспечивать прочность кондукторов, технических колонн и установленного на них противовыбросового оборудования?',
        answers: [
          'Герметизацию устья скважины в случаях газоводонефтепроявлений, выбросов и открытого фонтанирования с учетом превышения дополнительного давления, необходимого для глушения скважины, не менее чем на 5%',
          'Сохранение целостности при воздействии гидростатического давления столба бурового раствора средней плотности',
          'Противостояние воздействию максимальных сжимающих нагрузок в случаях открытого фонтанирования или поглощения с падением уровня бурового раствора, а также в интервалах залегания склонных к текучести пород',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Противостояние воздействию максимальных сжимающих нагрузок в случаях открытого фонтанирования или поглощения с падением уровня бурового раствора, а также в интервалах залегания склонных к текучести пород',
        ],
      },
      {
        code: '63651045',
        text:
          'На какие виды работ распространяются Правила ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на объектах атомной энергетики',
          'На ведение газоопасных, огневых и ремонтных работ на объектах электроэнергетики',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на выделенной и огражденной площадке на территории находящихся в эксплуатации опасных производственных объектов',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах',
        ],
      },
      {
        code: '63651046',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '3 месяца со дня его окончания',
          '6 месяцев со дня его окончания',
          '1 год со дня его окончания',
          '3 года со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63651047',
        text:
          'Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?',
        answers: [
          'Запорной арматурой и сальниковым устройством для герметизации штока',
          'Устройством для сигнализации об утечках продукта',
          'Перфорационной задвижкой',
          'Запорной арматурой и обратным клапаном',
          'Шаровым клапаном и сальниковым устройством для герметизации штока',
        ],
        correctAnswers: [
          'Запорной арматурой и сальниковым устройством для герметизации штока',
        ],
      },
      {
        code: '63651048',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
          'Во время грозы',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63651049',
        text:
          'Исходя из каких требований производится выбор манометров для установки на блоках дросселирования и глушения?',
        answers: [
          'Манометры должны иметь верхний предел диапазона измерений, равный давлению совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'Манометры должны иметь верхний предел диапазона измерений, равный двукратному давлению совместной опрессовки',
          'Манометры должны иметь верхний предел диапазона измерений, на 30% превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'Класс точности манометра должен быть не ниже 1,5, а верхний предел диапазона измерений не менее 40 МПа (400 кгс/см2)',
        ],
        correctAnswers: [
          'Манометры должны иметь верхний предел диапазона измерений, на 30% превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
        ],
      },
      {
        code: '63651050',
        text:
          'Кто разрабатывает и утверждает документацию по организации безопасного производства работ на кустовой площадке?',
        answers: [
          'Представитель проектной организации',
          'Пользователь недр (заказчик) или его представитель',
          'Представитель организации, осуществляющей строительство нефтяных и газовых скважин',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Пользователь недр (заказчик) или его представитель'],
      },
      {
        code: '63651051',
        text: 'Где регистрируются газоопасные работы II группы?',
        answers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
          'В журнале регистрации нарядов-допусков на проведение газоопасных работ с присвоением очередного номера',
          'В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности',
          'В журнале регистрации целевого инструктажа',
        ],
        correctAnswers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
        ],
      },
      {
        code: '63651052',
        text:
          'На какое давление должна быть опрессована скважина после установки противовыбросового оборудования?',
        answers: [
          'На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны',
          'На минимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны',
          'На максимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны',
          'На давление, равное давлению опрессовки эксплуатационной колонны',
        ],
        correctAnswers: [
          'На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны',
        ],
      },
      {
        code: '63651053',
        text:
          'Требованиям какого документа должны соответствовать свойства тампонажных материалов и формируемого из них цементного камня?',
        answers: [
          'Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям стандартов',
          'Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям, которые устанавливает завод-изготовитель',
          'Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям рабочего проекта',
        ],
        correctAnswers: [
          'Свойства тампонажных материалов и формируемого из них цементного камня должны соответствовать требованиям рабочего проекта',
        ],
      },
      {
        code: '63651054',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63651055',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63651056',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63651057',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'Нужно в любом случае',
          'По решению руководителя эксплуатирующей организации',
          'Решение принимается совместно руководителем эксплуатирующей организации и руководителем газоспасательной службы',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63651058',
        text:
          'Какие ограничения предусмотрены для перфорации обсадных колонн при проведении ремонтно-изоляционных работ в процессе проводки ствола скважины?',
        answers: [
          'Ограничения не предусмотрены',
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале проницаемых горизонтов',
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора',
        ],
        correctAnswers: [
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора',
        ],
      },
      {
        code: '63651059',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63651060',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Работают люди!", "Опасно для жизни!"',
          '"Газоопасные работы", "Газ"',
          '"Опасно для жизни!", "Внимание!"',
          '"Осторожно! Опасная зона", "Работают люди!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63651061',
        text:
          'Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?',
        answers: [
          'На диспетчерский пункт',
          'На пульт насосной станции',
          'На пульт групповой замерной установки',
          'На центральный пульт нефтегазодобывающего управления (НГДУ)',
        ],
        correctAnswers: ['На диспетчерский пункт'],
      },
      {
        code: '63651062',
        text:
          'В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.',
        answers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
          'Когда газоопасные работы могут быть проведены без непосредственного участия людей',
        ],
        correctAnswers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
        ],
      },
      {
        code: '63651063',
        text:
          'Разрешается ли последовательное соединение между собой заземляющих устройств разных зданий, сооружений, установок с помощью одного заземляющего проводника?',
        answers: [
          'Допускается',
          'Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом',
          'Не допускается',
          'Не допускается, за исключением аппаратов или резервуаров, установленных в одном обваловании',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63651064',
        text:
          'Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?',
        answers: [
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
          'Взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
          'Азимут и зенитный угол ствола скважины',
          'Пространственное расположение ствола скважины и дифференциальное давление в системе скважина - пласт',
        ],
        correctAnswers: [
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
        ],
      },
      {
        code: '63651065',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63651066',
        text:
          'Что необходимо предпринять в случае обнаружения загазованности воздуха рабочей зоны?',
        answers: [
          'Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности',
          'Незамедлительно предупредить обслуживающий персонал и покинуть загазованный участок',
          'Незамедлительно подать сигнал тревоги и предупредить ответственного руководителя',
          'Незамедлительно предупредить обслуживающий персонал о возможной опасности',
          'Незамедлительно покинуть загазованный участок и проинформировать о случившемся ответственного руководителя',
        ],
        correctAnswers: [
          'Незамедлительно предупредить обслуживающий персонал близлежащих установок о возможной опасности, оградить загазованный участок и принять меры по устранению источника загазованности',
        ],
      },
      {
        code: '63651067',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта совместно с работниками аварийно-спасательных подразделений',
          'Работники газоспасательной службы',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63651068',
        text:
          'Какое расстояние должно быть между устьями скважин при их размещении на кустовых площадках вечномерзлых грунтов?',
        answers: [
          'Должно быть равно радиусу растепления пород вокруг устья скважины',
          'Должно быть не менее 50 м',
          'Должно быть не меньше 1,2 диаметра растепления пород вокруг устья скважины',
          'Должно быть не менее 100 м',
        ],
        correctAnswers: [
          'Должно быть не меньше 1,2 диаметра растепления пород вокруг устья скважины',
        ],
      },
      {
        code: '63651069',
        text:
          'Кем производится пуск в работу смонтированной установки и оборудования?',
        answers: [
          'Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией',
          'Двумя рабочими бригадами, прошедшими соответствующий инструктаж, при условии, что один из них имеет квалификационную группу не ниже второй',
          'Двумя рабочими: электромонтером и специалистом по охране труда эксплуатирующей организации',
          'Двумя рабочими: электромонтером и рабочим бригады',
        ],
        correctAnswers: [
          'Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией',
        ],
      },
      {
        code: '63651070',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63651071',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63651072',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63651073',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главный инженер эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63651074',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Руководитель огневых работ, назначенный руководителем структурного подразделения',
          'Работники подрядной организации',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63651075',
        text:
          'Что необходимо предпринять в процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений?',
        answers: [
          'Следует производить долив бурового раствора в скважину',
          'Следует обеспечить большую скорость восходящего потока жидкости в кольцевом пространстве',
          'Следует ввести в промывочную жидкость смазывающие добавки',
          'Следует ввести промывочные жидкости, инертные по отношению к горным породам',
        ],
        correctAnswers: [
          'Следует производить долив бурового раствора в скважину',
        ],
      },
      {
        code: '63651076',
        text:
          'Какие показатели должны контролироваться при бурении наклонно-направленных и горизонтальных скважин?',
        answers: [
          'Плотность, структурно-механические и реологические свойства бурового раствора и пространственное расположение ствола скважины',
          'Расход бурового раствора на входе и выходе из скважины, давление в манифольде буровых насосов и зенитный угол ствола скважины',
          'Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
          'Крутящий момент на роторе при роторном способе бурения, давление в манифольде буровых насосов и азимут ствола скважины',
        ],
        correctAnswers: [
          'Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
        ],
      },
      {
        code: '63651077',
        text:
          'Какая техническая документация должна быть на буровой установке на краны шаровые и клапаны обратные?',
        answers: [
          'Руководство по эксплуатации',
          'Инструкция с указанием технических характеристик кранов шаровых и клапанов обратных',
          'Технические паспорта и сведения о проведении дефектоскопии',
        ],
        correctAnswers: [
          'Технические паспорта и сведения о проведении дефектоскопии',
        ],
      },
      {
        code: '63651078',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63651079',
        text:
          'В каких случаях следует производить долив бурового раствора в скважину?',
        answers: [
          'При спуско-подъемных операциях',
          'В процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений и обвалов стенок скважин',
          'При обнаружении газонефтеводопроявлений',
        ],
        correctAnswers: [
          'В процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений и обвалов стенок скважин',
        ],
      },
      {
        code: '63651080',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений хранение токсичных жидкостей в резервуарах с "атмосферным" дыханием?',
        answers: [
          'Допускается при условии оснащения резервуаров системой аварийного слива жидкости в дренажную систему',
          'Допускается при условии оснащения резервуаров сигнализацией предельного верхнего уровня заполнения резервуара, сблокированной с насосным оборудованием',
          'Допускается в любом случае',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63651081',
        text:
          'Кто принимает оперативные решения по отклонению от параметров, предусмотренных в рабочем проекте, при возникновении в процессе производства буровых работ осложнений (газонефтепроявление, поглощения, обвалы и др.)?',
        answers: [
          'Руководство эксплуатирующей организации (заказчик)',
          'Проектная организация',
          'Буровой подрядчик с последующим уведомлением заказчика',
        ],
        correctAnswers: [
          'Буровой подрядчик с последующим уведомлением заказчика',
        ],
      },
      {
        code: '63651082',
        text:
          'После выполнения какого условия работникам разрешается приступить к демонтажу буровой установки на электроприводе?',
        answers: [
          'После получения устного подтверждения главного энергетика организации об отключении установки от электросети',
          'После подачи звукового сигнала оператором буровой установки о снятии напряжения на установке',
          'После получения письменного подтверждения работника, ответственного за эксплуатацию электрооборудования, об отключении установки от электросети',
          'После получения информации от вышкомонтажника-электромонтера о снятии напряжения на буровой установке',
        ],
        correctAnswers: [
          'После получения письменного подтверждения работника, ответственного за эксплуатацию электрооборудования, об отключении установки от электросети',
        ],
      },
      {
        code: '63651083',
        text:
          'В течение какого времени наряд-допуск на проведение огневых работ действителен?',
        answers: [
          'В течение одной дневной смены',
          'До окончания работ на конкретном месте',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной дневной смены'],
      },
      {
        code: '63651084',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С газоспасательной службой и службой охраны труда',
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63651085',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63651086',
        text:
          'Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?',
        answers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
          'Лицом, ответственным за осуществление производственного контроля',
          'Лицом, ответственным за подготовку газоопасных работ',
          'Представителем Ростехнадзора',
        ],
        correctAnswers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
        ],
      },
      {
        code: '63651087',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения представителя газоспасательной службы, и при обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования',
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63651088',
        text:
          'Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования?',
        answers: [
          'Не нормируется',
          'Для нефтяных скважин:  - с газовым фактором менее 200 м3/т - не менее 30 м  - с газовым фактором более 200 м3/т - не менее 100 м',
          'Для нефтяных скважин: - с газовым фактором менее 300 м3/т - не менее 50 м - с газовым фактором более 300 м3/т - не менее 200 м',
        ],
        correctAnswers: [
          'Для нефтяных скважин:  - с газовым фактором менее 200 м3/т - не менее 30 м  - с газовым фактором более 200 м3/т - не менее 100 м',
        ],
      },
      {
        code: '63651089',
        text:
          'Допускается ли отклонение от проектной величины плотности бурового раствора (освобожденного от газа), закачиваемого в скважину в процессе циркуляции?',
        answers: [
          'Допускается, но не более чем на 0,5 г/см³',
          'Допускается не более чем на 0,05 г/см³ только при закачивании отдельных порций утяжеленного раствора',
          'Допускается не более чем на 0,03 г/см³, кроме случаев ликвидации газонефтеводопроявлений и осложнений',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается не более чем на 0,03 г/см³, кроме случаев ликвидации газонефтеводопроявлений и осложнений',
        ],
      },
      {
        code: '63651090',
        text:
          'Где должен быть установлен основной пульт для управления превенторами и гидравлическими задвижками?',
        answers: [
          'На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте',
          'На расстоянии не менее 8 м от устья скважины в удобном и безопасном месте',
          'На расстоянии не менее 6 м от устья скважины в удобном и безопасном месте',
          'Основной пульт должен быть установлен непосредственно возле пульта бурильщика',
        ],
        correctAnswers: [
          'На расстоянии не менее 10 м от устья скважины в удобном и безопасном месте',
        ],
      },
      {
        code: '63651091',
        text:
          'С какой периодичностью буровая бригада должна проводить профилактический осмотр подъемного оборудования (лебедки, талевого блока, крюка, штропов, талевого каната, блокировок)?',
        answers: [
          'Каждую смену',
          'Каждый день',
          'Каждую неделю',
          'Каждый месяц',
        ],
        correctAnswers: ['Каждую смену'],
      },
      {
        code: '63651092',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63651093',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63651094',
        text:
          'Кем утверждается перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право выдавать и утверждать наряды-допуски?',
        answers: [
          'Руководителем организации или уполномоченным им лицом',
          'Техническим руководителем организации',
          'Специалистом по охране труда',
          'Представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Руководителем организации или уполномоченным им лицом',
        ],
      },
      {
        code: '63651095',
        text:
          'Кто выбирает тип ПВО и колонной головки, схему установки и обвязки ПВО, блоков глушения и дросселирования?',
        answers: [
          'Буровая организация при согласовании с противофонтанной службой',
          'Заказчик при согласовании с Ростехнадзором',
          'Проектная организация при согласовании с заказчиком',
        ],
        correctAnswers: ['Проектная организация при согласовании с заказчиком'],
      },
      {
        code: '63651096',
        text:
          'Кем осуществляется надзор за ходом строительства скважин, качеством выполнения работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?',
        answers: [
          'Проектной организацией',
          'Региональным центром Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: [
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
        ],
      },
      {
        code: '63651097',
        text:
          'При каких условиях допускается повторное использование рабочего проекта при бурении группы скважин на идентичных по геолого-техническим условиям площадях?',
        answers: [
          'Только при одинаковых проектных глубинах по стволу скважин',
          'Только при одинаковом назначении и конструкции скважин',
          'Только при отличии плотности бурового раствора от проектной в пределах ± 0,3 г/см3',
          'Только при идентичности горно-геологических условий проводки и условий природопользования',
          'При выполнении всех перечисленных условий',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63651098',
        text:
          'Каким документом определяются типы резьбовых соединений и резьбовых смазок, применяемых в интервалах интенсивного искривления ствола в конструкциях скважин?',
        answers: [
          'Стандартами буровой организации',
          'Техническими регламентами',
          'Рабочим проектом на производство буровых работ',
        ],
        correctAnswers: ['Рабочим проектом на производство буровых работ'],
      },
      {
        code: '63651099',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63651100',
        text:
          'Какие плакаты должны быть постоянно укреплены на пусковом устройстве и вблизи него на скважинах с автоматическим и дистанционным управлением станков-качалок?',
        answers: [
          '"Внимание! Пуск автоматический"',
          '"Осторожно! Высокое напряжение"',
          '"Без команды не включать!"',
          '"Посторонним вход запрещен!"',
        ],
        correctAnswers: ['"Внимание! Пуск автоматический"'],
      },
      {
        code: '63651101',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63651102',
        text: 'Каким канатом должен быть обмотан промывочный шланг?',
        answers: [
          'Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1 - 1,5 м по всей длине шланга',
          'Стальным мягким канатом диаметром не менее 4 мм с петлями через каждые 2,5 м по всей длине шланга',
          'Стальным мягким канатом диаметром не менее 5 мм с петлями через каждые 2,5 м по всей длине шланга',
          'Стальным мягким канатом диаметром не менее 7 мм с петлями через каждые 2 м по всей длине шланга',
        ],
        correctAnswers: [
          'Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1 - 1,5 м по всей длине шланга',
        ],
      },
      {
        code: '63651103',
        text:
          'Какое необходимое количество шаровых кранов на буровой установке при вскрытии коллекторов, насыщенных нефтью и газом?',
        answers: ['Один', 'Два', 'Три', 'Четыре'],
        correctAnswers: ['Два'],
      },
      {
        code: '63651104',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Любым из перечисленных лиц',
          'Только лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Только лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63651105',
        text:
          'В каком случае следует прекратить все работы на соседних объектах кустовых площадок?',
        answers: [
          'Только в случае передвижки вышечно-лебедочного блока на новую точку (позицию)',
          'Только в случае ведения сложных аварийных работ на скважине',
          'Только в случае испытания вышек',
          'Во всех вышеперечисленных случаях все работы на соседних объектах должны быть прекращены',
        ],
        correctAnswers: [
          'Во всех вышеперечисленных случаях все работы на соседних объектах должны быть прекращены',
        ],
      },
      {
        code: '63651106',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее',
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63651107',
        text:
          'Должны ли буровые насосы оборудоваться компенсаторами давления? Если да, то какие требования при этом должны соблюдаться?',
        answers: [
          'Установка на буровых насосах компенсаторов давления необязательна в том случае, если проводятся мероприятия по обеспечению равномерности подачи промывочной жидкости',
          'Компенсаторы давления должны устанавливаться только на трех поршневых буровых насосах, при этом компенсаторы должны быть заполнены воздухом или инертным газом, с приспособлениями для контроля давления в компенсаторах',
          'На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля',
          'На всех буровых насосах должны быть установлены гидрокомпенсаторы давления, заполняемые водой, с приспособлениями для контроля давления в компенсаторах',
        ],
        correctAnswers: [
          'На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля',
        ],
      },
      {
        code: '63651108',
        text:
          'Кто устанавливает порядок организации, проведения планового ремонта и обслуживания бурового и энергетического оборудования?',
        answers: [
          'Организация-заказчик с учетом инструкций по эксплуатации, предоставляемых производителем продукции',
          'Буровая организация с учетом инструкций по эксплуатации, предоставляемых производителем продукции',
          'Служба главного энергетика совместно с буровой организацией',
        ],
        correctAnswers: [
          'Буровая организация с учетом инструкций по эксплуатации, предоставляемых производителем продукции',
        ],
      },
      {
        code: '63651109',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в два года',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63651110',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Один экземпляр - главному инженеру, а второй - руководителю службы производственного контроля',
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63651111',
        text:
          'Что из перечисленного не учитывается при планировании площадки для монтажа буровой установки?',
        answers: [
          'Обеспечение движения сточных вод в систему их сбора и очистки',
          'Естественный уклон местности',
          'Роза ветров',
        ],
        correctAnswers: ['Роза ветров'],
      },
      {
        code: '63651112',
        text: 'Какой класс взрывоопасной зоны представлен на рисунке?',
        answers: [
          'Класс взрывоопасности - зона 0',
          'Класс взрывоопасности - зона 1',
          'Класс взрывоопасности - зона 2',
        ],
        correctAnswers: ['Класс взрывоопасности - зона 1'],
      },
      {
        code: '63651113',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только специалист, обученный по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63651114',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Только непрерывный контроль состояния воздушной среды',
          'Только наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
          'Только наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с сигнально-спасательной веревкой',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63651115',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63651116',
        text:
          'Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования для скважин, сооружаемых с насыпного основания и ограниченных площадок?',
        answers: [
          'Определяется проектной документацией',
          'Такая же, как для нефтяных скважин на равнине: - с газовым фактором менее 200 м3/т - не менее 30 м - с газовым фактором более 200 м3/т - не менее 100 м',
          'Устанавливается подрядчиком по согласованию с заказчиком',
        ],
        correctAnswers: [
          'Устанавливается подрядчиком по согласованию с заказчиком',
        ],
      },
      {
        code: '63651117',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны уметь пользоваться СИЗ',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны пройти инструктаж',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63651118',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Увеличить число наблюдающих и работающих',
          'Предусмотреть дополнительное освещение внутри емкости',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Установить систему подачи условных сигналов',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63651119',
        text:
          'В каких случаях производится контроль бурового раствора на газонасыщенность?',
        answers: [
          'При переливе или при увеличении количества бурового раствора',
          'Во всех случаях при работе с буровым раствором',
          'Перед и после вскрытия пластов с аномально высоким пластовым давлением',
          'При вскрытии газоносных горизонтов и дальнейшем углублении скважины',
        ],
        correctAnswers: [
          'При вскрытии газоносных горизонтов и дальнейшем углублении скважины',
        ],
      },
      {
        code: '63651120',
        text:
          'Чему должна быть равна расчетная продолжительность процесса цементирования обсадной колонны?',
        answers: [
          'Не должна превышать 95% времени начала загустевания тампонажного раствора по лабораторному анализу',
          'Не должна превышать 90% времени начала загустевания тампонажного раствора по лабораторному анализу',
          'Не должна превышать 75% времени начала загустевания тампонажного раствора по лабораторному анализу',
          'Должна быть равна времени загустевания тампонажного раствора',
        ],
        correctAnswers: [
          'Не должна превышать 75% времени начала загустевания тампонажного раствора по лабораторному анализу',
        ],
      },
      {
        code: '63651121',
        text:
          'Кем устанавливается порядок проведения работ на кустовых площадках при совмещении во времени различных по характеру работ (бурение, освоение, эксплуатация, монтаж нефтегазодобывающего оборудования и т.д.)?',
        answers: [
          'Министерством природных ресурсов и экологии Российской Федерации',
          'Ростехнадзором',
          'Пользователем недр (заказчиком) или его представителем',
          'Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: [
          'Пользователем недр (заказчиком) или его представителем',
        ],
      },
      {
        code: '63651122',
        text:
          'Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?',
        answers: [
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63651123',
        text:
          'Какое напряжение должно применяться для питания переносных электрических светильников, используемых при работах в особо неблагоприятных условиях и наружных установках?',
        answers: ['Не выше 12 В', 'Не выше 50 В', 'Не выше 127 В'],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '63651124',
        text:
          'Какое из приведенных действий допускается при проведении спуско-подъемных операций?',
        answers: [
          'Находиться в радиусе (зоне) действия автоматических и машинных ключей, рабочих и страховочных канатов',
          'Открывать и закрывать элеватор только при полной остановке талевого блока',
          'Подавать бурильные свечи с подсвечника и устанавливать их без использования специальных приспособлений',
          'Пользоваться перевернутым элеватором',
        ],
        correctAnswers: [
          'Открывать и закрывать элеватор только при полной остановке талевого блока',
        ],
      },
      {
        code: '63651125',
        text:
          'В соответствии с требованиями каких документов производится подготовка площадки, монтаж и эксплуатация колтюбинговых установок?',
        answers: [
          'В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя',
          'Только в соответствии с инструкцией по производству работ, утвержденной Ростехнадзором',
          'В соответствии с техническими условиями и инструкцией по производству работ, утвержденной Ростехнадзором',
          'Только в соответствии с техническими условиями',
        ],
        correctAnswers: [
          'В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя',
        ],
      },
      {
        code: '63651126',
        text:
          'Кто устанавливает нормативные сроки наработки, виды инспекций и дефектоскопии для бурильных труб, ведущих, утяжеленных бурильных труб, переводников, опорно-центрирующих и других элементов бурильной колонны?',
        answers: [
          'Разработчики-проектанты в рабочем проекте производства буровых работ',
          'Эксплуатирующая организация по согласованию с надзорными органами',
          'Эксплуатирующая организация в соответствии с технической документацией завода-изготовителя',
        ],
        correctAnswers: [
          'Эксплуатирующая организация в соответствии с технической документацией завода-изготовителя',
        ],
      },
      {
        code: '63651127',
        text:
          'С какой частотой проводится опрессовка кранов шаровых и клапанов обратных?',
        answers: [
          'Перед спецработами',
          'Перед установкой',
          'Один раз в 6 месяцев',
        ],
        correctAnswers: ['Один раз в 6 месяцев'],
      },
      {
        code: '63651128',
        text:
          'Представители каких организаций включаются в обязательном порядке в комиссию при испытании колонны на герметичность?',
        answers: [
          'Представители заказчика и надзорных органов',
          'Представители буровой организации и природоохранных организаций',
          'Представитель заказчика',
        ],
        correctAnswers: ['Представитель заказчика'],
      },
      {
        code: '63651129',
        text:
          'На какое давление должны опрессовываться нагнетательные трубопроводы для цементирования ствола скважины?',
        answers: [
          'В 1,25 раза превышающее максимальное расчетное рабочее давление при цементировании скважины',
          'В 1,5 раза превышающее ожидаемое рабочее давление при цементировании скважины',
          'На максимальное расчетное рабочее давление при цементировании скважины',
        ],
        correctAnswers: [
          'В 1,5 раза превышающее ожидаемое рабочее давление при цементировании скважины',
        ],
      },
      {
        code: '63651130',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Не включать: работают люди!"',
          '"Не включать - работа на линии!"',
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: не в фазе!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63651131',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ взрывопожароопасных веществ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Увеличение объема и характера работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63651132',
        text:
          'Какой документ является основным для производства буровых работ?',
        answers: [
          'Градостроительный кодекс Российской Федерации',
          'Проект обустройства месторождения углеводородов',
          'Рабочий проект на производство буровых работ',
        ],
        correctAnswers: ['Рабочий проект на производство буровых работ'],
      },
      {
        code: '63651133',
        text:
          'В каком случае оснащение буровых установок верхним приводом необязательно?',
        answers: [
          'В случае вскрытия пластов с ожидаемым содержанием в пластовом флюиде сероводорода свыше 6% (объемных)',
          'В случае набора угла с радиусом кривизны менее 30 м в наклонно-направленных скважинах',
          'В случае бурения скважин с глубиной менее 4500 м',
          'В случае бурения горизонтального участка ствола скважины длиной более 300 м в скважинах глубиной по вертикали более 3000 м',
        ],
        correctAnswers: ['В случае бурения скважин с глубиной менее 4500 м'],
      },
      {
        code: '63651134',
        text:
          'Кем разрабатываются инструкции по монтажу и эксплуатации противовыбросового оборудования (далее - ПВО)?',
        answers: [
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются заводом-изготовителем',
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются заказчиком (пользователем недр) и согласовываются с Ростехнадзором',
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются буровой организацией в соответствии с применяемым оборудованием, технологией ведения работ и инструкциями по монтажу, техническому обслуживанию, эксплуатации и ремонту изготовителей',
        ],
        correctAnswers: [
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются буровой организацией в соответствии с применяемым оборудованием, технологией ведения работ и инструкциями по монтажу, техническому обслуживанию, эксплуатации и ремонту изготовителей',
        ],
      },
      {
        code: '63651135',
        text:
          'В присутствии кого проводится проверка исправности, устойчивости и надежности закрепления лестницы по месту работы при работах внутри емкости?',
        answers: [
          'В присутствии главного инженера',
          'В присутствии лица, ответственного за проведение газоопасных работ',
          'В присутствии начальника цеха',
          'В присутствии рабочего, который будет спускаться в емкость, и наблюдающего',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение газоопасных работ',
        ],
      },
      {
        code: '63651136',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63651137',
        text:
          'С какой периодичностью превенторы должны проверяться на закрытие и открытие?',
        answers: [
          'Перед началом каждой смены',
          'Перед началом спецработ',
          'Периодичность проверки устанавливается буровой организацией, но не реже одного раза в месяц',
        ],
        correctAnswers: [
          'Периодичность проверки устанавливается буровой организацией, но не реже одного раза в месяц',
        ],
      },
      {
        code: '63651138',
        text: 'Откуда должен осуществляться пуск буровых насосов в работу?',
        answers: [
          'С пульта бурильщика',
          'С местного поста управления',
          'С пульта оператора, осуществляющего контроль технологического процесса',
          'Одновременно с пульта бурильщика и местного поста управления',
        ],
        correctAnswers: ['С местного поста управления'],
      },
      {
        code: '63651139',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Не допускается',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63651140',
        text:
          'Рабочий проект на производство буровых работ разрабатывается на отдельную скважину или на группу скважин?',
        answers: [
          'Рабочий проект на производство буровых работ разрабатывается только на отдельную скважину',
          'Рабочий проект на производство буровых работ разрабатывается на бурение отдельной скважины или на группу скважин, расположенных на одной кустовой площадке или одном месторождении, площади',
          'Рабочий проект на производство буровых работ может разрабатываться на группу скважин, расположенных на одном кусте, если есть опыт бурения на данном месторождении',
        ],
        correctAnswers: [
          'Рабочий проект на производство буровых работ разрабатывается на бурение отдельной скважины или на группу скважин, расположенных на одной кустовой площадке или одном месторождении, площади',
        ],
      },
      {
        code: '63651141',
        text:
          'Каким документом определяется порядок организации и производства работ на одном объекте нескольких подразделений одной организации, эксплуатирующей ОПО?',
        answers: [
          'Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом',
          'Положением о производственном контроле организации',
          'Нарядом-допуском, оформленным техническим руководителем организации',
          'Производственным заданием, выданным руководителем организации или лицом, его замещающим',
          'Графиком взаимодействия, согласованным с вышестоящей организацией',
        ],
        correctAnswers: [
          'Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом',
        ],
      },
      {
        code: '63651142',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63651143',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'Ко II группе',
          'К I группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['Ко II группе'],
      },
      {
        code: '63651144',
        text:
          'Консервация скважин в процессе бурения осуществляется в соответствии с инструкцией о порядке ликвидации, консервации скважин и оборудования, их устьев и стволов. Предусмотрены ли Правилами дополнительные работы при консервации скважин в процессе бурения, кроме требований инструкции?',
        answers: [
          'Да',
          'Нет',
          'Предусмотрены, но только в случае нестандартных ситуаций',
        ],
        correctAnswers: ['Да'],
      },
      {
        code: '63651145',
        text:
          'Какие меры из перечисленных входят в комплекс работ по освоению скважин?',
        answers: [
          'Предупреждение прорыва пластовой воды и газа из газовой "шапки" и термогидрогазодинамические исследования по определению количественной и качественной характеристик пласта и его геологофизических параметров',
          'Сохранение, восстановление или повышение проницаемости призабойной зоны и предотвращение неконтролируемых газонефтеводопроявлений и открытых фонтанов',
          'Исключение закупорки пласта при вторичном вскрытии и сохранение скелета пласта в призабойной зоне',
          'В комплекс работ по освоению скважин входят все перечисленные меры',
        ],
        correctAnswers: [
          'В комплекс работ по освоению скважин входят все перечисленные меры',
        ],
      },
      {
        code: '63651146',
        text:
          'Каковы требования к производству работ по глубинным измерениям в скважинах с избыточным давлением на устье?',
        answers: [
          'Производятся в соответствии с требованиями заказчика',
          'Производятся с применением утяжеленного бурового раствора',
          'Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны',
        ],
        correctAnswers: [
          'Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны',
        ],
      },
      {
        code: '63651147',
        text:
          'При достижении какого значения нижнего предела воспламенения смеси воздуха с углеводородом должно быть обеспечено полное отключение оборудования и механизмов?',
        answers: [
          'При достижении 20% от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 30% от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 40% от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 50% от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
        correctAnswers: [
          'При достижении 50% от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
      },
      {
        code: '63651148',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63651149',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63651150',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63651151',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, на необходимый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63651152',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63651153',
        text:
          'Необходим ли лабораторный анализ компонентов тампонажной смеси для условий предстоящего цементирования колонны?',
        answers: [
          'Лабораторному анализу подвергается 5% от одной из партий цемента, поступившего на буровую установку. Предварительный лабораторный анализ для условий предстоящего цементирования не проводится',
          'В лабораторном анализе цемента для условий предстоящего цементирования колонны нет необходимости',
          'Применение компонентов тампонажной смеси без проведения предварительного лабораторного анализа для условий предстоящего цементирования колонны запрещается',
        ],
        correctAnswers: [
          'Применение компонентов тампонажной смеси без проведения предварительного лабораторного анализа для условий предстоящего цементирования колонны запрещается',
        ],
      },
      {
        code: '63651154',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['40 °C', '45 °C', '50 °C', '55 °C'],
        correctAnswers: ['50 °C'],
      },
    ],
    63653: [
      {
        code: '63653000',
        text:
          'При какой максимальной температуре работа внутри емкостей (аппаратов) не допускается?',
        answers: ['55 °С', '45 °С', '40 °С', '50 °С'],
        correctAnswers: ['50 °С'],
      },
      {
        code: '63653001',
        text:
          'Каким количеством работников осуществляется обслуживание устьев морских скважин на МСП и ПТК?',
        answers: [
          'Одним работником',
          'Не менее чем двумя работниками',
          'Не менее чем четырьмя работниками',
          'Не менее чем тремя работниками',
        ],
        correctAnswers: ['Не менее чем двумя работниками'],
      },
      {
        code: '63653002',
        text:
          'Кто организует и проводит ремонтно-изоляционные работы на ликвидированных скважинах и проводит повторную ликвидацию при обнаружении ГНВП?',
        answers: [
          'Строительная организация',
          'Организация - исполнитель буровых работ',
          'Специализированная организация',
          'Пользователь недр или его представитель',
        ],
        correctAnswers: ['Пользователь недр или его представитель'],
      },
      {
        code: '63653003',
        text:
          'Какими организациями разрабатываются и утверждаются технологические регламенты на работы по добыче, сбору и подготовке нефти, газа и газового конденсата?',
        answers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. Технологические регламенты на опасный производственный объект, находящийся в эксплуатации, могут разрабатываться эксплуатирующей организацией',
          'Разрабатываются проектной организацией, а утверждаются территориальными органами Ростехнадзора',
          'Разрабатываются проектной организацией, а утверждаются подрядной организацией',
          'Разрабатываются специализированными организациями, а утверждаются компанией-оператором',
          'Разрабатываются и утверждаются компанией-оператором',
        ],
        correctAnswers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. Технологические регламенты на опасный производственный объект, находящийся в эксплуатации, могут разрабатываться эксплуатирующей организацией',
        ],
      },
      {
        code: '63653004',
        text:
          'Что из перечисленного указывается в плане безопасного проведения изоляционно-ликвидационных работ на ОПО МНГК?',
        answers: [
          'Плотность бурового раствора',
          'Интервалы установок цементных мостов',
          'Ответственные за проведение указанных работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63653005',
        text:
          'Какие требования предъявляются к работникам комплексных бригад при необходимости выполнения работ, требующих совмещения профессий?',
        answers: [
          'Работники должны пройти обучение и получить соответствующую квалификацию по видам выполняемых работ, а также иметь допуски к самостоятельной работе по совмещаемым профессиям',
          'Работники должны пройти дополнительное обучение по охране труда',
          'Работники должны получить только допуск к ведению таких работ',
          'Работники должны пройти дополнительное обучение по экологической безопасности',
        ],
        correctAnswers: [
          'Работники должны пройти обучение и получить соответствующую квалификацию по видам выполняемых работ, а также иметь допуски к самостоятельной работе по совмещаемым профессиям',
        ],
      },
      {
        code: '63653006',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63653007',
        text:
          'С какой регулярностью проводятся повторная и последующие проверки подводного трубопровода?',
        answers: [
          'Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет',
          'Повторная проверка подводного трубопровода проводится в срок не позднее шести месяцев с начала эксплуатации, последующие проверки проводятся при необходимости',
          'Повторная проверка подводного трубопровода проводится только в случае экстренной необходимости, последующие проверки проводятся каждые пять лет',
        ],
        correctAnswers: [
          'Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет',
        ],
      },
      {
        code: '63653008',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К I группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63653009',
        text:
          'Кто обеспечивает работников геофизической организации при нахождении на МСП, ПБУ, МЭ, ПТК спасательными средствами?',
        answers: [
          'Эксплуатирующая организация',
          'Руководство геофизической организации',
          'Персонал геофизической организации обеспечивается спасательными средствами на договорной основе с организацией заказчика',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63653010',
        text:
          'При применении каких материалов устанавливается герметичное оборудование с устройством для пневмотранспорта при проведении буровых работ?',
        answers: [
          'Только при применении глинопорошка',
          'Только при применении цемента',
          'Только при применении химреагентов',
          'При применении всех перечисленных материалов',
        ],
        correctAnswers: ['При применении всех перечисленных материалов'],
      },
      {
        code: '63653011',
        text:
          'Кто устанавливает порядок организации, проведения планового ремонта и обслуживания бурового и энергетического оборудования?',
        answers: [
          'Буровая организация с учетом инструкций по эксплуатации, предоставляемых производителем продукции',
          'Организация-заказчик с учетом инструкций по эксплуатации, предоставляемых производителем продукции',
          'Служба главного энергетика совместно с буровой организацией',
        ],
        correctAnswers: [
          'Буровая организация с учетом инструкций по эксплуатации, предоставляемых производителем продукции',
        ],
      },
      {
        code: '63653012',
        text:
          'Кем осуществляется надзор за ходом строительства скважин, качеством выполнения работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?',
        answers: [
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
          'Проектной организацией',
          'Региональным центром Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: [
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
        ],
      },
      {
        code: '63653013',
        text: 'На какой срок на стадии проектирования ОПО разрабатывается ТР?',
        answers: ['На 1 год', 'На 2 года', 'На 3 года', 'На 5 лет'],
        correctAnswers: ['На 5 лет'],
      },
      {
        code: '63653014',
        text:
          'Какие из приведенных помещений (пространств) относятся к зоне 2 (участок, на котором присутствие взрывоопасной смеси в нормальном рабочем режиме исключается на открытых площадках и в помещениях)?',
        answers: [
          'Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны',
          'Закрытые помещения насосных для сточных вод',
          'Закрытые помещения для хранения шлангов для перекачки легковоспламеняющихся жидкостей',
          'Пространство внутри открытых и закрытых технических устройств и емкостей, содержащих нефть, буровой раствор, обработанный нефтью, нефтяные газы или другие легковоспламеняющиеся вещества',
        ],
        correctAnswers: [
          'Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны',
        ],
      },
      {
        code: '63653015',
        text:
          'С чем из перечисленного должны быть ознакомлены работники, прибывшие на ОПО для работы?',
        answers: [
          'Только с действиями по конкретным видам тревог',
          'Только с опасными и вредными производственными факторами и признаками их проявления',
          'Только с другими вопросами, входящими в объем вводного инструктажа',
          'Со всем перечисленным',
        ],
        correctAnswers: ['Со всем перечисленным'],
      },
      {
        code: '63653016',
        text:
          'Кто руководит всеми работами по герметизации и отсоединению от устья скважины при прямых признаках газонефтеводопроявления (ГНВП)?',
        answers: [
          'Начальник бурового комплекса',
          'Капитан ПБУ',
          'Начальник внешней связи',
          'Начальник ПБУ',
        ],
        correctAnswers: ['Начальник ПБУ'],
      },
      {
        code: '63653017',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главный инженер эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63653018',
        text:
          'Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?',
        answers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
          'Определяются Ростехнадзором или его территориальным органом на основании экспертизы промышленной безопасности',
          'Определяются эксплуатирующей организацией или ее структурным подразделением на основании диагностирования',
        ],
        correctAnswers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
        ],
      },
      {
        code: '63653019',
        text:
          'Какое напряжение необходимо для энергообеспечения электрооборудования агрегатов для ремонта скважин?',
        answers: [
          'Не более 450 В',
          'Не более 500 В',
          'Не более 400 В',
          'Не более 600 В',
        ],
        correctAnswers: ['Не более 400 В'],
      },
      {
        code: '63653020',
        text:
          'Какие из перечисленных работников ОПО МНГК не должны дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП?',
        answers: [
          'Работники, осуществляющие непосредственное руководство и выполнение работ по бурению, освоению, ремонту и реконструкции скважин',
          'Работники, осуществляющие непосредственное руководство и выполнение работ по ведению геофизических и ПВР на скважинах',
          'Работники, осуществляющие авторский надзор и научное сопровождение внедрения технологических процессов, технических устройств и инструмента',
          'Все перечисленные работники должны проходить проверку знаний по контролю и управлению скважиной при ГНВП',
        ],
        correctAnswers: [
          'Работники, осуществляющие авторский надзор и научное сопровождение внедрения технологических процессов, технических устройств и инструмента',
        ],
      },
      {
        code: '63653021',
        text:
          'Каким давлением производится испытание пневматической системы буровой установки на месте производства работ (после монтажа, ремонта)?',
        answers: [
          'Давление испытания должно быть 1,25 рабочего давления, но не менее 3 кгс/см² превышающим рабочее',
          'Давление испытания должно быть 1,5 рабочего давления',
          'При проведении испытаний пневматической системы буровой установки на заводе-изготовителе испытания на месте производства работ не проводятся',
          'Давление испытания должно быть 1,25 рабочего давления',
        ],
        correctAnswers: [
          'Давление испытания должно быть 1,25 рабочего давления, но не менее 3 кгс/см² превышающим рабочее',
        ],
      },
      {
        code: '63653022',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63653023',
        text: 'Какие карты обязана иметь эксплуатирующая организация ОПО МНГК?',
        answers: [
          'Климатические карты района',
          'Только карты подводных коммуникаций в районе ведения работ',
          'Только карты надводных коммуникаций в районе ведения работ',
          'Все карты подводных и надводных коммуникаций в районе ведения работ',
        ],
        correctAnswers: [
          'Все карты подводных и надводных коммуникаций в районе ведения работ',
        ],
      },
      {
        code: '63653024',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Не моложе 18 лет',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
          'Прошедшие обучение приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63653025',
        text:
          'В каких случаях необходимо проверять изоляцию электрооборудования и исправность устройства защитного заземления буровой установки или скважины?',
        answers: [
          'Перед проведением промыслово-геофизических работ',
          'Перед проведением любых работ на ОПО',
          'Перед началом проведения буровых работ',
        ],
        correctAnswers: ['Перед проведением промыслово-геофизических работ'],
      },
      {
        code: '63653026',
        text:
          'Какие виды работ относятся к реконструкции нефтяных и газовых скважин в соответствии с Правилами безопасности в нефтяной и газовой промышленности?',
        answers: [
          'Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции',
          'Работы по оптимизации режима эксплуатации нефтяных и газовых скважин',
          'Работы по изоляции пластов',
          'Работы по восстановлению работоспособности скважин, утраченной в результате аварии или инцидента',
        ],
        correctAnswers: [
          'Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции',
        ],
      },
      {
        code: '63653027',
        text: 'Где фиксируются сведения о проведении вводного инструктажа?',
        answers: [
          'В специальных журналах',
          'В протоколе о прохождении инструктажа',
          'В актах',
          'В приказе по предприятию',
        ],
        correctAnswers: ['В специальных журналах'],
      },
      {
        code: '63653028',
        text:
          'В каких зонах классов взрывоопасности помещений морских стационарных платформ (далее - МСП), плавучих буровых установок (далее - ПБУ), морских эстакад с приэстакадными нефтегазодобывающими и буровыми площадками, искусственных островов (далее - МЭ) и плавучих технологических комплексов (далее - ПТК) допускается установка электрооборудования, не имеющего маркировки по взрывозащите?',
        answers: ['2', '0', '1', 'Все ответы неверны'],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63653029',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главным инженером эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63653030',
        text:
          'Разрешается ли исследование разведочных и эксплуатационных скважин в случае отсутствия утилизации жидкого продукта?',
        answers: [
          'Разрешается без ограничений',
          'Запрещается во всех случаях',
          'Разрешается по согласованию с противофонтанной службой',
          'Разрешается по решению территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Запрещается во всех случаях'],
      },
      {
        code: '63653031',
        text:
          'По истечении какого срока технологический регламент подлежит обязательному пересмотру?',
        answers: [
          'Двух пятилетних сроков',
          'Одного пятилетнего срока',
          'Трех пятилетних сроков',
          'Двух трехлетних сроков',
        ],
        correctAnswers: ['Двух пятилетних сроков'],
      },
      {
        code: '63653032',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63653033',
        text:
          'Какая вентиляция должна включаться перед пуском насоса, расположенного в помещении, и заполнением его нефтью на ОПО МНГК?',
        answers: [
          'Вытяжная вентиляция',
          'Приточная вентиляция',
          'Приточно-вытяжная вентиляция',
          'Не регламентируется',
        ],
        correctAnswers: ['Вытяжная вентиляция'],
      },
      {
        code: '63653034',
        text:
          'Что из перечисленного допускается при электрообеспечении и эксплуатации электрооборудования технологических систем ОПО МНГК?',
        answers: [
          'Применять стальные тросы и швартовы для заземления корпуса',
          'Допускается все перечисленное',
          'Присоединять заземление скруткой',
          'Шунтировать неэлектропроводные вставки электропроводными перемычками',
        ],
        correctAnswers: [
          'Шунтировать неэлектропроводные вставки электропроводными перемычками',
        ],
      },
      {
        code: '63653035',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
          'Лицо, зарегистрировавшее наряд-допуск не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63653036',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Увеличить число наблюдающих и работающих',
          'Работающему снять маску фильтрующего противогаза с исполнителя работ, продолжить работу',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Установить систему подачи условных сигналов',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63653037',
        text:
          'Кто оформляет акт о консервации (ликвидации) скважины на ОПО МНГК?',
        answers: [
          'Эксплуатирующая организация',
          'Геологическая служба организации-исполнителя работ',
          'Научно-исследовательская организация',
          'Ростехнадзор',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63653038',
        text:
          'В каком случае допускается одновременное проведение испытания и ремонтных работ электрооборудования технологических систем ОПО МНГК различными бригадами в пределах одного присоединения?',
        answers: [
          'При наличии письменного разрешения ответственного дежурного по энергоснабжению',
          'В присутствии наблюдающего',
          'Не допускается ни в каком случае',
          'При наличии наряда-задания у всех бригад',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63653039',
        text:
          'Какие из обязательных мер безопасного ведения газоопасных работ, предусмотренных правилами, указаны неверно?',
        answers: [
          'Исключение возможности присутствия на месте проведения газоопасной работы лиц, не занятых ее выполнением',
          'Обеспечение контроля за состоянием воздушной среды',
          'Выполнение работ I группы бригадой исполнителей не менее трех человек',
          'Обеспечение членов бригады СИЗ, спецодеждой, инструментом',
        ],
        correctAnswers: [
          'Выполнение работ I группы бригадой исполнителей не менее трех человек',
        ],
      },
      {
        code: '63653040',
        text:
          'Каким образом должно осуществляться управление энергетическими установками на ОПО МНГК?',
        answers: [
          'Управление энергетическими установками должно осуществляться только с центрального пульта управления',
          'Управление энергетическими установками должно осуществляться только с пультов, расположенных в помещениях каждой установки',
          'Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки',
          'Управление энергетическими установками должно осуществляться с пультов, расположенных в помещении жилого блока',
        ],
        correctAnswers: [
          'Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки',
        ],
      },
      {
        code: '63653041',
        text:
          'При каких условиях допускается повторное использование рабочего проекта при бурении группы скважин на идентичных по геолого-техническим условиям площадях?',
        answers: [
          'При выполнении всех перечисленных условий',
          'При отличии плотности бурового раствора от проектной в пределах ± 0,3 г/см3',
          'При одинаковом назначении и конструкции скважин',
          'При идентичности горно-геологических условий проводки и условий природопользования',
          'При одинаковых проектных глубинах по стволу скважин',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63653042',
        text:
          'Допускается ли не устанавливать молниеотводное устройство на возвышающихся над МСП, ПБУ, МЭ и ПТК конструкциях?',
        answers: [
          'Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК',
          'Не допускается ни в каком случае',
          'Допускается для отдельно стоящего оборудования',
        ],
        correctAnswers: [
          'Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК',
        ],
      },
      {
        code: '63653043',
        text:
          'Как часто проводится определение технического состояния заземляющего устройства?',
        answers: [
          'Один раз в месяц',
          'Один раз в три месяца',
          'Один раз в полгода',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63653044',
        text:
          'Какие требования предъявляются для защиты от статического электричества одиночно установленных технических устройств (оборудование, емкость, аппарат, агрегат)?',
        answers: [
          'Одиночно установленное техническое устройство заземляется только самостоятельно',
          'Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)',
          'Требования не регламентируются',
        ],
        correctAnswers: [
          'Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)',
        ],
      },
      {
        code: '63653045',
        text:
          'Какое напряжение должно применяться для питания переносных электрических светильников, используемых при работах в особо неблагоприятных условиях и наружных установках?',
        answers: ['Не выше 127 В', 'Не выше 50 В', 'Не выше 12 В'],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '63653046',
        text:
          'Кем производится пуск в работу смонтированной установки и оборудования?',
        answers: [
          'Двумя рабочими: электромонтером и рабочим бригады',
          'Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией',
          'Двумя рабочими: электромонтером и специалистом по охране труда эксплуатирующей организации',
          'Двумя рабочими бригады, прошедшими соответствующий инструктаж, при условии, что один из них имеет квалификационную группу не ниже второй',
        ],
        correctAnswers: [
          'Комиссией, состав и порядок работы которой устанавливается эксплуатирующей организацией',
        ],
      },
      {
        code: '63653047',
        text:
          'В каком случае допускается выполнение спуска перфоратора или торпеды в скважину и непосредственно первый отстрел перфоратора или взрыв торпеды с наступлением темноты?',
        answers: [
          'В присутствии наблюдающего',
          'При обосновании данной работы в плане безопасного ведения работ',
          'При обеспечении достаточного освещения рабочих мест и опасной зоны',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При обеспечении достаточного освещения рабочих мест и опасной зоны',
        ],
      },
      {
        code: '63653048',
        text:
          'Какая должна быть кратность воздухообмена в помещениях с взрывоопасными зонами?',
        answers: ['Не менее 6 в час', 'Не менее 8 в час', 'Не менее 10 в час'],
        correctAnswers: ['Не менее 6 в час'],
      },
      {
        code: '63653049',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63653050',
        text:
          'Какое из перечисленных организационно-технических требований на ОПО МНГК указано неверно?',
        answers: [
          'Вывод отработанного воздуха от циклонов осуществляется с одной из противоположных сторон ОПО МНГК в зависимости от направления ветра',
          'На трубопроводах пневмотранспортной системы в местах возможных отложений порошкообразного материала и образования пробок устанавливаются продувочные клапаны с подводом сжатого воздуха',
          'Крышка люка-лаза оснащается уплотнением и фиксирующим устройством в закрытом положении',
          'Все трубопроводы маркируются по типу транспортируемого агента с указанием направления его движения',
        ],
        correctAnswers: [
          'Крышка люка-лаза оснащается уплотнением и фиксирующим устройством в закрытом положении',
        ],
      },
      {
        code: '63653051',
        text:
          'Что из перечисленного не следует выполнять при консервации эксплуатационных скважин с надводным расположением устья?',
        answers: [
          'Снять штурвал контрольной задвижки',
          'Заглушить места установки манометров',
          'Отсоединить коммуникации арматуры',
          'Оборудовать фланцевыми заглушками внешние фланцы задвижек арматуры',
        ],
        correctAnswers: ['Снять штурвал контрольной задвижки'],
      },
      {
        code: '63653052',
        text: 'Каким оборудованием допускается проводить геофизические работы?',
        answers: [
          'Имеющим подтверждение соответствия и свидетельство о поверке приборов и средств измерения, входящих в их состав',
          'Имеющим подтверждение соответствия и сертификат о калибровке приборов и средств измерения, входящих в их состав',
          'Имеющим свидетельство о поверке приборов и средств измерения, входящих в их состав',
        ],
        correctAnswers: [
          'Имеющим подтверждение соответствия и свидетельство о поверке приборов и средств измерения, входящих в их состав',
        ],
      },
      {
        code: '63653053',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии технического руководителя организации и начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63653054',
        text:
          'Какое из перечисленных требований при формировании подводного устья скважины указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Перед началом бурения скважины руководитель эксплуатирующей организации утверждает акт готовности буровой установки на пуск буровой установки в работу',
          'Допускаются работы по формированию устья скважины без стабилизации (ориентации) ПБУ на точке бурения с участием представителя Ростехнадзора',
          'Установка опорной плиты (при ее наличии) на створках спайдерной площадки проводится так, чтобы центр плиты совпадал с осью спускаемого инструмента (центром ротора)',
          'Распоряжение ответственного лица о начале работ по формированию подводного устья скважины фиксируется в судовом и буровом журналах',
        ],
        correctAnswers: [
          'Установка опорной плиты (при ее наличии) на створках спайдерной площадки проводится так, чтобы центр плиты совпадал с осью спускаемого инструмента (центром ротора)',
          'Распоряжение ответственного лица о начале работ по формированию подводного устья скважины фиксируется в судовом и буровом журналах',
        ],
      },
      {
        code: '63653055',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, список которых определяется внутренними документами организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63653056',
        text:
          'Кто имеет право на подключение переносных светильников и разводку кабелей в полевых условиях?',
        answers: [
          'Электромонтер',
          'Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет группу по электробезопасности не ниже II',
          'Правилами не регламентируется',
          'Двое рабочих бригады, причем один из них должен иметь группу по электробезопасности не ниже II',
        ],
        correctAnswers: [
          'Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет группу по электробезопасности не ниже II',
        ],
      },
      {
        code: '63653057',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63653058',
        text:
          'Кем должны быть приняты законченные строительно-монтажные работы на ОПО МНГК или их составляющих?',
        answers: [
          'Комиссией Росприроднадзора',
          'Комиссией Ростехнадзора',
          'Комиссией, назначенной приказом эксплуатирующей организации',
        ],
        correctAnswers: [
          'Комиссией, назначенной приказом эксплуатирующей организации',
        ],
      },
      {
        code: '63653059',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны пройти инструктаж',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63653060',
        text:
          'С какой периодичностью проводится проверка знаний по видам тревог во время учебных и тренировочных занятий на МСП, ПБУ, МЭ и ПТК?',
        answers: [
          'Не реже одного раза в неделю',
          'Не реже двух раз в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63653061',
        text:
          'Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих и серосодержащих жидкостей?',
        answers: [
          'Запрещается в любом случае',
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
          'Разрешается',
          'Разрешается по согласованию с проектной организацией',
        ],
        correctAnswers: [
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
        ],
      },
      {
        code: '63653062',
        text:
          'В каком порядке производятся передача скважин для ремонта или реконструкции и приемка скважин после завершения ремонтных работ от заказчика подрядчику?',
        answers: [
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ, устанавливается эксплуатирующей организацией',
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ, устанавливается нормативно-технической документацией Ростехнадзора',
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ, устанавливается подрядной организацией и утверждается заказчиком',
        ],
        correctAnswers: [
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ, устанавливается эксплуатирующей организацией',
        ],
      },
      {
        code: '63653063',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63653064',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63653065',
        text:
          'Куда направляется сообщение о сбрасывании контейнеров с ВМ в море при аварийной ситуации на МСП, ПБУ, МЭ или ПТК и невозможности срочной перегрузки ВМ на спасательное или дежурное судно? Выберите два правильных варианта ответа.',
        answers: [
          'В правоохранительные органы',
          'В гидрографическую службу',
          'В Ростехнадзор',
          'В Роснедра',
        ],
        correctAnswers: [
          'В правоохранительные органы',
          'В гидрографическую службу',
        ],
      },
      {
        code: '63653066',
        text:
          'Кем выдаются разрешения на выполнение отдельных технологических операций и применение ограничений эксплуатации бурового оборудования, а также указания о прекращении бурения и отсоединении бурового райзера по гидрометеорологическим условиям?',
        answers: [
          'Начальником ПБУ',
          'Вахтенным помощником капитана судна',
          'Капитаном судна',
          'Заместителем начальника по технологии бурения',
        ],
        correctAnswers: ['Начальником ПБУ'],
      },
      {
        code: '63653067',
        text:
          'Какое минимальное количество превенторов должно быть в составе противовыбросового оборудования (далее - ПВО)?',
        answers: ['Два', 'Четыре', 'Три', 'Пять'],
        correctAnswers: ['Четыре'],
      },
      {
        code: '63653068',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники подрядной организации',
          'Работники, указанные в наряде-допуске',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63653069',
        text:
          'Допускается ли последовательное соединение между собой заземляющих устройств разных зданий, сооружений, установок с помощью одного заземляющего проводника?',
        answers: [
          'Допускается',
          'Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом',
          'Не допускается ни в каком случае',
          'Не допускается, за исключением аппаратов или резервуаров, установленных в одном обваловании',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63653070',
        text:
          'Какие меры безопасности должны быть предусмотрены при проведении ремонтных работ или укладке кабеля после ремонта?',
        answers: [
          'Включение питания проводится ответственным дежурным по энергоснабжению после устного подтверждения руководителя работ и личного контроля об окончании этих работ',
          'При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель',
          'До начала ремонтных работ после подъема поврежденного кабеля на борт кабелеукладочного судна кабель отключается хотя бы с одной стороны и заземляется',
          'Одновременное проведение испытания и ремонтных работ различными бригадами в пределах одного присоединения допускается по одному наряду-заданию с указанием в строке "Отдельные указания" дополнительных мер, обеспечивающих безопасность работников',
        ],
        correctAnswers: [
          'При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель',
        ],
      },
      {
        code: '63653071',
        text:
          'После чего производится сдача в работу смонтированной буровой установки?',
        answers: [
          'Только после проверки качества заземления оборудования и заземляющих устройств',
          'Только после испытания на герметичность нагнетательных трубопроводов, воздухопроводов',
          'Только после испытания систем управления оборудованием и блокировок',
          'Сдача в работу смонтированной буровой установки производится после проведения всех вышеперечисленных действий',
        ],
        correctAnswers: [
          'Сдача в работу смонтированной буровой установки производится после проведения всех вышеперечисленных действий',
        ],
      },
      {
        code: '63653072',
        text:
          'Каким проверкам подвергается подводный трубопровод после капитального ремонта?',
        answers: [
          'Проверкам роботизированными подводными аппаратами',
          'Проверкам методами неразрушающего контроля',
          'Проверкам на прочность и герметичность',
        ],
        correctAnswers: ['Проверкам на прочность и герметичность'],
      },
      {
        code: '63653073',
        text:
          'Каким образом и с какой периодичностью должна проводиться очистка воздухопроводов энергетических установок от масляных наслоений?',
        answers: [
          'Очистку воздуховодов проводить не следует. Достаточно осуществлять их продувку сжатым воздухом каждый месяц',
          'Очистка воздухопроводов должна проводиться ежегодно паром или допущенным к применению моющим средством с последующей продувкой сжатым воздухом',
          'Очистка воздухопроводов должна проводиться не реже одного раза в шесть месяцев паром или допущенным к применению моющим средством с последующей продувкой сжатым воздухом',
          'Очистка воздухопроводов должна проводиться в случае, когда величина масляных наслоений превысит допускаемые значения. Очищаются поверхности продувкой паром с последующей продувкой сжатым воздухом',
        ],
        correctAnswers: [
          'Очистка воздухопроводов должна проводиться не реже одного раза в шесть месяцев паром или допущенным к применению моющим средством с последующей продувкой сжатым воздухом',
        ],
      },
      {
        code: '63653074',
        text:
          'В каком исполнении должны быть технические устройства, применяемые во взрывопожароопасных зонах?',
        answers: [
          'Во взрывозащищенном',
          'В пылевлагонепроницаемом',
          'Во взрывонепроницаемом',
          'В брызгозащищенном',
        ],
        correctAnswers: ['Во взрывозащищенном'],
      },
      {
        code: '63653075',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания медицинской помощи',
          'О самочувствии',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63653076',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Непосредственный руководитель работ и лица ответственные за подготовку к ремонтным работам',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63653077',
        text:
          'Как организована система сброса с предохранительных клапанов технологического оборудования на опасных производственных объектах (далее - ОПО) морских нефтегазовых комплексов (далее - МНГК)?',
        answers: [
          'Сбросы с предохранительных клапанов технологического оборудования направляются в общую систему сточных вод, а газ утилизируется',
          'Сбросы с предохранительных клапанов технологического оборудования, а также из коммуникаций направляются в емкость (каплеотбойник), а газ - на факел или свечу рассеивания',
          'Сбросы с предохранительных клапанов технологического оборудования направляются в закрытую емкость, а газ - в атмосферу',
        ],
        correctAnswers: [
          'Сбросы с предохранительных клапанов технологического оборудования, а также из коммуникаций направляются в емкость (каплеотбойник), а газ - на факел или свечу рассеивания',
        ],
      },
      {
        code: '63653078',
        text:
          'Какую минимальную группу по электробезопасности должны иметь лица, допускаемые к управлению грузоподъемными устройствами на ОПО МНГК?',
        answers: [
          'II группу',
          'IV группу',
          'III группу',
          'V группу',
          'Не регламентируется',
        ],
        correctAnswers: ['II группу'],
      },
      {
        code: '63653079',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений хранение токсичных жидкостей в резервуарах с "атмосферным" дыханием?',
        answers: [
          'Не допускается ни в каком случае',
          'Допускается в любом случае',
          'Допускается при условии оснащения резервуаров системой аварийного слива жидкости в дренажную систему',
          'Допускается при условии оснащения резервуаров сигнализацией предельного верхнего уровня заполнения резервуара, сблокированной с насосным оборудованием',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63653080',
        text:
          'Какой документ определяет технологию ведения процесса или отдельных его стадий (операций), режимы и рецептуру производства продукции, показатели качества продукции и безопасные условия работы?',
        answers: [
          'Производственная методика',
          'Инструкция ведения технологического процесса',
          'Задание на безопасное производство продукции',
          'Технологический регламент',
          'Правила ведения технологического процесса',
        ],
        correctAnswers: ['Технологический регламент'],
      },
      {
        code: '63653081',
        text:
          'Какие требования предъявляются к выхлопным трубам энергетических установок?',
        answers: [
          'Выхлопные трубы могут располагаться в помещении при условии расположения выхлопов не ниже 4 м над рабочей зоной персонала',
          'Выхлопные трубы должны выводиться из помещений наружу с учетом преобладающего направления ветра и оборудуются глушителями-искрогасителями',
          'Выхлопные трубы могут не выводиться из помещения при условии соблюдения правил пожарной безопасности',
          'Выхлопные трубы могут не выводиться из помещения при наличии письменного разрешения Ростехнадзора',
        ],
        correctAnswers: [
          'Выхлопные трубы должны выводиться из помещений наружу с учетом преобладающего направления ветра и оборудуются глушителями-искрогасителями',
        ],
      },
      {
        code: '63653082',
        text:
          'Какой документ является основным на производство буровых работ согласно Правилам безопасности в нефтяной и газовой промышленности?',
        answers: [
          'План строительства',
          'Задание на проектирование',
          'Рабочий проект',
          'План на производство работ',
        ],
        correctAnswers: ['Рабочий проект'],
      },
      {
        code: '63653083',
        text:
          'Какое судно должно постоянно находиться в период опробования скважины вблизи МСП, ПБУ, МЭ и ПТК?',
        answers: [
          'Пожарное судно',
          'Аварийно-спасательное судно',
          'Вспомогательное судно',
        ],
        correctAnswers: ['Аварийно-спасательное судно'],
      },
      {
        code: '63653084',
        text:
          'Каким требованиям должны соответствовать электрические сети на МСП, ПБУ, МЭ и ПТК?',
        answers: [
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор',
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 0,4 кВ заземляется через высокоомный резистор',
          'Должны быть изолированными, в том числе сети двухфазного переменного тока напряжением 220 В',
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с неизолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через низкоомный резистор',
        ],
        correctAnswers: [
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор',
        ],
      },
      {
        code: '63653085',
        text:
          'В каком из положений нарушены требования к энергетическим установкам?',
        answers: [
          'Пуск аварийной энергоустановки обеспечивается без потребления электроэнергии извне',
          'В качестве аварийной энергоустановки, как правило, должен применяться бензиновый генератор, оборудованный системой автоматического пуска',
          'Аварийная энергетическая установка размещается в отдельном помещении, исключающем ее повреждение при авариях в месте размещения основных и вспомогательных энергетических установок',
          'Управление энергетическими установками осуществляется как с центрального пульта управления (далее - ЦПУ), так и с пультов, расположенных в помещениях каждой установки',
        ],
        correctAnswers: [
          'В качестве аварийной энергоустановки, как правило, должен применяться бензиновый генератор, оборудованный системой автоматического пуска',
        ],
      },
      {
        code: '63653086',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
        ],
      },
      {
        code: '63653087',
        text:
          'Какие данные должны быть указаны на металлической табличке, укрепляемой на видном месте мачты агрегата по ремонту скважин?',
        answers: [
          'Грузоподъемность и дата последнего технического освидетельствования',
          'Грузоподъемность и дата очередного технического освидетельствования',
          'Наименование организации, владеющей данным агрегатом, и заводской номер',
        ],
        correctAnswers: [
          'Грузоподъемность и дата очередного технического освидетельствования',
        ],
      },
      {
        code: '63653088',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63653089',
        text:
          'При каком превышении давления должны срабатывать предохранительные устройства насоса?',
        answers: [
          'При давлении, превышающем на 10% рабочее давление насоса, соответствующего диаметру установленных цилиндровых втулок',
          'При давлении, превышающем на 20% рабочее давление насоса, соответствующего диаметру установленных цилиндровых втулок',
          'При давлении, превышающем на 3% рабочее давление насоса, соответствующего диаметру установленных цилиндровых втулок',
          'При давлении, превышающем на 15% рабочее давление насоса, соответствующего диаметру установленных цилиндровых втулок',
        ],
        correctAnswers: [
          'При давлении, превышающем на 10% рабочее давление насоса, соответствующего диаметру установленных цилиндровых втулок',
        ],
      },
      {
        code: '63653090',
        text:
          'Какие из перечисленных документов обязательны для изучения работниками на ОПО МНГК?',
        answers: [
          'Только инструкция по предупреждению ГНВП и открытых фонтанов',
          'Только ПЛА',
          'Только расписание по тревогам',
          'Все перечисленные документы',
        ],
        correctAnswers: ['Все перечисленные документы'],
      },
      {
        code: '63653091',
        text:
          'Цементный мост какой высоты устанавливается во всех ликвидируемых скважинах в последней (наименьшей) обсадной колонне, связанной с устьем скважины, с расположением кровли цементного моста на уровне дна моря?',
        answers: [
          'Не менее 50 м',
          'Не менее 100 м',
          'Не менее 60 м',
          'Не менее 40 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63653092',
        text:
          'Какие инструктажи необходимо провести до начала работ по формированию устья скважины?',
        answers: [
          'Руководитель буровых работ проводит инструктаж работникам по основным технологическим особенностям работ, связанных с формированием подводного устья скважины, а также по безопасной эксплуатации буровой установки',
          'Руководитель буровых работ проводит инструктаж работников по контролю за ГНВП',
          'Руководитель буровых работ проводит инструктаж персонала по требованиям безопасности по работам с электрооборудованием и грузоподъемными механизмами',
        ],
        correctAnswers: [
          'Руководитель буровых работ проводит инструктаж работникам по основным технологическим особенностям работ, связанных с формированием подводного устья скважины, а также по безопасной эксплуатации буровой установки',
        ],
      },
      {
        code: '63653093',
        text:
          'Что должна обеспечивать лебедка при проведении спуско-подъемных операций?',
        answers: [
          'Вращение барабана с канатом на протяжении всей спуско-подъемной операции с фиксированной скоростью',
          'Вращение барабана с канатом в любых желаемых диапазонах скоростей и с фиксированной нагрузкой на канат (проволоку)',
          'Номинальную скорость вращения барабана с нагрузкой на крюке, превышающую расчетную на 10%',
          'Аварийное торможение с помощью рабочего тормозного зажима',
        ],
        correctAnswers: [
          'Вращение барабана с канатом в любых желаемых диапазонах скоростей и с фиксированной нагрузкой на канат (проволоку)',
        ],
      },
      {
        code: '63653094',
        text:
          'С кем согласовывается документация на ликвидацию или консервацию скважин с подводным расположением устья?',
        answers: [
          'С соответствующей гидрографической службой',
          'С Ростехназдором',
          'С ПАСФ',
          'С газоспасательной службой',
        ],
        correctAnswers: ['С соответствующей гидрографической службой'],
      },
      {
        code: '63653095',
        text:
          'Каким требованиям должны соответствовать трубопроводы, проложенные от устья скважин до технологических установок?',
        answers: [
          'Должны быть проложены в один ярус',
          'Должны быть рассчитаны на полуторакратное рабочее давление',
          'В начале и конце трубопровода краской должны быть нанесены номер скважины и направление потока',
          'Должны соответствовать всем перечисленным требованиям',
        ],
        correctAnswers: [
          'Должны соответствовать всем перечисленным требованиям',
        ],
      },
      {
        code: '63653096',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63653097',
        text:
          'Кем разрабатывается план действий по перевозке отказавшей аппаратуры (торпеды) с неизвлекаемыми средствами взрывания, в случае невозможности уничтожения на месте работы?',
        answers: [
          'Организацией - исполнителем ПВР',
          'Эксплуатирующей организацией',
          'Ростехнадзором',
        ],
        correctAnswers: ['Организацией - исполнителем ПВР'],
      },
      {
        code: '63653098',
        text:
          'При выполнении какого условия допускается размещение плавучих буровых установок (далее - ПБУ) на месте производства работ, постановка обслуживающих судов на якоря и производство работ в охранной зоне ЛЭП, кабелей связи, морских трубопроводов и других сооружений?',
        answers: [
          'В случае поступающей допустимой гидрометеорологической информации',
          'При наличии письменного согласования с эксплуатирующей их организацией',
          'Не допускается ни в каком случае',
          'При наличии письменного разрешения Ростехнадзора',
        ],
        correctAnswers: [
          'При наличии письменного согласования с эксплуатирующей их организацией',
        ],
      },
      {
        code: '63653099',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Только специалист, прошедший обучение по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63653100',
        text:
          'Какая из перечисленных надписей не указывается на каждой консервируемой скважине?',
        answers: [
          'Дата и срок введения скважины в эксплуатацию',
          'Наименование месторождения (площади)',
          'Номер скважины',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Дата и срок введения скважины в эксплуатацию'],
      },
      {
        code: '63653101',
        text:
          'Какие из приведенных помещений (пространств) относятся к зоне 1 (участок, на котором может присутствовать взрывоопасная смесь в нормальном рабочем режиме)?',
        answers: [
          'Закрытые помещения для хранения шлангов для перекачки легковоспламеняющихся жидкостей',
          'Открытые пространства вокруг закрытых и открытых технических устройств, оборудования',
          'Закрытые помещения насосных для сточных вод',
          'Пространство внутри открытых и закрытых технических устройств и емкостей, содержащих нефть, буровой раствор, обработанный нефтью, нефтяные газы или другие легковоспламеняющиеся вещества',
        ],
        correctAnswers: ['Закрытые помещения насосных для сточных вод'],
      },
      {
        code: '63653102',
        text: 'Какое из перечисленных требований к ОПО МНГК указано неверно?',
        answers: [
          'Продувка, разрядка и прокачка коммуникаций должны осуществляться с последующим разделением и утилизацией жидкости и газа',
          'На объектах управления следует иметь сигнальные устройства предупреждения об отключении объектов и обратную связь с ЦПУ',
          'На каждый управляемый с ЦПУ объект следует иметь систему блокировки и ручное управление непосредственно на объекте',
          'Для обеспечения одновременного бурения новых и эксплуатации действующих скважин предусматривается расположение устьев скважин, законченных бурением, на верхнем ярусе (уровне) МСП',
        ],
        correctAnswers: [
          'Для обеспечения одновременного бурения новых и эксплуатации действующих скважин предусматривается расположение устьев скважин, законченных бурением, на верхнем ярусе (уровне) МСП',
        ],
      },
      {
        code: '63653103',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63653104',
        text:
          'Что необходимо выполнить перед началом транспортирования оборудования на скважину?',
        answers: [
          'Подготовить планы работ и утвердить в установленном порядке',
          'Согласовать с соответствующими организациями условия пересечения ЛЭП, железнодорожных магистралей, магистральных трубопроводов',
          'Проверить готовность трассы передвижения агрегатов',
          'Заключить договоры на производство работ с подрядчиками',
          'Необходимо выполнить все перечисленные условия',
        ],
        correctAnswers: ['Необходимо выполнить все перечисленные условия'],
      },
      {
        code: '63653105',
        text:
          'В каком случае следует прекратить все работы на соседних объектах кустовых площадок?',
        answers: [
          'Во всех перечисленных случаях все работы на соседних объектах должны быть прекращены',
          'Только в случае испытания вышек',
          'Только в случае ведения сложных аварийных работ на скважине',
          'Только в случае передвижки вышечно-лебедочного блока на новую точку (позицию)',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях все работы на соседних объектах должны быть прекращены',
        ],
      },
      {
        code: '63653106',
        text:
          'С какой периодичностью на скважинах, находящихся в консервации, проводятся проверки состояния оборудования и наличия избыточного давления на устье с составлением акта обследования?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в пять лет',
          'Данные проверки не проводятся',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63653107',
        text:
          'Какие из перечисленных расписаний по тревогам предусматриваются на ОПО МНГК?',
        answers: [
          'Только шлюпочная тревога',
          'Только "Человек за бортом"',
          'Только общесудовая тревога',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63653108',
        text:
          'После выполнения какого условия работникам разрешается приступить к демонтажу буровой установки на электроприводе?',
        answers: [
          'После получения письменного подтверждения работника, ответственного за эксплуатацию электрооборудования, об отключении установки от электросети',
          'После подачи звукового сигнала оператором буровой установки о снятии напряжения на установке',
          'После получения информации от вышкомонтажника-электромонтера о снятии напряжения на буровой установке',
          'После получения устного подтверждения главного энергетика организации об отключении установки от электросети',
        ],
        correctAnswers: [
          'После получения письменного подтверждения работника, ответственного за эксплуатацию электрооборудования, об отключении установки от электросети',
        ],
      },
      {
        code: '63653109',
        text:
          'Какие данные не регистрируются и не контролируются на ПБУ с динамической системой позиционирования и (или) с якорной системой удержания?',
        answers: [
          'Осадка судна',
          'Высота волны',
          'Угол наклона бурового райзера',
          'Скорость течения',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63653110',
        text:
          'Каким документом устанавливаются периодичность и способы проверки состояния обсадных колонн по мере их износа и необходимые мероприятия по обеспечению безопасной проводки и эксплуатации скважин?',
        answers: [
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
          'Регламентом, разработанным в соответствии с проектом',
          'Методическими указаниями проектной организации',
          'Правилами безопасности в нефтяной и газовой промышленности',
        ],
        correctAnswers: [
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
        ],
      },
      {
        code: '63653111',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'До окончания работ на конкретном месте',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63653112',
        text:
          'В каком случае должно обеспечиваться полное отключение оборудования и механизмов в закрытых помещениях буровой установки, где возможны возникновение или проникновение воспламеняющихся смесей?',
        answers: [
          'При достижении 20% от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 30% от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 40% от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 50% от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
        correctAnswers: [
          'При достижении 50% от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
      },
      {
        code: '63653113',
        text:
          'В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.',
        answers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
          'Когда газоопасные работы могут быть проведены без непосредственного участия людей',
        ],
        correctAnswers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
        ],
      },
      {
        code: '63653114',
        text:
          'Какое из перечисленных действий перед проведением прострелочно-взрывных работ указано неверно?',
        answers: [
          'Оповестить радиостанции, находящиеся на связи, о прекращении работы радиостанции на МСП, ПБУ или МЭ до специального разрешения',
          'Включить активную катодную защиту',
          'Отключить мобильные (носимые) радиостанции внутренней связи',
          'Прекратить погрузочно-разгрузочные работы',
        ],
        correctAnswers: ['Включить активную катодную защиту'],
      },
      {
        code: '63653115',
        text:
          'Какой установлен срок действия технологического регламента на действующем ОПО при опробовании нового оборудования?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['2 года'],
      },
      {
        code: '63653116',
        text:
          'Какие данные не указываются в рабочем проекте на бурение скважин?',
        answers: [
          'Условия расчета обсадных и насосно-компрессорных (лифтовых) колонн исходя из порогового напряжения сталей труб, принимаемых не выше 0,75 от предела текучести',
          'Типы нейтрализаторов, методы и технология нейтрализации сернистого водорода в буровом растворе, а также расход реагентов для этих целей на весь процесс бурения скважины',
          'Методы и периодичность проверки износа и контроля коррозионного состояния бурильных, ведущих, насосно-компрессорных труб (далее - НКТ) и элементов трубных колонн',
          'Мероприятия по предупреждению и раннему обнаружению газонефтеводопроявлений',
          'Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн',
        ],
        correctAnswers: [
          'Конструкции скважин, диаметры и глубины спуска эксплуатационных и лифтовых колонн',
        ],
      },
      {
        code: '63653117',
        text:
          'Какое расстояние должно быть между устьями скважин при их размещении на кустовых площадках вечномерзлых грунтов?',
        answers: [
          'Должно быть не меньше 1,2 диаметра растепления пород вокруг устья скважины',
          'Должно быть равно радиусу растепления пород вокруг устья скважины',
          'Должно быть не менее 100 м',
          'Должно быть не менее 50 м',
        ],
        correctAnswers: [
          'Должно быть не меньше 1,2 диаметра растепления пород вокруг устья скважины',
        ],
      },
      {
        code: '63653118',
        text:
          'Какие работы не запрещается производить во время стоянки нефтеналивного судна у причала?',
        answers: [
          'Подход к нему и швартовка судов и иных плавсредств, не связанных с операциями по наливу нефти',
          'Отогревание замерзших трубопроводов паром',
          'Налив нефтепродуктов при грозовых разрядах',
        ],
        correctAnswers: ['Отогревание замерзших трубопроводов паром'],
      },
      {
        code: '63653119',
        text:
          'Какая из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указана неверно?',
        answers: [
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63653120',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
          'Начальнику цеха',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Пожарной службе эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63653121',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
          'Разработать проект производства работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Составить план подготовительных работ',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63653122',
        text: 'На кого возлагается общее руководство буровыми работами на ПБУ?',
        answers: [
          'На заместителя начальника по технологии бурения',
          'На начальника ОПО МНГК',
          'На капитана ПБУ',
          'На главного инженера ПБУ',
        ],
        correctAnswers: ['На начальника ОПО МНГК'],
      },
      {
        code: '63653123',
        text:
          'Какие действия не следует проводить перед испытанием и освоением скважин ОПО МНГК?',
        answers: [
          'Проверять узлы крепления и стрелы факела, системы трубопроводов устройства для сжигания продукции скважины',
          'Поджигать дежурную горелку факела',
          'Проверять комплектность и готовность средств пожаротушения и спасательных средств',
          'Опрессовывать сепаратор с обвязкой',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63653124',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63653125',
        text:
          'Какое из перечисленных требований к проведению ПГР на ОПО МНГК указано неверно?',
        answers: [
          'Допускается проводить ПГР в скважинах при ГНВП и поглощении с разрешения начальника ОПО МНГК',
          'При ПГР обеспечивается двусторонняя связь между операторами, находящимися в помещении лаборатории, у пульта управления лебедкой и на устье скважины',
          'Каротажный подъемник устанавливается так, чтобы ось барабана была перпендикулярна к плоскости, проходящей через середину барабана и устье скважины',
          'Перед перфорацией скважины проверяется герметичность обвязки устьев всех скважин на МСП, ПБУ, МЭ или ПТК. Обнаруженные пропуски ликвидируются и составляется акт готовности скважины к проведению перфорации',
        ],
        correctAnswers: [
          'Допускается проводить ПГР в скважинах при ГНВП и поглощении с разрешения начальника ОПО МНГК',
        ],
      },
      {
        code: '63653126',
        text:
          'Как проверяется наличие мостов при ликвидации скважин без эксплуатационной колонны?',
        answers: [
          'Методом гидравлической опрессовки',
          'Разгрузкой бурильного инструмента или насосно-компрессорных труб с усилием, не превышающим предельно допустимую удельную нагрузку на цементный камень',
          'Путем проведения исследовательских работ',
        ],
        correctAnswers: [
          'Разгрузкой бурильного инструмента или насосно-компрессорных труб с усилием, не превышающим предельно допустимую удельную нагрузку на цементный камень',
        ],
      },
      {
        code: '63653127',
        text:
          'Как оборудуется устье скважины при ликвидации скважин, пробуренных с ПБУ?',
        answers: [
          'На устье скважины устанавливается бетонная тумба с репером высотой не менее 5 м',
          'На устье скважины устанавливается репер',
          'Выступающая над дном моря обсадная колонна (в случае, если при бурении скважины не использовались специальная система придонных подвесок, колонные головки) удаляется на уровень дна моря',
        ],
        correctAnswers: [
          'Выступающая над дном моря обсадная колонна (в случае, если при бурении скважины не использовались специальная система придонных подвесок, колонные головки) удаляется на уровень дна моря',
        ],
      },
      {
        code: '63653128',
        text:
          'При каком условии закрытое помещение МСП, ПБУ, МЭ и ПТК, непосредственно сообщающееся с помещением с зоной класса взрывоопасности 0, относится к помещениям с зоной класса взрывоопасности 1? Выберите два правильных варианта ответа.',
        answers: [
          'Вентиляция обеспечивает избыточное давление по отношению к зоне 0',
          'Предусмотрена сигнализация на посту управления с постоянной вахтой, срабатывающая при прекращении действия вентиляции',
          'Доступ осуществляется через двойные самозакрывающиеся газонепроницаемые двери, образующие воздушный тамбур-шлюз, в котором создается подпор воздуха механической приточной вентиляцией',
        ],
        correctAnswers: [
          'Вентиляция обеспечивает избыточное давление по отношению к зоне 0',
          'Предусмотрена сигнализация на посту управления с постоянной вахтой, срабатывающая при прекращении действия вентиляции',
        ],
      },
      {
        code: '63653129',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63653130',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С газоспасательной службой и службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества)',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63653131',
        text:
          'Для каких целей во взрывоопасных технологических процессах должны быть предусмотрены системы противоаварийной защиты, противопожарной защиты и газовой безопасности?',
        answers: [
          'Для срабатывания предупредительной сигнализации о возникновении аварийных ситуаций при отклонении от предусмотренных регламентом предельно допустимых параметров во всех режимах работы',
          'Для автоматического форсирования технологического процесса',
          'Для ликвидации аварийных ситуаций в автоматическом режиме',
          'Для обеспечения безопасной остановки или перевода процесса в безопасное состояние в случае критического отклонения от предусмотренных технологическим регламентом параметров',
        ],
        correctAnswers: [
          'Для обеспечения безопасной остановки или перевода процесса в безопасное состояние в случае критического отклонения от предусмотренных технологическим регламентом параметров',
        ],
      },
      {
        code: '63653132',
        text:
          'Какая организация отвечает за безопасное ведение промыслово-геофизических работ на МСП, ПБУ, МЭ, ПТК и хранение ВМ?',
        answers: [
          'Организация - исполнитель буровых работ',
          'Эксплуатирующая организация',
          'Научно-исследовательская организация',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63653133',
        text:
          'Допускается ли пересечение технологических трубопроводов с газом, ЛВЖ, ГЖ, с трубопроводами негорючих веществ на ОПО МНГК?',
        answers: [
          'Допускается при наличии разрешения Ростехнадзора',
          'Допускается, если трубопроводы негорючих веществ располагаются снизу',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если трубопроводы негорючих веществ располагаются снизу',
        ],
      },
      {
        code: '63653134',
        text:
          'В каком случае допускается применение устройств, изменяющих противодавление на выхлопе и разряжение на входе двигателя энергетической установки ОПО МНГК?',
        answers: [
          'При наличии письменного разрешения лица, ответственного за электрообеспечение',
          'Размещенных вне взрывоопасных помещений и зон',
          'Не допускается ни в каком случае',
          'Согласованных с заводом-изготовителем',
        ],
        correctAnswers: ['Согласованных с заводом-изготовителем'],
      },
      {
        code: '63653135',
        text:
          'Каким образом выполняется присоединение заземляющих проводников к заземлителю и заземляемым конструкциям?',
        answers: [
          'Винтовыми соединениями',
          'Заклепочными соединениями',
          'Болтовыми соединениями',
          'Сваркой',
        ],
        correctAnswers: ['Сваркой'],
      },
      {
        code: '63653136',
        text: 'В каком случае разрешаются швартовка судов к ПБУ на ОПО МНГК?',
        answers: [
          'Не регламентируется',
          'Если это оговорено в судовой документации',
          'Только при благоприятных метеоусловиях в специально отведенном месте с разрешения капитана ПБУ',
        ],
        correctAnswers: [
          'Только при благоприятных метеоусловиях в специально отведенном месте с разрешения капитана ПБУ',
        ],
      },
      {
        code: '63653137',
        text:
          'Кем устанавливается состав работников геофизической партии и буровой бригады, находящихся при ПВР в пределах опасной зоны?',
        answers: [
          'Только организацией - исполнителем ПВР',
          'Только организацией - исполнителем буровых работ',
          'Только эксплуатирующей организацией',
          'Совместно всеми перечисленными организациями',
        ],
        correctAnswers: ['Совместно всеми перечисленными организациями'],
      },
      {
        code: '63653138',
        text:
          'Кто утверждает перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право выдавать и утверждать наряды-допуски?',
        answers: [
          'Специалист по охране труда',
          'Руководитель организации или уполномоченное им лицо',
          'Технический руководитель организации',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Руководитель организации или уполномоченное им лицо'],
      },
      {
        code: '63653139',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'По решению руководителя эксплуатирующей организации',
          'Нужно в любом случае',
          'Правилами не регламентируется',
          'Решение принимается совместно руководителем эксплуатирующей организации и руководителем газоспасательной службы',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63653140',
        text:
          'Кто постоянно контролирует положение ПБУ над скважиной, горизонтальные перемещения ПБУ и угол наклона бурового райзера при бурении и выполнении других технологических операций?',
        answers: [
          'Капитан ПБУ',
          'Начальник внешней связи',
          'Начальник ПБУ',
          'Оператор системы позиционирования ПБУ',
        ],
        correctAnswers: ['Оператор системы позиционирования ПБУ'],
      },
      {
        code: '63653141',
        text:
          'С какой периодичностью и в какой период проводится обследование опорной части ОПО МНГК в целях определения воздействия на нее ледовых образований?',
        answers: [
          'Раз в два года в период отсутствия льда на водном пространстве',
          'Дважды в год в период отсутствия льда на водном пространстве и в период наличия льда на водном пространстве',
          'Ежегодно вне зависимости от периода',
          'Не реже чем один раз в три года в период отсутствия льда на водном пространстве',
        ],
        correctAnswers: [
          'Не реже чем один раз в три года в период отсутствия льда на водном пространстве',
        ],
      },
      {
        code: '63653142',
        text: 'Какие требования к емкости для долива скважины указаны неверно?',
        answers: [
          'Емкость должна быть обвязана с устьем скважины с таким расчетом, чтобы обеспечивался постоянный долив жидкости в скважину самотеком или принудительно с использованием насоса',
          'Емкость должна быть стационарной или передвижной (автоцистерна любого типа)',
          'Емкость должна устанавливаться на расстоянии пяти метров от устья ремонтируемой скважины в зоне видимости бурильщика КРС (оператора ТРС)',
          'Емкость (автоцистерна) должна быть оборудована показывающим замерным устройством (уровнемером), имеющим градуировку с ценой деления 0,2 м3',
        ],
        correctAnswers: [
          'Емкость должна устанавливаться на расстоянии пяти метров от устья ремонтируемой скважины в зоне видимости бурильщика КРС (оператора ТРС)',
        ],
      },
      {
        code: '63653143',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63653144',
        text:
          'В каком из перечисленных случаев должно проводиться забуривание новых (боковых) стволов в обсаженных скважинах?',
        answers: [
          'При ликвидации сложных аварий',
          'При вскрытии дополнительных продуктивных мощностей',
          'При восстановлении бездействующего фонда скважин',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63653145',
        text:
          'На каком расстоянии проводится крепление шлангокабелей системы управления с помощью хомутов к линиям глушения и дросселирования на секциях райзера или к канатам коллектора при спуске секций райзера с ППВО?',
        answers: [
          'Через каждые 8 - 10 м',
          'Через каждые 4 - 6 м',
          'Через каждые 10 - 12 м',
          'Через каждые 6 - 8 м',
        ],
        correctAnswers: ['Через каждые 8 - 10 м'],
      },
      {
        code: '63653146',
        text:
          'В каких местах должны устанавливаться датчики контроля концентрационных пределов распространения пламени? Выберите два правильных варианта ответа.',
        answers: [
          'У технологических аппаратов в местах возможных источников выделений паров и газов на высоте не более 1 м над источником для нефтяных газов и на высоте 1,5 м над полом для паров нефти',
          'Над открытой емкостью для бурового раствора на расстоянии не более 0,5 м над ее верхней кромкой',
          'У вибросита на расстоянии не более 1 м от него на высоте не более 0,5 м над ним',
          'В районе устья скважины на высоте не более 0,5 м над полом',
        ],
        correctAnswers: [
          'У вибросита на расстоянии не более 1 м от него на высоте не более 0,5 м над ним',
          'В районе устья скважины на высоте не более 0,5 м над полом',
        ],
      },
      {
        code: '63653147',
        text:
          'Кто осматривает нефтеналивное судно, пришвартованное к нефтеналивному или перегрузочному комплексу, для определения возможности налива нефти?',
        answers: [
          'Представитель противопожарной службы субъектов Российской Федерации',
          'Ответственное лицо, назначенное начальником ОПО МНГК',
          'Представитель территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Ответственное лицо, назначенное начальником ОПО МНГК',
        ],
      },
      {
        code: '63653148',
        text:
          'Кем устанавливается порядок проведения работ на кустовых площадках при совмещении во времени различных по характеру работ (бурение, освоение, эксплуатация, монтаж нефтегазодобывающего оборудования и т.д.)?',
        answers: [
          'Пользователем недр (заказчиком) или его представителем',
          'Ростехнадзором',
          'Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Министерством природных ресурсов и экологии Российской Федерации',
        ],
        correctAnswers: [
          'Пользователем недр (заказчиком) или его представителем',
        ],
      },
      {
        code: '63653149',
        text:
          'Что должно быть указано на корпусах оборудования, входящего в состав талевой системы (кронблок, талевый блок, крюк)?',
        answers: [
          'Допускаемая грузоподъемность',
          'Дата изготовления',
          'Сроки следующего испытания',
          'Материал изготовления',
        ],
        correctAnswers: ['Допускаемая грузоподъемность'],
      },
      {
        code: '63653150',
        text:
          'Что должно включаться в опасную зону на период прострелочно-взрывных работ на МСП, ПБУ и МЭ?',
        answers: [
          'Только буровая вышка',
          'Только трасса каротажного кабеля',
          'Только место зарядки прострелочно-взрывных аппаратов и подготовки торпед и каротажная лебедка',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63653151',
        text:
          'Вентиляционной системой какого типа должны быть оборудованы все закрытые помещения буровой установки, где возможны проникновение или возникновение воспламеняющихся смесей?',
        answers: [
          'Естественной',
          'Местной вытяжной',
          'Местной приточной',
          'Приточно-вытяжной вентиляцией с механическим побуждением',
        ],
        correctAnswers: [
          'Приточно-вытяжной вентиляцией с механическим побуждением',
        ],
      },
      {
        code: '63653152',
        text:
          'С какой периодичностью и в каком объеме проводятся исследования эксплуатационных скважин?',
        answers: [
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
          'В соответствии с Инструкцией по исследованию скважин, утвержденной Минприроды России',
          'Каждые 6 месяцев в полном объеме и ежеквартально в объеме, необходимом геологической службе организации',
        ],
        correctAnswers: [
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
        ],
      },
      {
        code: '63653153',
        text:
          'Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?',
        answers: [
          'Представителем Ростехнадзора',
          'Лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
          'Лицом, ответственным за подготовку газоопасных работ',
        ],
        correctAnswers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала или его уполномоченным заместителем с учетом особенностей использования электронной подписи',
        ],
      },
      {
        code: '63653154',
        text:
          'С учетом каких параметров производятся выбор обсадных труб и расчет обсадных колонн на стадиях строительства и эксплуатации скважин?',
        answers: [
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении раствора газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении бурового раствора пластовым флюидом и агрессивности флюида',
          'С учетом минимальных ожидаемых наружных и внутренних давлений при полном замещении раствора пластовым флюидом, снижении уровня осевых нагрузок на трубы',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
        ],
        correctAnswers: [
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
        ],
      },
      {
        code: '63653155',
        text:
          'Кто принимает оперативные решения по отклонению от параметров, предусмотренных в рабочем проекте при возникновении в процессе производства буровых работ осложнений (газонефтепроявление, поглощения, обвалы и др.)?',
        answers: [
          'Буровой подрядчик с последующим уведомлением заказчика',
          'Проектная организация',
          'Руководство эксплуатирующей организации (заказчик)',
        ],
        correctAnswers: [
          'Буровой подрядчик с последующим уведомлением заказчика',
        ],
      },
      {
        code: '63653156',
        text:
          'Допускается ли отдача якорей судами в охранной зоне подводных трубопроводов?',
        answers: [
          'Не допускается ни при каких условиях',
          'Допускается только при выполнении подводно-технических работ и ремонте трубопровода при наличии письменного разрешения организации, эксплуатирующей трубопровод',
          'Допускается при письменном разрешении организации, эксплуатирующей трубопровод',
          'Допускается в любом случае',
        ],
        correctAnswers: [
          'Допускается только при выполнении подводно-технических работ и ремонте трубопровода при наличии письменного разрешения организации, эксплуатирующей трубопровод',
        ],
      },
      {
        code: '63653157',
        text:
          'Каким образом осуществляется визуальный контроль за соединениями бурового райзера, блока ППВО под водой?',
        answers: [
          'Визуальный контроль не осуществляется',
          'С помощью водолазов',
          'С помощью телеуправляемого подводного аппарата',
        ],
        correctAnswers: ['С помощью телеуправляемого подводного аппарата'],
      },
      {
        code: '63653158',
        text:
          'Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?',
        answers: [
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
          'Взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
          'Азимут и зенитный угол ствола скважины',
          'Пространственное расположение ствола скважины и дифференциальное давление в системе скважина - пласт',
        ],
        correctAnswers: [
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
        ],
      },
      {
        code: '63653159',
        text:
          'Кем осуществляется выбор типа противовыбросового оборудования и колонной головки?',
        answers: [
          'Буровой организацией',
          'Пользователем недр',
          'Проектной организацией',
        ],
        correctAnswers: ['Проектной организацией'],
      },
      {
        code: '63653160',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Опасно для жизни!", "Внимание!"',
          '"Газоопасные работы", "Газ"',
          '"Работают люди!", "Опасно для жизни!"',
          '"Осторожно! Опасная зона", "Работают люди!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63653161',
        text:
          'Какие из приведенных помещений (пространств) относятся к зоне 0 (участок, на котором взрывоопасная смесь присутствует постоянно или в течение длительных периодов времени)?',
        answers: [
          'Закрытые помещения насосных для сточных вод',
          'Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов',
          'Открытые пространства вокруг закрытых и открытых технических устройств, оборудования',
          'Полузакрытые пространства, в которых установлены технические устройства, оборудование, аппараты',
        ],
        correctAnswers: [
          'Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов',
        ],
      },
      {
        code: '63653162',
        text:
          'При каком условии закрытое помещение, непосредственно сообщающееся с зоной 0, считается взрывобезопасным?',
        answers: [
          'Если доступ осуществляется через двойные самозакрывающиеся газонепроницаемые двери, образующие воздушный тамбур-шлюз, в котором создается подпор воздуха механической приточной вентиляцией',
          'Если предусмотрена сигнализация в посту управления с постоянной вахтой, срабатывающая при прекращении действия вентиляции',
          'Если вентиляция обеспечивает избыточное давление по отношению к взрывоопасному пространству',
          'При всех перечисленных условиях',
        ],
        correctAnswers: ['При всех перечисленных условиях'],
      },
      {
        code: '63653163',
        text:
          'Кем составляется акт готовности скважины к промыслово-геофизическим работам? Выберите два правильных варианта ответа.',
        answers: [
          'Организацией - исполнителем буровых работ',
          'Эксплуатирующей организацией',
          'Ростехнадзором',
          'Организацией, проводящей экспертизу промышленной безопасности',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Организацией - исполнителем буровых работ',
          'Эксплуатирующей организацией',
        ],
      },
      {
        code: '63653164',
        text:
          'Какое должно быть расстояние между устьями скважин при расположении систем управления оборудования ПВО при бурении скважин и задвижками фонтанной арматуры эксплуатируемых скважин на одном ярусе при отсутствии обоснованных проектных решений о конкретных расстояниях?',
        answers: [
          'Не менее 2,4 м (для нефтяных)',
          'Не менее 3 м (для газовых и газоконденсатных)',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 5 м'],
      },
      {
        code: '63653165',
        text:
          'В каких случаях устье скважин на период ремонта оснащается противовыбросовым оборудованием?',
        answers: [
          'Только в случае ведения работ на кустовых площадках',
          'В случаях возможных газонефтеводопроявлениях на скважинах',
          'Во всех случаях',
        ],
        correctAnswers: [
          'В случаях возможных газонефтеводопроявлениях на скважинах',
        ],
      },
      {
        code: '63653166',
        text:
          'При каком условии допускается одновременное бурение двух скважин на ОПО МНГК? Выберите два правильных варианта ответа.',
        answers: [
          'При спуске на одной из скважин кондуктора',
          'При смонтированном на устье скважины ПВО',
          'При обосновании в проектной документации',
          'Только при бурении скважин двумя буровыми установками, с соединенными циркуляционными системами',
        ],
        correctAnswers: [
          'При спуске на одной из скважин кондуктора',
          'При смонтированном на устье скважины ПВО',
        ],
      },
      {
        code: '63653167',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'Во время грозы',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63653168',
        text:
          'Какие меры безопасности и охраны окружающей среды необходимо произвести при сжигании продукции опробования скважины?',
        answers: [
          'Необходимо регулировать подачу воды и воздуха на факел для обеспечения бездымного сжигания продукции скважины',
          'Необходимо предусмотреть наличие средств пожаротушения',
          'Меры безопасности и охраны окружающей среды не регламентируются',
        ],
        correctAnswers: [
          'Необходимо регулировать подачу воды и воздуха на факел для обеспечения бездымного сжигания продукции скважины',
        ],
      },
      {
        code: '63653169',
        text:
          'Куда представляется один экземпляр акта обследования дна на отсутствие навигационных опасностей, составленный эксплуатирующей организацией, при ликвидации и консервации морских нефтегазовых скважин?',
        answers: [
          'В специализированную организацию',
          'В Ростехнадзор',
          'В гидрографическую службу',
          'В проектную организацию',
        ],
        correctAnswers: ['В гидрографическую службу'],
      },
      {
        code: '63653170',
        text:
          'Какие требования предъявляются к хранению использованной ветоши и обтирочных материалов во взрывоопасных зонах?',
        answers: [
          'Места хранения использованной ветоши и обтирочных материалов Правилами не регламентируются',
          'Места хранения использованной ветоши и обтирочных материалов определяются руководителями подразделений',
          'В зонах не допускается хранение использованной ветоши и обтирочных материалов',
        ],
        correctAnswers: [
          'В зонах не допускается хранение использованной ветоши и обтирочных материалов',
        ],
      },
      {
        code: '63653171',
        text: 'Кто подает команду об эвакуации работников на ОПО МНГК?',
        answers: [
          'Начальник ОПО МНГК',
          'Капитан ОПО МНГК',
          'Руководитель спасательной группы',
        ],
        correctAnswers: ['Начальник ОПО МНГК'],
      },
      {
        code: '63653172',
        text:
          'В присутствии кого производятся промыслово-геофизические работы в скважинах? Выберите два правильных варианта ответа.',
        answers: [
          'Представителей организации - исполнителя буровых работ',
          'Представителей эксплуатирующей организации',
          'Представителей проектной организации',
          'Представителя Ростехнадзора',
        ],
        correctAnswers: [
          'Представителей организации - исполнителя буровых работ',
          'Представителей эксплуатирующей организации',
        ],
      },
      {
        code: '63653173',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63653174',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Только наличие у каждого работающего в емкости (аппарате) предохранительного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
          'Только непрерывный контроль состояния воздушной среды',
          'Только наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63653175',
        text:
          'Каким образом выполняются соединения между молниеприемником, токоотводом и заземлителем на ОПО МНГК?',
        answers: [
          'Только болтовыми зажимами из стали с защитным покрытием от коррозии',
          'Только болтовыми зажимами из меди (медных сплавов)',
          'Только сваркой',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63653176',
        text:
          'Кем утверждается инструкция, предусматривающая порядок проведения испытания подводного трубопровода?',
        answers: [
          'Эксплуатирующей организацией',
          'Организацией, осуществляющей промыслово-геофизические работы',
          'Научно-исследовательской организацией',
          'Строительной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63653177',
        text:
          'На каком максимальном расстоянии допускается расположение аварийно-спасательного судна от МСП, ПБУ, МЭ или ПТК, если эвакуация для ОПО МНГК организована с применением АСС?',
        answers: [
          '4 морские мили',
          '7 морских миль',
          '5 морских миль',
          '6 морских миль',
        ],
        correctAnswers: ['5 морских миль'],
      },
      {
        code: '63653178',
        text:
          'Чем должны оснащаться передвижные насосные установки, предназначенные для работы на скважинах?',
        answers: [
          'Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления',
          'Только приборами, контролирующими основные параметры технологического процесса',
          'Только запорными, предохранительными и регулирующими устройствами',
        ],
        correctAnswers: [
          'Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления',
        ],
      },
      {
        code: '63653179',
        text:
          'В соответствии с требованиями каких документов производится подготовка площадки, монтаж и эксплуатация колтюбинговых установок?',
        answers: [
          'Только в соответствии с инструкцией по производству работ, утвержденной Ростехнадзором',
          'В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя',
          'В соответствии с техническими условиями и инструкцией по производству работ, утвержденной Ростехнадзором',
          'Только в соответствии с техническими условиями',
        ],
        correctAnswers: [
          'В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя',
        ],
      },
      {
        code: '63653180',
        text:
          'Какие действия необходимо предпринять, если взрывчатые материалы, завезенные на ОПО МНГК, использованы неполностью?',
        answers: ['Утилизировать', 'По решению руководства', 'Вывезти'],
        correctAnswers: ['Вывезти'],
      },
      {
        code: '63653181',
        text:
          'Кого должен оповестить представитель организации - исполнителя буровых работ в случае возникновения открытого фонтана?',
        answers: [
          'Только территориальный орган Ростехнадзора',
          'Только ПАСФ',
          'Только руководство эксплуатирующей организации',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63653182',
        text:
          'Какие из перечисленных металлических частей и конструкций ОПО МНГК, которые не находятся под напряжением, но доступны для прикосновения в условиях эксплуатации, заземляются?',
        answers: [
          'Все перечисленные части и конструкции ОПО МНГК',
          'Цоколи патронов и крепежных элементов люминесцентных ламп, абажуров и отражателей',
          'Крепежные элементы кабеля',
          'Корпуса специально изолированных подшипников',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63653183',
        text:
          'Чем должны быть оборудованы и оснащены колтюбинговые установки с гибкими трубами?',
        answers: [
          'Системами контроля и регистрации нагрузок, возникающих при спуско-подъемных операциях',
          'Системой контроля утонения труб',
          'Системой контроля и регистрации давления при прокачивании через гибкую трубу жидкостей',
          'Комплектом устройств на устье скважины для спуска труб под давлением',
          'Всеми вышеперечисленными устройствами',
        ],
        correctAnswers: ['Всеми вышеперечисленными устройствами'],
      },
      {
        code: '63653184',
        text:
          'Каким образом следует располагать здания и сооружения с производственными процессами, выделяющими в атмосферу вредные и (или) горючие вещества, при обустройстве нефтяных, газовых и газоконденсатных месторождений?',
        answers: [
          'За пределами санитарно-защитной зоны населенных пунктов, объединяя данные здания и сооружения со вспомогательными, складскими и санитарно-бытовыми помещениями',
          'За пределами аварийной зоны населенных пунктов',
          'На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления',
          'За пределами прилегающих народно-хозяйственных объектов',
        ],
        correctAnswers: [
          'На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления',
        ],
      },
      {
        code: '63653185',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63653186',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом исключающим возможность искрообразования',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения представителя газоспасательной службы, и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63653187',
        text:
          'Кем разрабатываются инструкции по монтажу и эксплуатации противовыбросового оборудования (далее -ПВО)?',
        answers: [
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются буровой организацией в соответствии с применяемым оборудованием, технологией ведения работ и инструкциями по монтажу, техническому обслуживанию, эксплуатации и ремонту изготовителей',
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются заводом-изготовителем',
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются заказчиком (пользователем недр) и согласовываются с Ростехнадзором',
        ],
        correctAnswers: [
          'Инструкции по монтажу и эксплуатации ПВО разрабатываются буровой организацией в соответствии с применяемым оборудованием, технологией ведения работ и инструкциями по монтажу, техническому обслуживанию, эксплуатации и ремонту изготовителей',
        ],
      },
      {
        code: '63653188',
        text:
          'Какое требование, предъявляемое к применению противовыбросового оборудования, указано неверно?',
        answers: [
          'На ПБУ с подводным расположением устья проводится опрессовка каждого превентора в сборке подводного противовыбросового оборудования (далеее - ППВО) на стенде на рабочее давление',
          'Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину',
          'При ГНВП разгазированная жидкость через штуцерную линию поступает в систему сепарации и дегазации',
          'Противовыбросовый манифольд вместе с линией от сепаратора бурового раствора на желоб оборудуется устройством для продувки',
        ],
        correctAnswers: [
          'Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину',
        ],
      },
      {
        code: '63653189',
        text: 'В каком случае не допускается швартовка судов к ПБУ?',
        answers: [
          'В период постановки ПБУ на точку бурения',
          'С разрешения капитана ПБУ при благоприятных метеоусловиях',
          'Во всех перечисленных случаях',
          'В специально отведенном месте согласно инструкциям по эксплуатации данного ПБУ',
        ],
        correctAnswers: ['В период постановки ПБУ на точку бурения'],
      },
      {
        code: '63653190',
        text:
          'С каким уклоном допускается устанавливать опорную плиту (при ее наличии) на ровную поверхность дна моря при формировании подводного устья скважины?',
        answers: [
          'Не более 3°',
          'Не более 1°',
          'Не более 5°',
          'Уклон не допускается',
        ],
        correctAnswers: ['Не более 1°'],
      },
    ],
    63655: [
      {
        code: '63655000',
        text:
          'Какие сведения может не содержать формуляр (паспорт) подтверждения величины разрешенного (допустимого) рабочего давления на ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'О необходимости обеспечения ОПО МТ предохранительными устройствами для ограничения величины рабочего (допустимого) давления',
          'О величине разрешенного давления',
          'Об участке (номере участка) ОПО МТ',
          'О сроках проведения следующего технического диагностирования',
        ],
        correctAnswers: [
          'О сроках проведения следующего технического диагностирования',
        ],
      },
      {
        code: '63655001',
        text:
          'В каких целях разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'В целях координации действий персонала при возникновении аварии на опасных производственных объектах',
          'В целях обучения персонала действиям при возникновении аварии на опасных производственных объектах',
          'В целях обеспечения соответствия объекта требованиям промышленной безопасности',
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
        correctAnswers: [
          'В целях обеспечения готовности организаций, эксплуатирующих опасные производственные объекты, к действиям по локализации и ликвидации последствий аварий на таких объектах',
        ],
      },
      {
        code: '63655002',
        text:
          'В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?',
        answers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В случае если 4 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке',
          'В случае если 3 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
          'В любом случае на каждый объект должен быть разработан отдельный план',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63655003',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только руководителями (заместителями руководителей) организаций, эксплуатирующих объекты',
          'Только руководителями структурных подразделений организаций, эксплуатирующих объекты',
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Специалистами поднадзорных организаций совместно со специалистами надзорных органов',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63655004',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
          'До окончания работ на конкретном месте',
          'В течение двух суток',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63655005',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Для всех ОПО без исключения',
          'Только для ОПО I и II классов опасности',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63655006',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63655007',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Главный инженер эксплуатирующей организации',
          'Руководитель аварийно-спасательной службы совместно с руководителем эксплуатирующей организации',
          'Руководитель службы производственного контроля (лицо, ответственное за осуществление производственного контроля)',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63655008',
        text:
          'К чему из перечисленного не относится анализ опасностей технологических процессов, количественный анализ риска и иные методы анализа риска аварий, связанных с выбросом транспортируемых углеводородов?',
        answers: [
          'К декларированию промышленной безопасности',
          'К декларированию пожарной безопасности',
          'К обоснованию безопасности ОПО МТ',
          'К системе управления промышленной безопасностью ОПО МТ',
        ],
        correctAnswers: ['К декларированию пожарной безопасности'],
      },
      {
        code: '63655009',
        text:
          'Какая информация не должна содержаться на опознавательных знаках линейной части магистрального газопровода?',
        answers: [
          'Расстояние от оси магистрального газопровода до границы охранной зоны',
          'Расстояние от начала магистрального газопровода, измеряемое в километрах',
          'Местоположение оси магистрального газопровода по отношению к основанию знака',
          'Телефон и ФИО лица, ответственного за установку опознавательных знаков',
        ],
        correctAnswers: [
          'Телефон и ФИО лица, ответственного за установку опознавательных знаков',
        ],
      },
      {
        code: '63655010',
        text:
          'Кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Руководителями муниципальных образований, на территории которых расположен объект',
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'Руководителями территориальных органов МЧС России',
          'Руководителями территориальных органов Ростехнадзора',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63655011',
        text:
          'Из каких разделов состоит план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Из частных разделов',
          'Из специализированных разделов',
          'Из основного и общих разделов',
          'Из общих и специальных разделов',
        ],
        correctAnswers: ['Из общих и специальных разделов'],
      },
      {
        code: '63655012',
        text:
          'Чем подтверждается соответствие комплектного оборудования и технических устройств на ОПО магистральных трубопроводов (МТ) при наличии технической документации изготовителя, а также при их соответствии требованиям технических регламентов и Правил безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Разрешением Ростехнадзора на применение технических устройств на ОПО',
          'Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности',
          'Сертификатом соответствия техническим регламентам ЕАЭС',
          'Сертификатом соответствия системы ГОСТ Р',
        ],
        correctAnswers: [
          'Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63655013',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах индивидуальной защиты органов дыхания',
          'Только с разрешения представителя газоспасательной службы при обеспечении принудительного воздухообмена в газоопасном месте',
          'Только с разрешения лица, ответственного за подготовку и проведение работ, с инструментом, исключающим возможность искрообразования',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63655014',
        text:
          'Какие работы должны быть проведены перед началом осуществления работ по выводу из эксплуатации ОПО магистральных трубопроводов (МТ) и ОПО магистральных аммиакопроводов (МАП), подлежащих ликвидации?',
        answers: [
          'Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака',
          'Работы по очистке трубопроводов ОПО МТ и ОПО МАП',
          'Ревизия и проверка трубопроводов и оборудования ОПО МТ и ОПО МАП',
          'Техническое диагностирование трубопроводов и оборудования ОПО МТ и ОПО МАП',
        ],
        correctAnswers: [
          'Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака',
        ],
      },
      {
        code: '63655015',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Специализированная подрядная организация',
          'Руководитель структурного подразделения',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63655016',
        text:
          'В течение какого времени после пробной эксплуатации опасных производственных объектов магистральных трубопроводов эксплуатирующая организация составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе опасных производственных объектов магистральных трубопроводов из консервации?',
        answers: [
          'В течение 12 часов',
          'В течение 24 часов',
          'В течение 48 часов',
          'В течение 72 часов',
        ],
        correctAnswers: ['В течение 72 часов'],
      },
      {
        code: '63655017',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий на ОПО магистральных трубопроводов (МТ) II класса опасности?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63655018',
        text:
          'За сколько дней до планируемого дня начала работ организация или физическое лицо обязаны обратиться к собственнику магистрального газопровода или организации, эксплуатирующей магистральный газопровод, с письменным заявлением в целях получения разрешения на производство работ?',
        answers: [
          'Не менее чем за 20 рабочих дней',
          'Не менее чем за 5 рабочих дней',
          'Не менее чем за 15 рабочих дней',
          'Не менее чем за 10 рабочих дней',
        ],
        correctAnswers: ['Не менее чем за 20 рабочих дней'],
      },
      {
        code: '63655019',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Лицу, замещающее руководителя структурного подразделения, на объекте которого проводятся газоопасные работы',
          'Лицу, представляющему профессиональную аварийно-спасательную службу',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63655020',
        text:
          'Какой документ разрабатывают для вывода опасных производственных объектов магистральных трубопроводов из консервации и ввода их в эксплуатацию?',
        answers: [
          'Рабочую программу с указанием перечня работ, порядка и сроков их выполнения',
          'Технический регламент с указанием перечня работ',
          'План мероприятий по выводу опасных производственных объектов магистральных трубопроводов из консервации и ввода их в эксплуатацию',
          'График выполнения работ по вводу опасных производственных объектов магистральных трубопроводов в эксплуатацию',
        ],
        correctAnswers: [
          'Рабочую программу с указанием перечня работ, порядка и сроков их выполнения',
        ],
      },
      {
        code: '63655021',
        text:
          'После чего следует начинать производство работ по реконструкции, техническому перевооружению и капитальному ремонту ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Только после выполнения подготовительных мероприятий и письменного разрешения руководства эксплуатирующей организации на производство работ',
          'После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ',
          'Только после приемки объектов подрядчиком',
          'Только после письменного разрешения руководства эксплуатирующей организации на производство работ',
        ],
        correctAnswers: [
          'После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ',
        ],
      },
      {
        code: '63655022',
        text:
          'В каких целях проводят периодическое техническое диагностирование опасных производственных объектов магистральных трубопроводов в процессе эксплуатации?',
        answers: [
          'Только в целях обеспечения безопасности',
          'Только в целях определения фактического технического состояния ОПО МТ',
          'Только в целях расчета допустимого давления',
          'Только в целях определения возможности дальнейшей безопасной эксплуатации на проектных технологических режимах',
          'Во всех перечисленных целях',
        ],
        correctAnswers: ['Во всех перечисленных целях'],
      },
      {
        code: '63655023',
        text:
          'Что определяется в специальном разделе плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Порядок действий в случае аварий на объекте',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
        ],
        correctAnswers: ['Порядок действий в случае аварий на объекте'],
      },
      {
        code: '63655024',
        text:
          'Каким требованиям должен соответствовать технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов, определяющий порядок организации надежного и безопасного ведения технологического процесса?',
        answers: [
          'Проектным решениям',
          'Условиям работы опасных производственных объектов магистральных трубопроводов',
          'Требованиям законодательства Российской Федерации в области промышленной безопасности и нормативных технических документов',
          'Проектным решениям, условиям работы ОПО МТ, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании',
        ],
        correctAnswers: [
          'Проектным решениям, условиям работы ОПО МТ, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании',
        ],
      },
      {
        code: '63655025',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63655026',
        text:
          'Где должен храниться формуляр (паспорт), на основании которого была установлена величина разрешенного (допустимого) рабочего давления?',
        answers: [
          'В архиве подрядной организации',
          'В архиве проектной организации',
          'В архиве эксплуатирующей организации',
        ],
        correctAnswers: ['В архиве эксплуатирующей организации'],
      },
      {
        code: '63655027',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63655028',
        text:
          'В соответствии с требованиями какого нормативно-правового документа обеспечивается пожарная безопасность опасных производственных объектов (далее - ОПО) магистральных трубопроводов (МТ)?',
        answers: [
          'Федеральных норм и правил в области промышленной безопасности "Правила безопасности для опасных производственных объектов магистральных трубопроводов"',
          'Федерального закона от 22 июля 2008 г. № 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
          'Федерального закона от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'СП 36.13330.2012 "Свод правил. Магистральные трубопроводы"',
        ],
        correctAnswers: [
          'Федерального закона от 22 июля 2008 г. № 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
        ],
      },
      {
        code: '63655029',
        text:
          'Какой входной контроль должен быть организован на всех этапах выполнения работ по строительству, реконструкции, техническому перевооружению и капитальному ремонту ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Только конструкций, изделий, материалов, оборудования и технических устройств',
          'Только технологических операций',
          'Только качества выполнения работ',
          'Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций',
        ],
        correctAnswers: [
          'Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций',
        ],
      },
      {
        code: '63655030',
        text:
          'Какая из перечисленных специальных мер безопасности, снижающая риск аварии, не предусматривается проектной документацией/документацией для наиболее опасных участков объектов линейной части ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Увеличение глубины залегания трубопровода',
          'Увеличение толщины стенки трубопровода',
          'Устройство отводящих систем (каналов, канав)',
          'Увеличение диаметра трубопровода',
        ],
        correctAnswers: ['Увеличение диаметра трубопровода'],
      },
      {
        code: '63655031',
        text:
          'В виде какой территории устанавливаются охранные зоны объектов магистральных газопроводов вдоль линейной части магистрального газопровода?',
        answers: [
          'В виде территории, ограниченной условными параллельными плоскостями, проходящими на расстоянии 25 метров от осей крайних ниток магистрального газопровода',
          'В виде территории, ограниченной условными параллельными плоскостями, проходящими на расстоянии 25 метров от оси магистрального газопровода с каждой стороны',
          'В виде территории, ограниченной условной замкнутой линией, отстоящей от внешней границы указанных объектов на 100 метров с каждой стороны',
        ],
        correctAnswers: [
          'В виде территории, ограниченной условными параллельными плоскостями, проходящими на расстоянии 25 метров от оси магистрального газопровода с каждой стороны',
        ],
      },
      {
        code: '63655032',
        text:
          'В какие сроки эксплуатирующая организация обязана пересматривать инструкции на рабочих местах?',
        answers: [
          'Один раз в три года',
          'Один раз в год',
          'Один раз в пять лет',
          'Один раз в два года',
        ],
        correctAnswers: ['Один раз в пять лет'],
      },
      {
        code: '63655033',
        text:
          'Что из перечисленного допускается в охранных зонах объектов магистральных газопроводов?',
        answers: [
          'Огораживать и перегораживать охранные зоны',
          'Проводить работы с использованием ударно-импульсных устройств и вспомогательных механизмов',
          'Складировать любые материалы',
          'Производить полевые сельскохозяйственные работы',
        ],
        correctAnswers: ['Производить полевые сельскохозяйственные работы'],
      },
      {
        code: '63655034',
        text:
          'Что необходимо контролировать в месте проведения ремонтных, газоопасных работ на ОПО магистральных трубопроводов (МТ) с использованием приборов контроля с автоматической сигнализацией?',
        answers: [
          'Влажность',
          'Температуру',
          'Содержание горючих паров и газов в воздухе рабочей зоны или помещения',
          'Атмосферное давление',
        ],
        correctAnswers: [
          'Содержание горючих паров и газов в воздухе рабочей зоны или помещения',
        ],
      },
      {
        code: '63655035',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63655036',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Любой из специалистов эксплуатирующей организации',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63655037',
        text:
          'Какие сведения допускается не учитывать при определении периодичности, полноты и порядка обследования, методов и средств контроля трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Информацию об условиях эксплуатации магистральных трубопроводов',
          'Информацию о техническом состоянии магистральных трубопроводов',
          'Сведения об устранении дефектов в результате планового капремонта',
          'Данные о строительстве магистральных трубопроводов',
        ],
        correctAnswers: [
          'Сведения об устранении дефектов в результате планового капремонта',
        ],
      },
      {
        code: '63655038',
        text:
          'На основе чего осуществляется определение нагрузок и воздействий, возникающих на этапах строительства, эксплуатации, реконструкции, при техническом перевооружении, капитальном ремонте, консервации, ликвидации ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации',
          'На основе результатов анализа опасностей технологических процессов и анализа риска аварий',
          'На основе проектной документации',
          'На основе технических решений, принимаемых при разработке проектной документации',
        ],
        correctAnswers: [
          'На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации',
        ],
      },
      {
        code: '63655039',
        text:
          'В каком случае оформление формуляра (паспорта) подтверждения величины разрешенного (допустимого) рабочего давления не требуется?',
        answers: [
          'Для объектов, вводимых в эксплуатацию по завершении реконструкции',
          'Для действующих объектов, на которых проведено изменение величины разрешенного (допустимого) рабочего давления',
          'Для объектов, вводимых в эксплуатацию по завершении строительства',
          'Для всех эксплуатируемых ОПО МТ ежегодно',
        ],
        correctAnswers: ['Для всех эксплуатируемых ОПО МТ ежегодно'],
      },
      {
        code: '63655040',
        text:
          'В каких случаях проводят контроль содержания горючих паров и газов в воздухе рабочей зоны или помещения при проведении ремонтных, газоопасных работ на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа',
          'Перед началом проведения работ и после каждого перерыва длительностью не менее двух часов',
          'После каждого перерыва длительностью не менее трех часов',
          'Перед началом проведения работ',
        ],
        correctAnswers: [
          'Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа',
        ],
      },
      {
        code: '63655041',
        text:
          'В течение какого времени собственник магистрального газопровода или организация, эксплуатирующая магистральный газопровод, обязаны выдать разрешение на производство работ либо направить мотивированный отказ в выдаче разрешения на производство работ?',
        answers: [
          'В течение 15 рабочих дней со дня получения заявления',
          'В течение 30 рабочих дней со дня получения заявления',
          'В течение 20 рабочих дней со дня получения заявления',
          'В течение 25 рабочих дней со дня получения заявления',
        ],
        correctAnswers: [
          'В течение 15 рабочих дней со дня получения заявления',
        ],
      },
      {
        code: '63655042',
        text:
          'Какой документ не содержит требований к объему и периодичности работ, выполняемых при техническом обслуживании ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Проектная документация',
          'Технический регламент на эксплуатацию ОПО МТ',
          'Нормативно-технические документы заводов-изготовителей к трубам, материалам и оборудованию',
          'Техническое задание',
        ],
        correctAnswers: ['Техническое задание'],
      },
      {
        code: '63655043',
        text:
          'В каком случае ремонтные работы на ОПО магистральных трубопроводов (МТ) проводят в средствах индивидуальной защиты органов дыхания?',
        answers: [
          'При срабатывании системы автоматической сигнализации',
          'При превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта',
          'При осуществлении ремонтных работ одним рабочим',
          'При скорости ветра более 12 м/с',
        ],
        correctAnswers: [
          'При превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта',
        ],
      },
      {
        code: '63655044',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Лица, допущенные к выполнению газоопасных работ, должны быть не моложе 18 лет',
          'Лица, допущенные к выполнению газоопасных работ, должны проходить медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
          'Лица, допущенные к выполнению газоопасных работ, должны проходить обучение приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63655045',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О знании правил оказания первой помощи',
          'О самочувствии',
          'О знании действий при пожаре',
          'О наличии медицинских противопоказаний к работе',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63655046',
        text:
          'Что, согласно требованиям нормативных правовых актов и нормативных технических документов, не проводится при выводе из консервации опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Ревизия трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
          'Опробование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
          'Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
          'Испытания трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
        ],
        correctAnswers: [
          'Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
        ],
      },
      {
        code: '63655047',
        text:
          'Чем должны быть определены объем и периодичность выполняемых работ при техническом обслуживании ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Только техническим регламентом на эксплуатацию ОПО МТ',
          'Только нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию',
          'Только проектной документацией и результатами контроля технического состояния',
          'Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния',
        ],
        correctAnswers: [
          'Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния',
        ],
      },
      {
        code: '63655048',
        text:
          'Какие условия должны быть обеспечены в процессе эксплуатации опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и меры по закреплению трубопровода на проектных отметках в случае его смещения',
          'Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и укомплектованность рабочих мест необходимой документацией, запасами материалов, запасными частями, инвентарем, средствами индивидуальной и коллективной защиты согласно установленным нормам',
          'Укомплектованность рабочих мест необходимой документацией, запасами материалов, запасными частями, инвентарем, средствами индивидуальной и коллективной защиты, согласно установленным нормам и меры по закреплению трубопровода на проектных отметках в случае его смещения',
        ],
        correctAnswers: [
          'Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и меры по закреплению трубопровода на проектных отметках в случае его смещения',
        ],
      },
      {
        code: '63655049',
        text:
          'Какая из перечисленных документаций должна храниться в эксплуатирующей ОПО магистральных трубопроводов (МТ) организации?',
        answers: [
          'Только эксплуатационная документация',
          'Только исполнительная документация',
          'Только проектная документация',
          'Вся перечисленная',
        ],
        correctAnswers: ['Вся перечисленная'],
      },
      {
        code: '63655050',
        text:
          'В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.',
        answers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
          'Когда газоопасные работы могут быть проведены без непосредственного участия людей',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
        ],
        correctAnswers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
        ],
      },
      {
        code: '63655051',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63655052',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63655053',
        text:
          'Каким образом определяются объем и методы неразрушающего контроля сварных соединений?',
        answers: [
          'Проектной документацией (документацией)',
          'Технологической картой',
          'Технической документацией на оборудование',
          'Распоряжением эксплуатирующей организации',
        ],
        correctAnswers: ['Проектной документацией (документацией)'],
      },
      {
        code: '63655054',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 месяца со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63655055',
        text:
          'Проведение какого вида работ не предусматривается при техническом диагностировании линейной части опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Оценки состояния изоляционных покрытий',
          'Внутритрубной дефектоскопии путем пропуска внутритрубных инспекционных приборов',
          'Внешнего обследования оборудования и участков МТ, не подлежащих ВТД с применением методов неразрушающего контроля',
          'Гидравлических (пневматических) испытаний на прочность',
        ],
        correctAnswers: [
          'Гидравлических (пневматических) испытаний на прочность',
        ],
      },
      {
        code: '63655056',
        text:
          'Кто составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе ОПО магистральных трубопроводов (МТ) из консервации?',
        answers: [
          'Эксплуатирующая организация',
          'Территориальный орган Ростехнадзора',
          'Экспертная организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63655057',
        text:
          'Что включает в себя планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий',
          'Подготовку и аттестацию руководителей и специалистов в области промышленной безопасности',
          'Страхование ответственности за причинение вреда в случае возникновения аварии или инцидента на ОПО',
          'Разработку мер по снижению риска аварий на ОПО',
        ],
        correctAnswers: [
          'Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63655058',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
          'Электротехнический персонал эксплуатирующей организации',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63655059',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В ночное время суток с участием лица, ответственного за выполнение газоопасных работ',
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск',
          'Во время грозы с соблюдением мер по обеспечению безопасного проведения работ',
          'В дневное или темное время суток в присутствии лица, ответственного за выполнение газоопасных работ',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63655060',
        text:
          'В течение какого срока после реконструкции или технического перевооружения объекта должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее 45 календарных дней',
          'Не позднее 3 месяцев',
          'Не позднее 6 месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63655061',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение месяца',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63655062',
        text:
          'Кто устанавливает продолжительность периода, на который ОПО магистральных трубопроводов (МТ) выводят из эксплуатации, условия нахождения в резерве (консервация или периодическое включение в работу в целях поддержания работоспособного состояния ОПО МТ)?',
        answers: [
          'Проектная организация',
          'Ростехнадзор',
          'Экспертная организация',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63655063',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, на срок, требуемый для окончания работ',
          'Руководитель структурного подразделения на срок, требуемый для окончания работ',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 рабочую смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63655064',
        text:
          'В какой документ заносятся результаты входного контроля конструкций, изделий, материалов, оборудования и технических устройств ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В паспорт оборудования',
          'В журнал входного контроля с оформлением акта проверки',
          'В протокол по итогам входного контроля',
          'Занесение результатов входного контроля в какой-либо документ не регламентируется',
        ],
        correctAnswers: [
          'В журнал входного контроля с оформлением акта проверки',
        ],
      },
      {
        code: '63655065',
        text:
          'В какой документации определяются места установки и количество автоматических датчиков или пробоотборных устройств автоматических анализаторов на площадочных объектах ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В технологическом регламенте',
          'В проектной документации (документации)',
          'В производственных инструкциях',
          'В предписаниях Ростехнадзора',
        ],
        correctAnswers: ['В проектной документации (документации)'],
      },
      {
        code: '63655066',
        text:
          'В течение какого времени заполнение линейных сооружений ОПО магистральных трубопроводов (МТ) углеводородами и его работу после заполнения считают комплексным опробованием линейного сооружения ОПО МТ?',
        answers: [
          'В течение 24 часов',
          'В течение 48 часов',
          'В течение 72 часов',
          'В течение 96 часов',
        ],
        correctAnswers: ['В течение 72 часов'],
      },
      {
        code: '63655067',
        text:
          'С учетом каких факторов определяются сроки и методы технического диагностирования опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Только опасности и технического состояния участков линейной части опасных производственных объектов магистральных трубопроводов',
          'Только показателей эксплуатации (срок службы, ресурс), установленных проектной и/или нормативно-технической документацией',
          'Только опасности и технического состояния сооружений и технических устройств площадочных сооружений опасных производственных объектов магистральных трубопроводов',
          'Всех перечисленных факторов',
        ],
        correctAnswers: ['Всех перечисленных факторов'],
      },
      {
        code: '63655068',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
          'Исполнители должны пройти инструктаж',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63655069',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Исполнители ремонтных работ расписываются в вахтовом журнале ремонтируемого объекта',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63655070',
        text:
          'В каких случаях вместо гидравлических испытаний допускается проведение испытаний линейной части ОПО магистральных трубопроводов (МТ) на прочность и герметичность газообразными рабочими средами?',
        answers: [
          'Только при отрицательных температурах окружающей среды',
          'Только при невозможности обеспечения необходимого количества жидкой рабочей среды',
          'При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды',
          'В любых случаях проведение пневматических испытаний на прочность и герметичность линейной части ОПО МТ запрещается',
        ],
        correctAnswers: [
          'При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды',
        ],
      },
      {
        code: '63655071',
        text:
          'Что должна включать оценка технического состояния оборудования площадочных сооружений опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Только наружное обследование в режиме эксплуатации',
          'Только полное техническое обследование в режиме выведения (временного или длительного) из эксплуатации',
          'Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)',
        ],
        correctAnswers: [
          'Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)',
        ],
      },
      {
        code: '63655072',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'При возникновении обстоятельств, угрожающих безопасности работника внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работника внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63655073',
        text:
          'Что из перечисленного не входит в мероприятия по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварий на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Контроль состояния технических устройств',
          'Оснащение системами и средствами наблюдения, оповещения, связи',
          'Подготовка и аттестация руководителей и специалистов в области промышленной безопасности',
          'Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Подготовка и аттестация руководителей и специалистов в области промышленной безопасности',
        ],
      },
      {
        code: '63655074',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63655075',
        text:
          'Через какие промежутки должны устанавливаться опознавательные знаки линейной части магистрального газопровода в границах охранной зоны на прямых участках?',
        answers: [
          'Не реже чем через 1 тыс. м',
          'Не реже чем через 1,5 тыс. м',
          'Не реже чем через 1,8 тыс. м',
          'Не реже чем через 2 тыс. м',
        ],
        correctAnswers: ['Не реже чем через 1 тыс. м'],
      },
      {
        code: '63655076',
        text:
          'Какие из перечисленных степеней со среднестатистическим уровнем риска аварий при анализе результатов расчетов на участках и составляющих ОПО магистральных трубопроводов (МТ) указаны неверно?',
        answers: [
          'Высокая',
          'Средняя',
          'Чрезвычайно высокая',
          'Низкая',
          'Чрезвычайно низкая',
        ],
        correctAnswers: ['Чрезвычайно низкая'],
      },
      {
        code: '63655077',
        text:
          'К какому устройству ОПО магистральных трубопроводов (МТ) и ОПО магистральных аммиакопроводов (МАП) допускается несанкционированный доступ в аварийной ситуации?',
        answers: [
          'К автоматизированным системам управления технологическими процессами',
          'К запорной арматуре',
          'К регулирующей арматуре',
          'К предохранительной арматуре',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63655078',
        text:
          'В каких случаях требуется пересмотр технологического регламента на эксплуатацию магистральных трубопроводов?',
        answers: [
          'Только в случае изменения проектной документации',
          'Только в случае изменений параметров ведения технологического процесса',
          'Только в случаях, предусмотренных законодательством Российской Федерации в области промышленной безопасности',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63655079',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Производить подготовку объекта к проведению газоопасных работ',
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Непосредственно участвовать в выполнении газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63655080',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          '1 год',
          '5 лет',
          'Наибольший срок, предусмотренный для этих объектов',
          'Наименьший срок, предусмотренный для этих объектов',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63655081',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63655082',
        text:
          'Что может применяться в качестве жидкой рабочей среды при гидравлических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?',
        answers: [
          'Только вода',
          'Горючие жидкости при обосновании их применения в документации на проведение испытаний и уведомлении Ростехнадзора',
          'Вода и другие негорючие жидкости',
          'Вид жидкости в Правилах безопасности для опасных производственных объектов магистральных трубопроводов не нормируется',
        ],
        correctAnswers: ['Вода и другие негорючие жидкости'],
      },
      {
        code: '63655083',
        text:
          'Кого должны поставить в известность о начале и сроках работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО магистральных трубопроводов (МТ) производители работ перед началом выполнения данных работ?',
        answers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ',
          'Территориальный орган Ростехнадзора',
          'Проектную организацию',
          'Местные органы муниципального управления',
        ],
        correctAnswers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ',
        ],
      },
      {
        code: '63655084',
        text:
          'В каком случае не применяются Правила безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'В случае разработки технологических процессов, проектирования, строительства, эксплуатации, реконструкции, технического перевооружения, капитального ремонта, консервации и ликвидации ОПО МТ',
          'В случае изготовления, монтажа, наладки, обслуживания, диагностирования и ремонта технических устройств, применяемых на ОПО МТ',
          'В случае проведения экспертизы промышленной безопасности: документации на консервацию, ликвидацию, техническое перевооружение ОПО; технических устройств; зданий и сооружений; деклараций промышленной безопасности ОПО МТ; обоснований безопасности ОПО',
          'В случае проведения государственной экспертизы проектной документации в соответствии с законодательством о градостроительной деятельности',
        ],
        correctAnswers: [
          'В случае проведения государственной экспертизы проектной документации в соответствии с законодательством о градостроительной деятельности',
        ],
      },
      {
        code: '63655085',
        text: 'В чем заключается основная задача анализа риска?',
        answers: [
          'В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках ОПО МТ',
          'В информировании Ростехнадзора о существующих рисках на ОПО',
          'В определении сумм потенциального ущерба в случае возникновения аварии на ОПО',
          'В информировании населения о существующих рисках возникновения аварии на ОПО',
        ],
        correctAnswers: [
          'В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках ОПО МТ',
        ],
      },
      {
        code: '63655086',
        text:
          'Что не относится к основным этапам процесса проведения количественного анализа риска аварии на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Оценка риска методом НАZOP',
          'Идентификация опасностей аварий',
          'Количественная оценка риска аварий на ОПО МТ',
          'Разработка рекомендаций по снижению риска аварий',
        ],
        correctAnswers: ['Оценка риска методом НАZOP'],
      },
      {
        code: '63655087',
        text:
          'Какой вид газообразной рабочей среды может применяться при пневматических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?',
        answers: [
          'Только воздух',
          'Воздух, инертные газы, природный газ',
          'Воздух и инертные газы',
          'Вид газообразной рабочей среды не нормируется',
        ],
        correctAnswers: ['Воздух, инертные газы, природный газ'],
      },
      {
        code: '63655088',
        text:
          'Какие виды патрулирования используются для контроля состояния линейных участков, сооружений и объектов ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Только объезд автотранспортом',
          'Только авиапатрулирование',
          'Только пеший обход',
          'Все перечисленные виды патрулирования',
        ],
        correctAnswers: ['Все перечисленные виды патрулирования'],
      },
      {
        code: '63655089',
        text:
          'Что из перечисленного допускается не включать в технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Технологические режимы процесса транспортирования продукта и схемы',
          'Штатное расписание',
          'Порядок приема, сдачи и учета перекачиваемых продуктов',
          'Нормы расхода основных видов сырья, материалов и энергоресурсов',
        ],
        correctAnswers: ['Штатное расписание'],
      },
      {
        code: '63655090',
        text:
          'Какие факторы следует учитывать при определении периодичности, мест и методов контроля, применяемых при техническом диагностировании?',
        answers: [
          'Только расчетный срок службы технических устройств и сооружений',
          'Только сведения о неисправностях, проведенных ремонтах',
          'Только данные о режимах работы системы электрохимической защиты',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63655091',
        text:
          'В течение какого периода эксплуатирующая организация обязана проводить периодические обследования трубопроводов и оборудования ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В течение 10 лет',
          'В течение 25 лет',
          'В течение всего жизненного цикла (до ликвидации ОПО МТ)',
          'В течение 15 лет',
        ],
        correctAnswers: [
          'В течение всего жизненного цикла (до ликвидации ОПО МТ)',
        ],
      },
      {
        code: '63655092',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Инструкциями по охране труда и промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63655093',
        text:
          'В каком случае осуществляются технические мероприятия по консервации и ликвидации опасных производственных объектов магистральных трубопроводов в соответствии с документацией на консервацию и ликвидацию опасных производственных объектов?',
        answers: [
          'После положительного заключения государственной экспертизы в соответствии с Градостроительным кодексом',
          'После получения разрешения на консервацию и ликвидацию ОПО, выдаваемого федеральным органом исполнительной власти в области промышленной безопасности',
          'После проведения проверки ОПО Ростехнадзором',
          'После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации ОПО',
        ],
        correctAnswers: [
          'После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации ОПО',
        ],
      },
      {
        code: '63655094',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
          'Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований Правил противопожарного режима в Российской Федерации, утвержденных постановлением Правительства Российской Федерации',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63655095',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63655096',
        text:
          'Какие требования к эксплуатирующей организации по предупреждению и ликвидации аварий на ОПО магистральных трубопроводов (МТ) указаны неверно?',
        answers: [
          'Планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО МТ возлагается на эксплуатирующую организацию',
          'В эксплуатационных подразделениях должны быть созданы и укомплектованы службы аварийно-восстановительных бригад и нештатные аварийно-спасательные формирования',
          'Разработка организационных и технических мероприятий для безопасной эксплуатации ОПО МТ и подготовка персонала к действиям по локализации и ликвидации последствий аварий возлагается на эксплуатирующую организацию',
          'Все перечисленное указано верно',
        ],
        correctAnswers: ['Все перечисленное указано верно'],
      },
      {
        code: '63655097',
        text:
          'Что должно быть обеспечено на территории размещения линейных и площадочных сооружений опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Возможность размещения предприятий и складов взрывчатых веществ',
          'Возможность размещения объектов энергетического и водооборотного хозяйства',
          'Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов',
          'Возможность строительства производственных зданий и сооружений',
        ],
        correctAnswers: [
          'Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов',
        ],
      },
      {
        code: '63655098',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий на ОПО установлены для ОПО магистральных трубопроводов (МТ) III класса опасности?',
        answers: ['1 год', '2 года', '3 года', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63655099',
        text:
          'Что допускается не учитывать при определении периодичности и методов патрулирования трассы линейных сооружений опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Техническое состояние трубопроводов',
          'Особенности участка прокладки трубопровода',
          'Природные факторы, влияющие на безопасность эксплуатации трубопровода',
          'Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты',
        ],
        correctAnswers: [
          'Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты',
        ],
      },
      {
        code: '63655100',
        text:
          'Каким испытаниям должны быть подвергнуты трубопроводы линейной части ОПО магистральных трубопроводов (МТ) по завершении строительства, реконструкции, технического перевооружения и капитального ремонта?',
        answers: [
          'На износ и твердость',
          'На прочность и герметичность',
          'На деформацию',
          'Правила безопасности для опасных производственных объектов магистральных трубопроводов не содержат требований, предъявляемых к испытаниям объектов линейной части ОПО МТ по завершении строительства, реконструкции, технического перевооружения и капитального ремонта',
        ],
        correctAnswers: ['На прочность и герметичность'],
      },
      {
        code: '63655101',
        text:
          'На основании чего составляется график ремонта (включая капитальный ремонт) опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Результатов технического обследования',
          'Проведения экспертизы промышленной безопасности',
          'Проведения неразрушающего контроля',
        ],
        correctAnswers: ['Результатов технического обследования'],
      },
      {
        code: '63655102',
        text:
          'Какие действия следуют по завершении строительства, реконструкции, технического перевооружения и после испытания на прочность и проверки на герметичность ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Приступают к эксплуатации ОПО МТ в режиме опытного использования',
          'Осуществляют комплексное опробование ОПО МТ',
          'Приступают к эксплуатации ОПО МТ в штатном режиме',
        ],
        correctAnswers: ['Осуществляют комплексное опробование ОПО МТ'],
      },
      {
        code: '63655103',
        text:
          'Какой документацией предусматриваются технические решения по очистке полости трубопроводов после строительства, реконструкции, технического перевооружения и капитального ремонта ОПО магистральных трубопроводов (МТ)? Выберите два правильных варианта ответа.',
        answers: [
          'Технологическим регламентом',
          'Паспортом оборудования',
          'Проектной документацией (документацией)',
          'Заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Технологическим регламентом',
          'Проектной документацией (документацией)',
        ],
      },
      {
        code: '63655104',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем филиала или его уполномоченным заместителем',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С руководителем структурного подразделения, или лицом, его заменяющим, на объекте которого производится газоопасные работы',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63655105',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'К II группе',
          'К группе работ высокой степени опасности',
          'К группе работ средней степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63655106',
        text:
          'В какие сроки должен пересматриваться план мероприятий по локализации и ликвидации последствий аварий на ОПО при истечении срока действия предыдущего плана мероприятий?',
        answers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'Не менее чем за 7 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'В течение 15 календарных дней после истечения срока действия предыдущего плана мероприятий',
          'В течение 7 календарных дней после истечения срока действия предыдущего плана мероприятий',
        ],
        correctAnswers: [
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
      },
      {
        code: '63655107',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации',
          'Техническим руководителем эксплуатирующей организации',
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63655108',
        text:
          'При каком расположении населенных пунктов и промышленных предприятий при прокладке нефтепроводов и нефтепродуктопроводов проектом должны быть предусмотрены технические решения, исключающие поступление транспортируемой по трубопроводу среды в зону застройки?',
        answers: [
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 600 м при диаметре труб 700 мм и менее 1100 м при диаметре труб свыше 700 мм',
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм',
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 800 м при диаметре труб 700 мм и менее 1300 м при диаметре труб свыше 700 мм',
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 700 м при диаметре труб 700 мм и менее 1200 м при диаметре труб свыше 700 мм',
        ],
        correctAnswers: [
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм',
        ],
      },
      {
        code: '63655109',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками профессиональных аварийно-спасательных формирований',
          'Работники газоспасательной службы',
          'Работники подрядной организации',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63655110',
        text:
          'Когда оформляют формуляр (паспорт) подтверждения величины разрешенного (допустимого) рабочего давления на ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'До пуска ОПО МТ в эксплуатацию',
          'После пуска ОПО МТ в эксплуатацию',
          'В процессе эксплуатации ОПО МТ, но не позднее 10 дней с момента его пуска',
        ],
        correctAnswers: ['До пуска ОПО МТ в эксплуатацию'],
      },
      {
        code: '63655111',
        text:
          'Какие требования предъявляются к столбикам, на которых устанавливаются опознавательные знаки, обозначающие линейную часть магистрального газопровода на местности?',
        answers: [
          'Должны быть окрашены в оранжевый или ярко-желтый цвет, высотой от 1,5 м до 2 м',
          'Должны быть окрашены в синий цвет высотой от 1,5 м до 2 м',
          'Должны быть окрашены в красный или ярко-синий цвет, высотой не выше 1,5 м',
          'Должны быть окрашены в красный цвет, высотой от 1,0 м до 2,5 м',
        ],
        correctAnswers: [
          'Должны быть окрашены в оранжевый или ярко-желтый цвет, высотой от 1,5 м до 2 м',
        ],
      },
      {
        code: '63655112',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Газоопасные работы", "Газ"',
          '"Осторожно! Работают люди"',
          '"Опасная зона", "Работают люди!"',
          '"Опасная зона!", "Внимание!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63655113',
        text:
          'Что обязана предпринять эксплуатирующая организация ОПО магистральных трубопроводов (МТ) в случае угрозы аварии и возможного возникновения риска поражения для населения?',
        answers: [
          'Ограничить доступ работников на территорию места аварии или инцидента',
          'Продолжить эксплуатацию ОПО МТ в штатном режиме',
          'Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ',
        ],
        correctAnswers: [
          'Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ',
        ],
      },
      {
        code: '63655114',
        text:
          'Какой должна быть концентрация горючих паров и газов в месте проведения сварочных и других огневых работ на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Не превышающая 20% величины нижнего концентрационного предела распространения пламени',
          'Не превышающая 25% величины нижнего концентрационного предела распространения пламени',
          'Не превышающая 30% величины нижнего концентрационного предела распространения пламени',
          'Не превышающая 35% величины нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Не превышающая 20% величины нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63655115',
        text:
          'Что определяют на основании результатов технического диагностирования ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Величину разрешенного (допустимого) рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ',
          'Величину максимального рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ',
          'Величину минимального рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ',
        ],
        correctAnswers: [
          'Величину разрешенного (допустимого) рабочего давления в соответствии с нормативно-технической документацией по эксплуатации ОПО МТ',
        ],
      },
      {
        code: '63655116',
        text:
          'В соответствии с требованиями какого документа разрабатываются планы мероприятий по локализации и ликвидации последствий аварий на ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Постановления Правительства Российской Федерации от 15 сентября 2020 г. № 1437 "Об утверждении Положения о разработке планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах"',
          'Постановления Правительства РФ от 31.12.2020 № 2451 "Об утверждении Правил организации мероприятий по предупреждению и ликвидации разливов нефти и нефтепродуктов на территории Российской Федерации, за исключением внутренних морских вод Российской Федерации и территориального моря Российской Федерации"',
          'Федерального закона от 21.07.1997 № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'Постановления Правительства Российской Федерации от 15 сентября 2020 г. № 1437 "Об утверждении Положения о разработке планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах"',
        ],
      },
      {
        code: '63655117',
        text: 'Где регистрируются газоопасные работы II группы?',
        answers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
          'В журнале регистрации нарядов-допусков на проведение газоопасных работ',
          'В журнале регистрации целевого инструктажа по проведению газоопасных работ',
          'В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности',
        ],
        correctAnswers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
        ],
      },
      {
        code: '63655118',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее двух',
          'Не менее трех',
          'Один',
          'Определяется ответственным за проведение газоопасных работ',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63655119',
        text:
          'На какие магистральные трубопроводы не распространяются требования Правил безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'На конденсатопроводы',
          'На трубопроводы широкой фракции легких углеводородов',
          'На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак',
          'На магистральные трубопроводы и аммиакопроводы',
        ],
        correctAnswers: [
          'На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак',
        ],
      },
      {
        code: '63655120',
        text:
          'Что из перечисленного не следует проводить перед обследованием оборудования площадочных сооружений ОПО магистральных трубопроводов (МТ) в связи с выводом его из эксплуатации?',
        answers: [
          'Очистку',
          'Дегазацию и установку заглушек',
          'Опорожнение',
          'Опрессовку',
        ],
        correctAnswers: ['Опрессовку'],
      },
      {
        code: '63655121',
        text:
          'Какие из перечисленных работ допускаются в охранных зонах с письменного разрешения собственника магистрального газопровода или организации, эксплуатирующей магистральный газопровод?',
        answers: [
          'Только проводить погрузочно-разгрузочные работы',
          'Только проводить земляные работы на глубине более чем 0,3 м, планировка грунта',
          'Только проводить работы на объектах транспортной инфраструктуры, находящиеся на территории охранной зоны',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63655122',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 10% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63655123',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии руководителя структурного подразделения и лица, ответственного за проведение подготовительных работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63655124',
        text:
          'Что из перечисленного должен предусматривать план мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий',
          'Только первоочередные действия при получении сигнала об авариях на объекте',
          'Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники их возникновения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63655125',
        text:
          'Какое установлено ограничение диаметров трубопроводов, предназначенных для транспортирования широкой фракции легких углеводородов?',
        answers: [
          'Не более 400 мм',
          'Не более 500 мм',
          'Не более 600 мм',
          'Не более 700 мм',
        ],
        correctAnswers: ['Не более 400 мм'],
      },
      {
        code: '63655126',
        text:
          'Какая информация должна быть указана в перечне газоопасных работ? Выберите два правильных варианта ответа.',
        answers: [
          'Структурное подразделение организации (производство, цех, отделение, установка, участок)',
          'Список лиц, ответственных за подготовку и проведение газоопасных работ эксплуатирующей организации',
          'Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы',
          'Список лиц, ответственных за безопасное проведение газоопасных работ подрядной организации',
        ],
        correctAnswers: [
          'Структурное подразделение организации (производство, цех, отделение, установка, участок)',
          'Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы',
        ],
      },
      {
        code: '63655127',
        text:
          'При проведении каких работ представитель собственника магистрального газопровода или организации, эксплуатирующей магистральный газопровод, не вправе присутствовать при их проведении?',
        answers: [
          'При проведении погрузочно-разгрузочных работ, устройство водопоев скота, колка и заготовка льда',
          'При проведении земляных работ на глубине более чем 0,3 метра, планировка грунта',
          'При проведении инженерных изысканий, связанных с бурением скважин и устройством шурфов;',
          'При проведении работ, связанных с временным затоплением земель, не относящихся к землям сельскохозяйственного назначения.',
        ],
        correctAnswers: [
          'При проведении земляных работ на глубине более чем 0,3 метра, планировка грунта',
        ],
      },
      {
        code: '63655128',
        text:
          'В какую документацию должны быть своевременно внесены все конструктивные изменения линейных сооружений ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Только в проектную документацию',
          'Только в эксплуатационную документацию',
          'Только в исполнительную документацию',
          'В эксплуатационную и исполнительную документацию',
        ],
        correctAnswers: ['В эксплуатационную и исполнительную документацию'],
      },
      {
        code: '63655129',
        text:
          'Какой документ подтверждает величину разрешенного (допустимого) рабочего давления на основании результатов технического диагностирования ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Формуляр подтверждения величины разрешенного (допустимого) рабочего давления',
          'Акт технического диагностирования',
          'Паспорт технического устройства (отметка в паспорте)',
          'Заключение экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Формуляр подтверждения величины разрешенного (допустимого) рабочего давления',
        ],
      },
      {
        code: '63655130',
        text:
          'Кого обязаны извещать о начале и сроках проведения работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО магистральных трубопроводов (МТ) производители работ перед началом выполнения этих работ?',
        answers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ',
          'Разработчика проектной документации',
          'Органы местного самоуправления',
        ],
        correctAnswers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ',
        ],
      },
    ],
    63654: [
      {
        code: '63654000',
        text:
          'Каким образом производятся работы, связанные с временным затоплением земель, находящихся в охранных зонах трубопроводов?',
        answers: [
          'По согласованию между землепользователем и Минприроды',
          'По согласованию между землепользователем, предприятием трубопроводного транспорта и Ростехнадзором',
          'По согласованию между землепользователем и предприятием трубопроводного транспорта',
          'Не регламентируется',
        ],
        correctAnswers: [
          'По согласованию между землепользователем и предприятием трубопроводного транспорта',
        ],
      },
      {
        code: '63654001',
        text:
          'В каком случае осуществляются технические мероприятия по консервации и ликвидации опасных производственных объектов магистральных трубопроводов в соответствии с документацией на консервацию и ликвидацию опасных производственных объектов?',
        answers: [
          'После получения разрешения на консервацию и ликвидацию опасных производственных объектов, выдаваемого федеральным органом исполнительной власти в области промышленной безопасности',
          'После положительного заключения государственной экспертизы в соответствии с Градостроительным кодексом',
          'После проведения проверки опасных производственных объектов федеральным органом исполнительной власти в области промышленной безопасности',
          'После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации',
        ],
        correctAnswers: [
          'После получения положительного заключения экспертизы промышленной безопасности на документацию по консервации и ликвидации',
        ],
      },
      {
        code: '63654002',
        text:
          'Кто устанавливает продолжительность периода, на который ОПО магистральных трубопроводов (МТ) выводят из эксплуатации, условия нахождения в резерве (консервация или периодическое включение в работу в целях поддержания работоспособного состояния ОПО МТ)?',
        answers: [
          'Проектная организация',
          'Федеральный орган исполнительной власти в области промышленной безопасности',
          'Экспертная организация',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63654003',
        text:
          'Что, согласно требованиям нормативных правовых актов и нормативных технических документов, допускается не проводить при выводе из консервации опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Ревизию трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
          'Опробование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
          'Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
          'Испытания трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
        ],
        correctAnswers: [
          'Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
        ],
      },
      {
        code: '63654004',
        text:
          'При каком расположении населенных пунктов и промышленных предприятий при прокладке нефтепроводов и нефтепродуктопроводов проектом должны быть предусмотрены технические решения, исключающие поступление транспортируемой по трубопроводу среды в зону застройки?',
        answers: [
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм',
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 600 м при диаметре труб 700 мм и менее 1100 м при диаметре труб свыше 700 мм',
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 700 м при диаметре труб 700 мм и менее 1200 м при диаметре труб свыше 700 мм',
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 800 м при диаметре труб 700 мм и менее 1300 м при диаметре труб свыше 700 мм',
        ],
        correctAnswers: [
          'Расположенных на отметках ниже этих трубопроводов на расстоянии от них менее 500 м при диаметре труб 700 мм и менее 1000 м при диаметре труб свыше 700 мм',
        ],
      },
      {
        code: '63654005',
        text:
          'Какие из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указаны неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63654006',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Лицу, замещающее руководителя структурного подразделения, на объекте которого проводятся газоопасные работы',
          'Лицу, представляющему профессиональную аварийно-спасательную службу',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63654007',
        text:
          'Каким образом определяются объем и методы неразрушающего контроля сварных соединений?',
        answers: [
          'Экспертной организацией',
          'Технической документацией на оборудование (паспорт, инструкция и т. д.)',
          'Организацией, осуществляющей строительство опасных производственных объектов магистральных трубопроводов',
          'Проектной документацией/документацией',
        ],
        correctAnswers: ['Проектной документацией/документацией'],
      },
      {
        code: '63654008',
        text:
          'Каким испытаниям должны быть подвергнуты трубопроводы линейной части ОПО магистральных трубопроводов (МТ) по завершении строительства, реконструкции, технического перевооружения и капитального ремонта?',
        answers: [
          'На прочность и герметичность',
          'Только на прочность',
          'Только на герметичность',
          'Не регламентируется',
        ],
        correctAnswers: ['На прочность и герметичность'],
      },
      {
        code: '63654009',
        text:
          'Кто составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе ОПО магистральных трубопроводов (МТ) из консервации?',
        answers: [
          'Территориальный орган Ростехнадзора',
          'Экспертная организация',
          'Эксплуатирующая организация',
          'Проектная организация',
          'Подрядная организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63654010',
        text:
          'Что не относится к основным этапам процесса проведения количественного анализа риска аварии на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Оценка риска методом НАZOP',
          'Идентификация опасностей аварий',
          'Количественная оценка риска аварий на опасных производственных объектах магистральных трубопроводов',
          'Разработка рекомендаций по снижению риска аварий',
        ],
        correctAnswers: ['Оценка риска методом НАZOP'],
      },
      {
        code: '63654011',
        text:
          'Чем подтверждается соответствие комплектного оборудования и технических устройств на опасных производственных объектах (далее - ОПО) магистральных трубопроводов (МТ) при наличии технической документации изготовителя, а также при их соответствии требованиям технических регламентов и Правил безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Сертификатом соответствия системы ГОСТ Р',
          'Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности',
          'Сертификатом системы качества изготовителей',
          'Разрешением на применение технических устройств на опасных производственных объектах',
          'Техническим заданием',
          'Проектом',
        ],
        correctAnswers: [
          'Документом соответствия требованиям технических регламентов или заключением экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63654012',
        text:
          'Какие факторы следует учитывать при определении периодичности, мест и методов контроля, применяемых при техническом диагностировании?',
        answers: [
          'Данные о строительстве МАП',
          'Характеристики наиболее опасных участков, особенности расположения',
          'Данные о режимах работы системы электрохимической защиты',
          'Все перечисленные',
          'Условия эксплуатации (продолжительность, технологические режимы)',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63654013',
        text:
          'Куда должны быть переданы материалы фактического положения трубопровода (исполнительная съемка) с привязкой охранных зон, входящих в его состав коммуникаций и объектов?',
        answers: [
          'В местные органы власти и управления',
          'В территориальные органы Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'В территориальные органы Ростехнадзора',
          'Заинтересованным федеральным органам исполнительной власти',
          'В проектную организацию',
        ],
        correctAnswers: ['В местные органы власти и управления'],
      },
      {
        code: '63654014',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63654015',
        text:
          'В течение какого времени заполнение линейных сооружений опасных производственных объектов магистральных трубопроводов углеводородами и его работу после заполнения считают комплексным опробованием линейного сооружения опасных производственных объектов магистральных трубопроводов?',
        answers: ['24 часов', '48 часов', '72 часов', '96 часов'],
        correctAnswers: ['72 часов'],
      },
      {
        code: '63654016',
        text:
          'В какой документации определяются места установки и количество автоматических датчиков или пробоотборных устройств автоматических анализаторов на площадочных объектах ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В предписании Ростехнадзора',
          'В производственных инструкциях',
          'В технологическом регламенте',
          'В проектной документации/документации',
        ],
        correctAnswers: ['В проектной документации/документации'],
      },
      {
        code: '63654017',
        text:
          'Каким образом необходимо контролировать содержание горючих паров и газов в воздухе рабочей зоны или помещения в месте проведения ремонтных, газоопасных работ на ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Передвижными мобильными системами',
          'Содержание горючих паров и газов в воздухе рабочей зоны не контролируется',
          'Приборами контроля с автоматической сигнализацией на месте отбора проб',
        ],
        correctAnswers: [
          'Приборами контроля с автоматической сигнализацией на месте отбора проб',
        ],
      },
      {
        code: '63654018',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63654019',
        text:
          'В течение какого времени после пробной эксплуатации опасных производственных объектов магистральных трубопроводов эксплуатирующая организация составляет акт о вводе объекта в действие с приложением перечня выполненных работ при выводе опасных производственных объектов магистральных трубопроводов из консервации?',
        answers: [
          'В течение 12 часов',
          'В течение 24 часов',
          'в течение 48 часов',
          'В течение 72 часов',
        ],
        correctAnswers: ['В течение 72 часов'],
      },
      {
        code: '63654020',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'Во время грозы',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
          'Не регламентируется',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63654021',
        text:
          'Что должно быть обеспечено для контроля состояния линейных участков, сооружений и объектов ОПО магистральных трубопроводов (МТ) и выявления факторов, создающих угрозу безопасности при их эксплуатации?',
        answers: [
          'Постоянный и периодический контроль (патрулирование) линейных сооружений, осуществляемый в соответствии с утвержденными графиками',
          'Видеонаблюдение',
          'Установка датчиков',
        ],
        correctAnswers: [
          'Постоянный и периодический контроль (патрулирование) линейных сооружений, осуществляемый в соответствии с утвержденными графиками',
        ],
      },
      {
        code: '63654022',
        text:
          'Какие требования к эксплуатирующей организации по предупреждению и ликвидации аварий на ОПО магистральных трубопроводов (МТ) указаны неверно?',
        answers: [
          'Планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО МТ возлагается на эксплуатирующую организацию',
          'В эксплуатационных подразделениях должны быть созданы и укомплектованы службы аварийно-восстановительных бригад и нештатные аварийно-спасательные формирования',
          'Разработка организационных и технических мероприятий для безопасной эксплуатации ОПО МТ и подготовка персонала к действиям по локализации и ликвидации последствий аварий возлагается на эксплуатирующую организацию',
          'Все перечисленное указано верно',
        ],
        correctAnswers: ['Все перечисленное указано верно'],
      },
      {
        code: '63654023',
        text:
          'К какому устройству ОПО магистральных трубопроводов (МТ) и ОПО магистральных аммиакопроводов (МАП) допускается несанкционированный доступ в аварийной ситуации?',
        answers: [
          'К запорной арматуре',
          'К регулирующей арматуре',
          'К предохранительной арматуре',
          'Все ответы неверны',
          'К автоматизированным системам управления технологическими процессами',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63654024',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Специализированная подрядная организация',
          'Руководитель структурного подразделения',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63654025',
        text:
          'Какие меры необходимо предпринять при обнаружении на месте производства работ в охранной зоне подземных коммуникаций и сооружений, не указанных в проектной документации? Выберите два правильных варианта ответа.',
        answers: [
          'Немедленно вывести персонал и технические средства за пределы этой зоны',
          'Вызвать представителя эксплуатационной организации',
          'Вызвать аварийно-восстановительную бригаду',
          'Немедленно остановить работы',
        ],
        correctAnswers: [
          'Вызвать представителя эксплуатационной организации',
          'Немедленно остановить работы',
        ],
      },
      {
        code: '63654026',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Утверждать наряд-допуск на проведение газоопасных работ в единственном экземпляре',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63654027',
        text:
          'В соответствии с требованиями какого нормативно-правового документа обеспечивается пожарная безопасность опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Федерального закона от 22 июля 2008 г. № 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
          'Правил безопасности для опасных производственных объектов магистральных трубопроводов',
          'СП 36.13330.2012 "Свод правил. Магистральные трубопроводы"',
          'Федерального закона от 21 июля 1997 г. № 116-ФЗ "О промышленной безопасности опасных производственных объектов"',
          'Внутренним инструкциям по пожарной безопасности',
        ],
        correctAnswers: [
          'Федерального закона от 22 июля 2008 г. № 123-ФЗ "Технический регламент о требованиях пожарной безопасности"',
        ],
      },
      {
        code: '63654028',
        text:
          'В каких случаях вместо гидравлических испытаний допускается проведение испытаний линейной части опасных производственных объектов магистральных трубопроводов на прочность и герметичность газообразными рабочими средами?',
        answers: [
          'Только при отрицательных температурах окружающей среды',
          'При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды',
          'Проведение пневматических испытаний на прочность и герметичность линейной части опасных производственных объектов магистральных трубопроводов запрещено',
          'Только при осадках в виде дождя и снега',
        ],
        correctAnswers: [
          'При отрицательных температурах окружающей среды или невозможности обеспечить необходимое количество жидкой рабочей среды',
        ],
      },
      {
        code: '63654029',
        text:
          'Какие работы должны быть проведены перед началом осуществления работ по выводу из эксплуатации ОПО магистральных трубопроводов (МТ) и ОПО магистральных аммиакопроводов (МАП), подлежащих ликвидации?',
        answers: [
          'Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака',
          'Работы по очистке трубопроводов',
          'Ревизия и проверка трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
          'Техническое диагностирование трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов',
        ],
        correctAnswers: [
          'Работы по освобождению трубопроводов и оборудования данных объектов от углеводородов или жидкого аммиака',
        ],
      },
      {
        code: '63654030',
        text:
          'Что должна включать оценка технического состояния оборудования площадочных сооружений опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Только наружное обследование в режиме эксплуатации',
          'Только полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)',
          'Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)',
        ],
        correctAnswers: [
          'Наружное обследование в режиме эксплуатации и полное техническое обследование в режиме выведения из эксплуатации (временного или длительного)',
        ],
      },
      {
        code: '63654031',
        text:
          'Какие сведения допускается не учитывать при определении периодичности, полноты и порядка обследования, методов и средств контроля трубопроводов и оборудования опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Данные о строительстве магистральных трубопроводов',
          'Информацию о техническом состоянии магистральных трубопроводов',
          'Информацию об условиях эксплуатации магистральных трубопроводов',
          'Сведения об устранении дефектов в результате планового капремонта',
          'Особенности района расположения (наличие охранных зон, наиболее опасных участков)',
          'Информацию о свойствах обращаемого продукта',
        ],
        correctAnswers: [
          'Сведения об устранении дефектов в результате планового капремонта',
        ],
      },
      {
        code: '63654032',
        text:
          'Что из перечисленного не следует проводить перед обследованием оборудования площадочных сооружений ОПО магистральных трубопроводов (МТ) в связи с выводом его из эксплуатации?',
        answers: [
          'Опорожнение',
          'Очистку',
          'Дегазацию',
          'Опрессовку',
          'Установку заглушек',
        ],
        correctAnswers: ['Опрессовку'],
      },
      {
        code: '63654033',
        text:
          'Какой документ подтверждает величину разрешенного (допустимого) рабочего давления на основании результатов технического диагностирования ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Формуляр подтверждения величины разрешенного (допустимого) рабочего давления',
          'Свидетельство по итогам технического диагностирования',
          'Паспорт технического устройства (отметка в паспорте)',
          'Заключение экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Формуляр подтверждения величины разрешенного (допустимого) рабочего давления',
        ],
      },
      {
        code: '63654034',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Технологический регламент',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63654035',
        text:
          'Кому обязан немедленно сообщить о повреждении трубопровода производственный персонал, выполняющий осмотр или обслуживание инженерных коммуникаций и объектов, находящихся в районе прохождения трубопровода?',
        answers: [
          'Представителю Ростехнадзора',
          'Соответствующим органам власти и управления',
          'Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода',
        ],
        correctAnswers: [
          'Диспетчерской или аварийной службе производственного подразделения, эксплуатирующего данный участок трубопровода',
        ],
      },
      {
        code: '63654036',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Главный инженер эксплуатирующей организации',
          'Руководитель аварийно-спасательной службы совместно с руководителем эксплуатирующей организации',
          'Руководитель службы производственного контроля (лицо, ответственное за осуществление производственного контроля)',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63654037',
        text:
          'В какое время суток работники, обслуживающие нефтепроводы, допускаются для проведения осмотров и ремонтных работ, если нефтепроводы проходят по территории запретных зон и специальных объектов?',
        answers: [
          'В ночное время',
          'В дневное время',
          'По усмотрению соответствующих организаций, выдающих пропуски',
          'В любое время суток',
        ],
        correctAnswers: ['В любое время суток'],
      },
      {
        code: '63654038',
        text:
          'Какие условия должны быть обеспечены в процессе эксплуатации опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и меры по закреплению трубопровода на проектных отметках в случае его смещения',
          'Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и укомплектованность рабочих мест необходимой документацией, запасами материалов, запасными частями, инвентарем, средствами индивидуальной и коллективной защиты согласно установленным нормам',
          'Укомплектованность рабочих мест необходимой документацией, запасами материалов, запасными частями, инвентарем, средствами индивидуальной и коллективной защиты согласно установленным нормам и меры по закреплению трубопровода на проектных отметках в случае его смещения',
          'Сохранность опознавательных знаков трассы',
        ],
        correctAnswers: [
          'Контроль технического состояния опасных производственных объектов магистральных трубопроводов с применением необходимых методов технического диагностирования и меры по закреплению трубопровода на проектных отметках в случае его смещения',
        ],
      },
      {
        code: '63654039',
        text:
          'В каких случаях проводят контроль содержания горючих паров и газов в воздухе рабочей зоны или помещения при проведении ремонтных, газоопасных работ на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Только перед началом проведения работ',
          'Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа',
          'Перед началом проведения работ и после каждого перерыва длительностью не менее двух часов',
          'Перед началом проведения работ и после каждого перерыва длительностью не менее трех часов',
        ],
        correctAnswers: [
          'Перед началом проведения работ и после каждого перерыва длительностью не менее одного часа',
        ],
      },
      {
        code: '63654040',
        text:
          'Какие из перечисленных степеней со среднестатистическим уровнем риска аварий при анализе результатов расчетов на участках и составляющих ОПО магистральных трубопроводов (МТ) указаны неверно?',
        answers: [
          'Низкая',
          'Средняя',
          'Высокая',
          'Чрезвычайно высокая',
          'Чрезвычайно низкая',
        ],
        correctAnswers: ['Чрезвычайно низкая'],
      },
      {
        code: '63654041',
        text:
          'На каком расстоянии производится установка опознавательных знаков обозначения трассы магистрального трубопровода?',
        answers: [
          'В пределах прямой видимости',
          'В пределах прямой видимости, но не реже чем через 200 м',
          'В пределах прямой видимости, но не реже чем через 400 м и на углах поворота',
          'В пределах прямой видимости, но не реже чем через 500 м и на углах поворота',
          'Не регламентируется',
        ],
        correctAnswers: [
          'В пределах прямой видимости, но не реже чем через 500 м и на углах поворота',
        ],
      },
      {
        code: '63654042',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнологический персонал эксплуатирующей организации',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63654043',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63654044',
        text:
          'Кого должны поставить в известность о начале и сроках работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО магистральных трубопроводов (МТ) производители работ перед началом выполнения данных работ?',
        answers: [
          'Проектную организацию',
          'Территориальные органы Ростехнадзора',
          'Местные органы муниципального управления',
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ',
        ],
        correctAnswers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с ОПО МТ',
        ],
      },
      {
        code: '63654045',
        text:
          'Каким требованиям должен соответствовать технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов, определяющий порядок организации надежного и безопасного ведения технологического процесса?',
        answers: [
          'Проектным решениям',
          'Действительным характеристикам трубопровода',
          'Требованиям законодательства Российской Федерации в области промышленной безопасности и нормативных технических документов',
          'Проектным решениям, условиям работы опасных производственных объектов магистральных трубопроводов, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании',
        ],
        correctAnswers: [
          'Проектным решениям, условиям работы опасных производственных объектов магистральных трубопроводов, требованиям законодательства Российской Федерации в области промышленной безопасности и законодательства Российской Федерации о техническом регулировании',
        ],
      },
      {
        code: '63654046',
        text:
          'С учетом каких факторов определяются сроки и методы технического диагностирования опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Опасности и технического состояния участков линейной части опасных производственных объектов магистральных трубопроводов',
          'Показателей эксплуатации (срок службы, ресурс), установленных проектной и/или нормативно-технической документацией',
          'Опасности и технического состояния сооружений и технических устройств площадочных сооружений опасных производственных объектов магистральных трубопроводов',
          'Всех вышеперечисленных факторов',
        ],
        correctAnswers: ['Всех вышеперечисленных факторов'],
      },
      {
        code: '63654047',
        text:
          'В течение какого периода эксплуатирующая организация обязана проводить периодические обследования трубопроводов и оборудования ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В течение срока, установленного проектной документацией',
          'В течение 25 лет',
          'В течение всего жизненного цикла (до ликвидации ОПО МТ)',
          'В течение срока амортизации трубопроводов и оборудования',
          'В течение 20 лет',
        ],
        correctAnswers: [
          'В течение всего жизненного цикла (до ликвидации ОПО МТ)',
        ],
      },
      {
        code: '63654048',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63654049',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Определить структурное подразделение, с которым предстоит взаимодействовать бригаде, проводящей газоопасные работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63654050',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах стационарных автомобильных газозаправочных станций, автомобильных газонаполнительных компрессорных станциях и криогенных автозаправочных станциях газомоторного топлива',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на объектах тепло- и электроэнергетики',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63654051',
        text:
          'Какая информация должна быть указана в перечне газоопасных работ? Выберите два правильных варианта ответа.',
        answers: [
          'Список лиц, ответственных за безопасную подготовку и проведение газоопасных работ подрядной организации',
          'Структурное подразделение организации (производство, цех, отделение, установка, участок)',
          'Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы',
          'График проверки средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Структурное подразделение организации (производство, цех, отделение, установка, участок)',
          'Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы), выполняющих указанные работы',
        ],
      },
      {
        code: '63654052',
        text:
          'Каким образом должны быть обозначены трассы нефтепродуктопроводов в соответствии с правилами охраны магистральных трубопроводов?',
        answers: [
          'Трассы должны быть обозначены столбиками высотой 1 м от поверхности земли, устанавливаемыми в пределах прямой видимости, на углах поворота, водных и воздушных переходах, а также при пересечении трубопроводом шоссейных и железных дорог',
          'Трассы должны быть обозначены предупреждающими знаками в пределах прямой видимости, но не реже чем через 1,5 км, на углах поворота, водных и воздушных переходах, а также при пересечении трубопроводом шоссейных и железных дорог',
          'Трассы должны быть обозначены опознавательными знаками (со щитами-указателями) высотой 1,5 - 2 м от поверхности земли, устанавливаемыми в пределах прямой видимости, но не реже чем через 500 м и на углах поворота',
          'Трассы должны быть обозначены предупреждающими знаками высотой 2,5 м от поверхности земли в пределах видимости, на водных и воздушных переходах - столбиками высотой 1,5 - 2 м',
        ],
        correctAnswers: [
          'Трассы должны быть обозначены опознавательными знаками (со щитами-указателями) высотой 1,5 - 2 м от поверхности земли, устанавливаемыми в пределах прямой видимости, но не реже чем через 500 м и на углах поворота',
        ],
      },
      {
        code: '63654053',
        text:
          'Какой документацией предусматриваются технические решения по очистке полости трубопроводов после строительства, реконструкции, технического перевооружения и капитального ремонта ОПО магистральных трубопроводов (МТ)? Выберите два правильных варианта ответа.',
        answers: [
          'Технологическим регламентом',
          'Паспортом оборудования',
          'Проектной документацией (документацией)',
          'Заключением экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Технологическим регламентом',
          'Проектной документацией (документацией)',
        ],
      },
      {
        code: '63654054',
        text:
          'Что должно быть обеспечено на территории размещения линейных и площадочных сооружений опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Возможность строительства жилых зданий малой этажности',
          'Возможность строительства производственных сооружений',
          'Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов',
          'Возможность размещения складов взрывчатых веществ',
          'Возможность размещения бытовых помещений',
        ],
        correctAnswers: [
          'Возможность проведения строительно-монтажных работ с использованием грузоподъемной и специальной техники, а также возможность размещения мест складирования оборудования и строительных материалов',
        ],
      },
      {
        code: '63654055',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63654056',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Исполнители ремонтных работ расписываются в вахтовом журнале ремонтируемого объекта',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63654057',
        text:
          'В какой документ заносятся результаты входного контроля конструкций, изделий, материалов, оборудования и технических устройств ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'В паспорт оборудования',
          'В журнал входного контроля с оформлением акта проверки',
          'В протокол по итогам входного контроля',
          'Процедура занесения результатов входного контроля в какой-либо документ не регламентируется',
        ],
        correctAnswers: [
          'В журнал входного контроля с оформлением акта проверки',
        ],
      },
      {
        code: '63654058',
        text:
          'В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.',
        answers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
          'Когда газоопасные работы могут быть проведены без непосредственного участия людей',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
        ],
        correctAnswers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
        ],
      },
      {
        code: '63654059',
        text:
          'Кого обязаны извещать о начале и сроках проведения работ по реконструкции, техническому перевооружению и капитальному ремонту линейных сооружений ОПО магистральных трубопроводов (МТ) производители работ перед началом выполнения этих работ?',
        answers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с опасными производственными объектами магистральных трубопроводов',
          'Органы местного самоуправления',
          'Разработчика проектной документации',
          'Ростехнадзор',
        ],
        correctAnswers: [
          'Организации (собственников), эксплуатирующие сооружения, проходящие в одном техническом коридоре с опасными производственными объектами магистральных трубопроводов',
        ],
      },
      {
        code: '63654060',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К I группе',
          'К группе неопасных работ',
          'К группе работ повышенной опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63654061',
        text:
          'Какие действия следуют по завершении строительства, реконструкции, технического перевооружения и после испытания на прочность и проверки на герметичность опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Приступают к эксплуатации опасных производственных объектов магистральных трубопроводов в режиме опытного использования',
          'Осуществляют комплексное опробование опасных производственных объектов магистральных трубопроводов',
          'Приступают к эксплуатации опасных производственных объектов магистральных трубопроводов в штатном режиме',
          'Осуществляют пусконаладочные мероприятия',
        ],
        correctAnswers: [
          'Осуществляют комплексное опробование опасных производственных объектов магистральных трубопроводов',
        ],
      },
      {
        code: '63654062',
        text:
          'В каких целях проводят периодическое техническое диагностирование опасных производственных объектов магистральных трубопроводов в процессе эксплуатации?',
        answers: [
          'В целях обеспечения безопасности',
          'В целях определения фактического технического состояния опасных производственных объектов магистральных трубопроводов',
          'В целях расчета допустимого давления',
          'В целях определения возможности дальнейшей эксплуатации на проектных технологических режимах',
          'Во всех перечисленных целях',
        ],
        correctAnswers: ['Во всех перечисленных целях'],
      },
      {
        code: '63654063',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Только специалист, обученный пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63654064',
        text:
          'На основании каких данных определяют величину разрешенного (допустимого) рабочего давления?',
        answers: [
          'На основании результатов технического диагностирования и в соответствии с нормативно-технической документацией по эксплуатации опасных производственных объектов магистральных трубопроводов',
          'На основании решения эксплуатирующей организации, оформленного протоколом',
          'На основании решения Ростехнадзора',
          'На основании проектных решений',
        ],
        correctAnswers: [
          'На основании результатов технического диагностирования и в соответствии с нормативно-технической документацией по эксплуатации опасных производственных объектов магистральных трубопроводов',
        ],
      },
      {
        code: '63654065',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
          'Работники, список которых определяется внутренними документами организации',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63654066',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны пройти инструктаж',
          'Исполнители должны уметь пользоваться СИЗ',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63654067',
        text: 'Где регистрируются газоопасные работы II группы?',
        answers: [
          'В журнале регистрации нарядов-допусков на проведение газоопасных работ с присвоением очередного номера',
          'В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности',
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
          'В журнале регистрации целевого инструктажа',
        ],
        correctAnswers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
        ],
      },
      {
        code: '63654068',
        text:
          'Чем должны быть определены объем и периодичность выполняемых работ при техническом обслуживании опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Проектной документацией и нормативными документами производителей оборудования',
          'Технологическим регламентом на эксплуатацию опасных производственных объектов магистральных трубопроводов',
          'Нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию',
          'Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния',
          'Федеральными нормами и правилами в области промышленной безопасности',
        ],
        correctAnswers: [
          'Проектной документацией/документацией, технологическим регламентом на эксплуатацию ОПО МТ, нормативно-техническими документами заводов-изготовителей к трубам, материалам и оборудованию, а также результатами контроля технического состояния',
        ],
      },
      {
        code: '63654069',
        text:
          'Какие виды работ могут проводиться в охранных зонах трубопроводов без получения разрешения от предприятия трубопроводного транспорта?',
        answers: [
          'Строительные работы не ближе 20 м от оси трубопровода в каждую сторону',
          'Ремонтно-восстановительные и сельскохозяйственные работы',
          'Размещение автотранспорта на специально подготовленной площадке',
          'Прокладка подземного кабеля связи',
        ],
        correctAnswers: [
          'Ремонтно-восстановительные и сельскохозяйственные работы',
        ],
      },
      {
        code: '63654070',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем филиала или его уполномоченным заместителем',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С руководителем структурного подразделения, или лицом, его заменяющим, на объекте которого производится газоопасные работы',
          'С газоспасательной службой и службой охраны труда',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63654071',
        text:
          'Какой должна быть концентрация горючих паров и газов в месте проведения сварочных и других огневых работ на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Не превышающей 20% величины нижнего концентрационного предела распространения пламени',
          'Не превышающей 25% величины нижнего концентрационного предела распространения пламени',
          'Не превышающей 30% величины нижнего концентрационного предела распространения пламени',
          'Не превышающей 40% величины нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Не превышающей 20% величины нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63654072',
        text:
          'Что обязана предпринять эксплуатирующая организация ОПО магистральных трубопроводов (МТ) в случае угрозы аварии и возможного возникновения риска поражения для населения?',
        answers: [
          'Изолировать территорию места аварии или инцидента для доступа работников',
          'Продолжать эксплуатацию ОПО МТ в обычном порядке',
          'Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ',
        ],
        correctAnswers: [
          'Все необходимые меры по обследованию ОПО МТ, при необходимости ограничивать режим работы или приостанавливать эксплуатацию ОПО МТ',
        ],
      },
      {
        code: '63654073',
        text:
          'На основе чего осуществляется определение нагрузок и воздействий, возникающих на этапах строительства, эксплуатации, реконструкции, при техническом перевооружении, капитальном ремонте, консервации, ликвидации ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации в области градостроительной деятельности',
          'На основе результатов анализа опасностей технологических процессов и анализа риска аварий',
          'На основе проектной документации',
          'На основе предписания территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'На основе результатов инженерных изысканий, получивших положительное заключение экспертизы в порядке, установленном законодательством Российской Федерации в области градостроительной деятельности',
        ],
      },
      {
        code: '63654074',
        text:
          'Какие сведения может не содержать формуляр (паспорт) подтверждения величины разрешенного (допустимого) рабочего давления на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Об участке (номере участка) опасного производственного объекта магистрального трубопровода',
          'О сроках проведения следующего технического диагностирования',
          'О величине разрешенного давления',
          'О необходимости обеспечения объекта предохранительными устройствами для ограничения величины рабочего давления',
        ],
        correctAnswers: [
          'О сроках проведения следующего технического диагностирования',
        ],
      },
      {
        code: '63654075',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии руководителя структурного подразделения и лица, ответственного за проведение подготовительных работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63654076',
        text:
          'Какой документ разрабатывают для вывода опасных производственных объектов магистральных трубопроводов из консервации и ввода их в эксплуатацию?',
        answers: [
          'Технический регламент с указанием перечня работ',
          'График выполнения мероприятий по вводу опасных производственных объектов магистральных трубопроводов в эксплуатацию',
          'Рабочую программу с указанием перечня работ, порядка и сроков их выполнения',
        ],
        correctAnswers: [
          'Рабочую программу с указанием перечня работ, порядка и сроков их выполнения',
        ],
      },
      {
        code: '63654077',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'При наличии разрешения лица, ответственного за осуществление производственного контроля, в средствах индивидуальной защиты органов дыхания',
          'При наличии разрешения представителя газоспасательной службы при обеспечении принудительного воздухообмена в газоопасном месте',
          'При наличии разрешения лица, ответственного за подготовку и проведение работ, с инструментом, исключающим возможность искрообразования',
          'При наличии разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
        correctAnswers: [
          'При наличии разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63654078',
        text:
          'Какая из перечисленных документаций должна храниться в эксплуатирующей ОПО магистральных трубопроводов (МТ) организации?',
        answers: [
          'Только эксплуатационная документация',
          'Только исполнительная документация',
          'Только проектная документация',
          'Вся перечисленная',
        ],
        correctAnswers: ['Вся перечисленная'],
      },
      {
        code: '63654079',
        text:
          'В какую документацию должны быть своевременно внесены все конструктивные изменения линейных сооружений ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Только в проектную документацию',
          'Только в эксплуатационную документацию',
          'Только в исполнительную документацию',
          'В эксплуатационную и исполнительную документацию',
        ],
        correctAnswers: ['В эксплуатационную и исполнительную документацию'],
      },
      {
        code: '63654080',
        text:
          'В каком случае не применяются Правила безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'При разработке технологических процессов, проектировании, строительстве, эксплуатации, реконструкции, техническом перевооружении, капитальном ремонте, консервации и ликвидации опасных производственных объектов магистральных трубопроводов',
          'При изготовлении, монтаже, наладке, обслуживании, диагностировании и ремонте технических устройств',
          'При проведении экспертизы промышленной безопасности документации на консервацию, ликвидацию, техническое перевооружение опасного производственного объекта',
          'При страховании гражданской ответственности владельцев опасных объектов',
        ],
        correctAnswers: [
          'При страховании гражданской ответственности владельцев опасных объектов',
        ],
      },
      {
        code: '63654081',
        text:
          'Каким образом определяются сроки и методы проведения работ по реконструкции, техническому перевооружению, капитальному ремонту и консервации опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'На основе проектной документации/документации, исходя из требований промышленной безопасности и условий обеспечения бесперебойной эксплуатации ОПО МТ',
          'Исходя из результатов комплексного обследования',
          'Исходя из конструктивных особенностей оборудования',
          'Исходя из ремонтной документации подразделения',
        ],
        correctAnswers: [
          'На основе проектной документации/документации, исходя из требований промышленной безопасности и условий обеспечения бесперебойной эксплуатации ОПО МТ',
        ],
      },
      {
        code: '63654082',
        text:
          'После чего следует начинать производство работ по реконструкции, техническому перевооружению и капитальному ремонту опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'После выполнения подготовительных мероприятий и письменного разрешения руководства эксплуатирующей организации на производство работ',
          'После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ',
          'После приемки объектов подрядчиком',
          'После письменного разрешения руководства эксплуатирующей организации на производство работ',
        ],
        correctAnswers: [
          'После выполнения подготовительных мероприятий, приемки объектов подрядчиком и письменного разрешения руководства эксплуатирующей организации на производство работ',
        ],
      },
      {
        code: '63654083',
        text:
          'В какие сроки эксплуатирующая организация обязана пересматривать инструкции на рабочих местах?',
        answers: [
          '1 раз в 3 года',
          '1 раз в год',
          '1 раз в 5 лет',
          '1 раз в 2 года',
        ],
        correctAnswers: ['1 раз в 5 лет'],
      },
      {
        code: '63654084',
        text:
          'Какая информация не приводится на щите-указателе опознавательного знака обозначения трассы магистрального трубопровода?',
        answers: [
          'Местоположение оси трубопровода от основания знака',
          'Разрешенное рабочее давление трубопровода',
          'Привязка знака (км, пк) к трассе',
          'Размеры охранной зоны',
        ],
        correctAnswers: ['Разрешенное рабочее давление трубопровода'],
      },
      {
        code: '63654085',
        text:
          'Какое установлено ограничение диаметров трубопроводов, предназначенных для транспортирования широкой фракции легких углеводородов?',
        answers: [
          'Не более 400 мм',
          'Не более 500 мм',
          'Не более 600 мм',
          'Не более 700 мм',
        ],
        correctAnswers: ['Не более 400 мм'],
      },
      {
        code: '63654086',
        text:
          'Что может применяться в качестве жидкой рабочей среды при гидравлических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?',
        answers: [
          'Только вода',
          'Вода и другие негорючие жидкости',
          'Вода и другие негорючие жидкости, а также горючие жидкости при обосновании их применения в документации на проведение испытаний и уведомлении федерального органа исполнительной власти в области промышленной безопасности',
          'Вид жидкости в ФНП "Правила безопасности для опасных производственных объектов магистральных трубопроводов" не нормируется',
        ],
        correctAnswers: ['Вода и другие негорючие жидкости'],
      },
      {
        code: '63654087',
        text:
          'Что допускается не учитывать при определении периодичности и методов патрулирования трассы линейных сооружений опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Техническое состояние трубопроводов',
          'Особенности участка прокладки трубопровода',
          'Природные факторы, влияющие на безопасность эксплуатации трубопровода',
          'Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты',
        ],
        correctAnswers: [
          'Транспортируемые на опасных производственных объектах магистральных трубопроводов продукты',
        ],
      },
      {
        code: '63654088',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'Без наряда-допуска, по устному распоряжению',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63654089',
        text: 'В чем заключается основная задача анализа риска?',
        answers: [
          'В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках опасных производственных объектов магистральных трубопроводов',
          'В информировании федерального органа исполнительной власти в области промышленной безопасности о существующих рисках на опасных производственных объектах',
          'В определении сумм потенциального ущерба в случае возникновения аварии на опасных производственных объектах',
          'В информировании населения о существующих рисках на опасных производственных объектах',
        ],
        correctAnswers: [
          'В предоставлении должностным лицам, принимающим решения по обеспечению безопасности, сведений о наиболее опасных процессах, участках опасных производственных объектов магистральных трубопроводов',
        ],
      },
      {
        code: '63654090',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Все перечисленные требования',
          'Прошедшие обучение приемам и методам проведения работ',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Не моложе 18 лет',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63654091',
        text:
          'Где должен храниться формуляр (паспорт), на основании которого была установлена величина разрешенного (допустимого) рабочего давления?',
        answers: [
          'В архиве эксплуатирующей организации',
          'В архиве эксплуатирующей организации и органах местного самоуправления',
          'В Ростехнадзоре',
          'В проектной организации',
        ],
        correctAnswers: ['В архиве эксплуатирующей организации'],
      },
      {
        code: '63654092',
        text:
          'За какое время до начала проведения в охранных зонах работ, требующих присутствия представителя предприятия трубопроводного транспорта, юридические или физические лица, имеющие намерение проводить работы, обязаны пригласить этого представителя на место производства работ?',
        answers: [
          'Не менее чем за 2 суток',
          'Не менее чем за 5 суток',
          'Не менее чем за 5 рабочих дней',
          'Нормативными документами не регламентируется',
        ],
        correctAnswers: ['Не менее чем за 5 суток'],
      },
      {
        code: '63654093',
        text:
          'Какая из перечисленных специальных мер безопасности, снижающая риск аварии, не предусматривается проектной документацией/документацией для наиболее опасных участков объектов линейной части ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Увеличение диаметра трубопровода',
          'Увеличение глубины залегания трубопровода',
          'Увеличение толщины стенки трубопровода',
          'Устройство отводящих систем (каналов, канав)',
        ],
        correctAnswers: ['Увеличение диаметра трубопровода'],
      },
      {
        code: '63654094',
        text:
          'В каких случаях требуется пересмотр технологического регламента на эксплуатацию магистральных трубопроводов?',
        answers: [
          'В случае изменения требований промышленной безопасности',
          'В случае изменения параметров ведения технологического процесса',
          'В случаях, предусмотренных законодательством Российской Федерации в области промышленной безопасности',
          'Во всех вышеперечисленных случаях',
        ],
        correctAnswers: ['Во всех вышеперечисленных случаях'],
      },
      {
        code: '63654095',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63654096',
        text:
          'Для каких объектов требуется оформление формуляра (паспорта) подтверждения величины разрешенного (допустимого) рабочего давления?',
        answers: [
          'Для объектов, вводимых в эксплуатацию по завершении реконструкции',
          'Для объектов, вводимых в эксплуатацию по завершении строительства',
          'Для действующих объектов, на которых проведено изменение величины разрешенного (допустимого) рабочего давления',
          'Для всех перечисленных объектов',
        ],
        correctAnswers: ['Для всех перечисленных объектов'],
      },
      {
        code: '63654097',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 3 рабочие смены',
          'Лицо, зарегистрировавшее наряд-допуск не более чем на 2 рабочие смены',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63654098',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63654099',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение 1 смены',
          'В течение 1 суток',
          'В течение 2 дневных смен',
          'В течение 2 суток',
        ],
        correctAnswers: ['В течение 1 смены'],
      },
      {
        code: '63654100',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует к наряду-допуску на проведение огневых работ приложить схему места проведения огневых работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63654101',
        text:
          'Какой входной контроль должен быть организован на всех этапах выполнения работ по строительству, реконструкции, техническому перевооружению и капитальному ремонту ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Только технологических операций',
          'Только качества выполнения работ',
          'Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций',
        ],
        correctAnswers: [
          'Конструкций, изделий, материалов, оборудования и технических устройств, качества выполнения работ и всех технологических операций',
        ],
      },
      {
        code: '63654102',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания первой помощи',
          'О самочувствии',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63654103',
        text:
          'Допускается ли выброс углеводородов в окружающую среду способами, не предусмотренными проектной документацией/документацией на ликвидацию ОПО магистральных трубопроводов (МТ), при освобождении трубопроводов и оборудования перед началом осуществления работ по выводу из эксплуатации опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Не допускается',
          'Допускается только после согласования с федеральным органом исполнительной власти в области охраны окружающей среды',
          'Зависит от транспортируемого углеводорода',
          'Допускается вне акваторий и иных особо охраняемых территорий',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63654104',
        text:
          'За сколько суток до начала работ по устройству в пределах охранной зоны шурфов для проверки качества изоляции нефтепроводов и состояния средств их электрохимической защиты от коррозии необходимо уведомить землепользователя?',
        answers: [
          'Не менее чем за 3 суток',
          'Не менее чем за сутки',
          'Не менее чем за 10 суток',
          'Не менее чем за 5 суток',
        ],
        correctAnswers: ['Не менее чем за 5 суток'],
      },
      {
        code: '63654105',
        text:
          'В каком случае допускаются ремонтные работы при превышении в воздухе рабочей зоны установленных значений предельно допустимых концентраций для транспортируемого продукта?',
        answers: [
          'При использовании средств индивидуальной защиты органов дыхания',
          'Ни в каком случае',
          'При выполнении работ специализированными организациями',
          'При согласовании с Ростехнадзором',
        ],
        correctAnswers: [
          'При использовании средств индивидуальной защиты органов дыхания',
        ],
      },
      {
        code: '63654106',
        text:
          'Что из перечисленного не входит в мероприятия по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварий на опасных производственных объектах магистральных трубопроводов?',
        answers: [
          'Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий',
          'Подготовка и аттестация руководителей и специалистов в области промышленной безопасности',
          'Контроль состояния технических устройств',
          'Оснащение средствами коллективной и индивидуальной защиты',
        ],
        correctAnswers: [
          'Подготовка и аттестация руководителей и специалистов в области промышленной безопасности',
        ],
      },
      {
        code: '63654107',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63654108',
        text:
          'Какие виды патрулирования используются для контроля состояния линейных участков, сооружений и объектов ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Пеший обход',
          'Проезд автотранспортом',
          'Авиапатрулирование',
          'Все перечисленные виды патрулирования',
        ],
        correctAnswers: ['Все перечисленные виды патрулирования'],
      },
      {
        code: '63654109',
        text:
          'Что включает в себя планирование и осуществление мероприятий по предупреждению возможных аварий и обеспечению постоянной готовности к локализации и ликвидации последствий аварии на ОПО магистральных трубопроводов (МТ)?',
        answers: [
          'Разработку системы управления охраной труда',
          'Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий',
          'Аттестацию руководителей и специалистов в области промышленной безопасности',
          'Лицензирование и страхование',
        ],
        correctAnswers: [
          'Формирование необходимых финансовых средств и материальных ресурсов для локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63654110',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается в случае описания порядка оформления электронного документа в локальных нормативных актах',
          'Допускается в случае согласования порядка оформления и регистрации наряда-допуска территориальными органами Ростехнадзора',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63654111',
        text:
          'На какие магистральные трубопроводы не распространяются требования Правил безопасности для опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'На трубопроводы газового конденсата',
          'На трубопроводы широкой фракции легких углеводородов',
          'На трубопроводы сжиженных углеводородных газов',
          'На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак',
          'На магистральные аммиакопроводы',
        ],
        correctAnswers: [
          'На внутризаводские трубопроводы организаций, производящих и потребляющих жидкий аммиак',
        ],
      },
      {
        code: '63654112',
        text:
          'В какой срок должны сообщать предприятия, эксплуатирующие инженерные коммуникации, проходящие в техническом коридоре, всем заинтересованным предприятиям вносимые изменения в схему объектов с точным указанием их взаиморасположения?',
        answers: [
          'В недельный срок',
          'В месячный срок',
          'В десятидневный срок',
          'В трехдневный срок',
        ],
        correctAnswers: ['В месячный срок'],
      },
      {
        code: '63654113',
        text:
          'На основании чего составляется график ремонта (включая капитальный ремонт) опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Результатов неразрушающего контроля',
          'Результатов технического обследования',
          'Результатов экспертизы промышленной безопасности',
          'Регулярных осмотров',
        ],
        correctAnswers: ['Результатов технического обследования'],
      },
      {
        code: '63654114',
        text:
          'К чему из перечисленного не относится анализ опасностей технологических процессов, количественный анализ риска и иные методы анализа риска аварий, связанных с выбросом транспортируемых углеводородов?',
        answers: [
          'К декларированию промышленной безопасности',
          'К классификации опасных производственных объектов по степени опасности для регистрации в государственном реестре',
          'К обоснованию безопасности опасных производственных объектов магистральных трубопроводов',
          'К системе управления промышленной безопасностью опасных производственных объектов магистральных трубопроводов',
        ],
        correctAnswers: [
          'К классификации опасных производственных объектов по степени опасности для регистрации в государственном реестре',
        ],
      },
      {
        code: '63654115',
        text:
          'Проведение какого вида работ не предусматривается при техническом диагностировании линейной части опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Внутритрубной дефектоскопии путем пропуска внутритрубных средств диагностики',
          'Внешнего дефектоскопического обследования с применением методов неразрушающего контроля',
          'Гидравлических (пневматических) испытаний на прочность',
          'Оценки состояния изоляционных покрытий',
        ],
        correctAnswers: [
          'Гидравлических (пневматических) испытаний на прочность',
        ],
      },
      {
        code: '63654116',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда допуска',
          'Не менее 1 года со дня закрытия наряда допуска',
          'Не менее 6 месяцев со дня закрытия наряда допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда допуска'],
      },
      {
        code: '63654117',
        text:
          'Какой вид газообразной рабочей среды может применяться при пневматических испытаниях на прочность и проверке на герметичность магистральных трубопроводов, транспортирующих углеводороды?',
        answers: [
          'Только воздух',
          'Воздух, инертные газы, природный газ',
          'Только воздух и инертные газы',
          'Вид газообразной рабочей среды в Правилах безопасности для опасных производственных объектов магистральных трубопроводов не нормируется',
          'Водяной пар',
        ],
        correctAnswers: ['Воздух, инертные газы, природный газ'],
      },
      {
        code: '63654118',
        text:
          'Что из перечисленного допускается не включать в технологический регламент на эксплуатацию опасных производственных объектов магистральных трубопроводов?',
        answers: [
          'Порядок контроля и управления технологическим процессом',
          'Анализ риска аварий на участке',
          'Порядок обнаружения утечек',
          'Паспортные характеристики технических устройств, применяемых на опасных производственных объектах магистральных трубопроводов',
          'Порядок приема, сдачи и учета перекачиваемых продуктов',
        ],
        correctAnswers: ['Анализ риска аварий на участке'],
      },
      {
        code: '63654119',
        text:
          'Какой знак должен устанавливаться в местах пересечения магистральных нефтепроводов с автомобильными дорогами всех категорий?',
        answers: [
          'Информационный знак',
          'Дорожный знак, запрещающий остановку транспорта',
          'Предписывающий знак',
          'Разрешающий знак',
        ],
        correctAnswers: ['Дорожный знак, запрещающий остановку транспорта'],
      },
      {
        code: '63654120',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Любым из перечисленных лиц',
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Лицом, назначенным руководителем эксплуатирующей организации (филиала организации) или его уполномоченным заместителем',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
    ],
    63652: [
      {
        code: '63652000',
        text:
          'Кого должны известить производители работ при реконструкции, техническом перевооружении и капитальном ремонте промысловых трубопроводов на территории населенного пункта о начале и сроках проведения этих работ?',
        answers: [
          'Орган местного самоуправления',
          'Территориальный орган Ростехнадзора',
          'Территориальный орган Ростехнадзора и орган местного самоуправления',
          'Территориальный орган Ростехнадзора, территориальный орган МЧС и орган местного самоуправления',
        ],
        correctAnswers: ['Орган местного самоуправления'],
      },
      {
        code: '63652001',
        text:
          'Какие действия требуется выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей при наличии в них перемешивающих устройств с электроприводом?',
        answers: [
          'Только вывесить плакаты на емкостях "Газ", "Газоопасные работы"',
          'Только вывесить плакаты у емкостей в электрораспределительных устройствах "Не включать: работают люди"',
          'Только отключить электропривод от источников питания видимым разрывом',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63652002',
        text:
          'Какие противогазы или аппараты не допускается использовать для защиты органов дыхания работников внутри емкостей при проведении газоопасных работ?',
        answers: [
          'Шланговые противогазы',
          'Кислородно-изолирующие противогазы',
          'Воздушные изолирующие аппараты',
          'Фильтрующие противогазы',
        ],
        correctAnswers: ['Фильтрующие противогазы'],
      },
      {
        code: '63652003',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63652004',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63652005',
        text:
          'При каких условиях допускается работа внутри емкостей без средств защиты органов дыхания?',
        answers: [
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 25% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) превышает предельно допустимые концентрации в воздухе рабочей зоны не более чем на 10%, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 30% объемной доли (внутри емкостей (аппаратов)',
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
        correctAnswers: [
          'При условии, что концентрация опасных веществ (паров, газов) не превышает предельно допустимых концентраций в воздухе рабочей зоны, а содержание кислорода не менее 20% объемной доли (внутри емкостей (аппаратов)',
        ],
      },
      {
        code: '63652006',
        text:
          'Какие данные не должен в обязательном порядке содержать план мероприятий по выводу из эксплуатации объектов ПТ?',
        answers: [
          'Периодический контроль состояния ПТ на период вывода из эксплуатации',
          'Краткое изложение причины вывода объектов ПТ из эксплуатации',
          'Сроки завершения работ',
          'Регистрационный номер ОПО в государственном реестре ОПО',
        ],
        correctAnswers: [
          'Краткое изложение причины вывода объектов ПТ из эксплуатации',
        ],
      },
      {
        code: '63652007',
        text:
          'Каким документом оформляются результаты ревизии промысловых трубопроводов?',
        answers: [
          'Составляется акт ревизии',
          'Совместным протоколом эксплуатирующей организации и подрядной организации, проводившей ревизию промысловых трубопроводов',
          'Техническим отчетом подрядной организации, проводившей ревизию промысловых тркбопроводов',
        ],
        correctAnswers: ['Составляется акт ревизии'],
      },
      {
        code: '63652008',
        text: 'Кто разрабатывает перечень газоопасных работ?',
        answers: [
          'Каждое структурное подразделение эксплуатирующей организации',
          'Служба производственного контроля эксплуатирующей организации',
          'Газоспасательная служба',
          'Подразделения, которые обязаны готовить объекты к газоопасным работам',
        ],
        correctAnswers: [
          'Каждое структурное подразделение эксплуатирующей организации',
        ],
      },
      {
        code: '63652009',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания первой медицинской помощи',
          'О знании действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63652010',
        text:
          'Что из перечисленного необходимо выполнить при отсутствии зрительной связи между работающим и наблюдающим при проведении газоопасных работ внутри емкостей?',
        answers: [
          'Установить систему подачи условных сигналов',
          'Только предусмотреть дополнительное освещение внутри емкости',
          'Работающему снять маску фильтрующего противогаза и продолжить работу',
          'Увеличить число наблюдающих и работающих',
        ],
        correctAnswers: ['Установить систему подачи условных сигналов'],
      },
      {
        code: '63652011',
        text:
          'Какие работы, кроме работ, выполняемых при техническом осмотре, должны быть выполнены при проверке эффективности работы установок электрохимической защиты промысловых трубопроводов?',
        answers: [
          'Измерение потенциалов на защищаемом промысловом трубопроводе в опорных точках (на границах зоны защиты) и в точках, расположенных по трассе промысловых трубопроводов, установленных в проектной и технологической документации',
          'Измерение напряжения, величины тока на выходе преобразователя, потенциала на защищаемом промысловом трубопроводе в точке подключения при включенной и отключенной установке электрохимической защиты',
          'Измерение потенциала протектора относительно земли при отключенном протекторе, а также измерение величины тока в цепи "протектор - защищаемое сооружение"',
        ],
        correctAnswers: [
          'Измерение потенциалов на защищаемом промысловом трубопроводе в опорных точках (на границах зоны защиты) и в точках, расположенных по трассе промысловых трубопроводов, установленных в проектной и технологической документации',
        ],
      },
      {
        code: '63652012',
        text:
          'С какой периодичностью эксплуатирующая организация обязана формировать графики выполнения работ по ревизии ПТ?',
        answers: ['Ежегодно', 'Ежеквартально', 'Ежемесячно', 'Раз в 6 месяцев'],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63652013',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Ответственный за проведение работ, на требуемый для окончания работ срок',
          'Лицо, зарегистрировавшее наряд-допуск, на требуемый для окончания работ срок',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения, или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63652014',
        text: 'Что указывается на схеме места выполнения огневых работ?',
        answers: [
          'Только месторасположение автомобильной техники',
          'Только места размещения сварочного и другого оборудования для проведения огневых работ, места установки предупредительных знаков',
          'Только места расположения запорной арматуры и установки заглушек на технологическом оборудовании и трубопроводах',
          'Только место выполнения огневых работ и границы опасной зоны, места отбора проб воздуха',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63652015',
        text:
          'Что должны включать в себя мероприятия, обеспечивающие безопасность выполнения работ внутри аппаратов без средств индивидуальной защиты органов дыхания?',
        answers: [
          'Только наличие у места проведения работ средств сигнализации и связи (световой, звуковой, радиотелефонной)',
          'Только непрерывный контроль состояния воздушной среды',
          'Только наличие у каждого работающего в емкости (аппарате) спасательного пояса или страховочной привязи с закрепленной сигнально-спасательной веревкой',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63652016',
        text:
          'Что необходимо выполнить перед началом проведения газоопасных, огневых и ремонтных работ внутри емкостей (аппаратов) для оценки качества выполнения подготовительных мероприятий?',
        answers: [
          'Произвести продувку инертным газом и воздухом с записью результатов в наряде-допуске',
          'Вывесить предупреждающие плакаты "Газ", "Газоопасные работы"',
          'Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Произвести анализ воздушной среды на содержание опасных веществ (паров, газов)',
        ],
      },
      {
        code: '63652017',
        text:
          'На сколько групп подразделяются газоопасные работы в зависимости от степени опасности и на основании каких критериев устанавливается та или иная группа?',
        answers: [
          'На 2: газоопасные работы, проводимые с оформлением наряда-допуска или без оформления',
          'На 3 в зависимости от степени риска проводимых работ',
          'На 2 в зависимости от того, проводятся работы в закрытом или открытом пространстве',
        ],
        correctAnswers: [
          'На 2: газоопасные работы, проводимые с оформлением наряда-допуска или без оформления',
        ],
      },
      {
        code: '63652018',
        text:
          'В каких местах трасса ПТ должна быть обозначена на местности постоянными опознавательно-предупредительными знаками?',
        answers: [
          'В местах переходов через естественные и искусственные преграды',
          'В местах узлов запорной арматуры',
          'На опасных участках',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63652019',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63652020',
        text:
          'Куда должны быть занесены результаты контроля электрохимической защиты?',
        answers: [
          'В журнал контроля ЭХЗ с указанием минимально допустимого потенциала',
          'В паспорт установки ЭХЗ с указанием минимально допустимого потенциала',
          'В журнал осмотра ПТ и оборудования с указанием даты следующего контроля',
          'В вахтенный журнал по эксплуатации ПТ с указанием максимально допустимого потенциала',
        ],
        correctAnswers: [
          'В журнал контроля ЭХЗ с указанием минимально допустимого потенциала',
        ],
      },
      {
        code: '63652021',
        text:
          'Каким образом объект, ремонт которого закончен, принимается в эксплуатацию?',
        answers: [
          'По акту сдачи-приемки в эксплуатацию',
          'Совместным приказом руководителей эксплуатирующей и подрядной организаций',
          'После закрытия наряда-допуска',
          'На основании положительного заключения экспертизы промышленной безопасности',
        ],
        correctAnswers: ['По акту сдачи-приемки в эксплуатацию'],
      },
      {
        code: '63652022',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, список которых определяется внутренними документами организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63652023',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63652024',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63652025',
        text:
          'Какие сведения не приводятся на щите-указателе при обозначении трассы промыслового трубопровода на местности?',
        answers: [
          'Охранная зона ПТ',
          'Технологические показатели перекачиваемого агента (давление и температура)',
          'Привязка знака на трассе (километр или пикет трассы)',
          'Назначение, наименование ПТ',
        ],
        correctAnswers: [
          'Технологические показатели перекачиваемого агента (давление и температура)',
        ],
      },
      {
        code: '63652026',
        text:
          'В каких местах допускается газоопасная работа без изолирующих средств защиты органов дыхания?',
        answers: [
          'В тоннелях',
          'В колодцах',
          'В коллекторах',
          'Не допускается во всех перечисленных местах',
        ],
        correctAnswers: ['Не допускается во всех перечисленных местах'],
      },
      {
        code: '63652027',
        text:
          'Требования какого документа обеспечивают безопасность технологических процессов на объектах добычи, сбора и подготовки нефти, газа и газового конденсата?',
        answers: [
          'Федеральных норм и правил в области промышленной безопасности',
          'Руководства по эксплуатации оборудования',
          'Проектной документации на эксплуатацию опасного производственного объекта',
          'Технологического регламента на каждый технологический процесс опасного производственного объекта',
        ],
        correctAnswers: [
          'Технологического регламента на каждый технологический процесс опасного производственного объекта',
        ],
      },
      {
        code: '63652028',
        text:
          'С какой периодичностью необходимо пересматривать план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'План мероприятий по локализации и ликвидации последствий аварий пересмотру не подлежит',
          'Каждый раз, когда меняется технология и условия работы',
          '1 раз в 5 лет',
          'В сроки, установленные Правительством Российской Федерации',
        ],
        correctAnswers: [
          'В сроки, установленные Правительством Российской Федерации',
        ],
      },
      {
        code: '63652029',
        text:
          'Какой из перечисленных документов подлежит ежегодному пересмотру?',
        answers: [
          'Графики технического обслуживания, диагностирования и ремонта промыслового трубопровода',
          'План мероприятий по локализации и ликвидации последствий аварий',
          'Журнал осмотров или вахтенный журнал',
          'Технологические схемы',
        ],
        correctAnswers: [
          'Графики технического обслуживания, диагностирования и ремонта промыслового трубопровода',
        ],
      },
      {
        code: '63652030',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф)',
          'В ПАСС(Ф) и аварийно-спасательную службу организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
        ],
      },
      {
        code: '63652031',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты или руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Главный инженер организации и работник службы охраны труда',
          'Главный механик и работник службы охраны труда',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты или руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63652032',
        text:
          'Для каких целей во взрывоопасных технологических процессах должны быть предусмотрены системы противоаварийной защиты, противопожарной защиты и газовой безопасности?',
        answers: [
          'Для срабатывания предупредительной сигнализации о возникновении аварийных ситуаций при отклонении от предусмотренных регламентом предельно допустимых параметров во всех режимах работы',
          'Для обеспечения безопасной остановки или перевода процесса в безопасное состояние в случае критического отклонения от предусмотренных технологическим регламентом параметров',
          'Для ликвидации аварийных ситуаций в автоматическом режиме',
          'Для автоматического форсирования технологического процесса',
        ],
        correctAnswers: [
          'Для обеспечения безопасной остановки или перевода процесса в безопасное состояние в случае критического отклонения от предусмотренных технологическим регламентом параметров',
        ],
      },
      {
        code: '63652033',
        text:
          'Кому обязаны немедленно сообщить работники, выполняющие осмотр или обслуживание инженерных коммуникаций и объектов, находящихся в районе прохождения ПТ, о повреждении ПТ или выходе (утечке) транспортируемой среды? Выберите 2 правильных варианта ответа.',
        answers: [
          'Диспетчерской службе организации, эксплуатирующей данный участок ПТ',
          'Аварийной службе организации, эксплуатирующей данный участок ПТ',
          'Соответствующим органам власти и управления',
          'Лицу, ответственному за безопасную эксплуатацию ПТ',
        ],
        correctAnswers: [
          'Диспетчерской службе организации, эксплуатирующей данный участок ПТ',
          'Аварийной службе организации, эксплуатирующей данный участок ПТ',
        ],
      },
      {
        code: '63652034',
        text:
          'Какой из перечисленных документов не оформляется при выводе из эксплуатации объектов промысловых трубопроводов?',
        answers: [
          'Приказ руководителя эксплуатирующей организации',
          'План мероприятий по выводу из эксплуатации объектов ПТ',
          'Уведомление о выводе из эксплуатации объектов ПТ, направляемое в территориальный орган Ростехнадзора',
          'Все приведенные документы оформляются при выводе из эксплуатации объектов ПТ',
          'Акт выполнения плана мероприятий по выводу из эксплуатации объектов ПТ',
        ],
        correctAnswers: [
          'Уведомление о выводе из эксплуатации объектов ПТ, направляемое в территориальный орган Ростехнадзора',
        ],
      },
      {
        code: '63652035',
        text:
          'Каким из перечисленных значений пробного давления допускается проведение испытаний промысловых трубопроводов на прочность и герметичность газообразными средами?',
        answers: [
          'Не превышающим 11 МПа',
          'Не превышающим 12 МПа',
          'Не превышающим 10 МПа',
          'Проведение испытаний промыслового трубопровода на прочность и герметичность газообразными средами не допускается',
        ],
        correctAnswers: ['Не превышающим 11 МПа'],
      },
      {
        code: '63652036',
        text:
          'Какие действия должны быть предприняты в случае повреждения ПТ или обнаружения утечки продукции в процессе выполнения работ сторонней организацией? Выберите 2 правильных варианта ответа.',
        answers: [
          'Работники и технические средства должны быть немедленно выведены за пределы опасной зоны',
          'Немедленно должны быть предприняты возможные действия для устранения повреждений и утечек',
          'Территориальный орган Ростехнадзора должен быть извещен о происшествии',
          'Организация, эксплуатирующая ПТ, должна быть извещена о происшествии',
        ],
        correctAnswers: [
          'Работники и технические средства должны быть немедленно выведены за пределы опасной зоны',
          'Организация, эксплуатирующая ПТ, должна быть извещена о происшествии',
        ],
      },
      {
        code: '63652037',
        text:
          'Что из перечисленного должно быть учтено при разработке документов (стандартов, инструкций), уточняющих и конкретизирующих требования к ведению газоопасных, огневых и ремонтных работ на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Результаты согласования документов (стандартов, инструкций) с газоспасательной службой, службой производственного контроля и территориальными органами Ростехнадзора',
          'Взрывопожароопасные показатели обращающихся в технологическом процессе опасных веществ',
          'Особенности и условия проведения газоопасных работ во время грозы',
          'Физико-химические свойства обращающихся в технологическом процессе опасных веществ',
        ],
        correctAnswers: [
          'Взрывопожароопасные показатели обращающихся в технологическом процессе опасных веществ',
          'Физико-химические свойства обращающихся в технологическом процессе опасных веществ',
        ],
      },
      {
        code: '63652038',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63652039',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и подобных им сооружениях?',
        answers: [
          'C руководителями аварийно-спасательных служб',
          'C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
          'C руководителями службы охраны труда и санитарными службами',
          'C руководителями службы производственного контроля',
        ],
        correctAnswers: [
          'C руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63652040',
        text:
          'Допускается ли проведение огневых работ на действующих взрывопожароопасных производственных объектах?',
        answers: [
          'Не допускается',
          'Допускается при соблюдении дополнительных требований безопасности',
          'Допускается при положительном заключении противопожарной службы',
          'Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах',
        ],
        correctAnswers: [
          'Допускается в исключительных случаях, когда отсутствует возможность их проведения в специально отведенных для этой цели постоянных местах',
        ],
      },
      {
        code: '63652041',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'Во время грозы',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63652042',
        text:
          'В каких случаях огневые работы могут проводиться в ночное время суток?',
        answers: [
          'В случаях ликвидации или локализации возможных аварий',
          'В случаях когда наряд-допуск подписан руководителем эксплуатирующей организации, на объекте которой будут проводиться огневые работы',
          'В случаях когда наряд-допуск подписан руководителем структурного подразделения, на объекте которого будут проводиться огневые работы',
          'Огневые работы должны проводиться только в дневное время суток',
        ],
        correctAnswers: [
          'В случаях ликвидации или локализации возможных аварий',
        ],
      },
      {
        code: '63652043',
        text:
          'Какие меры безопасности должны быть выполнены перед началом продувкии испытанием трубопровода газом или воздухом?',
        answers: [
          'Необходимо обозначить знаками опасные зоны, в которых запрещено находиться людям',
          'Необходимо определить опасные зоны и выставить наблюдающих',
          'Необходимо установить видеокамеры в опасных зонах',
          'Необходимо подать длинный звуковой сигнал для удаления персонала в безопасную зону',
          'Необходимо установить заградительные сооружения вдоль трассы со световой сигнализацией',
        ],
        correctAnswers: [
          'Необходимо обозначить знаками опасные зоны, в которых запрещено находиться людям',
        ],
      },
      {
        code: '63652044',
        text:
          'В течение какого срока должны храниться в эксплуатирующей организации технические отчеты (заключения) по результатам диагностирования ПТ?',
        answers: [
          'В течение всего срока эксплуатации ПТ',
          'В течение трех лет',
          'В течение года',
          'В течение срока, утвержденного техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: ['В течение всего срока эксплуатации ПТ'],
      },
      {
        code: '63652045',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63652046',
        text:
          'Что необходимо устанавливать вдоль трассы подземного технологического трубопровода сжиженного газа?',
        answers: [
          'Опознавательные знаки через 50 м на прямых участках трубопровода и на каждом его повороте',
          'Ограждения',
          'Охраняемые посты в зоне видимости',
        ],
        correctAnswers: [
          'Опознавательные знаки через 50 м на прямых участках трубопровода и на каждом его повороте',
        ],
      },
      {
        code: '63652047',
        text:
          'Какое из перечисленных требований федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности" указано неверно?',
        answers: [
          'По завершении капитального ремонта, испытания на прочность и проверки на герметичность ПТ должно быть осуществлено комплексное опробование',
          'Заполнение ПТ транспортируемой средой и его работа после заполнения в течение 72 часов считаются комплексным опробованием ПТ',
          'Заполнение и комплексное опробование должно проводиться в соответствии с планом мероприятий, установленным проектной документацией',
          'Все требования указаны верно',
        ],
        correctAnswers: ['Все требования указаны верно'],
      },
      {
        code: '63652048',
        text:
          'На каком основании осуществляется ввод в эксплуатацию объектов ПТ после консервации или вывода из эксплуатации объектов ПТ?',
        answers: [
          'На основании приказа руководителя эксплуатирующей организации',
          'На основании приказа технического руководителя эксплуатирующей организации',
          'На основании приказа лица, ответственного за проведение периодического контроля состояния ПТ на период вывода из эксплуатации',
          'На основании приказа инспектора территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'На основании приказа руководителя эксплуатирующей организации',
        ],
      },
      {
        code: '63652049',
        text:
          'Когда следует проводить нивелировку и измерения деформации свайных опор надземных участков промысловых трубопроводов при их эксплуатации?',
        answers: [
          'До достижения условной стабилизации деформаций, установленной проектной документацией (документацией)',
          'В сроки, установленные проектной документацией (документацией), но не реже одного раза в два года',
          'Только во время ремонта',
        ],
        correctAnswers: [
          'До достижения условной стабилизации деформаций, установленной проектной документацией (документацией)',
        ],
      },
      {
        code: '63652050',
        text:
          'Что из перечисленного включает в себя техническое обслуживание промысловых трубопроводов?',
        answers: [
          'Только обследование переходов через естественные и искусственные преграды',
          'Только наблюдение за состоянием трассы промысловых трубопроводов, элементов трубопроводов и их деталей, находящихся на поверхности земли',
          'Только обслуживание технических устройств и средств электрохимической защиты промысловых трубопроводов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63652051',
        text:
          'С учетом каких характеристик должны приниматься решения о сроках, способах, объемах проведения работ по реконструкции, техническому перевооружению и капитальному ремонту ПТ? Выберите два правильных варианта ответа.',
        answers: [
          'С учетом предписаний территориального органа Ростехнадзора',
          'С учетом согласования с привлекаемой организацией, имеющей право (аттестованной) на проведение указанных работ',
          'С учетом анализа результатов ревизии ПТ',
          'С учетом срока службы ПТ',
        ],
        correctAnswers: [
          'С учетом анализа результатов ревизии ПТ',
          'С учетом срока службы ПТ',
        ],
      },
      {
        code: '63652052',
        text:
          'На кого возлагается ответственность за организацию и проведение работ по очистке промыслового трубопровода?',
        answers: [
          'На эксплуатирующую организацию',
          'Ответственность определяется договором между эксплуатирующей организацией и подрядной организацией, проводящей очистку ПТ',
          'На специализированную подрядную организацию, проводящую очистку ПТ',
        ],
        correctAnswers: ['На эксплуатирующую организацию'],
      },
      {
        code: '63652053',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '2 года', '1 год', '3 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63652054',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение всего времени проведения работ на конкретном месте',
          'До окончания работ на конкретном месте',
          'В течение одной смены',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63652055',
        text:
          'Что из перечисленного не входит в общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Характеристика объекта, в отношении которого разрабатывается план мероприятий',
        ],
        correctAnswers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63652056',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63652057',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '1 год', '3 года', '2 года'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63652058',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63652059',
        text:
          'Допускается ли на территории охранной зоны нефтегазопроводов устройство колодцев, котлованов и других заглублений?',
        answers: [
          'Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации',
          'Допускается без ограничений',
          'Допускается, если они предусмотрены проектной организацией',
          'Допускается, если их обустройство согласовано с территориальным органом Ростехнадзора',
        ],
        correctAnswers: [
          'Не допускается, кроме углублений, выполняемых при ремонте или реконструкции по плану производства работ эксплуатирующей организации',
        ],
      },
      {
        code: '63652060',
        text:
          'Какие сведения должны быть приведены на щите-указателе при обозначении трассы промыслового трубопровода на местности?',
        answers: [
          'Местоположение оси промыслового трубопровода от основания знака',
          'Диаметр и толщина стенки промыслового трубопровода',
          'Протяженность трассы промыслового трубопровода',
          'Дата последней ревизии промыслового трубопровода',
        ],
        correctAnswers: [
          'Местоположение оси промыслового трубопровода от основания знака',
        ],
      },
      {
        code: '63652061',
        text:
          'Какие виды работ относятся к подготовительным для проведения газоопасных работ?',
        answers: [
          'Только работы, связанные с подготовкой коммуникаций',
          'Только работы, связанные с подготовкой технологического оборудования и трубопроводов',
          'Только работы, связанные с подготовкой емкостей',
          'Все перечисленные виды работ',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63652062',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для ОПО I и II классов опасности',
          'Для всех ОПО без исключения',
        ],
        correctAnswers: [
          'Для ОПО, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63652063',
        text:
          'Как осуществляется ввод в эксплуатацию объектов промысловых трубопроводов по прежнему назначению, остановленных на срок свыше 3 месяцев?',
        answers: [
          'После пробной эксплуатации ПТ не менее 72 часов',
          'После пробной эксплуатации ПТ не менее 24 часов',
          'После проведения работ по ревизии',
          'После проведения экспертизы промышленной безопасности',
        ],
        correctAnswers: ['После пробной эксплуатации ПТ не менее 72 часов'],
      },
      {
        code: '63652064',
        text:
          'Кем составляется акт, в котором делается вывод о техническом состоянии перехода ПТ через железные и автомобильные дороги, на основании данных, полученных при обследовании переходов ПТ?',
        answers: [
          'Эксплуатирующей организацией',
          'Сторонней ремонтной организацией',
          'Проектной организацией',
          'Комиссией из представителей эксплуатирующей организации и проектной организации',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63652065',
        text:
          'С какой периодичностью проводится обследование переходов через водные преграды?',
        answers: [
          'Сроки устанавливаются документацией эксплуатирующей организации в зависимости от скорости коррозионно-эрозионных процессов с учетом опыта эксплуатации аналогичных промысловых трубопроводов',
          'Ежегодно',
          'Ежеквартально',
          'Не реже одного раза в четыре года',
        ],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63652066',
        text:
          'На какой из перечисленных промысловых трубопроводов распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"?',
        answers: [
          'Технологический внутриплощадочный деэмульгаторопровод от блоков подачи химреагентов',
          'Трубопровод для транспортирования продуктов с температурой выше 100 °С',
          'Деэмульгаторопровод для подачи деэмульгатора к объектам дожимных насосных станций',
        ],
        correctAnswers: [
          'Деэмульгаторопровод для подачи деэмульгатора к объектам дожимных насосных станций',
        ],
      },
      {
        code: '63652067',
        text:
          'Что из перечисленного допускается при замерзании влаги в технологическом трубопроводе?',
        answers: [
          'Пользоваться крюками, ломами и трубами для открытия замерзших задвижек, вентилей и других запорных приспособлений',
          'Отогревание замерзших спусков (дренажей) трубопроводов, аппаратов при открытой задвижке, а также открытым огнем',
          'Отогревание замерзших спусков (дренажей) трубопроводов, аппаратов открытым огнем',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63652068',
        text:
          'Какова функция запорных устройств на выкидных трубопроводах, непосредственно соединенных со скважинами?',
        answers: [
          'Безопасная очистка трубопровода от гидратных пробок',
          'Отбор проб для анализа среды',
          'Перекрытие потока жидкости из скважины при аварийной разгерметизации нефтегазоконденсатопровода',
          'Ввод ингибитора и химических реагентов в поток среды из скважины',
        ],
        correctAnswers: [
          'Перекрытие потока жидкости из скважины при аварийной разгерметизации нефтегазоконденсатопровода',
        ],
      },
      {
        code: '63652069',
        text:
          'Где и кем должны фиксироваться результаты осмотра промысловых трубопроводов?',
        answers: [
          'В журнале осмотра лицом, осуществившим осмотр',
          'В паспорте промысловых трубопроводов лицом, осуществившим осмотр',
          'В журнале осмотра, а также в паспорте промысловых трубопроводов лицом, осуществившим осмотр',
          'В журнале осмотра лицом, осуществившим осмотр, а в паспорте промысловых трубопроводов - ответственным должностным лицом',
        ],
        correctAnswers: ['В журнале осмотра лицом, осуществившим осмотр'],
      },
      {
        code: '63652070',
        text: 'Кем проводится ревизия промысловых трубопроводов?',
        answers: [
          'Работниками эксплуатирующей или подрядной организации с привлечением аттестованной лаборатории неразрушающего контроля',
          'Работниками организации, имеющей лицензию на право проведения экспертизы промышленной безопасности',
          'Работниками специализированной организации, располагающей аттестованной лабораторией неразрушающего контроля',
        ],
        correctAnswers: [
          'Работниками эксплуатирующей или подрядной организации с привлечением аттестованной лаборатории неразрушающего контроля',
        ],
      },
      {
        code: '63652071',
        text:
          'Какие из перечисленных мероприятий при выполнении подготовительных работ к проведению огневых работ указаны неверно?',
        answers: [
          'Место выполнения огневых работ должно быть обеспечено огнетушителем и другими первичными средствами пожаротушения, указанными в наряде-допуске на выполнение огневых работ',
          'При выполнении огневых работ в помещении следует предусмотреть меры защиты от разлета и попадания искр в проемы межэтажных перекрытий, а также лотков и приямков',
          'В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ',
          'Сливные воронки, выходы из лотков и другие устройства, связанные с канализацией, в которых могут быть горючие газы и пары, должны быть герметизированы',
          'Все перечисленные',
        ],
        correctAnswers: [
          'В помещении в зоне выполнения огневых работ следует обеспечить автоматическое включение системы вентиляции при превышении уровня предельно допустимой концентрации вредных веществ',
        ],
      },
      {
        code: '63652072',
        text:
          'Каким документом определяется порядок организации и производства работ на одном объекте несколькими подразделениями одной организации, эксплуатирующей опасный производственный объект?',
        answers: [
          'Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом',
          'Положением о производственном контроле организации',
          'Производственным заданием, выданным руководителем организации или лицом, его заменяющим',
          'Нарядом-допуском, оформленным техническим директором (ответственным лицом) организации',
          'Графиком взаимодействия, согласованным с вышестоящей организацией',
        ],
        correctAnswers: [
          'Регламентом об организации безопасного производства работ, утвержденным руководителем этой организации или уполномоченным им лицом',
        ],
      },
      {
        code: '63652073',
        text:
          'Кто утверждает планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите 2 правильных варианта ответа.',
        answers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Инспектор Ростехнадзора',
          'Руководители профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
        ],
        correctAnswers: [
          'Руководитель (заместители руководителей) организаций, эксплуатирующих объекты',
          'Руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63652074',
        text:
          'Какую информацию включает в себя общий раздел плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Только характеристику объекта, в отношении которого разрабатывается план мероприятий',
          'Только сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Только характеристики аварийности, присущие объектам, в отношении которых разрабатывается план мероприятий, и травматизма на таких объектах',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63652075',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К II группе',
          'К I группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63652076',
        text:
          'Кому должна быть передана информация о возможном развитии опасных факторов при обнаружении повреждения ПТ или утечки продукции, угрожающих объектам, зданиям и сооружениям, эксплуатируемым иными организациями, и окружающей среде? Выберите 2 правильных варианта ответа.',
        answers: [
          'Соответствующим органам власти и управлениям',
          'Организациям-владельцам указанных объектов',
          'Аварийно-спасательным формированиям',
          'Проектной организации',
        ],
        correctAnswers: [
          'Соответствующим органам власти и управлениям',
          'Организациям-владельцам указанных объектов',
        ],
      },
      {
        code: '63652077',
        text:
          'Что запрещается выполнять обходчикам при профилактических осмотрах нефтегазопроводов?',
        answers: [
          'Спускаться в колодцы и другие углубления на территории охранной зоны',
          'Подходить к станции катодной защиты на расстояние ближе 10 м',
          'Передвигаться в одиночку по территории',
          'Выходить из операторской без средств индивидуальной защиты органов дыхания',
        ],
        correctAnswers: [
          'Спускаться в колодцы и другие углубления на территории охранной зоны',
        ],
      },
      {
        code: '63652078',
        text: 'Где регистрируются газоопасные работы II группы?',
        answers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
          'В журнале учета выдачи нарядов-допусков на выполнение работ повышенной опасности',
          'В журнале регистрации нарядов-допусков на проведение газоопасных работ с присвоением очередного номера',
          'В журнале регистрации целевого инструктажа',
        ],
        correctAnswers: [
          'В журнале учета газоопасных работ, проводимых без наряда-допуска на проведение газоопасных работ',
        ],
      },
      {
        code: '63652079',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63652080',
        text: 'Кто утверждает наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель эксплуатирующей организации',
          'Руководитель структурного подразделения',
          'Руководитель газоспасательной службы',
          'Руководитель службы производственного контроля',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63652081',
        text:
          'Кем утверждается инструкция (мероприятия), в соответствии с которой проводятся испытания ПТ на прочность и проверка на герметичность ПТ?',
        answers: [
          'Техническим руководителем эксплуатирующей организации',
          'Руководителем ремонтной бригады',
          'Комиссией эксплуатирующей организации',
          'Руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Техническим руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63652082',
        text:
          'Что должно быть предусмотрено в оперативной части плана мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Способы оповещения об аварии (например, сирена, световая сигнализация, громкоговорящая связь, телефон), пути выхода людей из опасных мест и участков в зависимости от характера аварии, действия работников, ответственных за эвакуацию людей и проведение предусмотренных мероприятий, режимы работы вентиляции при возникновении аварии, необходимость и последовательность выключения электроэнергии, ограничение допуска персонала в аварийную зону',
          'Места нахождения средств для спасения людей и ликвидации аварий',
          'Действия газоспасателей, пожарных и других подразделений',
          'Все виды возможных аварий на данном объекте',
          'Мероприятия по спасению людей и ликвидации аварии',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Способы оповещения об аварии (например, сирена, световая сигнализация, громкоговорящая связь, телефон), пути выхода людей из опасных мест и участков в зависимости от характера аварии, действия работников, ответственных за эвакуацию людей и проведение предусмотренных мероприятий, режимы работы вентиляции при возникновении аварии, необходимость и последовательность выключения электроэнергии, ограничение допуска персонала в аварийную зону',
        ],
      },
      {
        code: '63652083',
        text:
          'Какие требования установлены к люкам колодцев канализации, расположенным в зоне проведения огневых работ?',
        answers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
          'Крышки колодцев должны быть плотно закрыты, огорожены и отмечены хорошо видимыми опознавательными знаками',
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 2 см в стальном или железобетонном кольце',
        ],
        correctAnswers: [
          'Крышки колодцев должны быть плотно закрыты и засыпаны слоем песка не менее 10 см в стальном или железобетонном кольце',
        ],
      },
      {
        code: '63652084',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений размещение инженерных сетей транзитных внутриплощадочных трубопроводов с токсичными жидкостями по стенам и кровлям зданий?',
        answers: [
          'Допускается при условии прокладки их по глухой стене не ниже II степени огнестойкости',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63652085',
        text:
          'На какой стадии должен быть разработан технологический регламент на эксплуатацию ПТ?',
        answers: [
          'Только на стадии проектирования',
          'Только на стадии строительства, реконструкции',
          'Только на стадии эксплуатации',
          'На любой стадии из перечисленных',
        ],
        correctAnswers: ['На любой стадии из перечисленных'],
      },
      {
        code: '63652086',
        text:
          'На какие трубопроводы распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"? Выберите два правильных варианта ответа.',
        answers: [
          'На нефтепроводы для транспортирования товарной нефти от центрального пункта сбора до сооружения магистрального транспорта',
          'На трубопроводы для магистрального транспорта',
          'На газопроводы для транспортирования газа к эксплуатационным скважинам при газлифтном способе добычи',
          'На технологические внутриплощадочные трубопроводы, в том числе ингибиторопроводы, метанолопроводы, деэмульгаторопроводы от блоков подачи химреагентов',
        ],
        correctAnswers: [
          'На нефтепроводы для транспортирования товарной нефти от центрального пункта сбора до сооружения магистрального транспорта',
          'На газопроводы для транспортирования газа к эксплуатационным скважинам при газлифтном способе добычи',
        ],
      },
      {
        code: '63652087',
        text:
          'Какой из перечисленных вариантов обозначения трассы промыслового трубопровода на местности отвечает требованиям федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"?',
        answers: [
          'Трасса промыслового трубопровода на местности должна обозначаться щитовыми указателями, устанавливаемыми на высоте 2 м от поверхности земли в пределах прямой видимости через 1000 м, а также на углах поворота и пересечениях с другими промысловыми трубопроводами и коммуникациями. Щит-указатель устанавливается в 1 м от оси подземного промыслового трубопровода',
          'Трасса промыслового трубопровода на местности обозначается щитовыми указателями, устанавливаемыми на высоте 2,5 м от поверхности земли в пределах прямой видимости через каждые 700 м, а также на углах поворота и пересечениях с другими промысловыми трубопроводами и коммуникациями. Щит-указатель устанавливается на оси подземного промыслового трубопровода',
          'Трасса промыслового трубопровода на местности обозначается щитовыми указателями, устанавливаемыми на высоте 1,75 м от поверхности земли в пределах прямой видимости через 1200 м, а также на углах поворота и пересечениях с другими промысловыми трубопроводами и коммуникациями. Щит-указатель устанавливается в 1 м от оси подземного промыслового трубопровода',
        ],
        correctAnswers: [
          'Трасса промыслового трубопровода на местности должна обозначаться щитовыми указателями, устанавливаемыми на высоте 2 м от поверхности земли в пределах прямой видимости через 1000 м, а также на углах поворота и пересечениях с другими промысловыми трубопроводами и коммуникациями. Щит-указатель устанавливается в 1 м от оси подземного промыслового трубопровода',
        ],
      },
      {
        code: '63652088',
        text:
          'Какой документацией определяется способ доступа участков ПТ для выполнения профилактических, ремонтных и аварийных работ?',
        answers: [
          'Проектной документацией',
          'Технологическим регламентом',
          'Планом мероприятий по локализации и ликвидации аварий',
          'Любой перечисленной документацией',
        ],
        correctAnswers: ['Любой перечисленной документацией'],
      },
      {
        code: '63652089',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63652090',
        text:
          'Какое из перечисленных требований по проведению очистки промыслового трубопровода является неверным?',
        answers: [
          'Используемые очистные устройства должны иметь комплект разрешительной и эксплуатационной документации',
          'Работы по очистке ПТ должны выполняться в соответствии с мероприятиями, разработанными подрядной организацией, проводящей очистку ПТ, согласованными с эксплуатирующей организацией',
          'Переключение технологических линий при запуске, пропуске и приеме очистных устройств выполняется работниками только по указанию руководителя работ',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Работы по очистке ПТ должны выполняться в соответствии с мероприятиями, разработанными подрядной организацией, проводящей очистку ПТ, согласованными с эксплуатирующей организацией',
        ],
      },
      {
        code: '63652091',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений пересечение трубопроводов с токсичными жидкостями и газами с железнодорожными подъездными путями?',
        answers: [
          'Допускается при условии прокладки их по глухой стене не ниже II степени огнестойкости',
          'Допускается',
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
          'Не допускается',
        ],
        correctAnswers: [
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
        ],
      },
      {
        code: '63652092',
        text:
          'Какие из перечисленных методов ремонта в соответствии с федеральными нормами и правилами в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности" не относятся к методам текущего ремонта промысловых трубопроводов?',
        answers: [
          'Вырезка дефекта - замена плети',
          'Шлифовка и заварка',
          'Наложение хомутов',
          'Все перечисленные методы относятся к методам текущего ремонта',
        ],
        correctAnswers: ['Наложение хомутов'],
      },
      {
        code: '63652093',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['5 лет', '2 года', '3 года', '1 год'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63652094',
        text:
          'Какими светильниками должны быть обеспечены опасные производственные объекты нефтегазодобывающих производств?',
        answers: [
          'Переносными светильниками напряжением 24 В во взрывозащищенном исполнении',
          'Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В в особо опасных помещениях, а в наружных установках - не выше 12 В',
          'Стационарными светильниками напряжением 6 В во взрывозащищенном исполнении',
          'Стационарными светильниками напряжением 12 В во взрывозащищенном исполнении',
        ],
        correctAnswers: [
          'Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В в особо опасных помещениях, а в наружных установках - не выше 12 В',
        ],
      },
      {
        code: '63652095',
        text:
          'Каким образом при ревизии ПТ осуществляется контроль толщины стенки в случае, если ПТ имеет заводскую изоляцию?',
        answers: [
          'Контроль толщины стенки осуществляется приборами, позволяющими выполнять измерения через слой изоляции',
          'Если изоляционный слой не имеет видимых повреждений, то при ревизии ПТ контроль толщины стенки не проводится',
          'Контроль толщины стенки при ревизии ПТ проводится путем частичного снятия слоя заводской изоляции',
        ],
        correctAnswers: [
          'Контроль толщины стенки осуществляется приборами, позволяющими выполнять измерения через слой изоляции',
        ],
      },
      {
        code: '63652096',
        text:
          'Какие действия следует выполнить для обеспечения безопасного проведения подготовительных работ и газоопасных работ? Выберите два правильных варианта ответа.',
        answers: [
          'Провести инструктаж работникам подрядных организаций об основных опасных факторах производства',
          'Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)',
          'Во время проведения подготовительных работ выявить участки возможного выделения в зону работ взрывопожароопасных веществ с целью дальнейшего совмещения газоопасных и огневых работ в одном помещении',
          'Лицу, ответственному за проведение газоопасной работы, проверить полноту и качество выполненных подготовительных работ после их окончания',
        ],
        correctAnswers: [
          'Провести инструктаж работникам подрядных организаций об основных опасных факторах производства',
          'Предупредить работников, занятых ведением технологического процесса, о проводимых газоопасных работах с записью в журнале ведения технологического процесса (вахтенный журнал, журнал приема-сдачи смен)',
        ],
      },
      {
        code: '63652097',
        text:
          'В каком из перечисленных случаев необходимо провести внеочередной осмотр промысловых трубопроводов?',
        answers: [
          'В случае изменения схемы транспортирования',
          'При обнаружении на месте производства работ подземных коммуникаций и сооружений, не указанных в проектной документации',
          'При нарушении фактической глубины заложения промысловых трубопроводов',
          'Внеочередные осмотры промысловых трубопроводов не проводятся',
        ],
        correctAnswers: ['В случае изменения схемы транспортирования'],
      },
      {
        code: '63652098',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
          'Прохождение обучения по пожарной безопасности в объеме знаний требований Правил противопожарного режима в Российской Федерации, утвержденных постановлением Правительства Российской Федерации',
          'Назначение специалиста организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
        ],
      },
      {
        code: '63652099',
        text:
          'Какое из перечисленных лиц имеет право ставить подписи в наряде-допуске на проведение газоопасных работ, подтверждающие его закрытие и выполнение работ в полном объеме?',
        answers: [
          'Только руководитель структурного подразделения',
          'Только должностное лицо, ответственное за безопасное ведение технологического процесса на объекте',
          'Только лицо, ответственное за проведение газоопасных работ',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63652100',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63652101',
        text:
          'Какое из перечисленных требований к промысловым трубопроводам указано неверно?',
        answers: [
          'Запрещается применение неметаллических труб и их соединений',
          'Применяемые средства защиты от возможных видов коррозии должны обеспечивать безаварийное (по причине коррозии) функционирование ПТ в соответствии с условиями и сроком эксплуатации, установленными проектной документацией (документацией)',
          'Запрещается применение резьбовых соединений на ПТ, за исключением технических устройств, входящих в состав ПТ',
          'Техническими решениями должна быть обеспечена компенсация перемещений ПТ от изменения температуры, воздействия внутреннего давления',
        ],
        correctAnswers: [
          'Запрещается применение неметаллических труб и их соединений',
        ],
      },
      {
        code: '63652102',
        text:
          'В какой срок должна быть произведена ликвидация объектов промысловых трубопроводов?',
        answers: [
          'В срок не позднее 5 лет со дня принятия решения о ликвидации',
          'В срок не позднее 3 лет со дня принятия решения о ликвидации',
          'Срок определяется эксплуатирующей организацией в соответствии с документацией, подлежащей экспертизе промышленной безопасности',
          'Срок определяется экспертной организацией на основании заключения экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'В срок не позднее 5 лет со дня принятия решения о ликвидации',
        ],
      },
      {
        code: '63652103',
        text:
          'В каком из перечисленных случаях промысловый трубопровод не считается выдержавшим испытание на прочность?',
        answers: [
          'Если за время испытаний труба разрушилась',
          'Если за время испытаний не были обнаружены утечки среды, которой проводятся испытания',
          'Если за время выдержки под давлением значение пробного давления осталось в пределах допустимых норм',
          'Во всех перечисленных случаях ПТ не считается выдержавшим испытание на прочность',
        ],
        correctAnswers: ['Если за время испытаний труба разрушилась'],
      },
      {
        code: '63652104',
        text:
          'На сколько этапов разделяются ремонтные работы согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: ['Один', 'Два', 'Три', 'Четыре'],
        correctAnswers: ['Два'],
      },
      {
        code: '63652105',
        text:
          'Какое из перечисленных требований по обслуживанию арматуры промысловых трубопроводов указано верно?',
        answers: [
          'На запорной арматуре промысловых трубопроводов, кроме арматуры, имеющей редуктор, должны быть указатели, показывающие направление их вращения: "Открыто", "Закрыто"',
          'Открывать и закрывать запорную арматуру разрешается по распоряжению ответственного лица с фиксацией в журнале осмотров или вахтенном журнале',
          'Операции по управлению запорной арматурой и ее техническому обслуживанию должны проводиться в соответствии с требованиями технологического регламента, утвержденного руководителем эксплуатирующей организации',
          'Все перечисленные требования по обслуживанию арматуры промысловых трубопроводов указаны верно',
        ],
        correctAnswers: [
          'Открывать и закрывать запорную арматуру разрешается по распоряжению ответственного лица с фиксацией в журнале осмотров или вахтенном журнале',
        ],
      },
      {
        code: '63652106',
        text:
          'Какое количество наблюдающих должно быть, если существует необходимость выполнения газоопасных работ в емкости (аппарате) двумя работающими?',
        answers: [
          'Не менее одного',
          'Не менее двух',
          'Не менее четырех',
          'Определяется ответственным за проведение газоопасных работ',
        ],
        correctAnswers: ['Не менее двух'],
      },
      {
        code: '63652107',
        text:
          'По согласованию с кем устанавливается необходимость установки защитных кожухов в местах прокладки промысловых трубопроводов через ручьи, болота и озера?',
        answers: [
          'С владельцами данных участков',
          'С проектной организацией',
          'С органами местного самоуправления',
          'С территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['С владельцами данных участков'],
      },
      {
        code: '63652108',
        text:
          'Кто разрабатывает положение (инструкцию) об условиях совместной эксплуатации линейных сооружений при вводе в эксплуатацию вновь построенного ПТ, трасса которого проходит в одном техническом коридоре с другими коммуникациями?',
        answers: [
          'Эксплуатирующая организация совместно с владельцами других коммуникаций и сооружений технического коридора',
          'Проектная организация',
          'Сторонняя специализированная организация',
          'Эксплуатирующая организация совместно с проектной организацией и владельцами других коммуникаций и сооружений технического коридора',
        ],
        correctAnswers: [
          'Эксплуатирующая организация совместно с владельцами других коммуникаций и сооружений технического коридора',
        ],
      },
      {
        code: '63652109',
        text:
          'Какие меры безопасности должны быть выполнены перед началом продувки и испытания трубопровода газом или воздухом?',
        answers: [
          'Необходимо определить и обозначить знаками опасные зоны, в которых запрещено находиться людям',
          'Необходимо определить опасные зоны и выставить наблюдающих',
          'Необходимо установить видеокамеры в опасных зонах',
          'Необходимо подать длинный звуковой сигнал для удаления персонала в безопасную зону',
          'Необходимо установить заградительные сооружения вдоль трассы со световой сигнализацией',
        ],
        correctAnswers: [
          'Необходимо определить и обозначить знаками опасные зоны, в которых запрещено находиться людям',
        ],
      },
      {
        code: '63652110',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Прохождение медицинского осмотра в соответствии с требованиями законодательства Российской Федерации',
          'Возраст не менее 18 лет',
          'Прохождение обучения приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63652111',
        text:
          'В каком из перечисленных случаев испытания участка промысловых трубопроводов на прочность и проверка на герметичность не проводятся?',
        answers: [
          'После замены участка промысловых трубопроводов при капитальном ремонте на трубы, которые прошли испытания на прочность и проверку на герметичность на заводе-изготовителе',
          'Испытания на прочность и проверка на герметичность участков промысловых трубопроводов проводятся всегда',
          'Если на участке проводились работы по внутритрубной диагностике',
          'Если толщина стенки участка промысловых трубопроводов уменьшилась, но не достигла критической величины, определяемой в соответствии с расчетом критической толщины стенки и деталей промысловых трубопроводов',
        ],
        correctAnswers: [
          'Если на участке проводились работы по внутритрубной диагностике',
        ],
      },
      {
        code: '63652112',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Только специалист, прошедший дополнительное профессиональное обучение в области пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63652113',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63652114',
        text:
          'Какое из перечисленных мероприятий необходимо произвести в дополнение к плану мероприятий по выводу из эксплуатации при принятии решения о консервации и ликвидации объектов ПТ?',
        answers: [
          'Освобождение от продукта и отсечение от действующих коммуникаций с установкой концевых заглушек',
          'Обработку оборудования и ПТ, работавших с токсичными веществами',
          'Осмотр состояния объекта ПТ (с периодичностью, установленной эксплуатирующей организацией, но не реже одного раза в год)',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63652115',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны уметь пользоваться СИЗ',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны пройти инструктаж',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63652116',
        text:
          'Что необходимо предпринять эксплуатирующей организации при вводе в эксплуатацию вновь построенного ПТ, трасса которого проходит в одном техническом коридоре с другими коммуникациями?',
        answers: [
          'Разработать совместно с владельцами других коммуникаций и сооружений технического коридора дополнения к проектной документации на эксплуатацию опасного производственного объекта',
          'Уведомить территориальный орган Ростехнадзора и владельцев коммуникаций и сооружений технического коридора о вводе в эксплуатацию вновь построенного ПТ с предоставлением им проектной и эксплуатационной документации на данный ПТ',
          'Разработать совместно с владельцами других коммуникаций и сооружений технического коридора положение (инструкцию) об условиях совместной эксплуатации линейных сооружений',
        ],
        correctAnswers: [
          'Разработать совместно с владельцами других коммуникаций и сооружений технического коридора положение (инструкцию) об условиях совместной эксплуатации линейных сооружений',
        ],
      },
      {
        code: '63652117',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          '5 лет',
          'Наибольший срок, предусмотренный для этих объектов',
          '3 года',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63652118',
        text:
          'Что из перечисленного не является обязательной составляющей плана мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий',
          'Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварийных ситуаций',
        ],
        correctAnswers: [
          'Результаты расчета риска возникновения аварий на опасном производственном объекте',
        ],
      },
      {
        code: '63652119',
        text:
          'С какой периодичностью проводится обследование переходов через железные и автомобильные дороги общего пользования для промысловых трубопроводов, проложенных методом наклонно-направленного бурения?',
        answers: [
          'Обследование переходов через железные дороги и через автомобильные дороги проводится ежегодно',
          'Обследование переходов через железные и автомобильные дороги общего пользования проводится в составе общих работ по ревизии',
          'Обследование переходов через железные дороги - ежегодно, а через автомобильные дороги - один раз в два года',
        ],
        correctAnswers: [
          'Обследование переходов через железные и автомобильные дороги общего пользования проводится в составе общих работ по ревизии',
        ],
      },
      {
        code: '63652120',
        text:
          'С какой периодичностью проводится очистка промыслового трубопровода очистными устройствами?',
        answers: [
          'Периодичность очистки промыслового трубопровода устанавливается графиком, утвержденным техническим руководителем или уполномоченным им лицом эксплуатирующей организации',
          'Периодичность очистки ПТ устанавливается типовой инструкцией по проведению очистки внутренней полости промыслового трубопровода пропуском очистных устройств в зависимости от свойств транспортируемой среды',
          'Периодичность очистки промыслового трубопровода очистными устройствами определяется специализированной организацией, но не реже одного раза в год',
          'Каждый раз при снижении пропускной способности промыслового трубопровода на 10% от номинальной',
        ],
        correctAnswers: [
          'Периодичность очистки промыслового трубопровода устанавливается графиком, утвержденным техническим руководителем или уполномоченным им лицом эксплуатирующей организации',
        ],
      },
      {
        code: '63652121',
        text:
          'В течение какого срока со дня вывода объектов ПТ из эксплуатации принимается решение о вводе ПТ в эксплуатацию?',
        answers: [
          'Не позднее 12 месяцев',
          'Не позднее 6 месяцев',
          'Не позднее 24 месяцев',
          'Не позднее 3 месяцев',
        ],
        correctAnswers: ['Не позднее 12 месяцев'],
      },
      {
        code: '63652122',
        text:
          'Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?',
        answers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
          'Определяются эксплуатирующей организацией или ее структурным подразделением на основании диагностирования',
          'Определяются Ростехнадзором или его территориальным органом на основании экспертизы промышленной безопасности',
        ],
        correctAnswers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
        ],
      },
      {
        code: '63652123',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Любым из перечисленных лиц',
          'Только лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Только лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63652124',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует немедленно прекратить, а работника из емкости эвакуировать',
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует немедленно прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63652125',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63652126',
        text:
          'Что включает в себя вывод из эксплуатации промыслового трубопровода?',
        answers: [
          'Остановку технических устройств и сооружений промыслового трубопровода в целом с прекращением транспортирования сред, за исключением технических устройств, необходимых для обеспечения сохранности остановленных объектов, на срок от 1 до 12 месяцев',
          'Остановку технических устройств и сооружений промыслового трубопровода в целом с прекращением транспортирования сред на срок более 12 месяцев',
          'Остановку технических устройств и сооружений промыслового трубопровода в целом с прекращением транспортирования сред, за исключением технических устройств, необходимых для обеспечения сохранности остановленных объектов, на срок более 12 месяцев',
          'Остановку технических устройств и сооружений промыслового трубопровода в целом с прекращением транспортирования сред на срок от 3 до 6 месяцев',
        ],
        correctAnswers: [
          'Остановку технических устройств и сооружений промыслового трубопровода в целом с прекращением транспортирования сред, за исключением технических устройств, необходимых для обеспечения сохранности остановленных объектов, на срок от 1 до 12 месяцев',
        ],
      },
      {
        code: '63652127',
        text:
          'В какой срок после внесения изменений в системы управления технологическими процессами на объекте пересматриваются планы мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее двух недель',
          'Не позднее 15 календарных дней',
          'По предписанию территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63652128',
        text:
          'Кто утверждает перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право руководить этими работами?',
        answers: [
          'Руководитель организации или уполномоченное им лицо',
          'Ответственный исполнитель работ',
          'Начальник территориального органа Ростехнадзора',
          'Ответственный руководитель вышестоящей организации',
          'Директор регионального центра Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: ['Руководитель организации или уполномоченное им лицо'],
      },
      {
        code: '63652129',
        text:
          'Какие действия допускается выполнять с емкостями (аппаратами), подлежащими вскрытию, осмотру, чистке или ремонту?',
        answers: [
          'Только продувать инертным газом или воздухом',
          'Только отключать от действующего оборудования, систем трубопроводов и коммуникаций с помощью заглушек',
          'Только освобождать от опасных веществ',
          'Только промывать и пропаривать',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63652130',
        text:
          'Какие меры необходимо предпринять эксплуатирующей организации, если при ревизии ПТ будут обнаружены опасные дефекты, которые приводят к разгерметизации ПТ?',
        answers: [
          'Меры по устранению обнаруженных дефектов',
          'Незамедлительно прекратить эксплуатацию ПТ',
          'Устранение выявленных дефектов осуществляется в соответствии с мероприятиями, утверждаемыми техническим руководителем эксплуатирующей организации',
        ],
        correctAnswers: ['Меры по устранению обнаруженных дефектов'],
      },
      {
        code: '63652131',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 25% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63652132',
        text:
          'В течение какого времени каждый ресивер должен обеспечивать запас сжатого воздуха для работы всех средств измерений, регулирующих устройств и средств автоматики?',
        answers: [
          'В течение не менее одного часа',
          'В течение не менее 10 минут',
          'В течение не менее двух часов',
          'В течение не менее 30 минут',
        ],
        correctAnswers: ['В течение не менее одного часа'],
      },
      {
        code: '63652133',
        text:
          'При соблюдении какого требования выдается наряд-допуск на проведение ремонтных работ?',
        answers: [
          'После оформления акта сдачи-приемки объекта в ремонт',
          'После выполнения всех мероприятий, предусмотренных планом подготовительных работ',
          'После проверки выполнения всех мероприятий, предусмотренных планом подготовительных работ',
        ],
        correctAnswers: [
          'После оформления акта сдачи-приемки объекта в ремонт',
        ],
      },
      {
        code: '63652134',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63652135',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Главный инженер эксплуатирующей организации',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководитель эксплуатирующей организации',
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля.',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63652136',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63652137',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
          'Непосредственный руководитель работ и лица, ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования, технических устройств, коммуникаций в эксплуатацию',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63652138',
        text:
          'Кем определяются технические и организационные мероприятия, обеспечивающие безопасность ремонтных работ?',
        answers: [
          'Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации',
          'Руководителем эксплуатирующей организации, объект которой подлежит ремонту, совместно с руководителем структурного подразделения ремонтируемого объекта',
          'Непосредственным руководителем работ подрядной организации по согласованию с руководителем эксплуатирующей организации, объект которой подлежит ремонту',
        ],
        correctAnswers: [
          'Руководителем структурного подразделения ремонтируемого объекта совместно с непосредственным руководителем работ подрядной организации',
        ],
      },
      {
        code: '63652139',
        text:
          'Как должен проводиться подвод инертного газа или пара к трубопроводам для продувки?',
        answers: [
          'С помощью съемных участков трубопроводов или гибких шлангов, с установкой запорной арматуры с обеих сторон съемного участка',
          'С помощью шлангов, имеющих металлическую оплетку, предотвращающую перегиб шланга',
          'С помощью шарнирно соединенного металлического трубопровода',
          'С помощью стационарно установленного трубопровода или гибкого шланга, с установкой на его конце заглушки',
          'С помощью стационарно установленного трубопровода или гибкого шланга, с установкой на его конце обратного клапана',
        ],
        correctAnswers: [
          'С помощью съемных участков трубопроводов или гибких шлангов, с установкой запорной арматуры с обеих сторон съемного участка',
        ],
      },
      {
        code: '63652140',
        text:
          'На какой из перечисленных трубопроводов не распространяется действие федеральных норм и правил в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности"?',
        answers: [
          'Нефтепровод для транспортирования продуктов с температурой выше 100 °C',
          'Расположенные на кустовых площадках скважин выкидные трубопроводы от нефтяных скважин для транспортирования продуктов скважин до замерных установок',
          'Нефтепровод для транспортирования газонасыщенной безводной нефти от дожимной насосной станции до центрального пункта сбора',
          'Нефтепровод для транспортирования товарной нефти от центрального пункта сбора до сооружения магистрального транспорта',
        ],
        correctAnswers: [
          'Нефтепровод для транспортирования продуктов с температурой выше 100 °C',
        ],
      },
      {
        code: '63652141',
        text:
          'При каком условии допускается не проводить испытание всего промыслового трубопровода после замены его участка?',
        answers: [
          'Если участок перед врезкой в промысловый трубопровод прошел испытание, а гарантийные стыки (места присоединения к промысловому трубопроводу) были подвергнуты двойному неразрушающему контролю',
          'Если участок перед врезкой в промысловый трубопровод прошел ревизию в срок, не превышающий 6 месяцев, а гарантийные стыки (места присоединения к промысловому трубопроводу) были подвергнуты неразрушающему контролю радиографической дефектоскопией',
          'Если гарантийные стыки (места присоединения к промысловому трубопроводу) выполнялись аттестованными сварщиками под непосредственным контролем ответственного за производство сварочных работ',
          'После замены участка промыслового трубопровода всегда проводится испытание всего промыслового трубопровода',
        ],
        correctAnswers: [
          'Если участок перед врезкой в промысловый трубопровод прошел испытание, а гарантийные стыки (места присоединения к промысловому трубопроводу) были подвергнуты двойному неразрушающему контролю',
        ],
      },
      {
        code: '63652142',
        text:
          'Через какое расстояние эстакады для трубопроводов при обустройстве нефтяных, газовых и газоконденсатных месторождений должны быть электрически соединены с проходящими по ним трубопроводами и заземлены?',
        answers: [
          'Через 200 - 300 м, а также в начале и в конце',
          'Через 300 - 400 м, а также в начале и в конце',
          'Через 150 - 200 м, а также в начале и в конце',
          'Через 450 - 500 м, а также в начале и в конце',
        ],
        correctAnswers: ['Через 200 - 300 м, а также в начале и в конце'],
      },
      {
        code: '63652143',
        text:
          'Какое из приведенных требований к осмотру трассы промысловых трубопроводов указано неверно?',
        answers: [
          'Периодичность осмотра трассы промысловых трубопроводов должна определяться эксплуатирующей организацией',
          'В паводковый период периодичность осмотра трассы промысловых трубопроводов уменьшается',
          'Осмотр трассы промысловых трубопроводов может осуществляться путем постоянного видеоконтроля',
          'Все приведенные требования указаны верно',
        ],
        correctAnswers: [
          'В паводковый период периодичность осмотра трассы промысловых трубопроводов уменьшается',
        ],
      },
      {
        code: '63652144',
        text:
          'В каком случае допускается применение ремонтных конструкций, не имеющих маркировку и сертификаты на применяемые материалы, при ремонте ПТ?',
        answers: [
          'При условии, что информация о проведенных ремонтах вносится в паспорт ПТ',
          'При согласовании со специализированной сторонней организацией',
          'При согласовании с Ростехнадзором',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63652145',
        text: 'Какой срок консервации объектов промысловых трубопроводов?',
        answers: [
          'Срок консервации определяется проектной организацией, но не должен превышать 3 лет со дня принятия решения о консервации',
          'Срок консервации определяется эксплуатирующей организацией, но должен превышать 3 лет со дня принятия решения о консервации',
          'Срок консервации определяется эксплуатирующей организацией, но должен превышать 5 лет со дня принятия решения о консервации',
          'Срок консервации определяется проектной организацией, но должен превышать 5 лет со дня принятия решения о консервации',
        ],
        correctAnswers: [
          'Срок консервации определяется проектной организацией, но не должен превышать 3 лет со дня принятия решения о консервации',
        ],
      },
      {
        code: '63652146',
        text:
          'Каким документом устанавливается периодичность технического обслуживания средств электрохимической защиты промысловых трубопроводов?',
        answers: [
          'Графиками, утверждаемыми техническим руководителем эксплуатирующей организации',
          'Инструкцией по эксплуатации завода - изготовителя оборудования электрохимической защиты промысловых трубопроводов',
          'Проектной документацией (документацией) на промысловые трубопроводы',
          'Технологическим регламентом по эксплуатации промысловых трубопроводов',
        ],
        correctAnswers: [
          'Графиками, утверждаемыми техническим руководителем эксплуатирующей организации',
        ],
      },
      {
        code: '63652147',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии технического руководителя организации и начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63652148',
        text:
          'Какие процедуры производятся обслуживающим персоналом перед вводом в эксплуатацию участка трубопровода или всего трубопровода?',
        answers: [
          'Очистка полости, испытание на прочность и герметичность',
          'Очистка и пропарка всего участка',
          'Осмотр наружной поверхности',
          'Продувка и неразрушающий контроль',
          'Испытание на коррозиционную стойкость',
        ],
        correctAnswers: [
          'Очистка полости, испытание на прочность и герметичность',
        ],
      },
      {
        code: '63652149',
        text:
          'В течение какого срока организация, эксплуатирующая ПТ, обязана хранить проектную и исполнительную документацию, в порядке определенном руководителем организации?',
        answers: [
          'В течение всего срока эксплуатации ОПО (до ликвидации)',
          'В течение всего срока эксплуатации ОПО и 15 лет после его ликвидации',
          'В течение всего срока эксплуатации ОПО и 10 лет после его ликвидации',
          'В течение всего срока эксплуатации ОПО и года после его ликвидации',
        ],
        correctAnswers: [
          'В течение всего срока эксплуатации ОПО (до ликвидации)',
        ],
      },
      {
        code: '63652150',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять газоопасные работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63652151',
        text:
          'Что из перечисленного должно выполняться при обследовании подводных переходов ПТ?',
        answers: [
          'Только осмотр защитного кожуха',
          'Только определение положения ПТ (визуальный осмотр), наличия и величины оголений, провисов',
          'Только проверка на соответствие проектной, исполнительной и эксплуатационной документации на ПТ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63652152',
        text:
          'К какой группе газоопасных работ относятся работы по установке (снятию) заглушек и кто их проводит?',
        answers: [
          'К II группе, проводит эксплуатационный персонал',
          'К I группе, проводит бригада, определенная нарядом-допуском',
          'К I группе, проводит эксплуатационный персонал',
        ],
        correctAnswers: ['К II группе, проводит эксплуатационный персонал'],
      },
      {
        code: '63652153',
        text:
          'Кто утверждает мероприятия, в соответствии с которыми должны быть устранены дефекты, обнаруженные при ревизии ПТ?',
        answers: [
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель аварийной службы организации',
          'Лицо, ответственное за безопасную эксплуатацию ПТ',
        ],
        correctAnswers: [
          'Технический руководитель эксплуатирующей организации',
        ],
      },
      {
        code: '63652154',
        text: 'Что допускается во время очистки внутренней полости ПТ?',
        answers: [
          'Проведение ремонтно-строительных работ в охранной зоне ПТ',
          'Присутствие на площадках узлов запуска и приема средств очистки и диагностики лиц, не участвующих в проведении очистных работ',
          'Переезд трассы ПТ транспортом и механизмами',
          'Все перечисленное допускается',
          'Оборудование площадок приема и запуска средств очистки и диагностики конструкцией, предотвращающей вылет очистного устройства за пределы площадки',
        ],
        correctAnswers: [
          'Оборудование площадок приема и запуска средств очистки и диагностики конструкцией, предотвращающей вылет очистного устройства за пределы площадки',
        ],
      },
      {
        code: '63652155',
        text:
          'Для какого из перечисленных условий состояние перехода промыслового трубопровода считается исправным?',
        answers: [
          'Для всех перечисленных условий состояние перехода ПТ не считается исправным',
          'Понижение отметок дна в зоне перехода на 0,3 м',
          'Нарушение расположения балластных грузов',
          'Наличие вибрации ПТ под воздействием течения',
        ],
        correctAnswers: ['Понижение отметок дна в зоне перехода на 0,3 м'],
      },
      {
        code: '63652156',
        text:
          'С какой периодичностью должна проводиться разбивка промерных створов на подводных переходах через судоходные и несудоходные реки шириной зеркала воды в межень 25 м и более?',
        answers: [
          'Не менее одного раза в 4 года',
          'Для таких переходов проведение разбивки промерных створов не проводится',
          'Не менее одного раза в 8 лет',
          'Каждый раз после аномальных паводков',
        ],
        correctAnswers: ['Не менее одного раза в 4 года'],
      },
      {
        code: '63652157',
        text:
          'Кем выдается письменное разрешение на производство работ по реконструкции, техническому перевооружению и капитальному ремонту, на основании которого исполнитель может приступить к производству данных видов работ?',
        answers: [
          'Эксплуатирующей организацией',
          'Территориальным органом Ростехнадзора',
          'Организацией, осуществляющей экспертизу промышленной безопасности',
          'Проектной организацией',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '63652158',
        text:
          'Какое из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указано неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение организационных и технических мер по обеспечению пожарной безопасности мест проведения огневых работ',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63652159',
        text:
          'Когда следует проводить первую ревизию вновь введенных в эксплуатацию промысловых трубопроводов?',
        answers: [
          'Когда скорость эрозионно-коррозионных процессов превысит значение 0,1 мм/год',
          'Не позднее чем через 1 год после начала эксплуатации ПТ',
          'Не позднее чем через 2 года после начала эксплуатации ПТ',
          'Не ранее чем через 1 год после начала эксплуатации ПТ',
        ],
        correctAnswers: [
          'Не позднее чем через 1 год после начала эксплуатации ПТ',
        ],
      },
      {
        code: '63652160',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Начальнику цеха',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
          'Пожарной службе эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63652161',
        text:
          'С какой периодичностью проводится обследование переходов через железные и автомобильные дороги общего пользования?',
        answers: [
          'Обследование переходов через железные дороги - ежегодно, а через автомобильные дороги - один раз в два года',
          'Обследование переходов через железные и автомобильные дороги общего пользования проводится в составе общих работ по ревизии',
          'Обследование переходов через железные дороги и через автомобильные дороги проводится ежегодно',
        ],
        correctAnswers: [
          'Обследование переходов через железные дороги и через автомобильные дороги проводится ежегодно',
        ],
      },
      {
        code: '63652162',
        text:
          'Проведение пневматических испытаний каких вновь построенных промысловых трубопроводов не разрешается?',
        answers: [
          'С рабочим давлением выше 11,8 МПа',
          'С рабочим давлением выше 9,8 МПа',
          'С рабочим давлением выше 8,8 МПа',
          'С рабочим давлением выше 10,8 МПа',
        ],
        correctAnswers: ['С рабочим давлением выше 11,8 МПа'],
      },
      {
        code: '63652163',
        text:
          'Какие существуют виды ремонтных работ согласно Федеральным нормам и правилам в области промышленной безопасности "Правила безопасного ведения газоопасных, огневых и ремонтных работ"?',
        answers: [
          'Только плановые ремонтные работы',
          'Только внеплановые ремонтные работы',
          'Только аварийно-восстановительные ремонтные работы',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63652164',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты или руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Главный инженер организации и работник службы охраны труда',
          'Главный механик и работник службы охраны труда',
          'Сотрудник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты или руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63652165',
        text:
          'Какие из перечисленных методов ремонта в соответствии с федеральными нормами и правилами в области промышленной безопасности "Правила безопасности в нефтяной и газовой промышленности" относятся к методам текущего ремонта промыслового трубопровода?',
        answers: [
          'Установка ремонтной конструкции',
          'Установка чопиков',
          'Замена изоляции',
          'Замена ЭХЗ',
        ],
        correctAnswers: ['Установка ремонтной конструкции'],
      },
      {
        code: '63652166',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ взрывопожароопасных веществ',
          'Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек',
          'Увеличение объема и характера работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ взрывопожароопасных веществ',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ I группы бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63652167',
        text:
          'Какие меры необходимо принять при обнаружении участков изоляции, пропитанной горючим веществом?',
        answers: [
          'Снять пропитанную изоляцию, подвести водяной пар',
          'Разместить рядом с участком средства пожаротушения',
          'Обтереть изоляцию ветошью',
          'Обсыпать изоляцию песком',
        ],
        correctAnswers: ['Снять пропитанную изоляцию, подвести водяной пар'],
      },
      {
        code: '63652168',
        text:
          'При каком способе прокладки ПТ должна быть обеспечена надежная и безопасная эксплуатация ПТ с учетом рельефа, грунтовых и природно-климатических условий?',
        answers: [
          'При всех способах прокладки ПТ',
          'При подземной прокладке ПТ',
          'При надземной прокладке ПТ',
          'При наземной прокладке ПТ',
        ],
        correctAnswers: ['При всех способах прокладки ПТ'],
      },
      {
        code: '63652169',
        text:
          'Какое из перечисленных требований должно выполняться для определения мест проведения неразрушающего контроля при ревизии промысловых трубопроводов?',
        answers: [
          'Для промысловых трубопроводов с протяженностью менее 500 м производится не менее двух шурфов на объект',
          'Для промысловых трубопроводов с протяженностью 500 м и более выбирается один участок на каждый километр трассы промысловых трубопроводов',
          'На временно неработающих участках при ревизии промысловых трубопроводов неразрушающий контроль не проводится',
        ],
        correctAnswers: [
          'Для промысловых трубопроводов с протяженностью менее 500 м производится не менее двух шурфов на объект',
        ],
      },
      {
        code: '63652170',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63652171',
        text:
          'В соответствии с какой документацией проводится ввод в эксплуатацию вновь построенных промысловых трубопроводов?',
        answers: [
          'В соответствии с проектной документацией',
          'В соответствии с протоколом проведения проверки готовности промысловых трубопроводов к вводу в эксплуатацию',
          'В соответствии с типовой инструкцией по вводу в эксплуатацию вновь построенных промысловых трубопроводов',
          'В соответствии с исполнительно-технической документацией исполнителя строительных работ',
        ],
        correctAnswers: ['В соответствии с проектной документацией'],
      },
      {
        code: '63652172',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63652173',
        text:
          'Какое из перечисленных требований к внутритрубной диагностике ПТ является неверным?',
        answers: [
          'Внутритрубная диагностика проводится при условии технической возможности, определенной проектной документацией (документацией)',
          'При проведении внутритрубной диагностики ультразвуковая толщинометрия не проводится',
          'Запасовка, пуск, прием и извлечение внутритрубных снарядов должны проводиться под контролем ответственного лица эксплуатирующей организации, назначаемого приказом',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'При проведении внутритрубной диагностики ультразвуковая толщинометрия не проводится',
        ],
      },
      {
        code: '63652174',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63652175',
        text:
          'Какой максимальный промежуток времени между периодическими ревизиями промысловых трубопроводов?',
        answers: ['1 год', '4 года', '8 лет', '10 лет'],
        correctAnswers: ['8 лет'],
      },
      {
        code: '63652176',
        text:
          'Какие требования предъявляются к техническим устройствам, которые вводятся в эксплуатацию на опасном производственном объекте после капитального ремонта, связанного с конструктивными изменениями?',
        answers: [
          'Технические устройства, которые вводятся в эксплуатацию на опасном производственном объекте после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации',
          'Документация на технические устройства, которые вводятся в эксплуатацию на опасном производственном объекте после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с разработчиком этого оборудования',
          'Документация на технические устройства, которые вводятся в эксплуатацию на опасном производственном объекте после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с надзорными органами',
        ],
        correctAnswers: [
          'Технические устройства, которые вводятся в эксплуатацию на опасном производственном объекте после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации',
        ],
      },
      {
        code: '63652177',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования',
          'Только с разрешения представителя газоспасательной службы и при обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63652178',
        text:
          'Какие из обязательных мер безопасного ведения газоопасных работ, предусмотренных правилами, указаны неверно?',
        answers: [
          'Выполнение работ I группы бригадой исполнителей не менее трех человек',
          'Обеспечение контроля за состоянием воздушной среды',
          'Исключение возможности присутствия на месте проведения газоопасной работы лиц, не занятых ее выполнением',
          'Обеспечение членов бригады СИЗ, спецодеждой, инструментом',
        ],
        correctAnswers: [
          'Выполнение работ I группы бригадой исполнителей не менее трех человек',
        ],
      },
      {
        code: '63652179',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
          'Руководитель структурного подразделения или лицо, его замещающее',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63652180',
        text:
          'Какие условия должны выполняться при пересечении подземных промысловых трубопроводов с автомобильными и железными дорогами?',
        answers: [
          'Обязательна установка предупредительных знаков и надписей',
          'Трубопроводы должны быть уложены в защитные кожухи из стальных или железобетонных труб',
          'Высота перехода над дорогами должна быть не менее 10 м',
          'По обе стороны от места пересечения устанавливается запорная арматур',
        ],
        correctAnswers: [
          'Трубопроводы должны быть уложены в защитные кожухи из стальных или железобетонных труб',
        ],
      },
      {
        code: '63652181',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники подрядной организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Правилами не регламентируется',
          'Работники, указанные в наряде-допуске',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63652182',
        text:
          'Каким образом необходимо разработать план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах в случае, если 2 и более объекта, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
          'Необходимо разработать планы мероприятий для каждого объекта отдельно',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
        ],
        correctAnswers: [
          'Допускается разработка единого плана мероприятий для 2 и более объектов',
        ],
      },
      {
        code: '63652183',
        text:
          'С кем согласовываются планы мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий и аварийно-спасательным формированием',
          'C Ростехнадзором и Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'С Министерством Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63652184',
        text:
          'Каким образом осуществляется ввод в эксплуатацию объектов промысловых трубопроводов по прежнему назначению, если срок вывода из эксплуатации составляет 12 месяцев?',
        answers: [
          'Ввод в эксплуатацию осуществляется после пробной эксплуатации ПТ не менее 72 часов',
          'Ввод в эксплуатацию осуществляется после проведения экспертизы промышленной безопасности',
          'Ввод в эксплуатацию осуществляется после проведения работ по ревизии при условии, что срок эксплуатации не превышает нормативный',
          'Ввод в эксплуатацию осуществляется после проведения ультразвуковой толщинометрии стенки ПТ или внутритрубной диагностики',
        ],
        correctAnswers: [
          'Ввод в эксплуатацию осуществляется после проведения работ по ревизии при условии, что срок эксплуатации не превышает нормативный',
        ],
      },
      {
        code: '63652185',
        text:
          'С какой периодичностью должна проводиться проверка состояния балластировки и изоляции на размытых участках промыслового трубопровода на подводных переходах через судоходные и несудоходные реки шириной зеркала воды в межень 25 м и более?',
        answers: [
          'Не менее одного раза в 4 года',
          'Каждый раз при обнаружении нарушения расположения балластировочных грузов',
          'При обнаружении оголенных участков, но не реже одного раза в год',
          'Не регламентируется',
        ],
        correctAnswers: ['Не менее одного раза в 4 года'],
      },
      {
        code: '63652186',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Технологический регламент',
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63652187',
        text:
          'Чем должны проводиться пневматические испытания промысловых трубопроводов, ранее транспортировавших углеводородные взрывоопасные среды?',
        answers: ['Инертным газом', 'Воздухом', 'Паром', 'Углекислым газом'],
        correctAnswers: ['Инертным газом'],
      },
    ],
    63649: [
      {
        code: '63649000',
        text:
          'В каком исполнении должны быть агрегаты, применяемые во взрывопожароопасных зонах?',
        answers: [
          'Во взрывозащищенном',
          'В пылевлагонепроницаемом',
          'Во взрывонепроницаемом',
          'В брызгозащищенном',
        ],
        correctAnswers: ['Во взрывозащищенном'],
      },
      {
        code: '63649001',
        text:
          'При каких погодных условиях запрещается передвижение оборудования на скважину?',
        answers: [
          'При снегопаде, тумане, пылевых бурях, порывах ветра более 20 м/с',
          'При снегопаде, тумане, пылевых бурях при видимости менее 50 м, порывах ветра более 30 м/с',
          'При тумане, пылевых бурях, порывах ветра более 15 м/с',
          'Только при снегопаде, тумане, пылевых бурях',
        ],
        correctAnswers: [
          'При снегопаде, тумане, пылевых бурях при видимости менее 50 м, порывах ветра более 30 м/с',
        ],
      },
      {
        code: '63649002',
        text:
          'Какие требования предъявляются к территориям при подготовке скважины к ремонту?',
        answers: [
          'Территория должна быть ограждена металлической сеткой высотой более 1,5 м',
          'По периметру территории должна быть установлена световая и звуковая сигнализация',
          'На территории должны быть установлены плакаты, содержащие сведения о ремонтной организации',
          'Территория должна быть спланирована, освобождена от посторонних предметов, подземные коммуникации четко обозначены, а газопроводы газлифтной скважины заключены в патрон',
        ],
        correctAnswers: [
          'Территория должна быть спланирована, освобождена от посторонних предметов, подземные коммуникации четко обозначены, а газопроводы газлифтной скважины заключены в патрон',
        ],
      },
      {
        code: '63649003',
        text:
          'На каком расстоянии от устья скважины можно располагать бытовые помещения?',
        answers: [
          'На расстоянии не менее высоты мачты агрегата плюс 10 м',
          'На расстоянии не менее высоты мачты агрегата плюс 7 м',
          'На расстоянии не менее высоты мачты агрегата плюс 3 м',
          'На расстоянии не менее высоты мачты агрегата плюс 5 м',
        ],
        correctAnswers: [
          'На расстоянии не менее высоты мачты агрегата плюс 10 м',
        ],
      },
      {
        code: '63649004',
        text:
          'В течение какого времени наряд-допуск на проведение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной дневной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной дневной смены'],
      },
      {
        code: '63649005',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63649006',
        text:
          'На основании какого документа осуществляются работы повышенной опасности на опасных производственных объектах?',
        answers: [
          'На основании регламента об организации безопасного производства работ, утвержденного руководителем этой организации',
          'На основании руководства по эксплуатации оборудования',
          'На основании наряда-допуска',
          'На основании Федеральных норм и правил в области промышленной безопасности',
        ],
        correctAnswers: ['На основании наряда-допуска'],
      },
      {
        code: '63649007',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы?',
        answers: [
          'Работники, список которых определяется внутренними документами организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта совместно с работниками аварийно-спасательных подразделений',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63649008',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за проведение газоопасной работы',
          'Руководителю эксплуатирующей организации',
          'Лицу, ответственному за безопасное ведение технологического процесса на объекте',
          'Начальнику цеха',
          'Пожарной службе эксплуатирующей организации',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63649009',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 6 месяцев со дня закрытия наряда-допуска',
          'Не менее 1 года со дня закрытия наряда-допуска',
          'Не менее 3 месяцев со дня закрытия наряда-допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда-допуска'],
      },
      {
        code: '63649010',
        text:
          'Какой вид вентиляционной системы допустим во взрывоопасных помещениях во время проведения ремонта оборудования?',
        answers: [
          'Естественная вентиляция',
          'Механическая вентиляция',
          'Вытяжная вентиляция',
          'Приточно-вытяжная вентиляция',
        ],
        correctAnswers: ['Приточно-вытяжная вентиляция'],
      },
      {
        code: '63649011',
        text:
          'Какими светильниками должны быть обеспечены опасные производственные объекты нефтегазодобывающих производств?',
        answers: [
          'Стационарными светильниками напряжением 12 В во взрывозащищенном исполнении',
          'Стационарными светильниками напряжением 6 В во взрывозащищенном исполнении',
          'Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В в особо опасных помещениях, а в наружных установках - не выше 12 В',
          'Переносными светильниками напряжением 24 В во взрывозащищенном исполнении - в наружных установках',
        ],
        correctAnswers: [
          'Переносными светильниками, для питания которых должно применяться напряжение не выше 50 В в особо опасных помещениях, а в наружных установках - не выше 12 В',
        ],
      },
      {
        code: '63649012',
        text:
          'Какое из перечисленных требований предъявляется к оборудованию устья скважины, подлежащей реконструкции?',
        answers: [
          'Обвязка устья скважины, подлежащей реконструкции, должна позволять выполнение исследовательских работ',
          'Устье скважины, подлежащей реконструкции, должно быть оборудовано необходимыми контрольно-измерительными приборами',
          'Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 10% возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны',
        ],
        correctAnswers: [
          'Устье скважины вместе с ПВО должно быть опрессовано на давление, превышающее не менее чем на 10% возможное давление, возникающее при ликвидации ГНВП и открытых фонтанов, а также при опробовании и эксплуатации скважины, но не выше допустимого давления опрессовки эксплуатационной колонны',
        ],
      },
      {
        code: '63649013',
        text:
          'Каким образом происходит передвижение транспортных средств на кустовых площадках?',
        answers: [
          'В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ',
          'В соответствии с графиком передвижения',
          'По имеющимся в наличии дорогам со скоростью не более 40 км/ч',
          'Правилами не регламентировано',
        ],
        correctAnswers: [
          'В соответствии с установленными маршрутами передвижения и под контролем ответственного руководителя работ',
        ],
      },
      {
        code: '63649014',
        text:
          'С чем должны быть ознакомлены работники, принимающие участие в транспортировании оборудования?',
        answers: [
          'С краткосрочным прогнозом погоды',
          'С трассой передвижения, опасными участками и мерами безопасности при их преодолении',
          'С техническими характеристиками подвижного состава',
          'Со всем перечисленным',
        ],
        correctAnswers: [
          'С трассой передвижения, опасными участками и мерами безопасности при их преодолении',
        ],
      },
      {
        code: '63649015',
        text:
          'В каком порядке осуществляется допуск подрядных организаций на опасные производственные объекты нефтегазодобывающих производств?',
        answers: [
          'В соответствии с положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств',
          'В соответствии с графиком взаимодействия, согласованным с заинтересованными организациями',
          'В соответствии с инструкцией, устанавливающей требования к организации работ, утвержденной организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств',
          'В соответствии с производственным заданием, выданным руководителем организации, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств, или лицом, его заменяющим',
        ],
        correctAnswers: [
          'В соответствии с положением о порядке допуска и организации безопасного производства работ, утвержденным организацией, эксплуатирующей опасные производственные объекты нефтегазодобывающих производств',
        ],
      },
      {
        code: '63649016',
        text: 'В каких случаях допускается эксплуатация стрелочных переводов?',
        answers: [
          'При разъединенных стрелочных тягах',
          'При отсутствии фиксации положения стрелочных переводов',
          'При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм',
          'При открытых канавах стрелочных переводов',
          'При неплотно прилегающих к рамному рельсу и башмакам стрелочных перьях',
        ],
        correctAnswers: [
          'При замыкании стрелок с зазором между прижатым острием пера и рамным рельсом 3 мм',
        ],
      },
      {
        code: '63649017',
        text:
          'В каком порядке производятся передача скважин для ремонта или реконструкции и приемка скважин после завершения ремонтных работ от заказчика подрядчику?',
        answers: [
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией',
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается нормативно-технической документацией Ростехнадзора',
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается подрядной организацией и утверждается заказчиком',
        ],
        correctAnswers: [
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией',
        ],
      },
      {
        code: '63649018',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами пожарной безопасности',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
      {
        code: '63649019',
        text:
          'Кем утверждается перечень газоопасных работ, проводимых на опасных производственных объектах?',
        answers: [
          'Руководителем эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Руководителем службы производственного контроля или лицом, ответственным за осуществление производственного контроля',
          'Руководителем эксплуатирующей организации',
          'Главным инженером эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем эксплуатирующей организации'],
      },
      {
        code: '63649020',
        text:
          'В каких случаях производится забуривание новых (боковых) стволов в обсаженных скважинах?',
        answers: [
          'Только при ликвидации аварий, инцидентов и осложнений, возникающих в процессе бурения, эксплуатации скважины или при проведении ремонтных работ',
          'Только при вскрытии дополнительных продуктивных мощностей путем проводки ответвлений (в том числе горизонтальных) из пробуренных стволов скважин',
          'Только при восстановлении бездействующего фонда скважин, в том числе ранее ликвидированных по техническим или иным причинам, с целью вскрытия новым стволом участков с неизвлеченными запасами углеводородного сырья',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63649021',
        text:
          'Какими организациями разрабатываются и утверждаются технологические регламенты (ТР) на работы по добыче, сбору и подготовке нефти, газа и газового конденсата?',
        answers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией',
          'Разрабатываются специализированными организациями, а утверждаются компанией-оператором',
          'Разрабатываются проектной организацией, а утверждаются территориальными органами Ростехнадзора',
          'Разрабатываются проектной организацией, а утверждаются подрядной организацией',
          'Разрабатываются и утверждаются компанией-оператором',
        ],
        correctAnswers: [
          'Разрабатываются проектной организацией на стадии проектирования и строительства, а также реконструкции. ТР на ОПО, находящийся в эксплуатации, может разрабатываться эксплуатирующей организацией',
        ],
      },
      {
        code: '63649022',
        text:
          'Какие дополнительные требования устанавливаются при передаче газлифтной скважины в ремонт?',
        answers: [
          'Установка экранирующего устройства, обеспечивающего защиту устьевого оборудования',
          'Остановка соседних скважин и наличие плана работ',
          'Кроме плана работ по ремонту скважины, предоставляется план-схема газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин',
        ],
        correctAnswers: [
          'Кроме плана работ по ремонту скважины, предоставляется план-схема газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин',
        ],
      },
      {
        code: '63649023',
        text:
          'На основании какого документа осуществляются работы по строительству, эксплуатации и ремонту подземных скважин?',
        answers: [
          'На основании плана работ',
          'На основании технологической инструкции',
          'На основании распоряжения руководителя работ',
          'На основании наряда',
        ],
        correctAnswers: ['На основании наряда'],
      },
      {
        code: '63649024',
        text:
          'При каких погодных условиях запрещается проводить работы на высоте?',
        answers: [
          'Только во время грозы, ливня, снегопада',
          'Только при скорости ветра более 15 м/с',
          'Только при гололедице',
          'При всех перечисленных факторах',
        ],
        correctAnswers: ['При всех перечисленных факторах'],
      },
      {
        code: '63649025',
        text:
          'На какое давление должна быть опрессована скважина после установки противовыбросового оборудования?',
        answers: [
          'На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны',
          'На давление, равное давлению опрессовки эксплуатационной колонны',
          'На максимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны',
          'На минимально ожидаемое давление, но не ниже давления опрессовки эксплуатационной колонны',
        ],
        correctAnswers: [
          'На максимально ожидаемое давление, но не выше давления опрессовки эксплуатационной колонны',
        ],
      },
      {
        code: '63649026',
        text:
          'Кто утверждает состав комиссии, которая производит пуск смонтированной установки в работу?',
        answers: [
          'Экспертная организация',
          'Эксплуатирующая организация',
          'Ростехнадзор',
          'Нештатное аварийно-спасательное формирование',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63649027',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63649028',
        text:
          'На сколько должен быть сработан деревянный настил мостков и рабочей площадки, чтобы его заменили?',
        answers: [
          'Более 10% от первоначальной толщины',
          'Более 15% от первоначальной толщины',
          'Более 5% от первоначальной толщины',
          'Более 7% от первоначальной толщины',
        ],
        correctAnswers: ['Более 15% от первоначальной толщины'],
      },
      {
        code: '63649029',
        text:
          'Каким кабелем должно осуществляться подключение станции управления к нефтепромысловой сети напряжением 400 В или передвижной электростанции?',
        answers: [
          'Двухжильным кабелем в металлической оплетке с применением разъема с экранирующим контуром',
          'Трехжильным кабелем с двойной изоляцией и заземляющим контуром',
          'Гибким пятипроводным кабелем посредством четырехконтактного разъема с заземляющим контактом',
          'Бронированным трехжильным кабелем с заземляющим контактом',
        ],
        correctAnswers: [
          'Гибким пятипроводным кабелем посредством четырехконтактного разъема с заземляющим контактом',
        ],
      },
      {
        code: '63649030',
        text:
          'Кто выполняет все работы по остановке действующих скважин и их пуску в работу?',
        answers: [
          'Соответствующие службы заказчика',
          'Сервисная организация',
          'Специализированная служба подрядчика',
          'Противофонтанная служба',
        ],
        correctAnswers: ['Соответствующие службы заказчика'],
      },
      {
        code: '63649031',
        text:
          'Нужно ли пересматривать перечни газоопасных работ при изменении технологического процесса и технологической схемы производства?',
        answers: [
          'Нужно в любом случае',
          'По решению руководителя эксплуатирующей организации',
          'Правилами не регламентируется',
          'Решение принимается совместно руководителем эксплуатирующей организации и руководителем газоспасательной службы',
        ],
        correctAnswers: ['Нужно в любом случае'],
      },
      {
        code: '63649032',
        text:
          'Что не указывается на металлической табличке, укрепленной на мачте агрегата для ремонта скважин?',
        answers: [
          'Дата изготовления',
          'Заводской номер установки, грузоподъемность (номинальная) мачты',
          'Завод-изготовитель, сроки следующей проверки технического освидетельствования подъемного агрегата',
          'Наименование предприятия - владельца агрегата',
        ],
        correctAnswers: ['Наименование предприятия - владельца агрегата'],
      },
      {
        code: '63649033',
        text:
          'Обязательно ли прекращение нагнетания газа в ремонтируемую скважину, а также в соседние скважины слева и справа перед расстановкой оборудования для подземного или капитального ремонта скважин?',
        answers: [
          'Обязательно',
          'Необязательно',
          'По усмотрению заказчика',
          'По решению подрядной организации',
        ],
        correctAnswers: ['Обязательно'],
      },
      {
        code: '63649034',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий (ПЛА)?',
        answers: [
          'Главный механик и работник службы охраны труда',
          'Технический руководитель предприятия',
          'Главный инженер организации и работник службы охраны труда',
          'Работник, ответственный за организацию и осуществление производственного контроля',
        ],
        correctAnswers: ['Технический руководитель предприятия'],
      },
      {
        code: '63649035',
        text:
          'Сколько часов следует выдерживать скважину, оборудованную забойным клапаном-отсекателем, в которой не предусмотрено проведение предварительного глушения, после остановки и стравливания давления до атмосферного?',
        answers: [
          'Не менее двух часов',
          'Не менее трех часов',
          'Не менее одного часа',
        ],
        correctAnswers: ['Не менее трех часов'],
      },
      {
        code: '63649036',
        text:
          'Какое натяжение должны иметь оттяжки агрегатов по ремонту скважин (установок)?',
        answers: [
          'Не менее 300 - 350 кгс',
          'Не менее 400 - 500 кгс',
          'Не менее 250 - 350 кгс',
          'Не менее 100 - 250 кгс',
        ],
        correctAnswers: ['Не менее 400 - 500 кгс'],
      },
      {
        code: '63649037',
        text:
          'При каких условиях проводится чистка песчаных пробок желонкой в скважинах, в продукции которых есть сероводород?',
        answers: [
          'После проведения замера концентрации сернистого водорода газоанализаторами на объекте',
          'При наличии в бригаде средств индивидуальной защиты',
          'Чистка не разрешается',
        ],
        correctAnswers: ['Чистка не разрешается'],
      },
      {
        code: '63649038',
        text:
          'При каких условиях запрещается проведение спуско-подъемных операций, а также ведение ремонтных работ, связанных с нагрузкой на мачту (вышку)?',
        answers: [
          'Если нагрузка на мачту превышает предусмотренную в инструкции завода-изготовителя нагрузку',
          'При неполном составе вахты',
          'Без исправного индикатора веса',
        ],
        correctAnswers: ['Без исправного индикатора веса'],
      },
      {
        code: '63649039',
        text:
          'Разрешается ли проводить забуривание бокового ствола в скважине при наличии перетоков в затрубном пространстве?',
        answers: [
          'Разрешается при использовании специального оборудования',
          'Разрешается при наличии плана работ, в котором указаны интервалы, где возможны перетоки',
          'Разрешается, если все перетоки, выявленные в ходе исследований, ликвидированы',
        ],
        correctAnswers: [
          'Разрешается, если все перетоки, выявленные в ходе исследований, ликвидированы',
        ],
      },
      {
        code: '63649040',
        text:
          'Что необходимо сделать перед ремонтом скважины, оборудованной погружным центробежным электронасосом?',
        answers: [
          'Обесточить электрический кабель',
          'Открыть центральную задвижку и начать демонтаж адаптера',
          'Сделать натяжку насосно-компрессорной трубы (далее - НКТ) и освободить устьевой пакер',
          'Провести проверку работоспособности оборудования',
        ],
        correctAnswers: ['Обесточить электрический кабель'],
      },
      {
        code: '63649041',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем огневых работ?',
        answers: [
          'Работники подрядной организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, указанные в наряде-допуске',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63649042',
        text:
          'Какая из перечисленных обязанностей руководителя структурного подразделения, на объекте которого будут проводиться огневые работы, указана неверно?',
        answers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
          'Назначение лиц, ответственных за подготовку и выполнение огневых работ',
          'Определение объема и содержания подготовительных работ и последовательности их выполнения',
          'Определение порядка контроля воздушной среды и выбор средств индивидуальной защиты',
        ],
        correctAnswers: [
          'Определение списка лиц, ответственных за подготовку места проведения огневых работ, и лиц, ответственных за выполнение огневых работ',
        ],
      },
      {
        code: '63649043',
        text:
          'Какое количество бригад может одновременно работать по ремонту скважин с одновременным бурением на кусте?',
        answers: [
          'Одна',
          'Две',
          'Количество бригад не регламентируется',
          'Три',
        ],
        correctAnswers: ['Количество бригад не регламентируется'],
      },
      {
        code: '63649044',
        text:
          'Тросом какого диаметра должен быть застрахован ролик кабеля электрического центробежного насоса (далее - ЭЦН), подвешиваемого на мачте агрегата для ремонта скважин?',
        answers: ['5 мм', '7 мм', '8 - 10 мм', '10 - 12 мм'],
        correctAnswers: ['8 - 10 мм'],
      },
      {
        code: '63649045',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, список которых определяется внутренними документами организации',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63649046',
        text:
          'При какой концентрации взрывопожароопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии взрывопожароопасных веществ выше 18% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии взрывопожароопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63649047',
        text:
          'В каком случае при ремонте механизированных скважин на газлифтном кусте соседняя скважина должна быть остановлена, а при необходимости заглушена?',
        answers: [
          'Если расстояние между центрами устьев скважин 1,5 м и менее',
          'Если расстояние между центрами устьев скважин 3,0 м и более',
          'Если расстояние между центрами устьев скважин 2,0 м и менее',
          'Если расстояние между центрами устьев скважин 2,2 м и менее',
        ],
        correctAnswers: [
          'Если расстояние между центрами устьев скважин 1,5 м и менее',
        ],
      },
      {
        code: '63649048',
        text:
          'В соответствии с каким документом устанавливается контроль за состоянием воздушной среды?',
        answers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
          'В соответствии с технологическим регламентом',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63649049',
        text:
          'Кем из перечисленных лиц может осуществляться общая координация ремонтных работ на объекте, где ремонтные работы производятся несколькими подрядными организациями и заказчиком?',
        answers: [
          'Только лицом, назначенным руководителем эксплуатирующей организации (филиала организации)',
          'Только лицом, назначенным уполномоченным заместителем руководителя эксплуатирующей организации (филиала организации)',
          'Любым из перечисленных лиц',
        ],
        correctAnswers: ['Любым из перечисленных лиц'],
      },
      {
        code: '63649050',
        text:
          'Какое из перечисленных требований к объектам сбора, транспорта, подготовки нефти, попутного нефтяного газа, воды в горных выработках, указано верно?',
        answers: [
          'Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород',
          'Нефтеловушки и нефтеперекачивающие станции размещаются в тупиковых горных выработках',
          'При бурении скважин станками с гидроприводом или с промывкой в полевых штреках сброс воды в водоотливные канавки категорически запрещен',
          'Допускается оставлять застой нефти на фундаментах насосов и под фундаментной рамой оборудования и механизмов, если это регламентировано локальной инструкцией организации',
        ],
        correctAnswers: [
          'Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород',
        ],
      },
      {
        code: '63649051',
        text:
          'С кем должен быть согласован план работ по текущему ремонту скважин?',
        answers: [
          'С руководителем ремонтной организации',
          'С заказчиком',
          'С территориальным органом Ростехнадзора',
          'С противофонтанной службой',
        ],
        correctAnswers: ['С заказчиком'],
      },
      {
        code: '63649052',
        text:
          'В каком случае допускается пересечение электрокабелем внутрипромысловых дорог?',
        answers: [
          'В трубах на глубине не менее 0,5 м от полотна дороги',
          'В трубах на глубине не менее 0,4 м от полотна дороги',
          'В трубах на глубине не менее 0,3 м от полотна дороги',
          'В трубах на глубине не менее 0,2 м от полотна дороги',
        ],
        correctAnswers: [
          'В трубах на глубине не менее 0,5 м от полотна дороги',
        ],
      },
      {
        code: '63649053',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Исправления в тексте наряда-допуска на проведение газоопасных работ',
          'Заполнение наряда-допуска на проведение газоопасных работ карандашом',
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63649054',
        text:
          'У каких лиц устанавливается аппаратура системы общешахтного аварийного оповещения?',
        answers: [
          'Только у горного диспетчера нефтяной шахты',
          'Только у технического руководителя обособленного структурного подразделения',
          'Только у абонентов общешахтной и диспетчерской телефонной связи, определенных техническим руководителем обособленного структурного подразделения',
          'У всех перечисленных лиц',
        ],
        correctAnswers: ['У всех перечисленных лиц'],
      },
      {
        code: '63649055',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'Во время грозы',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ, учитывающих условия их выполнения в темное время суток',
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
          'В дневное или в темное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63649056',
        text:
          'Каким должен быть минимальный радиус опасной зоны вокруг устья скважины на время прострелочных работ?',
        answers: ['5 м', '7 м', '10 м', '9 м', '8 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63649057',
        text:
          'За сколько суток до начала проведения работ по строительству и ремонту подземных скважин, предназначенных для закачивания теплоносителя, на участках, где ведется нагнетание пара в пласт, должно быть прекращено закачивание теплоносителя в соседние скважины?',
        answers: [
          'Не позднее чем за сутки до начала работ',
          'Не позднее чем за 5 суток до начала работ',
          'Не позднее чем за 10 суток до начала работ',
          'Не позднее чем за 15 суток до начала работ',
        ],
        correctAnswers: ['Не позднее чем за 10 суток до начала работ'],
      },
      {
        code: '63649058',
        text:
          'В соответствии с каким документом осуществляются ремонтные работы нефтяных и газовых скважин?',
        answers: [
          'В соответствии с рабочей документацией на строительство и реконструкцию скважин',
          'На основании планов работ по текущему, капитальному ремонтам и реконструкции нефтяных и газовых скважин, порядок разработки и условия согласования которого устанавливаются организацией, эксплуатирующей ОПО',
          'На основании плана работ по текущему, капитальному ремонту и реконструкции нефтяных и газовых скважин, разработанного подрядной организацией, осуществляющей ремонтные работы',
        ],
        correctAnswers: [
          'На основании планов работ по текущему, капитальному ремонтам и реконструкции нефтяных и газовых скважин, порядок разработки и условия согласования которого устанавливаются организацией, эксплуатирующей ОПО',
        ],
      },
      {
        code: '63649059',
        text:
          'Разрешается ли совместная прокладка электрических кабелей и трубопроводов?',
        answers: [
          'Разрешается по согласованию с органом Ростехнадзора',
          'Разрешается по согласованию с заказчиком проекта',
          'Запрещается',
          'Разрешается без согласований',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63649060',
        text:
          'Кто допускается к руководству и ведению работ по ремонту и реконструкции скважин?',
        answers: [
          'Лица, имеющие опыт работ по ремонту и реконструкции скважин',
          'Лица, прошедшие проверку знаний в области промышленной безопасности, требования к профессиональному образованию не предъявляются',
          'Лица, имеющие профессиональное образование по специальности, требования к прохождению проверки знаний в области промышленной безопасности не предъявляются',
          'Лица, имеющие профессиональное образование, соответствующее занимаемой должности, и аттестованные в области промышленной безопасности',
        ],
        correctAnswers: [
          'Лица, имеющие профессиональное образование, соответствующее занимаемой должности, и аттестованные в области промышленной безопасности',
        ],
      },
      {
        code: '63649061',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Производить подготовку объекта к проведению газоопасных работ',
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
          'Непосредственно участвовать в выполнении газоопасных работ',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63649062',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом, исключающим возможность искрообразования',
          'Только с разрешения представителя газоспасательной службы и при обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63649063',
        text:
          'В соответствии с требованиями каких документов производится подготовка площадки, монтаж и эксплуатация колтюбинговых установок?',
        answers: [
          'Только в соответствии с техническими условиями',
          'В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя',
          'В соответствии с техническими условиями и инструкцией по производству работ, утвержденной Ростехнадзором',
          'Только в соответствии с инструкцией по производству работ, утвержденной Ростехнадзором',
        ],
        correctAnswers: [
          'В соответствии с техническими условиями и инструкцией по эксплуатации завода-изготовителя',
        ],
      },
      {
        code: '63649064',
        text:
          'В каких случаях разрешается проводить текущий и капитальный ремонт скважин без их предварительного глушения?',
        answers: [
          'Ни в каких',
          'В случаях, когда скважины расположены не на кустовых площадках',
          'В случаях, когда скважины оборудованы специальными устройствами, исключающими возможность ГНВП',
          'В случаях, когда скважины оборудованы клапаном-отсекателем и на месторождении с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
        ],
        correctAnswers: [
          'В случаях, когда скважины оборудованы клапаном-отсекателем и на месторождении с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
        ],
      },
      {
        code: '63649065',
        text:
          'При каких погодных условиях запрещаются спуско-подъемные операции при ремонте скважин?',
        answers: [
          'При ветре со скоростью 15 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 50 м',
          'При ветре со скоростью 10 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 40 м',
          'При ветре со скоростью 5 м/с и более, во время ливня или сильного снегопада',
          'При ветре со скоростью 7 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 30 м',
        ],
        correctAnswers: [
          'При ветре со скоростью 15 м/с и более, во время ливня, сильного снегопада и тумана с видимостью менее 50 м',
        ],
      },
      {
        code: '63649066',
        text:
          'Какую информацию, кроме плана работ по ремонту скважины, необходимо предоставить при передаче газлифтной скважины в текущий, капитальный ремонт?',
        answers: [
          'Схему расположения подземных и надземных коммуникаций',
          'Положение по одновременному ведению работ на кусте скважины',
          'Схему установки и обвязки противовыбросового оборудования',
          'План-схему газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин',
        ],
        correctAnswers: [
          'План-схему газонефтепроводных коммуникаций и обвязки всех скважин куста с нанесенными размерами и порядком отключения газонагнетательных скважин',
        ],
      },
      {
        code: '63649067',
        text:
          'На содержание каких веществ проводится анализ воздушной среды для оценки качества выполнения подготовительных мероприятий перед началом проведения газоопасной работы с записью результатов в наряде-допуске?',
        answers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
          'На содержание кислорода, пыли, бензапирена, фенола, а также взрывопожароопасных веществ',
          'На содержание кислорода, водорода, азота, а также вредных веществ',
        ],
        correctAnswers: [
          'На содержание кислорода (в закрытых помещениях, внутри емкостей (аппаратов), котлованах) и опасных веществ, указанных в перечне газоопасных работ, согласно месту и характеру работы',
        ],
      },
      {
        code: '63649068',
        text:
          'Какие скважины перед началом ремонтных работ подлежат обязательному глушению?',
        answers: [
          'Все скважины с пластовым давлением выше гидростатического и скважины, в которых (согласно выполненным расчетам) сохраняются условия фонтанирования или газонефтеводопроявлений при пластовых давлениях ниже гидростатического',
          'Скважины, оборудованные глубинными клапанами-отсекателями',
          'Скважины на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
          'Правилами не регламентируется',
        ],
        correctAnswers: [
          'Все скважины с пластовым давлением выше гидростатического и скважины, в которых (согласно выполненным расчетам) сохраняются условия фонтанирования или газонефтеводопроявлений при пластовых давлениях ниже гидростатического',
        ],
      },
      {
        code: '63649069',
        text:
          'На какое давление должен быть опрессован цементный мост совместно с обсадной колонной?',
        answers: [
          'На давление, превышающее не менее чем на 10% давление при возникновении газонефтеводопроявлений или при эксплуатации',
          'На давление, превышающее не менее чем на 5% давление при возникновении газонефтеводопроявлений или при эксплуатации',
          'На давление, превышающее не менее чем на 7% давление при возникновении газонефтеводопроявлений или при эксплуатации',
          'На давление, превышающее не менее чем на 8% давление при возникновении газонефтеводопроявлений или при эксплуатации',
        ],
        correctAnswers: [
          'На давление, превышающее не менее чем на 10% давление при возникновении газонефтеводопроявлений или при эксплуатации',
        ],
      },
      {
        code: '63649070',
        text:
          'Какие из документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Проект производства ремонтных работ',
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63649071',
        text:
          'Какое напряжение необходимо для энергообеспечения электрооборудования агрегатов для ремонта скважин?',
        answers: [
          'Не более 450 В',
          'Не более 500 В',
          'Не более 400 В',
          'Не более 600 В',
        ],
        correctAnswers: ['Не более 400 В'],
      },
      {
        code: '63649072',
        text:
          'Кто утверждает перечень работ, осуществляемых по наряду-допуску, порядок оформления нарядов-допусков, перечни должностей специалистов, имеющих право руководить этими работами?',
        answers: [
          'Ответственный руководитель вышестоящей организации',
          'Начальник территориального органа Ростехнадзора',
          'Руководитель организации или уполномоченное им лицо',
          'Директор регионального центра Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Ответственный исполнитель работ',
        ],
        correctAnswers: ['Руководитель организации или уполномоченное им лицо'],
      },
      {
        code: '63649073',
        text:
          'В каком из перечисленных положений нарушены требования по безопасному проведению ремонтных работ?',
        answers: [
          'Перед началом ремонтных работ на рабочих местах должны быть вывешены плакаты и предупредительные надписи по безопасному ведению данных работ',
          'Ремонтные работы разрешается проводить после сдачи установки в ремонт по акту отдельного оборудования или технологических блоков',
          'К проведению ремонтных работ аппаратов, резервуаров и оборудования, где имеется или может возникнуть повышенная производственная опасность, можно приступать только после оформления наряда-допуска с указанием ответственных лиц за подготовку и проведение ремонтных работ',
          'Для проведения ремонтных работ на высоте должны быть предусмотрены временные подмости и леса. Доски настилов должны неплотно прилегать одна к другой. Для устройства подмостей должны применяться доски толщиной не более 3 см',
        ],
        correctAnswers: [
          'Для проведения ремонтных работ на высоте должны быть предусмотрены временные подмости и леса. Доски настилов должны неплотно прилегать одна к другой. Для устройства подмостей должны применяться доски толщиной не более 3 см',
        ],
      },
      {
        code: '63649074',
        text:
          'С кем согласовывается Положение по одновременному ведению работ на кусте?',
        answers: [
          'С Государственной инспекцией труда',
          'С Федеральной службой по надзору в сфере природопользования',
          'С Ростехнадзором',
          'Согласовывается с ПАСФ',
        ],
        correctAnswers: ['Согласовывается с ПАСФ'],
      },
      {
        code: '63649075',
        text:
          'Кем определяются критерии предельных состояний и критерии вывода из эксплуатации оборудования, инструментов, контрольно-измерительных приборов?',
        answers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
          'Определяются Ростехнадзором или его территориальным органом на основании экспертизы промышленной безопасности',
          'Определяются эксплуатирующей организацией или ее структурным подразделением на основании диагностирования',
        ],
        correctAnswers: [
          'Определяются изготовителем и вносятся в эксплуатационную документацию изготовителя или разработчика технического устройства',
        ],
      },
      {
        code: '63649076',
        text:
          'Кто утверждает перечень скважин по месторождениям с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины?',
        answers: [
          'Противофонтанная служба',
          'Организация, эксплуатирующая ОПО (заказчик)',
          'Территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['Организация, эксплуатирующая ОПО (заказчик)'],
      },
      {
        code: '63649077',
        text:
          'Какие виды работ относятся к реконструкции нефтяных и газовых скважин в соответствии с Правилами безопасности в нефтяной и газовой промышленности?',
        answers: [
          'Работы по восстановлению работоспособности скважин, утраченной в результате аварии или инцидента',
          'Работы по изоляции пластов',
          'Работы по оптимизации режима эксплуатации нефтяных и газовых скважин',
          'Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции',
        ],
        correctAnswers: [
          'Комплекс работ по восстановлению работоспособности скважин, связанный с изменением их конструкции',
        ],
      },
      {
        code: '63649078',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К I группе',
          'К II группе',
          'К группе работ средней степени опасности',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63649079',
        text:
          'Какой мост должен быть установлен в обсадной колонне перед зарезкой нового ствола?',
        answers: ['Асбестовый', 'Цементный', 'Каменный', 'Асфальтобетонный'],
        correctAnswers: ['Цементный'],
      },
      {
        code: '63649080',
        text:
          'Разрешается ли проведение спуско-подъемных операций, а также ведение ремонтных работ, связанных с нагрузкой на мачту (вышку), независимо от глубины скважины без исправного индикатора веса?',
        answers: [
          'Запрещается',
          'Разрешается',
          'Разрешается только при согласовании с Ростехнадзором',
          'Разрешается только при письменном обосновании в проектной документации',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63649081',
        text:
          'Чем должны быть оборудованы и оснащены колтюбинговые установки с гибкими непрерывными трубами?',
        answers: [
          'Только комплектом устройств на устье скважины для спуска труб под давлением, рассчитанным на максимально возможное устьевое давление',
          'Только системой контроля утонения труб',
          'Только системой контроля и регистрации давления при прокачивании через гибкую трубу жидкостей в процессе технологических операций',
          'Всеми перечисленными устройствами',
        ],
        correctAnswers: ['Всеми перечисленными устройствами'],
      },
      {
        code: '63649082',
        text:
          'Разрешается ли чистка песчаных пробок желонкой в фонтанных скважинах, в скважинах с возможными газонефтеводопроявлениями, а также в скважинах с наличием сернистого водорода?',
        answers: [
          'Разрешается',
          'Разрешается по согласованию с органами Ростехнадзора',
          'Допускается по письменному разрешению руководителя организации',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63649083',
        text:
          'Чем должны быть заглушены скважины, в продукции которых содержится сернистый водород, создающий угрозу сульфидно-коррозионного растрескивания металла обсадных труб, оборудования и лифтовых колонн?',
        answers: [
          'Жидкостью, содержащей нейтрализатор сернистого водорода',
          'Жидкостью, содержащей щелочи',
          'Жидкостью, содержащей кислоты',
          'Жидкостью, содержащей нефтепродукты',
        ],
        correctAnswers: [
          'Жидкостью, содержащей нейтрализатор сернистого водорода',
        ],
      },
      {
        code: '63649084',
        text:
          'Как Правила безопасности в нефтяной и газовой промышленности регламентируют посещение работниками горных выработок, температура рудничной атмосферы в которых превышает значения 25 °C при относительной влажности свыше 90%?',
        answers: [
          'Посещение допускается не менее чем двумя работниками',
          'Посещение допускается при скорости воздуха не менее 0,05 м/с',
          'Посещение не допускается',
          'Посещение допускается на время не более 30 минут',
        ],
        correctAnswers: [
          'Посещение допускается не менее чем двумя работниками',
        ],
      },
      {
        code: '63649085',
        text:
          'Что из перечисленного разрешено использовать для промывки деталей оборудования?',
        answers: ['Лигроиновый растворитель', 'Керосин', 'Бензин', 'Ацетон'],
        correctAnswers: ['Керосин'],
      },
      {
        code: '63649086',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации (филиала организации)',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63649087',
        text:
          'Каков максимальный срок единовременного пребывания работающего в средствах защиты органов дыхания?',
        answers: ['10 минут', '15 минут', '30 минут', '60 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63649088',
        text:
          'Что должно быть установлено перед зарезкой бокового ствола в обсадной колонне?',
        answers: [
          'Цементный мост',
          'Заглушка',
          'Дополнительный (запасной) кабель',
          'Клапан-отсекатель',
        ],
        correctAnswers: ['Цементный мост'],
      },
      {
        code: '63649089',
        text:
          'Какой минимальный срок хранения установлен для журнала регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          '3 месяца со дня его окончания',
          '6 месяцев со дня его окончания',
          '1 год со дня его окончания',
          '3 года со дня его окончания',
        ],
        correctAnswers: ['6 месяцев со дня его окончания'],
      },
      {
        code: '63649090',
        text:
          'Какие средства могут применяться для контроля состава рудничной атмосферы при абсолютной газообильности горных выработок менее 3 м³/мин?',
        answers: [
          'Переносные и (или) стационарные средства контроля',
          'Только стационарные средства контроля, применение переносных средств не допускается',
          'Выбор средств контроля суммарного содержания углеводородных газов и паров жидких углеводородов в горных выработках осуществляет технический руководитель обособленного структурного подразделения',
          'Универсальные средства контроля',
        ],
        correctAnswers: ['Переносные и (или) стационарные средства контроля'],
      },
      {
        code: '63649091',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О самочувствии',
          'О наличии медицинских противопоказаний к работе',
          'О знании правил оказания первой медицинской помощи',
          'О знании персональных действий при пожаре',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63649092',
        text:
          'С какими документами должна быть ознакомлена бригада, осуществляющая ремонт и реконструкцию нефтяных и газовых скважин, перед началом работ?',
        answers: [
          'С инструкциями по видам работ',
          'С нарядом-допуском',
          'С планом работ, ПЛА и возможными осложнениями и авариями',
        ],
        correctAnswers: [
          'С планом работ, ПЛА и возможными осложнениями и авариями',
        ],
      },
      {
        code: '63649093',
        text:
          'Кто проводит отключение газопроводов и демонтаж газовой обвязки передаваемой в ремонт газлифтной скважины?',
        answers: [
          'Служба заказчика',
          'Специальная служба ремонтной организации',
          'Соответствующая служба пуско-наладочного управления',
          'Буровая бригада',
        ],
        correctAnswers: ['Служба заказчика'],
      },
      {
        code: '63649094',
        text:
          'В каких случаях ликвидированные скважины подлежат реконструкции?',
        answers: [
          'В случае, когда необходимо повысить добычу месторождения',
          'В случае, когда скважина была ликвидирована по техническим причинам',
          'В случае, когда комиссия, создаваемая организацией, эксплуатирующей ОПО, принимает решение по реконструкции скважин',
        ],
        correctAnswers: [
          'В случае, когда комиссия, создаваемая организацией, эксплуатирующей ОПО, принимает решение по реконструкции скважин',
        ],
      },
      {
        code: '63649095',
        text: 'Какой должна быть минимальная ширина настила приемных мостков?',
        answers: ['0,9 м', '1,0 м', '0,8 м', '0,5 м'],
        correctAnswers: ['1,0 м'],
      },
      {
        code: '63649096',
        text:
          'Какие требования предъявляются к емкости для долива скважины при выполнении ремонтных работ?',
        answers: [
          'Емкость должна быть обвязана с устьем скважины с таким расчетом, чтобы обеспечивался постоянный долив жидкости в скважину самотеком или принудительно с использованием насоса',
          'Объем емкости долива должен быть не менее 4,5 м³',
          'Емкость должна быть оборудована уровнемером, имеющим градуировку с ценой деления 0,2 м³',
          'Емкость должна устанавливаться на расстоянии не менее 10 м от устья ремонтируемой скважины, в зоне видимости бурильщика капитального ремонта скважин (оператора текущего ремонта скважин)',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63649097',
        text:
          'При какой скорости движения осматриваются поврежденные участки канатов, а также стыковые соединения резинотросовых канатов?',
        answers: [
          'При неподвижном канате',
          'На скорости, обеспечивающий 100%-ный визуальный контроль за целостностью каната',
          '1 м/с',
          '0,3 м/с',
        ],
        correctAnswers: ['При неподвижном канате'],
      },
      {
        code: '63649098',
        text: 'Где фиксируются сведения о проведении вводного инструктажа?',
        answers: [
          'В актах',
          'В специальных журналах',
          'В протоколе о прохождении инструктажа',
          'В приказе по предприятию',
        ],
        correctAnswers: ['В специальных журналах'],
      },
      {
        code: '63649099',
        text:
          'Что из перечисленного допускается при проведении газоопасных работ I группы?',
        answers: [
          'Совмещение газоопасных работ и огневых работ в одном помещении в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Совмещение газоопасных работ и огневых работ в непосредственной близости на открытой площадке в случае возможного выделения в зону работ пожаровзрывоопасных веществ',
          'Увеличивать объем и характер работ, предусмотренных нарядом-допуском на проведение газоопасных работ',
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
        correctAnswers: [
          'Выполнение газоопасных работ бригадой исполнителей в составе двух человек',
        ],
      },
      {
        code: '63649100',
        text:
          'Кто определяет структурные подразделения, на которые возложены полномочия по согласованию перечня газоопасных работ?',
        answers: [
          'Руководитель службы производственного контроля или лицо, ответственное за осуществление производственного контроля',
          'Руководитель эксплуатирующей организации совместно с руководителем аварийно-спасательной службы',
          'Главный инженер эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63649101',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63649102',
        text:
          'Когда ремонтной бригаде должна выдаваться схема расположения подземных и наземных коммуникаций вокруг ремонтируемой скважины?',
        answers: [
          'Не менее чем за трое суток до начала производства работ',
          'Не менее чем за двое суток до начала производства работ',
          'Не менее чем за сутки до начала производства работ',
          'Непосредственно перед началом производства работ',
        ],
        correctAnswers: [
          'Не менее чем за трое суток до начала производства работ',
        ],
      },
      {
        code: '63649103',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63649104',
        text:
          'Какие требования предъявляются к кронблоку агрегата для ремонта скважин?',
        answers: [
          'Кронблок должен иметь один ролик под канат диаметром 13 мм вспомогательной лебедки, два ролика под канат диаметром не менее 10 мм для подвески машинных ключей и приспособление для подвески гидравлического ключа',
          'Кронблок должен иметь два ролика под канат диаметром 10 мм вспомогательной лебедки, два ролика под канат диаметром 5 мм для подвески машинных ключей',
          'Кронблок должен иметь один ролик под канат диаметром 11 мм вспомогательной лебедки, один ролик под канат диаметром 7 мм для подвески машинных ключей',
          'Кронблок должен иметь только приспособление для подвески гидравлического ключа',
        ],
        correctAnswers: [
          'Кронблок должен иметь один ролик под канат диаметром 13 мм вспомогательной лебедки, два ролика под канат диаметром не менее 10 мм для подвески машинных ключей и приспособление для подвески гидравлического ключа',
        ],
      },
      {
        code: '63649105',
        text:
          'Каким способом должна производиться намотка и размотка кабеля погружного центробежного электронасоса после его обесточивания?',
        answers: [
          'Ручным способом',
          'Полностью механизированным способом',
          'Комбинированным способом',
          'Любым удобным в данной ситуации способом',
        ],
        correctAnswers: ['Полностью механизированным способом'],
      },
      {
        code: '63649106',
        text:
          'В какие сроки необходимо пересматривать план мероприятий по локализации и ликвидации последствий аварий (ПЛА)?',
        answers: [
          'В сроки, установленные Правительством Российской Федерации',
          'ПЛА пересмотру не подлежит',
          'Каждый раз, когда изменяется технология и условия работы',
          'Раз в пять лет',
        ],
        correctAnswers: [
          'В сроки, установленные Правительством Российской Федерации',
        ],
      },
      {
        code: '63649107',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательства Российской Федерации',
          'Не моложе 18 лет',
          'Прошедшие обучение приемам и методам проведения работ',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63649108',
        text:
          'Разрешается ли последовательно включать в заземляющее устройство несколько заземляемых объектов?',
        answers: [
          'Разрешается при поступлении разрешения от главного энергетика организации',
          'Разрешается, при получении одобрения от главного инженера организации',
          'Разрешается в исключительных случаях и по согласованию с территориальным органом Ростехнадзора',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63649109',
        text:
          'Какие виды ремонта нефтяных и газовых скважин определены Правилами безопасности в нефтяной и газовой промышленности?',
        answers: [
          'Ремонты, связанные с изменением конструкции нефтяных и газовых скважин',
          'Ремонты по восстановлению работоспособности скважин',
          'Ремонтные работы по переводу скважин на другие горизонты',
          'Текущий и капитальный ремонт нефтяных и газовых скважин',
        ],
        correctAnswers: [
          'Текущий и капитальный ремонт нефтяных и газовых скважин',
        ],
      },
      {
        code: '63649110',
        text:
          'С какой периодичностью работники, осуществляющие непосредственное руководство и выполнение работ по бурению, освоению, ремонту, реконструкции, консервации и ликвидации скважин, ведению геофизических и ПВР на скважинах, должны дополнительно проходить проверку знаний по контролю и управлению скважиной при ГНВП?',
        answers: [
          'Один раз в два года',
          'Один раз в три года',
          'Один раз в пять лет',
          'Один раз в год',
        ],
        correctAnswers: ['Один раз в два года'],
      },
      {
        code: '63649111',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в полгода',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 2 года',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63649112',
        text:
          'Чем должны оснащаться передвижные насосные установки, предназначенные для работы на скважинах?',
        answers: [
          'Приборами, контролирующими основные параметры технологического процесса',
          'Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления',
          'Запорными, предохранительными и регулирующими устройствами',
        ],
        correctAnswers: [
          'Запорными и предохранительными устройствами, приборами, контролирующими основные параметры технологического процесса, выведенные на пульт управления',
        ],
      },
      {
        code: '63649113',
        text:
          'Какая из перечисленных подготовительных работ к проведению газоопасных работ в пределах площади, где возможно поступление паров и газов опасных веществ, указана неверно?',
        answers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
          'Обозначается (ограждается) место в пределах площади, где возможно поступление паров и газов опасных веществ',
          'Выставляются посты в целях исключения допуска посторонних лиц в опасную зону по решению лица, ответственного за подготовку газоопасной работы',
        ],
        correctAnswers: [
          'Устанавливаются предупреждающие знаки "Опасная зона" или "Стой! Опасно для жизни"',
        ],
      },
      {
        code: '63649114',
        text:
          'Каким должно быть минимальное расстояние между проложенными электрическими кабелями и трубопроводами?',
        answers: ['0,4 м', '0,5 м', '0,1 м', '0,2 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63649115',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Технологический регламент',
          'Инструкции по эксплуатации и безопасному проведению ремонтных работ',
          'Проект производства ремонтных работ',
          'Требования к проведению огневых и газоопасных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63649116',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
        ],
      },
      {
        code: '63649117',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63649118',
        text:
          'Какое из перечисленных требований при назначении специалиста ответственным за выполнение огневых работ указано неверно?',
        answers: [
          'Прохождение обучения по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации ответственным за обеспечение пожарной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности в объеме общих требований промышленной безопасности',
        ],
      },
      {
        code: '63649119',
        text:
          'Какую информацию должен содержать план работ по проведению ремонта скважин?',
        answers: [
          'Только сведения по пластовому давлению и внутрискважинному оборудованию',
          'Только сведения о конструкции и состоянии скважины',
          'Только перечень планируемых технологических операций',
          'План должен содержать всю перечисленную информацию',
        ],
        correctAnswers: ['План должен содержать всю перечисленную информацию'],
      },
      {
        code: '63649120',
        text:
          'Куда (кому) лицо, ответственное за проведение газоопасных работ передает наряд-допуск после его закрытия?',
        answers: [
          'Главному инженеру, руководителю службы производственного контроля, а также в ГСС',
          'В ГСС и аварийно-спасательную службу организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф)',
        ],
      },
      {
        code: '63649121',
        text:
          'Каким образом организуется проветривание тупиковых горных выработок?',
        answers: [
          'Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводятся закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин',
          'Суммарная концентрация углеводородных газов не должна превышать 15% от НКПР смеси или 0,5% (по объему)',
          'Струя воздуха, исходящая из тупиковой горной выработки, должна попадать в горные выработки со свежей струей воздуха, проветривающей горные выработки, в которых проводятся закачка теплоносителя в пласт',
          'Расстояние от конца вентиляционных труб до тупиковой горной выработки, проветриваемой вентиляторной установкой, должно быть не более 30 м',
        ],
        correctAnswers: [
          'Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводятся закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин',
        ],
      },
      {
        code: '63649122',
        text:
          'До какого уровня должно быть снижено давление в трубном и затрубном пространствах перед разборкой устьевой арматуры скважины?',
        answers: [
          'До номинального',
          'До минимального',
          'До атмосферного',
          'До пробного',
        ],
        correctAnswers: ['До атмосферного'],
      },
      {
        code: '63649123',
        text:
          'Кто должен руководить передвижением агрегатов по ремонту скважин и транспортированию оборудования на скважину?',
        answers: [
          'Любой специалист',
          'Ответственное лицо',
          'Руководитель организации',
          'Специалист по охране труда',
        ],
        correctAnswers: ['Ответственное лицо'],
      },
      {
        code: '63649124',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
          'Эксплуатирующая организация должна согласовать с подрядной организацией расстановку знаков, обозначающих границы земляных работ',
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63649125',
        text:
          'Допускается ли проведение текущих ремонтов скважин без их предварительного глушения?',
        answers: [
          'Не допускается',
          'Допускается, если разработаны и согласованы с органами Ростехнадзора',
          'Допускается на скважинах, оборудованных глубинными клапанами-отсекателями, и месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
          'Допускается в любом случае',
        ],
        correctAnswers: [
          'Допускается на скважинах, оборудованных глубинными клапанами-отсекателями, и месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
        ],
      },
      {
        code: '63649126',
        text:
          'Какой должен использоваться комплект вспомогательного инструмента при глушении подземной скважины в горной выработке, из которой она пробурена?',
        answers: [
          'Латунный или омедненный',
          'Чугунный',
          'Бронзовый',
          'Стальной',
        ],
        correctAnswers: ['Латунный или омедненный'],
      },
      {
        code: '63649127',
        text:
          'Какие из перечисленных требований к перечню постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты, указаны верно?',
        answers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
          'Указываются места выполнения огневых работ, виды, количество средств индивидуальной защиты пожаротушения, лица, ответственные за производственный контроль',
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество средств индивидуальной защиты, лица, ответственные за противопожарное состояние организации',
        ],
        correctAnswers: [
          'Указываются привязка каждого постоянного места выполнения огневых работ к территории или помещению объекта, виды, количество первичных средств пожаротушения, ответственные за противопожарное состояние указанных мест и безопасное проведение огневых работ лица',
        ],
      },
      {
        code: '63649128',
        text:
          'В каких случаях при реконструкции и ремонте скважин на рабочей площадке проводится контроль состояния газовоздушной среды?',
        answers: [
          'В случае, когда возможно газонефтеводопроявление',
          'В случае, когда работы ведутся на кустовой площадке',
          'Всегда при проведении работ по реконструкции и ремонту скважин на рабочей площадке проводится контроль состояния газовоздушной среды с регистрацией в журнале контроля',
        ],
        correctAnswers: [
          'Всегда при проведении работ по реконструкции и ремонту скважин на рабочей площадке проводится контроль состояния газовоздушной среды с регистрацией в журнале контроля',
        ],
      },
      {
        code: '63649129',
        text:
          'Требования какого документа обеспечивают безопасность технологических процессов на объектах добычи, сбора и подготовки нефти, газа и газового конденсата?',
        answers: [
          'Руководства по эксплуатации оборудования',
          'Проектной документации на эксплуатацию опасного производственного объекта',
          'Федеральных норм и правил в области промышленной безопасности',
          'ТР на каждый технологический процесс опасного производственного объекта',
        ],
        correctAnswers: [
          'ТР на каждый технологический процесс опасного производственного объекта',
        ],
      },
      {
        code: '63649130',
        text:
          'Что из нижеперечисленного подлежит заземлению при ведении ремонтных работ?',
        answers: [
          'Только корпусы генераторов передвижных электростанций',
          'Только каркасы распределительных щитов станций управления',
          'Только емкости под раствор для глушения или долива скважины',
          'Только емкости горюче-смазочных материалов',
          'Все вышеперечисленное',
        ],
        correctAnswers: ['Все вышеперечисленное'],
      },
      {
        code: '63649131',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии лица, ответственного за проведение работ',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии технического руководителя организации и начальника смены',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63649132',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии с требованиями, установленными в Правилах пожарной безопасности',
          'В соответствии с нарядом-допуском на выполнение огневых работ',
          'В соответствии с технологическим регламентом',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63649133',
        text:
          'Что необходимо предпринимать при подъеме лифтовых (бурильных) труб с сифоном (не снят клапан, "шламование" лифтовых колонн и другие возможные причины)?',
        answers: [
          'Производить постоянный долив скважины с поддержанием уровня жидкости на устье',
          'Делать технологические перерывы через каждые 10 труб',
          'Организовывать дежурство противофонтанной службы',
          'Прекратить подъем и принять меры по ликвидации сифона',
        ],
        correctAnswers: [
          'Производить постоянный долив скважины с поддержанием уровня жидкости на устье',
        ],
      },
      {
        code: '63649134',
        text:
          'С какой периодичностью проводится ревизия и наладка электрической части и аппаратуры автоматизированной подъемной установки после ввода ее в эксплуатацию?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в 6 месяцев'],
      },
      {
        code: '63649135',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем либо руководителем филиала (дочернего общества)',
          'С газоспасательной службой и службой охраны труда',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63649136',
        text:
          'Каким запасом жидкости должна быть обеспечена скважина для предотвращения и ликвидации возможных газонефтеводопроявлений?',
        answers: [
          'Непосредственно на скважине в блоке долива не менее 4,5 м³ и не менее двух объемов скважины, находящихся непосредственно на скважине или на узле приготовления раствора',
          'Не менее 1,5 объемов скважины, находящихся непосредственно на скважине или на узле приготовления раствора',
          'Непосредственно на скважине в блоке долива не менее 1,5 м³',
          'Непосредственно на скважине в блоке долива не менее 2,5 м³',
        ],
        correctAnswers: [
          'Непосредственно на скважине в блоке долива не менее 4,5 м³ и не менее двух объемов скважины, находящихся непосредственно на скважине или на узле приготовления раствора',
        ],
      },
      {
        code: '63649137',
        text:
          'Какие требования предъявляются к конструкции экранирующего устройства?',
        answers: [
          'Должна исключать возможность образования непроветриваемых зон',
          'Должна обеспечивать свободный доступ к узлам управления арматуры скважины',
          'Должны выполняться все перечисленные требования',
        ],
        correctAnswers: ['Должны выполняться все перечисленные требования'],
      },
      {
        code: '63649138',
        text:
          'Какие из перечисленных требований безопасности предъявляются при работах внутри емкости?',
        answers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
          'Если в действиях работающего внутри емкости наблюдаются отклонения от обычного поведения (признаки недомогания, попытка снять маску противогаза), к нему следует спустить медицинского работника для оказания первой помощи',
          'Запрещается подача материалов в емкость во время проведения газоопасных работ',
          'Допускается подача необходимых инструментов и материалов в емкость любым удобным для работников способом',
        ],
        correctAnswers: [
          'При возникновении обстоятельств, угрожающих безопасности работающего внутри емкости, работу следует прекратить, а работника из емкости эвакуировать',
        ],
      },
      {
        code: '63649139',
        text:
          'С чем из перечисленного должны быть ознакомлены работники, прибывшие на ОПО для работы?',
        answers: [
          'Только с действиями по конкретным видам тревог',
          'Только с опасными и вредными производственными факторами и признаками их проявления',
          'Только с другими вопросами, входящими в объем вводного инструктажа',
          'Со всем перечисленным',
        ],
        correctAnswers: ['Со всем перечисленным'],
      },
      {
        code: '63649140',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на требуемый для окончания работ срок',
          'Руководитель структурного подразделения не более чем на 1 рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск, не более чем на 1 дневную смену',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения или лицо, его замещающее, не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63649141',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель структурного подразделения или лицо, его замещающее',
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
          'Руководитель эксплуатирующей организации',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63649142',
        text:
          'Какой должна быть максимальная скорость подъема и спуска насосно-компрессорных труб с закрытым проходным сечением?',
        answers: ['0,35 м/с', '0,38 м/с', '0,28 м/с', '0,25 м/с'],
        correctAnswers: ['0,25 м/с'],
      },
      {
        code: '63649143',
        text:
          'Какие требования предъявляются к работникам комплексных бригад при необходимости выполнения работ, требующих совмещения профессий?',
        answers: [
          'Работники должны пройти дополнительное обучение по охране труда',
          'Работники должны получить допуск к ведению таких работ',
          'Работники должны пройти дополнительное обучение по экологической безопасности',
          'Работники должны пройти обучение и получить соответствующую квалификацию по видам выполняемых работ, а также иметь допуски к самостоятельной работе по совмещаемым профессиям',
        ],
        correctAnswers: [
          'Работники должны пройти обучение и получить соответствующую квалификацию по видам выполняемых работ, а также иметь допуски к самостоятельной работе по совмещаемым профессиям',
        ],
      },
      {
        code: '63649144',
        text:
          'Какие требования предъявляются к техническим устройствам, которые вводятся   в эксплуатацию на опасных производственных объектах (далее - ОПО) после капитального ремонта, связанного с конструктивными изменениями?',
        answers: [
          'Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с разработчиком этого оборудования',
          'Документация на технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должна быть согласована с надзорными органами',
          'Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации',
        ],
        correctAnswers: [
          'Технические устройства, которые вводятся в эксплуатацию на ОПО после капитального ремонта, связанного с конструктивными изменениями, должны пройти приемо-сдаточные испытания, результаты которых оформляются актом эксплуатирующей организации',
        ],
      },
      {
        code: '63649145',
        text:
          'Каким образом должны быть устроены стеллажи передвижных или стационарных приемных мостков при ремонте скважин?',
        answers: [
          'Должны обеспечивать возможность укладки труб и штанг не более чем в 11 рядов',
          'Должны обеспечивать возможность укладки труб и штанг не более чем в 10 рядов',
          'Должны обеспечивать возможность укладки труб и штанг не более чем в 8 рядов',
          'Должны обеспечивать возможность укладки труб и штанг не более чем в 6 рядов',
        ],
        correctAnswers: [
          'Должны обеспечивать возможность укладки труб и штанг не более чем в 6 рядов',
        ],
      },
      {
        code: '63649146',
        text:
          'На какое давление должны быть опрессованы нагнетательные линии, собранные из труб с быстросъемными соединительными гайками и шарнирными коленями (угольниками)?',
        answers: [
          'На полуторакратное давление от максимального рабочего давления, предусмотренного планом работ',
          'На давление, равное максимальному рабочему давлению, предусмотренному планом работ',
          'На двукратное давление от максимального рабочего давления, предусмотренного планом работ',
          'На трехкратное давление от максимального рабочего давления, предусмотренного планом работ',
        ],
        correctAnswers: [
          'На полуторакратное давление от максимального рабочего давления, предусмотренного планом работ',
        ],
      },
      {
        code: '63649147',
        text:
          'Что должен сделать руководитель структурного подразделения, где будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63649148',
        text:
          'Каким образом должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63649149',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'До окончания работ на конкретном месте',
          'В течение одной смены',
          'По решению руководителя структурного подразделения, на объекте которого выполняются огневые работы',
        ],
        correctAnswers: ['В течение одной смены'],
      },
      {
        code: '63649150',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 15% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ выше 17% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63649151',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ и лица ответственные за подготовку к ремонтным работам',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63649152',
        text:
          'Какая информация дополнительно включается в план при ведении работ, связанных с проводкой боковых стволов?',
        answers: [
          'Только интервал вырезки "окна" в эксплуатационной колонне',
          'Только компоновки колонны труб и низа бурильной колонны',
          'Только тип породоразрушающего инструмента и его привода',
          'Только режимы проходки бокового ствола и утилизации выбуренной породы',
          'Только крепление пробуренного ствола (спуск фильтра, технологическая оснастка, сочленение фильтра с эксплуатационной колонной и другие технологические операции)',
          'Все перечисленное дополнительно включается в план работ',
        ],
        correctAnswers: [
          'Все перечисленное дополнительно включается в план работ',
        ],
      },
      {
        code: '63649153',
        text: 'В каком случае проводниковые канаты допускаются к эксплуатации?',
        answers: [
          'При износе наружных проволок более 50% их высоты',
          'Если на 100 м длины каната закрытой конструкции обнаружено два обрыва наружных проволок',
          'При наличии спайки обрыва наружной проволоки в канате закрытой конструкции, вышедшей из замка',
          'Допускаются во всех перечисленных случаях',
        ],
        correctAnswers: [
          'При наличии спайки обрыва наружной проволоки в канате закрытой конструкции, вышедшей из замка',
        ],
      },
      {
        code: '63649154',
        text:
          'Какие требования определены Правилами безопасности в нефтяной и газовой промышленности к установке агрегатов для ремонта скважин, оборудования?',
        answers: [
          'Агрегаты для ремонта скважин, оборудование устанавливаются на расстоянии от устья скважины, предусмотренном в плане проведения работ',
          'Агрегаты для ремонта скважин, оборудование должны устанавливаться на специальный настил',
          'Агрегаты для ремонта скважин, оборудование должны устанавливаться на передвижные или стационарные фундаменты, выполненные в соответствии с инструкциями по эксплуатации или документацией по обустройству кустов скважин',
        ],
        correctAnswers: [
          'Агрегаты для ремонта скважин, оборудование должны устанавливаться на передвижные или стационарные фундаменты, выполненные в соответствии с инструкциями по эксплуатации или документацией по обустройству кустов скважин',
        ],
      },
      {
        code: '63649155',
        text:
          'Какие из перечисленных действий, производимых при подготовке объекта к огневым работам, указаны неверно?',
        answers: [
          'Аппараты, машины и другое оборудование, на которых будут проводиться огневые работы, должны быть остановлены, освобождены от опасных веществ',
          'Электроприводы движущихся механизмов, которые находятся в зоне выполнения огневых работ, должны быть отключены от источников питания, отсоединены от этих механизмов видимым разрывом',
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
          'На пусковых устройствах должны быть вывешены плакаты "Не включать: работают люди!"',
        ],
        correctAnswers: [
          'Аппараты, машины, и другое оборудование, находящееся на расстоянии менее 1 м от границы опасной зоны, связанной с проведением огневых работ, и внутри нее, должны быть остановлены, освобождены от опасных веществ',
        ],
      },
      {
        code: '63649156',
        text:
          'Разрешается ли нахождение людей между устьем скважины и барабаном с кабелем погружного насоса при спуске (подъеме) насоса?',
        answers: [
          'Разрешается, если это вызвано производственной необходимостью',
          'Запрещается',
          'Разрешается по согласованию с территориальным органом Ростехнадзора',
          'Разрешается в случае возникновения аварийной ситуации',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63649157',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Исполнители должны пройти инструктаж',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Исполнители должны знать безопасные приемы работы и методы оказания первой помощи',
          'Исполнители должны уметь пользоваться СИЗ',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63649158',
        text:
          'Что необходимо сделать с электрокабелями, попадающими в зону перемещения и монтажа оборудования ремонтных бригад и освоения, при работе на кустах скважин, оборудованных центробежными насосами?',
        answers: [
          'Электрокабели должны быть обесточены',
          'Электрокабели необходимо снять с эстакад (стоек)',
          'Электрокабели должны быть закрыты кожухами (деревянными, металлическими), обеспечивающими сохранность изоляции и безопасность работающего персонала',
          'Необходимо провести все перечисленные мероприятия',
        ],
        correctAnswers: ['Необходимо провести все перечисленные мероприятия'],
      },
      {
        code: '63649159',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал эксплуатирующей организации',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63649160',
        text: 'Каким канатом должен быть обмотан промывочный шланг?',
        answers: [
          'Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1 - 1,5 м по всей длине шланга',
          'Стальным мягким канатом диаметром не менее 5 мм с петлями через каждые 2,5 м по всей длине шланга',
          'Стальным мягким канатом диаметром не менее 4 мм с петлями через каждые 2,5 м по всей длине шланга',
          'Стальным мягким канатом диаметром не менее 7 мм с петлями через каждые 2 м по всей длине шланга',
        ],
        correctAnswers: [
          'Стальным мягким канатом диаметром не менее 8 мм с петлями через каждые 1 - 1,5 м по всей длине шланга',
        ],
      },
      {
        code: '63649161',
        text:
          'В каких случаях допускается ремонт скважин на кусте без остановки соседней скважины?',
        answers: [
          'Допускается при условии осуществления и использования специальных мероприятий и технических средств, предусмотренных планом',
          'Не допускается ни в каких случаях',
          'Допускается по согласованию с органами Ростехнадзора',
          'Допускается по письменному разрешению главного инженера проекта',
        ],
        correctAnswers: [
          'Допускается при условии осуществления и использования специальных мероприятий и технических средств, предусмотренных планом',
        ],
      },
      {
        code: '63649162',
        text:
          'Какими документами определяется перечень постоянных мест проведения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Правилами пожарной безопасности',
          'Технологическим регламентом',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Организационно-распорядительными документами организации',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами организации',
        ],
      },
      {
        code: '63649163',
        text:
          'Что должно быть предусмотрено в оперативной части плана мероприятий по локализации и ликвидации последствий аварий (ПЛА)?',
        answers: [
          'Только мероприятия по спасению людей и ликвидации аварии',
          'Только все виды возможных аварий на данном объекте',
          'Только места нахождения средств для спасения людей и ликвидации аварий',
          'Только действия газоспасателей, пожарных и других подразделений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63649164',
        text:
          'В соответствии с какими требованиями должна проводиться оценка технического состояния агрегатов для ремонта скважин?',
        answers: [
          'В соответствии с требованиями проектной организации',
          'В соответствии с требованиями завода-изготовителя',
          'В соответствии с требованиями нормативных технических документов',
          'В соответствии с требованиями территориального управления Ростехнадзора',
          'В соответствии с требованиями эксплуатирующей организации',
        ],
        correctAnswers: [
          'В соответствии с требованиями нормативных технических документов',
        ],
      },
      {
        code: '63649165',
        text:
          'Каковы размеры опасной зоны вокруг устья скважины, которая устанавливается на время прострелочных работ?',
        answers: ['5 м', '10 м', '15 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63649166',
        text:
          'Что должен сделать руководитель структурного подразделения, на объекте которого будет проводиться газоопасная работа, при подготовке наряда-допуска на ее проведение?',
        answers: [
          'Обеспечить работников сертифицированным оборудованием, необходимым для проведения газоопасных работ',
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
          'Провести обучение и инструктаж персонала, который будет проводить газоопасные работы',
          'Определить структурные подразделения организации, с которыми будет взаимодействовать бригада исполнителей при проведении газоопасных работ',
        ],
        correctAnswers: [
          'Определить место и характер выполняемой газоопасной работы, разработать мероприятия по подготовке объекта к проведению газоопасных работ и последовательность их проведения, мероприятия, обеспечивающие безопасное проведение работ, определить СИЗ, установить режим работы',
        ],
      },
      {
        code: '63649167',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
          'Только специалист, обученный по пожарной безопасности в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Только руководитель структурного подразделения, где будет проводиться газоопасная работа, или его уполномоченный заместитель',
          'Любой из специалистов организации-заказчика (эксплуатирующей организации)',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63649168',
        text:
          'С какой периодичностью проводятся проверка износа рельсов и нивелирование профиля откаточных путей?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже двух раз в месяц',
          'Не реже одного раза в год',
          'Периодичность устанавливается техническим руководителем обособленного структурного подразделения',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63649169',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в электронном виде?',
        answers: [
          'Допускается при наличии разрешающих внутренних документов организации, определяющих порядок использования электронной подписи',
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Не допускается',
          'Допускается по решению руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63649170',
        text:
          'В каких случаях устье скважин на период ремонта оснащается противовыбросовым оборудованием?',
        answers: [
          'Во всех случаях',
          'Только при ведении работ на кустовых площадках',
          'В случаях возможных газонефтеводопроявлений на скважинах',
        ],
        correctAnswers: [
          'В случаях возможных газонефтеводопроявлений на скважинах',
        ],
      },
      {
        code: '63649171',
        text:
          'Каковы первоочередные действия бригады по ремонту скважин при обнаружении газонефтеводопроявления (ГНВП)?',
        answers: [
          'Покинуть рабочую площадку',
          'Подать сигнал тревоги и покинуть рабочую площадку',
          'Загерметизировать устье скважины и действовать в соответствии с ПЛА',
          'Действовать по инструкции, утвержденной пользователем недр',
        ],
        correctAnswers: [
          'Загерметизировать устье скважины и действовать в соответствии с ПЛА',
        ],
      },
      {
        code: '63649172',
        text:
          'С какой периодичностью должны проводиться работы по ревизии клапана-отсекателя?',
        answers: [
          'Не реже одного раза в три месяца',
          'Периодичность проведения должна соответствовать требованиям эксплуатационной документации',
          'Не реже одного раза в год',
          'Не реже одного раза в 18 месяцев',
        ],
        correctAnswers: [
          'Периодичность проведения должна соответствовать требованиям эксплуатационной документации',
        ],
      },
      {
        code: '63649173',
        text:
          'На каких скважинах разрешается проведение капитальных ремонтов без их предварительного глушения?',
        answers: [
          'На любых скважинах',
          'Только на скважинах, оборудованных клапанами-отсекателями',
          'На скважинах, оборудованных клапанами-отсекателями, и на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
          'На скважинах, расположенных на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
        ],
        correctAnswers: [
          'На скважинах, оборудованных клапанами-отсекателями, и на месторождениях с горно-геологическими условиями, исключающими возможность самопроизвольного поступления пластового флюида к устью скважины',
        ],
      },
      {
        code: '63649174',
        text:
          'Кто имеет право на подключение переносных светильников и разводку кабелей в полевых условиях?',
        answers: [
          'Только электромонтер',
          'Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет квалификационную группу не ниже второй',
          'Рабочие бригады, прошедшие соответствующий инструктаж',
          'Правилами не регламентируется',
        ],
        correctAnswers: [
          'Электромонтер и рабочий бригады или двое рабочих бригады, прошедшие соответствующий инструктаж, при условии, что один из них имеет квалификационную группу не ниже второй',
        ],
      },
      {
        code: '63649175',
        text:
          'Какие требования к атмосфере в горных выработках нефтяных шахт указаны верно?',
        answers: [
          'Максимально допустимая концентрация диоксида углерода в рудничном воздухе в горных выработках с исходящей струей крыла, горизонта и шахты составляет 0,75%',
          'Максимально допустимая концентрация водорода в зарядных камерах составляет 0,5%',
          'Концентрация кислорода в воздухе в горных выработках нефтяных шахт должна составлять не менее 20% (по объему)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63649176',
        text:
          'Чем должны владеть работники при проведении ремонтных работ нефтяных и газовых скважин?',
        answers: [
          'Приемами оказания первой помощи пострадавшим при несчастных случаях',
          'Приемами оказания врачебной помощи',
          'Основными понятиями технологии проведения буровых работ',
          'Знаниями об опасных свойствах углеводородов',
        ],
        correctAnswers: [
          'Приемами оказания первой помощи пострадавшим при несчастных случаях',
        ],
      },
      {
        code: '63649177',
        text:
          'Кому лицо, ответственное за проведение газоопасных работ, передает наряд-допуск после его закрытия?',
        answers: [
          'Главному инженеру, руководителю службы производственного контроля, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
          'Аварийно-спасательной службе организации',
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
        correctAnswers: [
          'Руководителю структурного подразделения или его заместителю, а также в ПАСС(Ф) или иную производственную службу, на которую возложены данные полномочия в соответствии с документами эксплуатирующей организации (филиала организации)',
        ],
      },
      {
        code: '63649178',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
          'Допускаются по решению руководителя эксплуатирующей организации',
          'Допускаются при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Не допускаются',
        ],
        correctAnswers: [
          'Допускаются, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия его хранения в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63649179',
        text:
          'Кто должен присутствовать при освоении скважины после завершения ремонтных работ?',
        answers: [
          'Представитель заказчика',
          'Представитель проектной организации',
          'Представитель территориального органа Ростехнадзора',
          'Представитель экспертной организации',
        ],
        correctAnswers: ['Представитель заказчика'],
      },
    ],
    63657: [
      {
        code: '63657000',
        text:
          'При каких признаках предельных состояний допускается эксплуатация скважин с межколонным давлением? Выберите два правильных варианта ответов.',
        answers: [
          'Расход межколонного флюида из межколонного пространства при установившемся режиме стравливания более 1000 м³/сут для газовой фазы или 1 м³/сут для жидкой фазы',
          'Присутствие в составе межколонного флюида диоксида углерода при парциальном давлении, равном или превышающем 0,2 МПа',
          'Превышающее значение 70% от давления гидроразрыва пласта на уровне башмака внешней колонны данного межколонного пространства',
          'Присутствие сероводорода в межколонных пространствах между промежуточными колоннами или между промежуточной колонной и кондуктором на месторождениях с содержанием сероводорода в добываемой продукции больше 4% объема',
        ],
        correctAnswers: [
          'Превышающее значение 70% от давления гидроразрыва пласта на уровне башмака внешней колонны данного межколонного пространства',
          'Присутствие сероводорода в межколонных пространствах между промежуточными колоннами или между промежуточной колонной и кондуктором на месторождениях с содержанием сероводорода в добываемой продукции больше 4% объема',
        ],
      },
      {
        code: '63657001',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов II класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['6 месяцев', '1 год', '5 лет', '10 месяцев'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63657002',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов III класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['6 месяцев', '1 год', '5 лет', '10 месяцев'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63657003',
        text:
          'В каком случае организация вправе разрабатывать единый план мероприятий по локализации и ликвидации последствий аварий для нескольких опасных производственных объектов?',
        answers: [
          'В случае если объекты, эксплуатируемые одной организацией, принадлежат к одному классу опасности',
          'В случае если объекты, эксплуатируемые одной организацией, имеют схожие характеристики аварийности и сценарии наиболее вероятных аварий',
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
        correctAnswers: [
          'В случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках',
        ],
      },
      {
        code: '63657004',
        text:
          'Какие плакаты вывешиваются на пусковых устройствах у аппаратов и в электрораспределительных устройствах при производстве газоопасных работ?',
        answers: [
          '"Высокое напряжение. Опасно для жизни!"',
          '"Не включать: работают люди!"',
          '"Опасная зона", "Проход запрещен"',
          '"Стой! Напряжение!"',
        ],
        correctAnswers: ['"Не включать: работают люди!"'],
      },
      {
        code: '63657005',
        text:
          'Что следует предусматривать в технологическом проекте на создание и эксплуатацию опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Оценку месячной производительности эксплуатационных скважин',
          'Мероприятия по контролю герметичности объекта хранения в процессе строительства и эксплуатации ОПО ПХГ',
          'Обоснование суточных темпов отбора газа',
          'Оценку готовности объекта к локализации и ликвидации чрезвычайных ситуаций',
        ],
        correctAnswers: [
          'Мероприятия по контролю герметичности объекта хранения в процессе строительства и эксплуатации ОПО ПХГ',
        ],
      },
      {
        code: '63657006',
        text:
          'О чем опрашивает каждого исполнителя лицо, ответственное за проведение газоопасных работ?',
        answers: [
          'О наличии медицинских противопоказаний к работе',
          'О самочувствии',
          'О знании действий при пожаре',
          'О знании правил оказания первой помощи',
        ],
        correctAnswers: ['О самочувствии'],
      },
      {
        code: '63657007',
        text:
          'Что допускается при оформлении наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Подписи ответственных лиц с использованием факсимиле и их ксерокопии',
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
          'Исправления в тексте, подписи ответственных лиц с использованием факсимиле',
          'Оформление наряда-допуска на проведение газоопасных работ I группы в одном экземпляре',
        ],
        correctAnswers: [
          'Заполнение наряда-допуска на проведение газоопасных работ с использованием персонального компьютера',
        ],
      },
      {
        code: '63657008',
        text:
          'На каком минимальном расстоянии от уровня земной поверхности (шахты) должен быть расположен низ колонной головки для обеспечения свободного доступа к замерным узлам межколонных пространств после окончания бурения, реконструкции или ремонта скважин, связанного с переоборудованием устья?',
        answers: ['400 мм', '300 мм', '500 мм', '200 мм'],
        correctAnswers: ['300 мм'],
      },
      {
        code: '63657009',
        text:
          'На сколько должна превышать температура вспышки раствора на углеводородной основе максимально ожидаемую температуру раствора на устье скважины?',
        answers: ['На 40 °C', 'На 50 °C', 'На 45 °C'],
        correctAnswers: ['На 50 °C'],
      },
      {
        code: '63657010',
        text:
          'Сколько необходимо разработать планов мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, в случае если 2 и более объектов, эксплуатируемых одной организацией, расположены на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
          'Разрабатывается единый план мероприятий только для объектов, находящихся на расстоянии менее 100 м друг от друга',
          'Разрабатывается один план мероприятий для объектов, расположенных на одном участке, и несколько для объектов на смежных участках',
          'Разрабатываются планы мероприятий для каждого объекта отдельно',
        ],
        correctAnswers: [
          'Разрабатывается единый план мероприятий для двух и более объектов',
        ],
      },
      {
        code: '63657011',
        text:
          'Какой срок действия устанавливается для единого плана мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах, расположенных на одном земельном участке или на смежных земельных участках?',
        answers: [
          'Наименьший срок, предусмотренный для этих объектов',
          'Наибольший срок для этих объектов',
          '3 года',
          '5 лет',
        ],
        correctAnswers: ['Наименьший срок, предусмотренный для этих объектов'],
      },
      {
        code: '63657012',
        text:
          'В соответствии с каким документом обеспечивается пожарная безопасность опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'В соответствии с Правилами безопасности опасных производственных объектов подземных хранилищ газа',
          'В соответствии с Правилами охраны внутрипромысловых трубопроводов',
          'В соответствии с Техническим регламентом о требованиях пожарной безопасности, с постановлением правительства "О противопожарном режиме" и иными нормативными правовыми актами, устанавливающими противопожарные требования, относящиеся к ОПО ПХГ',
          'В соответствии с Правилами безопасности для опасных производственных объектов внутрипромысловых трубопроводов',
        ],
        correctAnswers: [
          'В соответствии с Техническим регламентом о требованиях пожарной безопасности, с постановлением правительства "О противопожарном режиме" и иными нормативными правовыми актами, устанавливающими противопожарные требования, относящиеся к ОПО ПХГ',
        ],
      },
      {
        code: '63657013',
        text:
          'Какими средствами индивидуальной защиты в обязательном порядке должен быть оснащен рабочий, спускающийся в емкость?',
        answers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
          'Защитными очками и защитной каской',
          'Воздушными изолирующими аппаратами',
          'Всеми перечисленными средствами',
        ],
        correctAnswers: [
          'Предохранительным поясом или страховочной привязью с сигнально-спасательной веревкой',
        ],
      },
      {
        code: '63657014',
        text:
          'С какой периодичностью осуществляется учет, контроль за состоянием устьев скважин, ликвидированных после окончания бурения?',
        answers: [
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Все ответы неверны',
        ],
        correctAnswers: ['Не реже одного раза в два года'],
      },
      {
        code: '63657015',
        text:
          'При каких условиях разрешается входить в газоопасное место при проведении газоопасных работ?',
        answers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
          'Только с разрешения представителя газоспасательной службы, и обеспечении принудительного воздухообмена для безопасного ведения работ в газоопасном месте',
          'Только с разрешения лиц, ответственных за подготовку и проведение работ, и с инструментом исключающим возможность искрообразования',
          'Только с разрешения лица, ответственного за осуществление производственного контроля, в средствах защиты органов дыхания',
        ],
        correctAnswers: [
          'Только с разрешения лица, ответственного за проведение работ, и в средствах индивидуальной защиты, надетых за пределами опасной зоны',
        ],
      },
      {
        code: '63657016',
        text:
          'С какой периодичностью осуществляется учет, контроль за состоянием устьев скважин, ликвидированных в процессе эксплуатации?',
        answers: [
          'Все ответы неверны',
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63657017',
        text:
          'Что следует обеспечивать при вскрытии пласта-коллектора объекта эксплуатации?',
        answers: [
          'Непрерывное воздействие на фильтрационные свойства пласта-коллектора',
          'Максимальное воздействие на емкостные свойства пласта-коллектора',
          'Нет правильных ответов',
          'Минимальное воздействие на фильтрационно-емкостные свойства пласта-коллектора',
        ],
        correctAnswers: [
          'Минимальное воздействие на фильтрационно-емкостные свойства пласта-коллектора',
        ],
      },
      {
        code: '63657018',
        text:
          'Где следует устанавливать башмак обсадной колонны, перекрывающий породы, склонные к текучести?',
        answers: [
          'Ниже их подошвы',
          'Нет правильного ответа',
          'Выше их подошвы',
          'Ниже их подошвы или в плотных пропластках',
          'Не регламентируется',
        ],
        correctAnswers: ['Ниже их подошвы или в плотных пропластках'],
      },
      {
        code: '63657019',
        text:
          'Какие информационные плакаты вывешиваются в зоне газоопасных работ на видном месте перед началом работ внутри емкостей и на все время их проведения?',
        answers: [
          '"Опасно для жизни!", "Внимание!"',
          '"Газоопасные работы", "Газ"',
          '"Осторожно! Опасная зона", "Работают люди!"',
          '"Работают люди!", "Опасно для жизни!"',
        ],
        correctAnswers: ['"Газоопасные работы", "Газ"'],
      },
      {
        code: '63657020',
        text:
          'Кто утверждает план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Руководители (заместители руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Представители федерального органа исполнительной власти в области промышленной безопасности',
        ],
        correctAnswers: [
          'Руководители (заместители руководителей) организаций, эксплуатирующих объекты, либо руководители обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63657021',
        text: 'Что должно обеспечиваться на всех участках трубопровода?',
        answers: [
          'Возможность подъезда к трубопроводу для выполнения только аварийных работ',
          'Возможность подхода к любой точке трубопровода для выполнения только профилактических и ремонтных работ',
          'Возможность подъезда к любой точке трубопровода для выполнения профилактических, ремонтных и аварийных работ',
        ],
        correctAnswers: [
          'Возможность подъезда к любой точке трубопровода для выполнения профилактических, ремонтных и аварийных работ',
        ],
      },
      {
        code: '63657022',
        text:
          'Какой документ разрабатывается на бурение группы скважин, расположенных на одной кустовой площадке или одном производственном объекте подземных хранилищ газа (ПХГ), площади (групповой)?',
        answers: [
          'Нет правильного ответа',
          'Технологическая документация',
          'Рабочий проект',
        ],
        correctAnswers: ['Рабочий проект'],
      },
      {
        code: '63657023',
        text:
          'Каким органом определяются требования к структуре и оформлению технических проектов строительства и эксплуатации подземных сооружений, технических проектов ликвидации и консервации горных выработок, буровых скважин и иных сооружений, связанных с пользованием недрами?',
        answers: [
          'Территориальным органом Федерального агентства по недропользованию',
          'Федеральной службой по экологическому, технологическому и атомному надзору',
          'Министерством природных ресурсов и экологии Российской Федерации',
        ],
        correctAnswers: [
          'Министерством природных ресурсов и экологии Российской Федерации',
        ],
      },
      {
        code: '63657024',
        text:
          'Что из перечисленного не содержит заявление, передаваемое организацией-пользователем недр в Федеральное агентство по недропользованию, его территориальный орган или в уполномоченный орган, на согласование проектной документации?',
        answers: [
          'Реквизиты лицензии на пользование участком недр с реквизитами всех изменений',
          'Реквизиты заключения государственной экспертизы запасов полезных ископаемых и подземных вод, геологической информации о предоставляемых в пользование участках недр',
          'Наименование представляемой на согласование проектной документации',
          'Реквизиты декларации промышленной безопасности',
        ],
        correctAnswers: ['Реквизиты декларации промышленной безопасности'],
      },
      {
        code: '63657025',
        text:
          'С какой периодичностью необходимо пересматривать и переутверждать перечень газоопасных работ?',
        answers: [
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '63657026',
        text:
          'Какую информацию не должен содержать знак, установленный при надземной прокладке трубопроводов?',
        answers: [
          'Размер охранной зоны',
          'Дату установки',
          'Местоположение оси трубопровода',
          'Номер телефона эксплуатирующей организации',
        ],
        correctAnswers: ['Дату установки'],
      },
      {
        code: '63657027',
        text:
          'Кто определяет структурные подразделения, на которые возлагается согласование наряда-допуска на выполнение огневых работ?',
        answers: [
          'Руководитель работ подрядной организации',
          'Руководитель эксплуатирующей организации',
          'Руководитель структурного подразделения совместно с руководителем службы организации, на которую возложены функции обеспечения мер пожарной безопасности',
        ],
        correctAnswers: ['Руководитель эксплуатирующей организации'],
      },
      {
        code: '63657028',
        text:
          'Для каких работ применяются ФНП в области промышленной безопасности "Правила безопасности опасных производственных объектов подземных хранилищ газа"?',
        answers: [
          'Для эксплуатации, технического перевооружения, капитального ремонта, консервации и ликвидации ОПО ПХГ',
          'Для проведения экспертизы промышленной безопасности документации на техническое перевооружение, консервацию, ликвидацию ОПО ПХГ',
          'Для разработки технологических процессов, связанных с проектированием, строительством, реконструкцией ОПО ПХГ',
          'Для проведения экспертизы промышленной безопасности технических устройств, зданий и сооружений, деклараций промышленной безопасности ОПО ПХГ',
          'Для всех вышеперечисленных работ',
        ],
        correctAnswers: ['Для всех вышеперечисленных работ'],
      },
      {
        code: '63657029',
        text:
          'С какой частотой устанавливаются столбики со щитами-указателями?',
        answers: [
          'Не реже чем через 1500 м',
          'Не реже чем через 500 м',
          'Не реже чем через 800 м',
          'Не реже чем через 1000 м',
        ],
        correctAnswers: ['Не реже чем через 1000 м'],
      },
      {
        code: '63657030',
        text:
          'Для каких объектов должны разрабатываться планы мероприятий по локализации и ликвидации последствий аварий на ОПО?',
        answers: [
          'Только для опасных производственных объектов, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Только для опасных производственных объектов I и II классов опасности',
          'Для всех опасных производственных объектов',
        ],
        correctAnswers: [
          'Только для опасных производственных объектов, указанных в пункте 2 статьи 10 Федерального закона "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63657031',
        text:
          'Как осуществляется контроль за динамикой давлений в объекте хранения и контрольных горизонтах при эксплуатации опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Путем замера пластового давления, уровней пластовой воды в контрольных скважинах',
          'Путем замера пластового давления, уровней пластовой воды во всех скважинах',
          'Путем замера только пластового давления во всех скважинах',
          'Нет правильного ответа',
        ],
        correctAnswers: [
          'Путем замера пластового давления, уровней пластовой воды в контрольных скважинах',
        ],
      },
      {
        code: '63657032',
        text:
          'Как ведется баланс газа, если на опасных производственных объектах подземных хранилищ газа (ОПО ПХГ) эксплуатируется несколько объектов хранения?',
        answers: [
          'Только в целом по ОПО ПХГ, без оценки затрат газа на собственные нужды',
          'Только отдельно по каждому объекту хранения без расчета текущего буферного объема газа',
          'Как в целом по ОПО ПХГ, так и по каждому объекту хранения отдельно',
          'Нет правильного ответа',
        ],
        correctAnswers: [
          'Как в целом по ОПО ПХГ, так и по каждому объекту хранения отдельно',
        ],
      },
      {
        code: '63657033',
        text:
          'Как осуществляется контроль за динамикой давлений, если в составе опасных производственных объектов подземных хранилищ газа (ОПО ПХГ) эксплуатируется несколько контрольных пластов?',
        answers: [
          'По дальнему пласту',
          'По каждому пласту отдельно',
          'По одному контрольному пласту',
          'Нет правильного ответа',
        ],
        correctAnswers: ['По каждому пласту отдельно'],
      },
      {
        code: '63657034',
        text:
          'Что должна обеспечивать конструкция устья скважины, колонных головок, герметизирующих устройств?',
        answers: [
          'Испытание обсадных колонн и межколонных пространств на герметичность',
          'Возможность аварийного глушения скважины',
          'Все вышеперечисленное',
          'Контроль за возможными флюидопроявлениями за обсадными колоннами',
          'Подвеску с расчетным натяжением промежуточных и эксплуатационных колонн с учетом компенсации температурных деформаций на всех стадиях работы скважины (колонны), а также подвеску колонны бурильных труб на противовыбросовом оборудовании, контроль за возможными флюидопроявлениями за обсадными колоннами',
        ],
        correctAnswers: ['Все вышеперечисленное'],
      },
      {
        code: '63657035',
        text:
          'На какие из перечисленных трубопроводов опасных производственных объектов подземных хранилищ газа (ОПО ПХГ) распространяются Правила безопасности опасных производственных объектов подземных хранилищ газа?',
        answers: [
          'На шлейфы скважин',
          'На газопроводы низкого и среднего давления для котельного оборудования',
          'На межплощадочные газопроводы-коллекторы',
          'На все перечисленные трубопроводы',
        ],
        correctAnswers: ['На все перечисленные трубопроводы'],
      },
      {
        code: '63657036',
        text:
          'Кто производит подключение к электросетям передвижных электроприемников подрядной организации и их отключение при проведении ремонтных работ?',
        answers: [
          'Электротехнический персонал подрядной организации в присутствии лица, ответственного за проведение ремонтных работ',
          'Электротехнический персонал эксплуатирующей организации',
          'Персонал, назначенный руководителем эксплуатирующей организации и определенный в наряде-допуске',
        ],
        correctAnswers: [
          'Электротехнический персонал эксплуатирующей организации',
        ],
      },
      {
        code: '63657037',
        text: 'На каком этапе может производится консервация скважин?',
        answers: [
          'Все ответы неверны',
          'В процессе бурения',
          'На всех перечисленных этапах',
          'После окончания бурения',
          'В процессе эксплуатации',
        ],
        correctAnswers: ['На всех перечисленных этапах'],
      },
      {
        code: '63657038',
        text:
          'Какие сроки испытаний устанавливаются на трубопроводах, для которых проектной документацией/документацией предусматривается проведение периодических испытаний на прочность, герметичность?',
        answers: [
          'Не реже одного раза в год',
          'Не должны превышать тройную периодичность ревизий',
          'Не должны превышать двойную периодичность ревизий',
        ],
        correctAnswers: ['Не должны превышать двойную периодичность ревизий'],
      },
      {
        code: '63657039',
        text:
          'Каким образом фиксируется прохождение инструктажа исполнителями ремонтных работ?',
        answers: [
          'Руководитель структурного подразделения ремонтируемого объекта делает отметку в журнале проведения инструктажа и информирует об этом руководителя бригады, которая будет выполнять ремонтные работы',
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
          'Руководитель службы производственного контроля делает записи в журнале проведения инструктажа и отметку в наряде-допуске',
        ],
        correctAnswers: [
          'Исполнители ремонтных работ расписываются в журнале проведения инструктажа структурного подразделения ремонтируемого объекта, соответствующая отметка делается в наряде-допуске',
        ],
      },
      {
        code: '63657040',
        text:
          'В каком случае допускается эксплуатация труб и трубопроводной арматуры в положениях между "открыто" и "закрыто"?',
        answers: [
          'По решению лица, ответственного за производственный контроль',
          'Если такая возможность предусмотрена изготовителем',
          'Если такая необходимость утверждена локальным документом организации',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Если такая возможность предусмотрена изготовителем'],
      },
      {
        code: '63657041',
        text:
          'Какое отклонение глубин спуска обсадных колонн от указанных в рабочем проекте по вертикали допускается при разработке рабочего проекта группы скважин?',
        answers: [
          'Отклонение не допускается',
          'Не более 300 м',
          'Не более 500 м',
          'Все ответы неверны',
          'Не более 400 м',
        ],
        correctAnswers: ['Не более 400 м'],
      },
      {
        code: '63657042',
        text:
          'В каких из перечисленных случаев должны проводиться газоопасные работы? Выберите два правильных варианта ответа.',
        answers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри емкости проводятся без наблюдающего',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
        ],
        correctAnswers: [
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть автоматизированы',
          'Когда газоопасные работы, в том числе работы, связанные с пребыванием людей внутри аппаратов, емкостей и другого оборудования, не могут быть механизированы',
        ],
      },
      {
        code: '63657043',
        text:
          'Что не производится на этапе создания подземных хранилищ газа (ПХГ)?',
        answers: [
          'Обеспечение возможности выхода хранилища на максимальные показатели',
          'Уточнение и совершенствование геологической и технологической моделей эксплуатации',
          'Развитие и дополнение базы данных текущими данными эксплуатации',
          'Оценка возможности выхода хранилища на проектные показатели и обеспечение его безопасной циклической эксплуатации',
        ],
        correctAnswers: [
          'Обеспечение возможности выхода хранилища на максимальные показатели',
        ],
      },
      {
        code: '63657044',
        text:
          'Что проводится после спуска и цементирования каждой обсадной колонны?',
        answers: [
          'Контроль состояния обсадных труб, цементного камня и положения элементов оснастки',
          'Замер относительной влажности газа',
          'Замер плотности газа',
          'Замер давления газа',
        ],
        correctAnswers: [
          'Контроль состояния обсадных труб, цементного камня и положения элементов оснастки',
        ],
      },
      {
        code: '63657045',
        text:
          'С какой периодичностью должны производиться замеры суммарных объемов количества закачиваемого и отбираемого газа при эксплуатации подземных хранилищ газа (ПХГ)?',
        answers: [
          'Ежесменно, после окончания работы',
          'Ежесуточно',
          'Не регламентируется',
          'Раз в 12 часов',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63657046',
        text:
          'Что из перечисленного следует выполнить для проведения огневых работ внутри емкости (аппарата) при проведении газоопасных работ?',
        answers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
          'Следует оформить наряд-допуск на выполнение огневых работ, закрыв при этом наряд-допуск на проведение газоопасных работ',
          'Следует получить письменное разрешение лица, утвердившего наряд-допуск на проведение газоопасных работ, приложив его к наряду-допуску на проведение газоопасных работ',
          'Следует к наряду-допуску на проведение газоопасных работ приложить перечень мест выполнения огневых работ',
        ],
        correctAnswers: [
          'Следует к наряду-допуску на выполнение огневых работ оформить наряд-допуск на проведение газоопасных работ (в нарядах-допусках на проведение огневых и газоопасных работ указываются зарегистрированные номера нарядов-допусков)',
        ],
      },
      {
        code: '63657047',
        text:
          'До какой максимальной температуры должны быть охлаждены нагретые емкости перед допуском внутрь в них людей?',
        answers: ['30 °C', '35 °C', '40 °C', '45 °C'],
        correctAnswers: ['30 °C'],
      },
      {
        code: '63657048',
        text:
          'Какая устанавливается периодичность проверок состояния скважин, законсервированных в процессе эксплуатации, если в них не установлены цементные мосты?',
        answers: [
          'Все ответы неверны',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 3 года',
          'Любая на основании согласования с противофонтанной службой',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63657049',
        text:
          'В какие документы вносятся сведения о проведенных ремонтах, освидетельствованиях, диагностических обследованиях?',
        answers: [
          'Не регламентировано',
          'Нет правильного ответа',
          'В технические паспорта (эксплуатационные формуляры) технических устройств',
        ],
        correctAnswers: [
          'В технические паспорта (эксплуатационные формуляры) технических устройств',
        ],
      },
      {
        code: '63657050',
        text:
          'Где хранятся все материалы по ликвидированной скважине, включая подписанный сторонами акт о ликвидации?',
        answers: [
          'В эксплуатирующей организации',
          'В территориальном органе Росприроднадзора',
          'У пользователя недр',
        ],
        correctAnswers: ['У пользователя недр'],
      },
      {
        code: '63657051',
        text:
          'Какая информация не указывается на бирке, которой снабжается трубопроводная предохранительная арматура?',
        answers: [
          'Давление срабатывания',
          'Дата настройки',
          'Дата очередной проверки',
          'Данные лица, ответственного за настройку',
        ],
        correctAnswers: ['Данные лица, ответственного за настройку'],
      },
      {
        code: '63657052',
        text:
          'Какие устанавливаются максимальные сроки проведения ревизии трубопроводов ОПО?',
        answers: ['8 лет', '3 года', '5 лет', 'Все ответы неверны'],
        correctAnswers: ['8 лет'],
      },
      {
        code: '63657053',
        text:
          'Что из перечисленного включает в себя баланс газа в подземных хранилищах газа (ПХГ)?',
        answers: [
          'Только оценку затрат газа на собственные технические (технологические) нужды',
          'Только расчет объема закачанного (отобранного) газа за сутки, месяц, сезон с учетом собственных технических нужд',
          'Только расчет текущего буферного, активного и общего объемов газа ПХГ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63657054',
        text:
          'Что должно быть предусмотрено при эксплуатации подземных резервуаров по рассольной схеме в случаях невозможности утилизации или реализации рассола?',
        answers: [
          'Обвалования',
          'Дополнительные резервуары',
          'Рассолохранилище',
        ],
        correctAnswers: ['Рассолохранилище'],
      },
      {
        code: '63657055',
        text:
          'Допускается ли оформление и регистрация наряда-допуска на выполнение ремонтных работ в виде электронного документа?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия хранения наряда-допуска в течение одного года со дня его закрытия',
          'Допускается при наличии внутренних документов организации, устанавливающих порядок использования электронной подписи',
          'Допускается по решению руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия хранения наряда-допуска в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63657056',
        text:
          'Чем следует продувать газопроводы и оборудование на компрессорных станциях, газораспределительных пунктах, в цехах осушки и сепарации газа опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Инертным газом (паром)',
          'Воздухом',
          'Углекислым газом',
          'Только аргоном',
        ],
        correctAnswers: ['Инертным газом (паром)'],
      },
      {
        code: '63657057',
        text:
          'С какой периодичностью производится осмотр трассы, охранной зоны трубопроводов и участков трубопроводов надземного исполнения?',
        answers: [
          'Не реже одного раз в месяц',
          'Не реже двух раз в месяц',
          'Не реже двух раз в квартал',
        ],
        correctAnswers: ['Не реже двух раз в месяц'],
      },
      {
        code: '63657058',
        text:
          'Что из перечисленного является основным технологическим документом и определяет технологию ведения процесса или его отдельных стадий?',
        answers: [
          'Технологический регламент',
          'Техническая карта',
          'Нет правильного ответа',
        ],
        correctAnswers: ['Технологический регламент'],
      },
      {
        code: '63657059',
        text:
          'Какой мониторинг должен осуществляться на опасных производственных объектах подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Мониторинг контрольных горизонтов',
          'Мониторинг критически важных объектов',
          'Объектный мониторинг',
          'Мониторинг опасных объектов',
        ],
        correctAnswers: ['Объектный мониторинг'],
      },
      {
        code: '63657060',
        text:
          'Кем осуществляется подготовка проектной документации на разработку месторождений полезных ископаемых?',
        answers: [
          'Руководителями (заместителями руководителей, в должностные обязанности которых входит согласование проектной документации) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований',
          'Пользователями недр или организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование',
          'Представителем комиссии, создаваемой территориальным органом Федерального агентства по недропользованию',
        ],
        correctAnswers: [
          'Пользователями недр или организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование',
        ],
      },
      {
        code: '63657061',
        text:
          'Какие из указанных стадий технологического проекта эксплуатации опасных производственных объектов подземных хранилищ газа (ОПО ПХГ) указаны неверно?',
        answers: [
          'Опытно-промышленная эксплуатация',
          'Вывод на циклическую эксплуатацию',
          'Вывод из эксплуатации',
        ],
        correctAnswers: ['Вывод из эксплуатации'],
      },
      {
        code: '63657062',
        text:
          'Что входит в обязанности руководителя структурного подразделения при проведении газоопасных работ?',
        answers: [
          'Непосредственно участвовать в выполнении газоопасных работ',
          'Производить подготовку объекта к проведению газоопасных работ',
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
        correctAnswers: [
          'Совместно с лицами, ответственными за подготовку и проведение газоопасной работы, определять средства индивидуальной защиты',
        ],
      },
      {
        code: '63657063',
        text:
          'Какие сведения не указываются в организационно-распорядительном документе для остановки на ремонт объекта или оборудования?',
        answers: [
          'Непосредственный руководитель работ (при выполнении работ подрядной организацией - представитель подрядной организации)',
          'Сроки остановки, подготовки, ремонта и пуска объекта или оборудования, технических устройств, коммуникаций в эксплуатацию',
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
        correctAnswers: [
          'Наличие плана мероприятий по локализации аварий и ликвидации их последствий',
        ],
      },
      {
        code: '63657064',
        text:
          'Кем должен осуществляться контроль за ходом производства буровых работ, качеством выполнения указанных работ, технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда? Выберите два правильных варианта ответа.',
        answers: [
          'Организацией, осуществляющей производство буровых работ',
          'Любым субъектом хозяйственной деятельности',
          'Росприроднадзором',
          'Не регламентируется',
          'Пользователем недр',
        ],
        correctAnswers: [
          'Организацией, осуществляющей производство буровых работ',
          'Пользователем недр',
        ],
      },
      {
        code: '63657065',
        text:
          'С какой периодичностью проводится проверка отсутствия электрического контакта между участком трубопровода и защитным кожухом?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63657066',
        text:
          'С какой периодичностью обратная арматура технологической обвязки компрессорных станций подлежит вскрытию и внутреннему осмотру в соответствии с проектной документацией/документацией, документацией изготовителя?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в год',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63657067',
        text:
          'Какие требования предъявляются к лицам, допущенным к выполнению газоопасных работ?',
        answers: [
          'Все перечисленные требования',
          'Не моложе 18 лет',
          'Прошедшие обучение приемам и методам проведения работ',
          'Прошедшие медицинский осмотр в соответствии с требованиями законодательством Российской Федерации',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63657068',
        text:
          'В соответствии с какими документами наносятся обозначения на производственные объекты опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'В соответствии с технологическими схемами',
          'В соответствии с техническими проектами',
          'В соответствии с рабочими регламентами',
        ],
        correctAnswers: ['В соответствии с технологическими схемами'],
      },
      {
        code: '63657069',
        text:
          'Где устанавливаются опознавательно-предупредительные знаки при надземной прокладке трубопроводов?',
        answers: [
          'Непосредственно на трубопроводе в доступном и видном месте',
          'Не регламентируется',
          'На опорах трубопровода',
        ],
        correctAnswers: ['На опорах трубопровода'],
      },
      {
        code: '63657070',
        text:
          'Кто проводит анализ опасностей технологических процессов с определением отклонений технологических параметров от проектных (регламентных) значений с анализом причин, последствий этих отклонений и мер безопасности в целях идентификации опасностей, обоснования технических мер предупреждения аварий и инцидентов?',
        answers: [
          'Только специалисты строительных организаций',
          'Группа специалистов, в состав которой входят представители проектных, строительных, эксплуатирующих организаций',
          'Только специалисты эксплуатирующих организаций',
          'Только специалисты проектных организаций',
        ],
        correctAnswers: [
          'Группа специалистов, в состав которой входят представители проектных, строительных, эксплуатирующих организаций',
        ],
      },
      {
        code: '63657071',
        text:
          'Какой коэффициент запаса используется при проведении испытаний на герметичность подвесных колонн?',
        answers: ['1,1', '1,15', '1,05'],
        correctAnswers: ['1,05'],
      },
      {
        code: '63657072',
        text:
          'В течение какого периода времени должна быть проведена первичная ревизия вновь построенного трубопровода ОПО?',
        answers: [
          'В течение первых восемнадцати месяцев эксплуатации трубопровода',
          'В течение первых двух лет эксплуатации трубопровода',
          'В течение первого месяца эксплуатации трубопровода',
          'В течение первого года эксплуатации трубопровода',
        ],
        correctAnswers: ['В течение первого года эксплуатации трубопровода'],
      },
      {
        code: '63657073',
        text: 'В присутствии кого должна начинаться газоопасная работа?',
        answers: [
          'В присутствии главного инженера и начальника службы охраны труда',
          'В присутствии технического руководителя организации и начальника смены',
          'В присутствии начальника цеха и лица, ответственного за проведение подготовительных работ',
          'В присутствии лица, ответственного за проведение работ',
        ],
        correctAnswers: [
          'В присутствии лица, ответственного за проведение работ',
        ],
      },
      {
        code: '63657074',
        text:
          'С кем согласовывается наряд-допуск на проведение газоопасных работ?',
        answers: [
          'С руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала',
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
          'С газоспасательной службой и службой охраны труда',
        ],
        correctAnswers: [
          'С собственной ПАСС(Ф), аттестованной на ведение газоспасательных работ, либо с ПАСС(Ф), с которой заключен договор на обслуживание',
        ],
      },
      {
        code: '63657075',
        text:
          'Кто осуществляет контроль за состоянием устьев ликвидированных и законсервированных скважин?',
        answers: [
          'Проектная организация',
          'Росприроднадзор',
          'Все ответы неверны',
          'Пользователь недр или уполномоченный им представитель',
        ],
        correctAnswers: [
          'Пользователь недр или уполномоченный им представитель',
        ],
      },
      {
        code: '63657076',
        text:
          'Контроль каких параметров должен осуществляться при размыве резервуаров?',
        answers: [
          'Давления и температуры в линиях закачки и выхода рассола',
          'Плотности и химического состава рассола в линиях закачки и выхода (последнее - для бесшахтных резервуаров в каменной соли)',
          'Уровня границы раздела фаз в выработке-емкости',
          'Формы и размеров выработки-емкости',
          'Всех вышеперечисленных',
        ],
        correctAnswers: ['Всех вышеперечисленных'],
      },
      {
        code: '63657077',
        text:
          'Какое условие не является обязательным для установления режима эксплуатации подземных хранилищ газа (ПХГ)?',
        answers: [
          'Предупреждение преждевременного износа скважинного оборудования вследствие наличия в продукции скважин механических примесей и коррозионно-активных компонентов',
          'Сохранение фильтрационно-емкостных свойств и производительности объекта хранения',
          'Установление автоматического газоанализатора на наземном оборудовании ПХГ',
          'Предупреждение нарушения герметичности объекта хранения',
        ],
        correctAnswers: [
          'Установление автоматического газоанализатора на наземном оборудовании ПХГ',
        ],
      },
      {
        code: '63657078',
        text:
          'Допускаются ли оформление и регистрация наряда-допуска на выполнение огневых работ в электронном виде?',
        answers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия хранения наряда-допуска в течение одного года со дня его закрытия',
          'Допускается по решению руководителя эксплуатирующей организации, на объекте которого выполняются огневые работы',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Допускается, если исключена возможность несанкционированного изменения информации в наряде-допуске, а также обеспечены условия хранения наряда-допуска в течение одного года со дня его закрытия',
        ],
      },
      {
        code: '63657079',
        text:
          'Какая информация должна быть указана в перечне газоопасных работ? Выберите два правильных варианта ответа.',
        answers: [
          'Список лиц, ответственных за подготовку и проведение газоопасных работ подрядной организации',
          'Список лиц, ответственных за безопасное проведение газоопасных работ эксплуатирующей организации',
          'Структурное подразделение организации (производство, цех, отделение, установка, участок)',
          'Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы, профессиональной аварийно-спасательной службы), выполняющих указанные работы',
        ],
        correctAnswers: [
          'Структурное подразделение организации (производство, цех, отделение, установка, участок)',
          'Категория исполнителей (персонал эксплуатирующей организации, газоспасательной службы, профессиональной аварийно-спасательной службы), выполняющих указанные работы',
        ],
      },
      {
        code: '63657080',
        text:
          'Какие из перечисленных документов и требований, в соответствии с которыми должны выполняться подготовительные работы к проведению ремонтных работ, указаны неверно?',
        answers: [
          'Проектная или исполнительная документация на объект (чертежи, схемы)',
          'Проект производства работ',
          'План подготовительных работ',
          'Проект производства ремонтных работ',
        ],
        correctAnswers: ['Проект производства ремонтных работ'],
      },
      {
        code: '63657081',
        text:
          'Допускается ли при эксплуатации подземных хранилищ газа (ПХГ) для сглаживания пиковых нагрузок кратковременное превышение до 20 % фактического суточного отбора газа над отбором, предусмотренным технологическим проектом?',
        answers: [
          'Допускается при согласовании режима с территориальным органом Росприроднадзора',
          'Допускается при согласовании режима с организацией, ведущей авторский надзор',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Допускается при согласовании режима с организацией, ведущей авторский надзор',
        ],
      },
      {
        code: '63657082',
        text:
          'Что из перечисленного не предусматривает план мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
          'Действия производственного персонала и аварийно-спасательных служб (формирований) по локализации и ликвидации аварий',
          'Мероприятия, направленные на обеспечение безопасности населения (в случае если в результате аварий на объекте может возникнуть угроза безопасности населения)',
          'Количество сил и средств, используемых для локализации и ликвидации последствий аварий на объекте, и их соответствие задачам по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Порядок проведения восстановительных работ по окончании ликвидации аварии на объекте',
        ],
      },
      {
        code: '63657083',
        text:
          'Кем утверждается проектная документация на разработку месторождений полезных ископаемых?',
        answers: [
          'Федеральным агентством по недропользованию или его соответствующим территориальным органом',
          'Федеральной службой по надзору в сфере природопользования',
          'Проектной организацией',
          'Пользователем недр',
        ],
        correctAnswers: ['Пользователем недр'],
      },
      {
        code: '63657084',
        text:
          'Какие из перечисленных параметров контролируются в рамках объектного мониторинга опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Только давление, температура в технологической линии (скважина - газосборный пункт (компрессорная станция) - газопровод подключения), межколонное давление и межколонный расход газа по скважинам, поверхностные газопроявления на хранилище',
          'Только общий объем газа, активный объем газа (в том числе долгосрочный резерв), буферный объем газа, объем закачки (отбора) газа, а также объем пластовой жидкости, добываемой при отборе газа',
          'Все перечисленные параметры, включая содержание растворенного газа, химический состав, давление насыщения растворенного газа в пластовой воде объекта хранения и контрольных горизонтов',
          'Только затраты газа на собственные технические (технологические) нужды, суточная производительность эксплуатационных скважин (для вновь проектируемых ОПО ПХГ) и ОПО ПХГ в целом, а также газоводяной контакт',
          'Только газонасыщенный поровый объем хранилища, компонентный состав газа, точка росы, соответствие качества подготовленного к транспорту газа установленным требованиям, давление в объекте хранения, уровни и давление в контрольных горизонтах, а также газонасыщенность объекта хранения и контрольных горизонтов',
        ],
        correctAnswers: [
          'Все перечисленные параметры, включая содержание растворенного газа, химический состав, давление насыщения растворенного газа в пластовой воде объекта хранения и контрольных горизонтов',
        ],
      },
      {
        code: '63657085',
        text:
          'Кто из перечисленных лиц может быть назначен лицом, ответственным за подготовку газоопасной работы?',
        answers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
          'Любой из специалистов эксплуатирующей организации',
          'Только специалист, обученный пожарно-техническому минимуму',
          'Нет правильного ответа',
        ],
        correctAnswers: [
          'Только специалист из числа инженерно-технических работников эксплуатирующей организации (филиала организации), в ведении которого находятся работники, осуществляющие эксплуатацию объекта (производства, цеха, установки, оборудования), не занятый на период проведения такой работы ведением технологического процесса и знающий безопасные методы, и приемы ведения газоопасных работ',
        ],
      },
      {
        code: '63657086',
        text:
          'На какие виды работ распространяются Правила безопасного ведения газоопасных, огневых и ремонтных работ?',
        answers: [
          'На проведение строительно-монтажных и наладочных работ при строительстве, реконструкции объектов капитального строительства на территории находящихся в эксплуатации опасных производственных объектов',
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
          'На ведение газоопасных, огневых и ремонтных работ на объектах, использующих сжиженные углеводородные газы',
          'На ведение газоопасных, огневых и ремонтных работ на объектах горнорудной и металлургической промышленности',
        ],
        correctAnswers: [
          'На ведение газоопасных, огневых и ремонтных работ на опасных производственных объектах, указанных в пункте 1 приложения № 1 к Федеральному закону "О промышленной безопасности опасных производственных объектов"',
        ],
      },
      {
        code: '63657087',
        text:
          'Какие сроки действия планов мероприятий по локализации и ликвидации последствий аварий установлены для объектов I класса опасности (за исключением объектов, на которых ведутся горные работы)?',
        answers: ['6 месяцев', '1 год', '5 лет', '10 месяцев'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63657088',
        text:
          'Какие требования к исполнителям газоопасных работ указаны неверно?',
        answers: [
          'Члены бригады должны быть обеспечены соответствующими средствами индивидуальной защиты органов дыхания и кожных покровов, спецодеждой, спецобувью, инструментом, приспособлениями и вспомогательными материалами',
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
          'Члены бригады исполнителей газоопасных работ должны быть указаны в наряде-допуске',
          'Исполнители газоопасных работ обязаны пройти инструктаж проверку на знание и умение пользоваться средствами индивидуальной защиты, знание безопасных приемов работы и методов оказания первой помощи пострадавшим',
        ],
        correctAnswers: [
          'Исполнители должны иметь квалификационное удостоверение, выданное специализированными организациями, дающее право ведения газоопасных работ, а также пройти аттестацию в органах Ростехнадзора',
        ],
      },
      {
        code: '63657089',
        text:
          'В каком случае пользователь недр не обязан разрабатывать и реализовывать дополнительные меры безопасности, исключающие риск аварий, или ликвидировать законсервированную скважину?',
        answers: [
          'По результатам экспертизы промышленной безопасности',
          'По результатам государственного экологического контроля',
          'По требованию противофонтанной службы',
          'По результатам производственного контроля',
        ],
        correctAnswers: ['По требованию противофонтанной службы'],
      },
      {
        code: '63657090',
        text:
          'Кто и на какой срок может продлить наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Руководитель структурного подразделения не более чем на 1 дневную рабочую смену',
          'Лицо, зарегистрировавшее наряд-допуск на 1 дневную рабочую смену',
          'Руководитель структурного подразделения на требуемый для окончания работ срок',
          'Продление наряда-допуска не предусмотрено',
        ],
        correctAnswers: [
          'Руководитель структурного подразделения не более чем на 1 дневную рабочую смену',
        ],
      },
      {
        code: '63657091',
        text:
          'Кем из перечисленных должностных лиц согласовываются сроки проведения газоопасных работ на опасных производственных объектах подрядными организациями?',
        answers: [
          'Лицом, ответственным за осуществление производственного контроля с учетом особенностей использования электронной подписи',
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества) эксплуатирующей организации с учетом особенностей использования электронной подписи',
          'Лицом, ответственным за проведение газоопасных работ',
          'Представителем Росприроднадзора',
        ],
        correctAnswers: [
          'Руководителем эксплуатирующей организации или его уполномоченным заместителем, либо руководителем филиала (дочернего общества) эксплуатирующей организации с учетом особенностей использования электронной подписи',
        ],
      },
      {
        code: '63657092',
        text:
          'Когда могут проводиться газоопасные работы, выполняемые по наряду-допуску?',
        answers: [
          'В дневное время суток с участием или в присутствии лица, ответственного за выполнение газоопасных работ',
          'Нет правильного ответа',
          'Только в темное время суток с соблюдением мероприятий по обеспечению безопасного проведения работ',
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
        correctAnswers: [
          'В рабочие дни в дневное время (дневную рабочую смену) или по письменному разрешению лица, утвердившего наряд-допуск на проведение газоопасных работ, в темное время суток (вечернюю и ночную рабочую смену), а также в выходные и праздничные дни в присутствии представителя ПАСС(Ф) или аттестованных представителей аварийно-спасательных служб (формирований) для объектов магистрального трубопроводного транспорта и подземных хранилищ газа, с уведомлением лиц, согласовавших наряд-допуск',
        ],
      },
      {
        code: '63657093',
        text:
          'Какое устанавливается максимальное отличие по длине ствола между наиболее и наименее протяженной скважиной?',
        answers: ['1500 м', '400 м', '2000 м', '2500 м'],
        correctAnswers: ['2000 м'],
      },
      {
        code: '63657094',
        text:
          'Кого вправе привлекать эксплуатирующая организация в процессе эксплуатации ОПО к авторскому надзору за эксплуатацией ОПО?',
        answers: [
          'Научно-исследовательскую организацию проблем промышленной безопасности',
          'Проектную организацию',
          'Все ответы неверны',
          'Автора технологического проекта',
        ],
        correctAnswers: ['Автора технологического проекта'],
      },
      {
        code: '63657095',
        text:
          'Какое из перечисленных требований при консервации скважин указано верно? Выберите два правильных варианта ответов.',
        answers: [
          'Продление срока консервации скважин устанавливается территориальным органом Росприроднадзора',
          'Срок консервации скважин после эксплуатации без установки консервационного моста над интервалом перфорации составляет 5 лет',
          'Срок консервации скважин после эксплуатации с установкой консервационного моста над интервалом перфорации составляет 7 лет',
          'Срок нахождения в бездействии скважины перед консервацией не учитывается',
        ],
        correctAnswers: [
          'Срок консервации скважин после эксплуатации без установки консервационного моста над интервалом перфорации составляет 5 лет',
          'Срок нахождения в бездействии скважины перед консервацией не учитывается',
        ],
      },
      {
        code: '63657096',
        text:
          'Что не включает в себя контроль технического состояния при эксплуатации скважин?',
        answers: [
          'Диагностику целостности и износа обсадных колонн и насосно-компрессорных труб геофизическими методами',
          'Контроль заколонных перетоков и техногенных скоплений газа геофизическими методами',
          'Осмотр задвижек и обвязки устья',
          'Диагностирование технического состояния фонтанных арматур и колонных головок',
          'Замер межколонных давлений',
        ],
        correctAnswers: ['Осмотр задвижек и обвязки устья'],
      },
      {
        code: '63657097',
        text:
          'В течение какого срока осуществляется рассмотрение проектов строительства и эксплуатации подземных сооружений комиссией или уполномоченным органом со дня представления материалов пользователем недр?',
        answers: [
          '10 рабочих дней',
          '25 рабочих дней',
          '45 рабочих дней',
          '50 рабочих дней',
        ],
        correctAnswers: ['25 рабочих дней'],
      },
      {
        code: '63657098',
        text:
          'Что должно быть обеспечено при консервации опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Контроль за герметичностью только объекта хранения на протяжении всего периода консервации',
          'Контроль за герметичностью только скважин и их устьев на протяжении 7 лет',
          'Контроль за герметичностью объекта хранения, скважин и их устьев на протяжении всего периода консервации',
          'Контроль за герметичностью объекта хранения, скважин и их устьев на протяжении 5 лет',
        ],
        correctAnswers: [
          'Контроль за герметичностью объекта хранения, скважин и их устьев на протяжении всего периода консервации',
        ],
      },
      {
        code: '63657099',
        text:
          'С какой периодичностью необходимо проводить замер давления и расхода газа между обсадными колоннами при максимальном (приближенном к максимальному) давлении в подземном хранилище газа по скважинам, на которых проектом предусмотрена обвязка обсадных колонн и наличие замерных устройств?',
        answers: [
          'Не реже одного раза в месяц',
          'Все ответы неверны',
          'Не реже одного раза в год',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63657100',
        text: 'Что должно обеспечиваться в процессе цементирования?',
        answers: [
          'Максимально возможная репрессия на продуктивные горизонты',
          'Непрерывная приборная регистрация параметров, характеризующих этот процесс',
          'Ускоренный режим спуска обсадных колонн',
        ],
        correctAnswers: [
          'Непрерывная приборная регистрация параметров, характеризующих этот процесс',
        ],
      },
      {
        code: '63657101',
        text: 'Что должно учитываться в рабочем проекте?',
        answers: [
          'Горно-геологические условия бурения скважин на ближайших площадях с аналогичными условиями',
          'Способ бурения скважин на ближайших площадях с аналогичными условиями',
          'Опыт буровых работ на скважинах данной и ближайших площадей с аналогичными условиями',
        ],
        correctAnswers: [
          'Опыт буровых работ на скважинах данной и ближайших площадей с аналогичными условиями',
        ],
      },
      {
        code: '63657102',
        text:
          'Каков срок действия регламента для опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: ['5 лет', '3 года', '7 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63657103',
        text:
          'В течение какого времени после внесения изменений в системы управления технологическими процессами на объекте должен быть пересмотрен план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее 90 календарных дней',
          'Не позднее 6 месяцев',
          'Не позднее 3 месяцев',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63657104',
        text:
          'При какой концентрации пожаровзрывоопасных веществ не допускается проведение огневых работ?',
        answers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При концентрации пожаровзрывоопасных выше 30% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При концентрации пожаровзрывоопасных в пределах 30-45% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
          'При наличии пожаровзрывоопасных веществ проведение огневых работ не допускается',
        ],
        correctAnswers: [
          'При наличии пожаровзрывоопасных веществ выше 20% объемных от нижнего концентрационного предела распространения пламени в зоне их проведения',
        ],
      },
      {
        code: '63657105',
        text:
          'В каком документе фиксируются результаты осмотров трассы, охранной зоны трубопроводов и участков трубопроводов надземного исполнения?',
        answers: [
          'В специальном журнале в произвольной форме',
          'В журнале наружного осмотра трубопровода',
          'В паспорте трубопровода',
        ],
        correctAnswers: ['В журнале наружного осмотра трубопровода'],
      },
      {
        code: '63657106',
        text:
          'Как проводится контроль за расходом закачиваемого (отбираемого) газа при наличии нескольких газосборных пунктов?',
        answers: [
          'Контроль проводится на любом из пунктов (по выбору эксплуатирующей организации)',
          'Контроль проводится на каждом пункте',
          'Контроль проводится только на самом дальнем пункте',
          'Нет правильного ответа',
        ],
        correctAnswers: ['Контроль проводится на каждом пункте'],
      },
      {
        code: '63657107',
        text:
          'Что предусматривается при вскрытии слабосцементированного пласта-коллектора объекта эксплуатации?',
        answers: [
          'Мероприятия по укреплению призабойной зоны скважины',
          'Режим работы пескосмесительных агрегатов',
          'Проведение исследовательских работ',
          'Замеры относительной плотности газа',
        ],
        correctAnswers: ['Мероприятия по укреплению призабойной зоны скважины'],
      },
      {
        code: '63657108',
        text:
          'Что является основным документом на производство буровых работ на опасных производственных объектах подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Сметная документация',
          'Рабочий проект производства буровых работ',
          'Технологическая документация',
        ],
        correctAnswers: ['Рабочий проект производства буровых работ'],
      },
      {
        code: '63657109',
        text:
          'Какой документ разрабатывается на бурение отдельной скважины (индивидуальный)?',
        answers: [
          'Нет правильного ответа',
          'Технологическая документация',
          'Рабочий проект',
        ],
        correctAnswers: ['Рабочий проект'],
      },
      {
        code: '63657110',
        text:
          'К какой группе газоопасных работ относятся работы, выполняемые без оформления наряда-допуска?',
        answers: [
          'К группе работ средней степени опасности',
          'К I группе',
          'К II группе',
          'К группе работ высокой степени опасности',
        ],
        correctAnswers: ['К II группе'],
      },
      {
        code: '63657111',
        text:
          'Что из перечисленного не является объектом мониторинга при эксплуатации опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Объект хранения газа',
          'Эксплуатационные, специальные скважины в пределах горного отвода ОПО ПХГ',
          'Технологическое оборудование наземного комплекса ОПО ПХГ',
          'Контрольные горизонты',
        ],
        correctAnswers: [
          'Технологическое оборудование наземного комплекса ОПО ПХГ',
        ],
      },
      {
        code: '63657112',
        text:
          'Кем согласовывается акт консервации скважин подземных хранилищ газа в процессе бурения?',
        answers: [
          'Пользователем недр и противофонтанной службой (противофонтанной военизированной частью)',
          'Пользователем недр и эксплуатирующей организацией',
          'Пользователем недр и Росприроднадзором',
          'Нет правильного ответа',
        ],
        correctAnswers: [
          'Пользователем недр и противофонтанной службой (противофонтанной военизированной частью)',
        ],
      },
      {
        code: '63657113',
        text:
          'Допускается ли повторное использование внешней подвесной колонны при пакерном способе эксплуатации после ее извлечения?',
        answers: [
          'Допускается если это предусмотрено проектной документацией',
          'Не допускается, кроме случаев, предусмотренных проектной документацией',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63657114',
        text:
          'Какие требования должны выполняться при проведении земляных работ в ремонтной зоне?',
        answers: [
          'Подрядная организация согласовывает наряд-допуск на производство земляных работ со структурными подразделениями эксплуатирующей организации, на которые возложено согласование наряда-допуска на производство земляных работ внутренними документами эксплуатирующей организацией',
          'Эксплуатирующая организация должна согласовать с подрядной организации расстановку знаков, обозначающих границы земляных работ',
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
        correctAnswers: [
          'Эксплуатирующая организация должна передать подрядной организации наряд-допуск на производство земляных работ, согласованный со структурными подразделениями эксплуатирующей организации',
        ],
      },
      {
        code: '63657115',
        text:
          'Кому лицо, ответственное за подготовку газоопасных работ, должно сдать объект после окончания подготовительных работ?',
        answers: [
          'Лицу, ответственному за обеспечение пожарной безопасности',
          'Лицу из состава собственной профессиональной аварийно-спасательной службы',
          'Бригаде исполнителей газоопасных работ',
          'Лицу, ответственному за проведение газоопасной работы',
        ],
        correctAnswers: [
          'Лицу, ответственному за проведение газоопасной работы',
        ],
      },
      {
        code: '63657116',
        text:
          'Как должны выполняться работы, не включенные в утвержденный перечень газоопасных работ?',
        answers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в течение года',
          'Запрещается выполнять работы, не включенные в утвержденный перечень газоопасных работ',
        ],
        correctAnswers: [
          'По наряду-допуску на проведение газоопасных работ с последующим их внесением в перечень газоопасных работ в десятидневный срок',
        ],
      },
      {
        code: '63657117',
        text:
          'Куда вносятся сведения о результатах периодических диагностических обследований, проведенных ремонтах, техническом перевооружении, реконструкциях трубопроводов и скважин?',
        answers: [
          'В эксплуатационные формуляры трубопровода и дела (паспорта скважин)',
          'В эксплуатационный журнал',
          'В технологические карты',
          'В ремонтный журнал',
        ],
        correctAnswers: [
          'В эксплуатационные формуляры трубопровода и дела (паспорта скважин)',
        ],
      },
      {
        code: '63657118',
        text:
          'Кто осуществляет подготовку объекта к проведению на нем газоопасной работы и огневых работ?',
        answers: [
          'Работники, список которых определяется внутренними документами организации',
          'Работники, осуществляющие эксплуатацию объекта, совместно с работниками аварийно-спасательных подразделений',
          'Работники, осуществляющие эксплуатацию объекта',
          'Работники газоспасательной службы',
        ],
        correctAnswers: ['Работники, осуществляющие эксплуатацию объекта'],
      },
      {
        code: '63657119',
        text:
          'Что из перечисленного содержится в специальном разделе мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Первоочередные действия при получении сигнала об авариях на объекте',
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
          'Сценарии наиболее вероятных аварий и наиболее опасных по последствиям аварий, а также источники (места) их возникновения',
          'Мероприятия, направленные на обеспечение безопасности населения (в случае если в результате аварий на объекте может возникнуть угроза безопасности населения)',
        ],
        correctAnswers: [
          'Порядок действий в случае аварий на объекте в соответствии с требованиями, установленными федеральными нормами и правилами в области промышленной безопасности',
        ],
      },
      {
        code: '63657120',
        text:
          'В соответствии с каким документом устанавливается периодичность контроля за состоянием воздушной среды?',
        answers: [
          'В соответствии планом подготовительных работ',
          'В соответствии проектной документацией',
          'В соответствии внутренними документами эксплуатирующей организации (стандартами, инструкциями, положениями)',
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
        correctAnswers: [
          'В соответствии с нарядом-допуском на выполнение огневых работ',
        ],
      },
      {
        code: '63657121',
        text: 'Куда представляется акт о ликвидации (консервации) скважины?',
        answers: [
          'В территориальный орган Росприроднадзора',
          'В территориальный орган Федерального агентства по недропользованию',
          'В территориальный орган Ростехнадзора',
          'В Министерство природных ресурсов и экологии Российской Федерации',
        ],
        correctAnswers: ['В территориальный орган Ростехнадзора'],
      },
      {
        code: '63657122',
        text:
          'Какие разделы включаются в состав документации на консервацию и ликвидацию опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Только мероприятия по максимально возможному отбору газа из объекта хранения с обеспечением требований безопасности населения, охраны недр и окружающей среды',
          'Только общая пояснительная записка, включающая обоснование критериев и варианта ликвидации скважин, вариант ликвидации (в зависимости от этапа бурения или эксплуатации скважин)',
          'Только мероприятия по охране окружающей среды, мероприятия по рекультивации нарушенных земель, мероприятия по охране зданий и сооружений, мероприятия по безопасности жизни и здоровья населения, мероприятия по охране недр',
          'Только решения о целесообразности использования подземных резервуаров для иных целей, способы консервации (ликвидации) ОПО ПХГ и его частей, порядок и график проведения работ',
          'Все перечисленные, включая мероприятия по предотвращению загрязнения питьевых водоносных горизонтов',
        ],
        correctAnswers: [
          'Все перечисленные, включая мероприятия по предотвращению загрязнения питьевых водоносных горизонтов',
        ],
      },
      {
        code: '63657123',
        text:
          'Какой может быть документация на ликвидацию и консервацию скважин?',
        answers: [
          'Зональной и групповой',
          'Индивидуальной и групповой',
          'Индивидуальной, групповой и зональной',
        ],
        correctAnswers: ['Индивидуальной, групповой и зональной'],
      },
      {
        code: '63657124',
        text:
          'Какое из перечисленных действий допускается при эксплуатации трубопроводов и трубопроводной арматуры (ТПА)?',
        answers: [
          'Производить работы по устранению дефектов, подтяжку уплотнения, резьбовых соединений трубной обвязки и фитингов, находящихся под давлением',
          'Использовать ТПА в качестве опор',
          'Дросселировать газ при частично открытом затворе запорной арматуры, установленной на обводных и выпускных газопроводах, если это не предусмотрено изготовителем',
          'Соединять сброс газа из предохранительной арматуры разных потребителей на одну свечу рассеивания',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63657125',
        text:
          'В соответствии с каким документом осуществляется эксплуатация подземных хранилищ газа (ПХГ)?',
        answers: [
          'В соответствии с анализом эксплуатации ПХГ',
          'В соответствии с дополнением в технологический проект ПХГ',
          'В соответствии с технологическим проектом',
        ],
        correctAnswers: ['В соответствии с технологическим проектом'],
      },
      {
        code: '63657126',
        text:
          'В течение какого времени наряд-допуск на выполнение огневых работ действителен?',
        answers: [
          'В течение времени, установленного руководителем структурного подразделения, на объекте которого выполняются огневые работы',
          'В течение 1 смены',
          'До окончания работ на конкретном месте',
        ],
        correctAnswers: ['В течение 1 смены'],
      },
      {
        code: '63657127',
        text:
          'С кем согласовывается план мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
          'С руководителями (заместителями руководителей) организации, эксплуатирующей объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Представителем федерального органа исполнительной власти в области промышленной безопасности',
          'С комиссией, создаваемой Федеральным агентством по недропользованию или его соответствующим территориальным органом',
        ],
        correctAnswers: [
          'С руководителями (заместителями руководителей, в должностные обязанности которых входит согласование планов мероприятий) профессиональных аварийно-спасательных служб или профессиональных аварийно-спасательных формирований, которые привлекаются для локализации и ликвидации последствий аварий на объекте',
        ],
      },
      {
        code: '63657128',
        text:
          'Какие исследования не проводятся при консервации и ликвидации опасных производственных объектов подземных хранилищ газа (ОПО ПХГ) в процессе контроля за состоянием объекта хранения и контрольных горизонтов?',
        answers: [
          'Промысловые',
          'Геофизические',
          'Гидрохимические',
          'Аналитические',
        ],
        correctAnswers: ['Аналитические'],
      },
      {
        code: '63657129',
        text:
          'В течение какого срока должны храниться экземпляры наряда-допуска на проведение газоопасных работ?',
        answers: [
          'Не менее 3 месяцев со дня закрытия наряда допуска',
          'Не менее 6 месяцев со дня закрытия наряда допуска',
          'Не менее 1 года со дня закрытия наряда допуска',
        ],
        correctAnswers: ['Не менее 6 месяцев со дня закрытия наряда допуска'],
      },
      {
        code: '63657130',
        text:
          'Каким документом оформляется повторное использование рабочего проекта?',
        answers: [
          'Протоколом комиссии, создаваемой пользователем недр (заказчиком), согласованным с проектной организацией',
          'Технологическим проектом, разработанным специализированной организацией',
          'Повторное использование проекта не допускается',
        ],
        correctAnswers: [
          'Протоколом комиссии, создаваемой пользователем недр (заказчиком), согласованным с проектной организацией',
        ],
      },
      {
        code: '63657131',
        text:
          'С кем согласовывает пользователь недр план работ, на основании которого производится вывод скважин из консервации?',
        answers: [
          'С противофонтанной службой (противофонтанной военизированной частью)',
          'С органами местного самоуправления',
          'С проектной организацией',
          'С Ростехнадзором',
        ],
        correctAnswers: [
          'С противофонтанной службой (противофонтанной военизированной частью)',
        ],
      },
      {
        code: '63657132',
        text:
          'Кем утверждаются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах?',
        answers: [
          'Инженерно-техническим персоналом организаций, эксплуатирующих объекты',
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
          'Представителем федерального органа исполнительной власти в области промышленной безопасности',
          'Специалистом военизированной газоспасательной службы',
        ],
        correctAnswers: [
          'Руководителями (заместителями руководителей) организаций, эксплуатирующих объекты, либо руководителями обособленных подразделений юридических лиц (в случаях, предусмотренных положениями о таких обособленных подразделениях)',
        ],
      },
      {
        code: '63657133',
        text:
          'Что должна выполнить подрядная организация до начала проведения ремонтных работ?',
        answers: [
          'Составить план подготовительных работ',
          'Организовать изготовление необходимых узлов и деталей для замены',
          'Разработать проект производства работ',
          'Приобрести необходимое оборудование, арматуру, запасные части, трубы, материалы согласно дефектной ведомости',
        ],
        correctAnswers: ['Разработать проект производства работ'],
      },
      {
        code: '63657134',
        text:
          'Какое из перечисленных требований при назначении специалиста, ответственного за проведение огневых работ, указано неверно?',
        answers: [
          'Прохождение обучения по пожарно-техническому минимуму в объеме знаний требований нормативных правовых актов, регламентирующих пожарную безопасность',
          'Назначение организационно-распорядительными документами руководителя эксплуатирующей организации (филиала организации) или лица, его замещающего, ответственного за обеспечение пожарной безопасности',
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
        correctAnswers: [
          'Прохождение подготовки и аттестации по промышленной безопасности',
        ],
      },
      {
        code: '63657135',
        text:
          'На что направлены требования, установленные ФНП в области промышленной безопасности "Правила безопасности опасных производственных объектов подземных хранилищ газа"?',
        answers: [
          'На обеспечение промышленной безопасности, предупреждение аварий на ОПО ПХГ',
          'На организацию производственного контроля за соблюдением требований промышленной безопасности на ОПО ПХГ',
          'На предупреждение случаев производственного травматизма на ОПО ПХГ',
        ],
        correctAnswers: [
          'На обеспечение промышленной безопасности, предупреждение аварий на ОПО ПХГ',
        ],
      },
      {
        code: '63657136',
        text:
          'В каком случае может осуществляться временная приостановка скважин без консервации?',
        answers: [
          'В случае согласования приостановки скважин с проектной организацией',
          'Если срок приостановки не превышает 12 месяцев',
          'В случае согласования приостановки скважин с противофонтанной службой',
          'Не может осуществляться ни в каком случае',
        ],
        correctAnswers: ['Если срок приостановки не превышает 12 месяцев'],
      },
      {
        code: '63657137',
        text:
          'Какой технологический параметр не контролируется при закачке промышленных стоков на опасных производственных объектах подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Давление нагнетания',
          'Объем промстоков',
          'Содержание БПК и ХПК',
          'Химический состав',
        ],
        correctAnswers: ['Содержание БПК и ХПК'],
      },
      {
        code: '63657138',
        text:
          'Какое из перечисленных требований безопасности к проектированию, строительству опасных производственных объектов подземных хранилищ газа в каменной соли указано неверно?',
        answers: [
          'Количество резервуаров и их размещение определяются исходя из площади распространения соляной залежи, ее общей толщины и наличия прослоев калийно-магниевых и других солей',
          'При размыве резервуаров должен осуществляться контроль плотности и химического состава рассола в линиях закачки и выхода',
          'Башмак подвесной колонны (при отборе газа) эксплуатационной скважины должен располагаться ниже зеркала рассола',
          'Конструкция эксплуатационной скважины должна обеспечивать ввод в скважину ингибиторов гидратообразования (при наличии условий гидратообразования) и коррозии (при наличии агрессивной среды)',
        ],
        correctAnswers: [
          'Башмак подвесной колонны (при отборе газа) эксплуатационной скважины должен располагаться ниже зеркала рассола',
        ],
      },
      {
        code: '63657139',
        text:
          'С кем необходимо согласовывать проведение работ в коллекторах, тоннелях, колодцах, приямках, траншеях и аналогичных сооружениях?',
        answers: [
          'С руководителями аварийно-спасательных служб',
          'С руководителями службы производственного контроля',
          'С руководителями службы охраны труда и санитарными службами',
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
        correctAnswers: [
          'С руководителями структурных подразделений, технологически связанных с объектами, на которых будут проводиться газоопасные работы',
        ],
      },
      {
        code: '63657140',
        text:
          'В каком из перечисленных случаев пересматриваются планы мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах? Выберите два правильных варианта ответа.',
        answers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не позднее 15 календарных дней после изменения сведений, содержащихся в общих или специальных разделах плана мероприятий',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
          'В случае назначения нового руководителя организации',
          'Не позднее 40 календарных дней после внесения изменений в системы управления технологическими процессами на объекте',
        ],
        correctAnswers: [
          'Не позднее 30 календарных дней после реконструкции, технического перевооружения объекта или внесения изменений в технологию производства',
          'Не менее чем за 15 календарных дней до истечения срока действия предыдущего плана мероприятий',
        ],
      },
      {
        code: '63657141',
        text:
          'Как осуществляется учет и замер количества извлекаемой попутной пластовой жидкости при эксплуатации опасных производственных объектов подземных хранилищ газа (ОПО ПХГ)?',
        answers: [
          'Только в целом по ОПО ПХГ без учета объема промстоков',
          'Только по каждому объекту хранения газа без учета его химического состава',
          'Как в целом по ОПО ПХГ, так и по каждому объекту хранения газа',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Как в целом по ОПО ПХГ, так и по каждому объекту хранения газа',
        ],
      },
      {
        code: '63657142',
        text:
          'Какой коэффициент запаса используется при проведении испытаний на герметичность резервуара?',
        answers: ['1,1', '1,15', '1,05'],
        correctAnswers: ['1,05'],
      },
      {
        code: '63657143',
        text:
          'Кем разрабатывается технический проект на производство перфорационных работ?',
        answers: [
          'Организацией-пользователем недр',
          'Буровой организацией',
          'Геофизической организацией',
        ],
        correctAnswers: ['Геофизической организацией'],
      },
      {
        code: '63657144',
        text:
          'В каких документах указывается срок безопасной эксплуатации скважин?',
        answers: [
          'В проектной документации',
          'В документации, разработанной автором технологического проекта',
          'В заключении экспертизы промышленной безопасности',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63657145',
        text:
          'Кем проводятся все работы при необходимости повторной ликвидации скважин?',
        answers: [
          'Все ответы неверны',
          'Пользователем недр или уполномоченным им представителем',
          'Организацией, осуществляющей проведение экспертизы промышленной безопасности при участии противофонтанной службы',
          'Организацией, осуществляющей проведение разведочных работ',
        ],
        correctAnswers: [
          'Пользователем недр или уполномоченным им представителем',
        ],
      },
      {
        code: '63657146',
        text:
          'В какое состояние должны быть приведены объекты хранения, здания и сооружения опасных производственных объектов подземных хранилищ газа (ОПО ПХГ) при консервации и ликвидации?',
        answers: [
          'В состояние, обеспечивающее безопасность населения и охрану окружающей среды',
          'Нет правильного ответа',
          'В состояние, обеспечивающее только безопасность окружающей среды',
          'В состояние, обеспечивающее только безопасность населения',
        ],
        correctAnswers: [
          'В состояние, обеспечивающее безопасность населения и охрану окружающей среды',
        ],
      },
      {
        code: '63657147',
        text:
          'Каким документом определяется перечень постоянных мест выполнения огневых работ на территории, на которой находятся взрывопожароопасные производственные объекты?',
        answers: [
          'Организационно-распорядительными документами руководителя',
          'Технологическим регламентом о требованиях пожарной безопасности',
          'Федеральными нормами и правилами в области промышленной безопасности',
          'Правилами противопожарного режима',
        ],
        correctAnswers: [
          'Организационно-распорядительными документами руководителя',
        ],
      },
    ],
  },
  7: {
    63690: [
      {
        code: '63690000',
        text:
          'Допускается ли на автогазозаправочной станции проводить работы по регулировке и ремонту средств автоматизации, противоаварийных защит и сигнализации в условиях загазованности?',
        answers: [
          'Допускается при условии постоянного контроля загазованности при помощи переносных газоанализаторов',
          'Допускается в экстренных ситуациях при соблюдении дополнительных мер безопасности',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690001',
        text:
          'Куда заносятся результаты проверок загазованности колодцев подземных коммуникаций на территории автогазозаправочной станции?',
        answers: [
          'В эксплуатационный журнал',
          'В ремонтный журнал',
          'В сменный журнал',
          'В график дежурств',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63690002',
        text:
          'С какой периодичностью должны проверяться действие и исправность предохранительных пружинных клапанов, установленных на газопроводах, резервуарах автогазозаправочной станции?',
        answers: [
          'Еженедельно',
          'Не реже одного раза в месяц',
          'Ежеквартально',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63690003',
        text:
          'С кем согласовывается план производства работ при проведении капитального ремонта АГНКС?',
        answers: [
          'С техническим руководителем АГНКС (руководителем АГНКС)',
          'С территориальным органом Ростехнадзора',
          'С проектной организацией',
          'План производства работ не согласовывается',
        ],
        correctAnswers: [
          'С техническим руководителем АГНКС (руководителем АГНКС)',
        ],
      },
      {
        code: '63690004',
        text:
          'Допускается ли изменять конструкцию вентиляционных систем на АГНКС?',
        answers: [
          'Допускается при согласовании с территориальным органом Ростехнадзора',
          'Допускается при согласовании с проектной организацией',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: [
          'Допускается при согласовании с проектной организацией',
        ],
      },
      {
        code: '63690005',
        text:
          'Допускается ли работа технических устройств автогазозаправочной станции при неотрегулированных и неисправных предохранительных сбросных клапанах?',
        answers: [
          'Допускается в исключительных случаях на срок не более суток',
          'Допускается на срок не более 1 смены',
          'Допускается',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690006',
        text:
          'Что из перечисленного не указывается в технологической схеме на автогазозаправочной станции?',
        answers: [
          'Фланцевые и резьбовые соединения',
          'Запорная арматура',
          'Предохранительная арматура',
          'Номера резервуаров',
        ],
        correctAnswers: ['Фланцевые и резьбовые соединения'],
      },
      {
        code: '63690007',
        text:
          'Допускается ли проведение ремонтных работ на АГЗС при утечке СУГ?',
        answers: [
          'Ремонтные работы могут быть продолжены при условии обеспечения дополнительных мер безопасности',
          'Ремонтные работы могут быть продолжены при наличии разрешения технического руководителя АГЗС',
          'Ремонтные работы должны быть прекращены, а работники выведены из опасной зоны',
          'Ремонтные работы могут быть продолжены в любом случае',
        ],
        correctAnswers: [
          'Ремонтные работы должны быть прекращены, а работники выведены из опасной зоны',
        ],
      },
      {
        code: '63690008',
        text:
          'Какие документы должно иметь оборудование, применяемое на автогазозаправочных станциях?',
        answers: [
          'Эксплуатационные паспорта, составленные организацией-владельцем',
          'Паспорта и руководства по эксплуатации организаций-изготовителей',
          'Производственные инструкции по эксплуатации',
        ],
        correctAnswers: [
          'Паспорта и руководства по эксплуатации организаций-изготовителей',
        ],
      },
      {
        code: '63690009',
        text:
          'Допускается ли на АГЗС работа остальных насосов при установке заглушек на газопроводах обвязки насоса, подлежащего ремонту?',
        answers: [
          'Работа остальных насосов может быть продолжена при контроле загазованности переносным газоанализатором через каждые 10 минут',
          'Работа остальных насосов может быть продолжена при организации дополнительной вентиляции',
          'Работа остальных насосов должна быть прекращена',
        ],
        correctAnswers: ['Работа остальных насосов должна быть прекращена'],
      },
      {
        code: '63690010',
        text:
          'Допускается ли эксплуатация насосов с отключенной автоматикой, аварийной сигнализацией?',
        answers: [
          'Допускается в течение не более 1 смены при соблюдении дополнительных мер безопасности',
          'Допускается по письменному разрешению технического руководителя АГЗС',
          'Допускается в случаях, указанных в производственной инструкции',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690011',
        text:
          'С какой периодичностью на АГНКС в помещениях, относящихся к категории "А" по пожарной и взрывопожарной опасности зданий, следует контролировать техническое состояние газонепроницаемых стен, отделяющих помещения категории "А" от помещений с иными категориями, и состояние покрытия пола?',
        answers: [
          '2 раза в смену',
          'Ежесменно',
          'Не реже 1 раза в неделю',
          'Еженедельно',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63690012',
        text:
          'Что должна включать маркировка, нанесенная на оборудование, предназначенное для работы во взрывоопасных средах?',
        answers: [
          'Только наименование изготовителя или его зарегистрированный товарный знак',
          'Только номер сертификата соответствия и маркировку взрывозащиты',
          'Только обозначение типа оборудования и его заводской номер',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63690013',
        text:
          'Какая вентиляция должна действовать при выполнении огневых работ в помещении?',
        answers: [
          'Приточно-вытяжная вентиляция',
          'Местная приточная вентиляция',
          'Местная вытяжная вентиляция',
          'Общеобменная вентиляция',
        ],
        correctAnswers: ['Приточно-вытяжная вентиляция'],
      },
      {
        code: '63690014',
        text:
          'К какому моменту на автогазозаправочной станции должны быть назначены лица, ответственные за выполнение газоопасных работ, техническое состояние и безопасную эксплуатацию оборудования, работающего под избыточным давлением, за осуществление производственного контроля, а также лица, ответственные за безопасную эксплуатацию электрохозяйства и вентиляционного оборудования?',
        answers: [
          'К моменту приемки автозаправочной станции по окончании пусконаладочных работ и комплексного опробования',
          'К моменту завершения сооружения автозаправочной станции',
          'К моменту ввода автозаправочной станции в эксплуатацию',
          'К моменту проведения пусконаладочных работ',
        ],
        correctAnswers: ['К моменту проведения пусконаладочных работ'],
      },
      {
        code: '63690015',
        text:
          'Какую из газоопасных работ на автогазозаправочной станции разрешается проводить бригадой, состоящей из двух работников под руководством наиболее квалифицированного работника?',
        answers: [
          'Отсоединение от газопроводов технических устройств и их отдельных узлов',
          'Наполнение резервуаров газом из автоцистерн при эксплуатации',
          'Подготовку резервуаров к техническому освидетельствованию',
          'Раскопку грунта в местах утечек газа до их устранения',
        ],
        correctAnswers: [
          'Наполнение резервуаров газом из автоцистерн при эксплуатации',
        ],
      },
      {
        code: '63690016',
        text:
          'С какой периодичностью на автогазозаправочной станции должна проводиться проверка параметров настройки клапанов для ПСК резервуаров и их регулировка на стенде или на месте их установки с помощью специального приспособления?',
        answers: [
          'Ежемесячно',
          'Не реже одного раза в 2 месяца',
          'Ежеквартально',
          'Не реже одного раза в 6 месяцев',
          'Ежегодно',
        ],
        correctAnswers: ['Не реже одного раза в 6 месяцев'],
      },
      {
        code: '63690017',
        text:
          'В какие сроки проводится проверка загазованности колодцев подземных коммуникаций в пределах территории автогазозаправочных станций?',
        answers: [
          'По графику, утвержденному техническим руководителем организации, эксплуатирующей автозаправочную станцию',
          'В начале каждой недели',
          'В начале и в конце каждого месяца',
          'В начале каждого месяца',
        ],
        correctAnswers: [
          'По графику, утвержденному техническим руководителем организации, эксплуатирующей автозаправочную станцию',
        ],
      },
      {
        code: '63690018',
        text:
          'Что должно быть размещено на рабочих местах работников, обслуживающих автогазозаправочные станции?',
        answers: [
          'Ежеквартальные планы технического обслуживания оборудования и газопроводов',
          'Технологические схемы автогазозаправочных станций',
          'Плакаты с правилами пожарной безопасности',
          'Графики дежурств',
        ],
        correctAnswers: ['Технологические схемы автогазозаправочных станций'],
      },
      {
        code: '63690019',
        text:
          'Когда на АГНКС должен быть произведен пуск вытяжных вентиляционных систем?',
        answers: [
          'За 15 минут до начала работы технологического оборудования',
          'Одновременно с началом работы технологического оборудования',
          'Через 15 минут после начала работы технологического оборудования',
          'Одновременно с включением приточных систем вентиляции',
          'Через 15 минут после включения приточных систем вентиляции',
        ],
        correctAnswers: [
          'За 15 минут до начала работы технологического оборудования',
        ],
      },
      {
        code: '63690020',
        text:
          'Какое утверждение противоречит требованиям промышленной безопасности при организации ремонтных работ на АГЗС?',
        answers: [
          'Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты воздухом после отсоединения их от элементов технологической системы с помощью заглушек',
          'Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты паром после отсоединения их от элементов технологической системы с помощью заглушек',
          'Заглушки, устанавливаемые на газопроводах, должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев',
          'На хвостовиках заглушек, устанавливаемых на газопроводах, должно быть выбито клеймо с указанием давления СУГ и диаметра газопровода',
          'Запрещается подчеканивать сварные швы газопроводов для устранения дефектов',
          'Запрещается подтягивать фланцевые соединения, находящиеся под давлением',
        ],
        correctAnswers: [
          'Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты воздухом после отсоединения их от элементов технологической системы с помощью заглушек',
        ],
      },
      {
        code: '63690021',
        text:
          'Допускается ли работа автогазозаправочных станций при отключении от сетей водоснабжения?',
        answers: [
          'Допускается при наличии аварийного запаса воды для технологических нужд',
          'Допускается при возможности подвоза воды в автоцистернах',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690022',
        text:
          'Какое определение соответствует термину "аварийный режим" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Режим, при котором характеристики оборудования для работы во взрывоопасных средах приближаются на определенные в технической документации величины к пределам ограничений, указанных изготовителем',
          'Режим, при котором характеристики оборудования для работы во взрывоопасных средах приближаются к пределам ограничений, указанных изготовителем в технической документации',
          'Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации',
        ],
        correctAnswers: [
          'Режим, при котором характеристики оборудования для работы во взрывоопасных средах выходят за пределы ограничений, указанных изготовителем в технической документации',
        ],
      },
      {
        code: '63690023',
        text:
          'Какое утверждение противоречит требованиям безопасности при освидетельствовании резервуаров СУГ на АГЗС?',
        answers: [
          'Пирофорные отложения, извлеченные из резервуаров, должны поддерживаться во влажном состоянии и вывозиться с территории АГЗС для захоронения в специально отведенном месте',
          'Во время работ в резервуаре допускается нахождение вблизи резервуара, кроме лиц, участвующих в работе, дежурной смены АГЗС',
          'Участки газопроводов с пирофорными отложениями в день их вскрытия должны быть демонтированы и сложены в безопасной зоне',
        ],
        correctAnswers: [
          'Во время работ в резервуаре допускается нахождение вблизи резервуара, кроме лиц, участвующих в работе, дежурной смены АГЗС',
        ],
      },
      {
        code: '63690024',
        text:
          'Кто является ответственным за безопасное проведение пусконаладочных работ на автогазозаправочной станции?',
        answers: [
          'Технический руководитель автозаправочной станции',
          'Руководитель пусконаладочной организации',
          'Руководитель пусконаладочной бригады',
          'Руководитель автозаправочной станции',
        ],
        correctAnswers: ['Руководитель пусконаладочной бригады'],
      },
      {
        code: '63690025',
        text:
          'В каком случае технологическая система автогазозаправочной станции должна быть повторно испытана на герметичность?',
        answers: [
          'Если объект не был введен в эксплуатацию в течение 1 месяца',
          'Если объект не был введен в эксплуатацию в течение 3 месяцев',
          'Если объект не был введен в эксплуатацию в течение 6 месяцев',
          'Повторные испытания на герметичность Правилами безопасности автогазозаправочных станций газомоторного топлива не предусмотрены',
        ],
        correctAnswers: [
          'Если объект не был введен в эксплуатацию в течение 6 месяцев',
        ],
      },
      {
        code: '63690026',
        text:
          'В течение какого времени на автогазозаправочных станциях хранятся журналы регистрации нарядов-допусков на проведение газоопасных работ?',
        answers: [
          'В течение 2 лет после окончания',
          'В течение 5 лет после окончания',
          'В течение 10 лет после окончания',
          'Постоянно',
        ],
        correctAnswers: ['В течение 5 лет после окончания'],
      },
      {
        code: '63690027',
        text:
          'Какое максимальное избыточное давление установлено Правилами безопасности автогазозаправочных станций газомоторного топлива для сжиженного углеводородного газа, используемого в качестве автомобильного топлива на АГЗС?',
        answers: ['0,5 МПа', '1,6 МПа', '2 МПа', '3,2 МПа'],
        correctAnswers: ['1,6 МПа'],
      },
      {
        code: '63690028',
        text:
          'Чьим приказом создается комиссия для технического расследования причин инцидентов на опасном производственном объекте?',
        answers: [
          'Приказом территориального органа Ростехнадзора, в котором зарегистрирован опасный производственный объект',
          'Приказом вышестоящего органа или организации, эксплуатирующей опасный производственный объект',
          'Приказом руководителя организации, эксплуатирующей объект',
          'Приказом Ростехнадзора или его территориального органа',
        ],
        correctAnswers: [
          'Приказом руководителя организации, эксплуатирующей объект',
        ],
      },
      {
        code: '63690029',
        text:
          'На основании чего определяется срок проведения текущего ремонта газопроводов?',
        answers: [
          'На основании эксплуатационной документации',
          'На основании паспорта газопроводов',
          'На основании результатов осмотра',
        ],
        correctAnswers: ['На основании результатов осмотра'],
      },
      {
        code: '63690030',
        text:
          'Кто допускается к выполнению сварочных работ на опасном производственном объекте?',
        answers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
          'Сварщики и специалисты сварочного производства, прошедшие обучение мерам пожарной безопасности в объеме пожарно-технического минимума',
          'Любые лица, обладающие необходимыми умениями и ознакомившиеся с требованиями охраны труда при производстве сварочных работ',
          'Сварщики и специалисты сварочного производства не моложе 21 года, имеющие группу по электробезопасности не ниже III',
        ],
        correctAnswers: [
          'Сварщики и специалисты сварочного производства, обладающие квалификацией, соответствующей видам выполняемых работ и применяемых при этом технологий сварки, и аттестованные для соответствующих способов сварки, видов конструкций, положений при сварке, основных и сварочных материалов',
        ],
      },
      {
        code: '63690031',
        text:
          'Какие устанавливаются нормы наполнения резервуаров жидкой фазой?',
        answers: [
          'Не более 60% геометрического объема резервуара',
          'Не более 75% геометрического объема резервуара',
          'Устанавливаются проектной документацией, в случае ее отсутствия - не более 85% геометрического объема резервуара',
          'Не более 95% геометрического объема резервуара',
        ],
        correctAnswers: [
          'Устанавливаются проектной документацией, в случае ее отсутствия - не более 85% геометрического объема резервуара',
        ],
      },
      {
        code: '63690032',
        text:
          'Какое максимальное избыточное давление установлено Правилами безопасности автогазозаправочных станций газомоторного топлива для компримированного природного газа, используемого в качестве автомобильного топлива на АГНКС?',
        answers: ['16 МПа', '25 МПа', '120 МПа', '250 МПа'],
        correctAnswers: ['25 МПа'],
      },
      {
        code: '63690033',
        text:
          'Кем утверждается и с кем согласовывается проект производства работ по капитальному ремонту автогазозаправочной станции?',
        answers: [
          'Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с техническим руководителем организации, эксплуатирующей автозаправочную станцию',
          'Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с территориальным органом Росприроднадзора',
          'Утверждается территориальным органом Ростехнадзора и согласовывается с территориальным органом Росприроднадзора',
          'Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с территориальным органом Ростехнадзора',
          'Утверждается территориальным органом Ростехнадзора и согласовывается с организацией, выполняющей ремонт',
        ],
        correctAnswers: [
          'Утверждается организацией, выполняющей капитальный ремонт, и согласовывается с техническим руководителем организации, эксплуатирующей автозаправочную станцию',
        ],
      },
      {
        code: '63690034',
        text:
          'Какое избыточное давление должно быть в резервуарах (кроме новых резервуаров и резервуаров после технического освидетельствования, диагностирования и ремонта) перед их наполнением?',
        answers: [
          'Не менее 0,01 МПа',
          'Не менее 0,05 МПа',
          'Не менее 0,6 МПа',
          'Не менее 1 МПа',
        ],
        correctAnswers: ['Не менее 0,05 МПа'],
      },
      {
        code: '63690035',
        text:
          'Для чего все трубопроводы АГНКС должны быть испытаны после сборки?',
        answers: [
          'Чтобы установить отсутствие протечек при давлении, равном максимальному рабочему давлению в соответствующей части системы',
          'Чтобы установить отсутствие протечек при максимальном давлении компримированного природного газа на АГНКС, равном 25 МПа',
          'Чтобы проверить прочность трубопроводов в условиях эксплуатации',
        ],
        correctAnswers: [
          'Чтобы установить отсутствие протечек при давлении, равном максимальному рабочему давлению в соответствующей части системы',
        ],
      },
      {
        code: '63690036',
        text:
          'Что из перечисленного должно быть в требованиях по сборке деталей под сварку, содержащихся в ПТД?',
        answers: [
          'Порядок и последовательность сборки',
          'Способы крепления деталей',
          'Размеры, количество и расположение прихваток',
          'Используемые при сборке приспособления и оборудование',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63690037',
        text:
          'Какое максимальное содержание кислорода допускается после продувки технических устройств инертным газом при пусконаладочных работах по вводу в эксплуатацию автогазозаправочных станций?',
        answers: ['0,01', '0,02', '0,05', '0,03'],
        correctAnswers: ['0,01'],
      },
      {
        code: '63690038',
        text:
          'Кем выдается разрешение на пуск и остановку технологического оборудования автогазозаправочной станции?',
        answers: [
          'Работником, ответственным за производство газоопасных работ',
          'Работником, ответственным за безопасную эксплуатацию АГЗС',
          'Техническим руководителем АГЗС (руководителем АГЗС)',
          'Любым инженерно-техническим работником',
          'Сменным мастером',
        ],
        correctAnswers: ['Техническим руководителем АГЗС (руководителем АГЗС)'],
      },
      {
        code: '63690039',
        text:
          'Какое утверждение противоречит требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к эксплуатации вентиляционных систем на АГНКС?',
        answers: [
          'При выявлении в помещениях АГНКС опасной концентрации газа необходимо обеспечить дополнительную вентиляцию для обеспечения возможности продолжения работ и устранить причины загазованности',
          'Отсосы воздуха вытяжных вентиляционных систем помещений должны быть закрыты сеткой, предотвращающей попадание в систему посторонних предметов',
          'Мягкие вставки вентиляционных систем должны быть герметичны',
          'Воздуховоды должны быть заземлены',
        ],
        correctAnswers: [
          'При выявлении в помещениях АГНКС опасной концентрации газа необходимо обеспечить дополнительную вентиляцию для обеспечения возможности продолжения работ и устранить причины загазованности',
        ],
      },
      {
        code: '63690040',
        text:
          'Применение какой маркировки допускается при выполнении одного сварного соединения несколькими сварщиками?',
        answers: [
          'Допускается применение клейма, определенного распорядительным документом организации, выполняющей сварочные работы',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
          'Должны быть поставлены клейма всех сварщиков, участвовавших в сварке',
          'Допускается применение клейма сварщика, выполнившего наибольший объем работ',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного распорядительным документом организации, выполняющей сварочные работы',
        ],
      },
      {
        code: '63690041',
        text:
          'В течение какого времени после получения оперативного сообщения об аварии издается приказ органом, производящим расследование?',
        answers: [
          'Не позднее 6 часов',
          'Не позднее 12 часов',
          'Не позднее 24 часов',
          'Не позднее 2 суток',
          'Не позднее 3 суток',
        ],
        correctAnswers: ['Не позднее 24 часов'],
      },
      {
        code: '63690042',
        text:
          'Какие документы должны быть разработаны на автогазозаправочных станциях?',
        answers: [
          'Производственные и должностные инструкции, графики по техническому обслуживанию, текущему и капитальному ремонту',
          'Производственные инструкции и ежеквартальные планы работ по техническому обслуживанию и текущему ремонту',
          'Должностные инструкции и ежемесячные планы работ по техническому обслуживанию',
        ],
        correctAnswers: [
          'Производственные и должностные инструкции, графики по техническому обслуживанию, текущему и капитальному ремонту',
        ],
      },
      {
        code: '63690043',
        text:
          'Какое утверждение противоречит требованиям безопасности при производстве газоопасных работ на автогазозаправочной станции?',
        answers: [
          'Допускается проведение сварки и резки на действующих газопроводах, а также разборка фланцевых и резьбовых соединений после продувки воздухом',
          'Насосы и компрессоры на время производства газоопасных работ в помещении насосов и компрессоров должны быть остановлены',
          'На газопроводах у закрытых отключающих устройств должны быть установлены заглушки с хвостовиками',
          'Сбрасывать газ из участка газопровода, подлежащего ремонту, следует через продувочные свечи',
        ],
        correctAnswers: [
          'Допускается проведение сварки и резки на действующих газопроводах, а также разборка фланцевых и резьбовых соединений после продувки воздухом',
        ],
      },
      {
        code: '63690044',
        text:
          'С какой периодичностью проводится текущий ремонт запорной арматуры на автогазозаправочных станциях?',
        answers: [
          'Ежемесячно',
          'Ежеквартально',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 12 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63690045',
        text:
          'Кто на автогазозаправочной станции продлевает наряд-допуск на проведение газоопасных работ?',
        answers: [
          'Работник, ответственный за производство газоопасной работы',
          'Любой инженерно-технический работник',
          'Руководитель автозаправочной станции',
          'Лицо, выдавшее наряд-допуск',
          'Сменный мастер',
        ],
        correctAnswers: ['Лицо, выдавшее наряд-допуск'],
      },
      {
        code: '63690046',
        text:
          'Кем на автогазозаправочной станции выдаются наряды-допуски на проведение газоопасных работ?',
        answers: [
          'Работником, ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности',
          'Ответственным лицом, утвержденным руководителем организации/структурного подразделения, эксплуатирующей автогазозаправочные станции',
          'Работником, ответственным за безопасную эксплуатацию автозаправочной станции',
          'Сменным мастером',
        ],
        correctAnswers: [
          'Ответственным лицом, утвержденным руководителем организации/структурного подразделения, эксплуатирующей автогазозаправочные станции',
        ],
      },
      {
        code: '63690047',
        text:
          'Какое утверждение противоречит требованиям безопасности при проведении газоопасных работ на автогазозаправочной станции?',
        answers: [
          'Перед началом газоопасной работы лицо, ответственное за ее проведение, обязано провести инструктаж исполнителей о необходимых мерах безопасности при выполнении работы, а рабочие самостоятельно обязаны проверить наличие и исправность средств индивидуальной защиты',
          'Перед началом газоопасных работ должны быть выполнены подготовительные мероприятия, предусмотренные в наряде-допуске, плане производства работ и производственных инструкциях',
          'Если работа не закончена, а условия ее проведения и характер не изменились, наряд-допуск может быть продлен лицом, его выдавшим',
          'Наряд-допуск на производство газоопасных работ может быть продлен не более чем на 1 дневную рабочую смену',
        ],
        correctAnswers: [
          'Перед началом газоопасной работы лицо, ответственное за ее проведение, обязано провести инструктаж исполнителей о необходимых мерах безопасности при выполнении работы, а рабочие самостоятельно обязаны проверить наличие и исправность средств индивидуальной защиты',
        ],
      },
      {
        code: '63690048',
        text:
          'Какое определение соответствует термину "взрывоопасная зона" в соответствии с ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования',
          'Зона с высоким риском воспламенения окружающей взрывоопасной среды, связанного с возможностью причинения вреда и (или) нанесения ущерба',
          'Замкнутое пространство, в котором образовалась взрывоопасная среда и требуется незамедлительное применение средств взрывозащиты',
          'Зона, в которой произошло разрушение оборудования, сопровождающееся выбросом опасных веществ и отключением электроэнергии',
        ],
        correctAnswers: [
          'Часть замкнутого или открытого пространства, в которой присутствует или может образоваться взрывоопасная среда в объеме, требующем специальных мер защиты при конструировании, изготовлении, монтаже и эксплуатации оборудования',
        ],
      },
      {
        code: '63690049',
        text: 'Кем проводятся испытания вентиляционных систем на АГНКС?',
        answers: [
          'Специальной комиссией, назначенной приказом руководителя АГНКС',
          'Эксплуатационным персоналом АГНКС',
          'Специализированной организацией',
        ],
        correctAnswers: ['Специализированной организацией'],
      },
      {
        code: '63690050',
        text:
          'В какое время суток осуществляется слив СУГ из автомобильных цистерн в резервуары?',
        answers: [
          'В светлое время суток или при обеспечении необходимого освещения, соответствующего освещенности в светлое время суток',
          'В любое время суток вне зависимости от условий',
          'Только в светлое время суток',
          'В темное время суток',
        ],
        correctAnswers: [
          'В светлое время суток или при обеспечении необходимого освещения, соответствующего освещенности в светлое время суток',
        ],
      },
      {
        code: '63690051',
        text:
          'Кто на автогазозаправочной станции является ответственным за своевременный пересмотр плана мероприятий по локализации и ликвидации аварий?',
        answers: [
          'Работник, ответственный за осуществление производственного контроля за соблюдением требований промышленной безопасности',
          'Технический руководитель АГНКС (руководитель АГНКС), относящейся к II, III классу опасности',
          'Руководитель аварийно-спасательного формирования',
        ],
        correctAnswers: [
          'Технический руководитель АГНКС (руководитель АГНКС), относящейся к II, III классу опасности',
        ],
      },
      {
        code: '63690052',
        text:
          'Когда на АГНКС должно производиться включение приточных систем вентиляции?',
        answers: [
          'За 30 минут до включения вытяжных вентиляционных систем',
          'За 15 минут до включения вытяжных вентиляционных систем',
          'Одновременно с включением вытяжных вентиляционных систем',
          'Через 15 минут после включения вытяжных вентиляционных систем',
          'Через 30 минут после включения вытяжных вентиляционных систем',
        ],
        correctAnswers: [
          'Через 15 минут после включения вытяжных вентиляционных систем',
        ],
      },
      {
        code: '63690053',
        text:
          'С какой периодичностью при продолжении работ в колодце на территории АГЗС более 1 часа необходимо проводить проверку загазованности и вентиляцию колодца?',
        answers: [
          'Каждые 5 минут',
          'Каждые 10 минут',
          'Каждые 15 минут',
          'Каждые 30 минут',
          'Каждый час',
        ],
        correctAnswers: ['Каждые 15 минут'],
      },
      {
        code: '63690054',
        text:
          'К выполнению каких работ могут быть допущены сварщики и специалисты сварочного производства?',
        answers: [
          'К сварочным работам, на которые они когда-либо были аттестованы независимо от того, истек ли срок действия соответствующих аттестационных удостоверений',
          'К выполнению сварочных работ, указанных в действующих документах',
          'К сварочным работам, указанным в проекте производства работ',
          'К любым сварочным работам',
        ],
        correctAnswers: [
          'К выполнению сварочных работ, указанных в действующих документах',
        ],
      },
      {
        code: '63690055',
        text:
          'Допускается ли работа насосов, компрессоров и испарителей с отключенной автоматикой, аварийной сигнализацией, а также блокировкой с вентиляторами вытяжных систем?',
        answers: [
          'Допускается только по письменному распоряжению технического руководителя автозаправочной станции на время, которое требуется для ремонта автоматики, аварийной сигнализации и блокировки',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690056',
        text:
          'С какой периодичностью проводится регистрация измерений показывающих приборов АГЗС?',
        answers: [
          'Не реже 3 раз в смену',
          'Каждую смену',
          'Не реже 2 раз в неделю',
          'Каждую неделю',
        ],
        correctAnswers: ['Каждую смену'],
      },
      {
        code: '63690057',
        text:
          'Каким должно быть количество работников, выполняющих первоначальный слив СУГ/СПГ в резервуары из автомобильных цистерн?',
        answers: [
          'Не менее двух человек',
          'Не менее трех человек',
          'Не менее четырех человек',
          'Не менее пяти человек',
        ],
        correctAnswers: ['Не менее трех человек'],
      },
      {
        code: '63690058',
        text: 'Что из перечисленного обозначает личные шифры клейм сварщиков?',
        answers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
          'Уникальный шифр, содержащий пятизначное цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и меняющийся при последующих аттестациях',
          'Шифр, содержащий произвольное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации или при приеме на работу',
          'Уникальный шифр, содержащий трехзначное буквенно-цифровое сочетание, присваиваемый сварщику при приеме на работу',
        ],
        correctAnswers: [
          'Уникальный шифр, содержащий четырехзначное буквенно-цифровое сочетание, присваиваемый сварщику при положительных результатах первичной аттестации и остающийся неизменным при последующих аттестациях',
        ],
      },
      {
        code: '63690059',
        text:
          'Гидравлическим испытаниям каким давлением должны подвергаться газозаправочные рукава (РВД) АГНКС?',
        answers: [
          'Рабочим давлением',
          'Равным 1,25 рабочего давления',
          'Равным 1,5 рабочего давления',
          'Равным 2 рабочим давлениям',
        ],
        correctAnswers: ['Равным 1,25 рабочего давления'],
      },
      {
        code: '63690060',
        text:
          'С какой периодичностью на автогазозаправочной станции выполняются техническое обслуживание и ремонты КИП?',
        answers: [
          'С периодичностью, установленной графиком, утвержденным техническим организации, эксплуатирующей автогазозаправочную станцию, но не реже чем указано в эксплуатационной документации изготовителей',
          'С периодичностью, установленной в стандартах на приборы',
          'Не реже 1 раза в 3 года',
          'Ежегодно',
        ],
        correctAnswers: [
          'С периодичностью, установленной графиком, утвержденным техническим организации, эксплуатирующей автогазозаправочную станцию, но не реже чем указано в эксплуатационной документации изготовителей',
        ],
      },
      {
        code: '63690061',
        text: 'Каким должно быть давление СУГ на всасывающей линии насоса?',
        answers: [
          'На 0,01 - 0,02 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
          'На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
          'На 0,2 - 0,5 МПа ниже упругости насыщенных паров жидкой фазы при данной температуре',
          'На 0,5 - 1 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
        ],
        correctAnswers: [
          'На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
        ],
      },
      {
        code: '63690062',
        text:
          'С какой периодичностью на автогазозаправочной станции должна производиться проверка срабатывания устройств сигнализации и блокировок автоматики безопасности?',
        answers: [
          'Не реже 1 раза в смену',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в полугодие',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63690063',
        text:
          'Какой минимальный состав бригады установлен для выполнения газоопасных работ на автогазозаправочной станции?',
        answers: ['Два работника', 'Три работника', 'Четыре работника'],
        correctAnswers: ['Два работника'],
      },
      {
        code: '63690064',
        text:
          'С какой периодичностью на автогазозаправочных станциях должна осуществляться проверка уровня газа в резервуарах?',
        answers: [
          'Ежесменно',
          'Не реже 2 раз в неделю',
          'Еженедельно',
          'Ежемесячно',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63690065',
        text:
          'Какой должна быть концентрация СУГ пробы после дегазации резервуара?',
        answers: [
          'Не должна превышать 5% НКПР',
          'Не должна превышать 7% НКПР',
          'Не должна превышать 10% НКПР',
          'Должна быть нулевой',
        ],
        correctAnswers: ['Не должна превышать 10% НКПР'],
      },
      {
        code: '63690066',
        text:
          'С какой периодичностью должен проводиться контроль герметичности приборов, импульсных трубопроводов и арматуры КИП на автогазозаправочных станциях?',
        answers: [
          'Одновременно с проверкой герметичности технических устройств, но не реже одного раза в полугодие',
          'Одновременно с проверкой герметичности технических устройств, но не реже одного раза в месяц',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: [
          'Одновременно с проверкой герметичности технических устройств, но не реже одного раза в месяц',
        ],
      },
      {
        code: '63690067',
        text: 'Как недопустимо дегазировать резервуары СУГ?',
        answers: [
          'Пропариванием и продувкой инертным газом',
          'Заполнением теплой водой',
          'Продувкой воздухом',
        ],
        correctAnswers: ['Продувкой воздухом'],
      },
      {
        code: '63690068',
        text:
          'Техническая документация, которая прилагается при поставке оборудования потребителю, не включает:',
        answers: [
          'Перечень критических отказов, возможных ошибок персонала (пользователя), приводящих к аварийным режимам оборудования, и действий, предотвращающих указанные ошибки',
          'Информацию о мерах, которые следует предпринять при обнаружении неисправности оборудования',
          'Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах',
          'Назначенные показатели срока службы и (или) назначенный ресурс',
          'Дату изготовления оборудования',
          'Требования к персоналу',
        ],
        correctAnswers: [
          'Обоснование безопасности оборудования, предназначенного для работы во взрывоопасных средах',
        ],
      },
      {
        code: '63690069',
        text:
          'Какое утверждение противоречит требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к выполнению аварийных работ на АГНКС?',
        answers: [
          'Аварийные работы на АГНКС выполняются аварийными службами газораспределительных организаций. Участие персонала АГНКС в аварийных работах на АГНКС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий',
          'Технический руководитель АГНКС (руководитель АГНКС), относящейся к II, III классу опасности, является лицом, ответственным за своевременный пересмотр плана мероприятий по локализации и ликвидации последствий аварий',
          'Действия по локализации и ликвидации аварий персонала АГНКС, относящихся ко II, III классу опасности, определяются планом мероприятий по локализации и ликвидации последствий аварий на АГНКС',
        ],
        correctAnswers: [
          'Аварийные работы на АГНКС выполняются аварийными службами газораспределительных организаций. Участие персонала АГНКС в аварийных работах на АГНКС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63690070',
        text:
          'Какое утверждение противоречит требованиям промышленной безопасности при организации ремонтных работ на АГЗС?',
        answers: [
          'Перед началом продувки и испытаний газопроводов следует определить и обозначить знаками опасные зоны, в которых запрещено находиться людям, не задействованным в проведении данных работ',
          'Газопроводы и арматура на них перед началом ремонта должны быть освобождены от СУГ и продуты инертным газом или паром',
          'Допускается заполнение СУГ резервуаров путем снижения в них давления СУГ за счет сброса паровой фазы СУГ в атмосферу',
          'Запрещается в местах забора воздуха выполнять работы, вызывающие попадание паров СУГ в воздухозаборы',
        ],
        correctAnswers: [
          'Допускается заполнение СУГ резервуаров путем снижения в них давления СУГ за счет сброса паровой фазы СУГ в атмосферу',
        ],
      },
      {
        code: '63690071',
        text:
          'Каким веществом разбавляют пенообразующий раствор при контрольной опрессовке сварных стыков, резьбовых и фланцевых соединений, сальниковых уплотнений газопроводов и газового оборудования автогазозаправочных станций в условиях отрицательных температур?',
        answers: [
          'Этиленгликолем',
          'Бензпиреном',
          'Глицерином',
          'Изопреном',
          'Спиртом',
        ],
        correctAnswers: ['Спиртом'],
      },
      {
        code: '63690072',
        text:
          'С какой периодичностью на автогазозаправочных станциях должен осуществляться осмотр резервуаров и арматуры в целях выявления и устранения неисправностей и утечек газа?',
        answers: ['Ежесменно', '2 раза в неделю', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63690073',
        text:
          'Кто должен контролировать надлежащее состояние систем наблюдения, оповещения и связи при организации ремонтных работ на АГЗС?',
        answers: [
          'Специальная комиссия, утверждаемая техническим руководителем организации',
          'Технический руководитель АГЗС (руководитель АГЗС)',
          'Руководитель ремонтной бригады',
          'Начальник ремонтного участка',
        ],
        correctAnswers: ['Технический руководитель АГЗС (руководитель АГЗС)'],
      },
      {
        code: '63690074',
        text:
          'Каким образом должен осуществляться слив СУГ/СПГ из автоцистерн на АГЗС?',
        answers: [
          'Только путем передавливания среды из сосуда автоцистерны в резервуар автозаправочной станции',
          'Только самотеком при расположении резервуаров ниже цистерны',
          'Только перекачиванием СУГ/СПГ насосами',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63690075',
        text:
          'Каким составом бригады разрешается проводить наполнение резервуаров СУГ/СПГ из автоцистерн при эксплуатации АГЗС?',
        answers: [
          'Бригадой из двух работников, при наличии быстросъемных соединений - одним работником',
          'Бригадой не менее чем из трех работников',
          'Бригадой не менее чем из четырех работников',
          'Бригадой не менее чем из пяти работников',
        ],
        correctAnswers: [
          'Бригадой из двух работников, при наличии быстросъемных соединений - одним работником',
        ],
      },
      {
        code: '63690076',
        text:
          'Какая из перечисленных газоопасных работ проводится по наряду-допуску и плану производства работ, утвержденному техническим руководителем АГЗС (руководителем АГЗС)?',
        answers: [
          'Установка и снятие заглушек на действующих газопроводах',
          'Раскопка грунта в местах утечки газа до ее устранения',
          'Ремонт, осмотр и проветривание колодцев',
          'Расконсервация оборудования',
        ],
        correctAnswers: ['Расконсервация оборудования'],
      },
      {
        code: '63690077',
        text:
          'Как проводятся работы по устранению утечек газа и ликвидации последствий аварий?',
        answers: [
          'Проводятся без нарядов-допусков до устранения прямой угрозы причинения вреда жизни, здоровью граждан и по наряду-допуску до устранения прямой угрозы причинения вреда окружающей среде, объектам культурного наследия народов Российской Федерации, зданиям и сооружениям',
          'Проводятся без нарядов-допусков до устранения прямой угрозы причинения вреда жизни, здоровью граждан, вреда животным, растениям, окружающей среде, объектам культурного наследия народов Российской Федерации, зданиям и сооружениям',
          'Проводятся строго по наряду-допуску',
        ],
        correctAnswers: [
          'Проводятся без нарядов-допусков до устранения прямой угрозы причинения вреда жизни, здоровью граждан, вреда животным, растениям, окружающей среде, объектам культурного наследия народов Российской Федерации, зданиям и сооружениям',
        ],
      },
      {
        code: '63690078',
        text:
          'Каким образом допускается маркировать сварное соединение, выполненное несколькими сварщиками (бригадой сварщиков)?',
        answers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
          'Допускается применение клейма одного из участвовавших в сварке сварщиков по выбору руководителя сварочных работ',
          'Допускается применение только клейма сварщика, выполнившего наибольший объем работ',
          'Допускается маркировать сварное соединение произвольным способом',
        ],
        correctAnswers: [
          'Допускается применение клейма, определенного документом организации или индивидуальным предпринимателем, выполняющими сварочные работы',
        ],
      },
      {
        code: '63690079',
        text:
          'Какую проверку должен пройти сварщик, впервые приступающий к сварке, перед допуском к работе?',
        answers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
          'Проверку знания правил пожарной безопасности',
          'Проверку знания теоретических основ сварки',
        ],
        correctAnswers: [
          'Проверку путем выполнения и контроля допускного сварного соединения',
        ],
      },
      {
        code: '63690080',
        text:
          'Каким требованиям должна соответствовать катушка, ввариваемая для замены поврежденных участков газопроводов СУГ и деформированных фланцевых соединений?',
        answers: [
          'Должна быть длиной не менее одного диаметра (не менее 200 мм) и быть той же толщины, того же диаметра и выполнена из трубы той же марки стали, что и соединяемые (основные) трубы, а также с разделкой кромок (по возможности)',
          'Должна быть длиной не менее двух диаметров и быть той же толщины, что и соединяемые (основные) трубы, а также с разделкой кромок (по возможности)',
          'Должна быть длиной не менее одного диаметра (не менее 100 мм) и быть толщиной не менее 50% толщины соединяемых (основных) труб',
          'Должна быть меньшего диаметра, чем соединяемые (основные) трубы, и выполнена из трубы той же марки стали',
        ],
        correctAnswers: [
          'Должна быть длиной не менее одного диаметра (не менее 200 мм) и быть той же толщины, того же диаметра и выполнена из трубы той же марки стали, что и соединяемые (основные) трубы, а также с разделкой кромок (по возможности)',
        ],
      },
      {
        code: '63690081',
        text: 'Какой документ дает право на выполнение огневых работ?',
        answers: [
          'Наряд-допуск и план производства работ, утвержденный техническим руководителем АГЗС (руководителем АГЗС)',
          'Письменное разрешение руководителя службы пожарной охраны',
          'Только наряд-допуск на проведение огневых работ',
          'Распоряжение технического руководителя АГЗС',
        ],
        correctAnswers: [
          'Наряд-допуск и план производства работ, утвержденный техническим руководителем АГЗС (руководителем АГЗС)',
        ],
      },
      {
        code: '63690082',
        text:
          'С какой периодичностью во время огневых работ на автогазозаправочной станции должен проводиться анализ воздушной среды на содержание газа?',
        answers: [
          'Не реже чем через каждые 10 минут',
          'Не реже чем через каждые 20 минут',
          'Через каждые 30 минут',
          'Не реже чем через каждый час',
        ],
        correctAnswers: ['Не реже чем через каждые 10 минут'],
      },
      {
        code: '63690083',
        text:
          'Какую шкалу должны иметь манометры, устанавливаемые на оборудовании и газопроводах?',
        answers: [
          'Шкалу, у которой предел измерения рабочего давления находится во второй трети шкалы',
          'Шкалу, у которой предел измерения рабочего давления находится в третьей трети шкалы',
          'Шкалу, у которой предел измерения рабочего давления находится в четвертой четверти шкалы',
        ],
        correctAnswers: [
          'Шкалу, у которой предел измерения рабочего давления находится во второй трети шкалы',
        ],
      },
      {
        code: '63690084',
        text:
          'В соответствии с требованиями каких документов должен осуществляться контроль за техническим состоянием, освидетельствованием, техническим обслуживанием и ремонтом резервуаров автогазозаправочных станций?',
        answers: [
          'Федеральных норм и правил в области промышленной безопасности, устанавливающих требования при использовании оборудования, работающего под избыточным давлением, Правил безопасности автогазозаправочных станций газомоторного топлива и эксплуатационных документов организаций-изготовителей',
          'Правил промышленной безопасности опасных производственных объектов, на которых используется оборудование, работающее под избыточным давлением',
          'Правил безопасности для объектов, использующих СУГ, и эксплуатационных документов организаций-изготовителей',
          'Правил безопасности автогазозаправочных станций газомоторного топлива и проектной документации',
        ],
        correctAnswers: [
          'Федеральных норм и правил в области промышленной безопасности, устанавливающих требования при использовании оборудования, работающего под избыточным давлением, Правил безопасности автогазозаправочных станций газомоторного топлива и эксплуатационных документов организаций-изготовителей',
        ],
      },
      {
        code: '63690085',
        text:
          'С какой периодичностью следует осматривать технологическое оборудование, газопроводы, арматуру, электрооборудование, вентиляционные системы, средства измерений, блокировок и сигнализации в целях выявления неисправностей и их устранения?',
        answers: ['Ежесменно', 'Еженедельно', '2 раза в месяц', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63690086',
        text:
          'В соответствии с чьими указаниями должно содержаться в исправном состоянии, обслуживаться и эксплуатироваться сварочное оборудование?',
        answers: [
          'Ответственного за осуществление производственного контроля',
          'Производителя сварочного оборудования',
          'Руководителя независимого аттестационного центра',
          'Руководителя организации',
        ],
        correctAnswers: ['Производителя сварочного оборудования'],
      },
      {
        code: '63690087',
        text:
          'Какие виды классификаций оборудования для работы во взрывоопасных средах не устанавливает ТР "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Классификация оборудования по группам (в зависимости от области применения)',
          'Классификация оборудования по уровням взрывозащиты',
          'Классификация оборудования по температурным классам',
          'Классификация оборудования по давлению',
          'Классификация взрывоопасных зон',
        ],
        correctAnswers: ['Классификация оборудования по давлению'],
      },
      {
        code: '63690088',
        text:
          'Кем утверждается программа приемочных испытаний (пусконаладочных работ) технологической системы автогазозаправочной станции?',
        answers: [
          'Застройщиком или техническим заказчиком',
          'Территориальным органом Ростехнадзора',
          'Строительно-монтажной организацией',
          'Эксплуатационной организацией',
          'Пусконаладочной организацией',
        ],
        correctAnswers: ['Застройщиком или техническим заказчиком'],
      },
      {
        code: '63690089',
        text:
          'Допускается ли проводить приемку газа, его слив и заправку автомобилей при проведении огневых работ на автогазозаправочной станции?',
        answers: [
          'Допускается, если есть специальное разрешение Государственной противопожарной службы и Ростехнадзора',
          'Допускается, если есть письменное разрешение технического руководителя автозаправочной станции',
          'Допускается, если соблюдаются дополнительные требования пожарной безопасности',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690090',
        text:
          'В каком случае результаты испытания на герметичность газопроводов, резервуаров и технических устройств автогазозаправочной станции считаются положительными?',
        answers: [
          'Если падение давления в газопроводе по манометру класса точности 0,6 не превышает одного деления шкалы, по манометру класса точности 0,4 - двух, а по манометру класса точности 0,15 - трех делений шкалы',
          'При отсутствии видимого падения давления в газопроводе по манометру класса точности 0,6, а по манометрам класса точности 0,15 и 0,4 - если падение давления не превышает одного деления шкалы',
          'Если падение давления в газопроводе по манометру класса точности 0,6 не превышает одного деления шкалы, а по манометрам класса точности 0,4 и 0,15 не превышает двух делений шкалы',
          'Только при отсутствии видимого падения давления в газопроводе',
        ],
        correctAnswers: [
          'При отсутствии видимого падения давления в газопроводе по манометру класса точности 0,6, а по манометрам класса точности 0,15 и 0,4 - если падение давления не превышает одного деления шкалы',
        ],
      },
      {
        code: '63690091',
        text:
          'Каким должно быть давление паров СУГ в цистерне после слива СУГ из нее?',
        answers: [
          'Не ниже 0,005 МПа',
          'Не ниже 0,05 МПа',
          'Не ниже 0,5 МПа',
          'Не ниже 1 МПа',
        ],
        correctAnswers: ['Не ниже 0,05 МПа'],
      },
      {
        code: '63690092',
        text:
          'Когда на АГНКС должны проводиться испытания вентиляционных систем с определением их эксплуатационных технических характеристик?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в год, а также после ремонта, реконструкции',
          'Не реже одного раза в 2 года',
          'Только после ремонта или реконструкции',
        ],
        correctAnswers: [
          'Не реже одного раза в год, а также после ремонта, реконструкции',
        ],
      },
      {
        code: '63690093',
        text:
          'В каких местах производства огневых работ на автогазозаправочной станции перед началом и во время их проведения должен проводиться анализ воздушной среды на содержание газа?',
        answers: [
          'В помещениях, а также в 20-метровой зоне от рабочего места, на территории',
          'В 30-метровой зоне от рабочего места, на территории автозаправочной станции',
          'Только в помещениях, где проводятся огневые работы',
          'В 50-метровой зоне от рабочего места',
        ],
        correctAnswers: [
          'В помещениях, а также в 20-метровой зоне от рабочего места, на территории',
        ],
      },
      {
        code: '63690094',
        text:
          'Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?',
        answers: [
          'Только информацию о принятых мерах по ликвидации инцидента, а также информацию о материальном ущербе, в том числе вреде, нанесенном окружающей среде',
          'Только информацию о продолжительности простоя и мерах по устранению причин инцидента',
          'Только дату и место инцидента, его причины и обстоятельства',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63690095',
        text:
          'Какая максимальная объемная доля кислорода в продувочных газах для резервуаров хранения СПГ установлена Правилами безопасности автогазозаправочных станций газомоторного топлива?',
        answers: [
          '10% минимального взрывоопасного содержания кислорода',
          '20% минимального взрывоопасного содержания кислорода',
          '30% минимального взрывоопасного содержания кислорода',
          '50% минимального взрывоопасного содержания кислорода',
        ],
        correctAnswers: [
          '50% минимального взрывоопасного содержания кислорода',
        ],
      },
      {
        code: '63690096',
        text:
          'Чем должны продуваться газопроводы и оборудование автогазозаправочных станций перед пуском газа?',
        answers: [
          'Инертным газом',
          'Кислородом',
          'Воздухом',
          'Паром',
          'Продувка перед пуском газа не предусматривается',
        ],
        correctAnswers: ['Инертным газом'],
      },
      {
        code: '63690097',
        text:
          'Какая из перечисленных газоопасных работ на автогазозаправочной станции может проводиться без оформления наряда-допуска по утвержденной производственной инструкции?',
        answers: [
          'Пуск газа в газопроводы после окончания строительства, реконструкции, капитального ремонта',
          'Подготовка к техническому освидетельствованию резервуаров',
          'Установка и снятие заглушек на действующих газопроводах',
          'Слив газа из переполненных баллонов',
        ],
        correctAnswers: ['Слив газа из переполненных баллонов'],
      },
      {
        code: '63690098',
        text:
          'Какую проверку должен пройти сварщик, приступающий к сварке на конкретном объекте впервые или после перерыва в работе продолжительностью более установленного НД, независимо от наличия аттестационного удостоверения, до начала производства работ?',
        answers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
          'Проверку умения определять и устранять видимые дефекты сварного соединения',
          'Проверку знания правил пожарной безопасности',
          'Проверку знания теоретических основ сварки',
        ],
        correctAnswers: [
          'Проверку путем выполнения допускных сварных соединений в условиях, соответствующих выполнению производственных сварных соединений на данном объекте, с получением положительных результатов контроля их качества',
        ],
      },
      {
        code: '63690099',
        text:
          'Какая из перечисленных газоопасных работ проводится по наряду-допуску и плану производства работ, утвержденному техническим руководителем АГЗС (руководителем АГЗС)?',
        answers: [
          'Подготовка к техническому освидетельствованию и техническому диагностированию резервуаров',
          'Слив газа из автоцистерн в резервуары, откачка неиспарившихся остатков',
          'Установка и снятие заглушек на действующих газопроводах',
          'Проведение пусконаладочных работ',
        ],
        correctAnswers: ['Проведение пусконаладочных работ'],
      },
      {
        code: '63690100',
        text:
          'По каким уровням взрывозащиты не классифицируется оборудование в зависимости от опасности стать источником воспламенения и условий его применения во взрывоопасных средах?',
        answers: [
          'Взрывобезопасный (высокий)',
          'Низковзрывоопасный (умеренный)',
          'Особовзрывобезопасный (очень высокий)',
          'Повышенная надежность против взрыва (повышенный)',
        ],
        correctAnswers: ['Низковзрывоопасный (умеренный)'],
      },
      {
        code: '63690101',
        text:
          'С какой периодичностью газозаправочные рукава (РВД) АГНКС должны подвергаться гидравлическим испытаниям?',
        answers: [
          'Один раз в 3 месяца',
          'Один раз в 6 месяцев',
          'Один раз в год',
          'Один раз в 2 года',
        ],
        correctAnswers: ['Один раз в 6 месяцев'],
      },
      {
        code: '63690102',
        text:
          'Чем должно быть укомплектовано место производства сварочных работ?',
        answers: [
          'Сварочным оборудованием, установками пожаротушения и местной приточной вентиляции',
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
          'Инструментом, установками пожарной сигнализации, средствами канатного доступа',
        ],
        correctAnswers: [
          'Исправным сварочным оборудованием, технологической оснасткой и инструментом в соответствии с требованиями ПТД',
        ],
      },
      {
        code: '63690103',
        text:
          'С какой периодичностью необходимо окрашивать наружные металлические конструкции зданий и сооружений с целью предохранения от коррозии?',
        answers: [
          'Ежеквартально',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в 12 месяцев'],
      },
      {
        code: '63690104',
        text:
          'Какое утверждение противоречит требованиям промышленной безопасности к эксплуатации насосов на АГЗС?',
        answers: [
          'Не допускается работа насосов (при установке их в помещении) при отключенной вентиляции, КИП или их отсутствии, при наличии в помещении концентрации СУГ, превышающей 5% НКПР',
          'В случае превышения предусмотренного проектной документацией давления на нагнетательных линиях насосов электродвигатели должны автоматически отключаться',
          'Сведения о режиме работы, количестве отработанного времени насосов, а также неполадках в работе должны фиксироваться в эксплуатационном журнале',
        ],
        correctAnswers: [
          'Не допускается работа насосов (при установке их в помещении) при отключенной вентиляции, КИП или их отсутствии, при наличии в помещении концентрации СУГ, превышающей 5% НКПР',
        ],
      },
      {
        code: '63690105',
        text:
          'Какие требования предъявляются ФНП "Требования к производству сварочных работ на опасных производственных объектах" к сварочному оборудованию и сварочным материалам, применяемым при выполнении сварочных работ?',
        answers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
          'Оборудование и материалы должны обеспечивать максимальную производительность работ',
          'Оборудование и материалы должны быть экономичными в эксплуатации',
          'Оборудование и материалы должны быть простыми в ремонте',
        ],
        correctAnswers: [
          'Оборудование и материалы должны соответствовать применяемым аттестованным технологиям сварки, обладать сварочно-технологическими характеристиками и качествами, обеспечивающими свойства сварных соединений в пределах значений, установленных требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63690106',
        text:
          'Допускается ли проведение работ на АГНКС при неисправности вентиляционных систем и недостаточном воздухообмене в производственных помещениях?',
        answers: [
          'Допускается в течение времени, необходимого для завершения технологических операций',
          'Допускается по письменному разрешению технического руководителя АГНКС',
          'Допускается в течение не более 1 часа после возникновения неисправности',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690107',
        text:
          'Как на АГЗС устанавливаются сроки технического обслуживания, текущего и капитального ремонтов насосов?',
        answers: [
          'Графиками, утвержденными техническим руководителем АГЗС (руководителем АГЗС), но не реже указанных в эксплуатационной документации на насосы',
          'Правилами безопасности автогазозаправочных станций газомоторного топлива',
          'Эксплуатационной документацией на насосы и другое оборудование',
          'Графиками, утвержденными руководителем ремонтного персонала',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: [
          'Графиками, утвержденными техническим руководителем АГЗС (руководителем АГЗС), но не реже указанных в эксплуатационной документации на насосы',
        ],
      },
      {
        code: '63690108',
        text:
          'С какой периодичностью на автогазозаправочных станциях стационарные и переносные газоанализаторы и сигнализаторы должны проходить проверку контрольными смесями на срабатывание?',
        answers: [
          'Ежемесячно',
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в шесть месяцев',
          'Ежегодно',
        ],
        correctAnswers: ['Не реже одного раза в три месяца'],
      },
      {
        code: '63690109',
        text:
          'С какой периодичностью на АГНКС должен проводиться наружный осмотр газопроводов для выявления неплотностей в сварных стыках, фланцевых и резьбовых соединениях, сальниковых уплотнениях и определения состояния теплоизоляции и окраски?',
        answers: [
          'Ежемесячно',
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в полгода',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63690110',
        text:
          'Какая концентрация СУГ в воздухе вне помещений АГЗС считается опасной?',
        answers: ['5% НКПР', '10% НКПР', '15% НКПР', '20% НКПР'],
        correctAnswers: ['20% НКПР'],
      },
      {
        code: '63690111',
        text:
          'Допускается ли эксплуатация отдельных элементов автогазозаправочных станций при срабатывании автоматики безопасности?',
        answers: [
          'Допускается, если не отключились резервные и аварийные источники питания',
          'Не допускается до устранения причин ее срабатывания',
          'Допускается, если не завершены технологические процессы',
          'Допускается',
        ],
        correctAnswers: ['Не допускается до устранения причин ее срабатывания'],
      },
      {
        code: '63690112',
        text:
          'Кто обеспечивает организацию и выполнение аттестационных процедур согласно требованиям к производству сварочных работ на опасных производственных объектах?',
        answers: [
          'Руководитель независимого аттестационного центра',
          'Технический руководитель организации',
          'Руководитель организации',
          'Инспектор Ростехнадзора',
        ],
        correctAnswers: ['Руководитель независимого аттестационного центра'],
      },
      {
        code: '63690113',
        text:
          'Какую из газоопасных работ на автогазозаправочной станции разрешается проводить бригадой, состоящей из двух работников под руководством наиболее квалифицированного работника?',
        answers: [
          'Проведение технического обслуживания газопроводов и технических устройств',
          'Установку и снятие заглушек на действующих газопроводах',
          'Первичное наполнение резервуаров газом из автоцистерн',
        ],
        correctAnswers: [
          'Проведение технического обслуживания газопроводов и технических устройств',
        ],
      },
      {
        code: '63690114',
        text: 'Что включает в себя капитальный ремонт газопроводов?',
        answers: [
          'Только ремонт изоляции на поврежденных участках газопровода',
          'Только замену или усиление подвижных и неподвижных опор',
          'Только замену арматуры (при наличии дефектов)',
          'Только замену участков газопроводов',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63690115',
        text:
          'Куда организация, на объекте которой произошла авария, после окончания расследования обязана направить экземпляры материалов технического расследования причин аварии?',
        answers: [
          'Только в Ростехнадзор (иной федеральный орган исполнительной власти в области промышленной безопасности) или его территориальный орган, сформировавший комиссию по проведению технического расследования',
          'Только в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию',
          'Только в органы, определенные председателем комиссии',
          'Во все перечисленные органы',
        ],
        correctAnswers: ['Во все перечисленные органы'],
      },
      {
        code: '63690116',
        text:
          'В течение какого срока должен быть составлен акт технического расследования причин аварии на опасных производственных объектах?',
        answers: [
          'Не позднее 10 рабочих дней',
          'Не позднее 14 календарных дней',
          'Не позднее 30 календарных дней',
          'Не позднее 60 календарных дней',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63690117',
        text:
          'Кем осуществляется контроль за выполнением и приемкой работ по капитальному ремонту автогазозаправочной станции?',
        answers: [
          'Лицом, назначенным техническим руководителем организации, эксплуатирующей автогазозаправочную станцию',
          'Лицом, назначенным техническим руководителем организации, выполняющей капитальный ремонт',
          'Техническим руководителем организации, выполняющей капитальный ремонт',
          'Должностным лицом Ростехнадзора',
        ],
        correctAnswers: [
          'Лицом, назначенным техническим руководителем организации, эксплуатирующей автогазозаправочную станцию',
        ],
      },
      {
        code: '63690118',
        text:
          'Какие требования устанавливаются для рабочих автогазозаправочных станций для их допуска к выполнению газоопасных работ?',
        answers: [
          'Должны пройти профессиональное обучение по профессии "слесарь по эксплуатации и ремонту газового оборудования" (слесарь аварийно-восстановительных работ) с правом проведения газоопасных работ и иметь удостоверение, должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи',
          'Должны быть обучены технике проведения газоопасных работ, правилам использования средств индивидуальной защиты, способам оказания первой помощи',
          'Должны быть обучены требованиям охраны труда, технике проведения газоопасных работ, способам оказания первой помощи',
        ],
        correctAnswers: [
          'Должны пройти профессиональное обучение по профессии "слесарь по эксплуатации и ремонту газового оборудования" (слесарь аварийно-восстановительных работ) с правом проведения газоопасных работ и иметь удостоверение, должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи',
        ],
      },
      {
        code: '63690119',
        text:
          'Каким количеством огнетушителей должна быть оснащена автоцистерна с СУГ?',
        answers: [
          'Одним огнетушителем',
          'Двумя огнетушителями',
          'Тремя огнетушителями',
          'Не регламентируется',
        ],
        correctAnswers: ['Двумя огнетушителями'],
      },
      {
        code: '63690120',
        text:
          'Каких пределов не должна превышать степень заполнения резервуаров СПГ на КриоАЗС?',
        answers: [
          '50% геометрического объема резервуара',
          '85% геометрического объема резервуара',
          '95% геометрического объема резервуара',
          'Пределов, указанных в паспортах на оборудование систем хранения, выдачи и регазификации СПГ',
        ],
        correctAnswers: [
          'Пределов, указанных в паспортах на оборудование систем хранения, выдачи и регазификации СПГ',
        ],
      },
      {
        code: '63690121',
        text:
          'Что следует применять при сливо-наливных операциях на АГЗС для оттаивания арматуры и газопроводов при образовании гидратных пробок?',
        answers: [
          'Нагретый песок, горячую воду или водяной пар',
          'Газовую горелку',
          'Паяльную лампу',
          'Факел',
        ],
        correctAnswers: ['Нагретый песок, горячую воду или водяной пар'],
      },
      {
        code: '63690122',
        text:
          'С какой периодичностью на АГЗС осматриваются металлические конструкции?',
        answers: [
          'Ежемесячно',
          'Ежеквартально',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 12 месяцев',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в 12 месяцев'],
      },
      {
        code: '63690123',
        text:
          'Что из перечисленного должно обеспечить лицо, осуществляющее руководство сварочными работами при производстве сварочных работ?',
        answers: [
          'Только идентификацию мест расположения сварных соединений в конструкции и мест исправлений дефектов сварных соединений',
          'Только выполнение сварных соединений в соответствии с технологическими (операционными) картами сварки',
          'Только идентификацию применяемых сварочных материалов и сварочного оборудования',
          'Только регистрацию сведений о сварщиках, выполняющих сварные соединения',
          'Обеспечить все перечисленное',
        ],
        correctAnswers: ['Обеспечить все перечисленное'],
      },
      {
        code: '63690124',
        text:
          'Какое утверждение соответствует требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к хранению СПГ на КриоАЗС?',
        answers: [
          'Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,05 МПа)',
          'Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление должно быть равным минимальному давлению, установленному руководством по эксплуатации резервуара',
          'Хранение СПГ в резервуарах разрешается только с непрерывным газосбросом. Избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,5 МПа)',
          'Хранение СПГ в резервуарах разрешается только с периодическим газосбросом. Избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 1 МПа)',
        ],
        correctAnswers: [
          'Хранение СПГ в резервуарах разрешается как с периодическим, так и с непрерывным газосбросом. В обоих случаях избыточное давление не должно быть ниже минимального давления, установленного руководством по эксплуатации резервуара (не менее 0,05 МПа)',
        ],
      },
      {
        code: '63690125',
        text:
          'Каким должно быть значение давления настройки предохранительных сбросных клапанов?',
        answers: [
          'Не должно превышать более чем на 15% рабочее давление в резервуарах и газопроводах',
          'Должно составлять не более 90% максимального давления в резервуарах и газопроводах',
          'Должно быть равным максимальному давлению в резервуарах и газопроводах',
          'Не должно превышать максимальное давление в резервуарах и газопроводах',
        ],
        correctAnswers: [
          'Не должно превышать более чем на 15% рабочее давление в резервуарах и газопроводах',
        ],
      },
      {
        code: '63690126',
        text:
          'Что из перечисленного должно контролироваться в процессе сварки, как минимум?',
        answers: [
          'Соответствие параметров режима сварки и технологических приемов выполнения сварного соединения',
          'Очередность выполнения сварных швов и участков наплавки',
          'Отсутствие видимых дефектов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63690127',
        text: 'На основании какого документа проводятся газоопасные работы?',
        answers: [
          'Наряда–допуска или без оформления наряда-допуска, но с регистрацией в журнале газоопасных работ',
          'Письменного распоряжения технического руководителя автозаправочной станции',
          'Письменного распоряжения руководителя автозаправочной станции',
          'Плана производства работ',
        ],
        correctAnswers: [
          'Наряда–допуска или без оформления наряда-допуска, но с регистрацией в журнале газоопасных работ',
        ],
      },
      {
        code: '63690128',
        text:
          'Что является идентификационным признаком оборудования для работы во взрывоопасных средах?',
        answers: [
          'Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование',
          'Внешний вид оборудования (наличие глухих металлических корпусов, кожухов, защитных решеток и т. п.)',
          'Только наличие сертификата взрывозащиты, выданного аккредитованным органом по сертификации',
          'Только наличие маркировки взрывозащиты',
        ],
        correctAnswers: [
          'Наличие средств обеспечения взрывозащиты, указанных в технической документации изготовителя, и маркировки взрывозащиты, нанесенной на оборудование',
        ],
      },
      {
        code: '63690129',
        text:
          'Что устанавливают Правила безопасности автогазозаправочных станций газомоторного топлива?',
        answers: [
          'Обязательные требования к эксплуатации автогазозаправочных станций, в том числе к системам безопасности и работникам, а также к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, хранения сжиженных углеводородных газов, компримированного природного газа и сжиженного природного газа на автогазозаправочных станциях',
          'Обязательные требования к эксплуатации автозаправочных станций, а также к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, к эксплуатации газопроводов и оборудования подачи природного газа на заправочные станции',
          'Обязательные требования к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, к эксплуатации газопроводов и оборудования подачи природного газа на заправочные станции',
          'Обязательные требования к эксплуатации автозаправочных станций, к эксплуатации газопроводов и оборудования подачи природного газа на заправочные станции',
        ],
        correctAnswers: [
          'Обязательные требования к эксплуатации автогазозаправочных станций, в том числе к системам безопасности и работникам, а также к эксплуатации технических устройств, применяемых для сжатия и сжижения природного газа, хранения сжиженных углеводородных газов, компримированного природного газа и сжиженного природного газа на автогазозаправочных станциях',
        ],
      },
      {
        code: '63690130',
        text:
          'Какое утверждение противоречит требованиям промышленной безопасности при проведении сливо-наливных операций на АГЗС?',
        answers: [
          'Запрещается оставлять автоцистерны присоединенными к газопроводам технологической системы АГЗС в период, когда слив СУГ не производится',
          'По окончании слива СУГ запорная арматура на автоцистерне должна быть закрыта, а сливные штуцеры заглушены',
          'Во время слива СУГ допускается производить работы по уплотнению соединений, находящихся под давлением',
          'В случае поступления автоцистерны с СУГ, имеющей течь, она должна быть немедленно освобождена от СУГ',
        ],
        correctAnswers: [
          'Во время слива СУГ допускается производить работы по уплотнению соединений, находящихся под давлением',
        ],
      },
      {
        code: '63690131',
        text:
          'Кто осуществляет контроль за выполнением работ по капитальному ремонту в соответствии с утвержденной технической документацией и требованиями безопасности с последующей приемкой работ?',
        answers: [
          'Аттестационная комиссия Ростехнадзора',
          'Организация, осуществляющая ремонт',
          'Организация, эксплуатирующая АГНКС',
          'Независимая экспертная организация',
        ],
        correctAnswers: ['Организация, эксплуатирующая АГНКС'],
      },
      {
        code: '63690132',
        text:
          'К какому моменту должна быть обеспечена подготовка и аттестация работников автогазозаправочной станции в области промышленной безопасности, проведена проверка знаний Правил безопасности автогазозаправочных станций газомоторного топлива?',
        answers: [
          'К моменту приемки автозаправочной станции по окончании пусконаладочных работ и комплексного опробования',
          'К моменту ввода автозаправочной станции в эксплуатацию',
          'К моменту проведения пусконаладочных работ',
        ],
        correctAnswers: ['К моменту проведения пусконаладочных работ'],
      },
      {
        code: '63690133',
        text:
          'Какие требования устанавливаются к контрольной опрессовке газопроводов, резервуаров и технических устройств автогазозаправочной станции?',
        answers: [
          'Контрольная опрессовка осуществляется избыточным давлением воздуха или инертного газа 0,3 МПа в течение часа',
          'Контрольная опрессовка осуществляется избыточным давлением воздуха или инертного газа 0,5 МПа в течение часа',
          'Контрольная опрессовка осуществляется избыточным давлением инертного газа 0,1 МПа в течение часа',
          'Контрольная опрессовка осуществляется избыточным давлением воздуха 1 МПа в течение 30 минут',
        ],
        correctAnswers: [
          'Контрольная опрессовка осуществляется избыточным давлением воздуха или инертного газа 0,3 МПа в течение часа',
        ],
      },
      {
        code: '63690134',
        text:
          'Какие действия из перечисленных допускаются при проведении сливо-наливных операций?',
        answers: [
          'Отсоединять заземляющие устройства после окончания сливо-наливных операций и установки заглушек на штуцеры вентилей автоцистерн',
          'Подтягивать накидные гайки рукавов',
          'Отсоединять рукава, находящиеся под давлением',
          'Применять ударный инструмент при навинчивании и отвинчивании гаек',
        ],
        correctAnswers: [
          'Отсоединять заземляющие устройства после окончания сливо-наливных операций и установки заглушек на штуцеры вентилей автоцистерн',
        ],
      },
      {
        code: '63690135',
        text:
          'Какие обозначения наносятся на входе в каждое производственное помещение на АГНКС?',
        answers: [
          'Обозначения категории помещения по пожарной и взрывопожарной опасности и уровень взрывозащиты оборудования, установленного в этом помещении',
          'Обозначения категории помещения по пожарной и взрывопожарной опасности, а также класса конструктивной пожарной опасности',
          'Обозначения класса взрывоопасных зон',
        ],
        correctAnswers: ['Обозначения класса взрывоопасных зон'],
      },
      {
        code: '63690136',
        text:
          'Какие требования необходимо соблюдать перед демонтажем насоса, работающего на СПГ, для ремонта?',
        answers: [
          'Должен быть произведен сброс среды в систему газосброса, произведена смена рабочей среды на инертный газ, и насос должен быть отключен от обвязывающих газопроводов заглушками',
          'Должен быть произведен сброс среды в систему газосброса, затем насос должен быть продут инертным газом и отключен от обвязывающих газопроводов запорной арматурой',
          'Должен быть произведен сброс среды в систему газосброса, затем насос должен быть отключен от обвязывающих газопроводов запорной арматурой и пропарен',
          'Насос должен быть освобожден от СУГ, отключен от обвязывающих газопроводов запорной арматурой и продут инертным газом',
        ],
        correctAnswers: [
          'Должен быть произведен сброс среды в систему газосброса, произведена смена рабочей среды на инертный газ, и насос должен быть отключен от обвязывающих газопроводов заглушками',
        ],
      },
      {
        code: '63690137',
        text:
          'Какая газоопасная работа относится к периодически повторяющимся работам на автогазозаправочной станции?',
        answers: [
          'Ремонтные работы без применения сварки и резки в колодцах, траншеях, заглублениях',
          'Ремонт оборудования насосов, компрессоров, испарителей, резервуаров',
          'Отключение газопроводов от действующей сети и их продувка',
          'Ремонт действующих внутренних и наружных газопроводов',
          'Заполнение резервуаров газом после их ремонта',
        ],
        correctAnswers: [
          'Ремонтные работы без применения сварки и резки в колодцах, траншеях, заглублениях',
        ],
      },
      {
        code: '63690138',
        text:
          'С какой периодичностью должен осуществляться контроль концентрации газа в производственных помещениях переносными газоанализаторами в период замены стационарных сигнализаторов загазованности?',
        answers: [
          'Через каждые 30 минут в течение рабочей смены',
          'Через каждый час в течение рабочей смены',
          'Через каждые 2 часа в течение рабочей смены',
          'Через каждые 4 часа в течение рабочей смены',
        ],
        correctAnswers: ['Через каждые 30 минут в течение рабочей смены'],
      },
      {
        code: '63690139',
        text:
          'На основании чего резервуары автогазозаправочных станций вводятся в эксплуатацию?',
        answers: [
          'На основании письменного разрешения технического руководителя автозаправочной станции, согласованного с инспектором Ростехнадзора',
          'На основании устного разрешения руководителя специализированной организации, проводившей техническое освидетельствование',
          'На основании письменного разрешения ответственного за исправное состояние и безопасную эксплуатацию',
          'На основании устного разрешения руководителя автозаправочной станции',
          'На основании письменного разрешения инспектора Ростехнадзора',
        ],
        correctAnswers: [
          'На основании письменного разрешения ответственного за исправное состояние и безопасную эксплуатацию',
        ],
      },
      {
        code: '63690140',
        text:
          'Каким образом металлокордовые и резинотканевые рукава, применяемые для сливо-наливных операций на АГЗС, должны быть защищены от статического электричества?',
        answers: [
          'Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 2 мм или медным тросиком площадью сечения не менее 4 мм² с шагом витка не более 100 мм',
          'Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 1 мм или медным тросиком площадью сечения не менее 4 мм² с шагом витка не более 200 мм',
          'Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 1,5 мм или медным тросиком площадью сечения не менее 3 мм² с шагом витка не более 100 мм',
        ],
        correctAnswers: [
          'Они должны быть обвиты (или пропущены внутри) медной проволокой диаметром не менее 2 мм или медным тросиком площадью сечения не менее 4 мм² с шагом витка не более 100 мм',
        ],
      },
      {
        code: '63690141',
        text:
          'Требования каких документов необходимо соблюдать при эксплуатации насосов на АГЗС?',
        answers: [
          'Эксплуатационной документации организаций-изготовителей, Правил безопасности автогазозаправочных станций газомоторного топлива и производственных инструкций',
          'Правил безопасности автогазозаправочных станций газомоторного топлива, Правил безопасности для объектов, использующих СУГ, и производственных инструкций',
          'Эксплуатационной документации организаций-изготовителей и Правил безопасности для объектов, использующих СУГ',
        ],
        correctAnswers: [
          'Эксплуатационной документации организаций-изготовителей, Правил безопасности автогазозаправочных станций газомоторного топлива и производственных инструкций',
        ],
      },
      {
        code: '63690142',
        text:
          'Кому сообщается о выявленных неисправностях в работе средств измерений и автоматики безопасности АГЗС?',
        answers: [
          'Работнику, ответственному за осуществление производственного контроля за соблюдением требований промышленной безопасности',
          'Техническому руководителю АГЗС (руководителю АГЗС)',
          'Должностному лицу Ростехнадзора',
          'Начальнику ремонтного участка',
        ],
        correctAnswers: ['Техническому руководителю АГЗС (руководителю АГЗС)'],
      },
      {
        code: '63690143',
        text:
          'С какой периодичностью на АГНКС должна проводиться проверка состояния опор трубопроводов, колодцев?',
        answers: [
          'Ежемесячно',
          'Ежеквартально',
          'Не реже 1 раза в полгода',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63690144',
        text:
          'Какая организация обеспечивает анализ причин возникновения инцидентов на опасном производственном объекте и принятие мер по их профилактике и устранению?',
        answers: [
          'Организация, эксплуатирующая автозаправочную станцию',
          'Научно-исследовательская организация',
          'Проектная организация',
          'Ремонтная организация',
        ],
        correctAnswers: [
          'Организация, эксплуатирующая автозаправочную станцию',
        ],
      },
      {
        code: '63690145',
        text:
          'Что должны включать в себя производственные инструкции по эксплуатации системы вентиляции АГНКС (при установке оборудования в помещении)?',
        answers: [
          'Требования инструкций заводов - изготовителей вентиляционного оборудования, объем и сроки технического обслуживания, текущего и капитального ремонтов',
          'Требования инструкций заводов - изготовителей вентиляционного оборудования, противопожарные мероприятия',
          'Графики, объемы и сроки технического обслуживания, текущего и капитального ремонтов',
          'Графики текущего и капитального ремонтов, противоаварийные мероприятия',
        ],
        correctAnswers: [
          'Требования инструкций заводов - изготовителей вентиляционного оборудования, объем и сроки технического обслуживания, текущего и капитального ремонтов',
        ],
      },
      {
        code: '63690146',
        text:
          'Кто отвечает за эксплуатацию зданий и сооружений, соблюдение сроков и качества их ремонта, а также за исправное состояние ограждений территории дорог, тротуаров, отмосток зданий и благоустройство территории АГЗС?',
        answers: [
          'Лицо, ответственное за эксплуатацию зданий и сооружений, назначенное приказом руководителя эксплуатирующей организации',
          'Лицо, ответственное за осуществление производственного контроля за соблюдением требований промышленной безопасности',
          'Лицо, ответственное за безопасную эксплуатацию АГЗС',
          'Технический руководитель АГЗС',
        ],
        correctAnswers: [
          'Лицо, ответственное за эксплуатацию зданий и сооружений, назначенное приказом руководителя эксплуатирующей организации',
        ],
      },
      {
        code: '63690147',
        text:
          'Какое средство индивидуальной защиты должны использовать лица, первый раз входящие в замкнутое пространство для отбора проб воздуха?',
        answers: [
          'Респиратор или фильтрующий противогаз',
          'Шланговый или изолирующий противогаз',
          'Респиратор или изолирующий противогаз',
          'Шланговый противогаз',
        ],
        correctAnswers: ['Шланговый противогаз'],
      },
      {
        code: '63690148',
        text:
          'Какие из перечисленных мероприятий необходимо произвести перед началом приемочных испытаний эксплуатационной организации, участвующей в них совместно с пусконаладочной организацией? Выберите 2 варианта ответа.',
        answers: [
          'Утвердить должностные и производственные инструкции, графики технического обслуживания и ремонта',
          'Вывесить на рабочих местах технологические схемы газопроводов и технических устройств',
          'Обеспечить подготовку и аттестацию работников в области промышленной безопасности',
          'Указать направление движения газа на газопроводах',
          'Нанести обозначения класса взрывоопасных зон помещений в соответствии с проектной документацией',
        ],
        correctAnswers: [
          'Утвердить должностные и производственные инструкции, графики технического обслуживания и ремонта',
          'Вывесить на рабочих местах технологические схемы газопроводов и технических устройств',
        ],
      },
      {
        code: '63690149',
        text:
          'Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?',
        answers: [
          'Организацией, на объекте которой произошла авария',
          'Комиссией по техническому расследованию аварии',
          'Специализированной сторонней организацией',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Организацией, на объекте которой произошла авария'],
      },
      {
        code: '63690150',
        text:
          'Допускается ли на автогазозаправочной станции эксплуатация КИП, не имеющих поверительного клейма (наклейки, оттиска) или с просроченным поверительным клеймом, за исключением КИП, конструктивно не имеющих возможности для размещения поверительного клейма (наклейки, оттиска)?',
        answers: [
          'Допускается в течение периода, установленного техническим руководителем автозаправочной станции',
          'Допускается в течение не более 1 месяца после окончания срока эксплуатации',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690151',
        text:
          'При какой концентрации газа в воздухе огневые работы на автогазозаправочной станции в помещении (замкнутых пространствах) должны быть приостановлены?',
        answers: [
          '3% НКПР',
          '5% НКПР',
          '10% НКПР',
          'При наличии газа в воздухе, независимо от концентрации',
        ],
        correctAnswers: ['10% НКПР'],
      },
      {
        code: '63690152',
        text:
          'В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?',
        answers: [
          'В двухнедельный срок',
          'В месячный срок',
          'В десятидневный срок',
          'В недельный срок',
        ],
        correctAnswers: ['В двухнедельный срок'],
      },
      {
        code: '63690153',
        text:
          'Какие работы выполняются при проведении технического обслуживания газопроводов и арматуры?',
        answers: [
          'Только наружный осмотр газопроводов для выявления неплотностей в сварных стыках, фланцевых и резьбовых соединениях, сальниковых уплотнениях и определения состояния теплоизоляции и окраски',
          'Только осмотр и ремонт арматуры, очистка арматуры и приводного устройства от загрязнения, наледи',
          'Только проверка состояния опор трубопроводов, колодцев',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '63690154',
        text:
          'Кем на АГЗС выдается письменное разрешение на включение в работу оборудования и газопроводов после технического обслуживания или ремонта, связанных с их остановкой и отключением СУГ?',
        answers: [
          'Начальником службы производственного контроля организации',
          'Руководителем специализированной сторонней организации',
          'Техническим руководителем АГЗС (руководителем АГЗС)',
          'Руководителем газоопасных работ',
        ],
        correctAnswers: ['Техническим руководителем АГЗС (руководителем АГЗС)'],
      },
      {
        code: '63690155',
        text:
          'В какое время суток должны проводиться огневые работы на автогазозаправочных станциях?',
        answers: [
          'Только в светлое время суток',
          'Только в темное время суток',
          'В светлое время суток, в исключительных случаях - в темное время суток',
        ],
        correctAnswers: ['Только в светлое время суток'],
      },
      {
        code: '63690156',
        text:
          'На кого возлагается финансирование расходов на техническое расследование причин аварий?',
        answers: [
          'На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном объекте',
          'На организацию, эксплуатирующую опасные производственные объекты, или страховую компанию, в которой застрахована гражданская ответственность этой организации',
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
          'На территориальный орган Ростехнадзора',
        ],
        correctAnswers: [
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
        ],
      },
      {
        code: '63690157',
        text:
          'Кто осуществляет координацию и общее руководство газоопасными работами на автогазозаправочной станции, выполняемыми по наряду-допуску и плану производства работ?',
        answers: [
          'Руководитель газоопасной работы, назначенный техническим руководителем АГЗС',
          'Технический руководитель АГЗС (руководитель АГЗС)',
          'Работник, ответственный за пожарную безопасность',
          'Сменный мастер',
        ],
        correctAnswers: ['Технический руководитель АГЗС (руководитель АГЗС)'],
      },
      {
        code: '63690158',
        text:
          'По истечении какого срока эксплуатации здания и сооружения должны пройти обследование для установления возможности дальнейшей их эксплуатации, необходимости проведения реконструкции или прекращения эксплуатации?',
        answers: [
          'По истечении срока, предусмотренного в проектной документации',
          'По истечении 25 лет',
          'По истечении 30 лет',
          'По истечении 40 лет',
          'По истечении 50 лет',
        ],
        correctAnswers: [
          'По истечении срока, предусмотренного в проектной документации',
        ],
      },
      {
        code: '63690159',
        text:
          'Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?',
        answers: [
          'Документом, утвержденным органом исполнительной власти субъекта Российской Федерации, на территории которого находится опасный производственный объект',
          'Документом, утвержденным представительным органом работников организации, эксплуатирующей объект',
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
        ],
        correctAnswers: [
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63690160',
        text:
          'Допускается ли работа автогазозаправочных станций при грозовых явлениях?',
        answers: [
          'Допускается при условии выполнения дополнительных мероприятий по обеспечению безопасности проведения работ',
          'Допускается при условии оформления соответствующего наряда-допуска на выполнение работ',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690161',
        text: 'Что относится к системам безопасности останова станции?',
        answers: [
          'Только устройства хранения газа и технические устройства, которые распределяют (выдают, отпускают) газомоторное топливо в баллоны, установленные на транспортных средствах',
          'Только кнопка аварийной остановки технологической системы автозаправочной станции',
          'Только аварийные автоматические системы безопасности',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63690162',
        text:
          'Куда организация обязана направить результаты технического расследования причин аварии?',
        answers: [
          'Только в федеральный орган исполнительной власти в области промышленной безопасности',
          'В федеральный орган исполнительной власти в области промышленной безопасности, вышестоящий орган, орган местного самоуправления, государственную инспекцию труда субъекта Российской Федерации, территориальное объединение профсоюза, а также в территориальные органы МЧС России',
          'В центральный аппарат или территориальные органы Ростехнадзора, проводившие расследование, страховую организацию, территориальные органы МЧС России',
          'В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии',
        ],
        correctAnswers: [
          'В уполномоченный орган или его территориальный орган, сформировавший комиссию по проведению технического расследования, в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию, и в другие органы, определенные председателем комиссии',
        ],
      },
      {
        code: '63690163',
        text:
          'С какой периодичностью должна направляться информация о произошедших инцидентах в территориальный орган Ростехнадзора?',
        answers: [
          'Один раз в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
          'Один раз в год',
        ],
        correctAnswers: ['Не реже одного раза в квартал'],
      },
      {
        code: '63690164',
        text:
          'Кем на автогазозаправочной станции проводится наружный осмотр резервуаров с арматурой и контрольно-измерительными приборами в рабочем состоянии?',
        answers: [
          'Лицом, ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности',
          'Специальной комиссией, утверждаемой техническим руководителем организации',
          'Лицом, ответственным за безопасную эксплуатацию автогазозаправочной станции',
          'Любым инженерно-техническим работником',
          'Техническим руководителем организации',
        ],
        correctAnswers: [
          'Лицом, ответственным за безопасную эксплуатацию автогазозаправочной станции',
        ],
      },
      {
        code: '63690165',
        text:
          'С какой периодичностью на АГЗС осматриваются железобетонные конструкции?',
        answers: [
          'Ежеквартально',
          'Не реже 1 раза в 6 месяцев',
          'Ежегодно',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63690166',
        text:
          'Какое утверждение противоречит требованиям безопасности при работах в колодцах на территории АГЗС?',
        answers: [
          'С наветренной стороны колодца должны находиться 2 человека, которые обязаны держать концы веревок от спасательных поясов работников, находящихся внутри колодца, вести наблюдение за ними и не допускать к месту работ посторонних лиц',
          'При продолжении работ в колодцах более 1 часа необходимо периодически, каждые 30 минут, проводить проверку загазованности и вентиляцию колодца',
          'В колодцах разрешается нахождение не более 2 работников в спасательных поясах и шланговых противогазах. Применение открытого огня запрещается',
        ],
        correctAnswers: [
          'При продолжении работ в колодцах более 1 часа необходимо периодически, каждые 30 минут, проводить проверку загазованности и вентиляцию колодца',
        ],
      },
      {
        code: '63690167',
        text:
          'Выполнение каких требований безопасности к разработке и изготовлению оборудования для работы во взрывоопасных средах не предусмотрено в ТР ТС 012/2011?',
        answers: [
          'Оборудование должно сохранять взрывобезопасность в изменяющихся условиях окружающей среды и при наличии внешних воздействий (влажность, вибрация, загрязнения, грозовые и коммутационные перенапряжения и др.) с учетом ограничений рабочих условий, установленных изготовителем',
          'Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.',
          'Оборудование должно функционировать в фактических или прогнозируемых условиях окружающей среды',
          'Оборудование должно обеспечивать взрывобезопасность при эксплуатации в течение всего предполагаемого (расчетного) срока службы',
        ],
        correctAnswers: [
          'Применение оборудования в нормальных условиях, избежание внешних воздействий, вибрации, загрязнений, грозовых и коммутационных перенапряжений и др.',
        ],
      },
      {
        code: '63690168',
        text:
          'Какие требования устанавливаются для руководителей и инженерно-технических работников автогазозаправочных станций для их допуска к выполнению газоопасных работ?',
        answers: [
          'Должны быть обучены требованиям охраны труда и аттестованы в области промышленной безопасности в объеме Правил безопасности автогазозаправочных станций газомоторного топлива',
          'Должны пройти медицинское освидетельствование и проверку знаний в области промышленной безопасности и охраны труда при выполнении газоопасных работ',
          'Должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи',
        ],
        correctAnswers: [
          'Должны быть обучены правилам пользования средствами индивидуальной защиты, способам оказания первой помощи',
        ],
      },
      {
        code: '63690169',
        text: 'Что должно размещаться в помещении операторной?',
        answers: [
          'Только производственная инструкция, содержащая порядок проведения и меры безопасности при сливе СУГ',
          'Только схема обвязки насосов с раздаточными колонками и резервуарами',
          'Только схема слива СУГ из автомобильных цистерн',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63690170',
        text:
          'Какие функции обязано выполнить лицо, осуществляющее руководство сварочными работами, назначенное распорядительным документом организации или (и) должностной инструкцией которого предусмотрено руководство сварочными работами, перед выполнением сварочных работ?',
        answers: [
          'Только проверить и обеспечить соответствие численного состава и квалификации персонала сварочного производства, сборочного и сварочного оборудования, основных и сварочных материалов, применяемой технологии сварки требованиям производственно-технологической документации',
          'Только ознакомить сварщиков с требованиями технологических карт сварки, а также с внесенными в них изменениями (при наличии), с подтверждением ознакомления подписями сварщиков в применяемых ими технологических картах сварки',
          'Только организовать проведение операционного контроля',
          'Все перечисленные функции',
        ],
        correctAnswers: ['Все перечисленные функции'],
      },
      {
        code: '63690171',
        text:
          'Что перед началом сварочных работ руководитель сварочных работ обязан проверить?',
        answers: [
          'Только исправность сварочного оборудования',
          'Только наличие на месте сварки огнетушителя',
          'Выполнение всех подготовительных мероприятий',
        ],
        correctAnswers: ['Выполнение всех подготовительных мероприятий'],
      },
      {
        code: '63690172',
        text:
          'Какое утверждение противоречит требованиям промышленной безопасности при эксплуатации насосов на АГЗС?',
        answers: [
          'Перед демонтажем насоса, работающего на СПГ, должен быть произведен сброс среды в систему газосброса, произведена смена рабочей среды на инертный газ, и насос должен быть отключен от обвязывающих газопроводов заглушками',
          'Аварийная остановка насосов должна быть осуществлена немедленно при внезапном падении напора на нагнетании более чем на 5%',
          'При установке заглушек на газопроводах обвязки насоса, подлежащего ремонту, работа остальных насосов должна быть прекращена',
        ],
        correctAnswers: [
          'Аварийная остановка насосов должна быть осуществлена немедленно при внезапном падении напора на нагнетании более чем на 5%',
        ],
      },
      {
        code: '63690173',
        text:
          'Что из перечисленного допускается при проведении сливо-наливных операций?',
        answers: [
          'Слив СУГ из автоцистерны в резервуары АГЗС после проверки правильности открытия и закрытия запорной арматуры, связанной с технологической операцией слива СУГ',
          'Создание перепада давления между автоцистерной и резервуаром сбросом в атмосферу паровой фазы СУГ из наполняемого резервуара',
          'Нахождение водителя в кабине во время слива СУГ',
          'Сброс СУГ в атмосферу',
        ],
        correctAnswers: [
          'Слив СУГ из автоцистерны в резервуары АГЗС после проверки правильности открытия и закрытия запорной арматуры, связанной с технологической операцией слива СУГ',
        ],
      },
      {
        code: '63690174',
        text:
          'С какой периодичностью на АГНКС проводится техническое обслуживание вентиляционных камер и вентиляционных систем помещений?',
        answers: ['Ежесменно', 'Ежесуточно', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63690175',
        text:
          'В течение какого времени должна сохраняться маркировка взрывозащиты, нанесенная на поверхность оборудования или табличку?',
        answers: [
          'В течение 3 лет после начала эксплуатации оборудования',
          'В течение 5 лет после начала эксплуатации оборудования',
          'В течение всего срока службы этого оборудования',
          'Не регламентируется',
        ],
        correctAnswers: ['В течение всего срока службы этого оборудования'],
      },
      {
        code: '63690176',
        text:
          'Где должны быть отражены сведения о ремонте и наладке вентиляционных систем на АГНКС?',
        answers: [
          'В производственных инструкциях по эксплуатации вентиляционных систем',
          'В отчетах о ремонте и наладке вентиляционных систем',
          'В эксплуатационных журналах вентиляционных систем',
          'В паспорте вентиляционных систем',
        ],
        correctAnswers: ['В паспорте вентиляционных систем'],
      },
      {
        code: '63690177',
        text:
          'Каким образом осуществляется включение насосов АГЗС после перерыва в работе более одной смены?',
        answers: [
          'С разрешения технического руководителя АГЗС (руководителя АГЗС) после осмотра резервуаров и газопроводов',
          'С разрешения технического руководителя АГЗС, согласованного с инспектором Ростехнадзора',
          'С разрешения руководителя АГЗС, согласованного с инспектором Ростехнадзора',
          'В обычном порядке',
        ],
        correctAnswers: [
          'С разрешения технического руководителя АГЗС (руководителя АГЗС) после осмотра резервуаров и газопроводов',
        ],
      },
      {
        code: '63690178',
        text:
          'Какая документация оформляется в процессе выполнения сварочных работ?',
        answers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
          'Только паспорта, акты и заключения по неразрушающему контролю',
          'Только протоколы испытаний сварных соединений',
          'Только журналы сварочных работ',
        ],
        correctAnswers: [
          'Исполнительная и (или) эксплуатационная документация (журналы сварочных работ, паспорта, акты и заключения по неразрушающему контролю, протоколы испытаний сварных соединений) и иные документы, предусмотренные требованиями НД и (или) проектной (конструкторской) документации',
        ],
      },
      {
        code: '63690179',
        text:
          'Какие действия выполняются ежесменно при техническом обслуживании насосов?',
        answers: [
          'Только осмотр насосов, запорной и предохранительной арматуры, средств измерений, автоматики и блокировок',
          'Только очистка насосов и КИП от пыли и загрязнений, проверка наличия и исправности заземления и креплений',
          'Только контроль за отсутствием посторонних шумов, характерных вибраций, температурой подшипников',
          'Только проверка исправности доступных для осмотра движущихся частей',
          'Только отключение неисправных насосов',
          'Выполняются все перечисленные действия',
        ],
        correctAnswers: ['Выполняются все перечисленные действия'],
      },
      {
        code: '63690180',
        text:
          'На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?',
        answers: [
          'Не более чем на 7 календарных дней',
          'Не более чем на 10 рабочих дней',
          'Не более чем на 15 календарных дней',
          'Не более чем на 30 календарных дней',
        ],
        correctAnswers: ['Не более чем на 15 календарных дней'],
      },
      {
        code: '63690181',
        text:
          'На каких автогазозаправочных станциях должны быть разработаны планы мероприятий по локализации и ликвидации последствий аварий?',
        answers: [
          'На всех автогазозаправочных станциях, на которые распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива, являющихся опасными производственными объектами I, II и III классов опасности, за исключением КриоАЗС',
          'На всех автогазозаправочных станциях, на которые распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива, являющихся опасными производственными объектами I, II и III классов опасности',
          'Только на автогазозаправочных станциях СУГ, являющихся опасными производственными объектами I, II и III классов опасности',
        ],
        correctAnswers: [
          'На всех автогазозаправочных станциях, на которые распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива, являющихся опасными производственными объектами I, II и III классов опасности',
        ],
      },
      {
        code: '63690182',
        text:
          'Кто проводит испытания технологической системы автогазозаправочной станции перед проведением пусконаладочных работ?',
        answers: [
          'Аттестационная комиссия Ростехнадзора',
          'Строительно-монтажная организация',
          'Эксплуатационная организация',
          'Пусконаладочная организация',
          'Экспертная организация',
        ],
        correctAnswers: ['Строительно-монтажная организация'],
      },
      {
        code: '63690183',
        text:
          'Какие виды контроля должны осуществляться при подготовке и выполнении сварочных работ?',
        answers: [
          'Только операционный контроль',
          'Только входной контроль',
          'Только приемочный контроль',
          'Все перечисленные виды контроля',
        ],
        correctAnswers: ['Все перечисленные виды контроля'],
      },
      {
        code: '63690184',
        text:
          'Кем на автогазозаправочной станции утверждается график работ по текущему ремонту?',
        answers: [
          'Работником, ответственным за осуществление производственного контроля за соблюдением требований промышленной безопасности',
          'Техническим руководителем организации, эксплуатирующей автозаправочную станцию',
          'Начальником ремонтного участка',
        ],
        correctAnswers: [
          'Техническим руководителем организации, эксплуатирующей автозаправочную станцию',
        ],
      },
      {
        code: '63690185',
        text:
          'Какое утверждение противоречит требованиям промышленной безопасности при организации ремонтных работ на АГЗС, связанных со сваркой?',
        answers: [
          'Поврежденные участки газопровода и деформированные фланцевые соединения следует заменять путем вварки катушек длиной не менее одного диаметра (не менее 200 мм)',
          'Запрещается устанавливать заплаты, заваривать трещины, разрывы и дефекты на поврежденных участках газопровода',
          'Ремонт сварного соединения допускается выполнять двумя сварщиками',
        ],
        correctAnswers: [
          'Ремонт сварного соединения допускается выполнять двумя сварщиками',
        ],
      },
      {
        code: '63690186',
        text: 'Какие смазочные масла должны использоваться для насосов?',
        answers: [
          'Только масла, предусмотренные инструкциями организаций-изготовителей',
          'Только полусинтетические масла',
          'Только индустриальные масла',
          'Только моторные масла',
        ],
        correctAnswers: [
          'Только масла, предусмотренные инструкциями организаций-изготовителей',
        ],
      },
      {
        code: '63690187',
        text:
          'Какому испытанию должны подвергаться рукава, применяемые при сливо-наливных операциях, и с какой периодичностью?',
        answers: [
          'Гидравлическому испытанию на прочность давлением, равным 2 рабочим давлениям, не реже одного раза в 6 месяцев',
          'Пневматическому испытанию на герметичность давлением, равным рабочему давлению, не реже одного раза в 2 месяца',
          'Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в 3 месяца',
          'Гидравлическому испытанию на герметичность давлением, равным 1,5 рабочего давления, не реже одного раза в месяц',
          'Пневматическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в год',
        ],
        correctAnswers: [
          'Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в 3 месяца',
        ],
      },
      {
        code: '63690188',
        text:
          'При наличии каких дефектов рукава, применяемые при сливоналивных операциях на АГЗС, подлежат замене?',
        answers: [
          'При наличии трещин, надрезов, вздутий и потертостей',
          'Только при наличии потертостей и вздутий',
          'Только при наличии трещин и надрезов',
          'Только при наличии надрезов',
        ],
        correctAnswers: ['При наличии трещин, надрезов, вздутий и потертостей'],
      },
      {
        code: '63690189',
        text:
          'Какие условия должны соблюдаться при замене прибора, снятого для ремонта или предоставления на поверку?',
        answers: [
          'Прибор должен быть заменен на имеющий только соответствующий предел измерения',
          'Прибор должен быть заменен на имеющий только соответствующий класс точности',
          'Прибор должен быть заменен на идентичный по условиям эксплуатации',
        ],
        correctAnswers: [
          'Прибор должен быть заменен на идентичный по условиям эксплуатации',
        ],
      },
      {
        code: '63690190',
        text:
          'Какие требования установлены к составу бригады при выполнении газоопасных работ в резервуарах, помещениях заправочной станции, а также ремонта с применением газовой сварки и резки?',
        answers: [
          'Не менее двух работников под руководством аттестованного инженерно-технического работника',
          'Не менее трех работников под руководством аттестованного инженерно-технического работника',
          'Не менее трех работников под руководством квалифицированного рабочего',
          'Не менее пяти работников под руководством инженерно-технического работника',
        ],
        correctAnswers: [
          'Не менее трех работников под руководством аттестованного инженерно-технического работника',
        ],
      },
      {
        code: '63690191',
        text:
          'Кто дает разрешение на снятие заглушек после контрольной опрессовки отключенного участка газопровода и оборудования?',
        answers: [
          'Руководитель газоопасных работ',
          'Технический руководитель АГЗС',
          'Инспектор Ростехнадзора',
          'Руководитель АГЗС',
        ],
        correctAnswers: ['Руководитель газоопасных работ'],
      },
      {
        code: '63690192',
        text:
          'Какое утверждение противоречит требованиям к эксплуатации газопроводов, арматуры и сетей инженерно-технического обеспечения на автогазозаправочных станциях?',
        answers: [
          'Перед установкой заглушек следует составить схему их установки, которая должна быть утверждена сотрудником, ответственным за подготовку технических устройств к ремонту',
          'Запрещается применение каких-либо дополнительных рычагов при закрытии или открытии запорной арматуры на газопроводах',
          'Запорную арматуру на газопроводах следует открывать и закрывать медленно во избежание гидравлического удара',
          'При разъединении фланцев в первую очередь должны быть освобождены верхние болты',
        ],
        correctAnswers: [
          'При разъединении фланцев в первую очередь должны быть освобождены верхние болты',
        ],
      },
      {
        code: '63690193',
        text:
          'Какие данные должны быть на рукавах, применяемых для проведения сливо-наливных операций?',
        answers: [
          'Обозначение с порядковым номером, датой проведения (месяц, год) испытания и последующего испытания (месяц, год)',
          'Обозначение с заводским номером, датой проведения (год) испытания и последующего испытания (месяц, год)',
          'Обозначение с заводским и порядковым номерами, датой проведения (год) испытания',
          'Обозначение с порядковым номером и данными об организации-владельце',
        ],
        correctAnswers: [
          'Обозначение с порядковым номером, датой проведения (месяц, год) испытания и последующего испытания (месяц, год)',
        ],
      },
      {
        code: '63690194',
        text:
          'При каких режимах работы должна обеспечиваться взрывобезопасность оборудования?',
        answers: [
          'В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя',
          'Взрывобезопасность должна быть обеспечена независимо от режимов работы',
          'Только в аварийных режимах работы',
        ],
        correctAnswers: [
          'В нормальных режимах работы и в пределах отклонений, установленных технической документацией изготовителя',
        ],
      },
      {
        code: '63690195',
        text:
          'С какой периодичностью на АГЗС в первые два года эксплуатации следует наблюдать за осадкой фундаментов зданий, сооружений и оборудования?',
        answers: [
          'Еженедельно',
          'Ежемесячно',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в полгода',
          'Ежегодно',
        ],
        correctAnswers: ['Не реже одного раза в три месяца'],
      },
      {
        code: '63690196',
        text:
          'Кто на автогазозаправочной станции организует контроль за исправностью средств измерений и своевременностью проведения метрологического контроля и надзора?',
        answers: [
          'Работник, ответственный за метрологическое обеспечение АГЗС',
          'Работник, ответственный за безопасную эксплуатацию АГЗС',
          'Технический руководитель АГЗС (руководитель АГЗС)',
          'Любой инженерно-технический работник',
        ],
        correctAnswers: ['Технический руководитель АГЗС (руководитель АГЗС)'],
      },
      {
        code: '63690197',
        text:
          'Кем осуществляется техническое обслуживание насосов на АГЗС? Выберите 2 варианта ответа.',
        answers: [
          'Ремонтным персоналом под руководством лица, ответственного за проведение этих работ',
          'Ремонтным персоналом под руководством наиболее квалифицированного рабочего',
          'Персоналом специализированной организации',
          'Работниками (эксплуатационным персоналом)',
          'Работниками подрядной организации',
        ],
        correctAnswers: [
          'Работниками (эксплуатационным персоналом)',
          'Работниками подрядной организации',
        ],
      },
      {
        code: '63690198',
        text:
          'При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?',
        answers: [
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30 % от общего числа членов комиссии',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 25 % от общего числа членов комиссии',
          'При условии, что их число составляет не более 2',
          'При условии, что их число составляет не более 3',
        ],
        correctAnswers: [
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30 % от общего числа членов комиссии',
        ],
      },
      {
        code: '63690199',
        text:
          'С какой периодичностью должны осматривать газопроводы и арматуру в целях выявления и устранения утечек газа?',
        answers: ['Ежесменно', 'Ежесуточно', 'Еженедельно', 'Ежемесячно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63690200',
        text:
          'Какой вид классификации оборудования для работы во взрывоопасных средах не устанавливает ТР ТС 012/2011 "О безопасности оборудования для работы во взрывоопасных средах"?',
        answers: [
          'Классификация оборудования по группам (в зависимости от области применения)',
          'Классификация оборудования по давлению взрывоопасной среды',
          'Классификация оборудования по уровням взрывозащиты',
          'Классификация взрывоопасных зон',
        ],
        correctAnswers: [
          'Классификация оборудования по давлению взрывоопасной среды',
        ],
      },
      {
        code: '63690201',
        text:
          'Какой документацией, в соответствии с требованиями Правил безопасности автогазозаправочных станций газомоторного топлива, должен быть обеспечен оператор АГНКС?',
        answers: [
          'Письменными инструкциями и руководствами по обеспечению безопасности',
          'Письменными инструкциями и инструкциями изготовителей оборудования',
          'Планом мероприятий по локализации и ликвидации последствий аварий',
        ],
        correctAnswers: [
          'Письменными инструкциями и руководствами по обеспечению безопасности',
        ],
      },
      {
        code: '63690202',
        text:
          'В течение какого срока по результатам технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?',
        answers: [
          'В течение суток',
          'В течение 3 рабочих дней',
          'В течение 7 рабочих дней',
          'В течение 10 рабочих дней',
        ],
        correctAnswers: ['В течение 7 рабочих дней'],
      },
      {
        code: '63690203',
        text:
          'Кто возглавляет специальную комиссию по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: [
          'Представитель органа исполнительной власти субъекта Российской Федерации и (или) органа местного самоуправления, на территории которых располагается опасный производственный объект',
          'Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа',
          'Представитель вышестоящего органа или организации, эксплуатирующей опасный производственный объект',
          'Представитель организации, эксплуатирующей опасный производственный объект',
        ],
        correctAnswers: [
          'Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа',
        ],
      },
      {
        code: '63690204',
        text:
          'Куда на КриоАЗС должны быть направлены сбросы через предохранительные клапаны и другие аварийные устройства технологических блоков?',
        answers: [
          'В систему сброса от газосбросных клапанов резервуаров или в самостоятельную систему, не связанную с системой сброса от газосбросных клапанов резервуаров',
          'В самостоятельную систему, не связанную с системой сброса от газосбросных клапанов резервуаров',
          'В систему сброса от газосбросных клапанов резервуаров',
        ],
        correctAnswers: [
          'В самостоятельную систему, не связанную с системой сброса от газосбросных клапанов резервуаров',
        ],
      },
      {
        code: '63690205',
        text:
          'Какая концентрация СУГ в воздухе помещений АГЗС считается опасной?',
        answers: ['1% НКПР', '3% НКПР', '5% НКПР', '7% НКПР', '10% НКПР'],
        correctAnswers: ['10% НКПР'],
      },
      {
        code: '63690206',
        text:
          'Какой документ составляется по результатам испытаний вентиляционных систем АГНКС?',
        answers: [
          'Технический отчет, содержащий оценку эффективности работы вентиляционных систем с указанием режима их эксплуатации',
          'Производственная инструкция по эксплуатации вентиляционных систем',
          'Акт проверки эффективности работы вентиляционных систем',
        ],
        correctAnswers: [
          'Технический отчет, содержащий оценку эффективности работы вентиляционных систем с указанием режима их эксплуатации',
        ],
      },
      {
        code: '63690207',
        text:
          'На какие объекты распространяются требования Правил безопасности автогазозаправочных станций газомоторного топлива?',
        answers: [
          'На стационарные АГЗС, АГНКС, КриоАЗС, многотопливные АЗС',
          'Только на стационарные АГЗС и стационарные АГНКС',
          'На стационарные и передвижные АГЗС',
          'Только на стационарные АГЗС',
        ],
        correctAnswers: [
          'На стационарные АГЗС, АГНКС, КриоАЗС, многотопливные АЗС',
        ],
      },
      {
        code: '63690208',
        text:
          'Какое утверждение противоречит требованиям безопасности при заправке газобаллонных автомобилей?',
        answers: [
          'Включение АГЗС в работу до эвакуации неисправного транспортного средства на безопасное расстояние допускается только в присутствии ответственного лица',
          'При обнаружении неплотностей в газовом оборудовании автомобиля или переполнении баллона, заправляемого СПГ или СУГ, газ из него должен быть слит в резервуар',
          'Запрещается въезд на территорию автозаправочной станции на автомобиле с неисправным газобаллонным оборудованием',
          'Запрещается включение АГЗС в работу прежде эвакуации неисправного транспортного средства на безопасное расстояние',
        ],
        correctAnswers: [
          'Включение АГЗС в работу до эвакуации неисправного транспортного средства на безопасное расстояние допускается только в присутствии ответственного лица',
        ],
      },
      {
        code: '63690209',
        text:
          'В течение какого срока на автогазозаправочной станции должны храниться наряды-допуски (за исключением нарядов-допусков на первичный слив газа, производство ремонтных работ с применением сварки на элементах подземных газопроводов и резервуаров)?',
        answers: [
          'В течение одного месяца',
          'В течение трех месяцев',
          'В течение шести месяцев',
          'Не менее одного года',
          'В течение десяти лет',
          'В течение всего срока эксплуатации автозаправочной станции',
        ],
        correctAnswers: ['Не менее одного года'],
      },
      {
        code: '63690210',
        text:
          'Каким образом на АГЗС должны ремонтироваться поврежденные участки газопроводов и деформированные фланцевые соединения?',
        answers: [
          'Вваркой катушек длиной не менее одного диаметра (не менее 200 мм). Устанавливать заплаты, заваривать трещины, разрывы и дефекты не допускается',
          'Вваркой катушек длиной не менее половины диаметра (не менее 100 мм)',
          'Установкой заплат на разрывы и дефекты',
          'Завариванием трещин и разрывов',
        ],
        correctAnswers: [
          'Вваркой катушек длиной не менее одного диаметра (не менее 200 мм). Устанавливать заплаты, заваривать трещины, разрывы и дефекты не допускается',
        ],
      },
      {
        code: '63690211',
        text:
          'Какое утверждение противоречит требованиям безопасности при освидетельствовании резервуаров на АГЗС?',
        answers: [
          'Качество дегазации должно быть проверено анализом проб воздуха, отобранного из нижней части сосуда. Концентрация СУГ пробы после дегазации не должна превышать 20% НКПР',
          'Дегазация резервуаров должна быть проведена после отсоединения их от газопроводов паровой и жидкой фаз СУГ с помощью заглушек путем их пропаривания и продувки инертным газом или заполнения теплой водой',
          'Резервуары перед внутренним осмотром, гидравлическим испытанием, ремонтом или демонтажем должны быть освобождены от СУГ, неиспарившихся остатков и тщательно дегазированы',
          'Разгерметизация резервуаров без предварительного снижения в них давления до атмосферного, а также применение для дегазации воздуха не разрешаются',
        ],
        correctAnswers: [
          'Качество дегазации должно быть проверено анализом проб воздуха, отобранного из нижней части сосуда. Концентрация СУГ пробы после дегазации не должна превышать 20% НКПР',
        ],
      },
      {
        code: '63690212',
        text:
          'В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?',
        answers: [
          'В течение 1 часа с момента возникновения аварии, инцидента',
          'В течение 12 часов с момента возникновения аварии, инцидента',
          'В течение 24 часов с момента возникновения аварии, инцидента',
          'В течение 2 суток с момента возникновения аварии, инцидента',
        ],
        correctAnswers: [
          'В течение 24 часов с момента возникновения аварии, инцидента',
        ],
      },
      {
        code: '63690213',
        text:
          'Каким требованиям на АГЗС должны соответствовать заглушки, устанавливаемые на газопроводах СУГ?',
        answers: [
          'Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием давления СУГ и диаметра газопровода',
          'Должны быть рассчитаны на рабочее давление в газопроводе и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием производителя',
          'Должны быть рассчитаны на давление 2,5 МПа и иметь хвостовики, на которых должно быть выбито клеймо с указанием года установки',
          'Должны быть рассчитаны на рабочее давление в газопроводе и иметь хвостовики, не выступающие за габариты фланцев',
        ],
        correctAnswers: [
          'Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием давления СУГ и диаметра газопровода',
        ],
      },
      {
        code: '63690214',
        text:
          'С какой периодичностью дежурный персонал АГНКС должен осматривать технологические газопроводы и арматуру в целях выявления утечек газа?',
        answers: [
          '2 раза в сутки',
          'Ежесменно',
          'Не реже 1 раза в неделю',
          'Еженедельно',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63690215',
        text:
          'С какой периодичностью работники автогазозаправочных станций должен участвовать в учебно-тренировочных занятиях по ликвидации аварийных ситуаций, аварий и пожаров, предусмотренных планом мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах I, II и III классов опасности?',
        answers: [
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в полугодие',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в квартал'],
      },
      {
        code: '63690216',
        text:
          'Кто на АГЗС дает разрешение на пуск и остановку насосов при выводе в ремонт и из ремонта?',
        answers: [
          'Технический руководитель АГЗС (руководитель АГЗС)',
          'Инженерно-технический работник',
          'Руководитель ремонтной бригады',
          'Сменный мастер',
        ],
        correctAnswers: ['Технический руководитель АГЗС (руководитель АГЗС)'],
      },
      {
        code: '63690217',
        text:
          'В каком исполнении должны быть приборы, применяемые для выявления утечек газа?',
        answers: [
          'В воздухонепроницаемом исполнении',
          'Во взрывозащищенном исполнении',
          'В пыленепроницаемом исполнении',
          'В водонепроницаемом исполнении',
        ],
        correctAnswers: ['Во взрывозащищенном исполнении'],
      },
      {
        code: '63690218',
        text:
          'В каком случае наряд-допуск на производство газоопасных работ может быть продлен?',
        answers: [
          'Если работа не закончена, а условия ее проведения и характер не изменились',
          'Если увеличился объем проводимых работ',
          'Ни в каком случае',
          'В любом случае',
        ],
        correctAnswers: [
          'Если работа не закончена, а условия ее проведения и характер не изменились',
        ],
      },
      {
        code: '63690219',
        text:
          'Какие действия должны быть осуществлены при выполнении многопроходных швов после наложения каждого валика с поверхностью шва и кромкой разделки?',
        answers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
          'Поверхности шва и кромки разделки должны быть обработаны солидолом, литолом или тавотом',
          'Поверхности шва и кромки разделки должны быть обезжирены водными смывками',
          'Поверхности шва и кромки разделки должны быть окрашены',
        ],
        correctAnswers: [
          'Должны быть зачищены от шлака, брызг металла и визуально проконтролированы на отсутствие поверхностных дефектов (трещин, недопустимых шлаковых или вольфрамовых включений, пор, неровностей и других дефектов)',
        ],
      },
      {
        code: '63690220',
        text:
          'Допускается ли на автогазозаправочных станциях проведение газоопасных работ, выполняемых по нарядам-допускам, в темное время суток?',
        answers: [
          'Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ',
          'Допускается при наличии достаточного освещения',
          'Допускается в любом случае',
          'Не допускается',
        ],
        correctAnswers: [
          'Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ',
        ],
      },
      {
        code: '63690221',
        text:
          'С какой периодичностью выполняются работы по проверке настройки предохранительных клапанов резервуаров на срабатывание при давлении?',
        answers: [
          'В соответствии с графиком, утверждаемым техническим руководителем АГЗС (руководителем АГЗС)',
          'Ежесменно',
          'Ежедневно',
          'Еженедельно',
          'Ежемесячно',
        ],
        correctAnswers: [
          'В соответствии с графиком, утверждаемым техническим руководителем АГЗС (руководителем АГЗС)',
        ],
      },
      {
        code: '63690222',
        text: 'В каком случае средства измерения допускаются к применению?',
        answers: [
          'Стрелка при отключении не возвращается к нулевому делению шкалы на величину, не превышающую половину допускаемой погрешности прибора',
          'Просрочен срок поверки или калибровки средства измерения',
          'У средства измерения отсутствует пломба или клеймо',
          'Имеются повреждения прибора',
        ],
        correctAnswers: [
          'Стрелка при отключении не возвращается к нулевому делению шкалы на величину, не превышающую половину допускаемой погрешности прибора',
        ],
      },
      {
        code: '63690223',
        text:
          'В соответствии с требованиями каких документов должен проводиться пуск в эксплуатацию оборудования автогазозаправочной станции (компрессоры, насосы, испарители) после технического обслуживания и ремонта?',
        answers: [
          'Правил безопасности автогазозаправочных станций газомоторного топлива',
          'Инструкций по эксплуатации оборудования организаций-изготовителей',
          'Производственных инструкций',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63690224',
        text:
          'Допускается ли во время грозы или проведения огневых работ на АГЗС проводить сливо-наливные операции?',
        answers: [
          'Допускается в исключительных случаях для завершения слива СУГ в резервуары',
          'Допускается по письменному разрешению технического руководителя АГЗС',
          'Допускается при условии соблюдения дополнительных мер безопасности',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63690225',
        text:
          'Какое утверждение противоречит требованиям Правил безопасности автогазозаправочных станций газомоторного топлива к выполнению аварийных работ на АГЗС?',
        answers: [
          'Аварийные работы на АГЗС выполняются аварийными службами газораспределительных организаций. Участие персонала АГЗС в аварийных работах на АГЗС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий',
          'Действия персонала АГЗС по локализации и ликвидации аварий определяются планом мероприятий по локализации и ликвидации последствий аварий на опасных производственных объектах I, II и III классов опасности',
          'Действия персонала АГЗС по локализации и ликвидации аварий определяются инструкцией по действиям работников в аварийных ситуациях для опасных производственных объектов IV класса опасности',
        ],
        correctAnswers: [
          'Аварийные работы на АГЗС выполняются аварийными службами газораспределительных организаций. Участие персонала АГЗС в аварийных работах на АГЗС устанавливается планом мероприятий газораспределительных организаций по локализации и ликвидации последствий аварий',
        ],
      },
      {
        code: '63690226',
        text:
          'Как должны проводиться работы внутри резервуара СУГ при его подготовке к освидетельствованию?',
        answers: [
          'Должны проводиться по наряду-допуску или без него бригадой в составе не менее четырех человек под руководством инженерно-технического работника',
          'Должны проводиться по наряду-допуску бригадой в составе не менее трех человек под руководством наиболее квалифицированного работника',
          'Должны проводиться по наряду-допуску бригадой в составе не менее трех человек под руководством инженерно-технического работника',
          'Должны проводиться по наряду-допуску бригадой в составе не менее двух человек под руководством инженерно-технического работника',
        ],
        correctAnswers: [
          'Должны проводиться по наряду-допуску бригадой в составе не менее трех человек под руководством инженерно-технического работника',
        ],
      },
      {
        code: '63690227',
        text:
          'Какой должна быть температура поверхности оборудования и (или) его частей с уровнем взрывозащиты "особовзрывобезопасный" ("очень высокий") и "взрывобезопасный" ("высокий")?',
        answers: [
          'Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации',
          'Температура должна быть не выше максимальной температуры окружающей среды в нормальном режиме эксплуатации',
          'Температура должна соответствовать температуре самовоспламенения окружающей взрывоопасной газовой среды',
          'Температура должна соответствовать температуре самовоспламенения слоя пыли при эксплуатации оборудования',
        ],
        correctAnswers: [
          'Температура должна быть ниже температуры самовоспламенения окружающей взрывоопасной газовой среды и температуры самовоспламенения слоя пыли при эксплуатации',
        ],
      },
    ],
    63688: [
      {
        code: '63688000',
        text:
          'Какая документация должна быть в насосно-компрессорном и испарительном отделениях?',
        answers: [
          'Только технологические схемы оборудования, трубопроводов и контрольно-измерительных приборов',
          'Только инструкции по эксплуатации установок',
          'Только эксплуатационные журналы',
          'Все перечисленные документы',
        ],
        correctAnswers: ['Все перечисленные документы'],
      },
      {
        code: '63688001',
        text:
          'Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?',
        answers: [
          'Организацией, на объекте которой произошла авария',
          'Специализированной сторонней организацией',
          'Комиссией по техническому расследованию аварий',
          'Территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Организацией, на объекте которой произошла авария'],
      },
      {
        code: '63688002',
        text:
          'Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?',
        answers: [
          'Всю перечисленную информацию',
          'Только информацию о принятых мерах по ликвидации инцидента, а также информацию о материальном ущербе, в том числе вреде, нанесенном окружающей среде',
          'Только информацию о продолжительности простоя и мерах по устранению причин инцидента',
          'Только дату и место инцидента, его причины и обстоятельства',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63688003',
        text:
          'Какое из перечисленных требований к проведению работ в колодцах указано верно?',
        answers: [
          'В колодцах разрешается нахождение не более трех рабочих в спасательных поясах и шланговых противогазах',
          'Для спуска в колодцы, не имеющие скоб, должны применяться веревочные лестницы',
          'При продолжении работ более 30 минут необходимо периодически проводить проверку загазованности и вентиляцию колодца',
          'С наветренной стороны должны быть два человека, которые обязаны держать концы веревок от спасательных поясов рабочих, находящихся внутри колодца, вести наблюдение за ними и не допускать к месту работ посторонних лиц',
        ],
        correctAnswers: [
          'С наветренной стороны должны быть два человека, которые обязаны держать концы веревок от спасательных поясов рабочих, находящихся внутри колодца, вести наблюдение за ними и не допускать к месту работ посторонних лиц',
        ],
      },
      {
        code: '63688004',
        text:
          'В каком документе отражаются результаты контроля отбора проб, а также интенсивность запаха СУГ (одоризация)?',
        answers: [
          'В наряде-допуске',
          'В эксплуатационном журнале',
          'В акте проведения индивидуального опробования технологической системы',
          'В акте индивидуальных испытаний',
        ],
        correctAnswers: ['В эксплуатационном журнале'],
      },
      {
        code: '63688005',
        text:
          'На кого возлагается финансирование расходов на техническое расследование причин аварий?',
        answers: [
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
          'На страховую компанию, с которой заключен договор обязательного страхования гражданской ответственности владельца опасного объекта за причинение вреда в результате аварии на опасном объекте',
          'На территориальный орган Ростехнадзора',
          'На организацию, эксплуатирующую опасные производственные объекты, и страховую компанию, в которой застрахована гражданская ответственность этой организации',
        ],
        correctAnswers: [
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
        ],
      },
      {
        code: '63688006',
        text:
          'Какая из перечисленных газоопасных работ может проводиться без оформления наряда-допуска по утвержденной производственной инструкции?',
        answers: [
          'Техническое обслуживание запорной арматуры, предохранительных клапанов и проверка параметров их настройки',
          'Проведение пусконаладочных работ',
          'Установка и снятие заглушек на действующих газопроводах',
          'Подготовка к техническому освидетельствованию резервуаров СУГ',
        ],
        correctAnswers: [
          'Техническое обслуживание запорной арматуры, предохранительных клапанов и проверка параметров их настройки',
        ],
      },
      {
        code: '63688007',
        text:
          'Какие данные не указываются наполнительной станцией, производящей наполнение баллонов сжатыми, сжиженными и растворимыми газами, в журнале наполнения баллонов?',
        answers: [
          'Дата наполнения и номер баллона',
          'Подпись, фамилия и инициалы лица, наполнившего баллон',
          'Масса газа (сжиженного) в баллоне, кг',
          'Дата освидетельствования',
          'Эксплуатирующая организация',
        ],
        correctAnswers: ['Эксплуатирующая организация'],
      },
      {
        code: '63688008',
        text:
          'В течение какого времени при пусконаладочных работах по вводу в эксплуатацию проводится отработка технологических режимов на резервуарных установках?',
        answers: [
          'Не менее 2 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
          'Не менее 48 часов',
          'Не менее 72 часов',
        ],
        correctAnswers: ['Не менее 2 часов'],
      },
      {
        code: '63688009',
        text:
          'Какое допускается давление газа в нагнетательном газопроводе компрессора?',
        answers: [
          'Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 0,7 Мпа',
          'Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 1,2 Мпа',
          'Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 1,6 Мпа',
          'Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 2,5 МПа',
        ],
        correctAnswers: [
          'Не превышающее давления конденсации паров СУГ при температуре нагнетания и быть не более 1,6 Мпа',
        ],
      },
      {
        code: '63688010',
        text:
          'Согласно какому документу должен производиться вывод компрессоров, насосов, испарителей из рабочего режима в резерв?',
        answers: [
          'Производственной инструкции',
          'Эксплуатационному журналу',
          'Проектной документации',
          'Инструкции по охране труда',
        ],
        correctAnswers: ['Производственной инструкции'],
      },
      {
        code: '63688011',
        text:
          'Как оформляется журнал наполнения, если наполнительная станция производит наполнение баллонов различными газами?',
        answers: [
          'Ведется единый журнал наполнения',
          'Должен вестись отдельный журнал наполнения',
          'В отдельный журнал заносятся только сведения о наполнении баллонов взрывоопасными газами',
        ],
        correctAnswers: ['Должен вестись отдельный журнал наполнения'],
      },
      {
        code: '63688012',
        text:
          'Каким должно быть время выдержки баллонов под пробным давлением при проведении гидравлического испытания?',
        answers: [
          'Устанавливается организацией-изготовителем',
          'Не менее 10 минут',
          'Не менее 15 минут',
          'Не менее 5 минут',
        ],
        correctAnswers: ['Устанавливается организацией-изготовителем'],
      },
      {
        code: '63688013',
        text:
          'Какое число членов должно входить в состав комиссии по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: ['Нечетное', 'Четное', 'Не менее четырех', 'Не более шести'],
        correctAnswers: ['Нечетное'],
      },
      {
        code: '63688014',
        text:
          'В каком случае не допускается выполнение сливоналивных операций из железнодорожных и автомобильных цистерн?',
        answers: [
          'При температуре окружающего воздуха выше +30 °С и ниже -25 °С',
          'Во время грозы и при проведении огневых работ',
          'При порывах ветра более 15 м/с',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во время грозы и при проведении огневых работ'],
      },
      {
        code: '63688015',
        text:
          'Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?',
        answers: [
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
          'Документом, утвержденным организацией, эксплуатирующей опасные производственные объекты, по согласованию с представительным органом работников данной организации',
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект, согласованным с органом исполнительной власти субъекта Российской Федерации, на территории которого находится опасный производственный объект',
        ],
        correctAnswers: [
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63688016',
        text:
          'Что контролируется при проведении проверки готовности сосуда к пуску в работу?',
        answers: [
          'Только наличие, соответствие проекту и исправность арматуры, контрольно-измерительных приборов, приборов безопасности и технологических защит',
          'Только наличие положительных результатов технического освидетельствования',
          'Только правильность установки, размещения, обвязки оборудования и их соответствие требованиям промышленной безопасности, указаниям проектной документации и руководства (инструкции) по эксплуатации организации-изготовителя оборудования',
          'Контролируется все перечисленное',
        ],
        correctAnswers: ['Контролируется все перечисленное'],
      },
      {
        code: '63688017',
        text:
          'К какому моменту должны быть назначены лица, ответственные за выполнение газоопасных работ, техническое состояние и безопасную эксплуатацию сосудов, работающих под избыточным давлением, за безопасную эксплуатацию электрохозяйства и вентиляционного оборудования?',
        answers: [
          'К моменту приемки объекта, использующего СУГ, по окончании пусконаладочных работ и комплексного опробования',
          'К моменту ввода объекта, использующего СУГ, в эксплуатацию',
          'К моменту проведения пусконаладочных работ',
        ],
        correctAnswers: ['К моменту проведения пусконаладочных работ'],
      },
      {
        code: '63688018',
        text:
          'Кем утверждается график технического обслуживания вентиляционных установок объекта, использующего СУГ?',
        answers: [
          'Руководителем объекта, использующего СУГ',
          'Лицом, ответственным за проведения газоопасных работ',
          'Техническим руководителем (главным инженером)',
          'Лицом, ответственным за эксплуатацию вентиляционных систем',
        ],
        correctAnswers: ['Техническим руководителем (главным инженером)'],
      },
      {
        code: '63688019',
        text:
          'С какой периодичностью должна проводиться проверка кратности воздухообмена в помещениях объекта, использующего СУГ?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в два месяца',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в четыре месяца',
        ],
        correctAnswers: ['Не реже одного раза в три месяца'],
      },
      {
        code: '63688020',
        text:
          'В каком случае результаты испытания на герметичность считаются положительными по манометру класса точности 0,6?',
        answers: [
          'При отсутствии видимого падения давления в газопроводе',
          'При падении давления не более трех делений шкалы',
          'При падении давления не более двух делений шкалы',
          'При падении давления не более одного деления шкалы',
        ],
        correctAnswers: [
          'При отсутствии видимого падения давления в газопроводе',
        ],
      },
      {
        code: '63688021',
        text: 'Каким способом определяется фактическая вместимость баллона?',
        answers: [
          'Только по разности между массой баллона, наполненного водой, и массой порожнего баллона',
          'Только способом, указанным в производственной инструкции',
          'Только с помощью мерных бачков',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63688022',
        text:
          'Какой длины должна быть ввариваемая катушка для ремонта поврежденных участков газопроводов?',
        answers: [
          'Длиной не менее 100 мм',
          'Длиной не менее 200 мм',
          'Длиной не менее 500 мм',
          'Длиной не менее 1 м',
        ],
        correctAnswers: ['Длиной не менее 200 мм'],
      },
      {
        code: '63688023',
        text:
          'С какой периодичностью должна направляться информация об инцидентах, происшедших на опасных производственных объектах, в территориальный орган Ростехнадзора?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полугодие',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в квартал'],
      },
      {
        code: '63688024',
        text:
          'В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?',
        answers: [
          'В течение 24 часов с момента возникновения аварии, инцидента',
          'В течение 72 часов с момента возникновения аварии, инцидента',
          'В течение 48 часов с момента возникновения аварии, инцидента',
          'Немедленно',
        ],
        correctAnswers: [
          'В течение 24 часов с момента возникновения аварии, инцидента',
        ],
      },
      {
        code: '63688025',
        text:
          'Какое из требований должно выполняться при выполнении работ внутри резервуаров?',
        answers: [
          'Работы внутри резервуаров должны проводиться по наряду-допуску бригадой в составе не менее двух работников под руководством ответственного лица',
          'Спуск в резервуар более двух работников не допускается',
          'Время пребывания в резервуаре не должно превышать пятнадцать минут',
          'Каждые пятнадцать минут следует проверять резервуар на загазованность',
        ],
        correctAnswers: [
          'Время пребывания в резервуаре не должно превышать пятнадцать минут',
        ],
      },
      {
        code: '63688026',
        text:
          'С какой периодичностью должна производиться проверка включения в работу аварийных вентиляционных установок?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в квартал',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63688027',
        text:
          'Какое из перечисленных требований не должна выполнять эксплуатирующая организация, участвующая в приемочных испытаниях с пусконаладочной организацией, перед началом этих испытаний?',
        answers: [
          'Назначить ответственного за проведение приемочных испытаний',
          'Вывесить на рабочих местах технологические схемы газопроводов и технических устройств',
          'Утвердить должностные и производственные инструкции, графики технического обслуживания и ремонта, планы мероприятий по локализации и ликвидации последствий аварий, обеспечить взаимодействие с пожарной командой, скорой помощью, газораспределительными организациями',
          'Обеспечить объект, использующий СУГ, средствами пожаротушения в соответствии с проектной документацией',
          'Иметь проектную (исполнительную) и эксплуатационную документацию, акты на проверку эффективности вентиляционных систем, электрооборудования, средств автоматики безопасности',
        ],
        correctAnswers: [
          'Назначить ответственного за проведение приемочных испытаний',
        ],
      },
      {
        code: '63688028',
        text:
          'В каком случае из перечисленных допускается эксплуатация наполнительных установок, установленных на открытых площадках?',
        answers: [
          'При неисправности предохранительных клапанов, манометров, контрольно-измерительных приборов и средств автоматики',
          'При температуре наружного воздуха не ниже -20 °С',
          'При неповеренных контрольно-измерительных приборах',
          'При неполном количестве или неисправности крепежных деталей',
        ],
        correctAnswers: ['При температуре наружного воздуха не ниже -20 °С'],
      },
      {
        code: '63688029',
        text:
          'С какой периодичностью должны производиться техническое обслуживание и текущий ремонт арматуры?',
        answers: [
          'С учетом рекомендаций организации-изготовителя, но не реже одного раза в 6 месяцев',
          'С учетом рекомендаций организации-изготовителя, но не реже одного раза в 12 месяцев',
          'С учетом рекомендаций организации-изготовителя, но не реже одного раза в 24 месяца',
          'С учетом рекомендаций организации-изготовителя, но не реже одного раза в 36 месяцев',
        ],
        correctAnswers: [
          'С учетом рекомендаций организации-изготовителя, но не реже одного раза в 12 месяцев',
        ],
      },
      {
        code: '63688030',
        text: 'Какой документ дает право на выполнение огневых работ?',
        answers: [
          'Наряд-допуск на проведение огневых работ и специальный план, утвержденный техническим руководителем объекта, использующего СУГ',
          'Распоряжение технического руководителя объекта, использующего СУГ',
          'План проведения огневых работ, согласованный со службой пожарной охраны',
          'Разрешение Ростехнадзора',
        ],
        correctAnswers: [
          'Наряд-допуск на проведение огневых работ и специальный план, утвержденный техническим руководителем объекта, использующего СУГ',
        ],
      },
      {
        code: '63688031',
        text:
          'При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?',
        answers: [
          'Нет, они в расследовании не принимают участия',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 25% от общего числа членов комиссии',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии',
        ],
        correctAnswers: [
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии',
        ],
      },
      {
        code: '63688032',
        text:
          'Кем утверждаются производственные инструкции, устанавливающие технологическую последовательность выполнения работ, методы и объемы проверки качества их выполнения?',
        answers: [
          'Руководителем (уполномоченным заместителем руководителя) организации, эксплуатирующей объект',
          'Главным инженером',
          'Инженером по охране труда',
        ],
        correctAnswers: [
          'Руководителем (уполномоченным заместителем руководителя) организации, эксплуатирующей объект',
        ],
      },
      {
        code: '63688033',
        text:
          'При какой концентрации паров СУГ в воздухе огневые работы должны быть приостановлены?',
        answers: [
          'Свыше 10% нижнего концентрационного предела распространения пламени',
          'Свыше 15% нижнего концентрационного предела распространения пламени',
          'Свыше 20% нижнего концентрационного предела распространения пламени',
          'Независимо от их концентрации',
        ],
        correctAnswers: ['Независимо от их концентрации'],
      },
      {
        code: '63688034',
        text:
          'Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением от 0,3 до 6 МПа включительно?',
        answers: [
          'Превышающее разрешенное давление не более чем на 25%',
          'Превышающее разрешенное давление не более чем на 15%',
          'Превышающее разрешенное давление не более чем на 0,05 МПа',
          'Превышающее разрешенное давление не более чем на 10%',
        ],
        correctAnswers: [
          'Превышающее разрешенное давление не более чем на 15%',
        ],
      },
      {
        code: '63688035',
        text:
          'Какие газоопасные работы могут выполняться без оформления наряда-допуска?',
        answers: [
          'Периодически повторяющиеся газоопасные работы, являющиеся неотъемлемой частью технологического процесса, характеризующиеся аналогичными условиями их проведения, постоянством места и характера работ, определенным составом исполнителей',
          'Только газоопасные работы, связанные с осмотрами и проветриванием колодцев, и работы по устранению утечек СУГ и ликвидации аварий',
          'Все газоопасные работы должны выполняться с обязательным оформлением наряда-допуска',
        ],
        correctAnswers: [
          'Периодически повторяющиеся газоопасные работы, являющиеся неотъемлемой частью технологического процесса, характеризующиеся аналогичными условиями их проведения, постоянством места и характера работ, определенным составом исполнителей',
        ],
      },
      {
        code: '63688036',
        text:
          'Какие смазочные масла должны использоваться для компрессоров и насосов?',
        answers: [
          'Любые, имеющиеся в наличии',
          'Только масла, предусмотренные в заводской инструкции к оборудованию',
          'Масла, рекомендованные к применению Ростехнадзором',
        ],
        correctAnswers: [
          'Только масла, предусмотренные в заводской инструкции к оборудованию',
        ],
      },
      {
        code: '63688037',
        text:
          'Что из перечисленного не должно выполняться ежесменно при техническом обслуживании компрессоров и насосов?',
        answers: [
          'Осмотр компрессоров и насосов, запорной и предохранительной арматуры, средств измерений, автоматики и блокировок в целях выявления неисправностей и утечек СУГ',
          'Очистка компрессоров, насосов и КИП от пыли и загрязнений, проверка наличия и исправности заземления и креплений',
          'Контроль за исправным состоянием и положением запорной арматуры и предохранительных клапанов',
          'Проверка исправности доступных для осмотра движущихся частей',
          'Осмотр всех наружных газопроводов и арматуры',
        ],
        correctAnswers: ['Осмотр всех наружных газопроводов и арматуры'],
      },
      {
        code: '63688038',
        text:
          'При какой вместимости баллонов результаты технического освидетельствования баллонов заносятся в паспорт баллона?',
        answers: [
          'Результаты освидетельствования в паспорт баллона не заносятся, ставится только клеймо на баллоне',
          'Свыше 55 до 100 литров',
          'Более 100 литров',
          'До 55 литров',
        ],
        correctAnswers: ['Более 100 литров'],
      },
      {
        code: '63688039',
        text:
          'Кем утверждается программа приемочных испытаний (пусконаладочных работ) технологической системы объекта, использующего СУГ?',
        answers: [
          'Пусконаладочной организацией',
          'Застройщиком или заказчиком',
          'Эксплуатационной организацией',
          'Строительно-монтажной организацией',
        ],
        correctAnswers: ['Застройщиком или заказчиком'],
      },
      {
        code: '63688040',
        text: 'Каким образом должен осуществляться слив СУГ из цистерн?',
        answers: [
          'Только созданием перепада давления между цистерной и резервуаром при работе компрессора',
          'Только созданием перепада давления между цистерной и резервуаром подогревом паров СУГ в испарителе',
          'Только перекачиванием СУГ насосами',
          'Только самотеком при расположении резервуаров ниже цистерны',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '63688041',
        text:
          'Какая информация о произошедших инцидентах направляется в территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), на территории деятельности которого располагается эксплуатируемый объект?',
        answers: [
          'Только количество инцидентов',
          'Только анализ причин возникновения инцидентов и принятые меры по их устранению',
          'Только характер инцидентов',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63688042',
        text:
          'Кем производится запись в журнале о проведенном полном осмотре резервуаров с арматурой и КИП в рабочем состоянии?',
        answers: [
          'Лицом, ответственным за исправное состояние и безопасную эксплуатацию сосудов, работающих под избыточным давлением',
          'Главным механиком организации',
          'Представителем Ростехнадзора',
          'Начальником службы производственного контроля',
        ],
        correctAnswers: [
          'Лицом, ответственным за исправное состояние и безопасную эксплуатацию сосудов, работающих под избыточным давлением',
        ],
      },
      {
        code: '63688043',
        text:
          'Какие требования должны выполняться при эксплуатации резервуаров СУГ?',
        answers: [
          'Резервуары перед наполнением должны быть проверены на наличие избыточного давления, которое должно быть не менее 0,01 МПа',
          'При эксплуатации резервуаров должно осуществляться ежесменное их техническое обслуживание',
          'Резервуары должны вводиться в эксплуатацию на основании письменного разрешения представителя Ростехнадзора после их освидетельствования, проведенного владельцем',
        ],
        correctAnswers: [
          'При эксплуатации резервуаров должно осуществляться ежесменное их техническое обслуживание',
        ],
      },
      {
        code: '63688044',
        text:
          'Каким должен быть максимальный объем жидкой фазы после заполнения резервуара?',
        answers: [
          '70% геометрического объема',
          '80% геометрического объема',
          '85% геометрического объема',
          '90% геометрического объема',
        ],
        correctAnswers: ['85% геометрического объема'],
      },
      {
        code: '63688045',
        text:
          'Кто на объекте, использующем СУГ, отвечает за эксплуатацию зданий и сооружений, соблюдение сроков и качества их ремонта?',
        answers: [
          'Руководитель объекта, использующего СУГ',
          'Технический руководитель объекта, использующего СУГ',
          'Лицо, назначенное приказом руководителя объекта, использующего СУГ',
          'Главный механик объекта, использующего СУГ',
        ],
        correctAnswers: [
          'Лицо, назначенное приказом руководителя объекта, использующего СУГ',
        ],
      },
      {
        code: '63688046',
        text:
          'Что из перечисленного должно выполняться по графику, утвержденному руководителем объекта, использующего СУГ?',
        answers: [
          'Только проверка предохранительных клапанов на срабатывание при давлении настройки',
          'Только осмотр и профилактика задвижек, кранов и вентилей',
          'Только слив конденсата из резервуаров через дренажные устройства',
          'Должно выполняться все перечисленное',
        ],
        correctAnswers: ['Должно выполняться все перечисленное'],
      },
      {
        code: '63688047',
        text:
          'В каком случае допускается эксплуатация компрессоров и насосов при отсутствии ограждения на муфте сцепления и клиноременных передач с электродвигателем?',
        answers: [
          'Не допускается ни в каком случае',
          'Если их отсутствие регламентируется производственной инструкцией',
          'Если получено указание технического руководителя (главного инженера)',
          'Если их отсутствие отражено в эксплуатационном журнале',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63688048',
        text:
          'Какие работы не проводятся при техническом обслуживании вентиляционных установок?',
        answers: [
          'Контроль за температурой подшипников электродвигателей и проверка наличия заземления последних',
          'Контроль за параметрами воздуха, нагнетаемого в помещение',
          'Проверка ограждений вращающихся частей',
          'Определение загрязненности фильтров, пластин и секций у калориферов и проверка заполнения кассет фильтрующим материалом',
          'Разборка и чистка электродвигателей',
        ],
        correctAnswers: ['Разборка и чистка электродвигателей'],
      },
      {
        code: '63688049',
        text:
          'Что из перечисленного должно быть организовано на объекте, использующем СУГ, согласно требованиям к эксплуатации вентиляционного оборудования?',
        answers: [
          'Только учет времени работы вентиляционной установки',
          'Только учет неисправностей при эксплуатации установки',
          'Только учет технического обслуживания и ремонтов оборудования и элементов установки',
          'Должно быть организовано все перечисленное',
        ],
        correctAnswers: ['Должно быть организовано все перечисленное'],
      },
      {
        code: '63688050',
        text:
          'При каком минимальном избыточном давлении в сосуде допускается проведение ремонта сосуда и его элементов?',
        answers: [
          'Не допускается проведение ремонта сосудов и их элементов, подлежащих учету в территориальных органах Ростехнадзора и находящихся под давлением, для остальных сосудов и их элементов - 0,05 МПа',
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
          '0,025 МПа',
          '0,05 МПа',
        ],
        correctAnswers: [
          'Не допускается проведение ремонта сосудов и их элементов, находящихся под давлением',
        ],
      },
      {
        code: '63688051',
        text:
          'Кем проводится техническое обслуживание компрессоров, насосов и испарителей?',
        answers: [
          'Ремонтным персоналом организации под руководством квалифицированного рабочего',
          'Персоналом специализированной организации под руководством специалиста',
          'Эксплуатационным персоналом организации под руководством лица, ответственного за проведение этих работ',
        ],
        correctAnswers: [
          'Эксплуатационным персоналом организации под руководством лица, ответственного за проведение этих работ',
        ],
      },
      {
        code: '63688052',
        text:
          'В каком случае наряд-допуск на выполнение газоопасных работ может быть продлен?',
        answers: [
          'В любом случае, если работа не завершена',
          'Наряд-допуск не продлевается, при продлении сроков проведения работ текущий наряд-допуск закрывается, и при возобновлении работ оформляется новый',
          'Если работа не закончена, а условия ее проведения и характер не изменились',
          'Решение о продлении наряда-допуска принимается отдельно по каждому объекту',
        ],
        correctAnswers: [
          'Если работа не закончена, а условия ее проведения и характер не изменились',
        ],
      },
      {
        code: '63688053',
        text:
          'В каком случае из указанных допускается наполнение баллонов газом?',
        answers: [
          'Если до истечения срока технического освидетельствования остается 1 месяц',
          'Если поврежден корпус баллона',
          'Если отсутствуют установленные клейма',
          'Если отсутствует избыточное давление газа',
        ],
        correctAnswers: [
          'Если до истечения срока технического освидетельствования остается 1 месяц',
        ],
      },
      {
        code: '63688054',
        text:
          'Какой воздухозабор должен быть обеспечен при проверке степени воздухообмена, создаваемого принудительной вентиляцией?',
        answers: [
          '2/3 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 1/3 - из верхней зоны',
          '1/3 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 2/3 - из верхней зоны',
          '1/2 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 1/2 - из верхней зоны',
          '1/5 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 4/5 - из верхней зоны',
        ],
        correctAnswers: [
          '2/3 воздухозабора вытяжной вентиляцией из нижней зоны помещения и 1/3 - из верхней зоны',
        ],
      },
      {
        code: '63688055',
        text:
          'При какой концентрации газа в помещении не допускается работа компрессоров, насосов и испарителей?',
        answers: [
          'Свыше 2% нижнего концентрационного предела распространения пламени',
          'Свыше 3% нижнего концентрационного предела распространения пламени',
          'Свыше 10% нижнего концентрационного предела распространения пламени',
          'Свыше 5% нижнего концентрационного предела распространения пламени',
        ],
        correctAnswers: [
          'Свыше 10% нижнего концентрационного предела распространения пламени',
        ],
      },
      {
        code: '63688056',
        text:
          'Каким образом баллоны, из которых невозможно выпустить газ из-за неисправности вентилей на месте потребления, возвращаются на наполнительную станцию?',
        answers: [
          'Вместе с пустыми (порожними) баллонами с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона',
          'Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона',
          'Любым способом, обеспечивающим безопасность их транспортирования',
        ],
        correctAnswers: [
          'Отдельно от пустых (порожних) баллонов с нанесением на них соответствующей временной надписи (маркировки) любым доступным способом, не нарушающим целостность корпуса баллона',
        ],
      },
      {
        code: '63688057',
        text:
          'Какой максимальный срок эксплуатации, по истечении которого должно проводиться диагностирование, установлен Правилами для технических устройств?',
        answers: ['15 лет', '20 лет', '30 лет', '40 лет'],
        correctAnswers: ['20 лет'],
      },
      {
        code: '63688058',
        text:
          'В какое время суток должен производиться слив СУГ из автомобильных и железнодорожных цистерн в резервуары?',
        answers: [
          'Только в утренние и вечерние часы - в начале и конце смены',
          'Только в светлое время суток',
          'В любое время суток при обязательном освещении места проведения работ',
        ],
        correctAnswers: ['Только в светлое время суток'],
      },
      {
        code: '63688059',
        text:
          'В течение какого срока должна храниться предъявляемая приемочной комиссии документация, включая проектную и исполнительскую документацию, и акт приемочной комиссии?',
        answers: [
          'В течение 10 лет с начала эксплуатации оборудования',
          'В течение 30 лет с момента приемки оборудования в эксплуатацию',
          'В течение всего срока эксплуатации объекта, использующего СУГ (до его ликвидации)',
          'В течение срока эксплуатации оборудования, указанного в его паспорте',
        ],
        correctAnswers: [
          'В течение всего срока эксплуатации объекта, использующего СУГ (до его ликвидации)',
        ],
      },
      {
        code: '63688060',
        text:
          'При какой концентрации газа должны срабатывать газоанализаторы и сигнализаторы, установленные вне помещения?',
        answers: [
          '5% нижнего предела распространения пламени',
          '10% нижнего предела распространения пламени',
          '15% нижнего предела распространения пламени',
          '20% нижнего предела распространения пламени',
        ],
        correctAnswers: ['20% нижнего предела распространения пламени'],
      },
      {
        code: '63688061',
        text:
          'Какой максимальный срок эксплуатации, по истечении которого должно проводиться диагностирование, установлен Правилами для газопроводов?',
        answers: ['15 лет', '20 лет', '30 лет', '40 лет'],
        correctAnswers: ['30 лет'],
      },
      {
        code: '63688062',
        text:
          'Каким требованиям должны соответствовать заглушки, устанавливаемые на газопроводах СУГ?',
        answers: [
          'Должны быть рассчитаны на максимальное рабочее давление и иметь хвостовики, выступающие за пределы фланцев',
          'Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должны быть выбиты номер, марка стали, условный диаметр и давление',
          'Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, не выступающие за пределы фланцев',
          'Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должно быть выбито клеймо с указанием давления СУГ',
        ],
        correctAnswers: [
          'Должны быть рассчитаны на давление 1,6 МПа и иметь хвостовики, выступающие за пределы фланцев. На хвостовиках должны быть выбиты номер, марка стали, условный диаметр и давление',
        ],
      },
      {
        code: '63688063',
        text:
          'Какой из перечисленных видов ремонта не входит в перечень работ по текущему ремонту газопроводов?',
        answers: [
          'Усиление подвижных и неподвижных опор',
          'Устранение провеса надземных газопроводов',
          'Восстановление или замена креплений надземных газопроводов',
          'Ремонт запорной арматуры',
          'Проверка герметичности резьбовых и фланцевых соединений',
        ],
        correctAnswers: ['Усиление подвижных и неподвижных опор'],
      },
      {
        code: '63688064',
        text:
          'При какой величине пробного давления проводится гидравлическое испытание баллонов?',
        answers: [
          'Величину пробного давления устанавливает организация-изготовитель, при этом пробное давление должно быть не менее чем 1,25 рабочего давления',
          'Пробное давление должно быть не менее чем рабочее давление',
          'Величину пробного давления устанавливает организация-изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление',
        ],
        correctAnswers: [
          'Величину пробного давления устанавливает организация-изготовитель, при этом пробное давление должно быть не менее чем полуторное рабочее давление',
        ],
      },
      {
        code: '63688065',
        text:
          'Чьим приказом создается комиссия для технического расследования причин инцидентов на опасном производственном объекте?',
        answers: [
          'Распорядительным актом (приказом) руководителя организации, эксплуатирующей объект',
          'Распорядительным актом (приказом) Ростехнадзора или его территориального органа',
          'Распорядительным актом (приказом) вышестоящего органа организации, эксплуатирующей опасный производственный объект',
          'Распорядительным актом (приказом) территориального органа Ростехнадзора, в котором этот объект зарегистрирован',
        ],
        correctAnswers: [
          'Распорядительным актом (приказом) руководителя организации, эксплуатирующей объект',
        ],
      },
      {
        code: '63688066',
        text:
          'Какие требования установлены к графикам технического обслуживания и ремонта объектов, использующих СУГ?',
        answers: [
          'Графики утверждаются техническим руководителем (главным инженером) объекта и согласовываются при необходимости с организацией, осуществляющей обслуживание и ремонт технических устройств',
          'Графики утверждаются руководителем организации и согласовываются с организацией, осуществляющей обслуживание и ремонт технических устройств',
          'Графики утверждаются техническим руководителем (главным инженером) объекта и согласовываются с территориальным органом Ростехнадзора, осуществляющим надзор за данным объектом',
        ],
        correctAnswers: [
          'Графики утверждаются техническим руководителем (главным инженером) объекта и согласовываются при необходимости с организацией, осуществляющей обслуживание и ремонт технических устройств',
        ],
      },
      {
        code: '63688067',
        text:
          'Какова норма контрольной опрессовки газопроводов паровой фазы СУГ от резервуарной установки, внутренних газопроводов и газового оборудования для низкого давления?',
        answers: [
          '0,01 МПа. Допустимое падение давления за 1 час не должно превышать 0,002 МПа',
          '0,01 МПа. Допустимое падение давления за 1 час не должно превышать 0,0006 МПа',
          '0,01 МПа. Видимого падения давления по манометру быть не должно',
          '0,02 МПа. Допустимое падение давления за 1 час не должно превышать 0,0006 МПа',
        ],
        correctAnswers: [
          '0,01 МПа. Допустимое падение давления за 1 час не должно превышать 0,0006 МПа',
        ],
      },
      {
        code: '63688068',
        text:
          'Какие сведения из указанных не наносятся на сферическую часть баллона?',
        answers: [
          'Сведения, подлежащие нанесению в соответствии с требованиями ТР ТС 032/2013',
          'Дата проведенного и следующего технического освидетельствования баллона',
          'Клеймо организации (индивидуального предпринимателя), проводившей техническое освидетельствование',
          'Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона',
        ],
        correctAnswers: [
          'Сведения о наполнительной станции: дата наполнения и клеймо организации, проводившей наполнение баллона',
        ],
      },
      {
        code: '63688069',
        text:
          'Какие из перечисленных сведений не заносятся в технический паспорт объекта, использующего СУГ?',
        answers: [
          'Сведения о техническом обслуживании',
          'Сведения о капитальном ремонте',
          'Сведения о консервации',
          'Все перечисленные сведения заносятся в технический паспорт объекта',
        ],
        correctAnswers: ['Сведения о техническом обслуживании'],
      },
      {
        code: '63688070',
        text: 'В каких случаях проводятся испытания вентиляционных установок?',
        answers: [
          'Только если испытания проводятся не реже 1 раза в 12 месяцев',
          'Только если испытания проводятся после капитального ремонта и наладки',
          'Только если испытания проводятся после получения неудовлетворительных результатов анализа воздушной среды',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63688071',
        text:
          'При наличии какой концентрации газа в помещении должна включаться аварийная вентиляция?',
        answers: [
          'Свыше 5% нижнего концентрационного предела распространения пламени (НКПР)',
          'Свыше 10% НКПР',
          'Свыше 15% НКПР',
          'Свыше 20% НКПР',
        ],
        correctAnswers: ['Свыше 10% НКПР'],
      },
      {
        code: '63688072',
        text:
          'Что должно быть обозначено краской на шкале или корпусе показывающих манометров?',
        answers: [
          'Значение, соответствующее расчетному давлению',
          'Значение класса точности манометра',
          'Значение, соответствующее рабочему давлению',
          'Условное обозначение прибора',
        ],
        correctAnswers: ['Значение, соответствующее рабочему давлению'],
      },
      {
        code: '63688073',
        text:
          'Какие требования к хранению баллонов на наполнительной станции указаны неверно?',
        answers: [
          'При укладке баллонов в штабеля высота последних не должна превышать 1,5 м, вентили баллонов должны быть обращены в одну сторону',
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
          'При хранении на открытых площадках разрешается укладывать баллоны с башмаками в штабеля с прокладками из веревки, деревянных брусьев, резины или иных неметаллических материалов, имеющих амортизирующие свойства, между горизонтальными рядами',
        ],
        correctAnswers: [
          'Не допускается хранение баллонов, которые не имеют башмаков, в горизонтальном положении на деревянных рамах или стеллажах',
        ],
      },
      {
        code: '63688074',
        text:
          'В каком случае допускается оставлять цистерны присоединенными к газопроводам в период, когда слив СУГ не производится?',
        answers: [
          'Не допускается ни в каком случае',
          'Если период длится не более 1 часа',
          'Если на месте сливоналивных операций присутствует наблюдающий',
          'Если двигатель автомобиля заглушен',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63688075',
        text:
          'Какой перепад давления допускается между цистерной и резервуаром во время слива СУГ?',
        answers: [
          'В пределах 0,5 - 0,6 МПа',
          'В пределах 0,3 - 0,5 МПа',
          'В пределах 0,2 - 0,3 МПа',
          'В пределах 0,15 - 0,2 МПа',
        ],
        correctAnswers: ['В пределах 0,15 - 0,2 МПа'],
      },
      {
        code: '63688076',
        text: 'В каком помещении должно производиться наполнение баллонов СУГ?',
        answers: [
          'В неотапливаемом помещении при температуре воздуха не ниже +5 °С при работающих приточно-вытяжной вентиляции и сигнализации загазованности',
          'В отапливаемом помещении при температуре воздуха не ниже +10 °С при наличии естественной вентиляции',
          'В отапливаемом помещении при температуре воздуха не ниже +15 °С при работающей приточно-вытяжной вентиляции',
          'В отапливаемом помещении при температуре воздуха не ниже +10 °С при работающих приточно-вытяжной вентиляции и сигнализации загазованности',
        ],
        correctAnswers: [
          'В отапливаемом помещении при температуре воздуха не ниже +10 °С при работающих приточно-вытяжной вентиляции и сигнализации загазованности',
        ],
      },
      {
        code: '63688077',
        text:
          'С какой периодичностью должен производиться наружный осмотр газопроводов и арматуры для выявления неплотностей в сварных швах и фланцевых соединениях?',
        answers: [
          'Ежедневно',
          'Ежесменно',
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63688078',
        text:
          'Какая документация должна быть разработана для лиц, занятых эксплуатацией объектов, использующих СУГ?',
        answers: [
          'Правила выполнения работ',
          'Должностные и производственные инструкции',
          'Регламенты по организации работы',
          'Правила трудового распорядка и коллективный договор',
        ],
        correctAnswers: ['Должностные и производственные инструкции'],
      },
      {
        code: '63688079',
        text:
          'В течение какого времени при пусконаладочных работах по вводу в эксплуатацию проводится отработка технологических режимов на газонаполнительных станциях (далее - ГНС) и газонаполнительных пунктах (далее - ГНП)?',
        answers: [
          'Не менее 2 часов',
          'Не менее 12 часов',
          'Не менее 24 часов',
          'Не менее 48 часов',
          'Не менее 72 часов',
        ],
        correctAnswers: ['Не менее 72 часов'],
      },
      {
        code: '63688080',
        text:
          'Какое требование Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, предъявляется к инженерно-техническим работникам, связанным с эксплуатацией оборудования под давлением?',
        answers: [
          'Инженерно-технические работники должны соответствовать квалификационным требованиям',
          'Инженерно-технические работники должны быть не моложе 18-летнего возраста и не иметь медицинских противопоказаний для выполнения работ по обслуживанию оборудования под давлением',
          'Инженерно-технические работники должны пройти аттестацию по промышленной безопасности, в объеме требований промышленной безопасности, необходимых для исполнения трудовых обязанностей (в зависимости от типа конкретного оборудования, к эксплуатации которого они допускаются), знать положения распорядительных документов, инструкций и иных нормативных документов, принятых в организации для обеспечения промышленной безопасности, относящихся к выполняемым обязанностям и выполнять установленные в них требования в процессе выполнения работ',
          'Инженерно-технические работники должны быть допущены к самостоятельной работе в установленном порядке',
          'Все требования указаны верно',
        ],
        correctAnswers: [
          'Инженерно-технические работники должны пройти аттестацию по промышленной безопасности, в объеме требований промышленной безопасности, необходимых для исполнения трудовых обязанностей (в зависимости от типа конкретного оборудования, к эксплуатации которого они допускаются), знать положения распорядительных документов, инструкций и иных нормативных документов, принятых в организации для обеспечения промышленной безопасности, относящихся к выполняемым обязанностям и выполнять установленные в них требования в процессе выполнения работ',
        ],
      },
      {
        code: '63688081',
        text:
          'Какое из перечисленных требований при освобождении резервуаров резервуарной установки указано верно?',
        answers: [
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече" диаметром не менее 20 мм',
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече" высотой 2 м',
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече", установленной на расстоянии не менее 10 м от границы резервуарной установки',
          'Освобождение резервуаров резервуарной установки должно производиться сбросом СУГ в атмосферу',
        ],
        correctAnswers: [
          'Освобождение резервуаров резервуарной установки должно производиться выжиганием остатков СУГ на "свече" диаметром не менее 20 мм',
        ],
      },
      {
        code: '63688082',
        text:
          'Каким образом назначается комиссия по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: [
          'Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в течение 24 часов с момента возникновения аварии',
          'Приказом организации, эксплуатирующей опасный производственный объект, в течение 24 часов с момента возникновения аварии',
          'Приказом вышестоящего органа или организации, эксплуатирующей опасный производственный объект, в течение 24 часов с момента возникновения аварии',
          'Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии',
        ],
        correctAnswers: [
          'Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии',
        ],
      },
      {
        code: '63688083',
        text:
          'Каким способом не разрешается оттаивать арматуру и сливные газопроводы?',
        answers: [
          'Нагретым песком',
          'Открытым огнем',
          'Горячей водой',
          'Водяным паром',
        ],
        correctAnswers: ['Открытым огнем'],
      },
      {
        code: '63688084',
        text:
          'В течение какого срока по результатам технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?',
        answers: [
          'В течение 7 рабочих дней',
          'В течение суток',
          'В течение 10 рабочих дней',
          'В течение 3 рабочих дней',
        ],
        correctAnswers: ['В течение 7 рабочих дней'],
      },
      {
        code: '63688085',
        text:
          'Для каких баллонов допустимая погрешность составляет +/- двадцать граммов?',
        answers: [
          'Для баллонов вместимостью 5 л',
          'Для баллонов вместимостью 1 л',
          'Для баллонов вместимостью 27 л',
          'Для баллонов вместимостью 50 л',
        ],
        correctAnswers: ['Для баллонов вместимостью 5 л'],
      },
      {
        code: '63688086',
        text:
          'С какой периодичностью должен проводиться текущий ремонт противопожарных нормально открытых клапанов и обратных клапанов с записью в паспорт вентсистемы?',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 12 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 12 месяцев'],
      },
      {
        code: '63688087',
        text: 'В каком случае не допускается наполнение СУГ резервуаров?',
        answers: [
          'Только в случае обнаружения трещин, выпучин, пропусков или потения в сварных швах и фланцевых соединениях',
          'Только в случае обнаружения неисправности предохранительных клапанов и уровнемерных устройств',
          'Только в случае неисправности или неполном количестве крепежных деталей на лазах и люках',
          'Только в случае осадки фундаментов резервуаров и опор подводящих газопроводов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63688088',
        text:
          'Каким образом не допускается создавать перепад давлений между цистерной и наполняемым резервуаром?',
        answers: [
          'Работой компрессора',
          'Подогревом паров СУГ в испарителе',
          'Сбросом паровой фазы СУГ из наполняемого резервуара в атмосферу',
          'Всеми перечисленными способами',
        ],
        correctAnswers: [
          'Сбросом паровой фазы СУГ из наполняемого резервуара в атмосферу',
        ],
      },
      {
        code: '63688089',
        text:
          'Когда должны включаться в работу приточные системы вентиляции на ГНС и ГНП?',
        answers: [
          'За 20 минут до включения технологического оборудования',
          'Одновременно с включением вытяжной системы вентиляции',
          'Через 15 минут после включения вытяжных вентиляционных систем',
        ],
        correctAnswers: [
          'Через 15 минут после включения вытяжных вентиляционных систем',
        ],
      },
      {
        code: '63688090',
        text:
          'С какой периодичностью должен осуществляться контроль концентрации СУГ в производственных помещениях переносными газоанализаторами в период замены стационарных сигнализаторов загазованности?',
        answers: [
          'Через каждые 15 минут в течение смены',
          'Через каждые 30 минут в течение смены',
          'Через каждый час в течение смены',
          'Через каждые 3 часа в течение смены',
        ],
        correctAnswers: ['Через каждые 30 минут в течение смены'],
      },
      {
        code: '63688091',
        text:
          'Куда заносятся результаты плановых осмотров вентиляционных установок?',
        answers: [
          'В паспорт установки',
          'В ремонтный журнал',
          'В вахтенный журнал',
          'В эксплуатационный журнал',
        ],
        correctAnswers: ['В эксплуатационный журнал'],
      },
      {
        code: '63688092',
        text:
          'Какие документы вентиляционного оборудования должны храниться на объекте, использующем СУГ?',
        answers: [
          'Все перечисленные документы',
          'Только схема системы воздуховодов с указанием размеров, расположения арматуры, компенсаторов, предохранительных и дренажных устройств',
          'Только акты приемки в эксплуатацию вентиляционной установки',
          'Только паспорта на оборудование, являющееся неотъемлемой частью вентиляционной установки',
        ],
        correctAnswers: ['Все перечисленные документы'],
      },
      {
        code: '63688093',
        text:
          'С какой периодичностью должны осматриваться технические устройства на газонаполнительной станции и газонаполнительном пункте?',
        answers: ['Ежеквартально', 'Еженедельно', 'Ежесменно', 'Ежедневно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63688094',
        text:
          'Какие требования к эксплуатации вентиляционного оборудования указаны неверно?',
        answers: [
          'Вентиляционные системы должны быть определены по функциональным признакам (приточная, вытяжная, аварийная) с присвоением порядкового номера',
          'На вентиляционные системы должны составляться паспорта. В паспорт вносятся информация о производительности системы, ее схема, характеристика, тип вентилятора и электродвигателя, сведения о ремонтах и наладках',
          'На ГНС и ГНП решением главного механика назначается ответственное лицо за эксплуатацию вентиляционных систем',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'На ГНС и ГНП решением главного механика назначается ответственное лицо за эксплуатацию вентиляционных систем',
        ],
      },
      {
        code: '63688095',
        text:
          'С какой периодичностью должна производиться проверка параметров настройки предохранительных сбросных клапанов резервуаров и регулировка на стенде или по месту с помощью специального приспособления?',
        answers: [
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в квартал',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 12 месяцев',
        ],
        correctAnswers: ['Не реже 1 раза в 6 месяцев'],
      },
      {
        code: '63688096',
        text:
          'В каком количестве должны находиться баллоны в наполнительном цехе?',
        answers: [
          'Не больше суммарной часовой производительности наполнительного цеха',
          'Не больше половины суммарной дневной производительности наполнительного цеха',
          'Не больше трети суммарной часовой производительности наполнительного цеха',
          'Не больше половины суммарной часовой производительности наполнительного цеха',
        ],
        correctAnswers: [
          'Не больше половины суммарной часовой производительности наполнительного цеха',
        ],
      },
      {
        code: '63688097',
        text:
          'Кем утверждаются графики, согласно которым газопроводы и установленная на них арматура должны подвергаться ремонтам?',
        answers: [
          'Техническим руководителем (главным инженером) объекта',
          'Руководителем организации, эксплуатирующей объект',
          'Руководителем строительно-монтажной организации',
          'Лицом, ответственным за исправное состояние и безопасную эксплуатацию резервуаров',
        ],
        correctAnswers: [
          'Техническим руководителем (главным инженером) объекта',
        ],
      },
      {
        code: '63688098',
        text:
          'Что из перечисленного должна предусматривать должностная инструкция лица, ответственного за безопасную эксплуатацию объектов, использующих СУГ?',
        answers: [
          'Только разработку производственных инструкций, планов мероприятий по локализации и ликвидации последствий аварий',
          'Только приостановку работы неисправных технических устройств',
          'Только организацию и проведение тренировок специалистов и рабочих по ликвидации возможных аварий',
          'Только выдачу руководителям и инженерно-техническим работникам предписаний по устранению нарушений требований Правил и контроль их выполнения',
          'Должна предусматривать все перечисленное',
        ],
        correctAnswers: ['Должна предусматривать все перечисленное'],
      },
      {
        code: '63688099',
        text:
          'Кто в организации должен отвечать за безопасную эксплуатацию объектов, использующих СУГ?',
        answers: [
          'Лицо из числа инженерно-технических работников, назначенное ответственным за организацию производственного контроля',
          'Главный энергетик',
          'Главный механик',
          'Лицо из числа руководителей или инженерно-технических работников, прошедшее аттестацию, назначенное ответственным за безопасную эксплуатацию объектов, использующих СУГ',
        ],
        correctAnswers: [
          'Лицо из числа руководителей или инженерно-технических работников, прошедшее аттестацию, назначенное ответственным за безопасную эксплуатацию объектов, использующих СУГ',
        ],
      },
      {
        code: '63688100',
        text:
          'По чьему указанию производят снятие заглушек после контрольной опрессовки отключенного участка газопровода и оборудования?',
        answers: [
          'Руководителя газоопасных работ',
          'Руководителя объекта, использующего СУГ',
          'Лица, ответственного за безопасную эксплуатацию объекта, использующего СУГ',
          'Технического руководителя (главного инженера) объекта, использующего СУГ',
        ],
        correctAnswers: ['Руководителя газоопасных работ'],
      },
      {
        code: '63688101',
        text:
          'Какому требованию должен соответствовать сжатый воздух, использующийся для пневматических устройств, систем автоматического регулирования и контроля, в случае отсутствия требований, установленных в проектной, а также в эксплуатационной документации изготовителя оборудования?',
        answers: [
          'Размер единичной твердой частицы - не более 15 мкм',
          'Содержание твердых примесей - не более 4 мг/м³',
          'Давление - не более 1,3 МПа',
          'Температура точки росы - не менее -40 °C',
        ],
        correctAnswers: ['Температура точки росы - не менее -40 °C'],
      },
      {
        code: '63688102',
        text:
          'Каким документом определяется порядок действия в случае инцидента при эксплуатации сосуда?',
        answers: [
          'Инструкцией (руководством) по эксплуатации предприятия - изготовителя сосуда',
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
          'Паспортом оборудования, работающего под давлением',
        ],
        correctAnswers: [
          'Производственной инструкцией, утвержденной эксплуатирующей организацией',
        ],
      },
      {
        code: '63688103',
        text:
          'Где может проводиться восстановление окраски и надписей на баллонах?',
        answers: [
          'На наполнительной станции',
          'На пунктах освидетельствования баллонов',
          'В любой специализированной ремонтной организации',
          'В эксплуатирующей организации',
        ],
        correctAnswers: ['На пунктах освидетельствования баллонов'],
      },
      {
        code: '63688104',
        text:
          'Количество персонала, выполняющего слив СУГ из железнодорожных и автомобильных цистерн в резервуары резервуарных установок, должно быть не менее:',
        answers: ['2 рабочих', '3 рабочих', '4 рабочих'],
        correctAnswers: ['2 рабочих'],
      },
      {
        code: '63688105',
        text:
          'Что из перечисленного не обязана выполнять организация, эксплуатирующая объекты, использующие СУГ?',
        answers: [
          'Выполнять мероприятия, обеспечивающие содержание в безопасном состоянии объектов, использующих СУГ',
          'Заключать договоры с организациями, выполняющими работы по техническому обслуживанию и ремонту технических устройств, обеспечивающими безопасное их проведение, в случае, если организация, эксплуатирующая объекты, использующие СУГ, не имеет в своем составе газовой службы',
          'Обеспечивать организацию и проведение обследования состояния зданий, сооружений и газопроводов в сроки, установленные в проектной документации',
          'Обязана выполнять все перечисленное',
        ],
        correctAnswers: ['Обязана выполнять все перечисленное'],
      },
      {
        code: '63688106',
        text:
          'В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?',
        answers: [
          'В месячный срок',
          'В десятидневный срок',
          'В недельный срок',
          'В двухнедельный срок',
        ],
        correctAnswers: ['В двухнедельный срок'],
      },
      {
        code: '63688107',
        text:
          'Какой воздухообмен в час должна обеспечить вентиляционная установка в нерабочее время?',
        answers: ['3-кратный', '5-кратный', '8-кратный', '10-кратный'],
        correctAnswers: ['3-кратный'],
      },
      {
        code: '63688108',
        text:
          'Какую величину не должна превышать объемная доля кислорода после окончания продувки газопроводов и оборудования СУГ парами СУГ?',
        answers: ['0,5%', '1%', '5%', '10%'],
        correctAnswers: ['1%'],
      },
      {
        code: '63688109',
        text:
          'Каково минимальное значение избыточного давления газа, которое должно оставаться в опорожняемых потребителем цистернах и бочках?',
        answers: [
          '0,07 МПа',
          '0,05 МПа',
          'Значение избыточного давления указывается в производственной инструкции организации, осуществляющей наполнение цистерн и бочек',
          'Потребитель, опорожняя цистерны и бочки, обязан срабатывать давление полностью, не оставляя в порожнем оборудовании избыточное давление',
        ],
        correctAnswers: ['0,05 МПа'],
      },
      {
        code: '63688110',
        text:
          'Какие действия, выполняемые перед пуском насосов, компрессоров и испарителей объектов, использующих СУГ, указаны неверно?',
        answers: [
          'За пять минут до пуска насосов, компрессоров и испарителей включить приточно-вытяжную вентиляцию и проверить состояние воздушной среды в помещениях',
          'Проверить исправность и герметичность арматуры и газопроводов, предохранительных клапанов и КИП, исправность пусковых и заземляющих устройств',
          'Проверить исправность автоматики безопасности и блокировок',
          'Уточнить причины остановки оборудования (по журналу) и убедиться, что неисправность устранена',
          'Все перечисленные действия указаны верно',
        ],
        correctAnswers: [
          'За пять минут до пуска насосов, компрессоров и испарителей включить приточно-вытяжную вентиляцию и проверить состояние воздушной среды в помещениях',
        ],
      },
      {
        code: '63688111',
        text: 'Какое требование к наполнению баллонов газами указано неверно?',
        answers: [
          'Наполнение баллонов сжиженными газами должно соответствовать нормам, установленным организацией - изготовителем баллонов и (или) техническими условиями на сжиженные газы',
          'Перед наполнением кислородных баллонов должен быть проведен контроль отсутствия в них примеси горючих газов газоанализатором в порядке, установленном инструкцией',
          'Наполнение баллонов газами должно быть произведено по инструкции, разработанной и утвержденной наполнительной организацией (индивидуальным предпринимателем) в установленном порядке с учетом свойств газа, местных условий и требований руководства (инструкции) по эксплуатации и иной документации изготовителя баллона',
          'При отсутствии установленных организацией - изготовителем баллонов и (или) техническими условиями на сжиженные газы норм наполнения наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013',
        ],
        correctAnswers: [
          'При отсутствии установленных организацией - изготовителем баллонов и (или) техническими условиями на сжиженные газы норм наполнения наполнение баллонов производится в соответствии с нормами, установленными ТР ТС 032/2013',
        ],
      },
      {
        code: '63688112',
        text:
          'Кем рассматриваются вопросы устранения неполадок оборудования и продолжения пусконаладочных работ?',
        answers: [
          'Комиссией, созданной из представителей пусконаладочной и эксплуатирующей организаций',
          'Руководителем пусконаладочных работ',
          'Техническим руководителем (главным инженером) организации, эксплуатирующей объекты, использующие СУГ',
          'Руководителем организации, эксплуатирующей объекты, использующие СУГ',
        ],
        correctAnswers: [
          'Комиссией, созданной из представителей пусконаладочной и эксплуатирующей организаций',
        ],
      },
      {
        code: '63688113',
        text:
          'На какое минимальное расстояние должен быть удален локомотив с территории сливной эстакады при подготовке к сливу СУГ из железнодорожных цистерн?',
        answers: ['100 м', '150 м', '50 м', '200 м'],
        correctAnswers: ['100 м'],
      },
      {
        code: '63688114',
        text:
          'Кто дает разрешение на пуск и остановку насосов, компрессоров и испарителей?',
        answers: [
          'Руководитель организации',
          'Технический руководитель (главный инженер) объекта, использующего СУГ',
          'Представитель специализированной организации',
          'Представитель Ростехнадзора',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) объекта, использующего СУГ',
        ],
      },
      {
        code: '63688115',
        text:
          'С какой периодичностью должен проводиться текущий ремонт наружных сетей водопровода и канализации?',
        answers: [
          'Один раз в двенадцать месяцев',
          'Один раз в два года',
          'Один раз в три года',
          'Один раз в пять лет',
        ],
        correctAnswers: ['Один раз в два года'],
      },
      {
        code: '63688116',
        text:
          'Кто должен испытывать на герметичность технологическую систему объекта, использующего сжиженные углеводородные газы (далее – СУГ), перед проведением пусконаладочных работ?',
        answers: [
          'Пусконаладочная организация',
          'Эксплуатационная организация',
          'Строительно-монтажная организация',
          'Экспертная организация',
        ],
        correctAnswers: ['Строительно-монтажная организация'],
      },
      {
        code: '63688117',
        text:
          'Какое из приведенных требований к манометрам, устанавливаемым на сосудах, указано неверно?',
        answers: [
          'Манометр должен быть выбран с такой шкалой, чтобы предел измерения рабочего давления находился во второй трети шкалы',
          'Взамен красной черты разрешается в качестве указателя значения максимально допустимого давления прикреплять к корпусу манометра пластину (скобу) из металла или иного материала достаточной прочности, окрашенную в красный цвет и плотно прилегающую к стеклу манометра',
          'На шкале манометра владельцем сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде',
          'Все приведенные требования верны',
        ],
        correctAnswers: [
          'На шкале манометра владельцем сосуда должна быть нанесена красная черта, указывающая разрешенное давление в сосуде',
        ],
      },
      {
        code: '63688118',
        text:
          'В течение какого срока составляется акт технического расследования причин аварии на опасном производственном объекте?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее 30 рабочих дней',
          'Не позднее 15 календарных дней',
          'Не позднее 3 рабочих дней',
          'Не позднее 45 календарных дней',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63688119',
        text:
          'На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?',
        answers: [
          'Не более чем на 15 календарных дней',
          'Не более чем на 15 рабочих дней',
          'Не более чем на 10 рабочих дней',
          'Не более чем на 30 календарных дней',
        ],
        correctAnswers: ['Не более чем на 15 календарных дней'],
      },
      {
        code: '63688120',
        text:
          'Какова норма контрольной опрессовки наружных и внутренних газопроводов паровой и жидкой фазы СУГ ГНС и ГНП, резервуаров СУГ, газопроводов обвязки?',
        answers: [
          '0,3 МПа в течение одного часа. Допустимое падение давления не должно превышать 0,0002 МПа',
          '0,3 МПа в течение одного часа. Видимого падения давления по манометру быть не должно',
          '0,03 МПа в течение одного часа. Видимого падения давления по манометру быть не должно',
          '0,01 МПа в течение одного часа. Допустимое падение давления не должно превышать 0,0006 МПа',
        ],
        correctAnswers: [
          '0,3 МПа в течение одного часа. Видимого падения давления по манометру быть не должно',
        ],
      },
      {
        code: '63688121',
        text:
          'Какие мероприятия должны осуществляться с законсервированными наружными газопроводами?',
        answers: [
          'Периодические осмотры и приборный контроль',
          'Текущий и капитальный ремонт',
          'Наружный осмотр',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Наружный осмотр'],
      },
      {
        code: '63688122',
        text:
          'Допускается ли работа насосов, компрессоров и испарителей с отключенной автоматикой, аварийной сигнализацией, а также блокировкой с вентиляторами вытяжных систем?',
        answers: [
          'Допускается в течение времени, указанного в производственной инструкции',
          'Не допускается',
          'Допускается по письменному распоряжению технического руководителя объекта, использующего СУГ, на время, которое требуется для ремонта автоматики, аварийной сигнализации и блокировки',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63688123',
        text:
          'С какой периодичностью должен производиться осмотр надземных газопроводов?',
        answers: [
          'Ежесменно',
          'Ежедневно',
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца',
        ],
        correctAnswers: ['Не реже одного раза в три месяца'],
      },
      {
        code: '63688124',
        text:
          'Какой документ должен прилагаться к производственной инструкции?',
        answers: [
          'Технологическая схема газопроводов и технических устройств',
          'Паспорт на технические устройства',
          'Акт проведенных пусконаладочных работ',
          'Все перечисленные документы',
        ],
        correctAnswers: [
          'Технологическая схема газопроводов и технических устройств',
        ],
      },
      {
        code: '63688125',
        text:
          'При каком условии допускается дальнейшая эксплуатация забракованных баллонов?',
        answers: [
          'Эксплуатация не допускается, они должны быть приведены в негодность независимо от их назначения любым способом, исключающим возможность их дальнейшего использования, и утилизированы согласно требованиям руководства (инструкции) по эксплуатации',
          'Дальнейшая эксплуатация допускается при величине 0,75 рабочего давления',
          'Дальнейшая эксплуатация допускается только для баллонов специального назначения при условии ежедневного наружного осмотра',
          'Дальнейшая эксплуатация допускается при заполнении баллонов не более чем на 50% их рабочего объема',
        ],
        correctAnswers: [
          'Эксплуатация не допускается, они должны быть приведены в негодность независимо от их назначения любым способом, исключающим возможность их дальнейшего использования, и утилизированы согласно требованиям руководства (инструкции) по эксплуатации',
        ],
      },
      {
        code: '63688126',
        text:
          'Кому сообщается о выявленных неисправностях в работе средств измерений и автоматики безопасности?',
        answers: [
          'Техническому руководителю (главному инженеру) объекта, использующего СУГ',
          'Руководителю пусконаладочной бригады',
          'Лицу, ответственному за безопасную эксплуатацию объекта, использующего СУГ',
          'Руководителю объекта, использующего СУГ',
        ],
        correctAnswers: [
          'Техническому руководителю (главному инженеру) объекта, использующего СУГ',
        ],
      },
      {
        code: '63688127',
        text:
          'Какая из перечисленных газоопасных работ проводится по наряду-допуску и специальному плану, утвержденному техническим руководителем объекта, использующего СУГ?',
        answers: [
          'Установка и снятие заглушек на действующих газопроводах',
          'Ремонт, осмотр и проветривание колодцев',
          'Раскопка грунта в местах утечки СУГ до ее устранения',
          'Расконсервация оборудования',
        ],
        correctAnswers: ['Расконсервация оборудования'],
      },
      {
        code: '63688128',
        text:
          'Какие из перечисленных видов работ относятся к газоопасным работам на объектах, использующих СУГ?',
        answers: [
          'Все перечисленные виды работ',
          'Только проведение пусконаладочных работ',
          'Только удаление закупорок',
          'Только раскопка грунта в местах утечки СУГ до ее устранения',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63688129',
        text:
          'Какие из перечисленных работ допускается производить в темное время суток?',
        answers: [
          'Все перечисленные',
          'Техническое обслуживание технических устройств',
          'Ремонт технических устройств',
          'Аварийно-восстановительные работы',
        ],
        correctAnswers: ['Аварийно-восстановительные работы'],
      },
      {
        code: '63688130',
        text:
          'В каких случаях компрессоры и насосы на объектах, использующих СУГ, должны быть остановлены?',
        answers: [
          'Только в случае повышения или понижения установленного давления газа во всасывающем и напорном газопроводе',
          'Только в случае утечек газа и неисправностей запорной арматуры',
          'Только в случае нарушений в работе систем вентиляции',
          'Только в случае появления вибрации, посторонних шумов и стуков',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63688131',
        text:
          'Какому испытанию должны подвергаться рукава, применяемые при сливоналивных операциях, и с какой периодичностью?',
        answers: [
          'Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в 3 месяца',
          'Пневматическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в 3 месяца',
          'Гидравлическому испытанию на прочность давлением, равным 1,5 рабочего давления, не реже одного раза в месяц',
          'Гидравлическому испытанию на прочность давлением, равным 1,5 рабочего давления, не реже одного раза в 3 месяца',
        ],
        correctAnswers: [
          'Гидравлическому испытанию на прочность давлением, равным 1,25 рабочего давления, не реже одного раза в 3 месяца',
        ],
      },
      {
        code: '63688132',
        text: 'Какой документ оформляется на проведение газоопасных работ?',
        answers: [
          'Письменное разрешение',
          'Наряд-допуск',
          'Распоряжение',
          'План проведения работ',
        ],
        correctAnswers: ['Наряд-допуск'],
      },
      {
        code: '63688133',
        text:
          'Давление настройки предохранительных сбросных клапанов не должно превышать рабочее давление более чем:',
        answers: ['На 15%', 'На 10%', 'На 20%', 'На 25%'],
        correctAnswers: ['На 15%'],
      },
      {
        code: '63688134',
        text:
          'При какой температуре наружного воздуха допускается наполнять баллоны СУГ на открытых площадках?',
        answers: [
          'Не ниже -30 °С',
          'Не ниже -25 °С',
          'Не ниже -20 °С',
          'Не ниже -10 °С',
        ],
        correctAnswers: ['Не ниже -20 °С'],
      },
      {
        code: '63688135',
        text:
          'Кто принимает решение о вводе в эксплуатацию сосуда, работающего под давлением?',
        answers: [
          'Уполномоченный представитель Ростехнадзора',
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
          'Уполномоченный представитель Ростехнадзора принимает решение о вводе в эксплуатацию сосудов, подлежащих учету в органах Ростехнадзора, в остальных случаях решение принимает лицо, ответственное за осуществление производственного контроля безопасной эксплуатации оборудования под давлением',
          'Лицо, ответственное за исправное состояние и безопасную эксплуатацию сосуда',
        ],
        correctAnswers: [
          'Руководитель (или уполномоченное им должностное лицо) эксплуатирующей организации (обособленного структурного подразделения)',
        ],
      },
      {
        code: '63688136',
        text:
          'Какое допускается максимальное превышение давления настройки предохранительного сбросного клапана?',
        answers: [
          'На 15% рабочего давления в резервуарах и газопроводах',
          'На 10% рабочего давления в резервуарах и газопроводах',
          'На 30% рабочего давления в резервуарах и газопроводах',
          'На 25% рабочего давления в резервуарах и газопроводах',
        ],
        correctAnswers: [
          'На 15% рабочего давления в резервуарах и газопроводах',
        ],
      },
      {
        code: '63688137',
        text:
          'Каким должно быть остаточное давление паров в цистерне после слива СУГ?',
        answers: [
          'Не ниже 0,1 МПа',
          'Не ниже 0,01 МПа',
          'Не ниже 0,5 МПа',
          'Не ниже 0,05 МПа',
        ],
        correctAnswers: ['Не ниже 0,05 МПа'],
      },
      {
        code: '63688138',
        text:
          'Какой воздухообмен в час должна обеспечить аварийная вытяжная вентиляция?',
        answers: ['3-кратный', '5-кратный', '8-кратный', '10-кратный'],
        correctAnswers: ['8-кратный'],
      },
      {
        code: '63688139',
        text: 'Какие требования к сливу СУГ из цистерны указаны неверно?',
        answers: [
          'По окончании слива запорная арматура на цистерне должна быть заглушена',
          'В период слива СУГ должен осуществляться контроль за давлением и уровнем СУГ в цистерне и приемном резервуаре',
          'Во время слива СУГ не допускается производить работы по уплотнению соединений, находящихся под давлением',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '63688140',
        text:
          'В течение какого времени подвергаются контрольной опрессовке воздухом или инертным газом с избыточным давлением 0,3 МПа газопроводы, резервуары и технические устройства при проведении пусконаладочных работ на объектах, использующих СУГ, перед продувкой паровой фазой СУГ?',
        answers: [
          'В течение 15 минут',
          'В течение часа',
          'В течение 6 часов',
          'В течение 3 часов',
        ],
        correctAnswers: ['В течение часа'],
      },
      {
        code: '63688141',
        text:
          'Кто является ответственным за безопасное проведение пусконаладочных работ на объектах, использующих СУГ?',
        answers: [
          'Руководитель объекта, использующего СУГ',
          'Руководитель пусконаладочной бригады',
          'Руководитель пусконаладочной организации',
          'Технический руководитель объекта, использующего СУГ',
        ],
        correctAnswers: ['Руководитель пусконаладочной бригады'],
      },
      {
        code: '63688142',
        text:
          'При соблюдении каких условий допускается разборка арматуры, резьбовых и фланцевых соединений на газопроводах СУГ?',
        answers: [
          'После отключения и продувки воздухом',
          'После отключения и продувки инертным газом или паром',
          'После отключения и промывки водой',
          'После отключения без последующей продувки отключенного газопровода',
        ],
        correctAnswers: [
          'После отключения и продувки инертным газом или паром',
        ],
      },
      {
        code: '63688143',
        text:
          'Какие из перечисленных действий, которые необходимо выполнить после включения электродвигателя компрессора, указаны неверно?',
        answers: [
          'Убедиться в правильном вращении вала по стрелке на передней крышке картера',
          'Открыть вентиль на нагнетательном патрубке компрессора при достижении номинального числа оборотов вала электродвигателя компрессора',
          'Постепенно открыть вентиль на всасывающем патрубке компрессора',
          'Все перечисленные действия указаны верно',
        ],
        correctAnswers: ['Все перечисленные действия указаны верно'],
      },
      {
        code: '63688144',
        text:
          'Какие требования к сливу СУГ из цистерны автомобиля указаны неверно?',
        answers: [
          'Не занятый на операции слива персонал не должен находиться на месте производства работ',
          'Включать двигатель автомобиля допускается только после отсоединения шлангов от газопроводов и установки заглушек на сливные штуцера',
          'Во время слива СУГ нахождение водителя в кабине является обязательным',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'Во время слива СУГ нахождение водителя в кабине является обязательным',
        ],
      },
      {
        code: '63688145',
        text:
          'При выявлении каких дефектов во время осмотра наружной и внутренней поверхности баллоны не подлежат отбраковке?',
        answers: [
          'Выявление всех указанных дефектов влечет за собой отбраковку баллона',
          'Ослабление кольца на горловине баллона',
          'Износ резьбы горловины',
          'Наличие надрывов и выщерблений',
          'Наличие трещин, плен, вмятин, отдулин, раковин и рисок глубиной более 10% номинальной толщины стенки',
        ],
        correctAnswers: ['Ослабление кольца на горловине баллона'],
      },
      {
        code: '63688146',
        text:
          'Какой воздухообмен в час должна обеспечить вентиляционная установка в рабочее время?',
        answers: ['3-кратный', '5-кратный', '8-кратный', '10-кратный'],
        correctAnswers: ['10-кратный'],
      },
      {
        code: '63688147',
        text:
          'Какое из перечисленных действий допускается при эксплуатации насосов, компрессоров и испарителей?',
        answers: [
          'Передача вращения от двигателя к насосу и компрессору через эластичные муфты или при помощи клиновидных ременных передач',
          'Устройство приямков, подпольных каналов в помещении НКО',
          'Эксплуатация испарителей при попадании жидкой фазы в газопровод паровой фазы',
          'Продувка и дренирование насосов, компрессоров и трубопроводов в насосно-компрессорном помещении',
          'Работа компрессоров и насосов без манометров',
        ],
        correctAnswers: [
          'Передача вращения от двигателя к насосу и компрессору через эластичные муфты или при помощи клиновидных ременных передач',
        ],
      },
      {
        code: '63688148',
        text:
          'С какой периодичностью должна производиться проверка срабатывания устройств сигнализации и блокировок автоматики безопасности?',
        answers: [
          'Не реже 1 раза в смену',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 3 месяца',
        ],
        correctAnswers: ['Не реже 1 раза в месяц'],
      },
      {
        code: '63688149',
        text:
          'Какие требования Правил промышленной безопасности при использовании оборудования, работающего под избыточным давлением, к оснащению баллонов предохранительным клапаном указаны неверно?',
        answers: [
          'Пропускная способность предохранительного клапана подтверждается расчетом',
          'Баллоны вместимостью более 100 л должны быть оснащены предохранительными клапанами',
          'При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон',
          'При групповой установке баллонов допускается установка предохранительного клапана на всю группу баллонов',
        ],
        correctAnswers: [
          'При групповой установке баллонов предохранительным клапаном обязательно оснащается каждый баллон',
        ],
      },
      {
        code: '63688150',
        text:
          'Что из перечисленного не входит в полномочия лиц, ответственных за безопасную эксплуатацию объектов, использующих СУГ?',
        answers: [
          'Требование отстранения от обслуживания технических устройств и выполнения газоопасных работ лиц, не прошедших проверку знаний или показавших неудовлетворительные знания Правил безопасности для объектов, использующих сжиженные углеводородные газы',
          'Осуществление контроля при строительстве, реконструкции, техническом перевооружении, консервации и ликвидации опасных производственных объектов',
          'Назначение ответственного лица за эксплуатацию вентиляционных систем',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Назначение ответственного лица за эксплуатацию вентиляционных систем',
        ],
      },
      {
        code: '63688151',
        text: 'Кто дает распоряжения в процессе выполнения газоопасных работ?',
        answers: [
          'Технический руководитель объекта, использующего СУГ',
          'Лицо, ответственное за проведение газоопасной работы',
          'Руководитель объекта, использующего СУГ',
          'Лицо, ответственное за безопасную эксплуатацию объекта, использующего СУГ',
        ],
        correctAnswers: [
          'Лицо, ответственное за проведение газоопасной работы',
        ],
      },
      {
        code: '63688152',
        text:
          'С какой периодичностью должно проводиться техническое обслуживание с записью в журнале венткамер и помещений категории А по пожарной безопасности?',
        answers: ['Ежесменно', 'Еженедельно', 'Ежемесячно', '1 раз в квартал'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63688153',
        text:
          'С какой периодичностью проводится проверка параметров настройки предохранительного сбросного клапана резервуаров?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в 6 месяцев'],
      },
      {
        code: '63688154',
        text:
          'С какой периодичностью проводится проверка работы предохранительных клапанов в рамках технического обслуживания наполнительных установок?',
        answers: [
          'Один раз в месяц',
          'Один раз в квартал',
          'Один раз в полгода',
          'Один раз в неделю',
        ],
        correctAnswers: ['Один раз в месяц'],
      },
      {
        code: '63688155',
        text:
          'Какая документация должна быть оформлена на объекте, использующем СУГ, при эксплуатации насосов, компрессоров и испарителей установок?',
        answers: [
          'Только техническое описание оборудования, инструкции по эксплуатации, инструкции по охране труда, техническому обслуживанию, пуску, обкатке, ведомости запасных частей',
          'Только акты индивидуальных испытаний',
          'Только руководство по ремонту, предельные нормы износа быстроизнашивающихся деталей и узлов',
          'Только описание технологического процесса и ремонта оборудования',
          'Вся перечисленная документация',
        ],
        correctAnswers: ['Вся перечисленная документация'],
      },
      {
        code: '63688156',
        text:
          'Кем должен утверждаться график проверки загазованности колодцев всех подземных коммуникаций в пределах территории объекта, использующего СУГ?',
        answers: [
          'Руководителем организации',
          'Инспектором Ростехнадзора',
          'Техническим руководителем объекта, использующего СУГ',
          'Главным механиком объекта, использующего СУГ',
        ],
        correctAnswers: [
          'Техническим руководителем объекта, использующего СУГ',
        ],
      },
      {
        code: '63688157',
        text:
          'С какой периодичностью проводятся учебно-тренировочные занятия по локализации и ликвидации последствий аварий на объектах, использующих СУГ?',
        answers: [
          'Не реже 1 раза месяц',
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Ежесменно',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '63688158',
        text:
          'Кто возглавляет комиссию по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: [
          'Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа',
          'Представитель вышестоящего органа или организации, эксплуатирующей опасный производственный объект',
          'Представитель организации, эксплуатирующей опасный производственный объект',
          'Представитель органа исполнительной власти субъекта Российской Федерации и (или) органа местного самоуправления, на территории которых располагается опасный производственный объект',
        ],
        correctAnswers: [
          'Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа',
        ],
      },
      {
        code: '63688159',
        text:
          'Чем осуществляется продувка сосуда, работающего под давлением горючих газов, до начала выполнения работ внутри его корпуса?',
        answers: [
          'Для таких сосудов предусмотрена специальная обработка (дегазация, нейтрализация)',
          'Инертным газом и (или) воздухом',
          'Только воздухом',
        ],
        correctAnswers: ['Инертным газом и (или) воздухом'],
      },
      {
        code: '63688160',
        text:
          'Что из перечисленного допускается проверять нанесением пенообразующего раствора или акустическими приборами (течеискателями) при контрольной опрессовке?',
        answers: [
          'Только сварные стыки',
          'Только резьбовые и фланцевые соединения',
          'Только сальниковые уплотнения',
          'Проверяется все перечисленное',
        ],
        correctAnswers: ['Проверяется все перечисленное'],
      },
      {
        code: '63688161',
        text:
          'В какой документ заносятся результаты проверки исправности предохранительных устройств, установленных на сосуде, и сведения об их настройке?',
        answers: [
          'В сменный (оперативный) журнал или иные эксплуатационные документы',
          'В паспорт сосуда',
          'В соответствующее приложение (проверка исправности и сведения о настройках) к производственной инструкции по эксплуатации предохранительных устройств',
          'В паспорт предохранительного клапана',
        ],
        correctAnswers: [
          'В сменный (оперативный) журнал или иные эксплуатационные документы',
        ],
      },
      {
        code: '63688162',
        text:
          'Какое допускается максимальное давление настройки предохранительных сбросных клапанов резервуаров?',
        answers: [
          'Не более чем на 50% превышающее рабочее давление',
          'Не более чем на 10% превышающее рабочее давление',
          'Не более чем на 15% превышающее рабочее давление',
          'Не более чем на 25% превышающее рабочее давление',
        ],
        correctAnswers: ['Не более чем на 15% превышающее рабочее давление'],
      },
      {
        code: '63688163',
        text:
          'На основании какого документа осуществляется пуск (включение) в работу и штатная остановка сосуда?',
        answers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
          'На основании письменного распоряжения ответственного за осуществление производственного контроля за безопасной эксплуатацией оборудования, работающего под давлением',
          'На основании приказа руководителя эксплуатирующей организации',
        ],
        correctAnswers: [
          'На основании письменного распоряжения ответственного за исправное состояние и безопасную эксплуатацию сосуда',
        ],
      },
      {
        code: '63688164',
        text:
          'Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением более 6 МПа?',
        answers: [
          'Превышающее разрешенное давление не более чем на 25%',
          'Превышающее разрешенное давление не более чем на 0,05 МПа',
          'Превышающее разрешенное давление не более чем на 10%',
          'Превышающее разрешенное давление не более чем на 15%',
        ],
        correctAnswers: [
          'Превышающее разрешенное давление не более чем на 10%',
        ],
      },
      {
        code: '63688165',
        text:
          'Какой должна быть температура воздуха в рабочее время в насосно-компрессорном отделении с оборудованием с водяным охлаждением?',
        answers: [
          'Не ниже +5 °C',
          'Не ниже +8 °C',
          'Не ниже +10 °C',
          'Не ниже 0 °C',
        ],
        correctAnswers: ['Не ниже +10 °C'],
      },
      {
        code: '63688166',
        text:
          'Количество персонала, выполняющего слив СУГ из железнодорожных и автомобильных цистерн и наполнение автомобильных цистерн, должно быть не менее:',
        answers: ['2 рабочих', '3 рабочих', '4 рабочих'],
        correctAnswers: ['3 рабочих'],
      },
      {
        code: '63688167',
        text: 'Где могут храниться наполненные баллоны с газами?',
        answers: [
          'Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей',
          'Правила промышленной безопасности при использовании оборудования, работающего под избыточным давлением, не устанавливают дополнительных требований к помещениям для хранения баллонов',
          'Только в специально оборудованном помещении',
        ],
        correctAnswers: [
          'Как в специальных помещениях, так и на открытом воздухе, в последнем случае они должны быть защищены от атмосферных осадков и солнечных лучей',
        ],
      },
      {
        code: '63688168',
        text:
          'Каким образом осуществляется учет транспортируемых сосудов (цистерн) в территориальных органах Ростехнадзора (за исключением подлежащих учету в ином федеральном органе исполнительной власти в области промышленной безопасности)?',
        answers: [
          'Цистерны подлежат учету в органе Ростехнадзора как по месту нахождения (регистрации) организации, эксплуатирующей эти цистерны, так и по месту их использования (временный учет) при сроках их использования на этом месте более 3 месяцев',
          'Транспортируемые сосуды (цистерны) не подлежат учету в территориальных органах Ростехнадзора',
          'Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию',
          'Цистерны подлежат учету в органе Ростехнадзора по месту нахождения (регистрации) организации, эксплуатирующей эти цистерны',
        ],
        correctAnswers: [
          'Цистерны подлежат учету в органе Ростехнадзора по месту нахождения площадки (при наличии) эксплуатирующей организации, на которой производятся работы по ремонту, техническому обслуживанию и освидетельствованию',
        ],
      },
      {
        code: '63688169',
        text:
          'Существует ли разница в нанесении надписей на баллонах вместимостью более 12 литров и до 12 литров?',
        answers: [
          'Существует, размеры надписей и полос на баллонах вместимостью до 12 литров должны определяться в зависимости от величины боковой поверхности баллонов',
          'Разницы нет, надписи наносятся по окружности на длину не менее 1/3 окружности, а полосы - по всей окружности',
          'Существует, размеры надписей на баллонах вместимостью до 12 литров наносят на длину не менее 1/2 окружности, а для баллонов более 12 литров - на длину не менее 1/3 окружности',
          'Разница только в высоте букв: на баллонах вместимостью до 12 литров высота букв должна быть 40 мм, а на баллонах более 12 литров - 60 мм',
        ],
        correctAnswers: [
          'Существует, размеры надписей и полос на баллонах вместимостью до 12 литров должны определяться в зависимости от величины боковой поверхности баллонов',
        ],
      },
      {
        code: '63688170',
        text:
          'По истечении какого срока эксплуатации здания и сооружения должны пройти обследование для установления возможности дальнейшей их эксплуатации, необходимости проведения реконструкции или прекращения эксплуатации?',
        answers: [
          'По истечении 30 лет',
          'По истечении 40 лет',
          'Срока, предусмотренного в проектной документации',
          'По истечении 75 лет',
        ],
        correctAnswers: ['Срока, предусмотренного в проектной документации'],
      },
      {
        code: '63688171',
        text:
          'В каком случае при установке заглушек на газопроводах обвязки насоса, компрессора, испарителя, подлежащих ремонту, работа остальных насосов, компрессоров, испарителей может не прекращаться?',
        answers: [
          'По решению руководителя организации, эксплуатирующей объект, при обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам',
          'В любом случае работа оборудования должна быть прекращена без исключений',
          'В случае разработки обоснования безопасности и проведения его экспертизы промышленной безопасности',
          'В случае обеспечения работников, выполняющих данные работы, необходимыми средствами индивидуальной защиты',
        ],
        correctAnswers: [
          'По решению руководителя организации, эксплуатирующей объект, при обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам',
        ],
      },
      {
        code: '63688172',
        text:
          'С какой периодичностью должна проводиться проверка параметров настройки предохранительных сбросных клапанов резервуаров и их регулировка на стенде?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 12 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 6 месяцев'],
      },
      {
        code: '63688173',
        text:
          'В каком случае насосы и компрессоры в насосно-компрессорном отделении (НКО) допускается эксплуатировать без остановки при ремонтных работах, а также во время производства газоопасных работ в производственной зоне?',
        answers: [
          'При обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам',
          'В случае проведения работ не более 30 минут',
          'В случае присутствия в НКО руководителя организации, эксплуатирующей объект',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При обеспечении дополнительных мер безопасности, исключающих угрозу жизни и здоровью работникам организации и третьим лицам',
        ],
      },
      {
        code: '63688174',
        text:
          'Куда организация, на объекте которой произошла авария, после окончания расследования обязана направить экземпляры материалов технического расследования причин аварии?',
        answers: [
          'Только в органы, определенные председателем комиссии',
          'Только в Ростехнадзор (иной федеральный орган исполнительной власти в области промышленной безопасности) или его территориальный орган',
          'Только в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию',
          'Во все перечисленные органы',
        ],
        correctAnswers: ['Во все перечисленные органы'],
      },
      {
        code: '63688175',
        text:
          'На каком минимальном расстоянии от ограждения должна быть установлена запорная арматура вне территории ГНС, ГНП при подаче СУГ на ГНС, ГНП по газопроводу?',
        answers: ['50 м', '100 м', '150 м', '200 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63688176',
        text:
          'Можно ли проводить приемку СУГ, сливо-наливные операции при проведении огневых работ?',
        answers: [
          'Можно, если есть специальное разрешение Пожнадзора и Ростехнадзора',
          'Можно, если есть письменное разрешение руководителя объекта, использующего СУГ',
          'Можно при соблюдении требований безопасности',
          'Не допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63688177',
        text:
          'Какие требования к окраске баллонов и нанесению надписей указаны неверно?',
        answers: [
          'Окраску баллонов и нанесение надписей при эксплуатации производят организации-изготовители, наполнительные станции (пункты наполнения) или испытательные пункты (пункты проверки) в соответствии с требованиями ТР ТС 032/2013',
          'Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации',
          'Стационарно установленные баллоны вместимостью более 100 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации',
          'Требования к окраске и надписям, не установленные ТР ТС 032/2013, устанавливаются проектной документацией и (или) техническими условиями на продукцию, для хранения которой предназначены эти баллоны, и указываются в распорядительных документах',
        ],
        correctAnswers: [
          'Баллоны вместимостью более 75 л допускается окрашивать в иные цвета с нанесением надписей и маркировки в соответствии с проектной документацией и руководством (инструкцией) по эксплуатации',
        ],
      },
      {
        code: '63688178',
        text:
          'Что включает в себя техническое обслуживание наполнительных установок?',
        answers: [
          'Только осмотр и очистку фильтров',
          'Только смазку трущихся частей и перенабивку сальников',
          'Только проверку плотности закрытия клапана струбцины',
          'Только проверку правильности настройки сбросного клапана',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63688179',
        text:
          'В каком случае технологическая система объекта, использующего СУГ, должна быть повторно испытана на герметичность?',
        answers: [
          'Если объект не был введен в эксплуатацию в течение 3 месяцев',
          'Если объект не был введен в эксплуатацию в течение 4 месяцев',
          'Если объект не был введен в эксплуатацию в течение 6 месяцев',
          'Повторные испытания на герметичность правилами не предусмотрены',
        ],
        correctAnswers: [
          'Если объект не был введен в эксплуатацию в течение 6 месяцев',
        ],
      },
      {
        code: '63688180',
        text:
          'Какие требования необходимо соблюдать при демонтаже насосов и испарителей для ремонта?',
        answers: [
          'Оборудование следует освободить от СУГ и пропарить',
          'Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью запорной арматуры и пропарить',
          'Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью заглушек и пропарить',
          'Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью заглушек и продуть инертным газом',
        ],
        correctAnswers: [
          'Оборудование следует освободить от СУГ, отключить от обвязывающих газопроводов с помощью заглушек и пропарить',
        ],
      },
      {
        code: '63688181',
        text:
          'Какое из требований должно выполняться при обработке резервуаров перед их внутренним осмотром или ремонтом?',
        answers: [
          'Резервуары должны быть отключены от газопроводов обвязки с помощью запорной арматуры. Установка заглушек необязательна',
          'Обработка резервуаров должна производиться путем их пропаривания с последующей продувкой воздухом. Промывка водой не допускается',
          'Обработка резервуаров должна производиться путем их пропаривания или промывки водой с последующей продувкой инертным газом. Применение для дегазации воздуха не допускается',
          'Качество дегазации должно проверяться путем анализа проб, отобранных из верхней части сосуда',
        ],
        correctAnswers: [
          'Обработка резервуаров должна производиться путем их пропаривания или промывки водой с последующей продувкой инертным газом. Применение для дегазации воздуха не допускается',
        ],
      },
      {
        code: '63688182',
        text:
          'Каково максимально допустимое значение давления при работающих предохранительных клапанах в сосуде с давлением менее 0,3 МПа?',
        answers: [
          'Превышающее разрешенное давление не более чем на 0,05 МПа',
          'Превышающее разрешенное давление не более чем на 10%',
          'Превышающее разрешенное давление не более чем на 15%',
          'Превышающее разрешенное давление не более чем на 25%',
        ],
        correctAnswers: [
          'Превышающее разрешенное давление не более чем на 0,05 МПа',
        ],
      },
      {
        code: '63688183',
        text:
          'С какой периодичностью следует наблюдать за осадкой фундаментов зданий, сооружений и оборудования в первые два года эксплуатации?',
        answers: [
          'Не реже 1 раза 3 месяца',
          'Не реже 1 раза 6 месяцев',
          'Не реже 1 раза 12 месяцев',
          'Не реже 1 раза 3 года',
        ],
        correctAnswers: ['Не реже 1 раза 3 месяца'],
      },
      {
        code: '63688184',
        text:
          'Кем утверждается график периодичности обхода трасс подземных газопроводов?',
        answers: [
          'Техническим руководителем (главным инженером)',
          'Инспектором территориального органа Ростехнадзора',
          'Лицом, ответственным за безопасную эксплуатацию объекта, использующего СУГ',
          'Руководителем объекта, использующего СУГ',
        ],
        correctAnswers: ['Техническим руководителем (главным инженером)'],
      },
      {
        code: '63688185',
        text:
          'Какая вентиляция должна действовать при выполнении огневых работ в помещении?',
        answers: [
          'Приточно-вытяжная вентиляция',
          'Приточная вентиляция',
          'Естественная вентиляция',
          'Вытяжная вентиляция',
        ],
        correctAnswers: ['Приточно-вытяжная вентиляция'],
      },
      {
        code: '63688186',
        text:
          'Каким должно быть давление газа на всасывающей линии насоса на объекте, использующем СУГ?',
        answers: [
          'На 0,5 - 0,6 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
          'На 0,4 - 0,6 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
          'На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
          'На 1,1 - 1,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
        ],
        correctAnswers: [
          'На 0,1 - 0,2 МПа выше упругости насыщенных паров жидкой фазы при данной температуре',
        ],
      },
      {
        code: '63688187',
        text: 'Куда передается оперативное сообщение об аварии или инциденте?',
        answers: [
          'Во все перечисленные организации',
          'Только в орган местного самоуправления',
          'Только в страховую организацию, с которой заключен договор обязательного страхования гражданской ответственности в соответствии с законодательством Российской Федерации об обязательном страховании гражданской ответственности владельца опасного производственного объекта за причинение вреда в результате аварии на опасном объекте',
          'Только в федеральный орган исполнительной власти, осуществляющий функции по контролю и надзору в области охраны окружающей среды (при авариях, связанных с выбросом опасных веществ)',
          'Только в территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), осуществляющий надзор за объектом, либо территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), на территории деятельности которого произошла авария (при эксплуатации передвижных технических устройств (кранов, подъемников (вышек), передвижных котельных, цистерн, вагонов, локомотивов, автомобилей)',
        ],
        correctAnswers: ['Во все перечисленные организации'],
      },
      {
        code: '63688188',
        text:
          'Кто дает разрешение на ввод резервуаров в эксплуатацию после их освидетельствования?',
        answers: [
          'Руководитель организации',
          'Представитель специализированной организации',
          'Технический руководитель (главный инженер) объекта, использующего СУГ',
          'Представитель Ростехнадзора',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) объекта, использующего СУГ',
        ],
      },
      {
        code: '63688189',
        text:
          'Какую шкалу должны иметь манометры, устанавливаемые на оборудовании и газопроводах?',
        answers: [
          'Шкалу, предел измерения рабочего давления которой находится в первой ее трети',
          'Шкалу, предел измерения рабочего давления которой находится во второй ее трети',
          'Шкалу, предел измерения рабочего давления которой находится в третьей ее трети',
        ],
        correctAnswers: [
          'Шкалу, предел измерения рабочего давления которой находится во второй ее трети',
        ],
      },
      {
        code: '63688190',
        text:
          'Какие данные должны быть на рукавах, применяемых для проведения сливоналивных операций?',
        answers: [
          'Обозначение с заводским номером, датами проведения (месяц, год) испытания и последующего испытания (месяц, год)',
          'Обозначение с порядковым номером, датой проведения (месяц, год) испытания и данными об организации-владельце',
          'Обозначение с заводским и порядковым номерами, датой проведения (месяц, год) испытания',
          'Обозначение с порядковым номером, датами проведения (месяц, год) испытания и последующего испытания (месяц, год)',
        ],
        correctAnswers: [
          'Обозначение с порядковым номером, датами проведения (месяц, год) испытания и последующего испытания (месяц, год)',
        ],
      },
      {
        code: '63688191',
        text:
          'На какие объекты должны составляться эксплуатационные формуляры (паспорта)?',
        answers: [
          'Только на каждый наружный газопровод',
          'Только на каждую электрозащитную установку',
          'Только на каждый резервуар СУГ',
          'На все перечисленные объекты',
        ],
        correctAnswers: ['На все перечисленные объекты'],
      },
      {
        code: '63688192',
        text:
          'Требования каких документов необходимо соблюдать при эксплуатации компрессоров, насосов и испарителей?',
        answers: [
          'Только требования эксплуатационной документации',
          'Только требования ФНП "Правила безопасности для объектов, использующих СУГ"',
          'Только требования производственных инструкций',
          'Требования всех перечисленных документов',
        ],
        correctAnswers: ['Требования всех перечисленных документов'],
      },
      {
        code: '63688193',
        text:
          'Допускается ли проведение газоопасных работ, выполняемых по нарядам-допускам, в темное время суток?',
        answers: [
          'Нет, не допускается. Данные работы должны выполняться только в светлое время суток',
          'Допускается проведение газоопасных работ, выполняемых по нарядам-допускам, в любое время суток',
          'Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ',
          'Допускается в исключительных случаях, если работы относятся к аварийному ремонту',
        ],
        correctAnswers: [
          'Допускается в исключительных случаях, если это неотложные работы, при условии выполнения дополнительных мероприятий по обеспечению безопасного проведения работ',
        ],
      },
      {
        code: '63688194',
        text:
          'С какой периодичностью проводятся контроль герметичности приборов, импульсных трубопроводов и арматуры одновременно с проверкой герметичности технических устройств?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже двух раз в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63688195',
        text:
          'При какой концентрации газа должны срабатывать газоанализаторы и сигнализаторы, установленные в помещении?',
        answers: [
          '5% нижнего предела распространения пламени',
          '10% нижнего предела распространения пламени',
          '15% нижнего предела распространения пламени',
          '20% нижнего предела распространения пламени',
        ],
        correctAnswers: ['10% нижнего предела распространения пламени'],
      },
      {
        code: '63688196',
        text:
          'Какой срок службы устанавливается для баллонов при отсутствии в технической документации сведений о сроке службы баллона, определенном при его проектировании?',
        answers: ['10 лет', '20 лет', '30 лет', '40 лет', '50 лет'],
        correctAnswers: ['20 лет'],
      },
    ],
    63689: [
      {
        code: '63689000',
        text:
          'Допускается ли замена прокладок фланцевых соединений на внутренних газопроводах под давлением газа?',
        answers: [
          'Нет, не допускается',
          'Да, при давлении не выше 0,1 МПа',
          'Да, при давлении 0,0004 - 0,002 МПа',
          'Да, при давлении не выше 0,005 МПа',
        ],
        correctAnswers: ['Нет, не допускается'],
      },
      {
        code: '63689001',
        text:
          'Каким образом могут производиться работы по предотвращению аварий или ликвидации их последствий на газопроводах эксплуатационной организацией газораспределительной сети?',
        answers: [
          'Без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах',
          'Без согласования с органами исполнительной власти субъектов Российской Федерации, но с уведомлением их о проводимых работах',
          'Без согласования с органами местного самоуправления, но с уведомлением их о проводимых работах',
          'Без согласования с кем-либо о проводимых работах',
        ],
        correctAnswers: [
          'Без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах',
        ],
      },
      {
        code: '63689002',
        text:
          'Кем утверждаются границы охранных зон газораспределительных сетей и наложение ограничений (обременений) на входящие в них земельные участки для проектируемых газораспределительных сетей?',
        answers: [
          'Органами исполнительной власти субъектов Российской Федерации по согласованию с собственниками, владельцами или пользователями земельных участков',
          'Органами местного самоуправления без согласования с собственниками, владельцами или пользователями земельных участков',
          'Собственниками, владельцами или пользователями земельных участков',
          'Органами исполнительной власти субъектов Российской Федерации без согласования с собственниками, владельцами или пользователями земельных участков',
        ],
        correctAnswers: [
          'Органами исполнительной власти субъектов Российской Федерации по согласованию с собственниками, владельцами или пользователями земельных участков',
        ],
      },
      {
        code: '63689003',
        text:
          'На каком расстоянии (в радиусе) от дверных и открывающихся оконных проемов следует размещать запорную арматуру на надземных газопроводах низкого давления, проложенных по стенам зданий и на опорах?',
        answers: [
          'Не менее 0,25 м',
          'Не менее 0,5 м',
          'Не менее 1 м',
          'Не менее 3 м',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 0,5 м'],
      },
      {
        code: '63689004',
        text:
          'Где не допускается устанавливать запорную арматуру на надземных газопроводах?',
        answers: [
          'На участках транзитной прокладки по стенам жилых зданий',
          'На участках прокладки по стенам с открывающимися оконными проемами',
          'На участках транзитной прокладки по стенам зданий любого назначения',
        ],
        correctAnswers: [
          'На участках транзитной прокладки по стенам зданий любого назначения',
        ],
      },
      {
        code: '63689005',
        text:
          'Какой из перечисленных терминов относится к газопроводу от места присоединения к распределительному газопроводу до отключающего устройства или наружной конструкции здания либо сооружения потребителя газа согласно Правилам охраны газораспределительных сетей?',
        answers: [
          'Газопровод-ввод',
          'Трасса газопровода',
          'Распределительный газопровод',
          'Газорегуляторный пункт',
        ],
        correctAnswers: ['Газопровод-ввод'],
      },
      {
        code: '63689006',
        text:
          'Каким образом проводят входной контроль при поступлении партии труб или соединительных деталей в строительную организацию? Выберите два варианта ответа.',
        answers: [
          'Проводят дефектоскопию неразрушающими методами контроля',
          'Испытывают выборочные образцы каждой марки (модели) из партии на прочность методом изгиба',
          'Проводят внешний осмотр',
          'Проводят измерение основных геометрических параметров изделий на соответствие нормативной документации',
          'Всеми перечисленными методами',
        ],
        correctAnswers: [
          'Проводят внешний осмотр',
          'Проводят измерение основных геометрических параметров изделий на соответствие нормативной документации',
        ],
      },
      {
        code: '63689007',
        text:
          'Каким должно быть давление природного газа на входе в газорегуляторную установку?',
        answers: [
          'Не должно превышать 1,2 МПа',
          'Не должно превышать 0,3 МПа',
          'Не должно превышать 1,0 МПа',
          'Не должно превышать 0,6 МПа',
        ],
        correctAnswers: ['Не должно превышать 0,6 МПа'],
      },
      {
        code: '63689008',
        text:
          'Какое требование по размещению ГРП противоречит СП 62.13330.2011. Свод правил. Газораспределительные системы?',
        answers: [
          'ГРП размещают встроенными в двухэтажные газифицируемые производственные здания и котельные',
          'ГРП размещают отдельно стоящими',
          'ГРП размещают пристроенными к газифицируемым производственным зданиям, котельным и общественным зданиям с помещениями производственного назначения',
          'ГРП размещают под навесом на открытых огражденных площадках на территории промышленных организаций при размещении оборудования ПРГ вне зданий',
        ],
        correctAnswers: [
          'ГРП размещают встроенными в двухэтажные газифицируемые производственные здания и котельные',
        ],
      },
      {
        code: '63689009',
        text:
          'К какой категории относятся газопроводы с давлением газа свыше 0,005 до 0,3 МПа включительно?',
        answers: [
          'Высокого давления 1 категории',
          'Высокого давления 1а категории',
          'Среднего давления',
          'Низкого давления',
        ],
        correctAnswers: ['Среднего давления'],
      },
      {
        code: '63689010',
        text:
          'Что должно быть предусмотрено проектом газорегуляторного пункта в целях обеспечения взрывоустойчивости помещения для размещения линии редуцирования?',
        answers: [
          'Применение усиленных железобетонных строительных конструкций с минимальной площадью оконных проемов',
          'Выполнение стен помещения для размещения линий редуцирования из трудновоспламеняемых строительных материалов группы В1',
          'Легкосбрасываемые конструкции, площадь которых должна быть не менее 0,05 м² на 1 м³ свободного объема помещения',
        ],
        correctAnswers: [
          'Легкосбрасываемые конструкции, площадь которых должна быть не менее 0,05 м² на 1 м³ свободного объема помещения',
        ],
      },
      {
        code: '63689011',
        text:
          'Какие требования предъявляются Техническим регламентом о безопасности сетей газораспределения и газопотребления к конструкциям проектируемых зданий газорегуляторных пунктов, газорегуляторных пунктов блочных и пунктов учета газа?',
        answers: [
          'Конструкции должны обеспечить взрывоустойчивость зданий',
          'Конструкции должны обеспечить энергетическую эффективность зданий',
          'Конструкции должны обеспечить удобство обслуживания и ремонта технологических устройств',
        ],
        correctAnswers: [
          'Конструкции должны обеспечить взрывоустойчивость зданий',
        ],
      },
      {
        code: '63689012',
        text:
          'Какие испытания проводятся для определения механических свойств стыков стальных труб с условным проходом до 50 мм включительно?',
        answers: [
          'На изгиб и сплющивание',
          'Только на растяжение',
          'На статическое растяжение и сплющивание',
          'На статическое растяжение и статический изгиб',
        ],
        correctAnswers: ['На статическое растяжение и сплющивание'],
      },
      {
        code: '63689013',
        text:
          'В каких случаях испытания участков переходов через искусственные и естественные преграды допускается проводить в одну стадию вместе с основным газопроводом?',
        answers: [
          'Во всех перечисленных случаях',
          'В случаях отсутствия сварных соединений в пределах перехода',
          'В случаях использования метода наклонно-направленного бурения',
          'В случаях использования в пределах перехода для сварки полиэтиленовых труб деталей с ЗН или сварочного оборудования со средней и высокой степенью автоматизации',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63689014',
        text:
          'Каким освещением следует оборудовать помещения насосно-компрессорного, наполнительного, испарительного и окрасочного отделений ГНС и ГНП?',
        answers: [
          'Только аккумуляторными фонарями напряжением не выше 12 В во взрывозащищенном исполнении',
          'Только рабочим освещением',
          'Всеми перечисленными',
          'Только дополнительным аварийным освещением',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63689015',
        text:
          'По каким существенным признакам сети газораспределения и газопотребления идентифицируются в качестве объекта технического регулирования Технического регламента о безопасности сетей газораспределения и газопотребления?',
        answers: [
          'Только по назначению',
          'Только по составу объектов, входящих в сети газораспределения и газопотребления',
          'Только по давлению газа, определенному в техническом регламенте',
          'По всем указанным признакам, рассматриваемым исключительно в совокупности',
        ],
        correctAnswers: [
          'По всем указанным признакам, рассматриваемым исключительно в совокупности',
        ],
      },
      {
        code: '63689016',
        text:
          'Каким должно быть минимальное расстояние по горизонтали (в свету) от водопроводов и напорной канализации до фундаментов зданий и сооружений?',
        answers: ['4 м', '5 м', '7 м', '3 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63689017',
        text:
          'Какой документ устанавливает предельные сроки эксплуатации газопроводов, зданий и сооружений, технических и технологических устройств, по истечении которых должно быть обеспечено их техническое диагностирование?',
        answers: [
          'Технический регламент о безопасности сетей газораспределения и газопотребления',
          'Проектная документация',
          'ФНП "Правила безопасности сетей газораспределения и газопотребления"',
          'Эксплуатационная документация',
        ],
        correctAnswers: ['Проектная документация'],
      },
      {
        code: '63689018',
        text:
          'В каком случае разрешается вмешательство в деятельность, связанную с обеспечением безопасной эксплуатации газораспределительных сетей, не уполномоченных на то органов исполнительной власти субъектов Российской Федерации, органов местного самоуправления, общественных организаций, юридических и физических лиц?',
        answers: [
          'В случае возникновения аварийных ситуаций',
          'В случае устранения утечек газа',
          'Все ответы неверны',
          'В случае необходимости проведения ремонтных работ для предотвращения разрушения газораспределительной сети',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63689019',
        text:
          'К какой категории относятся газопроводы с давлением газа свыше 0,6 до 1,2 МПа включительно?',
        answers: [
          'Высокого давления 1 категории',
          'Высокого давления 2 категории',
          'Среднего давления',
          'Высокого давления 1а категории',
        ],
        correctAnswers: ['Высокого давления 1 категории'],
      },
      {
        code: '63689020',
        text:
          'На какое расстояние должны выводиться концы футляров в местах пересечения газопроводов с подземными коммуникационными коллекторами и каналами различного назначения (за исключением пересечений стенок газовых колодцев)?',
        answers: [
          'Не менее 1 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций',
          'Не менее 2 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций',
          'Не менее 1,3 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций',
          'Не менее 0,8 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций',
        ],
        correctAnswers: [
          'Не менее 2 м в обе стороны от наружных стенок пересекаемых сооружений и коммуникаций',
        ],
      },
      {
        code: '63689021',
        text:
          'Какой способ монтажа газопровода рекомендуется применять (на прямых участках) при прокладке газопроводов в узкой строительной полосе?',
        answers: [
          'Методом протягивания',
          'Методом прокола',
          'Методом продавливания',
          'Методом наклонно-направленного бурения',
        ],
        correctAnswers: ['Методом протягивания'],
      },
      {
        code: '63689022',
        text:
          'В каком случае рекомендуется укомплектовывать газоиспользующее оборудование производственных зданий автоматикой безопасности, обеспечивающей прекращение подачи газа?',
        answers: [
          'Во всех перечисленных случаях',
          'Только в случае погасания пламени горелок',
          'Только в случае понижения давления воздуха',
          'Только в случае уменьшения разрежения в топке',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63689023',
        text:
          'Что из перечисленного должны обеспечивать количество, места размещения и вид запорной трубопроводной арматуры на внутренних газопроводах?',
        answers: [
          'Должны обеспечивать все перечисленное',
          'Только возможность отключения газоиспользующего оборудования для его ремонта или замены',
          'Только возможность отключения участков сети газопотребления для проведения ремонта газоиспользующего оборудования и технических устройств',
          'Только возможность отключения участка газопровода для демонтажа и последующей установки технических устройств при необходимости их ремонта или поверки',
        ],
        correctAnswers: ['Должны обеспечивать все перечисленное'],
      },
      {
        code: '63689024',
        text:
          'Каким образом следует проводить продувку полости внутренних газопроводов и газопроводов ПРГ перед их монтажом?',
        answers: ['Воздухом', 'Паром', 'Азотом', 'Гелием'],
        correctAnswers: ['Воздухом'],
      },
      {
        code: '63689025',
        text:
          'Какое проектное решение должно предусматриваться в случае пересечения полиэтиленовых газопроводов с нефтепроводами и теплотрассами?',
        answers: [
          'Запрещается проектирование пересечения полиэтиленовых газопроводов с нефтепроводами и теплотрассами',
          'Специальные проектные решения в этом случае не применяются',
          'Применение защитных покрытий или устройств, стойких к внешним воздействиям и обеспечивающих сохранность газопровода',
        ],
        correctAnswers: [
          'Применение защитных покрытий или устройств, стойких к внешним воздействиям и обеспечивающих сохранность газопровода',
        ],
      },
      {
        code: '63689026',
        text:
          'Чем должны оснащаться предохранительные сбросные клапаны технологических устройств?',
        answers: [
          'Сбросными газопроводами',
          'Последовательно установленными обратным клапаном и задвижкой',
          'Звуковой и световой сигнализацией',
        ],
        correctAnswers: ['Сбросными газопроводами'],
      },
      {
        code: '63689027',
        text:
          'На какой минимальной высоте от поверхности пола допускается размещать края футляров газопроводов?',
        answers: ['50 мм', '60 мм', '70 мм', '80 мм'],
        correctAnswers: ['50 мм'],
      },
      {
        code: '63689028',
        text:
          'С каким уклоном в сторону сливного патрубка устанавливают надземные резервуары для хранения СУГ?',
        answers: ['2 - 3%', '0,5 - 1%', '4 - 5%', '6 - 7%'],
        correctAnswers: ['2 - 3%'],
      },
      {
        code: '63689029',
        text:
          'На какие сети, а также на связанные с ними процессы проектирования, строительства, реконструкции, монтажа, эксплуатации (включая техническое обслуживание, текущий ремонт), капитального ремонта, консервации и ликвидации требования Технического регламента о безопасности сетей газораспределения и газопотребления не распространяются?',
        answers: [
          'На сети газораспределения и газопотребления общественных и бытовых зданий',
          'На сети газораспределения жилых зданий',
          'На сети газопотребления жилых зданий',
          'На сети газопотребления парогазовых и газотурбинных установок давлением свыше 1,2 МПа',
        ],
        correctAnswers: ['На сети газопотребления жилых зданий'],
      },
      {
        code: '63689030',
        text: 'В каких местах допускается размещать надземные коммуникации?',
        answers: [
          'Газопроводы горючих газов размещаются на территории складов легковоспламеняющихся и горючих жидкостей и материалов',
          'Трубопроводы с горючими жидкими и газообразными продуктами размещаются в галереях, если смешение продуктов может вызвать взрыв или пожар',
          'Все ответы неверны',
          'Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами размещаются по эстакадам, отдельно стоящим колоннам и опорам из горючих материалов',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63689031',
        text:
          'Для каких грунтов глубина прокладки газопровода до верха трубы должна быть не менее 0,7 м расчетной глубины промерзания, но не менее 0,9 м?',
        answers: [
          'Для грунтов неодинаковой степени пучинистости',
          'Для среднепучинистых грунтов при равномерной пучинистости грунтов',
          'Для чрезмерно пучинистых грунтов при равномерной пучинистости грунтов',
          'Для сильнопучинистых грунтов при равномерной пучинистости грунтов',
        ],
        correctAnswers: [
          'Для среднепучинистых грунтов при равномерной пучинистости грунтов',
        ],
      },
      {
        code: '63689032',
        text: 'Какое из перечисленных требований указано верно?',
        answers: [
          'Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой не менее 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 500 м друг от друга',
          'Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой до 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 1000 м друг от друга, а также в местах пересечений газопроводов с железными и автомобильными дорогами, на поворотах и у каждого сооружения газопровода (колодцев, коверов, конденсатосборников, устройств электрохимической защиты и др.)',
          'Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой до 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 500 м друг от друга, а также в местах пересечений газопроводов с железными и автомобильными дорогами, на поворотах и у каждого сооружения газопровода (колодцев, коверов, конденсатосборников, устройств электрохимической защиты и др.)',
        ],
        correctAnswers: [
          'Трассы подземных газопроводов обозначаются опознавательными знаками, нанесенными на постоянные ориентиры или железобетонные столбики высотой до 1,5 м (вне городских и сельских поселений), которые устанавливаются в пределах прямой видимости не реже чем через 500 м друг от друга, а также в местах пересечений газопроводов с железными и автомобильными дорогами, на поворотах и у каждого сооружения газопровода (колодцев, коверов, конденсатосборников, устройств электрохимической защиты и др.)',
        ],
      },
      {
        code: '63689033',
        text:
          'На какие организации требования ФНП "Правила безопасности сетей газораспределения и газопотребления" не распространяются?',
        answers: [
          'Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления',
          'Осуществляющие деятельность по эксплуатации, консервации и ликвидации сетей газораспределения и газопотребления',
          'Осуществляющие деятельность по техническому перевооружению и ремонту сетей газораспределения и газопотребления',
        ],
        correctAnswers: [
          'Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления',
        ],
      },
      {
        code: '63689034',
        text:
          'Какие максимальные размеры земельных участков газонаполнительных пунктов и промежуточных складов баллонов следует принимать по проекту?',
        answers: ['0,7 га', '0,4 га', '0,6 га', '0,8 га'],
        correctAnswers: ['0,6 га'],
      },
      {
        code: '63689035',
        text:
          'В каких местах допускается размещение промышленных объектов и их групп?',
        answers: [
          'В зонах возможного затопления на глубину от 0,5 до 1,2 м',
          'В опасных зонах отвалов породы угольных и сланцевых шахт или обогатительных фабрик',
          'В зонах возможного катастрофического затопления в результате разрушения плотин или дамб',
          'В зеленых зонах городов',
        ],
        correctAnswers: [
          'В зонах возможного затопления на глубину от 0,5 до 1,2 м',
        ],
      },
      {
        code: '63689036',
        text:
          'Какие требования установлены к участкам газопроводов, прокладываемых в каналах со съемными перекрытиями и в бороздах стен?',
        answers: [
          'Они могут иметь сварные стыки, но не должны иметь фланцевые и резьбовые соединения',
          'Они могут иметь сварные стыки, фланцевые и резьбовые соединения',
          'Они не должны иметь сварные стыки, фланцевые и резьбовые соединения',
        ],
        correctAnswers: [
          'Они могут иметь сварные стыки, но не должны иметь фланцевые и резьбовые соединения',
        ],
      },
      {
        code: '63689037',
        text:
          'Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком на подземных газопроводах давлением до 0,1 МПа, прокладываемых на расстоянии от фундаментов зданий менее 2 м, подлежит контролю физическими методами?',
        answers: [
          '100%',
          '50% от общего числа стыков, но не менее одного стыка',
          '25% от общего числа стыков, но не менее одного стыка',
          '10% от общего числа стыков, но не менее одного стыка',
        ],
        correctAnswers: ['100%'],
      },
      {
        code: '63689038',
        text:
          'Объемная доля кислорода в газопроводе после окончания продувки не должна превышать:',
        answers: [
          '3% по объему',
          '1% по объему',
          '5% по объему',
          '2% по объему',
        ],
        correctAnswers: ['1% по объему'],
      },
      {
        code: '63689039',
        text:
          'В какой форме осуществляется оценка соответствия сетей газораспределения и газопотребления требованиям Технического регламента о безопасности сетей газораспределения и газопотребления при проектировании?',
        answers: [
          'В форме государственного контроля (надзора)',
          'В форме строительного контроля',
          'В форме подтверждения соответствия',
          'В форме экспертизы проектной документации и результатов инженерных изысканий в соответствии с законодательством о градостроительной деятельности',
        ],
        correctAnswers: [
          'В форме экспертизы проектной документации и результатов инженерных изысканий в соответствии с законодательством о градостроительной деятельности',
        ],
      },
      {
        code: '63689040',
        text:
          'При каком максимальном входном давлении газа следует устанавливать ГРУ?',
        answers: [
          '0,3 МПа',
          '0,6 МПа',
          'Определяется главным инженером эксплуатирующей организации',
          '1,2 МПа',
        ],
        correctAnswers: ['0,6 МПа'],
      },
      {
        code: '63689041',
        text:
          'В каком случае не допускается размещать газорегуляторные пункты шкафные на наружных стенах газифицируемых зданий?',
        answers: [
          'Если входное давление превышает 0,3 МПа',
          'Если входное давление превышает 0,6 МПа',
          'Ни в каком случае, все газорегуляторные пункты шкафные должны размещаться на отдельно стоящих опорах',
        ],
        correctAnswers: ['Если входное давление превышает 0,6 МПа'],
      },
      {
        code: '63689042',
        text:
          'Какие требования к размещению надземных коммуникаций указаны верно?',
        answers: [
          'Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов',
          'Трубопроводы с легковоспламеняющимися и горючими жидкостями и газами должны размещаться по покрытиям и стенам зданий категорий А и Б по взрывопожароопасности',
          'Не допускается размещать надземные коммуникации на опорах, эстакадах, в галереях или на стенах зданий и сооружений',
          'Транзитные наружные трубопроводы с легковоспламеняющимися и горючими жидкостями и газами не допускается размещать по стенам и кровлям зданий I, II, IIIа степеней огнестойкости с производствами категорий В, Г и Д',
        ],
        correctAnswers: [
          'Не допускается размещение газопроводов горючих газов по территории складов легковоспламеняющихся и горючих жидкостей и материалов',
        ],
      },
      {
        code: '63689043',
        text:
          'В каком месте футляра предусматривается контрольная трубка, выходящая под защитное устройство?',
        answers: [
          'На одном конце в нижней точке уклона',
          'На обоих концах',
          'На одном конце в верхней точке уклона',
          'В любом месте',
        ],
        correctAnswers: ['На одном конце в верхней точке уклона'],
      },
      {
        code: '63689044',
        text:
          'В каких помещениях должна размещаться ГРУ? Укажите все правильные ответы.',
        answers: [
          'В помещениях категорий В1 - В4, если расположенное в них газоиспользующее оборудование вмонтировано в технологические агрегаты производства',
          'В складских помещениях категорий В1 - В3',
          'В помещениях категорий Г и Д, в которых расположено газоиспользующее оборудование',
          'В помещениях категорий А и Б',
        ],
        correctAnswers: [
          'В помещениях категорий В1 - В4, если расположенное в них газоиспользующее оборудование вмонтировано в технологические агрегаты производства',
          'В помещениях категорий Г и Д, в которых расположено газоиспользующее оборудование',
        ],
      },
      {
        code: '63689045',
        text:
          'Каким образом выполняют очистку полости полиэтиленовых газопроводов? Выберите два варианта ответа.',
        answers: [
          'Продувкой воздухом скоростным потоком (15 - 20 м/с) воздуха под давлением',
          'При помощи пропуска очистных поршней из эластичных материалов',
          'Промывкой водой под давлением 1,25 рабочего',
          'Промывкой химическими средствами под давлением, равным рабочему',
          'Пропуском скребкового снаряда с металлическими лопастями',
        ],
        correctAnswers: [
          'Продувкой воздухом скоростным потоком (15 - 20 м/с) воздуха под давлением',
          'При помощи пропуска очистных поршней из эластичных материалов',
        ],
      },
      {
        code: '63689046',
        text:
          'Какое количество человек должно находиться снаружи с наветренной стороны для страховки на каждого работающего в колодцах и котлованах и недопущения к месту работы посторонних лиц при проведении газоопасных работ?',
        answers: [
          'По одному человеку',
          'По два человека',
          'По три человека',
          'Не регламентируется',
        ],
        correctAnswers: ['По два человека'],
      },
      {
        code: '63689047',
        text:
          'Какая норма испытаний на герметичность установлена для полиэтиленовых газопроводов с давлением до 0,1 МПа включительно?',
        answers: [
          '0,3 МПа, продолжительность испытаний - 12 часов',
          '0,3 МПа, продолжительность испытаний - 24 часа',
          '0,6 МПа, продолжительность испытаний - 24 часа',
          '0,45 МПа, продолжительность испытаний - 1 час',
        ],
        correctAnswers: ['0,3 МПа, продолжительность испытаний - 24 часа'],
      },
      {
        code: '63689048',
        text:
          'Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных стальных газопроводов?',
        answers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 1 м с каждой стороны газопровода',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м с каждой стороны газопровода',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода с каждой стороны газопровода',
        ],
        correctAnswers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода с каждой стороны газопровода',
        ],
      },
      {
        code: '63689049',
        text:
          'При каком условии производятся работы, связанные с обработкой почвы на глубину менее 0,3 м, собственниками, владельцами или пользователями земельных участков в охранной зоне газораспределительной сети?',
        answers: [
          'При наличии распоряжения эксплуатационной организации газораспределительных сетей',
          'При наличии письменного разрешения Ростехнадзора',
          'При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ',
          'При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 5 рабочих дней до начала работ',
        ],
        correctAnswers: [
          'При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ',
        ],
      },
      {
        code: '63689050',
        text:
          'В каких местах контролируют толщину битумно-мастичных покрытий на резервуарах приборным методом неразрушающего контроля с применением толщиномеров и других измерительных приборов?',
        answers: [
          'В одной точке на каждом квадратном метре поверхности, а в местах перегибов изоляционных покрытий через 1 м по длине окружности',
          'В пяти точках на 10% поверхности резервуара',
          'На каждом десятом резервуаре одной партии не менее чем в 4 точках по окружности',
          'Любым из перечисленных способов',
        ],
        correctAnswers: [
          'В одной точке на каждом квадратном метре поверхности, а в местах перегибов изоляционных покрытий через 1 м по длине окружности',
        ],
      },
      {
        code: '63689051',
        text:
          'При какой объемной доле газа в воздухе выполнение газоопасных работ в помещениях ПРГ, колодцах, туннелях, коллекторах не допускается?',
        answers: [
          'Более 10% от НКПРП',
          'Более 20% от НКПРП',
          'Более 15% от НКПРП',
          'Более 5% от НКПРП',
        ],
        correctAnswers: ['Более 20% от НКПРП'],
      },
      {
        code: '63689052',
        text:
          'Что из перечисленного не относится к материалам идентификации объектов технического регулирования?',
        answers: [
          'Разрешение на ввод в эксплуатацию',
          'Сведения о сетях газораспределения и газопотребления, содержащиеся в Едином государственном реестре недвижимости',
          'Разрешение на строительство',
          'Заключение экспертизы декларации промышленной безопасности',
        ],
        correctAnswers: [
          'Заключение экспертизы декларации промышленной безопасности',
        ],
      },
      {
        code: '63689053',
        text:
          'Какие функционально-технологические зоны следует выделять при разработке планировочной организации земельных участков производственных объектов?',
        answers: [
          'Только подсобную',
          'Только складскую',
          'Только производственную, включая зоны исследовательского назначения и опытных производств',
          'Только входную',
          'Следует выделять все перечисленные зоны',
        ],
        correctAnswers: ['Следует выделять все перечисленные зоны'],
      },
      {
        code: '63689054',
        text:
          'На каких из перечисленных территорий не допускается размещать производственные объекты?',
        answers: [
          'Только в первом поясе зоны санитарной охраны подземных и наземных источников водоснабжения',
          'Только в опасных зонах отвалов породы угольных и сланцевых шахт или обогатительных фабрик',
          'Только в зонах возможного катастрофического затопления в результате разрушения плотин или дамб',
          'На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов',
        ],
        correctAnswers: [
          'На всех перечисленных, а также в районах развития опасных геологических и инженерно-геологических процессов',
        ],
      },
      {
        code: '63689055',
        text: 'В каких местах следует размещать надземные коммуникации?',
        answers: [
          'Только в галереях',
          'Только на опорах',
          'Только на стенах зданий и сооружений',
          'Только на эстакадах',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63689056',
        text:
          'Какое минимальное расстояние устанавливается от прогнозируемых границ развития оползней, обвалов горных пород и склонов, эрозионных и иных негативных явлений до опор газопровода?',
        answers: ['10 м', '2 м', '5 м', '15 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63689057',
        text:
          'Каким должно быть расстояние по горизонтали (в свету) от отдельно стоящего пункта редуцирования газа до воздушных линий электропередачи при давлении газа на вводе до 0,6 МПа?',
        answers: [
          'Не менее 0,5 высоты опоры',
          'Не менее 1 высоты опоры',
          'Не менее 1,2 высоты опоры',
          'Не менее 1,5 высоты опоры',
        ],
        correctAnswers: ['Не менее 1,5 высоты опоры'],
      },
      {
        code: '63689058',
        text:
          'Какие сведения наносятся на опознавательный знак для определения местонахождения газопровода на углах поворота трассы, местах изменения диаметра, установки арматуры и сооружений, принадлежащих газопроводу, а также на прямолинейных участках трассы (через 200 - 500 м)? Выберите два варианта ответа.',
        answers: [
          'Сведения о диаметре, давлении, глубине заложения газопровода',
          'Сведения о материале труб, расстоянии до газопровода, сооружения или характерной точки и другие сведения',
          'Сведения о протяженности трубопровода, дате очередного технического диагностирования',
          'Сведения об эксплуатирующей организации, монтажной организации и сроке службы',
        ],
        correctAnswers: [
          'Сведения о диаметре, давлении, глубине заложения газопровода',
          'Сведения о материале труб, расстоянии до газопровода, сооружения или характерной точки и другие сведения',
        ],
      },
      {
        code: '63689059',
        text:
          'За счет чего, в соответствии с требованиями Технического регламента о безопасности сетей газораспределения и газопотребления, обеспечивается энергетическая эффективность построенных, отремонтированных, реконструированных сетей газораспределения и газопотребления?',
        answers: [
          'За счет оснащения помещений с газоиспользующим оборудованием счетчиком расхода газа',
          'За счет их герметичности (отсутствия утечек газа)',
          'За счет бесперебойной транспортировки газа с заданными параметрами по расходу и давлению',
        ],
        correctAnswers: ['За счет их герметичности (отсутствия утечек газа)'],
      },
      {
        code: '63689060',
        text:
          'Что устанавливают на газопроводах для снижения напряжений, возникающих в газопроводе в результате температурных, грунтовых и т.п. воздействий, а также удобства монтажа и демонтажа арматуры?',
        answers: ['Компенсаторы', 'Сальники', 'Эстакады', 'Скользящие опоры'],
        correctAnswers: ['Компенсаторы'],
      },
      {
        code: '63689061',
        text:
          'Какими методами определяют качество изоляционных покрытий, нанесенных на стальные трубы?',
        answers: [
          'Внешним осмотром, измерением толщины, проверкой сплошности и адгезии к металлу',
          'Только измерением толщины, проверкой сплошности и адгезии к металлу',
          'Только внешним осмотром и измерением толщины',
          'Только внешним осмотром и проверкой сплошности',
        ],
        correctAnswers: [
          'Внешним осмотром, измерением толщины, проверкой сплошности и адгезии к металлу',
        ],
      },
      {
        code: '63689062',
        text:
          'Какие требования к испарителям и теплообменникам для подогрева СУГ (далее - испарительные установки) указаны неверно?',
        answers: [
          'Испарительные установки, размещаемые вне помещений, располагают на расстоянии не менее 10 м от резервуаров для хранения СУГ',
          'Испарительные установки, размещаемые вне помещений, располагают на расстоянии не менее 1 м от стен здания насосно-компрессорного отделения или наполнительного цеха',
          'Испарительные установки производительностью до 200 кг/ч допускается размещать в насосно-компрессорном отделении или непосредственно на крышках горловин (на штуцерах) подземных и надземных резервуаров, а также в пределах базы хранения на расстоянии не менее 1 м от резервуаров',
          'Расстояние между испарителями принимают не менее двух диаметров испарителя, но не менее 10 м',
        ],
        correctAnswers: [
          'Расстояние между испарителями принимают не менее двух диаметров испарителя, но не менее 10 м',
        ],
      },
      {
        code: '63689063',
        text:
          'Какая норма испытаний на герметичность установлена для подземных стальных газопроводов с давлением до 0,1 МПа включительно?',
        answers: [
          '0,3 МПа, продолжительность испытаний - 12 часов',
          '0,3 МПа, продолжительность испытаний - 24 часа',
          '0,6 МПа, продолжительность испытаний - 24 часа',
          '0,45 МПа, продолжительность испытаний - 1 час',
        ],
        correctAnswers: ['0,6 МПа, продолжительность испытаний - 24 часа'],
      },
      {
        code: '63689064',
        text:
          'Каким должно быть расстояние по горизонтали (в свету) от отдельно стоящего пункта редуцирования газа до зданий и сооружений при давлении газа на вводе до 0,6 МПа?',
        answers: [
          'Не менее 5 м',
          'Не менее 10 м',
          'Не менее 15 м',
          'Расстояние не нормируется',
        ],
        correctAnswers: ['Не менее 10 м'],
      },
      {
        code: '63689065',
        text:
          'Какому из перечисленных требований должна соответствовать проектная документация на сети газораспределения?',
        answers: [
          'Проектная документация должна соответствовать требованиям законодательства о градостроительной деятельности',
          'Проектная документация должна соответствовать требованиям законодательства о промышленной безопасности',
          'Проектная документация должна соответствовать требованиям законодательства о техническом регулировании',
        ],
        correctAnswers: [
          'Проектная документация должна соответствовать требованиям законодательства о градостроительной деятельности',
        ],
      },
      {
        code: '63689066',
        text:
          'В каком случае могут применяться газопроводы из стальных труб и их соединительные детали для наружной и внутренней прокладки для СУГ?',
        answers: [
          'При давлении до 1,6 МПа',
          'При давлении выше 1,6 МПа',
          'Ни в каком случае, применяются только для природного газа',
          'При любом давлении газа',
        ],
        correctAnswers: ['При давлении до 1,6 МПа'],
      },
      {
        code: '63689067',
        text:
          'При каком давлении газа в газопроводе разрешается устранение в газопроводах закупорок путем шуровки металлическими шомполами, заливки растворителей или подачи пара?',
        answers: [
          'Не более 0,01 МПа',
          'Не более 300 МПа',
          'Не более 0,005 МПа',
          'Не более 0,1 МПа',
        ],
        correctAnswers: ['Не более 0,005 МПа'],
      },
      {
        code: '63689068',
        text:
          'Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории поселений?',
        answers: ['2,5 МПа', '1,2 МПа', '0,6 МПа', '0,005 МПа'],
        correctAnswers: ['0,6 МПа'],
      },
      {
        code: '63689069',
        text:
          'За счет чего обеспечивается прочность и устойчивость газопроводов, проектируемых для прокладки на подрабатываемых территориях? Выберите два варианта ответа.',
        answers: [
          'Увеличения подвижности газопровода в грунте',
          'Снижения воздействия деформирующегося грунта на газопровод',
          'Уменьшения подвижности газопровода в грунте',
          'Прямолинейной укладки газопровода по дну траншеи',
        ],
        correctAnswers: [
          'Увеличения подвижности газопровода в грунте',
          'Снижения воздействия деформирующегося грунта на газопровод',
        ],
      },
      {
        code: '63689070',
        text:
          'Каким образом следует определять число баллонов в групповой баллонной установке СУГ?',
        answers: [
          'Расчетом',
          'На основании заключения специализированной сторонней организации',
          'На основании разрешения территориального органа Ростехнадзора',
          'На основании проектной документации',
        ],
        correctAnswers: ['Расчетом'],
      },
      {
        code: '63689071',
        text:
          'Какие меры необходимо предпринять во время выполнения монтажных работ на газопроводе при обнаружении действующих подземных коммуникаций и других сооружений, не обозначенных в имеющейся проектной документации?',
        answers: [
          'Приостановить земляные работы, на место работы вызвать представителей организаций, эксплуатирующих эти сооружения',
          'Оградить указанные места от повреждений и продолжить выполнение работ',
          'Предпринимаемые меры определяет производитель работ в зависимости от обнаруженных коммуникаций',
          'Обратиться в территориальный орган Ростехнадзора для согласования дальнейших действий',
        ],
        correctAnswers: [
          'Приостановить земляные работы, на место работы вызвать представителей организаций, эксплуатирующих эти сооружения',
        ],
      },
      {
        code: '63689072',
        text:
          'Какое из перечисленных требований при проведении контроля физическими методами сварных стыков указано верно?',
        answers: [
          'При получении неудовлетворительных результатов радиографического контроля хотя бы на одном стыке объем контроля следует увеличить до 50% общего числа стыков',
          'В случае повторного выявления дефектных стыков все стыки, сваренные конкретным сварщиком на объекте в течение рабочей недели и проверенные ультразвуковым методом, должны быть подвергнуты радиографическому контролю',
          'При получении неудовлетворительных результатов радиографического контроля хотя бы на одном стыке объем контроля следует увеличить до 30% общего числа стыков',
          'Ультразвуковой метод контроля сварных стыков стальных газопроводов должен применяться при условии проведения выборочной проверки не менее 5% стыков радиографическим методом',
        ],
        correctAnswers: [
          'При получении неудовлетворительных результатов радиографического контроля хотя бы на одном стыке объем контроля следует увеличить до 50% общего числа стыков',
        ],
      },
      {
        code: '63689073',
        text:
          'Какие испытания проводятся для определения механических свойств стыков стальных труб с условным проходом свыше 50 мм?',
        answers: [
          'На ударный изгиб',
          'На статическое растяжение и сплющивание',
          'На статическое растяжение и статический изгиб',
          'На растяжение, изгиб и сплющивание',
        ],
        correctAnswers: ['На статическое растяжение и статический изгиб'],
      },
      {
        code: '63689074',
        text:
          'Допускается ли проведение разборки фланцевых, резьбовых соединений и арматуры на внутренних газопроводах без их отключения?',
        answers: [
          'Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода',
          'Допускается разборка фланцевых и резьбовых соединений и арматуры на отключенном участке внутреннего газопровода без установки заглушек',
          'Допускается только для газопроводов низкого давления',
          'Допускается при снижении давления газа во внутреннем газопроводе до величины 0,0004 МПа',
        ],
        correctAnswers: [
          'Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода',
        ],
      },
      {
        code: '63689075',
        text:
          'Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, пристроенных к жилым зданиям, крышным котельным жилых зданий?',
        answers: ['0,6 МПа', '2,5 МПа', '1,2 МПа', '0,005 МПа'],
        correctAnswers: ['0,005 МПа'],
      },
      {
        code: '63689076',
        text:
          'Каким образом определяется окончание продувки газопровода при пуске газа?',
        answers: [
          'Путем анализа или сжиганием отобранных проб газа',
          'Только путем анализа с применением газоанализатора или газосигнализатора',
          'Временем продувки, установленным экспериментально и указанном в инструкции',
        ],
        correctAnswers: ['Путем анализа или сжиганием отобранных проб газа'],
      },
      {
        code: '63689077',
        text:
          'Какие регулирующие устройства могут применяться в ГРП, ГРУ, ГРПБ и ШРП?',
        answers: [
          'Только регуляторы давления газа с односедельным клапаном',
          'Только клапаны регулирующие двухседельные',
          'Только поворотные заслонки с электронным регулятором и исполнительным механизмом',
          'Все перечисленные устройства',
        ],
        correctAnswers: ['Все перечисленные устройства'],
      },
      {
        code: '63689078',
        text:
          'В каких местах допускается размещение отдельно стоящих зданий или сооружений?',
        answers: [
          'В замкнутых и полузамкнутых дворах',
          'Все ответы неверны',
          'Только в полузамкнутых дворах, при этом расстояние от этих сооружений до зданий должно удовлетворять требованиям, предъявляемым к устройству замкнутых дворов',
          'Только в замкнутых дворах',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63689079',
        text:
          'В каких случаях допускается применять здания, образующие полузамкнутые дворы?',
        answers: [
          'Во всех перечисленных случаях',
          'В случае расположения зданий перпендикулярно или под углом не менее 30° к преобладающему направлению ветров летнего периода года',
          'В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции',
          'В случае отсутствия вредных производственных выделений',
        ],
        correctAnswers: [
          'В тех случаях, когда другое планировочное решение не может быть принято по условиям технологии либо по условиям реконструкции',
        ],
      },
      {
        code: '63689080',
        text:
          'Какое проектное решение должно предусматриваться в случае пересечения надземных газопроводов с высоковольтными линиями электропередачи?',
        answers: [
          'При напряжении линии электропередачи, превышающем 1 кВ, должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве, а также защитные устройства от падения опор линий электропередачи',
          'При напряжении линии электропередачи, превышающем 3 кВ, должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве, а также защитные устройства от падения опор линий электропередачи',
          'При напряжении линии электропередачи, превышающем 1 кВ, должно быть предусмотрено либо применение диэлектрических футляров на газопроводах, либо подземная прокладка газопровода на участке пересечения',
        ],
        correctAnswers: [
          'При напряжении линии электропередачи, превышающем 1 кВ, должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве, а также защитные устройства от падения опор линий электропередачи',
        ],
      },
      {
        code: '63689081',
        text:
          'Каким требованиям должны соответствовать заглушки, устанавливаемые на газопроводы природного газа?',
        answers: [
          'Должны соответствовать номинальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода',
          'Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием диаметра газопровода',
          'Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода',
          'Должны соответствовать рабочему давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа',
        ],
        correctAnswers: [
          'Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода',
        ],
      },
      {
        code: '63689082',
        text:
          'В каком случае при пересечении надземных газопроводов высоковольтными линиями электропередачи должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве?',
        answers: [
          'При отнесении газопровода к категории 1',
          'При напряжении ВЛ свыше 1 кВ',
          'При прокладке газопроводов на территории населенных пунктов',
        ],
        correctAnswers: ['При напряжении ВЛ свыше 1 кВ'],
      },
      {
        code: '63689083',
        text:
          'Какое из перечисленных требований при пересечении газопроводами железнодорожных и трамвайных путей и автомобильных дорог указано верно?',
        answers: [
          'Толщина стенок труб стального газопровода при пересечении им железнодорожных путей общего пользования и внешних железнодорожных подъездных путей предприятия должна на 2-3 мм превышать расчетную, но не менее 5 мм на расстояниях 50 м в каждую сторону от подошвы откоса насыпи или оси крайнего рельса на нулевых отметках',
          'При прокладке газопровода методом наклонно-направленного бурения отметка должна находиться не менее чем на 1,0 м ниже прогнозируемого профиля дна',
          'Отметка верха газопровода (балласта, футеровки) должна быть не менее чем на 1,0 м, а на переходах через судоходные и сплавные водные преграды - на 1,5 м ниже прогнозируемого профиля дна на весь срок эксплуатации газопровода',
          'Все перечисленные указаны верно',
          'Все ответы неверны',
        ],
        correctAnswers: [
          'Толщина стенок труб стального газопровода при пересечении им железнодорожных путей общего пользования и внешних железнодорожных подъездных путей предприятия должна на 2-3 мм превышать расчетную, но не менее 5 мм на расстояниях 50 м в каждую сторону от подошвы откоса насыпи или оси крайнего рельса на нулевых отметках',
        ],
      },
      {
        code: '63689084',
        text:
          'Кто должен проводить испытания на герметичность законченных строительством или реконструкцией газопроводов?',
        answers: [
          'Строительная организация совместно с газораспределительной организацией',
          'Эксплуатационная организация',
          'Строительная организация в присутствии представителя строительного контроля со стороны застройщика',
        ],
        correctAnswers: [
          'Строительная организация в присутствии представителя строительного контроля со стороны застройщика',
        ],
      },
      {
        code: '63689085',
        text:
          'В каких местах следует предусматривать контрольные трубки при проектировании подземных газопроводов на площадках строительства сейсмичностью более 6 баллов, на подрабатываемых и закарстованных территориях?',
        answers: [
          'На всех перечисленных территориях',
          'Только в местах пересечения с другими сетями инженерно-технического обеспечения',
          'Только в местах подземных вводов в здания',
          'Только в местах расположения переходов полиэтилен-сталь',
        ],
        correctAnswers: ['На всех перечисленных территориях'],
      },
      {
        code: '63689086',
        text:
          'Исходя из каких приведенных условий должны определяться места размещения сбросных и продувочных газопроводов?',
        answers: [
          'Места размещения сбросных и продувочных газопроводов должны определяться проектной документацией',
          'Максимальное рассеивание вредных веществ, при этом их концентрация в атмосфере не должна превышать более чем на 10% предельно допустимую максимальную разовую концентрацию',
          'Максимально быстрое удаление газов из сбросных и продувочных газопроводов',
          'Максимальное рассеивание вредных веществ, при этом их концентрация в атмосфере не должна превышать предельно допустимую максимальную разовую концентрацию',
        ],
        correctAnswers: [
          'Максимальное рассеивание вредных веществ, при этом их концентрация в атмосфере не должна превышать предельно допустимую максимальную разовую концентрацию',
        ],
      },
      {
        code: '63689087',
        text:
          'Какой следует принимать скорость движения газа при выполнении гидравлического расчета надземных и внутренних газопроводов среднего давления с учетом степени шума, создаваемого движением газа?',
        answers: ['15 м/с', '20 м/с', '25 м/с', '17 м/с'],
        correctAnswers: ['15 м/с'],
      },
      {
        code: '63689088',
        text: 'Какой документ выдается на производство газоопасных работ?',
        answers: ['Наряд-допуск', 'Акт-допуск', 'Распоряжение', 'Заявка'],
        correctAnswers: ['Наряд-допуск'],
      },
      {
        code: '63689089',
        text:
          'Для каких потребителей природного газа максимальное значение величины давления в сетях газопотребления составляет 0,6 МПа?',
        answers: [
          'Газоиспользующее оборудование котельных, отдельно стоящих на территории производственных предприятий',
          'Газоиспользующее оборудование котельных, пристроенных к производственным зданиям, встроенных в эти здания',
          'Газоиспользующее оборудование котельных, пристроенных к общественным зданиям, встроенных в эти здания',
        ],
        correctAnswers: [
          'Газоиспользующее оборудование котельных, пристроенных к производственным зданиям, встроенных в эти здания',
        ],
      },
      {
        code: '63689090',
        text:
          'Какие должны быть размеры приямков для заделки стыков в траншее для газопроводов из полиэтиленовых труб всех диаметров (D)?',
        answers: [
          'Длина 0,6 м, ширина D + 0,5 м, глубина 0,2 м',
          'Длина 1,0 м, ширина D + 1,5 м, глубина 0,5 м',
          'Длина 1,0 м, ширина D + 2 м, глубина 0,7 м',
          'Длина 1,2 м, ширина D + 1 м, глубина 1,0 м',
        ],
        correctAnswers: ['Длина 0,6 м, ширина D + 0,5 м, глубина 0,2 м'],
      },
      {
        code: '63689091',
        text:
          'В каких местах на внутренних газопроводах проектом должна предусматриваться установка продувочных газопроводов?',
        answers: [
          'На наиболее удаленных от места ввода участках газопровода',
          'На наиболее приближенных к месту ввода участках газопровода',
          'На ответвлении к газоиспользующему оборудованию до запорной трубопроводной арматуры',
        ],
        correctAnswers: [
          'На наиболее удаленных от места ввода участках газопровода',
        ],
      },
      {
        code: '63689092',
        text:
          'Какие из перечисленных расстояний от испарительной (смесительной) установки в свету указаны верно? Укажите все правильные ответы.',
        answers: [
          'До ограждения резервуарной установки с надземными резервуарами не менее 2 м',
          'До ограждения резервуарной установки с подземными резервуарами не менее 1,5 м от края откоса засыпки резервуаров',
          'До подземных резервуаров не менее 1 м',
          'До надземных резервуаров не менее 2 м',
        ],
        correctAnswers: [
          'До подземных резервуаров не менее 1 м',
          'До надземных резервуаров не менее 2 м',
        ],
      },
      {
        code: '63689093',
        text:
          'По указанию какого лица производится снятие заглушек, установленных на ответвлениях к потребителям (вводах), после визуального осмотра и опрессовки газопровода?',
        answers: [
          'Лица, руководящего работами по пуску газа',
          'Технического руководителя',
          'Начальника цеха',
          'Начальника смены',
        ],
        correctAnswers: ['Лица, руководящего работами по пуску газа'],
      },
      {
        code: '63689094',
        text:
          'В соответствии с каким документом принимаются допустимые отклонения от геометрических размеров трубы или соединительной детали (толщина стенки по периметру, наружный диаметр, овальность) при входном контроле труб и соединительных деталей?',
        answers: [
          'ГОСТ (ТУ) на эти изделия',
          'Федеральными нормами и правилами',
          'СНиП',
          'Руководящим документом',
        ],
        correctAnswers: ['ГОСТ (ТУ) на эти изделия'],
      },
      {
        code: '63689095',
        text:
          'К какому классу взрывоопасной зоны следует относить помещения категории А по взрывопожарной опасности, в которых расположено оборудование сетей газопотребления ГТУ и ПГУ?',
        answers: [
          'К зоне класса 22',
          'К зоне класса 1',
          'К зоне класса 2',
          'К зоне класса 0',
        ],
        correctAnswers: ['К зоне класса 1'],
      },
      {
        code: '63689096',
        text:
          'Какие из перечисленных мероприятий необходимо предусматривать при разработке планировочной организации земельных участков производственных объектов?',
        answers: [
          'Только организацию единой сети обслуживания работающих',
          'Только возможность осуществления строительства и ввода в эксплуатацию пусковыми комплексами или очередями',
          'Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве',
          'Только защиту прилегающих территорий от эрозии, заболачивания, засоления и загрязнения подземных вод и открытых водоемов сточными водами, отходами и отбросами предприятий',
        ],
        correctAnswers: [
          'Все перечисленные мероприятия, включая восстановление (рекультивацию) отведенных во временное пользование земель, нарушенных при строительстве',
        ],
      },
      {
        code: '63689097',
        text:
          'В каком месте должен выводиться газ от предохранительной арматуры, предусмотренной системами трубопроводов в ПРГ?',
        answers: [
          'Не менее 1 м выше карниза здания',
          'Не менее 1,5 м выше карниза здания',
          'Не менее 0,5 м выше карниза здания',
          'Определяется проектной документацией',
        ],
        correctAnswers: ['Не менее 1 м выше карниза здания'],
      },
      {
        code: '63689098',
        text:
          'Какой должна быть прокладка газопроводов СУГ, а также газопроводов природного газа на ГНС и ГНП?',
        answers: [
          'Надземной',
          'Скрытой',
          'Любой в соответствии с проектной документацией',
          'Подземной',
        ],
        correctAnswers: ['Надземной'],
      },
      {
        code: '63689099',
        text:
          'Каким образом устанавливаются предельные сроки дальнейшей эксплуатации газопроводов?',
        answers: [
          'Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться эксплуатирующей организацией на основании анализа приборного обследования газопроводов',
          'Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования',
          'Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться проектной организацией',
          'Предельные сроки дальнейшей эксплуатации газопроводов не должны превышать двойной продолжительности эксплуатации газопроводов, установленной при проектировании',
        ],
        correctAnswers: [
          'Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования',
        ],
      },
      {
        code: '63689100',
        text:
          'Какие из перечисленных действий разрешается производить на земельных участках, входящих в охранные зоны газораспределительных сетей?',
        answers: [
          'Сносить и реконструировать мосты, коллекторы, автомобильные и железные дороги с расположенными на них газораспределительными сетями без предварительного выноса этих газопроводов по согласованию с эксплуатационными организациями',
          'Строить объекты жилищно-гражданского и производственного назначения по согласованию с эксплуатационными организациями',
          'Самовольно подключаться к газораспределительным сетям',
          'Рыть погреба, копать и обрабатывать почву сельскохозяйственными и мелиоративными орудиями и механизмами на глубину более 0,3 м',
        ],
        correctAnswers: [
          'Строить объекты жилищно-гражданского и производственного назначения по согласованию с эксплуатационными организациями',
        ],
      },
      {
        code: '63689101',
        text:
          'Как регламентируется прокладка трубопроводов с легковоспламеняющимися и горючими жидкостями, а также со сжиженными газами для снабжения промышленных предприятий и складов по селитебной территории?',
        answers: [
          'Прокладка трубопроводов допускается в случае применения для строительства полиэтиленовых трубопроводов',
          'Прокладка трубопроводов допускается в футлярах',
          'Прокладка трубопроводов допускается при глубине их пролегания более 3 м',
          'Прокладка трубопроводов не допускается',
        ],
        correctAnswers: ['Прокладка трубопроводов не допускается'],
      },
      {
        code: '63689102',
        text:
          'Подтягивание или замена сальниковой набивки запорной арматуры, разборка резьбовых соединений конденсатосборников на наружных газопроводах среднего и высокого давлений допускаются при давлении газа:',
        answers: [
          'Не более 0,02 МПа',
          'Не более 0,03 МПа',
          'Не более 0,01 МПа',
          'Не более 0,1 МПа',
        ],
        correctAnswers: ['Не более 0,1 МПа'],
      },
      {
        code: '63689103',
        text:
          'При какой температуре наружного воздуха рекомендуется производить работы по укладке газопроводов без подогрева при низких температурах?',
        answers: [
          'Не ниже минус 15 °С и не выше плюс 30 °С',
          'Не ниже минус 10 °С и не выше плюс 20 °С',
          'Не ниже минус 15 °С и не выше плюс 40 °С',
          'Не ниже минус 25 °С и не выше плюс 30 °С',
        ],
        correctAnswers: ['Не ниже минус 15 °С и не выше плюс 30 °С'],
      },
      {
        code: '63689104',
        text:
          'Какое минимальное количество выездов должно иметь на объектах с земельным участком более 5 га?',
        answers: ['Два', 'Три', 'Один', 'Четыре'],
        correctAnswers: ['Два'],
      },
      {
        code: '63689105',
        text:
          'К какой категории относятся газопроводы с давлением газа свыше 0,3 до 0,6 МПа включительно?',
        answers: [
          'Высокого давления 1а категории',
          'Высокого давления 2 категории',
          'Среднего давления',
          'Низкого давления',
        ],
        correctAnswers: ['Высокого давления 2 категории'],
      },
      {
        code: '63689106',
        text:
          'На сколько допускается сокращать расстояния до зданий и сооружений при прокладке подземных газопроводов в стесненных, особых природных условиях?',
        answers: [
          'Не более чем на 50%',
          'Не допускается сокращать расстояния до зданий и сооружений',
          'Не более чем на 25%',
          'Не более чем на 15%',
        ],
        correctAnswers: ['Не более чем на 25%'],
      },
      {
        code: '63689107',
        text:
          'Что должен представить приемочной комиссии генеральный подрядчик при приемке в эксплуатацию объекта системы газораспределения?',
        answers: [
          'Комплект исполнительной документации',
          'Акт приемки законченного строительством объекта системы газораспределения',
          'Договоры субподряда',
          'Все перечисленное',
        ],
        correctAnswers: ['Комплект исполнительной документации'],
      },
      {
        code: '63689108',
        text:
          'Потоком воздуха какой скорости осуществляется очистка полости наружных газопроводов продувкой воздухом?',
        answers: ['15 - 20 м/с', '10 - 12 м/с', '7 - 10 м/с', '5 - 12 м/с'],
        correctAnswers: ['15 - 20 м/с'],
      },
      {
        code: '63689109',
        text:
          'Какой коэффициент запаса прочности труб и соединительных деталей устанавливается при прокладке полиэтиленовых газопроводов давлением свыше 0,3 до 0,6 МПа включительно на территориях городов и сельских населенных пунктов?',
        answers: [
          'Не менее 3,2',
          'Не менее 2,7',
          'Не менее 1,8',
          'Устанавливается проектной документацией',
        ],
        correctAnswers: ['Не менее 3,2'],
      },
      {
        code: '63689110',
        text: 'Для чего предназначен продувочный газопровод?',
        answers: [
          'Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств',
          'Для транспортирования природного газа',
          'Для отвода природного газа от предохранительных сбросных клапанов',
          'Для вытеснения природного газа из газопровода и технических устройств газа при их отключении',
        ],
        correctAnswers: [
          'Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств',
        ],
      },
      {
        code: '63689111',
        text:
          'На каком расстоянии от оси газопроводов устанавливаются навигационные знаки в местах пересечения газопроводов с судоходными и сплавными реками и каналами на обоих берегах?',
        answers: ['100 м', '80 м', '70 м', '50 м'],
        correctAnswers: ['100 м'],
      },
      {
        code: '63689112',
        text:
          'Что должны обеспечить сети газораспределения и газопотребления как объекты технического регулирования?',
        answers: [
          'Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации',
          'Экономическую эффективность применения природного газа с параметрами по давлению и расходу, определенными эксплуатирующей документацией',
          'Эффективность сжигания природного газа в газоиспользующих установках с параметрами по давлению и расходу, определенными проектной документацией',
        ],
        correctAnswers: [
          'Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации',
        ],
      },
      {
        code: '63689113',
        text:
          'На каких газопроводах проектом должен предусматриваться штуцер с краном для отбора проб?',
        answers: [
          'На внутреннем газопроводе после отключающего устройства',
          'На продувочном газопроводе после отключающего устройства',
          'По усмотрению проектной организации',
        ],
        correctAnswers: [
          'На продувочном газопроводе после отключающего устройства',
        ],
      },
      {
        code: '63689114',
        text:
          'Каким образом проводится очистка полости наружных газопроводов диаметром 219 мм и более продувкой воздухом?',
        answers: [
          'Продувкой с пропуском очистных устройств',
          'Продувкой с предшествующей механизированной очисткой с помощью штанги',
          'Продувкой без пропуска очистных устройств',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Продувкой с пропуском очистных устройств'],
      },
      {
        code: '63689115',
        text:
          'Из каких материалов не следует сооружать колодцы на газопроводах?',
        answers: [
          'Из полиэтилена',
          'Из железобетона',
          'Из бутовых камней',
          'Из кирпича',
          'Из бетона',
        ],
        correctAnswers: ['Из полиэтилена'],
      },
      {
        code: '63689116',
        text:
          'Что необходимо учитывать при выборе оборудования ГРП, ГРПБ, ШРП и ГРУ?',
        answers: [
          'Только рабочее давление газа в газопроводе, к которому подключается объект',
          'Только состав газа, его плотность, температуру точки росы, теплоту сжигания (Qн)',
          'Только потери давления на трение в газопроводе от места подключения до ввода его в ГРП или подвода к ГРУ',
          'Только температурные условия эксплуатации оборудования и приборов КИП ГРП и ГРУ',
          'Все перечисленные параметры',
        ],
        correctAnswers: ['Все перечисленные параметры'],
      },
      {
        code: '63689117',
        text:
          'Какие соединения полиэтиленовых газопроводов подвергаются внешнему осмотру?',
        answers: [
          'Все соединения, выполненные в процессе строительства газопровода любыми способами сварки',
          'Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык',
          'Соединения, выполненные в процессе строительства газопровода при помощи деталей с закладными нагревателями',
        ],
        correctAnswers: [
          'Все соединения, выполненные в процессе строительства газопровода любыми способами сварки',
        ],
      },
      {
        code: '63689118',
        text:
          'Каким должно быть расстояние по горизонтали (в свету) от отдельно стоящего пункта редуцирования газа до обочин автомобильных дорог при давлении газа на вводе до 0,6 МПа?',
        answers: [
          'Не менее 5 м',
          'Не менее 10 м',
          'Не менее 15 м',
          'Расстояние не нормируется',
        ],
        correctAnswers: ['Не менее 5 м'],
      },
      {
        code: '63689119',
        text: 'Что входит в состав газораспределительных сетей?',
        answers: [
          'Только отдельно стоящие газорегуляторные пункты, расположенные на территории и за территорией населенных пунктов, промышленных и иных предприятий',
          'Только наружные подземные, наземные и надземные распределительные газопроводы, межпоселковые газопроводы, газопроводы-вводы с установленной на них запорной арматурой',
          'Только переходы газопроводов через естественные и искусственные препятствия, в том числе через реки, железные и автомобильные дороги',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63689120',
        text:
          'Кто, в соответствии с Правилами охраны газораспределительных сетей, должен устанавливать или наносить на постоянные ориентиры опознавательные знаки в период сооружения газопровода?',
        answers: [
          'Эксплуатационная организация',
          'Газораспределительная организация',
          'Строительная организация',
          'Владелец земельного участка, по которому проходит трасса газопровода',
        ],
        correctAnswers: ['Строительная организация'],
      },
      {
        code: '63689121',
        text:
          'Какие требования, предъявляемые к внешнему виду сварных соединений полиэтиленовых трубопроводов, выполненных сваркой нагретым инструментом встык, указаны верно?',
        answers: [
          'Цвет валиков должен быть одного цвета с трубой и не иметь трещин, пор, инородных включений',
          'Симметричность шва (отношение ширины наружных валиков грата к общей ширине грата) должна быть в пределах 0,5 - 0,9 в любой точке шва',
          'Смещение наружных кромок свариваемых заготовок не должно превышать 15% толщины стенки трубы (детали)',
          'Угол излома сваренных труб или трубы и соединительной детали не должен превышать 10°',
        ],
        correctAnswers: [
          'Цвет валиков должен быть одного цвета с трубой и не иметь трещин, пор, инородных включений',
        ],
      },
      {
        code: '63689122',
        text:
          'Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком на газопроводах газорегуляторных пунктов (далее - ГРП) и ГРУ, подлежит контролю физическими методами?',
        answers: [
          '100%',
          '20%, но не менее одного стыка',
          '25%, но не менее одного стыка',
          '50%, но не менее одного стыка',
        ],
        correctAnswers: ['100%'],
      },
      {
        code: '63689123',
        text:
          'За сколько дней до начала технического обслуживания, ремонта и диагностирования газораспределительных сетей эксплуатационная организация газораспределительной сети отправляет уведомление о производстве работ по почте собственникам, владельцам или пользователям земельных участков, которые расположены в охранных зонах?',
        answers: [
          'За 10 календарных дней',
          'За 3 рабочих дня',
          'За 5 рабочих дней',
          'За 7 календарных дней',
        ],
        correctAnswers: ['За 5 рабочих дней'],
      },
      {
        code: '63689124',
        text:
          'Какую высоту следует принимать от уровня земли до низа труб или поверхности изоляции, прокладываемых на высоких опорах, в непроезжей части территории, в местах прохода людей?',
        answers: ['5 м', '6 м', '7,1 м', '2,2 м'],
        correctAnswers: ['2,2 м'],
      },
      {
        code: '63689125',
        text:
          'Какое из перечисленных расстояний (в свету) должно быть не менее 1 м при размещении в один ряд двух насосов и более или компрессоров? Укажите все правильные ответы.',
        answers: [
          'Между компрессорами',
          'От насосов и компрессоров до стен помещения',
          'Между насосами и компрессорами',
          'Между насосами',
        ],
        correctAnswers: [
          'От насосов и компрессоров до стен помещения',
          'Между насосами и компрессорами',
        ],
      },
      {
        code: '63689126',
        text:
          'Под каким углом должны предусматриваться пересечения кабельных эстакад с воздушными линиями электропередачи?',
        answers: [
          'Пересечение надземных коммуникаций не допускается',
          'Под углом не менее 45°',
          'Под углом не менее 30°',
        ],
        correctAnswers: ['Под углом не менее 30°'],
      },
      {
        code: '63689127',
        text: 'В каких местах допускается установка баллонов СУГ?',
        answers: [
          'В помещениях с естественным освещением',
          'У аварийных выходов',
          'В цокольных и подвальных помещениях',
          'Над цокольными и подвальными этажами',
        ],
        correctAnswers: ['В помещениях с естественным освещением'],
      },
      {
        code: '63689128',
        text:
          'Какие сварные стыки стальных газопроводов природного газа не подлежат контролю физическими методами?',
        answers: [
          'Только стыки наружных и внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа',
          'Только стыки внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа',
          'Стыки надземных и внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа',
        ],
        correctAnswers: [
          'Стыки надземных и внутренних газопроводов природного газа условным проходом 50 и более, давлением до 0,1 МПа',
        ],
      },
      {
        code: '63689129',
        text:
          'Трубы с какой минимальной толщиной стенки следует применять для внутренних газопроводов из меди?',
        answers: ['1 мм', '1,2 мм', '3 мм', '1,5 мм'],
        correctAnswers: ['1 мм'],
      },
      {
        code: '63689130',
        text:
          'Допускается ли проектирование прокладки наружных газопроводов по стенам помещений категорий А и Б по взрывопожарной опасности?',
        answers: [
          'Допускается проектирование прокладки только газопроводов среднего давления',
          'Допускается проектирование прокладки только газопроводов низкого давления',
          'Не допускается проектирование газопроводов всех категорий давлений, за исключением зданий газорегуляторных пунктов и пунктов учета газа',
        ],
        correctAnswers: [
          'Не допускается проектирование газопроводов всех категорий давлений, за исключением зданий газорегуляторных пунктов и пунктов учета газа',
        ],
      },
      {
        code: '63689131',
        text:
          'Для каких технологических устройств газопроводов не допускается проектирование обводных газопроводов с запорной арматурой, предназначенных для транспортирования природного газа, минуя основной газопровод на участке его ремонта, и для возвращения потока газа в сеть в конце участка?',
        answers: [
          'Только в блочных газорегуляторных пунктах',
          'Только во встроенных и пристроенных газорегуляторных пунктах и газорегуляторных установках',
          'В газорегуляторных пунктах всех видов и газорегуляторных установках',
        ],
        correctAnswers: [
          'В газорегуляторных пунктах всех видов и газорегуляторных установках',
        ],
      },
      {
        code: '63689132',
        text:
          'В каком случае допускается не проводить испытания подземных газопроводов, прокладываемых в футлярах на участках переходов через искусственные и естественные преграды, после полного монтажа и засыпки перехода?',
        answers: [
          'По согласованию с территориальным органом Ростехнадзора',
          'Не допускается ни в каком случае',
          'По согласованию с эксплуатационной организацией',
          'По согласованию с проектной организацией',
        ],
        correctAnswers: ['По согласованию с эксплуатационной организацией'],
      },
      {
        code: '63689133',
        text:
          'Какова минимальная глубина прокладки наружных подземных газопроводов?',
        answers: [
          '0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,6 м до верха трубы для стальных газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин',
          '0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,3 м до верха трубы для полиэтиленовых газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин',
          '0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,6 м до верха трубы для полиэтиленовых газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин',
        ],
        correctAnswers: [
          '0,8 м до верха газопровода, футляра или балластирующего устройства. Допускается 0,6 м до верха трубы для стальных газопроводов на участках, где не предусмотрено движение транспорта и сельскохозяйственных машин',
        ],
      },
      {
        code: '63689134',
        text:
          'При какой минимальной температуре наружного воздуха производятся транспортировка, погрузка и разгрузка одиночных труб?',
        answers: ['Минус 20 °С', 'Минус 25 °С', 'Минус 30 °С', 'Минус 35 °С'],
        correctAnswers: ['Минус 20 °С'],
      },
      {
        code: '63689135',
        text:
          'Какие типы предохранительных сбросных клапанов (ПСК) могут применяться в ГРП, ГРУ, ГРПБ и ШРП?',
        answers: [
          'Мембранные и пружинные',
          'С ручным приводом',
          'Рычажные',
          'С соленоидным приводом',
        ],
        correctAnswers: ['Мембранные и пружинные'],
      },
      {
        code: '63689136',
        text: 'В какие здания допускается встраивать газорегуляторные пункты?',
        answers: [
          'В 1-этажные газифицируемые производственные здания и котельные (кроме помещений, расположенных в цокольных и подвальных этажах) I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д',
          'Встраивать газорегуляторные пункты в здания любого назначения запрещается',
          'В 1-этажные газифицируемые производственные здания и котельные I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий А и Б',
        ],
        correctAnswers: [
          'В 1-этажные газифицируемые производственные здания и котельные (кроме помещений, расположенных в цокольных и подвальных этажах) I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д',
        ],
      },
      {
        code: '63689137',
        text:
          'Где должен быть установлен манометр для контроля давления в газопроводе при проведении газовой резки и сварки?',
        answers: [
          'Не далее 50 м от места проведения работ',
          'Не далее 100 м от места проведения работ',
          'Не далее 10 м от места проведения работ',
          'Не далее 15 м от места проведения работ',
        ],
        correctAnswers: ['Не далее 100 м от места проведения работ'],
      },
      {
        code: '63689138',
        text:
          'В каких местах необходимо предусматривать запорную арматуру (отключающие устройства) на газопроводах?',
        answers: [
          'Перед наружным газоиспользующим оборудованием',
          'Перед пунктами редуцирования газа (далее - ПРГ), включая ПРГ предприятий, на ответвлении газопровода к которым имеется отключающее устройство на расстоянии менее 100 м от ПРГ',
          'На ответвлениях от газопроводов к поселениям, отдельным микрорайонам, кварталам, включая отдельные жилые дома с количеством проживающих более 50 человек, а также на ответвлениях к производственным потребителям и котельным',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Перед наружным газоиспользующим оборудованием'],
      },
      {
        code: '63689139',
        text:
          'В соответствии с чем устанавливается периодичность ремонтов оборудования ГТУ и ПГУ?',
        answers: [
          'В соответствии с графиками, утвержденными техническим руководителем ТЭС',
          'В соответствии с проектной документацией',
          'В соответствии с техническим регламентом',
          'В соответствии с Правилами безопасности сетей газораспределения и газопотребления',
        ],
        correctAnswers: [
          'В соответствии с графиками, утвержденными техническим руководителем ТЭС',
        ],
      },
      {
        code: '63689140',
        text:
          'На каком расстоянии друг от друга должны размещаться групповые баллонные установки СУГ?',
        answers: [
          'Не менее 5 м',
          'Не менее 10 м',
          'Не менее 15 м',
          'Не менее 20 м',
        ],
        correctAnswers: ['Не менее 15 м'],
      },
      {
        code: '63689141',
        text:
          'При какой минимальной температуре допускается применение медных газопроводов для транспортирования сернистых газов?',
        answers: ['0 °С', 'Минус 5 °С', 'Минус 10 °С', 'Минус 7 °С'],
        correctAnswers: ['0 °С'],
      },
      {
        code: '63689142',
        text:
          'Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных газопроводов из полиэтиленовых труб, для обозначения трасс которых используется медный провод?',
        answers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м с каждой стороны газопровода',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м с каждой стороны газопровода',
        ],
        correctAnswers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны',
        ],
      },
      {
        code: '63689143',
        text:
          'Какая информация должна содержаться на опознавательных знаках для обнаружения трасс подземных газопроводов?',
        answers: [
          'Только расстояние от газопровода',
          'Только глубина заложения газопровода',
          'Только телефон аварийно - диспетчерской службы',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63689144',
        text: 'В каком случае предусматривается резервная линия редуцирования?',
        answers: [
          'При обеспечении непрерывности подачи газа потребителям в ПРГ, пропускная способность которых обеспечивается одной линией редуцирования',
          'При подаче газа на объекты, в работе которых допускается прекращение подачи газа на период выполнения регламентных работ',
          'При подаче газа потребителям по закольцованной схеме газопроводов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: [
          'При обеспечении непрерывности подачи газа потребителям в ПРГ, пропускная способность которых обеспечивается одной линией редуцирования',
        ],
      },
      {
        code: '63689145',
        text:
          'Какие трубопроводы допускается размещать в открытых траншеях и лотках?',
        answers: [
          'Трубопроводы бытовой канализации',
          'Трубопроводы, по которым транспортируются кислоты и щелочи',
          'Трубопроводы для горючих газов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63689146',
        text:
          'Кто утверждает графики ремонтов газопроводов и технических устройств сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'Технический руководитель ТЭС',
          'Руководитель ТЭС',
          'Руководитель ремонтной бригады',
          'Комиссия ТЭС',
        ],
        correctAnswers: ['Технический руководитель ТЭС'],
      },
      {
        code: '63689147',
        text:
          'Какому виду контроля подлежат сварные стыки газопроводов, сваренные после испытаний на герметичность?',
        answers: [
          'Визуальному осмотру и проверке на герметичность мыльной эмульсией',
          'Только визуальному осмотру',
          'Физическому методу контроля',
        ],
        correctAnswers: ['Физическому методу контроля'],
      },
      {
        code: '63689148',
        text:
          'Какая норма испытаний на герметичность установлена для газопроводов котельных с давлением свыше 0,1 МПа до 0,3 МПа включительно?',
        answers: [
          '0,3 МПа, продолжительность испытаний - 1 час',
          '0,1 МПа, продолжительность испытаний - 12 часов',
          '1,25 от рабочего давления, но не более 0,3 МПа, продолжительность испытаний - 12 часов',
          '1,25 от рабочего давления, но не более 0,3 МПа, продолжительность испытаний - 1 час',
        ],
        correctAnswers: [
          '1,25 от рабочего давления, но не более 0,3 МПа, продолжительность испытаний - 1 час',
        ],
      },
      {
        code: '63689149',
        text:
          'На основании чего производятся работы, связанные с обработкой почвы на глубину более 0,3 м, собственниками, владельцами или пользователями земельных участков в охранной зоне газораспределительной сети?',
        answers: [
          'На основании письменного разрешения эксплуатационной организации газораспределительных сетей',
          'На основании письменного разрешения органов местного самоуправления',
          'На основании письменного разрешения Ростехнадзора',
          'На основании письменного разрешения Росприроднадзора',
        ],
        correctAnswers: [
          'На основании письменного разрешения эксплуатационной организации газораспределительных сетей',
        ],
      },
      {
        code: '63689150',
        text:
          'На сколько уровень полов первого этажа зданий должен быть выше планировочной отметки примыкающих к зданиям участков?',
        answers: [
          'Не менее чем на 12 см',
          'Не менее чем на 10 см',
          'Не менее чем на 15 см',
        ],
        correctAnswers: ['Не менее чем на 15 см'],
      },
      {
        code: '63689151',
        text:
          'Что из перечисленного должно соблюдаться при строительстве сети газораспределения и сети газопотребления?',
        answers: [
          'Только технические решения, предусмотренные проектной документацией',
          'Только требования эксплуатационной документации изготовителей газоиспользующего оборудования, технических и технологических устройств, труб, материалов и соединительных деталей',
          'Только технология строительства, монтажа, капитального ремонта и реконструкции в соответствии с проектом производства работ или технологическими картами',
          'Должно соблюдаться все перечисленное',
        ],
        correctAnswers: ['Должно соблюдаться все перечисленное'],
      },
      {
        code: '63689152',
        text:
          'Какое количество сварных стыков, сваренных каждым сварщиком, на подземном стальном газопроводе давлением свыше 0,1 МПа до 0,3 МПа включительно подлежит контролю физическими методами?',
        answers: [
          'Контролю физическими методами не подлежат',
          '10% от общего числа стыков, но не менее одного стыка',
          '25% от общего числа стыков, но не менее одного стыка',
          '50% от общего числа стыков, но не менее одного стыка',
        ],
        correctAnswers: [
          '50% от общего числа стыков, но не менее одного стыка',
        ],
      },
      {
        code: '63689153',
        text:
          'Допускается ли при проектировании внутренних газопроводов по стенам помещения пересечение ими оконных, дверных проемов, вентиляционных решеток?',
        answers: [
          'Не допускается ни при каких условиях',
          'Не допускается, за исключением переплетов и импостов неоткрывающихся окон и оконных проемов, заполненных стеклоблоками',
          'Допускается, если на участке пересечения газопровод не будет иметь разъемных соединений',
        ],
        correctAnswers: [
          'Не допускается, за исключением переплетов и импостов неоткрывающихся окон и оконных проемов, заполненных стеклоблоками',
        ],
      },
      {
        code: '63689154',
        text:
          'Для каких категорий наружных газопроводов не допускается проектирование их прокладки по пешеходным и автомобильным мостам, построенным из негорючих материалов?',
        answers: [
          'Для газопроводов среднего давления',
          'Для газопроводов низкого давления',
          'Для газопроводов высокого давления, превышающего 0,6 МПа',
        ],
        correctAnswers: [
          'Для газопроводов высокого давления, превышающего 0,6 МПа',
        ],
      },
      {
        code: '63689155',
        text:
          'Какие виды грунтов относятся к особым условиям при прокладке газопроводов?',
        answers: [
          'Элювиальные грунты',
          'Слабонабухающие',
          'Просадочные грунты типа I просадочности',
          'Слабопучинистые',
        ],
        correctAnswers: ['Элювиальные грунты'],
      },
      {
        code: '63689156',
        text:
          'Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком, на надземных и внутренних газопроводах давлением до 0,1 МПа и условным проходом 50 и более подлежит контролю физическими методами?',
        answers: [
          '10%, но не менее одного стыка',
          '5%, но не менее одного стыка',
          '15%, но не менее одного стыка',
          'Контролю физическими методами не подлежат',
        ],
        correctAnswers: ['Контролю физическими методами не подлежат'],
      },
      {
        code: '63689157',
        text:
          'Какой шириной должны создаваться минерализованные полосы по границам просек эксплуатационными организациями за свой счет при прохождении охранных зон газораспределительных сетей по лесам и древесно-кустарниковой растительности?',
        answers: [
          'Не менее 2,0 м',
          'Не менее 1,4 м',
          'Не менее 1,8 м',
          'Не менее 1,0 м',
        ],
        correctAnswers: ['Не менее 1,4 м'],
      },
      {
        code: '63689158',
        text:
          'Какое число газорегуляторных установок (далее – ГРУ) допускается размещать в одном помещении?',
        answers: [
          'Число ГРУ, размещаемых в одном помещении, не ограничивается',
          '2 ГРУ в помещениях категорий А и Б, в остальных помещениях не ограничивается',
          '3 ГРУ в помещениях категорий Г и Д',
          'Не более 1 ГРУ в помещениях любых категорий',
        ],
        correctAnswers: [
          'Число ГРУ, размещаемых в одном помещении, не ограничивается',
        ],
      },
      {
        code: '63689159',
        text:
          'Что рекомендуется устанавливать на газовых сетях для обеспечения стабильного давления газа перед газовыми горелками газоиспользующего оборудования и котлов производственных зданий и котельных?',
        answers: [
          'Регуляторы-стабилизаторы',
          'Все перечисленное',
          'Предохранительно-сбросные клапаны',
          'Предохранительно-запорные клапаны',
        ],
        correctAnswers: ['Регуляторы-стабилизаторы'],
      },
      {
        code: '63689160',
        text:
          'Какая документация предъявляется приемочной комиссии при приемке новых полиэтиленовых газопроводов и реконструированных методом протяжки полиэтиленовых труб изношенных газопроводов?',
        answers: [
          'Только проектная документация в полном объеме',
          'Только акт разбивки трассы, исполнительные чертежи (план, профиль) газопровода с указанием его границ (пикетажа)',
          'Только строительный паспорт газопровода, включая акт испытания его на герметичность',
          'Только российские сертификаты или технические свидетельства на примененные материалы (полиэтиленовые трубы, фитинги и т.д.)',
          'Вся перечисленная',
        ],
        correctAnswers: ['Вся перечисленная'],
      },
      {
        code: '63689161',
        text:
          'Каким образом должна проверяться герметичность резьбовых и фланцевых соединений, которые разбирались для устранения закупорок?',
        answers: [
          'Любым из перечисленных способов',
          'Испытанием на герметичность',
          'Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)',
          'Опрессовкой',
        ],
        correctAnswers: [
          'Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)',
        ],
      },
      {
        code: '63689162',
        text:
          'Какие соединения должны применяться для подземных медных газопроводов? Укажите все правильные ответы.',
        answers: [
          'Соединения встык нагретым инструментом',
          'Соединения, выполненные сваркой',
          'Соединения, выполненные высокотемпературной капиллярной пайкой',
          'Стыковые соединения',
          'Нахлесточные соединения',
        ],
        correctAnswers: [
          'Соединения, выполненные сваркой',
          'Соединения, выполненные высокотемпературной капиллярной пайкой',
        ],
      },
      {
        code: '63689163',
        text:
          'Какие охранные зоны установлены Правилами охраны газораспределительных сетей вокруг отдельно стоящих газорегуляторных пунктов?',
        answers: [
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов',
          'Охранная зона не регламентируется',
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 3 м от границ этих объектов',
        ],
        correctAnswers: [
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов',
        ],
      },
      {
        code: '63689164',
        text:
          'Какими способами происходит строительство подводных переходов газопроводов? Выберите два варианта ответа.',
        answers: [
          'Открытым (траншейным)',
          'Способом наклонно-направленного бурения (ННБ)',
          'Способом микротоннелирования',
          'Пневмопробивкой с помощью пневмопробойника',
        ],
        correctAnswers: [
          'Открытым (траншейным)',
          'Способом наклонно-направленного бурения (ННБ)',
        ],
      },
      {
        code: '63689165',
        text:
          'Какое из перечисленных требований к проектированию внутренних газопроводов верно?',
        answers: [
          'На продувочном газопроводе должен предусматриваться штуцер с краном перед отключающим устройством',
          'При установке нескольких единиц газоиспользующего оборудования должна быть обеспечена возможность отключения каждой единицы оборудования',
          'При проектировании внутренних газопроводов по стенам зданий допускается пересечение газопроводами дверных проемов, если высота проемов не менее 3 м',
        ],
        correctAnswers: [
          'При установке нескольких единиц газоиспользующего оборудования должна быть обеспечена возможность отключения каждой единицы оборудования',
        ],
      },
      {
        code: '63689166',
        text:
          'Какие сварные стыки стальных газопроводов не подлежат контролю физическими методами?',
        answers: [
          'Стыки наружных и внутренних газопроводов природного газа всех давлений с условным проходом менее 50',
          'Только стыки внутренних газопроводов природного газа всех давлений с условным проходом менее 50',
          'Стыки наружных и внутренних газопроводов природного газа условным проходом 50 и более, давлением выше 0,1 МПа',
        ],
        correctAnswers: [
          'Стыки наружных и внутренних газопроводов природного газа всех давлений с условным проходом менее 50',
        ],
      },
      {
        code: '63689167',
        text:
          'В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газораспределения?',
        answers: [
          'Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа',
          'Если объект транспортирует природный газ к газотурбинным и парогазовым установкам с давлением, не превышающим 2,5 МПа',
          'Если объект транспортирует природный газ к газоиспользующему оборудованию газифицируемых зданий с давлением, не превышающим 1,2 МПа',
        ],
        correctAnswers: [
          'Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа',
        ],
      },
      {
        code: '63689168',
        text:
          'Какой минимальный срок службы должен быть у гибких рукавов для присоединения газоиспользующего оборудования?',
        answers: ['12 лет', '10 лет', '5 лет', '7 лет'],
        correctAnswers: ['12 лет'],
      },
      {
        code: '63689169',
        text:
          'В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газопотребления?',
        answers: [
          'Если объект транспортирует природный газ между населенными пунктами с давлением, превышающим 0,005 МПа',
          'Если объект транспортирует природный газ по территориям населенных пунктов исключительно к производственным площадкам, на которых размещены газотурбинные и парогазовые установки, с давлением, превышающим 1,2 МПа',
          'Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа',
        ],
        correctAnswers: [
          'Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа',
        ],
      },
      {
        code: '63689170',
        text:
          'Для каких потребителей природного газа максимальное значение величины давления в сетях газопотребления составляет 0,005 МПа?',
        answers: [
          'Газоиспользующее оборудование котельных, отдельно стоящих на территории населенных пунктов',
          'Газоиспользующее оборудование котельных, пристроенных к производственным зданиям, встроенных в эти здания',
          'Газоиспользующее оборудование котельных, пристроенных к жилым зданиям, и крышных котельных жилых зданий',
        ],
        correctAnswers: [
          'Газоиспользующее оборудование котельных, пристроенных к жилым зданиям, и крышных котельных жилых зданий',
        ],
      },
      {
        code: '63689171',
        text:
          'Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком в течение календарного месяца, отбирается для механических испытаний?',
        answers: [
          '0,5% всех стыков',
          '0,5% всех стыков на газопроводах, не подлежащих контролю физическими методами, но не менее 2 стыков диаметром 50 мм и менее',
          '0,5% всех стыков на газопроводах, не подлежащих контролю физическими методами, но не менее 1 стыка диаметром более 50 мм',
          '0,5% от общего числа стыковых соединений, сваренных каждым сварщиком, но не менее 2 стыков диаметром 50 мм и менее и 1 стыка диаметром свыше 50 мм',
        ],
        correctAnswers: [
          '0,5% от общего числа стыковых соединений, сваренных каждым сварщиком, но не менее 2 стыков диаметром 50 мм и менее и 1 стыка диаметром свыше 50 мм',
        ],
      },
      {
        code: '63689172',
        text:
          'Какой срок службы принимается при расчетах на прочность и устойчивость газопроводов из полиэтиленовых труб?',
        answers: ['50 лет', '30 лет', '25 лет', '15 лет'],
        correctAnswers: ['50 лет'],
      },
      {
        code: '63689173',
        text:
          'В каких местах не рекомендуется предусматривать наружное размещение газового счетчика?',
        answers: [
          'На стене газифицируемого здания на расстоянии по горизонтали не менее 0,5 м от дверных проемов',
          'Под проемами в стенах',
          'На отдельно стоящей опоре на территории потребителя газа',
          'На стене газифицируемого здания на расстоянии по горизонтали не менее 0,5 м от оконных проемов',
        ],
        correctAnswers: ['Под проемами в стенах'],
      },
      {
        code: '63689174',
        text:
          'Какие требования установлены к стенам и перегородкам газорегуляторного пункта, отделяющим помещение для линии редуцирования от других помещений?',
        answers: [
          'Противопожарная стена без проемов 2-го типа либо противопожарная перегородка 1-го типа',
          'Противопожарная стена 2-го типа с проемами либо противопожарная перегородка 2-го типа',
          'Противопожарная стена без проемов 1-го типа либо противопожарная перегородка 2-го типа',
        ],
        correctAnswers: [
          'Противопожарная стена без проемов 2-го типа либо противопожарная перегородка 1-го типа',
        ],
      },
      {
        code: '63689175',
        text:
          'Какое минимальное расстояние по горизонтали (в свету) от кабеля до крайнего провода должно быть при прокладке кабельной линии параллельно высоковольтной линии (ВЛ) напряжением 110 кВ и выше?',
        answers: ['15 м', '3 м', '10 м', '8 м', '5 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63689176',
        text:
          'Что должна предусматривать схема транспорта, разрабатываемая в составе проекта, планировочной организации земельного участка объекта, группы объектов?',
        answers: [
          'Все перечисленное',
          'Использование сооружений и устройств, проектируемых для других целей (дамб водохранилищ и плотин, водопропускных сооружений), под земляное полотно и искусственные сооружения железных и автомобильных дорог',
          'Максимальное совмещение транспортных сооружений и устройств для различных видов транспорта (совмещенные автомобильные и железнодорожные или автомобильные и трамвайные мосты и путепроводы, общее земляное полотно для автомобильных дорог и трамвайных путей, кроме скоростных, и др.)',
          'Возможность последующего развития схемы внешнего транспорта',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63689177',
        text:
          'В течение какого времени должны храниться наряды-допуски на производство газоопасных работ?',
        answers: [
          'Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с момента его закрытия',
          'Все наряды-допуски на производство газоопасных работ должны храниться постоянно в исполнительно-технической документации',
          'Наряды-допуски на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должны храниться не менее трех лет с момента их закрытия',
          'Время хранения нарядов-допусков на производство газоопасных работ определяется эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с момента его закрытия',
        ],
      },
      {
        code: '63689178',
        text:
          'Что должно быть учтено при проектировании помещений, в которых будет размещено газоиспользующее оборудование?',
        answers: [
          'Их оснащение системами контроля загазованности (по метану и оксиду углерода) с выводом сигнала на пульт управления',
          'Их оснащение системами контроля загазованности (по метану) с выводом сигнала на пульт управления',
          'Их оснащение системами контроля загазованности (по оксиду углерода и диоксиду углерода) с выводом сигнала на пульт управления',
        ],
        correctAnswers: [
          'Их оснащение системами контроля загазованности (по метану и оксиду углерода) с выводом сигнала на пульт управления',
        ],
      },
      {
        code: '63689179',
        text:
          'В каких из перечисленных помещений допускается проектирование прокладки внутренних газопроводов?',
        answers: [
          'В помещениях категорий В, Г и Д по взрывопожарной опасности',
          'В помещениях категорий А и Б по взрывопожарной опасности',
          'В складских помещениях категорий А, Б и В1 - В3',
        ],
        correctAnswers: [
          'В помещениях категорий В, Г и Д по взрывопожарной опасности',
        ],
      },
      {
        code: '63689180',
        text:
          'Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории производственных предприятий?',
        answers: ['2,5 МПа', '1,2 МПа', '0,6 МПа', '0,005 МПа'],
        correctAnswers: ['1,2 МПа'],
      },
      {
        code: '63689181',
        text:
          'Каким документом оформляется приемка в эксплуатацию установок электрохимической защиты?',
        answers: [
          'Актом',
          'Протоколом испытаний',
          'Распорядительным документом руководителя организации',
          'Свидетельством',
        ],
        correctAnswers: ['Актом'],
      },
      {
        code: '63689182',
        text:
          'Для каких зданий допускается ввод газопроводов природного газа в помещения подвальных и цокольных этажей?',
        answers: [
          'Для зданий I - III степени огнестойкости с количеством этажей не более 2',
          'Для зданий I - II степени огнестойкости производственного назначения',
          'Для многоквартирных домов',
          'Для одноквартирных и блокированных домов',
        ],
        correctAnswers: ['Для одноквартирных и блокированных домов'],
      },
      {
        code: '63689183',
        text:
          'Какую информацию не содержит разрешение на производство работ в охранной зоне газораспределительной сети?',
        answers: [
          'О характере опасных производственных факторов',
          'О расположении трассы газопровода и условиях, в которых будут производиться работы',
          'О мерах предосторожности, наличии и содержании инструкций, которыми необходимо руководствоваться при выполнении конкретных видов работ',
          'О этапах работ, выполняемых в присутствии и под наблюдением представителя Ростехнадзора',
        ],
        correctAnswers: [
          'О этапах работ, выполняемых в присутствии и под наблюдением представителя Ростехнадзора',
        ],
      },
      {
        code: '63689184',
        text:
          'На каком расстоянии (в радиусе) от дверных и открывающихся оконных проемов следует размещать запорную арматуру на надземных газопроводах среднего давления, проложенных по стенам зданий и на опорах?',
        answers: [
          'Не менее 0,25 м',
          'Не менее 0,5 м',
          'Не менее 1 м',
          'Не менее 3 м',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 1 м'],
      },
      {
        code: '63689185',
        text:
          'Какие требования предъявляются к строительным конструкциям проектируемого здания газорегуляторного пункта?',
        answers: [
          'Строительные конструкции должны обеспечить зданию III и IV степени огнестойкости и класс конструктивной пожарной опасности C0',
          'Строительные конструкции должны обеспечить зданию I и II степени огнестойкости и класс конструктивной пожарной опасности С0',
          'Строительные конструкции должны обеспечить зданию I и II степени огнестойкости и класс конструктивной пожарной опасности C3',
        ],
        correctAnswers: [
          'Строительные конструкции должны обеспечить зданию I и II степени огнестойкости и класс конструктивной пожарной опасности С0',
        ],
      },
      {
        code: '63689186',
        text:
          'Каким должно быть минимальное расстояние по горизонтали (в свету) от газопроводов среднего давления (св. 0,005 до 0,3 МПа) до фундаментов зданий и сооружений?',
        answers: ['2 м', '7 м', '4 м', '3 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63689187',
        text:
          'Какие соединения полиэтиленовых газопроводов подвергаются ультразвуковому контролю?',
        answers: [
          'Соединения, выполненные в процессе строительства газопровода при помощи деталей с закладными нагревателями',
          'Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык',
          'Все соединения, выполненные в процессе строительства газопровода любыми способами сварки и соответствующие требованиям визуального контроля (внешнего осмотра)',
          'Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык и соответствующие требованиям визуального контроля (внешнего осмотра)',
        ],
        correctAnswers: [
          'Соединения, выполненные в процессе строительства газопровода сваркой нагретым инструментом встык и соответствующие требованиям визуального контроля (внешнего осмотра)',
        ],
      },
      {
        code: '63689188',
        text:
          'Чем должны оснащаться технологические устройства систем газораспределения и газопотребления?',
        answers: [
          'Только молниезащитой и заземлением',
          'Только молниезащитой и вентиляцией',
          'Только заземлением и вентиляцией',
          'Молниезащитой, заземлением и вентиляцией',
        ],
        correctAnswers: ['Молниезащитой, заземлением и вентиляцией'],
      },
      {
        code: '63689189',
        text:
          'В каком случае построенные или реконструированные газопроводы должны пройти повторное испытание на герметичность?',
        answers: [
          'Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания',
          'Если газопроводы не были введены в эксплуатацию в течение 3 месяцев и срок ввода в эксплуатацию совпал с началом зимнего периода',
          'В любом случае, все газопроводы при вводе в эксплуатацию подлежат повторному испытанию на герметичность вне зависимости от того, какие результаты до этого были получены',
        ],
        correctAnswers: [
          'Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания',
        ],
      },
      {
        code: '63689190',
        text:
          'Какую расчетную температуру наружного воздуха в районе строительства следует принимать при выборе стали для газопроводов и запорной арматуры сетей газораспределения и газопотребления газотурбинных и парогазовых установок?',
        answers: [
          'Температуру наиболее холодной декады обеспеченностью 0,98',
          'Температуру наиболее холодной пятидневки обеспеченностью 0,92',
          'Температуру наиболее холодной пятидневки обеспеченностью 0,98',
          'Температуру наиболее холодной декады обеспеченностью 0,82',
        ],
        correctAnswers: [
          'Температуру наиболее холодной пятидневки обеспеченностью 0,92',
        ],
      },
      {
        code: '63689191',
        text:
          'Какие размеры земельных участков газонаполнительных станций производительностью 20 тыс. т/год следует принимать по проекту?',
        answers: ['8 га', '7 га', '9 га', '6 га'],
        correctAnswers: ['7 га'],
      },
      {
        code: '63689192',
        text:
          'При обнаружении какой концентрации газа в помещениях ППГ необходимо организовать дополнительную вентиляцию помещения, выявить причину и незамедлительно устранить утечку газа?',
        answers: [
          '10% и более НКПРП',
          '5% и более НКПРП',
          '7% и более НКПРП',
          'При обнаружении любой концентрации газа',
        ],
        correctAnswers: ['10% и более НКПРП'],
      },
      {
        code: '63689193',
        text:
          'Какое из перечисленных требований к прокладке внутренних газопроводов противоречит СП 62.13330.2011. Свод правил. Газораспределительные системы»?',
        answers: [
          'Скрытая прокладка газопроводов из многослойных металлополимерных труб может производиться с последующей штукатуркой стен',
          'При скрытой прокладке стальных и медных труб необходимо обеспечить вентиляцию штрабы и доступ к газопроводу в процессе эксплуатации',
          'В производственных помещениях допускается скрытая прокладка газовых шлангов и газопроводов СУГ',
          'В местах пересечения строительных конструкций зданий газопроводы рекомендуется прокладывать в футлярах',
          'Трубы в штрабе рекомендуется прокладывать монолитно или свободно (при условии принятия мер по уплотнению штрабы)',
        ],
        correctAnswers: [
          'В производственных помещениях допускается скрытая прокладка газовых шлангов и газопроводов СУГ',
        ],
      },
      {
        code: '63689194',
        text:
          'При каком содержании кислорода в газовоздушной смеси розжиг горелок не допускается?',
        answers: [
          'Более 1,0% по объему',
          'Более 0,5% по объему',
          'Более 0,8% по объему',
        ],
        correctAnswers: ['Более 1,0% по объему'],
      },
      {
        code: '63689195',
        text:
          'Как регламентируется совместная прокладка газопроводов и трубопроводов, транспортирующих легковоспламеняющиеся и горючие вещества, с кабельными линиями?',
        answers: [
          'Совместная прокладка разрешается только в случаях, если диаметр трубопроводов менее 500 мм',
          'Совместная прокладка допускается при разработке мероприятий по безопасности',
          'Совместная прокладка не допускается',
          'Совместная прокладка допускается без ограничений',
        ],
        correctAnswers: ['Совместная прокладка не допускается'],
      },
      {
        code: '63689196',
        text:
          'В каком случае результаты испытаний на герметичность считаются положительными?',
        answers: [
          'Если в течение испытания не фиксируется видимое падение давления манометром класса точности 0,6',
          'Если в течение испытания падение давления не составило 1% от испытательного давления',
          'Если в течение испытания не фиксируется видимое падение давления по манометрам класса точности 0,15 и 0,6, а также жидкостным манометром падение давления фиксируется в пределах одного деления шкалы',
          'Если в течение испытания давление в газопроводе не меняется, то есть не фиксируется видимое падение давления манометром класса точности 0,6, а по манометрам класса точности 0,15 и 0,4, а также жидкостным манометром падение давления фиксируется в пределах одного деления шкалы',
        ],
        correctAnswers: [
          'Если в течение испытания давление в газопроводе не меняется, то есть не фиксируется видимое падение давления манометром класса точности 0,6, а по манометрам класса точности 0,15 и 0,4, а также жидкостным манометром падение давления фиксируется в пределах одного деления шкалы',
        ],
      },
      {
        code: '63689197',
        text:
          'Какой надлежит принимать ширину ворот автомобильных въездов на земельный участок производственного объекта?',
        answers: ['Не менее 3,0 м', 'Не менее 4,5 м', 'Не менее 4,0 м'],
        correctAnswers: ['Не менее 4,5 м'],
      },
      {
        code: '63689198',
        text:
          'В каких случаях не рекомендуется применение труб из полуспокойной, кипящей углеродистой стали?',
        answers: [
          'Только при наличии вибрационных нагрузок, на подводных переходах, переходах через автомобильные I - III категорий и железные дороги, трамвайные пути и прокладываемых по мостам и гидротехническим сооружениям',
          'Только при изготовлении соединительных деталей, отводов и компенсирующих устройств для газопроводов среднего и высокого давления методом холодного гнутья',
          'Только для подземных газопроводов, прокладываемых в особых грунтовых условиях, на подрабатываемых территориях и в сейсмических районах с сейсмичностью площадки свыше 6 баллов',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63689199',
        text:
          'Какой из перечисленных параметров не учитывается при расчете газопроводов на прочность и устойчивость?',
        answers: [
          'Оптимальное соотношение перепада давления на участке рассчитываемого газопровода',
          'Величину и направление действующих на газопроводы нагрузок',
          'Время действия нагрузок на газопроводы',
        ],
        correctAnswers: [
          'Оптимальное соотношение перепада давления на участке рассчитываемого газопровода',
        ],
      },
      {
        code: '63689200',
        text:
          'С каким резервом по производительности предусматриваются аппараты сетей газораспределения и газопотребления газотурбинных и парогазовых установок в каждой ступени очистки газа?',
        answers: [
          'С пятидесятипроцентным',
          'С тридцатипроцентным',
          'С двадцатипроцентным',
          'С шестидесятипроцентным',
        ],
        correctAnswers: ['С пятидесятипроцентным'],
      },
      {
        code: '63689201',
        text:
          'Какой нормативный документ регламентирует границы зон с особыми условиями использования территории вдоль трассы газопроводов и вокруг других объектов газораспределительной сети, которые должны быть указаны в проектной документации на сети газораспределения?',
        answers: [
          'ФНП "Правила безопасности сетей газораспределения и газопотребления"',
          'Технический регламент о безопасности сетей газораспределения и газопотребления',
          'Правила охраны газораспределительных сетей',
          'СП 62.13330.2011*. Свод правил. Газораспределительные системы. Актуализированная редакция СНиП 42-01-2002 С изменением N 1',
        ],
        correctAnswers: ['Правила охраны газораспределительных сетей'],
      },
      {
        code: '63689202',
        text:
          'К какой категории относятся газопроводы с давлением газа до 0,005 МПа включительно?',
        answers: [
          'Высокого давления 1а категории',
          'Высокого давления 2 категории',
          'Среднего давления',
          'Низкого давления',
        ],
        correctAnswers: ['Низкого давления'],
      },
      {
        code: '63689203',
        text:
          'Чем должны быть испытаны сети газораспределения и сети газопотребления по завершении строительства, реконструкции, монтажа и капитального ремонта?',
        answers: ['Воздухом', 'Аргоном', 'Азотом', 'Любым инертным газом'],
        correctAnswers: ['Воздухом'],
      },
      {
        code: '63689204',
        text:
          'Что должна предусматривать система контроля качества строительно-монтажных работ при сооружении систем газораспределения?',
        answers: [
          'Только проведение производственного контроля качества работ',
          'Только проведение ведомственного контроля за качеством работ и техникой безопасности',
          'Только проведение технического надзора со стороны эксплуатационной организации',
          'Только контроль со стороны органов Ростехнадзора России',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63689205',
        text:
          'На какое расстояние ниже границ прогнозируемого размыва должен проектироваться подземный газопровод для участков, подверженных эрозии?',
        answers: ['На 0,5 м', 'На 1,0 м', 'На 0,7 м', 'На 0,3 м'],
        correctAnswers: ['На 0,5 м'],
      },
      {
        code: '63689206',
        text:
          'Какой документ необходимо получить строительно-монтажной организации до начала производства работ по реконструкции стальных изношенных газопроводов?',
        answers: [
          'Разрешение, выданное местной администрацией',
          'Разрешение, выданное Ростехнадзором',
          'Свидетельство Национального объединения строителей о допуске к работам по реконструкции данного участка',
          'Все перечисленные документы',
        ],
        correctAnswers: ['Разрешение, выданное местной администрацией'],
      },
      {
        code: '63689207',
        text:
          'Норма контрольной опрессовки внутренних газопроводов промышленных, сельскохозяйственных и других производств, котельных, оборудования и газопроводов газорегуляторных пунктов (далее - ГРП), блочных газорегуляторных пунктов (далее - ГРПБ), шкафных регуляторных пунктов (далее - ШРП), газорегуляторных установок (далее - ГРУ):',
        answers: [
          'Величина давления воздуха (инертного газа) при опрессовке - 0,01 МПа, падение давления не должно превышать 0,0001 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке - 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке - 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке - 0,02 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
        ],
        correctAnswers: [
          'Величина давления воздуха (инертного газа) при опрессовке - 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
        ],
      },
      {
        code: '63689208',
        text:
          'Чем необходимо оборудовать помещения установок по комбинированной выработке электроэнергии и тепла?',
        answers: [
          'Только шумопоглощающими устройствами',
          'Только постоянно действующей вентиляцией с механическим побуждением, сблокированной с автоматическим запорным органом, установленным непосредственно на вводе газопровода в помещение',
          'Только системами по контролю загазованности и пожарной сигнализацией, сблокированной с автоматическим запорным органом на вводе в помещение, с выводом сигнала опасности на диспетчерский пульт',
          'Всем перечисленным',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '63689209',
        text:
          'Допускается ли проектирование прокладки наружных газопроводов по железнодорожным мостам?',
        answers: [
          'Допускается проектирование прокладки только газопроводов низкого давления',
          'Не допускается',
          'Допускается проектирование прокладки только газопроводов среднего давления',
          'Допускается проектирование прокладки газопроводов всех категорий давлений',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63689210',
        text:
          'Какие требования к скрытой прокладке газопроводов указаны неверно?',
        answers: [
          'При скрытой прокладке газопроводов допускается пересечение газопроводов каналами других коммуникаций',
          'При прокладке газопроводов в штрабе стены размер последней принимается из условия обеспечения возможности монтажа, эксплуатации и ремонта газопроводов',
          'При прокладке газопроводов в полах монолитной конструкции толщина подстилающего слоя пола под газопроводом, а также расстояние от металлических сеток (или других конструкций, расположенных в полу) принимается не менее 5 см',
          'При прокладке газопроводов в каналах полов конструкция каналов должна исключать возможность распространения газа в конструкции полов и обеспечивать возможность осмотра и ремонта газопроводов',
        ],
        correctAnswers: [
          'При скрытой прокладке газопроводов допускается пересечение газопроводов каналами других коммуникаций',
        ],
      },
      {
        code: '63689211',
        text:
          'Какие расчеты должны выполняться при проектировании газопроводов?',
        answers: [
          'Расчеты границ охранных зон газопроводов',
          'Расчеты на пропускную способность, а также расчеты на прочность и устойчивость газопроводов',
          'Расчеты на прочность и герметичность газопроводов',
        ],
        correctAnswers: [
          'Расчеты на пропускную способность, а также расчеты на прочность и устойчивость газопроводов',
        ],
      },
      {
        code: '63689212',
        text: 'В каком случае допускается установка одного резервуара СУГ?',
        answers: [
          'Если вместимость резервуара 10 м³',
          'При достаточной производительности резервуара',
          'Если по условиям эксплуатации допускаются перерывы в потреблении СУГ на длительное время (не менее месяца)',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: [
          'Если по условиям эксплуатации допускаются перерывы в потреблении СУГ на длительное время (не менее месяца)',
        ],
      },
      {
        code: '63689213',
        text:
          'Каким должно быть расстояние между трубопроводами и силовыми кабелями напряжением до 35 кВ и кабелями связи?',
        answers: ['0,8 м', '0,2 м', '0,4 м', '0,5 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63689214',
        text:
          'Какие типы запорной арматуры рекомендуется предусматривать при проектировании стальных и полиэтиленовых газопроводов?',
        answers: [
          'Только краны конусные сальниковые',
          'Только краны конусные натяжные',
          'Только краны шаровые, задвижки, клапаны (вентили)',
          'Все перечисленные типы',
        ],
        correctAnswers: ['Все перечисленные типы'],
      },
      {
        code: '63689215',
        text:
          'В каком из перечисленных случаев не допускается проектирование прокладки внутренних газопроводов?',
        answers: [
          'Через вентиляционные камеры, шахты и каналы',
          'В помещениях категорий В, Г и Д по взрывопожарной опасности',
          'В складских помещениях категорий Г и Д',
        ],
        correctAnswers: ['Через вентиляционные камеры, шахты и каналы'],
      },
      {
        code: '63689216',
        text:
          'Какой должна быть кратность воздухообмена в закрытых помещениях производственных зданий ГНС и ГНП, в которых обращаются СУГ, в рабочее время?',
        answers: [
          'Не менее 15 обменов в час',
          'Не менее 3 обменов в час',
          'Не менее 10 обменов в час',
          'Не менее 6 обменов в час',
        ],
        correctAnswers: ['Не менее 10 обменов в час'],
      },
      {
        code: '63689217',
        text:
          'Каким должно быть минимальное расстояние между рядами надземных резервуаров для СУГ, размещаемых в два ряда и более?',
        answers: ['2 м', '5 м', '15 м', '10 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63689218',
        text:
          'К каким зданиям допускается пристраивать газорегуляторные пункты?',
        answers: [
          'К газифицируемым производственным зданиям, котельным и общественным зданиям III и IV степеней огнестойкости и класса конструктивной пожарной опасности С1 с помещениями производственного назначения категорий А, Б и В',
          'К газифицируемым производственным зданиям, котельным и общественным зданиям I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д',
          'Только к газифицируемым производственным зданиям и котельным',
        ],
        correctAnswers: [
          'К газифицируемым производственным зданиям, котельным и общественным зданиям I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д',
        ],
      },
      {
        code: '63689219',
        text:
          'Из каких материалов предусматривается противокоррозионная изоляция вертикальных участков подземных газопроводов и футляров (вводы в здания и ГРП, конденсатосборники, гидрозатворы и др.)?',
        answers: [
          'Из полимерных материалов',
          'Из битумной мастики',
          'Из композиционных материалов',
          'Из всех перечисленных материалов',
        ],
        correctAnswers: ['Из полимерных материалов'],
      },
      {
        code: '63689220',
        text:
          'Какой воздухообмен должна обеспечивать вентиляция для помещений котельных, в которых установлено газоиспользующее оборудование, с постоянным присутствием обслуживающего персонала?',
        answers: [
          'Не менее трехкратного в час',
          'Не менее четырехкратного в час',
          'Не менее пятикратного в час',
          'Не менее шестикратного в час',
        ],
        correctAnswers: ['Не менее трехкратного в час'],
      },
      {
        code: '63689221',
        text:
          'Какое расстояние следует принимать от отдельно стоящего ГРПШ с входным давлением газа до 0,3 МПа включительно до здания, для газоснабжения которого оно предназначено?',
        answers: [
          'Не менее 10 м',
          'Не менее 5 м',
          'Не менее 3 м',
          'Расстояние не нормируется, но ГРПШ следует размещать со смещением от проемов зданий на расстояние не менее 1 м',
        ],
        correctAnswers: [
          'Расстояние не нормируется, но ГРПШ следует размещать со смещением от проемов зданий на расстояние не менее 1 м',
        ],
      },
      {
        code: '63689222',
        text:
          'Кем решается вопрос о необходимости установки клапана безопасности (контроллера) расхода газа на участках присоединения к распределительному газопроводу газопроводов-вводов к отдельным зданиям различного назначения, многоквартирным зданиям, котельным и производственным потребителям?',
        answers: [
          'Проектной организацией по согласованию с эксплуатационной организацией',
          'Эксплуатационной организацией',
          'Территориальным органом Ростехнадзора',
          'Специализированной сторонней организацией',
        ],
        correctAnswers: [
          'Проектной организацией по согласованию с эксплуатационной организацией',
        ],
      },
      {
        code: '63689223',
        text:
          'Где запрещается устанавливать запорную арматуру на внутренних газопроводах?',
        answers: [
          'Перед приборами учета газа (если для отключения прибора учета газа нельзя использовать запорную арматуру на вводе)',
          'Перед газоиспользующим оборудованием и контрольно-измерительными приборами',
          'На продувочных газопроводах',
          'На скрытых и транзитных участках газопровода',
        ],
        correctAnswers: ['На скрытых и транзитных участках газопровода'],
      },
      {
        code: '63689224',
        text:
          'Из каких материалов должен выполняться шкаф газорегуляторного пункта?',
        answers: [
          'Из негорючих или трудногорючих материалов',
          'Только из негорючих материалов',
          'Из негорючих, а при соответствующем обосновании - и из горючих материалов',
        ],
        correctAnswers: ['Только из негорючих материалов'],
      },
      {
        code: '63689225',
        text:
          'На каких наружных стенах зданий допускается устанавливать ГРПШ с входным давлением газа до 0,6 МПа включительно?',
        answers: [
          'На наружных стенах производственных зданий, котельных, общественных и бытовых зданий производственного назначения с помещениями категорий В4, Г и Д',
          'На наружных стенах газифицируемых жилых, общественных, административных и бытовых зданий независимо от степени огнестойкости и класса конструктивной пожарной опасности при расходе газа до 50 м³/ч',
          'На наружных стенах газифицируемых жилых, общественных, административных и бытовых зданий не ниже степени огнестойкости III и не ниже класса конструктивной пожарной опасности С1 при расходе газа до 400 м³/ч',
          'На наружных стенах зданий устанавливать не допускается',
        ],
        correctAnswers: [
          'На наружных стенах производственных зданий, котельных, общественных и бытовых зданий производственного назначения с помещениями категорий В4, Г и Д',
        ],
      },
      {
        code: '63689226',
        text:
          'Какие требования установлены к участкам газопроводов, прокладываемых внутри защитных устройств через ограждающие строительные конструкции здания?',
        answers: [
          'Они могут иметь сварные стыки, но не должны иметь фланцевые и резьбовые соединения',
          'Они не должны иметь сварные стыки, фланцевые и резьбовые соединения',
          'Они могут иметь сварные стыки, фланцевые и резьбовые соединения',
        ],
        correctAnswers: [
          'Они не должны иметь сварные стыки, фланцевые и резьбовые соединения',
        ],
      },
      {
        code: '63689227',
        text:
          'Какая норма испытаний на герметичность установлена для газопроводов и газового оборудования ГРП давлением свыше 0,1 до 0,3 МПа включительно?',
        answers: [
          '0,3 МПа, продолжительность испытаний - 12 часов',
          '0,45 МПа, продолжительность испытаний - 24 часа',
          '0,45 МПа, продолжительность испытаний - 12 часов',
          '0,75 МПа, продолжительность испытаний - 1 час',
        ],
        correctAnswers: ['0,45 МПа, продолжительность испытаний - 12 часов'],
      },
      {
        code: '63689228',
        text:
          'Допускается ли исправление дефектов шва стыков стальных газопроводов, выполненных газовой сваркой?',
        answers: [
          'Допускается путем приварки усилительной муфты на дефектный стык',
          'Не допускается',
          'Допускается путем удаления дефектной части и заварки ее заново с последующей проверкой всего сварного стыка радиографическим методом контроля',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63689229',
        text:
          'Что из перечисленного не входит в состав сети газораспределения?',
        answers: [
          'Наружные газопроводы',
          'Сооружения',
          'Технические и технологические устройства',
          'Внутренние газопроводы',
        ],
        correctAnswers: ['Внутренние газопроводы'],
      },
      {
        code: '63689230',
        text:
          'Какое из перечисленных требований к размещению насосов и компрессоров для перемещения жидкой и паровой фаз СУГ по трубопроводам ГНС, ГНП указано верно?',
        answers: [
          'Компрессоры, работающие с воздушным охлаждением, и насосы допускается устанавливать на площадках с устройством над ними навеса и по периметру площадки проветриваемого ограждения',
          'Насосы и компрессоры следует устанавливать на фундаментах, связанных с фундаментами другого оборудования и стенами здания',
          'Пол помещения, где размещаются насосы и компрессоры, рекомендуется предусматривать не менее чем на 0,1 м выше планировочных отметок прилегающей территории',
          'Компрессоры и насосы рекомендуется размещать в отапливаемых помещениях',
        ],
        correctAnswers: [
          'Компрессоры, работающие с воздушным охлаждением, и насосы допускается устанавливать на площадках с устройством над ними навеса и по периметру площадки проветриваемого ограждения',
        ],
      },
      {
        code: '63689231',
        text:
          'Где указываются сроки эксплуатации газопроводов, технических и технологических устройств сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'В проектной документации',
          'В техническом регламенте',
          'В распорядительной документации организации',
          'В Правилах безопасности сетей газораспределения и газопотребления',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63689232',
        text:
          'Что должны иметь контролируемые пункты на ГРС, ГРП (ГРУ) и замерных пунктах систем газораспределения?',
        answers: [
          'Только контур заземления',
          'Только отопительную систему, поддерживающую температуру в помещениях не ниже 5 °С',
          'Только телефонный ввод или каналообразующую аппаратуру радиоканала',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63689233',
        text:
          'Каким образом допускается транзитная прокладка надземных газопроводов? Укажите все правильные ответы.',
        answers: [
          'Газопроводом всех давлений по стенам помещения категории А здания ГРП',
          'По стенам одного жилого здания газопроводом среднего давления номинальным диаметром 80 мм и на расстоянии ниже кровли (карниза) не менее 0,2 м',
          'Газопроводом номинальным диаметром от 100 до 200 мм по стенам административных и бытовых зданий',
          'Газопроводом низкого давления над кровлями общественных зданий номинальным диаметром от 150 до 200 мм',
        ],
        correctAnswers: [
          'Газопроводом всех давлений по стенам помещения категории А здания ГРП',
          'По стенам одного жилого здания газопроводом среднего давления номинальным диаметром 80 мм и на расстоянии ниже кровли (карниза) не менее 0,2 м',
        ],
      },
      {
        code: '63689234',
        text:
          'Какое из приведенных требований к размещению газорегуляторных установок верно?',
        answers: [
          'Давление природного газа на входе в газорегуляторную установку не должно превышать 0,3 МПа',
          'Допускается проектировать размещение газорегуляторных установок в помещениях категорий А и Б по взрывопожарной опасности',
          'Газорегуляторные установки разрешается размещать в помещениях, в которых устанавливается газоиспользующее оборудование, или в смежных помещениях, соединенных с ними открытыми проемами',
        ],
        correctAnswers: [
          'Газорегуляторные установки разрешается размещать в помещениях, в которых устанавливается газоиспользующее оборудование, или в смежных помещениях, соединенных с ними открытыми проемами',
        ],
      },
      {
        code: '63689235',
        text:
          'Какие требования предъявляются к проектированию взрывных предохранительных клапанов, предусматриваемых на горизонтальных участках газоходов от газоиспользующего оборудования?',
        answers: [
          'Площадь каждого взрывного предохранительного клапана должна быть не менее 0,05 м². Взрывные предохранительные клапаны должны быть оборудованы защитными устройствами на случай их срабатывания',
          'Суммарная площадь взрывных предохранительных клапанов должна быть не менее 0,9 м². Взрывные предохранительные клапаны должны быть оборудованы защитными устройствами на случай их срабатывания',
          'Суммарная площадь взрывных предохранительных клапанов должна обеспечивать полный отвод продуктов сгорания газа из газоходов',
        ],
        correctAnswers: [
          'Площадь каждого взрывного предохранительного клапана должна быть не менее 0,05 м². Взрывные предохранительные клапаны должны быть оборудованы защитными устройствами на случай их срабатывания',
        ],
      },
      {
        code: '63689236',
        text:
          'Какой воздухообмен должна обеспечивать вентиляция для помещений котельных, встраиваемых в здания другого назначения?',
        answers: [
          'Не менее пятикратного в час',
          'Не менее трехкратного в час',
          'Не менее шестикратного в час',
          'Не менее четырехкратного в час',
        ],
        correctAnswers: ['Не менее трехкратного в час'],
      },
      {
        code: '63689237',
        text:
          'Какой документацией определяются способы присоединения вновь построенных газопроводов к действующим?',
        answers: [
          'Проектной документацией',
          'Технической документацией',
          'Эксплуатационной документацией',
          'Распоряжением территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63689238',
        text:
          'Какой орган согласовывает размещение промышленных объектов на территориях залегания полезных ископаемых?',
        answers: [
          'Роснедра',
          'Ростехнадзор',
          'Минэнерго России',
          'Росприроднадзор',
        ],
        correctAnswers: ['Ростехнадзор'],
      },
      {
        code: '63689239',
        text:
          'Газопроводы с каким давлением газа допускается размещать совместно с другими трубопроводами и кабелями связи в каналах и тоннелях?',
        answers: [
          'Размещение газопроводов совместно с другими трубопроводами и кабелями связи в каналах и тоннелях не допускается',
          'До 1,6 МПа',
          'До 1,2 МПа',
          'До 0,6 МПа',
        ],
        correctAnswers: ['До 0,6 МПа'],
      },
      {
        code: '63689240',
        text:
          'В каком из перечисленных случаев ударная вязкость металла стальных труб и соединительных деталей с толщиной стенки 5 мм и более должна быть не ниже 30 Дж/см² независимо от района строительства сетей газораспределения и газопотребления? Укажите все правильные ответы.',
        answers: [
          'Для газопроводов, прокладываемых на площадках строительства сейсмичностью свыше 3 баллов',
          'На переходах через естественные преграды и в местах пересечений с железными дорогами и автодорогами категорий I - III и магистральных улиц и дорог',
          'Для газопроводов давлением свыше 0,3 МПа и при номинальном диаметре более 420 мм',
          'Для газопроводов, испытывающих вибрационные нагрузки',
        ],
        correctAnswers: [
          'На переходах через естественные преграды и в местах пересечений с железными дорогами и автодорогами категорий I - III и магистральных улиц и дорог',
          'Для газопроводов, испытывающих вибрационные нагрузки',
        ],
      },
      {
        code: '63689241',
        text:
          'На какие технологические объекты распространяется СП 62.13330.2011. Свод правил. Газораспределительные системы?',
        answers: [
          'На сети газораспределения, газопотребления и объекты СУГ, предназначенные для обеспечения потребителей природным газом давлением до 1,2 МПа включительно и сжиженными углеводородными газами давлением до 1,6 МПа включительно',
          'На магистральные газопроводы давлением свыше 1,2 до 10 МПа включительно',
          'На все перечисленные технологические объекты',
          'На технологические газопроводы, предназначенные для транспортирования газа в пределах химических, нефтехимических и металлургических (кроме производств, где получаются расплавы и сплавы цветных металлов) организаций, на которых природный газ используется в качестве сырья',
        ],
        correctAnswers: [
          'На сети газораспределения, газопотребления и объекты СУГ, предназначенные для обеспечения потребителей природным газом давлением до 1,2 МПа включительно и сжиженными углеводородными газами давлением до 1,6 МПа включительно',
        ],
      },
      {
        code: '63689242',
        text:
          'На каких участках газоходов проектом должна предусматриваться установка предохранительных взрывных клапанов?',
        answers: [
          'На участках газоходов от газоиспользующего оборудования, расположенных горизонтально',
          'На всех участках газоходов от газоиспользующего оборудования',
          'На участках газоходов от газоиспользующего оборудования, расположенных вертикально',
        ],
        correctAnswers: [
          'На участках газоходов от газоиспользующего оборудования, расположенных горизонтально',
        ],
      },
      {
        code: '63689243',
        text:
          'Какой вид контроля не включает в себя строительный контроль, осуществляемый в процессе строительства сетей газораспределения, газопотребления и объектов СУГ?',
        answers: [
          'Допускающий контроль',
          'Входной контроль',
          'Приемочный контроль',
          'Операционный контроль',
        ],
        correctAnswers: ['Допускающий контроль'],
      },
      {
        code: '63689244',
        text:
          'При каких условиях допускается проектирование транзитной прокладки наружных газопроводов?',
        answers: [
          'Для газопроводов низкого и среднего давления - по стенам и над кровлями производственных зданий, выполненных из горючих материалов групп Г1 - Г4',
          'Для газопроводов низкого давления - по территориям складов легковоспламеняющихся и горючих материалов групп Г1 - Г4',
          'Для газопроводов низкого и среднего давления с номинальным размером диаметра не более 100 мм - по стенам одного жилого здания I - III степеней огнестойкости и класса конструктивной пожарной опасности С0 и на расстоянии до кровли не менее 0,2 м',
        ],
        correctAnswers: [
          'Для газопроводов низкого и среднего давления с номинальным размером диаметра не более 100 мм - по стенам одного жилого здания I - III степеней огнестойкости и класса конструктивной пожарной опасности С0 и на расстоянии до кровли не менее 0,2 м',
        ],
      },
      {
        code: '63689245',
        text:
          'Какие меры необходимо предпринимать, если при проведении газовой резки (сварки) на действующем газопроводе произошло снижение или превышение давления газа сверх установленных пределов: ниже 0,0004 МПа или выше 0,002 МПа?',
        answers: [
          'Работы следует прекратить',
          'Необходимо сообщить специалисту, ответственному за проведение газоопасных работ, и продолжить работы под его руководством',
          'Необходимо сделать запись в наряде-допуске и продолжать работы, соблюдая меры безопасности, указанные в инструкции по безопасности',
          'При превышении давления газа работы необходимо прекратить, а при снижении - продолжать под руководством специалиста, ответственного за проведение газоопасных работ',
        ],
        correctAnswers: ['Работы следует прекратить'],
      },
      {
        code: '63689246',
        text:
          'К каким зданиям допускается пристраивать газорегуляторные пункты блочные?',
        answers: [
          'К газифицируемым производственным зданиям, котельным и общественным зданиям I и II степеней огнестойкости и класса конструктивной пожарной опасности С0 с помещениями производственного назначения категорий Г и Д',
          'Газорегуляторные пункты блочные должны размещаться отдельно стоящими',
          'К зданиям, для газификации которых они предназначены',
        ],
        correctAnswers: [
          'Газорегуляторные пункты блочные должны размещаться отдельно стоящими',
        ],
      },
      {
        code: '63689247',
        text:
          'За какое время до начала работ в охранной зоне газораспределительной сети лица, имеющие намерение производить данные работы, обязаны пригласить представителя эксплуатационной организации газораспределительной сети на место производства работ?',
        answers: [
          'Не менее чем за 3 рабочих дня',
          'Не менее чем за 3 календарных дня',
          'Не менее чем за 5 рабочих дней',
          'Не менее чем за 5 календарных дней',
        ],
        correctAnswers: ['Не менее чем за 3 рабочих дня'],
      },
      {
        code: '63689248',
        text:
          'Какой из перечисленных терминов соответствует определению "устройство, автоматически поддерживающее рабочее давление газа, необходимое для оптимальной работы газоиспользующего оборудования" согласно СП 62.13330.2011. Свод правил. Газораспределительные системы?',
        answers: [
          'Клапан безопасности (контроллер) расхода газа',
          'Регулятор-стабилизатор',
          'Контрольно-измерительный прибор',
          'Регулятор-монитор',
        ],
        correctAnswers: ['Регулятор-стабилизатор'],
      },
      {
        code: '63689249',
        text:
          'Каким образом не допускается прокладка газопроводов согласно СП 62.13330.2011. Свод правил. Газораспределительные системы? Укажите все правильные ответы.',
        answers: [
          'На участках переходов через искусственные и естественные преграды, при пересечении сетей инженерно-технического обеспечения',
          'По стенам газифицируемых зданий',
          'Через лоджии и балконы',
          'Через фундаменты зданий и сооружений',
        ],
        correctAnswers: [
          'Через лоджии и балконы',
          'Через фундаменты зданий и сооружений',
        ],
      },
      {
        code: '63689250',
        text:
          'Где рекомендуется предусматривать размещение помещения для окраски баллонов при реконструкции ГНС?',
        answers: [
          'В отдельном здании',
          'Сблокированным с отделением технического освидетельствования баллонов',
          'В здании наполнительного отделения (цеха)',
          'В любом из перечисленных мест',
        ],
        correctAnswers: ['В отдельном здании'],
      },
      {
        code: '63689251',
        text:
          'Что должна обеспечивать автоматика безопасности при ее отключении или неисправности?',
        answers: [
          'Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме',
          'Подачу природного газа на газоиспользующее оборудование в ручном режиме, если отключение автоматики безопасности кратковременное',
          'Подачу природного газа в ручном режиме по обводной линии (байпасу) при условии контроля концентрации природного газа в помещении',
        ],
        correctAnswers: [
          'Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме',
        ],
      },
      {
        code: '63689252',
        text:
          'Что следует предпринять в случае отрицательного результата испытаний газопроводов на герметичность?',
        answers: [
          'Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять только после снижения давления в газопроводе до атмосферного. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует провести повторное испытание',
          'Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять только после снижения давления до атмосферного. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует выдержать газопровод в течение 10 минут под пробным давлением. Герметичность разъемных соединений следует проверить мыльной эмульсией',
          'Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять незамедлительно без снижения давления в газопроводе. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует провести повторное испытание',
        ],
        correctAnswers: [
          'Дефекты, обнаруженные в процессе испытаний газопроводов, следует устранять только после снижения давления в газопроводе до атмосферного. После устранения дефектов, обнаруженных в результате испытания газопровода на герметичность, следует провести повторное испытание',
        ],
      },
      {
        code: '63689253',
        text:
          'Какая норма испытаний на герметичность установлена для надземных газопроводов с давлением до 0,1 МПа включительно?',
        answers: [
          '0,3 МПа, продолжительность испытаний - 12 часов',
          '0,3 МПа, продолжительность испытаний - 1 час',
          '0,6 МПа, продолжительность испытаний - 12 часов',
          '0,45 МПа, продолжительность испытаний - 1 час',
        ],
        correctAnswers: ['0,3 МПа, продолжительность испытаний - 1 час'],
      },
      {
        code: '63689254',
        text:
          'Какое количество сварных стыков от общего числа стыков, сваренных каждым сварщиком на подземных газопроводах всех давлений, прокладываемых под магистральными дорогами и улицами с капитальными типами дорожных одежд, подлежит контролю физическими методами?',
        answers: [
          '10% от общего числа стыков, но не менее одного стыка',
          '100%',
          '25% от общего числа стыков, но не менее одного стыка',
          '50% от общего числа стыков, но не менее одного стыка',
        ],
        correctAnswers: ['100%'],
      },
      {
        code: '63689255',
        text:
          'Каким образом проводится испытание на герметичность внутренних газопроводов из многослойных труб?',
        answers: [
          'Давлением 0,1 МПа в течение 10 мин',
          'Давлением 0,6 МПа в течение 5 мин',
          'Давлением 0,015 МПа в течение 10 мин',
          'Давлением 0,2 МПа в течение 20 мин',
        ],
        correctAnswers: ['Давлением 0,015 МПа в течение 10 мин'],
      },
      {
        code: '63689256',
        text:
          'В каких местах на внутренних газопроводах должна быть установлена запорная арматура?',
        answers: [
          'Только перед газоиспользующим оборудованием и контрольно-измерительными приборами',
          'Только перед горелками и запальниками газоиспользующего оборудования',
          'Только на продувочных газопроводах',
          'Только на вводе газопровода в помещение при размещении в нем ГРУ или прибора учета газа с запорной арматурой на расстоянии более 10 м от места ввода',
          'В каждом из перечисленных мест',
        ],
        correctAnswers: ['В каждом из перечисленных мест'],
      },
      {
        code: '63689257',
        text:
          'Представители какого федерального органа исполнительной власти не входят в состав комиссии по приемке сетей газораспределения и газопотребления в эксплуатацию?',
        answers: ['Минэнерго России', 'Ростехнадзора', 'Росприроднадзора'],
        correctAnswers: ['Минэнерго России'],
      },
      {
        code: '63689258',
        text:
          'Какой вид запорного устройства предусматривают на газопроводах жидкой фазы для слива газа из железнодорожных цистерн в непосредственной близости от места соединения стационарных газопроводов ГНС со сливными устройствами транспортных средств?',
        answers: [
          'Обратный клапан',
          'Скоростной клапан',
          'Штуцер с запорным органом',
          'Любое из перечисленных устройств',
        ],
        correctAnswers: ['Обратный клапан'],
      },
      {
        code: '63689259',
        text:
          'В каких районах не следует размещать промышленные объекты с источниками загрязнения атмосферного воздуха вредными веществами 1-го и 2-го классов опасности?',
        answers: [
          'Во всех перечисленных районах',
          'В районах с повторяющимися туманами частотой 15% за год',
          'В районах с повторяющимися штилями частотой 10% за год',
          'В районах с преобладающими ветрами со скоростью до 1 м/с',
        ],
        correctAnswers: [
          'В районах с преобладающими ветрами со скоростью до 1 м/с',
        ],
      },
      {
        code: '63689260',
        text:
          'Какое максимальное расстояние должно быть от проходных пунктов до входов в санитарно-бытовые помещения основных цехов?',
        answers: ['600 м', '1500 м', '800 м', '500 м'],
        correctAnswers: ['800 м'],
      },
      {
        code: '63689261',
        text:
          'В каком случае допускается прокладка газопроводов в тоннелях, коллекторах и каналах?',
        answers: [
          'Допускается прокладка стальных газопроводов давлением до 0,6 МПа включительно на территории промышленных организаций',
          'Не допускается ни в каком случае',
          'Допускается при отсутствии технической возможности прокладки газопроводов другим способом',
          'Допускается прокладка полиэтиленовых газопроводов давлением до 1,6 МПа включительно',
        ],
        correctAnswers: [
          'Допускается прокладка стальных газопроводов давлением до 0,6 МПа включительно на территории промышленных организаций',
        ],
      },
      {
        code: '63689262',
        text:
          'Какое из перечисленных требований необходимо соблюдать при проектировании заглубления подводного перехода газопровода в дно пересекаемых водных преград?',
        answers: [
          'Заглубление подводного перехода газопровода в дно судоходных рек должно быть не менее чем на 1 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода',
          'Заглубление подводного перехода газопровода в дно всех пересекаемых водных преград должно быть не менее чем на 0,3 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода',
          'Заглубление подводного перехода, проектируемого с применением работ наклонно-направленного бурения, должно быть не менее чем на 1 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода',
        ],
        correctAnswers: [
          'Заглубление подводного перехода газопровода в дно судоходных рек должно быть не менее чем на 1 м ниже профиля дна, прогнозируемого на срок эксплуатации газопровода',
        ],
      },
      {
        code: '63689263',
        text:
          'Какая норма испытаний на герметичность установлена для подземных стальных газопроводов давлением свыше 0,1 до 0,3 МПа включительно с изоляционным покрытием из битумной мастики или полимерной липкой ленты?',
        answers: [
          '0,3 МПа, продолжительность испытаний - 24 часа',
          '0,6 МПа, продолжительность испытаний - 24 часа',
          '0,75 МПа, продолжительность испытаний - 24 часа',
          '1,5 МПа, продолжительность испытаний - 24 часа',
        ],
        correctAnswers: ['0,6 МПа, продолжительность испытаний - 24 часа'],
      },
      {
        code: '63689264',
        text:
          'Какой класс герметичности затворов должна обеспечивать запорная арматура на газопроводах СУГ?',
        answers: [
          'Не ниже класса С',
          'Не ниже класса D',
          'Не ниже класса А',
          'Не ниже класса В',
        ],
        correctAnswers: ['Не ниже класса А'],
      },
      {
        code: '63689265',
        text:
          'Что является документальным подтверждением соответствия построенных или реконструированных сетей газораспределения и газопотребления требованиям, установленным в Техническом регламенте о безопасности сетей газораспределения и газопотребления?',
        answers: [
          'Положительное заключение экспертизы проектной документации на строительство или реконструкцию',
          'Заключение органа государственного строительного надзора',
          'Акт приемки, подписанный всеми членами приемочной комиссии',
          'Документы, подтверждающие соответствие используемых труб, технических устройств, сварочных и изоляционных материалов',
        ],
        correctAnswers: [
          'Акт приемки, подписанный всеми членами приемочной комиссии',
        ],
      },
      {
        code: '63689266',
        text:
          'Какие стыки стальных газопроводов следует отбирать для проверки физическими методами контроля?',
        answers: [
          'Любые по выбору специалиста неразрушающего контроля',
          'Имеющие лучший внешний вид',
          'Имеющие худший внешний вид',
        ],
        correctAnswers: ['Имеющие худший внешний вид'],
      },
      {
        code: '63689267',
        text:
          'На каких участках технологических устройств проектом должна предусматриваться установка продувочных газопроводов?',
        answers: [
          'После первого отключающего устройства на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта, и на обводной линии (байпасе) между двумя отключающими устройствами',
          'Перед первым отключающим устройством и на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта',
          'После первого отключающего устройства и на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта',
        ],
        correctAnswers: [
          'После первого отключающего устройства и на участках газопровода с техническими устройствами, отключаемыми для технического обслуживания и ремонта',
        ],
      },
      {
        code: '63689268',
        text:
          'Какие соединения должны применяться для внутренних полимерных многослойных труб?',
        answers: [
          'Соединения, выполненные прессованием',
          'Тавровые соединения',
          'Нахлесточные соединения',
          'Соединения, выполненные высокотемпературной капиллярной пайкой',
        ],
        correctAnswers: ['Соединения, выполненные прессованием'],
      },
      {
        code: '63689269',
        text:
          'Каким должно быть минимальное расстояние по горизонтали (в свету) от канализации до водопровода из железобетонных труб, прокладываемых в глинистых грунтах?',
        answers: ['3 м', '10 м', '5 м', '1,5 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63689270',
        text:
          'В каких местах не рекомендуется надземная прокладка газопроводов?',
        answers: [
          'По территории поселений на опорах',
          'По стенам зданий внутри жилых дворов и кварталов',
          'На участках переходов через естественные и искусственные преграды',
          'Для межпоселковых газопроводов, расположенных в районах распространения скальных, вечномерзлых грунтов, при наличии оползней, горных выработок, карстов и т.д., где при подземной прокладке по расчетам возможно образование провалов, трещин с напряжениями в газопроводах, превышающими допустимые',
        ],
        correctAnswers: ['По территории поселений на опорах'],
      },
      {
        code: '63689271',
        text:
          'Какое из перечисленных помещений и сооружений относится к производственной зоне территории ГНС?',
        answers: [
          'Механическая мастерская',
          'База хранения с резервуарами СУГ',
          'Котельная',
          'Очистные сооружения',
          'Трансформаторная и (или) дизельная подстанция',
        ],
        correctAnswers: ['База хранения с резервуарами СУГ'],
      },
      {
        code: '63689272',
        text:
          'Какую высоту от уровня земли до низа труб (или поверхности их изоляции), прокладываемых на низких опорах на свободной территории вне проезда транспортных средств и прохода людей, следует принимать при ширине группы труб от 1,5 м и более?',
        answers: ['0,35 м', '0,25 м', '0,75 м', '0,5 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63689273',
        text:
          'Какие требования к размещению инженерных коммуникаций указаны неверно?',
        answers: [
          'На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций',
          'Допускается совместное подземное размещение трубопроводов оборотного водоснабжения, тепловых сетей и газопроводов с технологическими трубопроводами, независимо от параметров теплоносителя и параметров среды в технологических трубопроводах',
          'Не допускается размещение коммуникаций с легковоспламеняющимися и горючими жидкостями и газами под зданиями и сооружениями',
          'Во входных зонах объектов следует предусматривать преимущественно подземное размещение инженерных коммуникаций',
        ],
        correctAnswers: [
          'На территории объектов следует предусматривать преимущественно подземный способ размещения инженерных коммуникаций',
        ],
      },
      {
        code: '63689274',
        text: 'Что не входит в состав оборудования ГРП, ГРУ, ГРПБ и ШРП?',
        answers: [
          'Датчики замера расхода воздуха и запыленности',
          'Запорная арматура, регуляторы давления',
          'Предохранительно-запорные клапаны, предохранительные сбросные клапаны',
          'Приборы замера расхода газа, приборы КИП',
        ],
        correctAnswers: ['Датчики замера расхода воздуха и запыленности'],
      },
      {
        code: '63689275',
        text:
          'С учетом соблюдения каких требований следует, по возможности, размещать здания и сооружения производственных объектов исходя из специфики производства и природных условий?',
        answers: [
          'В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия',
          'Продольные оси аэрационных фонарей и стены зданий с проемами, используемыми для аэрации помещений, следует ориентировать в плане параллельно или под углом не менее 30° к преобладающему направлению ветров летнего периода года',
          'В районах массового переноса песка ветрами наиболее низкие здания необходимо располагать с наветренной стороны площадки перпендикулярно потоку переносимого песка, а также предусматривать полосы зеленых насаждений (шириной не менее 5 м)',
          'Продольные оси здания и световых фонарей следует ориентировать в пределах от 10° до 45° к меридиану',
        ],
        correctAnswers: [
          'В районах со снеговым покровом более 50 см или с количеством переносимого снега более 200 м³ на 1 м фронта переноса в год следует предусматривать сквозное проветривание площадки предприятия',
        ],
      },
      {
        code: '63689276',
        text:
          'Для каких потребителей природного газа максимальное значение величины давления в сетях газопотребления составляет 1,2 МПа?',
        answers: [
          'Газоиспользующее оборудование производственных зданий, в которых величина давления природного газа обусловлена требованиями производства',
          'Газоиспользующее оборудование котельных, пристроенных к жилым зданиям, и крышных котельных жилых зданий',
          'Газоиспользующее оборудование котельных, отдельно стоящих на территории населенных пунктов',
        ],
        correctAnswers: [
          'Газоиспользующее оборудование производственных зданий, в которых величина давления природного газа обусловлена требованиями производства',
        ],
      },
      {
        code: '63689277',
        text:
          'Каким способом могут устанавливаться резервуары для СУГ на ГНС, ГНП?',
        answers: [
          'Только подземным',
          'Только в засыпке грунтом (в обваловании)',
          'Только надземным',
          'Любым способом из перечисленных',
        ],
        correctAnswers: ['Любым способом из перечисленных'],
      },
      {
        code: '63689278',
        text:
          'Подлежат ли исправлению дефектные стыковые соединения полиэтиленовых газопроводов?',
        answers: [
          'Нет, не подлежат и должны быть удалены',
          'Подлежат исправлению путем приварки на дефектный стык усилительной муфты с закладными нагревателями',
          'Подлежат исправлению путем наложения на дефектный стык хомута или бандажа',
        ],
        correctAnswers: ['Нет, не подлежат и должны быть удалены'],
      },
      {
        code: '63689279',
        text:
          'В каком случае не предусматриваются защитные покрытия и устройства, обеспечивающие сохранность газопровода?',
        answers: [
          'В местах входа и выхода из земли',
          'В местах прохода через стенки газовых колодцев, прохода через строительные конструкции здания',
          'В местах прохода под дорогами, железнодорожными и трамвайными путями',
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен - сталь"',
          'Должны быть предусмотрены во всех случаях',
        ],
        correctAnswers: [
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен - сталь"',
        ],
      },
      {
        code: '63689280',
        text:
          'Какие принципы рекомендуется соблюдать при разработке проектных решений выходов и вводов газопроводов?',
        answers: [
          'Конструкция футляра должна обеспечивать тепловую изоляцию полиэтиленовых труб с целью предотвращения охлаждения трубы ниже температуры минус 15 °С',
          'Переход "полиэтилен-сталь" должен располагаться таким образом, чтобы место соединения полиэтиленовой и стальной его частей располагалось не выше уровня земли',
          'Футляр газопровода должен быть герметично заделан с двух концов. Для отбора проб воздуха допускается предусматривать контрольную трубку (штуцер)',
          'Подземный участок ввода газопровода, выполненный "свободным изгибом", должен заключаться в жесткий (пластмассовый) футляр, плотно соединяющийся с вертикальным стальным футляром',
          'Все перечисленные принципы',
        ],
        correctAnswers: ['Все перечисленные принципы'],
      },
      {
        code: '63689281',
        text:
          'Какая устанавливается минимальная глубина заложения подземных резервуаров при использовании испарительных установок?',
        answers: ['0,4 м', '1,0 м', '0,2 м', '0,6 м'],
        correctAnswers: ['0,2 м'],
      },
      {
        code: '63689282',
        text:
          'При каком давлении газа допускается предусматривать присоединение КИП с помощью гибких рукавов длиной не более 3 м?',
        answers: ['До 0,1 МПа', 'До 0,3 МПа', 'До 0,5 МПа', 'До 1,2 МПа'],
        correctAnswers: ['До 0,1 МПа'],
      },
    ],
    63687: [
      {
        code: '63687000',
        text:
          'Какие требования установлены к участкам газопроводов, прокладываемых внутри защитных устройств через ограждающие строительные конструкции здания?',
        answers: [
          'Они не должны иметь сварных стыков, фланцевых и резьбовых соединений',
          'Они могут иметь только фланцевые соединения',
          'Они могут иметь только резьбовые соединения',
        ],
        correctAnswers: [
          'Они не должны иметь сварных стыков, фланцевых и резьбовых соединений',
        ],
      },
      {
        code: '63687001',
        text:
          'На сколько может быть увеличен срок технического расследования причин аварии на опасном производственном объекте?',
        answers: [
          'Не более чем на 15 календарных дней',
          'Не более чем на 5 рабочих дней',
          'Не более чем на 20 рабочих дней',
          'Не более чем на 10 календарных дней',
        ],
        correctAnswers: ['Не более чем на 15 календарных дней'],
      },
      {
        code: '63687002',
        text:
          'В течение какого срока по результатам технического расследования причин аварии руководителем организации издается приказ, определяющий меры по устранению причин и последствий аварии, по обеспечению безаварийной и стабильной работы опасного производственного объекта?',
        answers: [
          'В течение 7 рабочих дней',
          'В течение 2 суток',
          'В течение 5 рабочих дней',
          'В течение 3 суток',
        ],
        correctAnswers: ['В течение 7 рабочих дней'],
      },
      {
        code: '63687003',
        text:
          'С какой периодичностью должна направляться информация об инцидентах, происшедших на опасных производственных объектах, в территориальный орган Ростехнадзора?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полугодие',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в квартал'],
      },
      {
        code: '63687004',
        text:
          'Кем продлевается наряд-допуск на проведение газоопасных работ при невозможности окончить выполняемые работы в установленный срок?',
        answers: [
          'Лицом, выдавшим наряд-допуск на проведение газоопасных работ',
          'Специалистом по промышленной безопасности',
          'Начальником смены',
          'Техническим руководителем организации',
        ],
        correctAnswers: [
          'Лицом, выдавшим наряд-допуск на проведение газоопасных работ',
        ],
      },
      {
        code: '63687005',
        text:
          'В каком из перечисленных случаев должна проводиться проверка герметичности затвора стопорного клапана, ПЗК ГТ?',
        answers: [
          'Только перед каждым пуском ГТУ',
          'Только после ремонта с визуальным контролем',
          'Только периодически - не реже одного раза в месяц',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63687006',
        text:
          'К какой категории относятся газопроводы с давлением газа свыше 0,6 до 1,2 МПа включительно?',
        answers: [
          'Высокого давления 1 категории',
          'Высокого давления 1а категории',
          'Высокого давления 2 категории',
          'Низкого давления',
        ],
        correctAnswers: ['Высокого давления 1 категории'],
      },
      {
        code: '63687007',
        text:
          'Каким образом должны проводиться работы по присоединению газоиспользующего оборудования к действующим внутренним газопроводам с использованием сварки (резки)?',
        answers: [
          'Допускается проводить работы после отключения газопроводов без продувки',
          'Газопроводы должны быть отключены с продувкой их воздухом или инертным газом',
          'Допускается проводить работы без отключения газопроводов при обязательном присутствии лица, ответственного за безопасную эксплуатацию сетей газопотребления',
          'Газопроводы должны быть отключены. Продувку воздухом или инертным газом газопроводов низкого давления проводить необязательно при условии проведения непрерывного контроля уровня газа в воздухе рабочей зоны',
        ],
        correctAnswers: [
          'Газопроводы должны быть отключены с продувкой их воздухом или инертным газом',
        ],
      },
      {
        code: '63687008',
        text:
          'Каким образом определяется окончание продувки газопровода при пуске газа?',
        answers: [
          'Только путем анализа с использованием газоанализаторов',
          'Временем продувки, до отсутствия характерного запаха газа',
          'Путем анализа или сжиганием отобранных проб газа',
        ],
        correctAnswers: ['Путем анализа или сжиганием отобранных проб газа'],
      },
      {
        code: '63687009',
        text:
          'После какого простоя должна проводиться проверка срабатывания предохранительного запорного клапана (далее - ПЗК) котлов и горелок ТЭС перед растопкой котла на газе?',
        answers: [
          'Более десяти суток',
          'Более пяти суток',
          'Более трех суток',
          'Более семи суток',
        ],
        correctAnswers: ['Более трех суток'],
      },
      {
        code: '63687010',
        text:
          'Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных стальных газопроводов?',
        answers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м с каждой стороны газопровода',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 1 м с каждой стороны газопровода',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м с каждой стороны газопровода',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 1,5 м с каждой стороны газопровода',
        ],
        correctAnswers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м с каждой стороны газопровода',
        ],
      },
      {
        code: '63687011',
        text:
          'Что из перечисленного не входит в состав сети газораспределения?',
        answers: [
          'Наружные газопроводы',
          'Сооружения',
          'Технические и технологические устройства',
          'Внутренние газопроводы',
        ],
        correctAnswers: ['Внутренние газопроводы'],
      },
      {
        code: '63687012',
        text:
          'Какие мероприятия должны быть предусмотрены при консервации сетей газораспределения и сетей газопотребления?',
        answers: [
          'Только обеспечивающие их промышленную и экологическую безопасность',
          'Только обеспечивающие их материальную сохранность и предотвращение их разрушения',
          'Только обеспечивающие восстановление их работоспособности после расконсервации',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '63687013',
        text:
          'Чем регламентируется порядок проведения работ по установлению причин инцидентов на опасном производственном объекте?',
        answers: [
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект, согласованным с органами Ростехнадзора',
          'Документом, утвержденным организацией, эксплуатирующей опасные производственные объекты, по согласованию с территориальными органами МЧС России',
        ],
        correctAnswers: [
          'Документом, утвержденным организацией, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63687014',
        text:
          'В течение какого срока составляется акт технического расследования причин аварии на опасном производственном объекте?',
        answers: [
          'Не позднее 30 календарных дней',
          'Не позднее 20 рабочих дней',
          'Не позднее 10 календарных дней',
          'Не позднее 5 рабочих дней',
          'Не позднее 45 календарных дней',
        ],
        correctAnswers: ['Не позднее 30 календарных дней'],
      },
      {
        code: '63687015',
        text:
          'В течение какого времени организация, осуществляющая деятельность по эксплуатации сетей газораспределения и газопотребления, должна хранить проектную и исполнительную документацию?',
        answers: [
          'В течение 50 лет с начала эксплуатации',
          'В течение всего срока эксплуатации опасного производственного объекта (до ликвидации)',
          'В течение 75 лет с момента завершения строительства по проекту',
          'Срок хранения документов зависит от класса опасности объекта',
        ],
        correctAnswers: [
          'В течение всего срока эксплуатации опасного производственного объекта (до ликвидации)',
        ],
      },
      {
        code: '63687016',
        text:
          'При обнаружении какой концентрации газа в помещениях ППГ необходимо организовать дополнительную вентиляцию помещения, выявить причину и незамедлительно устранить утечку газа?',
        answers: [
          '10% и более НКПРП',
          '15% и более НКПРП',
          '20% и более НКПРП',
          '25% и более НКПРП',
        ],
        correctAnswers: ['10% и более НКПРП'],
      },
      {
        code: '63687017',
        text:
          'Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории поселений (населенных пунктов)?',
        answers: ['2,5 МПа', '1,2 МПа', '0,6 МПа', '0,005 МПа'],
        correctAnswers: ['0,6 МПа'],
      },
      {
        code: '63687018',
        text:
          'Какое из перечисленных требований при техническом обслуживании сетей газораспределения и газопотребления ТЭС указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Должно проводиться только с оформлением наряда-допуска на производство газоопасных работ',
          'Должно проводиться бригадой в составе не менее трех человек',
          'Должно проводиться под руководством технического руководителя организации',
          'Должно проводиться с оформлением проекта производства работ',
        ],
        correctAnswers: [
          'Должно проводиться только с оформлением наряда-допуска на производство газоопасных работ',
          'Должно проводиться бригадой в составе не менее трех человек',
        ],
      },
      {
        code: '63687019',
        text:
          'Какое из перечисленных требований должно выполняться при работе в шланговом противогазе?',
        answers: [
          'Длина шланга для противогазов не должна превышать 20 метров',
          'Воздухозаборные патрубки шланговых противогазов должны располагаться с подветренной стороны',
          'Воздухозаборные патрубки шланговых противогазов должны располагаться с наветренной стороны и закрепляться',
          'На шланге противогаза допускаются перегибы, если они не мешают подаче воздуха',
        ],
        correctAnswers: [
          'Воздухозаборные патрубки шланговых противогазов должны располагаться с наветренной стороны и закрепляться',
        ],
      },
      {
        code: '63687020',
        text:
          'Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, отдельно стоящих на территории производственных предприятий?',
        answers: ['2,5 МПа', '1,2 МПа', '0,6 МПа', '0,005 МПа'],
        correctAnswers: ['1,2 МПа'],
      },
      {
        code: '63687021',
        text:
          'К какой категории относятся газопроводы с давлением газа свыше 0,005 до 0,3 МПа включительно?',
        answers: [
          'Высокого давления 1 категории',
          'Высокого давления 2 категории',
          'Среднего давления',
          'Высокого давления 1а категории',
        ],
        correctAnswers: ['Среднего давления'],
      },
      {
        code: '63687022',
        text:
          'Что должна обеспечивать автоматика безопасности при ее отключении или неисправности?',
        answers: [
          'Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме',
          'Блокировку возможности подачи природного газа на газоиспользующее оборудование по резервным линиям в автоматическом режиме',
          'Блокировку возможности подачи природного газа в автоматическом режиме при наличии автоматического газоанализатора в помещении',
        ],
        correctAnswers: [
          'Блокировку возможности подачи природного газа на газоиспользующее оборудование в ручном режиме',
        ],
      },
      {
        code: '63687023',
        text:
          'В каком случае допускается избыточное давление газа в газопроводах котла ТЭС при работе на другом топливе?',
        answers: [
          'При условии обеспечения плотности закрытия отключающих устройств перед горелками котла',
          'При условии нахождения котла в резерве или работе на другом виде топлива',
          'При повторной растопке котла',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'При условии обеспечения плотности закрытия отключающих устройств перед горелками котла',
        ],
      },
      {
        code: '63687024',
        text:
          'Какую информацию должен содержать акт по установлению причин инцидента на опасном производственном объекте?',
        answers: [
          'Всю перечисленную информацию',
          'Только дату и место инцидента, его причины и обстоятельства',
          'Только информацию о продолжительности простоя и мерах по устранению причин инцидента',
          'Только информацию о принятых мерах по ликвидации инцидента, а также информацию о материальном ущербе, в том числе вреде, нанесенном окружающей среде',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '63687025',
        text:
          'Какие сроки обслуживания сетей газораспределения и газопотребления ТЭС указаны верно?',
        answers: [
          'Техническое обслуживание технических устройств проводится по графику, утвержденному персоналом, осуществляющим осмотр оборудования',
          'Техническое обслуживание газопроводов проводится не реже одного раза в три месяца',
          'Проверка параметров срабатывания ПЗК и ПСК в ГРП проводится не реже одного раза в месяц',
          'Проверка параметров срабатывания ПЗК и ПСК в ПРГ должна проводиться не реже одного раза в шесть месяцев, а также после ремонта оборудования ПРГ',
        ],
        correctAnswers: [
          'Проверка параметров срабатывания ПЗК и ПСК в ПРГ должна проводиться не реже одного раза в шесть месяцев, а также после ремонта оборудования ПРГ',
        ],
      },
      {
        code: '63687026',
        text:
          'В каком случае не предусматриваются защитные покрытия и устройства, обеспечивающие сохранность газопровода?',
        answers: [
          'В местах входа и выхода из земли',
          'В местах пересечения с подземными коммуникационными коллекторами',
          'В местах прохода через строительные конструкции здания',
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен-сталь"',
          'В местах пересечения полиэтиленовых газопроводов с нефтепроводами и теплотрассами',
        ],
        correctAnswers: [
          'В местах наличия подземных неразъемных соединений по типу "полиэтилен-сталь"',
        ],
      },
      {
        code: '63687027',
        text:
          'Какое количество человек должно находиться снаружи с наветренной стороны для страховки на каждого работающего в колодцах и котлованах и недопущения к месту работы посторонних лиц при проведении газоопасных работ?',
        answers: ['По два человека', 'По три человека', 'По одному человеку'],
        correctAnswers: ['По два человека'],
      },
      {
        code: '63687028',
        text:
          'Куда организация, на объекте которой произошла авария, после окончания расследования обязана направить экземпляры материалов технического расследования причин аварии?',
        answers: [
          'Во все перечисленные органы',
          'Только в Ростехнадзор (иной федеральный орган исполнительной власти в области промышленной безопасности) или его территориальный орган',
          'Только в другие органы, определенные председателем комиссии',
          'Только в соответствующие органы, представители которых принимали участие в работе комиссии по техническому расследованию',
        ],
        correctAnswers: ['Во все перечисленные органы'],
      },
      {
        code: '63687029',
        text:
          'Каким образом могут производиться работы по предотвращению аварий или ликвидации их последствий на газопроводах эксплуатационной организацией газораспределительной сети?',
        answers: [
          'Без согласования с собственниками, владельцами или пользователями земельных участков и без уведомления их о проводимых работах',
          'С согласованием с собственниками, владельцами или пользователями земельных участков, с уведомлением их о проводимых работах',
          'Без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Без согласования с собственниками, владельцами или пользователями земельных участков, но с уведомлением их о проводимых работах',
        ],
      },
      {
        code: '63687030',
        text:
          'В каком случае допускается проведение ремонтных и наладочных работ в цепях защит, блокировок и сигнализации на действующем оборудовании сетей газораспределения и газопотребления ТЭС без оформления наряда-допуска?',
        answers: [
          'Не допускается ни в каком случае',
          'Допускается, если данные работы будут закончены в течение одной рабочей смены',
          'Допускается, если в ремонтный журнал будет внесена запись о проделанных работах',
          'Допускается, если во время работ обеспечен постоянный контроль качества воздуха газоанализатором',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63687031',
        text:
          'Какое требование должно выполняться при техническом обслуживании и ремонте газоиспользующего оборудования?',
        answers: [
          'Газоиспользующее оборудование должно быть отключено от газопроводов с помощью запорной арматуры, на которой вывешиваются предупреждающие плакаты',
          'Газоиспользующее оборудование должно быть отключено от газопроводов с помощью заглушек',
          'Питание газоиспользующего оборудования должно быть постепенно снижено, газопроводы продуты инертным газом',
        ],
        correctAnswers: [
          'Газоиспользующее оборудование должно быть отключено от газопроводов с помощью заглушек',
        ],
      },
      {
        code: '63687032',
        text:
          'Кем проверяется соответствие исполнительной документации, прилагаемой к плану и нарядам-допускам, фактическому расположению газопровода перед началом газоопасных работ?',
        answers: [
          'Лицом, ответственным за проведение газоопасных работ',
          'Лицом, выдающим наряд-допуск на проведение газоопасных работ',
          'Оперативным руководителем',
          'Специалистом по промышленной безопасности',
        ],
        correctAnswers: [
          'Лицом, ответственным за проведение газоопасных работ',
        ],
      },
      {
        code: '63687033',
        text:
          'Какой из перечисленных терминов относится к газопроводу от места присоединения к распределительному газопроводу до отключающего устройства или наружной конструкции здания либо сооружения потребителя газа, согласно Правилам охраны газораспределительных сетей?',
        answers: [
          'Газопровод-ввод',
          'Распределительные газопроводы',
          'Газорегуляторный пункт',
          'Межпоселковый газопровод',
        ],
        correctAnswers: ['Газопровод-ввод'],
      },
      {
        code: '63687034',
        text:
          'При каком условии должны быть провентилированы с включением всех дымососов, дутьевых вентиляторов и дымососов рециркуляции топка, газоходы отвода продуктов сгорания котла, системы рециркуляции, а также закрытые объемы, в которых размещены коллекторы, перед растопкой котла и после его остановки?',
        answers: [
          'В течение не менее 10 мин при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 25% от номинального',
          'В течение не менее 15 мин при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 10% от номинального',
          'В течение не менее 20 мин при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 20% от номинального',
        ],
        correctAnswers: [
          'В течение не менее 10 мин при открытых шиберах (клапанах) газовоздушного тракта и расходе воздуха не менее 25% от номинального',
        ],
      },
      {
        code: '63687035',
        text:
          'Кто осуществляет государственный контроль (надзор) при эксплуатации сетей газораспределения и газопотребления?',
        answers: [
          'Федеральный государственный строительный надзор',
          'Федеральное агентство по техническому регулированию и метрологии',
          'Федеральная служба по экологическому, технологическому и атомному надзору',
          'Федеральный государственный энергетический надзор',
        ],
        correctAnswers: [
          'Федеральная служба по экологическому, технологическому и атомному надзору',
        ],
      },
      {
        code: '63687036',
        text:
          'Представители какого федерального органа исполнительной власти не входят в состав комиссии по приемке сетей газораспределения и газопотребления в эксплуатацию?',
        answers: ['Ростехнадзора', 'Минэнерго России', 'Росприроднадзора'],
        correctAnswers: ['Минэнерго России'],
      },
      {
        code: '63687037',
        text: 'Куда передается оперативное сообщение об аварии или инциденте?',
        answers: [
          'Только в орган местного самоуправления',
          'Только в территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), осуществляющий надзор за объектом, либо территориальный орган Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности), на территории деятельности которого произошла авария (при эксплуатации передвижных технических устройств (кранов, подъемников (вышек), передвижных котельных, цистерн, вагонов, локомотивов, автомобилей)',
          'Только в страховую организацию, с которой заключен договор обязательного страхования гражданской ответственности в соответствии c законодательством Российской Федерации об обязательном страховании гражданской ответственности владельца опасного производственного объекта за причинение вреда в результате аварии на опасном объекте',
          'Только в федеральный орган исполнительной власти, осуществляющий функции по контролю и надзору в области охраны окружающей среды (при авариях, связанных с выбросом опасных веществ)',
          'Во все перечисленные организации',
        ],
        correctAnswers: ['Во все перечисленные организации'],
      },
      {
        code: '63687038',
        text:
          'Допускается ли эксплуатация газопроводов, зданий и сооружений и технологических устройств сетей газораспределения и газопотребления по истечении срока, указанного в проектной документации?',
        answers: [
          'Эксплуатация не допускается',
          'Эксплуатация может быть допущена после технического диагностирования газопроводов, зданий и сооружений и технологических устройств',
          'Эксплуатация может быть допущена после проведения экспертизы промышленной безопасности',
          'Эксплуатация может быть допущена проведения диагностирования и согласования продления срока эксплуатации Ростехнадзором',
        ],
        correctAnswers: [
          'Эксплуатация может быть допущена после технического диагностирования газопроводов, зданий и сооружений и технологических устройств',
        ],
      },
      {
        code: '63687039',
        text:
          'Когда должны включаться в работу регуляторы давления при прекращении подачи природного газа?',
        answers: [
          'После отключения предохранительного клапана',
          'Немедленно',
          'После выявления причины срабатывания предохранительного запорного клапана и принятия мер по устранению неисправности',
        ],
        correctAnswers: [
          'После выявления причины срабатывания предохранительного запорного клапана и принятия мер по устранению неисправности',
        ],
      },
      {
        code: '63687040',
        text:
          'Каким образом устанавливаются предельные сроки дальнейшей эксплуатации газопроводов?',
        answers: [
          'Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования',
          'Предельные сроки дальнейшей эксплуатации не должны превышать максимальный срок эксплуатации труб, из которых изготовлен газопровод',
          'Предельные сроки дальнейшей эксплуатации газопроводов устанавливаются эксплуатирующей организацией по итогу проведения неразрушающего контроля специализированной лабораторией',
          'Предельные сроки дальнейшей эксплуатации газопроводов не должны превышать четверти всего срока эксплуатации',
        ],
        correctAnswers: [
          'Предельные сроки дальнейшей эксплуатации газопроводов должны устанавливаться по результатам технического диагностирования',
        ],
      },
      {
        code: '63687041',
        text:
          'Кто должен руководить пуском ГТУ после ремонта или проведения регламентных работ?',
        answers: [
          'Начальник цеха или его заместитель',
          'Оперативный руководитель работ',
          'Специалист по промышленной безопасности',
          'Начальник смены',
        ],
        correctAnswers: ['Начальник цеха или его заместитель'],
      },
      {
        code: '63687042',
        text:
          'По указанию какого лица производится снятие заглушек, установленных на ответвлениях к потребителям (вводах), после визуального осмотра и опрессовки газопровода?',
        answers: [
          'Технического руководителя эксплуатирующей организации',
          'Лица, руководящего работами по пуску газа',
          'Руководителя пусконаладочной организации',
          'Начальника цеха',
        ],
        correctAnswers: ['Лица, руководящего работами по пуску газа'],
      },
      {
        code: '63687043',
        text:
          'Допускается ли замена прокладок фланцевых соединений на внутренних газопроводах под давлением газа?',
        answers: [
          'Да, при давлении газа не более 0,005 МПа',
          'Да, при давлении газа 0,0002 - 0,004 МПа',
          'Нет, не допускается',
          'Да, при давлении газа 0,0004 - 0,002 МПа',
        ],
        correctAnswers: ['Нет, не допускается'],
      },
      {
        code: '63687044',
        text:
          'Когда необходимо выполнять капитальный ремонт при эксплуатации пункта подготовки газа сетей газопотребления ГТУ и ПГУ?',
        answers: [
          'При замене оборудования, средств измерений, ремонте здания, систем отопления, вентиляции, освещения на основании дефектных ведомостей',
          'В сроки, указанные в годовом плане обслуживания',
          'Не реже одного раза в пять лет',
          'По итогам проведения комиссионного обследования пункта подготовки газа',
        ],
        correctAnswers: [
          'При замене оборудования, средств измерений, ремонте здания, систем отопления, вентиляции, освещения на основании дефектных ведомостей',
        ],
      },
      {
        code: '63687045',
        text:
          'На какое из перечисленных оборудований сетей газораспределения и газопотребления тепловых электрических станций (далее - ТЭС) должен быть составлен паспорт?',
        answers: [
          'Только на каждый газопровод (наружный и внутренний)',
          'Только на технологическое устройство (пункт редуцирования газа, газорегуляторную установку)',
          'Только на котел',
          'На все перечисленное',
        ],
        correctAnswers: ['На все перечисленное'],
      },
      {
        code: '63687046',
        text:
          'В каких противогазах не допускается проводить газоопасные работы по устранению закупорок в газопроводах?',
        answers: [
          'В фильтрующих противогазах',
          'В шланговых противогазах',
          'В кислородно-изолирующих противогазах',
          'Не регламентируется',
        ],
        correctAnswers: ['В фильтрующих противогазах'],
      },
      {
        code: '63687047',
        text:
          'Какая информация должна содержаться на опознавательных знаках для обнаружения трасс подземных газопроводов?',
        answers: [
          'Только расстояние от газопровода',
          'Только глубина заложения газопровода',
          'Только телефон аварийно-диспетчерской службы',
          'Вся перечисленная информация',
        ],
        correctAnswers: ['Вся перечисленная информация'],
      },
      {
        code: '63687048',
        text:
          'В каком случае пуск газовой турбины осуществляется из холодного состояния? Выберите два правильных варианта ответа.',
        answers: [
          'При температуре металла корпуса турбины менее 150 °C',
          'После монтажа или ремонта',
          'После превышения максимальных значений температуры, указанных изготовителем турбины',
          'При проведении тестового запуска во время технического обслуживания',
        ],
        correctAnswers: [
          'При температуре металла корпуса турбины менее 150 °C',
          'После монтажа или ремонта',
        ],
      },
      {
        code: '63687049',
        text:
          'В каком случае не допускается размещать газорегуляторные пункты шкафные на наружных стенах газифицируемых зданий?',
        answers: [
          'Если входное давление превышает 0,6 МПа',
          'Если входное давление превышает 0,3 МПа',
          'Газорегуляторные пункты допускается размещать на стенах зданий во всех случаях',
        ],
        correctAnswers: ['Если входное давление превышает 0,6 МПа'],
      },
      {
        code: '63687050',
        text:
          'Норма контрольной опрессовки внутренних газопроводов промышленных, сельскохозяйственных и других производств, котельных, оборудования и газопроводов газорегуляторных пунктов (далее - ГРП), блочных газорегуляторных пунктов (далее - ГРПБ), шкафных регуляторных пунктов (далее - ШРП), газорегуляторных установок (далее - ГРУ):',
        answers: [
          'Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке 0,06 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0001 МПа за 1 час',
        ],
        correctAnswers: [
          'Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
        ],
      },
      {
        code: '63687051',
        text:
          'Какие требования установлены Техническим регламентом о безопасности сетей газораспределения и газопотребления к оснащению газоходов от газоиспользующего оборудования взрывными предохранительными клапанами?',
        answers: [
          'Должны устанавливаться на горизонтальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,04 м² каждый; клапаны должны располагаться на расстоянии не более 20 м друг от друга',
          'Должны устанавливаться на горизонтальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,05 м² каждый; клапаны должны быть оборудованы защитными устройствами на случай срабатывания',
          'Должны устанавливаться на вертикальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,05 м² каждый',
        ],
        correctAnswers: [
          'Должны устанавливаться на горизонтальных участках газоходов от газоиспользующей установки; площадь клапанов - не менее 0,05 м² каждый; клапаны должны быть оборудованы защитными устройствами на случай срабатывания',
        ],
      },
      {
        code: '63687052',
        text:
          'При вводе сети газопотребления в эксплуатацию и после выполнения ремонтных работ газопроводы, присоединенные к газоиспользующему оборудованию, должны быть продуты:',
        answers: [
          'Оксидом углерода до вытеснения всего воздуха',
          'Природным газом до вытеснения всего воздуха',
          'Аргоном до вытеснения всего природного газа',
        ],
        correctAnswers: ['Природным газом до вытеснения всего воздуха'],
      },
      {
        code: '63687053',
        text:
          'Как назначается специальная комиссия по техническому расследованию причин аварии?',
        answers: [
          'В зависимости от характера и возможных последствий аварии распорядительным документом руководителя эксплуатирующей организации',
          'В зависимости от характера и возможных последствий аварии правовым актом уполномоченного органа или его территориального органа',
          'В зависимости от характера и возможных последствий аварии распоряжением органа местного самоуправления, на территории которого произошел случай',
        ],
        correctAnswers: [
          'В зависимости от характера и возможных последствий аварии правовым актом уполномоченного органа или его территориального органа',
        ],
      },
      {
        code: '63687054',
        text:
          'Продолжительность работы в кислородно-изолирующем противогазе без перерыва не должна превышать:',
        answers: ['60 минут', '30 минут', '20 минут', '10 минут'],
        correctAnswers: ['30 минут'],
      },
      {
        code: '63687055',
        text:
          'Какой воздухообмен должна обеспечивать вентиляция для помещений котельных, в которых установлено газоиспользующее оборудование, с постоянным присутствием обслуживающего персонала?',
        answers: [
          'Не менее трехкратного в час',
          'Не менее двукратного в час',
          'Не менее семикратного в час',
          'Не менее шестикратного в час',
        ],
        correctAnswers: ['Не менее трехкратного в час'],
      },
      {
        code: '63687056',
        text:
          'На кого возлагается ответственность за наличие у рабочих средств индивидуальной защиты, их исправность и применение?',
        answers: [
          'На руководителя организации',
          'На оперативного руководителя',
          'На специалиста по промышленной безопасности',
          'На лицо, ответственное за проведение работ',
        ],
        correctAnswers: ['На лицо, ответственное за проведение работ'],
      },
      {
        code: '63687057',
        text:
          'С какой периодичностью должна производиться проверка срабатывания устройств защиты, блокировок и сигнализации сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в год',
          'Не реже одного раза в четыре месяца',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в шесть месяцев'],
      },
      {
        code: '63687058',
        text:
          'С какой периодичностью должен проводиться контроль загазованности в помещениях пункта подготовки газа (далее - ППГ) переносным прибором из верхней зоны помещений?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в месяц',
          'Не реже одного раза в сутки',
          'Не реже одного раза в неделю',
        ],
        correctAnswers: ['Не реже одного раза в сутки'],
      },
      {
        code: '63687059',
        text:
          'В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газопотребления?',
        answers: [
          'Если объект транспортирует природный газ между населенными пунктами с давлением, превышающим 0,01 МПа',
          'Если объект транспортирует природный газ к газотурбинным установкам с давлением, не превышающим 5 МПа',
          'Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа',
          'Если объект транспортирует природный газ по территории населенных пунктов исключительно к производственным площадкам, на которых размещены газотурбинные установки с давлением, не превышающим 1,2 МПа',
        ],
        correctAnswers: [
          'Если объект транспортирует природный газ к газоиспользующему оборудованию, размещенному вне зданий, с давлением, не превышающим 1,2 МПа',
        ],
      },
      {
        code: '63687060',
        text:
          'В течение какого времени после получения оперативного сообщения об аварии издается приказ органом, производящим расследование?',
        answers: [
          'Не позднее 60 часов',
          'Не позднее 36 часов',
          'Не позднее 48 часов',
          'Не позднее 24 часов',
        ],
        correctAnswers: ['Не позднее 24 часов'],
      },
      {
        code: '63687061',
        text:
          'С какой периодичностью должен пересматриваться и переутверждаться перечень газоопасных работ?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в четыре года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63687062',
        text:
          'Какая из перечисленных газоопасных работ может выполняться без оформления наряда-допуска по утвержденной производственной инструкции?',
        answers: [
          'Установка и снятие заглушек без снижения давления',
          'Ремонтные работы на внешнем газопроводе с применением сварки',
          'Разрытия в местах утечек газа до их устранения',
          'Техническое обслуживание запорной арматуры, расположенной вне колодцев',
        ],
        correctAnswers: [
          'Техническое обслуживание запорной арматуры, расположенной вне колодцев',
        ],
      },
      {
        code: '63687063',
        text:
          'Каков количественный состав бригады работников, выполняющих газоопасные работы в колодцах, туннелях и коллекторах?',
        answers: [
          'Газоопасные работы выполняются бригадой в составе не менее 2 работников под руководством специалиста',
          'Газоопасные работы выполняются бригадой в составе не менее 3 работников. Руководство поручается наиболее опытному рабочему',
          'Газоопасные работы выполняются бригадой в составе не менее 3 работников под руководством инженерно-технического работника (в составе не менее четырех работников)',
          'Газоопасные работы выполняются бригадой в составе не менее 2 работников. Руководство поручается наиболее опытному рабочему',
        ],
        correctAnswers: [
          'Газоопасные работы выполняются бригадой в составе не менее 3 работников под руководством инженерно-технического работника (в составе не менее четырех работников)',
        ],
      },
      {
        code: '63687064',
        text:
          'Какое из перечисленных требований при пуске газовой турбины (далее - ГТ) указано верно? Выберите 2 варианта ответа.',
        answers: [
          'Камеры сгорания и газовоздушные тракты ГТУ или ПГУ, включая газоходы, КУ, перед розжигом горелочных устройств ГТ должны быть провентилированы (проветрены) с использованием пускового устройства ГТ с обеспечением шестикратного воздухообмена вентилируемых объемов до дымовой трубы',
          'Запорная арматура на газопроводе перед горелочным устройством должна открываться после окончания вентиляции газовоздушного тракта и включения ЗЗУ',
          'После каждой неудачной попытки пуска ГТ зажигание топлива без предварительной вентиляции газовоздушных трактов ГТУ или ПГУ допускается при использовании дополнительных СИЗ',
          'Продолжительность вентиляции должна соответствовать Правилам безопасности сетей газораспределения и газопотребления',
        ],
        correctAnswers: [
          'Камеры сгорания и газовоздушные тракты ГТУ или ПГУ, включая газоходы, КУ, перед розжигом горелочных устройств ГТ должны быть провентилированы (проветрены) с использованием пускового устройства ГТ с обеспечением шестикратного воздухообмена вентилируемых объемов до дымовой трубы',
          'Запорная арматура на газопроводе перед горелочным устройством должна открываться после окончания вентиляции газовоздушного тракта и включения ЗЗУ',
        ],
      },
      {
        code: '63687065',
        text:
          'В какие сроки должны быть устранены неисправности регуляторов давления газа, приводящие к изменению давления газа до значений, выходящих за пределы, установленные в проектной документации, а также к утечкам природного газа?',
        answers: [
          'В течение двух часов после их выявления',
          'Незамедлительно при их выявлении',
          'В течение времени достижения предельной концентрации газа в помещении',
          'В течение суток при обеспечении принудительной вентиляции',
        ],
        correctAnswers: ['Незамедлительно при их выявлении'],
      },
      {
        code: '63687066',
        text:
          'Что из перечисленного должно подвергаться внешнему осмотру перед началом смены? Выберите два правильных варианта ответа.',
        answers: [
          'Технологическое оборудование, трубопроводная арматура, электрооборудование, средства защиты, технологические трубопроводы',
          'Вентиляционные системы',
          'Системы оповещения о пожаре',
          'Средства сигнализации',
        ],
        correctAnswers: [
          'Технологическое оборудование, трубопроводная арматура, электрооборудование, средства защиты, технологические трубопроводы',
          'Вентиляционные системы',
        ],
      },
      {
        code: '63687067',
        text:
          'При какой минимальной концентрации газа в воздухе рабочих зон помещений ТЭС до начала и в процессе выполнения работ по техническому обслуживанию работы должны быть приостановлены?',
        answers: ['10% НКПРП', '5% НКПРП', '15% НКПРП', '20% НКПРП'],
        correctAnswers: ['10% НКПРП'],
      },
      {
        code: '63687068',
        text:
          'Какие из перечисленных действий необходимо произвести при аварийной остановке ГТУ или ПГУ с КУ?',
        answers: [
          'Только прекратить подачу топлива в камеру сгорания ГТ',
          'Только открыть продувочные газопроводы и трубопроводы безопасности на отключенных газопроводах ГТ и КУ',
          'Только отключить паровую турбину и генератор, предусмотренные в составе ПГУ',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63687069',
        text:
          'Что является документальным подтверждением соответствия построенных или реконструированных сетей газораспределения и газопотребления требованиям, установленным в Техническом регламенте о безопасности сетей газораспределения и газопотребления?',
        answers: [
          'Положительное заключение экспертизы промышленной безопасности готового объекта',
          'Заключение органа исполнительной власти, осуществляющего функции по контролю (надзору) в сфере промышленной безопасности',
          'Акт приемки, подписанный всеми членами приемочной комиссии',
          'Акты успешного опробования сетей газораспределения и газопотребления',
        ],
        correctAnswers: [
          'Акт приемки, подписанный всеми членами приемочной комиссии',
        ],
      },
      {
        code: '63687070',
        text:
          'В каком случае построенные или реконструированные газопроводы должны пройти повторное испытание на герметичность?',
        answers: [
          'Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания',
          'Если газопроводы не были введены в эксплуатацию в течение 3 месяцев со дня испытания',
          'Все газопроводы подлежат повторному испытанию непосредственно перед пуском работу',
        ],
        correctAnswers: [
          'Если газопроводы не были введены в эксплуатацию в течение 6 месяцев со дня испытания',
        ],
      },
      {
        code: '63687071',
        text:
          'Чем должны оснащаться предохранительные сбросные клапаны технологических устройств?',
        answers: [
          'Сбросными газопроводами',
          'Пломбой с указанием даты испытания',
          'Молниезащитой и заземлением',
        ],
        correctAnswers: ['Сбросными газопроводами'],
      },
      {
        code: '63687072',
        text:
          'В каком случае разрешается вмешательство в деятельность, связанную с обеспечением безопасной эксплуатации газораспределительных сетей, не уполномоченных на то органов исполнительной власти субъектов Российской Федерации, органов местного самоуправления, общественных организаций, юридических и физических лиц?',
        answers: [
          'В случае выполнения работ по ремонту, обслуживанию или устранению последствий аварий газораспределительной сети на землях лесного фонда или сельскохозяйственного назначения',
          'В случае расположения в охранных зонах газораспределительных сетей других инженерных коммуникаций, принадлежащих иным организациям',
          'В случае пересечения газораспределительных сетей с другими инженерными коммуникациями, принадлежащими иным организациям',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63687073',
        text:
          'Какой документ устанавливает предельные сроки эксплуатации газопроводов, зданий и сооружений, технических и технологических устройств, по истечении которых должно быть обеспечено их техническое диагностирование?',
        answers: [
          'Инструкция по эксплуатации',
          'Проектная документация',
          'Регламент технического обслуживания газопроводов, зданий и сооружений, технических и технологических устройств',
          'ФНП "Правила безопасности сетей газораспределения и газопотребления"',
        ],
        correctAnswers: ['Проектная документация'],
      },
      {
        code: '63687074',
        text:
          'Какие виды работ необходимо выполнять при текущем ремонте запорной арматуры сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'Только разборку запорной арматуры, не обеспечивающей плотность закрытия затворов, с притиркой уплотняющих поверхностей',
          'Только проверку затяжки (крепежа) фланцевых соединений, смену износившихся и поврежденных болтов и прокладок',
          'Только очистку запорной арматуры, ремонт привода и его смазку, набивку сальника, проверку исправности и ремонт приводного устройства',
          'Все перечисленные виды работ',
        ],
        correctAnswers: ['Все перечисленные виды работ'],
      },
      {
        code: '63687075',
        text:
          'Кем должна осуществляться эксплуатация сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'Оперативным персоналом и газовой службой организации',
          'Специализированной организацией по договору, оформленному в соответствии с гражданским законодательством',
          'Специальной комиссией, назначенной приказом руководителя организации',
          'Диспетчерским персоналом',
        ],
        correctAnswers: [
          'Оперативным персоналом и газовой службой организации',
        ],
      },
      {
        code: '63687076',
        text:
          'На какие сети, а также на связанные с ними процессы проектирования, строительства, реконструкции, монтажа, эксплуатации (включая техническое обслуживание, текущий ремонт), капитального ремонта, консервации и ликвидации требования Технического регламента о безопасности сетей газораспределения и газопотребления не распространяются?',
        answers: [
          'На сети газораспределения и газопотребления общественных и бытовых зданий',
          'На сети газораспределения жилых зданий',
          'На сети газопотребления жилых зданий',
          'На сети газопотребления парогазовых и газотурбинных установок давлением свыше 1,2 МПа',
        ],
        correctAnswers: ['На сети газопотребления жилых зданий'],
      },
      {
        code: '63687077',
        text:
          'Кем составляются и утверждаются перечень газоопасных работ и инструкция, определяющая порядок подготовки и безопасность их проведения применительно к производственным условиям, на тепловых электрических станциях (далее – ТЭС)?',
        answers: [
          'Руководителем такой организации или его уполномоченным заместителем',
          'Техническим руководителем',
          'Начальником смены',
          'Специалистом по промышленной безопасности',
        ],
        correctAnswers: [
          'Руководителем такой организации или его уполномоченным заместителем',
        ],
      },
      {
        code: '63687078',
        text:
          'В каком из приведенных случаев объект технического регулирования идентифицируется в качестве сети газораспределения?',
        answers: [
          'Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа',
          'Если объект транспортирует природный газ к производственным площадкам, на которых размещены газотурбинные установки с давлением, не превышающим 1 МПа',
          'Если объект транспортирует природный газ между населенными пунктами с давлением, не превышающим 0,004 МПа',
        ],
        correctAnswers: [
          'Если объект транспортирует природный газ по территориям населенных пунктов с давлением, не превышающим 1,2 МПа',
        ],
      },
      {
        code: '63687079',
        text:
          'Какая из приведенных газоопасных работ выполняется по специальному плану, утвержденному техническим руководителем газораспределительной организации?',
        answers: [
          'Ремонтные работы в ПРГ и ГРУ с применением сварки и газовой резки',
          'Технический осмотр газопроводов и ПРГ',
          'Контроль давления газа в сети газораспределения и газопотребления',
          'Проверка состояния охранных зон газопроводов',
        ],
        correctAnswers: [
          'Ремонтные работы в ПРГ и ГРУ с применением сварки и газовой резки',
        ],
      },
      {
        code: '63687080',
        text:
          'С какой периодичностью проводится визуальный контроль технического состояния (обход) ГРП, внутренних газопроводов котельной, в случае отсутствия сроков в эксплуатационной документации сети газопотребления ТЭС?',
        answers: [
          'Не реже одного раза в смену',
          'Не реже одного раза в месяц',
          'Не реже одного раза в семь дней',
          'Не реже двух раз в месяц',
        ],
        correctAnswers: ['Не реже одного раза в смену'],
      },
      {
        code: '63687081',
        text:
          'Какие из перечисленных документов не входят в состав приемо-сдаточной документации после строительства или реконструкции?',
        answers: [
          'Акты о разбивке и передаче трассы, приемке скрытых работ, приемке специальных работ, приемке внутренней полости газопровода',
          'Технико-эксплуатационная документация изготовителей технических и технологических устройств (паспорта, инструкции по эксплуатации и монтажу)',
          'Проектная документация (исполнительная документация), положительное заключение экспертизы проектной документации',
          'Протоколы проведения испытаний на герметичность сетей газораспределения и газопотребления, проверки сварных соединений и защитных покрытий',
          'Положительное заключение экспертизы промышленной безопасности проектной документации',
        ],
        correctAnswers: [
          'Положительное заключение экспертизы промышленной безопасности проектной документации',
        ],
      },
      {
        code: '63687082',
        text:
          'С каким резервом по производительности предусматриваются аппараты сетей газораспределения и газопотребления газотурбинных и парогазовых установок в каждой ступени очистки газа?',
        answers: [
          'С двадцатипроцентным',
          'С тридцатипроцентным',
          'С шестидесятипроцентным',
          'С пятидесятипроцентным',
        ],
        correctAnswers: ['С пятидесятипроцентным'],
      },
      {
        code: '63687083',
        text:
          'Какое из перечисленных требований к эксплуатации сетей газораспределения и газопотребления ТЭС указано неверно?',
        answers: [
          'Сброс удаленной из газопровода жидкости осуществляется в канализацию на основании эксплуатационной документации',
          'Перед входом в помещение должна быть проведена проверка загазованности помещения переносным сигнализатором',
          'Проверка срабатывания устройств технологических защит и действия сигнализации по максимальному и минимальному давлению газа в газопроводах проводится в сроки, установленные изготовителями, но не реже одного раза в шесть месяцев',
          'Контроль загазованности в помещениях ГРП и котельной должен проводиться стационарными сигнализаторами загазованности или переносным прибором из верхней зоны помещений не реже одного раза в смену',
        ],
        correctAnswers: [
          'Сброс удаленной из газопровода жидкости осуществляется в канализацию на основании эксплуатационной документации',
        ],
      },
      {
        code: '63687084',
        text:
          'Что из перечисленного должна обеспечивать эксплуатирующая организация при эксплуатации подземных газопроводов в соответствии с Техническим регламентом о безопасности сетей газораспределения и газопотребления?',
        answers: [
          'Должна обеспечивать мониторинг и устранение только утечек природного газа',
          'Должна обеспечивать мониторинг и устранение только повреждений изоляции труб газопроводов',
          'Должна обеспечивать мониторинг и устранение только повреждений сооружений, технических и технологических устройств сетей газораспределения и газопотребления',
          'Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей',
        ],
        correctAnswers: [
          'Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей',
        ],
      },
      {
        code: '63687085',
        text:
          'Где указываются сроки эксплуатации газопроводов, технических и технологических устройств сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'В паспорте объекта',
          'В техническом регламенте',
          'В эксплуатационной документации',
          'В проектной документации',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63687086',
        text:
          'Какие охранные зоны установлены Правилами охраны газораспределительных сетей вокруг отдельно стоящих газорегуляторных пунктов?',
        answers: [
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 25 м от границ этих объектов',
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов',
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 20 м от границ этих объектов',
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 15 м от границ этих объектов',
        ],
        correctAnswers: [
          'В виде территории, ограниченной замкнутой линией, проведенной на расстоянии 10 м от границ этих объектов',
        ],
      },
      {
        code: '63687087',
        text:
          'Замена прокладок фланцевых соединений на наружных газопроводах допускается при давлении газа:',
        answers: [
          'Не более 0,01 МПа',
          'Не более 0,1 МПа',
          'Не более 0,02 МПа',
          '0,0002 - 0,004 МПа',
          '0,0004 - 0,002 МПа',
        ],
        correctAnswers: ['0,0004 - 0,002 МПа'],
      },
      {
        code: '63687088',
        text:
          'Норма контрольной опрессовки наружных газопроводов всех давлений:',
        answers: [
          'Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0006 МПа за 1 час',
          'Величина давления воздуха (инертного газа) при опрессовке 0,01 МПа, падение давления не должно превышать 0,0001 МПа за 1 час',
        ],
        correctAnswers: [
          'Величина давления воздуха (инертного газа) при опрессовке 0,02 МПа, падение давления не должно превышать 0,0001 МПа за 1 час',
        ],
      },
      {
        code: '63687089',
        text:
          'В каком случае газотурбинная установка (далее - ГТУ) не должна быть немедленно отключена действием защит или персоналом?',
        answers: [
          'Все ответы неверны',
          'В случае обнаружения трещин газопроводов',
          'В случае закрытого положения заслонки на дымовой трубе котла-утилизатора',
          'В случае возникновения помпажа компрессоров',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63687090',
        text:
          'Какое из приведенных требований должно выполняться при выполнении сварочных работ и газовой резки на газопроводах в колодцах, туннелях, коллекторах?',
        answers: [
          'До начала работ по сварке (резке) газопроводы необходимо отключить и продуть углекислым газом',
          'До начала работ по сварке (резке) газопровода, а также замене арматуры, компенсаторов и изолирующих фланцев в колодцах, туннелях, коллекторах следует снять (демонтировать) перекрытия',
          'При проведении работ по сварке (резке) снятие перекрытий не обязательно, если они не мешают проведению работ',
          'При проведении работ по сварке (резке) установка заглушек на газопроводе при герметичном закрытии арматуры не обязательна',
        ],
        correctAnswers: [
          'До начала работ по сварке (резке) газопровода, а также замене арматуры, компенсаторов и изолирующих фланцев в колодцах, туннелях, коллекторах следует снять (демонтировать) перекрытия',
        ],
      },
      {
        code: '63687091',
        text:
          'В соответствии с требованиями Технического регламента о безопасности сетей газораспределения и газопотребления помещения зданий и сооружений, в которых устанавливается газоиспользующее оборудование, должны быть оснащены системами контроля загазованности с выводом сигнала на пульт управления:',
        answers: [
          'По оксиду углерода и оксиду серы',
          'По метану и оксиду серы',
          'По оксиду серы и двуоксиду углерода',
          'По метану и оксиду углерода',
        ],
        correctAnswers: ['По метану и оксиду углерода'],
      },
      {
        code: '63687092',
        text:
          'В каких пределах допускаются колебания давления газа на выходе при настройке регуляторов в ППГ?',
        answers: [
          'В пределах 10% от рабочего давления',
          'В пределах 15% от рабочего давления',
          'В пределах 20% от рабочего давления',
          'В пределах 25% от рабочего давления',
        ],
        correctAnswers: ['В пределах 10% от рабочего давления'],
      },
      {
        code: '63687093',
        text:
          'Что должны обеспечить сети газораспределения и газопотребления как объекты технического регулирования?',
        answers: [
          'Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации',
          'Экологическую безопасность процесса транспортирования газа с параметрами, определенными проектной документацией',
          'Снабжение газоиспользующих установок природным газом с параметрами по давлению и расходу, определенными проектной документацией',
        ],
        correctAnswers: [
          'Безопасность и энергетическую эффективность транспортирования природного газа с параметрами по давлению и расходу, определенными проектной документацией и условиями эксплуатации',
        ],
      },
      {
        code: '63687094',
        text:
          'Какие требования должны выполняться перед вскрытием турбин, камеры сгорания, стопорного и регулирующего клапана (далее - РК) сетей газопотребления ГТУ и ПГУ?',
        answers: [
          'Все перечисленные требования',
          'Запорные устройства на подводе газа к газовой турбине должны быть закрыты',
          'На газопроводах должны быть установлены заглушки',
          'Газопроводы должны быть освобождены от газа, арматура на продувочных газопроводах должна быть открыта',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63687095',
        text:
          'Кто принимает решение о консервации и расконсервации сетей газораспределения и сетей газопотребления?',
        answers: [
          'Территориальный орган Ростехнадзора',
          'Эксплуатант совместно со специализированной организацией',
          'Организация-собственник с уведомлением федерального органа исполнительной власти, осуществляющего функции по контролю (надзору) в сфере промышленной безопасности',
        ],
        correctAnswers: [
          'Организация-собственник с уведомлением федерального органа исполнительной власти, осуществляющего функции по контролю (надзору) в сфере промышленной безопасности',
        ],
      },
      {
        code: '63687096',
        text:
          'На кого возлагается финансирование расходов на техническое расследование причин аварий?',
        answers: [
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
          'На страховщика гражданской ответственности владельца опасного объекта',
          'На органы исполнительной власти субъекта Российской Федерации, на территории которого произошла авария',
          'На юридическое или должностное лицо, которое в ходе технического расследования было признано виновным в аварии',
        ],
        correctAnswers: [
          'На организацию, эксплуатирующую опасный производственный объект, на котором произошла авария',
        ],
      },
      {
        code: '63687097',
        text: 'Что входит в состав газораспределительных сетей?',
        answers: [
          'Только наружные подземные, наземные и надземные распределительные газопроводы, межпоселковые газопроводы, газопроводы-вводы с установленной на них запорной арматурой',
          'Только внеплощадочные газопроводы промышленных предприятий',
          'Только отдельно стоящие газорегуляторные пункты, расположенные на территории и за территорией населенных пунктов, промышленных и иных предприятий, а также газорегуляторные пункты, размещенные в зданиях, шкафах или блоках',
          'Только устройства электрохимической защиты стальных газопроводов от коррозии и средства телемеханизации газораспределительных сетей, объекты их электропривода и энергоснабжения',
          'Только переходы газопроводов через естественные и искусственные препятствия, в том числе через реки, железные и автомобильные дороги',
          'Входит все перечисленное',
        ],
        correctAnswers: ['Входит все перечисленное'],
      },
      {
        code: '63687098',
        text:
          'В соответствии с какими документами должны проводиться проверка срабатывания предохранительных запорных и сбросных клапанов, техническое обслуживание, текущие ремонты и наладка технологических устройств?',
        answers: [
          'В соответствии с документацией, разработанной эксплуатантом',
          'В соответствии с инструкциями изготовителей',
          'В соответствии с документацией, разработанной специализированной организацией, имеющей лицензию Ростехнадзора',
        ],
        correctAnswers: ['В соответствии с инструкциями изготовителей'],
      },
      {
        code: '63687099',
        text:
          'Кто немедленно извещается о происшедшем повреждении газораспределительной сети или обнаружении утечки газа при выполнении работ в охранной зоне? Выберите все правильные варианты ответа.',
        answers: [
          'Аварийно-диспетчерская служба эксплуатационной организации газораспределительной сети',
          'Лицо, ответственное за проведение работ в охранной зоне газораспределительной сети',
          'Технический руководитель эксплуатирующей организации',
          'Руководитель эксплуатирующей организации',
          'Орган исполнительной власти субъекта Российской Федерации и (или) орган местного самоуправления',
        ],
        correctAnswers: [
          'Аварийно-диспетчерская служба эксплуатационной организации газораспределительной сети',
          'Орган исполнительной власти субъекта Российской Федерации и (или) орган местного самоуправления',
        ],
      },
      {
        code: '63687100',
        text:
          'В течение какого времени должны храниться наряды-допуски на производство газоопасных работ?',
        answers: [
          'Все наряды-допуски хранятся в течение двадцати лет в исполнительно-технической документации',
          'Наряды-допуски на производство газоопасных работ хранятся в течение пяти лет',
          'Время хранения нарядов-допусков на производство газоопасных работ определяется руководителем организации',
          'Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с даты его закрытия',
        ],
        correctAnswers: [
          'Наряд-допуск на производство газоопасных работ (за исключением нарядов-допусков, выдаваемых на первичный пуск газа, врезку в действующий газопровод, отключение газопровода с заваркой наглухо в местах ответвлений) должен храниться не менее одного года с даты его закрытия',
        ],
      },
      {
        code: '63687101',
        text:
          'Каким требованиям должны соответствовать заглушки, устанавливаемые на газопроводы природного газа?',
        answers: [
          'Должны соответствовать номинальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием даты установки',
          'Должны соответствовать максимальному давлению газа в газопроводе, иметь соответствующее цветовое обозначение при условии установки на наружном трубопроводе и клеймо с указанием диаметра газопровода',
          'Должны соответствовать рабочему давлению газа в газопроводе, иметь хвостовики и клеймо с указанием давления газа и даты технического обслуживания',
          'Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода',
        ],
        correctAnswers: [
          'Должны соответствовать максимальному давлению газа в газопроводе, иметь хвостовики, выступающие за пределы фланцев, и клеймо с указанием давления газа и диаметра газопровода',
        ],
      },
      {
        code: '63687102',
        text:
          'Какие охранные зоны установлены Правилами охраны газораспределительных сетей вдоль трасс подземных газопроводов из полиэтиленовых труб, для обозначения трасс которых используется медный провод?',
        answers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 5 м от газопровода со стороны провода и 3 м - с противоположной стороны',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода со стороны провода и 1 м - с противоположной стороны',
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 2 м от газопровода со стороны провода и 0,5 м - с противоположной стороны',
        ],
        correctAnswers: [
          'В виде территории, ограниченной условными линиями, проходящими на расстоянии 3 м от газопровода со стороны провода и 2 м - с противоположной стороны',
        ],
      },
      {
        code: '63687103',
        text:
          'Чьим приказом создается комиссия для технического расследования причин инцидентов на опасном производственном объекте?',
        answers: [
          'Приказом (внутренним распорядительным актом) руководителя организации, эксплуатирующей объект',
          'Приказом территориального органа Ростехнадзора',
          'Приказом Государственной инспекции по труду',
        ],
        correctAnswers: [
          'Приказом (внутренним распорядительным актом) руководителя организации, эксплуатирующей объект',
        ],
      },
      {
        code: '63687104',
        text:
          'При каком условии производятся работы, связанные с обработкой почвы на глубину менее 0,3 м, собственниками, владельцами или пользователями земельных участков в охранной зоне газораспределительной сети?',
        answers: [
          'При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ',
          'При условии письменного разрешения Ростехнадзора',
          'При условии письменного разрешения эксплуатационной организации газораспределительных сетей',
          'При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 5 рабочих дня до начала работ',
        ],
        correctAnswers: [
          'При условии предварительного письменного уведомления эксплуатационной организации не менее чем за 3 рабочих дня до начала работ',
        ],
      },
      {
        code: '63687105',
        text:
          'Кто утверждает графики ремонтов газопроводов и технических устройств сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'Руководитель территориального органа Ростехнадзора',
          'Технический руководитель ТЭС',
          'Руководитель ТЭС',
          'Руководитель подрядной организации, производящей ремонт и обслуживание ТЭС',
        ],
        correctAnswers: ['Технический руководитель ТЭС'],
      },
      {
        code: '63687106',
        text:
          'С какой периодичностью должен проводиться текущий ремонт на внутренних газопроводах ГТУ и ПГУ?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в двенадцать месяцев',
        ],
        correctAnswers: ['Не реже одного раза в двенадцать месяцев'],
      },
      {
        code: '63687107',
        text:
          'В соответствии с требованиями каких документов должны осуществляться эксплуатация, техническое перевооружение, ремонт, консервация и ликвидация сетей газораспределения и газопотребления?',
        answers: [
          'Только Технического регламента о безопасности сетей газораспределения и газопотребления',
          'Только Федерального закона N 116-ФЗ от 21.07.1997 "О промышленной безопасности опасных производственных объектов"',
          'Всех перечисленных документов',
        ],
        correctAnswers: ['Всех перечисленных документов'],
      },
      {
        code: '63687108',
        text:
          'Какая из приведенных газоопасных работ выполняется по специальному плану, утвержденному техническим руководителем газораспределительной организации?',
        answers: [
          'Снижение и восстановление давления газа в газопроводах низкого давления, связанные с отключением потребителей',
          'Отключение и последующее включение подачи газа на промышленные производства',
          'Техническое обслуживание газопроводов без отключения газа',
          'Техническое обслуживание запорной арматуры и компенсаторов, расположенных вне колодцев (без нарушения герметичности корпуса и фланцевых соединений)',
        ],
        correctAnswers: [
          'Отключение и последующее включение подачи газа на промышленные производства',
        ],
      },
      {
        code: '63687109',
        text: 'Продувочный газопровод - газопровод, предназначенный для:',
        answers: [
          'Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств',
          'Для отвода газа или воздуха от предохранительных устройств при повышении давления в газопроводе',
          'Для сброса избыточного давления газа из газопровода и технических устройств',
          'Для вытеснения природного газа из газопровода и технических устройств при их отключении',
        ],
        correctAnswers: [
          'Для вытеснения газа или воздуха (по условиям эксплуатации) из газопроводов и технических устройств',
        ],
      },
      {
        code: '63687110',
        text:
          'По каким существенным признакам сети газораспределения и газопотребления идентифицируются в качестве объекта технического регулирования Технического регламента о безопасности сетей газораспределения и газопотребления?',
        answers: [
          'По назначению',
          'По составу объектов, входящих в сети газораспределения и газопотребления',
          'По давлению газа, определенному в техническом регламенте',
          'По всем указанным признакам, рассматриваемым исключительно в совокупности',
        ],
        correctAnswers: [
          'По всем указанным признакам, рассматриваемым исключительно в совокупности',
        ],
      },
      {
        code: '63687111',
        text:
          'Какую информацию не содержит разрешение на производство работ в охранной зоне газораспределительной сети?',
        answers: [
          'О характере опасных производственных факторов',
          'О расположении трассы газопровода',
          'О наличии и содержании инструкций, которыми необходимо руководствоваться при выполнении конкретных видов работ',
          'Об этапах работ, выполняемых в присутствии и под наблюдением представителя Ростехнадзора',
        ],
        correctAnswers: [
          'Об этапах работ, выполняемых в присутствии и под наблюдением представителя Ростехнадзора',
        ],
      },
      {
        code: '63687112',
        text:
          'Где должен быть установлен манометр для контроля давления в газопроводе при проведении газовой резки и сварки?',
        answers: [
          'Не далее 20 м от места проведения работ',
          'Не далее 30 м от места проведения работ',
          'Не далее 50 м от места проведения работ',
          'Не далее 100 м от места проведения работ',
        ],
        correctAnswers: ['Не далее 100 м от места проведения работ'],
      },
      {
        code: '63687113',
        text:
          'Какая допускается максимальная остаточная объемная доля газа в продувочном воздухе при освобождении газопроводов сетей газораспределения и газопотребления ТЭС от газа?',
        answers: ['30% НКПРП', '50% НКПРП', '20% НКПРП', '40% НКПРП'],
        correctAnswers: ['20% НКПРП'],
      },
      {
        code: '63687114',
        text:
          'За счет чего, в соответствии с требованиями Технического регламента о безопасности сетей газораспределения и газопотребления, обеспечивается энергетическая эффективность построенных, отремонтированных, реконструированных сетей газораспределения и газопотребления?',
        answers: [
          'За счет их герметичности (отсутствия утечек газа)',
          'За счет снижения сопротивления в газопроводе путем уменьшения количества его изгибов',
          'За счет оснащения помещений газоанализаторами',
        ],
        correctAnswers: ['За счет их герметичности (отсутствия утечек газа)'],
      },
      {
        code: '63687115',
        text:
          'При каком условии представители организации, эксплуатирующей опасный производственный объект, принимают участие в техническом расследовании причин аварии?',
        answers: [
          'В качестве членов комиссии по расследованию, но их число не должно превышать 50% от общего числа членов комиссии',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 60% от общего числа членов комиссии',
          'В качестве членов комиссии по расследованию, но их число не должно превышать 70% от общего числа членов комиссии',
        ],
        correctAnswers: [
          'В качестве членов комиссии по расследованию, но их число не должно превышать 30% от общего числа членов комиссии',
        ],
      },
      {
        code: '63687116',
        text:
          'Какие газоопасные работы могут выполняться без оформления наряда-допуска по утвержденным производственным инструкциям?',
        answers: [
          'Замена измерительных приборов на штуцерах трубопровода',
          'Периодически повторяющиеся газоопасные работы, выполняемые постоянным составом бригады',
          'Осмотр колодцев со спуском в них',
          'Установка запорной арматуры',
        ],
        correctAnswers: [
          'Периодически повторяющиеся газоопасные работы, выполняемые постоянным составом бригады',
        ],
      },
      {
        code: '63687117',
        text: 'В какое время суток должны проводиться газоопасные работы?',
        answers: [
          'Газоопасные работы выполняются в любое время суток',
          'Газоопасные работы выполняются в любое время суток и только по наряду-допуску',
          'Газоопасные работы выполняются в любое время суток по письменному распоряжению технического руководителя',
          'Газоопасные работы по локализации и ликвидации аварий выполняются независимо от времени суток под непосредственным руководством инженерно-технического работника',
        ],
        correctAnswers: [
          'Газоопасные работы по локализации и ликвидации аварий выполняются независимо от времени суток под непосредственным руководством инженерно-технического работника',
        ],
      },
      {
        code: '63687118',
        text:
          'В каком случае работы по установке и снятию заглушек сетей газораспределения и газопотребления ТЭС должны выполняться в шланговых противогазах?',
        answers: [
          'При ПДК газа в воздухе рабочей зоны, превышающей 300 мг/м³',
          'При ПДК газа в воздухе рабочей зоны, превышающей 200 мг/м³',
          'При ПДК газа в воздухе рабочей зоны, превышающей 100 мг/м³',
          'При любой концентрации газа',
        ],
        correctAnswers: [
          'При ПДК газа в воздухе рабочей зоны, превышающей 300 мг/м³',
        ],
      },
      {
        code: '63687119',
        text:
          'В каком случае допускается проведение ремонтных и наладочных работ в цепях защит, блокировок и сигнализации на действующем оборудовании сетей газораспределения и газопотребления ТЭС без оформления наряда-допуска (распоряжения)?',
        answers: [
          'Не допускается ни в каком случае',
          'Допускается, если данные работы проводятся под наблюдением оперативного руководителя',
          'Допускается, если в ремонтный журнал будет внесена запись о проделанных работах',
          'Допускается, если во время работ обеспечен постоянный контроль качества воздуха газоанализатором',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63687120',
        text:
          'Объемная доля кислорода в газопроводе после окончания продувки не должна превышать:',
        answers: [
          '3% по объему',
          '5% по объему',
          '1% по объему',
          '2% по объему',
        ],
        correctAnswers: ['1% по объему'],
      },
      {
        code: '63687121',
        text:
          'В какой форме осуществляется оценка соответствия сетей газораспределения и газопотребления требованиям Технического регламента о безопасности сетей газораспределения и газопотребления при эксплуатации?',
        answers: [
          'В форме государственного энергетического надзора',
          'В форме экологического контроля',
          'В форме государственного контроля (надзора)',
          'В форме ежегодной отчетности',
        ],
        correctAnswers: ['В форме государственного контроля (надзора)'],
      },
      {
        code: '63687122',
        text:
          'С какой периодичностью ответственный за выполнение газоопасных работ обязан докладывать о положении дел лицу, выдавшему наряд-допуск, если данные работы проводятся в течение более одного дня?',
        answers: [
          'Ежедневно',
          'В начале и в конце смены',
          'По требованию лица, выдавшего наряд-допуск на проведение газоопасных работ',
          'После каждого этапа выполнения работ',
        ],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63687123',
        text:
          'Что должно быть установлено на продувочном газопроводе внутреннего газопровода?',
        answers: [
          'Только отключающее устройство',
          'Только байпас со штуцером для отбора проб газа',
          'Отключающее устройство, а после него - штуцер с краном для отбора проб газа',
        ],
        correctAnswers: [
          'Отключающее устройство, а после него - штуцер с краном для отбора проб газа',
        ],
      },
      {
        code: '63687124',
        text:
          'Каким образом определяется порядок перевода котла с пылеугольного или жидкого топлива на природный газ?',
        answers: [
          'Производственной инструкцией по эксплуатации котла',
          'Технологической картой',
          'Проектной документацией',
          'Порядком выполнения работ',
        ],
        correctAnswers: ['Производственной инструкцией по эксплуатации котла'],
      },
      {
        code: '63687125',
        text:
          'Какое число членов должно входить в состав комиссии по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: ['Нечетное', 'Четное', 'Не более пяти', 'Не менее трех'],
        correctAnswers: ['Нечетное'],
      },
      {
        code: '63687126',
        text: 'В каких из перечисленных случаев допускается пуск ГТУ?',
        answers: [
          'Все ответы неверны',
          'При температуре масла ниже установленного предела',
          'При давлении топлива выше установленного предела',
          'При отключении хотя бы одной из систем аварийной защиты',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63687127',
        text:
          'Лицо, ответственное за безопасность эксплуатации сетей газораспределения и газопотребления, должно быть назначено приказом:',
        answers: [
          'До приемки сетей газораспределения и газопотребления',
          'После приемки сетей газораспределения и газопотребления',
          'До начала опробования оборудования',
        ],
        correctAnswers: [
          'До приемки сетей газораспределения и газопотребления',
        ],
      },
      {
        code: '63687128',
        text:
          'Предохранительные запорные и предохранительные сбросные клапаны должны обеспечить автоматическое и ручное прекращение подачи или сброс природного газа в атмосферу при изменении давления газа до значений, выходящих за пределы, установленные:',
        answers: [
          'В документации завода-изготовителя',
          'В проектной документации',
          'В исполнительной документации',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63687129',
        text:
          'При каком давлении газа в газопроводе разрешается устранение в газопроводах закупорок путем шуровки металлическими шомполами, заливки растворителей или подачи пара?',
        answers: [
          'Не более 0,1 МПа',
          'Не более 0,01 МПа',
          'Не более 0,005 МПа',
          'Не более 300 МПа',
        ],
        correctAnswers: ['Не более 0,005 МПа'],
      },
      {
        code: '63687130',
        text:
          'Каким должно быть максимальное значение величины давления природного газа в сетях газопотребления газоиспользующего оборудования в котельных, пристроенных к жилым зданиям, крышным котельным жилых зданий?',
        answers: ['2,5 МПа', '1,2 МПа', '0,6 МПа', '0,005 МПа'],
        correctAnswers: ['0,005 МПа'],
      },
      {
        code: '63687131',
        text:
          'К какой категории относятся газопроводы с давлением газа свыше 0,3 до 0,6 МПа включительно?',
        answers: [
          'Высокого давления 1 категории',
          'Высокого давления 2 категории',
          'Высокого давления 1а категории',
          'Среднего давления',
        ],
        correctAnswers: ['Высокого давления 2 категории'],
      },
      {
        code: '63687132',
        text:
          'В каком случае при пересечении надземных газопроводов высоковольтными линиями электропередачи должны быть предусмотрены защитные устройства, предотвращающие падение на газопровод электропроводов при их обрыве?',
        answers: [
          'При напряжении ВЛ свыше 1 кВ',
          'Если газопровод относится к категории высокого давления',
          'При прокладке газопроводов на производственных территориях',
        ],
        correctAnswers: ['При напряжении ВЛ свыше 1 кВ'],
      },
      {
        code: '63687133',
        text:
          'Подтягивание или замена сальниковой набивки запорной арматуры, разборка резьбовых соединений конденсатосборников на наружных газопроводах среднего и высокого давлений допускаются при давлении газа:',
        answers: [
          'Не более 0,01 МПа',
          'Не более 0,1 МПа',
          'Не более 0,02 МПа',
          'Не более 0,03 МПа',
        ],
        correctAnswers: ['Не более 0,1 МПа'],
      },
      {
        code: '63687134',
        text:
          'Допускается ли проведение разборки фланцевых, резьбовых соединений и арматуры на внутренних газопроводах без их отключения?',
        answers: [
          'Разборка фланцевых и резьбовых соединений и арматуры допускается только для наружных газопроводов',
          'Разборка фланцевых и резьбовых соединений и арматуры допускается при снижении давления газа в наружном газопроводе до величины 0,0004 МПа и постоянном контроле газоанализатором',
          'Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода',
          'Разборка фланцевых и резьбовых соединений и арматуры должна производиться только на отключенном участке внутреннего газопровода, установка заглушек необязательна',
        ],
        correctAnswers: [
          'Разборка фланцевых и резьбовых соединений и арматуры должна производиться на отключенном и заглушенном участке внутреннего газопровода',
        ],
      },
      {
        code: '63687135',
        text:
          'При каком минимальном содержании кислорода по объему розжиг горелок не допускается?',
        answers: ['0,01', '0,001', '0,005', '0,009'],
        correctAnswers: ['0,01'],
      },
      {
        code: '63687136',
        text:
          'Какие из перечисленных требований, в соответствии с Техническим регламентом о безопасности сетей газораспределения и газопотребления, должна обеспечить эксплуатирующая организация при эксплуатации надземных газопроводов?',
        answers: [
          'Должна обеспечивать мониторинг и устранение только неисправностей в работе трубопроводной арматуры',
          'Должна обеспечивать мониторинг и устранение только перемещения газопроводов за пределы опор',
          'Должна обеспечивать мониторинг и устранение только повреждения и изгиба опор, нарушающих безопасность газопровода',
          'Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей',
        ],
        correctAnswers: [
          'Должна обеспечивать мониторинг и устранение всех перечисленных неисправностей',
        ],
      },
      {
        code: '63687137',
        text:
          'Кем должны выдаваться распоряжения при проведении газоопасной работы?',
        answers: [
          'Всеми инженерно-техническими работниками, участвующими в проведении работ',
          'Только техническим директором',
          'Только лицом, ответственным за работу',
          'Только лицом, выдавшим наряд-допуск',
        ],
        correctAnswers: ['Только лицом, ответственным за работу'],
      },
      {
        code: '63687138',
        text:
          'С какой периодичностью проводится визуальный контроль технического состояния (обход) надземных газопроводов в случае отсутствия сроков в эксплуатационной документации сети газопотребления ТЭС?',
        answers: [
          'Все ответы неверны',
          'Не реже двух раз в смену',
          'Не реже одного раза в два дня',
          'Не реже четырех раз в месяц',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63687139',
        text:
          'В каком случае проводится проверка настройки и действия предохранительных устройств газоиспользующего оборудования ТЭС?',
        answers: [
          'Перед пуском газа',
          'После длительного (более одного месяца) перерыва в работе оборудования',
          'При эксплуатации в сроки не реже одного раза в три месяца',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Перед пуском газа'],
      },
      {
        code: '63687140',
        text:
          'Какие меры необходимо предпринимать, если при проведении газовой резки (сварки) на действующем газопроводе произошло снижение или превышение давления газа сверх установленных пределов: ниже 0,0004 МПа или выше 0,002 МПа?',
        answers: [
          'Необходимо сделать запись в наряде-допуске, принять дополнительные меры безопасности и продолжить работу',
          'При снижении или превышении давления газа сверх установленных пределов работы проводятся с разрешения работника, выдавшего наряд-допуск',
          'Работы могут быть продолжены только в случае устранения аварийной ситуации',
          'Работы следует прекратить',
        ],
        correctAnswers: ['Работы следует прекратить'],
      },
      {
        code: '63687141',
        text:
          'За какое время до начала работ в охранной зоне газораспределительной сети лица, имеющие намерение производить данные работы, обязаны пригласить представителя эксплуатационной организации газораспределительной сети на место производства работ?',
        answers: [
          'Не менее чем за 14 рабочих дней',
          'Не менее чем за 10 рабочих дней',
          'Не менее чем за 5 рабочих дней',
          'Не менее чем за 3 рабочих дня',
        ],
        correctAnswers: ['Не менее чем за 3 рабочих дня'],
      },
      {
        code: '63687142',
        text:
          'Какие из перечисленных действий разрешается производить на земельных участках, входящих в охранные зоны газораспределительных сетей?',
        answers: [
          'Разрушать берегоукрепительные сооружения, водопропускные устройства, земляные и иные сооружения, предохраняющие газораспределительные сети от разрушений',
          'Перемещать, повреждать, засыпать и уничтожать опознавательные знаки, контрольно-измерительные пункты и другие устройства газораспределительных сетей',
          'Сносить и реконструировать мосты, коллекторы, автомобильные и железные дороги с расположенными на них газораспределительными сетями с предварительным выносом этих газопроводов по согласованию с эксплуатационными организациями',
          'Открывать калитки и двери газорегуляторных пунктов, станций катодной и дренажной защиты, люки подземных колодцев, включать или отключать электроснабжение средств связи, освещения и систем телемеханики',
        ],
        correctAnswers: [
          'Сносить и реконструировать мосты, коллекторы, автомобильные и железные дороги с расположенными на них газораспределительными сетями с предварительным выносом этих газопроводов по согласованию с эксплуатационными организациями',
        ],
      },
      {
        code: '63687143',
        text:
          'Какой документацией определяются способы присоединения вновь построенных газопроводов к действующим?',
        answers: [
          'Производственными инструкциями',
          'Эксплуатационной документацией',
          'Проектной документацией',
          'Технической документацией',
        ],
        correctAnswers: ['Проектной документацией'],
      },
      {
        code: '63687144',
        text:
          'Какое из перечисленных требований к выводу из работы технологических защит, блокировок и сигнализации на работающем оборудовании сетей газораспределения и газопотребления ТЭС указано верно?',
        answers: [
          'Вывод из работы технологических защит, а также технологических блокировок и сигнализации на работающем оборудовании разрешается только в дневное время и не более одной защиты, блокировки или сигнализации одновременно при работе оборудования в переходных режимах, когда необходимость отключения защиты определена инструкцией по эксплуатации основного оборудования',
          'Отключение должно выполняться по заранее составленному плану отключения технического руководителя ТЭС',
          'Вывод из работы технологических защит, обеспечивающих взрывобезопасность, на работающем оборудовании разрешается только в дневное время в присутствии оперативного руководителя',
          'Допускается производство ремонтных и наладочных работ в цепях включенных защит в случае ликвидации аварийной ситуации',
        ],
        correctAnswers: [
          'Вывод из работы технологических защит, а также технологических блокировок и сигнализации на работающем оборудовании разрешается только в дневное время и не более одной защиты, блокировки или сигнализации одновременно при работе оборудования в переходных режимах, когда необходимость отключения защиты определена инструкцией по эксплуатации основного оборудования',
        ],
      },
      {
        code: '63687145',
        text:
          'При какой объемной доле газа в воздухе выполнение газоопасных работ в помещениях ПРГ, колодцах, туннелях, коллекторах не допускается?',
        answers: [
          'Более 20% от НКПРП',
          'Более 10% от НКПРП',
          'Более 15% от НКПРП',
          'Более 5% от НКПРП',
        ],
        correctAnswers: ['Более 20% от НКПРП'],
      },
      {
        code: '63687146',
        text:
          'К какой категории относятся газопроводы с давлением газа до 0,005 МПа включительно?',
        answers: [
          'Высокого давления 1 категории',
          'Высокого давления 1а категории',
          'Среднего давления',
          'Низкого давления',
        ],
        correctAnswers: ['Низкого давления'],
      },
      {
        code: '63687147',
        text:
          'На каком расстоянии от оси газопроводов устанавливаются навигационные знаки в местах пересечения газопроводов с судоходными и сплавными реками и каналами на обоих берегах?',
        answers: ['60 м', '100 м', '50 м', '80 м'],
        correctAnswers: ['100 м'],
      },
      {
        code: '63687148',
        text:
          'Кем осуществляется расчет вреда (экономического и экологического ущерба) от аварии?',
        answers: [
          'Организацией, на объекте которой произошла авария',
          'Федеральной службой по надзору в сфере природопользования',
          'Федеральной службой по экологическому, технологическому и атомному надзору',
          'Специализированной организацией, привлекаемой объектом, на котором произошла авария',
        ],
        correctAnswers: ['Организацией, на объекте которой произошла авария'],
      },
      {
        code: '63687149',
        text:
          'По решению кого из должностных лиц должна быть разгружена и остановлена ГТУ в случаях обледенения воздухозаборного устройства, если не удается устранить обледенение при работе ГТУ под нагрузкой?',
        answers: [
          'Руководителя ТЭС',
          'Технического руководителя ТЭС',
          'Руководителя структурного подразделения ТЭС',
          'Оперативного дежурного ТЭС',
        ],
        correctAnswers: ['Технического руководителя ТЭС'],
      },
      {
        code: '63687150',
        text:
          'Какое из приведенных требований должно выполняться при ремонтных работах в загазованной среде?',
        answers: [
          'Применение инструмента с рабочей частью, выполненной из цветного металла, не допускается',
          'Электрический инструмент, дающий искрение, возможно применять при наличии защитных устройств',
          'Обувь у лиц, выполняющих газоопасные работы в колодцах, помещениях ПРГ ГРУ, не должна иметь стальных подковок и гвоздей',
          'При выполнении газоопасных работ следует использовать переносные светильники во взрывозащищенном исполнении с напряжением до 50 В',
        ],
        correctAnswers: [
          'Обувь у лиц, выполняющих газоопасные работы в колодцах, помещениях ПРГ ГРУ, не должна иметь стальных подковок и гвоздей',
        ],
      },
      {
        code: '63687151',
        text:
          'Кому предоставляется право выдачи нарядов-допусков на производство газоопасных работ?',
        answers: [
          'Руководителю организации',
          'Специалисту по промышленной безопасности',
          'Оперативным руководителям подразделений, чьи работники производят газоопасные работы',
          'Работникам, назначенным распорядительным документом по организации, из числа руководящих работников и инженерно-технических работников, осуществляющих эксплуатацию сетей газораспределения или газопотребления, аттестованных в установленном порядке и имеющих опыт работы на объектах сетей газораспределения и газопотребления не менее одного года',
        ],
        correctAnswers: [
          'Работникам, назначенным распорядительным документом по организации, из числа руководящих работников и инженерно-технических работников, осуществляющих эксплуатацию сетей газораспределения или газопотребления, аттестованных в установленном порядке и имеющих опыт работы на объектах сетей газораспределения и газопотребления не менее одного года',
        ],
      },
      {
        code: '63687152',
        text: 'Какой документ выдается на производство газоопасных работ?',
        answers: ['Талон', 'Поручение', 'Распоряжение', 'Наряд-допуск'],
        correctAnswers: ['Наряд-допуск'],
      },
      {
        code: '63687153',
        text:
          'Когда после окончания сварки последнего стыка разрешается производить испытания газопроводов из полиэтиленовых труб?',
        answers: [
          'Не ранее чем через 24 часа',
          'Не ранее чем через 36 часов',
          'Не ранее чем через 12 часов',
          'Не ранее чем через 72 часа',
        ],
        correctAnswers: ['Не ранее чем через 24 часа'],
      },
      {
        code: '63687154',
        text:
          'При каком содержании кислорода в газовоздушной смеси розжиг горелок не допускается?',
        answers: [
          'Более 0,3% по объему',
          'Более 0,9% по объему',
          'Более 1,0% по объему',
        ],
        correctAnswers: ['Более 1,0% по объему'],
      },
      {
        code: '63687155',
        text:
          'С какой периодичностью должны осматриваться технологическое оборудование, газопроводы, арматура, электрооборудование, вентиляционные системы, средства измерений, противоаварийные защиты, блокировки и сигнализации в производственной зоне ППГ?',
        answers: ['Ежесуточно', 'Ежесменно', 'Ежемесячно', 'Еженедельно'],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63687156',
        text:
          'В течение какого срока передается оперативное сообщение об аварии, инциденте на опасном производственном объекте?',
        answers: [
          'В течение 24 часов с момента возникновения аварии, инцидента',
          'В течение 36 часов с момента возникновения аварии, инцидента',
          'В течение 12 часов с момента возникновения аварии, инцидента',
          'Немедленно',
        ],
        correctAnswers: [
          'В течение 24 часов с момента возникновения аварии, инцидента',
        ],
      },
      {
        code: '63687157',
        text:
          'Каким документом по газораспределительной организации или организации, имеющей собственную газовую службу, назначаются работники, имеющие право выдачи нарядов-допусков к выполнению газоопасных работ?',
        answers: [
          'Распоряжением технического руководителя',
          'Распоряжением начальника территориального отдела Ростехнадзора',
          'Распорядительным документом по организации',
        ],
        correctAnswers: ['Распорядительным документом по организации'],
      },
      {
        code: '63687158',
        text:
          'В каком случае дожимающие компрессоры сетей газопотребления ГТУ и ПГУ подлежат аварийной остановке?',
        answers: [
          'Во всех перечисленных случаях',
          'Только в случае неисправности механических передач и приводов',
          'Только в случае вибрации, посторонних шумов',
          'Только в случае изменения допустимых параметров масла и воды',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63687159',
        text:
          'При соблюдении каких требований должна производиться разборка (замена) установленного на наружных и внутренних газопроводах оборудования?',
        answers: [
          'При полном отключении и продувке трубопровода установка заглушек необязательна',
          'Только на отключенном участке газопровода с установкой заглушек',
          'На наружном газопроводе разрешается разборка и замена оборудования без его продувки',
          'На отключенном участке газопровода установка заглушек необязательна, если герметичность закрытия обеспечена запорной арматурой',
        ],
        correctAnswers: [
          'Только на отключенном участке газопровода с установкой заглушек',
        ],
      },
      {
        code: '63687160',
        text:
          'Каким должно быть давление природного газа на входе в газорегуляторную установку?',
        answers: [
          'Не должно превышать 1,2 МПа',
          'Не должно превышать 0,3 МПа',
          'Не должно превышать 1,0 МПа',
          'Не должно превышать 0,6 МПа',
        ],
        correctAnswers: ['Не должно превышать 0,6 МПа'],
      },
      {
        code: '63687161',
        text:
          'Какой инструмент следует применять при ремонтных работах в загазованной среде?',
        answers: [
          'Специальных требований к инструменту не предусмотрено',
          'Следует применять инструмент из цветного металла, исключающий искрообразование. При применении инструментов из черного металла их рабочая часть должна обильно смазываться солидолом или другой аналогичной смазкой. Применение электрических инструментов, дающих искрение, не допускается',
          'Следует применять электрический инструмент, дающий искрение, только при наличии защитных устройств',
        ],
        correctAnswers: [
          'Следует применять инструмент из цветного металла, исключающий искрообразование. При применении инструментов из черного металла их рабочая часть должна обильно смазываться солидолом или другой аналогичной смазкой. Применение электрических инструментов, дающих искрение, не допускается',
        ],
      },
      {
        code: '63687162',
        text:
          'Кто возглавляет специальную комиссию по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: [
          'Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа',
          'Работник из числа руководящего персонала организации, эксплуатирующей опасный производственный объект',
          'Общественный инспектор в области промышленной безопасности',
          'Представитель органа исполнительной власти субъекта Российской Федерации',
        ],
        correctAnswers: [
          'Представитель Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа',
        ],
      },
      {
        code: '63687163',
        text:
          'В какие сроки проводится текущий ремонт с разборкой регуляторов давления, предохранительных клапанов и фильтров сетей газораспределения и газопотребления ТЭС?',
        answers: [
          'В сроки, установленные в эксплуатационной документации, но не реже одного раза в двенадцать месяцев, если иное не установлено эксплуатационной документацией организации-изготовителя',
          'В сроки, установленные в эксплуатационной документации, но не реже одного раза в четыре месяца',
          'В сроки, установленные в эксплуатационной документации, но не реже одного раза в шесть месяцев, если иное не установлено эксплуатационной документацией организации-изготовителя',
          'В сроки, установленные в эксплуатационной документации, но не реже одного раза в два года',
        ],
        correctAnswers: [
          'В сроки, установленные в эксплуатационной документации, но не реже одного раза в двенадцать месяцев, если иное не установлено эксплуатационной документацией организации-изготовителя',
        ],
      },
      {
        code: '63687164',
        text:
          'В каком случае допускается проведение ремонтных и наладочных работ в целях защит, блокировок и сигнализации на действующем оборудовании сетей газораспределения и газопотребления ТЭС без оформления наряда-допуска?',
        answers: [
          'Допускается, если данные работы проводятся под наблюдением оперативного руководителя',
          'Допускается, если в ремонтный журнал будет внесена запись о проделанных работах',
          'Допускается, если во время работ обеспечен постоянный контроль качества воздуха газоанализатором',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63687165',
        text:
          'В соответствии с чем устанавливается периодичность ремонтов оборудования ГТУ и ПГУ?',
        answers: [
          'В соответствии с технической документацией',
          'В соответствии с графиками, утвержденными техническим руководителем ТЭС',
          'В соответствии с техническим заданием',
          'В соответствии с эксплуатационной документацией',
        ],
        correctAnswers: [
          'В соответствии с графиками, утвержденными техническим руководителем ТЭС',
        ],
      },
      {
        code: '63687166',
        text:
          'На какие организации требования ФНП "Правила безопасности сетей газораспределения и газопотребления" не распространяются?',
        answers: [
          'Осуществляющие деятельность по техническому диагностированию и перевооружению оборудования сетей газораспределения и газопотребления',
          'Осуществляющие деятельность по текущему и капитальному ремонту сетей газораспределения и газопотребления',
          'Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления',
        ],
        correctAnswers: [
          'Осуществляющие деятельность по проектированию, строительству и реконструкции сетей газораспределения и газопотребления',
        ],
      },
      {
        code: '63687167',
        text:
          'С какой периодичностью производят очистку и восстановление окраски газопроводов и запорной арматуры при текущем ремонте надземных газопроводов ТЭС?',
        answers: [
          'Не реже одного раза в десять лет',
          'Не реже одного раза в семь лет',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в пять лет'],
      },
      {
        code: '63687168',
        text:
          'В каком случае не допускается эксплуатация сети газопотребления?',
        answers: [
          'Только при неисправности газоиспользующего оборудования',
          'Только с отключенными технологическими защитами, блокировками, предусмотренными проектом',
          'Только с отключенными сигнализацией и контрольно-измерительными приборами, предусмотренными проектом',
          'Эксплуатация не допускается в любом из перечисленных случаев',
        ],
        correctAnswers: [
          'Эксплуатация не допускается в любом из перечисленных случаев',
        ],
      },
      {
        code: '63687169',
        text:
          'Какое из перечисленных требований при выводе из работы технологических защит ГТУ и ПГУ указано неверно?',
        answers: [
          'Запорная арматура на продувочных газопроводах и газопроводах безопасности после отключения ГТУ должна постоянно находиться в открытом положении',
          'Вывод из работы технологических защит, обеспечивающих взрывобезопасность, на работающем оборудовании запрещается',
          'Отключение должно выполняться по письменному распоряжению технического руководителя ТЭС в оперативном журнале с обязательным уведомлением руководителя ТЭС',
          'Вывод из работы других технологических защит, а также технологических блокировок и сигнализации на работающем оборудовании разрешается только в светлое время суток',
        ],
        correctAnswers: [
          'Отключение должно выполняться по письменному распоряжению технического руководителя ТЭС в оперативном журнале с обязательным уведомлением руководителя ТЭС',
        ],
      },
      {
        code: '63687170',
        text:
          'Каким образом должна проверяться герметичность резьбовых и фланцевых соединений, которые разбирались для устранения закупорок?',
        answers: [
          'Акустическим методом',
          'Манометрическим методом',
          'Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)',
          'Любым из указанных способов',
        ],
        correctAnswers: [
          'Мыльной эмульсией или с помощью высокочувствительных газоанализаторов (течеискателей)',
        ],
      },
      {
        code: '63687171',
        text:
          'С какой периодичностью должно проводиться техническое обслуживание газопроводов и технических устройств ППГ?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в год',
          'Не реже одного раза в десять месяцев',
          'Не реже одного раза в шесть месяцев',
        ],
        correctAnswers: ['Не реже одного раза в шесть месяцев'],
      },
      {
        code: '63687172',
        text:
          'В каком случае допускается проведение ремонтных и наладочных работ защит, блокировок и сигнализации на действующем оборудовании ГТУ и ПГУ без оформления наряда-допуска?',
        answers: [
          'По письменному разрешению технического руководителя организации',
          'Если данные работы проводятся в течение не более 1 часа',
          'При внесении определенной записи в ремонтный журнал',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63687173',
        text:
          'Какие меры необходимо предпринять во избежание превышения давления газа в газопроводе при проведении газовой сварки или резки на действующем наружном газопроводе?',
        answers: [
          'Избыточное давление следует сбрасывать в вентиляционную систему',
          'Избыточное давление следует сбрасывать только в конденсатосборники',
          'Избыточное давление следует сбрасывать через фильтрующую установку',
          'Избыточное давление следует сбрасывать на продувочный газопровод',
        ],
        correctAnswers: [
          'Избыточное давление следует сбрасывать на продувочный газопровод',
        ],
      },
      {
        code: '63687174',
        text:
          'Каким образом назначается комиссия по техническому расследованию причин аварии на опасном производственном объекте?',
        answers: [
          'Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии',
          'Приказом организации, эксплуатирующей опасный производственный объект, в течение 12 часов с момента возникновения аварии',
          'Приказом органа исполнительной власти субъекта Российской Федерации, на территории которого произошла авария, в срок не позднее 24 часов после получения оперативного сообщения об аварии',
          'Приказом Ростехнадзора или его территориального органа в течение 24 часов с момента возникновения аварии',
        ],
        correctAnswers: [
          'Приказом Ростехнадзора (иного федерального органа исполнительной власти в области промышленной безопасности) или его территориального органа в срок не позднее 24 часов после получения оперативного сообщения об аварии',
        ],
      },
      {
        code: '63687175',
        text:
          'В какой документации устанавливаются сроки эксплуатации газопроводов, по истечении которых должно проводиться их техническое диагностирование?',
        answers: [
          'В инструкции по эксплуатации, разработанной эксплуатантом газопровода',
          'В декларации промышленной безопасности на газопровод',
          'В проектной документации',
        ],
        correctAnswers: ['В проектной документации'],
      },
      {
        code: '63687176',
        text:
          'Какое требование при проведении контрольной опрессовки оборудования сетей газораспределения и газопотребления ТЭС указано верно? Выберите два правильных варианта ответа.',
        answers: [
          'Оборудование и газопроводы ГРП должны подвергаться контрольной опрессовке под давлением 0,01 МПа',
          'Результаты контрольной опрессовки должны записываться в наряде-допуске на проведение газоопасных работ',
          'При значениях падения давления, превышающих допустимые нормы, пуск газа и снятие заглушек на газопроводах разрешаются до устранения причин сверхнормативного падения давления под наблюдением лица, ответственного за производство работ',
          'Подземные и надземные (наружные) газопроводы независимо от расчетного давления подлежат контрольной опрессовке под давлением 0,03 МПа',
        ],
        correctAnswers: [
          'Оборудование и газопроводы ГРП должны подвергаться контрольной опрессовке под давлением 0,01 МПа',
          'Результаты контрольной опрессовки должны записываться в наряде-допуске на проведение газоопасных работ',
        ],
      },
      {
        code: '63687177',
        text:
          'По завершении каких работ осуществляется приемка сети газопотребления в эксплуатацию?',
        answers: [
          'По завершении пусконаладочных работ',
          'По завершении строительных работ',
          'По завершении строительных, монтажных работ, а также пусконаладочных работ и комплексного опробования оборудования',
          'По завершении строительных, монтажных работ, а также пусконаладочных работ',
        ],
        correctAnswers: [
          'По завершении строительных, монтажных работ, а также пусконаладочных работ и комплексного опробования оборудования',
        ],
      },
      {
        code: '63687178',
        text:
          'В какой срок материалы технического расследования аварии направляются территориальным органом Ростехнадзора в центральный аппарат Ростехнадзора?',
        answers: [
          'В двухнедельный срок',
          'В недельный срок',
          'В месячный срок',
        ],
        correctAnswers: ['В двухнедельный срок'],
      },
      {
        code: '63687179',
        text:
          'Чем должны оснащаться технологические устройства систем газораспределения и газопотребления?',
        answers: [
          'Молниезащитой, заземлением и вентиляцией',
          'Только заземлением',
          'Только вентиляцией',
          'Только молниезащитой',
        ],
        correctAnswers: ['Молниезащитой, заземлением и вентиляцией'],
      },
      {
        code: '63687180',
        text:
          'Какое из перечисленных требований при проведении газоопасных работ на сетях газораспределения и газопотребления указано верно?',
        answers: [
          'Все перечисленные требования',
          'При газовой резке (сварке) на действующих газопроводах во избежание большого пламени места выхода газа затираются шамотной глиной с асбестовой крошкой',
          'Вблизи мест проведения газоопасных работ вывешиваются или выставляются предупредительные знаки "Огнеопасно - газ"',
          'Котлованы должны иметь размеры, удобные для проведения работ и эвакуации рабочих',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63687181',
        text:
          'Какая из перечисленных газоопасных работ может выполняться бригадой из двух рабочих, руководство которой поручается наиболее квалифицированному рабочему?',
        answers: [
          'Проведение работ в траншеях и туннелях',
          'Установка и снятие заглушек на действующих газопроводах',
          'Проверка и откачка конденсата из конденсатосборников',
          'Проведение сварочных работ на ремонтируемых участках',
        ],
        correctAnswers: [
          'Проверка и откачка конденсата из конденсатосборников',
        ],
      },
      {
        code: '63687182',
        text:
          'В герметичности шлангового противогаза перед выполнением работ убеждаются:',
        answers: [
          'Путем проведения тестирования до передачи противогаза в работу',
          'Путем визуальной проверки на предмет наличия трещин на противогазе и трубке',
          'При надетом противогазе путем зажима конца гофрированной трубки',
          'При надетом противогазе путем проверки отсутствия конденсата на стеклах маски',
          'При надетом противогазе путем проверки отсутствия подсосов в маске и перегибов и защемлений гофрированной трубки',
        ],
        correctAnswers: [
          'При надетом противогазе путем зажима конца гофрированной трубки',
        ],
      },
      {
        code: '63687183',
        text:
          'Кем должны выполняться испытания на прочность и герметичность газопроводов ТЭС после окончания выполнения работ по техническому обслуживанию?',
        answers: [
          'Работниками, выполнившими ремонтные работы',
          'Специализированной организацией',
          'Работниками, эксплуатирующими оборудование',
          'Специалистами внутреннего инспекционного контроля',
        ],
        correctAnswers: ['Работниками, выполнившими ремонтные работы'],
      },
    ],
  },
  6: {
    63682: [
      {
        code: '63682000',
        text:
          'Чем необходимо руководствоваться при подготовке обоснований границ горного отвода?',
        answers: [
          'Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ',
          'При использовании в качестве подземных хранилищ соляных пластов границы горного отвода устанавливаются по границам геометрического тела сферической или цилиндрической формы, увеличенным не менее чем на 3 м по вертикали и 20 м по горизонтали относительно контура камер выщелачивания',
          'Площадь проекции горного отвода на земную поверхность определяется в гектарах с точностью до целого числа',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ',
        ],
      },
      {
        code: '63682001',
        text:
          'В течение какого срока должны храниться в организации книга учета прихода и расхода взрывчатых материалов и книга учета выдачи и возврата взрывчатых материалов?',
        answers: [
          'В течение не менее двух лет',
          'В течение не менее одного года',
          'В течение не менее трех лет',
          'В течение не менее пяти лет',
        ],
        correctAnswers: ['В течение не менее трех лет'],
      },
      {
        code: '63682002',
        text:
          'Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?',
        answers: [
          'В соответствии с законодательством Российской Федерации о государственной тайне',
          'Курьером любой курьерской фирмы',
          'Заказным почтовым отправлением через "Почту России"',
          'Курьером фирмы, подготовившей документацию',
        ],
        correctAnswers: [
          'В соответствии с законодательством Российской Федерации о государственной тайне',
        ],
      },
      {
        code: '63682003',
        text:
          'Что из перечисленного может использоваться на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов? Укажите все правильные ответы.',
        answers: [
          'Сумки с жесткими ячейками, покрытыми внутри мягким материалом',
          'Шахтные вагонетки, оборудованные металлическими крышками',
          'Ящики, изготовленные из гипсоволокнистых листов',
          'Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм',
        ],
        correctAnswers: [
          'Шахтные вагонетки, оборудованные металлическими крышками',
          'Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм',
        ],
      },
      {
        code: '63682004',
        text:
          'Через какое время разрешается подход взрывника к месту взрыва, если взрыва не произошло, при взрывании электронными детонаторами, электродетонаторами и капсюлями-детонаторами?',
        answers: [
          'Не ранее чем через 15 минут',
          'Не ранее чем через 7 минут',
          'Не ранее чем через 10 минут',
          'По распоряжению руководителя взрывных работ',
        ],
        correctAnswers: ['Не ранее чем через 15 минут'],
      },
      {
        code: '63682005',
        text:
          'Какое из перечисленных требований к мерам защиты от статического электричества технологического оборудования при взрывных работах указано неверно?',
        answers: [
          'Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов',
          'Допускается объединение заземляющих устройств для защиты от статического электричества с защитным заземлением электрооборудования',
          'В каждом производственном здании должна быть составлена схема (карта) заземления',
          'Места для присоединения заземляющих проводников и способ их крепления должны быть указаны в технической документации оборудования',
        ],
        correctAnswers: [
          'Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов',
        ],
      },
      {
        code: '63682006',
        text:
          'В каких количествах разрешается размещать взрывчатые вещества непосредственно у зарядного оборудования?',
        answers: [
          'Не более пятисменной производительности оборудования',
          'Не более сменной производительности оборудования',
          'Не допускается ни в каких количествах',
          'Не более трехсменной производительности оборудования',
        ],
        correctAnswers: ['Не более сменной производительности оборудования'],
      },
      {
        code: '63682007',
        text:
          'Какое из перечисленных условий указано неверно при вскрытии пластов сотрясательным взрыванием с применением рассредоточенных (двухъярусных) зарядов взрывчатых веществ?',
        answers: [
          'В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья',
          'Инициирование зарядов осуществляется электродетонаторами мгновенного и короткозамедленного действия',
          'При использовании допущенных для соответствующих условий взрывчатых веществ III и IV классов длина забойки между рассредоточенными зарядами должна быть не менее 0,75 м, а масса первого от устья шпура заряда - не более 1,2 кг',
          'Длина шпуров с рассредоточенными зарядами должна составлять не менее 3 м',
        ],
        correctAnswers: [
          'В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья',
        ],
      },
      {
        code: '63682008',
        text:
          'Не ниже какого класса допускается применение предохранительных взрывчатых веществ в нефтяных шахтах?',
        answers: ['II класса', 'III класса', 'IV класса', 'V класса'],
        correctAnswers: ['III класса'],
      },
      {
        code: '63682009',
        text:
          'Какое количество приемов допускается при проведении взрывания по породе выработок, в которых отсутствует выделение метана, с применением электродетонаторов мгновенного, короткозамедленного и замедленного действия со временем замедления до 2 секунд?',
        answers: [
          'Не более трех приемов',
          'Не более четырех приемов',
          'Без ограничения количества приемов',
          'Не более двух приемов',
        ],
        correctAnswers: ['Без ограничения количества приемов'],
      },
      {
        code: '63682010',
        text:
          'Какие условия во временных складах взрывчатых материалов указаны верно? Укажите все правильные ответы.',
        answers: [
          'Устройство противопожарных водоемов (резервуаров, скважин) необязательно',
          'Устройство тамбуров не обязательно, двери могут быть одинарными',
          'Рабочее освещение внутри хранилищ не может осуществляться рудничными аккумуляторными светильниками',
          'Деревянные стены и крыши могут не покрываться огнезащитным составом',
        ],
        correctAnswers: [
          'Устройство противопожарных водоемов (резервуаров, скважин) необязательно',
          'Устройство тамбуров не обязательно, двери могут быть одинарными',
        ],
      },
      {
        code: '63682011',
        text:
          'С какой периодичностью должна проверяться техническая исправность транспортных средств, используемых для доставки взрывчатых материалов, лицом, назначенным распорядительным документом организации?',
        answers: [
          'Ежесменно',
          'Трижды в смену',
          'Еженедельно',
          'Дважды в смену',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63682012',
        text:
          'Прокладка каких труб и кабелей допускается в производственных зданиях (помещениях), где ведутся работы с окислителями или их растворами?',
        answers: [
          'Пластмассовых импульсных труб',
          'Медных командных труб',
          'Бронированных кабелей с оцинкованной броней',
          'Бронированных кабелей с открытой свинцовой оболочкой',
        ],
        correctAnswers: ['Пластмассовых импульсных труб'],
      },
      {
        code: '63682013',
        text:
          'В течение какого срока допускается размещать зарядные машины, загруженные взрывчатыми веществами, на специально выделенной площадке на территории склада ВМ?',
        answers: [
          'На срок не более двух суток',
          'На срок не более трех суток',
          'На срок не более суток',
          'Срок определяется приказом предприятия',
        ],
        correctAnswers: ['На срок не более двух суток'],
      },
      {
        code: '63682014',
        text:
          'Какое из перечисленных условий допуска работников на рабочие места после производства массовых взрывов указано верно?',
        answers: [
          'Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК',
          'Осмотр мест (места) взрыва',
          'Частичное восстановление видимости',
          'Не ранее чем через 10 минут после взрыва',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК',
        ],
      },
      {
        code: '63682015',
        text:
          'Какие электродетонаторы разрешается применять в бутовых штреках с подрывкой кровли?',
        answers: [
          'Электродетонаторы замедленного действия',
          'Электродетонаторы мгновенного действия',
          'Все перечисленные электродетонаторы',
          'Электродетонаторы короткозамедленного действия',
        ],
        correctAnswers: ['Электродетонаторы мгновенного действия'],
      },
      {
        code: '63682016',
        text:
          'Каким лицам право руководства взрывными работами предоставляется после дополнительного обучения по программе, согласованной с федеральным органом исполнительной власти в области промышленной безопасности?',
        answers: [
          'Лицам, окончившим высшие учебные заведения по специальности "Подземная разработка пластовых месторождений"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Шахтное строительство"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Подземная разработка месторождений полезных ископаемых"',
          'Все ответы неверны',
          'Лицам, окончившим высшие учебные заведения по специальности "Открытые горные работы"',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63682017',
        text:
          'Какого формата должен быть план границ горного отвода, на котором должны отображаться: контуры предварительных и уточненных границ горного отвода, угловые точки границ горного отвода?',
        answers: ['А3', 'А1', 'А5', 'А4'],
        correctAnswers: ['А4'],
      },
      {
        code: '63682018',
        text:
          'Какое из перечисленных требований во время взрывания при образовании каналов, канав и котлованов указано верно?',
        answers: [
          'В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок',
          'При формировании траншейных зарядов расстояние между экскаватором (траншеекопателем) и краном, укладывающим взрывчатые материалы в траншею, должно быть не менее 1,5 суммы радиусов разворота ковша экскаватора с вытянутой рукоятью и разворота крана',
          'При заряжании линейных зарядов более 12 часов боевики укладываются в день производства взрыва',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок',
        ],
      },
      {
        code: '63682019',
        text:
          'Каким образом должен упаковываться заряд при температуре более 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?',
        answers: [
          'В термоизолирующую оболочку',
          'В противоударную оболочку',
          'В целлофановую оболочку',
          'В водонепроницаемую оболочку',
        ],
        correctAnswers: ['В термоизолирующую оболочку'],
      },
      {
        code: '63682020',
        text: 'Кем подписывается горноотводная документация?',
        answers: [
          'Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора',
          'Главным маркшейдером',
          'Главным геологом',
          'Руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора',
        ],
      },
      {
        code: '63682021',
        text:
          'Какие из перечисленных помещений разрешается размещать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите 2 варианта ответа.',
        answers: [
          'Помещения временного пребывания дежурных слесарей и электриков',
          'Слесарные мастерские для мелкого текущего ремонта',
          'Склады ГСМ',
          'Сварочные посты',
        ],
        correctAnswers: [
          'Помещения временного пребывания дежурных слесарей и электриков',
          'Слесарные мастерские для мелкого текущего ремонта',
        ],
      },
      {
        code: '63682022',
        text:
          'Кем подписывается проект горного отвода (пояснительная записка)?',
        answers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
          'Руководителем проектной организации, имеющим право без доверенности действовать от имени пользователя недр',
          'Главным маркшейдером организации',
          'Техническим руководителем организации, испрашивающей горный отвод',
        ],
        correctAnswers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
        ],
      },
      {
        code: '63682023',
        text:
          'На кого возлагается контроль за своевременным выполнением и качеством маркшейдерских работ?',
        answers: [
          'На главного маркшейдера организации',
          'На главного геолога организации',
          'На технического руководителя организации',
          'На комиссию, утвержденную руководителем организации',
        ],
        correctAnswers: ['На главного маркшейдера организации'],
      },
      {
        code: '63682024',
        text:
          'С какой периодичностью должны проверяться знания требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими Единую книжку взрывника (за исключением заведующих складами взрывчатых материалов, пунктами производства взрывчатых материалов и руководителей взрывных работ) специальной комиссией организации с участием представителя территориального органа Ростехнадзора?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в три года',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в два года'],
      },
      {
        code: '63682025',
        text:
          'В каком месте необходимо располагать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных машин (смесительно-зарядных машин, транспортно-зарядных машин)?',
        answers: [
          'Определяется проектной документацией',
          'За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ',
          'Не ближе 100 м от здания подготовки и (или) производства взрывчатых веществ',
          'На территории пунктов производства и подготовки взрывчатых веществ на расстоянии от здания подготовки и (или) производства взрывчатых веществ, определенном техническим руководителем организации',
        ],
        correctAnswers: [
          'За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ',
        ],
      },
      {
        code: '63682026',
        text:
          'В каких местах при взрывных работах могут использоваться слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без размятия или измельчения?',
        answers: [
          'Во всех перечисленных местах',
          'На земной поверхности',
          'В шахтах (рудниках), разрабатывающих пласты (рудные тела), не опасные по взрывам пыли',
          'В шахтах (рудниках), не опасных по газу',
        ],
        correctAnswers: ['На земной поверхности'],
      },
      {
        code: '63682027',
        text:
          'Какое из перечисленных условий допускается при заземлении и защите от образования статического электричества в пневмозарядных устройствах?',
        answers: [
          'Пневмозаряжание по одному трубопроводу взрывчатых веществ разных типов',
          'Пневмозаряжание гранулированными алюмо- и тротилсодержащими взрывчатыми веществами без предварительного их увлажнения',
          'Производить пневматическое заряжание шпуров в подземных горных выработках при относительной влажности рудничного воздуха более 50 % зарядчиками с металлической зарядной трубкой или с электропроводящим зарядным трубопроводом длиной не более 5 м',
          'Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 МОм x м',
        ],
        correctAnswers: [
          'Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 МОм x м',
        ],
      },
      {
        code: '63682028',
        text:
          'Какой должна быть плотность высотной опорной геодезической сети при съемке в масштабе 1:5000?',
        answers: [
          'Не менее одного репера на 5 - 7 км²',
          'Не менее одного репера на 8 км²',
          'Не менее одного репера на 10 - 15 км²',
          'Не менее одного репера на 5 км²',
        ],
        correctAnswers: ['Не менее одного репера на 10 - 15 км²'],
      },
      {
        code: '63682029',
        text:
          'Кто устанавливает маршруты транспортирования взрывчатых материалов от склада ВМ на места работ (в пределах опасного производственного объекта)?',
        answers: [
          'Представитель вспомогательной горно-спасательной команды или профессионального аварийно-спасательного формирования (службы)',
          'Руководитель взрывных работ организации, эксплуатирующей опасный производственный объект',
          'Представитель территориального органа Ростехнадзора',
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63682030',
        text:
          'Каким документом должны определяться место погрузки-выгрузки, меры безопасности, а также порядок погрузки-разгрузки взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и надшахтных зданиях?',
        answers: [
          'Технологическим регламентом на проведение соответствующих работ',
          'Проектной документацией',
          'Предписанием территориального органа Ростехнадзора',
          'Распорядительным документом организации (шахты, рудника)',
        ],
        correctAnswers: [
          'Распорядительным документом организации (шахты, рудника)',
        ],
      },
      {
        code: '63682031',
        text:
          'При какой толщине породной пробки между забоем выработки и крутым пластом (пропластком) необходимо производить вскрытие и пересечение пластов при помощи буровзрывных работ?',
        answers: [
          'Не менее 3 м',
          'Не менее 1 м',
          'Не менее 2 м',
          'Не менее 4 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63682032',
        text:
          'На каком расстоянии от отказавших шпуровых зарядов разрешается размещать вспомогательные шпуры для их ликвидации?',
        answers: [
          'Не ближе 15 см',
          'Не ближе 30 см',
          'Не ближе 20 см',
          'Не ближе 25 см',
        ],
        correctAnswers: ['Не ближе 30 см'],
      },
      {
        code: '63682033',
        text:
          'В каких случаях при взрывании скважинных зарядов на поверхности обязательно дублирование внутрискважинной сети?',
        answers: [
          'При глубине взрывных скважин более 15 м',
          'При глубине взрывных скважин более 5 м',
          'При использовании электронных систем инициирования',
          'При производстве взрывных работ в подземных выработках',
        ],
        correctAnswers: ['При глубине взрывных скважин более 15 м'],
      },
      {
        code: '63682034',
        text:
          'При каком условии разрешается выход взрывника из укрытия после взрыва при взрывании с применением электродетонаторов?',
        answers: [
          'После отсоединения электровзрывной сети от источника тока и замыкания ее накоротко',
          'При выполнении всех перечисленных условий',
          'Не ранее чем через 5 минут',
          'После проветривания',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63682035',
        text:
          'Какой документ служит для отпуска взрывчатых материалов взрывникам для производства взрывных работ?',
        answers: [
          'Наряд-путевка',
          'Наряд-накладная',
          'Акт-допуск',
          'Распоряжение',
        ],
        correctAnswers: ['Наряд-путевка'],
      },
      {
        code: '63682036',
        text:
          'Какое действие из перечисленных допускается при ведении взрывных работ?',
        answers: [
          'Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах',
          'Взрывать заряды без забойки на шахтах (рудниках), опасных по газу и пыли',
          'Тянуть провода электронных детонаторов и электродетонаторов, введенные в боевики',
          'Переламывать выходящие из зарядов концы детонирующего шнура',
        ],
        correctAnswers: [
          'Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах',
        ],
      },
      {
        code: '63682037',
        text:
          'В каком случае транспортные пути для перевозок взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 3 м от зданий?',
        answers: [
          'От зданий, в которых изготавливаются или перерабатываются взрывчатые вещества',
          'От вспомогательных зданий, находящихся на территории пункт',
          'В случае, если пути предназначены для подъезда к этим зданиям',
          'От зданий, где имеются открытые огневые топки и источники открытого огня или где производятся работы с открытым огнем',
        ],
        correctAnswers: [
          'В случае, если пути предназначены для подъезда к этим зданиям',
        ],
      },
      {
        code: '63682038',
        text:
          'Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?',
        answers: [
          '2 экземпляра',
          '3 экземпляра',
          '4 экземпляра',
          '5 экземпляров',
        ],
        correctAnswers: ['3 экземпляра'],
      },
      {
        code: '63682039',
        text: 'Какие грунты относятся к классу А?',
        answers: [
          'Песчаные, крупнообломочные и глинистые в твердом и полутвердом состоянии',
          'Скальные',
          'Глинистые водонасыщенные в пластичном состоянии',
        ],
        correctAnswers: ['Скальные'],
      },
      {
        code: '63682040',
        text:
          'Где должен находиться руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания?',
        answers: [
          'На расстоянии 3 м впереди взрывника',
          'На расстоянии 5 м позади взрывника',
          'Не регламентируется',
          'На расстоянии 5 м впереди взрывника',
          'На расстоянии 3 м позади взрывника',
        ],
        correctAnswers: ['На расстоянии 3 м впереди взрывника'],
      },
      {
        code: '63682041',
        text:
          'Кем разрабатывается и согласовывается с командиром обслуживающего аварийно-спасательного формирования план по обслуживанию массового взрыва силами аварийно-спасательных формирований? Укажите все правильные ответы.',
        answers: [
          'Техническим руководителем рудника, шахты, объекта строительства',
          'Руководителем рудника, шахты, объекта строительства',
          'Ответственным руководителем взрыва',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Техническим руководителем рудника, шахты, объекта строительства',
          'Ответственным руководителем взрыва',
        ],
      },
      {
        code: '63682042',
        text: 'Какие грунты относятся к классу В?',
        answers: [
          'Песчаные, крупнообломочные и глинистые в твердом и полутвердом состоянии',
          'Скальные',
          'Глинистые водонасыщенные в пластичном состоянии',
        ],
        correctAnswers: ['Глинистые водонасыщенные в пластичном состоянии'],
      },
      {
        code: '63682043',
        text:
          'Какое из перечисленных требований к уничтожению взрывчатых материалов указано верно?',
        answers: [
          'Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами',
          'Допускается сжигать взрывчатые материалы в их таре',
          'Допускается применение смерзшихся взрывчатых веществ, содержащих жидкие нитроэфиры свыше 5%',
          'Взрывчатые материалы со следами экссудации должны выдаваться со склада для применения',
        ],
        correctAnswers: [
          'Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами',
        ],
      },
      {
        code: '63682044',
        text:
          'Какое количество зарядов разрешается одновременно заряжать и взрывать при температуре в шпуре ниже 80 °C при ведении взрывных работ по металлу?',
        answers: [
          'Не более пяти зарядов',
          'Не более одного заряда',
          'Не более шести зарядов',
          'Не более семи зарядов',
        ],
        correctAnswers: ['Не более пяти зарядов'],
      },
      {
        code: '63682045',
        text: 'Куда передается акт об уничтожении взрывчатых материалов?',
        answers: [
          'На склад взрывчатых материалов',
          'В отдел пожарной безопасности',
          'Главному инженеру',
          'В территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['На склад взрывчатых материалов'],
      },
      {
        code: '63682046',
        text:
          'С какой периодичностью проводится определение относительной влажности воздуха в забойном пространстве после внедрения пневматического заряжания?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
          'Не реже одного раза в год',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в квартал'],
      },
      {
        code: '63682047',
        text:
          'Какое из перечисленных требований к условиям заряжания, массе зарядов взрывчатых веществ и длине забойки указано верно?',
        answers: [
          'При наличии в забое нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе',
          'В шпурах глубиной от 1 до 1,5 м заряд должен занимать не более половины их длины',
          'Все перечисленные требования',
          'В шпурах глубиной более 1,5 м заряд должен занимать не более 2/3 их длины',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63682048',
        text:
          'На какое минимальное расстояние от скважины должны быть убраны буровые установки, не имеющие приспособления для заряжания, в сложных горно-геологических условиях?',
        answers: ['10 м', '5 м', '3 м', '7 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63682049',
        text:
          'В каком случае оборудование и здание пунктов производства и подготовки взрывчатых веществ в целом должны быть полностью освобождены от взрывоопасных продуктов?',
        answers: [
          'Ни в каком случае',
          'Если не может быть организовано постоянное наблюдение',
          'Перед нерабочей сменой',
          'При остановке пункта на период более двух суток',
        ],
        correctAnswers: ['При остановке пункта на период более двух суток'],
      },
      {
        code: '63682050',
        text:
          'Кому должен сообщить взрывник при возникновении аварийной ситуации в процессе заряжания?',
        answers: [
          'Техническому руководителю организации',
          'Рабочему, привлекаемому в помощь взрывнику',
          'Руководителю взрывных работ',
          'Диспетчеру',
        ],
        correctAnswers: ['Руководителю взрывных работ'],
      },
      {
        code: '63682051',
        text:
          'Что из перечисленного целесообразно отражать в инструкции по ликвидации отказавших зарядов взрывчатых веществ при строительстве гидротехнических сооружений?',
        answers: [
          'Основные мероприятия по предупреждению отказавших зарядов, порядок обнаружения невзорвавшихся зарядов, методы ликвидации отказов для каждого вида взрывных работ',
          'Величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности и в подземных выработках, а также ее охране, организацию работ по ликвидации отказов',
          'Порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа, мероприятия по безопасности работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63682052',
        text:
          'Каким образом запрещается производить заряжание шпуров (скважин) и монтаж взрывной сети на высоте более 2 м?',
        answers: [
          'С лестниц',
          'С оборудованных подъемных площадок (помостов)',
          'С площадок подъемных механизмов',
          'С применением погрузочно-доставочной техники',
        ],
        correctAnswers: ['С лестниц'],
      },
      {
        code: '63682053',
        text:
          'Какие материалы передаются организации-заказчику при постороении маркшейдерских опорных геодезических сетей на поверхности сторонней организацией?',
        answers: [
          'Каталоги координат и высот пунктов',
          'Все перечисленные материалы',
          'Журналы измерений и ведомости вычислений',
          'Оригиналы планов',
        ],
        correctAnswers: ['Каталоги координат и высот пунктов'],
      },
      {
        code: '63682054',
        text:
          'В какой срок со дня поступления заявления осуществляется оформление документации, удостоверяющей уточненные границы горного отвода?',
        answers: [
          'Не более 10 дней',
          'Не более 15 дней',
          'Не более 25 дней',
          'Не более 30 дней',
        ],
        correctAnswers: ['Не более 25 дней'],
      },
      {
        code: '63682055',
        text:
          'К какому классу относится гидротехническое сооружение в соответствии с критериями, утвержденными Постановлением Правительства РФ "Об утверждении критериев классификации гидротехнических сооружений" в случае, если оно может быть отнесено к разным классам?',
        answers: [
          'К наиболее высокому из них',
          'К наиболее низкому из них',
          'Класс определяется Ростехнадзором по инициативе собственников гидротехнических сооружений и (или) эксплуатирующих организаций',
          'Класс определяется проектной организацией при проведении государственной экспертизы декларации безопасности гидротехнического сооружения',
        ],
        correctAnswers: ['К наиболее высокому из них'],
      },
      {
        code: '63682056',
        text:
          'Каким образом должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления при взрывных работах?',
        answers: [
          'Одной поперечной полосой шириной 10 мм зеленого цвета',
          'Одной поперечной полосой шириной 10 мм синего цвета',
          'Одной поперечной полосой шириной 15 мм красного цвета',
          'Двумя поперечными полосами шириной 15 мм красного цвета',
        ],
        correctAnswers: [
          'Одной поперечной полосой шириной 15 мм красного цвета',
        ],
      },
      {
        code: '63682057',
        text:
          'С какой периодичностью проводится измерение параметров электризации в условиях производства?',
        answers: [
          'Не реже двух раз в год',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже двух раз в квартал',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63682058',
        text:
          'В каком случае разрешается доставка аммиачно-селитренных взрывчатых веществ к местам проведения взрывных работ в подземных выработках в ковшах погрузочно-доставочных машин от участковых пунктов хранения и мест выгрузки взрывчатых материалов?',
        answers: [
          'При условии загрузки ковша не более 2/3 по его высоте',
          'При условии загрузки ковша не более 3/4 по его высоте',
          'Ни при каких условиях',
          'При условии руководства операцией лицом, назначенным ответственным за доставку (подъем, спуск) взрывчатых материалов',
        ],
        correctAnswers: [
          'При условии загрузки ковша не более 2/3 по его высоте',
        ],
      },
      {
        code: '63682059',
        text:
          'Что является базовым документом для разработки паспортов и проектов буровзрывных (взрывных) работ, в том числе и проектов массовых взрывов, за исключением специальных и экспериментальных массовых взрывов в подземных выработках, выполняемых в конкретных условиях?',
        answers: [
          'Программа проведения взрывных работ',
          'Типовой проект буровзрывных (взрывных) работ',
          'Руководство по проведению буровзрывных работ',
          'Правила безопасности при производстве, хранении и применении взрывчатых материалов промышленного назначения',
        ],
        correctAnswers: ['Типовой проект буровзрывных (взрывных) работ'],
      },
      {
        code: '63682060',
        text:
          'На каком расстоянии от места заряжания скважин и стоянки зарядной машины и трубопровода запрещается производить какие-либо работы, непосредственно не связанные с заряжанием?',
        answers: [
          'Не ближе 50 м',
          'Не ближе 40 м',
          'Не ближе 30 м',
          'Не ближе 20 м',
        ],
        correctAnswers: ['Не ближе 50 м'],
      },
      {
        code: '63682061',
        text:
          'С какой периодичностью проводится пересмотр Регламента технологического процесса производства и подготовки взрывчатых веществ?',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в год',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '63682062',
        text:
          'После чего начинаются загрузка бункера зарядного оборудования и непосредственно заряжание?',
        answers: [
          'Поcле сигнала диспетчера',
          'После того, как взрывник убедится в том, что блок и зарядные устройства к этим работам подготовлены',
          'После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены',
          'После предупреждения лиц, участвующих в заряжании',
        ],
        correctAnswers: [
          'После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены',
        ],
      },
      {
        code: '63682063',
        text: 'Какими лицами переносятся средства инициирования?',
        answers: [
          'Проходчиками',
          'Всеми перечисленными лицами',
          'Взрывниками',
          'Раздатчиками взрывных материалов',
        ],
        correctAnswers: ['Взрывниками'],
      },
      {
        code: '63682064',
        text:
          'В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?',
        answers: [
          'В хранилищах аммиачной селитры',
          'В хранилищах кальциевой селитры',
          'В хранилищах натриевой селитры',
          'Во всех перечисленных хранилищах',
        ],
        correctAnswers: ['В хранилищах аммиачной селитры'],
      },
      {
        code: '63682065',
        text:
          'Какой способ ликвидации отказавших скважинных зарядов указан неверно?',
        answers: [
          'Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура',
          'Взрывание заряда в скважине, пробуренной параллельно на расстоянии не менее 3 м от скважины с отказавшим зарядом',
          'Разборка породы в месте нахождения скважины с отказавшим зарядом с извлечением последнего вручную',
          'Взрывание отказавшего заряда в случае, если отказ произошел в результате нарушения целостности внешней взрывной сети (если линия наименьшего сопротивления отказавшего заряда не уменьшилась)',
        ],
        correctAnswers: [
          'Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура',
        ],
      },
      {
        code: '63682066',
        text:
          'Кем проверяется подготовленный к массовому взрыву подэтаж (блок, панель)?',
        answers: [
          'Руководителем рудника (шахты, объекта строительства)',
          'Техническим руководителем рудника (шахты, объекта строительства)',
          'Руководителем массового взрыва',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63682067',
        text:
          'Какое из перечисленных требований запрещается при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и (или) пыли? Укажите все правильные ответы.',
        answers: [
          'Размещать в одном шпуре при сплошном заряде более одного патрона-боевика',
          'Размещать в одном шпуре взрывчатые вещества различных классов',
          'Одновременно вводить в шпур заряд, состоящий из двух патронов взрывчатого вещества и более',
          'Устанавливать шпуры при взрывании по углю и породе на глубине 0,6 м',
        ],
        correctAnswers: [
          'Размещать в одном шпуре при сплошном заряде более одного патрона-боевика',
          'Размещать в одном шпуре взрывчатые вещества различных классов',
        ],
      },
      {
        code: '63682068',
        text:
          'Кому должны быть сданы остатки взрывчатых материалов взрывниками по окончании взрывных работ?',
        answers: [
          'Остатки взрывчатых материалов должны быть возвращены обратно на склад кладовщику',
          'Лично техническому руководителю шахты (рудника)',
          'Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)',
          'Лично руководителю взрывных работ',
        ],
        correctAnswers: [
          'Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)',
        ],
      },
      {
        code: '63682069',
        text:
          'Какая допускается максимальная скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?',
        answers: ['5 км/ч', '15 км/ч', '20 км/ч', '10 км/ч'],
        correctAnswers: ['15 км/ч'],
      },
      {
        code: '63682070',
        text:
          'К какому классу относятся плотины из грунтовых материалов высотой менее 15 м и типом грунта основания Б?',
        answers: ['I классу', 'II классу', 'III классу', 'IV классу'],
        correctAnswers: ['IV классу'],
      },
      {
        code: '63682071',
        text:
          'Что из перечисленного допускается в процессе пневмотранспортирования или пневмозаряжания?',
        answers: [
          'Расстилать брезент на месте заряжания под восходящими скважинами',
          'Оставлять просыпь взрывчатых веществ на почве выработок',
          'Находиться в створе с заряжаемой скважиной (шпуром)',
          'Приступать к заряжанию в тупиковых выработках при отсутствии проветривания',
        ],
        correctAnswers: [
          'Расстилать брезент на месте заряжания под восходящими скважинами',
        ],
      },
      {
        code: '63682072',
        text:
          'Какие требования при вместимости складов и камер взрывчатых материалов указаны верно? Укажите все правильные ответы.',
        answers: [
          'Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ',
          'На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования',
          'В складах ячейкового типа в каждой ячейке разрешается хранить не более 600 кг взрывчатых веществ',
          'Предельная вместимость отдельной раздаточной камеры в подземных выработках не должна превышать 4000 кг взрывчатых веществ и соответствующего количества средств инициирования',
        ],
        correctAnswers: [
          'Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ',
          'На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования',
        ],
      },
      {
        code: '63682073',
        text:
          'Какое действие необходимо выполнять для уменьшения пыления и просыпи взрывчатых веществ?',
        answers: [
          'Выдерживать оптимальное расстояние от конца шланга до заряда',
          'При заряжании центрировать конец зарядного трубопровода относительно оси скважины',
          'При пневмозаряжании алюмо- и тротилсодержащими рассыпными гранулированными взрывчатыми веществами добавлять во взрывчатые вещества воду или смачивающий раствор',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63682074',
        text:
          'На каком минимальном расстоянии от места взрыва должно находиться место укрытия взрывников при пропуске угля и породы в восстающих выработках?',
        answers: ['150 м', '100 м', '70 м', '50 м'],
        correctAnswers: ['150 м'],
      },
      {
        code: '63682075',
        text:
          'Кем могут выполняться отдельные виды геодезических и маркшейдерских работ?',
        answers: [
          'Территориальным органом Ростехнадзора',
          'Сторонней организацией, имеющей лицензию на право производства маркшейдерских работ, выданную Ростехнадзором',
          'Любой экспертной организацией по решению организации-заказчика',
          'Территориальным управлением Росреестра',
          'Органами местного самоуправления',
        ],
        correctAnswers: [
          'Сторонней организацией, имеющей лицензию на право производства маркшейдерских работ, выданную Ростехнадзором',
        ],
      },
      {
        code: '63682076',
        text:
          'В течение какого времени должна проводиться стажировка взрывников перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?',
        answers: [
          'В течение 15 дней',
          'В течение трех месяцев',
          'В течение одного месяца',
          'В течение двух месяцев',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63682077',
        text:
          'Для каких видов взрывных работ применяются предохранительные взрывчатые вещества VI класса?',
        answers: [
          'Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой',
          'Для ликвидации зависаний горной массы в углеспускных выработках',
          'Для взрывного перебивания деревянных стоек при посадке кровли',
          'Для дробления негабаритов наружными зарядами',
        ],
        correctAnswers: [
          'Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой',
        ],
      },
      {
        code: '63682078',
        text:
          'При каких условиях допускается проведение прострелочных или взрывных работ в скважинах?',
        answers: [
          'Во время пурги',
          'В сухих газирующих и поглощающих раствор скважинах без применения лубрикаторов',
          'Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых',
          'Во время грозы',
        ],
        correctAnswers: [
          'Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых',
        ],
      },
      {
        code: '63682079',
        text:
          'Кто должен осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние перед началом работы по механизированному заряжанию шпуров, скважин или камер? Укажите все правильные ответы.',
        answers: [
          'Взрывник',
          'Руководитель взрывных работ',
          'Технический руководитель организации',
          'Рабочие, привлекаемые в помощь взрывникам',
        ],
        correctAnswers: ['Взрывник', 'Руководитель взрывных работ'],
      },
      {
        code: '63682080',
        text:
          'В какой системе координат должны быть определены уточненные границы горного отвода?',
        answers: [
          'В прямоугольной системе координат',
          'В полярной системе координат (азимут, горизонтальное проложение)',
          'В географической системе координат (ширина, долгота)',
        ],
        correctAnswers: ['В прямоугольной системе координат'],
      },
      {
        code: '63682081',
        text:
          'Каким образом очищается оборудование при наличии несмываемых остатков взрывчатых веществ по окончании заряжания? Укажите все правильные ответы.',
        answers: [
          'Горячей водой',
          'Паром',
          'Металлической щеткой',
          'Сжатым воздухом',
        ],
        correctAnswers: ['Горячей водой', 'Паром'],
      },
      {
        code: '63682082',
        text:
          'Кем должны проводиться заряжание и забойка при ведении взрывных работ по металлу?',
        answers: [
          'Двумя взрывниками в присутствии руководителя взрывных работ',
          'Тремя взрывниками в присутствии руководителя взрывных работ',
          'Одним взрывником в присутствии руководителя взрывных работ',
          'Тремя взрывниками',
        ],
        correctAnswers: [
          'Двумя взрывниками в присутствии руководителя взрывных работ',
        ],
      },
      {
        code: '63682083',
        text: 'Кем подписываются акты о приемке геодезических пунктов?',
        answers: [
          'Главным маркшейдером строительно-монтажной организации',
          'Комиссией эксплуатирующей организации',
          'Руководителем маркшейдерской службы организации',
          'Руководителем эксплуатирующей организации',
        ],
        correctAnswers: ['Руководителем маркшейдерской службы организации'],
      },
      {
        code: '63682084',
        text:
          'В течение какого срока в организации хранятся проекты буровзрывных (взрывных) работ, паспорта, схемы, в соответствии с которыми осуществляются взрывные работы?',
        answers: [
          'Не менее года',
          'Не менее месяца',
          'Не менее квартала',
          'Не менее полугода',
        ],
        correctAnswers: ['Не менее года'],
      },
      {
        code: '63682085',
        text:
          'Из чего состоит проект буровзрывных (взрывных) работ (проект массового взрыва) для конкретных условий?',
        answers: [
          'Из таблицы параметров взрывных работ',
          'Из распорядка проведения массового взрыва',
          'Из технического расчета со схемой расположения скважин и графическими материалами',
          'Из всего перечисленного',
        ],
        correctAnswers: ['Из всего перечисленного'],
      },
      {
        code: '63682086',
        text:
          'Какое из перечисленных требований к углубленным складам взрывчатых материалов указано верно?',
        answers: [
          'Наружная дверь выработок, ведущих к углубленному складу, должна быть решетчатой, а внутренняя - металлической или деревянной',
          'Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу',
          'Перед устьем выработки, ведущей к углубленному складу, необходимо устраивать защитный вал высотой, превышающей высоту выработки на 1 м',
          'Если расстояние от входа в углубленный склад до ближайшей камеры хранения взрывчатых материалов более 10 м, склад должен иметь 2 выхода',
        ],
        correctAnswers: [
          'Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу',
        ],
      },
      {
        code: '63682087',
        text:
          'Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?',
        answers: [
          'Органом государственного горного надзора',
          'Органами исполнительной власти каждого субъекта Российской Федерации',
          'Органом исполнительной власти любого из причастных к горному отводу субъекта Российской Федерации',
        ],
        correctAnswers: ['Органом государственного горного надзора'],
      },
      {
        code: '63682088',
        text:
          'Какое из перечисленных требований к транспортированию взрывчатых материалов указано верно? Укажите все правильные ответы.',
        answers: [
          'Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди',
          'При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика',
          'Разрешается одновременно спускаться или подниматься в одной клети не более одного взрывника или подносчика с сумками с взрывчатыми материалами',
          'Транспортирование взрывчатых материалов по подземным выработкам должно осуществляться со скоростью не более 10 м/с',
        ],
        correctAnswers: [
          'Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди',
          'При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика',
        ],
      },
      {
        code: '63682089',
        text:
          'При какой температуре запрещается заряжание и взрывание зарядов в шпурах при ведении взрывных работ по металлу?',
        answers: ['Свыше 100 °C', 'Свыше 200 °C', 'Свыше 150 °C'],
        correctAnswers: ['Свыше 200 °C'],
      },
      {
        code: '63682090',
        text:
          'К какому классу относятся плотины бетонные, железобетонные высотой менее 25 м и типом грунта основания А?',
        answers: ['I классу', 'II классу', 'III классу', 'IV классу'],
        correctAnswers: ['IV классу'],
      },
      {
        code: '63682091',
        text:
          'Какие из перечисленных сооружений при строительстве коллекторных тоннелей относятся к группе А? Укажите все правильные ответы.',
        answers: [
          'Насосные станции',
          'Порталы тоннелей',
          'Строительные краны',
          'Подъемные машины (лебедки)',
          'Копры',
        ],
        correctAnswers: ['Насосные станции', 'Порталы тоннелей'],
      },
      {
        code: '63682092',
        text:
          'Что должны включать графические материалы проекта горного отвода при разработке месторождений полезных ископаемых, представленных комплексом рудных тел, а также месторождений угля? Укажите все правильные ответы.',
        answers: [
          'Гипсометрические планы рудных тел и пластов',
          'Погоризонтные планы рудных тел и пластов',
          'Геологическое строение участка недр',
          'Контуры запасов полезных ископаемых по категориям геологической изученности',
          'Тектонические нарушения',
        ],
        correctAnswers: [
          'Гипсометрические планы рудных тел и пластов',
          'Погоризонтные планы рудных тел и пластов',
        ],
      },
      {
        code: '63682093',
        text:
          'В каком случае должно проводиться сотрясательное взрывание при отработке пластов, опасных по внезапным выбросам угля, породы и газа? Укажите все правильные ответы.',
        answers: [
          'При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился',
          'При проведении восстающих выработок с углом наклона 15°',
          'При вскрытии песчаников на глубине 300 м, если прогноз выбросоопасности перед вскрытием не осуществлялся',
          'При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м',
        ],
        correctAnswers: [
          'При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился',
          'При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м',
        ],
      },
      {
        code: '63682094',
        text:
          'Какие прострелочно-взрывные аппараты должны подниматься над устьем скважины и опускаться с помощью грузоподъемных механизмов?',
        answers: [
          'Массой более 30 кг или длиной более 1 м',
          'Массой более 40 кг или длиной более 0,5 м',
          'Массой более 40 кг или длиной более 1,5 м',
          'Все ответы неверны',
          'Массой более 20 кг или длиной более 1,7 м',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63682095',
        text:
          'Взрывчатые вещества каких составов разрешается производить на пунктах производства взрывчатых веществ?',
        answers: [
          'Смеси холодного смешения гранулированной аммиачной селитры с гранулированным (чешуированным) тротилом, жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими',
          'Водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими',
          'Всех перечисленных',
          'Смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63682096',
        text:
          'Какие из перечисленных мест хранения взрывчатых материалов должны быть приняты в эксплуатацию комиссией по оценке соответствия места хранения установленным требованиям и проектной документации?',
        answers: [
          'Места сменного хранения, размещаемые вблизи мест ведения взрывных работ',
          'Места размещения взрывчатых материалов на местах производства взрывных работ',
          'Все ответы неверны',
          'Передвижные склады',
          'Все перечисленные',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63682097',
        text:
          'Кто утверждает регламент технологического процесса производства и подготовки взрывчатых веществ и изменения в него?',
        answers: [
          'Представитель территориального органа Ростехнадзора',
          'Ответственный за пожарную безопасность',
          'Руководитель (технический руководитель) эксплуатирующей организации',
          'Комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) эксплуатирующей организации',
        ],
      },
      {
        code: '63682098',
        text:
          'Какое из перечисленных требований при применении средств инициирования запрещается?',
        answers: [
          'Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов',
          'Обматывать детонирующий шнур вокруг патрона взрывчатых веществ',
          'При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне складывать вдвое',
          'При изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ с применением детонирующего шнура конец детонирующего шнура в патроне завязывать узлом',
        ],
        correctAnswers: [
          'Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов',
        ],
      },
      {
        code: '63682099',
        text:
          'Каким способом разрешается проводить взрывание камерных зарядов?',
        answers: [
          'С применением электродетонаторов',
          'С применением неэлектрических систем инициирования',
          'С применением детонирующего шнура',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63682100',
        text:
          'В каком случае обеспечивается защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения присоединением металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?',
        answers: [
          'При бронированных кабелях, проложенных в земле',
          'При кабелях, присоединенных к воздушной линии',
          'При небронированных кабелях',
        ],
        correctAnswers: ['При бронированных кабелях, проложенных в земле'],
      },
      {
        code: '63682101',
        text: 'Какое совместное хранение взрывчатых материалов не допускается?',
        answers: [
          'Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом',
          'Зарядов кумулятивных группы совместимости S с зарядами кумулятивными группы совместимости D',
          'Детонирующего шнура групп совместимости D и S со средствами инициирования групп совместимости B и S',
          'Средств инициирования группы совместимости S и средств инициирования группы совместимости B',
        ],
        correctAnswers: [
          'Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом',
        ],
      },
      {
        code: '63682102',
        text:
          'Какой из перечисленных индексов, наносимый на гильзы электродетонаторов и капсюлей-детонаторов, обозначает номер взрывника?',
        answers: [
          'Два буквенных индекса справа от цифровых',
          'Два цифровых индекса',
          'Два буквенных индекса слева от цифровых',
          'Три буквенных индекса справа от цифровых',
        ],
        correctAnswers: ['Два буквенных индекса справа от цифровых'],
      },
      {
        code: '63682103',
        text:
          'Кем проверяется состояние зарядных устройств не реже одного раза в месяц?',
        answers: [
          'Комиссией организации, эксплуатирующей зарядное оборудование',
          'Лицензированной подрядной организацией',
          'Работниками, эксплуатирующим зарядное оборудование',
          'Руководителем взрывных работ',
        ],
        correctAnswers: [
          'Комиссией организации, эксплуатирующей зарядное оборудование',
        ],
      },
      {
        code: '63682104',
        text:
          'Работа каких конвейеров, транспортирующих пожаровзрывоопасные вещества, допускается без устройства блокировочных устройств?',
        answers: [
          'Ленточные конвейеры',
          'Цепные конвейеры',
          'Винтовые конвейеры',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63682105',
        text:
          'В каком документе фиксируются отказы зарядов при взрывных работах?',
        answers: [
          'В ведомости учета зарядов',
          'В акте выполненных работ',
          'В журнале регистрации отказов при взрывных работах',
          'В сменном журнале, наряде-путевке и журнале осмотра технического состояния и учета работы оборудования',
        ],
        correctAnswers: ['В журнале регистрации отказов при взрывных работах'],
      },
      {
        code: '63682106',
        text:
          'В каком случае допускается оставлять взрывчатые материалы на местах работ без постоянного надзора (охраны)?',
        answers: [
          'Все ответы неверны',
          'Если взрывчатые материалы хранятся в отдельных металлических ящиках и контейнерах (сейфах)',
          'Если взрывчатые материалы защищены от атмосферных осадков',
          'В случаях, утвержденных распорядительным документом организации, ведущей взрывные работы',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63682107',
        text:
          'Какому эквивалентному значению приравнивается 1 л диоксида азота при проверке вредных продуктов взрыва в выработке (забое)?',
        answers: [
          '8,5 л оксида углерода',
          '7,5 л оксида углерода',
          '6,5 л оксида углерода',
          '5,5 л оксида углерода',
        ],
        correctAnswers: ['6,5 л оксида углерода'],
      },
      {
        code: '63682108',
        text:
          'При какой минимальной концентрации метана руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания, обязан немедленно возвратиться в выработку со свежей струей воздуха?',
        answers: ['2 %', '4 %', '7 %', '9 %'],
        correctAnswers: ['2 %'],
      },
      {
        code: '63682109',
        text:
          'В каких случаях документация, удостоверяющая уточненные границы горного отвода, подлежит переоформлению?',
        answers: [
          'Только при изменении технического проекта выполнения работ, связанных с пользованием недрами',
          'Только при изменении геологической информации о недрах, наличии технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
          'Только при переоформлении лицензии на пользование недрами',
          'Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами',
        ],
      },
      {
        code: '63682110',
        text:
          'В каких из перечисленных помещениях допускается хранение взрывчатых материалов?',
        answers: [
          'В здании выдачи взрывчатых материалов',
          'Все ответы неверны',
          'В тамбурах хранилищ',
          'В здании подготовки взрывчатых материалов',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63682111',
        text:
          'Какое требование относится к хранилищам, имеющим рампы и средства механизации погрузочно-разгрузочных работ?',
        answers: [
          'Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями',
          'Необходимо оборудовать не менее двух тамбуров, вход через тамбуры следует оборудовать не менее чем двумя двустворчатыми решетчатыми дверями',
          'Необходимо оборудовать не менее одного тамбура',
          'Необходимо оборудовать не менее одного тамбура, вход через тамбур следует оборудовать металлической дверью',
        ],
        correctAnswers: [
          'Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями',
        ],
      },
      {
        code: '63682112',
        text:
          'Какая устанавливается максимальная суммарная загрузка здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом взрывчатых веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?',
        answers: ['60 т', '70 т', '80 т', '90 т'],
        correctAnswers: ['60 т'],
      },
      {
        code: '63682113',
        text:
          'Какие условия должны соблюдаться в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль?',
        answers: [
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно',
          'В подготовительных выработках, проводимых по углю, и в комбайновых нишах очистных забоев без машинного вруба все заряды в угольном забое должны взрываться последовательно от нескольких импульсов тока взрывного прибора',
          'Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания не должно превышать при применении взрывчатых веществ III класса - 320 миллисекунд',
          'Все перечисленные условия',
        ],
        correctAnswers: [
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно',
        ],
      },
      {
        code: '63682114',
        text:
          'Каким федеральным органом исполнительной власти оформляется горноотводная документация?',
        answers: [
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
          'Федеральной службой по экологическому, технологическому и атомному надзору',
          'Федеральным агентством по недропользованию',
          'Министерством природных ресурсов и экологии Российской Федерации',
        ],
        correctAnswers: [
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '63682115',
        text:
          'Для решения какого рода вопросов предназначены маркшейдерские работы и исполнительная маркшейдерская документация?',
        answers: [
          'Для решения вопросов проектирования и строительства подземных сооружений',
          'Для решения вопросов рационального использования подземного пространства городов',
          'Для решения вопросов охраны недр и природных объектов от вредного влияния подземного строительства',
          'Для решения всех перечисленных вопросов, а также для обеспечения безопасности строительно-монтажных работ',
        ],
        correctAnswers: [
          'Для решения всех перечисленных вопросов, а также для обеспечения безопасности строительно-монтажных работ',
        ],
      },
      {
        code: '63682116',
        text:
          'Какое из перечисленных требований к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?',
        answers: [
          'В случае появления трещин во внешней оболочке изделий они должны быть отремонтированы',
          'Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
          'Нанесение индивидуальных индексов на электродетонаторы с помощью устройств обжимного типа должно производиться на 1,0 - 3,0 мм выше нижнего зига заводской обжимки гильзы (соединения капсюля-детонатора с электровоспламенителем)',
          'Нанесение индивидуальных индексов на капсюли-детонаторы должно осуществляться после изготовления зажигательных трубок',
        ],
        correctAnswers: [
          'Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
        ],
      },
      {
        code: '63682117',
        text:
          'В каком случае допускается использовать для освещения в углубленном складе индивидуальные рудничные аккумуляторные светильники при отсутствии стационарных источников электроэнергии?',
        answers: [
          'Если посты охраны располагаются как у входа в склад, так и у устья вентиляционных выработок и у запасного выхода',
          'Не допускается ни в каком случае',
          'По разрешению руководителя (технического руководителя) организации',
          'При толщине покрывающего слоя более 10 м',
        ],
        correctAnswers: [
          'По разрешению руководителя (технического руководителя) организации',
        ],
      },
      {
        code: '63682118',
        text:
          'Куда должны быть занесены машинистом погрузочной техники время обнаружения отказа, принятые меры безопасности, а также данные о том, кому сообщено об обнаружении отказа?',
        answers: [
          'В журнал приема-сдачи смен',
          'В журнал регистрации отказов при взрывных работах',
          'В журнал регистрации ликвидированных отказов взрывных веществ',
          'На маркшейдерские планы',
        ],
        correctAnswers: ['В журнал приема-сдачи смен'],
      },
      {
        code: '63682119',
        text:
          'Какие материалы допускается использовать для насыпки валов складов взрывчатых материалов?',
        answers: [
          'Камень',
          'Пластичные грунты',
          'Все перечисленные материалы',
          'Щебень',
        ],
        correctAnswers: ['Пластичные грунты'],
      },
      {
        code: '63682120',
        text:
          'Каким образом следует проводить бурение шпуров по углю для сотрясательного взрывания?',
        answers: [
          'В соответствии с решением руководителя сотрясательного взрывания',
          'Ударновращательным способом',
          'Ударным способом',
          'Вращательным способом',
        ],
        correctAnswers: ['Вращательным способом'],
      },
      {
        code: '63682121',
        text:
          'Какое из перечисленных требований к поверхностным постоянным складам указано неверно?',
        answers: [
          'Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации',
          'Склады должны ограждаться и иметь запретную зону шириной от ограды не менее 50 м',
          'Склады должны иметь противопожарный водоем (резервуар, скважину, насосы, гидранты)',
          'Хранилища следует располагать так, чтобы обеспечивался свободный подход и подъезд к каждому из них',
        ],
        correctAnswers: [
          'Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации',
        ],
      },
      {
        code: '63682122',
        text:
          'С какой периодичностью места хранения селитры должны подвергаться очистке?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63682123',
        text:
          'Каким должно быть расстояние между параллельно проводимыми выработками угольных и нефтяных шахт, при котором взрывание зарядов в каждом забое должно проводиться только после вывода людей из забоя параллельной выработки и выставления постов охраны, предусмотренных паспортами буровзрывных работ?',
        answers: [
          '20 м и менее',
          '30 м и менее',
          '15 м и менее',
          '25 м и менее',
        ],
        correctAnswers: ['15 м и менее'],
      },
      {
        code: '63682124',
        text: 'Что указывается на плане горного отвода?',
        answers: [
          'Площадь проекции горного отвода в гектарах на горизонтальную плоскость',
          'Линии вертикальных разрезов (профилей) и проекций',
          'Используемые системы координат и высот, масштаб плана, координатная сетка',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63682125',
        text:
          'Какая допускается максимальная температура сжатого воздуха (при работе с автономным компрессором), а также нагрева узлов зарядных устройств, через которые проходят взрывчатые вещества?',
        answers: ['40 °C', '70 °C', '50 °C', '60 °C'],
        correctAnswers: ['60 °C'],
      },
      {
        code: '63682126',
        text:
          'В состав какой документации должен входить горноотводный акт и графические приложения к нему?',
        answers: [
          'Горноотводной документации',
          'Проектной документации',
          'Документации на техническое перевооружение, консервацию и ликвидацию',
          'Документации к обоснованию безопасности',
        ],
        correctAnswers: ['Горноотводной документации'],
      },
      {
        code: '63682127',
        text:
          'В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?',
        answers: [
          'Не позднее 7 дней со дня оформления',
          'Не позднее 5 дней со дня оформления',
          'Не позднее 10 дней со дня оформления',
          'Не позднее 14 дней со дня оформления',
        ],
        correctAnswers: ['Не позднее 5 дней со дня оформления'],
      },
      {
        code: '63682128',
        text:
          'Какие взрывчатые вещества должны использоваться при разупрочнении труднообрушаемых пород любой крепости?',
        answers: [
          'Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил',
          'Взрывчатые вещества, содержащие нитроэфиры',
          'Взрывчатые вещества, содержащие гексоген',
          'Любые взрывчатые вещества',
        ],
        correctAnswers: [
          'Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил',
        ],
      },
    ],
    63684: [
      {
        code: '63684000',
        text:
          'Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?',
        answers: [
          'Физические свойства складируемых пород, прочность их в куске',
          'Сдвиговые характеристики отвальной массы',
          'Строение основания (наклон основания, наличие прослоев слабых пород и их мощность, наличие водоносных горизонтов, возможность образования техногенных водоносных горизонтов в нижней части отвала и в породах основания)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684001',
        text:
          'Чем необходимо руководствоваться при подготовке обоснований границ горного отвода?',
        answers: [
          'Площадью проекции горного отвода на земную поверхность, которая определяется в гектарах с точностью до одной сотой',
          'Всем перечисленным',
          'Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ',
          'Подземными хранилищами соляных пластов границы горного отвода, которые устанавливаются по границам геометрического тела сферической формы, увеличенным не менее чем на 2 м по вертикали и 15 м по горизонтали относительно контура камер выщелачивания',
        ],
        correctAnswers: [
          'Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ',
        ],
      },
      {
        code: '63684002',
        text:
          'При каких условиях недропользователю устанавливаются уточненные границы горного отвода?',
        answers: [
          'При одновременном получении лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений',
          'При процессе разработки месторождений нефти или газа по требованию органа, выдавшего лицензию на пользование недрами',
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы',
          'При ликвидации, консервации скважин и оборудования их устьев и стволов',
        ],
        correctAnswers: [
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы',
        ],
      },
      {
        code: '63684003',
        text:
          'На какой высоте от оси пути следует располагать указатели путевого заграждения, располагая их со стороны машиниста локомотива?',
        answers: ['1,5 м', '2,5 м', '2,0 м', '1,0 м'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63684004',
        text:
          'В каком случае при работе на отвале нарушены требования Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Работа бульдозера должна производиться перпендикулярно верхней бровке откоса площадки',
          'Движение бульдозера во время работы должно производиться только отвалом вперед с одновременным формированием перед отвалом бульдозера предохранительного вала в соответствии с проектом',
          'Подача автосамосвала на разгрузку должна осуществляться задним ходом',
          'При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса',
        ],
        correctAnswers: [
          'При разгрузке автосамосвалов в пределах призмы обрушения при подработанном экскаватором откосе яруса допускается установка автосамосвалов перпендикулярно верхней бровке откоса',
        ],
      },
      {
        code: '63684005',
        text:
          'В каком документе в зависимости от видов полезных ископаемых и целей пользования недрами должны отображаться контуры месторождения, совмещенный (интегральный) контур поставленных на государственный баланс запасов полезных ископаемых?',
        answers: [
          'В пояснительной записке проекта горного отвода',
          'В приложении к лицензии на пользование недрами',
          'На плане горного отвода',
          'На графических материалах проекта горного отвода',
        ],
        correctAnswers: ['На плане горного отвода'],
      },
      {
        code: '63684006',
        text:
          'С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций вне зоны опорного давления?',
        answers: [
          '1 раз в квартал',
          '1 раз в месяц',
          '1 раз в смену',
          '1 раз в неделю',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63684007',
        text:
          'Что не соответствует требованиям к сбросу воды, удаляемой из разреза, а также сбросу воды, полученной в результате осушения месторождения?',
        answers: [
          'Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы окрашены в яркий цвет и на них нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом',
          'Вода, удаляемая из разреза, должна сбрасываться в ближайший водоток или в место, исключающее возможность ее обратного проникновения через трещины, провалы или водопроницаемые породы в выработки и заболачивание прилегающих территорий',
          'Сброс вод, полученных в результате осушения месторождения, должен производиться только после их осветления и очистки от вредных примесей',
        ],
        correctAnswers: [
          'Устья дренажно-вентиляционных скважин должны быть обсажены перфорированными трубами, выступающими над подошвой уступа на высоту 1 м, трубы окрашены в яркий цвет и на них нанесены номера скважин, а устье трубы должно быть наглухо перекрыто приваренным металлическим листом',
        ],
      },
      {
        code: '63684008',
        text:
          'В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?',
        answers: [
          'Не позднее 3 дней со дня оформления',
          'Не позднее 5 дней со дня оформления',
          'Не позднее 10 дней со дня оформления',
          'Не позднее 15 дней со дня оформления',
        ],
        correctAnswers: ['Не позднее 5 дней со дня оформления'],
      },
      {
        code: '63684009',
        text:
          'Какой должна быть требуемая минимальная плотность установки анкеров в классе неустойчивой кровли?',
        answers: [
          'Не меньше 0,3 анк/м²',
          'Не меньше 0,7 анк/м²',
          'Не меньше 0,5 анк/м²',
          'Не меньше 1 анк/м²',
        ],
        correctAnswers: ['Не меньше 1 анк/м²'],
      },
      {
        code: '63684010',
        text:
          'От чего зависит надежность поддержания горных выработок в нижнем слое?',
        answers: [
          'Только от прочности угля',
          'Только от трещиноватости угля',
          'Только от мощности межслоевой угольной пачки, оставляемой между штреками верхнего и нижнего слоя',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63684011',
        text:
          'Каким должно быть расстояние между откосом уступа, отвала или транспортным средством и контргрузом экскаватора?',
        answers: [
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,5 м',
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м',
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,75 м',
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 0,6 м',
        ],
        correctAnswers: [
          'Устанавливается паспортом забоя в зависимости от горно-геологических условий и типа оборудования, но в любом случае должно быть не менее 1,0 м',
        ],
      },
      {
        code: '63684012',
        text:
          'В каком случае нарушены требования Правил безопасности при разработке угольных месторождений открытым способом по организации ремонта технологического оборудования?',
        answers: [
          'Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает главный механик угольного разреза',
          'На все виды ремонтов основного технологического оборудования, в соответствии с действующим в организации положением о планово-предупредительных ремонтах должны быть разработаны инструкции (технологические карты, руководства, документация организации работ)',
          'В технологических картах, руководствах, документации организации работ указывается количество приспособлений и инструментов, определяется порядок и последовательность работ, обеспечивающих безопасность их проведения',
          'Работники, выполняющие ремонт, должны быть ознакомлены с инструкциями, технологическими картами и проектами организации работ под подпись',
        ],
        correctAnswers: [
          'Ремонт технологического оборудования должен проводиться в соответствии с графиками обслуживания и ремонта оборудования согласно документации завода-изготовителя. Годовые и месячные графики ремонтов утверждает главный механик угольного разреза',
        ],
      },
      {
        code: '63684013',
        text:
          'На каком расстоянии от воздушных линий должен быть расположен гидромонитор без разработки и осуществления мероприятий, обеспечивающих безопасность работ, утвержденных техническим руководителем (главным инженером) угольного разреза?',
        answers: [
          'На расстоянии не менее двукратной дальности полета струи',
          'На расстоянии не менее полуторной дальности полета струи',
          'На расстоянии не менее однократной дальности полета струи',
          'На расстоянии не менее трехкратной дальности полета струи',
        ],
        correctAnswers: [
          'На расстоянии не менее двукратной дальности полета струи',
        ],
      },
      {
        code: '63684014',
        text:
          'На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ?',
        answers: [
          'Не более 3 лет',
          'Не более 5 лет',
          'Не более 1 года',
          'Не регламентируется',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63684015',
        text:
          'Какой должна быть максимальная высота уступа при разработке вручную рыхлых устойчивых плотных пород?',
        answers: ['3 м', '5 м', '6 м', '7 м', '4 м'],
        correctAnswers: ['6 м'],
      },
      {
        code: '63684016',
        text: 'Что указывается на плане горного отвода?',
        answers: [
          'Площадь проекции горного отвода в гектарах на горизонтальную плоскость',
          'Линии вертикальных разрезов (профилей) и проекций',
          'Используемые системы координат и высот, масштаб плана, координатная сетка',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684017',
        text:
          'Что должна содержать пояснительная записка проекта горного отвода в зависимости от видов полезных ископаемых и целей пользования недрами?',
        answers: [
          'Сведения о смежных участках недр, предоставленных в пользование, зонах с особыми условиями использования территорий в границах предоставленного в пользование участка недр (при наличии)',
          'Сведения о пользовании недрами (согласно проектной документации)',
          'Сведения об условиях в области безопасного пользования недрами, рационального использования и охраны недр, содержащихся в неотъемлемых составных частях лицензии на пользование недрами',
          'Геологическую и гидрогеологическую характеристику участка недр и горно-геологических условий, влияющих на параметры сдвижения массива горных пород и деформации объектов, установленные техническим проектом и иной документацией на выполнение работ, связанных с пользованием недрами',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684018',
        text:
          'Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?',
        answers: [
          'В соответствии с законодательством Российской Федерации о государственной тайне',
          'Заказным почтовым отправлением через АО "Почта России"',
          'Через курьерскую службу',
          'Любым из перечисленных способов',
        ],
        correctAnswers: [
          'В соответствии с законодательством Российской Федерации о государственной тайне',
        ],
      },
      {
        code: '63684019',
        text:
          'Кому из перечисленных лиц разрешается проезд в многоместных кабинах транспортных средств, в железнодорожных составах и кабинах локомотивов?',
        answers: [
          'Только лицам, сопровождающим составы',
          'Только сменному надзору',
          'Всем перечисленным лицам',
          'Только отдельным работникам при наличии у них письменного разрешения технического руководителя (главного инженера) угольного разреза',
        ],
        correctAnswers: ['Всем перечисленным лицам'],
      },
      {
        code: '63684020',
        text:
          'Какие их перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны неверно? Выберите два правильных варианта ответа.',
        answers: [
          'Рабочие площадки приемных и разгрузочных устройств и бункеров обязательно должны быть оборудованы звуковой сигнализацией, предназначенной для оповещения обслуживающего персонала о прибытии железнодорожного состава',
          'Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м',
          'Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м',
          'Сигналы о прибытии железнодорожного состава подаются за 1,5 - 2 минуты до момента прибытия транспортных средств',
          'На приемных бункерах должен быть установлен светофор, разрешающий или запрещающий въезд транспортного средства на площадку бункера под разгрузку',
        ],
        correctAnswers: [
          'Разгрузочные площадки для железнодорожного транспорта и автосамосвалов ограждаются перилами высотой не более 1 м в местах возможного прохода людей с обшивкой их по низу на высоту 0,15 м',
          'Проемы бункеров должны ограждаться с нерабочих сторон перилами высотой не менее 1 м со сплошной обшивкой их по низу полосой на высоту 0,15 м',
        ],
      },
      {
        code: '63684021',
        text:
          'В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?',
        answers: [
          'До 1 августа текущего года',
          'До 15 августа текущего года',
          'До 1 сентября текущего года',
          'До 15 сентября текущего года',
        ],
        correctAnswers: ['До 1 сентября текущего года'],
      },
      {
        code: '63684022',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к проведению ремонта технологического оборудования?',
        answers: [
          'Ремонт и замену частей механизмов допускается проводить только после полной остановки машины, снятия давления в гидравлических и пневматических системах, блокировки пусковых аппаратов, приводящих в движение механизмы, на которых производятся ремонтные работы; допускается при выполнении ремонтных работ подача электроэнергии по документации организации работ',
          'Запрещается проведение ремонтных работ в непосредственной близости от открытых движущихся частей механических установок, а также вблизи электрических проводов и токоведущих частей, находящихся под напряжением, при отсутствии их ограждения',
          'Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ',
          'При выполнении ремонтных работ подрядной организацией ответственные представители заказчика и подрядчика должны оформить на весь период выполнения работ наряд-допуск, разработать и осуществить организационно-технические мероприятия, направленные на повышение безопасного ведения ремонтных работ',
        ],
        correctAnswers: [
          'Ремонты, связанные с изменением несущих металлоконструкций основного технологического оборудования, должны проводиться по документации, согласованной с территориальным органом Ростехнадзора, с составлением акта выполненных работ',
        ],
      },
      {
        code: '63684023',
        text:
          'В каком случае допускается подвеска к анкерной крепи оборудования?',
        answers: [
          'Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом',
          'Допускается в случае выполнения аварийно-спасательных работ',
          'Не допускается ни в каком случае',
          'Допускается в любом случае при технической необходимости',
        ],
        correctAnswers: [
          'Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом',
        ],
      },
      {
        code: '63684024',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'Со специализированными профессиональными аварийно-спасательными формированиями',
          'Со сторонними организациями, расположенными на территории горного отвода',
          'С территориальными органами Ростехнадзора',
          'С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63684025',
        text:
          'Каков минимальный период проверки и контрольной наладки (испытания) основной селективной защиты от замыкания на землю на подстанциях, от которых получают питание передвижные карьерные электроустановки?',
        answers: ['3 месяца', '12 месяцев', 'Не нормируется', '6 месяцев'],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63684026',
        text:
          'Что из перечисленного не соответствует требованиям организации безопасного обслуживания электроустановок и сетей?',
        answers: [
          'Лица, ответственные за безопасную эксплуатацию электроустановок, должны быть обучены и аттестованы на знание правил безопасной эксплуатации электроустановок',
          'Лица, работающие в электроустановках и на ЛЭП, должны выполнять организационные и технические мероприятия, предусмотренные требованиями Правил безопасности при разработке угольных месторождений открытым способом и других нормативных правовых актов по безопасной эксплуатации электроустановок',
          'Для организации безопасного обслуживания электроустановок и сетей должны быть определены и оформлены распоряжениями технического руководителя (главного инженера) угольного разреза границы обслуживания электротехническим персоналом, назначены лица, ответственные за безопасную эксплуатацию электроустановок по организации и структурным подразделениям',
          'При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение трех суток',
        ],
        correctAnswers: [
          'При изменении электроснабжения электроустановки все изменения необходимо внести в схему электроснабжения в течение трех суток',
        ],
      },
      {
        code: '63684027',
        text:
          'В каких случаях документация, удостоверяющая уточненные границы горного отвода, подлежит переоформлению?',
        answers: [
          'Только в случае переоформления лицензии на пользование недрами',
          'Только в случаях изменения геологической информации о недрах, наличия технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов, в том числе при изменении технического проекта выполнения работ, связанных с пользованием недрами',
          'Только в случаях изменения срока пользования участком недр, установленного в лицензии на пользование недрами',
          'Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами',
        ],
      },
      {
        code: '63684028',
        text:
          'Какое из приведенных положений требований безопасности указано неверно?',
        answers: [
          'При невозможности вывода техники в случае угрозы ее повреждения техника должна быть ограждена и установлены знаки, предупреждающие об опасности',
          'На угольном разрезе запрещается нахождение людей в опасной зоне работающих механизмов',
          'При остановке работ на угольном разрезе запрещается нахождение на его территории лиц, не связанных с обеспечением его жизнедеятельности',
        ],
        correctAnswers: [
          'При невозможности вывода техники в случае угрозы ее повреждения техника должна быть ограждена и установлены знаки, предупреждающие об опасности',
        ],
      },
      {
        code: '63684029',
        text:
          'Кем утверждается документация, по которой должны производиться ремонты по восстановлению несущих металлоконструкций?',
        answers: [
          'Руководителем завода-изготовителя',
          'Техническим руководителем (главным инженером) угольного разреза',
          'Руководителем проектной организации',
          'Представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Техническим руководителем (главным инженером) угольного разреза',
        ],
      },
      {
        code: '63684030',
        text:
          'На какую величину меньше высоты подвески контактного провода должна быть минимальная высота габаритных ворот около переездов с обеих сторон на всех пересечениях электрифицированных путей с автомобильными дорогами и пунктами, где проводятся погрузочно-разгрузочные работы?',
        answers: ['На 0,3 м', 'На 0,4 м', 'На 0,5 м', 'На 0,6 м'],
        correctAnswers: ['На 0,5 м'],
      },
      {
        code: '63684031',
        text:
          'Что должны включать графические материалы проекта горного отвода при разработке месторождений полезных ископаемых, представленных комплексом рудных тел, а также месторождений угля? Укажите все правильные ответы.',
        answers: [
          'Геологическое строение участка недр',
          'Погоризонтные планы рудных тел и пластов',
          'Гипсометрические планы рудных тел и пластов',
          'Контуры запасов полезных ископаемых по категориям геологической изученности',
          'Тектонические нарушения',
        ],
        correctAnswers: [
          'Погоризонтные планы рудных тел и пластов',
          'Гипсометрические планы рудных тел и пластов',
        ],
      },
      {
        code: '63684032',
        text:
          'Что из перечисленного используется для обозначения границ опасных зон на местности в процессе ведения горных работ на разрезе?',
        answers: [
          'Только предохранительные валы',
          'Только предупредительные знаки',
          'Только ограждения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684033',
        text:
          'Что должны включать табличные материалы пояснительной записки планов (схем) по видам горных работ и видам полезных ископаемых?',
        answers: [
          'Сведения о состоянии и движении вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых по состоянию на начало планируемого периода и ожидаемого на конец этого периода (при добыче твердых полезных ископаемых)',
          'Все перечисленное',
          'Сведения о состоянии и движении фонда скважин (при разработке месторождений углеводородного сырья)',
          'Основные технические показатели на предстоящий период (план) и ожидаемые на конец текущего года (факт)',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684034',
        text:
          'Каким должно быть минимальное расстояние по горизонтали между экскаваторами, расположенными на одном горизонте?',
        answers: [
          'Равным полуторной сумме их наибольших радиусов действия',
          'Равным сумме их наибольших радиусов действия',
          'Равным удвоенной сумме их наибольших радиусов действия',
          'Равным утроенной сумме их наибольших радиусов действия',
        ],
        correctAnswers: ['Равным сумме их наибольших радиусов действия'],
      },
      {
        code: '63684035',
        text:
          'Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?',
        answers: [
          'Только применение средств и технологий, повышающих безопасное ведение горных работ и уровень извлечения полезных ископаемых',
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
          'Только рекультивацию нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых)',
          'Только защиту объектов ведения горных работ и месторождений полезных ископаемых от затопления, обводнения, взрывов, пожаров, горных ударов',
        ],
        correctAnswers: [
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
      },
      {
        code: '63684036',
        text:
          'В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?',
        answers: [
          'Не мельче 1:15 000',
          'Не мельче 1:10 000',
          'Не мельче 1:35 000',
          'Не мельче 1:25 000',
        ],
        correctAnswers: ['Не мельче 1:10 000'],
      },
      {
        code: '63684037',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара до 60 тонн?',
        answers: ['1700 мм', '1600 мм', '1500 мм', '1300 мм', '1400 мм'],
        correctAnswers: ['1600 мм'],
      },
      {
        code: '63684038',
        text:
          'Каким должно быть расстояние от гидромониторной установки и другого забойного оборудования (скреперов, бульдозеров) до забоя при гидромеханизированном способе разработки глинистых, плотных и лессовидных пород, способных к обрушению глыбами?',
        answers: [
          'Не менее 1,2 высоты уступа',
          'Не менее 0,8 высоты уступа',
          'Не менее 1,0 высоты уступа',
          'Не менее 0,5 высоты уступа',
        ],
        correctAnswers: ['Не менее 1,2 высоты уступа'],
      },
      {
        code: '63684039',
        text:
          'Какие требования при проектировании паспортов крепления и поддержания подготовительных выработок анкерной крепью указаны неверно?',
        answers: [
          'Анкеры в кровле следует располагать на расстоянии их от боков не более 0,3 м',
          'Анкеры в боках следует располагать так, чтобы они пересекали наиболее слабые слои угля и пород, т. е. с отклонением от напластования на угол 15 - 30°',
          'Анкеры в кровле следует располагать с отклонением крайних анкеров от нормали к напластованию на угол 15 - 20°',
          'Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м',
        ],
        correctAnswers: [
          'Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м',
        ],
      },
      {
        code: '63684040',
        text:
          'На каких участках возможных деформаций выполняют закладку наблюдательных станций?',
        answers: [
          'На участках слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал',
          'На участках слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание, близкое к простиранию борта',
          'На всех перечисленных',
        ],
        correctAnswers: ['На всех перечисленных'],
      },
      {
        code: '63684041',
        text:
          'На каком минимальном расстоянии от работающих механизмов на территории складирования горной массы (пород), на разгрузочных площадках, перегрузочных пунктах (складах) должны находиться люди?',
        answers: ['4,0 м', '3,0 м', 'Не нормируется', '5,0 м'],
        correctAnswers: ['5,0 м'],
      },
      {
        code: '63684042',
        text:
          'Каким должно быть расстояние между концом отвальной консоли транспортно-отвального моста и гребнем отвала?',
        answers: [
          'Не менее 1,5 м',
          'Не менее 1,0 м',
          'Не менее 3,0 м',
          'Не менее 2,0 м',
        ],
        correctAnswers: ['Не менее 3,0 м'],
      },
      {
        code: '63684043',
        text:
          'Каким должно быть минимальное расстояние между стоящими на разгрузке и проезжающими транспортными средствами?',
        answers: ['3 м', '2 м', '6 м', '5 м', '4 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63684044',
        text:
          'Что из перечисленного не соответствует требованиям по обеспечению безопасности движения подвижного состава при ремонте сооружений и устройств?',
        answers: [
          'Запрещается снимать сигналы, ограждающие места работ, до полного их окончания, а также до проверки состояния пути, контактной сети и соблюдения габарита',
          'Запрещается приступать к работам до ограждения сигналами мест производства работ, опасных для следования подвижного состава',
          'Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда',
          'Перед началом путевых ремонтных работ руководитель обязан проинструктировать работников об условиях безопасного производства этих работ и указать места, куда работники должны уходить во время прохода поездов, а также предупредить дежурного по станции и согласовать с ним условия работы',
        ],
        correctAnswers: [
          'Места производства работ, опасные для следования подвижного состава, должны ограждаться сигналами с обеих сторон только на однопутных участках. На двух- и многопутных участках дорог - только на участках, на которых ожидается следование поезда',
        ],
      },
      {
        code: '63684045',
        text:
          'На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?',
        answers: [
          'На 1 год',
          'На срок действия лицензии на пользование недрами',
          'На 2 года',
          'Бессрочно',
        ],
        correctAnswers: ['На срок действия лицензии на пользование недрами'],
      },
      {
        code: '63684046',
        text:
          'По каким данным могут быть скорректированы в процессе эксплуатации предельные углы откосов уступов и бортов угольного разреза (углы устойчивого борта), в том числе временно консервируемых участков бортов? Выберите два правильных варианта ответа.',
        answers: [
          'По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвала',
          'По требованиям дополнений к технической документации ведения горных работ на угольных разрезах, если это не выполнено в проекте на отработку месторождения',
          'По данным геолого-маркшейдерской службы угольного разреза',
          'По данным планов горных работ (в масштабе 1:5000 или 1:2000)',
        ],
        correctAnswers: [
          'По результатам дополнительных изысканий инженерно-геологических условий массива борта угольного разреза и отвала',
          'По данным геолого-маркшейдерской службы угольного разреза',
        ],
      },
      {
        code: '63684047',
        text:
          'До какой максимальной высоты над уровнем земли разрешается использование конвейеров, не оборудованных по всей длине с обеих сторон непрерывными боковыми площадками шириной не менее 0,3 м?',
        answers: ['0,8 м', '1,1 м', '1,8 м', '1,5 м', '1,3 м'],
        correctAnswers: ['1,8 м'],
      },
      {
        code: '63684048',
        text:
          'Кем подписывается проект горного отвода (пояснительная записка)?',
        answers: [
          'Руководителем организации, испрашивающей горный отвод, и руководителем организации, разработавшей проект горного отвода',
          'Техническим руководителем организации, испрашивающей горный отвод, главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода',
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
          'Главным геологом и главным маркшейдером организации, испрашивающей горный отвод, ответственным специалистом - разработчиком проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода',
        ],
        correctAnswers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
        ],
      },
      {
        code: '63684049',
        text:
          'Какой минимальной ширины должны быть маршевые лестницы, устраиваемые на высоте более 10 м для сообщения между уступами разреза?',
        answers: ['0,6 м', '0,7 м', '0,8 м', '0,9 м', '1,0 м'],
        correctAnswers: ['0,8 м'],
      },
      {
        code: '63684050',
        text:
          'С какой периодичностью должно осматриваться каждое рабочее место начальником участка или его заместителем?',
        answers: [
          'Один раз в 2 дня',
          'Один раз в 2 недели',
          'В течение смены',
          'В течение суток',
        ],
        correctAnswers: ['В течение суток'],
      },
      {
        code: '63684051',
        text:
          'Какой должна быть минимальная ширина переходных мостиков через ленточные конвейеры?',
        answers: ['0,8 м', '1,2 м', '1,0 м', '0,7 м'],
        correctAnswers: ['0,8 м'],
      },
      {
        code: '63684052',
        text:
          'Каким должно быть превышение гребня гидроотвала у верхового откоса над пляжем при выпуске пульпы на пляж для исключения перелива на гребень и низовой откос дамбы?',
        answers: [
          'Не менее диаметра пульповыпуска, но не менее 0,5 м',
          'Не менее диаметра пульповыпуска, но не менее 1 м',
          'Не менее диаметра пульповыпуска, но не менее 1,5 м',
          'Равным диаметру пульповыпуска',
        ],
        correctAnswers: ['Не менее диаметра пульповыпуска, но не менее 0,5 м'],
      },
      {
        code: '63684053',
        text:
          'Что не соответствует требованиям к состоянию горнотранспортных и строительно-дорожных машин в нерабочее время?',
        answers: [
          'С питающего кабеля напряжение не снимается',
          'При отсутствии экипажа кабина запирается',
          'Рабочий орган (ковш) должен быть опущен на грунт',
          'Машины должны быть выведены из забоя в безопасное место',
        ],
        correctAnswers: ['С питающего кабеля напряжение не снимается'],
      },
      {
        code: '63684054',
        text:
          'В каком случае требования по подвеске проводов электрического освещения и светильников на стационарных и передвижных опорах контактной сети постоянного тока напряжением до 1650 В включительно указаны неверно?',
        answers: [
          'Расстояние от контактного провода до проводов освещения по вертикали должно быть не менее 1,5 м',
          'Изоляторы осветительной сети принимаются на напряжение 1650 В',
          'Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м',
          'Подвеска проводов электрического освещения и светильников допускается выше контактного провода на противоположной от него стороне опоры',
        ],
        correctAnswers: [
          'Расстояние от контактного провода до опоры при боковой подвеске должно быть не менее 0,5 м',
        ],
      },
      {
        code: '63684055',
        text:
          'Какие действия не соответствуют требованиям безопасности при разработке полезных ископаемых с повышенным радиационным фоном?',
        answers: [
          'На угольных разрезах, разрабатывающих сопутствующие полезные ископаемые с повышенным радиационным фоном, осуществляется радиационный контроль; результаты замеров радиационного фона фиксируются в книге учета радиационного фона',
          'Для контроля уровня радиоактивности грунтовых вод должны быть предусмотрены пробоотборные (наблюдательные) скважины по периметру гидроотвала и по направлению потока грунтовых вод',
          'Местоположение и число скважин определяются в зависимости от гидрогеологических условий с таким расчетом, чтобы расстояние между скважинами было не менее 300 м; при этом 2 скважины, диаметрально расположенные, должны быть за пределами санитарно-защитной зоны',
          'Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки',
        ],
        correctAnswers: [
          'Для устранения возможного пылеобразования и разноса радиоактивных аэрозолей с поверхности намывного откоса при эксплуатации гидроотвала его необходимо покрывать по мере намыва до проектных отметок распыляемыми аэрозолями до образования защитной пленки',
        ],
      },
      {
        code: '63684056',
        text:
          'Где разрешается применение напряжения 380/220 В в сети с заземленной нейтралью для освещения?',
        answers: [
          'Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций',
          'Для осветительных сетей на угольном разрезе',
          'Для стационарных световых точек на передвижных машинах',
          'Для стационарных световых точек на механизмах и агрегатах',
        ],
        correctAnswers: [
          'Для освещения отвалов и автомобильных дорог вне угольного разреза при питании от отдельных трансформаторных подстанций',
        ],
      },
      {
        code: '63684057',
        text:
          'Какие схемы электроснабжения должны быть в наличии на угольном разрезе?',
        answers: [
          'Только принципиальная однолинейная схема',
          'Только отдельная схема электроснабжения для сезонных электроустановок перед вводом их в работу',
          'Только схема электроснабжения, нанесенная на план горных работ, утвержденная техническим руководителем (главным инженером) угольного разреза',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63684058',
        text:
          'Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при однополосном движении?',
        answers: [
          'Не менее 7,5 м',
          'Не менее 5,5 м',
          'Не менее 8,5 м',
          'Не менее 10,0 м',
        ],
        correctAnswers: ['Не менее 7,5 м'],
      },
      {
        code: '63684059',
        text:
          'Как должны производиться перегон горнотранспортных и строительно-дорожных машин (экскаваторов, буровых станков) и перевозки их на транспортных средствах на расстояние более 1 км, а также при необходимости отключения пересекаемых воздушных линий электропередачи?',
        answers: [
          'В соответствии с документацией на производство работ, утвержденной техническим руководителем (главным инженером) угольного разреза',
          'В соответствии с техническими инструкциями завода-изготовителя',
          'С обязательным уведомлением территориального органа Ростехнадзора, осуществляющего федеральный государственный надзор в области промышленной безопасности за угольным разрезом',
          'В соответствии с планом мероприятий по локализации и ликвидации последствий аварий',
          'В соответствии с планом развития горных работ',
        ],
        correctAnswers: [
          'В соответствии с документацией на производство работ, утвержденной техническим руководителем (главным инженером) угольного разреза',
        ],
      },
      {
        code: '63684060',
        text:
          'К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли свыше 200 мм?',
        answers: [
          'К I категории (небольшое горное давление)',
          'К II категории (горное давление средней интенсивности)',
          'К III категории (интенсивное горное давление)',
        ],
        correctAnswers: ['К III категории (интенсивное горное давление)'],
      },
      {
        code: '63684061',
        text:
          'Кто из перечисленных лиц назначает комиссию по приемке в эксплуатацию строительно-дорожных машин, горнотранспортного и технологического оборудования после монтажа?',
        answers: [
          'Представитель государственной инспекции труда',
          'Командир специализированного профессионального аварийно-спасательного формирования',
          'Технический руководитель (главный инженер) угольного разреза',
          'Руководитель территориального органа Ростехнадзора',
          'Представитель исполнительной власти муниципального образования, на территории которого находится разрез',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63684062',
        text:
          'В каком случае допускается подработка железных дорог и транспортных сооружений при деформациях земной поверхности, превышающих предельные по Правилам охраны сооружений и природных объектов от вредного влияния подземных горных разработок на угольных месторождениях?',
        answers: [
          'В случае применения горных мер защиты',
          'Подработка не допускается',
          'Подработка производится без ограничений',
          'В случае согласования с ОАО "РЖД"',
        ],
        correctAnswers: ['В случае применения горных мер защиты'],
      },
      {
        code: '63684063',
        text:
          'В каком из перечисленных случаев основным критерием определения безопасной высоты уступа являются расчеты с учетом траектории движения рабочего органа (ковша) экскаватора (погрузчика)?',
        answers: [
          'При применении гидравлических экскаваторов и погрузчиков',
          'При разработке пород с применением буровзрывных работ',
          'При применении канатных экскаваторов',
          'При применении экскаваторов с удлиненным рабочим оборудованием',
          'При разработке уступа вручную',
        ],
        correctAnswers: [
          'При применении гидравлических экскаваторов и погрузчиков',
        ],
      },
      {
        code: '63684064',
        text:
          'Каким должно быть расстояние между пробоотборными (наблюдательными) скважинами, располагаемыми по периметру гидроотвала и по направлению потока грунтовых вод для контроля уровня радиоактивности грунтовых вод?',
        answers: [
          'Не менее 300 м',
          'Не менее 400 м',
          'Не менее 500 м',
          'Не менее 700 м',
        ],
        correctAnswers: ['Не менее 300 м'],
      },
      {
        code: '63684065',
        text:
          'С какой периодичностью должна проверяться исправность и комплектность машин механиком, энергетиком участка?',
        answers: [
          'Один раз в квартал',
          'Один раз в 2 недели',
          'Еженедельно',
          'Один раз в месяц',
        ],
        correctAnswers: ['Еженедельно'],
      },
      {
        code: '63684066',
        text:
          'Кем осуществляется подача сигналов при погрузке экскаваторами в железнодорожные вагоны и разгрузке их на экскаваторных отвалах?',
        answers: [
          'Лицом, назначенным приказом технического руководителя (главного инженера) угольного разреза',
          'Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта',
          'Машинистом поезда в соответствии с сигналами, установленными при эксплуатации угольного разреза',
          'Мастером смены',
        ],
        correctAnswers: [
          'Машинистом экскаватора в соответствии с сигналами, установленными при эксплуатации железнодорожного транспорта',
        ],
      },
      {
        code: '63684067',
        text:
          'Что является коэффициентом запаса устойчивости при детерминированном подходе?',
        answers: [
          'Допустимая вероятность развития деформаций, которые определяются проектной документацией с учетом размещения элементов инфраструктуры объекта недропользования',
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
          'Действие удерживающих и сдвигающих сил, перенесенное на потенциальную (наиболее напряженную) поверхность скольжения во всех инженерных методах',
        ],
        correctAnswers: [
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
        ],
      },
      {
        code: '63684068',
        text: 'К какому классу относится "неустойчивая кровля"?',
        answers: ['К I классу', 'К II классу', 'К III классу'],
        correctAnswers: ['К I классу'],
      },
      {
        code: '63684069',
        text:
          'Что является основой классификации на аварии и инциденты происшествий, происходящих на опасных производственных объектах угольной промышленности?',
        answers: [
          'Место происшествия',
          'Вид происшествия',
          'Все перечисленное',
          'Размер экономического ущерба, причиненный происшествием предприятию, выраженный в длительности простоя оборудования',
        ],
        correctAnswers: [
          'Размер экономического ущерба, причиненный происшествием предприятию, выраженный в длительности простоя оборудования',
        ],
      },
      {
        code: '63684070',
        text:
          'Какими должны быть размеры укрытий (труб, коробов и т. п.), применяемых в целях защиты от повреждений кабелей, в местах пересечения с железнодорожными путями и автомобильными дорогами?',
        answers: [
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,2 м в каждую сторону',
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону',
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,7 м в каждую сторону',
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 1,5 м в каждую сторону',
        ],
        correctAnswers: [
          'Должны превышать ширину железнодорожных путей или автомобильных дорог не менее чем на 2 м в каждую сторону',
        ],
      },
      {
        code: '63684071',
        text:
          'С кем согласовываются графики проведения учебных тревог на угольном разрезе?',
        answers: [
          'С руководителем профсоюзной организации угольного разреза',
          'С руководителем органа местного самоуправления, на территории которого располагается угольный разрез',
          'С руководителем подразделения ПАСС(Ф), обслуживающего угольный разрез',
          'С руководителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'С руководителем подразделения ПАСС(Ф), обслуживающего угольный разрез',
        ],
      },
      {
        code: '63684072',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Название графического документа',
          'Дата составления',
          'Все ответы неверны',
          'Наименования организации, объекта недропользования (участка недр)',
          'Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63684073',
        text:
          'Какой следует принимать расчетную несущую способность анкера Nа для горных выработок и сопряжений со сроком службы свыше 40 лет и в обводненных породах?',
        answers: [
          'Следует корректировать в соответствии с фактической, определяемой экспериментально в шахтных условиях',
          'Следует принимать на 20% меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
          'Следует принимать в соответствии с технической документацией с учетом уменьшения расчетной несущей способности при различных условиях работы анкеров',
        ],
        correctAnswers: [
          'Следует принимать на 20% меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
        ],
      },
      {
        code: '63684074',
        text:
          'Как квалифицируются происшествия на опасных производственных объектах, сопровождающиеся несчастными (групповыми) случаями?',
        answers: [
          'Происшедшие несчастные случаи не влияют на классификацию, предусмотренную в перечне аварий и в перечне инцидентов',
          'В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии или инцидент, на основании решения технического руководства организации',
          'В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии или инцидент, на основании указания Ростехнадзора',
          'В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии',
        ],
        correctAnswers: [
          'В случае происшествий, сопровождающихся несчастными (групповыми) случаями, их следует квалифицировать как аварии',
        ],
      },
      {
        code: '63684075',
        text:
          'Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней диаметром 20 мм с метрической резьбой из стали Ст.5?',
        answers: ['100 кН', '160 кН', '75 кН', '130 кН'],
        correctAnswers: ['100 кН'],
      },
      {
        code: '63684076',
        text:
          'Какие из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом по профилактике эндогенных пожаров указаны верно?',
        answers: [
          'Способы замера температуры, концентрации угарного газа должны определяться инспектором Ростехнадзора',
          'На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза',
          'Способы обнаружения очагов пожаров и наблюдений за внешними признаками самонагревания угля должны определяться представителем пожарной части',
          'Места установки датчиков должны определяться представителем подразделения специализированного профессионального аварийно-спасательного формирования',
          'При обнаружении признаков самонагревания угля должны быть приняты меры, предусмотренные планом по профилактике и тушению экзогенных пожаров на угольном разрезе',
        ],
        correctAnswers: [
          'На угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию, должны выполняться мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, профилактике и тушению пожаров, утвержденные техническим руководителем (главным инженером) угольного разреза',
        ],
      },
      {
        code: '63684077',
        text:
          'Какой должна быть ширина проезжей дороги на переездах временных железнодорожных путей для автосамосвалов грузоподъемностью до 10 т при двухполосном движении?',
        answers: [
          'Не менее 6,5 м',
          'Не менее 8,0 м',
          'Не менее 10,0 м',
          'Не менее 7,5 м',
        ],
        correctAnswers: ['Не менее 10,0 м'],
      },
      {
        code: '63684078',
        text:
          'Кто из перечисленных лиц назначает комиссию по оценке качества проведенного планового текущего и капитального ремонта?',
        answers: [
          'Представитель территориального органа Ростехнадзора',
          'Представитель завода-изготовителя',
          'Главный механик угольного разреза',
          'Технический руководитель (главный инженер) угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63684079',
        text:
          'Кем осуществляются подготовка и утверждение планов и схем развития горных работ?',
        answers: [
          'Подготовка - пользователем недр, утверждение - руководителем территориального органа государственного горного надзора',
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - руководителем территориального органа государственного горного надзора',
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - представителем местной исполнительной власти',
        ],
        correctAnswers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
        ],
      },
      {
        code: '63684080',
        text:
          'Что не отображается на планах поверхности при составлении планов и схем развития горных работ?',
        answers: [
          'Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов',
          'Устья выходящих на поверхность действующих, законсервированных и ликвидированных горных выработок',
          'Места образования провалов, оползней, прорыва плывунов',
          'Все перечисленное',
          'Объекты наземных и подземных коммуникаций (при наличии)',
          'Объекты застройки площади залегания полезных ископаемых (горного отвода) с выделением зданий и сооружений, подлежащих охране от вредного влияния горных работ',
        ],
        correctAnswers: [
          'Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов',
        ],
      },
      {
        code: '63684081',
        text:
          'Что из перечисленного должна обеспечивать высота уступа для экскаваторов с удлиненным рабочим оборудованием?',
        answers: [
          'Возможность контроля ширины рабочих площадок и углов откоса уступов',
          'Видимость соседних рабочих уступов',
          'Видимость транспортных сосудов из кабины машиниста экскаватора',
          'Видимость всех формируемых временно неработающих бортов',
        ],
        correctAnswers: [
          'Видимость транспортных сосудов из кабины машиниста экскаватора',
        ],
      },
      {
        code: '63684082',
        text:
          'С какой периодичностью должен проводиться визуальный контроль работоспособности анкерной крепи в зонах влияния очистных работ?',
        answers: [
          'Ежесуточно',
          'Еженедельно',
          'Один раз в месяц',
          'Один раз в квартал',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63684083',
        text:
          'Чем из перечисленного в обязательном порядке должен быть оборудован пункт первой медицинской помощи на каждом угольном разрезе?',
        answers: [
          'Сатураторной установкой',
          'Телефонной связью',
          'Системами видеонаблюдения',
          'Не регламентируется',
        ],
        correctAnswers: ['Телефонной связью'],
      },
      {
        code: '63684084',
        text:
          'Что из перечисленного не соответствует требованиям безопасности к расположению гибкого кабеля, питающего передвижные механизмы?',
        answers: [
          'Гибкие кабели должны подвешиваться с провисом',
          'Расположение кабеля на почве должно исключать возможность его повреждения движущейся машиной',
          'Высота подвеса кабеля должна исключать возможность его повреждения',
          'Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м',
        ],
        correctAnswers: [
          'Допускается располагать на почве часть гибкого кабеля, питающего передвижные механизмы, на протяжении не более 25 м',
        ],
      },
      {
        code: '63684085',
        text:
          'На какую деятельность на опасных производственных объектах юридических лиц и индивидуальных предпринимателей распространяются Правила безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Только на деятельность, связанную с разработкой угольных месторождений открытым способом',
          'Только на конструирование, изготовление, монтаж, эксплуатацию и ремонт технических устройств',
          'Только на проектирование, строительство и эксплуатацию угольного разреза',
          'На все перечисленное',
        ],
        correctAnswers: ['На все перечисленное'],
      },
      {
        code: '63684086',
        text:
          'Кто устанавливает сроки приведения действующего угольного разреза в соответствие с требованиями Правил безопасности при разработке угольных месторождений открытым способом? Выберите все варианты ответов.',
        answers: [
          'Представитель территориального органа Ростехнадзора',
          'Представитель органа местного самоуправления, на территории которого располагается разрез',
          'Технический руководитель (главный инженер) угольного разреза',
          'Руководитель угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
          'Руководитель угольного разреза',
        ],
      },
      {
        code: '63684087',
        text:
          'Что не соответствует требованиям по выбору и контролю за состоянием подъемного каната бурового станка?',
        answers: [
          'Не реже одного раза в семь дней механик участка или другое назначенное лицо должен проводить наружный осмотр каната и делать запись о результатах осмотра в книге приема-передачи смен или книге осмотра канатов',
          'Выступающие концы проволок должны быть обрезаны',
          'Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности',
          'При наличии в подъемном канате более 10% порванных проволок на длине шага свивки его следует заменить',
        ],
        correctAnswers: [
          'Подъемный канат бурового станка должен рассчитываться на максимальную нагрузку и иметь трехкратный запас прочности',
        ],
      },
      {
        code: '63684088',
        text:
          'Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации и обслуживанию технологического оборудования, технических устройств, машин и механизмов, используемых на разрезе?',
        answers: [
          'Технические устройства, в том числе иностранного производства, должны иметь заводские паспорта, руководства (инструкции) по монтажу, эксплуатации, техническому обслуживанию и ремонту, оформленные на русском языке с указанием метрической системы измерений',
          'Эксплуатация, обслуживание технологического оборудования, технических устройств, транспортирование и хранение машин и оборудования, их узлов и деталей, а также их монтаж и демонтаж должны производиться в соответствии с документацией организации-изготовителя и технологическими картами, утвержденными техническим руководителем (главным инженером) угольного разреза',
          'Нормируемые организациями - изготовителями технические характеристики должны выдерживаться на протяжении всего периода эксплуатации оборудования',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684089',
        text:
          'Какими средствами индивидуальной защиты органов дыхания (СИЗОД) должен быть обеспечен обслуживающий персонал на рабочих местах, где концентрация пыли превышает установленные предельно допустимые концентрации (ПДК) содержания пыли и вредных газов?',
        answers: [
          'Респираторами',
          'Изолирующими противогазами',
          'Фильтрующими противогазами',
        ],
        correctAnswers: ['Изолирующими противогазами'],
      },
      {
        code: '63684090',
        text:
          'Какое из требований к ведению горных работ по проведению траншей, разработке уступов и отсыпке отвалов указано неверно?',
        answers: [
          'В процессе эксплуатации параметры уступов и предохранительных берм должны уточняться по результатам исследований физико-механических свойств горных пород и должны быть не менее установленных техническим проектом разработки месторождения',
          'Формирование временно нерабочих бортов угольного разреза и возобновление горных работ на них должны проводиться в соответствии с техническим проектом разработки месторождения и по документации на производство работ, предусматривающими необходимые меры безопасности',
          'Расстояние между смежными бермами при погашении уступов и постановке их в предельное положение, ширина, конструкция и порядок обслуживания предохранительных берм должны быть определены в техническом проекте разработки месторождения с учетом обеспечения устойчивости конструкции борта угольного разреза, безопасной механизированной их очистки',
          'Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м',
        ],
        correctAnswers: [
          'Расстояние между нижними бровками откосов уступа отвала до оси железнодорожного пути или оси конвейера устанавливается проектом и должно быть не менее 6 м',
        ],
      },
      {
        code: '63684091',
        text:
          'По какому из перечисленных проектов следует проводить горные работы вблизи затопленных выработок или водоемов ниже зеркала воды при их максимальном наполнении?',
        answers: [
          'По проекту, предусматривающему сооружение вокруг затопленных выработок или водоемов обваловки',
          'По техническому проекту разработки месторождения и нормативным требованиям к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды',
          'По проекту, предусматривающему установку откачивающих насосов достаточной производительности',
          'По проекту, предусматривающему сооружение в пониженных местах дамб',
        ],
        correctAnswers: [
          'По техническому проекту разработки месторождения и нормативным требованиям к данным условиям разработки с учетом оставления целиков для предотвращения прорыва воды',
        ],
      },
      {
        code: '63684092',
        text:
          'За чем должны проводиться визуальные и инструментальные наблюдения в процессе эксплуатации объектов ведения открытых горных работ для своевременного выявления опасных зон и прогнозирования опасных ситуаций?',
        answers: [
          'За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров',
          'За проявлениями удароопасности горных пород, вмещающих полезное ископаемое',
          'За устойчивостью бортов, уступов, откосов',
          'За закарстованностью месторождения',
        ],
        correctAnswers: ['За устойчивостью бортов, уступов, откосов'],
      },
      {
        code: '63684093',
        text:
          'Из каких документов должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Только из структурных карт, характерных разрезов, проекций, схем',
          'Только из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)',
          'Только из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63684094',
        text:
          'В какой срок со дня поступления заявления и проекта горного отвода осуществляется оформление документации либо принятие мотивированного решения об отказе?',
        answers: [
          'Не позднее 10 дней',
          'Не позднее 15 дней',
          'Не позднее 25 дней',
          'Не позднее 30 дней',
        ],
        correctAnswers: ['Не позднее 25 дней'],
      },
      {
        code: '63684095',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом перед началом работы гидромонитора?',
        answers: [
          'Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками',
          'С места работы гидромонитора должны быть удалены лица, не имеющие отношения к его работе',
          'Из зоны действия струи гидромонитора должны быть удалены все люди',
          'Во время пуска выходное отверстие гидромонитора должно быть всегда направлено в безопасное для окружающих место',
        ],
        correctAnswers: [
          'Территория участка на расстоянии не менее дальности действия струи гидромонитора должна ограждаться предупредительными знаками',
        ],
      },
      {
        code: '63684096',
        text:
          'Что из перечисленного не подлежит профилактической обработке антипирогенами?',
        answers: [
          'Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве автотракторных съездов',
          'Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационный период',
          'Угольные уступы по рабочему борту, имеющие геологические нарушения или нарушения от взрывных работ, со сроком отработки, превышающим продолжительность инкубационного периода их самовозгорания',
          'Угольные и породно-угольные скопления небольших объемов, оставляемые в разрезе в качестве насыпей под временные железнодорожные пути и конвейерные линии',
        ],
        correctAnswers: [
          'Угольные и породно-угольные уступы в зоне геологических нарушений или в местах сосредоточения породно-угольных скоплений, сроки отгрузки которых не превышают инкубационный период',
        ],
      },
      {
        code: '63684097',
        text:
          'На какие перечисленные виды работ не составляются планы и схемы горных работ?',
        answers: [
          'На работы по добыче полезных ископаемых',
          'На маркшейдерские работы',
          'На работы, связанные со вторичной переработкой минерального сырья',
          'На вскрышные работы',
        ],
        correctAnswers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
        ],
      },
      {
        code: '63684098',
        text:
          'Какое минимальное расстояние по горизонтали должно быть между рабочими местами, расположенными на двух смежных по вертикали уступах при ручной разработке?',
        answers: ['10 м', '5 м', '12 м', '3 м', '7 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63684099',
        text:
          'Какой вид лицензии необходимо иметь для права подготовки планов и схем развития горных работ?',
        answers: [
          'Лицензию на детальное изучение (разведку) месторождений полезных ископаемых',
          'Лицензию на геологическое изучение недр',
          'Лицензию на добычу полезных ископаемых',
          'Лицензию на производство маркшейдерских работ',
        ],
        correctAnswers: ['Лицензию на производство маркшейдерских работ'],
      },
      {
        code: '63684100',
        text:
          'По истечении какого срока эксплуатации анкерной крепи в выработках должна быть проведена научно-исследовательская работа с оценкой несущей способности анкеров, коррозионного износа и работоспособности анкерной крепи?',
        answers: ['5 лет', '7 лет', '10 лет', '9 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63684101',
        text: 'В каком случае выполняется одинарное крепление проводов?',
        answers: [
          'При пересечении воздушных линий электропередачи с контактной сетью',
          'При пересечении воздушных линий электропередачи с постоянными технологическими дорогами',
          'При пересечении воздушных линий электропередачи с воздушной линией электропередач',
          'При пересечении воздушных линий электропередачи с автомобильными дорогами, проложенными по уступам и отвалам',
        ],
        correctAnswers: [
          'При пересечении воздушных линий электропередачи с автомобильными дорогами, проложенными по уступам и отвалам',
        ],
      },
      {
        code: '63684102',
        text:
          'Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указано неверно?',
        answers: [
          'Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне',
          'Перед началом работы или движения машины (механизма) машинист обязан убедиться в безопасности персонала, находящегося поблизости',
          'Таблица предупредительных сигналов должна располагаться на работающем механизме или вблизи него. При неправильно поданном или непонятном предупредительном сигнале машины (механизмы) должны быть остановлены',
          'Движущиеся части оборудования, представляющие собой источник опасности для людей, должны быть ограждены, за исключением частей, ограждение которых невозможно из-за их функционального назначения',
          'Перед началом движения машин, железнодорожных составов, транспортных средств, погрузочной техники обязательна подача звуковых и (или) световых предупредительных сигналов, со значением которых должны быть ознакомлены все работники под подпись. Сигналы должны быть слышны (видны) всем работникам в зоне действия машин (механизмов)',
        ],
        correctAnswers: [
          'Предпусковой предупредительный сигнал должен быть звуковым. Продолжительность сигнала должна составлять не менее 3 секунд. Сигнал должен быть слышен по всей опасной зоне',
        ],
      },
      {
        code: '63684103',
        text:
          'Каким должно быть минимальное расстояние по горизонтали между рабочими местами или механизмами, расположенными на двух смежных по вертикали уступах при экскаваторной разработке?',
        answers: [
          'Равным полуторной сумме максимальных радиусов черпания',
          'Равным двойной сумме максимальных радиусов черпания',
          'Равным утроенной сумме максимальных радиусов черпания',
          'Равным максимальному радиусу черпания',
        ],
        correctAnswers: [
          'Равным полуторной сумме максимальных радиусов черпания',
        ],
      },
      {
        code: '63684104',
        text:
          'Какой минимальной ширины должен быть свободный проход с обеих сторон транспортного средства при его движении через временные въезды в траншеи?',
        answers: ['1,5 м', '0,8 м', '2,0 м', '1,7 м'],
        correctAnswers: ['1,5 м'],
      },
      {
        code: '63684105',
        text:
          'Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации бульдозера, скрепера или погрузчика?',
        answers: [
          'Для осмотра рабочего органа (ножа или ковша) снизу его необходимо опустить на надежные подкладки, а двигатель выключить',
          'Запрещается эксплуатация бульдозера (трактора) при отсутствии или неисправности блокировки, исключающей запуск двигателя при включенной коробке передач, или устройства для запуска двигателя из кабины',
          'Допускается работа техники поперек крутых склонов при углах, предусмотренных инструкцией завода-изготовителя',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684106',
        text:
          'Каким документом определяется ширина рабочих площадок с учетом их назначения, а также расположения на них горнотранспортного оборудования, транспортных коммуникаций, линий электроснабжения и связи?',
        answers: [
          'Техническим проектом разработки месторождения и уточняется в документации на производство работ',
          'Локальным проектом, утвержденным техническим руководителем (главным инженером) угольного разреза',
          'Планом производства работ на объекте угольного разреза',
        ],
        correctAnswers: [
          'Техническим проектом разработки месторождения и уточняется в документации на производство работ',
        ],
      },
      {
        code: '63684107',
        text:
          'На кого возлагается ответственность за правильность, своевременное оформление и осуществление проекта подработки железной дороги и транспортных сооружений в части мероприятий, осуществляемых эксплуатирующей организацией? Укажите все правильные ответы.',
        answers: [
          'На главного инженера',
          'На главного маркшейдера',
          'На заместителя начальника дороги по региону',
          'На ответственного за осуществление производственного контроля',
          'На главного механика',
        ],
        correctAnswers: ['На главного инженера', 'На главного маркшейдера'],
      },
      {
        code: '63684108',
        text:
          'На каком минимальном безопасном расстоянии от верхней бровки уступа, определяемом расчетами или проектом, до ближайшей точки опоры станка на спланированной площадке должен устанавливаться буровой станок?',
        answers: ['5 м', '3 м', '2 м', '1,5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63684109',
        text:
          'На что направлены требования Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'На все перечисленное',
          'Только на предупреждение аварий и инцидентов на угольных разрезах',
          'Только на обеспечение готовности к локализации и ликвидации последствий аварий',
          'Только на обеспечение промышленной безопасности',
        ],
        correctAnswers: ['На все перечисленное'],
      },
      {
        code: '63684110',
        text:
          'Какая организация должна согласовать проект на выполнение ремонтных работ, связанных с изменением несущих металлоконструкций основного технологического оборудования?',
        answers: [
          'Специализированная организация по диагностике оборудования',
          'Завод-изготовитель',
          'Проектная организация',
          'Территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['Завод-изготовитель'],
      },
      {
        code: '63684111',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к канатам подвески стрелы экскаваторов, их осмотру и допуску к эксплуатации?',
        answers: [
          'Канаты подлежат осмотру не реже одного раза в месяц механиком участка',
          'На длине шага свивки допускается не более 15% порванных проволок от их общего числа в канате',
          'Торчащие концы оборванных проволок должны быть отрезаны',
          'Подъемные, тяговые и напорные канаты должны осматриваться в сроки, установленные заводом-изготовителем',
        ],
        correctAnswers: [
          'Канаты подлежат осмотру не реже одного раза в месяц механиком участка',
        ],
      },
      {
        code: '63684112',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом с использованием гидромеханизированного способа разработки?',
        answers: [
          'Углы откоса уступов отработанных угольных разрезов не должны превышать углов естественного откоса пород',
          'При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора',
          'Проведение в забое вспомогательных работ допускается только после осмотра забоя, ликвидации козырьков и нависей',
          'Зумпфы землесосов и гидроэлеваторов должны быть закрыты или ограждены в целях исключения падения людей в зумпф',
        ],
        correctAnswers: [
          'При работе гидромониторов навстречу друг другу работу одного из них следует остановить при сближении на расстояние дальности полета максимальной струи более мощного гидромонитора',
        ],
      },
      {
        code: '63684113',
        text:
          'К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли свыше 200 мм?',
        answers: [
          'К I категории (небольшое горное давление)',
          'К II категории (горное давление средней интенсивности)',
          'К III категории (интенсивное горное давление)',
        ],
        correctAnswers: ['К III категории (интенсивное горное давление)'],
      },
      {
        code: '63684114',
        text: 'К какому классу относится "кровля средней устойчивости"?',
        answers: ['К I классу', 'К II классу', 'К III классу'],
        correctAnswers: ['К II классу'],
      },
      {
        code: '63684115',
        text:
          'Какая из перечисленных зон при ведении открытых горных работ не должна относиться к зонам, опасным по геомеханическим условиям?',
        answers: [
          'Участок экзогенных пожаров',
          'Участок борта, нагруженный отвалами, размещенными в пределах призмы возможного обрушения',
          'Участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами',
          'Участок бортов разреза и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)',
          'Горный массив с наклонным и пологим залеганием слоистости в сторону выработанного пространства с тектоническими трещинами в призме возможного обрушения, секущих уступ протяженностью более 0,25 - 0,30 высоты уступа или ослабленных поверхностей',
        ],
        correctAnswers: ['Участок экзогенных пожаров'],
      },
      {
        code: '63684116',
        text:
          'Какие требования Правил безопасности при разработке угольных месторождений открытым способом к проведению разгрузочных работ в одном секторе указаны верно?',
        answers: [
          'Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами',
          'Одновременная работа в одном секторе на отвале бульдозера и автосамосвалов, а на перегрузочном пункте - бульдозера, автосамосвала и экскаватора (погрузчика) должна проводиться по утвержденному регламенту',
          'Расстояние между стоящими на разгрузке, проезжающими транспортными средствами и работающим бульдозером должно быть не менее 3 м',
          'Все перечисленные',
        ],
        correctAnswers: [
          'Работа на отвале и перегрузочном пункте должна производиться в соответствии с проектом ведения работ и регулироваться знаками и аншлагами',
        ],
      },
      {
        code: '63684117',
        text:
          'В каком случае нарушены требования по прокладке и переноске (перетаскиванию) гибкого кабеля?',
        answers: [
          'Гибкие кабели, питающие передвижные машины, необходимо прокладывать так, чтобы исключалась возможность их повреждения, примерзания, завала породой, наезда на них транспортных средств и механизмов',
          'Переноска (перетаскивание) гибкого кабеля должна производиться с помощью механизмов с применением приспособлений, обеспечивающих ограничение радиуса изгиба кабеля, или вручную',
          'На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы',
          'При переноске (перетаскивании) экскаваторного кабеля, находящегося под напряжением, обслуживающий персонал обязан пользоваться диэлектрическими перчатками или специальными устройствами с изолирующими рукоятками',
        ],
        correctAnswers: [
          'На обводненной площади кабель прокладывается по дну, для исключения самопроизвольного перемещения используются пригрузы',
        ],
      },
      {
        code: '63684118',
        text:
          'Чему равно значение коэффициента разрыхления пород при вынимаемой мощности подрабатывающего пласта до 1 м и прочности пород непосредственной кровли подрабатывающего пласта до 30 МПа?',
        answers: ['1,35 - 1,40', '1,15 - 1,20', '1,30 - 1,35', '1,20 - 1,25'],
        correctAnswers: ['1,15 - 1,20'],
      },
      {
        code: '63684119',
        text:
          'В какой период осуществляется рассмотрение планов развития горных работ?',
        answers: [
          'В период с 20 августа по 25 ноября года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 октября по 20 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 10 сентября по 10 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
        correctAnswers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
      },
      {
        code: '63684120',
        text:
          'Какое превышение должен иметь внешний рельс разгрузочного пути по отношению к внутреннему?',
        answers: [
          'На 40 - 60 мм',
          'На 100 - 150 мм',
          'На 60 - 80 мм',
          'На 80 - 100 мм',
        ],
        correctAnswers: ['На 100 - 150 мм'],
      },
      {
        code: '63684121',
        text:
          'Какой минимальной ширины должен быть проход для людей в галереях и эстакадах вновь проектируемых угольных разрезов у конвейера при ширине ленты до 1400 мм?',
        answers: ['700 мм', '1000 мм', '1400 мм', '800 мм'],
        correctAnswers: ['800 мм'],
      },
      {
        code: '63684122',
        text:
          'Решение каких задач не преследуется при проведении работ по осушению территории производства работ?',
        answers: [
          'Создание благоприятных условий для последующей рекультивации земель',
          'Создание безопасных условий работы горнотранспортного оборудования',
          'Снижение влажности полезных ископаемых и вскрышных пород',
          'Обеспечение устойчивости откосов горных выработок и отвалов',
        ],
        correctAnswers: [
          'Создание благоприятных условий для последующей рекультивации земель',
        ],
      },
      {
        code: '63684123',
        text:
          'В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За 25 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За 45 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
      },
      {
        code: '63684124',
        text:
          'Каким должно быть расстояние между концом консольного ленточного отвалообразователя с периодическим перемещением и гребнем отвала?',
        answers: [
          'Не менее 1,0 м',
          'Не менее 0,7 м',
          'Не менее 0,5 м',
          'Не менее 1,5 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63684125',
        text:
          'Что не учитывается проектом при определении высоты уступа разреза?',
        answers: [
          'Квалификация персонала угольного разреза',
          'Горно-геологические условия залегания горных пород и угля',
          'Параметры оборудования',
          'Результаты исследований физико-механических свойств вмещающих горных пород и угля',
        ],
        correctAnswers: ['Квалификация персонала угольного разреза'],
      },
      {
        code: '63684126',
        text:
          'Что должно входить в состав планов и схем развития горных работ?',
        answers: [
          'Графическая часть и пояснительная записка с табличными материалами',
          'Геодезическая съемка объекта',
          'План-график производства работ',
          'Перечень используемого оборудования',
        ],
        correctAnswers: [
          'Графическая часть и пояснительная записка с табличными материалами',
        ],
      },
      {
        code: '63684127',
        text:
          'При каком расстоянии от металлических конструкций, (мостов, путепроводов, светофоров и т.п.), от частей контактной сети, находящихся под напряжением эти конструкции должны быть заземлены?',
        answers: [
          'На расстоянии более 5 м',
          'На расстоянии 5 м',
          'На расстоянии менее 5 м',
          'На расстоянии 5 м и более',
        ],
        correctAnswers: ['На расстоянии менее 5 м'],
      },
      {
        code: '63684128',
        text:
          'На каком расстоянии от конца рельсов устанавливаются предохранительные упоры на забойных и отвальных железнодорожных путях?',
        answers: [
          'На расстоянии не менее 8 м от конца рельсов',
          'На расстоянии не менее 2 м от конца рельсов',
          'На расстоянии не менее 5 м от конца рельсов',
          'На расстоянии не менее 10 м от конца рельсов',
        ],
        correctAnswers: ['На расстоянии не менее 10 м от конца рельсов'],
      },
      {
        code: '63684129',
        text:
          'Что устанавливается в лицензиях на пользование недрами, технических проектах и иной проектной документации на выполнение работ, связанных с пользованием участками недр?',
        answers: [
          'Дополнительные мероприятия, обеспечивающие, соблюдение требований по технологии ведения работ',
          'Дополнительные мероприятия, обеспечивающие безопасное пользование недрами',
          'Дополнительные мероприятия по прогнозированию и предупреждению опасных ситуаций с соответствующей корректировкой технических и технологических решений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684130',
        text:
          'Куда должен быть направлен план реализации мероприятий, обосновывающих и обеспечивающих безопасную эксплуатацию угольного разреза в соответствии с требованиями Правил безопасности при разработке угольных месторождений открытым способом, до их реализации?',
        answers: [
          'В территориальный орган Ростехнадзора',
          'Руководителю угольного разреза',
          'В профессиональные аварийно-спасательные службы и (или) профессиональные аварийно-спасательные формирования',
          'В органы местного самоуправления, на территории которых располагается разрез',
        ],
        correctAnswers: ['В территориальный орган Ростехнадзора'],
      },
      {
        code: '63684131',
        text:
          'Каким должно быть расстояние от нижней бровки уступа (развала горной массы) и от верхней бровки уступа до оси ближайшего железнодорожного пути?',
        answers: [
          'Не менее 1,8 м',
          'Не менее 2,2 м',
          'Не менее 1,5 м',
          'Не менее 2,5 м',
        ],
        correctAnswers: ['Не менее 2,5 м'],
      },
      {
        code: '63684132',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации скреперов, бульдозеров и погрузчиков?',
        answers: [
          'Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос',
          'Во всех случаях при движении транспортного средства и самоходной техники задним ходом должен подаваться звуковой сигнал',
          'Вся самоходная техника (грейдеры, скреперы, бульдозеры, погрузчики) должна иметь руководства по эксплуатации, техническому обслуживанию и ремонту, выданные заводом-изготовителем, содержащие их основные технические и эксплуатационные характеристики',
          'На линию транспортные средства могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения, находятся в технически исправном состоянии',
        ],
        correctAnswers: [
          'Запрещается движение самоходной техники (скреперов, бульдозеров, погрузчиков) по призме возможного обрушения уступа. При разгрузке скрепер должен передвигаться назад под откос',
        ],
      },
      {
        code: '63684133',
        text:
          'Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для I типа кровли?',
        answers: ['2,4 м', '1,8 м', '1,4 м', '2,2 м'],
        correctAnswers: ['2,4 м'],
      },
      {
        code: '63684134',
        text:
          'К какой категории интенсивности проявления горного давления относится горная выработка при расчетном смещении пород кровли свыше 50 до 200 мм?',
        answers: [
          'К II категории (горное давление средней интенсивности)',
          'К I категории (небольшое горное давление)',
          'К III категории (интенсивное горное давление)',
        ],
        correctAnswers: [
          'К II категории (горное давление средней интенсивности)',
        ],
      },
      {
        code: '63684135',
        text:
          'Что из перечисленного соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к прокладке трубопроводов?',
        answers: [
          'Трубопроводы укладываются на выровненном основании на подкладках',
          'При прокладке по откосу уступа или борту разреза трубопровод должен быть закреплен анкерами не реже чем через каждые 35 - 40 м по высоте',
          'На каждом прямолинейном участке трубопровода необходимо не реже чем через 750 м устанавливать компенсатор',
          'Все перечисленное',
        ],
        correctAnswers: [
          'Трубопроводы укладываются на выровненном основании на подкладках',
        ],
      },
      {
        code: '63684136',
        text:
          'С какой периодичностью должны проверяться устройства контроля за изменением ширины рельсовых путей и их уклонов?',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в тридцать дней',
          'Не реже одного раза в 3 месяца',
        ],
        correctAnswers: ['Не реже одного раза в тридцать дней'],
      },
      {
        code: '63684137',
        text:
          'Какого формата должен быть план границ горного отвода, на котором должны отображаться: контуры предварительных и уточненных границ горного отвода, угловые точки границ горного отвода?',
        answers: ['А1', 'А5', 'А4', 'А3'],
        correctAnswers: ['А4'],
      },
      {
        code: '63684138',
        text:
          'Как должны быть устроены мостки для обслуживания трубопровода и водопроводных лотков, уложенных на эстакадах?',
        answers: [
          'Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м',
          'Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,1 м и обшитые снизу на высоту не менее 0,1 м',
          'Мостки должны быть шириной 0,6 м, огражденные перилами высотой 1,0 м и обшитые снизу на высоту не менее 0,1 м',
          'Мостки должны быть шириной 0,4 м, огражденные перилами высотой 1,3 м и обшитые снизу на высоту не менее 0,25 м',
        ],
        correctAnswers: [
          'Мостки должны быть шириной 0,5 м, огражденные перилами высотой 1,2 м и обшитые снизу на высоту не менее 0,2 м',
        ],
      },
      {
        code: '63684139',
        text:
          'Какова минимальная периодичность измерения сопротивления общего заземляющего устройства передвижных электроустановок?',
        answers: [
          '1 раз в 30 дней',
          '1 раз в 6 месяцев',
          '1 раз в квартал',
          '1 раз в год',
        ],
        correctAnswers: ['1 раз в 30 дней'],
      },
      {
        code: '63684140',
        text:
          'В течение какого срока управление железной дороги со дня получения от горного предприятия проекта мер охраны должно направить последнему справку о согласии с выбранными мерами или мотивированное возражение?',
        answers: [
          'В течение 45 дней',
          'В течение 30 дней',
          'В течение 20 дней',
          'В течение 15 дней',
        ],
        correctAnswers: ['В течение 20 дней'],
      },
      {
        code: '63684141',
        text:
          'Что из перечисленного влияет на результаты расчетов, устанавливающих проектные высоту отвала и отвальных ярусов, углы откоса и призмы возможного обрушения, скорость продвижения фронта отвальных работ?',
        answers: [
          'Рельеф местности и несущая способность нагруженных отвалов',
          'Способы отвалообразования',
          'Все перечисленное',
          'Физико-механические свойства пород отвала и его основания',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684142',
        text:
          'Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?',
        answers: [
          'Органом исполнительной власти любого из причастных к горному отводу субъекта Российской Федерации',
          'Органом государственного горного надзора',
          'Федеральным агентством по недропользованию',
        ],
        correctAnswers: ['Органом государственного горного надзора'],
      },
      {
        code: '63684143',
        text:
          'До какой нагрузки нагружают анкерную крепь при испытании ее несущей способности?',
        answers: [
          'До 0,8 Nа, где Nа - несущая способность анкера, кН',
          'До 1,2 Nа, где Nа - несущая способность анкера, кН',
          'До 0,6 Nа, где Nа - несущая способность анкера, кН',
          'До 1,1 Nа, где Nа - несущая способность анкера, кН',
        ],
        correctAnswers: ['До 0,6 Nа, где Nа - несущая способность анкера, кН'],
      },
      {
        code: '63684144',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации экскаваторов?',
        answers: [
          'В случае выявления угрозы подтопления, обрушения или оползания горных выработок во время работы экскаватора машинист экскаватора обязан прекратить работу, отвести экскаватор в безопасное место и поставить в известность руководителя смены',
          'При работе экскаватора на грунтах, не выдерживающих давления гусениц, должны осуществляться меры, отражаемые в документации производства работ, обеспечивающие устойчивое положение экскаватора',
          'Погрузка горной массы экскаватором в забоях с контактными сетями электрифицированного транспорта в думпкары или другие емкости допускается при условии осуществления мероприятий по безопасным приемам работы, включая защиту от прикосновения ковша с контактным проводом',
          'Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в два-три слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке',
        ],
        correctAnswers: [
          'Для вывода экскаватора из забоя необходимо всегда иметь свободный проход. Негабаритные куски горной массы должны укладываться устойчиво не более чем в два-три слоя, не создавая препятствий для перемещения горнотранспортного оборудования на площадке',
        ],
      },
      {
        code: '63684145',
        text:
          'На какой максимальный срок составляется схема развития горных работ?',
        answers: ['На 1 год', 'На 2 года', 'На 5 лет', 'На 8 лет'],
        correctAnswers: ['На 5 лет'],
      },
      {
        code: '63684146',
        text:
          'Каким должно быть расстояние от нижнего фазного провода воздушной линии электропередачи напряжением 35 кВ до верхней точки машин или груза при пересечении (сближении) воздушной линии электропередачи с автомобильными дорогами?',
        answers: [
          'Не менее 1,0 м',
          'Не менее 2,0 м',
          'Не менее 1,5 м',
          'Не менее 2,5 м',
        ],
        correctAnswers: ['Не менее 2,5 м'],
      },
      {
        code: '63684147',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
          'На основе технического регламента',
          'На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России',
          'На основе локального плана проведения работ',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63684148',
        text:
          'Каким должен быть максимальный угол откосов рабочих уступов при работе экскаваторов типа механической лопаты, драглайна и роторных экскаваторов?',
        answers: ['70°', '80°', '75°', '85°'],
        correctAnswers: ['80°'],
      },
      {
        code: '63684149',
        text:
          'Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к перегону экскаваторов указано неверно?',
        answers: [
          'Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады',
          'Перегон должен осуществляться по трассе, расположенной вне призм обрушения',
          'Перегон экскаватора должен производиться по сигналам помощника машиниста или назначенного лица, при этом должна быть обеспечена постоянная видимость между ним и машинистом экскаватора',
          'Уклон трассы не должен превышать величины, допустимые по техническим характеристикам экскаватора, и трасса должна иметь ширину, достаточную для маневров',
        ],
        correctAnswers: [
          'Для шагающих экскаваторов не допускается передача сигналов от помощника машиниста к машинисту через третьего члена бригады',
        ],
      },
      {
        code: '63684150',
        text:
          'Каким должно быть максимальное сопротивление общего заземляющего устройства разреза?',
        answers: [
          'Не более 4 Ом',
          'Не более 6 Ом',
          'Не более 8 Ом',
          'Не более 10 Ом',
        ],
        correctAnswers: ['Не более 4 Ом'],
      },
      {
        code: '63684151',
        text:
          'Какое определение характеризует смещение кровли более 200 мм, выделяемое при определении и выборе документации крепления в зависимости от расчетных смещений и от интенсивности проявлений горного давления?',
        answers: [
          'Горное давление средней интенсивности',
          'Горное давление повышенной интенсивности',
          'Интенсивное горное давление',
          'Незначительное горное давление',
        ],
        correctAnswers: ['Интенсивное горное давление'],
      },
      {
        code: '63684152',
        text:
          'В течение какого срока решение об утверждении мер охраны предприятием по добыче угля (сланца) сообщается Управлению железной дороги?',
        answers: [
          'В течение 10 дней',
          'В течение 15 дней',
          'В течение 20 дней',
          'В течение 25 дней',
        ],
        correctAnswers: ['В течение 10 дней'],
      },
      {
        code: '63684153',
        text:
          'Какие визуальные и инструментальные наблюдения должны проводиться на объектах ведения открытых горных работ?',
        answers: [
          'За поверхностной эрозией песчано-глинистых рыхлых пород, слагающих борта карьеров',
          'За состоянием бортов, уступов и откосов',
          'За закарстованностью месторождения',
          'За проявлениями удароопасности горных пород, вмещающих полезное ископаемое',
        ],
        correctAnswers: ['За состоянием бортов, уступов и откосов'],
      },
      {
        code: '63684154',
        text: 'Что должны отображать графические материалы?',
        answers: [
          'Границы участка недр, установленные при предоставлении лицензии на пользование недрами (предварительные границы горных отводов)',
          'Все перечисленное',
          'Уточненные границы горного отвода',
          'Контуры горных выработок (для твердых полезных ископаемых) и объектов поверхности, включая устья скважин (кусты скважин) различного назначения',
          'Границы опасных зон и зон с особыми условиями использования территорий (при разработке месторождений твердых полезных ископаемых при наличии)',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684155',
        text:
          'С какой периодичностью и при выполнении каких условий должна проводиться оценка несущей способности применяемых анкеров при проведении (восстановлении) выработок?',
        answers: [
          'Оценка проводится при помощи штанговыдергивателя не реже одного раза в 3 месяца через каждые 100 м длины горной выработки',
          'Оценка проводится при помощи штанговыдергивателя не реже двух раз в 3 месяца через каждые 250 м длины горной выработки',
          'Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки',
          'Оценка проводится при помощи штанговыдергивателя один раз в 2 месяца через каждые 150 м длины горной выработки',
        ],
        correctAnswers: [
          'Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки',
        ],
      },
      {
        code: '63684156',
        text:
          'К какому типу кровли по обрушаемости относится кровля при сопротивлении пород одноосному сжатию Rc ≤ 30 МПа?',
        answers: [
          'К I типу кровли',
          'К II типу кровли',
          'К III типу кровли',
          'К IV типу кровли',
        ],
        correctAnswers: ['К III типу кровли'],
      },
      {
        code: '63684157',
        text:
          'С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций в зоне опорного давления?',
        answers: [
          '1 раз в неделю',
          '1 раз в квартал',
          '1 раз в сутки',
          '1 раз в месяц',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63684158',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для колеи 900 мм?',
        answers: ['1700 мм', '1400 мм', '1300 мм', '1500 мм', '1100 мм'],
        correctAnswers: ['1300 мм'],
      },
      {
        code: '63684159',
        text:
          'Какой минимальной длины должны быть площадки при уклонах дорог длиной более 600 м и более 60 промилле (‰)?',
        answers: ['50 м', '10 м', '25 м', '75 м'],
        correctAnswers: ['50 м'],
      },
      {
        code: '63684160',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Опрокидывание кузовов думпкаров и возвращение их в транспортное положение после разгрузки должны производиться без помощи подставок, шпал, рельсов',
          'Для безопасной разгрузки думпкаров, груженных смерзающимися, налипающими породами и крупногабаритными кусками, должны быть разработаны мероприятия, утвержденные техническим руководителем (главным инженером) угольного разреза',
          'Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза',
          'При разгрузке думпкаров люди должны находиться вне зоны развала горной массы. Вдоль железнодорожного пути в месте разгрузки состава с противоположной от приямка стороны должна быть спланирована площадка для обслуживающего состав персонала',
        ],
        correctAnswers: [
          'Очистка думпкаров вручную на приямках допускается в случаях неисправности механизмов по очистке при соблюдении специально разработанных мер безопасности, утвержденных техническим руководителем разреза',
        ],
      },
      {
        code: '63684161',
        text:
          'К какой основной схеме подготовки выемочных участков относится проведение спаренных горных выработок в зоне и впереди зоны влияния очистных работ при подготовке выемочных столбов спаренными горными выработками?',
        answers: ['К первой', 'Ко второй', 'К третьей', 'К четвертой'],
        correctAnswers: ['Ко второй'],
      },
      {
        code: '63684162',
        text:
          'Какой должна быть максимальная высота уступа при разработке вручную рыхлых неустойчивых пород?',
        answers: ['3 м', '4 м', '5 м', '7 м'],
        correctAnswers: ['3 м'],
      },
      {
        code: '63684163',
        text: 'Каким образом целесообразно располагать анкеры в кровле?',
        answers: [
          'С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м',
          'С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,5 м',
          'С отклонением крайних анкеров от нормали к напластованию на угол 20 - 25° и на расстоянии их от боков не более 0,4 м',
        ],
        correctAnswers: [
          'С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м',
        ],
      },
      {
        code: '63684164',
        text:
          'Какой должна быть высота подвески контактного провода на постоянных путях над головкой рельса на станциях и на перегонах соответственно?',
        answers: [
          'Соответственно не менее 5750 мм и не менее 5250 мм',
          'Соответственно не менее 5250 мм и не менее 4750 мм',
          'Соответственно не менее 6250 мм и не менее 5750 мм',
          'Соответственно не менее 4750 мм и не менее 4250 мм',
        ],
        correctAnswers: ['Соответственно не менее 6250 мм и не менее 5750 мм'],
      },
      {
        code: '63684165',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Техническим руководителем пользователя недр (подрядной организации)',
          'Комиссией пользователя недр (подрядной организации)',
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
          'Уполномоченными работниками на объекте',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63684166',
        text:
          'Что из перечисленного должно быть обозначено предупредительными знаками, ограждениями или предохранительными валами?',
        answers: [
          'Только участки высокого борта и горные выработки с признаками деформации',
          'Только водоемы и затопленные выработки',
          'Только очаги самонагревания породных отвалов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684167',
        text:
          'Что из перечисленного не соответствует условиям погрузки горной массы в автомобили экскаваторами?',
        answers: [
          'Ожидающий погрузки автомобиль должен находиться за пределами радиуса действия экскаватора (погрузчика) и становиться под погрузку только после звукового и (или) светового сигналов машиниста экскаватора (погрузчика) или оператора погрузочного устройства',
          'Запрещается односторонняя или сверхгабаритная, а также превышающая установленную грузоподъемность автомобиля загрузка',
          'Погрузка в кузов автомобиля производится только сзади или, осуществляя перенос экскаваторного ковша, над кабиной',
          'Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м',
        ],
        correctAnswers: [
          'Погрузка в кузов автомобиля производится только сзади или, осуществляя перенос экскаваторного ковша, над кабиной',
        ],
      },
      {
        code: '63684168',
        text:
          'В какой системе координат должны быть определены уточненные границы горного отвода?',
        answers: [
          'В прямоугольной системе координат',
          'В полярной системе координат (азимут, горизонтальное положение)',
          'В географической системе координат (ширина, долгота)',
        ],
        correctAnswers: ['В прямоугольной системе координат'],
      },
      {
        code: '63684169',
        text: 'Какие требования предъявляются к воздуху рабочей зоны разреза?',
        answers: [
          'Воздух рабочей зоны должен содержать по объему около 18% кислорода и не более 0,8% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
          'Воздух рабочей зоны должен содержать по объему около 17% кислорода и не более 0,7% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
          'Воздух рабочей зоны должен содержать по объему около 19% кислорода и не более 0,6% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
          'Воздух рабочей зоны должен содержать по объему около 20% кислорода и не более 0,5% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
        ],
        correctAnswers: [
          'Воздух рабочей зоны должен содержать по объему около 20% кислорода и не более 0,5% углекислого газа; содержание других вредных газов и пыли не должно превышать предельно допустимых концентраций (ПДК)',
        ],
      },
      {
        code: '63684170',
        text:
          'Какое из перечисленных требований безопасности предъявляется к выполнению работ по ручной оборке уступов от нависей и козырьков?',
        answers: [
          'Ручная оборка допускается при наличии распоряжения под непосредственным наблюдением руководителя смены или бригадира',
          'Ручная оборка уступов не допускается',
          'Ручная оборка допускается по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира',
          'Ручная оборка уступов проводится в дневное время с применением соответствующих мер безопасности под непосредственным наблюдением руководителя смены или бригадира',
        ],
        correctAnswers: [
          'Ручная оборка допускается по наряду-допуску под непосредственным наблюдением руководителя смены или бригадира',
        ],
      },
      {
        code: '63684171',
        text:
          'Какой должна быть высота подвески контактного провода над уровнем головки рельса на передвижных железнодорожных путях при боковой подвеске?',
        answers: [
          'Не менее 4200 мм',
          'Не менее 4000 мм',
          'Не менее 3800 мм',
          'Не менее 4400 мм',
        ],
        correctAnswers: ['Не менее 4400 мм'],
      },
      {
        code: '63684172',
        text:
          'Кто разрабатывает мероприятия, обосновывающие и обеспечивающие безопасную эксплуатацию угольного разреза до приведения действующего угольного разреза в соответствие с требованиями Правил безопасности при разработке угольных месторождений открытым способом?',
        answers: [
          'Руководитель угольного разреза',
          'Технический руководитель (главный инженер) угольного разреза',
          'Главный механик угольного разреза',
          'Главный энергетик угольного разреза',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63684173',
        text:
          'В каких горных выработках применяются анкеры с закреплением стержней минеральной композицией?',
        answers: [
          'Только в горных выработках, пройденных по пластам, весьма склонным к самовозгоранию',
          'Во всех перечисленных',
          'Только в горных выработках со сроком службы более 10 лет',
          'Только в горных выработках с обводненными породами',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63684174',
        text:
          'Какое из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом к эксплуатации технологического автомобильного транспорта указано неверно?',
        answers: [
          'Допускается использование автомобилей, не имеющих техническую документацию, выданную заводом-изготовителем и содержащую его основные технические и эксплуатационные характеристики, при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии',
          'Автомобили должны иметь необходимый запас горючего и комплект инструмента, предусмотренный заводом-изготовителем',
          'На линию автомобили могут выпускаться только при условии, если все их агрегаты и узлы, обеспечивающие безопасность движения, а также безопасность других работ, предусмотренных технологией применения транспортных средств, находятся в технически исправном состоянии',
        ],
        correctAnswers: [
          'Допускается использование автомобилей, не имеющих техническую документацию, выданную заводом-изготовителем и содержащую его основные технические и эксплуатационные характеристики, при условии, что все их агрегаты и узлы, обеспечивающие безопасность движения, находятся в технически исправном состоянии',
        ],
      },
      {
        code: '63684175',
        text:
          'В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?',
        answers: [
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
          'Не позднее чем за 10 дней до установленной даты их рассмотрения',
          'Не позднее чем за 15 дней до установленной даты их рассмотрения',
          'Не позднее чем за 7 дней до установленной даты их рассмотрения',
        ],
        correctAnswers: [
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
        ],
      },
      {
        code: '63684176',
        text:
          'Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?',
        answers: [
          '2 экземпляра',
          '3 экземпляра',
          '4 экземпляра',
          '5 экземпляров',
        ],
        correctAnswers: ['3 экземпляра'],
      },
      {
        code: '63684177',
        text:
          'Что из перечисленного разрешается при работе автомобиля на линии?',
        answers: [
          'Перевозка посторонних людей в кабине без разрешения руководителя (главного инженера) организации или главного инженера угольного разреза',
          'Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса',
          'Переезд кабелей, уложенных по почве и не огражденных специальными предохранительными устройствами',
          'Нахождение персонала под поднятым, незастопоренным кузовом',
          'Выход из кабины автомобиля до полного подъема или опускания кузова',
        ],
        correctAnswers: [
          'Движение вдоль железнодорожных путей на расстоянии более 5 м от ближайшего рельса',
        ],
      },
      {
        code: '63684178',
        text:
          'С каким максимальным уклоном должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 промилле (‰)?',
        answers: ['20‰', '15‰', '25‰', '10‰'],
        correctAnswers: ['20‰'],
      },
      {
        code: '63684179',
        text:
          'Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?',
        answers: [
          'Все перечисленные',
          'Консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при наличии)',
          'Организация наблюдений за состоянием горных отводов',
          'Достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63684180',
        text:
          'При каком минимальном расстоянии по воздуху от подъемной или выдвижной части в любом ее положении, в том числе и при наибольшем допускаемом конструкцией подъеме или боковом вылете, до ближайшего провода воздушной линии электропередачи, находящейся под напряжением 35 кВ, допускается работа экскаваторов, погрузчиков, буровых станков?',
        answers: ['1 м', '2 м', '3 м', '4 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63684181',
        text:
          'К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли свыше 50 до 200 мм?',
        answers: [
          'К I категории (небольшое горное давление)',
          'К II категории (горное давление средней интенсивности)',
          'К III категории (интенсивное горное давление)',
        ],
        correctAnswers: [
          'К II категории (горное давление средней интенсивности)',
        ],
      },
      {
        code: '63684182',
        text:
          'Каким должно быть расстояние между передвижными опорами временных технологических воздушных линий электропередачи напряжением до 35 кВ?',
        answers: [
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 90 м',
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 75 м',
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м',
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 60 м',
        ],
        correctAnswers: [
          'Определяется по расчету с учетом климатических условий и обеспечения устойчивости опор и, как правило, не должно превышать 50 м',
        ],
      },
      {
        code: '63684183',
        text:
          'В зависимости от каких характеристик проводится расчет параметров анкерной крепи и дополнительных средств ее усиления?',
        answers: [
          'Способа и параметров охраны горных выработок от влияния очистных работ',
          'Прочности, трещиноватости и устойчивости пород в кровле',
          'Интенсивности горного давления',
          'Всех перечисленных характеристик',
        ],
        correctAnswers: ['Всех перечисленных характеристик'],
      },
      {
        code: '63684184',
        text:
          'Кто утверждает мероприятия по своевременному обнаружению очагов самонагревания и самовозгорания угля, по профилактике и тушению пожаров на угольных разрезах, разрабатывающих пласты угля, склонные к самовозгоранию?',
        answers: [
          'Представитель органов исполнительной власти муниципального образования, на территории которого находится разрез',
          'Руководитель территориального органа Ростехнадзора',
          'Технический руководитель (главный инженер) угольного разреза',
          'Командование военизированной горноспасательной части',
          'Командир пожарной части, на территории обслуживания которой находится разрез',
        ],
        correctAnswers: [
          'Технический руководитель (главный инженер) угольного разреза',
        ],
      },
      {
        code: '63684185',
        text:
          'Что из перечисленного является обязательными условиями принятия решения о консервации и ликвидации разреза? Выберите два правильных варианта ответа.',
        answers: [
          'Наличие проектной документации на консервацию и ликвидацию разреза',
          'Наличие положительного заключения экспертизы промышленной безопасности',
          'Наличие договоров с подрядными организациями',
          'Наличие согласованного и утвержденного плана работ',
        ],
        correctAnswers: [
          'Наличие проектной документации на консервацию и ликвидацию разреза',
          'Наличие положительного заключения экспертизы промышленной безопасности',
        ],
      },
      {
        code: '63684186',
        text:
          'Электроприводы каких установок не оборудуются электрической блокировкой, исключающей самозапуск механизмов после подачи напряжения питания?',
        answers: [
          'Электроприводы экскаваторов, буровых станков',
          'Электроприводы отвалообразователей, конвейеров',
          'Электроприводы автоматизированных компрессорных установок, водоотливов',
          'Электроприводы насосов',
        ],
        correctAnswers: [
          'Электроприводы автоматизированных компрессорных установок, водоотливов',
        ],
      },
      {
        code: '63684187',
        text:
          'К какому типу кровли (по обрушаемости) будет относиться однородная кровля из слоистых преимущественно глинистых, песчано-глинистых и песчаных сланцев?',
        answers: [
          'К I типу кровли',
          'К II типу кровли',
          'К III типу кровли',
          'К IV типу кровли',
        ],
        correctAnswers: ['К I типу кровли'],
      },
      {
        code: '63684188',
        text:
          'Какие из перечисленных участков ведения открытых горных работ должны относиться к зонам, опасным по геомеханическим условиям?',
        answers: [
          'Только участок повышенной водообильности бортов и отвалов, сложенных мягкими связными и твердыми глинистыми, а также рыхлыми несвязными или слабосцементированными породами',
          'Только участок борта, нагруженный отвалами, размещенными в пределах призмы возможного обрушения',
          'Только участок бортов разреза и откосов отвалов, на которых обнаружены признаки деформаций (трещины, заколы или просадки)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63684189',
        text:
          'С какой периодичностью должна проверяться исправность и комплектность машин машинистом (оператором, водителем)?',
        answers: [
          'Ежесменно',
          'Один раз в полугодие',
          'Ежемесячно',
          'Один раз в квартал',
          'Один раз в 2 месяца',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63684190',
        text:
          'Чему равно значение коэффициента снижения сопротивления пород сжатию за счет воздействия влаги при сопротивлении пород одноосному сжатию в образце 50 МПа?',
        answers: ['0,5', '0,6', '0,7', '0,8'],
        correctAnswers: ['0,5'],
      },
      {
        code: '63684191',
        text:
          'В каком случае углы откосов рабочих уступов не должны превышать угла естественного откоса разрабатываемых пород? Выберите два правильных варианта ответа.',
        answers: [
          'При работе экскаваторов типа механической лопаты и гидравлических экскаваторов',
          'При работе многоковшовых цепных экскаваторов нижним черпанием',
          'При работе погрузчиков, драглайнов и роторных экскаваторов',
          'При разработке вручную рыхлых и сыпучих пород',
        ],
        correctAnswers: [
          'При работе многоковшовых цепных экскаваторов нижним черпанием',
          'При разработке вручную рыхлых и сыпучих пород',
        ],
      },
      {
        code: '63684192',
        text:
          'С какой периодичностью должна проверяться исправность и комплектность машин главным механиком, главным энергетиком разреза или другим назначаемым лицом?',
        answers: [
          'Один раз в полугодие',
          'Один раз в квартал',
          'Ежемесячно',
          'Один раз в 2 месяца',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '63684193',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по созданию безопасных условий труда при эксплуатации водосбросных и водосборных сооружений?',
        answers: [
          'Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м',
          'На землесосных установках пульповоды и водоводы должны быть оборудованы обратными клапанами',
          'Все водосбросные и водосборные сооружения гидроотвалов должны быть рассчитаны на максимально возможный приток',
          'Помещения насосных и землесосных установок должны иметь средства связи с местом установки гидромониторов и быть оборудованы аварийной сигнализацией',
        ],
        correctAnswers: [
          'Во вновь строящихся помещениях между насосами и землесосными агрегатами, а также между ними и стенками помещения следует предусматривать проходы шириной не менее 0,75 м',
        ],
      },
      {
        code: '63684194',
        text:
          'Как должен располагаться привод ходовой тележки при передвижении гусеничного экскаватора по горизонтальному участку или на подъем и при спуске?',
        answers: [
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться впереди, а при спусках с уклона - сзади',
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться сзади',
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди',
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем, а также при спуске привод ходовой тележки должен находиться впереди',
        ],
        correctAnswers: [
          'При передвижении гусеничного экскаватора по горизонтальному участку или на подъем привод ходовой тележки должен находиться сзади, а при спусках с уклона - впереди',
        ],
      },
      {
        code: '63684195',
        text:
          'Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для II типа кровли?',
        answers: ['2,4 м', '2,0 м', '1,6 м', '1,2 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63684196',
        text:
          'Какой должна быть минимальная ширина рабочей бермы при бурении шпуров перфораторами и электросверлами?',
        answers: ['4,5 м', '4,0 м', '3,0 м', '3,5 м'],
        correctAnswers: ['4,0 м'],
      },
      {
        code: '63684197',
        text:
          'Каким должно быть минимальное расстояние по горизонтали между экскаваторами и драглайнами (с учетом величины заброса ковша) или драглайнами с учетом величины заброса ковшов, расположенными на одном горизонте?',
        answers: [
          'Равным сумме их наибольших радиусов действия',
          'Равным удвоенной сумме их наибольших радиусов действия',
          'Равным утроенной сумме их наибольших радиусов действия',
          'Равным полуторной сумме их наибольших радиусов действия',
        ],
        correctAnswers: ['Равным сумме их наибольших радиусов действия'],
      },
      {
        code: '63684198',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к следованию поездов вагонами вперед?',
        answers: [
          'Следование поездов вагонами вперед разрешается при наличии вагонов с тормозной площадкой',
          'Следование поездов вагонами вперед разрешается при наличии на переднем вагоне исправной подножки, которая должна быть обращена в сторону движения поезда, на которой должен находиться составитель (кондуктор)',
          'Допускается следование специализированных технологических поездов вагонами вперед без составителя (кондуктора) при обязательном наличии на переднем вагоне (думпкаре) соответствующих звуковых, а в темное время суток и световых сигналов',
          'Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24',
        ],
        correctAnswers: [
          'Хозяйственный поезд, отправляемый на перегон в тупик погрузки и выгрузки вагонами вперед без составителя (кондуктора) и звуковых и световых сигналов, может состоять из вагонов (думпкаров) с числом осей не более 24',
        ],
      },
      {
        code: '63684199',
        text:
          'Как часто должна обновляться схема транспортных коммуникаций на угольных разрезах, нанесенная на план горных работ?',
        answers: [
          'Один раз в 2 месяца',
          'Один раз в месяц',
          'Один раз в полугодие',
          'Один раз в квартал',
          'Должна обновляться при изменении схемы',
        ],
        correctAnswers: ['Должна обновляться при изменении схемы'],
      },
      {
        code: '63684200',
        text:
          'Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?',
        answers: [
          'Штатная численность сотрудников объекта ведения горных работ',
          'Условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ',
          'Направления развития горных работ',
          'Объемы добычи и первичной переработки полезных ископаемых',
        ],
        correctAnswers: [
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
      },
      {
        code: '63684201',
        text:
          'На каком минимальном расстоянии от воздушных линий электропередачи (ЛЭП) и линий связи разрешается укладывать пульпопроводы и водоводы без согласования с владельцами коммуникаций и организациями, осуществляющими контроль за их безопасной эксплуатацией?',
        answers: [
          'На расстоянии 10 м',
          'На расстоянии 15 м',
          'На расстоянии 20 м',
          'На расстоянии 25 м',
        ],
        correctAnswers: ['На расстоянии 25 м'],
      },
      {
        code: '63684202',
        text: 'Кем подписывается горноотводная документация?',
        answers: [
          'Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора',
          'Руководителем эксплуатирующей организации',
          'Главным геологом',
          'Главным маркшейдером',
        ],
        correctAnswers: [
          'Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора',
        ],
      },
      {
        code: '63684203',
        text:
          'Какое из требований безопасности к определению мест и периодичности отбора проб воздуха рабочей зоны угольного разреза указано неверно?',
        answers: [
          'Отбор проб пыли и вредных газов производится не реже одного раза в пять месяцев для угольных разрезов глубиной отработки более 150 м',
          'Отбор проб пыли и вредных газов производится после каждого изменения технологии работ',
          'Места отбора проб пыли и вредных газов и их периодичность устанавливаются графиком, утвержденным техническим руководителем (главным инженером) угольного разреза',
          'Отбор проб пыли и вредных газов производится не реже одного раза в шесть месяцев для угольных разрезов глубиной отработки менее 150 м',
        ],
        correctAnswers: [
          'Отбор проб пыли и вредных газов производится не реже одного раза в пять месяцев для угольных разрезов глубиной отработки более 150 м',
        ],
      },
      {
        code: '63684204',
        text: 'К какому классу относится "устойчивая кровля"?',
        answers: ['К I классу', 'К II классу', 'К III классу'],
        correctAnswers: ['К III классу'],
      },
      {
        code: '63684205',
        text:
          'В какой момент допускается проводить затяжку гаек анкера при возведении анкерной крепи?',
        answers: [
          'Через час после окончания твердения состава ампул',
          'После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем',
          'После первичного отвердения состава ампул, определяемого визуально',
          'Сразу после установки анкера',
        ],
        correctAnswers: [
          'После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем',
        ],
      },
      {
        code: '63684206',
        text:
          'На каком расстоянии от забоя должна быть установлена анкерная крепь при условии наличия в кровле горной выработки неустойчивых пород, обрушающихся в проходческом забое?',
        answers: [
          'Не менее 2 м',
          'Не менее 1,5 м',
          'Не более 1,3 м',
          'Не более 1 м',
        ],
        correctAnswers: ['Не более 1 м'],
      },
      {
        code: '63684207',
        text:
          'С какой периодичностью следует проверять тормоза приводов конвейеров?',
        answers: [
          'Не реже одного раза в полугодие',
          'Не реже одного раза в 2 месяца',
          'Не реже одного раза в квартал',
          'Не реже одного раза в тридцать дней',
        ],
        correctAnswers: ['Не реже одного раза в тридцать дней'],
      },
      {
        code: '63684208',
        text:
          'В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?',
        answers: [
          'В планы развития горных работ на предстоящий календарный период',
          'В журнал осмотра',
          'В пояснительную записку с фотографией деформации',
          'В отчет о проведенных работах',
        ],
        correctAnswers: [
          'В планы развития горных работ на предстоящий календарный период',
        ],
      },
      {
        code: '63684209',
        text:
          'В соответствии с каким документом должны осуществляться горные работы по проведению траншей, разработке уступов, отсыпке отвалов?',
        answers: [
          'В соответствии с локальным проектом производства работ (паспортом), утвержденным техническим руководителем разреза',
          'В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ',
          'В соответствии с планом работ по разработке разрезов, согласованным с аварийно-спасательными формированиями',
          'В соответствии с утвержденным планом производства работ, согласованным с органом исполнительной власти муниципального образования, на территории которого эксплуатируется разрез',
          'В соответствии с планом по разработке разрезов, согласованным с Ростехнадзором',
        ],
        correctAnswers: [
          'В соответствии с утвержденной техническим руководителем (главным инженером) угольного разреза документацией на производство работ',
        ],
      },
      {
        code: '63684210',
        text:
          'Что не соответствует требованиям по обеспечению разрезов связью?',
        answers: [
          'Питание устройств связи и сигнализации разреза для исключения помех от осветительной сети должно производиться только от специальных аккумуляторных батарей',
          'Угольный разрез должен быть оборудован комплексом технических средств связи и сигнализации, обеспечивающих эффективное управление технологическими процессами и безопасностью работ, включающих два канала связи с подразделениями профессиональной аварийно-спасательной службы и (или) профессионального аварийно-спасательного формирования ПАСС(Ф), обслуживающими угольный разрез',
          'Для сигнальных устройств, кроме систем централизованных блокировок (СЦБ), питаемых напряжением не выше 24 В, допускается выполнение линий голыми проводами',
          'Установки связи должны обеспечиваться защитой от мешающего и опасного влияния линий высокого напряжения контактной сети, грозовых разрядов и блуждающих токов',
        ],
        correctAnswers: [
          'Питание устройств связи и сигнализации разреза для исключения помех от осветительной сети должно производиться только от специальных аккумуляторных батарей',
        ],
      },
      {
        code: '63684211',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?',
        answers: [
          'Выявление недостоверных сведений в представленных документах',
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
          'Отсутствие обоснования соблюдения условий безопасного недропользования',
          'Отсутствие геологического и маркшейдерского обеспечения горных работ',
        ],
        correctAnswers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63684212',
        text:
          'Оценка каких параметров проводится при бурении скважин (шпуров)? Укажите все правильные ответы.',
        answers: [
          'Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи',
          'Несущей способности используемых при креплении горной выработки анкеров',
          'Прочностных свойств пород',
          'Температуры нагрева буровой коронки',
        ],
        correctAnswers: [
          'Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи',
          'Прочностных свойств пород',
        ],
      },
      {
        code: '63684213',
        text:
          'Какой должна быть длина надводного пляжа в течение всего срока эксплуатации намывных гидроотвалов?',
        answers: [
          'Не менее 30 м',
          'Должна соответствовать проекту',
          'Не менее 50 м',
          'Должна определяться опытным путем',
          'Не менее 40 м',
        ],
        correctAnswers: ['Должна соответствовать проекту'],
      },
      {
        code: '63684214',
        text:
          'Влияние каких профилактических мероприятий на геомеханическое состояние вмещающих пород не требуется учитывать при расчете параметров анкерной крепи горных выработок, проводимых по пластам, склонным к горным ударам и внезапным выбросам угля (породы) и газа?',
        answers: [
          'Региональная предварительная подработка и (или) надработка массива с последующим проведением в нем подготовительных горных выработок',
          'Бурение разгрузочных скважин в окружающем угольном массиве в период проведения горной выработки',
          'Выполнение гидрообработки угольного массива',
          'Контроль за скоростью вращения инструмента при бурении, давлением на манометре насоса, выносом из скважины крупных обломков породы',
        ],
        correctAnswers: [
          'Контроль за скоростью вращения инструмента при бурении, давлением на манометре насоса, выносом из скважины крупных обломков породы',
        ],
      },
      {
        code: '63684215',
        text:
          'Чьи подписи ставятся на титульном листе пояснительной записки планов (схем) развития горных работ?',
        answers: [
          'Только лица пользователя недр, ответственного за руководство геологическими работами',
          'Только лица пользователя недр и (или) подрядной организации, ответственного за руководство горными работами',
          'Только лица пользователя недр и (или) подрядной организации, ответственного за руководство маркшейдерскими работами',
          'Только лица подрядной организации, ответственного за руководство геологическими работами',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63684216',
        text:
          'При каком различии отметок участков протяженных горизонтальных и наклонных горных выработок, расчетная глубина для них принимается равной максимальной глубине?',
        answers: ['До 15%', 'Свыше 25%', 'До 20%', 'Свыше 35%'],
        correctAnswers: ['До 15%'],
      },
      {
        code: '63684217',
        text:
          'С каким максимальным интервалом должны устраиваться площадки при уклонах дорог длиной более 600 м и более 60 промилле (‰)?',
        answers: [
          'Через каждые 700 м длины затяжного уклона',
          'Через каждые 400 м длины затяжного уклона',
          'Через каждые 500 м длины затяжного уклона',
          'Через каждые 600 м длины затяжного уклона',
          'Через каждые 300 м длины затяжного уклона',
        ],
        correctAnswers: ['Через каждые 600 м длины затяжного уклона'],
      },
      {
        code: '63684218',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Техническим руководителем организации - пользователя недр',
          'Руководителем организации - пользователя недр',
          'Органом государственного горного надзора',
          'Органом исполнительной власти субъекта Российской Федерации',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63684219',
        text:
          'Какие из перечисленных требований Правил безопасности при разработке угольных месторождений открытым способом указаны верно?',
        answers: [
          'К управлению горным и транспортным оборудованием с электрическим приводом, связанному с обслуживанием, оперативным включением и отключением электроустановок, допускаются только машинисты и помощники машинистов после присвоения им соответствующих квалификационных групп по электробезопасности',
          'При временном переводе машинистов и помощников машинистов на другое горное оборудование обслуживание данного оборудования допускается после ознакомления со схемой электроснабжения эксплуатируемого оборудования под подпись',
          'Оперативные переключения кабельных линий в пределах закрепленной за машинистом и помощником машиниста горнотранспортной машины и ее переключательного пункта должны проводиться по наряду-допуску',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63684220',
        text:
          'В какие сроки должна проверяться исправность действия (срабатывания) реле утечки тока?',
        answers: [
          'Один раз в 10 дней',
          'Один раз в 3 дня',
          'Один раз в сутки',
          'В каждой смене перед началом работы',
        ],
        correctAnswers: ['В каждой смене перед началом работы'],
      },
      {
        code: '63684221',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до бровки плужного отвала после каждой передвижки путей при грузоподъемности думпкара более 60 тонн?',
        answers: ['1700 мм', '1900 мм', '1600 мм', '1800 мм', '1500 мм'],
        correctAnswers: ['1800 мм'],
      },
      {
        code: '63684222',
        text:
          'Каким должно быть расстояние от оси крайнего железнодорожного пути до внутреннего края опор контактной сети на перегонах и железнодорожных станциях?',
        answers: [
          'Не менее 3000 мм',
          'Не менее 3100 мм',
          'Не менее 2800 мм',
          'Не менее 2900 мм',
        ],
        correctAnswers: ['Не менее 3100 мм'],
      },
      {
        code: '63684223',
        text:
          'Что из перечисленного учитывается инструкцией по эксплуатации железнодорожного транспорта угольного разреза при установлении скорости движения поездов на железнодорожных путях угольного разреза?',
        answers: [
          'Только применяемый подвижной состав',
          'Только верхнее строение и профиль пути',
          'Только местные условия',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684224',
        text:
          'На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте до 1200 мм?',
        answers: [
          'На расстоянии не менее 1,5 м',
          'На расстоянии не менее 1,25 м',
          'На расстоянии не менее 2,0 м',
          'На расстоянии не менее 1,75 м',
        ],
        correctAnswers: ['На расстоянии не менее 2,0 м'],
      },
      {
        code: '63684225',
        text:
          'Какие технические решения должны предусматриваться в проекте для реализации мер по осушению территории производства работ?',
        answers: [
          'Только по строительству сооружений для отвода воды за пределы зоны влияния дренажной системы',
          'Все приведенные решения',
          'Только по понижению уровня подземных вод (при необходимости)',
          'Только по ограждению сооружений, горных выработок и отвалов от поверхностных вод и атмосферных осадков',
        ],
        correctAnswers: ['Все приведенные решения'],
      },
      {
        code: '63684226',
        text:
          'Что не соответствует требованиям безопасности при эксплуатации горнотранспортных и строительно-дорожных машин?',
        answers: [
          'Копия проекта (документации) производства работ должна находиться только у горного мастера смены',
          'В случае внезапного прекращения подачи электроэнергии персонал, обслуживающий механизмы, обязан немедленно перевести пусковые устройства электродвигателей и рычаги управления в нейтральное положение "Стоп" (нулевое)',
          'Работы с использованием горнотранспортных и строительно-дорожных машин должны вестись в соответствии с документацией на производство работ, копии документов должны находиться в кабинах этих машин',
          'Запрещается присутствие посторонних лиц в кабине и на наружных площадках экскаватора и бурового станка при их работе, кроме руководителя смены и лиц, имеющих разрешение технического руководителя (главного инженера) угольного разреза',
        ],
        correctAnswers: [
          'Копия проекта (документации) производства работ должна находиться только у горного мастера смены',
        ],
      },
      {
        code: '63684227',
        text:
          'Каким федеральным органом исполнительной власти оформляется горноотводная документация?',
        answers: [
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
          'Федеральным агентством по недропользованию - Роснедрами',
          'Министерством природных ресурсов и экологии Российской Федерации',
          'Федеральной службой по надзору в сфере защиты прав потребителей и благополучия человека',
        ],
        correctAnswers: [
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '63684228',
        text:
          'В каких случаях допускается применение сетей напряжением до 1 кВ с глухозаземленной нейтралью?',
        answers: [
          'Для питания стационарных потребителей, расположенных за пределами открытых горных работ',
          'Для питающихся от отдельных трансформаторов установок освещения стационарных перегрузочных пунктов и отвалов',
          'Для въездных (выездных) траншей',
          'Для осветительных установок и сетей СЦБ',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63684229',
        text:
          'Что должен содержать проект мер охраны участков железных дорог общего пользования, намечаемых к подработке?',
        answers: [
          'Акт обследования состояния объектов комиссией',
          'Выкопировки с планов горных работ с нанесением проекта развития горных работ в зоне влияния на железную дорогу',
          'Пояснительную записку с обоснованием выбранных мер охраны',
          'План наблюдательной станции',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684230',
        text:
          'Какой должна быть минимальная высота перил переходных мостиков через ленточные конвейеры?',
        answers: ['0,7 м', '0,8 м', '1,2 м', '1,1 м'],
        correctAnswers: ['1,1 м'],
      },
      {
        code: '63684231',
        text:
          'Какие меры принимаются для снижения пылеобразования при положительной температуре воздуха? Укажите все правильные ответы.',
        answers: [
          'Выполнение работ в СИЗОД изолирующего типа',
          'На автомобильных дорогах проводится полив дорог водой с применением связующих добавок',
          'При сдувании пыли с обнаженных поверхностей на угольном разрезе и на отвале необходимо осуществлять меры по предотвращению пылеобразования, включающие обработку обнаженных поверхностей связующими растворами и их озеленение',
          'Все перечисленные',
        ],
        correctAnswers: [
          'На автомобильных дорогах проводится полив дорог водой с применением связующих добавок',
          'При сдувании пыли с обнаженных поверхностей на угольном разрезе и на отвале необходимо осуществлять меры по предотвращению пылеобразования, включающие обработку обнаженных поверхностей связующими растворами и их озеленение',
        ],
      },
      {
        code: '63684232',
        text:
          'Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней винтового профиля из стали А400С, А500С N 25?',
        answers: ['240 кН', '160 кН', '120 кН', '140 кН'],
        correctAnswers: ['240 кН'],
      },
      {
        code: '63684233',
        text:
          'Каким должно быть нормальное положение шлагбаумов автоматизированного и неавтоматизированного переездов?',
        answers: [
          'Закрытое у автоматизированного и неавтоматизированного переезда',
          'Открытое у автоматизированного и закрытое у неавтоматизированного переездов',
          'Открытое у автоматизированного и неавтоматизированного переездов',
          'Закрытое у автоматизированного и открытое у неавтоматизированного переездов',
        ],
        correctAnswers: [
          'Открытое у автоматизированного и закрытое у неавтоматизированного переездов',
        ],
      },
      {
        code: '63684234',
        text:
          'Какая электрическая система должна применяться для осветительных сетей на разрезе, а также для стационарных световых точек на передвижных машинах, механизмах и агрегатах?',
        answers: [
          'Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В',
          'Электрическая система с заземленной нейтралью напряжением 127 В',
          'Электрическая система с заземленной нейтралью напряжением 380 В',
          'Электрическая система с изолированной нейтралью при линейном напряжении не выше 127 В',
        ],
        correctAnswers: [
          'Электрическая система с изолированной нейтралью при линейном напряжении не выше 220 В',
        ],
      },
      {
        code: '63684235',
        text:
          'Какое минимальное расстояние должно быть от оси железнодорожного пути до верхней бровки на отвалах, оборудованных одноковшовыми экскаваторами, в месте разгрузки думпкаров для нормальной колеи 1520 мм?',
        answers: ['1400 мм', '1300 мм', '1700 мм', '1600 мм', '1500 мм'],
        correctAnswers: ['1600 мм'],
      },
      {
        code: '63684236',
        text:
          'На каком расстоянии от наружной грани головки крайнего рельса должны находиться грузы при высоте выше 1200 мм?',
        answers: [
          'На расстоянии не менее 2,5 м',
          'На расстоянии не менее 1,75 м',
          'На расстоянии не менее 2,25 м',
          'На расстоянии не менее 2,0 м',
        ],
        correctAnswers: ['На расстоянии не менее 2,5 м'],
      },
      {
        code: '63684237',
        text:
          'На сколько классов устойчивости следует разделять непосредственную кровлю над горными выработками и сопряжениями при определении плотности установки анкеров, конструкции опор, затяжки, технологии крепления кровли при проходке, для всех типов кровли по обрушаемости?',
        answers: ['На три', 'На шесть', 'На четыре', 'На пять'],
        correctAnswers: ['На три'],
      },
      {
        code: '63684238',
        text:
          'В состав какой документации должен входить горноотводный акт и графические приложения к нему?',
        answers: [
          'Горноотводной документации',
          'Документации на техническое перевооружение, консервацию и ликвидацию',
          'Проектной документации',
          'Документации к обоснованию безопасности',
        ],
        correctAnswers: ['Горноотводной документации'],
      },
      {
        code: '63684239',
        text:
          'С каким максимальным углом наклона должны устраиваться съезды, предназначенные для сообщения между уступами угольного разреза?',
        answers: ['15°', '20°', '10°', '25°', '30°'],
        correctAnswers: ['20°'],
      },
      {
        code: '63684240',
        text:
          'Под каким углом к бровке уступа должна быть продольная ось бурового станка при бурении первого ряда скважин?',
        answers: ['60º', '90º', '45º', '35º'],
        correctAnswers: ['90º'],
      },
      {
        code: '63684241',
        text:
          'Что из перечисленного допускается при разработке пород с применением буровзрывных работ при условии, что высота забоя по развалу не превышает максимальную высоту черпания экскаватора?',
        answers: [
          'Только разработка мероприятий по безопасному обрушению козырьков и нависей',
          'Только увеличение высоты уступа до полуторной высоты черпания экскаватора',
          'Только разделение развала по высоте на слои (подуступы)',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63684242',
        text:
          'С какой периодичностью должны обновляться (пополняться) сводные планы горных работ, планы поверхности в случаях, если ситуация местности (объектовый состав) в границах горного отвода и (или) система наблюдений за состоянием горного отвода и расположенных в его границах горных выработок (скважин), зданий, сооружений и иных объектов не претерпели изменений?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в 5 лет'],
      },
      {
        code: '63684243',
        text:
          'Какое максимальное количество соединений допускается в каждом пролете на один провод или трос?',
        answers: [
          '6 соединений',
          '4 соединения',
          '3 соединения',
          '5 соединений',
        ],
        correctAnswers: ['3 соединения'],
      },
      {
        code: '63684244',
        text:
          'Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?',
        answers: [
          'Главным инженером карьера ежеквартально',
          'Комиссией в составе главного инженера карьера и главного маркшейдера карьера раз в полугодие',
          'Главным маркшейдером или главным геологом не реже одного раза в полугодие',
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
        ],
        correctAnswers: [
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
        ],
      },
      {
        code: '63684245',
        text:
          'Что из перечисленного не должна превышать высота уступа при применении канатных экскаваторов? Выберите два правильных варианта ответа.',
        answers: [
          'Полуторную высоту черпания экскаватора',
          'Высоту траектории движения рабочего органа (ковша) экскаватора (погрузчика)',
          'Максимальную высоту черпания экскаватора',
          'Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов',
        ],
        correctAnswers: [
          'Максимальную высоту черпания экскаватора',
          'Высоту или глубину черпания драглайна, многоковшовых цепных и роторных экскаваторов',
        ],
      },
      {
        code: '63684246',
        text:
          'Для каких горно-геологических и горнотехнических условий в Инструкции по расчету и применению анкерной крепи на угольных шахтах не содержится порядок расчета анкерной крепи для горизонтальных и наклонных (до 35 градусов) подземных горных выработок и их сопряжений?',
        answers: [
          'При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород',
          'При прочности на одноосное сжатие оставляемой угольной пачки в кровле горной выработки или сопряжения не менее 6 МПа',
          'При максимальной расчетной ширине выработки или сопряжения не более 12 м',
          'При отношении глубины проведения горной выработки или сопряжения от дневной поверхности Н, м к расчетному сопротивлению пород и угля в кровле на одноосное сжатие Rc, МПа не более 25',
        ],
        correctAnswers: [
          'При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород',
        ],
      },
      {
        code: '63684247',
        text:
          'Что из перечисленного не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом к устройству переездов на временных железнодорожных путях? Выберите все варианты ответа.',
        answers: [
          'Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°',
          'Продольные уклоны дорог на подходах к переезду должны быть не менее 60‰',
          'На расстоянии не менее длины тормозного пути в обе стороны от переезда должны быть установлены предупредительные знаки о подаче свистка машинистом локомотива',
          'Перелом профиля устраивается на расстоянии 5 м от крайнего рельса',
        ],
        correctAnswers: [
          'Переезды должны иметь сплошной настил и угол пересечения с железной дорогой не менее 20°',
          'Продольные уклоны дорог на подходах к переезду должны быть не менее 60‰',
        ],
      },
      {
        code: '63684248',
        text:
          'Какое расстояние должно быть предусмотрено от кровли выработки до верхнего ряда анкеров?',
        answers: [
          'Не более 0,5 м',
          'Не менее 1,3 м',
          'Не менее 1,0 м',
          'Не более 0,8 м',
        ],
        correctAnswers: ['Не более 0,5 м'],
      },
      {
        code: '63684249',
        text:
          'Что не соответствует требованиям Правил безопасности при разработке угольных месторождений открытым способом по организации обслуживания намыва гидроотвала?',
        answers: [
          'Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности',
          'Для обеспечения безаварийной работы должны проводиться натурные наблюдения и инструментальный контроль с использованием контрольно-измерительной аппаратуры',
          'Участки намытого гидроотвала должны быть ограждены, и на них установлены предупредительные плакаты и знаки',
          'Для обслуживания намыва гидроотвала устраивают мостики с перилами',
        ],
        correctAnswers: [
          'Подход к вымоинам, провалам или воронкам, образовавшимся на гидроотвале, а также хождение по льду пруда-отстойника должны осуществляться с особыми мерами предосторожности',
        ],
      },
      {
        code: '63684250',
        text:
          'В какие сроки проводится измерение сопротивления стационарных электроустановок?',
        answers: [
          'В период наибольшего промерзания грунта зимой',
          'В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой',
          'В грозовые периоды весной и летом',
          'В период наибольшего высыхания грунта летом',
        ],
        correctAnswers: [
          'В периоды наибольшего высыхания грунта летом и наибольшего промерзания грунта зимой',
        ],
      },
    ],
    63683: [
      {
        code: '63683000',
        text:
          'В какой системе координат должны быть определены уточненные границы горного отвода?',
        answers: [
          'В прямоугольной системе координат',
          'В географической системе координат',
          'В референцной системе координат',
        ],
        correctAnswers: ['В прямоугольной системе координат'],
      },
      {
        code: '63683001',
        text: 'Что определяется планами и схемами развития горных работ?',
        answers: [
          'Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи и первичной переработки полезных ископаемых',
          'Только направления развития горных работ, технические и технологические решения при эксплуатации объектов ведения горных работ',
          'Только условия безопасного недропользования, объемы добычи и первичной переработки полезных ископаемых',
        ],
        correctAnswers: [
          'Направления развития горных работ, условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ, объемы добычи и первичной переработки полезных ископаемых',
        ],
      },
      {
        code: '63683002',
        text:
          'На какие виды работ составляются планы и схемы развития горных работ?',
        answers: [
          'Только на подготовительные работы',
          'Только на геологические работы',
          'Только на маркшейдерские работы',
          'Только на работы по добыче полезных ископаемых и первичной переработке минерального сырья',
          'Только на рекультивационные работы',
          'На все перечисленные виды работ',
        ],
        correctAnswers: ['На все перечисленные виды работ'],
      },
      {
        code: '63683003',
        text:
          'При какой глубине вертикальных стволов при наличии лестниц в обоих стволах механический подъем в одной из них может отсутствовать?',
        answers: [
          'При глубине до 70 м',
          'При глубине до 75 м',
          'При глубине до 80 м',
          'При глубине до 85 м',
        ],
        correctAnswers: ['При глубине до 70 м'],
      },
      {
        code: '63683004',
        text:
          'Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия',
          'Только осуществлять разделение месторождения на шахтные поля и порядок их отработки, чтобы обеспечивалось планомерное извлечение запасов без образования целиков, острых углов и выступов фронта очистных работ',
          'Только снижать количество горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления',
          'Только сокращать применение систем разработки с открытым очистным пространством',
        ],
        correctAnswers: [
          'Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия',
        ],
      },
      {
        code: '63683005',
        text:
          'На какой срок составляется план развития горных работ по всем планируемым видам горных работ?',
        answers: ['На 1 год', 'На 2 года', 'На 3 года', 'На 5 лет'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63683006',
        text:
          'Какие мероприятия по газовому режиму должны выполняться при бурении скважин на шахтах, опасных по газу?',
        answers: [
          'Посещение камер для бурения горным мастером службы вентиляции и техники безопасности не реже одного раза в смену',
          'Наличие у всех людей, работающих в камере для бурения, изолирующих самоспасателей',
          'Обеспечение буровой установки прибором непрерывного контроля содержания метана в атмосфере, прибором периодического замера углекислого газа и наличие двусторонней телефонной связи с горным диспетчером шахты',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63683007',
        text:
          'С какой периодичностью осуществляются наблюдения на типовых станциях при скорости оседания земной поверхности до 15 мм/год?',
        answers: [
          'Одно наблюдение в три года',
          'Одно наблюдение в два года',
          'Ежегодные наблюдения',
          'Два наблюдения в год',
        ],
        correctAnswers: ['Ежегодные наблюдения'],
      },
      {
        code: '63683008',
        text:
          'Каким образом должно осуществляться передвижение людей по территории объектов ведения горных работ и переработки полезных ископаемых?',
        answers: [
          'Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта',
          'Пути передвижения по территории объекта ведения открытых горных работ выбирают самостоятельно работающие на объекте, убеждаясь в отсутствии движущегося автотранспорта',
          'Передвижение людей по территории объекта ведения открытых горных работ допускается по маршрутам, обозначенным специальными указательными знаками',
          'Передвижение людей по территории объекта ведения открытых горных работ допускается в соответствии с указаниями ответственного за передвижение по территории объекта',
        ],
        correctAnswers: [
          'Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта',
        ],
      },
      {
        code: '63683009',
        text:
          'Укажите требования, соответствующие Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых" к организации надзора и проектному оформлению при посадке кровли в процессе очистной выемки.',
        answers: [
          'Посадка кровли должна производиться под непосредственным руководством технического руководителя шахты в соответствии со специальным проектом',
          'Посадка кровли должна производиться под руководством лица технического надзора в соответствии с техническим регламентом',
          'Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ',
          'Посадка кровли должна производиться под непосредственным руководством лица технического сменного надзора в соответствии со специальным проектом',
        ],
        correctAnswers: [
          'Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ',
        ],
      },
      {
        code: '63683010',
        text:
          'В каких случаях должны быть определены и нанесены на маркшейдерскую документацию границы опасных зон по прорыву воды и газов?',
        answers: [
          'Необходимость определения и нанесения на маркшейдерскую документацию границы опасных зон по прорывам воды и газа устанавливается территориальным органом Ростехнадзора',
          'Только на шахтах, где имеются затопленные выработки или другие водные (обводненные) объекты',
          'На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов',
        ],
        correctAnswers: [
          'На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов',
        ],
      },
      {
        code: '63683011',
        text:
          'На содержание каких веществ должны анализироваться пробы воздуха, набираемые в непроветриваемой части затопленных выработок (при откачке)?',
        answers: [
          'Только CH4, H2S',
          'CO, CO2, CH4, H2S, O2 и H2',
          'Только CO, O2',
          'Только СO2, H2',
        ],
        correctAnswers: ['CO, CO2, CH4, H2S, O2 и H2'],
      },
      {
        code: '63683012',
        text:
          'Согласно требованиям Инструкции допустимое отклонение ствола скважины подземного растворения не должно превышать 5° в пределах конуса средних отклонений. Что собой представляет конус средних отклонений?',
        answers: [
          'Конус, образующая которого составляет 5° с вертикалью, проходящей через устье скважины',
          'Конус, образующая которого составляет 3° с вертикалью, проходящей через устье скважины',
          'Конус, образующая которого составляет 1° с вертикалью, проходящей через устье скважины',
          'Конус, образующая которого составляет 2° с вертикалью, проходящей через устье скважины',
        ],
        correctAnswers: [
          'Конус, образующая которого составляет 1° с вертикалью, проходящей через устье скважины',
        ],
      },
      {
        code: '63683013',
        text: 'Какие скважины после окончания бурения должны быть перекрыты?',
        answers: [
          'Скважины, диаметр устья которых более 250 мм',
          'Скважины, диаметр устья которых более 200 мм',
          'Скважины, диаметр устья которых более 220 мм',
          'Скважины, диаметр устья которых более 180 мм',
        ],
        correctAnswers: ['Скважины, диаметр устья которых более 250 мм'],
      },
      {
        code: '63683014',
        text:
          'Что должно входить в состав планов и схем развития горных работ?',
        answers: [
          'Геодезическая съемка мест производства горных работ',
          'Морфометрический анализ земной поверхности',
          'Список работников с указанием паспортных данных и медицинского заключения',
          'Графическая часть и пояснительная записка с табличными материалами',
        ],
        correctAnswers: [
          'Графическая часть и пояснительная записка с табличными материалами',
        ],
      },
      {
        code: '63683015',
        text:
          'В каком случае допускается работа горно-транспортного оборудования в зоне обрушения?',
        answers: [
          'Запрещается',
          'Допускается только после положительного заключения геолого-маркшейдерской службы предприятия',
          'Допускается только после усадки породы, опробования подошвы уступа путем контрольного бурения',
          'Допускается после усадки породы, опробования подошвы уступа путем контрольного бурения и положительного заключения геолого-маркшейдерской службы предприятия',
        ],
        correctAnswers: [
          'Допускается после усадки породы, опробования подошвы уступа путем контрольного бурения и положительного заключения геолого-маркшейдерской службы предприятия',
        ],
      },
      {
        code: '63683016',
        text:
          'На какой максимальный срок составляется схема развития горных работ?',
        answers: ['На 6 лет', 'На 2 года', 'На 1 год', 'На 5 лет'],
        correctAnswers: ['На 5 лет'],
      },
      {
        code: '63683017',
        text:
          'По каким видам полезных ископаемых подготавливаются планы и схемы развития горных работ?',
        answers: [
          'Только по твердым полезным ископаемым',
          'Только по углеводородному сырью',
          'Только по общераспространенным полезным ископаемым',
          'Только по промышленным подземным водам',
          'По всем перечисленным видам полезных ископаемых',
        ],
        correctAnswers: ['По всем перечисленным видам полезных ископаемых'],
      },
      {
        code: '63683018',
        text: 'Какие виды происшествий относятся к категории инцидентов?',
        answers: [
          'Разрушение узлов и деталей, приведшее к остановке работы грузовых, грузолюдских подъемных установок на срок более суток либо к случаям травмирования',
          'Разрушение узлов и деталей участковых вентиляторных установок, центральных водоотливов, компрессорных установок, приведшее к их остановке',
          'Затопление заглубленных пульпонасосных станций и насосных отделений обогатительных фабрик',
          'Пожары в надшахтных зданиях и производственных сооружениях в пределах горного (земельного) отвода',
          'Падение в стволы и вертикальные выработки технологического оборудования, механизмов, материалов',
        ],
        correctAnswers: [
          'Разрушение узлов и деталей участковых вентиляторных установок, центральных водоотливов, компрессорных установок, приведшее к их остановке',
        ],
      },
      {
        code: '63683019',
        text:
          'Может ли "газовый режим" распространяться на отдельные участки, блоки, пласты или на шахту в целом?',
        answers: [
          'Может распространяться только на шахту в целом',
          'Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)',
          'Может распространяться только на горные выработки всего месторождения',
        ],
        correctAnswers: [
          'Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)',
        ],
      },
      {
        code: '63683020',
        text:
          'Обязательны ли наблюдения за сдвижением земной поверхности на всех рассолопромыслах?',
        answers: [
          'Наблюдения за сдвижением земной поверхности на рассолопромыслах проводятся только по предписанию территориального органа Ростехнадзора',
          'Наблюдения за сдвижением земной поверхности на рассолопромыслах необязательны',
          'Наблюдения за сдвижением земной поверхности на всех рассолопромыслах обязательны',
          'Наблюдения за сдвижением земной поверхности на рассолопромыслах проводятся на усмотрение технического руководителя организации',
        ],
        correctAnswers: [
          'Наблюдения за сдвижением земной поверхности на всех рассолопромыслах обязательны',
        ],
      },
      {
        code: '63683021',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Наименования организации, объекта недропользования (участка недр)',
          'Дата составления, масштаб',
          'Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами',
          'Все ответы неверны',
          'Название графического документа',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63683022',
        text:
          'В каких случаях могут применяться системы разработки сплошным растворением на месторождениях солей?',
        answers: [
          'В случаях, когда месторождения солей залегают на глубинах 100 м',
          'В случаях, когда месторождения солей залегают на глубинах 150 м и более',
          'Лишь в случаях, когда на месторождениях, где могут быть допущены деформации земной поверхности и связь искусственного рассольного горизонта с пресными водами',
          'Ни в каких случаях',
        ],
        correctAnswers: [
          'Лишь в случаях, когда на месторождениях, где могут быть допущены деформации земной поверхности и связь искусственного рассольного горизонта с пресными водами',
        ],
      },
      {
        code: '63683023',
        text: 'Чем определяется готовность к ведению работ в опасной зоне?',
        answers: [
          'Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного маркшейдера, главного геолога и начальника участка',
          'Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного маркшейдера, главного геолога и начальника участка',
          'Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного геолога и начальника участка',
          'Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного маркшейдера и главного геолога',
        ],
        correctAnswers: [
          'Актом комиссии в составе заместителей технического руководителя по ТБ и по производству горных работ, главного механика, главного маркшейдера, главного геолога и начальника участка',
        ],
      },
      {
        code: '63683024',
        text:
          'Из каких документов должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Только из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)',
          'Только из геологических и структурных карт, характерных разрезов, проекций, схем',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
          'Только из погоризонтных маркшейдерских планов горных выработок (горных работ)',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63683025',
        text:
          'В соответствии с какой документацией проводится оценка геодинамического состояния массива горных пород?',
        answers: [
          'В соответствии с документацией по расчету пороговых значений прогностических параметров',
          'В соответствии с документацией по прогнозу по параметрам аэрогазового контроля',
          'В соответствии с документацией по проведению геофизического мониторинга',
          'В соответствии с документацией по проведению разведочных и (или) контрольных наблюдений',
          'В соответствии с документацией по определению выбросоопасности по региональному прогнозу',
        ],
        correctAnswers: [
          'В соответствии с документацией по проведению геофизического мониторинга',
        ],
      },
      {
        code: '63683026',
        text:
          'С какой периодичностью должны обновляться (пополняться) сводные планы горных работ, планы поверхности, в случаях если ситуация местности (объектовый состав) в границах горного отвода и (или) система наблюдений за состоянием горного отвода и расположенных в его границах горных выработок (скважин), зданий, сооружений и иных объектов не претерпели изменений?',
        answers: [
          'Не реже одного раза в 7 лет',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 10 лет',
          'Не реже одного раза в 6 лет',
        ],
        correctAnswers: ['Не реже одного раза в 5 лет'],
      },
      {
        code: '63683027',
        text:
          'Какое из перечисленных требований к рудоспускам указано неверно?',
        answers: [
          'Каждый рудоспуск необходимо обустроить бетонными предохранительными барьерами высотой не менее 0,33 и шириной не менее 0,5 диаметра колеса автосамосвала',
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
          'При выполнении работ по переносу устья рудоспуска из карьера и ведении ремонтных работ число капитальных действующих рудоспусков с карьера в подземные выработки должно быть не менее 2',
          'При отработке верхней части рудоспусков они должны быть заполнены до подошвы уступа',
        ],
        correctAnswers: [
          'При перепуске руд глинистых, обводненных, склонных к слеживанию и самовозгоранию допускается аккумулирование руды в рудоспуске',
        ],
      },
      {
        code: '63683028',
        text:
          'Когда производится гидролокационная съемка формы подземной камеры рассолопромысла?',
        answers: [
          'После полной отработки каждой ступени, но не реже одного раза в пять лет',
          'После полной отработки каждой ступени, но не реже одного раза в год',
          'После полной отработки каждой ступени, но не реже одного раза в два года',
          'После полной отработки каждой ступени, но не реже одного раза в три года',
        ],
        correctAnswers: [
          'После полной отработки каждой ступени, но не реже одного раза в год',
        ],
      },
      {
        code: '63683029',
        text:
          'С какой периодичностью распорядительным документом руководителя угледобывающей организации угольные пласты и горные породы относятся к категориям по динамическим явлениям, утверждаются опасные зоны и определяется комплекс мер по прогнозу и предотвращению динамических явлений?',
        answers: [
          'Ежегодно',
          'Ежемесячно',
          'Еженедельно',
          'Ежеквартально',
          'Ежедекадно',
        ],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '63683030',
        text:
          'На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ, предусмотренным планами и схемами развития горных работ?',
        answers: [
          'Не более 8 лет',
          'Не более 7 лет',
          'Не более 6 лет',
          'Не более 5 лет',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63683031',
        text:
          'Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы и переработка полезных ископаемых, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?',
        answers: [
          'Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников',
          'Не обязаны',
          'По усмотрению руководителя организации, эксплуатирующей опасный производственный объект, на котором ведутся горные работы и переработка полезных ископаемых',
          'Не обязаны, если на то нет предписания территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников',
        ],
      },
      {
        code: '63683032',
        text:
          'В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За 7 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В течение 5 дней с назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В назначенный день рассмотрения планов и (или) схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
      },
      {
        code: '63683033',
        text:
          'Каким способом выполняют наблюдения за давлением воды в затопленных, затопляемых и осушаемых выработках?',
        answers: [
          'Только измерением уровня воды в скважинах, пробуренных на затопленные выработки с поверхности или из выше расположенных горных выработок',
          'Только измерением давления воды манометрами, установленными на устьях скважин, пробуренных на затопленные выработки из действующих выработок, расположенных ниже уровня воды в затопленных',
          'Только измерением давления воды манометрами, установленными на трубах, проложенных в изоляционных перемычках',
          'Только измерением уровней воды в горных выработках, имеющих гидравлическую связь с затопленными',
          'Любым из перечисленных способом',
        ],
        correctAnswers: ['Любым из перечисленных способом'],
      },
      {
        code: '63683034',
        text:
          'Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?',
        answers: [
          '2 экземпляра',
          '3 экземпляра',
          '4 экземпляра',
          '5 экземпляров',
        ],
        correctAnswers: ['3 экземпляра'],
      },
      {
        code: '63683035',
        text:
          'Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?',
        answers: [
          'Условия безопасного недропользования',
          'Технические и технологические решения при эксплуатации объектов ведения горных работ',
          'Объемы добычи и первичной переработки полезных ископаемых',
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
        correctAnswers: [
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
      },
      {
        code: '63683036',
        text:
          'Как выполняют замеры уровней воды в затопленных выработках и сопутствующие наблюдения маркшейдерская и геологическая службы шахты?',
        answers: [
          'Еженедельно, по специальной инструкции, утвержденной техническим руководителем шахты',
          'Ежесуточно, по графику, утвержденному техническим руководителем шахты',
          'По регламенту, установленному техническим руководителем шахты и увязанному с графиком работ по затоплению выработок или по спуску воды из них',
        ],
        correctAnswers: [
          'По регламенту, установленному техническим руководителем шахты и увязанному с графиком работ по затоплению выработок или по спуску воды из них',
        ],
      },
      {
        code: '63683037',
        text:
          'Какие требования установлены "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" для безопасного выполнения работ по ручной оборке откосов уступов?',
        answers: [
          'Ручная оборка откосов запрещается',
          'Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора',
          'Ручная оборка допускается при наличии распоряжения под непосредственным наблюдением специалистов маркшейдерской службы',
        ],
        correctAnswers: [
          'Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора',
        ],
      },
      {
        code: '63683038',
        text:
          'На какую максимальную глубину могут быть пробурены в опасной зоне водоспускные и опережающие скважины без герметизации их устьев?',
        answers: ['2 м', '5 м', '10 м', '12 м'],
        correctAnswers: ['5 м'],
      },
      {
        code: '63683039',
        text:
          'Что должна содержать пояснительная записка проекта горного отвода в зависимости от видов полезных ископаемых и целей пользования недрами?',
        answers: [
          'Только сведения, характеризующие географическое и административное местоположение участка недр, предоставленного в пользование',
          'Только сведения о пользовании недрами (согласно проектной документации)',
          'Только сведения о смежных участках недр, предоставленных в пользование, зонах с особыми условиями использования территорий в границах предоставленного в пользование участка недр (при наличии)',
          'Только сведения об условиях в области безопасного пользования недрами, рационального использования и охраны недр, содержащихся в неотъемлемых составных частях лицензии на пользование недрами',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683040',
        text:
          'Каким образом на каждой шахте должно обеспечиваться проветривание двух отдельных выходов, обеспечивающих выход людей на поверхность?',
        answers: [
          'За счет естественной тяги',
          'За счет общешахтной депрессии',
          'Разным направлением вентиляционных струй',
        ],
        correctAnswers: ['Разным направлением вентиляционных струй'],
      },
      {
        code: '63683041',
        text:
          'Где устанавливается периодичность осмотров и инструментальных наблюдений за деформациями бортов, откосов, уступов и отвалов, а также объектов, попадающих в зоны влияния горных работ и расположенных на земной поверхности?',
        answers: [
          'В проекте производства маркшейдерских работ',
          'В журнале маркшейдерских указаний',
          'В плане развития горных работ',
          'В паспорте ведения горных работ',
        ],
        correctAnswers: ['В проекте производства маркшейдерских работ'],
      },
      {
        code: '63683042',
        text:
          'Через какое максимальное расстояние подвигания забоя подготовительной или очистной выработки пороговые значения прогностических параметров переутверждаются?',
        answers: ['100 м', '200 м', '300 м', '400 м', '500 м'],
        correctAnswers: ['300 м'],
      },
      {
        code: '63683043',
        text:
          'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Укажите все правильные ответы.',
        answers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'Мероприятия по предотвращению горных ударов должны проводиться после возведения в стволе постоянной крепи',
          'В особо сложных условиях мероприятия по предотвращению горных ударов должны утверждаться представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
        ],
      },
      {
        code: '63683044',
        text: 'Какие виды происшествий относятся к категории аварий?',
        answers: [
          'Потопление драг, земснарядов, плавучих насосных станций',
          'Замораживание отдельных участков пульповодов и водоводов',
          'Пожары и возгорания в подземных горных выработках, не приведшие к выводу людей на поверхность либо к случаям травмирования',
          'Разрушение узлов и деталей участковых вентиляторных установок, центральных водоотливов, компрессорных установок, приведшее к их остановке',
          'Забивание посторонними предметами (заилование) водосбросных сооружений хвостохранилищ и шламохранилищ, приведшее к приостановке производственного процесса',
        ],
        correctAnswers: [
          'Потопление драг, земснарядов, плавучих насосных станций',
        ],
      },
      {
        code: '63683045',
        text:
          'Какими преимущественно системами разработки при совместной разработке месторождения следует производить подземные горные работы?',
        answers: [
          'Любыми системами разработки',
          'Только системами разработки с закладкой выработанного пространства',
          'Преимущественно системами с поддержанием выработанного пространства',
          'В каждом конкретном случае возможность применения той или иной системы разработки устанавливается проектной организацией по согласованию с Ростехнадзором',
        ],
        correctAnswers: [
          'Преимущественно системами с поддержанием выработанного пространства',
        ],
      },
      {
        code: '63683046',
        text:
          'В каких горных выработках проводится локальный прогноз удароопасности угольных пластов?',
        answers: [
          'Только в горных выработках действующих выемочных участков',
          'Только в подготовительных горных выработках, проводимых по угольным пластам',
          'Только в поддерживаемых горных выработках',
          'Во всех перечисленных горных выработках',
        ],
        correctAnswers: ['Во всех перечисленных горных выработках'],
      },
      {
        code: '63683047',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
          'На основе технического регламента',
          'На основе статистических данных по запасам полезных ископаемых Минприроды России',
          'На основе локального плана проведения горных работ',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63683048',
        text:
          'Какой вид контроля должен осуществляться при добыче под действующими блоками карьера за оставленным целиком безопасности, рассчитанным проектной организацией?',
        answers: [
          'Только оперативный контроль',
          'Инструментальный и метрологический контроль',
          'Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ',
          'Геотехнический контроль и визуальный осмотр в рамках производственного контроля',
        ],
        correctAnswers: [
          'Маркшейдерский и оперативный контроль в соответствии с Инструкцией по производству маркшейдерских работ',
        ],
      },
      {
        code: '63683049',
        text:
          'Чем обуславливаются основные факторы, представляющие опасность при комбинированной (совмещенной) разработке месторождений?',
        answers: [
          'Только степенью взаимного влияния открытого и подземного способов разработки',
          'Только технологической взаимосвязью открытого и подземного способов разработки',
          'Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки',
        ],
        correctAnswers: [
          'Степенью взаимного влияния и технологической взаимосвязи открытого и подземного способов разработки',
        ],
      },
      {
        code: '63683050',
        text:
          'Какое из перечисленных требований не допускается при комбинированной разработке месторождений?',
        answers: [
          'Фронт ведения горных работ располагать в направлении от массива к карьеру при подземных очистных работах',
          'Фронт ведения горных работ располагать навстречу фронту развития подземных очистных работ при открытых работах',
          'Добыча руды подземным способом из проектных контуров карьера',
          'Все перечисленные требования',
        ],
        correctAnswers: [
          'Добыча руды подземным способом из проектных контуров карьера',
        ],
      },
      {
        code: '63683051',
        text:
          'Какой должна быть допустимая скорость движения автомобилей, автомобильных и тракторных поездов на технологических дорогах карьера?',
        answers: [
          'Скорость устанавливается руководителем организации',
          '15 км/ч',
          '25 км/ч',
          'Скорость устанавливается техническим руководителем организации',
        ],
        correctAnswers: [
          'Скорость устанавливается техническим руководителем организации',
        ],
      },
      {
        code: '63683052',
        text:
          'Как часто должны проходить медицинский осмотр рабочие, выполняющие работы повышенной опасности, перечень которых установлен руководителем организации?',
        answers: [
          'Каждый раз перед началом смены',
          '1 раз в полугодие',
          '1 раз в месяц',
          '1 раз в неделю',
        ],
        correctAnswers: ['Каждый раз перед началом смены'],
      },
      {
        code: '63683053',
        text:
          'Какие требования предъявляются к максимально допустимой площади обнажения кровли в сдвоенных смежных камерах при применении системы разработки с ленточными целиками?',
        answers: [
          'Не должна превышать 4500 и 3500 м² в породах I и II классов устойчивости',
          'Не должна превышать 4000 и 3000 м² в породах I и II классов устойчивости',
          'Не должна превышать 5000 и 4000 м² в породах I и II классов устойчивости',
          'Определяется паспортом крепления и управления кровлей',
        ],
        correctAnswers: [
          'Не должна превышать 4000 и 3000 м² в породах I и II классов устойчивости',
        ],
      },
      {
        code: '63683054',
        text:
          'Кем утверждаются пороговые значения прогностических параметров, установленные по результатам текущего прогноза выбросоопасности по параметрам искусственного акустического сигнала?',
        answers: [
          'ИТР геологической службы угледобывающей организации',
          'Работником службы прогноза динамических явлений',
          'Руководителем угледобывающей организации',
          'Техническим руководителем (главным инженером) угледобывающей организации',
        ],
        correctAnswers: [
          'Техническим руководителем (главным инженером) угледобывающей организации',
        ],
      },
      {
        code: '63683055',
        text:
          'Каким должно быть расстояние между стенками стволов при строительстве шахт на месторождениях, склонных и опасных по горным ударам?',
        answers: [
          'Не менее 50 м',
          'Не менее 45 м',
          'Не менее 30 м',
          'Не менее 25 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63683056',
        text:
          'Как часто во всех действующих выработках должны производиться нивелирование откаточных путей и проверки соответствия зазоров требованиям Федеральных норм и правил в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Один раз в год',
          'Один раз в пять лет',
          'Один раз в два года',
          'Один раз в три года',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63683057',
        text:
          'Кто является ответственным за организацию составления проекта границ опасных зон у затопленных выработок на действующих шахтах?',
        answers: [
          'Технический руководитель шахты',
          'Главный геолог шахты',
          'Главный маркшейдер шахты',
          'Главный механик шахты',
        ],
        correctAnswers: ['Главный маркшейдер шахты'],
      },
      {
        code: '63683058',
        text:
          'Какие визуальные и инструментальные наблюдения должны проводиться на объектах ведения открытых горных работ?',
        answers: [
          'За деформацией просадочности (для глинистых пород)',
          'За состоянием бортов, уступов и откосов',
          'За проявлениями удароопасности горных пород, вмещающих полезное ископаемое',
          'За закарстованностью месторождения',
        ],
        correctAnswers: ['За состоянием бортов, уступов и откосов'],
      },
      {
        code: '63683059',
        text:
          'Чем необходимо руководствоваться при подготовке обоснований границ горного отвода?',
        answers: [
          'Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ',
          'Площадь проекции горного отвода на земную поверхность определяется в гектарах с точностью до одной сотой',
          'При использовании в качестве подземных хранилищ соляных пластов границы горного отвода устанавливаются по границам геометрического тела сферической формы, увеличенным не менее чем на 3 м по вертикали и 20 м по горизонтали относительно контура камер выщелачивания',
          'Всем перечисленным',
        ],
        correctAnswers: [
          'Горный отвод может состоять из нескольких отдельных блоков недр (рассредоточенный горный отвод) в случаях, когда участки ведения работ по добыче полезных ископаемых представлены отдельными участками (рудными телами, пластами) одного месторождения и (или) месторождениями полезных ископаемых (эксплуатационными объектами), находящимися в границах одного участка недр, предоставленного на основании лицензии на пользование недрами, и (или) отдельными участками ведения работ (объектами, сооружениями), связанных с пользованием недрами, предусмотренными проектной документацией и (или) для месторождений твердых полезных ископаемых по решению пользователя недр схемами развития горных работ',
        ],
      },
      {
        code: '63683060',
        text:
          'Кто утверждает акт окончания работ по бурению водоспускной или опережающей скважины?',
        answers: [
          'Технический руководитель шахты',
          'Главный маркшейдер шахты',
          'Начальник участка',
          'Главный геолог шахты',
          'Горный мастер службы вентиляции и техники безопасности',
        ],
        correctAnswers: ['Технический руководитель шахты'],
      },
      {
        code: '63683061',
        text: 'Какое максимальное время зачистки почвы камеры?',
        answers: ['2 суток', '3 суток', '5 суток', '7 суток'],
        correctAnswers: ['2 суток'],
      },
      {
        code: '63683062',
        text:
          'В какой документ должны включаться мероприятия при обнаружении признаков нарушения устойчивости или критических деформаций?',
        answers: [
          'В журнал маркшейдерских указаний',
          'В схему деформирования',
          'В акт проведенных работ',
          'В планы развития горных работ на предстоящий календарный период',
        ],
        correctAnswers: [
          'В планы развития горных работ на предстоящий календарный период',
        ],
      },
      {
        code: '63683063',
        text:
          'Сколько сигнальных устройств должна иметь подъемная установка при проходке и углублении стволов?',
        answers: [
          'Количество независимых сигнальных устройств определяется проектом ведения горных работ',
          'Не менее двух независимых сигнальных устройств',
          'Одно независимое сигнальное устройство',
        ],
        correctAnswers: ['Не менее двух независимых сигнальных устройств'],
      },
      {
        code: '63683064',
        text:
          'В какой период, установленный "Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых", осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?',
        answers: [
          'С 1 октября по 30 декабря года, предшествующего планируемому',
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
          'С 1 сентября по 15 декабря года, предшествующего планируемому',
          'С 20 октября по 30 декабря года, предшествующего планируемому',
        ],
        correctAnswers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
      },
      {
        code: '63683065',
        text:
          'Что в соответствии с Инструкцией по разработке многолетнемерзлых россыпей подземным способом определяется как "выработка, проведенная по пласту или вне его и предназначенная для сбора и транспортировки отбитой горной массы из очистных забоев в пределах панели"?',
        answers: [
          'Панель односторонняя',
          'Панельный сборный штрек',
          'Панель двухсторонняя',
          'Панельный вентиляционный штрек',
        ],
        correctAnswers: ['Панельный сборный штрек'],
      },
      {
        code: '63683066',
        text:
          'Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?',
        answers: [
          'Курьером',
          'Заказным почтовым отправлением через АО "Почта России"',
          'В соответствии с законодательством Российской Федерации о государственной тайне',
          'Любым доступным способом',
        ],
        correctAnswers: [
          'В соответствии с законодательством Российской Федерации о государственной тайне',
        ],
      },
      {
        code: '63683067',
        text:
          'Какие из перечисленных факторов должны учитываться при проектировании, строительстве и эксплуатации горнорудных объектов с комбинированной (совмещенной) разработкой?',
        answers: [
          'Только воздействие массовых взрывов на карьере на состояние подземных горных выработок',
          'Только исключение прорывов в подземные горные выработки паводковых и ливневых вод',
          'Только наличие аэродинамических связей между карьером и подземным рудником',
          'При ведении горных работ должны учитываться все перечисленные факторы',
        ],
        correctAnswers: [
          'При ведении горных работ должны учитываться все перечисленные факторы',
        ],
      },
      {
        code: '63683068',
        text:
          'На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ?',
        answers: [
          'Не более 7 лет',
          'Не более 6 лет',
          'Не более 5 лет',
          'Срок не ограничен',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63683069',
        text:
          'За какое расстояние до подхода забоя ствола к выработкам или опасным зонам следует предусматривать бурение опережающих скважин (шпуров) для контроля контура выработок и уровня воды в них?',
        answers: ['За 8 м', 'За 10 м', 'За 15 м', 'За 20 м'],
        correctAnswers: ['За 10 м'],
      },
      {
        code: '63683070',
        text:
          'В какой срок со дня поступления заявления и проекта горного отвода осуществляется оформление документации либо принятие мотивированного решения об отказе?',
        answers: [
          'Не позднее 28 дней',
          'Не позднее 35 дней',
          'Не позднее 25 дней',
          'Не позднее 30 дней',
        ],
        correctAnswers: ['Не позднее 25 дней'],
      },
      {
        code: '63683071',
        text:
          'В каких случаях допускается частичная или полная выемка угля в целиках без спуска воды из затопленных выработок?',
        answers: [
          'При положительном заключении территориального органа Ростехнадзора',
          'По решению технического руководителя вышестоящей, по отношению к шахте, организации',
          'При постоянном дежурстве горноспасателей на рабочих местах',
          'При наличии заключения Научно-исследовательского института горной геомеханики и маркшейдерского дела, по согласованию с органами Ростехнадзора',
        ],
        correctAnswers: [
          'При наличии заключения Научно-исследовательского института горной геомеханики и маркшейдерского дела, по согласованию с органами Ростехнадзора',
        ],
      },
      {
        code: '63683072',
        text:
          'Что является коэффициентом запаса устойчивости при детерминированном подходе?',
        answers: [
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
          'Допустимая вероятность развития деформаций, которые определяются проектной документацией с учетом размещения элементов инфраструктуры объекта недропользования',
          'Действие удерживающих и сдвигающих сил во всех инженерных методах переносится на потенциальную (наиболее напряженную) поверхность скольжения',
        ],
        correctAnswers: [
          'Отношение удерживающих и сдвигающих сил на сформировавшейся поверхности скольжения',
        ],
      },
      {
        code: '63683073',
        text:
          'Каким должно быть расстояние между стенками стволов при строительстве объекта подземного строительства на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Не менее 50 м',
          'Не менее 45 м',
          'Не менее 30 м',
          'Не менее 25 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63683074',
        text:
          'С какой целью производят закладку наблюдательных станций на рассолопромыслах и проводят инструментальные наблюдения?',
        answers: [
          'Для ведения учета добычи и потерь полезного ископаемого',
          'Только для определения эффективности применяемых конструктивных и горных мер охраны объектов от вредного влияния подземных камер растворения',
          'Для определения параметров процесса сдвижения, установления взаимосвязи между деформациями в подрабатываемых объектах, определения эффективности применяемых конструктивных и горных мер охраны объектов от вредного влияния подземных камер растворения',
        ],
        correctAnswers: [
          'Для определения параметров процесса сдвижения, установления взаимосвязи между деформациями в подрабатываемых объектах, определения эффективности применяемых конструктивных и горных мер охраны объектов от вредного влияния подземных камер растворения',
        ],
      },
      {
        code: '63683075',
        text:
          'Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
          'Только из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия',
          'Только из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)',
          'Только из геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63683076',
        text:
          'Кем осуществляются подготовка и утверждение планов и схем развития горных работ?',
        answers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ; утверждение - руководителем организации - пользователя недр',
          'Подготовка - пользователем недр; утверждение - руководителем территориального органа Ростехнадзора',
          'Подготовка - подрядной организацией, привлекаемой пользователем недр; утверждение - руководителем территориального органа Ростехнадзора',
          'Подготовка - подрядной организацией, привлекаемой пользователем недр; утверждение - представителем местной исполнительной власти субъекта Российской Федерации',
        ],
        correctAnswers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ; утверждение - руководителем организации - пользователя недр',
        ],
      },
      {
        code: '63683077',
        text:
          'С какой периодичностью необходимо выполнять замеры водопритоков в проводимые выработки при проведении горных и буровых работ в опасной по прорывам воды зоне в случаях отсутствия резкого увеличения водопритока?',
        answers: ['Еженедельно', 'Ежедневно', 'Каждый час', 'Каждые 2 часа'],
        correctAnswers: ['Ежедневно'],
      },
      {
        code: '63683078',
        text:
          'Допустимо ли при повторной разработке россыпей пользоваться оценкой устойчивости пород кровли, принятой при первичной разработке россыпи?',
        answers: [
          'Недопустимо',
          'Допустимо, если класс устойчивости пород кровли понизится на одну ступень',
          'Класс устойчивости пород кровли повторно разрабатываемой шахты определяется заново в соответствии с соответствующей Методикой',
          'Допустимо, если класс устойчивости пород кровли повысится на одну ступень',
        ],
        correctAnswers: [
          'Класс устойчивости пород кровли повторно разрабатываемой шахты определяется заново в соответствии с соответствующей Методикой',
        ],
      },
      {
        code: '63683079',
        text:
          'Какой следует принимать максимальный допустимый пролет первых от границ шахтного поля очистных камер?',
        answers: ['16 м', '18 м', '20 м', '22 м', '24 м'],
        correctAnswers: ['20 м'],
      },
      {
        code: '63683080',
        text:
          'В скважинах какой минимальной длины устанавливаются геофоны на выемочных участках для проведения текущего прогноза по акустической эмиссии?',
        answers: ['1 м', '2 м', '3 м', '4 м', '5 м'],
        correctAnswers: ['2 м'],
      },
      {
        code: '63683081',
        text:
          'Что должны включать графические материалы проекта горного отвода при разработке месторождений полезных ископаемых, представленных комплексом рудных тел, а также месторождений угля? Укажите все правильные ответы.',
        answers: [
          'Гипсометрические планы рудных тел и пластов',
          'Геологическое строение участка недр',
          'Погоризонтные планы рудных тел и пластов',
          'Глубины залегания продуктивных свит и горизонтов',
          'Контуры границ горного отвода',
        ],
        correctAnswers: [
          'Гипсометрические планы рудных тел и пластов',
          'Погоризонтные планы рудных тел и пластов',
        ],
      },
      {
        code: '63683082',
        text: 'Что относится к комбинированной разработке месторождений?',
        answers: [
          'Одновременное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения',
          'Последовательное ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения',
          'Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения',
        ],
        correctAnswers: [
          'Ведение открытых и подземных горных работ с совмещением в пространстве по вертикали или горизонтали в пределах одного месторождения',
        ],
      },
      {
        code: '63683083',
        text:
          'Кем устанавливаются размеры призмы обрушения (сползания) породы при отвалообразовании, в пределах которой не должны разгружаться автомобили и другие транспортные средства?',
        answers: [
          'Работниками проектной организацией при разработке проекта на отвал',
          'Техническим руководителем организации',
          'Работниками геологической службы',
          'Работниками маркшейдерской службы',
        ],
        correctAnswers: ['Работниками маркшейдерской службы'],
      },
      {
        code: '63683084',
        text:
          'Что при ведении горных работ является объектами открытых горных работ?',
        answers: [
          'Только карьеры, прииски, гидроотвалы',
          'Только объекты кучного выщелачивания',
          'Только породные, шлаковые отвалы, гидроотвалы',
          'Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы',
        ],
        correctAnswers: [
          'Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы',
        ],
      },
      {
        code: '63683085',
        text:
          'Назовите основной принцип определения класса устойчивости пород кровли (предварительный и окончательный) при разработке многолетнемерзлых россыпей подземным способом.',
        answers: [
          'Определять однозначно по общей характеристике горно-геологических условий выемочного участка',
          'Определять однозначно по усредненной характеристике горно-геологических условий выемочного участка',
          'Определять однозначно по средневзвешенной характеристике горно-геологических условий выемочного участка или отдельной его части',
          'Определять однозначно по наихудшей характеристике горно-геологических условий выемочного участка или отдельной его части',
        ],
        correctAnswers: [
          'Определять однозначно по наихудшей характеристике горно-геологических условий выемочного участка или отдельной его части',
        ],
      },
      {
        code: '63683086',
        text:
          'По прошествии какого времени после взрыва может осуществляться допуск постов профессиональной аварийно-спасательной службы (формирования) в пределы опасной зоны?',
        answers: ['5 минут', '12 минут', '10 минут', '15 минут'],
        correctAnswers: ['15 минут'],
      },
      {
        code: '63683087',
        text:
          'Кем должны определяться границы опасных зон по прорывам воды и газов при комбинированной (совмещенной) разработке с неблагоприятными гидрогеологическими условиями, наличием затопленных горных выработок или других водных объектов, а также горных выработок, в которых возможны скопления ядовитых и горючих газов?',
        answers: [
          'Только маркшейдерской службой предприятия',
          'Только геологической службой предприятия',
          'Маркшейдерской и геологической службой предприятия с привлечением представителей территориального органа Ростехнадзора',
          'Маркшейдерской и геологической службой предприятия с привлечением специализированных предприятий',
        ],
        correctAnswers: [
          'Маркшейдерской и геологической службой предприятия с привлечением специализированных предприятий',
        ],
      },
      {
        code: '63683088',
        text:
          'В какой срок пользователи недр представляют заявление о согласовании планов развития горных работ в орган государственного горного надзора?',
        answers: [
          'До 25 декабря текущего года (года, предшествующего планируемому)',
          'До 25 сентября текущего года (года, предшествующего планируемому)',
          'До 1 сентября текущего года (года, предшествующего планируемому)',
          'До 1 декабря текущего года (года, предшествующего планируемому)',
        ],
        correctAnswers: [
          'До 1 сентября текущего года (года, предшествующего планируемому)',
        ],
      },
      {
        code: '63683089',
        text:
          'В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?',
        answers: [
          'Не мельче 1 : 20 000',
          'Не мельче 1 : 15 000',
          'Не мельче 1 : 25 000',
          'Не мельче 1 : 10 000',
          'Не мельче 1 : 30 000',
        ],
        correctAnswers: ['Не мельче 1 : 10 000'],
      },
      {
        code: '63683090',
        text:
          'Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?',
        answers: [
          'Органом государственного горного надзора',
          'Органами исполнительной власти каждого из субъектов Российской Федерации',
          'Органом исполнительной власти любого из причастных к горному отводу субъекта Российской Федерации',
        ],
        correctAnswers: ['Органом государственного горного надзора'],
      },
      {
        code: '63683091',
        text:
          'Разрешается ли ведение горных работ вблизи затопленных выработок или поверхностных водоемов?',
        answers: [
          'Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды',
          'Запрещается',
          'Разрешается по согласованию с территориальным органом государственного горного надзора',
        ],
        correctAnswers: [
          'Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды',
        ],
      },
      {
        code: '63683092',
        text:
          'Что должно быть изучено в результате инженерно-геологических и гидрогеологических исследований?',
        answers: [
          'Только физические свойства складируемых пород, прочность их в куске',
          'Только строение основания (наклон основания, наличие прослоев слабых пород и их мощность, наличие водоносных горизонтов, возможность образования техногенных водоносных горизонтов в нижней части отвала и в породах основания)',
          'Только сдвиговые характеристики отвальной массы',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683093',
        text:
          'Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?',
        answers: [
          'Только организация наблюдений за состоянием горных отводов',
          'Только достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)',
          'Только консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при наличии)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63683094',
        text:
          'С учетом каких условий проектом должны определяться схемы и способы проветривания рудников (шахт) при наличии аэродинамических связей подземных горных выработок с карьерным пространством?',
        answers: [
          'С учетом образования избыточной депрессии в зоне влияния открытых горных работ',
          'С учетом обеспечения нейтральной депрессии в выработках, имеющих связь с дневной поверхностью',
          'С учетом применения комбинированного (нагнетательно-всасывающего) способа проветривания',
          'С учетом возникающей естественной тяги',
        ],
        correctAnswers: [
          'С учетом образования избыточной депрессии в зоне влияния открытых горных работ',
        ],
      },
      {
        code: '63683095',
        text:
          'Что устанавливается в лицензиях на пользование недрами, технических проектах и иной проектной документации на выполнение работ, связанных с пользованием участками недр?',
        answers: [
          'Только соблюдение требований по технологии ведения работ',
          'Только дополнительные мероприятия, обеспечивающие безопасное пользование недрами',
          'Только дополнительные мероприятия по прогнозированию и предупреждению опасных ситуаций с соответствующей корректировкой технических и технологических решений',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683096',
        text:
          'Бурение каких скважин предусматривают гидрогеологические разведочные работы, предшествующие извлечению отдельного целика вытянутой формы в ранее отработанном шахтном поле?',
        answers: [
          'Только контрольных скважин с обеих длинных сторон целика на расстоянии 10 - 15 м от его контура',
          'Только контрольных скважин со стороны торцов извлекаемого целика на расстоянии 5 - 10 м по оси целика',
          'Только контрольных скважин в места наиболее вероятного скопления воды при расположении этих мест от извлекаемого целика ближе 10 м',
          'Только контрольных скважин для разведки трассы проводимой к целику вскрывающей выработки - с поверхности по оси выработки с максимальным расстоянием между скважинами 50 м',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63683097',
        text:
          'Под чьим руководством производится инструментальная проверка вертикальности копра, правильности установки направляющих шкивов по отношению к оси ствола и оси подъема?',
        answers: [
          'Под руководством главного геолога шахты (рудника)',
          'Под руководством главного механика шахты (рудника)',
          'Под руководством главного маркшейдера шахты (рудника)',
          'Под руководством горного мастера службы вентиляции и техники безопасности',
        ],
        correctAnswers: [
          'Под руководством главного маркшейдера шахты (рудника)',
        ],
      },
      {
        code: '63683098',
        text:
          'В каком случае нарушены требования к системам вентиляции при комбинированной (совмещенной) разработке месторождения?',
        answers: [
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
          'Вспомогательные нагнетательные вентиляторы должны быть максимально приближены к зонам аэродинамических связей с обеспечением подпора воздуха в подземных выработках',
          'Способы проветривания рудников (шахт) определяются проектом с учетом образования избыточной депрессии в зоне влияния открытых работ',
          'При комбинированной (совмещенной) разработке месторождения и наличии аэродинамических связей подземных выработок с карьерным пространством предусматривается нагнетательный способ общерудничной системы проветривания',
        ],
        correctAnswers: [
          'Вентиляция горных работ при вскрытии и отработке запасов руд, находящихся в бортах и под дном карьера, может быть принята только локальной через вскрывающие выработки из карьера',
        ],
      },
      {
        code: '63683099',
        text:
          'В течение какого времени должны расследоваться события, предшествующие динамическим явлениям при первом их проявлении?',
        answers: [
          'В течение 10 дней',
          'В течение двадцати четырех часов',
          'В течение тридцати двух часов',
          'В течение 5 дней',
        ],
        correctAnswers: ['В течение двадцати четырех часов'],
      },
      {
        code: '63683100',
        text:
          'Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?',
        answers: [
          'Руководителем организации',
          'Техническим руководителем объекта',
          'Специализированной организацией, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Руководителем территориального органа Ростехнадзора',
          'Руководителем маркшейдерско-геологической службы',
        ],
        correctAnswers: ['Техническим руководителем объекта'],
      },
      {
        code: '63683101',
        text:
          'Какое допускается максимальное отклонение от нулевого кислородного баланса при производстве массовых взрывов при комбинированной (совмещенной) разработке рудных и нерудных месторождений полезных ископаемых?',
        answers: ['20%', '25%', '30%', '35%'],
        correctAnswers: ['25%'],
      },
      {
        code: '63683102',
        text: 'Что указывается на плане горного отвода?',
        answers: [
          'Только линии вертикальных разрезов (профилей) и проекций',
          'Только используемые системы координат и высот, масштаб плана, координатная сетка',
          'Только площадь проекции горного отвода в гектарах на горизонтальную плоскость',
          'Только условные обозначения и поясняющие надписи, дата пополнения (актуализации) плана',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683103',
        text:
          'Требуется ли маркшейдерское обеспечение буровзрывных работ при разработке месторождений полезных ископаемых в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Не требуется',
          'Требуется только при проведении массовых взрывов',
          'Требуется только при составлении проекта на массовый взрыв',
          'Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями',
        ],
        correctAnswers: [
          'Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями',
        ],
      },
      {
        code: '63683104',
        text:
          'Кем подписывается проект горного отвода (пояснительная записка)?',
        answers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
          'Главным геологом и главным маркшейдером организации пользователя недр и (или) привлекаемого пользователем недр для их подготовки юридического лица или индивидуального предпринимателя',
          'Только руководителем организации, разработавшей проект горного отвода',
          'Главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода',
        ],
        correctAnswers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
        ],
      },
      {
        code: '63683105',
        text:
          'Какие требования предъявляются к нерастворителю, применяемому при подземном выщелачивании солей?',
        answers: [
          'Должно быть только вещество, химически нейтральное к воде',
          'Должно быть только вещество, химически нейтральное к соли и рассолу',
          'Должно быть только вещество с относительной плотностью меньше единицы',
          'Должен удовлетворять всем указанным требованиям',
        ],
        correctAnswers: ['Должен удовлетворять всем указанным требованиям'],
      },
      {
        code: '63683106',
        text:
          'В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?',
        answers: [
          'Только если прогнозом при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена категория "Опасно" (удароопасная)',
          'Только если имели место микроудары и горные удары',
          'Только если имело место стреляние пород, интенсивное заколообразование, происходили толчки или горные удары на соседнем руднике (шахте) в пределах того же рудного тела и в аналогичных геологических условиях',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63683107',
        text: 'Какие требования предъявляются к установке бурового станка?',
        answers: [
          'Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка',
          'Буровой станок должен быть установлен на спланированной площадке на расстоянии 1 метра от верхней бровки уступа',
          'Буровой станок должен быть установлен на спланированной площадке на расстоянии 1,5 метра от верхней бровки уступа',
        ],
        correctAnswers: [
          'Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка',
        ],
      },
      {
        code: '63683108',
        text:
          'Кто на действующих шахтах составляет проекты границ опасных по прорывам воды зон, а также проекты ведения горных и буровых работ в опасных зонах?',
        answers: [
          'Только геологическая служба шахты',
          'Только маркшейдерская служба шахты',
          'Только техническая служба шахты',
          'Маркшейдерская, геологическая и техническая службы шахты',
        ],
        correctAnswers: [
          'Маркшейдерская, геологическая и техническая службы шахты',
        ],
      },
      {
        code: '63683109',
        text:
          'Какие требования безопасности при выполнении работ по извлечению крепи указаны неверно?',
        answers: [
          'При извлечении крепи должно быть не менее трех выходов из отработанной очистной выработки в действующие штреки, два из которых, при необходимости, допускается загромождать оборудованием или материалами',
          'Запрещается извлекать поломанные, изогнутые и расколотые стойки, а также стойки с полностью смятой подкладкой',
          'Перед началом работ по извлечению крепи и в процессе их производства следует производить осмотр и остукивание кровли специальным ручным инструментом, а в случаях необходимости опустить отдельные отслоения пород кровли',
          'При первых признаках обрушения кровли (потрескивание стоек) лица, занятые на извлечении крепи, должны быть выведены в безопасные места',
        ],
        correctAnswers: [
          'При извлечении крепи должно быть не менее трех выходов из отработанной очистной выработки в действующие штреки, два из которых, при необходимости, допускается загромождать оборудованием или материалами',
        ],
      },
      {
        code: '63683110',
        text:
          'Какие из перечисленных работ на карьере должны вестись в соответствии с утвержденными техническим руководителем объекта локальными проектами (паспортами) и проектом производства работ с учетом инженерно-геологических условий?',
        answers: [
          'Только горные работы по разработке уступов и дражных полигонов',
          'Только горные работы по проведению траншей и разработке уступов',
          'Только горные работы по отсыпке отвалов и разработке уступов',
          'Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов',
        ],
        correctAnswers: [
          'Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов',
        ],
      },
      {
        code: '63683111',
        text:
          'Какую температуру должен иметь воздух, поступающий в подземные горные выработки?',
        answers: ['Не менее 2 °C', 'Не менее 1 °C', 'Не менее 0 °C'],
        correctAnswers: ['Не менее 2 °C'],
      },
      {
        code: '63683112',
        text:
          'В каких случаях должен производиться контроль удароопасности в выработках?',
        answers: [
          'Только в случае изменений горно-геологической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'Только в случае появления внешних признаков удароопасности',
          'Только в случае изменений горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63683113',
        text:
          'На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?',
        answers: [
          'На 1 год',
          'На 5 лет',
          'На срок действия лицензии на пользование недрами',
          'Не регламентируется',
        ],
        correctAnswers: ['На срок действия лицензии на пользование недрами'],
      },
      {
        code: '63683114',
        text:
          'Какой должна быть высота свободного прохода на штреках (ортах) скреперования?',
        answers: [
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки',
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 1/2 от высоты выработки',
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте, который определен проектом',
        ],
        correctAnswers: [
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки',
        ],
      },
      {
        code: '63683115',
        text:
          'Каким методом проводится прогноз удароопасности в месте вскрытия склонного к горным ударам угольного пласта подготовительной выработкой?',
        answers: [
          'Методом регионального прогноза по непрерывным сейсмоакустическим наблюдениям',
          'Методом локального прогноза по изменению естественной влаги угля',
          'Методом локального прогноза по выходу буровой мелочи',
          'Методом текущего прогноза по параметрам выбросоопасности',
        ],
        correctAnswers: [
          'Методом локального прогноза по выходу буровой мелочи',
        ],
      },
      {
        code: '63683116',
        text:
          'До какого расстояния следует уменьшить величину между рабочими реперами при активизации процесса сдвижения земной поверхности на рассолопромысле?',
        answers: ['10 - 15 м', '20 - 30 м', '15 - 20 м', '25 - 35 м'],
        correctAnswers: ['15 - 20 м'],
      },
      {
        code: '63683117',
        text:
          'Что должны включать табличные материалы пояснительной записки планов (схем) по видам горных работ и видам полезных ископаемых?',
        answers: [
          'Только сведения о состоянии и движении фонда скважин (при разработке месторождений углеводородного сырья)',
          'Только основные технические показатели на предстоящий период (план) и ожидаемые на конец текущего года (факт)',
          'Только сведения о состоянии и движении вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых по состоянию на начало планируемого периода и ожидаемого на конец этого периода (при добыче твердых полезных ископаемых)',
          'Только сведения об объемах проведения горных разведочных выработок, в том числе скважин эксплуатационной разведки',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683118',
        text:
          'Какими должны быть предельные углы откосов (углы устойчивости) временно консервируемых участков борта в процессе эксплуатации?',
        answers: [
          'Предельные углы откосов (углы устойчивости) устанавливаются проектом',
          'Предельные углы откосов (углы устойчивости) не должны превышать 75°',
          'Предельные углы откосов (углы устойчивости) не должны превышать 60°',
        ],
        correctAnswers: [
          'Предельные углы откосов (углы устойчивости) устанавливаются проектом',
        ],
      },
      {
        code: '63683119',
        text:
          'Какие сведения должны быть нанесены на маркшейдерские планы и разрезы?',
        answers: [
          'Только все горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры, границы поверхностного контура зоны обрушения, разведочные и контрольные наблюдательные скважины и реперы',
          'Только зоны обводненных горизонтов, положение зумпфов водоотливных установок с указанием отметок дна зумпфа, водоводов, изоляционных перемычек в дренажных подземных выработках, предотвращающих проникновение ядовитых газов из карьера в подземные выработки',
          'Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода',
        ],
        correctAnswers: [
          'Все перечисленные сведения, а также при планировании развития горных работ в карьере - границы погашения дренажных выработок на конец планируемого периода',
        ],
      },
      {
        code: '63683120',
        text:
          'В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?',
        answers: [
          'Не позднее 5 дней со дня оформления',
          'Не позднее 10 дней со дня оформления',
          'Не позднее 14 дней со дня оформления',
          'Не позднее 20 дней со дня оформления',
        ],
        correctAnswers: ['Не позднее 5 дней со дня оформления'],
      },
      {
        code: '63683121',
        text:
          'Кем выполняются прогноз динамических явлений и контроль эффективности применения мер по предотвращению динамических явлений на шахтах, разрабатывающих угольные пласты, склонные к динамическим явлениям?',
        answers: [
          'Инженерно-техническими работниками службы прогноза динамических явлений',
          'Работниками специализированной организации',
          'Работниками маркшейдерско-геологической службы',
          'Техническим руководителем (главным инженером) угледобывающей организации',
        ],
        correctAnswers: [
          'Инженерно-техническими работниками службы прогноза динамических явлений',
        ],
      },
      {
        code: '63683122',
        text:
          'Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?',
        answers: [
          'Крутопадающих мощных рудных тел',
          'Пологих рудных тел',
          'Горизонтальных рудных тел',
          'Наклонных рудных тел',
        ],
        correctAnswers: ['Крутопадающих мощных рудных тел'],
      },
      {
        code: '63683123',
        text: 'Что должны отображать графические материалы?',
        answers: [
          'Только направления развития работ, связанных с пользованием недрами, в планируемом периоде (для месторождений твердых полезных ископаемых)',
          'Только контуры отвалов, складов добытых полезных ископаемых, объектов хранения отходов (хвостов) горных и перерабатывающих производств (при наличии)',
          'Только границы опасных зон и зон с особыми условиями использования территорий (при разработке месторождений твердых полезных ископаемых при наличии)',
          'Только уточненные границы горного отвода',
          'Только контуры запасов полезных ископаемых, числящихся на учете пользователя недр в государственном балансе запасов полезных ископаемых по состоянию на 1 января текущего года',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683124',
        text: 'Кем подписывается горноотводная документация?',
        answers: [
          'Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора',
          'Главным геологом',
          'Главным маркшейдером',
          'Руководителем эксплуатирующей организации',
          'Техническим руководителем (главным инженером) эксплуатирующей организации',
        ],
        correctAnswers: [
          'Уполномоченными должностными лицами территориального органа или структурного подразделения органа государственного горного надзора, в ведении которых находятся вопросы государственного горного надзора',
        ],
      },
      {
        code: '63683125',
        text:
          'При каком давлении воды в затопленных выработках должны быть предусмотрены мероприятия, обеспечивающие безопасность горных работ при возможном разрушении направляющих труб и задвижек опережающих и водоспускных скважин?',
        answers: [
          'Более 0,3 МПа (более 3 кгс/см²)',
          'Более 0,8 МПа (более 8 кгс/см²)',
          'Более 1 МПа (более 10 кгс/см²)',
          'Более 0,5 МПа (более 5 кгс/см²)',
        ],
        correctAnswers: ['Более 1 МПа (более 10 кгс/см²)'],
      },
      {
        code: '63683126',
        text:
          'Кто на каждом объекте, отрабатывающем месторождения, склонном и опасном по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?',
        answers: [
          'Технический руководитель организации',
          'Специализированная организация, выполняющая работы по проблеме горных ударов на данном месторождении',
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
        correctAnswers: [
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
      },
      {
        code: '63683127',
        text:
          'При какой температуре допускается ведение повторной подземной разработки россыпи?',
        answers: [
          'При температуре не выше минус 10 °С на всех участках шахтного поля',
          'При температуре не выше минус 20 °С во всех подземных горных выработках',
          'При температуре массива вмещающих пород на горизонте горных работ не выше минус 20 °С',
          'При температуре массива вмещающих пород на горизонте горных работ не выше минус 15 °С',
        ],
        correctAnswers: [
          'При температуре массива вмещающих пород на горизонте горных работ не выше минус 20 °С',
        ],
      },
      {
        code: '63683128',
        text:
          'Какой минимальный радиус опасной зоны должен быть у затопленных вертикальных технических скважин, обсаженных с цементацией затрубного пространства?',
        answers: ['15 м', '20 м', '25 м', '35 м', '10 м'],
        correctAnswers: ['15 м'],
      },
      {
        code: '63683129',
        text: 'Где должна располагаться промышленная площадка рассолопромысла?',
        answers: [
          'На балансовых запасах месторождения',
          'На забалансовых запасах',
          'Вне балансовых запасов месторождения либо на участках залегания некондиционных или пониженного качества запасов',
          'Вне контуров запасов',
        ],
        correctAnswers: [
          'Вне балансовых запасов месторождения либо на участках залегания некондиционных или пониженного качества запасов',
        ],
      },
      {
        code: '63683130',
        text:
          'В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?',
        answers: [
          'Проведение и поддержание горных выработок',
          'Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков',
          'Общие положения',
          'Методы прогноза удароопасности участков массива горных пород и руд',
          'Приведение горных выработок в неудароопасное состояние',
        ],
        correctAnswers: ['Общие положения'],
      },
      {
        code: '63683131',
        text:
          'На какие перечисленные виды работ не составляются планы и схемы горных работ?',
        answers: [
          'На работы по добыче полезных ископаемых',
          'На работы, связанные со вторичной переработкой минерального сырья',
          'На рекультивационные работы',
          'На подготовительные работы',
        ],
        correctAnswers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
        ],
      },
      {
        code: '63683132',
        text:
          'Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?',
        answers: [
          'Не ранее чем через 30 минут',
          'Не ранее чем через 15 минут',
          'Не ранее чем через 20 минут',
          'Не ранее чем через 25 минут',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63683133',
        text:
          'Какого формата должен быть план границ горного отвода, на котором должны отображаться: контуры предварительных и уточненных границ горного отвода, угловые точки границ горного отвода?',
        answers: ['А0', 'А1', 'А4', 'А3'],
        correctAnswers: ['А4'],
      },
      {
        code: '63683134',
        text:
          'Чем и в зависимости от чего устанавливается скорость движения поездов на железнодорожных путях объекта открытых горных работ?',
        answers: [
          'Скорость движения устанавливается специальным проектом организации в зависимости от производительности предприятия и местных условий',
          'Скорость движения устанавливается регламентом в зависимости от дальности перевозок вскрышных пород и добытого полезного ископаемого, с учетом верхнего строения пути',
          'Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий',
        ],
        correctAnswers: [
          'Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий',
        ],
      },
      {
        code: '63683135',
        text:
          'К какой категории следует относить происшествие, если оно содержит признаки аварии и инцидента?',
        answers: [
          'К категории инцидента',
          'К категории аварии',
          'Решение о присвоении категории принимает технический руководитель организации',
          'Решение о присвоении категории принимает территориальный орган Ростехнадзора',
        ],
        correctAnswers: ['К категории аварии'],
      },
      {
        code: '63683136',
        text:
          'В соответствии с какими требованиями необходимо осуществлять гидрогеологические разведочные работы при извлечении запасов, относительно равномерно распределенных по площади в ранее отработанном шахтном поле?',
        answers: [
          'Предусматривать бурение контрольных скважин в места наименее вероятного скопления воды',
          'Бурить контрольные шпуры при приближении забоя выработки к границе отрабатываемого целика на расстоянии 10 м',
          'Контрольные скважины у барьерных целиков располагать на расстоянии 15 - 20 м от торцов барьерных целиков по их оси',
          'Контрольные скважины бурить по осям панельных сборных штреков, количество скважин определять проектом',
        ],
        correctAnswers: [
          'Контрольные скважины бурить по осям панельных сборных штреков, количество скважин определять проектом',
        ],
      },
      {
        code: '63683137',
        text:
          'Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?',
        answers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
          'Указаниями территориального органа Ростехнадзора',
          'Техническими условиями, разработанными специализированной проектной организацией',
          'Локальным прогнозом удароопасности',
        ],
        correctAnswers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
        ],
      },
      {
        code: '63683138',
        text:
          'В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?',
        answers: [
          'Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)',
          'Только в виде схем эксплуатации объектов и водозаборных сооружений в отношении месторождений минеральных, теплоэнергетических (термальных), технических, промышленных вод',
          'Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63683139',
        text:
          'Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?',
        answers: [
          'Только определение опасных зон, охранных зон и их границ, порядка ведения работ вблизи таких зон и в этих зонах',
          'Только восполнение количества вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых и их соответствие планируемому объему добычи полезных ископаемых (при разработке месторождений твердых полезных ископаемых)',
          'Только соблюдение требований по безопасному ведению горных и взрывных работ, включая меры по охране населения, зданий, сооружений и природных объектов, расположенных на земной поверхности в зоне вредного влияния горных разработок, подземных горных выработок, объектов и сооружений',
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
        correctAnswers: [
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
      },
      {
        code: '63683140',
        text:
          'Какое допускается максимальное расстояние между опережающими скважинами (шпурами) при оценке возможного положения затопленной одиночной выработки или незатампонированной скважины?',
        answers: ['2 м', '4 м', '6 м', '8 м', '10 м'],
        correctAnswers: ['4 м'],
      },
      {
        code: '63683141',
        text:
          'Кто утверждает специальный план, в соответствии с которым производится вскрытие пожарного участка?',
        answers: [
          'Технический руководитель организации',
          'Руководитель территориального органа Ростехнадзора',
          'Руководитель организации',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63683142',
        text:
          'С какой периодичностью должно производиться профилирование стенок шахтного ствола и проводников в нем и где отражаются результаты такого профилирования?',
        answers: [
          'Раз в год с отражением результатов в паспорте ствола',
          'Раз в три года с отражением результатов в журнале осмотра подъемной установки',
          'Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации',
          'Сроки профилирования устанавливаются территориальным органом Ростехнадзора по представлению главного инженера организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в журнале осмотра крепи и состояния горных выработок',
        ],
        correctAnswers: [
          'Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации',
        ],
      },
      {
        code: '63683143',
        text:
          'В каком документе в зависимости от видов полезных ископаемых и целей пользования недрами должны отображаться контуры месторождения, совмещенный (интегральный) контур поставленных на государственный баланс запасов полезных ископаемых?',
        answers: [
          'На графических материалах проекта горного отвода',
          'В пояснительной записке проекта горного отвода',
          'В приложении к лицензии на пользование недрами',
          'На плане горного отвода',
        ],
        correctAnswers: ['На плане горного отвода'],
      },
      {
        code: '63683144',
        text:
          'В каком случае при необходимости внесения изменений в уточненные границы горного отвода документация не подлежит переоформлению?',
        answers: [
          'В случае изменения срока пользования участком недр, установленного в лицензии на пользование недрами',
          'В случае изменения геологической информации о недрах, наличия технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
          'В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
          'В случае выявления технических ошибок в лицензии на пользование недрами и (или) в документации',
        ],
        correctAnswers: [
          'В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
        ],
      },
      {
        code: '63683145',
        text:
          'В отношении каких видов горных работ планы и схемы развития горных работ не составляются?',
        answers: [
          'Вскрышных и подготовительных работ',
          'Работ по обустройству транспортной структуры места проведения горных работ',
          'Геологических и маркшейдерских работ',
          'Работ по добыче полезных ископаемых и работ, связанных с первичной переработкой минерального сырья',
        ],
        correctAnswers: [
          'Работ по обустройству транспортной структуры места проведения горных работ',
        ],
      },
      {
        code: '63683146',
        text:
          'Какой порядок отработки панелей предусматривается при применении столбовой системы разработки?',
        answers: [
          'Прямой порядок, от руддвора или транспортного штрека к границам горного отвода',
          'Отступающий порядок по направлению к главному транспортному штреку или околоствольному двору главного ствола',
          'Комбинированный способ отработки: прямым и обратным ходом',
          'Порядок отработки выбирается в проекте на рудник',
        ],
        correctAnswers: [
          'Отступающий порядок по направлению к главному транспортному штреку или околоствольному двору главного ствола',
        ],
      },
      {
        code: '63683147',
        text:
          'Что в соответствии с Инструкцией по разработке многолетнемерзлых россыпей подземным способом определяется как "выработка небольшой ширины (10 - 30 м), ограниченная междукамерными целиками или боковым массивом и междукамерными целиками, выемка пласта песков в которой осуществляется по короткой стороне"?',
        answers: [
          'Камеры (лавы) смежные',
          'Камера-лава',
          'Камера очистная',
          'Камера',
        ],
        correctAnswers: ['Камера очистная'],
      },
      {
        code: '63683148',
        text:
          'С какой периодичностью буровой мастер должен проверять исправность задвижек при подходе забоя скважины к затопленным выработкам?',
        answers: [
          'Ежемесячно',
          'Ежесменно',
          'Периодичность проверки устанавливает технический руководитель шахты',
          'Перед каждым спуском и подъемом бурильных труб',
        ],
        correctAnswers: ['Перед каждым спуском и подъемом бурильных труб'],
      },
      {
        code: '63683149',
        text:
          'На какой минимальной высоте должны быть размещены трубы, желоба и другие коммуникации в случае пересечения ими проходов и рабочих площадок?',
        answers: ['2,0 м', '1,7 м', '1,8 м', '2,1 м', '2,2 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63683150',
        text: 'Что относится к признакам внезапного выброса угля и газа?',
        answers: [
          'Только воздушный толчок и звуковые эффекты, повреждение и (или) перемещение оборудования, наличие угольной пыли на откосе выброшенного угля и крепи горной выработки',
          'Только быстропротекающее разрушение призабойной части угольного пласта, образование в угольном пласте полости, ширина которой меньше ее глубины',
          'Только относительное газовыделение из разрушенного угля, превысившее его природную газоносность',
          'Только отброс угля от забоя на расстояние, превышающее протяженность его размещения под углом естественного откоса',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683151',
        text:
          'На какое расстояние при одновременной отработке нескольких подэтажей системой подэтажного обрушения каждый верхний подэтаж должен опережать нижний?',
        answers: [
          'На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа',
          'На расстояние не менее чем на длину, равную высоте двух подэтажей',
          'Расстояние определяется специальным проектом',
        ],
        correctAnswers: [
          'На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа',
        ],
      },
      {
        code: '63683152',
        text: 'Какой контур затопленных выработок можно считать достоверным?',
        answers: [
          'Если он зафиксирован в горной графической документации, составленной без проведения маркшейдерских съемок',
          'Если он зафиксирован в горной графической документации по результатам маркшейдерских съемок, выполненных без остановки забоев выработок с соблюдением требований по производству маркшейдерских работ',
          'Если он зафиксирован в горной графической документации по результатам маркшейдерских съемок, выполненных после остановки забоев выработок с соблюдением требований инструкции по производству маркшейдерских работ; правильность нанесения контура выработок может быть проверена по материалам этих съемок',
        ],
        correctAnswers: [
          'Если он зафиксирован в горной графической документации по результатам маркшейдерских съемок, выполненных после остановки забоев выработок с соблюдением требований инструкции по производству маркшейдерских работ; правильность нанесения контура выработок может быть проверена по материалам этих съемок',
        ],
      },
      {
        code: '63683153',
        text:
          'Кем утверждается паспорт буровзрывных работ для каждого рабочего уступа - выемочного блока на основе маркшейдерской документации?',
        answers: [
          'Техническим руководителем карьера',
          'Руководителем территориального органа Ростехнадзора',
          'Главным маркшейдером',
          'Руководителем организации',
        ],
        correctAnswers: ['Техническим руководителем карьера'],
      },
      {
        code: '63683154',
        text:
          'На какое давление должна быть рассчитана герметизация устья и задвижек водоспускных и опережающих скважин?',
        answers: [
          'На давление, в 1,5 раза превышающее давление на устье скважины при вскрытии ею затопленной выработки с определенным уровнем (напором) воды',
          'На давление, рассчитанное по формуле в зависимости от притока (расхода) воды по скважине, диаметра скважины, напора над устьем скважины',
          'На давление более 2,0 МПа (20 кгс/см²)',
          'На давление более 0,5 МПа (5 кгс/см²)',
          'На давление, в 2 раза превышающее давление на устье скважины при вскрытии ею затопленной выработки с определенным уровнем (напором) воды',
        ],
        correctAnswers: [
          'На давление, в 1,5 раза превышающее давление на устье скважины при вскрытии ею затопленной выработки с определенным уровнем (напором) воды',
        ],
      },
      {
        code: '63683155',
        text:
          'Какие угольные пласты относятся к категории "особовыбросоопасные угольные пласты"?',
        answers: [
          'Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений',
          'Угольные пласты (горные породы), угрожаемые по горным ударам, на которых в пределах данного шахтного поля произошел горный удар',
          'Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля при проведении прогноза выбросоопасности выявлена категория "опасно"',
          'Угольные пласты (горные породы), угрожаемые по внезапным выбросам угля (породы) и газа, на которых в пределах данного шахтного поля произошел внезапный выброс угля (породы) и газа',
        ],
        correctAnswers: [
          'Выбросоопасные угольные пласты (участки угольных пластов) в зонах активных по внезапным выбросам тектонических нарушений',
        ],
      },
      {
        code: '63683156',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Техническим руководителем организации - пользователя недр',
          'Руководителем территориального органа государственного горного надзора',
          'Руководителем организации - пользователя недр',
          'Руководителем местного органа исполнительной власти субъекта Российской Федерации',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63683157',
        text:
          'В каком случае нарушены требования к осушению и водоотливу при комбинированной (совмещенной) разработке месторождений с неблагоприятными гидрогеологическими условиями?',
        answers: [
          'Вода из карьера в подземные выработки должна поступать самотеком через дренажные скважины с обязательным применением забивных или сквозных фильтров',
          'Допускается частичная отработка предохранительных целиков без предварительного отвода воды из водоемов системами с закладкой по проекту, утвержденному руководителем предприятия и согласованному со специализированной организацией',
          'Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера',
          'Горные работы в пределах барьерного или предохранительного целика под водоемом (затопленным карьером) должны производиться только после спуска воды из затопленных выработок',
        ],
        correctAnswers: [
          'Допускается использовать действующие рудоспуски и вентиляционные восстающие в качестве дренажных выработок для перепуска грунтовых и паводковых вод из карьера',
        ],
      },
      {
        code: '63683158',
        text:
          'На протяжении какого периода времени собирается информация, на основании которой определяются пороговые значения параметров сейсмических событий применяемого метода регионального прогноза для каждого пласта?',
        answers: [
          'Не менее шести месяцев',
          'Не менее пяти месяцев',
          'Не менее трех месяцев',
          'Не менее двух месяцев',
        ],
        correctAnswers: ['Не менее шести месяцев'],
      },
      {
        code: '63683159',
        text:
          'В какой период осуществляется рассмотрение планов развития горных работ?',
        answers: [
          'В период с 15 сентября по 15 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 15 октября по 30 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 августа по 20 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
        correctAnswers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
      },
      {
        code: '63683160',
        text:
          'При какой максимальной температуре вмещающих пород допускается применять камерно-лавную систему для отработки участков россыпей I и II классов устойчивости при отсутствии в толще налегающих пород таликов и при отсутствии на поверхности шахтного поля незамерзающих водотоков и водоемов?',
        answers: ['0 °C', '-2 °C', '-5 °C', '+2 °C', '+5 °C'],
        correctAnswers: ['-2 °C'],
      },
      {
        code: '63683161',
        text:
          'По прошествии какого времени после массового взрыва по разрушению целиков разрешается спуск в шахту представителей профессиональной аварийно-спасательной службы (формирования)?',
        answers: [
          'Через 2 часа',
          'Через 30 минут',
          'Через час',
          'Через 1,5 часа',
        ],
        correctAnswers: ['Через 2 часа'],
      },
      {
        code: '63683162',
        text:
          'К какому виду прогноза динамических явлений относится прогноз по данным, полученным при ведении геолого-разведочных работ, и прогноз по непрерывным сейсмоакустическим наблюдениям?',
        answers: [
          'Локальный прогноз',
          'Региональный прогноз',
          'Прогноз перед вскрытием угольных пластов горными выработками',
          'Текущий прогноз',
          'Прогноз удароопасности и выбросоопасности пород',
        ],
        correctAnswers: ['Региональный прогноз'],
      },
      {
        code: '63683163',
        text:
          'Что должен содержать специальный проект, по которому осуществляются горные работы при комбинированной разработке месторождений полезных ископаемых, наряду с принятыми техническими решениями?',
        answers: [
          'Только обоснование производственной (технической) возможности и технико-экономической целесообразности комбинированной разработки месторождения',
          'Только параметры карьерного и шахтного полей с запасами руды, намечаемые к отработке открытым и подземным способами',
          'Только границы зон влияния подземных разработок (опасных сдвижений, обрушения, воронкообразования)',
          'Только анализ особенностей горно-геологических, горно-технических, гидрогеологических и технологических условий комбинированной разработки месторождения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683164',
        text:
          'Кто выполняет проекты горных и буровых работ в опасных зонах, содержащие мероприятия по предотвращению прорывов воды из затопленных выработок?',
        answers: [
          'Служба профессионального аварийно-спасательного формирования',
          'Геологическая служба шахты',
          'Специализированная проектная организация',
          'Технологическая служба шахты',
          'Маркшейдерская служба шахты',
        ],
        correctAnswers: ['Технологическая служба шахты'],
      },
      {
        code: '63683165',
        text:
          'Какие горные породы относятся к категории "склонные к внезапным выбросам породы и газа"?',
        answers: [
          'Угрожаемые по внезапным выбросам угля и газа угольные пласты, у которых мощность пород основной кровли 10 м и более',
          'Горные породы с пределом прочности на одноосное сжатие 80 МПа или более, залегающие на глубине более 500 м, в породе присутствует кварц и при бурении скважин керн делится на выпукло-вогнутые диски толщиной менее 1/3 диаметра',
          'Угрожаемые по внезапным выбросам угля и газа пласты, на расстоянии до 25 м от которых в почве залегает надрабатываемый газоносный угольный пласт',
          'Горные породы, залегающие на глубине 600 м и более, керн которых, полученный при бурении скважин, делится на диски',
        ],
        correctAnswers: [
          'Горные породы, залегающие на глубине 600 м и более, керн которых, полученный при бурении скважин, делится на диски',
        ],
      },
      {
        code: '63683166',
        text:
          'Что не отображается на планах поверхности при составлении планов и схем развития горных работ?',
        answers: [
          'Объекты наземных и подземных коммуникаций (при наличии)',
          'Места образования провалов, оползней, прорыва плывунов',
          'Объекты застройки площади залегания полезных ископаемых (горного отвода) с выделением зданий и сооружений, подлежащих охране от вредного влияния горных работ',
          'Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов',
          'Устья выходящих на поверхность действующих, законсервированных и ликвидированных горных выработок',
          'Отображается все перечисленное',
        ],
        correctAnswers: [
          'Абсолютные отметки устьев стволов и скважин, околоствольных дворов (руддворов) и горизонтов',
        ],
      },
      {
        code: '63683167',
        text:
          'Какие мероприятия должны осуществляться при одновременной разработке месторождений открытым и подземным способами по обеспечению безопасности работающих одновременно на открытых и подземных горных работах?',
        answers: [
          'Совместные мероприятия, включая: согласование планов и графиков горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов; предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ',
          'Совместные мероприятия, включая только: применение нагнетательной схемы проветривания подземных объектов; предотвращение опасности прорыва воды в подземные выработки из объекта ведения открытых горных работ',
          'Совместные мероприятия, включая только: согласование планов и графиков горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов',
        ],
        correctAnswers: [
          'Совместные мероприятия, включая: согласование планов и графиков горных и взрывных работ; применение нагнетательной схемы проветривания подземных объектов; предотвращение опасности прорыва воды в подземные горные выработки из объекта открытых горных работ',
        ],
      },
      {
        code: '63683168',
        text:
          'Кем определяются места расположения скважин и периодичность их бурения при проведении прогноза динамических явлений в месте вскрытия угольных пластов?',
        answers: [
          'Работниками специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Специалистами службы прогноза динамических явлений',
          'ИТР геологической службы угледобывающей организации',
          'Техническим руководителем (главным инженером) угледобывающей организации',
        ],
        correctAnswers: ['ИТР геологической службы угледобывающей организации'],
      },
      {
        code: '63683169',
        text: 'На основе чего выполняется геодинамическое районирование?',
        answers: [
          'Только геодезических, геофизических и геохимических полевых инструментальных наблюдений',
          'Только анализа геологических, геофизических, геохимических и картографических материалов',
          'Только дешифрирования космических и аэрофотоснимков, морфометрического анализа земной поверхности',
          'Всего перечисленного',
        ],
        correctAnswers: ['Всего перечисленного'],
      },
      {
        code: '63683170',
        text:
          'В какой срок пользователи недр письменно уведомляются органом горного надзора о времени и месте рассмотрения планов и (или) схем развития горных работ?',
        answers: [
          'Не позднее чем за 12 дней до установленной даты их рассмотрения',
          'Не позднее чем за 10 дней до установленной даты их рассмотрения',
          'Не позднее чем за 15 дней до установленной даты их рассмотрения',
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
        ],
        correctAnswers: [
          'Не позднее чем за 20 дней до установленной даты их рассмотрения',
        ],
      },
      {
        code: '63683171',
        text:
          'Кто выполняет классификацию разведочных скважин на затампонированные и незатампонированные в целях установления под ними опасных зон?',
        answers: [
          'Главный маркшейдер',
          'Главный геолог',
          'Технический руководитель (главный инженер) шахты',
          'Начальник участка',
        ],
        correctAnswers: ['Главный геолог'],
      },
      {
        code: '63683172',
        text:
          'Что включают графические материалы к проектам границы опасной зоны у затопленной выработки?',
        answers: [
          'Только геологические разрезы с указанием затопленных выработок, разведочных скважин, разрывных нарушений, литологического состава угленосной толщи, уровня воды в затопленных выработках',
          'Только чертежи по построению границ опасных зон (барьерных и предохранительных целиков)',
          'Только выкопировки из планов горных выработок с нанесением проектируемых границ опасных зон, пунктов съемочной сети, положения геологических разрезов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63683173',
        text: 'С учетом каких факторов определяется высота уступа?',
        answers: [
          'Только с учетом результатов исследований физико-механических свойств горных пород',
          'Только с учетом параметров эксплуатируемого оборудования',
          'Только с учетом горно-геологических условий залегания горных пород',
          'С учетом всех перечисленных параметров',
        ],
        correctAnswers: ['С учетом всех перечисленных параметров'],
      },
      {
        code: '63683174',
        text:
          'Кем подписываются проект горного отвода (пояснительная записка)?',
        answers: [
          'Главным геологом и главным маркшейдером организации, ответственным специалистом-разработчиком проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода',
          'Руководителем организации, испрашивающей горный отвод, и руководителем организации, разработавшей проект горного отвода',
          'Техническим руководителем организации, испрашивающей горный отвод, главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода',
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
        ],
        correctAnswers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
        ],
      },
      {
        code: '63683175',
        text:
          'Какой класс устойчивости массива многолетнемерзлых пород россыпных месторождений не входит в Классификацию, приведенную в приложении 3 к Инструкции по разработке многолетнемерзлых россыпей подземным способом (камерные и столбовые системы разработки) (РД 06-326-99)?',
        answers: [
          'Высокоустойчивые',
          'Устойчивые',
          'Среднеустойчивые',
          'Низкоустойчивые',
          'Весьма неустойчивые',
          'Неустойчивые',
        ],
        correctAnswers: ['Низкоустойчивые'],
      },
      {
        code: '63683176',
        text:
          'Что необходимо соблюдать при погашении уступов и постановке их в предельное положение?',
        answers: [
          'Угол естественного откоса пород, слагающих уступы',
          'Угол внутреннего трения пород, слагающих уступы',
          'Общий угол откоса бортов, установленный проектом',
        ],
        correctAnswers: ['Общий угол откоса бортов, установленный проектом'],
      },
      {
        code: '63683177',
        text:
          'На каких участках возможных деформаций выполняют закладку наблюдательных станций?',
        answers: [
          'Только на участках слабых контактов, поверхностей тектонических нарушений, имеющих наклон в сторону выработанного пространства и простирание близкое к простиранию борта',
          'Только на участках слабых пластичных, обводненных или сильно трещиноватых пород в горном массиве, формирующих борт, уступ или отвал',
          'На всех перечисленных',
        ],
        correctAnswers: ['На всех перечисленных'],
      },
      {
        code: '63683178',
        text:
          'Разрешается ли одновременное производство работ в наклонных выработках на различных отметках?',
        answers: [
          'Запрещается',
          'Разрешается по письменному распоряжению технического руководителя шахты',
          'Разрешается по согласованию с территориальным органом Ростехнадзора',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63683179',
        text:
          'Какой может быть максимальный конечный диаметр опережающих и водоспускных скважин при ожидаемом давлении воды в затопленных выработках менее 0,5 МПа?',
        answers: ['56 мм', '66 мм', '76 мм', '86 мм'],
        correctAnswers: ['76 мм'],
      },
      {
        code: '63683180',
        text:
          'На каком расстоянии от ствола может быть установлена вентиляторная установка?',
        answers: [
          'Не менее 10 метров от ствола',
          'Не менее 12 метров от ствола',
          'Не менее 15 метров от ствола',
        ],
        correctAnswers: ['Не менее 15 метров от ствола'],
      },
      {
        code: '63683181',
        text:
          'Какие установлены требования к проектированию разработки крупных месторождений минеральных солей при размещении на них двух и более рассолопромыслов?',
        answers: [
          'Составляется комплексный проект освоения месторождения, предусматривающий рациональную раскройку месторождения на горные отводы, обеспечивающий наиболее полное извлечение из недр балансовых запасов',
          'Составляются поэтапные проекты по каждому рассолопромыслу с учетом границ горных отводов недропользователей',
          'Составляется технико-экономическое обоснование разработки месторождения, в котором определяется порядок отработки месторождения несколькими недропользователями',
        ],
        correctAnswers: [
          'Составляется комплексный проект освоения месторождения, предусматривающий рациональную раскройку месторождения на горные отводы, обеспечивающий наиболее полное извлечение из недр балансовых запасов',
        ],
      },
      {
        code: '63683182',
        text:
          'Что из перечисленного не входит в мероприятия по разгазированию выработок?',
        answers: [
          'Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей',
          'Обследование выработок после разгазирования перед допуском в них людей',
          'Контроль за разгазированием выработки и за свежей струей воздуха, концентрация газов в месте слияния исходящей и свежей струй воздуха не должна превышать: горючих газов - 1 %, ядовитых газов - ПДК',
          'Снятие напряжения с электрооборудования и электрических сетей и устранение других источников воспламенения в выработках, по которым будет двигаться исходящая струя',
          'Выбор способа и порядка разгазирования',
          'Выставление постов (на свежей струе) и запрещающих знаков в местах возможного подхода людей к выработкам, по которым двигается исходящая струя при разгазировании',
        ],
        correctAnswers: [
          'Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей',
        ],
      },
      {
        code: '63683183',
        text:
          'Какие наблюдения должны проводиться маркшейдерской службой шахты в период подготовки блока к выщелачиванию?',
        answers: [
          'Систематические наблюдения за фактическим контуром зоны очистной выемки',
          'Систематические наблюдения за необоснованной застройкой площадей под проектным контуром выщелачивания',
          'Систематические наблюдения за сдвижением и деформацией пород',
          'Систематические наблюдения за обнаружением пустот с целью последующей их ликвидации',
        ],
        correctAnswers: [
          'Систематические наблюдения за сдвижением и деформацией пород',
        ],
      },
      {
        code: '63683184',
        text:
          'Какой величины допускается высота уступа и его угол откоса при разработке соляного пласта?',
        answers: [
          'Высота не должна превышать 8 м, а угол откоса не менее 75°',
          'Высота не должна превышать 8 м, а угол откоса не менее 65°',
          'Высота не должна превышать 10 м, а угол откоса не менее 70°',
          'Высота не должна превышать 12 м, а угол откоса не менее 65°',
        ],
        correctAnswers: [
          'Высота не должна превышать 8 м, а угол откоса не менее 75°',
        ],
      },
      {
        code: '63683185',
        text:
          'Под каким давлением производится проверка на прочность и герметичность заделки направляющей трубы после схватывания цемента и разбуривания цементной пробки путем закачки воды в скважину?',
        answers: [
          'Под давлением, превышающим в 1,5 раза давление воды в затопленных выработках',
          'Под давлением, превышающим в 2 раза давление воды в затопленных выработках',
          'Под давлением более 0,5 МПа (5 кгс/см²)',
          'Под давлением более 2 МПа (20 кгс/см²)',
        ],
        correctAnswers: [
          'Под давлением, превышающим в 1,5 раза давление воды в затопленных выработках',
        ],
      },
      {
        code: '63683186',
        text:
          'В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?',
        answers: [
          'Расположенных на любом удалении от плоскости сместителя тектонического нарушения',
          'Расположенных на удалении 15 - 20 м от плоскости сместителя тектонического нарушения',
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
          'Данный комплекс мер допускается не производить на участках рудного массива или массива горных пород',
        ],
        correctAnswers: [
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
        ],
      },
      {
        code: '63683187',
        text:
          'Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?',
        answers: [
          'Только геофизическими методами',
          'Только геомеханическими методами',
          'Только визуальными наблюдениями за разрушением приконтурного массива выработок',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63683188',
        text:
          'В какой раздел плана (схемы) развития горных работ включаются сведения о соблюдении порядка ликвидации (консервации) горных выработок (скважин), участков, блоков, горизонтов на отработанных частях месторождений?',
        answers: [
          'План поверхности',
          'Погоризонтный маркшейдерский план горных работ',
          'Пояснительная записка планов (схем)',
          'Сводный маркшейдерский план горных работ',
        ],
        correctAnswers: ['Пояснительная записка планов (схем)'],
      },
      {
        code: '63683189',
        text:
          'Какова допустимая высота падения груза из ковша экскаватора в кузов автосамосвала?',
        answers: [
          'Высота падения груза не должна превышать 5 м',
          'Высота падения груза не должна превышать 4 м',
          'Высота падения груза определяется паспортом в зависимости от грузоподъемности автосамосвала',
          'Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м',
        ],
        correctAnswers: [
          'Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м',
        ],
      },
      {
        code: '63683190',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'С территориальными органами Ростехнадзора',
          'С территориальными органами управления природных ресурсов и охраны окружающей среды',
          'Со специализированными профессиональными аварийно-спасательными формированиями',
          'С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63683191',
        text:
          'При каких условиях допускается применение камерно-целиковой системы разработки?',
        answers: [
          'При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 2 °С и выше',
          'При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 5 °С и выше',
          'При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 3 °С и мощности вынимаемого пласта не более 2 м',
          'При любых условиях при разработке многолетнемерзлых россыпей подземным способом',
        ],
        correctAnswers: [
          'При отработке участков россыпей I и II классов устойчивости с температурой вмещающего горного массива минус 2 °С и выше',
        ],
      },
      {
        code: '63683192',
        text: 'Когда на руднике допускается проходка выработок без крепления?',
        answers: [
          'Допускается проходка вспомогательных горных выработок, когда срок их службы не превышает 6 месяцев',
          'Допускается проходка вспомогательных горных выработок протяженностью не более 25 м',
          'Допускается проходка горных выработок только в устойчивых породах',
          'Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния',
        ],
        correctAnswers: [
          'Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния',
        ],
      },
      {
        code: '63683193',
        text:
          'Какое время отводится на отработку запасов камер с использованием самоходного оборудования с выемкой пласта песков на полную мощность (вынимаемая мощность не более 3 м)?',
        answers: [
          'Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 30 суток',
          'Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 35 суток',
          'Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 20 суток',
          'Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 25 суток',
        ],
        correctAnswers: [
          'Полное время отработки запасов камер, включая зачистку почвы при актировке, не должно превышать 20 суток',
        ],
      },
      {
        code: '63683194',
        text:
          'Какую минимальную ширину ленточных междукамерных целиков следует принимать независимо от вынимаемой мощности пласта песков?',
        answers: ['1,5 м', '1,6 м', '1,7 м', '1,8 м', '2,0 м'],
        correctAnswers: ['1,7 м'],
      },
      {
        code: '63683195',
        text:
          'Какое утверждение, относящееся к порядку проведения локального прогноза удароопасности угольных пластов, указано неверно?',
        answers: [
          'Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя три года после окончания их проведения с периодичностью не реже одного раза в три года',
          'На удароопасных угольных пластах локальный прогноз удароопасности проводится на участках горных выработок, подлежащих погашению',
          'На удароопасных угольных пластах локальный прогноз удароопасности проводится до начала ведения горных работ на участке горной выработки, подлежащем перекреплению',
          'Локальный прогноз в поддерживаемых выработках проводится на сопряжениях поддерживаемых горных выработок в каждый борт горной выработки на расстоянии не более 10 м от сопряжения',
        ],
        correctAnswers: [
          'Локальный прогноз удароопасности в поддерживаемых горных выработках, пройденных по угрожаемым и опасным по горным ударам угольным пластам, проводится спустя три года после окончания их проведения с периодичностью не реже одного раза в три года',
        ],
      },
      {
        code: '63683196',
        text:
          'В состав какой документации должен входить горноотводный акт и графические приложения к нему?',
        answers: [
          'Документации на техническое перевооружение, консервацию и ликвидацию',
          'Горноотводной документации',
          'Документации к обоснованию безопасности',
          'Проектной документации',
        ],
        correctAnswers: ['Горноотводной документации'],
      },
      {
        code: '63683197',
        text:
          'Какие горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры, границы поверхностного контура зоны обрушения должны быть нанесены на маркшейдерские планы и разрезы карьера?',
        answers: [
          'Только основные горные выработки, границы открытого очистного пространства на подземных работах, отработанные камеры, границы поверхностного контура зоны обрушения',
          'Перечень подземных объектов, которые должны быть нанесены на маркшейдерские планы и разрезы карьера, устанавливается организацией, ведущей комбинированную разработку месторождения по согласованию с органами Ростехнадзора',
          'Перечень подземных объектов, которые должны быть нанесены на маркшейдерские планы и разрезы карьера, устанавливается главным маркшейдером по согласованию с главным инженером организации, ведущей комбинированную разработку месторождения',
          'Должны быть нанесены все без исключения',
        ],
        correctAnswers: ['Должны быть нанесены все без исключения'],
      },
      {
        code: '63683198',
        text: 'Из чего должна состоять типовая наблюдательная станция?',
        answers: [
          'Не менее чем из двух профильных линий вкрест простирания и одной по простиранию пласта',
          'Из одной профильной линии простирания пласта',
          'Не менее чем из двух профильных линий вкрест простирания и двух по простиранию пласта',
          'Не менее чем из четырех профильных линий вкрест простирания и двух по простиранию пласта',
        ],
        correctAnswers: [
          'Не менее чем из двух профильных линий вкрест простирания и одной по простиранию пласта',
        ],
      },
      {
        code: '63683199',
        text:
          'Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?',
        answers: [
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
          'Лица, ответственные за руководство геологическими работами, и представитель Ростехнадзора',
          'Только лица, ответственные за руководство маркшейдерскими работами',
          'Только лица, ответственные за руководство горными работами пользователя недр',
        ],
        correctAnswers: [
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63683200',
        text:
          'Какие требования к камерной системе разработки с применением самоходного оборудования указаны неверно?',
        answers: [
          'При смещении осей междукамерных целиков в смежных камерах более 3 м максимальное опережение забоев принимать равным пролету камеры',
          'Проходку просечек для оформления целиков в опережающей камере производить до завершения отработки запасов отстающей смежной камеры',
          'При соосном расположении междукамерных целиков зачистку почвы под актировку производить одновременно в обеих камерах после завершения отбойки в них песков',
          'При соосном расположении междукамерных целиков в смежных камерах до завершения зачистки почвы под актировку в обеих камерах допускаются работы только по созданию необходимого опережения вновь образуемых очистных забоев с подвиганием забоя опережающей камеры не более чем на 6 м',
        ],
        correctAnswers: [
          'При смещении осей междукамерных целиков в смежных камерах более 3 м максимальное опережение забоев принимать равным пролету камеры',
        ],
      },
      {
        code: '63683201',
        text:
          'За чем должны проводиться визуальные и инструментальные наблюдения в процессе эксплуатации объектов ведения открытых горных работ для своевременного выявления опасных зон и прогнозирования опасных ситуаций?',
        answers: [
          'За устойчивостью бортов, уступов, откосов',
          'За деформацией просадочности (для глинистых пород)',
          'За проявлениями удароопасности горных пород, вмещающих полезное ископаемое',
          'За закарстованностью месторождения',
        ],
        correctAnswers: ['За устойчивостью бортов, уступов, откосов'],
      },
      {
        code: '63683202',
        text:
          'Через какое минимальное время после предыдущей подработки разрешается повторная подработка затопленной выработки?',
        answers: [
          '3 месяца',
          '6 месяцев',
          '1 месяц',
          '5 месяцев',
          '12 месяцев',
        ],
        correctAnswers: ['6 месяцев'],
      },
      {
        code: '63683203',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?',
        answers: [
          'Отсутствие обоснования соблюдения условий безопасного недропользования',
          'Отсутствие геологического и маркшейдерского обеспечения горных работ',
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
          'Несоответствие планов и схем развития горных работ требованиям, установленным органом государственного горного надзора, к их подготовке, содержанию и оформлению',
        ],
        correctAnswers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63683204',
        text:
          'При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?',
        answers: [
          'Только крепления выработок, предотвращающего обрушение пород кровли и боков',
          'Только создания устойчивых форм',
          'Только бурения разгрузочных щелей и скважин',
          'Только контурного взрывания',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63683205',
        text: 'Какие из событий предшествуют горному удару?',
        answers: [
          'Только повышенное горное давление на крепь горной выработки',
          'Только повышенный выход буровой мелочи и зажатие бурового инструмента',
          'Только удары, трески, толчки в массиве горных пород различной силы и частоты',
          'Только стреляние отслоившихся кусков угля (породы)',
          'Все перечисленные события',
        ],
        correctAnswers: ['Все перечисленные события'],
      },
      {
        code: '63683206',
        text:
          'Чьи подписи ставятся на титульном листе пояснительной записки планов (схем) развития горных работ?',
        answers: [
          'Только лица, ответственного за руководство горными работами',
          'Только лица, ответственного за руководство маркшейдерскими работами',
          'Только пользователя недр и (или) привлекаемой пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
          'Только лица, ответственного за руководство геологическими работами',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63683207',
        text:
          'Какие мероприятия должна обеспечить организация, эксплуатирующая объект ведения открытых горных работ?',
        answers: [
          'Только контроль за соблюдением проектных параметров бортов и уступов карьера, разреза и откосов отвала',
          'Только изучение геологических, инженерно-геологических, гидрогеологических условий месторождения, структуры и условий залегания породных слоев, массива горных пород и пород основания отвалов',
          'Только сохранность объектов, расположенных на земной поверхности в границах горных отводов и на прилегающих к ним территориях',
          'Только выявление зон и участков возможного проявления разрушающих деформаций бортов, уступов и откосов',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63683208',
        text:
          'Каким должен быть минимальный диаметр наблюдательной скважины, необходимый для пропуска уровнемера или желонки?',
        answers: ['120 мм', '110 мм', '50 мм', '30 мм'],
        correctAnswers: ['50 мм'],
      },
      {
        code: '63683209',
        text:
          'В соответствии с чем должен проводиться мониторинг массива горных пород геофизическими методами?',
        answers: [
          'В соответствии с Инструкцией по прогнозу динамических явлений и мониторингу массива горных пород при отработке угольных месторождений',
          'В соответствии с распорядительным документом руководителя угледобывающей организации',
          'В соответствии с проектной документацией, утвержденной главным инженером',
          'В соответствии с Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых',
        ],
        correctAnswers: [
          'В соответствии с проектной документацией, утвержденной главным инженером',
        ],
      },
      {
        code: '63683210',
        text:
          'В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?',
        answers: [
          'В соответствии с требованиями Правил безопасности при ведении горных работ и переработке твердых полезных ископаемых',
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'Если данные отступления определяются указаниями по безопасному ведению горных работ на месторождении, склонном и опасном по горным ударам',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: [
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63683211',
        text:
          'На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'На основе специальных исследований и заключения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'На основе заключения представителя территориального органа Ростехнадзора',
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'На основе заключения технического руководителя объекта',
        ],
        correctAnswers: [
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63683212',
        text:
          'Длина профильной линии вкрест простирания станции наблюдения определяется по формуле.    Какой угол (α) используется при косинусе?',
        answers: [
          'Угол наклона земной поверхности над рассолопромыслом',
          'Угол, под которым отклонена скважина от вертикали',
          'Азимут профильной линии',
          'Угол падения пласта',
        ],
        correctAnswers: ['Угол падения пласта'],
      },
      {
        code: '63683213',
        text:
          'Кому должен сообщать работник при обнаружении опасности, угрожающей людям, производственным объектам?',
        answers: [
          'Главному маркшейдеру шахты',
          'Техническому руководителю смены',
          'Руководителю шахты',
          'Главному геологу шахты',
        ],
        correctAnswers: ['Техническому руководителю смены'],
      },
      {
        code: '63683214',
        text:
          'Выберите правильный ответ из предложенных вариантов, в соответствии с которым сбой в работе горнорудного предприятия должен быть отнесен к инциденту.',
        answers: [
          'Разрушение сооружений (технических устройств), приведшее к приостановке работ объекта (участка) на срок более суток',
          'Разрушение крепи и армировки, обрушения горной массы в главных откаточных и вентиляционных выработках, приведшее к приостановке работ на срок более суток',
          'Локальные возгорания, не приведшие к выводу людей из горных выработок на поверхность, вызвавшие приостановку работ на срок менее суток',
          'Подвижки бортов карьеров, вызванные техногенными факторами и нарушениями принятой технологии работ и приведшие к приостановке работ на срок более суток',
        ],
        correctAnswers: [
          'Локальные возгорания, не приведшие к выводу людей из горных выработок на поверхность, вызвавшие приостановку работ на срок менее суток',
        ],
      },
      {
        code: '63683215',
        text:
          'Какая из перечисленных характеристик соответствует мелкокусковатому типу нарушенности угля?',
        answers: [
          'Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом',
          'Слоистость и трещиноватость затушеваны. Основная масса состоит из мелких зерен угля, между которыми располагается землистый уголь (угольная мука). Достаточно спрессован, между пальцами раздавливается с трудом. Уголь слабоустойчив и склонен к осыпанию',
          'Слоистость и трещиноватость затушеваны. Состоит из тонкоперетертого угля (угольная мука). Неустойчив, осыпается интенсивно. Между пальцами раздавливается легко',
          'Слоистость и трещиноватость затушеваны. Уголь слагается из отдельных линз, поверхность их зеркально отполирована и несет на себе борозды и штрихи скольжения. При механическом воздействии превращается иногда в угольную мелочь',
          'Заметно выраженная слоистость, уголь в массиве монолитный, устойчивый, не осыпается. Разламывается на куски, ограниченные слоистостью и трещинами',
        ],
        correctAnswers: [
          'Слоистость и трещиноватость, как правило, затушеваны. Массив состоит из угловатых кусков угля разнообразной формы. Между кусками может встречаться угольная мелочь, зернистый и даже землистый уголь. Уголь слабоустойчив к механическому воздействию, но осыпается с трудом',
        ],
      },
      {
        code: '63683216',
        text:
          'При каких условиях недропользователю устанавливаются уточненные границы горного отвода?',
        answers: [
          'В ходе ликвидации, консервации скважин и оборудования их устьев и стволов',
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации',
          'Одновременно с получением лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений',
          'В процессе разработки месторождений нефти или газа по требованию органа, выдавшего лицензию на пользование недрами',
        ],
        correctAnswers: [
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации',
        ],
      },
      {
        code: '63683217',
        text: 'Что запрещено при комбинированной разработке месторождений?',
        answers: [
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
          'Применение систем разработки, исключающих сдвижение (разрушение) массива предохранительного целика',
          'Исключение прорыва ливневых и подземных вод из карьера в подземные выработки',
          'Оставление предохранительного целика, обеспечивающего устойчивость массива и бортов карьера',
        ],
        correctAnswers: [
          'Несвоевременная отработка и разрушение рудных целиков и потолочин в руднике от массовых взрывов',
        ],
      },
      {
        code: '63683218',
        text: 'В каком случае разрешается частичное извлечение стоечной крепи?',
        answers: [
          'Только после полной отработки',
          'Только после зачистки почвы камеры',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63683219',
        text:
          'Кто разрабатывает паспорта крепления горных выработок, технологию и очередность ведения горных работ в опасной зоне и вблизи ее, меры безопасности работ при составлении проектов ведения горных работ в опасных зонах?',
        answers: [
          'Энергомеханическая служба',
          'Служба вентиляции и техники безопасности (ВТБ)',
          'Технологическая служба шахты',
          'Геологическая служба шахты',
          'Маркшейдерская служба шахты',
          'Специализированная проектная организация',
        ],
        correctAnswers: ['Технологическая служба шахты'],
      },
      {
        code: '63683220',
        text:
          'В каком случае нарушены требования безопасности при проходке стволов?',
        answers: [
          'При проходке стволов допускается отставание постоянной крепи от забоя не более диаметра ствола вчерне',
          'Проходка выработок непосредственно от ствола должна осуществляться после возведения временной или постоянной крепи в стволе',
          'Образующийся при сбойке целик должен быть приведен при категории "Опасно" в неопасное состояние по всей площади',
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями',
        ],
        correctAnswers: [
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями',
        ],
      },
      {
        code: '63683221',
        text:
          'Когда производятся закладка реперов и начальные наблюдения на них и на наблюдательных станциях за сдвижением земной поверхности на рассолопромыслах?',
        answers: [
          'После эксплуатации рассолодобывающих камер',
          'Во время эксплуатации рассолодобывающих камер',
          'До начала эксплуатации рассолодобывающих камер',
          'После отработки месторождения',
        ],
        correctAnswers: ['До начала эксплуатации рассолодобывающих камер'],
      },
      {
        code: '63683222',
        text:
          'Какой порядок одновременного ведения очистных работ на смежных этажах предусмотрен "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 5 м',
          'При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом',
          'При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на безопасное расстояние, определенное техническим регламентом',
        ],
        correctAnswers: [
          'При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом',
        ],
      },
      {
        code: '63683223',
        text:
          'Что из перечисленного необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на месторождениях, склонных и опасных по горным ударам?',
        answers: [
          'Только сокращение применения систем разработки с открытым очистным пространством',
          'Только разделение месторождения на шахтные поля и порядок их отработки, обеспечивающие планомерное извлечение запасов по возможности без образования целиков, острых углов и выступов фронта очистных работ',
          'Только ограничение встречных и догоняющих фронтов очистных работ',
          'Только снижение количества горных выработок, проводимых вблизи фронта очистных работ, особенно в зонах опорного давления',
          'Только использование опережающей отработки защитных залежей (слоев), в том числе бурение разгрузочных скважин большого диаметра, расположение выработок в защищенных зонах',
          'Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород',
        ],
        correctAnswers: [
          'Все перечисленное, включая преимущественное проведение горных выработок вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород',
        ],
      },
      {
        code: '63683224',
        text:
          'Какие два дополнительных основных требования должны выполняться при отработке сближенных калийно-магниевых и соляных пластов?',
        answers: [
          'Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 45 м',
          'Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м',
          'Соосное расположение очистных камер с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м',
          'Соосное расположение очистных камер с опережением фронта очистных работ по верхнему пласту не менее чем на 45 м',
        ],
        correctAnswers: [
          'Соосное расположение очистных камер с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м',
        ],
      },
      {
        code: '63683225',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
          'Техническим руководителем пользователя недр (подрядной организации)',
          'Комиссией пользователя недр (подрядной организации)',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63683226',
        text:
          'При проектировании, строительстве и эксплуатации горнодобывающих предприятий с комбинированной (совмещенной) разработкой месторождения должен учитываться ряд основных факторов, определяющих специфические условия и представляющих опасность при ведении горных работ. Относится ли к основным факторам вид полезного ископаемого разрабатываемого месторождения?',
        answers: [
          'Относится',
          'Не относится',
          'Относится, если имеется наличие аэродинамических связей между открытыми и подземными горными работами при комбинированной разработке месторождения',
          'Не относится, если отсутствует склонность месторождения или массива горных пород или их части к горным ударам',
        ],
        correctAnswers: ['Не относится'],
      },
      {
        code: '63683227',
        text: 'Где запрещается размещение отвалов?',
        answers: [
          'Над отработанными подземным способом участками месторождения полезных ископаемых',
          'На косогорах',
          'На площадях месторождений, подлежащих отработке открытым способом',
        ],
        correctAnswers: [
          'На площадях месторождений, подлежащих отработке открытым способом',
        ],
      },
      {
        code: '63683228',
        text:
          'На основании какого документа могут быть возобновлены открытые горные работы после их прекращения при обнаружении признаков сдвижения пород?',
        answers: [
          'Специального проекта организации работ, утвержденного руководителем предприятия',
          'Технического регламента',
          'Локального проекта организации работ, утвержденного командиром специализированного профессионального аварийно-спасательного формирования',
          'Распоряжения территориального органа государственного горного надзора',
        ],
        correctAnswers: [
          'Специального проекта организации работ, утвержденного руководителем предприятия',
        ],
      },
      {
        code: '63683229',
        text:
          'Каким федеральным органом исполнительной власти оформляется горноотводная документация?',
        answers: [
          'Федеральным агентством по недропользованию',
          'Федеральным агентством в сфере природопользования',
          'Территориальным органом управления природных ресурсов и охраны окружающей среды',
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: [
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '63683230',
        text:
          'В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?',
        answers: [
          'До 1 декабря текущего года',
          'До 1 сентября текущего года',
          'До 20 декабря текущего года',
          'До 20 сентября текущего года',
        ],
        correctAnswers: ['До 1 сентября текущего года'],
      },
      {
        code: '63683231',
        text:
          'Кто утверждает заключение о результатах очередного цикла буровых работ в шахте?',
        answers: [
          'Руководитель территориального органа Ростехнадзора',
          'Технический руководитель шахты',
          'Главный геолог шахты',
          'Главный маркшейдер шахты',
        ],
        correctAnswers: ['Технический руководитель шахты'],
      },
      {
        code: '63683232',
        text:
          'Какой должна приниматься ширина ленточного междукамерного целика в случае применения камерной системы разработки с внутрипанельным опорным целиком?',
        answers: [
          'Не менее 1 м независимо от вынимаемой мощности пласта песков',
          'Не менее 1,3 м независимо от вынимаемой мощности пласта песков',
          'Не менее 1,7 м независимо от вынимаемой мощности пласта песков',
          'Не менее 1,5 м независимо от вынимаемой мощности пласта песков',
        ],
        correctAnswers: [
          'Не менее 1,7 м независимо от вынимаемой мощности пласта песков',
        ],
      },
      {
        code: '63683233',
        text:
          'Каким образом участки горных выработок категории "Опасно" приводят в неудароопасное состояние?',
        answers: [
          'Путем создания в краевой части массива пород защитной зоны шириной не более 1 м',
          'Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м',
          'Путем создания в краевой части массива пород защитной зоны шириной не менее 1 м',
          'Путем создания в краевой части массива пород защитной зоны шириной n+1 м, но не более 2 м',
        ],
        correctAnswers: [
          'Путем создания в краевой части массива пород защитной зоны шириной n+2 м, но не менее 2 м',
        ],
      },
      {
        code: '63683234',
        text:
          'Кто является ответственным за выполнение всех работ в зоне, опасной по прорывам воды, и по обеспечению их безопасности в шахтах?',
        answers: [
          'Главный геолог шахты',
          'Главный механик шахты',
          'Главный маркшейдер шахты',
          'Технический руководитель шахты',
        ],
        correctAnswers: ['Технический руководитель шахты'],
      },
      {
        code: '63683235',
        text:
          'Кем и с какой периодичностью должно проводиться визуальное обследование состояния откосов на карьерах?',
        answers: [
          'Главным инженером карьера не реже одного раза в полугодие',
          'Комиссией в составе главного инженера карьера и главного геолога карьера один раз в полугодие',
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
          'Участковым маркшейдером или геологом ежеквартально',
        ],
        correctAnswers: [
          'Специалистами геологической и маркшейдерской служб не реже одного раза в месяц',
        ],
      },
    ],
    63681: [
      {
        code: '63681000',
        text:
          'Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?',
        answers: [
          '3 экземпляра',
          '2 экземпляра',
          '5 экземпляров',
          '4 экземпляра',
        ],
        correctAnswers: ['3 экземпляра'],
      },
      {
        code: '63681001',
        text:
          'Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования для нефтяных скважин с газовым фактором менее 200 м³/т?',
        answers: [
          'Не менее 15 м',
          'Не менее 30 м',
          'Не менее 10 м',
          'не менее 25 м',
        ],
        correctAnswers: ['Не менее 30 м'],
      },
      {
        code: '63681002',
        text:
          'Какие участки недр относятся к участкам недр федерального значения?',
        answers: [
          'Только участки, расположенные на территории субъекта Российской Федерации и содержащие месторождения с запасами меди от 500 тыс. т',
          'Только участки, содержащие коренные (рудные) месторождения лития или металлов платиновой группы',
          'Только участки внутренних морских вод, континентального шельфа Российской Федерации',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63681003',
        text:
          'Какая устанавливается максимальная суммарная загрузка здания, в котором производятся или подготавливаются взрывчатые вещества, с учетом взрывчатых веществ, находящихся в вагоне, смесительно-зарядной машине или другом транспортном средстве и накопительных емкостях?',
        answers: ['50 т', '60 т', '30 т', '40 т'],
        correctAnswers: ['60 т'],
      },
      {
        code: '63681004',
        text:
          'Допускается ли проведение горных работ на участках категории «опасно» на склонных к динамическим явлениям пластах?',
        answers: [
          'Допускается при наличии разрешающего документа Федеральной службы по надзору в сфере природопользования',
          'Допускается при одновременном проведении горных работ по добыче и работ по предотвращению динамических явлений',
          'Запрещается, за исключением работ, проводимых для приведения горного массива в неопасное состояние',
          'Запрещается любой вид работ',
        ],
        correctAnswers: [
          'Запрещается, за исключением работ, проводимых для приведения горного массива в неопасное состояние',
        ],
      },
      {
        code: '63681005',
        text:
          'При какой температуре запрещается заряжание и взрывание зарядов в шпурах при ведении взрывных работ по металлу?',
        answers: [
          'Свыше 150 °C',
          'Свыше 200 °C',
          'Свыше 100 °C',
          'Свыше 80 °C',
        ],
        correctAnswers: ['Свыше 200 °C'],
      },
      {
        code: '63681006',
        text: 'Недра могут предоставляться в пользование для:',
        answers: [
          'Только для геологического изучения, включающего поиски и оценку месторождений полезных ископаемых',
          'Только для разведки и добычи углеводородного сырья для размещения в пластах горных пород попутных вод',
          'Только для сбора минералогических, палеонтологических и других геологических коллекционных материалов',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681007',
        text:
          'Что необходимо соблюдать при проектировании вскрытия, подготовки, систем разработки на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Только преимущественно проводить горные выработки преимущественно вне зоны влияния тектонических нарушений и по направлению наибольшего напряжения в массиве горных пород',
          'Обеспечивать по возможности планомерное извлечение запасов без образования целиков, острых углов и выступов фронта очистных работ',
          'Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия',
          'Снижать количество горных выработок, проводимых вблизи фронта очистных работ (в зонах опорного давления)',
        ],
        correctAnswers: [
          'Только преимущественно проводить горные выработки по направлению наибольшего напряжения в массиве горных пород, соблюдая все перечисленные условия',
        ],
      },
      {
        code: '63681008',
        text:
          'Какие требования установлены "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых" для безопасного выполнения работ по ручной оборке откосов уступов?',
        answers: [
          'Ручная оборка должна осуществляться только в дневное рабочее время под наблюдением технического руководителя горнодобывающей организации',
          'Ручная оборка должна выполняться бригадой в составе не менее 3 рабочих',
          'Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора',
        ],
        correctAnswers: [
          'Ручная оборка должна осуществляться по наряду-допуску под наблюдением лиц технического надзора',
        ],
      },
      {
        code: '63681009',
        text: 'Участки недр предоставляются в пользование:',
        answers: [
          'На определенный срок',
          'Без ограничения срока пользования',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681010',
        text:
          'Кто должен осмотреть состояние кровли и стенок выработки и принять меры по приведению их в безопасное состояние перед началом работы по механизированному заряжанию шпуров, скважин или камер? Укажите все правильные ответы.',
        answers: [
          'Руководитель взрывных работ',
          'Взрывник',
          'Горный мастер',
          'Инженер-геотехник',
        ],
        correctAnswers: ['Руководитель взрывных работ', 'Взрывник'],
      },
      {
        code: '63681011',
        text:
          'На какой основе осуществляется изменение параметров противоударных мероприятий и периодичности прогноза степени удароопасности, предусмотренных Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых?',
        answers: [
          'На основе регламента, регулирующего деятельность по горным отводам при добыче полезных ископаемых',
          'На основе заключения представителя территориального управления Росприроднадзора',
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'На основе произведенного главным инженером маркшейдерской службы анализа горнотехнических условий',
        ],
        correctAnswers: [
          'На основе специальных исследований и заключения специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63681012',
        text:
          'В каком из перечисленных документов должны быть определены границы участков, опасных по прорыву воды (опасные зоны)?',
        answers: [
          'В топографических планах (картах)',
          'В геологических и структурных картах',
          'В техническом проекте и (или) документации по ведению горных работ на участках',
          'В планах и (или) схемах развития горных работ',
        ],
        correctAnswers: [
          'В техническом проекте и (или) документации по ведению горных работ на участках',
        ],
      },
      {
        code: '63681013',
        text:
          'Какой из видов деятельности не относится к видам деятельности в области промышленной безопасности согласно требованиям Федерального закона "О промышленной безопасности опасных производственных объектов"?',
        answers: [
          'Строительство опасного производственного объекта',
          'Текущий ремонт зданий и сооружений',
          'Ликвидация опасного производственного объекта',
          'Проектирование опасного производственного объекта',
        ],
        correctAnswers: ['Текущий ремонт зданий и сооружений'],
      },
      {
        code: '63681014',
        text:
          'Какова допустимая высота падения груза из ковша экскаватора в кузов автосамосвала?',
        answers: [
          'Не должна превышать 5 м',
          'Высота падения груза определяется паспортом автосамосвала и зависит от его грузоподъемности',
          'Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м',
          'Должна соответствовать 3,5 м',
        ],
        correctAnswers: [
          'Высота падения груза должна быть минимально возможной и во всех случаях не должна превышать 3 м',
        ],
      },
      {
        code: '63681015',
        text:
          'В каком случае должно обеспечиваться полное отключение оборудования и механизмов в закрытых помещениях буровой установки, где возможны возникновение или проникновение воспламеняющихся смесей?',
        answers: [
          'При достижении 40 % от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 30 % от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 20 % от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
        correctAnswers: [
          'При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
      },
      {
        code: '63681016',
        text:
          'В каком случае допускается эксплуатация стрелочных переводов рельсовых путей?',
        answers: [
          'При сбитых и изогнутых в продольном и поперечном направлениях остряках (перьях)',
          'При закрытых канавках для тяг приводов стрелочных переводов',
          'При отсутствии фиксации положения стрелочных переводов с помощью фиксаторов',
          'При разъединенных стрелочных тягах',
        ],
        correctAnswers: [
          'При закрытых канавках для тяг приводов стрелочных переводов',
        ],
      },
      {
        code: '63681017',
        text:
          'При какой предельно допустимой концентрации содержания диоксида углерода в воздухе закрытого помещения работы в нем должны быть прекращены?',
        answers: [
          '0,4 (объемных) %',
          '0,6 (объемных) %',
          '0,3 (объемных) %',
          '0,2 (объемных) %',
          '0,5 (объемных) %',
        ],
        correctAnswers: ['0,5 (объемных) %'],
      },
      {
        code: '63681018',
        text:
          'В каком случае нарушены требования безопасности при проходке стволов?',
        answers: [
          'При проходке шахтных стволов разработка забоя произведена заходками на глубину менее 1 м',
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями',
          'Нормативные скорости проходки изменены в зависимости от формы и размеров выработки',
          'При проходке шахтных стволов применен способ опускной крепи',
        ],
        correctAnswers: [
          'Сбойка двух выработок на участках, склонных и опасных по горным ударам, с расстояния между забоями 20 м должна вестись двумя забоями',
        ],
      },
      {
        code: '63681019',
        text:
          'Какое из перечисленных требований к отработке пластов угля, склонных к самовозгоранию, указано неверно?',
        answers: [
          'Места и размеры целиков угля должны быть определены техническим проектом и (или) проектной документацией',
          'Отработку крутых и крутонаклонных пластов угля, склонных к самовозгоранию, ведут отдельными выемочными блоками с оставлением между ними противопожарных целиков, прорезаемых только на уровне откаточного и вентиляционного горизонтов',
          'При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь',
          'Размер целика по простиранию равен мощности пласта, но не менее 6 м',
        ],
        correctAnswers: [
          'При отработке пластов угля, склонных к самовозгоранию, оставляют в выработанном пространстве измельченный уголь',
        ],
      },
      {
        code: '63681020',
        text:
          'От чего зависит надежность поддержания горных выработок в нижнем слое?',
        answers: [
          'От трещиноватости угля',
          'От мощности межслоевой угольной пачки, оставляемой между штреками верхнего и нижнего слоя',
          'От прочности угля',
          'От всего перечисленного',
        ],
        correctAnswers: ['От всего перечисленного'],
      },
      {
        code: '63681021',
        text:
          'Какие виды работ и услуг не включает в себя лицензируемая деятельность по производству маркшейдерских работ?',
        answers: [
          'Проведение горно-капитальных вскрышных работ',
          'Создание маркшейдерских опорных и съемочных сетей',
          'Учет и обоснование объемов горных разработок',
          'Cоздание маркшейдерских сетей для наблюдения за сдвижением земной поверхности',
          'Обоснование границ горных отводов',
        ],
        correctAnswers: ['Проведение горно-капитальных вскрышных работ'],
      },
      {
        code: '63681022',
        text:
          'В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?',
        answers: [
          'Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)',
          'Только в виде схем эксплуатации объектов недропользования и их отдельных участков',
          'Только в виде схем эксплуатации объектов и водозаборных сооружений в отношении месторождений минеральных, теплоэнергетических (термальных), технических, промышленных вод',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63681023',
        text:
          'Какие из перечисленных работ на карьере должны вестись в соответствии с утвержденными техническим руководителем объекта локальными проектами (паспортами) и проектом производства работ с учетом инженерно-геологических условий?',
        answers: [
          'Исследования по определению возраста геологических образований',
          'Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов',
          'Топографо-геодезические работы',
          'Дренажные работы на заболоченных участках',
        ],
        correctAnswers: [
          'Горные работы по проведению траншей, разработке уступов, дражных полигонов и отсыпке отвалов',
        ],
      },
      {
        code: '63681024',
        text:
          'Какое действие необходимо предпринять, если при проверке линии наименьшего сопротивления выявится возможность опасного разлета кусков горной массы или воздействия ударной воздушной волны при ликвидации отказавших камерных зарядов?',
        answers: [
          'Разборку забойки с последующим извлечением взрывчатых веществ',
          'Провести ликвидацию по специально разработанным проектам, утвержденным руководителем (техническим руководителем) организации, ведущей взрывные работы, или назначенным им лицом',
          'Разборку забойки с последующим вводом нового боевика и взрывание в обычном порядке',
          'Взрывание дополнительно опущенного накладного заряда',
        ],
        correctAnswers: [
          'Разборку забойки с последующим извлечением взрывчатых веществ',
        ],
      },
      {
        code: '63681025',
        text:
          'Какие участки недр не относятся к участкам недр местного значения?',
        answers: [
          'Участки недр, содержащие подземные воды, используемые для целей питьевого, хозяйственно-бытового водоснабжения, технического водоснабжения садоводческих и огороднических некоммерческих товариществ',
          'Участки недр, содержащие подземные воды, которые используются для целей технологического обеспечения водой объектов промышленности и объем добычи которых составляет более 600 м³ в сутки',
          'Участки недр, используемые для строительства и эксплуатации подземных сооружений местного и регионального значения, не связанных с добычей полезных ископаемых',
          'Участки недр, содержащие общераспространенные полезные ископаемые',
        ],
        correctAnswers: [
          'Участки недр, содержащие подземные воды, которые используются для целей технологического обеспечения водой объектов промышленности и объем добычи которых составляет более 600 м³ в сутки',
        ],
      },
      {
        code: '63681026',
        text:
          'В каких случаях должны быть определены и нанесены на маркшейдерскую документацию границы опасных зон по прорыву воды и газов?',
        answers: [
          'На шахтах, для которых территориальным органом Ростехнадзора установлено данное требование',
          'На шахтах, вблизи которых присутствуют водные объекты',
          'На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов',
        ],
        correctAnswers: [
          'На шахтах, где имеются затопленные или другие водные (обводненные) объекты, а также выработки, в которых возможны скопления ядовитых и горючих газов',
        ],
      },
      {
        code: '63681027',
        text:
          'Какие из перечисленных мест хранения взрывчатых материалов должны быть приняты в эксплуатацию комиссией по оценке соответствия места хранения установленным требованиям и проектной документации?',
        answers: [
          'Места размещения взрывчатых материалов на местах производства взрывных работ',
          'Места сменного хранения, размещаемые вблизи мест ведения взрывных работ',
          'Передвижные склады',
          'Все ответы неверны',
          'Все перечисленные',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681028',
        text:
          'С какой периодичностью должна проверяться техническая исправность транспортных средств, используемых для доставки взрывчатых материалов, лицом, назначенным распорядительным документом организации?',
        answers: [
          'Еженедельно',
          'Два раза в неделю',
          'Два раза в смену',
          'Ежесменно',
        ],
        correctAnswers: ['Ежесменно'],
      },
      {
        code: '63681029',
        text:
          'Что необходимо соблюдать при погашении уступов и постановке их в предельное положение?',
        answers: [
          'Предельные углы откосов бортов объекта',
          'Общий угол откоса бортов, установленный проектом',
          'Угол сдвижения горных пород',
        ],
        correctAnswers: ['Общий угол откоса бортов, установленный проектом'],
      },
      {
        code: '63681030',
        text:
          'Какие прострелочно-взрывные аппараты должны подниматься над устьем скважины и опускаться с помощью грузоподъемных механизмов?',
        answers: [
          'Массой более 40 кг',
          'Массой более 20 кг или длиной более 1 м',
          'Массой более 35 кг или длиной более 1,2 м',
          'Массой более 30 кг или длиной равной 1 м',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681031',
        text:
          'Чем должна определяться технология производства буровых работ в зонах распространения многолетнемерзлых пород?',
        answers: [
          'Условиями транспортировки к месту проведения работ',
          'Наличием рабочего персонала имеющего соответствующую квалификацию и опыт работы в данной зоне',
          'Мерзлотными и климатическими условиями данного региона',
          'Продолжительностью теплого периода года',
        ],
        correctAnswers: [
          'Мерзлотными и климатическими условиями данного региона',
        ],
      },
      {
        code: '63681032',
        text:
          'Какому эквивалентному значению приравнивается 1 л диоксида азота при проверке вредных продуктов взрыва в выработке (забое)?',
        answers: [
          '5,5 л оксида углерода',
          '8,5 л оксида углерода',
          '6,5 л оксида углерода',
          '7,5 л оксида углерода',
        ],
        correctAnswers: ['6,5 л оксида углерода'],
      },
      {
        code: '63681033',
        text:
          'Куда должны быть занесены машинистом погрузочной техники время обнаружения отказа, принятые меры безопасности, а также данные о том, кому сообщено об обнаружении отказа?',
        answers: [
          'В журнал регистрации отказов при взрывных работах',
          'В журнал ознакомления должностных лиц и рабочих, связанных с подготовкой и производством взрывных работ',
          'В журнал приема-сдачи смен',
          'В журнал замеров электрического сопротивления горных пород',
        ],
        correctAnswers: ['В журнал приема-сдачи смен'],
      },
      {
        code: '63681034',
        text:
          'В течение какого времени предприятия, организации и учреждения, объекты которых расположены на площадях залегания полезных ископаемых и подлежат подработке, обязаны по запросу предприятия по добыче полезных ископаемых представить объективные данные о состоянии зданий, сооружений и технологического оборудования, а также о выполненных строительных мерах охраны и допустимых деформациях?',
        answers: [
          'В течение месяца',
          'В течение 7 дней',
          'В течение 10 дней',
          'В течение 2 месяцев',
        ],
        correctAnswers: ['В течение месяца'],
      },
      {
        code: '63681035',
        text:
          'В течение какого времени должна проводиться стажировка взрывников перед допуском к самостоятельному производству взрывных работ, в том числе после обучения на новый вид взрывных работ?',
        answers: [
          'В течение 14 дней',
          'В течение трех месяцей',
          'В течение одного месяца',
          'В течение двух месяцев',
        ],
        correctAnswers: ['В течение одного месяца'],
      },
      {
        code: '63681036',
        text:
          'Какое из перечисленных требований к порядку согласования и утверждения мер охраны объектов от вредного влияния горных разработок указано верно?',
        answers: [
          'Одной из мер охраны объектов от вредного влияния горных разработок является выданное органом Ростехнадзора разрешение на застройку площадей залегания полезных ископаемых',
          'Меры охраны объектов, построенных ранее в пределах горных отводов без строительных мер защиты, утверждаются собственниками участков недр',
          'Меры охраны объектов, представляемые на согласование и утверждение, составляются в 4 экземплярах и содержат следующие материалы: пояснительную записку с обоснованием выбранных мер охраны',
          'Меры охраны, выбранные проектной организацией, подписываются главным геологом этого предприятия',
        ],
        correctAnswers: [
          'Меры охраны объектов, представляемые на согласование и утверждение, составляются в 4 экземплярах и содержат следующие материалы: пояснительную записку с обоснованием выбранных мер охраны',
        ],
      },
      {
        code: '63681037',
        text:
          'Какой из перечисленных индексов, наносимый на гильзы электродетонаторов и капсюлей-детонаторов, обозначает номер взрывника?',
        answers: [
          'Два цифровых индекса',
          'Два буквенных индекса слева от цифровых',
          'Правый цифровой индекс и два буквенных индекса справа от цифровых',
          'Два буквенных индекса справа от цифровых',
        ],
        correctAnswers: ['Два буквенных индекса справа от цифровых'],
      },
      {
        code: '63681038',
        text:
          'В каких случаях должен производиться контроль удароопасности в выработках?',
        answers: [
          'В случаях появления внешних признаков удароопасности в местах ведения горных работ',
          'В случаях изменений горнотехнической обстановки во время ведения горных работ в местах, где ранее была установлена категория "Опасно"',
          'При изменении горно-геологической обстановки во время ведения горных работ в местах, отнесенных к удароопасной категории',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63681039',
        text:
          'Обязаны ли организации, эксплуатирующие опасные производственные объекты, на которых ведутся горные работы и переработка полезных ископаемых, заключать договоры на обслуживание с профессиональными аварийно-спасательными формированиями (службами)?',
        answers: [
          'Не обязаны в случаях отсутствия предписания территориального надзорного органа',
          'Обязаны в любом случае',
          'На усмотрение руководителя организации, эксплуатирующей объект',
          'Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников',
        ],
        correctAnswers: [
          'Обязаны, кроме случаев, установленных законодательством Российской Федерации, когда организации должны создавать собственные профессиональные аварийно-спасательные службы или профессиональные аварийно-спасательные формирования, а также нештатные аварийно-спасательные формирования из числа работников',
        ],
      },
      {
        code: '63681040',
        text:
          'С какой периодичностью контролируется состояние горных выработок угольной шахты ИТР шахты?',
        answers: [
          'Один раз в месяц',
          'Еженедельно',
          'Ежесуточно',
          'Два раза в смену',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63681041',
        text:
          'Какой должна быть высота свободного прохода на штреках (ортах) скреперования?',
        answers: [
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход, высота которого указывается в проекте производства работ',
          'На штреках (ортах) скреперования всегда должен сохраняться свободный проход по высоте не менее 1,6 м',
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки',
        ],
        correctAnswers: [
          'На штреках (ортах) скреперования должен всегда сохраняться свободный проход по высоте не менее 2/3 высоты выработки',
        ],
      },
      {
        code: '63681042',
        text:
          'Какой нормативный документ определяет порядок лицензирования производства маркшейдерских работ?',
        answers: [
          'Постановление Правительства Российской Федерации от 12.10.2020 № 1661 "О лицензировании эксплуатации взрывопожароопасных и химически опасных производственных объектов I, II, III классов опасности"',
          'Постановление Правительства Российской Федерации от 16.09.2020 № 1467 "О лицензировании производства маркшейдерских работ"',
          'Федеральный закон Российской Федерации от 04.05.2001 № 99-ФЗ "О лицензировании отдельных видов деятельности"',
          'Постановление Правительства Российской Федерации от 15.09.2020 № 1435 "О лицензировании деятельности, связанной с обращением взрывчатых материалов промышленного назначения"',
        ],
        correctAnswers: [
          'Постановление Правительства Российской Федерации от 16.09.2020 № 1467 "О лицензировании производства маркшейдерских работ"',
        ],
      },
      {
        code: '63681043',
        text:
          'Кто устанавливает маршруты транспортирования взрывчатых материалов от склада ВМ на места работ (в пределах опасного производственного объекта)?',
        answers: [
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
          'Руководитель группы открытых горных работ, ответственный за проведение взрывных работ',
          'Представитель территориального органа Ростехнадзора',
          'Руководитель взрывных работ организации, эксплуатирующей опасный производственный объект',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) организации, эксплуатирующей опасный производственный объект',
        ],
      },
      {
        code: '63681044',
        text: 'Что должны отображать графические материалы?',
        answers: [
          'Только границы участка недр, установленные при предоставлении лицензии на пользование недрами',
          'Только контуры запасов полезных ископаемых, числящихся на учете пользователя недр в государственном балансе запасов полезных ископаемых по состоянию на 1 января текущего года',
          'Только направления развития работ, связанных с пользованием недрами, в планируемом периоде (для месторождений твердых полезных ископаемых)',
          'Только ситуацию местности на площади горного отвода, обозначения линий вертикальных разрезов и проекций, поясняющие надписи, масштаб, координатную сетку',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681045',
        text:
          'В течение какого времени главный инженер шахты организует проверку состава, температуры, влажности рудничной атмосферы и расхода воздуха ИТР шахты и работниками ПАСС(Ф) после разгазирования вскрытых горных выработок?',
        answers: [
          'В течение двух суток',
          'В течение 12 часов',
          'В течение суток',
          'В течение не менее трех суток',
        ],
        correctAnswers: ['В течение не менее трех суток'],
      },
      {
        code: '63681046',
        text:
          'Какой порядок одновременного ведения очистных работ на смежных этажах предусмотрен "Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на расстояние не более 1 м',
          'При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом',
          'При одновременном ведении очистных работ на смежных этажах забои нижнего этажа должны опережать забои верхнего этажа на безопасное расстояние',
        ],
        correctAnswers: [
          'При одновременном ведении очистных работ на смежных этажах забои верхнего этажа должны опережать забои нижнего этажа на безопасное расстояние, определенное проектом',
        ],
      },
      {
        code: '63681047',
        text:
          'Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для II типа кровли?',
        answers: ['2,4 м', '1,6 м', '2,0 м', '1,2 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63681048',
        text:
          'Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?',
        answers: [
          'Только соблюдение требований по безопасному ведению горных и взрывных работ',
          'Только восполнение количества вскрытых, подготовленных и готовых к выемке запасов полезных ископаемых',
          'Только проведение комплекса геологических, маркшейдерских и иных наблюдений за состоянием горного отвода',
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
        correctAnswers: [
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
      },
      {
        code: '63681049',
        text:
          'При каких условиях допускается проведение прострелочных или взрывных работ в скважинах?',
        answers: [
          'Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых',
          'Во время грозы и сильных туманов',
          'Без применения лубрикаторов в сухих газирующих и поглощающих раствор скважинах',
          'Во время пурги, буранов',
        ],
        correctAnswers: [
          'Во время туманов (при видимости более 50 м) при выполнении работ в закрытых помещениях буровых',
        ],
      },
      {
        code: '63681050',
        text:
          'Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?',
        answers: [
          'Условия безопасного недропользования',
          'Штатная численность сотрудников объекта ведения горных работ',
          'Технологические решения при эксплуатации объектов ведения горных работ',
          'Объемы добычи полезных ископаемых',
        ],
        correctAnswers: [
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
      },
      {
        code: '63681051',
        text:
          'На каком расстоянии от места заряжания скважин и стоянки зарядной машины и трубопровода запрещается производить какие-либо работы, непосредственно не связанные с заряжанием?',
        answers: [
          'Не ближе 40 м',
          'Не ближе 70 м',
          'Не ближе 50 м',
          'Не ближе 30 м',
        ],
        correctAnswers: ['Не ближе 50 м'],
      },
      {
        code: '63681052',
        text:
          'Кем осуществляется подготовка проектной документации на разработку месторождений полезных ископаемых?',
        answers: [
          'Только организациями, имеющими лицензию на право разработки проектной документации в области недропользования',
          'Только специалистами Росгеологии',
          'Только проектной организацией, привлекаемой для подготовки проектной документации',
          'Пользователями недр или проектной организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование',
        ],
        correctAnswers: [
          'Пользователями недр или проектной организацией, привлекаемой пользователем недр для подготовки проектной документации, на основании технического задания на проектирование',
        ],
      },
      {
        code: '63681053',
        text:
          'В каком случае горные работы следует производить с применением комплекса мер по профилактике горных ударов, высыпаний, вывалов и обрушения пород (руд) на участках рудного массива или массива горных пород?',
        answers: [
          'При выявлении отдельных блоков, ограниченных крупными разрывными нарушениями в пределах месторождения',
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
          'При разработанных мероприятиях для каждого конкретного месторождения с учетом особенностей',
          'В случае отработки целиков с формированием защищенной зоны',
        ],
        correctAnswers: [
          'Расположенных на удалении 5 - 10 м от плоскости сместителя тектонического нарушения',
        ],
      },
      {
        code: '63681054',
        text:
          'Что из перечисленного не является предметом проверок при осуществлении государственного горного надзора?',
        answers: [
          'Cоблюдение пользователями недр требований по организации контроля за состоянием рудничной атмосферы, содержанием в ней кислорода, вредных и взрывоопасных газов и пыли',
          'Проведение маркшейдерских наблюдений, необходимых для обеспечения нормального технологического цикла, достоверного учета горных работ и прогнозирования опасных ситуаций',
          'Правильность использования взрывчатых веществ и средств взрывания, их надлежащий учет, хранение и расходование на объектах, связанных с пользованием недрами',
          'Соблюдение пользователями недр требований промышленной безопасности на объектах, на которых производятся работы по транспортировке опасных веществ, а так же при монтаже, наладке и ремонте технических устройств, применяемых на этих объектах',
          'Проведение горноспасательных работ в части, касающейся состояния и готовности подразделений военизированных горноспасательных формирований к ликвидации аварий на обслуживаемых объектах, связанных с пользованием недрами',
        ],
        correctAnswers: [
          'Проведение горноспасательных работ в части, касающейся состояния и готовности подразделений военизированных горноспасательных формирований к ликвидации аварий на обслуживаемых объектах, связанных с пользованием недрами',
        ],
      },
      {
        code: '63681055',
        text:
          'Какой федеральный орган исполнительной власти осуществляет регистрацию опасных производственных объектов и ведет реестр таких объектов?',
        answers: [
          'МЧС России',
          'Ростехнадзор',
          'Росприроднадзор',
          'Минэнерго России',
        ],
        correctAnswers: ['Ростехнадзор'],
      },
      {
        code: '63681056',
        text:
          'Каким должен быть свободный проход в наклонных горных выработках, предназначенных для передвижения людей?',
        answers: [
          'Шириной не менее 1 м и высотой 2 м',
          'Шириной не менее 0,7 м и высотой 1,8 м',
          'Шириной не менее 0,8 м и высотой 2,2 м',
          'Шириной не менее 1,5 м и высотой 2,5 м',
        ],
        correctAnswers: ['Шириной не менее 0,7 м и высотой 1,8 м'],
      },
      {
        code: '63681057',
        text:
          'Какое количество зарядов разрешается одновременно заряжать и взрывать при температуре в шпуре ниже 80 °C при ведении взрывных работ по металлу?',
        answers: [
          'Не более семи зарядов',
          'Не более трех зарядов',
          'Не более пяти зарядов',
          'Не более одного заряда',
        ],
        correctAnswers: ['Не более пяти зарядов'],
      },
      {
        code: '63681058',
        text:
          'Взрывчатые вещества каких составов разрешается производить на пунктах производства взрывчатых веществ?',
        answers: [
          'Только водосодержащие смеси-суспензии, эмульсии и эмульсионные взрывчатые вещества на основе раствора аммиачной селитры или ее раствора с добавками кальциевой или натриевой селитры или карбамида с порошкообразными и жидкими невзрывчатыми горючими',
          'Только смеси холодного смешения гранулированной аммиачной селитры с гранулированным или чешуированным тротилом',
          'Только смеси холодного смешения гранулированной аммиачной селитры с жидкими и твердыми нефтяными, порошкообразными или другого происхождения невзрывчатыми горючими',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63681059',
        text:
          'На каких пластах угольных шахт допускается в качестве вентиляционных печей использовать скважины, пробуренные диаметром не менее 0,7 м?',
        answers: [
          'Мощностью менее 6 м',
          'Мощностью менее 8 м',
          'Мощностью менее 5 м',
          'На всех мощных пластах',
        ],
        correctAnswers: ['Мощностью менее 5 м'],
      },
      {
        code: '63681060',
        text:
          'При каком минимальном расстоянии до опасной зоны участковый маркшейдер должен делать замеры и оповещения о размерах целика после каждой заходки главному инженеру и начальнику участка?',
        answers: [
          'За 8,5 м до опасной зоны',
          'За 7 м до опасной зоны',
          'За 12 м до опасной зоны',
          'За 10 м до опасной зоны',
        ],
        correctAnswers: ['За 7 м до опасной зоны'],
      },
      {
        code: '63681061',
        text:
          'В течение какого срока должны храниться в организации Книга учета прихода и расхода взрывчатых материалов и Книга учета выдачи и возврата взрывчатых материалов?',
        answers: [
          'В течение не менее одного года',
          'В течение не менее трех лет',
          'В течение не менее пяти лет',
          'В течение не менее двух лет',
        ],
        correctAnswers: ['В течение не менее трех лет'],
      },
      {
        code: '63681062',
        text:
          'Каким должно быть давление на форсунках (оросителях) выемочных и проходческих комбайнов на угольных шахтах?',
        answers: [
          'Не менее 0,7 МПа',
          'Определяется проектной документацией',
          'Не менее 0,5 МПа',
          'Не менее 1 МПа',
        ],
        correctAnswers: ['Определяется проектной документацией'],
      },
      {
        code: '63681063',
        text:
          'При выполнении каких из перечисленных профилактических мероприятий должно осуществляться проведение и поддержание выработок на месторождениях, на которых происходили горные удары?',
        answers: [
          'Использование податливых типов крепи',
          'Крепление выработок постоянной крепью с элементами податливости в зонах влияния геологических нарушений',
          'Приведение выработок категории "Опасно" в неудароопасное состояние',
          'Бурение разгрузочных щелей и скважин',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '63681064',
        text:
          'На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?',
        answers: [
          'На 2 года',
          'На 1 год',
          'На срок действия лицензии на пользование недрами',
          'Срок не ограничен',
        ],
        correctAnswers: ['На срок действия лицензии на пользование недрами'],
      },
      {
        code: '63681065',
        text: 'С учетом каких факторов определяется высота уступа?',
        answers: [
          'Только с учетом результатов исследований физико-механических свойств горных пород',
          'Только с учетом горно-геологических условий залегания пород полезных ископаемых',
          'Только с учетом параметров применяемого оборудования',
          'С учетом всех перечисленных параметров',
        ],
        correctAnswers: ['С учетом всех перечисленных параметров'],
      },
      {
        code: '63681066',
        text:
          'Что из перечисленного не учитывается при планировании площадки для монтажа буровой установки?',
        answers: [
          'Обеспечение движения сточных вод в систему их сбора и очистки',
          'Естественный уклон местности',
          'Роза ветров',
        ],
        correctAnswers: ['Роза ветров'],
      },
      {
        code: '63681067',
        text:
          'С какой периодичностью служба главного маркшейдера шахты проводит проверку износа рельсов откаточных путей?',
        answers: [
          'Не реже одного раза в полугодие',
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в год',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63681068',
        text:
          'С какой периодичностью производится осмотр устья ликвидированных горных выработок, имеющих выход на земную поверхность, комиссией, назначенной распорядительным документом руководителя шахты?',
        answers: [
          'Не менее двух раз в год',
          'Не менее одного раза в год',
          'Не менее одного раза в три года',
          'Не менее одного раза в пять лет',
        ],
        correctAnswers: ['Не менее двух раз в год'],
      },
      {
        code: '63681069',
        text:
          'Какой должна быть высота реборды над верхним слоем навивки при наличии более одного слоя навивки каната на барабан?',
        answers: [
          '1 диаметр каната',
          '2,5 диаметра каната',
          '1,5 диаметра каната',
          '2 диаметра каната',
        ],
        correctAnswers: ['2,5 диаметра каната'],
      },
      {
        code: '63681070',
        text:
          'Где устанавливают аппаратуру аварийной связи и оповещения на угольных шахтах?',
        answers: [
          'Только у главного инженера шахты',
          'Только у диспетчера',
          'Только у абонентов',
          'У всех перечисленных',
        ],
        correctAnswers: ['У всех перечисленных'],
      },
      {
        code: '63681071',
        text:
          'К какому типу кровли по обрушаемости относится кровля при сопротивлении пород одноосному сжатию Rc ≤ 30 МПа?',
        answers: [
          'К I типу кровли',
          'К II типу кровли',
          'К III типу кровли',
          'К IV типу кровли',
        ],
        correctAnswers: ['К III типу кровли'],
      },
      {
        code: '63681072',
        text:
          'Какой документ составляется по результатам проведения инструментальных наблюдений и анализа материалов главным маркшейдером или руководителем группы по наблюдениям за сдвижением?',
        answers: [
          'Схема производства работ на земной поверхности',
          'Технический отчет, содержащий основные результаты наблюдений и рекомендаций по их практическому использованию для решения вопросов охраны объекта',
          'Пояснительная записка о проведении инструментальных наблюдений за сдвижением горных пород',
          'Акт заключительного анализа по результатам произведенных наблюдений',
        ],
        correctAnswers: [
          'Технический отчет, содержащий основные результаты наблюдений и рекомендаций по их практическому использованию для решения вопросов охраны объекта',
        ],
      },
      {
        code: '63681073',
        text:
          'Какие мероприятия должны включаться в пояснительную записку планов развития горных работ?',
        answers: [
          'Только консервация и (или) ликвидация горных выработок, в том числе скважин, рекультивация нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых при их наличии)',
          'Только организация наблюдений за состоянием горных отводов',
          'Только достоверный учет количества добываемого минерального сырья в общем объеме горной массы, в том числе при маркшейдерском, весовом, метрологическом контроле (при разработке месторождений твердых полезных ископаемых)',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63681074',
        text:
          'Какое из перечисленных расстояний при ведении взрывных работ в подземных выработках указано верно?',
        answers: [
          'Запрещается ведение взрывных работ на расстоянии менее 50 м от склада взрывчатых материалов участкового пункта, раздаточной камеры',
          'Запрещается взрывание зарядов, если на расстоянии менее 50 м от места их заложения находятся неубранная отбитая горная масса, вагонетки или предметы, загромождающие выработку более чем на 1/5 площади ее поперечного сечения, при отсутствии свободных проходов',
          'Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них',
        ],
        correctAnswers: [
          'Запрещается нахождение людей в раздаточной камере при взрывных работах, проводящихся на расстоянии ближе 100 м от них',
        ],
      },
      {
        code: '63681075',
        text:
          'Кем утверждается проект и план перевода скважины на газлифтную эксплуатацию?',
        answers: [
          'Уполномоченным лицом надзорной организации',
          'Руководителем участка добычи',
          'Техническим руководителем организации',
          'Руководителем проектной организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63681076',
        text: 'Какие требования предъявляются к установке бурового станка?',
        answers: [
          'Буровой станок должен быть установлен на специальной отведенной площадке на расстоянии 3 метров от верхней бровки уступа',
          'Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка',
          'Буровой станок должен быть установлен на спланированной площадке на максимальном расстоянии от точки опоры станка до верхней бровки уступа',
        ],
        correctAnswers: [
          'Буровой станок должен быть установлен на безопасном расстоянии от верхней бровки уступа в соответствии с проектом, но не менее 2 м от бровки до ближайшей точки опоры станка',
        ],
      },
      {
        code: '63681077',
        text:
          'В какой момент допускается проводить затяжку гаек анкера при возведении анкерной крепи?',
        answers: [
          'После определяемого визуально отвердения состава ампул',
          'Через час после полного твердения состава ампул',
          'Сразу после установки анкеров',
          'После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем',
        ],
        correctAnswers: [
          'После окончания процесса твердения состава ампул, время которого указывается заводом-изготовителем',
        ],
      },
      {
        code: '63681078',
        text:
          'Кто утверждает документацию по ведению горных работ после внесения в нее дополнений, учитывающих происшедшие изменения горно-геологических и горнотехнических условий?',
        answers: [
          'Комиссия, назначенная приказом руководителя горнодобывающего предприятия',
          'Начальник маркшейдерской службы',
          'Главный инженер шахты',
          'Проектная организация',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63681079',
        text:
          'В отношении каких действий проектная документация подлежит согласованию с комиссией при пользовании недрами для строительства и эксплуатации подземных сооружений, не связанных с добычей полезных ископаемых?',
        answers: [
          'Только в отношении строительства и эксплуатации различных хранилищ углеводородного сырья и продуктов его переработки в пластах горных пород',
          'Только в отношении размещения отходов производства и потребления',
          'Только в отношении захоронения токсичных, радиоактивных и других опасных отходов в глубоких горизонтах, обеспечивающих локализацию данных отходов',
          'Всего перечисленного',
        ],
        correctAnswers: ['Всего перечисленного'],
      },
      {
        code: '63681080',
        text:
          'В какой орган пользователь недр должен ежемесячно представлять результаты наблюдений за процессами сдвижения горных пород и земной поверхности?',
        answers: [
          'В Росреестр',
          'В Роснедра',
          'В Ростехнадзор',
          'В Министерство природных ресурсов и экологии Российской Федерации',
        ],
        correctAnswers: ['В Ростехнадзор'],
      },
      {
        code: '63681081',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе плана производства горных работ',
          'На основе горно-графической документации',
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
          'На основе инструкции по производству маркшейдерских работ',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63681082',
        text:
          'Какие требования предъявляются к спуску забойного электронагревателя в скважину?',
        answers: [
          'Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье',
          'Все операции по спуску должны выполняться вручную, с использованием кабель-троса при открытом устье скважины при выполнении всех подготовительных работ',
          'Операции должны выполняться дистанционно, с использованием специального обратного клапана, при постоянном наблюдении за устьем',
          'Операции должны быть автоматизированы, с использованием превенторной установки, с фиксацией работ по монтажу и демонтажу установки',
        ],
        correctAnswers: [
          'Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье',
        ],
      },
      {
        code: '63681083',
        text:
          'Какое из перечисленных требований к оформлению таблиц в проектной документации на разработку месторождений углеводородного сырья соответствует Требованиям к структуре и оформлению проектной документации на разработку месторождений углеводородного сырья?',
        answers: [
          'Таблицы, расположенные на отдельных листах, не входят в общую нумерацию страниц отчета',
          'Название таблицы должно размещаться над таблицей в центре',
          'Таблицы, за исключением таблиц приложений, нумеруются арабскими цифрами сквозной нумерацией',
          'Нумерация таблиц в пределах раздела не требуется',
        ],
        correctAnswers: [
          'Таблицы, за исключением таблиц приложений, нумеруются арабскими цифрами сквозной нумерацией',
        ],
      },
      {
        code: '63681084',
        text:
          'Как часто во всех действующих выработках должны производиться нивелирование откаточных путей и проверки соответствия зазоров требованиям Федеральных норм и правил в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Один раз в полугодие',
          'Один раз в год',
          'Один раз в три года',
          'Два раза в квартал',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63681085',
        text: 'Какими лицами переносятся средства инициирования?',
        answers: [
          'Раздатчиками взрывчатых материалов 3 разряда',
          'Ответственными работниками маркшейдерской службы',
          'Взрывниками',
          'Всеми перечисленными лицами',
        ],
        correctAnswers: ['Взрывниками'],
      },
      {
        code: '63681086',
        text:
          'Какие требования к оформлению проектной документации на разработку месторождений твердых полезных ископаемых, ликвидацию и консервацию горных выработок и первичную переработку минерального сырья указаны неверно?',
        answers: [
          'Объемы и детальность проработки отдельных разделов определяются авторами проектного документа в зависимости от сложности строения месторождений, количества эксплуатационных объектов и рассматриваемых вариантов их разработки, стадии проектирования',
          'Изменения, дополнения, корректировка проектной документации должны содержать только те разделы, которые подвергаются переработке в процессе разработки изменений',
          'В изменении, дополнении, корректировке к проектной документации не допускается делать ссылки на неизмененные разделы технического проекта либо помещать их в кратком изложении',
          'Проектная документация должна содержать все данные, позволяющие производить анализ проектных решений без личного участия авторов',
        ],
        correctAnswers: [
          'В изменении, дополнении, корректировке к проектной документации не допускается делать ссылки на неизмененные разделы технического проекта либо помещать их в кратком изложении',
        ],
      },
      {
        code: '63681087',
        text:
          'Через какое расстояние эстакады для трубопроводов при обустройстве нефтяных, газовых и газоконденсатных месторождений должны быть электрически соединены с проходящими по ним трубопроводами и заземлены?',
        answers: [
          'Через 150 - 200 м, а также в начале и в конце',
          'Через 350 - 400 м, а также в начале и в конце',
          'Через 200 - 300 м, а также в начале и в конце',
          'Через каждые 100 м',
        ],
        correctAnswers: ['Через 200 - 300 м, а также в начале и в конце'],
      },
      {
        code: '63681088',
        text:
          'Какие электродетонаторы разрешается применять в бутовых штреках с подрывкой кровли?',
        answers: [
          'Электродетонаторы мгновенного действия',
          'Электродетонаторы замедленного действия',
          'Электродетонаторы короткозамедленного действия',
          'Все перечисленные электродетонаторы',
        ],
        correctAnswers: ['Электродетонаторы мгновенного действия'],
      },
      {
        code: '63681089',
        text:
          'Прокладка каких труб и кабелей допускается в производственных зданиях (помещениях), где ведутся работы с окислителями или их растворами?',
        answers: [
          'Кабелей с открытой свинцовой оболочкой',
          'Бронированных кабелей с оцинкованной броней',
          'Пластмассовых импульсных труб',
          'Медных импульсных труб',
        ],
        correctAnswers: ['Пластмассовых импульсных труб'],
      },
      {
        code: '63681090',
        text:
          'Какие действия обязаны предпринять работники подземного рудника (шахты), обнаружившие отказавший заряд? Укажите все правильные ответы.',
        answers: [
          'Незамедлительно поставить в известность технического руководителя горнодобывающей организации',
          'Обозначить невзорвавшийся заряд',
          'Прекратить все работы',
          'Закрестить выработку',
        ],
        correctAnswers: ['Прекратить все работы', 'Закрестить выработку'],
      },
      {
        code: '63681091',
        text:
          'Какая документация должна быть размещена на объектах сбора и подготовки нефти и газа (ЦПС, УПН, УКПГ, ГП), насосных и компрессорных станциях (ДНС, КС)?',
        answers: [
          'Технические паспорта и документация на оборудование насосных и компрессорных станций',
          'Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации',
          'План аварийно-спасательных мероприятий, схема эвакуации работников',
          'Технические паспорта оборудования (приборов) и список ответственных лиц за проведение работ',
          'Приказ о назначении лиц, ответственных по запуску и отключению технологического оборудования',
        ],
        correctAnswers: [
          'Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации',
        ],
      },
      {
        code: '63681092',
        text:
          'Каким устанавливается радиус опасной зоны вокруг нагнетательной скважины на период инициирования внутрипластового горения?',
        answers: [
          'Не менее 25 м',
          'Не менее 10 м',
          'Не менее 5 м',
          'Не менее 20 м',
        ],
        correctAnswers: ['Не менее 25 м'],
      },
      {
        code: '63681093',
        text:
          'В течение какого срока допускается размещать зарядные машины, загруженные взрывчатыми веществами, на специально выделенной площадке на территории склада ВМ?',
        answers: [
          'Не более одной недели',
          'На срок не более трех суток',
          'На срок не более двух суток',
          'На срок не более одной смены',
        ],
        correctAnswers: ['На срок не более двух суток'],
      },
      {
        code: '63681094',
        text:
          'На какую длину разрешается вынимать из шпуров отказавших зарядов забоечный материал для установления направления вспомогательных шпуров?',
        answers: [
          'На длину до 20 см от устья шпура',
          'На длину до 30 см от устья шпура',
          'На длину до 25 см от устья шпура',
          'На длину до 50 см от устья шпура',
        ],
        correctAnswers: ['На длину до 20 см от устья шпура'],
      },
      {
        code: '63681095',
        text:
          'Какие требования предъявляются к условиям закладки скважин, предназначенных для поисков, разведки, эксплуатации месторождений нефти, газа и газового конденсата?',
        answers: [
          'Скважины должны закладываться в пределах земельного отвода в зоне производственного назначения',
          'Скважины должны закладываться в границах зоны действия ограничений по условиям охраны территорий',
          'Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередачи, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов',
        ],
        correctAnswers: [
          'Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередачи, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов',
        ],
      },
      {
        code: '63681096',
        text:
          'Какой верхний предел диапазона измерений должны иметь манометры, устанавливаемые на блоках дросселирования и глушения?',
        answers: [
          'На 40 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'На 25 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'На 10 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
        ],
        correctAnswers: [
          'На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
        ],
      },
      {
        code: '63681097',
        text:
          'В каком случае оснащение буровых установок верхним приводом не обязательно?',
        answers: [
          'В случае вскрытия пластов с ожидаемым содержанием в пластовом флюиде сернистого водорода свыше 6% (объемных)',
          'В случае набора угла с радиусом кривизны 25 м в наклонно направленных скважинах',
          'В случае бурения скважин с глубиной по стволу менее 4500 м',
          'В случае бурения горизонтального участка ствола скважины длиной 400 м в скважинах глубиной по вертикали 3200 м',
        ],
        correctAnswers: [
          'В случае бурения скважин с глубиной по стволу менее 4500 м',
        ],
      },
      {
        code: '63681098',
        text:
          'Каким документом должны определяться место погрузки-выгрузки, меры безопасности, а также порядок погрузки-разгрузки взрывчатых материалов в околоствольных дворах шахт, рудников, штолен и надшахтных зданиях?',
        answers: [
          'Технологическим регламентом на проведение погрузочно-разгрузочных работ взрывчатых материалов',
          'Распорядительным документом организации (шахты, рудника)',
          'Предписанием территориального органа Ростехнадзора',
          'Проектной документацией',
        ],
        correctAnswers: [
          'Распорядительным документом организации (шахты, рудника)',
        ],
      },
      {
        code: '63681099',
        text:
          'К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли от 50 до 200 мм?',
        answers: [
          'К I категории (небольшое горное давление)',
          'К III категории (интенсивное горное давление)',
          'К II категории (горное давление средней интенсивности)',
        ],
        correctAnswers: [
          'К II категории (горное давление средней интенсивности)',
        ],
      },
      {
        code: '63681100',
        text:
          'Какие из перечисленных участков являются местами интенсивного пылеотложения при разработке пластов, опасных по взрывам угольной пыли?',
        answers: [
          'Только места в районе погрузочных пунктов и на протяжении 25 м от них по направлению вентиляционной струи',
          'Только конвейерные выработки, по которым транспортируется уголь',
          'Только подготовительные выработки, проводимые по углю и породе, на протяжении 50 м от их забоев',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63681101',
        text:
          'Какая максимальная концентрация метана в трубопроводах для изолированного отвода метана и в газодренажных горных выработках является допустимой?',
        answers: [
          '3,5 % по объему',
          '2,0 % по объему',
          '1,0 % по объему',
          '0,75 % по объему',
        ],
        correctAnswers: ['3,5 % по объему'],
      },
      {
        code: '63681102',
        text:
          'В каком случае установка автоматического клапана-отсекателя на выкидной линии газовых и газоконденсатных скважин не требуется?',
        answers: [
          'В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины',
          'В случае, если выполняется эксплуатационное бурение скважин',
          'В случае, если выполняется разведочное бурение',
          'В случае, если содержание сероводорода в природном газе или газоконденсате составляет не более 2 %',
        ],
        correctAnswers: [
          'В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины',
        ],
      },
      {
        code: '63681103',
        text:
          'Через какое время после производства массовых взрывов разрешается допуск работников на рабочие места?',
        answers: [
          'Не ранее чем через 60 минут',
          'Не ранее чем через 90 минут',
          'Не ранее чем через 30 минут',
          'Не ранее чем через 20 минут',
        ],
        correctAnswers: ['Не ранее чем через 30 минут'],
      },
      {
        code: '63681104',
        text:
          'В каком случае обеспечивается защита хранилищ взрывчатых материалов от заноса высоких потенциалов при вводе в них электрических сетей освещения присоединением металлической брони и оболочки кабеля к заземлителю защиты от вторичных воздействий?',
        answers: [
          'При кабелях, присоединенных к воздушной линии, не требующих заземления',
          'При небронированных кабелях, не требующих присоединения к заземлителю',
          'При бронированных кабелях, проложенных в земле',
        ],
        correctAnswers: ['При бронированных кабелях, проложенных в земле'],
      },
      {
        code: '63681105',
        text:
          'Какой величины допускается высота уступа и его угол откоса при разработке соляного пласта?',
        answers: [
          'Высота не должна быть меньше 5 м, а угол откоса не более 80°',
          'Высота не должна превышать 3 м, а угол откоса равен 65°',
          'Высота не должна превышать 6 м, а угол откоса не менее 80°',
          'Высота не должна превышать 8 м, а угол откоса не более 75°',
        ],
        correctAnswers: [
          'Высота не должна превышать 8 м, а угол откоса не более 75°',
        ],
      },
      {
        code: '63681106',
        text:
          'Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?',
        answers: [
          'Время, затраченное на работы по проходке основного ствола скважин и техническую скорость бурения',
          'Показатели измерений кривизны скважины, проводимые в процессе бурения',
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
          'Промежуточные показатели расхода буровой смеси для проходки',
        ],
        correctAnswers: [
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
        ],
      },
      {
        code: '63681107',
        text:
          'Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?',
        answers: [
          'Доставкой курьером по адресу организации',
          'Заказным письмом с уведомлением и описью вложения через почтовую службу',
          'В соответствии с законодательством Российской Федерации о государственной тайне',
          'Отправкой бандероли с объявленной ценностью, опломбированной печатью отправителя',
        ],
        correctAnswers: [
          'В соответствии с законодательством Российской Федерации о государственной тайне',
        ],
      },
      {
        code: '63681108',
        text:
          'Кем подписывается проект горного отвода (пояснительная записка)?',
        answers: [
          'Лицами, ответственными за руководство геологическими, маркшейдерскими работами пользователя недр',
          'Владельцем участка, содержащего месторождения полезных ископаемых, предоставляемого в пользование',
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
          'Руководителем сторонней организации, проектирующей горные работы',
        ],
        correctAnswers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
        ],
      },
      {
        code: '63681109',
        text:
          'Какие материалы допускается использовать для насыпки валов складов взрывчатых материалов?',
        answers: [
          'Щебень',
          'Камень',
          'Пластинчатые грунты',
          'Все перечисленные материалы',
        ],
        correctAnswers: ['Пластинчатые грунты'],
      },
      {
        code: '63681110',
        text:
          'Какое из перечисленных требований к уничтожению взрывчатых материалов указано верно?',
        answers: [
          'Допускается применение смерзшихся взрывчатых веществ, содержащих жидкие нитроэфиры 20%',
          'Взрывчатые материалы допускается сжигать в их таре в соответствии с руководством (инструкцией) по применению',
          'Уничтожение взрывчатых материалов должно выполняться взрывниками под контролем технического руководителя организации',
          'Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами',
        ],
        correctAnswers: [
          'Запрещается подход к месту сжигания до полного прекращения горения костра с взрывчатыми материалами',
        ],
      },
      {
        code: '63681111',
        text:
          'С какой периодичностью должно производиться профилирование стенок шахтного ствола и проводников в нем и где отражаются результаты такого профилирования?',
        answers: [
          'С периодичностью один раз в год с отражением результатов в паспорте вертикального шахтного ствола',
          'Сроки профилирования устанавливаются техническим регламентом для каждого ствола, но не реже одного раза в месяц и отражаются в геолого-техническом наряде',
          'Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации',
          'Проверка положения стенок шахтного ствола и проводников производится один раз в два года с отражением результатов профилирования в маркшейдерской документации',
        ],
        correctAnswers: [
          'Сроки профилирования устанавливаются техническим руководителем эксплуатирующей организации для каждого ствола, но не реже одного раза в три года. Результаты профилирования отражаются в маркшейдерской документации',
        ],
      },
      {
        code: '63681112',
        text:
          'В каких из перечисленных помещениях допускается хранение взрывчатых материалов?',
        answers: [
          'В здании подготовки взрывчатых материалов',
          'В тамбурах хранилищ',
          'В здании выдачи взрывчатых материалов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681113',
        text:
          'С учетом каких параметров производятся выбор обсадных труб и расчет обсадных колонн на стадиях строительства и эксплуатации скважин?',
        answers: [
          'С учетом возможного гидроразрыва пород или развитие интенсивного поглощения раствора',
          'С учетом возникновения давления гидростатического столба бурового раствора максимальной плотности',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
          'С учетом минимальных гидростатических давлений составного столба бурового раствора и жидкости затворения цемента над пластовыми давлениями перекрываемых флюидосодержащих горизонтов',
        ],
        correctAnswers: [
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
        ],
      },
      {
        code: '63681114',
        text:
          'С какой периодичностью проводится измерение параметров электризации в условиях производства?',
        answers: [
          'Не реже одного раза в год',
          'Не реже двух раз в год',
          'Не реже двух раз в месяц',
          'Не реже двух раз в полугодие',
        ],
        correctAnswers: ['Не реже двух раз в год'],
      },
      {
        code: '63681115',
        text:
          'Какая максимально допустимая скорость воздуха в вентиляционных скважинах угольных шахт?',
        answers: [
          'Скорость воздуха не ограничена',
          '15 м/с',
          '8 м/с',
          '12 м/с',
        ],
        correctAnswers: ['Скорость воздуха не ограничена'],
      },
      {
        code: '63681116',
        text:
          'Как часто должны проходить медицинский осмотр рабочие, выполняющие работы повышенной опасности, перечень которых установлен руководителем организации?',
        answers: [
          'Каждый раз перед началом смены',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в полугодие',
        ],
        correctAnswers: ['Каждый раз перед началом смены'],
      },
      {
        code: '63681117',
        text:
          'Укажите требования, соответствующие Федеральным нормам и правилам в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых" к организации надзора и проектному оформлению при посадке кровли в процессе очистной выемки.',
        answers: [
          'Посадка кровли должна выполняться бригадой в составе не менее 5 работников шахты под непосредственным руководством начальника смены',
          'Посадка кровли должна производиться под наблюдением руководителя маркшейдерской службы в соответствии с техническим проектом',
          'Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ',
          'Посадка кровли должна производиться под руководством лица технического надзора в соответствии со схемой развития горных работ',
        ],
        correctAnswers: [
          'Посадка кровли должна производиться под непосредственным руководством лица технического надзора в соответствии с проектом организации работ',
        ],
      },
      {
        code: '63681118',
        text:
          'При каких условиях недропользователю устанавливаются уточненные границы горного отвода?',
        answers: [
          'В момент подготовки к выполнению работ по добыче полезных ископаемых после получения лицензии на право пользования недрами',
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы',
          'Одновременно с получением лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений',
          'В процессе разработки нефтяных и газовых месторождений по требованию органа, выдавшего лицензию на пользование недрами',
        ],
        correctAnswers: [
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы',
        ],
      },
      {
        code: '63681119',
        text:
          'С какой периодичностью должны проверяться знания требований безопасности работниками, связанными с обращением со взрывчатыми материалами и имеющими Единую книжку взрывника (за исключением заведующих складами взрывчатых материалов, пунктами производства взрывчатых материалов и руководителей взрывных работ) специальной комиссией организации с участием представителя территориального органа Ростехнадзора?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в три года',
          'Не реже одного раза в два года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в два года'],
      },
      {
        code: '63681120',
        text: 'Какое совместное хранение взрывчатых материалов не допускается?',
        answers: [
          'Детонирующий шнур групп совместимости D и S со средства инициирования групп совместимости B и S',
          'Дымные (группа совместимости D) и бездымные (группа совместимости C) порохи',
          'Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом',
          'Средства инициирования группы совместимости S и средства инициирования группы совместимости В',
        ],
        correctAnswers: [
          'Детонаторы для взрывных работ, сборки детонаторов для взрывных работ и капсюли-воспламенители (группа совместимости В) с первичным взрывчатым веществом',
        ],
      },
      {
        code: '63681121',
        text: 'Когда на руднике допускается проходка выработок без крепления?',
        answers: [
          'Допускается проходка только горизонтальных или наклонных подземных выработок',
          'Допускается проходка горных выработок в неустойчивых породах',
          'Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния',
          'Допускается проходка горных выработок, если технологический срок службы выработки менее 5 лет',
        ],
        correctAnswers: [
          'Допускается проходка горных выработок в устойчивых породах, а также в случае, если технологический срок службы выработки не превышает времени ее устойчивого состояния',
        ],
      },
      {
        code: '63681122',
        text:
          'На сколько дней может быть увеличен срок рассмотрения комиссией проектной документации по уникальным и крупным месторождениям полезных ископаемых?',
        answers: [
          'Не более чем на 10 рабочих дней',
          'Не более чем на 14 рабочих дней',
          'Срок рассмотрения не увеличивается',
          'Не более чем на 25 рабочих дней',
        ],
        correctAnswers: ['Не более чем на 25 рабочих дней'],
      },
      {
        code: '63681123',
        text:
          'Какие меры из перечисленных входят в комплекс работ по освоению скважин?',
        answers: [
          'Только предупреждение прорыва пластовой воды и газа из газовой "шапки", предотвращение неконтролируемых газонефтеводопроявлений и открытых фонтанов',
          'Только термогидрогазодинамические исследования по определению количественной и качественной характеристики пласта и его геологофизических параметров',
          'Только сохранение скелета пласта в призабойной зоне, восстановление и повышение проницаемости данной зоны',
          'В комплекс работ по освоению скважин входят все перечисленные меры',
        ],
        correctAnswers: [
          'В комплекс работ по освоению скважин входят все перечисленные меры',
        ],
      },
      {
        code: '63681124',
        text:
          'В каком случае транспортные пути для перевозок взрывчатых материалов на территории поверхностных пунктов производства (подготовки) должны располагаться на расстоянии не менее 3 м от зданий?',
        answers: [
          'В случае необходимого безопасного их расположения от имеющихся на территории открытых огневых топок и источников открытого огня',
          'В случае, если пути предназначены для подъезда к этим зданиям',
          'В случае требуемого их расположения от зданий, где имеются открытые огневые топки и источники открытого огня или где производятся работы с открытым огнем',
          'В случае необходимого их расположения от вспомогательных зданий, находящихся на территории пункта',
        ],
        correctAnswers: [
          'В случае, если пути предназначены для подъезда к этим зданиям',
        ],
      },
      {
        code: '63681125',
        text:
          'В каком случае допускается одновременное взрывание в обоих крыльях калотты?',
        answers: [
          'В случае если дано указание лица, ответственного за проведение взрывных работ',
          'В случае если другие работы приостановлены на время проведения взрывных работ',
          'По проекту буровзрывных (взрывных) работ, согласованному с руководителем организации, выполняющей работы по строительству тоннеля',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '63681126',
        text:
          'Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих жидкостей и жидкостей, содержащих сернистый водород?',
        answers: [
          'Запрещается',
          'Разрешается по согласованию с территориальным органом Ростехнадзора',
          'Разрешается, если позволяет схема коммуникаций',
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
        ],
        correctAnswers: [
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
        ],
      },
      {
        code: '63681127',
        text:
          'Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Из визуально-ландшафтного анализа в результате проведения геодезических работ',
          'Из эскизов участка месторождений полезных ископаемых',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
          'Из инженерно-технических схем',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63681128',
        text:
          'Какое из перечисленных требований к углубленным складам взрывчатых материалов указано верно?',
        answers: [
          'Наружная дверь выработок, ведущих к углубленному складу, должна быть решетчатой, а внутренняя - металлической или деревянной',
          'Перед устьем выработки, ведущей к углубленному складу, необходимо устраивать защитный вал высотой, превышающей высоту выработки на 1 м',
          'Склад должен иметь два выхода, если расстояние от входа в углубленный склад до ближайшей камеры хранения взрывчатых материалов более 10 м',
          'Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу',
        ],
        correctAnswers: [
          'Устья выработок, ведущих к углубленному складу, должны быть оборудованы двойными дверями, открывающимися наружу',
        ],
      },
      {
        code: '63681129',
        text:
          'Каким нормативным правовым документом утверждено Положение о единой государственной системе предупреждения и ликвидации чрезвычайных ситуаций?',
        answers: [
          'Приказом Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Постановлением Министерства природных ресурсов и экологии Российской Федерации',
          'Постановлением Правительства Российской Федерации',
        ],
        correctAnswers: ['Постановлением Правительства Российской Федерации'],
      },
      {
        code: '63681130',
        text:
          'Каким должно быть расстояние между стенками стволов при строительстве шахт на месторождениях, склонных и опасных по горным ударам?',
        answers: [
          'Не менее 35 м',
          'Не менее 50 м',
          'Не менее 40 м',
          'Не менее 45 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63681131',
        text:
          'В какой орган пользователь недр подает заявление и прилагаемые к нему документы для согласования проектной документации?',
        answers: [
          'В Федеральное агентство по техническому регулированию и метрологии',
          'В территориальный орган Федеральной службы по экологическому, технологическому и атомному надзору',
          'В Федеральное агентство по недропользованию (или его территориальный орган)',
          'В Министерство строительства и жилищно-коммунального хозяйства (или его подведомственные организации)',
        ],
        correctAnswers: [
          'В Федеральное агентство по недропользованию (или его территориальный орган)',
        ],
      },
      {
        code: '63681132',
        text: 'Куда передается акт об уничтожении взрывчатых материалов?',
        answers: [
          'На склад взрывчатых материалов',
          'В территориальное подразделение Ростехнадзора',
          'В управление МЧС',
          'В отдел сбыта горнодобывающей организации',
        ],
        correctAnswers: ['На склад взрывчатых материалов'],
      },
      {
        code: '63681133',
        text:
          'Какое из перечисленных требований во время взрывания при образовании каналов, канав и котлованов указано верно?',
        answers: [
          'При формировании траншейных зарядов расстояние между экскаватором (траншеекопателем) и краном, укладывающим взрывчатые вещества в траншею, должно быть не менее 1,5 суммы радиусов разворота ковша экскаватора с вытянутой рукоятью и разворота крана',
          'В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок',
          'При заряжании линейных зарядов менее суток боевики укладываются в день производства взрыва',
          'Все перечисленные требования указаны верно',
        ],
        correctAnswers: [
          'В сложных горно-геологических условиях при взрывании неэлектрическими системами инициирования и детонирующим шнуром зарядов взрывчатых веществ группы D (кроме дымного пороха) допускается размещение удлиненных горизонтальных зарядов (траншейных, щелевых) непосредственно вслед за проведением горных выработок',
        ],
      },
      {
        code: '63681134',
        text:
          'Какими методами производится локальный прогноз удароопасности участков массива горных пород и руд, а также оценка эффективности мер предотвращения горных ударов?',
        answers: [
          'Геомеханическими методами',
          'Геофизическими методами',
          'Методом дискования керна или визуальными наблюдениями за разрушением приконтурного массива выработок',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63681135',
        text:
          'Кем утверждается проведение горных работ в пределах предохранительных целиков, предусмотренное проектами на строительство, расширение и реконструкцию предприятий по добыче полезных ископаемых?',
        answers: [
          'Руководителем проектной организации',
          'Организацией, выполняющей геодезические работы',
          'Пользователем недр',
          'Ростехнадзором',
        ],
        correctAnswers: ['Пользователем недр'],
      },
      {
        code: '63681136',
        text:
          'На каком минимальном расстоянии выше уровня почвы выемочной печи (штрека) должен быть расположен нижний уровень вентиляционной скважины на пластах средней мощности и мощных пластах?',
        answers: ['1,0 м', '0,5 м', '0,3 м', '0,8 м'],
        correctAnswers: ['0,5 м'],
      },
      {
        code: '63681137',
        text:
          'Какое из перечисленных требований к порядку присвоения и нанесения индивидуальных индексов электродетонаторов и капсюлей-детонаторов указано верно?',
        answers: [
          'Нанесение индивидуальных индексов на капсюли-детонаторы должно осуществляться после изготовления зажигательных трубок',
          'В случае появления трещин во внешней оболочке изделий они должны быть отремонтированы',
          'Нанесение индивидуальных индексов на электродетонаторы с помощью устройств обжимного типа должно производиться на 1,0 - 3,0 мм выше нижнего зига заводской обжимки гильзы (соединения капсюля-детонатора с электровоспламенителем)',
          'Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
        ],
        correctAnswers: [
          'Работы по нанесению индивидуальных индексов средств инициирования необходимо проводить в помещении, отдельном от мест хранения взрывчатых материалов',
        ],
      },
      {
        code: '63681138',
        text:
          'В каком случае разрешается ведение горных работ по добыче угля по каждому пласту для строящихся (реконструируемых) газовых шахт?',
        answers: [
          'При ведении горных работ по добыче угля по каждому пласту в нескольких уклонных полях',
          'Разрешается без ограничений',
          'При ведении горных работ по добыче угля по каждому пласту в одном уклонном поле',
          'При ведении горных работ по добыче угля по каждому пласту в двух уклонных полях',
        ],
        correctAnswers: [
          'При ведении горных работ по добыче угля по каждому пласту в одном уклонном поле',
        ],
      },
      {
        code: '63681139',
        text:
          'К какому типу кровли (по обрушаемости) будет относиться однородная кровля из слоистых преимущественно глинистых, песчано-глинистых и песчаных сланцев?',
        answers: [
          'К III типу кровли',
          'К I типу кровли',
          'К II типу кровли',
          'К IV типу кровли',
        ],
        correctAnswers: ['К I типу кровли'],
      },
      {
        code: '63681140',
        text:
          'Кому должен сообщать работник при обнаружении опасности, угрожающей людям, производственным объектам?',
        answers: [
          'Техническому руководителю смены',
          'Заместителю главного маркшейдера',
          'Главному инженеру шахты',
          'Руководителю предприятия',
        ],
        correctAnswers: ['Техническому руководителю смены'],
      },
      {
        code: '63681141',
        text:
          'Кто на каждом объекте, отрабатывающем месторождения, склонные и опасные по горным ударам, производит учет всех случаев горных ударов, микроударов и толчков, стреляний, интенсивного заколообразования и шелушения, результатов определения категорий удароопасности выработок, примененных мер по предотвращению горных ударов с оценкой их эффективности?',
        answers: [
          'Технический руководитель организации, осуществляющей горные работы',
          'Работник управления государственной экспертизы',
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
        correctAnswers: [
          'Служба прогноза и предотвращения горных ударов или маркшейдерско-геологическая служба',
        ],
      },
      {
        code: '63681142',
        text:
          'Какое количество приемов допускается при проведении взрывания по породе выработок, в которых отсутствует выделение метана, с применением электродетонаторов мгновенного, короткозамедленного и замедленного действия со временем замедления до 2 секунд?',
        answers: [
          'Не более трех приемов',
          'Не более одного приема',
          'Не более двух приемов',
          'Без ограничения количества приемов',
        ],
        correctAnswers: ['Без ограничения количества приемов'],
      },
      {
        code: '63681143',
        text:
          'Что из перечисленного допускается в процессе пневмотранспортирования или пневмозаряжания?',
        answers: [
          'Одновременно производить в одном забое ручное формирование основного заряда с применением высокочувствительных к механическим воздействиям взрывчатых веществ и пневматическое заряжание',
          'Расстилать брезент на месте заряжания под восходящими скважинами',
          'Смешивать два и более типа взрывчатых веществ',
          'Производить одновременное заряжание взрывчатых веществ, содержащих в своем составе тротил или алюминиевую пудру',
        ],
        correctAnswers: [
          'Расстилать брезент на месте заряжания под восходящими скважинами',
        ],
      },
      {
        code: '63681144',
        text:
          'Какой должна быть расчетная продолжительность процесса цементирования обсадной колонны?',
        answers: [
          'Не должна превышать 60 % времени от времени начала лабораторного анализа',
          'Не должна превышать 75 % времени начала загустевания тампонажного раствора',
          'Не должна превышать 70 % времени от начала проведения процесса цементирования',
          'Не должна превышать 85 % времени начала загустевания цементного раствора',
        ],
        correctAnswers: [
          'Не должна превышать 75 % времени начала загустевания тампонажного раствора',
        ],
      },
      {
        code: '63681145',
        text:
          'На каких уровнях действует Единая государственная система предупреждения и ликвидации чрезвычайных ситуаций?',
        answers: [
          'На федеральном уровне и уровне субъектов Российской Федерации',
          'На федеральном и межрегиональном уровнях',
          'На региональном и муниципальном уровнях',
          'На федеральном, межрегиональном, региональном, муниципальном и объектовом уровнях',
        ],
        correctAnswers: [
          'На федеральном, межрегиональном, региональном, муниципальном и объектовом уровнях',
        ],
      },
      {
        code: '63681146',
        text:
          'После каких работ в стволах должна проводиться профилировка проводников?',
        answers: [
          'После процесса перемещения технических устройств в вертикальной горной выработке',
          'После пуска вновь навешенного или отремонтированного подъемного сосуда (противовеса)',
          'После ремонта, связанного с заменой армировки или проводников',
          'После работ по проходке и углубке ствола с армированием',
        ],
        correctAnswers: [
          'После ремонта, связанного с заменой армировки или проводников',
        ],
      },
      {
        code: '63681147',
        text:
          'Какие меры должны приниматься в случае производства на скважине работ, требующих давлений, превышающих давления опрессовки обсадной колонны?',
        answers: [
          'На устье устанавливается насосно-компрессорная труба, эксплуатационная колонна защищается специальным составом',
          'На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера',
          'На устье скважины устанавливается оборудование для герметизации',
          'Проведение данных работ запрещается технической документацией',
        ],
        correctAnswers: [
          'На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера',
        ],
      },
      {
        code: '63681148',
        text:
          'На каком расстоянии устанавливаются основной и вспомогательный пульты управления превенторами и гидравлическими задвижками?',
        answers: [
          'Основной пульт управления - на расстоянии не менее 2 м от устья скважины, вспомогательный - непосредственно возле превентора',
          'Основной пульт управления - на расстоянии не менее 1 м от устья скважины, вспомогательный - на расстоянии не менее 0, 5 м от пульта бурильщика',
          'Основной пульт управления - на расстоянии не менее 6 м от устья скважины в любом безопасном месте, вспомогательный - непосредственно возле бурового оборудования',
          'Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика',
        ],
        correctAnswers: [
          'Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика',
        ],
      },
      {
        code: '63681149',
        text:
          'Каким образом должны быть окрашены заземляющие проводники, предназначенные для защиты от статического электричества, в местах присоединения к технологическому оборудованию и внутреннему контуру заземления при взрывных работах?',
        answers: [
          'Одной поперечной полосой шириной 10 мм зеленого цвета',
          'Одной поперечной полосой шириной 15 мм красного цвета',
          'Двумя поперечными полосами шириной 15 мм красного цвета',
          'Одной поперечной полосой шириной 10 мм синего цвета',
        ],
        correctAnswers: [
          'Одной поперечной полосой шириной 15 мм красного цвета',
        ],
      },
      {
        code: '63681150',
        text:
          'Куда направляют перечень пластов, склонных к самовозгоранию, после его утверждения? Укажите все правильные ответы.',
        answers: [
          'В главное управление агентства геодезии и картографии',
          'В территориальное подразделение государственного пожарного надзора',
          'В территориальный орган местного самоуправления',
          'В территориальный орган Ростехнадзора',
          'В ПАСС(Ф), обслуживающее шахту',
        ],
        correctAnswers: [
          'В территориальный орган Ростехнадзора',
          'В ПАСС(Ф), обслуживающее шахту',
        ],
      },
      {
        code: '63681151',
        text: 'Что из перечисленного не подлежит заземлению в шахтах?',
        answers: [
          'Пневматические вентиляторы',
          'Одиночные металлические воздухопроводы',
          'Трубопроводы, расположенные в горных выработках, где имеются электрические установки',
          'Металлическая крепь, пожарооросительный трубопровод, нетоковедущие рельсы, металлические устройства для подвески кабеля',
        ],
        correctAnswers: [
          'Металлическая крепь, пожарооросительный трубопровод, нетоковедущие рельсы, металлические устройства для подвески кабеля',
        ],
      },
      {
        code: '63681152',
        text:
          'Что должно быть указано на корпусах оборудования, входящего в состав талевой системы (кронблок, талевый блок, крюк)?',
        answers: [
          'Длительность срока службы',
          'Количество проведенных испытаний',
          'Допускаемая грузоподъемность',
          'Тип талевой системы',
        ],
        correctAnswers: ['Допускаемая грузоподъемность'],
      },
      {
        code: '63681153',
        text:
          'С какой периодичностью и в каком объеме проводятся исследования эксплуатационных скважин на нефтегазодобывающих объектах?',
        answers: [
          'Ежемесячно, в соответствии с утвержденным планом работ по разработке месторождений',
          'В сроки и объемы, указанные в методических рекомендациях по проектированию разработки нефтяных и газонефтяных месторождений',
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
        ],
        correctAnswers: [
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
        ],
      },
      {
        code: '63681154',
        text:
          'После выполнения каких работ разрешается опускать щитовое перекрытие при разработке мощных пластов?',
        answers: [
          'Только после обрушения потолочины над щитом для создания предохранительной подушки высотой не менее мощности пласта',
          'Только после оборудования щитового перекрытия предохранительными средствами (канатами, трапами, решетками)',
          'Только после монтажа не менее одной секции следующего щитового перекрытия (за исключением последнего щитового столба на выемочном участке)',
          'После выполнения всех перечисленных работ',
        ],
        correctAnswers: ['После выполнения всех перечисленных работ'],
      },
      {
        code: '63681155',
        text:
          'Что устанавливают требования Инструкции о порядке утверждения мер охраны зданий, сооружений и природных объектов от вредного влияния горных разработок?',
        answers: [
          'Меры охраны, предусматривающие оставление предохранительных целиков под подрабатываемыми объектами',
          'Меры охраны, применяемые в порядке опытно-промышленных испытаний',
          'Меры, выбранные в соответствии с правилами охраны сооружений и природных объектов от вредного влияния подземных разработок на месторождениях с недостаточно изученным характером процесса сдвижения горных пород',
          'Порядок согласования и утверждения мер охраны зданий, сооружений и природных объектов от вредного влияния горных разработок, обусловленного сдвижением и деформацией горных пород и земной поверхности, получения разрешений на проходку подготовительных выработок в предохранительных целиках и на ведение в них очистных работ',
        ],
        correctAnswers: [
          'Порядок согласования и утверждения мер охраны зданий, сооружений и природных объектов от вредного влияния горных разработок, обусловленного сдвижением и деформацией горных пород и земной поверхности, получения разрешений на проходку подготовительных выработок в предохранительных целиках и на ведение в них очистных работ',
        ],
      },
      {
        code: '63681156',
        text:
          'Какие радиусы закругления рельсовых путей и переводных кривых во вновь вводимых горных выработках устанавливаются для колеи 600 мм и 900 мм?',
        answers: [
          'Не менее 10 м и не менее 15 м соответственно',
          'Не менее 8 м и не менее 12 м соответственно',
          'Не менее 12 м и не менее 20 м соответственно',
          'Не менее 5 м и не менее 8 м соответственно',
        ],
        correctAnswers: ['Не менее 12 м и не менее 20 м соответственно'],
      },
      {
        code: '63681157',
        text:
          'Какие сведения содержит титульный лист проектной документации на разработку месторождений твердых полезных ископаемых, ликвидацию и консервацию горных выработок и первичную переработку минерального сырья?',
        answers: [
          'Только название месторождения (участка)',
          'Только наименование пользователя недр',
          'Только наименование организации-исполнителя, грифы согласования и утверждения документации',
          'Только наименование, место и год составления проектной документации',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63681158',
        text:
          'Максимально допустимая концентрация какого газа в рудничной атмосфере действующих горных выработок угольных шахт составляет 2 мг/м³?',
        answers: [
          'Оксида углерода',
          'Сернистого ангидрида',
          'Диоксида азота',
          'Сероводорода',
        ],
        correctAnswers: ['Диоксида азота'],
      },
      {
        code: '63681159',
        text:
          'Чем осуществляются вскрытие и подготовка пластов угля, склонных к самовозгоранию?',
        answers: [
          'Горными выработками, пройденными по породам или по пластам угля, с применением мер, обеспечивающих безопасное ведение горных работ',
          'Полевыми выработками',
          'Горными выработками, предусмотренными техническим проектом на разработку месторождений',
          'Выработками, вид которых согласован с надзорными органами',
        ],
        correctAnswers: [
          'Горными выработками, пройденными по породам или по пластам угля, с применением мер, обеспечивающих безопасное ведение горных работ',
        ],
      },
      {
        code: '63681160',
        text:
          'В каком случае должно проводиться сотрясательное взрывание при отработке пластов, опасных по внезапным выбросам угля, породы и газа? Укажите все правильные ответы.',
        answers: [
          'При проведении восстающих выработок с углом наклона 15°',
          'При вскрытии песчаников на глубине 300 м, если прогноз выбросоопасности перед вскрытием не осуществлялся',
          'При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился',
          'При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м',
        ],
        correctAnswers: [
          'При вскрытии угрожаемых угольных пластов, если прогнозом установлены опасные значения показателей выбросоопасности или прогноз перед вскрытием не проводился',
          'При вскрытии выбросоопасных угольных пластов мощностью более 0,1 м',
        ],
      },
      {
        code: '63681161',
        text:
          'Какие из перечисленных мер охраны объектов допускаются, если другие меры охраны не могут гарантировать нормальную эксплуатацию охраняемого объекта или являются экономически нецелесообразными?',
        answers: [
          'Применение различных способов управления кровлей',
          'Выбор скорости подвигания очистного забоя',
          'Неполная выемка полезного ископаемого по площади',
          'Укрепление уступов, уменьшение углов откосов отвалов на карьерах',
          'Оставление предохранительного целика полезного ископаемого',
        ],
        correctAnswers: [
          'Оставление предохранительного целика полезного ископаемого',
        ],
      },
      {
        code: '63681162',
        text:
          'В течение какого срока в организации хранятся проекты буровзрывных (взрывных) работ, паспорта, схемы, в соответствии с которыми осуществляются взрывные работы?',
        answers: [
          'Не менее 10 лет',
          'Не менее 5 лет',
          'Не менее года',
          'Не менее 2 лет',
        ],
        correctAnswers: ['Не менее года'],
      },
      {
        code: '63681163',
        text:
          'В каких случаях главный маркшейдер шахты определяет подрабатываемый участок земной поверхности, устанавливает порядок контроля образования провалов и организовывает контроль?',
        answers: [
          'При глубине залегания угольных пластов не более 100 м',
          'До начала осуществления гидравлической добычи угля',
          'До начала отработки угольных пластов',
          'При отработке свиты пластов подземным способом',
        ],
        correctAnswers: ['До начала отработки угольных пластов'],
      },
      {
        code: '63681164',
        text:
          'Какой должна быть допустимая скорость движения автомобилей, автомобильных и тракторных поездов на технологических дорогах карьера?',
        answers: [
          '40 км/ч',
          'Не более 25 км/ч',
          'Скорость устанавливается техническим руководителем организации',
          '30 км/ч',
        ],
        correctAnswers: [
          'Скорость устанавливается техническим руководителем организации',
        ],
      },
      {
        code: '63681165',
        text:
          'Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?',
        answers: [
          'Представители территориального органа государственного горного надзора',
          'Лица, ответственные за организацию маркшейдерских работ',
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр, и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
          'Лица, ответственные за руководство геологическими работами',
        ],
        correctAnswers: [
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр, и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63681166',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Специалистом управления геодезии и картографии',
          'Руководителем геологической проектной организации',
          'Техническим руководителем маркшейдерской службы',
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63681167',
        text:
          'На какое минимальное расстояние от скважины должны быть убраны буровые установки, не имеющие приспособления для заряжания, в сложных горно-геологических условиях?',
        answers: ['5 м', '20 м', '15 м', '10 м'],
        correctAnswers: ['10 м'],
      },
      {
        code: '63681168',
        text:
          'Какие документы могут не прилагаться к заявлению пользователем недр при подаче проектной документации на согласование?',
        answers: [
          'Копия предыдущего решения комиссии или уполномоченного органа',
          'Сведения о наличии в штате работника, имеющего высшее профессиональное образование по специальности "маркшейдерское дело" (имеющего высшее профессиональное образование и прошедшего профессиональную переподготовку с получением квалификации по указанной специальности), аттестованного в области промышленной безопасности',
          'Копия лицензии на пользование участком недр, в пределах которого находится месторождение полезного ископаемого, с приложениями к ней',
          'Копия заключения государственной экспертизы запасов полезных ископаемых и подземных вод, геологической информации о предоставляемых в пользование участках недр (за исключением запасов углеводородного сырья) - в случаях, предусмотренных законодательством Российской Федерации',
          'Проектная документация',
        ],
        correctAnswers: [
          'Сведения о наличии в штате работника, имеющего высшее профессиональное образование по специальности "маркшейдерское дело" (имеющего высшее профессиональное образование и прошедшего профессиональную переподготовку с получением квалификации по указанной специальности), аттестованного в области промышленной безопасности',
        ],
      },
      {
        code: '63681169',
        text:
          'На каком расстоянии от устья скважины располагаются передвижные насосные установки и каким должно быть расстояние между ними?',
        answers: [
          'Не менее 20 м от устья скважины, расстояние между ними должно быть не менее 1 м',
          'Не менее 15 м от устья скважины, расстояние между ними должно быть не менее 1,5 м',
          'Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м',
          'Не менее 5 м от устья скважины, расстояние между ними должно быть не менее 2 м',
        ],
        correctAnswers: [
          'Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м',
        ],
      },
      {
        code: '63681170',
        text:
          'Что необходимо предпринять в процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений?',
        answers: [
          'Необходимо ввести промывочные жидкости, инертные по отношению к горным породам',
          'Следует производить долив бурового раствора в скважину',
          'Следует ввести в промывочную жидкость смазывающие добавки',
          'Необходимо обеспечить большую скорость восходящего потока жидкости в кольцевом пространстве',
        ],
        correctAnswers: [
          'Следует производить долив бурового раствора в скважину',
        ],
      },
      {
        code: '63681171',
        text:
          'Каким уполномоченным органом не является Федеральная служба по экологическому, технологическому и атомному надзору?',
        answers: [
          'Органом федерального государственного надзора в области промышленной безопасности',
          'Органом государственного горного надзора',
          'Органом федерального государственного строительного надзора',
          'Органом федерального государственного надзора в области использования атомной энергии',
          'Органом федерального государственного экологического контроля',
        ],
        correctAnswers: [
          'Органом федерального государственного экологического контроля',
        ],
      },
      {
        code: '63681172',
        text:
          'Работа каких конвейеров, транспортирующих пожаровзрывоопасные вещества, допускается без устройства блокировочных устройств?',
        answers: [
          'Ленточных конвейеров',
          'Цепных конвейеров',
          'Винтовых конвейеров',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681173',
        text:
          'В каких местах при взрывных работах могут использоваться слежавшиеся порошкообразные взрывчатые вещества, содержащие гексоген или жидкие нитроэфиры, без размятия или измельчения?',
        answers: [
          'В шахтах (рудниках), не опасных по газу',
          'На земной поверхности',
          'В шахтах (рудниках), разрабатывающих пласты (рудные тела), не опасные по взрывам пыли',
          'В шахтах, отрабатывающих мощные месторождения',
        ],
        correctAnswers: ['На земной поверхности'],
      },
      {
        code: '63681174',
        text:
          'В каких количествах разрешается размещать взрывчатые вещества непосредственно у зарядного оборудования?',
        answers: [
          'Не более 5-сменной производительности оборудования',
          'Не более 3-сменной производительности оборудования',
          'Не более 2-сменной производительности оборудования',
          'Не более сменной производительности оборудования',
        ],
        correctAnswers: ['Не более сменной производительности оборудования'],
      },
      {
        code: '63681175',
        text:
          'Какие объекты, в соответствии с Федеральным законом "О промышленной безопасности опасных производственных объектов", относятся к категории опасных производственных объектов при пользовании недрами?',
        answers: [
          'Только те, на которых производится добыча общераспространенных полезных ископаемых без осуществления взрывных работ',
          'Только те, на которых производится добыча общераспространенных полезных ископаемых открытым способом',
          'Только те, на которых ведутся горные работы (за исключением добычи общераспространенных полезных ископаемых и разработки россыпных месторождений полезных ископаемых, осуществляемых открытым способом без применения взрывных работ), работы по обогащению полезных ископаемых',
          'Только те, на которых ведутся работы по частичному обогащению полезных ископаемых',
        ],
        correctAnswers: [
          'Только те, на которых ведутся горные работы (за исключением добычи общераспространенных полезных ископаемых и разработки россыпных месторождений полезных ископаемых, осуществляемых открытым способом без применения взрывных работ), работы по обогащению полезных ископаемых',
        ],
      },
      {
        code: '63681176',
        text:
          'Разрешается ли одновременное производство работ в наклонных выработках на различных отметках?',
        answers: [
          'Разрешается по согласованию с надзорным органом',
          'Разрешается при отметке в наряде на производство работ',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63681177',
        text: 'Куда должна передаваться информация о загазировании?',
        answers: [
          'Только в территориальный орган Ростехнадзора',
          'В территориальный орган Ростехнадзора и в Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Только в Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
        ],
        correctAnswers: ['Только в территориальный орган Ростехнадзора'],
      },
      {
        code: '63681178',
        text:
          'При какой толщине породной пробки между забоем выработки и крутым пластом (пропластком) необходимо производить вскрытие и пересечение пластов при помощи буровзрывных работ?',
        answers: [
          'Не менее 1 м',
          'Не менее 2 м',
          'Не менее 4 м',
          'Не менее 3 м',
        ],
        correctAnswers: ['Не менее 2 м'],
      },
      {
        code: '63681179',
        text:
          'Что из перечисленного целесообразно отражать в инструкции по ликвидации отказавших зарядов взрывчатых веществ при строительстве гидротехнических сооружений?',
        answers: [
          'Только порядок сбора, учета и уничтожения остатков взрывчатых материалов, извлеченных при ликвидации отказа, мероприятия по безопасности работ',
          'Только величину радиуса опасной зоны при ликвидации отказа, порядок ее обозначения на местности и в подземных выработках, а также ее охране, организацию работ по ликвидации отказов',
          'Только основные мероприятия по предупреждению отказавших зарядов, порядок обнаружения невзорвавшихся зарядов, методы ликвидации отказов для каждого вида взрывных работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681180',
        text:
          'Где следует устанавливать башмак обсадной колонны, перекрывающий породы, склонные к текучести?',
        answers: [
          'На уровне кровли',
          'На первой трубе обсадной колонны для предупреждения смятия торца нижней колонны',
          'Ниже их подошвы или в плотных пропластках',
        ],
        correctAnswers: ['Ниже их подошвы или в плотных пропластках'],
      },
      {
        code: '63681181',
        text:
          'Каким образом должно осуществляться управление запорной арматурой скважины, оборудованной под нагнетание пара или горячей воды?',
        answers: [
          'Ручным способом, с применением средств защиты',
          'Дистанционно и методом ручного управления с использованием защитных средств',
          'Только дистанционно',
        ],
        correctAnswers: ['Только дистанционно'],
      },
      {
        code: '63681182',
        text:
          'Разрешается ли исследование разведочных и эксплуатационных скважин в случае отсутствия утилизации жидкого продукта?',
        answers: [
          'Разрешается без ограничений',
          'Разрешается по решению руководителя горнодобывающего предприятия',
          'Разрешается по согласованию с территориальным органом Ростехнадзора',
          'Запрещается во всех случаях',
        ],
        correctAnswers: ['Запрещается во всех случаях'],
      },
      {
        code: '63681183',
        text:
          'Требованиями к структуре проектной документации по первичной переработке минерального сырья являются:',
        answers: [
          'Только проектная мощность предприятия по первичной переработке минерального сырья',
          'Только мероприятия по использованию отходов обогащения полезных ископаемых, их размещение, складирование и сохранение',
          'Только качественная характеристика минерального сырья, поступающего на первичную переработку, сведения о сырьевой базе и потребителях продуктов обогащения',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681184',
        text:
          'Каким образом направляются заявление о предоставлении лицензии и прилагаемые к нему документы соискателем лицензии в лицензирующий орган?',
        answers: [
          'Только лично заявителем в лицензирующий орган',
          'Только в форме электронного документа с помощью интернет-сервиса',
          'Подачей документов в лицензирующий орган представителем лицензиата, действующего по доверенности',
          'Представляются непосредственно в лицензирующий орган или направляются заказным почтовым отправлением с уведомлением о вручении или в форме электронных документов (пакета электронных документов), подписанных усиленной квалифицированной электронной подписью соискателя лицензии',
        ],
        correctAnswers: [
          'Представляются непосредственно в лицензирующий орган или направляются заказным почтовым отправлением с уведомлением о вручении или в форме электронных документов (пакета электронных документов), подписанных усиленной квалифицированной электронной подписью соискателя лицензии',
        ],
      },
      {
        code: '63681185',
        text:
          'На какой минимальной высоте от уровня пола должны быть размещены коммуникации: трубы, желоба над рабочими площадками?',
        answers: ['1,8 м', '2,0 м', '1,5 м', '2,2 м', '1,7 м'],
        correctAnswers: ['2,0 м'],
      },
      {
        code: '63681186',
        text:
          'Каким должно быть свободное расстояние от наиболее выступающих частей механических и ручных приводов стрелочных переводов откаточных путей до кромки подвижного состава?',
        answers: [
          'Не менее 0,7 м',
          'Не менее 0,6 м',
          'От 0,5 м до 0,65 м',
          'От 0,65 м до 0,7 м',
        ],
        correctAnswers: ['Не менее 0,7 м'],
      },
      {
        code: '63681187',
        text:
          'В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 3 дня до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В течение 10 дней с момента подготовки всей необходимой документации',
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За неделю до указанного дня рассмотрения планов и (или) схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
      },
      {
        code: '63681188',
        text:
          'С какой периодичностью индивидуальные предприниматели и работники юридического лица, осуществляющие производство маркшейдерских работ, должны проходить повышение квалификации?',
        answers: [
          'Ежегодно',
          'По мере необходимости, в связи с изменением технологического процесса',
          'Не реже одного раза в три года',
          'Один раз в пять лет',
          'Не реже двух раз в год',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63681189',
        text:
          'В каком документе следует предусматривать мероприятия по контролю герметичности объекта хранения в процессе строительства и эксплуатации опасных производственных объектов подземных хранилищ газа?',
        answers: [
          'В проекте на консервацию и ликвидацию опасных производственных объектов подземных хранилищ газа',
          'В технологическом проекте на создание и эксплуатацию опасных производственных объектов подземных хранилищ газа',
          'В проектной документации полигона захоронения промышленных стоков, образующихся при эксплуатации подземных хранилищ газа',
        ],
        correctAnswers: [
          'В технологическом проекте на создание и эксплуатацию опасных производственных объектов подземных хранилищ газа',
        ],
      },
      {
        code: '63681190',
        text:
          'В каком случае запрещается приступать к спуску технических и эксплуатационных колонн в скважину?',
        answers: [
          'Только если в скважине имеются осложнения с затяжками и посадками бурильной колонны',
          'Только если скважина имеет осложнения, вызванные осыпями и обвалами, до ликвидации данных осложнений',
          'Только если скважина осложнена поглощением бурового раствора с одновременным флюидопроявлением',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63681191',
        text:
          'При каких условиях допускается повторное использование рабочего проекта при бурении группы скважин на идентичных по геолого-техническим условиям площадях?',
        answers: [
          'При идентичности горно-геологических условий площадей разработки и частков месторождений',
          'При отличии плотности бурового раствора от проектной в пределах ± 0,3 г/см³',
          'При одинаковых проектных глубинах по стволу скважин',
          'При одинаковом назначении и конструкции скважин',
          'При выполнении всех перечисленных условий',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63681192',
        text:
          'В каком случае допускается использовать для освещения в углубленном складе индивидуальные рудничные аккумуляторные светильники при отсутствии стационарных источников электроэнергии?',
        answers: [
          'По решению работника склада при отсутствии других источников освещения',
          'При толще грунта менее 8 м над углубленным складом',
          'Не допускается ни в каком случае',
          'По разрешению руководителя (технического руководителя) организации',
        ],
        correctAnswers: [
          'По разрешению руководителя (технического руководителя) организации',
        ],
      },
      {
        code: '63681193',
        text:
          'Какие условия во временных складах взрывчатых материалов указаны верно? Укажите все правильные ответы.',
        answers: [
          'Устройство противопожарных водоемов (резервуаров, скважин) необязательно',
          'Устройство тамбуров не обязательно, двери могут быть одинарными',
          'Деревянные стены и крыши могут не покрываться огнезащитным составом',
          'Рабочее освещение внутри хранилищ не допускается осуществлять рудничными аккумуляторными светильниками',
        ],
        correctAnswers: [
          'Устройство противопожарных водоемов (резервуаров, скважин) необязательно',
          'Устройство тамбуров не обязательно, двери могут быть одинарными',
        ],
      },
      {
        code: '63681194',
        text:
          'С какой периодичностью угледобывающие организации определяют склонность отрабатываемых пластов к самовозгоранию?',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в год',
          'Периодичность проверки определяет главный инженер шахты',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '63681195',
        text:
          'Какое расстояние должно соблюдаться при креплении скважин от блок-манифольдов до агрегатов?',
        answers: [
          'Не менее 2 м',
          'Не менее 5 м',
          'Не менее 1,5 м',
          'Не менее 3 м',
        ],
        correctAnswers: ['Не менее 5 м'],
      },
      {
        code: '63681196',
        text:
          'Какие требования предъявляются к условиям установки подвесного и герметизирующего устройства потайной колонны?',
        answers: [
          'Подвесное и герметизирующее устройство нецементируемого хвостовика, устанавливаемого в продуктивном пласте, должно располагаться ниже башмака предыдущей обсадной колонны',
          'Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин',
          'Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 150 м для всех видов скважин',
        ],
        correctAnswers: [
          'Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин',
        ],
      },
      {
        code: '63681197',
        text:
          'Какие из перечисленных расстояний, относящихся к лестницам в лестничных отделениях угольных шахт, указаны верно?',
        answers: [
          'Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м',
          'Расстояние между крепью горной выработки и лестницей у ее основания должно быть не менее 0,5 м',
          'В горизонтальных полках для свободного прохода должны быть установлены лазы шириной не менее 0,5 м и высотой не менее 0,6 м',
          'В лестничных отделениях через каждые 10 м должны устанавливаться горизонтальные полки',
        ],
        correctAnswers: [
          'Ширина лестниц должна быть не менее 0,4 м, а расстояние между ступенями - не более 0,4 м',
        ],
      },
      {
        code: '63681198',
        text:
          'В каком случае буровые работы можно производить без применения дополнительных мер безопасности?',
        answers: [
          'В случае производства буровых работ в многолетнемерзлых грунтах',
          'Все буровые работы производятся только с применением дополнительных мер безопасности',
          'В случае производства буровых работ с кустовых площадок скважин',
          'В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3% об. сероводорода',
        ],
        correctAnswers: [
          'В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3% об. сероводорода',
        ],
      },
      {
        code: '63681199',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений пересечение трубопроводов с токсичными жидкостями и газами с железнодорожными подъездными путями?',
        answers: [
          'Допускается если соблюдены все меры безопасности',
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
          'Допускается при условии прокладки их вдоль стены, перекрытия которой имеют максимальный предел огнестойкости',
          'Не допускается ни в каком случае',
        ],
        correctAnswers: [
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
        ],
      },
      {
        code: '63681200',
        text:
          'Каковы требования к производству работ по глубинным измерениям в скважинах с избыточным давлением на устье?',
        answers: [
          'Работы производятся с применением многокомпонентного состава утяжеленного бурового раствора',
          'Производятся измерительные работы в соответствии с условиями заказчика',
          'Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны',
        ],
        correctAnswers: [
          'Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны',
        ],
      },
      {
        code: '63681201',
        text:
          'Что организуют при вскрытии газоносных угольных пластов горными выработками?',
        answers: [
          'Ежесменный контроль содержания сероводорода в месте вскрытия',
          'Постоянный контроль содержания диоксида углерода в месте вскрытия',
          'Непрерывный контроль содержания метана в месте вскрытия',
          'Контроль содержания серного газа при вскрытии',
        ],
        correctAnswers: [
          'Непрерывный контроль содержания метана в месте вскрытия',
        ],
      },
      {
        code: '63681202',
        text:
          'Может ли "газовый режим" распространяться на отдельные участки, блоки, пласты или на шахту в целом?',
        answers: [
          'Только на шахту в целом',
          'Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)',
          'Только на горные выработки всего месторождения',
        ],
        correctAnswers: [
          'Может распространяться на отдельные рабочие зоны (участки, блоки и т. д.)',
        ],
      },
      {
        code: '63681203',
        text:
          'В какой период осуществляется рассмотрение планов развития горных работ?',
        answers: [
          'В период с 1 по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 10 января по 31 марта текущего года в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 15 по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
        correctAnswers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
      },
      {
        code: '63681204',
        text:
          'В каком случае оборудование и здание пунктов производства и подготовки взрывчатых веществ в целом должны быть полностью освобождены от взрывоопасных продуктов?',
        answers: [
          'В случае невозможности обеспечения круглосуточного наблюдения',
          'Ни в каком случае',
          'При остановке работы пункта производства на более чем 5 часов',
          'При остановке пункта на период более двух суток',
        ],
        correctAnswers: ['При остановке пункта на период более двух суток'],
      },
      {
        code: '63681205',
        text:
          'Какой следует принимать расчетную несущую способность анкера Nа для горных выработок и сопряжений со сроком службы свыше 40 лет и в обводненных породах?',
        answers: [
          'Следует принимать в соответствии с проектом производства работ, с учетом расчетных смещений кровли',
          'Следует принимать в соответствии с технической документацией, с учетом расчетной несущей способности анкерных стержней',
          'Следует принимать на 20 % меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
        ],
        correctAnswers: [
          'Следует принимать на 20 % меньше значений, регламентированных в ФНП "Инструкция по расчету и применению анкерной крепи на угольных шахтах"',
        ],
      },
      {
        code: '63681206',
        text:
          'Какое из перечисленных требований запрещается при ведении взрывных работ на угольных шахтах и рудниках, опасных по газу и (или) пыли? Укажите все правильные ответы.',
        answers: [
          'Размещать в одном шпуре взрывчатые вещества различных классов',
          'Размещать в одном шпуре при сплошном заряде более одного патрона-боевика',
          'Устанавливать шпуры при взрывании по углю и породе на глубине 0,7 м',
          'Вводить в шпур одновременно заряд, состоящий из двух и более патронов взрывчатого вещества',
        ],
        correctAnswers: [
          'Размещать в одном шпуре взрывчатые вещества различных классов',
          'Размещать в одном шпуре при сплошном заряде более одного патрона-боевика',
        ],
      },
      {
        code: '63681207',
        text:
          'Допускаются ли вибрация и гидравлические удары в нагнетательных коммуникациях?',
        answers: [
          'Допускаются при расчете гидравлического удара в нагнетательных трубопроводах с учетом влияния времени регулирования',
          'Допускаются в пределах установленных в планах работ',
          'Не допускаются',
          'Допускаются при нахождении работников в безопасной зоне',
        ],
        correctAnswers: ['Допускаются в пределах установленных в планах работ'],
      },
      {
        code: '63681208',
        text:
          'В каких хранилищах взрывчатых материалов запрещается пользоваться открытым огнем?',
        answers: [
          'В хранилищах калиевой селитры',
          'В хранилищах аммиачной селитры',
          'В хранилищах натриевой селитры',
        ],
        correctAnswers: ['В хранилищах аммиачной селитры'],
      },
      {
        code: '63681209',
        text:
          'При каких горно-геологических и горнотехнических условиях угольных шахт не применяется порядок расчета, регламентированный Инструкцией по расчету и применению анкерной крепи на угольных шахтах?',
        answers: [
          'При прочности на одноосное сжатие оставляемой угольной пачки в кровле горной выработки или сопряжения не менее 6 МПа',
          'При поддержании горизонтальных и наклонных подземных горных выработок в полностью надработанном и подработанном массивах на расстояниях, больше ширины выработки и двенадцати мощностей пласта',
          'При максимальной расчетной ширине выработки или сопряжения не более 12 м',
          'При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород',
        ],
        correctAnswers: [
          'При проведении горных выработок в зонах разрывных геологических нарушений, при наличии выветренных, пластичных и мерзлых вмещающих пород',
        ],
      },
      {
        code: '63681210',
        text:
          'Кем осуществляются подготовка и утверждение планов и схем развития горных работ?',
        answers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
          'Подготовка - пользователем недр, утверждение - руководителем территориального органа государственного контроля за недропользованием',
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - руководителем территориального органа государственного горного надзора',
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - представителем местной администрации',
        ],
        correctAnswers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
        ],
      },
      {
        code: '63681211',
        text:
          'Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?',
        answers: [
          'Органами исполнительной власти субъектов Российской Федерации',
          'Органом государственного горного надзора',
          'Региональным органом государственного экологического надзора',
        ],
        correctAnswers: ['Органом государственного горного надзора'],
      },
      {
        code: '63681212',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?',
        answers: [
          'Несоблюдение требований законодательства в части предупреждения и устранения вредного влияния горных работ на население, окружающую среду, здания и сооружения',
          'Несоответствие планов и схем развития горных работ требованиям органов государственного горного надзора к их подготовке, содержанию и оформлению, а также выявление в них недостоверных сведений',
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
          'Отсутствие геологического и маркшейдерского обеспечения горных работ, необходимого для достоверного учета параметров горных разработок и прогнозирования опасных ситуаций',
        ],
        correctAnswers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63681213',
        text:
          'За какой срок до начала подработки охраняемых объектов горные и строительные меры охраны от вредного влияния горных разработок должны быть согласованы и утверждены?',
        answers: [
          'За один месяц',
          'За пять месяцев',
          'За шесть месяцев',
          'За два месяца',
        ],
        correctAnswers: ['За шесть месяцев'],
      },
      {
        code: '63681214',
        text:
          'Кому должны быть сданы остатки взрывчатых материалов взрывниками по окончании взрывных работ?',
        answers: [
          'Лично техническому руководителю шахты',
          'Руководителю взрывных работ',
          'Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)',
          'Кладовщику на склад',
        ],
        correctAnswers: [
          'Лично лицу, ответственному за выдачу и приемку взрывчатых материалов (раздатчику, заведующему складом взрывчатых материалов)',
        ],
      },
      {
        code: '63681215',
        text:
          'Кто принимает решение о разработке оперативных планов по локализации и ликвидации последствий аварий?',
        answers: [
          'Главный маркшейдер шахты',
          'Руководитель работ по ликвидации аварии',
          'Представитель территориального органа Ростехнадзора',
          'Руководитель горнодобывающей организации',
        ],
        correctAnswers: ['Руководитель работ по ликвидации аварии'],
      },
      {
        code: '63681216',
        text: 'К какому классу относится "Неустойчивая кровля"?',
        answers: ['К I классу', 'К III классу', 'К II классу'],
        correctAnswers: ['К I классу'],
      },
      {
        code: '63681217',
        text:
          'Кем и с какой периодичностью осматриваются помещения и площадки, где проводится обработка металлов, рассчитанные на взрыв максимально допустимого заряда?',
        answers: [
          'Руководителем взрывных работ ежемесячно',
          'Техническим руководителем организации ежеквартально',
          'Инспектором территориального органа Ростехнадзора ежегодно',
          'Комиссией организации ежегодно',
        ],
        correctAnswers: ['Комиссией организации ежегодно'],
      },
      {
        code: '63681218',
        text:
          'Какое из перечисленных требований к транспортированию взрывчатых материалов указано верно? Укажите все правильные ответы.',
        answers: [
          'Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди',
          'При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика',
          'Разрешается спускаться или подниматься в одной клети только одному взрывнику или подносчику с сумками с взрывчатыми материалами',
          'Транспортирование взрывчатых материалов по подземным выработкам должно осуществляться со скоростью не более 10 м/с',
        ],
        correctAnswers: [
          'Спуск-подъем взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами должен проводиться вне очереди',
          'При спуске-подъеме взрывников с взрывчатыми материалами и подносчиков с взрывчатыми веществами по наклонным выработкам в людских вагонетках на каждом сиденье должно находиться не более одного взрывника или подносчика',
        ],
      },
      {
        code: '63681219',
        text:
          'Какое из перечисленных мест укрытия взрывников должно располагаться от места взрыва на расстоянии не менее 200 м?',
        answers: [
          'В наклонных, в том числе восстающих (более 10°), подготовительных выработках',
          'В горизонтальных и наклонных (до 10°) подготовительных выработках, при дроблении негабаритов наружными зарядами',
          'В очистных забоях камерного типа, а также при погашении угольных целиков',
          'В лавах (слоях) с углом залегания до 18° и более, но не ближе 20 м от сопряжения с лавой (слоем) на штреке',
        ],
        correctAnswers: [
          'В очистных забоях камерного типа, а также при погашении угольных целиков',
        ],
      },
      {
        code: '63681220',
        text:
          'При каком различии отметок участков протяженных горизонтальных и наклонных горных выработок расчетная глубина для них принимается равной максимальной глубине?',
        answers: ['До 20 %', 'До 15 %', 'Более 23 %', 'Более 30 %'],
        correctAnswers: ['До 15 %'],
      },
      {
        code: '63681221',
        text:
          'Должны ли буровые насосы оборудоваться компенсаторами давления? Если да, то какие требования при этом должны соблюдаться?',
        answers: [
          'Компенсаторы давления должны устанавливаться только на трех поршневых буровых насосах, при этом компенсаторы должны быть заполнены воздухом или инертным газом, с приспособлениями для контроля давления в компенсаторах',
          'На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля',
          'Установка компенсаторов давления на буровых насосах необязательна в том случае, если проводятся мероприятия по обеспечению равномерности подачи промывочной жидкости',
          'На всех буровых насосах должны быть установлены гидрокомпенсаторы давления, заполняемые водой, с приспособлениями для контроля давления в компенсаторах',
        ],
        correctAnswers: [
          'На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля',
        ],
      },
      {
        code: '63681222',
        text:
          'Какая максимальная скорость воздуха в стволах, предназначенных для спуска и подъема грузов и используемых при аварии для вывода людей?',
        answers: ['10 м/с', '20 м/с', '12 м/с', '6 м/с'],
        correctAnswers: ['10 м/с'],
      },
      {
        code: '63681223',
        text:
          'Кем осуществляется надзор за ходом производства буровых работ, качеством выполнения этих работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?',
        answers: [
          'Проектной организацией',
          'Ответственным лицом надзорного органа',
          'Собственником недр',
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
        ],
        correctAnswers: [
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
        ],
      },
      {
        code: '63681224',
        text:
          'Какие требования предъявляются к состоянию выработки, в которой проводится сотрясательное взрывание, перед взрывными работами?',
        answers: [
          'Выработка должна быть освобождена на протяжении не менее 75 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения',
          'Выработка должна быть освобождена на протяжении не менее 50 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения',
          'Выработка должна быть освобождена на протяжении не менее 100 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения',
          'Выработка должна быть освобождена на протяжении не менее 90 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения',
        ],
        correctAnswers: [
          'Выработка должна быть освобождена на протяжении не менее 100 м от забоя от вагонеток и других предметов, загромождающих ее более чем на 1/3 площади поперечного сечения',
        ],
      },
      {
        code: '63681225',
        text:
          'Какого вида взрыва, после производства которого требуется больше времени для проветривания и возобновления работ в руднике (шахте, участке), чем это предусмотрено графиком проветривания горных выработок при повседневной организации работ, не существует?',
        answers: [
          'Экспериментальные взрывы',
          'Технологические взрывы',
          'Пробные вызовы',
          'Специальные взрывы',
        ],
        correctAnswers: ['Пробные вызовы'],
      },
      {
        code: '63681226',
        text:
          'На какой срок по решению пользователя недр составляется схема развития горных работ по одному или нескольким видам горных работ, предусмотренным планами и схемами развития горных работ?',
        answers: [
          'Не более 2 лет',
          'Не более 1 года',
          'Не более 5 лет',
          'Срок не ограничен',
        ],
        correctAnswers: ['Не более 5 лет'],
      },
      {
        code: '63681227',
        text:
          'Какими должны быть предельные углы откосов (углы устойчивости) временно консервируемых участков борта в процессе эксплуатации?',
        answers: [
          'Предельные углы откосов (углы устойчивости) устанавливаются проектом',
          'Равными 40 - 50°',
          'Не должны превышать 90°',
        ],
        correctAnswers: [
          'Предельные углы откосов (углы устойчивости) устанавливаются проектом',
        ],
      },
      {
        code: '63681228',
        text:
          'На каком расстоянии от устья скважины разрешается устанавливать компрессоры и парогенераторные установки?',
        answers: [
          'Не менее 20 м от устья скважины',
          'Не менее 15 м от устья скважины',
          'Не менее 25 м от устья скважины',
          'Не менее 10 м от устья скважины',
        ],
        correctAnswers: ['Не менее 25 м от устья скважины'],
      },
      {
        code: '63681229',
        text:
          'Каким должно быть отставание лавы каждого нижележащего слоя от границы обрушенного или заложенного пространства лавы вышележащего слоя на пластах с углом падения более 30°?',
        answers: [
          'Не менее 20 м',
          'Не менее 10 м',
          'От 10 до 15 м',
          'От 15 до 20 м',
        ],
        correctAnswers: ['Не менее 20 м'],
      },
      {
        code: '63681230',
        text:
          'Что из перечисленного не соответствует требованиям к гидравлическому способу добычи угля?',
        answers: [
          'Включение и выключение технологических насосов на технологических водоводах должно производиться только по разрешению горного диспетчера шахты',
          'Перед началом работы гидромонитора должны выводиться все люди из зоны действия его струи',
          'Открывание и закрывание задвижек на технологических водоводах должны производиться по распоряжению бригадира',
          'В действующем очистном забое работы при гидравлической и механогидравлической отбойке угля должны производить не менее двух работников',
        ],
        correctAnswers: [
          'Открывание и закрывание задвижек на технологических водоводах должны производиться по распоряжению бригадира',
        ],
      },
      {
        code: '63681231',
        text:
          'После чего начинаются загрузка бункера зарядного оборудования и непосредственно заряжание?',
        answers: [
          'После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены',
          'Поле указания диспетчера',
          'После того, как взрывник убедится в том, что блок и зарядные устройства к этим работам подготовлены',
          'После предупреждения всех лиц, участвующих в заряжании',
        ],
        correctAnswers: [
          'После того, как руководитель взрывных работ убедится в том, что блок и зарядные устройства к этим работам подготовлены',
        ],
      },
      {
        code: '63681232',
        text:
          'При каком условии допускается размещение отвалов на площадях месторождений, подлежащих отработке открытым способом?',
        answers: [
          'Допускается при условии, что земельный участок для ведения работ, связанных с пользованием недрами, находится в государственной или муниципальной собственности',
          'Допускается при указании места размещения отвалов в проекте документации для проведения работ по недропользованию',
          'Допускается по согласованию с органами Ростехнадзора',
          'Запрещается размещение отвалов на площадях месторождений, подлежащих разработке открытым способом',
        ],
        correctAnswers: [
          'Запрещается размещение отвалов на площадях месторождений, подлежащих разработке открытым способом',
        ],
      },
      {
        code: '63681233',
        text:
          'Какое из перечисленных условий допускается при заземлении и защите от образования статического электричества в пневмозарядных устройствах?',
        answers: [
          'Пневмозаряжание по одному трубопроводу взрывчатых веществ разных типов',
          'Пневматическое заряжание шпуров в подземных горных выработках при относительной влажности рудничного воздуха более 50 % зарядчиками с металлической зарядной трубкой или с электропроводящим зарядным трубопроводом длиной не более 5 м',
          'Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 Ом x м.',
          'Пневмозаряжание гранулированными алюмо- и тротилсодержащими взрывчатыми веществами без предварительного их увлажнения',
        ],
        correctAnswers: [
          'Применение полиэтиленовых и резиновых трубопроводов (шлангов) с удельным объемным электрическим сопротивлением не более 107 Ом x м.',
        ],
      },
      {
        code: '63681234',
        text:
          'Каким образом следует располагать здания и сооружения с производственными процессами, выделяющими в атмосферу вредные и (или) горючие вещества при обустройстве нефтяных, газовых и газоконденсатных месторождений?',
        answers: [
          'За границами прилегающих народно-хозяйственных объектов',
          'За пределами санитарно-защитной зоны населенных пунктов с наветренной стороны',
          'На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления',
          'На максимально удаленном расстоянии от городов и других населенных пунктов',
        ],
        correctAnswers: [
          'На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления',
        ],
      },
      {
        code: '63681235',
        text:
          'В течение какого срока осуществляется рассмотрение проектной документации?',
        answers: [
          'В течение 14 рабочих дней',
          'В течение 25 рабочих дней',
          'В течение 7 рабочих дней',
          'В течение 20 рабочих дней',
        ],
        correctAnswers: ['В течение 25 рабочих дней'],
      },
      {
        code: '63681236',
        text:
          'Что из перечисленного не указывается на аншлагах, устанавливаемых во всех местах измерения расхода воздуха в горных выработках шахты?',
        answers: [
          'Фамилия ответственного за проведение измерений',
          'Дата проведения измерения',
          'Площадь поперечного сечения горной выработки в месте проведения измерения',
          'Скорость воздушной струи',
          'Расчетный и фактический расходы воздуха',
        ],
        correctAnswers: ['Фамилия ответственного за проведение измерений'],
      },
      {
        code: '63681237',
        text:
          'В каком случае допускается подвеска к анкерной крепи оборудования?',
        answers: [
          'Допускается в случае выполнения ремонтных работ',
          'Не допускается ни в каком случае',
          'Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом',
          'Допускается в случае технической необходимости',
        ],
        correctAnswers: [
          'Допускается подвеска горно-шахтного оборудования, предусмотренного паспортом крепления или специальным проектом',
        ],
      },
      {
        code: '63681238',
        text:
          'Кому должны сообщить люди после выхода из горных выработок, проводимых в границах опасных зон, о признаках возможного прорыва воды?',
        answers: [
          'Главному инженеру маркшейдерской службы',
          'Горному диспетчеру',
          'Начальнику участка работ',
          'Руководителю организации',
          'Всем перечисленным лицам',
        ],
        correctAnswers: ['Горному диспетчеру'],
      },
      {
        code: '63681239',
        text:
          'Каким должно быть расстояние между устьями скважин при размещении кустовых площадок на вечномерзлых грунтах?',
        answers: [
          'Не менее 1,2 диаметра растепления пород вокруг устья скважин',
          'Не должно превышать диаметр зоны протаивания мерзлых пород, рассчитанной на период непрерывной эксплуатации скважин',
          'Соответствующим одному радиусу растепления пород вокруг устья скважин',
        ],
        correctAnswers: [
          'Не менее 1,2 диаметра растепления пород вокруг устья скважин',
        ],
      },
      {
        code: '63681240',
        text:
          'Какой документ служит для отпуска взрывчатых материалов взрывникам для производства взрывных работ?',
        answers: ['Наряд-допуск', 'Наряд-путевка', 'Накладная', 'Акт отгрузки'],
        correctAnswers: ['Наряд-путевка'],
      },
      {
        code: '63681241',
        text:
          'Требуется ли маркшейдерское обеспечение буровзрывных работ при разработке месторождений полезных ископаемых в соответствии с Федеральными нормами и правилами в области промышленной безопасности "Правила безопасности при ведении горных работ и переработке твердых полезных ископаемых"?',
        answers: [
          'Требуется только на момент получения разрешения на взрывные работы',
          'Требуется только при проведении детонационного взрыва при разработке месторождений полезных ископаемых',
          'Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями',
          'Не требуется',
        ],
        correctAnswers: [
          'Маркшейдерское обеспечение буровзрывных работ должно осуществляться в соответствии с установленными требованиями',
        ],
      },
      {
        code: '63681242',
        text:
          'В каком порядке производятся передача скважин для ремонта или реконструкции и приемка скважин после завершения ремонтных работ от заказчика подрядчику?',
        answers: [
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается подрядной организацией и утверждается заказчиком',
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией',
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается нормативно-технической документацией надзорной организации',
        ],
        correctAnswers: [
          'Порядок передачи нефтяных и газовых скважин для ремонта или реконструкции подрядчику и приемки скважин после завершения работ устанавливается эксплуатирующей организацией',
        ],
      },
      {
        code: '63681243',
        text:
          'Что необходимо сделать с газораспределительными трубопроводами после их монтажа?',
        answers: [
          'Продуть азотом и опрессовать жидкостью на давление, превышающее на 15 % максимальное расчетное',
          'Продуть инертным газом и провести пневматическое испытание на давление, превышающее на 35 % расчетное',
          'Продуть кислородом и опрессовать жидкостью на давление, превышающее на 50 % максимальное рабочее',
          'Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее',
        ],
        correctAnswers: [
          'Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее',
        ],
      },
      {
        code: '63681244',
        text:
          'Какие наблюдения должны проводиться маркшейдерской службой шахты в период подготовки блока к выщелачиванию?',
        answers: [
          'Наблюдения за состоянием откосов, уступов и бортов карьера',
          'Наблюдения и контроль за состоянием подземных и поверхностных вод',
          'Наблюдения за состоянием горных отводов и обоснование их границ',
          'Систематические наблюдения за сдвижением и деформацией пород',
        ],
        correctAnswers: [
          'Систематические наблюдения за сдвижением и деформацией пород',
        ],
      },
      {
        code: '63681245',
        text:
          'В каком масштабе должны составляться графические материалы для твердых полезных ископаемых?',
        answers: [
          'Не мельче 1 : 100 000',
          'Не мельче 1 : 25 000',
          'Не мельче 1 : 10 000',
          'Не мельче 1 : 50 000',
        ],
        correctAnswers: ['Не мельче 1 : 10 000'],
      },
      {
        code: '63681246',
        text:
          'Каким федеральным органом исполнительной власти осуществляется, в соответствии с законодательством Российской Федерации, государственный горный надзор?',
        answers: [
          'Федеральной службой по экологическому, технологическому и атомному надзору',
          'Министерством природных ресурсов и экологии Российской Федерации',
          'Федеральным агентством геодезии и картографии',
          'Федеральной службой по надзору в сфере природопользования',
        ],
        correctAnswers: [
          'Федеральной службой по экологическому, технологическому и атомному надзору',
        ],
      },
      {
        code: '63681247',
        text:
          'Какая должна быть минимальная длина анкеров первого уровня в случае применения двухуровневой схемы крепи при ширине выработки от 8 до 12 м для I типа кровли?',
        answers: ['2,2 м', '2,4 м', '1,8 м', '1,4 м'],
        correctAnswers: ['2,4 м'],
      },
      {
        code: '63681248',
        text:
          'Через какое время разрешается подход взрывника к месту взрыва, если взрыва не произошло, при взрывании электронными детонаторами, электродетонаторами и капсюлями-детонаторами?',
        answers: [
          'Не ранее чем через 5 минут',
          'Не ранее чем через 15 минут',
          'Не ранее чем через 20 минут',
          'Не ранее чем через 10 минут',
        ],
        correctAnswers: ['Не ранее чем через 15 минут'],
      },
      {
        code: '63681249',
        text:
          'Чему равно значение коэффициента разрыхления пород при вынимаемой мощности подрабатывающего пласта до 1 м и прочности пород непосредственной кровли подрабатывающего пласта до 30 МПа?',
        answers: ['1,35 - 1,40', '1,20 - 1,25', '1,15 - 1,20', '1,25 - 1,30'],
        correctAnswers: ['1,15 - 1,20'],
      },
      {
        code: '63681250',
        text:
          'Каким образом запрещается производить заряжание шпуров (скважин) и монтаж взрывной сети на высоте более 2 м?',
        answers: [
          'С использованием погрузочно-доставочной техники',
          'С полков, примыкающих к забою',
          'С лестниц',
          'С оборудованных подъемных площадок (помостов)',
        ],
        correctAnswers: ['С лестниц'],
      },
      {
        code: '63681251',
        text:
          'В какой сфере Федеральная служба по экологическому, технологическому и атомному надзору не осуществляет контроль и надзор?',
        answers: [
          'В сфере безопасного ведения работ, связанных с пользованием недрами',
          'В сфере безопасности электрических и тепловых установок и сетей (кроме бытовых установок и сетей)',
          'В сфере безопасности при использовании атомной энергии (за исключением деятельности по разработке, изготовлению, испытанию, эксплуатации и утилизации ядерного оружия и ядерных энергетических установок военного назначения)',
          'В сфере промышленной безопасности',
          'В сфере безопасности судоходных и портовых гидротехнических сооружений',
          'В сфере безопасности производства, хранения и применения взрывчатых материалов промышленного назначения',
        ],
        correctAnswers: [
          'В сфере безопасности судоходных и портовых гидротехнических сооружений',
        ],
      },
      {
        code: '63681252',
        text:
          'В какие сроки предприятие по добыче полезных ископаемых должно письменно уведомить (известить) организацию, эксплуатирующую подрабатываемый объект, или его владельца о начале ведения горных работ?',
        answers: [
          'Не позднее чем за 1 месяц до начала влияния горных работ на охраняемый объект',
          'Не позднее чем за 3 месяца до начала горных работ в зоне их влияния на охраняемый объект',
          'Не позднее чем за 6 месяцев до начала горных работ в зоне их влияния на охраняемый объект, а также о начале влияния горных работ на охраняемый объект',
          'Не менее чем за 6 месяцев до подхода зоны влияния очистных и подготовительных выработок к охраняемому объекту',
        ],
        correctAnswers: [
          'Не позднее чем за 6 месяцев до начала горных работ в зоне их влияния на охраняемый объект, а также о начале влияния горных работ на охраняемый объект',
        ],
      },
      {
        code: '63681253',
        text:
          'В каком документе фиксируются отказы зарядов при взрывных работах?',
        answers: [
          'В Журнале осмотра технического состояния оборудования',
          'В сменном журнале',
          'В Журнале регистрации отказов при взрывных работах',
          'В наряде-допуске',
        ],
        correctAnswers: ['В Журнале регистрации отказов при взрывных работах'],
      },
      {
        code: '63681254',
        text:
          'На каком расстоянии от устьев стволов, шурфов, штолен, скважин устанавливаются расположенные на поверхности здания вентиляторов главного проветривания?',
        answers: [
          'От 3 до 5 м',
          'Не более 5 м',
          'Не более 10 м',
          'На расстоянии, установленном проектной документацией',
        ],
        correctAnswers: [
          'На расстоянии, установленном проектной документацией',
        ],
      },
      {
        code: '63681255',
        text: 'К какому классу относится "Устойчивая кровля"?',
        answers: ['К I классу', 'Ко II классу', 'К III классу'],
        correctAnswers: ['К III классу'],
      },
      {
        code: '63681256',
        text:
          'Сколько должно быть выходов из очистной горной выработки с длинным очистным забоем и очистной горной выработки с коротким очистным забоем, тупиковая часть которой более 30 м, в оконтуривающие выемочный участок горные выработки?',
        answers: [
          'Правилами не регламентируется',
          'Не менее двух выходов',
          'Один выход',
          'Не более 1 - 2 выходов',
        ],
        correctAnswers: ['Не менее двух выходов'],
      },
      {
        code: '63681257',
        text:
          'С какой периодичностью ИТР структурного подразделения, в ведении которых находится горная выработка, должны осматривать крепь и армировку вертикальных и наклонных стволов?',
        answers: [
          'Не реже одного раза в квартал',
          'Не реже одного раза в неделю',
          'Ежемесячно',
          'Ежесуточно',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63681258',
        text:
          'Каким образом должен упаковываться заряд при температуре более 80 °C в шпуре (скважине, рукаве) при ведении взрывных работ по металлу?',
        answers: [
          'В пенопластовый короб',
          'В плотную упаковочную бумагу',
          'В прорезиненную ветошь',
          'В термоизолирующую оболочку',
        ],
        correctAnswers: ['В термоизолирующую оболочку'],
      },
      {
        code: '63681259',
        text:
          'Какое из перечисленных требований для получения разрешения на выемку запасов из предохранительного целика в случае, когда надобность в охране объекта миновала, указано верно?',
        answers: [
          'Предприятие по добыче полезных ископаемых в течение 14 дней до начала очистных работ в предохранительном целике обязано представить в надзорный орган заявление о выдаче разрешения на ведение горных работ',
          'Заявление о выдаче разрешения с приложением проекта ведения горных работ в предохранительном целике, а также пояснительная записка к нему представляются в Министерство природных ресурсов и экологии Российской Федерации в 3-дневный срок от начала горных работ',
          'В пояснительной записке к проекту должна быть указана причина утраты надобности в охране объекта, приведены способы, сроки и порядок выемки предохранительного целика',
          'Заявление о выдаче разрешения на выемку запасов из предохранительного целика должно составляться в 3 экземплярах',
        ],
        correctAnswers: [
          'В пояснительной записке к проекту должна быть указана причина утраты надобности в охране объекта, приведены способы, сроки и порядок выемки предохранительного целика',
        ],
      },
      {
        code: '63681260',
        text: 'К какому классу относится "Кровля средней устойчивости"?',
        answers: ['К I классу', 'Ко II классу', 'К III классу'],
        correctAnswers: ['Ко II классу'],
      },
      {
        code: '63681261',
        text:
          'Кем должны проводиться заряжание и забойка при ведении взрывных работ по металлу?',
        answers: [
          'Одним взрывником в присутствии руководителя взрывных работ',
          'Тремя взрывниками в присутствии руководителя взрывных работ',
          'Двумя взрывниками в присутствии руководителя взрывных работ',
          'Несколькими взрывниками',
        ],
        correctAnswers: [
          'Двумя взрывниками в присутствии руководителя взрывных работ',
        ],
      },
      {
        code: '63681262',
        text:
          'В границах геологического отвода проводить работы одновременно могут:',
        answers: [
          'Несколько пользователей недр',
          'Не более 2 пользователей недр',
          '2 пользователя недр, включая собственника участка',
        ],
        correctAnswers: ['Несколько пользователей недр'],
      },
      {
        code: '63681263',
        text:
          'За какой период времени до начала производства работ, связанных со строительством подземных сооружений, необходимо уведомить об этом органы Ростехнадзора?',
        answers: ['За 5 дней', 'За 10 дней', 'За 15 дней', 'За 1 месяц'],
        correctAnswers: ['За 15 дней'],
      },
      {
        code: '63681264',
        text:
          'Какие радиусы закругления рельсовых путей и переводных кривых в действующих горных выработках устанавливаются для колеи 600 мм и 900 мм?',
        answers: [
          'Не менее 8 м и не менее 12 м соответственно',
          'Не менее 5 м и не менее 8 м соответственно',
          'Не менее 15 м и не менее 23 м соответственно',
          'Не менее 12 м и не менее 20 м соответственно',
        ],
        correctAnswers: ['Не менее 8 м и не менее 12 м соответственно'],
      },
      {
        code: '63681265',
        text:
          'Какое из перечисленных условий указано неверно при вскрытии пластов сотрясательным взрыванием с применением рассредоточенных (двухъярусных) зарядов взрывчатых веществ?',
        answers: [
          'Длина шпуров с рассредоточенными зарядами должна составлять не менее 3 м',
          'При использовании взрывчатых веществ II класса длина забойки между рассредоточенными зарядами должна быть не менее 1 м, а масса первого от устья шпура заряда взрывчатых веществ - не более 1 кг',
          'В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья',
          'Инициирование зарядов осуществляется электродетонаторами мгновенного и короткозамедленного действия',
        ],
        correctAnswers: [
          'В шпурах с рассредоточенными зарядами замедление в донном заряде должно быть меньше, чем в первом заряде от устья',
        ],
      },
      {
        code: '63681266',
        text:
          'Кто принимает решение по изменению направления движения и расхода воздуха в горных выработках угольных шахт?',
        answers: [
          'Начальник смены',
          'Начальник участка аэрологической безопасности',
          'Руководитель маркшейдерской службы',
          'Рабочие шахты',
        ],
        correctAnswers: ['Начальник участка аэрологической безопасности'],
      },
      {
        code: '63681267',
        text:
          'Какое оборудование должно быть установлено на скважинах, где ожидаемое давление на устье превышает 700 кгс/см² (70 МПа)?',
        answers: [
          'Заводской блок с тремя регулируемыми дросселями с ручным управлением',
          'Заводской блок с двумя регулируемыми дросселями (один с дистанционным управлением, второй - с ручным управлением)',
          'Заводской блок с двумя регулируемыми дросселями, снабженными дистанционным управлением',
          'Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением',
        ],
        correctAnswers: [
          'Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением',
        ],
      },
      {
        code: '63681268',
        text:
          'Что из перечисленного не входит в мероприятия по разгазированию выработок?',
        answers: [
          'Выставление постов (на свежей струе) и запрещающих знаков в местах возможного подхода людей к выработкам, по которым двигается исходящая струя при разгазировании',
          'Снятие напряжения с электрооборудования и электрических сетей и устранение других источников воспламенения в выработках, по которым будет двигаться исходящая струя',
          'Обследование выработок после разгазирования перед допуском в них людей',
          'Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей',
          'Контроль за разгазированием выработки и за свежей струей воздуха (концентрация газов в месте слияния исходящей и свежей струй воздуха не должна превышать: горючих газов - 1 %, ядовитых газов - ПДК)',
        ],
        correctAnswers: [
          'Возобновление работ в отперемыченных выработках, подключение электрооборудования и электрических сетей',
        ],
      },
      {
        code: '63681269',
        text:
          'Каким способом разрешается проводить взрывание камерных зарядов?',
        answers: [
          'С применением электродетонаторов',
          'С применением неэлектрических систем инициирования',
          'С применением детонирующего шнура',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Всеми перечисленными способами'],
      },
      {
        code: '63681270',
        text:
          'В каком случае при необходимости внесения изменений в уточненные границы горного отвода документация не подлежит переоформлению?',
        answers: [
          'В случае переоформления лицензии на пользование недрами',
          'В случае изменения срока пользования участком недр, установленного в лицензии на пользование недрами',
          'В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
          'В случае выявления ошибок, несоответствий в лицензии на пользование недрами или в документации к ней',
        ],
        correctAnswers: [
          'В случае изменений технического проекта выполнения работ, связанных с пользованием недрами, не влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
        ],
      },
      {
        code: '63681271',
        text:
          'Какие из перечисленных сведений должен содержать титульный лист проектной документации на разработку месторождений углеводородного сырья? Укажите все правильные ответы.',
        answers: [
          'Тип месторождения',
          'Наименование месторождения',
          'Номер страницы',
          'Полные реквизиты исполнителя проектной документации',
          'Наименование территориального надзорного органа',
        ],
        correctAnswers: ['Тип месторождения', 'Наименование месторождения'],
      },
      {
        code: '63681272',
        text:
          'В каком случае допускается протягивать один предохранительный канат между крайними секциями щитового перекрытия при отработке мощных пластов?',
        answers: [
          'Ни в каком случае не допускается',
          'При мощности пласта менее 6 м',
          'Если данная мера прописана в документации по ведению горных работ',
          'В случае постоянного присутствия наблюдающего персонала во время проведения работ',
        ],
        correctAnswers: ['При мощности пласта менее 6 м'],
      },
      {
        code: '63681273',
        text:
          'В каком положении должна находиться задвижка на отводе от затрубного пространства при закачке теплоносителя (с установкой пакера)?',
        answers: ['В любом', 'В закрытом', 'В полуоткрытом', 'В открытом'],
        correctAnswers: ['В открытом'],
      },
      {
        code: '63681274',
        text: 'Каким документом оформляется предоставление недр в пользование?',
        answers: [
          'Договором о предоставлении в пользование недр',
          'Лицензией на право пользования недрами',
          'Планом производства работ в недрах',
          'Свидетельством о праве собственности на землю',
        ],
        correctAnswers: ['Лицензией на право пользования недрами'],
      },
      {
        code: '63681275',
        text:
          'Какие перечисленные горные выработки и скважины угольных шахт не подлежат изоляции?',
        answers: [
          'Горные выработки, используемые для отвода метана из выработанных пространств, со стороны действующих горных выработок',
          'Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа',
          'Неиспользуемые горные выработки и скважины',
          'Отработанные выемочные участки (поля)',
          'Все перечисленные горные выработки и скважины',
        ],
        correctAnswers: [
          'Скважины, предназначенные для борьбы с внезапными выбросами угля (породы) и газа',
        ],
      },
      {
        code: '63681276',
        text:
          'Какое из перечисленных требований к мерам защиты от статического электричества технологического оборудования при взрывных работах указано неверно?',
        answers: [
          'Места для присоединения заземляющих проводников и способ их крепления должны быть указаны в технической документации оборудования',
          'Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов',
          'В каждом производственном здании должна быть составлена схема (карта) заземления',
          'Допускается объединение заземляющих устройств для защиты от статического электричества с защитным заземлением электрооборудования',
        ],
        correctAnswers: [
          'Допускается последовательное включение в заземляющую шину (провод) нескольких заземляющих аппаратов, агрегатов или трубопроводов',
        ],
      },
      {
        code: '63681277',
        text:
          'В каких случаях не устанавливается превенторная сборка при бурении скважин для разведки и добычи метана угольных месторождений (площадей, участков)?',
        answers: [
          'По указанию руководителя подрядной организации, производящей буровые работы',
          'В случаях, предусмотренных в рабочем проекте на бурение',
          'В случае вскрытия изученного разреза с аномально низким пластовым давлением (далее – АНПД), представленного обводненными угольными пластами',
        ],
        correctAnswers: [
          'В случае вскрытия изученного разреза с аномально низким пластовым давлением (далее – АНПД), представленного обводненными угольными пластами',
        ],
      },
      {
        code: '63681278',
        text:
          'Не ниже какого класса допускается применение предохранительных взрывчатых веществ в нефтяных шахтах?',
        answers: ['IV класса', 'III класса', 'V класса', 'VII класса'],
        correctAnswers: ['III класса'],
      },
      {
        code: '63681279',
        text:
          'К какой основной схеме подготовки выемочных участков относится проведение спаренных горных выработок в зоне и впереди зоны влияния очистных работ при подготовке выемочных столбов спаренными горными выработками?',
        answers: ['К третьей', 'К первой', 'Ко второй', 'К четвертой'],
        correctAnswers: ['Ко второй'],
      },
      {
        code: '63681280',
        text:
          'Какая должна быть минимальная плотность установки анкеров в классе неустойчивой кровли?',
        answers: ['0,4 анк/м²', '0,3 анк/м²', '1 анк/м²', '0,7 анк/м²'],
        correctAnswers: ['1 анк/м²'],
      },
      {
        code: '63681281',
        text:
          'Что относится к грубым нарушениям лицензионных требований при производстве маркшейдерских работ?',
        answers: [
          'Только допуск к выполнению работ, составляющих лицензируемую деятельность, лиц, не имеющих специальную подготовку и квалификацию',
          'Только отсутствие у лицензиата оборудования для обработки результатов и средств измерений, соответствующих классификации по точности и техническим требованиям (условиям) производства маркшейдерских работ',
          'Только отсутствие высшего образования по специальности "маркшейдерское дело", высшего технического образования и прохождения профессиональной переподготовки по специальности "маркшейдерское дело" у штатного сотрудника, занимающего должность главного маркшейдера организации',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681282',
        text:
          'Какие условия должны соблюдаться в забоях выработок, где имеется газовыделение или взрывчатая угольная пыль?',
        answers: [
          'Раздельное взрывание зарядов в шпурах при числе циклов не более одного по углю и одного по породе, должно осуществляться только по разрешению руководителя горнодобывающего предприятия',
          'В забоях выработок, где имеется газовыделение или взрывчатая угольная пыль, разрешается применять только непредохранительные электродетонаторы с заземлением',
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно',
          'Общее максимальное время замедления электродетонаторов короткозамедленного действия с учетом разброса по времени срабатывания при применении взрывчатых веществ V и VI класса не должно превышать 420 миллисекунд',
        ],
        correctAnswers: [
          'При протяженности угольного забоя более 5 м разрешается его делить по длине на участки, и взрывание в каждом из них производить раздельно',
        ],
      },
      {
        code: '63681283',
        text:
          'Кто определяет порядок продления срока безопасной эксплуатации технических устройств, оборудования и сооружений, эксплуатируемых на опасных производственных объектах (определение остаточного ресурса)?',
        answers: [
          'Министерство промышленности и торговли Российской Федерации',
          'Федеральная служба по экологическому, технологическому и атомному надзору',
          'Федеральный горный и промышленный надзор России',
          'Федеральное агентство по техническому регулированию и метрологии',
        ],
        correctAnswers: [
          'Федеральная служба по экологическому, технологическому и атомному надзору',
        ],
      },
      {
        code: '63681284',
        text:
          'На каком расстоянии от устья необходимо размещать вентиляторные установки для проветривания вертикальных горных выработок, проводимых с поверхности?',
        answers: [
          'От 2 до 5 м',
          'От 15 м до 20 м',
          'Не менее 20 м',
          'Не менее 12 м',
        ],
        correctAnswers: ['Не менее 20 м'],
      },
      {
        code: '63681285',
        text:
          'Под чьим руководством производится инструментальная проверка вертикальности копра, правильности установки направляющих шкивов по отношению к оси ствола и оси подъема?',
        answers: [
          'Под руководством представителя надзорной организации',
          'Под руководством технического руководителя объекта',
          'Под руководством главного маркшейдера шахты',
          'Под руководством начальника смены',
        ],
        correctAnswers: ['Под руководством главного маркшейдера шахты'],
      },
      {
        code: '63681286',
        text:
          'Что из нижеперечисленного должно быть указано в плане производства работ по нагнетанию в скважину газа, пара, химических и других агентов?',
        answers: [
          'Только порядок подготовительных работ и меры безопасности',
          'Только схема размещения оборудования',
          'Только технология проведения процесса',
          'Только ответственный руководитель работ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681287',
        text:
          'С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций в зоне опорного давления?',
        answers: [
          '1 раз в месяц',
          '1 раз в квартал',
          '1 раз в сутки',
          '1 раз в неделю',
        ],
        correctAnswers: ['1 раз в сутки'],
      },
      {
        code: '63681288',
        text: 'В какие сроки изолируются отработанные участки?',
        answers: [
          'В течение одного месяца',
          'Определенные техническим проектом и (или) проектной документацией',
          'В течение двух рабочих недель',
          'В течение рабочей смены',
        ],
        correctAnswers: [
          'Определенные техническим проектом и (или) проектной документацией',
        ],
      },
      {
        code: '63681289',
        text:
          'В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?',
        answers: [
          'Не позднее 7 дней со дня оформления',
          'Не позднее 10 дней со дня оформления',
          'Не позднее 5 дней со дня оформления',
          'Не позднее 14 дней со дня оформления',
        ],
        correctAnswers: ['Не позднее 5 дней со дня оформления'],
      },
      {
        code: '63681290',
        text:
          'Кем утверждается проектная документация на разработку месторождений полезных ископаемых?',
        answers: [
          'Роснедрами',
          'Пользователем недр',
          'Росприроднадзором',
          'Ростехнадзором',
        ],
        correctAnswers: ['Пользователем недр'],
      },
      {
        code: '63681291',
        text:
          'Какую из перечисленных функций по контролю и надзору не осуществляет Федеральная служба по экологическому, технологическому и атомному надзору?',
        answers: [
          'За представлением декларации о потреблении энергетических ресурсов, соблюдением требований к форме декларации и порядку ее представления',
          'За соблюдением требований технических регламентов в установленной сфере деятельности',
          'За соблюдением в пределах своей компетенции теплоснабжающими организациями и теплосетевыми организациями требований безопасности в сфере теплоснабжения',
          'За соблюдением требований законодательства Российской Федерации в области обращения с радиоактивными отходами',
          'За соблюдением требований законодательства Российской Федерации в области охраны окружающей среды',
        ],
        correctAnswers: [
          'За соблюдением требований законодательства Российской Федерации в области охраны окружающей среды',
        ],
      },
      {
        code: '63681292',
        text:
          'Какое из перечисленных требований к условиям заряжания, массе зарядов взрывчатых веществ и длине забойки указано верно?',
        answers: [
          'Запрещается применять шпуры глубиной менее 1 м в продуктивном пласте',
          'В шпурах глубиной от 1 до 1,5 м заряд должен занимать не более половины их длины',
          'При наличии в забое нескольких обнаженных поверхностей линия наименьшего сопротивления от любой точки заряда до ближайшей обнаженной поверхности должна быть не менее 0,5 м в продуктивном пласте и не менее 0,3 м по породе',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63681293',
        text:
          'Разработку каких рудных тел необходимо производить преимущественно системой одностадийного этажного или подэтажного принудительного обрушения с отбойкой руды на зажатую среду?',
        answers: [
          'Маломощных рудных тел',
          'Крутопадающих мощных рудных тел',
          'Наклонных рудных тел',
          'Складчатых, пологих рудных тел',
        ],
        correctAnswers: ['Крутопадающих мощных рудных тел'],
      },
      {
        code: '63681294',
        text:
          'С какой периодичностью вносятся записи в журнал по контролю за состоянием выработки и показаниями реперных станций вне зоны опорного давления?',
        answers: [
          '1 раз в смену',
          '1 раз в месяц',
          '2 раза в неделю',
          '1 раз в две недели',
        ],
        correctAnswers: ['1 раз в месяц'],
      },
      {
        code: '63681295',
        text:
          'В каком случае допускаются отступления от последовательного продвигания фронта очистных работ в пределах как шахтного поля (участка), так и месторождения в целом, при отработке месторождений, склонных и опасных по горным ударам?',
        answers: [
          'При соблюдении мер по безопасному производству очистных работ',
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
          'В случае незначительных отступлений от требований Правил безопасности при ведении горных работ',
          'Не допускаются ни в каком случае',
        ],
        correctAnswers: [
          'С разрешения комиссии, состоящей из представителей эксплуатирующей организации, представителя территориального органа федерального органа исполнительной власти, уполномоченного в области промышленной безопасности, специализированной организации, выполняющей работы по проблеме горных ударов на данном месторождении',
        ],
      },
      {
        code: '63681296',
        text:
          'В каких случаях при взрывании скважинных зарядов на поверхности обязательно дублирование внутрискважинной сети?',
        answers: [
          'При взрывании скважинных зарядов независимо от их глубины',
          'При взрывании нескольких скважинных зарядов при глубине скважин более 10 м',
          'При глубине взрывных скважин более 15 м',
          'Необходимость дублирования определяется руководителем организации',
        ],
        correctAnswers: ['При глубине взрывных скважин более 15 м'],
      },
      {
        code: '63681297',
        text:
          'Какие взрывчатые вещества должны использоваться при разупрочнении труднообрушаемых пород любой крепости?',
        answers: [
          'Взрывчатые вещества, содержащие гексоген',
          'Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил',
          'Взрывчатые вещества, содержащие нитроэфиры',
          'Любые взрывчатые вещества',
        ],
        correctAnswers: [
          'Взрывчатые вещества, не содержащие сенсибилизаторов, более чувствительных, чем тротил',
        ],
      },
      {
        code: '63681298',
        text:
          'Для каких видов взрывных работ применяются предохранительные взрывчатые вещества VI класса?',
        answers: [
          'Для взрывного перебивания деревянных стоек при посадке кровли',
          'Для ликвидации зависаний горной массы в углеспускных выработках',
          'Для дробления негабаритов наружными зарядами',
          'Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой',
        ],
        correctAnswers: [
          'Для верхней и смешанной подрывки пород с f = 4 и менее в вентиляционных штреках, проводимых вслед за лавой',
        ],
      },
      {
        code: '63681299',
        text:
          'Какие показатели должны контролироваться при бурении наклонно-направленных и горизонтальных скважин?',
        answers: [
          'Крутящий момент на роторе при роторном способе бурения, давление в манифольде буровых насосов',
          'Расход бурового раствора на входе и выходе из скважины, показатели мощности и напора насосов',
          'Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
          'Плотность и реологические показатели, структурно-механические свойства бурового раствора',
        ],
        correctAnswers: [
          'Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
        ],
      },
      {
        code: '63681300',
        text: 'Какие скважины после окончания бурения должны быть перекрыты?',
        answers: [
          'Скважины, диаметр устья которых более 250 мм',
          'Скважины, диаметр устья которых менее 200 мм',
          'Скважины, диаметр устья которых равен 180 мм',
          'Скважины, диаметр устья которых более 220 мм',
        ],
        correctAnswers: ['Скважины, диаметр устья которых более 250 мм'],
      },
      {
        code: '63681301',
        text:
          'Каким должно быть содержание кислорода (по объему) в рудничной атмосфере горных выработок, в которых находится или может находиться человек?',
        answers: [
          'Не менее 12 %',
          'Не менее 15 %',
          'Не менее 20 %',
          'Не менее 10 %',
        ],
        correctAnswers: ['Не менее 20 %'],
      },
      {
        code: '63681302',
        text:
          'Что необходимо сделать перед началом заряжания шпуров и скважин при ведении взрывных работ в подземных выработках?',
        answers: [
          'Только убрать ранее взорванную в забое горную массу',
          'Только вывести людей, не участвующих в выполнении взрывных работ, за пределы запретной (опасной) зоны, в места, определенные паспортом (проектом) буровзрывных (взрывных) работ',
          'Только обеспечить проветривание забоя',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681303',
        text:
          'До какой нагрузки нагружают анкерную крепь при испытании ее несущей способности?',
        answers: [
          'До 0,8Nа, где Nа - несущая способность анкера, кН',
          'До 0,6Nа, где Nа - несущая способность анкера, кН',
          'До 1Nа, где Nа - несущая способность анкера, кН',
          'До 1,3Nа, где Nа - несущая способность анкера, кН',
        ],
        correctAnswers: ['До 0,6Nа, где Nа - несущая способность анкера, кН'],
      },
      {
        code: '63681304',
        text:
          'Разрешается ли применение системы разработки с обрушением руды и вмещающих пород при наличии в налегающих породах плывунов, неосушенных песков, карстов и т. д.?',
        answers: [
          'Разрешается при наличии записи в проектно-технической документации',
          'Разрешается при согласовании с территориальными надзорными органами',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63681305',
        text:
          'Сколько сигнальных устройств должна иметь подъемная установка при проходке и углублении стволов?',
        answers: [
          'Одно сигнальное устройство',
          'Количество сигнальных устройств устанавливается в зависимости от горно-геологических условий',
          'Не менее двух независимых сигнальных устройств',
        ],
        correctAnswers: ['Не менее двух независимых сигнальных устройств'],
      },
      {
        code: '63681306',
        text:
          'Кем устанавливается порядок подготовки, рассмотрения и согласования планов или схем развития горных работ по видам полезных ископаемых?',
        answers: [
          'Федеральной службой по надзору в сфере природопользования',
          'Правительством Российской Федерации',
          'Техническим руководителем маркшейдерской службы организации',
          'Руководителем организации (пользователя недр) либо лицом, исполняющим его обязанности',
        ],
        correctAnswers: ['Правительством Российской Федерации'],
      },
      {
        code: '63681307',
        text:
          'При какой минимальной концентрации метана руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания, обязан немедленно возвратиться в выработку со свежей струей воздуха?',
        answers: ['5 %', '7 %', '3 %', '2 %'],
        correctAnswers: ['2 %'],
      },
      {
        code: '63681308',
        text:
          'Какое минимальное расстояние должно быть между парораспределительным пунктом и устьем нагнетательной скважины?',
        answers: ['20 м', '25 м', '10 м', '15 м'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63681309',
        text:
          'Чем должно быть регламентировано приведение горных выработок в неудароопасное состояние?',
        answers: [
          'Конструкторской документацией, утвержденной специализированной организацией',
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
          'Указаниями горно-геологической службы',
          'Автоматизированной системой контроля состояния массива горных пород и прогноза динамических явлений',
        ],
        correctAnswers: [
          'Проектом, утвержденным председателем Комиссии (техническим руководителем эксплуатирующей объект ведения горных работ организации)',
        ],
      },
      {
        code: '63681310',
        text:
          'Какие требования при проектировании паспортов крепления и поддержания подготовительных выработок анкерной крепью указаны неверно?',
        answers: [
          'Анкеры в кровле должны располагаться с отклонением крайних анкеров от нормали к напластованию на угол 15 - 20°',
          'Крайние анкеры в кровле следует располагать на расстоянии от боков не более 0,3 м',
          'Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м',
          'Анкеры в боках следует располагать так, чтобы они пересекали наиболее слабые слои угля и пород (с отклонением от напластования на угол 15 - 30°)',
        ],
        correctAnswers: [
          'Расстояние от кровли выработки до верхнего ряда анкеров должно быть не более 1,5 м',
        ],
      },
      {
        code: '63681311',
        text:
          'Кто производит согласование проектной документации на разработку месторождений полезных ископаемых?',
        answers: [
          'Комиссия, создаваемая Минсельхозом России, состоящая из представителей Россельхознадзора и Минприроды России',
          'Комиссия, создаваемая Ростехнадзором, состоящая из представителей Ростехнадзора и Росприроднадзора',
          'Комиссия, создаваемая Роснедрами или его соответствующим территориальным органом, состоящая из представителей Минприроды России, Ростехнадзора и Росприроднадзора',
          'Комиссия, создаваемая Минприроды России, состоящая из представителей Роснедр, Рослесхоза',
        ],
        correctAnswers: [
          'Комиссия, создаваемая Роснедрами или его соответствующим территориальным органом, состоящая из представителей Минприроды России, Ростехнадзора и Росприроднадзора',
        ],
      },
      {
        code: '63681312',
        text:
          'В отношении каких видов горных работ планы и схемы развития горных работ не составляются?',
        answers: [
          'Геологических работ',
          'Работ по обустройству транспортной структуры места проведения горных работ',
          'Работ, связанных с первичной переработкой минерального сырья',
          'Работ по добыче полезных ископаемых',
        ],
        correctAnswers: [
          'Работ по обустройству транспортной структуры места проведения горных работ',
        ],
      },
      {
        code: '63681313',
        text: 'Каким образом целесообразно располагать анкеры в кровле?',
        answers: [
          'С отклонением крайних анкеров от нормали к напластованию на угол 20 - 25° и на расстоянии их от боков не более 0,4 м',
          'С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,5 м',
          'С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м',
        ],
        correctAnswers: [
          'С отклонением крайних анкеров от нормали к напластованию на угол 15 - 20° и на расстоянии их от боков не более 0,3 м',
        ],
      },
      {
        code: '63681314',
        text:
          'С какой периодичностью места хранения селитры должны подвергаться очистке?',
        answers: [
          'Не реже одного раза в месяц',
          'Не реже одного раза в год',
          'Не реже одного раза в две недели',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63681315',
        text:
          'В каком разделе указаний по безопасному ведению горных работ на месторождениях, склонных и опасных по горным ударам, представляется характеристика горных ударов, других геодинамических явлений, отражающих специфику геологических условий и технологии отработки месторождения?',
        answers: [
          'Общие положения',
          'Введение',
          'Проведение и поддержание горных выработок',
          'Порядок вскрытия, подготовки к выемке и отработки выемочных участков, блоков',
        ],
        correctAnswers: ['Общие положения'],
      },
      {
        code: '63681316',
        text:
          'Когда должны приниматься меры по обезвоживанию глины и выпуску воды при наличии в горных выработках подрабатываемого участка воды или жидкой глины?',
        answers: [
          'По завершении очистных работ',
          'До начала очистных работ',
          'Во время проведения очистных работ',
        ],
        correctAnswers: ['До начала очистных работ'],
      },
      {
        code: '63681317',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'С органами исполнительной власти субъекта Российской Федерации, в ведение которых входит участок горнодобычи',
          'Со сторонними организациями, расположенными на участке месторождений',
          'С территориальными органами Ростехнадзора',
          'С региональным подразделением Росприроднадзора',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63681318',
        text:
          'С какой периодичностью должен проводиться визуальный контроль работоспособности анкерной крепи в зонах влияния очистных работ?',
        answers: [
          'Один раз в 3 месяца',
          'Еженедельно',
          'Ежесуточно',
          'Раз в месяц',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63681319',
        text:
          'Что при ведении горных работ является объектами открытых горных работ?',
        answers: [
          'Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы',
          'Карьеры и объекты кучного выщелачивания',
          'Только объекты разработки горных отвалов',
          'Все объекты металлургических предприятий',
        ],
        correctAnswers: [
          'Карьеры, прииски, дражные полигоны, объекты кучного выщелачивания, породные, шлаковые отвалы и гидроотвалы',
        ],
      },
      {
        code: '63681320',
        text:
          'Кому должен сообщить взрывник при возникновении аварийной ситуации в процессе заряжания?',
        answers: [
          'Руководителю взрывных работ',
          'Рабочему, привлекаемому в помощь взрывнику',
          'Техническому инженеру организации',
          'Диспетчеру',
        ],
        correctAnswers: ['Руководителю взрывных работ'],
      },
      {
        code: '63681321',
        text:
          'Кем проверяется подготовленный к массовому взрыву подэтаж (блок, панель)?',
        answers: [
          'Горным инженером эксплуатирующей организации',
          'Руководителем рудника (шахты, объекта строительства)',
          'Руководителем взрывных работ',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681322',
        text:
          'Что должны обеспечивать конструкция и схема колонной устьевой обвязки, фонтанной арматуры?',
        answers: [
          'Контроль температуры в стволе во время проведения работ при глушении скважины',
          'Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры',
          'Контроль режима работы при бурении и подземном ремонте скважин, а также возможность безопасного проведения работ внутри скважин',
          'Возможность обеспечения капитального ремонта объектов горнодобычи',
        ],
        correctAnswers: [
          'Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры',
        ],
      },
      {
        code: '63681323',
        text:
          'Каким должно быть расстояние между стенками стволов при строительстве объекта подземного строительства на участках недр, склонных и опасных по горным ударам?',
        answers: [
          'Не более 37 м',
          'Не менее 70 м',
          'Не менее 50 м',
          'Не более 40 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63681324',
        text:
          'С какой периодичностью и при выполнении каких условий должна проводиться оценка несущей способности применяемых анкеров при проведении (восстановлении) выработок?',
        answers: [
          'Оценка проводится с применением прибора контроля анкерной крепи в срок не реже одного раза в месяц через каждые 300 м длины горной выработки',
          'Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки',
          'Оценка проводится при помощи штанговыдергивателя не реже одного раза в 6 месяцев через каждые 100 м длины горной выработки',
          'Оценка проводится при помощи средств мониторинга анкерной крепи не реже одного раза в 3 месяца через каждые 50 м длины горной выработки',
        ],
        correctAnswers: [
          'Оценка проводится при помощи штанговыдергивателя не реже одного раза в месяц через каждые 200 м длины горной выработки',
        ],
      },
      {
        code: '63681325',
        text:
          'Кто включается в состав комиссии по комплексному обследованию состояния как объектов, подлежащих охране от вредного влияния горных разработок, так и программы ведения горных работ (горные меры)?',
        answers: [
          'Только представители органов Ростехнадзора',
          'Только представители организации, эксплуатирующей объект для добычи полезных ископаемых',
          'Только представители органов Роснедр',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63681326',
        text:
          'Кто осуществляет перевод подземного пожара из действующего в потушенный на угольной шахте?',
        answers: [
          'Руководитель маркшейдерской службы и начальник производственного участка',
          'Руководитель горноспасательных работ',
          'Ответственные лица подразделения главного управления МЧС',
          'Комиссия, созданная распорядительным документом руководителя шахты',
        ],
        correctAnswers: [
          'Комиссия, созданная распорядительным документом руководителя шахты',
        ],
      },
      {
        code: '63681327',
        text:
          'Какой орган осуществляет лицензирование производства маркшейдерских работ?',
        answers: [
          'Росприроднадзор',
          'Ростехнадзор',
          'Роснедра',
          'Роскартография',
        ],
        correctAnswers: ['Ростехнадзор'],
      },
      {
        code: '63681328',
        text:
          'На каком расстоянии от забоя должна быть установлена анкерная крепь при условии наличия в кровле горной выработки неустойчивых пород, обрушающихся в проходческом забое?',
        answers: [
          'Не более 1,3 м',
          'Не менее 2 м',
          'Не более 1,5 м',
          'Не более 1 м',
        ],
        correctAnswers: ['Не более 1 м'],
      },
      {
        code: '63681329',
        text:
          'Какие ограничения предусмотрены для перфорации обсадных колонн при проведении ремонтно-изоляционных работ в процессе проводки ствола скважины?',
        answers: [
          'Запрещается интервал перфорации при проведении ремонтно-изоляционных работ перекрывать песчаной пробкой или цементным мостом',
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале проницаемых горизонтов',
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора',
        ],
        correctAnswers: [
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора',
        ],
      },
      {
        code: '63681330',
        text:
          'Какое действие из перечисленных допускается при ведении взрывных работ?',
        answers: [
          'Переломы выходящих из зарядов концов детонирующего шнура',
          'Взрывание зарядов без забойки на шахтах (рудниках), опасных по газу и пыли',
          'Тянуть провода электронных детонаторов и электродетонаторов, введенные в боевики',
          'Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах',
        ],
        correctAnswers: [
          'Применять рассредоточенные заряды в шахтах, опасных по газу или пыли, в породных забоях выработок, в которых отсутствует выделение горючих газов, во врубовых шпурах',
        ],
      },
      {
        code: '63681331',
        text:
          'Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней винтового профиля из стали А400С, А500С N 25?',
        answers: ['185 кН', '120 кН', '240 кН', '90 кН'],
        correctAnswers: ['240 кН'],
      },
      {
        code: '63681332',
        text:
          'Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?',
        answers: [
          'Универсальной перфорационной задвижкой',
          'Запорной арматурой и сальниковым устройством для герметизации штока',
          'Трехходовым клапаном и сальниковым устройством',
          'Запорной арматурой и обратным клапаном',
          'Устройством звуковой и световой сигнализации для контроля утечек',
        ],
        correctAnswers: [
          'Запорной арматурой и сальниковым устройством для герметизации штока',
        ],
      },
      {
        code: '63681333',
        text:
          'Что является основной задачей комиссий по предупреждению и ликвидации чрезвычайных ситуаций и обеспечению пожарной безопасности в соответствии с их компетенцией?',
        answers: [
          'Только рассмотрение вопросов о привлечении сил и средств гражданской обороны к организации и проведению мероприятий по предотвращению и ликвидации чрезвычайных ситуаций',
          'Только разработка предложений по реализации государственной политики в области предупреждения и ликвидации чрезвычайных ситуаций и обеспечения пожарной безопасности;',
          'Только рассмотрение вопросов об организации оповещения и информирования населения о чрезвычайных ситуациях',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681334',
        text:
          'Каким должно быть максимальное расстояние между смежными лавами при последовательном проветривании?',
        answers: ['250 м', '100 м', '300 м', '200 м'],
        correctAnswers: ['300 м'],
      },
      {
        code: '63681335',
        text:
          'С какой периодичностью следует контролировать по результатам лабораторного анализа проб отложившейся в горных выработках угольной пыли в местах интенсивного пылеотложения?',
        answers: [
          'Не реже одного раза в три месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже двух раз в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63681336',
        text:
          'При вертикальных стволах какой глубины может отсутствовать второй механизированный выход?',
        answers: [
          'Глубиной до 30 м',
          'Глубиной до 100 м',
          'Глубиной до 200 м',
          'Глубиной до 70 м',
        ],
        correctAnswers: ['Глубиной до 70 м'],
      },
      {
        code: '63681337',
        text:
          'Какое напряжение допускается для питания подземных осветительных установок и для ручных переносных светильников, питаемых от искробезопасных источников, на угольных шахтах?',
        answers: [
          'Не выше 127 В и не выше 12 В соответственно',
          'Не выше 380 В и не выше 50 В соответственно',
          'Не выше 120 В и не выше 36 В соответственно',
          'Не выше 220 В и не выше 42 В соответственно',
        ],
        correctAnswers: ['Не выше 220 В и не выше 42 В соответственно'],
      },
      {
        code: '63681338',
        text:
          'В каких случаях производится забуривание новых (боковых) стволов в обсаженных скважинах?',
        answers: [
          'Только при ликвидации аварий, инцидентов и осложнений (смятии эксплуатационной колонны, заклинивании инструмента, незапланированном цементировании колонны бурильных или лифтовых труб), возникших в процессе бурения',
          'Только при вскрытии дополнительных продуктивных мощностей путем проводки ответвлений (в т. ч. горизонтальных) из пробуренных стволов скважин',
          'Только при восстановлении бездействующего фонда скважин (включая ранее ликвидированные) с учетом достаточной сохранности крепи скважины и экономической целесообразности в целях вскрытия новым стволом участков с неизвлеченными запасами углеводородного сырья',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '63681339',
        text:
          'Какие из перечисленных требований к оформлению сведений об использованных источниках в проектной документации на разработку месторождений углеводородного сырья указаны верно?',
        answers: [
          'Ссылки об использованных источниках печатаются с абзацного отступа',
          'Сведения располагаются в порядке появления ссылок на источники в тексте отчета',
          'Ссылки на источники нумеруются арабскими цифрами без точки',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63681340',
        text:
          'Какие из перечисленных помещений разрешается размещать в зданиях пунктов производства и подготовки взрывчатых веществ, за исключением зданий, в которых непосредственно производятся или подготавливаются взрывчатые вещества? Выберите 2 варианта ответа.',
        answers: [
          'Места постоянного хранения тары, отведенные в рабочем помещении',
          'Служебные помещения для производства сварочных работ',
          'Помещения временного пребывания дежурных слесарей и электриков',
          'Слесарные мастерские для мелкого текущего ремонта',
        ],
        correctAnswers: [
          'Помещения временного пребывания дежурных слесарей и электриков',
          'Слесарные мастерские для мелкого текущего ремонта',
        ],
      },
      {
        code: '63681341',
        text:
          'В течение какого срока передаются материалы по мерам охраны объектов от вредного влияния горных разработок в Ростехнадзор при несогласии заинтересованной организации с решением по ее возражению?',
        answers: [
          'В четырнадцатидневный срок',
          'В трехдневный срок',
          'В семидневный срок',
          'В десятидневный срок',
        ],
        correctAnswers: ['В десятидневный срок'],
      },
      {
        code: '63681342',
        text:
          'В соответствии с какой документацией проводится объединение угольных шахт с независимым проветриванием в одну вентиляционную систему?',
        answers: [
          'В соответствии с Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых',
          'В сответствии с требованиями Федерального агентства по недропользованию',
          'В соответствии с технологическим регламентом на проведение горных работ',
          'В соответствии с проектной документацией',
        ],
        correctAnswers: ['В соответствии с проектной документацией'],
      },
      {
        code: '63681343',
        text:
          'Каким образом очищается оборудование при наличии несмываемых остатков взрывчатых веществ по окончании заряжания? Укажите все правильные ответы.',
        answers: [
          'Паром',
          'Горячей водой',
          'Сжатым воздухом',
          'Металлическим инструментом',
        ],
        correctAnswers: ['Паром', 'Горячей водой'],
      },
      {
        code: '63681344',
        text:
          'Какое из перечисленных условий допуска работников на рабочие места после производства массовых взрывов указано верно?',
        answers: [
          'Частичная видимость при осмотре места (мест) взрыва и допуске работников',
          'Неполное (менее 60 %) рассеивание пылевого облака',
          'Допуск не ранее чем через 15 минут после взрыва',
          'Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК',
        ],
        correctAnswers: [
          'Получение ответственным руководителем массового взрыва сообщения о снижении концентрации ядовитых продуктов взрыва в воздухе до установленных ПДК',
        ],
      },
      {
        code: '63681345',
        text:
          'В какой срок после начала проведения горных выработок, оконтуривающих выемочный участок, определяется фрикционная опасность горных пород для каждого выемочного участка?',
        answers: [
          'Не более одного месяца',
          'Не более одной недели',
          'В течение первых двух недель',
          'Не более двух месяцев',
        ],
        correctAnswers: ['Не более одного месяца'],
      },
      {
        code: '63681346',
        text:
          'При каком условии разрешается выход взрывника из укрытия после взрыва при взрывании с применением электродетонаторов?',
        answers: [
          'Только после времени ожидания не менее 5 минут',
          'Только после проветривания',
          'Только после отсоединения электровзрывной сети от источника тока и замыкания ее накоротко',
          'При выполнении всех перечисленных условий',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63681347',
        text:
          'Каким документом устанавливаются периодичность и способы проверки состояния обсадных колонн по мере их износа и необходимые мероприятия по обеспечению безопасной проводки и эксплуатации скважин?',
        answers: [
          'Методическими указаниями, разработанными в соответствии с проектом производства работ',
          'Правилами безопасности при ведении горных работ и переработке твердых полезных ископаемых',
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
          'Техническим регламентом, утвержденным территориальным органом Ростехнадзора',
        ],
        correctAnswers: [
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
        ],
      },
      {
        code: '63681348',
        text:
          'Разрешается ли ведение очистных работ более чем в двух смежных этажах согласно Правилам безопасности в угольных шахтах?',
        answers: [
          'Разрешается только в порядке, указанном в документации проведения горных работ',
          'Правилами не регламентируется',
          'Разрешается',
          'Запрещается',
        ],
        correctAnswers: ['Запрещается'],
      },
      {
        code: '63681349',
        text:
          'В какой период, установленный Правилами подготовки, рассмотрения и согласования планов и схем развития горных работ по видам полезных ископаемых, осуществляется рассмотрение органами Ростехнадзора планов и схем развития горных работ?',
        answers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
          'С 1 по 31 декабря года, предшествующего планируемому',
          'С 10 января по 31 марта текущего года',
          'С 15 по 25 декабря года, предшествующего планируемому',
        ],
        correctAnswers: [
          'С 20 сентября по 25 декабря года, предшествующего планируемому',
        ],
      },
      {
        code: '63681350',
        text:
          'Организации, эксплуатирующие объекты, на которых ведутся горные работы и переработка полезных ископаемых, обязаны осуществлять:',
        answers: [
          'Геодезическое обеспечение горных работ',
          'Маркшейдерское обеспечение работ',
          'Инженерное обеспечение открытых горных работ',
        ],
        correctAnswers: ['Маркшейдерское обеспечение работ'],
      },
      {
        code: '63681351',
        text:
          'Какая допускается максимальная скорость движения железнодорожного подвижного состава с опасными грузами на территории пункта подготовки взрывчатых веществ?',
        answers: ['20 км/ч', '30 км/ч', '15 км/ч', '10 км/ч'],
        correctAnswers: ['15 км/ч'],
      },
      {
        code: '63681352',
        text:
          'Каким образом проводится одновременная разработка месторождения подземным и открытым способами?',
        answers: [
          'Должен быть составлен согласованный с аварийно-спасательной службой график работ по разработке месторождения',
          'Должны быть проведены совместные мероприятия по обеспечению безопасности работающих на объектах ведения подземных и открытых горных работ, утвержденные техническим руководителем организации',
          'В составе сменного технического надзора должно быть не менее трех сотрудников, осуществляющих допуск работников к выполнению работ',
        ],
        correctAnswers: [
          'Должны быть проведены совместные мероприятия по обеспечению безопасности работающих на объектах ведения подземных и открытых горных работ, утвержденные техническим руководителем организации',
        ],
      },
      {
        code: '63681353',
        text:
          'При каких углах падения пласта производится выемка надштрековых целиков у вентиляционных штреков одновременно с отработкой лав нижележащего этажа?',
        answers: ['До 30°', 'До 40°', 'До 35°'],
        correctAnswers: ['До 30°'],
      },
      {
        code: '63681354',
        text:
          'Какие фонтанные скважины должны оснащаться внутрискважинным оборудованием (пакер и клапан-отсекатель, циркуляционный клапан, станция управления)?',
        answers: [
          'Фонтанные скважины с дебитом от 300 т/сут нефти или 400 тыс.м³/сут газа и более, расположенные на расстоянии менее 1 км от населенного пункта',
          'Фонтанные скважины с дебитом от 350 т/сут нефти и более',
          'Фонтанные скважины расположенные на расстоянии менее 1,5 км от населенного пункта',
          'Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс.м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта',
        ],
        correctAnswers: [
          'Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс.м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта',
        ],
      },
      {
        code: '63681355',
        text:
          'Как должны быть ограждены устья действующих и находящихся в проходке вертикальных и наклонных горных выработок, оборудованных подъемными установками?',
        answers: [
          'Должны быть ограждены с нерабочих сторон стенками или металлической сеткой высотой не менее 2,5 м, а с рабочих сторон иметь решетки или двери, оборудованные блокировкой, включающей сигнал «Стоп» у машиниста при открытых дверях',
          'Должны ограждаться с нерабочих сторон глухими стенками высотой не менее 2,3 м, а с рабочих сторон иметь автоматические ворота',
          'Должны быть ограждены с нерабочих сторон металлической сеткой высотой не менее 2 м, а с рабочих сторон иметь двери, оборудованные автоматически включаемой блокировкой',
          'Должны иметь металлические ограждения со всех сторон высотой 1, 5 м, оборудованные дверями с запорным механизмом',
        ],
        correctAnswers: [
          'Должны быть ограждены с нерабочих сторон стенками или металлической сеткой высотой не менее 2,5 м, а с рабочих сторон иметь решетки или двери, оборудованные блокировкой, включающей сигнал «Стоп» у машиниста при открытых дверях',
        ],
      },
      {
        code: '63681356',
        text:
          'При каких условиях допускается подключать сетевой кабель к пусковому оборудованию электронагревателя?',
        answers: [
          'Только после завершения всех подготовительных работ в скважине и на устье',
          'После заземления электрооборудования',
          'После подключения кабель-троса к трансформатору',
          'Только после удаления людей с участка проведения работ',
          'После выполнения всех условий',
        ],
        correctAnswers: ['После выполнения всех условий'],
      },
      {
        code: '63681357',
        text:
          'Какие два дополнительных основных требования должны выполняться при отработке сближенных калийно-магниевых и соляных пластов?',
        answers: [
          'Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 30 м',
          'Соосное расположение целиков с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м',
          'Соосное расположение очистных камер, с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м',
          'Соосное расположение очистных камер, с опережением фронта очистных работ по верхнему пласту не менее чем на 75 м',
        ],
        correctAnswers: [
          'Соосное расположение очистных камер, с опережением фронта очистных работ по верхнему пласту не менее чем на 50 м',
        ],
      },
      {
        code: '63681358',
        text:
          'Кем могут проводиться наблюдения за процессом сдвижения земной поверхности (основания сооружения) под влиянием горных работ и за охраняемыми поверхностными и подземными объектами?',
        answers: [
          'Только маркшейдерской службой предприятия',
          'Только специализированными организациями, привлекаемыми предприятиями по добыче полезных ископаемых',
          'Только предприятиями, эксплуатирующими охраняемые объекты',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63681359',
        text:
          'С какой периодичностью маркшейдерская служба шахты или специализированная организация, имеющая на это право, выполняет полную проверку геометрической связи шахтного подъема и копра?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в смену',
          'Не реже двух раз в год',
          'Не реже одного раза в три года',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '63681360',
        text:
          'Какая максимально допустимая концентрация оксида углерода в рудничной атмосфере действующих горных выработках?',
        answers: ['20 мг/м3', '10 мг/м3', '7 мг/м3', '30 мг/м3'],
        correctAnswers: ['20 мг/м3'],
      },
      {
        code: '63681361',
        text:
          'Какое действие необходимо выполнять для уменьшения пыления и просыпи взрывчатых веществ?',
        answers: [
          'При заряжании центрировать конец зарядного трубопровода относительно оси скважины',
          'Выдерживать оптимальное расстояние от конца шланга до заряда',
          'При пневмозаряжании алюмо- и тротилсодержащими рассыпными гранулированными взрывчатыми веществами добавлять во взрывчатые вещества воду или смачивающий раствор',
          'Все перечисленные действия',
        ],
        correctAnswers: ['Все перечисленные действия'],
      },
      {
        code: '63681362',
        text:
          'Какие из перечисленных требований к оформлению проектной документации на разработку месторождений углеводородного сырья указаны верно?',
        answers: [
          'Проектная документация выполняется на обеих сторонах листа бумаги формата АЗ',
          'Страницы текста проектной документации должны соответствовать формату листа A4, для таблиц допускается формат A3',
          'Проектная документация оформляется только в электронном виде',
          'Высота строчных букв должна составлять 14 мм',
        ],
        correctAnswers: [
          'Страницы текста проектной документации должны соответствовать формату листа A4, для таблиц допускается формат A3',
        ],
      },
      {
        code: '63681363',
        text:
          'На каком расстоянии от ствола может быть установлена вентиляторная установка?',
        answers: [
          'Не более 5 метров от ствола',
          'Не менее 15 метров от ствола',
          'Не менее 10 метров от ствола',
        ],
        correctAnswers: ['Не менее 15 метров от ствола'],
      },
      {
        code: '63681364',
        text:
          'Что из перечисленного может использоваться на участковых пунктах в качестве шкафов (контейнеров) для взрывчатых материалов? Укажите все правильные ответы.',
        answers: [
          'Сумки с жесткими ячейками, покрытыми внутри мягким материалом',
          'Ящики, изготовленные из гипсоволокнистых листов',
          'Шахтные вагонетки, оборудованные металлическими крышками',
          'Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм',
        ],
        correctAnswers: [
          'Шахтные вагонетки, оборудованные металлическими крышками',
          'Металлические сейфы, изготовленные из металлических листов толщиной не менее 2 мм',
        ],
      },
      {
        code: '63681365',
        text:
          'В каком случае допускается оставлять взрывчатые материалы на местах работ без постоянного надзора (охраны)?',
        answers: [
          'В случае, если взрывчатые материалы находятся в отдельных металлических ящиках и контейнерах (сейфах)',
          'В случае, если взрывчатые материалы защищены от атмосферных осадков',
          'В случаях, утвержденных распорядительным документом организации, ведущей взрывные работы',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681366',
        text:
          'Влияние каких профилактических мероприятий на геомеханическое состояние вмещающих пород не требуется учитывать при расчете параметров анкерной крепи горных выработок, проводимых по пластам, склонным к горным ударам и внезапным выбросам угля (породы) и газа?',
        answers: [
          'Выполнение гидрообработки угольного массива',
          'Региональная предварительная подработка и (или) надработка массива с последующим проведением в нем подготовительных горных выработок',
          'Бурение разгрузочных скважин в окружающем угольном массиве в период проведения горной выработки',
          'Контроль за скоростью вращения инструмента при бурении давлением на манометре насоса, выносом из скважины крупных обломков породы',
        ],
        correctAnswers: [
          'Контроль за скоростью вращения инструмента при бурении давлением на манометре насоса, выносом из скважины крупных обломков породы',
        ],
      },
      {
        code: '63681367',
        text:
          'Каким лицам право руководства взрывными работами предоставляется после дополнительного обучения по программе, согласованной с федеральным органом исполнительной власти в области промышленной безопасности?',
        answers: [
          'Лицам, окончившим высшие учебные заведения по специальности "Открытые горные работы"',
          'Лицам, окончившим высшие учебные заведения по специальности "Подземная разработка пластовых месторождений"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Шахтное строительство"',
          'Лицам, окончившим средние специальные учебные заведения по специальности "Подземная разработка месторождений полезных ископаемых"',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681368',
        text:
          'Какой должна быть требуемая минимальная плотность установки анкеров в классе неустойчивой кровли?',
        answers: [
          'Не меньше 1 анк/м2',
          'Не меньше 0,7 анк/м2',
          'Не больше 0,9 анк/м2',
          'Равной 0,3 анк/м2',
        ],
        correctAnswers: ['Не меньше 1 анк/м2'],
      },
      {
        code: '63681369',
        text:
          'Какой срок действия лицензии установлен на производство маркшейдерских работ?',
        answers: ['Бессрочный', '3 года', '5 лет', '2 года'],
        correctAnswers: ['Бессрочный'],
      },
      {
        code: '63681370',
        text:
          'При каком размере целика между встречными забоями работы должны проводиться только из одного забоя?',
        answers: ['1 м', '5 м', '3 м', '7 м'],
        correctAnswers: ['7 м'],
      },
      {
        code: '63681371',
        text:
          'Что из перечисленного входит в перечень задач государственного надзора за безопасным ведением работ, связанных с пользованием недрами?',
        answers: [
          'Только предупреждение нарушений требований по безопасному ведению работ, связанных с пользованием недрами, лицами, осуществляющими работы на участках недр',
          'Только выявление и пресечение нарушений пользователями недр требований по безопасному ведению работ, связанных с пользованием недрами',
          'Все перечисленное входит в перечень задач государственного надзора за безопасным ведением работ',
        ],
        correctAnswers: [
          'Все перечисленное входит в перечень задач государственного надзора за безопасным ведением работ',
        ],
      },
      {
        code: '63681372',
        text:
          'Какое из перечисленных требований при применении средств инициирования запрещается?',
        answers: [
          'Обматывать детонирующий шнур вокруг патрона взрывчатых веществ',
          'Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов',
          'Изготавливать боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов из патронов (шашек) с гнездами заводского изготовления',
          'Завязывать узлом или складываться вдвое конец детонирующего шнура при изготовлении промежуточных детонаторов из порошкообразных патронированных взрывчатых веществ',
        ],
        correctAnswers: [
          'Расширять гнезда патронов (шашек) заводского изготовления, из которых изготовлены боевики из прессованных или литых взрывчатых веществ с инициированием от детонаторов',
        ],
      },
      {
        code: '63681373',
        text:
          'Чем и в зависимости от чего устанавливается скорость движения поездов на железнодорожных путях объекта открытых горных работ?',
        answers: [
          'Скорость движения устанавливается проектом в зависимости от дальности расстояния транспортировки вскрышных пород и объема добытого полезного ископаемого',
          'Скорость движения устанавливается техническим регламентом в зависимости от местных условий и производительности горнодобывающего предприятия',
          'Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий',
        ],
        correctAnswers: [
          'Скорость движения устанавливается внутренней инструкцией по эксплуатации железнодорожного транспорта организации в зависимости от применяемого подвижного состава, верхнего строения и профиля пути, а также местных условий',
        ],
      },
      {
        code: '63681374',
        text:
          'Какую площадь должен иметь размер земельного участка для производства буровых работ?',
        answers: [
          'Равную 1 000 м²',
          'Обеспечивающую соблюдение требований промышленной безопасности',
          '0,1 га',
          'Не превышающую 1 га',
        ],
        correctAnswers: [
          'Обеспечивающую соблюдение требований промышленной безопасности',
        ],
      },
      {
        code: '63681375',
        text:
          'Какое из перечисленных требований к поверхностным постоянным складам указано неверно?',
        answers: [
          'Склады должны иметь запретную зону шириной от ограждения не менее 50 м',
          'На границах запретной зоны постоянных складов должны устанавливаться предупредительные знаки',
          'Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации',
          'Хранилища должны располагаться так, чтобы обеспечивался свободный подъезд к каждому из них',
        ],
        correctAnswers: [
          'Расстояния между отдельными хранилищами, между иными зданиями и сооружениями на территории склада, а также до объектов за территорией должны быть установлены техническим руководителем организации',
        ],
      },
      {
        code: '63681376',
        text:
          'Какое требование относится к хранилищам, имеющим рампы и средства механизации погрузочно-разгрузочных работ?',
        answers: [
          'Необходимо оборудовать не менее трех тамбуров',
          'Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями',
          'Вход в каждый из тамбуров должен быть оборудован двумя двустворчатыми дверями',
          'Вторая дверь в тамбурах может быть решетчатой, деревянной или металлической',
        ],
        correctAnswers: [
          'Устройство тамбуров не обязательно, но вход следует оборудовать не менее чем двумя двустворчатыми дверями',
        ],
      },
      {
        code: '63681377',
        text:
          'Каким должно быть расстояние между параллельно проводимыми выработками угольных и нефтяных шахт, при котором взрывание зарядов в каждом забое должно проводиться только после вывода людей из забоя параллельной выработки и выставления постов охраны, предусмотренных паспортами буровзрывных работ?',
        answers: [
          '30 м и менее',
          '20 м и менее',
          '15 м и менее',
          '25 м и менее',
        ],
        correctAnswers: ['15 м и менее'],
      },
      {
        code: '63681378',
        text:
          'В каких горных выработках применяются анкеры с закреплением стержней минеральной композицией?',
        answers: [
          'Только в горных выработках с обводненными породами',
          'Только в горных выработках, пройденных по пластам весьма склонных к самовозгоранию',
          'Только в горных выработках со сроком службы более 10 лет',
          'В любой из перечисленных горных выработках',
        ],
        correctAnswers: ['В любой из перечисленных горных выработках'],
      },
      {
        code: '63681379',
        text:
          'В какой системе координат должны быть определены уточненные границы горного отвода?',
        answers: [
          'В прямоугольной системе координат',
          'В географической системе координат (широта, долгота)',
          'В полярной системе координат (азимут, горизонтальное проложение)',
        ],
        correctAnswers: ['В прямоугольной системе координат'],
      },
      {
        code: '63681380',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Основная надпись (штамп) с указанием наименования объекта недропользования (участка недр)',
          'Название, масштаб графического документа',
          'Подписи лиц (с указанием должности), ответственных за составление графических материалов',
          'Дата составления графических материалов',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681381',
        text:
          'Кем утверждается проект работ и мероприятий по ликвидации последствий горного удара?',
        answers: [
          'Техническим руководителем объекта',
          'Руководителем горнодобывающей организации',
          'Председателем комиссии по ликвидации последствий горного удара',
          'Специализированной организацией, выполняющей работы по проблеме горных ударов',
        ],
        correctAnswers: ['Техническим руководителем объекта'],
      },
      {
        code: '63681382',
        text:
          'Разрешается ли ведение горных работ вблизи затопленных выработок или поверхностных водоемов?',
        answers: [
          'Разрешается по согласованию с собственником участка недр',
          'Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды',
          'Не разрешается',
        ],
        correctAnswers: [
          'Разрешается по проекту, предусматривающему оставление целиков для предотвращения прорыва воды',
        ],
      },
      {
        code: '63681383',
        text:
          'Кто утверждает специальный план, в соответствии с которым производится вскрытие пожарного участка?',
        answers: [
          'Инспектор пожарного надзора',
          'Технический руководитель организации',
          'Руководитель территориального органа Ростехнадзора',
        ],
        correctAnswers: ['Технический руководитель организации'],
      },
      {
        code: '63681384',
        text:
          'На какое расстояние при одновременной отработке нескольких подэтажей системой подэтажного обрушения каждый верхний подэтаж должен опережать нижний?',
        answers: [
          'На расстояние не менее чем на длину, равную высоте двух подэтажей',
          'На расстояние, установленное проектом и исключающее обрушение верхнего подэтажа на нижний',
          'На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа',
        ],
        correctAnswers: [
          'На расстояние, устанавливаемое проектом, но не менее чем на длину, равную высоте одного подэтажа',
        ],
      },
      {
        code: '63681385',
        text:
          'Каким образом допускается исправлять опечатки, описки и графические неточности, обнаруженные в процессе подготовки проектного документа на разработку месторождений углеводородного сырья?',
        answers: [
          'Подчисткой или закрашиванием белой краской и нанесением на том же месте исправленного текста (графики)',
          'Путем составления акта, прилагающегося к проектной документации',
          'Исправление ошибок не допускается ни в каком случае',
          'Внесением текста (графики) рядом с опечаткой, заверенного подписью лица, внесшего правки',
        ],
        correctAnswers: [
          'Подчисткой или закрашиванием белой краской и нанесением на том же месте исправленного текста (графики)',
        ],
      },
      {
        code: '63681386',
        text:
          'В каком случае разрешается доставка аммиачно-селитренных взрывчатых веществ к местам проведения взрывных работ в подземных выработках в ковшах погрузочно-доставочных машин от участковых пунктов хранения и мест выгрузки взрывчатых материалов?',
        answers: [
          'При условии загрузки ковша не более 2/3 по его высоте',
          'При условии загрузки ковша не более 1/2 по его высоте вместе со средствами инициирования',
          'Ни в каком случае',
          'При условии присутствия в транспорте сопровождающего персонала, ответственного за доставку взрывчатых материалов',
        ],
        correctAnswers: [
          'При условии загрузки ковша не более 2/3 по его высоте',
        ],
      },
      {
        code: '63681387',
        text:
          'Кто осматривает подвесные устройства проходческого оборудования и все узлы крепления канатов в стволе один раз в месяц?',
        answers: [
          'Технический руководитель организации',
          'Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок',
          'Начальник рудника (шахты)',
          'Механик по ремонту горного оборудования',
        ],
        correctAnswers: [
          'Главный механик шахты или организации, выполняющей работы по проведению наклонных или вертикальных горных выработок',
        ],
      },
      {
        code: '63681388',
        text:
          'Каким органом определяются требования к структуре и оформлению проектной документации по видам полезных ископаемых и видам пользования недрами?',
        answers: [
          'Федеральной службой по надзору в сфере природопользования',
          'Министерством природных ресурсов и экологии Российской Федерации',
          'Федеральным агентством по недропользованию',
          'Федеральной службой по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Министерством природных ресурсов и экологии Российской Федерации',
        ],
      },
      {
        code: '63681389',
        text:
          'Каким образом следует проводить бурение шпуров по углю для сотрясательного взрывания?',
        answers: [
          'Ударным способом',
          'Ударно-вращательным способом',
          'Вращательным способом',
          'В соответствии с решением руководителя сотрясательного взрывания',
        ],
        correctAnswers: ['Вращательным способом'],
      },
      {
        code: '63681390',
        text:
          'Каким образом должно осуществляться передвижение людей по территории объектов ведения горных работ и переработке полезных ископаемых?',
        answers: [
          'Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта',
          'Передвижение людей по территории объекта открытых горных работ допускается только по специально выделенному маршруту',
          'Пути передвижения по территории объекта открытых горных работ определяют сами работающие на объекте, соблюдая меры безопасности движения',
          'Пешеходное движение по территории объекта открытых горных работ допускается только по направлению движения автотранспорта',
        ],
        correctAnswers: [
          'Передвижение людей по территории объекта открытых горных работ допускается по специально устроенным пешеходным дорожкам или по обочинам автодорог навстречу направлению движения автотранспорта',
        ],
      },
      {
        code: '63681391',
        text:
          'Какую расчетную несущую способность по всей длине скважины должны обеспечивать анкеры из стержней диаметром 20 мм с метрической резьбой из стали Ст.5?',
        answers: ['60 кН', '85 кН', '130 кН', '100 кН'],
        correctAnswers: ['100 кН'],
      },
      {
        code: '63681392',
        text:
          'Что из нижеперечисленного не содержится в рабочем проекте на производство буровых работ?',
        answers: [
          'Ф. И. О. ответственного лица за производство буровых работ',
          'Обоснование конструкции скважины',
          'Методы оценки состояния обсадных колонн, способы и периодичность их испытания на остаточную прочность',
          'Геолого-технический наряд на производство буровых работ',
        ],
        correctAnswers: [
          'Ф. И. О. ответственного лица за производство буровых работ',
        ],
      },
      {
        code: '63681393',
        text:
          'Какой документ является базовым для разработки паспортов и проектов буровзрывных (взрывных) работ, в том числе и проектов массовых взрывов, за исключением специальных и экспериментальных массовых взрывов в подземных выработках, выполняемых в конкретных условиях?',
        answers: [
          'Согласованный с Ростехнадзором план проведения взрывных работ',
          'Типовой проект буровзрывных (взрывных) работ',
          'Руководство по проведению буровзрывных работ',
          'Утвержденная программа проведения взрывных работ',
        ],
        correctAnswers: ['Типовой проект буровзрывных (взрывных) работ'],
      },
      {
        code: '63681394',
        text:
          'В соответствии с какими документами должна устанавливаться скорость изменения технологических параметров?',
        answers: [
          'В соответствии со схемами развития горных работ',
          'В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования',
          'В соответствии с планами проведения работ, утвержденными пользователями недр',
          'В соответствии с нормами и правилами промышленной безопасности при ведении горных работ',
          'В соответствии с нарядами-допусками на производство работ',
        ],
        correctAnswers: [
          'В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования',
        ],
      },
      {
        code: '63681395',
        text:
          'С какой периодичностью осуществляют профильную съемку армировки и замер зазоров безопасности на угольных шахтах?',
        answers: [
          'Не реже одного раза в полугодие',
          'Не реже одного раза в месяц',
          'Не реже одного раза в два года',
          'Не реже двух раз в год',
        ],
        correctAnswers: ['Не реже одного раза в два года'],
      },
      {
        code: '63681396',
        text:
          'Максимально допустимая скорость воздуха в каких горных выработках угольных шахт составляет 4 м/с?',
        answers: [
          'В главных откаточных и вентиляционных штреках',
          'В очистных и тупиковых горных выработках',
          'В вертикальных горных выработках',
          'В открытых горных выработках',
        ],
        correctAnswers: ['В очистных и тупиковых горных выработках'],
      },
      {
        code: '63681397',
        text:
          'Где должен находиться руководитель сотрясательного взрывания в забое, замеряющий содержание метана, при продвижении к забою для осмотра его после сотрясательного взрывания?',
        answers: [
          'На расстоянии 3 м позади взрывника',
          'На расстоянии 3 м впереди взрывника',
          'На расстоянии 4,5 м позади взрывника',
          'На расстоянии 5 м впереди взрывника',
          'Не регламентируется',
        ],
        correctAnswers: ['На расстоянии 3 м впереди взрывника'],
      },
      {
        code: '63681398',
        text:
          'Разрешается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений последовательное соединение заземляющим проводником нескольких аппаратов или резервуаров?',
        answers: [
          'Разрешается',
          'Не допускается, за исключением аппаратов или резервуаров, находящихся в одном обваловании',
          'Не допускается',
          'Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63681399',
        text:
          'Какой вид пылевзрывозащиты применяется в шахтах для локализации и предупреждения взрывов угольной пыли?',
        answers: [
          'Только гидропылевзрывозащита',
          'Только сланцевая пылевзрывозащита',
          'Только комбинированная пылевзрывозащита',
          'Возможно применение всех перечисленных видов пылевзрывозащиты',
        ],
        correctAnswers: [
          'Возможно применение всех перечисленных видов пылевзрывозащиты',
        ],
      },
      {
        code: '63681400',
        text:
          'Какую температуру должен иметь воздух, поступающий в подземные горные выработки?',
        answers: ['Не менее 0 °С', 'Равную 1 °C', 'Не менее 2 °C'],
        correctAnswers: ['Не менее 2 °C'],
      },
      {
        code: '63681401',
        text:
          'По истечении какого срока эксплуатации анкерной крепи в выработках должна быть проведена научно-исследовательская работа с оценкой несущей способности анкеров, коррозионного износа и работоспособности анкерной крепи?',
        answers: ['7 лет', '10 лет', '12 лет', '5 лет'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63681402',
        text:
          'Какое определение характеризует смещение кровли более 200 мм, выделяемое при определении и выборе документации крепления в зависимости от расчетных смещений и от интенсивности проявлений горного давления?',
        answers: [
          'Горное давление повышенной интенсивности',
          'Интенсивное горное давление',
          'Горное давление средней интенсивности',
          'Небольшое горное давление',
        ],
        correctAnswers: ['Интенсивное горное давление'],
      },
      {
        code: '63681403',
        text:
          'Какой документ является основным для производства буровых работ?',
        answers: [
          'Земельный кодекс Российской Федерации',
          'Технический проект обустройства месторождения углеводородов',
          'Рабочий проект на производство буровых работ',
        ],
        correctAnswers: ['Рабочий проект на производство буровых работ'],
      },
      {
        code: '63681404',
        text:
          'Чему равно значение коэффициента снижения сопротивления пород сжатию за счет воздействия влаги при сопротивлении пород одноосному сжатию в образце 50 МПа?',
        answers: ['0,7', '0,5', '0,8', '0,6'],
        correctAnswers: ['0,5'],
      },
      {
        code: '63681405',
        text:
          'На содержание каких веществ должны анализироваться пробы воздуха, набираемые в непроветриваемой части затопленных выработок (при откачке)?',
        answers: [
          'Только на CO, CO',
          'Только на HS',
          'CO, CO, CH, HS, O и H',
          'Только на O и H',
        ],
        correctAnswers: ['CO, CO, CH, HS, O и H'],
      },
      {
        code: '63681406',
        text:
          'К какой категории шахт по метану должна быть отнесена шахта, имеющая относительную газообильность 16 м³/т?',
        answers: [
          'К I категории',
          'Ко II категории',
          'К газовой',
          'К сверхкатегорным',
        ],
        correctAnswers: ['К сверхкатегорным'],
      },
      {
        code: '63681407',
        text:
          'Какая допускается максимальная температура сжатого воздуха (при работе с автономным компрессором), а также нагрева узлов зарядных устройств, через которые проходят взрывчатые вещества?',
        answers: ['70 °C', '50 °C', '60 °C', '40 °C'],
        correctAnswers: ['60 °C'],
      },
      {
        code: '63681408',
        text:
          'Какой из приведенных нормативных документов регулирует отношения, возникающие в связи с использованием и охраной недр на территории Российской Федерации?',
        answers: [
          'Федеральный закон "О континентальном шельфе Российской Федерации"',
          'Закон Российской Федерации "О недрах"',
          'Положение о государственном контроле за геологическим изучением, рациональным использованием и охраной недр',
          'Все приведенные документы',
        ],
        correctAnswers: ['Все приведенные документы'],
      },
      {
        code: '63681409',
        text:
          'Какое из перечисленных требований по ведению горных работ в угольных шахтах является верным?',
        answers: [
          'Разрешается проведение горных работ в процессе утверждения главным инженером шахты документации по ведению горных работ',
          'Стаж работы по профессии одного из работников, выполняющего горные работы, должен быть не менее 6 месяцев',
          'Горные работы должны выполнять не менее трех работников, стаж по рабочей профессии которых должен составлять не менее 3 лет',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681410',
        text:
          'В какой срок со дня поступления заявления и проекта горного отвода осуществляется оформление документации либо принятие мотивированного решения об отказе?',
        answers: [
          'Не позднее 14 дней',
          'Не позднее 30 дней',
          'Не позднее 25 дней',
          'Не позднее 7 дней',
        ],
        correctAnswers: ['Не позднее 25 дней'],
      },
      {
        code: '63681411',
        text:
          'Что должно входить в состав планов и схем развития горных работ?',
        answers: [
          'Информация о количественном составе рабочих горнодобывающего предприятия',
          'Графическая часть и пояснительная записка с табличными материалами',
          'Результаты расчетных нагрузок инженерно-геологических испытаний',
          'Санитарно-гигиенические показатели загрязнения почвы',
        ],
        correctAnswers: [
          'Графическая часть и пояснительная записка с табличными материалами',
        ],
      },
      {
        code: '63681412',
        text:
          'Кем устанавливаются размеры призмы обрушения (сползания) породы при отвалообразовании, в пределах которой не должны разгружаться автомобили и другие транспортные средства?',
        answers: [
          'Работниками маркшейдерской службы',
          'Ответственным лицом по выпуску транспорта',
          'Техническим руководителем организации',
          'Главным инженером промплощадки',
        ],
        correctAnswers: ['Работниками маркшейдерской службы'],
      },
      {
        code: '63681413',
        text:
          'В каком случае месторождение (объект строительства подземных сооружений) будет относиться к опасному по горным ударам?',
        answers: [
          'Только если на участках месторождений происходили толчки, микроудары, горные удары',
          'Только если при определении степени удароопасности массива горных пород локальными инструментальными методами выявлена удароопасная категория',
          'Только если на месторождениях имело место интенсивное заколообразование и стреляние пород',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63681414',
        text:
          'Какие требования предъявляются правилами к системе контроля состояния воздушной среды для закрытых помещений объектов сбора, подготовки и транспортировки нефти, газа и конденсата?',
        answers: [
          'Система должна иметь блокировку от выключения технического оборудования, включая перекрытие запорной арматуры, все помещения должны иметь принудительную систему вентиляции',
          'Система должна иметь блокировку перед включением оборудования, все помещения должны иметь постоянно действующую противопожарную систему',
          'Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции',
          'Система должна быть сблокирована с аварийной системой выключения оборудования, включая перекрытие клапанов всех запитанных помещений',
        ],
        correctAnswers: [
          'Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63681415',
        text:
          'С какой периодичностью проводится пересмотр Регламента технологического процесса производства и подготовки взрывчатых веществ?',
        answers: [
          'Не реже 1 раза в 1 год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '63681416',
        text:
          'Оценка каких параметров проводится при бурении скважин (шпуров)? Укажите все правильные ответы.',
        answers: [
          'Температуры нагрева корпуса буровой коронки',
          'Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи',
          'Прочностных свойств пород',
          'Несущей способности анкеров, применяемых при креплении горной выработки',
        ],
        correctAnswers: [
          'Обводненности кровли горной выработки по скорости бурения и выходу буровой мелочи',
          'Прочностных свойств пород',
        ],
      },
      {
        code: '63681417',
        text:
          'Кто из должностных лиц организует выявление провалов земной поверхности, образовавшихся при ведении горных работ, периодический контроль их состояния и выполнение мер по их ликвидации?',
        answers: [
          'Главный инженер шахты',
          'Технический руководитель организации',
          'Руководитель маркшейдерской службы',
          'Начальник участка аэрологической безопасности шахты',
        ],
        correctAnswers: ['Главный инженер шахты'],
      },
      {
        code: '63681418',
        text:
          'К какой категории интенсивности проявления горного давления будет относиться горная выработка при расчетном смещении пород кровли свыше 200 мм?',
        answers: [
          'К II категории (горное давление средней интенсивности)',
          'К III категории (интенсивное горное давление)',
          'К I категории (небольшое горное давление)',
        ],
        correctAnswers: ['К III категории (интенсивное горное давление)'],
      },
      {
        code: '63681419',
        text:
          'В каком случае разрешаются включение и работа подземной передвижной компрессорной установки?',
        answers: [
          'При содержании метана более 1,0 % в месте расположения установки',
          'При неисправности регулятора производительности, предохранительных клапанов, манометров и термометров',
          'При отсутствии тепловой защиты',
          'При обратном вращении винтов компрессора',
          'Все ответы неверны',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63681420',
        text:
          'Каким образом на каждой шахте должно обеспечиваться проветривание двух отдельных выходов, обеспечивающих выход людей на поверхность?',
        answers: [
          'За счет естественной тяги',
          'За счет вентиляционных устройств (дверей, перемычек)',
          'Разным направлением вентиляционных струй',
        ],
        correctAnswers: ['Разным направлением вентиляционных струй'],
      },
      {
        code: '63681421',
        text:
          'В зависимости от каких характеристик проводится расчет параметров анкерной крепи и дополнительных средств ее усиления?',
        answers: [
          'Интенсивности горного давления',
          'Прочности, трещиноватости и устойчивости пород в кровле',
          'Способа и параметров охраны горных выработок от влияния очистных работ',
          'Всех перечисленных характеристик',
        ],
        correctAnswers: ['Всех перечисленных характеристик'],
      },
      {
        code: '63681422',
        text:
          'На каком минимальном расстоянии от места взрыва должно находиться место укрытия взрывников при пропуске угля и породы в восстающих выработках?',
        answers: ['100 м', '50 м', '150 м', '200 м'],
        correctAnswers: ['150 м'],
      },
      {
        code: '63681423',
        text:
          'Где устанавливается периодичность осмотров и инструментальных наблюдений за деформациями бортов, откосов, уступов и отвалов, а также объектов, попадающих в зоны влияния горных работ и расположенных на земной поверхности?',
        answers: [
          'В проекте производства маркшейдерских работ',
          'В журнале горной выработки',
          'В плане развития горных работ',
          'В журнале опытных гидрогеологических работ',
        ],
        correctAnswers: ['В проекте производства маркшейдерских работ'],
      },
      {
        code: '63681424',
        text: 'Откуда должен осуществляться пуск буровых насосов в работу?',
        answers: [
          'С местного поста управления',
          'С пульта бурильщика',
          'С пульта оператора, контролирующего технологический процесс',
          'Одновременно с пульта бурильщика и пульта управления оператора',
        ],
        correctAnswers: ['С местного поста управления'],
      },
      {
        code: '63681425',
        text:
          'Какие требования при вместимости складов и камер взрывчатых материалов указаны верно? Укажите все правильные ответы.',
        answers: [
          'Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ',
          'На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования',
          'В складах ячейкового типа в каждой ячейке разрешается хранить не более 600 кг взрывчатых веществ',
          'Предельная вместимость отдельной раздаточной камеры в подземных выработках не должна превышать 4000 кг взрывчатых веществ и соответствующего количества средств инициирования',
        ],
        correctAnswers: [
          'Вместимость камеры в складах камерного типа не должна превышать 2000 кг взрывчатых веществ',
          'На угольных и сланцевых шахтах вместимость склада без учета емкости раздаточных камер не должна превышать семисуточного запаса взрывчатых веществ и пятнадцатисуточного запаса средств инициирования',
        ],
      },
      {
        code: '63681426',
        text:
          'Кем разрабатывается и согласовывается с командиром обслуживающего аварийно-спасательного формирования план по обслуживанию массового взрыва силами аварийно-спасательных формирований? Укажите все правильные ответы.',
        answers: [
          'Ответственным руководителем взрыва',
          'Техническим руководителем рудника, шахты, объекта строительства',
          'Главным инженером горнодобывающей организации',
          'Инспектором территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Ответственным руководителем взрыва',
          'Техническим руководителем рудника, шахты, объекта строительства',
        ],
      },
      {
        code: '63681427',
        text:
          'Что понимается под первичной геологической информацией о недрах?',
        answers: [
          'Только информация о недрах, полученная в процессе осуществления вида деятельности, связанного с захоронением радиоактивных отходов и токсичных веществ',
          'Только геофизическая, геохимическая и иная информация о недрах, полученная в процессе пользования недрами',
          'Только информация о недрах, полученная в процессе пользования недрами, связанного с геологическим изучением и добычей отдельных видов минерального сырья',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63681428',
        text:
          'На какой максимальный срок составляются проекты пробной эксплуатации месторождения (залежи) и технологической схемы опытно-промышленной разработки залежей или участков залежей?',
        answers: ['1 год', '6 лет', '8 лет', '5 лет', '6 месяцев'],
        correctAnswers: ['5 лет'],
      },
      {
        code: '63681429',
        text:
          'Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?',
        answers: [
          'На пульт насосного оборудования',
          'На пульт бурильщика',
          'На диспетчерский пункт',
          'На центральный пульт нефтегазодобывающего управления',
        ],
        correctAnswers: ['На диспетчерский пункт'],
      },
      {
        code: '63681430',
        text:
          'Какой способ ликвидации отказавших скважинных зарядов указан неверно?',
        answers: [
          'Взрывание заряда в скважине, пробуренной параллельно на расстоянии не менее 3 м от скважины с отказавшим зарядом',
          'Взрывание отказавшего заряда в случае, если отказ произошел в результате нарушения целостности внешней взрывной сети (если линия наименьшего сопротивления отказавшего заряда не уменьшилась)',
          'Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура',
          'Разборка породы в месте нахождения скважины с отказавшим зарядом с извлечением последнего вручную',
        ],
        correctAnswers: [
          'Вымывание заряда из скважины при взрывании дымного пороха с применением детонирующего шнура',
        ],
      },
      {
        code: '63681431',
        text:
          'Из чего состоит проект буровзрывных (взрывных) работ (проект массового взрыва) для конкретных условий?',
        answers: [
          'Из распорядка проведения массового взрыва',
          'Из технического расчета со схемой расположения скважин и графическими материалами',
          'Из таблицы параметров взрывных работ',
          'Из всего перечисленного',
        ],
        correctAnswers: ['Из всего перечисленного'],
      },
      {
        code: '63681432',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Руководителем организации - пользователя недр',
          'Органом исполнительной власти субъекта Российской Федерации',
          'Федеральным государственным органом в области геодезии и картографии',
          'Органом государственного экологического контроля',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63681433',
        text:
          'Кем осуществляется ликвидация чрезвычайных ситуаций локального характера в соответствии с Положением о единой государственной системе предупреждения и ликвидации чрезвычайных ситуаций?',
        answers: [
          'Федеральными органами исполнительной власти',
          'Органами исполнительной власти субъектов Российской Федерации, оказавшихся в зоне чрезвычайной ситуации',
          'Организацией',
          'Органами местного самоуправления',
          'Территориальными органами МЧС России',
        ],
        correctAnswers: ['Организацией'],
      },
      {
        code: '63681434',
        text:
          'Где необходимо производить замер содержания углеводородных газов, паров жидких углеводородов перед заряжанием шпуров, а также перед взрыванием зарядов?',
        answers: [
          'Только в забое',
          'Только в месте нахождения взрывника',
          'Только в примыкающих к к забою выработках на протяжении 20 м',
          'Во всех перечисленных местах',
        ],
        correctAnswers: ['Во всех перечисленных местах'],
      },
      {
        code: '63681435',
        text:
          'Какой радиус опасной зоны должен быть установлен вокруг скважины и применяемого оборудования на период тепловой и комплексной обработки?',
        answers: [
          'Не менее 30 м',
          'Не менее 50 м',
          'Не менее 15 м',
          'Не менее 10 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63681436',
        text:
          'В каком месте необходимо располагать зарядную для аккумуляторных погрузчиков, а также постоянную стоянку зарядных машин (смесительно-зарядных машин, транспортно-зарядных машин)?',
        answers: [
          'Вблизи от здания производства взрывчатых веществ на территории пунктов производства и подготовки взрывчатых веществ',
          'Не ближе 120 м от здания подготовки и производства взрывчатых веществ',
          'За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ',
          'Определяется схемой развития горных работ',
        ],
        correctAnswers: [
          'За территорией пунктов производства и подготовки взрывчатых веществ на расстоянии не ближе 50 м от здания подготовки и (или) производства взрывчатых веществ',
        ],
      },
      {
        code: '63681437',
        text:
          'Каким федеральным органом исполнительной власти оформляется горноотводная документация?',
        answers: [
          'Федеральным агентством по недропользованию',
          'Федеральной службой по надзору в сфере природопользования',
          'Федеральной службой государственной регистрации, кадастра и картографии',
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: [
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '63681438',
        text:
          'Кем проверяется состояние зарядных устройств не реже одного раза в месяц?',
        answers: [
          'Бригадами, осуществляющими ремонтные работы',
          'Лицами, имеющими книжку взрывника и аттестованными в установленном порядке',
          'Комиссией организации, эксплуатирующей зарядное оборудование',
          'Техническим руководителем организации, эксплуатирующей зарядное оборудование',
        ],
        correctAnswers: [
          'Комиссией организации, эксплуатирующей зарядное оборудование',
        ],
      },
      {
        code: '63681439',
        text:
          'Какая информация дополнительно включается в план при ведении работ, связанных с проводкой боковых стволов?',
        answers: [
          'Режимы проходки бокового ствола и утилизации выбуренной породы',
          'Тип породоразрушающего инструмента и его привода',
          'Навигационное обеспечение траектории бокового ствола или горизонтального ответвления',
          'Компоновки колонны труб и низа бурильной колонны',
          'Технические средства и режимы работ по вырезке "окна"',
          'Все перечисленное дополнительно включается в план работ',
        ],
        correctAnswers: [
          'Все перечисленное дополнительно включается в план работ',
        ],
      },
      {
        code: '63681440',
        text:
          'Какие требования предъявляются к заземлению кондуктора (технической колонны) и рамы станка-качалки?',
        answers: [
          'Сечение прямоугольного проводника должно быть не менее 48 мм², толщина стенок угловой стали - не менее 4 мм, диаметр круглых заземлителей - не менее 10 мм',
          'В качестве заземляющих проводников может применяться сталь: круглая, полосовая, угловая или другого профиля',
          'Заземляющие проводники, соединяющие раму станка-качалки с кондуктором (технической колонной), должны быть заглублены в землю не менее чем на 0,5 м',
          'Кондуктор и рама станка-качалки должны быть связаны не менее чем двумя заземляющими стальными проводниками, приваренными в разных местах к кондуктору и раме',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63681441',
        text:
          'С какой периодичностью проводится определение относительной влажности воздуха в забойном пространстве после внедрения пневматического заряжания?',
        answers: [
          'Не реже одного раза в полугодие',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в квартал',
        ],
        correctAnswers: ['Не реже одного раза в квартал'],
      },
      {
        code: '63681442',
        text:
          'В каких горных выработках угольных шахт минимальная средняя по сечению скорость воздуха составляет 0,25 м/с?',
        answers: [
          'В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт',
          'В тупиковых горных выработках газовых шахт при ведении горных работ по их проведению в зонах повышенного горного давления',
          'В призабойном пространстве очистных выработок негазовых шахт и газовых шахт',
          'В выработках негазовых шахт и в горных выработках шахт всех категорий по газу, проветриваемых за счет общешахтной депрессии',
        ],
        correctAnswers: [
          'В призабойном пространстве тупиковых горных выработок негазовых и газовых шахт',
        ],
      },
      {
        code: '63681443',
        text:
          'Кто утверждает регламент технологического процесса производства и подготовки взрывчатых веществ и изменения в него?',
        answers: [
          'Ответственное лицо территориального органа Ростехнадзора',
          'Руководитель (технический руководитель) эксплуатирующей организации',
          'Работник организации, ответственный за пожарную безопасность',
          'Комиссия эксплуатирующей организации',
        ],
        correctAnswers: [
          'Руководитель (технический руководитель) эксплуатирующей организации',
        ],
      },
      {
        code: '63681444',
        text:
          'На сколько классов устойчивости следует разделять непосредственную кровлю над горными выработками и сопряжениями при определении плотности установки анкеров, конструкции опор, затяжки, технологии крепления кровли при проходке, для всех типов кровли по обрушаемости?',
        answers: ['На пять', 'На четыре', 'На три', 'На два'],
        correctAnswers: ['На три'],
      },
      {
        code: '63681445',
        text:
          'Какие из перечисленных требований на участках категории "Опасно" указаны верно? Укажите все правильные ответы.',
        answers: [
          'При расположении стволов в породах, опасных по горным ударам, должны быть выполнены процедуры, обеспечивающие постоянные максимальные значения концентрации напряжений в стенках ствола',
          'Мероприятия по предотвращению горных ударов проводятся во время возведения в стволе постоянной крепи',
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
        correctAnswers: [
          'При расположении стволов в породах, опасных по горным ударам, должны быть запроектированы и выполняться мероприятия, обеспечивающие снижение до неопасных значений концентраций напряжений в стенках ствола',
          'При проходке стволов на участках категории "Опасно" необходимо применять передовые разгрузочные щели с таким расчетом, чтобы плоскость щели располагалась перпендикулярно направлению действия максимальных горизонтальных напряжений',
        ],
      },
      {
        code: '63681446',
        text:
          'Какие способы соединений труб используются для обвязки скважины и аппаратуры, а также для газопроводов при фонтанной и газлифтной эксплуатации скважин?',
        answers: [
          'Фланцевые и резьбовые соединения',
          'Соединения с крепежными хомутами и сварочные соединения',
          'Муфтовые резьбовые соединения',
          'Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры',
          'Резьбовые соединения с изоляцией',
        ],
        correctAnswers: [
          'Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры',
        ],
      },
      {
        code: '63681447',
        text:
          'За какое время до начала подготовительных и монтажных работ по ремонту и реконструкции скважин бригаде должна выдаваться схема расположения подземных и наземных коммуникаций?',
        answers: [
          'Не менее чем за двое суток',
          'Не менее чем за сутки',
          'Не менее чем за трое суток',
          'В день начала выполнения работ',
        ],
        correctAnswers: ['Не менее чем за трое суток'],
      },
      {
        code: '63681448',
        text:
          'Какое из перечисленных требований к оформлению проектной документации на разработку месторождений углеводородного сырья указано неверно?',
        answers: [
          'Проектная документация должна содержать все данные, позволяющие производить анализ проектных решений без личного участия авторов',
          'Титульный лист проектного документа включается в общую нумерацию страниц отчета',
          'Если проектная документация состоит из 2 и более частей (томов), то каждая часть (том) должна иметь свой титульный лист',
          'Номер страницы на титульном листе проставляется арабскими цифрами',
        ],
        correctAnswers: [
          'Номер страницы на титульном листе проставляется арабскими цифрами',
        ],
      },
      {
        code: '63681449',
        text:
          'С какой периодичностью должен осуществляться визуальный контроль пылевых отложений ИТР технологического участка?',
        answers: [
          'Один раз в сутки',
          'Еженедельно',
          'Ежесменно',
          'Периодичность устанавливается на усмотрение главного маркшейдера шахты',
        ],
        correctAnswers: ['Ежесменно'],
      },
    ],
    63685: [
      {
        code: '63685000',
        text:
          'С какой регулярностью проводятся повторная и последующие проверки подводного трубопровода?',
        answers: [
          'Повторная проверка подводного трубопровода проводится в срок не позднее шести месяцев с начала эксплуатации, последующие проверки проводятся при необходимости',
          'Повторная проверка подводного трубопровода проводится только в случае экстренной необходимости, последующие проверки проводятся каждые пять лет',
          'Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет',
        ],
        correctAnswers: [
          'Повторная проверка подводного трубопровода проводится в срок не позднее одного года с начала эксплуатации, последующие проверки проводятся с определенной при проектировании периодичностью, но не реже чем раз в восемь лет',
        ],
      },
      {
        code: '63685001',
        text:
          'Кто из перечисленных лиц подписывает титульный лист плана (схемы) развития горных работ?',
        answers: [
          'Лица, ответственные за руководство горными работами пользователя недр',
          'Лица, ответственные за руководство маркшейдерскими работами',
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
          'Лица, ответственные за руководство геологическими работами, и представитель Ростехнадзора',
        ],
        correctAnswers: [
          'Лица, ответственные за руководство горными, геологическими и маркшейдерскими работами пользователя недр и (или) привлекаемые пользователем недр на договорной основе организации для осуществления работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63685002',
        text:
          'Кем должны быть подписаны планы (схемы) развития горных работ, направляемые пользователем недр в электронном виде?',
        answers: [
          'Комиссией пользователя недр (подрядной организации)',
          'Техническим руководителем пользователя недр (подрядной организации)',
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
          'Руководителем проектной организации',
        ],
        correctAnswers: [
          'Руководителем пользователя недр или его уполномоченным в соответствии с законодательством Российской Федерации лицом',
        ],
      },
      {
        code: '63685003',
        text:
          'В какой системе координат должны быть определены уточненные границы горного отвода?',
        answers: [
          'В полярной системе координат (азимут, горизонтальное проложение)',
          'В прямоугольной системе координат',
          'В географической системе координат (ширина, долгота)',
        ],
        correctAnswers: ['В прямоугольной системе координат'],
      },
      {
        code: '63685004',
        text:
          'Как должны размещаться трубопроводы, предназначенные для транспортирования газов и водяного пара?',
        answers: [
          'В верхних частях горных выработок',
          'В нижних частях горных выработок',
          'В горных выработках, проведенных по породам, склонных к пучению',
          'В изолированных каналах',
        ],
        correctAnswers: ['В верхних частях горных выработок'],
      },
      {
        code: '63685005',
        text:
          'Какая документация должна быть размещена на объектах сбора и подготовки нефти и газа (ЦПС, УПН, УКПГ, ГП), насосных и компрессорных станциях (ДНС, КС)?',
        answers: [
          'Технические паспорта и документация на оборудование насосных и компрессорных станций',
          'Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации',
          'Приказ о назначении лица, ответственного за проведение работ, и технические паспорта на оборудование',
          'План аварийно-спасательных мероприятий, утвержденный руководством организации',
          'Вся перечисленная документация',
        ],
        correctAnswers: [
          'Технологическая схема, утвержденная техническим руководителем организации, с указанием номеров задвижек, аппаратов, направлений потоков, полностью соответствующих их нумерации в проектной документации',
        ],
      },
      {
        code: '63685006',
        text:
          'В какой срок органом исполнительной власти субъекта Российской Федерации оформленная документация, удостоверяющая уточненные границы горного отвода, передается пользователю недр?',
        answers: [
          'Не позднее 7 дней со дня оформления',
          'Не позднее 5 дней со дня оформления',
          'Не позднее 20 дней со дня оформления',
          'Не позднее 25 дней со дня оформления',
        ],
        correctAnswers: ['Не позднее 5 дней со дня оформления'],
      },
      {
        code: '63685007',
        text:
          'С какой периодичностью необходимо проводить осмотр крепи и армировки вертикальных горных выработок?',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в день',
        ],
        correctAnswers: ['Не реже одного раза в неделю'],
      },
      {
        code: '63685008',
        text:
          'Какой должна быть скорость воздуха в горных выработках нефтяных шахт длиной менее 30 м?',
        answers: [
          'Не менее 0,1 м/с',
          'Не менее 0,25 м/с',
          'Не менее 0,3 м/с',
          'Не менее 0,4 м/с',
        ],
        correctAnswers: ['Не менее 0,1 м/с'],
      },
      {
        code: '63685009',
        text:
          'На каком расстоянии от устья скважины располагаются передвижные насосные установки и каким должно быть расстояние между ними?',
        answers: [
          'Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м',
          'Не менее 25 м от устья скважины, расстояние между ними должно быть не менее 1,5 м',
          'Не менее 35 м от устья скважины, расстояние между ними должно быть не менее 2,5 м',
          'Не менее 15 м от устья скважины, расстояние между ними должно быть не менее 3 м',
        ],
        correctAnswers: [
          'Не менее 10 м от устья скважины, расстояние между ними должно быть не менее 1 м',
        ],
      },
      {
        code: '63685010',
        text:
          'Разрешается ли исследование разведочных и эксплуатационных скважин в случае отсутствия утилизации жидкого продукта?',
        answers: [
          'Разрешается по решению территориального органа Ростехнадзора',
          'Запрещается во всех случаях',
          'Разрешается без ограничений',
          'Разрешается по согласованию с противофонтанной службой',
        ],
        correctAnswers: ['Запрещается во всех случаях'],
      },
      {
        code: '63685011',
        text:
          'На какой срок составляется план развития горных работ по всем планируемым видам горных работ?',
        answers: ['На 3 месяца', 'На 1 год', 'На 5 лет', 'На 2 года'],
        correctAnswers: ['На 1 год'],
      },
      {
        code: '63685012',
        text:
          'Какие требования предъявляются к условиям установки подвесного и герметизирующего устройства потайной колонны?',
        answers: [
          'Требования к условиям установки подвесного и герметизирующего устройства потайной колонны (хвостовика) определяется расчетным путем при проектировании конструкции скважины',
          'Требования не нормируются',
          'Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин',
        ],
        correctAnswers: [
          'Подвесное и герметизирующее устройство потайной колонны должно устанавливаться выше предыдущей обсадной колонны не менее чем на 75 м для нефтяных скважин и 250 м для газовых скважин',
        ],
      },
      {
        code: '63685013',
        text:
          'При каких условиях недропользователю устанавливаются уточненные границы горного отвода?',
        answers: [
          'Одновременно с получением лицензии на пользование недрами с целью разработки газовых и нефтяных месторождений',
          'В процессе разработки месторождений нефти или газа по требованию органа, выдавшего лицензию на пользование недрами',
          'В ходе ликвидации, консервации скважин и оборудования их устьев и стволов',
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы',
        ],
        correctAnswers: [
          'После предоставления лицензии на пользование недрами, разработки документов, подтверждающих согласование проектной документации, и получения положительного заключения государственной экспертизы',
        ],
      },
      {
        code: '63685014',
        text:
          'Какие данные не должны указываться в штампе графических материалов планов развития горных работ?',
        answers: [
          'Все ответы неверны',
          'Дата составления',
          'Подписи лиц (с указанием должности), ответственных за руководство геологическими и маркшейдерскими работами',
          'Наименования организации, объекта недропользования (участка недр)',
          'Название графического документа',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63685015',
        text:
          'Каким образом осуществляется пересылка документации, содержащей сведения, представляющие государственную тайну?',
        answers: [
          'Заказным почтовым отправлением через "Почту России"',
          'В соответствии с законодательством Российской Федерации о государственной тайне',
          'Курьером любой курьерской фирмы',
          'Курьером фирмы, подготовившей документацию',
        ],
        correctAnswers: [
          'В соответствии с законодательством Российской Федерации о государственной тайне',
        ],
      },
      {
        code: '63685016',
        text:
          'Каким образом должно осуществляться управление запорной арматурой скважины, оборудованной под нагнетание пара или горячей воды?',
        answers: [
          'Дистанционно, но с возможностью ручного управления с применением средств защиты',
          'Вручную с использованием средств защиты',
          'Только дистанционно',
          'Не регламентируется',
        ],
        correctAnswers: ['Только дистанционно'],
      },
      {
        code: '63685017',
        text:
          'В какой срок планы и (или) схемы развития горных работ направляются пользователем недр в орган государственного горного надзора для рассмотрения?',
        answers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В течение 15 дней с назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'За 5 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
          'В назначенный день рассмотрения планов и (или) схем развития горных работ',
        ],
        correctAnswers: [
          'За 15 дней до назначенной даты рассмотрения планов и (или) схем развития горных работ',
        ],
      },
      {
        code: '63685018',
        text:
          'Допускаются ли вибрация и гидравлические удары в нагнетательных коммуникациях?',
        answers: [
          'Допускаются при наличии в системе компенсаторов',
          'Допускаются при нахождении персонала в безопасной зоне',
          'Не допускаются',
          'Допускаются в пределах, установленных в планах работ',
        ],
        correctAnswers: [
          'Допускаются в пределах, установленных в планах работ',
        ],
      },
      {
        code: '63685019',
        text:
          'Какое из перечисленных требований к объектам сбора, транспорта, подготовки нефти, попутного нефтяного газа, воды в горных выработках указано верно?',
        answers: [
          'Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород',
          'Нефтеловушки и нефтеперекачивающие станции размещаются в тупиковых горных выработках',
          'При бурении скважин станками с гидроприводом или с промывкой в полевых штреках сброс воды в водоотливные канавки категорически запрещен',
          'Допускается оставлять застой нефти на фундаментах насосов и под фундаментной рамой оборудования и механизмов, если это регламентировано локальной инструкцией организации',
        ],
        correctAnswers: [
          'Водоотливные канавки в горных выработках нефтяных шахт II группы опасности по углеводородным газам используются для сбора и отвода жидкости, выделяющейся из горных пород',
        ],
      },
      {
        code: '63685020',
        text:
          'В какой срок пользователи недр представляют заявление о согласовании планов и схем развития горных работ в орган государственного горного надзора?',
        answers: [
          'До 1 апреля текущего года',
          'До 15 августа текущего года',
          'До 1 сентября текущего года',
          'До 15 декабря текущего года',
        ],
        correctAnswers: ['До 1 сентября текущего года'],
      },
      {
        code: '63685021',
        text:
          'В каких случаях документация, удостоверяющая уточненные границы горного отвода, подлежит переоформлению?',
        answers: [
          'Только при переоформлении лицензии на пользование недрами',
          'Только при изменении геологической информации о недрах, наличии технологических потребностей, условий и факторов, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
          'Только при изменении технического проекта выполнения работ, связанных с пользованием недрами, влияющих на безопасное состояние недр, земной поверхности и расположенных на ней объектов',
          'Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами',
        ],
        correctAnswers: [
          'Во всех перечисленных случаях, а также при выявлении технических ошибок в лицензии на пользование недрами',
        ],
      },
      {
        code: '63685022',
        text:
          'При каких условиях допускается повторное использование рабочего проекта при бурении группы скважин на идентичных по геолого-техническим условиям площадях?',
        answers: [
          'При выполнении всех перечисленных условий',
          'При одинаковых проектных глубинах по стволу скважин',
          'При отличии плотности бурового раствора от проектной в пределах ± 0,3 г/см³',
          'При одинаковом назначении и конструкции скважин',
          'При идентичности горно-геологических условий проводки и условий природопользования',
        ],
        correctAnswers: ['При выполнении всех перечисленных условий'],
      },
      {
        code: '63685023',
        text:
          'Каким устанавливается радиус опасной зоны вокруг нагнетательной скважины на период инициирования внутрипластового горения?',
        answers: [
          'Не менее 20 м',
          'Не менее 25 м',
          'Не менее 15 м',
          'Не менее 12 м',
        ],
        correctAnswers: ['Не менее 25 м'],
      },
      {
        code: '63685024',
        text:
          'Кем подписывается проект горного отвода (пояснительная записка)?',
        answers: [
          'Главным геологом и главным маркшейдером организации, испрашивающей горный отвод, ответственным специалистом - разработчиком проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода',
          'Техническим руководителем организации, испрашивающей горный отвод, главным инженером проекта разработки месторождения полезного ископаемого, руководителем организации, разработавшей проект горного отвода',
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
          'Руководителем организации, испрашивающей горный отвод, и руководителем организации, разработавшей проект горного отвода',
        ],
        correctAnswers: [
          'Руководителем организации пользователя недр или иным лицом, имеющим право без доверенности действовать от имени пользователя недр, либо лицом, уполномоченным доверенностью пользователя недр на подписание проекта горного отвода',
        ],
      },
      {
        code: '63685025',
        text:
          'Какие ограничения предусмотрены для перфорации обсадных колонн при проведении ремонтно-изоляционных работ в процессе проводки ствола скважины?',
        answers: [
          'Ограничения не предусмотрены',
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале проницаемых горизонтов',
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора',
        ],
        correctAnswers: [
          'Запрещается перфорация обсадных колонн при проведении ремонтно-изоляционных работ в интервале возможного разрыва пласта давлением газа, нефти (после вызова их притока) или столба бурового раствора',
        ],
      },
      {
        code: '63685026',
        text:
          'Допускается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений пересечение трубопроводов с токсичными жидкостями и газами с железнодорожными подъездными путями?',
        answers: [
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
          'Допускается',
          'Не допускается',
          'Допускается при условии прокладки их по глухой стене не ниже II степени огнестойкости',
        ],
        correctAnswers: [
          'Не допускается, за исключением продуктопроводов к двусторонним сливоналивным железнодорожным эстакадам',
        ],
      },
      {
        code: '63685027',
        text:
          'Какое установлено максимальное расстояние между устьями скважин в кусте при промышленном освоении нефтяных месторождений на площадях залегания калийных солей?',
        answers: ['10 м', '12 м', '15 м', '20 м'],
        correctAnswers: ['15 м'],
      },
      {
        code: '63685028',
        text:
          'Каким образом оценивается состояние ствола скважины при разработке залежей нефти на площадях залегания калийных солей?',
        answers: [
          'По результатам контрольных геофизических исследований скважин',
          'По результатам инструментальных наблюдений',
          'По результатам комплексного анализа',
          'По данным нивелировки устья скважины',
        ],
        correctAnswers: ['По данным нивелировки устья скважины'],
      },
      {
        code: '63685029',
        text:
          'Куда должны иметь выход системы замера дебита, контроля пуска, остановки скважин?',
        answers: [
          'На диспетчерский пункт',
          'На пульт насосной станции',
          'На центральный пульт нефтегазодобывающего управления',
          'На пульт групповой замерной установки',
        ],
        correctAnswers: ['На диспетчерский пункт'],
      },
      {
        code: '63685030',
        text:
          'Какие из перечисленных трубопроводов прокладываются в вентиляционной выработке эксплуатационных уклонов или панелей? Укажите все правильные ответы.',
        answers: [
          'Напорный трубопровод к гидроэлеваторам и буровому станку',
          'Трубопровод сжатого воздуха',
          'Сбросной трубопровод гидроэлеваторов',
          'Трубопровод для теплоносителя',
          'Трубопровод инертного газа',
        ],
        correctAnswers: [
          'Сбросной трубопровод гидроэлеваторов',
          'Трубопровод для теплоносителя',
        ],
      },
      {
        code: '63685031',
        text:
          'Кем должны быть приняты законченные строительно-монтажные работы на ОПО МНГК или их составляющих?',
        answers: [
          'Комиссией Ростехнадзора',
          'Комиссией Росприроднадзора',
          'Комиссией, назначенной приказом эксплуатирующей организации',
          'Комиссией проектной организации',
        ],
        correctAnswers: [
          'Комиссией, назначенной приказом эксплуатирующей организации',
        ],
      },
      {
        code: '63685032',
        text:
          'Чем должно быть оборудовано устье скважины при эксплуатации ее штанговыми насосами?',
        answers: [
          'Запорной арматурой и обратным клапаном',
          'Устройством для сигнализации об утечках продукта',
          'Перфорационной задвижкой',
          'Шаровым клапаном и двойным торцевым уплотнением для герметизации штока',
          'Запорной арматурой и сальниковым устройством для герметизации штока',
        ],
        correctAnswers: [
          'Запорной арматурой и сальниковым устройством для герметизации штока',
        ],
      },
      {
        code: '63685033',
        text:
          'На какой срок оформляется документация, удостоверяющая уточненные границы горного отвода?',
        answers: [
          'На срок действия лицензии на пользование недрами',
          'На 1 год',
          'Не более чем на 3 года',
          'Срок не ограничен',
        ],
        correctAnswers: ['На срок действия лицензии на пользование недрами'],
      },
      {
        code: '63685034',
        text:
          'Какую площадь должен иметь размер земельного участка для производства буровых работ?',
        answers: [
          'Не более 100 м²',
          'Не более 150 м²',
          'Не более 200 м²',
          'Обеспечивающую соблюдение требований промышленной безопасности',
        ],
        correctAnswers: [
          'Обеспечивающую соблюдение требований промышленной безопасности',
        ],
      },
      {
        code: '63685035',
        text:
          'Где в целях промышленной безопасности не допускается бурение скважин на нефть на площадях залегания калийных солей?',
        answers: [
          'Только на площадях залегания балансовых запасов калийных солей категорий А, В',
          'На площадях залегания балансовых запасов калийных солей категорий А, В, С1, а также в пределах охранной зоны вокруг указанных площадей',
          'Только в пределах охранной зоны вокруг площадей залегания балансовых запасов калийных солей категорий А, В, С1',
        ],
        correctAnswers: [
          'На площадях залегания балансовых запасов калийных солей категорий А, В, С1, а также в пределах охранной зоны вокруг указанных площадей',
        ],
      },
      {
        code: '63685036',
        text:
          'При применении каких материалов устанавливается герметичное оборудование с устройством для пневмотранспорта при проведении буровых работ?',
        answers: [
          'При применении глинопорошка',
          'При применении цемента',
          'При применении всех перечисленных материалов',
          'При применении химреагентов',
        ],
        correctAnswers: ['При применении всех перечисленных материалов'],
      },
      {
        code: '63685037',
        text:
          'Какие данные не регистрируются и не контролируются на ПБУ с динамической системой позиционирования и (или) с якорной системой удержания?',
        answers: [
          'Осадка судна',
          'Высота волны',
          'Все ответы неверны',
          'Угол наклона бурового райзера',
          'Скорость течения',
        ],
        correctAnswers: ['Все ответы неверны'],
      },
      {
        code: '63685038',
        text:
          'С каким уклоном допускается устанавливать опорную плиту (при ее наличии) на ровную поверхность дна моря при формировании подводного устья скважины?',
        answers: [
          'Не более 1°',
          'Не более 5°',
          'Не более 3°',
          'Уклон не допускается',
        ],
        correctAnswers: ['Не более 1°'],
      },
      {
        code: '63685039',
        text:
          'Какое требование, предъявляемое к применению противовыбросового оборудования, указано неверно?',
        answers: [
          'Противовыбросовый манифольд вместе с линией от сепаратора бурового раствора на желоб оборудуется устройством для продувки',
          'Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину',
          'На ПБУ с подводным расположением устья проводится опрессовка каждого превентора в сборке подводного противовыбросового оборудования (ППВО) на стенде на рабочее давление',
          'При ГНВП разгазированная жидкость через штуцерную линию поступает в систему сепарации и дегазации',
        ],
        correctAnswers: [
          'Универсальным превентором обеспечивается срезание наиболее прочной трубы, предполагаемой к спуску в скважину',
        ],
      },
      {
        code: '63685040',
        text:
          'За какое время до начала подготовительных и монтажных работ по ремонту и реконструкции скважин бригаде должна выдаваться схема расположения подземных и наземных коммуникаций?',
        answers: [
          'Не менее чем за двое суток',
          'Не менее чем за трое суток',
          'В день начала производства работ',
          'Не менее чем за одни сутки',
        ],
        correctAnswers: ['Не менее чем за трое суток'],
      },
      {
        code: '63685041',
        text:
          'Какие средства могут применяться для контроля состава рудничной атмосферы при абсолютной газообильности горных выработок менее 3 м³/мин?',
        answers: [
          'Универсальные средства контроля',
          'Переносные и (или) стационарные средства контроля',
          'Выбор средств контроля суммарного содержания углеводородных газов и паров жидких углеводородов в горных выработках осуществляет технический руководитель обособленного структурного подразделения',
          'Только стационарные средства контроля',
          'Только переносные средства контроля',
        ],
        correctAnswers: ['Переносные и (или) стационарные средства контроля'],
      },
      {
        code: '63685042',
        text:
          'В каких случаях не устанавливается превенторная сборка при бурении скважин для разведки и добычи метана угольных месторождений (площадей, участков)?',
        answers: [
          'В случаях, предусмотренных в рабочем проекте',
          'В случае вскрытия изученного разреза с аномально низким пластовым давлением (АНПД), представленного обводненными угольными пластами',
          'В случае принятия решения об этом буровой организацией',
          'В случае наличия заключения представителя Ростехнадзора',
        ],
        correctAnswers: [
          'В случае вскрытия изученного разреза с аномально низким пластовым давлением (АНПД), представленного обводненными угольными пластами',
        ],
      },
      {
        code: '63685043',
        text:
          'Какая должна быть кратность воздухообмена в помещениях с взрывоопасными зонами?',
        answers: ['Не менее 6 в час', 'Не менее 10 в час', 'Не менее 8 в час'],
        correctAnswers: ['Не менее 6 в час'],
      },
      {
        code: '63685044',
        text:
          'Какие из приведенных помещений (пространств) относятся к зоне 0 (участок, на котором взрывоопасная смесь присутствует постоянно или в течение длительных периодов времени)?',
        answers: [
          'Открытые пространства вокруг закрытых и открытых технических устройств, оборудования',
          'Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов',
          'Закрытые помещения насосных для сточных вод',
          'Полузакрытые пространства, в которых установлены технические устройства, оборудование, аппараты',
        ],
        correctAnswers: [
          'Закрытые помещения, в которых установлены открытые технические устройства, аппараты, емкости или имеются выходы для паров нефти и легковоспламеняющихся газов',
        ],
      },
      {
        code: '63685045',
        text:
          'В каких случаях необходимо проверять изоляцию электрооборудования и исправность устройства защитного заземления буровой установки или скважины?',
        answers: [
          'Перед проведением промыслово-геофизических работ',
          'Перед началом проведения буровых работ',
          'Перед проведением любых работ на ОПО',
          'Перед проведением газоопасных работ',
        ],
        correctAnswers: ['Перед проведением промыслово-геофизических работ'],
      },
      {
        code: '63685046',
        text:
          'Какие фонтанные скважины должны оснащаться внутрискважинным оборудованием (пакер и клапан-отсекатель, циркуляционный клапан, станция управления и др.)?',
        answers: [
          'Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта',
          'Фонтанные скважины с дебитом 350 т/сут нефти или 400 тыс. м³/сут газа и более',
          'Фонтанные скважины с дебитом 300 т/сут нефти или 400 тыс. м³/сут газа и более, расположенные на расстоянии менее 1 км от населенного пункта',
          'Фонтанные скважины, расположенные на расстоянии менее 2,5 км от населенного пункта',
        ],
        correctAnswers: [
          'Фонтанные скважины с дебитом 400 т/сут нефти или 500 тыс. м³/сут газа и более, расположенные на расстоянии менее 500 м от населенного пункта',
        ],
      },
      {
        code: '63685047',
        text:
          'В соответствии с чем производятся приготовление, контроль качества и использование буровых растворов и тампонажных материалов при освоении месторождений нефти на площадях залегания калийных солей?',
        answers: [
          'В соответствии с заключением экспертизы промышленной безопасности',
          'В соответствии с эксплуатирующей документацией',
          'В соответствии с распоряжениями руководителя эксплуатирующей организации',
          'В соответствии с технологическими регламентами, согласованными с Ростехнадзором',
          'В соответствии с проектом',
        ],
        correctAnswers: [
          'В соответствии с технологическими регламентами, согласованными с Ростехнадзором',
        ],
      },
      {
        code: '63685048',
        text: 'Кем утверждается документация по ведению горных работ?',
        answers: [
          'Специалистом, ответственным за осуществление производственного контроля',
          'Главным инженером проекта',
          'Руководителем обособленного структурного подразделения',
          'Главным механиком',
        ],
        correctAnswers: [
          'Руководителем обособленного структурного подразделения',
        ],
      },
      {
        code: '63685049',
        text:
          'К какому типу обсадных колонн относится часть конструкции скважин, бурящихся на подсолевые нефтегазоносные комплексы, спускаемая для перекрытия неустойчивых четвертичных отложений и цементируемая до устья?',
        answers: [
          'Второе направление',
          'Кондуктор',
          'Первое направление',
          'Техническая колонна',
          'Коллектор',
        ],
        correctAnswers: ['Второе направление'],
      },
      {
        code: '63685050',
        text:
          'Что из нижеперечисленного не содержится в рабочем проекте на производство буровых работ?',
        answers: [
          'Обоснование плотности бурового раствора и диапазон колебаний других параметров промывочной жидкости',
          'Объем исследования стратиграфического разреза в процессе бурения для уточнения пластовых давлений и состава флюида',
          'Географическая и климатическая характеристики района работ',
          'Ф. И. О. ответственного лица за производство буровых работ',
        ],
        correctAnswers: [
          'Ф. И. О. ответственного лица за производство буровых работ',
        ],
      },
      {
        code: '63685051',
        text:
          'Каким проверкам подвергается подводный трубопровод после капитального ремонта?',
        answers: [
          'Проверкам методами неразрушающего контроля',
          'Проверкам роботизированными подводными аппаратами',
          'Проверкам на прочность и герметичность',
        ],
        correctAnswers: ['Проверкам на прочность и герметичность'],
      },
      {
        code: '63685052',
        text:
          'Какими способами допускается освоение скважин в нефтяных шахтах? Укажите все правильные ответы.',
        answers: [
          'Свабированием (поршневанием)',
          'Способом аэрации жидкости в скважине',
          'Способом нагнетания сжатого воздуха в скважину',
          'Тартанием желонкой',
        ],
        correctAnswers: [
          'Способом аэрации жидкости в скважине',
          'Способом нагнетания сжатого воздуха в скважину',
        ],
      },
      {
        code: '63685053',
        text:
          'В течение какого времени передаются организациям по добыче калийных солей в случае бурения скважин на их горных отводах карта-схема инструментальной привязки устьев скважин и каталог в государственной системе координат?',
        answers: [
          'Не позднее 3 месяцев с момента окончания монтажа буровой установки',
          'Не позднее 1 месяца с момента окончания монтажа буровой установки',
          'Не позднее 6 месяцев с момента окончания монтажа буровой установки',
          'Не регламентируется',
        ],
        correctAnswers: [
          'Не позднее 1 месяца с момента окончания монтажа буровой установки',
        ],
      },
      {
        code: '63685054',
        text:
          'С какой периодичностью контролируется состояние крепи горной выработки в месте установки бурового станка работником структурного подразделения, за которым закреплена эта выработка?',
        answers: [
          'В начале и в конце рабочей смены',
          'Состояние крепи горной выработки контролируется только старшим в звене машинистом буровых установок в середине рабочей смены',
          'Постоянно в течение рабочей смены',
          'Один раз в смену',
        ],
        correctAnswers: ['Один раз в смену'],
      },
      {
        code: '63685055',
        text:
          'Чем должна определяться технология производства буровых работ в зонах распространения многолетнемерзлых пород?',
        answers: [
          'Мерзлотными и климатическими условиями данного региона',
          'Продолжительностью холодного периода года',
          'Транспортной доступностью места проведения работ',
          'Наличием квалифицированного рабочего персонала в данном регионе',
        ],
        correctAnswers: [
          'Мерзлотными и климатическими условиями данного региона',
        ],
      },
      {
        code: '63685056',
        text:
          'Какое минимальное расстояние должно быть между парораспределительным пунктом и устьем нагнетательной скважины?',
        answers: ['25 м', '10 м', '20 м', '15 м'],
        correctAnswers: ['25 м'],
      },
      {
        code: '63685057',
        text:
          'С кем необходимо согласовывать планы и схемы развития горных работ?',
        answers: [
          'С территориальными органами Ростехнадзора',
          'Со специализированными организациями МЧС',
          'С органами исполнительной власти субъекта Российской Федерации, на территории которого эксплуатируется разрез',
          'Со сторонними организациями, расположенными на территории горного разреза',
        ],
        correctAnswers: ['С территориальными органами Ростехнадзора'],
      },
      {
        code: '63685058',
        text: 'Какие карты обязана иметь эксплуатирующая организация ОПО МНГК?',
        answers: [
          'Только карты подводных коммуникаций в районе ведения работ',
          'Все карты подводных и надводных коммуникаций в районе ведения работ',
          'Только карты надводных коммуникаций в районе ведения работ',
          'Климатическиe карты района',
          'Топографические карты района',
        ],
        correctAnswers: [
          'Все карты подводных и надводных коммуникаций в районе ведения работ',
        ],
      },
      {
        code: '63685059',
        text:
          'Какие из приведенных помещений (пространств) относятся к зоне 2 (участок, на котором присутствие взрывоопасной смеси в нормальном рабочем режиме исключается на открытых площадках и в помещениях)?',
        answers: [
          'Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны',
          'Закрытые помещения насосных для сточных вод',
          'Пространство внутри открытых и закрытых технических устройств и емкостей, содержащих нефть, буровой раствор, обработанный нефтью, нефтяные газы или другие легковоспламеняющиеся вещества',
          'Закрытые помещения для хранения шлангов для перекачки легковоспламеняющихся жидкостей',
        ],
        correctAnswers: [
          'Пространство под ротором, ограниченное цилиндром радиусом 3 м от оси скважины, на всю высоту до низа при открытом подроторном пространстве вокруг фонтанной арматуры, ограниченное расстоянием 3 м во все стороны',
        ],
      },
      {
        code: '63685060',
        text:
          'Куда представляется один экземпляр акта обследования дна на отсутствие навигационных опасностей, составленный эксплуатирующей организацией, при ликвидации и консервации морских нефтегазовых скважин?',
        answers: [
          'В специализированную организацию',
          'В проектную организацию',
          'В гидрографическую службу',
          'В Ростехнадзор',
        ],
        correctAnswers: ['В гидрографическую службу'],
      },
      {
        code: '63685061',
        text:
          'Кем составляется акт готовности скважины к промыслово-геофизическим работам? Укажите все правильные ответы.',
        answers: [
          'Ростехнадзором',
          'Организацией, проводящей экспертизу промышленной безопасности',
          'Проектной организацией',
          'Организацией-исполнителем буровых работ',
          'Эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Организацией-исполнителем буровых работ',
          'Эксплуатирующей организацией',
        ],
      },
      {
        code: '63685062',
        text:
          'Какие требования предъявляются для защиты от статического электричества одиночно установленных технических устройств (оборудование, емкость, аппарат, агрегат)?',
        answers: [
          'Одиночно установленное техническое устройство заземляется только самостоятельно',
          'Требования не предъявляются',
          'Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)',
          'Одиночно установленное техническое устройство не заземляется',
        ],
        correctAnswers: [
          'Одиночно установленное техническое устройство заземляется самостоятельно или присоединяется к общей заземляющей магистрали ОПО МНГК, расположенной вблизи оборудования, при помощи отдельного заземляющего провода (шины)',
        ],
      },
      {
        code: '63685063',
        text: 'На кого возлагается общее руководство буровыми работами на ПБУ?',
        answers: [
          'На главного инженера ПБУ',
          'На капитана ПБУ',
          'На начальника ОПО МНГК',
          'На заместителя начальника по технологии бурения',
        ],
        correctAnswers: ['На начальника ОПО МНГК'],
      },
      {
        code: '63685064',
        text:
          'Какое оборудование должно быть установлено на скважинах, где ожидаемое давление на устье превышает 700 кгс/см² (70 МПа)?',
        answers: [
          'Заводской блок с двумя регулируемыми дросселями - один с дистанционным и один с ручным управлением',
          'Заводской блок с тремя регулируемыми дросселями - один с дистанционным и два с ручным управлением',
          'Заводской блок с пятью регулируемыми дросселями - два с дистанционным и три с ручным управлением',
          'Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением',
        ],
        correctAnswers: [
          'Заводской блок с тремя регулируемыми дросселями - два с дистанционным и один с ручным управлением',
        ],
      },
      {
        code: '63685065',
        text:
          'Какие способы соединений труб используются для обвязки скважины и аппаратуры, а также для газопроводов при фонтанной и газлифтной эксплуатации скважин?',
        answers: [
          'Резьбовые соединения типа Батресс',
          'Резьбовые соединения с последующей изоляцией',
          'Соединения на хомутах и сварные соединения',
          'Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры',
        ],
        correctAnswers: [
          'Сварные соединения, а также фланцевые - только в местах установки задвижек и другой арматуры',
        ],
      },
      {
        code: '63685066',
        text:
          'В каком из перечисленных случаев должно проводиться забуривание новых (боковых) стволов в обсаженных скважинах?',
        answers: [
          'При восстановлении бездействующего фонда скважин',
          'В любом из перечисленных случаев',
          'При ликвидации сложных аварий',
          'При вскрытии дополнительных продуктивных мощностей',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '63685067',
        text: 'Откуда должен осуществляться пуск буровых насосов в работу?',
        answers: [
          'С местного поста управления',
          'С пульта оператора, осуществляющего контроль технологического процесса',
          'С пульта бурильщика',
          'Одновременно с пульта бурильщика и местного поста управления',
        ],
        correctAnswers: ['С местного поста управления'],
      },
      {
        code: '63685068',
        text:
          'Какие показатели должны постоянно контролироваться в процессе проходки ствола скважины?',
        answers: [
          'Взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
          'Азимут и зенитный угол ствола скважины',
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
          'Пространственное расположение ствола скважины и дифференциальное давление в системе скважина пласт',
        ],
        correctAnswers: [
          'Расход бурового раствора на входе и выходе из скважины и давление в манифольде буровых насосов',
        ],
      },
      {
        code: '63685069',
        text:
          'Какое значение должен иметь зенитный угол ствола скважины от устья скважины до глубины 100 м при освоении месторождений нефти на площадях залегания калийных солей?',
        answers: [
          'Без ограничения, по расчету профиля ствола скважины',
          'Не более 15°',
          'Не более 7°',
          'Не более 3°',
        ],
        correctAnswers: ['Не более 3°'],
      },
      {
        code: '63685070',
        text:
          'Кем осуществляется контроль за изменением геомеханического и геодинамического состояния недр при освоении месторождений нефти на площадях залегания калийных солей?',
        answers: [
          'Федеральным агентством по недропользованию',
          'Федеральной службой по надзору в сфере природопользования',
          'Организациями, имеющими соответствующую лицензию Ростехнадзора на право производства маркшейдерских работ',
          'Эксплуатирующей организацией',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Организациями, имеющими соответствующую лицензию Ростехнадзора на право производства маркшейдерских работ',
        ],
      },
      {
        code: '63685071',
        text:
          'В какой срок со дня поступления заявления осуществляется оформление документации, удостоверяющей уточненные границы горного отвода?',
        answers: [
          'Не более 12 дней',
          'Не более 5 дней',
          'Не более 25 дней',
          'Не более 20 дней',
        ],
        correctAnswers: ['Не более 25 дней'],
      },
      {
        code: '63685072',
        text:
          'Какие требования предъявляются к заземлению кондуктора (технической колонны) и рамы станка-качалки?',
        answers: [
          'В качестве заземляющих проводников может применяться сталь любых профилей. Сечение прямоугольного проводника должно быть не менее 48 мм², толщина стенок угловой стали - не менее 4 мм, диаметр круглых заземлителей - не менее 10 мм',
          'Заземляющие проводники, соединяющие раму станка-качалки с кондуктором, должны заглубляться в землю не менее чем на 0,5 м',
          'Соединения заземляющих проводников должны быть доступны для осмотра',
          'Кондуктор и рама станка-качалки должны быть связаны не менее чем двумя заземляющими стальными проводниками, приваренными в разных местах к кондуктору и раме',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '63685073',
        text:
          'Каким должно быть расстояние между устьями скважин при размещении кустовых площадок на вечномерзлых грунтах?',
        answers: [
          'Меньше 2 радиусов растепления пород вокруг устья скважин',
          'Не менее 1,2 диаметра растепления пород вокруг устья скважин',
          'Не менее 1 радиуса растепления пород вокруг устья скважин',
        ],
        correctAnswers: [
          'Не менее 1,2 диаметра растепления пород вокруг устья скважин',
        ],
      },
      {
        code: '63685074',
        text:
          'На какие перечисленные виды работ не составляются планы и схемы горных работ?',
        answers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
          'На вскрышные работы',
          'На маркшейдерские работы',
          'На работы по добыче полезных ископаемых',
        ],
        correctAnswers: [
          'На работы, связанные со вторичной переработкой минерального сырья',
        ],
      },
      {
        code: '63685075',
        text:
          'Должны ли буровые насосы оборудоваться компенсаторами давления? Если да, то какие требования при этом должны соблюдаться?',
        answers: [
          'На всех буровых насосах должны быть установлены гидрокомпенсаторы давления, заполняемые водой, с приспособлениями для контроля давления в компенсаторах',
          'Установка на буровых насосах компенсаторов давления необязательна в том случае, если проводятся мероприятия по обеспечению равномерности подачи промывочной жидкости',
          'Компенсаторы давления должны устанавливаться только на трех поршневых буровых насосах, при этом компенсаторы должны быть заполнены воздухом или инертным газом и иметь приспособления для контроля давления в компенсаторах',
          'На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля',
        ],
        correctAnswers: [
          'На всех буровых насосах должны быть установлены компенсаторы давления, заполняемые воздухом или инертным газом, с конструкцией, предусматривающей установку манометра для измерения давления в газовой полости и обеспечивающей возможность сбрасывания давления до нуля',
        ],
      },
      {
        code: '63685076',
        text:
          'Кем осуществляются подготовка и утверждение планов и схем развития горных работ?',
        answers: [
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - руководителем территориального органа государственного горного надзора',
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
          'Подготовка - организацией, привлекаемой пользователем недр, утверждение - представителем местной исполнительной власти',
          'Подготовка - пользователем недр, утверждение - руководителем территориального органа государственного горного надзора',
        ],
        correctAnswers: [
          'Подготовка - пользователем недр или юридическим лицом (индивидуальным предпринимателем), привлекаемым пользователем недр, которые имеют лицензию на производство маркшейдерских работ, утверждение - руководителем организации - пользователя недр',
        ],
      },
      {
        code: '63685077',
        text:
          'Кем утверждаются инструкции, устанавливающие требования к организации и безопасному ведению работ повышенной опасности на нефтяной шахте?',
        answers: [
          'Начальником участка',
          'Специалистом структурного подразделения, ведущего горные работы или работы по добыче нефти',
          'Техническим руководителем обособленного структурного подразделения',
          'Руководителем эксплуатирующей организации',
          'Сотрудником отдела промышленной безопасности',
        ],
        correctAnswers: [
          'Техническим руководителем обособленного структурного подразделения',
        ],
      },
      {
        code: '63685078',
        text:
          'Что должно быть указано на корпусах оборудования, входящего в состав талевой системы (кронблок, талевый блок, крюк)?',
        answers: [
          'Дата изготовления',
          'Сроки следующего испытания',
          'Материал изготовления',
          'Допускаемая грузоподъемность',
        ],
        correctAnswers: ['Допускаемая грузоподъемность'],
      },
      {
        code: '63685079',
        text:
          'Что из перечисленного не учитывается при планировании площадки для монтажа буровой установки?',
        answers: [
          'Естественный уклон местности',
          'Обеспечение движения сточных вод в систему их сбора и очистки',
          'Роза ветров',
        ],
        correctAnswers: ['Роза ветров'],
      },
      {
        code: '63685080',
        text:
          'Допускается ли не устанавливать молниеотводное устройство на возвышающихся над МСП, ПБУ, МЭ и ПТК конструкциях?',
        answers: [
          'Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК',
          'Не допускается',
          'Допускается для отдельно стоящего оборудования',
          'Допускается для взрывозащищенного оборудования',
        ],
        correctAnswers: [
          'Допускается не устанавливать молниеотводное устройство, если конструктивно предусмотрен надежный электрический контакт вышки, мачты с металлоконструкцией МСП, ПБУ, МЭ и ПТК',
        ],
      },
      {
        code: '63685081',
        text:
          'Какое расстояние должно соблюдаться при креплении скважин между цементировочными агрегатами и цементосмесительными машинами?',
        answers: [
          'Не менее 0,5 м',
          'Не менее 1 м',
          'Не менее 1,5 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 1,5 м'],
      },
      {
        code: '63685082',
        text:
          'Как часто проводится определение технического состояния заземляющего устройства?',
        answers: [
          'Один раз в год',
          'Один раз в полгода',
          'Один раз в три месяца',
          'Один раз в месяц',
        ],
        correctAnswers: ['Один раз в год'],
      },
      {
        code: '63685083',
        text:
          'При какой предельно допустимой концентрации содержания диоксида углерода в воздухе закрытого помещения работы в нем должны быть прекращены?',
        answers: [
          '0,5 (объемных) %',
          '0,2 (объемных) %',
          '0,4 (объемных) %',
          '0,1 (объемных) %',
          '0,3 (объемных) %',
        ],
        correctAnswers: ['0,5 (объемных) %'],
      },
      {
        code: '63685084',
        text:
          'С какой периодичностью и в каком объеме проводятся исследования эксплуатационных скважин на нефтегазодобывающих объектах?',
        answers: [
          'В соответствии с инструкцией по исследованию скважин, утвержденной Минприроды России',
          'Каждые 6 месяцев в полном объеме и ежеквартально в объеме, необходимом геологической службе организации',
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
          'Не регламентируется',
        ],
        correctAnswers: [
          'В соответствии с утвержденным планом работ, разработанным в соответствии с проектной документацией разработки данного месторождения',
        ],
      },
      {
        code: '63685085',
        text:
          'Каким образом организуется проветривание тупиковых горных выработок?',
        answers: [
          'Расстояние от конца вентиляционных труб до тупиковой горной выработки, проветриваемой вентиляторной установкой, должно быть не более 30 м',
          'Суммарная концентрация углеводородных газов не должна превышать 15 % от НКПР смеси или 0,5 % (по объему)',
          'Струя воздуха, исходящая из тупиковой горной выработки, должна попадать в горные выработки со свежей струей воздуха, проветривающей горные выработки, в которых проводятся закачка теплоносителя в пласт',
          'Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводится закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин',
        ],
        correctAnswers: [
          'Исходящие из тупиковых горных выработок вентиляционные струи не должны поступать в горные выработки, в которых проводится закачка теплоносителя в пласт и (или) отбор продукции добывающих скважин',
        ],
      },
      {
        code: '63685086',
        text:
          'Какие виды работ выполняются при разработке проектной документации на нефтяных месторождениях с породами, склонными к динамическим явлениям?',
        answers: [
          'Обследование разгазированных выработок',
          'Проверка состояния крепи ствола и закрепного пространства',
          'Аэродинамическое обследование',
          'Геодинамическое районирование',
        ],
        correctAnswers: ['Геодинамическое районирование'],
      },
      {
        code: '63685087',
        text:
          'Какой должна быть расчетная продолжительность процесса цементирования обсадной колонны?',
        answers: [
          'Не должна превышать 75 % времени начала загустевания тампонажного раствора',
          'Не должна превышать 95 % времени начала загустевания тампонажного раствора',
          'Не должна превышать 70 % времени начала загустевания тампонажного раствора',
          'Не должна превышать 80 % времени начала загустевания тампонажного раствора',
        ],
        correctAnswers: [
          'Не должна превышать 75 % времени начала загустевания тампонажного раствора',
        ],
      },
      {
        code: '63685088',
        text:
          'Чем оборудуются наклонные горные выработки, предназначенные для передвижения людей, при углах наклона от 31° до 45°?',
        answers: [
          'Сходнями со ступенями и перилами',
          'Трапами с перилами',
          'Лестницами с горизонтальными ступенями и перилами',
          'Трапами',
        ],
        correctAnswers: ['Лестницами с горизонтальными ступенями и перилами'],
      },
      {
        code: '63685089',
        text:
          'С какой целью применяются эксплуатационные колонны скважин, бурящихся на подсолевые нефтегазоносные комплексы?',
        answers: [
          'Для перекрытия надсолевого водоносного комплекса',
          'Для перекрытия безводной солесодержащей части разреза и монтажа противовыбросового оборудования',
          'Для предотвращения размыва устья скважины',
          'Для перекрытия неустойчивых четвертичных отложений',
          'Для разобщения продуктивных пластов от вышерасположенных флюидосодержащих пород',
        ],
        correctAnswers: [
          'Для разобщения продуктивных пластов от вышерасположенных флюидосодержащих пород',
        ],
      },
      {
        code: '63685090',
        text:
          'Какие требования предъявляются правилами к системе контроля состояния воздушной среды для закрытых помещений объектов сбора, подготовки и транспортировки нефти, газа и конденсата?',
        answers: [
          'В системе контроля состояния воздушной среды должно быть предусмотрено дублирование датчиков аварийного включения оборудования и системы приточно-вытяжной вентиляции',
          'Система должна иметь блокировку от включения оборудования, все помещения должны иметь постоянно действующую противопожарную систему',
          'Система должна быть сблокирована с системой выключения оборудования, включая перекрытие клапанов, все помещения должны иметь постоянно действующую систему оповещения и сигнализации',
          'Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции',
          'Система должна иметь блокировку от выключения оборудования, включая перекрытие запорной арматуры, все помещения должны иметь постоянно действующую систему вытяжной вентиляции',
        ],
        correctAnswers: [
          'Система должна быть сблокирована с системой звуковой и световой аварийной сигнализации, все помещения должны иметь постоянно действующую систему приточно-вытяжной вентиляции',
        ],
      },
      {
        code: '63685091',
        text: 'Кем утверждаются план и схема развития горных работ?',
        answers: [
          'Техническим руководителем организации - пользователя недр',
          'Руководителем организации - пользователя недр',
          'Органом государственного горного надзора',
          'Органом исполнительной власти субъекта Российской Федерации',
          'Руководителем проектной организации',
        ],
        correctAnswers: ['Руководителем организации - пользователя недр'],
      },
      {
        code: '63685092',
        text:
          'Что необходимо сделать с газораспределительными трубопроводами после их монтажа?',
        answers: [
          'Продуть кислородом и опрессовать жидкостью на давление, превышающее на 50 % максимальное рабочее',
          'Продуть инертным газом и провести пневматическое испытание на давление, превышающее на 35 % расчетное',
          'Продуть азотом и опрессовать жидкостью на давление, превышающее на 15 % максимальное расчетное',
          'Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее',
        ],
        correctAnswers: [
          'Продуть сжатым воздухом и опрессовать жидкостью на давление, превышающее на 25 % максимальное рабочее',
        ],
      },
      {
        code: '63685093',
        text:
          'Где следует устанавливать башмак обсадной колонны, перекрывающий породы, склонные к текучести?',
        answers: [
          'Только ниже их подошвы',
          'Только в плотных пропластках',
          'Ниже их подошвы или в плотных пропластках',
        ],
        correctAnswers: ['Ниже их подошвы или в плотных пропластках'],
      },
      {
        code: '63685094',
        text:
          'В каких случаях в целях обеспечения промышленной безопасности не допускается промышленная разработка месторождений нефти на площадях залегания запасов калийных солей?',
        answers: [
          'На площадях залегания калийных солей, разведанных по категории С2',
          'На снятых с государственного баланса запасах',
          'На забалансовых запасах',
          'На неразведанных площадях',
        ],
        correctAnswers: [
          'На площадях залегания калийных солей, разведанных по категории С2',
        ],
      },
      {
        code: '63685095',
        text:
          'В течение какого времени производится закладка наблюдательной станции для проведения маркшейдерских инструментальных наблюдений и определения величин деформаций над вводимыми в разработку нефтяными месторождениями при совместном освоении месторождений нефти и калийных солей?',
        answers: [
          'Не позднее чем за 6 месяцев до начала пробной эксплуатации нефтяной залежи',
          'Не позднее чем за 3 месяца до начала пробной эксплуатации нефтяной залежи',
          'Не позднее чем за 1 месяц до начала пробной эксплуатации нефтяной залежи',
          'По мере необходимости',
        ],
        correctAnswers: [
          'Не позднее чем за 6 месяцев до начала пробной эксплуатации нефтяной залежи',
        ],
      },
      {
        code: '63685096',
        text:
          'Кем осуществляется надзор за ходом производства буровых работ, качеством выполнения этих работ, уровнем технологических процессов и операций, качеством используемых материалов и технических средств, соблюдением безопасных условий труда?',
        answers: [
          'Территориальным органом Ростехнадзора',
          'Проектной организацией',
          'Региональным центром Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий',
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
        ],
        correctAnswers: [
          'Пользователем недр (заказчиком), организацией, осуществляющей производство буровых работ, и другими субъектами хозяйственной деятельности, уполномоченными пользователем недр',
        ],
      },
      {
        code: '63685097',
        text:
          'Кому выдается наряд на выполнение работ на горных выработках нефтяной шахты, определенных организационно-распорядительным документом обособленного структурного подразделения?',
        answers: [
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 6 месяцев',
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 4 месяцев',
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 12 месяцев',
          'Не менее чем трем работникам, имеющим стаж работы по профессии не менее 6 месяцев',
        ],
        correctAnswers: [
          'Не менее чем двум работникам, имеющим стаж работы по профессии не менее 6 месяцев',
        ],
      },
      {
        code: '63685098',
        text:
          'С какой периодичностью на нефтяной шахте проводится плановая депрессионная съемка?',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в полгода',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '63685099',
        text:
          'Какой радиус опасной зоны должен быть установлен вокруг скважины и применяемого оборудования на период тепловой и комплексной обработки?',
        answers: [
          'Не менее 35 м',
          'Не менее 10 м',
          'Не менее 50 м',
          'Не менее 20 м',
        ],
        correctAnswers: ['Не менее 50 м'],
      },
      {
        code: '63685100',
        text:
          'В какой период осуществляется рассмотрение планов развития горных работ?',
        answers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 19 сентября по 30 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 августа по 25 ноября года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
          'В период с 20 октября по 25 января года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
        correctAnswers: [
          'В период с 20 сентября по 25 декабря года, предшествующего планируемому, в соответствии с графиком рассмотрения планов развития горных работ',
        ],
      },
      {
        code: '63685101',
        text:
          'Какие меры должны приниматься в случае производства на скважине работ, требующих давлений, превышающих давления опрессовки обсадной колонны?',
        answers: [
          'На устье устанавливается предохранитель, эксплуатационная колонна защищается специальным наголовником',
          'Проведение таких работ категорически запрещено',
          'На устье устанавливается обратный клапан, эксплуатационная колонна защищается клапаном-отсекателем',
          'На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера',
        ],
        correctAnswers: [
          'На устье устанавливается специальная арматура, а эксплуатационная колонна защищается установкой пакера',
        ],
      },
      {
        code: '63685102',
        text:
          'Каким требованиям должны соответствовать электрические сети на МСП, ПБУ, МЭ и ПТК?',
        answers: [
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор',
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с неизолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через низкоомный резистор',
          'Должны быть изолированными, в том числе сети двухфазного переменного тока напряжением 220 В',
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 0,4 кВ с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 0,4 кВ заземляется через высокоомный резистор',
        ],
        correctAnswers: [
          'Должны быть изолированными, в том числе сети трехфазного переменного тока напряжением до 1000 В с изолированной нейтралью. Нейтраль электрических сетей напряжением свыше 1000 В заземляется через высокоомный резистор',
        ],
      },
      {
        code: '63685103',
        text:
          'На каком расстоянии устанавливаются основной и вспомогательный пульты управления превенторами и гидравлическими задвижками?',
        answers: [
          'Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика',
          'Основной пульт управления - на расстоянии не менее 5 м от устья скважины в удобном и безопасном месте, вспомогательный - не менее 2 м от пульта бурильщика',
          'Основной пульт управления - на расстоянии не менее 3 м от устья скважины в удобном и безопасном месте, вспомогательный - не менее 5 м от пульта бурильщика',
          'Основной пульт управления - на расстоянии не менее 8 м от устья скважины в удобном и безопасном месте, вспомогательный - не менее 3 м от пульта бурильщика',
        ],
        correctAnswers: [
          'Основной пульт управления - на расстоянии не менее 10 м от устья скважины в удобном и безопасном месте, вспомогательный - непосредственно возле пульта бурильщика',
        ],
      },
      {
        code: '63685104',
        text:
          'Кем оформляется документация, удостоверяющая уточненные границы горного отвода, если участки недр местного значения расположены на территориях 2 и более субъектов Российской Федерации и если нормативными правовыми актами субъекта Российской Федерации не определен орган исполнительной власти субъекта Российской Федерации?',
        answers: [
          'Органом государственного горного надзора',
          'Органами исполнительной власти каждого из субъектов Российской Федерации',
          'Органом исполнительной власти любого из причастных к горному отводу субъектов Российской Федерации',
          'Технической службой организации - пользователя недр',
        ],
        correctAnswers: ['Органом государственного горного надзора'],
      },
      {
        code: '63685105',
        text:
          'С какой периодичностью осуществляется контроль мест ведения работ в горных выработках нефтяной шахты руководителем или заместителем руководителя структурного подразделения, за которым закреплены эти горные выработки?',
        answers: [
          'Каждые 10 часов',
          'Ежесменно',
          'Каждые 3 часа',
          'Ежесуточно',
          'Еженедельно',
        ],
        correctAnswers: ['Ежесуточно'],
      },
      {
        code: '63685106',
        text:
          'На какой максимальный срок составляется схема развития горных работ?',
        answers: ['На 2 года', 'На 10 лет', 'На 5 лет', 'На 6 лет'],
        correctAnswers: ['На 5 лет'],
      },
      {
        code: '63685107',
        text:
          'На основе чего составляются планы и схемы развития горных работ?',
        answers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
          'На основе рекомендаций территориальных комиссий по запасам полезных ископаемых Минприроды России',
          'На основе технического регламента',
          'На основе локального плана проведения работ',
          'На основе рекомендаций Ростехнадзора',
          'На основе ПЛА',
        ],
        correctAnswers: [
          'На основе утвержденной в установленном порядке проектной документации, условий лицензий на пользование недрами, соглашений о разделе продукции и требований, регламентирующих выполнение работ, связанных с пользованием недрами',
        ],
      },
      {
        code: '63685108',
        text:
          'Как Правила безопасности в нефтяной и газовой промышленности регламентируют использование крепи горной выработки в качестве опорной конструкции?',
        answers: [
          'Использование крепи Правилами не регламентируется',
          'Использование крепи не допускается, за исключением подвески вентиляционных труб и кабельной сети',
          'Использование крепи не допускается',
          'Использование крепи допускается',
        ],
        correctAnswers: [
          'Использование крепи не допускается, за исключением подвески вентиляционных труб и кабельной сети',
        ],
      },
      {
        code: '63685109',
        text:
          'Какие меры безопасности должны быть предусмотрены при проведении ремонтных работ или укладке кабеля после ремонта?',
        answers: [
          'Включение питания проводится ответственным дежурным по энергоснабжению после устного подтверждения руководителя работ и личного контроля по окончании этих работ',
          'Одновременное проведение испытания и ремонтных работ различными бригадами в пределах одного присоединения допускается по одному наряду-заданию с указанием в строке "Отдельные указания" дополнительных мер, обеспечивающих безопасность работников',
          'При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель',
          'До начала ремонтных работ после подъема поврежденного кабеля на борт кабелеукладочного судна кабель отключается хотя бы с одной стороны и заземляется',
        ],
        correctAnswers: [
          'При осмотре, ремонтных работах или укладке кабеля после ремонта исключается случайная подача напряжения в укладываемый, осматриваемый или ремонтируемый кабель',
        ],
      },
      {
        code: '63685110',
        text:
          'С какой предельной ошибкой в плане производится вынос скважин в натуру?',
        answers: [
          'Вынос скважин в натуру производится с предельной ошибкой в плане  5,0 м, а привязка - 1,0 м',
          'Вынос скважин в натуру производится с предельной ошибкой в плане  10,0 м, а привязка -  2,0 м',
          'Вынос скважин в натуру производится с предельной ошибкой в плане  3,0 м, а привязка - 1,0 м',
          'Вынос скважин в натуру производится с предельной ошибкой в плане  3,0 м, а привязка - 0,5 м',
        ],
        correctAnswers: [
          'Вынос скважин в натуру производится с предельной ошибкой в плане  5,0 м, а привязка - 1,0 м',
        ],
      },
      {
        code: '63685111',
        text:
          'С какой периодичностью проводится оценка влияния разработки нефтяных месторождений на сохранность калийных солей?',
        answers: [
          'Не реже одного раза в два года',
          'Ежегодно',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в пять лет'],
      },
      {
        code: '63685112',
        text:
          'Как оборудуется устье скважины при ликвидации скважин, пробуренных с ПБУ?',
        answers: [
          'На устье скважины устанавливается бетонная тумба с репером высотой не менее 5 м',
          'На устье скважины устанавливается репер',
          'Выступающая над дном моря обсадная колонна (в случае, если при бурении скважины не использовались специальная система придонных подвесок, колонные головки) удаляется на уровень дна моря',
        ],
        correctAnswers: [
          'Выступающая над дном моря обсадная колонна (в случае, если при бурении скважины не использовались специальная система придонных подвесок, колонные головки) удаляется на уровень дна моря',
        ],
      },
      {
        code: '63685113',
        text:
          'Чему должна соответствовать документация по ведению горных работ и документация по ведению работ по добыче нефти?',
        answers: [
          'Проектной документации, а также горно-геологическим и горнотехническим условиям',
          'Инструкции по охране окружающей среды при строительстве скважин на нефть и газ',
          'Инструкции по производству маркшейдерских работ',
          'Техническим регламентам таможенного союза',
        ],
        correctAnswers: [
          'Проектной документации, а также горно-геологическим и горнотехническим условиям',
        ],
      },
      {
        code: '63685114',
        text:
          'У каких лиц устанавливается аппаратура системы общешахтного аварийного оповещения?',
        answers: [
          'У горного диспетчера нефтяной шахты',
          'У технического руководителя обособленного структурного подразделения',
          'У всех перечисленных лиц',
          'У абонентов общешахтной и диспетчерской телефонной связи, определенных техническим руководителем обособленного структурного подразделения',
        ],
        correctAnswers: ['У всех перечисленных лиц'],
      },
      {
        code: '63685115',
        text:
          'Какая максимальная температура воздуха допускается в добычных, подготовительных и других действующих выработках у мест, где работают люди, при относительной влажности до 90 %?',
        answers: ['28 °C', '24 °C', '25 °C', '26 °C'],
        correctAnswers: ['26 °C'],
      },
      {
        code: '63685116',
        text:
          'С учетом каких параметров производятся выбор обсадных труб и расчет обсадных колонн на стадиях строительства и эксплуатации скважин?',
        answers: [
          'С учетом минимальных ожидаемых наружных и внутренних давлений при полном замещении раствора пластовым флюидом, снижении уровня осевых нагрузок на трубы',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении бурового раствора пластовым флюидом и агрессивности флюида',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при частичном замещении раствора газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы',
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
        ],
        correctAnswers: [
          'С учетом максимальных ожидаемых избыточных наружных и внутренних давлений при полном замещении раствора пластовым флюидом или газожидкостной смесью, снижении уровня, а также осевых нагрузок на трубы и агрессивности флюида',
        ],
      },
      {
        code: '63685117',
        text:
          'Где прокладываются напорные водопроводы и трубопроводы сжатого воздуха?',
        answers: [
          'В скважинах',
          'В наклонных горных выработках',
          'В вертикальных горных выработках, оборудованных клетевым подъемом',
          'В горизонтальных горных выработках',
        ],
        correctAnswers: [
          'В вертикальных горных выработках, оборудованных клетевым подъемом',
        ],
      },
      {
        code: '63685118',
        text:
          'Через какое расстояние эстакады для трубопроводов при обустройстве нефтяных, газовых и газоконденсатных месторождений должны быть электрически соединены с проходящими по ним трубопроводами и заземлены?',
        answers: [
          'Через 200 - 300 м, а также в начале и в конце',
          'Через 360 - 420 м, а также в начале и в конце',
          'Через 170 - 230 м, а также в начале и в конце',
          'Через 550 - 600 м, а также в начале и в конце',
        ],
        correctAnswers: ['Через 200 - 300 м, а также в начале и в конце'],
      },
      {
        code: '63685119',
        text:
          'Какие показатели должны контролироваться при бурении наклонно-направленных и горизонтальных скважин?',
        answers: [
          'Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
          'Плотность, структурно-механические и реологические свойства бурового раствора и пространственное расположение ствола скважины',
          'Крутящий момент на роторе при роторном способе бурения, давление в манифольде буровых насосов и азимут ствола скважины',
          'Расход бурового раствора на входе и выходе из скважины, давление в манифольде буровых насосов и зенитный угол ствола скважины',
        ],
        correctAnswers: [
          'Азимут, зенитный угол ствола скважины, пространственное расположение ствола скважины, взаимное расположение стволов бурящейся и ранее пробуренных соседних скважин',
        ],
      },
      {
        code: '63685120',
        text:
          'Из чего должны состоять графические материалы планов (схем) развития горных работ в зависимости от видов горных работ и видов полезных ископаемых?',
        answers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия',
          'Из структурных карт, характерных разрезов, проекций, схем',
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
          'Из схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ)',
        ],
        correctAnswers: [
          'Из маркшейдерских планов земной поверхности территории хозяйственной деятельности предприятия, планов горных выработок (горных работ), схем вскрытия месторождения, обзорных и (или) совмещенных планов земной поверхности и горных выработок (горных работ), погоризонтных планов горных выработок (горных работ), геологических и структурных карт, характерных разрезов, проекций, схем',
        ],
      },
      {
        code: '63685121',
        text:
          'В соответствии с какими документами должна устанавливаться скорость изменения технологических параметров?',
        answers: [
          'В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования',
          'В соответствии с инструкциями завода-изготовителя по пуску, эксплуатации и остановке установок',
          'В соответствии с инструкциями по пуску, эксплуатации и остановке установок, согласованными с Ростехнадзором',
          'В соответствии с правилами безопасности в нефтяной и газовой промышленности',
          'В соответствии с планами проведения работ, утвержденными техническим руководителем организации и согласованными с Ростехнадзором',
          'В соответствии с проектами',
        ],
        correctAnswers: [
          'В соответствии с технологическим регламентом и заводскими инструкциями по эксплуатации оборудования',
        ],
      },
      {
        code: '63685122',
        text:
          'В каком случае должно обеспечиваться полное отключение оборудования и механизмов в закрытых помещениях буровой установки, где возможно возникновение или проникновение воспламеняющихся смесей?',
        answers: [
          'При достижении 20 % от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 40 % от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами',
          'При достижении 30 % от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
        correctAnswers: [
          'При достижении 50 % от нижнего предела воспламенения смеси воздуха с углеводородами',
        ],
      },
      {
        code: '63685123',
        text:
          'В каком случае буровые работы можно производить без применения дополнительных мер безопасности?',
        answers: [
          'В случае производства буровых работ в многолетнемерзлых породах',
          'Во всех перечисленных случаях производство буровых работ необходимо производить с применением дополнительных мер безопасности',
          'В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3 % об. сероводорода',
          'В случае производства буровых работ с кустовых площадок',
        ],
        correctAnswers: [
          'В случае производства буровых работ на месторождениях с содержанием в нефти (газе) 3 % об. сероводорода',
        ],
      },
      {
        code: '63685124',
        text:
          'За сколько суток до начала проведения работ по строительству и ремонту подземных скважин, предназначенных для закачивания теплоносителя, на участках, где ведется нагнетание пара в пласт, должно быть прекращено закачивание теплоносителя в соседние скважины?',
        answers: [
          'Не позднее чем за сутки до начала работ',
          'Не позднее чем за 5 суток до начала работ',
          'Не позднее чем за 10 суток до начала работ',
          'Не позднее чем за 15 суток до начала работ',
        ],
        correctAnswers: ['Не позднее чем за 10 суток до начала работ'],
      },
      {
        code: '63685125',
        text:
          'Что из перечисленного не является основанием для принятия решения об отказе в согласовании плана и (или) схемы развития горных работ?',
        answers: [
          'Наличие недостоверных сведений в представленных документах',
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
          'Отсутствие обоснования соблюдения условий безопасного недропользования',
          'Отсутствие геологического и маркшейдерского обеспечения горных работ',
        ],
        correctAnswers: [
          'Наличие в предоставленной документации сведений о планировании производства работ подрядными организациями',
        ],
      },
      {
        code: '63685126',
        text:
          'Сколько экземпляров документации, удостоверяющей уточненные границы горного отвода, оформляется органом исполнительной власти субъекта Российской Федерации?',
        answers: [
          '2 экземпляра',
          '3 экземпляра',
          '4 экземпляра',
          '5 экземпляров',
        ],
        correctAnswers: ['3 экземпляра'],
      },
      {
        code: '63685127',
        text:
          'Разрешается ли прокладка заглубленных каналов и тоннелей при обустройстве нефтяных, газовых и газоконденсатных месторождений для размещения кабелей в помещениях и на территории наружных установок, имеющих источники возможного выделения в атмосферу вредных веществ плотностью по воздуху более 0,8, а также источники возможных проливов горючих и серосодержащих жидкостей?',
        answers: [
          'Разрешается по согласованию с проектной организацией',
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
          'Запрещается в любом случае',
          'Разрешается в любом случае',
        ],
        correctAnswers: [
          'Запрещается, за исключением каналов и тоннелей, подлежащих последующей засыпке',
        ],
      },
      {
        code: '63685128',
        text:
          'Кем выполняется вскрытие перемычек и разгазирование изолированных горных выработок?',
        answers: [
          'Профессиональным аварийно-спасательным формированием',
          'Специализированной подрядной организацией',
          'Службой аэрологической безопасности',
          'Эксплуатирующей организацией',
        ],
        correctAnswers: [
          'Профессиональным аварийно-спасательным формированием',
        ],
      },
      {
        code: '63685129',
        text:
          'На основании какого документа осуществляются работы по строительству, эксплуатации и ремонту подземных скважин?',
        answers: [
          'На основании плана работ',
          'На основании технологической инструкции',
          'На основании распоряжения руководителя работ',
          'На основании наряда',
        ],
        correctAnswers: ['На основании наряда'],
      },
      {
        code: '63685130',
        text:
          'Каковы требования к производству работ по глубинным измерениям в скважинах с избыточным давлением на устье?',
        answers: [
          'Производятся в соответствии с требованиями заказчика',
          'Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны',
          'Производятся с применением утяжеленного бурового раствора',
        ],
        correctAnswers: [
          'Производятся с применением лубрикатора, опрессованного на рабочее давление, установленное изготовителем, а после установки - на давление опрессовки колонны',
        ],
      },
      {
        code: '63685131',
        text:
          'Какие требования предъявляются к условиям закладки скважин, предназначенных для поисков, разведки, эксплуатации месторождений нефти, газа и газового конденсата?',
        answers: [
          'Скважины должны закладываться в пределах горного отвода и зоны производственной застройки',
          'Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередач, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов',
          'Скважины должны закладываться в пределах земельного отвода и зоны магистральных нефтегазопроводов',
        ],
        correctAnswers: [
          'Скважины должны закладываться за пределами границ зоны санитарной охраны источников водоснабжения и водопроводов питьевого назначения, водоохранных зон, охранных зон линий электропередач, магистральных нефтегазопроводов, водозаборных, других промышленных и гражданских объектов',
        ],
      },
      {
        code: '63685132',
        text:
          'Что требуется для остановки технических устройств, обеспечивающих проветривание и кондиционирование горных выработок, водоснабжение, откачку воды, спуск и подъем работников, работу МФСБ для выполнения ремонтных работ?',
        answers: [
          'Письменное разрешение технического руководителя обособленного структурного подразделения',
          'Письменное распоряжение технического руководителя обособленного структурного подразделения, согласованное с Ростехнадзором',
          'Письменное распоряжение руководителя эксплуатирующей организации',
          'Письменное или устное распоряжение технического руководителя обособленного структурного подразделения',
          'Акт-допуск, подписанный руководителем эксплуатирующей организации',
        ],
        correctAnswers: [
          'Письменное разрешение технического руководителя обособленного структурного подразделения',
        ],
      },
      {
        code: '63685133',
        text:
          'В каком случае запрещается производить спуск технических и эксплуатационных колонн в скважину?',
        answers: [
          'Если скважина осложнена осыпями и обвалами',
          'Если скважина осложнена затяжками и посадками бурильной колонны',
          'Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений',
          'Если скважина осложнена поглощениями бурового раствора с одновременным флюидопроявлением',
        ],
        correctAnswers: [
          'Спуск технических и эксплуатационных колонн во всех перечисленных случаях запрещен до ликвидации осложнений',
        ],
      },
      {
        code: '63685134',
        text:
          'Кем утверждается проект и план перевода скважины на газлифтную эксплуатацию?',
        answers: [
          'Техническим руководителем организации',
          'Мастером добычи участка',
          'Представителем Ростехнадзора',
          'Руководителем проектной организации',
        ],
        correctAnswers: ['Техническим руководителем организации'],
      },
      {
        code: '63685135',
        text:
          'В соответствии с какими документами осуществляется проведение и крепление горных выработок?',
        answers: [
          'Всеми перечисленными',
          'Проектной документацией',
          'Технологическими регламентами',
          'Документацией по ведению горных работ',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '63685136',
        text:
          'На каком расстоянии от устья скважины разрешается устанавливать компрессоры и парогенераторные установки?',
        answers: [
          'Не менее 8 м от устья скважины',
          'Не менее 25 м от устья скважины',
          'Не менее 12 м от устья скважины',
          'Не менее 15 м от устья скважины',
        ],
        correctAnswers: ['Не менее 25 м от устья скважины'],
      },
      {
        code: '63685137',
        text:
          'Какие требования к атмосфере в горных выработках нефтяных шахт указаны верно?',
        answers: [
          'Максимально допустимая концентрация диоксида углерода в рудничном воздухе в горных выработках с исходящей струей крыла, горизонта и шахты составляет 0,75%',
          'Все перечисленные',
          'Максимально допустимая концентрация водорода в зарядных камерах составляет 0,5%',
          'Концентрация кислорода в воздухе в горных выработках нефтяных шахт должна составлять не менее 20% (по объему)',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '63685138',
        text:
          'Какой верхний предел диапазона измерений должны иметь манометры, устанавливаемые на блоках дросселирования и глушения?',
        answers: [
          'На 10 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'На 80 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
          'На 50 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
        ],
        correctAnswers: [
          'На 30 % превышающий давление совместной опрессовки обсадной колонны и противовыбросового оборудования',
        ],
      },
      {
        code: '63685139',
        text:
          'Какие параметры эксплуатации объектов ведения горных работ планами и схемами развития горных работ не определяются?',
        answers: [
          'Объемы добычи и первичной переработки полезных ископаемых',
          'Направления развития горных работ',
          'Условия безопасного недропользования, технические и технологические решения при эксплуатации объектов ведения горных работ',
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
        correctAnswers: [
          'Штатная численность сотрудников объекта ведения горных работ',
        ],
      },
      {
        code: '63685140',
        text:
          'Что из нижеперечисленного должно быть указано в плане производства работ по нагнетанию в скважину газа, пара, химических и других агентов?',
        answers: [
          'Ответственный руководитель работ',
          'Порядок подготовительных работ и схема размещения оборудования',
          'Меры безопасности',
          'Технология проведения процесса',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63685141',
        text:
          'На какую глубину простирается охранная зона площадей залегания балансовых запасов калийных солей?',
        answers: [
          'На 300 м ниже подошвы подстилающей каменной соли, но не менее 800 м ниже калийной залежи',
          'На 200 м ниже подошвы подстилающей каменной соли, но не менее 400 м ниже калийной залежи',
          'Не менее 500 м ниже калийной залежи',
          'На 600 м ниже подошвы подстилающей каменной соли',
        ],
        correctAnswers: [
          'На 300 м ниже подошвы подстилающей каменной соли, но не менее 800 м ниже калийной залежи',
        ],
      },
      {
        code: '63685142',
        text:
          'В каком случае установка автоматического клапана-отсекателя на выкидной линии газовых и газоконденсатных скважин не требуется?',
        answers: [
          'В случае если выполняется эксплуатационное бурение',
          'В случае если содержание сероводорода в природном газе или газоконденсате составляет не более 2 %',
          'В случае если выполняется разведочное бурение',
          'В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины',
        ],
        correctAnswers: [
          'В случае наличия подземного клапана-отсекателя в составе комплекса подземного оборудования скважины',
        ],
      },
      {
        code: '63685143',
        text:
          'Какие требования предъявляются Правилами безопасности в нефтяной и газовой промышленности к работникам, выполняющим горные работы?',
        answers: [
          'Горные работы выполняются не менее чем тремя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев',
          'Горные работы выполняются не менее чем тремя работниками, причем стаж работы по профессии одного из них должен быть не менее 3 месяцев',
          'Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев',
          'Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 12 месяцев',
        ],
        correctAnswers: [
          'Горные работы выполняются не менее чем двумя работниками, причем стаж работы по профессии одного из них должен быть не менее 6 месяцев',
        ],
      },
      {
        code: '63685144',
        text:
          'Кем устанавливается состав работников геофизической партии и буровой бригады, находящихся при ПВР в пределах опасной зоны?',
        answers: [
          'Эксплуатирующей организацией',
          'Организацией-исполнителем ПВР',
          'Совместно всеми перечисленными организациями',
          'Организацией-исполнителем буровых работ',
        ],
        correctAnswers: ['Совместно всеми перечисленными организациями'],
      },
      {
        code: '63685145',
        text:
          'Что должно включаться в опасную зону на период прострелочно-взрывных работ на МСП, ПБУ и МЭ?',
        answers: [
          'Буровая вышка',
          'Место зарядки прострелочно-взрывных аппаратов и подготовки торпед и каротажная лебедка',
          'Все перечисленное',
          'Трасса каротажного кабеля',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '63685146',
        text:
          'Допускается ли пересечение технологических трубопроводов с газом, ЛВЖ, ГЖ с трубопроводами негорючих веществ на ОПО МНГК?',
        answers: [
          'Не допускается',
          'Допускается при наличии разрешения Ростехнадзора',
          'Допускается, если трубопроводы негорючих веществ располагаются снизу',
          'Допускается, если трубопроводы негорючих веществ располагаются сверху',
        ],
        correctAnswers: [
          'Допускается, если трубопроводы негорючих веществ располагаются снизу',
        ],
      },
      {
        code: '63685147',
        text:
          'Что должны обеспечивать конструкция и схема колонной устьевой обвязки, фонтанной арматуры?',
        answers: [
          'Возможность обеспечения правильной центровки обсадных колонн в скважине',
          'Оптимальные режимы при эксплуатации и подземном ремонте скважины, а также возможность безопасного проведения технологических операций на скважине и глубинных исследований',
          'Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры',
          'Безопасный отбор проб и контроль устьевого давления и температуры, проведение работ при глушении скважины',
        ],
        correctAnswers: [
          'Оптимальные режимы работы скважины, возможность герметизации трубного, затрубного и межтрубных пространств, возможность выполнения технологических операций в скважине, глубинные исследования, отбор проб и контроль устьевого давления и температуры',
        ],
      },
      {
        code: '63685148',
        text:
          'Какие требования предъявляются к спуску забойного электронагревателя в скважину?',
        answers: [
          'Операции должны выполняться дистанционно, с использованием специального герметизатора при герметизированном устье',
          'Операции должны выполняться дистанционно, с использованием переносной превенторной установки, с записью результатов',
          'Операции должны быть автоматизированы, с использованием специального обратного клапана, при постоянном наблюдении за устьем',
          'Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье',
        ],
        correctAnswers: [
          'Операции должны быть механизированы, с использованием специального лубрикатора, при герметизированном устье',
        ],
      },
      {
        code: '63685149',
        text:
          'Что должно входить в состав планов и схем развития горных работ?',
        answers: [
          'Список с указанием паспортных данных каждого работника',
          'Графическая часть и пояснительная записка с табличными материалами',
          'Фотографии мест дальнейшего производства работ',
          'Наряд-допуск на выполнение работ',
        ],
        correctAnswers: [
          'Графическая часть и пояснительная записка с табличными материалами',
        ],
      },
      {
        code: '63685150',
        text:
          'Какое из перечисленных действий перед проведением прострелочно-взрывных работ указано неверно?',
        answers: [
          'Включить активную катодную защиту',
          'Прекратить погрузочно-разгрузочные работы',
          'Отключить мобильные (носимые) радиостанции внутренней связи',
          'Оповестить радиостанции, находящиеся на связи, о прекращении работы радиостанции на МСП, ПБУ или МЭ до специального разрешения',
        ],
        correctAnswers: ['Включить активную катодную защиту'],
      },
      {
        code: '63685151',
        text:
          'Что необходимо предпринять в процессе подъема колонны бурильных труб для предупреждения газонефтеводопроявлений?',
        answers: [
          'Следует ввести промывочные жидкости, инертные по отношению к горным породам',
          'Следует обеспечить большую скорость восходящего потока жидкости в кольцевом пространстве',
          'Следует ввести в промывочную жидкость смазывающие добавки',
          'Следует производить долив бурового раствора в скважину',
        ],
        correctAnswers: [
          'Следует производить долив бурового раствора в скважину',
        ],
      },
      {
        code: '63685152',
        text:
          'Как Правила безопасности в нефтяной и газовой промышленности регламентируют посещение работниками горных выработок, температура рудничной атмосферы в которых превышает значения 25 °C при относительной влажности свыше 90 %?',
        answers: [
          'Посещение допускается не менее чем двумя работниками',
          'Посещение допускается при скорости воздуха не менее 0,05 м/с',
          'Посещение не допускается',
          'Посещение допускается не более чем на 30 минут',
        ],
        correctAnswers: [
          'Посещение допускается не менее чем двумя работниками',
        ],
      },
      {
        code: '63685153',
        text:
          'Разрешается ли при обустройстве нефтяных, газовых и газоконденсатных месторождений последовательное соединение заземляющим проводником нескольких аппаратов или резервуаров?',
        answers: [
          'Не допускается',
          'Допускается',
          'Не допускается, за исключением аппаратов или резервуаров, установленных в одном обваловании',
          'Допускается при условии, что общее сопротивление заземляющего проводника не превышает 20 Ом',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '63685154',
        text:
          'С какой периодичностью проверяется состояние горных выработок, служащих запасными выходами, лицами, назначенными организационно-распорядительным документом обособленного структурного подразделения?',
        answers: [
          'Не реже одного раза в шесть месяцев',
          'Не реже одного раза в три месяца',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
        ],
        correctAnswers: ['Не реже одного раза в месяц'],
      },
      {
        code: '63685155',
        text:
          'В каком виде подготавливаются схемы развития горных работ в отношении вскрышных, подготовительных, рекультивационных работ, а также работ по добыче полезных ископаемых и связанной с ней первичной переработкой минерального сырья, содержащие графическую часть и пояснительную записку с табличными материалами?',
        answers: [
          'Только в виде схем эксплуатации объектов недропользования и (или) их отдельных участков',
          'Во всех перечисленных',
          'Только в виде схем эксплуатации объектов первичной переработки (подготовки) минерального сырья (для месторождений твердых полезных ископаемых, включая общераспространенные)',
          'Только в виде схем эксплуатации объектов обустройства и схемы подготовки углеводородов (для месторождений углеводородного сырья)',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '63685156',
        text:
          'Какое должно быть расстояние между устьями скважин при расположении систем управления оборудования ПВО при бурении скважин и задвижками фонтанной арматуры эксплуатируемых скважин на одном ярусе при отсутствии обоснованных проектных решений о конкретных расстояниях?',
        answers: [
          'Не менее 2,4 м (для нефтяных)',
          'Не менее 3 м (для газовых и газоконденсатных)',
          'Не менее 5 м',
        ],
        correctAnswers: ['Не менее 5 м'],
      },
      {
        code: '63685157',
        text:
          'При каких условиях допускается подключать сетевой кабель к пусковому оборудованию электронагревателя?',
        answers: [
          'После проведения всех подготовительных работ в скважине и на устье',
          'После удаления людей',
          'После выполнения всех условий',
          'После подключения кабель-троса к трансформатору',
          'После заземления электрооборудования',
        ],
        correctAnswers: ['После выполнения всех условий'],
      },
      {
        code: '63685158',
        text:
          'Каким федеральным органом исполнительной власти оформляется горноотводная документация?',
        answers: [
          'Министерством природных ресурсов и экологии Российской Федерации',
          'Федеральным агентством по недропользованию',
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
          'Федеральным агентством в сфере природопользования',
        ],
        correctAnswers: [
          'Органом государственного горного надзора и уполномоченными органами исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '63685159',
        text:
          'Каким образом должно осуществляться управление энергетическими установками на ОПО МНГК?',
        answers: [
          'Управление энергетическими установками должно осуществляться с пультов, расположенных в помещении жилого блока',
          'Управление энергетическими установками должно осуществляться только с пультов, расположенных в помещениях каждой установки',
          'Управление энергетическими установками должно осуществляться только с центрального пульта управления',
          'Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки',
          'Управление энергетическими установками должно осуществляться с пультов, расположенных в помещении бытового блока',
        ],
        correctAnswers: [
          'Управление энергетическими установками должно осуществляться как с центрального пульта управления, так и с пультов, расположенных в помещениях каждой установки',
        ],
      },
      {
        code: '63685160',
        text:
          'Каким требованиям должны соответствовать трубопроводы, проложенные от устья скважин до технологических установок?',
        answers: [
          'Должны соответствовать всем перечисленным требованиям',
          'Должны быть рассчитаны на полуторакратное рабочее давление',
          'В начале и конце трубопровода краской должны быть нанесены номер скважины и направление потока',
          'Должны быть проложены в один ярус',
        ],
        correctAnswers: [
          'Должны соответствовать всем перечисленным требованиям',
        ],
      },
      {
        code: '63685161',
        text:
          'Какая информация дополнительно включается в план при ведении работ, связанных с проводкой боковых стволов?',
        answers: [
          'Интервал вырезки "окна" в эксплуатационной колонне',
          'Компоновки колонны труб и низа бурильной колонны',
          'Режимы проходки бокового ствола и утилизации выбуренной породы',
          'Крепление пробуренного ствола (спуск фильтра, технологическая оснастка, сочленение фильтра с эксплуатационной колонной и другие технологические операции)',
          'Все перечисленное дополнительно включается в план работ',
          'Тип породоразрушающего инструмента и его привода',
        ],
        correctAnswers: [
          'Все перечисленное дополнительно включается в план работ',
        ],
      },
      {
        code: '63685162',
        text:
          'Какое из перечисленных требований промышленной безопасности допускается при строительстве и эксплуатации подземных скважин?',
        answers: [
          'Допускается устанавливать запорные приспособления между предохранительными клапанами и оборудованием',
          'Допускается устанавливать задвижки без специальных запоров на продувочных стояках, конденсатосборных и конденсатоотводных линиях',
          'Допускается использовать общешахтную сеть сжатого воздуха и (или) передвижные компрессорные установки в качестве источников сжатого воздуха',
          'Допускается устанавливать предохранительные клапаны и контрольно-измерительные приборы на работающем оборудовании',
          'Допускается применять смазочные масла, не соответствующие эксплуатационной документации на оборудование',
        ],
        correctAnswers: [
          'Допускается использовать общешахтную сеть сжатого воздуха и (или) передвижные компрессорные установки в качестве источников сжатого воздуха',
        ],
      },
      {
        code: '63685163',
        text:
          'В каком положении должна находиться задвижка на отводе от затрубного пространства при закачке теплоносителя (с установкой пакера)?',
        answers: [
          'Не имеет значения',
          'В открытом',
          'В закрытом',
          'В полуоткрытом',
          'В закрытом и опломбированном',
        ],
        correctAnswers: ['В открытом'],
      },
      {
        code: '63685164',
        text:
          'Какое из утверждений по содержанию ремонта горных выработок указано неверно?',
        answers: [
          'Работы по перекреплению вертикальных горных выработок проводятся с закрепленного неподвижного или подвесного полка. На полке предусматривается подвесная лестница до полка лестничного отделения',
          'В кровле и боках незакрепленных горных выработок или выработок, закрепленных анкерной крепью, не допускается наличие отслоившихся кусков породы',
          'Допускается одновременное проведение ремонтных работ в горной выработке с углом наклона 18° и более в двух и более местах под непосредственным руководством работника структурного подразделения, за которым закреплены эти горные выработки',
          'Профилирование проводников и стенок главных вертикальных шахтных стволов проводится не реже одного раза в год, вспомогательных шахтных стволов - не реже одного раза в 2 года',
        ],
        correctAnswers: [
          'Допускается одновременное проведение ремонтных работ в горной выработке с углом наклона 18° и более в двух и более местах под непосредственным руководством работника структурного подразделения, за которым закреплены эти горные выработки',
        ],
      },
      {
        code: '63685165',
        text:
          'Что из перечисленного должны обеспечивать мероприятия по выполнению основных требований по безопасному ведению работ, связанных с пользованием недрами?',
        answers: [
          'Только защиту объектов ведения горных работ и месторождений полезных ископаемых от затопления, обводнения, взрывов, пожаров, горных ударов',
          'Только применение средств и технологий, повышающих безопасное ведение горных работ и уровень извлечения полезных ископаемых',
          'Только рекультивацию нарушенных горными работами земель (при разработке месторождений твердых полезных ископаемых)',
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
        correctAnswers: [
          'Все перечисленное, включая ликвидацию (консервацию) отработанных горных выработок, скважин, блоков, горизонтов, объектов обустройства и иных объектов, связанных с горным производством',
        ],
      },
      {
        code: '63685166',
        text:
          'Кем выдаются разрешения на выполнение отдельных технологических операций и применение ограничений эксплуатации бурового оборудования, а также указания о прекращении бурения и отсоединении бурового райзера по гидрометеорологическим условиям?',
        answers: [
          'Начальником ПБУ',
          'Вахтенным помощником капитана судна',
          'Заместителем начальника по технологии бурения',
          'Капитаном судна',
        ],
        correctAnswers: ['Начальником ПБУ'],
      },
      {
        code: '63685167',
        text:
          'Какой должна быть длина линий сбросов на факелы от блоков глушения и дросселирования для нефтяных скважин с газовым фактором менее 200 м³/т?',
        answers: [
          'Не менее 10 м',
          'Не менее 25 м',
          'Не менее 20 м',
          'Не менее 30 м',
        ],
        correctAnswers: ['Не менее 30 м'],
      },
      {
        code: '63685168',
        text:
          'Каким документом устанавливаются периодичность и способы проверки состояния обсадных колонн по мере их износа и необходимые мероприятия по обеспечению безопасной проводки и эксплуатации скважин?',
        answers: [
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
          'Регламентом, разработанным в соответствии с проектом',
          'Методическими указаниями проектной организации',
          'Правилами безопасности в нефтяной и газовой промышленности',
        ],
        correctAnswers: [
          'Рабочим проектом или иной документацией, содержащей аналогичные требования',
        ],
      },
      {
        code: '63685169',
        text:
          'Каким образом следует располагать здания и сооружения с производственными процессами, выделяющими в атмосферу вредные и (или) горючие вещества при обустройстве нефтяных, газовых и газоконденсатных месторождений?',
        answers: [
          'За пределами аварийной зоны населенных пунктов',
          'За пределами прилегающих народно-хозяйственных объектов',
          'За пределами санитарно-защитной зоны населенных пунктов, объединяя данные здания и сооружения со вспомогательными, складскими и санитарно-бытовыми помещениями',
          'На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления',
          'В пределах санитарной зоны',
        ],
        correctAnswers: [
          'На производственных площадках преимущественно с подветренной стороны от других зданий и сооружений с учетом розы ветров преобладающего направления',
        ],
      },
      {
        code: '63685170',
        text:
          'В каком месте зенитный угол ствола скважины должен быть не более 15° при освоении месторождений нефти на площадях залегания калийных солей?',
        answers: [
          'От глубины 100 м до глубины на 120 м ниже кровли подстилающей каменной соли',
          'От глубины на 120 м ниже кровли подстилающей каменной соли до башмака технической колонны',
          'От устья скважины до глубины 100 м',
          'От башмака технической колонный до забоя',
        ],
        correctAnswers: [
          'От глубины на 120 м ниже кровли подстилающей каменной соли до башмака технической колонны',
        ],
      },
      {
        code: '63685171',
        text:
          'Какие из приведенных помещений (пространств) относятся к зоне 1 (участок, на котором может присутствовать взрывоопасная смесь в нормальном рабочем режиме)?',
        answers: [
          'Закрытые помещения для хранения шлангов для перекачки легковоспламеняющихся жидкостей',
          'Открытые пространства вокруг закрытых и открытых технических устройств, оборудования',
          'Закрытые помещения насосных для сточных вод',
          'Пространство внутри открытых и закрытых технических устройств и емкостей, содержащих нефть, буровой раствор, обработанный нефтью, нефтяные газы или другие легковоспламеняющиеся вещества',
        ],
        correctAnswers: ['Закрытые помещения насосных для сточных вод'],
      },
      {
        code: '63685172',
        text:
          'Какие действия необходимо предпринять, если взрывчатые материалы, завезенные на ОПО МНГК, использованы неполностью?',
        answers: [
          'Вывезти',
          'Утилизировать',
          'Сортировать',
          'Складировать за пределами ОПО',
        ],
        correctAnswers: ['Вывезти'],
      },
      {
        code: '63685173',
        text:
          'Какой должен использоваться комплект вспомогательного инструмента при глушении подземной скважины в горной выработке, из которой она пробурена?',
        answers: [
          'Латунный или обмедненный',
          'Чугунный',
          'Бронзовый',
          'Стальной',
        ],
        correctAnswers: ['Латунный или обмедненный'],
      },
      {
        code: '63685174',
        text:
          'Какие меры из перечисленных входят в комплекс работ по освоению скважин?',
        answers: [
          'Предупреждение прорыва пластовой воды и газа из газовой "шапки" и термогидрогазодинамические исследования по определению количественной и качественной характеристик пласта и его геолого-физических параметров',
          'Исключение закупорки пласта при вторичном вскрытии и сохранение скелета пласта в призабойной зоне',
          'Сохранение, восстановление или повышение проницаемости призабойной зоны и предотвращение неконтролируемых газонефтеводопроявлений и открытых фонтанов',
          'В комплекс работ по освоению скважин входят все перечисленные меры',
        ],
        correctAnswers: [
          'В комплекс работ по освоению скважин входят все перечисленные меры',
        ],
      },
    ],
  },
  13: {
    64843: [
      {
        code: '64843000',
        text:
          'Какой инструктаж должен пройти электротехнический персонал перед началом работ по распоряжению? Выберите правильный вариант ответа.',
        answers: [
          'Первичный на рабочем месте',
          'Вводный инструктаж',
          'Целевой инструктаж',
          'Повторный инструктаж',
        ],
        correctAnswers: ['Целевой инструктаж'],
      },
      {
        code: '64843001',
        text:
          'В какой срок Ростехнадзор должен завершить расследование причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'В срок, не превышающий 10 календарных дней со дня начала расследования',
          'В срок, не превышающий 20 календарных дней со дня начала расследования',
          'В срок, не превышающий 20 календарных дней с момента получения информации об аварии',
          'В срок, не превышающий 10 календарных дней с момента получения информации об аварии',
          'В срок, не превышающий 20 календарных дней с момента аварии',
        ],
        correctAnswers: [
          'В срок, не превышающий 20 календарных дней со дня начала расследования',
        ],
      },
      {
        code: '64843002',
        text:
          'В какой срок комиссия по расследованию причин аварии уведомляет субъект электроэнергетики и (или) потребителя электрической энергии о начале обследования? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее чем за 12 часов',
          'Не позднее чем за 24 часа',
          'Не позднее чем за 3 часа',
          'Не позднее чем за 1 час',
        ],
        correctAnswers: ['Не позднее чем за 3 часа'],
      },
      {
        code: '64843003',
        text:
          'В течение какого времени со дня утверждения комиссией акта расследования материалы расследования причин аварии подлежат хранению Ростехнадзором? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 1 года',
          'Не менее 3 лет',
          'Не менее 5 лет',
          'Не менее 2 лет',
        ],
        correctAnswers: ['Не менее 3 лет'],
      },
      {
        code: '64843004',
        text:
          'Для чего предназначены стационарные сигнализаторы наличия напряжения? Выберите правильный вариант ответа.',
        answers: [
          'Для предупреждения персонала о наличии напряжения на токоведущих частях электроустановок',
          'Для определения отсутствия напряжения на токоведущих частях электроустановки',
          'Для всего перечисленного',
        ],
        correctAnswers: [
          'Для предупреждения персонала о наличии напряжения на токоведущих частях электроустановок',
        ],
      },
      {
        code: '64843005',
        text:
          'Какие работы на воздушной линии должны выполняться по технологическим картам или проекту производства работ? Выберите правильный вариант ответа.',
        answers: [
          'Работы по расчистке трассы воздушной линии от деревьев',
          'Все виды работ под наведенным напряжением, связанные с прикосновением к проводу (грозотросу)',
          'Работы с импульсным измерителем',
          'Работы с электроизмерительными клещами при нахождении на опоре воздушной линии',
        ],
        correctAnswers: [
          'Все виды работ под наведенным напряжением, связанные с прикосновением к проводу (грозотросу)',
        ],
      },
      {
        code: '64843006',
        text:
          'Какому административному штрафу могут быть подвергнуты юридические лица за повреждение электрических сетей напряжением свыше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'От десяти тысяч до двадцати тысяч рублей',
          'От тридцати тысяч до сорока тысяч рублей',
          'От сорока пяти тысяч до шестидесяти тысяч рублей',
          'От шестидесяти тысяч до восьмидесяти тысяч рублей',
        ],
        correctAnswers: ['От тридцати тысяч до сорока тысяч рублей'],
      },
      {
        code: '64843007',
        text:
          'Кем устанавливаются автономные резервные источники питания в случае, если в силу необходимой потребителю категории надежности электроснабжения и (или) для обеспечения установленной потребителю аварийной и (или) технологической брони требуется их наличие? Выберите правильный вариант ответа.',
        answers: [
          'Обслуживающей организацией',
          'Собственником оборудования',
          'Потребителем',
        ],
        correctAnswers: ['Потребителем'],
      },
      {
        code: '64843008',
        text:
          'Какие из перечисленных конструктивных элементов зданий и сооружений могут рассматриваться как естественные молниеприемники? Выберите правильный вариант ответа.',
        answers: [
          'Только металлические конструкции крыши (фермы, соединенная между собой стальная арматура)',
          'Только металлические элементы типа водосточных труб',
          'Только технологические металлические трубы и резервуары, выполненные из металла толщиной не менее 2,5 мм',
          'Любые элементы из перечисленных',
        ],
        correctAnswers: ['Любые элементы из перечисленных'],
      },
      {
        code: '64843009',
        text:
          'Каким образом разрешается выполнять проверку отключенного положения коммутационного аппарата в случае отсутствия видимого разрыва в комплектных распределительных устройствах заводского изготовления с выкатными элементами? Выберите правильный вариант ответа.',
        answers: [
          'По амперметру, установленному на ячейке',
          'По состоянию ламп сигнализации',
          'По механическому указателю гарантированного положения контактов',
          'Механической кнопкой отключения в приводе выключателя',
        ],
        correctAnswers: [
          'По механическому указателю гарантированного положения контактов',
        ],
      },
      {
        code: '64843010',
        text:
          'Какие из перечисленных работ не относятся к специальным, право проведения которых должно быть зафиксировано записью в удостоверении? Выберите правильный вариант ответа.',
        answers: [
          'Работы, выполняемые со снятием рабочего напряжения с электроустановки или ее части с прикосновением к токоведущим частям, находящимся под наведенным напряжением более 25 В на рабочем месте',
          'Работы по измерению сопротивления изоляции',
          'Работы на высоте',
          'Работы без снятия напряжения с электроустановки, выполняемые с прикосновением к первичным токоведущим частям, находящимся под рабочим напряжением',
        ],
        correctAnswers: ['Работы по измерению сопротивления изоляции'],
      },
      {
        code: '64843011',
        text:
          'Что подразумевается под характеристиками, отражающими отношение полезного эффекта от использования энергетических ресурсов к затратам энергетических ресурсов, произведенным в целях получения такого эффекта, применительно к продукции, технологическому процессу, юридическому лицу, индивидуальному предпринимателю? Выберите правильный вариант ответа.',
        answers: [
          'Класс энергетической эффективности',
          'Энергосбережение',
          'Вторичный энергетический ресурс',
          'Энергетический ресурс',
          'Энергетическая эффективность',
        ],
        correctAnswers: ['Энергетическая эффективность'],
      },
      {
        code: '64843012',
        text:
          'В какой срок работники организации электроэнергетики должны быть ознакомлены с графиком очередной проверки знаний? Выберите правильный вариант ответа.',
        answers: [
          'В течение месяца после утверждения графика проверки знаний, но не позднее чем за 14 календарных дней до даты проведения очередной проверки знаний',
          'В течение 60 дней после утверждения графика проверки знаний, но не позднее чем за 30 календарных дней до даты проведения очередной проверки знаний',
          'В течение 20 дней после утверждения графика проверки знаний, но не позднее чем за 7 календарный день до даты проведения очередной проверки знаний',
          'В течение 45 календарных дней после утверждения графика проверки знаний, но не позднее чем за 10 дней до даты проведения очередной проверки знаний',
        ],
        correctAnswers: [
          'В течение месяца после утверждения графика проверки знаний, но не позднее чем за 14 календарных дней до даты проведения очередной проверки знаний',
        ],
      },
      {
        code: '64843013',
        text:
          'Как оформляется акт расследования причин аварии при несогласии отдельных членов комиссии? Выберите правильный вариант ответа.',
        answers: [
          'Несогласные члены комиссии акт не подписывают',
          'Несогласные члены комиссии подписывают акт с примечанием "Не согласен"',
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
          'Несогласные члены комиссии акт не подписывают и направляют особое мнение в Управление государственного энергетического надзора России',
        ],
        correctAnswers: [
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
        ],
      },
      {
        code: '64843014',
        text:
          'На сколько календарных дней, в случае необходимости, руководитель Ростехнадзора может продлить срок проведения расследования причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Не более чем на 45 дней со дня окончания срока расследования',
          'Не более чем на 20 дней со дня окончания срока расследования',
          'Не более чем на 10 дней со дня окончания срока расследования',
          'Не более чем на 3 дня со дня окончания срока расследования',
        ],
        correctAnswers: [
          'Не более чем на 45 дней со дня окончания срока расследования',
        ],
      },
      {
        code: '64843015',
        text:
          'Какому административному штрафу могут быть подвергнуты юридические лица за ввод в эксплуатацию энергопотребляющих объектов без разрешения соответствующих органов? Выберите правильный вариант ответа.',
        answers: [
          'От десяти до двадцати тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
          'От двухсот до трехсот тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
          'От ста до двухсот тысяч рублей',
          'От тридцати до пятидесяти тысяч рублей или административное приостановление деятельности на срок до тридцати суток',
        ],
        correctAnswers: [
          'От десяти до двадцати тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
        ],
      },
      {
        code: '64843016',
        text:
          'Каким образом должны производиться неотложные работы в электроустановках напряжением до и выше 1000 В, для выполнения которых требуется более 1 часа или участия более трех человек? Выберите правильный вариант ответа.',
        answers: [
          'В порядке текущей эксплуатации',
          'По распоряжению оперативным персоналом',
          'По наряду-допуску',
          'Под наблюдением ремонтным персоналом',
        ],
        correctAnswers: ['По наряду-допуску'],
      },
      {
        code: '64843017',
        text:
          'Какие формы обязательного подтверждения соответствия установлены Федеральным законом "О техническом регулировании"? Выберите правильный вариант ответа.',
        answers: [
          'Экспертиза промышленной безопасности',
          'Только обязательная сертификация продукции',
          'Обязательная сертификация или декларирование соответствия продукции',
          'Оценка риска применения продукции',
        ],
        correctAnswers: [
          'Обязательная сертификация или декларирование соответствия продукции',
        ],
      },
      {
        code: '64843018',
        text:
          'По чьей команде вывешивается и снимается плакат "Не включать! Работа на линии!" на приводах разъединителей, которыми отключена для выполнения работ воздушная или кабельная линии? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
          'Выдающего наряд-допуск',
          'Ответственного руководителя работ',
          'Производителя работ',
        ],
        correctAnswers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
        ],
      },
      {
        code: '64843019',
        text:
          'С какой периодичностью производится осмотр наличия и состояния средств защиты, используемых в электроустановках (кроме переносных заземлений)? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в год',
          'Не реже одного раза в месяц',
          'Не реже одного раза в 9 месяцев',
        ],
        correctAnswers: ['Не реже одного раза в 6 месяцев'],
      },
      {
        code: '64843020',
        text:
          'Кто устанавливает порядок технологического присоединения энергопринимающих устройств юридических и физических лиц к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '64843021',
        text:
          'Что из перечисленного соответствует понятию "электроэнергетика"? Выберите правильный вариант ответа.',
        answers: [
          'Отрасль экономики Российской Федерации, включающая в себя комплекс экономических отношений, возникающих в процессе производства (в том числе производства в режиме комбинированной выработки электрической и тепловой энергии), передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике, сбыта и потребления электрической энергии с использованием производственных и иных имущественных объектов (в том числе входящих в Единую энергетическую систему России), принадлежащих на праве собственности или на ином предусмотренном федеральными законами основании субъектам электроэнергетики или иным лицам',
          'Электроэнергетическая система, расположенная в пределах территории Российской Федерации, в состав которой входят объединенные электроэнергетические системы и образующие их территориальные электроэнергетические системы и централизованное оперативно-диспетчерское управление которой осуществляется системным оператором электроэнергетических систем России',
          'Совокупность объектов электроэнергетики и энергопринимающих установок потребителей электрической энергии, связанных общим режимом работы в едином технологическом процессе производства, передачи и потребления электрической энергии в условиях централизованного оперативно-диспетчерского управления в электроэнергетике',
          'Линии электропередачи, трансформаторные и иные подстанции, распределительные пункты и иное предназначенное для обеспечения электрических связей и осуществления передачи электрической энергии оборудование',
        ],
        correctAnswers: [
          'Отрасль экономики Российской Федерации, включающая в себя комплекс экономических отношений, возникающих в процессе производства (в том числе производства в режиме комбинированной выработки электрической и тепловой энергии), передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике, сбыта и потребления электрической энергии с использованием производственных и иных имущественных объектов (в том числе входящих в Единую энергетическую систему России), принадлежащих на праве собственности или на ином предусмотренном федеральными законами основании субъектам электроэнергетики или иным лицам',
        ],
      },
      {
        code: '64843022',
        text:
          'Какой персонал должен обеспечивать выполнение технического обслуживания и ремонта объектов электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'Ремонтный персонал объектов электроэнергетики',
          'Персонал организации-исполнителя',
          'Персонал, привлекаемый Ростехнадзором',
          'Персонал проектной организации',
        ],
        correctAnswers: [
          'Ремонтный персонал объектов электроэнергетики',
          'Персонал организации-исполнителя',
        ],
      },
      {
        code: '64843023',
        text:
          'Каким образом эксплуатирующими организациями определяются линии (участки линий), находящиеся под наведенным напряжением? Выберите правильный вариант ответа.',
        answers: [
          'Путем выполнения измерений, с последующим перерасчетом значений на наибольший рабочий ток влияющей воздушной линии',
          'Расчетным путем',
          'Путем выполнения измерений при наибольшем значении тока влияющей линии',
        ],
        correctAnswers: [
          'Путем выполнения измерений, с последующим перерасчетом значений на наибольший рабочий ток влияющей воздушной линии',
        ],
      },
      {
        code: '64843024',
        text:
          'В какой срок после дня получения запроса уполномоченного органа в сфере электроэнергетики собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация направляют копии акта расследования уполномоченному органу в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'В 10-дневный срок',
          'В 5-дневный срок',
          'В 20-дневный срок',
          'В 30-дневный срок',
        ],
        correctAnswers: ['В 10-дневный срок'],
      },
      {
        code: '64843025',
        text:
          'У кого должен находиться комплект оперативных схем электроустановок определенного структурного подразделения потребителя? Выберите правильный вариант ответа.',
        answers: [
          'На рабочем месте ответственного за электрохозяйство',
          'На рабочем месте оперативного персонала',
          'На рабочем месте технического руководителя организации',
          'У всех перечисленных должно быть по комплекту схем',
        ],
        correctAnswers: ['На рабочем месте оперативного персонала'],
      },
      {
        code: '64843026',
        text:
          'При каком условии работникам, не имеющим профильного образования, допускается присваивать II группу по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'При наличии стажа работы в электроустановках не менее 6 месяцев',
          'При условии прохождения обучения в образовательных организациях в объеме не менее 72 часов',
          'При наличии стажа работы в электроустановках не менее 3 месяцев',
          'При наличии заключения руководителя о прохождении производственной практики не менее 6 месяцев',
        ],
        correctAnswers: [
          'При условии прохождения обучения в образовательных организациях в объеме не менее 72 часов',
        ],
      },
      {
        code: '64843027',
        text:
          'На какие категории подразделяется электротехнический персонал организации? Выберите правильный вариант ответа.',
        answers: [
          'На оперативный, административный и ремонтный',
          'На административно-технический и оперативно-ремонтный',
          'На административно-технический, оперативный, оперативно-ремонтный и ремонтный',
          'На оперативный, оперативно-ремонтный и ремонтный',
        ],
        correctAnswers: [
          'На административно-технический, оперативный, оперативно-ремонтный и ремонтный',
        ],
      },
      {
        code: '64843028',
        text:
          'В каком случае внеочередная проверка знаний не проводится? Выберите правильный вариант ответа.',
        answers: [
          'При переводе сотрудника на новую должность в другой филиал (представительство), структурное подразделение в пределах одной организации, если должностные обязанности (трудовые функции) работника по новой должности (рабочему месту) не требуют дополнительных знаний отраслевых актов и инструктивно-технических документов',
          'На основании предписания, выданного уполномоченным федеральным органом исполнительной власти по результатам осуществления в отношении организации федерального государственного энергетического надзора',
          'При вводе в эксплуатацию нового оборудования и изменениях технологических процессов, требующих дополнительных знаний работников',
          'При перерыве в работе по данной должности (рабочему месту) более 6 месяцев',
        ],
        correctAnswers: [
          'При переводе сотрудника на новую должность в другой филиал (представительство), структурное подразделение в пределах одной организации, если должностные обязанности (трудовые функции) работника по новой должности (рабочему месту) не требуют дополнительных знаний отраслевых актов и инструктивно-технических документов',
        ],
      },
      {
        code: '64843029',
        text:
          'В какой последовательности необходимо выполнять технические мероприятия, обеспечивающие безопасность работ со снятием напряжения? Выберите правильный вариант ответа.',
        answers: [
          'Произвести необходимые отключения, вывесить запрещающие плакаты, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить указательные, предупреждающие и предписывающие плакаты',
          'Произвести необходимые отключения, вывесить запрещающие, указательные и предписывающие плакаты, установить заземление, проверить отсутствие напряжения на токоведущих частях',
          'Вывесить указательные, предупреждающие и предписывающие плакаты, произвести необходимые отключения, проверить отсутствие напряжения на токоведущих частях, установить заземление',
          'Произвести необходимые отключения, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить запрещающие, указательные и предписывающие плакаты',
        ],
        correctAnswers: [
          'Произвести необходимые отключения, вывесить запрещающие плакаты, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить указательные, предупреждающие и предписывающие плакаты',
        ],
      },
      {
        code: '64843030',
        text:
          'В течение какого времени должен обеспечиваться непосредственный контакт указателя напряжения с контролируемыми токоведущими частями при проверке отсутствия напряжения в электроустановках напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 7 с',
          'Не менее 1 с',
          'Не менее 5 с',
          'Не менее 3 с',
        ],
        correctAnswers: ['Не менее 5 с'],
      },
      {
        code: '64843031',
        text:
          'Кто имеет право устанавливать переносные заземления в электроустановках напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Два работника с группой III из числа оперативного персонала',
          'Два работника, один с группой IV по электробезопасности, другой с группой III по электробезопасности',
          'Один работник с группой IV по электробезопасности из числа оперативного персонала',
          'Два работника, один с группой IV по электробезопасности из числа оперативного персонала, другой с группой III по электробезопасности',
        ],
        correctAnswers: [
          'Два работника, один с группой IV по электробезопасности из числа оперативного персонала, другой с группой III по электробезопасности',
        ],
      },
      {
        code: '64843032',
        text:
          'Кто имеет право включать электроустановки после полного окончания работ? Выберите правильный вариант ответа.',
        answers: [
          'Производитель работ',
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
          'Любой из членов бригады',
          'Только ответственный за электрохозяйство',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
        ],
      },
      {
        code: '64843033',
        text:
          'Какая процедура не устанавливается правилами технологического присоединения? Выберите правильный вариант ответа.',
        answers: [
          'Процедура присоединения энергопринимающих устройств к электрическим сетям сетевой организации',
          'Нормирование количества потребляемой электроэнергии',
          'Установка требований к выдаче технических условий, в том числе индивидуальных, для присоединения к электрическим сетям',
          'Определение существенных условий договора об осуществлении технологического присоединения к электрическим сетям',
        ],
        correctAnswers: ['Нормирование количества потребляемой электроэнергии'],
      },
      {
        code: '64843034',
        text:
          'На какие виды не подразделяется инструктаж по охране труда? Выберите правильный вариант ответа.',
        answers: [
          'Вводный инструктаж по охране труда',
          'Целевой инструктаж по охране труда',
          'Оперативный инструктаж по охране труда',
          'Инструктаж по охране труда на рабочем месте',
        ],
        correctAnswers: ['Оперативный инструктаж по охране труда'],
      },
      {
        code: '64843035',
        text:
          'С какой периодичностью собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация представляют сводный отчет об авариях в электроэнергетике в орган федерального государственного энергетического надзора, уполномоченный орган в сфере электроэнергетики, а также субъекту оперативно-диспетчерского управления в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Ежемесячно',
          '1 раз в квартал',
          'Ежегодно',
          '1 раз в 6 месяцев',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64843036',
        text:
          'Каким образом устанавливается продолжительность дублирования конкретного работника? Выберите правильный вариант ответа.',
        answers: [
          'Определяется руководителем структурного подразделения',
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
          'Органами Ростехнадзора',
          'Определяется комиссией по проверке знаний в зависимости от его уровня профессиональной подготовки, стажа и опыта оперативной работы',
        ],
        correctAnswers: [
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
        ],
      },
      {
        code: '64843037',
        text:
          'Какое определение соответствует термину "дублирование"? Выберите правильный вариант работы. Выберите правильный вариант ответа.',
        answers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
          'Практическое освоение непосредственно на рабочем месте навыков выполнения работы или группы работ, приобретенных при профессиональной подготовке',
          'Одна из форм дополнительного повышения образовательного уровня оперативного персонала, осуществляемая путем систематического самообразования',
          'Форма поддержания квалификации работника путем его систематической тренировки в управлении производственными процессами на учебно-тренировочных средствах, формирования его знаний, умения и навыков, проработки организационно-распорядительных документов и разборки технологических нарушений, пожаров и случаев производственного травматизма',
        ],
        correctAnswers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
        ],
      },
      {
        code: '64843038',
        text:
          'Какие требования предъявляются к командированному персоналу? Выберите правильный вариант ответа.',
        answers: [
          'Должен быть ознакомлен с электрической схемой и особенностями электроустановки, в которой предстоит работать',
          'По прибытии на место командировки должен пройти вводный и первичный инструктажи по охране труда',
          'Должен иметь удостоверения о проверке знаний норм и правил работы в электроустановках с отметкой о группе электробезопасности, по прибытии на место командировки должен пройти вводный и первичный инструктажи по охране труда',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '64843039',
        text:
          'Какие из перечисленных полномочий не могут быть возложены на ответственного за электрохозяйство? Выберите правильный вариант ответа.',
        answers: [
          'Обеспечение контроля соблюдения и поддержания режима работы электроустановок и режима потребления электрической энергии, в том числе режимов потребления реактивной мощности, заданных потребителю',
          'Непосредственное обслуживание электроустановок',
          'Контроль наличия средств защиты в электроустановках и инструмента, необходимых для эксплуатации электроустановок, проведение проверок и испытаний таких средств защиты и инструмента',
          'Обеспечение выполнения ремонта и технического обслуживания электроустановок',
        ],
        correctAnswers: ['Непосредственное обслуживание электроустановок'],
      },
      {
        code: '64843040',
        text:
          'В какие сроки должна производиться проверка исправности аварийного освещения при отключении рабочего освещения на электростанциях, подстанциях и диспетчерских пунктах? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в месяц',
          'При вводе в эксплуатацию и в дальнейшем один раз в год',
          'Два раза в год',
          'Один раз в год',
        ],
        correctAnswers: ['Два раза в год'],
      },
      {
        code: '64843041',
        text:
          'Каким образом необходимо присоединять переносное заземление при выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения, установить переносное заземление на токоведущие части',
          'Установить переносное заземление на токоведущие части, присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения',
          'Проверить отсутствие напряжения, установить переносное заземление на токоведущие части, присоединить переносное заземление к заземляющему устройству',
          'Присоединить переносное заземление к заземляющему устройству, установить переносное заземление на токоведущие части, проверить отсутствие напряжения',
        ],
        correctAnswers: [
          'Присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения, установить переносное заземление на токоведущие части',
        ],
      },
      {
        code: '64843042',
        text:
          'Какой срок хранения установлен для журналов учета работ по нарядам-допускам и распоряжениям? Выберите правильный вариант ответа.',
        answers: [
          'Один месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Два месяца со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Полгода со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Один год со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
        correctAnswers: [
          'Один месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
      },
      {
        code: '64843043',
        text:
          'Кто проводит первичный инструктаж командированному персоналу при проведении работ в электроустановках до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала (руководящих работников и специалистов), имеющий группу IV',
          'Работник организации - владельца электроустановок из числа электротехнического персонала, имеющий группу IV',
          'Работник организации - владельца электроустановок из числа оперативно-ремонтного персонала, имеющий группу IV',
          'Работник командирующей организации из числа административно-технического персонала, имеющий группу IV',
        ],
        correctAnswers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала (руководящих работников и специалистов), имеющий группу IV',
        ],
      },
      {
        code: '64843044',
        text:
          'В каких электроустановках диэлектрические перчатки применяются в качестве основного изолирующего электрозащитного средства? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках напряжением свыше 1000 В',
          'В электроустановках напряжением до 1000 В',
          'Во всех электроустановках они используются в качестве основного изолирующего средства',
          'Во всех электроустановках они используются в качестве дополнительного изолирующего средства',
        ],
        correctAnswers: ['В электроустановках напряжением до 1000 В'],
      },
      {
        code: '64843045',
        text:
          'В каких случаях допускается изменение распределения обязанностей между лицами, выполняющими переключения в электроустановках, и контролирующим лицом? Выберите правильный вариант ответа.',
        answers: [
          'По решению технического руководителя',
          'Не допускается ни в каких случаях',
          'При наличии двух контролирующих лиц',
          'В случаях предотвращения развития и ликвидации нарушений нормального режима',
        ],
        correctAnswers: ['Не допускается ни в каких случаях'],
      },
      {
        code: '64843046',
        text:
          'Под наблюдением каких работников должен осуществляться проезд автомобилей, подъемных сооружений и механизмов по территории открытого распределительного устройства и в охранной зоне воздушной линии выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Члена бригады с группой III',
          'Производителя работ',
          'Одного из работников из числа оперативного персонала, работника, выдавшего наряд-допуск или ответственного руководителя',
          'Наблюдающего с группой III',
        ],
        correctAnswers: [
          'Одного из работников из числа оперативного персонала, работника, выдавшего наряд-допуск или ответственного руководителя',
        ],
      },
      {
        code: '64843047',
        text:
          'Кому разрешается работать единолично в электроустановках напряжением до 1000 В, расположенных в помещениях, кроме особо опасных? Выберите правильный вариант ответа.',
        answers: [
          'Работнику имеющему III группу по электробезопасности и право быть производителем работ',
          'Работнику имеющему IV группу по электробезопасности',
          'Работнику имеющему III группу по электробезопасности',
          'Работать единолично не разрешается',
        ],
        correctAnswers: [
          'Работнику имеющему III группу по электробезопасности и право быть производителем работ',
        ],
      },
      {
        code: '64843048',
        text:
          'Каким образом юридическим лицом представляются в орган федерального государственного энергетического надзора заявление о выдаче разрешения на допуск в эксплуатацию энергоустановки и прилагаемые к нему документы? Выберите правильный вариант ответа.',
        answers: [
          'На бумажном носителе непосредственно',
          'Посредством заказного почтового отправления с уведомлением о вручении',
          'В виде электронного документа',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '64843049',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работники из числа ремонтного персонала, имеющие группу не ниже III',
          'Работники из числа административно-технического персонала, имеющие группу IV',
          'Работники, имеющий группу III и право единоличного осмотра на основании письменного распоряжения руководителя организации',
          'Работники из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работники из числа административно-технического персонала (руководящие работники и специалисты), на которых возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу IV и право единоличного осмотра на основании организационно-распорядительных документов организации',
        ],
        correctAnswers: [
          'Работники из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работники из числа административно-технического персонала (руководящие работники и специалисты), на которых возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу IV и право единоличного осмотра на основании организационно-распорядительных документов организации',
        ],
      },
      {
        code: '64843050',
        text:
          'Кто имеет право производить переключения в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерский персонал диспетчерского центра',
          'Персонал, имеющий право самостоятельно выполнять работы по техническому обслуживанию соответствующих устройств релейной защиты и автоматики (РЗА),  - в части выполнения операций с устройствами РЗА, не имеющими переключающих устройств оперативного вывода и ввода',
          'Весь перечисленный персонал',
          'Оперативный (оперативно-ремонтный) персонал объектов электроэнергетики',
        ],
        correctAnswers: ['Весь перечисленный персонал'],
      },
      {
        code: '64843051',
        text:
          'Какое минимальное количество членов комиссии организации по проверке знаний должно присутствовать при проведении процедуры проверки знаний работников для присвоения группы по электробезопасности? Выберите правильный вариант ответа',
        answers: [
          'Не менее двух членов комиссии организации по проверке знаний',
          'Не менее трех членов комиссии организации по проверке знаний, имеющих группу по электробезопасности',
          'Не менее пяти членов комиссии организации по проверке знаний',
          'Не менее четырех членов комиссии организации по проверке знаний',
        ],
        correctAnswers: [
          'Не менее трех членов комиссии организации по проверке знаний, имеющих группу по электробезопасности',
        ],
      },
      {
        code: '64843052',
        text:
          'Как должны храниться средства защиты органов дыхания? Выберите правильный вариант ответа.',
        answers: [
          'Не регламентируется',
          'В шкафах, на стеллажах, в сухом помещении и в открытом виде',
          'В сухом помещении в специальных ящиках',
          'В сухом помещении в специальных сумках',
        ],
        correctAnswers: ['В сухом помещении в специальных сумках'],
      },
      {
        code: '64843053',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа административно-технического персонала, имеющий группу V по электробезопасности',
          'Работник из числа ремонтного персонала, имеющий группу по электробезопасности не ниже IV',
          'Работник из числа административно-технического персонала, имеющий группу по электробезопасности IV',
          'Работник имеющий группу IV по электробезопасности, и право единоличного осмотра на основании письменного распоряжения руководителя организации',
        ],
        correctAnswers: [
          'Работник из числа административно-технического персонала, имеющий группу V по электробезопасности',
        ],
      },
      {
        code: '64843054',
        text:
          'При каких погодных условиях можно пользоваться изолирующими электрозащитными средствами в открытых электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'В любую погоду',
          'Только в сухую погоду',
          'При температуре не ниже -10 °С',
          'При температуре не ниже 0 °С и скорости ветра не выше 15 м/с',
        ],
        correctAnswers: ['Только в сухую погоду'],
      },
      {
        code: '64843055',
        text:
          'Какие мероприятия обязательно осуществляются перед допуском к проведению неотложных работ? Выберите правильный вариант ответа.',
        answers: [
          'Проверка количественного и качественного состава бригады',
          'Проведение целевого инструктажа',
          'Оформление наряда-допуска',
          'Технические мероприятия по подготовке рабочего места',
        ],
        correctAnswers: [
          'Технические мероприятия по подготовке рабочего места',
        ],
      },
      {
        code: '64843056',
        text:
          'Кто предоставляет командированному персоналу привлекаемой организации права работы в действующих электроустановках в качестве выдающих наряды-допуски и распоряжения, ответственных руководителей, производителей работ, членов бригады? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель организации',
          'Руководитель командирующей организации',
          'Ответственный за электрохозяйство командирующей организации',
          'Технический руководитель командирующей организации',
        ],
        correctAnswers: ['Руководитель организации'],
      },
      {
        code: '64843057',
        text:
          'Кто вправе рассматривать жалобы поставщиков и покупателей электрической и тепловой энергии о нарушениях их прав и законных интересов действиями (бездействием) иных субъектов электроэнергетики, а также запрашивать информацию, документы и иные доказательства, свидетельствующие о наличии признаков таких нарушений? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Федеральные органы исполнительной власти'],
      },
      {
        code: '64843058',
        text:
          'В каких случаях проводится первичная проверка знаний работников? Выберите 2 варианта ответа.',
        answers: [
          'При поступлении на работу, связанную с обслуживанием электроустановок',
          'При перерыве в проверке знаний более 3 лет',
          'При установке нового оборудования, реконструкции или изменении главных электрических и технологических схем',
          'При перерыве в работе в данной должности более 6 месяцев',
          'При перерыве в проверке знаний более 1 года',
        ],
        correctAnswers: [
          'При поступлении на работу, связанную с обслуживанием электроустановок',
          'При перерыве в проверке знаний более 3 лет',
        ],
      },
      {
        code: '64843059',
        text:
          'Для чего предназначены защитные каски? Выберите правильный вариант ответа.',
        answers: [
          'Только для защиты головы работающего от механических повреждений',
          'Только для защиты головы работающего от поражения электрическим током при случайном касании токоведущих частей, находящихся под напряжением до 1000 В',
          'Только для защиты головы работающего от воды и агрессивных жидкостей',
          'Для защиты от всего перечисленного',
        ],
        correctAnswers: ['Для защиты от всего перечисленного'],
      },
      {
        code: '64843060',
        text:
          'Кто из перечисленных лиц может подписывать перечень работ, разрешенных к выполнению под напряжением на токоведущих частях в электроустановках напряжением до и выше 1000 В? Выберите 2 варианта ответа.',
        answers: [
          'Специалист по охране труда, имеющий группу IV по электробезопасности и выше, назначенный распоряжением руководителя организации',
          'Ответственный за электрохозяйство',
          'Работники из числа электротехнического персонала, имеющие группу не ниже III по электробезопасности, привлеченные к выполнению данных работ',
          'Технический руководитель',
        ],
        correctAnswers: [
          'Технический руководитель',
          'Ответственный за электрохозяйство',
        ],
      },
      {
        code: '64843061',
        text:
          'При каких условиях не допускается производить в ОРУ переключения в электроустановках, не связанные с предотвращением развития и ликвидацией нарушения нормального режима? Выберите правильный вариант ответа.',
        answers: [
          'При грозе и (или) скорости ветра выше 20 м/с, и (или) температуре наружного воздуха ниже 30 °С',
          'Только при грозе',
          'При грозе и (или) скорости ветра выше 20 м/с',
          'Только при скорости ветра выше 20 м/с',
        ],
        correctAnswers: ['При грозе и (или) скорости ветра выше 20 м/с'],
      },
      {
        code: '64843062',
        text:
          'В какие сроки после назначения на должность проводится первичная проверка знаний работников, относящихся к категории административно-технического персонала или вспомогательного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее одного месяца после назначения на должность',
          'Не позднее 14 дней после назначения на должность',
          'Срок определяется ответственным за работу с персоналом организации',
          'Не позднее 10 дней после назначения на должность',
        ],
        correctAnswers: [
          'Не позднее одного месяца после назначения на должность',
        ],
      },
      {
        code: '64843063',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для диспетчерского, оперативного и оперативно-ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от характера выполняемой работы, но не реже 1 раза в 3 месяца',
          'Ежемесячно',
          'Не реже 1 раза в 6 месяцев',
          'В зависимости от квалификации работника, но не реже 1 раза в 3 месяца',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64843064',
        text:
          'На кого возложена обязанность по составлению годовых планов (графиков) по ремонту основного оборудования электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'На технического руководителя организации',
          'На уполномоченное должностное лицо потребителя (его филиала)',
          'На оперативный персонал Потребителя',
          'На административно-технический персонал Потребителя',
        ],
        correctAnswers: [
          'На уполномоченное должностное лицо потребителя (его филиала)',
        ],
      },
      {
        code: '64843065',
        text:
          'Кем осуществляется подготовка рабочего места для выполнения строительно-монтажных работ? Выберите правильный вариант ответа.',
        answers: [
          'Работниками организации-владельца электроустановки',
          'Работниками строительно-монтажной организации',
          'Работниками строительно-монтажной организации и организации-владельца электроустановок',
        ],
        correctAnswers: ['Работниками организации-владельца электроустановки'],
      },
      {
        code: '64843066',
        text:
          'Что должно обязательно указываться в наряде-допуске рядом с фамилией и инициалами работников? Выберите правильный вариант ответа.',
        answers: [
          'Дата рождения',
          'Место работы',
          'Группа по электробезопасности',
          'Возраст',
        ],
        correctAnswers: ['Группа по электробезопасности'],
      },
      {
        code: '64843067',
        text:
          'Какие формы работы с административно-техническим персоналом не проводятся? Выберите правильный вариант ответа.',
        answers: [
          'Дублирование и специальная подготовка',
          'Производственный инструктаж',
          'Предэкзаменационная подготовка',
          'Проверка знаний',
          'Инструктаж по охране труда',
        ],
        correctAnswers: ['Дублирование и специальная подготовка'],
      },
      {
        code: '64843068',
        text:
          'С какой периодичностью должен пересматриваться перечень технической документации структурного подразделения, утверждаемый руководителем или иным уполномоченным лицом потребителя? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
          'Не реже одного раза в пол года',
          'Не реже одного раза в год',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '64843069',
        text:
          'Какую группу по электробезопасности должны иметь специалисты по охране труда субъектов электроэнергетики, контролирующие электроустановки? Выберите правильный вариант ответа.',
        answers: ['Не ниже  IV', 'Не ниже II', 'Не ниже III', 'Не ниже V'],
        correctAnswers: ['Не ниже V'],
      },
      {
        code: '64843070',
        text:
          'Какая периодичность проверки знаний по электробезопасности установлена для электротехнического персонала, непосредственно организующего и проводящего работы по обслуживанию действующих электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в год'],
      },
      {
        code: '64843071',
        text:
          'Какое административное наказание может быть наложено на юридических лиц за нарушение правил пользования электрической и тепловой энергией? Выберите правильный вариант ответа.',
        answers: [
          'Административное приостановление деятельности на срок до ста суток',
          'Наложение административного штрафа от десяти тысяч до двадцати тысяч рублей или административное приостановление деятельности на срок до ста двадцати суток',
          'Наложение административного штрафа от двадцати тысяч до сорока тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
          'Наложение административного штрафа в размере от пяти до десяти тысяч рублей',
        ],
        correctAnswers: [
          'Наложение административного штрафа от двадцати тысяч до сорока тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
        ],
      },
      {
        code: '64843072',
        text:
          'Кто имеет право выполнять присоединение и отсоединение от сети электросварочных установок с помощью разборных контактных соединений, а также наблюдение за их исправным состоянием в процессе эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Электротехнический персонал потребителя, на балансе которого находится электросварочная установка, с группой по электробезопасности не ниже III',
          'Электротехнологический персонал потребителя, на балансе которого находится электросварочная установка, с группой по электробезопасности не ниже IV',
          'Электротехнический персонал потребителя, на балансе которого находится электросварочная установка, с группой по электробезопасности не ниже IV',
          'Электротехнологический персонал потребителя, на балансе которого находится электросварочная установка, с группой по электробезопасности не ниже II',
        ],
        correctAnswers: [
          'Электротехнический персонал потребителя, на балансе которого находится электросварочная установка, с группой по электробезопасности не ниже III',
        ],
      },
      {
        code: '64843073',
        text:
          'Кем утверждается перечень работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Руководителем организации или руководителем обособленного подразделения',
          'Ответственным за электрохозяйство организации',
          'Техническим руководителем организации',
          'Технической комиссией организации',
        ],
        correctAnswers: [
          'Руководителем организации или руководителем обособленного подразделения',
        ],
      },
      {
        code: '64843074',
        text:
          'Какое определение соответствует понятию "временная нормальная схема электрических соединений объекта электроэнергетики"? Выберите правильный вариант ответа.',
        answers: [
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и заземляющие разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап жизненного цикла строящегося (реконструируемого) объекта электроэнергетики',
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и заземляющие разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап капитального ремонта или реконструкции объекта электроэнергетики',
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и линейные (шинные) разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап жизненного цикла строящегося (реконструируемого) объекта электроэнергетики',
        ],
        correctAnswers: [
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и заземляющие разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап жизненного цикла строящегося (реконструируемого) объекта электроэнергетики',
        ],
      },
      {
        code: '64843075',
        text:
          'Какие организации электроэнергетики должны разработать порядок проведения работы с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Все организации электроэнергетики',
          'Только организации, эксплуатирующие трансформаторные подстанции',
          'Только организации, эксплуатирующие тепловые электростанции',
          'Только организации, эксплуатирующие гидроэлектростанции',
        ],
        correctAnswers: ['Все организации электроэнергетики'],
      },
      {
        code: '64843076',
        text:
          'Кем устанавливается порядок технологического присоединения энергопринимающих устройств и объектов электроэнергетики юридических лиц и физических лиц к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Правительством Российской Федерации',
          'Правительством Российской Федерации или уполномоченными им федеральными органами исполнительной власти',
          'Федеральными органами исполнительной власти',
          'Органами исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Правительством Российской Федерации'],
      },
      {
        code: '64843077',
        text:
          'Где должны фиксироваться выявленные дефекты ЛЭП, оборудования, устройств электроустановок по результатам контроля, основанном на показаниях контрольно-измерительной аппаратуры, результатах осмотров, испытаний, измерений и расчетов? Выберите правильный вариант ответа.',
        answers: [
          'В журнале дефектов',
          'В журнале учета электрооборудования',
          'В журнале распоряжений',
          'В оперативном журнале',
        ],
        correctAnswers: ['В журнале дефектов'],
      },
      {
        code: '64843078',
        text:
          'Какая техническая документация должна быть у каждого потребителя в отношении эксплуатируемых им электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'Акты комплексного опробования ЛЭП и оборудования и документы о приемке их в эксплуатацию',
          'Схемы электрических соединений и технологических систем, в том числе нормальные (временные нормальные) схемы электрических соединений электроустановок потребителя',
          'Вся перечисленная документация обязательно должна быть у каждого потребителя',
          'Должностные инструкции персонала',
          'Журналы учета электрооборудования с перечислением основного электрооборудования и с указанием его технических данных, а также присвоенных им инвентарных номеров',
        ],
        correctAnswers: [
          'Вся перечисленная документация обязательно должна быть у каждого потребителя',
        ],
      },
      {
        code: '64843079',
        text:
          'Каким образом должно осуществляться повышение квалификации персонала организации электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'Посредством реализации программ повышения квалификации',
          'Посредством реализации программ профессиональной переподготовки',
          'Посредством реализации программ самообразования',
          'Посредством получения опыта на производстве',
        ],
        correctAnswers: [
          'Посредством реализации программ повышения квалификации',
          'Посредством реализации программ профессиональной переподготовки',
        ],
      },
      {
        code: '64843080',
        text:
          'Когда после назначения на новую должность должна проводиться проверка знаний работников, относящихся к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'В сроки, установленные программой подготовки по новой должности',
          'Не позднее 14 дней после назначения на должность',
          'Не позднее одного месяца после назначения на должность',
          'Не позднее 10 дней после назначения на должность',
        ],
        correctAnswers: [
          'В сроки, установленные программой подготовки по новой должности',
        ],
      },
      {
        code: '64843081',
        text:
          'К каким распределительным электрическим сетям могут присоединяться источники сварочного тока? Выберите правильный вариант ответа.',
        answers: [
          'К сетям напряжением не выше 220 В',
          'К сетям напряжением не выше 660 В',
          'К сетям напряжением не выше 1000 В',
          'К сетям напряжением не выше 380 В',
        ],
        correctAnswers: ['К сетям напряжением не выше 660 В'],
      },
      {
        code: '64843082',
        text:
          'Каким должен быть состав постоянно действующей комиссии по проверке знаний организации? Выберите правильный вариант ответа.',
        answers: [
          'Не менее пяти человек, включая председателя и заместителя (ей) председателя комиссии',
          'Не менее семи человек, включая председателя и заместителя (ей) председателя комиссии',
          'Не менее трех человек, включая председателя и заместителя (ей) председателя комиссии',
          'Не менее трех человек, назначенных приказом по организации',
        ],
        correctAnswers: [
          'Не менее пяти человек, включая председателя и заместителя (ей) председателя комиссии',
        ],
      },
      {
        code: '64843083',
        text:
          'Какие права предоставляются командированному персоналу привлекаемой организации? Выберите правильный вариант ответа.',
        answers: [
          'Право работы в действующих электроустановках в качестве выдающих наряды-допуски и распоряжения, ответственных руководителей и производителей работ, членов бригады',
          'Право работы в действующих электроустановках только в качестве выдающих наряд-допуск, ответственных руководителей и производителей работ',
          'Право работы в действующих электроустановках только в качестве членов бригады',
          'Право работы в действующих электроустановках только в качестве допускающих на воздушной линии',
        ],
        correctAnswers: [
          'Право работы в действующих электроустановках в качестве выдающих наряды-допуски и распоряжения, ответственных руководителей и производителей работ, членов бригады',
        ],
      },
      {
        code: '64843084',
        text:
          'Как должны проводиться работы в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Работы в действующих электроустановках должны проводиться на основании перечня работ, выполняемых в порядке текущей эксплуатации',
          'Работы в действующих электроустановках должны проводиться по любому из перечисленных документов',
          'Работы в действующих электроустановках должны проводиться по распоряжению',
          'Работы в действующих электроустановках должны проводиться по наряду-допуску',
        ],
        correctAnswers: [
          'Работы в действующих электроустановках должны проводиться по любому из перечисленных документов',
        ],
      },
      {
        code: '64843085',
        text:
          'Какие плакаты при выполнении работ на электроустановках должны быть вывешены на приводах (рукоятках приводов) коммутационных аппаратов с ручным управлением (выключателей, отделителей, разъединителей, рубильников, автоматов) во избежание подачи напряжения на рабочее место? Выберите правильный вариант ответа.',
        answers: [
          '"Не включать! Работают люди"',
          '"Работают люди"',
          '"Не открывать!"',
          '“Опасно!”',
        ],
        correctAnswers: ['"Не включать! Работают люди"'],
      },
      {
        code: '64843086',
        text:
          'В отношении каких объектов и установок на время проведения испытаний и пусконаладочных работ не выдается временное разрешение на допуск в эксплуатацию? Выберите правильный вариант ответа.',
        answers: [
          'Для энергопринимающих установок, предназначенных для производства испытаний электрооборудования повышенным напряжением',
          'Для объектов по производству электрической энергии (в том числе по производству в режиме комбинированной выработки электрической и тепловой энергии)',
          'Для вновь вводимых в эксплуатацию или реконструированных объектов по производству электрической энергии  (в том числе по производству в режиме комбинированной выработки электрической и тепловой энергии)',
          'Для объектов теплоснабжения (источников тепловой энергии, тепловых сетей)',
        ],
        correctAnswers: [
          'Для энергопринимающих установок, предназначенных для производства испытаний электрооборудования повышенным напряжением',
        ],
      },
      {
        code: '64843087',
        text:
          'С какой периодичностью должны пересматриваться производственные инструкции по эксплуатации электроустановок, организации и осуществлению оперативно-технологического управления? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в 3 года',
          'Не реже одного раза в год',
          'Не реже одного раза в 6 месяцев',
          'Не реже одного раза в 5 лет',
        ],
        correctAnswers: ['Не реже одного раза в 3 года'],
      },
      {
        code: '64843088',
        text:
          'С какой периодичностью и в какой период разрабатываются графики аварийного ограничения режима потребления электрической энергии (мощности)? Выберите правильный вариант ответа.',
        answers: [
          'Ежегодно с 1 октября текущего года по 30 сентября следующего года',
          'Раз в 2 года с 1 октября текущего года по 30 сентября последнего года периода',
          'Ежегодно с 1 мая текущего года по 30 апреля следующего года',
          'Раз в 3 года с 1 мая текущего года по 30 апреля последнего года периода',
        ],
        correctAnswers: [
          'Ежегодно с 1 октября текущего года по 30 сентября следующего года',
        ],
      },
      {
        code: '64843089',
        text:
          'Какую группу по электробезопасности при проведении неотложных работ должен иметь производитель работ или наблюдающий из числа оперативного персонала, соответственно выполняющий работу или осуществляющий надзор за работающими в электроустановках напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Группу III по электробезопасности',
          'Группу IV по электробезопасности',
          'Группу V по электробезопасности',
          'Группу II по электробезопасности',
        ],
        correctAnswers: ['Группу IV по электробезопасности'],
      },
      {
        code: '64843090',
        text:
          'На проведение работ в каких электроустановках не распространяются требования по назначению лица, ответственного за выдачу разрешения на подготовку рабочих мест и на допуск, и по выдаче такого разрешения? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках потребителей электрической энергии',
          'В электроустановках генерирующих организаций',
          'На воздушной линии, кабельной линии, кабельно-воздушной линии потребителя, требующих координации со стороны персонала другой организации при изменении их эксплуатационного состояния',
          'В электроустановках, относящихся к объектам электросетевого хозяйства',
        ],
        correctAnswers: [
          'В электроустановках потребителей электрической энергии',
        ],
      },
      {
        code: '64843091',
        text:
          'В течение какого времени сетевая организация обязана уведомить потребителя услуг о перерыве, прекращении или ограничении передачи электрической энергии в случае, когда неудовлетворительное состояние энергопринимающего устройства потребителя услуг, удостоверенное федеральным уполномоченным органом по технологическому энергетическому надзору, угрожает аварией или создает угрозу жизни и безопасности? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее чем за 24 часа до введения указанных мер',
          'В течение суток с даты принятия такого решения',
          'В течение 3 дней с даты принятия такого решения, но не позднее чем за 24 часа до введения указанных мер',
          'Немедленно после принятия такого решения',
        ],
        correctAnswers: [
          'В течение 3 дней с даты принятия такого решения, но не позднее чем за 24 часа до введения указанных мер',
        ],
      },
      {
        code: '64843092',
        text:
          'Кто осуществляет допуск персонала строительно-монтажной организации к работам в охранной зоне линии электропередачи, находящейся под напряжением? Выберите правильный вариант ответа.',
        answers: [
          'Допускающий из персонала организации, эксплуатирующей линию электропередачи, и ответственный руководитель работ строительно-монтажной организации',
          'Наблюдающий из персонала организации, эксплуатирующей линию электропередачи, и ответственный руководитель работ строительно-монтажной организации',
          'Ответственный руководитель работ организации, эксплуатирующей линию электропередачи, и ответственный руководитель работ строительно-монтажной организации',
        ],
        correctAnswers: [
          'Допускающий из персонала организации, эксплуатирующей линию электропередачи, и ответственный руководитель работ строительно-монтажной организации',
        ],
      },
      {
        code: '64843093',
        text:
          'При наличии какого стажа у работника по решению руководителя организации или уполномоченного им должностного лица допускается его освобождение от стажировки при переводе из одного структурного подразделения организации в другое? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 5 лет',
          'Не менее 1 года',
          'Не менее 3 лет',
          'Не менее 10 лет',
        ],
        correctAnswers: ['Не менее 3 лет'],
      },
      {
        code: '64843094',
        text:
          'В какой срок с момента отключения (повреждения) или разрушения оборудования или устройств, явившихся причиной или следствием пожара на объекте, собственник или иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация принимает решение о создании комиссии по расследованию причин аварии и ее составе? Выберите правильный вариант ответа.',
        answers: [
          'В срок не позднее 24 часов',
          'В срок не позднее 12 часов',
          'В срок не позднее 48 часов',
          'В срок не позднее 3 рабочих дней',
        ],
        correctAnswers: ['В срок не позднее 24 часов'],
      },
      {
        code: '64843095',
        text:
          'Как часто проводится проверка знаний по электробезопасности для административно-технического персонала, не относящегося к электротехническому персоналу? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в два года',
          'Не реже одного раза в три года',
          'Не реже одного раза в пять лет',
        ],
        correctAnswers: ['Не реже одного раза в три года'],
      },
      {
        code: '64843096',
        text:
          'Какой порядок допуска к самостоятельной работе вновь принятых работников или имевших перерыв в работе более 6 месяцев? Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от категории персонала, после прохождения этапов подготовки в соответствии с индивидуальной программой',
          'В зависимости от категории персонала после прохождения специальной подготовки, программу и порядок проведения которой определяет руководитель организации',
          'В зависимости от категории персонала форму подготовки персонала для допуска к самостоятельной работе определяет руководитель организации или структурного подразделения',
          'В зависимости от категории персонала после ознакомления с изменениями в схемах и режимах работы энергоустановок, с вновь введенными в действие нормативно-техническими документами, приказами и распоряжениями',
        ],
        correctAnswers: [
          'В зависимости от категории персонала, после прохождения этапов подготовки в соответствии с индивидуальной программой',
        ],
      },
      {
        code: '64843097',
        text:
          'Что из перечисленного не относится к электрозащитным средствам? Выберите правильный вариант ответа.',
        answers: [
          'Указатели напряжения',
          'Сигнализаторы наличия напряжения индивидуальные и стационарные',
          'Комплекты индивидуальные экранирующие',
          'Изолирующие клещи',
        ],
        correctAnswers: ['Комплекты индивидуальные экранирующие'],
      },
      {
        code: '64843098',
        text:
          'Перед проведением операций с разъединителями и отделителями с ручным приводом каким напряжением должен проводиться их осмотр, в том числе с применением бинокля? Выберите правильный вариант ответа.',
        answers: [
          '35 кВ и выше',
          'от 25 кВ до 100 кВ',
          '10 кВ и выше',
          'от 15 кВ до 35 кВ',
        ],
        correctAnswers: ['35 кВ и выше'],
      },
      {
        code: '64843099',
        text:
          'Какая из перечисленных зон степени защиты относится к зоне, где каждый объект подвержен прямому удару молнии и поэтому через него может протекать полный ток молнии? Выберите правильный вариант ответа.',
        answers: ['Зона 0', 'Зона 0Е', 'Зона 1', 'Зона 2'],
        correctAnswers: ['Зона 0'],
      },
      {
        code: '64843100',
        text:
          'На какое расстояние до токоведущих частей электроустановок, находящихся под напряжением 1-35 кВ, не допускается приближение людей при оперативном обслуживании, осмотрах электроустановок, а также выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Менее чем 0,6 м',
          'Менее чем 0,3 м',
          'Менее чем 0,5 м',
          'Менее чем 0,4 м',
        ],
        correctAnswers: ['Менее чем 0,6 м'],
      },
      {
        code: '64843101',
        text:
          'Что понимается под аварией на объекте электроэнергетики и (или) энергопринимающей установке? Выберите правильный вариант ответа.',
        answers: [
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федерального закона "О промышленной безопасности опасных производственных объектов", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ',
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ; отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федеральных законов "Об электроэнергетике" и "О теплоснабжении", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
        ],
        correctAnswers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
        ],
      },
      {
        code: '64843102',
        text:
          'Сколько экземпляров наряда-допуска (независимо от способа его передачи) заполняется в случаях, когда производитель работ назначается одновременно допускающим? Выберите правильный вариант ответа.',
        answers: [
          'Два экземпляра',
          'Один экземпляр',
          'Три экземпляра',
          'Четыре экземпляра',
        ],
        correctAnswers: ['Два экземпляра'],
      },
      {
        code: '64843103',
        text:
          'С какими категориями персонала проводится подготовка по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'Со всеми категориями персонала, за исключением вспомогательного',
          'Со всеми категориями персонала',
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
          'Со всеми категориями персонала, за исключением административно-технического',
        ],
        correctAnswers: [
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
      },
      {
        code: '64843104',
        text:
          'Кем проводится комплексное опробование оборудования после окончания всех строительных и монтажных работ по сдаваемой электроустановке? Выберите правильный вариант ответа.',
        answers: [
          'Подрядчиком',
          'Потребителем',
          'Проектировщиком',
          'Органами госэнергонадзора',
        ],
        correctAnswers: ['Потребителем'],
      },
      {
        code: '64843105',
        text:
          'Какой документ определяет порядок технологического присоединения энергопринимающих устройств потребителей электрической энергии к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Федеральный закон от 26 марта 2003 г. № 35-ФЗ "Об электроэнергетике"',
          'Правила технологического присоединения энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, а также объектов электросетевого хозяйства, принадлежащих сетевым организациям и иным лицам, к электрическим сетям, утвержденные постановлением Правительства Российской Федерации от 27 декабря 2004 г. № 861',
          'Федеральный закон от 21 июля 2011 г. № 256-ФЗ "О безопасности объектов топливно-энергетического комплекса"',
          'Правила устройства электроустановок',
        ],
        correctAnswers: [
          'Правила технологического присоединения энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, а также объектов электросетевого хозяйства, принадлежащих сетевым организациям и иным лицам, к электрическим сетям, утвержденные постановлением Правительства Российской Федерации от 27 декабря 2004 г. № 861',
        ],
      },
      {
        code: '64843106',
        text:
          'Какие из перечисленных требований к бланкам переключений указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Использованные бланки переключений ( в том числе испорченные) должны храниться по порядку их текущих номеров',
          'Порядок нумерации, выдачи, хранения и учета типовых бланков переключений должен быть определен техническим руководителем владельца объекта электроэнергетики (его филиала) в местной инструкции по производству переключений в электроустановках',
          'При сдаче смены в оперативном журнале должны фиксироваться номера выданных оперативному персоналу незаполненных экземпляров бланков переключений и номер последнего использованного бланка переключений',
          'Выдаваемые оперативному персоналу незаполненные экземпляры бланков переключений должны быть пронумерованы',
          'В течение одного календарного месяца должна применяться единая сквозная нумерация использованных бланков переключений и незаполненных экземпляров бланков переключений',
        ],
        correctAnswers: [
          'В течение одного календарного месяца должна применяться единая сквозная нумерация использованных бланков переключений и незаполненных экземпляров бланков переключений',
        ],
      },
      {
        code: '64843107',
        text:
          'Учитываются ли расходы на проведение мероприятий по энергосбережению и повышению энергетической эффективности организаций, осуществляющих регулируемые виды деятельности, при установлении цен (тарифов) на товары, услуги таких организаций? Выберите правильный вариант ответа.',
        answers: [
          'Не учитываются',
          'Учитываются только для некоммерческих организаций',
          'Учитываются только для государственных предприятий',
          'Учитываются',
        ],
        correctAnswers: ['Учитываются'],
      },
      {
        code: '64843108',
        text:
          'Кто осуществляет управление государственной собственностью в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Органы исполнительной власти субъектов Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Только уполномоченные федеральные органы исполнительной власти',
          'Только Правительство Российской Федерации',
        ],
        correctAnswers: [
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
        ],
      },
      {
        code: '64843109',
        text:
          'Какое минимальное количество диэлектрических перчаток должно быть в распределительных устройствах напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: ['1 пара', '2 пары', '3 пары', 'Зависит от местных условий'],
        correctAnswers: ['2 пары'],
      },
      {
        code: '64843110',
        text:
          'Какие действия вправе осуществить сетевые организации при невыполнении потребителем команд (распоряжений) системного оператора о введении в действие графиков аварийного ограничения? Выберите правильный вариант ответа.',
        answers: [
          'Отключить указанного потребителя непосредственно с питающих центров или ограничить его потребление вплоть до уровня аварийной брони',
          'Только ограничить его потребление вплоть до уровня аварийной брони',
          'Только отключить указанного потребителя непосредственно с питающих центров',
          'Только ограничить его потребление',
        ],
        correctAnswers: [
          'Отключить указанного потребителя непосредственно с питающих центров или ограничить его потребление вплоть до уровня аварийной брони',
        ],
      },
      {
        code: '64843111',
        text:
          'В каких случаях проводится первичная проверка знаний работников организаций электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'При перерыве в работе в данной должности более 1,5 лет',
          'При перерыве в проверке знаний более 3 лет',
          'При поступлении на работу впервые',
          'При нарушении работниками требований нормативных актов по охране труда',
        ],
        correctAnswers: [
          'При поступлении на работу впервые',
          'При перерыве в проверке знаний более 3 лет',
        ],
      },
      {
        code: '64843112',
        text:
          'Какую группу по электробезопасности должны иметь специалисты по охране труда, контролирующие электроустановки организаций потребителей электроэнергии? Выберите правильный вариант ответа.',
        answers: ['Не ниже III', 'Не ниже  IV', 'Не ниже V', 'Не ниже II'],
        correctAnswers: ['Не ниже  IV'],
      },
      {
        code: '64843113',
        text:
          'В какой срок лицо, получившее неудовлетворительную оценку по результатам проверки знаний, должно пройти повторную проверку? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 10 дней',
          'Вопрос о сроках повторной проверки и о возможности сохранения трудового договора с работником решается руководителем организации',
          'Не позднее 1 месяца',
          'Не позднее 14 дней',
        ],
        correctAnswers: ['Не позднее 1 месяца'],
      },
      {
        code: '64843114',
        text:
          'Каков срок действия временного разрешения на допуск в эксплуатацию энергопринимающих установок потребителей электрической энергии? Выберите правильный вариант ответа.',
        answers: ['60 суток', '180 суток', '90 суток', '120 суток'],
        correctAnswers: ['180 суток'],
      },
      {
        code: '64843115',
        text:
          'На какие виды не подразделяются ремонты по техническому состоянию оборудования на объектах электроэнергетики в зависимости от перечня и объемов ремонтных мероприятий? Выберите правильный вариант ответа.',
        answers: ['Малые', 'Средние', 'Капитальные', 'Текущие'],
        correctAnswers: ['Малые'],
      },
      {
        code: '64843116',
        text:
          'В каких электроустановках применяются указатели напряжения для проверки совпадения фаз напряжения (фазировки)? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках напряжением до 1000 В',
          'В электроустановках напряжением свыше 1000 В',
          'В электроустановках напряжением от 6 до 110 кВ',
          'В электроустановках напряжением от 35 до 220 кВ',
        ],
        correctAnswers: ['В электроустановках напряжением от 6 до 110 кВ'],
      },
      {
        code: '64843117',
        text:
          'Когда проводится проверка и осмотр устройств молниезащиты зданий, сооружений и наружных установок? Выберите правильный вариант ответа.',
        answers: [
          'По графику два раза в год',
          'Один раз в год перед началом грозового сезона, а также после установки системы молниезащиты, после внесения каких-либо изменений в систему молниезащиты, после любых повреждений защищаемого объекта',
          'Только после установки системы молниезащиты',
          'Только при повреждениях защищаемого объекта',
        ],
        correctAnswers: [
          'Один раз в год перед началом грозового сезона, а также после установки системы молниезащиты, после внесения каких-либо изменений в систему молниезащиты, после любых повреждений защищаемого объекта',
        ],
      },
      {
        code: '64843118',
        text:
          'Что из перечисленного не указывается в заявлении о проведении проверки знаний, направляемом в федеральный орган исполнительной власти, уполномоченный на осуществление федерального государственного энергетического надзора? Выберите правильный вариант ответа.',
        answers: [
          'Категория персонала, к которой относится работник',
          'Стаж работы в области электробезопасности',
          'Уровень напряжения эксплуатируемых электроустановок',
          'Фамилия, имя, отчество (при наличии) работника, в отношении которого требуется проведение проверки знаний, и занимаемая им должность (выполняемая профессия)',
          'Присваиваемая группа по электробезопасности',
          'Причина проверки знаний',
        ],
        correctAnswers: ['Стаж работы в области электробезопасности'],
      },
      {
        code: '64843119',
        text:
          'Каким образом работник при непосредственном использовании может определить, что электрозащитные средства прошли эксплуатационные испытания и пригодны для применения? Выберите правильный вариант ответа.',
        answers: [
          'По протоколам эксплуатационных испытаний',
          'По штампу или маркировке на средстве защиты',
          'По записи в журнале испытаний средств защиты',
          'По внешнему виду средств защиты',
        ],
        correctAnswers: ['По штампу или маркировке на средстве защиты'],
      },
      {
        code: '64843120',
        text:
          'Каким работникам предоставляется право выдачи нарядов-допусков и распоряжений (кроме работ по предотвращению аварий или ликвидации их последствий)? Выберите правильный вариант ответа.',
        answers: [
          'Работникам из числа оперативного персонала, имеющим группу не ниже III, в соответствии с должностными инструкциями',
          'Работникам из числа оперативного персонала организации, имеющим группу IV - в электроустановках напряжением выше 1000 В и группу III - в электроустановках напряжением до 1000 В',
          'Работникам из числа административно-технического персонала организации (руководящих работников и специалистов), имеющим группу V (при эксплуатации электроустановок напряжением выше 1000 В), группу IV (при эксплуатации электроустановок напряжением до 1000 В)',
          'Работникам из числа ремонтного персонала, имеющим группу не ниже V, в соответствии с должностными инструкциями',
        ],
        correctAnswers: [
          'Работникам из числа административно-технического персонала организации (руководящих работников и специалистов), имеющим группу V (при эксплуатации электроустановок напряжением выше 1000 В), группу IV (при эксплуатации электроустановок напряжением до 1000 В)',
        ],
      },
      {
        code: '64843121',
        text:
          'С какой периодичностью ответственным за электрохозяйство должна проводиться проверка электрических (технологических) схем (чертежей) электроустановок на соответствие фактическим эксплуатационным? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в два года с отметкой на них о дате проверке',
          'Не реже одного раза в пять лет с отметкой на них о дате проверке',
          'Не реже одного раза в три года с отметкой на них о дате проверке',
          'Не реже одного раза в год с отметкой на них о дате проверке',
          'Не реже одного раза в полгода с отметкой на них о дате проверке',
        ],
        correctAnswers: [
          'Не реже одного раза в три года с отметкой на них о дате проверке',
        ],
      },
      {
        code: '64843122',
        text:
          'Каким образом оформляется решение о расследовании причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Решением уполномоченного органа в сфере электроэнергетики',
          'Протоколом должностного лица органа федерального государственного энергетического надзора',
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
          'Приказом организации, эксплуатирующей данный участок электроэнергетики, на котором произошла авария',
        ],
        correctAnswers: [
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
        ],
      },
      {
        code: '64843123',
        text:
          'Кто дает разрешение на снятие напряжения для освобождения пострадавшего от действия электрического тока? Выберите правильный вариант ответа.',
        answers: [
          'Административно-технический персонал',
          'Вышестоящий оперативный персонал',
          'Оперативный персонал энергообъекта',
          'Напряжение должно быть снято немедленно без предварительного разрешения',
        ],
        correctAnswers: [
          'Напряжение должно быть снято немедленно без предварительного разрешения',
        ],
      },
      {
        code: '64843124',
        text:
          'С какой периодичностью руководители (заместители руководителей) субъектов электроэнергетики и потребителей электрической энергии, в отношении которых осуществляется федеральный государственный энергетический надзор в сфере электроэнергетики, обязаны проходить аттестацию по вопросам безопасности в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в пять лет',
          'Не реже одного раза в год',
          'Не реже одного раза в три года',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в пять лет'],
      },
      {
        code: '64843125',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от стажа работника, но не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 3 месяца',
          'В зависимости от образования работника, но не реже 1 раза в 4 месяца',
          'На усмотрение главного технического руководителя в зависимости от характера выполняемой работы работника',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '64843126',
        text:
          'Что из перечисленного не относится к основным изолирующим электрозащитным средствам для электроустановок напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Указатели напряжения',
          'Диэлектрические перчатки',
          'Изолирующие клещи',
          'Электроизмерительные клещи',
          'Диэлектрические ковры и изолирующие подставки',
        ],
        correctAnswers: ['Диэлектрические ковры и изолирующие подставки'],
      },
      {
        code: '64843127',
        text:
          'Что из перечисленного не является критериями технической возможности технологического присоединения? Выберите правильный вариант ответа.',
        answers: [
          'Сохранение условий электроснабжения (установленной категории надежности электроснабжения и сохранения качества электроэнергии) для прочих потребителей, энергопринимающие установки которых на момент подачи заявки заявителя присоединены к электрическим сетям сетевой организации или смежных сетевых организаций',
          'Отсутствие ограничений на максимальную мощность в объектах электросетевого хозяйства, к которым надлежит произвести технологическое присоединение',
          'Отсутствие необходимости реконструкции или расширения (сооружения новых) объектов электросетевого хозяйства смежных сетевых организаций либо строительства (реконструкции) генерирующих объектов для удовлетворения потребности заявителя',
          'Возможность перераспределения объема присоединенной мощности одних владельцев в пользу других',
        ],
        correctAnswers: [
          'Возможность перераспределения объема присоединенной мощности одних владельцев в пользу других',
        ],
      },
      {
        code: '64843128',
        text:
          'На кого распространяются Правила по охране труда при эксплуатации электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'Только на работников организаций, занятых техническим обслуживанием электроустановок и выполняющих в них строительные, монтажные и ремонтные работы, в том числе работы с приборами учета электроэнергии и измерительными приборами',
          'На работодателей - юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
          'Только на работников организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих испытания и измерения',
          'Только на работников промышленных предприятий, осуществляющих управление технологическими режимами работы объектов энергопринимающих установок потребителей на балансе которых имеются электроустановки',
        ],
        correctAnswers: [
          'На работодателей - юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
        ],
      },
      {
        code: '64843129',
        text:
          'Когда работники должны проходить обучение по оказанию первой помощи пострадавшему на производстве? Выберите правильный вариант ответа.',
        answers: [
          'До допуска к самостоятельной работе',
          'Непосредственно сразу после допуска к самостоятельной работе',
          'В течение года после допуска к самостоятельной работе',
          'В течение месяца после допуска к самостоятельной работе',
        ],
        correctAnswers: ['До допуска к самостоятельной работе'],
      },
      {
        code: '64843130',
        text:
          'Кто из перечисленных лиц не включается в состав рабочей комиссии, осуществляющей приемку молниезащитных устройств на действующих объектах? Выберите правильный вариант ответа.',
        answers: [
          'Представитель территориального органа Ростехнадзора',
          'Представитель подрядной организации',
          'Представители службы противопожарной инспекции',
          'Представители лица, ответственного за электрохозяйство',
        ],
        correctAnswers: ['Представитель территориального органа Ростехнадзора'],
      },
      {
        code: '64843131',
        text:
          'Какие из перечисленных требований к эксплуатации электроустановок, конструкция которых такова, что установка заземления опасна или невозможна, указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках должны быть разработаны дополнительные мероприятия по обеспечению безопасности работ, включающие установку рубильников диэлектрических накладок или отсоединение проводов, кабелей и шин',
          'Перечень таких электроустановок утверждается руководителем организации или руководителем обособленного подразделения и доводится до сведения работников',
          'Все перечисленные требования указаны верно',
          'В электроустановках должны быть разработаны дополнительные мероприятия по обеспечению безопасности работ, включающие установку диэлектрических колпаков на ножи разъединителей',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '64843132',
        text:
          'Кем должен быть организован порядок хранения и выдачи ключей от электроустановок в организации? Выберите правильный вариант ответа.',
        answers: [
          'Работником, ответственным за выдачу и хранение ключей',
          'Работником, имеющим право единоличного осмотра',
          'Руководителем оперативной службы организации',
          'Руководителем организации (обособленного подразделения)',
        ],
        correctAnswers: [
          'Руководителем организации (обособленного подразделения)',
        ],
      },
      {
        code: '64843133',
        text:
          'Какой персонал должен проходить дублирование? Выберите правильный вариант ответа.',
        answers: [
          'Лица, непосредственно связанные с ремонтом и техническим обслуживанием энергетического оборудования, после первичной проверки знаний',
          'Лица из числа оперативного персонала',
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонал',
          'Специалисты, связанные с наладкой и испытанием энергоустановок, после первичной проверки знаний',
        ],
        correctAnswers: [
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонал',
        ],
      },
      {
        code: '64843134',
        text:
          'В каких электроустановках распределительных электрических сетей переключения допускается выполнять по команде на производство переключений, выдаваемой административно-техническим персоналом, выполняющим в указанном случае функции оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Напряжением до 1 кВ включительно',
          'Напряжением до 35 кВ включительно',
          'Напряжением до 110 кВ включительно',
          'Напряжением до 15 кВ включительно',
        ],
        correctAnswers: ['Напряжением до 1 кВ включительно'],
      },
      {
        code: '64843135',
        text:
          'Какими нормативно-техническими документами необходимо руководствоваться при организации и осуществлении эксплуатации электрооборудования и электроустановок общего назначения потребителей (силовые трансформаторы и масляные шунтирующие реакторы, распределительные устройства, воздушные ЛЭП, кабельные линии, электродвигатели)? Выберите правильный вариант ответа.',
        answers: [
          'Правилами технической эксплуатации электроустановок потребителей',
          'Правилами по охране труда при эксплуатации электроустановок',
          'Правилах технической эксплуатации электрических станций и сетей',
          'Всеми перечисленными документами',
        ],
        correctAnswers: [
          'Правилах технической эксплуатации электрических станций и сетей',
        ],
      },
      {
        code: '64843136',
        text:
          'Какой документ должны составить представители строительно-монтажной организации и организации-владельца электроустановки для производства работ на территории организации-владельца электроустановки? Выберите правильный вариант ответа.',
        answers: ['Протокол', 'Акт-допуск', 'Распоряжение', 'Наряд-допуск'],
        correctAnswers: ['Акт-допуск'],
      },
      {
        code: '64843137',
        text:
          'В каком случае аварийные ограничения режима потребления электрической энергии (мощности) вводятся без согласования с потребителем? Выберите правильный вариант ответа.',
        answers: [
          'Только в случае повреждения систем технологического управления, технологической связи и противоаварийной автоматики',
          'Только в случае повреждения электросетевого оборудования, в том числе в результате чрезвычайных ситуаций природного и техногенного характера',
          'Только в случае недостаточности производства электрической энергии и мощности в отдельных частях энергосистемы, приводящей к недопустимой перегрузке линий электропередачи',
          'В любом из перечисленных случаев, так как аварийные ограничения вводятся без согласования с потребителем',
        ],
        correctAnswers: [
          'В любом из перечисленных случаев, так как аварийные ограничения вводятся без согласования с потребителем',
        ],
      },
      {
        code: '64843138',
        text:
          'Какое количество указателей напряжения до 1000 В должна иметь при себе бригада, обслуживающая воздушные линии электропередачи? Выберите правильный вариант ответа.',
        answers: [
          'Один',
          'Минимум два',
          'Не более трех',
          'Зависит от местных условий',
        ],
        correctAnswers: ['Минимум два'],
      },
      {
        code: '64843139',
        text:
          'В течение какого срока должны храниться наряды-допуски, работы по которым полностью закончены, если при выполнении работ по этим нарядам-допускам не имели место аварии, инциденты или несчастные случаи? Выберите правильный вариант ответа.',
        answers: [
          'В течение 1 года',
          'В течение 6 месяцев',
          'В течение 30 суток',
          'В течение 20 суток',
        ],
        correctAnswers: ['В течение 1 года'],
      },
      {
        code: '64843140',
        text:
          'В течение какого срока проводится комплексное опробование основного и вспомогательного оборудования электроустановки при вводе в работу? Выберите правильный вариант ответа.',
        answers: [
          'В течение 24 часов',
          'В течение 12 часов',
          'В течение 72 часов',
          'В течение 36 часов',
        ],
        correctAnswers: ['В течение 72 часов'],
      },
      {
        code: '64843141',
        text:
          'Что входит в обязанности потребителя согласно Правилам технической эксплуатации электроустановок потребителей электрической энергии? Выберите правильный вариант ответа.',
        answers: [
          'Наличие, использование и поддержание в актуальном состоянии технической документации, необходимой для эксплуатации электроустановок',
          'Учет, расследование и анализ причин аварий в электроэнергетике, произошедших на объектах потребителя, а также принятие мер по устранению причин их возникновения',
          'Все перечисленное',
          'Эксплуатацию устройств молниезащиты, измерительных приборов и средств учета электрической энергии',
          'Содержание электроустановок в исправном состоянии и их безопасную эксплуатацию',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64843142',
        text:
          'Что из перечисленного соответствует понятию "объекты электроэнергетики"? Выберите правильный вариант ответа.',
        answers: [
          'Коммерческие организации, которые оказывают услуги по передаче электрической энергии с использованием объектов электросетевого хозяйства, не относящихся к единой национальной (общероссийской) электрической сети',
          'Имущественные объекты, непосредственно используемые в процессе производства, передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике и сбыта электрической энергии, в том числе объекты электросетевого хозяйства',
          'Организации, на которые в установленном порядке возложены функции обеспечения коммерческой инфраструктуры',
          'Коммерческие организации, которым в соответствии с законодательством Российской Федерации присвоен статус объекта электроэнергетики',
        ],
        correctAnswers: [
          'Имущественные объекты, непосредственно используемые в процессе производства, передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике и сбыта электрической энергии, в том числе объекты электросетевого хозяйства',
        ],
      },
      {
        code: '64843143',
        text:
          'У кого могут быть на учете ключи от электроустановок, не имеющих местного оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'У руководящих работников и специалистов организации',
          'У административно-технического персонала',
          'У специалистов по охране труда организации',
          'У всех перечисленных лиц',
        ],
        correctAnswers: ['У административно-технического персонала'],
      },
      {
        code: '64843144',
        text:
          'Кого уведомляет собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки, либо эксплуатирующая их организация о возникновении аварии? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерский центр субъекта оперативно-диспетчерского управления в электроэнергетике',
          'Орган федерального государственного энергетического надзора',
          'Всех перечисленных',
          'Подведомственное уполномоченному органу в сфере электроэнергетики государственное бюджетное учреждение',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '64843145',
        text:
          'Кто имеет право на продление нарядов-допусков? Выберите правильный вариант ответа.',
        answers: [
          'Только работник, выдавший наряд-допуск, или имеющий право выдачи наряда-допуска на работы в данной электроустановке',
          'Ответственный руководитель работ в данной электроустановке',
          'Ответственный за электрохозяйство структурного подразделения',
          'Руководитель объекта, на котором проводятся работы',
        ],
        correctAnswers: [
          'Только работник, выдавший наряд-допуск, или имеющий право выдачи наряда-допуска на работы в данной электроустановке',
        ],
      },
      {
        code: '64843146',
        text:
          'В каком случае требуется оформление разрешения на допуск в эксплуатацию энергоустановки для включения ее в работу при проведении аварийно-восстановительных работ в условиях ликвидации аварийных ситуаций в работе электроэнергетической системы? Выберите правильный вариант ответа.',
        answers: [
          'Не требуется',
          'Во всех случаях в соответствии с Правилами выдачи разрешений на допуск в эксплуатацию энергопринимающих установок потребителей электрической энергии, объектов по производству электрической энергии, объектов электросетевого хозяйства, объектов теплоснабжения и теплопотреблязих установок',
          'Если это предусмотрено проектом',
          'Во всех случаях, кроме проведения неотложных аварийно-восстановительных',
        ],
        correctAnswers: ['Не требуется'],
      },
      {
        code: '64843147',
        text:
          'Какие из перечисленных требований к допуску в эксплуатацию энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, объектов электросетевого хозяйства указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Разрешение на допуск в эксплуатацию выдается в ходе технологического присоединения',
          'Разрешение на допуск в эксплуатацию выдается при вводе в эксплуатацию новых или реконструированных допускаемых объектов, технологическое присоединение которых не осуществляется',
          'Категории энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, объектов электрического хозяйства, для которых требуется получение разрешения на допуск в эксплуатацию, определяются Правительством Российской Федерации',
          'Выдача разрешения на допуск в эксплуатацию осуществляется после уплаты государственной пошлины в размерах и порядке, которые установлены законодательством Российской Федерации о налогах и сборах',
          'На период проведения испытаний и наладки допускаемых объектов выдается временное разрешение на допуск в эксплуатацию',
        ],
        correctAnswers: [
          'Выдача разрешения на допуск в эксплуатацию осуществляется после уплаты государственной пошлины в размерах и порядке, которые установлены законодательством Российской Федерации о налогах и сборах',
        ],
      },
      {
        code: '64843148',
        text:
          'Каким образом должны производиться переключения в электроустановках при вводе в работу новых (модернизированных, реконструированных) линий электропередачи, оборудования, устройств релейной защиты и автоматики и при проведении испытаний? Выберите правильный вариант ответа.',
        answers: [
          'По комплексным программам',
          'Без использования программ',
          'По разовым программам',
        ],
        correctAnswers: ['По комплексным программам'],
      },
      {
        code: '64843149',
        text:
          'Какую работу на воздушной линии не разрешается выполнять по распоряжению одному работнику, имеющему группу II по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'Окраску бандажей на опорах',
          'Осмотр воздушной линии в темное время суток',
          'Восстановление постоянных обозначений на опоре',
          'Замер габаритов угломерными приборами',
        ],
        correctAnswers: ['Осмотр воздушной линии в темное время суток'],
      },
      {
        code: '64843150',
        text:
          'Кто несет ответственность за работу с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель подразделения, ответственный за работу с кадрами',
          'Технический руководитель организации',
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права и полномочия',
          'Лицо, осуществляющее хозяйственную деятельность организации',
        ],
        correctAnswers: [
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права и полномочия',
        ],
      },
      {
        code: '64843151',
        text:
          'На какой срок выдается распоряжение на производство работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Распоряжение носит разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
          'Не более 20 календарных дней со дня начала работы',
          'Не более 5 календарных дней со дня начала работы',
          'На все время проведения работ',
          'Не более 10 календарных дней со дня начала работы',
        ],
        correctAnswers: [
          'Распоряжение носит разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
        ],
      },
      {
        code: '64843152',
        text:
          'Какие требования для потребителя электрической энергии, энергопринимающие установки которого функционируют в составе энергосистемы, устанавливают Правила технологического функционирования электроэнергетических систем? Выберите правильный вариант ответа.',
        answers: [
          'Потребитель обязан организовать и осуществлять эксплуатацию принадлежащих ему электроустановок в соответствии с правилами технической эксплуатации электроустановок потребителей электрической энергии',
          'Потребитель обязан соблюдать значения соотношения активной и реактивной мощности, установленные для его энергопринимающих устройств в соответствии с порядком расчета значений соотношения потребления активной и реактивной мощности для отдельных энергопринимающих устройств',
          'Потребители обязаны обеспечить соответствие технических характеристик и параметров работы принадлежащих им на праве собственности или ином законном основании объектов электроэнергетики и энергопринимающих установок, их оборудования и устройств требованиям, установленным Правилами технологического функционирования электроэнергетических систем',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '64843153',
        text:
          'Какая общая продолжительность осмотра (присутствие непосредственно на месте расположения допускаемого объекта) должностными лицами органа федерального государственного энергетического надзора после принятия к рассмотрению заявления о выдаче разрешения на допуск в эксплуатацию энергопринимающих установок потребителей электрической энергии? Выберите правильный вариант ответа.',
        answers: [
          'Не должна превышать 7 рабочих дней',
          'Не должна превышать 3 рабочих дня',
          'Не должна превышать 5 рабочих дней',
          'Не должна превышать 10 рабочих дней',
          'Не должна превышать 2 рабочих дня',
        ],
        correctAnswers: ['Не должна превышать 7 рабочих дней'],
      },
      {
        code: '64843154',
        text:
          'Каким образом должен выполняться капитальный ремонт электрооборудования напряжением выше 1000 В? Выберите 2 варианта ответа.',
        answers: [
          'По проекту производства работ',
          'По технологическим картам',
          'По техническому заданию',
          'По техническому проекту',
        ],
        correctAnswers: [
          'По технологическим картам',
          'По проекту производства работ',
        ],
      },
      {
        code: '64843155',
        text:
          'Где должны храниться паспорта молниезащитных устройств и паспорта заземлителей устройств молниезащиты? Выберите правильный вариант ответа.',
        answers: [
          'У ответственного за электрохозяйство',
          'На рабочем месте оперативного персонала',
          'У технического руководителя организации',
          'У руководителя организации',
        ],
        correctAnswers: ['У ответственного за электрохозяйство'],
      },
      {
        code: '64843156',
        text:
          'Какую группу по электробезопасности должны иметь работники из числа оперативного персонала, единолично обслуживающие электроустановки напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'II или III группу',
          'Группу III',
          'Любую группу по электробезопасности',
          'Группу IV',
        ],
        correctAnswers: ['Группу III'],
      },
      {
        code: '64843157',
        text:
          'Для выполнения каких действий выдается временное разрешение на допуск в эксплуатацию? Выберите правильный вариант ответа.',
        answers: [
          'Пробных пусков и комплексных испытаний',
          'Комплексного опробования, иных испытаний и пусконаладочных работ',
          'Комплексного опробования и пусконаладочных работ в отношении допускаемых объектов, предусматривающих подключение таких объектов к системе теплоснабжения, подачу тепловой энергии и теплоносителя',
          'Для выполнения всего перечисленного',
        ],
        correctAnswers: ['Для выполнения всего перечисленного'],
      },
      {
        code: '64843158',
        text:
          'Что должна включать техническая эксплуатация электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'Оперативно-технологическое управление электроустановками',
          'Ремонт и техническое обслуживание электроустановок',
          'Все перечисленное',
          'Ввод в работу новых, реконструированных (модернизированных, технически перевооружаемых) электроустановок, нового (модернизированного) оборудования и новых (модернизированных) устройств, входящих в состав электроустановок',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64843159',
        text:
          'Какие работы могут выполняться в порядке текущей эксплуатации в электроустановках напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Испытания электрооборудования',
          'Прокладка контрольных кабелей',
          'Проверка устройств защиты, измерений, блокировки, электроавтоматики, телемеханики, связи',
          'Измерения, проводимые с использованием мегаомметра',
        ],
        correctAnswers: ['Измерения, проводимые с использованием мегаомметра'],
      },
      {
        code: '64843160',
        text:
          'Кто должен назначаться допускающим в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Работники из числа оперативного персонала',
          'Работники из числа административного персонала',
          'Работники из числа электротехнологического персонала',
          'Работники из числа ремонтного персонала',
        ],
        correctAnswers: ['Работники из числа оперативного персонала'],
      },
      {
        code: '64843161',
        text:
          'Что из перечисленного соответствует понятию "потребители электрической энергии"? Выберите правильный вариант ответа.',
        answers: [
          'Коммерческая организация, которой в соответствии с законодательство Российской Федерации присвоен статус потребителя электрической энергии, которая осуществляет энергосбытовую деятельность',
          'Юридические лица, получившие в установленном Федеральным законом “Об электроэнергетике”  порядке право участвовать в отношениях, связанных с обращением электрической энергии и )или) мощности на оптовом рынке, в соответствии с утверждаемыми Правительство Российской Федерации правилами оптового рынка',
          'Лица, приобретающие электрическую энергию для собственных бытовых и (или) производственных нужд',
          'Лица, приобретающие мощность, в том числе для собственных бытовых и (или) производственных нужд и (или) для последующей продажи, лица, реализующие электрическую энергию на розничных рынках, лица, реализующие электрическую энергию на территориях, на которых располагаются электротехнические системы иностранных государств',
        ],
        correctAnswers: [
          'Лица, приобретающие электрическую энергию для собственных бытовых и (или) производственных нужд',
        ],
      },
      {
        code: '64843162',
        text:
          'С какой периодичностью должно проводиться дополнительное профессиональное образование работников, относящихся к категориям административно-технического, диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 10 лет',
          'Не реже 1 раза в 6 лет',
          'Не реже 1 раза в 3 года',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '64843163',
        text:
          'Что должен пройти командированный персонал по прибытии на место своей командировки для выполнения работ в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Проверку знания норм и правил для подтверждения группы по электробезопасности',
          'Специальную подготовку',
          'Противоаварийную тренировку',
          'Вводный и первичный инструктажи по охране труда',
        ],
        correctAnswers: ['Вводный и первичный инструктажи по охране труда'],
      },
      {
        code: '64843164',
        text:
          'Какого вида ремонта объектов электроэнергетики в зависимости от планирования не существует? Выберите правильный вариант ответа.',
        answers: [
          'Планового ремонта',
          'Дополнительного ремонта',
          'Внепланового ремонта',
          'Аварийного ремонта',
        ],
        correctAnswers: ['Дополнительного ремонта'],
      },
      {
        code: '64843165',
        text:
          'Какие изолирующие средства защиты для электроустановок напряжением выше 1000 В относятся к дополнительным? Выберите правильный вариант ответа.',
        answers: [
          'Изолирующие штанги',
          'Штанги для переноса и выравнивания потенциала',
          'Клещи электроизмерительные',
          'Устройства для прокола кабеля',
        ],
        correctAnswers: ['Штанги для переноса и выравнивания потенциала'],
      },
      {
        code: '64843166',
        text:
          'В каком документе оформляется допуск к работам по распоряжению? Выберите правильный вариант ответа.',
        answers: [
          'В журнале выдачи распоряжений',
          'В журнале учета работ по нарядам-допускам и распоряжениям',
          'В журнале регистрации нарядов и распоряжений',
          'Допуск к работам оформляется только в самом распоряжении',
        ],
        correctAnswers: [
          'В журнале учета работ по нарядам-допускам и распоряжениям',
        ],
      },
      {
        code: '64843167',
        text:
          'В течение какого срока проводится комплексное опробование работы линии электропередачи при вводе в работу? Выберите правильный вариант ответа.',
        answers: [
          'В течение 24 часов',
          'В течение 72 часов',
          'В течение 36 часов',
          'В течение 48 часов',
        ],
        correctAnswers: ['В течение 24 часов'],
      },
      {
        code: '64843168',
        text:
          'Какие из перечисленных требований к организации и осуществлению эксплуатации электроустановок потребителей указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'В случае если электроустановка потребителя - юридического лица включает в себя только вводное (вводно-распределительное) устройство, осветительную установку, переносное электрооборудование, имеющие номинальное напряжение не выше 0,4 кВ, либо электроустановки имеют суммарную максимальную мощность не более 150 кВт, номинальное напряжение до 1000 В и присоединены к одному источнику электроснабжения, ответственность за выполнение обязанностей по организации эксплуатации электроустановок, организации проведения всех видов работ в электроустановках такого потребителя может быть возложена на единоличный исполнительный орган указанного потребителя - юридического лица',
          'Все перечисленные требования указаны верно',
          'Для непосредственного выполнения обязанностей по организации эксплуатации электроустановок руководитель потребителя (за исключением индивидуальных предпринимателей и физических лиц) организационно-распорядительным документом назначает из числа оперативно-ремонтного персонала потребителя лицо, на которое возложены обязанности по организации проведения всех видов работ в электроустановках потребителя',
          'Потребителем организационно-распорядительным актом должна быть определена организационная структура управления электроустановками, распределены границы эксплуатационной ответственности и функции по обслуживанию и контролю за техническим состоянием ЛЭП, оборудования, устройств, зданий и сооружений электроустановок между структурными подразделениями и должностными лицами  потребителя - юридического лица',
        ],
        correctAnswers: [
          'Для непосредственного выполнения обязанностей по организации эксплуатации электроустановок руководитель потребителя (за исключением индивидуальных предпринимателей и физических лиц) организационно-распорядительным документом назначает из числа оперативно-ремонтного персонала потребителя лицо, на которое возложены обязанности по организации проведения всех видов работ в электроустановках потребителя',
        ],
      },
      {
        code: '64843169',
        text:
          'Для каких категорий работников проводится стажировка? Выберите правильный вариант ответа.',
        answers: [
          'Определяется индивидуально по решению руководителя организации или уполномоченного им лица',
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
          'Для всех категорий, за исключением вспомогательного',
          'Для всех категорий персонала',
        ],
        correctAnswers: [
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64843170',
        text:
          'Где должны указываться сведения о наличии резервных стационарных или передвижных технологических электростанций потребителя, их установленной мощности и значении номинального напряжения? Выберите правильный вариант ответа.',
        answers: [
          'В договоре энергоснабжения',
          'На электрических схемах Потребителя',
          'В эксплуатационной документации',
          'На нормальной схеме электрических соединений и в договоре энергоснабжения',
        ],
        correctAnswers: [
          'На нормальной схеме электрических соединений и в договоре энергоснабжения',
        ],
      },
      {
        code: '64843171',
        text:
          'В каких случаях должен проводиться внеплановый производственный инструктаж с персоналом в организациях электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'При принятии новой или внесении изменений в действующую инструктивно-техническую документацию диспетчерских центров субъекта оперативно-диспетчерского управления, инструктивную документацию организации по вопросам, указанным в пункте 84 Правил работы с персоналом, а также при принятии новой инструктивной документации организации, определяющей порядок технологических процессов и связанных с ними действий персонала при эксплуатации линий электропередачи, оборудования, устройств и автоматизированных систем управления, являющейся обязательной для использования в работе и исполнения согласно должностным обязанностям (трудовым функциям) работника;',
          'При внесении изменений в действующие отраслевые акты в сфере электроэнергетики (для персонала объектов по производству электрической энергии, функционирующих в режиме комбинированной выработки электрической и тепловой энергии, - также в сфере теплоснабжения), являющиеся обязательными для использования в работе и исполнения согласно должностным обязанностям (трудовым функциям) работника',
          'По решению руководителя или иного уполномоченного должностного лица организации (ее филиала, представительства) при внесении изменений в обслуживаемые персоналом технологические схемы и оборудование, вводе в эксплуатацию новых или реконструированных (модернизированных, технически перевооруженных) линий электропередачи, оборудования, устройств (комплексов) РЗА, автоматизированных систем управления',
          'В случае непрохождения работником планового производственного инструктажа (по темам пропущенного инструктажа)',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '64843172',
        text:
          'После присвоения какой группы по электробезопасности в электроустановках напряжением выше 1000 В производится назначение ответственного за электрохозяйство и его заместителя? Выберите правильный вариант ответа.',
        answers: ['V', 'IV', 'III', 'Любой из перечисленных групп'],
        correctAnswers: ['V'],
      },
      {
        code: '64843173',
        text:
          'При каком условии работники, не обслуживающие электроустановки, могут быть допущены в распределительное устройство выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV, либо работника, имеющего право единоличного осмотра',
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III, либо работника, имеющего право единоличного осмотра',
          'В сопровождении опытного работника из числа ремонтного персонала, имеющего группу по электробезопасности не ниже V',
        ],
        correctAnswers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV, либо работника, имеющего право единоличного осмотра',
        ],
      },
      {
        code: '64843174',
        text:
          'Каким образом перед применением диэлектрические перчатки проверяются на наличие проколов? Выберите правильный вариант ответа.',
        answers: [
          'Путем скручивания их в сторону пальцев',
          'Путем растяжки и визуального осмотра',
          'Путем погружения в воду и проверки отсутствия пузырьков воздуха',
          'Путем проведения электрических испытаний',
        ],
        correctAnswers: ['Путем скручивания их в сторону пальцев'],
      },
      {
        code: '64843175',
        text:
          'В каком случае оперативному персоналу разрешается единолично выполнять переключения в электроустановках для предотвращения развития и ликвидации нарушений нормального режима? Выберите правильный вариант ответа.',
        answers: [
          'Разрешается, если порядок оперативного обслуживания объекта электроэнергетики предусматривает наличие одного работника из числа оперативного персонала в смене',
          'Разрешается по наряду-допуску',
          'Не разрешается ни в каком случае',
          'Разрешается по письменному распоряжению руководителя работ',
        ],
        correctAnswers: [
          'Разрешается, если порядок оперативного обслуживания объекта электроэнергетики предусматривает наличие одного работника из числа оперативного персонала в смене',
        ],
      },
      {
        code: '64843176',
        text:
          'В каком случае допускается использовать средства защиты с истекшим сроком годности? Выберите правильный вариант ответа.',
        answers: [
          'Не допускается ни в коем случае',
          'Допускается при отсутствии внешних повреждений',
          'Допускается при согласовании с организацией-изготовителем',
          'Допускается с разрешения непосредственного руководителя',
        ],
        correctAnswers: ['Не допускается ни в коем случае'],
      },
      {
        code: '64843177',
        text:
          'На какой срок и сколько раз может быть продлен наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Не более 15 календарных дней со дня продления и не более чем 2 раза',
          'Не более 3 календарных дней со дня продления и не более чем 2 раза',
          'Не более 15 календарных дней со дня продления и не более чем 1 раз',
          'Не более 30 календарных дней со дня продления и не более чем 1 раз',
        ],
        correctAnswers: [
          'Не более 15 календарных дней со дня продления и не более чем 1 раз',
        ],
      },
    ],
  },
  14: {
    64846: [
      {
        code: '64846000',
        text:
          'На какой срок устанавливается публичный сервитут для использования земельных участков и (или) земель в целях реконструкции, капитального ремонта участков (частей) инженерных сооружений, являющихся линейными объектами? Выберите правильный вариант ответа.',
        answers: [
          'От 10 до 49 лет',
          'На срок реконструкции, капитального ремонта',
          'На срок не более 1 года',
          'На срок не более 10 лет',
        ],
        correctAnswers: ['На срок реконструкции, капитального ремонта'],
      },
      {
        code: '64846001',
        text:
          'Какому административному штрафу могут быть подвергнуты юридические лица за нарушение правил охраны электрических сетей напряжением свыше 1000 В, вызвавшее перерыв в обеспечении потребителей электрической энергией? Выберите правильный вариант ответа.',
        answers: [
          'От десяти тысяч до двадцати тысяч рублей',
          'От двадцати тысяч до тридцати тысяч рублей',
          'От сорока тысяч до пятидесяти тысяч рублей',
          'От пятидесяти тысяч до шестидесяти тысяч рублей',
        ],
        correctAnswers: ['От десяти тысяч до двадцати тысяч рублей'],
      },
      {
        code: '64846002',
        text:
          'В течение какого времени сетевая организация с даты получения документов для заключения договора о возмездном оказании услуг по передаче электрической энергии, обязана их рассмотреть и направить заявителю подписанный сетевой организацией проект договора или мотивированный отказ от его заключения либо протокол разногласий к проекту договора в установленном порядке? Выберите правильный вариант ответа.',
        answers: [
          'В течение 25 дней',
          'В течение 30 дней',
          'В течение 45 дней',
          'В течение 60 дней',
        ],
        correctAnswers: ['В течение 30 дней'],
      },
      {
        code: '64846003',
        text:
          'Каким документом должны быть оформлены работы в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'На основании перечня работ, выполняемых в порядке текущей эксплуатации',
          'По распоряжению',
          'По заданию на производство работы, определяющему содержание, место работы, время ее начала и окончания, условия безопасного проведения, состав бригады и работников, ответственных за безопасное выполнение работы',
          'Любым из перечисленных документов',
        ],
        correctAnswers: ['Любым из перечисленных документов'],
      },
      {
        code: '64846004',
        text:
          'Какое напряжение должны иметь переносные электрические светильники, используемые в помещениях с повышенной опасностью и особо опасных? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 50 В',
          'Не выше 12 В',
          'Не выше 36 В',
          'Не выше 110 В',
        ],
        correctAnswers: ['Не выше 50 В'],
      },
      {
        code: '64846005',
        text:
          'Расследования каких аварий осуществляют собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация? Выберите правильный вариант ответа.',
        answers: [
          'Только в результате которых произошли неправильные действия защитных устройств и (или) систем автоматики',
          'Только в результате которых произошло повреждение основного оборудования электростанции, а также отключение такого оборудования действием автоматических защитных устройств или оперативным персоналом вследствие недопустимых отклонений технологических параметров или ошибочных действий оперативного персонала',
          'Только в результате которых произошло нарушение, приводящее к потере управляемости объекта электроэнергетики (потеря питания собственных нужд, оперативного тока, давления в магистралях сжатого воздуха, систем управления оборудованием) продолжительностью 1 час и более',
          'Выясняет причины возникновения всех перечисленных аварий',
        ],
        correctAnswers: [
          'Выясняет причины возникновения всех перечисленных аварий',
        ],
      },
      {
        code: '64846006',
        text:
          'С какими категориями персонала проводится подготовка по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'Со всеми категориями персонала, за исключением вспомогательного',
          'Со всеми категориями персонала',
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
          'Со всеми категориями персонала, за исключением административно-технического',
        ],
        correctAnswers: [
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
      },
      {
        code: '64846007',
        text:
          'Чем должны отличаться светильники аварийного освещения от светильников рабочего освещения при эксплуатации объектов электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'Видом источника света',
          'Знаками',
          'Степенью защиты',
          'Окраской',
        ],
        correctAnswers: ['Знаками', 'Окраской'],
      },
      {
        code: '64846008',
        text:
          'Кем осуществляется мониторинг риска нарушения работы субъектов электроэнергетики в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Правительством Российской Федерации',
          'Правительством Российской Федерации или уполномоченными им федеральными органами исполнительной власти',
          'Федеральными органами исполнительной власти',
          'Органами исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Федеральными органами исполнительной власти'],
      },
      {
        code: '64846009',
        text:
          'От каких факторов не зависит длительность и объем каждого этапа подготовки работника по соответствующей должности? Выберите правильный вариант ответа.',
        answers: [
          'От уровня профессионального образования работника и технических знаний',
          'От возраста работника',
          'От стажа практической работы по смежным должностям',
          'От занимаемой должности перед допуском к подготовке по новой должности и с учетом технической сложности объекта',
        ],
        correctAnswers: ['От возраста работника'],
      },
      {
        code: '64846010',
        text:
          'На какой персонал распространяются требования специальной подготовки? Выберите правильный вариант ответа.',
        answers: [
          'На ремонтный персонал, связанный с техническим обслуживанием, ремонтом, наладкой и испытанием энергоустановок',
          'На электротехнологический персонал производственных подразделений',
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
          'На административно-технический персонал',
        ],
        correctAnswers: [
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64846011',
        text:
          'Каким образом передаются разрешение на подготовку рабочего места и допуск к работе работнику, выполняющему подготовку рабочего места и допуск бригады к работе? Выберите правильный вариант ответа.',
        answers: [
          'Лично, по телефону, с нарочным или через оперативный персонал промежуточной подстанции',
          'Письменным распоряжением в журнале распоряжений',
          'Приказом по подразделению',
          'Только лично',
        ],
        correctAnswers: [
          'Лично, по телефону, с нарочным или через оперативный персонал промежуточной подстанции',
        ],
      },
      {
        code: '64846012',
        text:
          'С какой периодичностью должны актуализироваться техническая документация, перечни документов, используемых в работе, перечни документов на рабочем месте оперативного, оперативно-ремонтного персонала, исполнительные технологические схемы (чертежи), представляющие графическое представление последовательности основных стадий (операций) технологического процесса, и схемы первичных электрических соединений? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 10 лет',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в 3 года'],
      },
      {
        code: '64846013',
        text:
          'Какие мероприятия должны быть выполнены для первичного фактического приема (подачи) рабочего напряжения и мощности на ЛЭП и новое основное оборудование на вновь построенных, реконструированных, модернизированных, технически перевооруженных объектах электроэнергетики (постановки их под нагрузку или включения в транзит), а также фактического приема (подачи) рабочего напряжения и мощности на новое оборудование на действующих объектах электроэнергетики, в том числе после его замены? Выберите правильный вариант ответа.',
        answers: [
          'Проведены индивидуальные испытания оборудования и функциональные испытания отдельных технологических систем',
          'Проведены пробные пуски генерирующего оборудования',
          'Получено разрешение органа федерального государственного энергетического надзора на допуск в эксплуатацию объектов электроэнергетики',
          'Выполнены мероприятия по вводу ЛЭП, оборудования и устройств объектов электроэнергетики в работу в составе энергосистемы',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '64846014',
        text:
          'В какой срок Ростехнадзор или его территориальный орган, принявшие решение о расследовании причин аварии, уведомляют об этом уполномоченный орган в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 48 часов с момента принятия такого решения',
          'Не позднее 24 часов с момента принятия такого решения',
          'Не позднее 48 часов с момента аварии',
          'Не позднее 48 часов с момента получения информации об аварии',
        ],
        correctAnswers: [
          'Не позднее 48 часов с момента принятия такого решения',
        ],
      },
      {
        code: '64846015',
        text:
          'В течение какого срока в адрес заявителей, за исключением случаев осуществления технологического присоединения по индивидуальному проекту, сетевая организация направляет для подписания заполненный и подписанный ею проект договора? Выберите правильный вариант ответа.',
        answers: [
          'В течение 25 рабочих дней со дня получения заявки',
          'В течение 45 рабочих дней со дня получения заявки',
          'В течение 20 рабочих дней со дня получения заявки',
          'В течение 30 рабочих дней со дня получения заявки',
        ],
        correctAnswers: ['В течение 20 рабочих дней со дня получения заявки'],
      },
      {
        code: '64846016',
        text:
          'Какой документ определяет порядок технологического присоединения энергопринимающих устройств потребителей электрической энергии к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Федеральный закон "Об электроэнергетике"',
          'Федеральный закон "О техническом регулировании"',
          'Постановление Правительства Российской Федерации "Правила технологического присоединения энергопринимающих устройств потребителей"',
          'Постановление Правительства Российской Федерации " Об утверждении правил выдачи разрешений на допуск в эксплуатацию энергопринимающих установок потребителей электрической энергии"',
        ],
        correctAnswers: [
          'Постановление Правительства Российской Федерации "Правила технологического присоединения энергопринимающих устройств потребителей"',
        ],
      },
      {
        code: '64846017',
        text:
          'Какому административному штрафу могут быть подвергнуты юридические лица за повреждение электрических сетей напряжением свыше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'От двадцати тысяч до тридцати тысяч рублей',
          'От тридцати тысяч до сорока тысяч рублей',
          'От сорока пяти тысяч до шестидесяти тысяч рублей',
          'От пятидесяти тысяч до восьмидесяти тысяч рублей',
        ],
        correctAnswers: ['От тридцати тысяч до сорока тысяч рублей'],
      },
      {
        code: '64846018',
        text:
          'Что из перечисленного не входит в технологическую основу функционирования электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Единая национальная (общероссийская) электрическая сеть',
          'Территориальные распределительные сети',
          'Система отношений, связанных с производством и оборотом электроэнергии на оптовом рынке',
          'Единая система оперативно-диспетчерского управления',
        ],
        correctAnswers: [
          'Система отношений, связанных с производством и оборотом электроэнергии на оптовом рынке',
        ],
      },
      {
        code: '64846019',
        text:
          'Какие требования предъявляются к командированному персоналу? Выберите правильный вариант ответа.',
        answers: [
          'По прибытии на место командировки должен пройти вводный и первичный инструктажи по охране труда',
          'Должен иметь удостоверения о проверке знаний норм и правил работы в электроустановках с отметкой о группе электробезопасности, по прибытии на место командировки должен пройти вводный и первичный инструктажи по охране труда',
          'Все перечисленные требования',
          'Должен быть ознакомлен с электрической схемой и особенностями электроустановки, в которой предстоит работать',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '64846020',
        text:
          'Что из перечисленного владелец воздушных линий электропередачи (ВЛ) обязан содержать в исправном состоянии? Выберите правильный вариант ответа.',
        answers: [
          'Постоянные знаки, установленные на опорах (нанесенные на опоры) ВЛ, содержащие в том числе обозначение диспетчерского наименования ВЛ, предупреждающие знаки, знаки, содержащие сведения об охранных зонах ВЛ',
          'Сигнальные знаки на берегах в местах пересечения ВЛ с водными пространствами',
          'Маркировку и устройства светоограждения, установленные на опорах ВЛ',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64846021',
        text:
          'В какой срок после дня получения запроса уполномоченного органа в сфере электроэнергетики собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация направляют копии акта расследования уполномоченному органу в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'В 3-дневный срок',
          'В 10-дневный срок',
          'В месячный срок',
          'В недельный срок',
        ],
        correctAnswers: ['В 10-дневный срок'],
      },
      {
        code: '64846022',
        text:
          'В каких местах из перечисленных должны быть указаны диспетчерские наименования? Выберите 2 варианта ответа.',
        answers: [
          'В перечне объектов диспетчеризации с их распределением по способу управления, утверждаемом диспетчерским центром',
          'В перечне, утверждаемом владельцем объекта электроэнергетики',
          'В технических паспортах сооружений и оборудования',
          'В схемах электрических соединений',
        ],
        correctAnswers: [
          'В перечне объектов диспетчеризации с их распределением по способу управления, утверждаемом диспетчерским центром',
          'В перечне, утверждаемом владельцем объекта электроэнергетики',
        ],
      },
      {
        code: '64846023',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для диспетчерского, оперативного и оперативно-ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Ежемесячно',
          'Ежесменно',
          'Один раз в квартал',
          'Еженедельно',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64846024',
        text:
          'Как оформляется акт расследования причин аварии при несогласии отдельных членов комиссии? Выберите правильный вариант ответа.',
        answers: [
          'Несогласные члены комиссии акт не подписывают',
          'Несогласные члены комиссии подписывают акт с примечанием "не согласен"',
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
          'Несогласные члены комиссии подписывают акт с “особым мнением”, изложенным рядом с их подписью или адресующим к отдельному приложению',
          'Несогласные члены комиссии акт не подписывают и направляют “особое мнение” в Управление государственного энергетического надзора России',
        ],
        correctAnswers: [
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
        ],
      },
      {
        code: '64846025',
        text:
          'Кем утверждается перечень работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Руководителем организации',
          'Ответственным за электрохозяйство организации',
          'Техническим руководителем организации',
        ],
        correctAnswers: ['Руководителем организации'],
      },
      {
        code: '64846026',
        text:
          'Когда может быть снято заземление с провода (грозотроса) на промежуточной опоре при выполнении работ на воздушной линии? Выберите правильный вариант ответа.',
        answers: [
          'По окончании работы на опоре',
          'После полного окончания работ на воздушной линии',
          'После снятия заземления с соседних опор',
        ],
        correctAnswers: ['По окончании работы на опоре'],
      },
      {
        code: '64846027',
        text:
          'Что понимается под аварией на объекте электроэнергетики и (или) энергопринимающей установке? Выберите правильный вариант ответа.',
        answers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ',
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федерального закона "О промышленной безопасности опасных производственных объектов", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ; отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федеральных законов "Об электроэнергетике" и "О теплоснабжении", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
        ],
        correctAnswers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
        ],
      },
      {
        code: '64846028',
        text:
          'Какую информацию не должны содержать предупреждающие знаки, установленные сетевыми организациями с целью маркировки охранной зоны? Выберите правильный вариант ответа.',
        answers: [
          'Информацию о соответствующей сетевой организации',
          'Данные о вышестоящих и контролирующих организаций',
          'Размер охранной зоны',
          'Необходимость соблюдения введенных ограничений',
        ],
        correctAnswers: ['Данные о вышестоящих и контролирующих организаций'],
      },
      {
        code: '64846029',
        text:
          'Какие из перечисленных требований, которые должны выполняться владельцем объекта электроэнергетики, указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Обеспечивать соответствие параметров технологического режима работы указанных ЛЭП и оборудования их допустимым значениям',
          'Обеспечивать исполнение требований, касающихся проверки и обеспечения соответствия оборудования уровням токов короткого замыкания',
          'Обеспечивать актуальность данных о длительно допустимой и аварийно допустимой токовой нагрузке ЛЭП и оборудования, в том числе в зависимости от температуры воздуха и с учетом их технического состояния, определяемого в соответствии с Методикой оценки технического состояния',
        ],
        correctAnswers: [
          'Обеспечивать соответствие параметров технологического режима работы указанных ЛЭП и оборудования их допустимым значениям',
        ],
      },
      {
        code: '64846030',
        text:
          'Каким образом эксплуатирующими организациями определяются линии (участки линий), находящиеся под наведенным напряжением? Выберите правильный вариант ответа.',
        answers: [
          'Путем выполнения измерений, с последующим перерасчетом значений на наибольший рабочий ток влияющей воздушной линии',
          'Расчетным путем',
          'Путем выполнения измерений при наибольшем значении тока влияющей линии',
        ],
        correctAnswers: [
          'Путем выполнения измерений, с последующим перерасчетом значений на наибольший рабочий ток влияющей воздушной линии',
        ],
      },
      {
        code: '64846031',
        text:
          'Какая процедура не устанавливается Правилами технологического присоединения энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, а также объектов электросетевого хозяйства, принадлежащих сетевым организациям и иным лицам, к электрическим сетям, утвержденным постановлением Правительства Российской Федерации от 27 декабря 2004 г. № 861? Выберите правильный вариант ответа.',
        answers: [
          'Процедура присоединения энергопринимающих устройств к электрическим сетям сетевой организации',
          'Нормирование количества потребляемой электроэнергии',
          'Установка требований к выдаче технических условий, в том числе индивидуальных, для присоединения к электрическим сетям',
          'Определение существенных условий договора об осуществлении технологического присоединения к электрическим сетям',
        ],
        correctAnswers: ['Нормирование количества потребляемой электроэнергии'],
      },
      {
        code: '64846032',
        text:
          'Что принимается за начало и конец воздушной линии? Выберите правильный вариант ответа.',
        answers: [
          'Первая и последняя анкерные опоры линии',
          'Первая и последняя промежуточные опоры линии',
          'Линейные порталы или линейные вводы электроустановки, служащей для приема и распределения электроэнергии и содержащей коммутационные аппараты, сборные и соединительные шины, вспомогательные устройства (компрессорные, аккумуляторные), а также устройства защиты, автоматики и измерительные приборы, а для ответвлений - ответвительная опора и линейный портал или линейный ввод распределительного устройства',
          'Шинные порталы электроустановки, служащей для приема и распределения электроэнергии и содержащей коммутационные аппараты, сборные и соединительные шины, вспомогательные устройства (компрессорные, аккумуляторные), а также устройства защиты, автоматики и измерительные приборы',
        ],
        correctAnswers: [
          'Линейные порталы или линейные вводы электроустановки, служащей для приема и распределения электроэнергии и содержащей коммутационные аппараты, сборные и соединительные шины, вспомогательные устройства (компрессорные, аккумуляторные), а также устройства защиты, автоматики и измерительные приборы, а для ответвлений - ответвительная опора и линейный портал или линейный ввод распределительного устройства',
        ],
      },
      {
        code: '64846033',
        text:
          'Какое из перечисленных определений соответствуют термину "реконструкция объектов капитального строительства (за исключением линейных объектов)"? Выберите правильный вариант ответа.',
        answers: [
          'Изменение параметров объекта капитального строительства, его частей (высоты, количества этажей, площади, объема)',
          'Изменение параметров линейных объектов или их участков (частей)',
          'Замена и (или) восстановление строительных конструкций объектов капитального строительства',
          'Создание зданий, строений, сооружений (в том числе на месте сносимых объектов капитального строительства)',
        ],
        correctAnswers: [
          'Изменение параметров объекта капитального строительства, его частей (высоты, количества этажей, площади, объема)',
        ],
      },
      {
        code: '64846034',
        text:
          'В каких случаях из перечисленных не допускается применение экранирующих комплектов для защиты от воздействия электрического поля? Выберите правильный вариант ответа.',
        answers: [
          'Только для работников, непосредственно проводящих испытания оборудования повышенным напряжением',
          'Только при работах, не исключающих возможности прикосновения к находящимся под напряжением до 1000 В токоведущим частям',
          'Во всех перечисленных случаях',
          'Только при электросварочных работах',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '64846035',
        text:
          'Какое административное наказание может быть наложено на юридических лиц за нарушение правил пользования электрической и тепловой энергией? Выберите правильный вариант ответа.',
        answers: [
          'Наложение административного штрафа в размере от пяти до десяти тысяч рублей',
          'Наложение административного штрафа от двадцати до сорока тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
          'Административное приостановление деятельности на срок до ста суток',
        ],
        correctAnswers: [
          'Наложение административного штрафа от двадцати до сорока тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
        ],
      },
      {
        code: '64846036',
        text:
          'В отношении каких объектов и установок на время проведения испытаний и пусконаладочных работ не выдается временное разрешение на допуск в эксплуатацию? Выберите правильный вариант ответа.',
        answers: [
          'Объектов по производству электрической энергии',
          'Энергопринимающих установок, ввод в эксплуатацию которых осуществляется в уведомительном порядке согласно Правил технологического присоединения к электрическим сетям',
          'Энергопринимающих установок, предназначенных для производства испытаний электрооборудования повышенным напряжением',
          'Вновь вводимых в эксплуатацию или реконструированных объектов по производству электрической энергии',
        ],
        correctAnswers: [
          'Энергопринимающих установок, ввод в эксплуатацию которых осуществляется в уведомительном порядке согласно Правил технологического присоединения к электрическим сетям',
        ],
      },
      {
        code: '64846037',
        text:
          'Какие из перечисленных работ не относятся к специальным, право проведения которых должно быть зафиксировано записью в удостоверении? Выберите правильный вариант ответа.',
        answers: [
          'Работы по измерению сопротивления изоляции',
          'Работы, выполняемые на высоте более 5 м от поверхности земли, перекрытия или рабочего настила, над которым производятся работы непосредственно с конструкций или оборудования при их монтаже или ремонте с обязательным применением средств защиты от падения с высоты',
          'Работы без снятия напряжения с электроустановки, выполняемые с прикосновением к первичным токоведущим частям, находящимся под рабочим напряжением',
          'Работы, выполняемые со снятием рабочего напряжения с электроустановки или ее части с прикосновением к токоведущим частям, находящимся под наведенным напряжением более 25 В на рабочем месте',
        ],
        correctAnswers: ['Работы по измерению сопротивления изоляции'],
      },
      {
        code: '64846038',
        text:
          'Что из перечисленного должно быть определено организационно-распорядительным документом владельцем объекта по производству электрической энергии? Выберите правильный вариант ответа.',
        answers: [
          'Организационная структура управления каждым объектом по производству электрической энергии',
          'Организационная структура управления объектами электросетевого хозяйства',
          'Организационная структура управления электрохозяйством потребителя',
        ],
        correctAnswers: [
          'Организационная структура управления каждым объектом по производству электрической энергии',
        ],
      },
      {
        code: '64846039',
        text:
          'В какие сроки проводится проверка знаний работников, относящихся к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала, при подготовке по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'В сроки, установленные программой подготовки по новой должности',
          'Не позднее одного месяца после назначения на должность',
          'Не позднее одной недели после назначения на должность',
          'По истечению трех месяцев после назначения на должность',
        ],
        correctAnswers: [
          'В сроки, установленные программой подготовки по новой должности',
        ],
      },
      {
        code: '64846040',
        text:
          'В каких исключительных случаях осуществляется изъятие земельных участков для государственных или муниципальных нужд в исключительных случаях по основаниям, связанным с выполнением международных договоров Российской Федерации, а также строительством, реконструкцией объектов государственного значения (объектов федерального значения, объектов регионального значения) или объектов местного значения при отсутствии других возможных вариантов строительства, реконструкции этих объектов? Выберите правильный вариант ответа.',
        answers: [
          'По основаниям, связанным с выполнением международных договоров Российской Федерации',
          'По основаниям, связанным со строительством, реконструкцией следующих объектов государственного значения (объектов федерального значения, объектов регионального значения) или объектов местного значения при отсутствии других возможных вариантов строительства, реконструкции этих объектов',
          'По иным основаниями, предусмотренными федеральными законами',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '64846041',
        text:
          'Под наблюдением каких работников должен осуществляться проезд автомобилей, подъемных сооружений и механизмов по территории открытого распределительного устройства и в охранной зоне воздушной линии выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Под наблюдением одного из работников из числа оперативного персонала, работника, выдавшего наряд или ответственного руководителя',
          'Под наблюдением ответственного руководителя или производителя работ, имеющего группу III по электробезопасности',
          'Под наблюдением производителя работ, имеющего группу IV по электробезопасности',
        ],
        correctAnswers: [
          'Под наблюдением одного из работников из числа оперативного персонала, работника, выдавшего наряд или ответственного руководителя',
        ],
      },
      {
        code: '64846042',
        text:
          'Что должна включать техническая эксплуатация объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Ввод в работу в составе энергосистемы новых, реконструированных, модернизированных, технически перевооруженных объектов электроэнергетики в целом, нового (модернизированного) оборудования и устройств объектов электроэнергетики',
          'Использование по функциональному назначению линий электропередачи, оборудования и устройств',
          'Оперативно-технологическое управление объектами электроэнергетики',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64846043',
        text:
          'На какой срок выдается распоряжение на производство работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Не более 5 календарных дней со дня начала работы',
          'Не более 10 календарных дней со дня начала работы',
          'Распоряжение имеет разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
          'На все время проведения работ',
        ],
        correctAnswers: [
          'Распоряжение имеет разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
        ],
      },
      {
        code: '64846044',
        text:
          'Кто проводит целевой инструктаж ответственному руководителю работ? Выберите 2 варианта ответа.',
        answers: [
          'Выдающий наряд-допуск',
          'Допускающий',
          'Специалист по охране труда',
          'Дежурный оперативный персонал',
        ],
        correctAnswers: ['Выдающий наряд-допуск', 'Допускающий'],
      },
      {
        code: '64846045',
        text:
          'К каким производственным объектам в соответствии с Градостроительным законодательством Российской Федерации, относятся линии электропередачи и иные объекты электросетевого хозяйства напряжением 330 кВ? Выберите правильный вариант ответа.',
        answers: [
          'К особо опасным и технически сложным объектам',
          'К уникальным объектам',
          'К объектам средней опасности',
          'К объектам высокой опасности',
        ],
        correctAnswers: ['К особо опасным и технически сложным объектам'],
      },
      {
        code: '64846046',
        text:
          'Когда проводится техническое освидетельствование объекта технического освидетельствования? Выберите 2 варианта ответа.',
        answers: [
          'По истечении срока службы (срока эксплуатации), установленного организацией - изготовителем оборудования',
          '1 раз в 5 лет',
          'Не позднее срока, установленного при проведении последнего технического освидетельствования (периодическое техническое освидетельствование)',
          '1 раз в год',
        ],
        correctAnswers: [
          'По истечении срока службы (срока эксплуатации), установленного организацией - изготовителем оборудования',
          'Не позднее срока, установленного при проведении последнего технического освидетельствования (периодическое техническое освидетельствование)',
        ],
      },
      {
        code: '64846047',
        text:
          'Какую группу по электробезопасности должны иметь водители, крановщики, машинисты, стропальщики, работающие в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Группу I',
          'Группу не ниже II',
          'Группу не ниже III',
          'Группу не ниже IV',
        ],
        correctAnswers: ['Группу не ниже II'],
      },
      {
        code: '64846048',
        text:
          'Кто имеет право на технологическое присоединение построенных ими линий электропередачи к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Только юридические лица',
          'Только физические лица',
          'Только физические лица, зарегистрированные в качестве предпринимателя',
          'Любые лица',
        ],
        correctAnswers: ['Любые лица'],
      },
      {
        code: '64846049',
        text:
          'Какое из перечисленных определений соответствует понятию "безопасность продукции и связанных с ней процессов производства, эксплуатации, хранения, перевозки, реализации и утилизации"? Выберите правильный вариант ответа.',
        answers: [
          'Состояние, при котором отсутствует недопустимый риск, связанный с причинением вреда жизни или здоровью граждан, имуществу физических или юридических лиц, государственному или муниципальному имуществу, окружающей среде, жизни или здоровью животных и растений',
          'Обязательные для исполнения требования и процедуры, устанавливаемые в целях защиты от рисков, возникающих в связи с проникновением, закреплением или распространением вредных организмов, заболеваний, переносчиков болезней или болезнетворных организмов, в том числе в случае переноса или распространения их животными и (или) растениями, с продукцией, грузами, материалами, транспортными средствами, с наличием добавок, загрязняющих веществ, токсинов, вредителей, сорных растений, болезнетворных организмов, в том числе с пищевыми продуктами или кормами',
          'Форма подтверждения соответствия продукции требованиям технических регламентов',
          'Документальное удостоверение соответствия продукции или иных объектов, процессов проектирования (включая изыскания), производства, строительства, монтажа, наладки, эксплуатации, хранения, перевозки, реализации и утилизации, выполнения работ или оказания услуг требованиям технических регламентов, документам по стандартизации или условиям договоров',
        ],
        correctAnswers: [
          'Состояние, при котором отсутствует недопустимый риск, связанный с причинением вреда жизни или здоровью граждан, имуществу физических или юридических лиц, государственному или муниципальному имуществу, окружающей среде, жизни или здоровью животных и растений',
        ],
      },
      {
        code: '64846050',
        text:
          'Кто осуществляет оценку готовности субъектов электроэнергетики к работе в отопительный сезон? Выберите правильный вариант ответа.',
        answers: [
          'Федеральные органы исполнительной власти',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
          'Правительство Российской Федерации',
        ],
        correctAnswers: ['Федеральные органы исполнительной власти'],
      },
      {
        code: '64846051',
        text:
          'Что из перечисленного может являться объектами добровольного подтверждения соответствия? Выберите правильный вариант ответа',
        answers: [
          'Только сертифицированная продукция',
          'Продукция, выпускаемая в обращение на территории Российской Федерации',
          'Продукция, процессы производства, эксплуатации, хранения, перевозки, реализации и утилизации, работы и услуги, а также иные объекты, в отношении которых документами по стандартизации, системами добровольной сертификации и договорами устанавливаются требования',
          'Вся перечисленная продукция',
        ],
        correctAnswers: [
          'Продукция, процессы производства, эксплуатации, хранения, перевозки, реализации и утилизации, работы и услуги, а также иные объекты, в отношении которых документами по стандартизации, системами добровольной сертификации и договорами устанавливаются требования',
        ],
      },
      {
        code: '64846052',
        text:
          'Вывод из эксплуатации каких из перечисленных объектов не осуществляется по согласованию с уполномоченным органом на основании заключения субъекта оперативно-диспетчерского управления о возможности вывода объекта диспетчеризации из эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Линий электропередачи классом напряжения 110 кВ и более',
          'Трансформаторного (автотрансформаторного) оборудования высшим классом номинального напряжения 220 кВ и более',
          'Средств компенсации реактивной мощности напряжением 220 кВ и более',
          'Линий электропередачи классом напряжения менее 110 кВ',
        ],
        correctAnswers: [
          'Линий электропередачи классом напряжения менее 110 кВ',
        ],
      },
      {
        code: '64846053',
        text:
          'Каким образом оформляется наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
          'Допускается оформление наряда-допуска карандашем',
          'Допускается оформление наряда-допуска с использованием копировальной бумаги',
        ],
        correctAnswers: [
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
        ],
      },
      {
        code: '64846054',
        text:
          'На кого возлагаются обязанности по обеспечению безопасных условий и охраны труда? Выберите правильный вариант ответа.',
        answers: [
          'На технического руководителя организации',
          'На работодателя',
          'На руководителя службы охраны труда организации',
          'На руководителей подразделений организации',
        ],
        correctAnswers: ['На работодателя'],
      },
      {
        code: '64846055',
        text:
          'Какой вид диспетчерской заявки для вывода в ремонт объекта диспетчеризации, не предусмотренного сводным месячным графиком ремонта, не соответствует обязательным требованиям? Выберите правильный вариант ответа.',
        answers: [
          'Аварийная диспетчерская заявка',
          'Плановая диспетчерская заявка',
          'Неотложная диспетчерская заявка',
          'Внеплановая диспетчерская заявка',
        ],
        correctAnswers: ['Плановая диспетчерская заявка'],
      },
      {
        code: '64846056',
        text:
          'Кем осуществляется государственное управление охраной труда? Выберите 2 варианта ответа.',
        answers: [
          'Федеральным органом исполнительной власти, осуществляющим функции по выработке и реализации государственной политики и нормативно-правовому регулированию в сфере труда',
          'Министерством труда и социального развития РФ',
          'Правительством Российской Федерации',
          'Министерством здравоохранения РФ и Министерством труда и социального развития РФ',
        ],
        correctAnswers: [
          'Федеральным органом исполнительной власти, осуществляющим функции по выработке и реализации государственной политики и нормативно-правовому регулированию в сфере труда',
          'Правительством Российской Федерации',
        ],
      },
      {
        code: '64846057',
        text:
          'Кто проводит первичный инструктаж командированному персоналу при проведении работ в электроустановках до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала (руководящих работников и специалистов), имеющий группу IV',
          'Работник организации - владельца электроустановок из числа электротехнического персонала, имеющий группу IV',
          'Работник организации - владельца электроустановок из числа оперативно-ремонтного персонала, имеющий группу IV',
          'Работник командирующей организации из числа административно-технического персонала, имеющий группу IV',
        ],
        correctAnswers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала (руководящих работников и специалистов), имеющий группу IV',
        ],
      },
      {
        code: '64846058',
        text:
          'Какую группу по электробезопасности при проведении неотложных работ должен иметь производитель работ или наблюдающий из числа оперативного персонала, соответственно выполняющий работу или осуществляющий надзор за работающими в электроустановках напряжением до1000 В? Выберите правильный вариант ответа.',
        answers: ['Группу IV', 'Группу III', 'Группу V'],
        correctAnswers: ['Группу III'],
      },
      {
        code: '64846059',
        text:
          'Что должен пройти командированный персонал по прибытии на место своей командировки для выполнения работ в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Вводный и первичный инструктажи по охране труда',
          'Целевой инструктаж',
          'Внеплановый инструктаж',
          'Стажировку',
        ],
        correctAnswers: ['Вводный и первичный инструктажи по охране труда'],
      },
      {
        code: '64846060',
        text:
          'Какие организации электроэнергетики должны разработать порядок проведения работы с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Только организации, эксплуатирующие трансформаторные подстанции',
          'Только тепловые электростанции',
          'Только гидроэлектростанции',
          'Все организации электроэнергетики',
        ],
        correctAnswers: ['Все организации электроэнергетики'],
      },
      {
        code: '64846061',
        text:
          'К какому виду ремонтной документации относятся маршрутные, операционные и технологические карты объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'К технической документации',
          'К технологической документации',
          'К организационно-распорядительной документации',
          'К документам, фиксирующим результаты выполнения ремонта составных частей оборудования',
        ],
        correctAnswers: ['К технологической документации'],
      },
      {
        code: '64846062',
        text:
          'При каком условии осуществляется использование земель сельскохозяйственного назначения или земельных участков в составе таких земель, предоставляемых на период осуществления строительства, реконструкции линий электропередачи, линий связи на основании публичного сервитута? Выберите правильный вариант ответа.',
        answers: [
          'При переводе земель сельскохозяйственного назначения в земли иных категорий',
          'При наличии утвержденного проекта рекультивации таких земель для нужд сельского хозяйства без перевода земель сельскохозяйственного назначения в земли иных категорий',
          'Если земельные участки из земель сельскохозяйственного назначения, расположенные на расстоянии не более 30 километров от границ сельских населенных пунктов, не могут использоваться для целей, связанных с нуждами сельского хозяйства',
        ],
        correctAnswers: [
          'При наличии утвержденного проекта рекультивации таких земель для нужд сельского хозяйства без перевода земель сельскохозяйственного назначения в земли иных категорий',
        ],
      },
      {
        code: '64846063',
        text:
          'В каком случае допускается совмещение наблюдающим надзора с выполнением какой-либо работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Допускается в случае работы на нескольких рабочих местах, находящихся в прямой видимости',
          'Допускается в случае электроустановок до 1000 В',
          'Совмещение не допускается',
          'Допускается в любом случае',
        ],
        correctAnswers: ['Совмещение не допускается'],
      },
      {
        code: '64846064',
        text:
          'Что из перечисленного вправе инициировать диспетчерский центр в отношении дежурного работника субъекта электроэнергетики (потребителя электрической энергии) при невыполнении диспетчерской команды? Выберите правильный вариант ответа.',
        answers: [
          'Временное отстранение дежурного работника субъекта электроэнергетики (потребителя электрической энергии) от исполнения своих обязанностей',
          'Отстранение дежурного работника субъекта электроэнергетики (потребителя электрической энергии) от исполнения своих обязанностей с последующим понижением в должности',
          'Увольнение дежурного работника субъекта электроэнергетики (потребителя электрической энергии)',
        ],
        correctAnswers: [
          'Временное отстранение дежурного работника субъекта электроэнергетики (потребителя электрической энергии) от исполнения своих обязанностей',
        ],
      },
      {
        code: '64846065',
        text:
          'Какие из перечисленных требований к руководителю контрольной тренировки в организации электроэнергетики указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Руководителем контрольной тренировки является должностное лицо из числа административно-технического персонала, определенное организацией электроэнергетики',
          'Руководитель тренировки является ответственным за подготовку и проведение контрольной тренировки',
          'Руководитель тренировки вправе наряду с функциями руководителя тренировки возложить на себя функции контролирующего лица или посредника',
          'Руководителем контрольной тренировки является руководитель предприятия',
        ],
        correctAnswers: [
          'Руководителем контрольной тренировки является руководитель предприятия',
        ],
      },
      {
        code: '64846066',
        text:
          'Кем должен быть сформирован состав ремонтной документации при новом строительстве, техническом перевооружении и реконструкции объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Изготовителем оборудования',
          'Экспертной организацией',
          'Ремонтным персоналом',
          'Субъектом электроэнергетики',
        ],
        correctAnswers: ['Субъектом электроэнергетики'],
      },
      {
        code: '64846067',
        text:
          'Какие мероприятия выполняются, если в процессе подготовки рабочего места по наряду-допуску возникают сомнения в достаточности и правильности мер по подготовке рабочего места и возможности безопасного выполнения работ? Выберите правильный вариант ответа.',
        answers: [
          'Подготовка рабочих мест должна быть прекращена, в наряд-допуск вносятся необходимые дополнения',
          'Подготовка рабочих мест приостанавливается, лица, ответственные за безопасность работы, выполняют необходимые технические мероприятия, устраняющие возникшие сомнения, после чего подготовка продолжается',
          'Подготовка рабочих мест должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда-допуска, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
          'Подготовка рабочих мест приостанавливается и выдается новый наряд-допуск',
        ],
        correctAnswers: [
          'Подготовка рабочих мест должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда-допуска, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
        ],
      },
      {
        code: '64846068',
        text:
          'На какой срок выдается разрешение на использование земель или земельного участка, находящихся в государственной или муниципальной собственности, в целях проведения инженерных изысканий либо капитального или текущего ремонта линейного объекта? Выберите правильный вариант ответа.',
        answers: [
          'На срок не более 5 лет',
          'На срок не более 10 лет',
          'На срок не более 1 года',
          'На срок не более 3 лет',
        ],
        correctAnswers: ['На срок не более 1 года'],
      },
      {
        code: '64846069',
        text:
          'Какой персонал допускается к работам с кислотой, щелочью и свинцом? Выберите правильный вариант ответа.',
        answers: [
          'Лица, назначенные приказом по предприятию',
          'Специально обученные работники, имеющие группу III по электробезопасности',
          'Лица, назначенные распоряжением по предприятию для обслуживания аккумуляторных батарей, имеющие группу II по электробезопасности',
          'Оперативно-ремонтный персонал предприятия',
        ],
        correctAnswers: [
          'Специально обученные работники, имеющие группу III по электробезопасности',
        ],
      },
      {
        code: '64846070',
        text:
          'Чем определяется правовой режим земельных участков, равно как всего, что находится над и под поверхностью земельных участков и используется в процессе их застройки и последующей эксплуатации объектов капитального строительства? Выберите правильный вариант ответа.',
        answers: [
          'Градостроительным зонированием',
          'Нормативами градостроительного проектирования',
          'Документацией по планировке территории',
          'Градостроительным регламентом',
        ],
        correctAnswers: ['Градостроительным регламентом'],
      },
      {
        code: '64846071',
        text:
          'Какое из перечисленных определений соответствует понятию "средства коллективной защиты"? Выберите правильный вариант ответа.',
        answers: [
          'Средство защиты, используемое одним человеком',
          'Технические средства защиты работников, конструктивно и (или) функционально связанные с производственным оборудованием, производственным процессом, производственным зданием (помещением), производственной площадкой, производственной зоной, рабочим местом (рабочими местами) и используемые для предотвращения или уменьшения воздействия на работников вредных и (или) опасных производственных факторов',
          'Средство, используемое для предотвращения или уменьшения воздействия на работника вредных и (или) опасных производственных факторов, особых температурных условий, а также для защиты от загрязнения',
        ],
        correctAnswers: [
          'Технические средства защиты работников, конструктивно и (или) функционально связанные с производственным оборудованием, производственным процессом, производственным зданием (помещением), производственной площадкой, производственной зоной, рабочим местом (рабочими местами) и используемые для предотвращения или уменьшения воздействия на работников вредных и (или) опасных производственных факторов',
        ],
      },
      {
        code: '64846072',
        text:
          'В соответствии с каким законодательством осуществляется доступ к объектам электросетевого хозяйства для их эксплуатации и плановых (регламентных) работ? Выберите 2 варианта ответа.',
        answers: [
          'С гражданским законодательством',
          'С земельным законодательством',
          'С градостроительным законодательством',
          'С трудовым законодательством',
        ],
        correctAnswers: [
          'С гражданским законодательством',
          'С земельным законодательством',
        ],
      },
      {
        code: '64846073',
        text:
          'Каким образом осуществляется использование лесов для строительства, реконструкции, эксплуатации линейных объектов? Выберите правильный вариант ответа.',
        answers: [
          'Только на правах безвозмездного срочного пользования лесными участками',
          'Только на правах аренды лесных участков',
          'Без предоставления лесных участков в пользование',
          'Только на правах постоянного (бессрочного) пользования лесными участками',
        ],
        correctAnswers: ['Без предоставления лесных участков в пользование'],
      },
      {
        code: '64846074',
        text:
          'Каким образом устанавливается продолжительность дублирования конкретного работника? Выберите правильный вариант ответа.',
        answers: [
          'Определяется руководителем структурного подразделения',
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
          'Органами Ростехнадзора',
          'Определяется комиссией по проверке знаний в зависимости от его уровня профессиональной подготовки, стажа и опыта оперативной работы',
        ],
        correctAnswers: [
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
        ],
      },
      {
        code: '64846075',
        text:
          'В соответствии с чем осуществляется образование земельных участков для строительства, реконструкции линейных объектов федерального, регионального или местного значения? Выберите правильный вариант ответа.',
        answers: [
          'С согласием землевладельцев',
          'С утвержденным проектом межевания территории',
          'С проектной документацией земельных участков',
          'С кадастровым планом территории',
        ],
        correctAnswers: ['С утвержденным проектом межевания территории'],
      },
      {
        code: '64846076',
        text:
          'В какие сроки проводится первичная проверка знаний работников, относящихся к категории административно-технического персонала или вспомогательного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее одного месяца после назначения на должность',
          'Не позднее одной недели после назначения на должность',
          'По истечению трех месяцев после назначения на должность',
        ],
        correctAnswers: [
          'Не позднее одного месяца после назначения на должность',
        ],
      },
      {
        code: '64846077',
        text:
          'Какая установлена продолжительность принятого вида организации ремонта объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 12 лет',
          'Не менее 10 лет',
          'Не менее 5 лет',
          'Не менее 2 лет',
        ],
        correctAnswers: ['Не менее 12 лет'],
      },
      {
        code: '64846078',
        text:
          'Что из перечисленного не является основанием для проведения работ в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Наряд-допуск',
          'Указание вышестоящей организации',
          'Распоряжение',
          'Перечень работ, выполняемых в порядке текущей эксплуатации',
        ],
        correctAnswers: ['Указание вышестоящей организации'],
      },
      {
        code: '64846079',
        text:
          'Какие из перечисленных обязанностей владельца воздушных линий электропередачи (ВЛ) указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Для каждой ВЛ владелец ВЛ должен обеспечить разработку и поддержание в актуальном состоянии паспорта ВЛ',
          'Владельцем ВЛ должен осуществляться учет воздействия опасных природных (метеорологических) явлений и внешних условий эксплуатации ВЛ, в том числе ветрового давления, толщины стенки гололеда, температуры воздуха, степени агрессивного воздействия окружающей среды, интенсивности грозовой деятельности, пляски проводов и тросов, вибрации',
          'При организации эксплуатации ВЛ владелец ВЛ должен определить расположение производственных баз и линейных участков, обслуживающих ВЛ',
          'Владелец ВЛ должен в случае отключения ВЛ при возникновении короткого замыкания определять место повреждения ВЛ расчетным способом или с использованием приборов, предназначенных для определения места повреждения на ВЛ',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '64846080',
        text:
          'В течение какого времени со дня утверждения комиссией акта расследования материалы расследования причин аварии подлежат хранению Ростехнадзором? Выберите правильный вариант ответа.',
        answers: [
          'Не менее года',
          'Не менее пяти лет',
          'Не менее трех лет',
          'Не менее десяти лет',
        ],
        correctAnswers: ['Не менее трех лет'],
      },
      {
        code: '64846081',
        text:
          'Какие из перечисленных несчастных случаев не подлежат расследованию и учету? Выберите правильный вариант ответа.',
        answers: [
          'При работе вахтовым методом во время междусменного отдыха',
          'При следовании к месту выполнения работы или с работы',
          'В течение рабочего времени на территории работодателя либо в ином месте выполнения работы',
          'При осуществлении иных правомерных действий, обусловленных трудовыми отношениями с работодателем либо совершаемых в его интересах',
        ],
        correctAnswers: [
          'При следовании к месту выполнения работы или с работы',
        ],
      },
      {
        code: '64846082',
        text:
          'Кем устанавливаются правила использования лесов для строительства, реконструкции, эксплуатации линейных объектов и перечень случаев использования лесов в целях обеспечения безопасности граждан и создания необходимых условий для эксплуатации линейных объектов без предоставления лесного участка? Выберите правильный вариант ответа.',
        answers: [
          'Уполномоченные органы местного самоуправления',
          'Уполномоченным федеральным органом исполнительной власти',
          'Органы государственной власти',
        ],
        correctAnswers: [
          'Уполномоченным федеральным органом исполнительной власти',
        ],
      },
      {
        code: '64846083',
        text:
          'Кто имеет право включать электроустановки после полного окончания работ? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
          'Допускающий',
          'Наблюдающий',
          'Производитель работ',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
        ],
      },
      {
        code: '64846084',
        text:
          'С какой периодичностью должны составляться и утверждаться годовые графики проведения учебных тренировок и программы проведения учебной тренировки? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 30 января планируемого года',
          'Не позднее 10 января планируемого года',
          'Не позднее 25 декабря года, предшествующего планируемому',
          'Не позднее 31 декабря года, предшествующего планируемому',
        ],
        correctAnswers: [
          'Не позднее 25 декабря года, предшествующего планируемому',
        ],
      },
      {
        code: '64846085',
        text:
          'При каких погодных условиях переключения в электроустановках, не связанные с предотвращением развития и ликвидацией нарушения нормального режима, не допускается производить в ОРУ? Выберите правильный вариант ответа.',
        answers: [
          'При резких (в течение суток) колебаниях температуры окружающего воздуха (более 15 °C) с переходом через 0 °C и определенных в местных инструкциях по производству переключений',
          'При грозе',
          'При скорости ветра 15 м/с',
          'При температуре окружающего воздуха ниже -20 °С',
        ],
        correctAnswers: ['При грозе'],
      },
      {
        code: '64846086',
        text:
          'В каком случае диспетчерские команды не подлежат исполнению субъектами электроэнергетики или потребителями электрической энергии? Выберите правильный вариант ответа.',
        answers: [
          'Если их исполнение приводит к нарушению требований производственных инструкций',
          'Если их исполнение создает угрозу жизни людей',
          'Если их исполнение приводит к нарушению инструкций по эксплуатации оборудования',
          'В любом перечисленном случае',
        ],
        correctAnswers: ['Если их исполнение создает угрозу жизни людей'],
      },
      {
        code: '64846087',
        text:
          'В каком документе оформляется допуск к работам по распоряжению? Выберите правильный вариант ответа.',
        answers: [
          'В журнале выдачи распоряжений',
          'В журнале учета работ по нарядам-допускам и распоряжениям',
          'В журнале регистрации нарядов и распоряжений',
          'Допуск к работам оформляется только в самом распоряжении',
        ],
        correctAnswers: [
          'В журнале учета работ по нарядам-допускам и распоряжениям',
        ],
      },
      {
        code: '64846088',
        text:
          'Какое из перечисленных определений соответствует понятию "объекты электросетевого хозяйства"? Выберите правильный вариант ответа.',
        answers: [
          'Линии электропередачи, трансформаторные и иные подстанции, распределительные пункты и иное предназначенное для обеспечения электрических связей и осуществления передачи электрической энергии оборудование',
          'Имущественные объекты, непосредственно используемые в процессе производства, передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике и сбыта электрической энергии, в том числе объекты электросетевого хозяйства',
          'Объект по производству электрической энергии, принадлежащий на праве собственности или ином законном основании потребителю электрической энергии, энергопринимающие устройства которого технологически присоединены к объектам электросетевого хозяйства с уровнем напряжения до 1000 вольт, функционирующий в том числе на основе использования возобновляемых источников энергии и используемый указанным потребителем для производства электрической энергии в целях удовлетворения собственных бытовых и (или) производственных нужд',
        ],
        correctAnswers: [
          'Линии электропередачи, трансформаторные и иные подстанции, распределительные пункты и иное предназначенное для обеспечения электрических связей и осуществления передачи электрической энергии оборудование',
        ],
      },
      {
        code: '64846089',
        text:
          'Какие из перечисленных контрольных (надзорных) мероприятий проводятся при осуществлении федерального государственного энергетического надзора в сфере электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'Информирование',
          'Выездная проверка',
          'Обобщение правоприменительной практики',
          'Документарная проверка',
        ],
        correctAnswers: ['Выездная проверка', 'Документарная проверка'],
      },
      {
        code: '64846090',
        text:
          'Кто осуществляет допуск к работам на кабельных линиях, расположенных в распределительном устройстве, если распределительное устройство и кабельные линии принадлежат разным организациям? Выберите правильный вариант ответа.',
        answers: [
          'Персонал, обслуживающий кабельные линии',
          'Персонал, обслуживающий распределительное устройство',
          'Персонал, обслуживающий распределительное устройство или кабельные линии',
          'Персонал, обслуживающий распределительное устройство и кабельные линии',
        ],
        correctAnswers: [
          'Персонал, обслуживающий распределительное устройство',
        ],
      },
      {
        code: '64846091',
        text:
          'За сколько дней до начала планового ремонта субъектам электроэнергетики необходимо обеспечивать поставку оборудования, запасных частей и материалов и проведение их входного контроля до начала ремонта? Выберите правильный вариант ответа.',
        answers: ['За 5 дней', 'За 15 дней', 'За 20 дней', 'За 7 дней'],
        correctAnswers: ['За 20 дней'],
      },
      {
        code: '64846092',
        text:
          'Какую информацию не должны содержать предложения о выводе в ремонт линий электропередачи, оборудования и устройств, отнесенных к объектам диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'О диспетчерском наименовании выводимого в ремонт объекта диспетчеризации',
          'О составе ремонтной бригады',
          'О виде ремонта (технического обслуживания)',
          'О объемах основных планируемых работ',
        ],
        correctAnswers: ['О составе ремонтной бригады'],
      },
      {
        code: '64846093',
        text:
          'Для выполнения каких действий выдается временное разрешение на допуск в эксплуатацию? Выберите правильный вариант ответа.',
        answers: [
          'Для проведения пробных пусков и комплексных испытаний, включая комплексное опробование, генерирующего оборудования объектов по производству электрической энергии',
          'Для энергопринимающих установок, ввод в эксплуатацию которых осуществляется в уведомительном порядке',
          'Для оборудования, работающего под избыточным давлением, ввод в эксплуатацию которого осуществляется в соответствии с законодательством в области промышленной безопасности',
        ],
        correctAnswers: [
          'Для проведения пробных пусков и комплексных испытаний, включая комплексное опробование, генерирующего оборудования объектов по производству электрической энергии',
        ],
      },
      {
        code: '64846094',
        text:
          'Какие сведения указываются при регистрации каждой диспетчерской команды (разрешения) диспетчерским центром? Выберите правильный вариант ответа.',
        answers: [
          'Фамилия, инициалы и должность лица, давшего команду; фамилия, инициалы и должность лица, которому адресована команда; содержание команды; время, когда дается команда; наличие подтверждения получения команды',
          'Фамилия, инициалы и должность лица, давшего команду; фамилия, инициалы и должность лица, которому адресована команда; содержание команды; время, когда дается команда; требуемое время исполнения команды',
          'Фамилия лица, отдавшего команду; фамилия лица, которому адресована команда; содержание команды; время выдачи (получения) команды; требуемое время исполнения команды; другая информация по решению субъекта оперативно-диспетчерского управления',
        ],
        correctAnswers: [
          'Фамилия лица, отдавшего команду; фамилия лица, которому адресована команда; содержание команды; время выдачи (получения) команды; требуемое время исполнения команды; другая информация по решению субъекта оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64846095',
        text:
          'При каком условии заключается соглашение об установлении сервитута в отношении земельного участка, находящегося в государственной или муниципальной собственности, в случаях размещения линейных объектов, сооружений связи, специальных информационных знаков и защитных сооружений? Выберите правильный вариант ответа.',
        answers: [
          'Если размещение линейных объектов, сооружений связи, специальных информационных знаков и защитных сооружений, не препятствующих разрешенному использованию земельного участка',
          'Если находящийся в государственной или муниципальной собственности земельный участок предоставлен в постоянное (бессрочное) пользование',
          'Если срок действия соглашения об установлении сервитута больше 3 лет',
        ],
        correctAnswers: [
          'Если размещение линейных объектов, сооружений связи, специальных информационных знаков и защитных сооружений, не препятствующих разрешенному использованию земельного участка',
        ],
      },
      {
        code: '64846096',
        text:
          'В каком случае выдается временное разрешение на допуск в эксплуатацию энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, объектов электросетевого хозяйства? Выберите правильный вариант ответа.',
        answers: [
          'Для пробных пусков и комплексных испытаний, включая комплексное опробование, генерирующего оборудования объектов по производству электрической энергии',
          'Для ввода в эксплуатацию энергопринимающих установок, ввод в эксплуатацию которых осуществляется в уведомительном порядке',
          'Для ввода в эксплуатацию оборудования, работающего под избыточным давлением, ввод в эксплуатацию которого осуществляется в соответствии с законодательством в области промышленной безопасности',
          'Для всего перечисленного',
        ],
        correctAnswers: [
          'Для пробных пусков и комплексных испытаний, включая комплексное опробование, генерирующего оборудования объектов по производству электрической энергии',
        ],
      },
      {
        code: '64846097',
        text:
          'Кто устанавливает порядок технологического присоединения энергопринимающих устройств юридических и физических лиц к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '64846098',
        text:
          'Какие из перечисленных требований к владельцу объекта электроэнергетики указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Обеспечивать соответствие параметров технологического режима работы указанных ЛЭП и оборудования их допустимым значениям',
          'Обеспечивать исполнение требований, касающихся проверки и обеспечения соответствия оборудования уровням токов короткого замыкания',
          'Обеспечивать актуальность данных о длительно допустимой и аварийно допустимой токовой нагрузке ЛЭП и оборудования, в том числе в зависимости от температуры воздуха и с учетом их технического состояния, определяемого в соответствии с Методикой оценки технического состояния',
        ],
        correctAnswers: [
          'Обеспечивать соответствие параметров технологического режима работы указанных ЛЭП и оборудования их допустимым значениям',
        ],
      },
      {
        code: '64846099',
        text:
          'Какие из перечисленных методов могут использоваться при проведении противоаварийных тренировок в организациях электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'По схемам',
          'С условными действиями персонала',
          'С использованием технических средств',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '64846100',
        text:
          'Какого вида ремонта объектов электроэнергетики в зависимости от планирования не существует? Выберите правильный вариант ответа.',
        answers: ['Текущего', 'Внепланового', 'Среднего', 'Капитального'],
        correctAnswers: ['Внепланового'],
      },
      {
        code: '64846101',
        text:
          'Какие отключения оборудования объекта электросетевого хозяйства, приводящие к снижению надежности энергосистемы, не расследуются Ростехнадзором либо его территориальными органами? Выберите правильный вариант ответа.',
        answers: [
          'Разделение энергосистемы на части, выделение отдельных энергорайонов Российской Федерации на изолированную от Единой энергетической системы России работу (при отключении всех электрических связей с Единой энергетической системой России)',
          'Превышение максимально допустимых перетоков мощности в контролируемом сечении длительностью 1 час и более',
          'Применение графиков временных отключений суммарным объемом 100 МВт и более или прекращение электроснабжения на величину 25 и более процентов общего объема потребления в операционной зоне диспетчерского центра',
          'Все указанные отключения расследуются Ростехнадзором либо его территориальными органами',
        ],
        correctAnswers: [
          'Все указанные отключения расследуются Ростехнадзором либо его территориальными органами',
        ],
      },
      {
        code: '64846102',
        text:
          'Чем должны отличаться светильники аварийного освещения от светильников рабочего освещения? Выберите правильный вариант ответа.',
        answers: [
          'Видом источника света',
          'Знаками или окраской',
          'Степенью защиты',
          'Принципиальных отличий нет',
        ],
        correctAnswers: ['Знаками или окраской'],
      },
      {
        code: '64846103',
        text:
          'В какой срок комиссия по расследованию причин аварии уведомляет субъект электроэнергетики и (или) потребителя электрической энергии о начале обследования? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее чем за 2 часа',
          'Не позднее чем за 3 часа',
          'Не позднее чем за 18 часов',
          'Не позднее чем за 24 часа',
        ],
        correctAnswers: ['Не позднее чем за 3 часа'],
      },
      {
        code: '64846104',
        text:
          'Кто утверждает распорядительный документ сетевой организации, предоставляющий оперативному персоналу ЦУС право ведения оперативных переговоров и производства переключений в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Технический руководитель сетевой организации',
          'Руководитель сетевой организации',
          'Руководитель ЦУС',
          'Владелец объекта электроэнергетики',
        ],
        correctAnswers: ['Технический руководитель сетевой организации'],
      },
      {
        code: '64846105',
        text:
          'Какие из перечисленных вариантов комбинированных методов тренировки могут использоваться по решению ее руководителя при проведении противоаварийных тренировок в организациях электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'По схемам',
          'С условными действиями персонала',
          'С использованием технических средств',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '64846106',
        text:
          'Какое из перечисленных понятий соответствует определению "фактор производственной среды или трудового процесса, воздействие которого может привести к травме или смерти работника"? Выберите правильный вариант ответа.',
        answers: [
          'Вредный производственный фактор',
          'Опасный производственный фактор',
          'Опасность',
          'Профессиональный риск',
        ],
        correctAnswers: ['Опасный производственный фактор'],
      },
      {
        code: '64846107',
        text:
          'Кто является ответственным за безопасное ведение работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только допускающий',
          'Только ответственный руководитель работ',
          'Только наблюдающий',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
        correctAnswers: [
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
      },
      {
        code: '64846108',
        text:
          'Каким путем обеспечивается надежность электроснабжения собственных нужд переменного и постоянного тока электростанций и подстанций в нормальных, ремонтных и аварийных режимах при изменении технологического режима работы и эксплуатационного состояния ЛЭП и оборудования? Выберите правильный вариант ответа.',
        answers: [
          'Только секционированием шин',
          'Только распределением механизмов собственных нужд по секциям шин из условия минимального нарушения работы электростанции или подстанции в случае выхода из строя любой секции',
          'Только автоматическим вводом резервного питания любой секции шин собственных нужд всех напряжений',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '64846109',
        text:
          'Какие права предоставляются командированному персоналу привлекаемой организации? Выберите правильный вариант ответа.',
        answers: [
          'Право работы в действующих электроустановках в качестве выдающих наряд-допуск, ответственных руководителей и производителей работ, членов бригады',
          'Право работы в действующих электроустановках только в качестве выдающих наряд-допуск, ответственных руководителей и производителей работ',
          'Право работы в действующих электроустановках только в качестве членов бригады',
          'Право работы в действующих электроустановках только в качестве допускающих на воздушной линии',
        ],
        correctAnswers: [
          'Право работы в действующих электроустановках в качестве выдающих наряд-допуск, ответственных руководителей и производителей работ, членов бригады',
        ],
      },
      {
        code: '64846110',
        text:
          'У кого могут быть на учете ключи от электроустановок, не имеющих местного оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'У административно-технического персонала',
          'У специалистов по охране труда организации',
          'У ремонтного персонала, выполняющего работы в электроустановках',
        ],
        correctAnswers: ['У административно-технического персонала'],
      },
      {
        code: '64846111',
        text:
          'Как определяются работы, проводимые в рамках технического освидетельствования? Выберите правильный вариант ответа.',
        answers: [
          'Проведение выборочного наружного и внутреннего осмотра',
          'Проведение анализа результата обследований',
          'Проведение анализа результатов расчетов индексов технического состояния функциональных узлов',
          'Проведение оценки остаточного ресурса металла оборудования',
          'Всего перечисленного',
        ],
        correctAnswers: ['Всего перечисленного'],
      },
      {
        code: '64846112',
        text:
          'Какое из перечисленных определений соответствует понятию "Единая энергетическая система России"? Выберите правильный вариант ответа.',
        answers: [
          'Отрасль экономики Российской Федерации, включающая в себя комплекс экономических отношений, возникающих в процессе производства (в том числе производства в режиме комбинированной выработки электрической и тепловой энергии), передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике, сбыта и потребления электрической энергии с использованием производственных и иных имущественных объектов',
          'Совокупность объектов электроэнергетики и энергопринимающих установок потребителей электрической энергии, связанных общим режимом работы в едином технологическом процессе производства, передачи и потребления электрической энергии в условиях централизованного оперативно-диспетчерского управления в электроэнергетике;',
          'Электроэнергетическая система, расположенная в пределах территории Российской Федерации, в состав которой входят объединенные электроэнергетические системы и образующие их территориальные электроэнергетические системы и централизованное оперативно-диспетчерское управление которой осуществляется системным оператором электроэнергетических систем России',
          'Коммерческая организация, которая оказывает услуги по передаче электрической энергии с использованием объектов электросетевого хозяйства или части указанных объектов',
        ],
        correctAnswers: [
          'Электроэнергетическая система, расположенная в пределах территории Российской Федерации, в состав которой входят объединенные электроэнергетические системы и образующие их территориальные электроэнергетические системы и централизованное оперативно-диспетчерское управление которой осуществляется системным оператором электроэнергетических систем России',
        ],
      },
      {
        code: '64846113',
        text:
          'Какое требование безопасности при работе под напряжением в электроустановках напряжением до 1000 В указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Оградить расположенные вблизи рабочего места другие токоведущие части, находящиеся под напряжением, к которым возможно случайное прикосновение',
          'Работать в диэлектрических галошах или стоя на изолирующей подставке либо на резиновом диэлектрическом ковре',
          'Применять изолированный инструмент или пользоваться диэлектрическими перчатками',
          'В исключительных случаях с разрешения ответственного руководителя допускается использовать слесарный инструмент типа ножовки и напильника',
        ],
        correctAnswers: [
          'В исключительных случаях с разрешения ответственного руководителя допускается использовать слесарный инструмент типа ножовки и напильника',
        ],
      },
      {
        code: '64846114',
        text:
          'В каком случае действует особый порядок оперативно-диспетчерского управления в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'При производстве в режиме комбинированной выработки электрической и тепловой энергии',
          'При возникновении или угрозе возникновения аварийных электроэнергетических режимов (режимов, которые характеризуются параметрами, выходящими за допустимые пределы, определяемые в соответствии с обязательными требованиями)',
          'При управлении изменением режима потребления электрической энергии',
        ],
        correctAnswers: [
          'При возникновении или угрозе возникновения аварийных электроэнергетических режимов (режимов, которые характеризуются параметрами, выходящими за допустимые пределы, определяемые в соответствии с обязательными требованиями)',
        ],
      },
      {
        code: '64846115',
        text:
          'В распределительных электрических сетях каким напряжением должны быть организованы измерения нагрузок и напряжений трансформаторов в период максимальных и минимальных нагрузок в сроки и с периодичностью, установленными техническим руководителем? Выберите правильный вариант ответа.',
        answers: [
          'До 20 кВ включительно',
          'До 10 кВ включительно',
          'До 5 кВ включительно',
          'До 15 кВ включительно',
        ],
        correctAnswers: ['До 20 кВ включительно'],
      },
      {
        code: '64846116',
        text:
          'В каком документе регистрируется первичный и ежедневные допуски к работе по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'В журнале учета работ по нарядам-допускам и распоряжениям',
          'В оперативном журнале',
          'В журнале дефектов и неполадок на электрооборудовании',
          'В журнале учета электрооборудования',
        ],
        correctAnswers: ['В оперативном журнале'],
      },
      {
        code: '64846117',
        text:
          'В каких случаях допускается вывод из работы технологических защит оборудования объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Только в случае работы оборудования в переходных режимах, когда необходимость отключения защиты определена инструкцией по эксплуатации основного оборудования',
          'Только в случае очевидной неисправности защиты',
          'Только в случае необходимости периодического опробования защиты, если оно производится на действующем оборудовании',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '64846118',
        text:
          'Какое минимальное количество членов комиссии организации по проверке знаний, включая председателя (заместителя председателя), должно присутствовать при проведении процедуры проверки знаний работников организаций электроэнергетики? Выберите правильный вариант ответа.',
        answers: ['5', '3', '7', '2'],
        correctAnswers: ['3'],
      },
      {
        code: '64846119',
        text:
          'Когда должна отключаться приточно-вытяжная вентиляция в аккумуляторных помещениях? Выберите правильный вариант ответа.',
        answers: [
          'Не ранее чем через 1,5 часа после окончания заряда',
          'Не ранее чем через час после окончания заряда',
          'Не ранее чем через полчаса после окончания заряда',
          'Сразу после окончания заряда',
        ],
        correctAnswers: ['Не ранее чем через 1,5 часа после окончания заряда'],
      },
      {
        code: '64846120',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа ремонтного персонала, имеющий группу не ниже III',
          'Работник из числа административно-технического персонала, имеющий группу IV',
          'Работник, имеющий группу III и право единоличного осмотра на основании письменного распоряжения руководителя организации',
          'Работник из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работник из числа административно-технического персонала (руководящие работники и специалисты), на которого возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу IV и право единоличного осмотра на основании организационно-распорядительных документов организации',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работник из числа административно-технического персонала (руководящие работники и специалисты), на которого возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу IV и право единоличного осмотра на основании организационно-распорядительных документов организации',
        ],
      },
      {
        code: '64846121',
        text:
          'Кто имеет право устанавливать переносные заземления в электроустановках напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Два работника с группой III из числа оперативного персонала',
          'Два работника, один с группой IV по электробезопасности, другой с группой III по электробезопасности',
          'Один работник с группой IV по электробезопасности из числа оперативного персонала',
          'Два работника, один с группой IV по электробезопасности из числа оперативного персонала, другой с группой III по электробезопасности',
        ],
        correctAnswers: [
          'Два работника, один с группой IV по электробезопасности из числа оперативного персонала, другой с группой III по электробезопасности',
        ],
      },
      {
        code: '64846122',
        text:
          'Что из перечисленного соответствует понятию "средство индивидуальной защиты"? Выберите правильный вариант ответа.',
        answers: [
          'Средство защиты, используемое одним человеком',
          'Технические средства защиты работников, конструктивно и (или) функционально связанные с производственным оборудованием, производственным процессом, производственным зданием (помещением), производственной площадкой, производственной зоной, рабочим местом (рабочими местами) и используемые для предотвращения или уменьшения воздействия на работников вредных и (или) опасных производственных факторов',
          'Средство, используемое для предотвращения или уменьшения воздействия на работника вредных и (или) опасных производственных факторов, особых температурных условий, а также для защиты от загрязнения',
        ],
        correctAnswers: [
          'Средство, используемое для предотвращения или уменьшения воздействия на работника вредных и (или) опасных производственных факторов, особых температурных условий, а также для защиты от загрязнения',
        ],
      },
      {
        code: '64846123',
        text:
          'Кто несет ответственность за работу с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель подразделения, ответственный за работу с кадрами',
          'Главный инженер организации',
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права',
          'Руководитель административно-хозяйственного отдела',
        ],
        correctAnswers: [
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права',
        ],
      },
      {
        code: '64846124',
        text:
          'Какое из перечисленных определений соответствуют термину "капитальный ремонт линейных объектов"? Выберите правильный вариант ответа.',
        answers: [
          'Изменение параметров объекта капитального строительства, его частей (высоты, количества этажей, площади, объема), в том числе надстройка, перестройка, расширение объекта капитального строительства',
          'Изменение параметров линейных объектов или их участков (частей), которое не влечет за собой изменение класса, категории и (или) первоначально установленных показателей функционирования таких объектов и при котором не требуется изменение границ полос отвода и (или) охранных зон таких объектов',
          'Замена и (или) восстановление строительных конструкций объектов капитального строительства или элементов таких конструкций, за исключением несущих строительных конструкций',
          'Ликвидация объекта капитального строительства путем его разрушения (за исключением разрушения вследствие природных явлений либо противоправных действий третьих лиц), разборки и (или) демонтажа объекта капитального строительства, в том числе его частей',
        ],
        correctAnswers: [
          'Изменение параметров линейных объектов или их участков (частей), которое не влечет за собой изменение класса, категории и (или) первоначально установленных показателей функционирования таких объектов и при котором не требуется изменение границ полос отвода и (или) охранных зон таких объектов',
        ],
      },
      {
        code: '64846125',
        text:
          'На какое расстояние до токоведущих частей электроустановок, находящихся под напряжением 1-35 кВ, не допускается приближение людей при оперативном обслуживании, осмотрах электроустановок, а также выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Менее чем 0,6 м',
          'Менее чем 1,0 м',
          'Менее чем 1,5 м',
          'Менее чем 1,2 м',
        ],
        correctAnswers: ['Менее чем 0,6 м'],
      },
      {
        code: '64846126',
        text:
          'Кто предоставляет командированному персоналу привлекаемой организации права работы в действующих электроустановках в качестве выдающих наряды-допуски и распоряжения, ответственных руководителей, производителей работ, членов бригады? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель организации',
          'Руководитель командирующей организации',
          'Ответственный за электрохозяйство командирующей организации',
          'Технический руководитель командирующей организации',
        ],
        correctAnswers: ['Руководитель организации'],
      },
      {
        code: '64846127',
        text:
          'С какой периодичностью владельцем объекта электроэнергетики должна проводиться проверка состояния и готовности защиты от перенапряжений распределительных устройств и воздушных линий электропередачи к противодействию грозовым и внутренним перенапряжениям? Выберите правильный вариант ответа.',
        answers: [
          'Ежегодно',
          '1 раз в 5 лет',
          '1 раз в 2 года',
          '1 раз в 10 лет',
        ],
        correctAnswers: ['Ежегодно'],
      },
      {
        code: '64846128',
        text:
          'Каким образом оформляется решение о расследовании причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Приказом руководителя организации, эксплуатирующей участок электроэнергетики, на котором произошла авария',
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
          'Решением уполномоченного органа в сфере электроэнергетики',
          'Протоколом должностного лица органа федерального государственного энергетического надзора',
        ],
        correctAnswers: [
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
        ],
      },
      {
        code: '64846129',
        text:
          'На кого из перечисленных лиц не распространяются Правила технической эксплуатации электрических станций и сетей Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'Собственников объектов по производству электрической энергии, в том числе функционирующих в режиме комбинированной выработки электрической и тепловой энергии',
          'Собственников объектов электросетевого хозяйства классом напряжения 0,4 кВ и ниже, присоединенных к электрическим сетям на уровне напряжения 0,4 кВ',
          'Системного оператора и иных субъектов оперативно-диспетчерского управления в технологически изолированных территориальных электроэнергетических системах',
        ],
        correctAnswers: [
          'Собственников объектов электросетевого хозяйства классом напряжения 0,4 кВ и ниже, присоединенных к электрическим сетям на уровне напряжения 0,4 кВ',
        ],
      },
      {
        code: '64846130',
        text:
          'Какой срок действия разрешения и временного разрешения на допуск в эксплуатацию энергопринимающих установок потребителей электрической энергии? Выберите правильный вариант ответа.',
        answers: [
          'Разрешение на допуск действует 180 суток. Временное разрешение действует 90 суток',
          'Разрешение на допуск действует бессрочно. Временное разрешение действует 180 суток',
          'Разрешение на допуск действует 1 год. Временное разрешение действует 30 суток',
          'Разрешение на допуск действует 5 лет. Временное разрешение действует 60 суток',
        ],
        correctAnswers: [
          'Разрешение на допуск действует бессрочно. Временное разрешение действует 180 суток',
        ],
      },
      {
        code: '64846131',
        text:
          'Какому административному штрафу могут быть подвергнуты юридические лица за ввод в эксплуатацию энергопотребляющих объектов без разрешения соответствующих органов? Выберите правильный вариант ответа.',
        answers: [
          'От десяти до двадцати тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
          'От двухсот до трехсот тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
          'От ста до двухсот тысяч рублей',
          'От тридцати до пятидесяти тысяч рублей или административное приостановление деятельности на срок до тридцати суток',
        ],
        correctAnswers: [
          'От десяти до двадцати тысяч рублей или административное приостановление деятельности на срок до девяноста суток',
        ],
      },
      {
        code: '64846132',
        text:
          'По чьей команде вывешивается и снимается плакат «Не включать! Работа на линии!» на приводах разъединителей, которыми отключена для выполнения работ воздушная или кабельная линии? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
          'Выдающего наряд-допуск',
          'Ответственного руководителя работ',
          'Производителя работ',
        ],
        correctAnswers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
        ],
      },
      {
        code: '64846133',
        text:
          'Что должен сделать производитель работ или наблюдающий при необходимости временного ухода с рабочего места, если его не могут заменить ответственный руководитель работ, допускающий или работник, имеющий право выдачи нарядов-допусков? Выберите правильный вариант ответа.',
        answers: [
          'Предупредить бригаду о своем уходе с места работы и времени отсутствия',
          'Передать наряд одному из членов бригады с группой по электробезопасности не ниже IV',
          'Удалить бригаду с места работы (с выводом ее из РУ и закрытием входных дверей на замок, со снятием людей с опоры ВЛ и т.п.)',
          'Приостановить работу',
        ],
        correctAnswers: [
          'Удалить бригаду с места работы (с выводом ее из РУ и закрытием входных дверей на замок, со снятием людей с опоры ВЛ и т.п.)',
        ],
      },
      {
        code: '64846134',
        text:
          'Каким образом юридическим лицом представляются в орган федерального государственного энергетического надзора заявление о выдаче разрешения на допуск в эксплуатацию энергоустановки и прилагаемые к нему документы? Выберите правильный вариант ответа.',
        answers: [
          'Только в электронном виде, подписанного простой электронной подписью',
          'Посредством федеральной государственной информационной системы "Единый портал государственных и муниципальных услуг (функций)"',
          'Посредством электронной почты',
          'На бумажном носителе непосредственно или посредством заказного почтового отправления с уведомлением о вручении',
        ],
        correctAnswers: [
          'На бумажном носителе непосредственно или посредством заказного почтового отправления с уведомлением о вручении',
        ],
      },
      {
        code: '64846135',
        text:
          'В течение какого времени проводится первичная аттестация руководителей (заместителей руководителей) организаций по вопросам безопасности в сфере электроэнергетики при назначении на соответствующую должность? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее двух месяцев',
          'Не позднее одного месяца',
          'Не позднее трех месяцев',
          'Не позднее шести месяцев',
        ],
        correctAnswers: ['Не позднее одного месяца'],
      },
      {
        code: '64846136',
        text:
          'Каким образом необходимо присоединять переносное заземление при выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения, установить переносное заземление на токоведущие части',
          'Присоединить к токоведущим частям, а затем к заземляющему устройству',
          'Устанавливать заземления на токоведущие части, присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения',
        ],
        correctAnswers: [
          'Присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения, установить переносное заземление на токоведущие части',
        ],
      },
      {
        code: '64846137',
        text:
          'Какие меры безопасности необходимо принимать для предотвращения ошибочного включения коммутационных аппаратов при отсутствии в схеме предохранителей во время проведения планового ремонта электроустановки? Выберите правильный вариант ответа.',
        answers: [
          'Обеспечить запирание рукояток или дверец шкафа',
          'Обеспечить закрытие кнопок',
          'Установить между контактами коммутационного аппарата изолирующие накладки',
          'Можно принимать любые из перечисленных мер либо провести расшиновку или отсоединение кабеля, проводов от коммутационного аппарата либо от оборудования, на котором будут проводиться работы',
        ],
        correctAnswers: [
          'Можно принимать любые из перечисленных мер либо провести расшиновку или отсоединение кабеля, проводов от коммутационного аппарата либо от оборудования, на котором будут проводиться работы',
        ],
      },
      {
        code: '64846138',
        text:
          'Укажите требования к выполнению технического осмотра устройств РЗА и вторичного оборудования с целью определения состояния аппаратуры и вторичных цепей, проверки правильности положения переключающих устройств и испытательных блоков. Выберите правильный вариант ответа.',
        answers: [
          'Технический осмотр должен выполняться для всех устройств РЗА и вторичного оборудования',
          'При выполнении технического осмотра устройств РЗА и (или) вторичного оборудования должен выполняться только их внешний осмотр',
          'Запрещается снимать защитные крышки (кожухи) реле при выполнении технического осмотра',
          'Необходимо выполнять все перечисленное',
        ],
        correctAnswers: ['Необходимо выполнять все перечисленное'],
      },
      {
        code: '64846139',
        text:
          'Какие формы работы с административно-техническим персоналом не проводятся? Выберите правильный вариант ответа.',
        answers: [
          'Проверка знаний',
          'Дублирование и специальная подготовка',
          'Производственный инструктаж',
          'Предэкзаменационная подготовка',
        ],
        correctAnswers: ['Дублирование и специальная подготовка'],
      },
      {
        code: '64846140',
        text:
          'Какая периодичность осмотра оборудования распределительных устройств без отключения от сети указана неверно? Выберите правильный вариант ответа.',
        answers: [
          'На объектах с постоянным дежурством персонала - не реже 1 раза в смену',
          'На объектах с постоянным дежурством персонала - не реже 1 раза в сутки',
          'На объектах без постоянного дежурного персонала - не реже 1 раза в месяц',
          'В трансформаторных и распределительных пунктах - не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: [
          'На объектах с постоянным дежурством персонала - не реже 1 раза в смену',
        ],
      },
      {
        code: '64846141',
        text:
          'В какой последовательности необходимо выполнять технические мероприятия, обеспечивающие безопасность работ со снятием напряжения? Выберите правильный вариант ответа.',
        answers: [
          'Произвести необходимые отключения, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить запрещающие и указательные плакаты',
          'Вывесить запрещающие и указательные плакаты, произвести необходимые отключения, проверить отсутствие напряжения на токоведущих частях, установить заземление',
          'Произвести необходимые отключения, вывесить запрещающие и указательные плакаты, установить заземление, проверить отсутствие напряжения на токоведущих частях',
          'Произвести необходимые отключения, вывесить запрещающие плакаты, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить указательные плакаты',
        ],
        correctAnswers: [
          'Произвести необходимые отключения, вывесить запрещающие плакаты, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить указательные плакаты',
        ],
      },
      {
        code: '64846142',
        text:
          'Что из перечисленного не является критериями технической возможности технологического присоединения? Выберите правильный вариант ответа.',
        answers: [
          'Сохранение условий электроснабжения (установленной категории надежности электроснабжения и сохранения качества электроэнергии) для прочих потребителей, энергопринимающие установки которых на момент подачи заявки заявителя присоединены к электрическим сетям сетевой организации или смежных сетевых организаций',
          'Отсутствие ограничений на максимальную мощность в объектах электросетевого хозяйства, к которым надлежит произвести технологическое присоединение',
          'Отсутствие необходимости реконструкции или расширения (сооружения новых) объектов электросетевого хозяйства смежных сетевых организаций либо строительства (реконструкции) генерирующих объектов для удовлетворения потребности заявителя',
          'Возможность перераспределения объема присоединенной мощности одних владельцев в пользу других',
        ],
        correctAnswers: [
          'Возможность перераспределения объема присоединенной мощности одних владельцев в пользу других',
        ],
      },
      {
        code: '64846143',
        text:
          'Какие из перечисленных требований к владельцам объектов электроэнергетики указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Обеспечивать соответствие параметров технологического режима работы указанных ЛЭП и оборудования их допустимым значениям',
          'Обеспечивать исполнение требований, касающихся проверки и обеспечения соответствия оборудования уровням токов короткого замыкания',
          'Обеспечивать актуальность данных о длительно допустимой и аварийно допустимой токовой нагрузке ЛЭП и оборудования, в том числе в зависимости от температуры воздуха и с учетом их технического состояния, определяемого в соответствии с Методикой оценки технического состояния',
        ],
        correctAnswers: [
          'Обеспечивать соответствие параметров технологического режима работы указанных ЛЭП и оборудования их допустимым значениям',
        ],
      },
      {
        code: '64846144',
        text:
          'Кто имеет право на продление нарядов-допусков? Выберите правильный вариант ответа.',
        answers: [
          'Работник, выдавший наряд-допуск, или имеющий право выдачи наряд-допуска в данной электроустановке',
          'Ответственный руководитель работ в данной электроустановке',
          'Ответственный за электрохозяйство структурного подразделения',
          'Руководитель объекта, на котором проводятся работы',
        ],
        correctAnswers: [
          'Работник, выдавший наряд-допуск, или имеющий право выдачи наряд-допуска в данной электроустановке',
        ],
      },
      {
        code: '64846145',
        text:
          'Что из перечисленного соответствует определению "лица, осуществляющие деятельность в сфере электроэнергетики, в том числе производство электрической, тепловой энергии и мощности, приобретение и продажу электрической энергии и мощности, энергоснабжение потребителей, оказание услуг по передаче электрической энергии, оперативно-диспетчерскому управлению в электроэнергетике, сбыт электрической энергии (мощности), организацию купли-продажи электрической энергии и мощности"? Выберите правильный вариант ответа.',
        answers: [
          'Субъекты электроэнергетики',
          'Потребители электрической энергии',
          'Потребители мощности',
          'Субъекты оптового рынка',
        ],
        correctAnswers: ['Субъекты электроэнергетики'],
      },
      {
        code: '64846146',
        text:
          'Какую работу на воздушных линиях не разрешается выполнять по распоряжению одному работнику, имеющему группу II по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'Окраску бандажей на опорах',
          'Осмотр воздушной линии в темное время суток',
          'Восстановление постоянных обозначений на опоре',
          'Замер габаритов угломерными приборами',
        ],
        correctAnswers: ['Осмотр воздушной линии в темное время суток'],
      },
      {
        code: '64846147',
        text:
          'Что не входит в обязанности сетевой организации при содержании просек? Выберите правильный вариант ответа.',
        answers: [
          'Устройство пожарных водоемов, пожарных наблюдательных пунктов и противопожарных минерализованных полос',
          'Поддержание ширины просек в размерах, предусмотренных проектами строительства объектов электросетевого хозяйства и требованиями, определяемыми в порядке, установленном законодательством Российской Федерации, путем вырубки, обрезки крон деревьев (кустарников) и иными способами',
          'Вырубка или обрезка крон деревьев (лесных насаждений), произрастающих на просеках, высота которых превышает 4 метра',
        ],
        correctAnswers: [
          'Устройство пожарных водоемов, пожарных наблюдательных пунктов и противопожарных минерализованных полос',
        ],
      },
      {
        code: '64846148',
        text:
          'Кто с учетом местных особенностей объектов электроэнергетики должен определить переключения в электроустановках, относящиеся к сложным, и утвердить перечень сложных переключений? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель сетевой организации',
          'Технический руководитель владельца объекта электроэнергетики',
          'Владелец объекта электроэнергетики',
          'Руководитель ЦУС',
        ],
        correctAnswers: [
          'Технический руководитель владельца объекта электроэнергетики',
        ],
      },
      {
        code: '64846149',
        text:
          'Какой срок хранения установлен для журналов учета работ по нарядам-допускам и распоряжениям? Выберите правильный вариант ответа.',
        answers: [
          'Один месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Два месяца со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Полгода со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Один год со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
        correctAnswers: [
          'Один месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
      },
      {
        code: '64846150',
        text:
          'В какой срок Ростехнадзор должен завершить расследование причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'В срок, не превышающий 20 дней со дня начала расследования',
          'В срок, не превышающий 10 дней со дня начала расследования',
          'В срок, не превышающий 20 дней с момента аварии',
          'В срок, не превышающий 20 дней с момента получения информации об аварии',
          'В срок, не превышающий 10 дней с момента получения информации об аварии',
        ],
        correctAnswers: [
          'В срок, не превышающий 20 дней со дня начала расследования',
        ],
      },
      {
        code: '64846151',
        text:
          'Какое из перечисленных определений соответствует понятию "охрана труда"? Выберите правильный вариант ответа.',
        answers: [
          'Система сохранения жизни и здоровья работников в процессе трудовой деятельности, включающая в себя правовые, социально-экономические, организационно-технические, санитарно-гигиенические, лечебно-профилактические, реабилитационные и иные мероприятия',
          'Комплекс мер по сохранению жизни и здоровья работников в процессе трудовой деятельности',
          'Система сохранения жизни и здоровья работников в производственной деятельности с применением организационных и технических средств',
          'Организационные и технические средства, используемые для предотвращения или уменьшения воздействия на работников вредных и (или) опасных производственных факторов, а также для защиты от загрязнения',
        ],
        correctAnswers: [
          'Система сохранения жизни и здоровья работников в процессе трудовой деятельности, включающая в себя правовые, социально-экономические, организационно-технические, санитарно-гигиенические, лечебно-профилактические, реабилитационные и иные мероприятия',
        ],
      },
      {
        code: '64846152',
        text:
          'На сколько календарных дней, в случае необходимости, руководитель Ростехнадзора может продлить срок проведения расследования причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Не более чем на 45 дней',
          'Не более чем на 30 дней',
          'Не более чем на 10 дней',
          'Не более чем на 5 дней',
        ],
        correctAnswers: ['Не более чем на 45 дней'],
      },
      {
        code: '64846153',
        text:
          'Какие плакаты при выполнении работ на электроустановках должны быть вывешены на приводах (рукоятках приводов) коммутационных аппаратов с ручным управлением (выключателей, отделителей, разъединителей, рубильников, автоматов) во избежание подачи напряжения на рабочее место? Выберите правильный вариант ответа.',
        answers: [
          '"Не включать! Работают люди"',
          '"Стой! Напряжение"',
          '"Работать здесь"',
        ],
        correctAnswers: ['"Не включать! Работают люди"'],
      },
      {
        code: '64846154',
        text:
          'Какие мероприятия обязательно осуществляются перед допуском к проведению неотложных работ? Выберите правильный вариант ответа.',
        answers: [
          'Технические мероприятия по подготовке рабочего места',
          'Технологические мероприятия',
          'Организационные мероприятия',
          'Оформление распоряжения',
        ],
        correctAnswers: [
          'Технические мероприятия по подготовке рабочего места',
        ],
      },
      {
        code: '64846155',
        text:
          'Кто дает разрешение на снятие напряжения для освобождения пострадавшего от действия электрического тока? Выберите правильный вариант ответа.',
        answers: [
          'Разрешение дает оперативный персонал энергообъекта',
          'Разрешение дает вышестоящий оперативный персонал',
          'Разрешение дает административно-технический персонал',
          'Предварительного разрешения оперативного персонала не требуется, напряжение должно быть снято немедленно',
        ],
        correctAnswers: [
          'Предварительного разрешения оперативного персонала не требуется, напряжение должно быть снято немедленно',
        ],
      },
      {
        code: '64846156',
        text:
          'Когда должна осуществляться подготовка персонала для обслуживания новых и реконструируемых объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'После ввода этих объектов',
          'За 9 месяцев до ввода этих объектов в эксплуатацию',
          'За 6 месяцев до ввода этих объектов в эксплуатацию',
          'C опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
        ],
        correctAnswers: [
          'C опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
        ],
      },
      {
        code: '64846157',
        text:
          'Что из перечисленного соответствует понятию "объекты электроэнергетики"? Выберите правильный вариант ответа.',
        answers: [
          'Линии электропередачи, трансформаторные и иные подстанции, распределительные пункты и иное предназначенное для обеспечения электрических связей и осуществления передачи электрической энергии оборудование',
          'Имущественные объекты, непосредственно используемые в процессе производства, передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике и сбыта электрической энергии, в том числе объекты электросетевого хозяйства',
          'Объект по производству электрической энергии, принадлежащий на праве собственности или ином законном основании потребителю электрической энергии, энергопринимающие устройства которого технологически присоединены к объектам электросетевого хозяйства с уровнем напряжения до 1000 вольт, функционирующий в том числе на основе использования возобновляемых источников энергии и используемый указанным потребителем для производства электрической энергии в целях удовлетворения собственных бытовых и (или) производственных нужд',
        ],
        correctAnswers: [
          'Имущественные объекты, непосредственно используемые в процессе производства, передачи электрической энергии, оперативно-диспетчерского управления в электроэнергетике и сбыта электрической энергии, в том числе объекты электросетевого хозяйства',
        ],
      },
      {
        code: '64846158',
        text:
          'На какой срок заключается договор аренды земельного участка, находящегося в государственной или муниципальной собственности, для размещения линейных объектов? Выберите правильный вариант ответа.',
        answers: [
          'На срок от трех до десяти лет',
          'На срок до сорока девяти лет',
          'На срок двадцать лет',
          'На срок, не превышающий срока действия договора аренды земельного участка',
        ],
        correctAnswers: ['На срок до сорока девяти лет'],
      },
      {
        code: '64846159',
        text:
          'Кто является участниками лесных отношений? Выберите правильный вариант ответа.',
        answers: [
          'Только граждане и юридические лица',
          'Российская Федерация, субъекты Российской Федерации, муниципальные образования, граждане и юридические лица',
          'Только субъекты Российской Федерации и органы местного самоуправления',
          'Только органы государственной власти Российской Федерации и органы государственной власти субъектов Российской Федерации',
        ],
        correctAnswers: [
          'Российская Федерация, субъекты Российской Федерации, муниципальные образования, граждане и юридические лица',
        ],
      },
      {
        code: '64846160',
        text:
          'Кто должен разрабатывать и утверждать инструкции по производству переключений в электроустановках объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерский персонал объектов электроэнергетики',
          'Оперативный персонал объектов электроэнергетики',
          'Владельцы объектов электроэнергетики',
        ],
        correctAnswers: ['Владельцы объектов электроэнергетики'],
      },
      {
        code: '64846161',
        text:
          'Какая техническая документация оформляется владельцем объекта электроэнергетики на каждое устройство РЗА и вторичное оборудование? Выберите правильный вариант ответа.',
        answers: [
          'Инструкция по эксплуатации',
          'Схемы электрических, тепловых соединений, технологических систем',
          'Оперативная документация',
          'Паспорта-протоколы на весь срок эксплуатации',
        ],
        correctAnswers: ['Паспорта-протоколы на весь срок эксплуатации'],
      },
      {
        code: '64846162',
        text:
          'Что из перечисленного должен в обязательном порядке делать допускающий перед допуском к работе на электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только провести целевой инструктаж при работах по наряду ответственному руководителю работ, производителю работ (наблюдающему) и членам бригады',
          'Только проверить соответствие состава бригады составу, указанному в наряде или распоряжении, по именным удостоверениям членов бригады',
          'Только доказать бригаде, что напряжение отсутствует, демонстрацией установленных заземлений или проверкой отсутствия напряжения, если заземления не видны с рабочего места',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64846163',
        text:
          'Какую группу по электробезопасности должны иметь работники из числа оперативного персонала, единолично обслуживающие электроустановки напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: ['Группу II', 'Группу III', 'Группу IV', 'Группу V'],
        correctAnswers: ['Группу III'],
      },
      {
        code: '64846164',
        text:
          'Какой организацией определяются схема и порядок измерений величины наведенного напряжения и ее перерасчета на наибольший рабочий ток влияющей воздушной линии? Выберите правильный вариант ответа.',
        answers: [
          'Проектной организацией',
          'Эксплуатирующей организацией',
          'Пусконаладочной организацией',
          'Любой их перечисленных',
        ],
        correctAnswers: ['Эксплуатирующей организацией'],
      },
      {
        code: '64846165',
        text:
          'Каким образом должен выполняться капитальный ремонт электрооборудования напряжением выше 1000 В? Выберите 2 варианта ответа.',
        answers: [
          'По технологическим картам',
          'По распоряжению, согласно графикам ремонта',
          'Cогласно смете и дефектной ведомости',
          'По проекту производства работ',
        ],
        correctAnswers: [
          'По технологическим картам',
          'По проекту производства работ',
        ],
      },
      {
        code: '64846166',
        text:
          'Каковы условия проведения специальной подготовки персонала? Выберите правильный вариант ответа.',
        answers: [
          'Должна проводиться еженедельно в течение одной рабочей смены и составлять до 20% рабочего времени',
          'Должна проводиться еженедельно в течение одной рабочей смены без отрыва от производства',
          'Должна проводиться в соответствии с утвержденным графиком в рабочее время',
          'Должна проводиться с отрывом от выполнения основных функций не реже одного раза в месяц и составлять от 5 до 20% его рабочего времени',
        ],
        correctAnswers: [
          'Должна проводиться с отрывом от выполнения основных функций не реже одного раза в месяц и составлять от 5 до 20% его рабочего времени',
        ],
      },
      {
        code: '64846167',
        text:
          'Что является временным технологическим присоединением? Выберите правильный вариант ответа.',
        answers: [
          'Технологическое присоединение энергопринимающих устройств по третьей категории надежности электроснабжения на уровне напряжения свыше 35 кВ, осуществляемое на ограниченный период времени для обеспечения электроснабжения энергопринимающих устройств',
          'Технологическое присоединение энергопринимающих устройств по третьей категории надежности электроснабжения на уровне напряжения ниже 35 кВ, осуществляемое на неограниченный период времени для обеспечения электроснабжения энергопринимающих устройств',
          'Технологическое присоединение энергопринимающих устройств по третьей категории надежности электроснабжения на уровне напряжения не ниже 35 кВ, осуществляемое на неограниченный период времени для обеспечения электроснабжения энергопринимающих устройств',
          'Технологическое присоединение энергопринимающих устройств по третьей категории надежности электроснабжения на уровне напряжения ниже 35 кВ, осуществляемое на ограниченный период времени для обеспечения электроснабжения энергопринимающих устройств',
        ],
        correctAnswers: [
          'Технологическое присоединение энергопринимающих устройств по третьей категории надежности электроснабжения на уровне напряжения ниже 35 кВ, осуществляемое на ограниченный период времени для обеспечения электроснабжения энергопринимающих устройств',
        ],
      },
      {
        code: '64846168',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в 3 месяца',
          'Не реже одного раза в месяц',
          'Не реже одного раза в неделю',
          'Не реже одного раза в полгода',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '64846169',
        text:
          'Какое количество плакатов «Не включать! Работа на линии» должно вывешиваться на приводах разъединителей, которыми отключена для выполнения работ воздушная линия, кабельно-воздушная линия или кабельная линия, если на линии работает несколько бригад? Выберите правильный вариант ответа.',
        answers: [
          'По одному плакату для каждой бригады',
          'Два',
          'Один, независимо от числа работающих бригад',
        ],
        correctAnswers: ['Один, независимо от числа работающих бригад'],
      },
      {
        code: '64846170',
        text:
          'Какое из перечисленных определений соответствуют термину "капитальный ремонт объектов капитального строительства (за исключением линейных объектов)"? Выберите правильный вариант ответа.',
        answers: [
          'Изменение параметров объекта капитального строительства, его частей (высоты, количества этажей, площади, объема), в том числе надстройка, перестройка, расширение объекта капитального строительства',
          'Изменение параметров линейных объектов или их участков (частей), которое не влечет за собой изменение класса, категории и (или) первоначально установленных показателей функционирования таких объектов и при котором не требуется изменение границ полос отвода и (или) охранных зон таких объектов',
          'Замена и (или) восстановление строительных конструкций объектов капитального строительства или элементов таких конструкций, за исключением несущих строительных конструкций',
          'Ликвидация объекта капитального строительства путем его разрушения (за исключением разрушения вследствие природных явлений либо противоправных действий третьих лиц), разборки и (или) демонтажа объекта капитального строительства, в том числе его частей',
        ],
        correctAnswers: [
          'Замена и (или) восстановление строительных конструкций объектов капитального строительства или элементов таких конструкций, за исключением несущих строительных конструкций',
        ],
      },
      {
        code: '64846171',
        text:
          'Какая температура верхних слоев масла должна быть у трансформаторов и реакторов с естественной циркуляцией воздуха и масла при номинальной нагрузке, если документацией организации-изготовителя не определены иные значения температуры? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 90 °С',
          'Не выше 80 °С',
          'Не выше 85 °С',
          'Не выше 95 °С',
        ],
        correctAnswers: ['Не выше 95 °С'],
      },
      {
        code: '64846172',
        text:
          'Когда должен производиться контроль уровней магнитного поля? Выберите правильный вариант ответа.',
        answers: [
          'При приемке в эксплуатацию новых, расширении и реконструкции действующих электроустановок',
          'При оборудовании помещений для постоянного или временного пребывания персонала, находящихся вблизи электроустановок',
          'При оценке рабочих мест по условиям труда',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '64846173',
        text:
          'Какому административному штрафу могут быть подвергнуты юридические лица за повреждение электрических сетей напряжением до 1000 В (воздушных, подземных и подводных кабельных линий электропередачи, вводных и распределительных устройств)? Выберите правильный вариант ответа.',
        answers: [
          'От тридцати тысяч до сорока тысяч рублей',
          'От двадцати тысяч до тридцати тысяч рублей',
          'От пятидесяти тысяч до ста тысяч рублей',
          'От трехсот тысяч до пятисот тысяч рублей',
        ],
        correctAnswers: ['От двадцати тысяч до тридцати тысяч рублей'],
      },
      {
        code: '64846174',
        text:
          'Каким напряжением должны питаться от сети переносные ручные светильники ремонтного освещения на объектах электроэнергетики при повышенной опасности поражения электрическим током? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 127 В',
          'Не выше 42 В',
          'Не выше 12 В',
          'Не выше 220 В',
        ],
        correctAnswers: ['Не выше 12 В'],
      },
      {
        code: '64846175',
        text:
          'Какую окраску должны иметь открыто проложенные заземляющие проводники? Выберите правильный вариант ответа.',
        answers: [
          'Желтую окраску',
          'Красную окраску',
          'Синюю окраску',
          'Черную окраску',
        ],
        correctAnswers: ['Черную окраску'],
      },
      {
        code: '64846176',
        text:
          'Какое требование к установке переносных заземлений указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Устанавливать заземления на токоведущие части необходимо непосредственно после проверки отсутствия напряжения',
          'Переносное заземление сначала нужно присоединить к заземляющему устройству, а затем, после проверки отсутствия напряжения, установить на токоведущие части',
          'Переносные заземления в электроустановках необходимо устанавливать с применением диэлектрических перчаток',
          'Производится проверка отсутствия напряжения непосредственно перед присоединением переносного заземления к заземляющему устройству, а затем производится наложение на токоведущие части',
        ],
        correctAnswers: [
          'Производится проверка отсутствия напряжения непосредственно перед присоединением переносного заземления к заземляющему устройству, а затем производится наложение на токоведущие части',
        ],
      },
      {
        code: '64846177',
        text:
          'Когда выдается разрешение на допуск в эксплуатацию энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, объектов электросетевого хозяйства? Выберите правильный вариант ответа.',
        answers: [
          'В течение 14 рабочих дней со дня регистрации заявления о выдаче разрешения на допуск',
          'В течение 10 рабочих дней со дня регистрации заявления о выдаче разрешения на допуск',
          'В течение 20 рабочих дней со дня регистрации заявления о выдаче разрешения на допуск',
          'В течение 30 рабочих дней со дня регистрации заявления о выдаче разрешения на допуск',
        ],
        correctAnswers: [
          'В течение 10 рабочих дней со дня регистрации заявления о выдаче разрешения на допуск',
        ],
      },
      {
        code: '64846178',
        text:
          'С какой периодичностью должно проводиться дополнительное профессиональное образование работников, относящихся к категориям административно-технического, диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '64846179',
        text:
          'В каком случае допускается одновременная работа бригад, использующих различную подготовку рабочего места производства работ с наложением заземления на воздушной линии (на одном электрически связанном участке), под наведенным напряжением? Выберите правильный вариант ответа.',
        answers: [
          'При количестве бригад не более двух',
          'При количестве бригад не более трех',
          'Ни в каком случае',
          'Правилами не регламентируется',
        ],
        correctAnswers: ['Ни в каком случае'],
      },
      {
        code: '64846180',
        text:
          'Кого уведомляет собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация о возникновении аварии? Выберите правильный вариант ответа.',
        answers: [
          'Орган федерального государственного энергетического надзора',
          'Диспетчерский центр субъекта оперативно-диспетчерского управления в электроэнергетике, в операционной зоне которого находятся объект электроэнергетики и (или) энергопринимающая установка',
          'Уполномоченный орган в сфере электроэнергетики',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '64846181',
        text:
          'Какие формы обязательного подтверждения соответствия установлены Федеральным законом от 27 декабря 2002 г. № 184-ФЗ "О техническом регулировании"? Выберите правильный вариант ответа.',
        answers: [
          'Экспертиза промышленной безопасности',
          'Только обязательная сертификация продукции',
          'Обязательная сертификация или декларирование соответствия продукции',
          'Оценка риска применения продукции',
        ],
        correctAnswers: [
          'Обязательная сертификация или декларирование соответствия продукции',
        ],
      },
      {
        code: '64846182',
        text:
          'В каком случае допускается приближение к металлической площадке при выполнении работы на воздушных линиях под наведенным напряжением без средств защиты от напряжения шага? Выберите правильный вариант ответа.',
        answers: [
          'Допускается в любом случае',
          'Не допускается',
          'Допускается на расстояние не ближе 2 м',
          'Допускается на расстояние не ближе 4 м',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '64846183',
        text:
          'В каких случаях должны проводиться неплановые контрольные тренировки дополнительно к контрольным тренировкам, предусмотренным годовым графиком проведения контрольных тренировок? Выберите правильный вариант ответа.',
        answers: [
          'Если проведение тренировки предусмотрено актом расследования причин аварий в электроэнергетике',
          'Если по итогам проведения плановой контрольной тренировки участнику тренировки выставлена оценка "неудовлетворительно"',
          'Если работник не принял участие в плановой контрольной тренировке',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '64846184',
        text:
          'Какое количество бригад может работать одновременно на одной воздушной линии (на одном электрически связанном участке) без заземления воздушной линии в распределительном устройстве при заземлении воздушной линии только на рабочем месте? Выберите правильный вариант ответа.',
        answers: [
          'Не более одной',
          'Не более двух',
          'Правилами по охране труда при эксплуатации электроустановок не ограничивается',
        ],
        correctAnswers: ['Не более одной'],
      },
      {
        code: '64846185',
        text:
          'Какому из перечисленных терминов соответствует определение "линии электропередачи, линии связи (в том числе линейно-кабельные сооружения), трубопроводы, автомобильные дороги, железнодорожные линии и другие подобные сооружения"? Выберите правильный вариант ответа.',
        answers: [
          'Объекты местного значения',
          'Объект капитального строительства',
          'Некапитальные строения, сооружения',
          'Линейные объекты',
        ],
        correctAnswers: ['Линейные объекты'],
      },
      {
        code: '64846186',
        text:
          'Как классифицируются электроинструмент и ручные электрические машины по способу защиты от поражения электрическим током? Выберите правильный вариант ответа.',
        answers: [
          'Делятся на 4 класса - нулевой, первый, второй и третий',
          'Делятся на 3 класса - первый, второй и третий',
          'Делятся на 4 класса - первый, второй, третий и четвертый',
          'Делятся на 3 класса - нулевой, первый и второй',
        ],
        correctAnswers: [
          'Делятся на 4 класса - нулевой, первый, второй и третий',
        ],
      },
      {
        code: '64846187',
        text:
          'В каких случаях проводится первичная проверка знаний работников организаций электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'При поступлении на работу впервые',
          'При перерыве в работе в данной должности более 6 месяцев',
          'При установке нового оборудования',
          'При перерыве в проверке знаний более 3 лет',
        ],
        correctAnswers: [
          'При поступлении на работу впервые',
          'При перерыве в проверке знаний более 3 лет',
        ],
      },
      {
        code: '64846188',
        text:
          'Какое из перечисленных определений соответствует понятию "вредный производственный фактор"? Выберите правильный вариант ответа.',
        answers: [
          'Фактор производственной среды или трудового процесса, воздействие которого может привести к профессиональному заболеванию работника',
          'Производственный фактор, воздействие которого на работника может привести к его заболеванию',
          'Фактор производственной среды и трудового процесса, оказывающий влияние на работоспособность и здоровье работника',
          'Фактор производственной среды или трудового процесса, воздействие которого может привести к травме или смерти работника',
        ],
        correctAnswers: [
          'Фактор производственной среды или трудового процесса, воздействие которого может привести к профессиональному заболеванию работника',
        ],
      },
      {
        code: '64846189',
        text:
          'Какое определение соответствует термину "дублирование"? Выберите правильный вариант работы. Выберите правильный вариант ответа.',
        answers: [
          'Практическое освоение непосредственно на рабочем месте навыков выполнения работы или группы работ, приобретенных при профессиональной подготовке',
          'Форма производственной деятельности организации, обеспечивающая поддержание необходимого профессионального образовательного уровня персонала для выполнения им производственных функций, определенной работы или группы работ',
          'Одна из форм дополнительного повышения образовательного уровня персонала, осуществляемая путем систематического самообразования, проведения производственно-экономической учебы, краткосрочного и длительного периодического обучения в соответствующих образовательных учреждениях',
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
        ],
        correctAnswers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
        ],
      },
      {
        code: '64846190',
        text:
          'Когда субъект оперативно-диспетчерского управления утверждает сводный годовой график ремонта объектов диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 30 января года, предшествующего планируемому году',
          'Не позднее 30 февраля года, предшествующего планируемому году',
          'Не позднее 30 мая года, предшествующего планируемому году',
          'Не позднее 30 июля года, предшествующего планируемому году',
        ],
        correctAnswers: [
          'Не позднее 30 июля года, предшествующего планируемому году',
        ],
      },
      {
        code: '64846191',
        text:
          'С какой периодичностью руководители (заместители руководителей) субъектов электроэнергетики и потребителей электрической энергии обязаны проходить аттестацию по вопросам безопасности в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в пять лет',
          'Не реже одного раза в год',
          'Не реже одного раза в три года',
          'Не реже одного раза в два года',
        ],
        correctAnswers: ['Не реже одного раза в пять лет'],
      },
      {
        code: '64846192',
        text:
          'Какие работы на воздушной линии должны выполняться по технологическим картам или проекту производства работ? Выберите правильный вариант ответа.',
        answers: [
          'Работы с электроизмерительными клещами при нахождении на опоре ВЛ',
          'Работы по расчистке трассы ВЛ от деревьев',
          'Работы с импульсным измерителем',
          'Работы по замене элементов опор, монтажу и демонтажу опор и проводов, замене гирлянд изоляторов ВЛ',
        ],
        correctAnswers: [
          'Работы по замене элементов опор, монтажу и демонтажу опор и проводов, замене гирлянд изоляторов ВЛ',
        ],
      },
      {
        code: '64846193',
        text:
          'На основании чего устройства РЗА должны выводиться из работы в ходе организации и проведения работ по техническому обслуживанию? Выберите правильный вариант ответа.',
        answers: [
          'На основании заявок владельцев электроэнергетики',
          'На основании сводных годовых и месячных графиков обслуживания устройств РЗА',
          'На основании инструкции изготовителя оборудования',
          'На основании технологической инструкции',
        ],
        correctAnswers: [
          'На основании сводных годовых и месячных графиков обслуживания устройств РЗА',
        ],
      },
      {
        code: '64846194',
        text:
          'При каком условии работники, не обслуживающие электроустановки, могут допускаться в распределительное устройство до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV, либо работника, имеющего право единоличного осмотра',
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III, либо работника, имеющего право единоличного осмотра',
          'В сопровождении опытного работника из числа ремонтного персонала, имеющего группу по электробезопасности не ниже V',
        ],
        correctAnswers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III, либо работника, имеющего право единоличного осмотра',
        ],
      },
      {
        code: '64846195',
        text:
          'Какой персонал должен обеспечивать выполнение технического обслуживания и ремонта оборудования, зданий и сооружений объектов электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'Ремонтный персонал',
          'Персонал, ответственный за внутренний контроль',
          'Обслуживающий персонал',
          'Работники, привлекаемые к выполнению ТОиР организациями-исполнителями',
        ],
        correctAnswers: [
          'Ремонтный персонал',
          'Работники, привлекаемые к выполнению ТОиР организациями-исполнителями',
        ],
      },
      {
        code: '64846196',
        text:
          'Какие формы работы с ремонтным персоналом должны использоваться? Выберите правильный вариант ответа.',
        answers: [
          'Только предэкзаменационная подготовка и проверка знаний',
          'Только производственный инструктаж',
          'Только стажировка',
          'Все перечисленные формы работы',
        ],
        correctAnswers: ['Все перечисленные формы работы'],
      },
      {
        code: '64846197',
        text:
          'От кого должен получить подтверждение об окончании работ и удалении всех бригад с рабочего места диспетчерский или оперативный персонал перед отдачей команды на снятие плаката "Не включать! Работа на линии!"? Выберите правильный вариант ответа.',
        answers: [
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
          'От диспетчера',
          'От работника из числа электротехнологического персонала',
          'От работника из числа административного персонала',
        ],
        correctAnswers: [
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
        ],
      },
      {
        code: '64846198',
        text:
          'В каком случае допускается включать в состав бригады, выполняющей работы по наряду-допуску, работников, имеющих II группу по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'На каждого работника, имеющего группу по электробезопасности не ниже III, допускается включать одного работника, имеющего группу II по электробезопасности',
          'На каждого члена бригады, имеющего группу по электробезопасности не ниже IV, допускается включать одного работника, имеющего группу II по электробезопасности',
          'Не допускается',
        ],
        correctAnswers: [
          'На каждого работника, имеющего группу по электробезопасности не ниже III, допускается включать одного работника, имеющего группу II по электробезопасности',
        ],
      },
      {
        code: '64846199',
        text:
          'Какие из перечисленных требований к содержанию технической документации для оперативного и оперативно-ремонтного персонала электростанции указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Перечни документов на рабочих местах оперативного, оперативно-ремонтного персонала должны утверждаться руководителем службы охраны труда',
          'На рабочих местах оперативного, оперативно-ремонтного персонала должны находиться оперативные схемы, оперативный журнал, журнал распоряжений, журнал диспетчерских и оперативных заявок, журнал РЗА и журнал телемеханики, журнал дефектов оборудования, журнал учета работ по нарядам и распоряжениям, журнал учета выдачи ключей',
          'Не допускается наличие у владельца объекта электроэнергетики и на рабочих местах оперативного, оперативно-ремонтного персонала одного журнала распоряжений по отдельным функциональным направлениям деятельности структурных подразделений владельца объекта электроэнергетики',
          'Допускается нахождение на рабочих местах оперативного, оперативно-ремонтного персонала иной оперативной документации, позволяющей персоналу осуществлять контроль за работой оборудования объекта электроэнергетики, вести учет работ по техническому обслуживанию и ремонту, испытаниям и измерениям',
        ],
        correctAnswers: [
          'Допускается нахождение на рабочих местах оперативного, оперативно-ремонтного персонала иной оперативной документации, позволяющей персоналу осуществлять контроль за работой оборудования объекта электроэнергетики, вести учет работ по техническому обслуживанию и ремонту, испытаниям и измерениям',
        ],
      },
      {
        code: '64846200',
        text:
          'Кому разрешается работать единолично в электроустановках напряжением до 1000 В, расположенных в помещениях, кроме особо опасных? Выберите правильный вариант ответа.',
        answers: [
          'Работнику имеющему III группу по электробезопасности и право быть производителем работ',
          'Работнику имеющему IV группу по электробезопасности',
          'Работнику имеющему II группу по электробезопасности',
        ],
        correctAnswers: [
          'Работнику имеющему III группу по электробезопасности и право быть производителем работ',
        ],
      },
      {
        code: '64846201',
        text:
          'Какой порядок допуска к самостоятельной работе вновь принятых, переводимых на новую должность (рабочее место) или имевших перерыв в работе более 6 месяцев работников, относящихся к категориям диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от категории персонала после прохождения этапов подготовки в соответствии с индивидуальной программой',
          'В зависимости от категории персонала, после ознакомления с вновь введенными в действие нормативно-техническими документами, приказами и распоряжениями, изменениями в схемах и режимах работы энергоустановок',
          'В зависимости от категории персонала, после прохождения специальной подготовки, программу и порядок проведения которой определяет руководитель организации',
          'В зависимости от категории персонала, подготовки персонала для допуска к самостоятельной работе, форму которой определяет руководитель организации или структурного подразделения',
        ],
        correctAnswers: [
          'В зависимости от категории персонала после прохождения этапов подготовки в соответствии с индивидуальной программой',
        ],
      },
      {
        code: '64846202',
        text:
          'При каком условии работники, не обслуживающие электроустановки, могут быть допущены в распределительное устройство выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV, либо работника, имеющего право единоличного осмотра',
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III, либо работника, имеющего право единоличного осмотра',
          'В сопровождении опытного работника из числа ремонтного персонала, имеющего группу по электробезопасности не ниже V',
        ],
        correctAnswers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV, либо работника, имеющего право единоличного осмотра',
        ],
      },
      {
        code: '64846203',
        text:
          'Когда работники должны проходить обучение по оказанию первой помощи пострадавшему на производстве? Выберите правильный вариант ответа.',
        answers: [
          'До допуска к самостоятельной работе',
          'После допуска к самостоятельной работе',
          'Ежегодно перед проверкой знаний',
        ],
        correctAnswers: ['До допуска к самостоятельной работе'],
      },
      {
        code: '64846204',
        text:
          'Кто может выполнять перевод бригады на другое рабочее место в распределительном устройстве выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Ответственный руководитель по поручению выдающего наряд',
          'Производитель работ по поручению выдающего наряд',
          'Наблюдающий по поручению выдающего наряд',
          'Допускающий, а также ответственный руководитель работ или производитель работ или наблюдающий в соответствии с поручением работника, выдающего наряд-допуск, с записью в строке "Отдельные указания" наряда-допуска',
        ],
        correctAnswers: [
          'Допускающий, а также ответственный руководитель работ или производитель работ или наблюдающий в соответствии с поручением работника, выдающего наряд-допуск, с записью в строке "Отдельные указания" наряда-допуска',
        ],
      },
      {
        code: '64846205',
        text:
          'В течение какого срока должны храниться наряды-допуски, работы по которым полностью закончены, если при выполнении работ по этим нарядам-допускам не имели место аварии, инциденты или несчастные случаи? Выберите правильный вариант ответа.',
        answers: [
          'В течение 15 суток',
          'В течение 1 месяца',
          'В течение 6 месяцев',
          'В течение 1 года',
        ],
        correctAnswers: ['В течение 1 года'],
      },
      {
        code: '64846206',
        text:
          'Причины каких аварий расследует Федеральная служба по экологическому, технологическому и атомному надзору либо ее территориальный орган? Выберите правильный вариант ответа.',
        answers: [
          'Только причины аварий, в результате которых произошло отклонение частоты электрического тока в энергосистеме или ее части за пределы: 50,00 ± 0,2 Гц продолжительностью 3 часа и более; 50,00 ± 0,4 Гц продолжительностью 30 минут и более',
          'Только причины аварий, в результате которых произошли массовые отключения или повреждения объектов электросетевого хозяйства (высший класс напряжения 6 - 35 кВ), вызванные неблагоприятными природными явлениями, если они привели к прекращению электроснабжения потребителей общей численностью 200 тыс. человек и более',
          'Только причины аварий, в результате которых произошли нарушения в работе противоаварийной или режимной автоматики, в том числе обусловленные ошибочными действиями персонала, вызвавшие отключение объекта электросетевого хозяйства (высший класс напряжения 110 кВ и выше), отключение (включение) генерирующего оборудования, суммарная мощность которого составляет 100 МВт и более, или прекращение электроснабжения потребителей электрической энергии, суммарная мощность потребления которых составляет 100 МВт и более',
          'Расследует причины всех перечисленных аварий',
        ],
        correctAnswers: ['Расследует причины всех перечисленных аварий'],
      },
      {
        code: '64846207',
        text:
          'Что из перечисленного должны обеспечить владельцы объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Круглосуточный мониторинг эксплуатационного состояния средств диспетчерского и технологического управления (СДТУ), фиксация изменений эксплуатационного состояния СДТУ и нарушений в работе СДТУ в оперативном журнале',
          'Принятие мер по выявлению и устранению нарушений СДТУ, проверка работоспособности СДТУ после устранения нарушений',
          'Мониторинг достоверности телеметрической информации, передаваемой с объекта электроэнергетики в диспетчерские центры субъекта оперативно-диспетчерского управления, центр управления сетями и (или) структурное подразделение владельца объекта электроэнергетики, осуществляющее функции технологического управления и ведения в отношении соответствующего объекта электроэнергетики, и принятие мер по восстановлению достоверной телеметрической информации',
          'Всё перечисленное',
        ],
        correctAnswers: ['Всё перечисленное'],
      },
      {
        code: '64846208',
        text:
          'Какие из перечисленных профилактических мероприятий не проводятся при осуществлении федерального государственного энергетического надзора в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Информирование',
          'Обобщение правоприменительной практики',
          'Объявление предостережений',
          'Выездная проверка',
        ],
        correctAnswers: ['Выездная проверка'],
      },
      {
        code: '64846209',
        text:
          'Какие тренировки из перечисленных должны проводиться в организациях электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'Противоаварийные тренировки',
          'Противопожарные тренировки',
          'Тренировки по ликвидации последствий аварий',
          'Тренировки по оказанию первой помощи',
        ],
        correctAnswers: [
          'Противоаварийные тренировки',
          'Противопожарные тренировки',
        ],
      },
      {
        code: '64846210',
        text:
          'Что должно быть обеспечено владельцем объекта электроэнергетики при использовании на объектах электроэнергетики энергетических масел? Выберите правильный вариант ответа.',
        answers: [
          'Только работа технологических систем маслонаполненного оборудования без сбоев и нарушений функционирования',
          'Только сохранение эксплуатационных свойств масел',
          'Только сбор отработанных масел для утилизации',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64846211',
        text:
          'Как необходимо переносить стеклянные бутыли с кислотами и щелочами в аккумуляторных помещениях? Выберите правильный вариант ответа.',
        answers: [
          'С помощью веревочных ручек, закрепленных на бутылях',
          'В специальном деревянном ящике с ручками или на специальных носилках с отверстием посередине и обрешеткой',
          'В специальном металлическом ящике с ручками',
          'На тележках одним работником',
        ],
        correctAnswers: [
          'В специальном деревянном ящике с ручками или на специальных носилках с отверстием посередине и обрешеткой',
        ],
      },
      {
        code: '64846212',
        text:
          'В течение какого времени оперативный персонал объекта, уполномоченный организацией, осуществляет передачу территориальному органу Ростехнадзора соответствующей оперативной информации об авариях в электроэнергетике, в результате которых произошли события, указанные в пункте 5 Правил расследования причин аварий в электроэнергетике, утвержденных постановлением Правительства Российской Федерации от 28 октября 2009 г. № 846 (аварии, причины которых расследует собственник)? Выберите правильный вариант ответа.',
        answers: [
          'В течение 20 минут с момента возникновения аварии',
          'В течение 8 часов с момента возникновения аварии',
          'В течение суток с момента возникновения аварии',
          'В течение 1 часа с момента возникновения аварии',
        ],
        correctAnswers: ['В течение 8 часов с момента возникновения аварии'],
      },
      {
        code: '64846213',
        text:
          'В течение какого времени оперативный персонал объекта, уполномоченный организацией, осуществляет передачу территориальному органу Ростехнадзора оперативной информации об авариях в электроэнергетике, в результате которых произошли события, указанные в пункте 4 Правил расследования причин аварий в электроэнергетике, утвержденных постановлением Правительства Российской Федерации от 28 октября 2009 г. № 846 (аварии, причины которых расследует Федеральный орган исполнительной власти, уполномоченный на осуществление федерального государственного энергетического надзора)? Выберите правильный вариант ответа.',
        answers: [
          'В течение 20 минут с момента возникновения аварии',
          'В течение 8 часов с момента возникновения аварии',
          'В течение суток с момента возникновения аварии',
          'В течение 1 часа с момента возникновения аварии',
        ],
        correctAnswers: ['В течение 20 минут с момента возникновения аварии'],
      },
      {
        code: '64846214',
        text:
          'Какую группу по электробезопасности должны иметь специалисты по охране труда субъектов электроэнергетики, контролирующие электроустановки? Выберите правильный вариант ответа.',
        answers: ['Группу V', 'Группу II', 'Группу III', 'Группу IV'],
        correctAnswers: ['Группу V'],
      },
      {
        code: '64846215',
        text:
          'В каких целях устанавливается публичный сервитут для использования земельных участков и (или) земель? Выберите правильный вариант ответа.',
        answers: [
          'Строительство, реконструкция, эксплуатация, капитальный ремонт объектов электросетевого хозяйства, тепловых сетей, водопроводных сетей, сетей водоотведения, линий и сооружений связи, линейных объектов системы газоснабжения, нефтепроводов и нефтепродуктопроводов, их неотъемлемых технологических частей, если указанные объекты не являются объектами федерального, регионального или местного значения',
          'Устройство пересечений автомобильных дорог или железнодорожных путей с железнодорожными путями на земельных участках, находящихся в частной собственности',
          'Проведение инженерных изысканий в целях подготовки документации по планировке территории, предусматривающей размещение линейных объектов федерального, регионального или местного значения',
          'Реконструкция, капитальный ремонт участков (частей) инженерных сооружений, являющихся капитальными объектами.',
        ],
        correctAnswers: [
          'Проведение инженерных изысканий в целях подготовки документации по планировке территории, предусматривающей размещение линейных объектов федерального, регионального или местного значения',
        ],
      },
      {
        code: '64846216',
        text:
          'Каким образом осуществляется оперативно-диспетчерское управление в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Управление осуществляется субъектами оперативно-диспетчерского управления',
          'Управление осуществляется Правительством Российской Федерации',
          'Управление осуществляется единолично системным оператором',
        ],
        correctAnswers: [
          'Управление осуществляется единолично системным оператором',
        ],
      },
      {
        code: '64846217',
        text:
          'Какую маркировку должны иметь автоматические выключатели и колодки предохранителей, установленные в цепях питания устройств релейной защиты и автоматики? Выберите 2 варианта ответа.',
        answers: [
          'Указание назначения',
          'Указание допустимого напряжения',
          'Указание номинального тока',
          'Расшифровку цветовых обозначений',
        ],
        correctAnswers: ['Указание назначения', 'Указание номинального тока'],
      },
      {
        code: '64846218',
        text:
          'Кто из уполномоченных представителей не может быть включен при необходимости в состав комиссии по расследованию причин аварии в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Должностное лицо органа федерального государственного энергетического надзора',
          'Представитель субъекта оперативно-диспетчерского управления в электроэнергетике',
          'Потребитель электрической энергии, присоединенная мощность которого составляет 30 МВт',
          'Представитель уполномоченного органа в сфере электроэнергетики',
        ],
        correctAnswers: [
          'Потребитель электрической энергии, присоединенная мощность которого составляет 30 МВт',
        ],
      },
      {
        code: '64846219',
        text:
          'В течение какого времени уполномоченный персонал организации по управлению единой национальной (общероссийской) электрической сетью и территориальных сетевых организаций осуществляет передачу оперативной информации в Минэнерго России или подведомственному Минэнерго России государственному бюджетному учреждению об аварийных отключениях (обесточениях) или повреждениях оборудования на собственных объектах электросетевого хозяйства таких сетевых организаций, вызвавших прекращение электроснабжения потребителей? Выберите правильный вариант ответа.',
        answers: [
          'В течение 20 минут с момента возникновения аварии',
          'В течение 8 часов с момента возникновения аварии',
          'В течение суток с момента возникновения аварии',
          'В течение 1 часа с момента возникновения аварии',
        ],
        correctAnswers: ['В течение 8 часов с момента возникновения аварии'],
      },
      {
        code: '64846220',
        text:
          'Формирование и утверждение каких из перечисленных графиков ремонта осуществляет субъект оперативно-диспетчерского управления? Выберите правильный вариант ответа.',
        answers: [
          'Графиков технического обслуживания средств диспетчерского и технологического управления',
          'Сводных годовых и месячных графиков',
          'Графиков технического обслуживания устройств релейной защиты и автоматики',
        ],
        correctAnswers: ['Сводных годовых и месячных графиков'],
      },
      {
        code: '64846221',
        text:
          'Кем устанавливается порядок проведения обходов и осмотров рабочих мест в энергетических организациях? Выберите правильный вариант ответа.',
        answers: [
          'Руководителями соответствующих участков',
          'Руководителями структурных подразделений',
          'Главным инженером организации',
          'Руководителем организации или уполномоченным им должностным лицом',
        ],
        correctAnswers: [
          'Руководителем организации или уполномоченным им должностным лицом',
        ],
      },
      {
        code: '64846222',
        text:
          'Какое из перечисленных определений соответствуют термину "реконструкция линейных объектов"? Выберите правильный вариант ответа.',
        answers: [
          'Изменение параметров объекта капитального строительства, его частей (высоты, количества этажей, площади, объема)',
          'Изменение параметров линейных объектов или их участков (частей), которое влечет за собой изменение класса, категории и (или) первоначально установленных показателей функционирования таких объектов (мощности, грузоподъемности и других) или при котором требуется изменение границ полос отвода и (или) охранных зон таких объектов',
          'Замена и (или) восстановление строительных конструкций объектов капитального строительства',
          'Создание зданий, строений, сооружений (в том числе на месте сносимых объектов капитального строительства)',
        ],
        correctAnswers: [
          'Изменение параметров линейных объектов или их участков (частей), которое влечет за собой изменение класса, категории и (или) первоначально установленных показателей функционирования таких объектов (мощности, грузоподъемности и других) или при котором требуется изменение границ полос отвода и (или) охранных зон таких объектов',
        ],
      },
      {
        code: '64846223',
        text:
          'Какие из перечисленных требований к эксплуатации аккумуляторных батарей (далее - АБ) указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'АБ должны эксплуатироваться в режиме постоянного подзаряда, если иное не установлено документацией организации-изготовителя АБ',
          'Свинцово-кислотные АБ должны эксплуатироваться без тренировочных разрядов и периодических уравнительных перезарядов',
          'Уравнительный заряд должен проводиться при наличии в АБ значительного количества элементов с разным напряжением или разной плотностью электролита',
          'Продолжительность уравнительного заряда должна определяться в зависимости от состояния АБ и не может превышать 24 часа',
        ],
        correctAnswers: [
          'Продолжительность уравнительного заряда должна определяться в зависимости от состояния АБ и не может превышать 24 часа',
        ],
      },
      {
        code: '64846224',
        text:
          'В каком документе указывается значение расчетного наведенного напряжения на воздушной линии? Выберите правильный вариант ответа.',
        answers: [
          'В наряде-допуске',
          'В проектной документации на воздушную линию',
          'В перечне линий, находящихся под наведенным напряжением',
          'В формуляре на воздушную линию',
        ],
        correctAnswers: [
          'В перечне линий, находящихся под наведенным напряжением',
        ],
      },
      {
        code: '64846225',
        text:
          'С какой периодичностью собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация представляют сводный отчет об авариях в электроэнергетике в орган федерального государственного энергетического надзора, уполномоченный орган в сфере электроэнергетики, а также субъекту оперативно-диспетчерского управления в электроэнергетике? Выберите правильный вариант ответа.',
        answers: ['Ежеквартально', 'Ежемесячно', 'Еженедельно', 'Ежегодно'],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64846226',
        text:
          'Кем принимается решение о применении вида организации ремонта по техническому состоянию объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Изготовителем оборудования',
          'Эксплуатирующей организацией',
          'Аккредитованной экспертной организацией',
          'Комиссией, состав которой должен определяться субъектом электроэнергетики с привлечением организаций-изготовителей оборудования и (или) экспертных организаций, аккредитованных в соответствии с законодательством Российской Федерации об аккредитации',
        ],
        correctAnswers: [
          'Комиссией, состав которой должен определяться субъектом электроэнергетики с привлечением организаций-изготовителей оборудования и (или) экспертных организаций, аккредитованных в соответствии с законодательством Российской Федерации об аккредитации',
        ],
      },
      {
        code: '64846227',
        text:
          'Какие из перечисленных работ в электроустановках напряжением до 1000 В не могут быть отнесены к перечню работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Измерения, проводимые с использованием мегаомметра',
          'Снятие и установка электросчетчиков, других приборов и средств измерений',
          'Прокладка и перекладка силовых и контрольных кабелей',
          'Ремонт отдельно расположенных магнитных станций и блоков управления, уход за щеточным аппаратом электрических машин и смазка подшипников',
        ],
        correctAnswers: [
          'Прокладка и перекладка силовых и контрольных кабелей',
        ],
      },
      {
        code: '64846228',
        text:
          'При каком уровне напряженности электрического поля разрешается пребывание персонала в электрическом поле в течение всего рабочего дня (8 ч)? Выберите правильный вариант ответа.',
        answers: ['Не более 5 кВ/м', 'Не более 10 кВ/м', 'Не более 25 кВ/м'],
        correctAnswers: ['Не более 5 кВ/м'],
      },
      {
        code: '64846229',
        text:
          'Какое из перечисленных утверждений о перерыве в работе на протяжении рабочего дня (на обед, по условиям работы) во время работ на электроустановках не является верным? Выберите правильный вариант ответа.',
        answers: [
          'Наряд-допуск остается у производителя работ (наблюдающего)',
          'Двери распределительного устройства должны быть закрыты на замок',
          'Бригада должна быть удалена с рабочего места',
          'Допуск к работе после такого перерыва выполняется с указанием в наряде-допуске',
        ],
        correctAnswers: [
          'Допуск к работе после такого перерыва выполняется с указанием в наряде-допуске',
        ],
      },
      {
        code: '64846230',
        text:
          'Кто может выполнять проверку подготовки рабочего места при отсутствии оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель работ с разрешения допускающего',
          'Производитель работ с разрешения допускающего',
          'Наблюдающий с разрешения оперативного персонала',
          'Руководитель работ совместно с производителем работ с разрешения оперативного персонала',
        ],
        correctAnswers: [
          'Руководитель работ совместно с производителем работ с разрешения оперативного персонала',
        ],
      },
      {
        code: '64846231',
        text:
          'При каких условиях оперативный персонал, находящийся на дежурстве, допускается привлекать к работе в бригаде по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'По разрешению работника из числа вышестоящего оперативного персонала и оформлением в наряде-допуске',
          'Ни в каких случаях',
          'По разрешению работника из числа вышестоящего оперативного персонала',
          'По разрешению работника из числа вышестоящего административно-технического персонала',
        ],
        correctAnswers: [
          'По разрешению работника из числа вышестоящего оперативного персонала и оформлением в наряде-допуске',
        ],
      },
      {
        code: '64846232',
        text:
          'Какие инструкции должны быть разработаны и утверждены владельцами объектов электроэнергетики для всех устройств релейной защиты и автоматики (РЗА), находящихся в эксплуатации? Выберите 2 варианта ответа.',
        answers: [
          'Инструкции по эксплуатации комплексов и устройств РЗА',
          'Инструкции по оперативному обслуживанию комплексов и устройств РЗА',
          'Инструкции по выполнению переключений в схемах РЗА',
          'Инструкции по методике расчета и выбора параметров настройки (уставок) и алгоритмов функционирования устройств РЗА',
        ],
        correctAnswers: [
          'Инструкции по эксплуатации комплексов и устройств РЗА',
          'Инструкции по оперативному обслуживанию комплексов и устройств РЗА',
        ],
      },
      {
        code: '64846233',
        text:
          'На какой срок и сколько раз может быть продлен наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          '1 раз на срок не более 15 календарных дней',
          '2 раза на срок не более 20 календарных дней',
          'Еженедельно на расчетный срок всего ремонта в электроустановках',
        ],
        correctAnswers: ['1 раз на срок не более 15 календарных дней'],
      },
      {
        code: '64846234',
        text:
          'В течение какого времени должно быть рассмотрено заявление сетевой организации о согласовании границ охранной зоны в отношении отдельных объектов электросетевого хозяйства, поданное в федеральный орган исполнительной власти, осуществляющий технический контроль и надзор в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'В течение 30 дней со дня поступления заявления',
          'В течение 25 дней со дня поступления заявления',
          'В течение 20 дней с даты его поступления',
          'В течение 15 рабочих дней со дня поступления указанных заявления и сведений',
        ],
        correctAnswers: [
          'В течение 15 рабочих дней со дня поступления указанных заявления и сведений',
        ],
      },
      {
        code: '64846235',
        text:
          'Кому не предоставляется право выдачи разрешений на подготовку рабочих мест и допуск к работам на объектах электросетевого хозяйства? Выберите правильный вариант ответа.',
        answers: [
          'Оперативному персоналу, имеющему группу не ниже IV, в соответствии с должностными инструкциями',
          'Работникам, имеющим группу III по электробезопасности',
          'Работникам из числа административно-технического персонала организации, имеющим группу V - в электроустановках напряжением выше 1000 В и группу IV - в электроустановках напряжением до 1000 В',
        ],
        correctAnswers: [
          'Работникам из числа административно-технического персонала организации, имеющим группу V - в электроустановках напряжением выше 1000 В и группу IV - в электроустановках напряжением до 1000 В',
        ],
      },
      {
        code: '64846236',
        text:
          'Какое административное наказание для должностных лиц влечет нарушение собственниками или иными законными владельцами объектов по производству электрической энергии и (или) объектов электросетевого хозяйства порядка вывода объектов электроэнергетики в ремонт, повлекшее полное и (или) частичное ограничение режима потребления электрической и (или) тепловой энергии потребителями более чем на три календарных дня? Выберите правильный вариант ответа.',
        answers: [
          'Наложение административного штрафа в размере от двухсот до трехсот тысяч рублей или дисквалификацию на срок до пяти лет',
          'Наложение административного штрафа от двадцати до сорока тысяч рублей или дисквалификацию на срок до года',
          'Наложение административного штрафа в размере от пяти до десяти тысяч рублей',
          'Наложение административного штрафа в размере от тридцати тысяч до пятидесяти тысяч рублей или дисквалификацию на срок от восемнадцати месяцев до трех лет',
        ],
        correctAnswers: [
          'Наложение административного штрафа в размере от тридцати тысяч до пятидесяти тысяч рублей или дисквалификацию на срок от восемнадцати месяцев до трех лет',
        ],
      },
      {
        code: '64846237',
        text:
          'Кто обязан возместить причиненный вред и принять меры в целях недопущения причинения вреда другим лицам, их имуществу, окружающей среде в соответствии с законодательством Российской Федерации в случае, если в результате несоответствия продукции требованиям технических регламентов, нарушений требований технических регламентов при осуществлении связанных с требованиями к продукции процессов проектирования (включая изыскания), производства, строительства, монтажа, наладки, эксплуатации, хранения, перевозки, реализации и утилизации причинен вред жизни или здоровью граждан, имуществу физических или юридических лиц, государственному или муниципальному имуществу, окружающей среде, жизни или здоровью животных и растений или возникла угроза причинения такого вреда? Выберите правильный вариант ответа.',
        answers: [
          'Только изготовитель',
          'Только исполнитель',
          'Только продавец',
          'Только лицо, выполняющее функции иностранного изготовителя',
          'Любые из перечисленных лиц',
        ],
        correctAnswers: ['Любые из перечисленных лиц'],
      },
      {
        code: '64846238',
        text:
          'До какого числа необходимо подать предложения о выводе в ремонт объектов диспетчеризации для формирования и утверждения сводного годового графика ремонта? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 30 июля года, предшествующего планируемому году',
          'Не позднее 30 января года, предшествующего планируемому году',
          'Не позднее 1 сентября года, предшествующего планируемому году',
          'Не позднее 1 марта года, предшествующего планируемому году',
        ],
        correctAnswers: [
          'Не позднее 30 июля года, предшествующего планируемому году',
        ],
      },
      {
        code: '64846239',
        text:
          'Для каких категорий работников проводится стажировка? Выберите правильный вариант ответа.',
        answers: [
          'Для всех категорий',
          'Для административного персонала',
          'Для вспомогательного персонала',
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
        correctAnswers: [
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64846240',
        text:
          'Каким образом допускающий перед допуском к работе убеждается в выполнении технических мероприятий по подготовке рабочего места? Выберите правильный вариант ответа.',
        answers: [
          'Личным осмотром и по записям в оперативном журнале',
          'По показанию приборов на щите управления',
          'По сообщениям ремонтного персонала',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Личным осмотром и по записям в оперативном журнале'],
      },
      {
        code: '64846241',
        text:
          'В каких случаях из перечисленных проводятся внеочередные осмотры воздушных линий электропередачи (ВЛ) или их участков? Выберите правильный вариант ответа.',
        answers: [
          'При образовании на проводах и тросах гололеда',
          'При пляске проводов',
          'Во время ледохода и разлива рек, при лесных и степных пожарах, а также после стихийных бедствий',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '64846242',
        text:
          'Какое напряжение переносных светильников допускается при работах в особо неблагоприятных условиях (колодцах выключателей, отсеках КРУ, барабанах котлов, металлических резервуарах)? Выберите правильный вариант ответа.',
        answers: ['Не более 12 В', 'Не более 36 В', 'Не более 110 В'],
        correctAnswers: ['Не более 12 В'],
      },
      {
        code: '64846243',
        text:
          'Каким работникам предоставляется право выдачи нарядов-допусков и распоряжений (кроме работ по предотвращению аварий или ликвидации их последствий)? Выберите правильный вариант ответа.',
        answers: [
          'Работникам из числа оперативного персонала, имеющим группу не ниже III, в соответствии с должностными инструкциями',
          'Работникам из числа оперативного персонала организации, имеющим группу IV - в электроустановках напряжением выше 1000 В и группу III - в электроустановках напряжением до 1000 В',
          'Работникам из числа административно-технического персонала организации (руководящих работников и специалистов), имеющим группу V (при эксплуатации электроустановок напряжением выше 1000 В), группу IV (при эксплуатации электроустановок напряжением до 1000 В)',
          'Работникам из числа ремонтного персонала, имеющим группу не ниже V, в соответствии с должностными инструкциями',
        ],
        correctAnswers: [
          'Работникам из числа административно-технического персонала организации (руководящих работников и специалистов), имеющим группу V (при эксплуатации электроустановок напряжением выше 1000 В), группу IV (при эксплуатации электроустановок напряжением до 1000 В)',
        ],
      },
      {
        code: '64846244',
        text:
          'Какой персонал должен проходить дублирование? Выберите правильный вариант ответа.',
        answers: [
          'Специалисты, связанные с наладкой и испытанием энергоустановок, после первичной проверки знаний',
          'Лица, непосредственно связанные с ремонтом и техническим обслуживанием энергетического оборудования, после первичной проверки знаний',
          'Оперативный персонал, совмещающий профессии, должен проходить дублирование по основной профессии',
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
        correctAnswers: [
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64846245',
        text:
          'В какой срок с момента отключения (повреждения) или разрушения оборудования или устройств, явившиеся причиной или следствием пожара на объекте, собственник или иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация принимает решение о создании комиссии по расследованию причин аварии и ее составе? Выберите правильный вариант ответа.',
        answers: [
          'В срок не позднее 24 часов',
          'В срок не позднее 12 часов',
          'В срок не позднее 48 часов',
          'В срок не позднее 3 рабочих дней',
        ],
        correctAnswers: ['В срок не позднее 24 часов'],
      },
      {
        code: '64846246',
        text:
          'Что может быть объектом обязательного подтверждения соответствия согласно Федеральному закону "О техническом регулировании"? Выберите правильный вариант ответа.',
        answers: [
          'Только сертифицированная продукция',
          'Продукция, выпускаемая в обращение на территории Российской Федерации',
          'Продукция, процессы производства, эксплуатации, хранения, перевозки, реализации и утилизации, работы и услуги, а также иные объекты, в отношении которых документами по стандартизации, системами добровольной сертификации и договорами устанавливаются требования',
          'Вся перечисленная продукция',
        ],
        correctAnswers: [
          'Продукция, выпускаемая в обращение на территории Российской Федерации',
        ],
      },
      {
        code: '64846247',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа ремонтного персонала, имеющий группу не ниже IV',
          'Работник из числа административно-технического персонала, имеющий группу IV',
          'Работник, имеющий группу IV и право единоличного осмотра на основании письменного распоряжения руководителя организации',
          'Работник из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работник из числа административно-технического персонала (руководящие работники и специалисты), на которого возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу V и право единоличного осмотра на основании ОРД организации (обособленного подразделения)',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работник из числа административно-технического персонала (руководящие работники и специалисты), на которого возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу V и право единоличного осмотра на основании ОРД организации (обособленного подразделения)',
        ],
      },
      {
        code: '64846248',
        text:
          'Какие объекты (оборудование) не подлежат техническому освидетельствованию? Выберите правильный вариант ответа.',
        answers: [
          'Разъединители, отделители и короткозамыкатели классом напряжения 1 кВ и выше',
          'Линейные вводы классом напряжения 110 кВ и ниже',
          'Выключатели классом напряжения 1 кВ и выше, в том числе компактные ячейки заводской готовности',
          'Сборные и соединительные шины, шинные мосты, шинопроводы классом напряжения 1 кВ и выше',
        ],
        correctAnswers: ['Линейные вводы классом напряжения 110 кВ и ниже'],
      },
      {
        code: '64846249',
        text:
          'Какое из перечисленных определений соответствуют термину "объект капитального строительства"? Выберите правильный вариант ответа.',
        answers: [
          'Строения, сооружения, которые не имеют прочной связи с землей и конструктивные характеристики которых позволяют осуществить их перемещение',
          'Создание зданий, строений, сооружений (в том числе на месте сносимых объектов капитального строительства)',
          'Здание, строение, сооружение, объекты, строительство которых не завершено',
          'Отдельно стоящее здание с количеством надземных этажей не менее чем три, высотой не менее двадцати метров',
        ],
        correctAnswers: [
          'Здание, строение, сооружение, объекты, строительство которых не завершено',
        ],
      },
      {
        code: '64846250',
        text:
          'Что из перечисленного является основными принципами обеспечения безопасности труда? Выберите 2 варианта ответа.',
        answers: [
          'Предупреждение и профилактика опасностей',
          'Наказание виновных за нарушение правил охраны труда',
          'Минимизация повреждения здоровья работников',
          'Компенсация ущерба здоровью работников',
        ],
        correctAnswers: [
          'Предупреждение и профилактика опасностей',
          'Минимизация повреждения здоровья работников',
        ],
      },
      {
        code: '64846251',
        text:
          'В каком случае использование земель или земельных участков, находящихся в государственной или муниципальной собственности, за исключением земельных участков, предоставленных гражданам или юридическим лицам, может осуществляться без предоставления земельных участков и установления сервитута, публичного сервитута? Выберите правильный вариант ответа.',
        answers: [
          'Возведения капитальных строений, сооружений',
          'Размещения стационарных торговых объектов, рекламных конструкций',
          'Осуществления геологического изучения недр',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Осуществления геологического изучения недр'],
      },
      {
        code: '64846252',
        text:
          'В каких случаях из перечисленных наряд-допуск должен быть выдан заново? Выберите правильный вариант ответа.',
        answers: [
          'Только при изменении состава бригады более чем на половину',
          'Только при замене ответственного руководителя работ и производителя работ (наблюдающего)',
          'В любом из перечисленных случаев',
          'Только при изменении условий работы',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '64846253',
        text:
          'Куда должна быть подана диспетчерская заявка при необходимости изменения технологического режима работы или эксплуатационного состояния объекта диспетчеризации его владельцем? Выберите правильный вариант ответа.',
        answers: [
          'В исполнительный орган субъекта Российской Федерации',
          'В уполномоченный диспетчерский центр субъекта оперативно-диспетчерского управления',
          'В территориальное подразделение Ростехнадзора',
          'Руководителю службы диспетчерского управления',
        ],
        correctAnswers: [
          'В уполномоченный диспетчерский центр субъекта оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64846254',
        text:
          'Какую группу по электробезопасности при проведении неотложных работ должен иметь производитель работ или наблюдающий из числа оперативного персонала, соответственно выполняющий работу или осуществляющий надзор за работающими в электроустановках напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: ['Группу IV', 'Группу III', 'Группу V'],
        correctAnswers: ['Группу IV'],
      },
      {
        code: '64846255',
        text:
          'С каким персоналом в организации должен проводиться производственный инструктаж? Выберите правильный вариант ответа.',
        answers: [
          'Только с временными работниками',
          'Только с работниками, принимаемыми на должности, не связанные с нахождением в зоне действующих энергоустановок и не связанных с их обслуживанием',
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
          'Только с командированными, студентами и учащимися, прибывшими на предприятие для производственного обучения или практики',
        ],
        correctAnswers: [
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64846256',
        text:
          'Вывод в ремонт и из эксплуатации каких из перечисленных объектов системного оператора не подлежит согласованию с диспетчерскими центрами Министерства энергетики Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'Линии электропередачи, оборудование и устройства, входящие в состав объектов электросетевого хозяйства',
          'Оборудование и устройства систем накопления электрической энергии установленной мощностью 5 МВт или более, с использованием которых осуществляется производство (преобразование в электрическую энергию) и выдача электрической энергии в электрическую сеть',
          'Оборудование и устройства электрических станций, установленная мощность которых составляет менее 5 МВт (в технологически изолированных территориальных электроэнергетических системах - независимо от величины установленной мощности)',
          'Генерирующее оборудование, не отнесенное к объектам диспетчеризации, входящее в состав объектов по производству электрической энергии (мощности), установленная мощность которых составляет 5 МВт или более, функционирующих в составе Единой энергетической системы России',
        ],
        correctAnswers: [
          'Оборудование и устройства электрических станций, установленная мощность которых составляет менее 5 МВт (в технологически изолированных территориальных электроэнергетических системах - независимо от величины установленной мощности)',
        ],
      },
      {
        code: '64846257',
        text:
          'Что должно обязательно указываться в наряде-допуске рядом с фамилией и инициалами работников? Выберите правильный вариант ответа.',
        answers: [
          'Дата рождения',
          'Место работы',
          'Группа по электробезопасности',
          'Возраст',
        ],
        correctAnswers: ['Группа по электробезопасности'],
      },
    ],
    64847: [
      {
        code: '64847000',
        text:
          'От кого должен получить подтверждение об окончании работ и удалении всех бригад с рабочего места диспетчерский или оперативный персонал перед отдачей команды на снятие плаката «Не включать! Работа на линии!»? Выберите правильный вариант ответа.',
        answers: [
          'От вышестоящего диспетчерского или оперативного персонала',
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
          'От ответственного руководителя работ',
          'От выдающего наряд-допуск',
        ],
        correctAnswers: [
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
        ],
      },
      {
        code: '64847001',
        text:
          'На кого распространяются Правила по охране труда при эксплуатации электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'Только на юридических и физических лиц выполняющих ремонтные работы, испытания и измерения, в том числе работы с приборами учета электроэнергии, измерительными приборами и средствами автоматики',
          'На юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций (далее - работники), занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, в том числе работы с приборами учета электроэнергии, измерительными приборами и средствами автоматики, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
          'Только на юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций',
          'Только на юридических и физических лиц занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения',
        ],
        correctAnswers: [
          'На юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций (далее - работники), занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, в том числе работы с приборами учета электроэнергии, измерительными приборами и средствами автоматики, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
        ],
      },
      {
        code: '64847002',
        text:
          'Какую группу по электробезопасности должны иметь специалисты по охране труда субъектов электроэнергетики, контролирующие электроустановки? Выберите правильный вариант ответа.',
        answers: ['Группу III', 'Группу IV', 'Группу V'],
        correctAnswers: ['Группу V'],
      },
      {
        code: '64847003',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работники из числа оперативного персонала, должны иметь группу по электробезопасности не ниже III',
          'Работники из числа ремонтного персонала, имеющий не ниже III группы по электробезопасности',
          'Работник из числа административно-технического персонала, имеющий группу IV по электробезопасности',
          'Работник, имеющий группу III и право единоличного осмотра на основании письменного распоряжения руководителя организации',
        ],
        correctAnswers: [
          'Работники из числа оперативного персонала, должны иметь группу по электробезопасности не ниже III',
        ],
      },
      {
        code: '64847004',
        text:
          'Кто осуществляет региональный государственный контроль (надзор) за применением регулируемых органами исполнительной власти субъектов Российской Федерации цен (тарифов) на электрическую энергию? Выберите правильный вариант ответа.',
        answers: [
          'Управление государственного энергетического надзора',
          'Правительство Российской Федерации',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: [
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '64847005',
        text:
          'На каком расстоянии от основания плотины гидротехнического объекта вверх по течению должно быть устроено водное защитное заграждение? Выберите правильный вариант ответа.',
        answers: ['2,0 - 3,0 м', '4,5 - 5,0 м', '0,5 - 2,0 м', '5,0 - 8,0 м'],
        correctAnswers: ['2,0 - 3,0 м'],
      },
      {
        code: '64847006',
        text:
          'Причины каких аварий расследует Федеральная служба по экологическому, технологическому и атомному надзору либо ее территориальный орган? Выберите правильный вариант ответа.',
        answers: [
          'Только причины аварий, в результате которых произошло отключение генерирующего оборудования или объекта электросетевого хозяйства, приводящее к снижению надежности энергосистемы, включая превышение максимально допустимых перетоков мощности в контролируемом сечении длительностью 1 час и более',
          'Только причины аварий, в результате которых произошло нарушение в работе электрических сетей, приведшее к отклонению частоты на шинах распределительного устройства атомной электростанции (высший класс напряжения 110-750 кВ) от пределов нормальной эксплуатации, установленных технологическим регламентом эксплуатации атомных электростанций (49,0 - 50,5 Гц)',
          'Только причины аварий, в результате которых произошли нарушения работы средств диспетчерского и технологического управления, приводящее к прекращению связи (диспетчерской связи, передачи телеметрической информации или управляющих воздействий противоаварийной или режимной автоматики) между диспетчерским центром субъекта оперативно-диспетчерского управления в электроэнергетике, объектом электроэнергетики и (или) энергопринимающей установкой продолжительностью 1 час и более',
          'Причины всех перечисленных аварий',
        ],
        correctAnswers: ['Причины всех перечисленных аварий'],
      },
      {
        code: '64847007',
        text:
          'Как регламентируется проведение огневых работ на расстоянии 10 м от сливных эстакад горючих жидкостей? Выберите правильный вариант ответа.',
        answers: [
          'Проведение огневых работ допускается без осуществления дополнительных мероприятий',
          'Проведение огневых работ допускается после расчистки территории',
          'Проведение огневых работ допускается, если на месте проведения будут установлены не менее 2 порошковых огнетушителей',
          'Проведение огневых работ допускается при условии очистки сливной эстакады от остатки горючих жидкостей',
        ],
        correctAnswers: [
          'Проведение огневых работ допускается без осуществления дополнительных мероприятий',
        ],
      },
      {
        code: '64847008',
        text:
          'На какое расстояние до токоведущих частей электроустановок, находящихся под напряжением 1-35 кВ, не допускается приближение людей при оперативном обслуживании, осмотрах электроустановок, а также выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'На расстояние не менее 0,6 м',
          'На расстояние не менее 0,5 м',
          'На расстояние не менее 0,3 м',
          'На расстояние не менее 0,25 м',
        ],
        correctAnswers: ['На расстояние не менее 0,6 м'],
      },
      {
        code: '64847009',
        text:
          'Какие мероприятия выполняются, если в процессе подготовки рабочего места по наряду-допуску возникают сомнения в достаточности и правильности мер по подготовке рабочего места и возможности безопасного выполнения работ? Выберите правильный вариант ответа.',
        answers: [
          'Получить разрешение руководителя',
          'Подготовка рабочих мест должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда-допуска, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
          'Оформить новый наряд-допуск',
          'Расширение зоны работ, указанной в наряде-допуске',
        ],
        correctAnswers: [
          'Подготовка рабочих мест должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда-допуска, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
        ],
      },
      {
        code: '64847010',
        text:
          'Кто осуществляет управление государственной собственностью в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Единая национальная (общероссийская) электрическая сеть',
          'Министерство промышленности и торговли Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Центральное управление по экологическому, технологическому и атомному надзору',
        ],
        correctAnswers: [
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
        ],
      },
      {
        code: '64847011',
        text:
          'Как оформляется акт расследования причин аварии при несогласии отдельных членов комиссии? Выберите правильный вариант ответа.',
        answers: [
          'Несогласные члены комиссии акт не подписывают, подписание происходит после включения в комиссию представителя Управления государственного энергетического надзора и проведения дополнительного расследования',
          'Несогласные члены комиссии подписывают акт с примечанием "не согласен"',
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
          'Несогласные члены комиссии акт не подписывают',
        ],
        correctAnswers: [
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
        ],
      },
      {
        code: '64847012',
        text:
          'В течение какого времени со дня утверждения комиссией акта расследования материалы расследования причин аварии подлежат хранению Ростехнадзором? Выберите правильный вариант ответа.',
        answers: [
          'В течение не менее чем 10 лет',
          'В течение не менее чем 20 лет',
          'В течение не менее чем 3 лет',
          'В течение не менее чем 7 лет',
        ],
        correctAnswers: ['В течение не менее чем 3 лет'],
      },
      {
        code: '64847013',
        text:
          'Что понимается под термином "безопасность гидротехнических сооружений"? Выберите правильный вариант ответа.',
        answers: [
          'Обстановка на определенной территории, сложившаяся в результате аварии гидротехнического сооружения, которая может повлечь или повлекла за собой человеческие жертвы, ущерб здоровью людей или ущерб окружающей среде, значительные материальные потери и нарушение условий жизнедеятельности людей',
          'Предельные значения количественных и качественных показателей состояния гидротехнического сооружения и условий его эксплуатации, соответствующие допустимому уровню риска аварии гидротехнического сооружения и утвержденные в установленном порядке федеральными органами исполнительной власти, уполномоченными на осуществление федерального государственного надзора в области безопасности гидротехнических сооружений',
          'Свойство гидротехнических сооружений, позволяющее обеспечивать защиту жизни, здоровья и законных интересов людей, окружающей среды, объектов культурного наследия (памятников истории и культуры) народов Российской Федерации и хозяйственных объектов',
          'Определение соответствия состояния гидротехнического сооружения и квалификации работников эксплуатирующей организации требованиям к обеспечению безопасности гидротехнических сооружений, установленным законодательством Российской Федерации',
        ],
        correctAnswers: [
          'Свойство гидротехнических сооружений, позволяющее обеспечивать защиту жизни, здоровья и законных интересов людей, окружающей среды, объектов культурного наследия (памятников истории и культуры) народов Российской Федерации и хозяйственных объектов',
        ],
      },
      {
        code: '64847014',
        text:
          'Каким образом оформляется разрешение на производство огневых работ во временных местах? Выберите правильный вариант ответа.',
        answers: [
          'Нарядом',
          'Распоряжением руководителя структурного подразделения, ответственного за проведение огневых работ',
          'Записью в журнале регистрации огневых работ с указанием места и подписью работника, допустившего к их проведению',
          'При наличии удостоверения с соответствующей записью на допуск к выполнению огневых работ и специального талона по технике пожарной безопасности оформление разрешения не требуется',
        ],
        correctAnswers: ['Нарядом'],
      },
      {
        code: '64847015',
        text:
          'При каком условии работники, не обслуживающие электроустановки, могут быть допущены в распределительное устройство выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV по электробезопасности, либо работника, имеющего право единоличного осмотра',
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III по электробезопасности, либо работника, имеющего право единоличного осмотра',
          'В сопровождении опытного работника из числа ремонтного персонала, имеющего группу по электробезопасности не ниже V',
        ],
        correctAnswers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV по электробезопасности, либо работника, имеющего право единоличного осмотра',
        ],
      },
      {
        code: '64847016',
        text:
          'На какой персонал распространяются требования специальной подготовки? Выберите правильный вариант ответа.',
        answers: [
          'На руководящий персонал, работающий в электроустановках',
          'На ремонтный персонал, связанный с техническим обслуживанием, ремонтом, наладкой и испытанием энергоустановок',
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
          'На специалистов производственных подразделений',
        ],
        correctAnswers: [
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64847017',
        text:
          'В каком документе оформляется допуск к работам по распоряжению? Выберите правильный вариант ответа.',
        answers: [
          'В журнале учета работ по нарядам-допускам и распоряжениям',
          'В журнале ремонтных работ',
          'В оперативном журнале',
          'В журнале учета прекурсоров',
        ],
        correctAnswers: [
          'В журнале учета работ по нарядам-допускам и распоряжениям',
        ],
      },
      {
        code: '64847018',
        text:
          'При каких условиях оперативный персонал, находящийся на дежурстве, допускается привлекать к работе в бригаде по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'Ни в каком случае',
          'Если получено разрешении работника из числа вышестоящего оперативного персонала с записью в оперативном журнале',
          'Если расширена область работ указанной в наряде-допуске',
          'Если эти работы не противоречат правилам по охране труда',
        ],
        correctAnswers: [
          'Если получено разрешении работника из числа вышестоящего оперативного персонала с записью в оперативном журнале',
        ],
      },
      {
        code: '64847019',
        text:
          'Кто имеет право на продление нарядов-допусков? Выберите правильный вариант ответа.',
        answers: [
          'Ответственный руководитель работ в данной электроустановке',
          'Ответственный за электрохозяйство структурного подразделения',
          'Работник, выдавший наряд-допуск, или другой работник, имеющий право выдачи наряда-допуска на работы в данной электроустановке',
          'Руководитель объекта, на котором проводятся работы',
        ],
        correctAnswers: [
          'Работник, выдавший наряд-допуск, или другой работник, имеющий право выдачи наряда-допуска на работы в данной электроустановке',
        ],
      },
      {
        code: '64847020',
        text:
          'Защита каких сооружений не нормируется Правилами по обеспечению безопасности и антитеррористической защищенности объектов топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: [
          'Плотин (дамб), преграждающих свободное течение реки (протоки) и создающих водяной напор для функционирования гидротехнического сооружения',
          'Шлюзовых устройств на каналах, предназначенных для прохода речного транспорта',
          'Сооружений водопропуска и водозабора, необходимых для отвода избыточного тепла и других целей гидротехнического сооружения',
        ],
        correctAnswers: [
          'Шлюзовых устройств на каналах, предназначенных для прохода речного транспорта',
        ],
      },
      {
        code: '64847021',
        text:
          'По чьей команде вывешивается и снимается плакат «Не включать! Работа на линии!» на приводах разъединителей, которыми отключена для выполнения работ воздушная или кабельная линии? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
          'Выдающего наряд-допуск',
          'Ответственного производителя работ',
          'Ответственного руководителя работ',
        ],
        correctAnswers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
        ],
      },
      {
        code: '64847022',
        text:
          'В течение какого времени периметральные средства обнаружения и охранные извещатели в дежурном режиме должны сохранять работоспособность и не выдавать ложных тревог при отключении сетевого источника электропитания и переходе на резервный автономный источник? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 48 часов',
          'Не менее 8 часов',
          'Не менее 36 часов',
          'Не менее 24 часов',
        ],
        correctAnswers: ['Не менее 24 часов'],
      },
      {
        code: '64847023',
        text:
          'С кем заключают договор собственники объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, предусматривающий право собственников указанных объектов самостоятельно заключать договоры оказания услуг по передаче электрической энергии, в случаях, установленных Правительством Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'С органом исполнительной власти соответствующего субъекта Российской Федерации',
          'С организацией по управлению единой национальной (общероссийской) электрической сетью',
          'С Министерством энергетики Российской Федерации',
          'С администрацией региона',
        ],
        correctAnswers: [
          'С организацией по управлению единой национальной (общероссийской) электрической сетью',
        ],
      },
      {
        code: '64847024',
        text:
          'Какие сроки установлены Трудовым кодексом Российской Федерации для проведения расследования несчастного случая с работником в результате которого один или несколько пострадавших получили тяжелые повреждения здоровья посредством аварии на предприятии, эксплуатирующем электрические сети? Выберите правильный вариант ответа.',
        answers: ['10 дней', '15 дней', '30 дней', '20 дней'],
        correctAnswers: ['15 дней'],
      },
      {
        code: '64847025',
        text:
          'Какое требование безопасности при работе под напряжением в электроустановках напряжением до 1000 В указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Выполнять работы в диэлектрических галошах или стоя на изолирующей подставке либо на резиновом диэлектрическом коврике',
          'Применять изолированный или изолирующий инструмент, предназначенный для работ под напряжением на токоведущих частях, и пользоваться диэлектрическими перчатками',
          'Снимать напряжение с расположенных вблизи рабочего места других токоведущих частей, находящихся под напряжением, к которым возможно случайное прикосновение, или ограждать их',
          'Использовать в работе ножовки, напильники, металлические метры и другие металлические предметы и приспособления',
        ],
        correctAnswers: [
          'Использовать в работе ножовки, напильники, металлические метры и другие металлические предметы и приспособления',
        ],
      },
      {
        code: '64847026',
        text:
          'Что понимается под термином «противопожарный режим»? Выберите правильный вариант ответа.',
        answers: [
          'Правила поведения людей, порядок организации производства и (или) содержания территорий, зданий, сооружений, помещений организаций и других объектов защиты в целях обеспечения требований пожарной безопасности',
          'Совокупность созданных в установленном порядке органов управления, подразделений и организаций, предназначенных для организации профилактики пожаров, их тушения и проведения возложенных на них аварийно-спасательных работ',
          'Совокупность превентивных мер, направленных на исключение возможности возникновения пожаров и ограничение их последствий',
          'Реализация принятых в установленном порядке норм и правил по предотвращению пожаров, спасению людей и имущества от пожаров',
        ],
        correctAnswers: [
          'Правила поведения людей, порядок организации производства и (или) содержания территорий, зданий, сооружений, помещений организаций и других объектов защиты в целях обеспечения требований пожарной безопасности',
        ],
      },
      {
        code: '64847027',
        text:
          'Куда должна быть подана диспетчерская заявка при необходимости изменения технологического режима работы или эксплуатационного состояния объекта диспетчеризации его владельцем? Выберите правильный вариант ответа.',
        answers: [
          'В центральное управление Ростехнадзора',
          'В соответствующий диспетчерский центр субъекта оперативно-диспетчерского управления',
          'В министерство энергетики',
        ],
        correctAnswers: [
          'В соответствующий диспетчерский центр субъекта оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64847028',
        text:
          'Кто несет персональную ответственность за соблюдение требований пожарной безопасности в организации? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель организации',
          'Специалист по обеспечению пожарной безопасности организации',
          'Руководители структурных подразделений',
          'Руководители, назначенные распорядительным документом предприятия ответственными за противопожарное обеспечение объекта защиты',
        ],
        correctAnswers: ['Руководитель организации'],
      },
      {
        code: '64847029',
        text:
          'Какой состав постоянно действующей комиссии по проверке знаний организации? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 5-ти человек, председатель, заместители председателя и 2 члена центральной комиссии организации должны пройти проверку знаний руководителем или заместителем руководителя организации, прошедшим аттестацию по вопросам безопасности в сфере электроэнергетики в соответствии с нормативными правовыми актами по вопросам аттестации',
          'Не менее чем из 3 человек, включая председателя и заместителя(ей) председателя комиссии, прошедшие аттестацию по вопросам безопасности в соответствующей области аттестации в аттестационной комиссии, формируемой федеральным органом исполнительной власти, уполномоченным на осуществление федерального государственного энергетического надзора',
          'Не менее чем из 4 человек, не включая председателя и заместителя(ей) председателя комиссии, трое из которых должны пройти аттестацию по вопросам безопасности в соответствующей области аттестации в аттестационной комиссии, формируемой федеральным органом исполнительной власти, уполномоченным на осуществление федерального государственного энергетического надзора',
          'Не менее чем из 7 человек, не включая председателя и заместителя(ей) председателя комиссии',
        ],
        correctAnswers: [
          'Не менее 5-ти человек, председатель, заместители председателя и 2 члена центральной комиссии организации должны пройти проверку знаний руководителем или заместителем руководителя организации, прошедшим аттестацию по вопросам безопасности в сфере электроэнергетики в соответствии с нормативными правовыми актами по вопросам аттестации',
        ],
      },
      {
        code: '64847030',
        text:
          'На какую глубину подкопа должны быть рассчитаны основные ограждения объекта топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 0,5 м',
          'Не менее 1 м',
          'Не менее 1,5 м',
          'Не менее 0,8 м',
        ],
        correctAnswers: ['Не менее 0,5 м'],
      },
      {
        code: '64847031',
        text:
          'Когда должна отключаться приточно-вытяжная вентиляция в аккумуляторных помещениях? Выберите правильный вариант ответа.',
        answers: [
          'Не ранее чем через 1 час после окончания заряда',
          'Не ранее чем через 0,5 часа после окончания заряда',
          'Не ранее чем через 1,5 часа после окончания заряда',
          'Не ранее чем через 0,2 часа после окончания заряда',
        ],
        correctAnswers: ['Не ранее чем через 1,5 часа после окончания заряда'],
      },
      {
        code: '64847032',
        text:
          'На каком расстоянии от ограждения объекта топливно-энергетического комплекса с внутренней стороны оборудуется запретная зона, где отсутствуют какие-либо строения, сооружения и растительность (деревья, кустарники, трава и др.)? Выберите правильный вариант ответа.',
        answers: [
          'На расстоянии 10 м',
          'На расстоянии 6 м',
          'На расстоянии 7 м',
          'На расстоянии 4 м',
        ],
        correctAnswers: ['На расстоянии 6 м'],
      },
      {
        code: '64847033',
        text:
          'Кто может выполнять перевод бригады на другое рабочее место в распределительном устройстве выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Ответственный руководитель по поручению выдающего наряд-допуск',
          'Допускающий, а также ответственный руководитель работ или производитель работ (наблюдающий), если выдающий наряд поручил им это с записью в строке "Отдельные указания" наряда-допуска',
          'Производитель работ по поручению выдающего наряд-допуск',
          'Наблюдающий по поручению выдающего наряд-допуск',
        ],
        correctAnswers: [
          'Допускающий, а также ответственный руководитель работ или производитель работ (наблюдающий), если выдающий наряд поручил им это с записью в строке "Отдельные указания" наряда-допуска',
        ],
      },
      {
        code: '64847034',
        text:
          'Какие из перечисленных работ в электроустановках напряжением до 1000 В не могут быть отнесены к перечню работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Измерения, проводимые с использованием мегаомметра',
          'Прокладка и перекладка силовых и контрольных кабелей',
          'Работы в электроустановках с односторонним питанием',
          'Ремонт отдельных электроприемников, относящихся к инженерному оборудованию зданий и сооружений (электродвигателей, электрокалориферов, вентиляторов, насосов, установок кондиционирования воздуха)',
        ],
        correctAnswers: [
          'Прокладка и перекладка силовых и контрольных кабелей',
        ],
      },
      {
        code: '64847035',
        text:
          'Как оформляется разрешение на производство огневых работ во временных местах? Выберите правильный вариант ответа.',
        answers: [
          'Оформляется нарядом-допуском',
          'Распоряжением в журнале распоряжений структурного подразделения, в котором выполняются огневые работы',
          'Распоряжением в журнале распоряжений структурного подразделения, в котором выполняются огнеопасные работы',
          'Распоряжением главного инженера энергообъекта',
        ],
        correctAnswers: ['Оформляется нарядом-допуском'],
      },
      {
        code: '64847036',
        text:
          'Какой срок хранения установлен для журналов учета работ по нарядам-допускам и распоряжениям? Выберите правильный вариант ответа.',
        answers: [
          '1 месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          '6 месяцев со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          '2 месяца со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          '12 месяцев со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
        correctAnswers: [
          '1 месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
      },
      {
        code: '64847037',
        text:
          'Каким образом допускающий перед допуском к работе убеждается в выполнении технических мероприятий по подготовке рабочего места? Выберите правильный вариант ответа.',
        answers: [
          'Личным осмотром и по записям в оперативном журнале',
          'По показанию приборов на щите управления',
          'По сообщениям ремонтного персонала',
          'Всеми перечисленными способами',
        ],
        correctAnswers: ['Личным осмотром и по записям в оперативном журнале'],
      },
      {
        code: '64847038',
        text:
          'С какой периодичностью специальной комиссией проводятся обследования объекта топливно-энергетического комплекса высокой и средней категории опасности с целью анализа уязвимости объекта в целом, выявления уязвимых мест, потенциально опасных участков и критических элементов, а также оценки эффективности существующей системы физической защиты? Выберите правильный вариант ответа.',
        answers: [
          'После каждого случая проникновения посторонних лиц на объект',
          'Не реже 1 раза в 4 месяца',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '64847039',
        text:
          'На сколько календарных дней, в случае необходимости, руководитель Ростехнадзора может продлить срок проведения расследования причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Не более чем на 45 календарных дней',
          'Не более чем на 15 календарных дней',
          'Не более чем на 20 календарных дней',
          'Не более чем на 30 календарных дней',
        ],
        correctAnswers: ['Не более чем на 45 календарных дней'],
      },
      {
        code: '64847040',
        text:
          'Какие из перечисленных работ в электроустановках напряжением до и выше 1000 В необходимо проводить только по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'Только на выполнение работ на сборных шинах РУ',
          'Только на выполнение работ в распределительных щитах, сборках',
          'Только на выполнение работ при полностью снятом напряжении со всех токоведущих частей в электроустановках напряжением до 1000 В',
          'Все перечисленные работы',
        ],
        correctAnswers: ['Все перечисленные работы'],
      },
      {
        code: '64847041',
        text:
          'Какую группу по электробезопасности должны иметь водители, крановщики, машинисты, стропальщики, работающие в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: ['II группу', 'III группу', 'IV группу', 'V группу'],
        correctAnswers: ['II группу'],
      },
      {
        code: '64847042',
        text:
          'В соответствии с каким документом осуществляется распределение объектов диспетчеризации по способу управления (ведения)? Выберите правильный вариант ответа.',
        answers: [
          'С Федеральным законом №35-ФЗ "Об электроэнергетике"',
          'С правилами технологического функционирования электроэнергетических систем',
          'С правилами оперативно-диспетчерского управления в электроэнергетике',
        ],
        correctAnswers: [
          'С правилами технологического функционирования электроэнергетических систем',
        ],
      },
      {
        code: '64847043',
        text:
          'С какими категориями персонала проводится подготовка по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'Со всеми категориями персонала, за исключением административно-технического',
          'Со всеми категориями персонала',
          'Со всеми категориями персонала, за исключением вспомогательного',
          'С работниками из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
        correctAnswers: [
          'С работниками из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
      },
      {
        code: '64847044',
        text:
          'С каким интервалом устанавливаются предупредительные знаки по внутреннему ограждению запретной зоны объекта топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: [
          'Не более 60 м друг от друга',
          'Не более 75 м друг от друга',
          'Не более 50 м друг от друга',
          'Не более 25 м друг от друга',
        ],
        correctAnswers: ['Не более 50 м друг от друга'],
      },
      {
        code: '64847045',
        text:
          'Какие формы работы с ремонтным персоналом должны использоваться? Выберите правильный вариант ответа.',
        answers: [
          'Только стажировка',
          'Только производственный инструктаж',
          'Только предэкзаменационная подготовка и проверка знаний',
          'Все перечисленные формы работы',
        ],
        correctAnswers: ['Все перечисленные формы работы'],
      },
      {
        code: '64847046',
        text:
          'С какой периодичностью должно проводиться дополнительное профессиональное образование работников, относящихся к категориям административно-технического, диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в год',
          'Не реже одного раза в 5 лет',
          'Не реже одного раза в 3 года',
          'Не реже одного раза в 2 года',
        ],
        correctAnswers: ['Не реже одного раза в 5 лет'],
      },
      {
        code: '64847047',
        text:
          'Кто может выполнять проверку подготовки рабочего места при отсутствии оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель работ с разрешения допускающего',
          'Руководитель работ совместно с производителем работ с разрешения оперативного персонала',
          'Производитель работ с разрешения допускающего',
          'Наблюдающий с разрешения оперативного персонала',
        ],
        correctAnswers: [
          'Руководитель работ совместно с производителем работ с разрешения оперативного персонала',
        ],
      },
      {
        code: '64847048',
        text:
          'На какой срок и сколько раз может быть продлен наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Продлевать наряд -допуск разрешается на срок не более 12 дней и не более 2 раз',
          'Продлевать наряд -допуск разрешается на срок не более 15 дней и не более 1 раза',
          'Продлевать наряд -допуск разрешается на срок не более 5 дней и не более 1 раза',
          'Продлевать наряд -допуск разрешается на срок не более 10 дней и не более 2 раз',
        ],
        correctAnswers: [
          'Продлевать наряд -допуск разрешается на срок не более 15 дней и не более 1 раза',
        ],
      },
      {
        code: '64847049',
        text:
          'Кем устанавливается порядок технологического присоединения энергопринимающих устройств и объектов электроэнергетики юридических лиц и физических лиц к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Правительство РФ',
          'Центральное управление Ростехнадзора',
          'Министерство промышленности и торговли Российской Федерации',
          'Единая национальная (общероссийская) электрическая сеть',
        ],
        correctAnswers: ['Правительство РФ'],
      },
      {
        code: '64847050',
        text:
          'На каком расстоянии вверх по течению должна находиться граница охранной зоны акватории гидроэлектростанций и гидроаккумулирующих электростанций, преграждающих плотинами и дамбами свободное течение реки (протоки) и образующих напорные водохранилища? Выберите правильный вариант ответа.',
        answers: [
          'На расстоянии 250 - 300 м от плотины или дамбы',
          'На расстоянии 180 - 200 м от плотины или дамбы',
          'На расстоянии 200 - 450 м от плотины или дамбы',
          'На расстоянии 350 - 500 м от плотины или дамбы',
        ],
        correctAnswers: ['На расстоянии 250 - 300 м от плотины или дамбы'],
      },
      {
        code: '64847051',
        text:
          'Какой из перечисленных документов не составляется в результате работы комиссии по обследованию объекта топливно-энергетического комплекса с целью анализа уязвимости объекта, выявления уязвимых мест, потенциально опасных участков, а также оценки эффективности существующей системы физической защиты? Выберите правильный вариант ответа.',
        answers: [
          'Акт обследования объекта',
          'Заключение о мерах административного взыскания лиц, ответственных за нарушение мер защиты объекта',
          'Техническое задание на проектирование (модернизацию, реконструкцию) инженерно-технических средств охраны объекта',
        ],
        correctAnswers: [
          'Заключение о мерах административного взыскания лиц, ответственных за нарушение мер защиты объекта',
        ],
      },
      {
        code: '64847052',
        text:
          'Что понимается под аварией на объекте электроэнергетики и (или) энергопринимающей установке? Выберите правильный вариант ответа.',
        answers: [
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ',
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федерального закона "О промышленной безопасности опасных производственных объектов"',
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ; отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федеральных законов "Об электроэнергетике"',
        ],
        correctAnswers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
        ],
      },
      {
        code: '64847053',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для диспетчерского, оперативного и оперативно-ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в квартал',
          'Ежемесячно',
          'Не реже 1 раза в полугодие',
          'Не реже 1 раза в неделю',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64847054',
        text:
          'В какой срок Ростехнадзор должен завершить расследование причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'В срок, не превышающий 20 календарных дней со дня начала расследования',
          'В срок, не превышающий 15 календарных дней со дня начала расследования',
          'В срок, не превышающий 30 календарных дней с момента аварии',
          'В срок, не превышающий 15 календарных дней с момента получения информации об аварии',
          'В срок, не превышающий 20 календарных дней с момента получения информации об аварии',
        ],
        correctAnswers: [
          'В срок, не превышающий 20 календарных дней со дня начала расследования',
        ],
      },
      {
        code: '64847055',
        text:
          'Каковы условия проведения специальной подготовки персонала? Выберите правильный вариант ответа.',
        answers: [
          'Должна проводиться с отрывом от выполнения основных функций не реже одного раза в месяц и составлять от 5 до 20% его рабочего времени',
          'Должна проводиться еженедельно в течение одной рабочей смены и составлять до 30% рабочего времени',
          'Должна проводиться без отрыва от рабочего процесса',
          'Должна проводиться еженедельно в течение одной рабочей смены с отрывом от производства',
        ],
        correctAnswers: [
          'Должна проводиться с отрывом от выполнения основных функций не реже одного раза в месяц и составлять от 5 до 20% его рабочего времени',
        ],
      },
      {
        code: '64847056',
        text:
          'Кто несет ответственность за работу с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель кадровой службы',
          'Главный инженер организации',
          'Руководитель организации или должностное лицо (должностные лица) из числа руководящих работников организации, которому (которым) руководитель организации передает эту функцию и права',
          'Руководители подразделений',
        ],
        correctAnswers: [
          'Руководитель организации или должностное лицо (должностные лица) из числа руководящих работников организации, которому (которым) руководитель организации передает эту функцию и права',
        ],
      },
      {
        code: '64847057',
        text:
          'При каком условии допускается разъединять провод и тяговый канат в ходе работ на воздушной линии? Выберите правильный вариант ответа.',
        answers: [
          'После завершения натяжения участка воздушной линии',
          'Только после уравнивания их потенциалов, то есть после соединения каждого из них с общим заземлителем',
          'Если исключена возможность падения провода или троса',
          'После соединения провода с заземлителем',
        ],
        correctAnswers: [
          'Только после уравнивания их потенциалов, то есть после соединения каждого из них с общим заземлителем',
        ],
      },
      {
        code: '64847058',
        text:
          'Какие организации электроэнергетики должны разработать порядок проведения работы с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Все организации электроэнергетики',
          'Только предприятия гидроэнергетического комплекса',
          'Только предприятия теплоэнергетического комплекса',
          'Только гидроэлектростанции',
        ],
        correctAnswers: ['Все организации электроэнергетики'],
      },
      {
        code: '64847059',
        text:
          'Какие требования предъявляются к командированному персоналу? Выберите правильный вариант ответа.',
        answers: [
          'Персонал должен иметь профессиональную подготовку',
          'Персонал должен иметь удостоверения установленной формы о проверке знаний норм и правил работы в электроустановках с отметкой о группе электробезопасности',
          'Персонал должен быть обучен и аттестован по охране труда и промышленной безопасности',
          'Персонал должен быть не моложе 18 лет',
        ],
        correctAnswers: [
          'Персонал должен иметь удостоверения установленной формы о проверке знаний норм и правил работы в электроустановках с отметкой о группе электробезопасности',
        ],
      },
      {
        code: '64847060',
        text:
          'На каком расстоянии вниз по течению должна находиться граница охранной зоны акватории гидроэлектростанций и гидроаккумулирующих электростанций, преграждающих плотинами и дамбами свободное течение реки (протоки) и образующих напорные водохранилища? Выберите правильный вариант ответа.',
        answers: [
          'На расстоянии 250 - 300 м от плотины или дамбы',
          'На расстоянии 150 - 250 м от плотины или дамбы',
          'На расстоянии 180 - 200 м от плотины или дамбы',
          'На расстоянии 350 - 450 м от плотины или дамбы',
        ],
        correctAnswers: ['На расстоянии 180 - 200 м от плотины или дамбы'],
      },
      {
        code: '64847061',
        text:
          'Когда должна осуществляться подготовка персонала для обслуживания новых и реконструируемых объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'После проведения пробных пусков',
          'Во время функциональных испытаний',
          'До начала индивидуальных испытаний',
          'С опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
        ],
        correctAnswers: [
          'С опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
        ],
      },
      {
        code: '64847062',
        text:
          'Кем утверждается перечень работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Руководителем организации или руководителем обособленного подразделения',
          'Ответственным за электрохозяйство организации',
          'Техническим руководителем',
        ],
        correctAnswers: [
          'Руководителем организации или руководителем обособленного подразделения',
        ],
      },
      {
        code: '64847063',
        text:
          'К каким производственным объектам в соответствии с Градостроительным законодательством Российской Федерации относятся линии электропередачи и иные объекты электросетевого хозяйства напряжением 330кВ? Выберите правильный вариант ответа.',
        answers: [
          'К особо опасным и технически сложным объектам',
          'К уникальным объектам',
          'К объектам высокой опасности',
          'К объектам значительного риска',
        ],
        correctAnswers: ['К особо опасным и технически сложным объектам'],
      },
      {
        code: '64847064',
        text:
          'С какой вероятностью периметральные средства обнаружения должны обнаруживать несанкционированное проникновение нарушителя? Выберите правильный вариант ответа.',
        answers: [
          'С вероятностью не ниже 0,95',
          'С вероятностью не ниже 0,75',
          'С вероятностью не ниже 0,65',
          'С вероятностью не ниже 0,90',
        ],
        correctAnswers: ['С вероятностью не ниже 0,95'],
      },
      {
        code: '64847065',
        text:
          'Какие технические мероприятия необходимо соблюдать перед производством огневых работ на емкостях или внутри них и на трубопроводах, в которых находились легковоспламеняющиеся и горючие материалы? Выберите правильный вариант ответа.',
        answers: [
          'Только закрытие ближайших задвижек или шиберов, запирание их на замок, после чего на них вывешиваются соответствующие плакаты',
          'Только установка (при необходимости) заглушки, удаление (очистка от) остатков находящихся в них веществ',
          'Только организация и проведение пропарки или промывки пожаробезопасными растворами',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '64847066',
        text:
          'Кто должен назначаться допускающим в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа оперативного персонала',
          'Работник из числа ремонтного персонала',
          'Работник из числа оперативно-ремонтного персонала',
          'Работник из числа административно-технического персонала',
        ],
        correctAnswers: ['Работник из числа оперативного персонала'],
      },
      {
        code: '64847067',
        text:
          'Каким образом оформляется наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только письменно в присутствии производителя работ в трех экземплярах, передача по телефону или радио не допускается',
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
          'В двух экземплярах, независимо от способа передачи',
          'В трех экземплярах, независимо от способа передачи',
        ],
        correctAnswers: [
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
        ],
      },
      {
        code: '64847068',
        text:
          'Кто проводит первичный инструктаж командированному персоналу при проведении работ в электроустановках до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала (руководящих работников и специалистов), имеющий группу IV по электробезопасности',
          'Работник организации - владельца электроустановок из числа электротехнического персонала, имеющий группу IV по электробезопасности',
          'Работник организации - владельца электроустановок из числа оперативно-ремонтного персонала, имеющий группу IV по электробезопасности',
          'Работник командирующей организации из числа административно-технического персонала, имеющий группу IV по электробезопасности',
        ],
        correctAnswers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала (руководящих работников и специалистов), имеющий группу IV по электробезопасности',
        ],
      },
      {
        code: '64847069',
        text:
          'При какой грузоподъемности автомобильного грузового и общественного транспорта его сквозной проезд без контроля и досмотра по плотине (дамбе) гидротехнического сооружения должен быть исключен? Выберите правильный вариант ответа.',
        answers: [
          'При грузоподъемности более 1,5 т',
          'При грузоподъемности более 3,5 т',
          'При грузоподъемности более 1 т',
          'При грузоподъемности более 2 т',
        ],
        correctAnswers: ['При грузоподъемности более 3,5 т'],
      },
      {
        code: '64847070',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа ремонтного персонала, имеющий группу не ниже IV',
          'Работник из числа административно-технического персонала, имеющий группу IV',
          'Работник из числа оперативного персонала, имеющий группу по электробезопасности не ниже III, осуществляющий оперативное обслуживание данной электроустановки, находящийся на дежурстве, либо работник из числа административно-технического персонала, имеющий группу V по электробезопасности - при эксплуатации электроустановки напряжением выше 1000 В, группу IV по электробезопасности - при эксплуатации электроустановки напряжением до 1000 В',
          'Работник, имеющий группу IV и право единоличного осмотра на основании письменного распоряжения руководителя организации',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, имеющий группу по электробезопасности не ниже III, осуществляющий оперативное обслуживание данной электроустановки, находящийся на дежурстве, либо работник из числа административно-технического персонала, имеющий группу V по электробезопасности - при эксплуатации электроустановки напряжением выше 1000 В, группу IV по электробезопасности - при эксплуатации электроустановки напряжением до 1000 В',
        ],
      },
      {
        code: '64847071',
        text:
          'До какой глубины боносетевое заграждение гидротехнического объекта должно обеспечивать задержание самодвижущихся подводных средств? Выберите правильный вариант ответа.',
        answers: [
          'До 1,5 м от дна',
          'До 8 м от поверхности воды',
          'До 15 м от поверхности воды',
          'До 10 м от поверхности воды',
        ],
        correctAnswers: ['До 10 м от поверхности воды'],
      },
      {
        code: '64847072',
        text:
          'На каком расстоянии от трассы кабеля допускается проведение землеройных работ механизмами ударного действия, если эти работы не связаны с раскопкой кабеля? Выберите правильный вариант ответа.',
        answers: [
          'На расстоянии менее 4 м от трассы кабеля',
          'На расстоянии более 5 м от трассы кабеля',
          'На расстоянии менее 3 м от трассы кабеля',
          'На расстоянии менее 1 м от трассы кабеля',
        ],
        correctAnswers: ['На расстоянии более 5 м от трассы кабеля'],
      },
      {
        code: '64847073',
        text:
          'Для каких категорий работников проводится стажировка? Выберите правильный вариант ответа.',
        answers: [
          'Для всех категорий, за исключением вспомогательного',
          'Для всех категорий персонала, кроме оперативного',
          'Только для диспетчерского',
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
        correctAnswers: [
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64847074',
        text:
          'Кому дано право утверждать технологические характеристики объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, порядок ведения реестра указанных объектов? Выберите правильный вариант ответа.',
        answers: [
          'РАО ЕЭС',
          'Правительству Российской Федерации',
          'Министерством энергетики Российской Федерации',
          'Центральному  управлению Ростехнадзора',
        ],
        correctAnswers: ['Правительству Российской Федерации'],
      },
      {
        code: '64847075',
        text:
          'Расследования каких аварий осуществляют собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация? Выберите правильный вариант ответа.',
        answers: [
          'Повреждение генератора установленной мощностью 10 МВт и более с разрушением его статора, ротора, изоляции обмоток статора, изоляции обмоток ротора',
          'Нарушение, приводящее к потере управляемости объекта электроэнергетики (потеря питания собственных нужд, оперативного тока, давления в магистралях сжатого воздуха, систем управления оборудованием) продолжительностью 1 час и более',
          'Повреждение силового трансформатора (автотрансформатора) мощностью 10 МВА и более с разрушением, изменением формы и геометрических размеров или смещением его корпуса',
          'Обрушение несущих элементов технологических зданий, сооружений объекта электроэнергетики и (или) энергопринимающей установки, в том числе произошедшее вследствие взрыва или пожара, если такое обрушение привело к введению аварийного ограничения режима потребления электрической и (или) тепловой энергии (мощности)',
        ],
        correctAnswers: [
          'Нарушение, приводящее к потере управляемости объекта электроэнергетики (потеря питания собственных нужд, оперативного тока, давления в магистралях сжатого воздуха, систем управления оборудованием) продолжительностью 1 час и более',
        ],
      },
      {
        code: '64847076',
        text:
          'В какой срок Ростехнадзор или его территориальный орган, принявшие решение о расследовании причин аварии, уведомляют об этом уполномоченный орган в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 48 часов с момента принятия такого решения',
          'Не позднее 36 часов с момента принятия такого решения',
          'Не позднее 24 часов с момента аварии',
          'Не позднее 48 часов с момента создания комиссии по расследованию причин аварии',
        ],
        correctAnswers: [
          'Не позднее 48 часов с момента принятия такого решения',
        ],
      },
      {
        code: '64847077',
        text:
          'Когда может быть снято заземление с провода (грозотроса) на промежуточной опоре при выполнении работ на воздушной линии? Выберите правильный вариант ответа.',
        answers: [
          'По окончании работы на опоре',
          'После полного окончания работ на воздушной линии',
          'После снятия заземления с соседних опор',
        ],
        correctAnswers: ['По окончании работы на опоре'],
      },
      {
        code: '64847078',
        text:
          'Что из перечисленного должен в обязательном порядке делать допускающий перед допуском к работе на электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только убедиться в выполнении технических мероприятий по подготовке рабочего места путем личного осмотра, по записям в оперативном журнале, по оперативной схеме и по сообщениям лица, выдающего разрешение на подготовку рабочего места',
          'Только провести целевой инструктаж при работах по наряду ответственному руководителю работ, производителю работ (наблюдающему) и членам бригады',
          'Только доказать бригаде, что напряжение отсутствует, с помощью демонстрации установленных заземлений или проверкой отсутствия напряжения, если заземления не видны с рабочего места',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64847079',
        text:
          'В течение какого срока должны храниться наряды-допуски, работы по которым полностью закончены, если при выполнении работ по этим нарядам-допускам не имели место аварии, инциденты или несчастные случаи? Выберите правильный вариант ответа.',
        answers: [
          'В течение 1 года',
          'В течение 30 суток',
          'В течение 60 суток',
          'В течение 3 недель',
        ],
        correctAnswers: ['В течение 1 года'],
      },
      {
        code: '64847080',
        text:
          'При каких температурах разрешается пользоваться фильтрующими противогазами с гопкалитовым патроном для защиты от окиси углерода? Выберите правильный вариант ответа.',
        answers: [
          'При температурах не ниже 6 °С',
          'При температурах не ниже 2 °С',
          'При температурах не ниже 10 °С',
          'При любых температурах',
        ],
        correctAnswers: ['При температурах не ниже 6 °С'],
      },
      {
        code: '64847081',
        text:
          'Какие мероприятия обязательно осуществляются перед допуском к проведению неотложных работ? Выберите правильный вариант ответа.',
        answers: [
          'Проверка количественного и качественного состава бригады',
          'Технические мероприятия по подготовке рабочего места',
          'Проведение целевого инструктажа',
          'Оформление наряда-допуска',
        ],
        correctAnswers: [
          'Технические мероприятия по подготовке рабочего места',
        ],
      },
      {
        code: '64847082',
        text:
          'Кем устанавливается порядок проведения обходов и осмотров рабочих мест в энергетических организациях? Выберите правильный вариант ответа.',
        answers: [
          'Руководителем отдела метрологического обеспечения',
          'Руководителем организации или уполномоченным им должностным лицом',
          'Представителем организации электроэнергетики',
          'Уполномоченным представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Руководителем организации или уполномоченным им должностным лицом',
        ],
      },
      {
        code: '64847083',
        text:
          'Кого уведомляет собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки, либо эксплуатирующая их организация о возникновении аварии? Выберите правильный вариант ответа.',
        answers: [
          'Только диспетчерский центр субъекта оперативно-диспетчерского управления в электроэнергетике, в операционной зоне которого находится объект электроэнергетики и (или) энергопринимающая установка',
          'Только орган федерального государственного энергетического надзора',
          'Только уполномоченный орган в сфере электроэнергетики',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '64847084',
        text:
          'С каким персоналом в организации должен проводиться производственный инструктаж? Выберите правильный вариант ответа.',
        answers: [
          'Только с командированными, студентами и учащимися, прибывшими на предприятие для производственного обучения или практики',
          'Только работникам, принимаемым на должности, не связанные с нахождением в зоне действующих энергоустановок и не связанных с их обслуживанием',
          'С работниками, относящимися к категории оперативно-ремонтного и ремонтного персонала',
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
        correctAnswers: [
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64847085',
        text:
          'Кто осуществляет оперативно-диспетчерское управление в пределах Единой энергетической системы России? Выберите правильный вариант ответа.',
        answers: [
          'РАО ЕЭС',
          'Правительство Российской Федерации',
          'Министерство энергетики Российской Федерации',
          'Центральное  управление Ростехнадзора',
        ],
        correctAnswers: ['РАО ЕЭС'],
      },
      {
        code: '64847086',
        text:
          'К каким производственным объектам в соответствии с Градостроительным законодательством Российской Федерации относятся гидротехнические сооружения первого и второго классов, устанавливаемые в соответствии с законодательством о безопасности гидротехнических сооружений? Выберите правильный вариант ответа.',
        answers: [
          'К особо опасным и технически сложным объектам',
          'К уникальным объектам',
          'К объектам высокой опасности',
          'К объектам значительного риска',
        ],
        correctAnswers: ['К особо опасным и технически сложным объектам'],
      },
      {
        code: '64847087',
        text:
          'Что из перечисленного относится к полномочиям органов исполнительной власти субъектов Российской Федерации в области безопасности гидротехнических сооружений? Выберите правильный вариант ответа.',
        answers: [
          'Только разработка и реализация региональных программ обеспечения безопасности гидротехнических сооружений, в том числе гидротехнических сооружений, которые не имеют собственника или собственник которых неизвестен, либо от права собственности на которые собственник отказался',
          'Только обеспечение безопасности гидротехнических сооружений при использовании водных объектов и осуществлении природоохранных мероприятий',
          'Только принятие решений об ограничении условий эксплуатации гидротехнических сооружений в случаях нарушений законодательства о безопасности гидротехнических сооружений',
          'Все перечисленное, а также информирование населения об угрозе возникновения аварий гидротехнических сооружений, которые могут привести к возникновению чрезвычайных ситуаций',
        ],
        correctAnswers: [
          'Все перечисленное, а также информирование населения об угрозе возникновения аварий гидротехнических сооружений, которые могут привести к возникновению чрезвычайных ситуаций',
        ],
      },
      {
        code: '64847088',
        text:
          'На какой срок выдается распоряжение на производство работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Распоряжение выдается на 1 рабочую смену',
          'Распоряжение имеет разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
          'Распоряжение выдается на 5 суток',
          'Распоряжение выдается на 15 суток',
        ],
        correctAnswers: [
          'Распоряжение имеет разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
        ],
      },
      {
        code: '64847089',
        text:
          'С какой периодичностью проводятся учения с оценкой эффективности защиты объекта топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в 4 года',
          'Не реже 1 раза в год',
          'В рамках подготовки к работе в осенне-зимний и весенне-летний периоды',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '64847090',
        text:
          'Кто предоставляет командированному персоналу привлекаемой организации права работы в действующих электроустановках в качестве выдающих наряды-допуски и распоряжения, ответственных руководителей, производителей работ, членов бригады? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель командирующей организации',
          'Руководитель организации (обособленного подразделения) - владелец электроустановки на письме командирующей организации или ОРД организации (обособленного подразделения)',
          'Ответственный за электрохозяйство командирующей организации',
          'Технический руководитель командирующей организации',
        ],
        correctAnswers: [
          'Руководитель организации (обособленного подразделения) - владелец электроустановки на письме командирующей организации или ОРД организации (обособленного подразделения)',
        ],
      },
      {
        code: '64847091',
        text:
          'Какое минимальное количество членов комиссии организации по проверке знаний должно присутствовать при проведении процедуры проверки знаний работников организаций электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не менее двух членов комиссии организации по проверке знаний',
          'Не менее трех членов комиссии организации по проверке знаний, имеющих группу по электробезопасности',
          'Не менее пяти членов комиссии организации по проверке знаний',
          'Не менее четырех членов комиссии организации по проверке знаний',
        ],
        correctAnswers: [
          'Не менее трех членов комиссии организации по проверке знаний, имеющих группу по электробезопасности',
        ],
      },
      {
        code: '64847092',
        text:
          'Какой должна быть высота предупредительного ограждения, которым оборудуются отдельные участки территории и критические элементы объекта топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: ['1,5 - 2,0 м', '2,0 - 2,5 м', '1,7 - 1,9 м', '0,9 - 1,3 м'],
        correctAnswers: ['1,5 - 2,0 м'],
      },
      {
        code: '64847093',
        text:
          'В какие сроки проводится первичная проверка знаний работников, относящихся к категории административно-технического персонала или вспомогательного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 1 недели после назначения на должность',
          'Не позднее 3 месяцев после назначения на должность',
          'Не позднее 1 месяца после назначения на должность',
          'Не позднее 2 недель после назначения на должность',
        ],
        correctAnswers: ['Не позднее 1 месяца после назначения на должность'],
      },
      {
        code: '64847094',
        text:
          'Что из перечисленного является основанием для выдачи разрешения на эксплуатацию гидротехнического сооружения, находящегося в эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Согласование с федеральными органами исполнительной власти, уполномоченными на проведение федерального государственного надзора в области безопасности гидротехнических сооружений, разработанных собственником гидротехнического сооружения или эксплуатирующей организацией правил эксплуатации сооружения',
          'Заключение собственником гидротехнического сооружения или эксплуатирующей организацией договора водопользования в соответствии с Водным кодексом Российской Федерации',
          'Внесение в Регистр сведений о гидротехническом сооружении, находящемся в эксплуатации',
        ],
        correctAnswers: [
          'Внесение в Регистр сведений о гидротехническом сооружении, находящемся в эксплуатации',
        ],
      },
      {
        code: '64847095',
        text:
          'На какое расстояние от уреза воды должны заходить в воду участки предупредительного ограждения гидротехнического объекта? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 8 м',
          'Не менее 3 и не более 6 м',
          'Не менее 6 м',
          'Не менее 10 м',
        ],
        correctAnswers: ['Не менее 6 м'],
      },
      {
        code: '64847096',
        text:
          'Кто является ответственным за безопасное ведение работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только ответственный руководитель работ, допускающий, производитель работ, наблюдающий',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
          'Только выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, ответственный руководитель работ, допускающий, производитель работ, наблюдающий',
          'Только ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
        correctAnswers: [
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
      },
      {
        code: '64847097',
        text:
          'Кто имеет право включать электроустановки после полного окончания работ? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
          'Производитель работ',
          'Любой из членов бригады',
          'Только ответственный за электрохозяйство',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
        ],
      },
      {
        code: '64847098',
        text:
          'На какие классы не подразделяются пожароопасные зоны? Выберите правильный вариант ответа.',
        answers: [
          'П-Iа - зоны, расположенные в помещениях, в которых при нормальной эксплуатации образуются взрывоопасные смеси горючих газов или паров ЛВЖ с воздухом',
          'П-I - зоны, расположенные в помещениях, в которых обращаются горючие жидкости с температурой вспышки 61 °C и более',
          'П-II - зоны, расположенные в помещениях, в которых выделяются горючие пыли или волокна',
          'П-IIа - зоны, расположенные в помещениях, в которых обращаются твердые горючие вещества в количестве, при котором удельная пожарная нагрузка составляет не менее 1 мегаджоуля на квадратный метр',
          'П-III - зоны, расположенные вне зданий, сооружений, в которых обращаются горючие жидкости с температурой вспышки 61 °C и более или любые твердые горючие вещества',
        ],
        correctAnswers: [
          'П-Iа - зоны, расположенные в помещениях, в которых при нормальной эксплуатации образуются взрывоопасные смеси горючих газов или паров ЛВЖ с воздухом',
        ],
      },
      {
        code: '64847099',
        text:
          'Каким образом оформляется решение о расследовании причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
          'Приказом владельца объекта электроэнергетики',
          'Актом комиссии объекта электроэнергетики',
          'Решением уполномоченного органа в сфере электроэнергетики',
        ],
        correctAnswers: [
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
        ],
      },
      {
        code: '64847100',
        text:
          'Что из перечисленного является определением «зоны (полосы) отторжения» объекта топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: [
          'Территория, в пределах которой устанавливается специальный режимохраны размещаемых объектов',
          'Зона, непосредственно примыкающая к инженерным ограждениям объекта и свободная от построек, деревьев, кустарника и др.',
          'Специальная территория с особым режимом использования, которая устанавливается вокруг объектов и производств, являющихся источниками воздействия на среду обитания и здоровье человека',
          'Земельные участки, участки недр, воздушного пространства, участки водных объектов и береговых полос водных объектов, в отношении которых в соответствии с законодательством установлены особые условия использования',
        ],
        correctAnswers: [
          'Зона, непосредственно примыкающая к инженерным ограждениям объекта и свободная от построек, деревьев, кустарника и др.',
        ],
      },
      {
        code: '64847101',
        text:
          'Каким образом должны производиться неотложные работы в электроустановках напряжением до и выше 1000 В, для выполнения которых требуется более 1 часа или участия более трех человек? Выберите правильный вариант ответа.',
        answers: [
          'В порядке текущей эксплуатации',
          'По распоряжению оперативным персоналом',
          'По наряду-допуску',
          'Под наблюдением ремонтным персоналом',
        ],
        correctAnswers: ['По наряду-допуску'],
      },
      {
        code: '64847102',
        text:
          'С какой периодичностью собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация представляют сводный отчет об авариях в электроэнергетике в орган федерального государственного энергетического надзора, уполномоченный орган в сфере электроэнергетики, а также субъекту оперативно-диспетчерского управления в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Ежемесячно',
          '1 раз в 6 месяцев',
          '1 раз в год',
          '1 раз в 3 месяца',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64847103',
        text:
          'Допускается ли применение инженерных заграждений, которые могут оказывать активное воздействие на нарушителя для защиты объектов топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: [
          'Не допускается',
          'Допускается только на местности, плохо осматриваемой работниками службы охраны',
          'Допускается только на объектах высокой и средней категории опасности',
          'Допускается',
        ],
        correctAnswers: ['Допускается'],
      },
      {
        code: '64847104',
        text:
          'Что из перечисленного не входит в технологическую основу функционирования электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Единая национальная (общероссийская) электрическая сеть',
          'Территориальные распределительные сети',
          'Система отношений, связанных с производством и оборотом электроэнергии на оптовом рынке',
          'Единая система оперативно-диспетчерского управления',
        ],
        correctAnswers: [
          'Система отношений, связанных с производством и оборотом электроэнергии на оптовом рынке',
        ],
      },
      {
        code: '64847105',
        text:
          'Как необходимо переносить стеклянные бутыли с кислотами и щелочами в аккумуляторных помещениях? Выберите правильный вариант ответа.',
        answers: [
          'В специальном металлическом ящике',
          'С помощью носилок',
          'В специальном деревянном ящике с ручками или на специальных носилках с отверстием посередине и обрешеткой',
          'На тележках одним работником',
        ],
        correctAnswers: [
          'В специальном деревянном ящике с ручками или на специальных носилках с отверстием посередине и обрешеткой',
        ],
      },
      {
        code: '64847106',
        text:
          'В каком случае разрешается использовать для проживания людей производственные и складские здания, расположенные на территориях предприятий? Выберите правильный вариант ответа.',
        answers: [
          'Запрещается в любом случае',
          'В случае обеспечения помещений вентиляцией и первичными средствами пожаротушения',
          'В случае проведения экстренных ремонтных работ на объекте',
          'В случае такого решения руководителя организации',
        ],
        correctAnswers: ['Запрещается в любом случае'],
      },
      {
        code: '64847107',
        text:
          'До какого числа системный оператор должен направить в сетевые организации требования к графикам аварийного ограничения? Выберите правильный вариант ответа.',
        answers: ['До 1 июня', 'До 1 июля', 'До 10 июня', 'До 10 июля'],
        correctAnswers: ['До 1 июля'],
      },
      {
        code: '64847108',
        text:
          'Какое из перечисленных обстоятельств не является основанием для объявления режима с высокими рисками нарушения электроснабжения? Выберите правильный вариант ответа.',
        answers: [
          'Угроза нарушения устойчивости энергосистемы или ее частей',
          'Понижение до аварийно допустимых значений уровней напряжения в контрольных пунктах диспетчерского центра',
          'Единичные повреждения линий электропередачи',
          'Прогнозируемое наступление неблагоприятных природных явлений в регионе, которые могут привести к массовому отключению линий электропередачи и электросетевого оборудования',
        ],
        correctAnswers: ['Единичные повреждения линий электропередачи'],
      },
      {
        code: '64847109',
        text:
          'Какое определение соответствует термину "дублирование"? Выберите правильный вариант работы. Выберите правильный вариант ответа.',
        answers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
          'Практическое освоение непосредственно на рабочем месте навыков выполнения работы или группы работ, приобретенных при профессиональной подготовке',
          'Одна из форм дополнительного повышения образовательного уровня оперативного персонала, осуществляемая путем систематического самообразования',
          'Форма поддержания квалификации работника путем его систематической тренировки в управлении производственными процессами на учебно-тренировочных средствах, формирования его знаний, умения и навыков, проработки организационно-распорядительных документов и разборки технологических нарушений, пожаров и случаев производственного травматизма',
        ],
        correctAnswers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
        ],
      },
      {
        code: '64847110',
        text:
          'Кем проводится расследование группового несчастного случая с числом погибших более пяти человек в результате аварии на производстве, эксплуатирующем электрические сети? Выберите правильный вариант ответа.',
        answers: [
          'Комиссией, в которую входят представители работодателя, профсоюзной организации и Ростехнадзора. Состав комиссии утверждает и возглавляет работодатель или его представитель',
          'Комиссией, в которую входят представители работодателя, Ростехнадзора, государственной инспекции труда. Состав комиссии утверждает руководитель государственной инспекции труда, а возглавляет комиссию представитель Ростехнадзора',
          'Комиссией, в которую входят представители работодателя, Ростехнадзора, государственной инспекции труда, фонда социального страхования. Состав комиссии утверждает руководитель территориального органа Ростехнадзора, а возглавляет комиссию представитель государственной инспекции труда',
          'Комиссией, в которую входят представители работодателя, Ростехнадзора, представители соответствующей государственной инспекции труда, представители органа исполнительной власти субъекта Российской Федерации или органа местного самоуправления (по согласованию), представитель территориального объединения организаций профсоюзов. Возглавляет комиссию руководитель Ростехнадзора',
        ],
        correctAnswers: [
          'Комиссией, в которую входят представители работодателя, Ростехнадзора, представители соответствующей государственной инспекции труда, представители органа исполнительной власти субъекта Российской Федерации или органа местного самоуправления (по согласованию), представитель территориального объединения организаций профсоюзов. Возглавляет комиссию руководитель Ростехнадзора',
        ],
      },
      {
        code: '64847111',
        text:
          'Кто осуществляет допуск к работам на кабельных линиях, расположенных в распределительном устройстве, если распределительное устройство и кабельные линии принадлежат разным организациям? Выберите правильный вариант ответа.',
        answers: [
          'Персонал, обслуживающий распределительное устройство и кабельные линии',
          'Персонал, обслуживающий кабельные линии',
          'Персонал, обслуживающий РУ',
          'Персонал, обслуживающий РУ или КЛ',
        ],
        correctAnswers: ['Персонал, обслуживающий РУ'],
      },
      {
        code: '64847112',
        text:
          'Кто несет ответственность за проведение целевого инструктажа ответственному руководителю работ? Выберите правильный вариант ответа.',
        answers: [
          'Работник, выдающий наряд-допуск (распоряжение)',
          'Работник, наблюдающий за работой бригады',
          'Работник, ответственный за производственный контроль',
          'Специалист по охране труда',
        ],
        correctAnswers: ['Работник, выдающий наряд-допуск (распоряжение)'],
      },
      {
        code: '64847113',
        text:
          'Какие из перечисленных требований предъявляются к сотрудникам службы безопасности и подразделений охраны объектов топливно-энергетического комплекса? Выберите правильный вариант ответа.',
        answers: [
          'Гражданство Российской Федерации и возраст не моложе 18 лет',
          'Гражданство Российской Федерации и возраст не моложе 21 лет',
          'Гражданство Российской Федерации, специальное обучение и возраст не моложе 21 года',
          'Гражданство Российской Федерации и возраст не моложе 25 лет',
        ],
        correctAnswers: [
          'Гражданство Российской Федерации и возраст не моложе 18 лет',
        ],
      },
    ],
    64849: [
      {
        code: '64849000',
        text:
          'Кем определяется изменение формы организации круглосуточного оперативного обслуживания объекта электроэнергетики, в состав которого входят объекты диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'Субъектом оперативно-диспетчерского управления',
          'Владельцем объекта электроэнергетики',
          'Владельцем объекта электроэнергетики по согласованию с субъектом оперативно-диспетчерского управления',
        ],
        correctAnswers: [
          'Владельцем объекта электроэнергетики по согласованию с субъектом оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64849001',
        text:
          'На какой период времени разрабатываются графики аварийного ограничения режима потребления электрической энергии? Выберите правильный вариант ответа.',
        answers: [
          'На период с 1 октября текущего года по 30 сентября следующего года',
          'На период с 1 января по 31 декабря следующего года',
          'На период с 1 сентября текущего года по 31 августа следующего года',
          'На период с 1 ноября текущего года по 31 октября следующего года',
        ],
        correctAnswers: [
          'На период с 1 октября текущего года по 30 сентября следующего года',
        ],
      },
      {
        code: '64849002',
        text:
          'До какого числа системный оператор должен направить в сетевые организации требования к графикам аварийного ограничения? Выберите правильный вариант ответа.',
        answers: ['До 1 июня', 'До 1 июля', 'До 15 июня', 'До 15 июля'],
        correctAnswers: ['До 1 июля'],
      },
      {
        code: '64849003',
        text:
          'Должны ли быть уведомлены потребители соответствующими организациями, осуществляющими фактические действия по вводу аварийных ограничений, об утвержденных графиках аварийного ограничения? Выберите правильный вариант ответа.',
        answers: [
          'Не должны',
          'Должны, не позднее чем за 7 дней до начала очередного периода их действия',
          'Должны, не позднее чем за 15 дней до начала очередного периода их действия',
          'Должны, в порядке, предусмотренном договором оказания услуг по передаче электрической энергии либо договором энергоснабжения',
        ],
        correctAnswers: [
          'Должны, не позднее чем за 7 дней до начала очередного периода их действия',
        ],
      },
      {
        code: '64849004',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в месяц',
          'Не реже 1 раза в 6 месяцев',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '64849005',
        text:
          'Кто осуществляет региональный государственный контроль за применением регулируемых цен (тарифов) на электрическую энергию? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: [
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '64849006',
        text:
          'От каких факторов не зависит необходимость и длительность каждого этапа подготовки по новой должности оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'От квалификации работника',
          'От возраста работника',
          'От стажа работы по смежным должностям (профессиям)',
          'От уровня его знаний и технической сложности объекта',
        ],
        correctAnswers: ['От возраста работника'],
      },
      {
        code: '64849007',
        text:
          'Каким образом оформляется наряд на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только письменно в присутствии производителя работ в трех экземплярах, передача по телефону или радио не допускается',
          'В двух экземплярах, независимо от способа передачи',
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
          'В трех экземплярах, независимо от способа передачи',
        ],
        correctAnswers: [
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
        ],
      },
      {
        code: '64849008',
        text:
          'Разрешается ли оперативному персоналу единолично выполнять переключения в электроустановках для предотвращения развития и ликвидации нарушений нормального режима? Выберите правильный вариант ответа.',
        answers: [
          'Разрешается, если порядок оперативного обслуживания объекта электроэнергетики предусматривает наличие одного работника из числа оперативного персонала в смене',
          'Не разрешается',
          'Разрешается',
        ],
        correctAnswers: [
          'Разрешается, если порядок оперативного обслуживания объекта электроэнергетики предусматривает наличие одного работника из числа оперативного персонала в смене',
        ],
      },
      {
        code: '64849009',
        text:
          'Нормальной схемой энергосистемы является схема, в которой … ? Выберите правильный вариант ответа.',
        answers: [
          'Все ЛЭП и электросетевое оборудование, определяющие устойчивость, находятся в работе',
          'Все ЛЭП, электросетевое оборудование и устройства противоаварийной автоматики, определяющие устойчивость, находятся в работе',
        ],
        correctAnswers: [
          'Все ЛЭП и электросетевое оборудование, определяющие устойчивость, находятся в работе',
        ],
      },
      {
        code: '64849010',
        text:
          'Кем не производятся действия по временному отключению потребления в порядке и сроки, предусмотренные графиками временного отключения потребления по команде системного оператора? Выберите правильный вариант ответа.',
        answers: [
          'Оперативным персоналом сетевых организаций',
          'Оперативным персоналом объектов по производству электрической энергии',
          'Диспетчерским персоналом',
          'Оперативным персоналом потребителей',
        ],
        correctAnswers: ['Диспетчерским персоналом'],
      },
      {
        code: '64849011',
        text:
          'До какого числа сетевые организации должны сформировать перечни потребителей, аварийное ограничение нагрузки потребления которых осуществляется сетевыми организациями в соответствии с графиками аварийного ограничения? Выберите правильный вариант ответа.',
        answers: ['До 1 августа', 'До 1 июня', 'До 15 июня', 'До 15 июля'],
        correctAnswers: ['До 1 июня'],
      },
      {
        code: '64849012',
        text:
          'Кто вправе рассматривать жалобы поставщиков и покупателей электрической и тепловой энергии о нарушениях их прав и законных интересов действиями (бездействием) иных субъектов электроэнергетики, а также запрашивать информацию, документы и иные доказательства, свидетельствующие о наличии признаков таких нарушений? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Федеральные органы исполнительной власти'],
      },
      {
        code: '64849013',
        text:
          'Какой персонал должен проходить дублирование? Выберите правильный вариант ответа.',
        answers: [
          'Лица, непосредственно связанные с ремонтом и техническим обслуживанием энергетического оборудования, после первичной проверки знаний',
          'Лица из числа ремонтного персонала',
          'Специалисты, связанные с наладкой и испытанием энергоустановок, после первичной проверки знаний',
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
        correctAnswers: [
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64849014',
        text:
          'Какие отключения оборудования объекта электросетевого хозяйства, приводящие к снижению надежности энергосистемы, расследуются Ростехнадзором либо его территориальными органами? Выберите правильный вариант ответа.',
        answers: [
          'Разделение энергосистемы на части, выделение отдельных энергорайонов Российской Федерации с переходом на изолированную от Единой энергетической системы России работу (при отключении всех электрических связей с Единой энергетической системой России)',
          'Превышение максимально допустимых перетоков мощности в контролируемом сечении длительностью 1 час и более',
          'Применение графиков временных отключений суммарным объемом 100 МВт и более или прекращение электроснабжения на величину 25 и более процентов общего объема потребления в операционной зоне диспетчерского центра',
          'Все указанные отключения расследуются Ростехнадзором либо его территориальными органами',
        ],
        correctAnswers: [
          'Все указанные отключения расследуются Ростехнадзором либо его территориальными органами',
        ],
      },
      {
        code: '64849015',
        text:
          'Какие формы работы с административно-техническим персоналом не проводятся? Выберите правильный вариант ответа.',
        answers: [
          'Дублирование и специальная подготовка',
          'Производственный инструктаж',
          'Предэкзаменационная подготовка',
          'Проверка знаний',
        ],
        correctAnswers: ['Дублирование и специальная подготовка'],
      },
      {
        code: '64849016',
        text:
          'Причины каких аварий расследует Федеральная служба по экологическому, технологическому и атомному надзору либо ее территориальный орган? Выберите правильный вариант ответа.',
        answers: [
          'Только причины аварий, в результате которых произошло отклонение частоты электрического тока в энергосистеме или ее части за пределы: 50,00 ± 0,2 Гц продолжительностью 3 часа и более; 50,00 ± 0,4 Гц продолжительностью 30 минут и более',
          'Только причины аварий, в результате которых произошли массовые отключения или повреждения объектов электросетевого хозяйства (высший класс напряжения 6 - 35 кВ), вызванные неблагоприятными природными явлениями, если они привели к прекращению электроснабжения потребителей общей численностью 200 тыс. человек и более',
          'Только причины аварий, в результате которых произошли нарушения в работе противоаварийной или режимной автоматики, в том числе обусловленные ошибочными действиями персонала, вызвавшие отключение объекта электросетевого хозяйства (высший класс напряжения 110 кВ и выше), отключение (включение) генерирующего оборудования, суммарная мощность которого составляет 100 МВт и более, или прекращение электроснабжения потребителей электрической энергии, суммарная мощность потребления которых составляет 100 МВт и более',
          'Расследует причины всех перечисленных аварий',
        ],
        correctAnswers: ['Расследует причины всех перечисленных аварий'],
      },
      {
        code: '64849017',
        text:
          'С кем заключают договор собственники объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, предусматривающий право собственников указанных объектов самостоятельно заключать договоры оказания услуг по передаче электрической энергии, в случаях, установленных Правительством Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'С Министерством энергетики Российской Федерации',
          'С организацией по управлению единой национальной (общероссийской) электрической сетью',
          'С органом исполнительной власти соответствующего субъекта Российской Федерации',
          'С администратором торговой системы оптового рынка',
        ],
        correctAnswers: [
          'С организацией по управлению единой национальной (общероссийской) электрической сетью',
        ],
      },
      {
        code: '64849018',
        text:
          'Должно ли уведомление, направляемое субъектом оперативно-диспетчерского управления в штаб по обеспечению безопасности электроснабжения содержать информацию о предполагаемом сроке (времени окончания) работы энергосистемы в вынужденном режиме? Выберите правильный вариант ответа.',
        answers: ['Нет', 'Да'],
        correctAnswers: ['Да'],
      },
      {
        code: '64849019',
        text:
          'Для каких категорий работников проводится стажировка? Выберите правильный вариант ответа.',
        answers: [
          'Определяется индивидуально по решению руководителя организации или уполномоченного им лица',
          'Для всех категорий персонала',
          'Для всех категорий, за исключением вспомогательного',
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
        correctAnswers: [
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64849020',
        text:
          'Какие действия вправе осуществить сетевые организации при невыполнении потребителем команд (распоряжений) системного оператора о введении в действие графиков аварийного ограничения? Выберите правильный вариант ответа.',
        answers: [
          'Отключить указанного потребителя непосредственно с питающих центров или ограничить его потребление вплоть до уровня аварийной брони',
          'Только ограничить его потребление вплоть до уровня аварийной брони',
          'Только отключить указанного потребителя непосредственно с питающих центров',
          'Только ограничить его потребление',
        ],
        correctAnswers: [
          'Отключить указанного потребителя непосредственно с питающих центров или ограничить его потребление вплоть до уровня аварийной брони',
        ],
      },
      {
        code: '64849021',
        text:
          'На объектах электроэнергетики не допускается проведение переключений, за исключением переключений, необходимых для ликвидации нарушения нормального режима, при работе с частотой ниже… Выберите правильный вариант ответа.',
        answers: ['49,8 Гц', '49,9 Гц', '49,7 Гц', '49,6 Гц'],
        correctAnswers: ['49,8 Гц'],
      },
      {
        code: '64849022',
        text:
          'Что должна включать техническая эксплуатация объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Только ввод в работу в составе энергосистемы новых, реконструированных, модернизированных, технически перевооруженных объектов электроэнергетики в целом, нового (модернизированного) оборудования и устройств объектов электроэнергетики',
          'Только использование по функциональному назначению линий электропередачи (далее - ЛЭП), оборудования и устройств',
          'Только формирование и поддержание в актуальном состоянии базы данных в электронном виде, содержащей техническое описание ЛЭП, оборудования и устройств объектов электросетевого хозяйства, приборов учета электрической энергии',
          'Все перечисленное, а также консервацию, техническое перевооружение, модернизацию и реконструкцию объектов электроэнергетики',
        ],
        correctAnswers: [
          'Все перечисленное, а также консервацию, техническое перевооружение, модернизацию и реконструкцию объектов электроэнергетики',
        ],
      },
      {
        code: '64849023',
        text:
          'Кто принимает решение о проведении системных испытаний в целях исследования свойств Единой энергетической системы России, технологически изолированной территориальной электроэнергетической системы, отдельных территориальных энергосистем или объединенных энергосистем, а также для проверки функционирования систем автоматического регулирования и управления электроэнергетическими режимами? Выберите правильный вариант ответа.',
        answers: [
          'Владельцы объектов электроэнергетики',
          'Субъект оперативно-диспетчерского управления',
          'Министерство энергетики РФ',
        ],
        correctAnswers: ['Субъект оперативно-диспетчерского управления'],
      },
      {
        code: '64849024',
        text:
          'Для фактического присоединения к энергосистеме построенных (реконструированных) объектов электроэнергетики обязаны ли владельцы таких объектов представить на согласование субъекту оперативно-диспетчерского управления проект нормальной схемы электрических соединений объекта, в состав которого не входят объекты диспетчеризации? Выберите правильный вариант ответа.',
        answers: ['Нет', 'Да'],
        correctAnswers: ['Нет'],
      },
      {
        code: '64849025',
        text:
          'Каким образом должен выполняться капитальный ремонт электрооборудования напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'По техническому заданию',
          'По техническому проекту',
          'По технологическим картам или проекту производства работ',
        ],
        correctAnswers: [
          'По технологическим картам или проекту производства работ',
        ],
      },
      {
        code: '64849026',
        text:
          'За какое время до планируемого изменения формы организации оперативного обслуживания объекта электроэнергетики владелец объекта электроэнергетики обязан уведомить соответствующий диспетчерский центр? Выберите правильный вариант ответа.',
        answers: [
          'Не менее чем за месяц',
          'Не менее чем за 3 месяца',
          'Не менее чем за 2 месяца',
        ],
        correctAnswers: ['Не менее чем за 3 месяца'],
      },
      {
        code: '64849027',
        text:
          'Каким документом должны быть оформлены работы в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'По распоряжению',
          'На основании перечня работ, выполняемых в порядке текущей эксплуатации',
          'Любым из перечисленных документов',
          'По заданию на производство работы, определяющему содержание, место работы, время ее начала и окончания, условия безопасного проведения, состав бригады и работников, ответственных за безопасное выполнение работы',
        ],
        correctAnswers: ['Любым из перечисленных документов'],
      },
      {
        code: '64849028',
        text:
          'Кто устанавливает порядок технологического присоединения энергопринимающих устройств юридических и физических лиц к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '64849029',
        text:
          'Контролируемое сечение, включающее совокупность элементов одной или нескольких электрических связей, одновременное отключение которых приводит к разделению энергосистемы на две изолированно работающие части – это … ? Выберите правильный вариант ответа.',
        answers: [
          'Частичное контролируемое сечение',
          'Полное контролируемое сечение',
        ],
        correctAnswers: ['Полное контролируемое сечение'],
      },
      {
        code: '64849030',
        text:
          'Каким образом определяется продолжительность времени для завершения непрерывного технологического процесса потребителя, внезапное прекращение которого вызывает необратимое нарушение технологического процесса и (или) опасность для жизни людей, окружающей среды? Выберите правильный вариант ответа.',
        answers: [
          'Устанавливается только на основании проектной документации',
          'Устанавливается с согласования сетевой организации в порядке, предусмотренном Правилами недискриминационного доступа к услугам по передаче электрической энергии',
          'Устанавливается только Правилами технологического присоединения',
          'Устанавливается на основании проектной документации, а при ее отсутствии определяется по взаимному согласованию сетевой организации и потребителя в порядке, предусмотренном Правилами недискриминационного доступа к услугам по передаче электрической энергии, Правилами технологического присоединения',
        ],
        correctAnswers: [
          'Устанавливается на основании проектной документации, а при ее отсутствии определяется по взаимному согласованию сетевой организации и потребителя в порядке, предусмотренном Правилами недискриминационного доступа к услугам по передаче электрической энергии, Правилами технологического присоединения',
        ],
      },
      {
        code: '64849031',
        text:
          'Считается ли работа в вынужденном режиме аварийным режим работы энергосистемы? Выберите правильный вариант ответа.',
        answers: ['Да', 'Нет'],
        correctAnswers: ['Нет'],
      },
      {
        code: '64849032',
        text:
          'Какое энергетическое оборудование ветровых и солнечных электростанций не относится к объектам диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'Отдельно функционирующие в составе энергосистемы ветроэнергетические установки, фотоэлектрические солнечные модули, установленная мощность которых составляет 1 МВт и более',
          'Ветроэнергетические установки или их группы, работающие в составе энергосистемы через один преобразователь постоянного тока или одно устройство напряжением 10 кВ и выше',
          'Отдельно функционирующие в составе энергосистемы ветроэнергетические установки, установленная мощность которых составляет 5 МВт и более',
        ],
        correctAnswers: [
          'Отдельно функционирующие в составе энергосистемы ветроэнергетические установки, фотоэлектрические солнечные модули, установленная мощность которых составляет 1 МВт и более',
        ],
      },
      {
        code: '64849033',
        text:
          'Допускается ли переход энергосистемы на работу в вынужденном режиме, если максимально допустимый переток активной мощности в контролируемом сечении ограничивается (определяется) необходимостью обеспечения допустимой токовой нагрузки ЛЭП и (или) электросетевого оборудования в нормальной (ремонтной) схеме(в послеаварийном режиме после нормативного возмущения), если отключение (оперативное или автоматическое) указанных ЛЭП (электросетевого оборудования) недопустимо? Выберите правильный вариант ответа.',
        answers: ['Не допускается', 'Допускается'],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '64849034',
        text:
          'Что из перечисленного должен в обязательном порядке делать допускающий перед допуском к работе на электроустановках? Выберите правильный вариант ответа.',
        answers: [
          '﻿Только провести целевой инструктаж при работах по наряду ответственному руководителю работ, производителю работ (наблюдающему) и членам бригады',
          'Только доказать бригаде, что напряжение отсутствует, демонстрацией установленных заземлений или проверкой отсутствия напряжения, если заземления не видны с рабочего места',
          '﻿Только проверить соответствие состава бригады составу, указанному в наряде или распоряжении, по именным удостоверениям членов бригады',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64849035',
        text:
          'Кем определяются периодичность, порядок организации и проведения обходов и осмотров рабочих мест, ответственные за их проведение лица в организациях электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Руководителем структурного подразделения',
          'Руководителем организации или уполномоченным им должностным лицом',
          'Представителем организации электроэнергетики',
          'Представителем территориального органа Ростехнадзора',
        ],
        correctAnswers: [
          'Руководителем организации или уполномоченным им должностным лицом',
        ],
      },
      {
        code: '64849036',
        text:
          'Каким образом необходимо присоединять переносное заземление при выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Присоединить переносное заземление к заземляющему устройству, установить переносное заземление на токоведущие части, проверить отсутствие напряжения',
          'Проверить отсутствие напряжения, установить переносное заземление на токоведущие части, присоединить переносное заземление к заземляющему устройству',
          'Присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения, установить переносное заземление на токоведущие части',
          'Установить переносное заземление на токоведущие части, присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения',
        ],
        correctAnswers: [
          'Присоединить переносное заземление к заземляющему устройству, проверить отсутствие напряжения, установить переносное заземление на токоведущие части',
        ],
      },
      {
        code: '64849037',
        text:
          'За сколько месяцев владелец объекта электроэнергетики обязан уведомить соответствующий диспетчерский центр о планируемом изменении формы организации оперативного обслуживания объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не менее чем за 6 месяцев до предполагаемого срока такого изменения',
          'Не менее чем за 3 месяца до предполагаемого срока такого изменения',
          'Не менее чем за 9 месяцев до предполагаемого срока такого изменения',
        ],
        correctAnswers: [
          'Не менее чем за 3 месяца до предполагаемого срока такого изменения',
        ],
      },
      {
        code: '64849038',
        text:
          'С какими категориями персонала проводится подготовка по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'Со всеми категориями, за исключением вспомогательного персонала',
          'С категориями всего персонала, за исключением административно-технического',
          'Только с оперативно-ремонтным и ремонтным персоналом',
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
        correctAnswers: [
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
      },
      {
        code: '64849039',
        text:
          'Кто имеет право устанавливать переносные заземления в электроустановках выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Один работник с группой IV из числа оперативного персонала',
          'Два работника с группой III из числа оперативного персонала',
          'Два работника: один с группой IV, другой с группой III',
          'Два работника: один с группой IV из числа оперативного персонала, другой с группой III',
        ],
        correctAnswers: [
          'Два работника: один с группой IV из числа оперативного персонала, другой с группой III',
        ],
      },
      {
        code: '64849040',
        text:
          'Кем определяется форма организации круглосуточного оперативного обслуживания объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Владельцем объекта электроэнергетики',
          'Владельцем объекта электроэнергетики по согласованию с субъектом оперативно-диспетчерского управления',
          'Субъектом оперативно-диспетчерского управления',
        ],
        correctAnswers: ['Владельцем объекта электроэнергетики'],
      },
      {
        code: '64849041',
        text:
          'С какой периодичностью должны актуализироваться техническая документация, перечни документов, используемых в работе, перечни документов на рабочем месте оперативного, оперативно-ремонтного персонала, исполнительные технологические схемы (чертежи), представляющие графическое представление последовательности основных стадий (операций) технологического процесса, и схемы первичных электрических соединений? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 10 лет',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в 3 года'],
      },
      {
        code: '64849042',
        text:
          'Должно ли уведомление, направляемое субъектом оперативно-диспетчерского управления в штаб по обеспечению безопасности электроснабжения содержать информацию о рисках работы энергосистемы в вынужденном режиме с указанием перечня линий электропередачи, электросетевого и (или) генерирующего оборудования, устройств РЗА, отключение (отказ) которых приводит к таким рискам? Выберите правильный вариант ответа.',
        answers: ['Нет', 'Да'],
        correctAnswers: ['Да'],
      },
      {
        code: '64849043',
        text:
          'Сколько договоров энергоснабжения может быть заключено в отношении одного энергопринимающего устройства? Выберите правильный вариант ответа.',
        answers: [
          'Только один договор',
          'Количество договоров не регламентируется',
        ],
        correctAnswers: ['Только один договор'],
      },
      {
        code: '64849044',
        text:
          'Что из перечисленного должны обеспечить владельцы объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Круглосуточный мониторинг эксплуатационного состояния средств диспетчерского и технологического управления (СДТУ), фиксация изменений эксплуатационного состояния СДТУ и нарушений в работе СДТУ в оперативном журнале',
          'Принятие мер по выявлению и устранению нарушений СДТУ, проверка работоспособности СДТУ после устранения нарушений',
          'Мониторинг достоверности телеметрической информации, передаваемой с объекта электроэнергетики в диспетчерские центры субъекта оперативно-диспетчерского управления, центр управления сетями и (или) структурное подразделение владельца объекта электроэнергетики, осуществляющее функции технологического управления и ведения в отношении соответствующего объекта электроэнергетики, и принятие мер по восстановлению достоверной телеметрической информации',
          'Всё перечисленное',
        ],
        correctAnswers: ['Всё перечисленное'],
      },
      {
        code: '64849045',
        text:
          'Какие плакаты при выполнении работ на электроустановках должны быть вывешены на приводах (рукоятках приводов) коммутационных аппаратов с ручным управлением (выключателей, отделителей, разъединителей, рубильников, автоматов) во избежание подачи напряжения на рабочее место? Выберите правильный вариант ответа.',
        answers: [
          '"Работают люди"',
          '"Опасно!"',
          '"Не открывать!"',
          '"Не включать! Работают люди"',
        ],
        correctAnswers: ['"Не включать! Работают люди"'],
      },
      {
        code: '64849046',
        text:
          'В какой срок лицо, получившее неудовлетворительную оценку по результатам проверки знаний, должно пройти повторную проверку? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 14 дней',
          'Не позднее одного месяца',
          'Вопрос о сроках повторной проверки и о возможности сохранения трудового договора с работником решается руководителем организации',
          'Не позднее 10 дней',
        ],
        correctAnswers: ['Не позднее одного месяца'],
      },
      {
        code: '64849047',
        text:
          'Что понимается под аварией на объекте электроэнергетики и (или) энергопринимающей установке? Выберите правильный вариант ответа.',
        answers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ',
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федерального закона "О промышленной безопасности опасных производственных объектов", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ; отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федеральных законов "Об электроэнергетике" и "О теплоснабжении", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
        ],
        correctAnswers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
        ],
      },
      {
        code: '64849048',
        text:
          'Какие организации составляют технологическую инфраструктуру розничных рынков? Укажите все правильные ответы.',
        answers: [
          'Субъекты оперативно-диспетчерского управления в электроэнергетике, осуществляющие оперативно-диспетчерское управление на розничных рынках (системный оператор и субъекты оперативно-диспетчерского управления в технологически изолированных территориальных электроэнергетических системах)',
          'Сетевые организации, осуществляющие передачу электрической энергии',
          'Потребители (покупатели) электрической энергии (мощности)',
          'Гарантирующие поставщики, энергосбытовые (энергоснабжающие) организации',
        ],
        correctAnswers: [
          'Субъекты оперативно-диспетчерского управления в электроэнергетике, осуществляющие оперативно-диспетчерское управление на розничных рынках (системный оператор и субъекты оперативно-диспетчерского управления в технологически изолированных территориальных электроэнергетических системах)',
          'Сетевые организации, осуществляющие передачу электрической энергии',
        ],
      },
      {
        code: '64849049',
        text:
          'По чьему решению создается штаб по обеспечению безопасности электроснабжения? Выберите правильный вариант ответа.',
        answers: [
          'Руководителя территориального органа МЧС в субъекте Российской Федерации',
          'Председателя (руководителя) высшего исполнительного органа субъекта Российской Федерации',
          'Руководителя территориального органа Ростехнадзора в субъекте Российской Федерации',
        ],
        correctAnswers: [
          'Председателя (руководителя) высшего исполнительного органа субъекта Российской Федерации',
        ],
      },
      {
        code: '64849050',
        text:
          'В каких случаях проводится первичная проверка знаний работников организаций электроэнергетики? Укажите 2 правильных ответа.',
        answers: [
          'При поступлении на работу впервые',
          'При перерыве в работе более 3 лет',
          'При перерыве в работе в данной должности более 2 лет',
          'При нарушении работниками требований нормативных актов по охране труда',
        ],
        correctAnswers: [
          'При поступлении на работу впервые',
          'При перерыве в работе более 3 лет',
        ],
      },
      {
        code: '64849051',
        text:
          'Кем осуществляется выбор состава включенного генерирующего оборудования и генерирующего оборудования, находящегося в резерве? Выберите правильный вариант ответа.',
        answers: [
          'Владельцем генерирующего оборудования',
          'Субъектом оперативно-диспетчерского управления',
          'Владельцем генерирующего оборудования по согласованию с субъектом оперативно-диспетчерского управления',
        ],
        correctAnswers: ['Субъектом оперативно-диспетчерского управления'],
      },
      {
        code: '64849052',
        text:
          'При какой длительности аварийный выход из строя средств связи диспетчерских центров, центров управления сетями в сетевых организациях и объектов электроэнергетики считается угрозой нарушения электроснабжения (режим с высоким риском нарушения электроснабжения)? Выберите правильный вариант ответа.',
        answers: [
          'При длительности более 6 часов',
          'При длительности более 12 часов',
          'При длительности более 18 часов',
          'При длительности более 24 часов',
        ],
        correctAnswers: ['При длительности более 24 часов'],
      },
      {
        code: '64849053',
        text:
          'Допускается ли работа гидрогенераторов в асинхронном режиме без возбуждения? Выберите правильный вариант ответа.',
        answers: [
          'Не допускается',
          'Допускается при нагрузке не более 60% номинальной, а продолжительность работы при этом не более 30 минут',
          'Допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '64849054',
        text:
          'Какую группу по электробезопасности должны иметь работники из числа оперативного персонала, единолично обслуживающие электроустановки напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: ['Группу II или IIII', 'Не ниже группы III', 'Группу II'],
        correctAnswers: ['Не ниже группы III'],
      },
      {
        code: '64849055',
        text:
          'Каковы условия проведения специальной подготовки персонала? Выберите правильный вариант ответа.',
        answers: [
          'Должна проводиться с отрывом от выполнения основных функций и составлять от 40 до 50% его рабочего времени',
          'Должна проводиться с отрывом от выполнения основных функций и составлять от 20 до 30% его рабочего времени',
          'Должна проводиться без отрыва от производства',
          'Должна проводиться с отрывом от выполнения основных функций и составлять от 5 до 20% его рабочего времени',
        ],
        correctAnswers: [
          'Должна проводиться с отрывом от выполнения основных функций и составлять от 5 до 20% его рабочего времени',
        ],
      },
      {
        code: '64849056',
        text:
          'Какая организация утвердила «Правила предотвращения развития и ликвидации нарушений нормального режима электрической части энергосистем и объектов электроэнергетики»? Выберите правильный вариант ответа.',
        answers: ['МЧС', 'Минэнерго России', 'Ростехнадзор', 'ОАО "ФСК ЕЭС"'],
        correctAnswers: ['Минэнерго России'],
      },
      {
        code: '64849057',
        text:
          'Кем осуществляется определение электрической станции, осуществляющей регулирование частоты, при разделении энергосистемы (отделении части синхронной зоны на изолированную работу или электрической станции (генерирующего оборудования) на изолированный район), в части синхронной зоны или изолированном районе? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерским персоналом, руководящим ликвидацией нарушения нормального режима',
          'Главным диспетчером',
          'Диспетчерским персоналом, ответственным за регулирование частоты в изолированном районе (части синхронной зоны)',
        ],
        correctAnswers: [
          'Диспетчерским персоналом, ответственным за регулирование частоты в изолированном районе (части синхронной зоны)',
        ],
      },
      {
        code: '64849058',
        text:
          'В каких случаях допускается вывод из работы технологических защит оборудования, объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'При работе оборудования в переходных режимах в случае, если необходимость отключения технологической защиты определена производственной (местной) инструкцией по эксплуатации основного оборудования',
          'Для периодического опробования, если оно проводится на действующем оборудовании',
          'При неисправности технологической защиты',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '64849059',
        text:
          'Укажите минимальное количество энергоблоков (турбоагрегатов) тепловой электростанции (в том числе с общим паропроводом), одновременный пооперационный пуск которых должен быть обеспечен после любой продолжительности простоя. Выберите правильный вариант ответа.',
        answers: ['3', '2', '1'],
        correctAnswers: ['2'],
      },
      {
        code: '64849060',
        text:
          'Кто вправе запрашивать у субъектов электроэнергетики информацию о возникновении аварий, об изменениях или о нарушениях технологических процессов, а также о выходе из строя сооружений и оборудования, которые могут причинить вред жизни или здоровью граждан, окружающей среде и имуществу граждан и (или) юридических лиц? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Федеральные органы исполнительной власти'],
      },
      {
        code: '64849061',
        text:
          'Допускается ли включать в состав бригады, выполняющей работы по наряду, работников, имеющих II группу по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'На каждого работника, имеющего группу III, допускается включать только одного работника, имеющего группу II',
          'Не допускается',
          'На каждого работника, имеющего, группу не ниже III, допускается включать одного работника, имеющего группу II, но не более трех в бригаду',
          'Допускается, но не более двух в бригаду',
        ],
        correctAnswers: [
          'На каждого работника, имеющего, группу не ниже III, допускается включать одного работника, имеющего группу II, но не более трех в бригаду',
        ],
      },
      {
        code: '64849062',
        text:
          'От кого должен получить подтверждение об окончании работ и удалении всех бригад с рабочего места диспетчерский или оперативный персонал перед отдачей команды на снятие плаката «Не включать! Работа на линии!»? Выберите правильный вариант ответа.',
        answers: [
          'От выдающего наряд-допуск',
          'От ответственного руководителя работ',
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
          'От вышестоящего диспетчерского или оперативного персонала',
        ],
        correctAnswers: [
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
        ],
      },
      {
        code: '64849063',
        text:
          'Допускается ли выдача команд (разрешений, подтверждений) на производство переключений диспетчерскому или оперативному персоналу, прямая связь с которым нарушилась, через другой диспетчерский или оперативный персонал, который должен зафиксировать команду (разрешение, подтверждение) в своем оперативном журнале, а затем передать команду (разрешение, подтверждение) на производство переключений по назначению? (укажите правильный ответ): Выберите правильный вариант ответа.',
        answers: ['Да', 'Нет'],
        correctAnswers: ['Да'],
      },
      {
        code: '64849064',
        text:
          'Когда диспетчерский персонал имеет право корректировать действия подчиненного диспетчерского и (или) оперативного персонала, при действиях с ЛЭП и оборудованием, не являющимися объектами диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'При проведении плановых переключений',
          'При ликвидации нарушений нормального режима',
        ],
        correctAnswers: ['При ликвидации нарушений нормального режима'],
      },
      {
        code: '64849065',
        text:
          'Каким документом должен быть определен порядок подачи и снятия напряжения с ЛЭП, а также допустимость его изменения с указанием выполнения необходимых мероприятий? Выберите правильный вариант ответа.',
        answers: [
          'В типовых программах переключений',
          'В типовых бланках переключений',
          'В местных инструкциях',
        ],
        correctAnswers: ['В местных инструкциях'],
      },
      {
        code: '64849066',
        text:
          'Могут ли в работе штаба по обеспечению безопасности электроснабжения принимать участие представители иных органов государственной власти субъекта Российской Федерации, органов местного самоуправления, субъектов электроэнергетики и потребителей электрической энергии, осуществляющих свою деятельность на территории соответствующего субъекта Российской Федерации, а также представители иных организаций, не являющиеся членами штаба? Выберите правильный вариант ответа.',
        answers: [
          'Не могут',
          'Могут, по решению руководителя штаба (лица, его замещающего)',
        ],
        correctAnswers: [
          'Могут, по решению руководителя штаба (лица, его замещающего)',
        ],
      },
      {
        code: '64849067',
        text:
          'Укажите правильный порядок приемки и сдачи смены диспетчерским персоналом во время ликвидации нарушения нормального режима. Выберите правильный вариант ответа.',
        answers: [
          'Приемка и сдача смены не осуществляются. С учетом текущей и прогнозируемой ситуации в энергосистеме (энергорайоне, электрических сетях, на объекте электроэнергетики), предполагаемого времени реализации мероприятий по ликвидации нарушения нормального режима, требующих времени, выходящего за продолжительность текущей дежурной смены, сдача и приемка смены осуществляются только по разрешению диспетчера ДЦ, руководящего ликвидацией нарушения нормального режима',
          'Приемка и сдача смены не осуществляются. С учетом текущей и прогнозируемой ситуации в энергосистеме (энергорайоне, электрических сетях, на объекте электроэнергетики), предполагаемого времени реализации мероприятий по ликвидации нарушения нормального режима, требующих времени, выходящего за продолжительность текущей дежурной смены, сдача и приемка смены осуществляются только по разрешению главного диспетчера',
        ],
        correctAnswers: [
          'Приемка и сдача смены не осуществляются. С учетом текущей и прогнозируемой ситуации в энергосистеме (энергорайоне, электрических сетях, на объекте электроэнергетики), предполагаемого времени реализации мероприятий по ликвидации нарушения нормального режима, требующих времени, выходящего за продолжительность текущей дежурной смены, сдача и приемка смены осуществляются только по разрешению диспетчера ДЦ, руководящего ликвидацией нарушения нормального режима',
        ],
      },
      {
        code: '64849068',
        text:
          'Превышение аварийно допустимого перетока активной мощности в контролируемом сечении в вынужденном режиме … ? Выберите правильный вариант ответа.',
        answers: [
          'Допускается кратковременно',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '64849069',
        text:
          'Какой период планирования электроэнергетического режима энергосистемы используют в качестве основы для планирования ремонтной кампании владельцами объектов электроэнергетики, линии электропередачи, оборудование и устройства которых относятся к объектам диспетчеризации? Выберите правильный вариант ответа.',
        answers: ['Год', 'Семь лет', 'Месяц', 'Пять лет'],
        correctAnswers: ['Год'],
      },
      {
        code: '64849070',
        text:
          'Что должно отключаться в первую очередь для ликвидации непрекращающегося асинхронного режима по диспетчерской команде диспетчерского персонала? Выберите правильный вариант ответа.',
        answers: [
          'Элементы электрической сети более высокого класса напряжения',
          'Элементы электрической сети более низкого класса напряжения',
        ],
        correctAnswers: [
          'Элементы электрической сети более высокого класса напряжения',
        ],
      },
      {
        code: '64849071',
        text:
          'В каком случае аварийный выход из строя электросетевого или генерирующего оборудования считается угрозой нарушения электроснабжения (режимом с высоким риском нарушения электроснабжения)? Выберите правильный вариант ответа.',
        answers: [
          'Если это приводит к электроэнергетическому режиму энергосистемы с превышением максимально допустимых перетоков длительностью 1 час',
          'Если это приводит к электроэнергетическому режиму энергосистемы с превышением максимально допустимых перетоков длительностью 2 часа',
          'Если это приводит к электроэнергетическому режиму энергосистемы с превышением максимально допустимых перетоков длительностью более 3 часов',
          'Если это приводит к электроэнергетическому режиму энергосистемы с превышением максимально допустимых перетоков длительностью 2,5 часа',
        ],
        correctAnswers: [
          'Если это приводит к электроэнергетическому режиму энергосистемы с превышением максимально допустимых перетоков длительностью более 3 часов',
        ],
      },
      {
        code: '64849072',
        text:
          'Какой состав постоянно действующей комиссии для проведения проверки знаний, назначенной руководителем организации? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 5-ти человек, председатель, заместители председателя и 2 члена центральной комиссии организации должны пройти проверку знаний руководителем или заместителем руководителя организации, прошедшим аттестацию по вопросам безопасности в сфере электроэнергетики в соответствии с нормативными правовыми актами по вопросам аттестации',
          'Не менее 3-х человек, председатель должен пройти аттестацию по вопросам безопасности в соответствующей области аттестации в аттестационной комиссии, формируемой федеральным органом исполнительной власти, уполномоченным на осуществление федерального государственного энергетического надзора',
          'Не менее 4-х человек, председатель должен пройти аттестацию по вопросам безопасности в соответствующей области аттестации в аттестационной комиссии, формируемой федеральным органом исполнительной власти, уполномоченным на осуществление государственного экологического надзора',
        ],
        correctAnswers: [
          'Не менее 5-ти человек, председатель, заместители председателя и 2 члена центральной комиссии организации должны пройти проверку знаний руководителем или заместителем руководителя организации, прошедшим аттестацию по вопросам безопасности в сфере электроэнергетики в соответствии с нормативными правовыми актами по вопросам аттестации',
        ],
      },
      {
        code: '64849073',
        text:
          'Кто является ответственным за безопасное ведение работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, ответственный руководитель работ',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
        correctAnswers: [
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
      },
      {
        code: '64849074',
        text:
          'Допускается ли работа с токовой нагрузкой ЛЭП и электросетевого оборудования, превышающей длительно допустимую токовую нагрузку? Выберите правильный вариант ответа.',
        answers: [
          'Допускается по разрешению собственника или иного законного владельца оборудования',
          'Не допускается',
          'Допускается',
        ],
        correctAnswers: [
          'Допускается по разрешению собственника или иного законного владельца оборудования',
        ],
      },
      {
        code: '64849075',
        text:
          'Какие из перечисленных требований к содержанию технической документации для оперативного и оперативно-ремонтного персонала электростанции указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Перечни документов на рабочих местах оперативного, оперативно-ремонтного персонала должны утверждаться руководителем службы охраны труда',
          'На рабочих местах оперативного, оперативно-ремонтного персонала должны находиться оперативные схемы, оперативный журнал, журнал распоряжений, журнал диспетчерских и оперативных заявок, журнал РЗА и журнал телемеханики, журнал дефектов оборудования, журнал учета работ по нарядам и распоряжениям, журнал учета выдачи ключей',
          'Не допускается наличие у владельца объекта электроэнергетики и на рабочих местах оперативного, оперативно-ремонтного персонала одного журнала распоряжений по отдельным функциональным направлениям деятельности структурных подразделений владельца объекта электроэнергетики',
          'Допускается нахождение на рабочих местах оперативного, оперативно-ремонтного персонала иной оперативной документации, позволяющей персоналу осуществлять контроль за работой оборудования объекта электроэнергетики, вести учет работ по техническому обслуживанию и ремонту, испытаниям и измерениям',
        ],
        correctAnswers: [
          'Допускается нахождение на рабочих местах оперативного, оперативно-ремонтного персонала иной оперативной документации, позволяющей персоналу осуществлять контроль за работой оборудования объекта электроэнергетики, вести учет работ по техническому обслуживанию и ремонту, испытаниям и измерениям',
        ],
      },
      {
        code: '64849076',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для диспетчерского, оперативного и оперативно-ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от характера выполняемой работы, но не реже 1 раза в 3 месяца',
          'Ежемесячно',
          'Не реже 1 раза в 6 месяцев',
          'В зависимости от квалификации работника, но не реже 1 раза в 3 месяца',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64849077',
        text:
          'В какой срок Ростехнадзор должен завершить расследование причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'В срок, не превышающий 20 дней со дня начала расследования',
          'В срок, не превышающий 10 дней со дня начала расследования',
          'В срок, не превышающий 20 дней с момента аварии',
          'В срок, не превышающий 20 дней с момента получения информации об аварии',
          'В срок, не превышающий 10 дней с момента получения информации об аварии',
        ],
        correctAnswers: [
          'В срок, не превышающий 20 дней со дня начала расследования',
        ],
      },
      {
        code: '64849078',
        text:
          'На какое расстояние до токоведущих частей электроустановок, находящихся под напряжением 1-35 кВ, не допускается приближение людей при оперативном обслуживании, осмотрах электроустановок, а также выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Менее чем 0,6 м',
          'Менее чем 0,3 м',
          'Менее чем 0,5 м',
          'Менее чем 0,4 м',
        ],
        correctAnswers: ['Менее чем 0,6 м'],
      },
      {
        code: '64849079',
        text:
          'Согласование штабом по обеспечению безопасности электроснабжения решения о переходе энергосистемы на работу в вынужденном режиме должно быть оформлено протоколом заседания штаба? Выберите правильный вариант ответа.',
        answers: ['Да', 'Нет'],
        correctAnswers: ['Да'],
      },
      {
        code: '64849080',
        text:
          'Какое основное условие осуществления параллельной или совместной работы ЕЭС России с энергосистемами иностранных государств? Выберите правильный вариант ответа.',
        answers: [
          'Наличие действующих соглашений об организации параллельной или совместной работы',
          'Наличие действующих положений об организации параллельной или совместной работы',
          'Наличие действующих договоров об организации параллельной или совместной работы',
        ],
        correctAnswers: [
          'Наличие действующих договоров об организации параллельной или совместной работы',
        ],
      },
      {
        code: '64849081',
        text:
          'В течение какого времени при выделении генераторов тепловых электростанций на собственные нужды или сбалансированную нагрузку действием частотной делительной автоматики должна обеспечиваться устойчивая работа выделяемого генерирующего оборудования? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 20 минут',
          'Не менее 60 минут',
          'Не менее 40 минут',
          'Не менее 30 минут',
        ],
        correctAnswers: ['Не менее 30 минут'],
      },
      {
        code: '64849082',
        text:
          'Являются ли обязательными для соблюдения субъектами электроэнергетики уровни и (или) диапазоны напряжения в контрольных пунктах субъекта оперативно-диспетчерского управления, указанные в составе диспетчерского графика? Выберите правильный вариант ответа.',
        answers: [
          'Нет',
          'Да, если обеспечиваются уровни и (или) диапазоны напряжения в контрольных пунктах сетевой организации',
          'Да',
        ],
        correctAnswers: ['Да'],
      },
      {
        code: '64849083',
        text:
          'Из чего исходит штаб по обеспечению безопасности электроснабжения при принятии решений? Выберите правильный вариант ответа.',
        answers: [
          'Из необходимости обеспечения гарантированности функционирования оптового и розничного рынков электрической энергии и мощности',
          'Из необходимости обеспечения безопасности работы объектов электроэнергетики и энергетических установок потребителей электрической энергии',
        ],
        correctAnswers: [
          'Из необходимости обеспечения безопасности работы объектов электроэнергетики и энергетических установок потребителей электрической энергии',
        ],
      },
      {
        code: '64849084',
        text:
          'Каким образом передаются разрешение на подготовку рабочего места и допуск к работе работнику, выполняющему подготовку рабочего места и допуск бригады к работе? Выберите правильный вариант ответа.',
        answers: [
          'Только лично или через оперативный персонал промежуточной подстанции',
          'Только лично или с нарочным',
          'Лично, по телефону, с нарочным или через оперативный персонал промежуточной подстанции',
          'Только лично или по телефону',
          'Только лично',
        ],
        correctAnswers: [
          'Лично, по телефону, с нарочным или через оперативный персонал промежуточной подстанции',
        ],
      },
      {
        code: '64849085',
        text:
          'До какого числа сетевые организации должны сформировать перечень вторичных получателей команд об аварийных ограничениях? Выберите правильный вариант ответа.',
        answers: ['До 1 июня', 'До 1 июля', 'До 15 июня', 'До 15 июля'],
        correctAnswers: ['До 15 июня'],
      },
      {
        code: '64849086',
        text:
          'Какие мероприятия обязательно осуществляются перед допуском к проведению неотложных работ? Выберите правильный вариант ответа.',
        answers: [
          'Проверка количественного и качественного состава бригады',
          'Проведение целевого инструктажа',
          'Оформление наряда-допуска',
          'Технические мероприятия по подготовке рабочего места',
        ],
        correctAnswers: [
          'Технические мероприятия по подготовке рабочего места',
        ],
      },
      {
        code: '64849087',
        text:
          'В течение какого времени осуществляется сохранность информации о произведенном воздействии на оборудование и устройства объектов электроэнергетики, осуществленное с использованием средств дистанционного управления из диспетчерских центров и центров управления сетями? Выберите правильный вариант ответа.',
        answers: [
          'В течение не менее чем 12-ти месяцев со дня регистрации',
          'В течение не менее чем 3-х месяцев со дня регистрации',
          'В течение не менее чем 18-ти месяцев со дня регистрации',
          'В течение не менее чем 6-ти месяцев со дня регистрации',
        ],
        correctAnswers: [
          'В течение не менее чем 12-ти месяцев со дня регистрации',
        ],
      },
      {
        code: '64849088',
        text:
          'Что понимается под отказом средств связи? Выберите 2 варианта ответа.',
        answers: [
          'Нарушение всех видов связи',
          'Невозможность связаться с оперативным и диспетчерским персоналом более 5 минут из-за плохой слышимости и (или) перебоев в работе связи',
          'Невозможность связаться с административно-техническим персоналом',
          'Невозможность связаться с оперативным и диспетчерским персоналом более 3 минут из-за плохой слышимости и (или) перебоев в работе связи',
        ],
        correctAnswers: [
          'Нарушение всех видов связи',
          'Невозможность связаться с оперативным и диспетчерским персоналом более 3 минут из-за плохой слышимости и (или) перебоев в работе связи',
        ],
      },
      {
        code: '64849089',
        text:
          'Что из перечисленного не входит в технологическую основу функционирования электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Единая национальная (общероссийская) электрическая сеть',
          'Территориальные распределительные сети',
          'Система отношений, связанных с производством и оборотом электроэнергии на оптовом рынке',
          'Единая система оперативно-диспетчерского управления',
        ],
        correctAnswers: [
          'Система отношений, связанных с производством и оборотом электроэнергии на оптовом рынке',
        ],
      },
      {
        code: '64849090',
        text:
          'Заседание штаба по обеспечению безопасности электроснабжения созывается в случае получения руководителем штаба уведомления о возникновении или угрозе возникновения нарушения электроснабжения по причинам, не зависящим от действий субъектов электроэнергетики и вызванным в том числе опасными природными явлениями или иными чрезвычайными ситуациями (режима с высокими рисками нарушения электроснабжения), либо о возникновении или угрозе возникновения аварийного электроэнергетического режима. Срок созыва заседания штаба после получения его руководителем указанной информации…? Выберите правильный вариант ответа.',
        answers: ['В течение 3 часов', 'По согласованию с руководителем штаба'],
        correctAnswers: ['В течение 3 часов'],
      },
      {
        code: '64849091',
        text:
          'В течение какого срока должны храниться использованные программы (типовые программы) и бланки (типовые бланки) переключений? Выберите правильный вариант ответа.',
        answers: [
          'Должны храниться до ввода в работу ЛЭП, оборудования и устройств РЗА, но не менее 15 суток',
          'Должны храниться до ввода в работу ЛЭП, оборудования и устройств РЗА, но не менее 20 суток',
          'Должны храниться до ввода в работу ЛЭП, оборудования и устройств РЗА, но не менее 10 суток',
        ],
        correctAnswers: [
          'Должны храниться до ввода в работу ЛЭП, оборудования и устройств РЗА, но не менее 20 суток',
        ],
      },
      {
        code: '64849092',
        text:
          'Каким образом должны производиться переключения в электроустановках при вводе в работу новых (модернизированных, реконструированных) ЛЭП, оборудования, устройств РЗА и при проведении испытаний? Выберите правильный вариант ответа.',
        answers: [
          'По комплексным программам',
          'Без использования программ',
          'По разовым программам',
        ],
        correctAnswers: ['По комплексным программам'],
      },
      {
        code: '64849093',
        text:
          'В течение какого времени осуществляется сохранность оперативных переговоров диспетчерского персонала ДЦ, оперативного персонала ЦУС, оперативного персонала объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'В течение не менее чем 3-х месяцев со дня регистрации',
          'В течение не менее чем 2-х месяцев со дня регистрации',
          'В течение не менее чем 1-го месяца со дня регистрации',
          'В течение не менее чем 6-ти месяцев со дня регистрации',
        ],
        correctAnswers: [
          'В течение не менее чем 3-х месяцев со дня регистрации',
        ],
      },
      {
        code: '64849094',
        text:
          'В каких случаях из перечисленных проводятся внеочередные осмотры воздушных линий электропередачи (ВЛ) или их участков? Выберите правильный вариант ответа.',
        answers: [
          'При образовании на проводах и тросах гололеда',
          'При пляске проводов',
          'Во время ледохода и разлива рек, при лесных и степных пожарах, а также после стихийных бедствий',
          'Во всех перечисленных',
        ],
        correctAnswers: ['Во всех перечисленных'],
      },
      {
        code: '64849095',
        text:
          'Что обеспечивается совместными действиями субъектов электроэнергетики, в том числе системного оператора, в ходе исполнения своих обязательств по снабжению электрической энергией потребителей в соответствии с заключаемыми ими на оптовом рынке и розничных рынках договорами? Укажите 2 правильных варианта ответа.',
        answers: [
          'Соблюдение правил технической эксплуатации энергопринимающих устройств потребителей',
          'Контроль за соблюдением правил недискриминационного доступа к услугам по передаче электрической энергии, услугам по оперативно- диспетчерскому управлению в электроэнергетике',
          'Информирование органов исполнительной власти о результатах мониторинга ценовой ситуации на оптовом и розничных рынках',
          'Регулирование цен (тарифов) на электрическую энергию (мощность)',
          'Качество электрической энергии',
          'Надежность снабжения потребителей',
        ],
        correctAnswers: [
          'Качество электрической энергии',
          'Надежность снабжения потребителей',
        ],
      },
      {
        code: '64849096',
        text:
          'Что такое штаб по обеспечению безопасности электроснабжения? Выберите правильный вариант ответа.',
        answers: [
          'Постоянно действующий коллегиальный координационный орган, создается и функционирует при высшем исполнительном органе субъекта Российской Федерации',
          'Учреждаемый при необходимости коллегиальный координационный орган, создается и функционирует при высшем органе исполнительной власти Российской Федерации',
        ],
        correctAnswers: [
          'Постоянно действующий коллегиальный координационный орган, создается и функционирует при высшем исполнительном органе субъекта Российской Федерации',
        ],
      },
      {
        code: '64849097',
        text:
          'В послеаварийном режиме после нормативного возмущения токовая нагрузка ЛЭП и электросетевого оборудования не должна превышать … ? Выберите правильный вариант ответа.',
        answers: [
          'Аварийно допустимой в течение 20 минут токовой нагрузки',
          'Длительно допустимой токовой нагрузки',
        ],
        correctAnswers: [
          'Аварийно допустимой в течение 20 минут токовой нагрузки',
        ],
      },
      {
        code: '64849098',
        text:
          'Что из перечисленного должно быть определено организационно-распорядительным документом владельцем объекта по производству электрической энергии? Выберите правильный вариант ответа.',
        answers: [
          'Оперативный персонал, допущенный к производству переключений и уполномоченный на осуществление операций по изменению технологического режима работы или эксплуатационного состояния ЛЭП, оборудования и устройств объектов электроэнергетики и (или) осуществление координации действий персонала, непосредственно выполняющего такие операции',
          'Только работников, имеющих право контролировать переключения в электроустановках',
          'Только работников, уполномоченных выдавать разрешение на деблокирование при неисправности оперативной блокировки',
          'Только работников из числа административно-технического персонала, ремонтного персонала, которым предоставлены права оперативного персонала (в указанном случае на таких лиц распространяются требования, установленные Правилами для оперативного персонала)',
        ],
        correctAnswers: [
          'Оперативный персонал, допущенный к производству переключений и уполномоченный на осуществление операций по изменению технологического режима работы или эксплуатационного состояния ЛЭП, оборудования и устройств объектов электроэнергетики и (или) осуществление координации действий персонала, непосредственно выполняющего такие операции',
        ],
      },
      {
        code: '64849099',
        text:
          'Укажите уровень частоты, при котором диспетчерский персонал, ответственный за регулирование частоты, отдает диспетчерские команды на введение в действие ГВО. Выберите правильный вариант ответа.',
        answers: [
          '49,90 Гц',
          '50,00 Гц',
          '47,00 Гц',
          '49,80 Гц',
          '49,70 Гц',
          '49,50 Гц',
          '49,60 Гц',
          '49,00 Гц',
          '49,30 Гц',
        ],
        correctAnswers: ['49,80 Гц'],
      },
      {
        code: '64849100',
        text:
          'Как оформляется акт расследования технологического нарушения при несогласии отдельных членов комиссии? Выберите правильный вариант ответа.',
        answers: [
          'Несогласные члены комиссии акт не подписывают',
          'Несогласные члены комиссии подписывают акт с примечанием "не согласен"',
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
          'Несогласные члены комиссии подписывают акт с особым мнением, изложенным рядом с их подписью или адресующим к отдельному приложению',
          'Несогласные члены комиссии акт не подписывают и направляют "особое мнение" в Управление государственного энергетического надзора Ростехнадзора',
        ],
        correctAnswers: [
          'Несогласные члены комиссии подписывают акт, а их особое мнение прилагается к акту расследования',
        ],
      },
      {
        code: '64849101',
        text:
          'В какие сроки проводится проверка знаний работников, относящихся к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала, при подготовке по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'В сроки, установленные программой подготовки по новой должности',
          'Не позднее 10 дней',
          'В сроки, установленные председателем комиссии по проверке знаний в организации',
          'Не позднее 14 дней',
        ],
        correctAnswers: [
          'В сроки, установленные программой подготовки по новой должности',
        ],
      },
      {
        code: '64849102',
        text:
          'Правильно ли указан порядок действий диспетчерского и оперативного персонала по ликвидации нарушений нормального режима (в порядке снижения приоритетности): -обеспечение безопасности персонала объектов электроэнергетики; -исключение повреждения ЛЭП и оборудования объектов электроэнергетики; -предотвращение развития и локализацию нарушения нормального режима; -обеспечение допустимых значений параметров электроэнергетического режима; -восстановление электроснабжения потребителей электрической энергии; -создание наиболее надежной послеаварийной схемы энергосистемы (объектов электроэнергетики). Выберите правильный вариант ответа.',
        answers: ['Нет', 'Да'],
        correctAnswers: ['Да'],
      },
      {
        code: '64849103',
        text:
          'В течение какого времени с момента получения запроса от системного оператора необходимо предоставить ему сведения о значениях, характеризующих текущую перегрузочную способность электроэнергетического оборудования? Выберите правильный вариант ответа.',
        answers: [
          'В течение 1 часа с момента получения запроса',
          'В течение 2 часов с момента получения запроса или в иные предусмотренные запросом сроки',
          'В течение 1 часа с момента получения запроса или в иные предусмотренные запросом сроки',
          'В течение 2 часов с момента получения запроса',
        ],
        correctAnswers: [
          'В течение 2 часов с момента получения запроса или в иные предусмотренные запросом сроки',
        ],
      },
      {
        code: '64849104',
        text:
          'В какой срок Ростехнадзор или его территориальные органы, принявшие решение о расследовании причин аварии, уведомляют об этом уполномоченный орган в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 48 часов с момента принятия такого решения',
          'Не позднее 24 часов с момента принятия такого решения',
          'Не позднее 48 часов с момента аварии',
          'Не позднее 48 часов с момента получения информации об аварии',
        ],
        correctAnswers: [
          'Не позднее 48 часов с момента принятия такого решения',
        ],
      },
      {
        code: '64849105',
        text:
          'Кому дано право утверждать технологические характеристики объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, порядок ведения реестра указанных объектов? Выберите правильный вариант ответа.',
        answers: [
          'Министерству энергетики Российской Федерации',
          'ФСК ЕЭС',
          'Правительству Российской Федерации',
          'Администратору торговой системы оптового рынка',
        ],
        correctAnswers: ['Правительству Российской Федерации'],
      },
      {
        code: '64849106',
        text:
          'На кого из перечисленных лиц не распространяются Правила технической эксплуатации электрических станций и сетей Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'На собственников и иных законных владельцев объектов по производству электрической энергии, в том числе функционирующих в режиме комбинированной выработки электрической и тепловой энергии',
          'На собственников и иных законных владельцев объектов по производству электрической энергии и (или) объектов электросетевого хозяйства (далее - владелец объекта электроэнергетики)',
          'На собственников и иных законных владельцев объектов принадлежащих им объекты по производству электрической энергии и объекты электросетевого хозяйства (далее - объекты электроэнергетики)',
          'На принадлежащих потребителям электрической энергии объектов электросетевого хозяйства классом напряжения 0,4 кВ и ниже, присоединенных к электрическим сетям на уровне напряжения 0,4 кВ',
        ],
        correctAnswers: [
          'На принадлежащих потребителям электрической энергии объектов электросетевого хозяйства классом напряжения 0,4 кВ и ниже, присоединенных к электрическим сетям на уровне напряжения 0,4 кВ',
        ],
      },
      {
        code: '64849107',
        text:
          'Кто имеет право включать электроустановки после полного окончания работ? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
          'Работник с группой по электробезопасности V',
          'Работник с группой по электробезопасности не ниже IV',
          'Должностное лицо, осуществляющие контроль и надзор за соблюдением требований безопасности при эксплуатации электроустановок',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
        ],
      },
      {
        code: '64849108',
        text:
          'На какой период разрабатываются графики напряжения в контрольных пунктах субъекта оперативно-диспетчерского управления? Выберите правильный вариант ответа.',
        answers: [
          'На календарный месяц',
          'На календарную неделю',
          'На календарный год',
          'На квартал',
        ],
        correctAnswers: ['На календарный месяц'],
      },
      {
        code: '64849109',
        text:
          'В течение какого времени штаб по обеспечению безопасности электроснабжения рассматривает полученное на согласование решение системного оператора и выбирает необходимый вариант мер, направленных на обеспечение эффективного выполнения решений по управлению режимами ЕЭС России? Выберите правильный вариант ответа.',
        answers: [
          'В срок, согласованный со всеми членами Штаба',
          'Не позднее 3 часов с момента получения решения',
        ],
        correctAnswers: ['Не позднее 3 часов с момента получения решения'],
      },
      {
        code: '64849110',
        text:
          'Что должен сделать оперативный персонал объекта электроэнергетики при выявлении неполнофазного режима? Выберите правильный вариант ответа.',
        answers: [
          'Немедленно сообщить об этом соответствующему диспетчерскому персоналу ДЦ и (или) оперативному персоналу ЦУС',
          'Немедленно отключить поврежденный элемент электрической сети',
        ],
        correctAnswers: [
          'Немедленно сообщить об этом соответствующему диспетчерскому персоналу ДЦ и (или) оперативному персоналу ЦУС',
        ],
      },
      {
        code: '64849111',
        text:
          'Когда работники должны проходить обучение по оказанию первой помощи пострадавшему на производстве? Выберите правильный вариант ответа.',
        answers: [
          'До допуска к самостоятельной работе',
          'Непосредственно сразу после допуска к самостоятельной работе',
          'В течение месяца после допуска к самостоятельной работе',
          'В течение года после допуска к самостоятельной работе',
        ],
        correctAnswers: ['До допуска к самостоятельной работе'],
      },
      {
        code: '64849112',
        text:
          'Кто несет ответственность за работу с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель подразделения, ответственный за работу с кадрами',
          'Технический руководитель организации',
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права',
          'Лицо, осуществляющее хозяйственную деятельность организации',
        ],
        correctAnswers: [
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права',
        ],
      },
      {
        code: '64849113',
        text:
          'Что следует предпринять при возникновении замыкания на землю в сети постоянного тока? Выберите правильный вариант ответа.',
        answers: [
          'Приступить к его отысканию после прибытия персонала СРЗА',
          'Немедленно приступить к его отысканию',
        ],
        correctAnswers: ['Немедленно приступить к его отысканию'],
      },
      {
        code: '64849114',
        text:
          'Допускается ли во время переключений в электроустановках изменение распределения обязанностей между лицами, выполняющими переключения в электроустановках, и контролирующим лицом? Выберите правильный вариант ответа.',
        answers: [
          'Допускается',
          'Не допускается',
          'Допускается с разрешения вышестоящего оперативного (диспетчерского) персонала',
        ],
        correctAnswers: ['Не допускается'],
      },
      {
        code: '64849115',
        text:
          'На какой срок и сколько раз может быть продлен наряд на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Не более 30 календарных дней и не более чем 1 раз',
          'Не более 15 календарных дней и не более чем 1 раз',
          'Не более 15 календарных дней и не более чем 2 раза',
          'Не более 3 календарных дней и не более чем 2 раза',
        ],
        correctAnswers: ['Не более 15 календарных дней и не более чем 1 раз'],
      },
      {
        code: '64849116',
        text:
          'В каких документах определяется объем минимально необходимых поставок электрической энергии потребителю при возникновении или угрозе возникновения аварийного электроэнергетического режима? Выберите правильный вариант ответа.',
        answers: [
          'В акте согласования технологической и аварийной брони электроснабжения потребителя, являющемся неотъемлемым приложением к договору энергоснабжения (договору оказания услуг по передаче электрической энергии)',
          'В договоре энергоснабжения (договор оказания услуг по передаче электрической энергии)',
          'В специальном акте согласования технологической и аварийной брони электроснабжения потребителя',
          'В специальном акте согласования объема минимально необходимых поставок электрической энергии потребителю',
        ],
        correctAnswers: [
          'В акте согласования технологической и аварийной брони электроснабжения потребителя, являющемся неотъемлемым приложением к договору энергоснабжения (договору оказания услуг по передаче электрической энергии)',
        ],
      },
      {
        code: '64849117',
        text:
          'На какой персонал распространяются требования специальной подготовки? Выберите правильный вариант ответа.',
        answers: [
          'На руководящий персонал, работающий в электроустановках',
          'На ремонтный персонал, связанный с техническим обслуживанием, ремонтом, наладкой и испытанием энергоустановок',
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
          'На управленческий персонал и специалистов производственных подразделений',
        ],
        correctAnswers: [
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64849118',
        text:
          'Какое определение соответствует термину "дублирование"? Выберите правильный вариант ответа.',
        answers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
          'Практическое освоение непосредственно на рабочем месте навыков выполнения работы или группы работ, приобретенных при профессиональной подготовке',
          'Одна из форм дополнительного повышения образовательного уровня оперативного персонала, осуществляемая путем систематического самообразования',
          'Форма поддержания квалификации работника путем его систематической тренировки в управлении производственными процессами на учебно-тренировочных средствах, формирования его знаний, умения и навыков, проработки организационно-распорядительных документов и разборки технологических нарушений, пожаров и случаев производственного травматизма',
        ],
        correctAnswers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
        ],
      },
      {
        code: '64849119',
        text:
          'Какие организации электроэнергетики должны разработать порядок проведения работы с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Все организации электроэнергетики',
          'Только организации, эксплуатирующие трансформаторные подстанции',
          'Только организации, эксплуатирующие тепловые электростанции',
          'Только организации, эксплуатирующие гидроэлектростанции',
        ],
        correctAnswers: ['Все организации электроэнергетики'],
      },
      {
        code: '64849120',
        text:
          'Какое время прибытия оперативного персонала на объект электроэнергетики должно быть, в случае организации оперативного обслуживания объекта электроэнергетики в форме, не предполагающей постоянного дежурства оперативного персонала на объекте? Выберите правильный вариант ответа.',
        answers: [
          'Не должно превышать 120 минут',
          'Не должно превышать 60 минут',
          'Не должно превышать 90 минут',
        ],
        correctAnswers: ['Не должно превышать 60 минут'],
      },
      {
        code: '64849121',
        text:
          'Должно ли уведомление, направляемое субъектом оперативно-диспетчерского управления в штаб содержать информацию о необходимости уведомления потребителей электрической энергии о рисках работы энергосистемы в вынужденном режиме и организации выполнения ими мероприятий по обеспечению электроснабжения в аварийных ситуациях (в том числе по проверке работоспособности резервных источников снабжения электроэнергией)? Выберите правильный вариант ответа.',
        answers: ['Нет', 'Да'],
        correctAnswers: ['Да'],
      },
      {
        code: '64849122',
        text:
          'Каков порядок допуска к самостоятельной работе вновь принятых работников или имевших перерыв в работе более 6 месяцев? Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от категории персонала, после прохождения этапов подготовки в соответствии с индивидуальной программой',
          'В зависимости от категории персонала после прохождения специальной подготовки, программу и порядок проведения которой определяет руководитель организации',
          'В зависимости от категории персонала форму подготовки персонала для допуска к самостоятельной работе определяет руководитель организации или структурного подразделения',
          'В зависимости от категории персонала после ознакомления с изменениями в схемах и режимах работы энергоустановок, с вновь введенными в действие нормативно-техническими документами, приказами и распоряжениями',
        ],
        correctAnswers: [
          'В зависимости от категории персонала, после прохождения этапов подготовки в соответствии с индивидуальной программой',
        ],
      },
      {
        code: '64849123',
        text:
          'На сколько дней в случае необходимости руководитель Ростехнадзора может продлить срок проведения расследования причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Не более чем на 45 дней',
          'Не более чем на 20 дней',
          'Не более чем на 10 дней',
          'Не более чем на 3 дня',
        ],
        correctAnswers: ['Не более чем на 45 дней'],
      },
      {
        code: '64849124',
        text:
          'В какие сроки проводится первичная проверка знаний работников, относящихся к категории административно-технического персонала или вспомогательного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее одной недели после назначения на должность',
          'Не позднее 3 месяцев после назначения на должность',
          'Не позднее одного месяца после назначения на должность',
          'Не позднее двух недель после назначения на должность',
        ],
        correctAnswers: [
          'Не позднее одного месяца после назначения на должность',
        ],
      },
      {
        code: '64849125',
        text:
          'В каком случае допускается совмещение наблюдающим надзора с выполнением какой-либо работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Допускается в случае электроустановок до 1000 В',
          'Совмещение не допускается',
          'Допускается в любом случае',
          'Допускается в случае работы на нескольких рабочих местах, находящихся в прямой видимости',
        ],
        correctAnswers: ['Совмещение не допускается'],
      },
      {
        code: '64849126',
        text:
          'Какие мероприятия должны быть выполнены для первичного фактического приема (подачи) рабочего напряжения и мощности на ЛЭП и новое основное оборудование на вновь построенных, реконструированных, модернизированных, технически перевооруженных объектах электроэнергетики (постановки их под нагрузку или включения в транзит), а также фактического приема (подачи) рабочего напряжения и мощности на новое оборудование на действующих объектах электроэнергетики, в том числе после его замены? Выберите правильный вариант ответа.',
        answers: [
          'Только проведены индивидуальные испытания оборудования и функциональные испытания отдельных технологических систем',
          'Только если получено временное разрешение органа федерального государственного энергетического надзора на допуск в эксплуатацию объекта электроэнергетики для проведения пусконаладочных работ и проведения комплексного опробования (в случае необходимости)',
          'Только если проведены пробные пуски генерирующего оборудования',
          'Все перечисленные мероприятия',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '64849127',
        text:
          'В какой срок комиссия по расследованию причин аварии уведомляет субъект электроэнергетики и (или) потребителя электрической энергии о начале обследования? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее чем за 1 час',
          'Не позднее чем за 3 часа',
          'Не позднее чем за 12 часов',
          'Не позднее чем за 24 часа',
        ],
        correctAnswers: ['Не позднее чем за 3 часа'],
      },
      {
        code: '64849128',
        text:
          'При каком уровне напряженности электрического поля разрешается пребывание персонала в электрическом поле в течение всего рабочего дня (8 ч)? Выберите правильный вариант ответа.',
        answers: [
          'Не более 5 кВ/м',
          'Не более 7 кВ/м',
          'Не более 12 кВ/м',
          'Не более 9 кВ/м',
        ],
        correctAnswers: ['Не более 5 кВ/м'],
      },
      {
        code: '64849129',
        text:
          'С каким персоналом в организации должен проводиться производственный инструктаж? Выберите правильный вариант ответа.',
        answers: [
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
          'Только с работниками, принимаемыми на должности, не связанные с нахождением в зоне действующих энергоустановок и не связанных с их обслуживанием',
          'Только с командированными, студентами и учащимися, прибывшими на предприятие для производственного обучения или практики',
          'Только с временными работниками',
        ],
        correctAnswers: [
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64849130',
        text:
          'Какие государственные органы имеют право принимать нормативные правовые акты в области государственного регулирования отношений в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации и уполномоченные им федеральные органы исполнительной власти',
          'Только федеральные органы исполнительной власти',
          'Органы государственной власти субъектов Российской Федерации',
          'Органы местного самоуправления',
        ],
        correctAnswers: [
          'Правительство Российской Федерации и уполномоченные им федеральные органы исполнительной власти',
        ],
      },
      {
        code: '64849131',
        text:
          'Какие требования предъявляются к владельцам объектов электроэнергетики и потребителям, участвующим в противоаварийном управлении, линии электропередачи, оборудование и устройства которых относятся к объектам диспетчеризации, в части организации каналов связи от принадлежащих им объектов электроэнергетики до каждого ДЦ, к объектам диспетчеризации которого относятся соответствующие линии электропередачи, оборудование и устройства? Выберите правильный вариант ответа.',
        answers: [
          'Должны организовать наличие и обеспечить функционирование двух независимых каналов связи до узлов доступа сетей связи, определенных субъектом оперативно-диспетчерского управления, а организация каналов связи от указанных узлов доступа до ДЦ осуществляется субъектом оперативно-диспетчерского управления',
          'Должны организовать наличие и обеспечить функционирование двух независимых каналов связи до ДЦ субъекта оперативно-диспетчерского управления',
          'Должны организовать наличие и обеспечить функционирование двух каналов связи до узлов доступа сетей связи, определенных субъектом оперативно-диспетчерского управления, а организация каналов связи от указанных узлов доступа до ДЦ осуществляется субъектом оперативно-диспетчерского управления',
          'Должны организовать наличие и обеспечить функционирование двух каналов связи до ДЦ субъекта оперативно-диспетчерского управления',
        ],
        correctAnswers: [
          'Должны организовать наличие и обеспечить функционирование двух независимых каналов связи до узлов доступа сетей связи, определенных субъектом оперативно-диспетчерского управления, а организация каналов связи от указанных узлов доступа до ДЦ осуществляется субъектом оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64849132',
        text:
          'Должен ли диспетчерский персонал субъекта оперативно-диспетчерского управления уведомить посредством диспетчерской телефонной связи о фактическом переходе энергосистемы на работу в вынужденном режиме и фактическом завершении работы энергосистемы в вынужденном режиме оперативный персонал субъектов электроэнергетики, осуществляющих эксплуатацию линий электропередачи, электросетевого и (или) генерирующего оборудования, устройств РЗА, эксплуатационное состояние и (или) режим работы которых оказывают влияние на величину фактического или максимально допустимого (аварийно допустимого) перетока активной мощности в контролируемом сечении, в котором осуществляется переход энергосистемы на работу в вынужденном режиме? Выберите правильный вариант ответа.',
        answers: ['Да', 'Нет'],
        correctAnswers: ['Да'],
      },
      {
        code: '64849133',
        text:
          'С какой периодичностью должно проводиться дополнительное профессиональное образование работников, относящихся к категориям административно-технического, диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 6 лет',
          'Не реже 1 раза в 10 лет',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '64849134',
        text:
          'Каким образом должен быть оформлен допуск к дублированию при подготовке по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'Определяется комиссией по проверке знаний в зависимости от его уровня профессиональной подготовки, стажа и опыта оперативной работы',
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
          'Определяется руководителем структурного подразделения',
          'Определяется техническим директором организации',
        ],
        correctAnswers: [
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
        ],
      },
      {
        code: '64849135',
        text:
          'В течение какого времени материалы расследования причин аварии подлежат хранению Ростехнадзором? Выберите правильный вариант ответа.',
        answers: [
          'Не менее одного года',
          'Не менее двух лет',
          'Не менее трех лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее трех лет'],
      },
      {
        code: '64849136',
        text:
          'Каким документом определен порядок действий диспетчерских центров субъекта оперативно- диспетчерского управления и владельцев объектов электроэнергетики в условиях режима с высокими рисками нарушения электроснабжения? Выберите правильный вариант ответа.',
        answers: [
          'Правилами оперативно-диспетчерского управления в электроэнергетике',
          'Правилами предоставления информации, необходимой для осуществления оперативно- диспетчерского управления в электроэнергетике',
          'Правилами перехода на работу в вынужденном режиме и условия работы в вынужденном режиме',
          'Правилами предотвращения развития и ликвидации нарушений нормального режима электрической части энергосистем и объектов электроэнергетики',
        ],
        correctAnswers: [
          'Правилами оперативно-диспетчерского управления в электроэнергетике',
        ],
      },
      {
        code: '64849137',
        text:
          'Где должна производиться синхронизация при наличии одновременной возможности синхронизации разделившихся частей энергосистем на элементах электрической сети разного класса напряжения? Выберите правильный вариант ответа.',
        answers: [
          'Не регламентируется',
          'На элементе электрической сети высшего класса напряжения',
          'На элементе электрической сети низшего класса напряжения',
        ],
        correctAnswers: [
          'На элементе электрической сети высшего класса напряжения',
        ],
      },
      {
        code: '64849138',
        text:
          'На основании чего устанавливается величина амплитуды нерегулярных колебаний активной мощности в контролируемом сечении: Выберите правильный вариант ответа.',
        answers: [
          'На основании расчетного выражения по определению амплитуды нерегулярных колебаний активной мощности в контролируемом сечении',
          'На основании статистических данных, а при невозможности их использования – на основании расчетного выражения',
        ],
        correctAnswers: [
          'На основании статистических данных, а при невозможности их использования – на основании расчетного выражения',
        ],
      },
      {
        code: '64849139',
        text:
          'Укажите периодичность проведения контрольных замеров потокораспределения, нагрузок и уровней напряжения, выполняемых субъектами электроэнергетики по заданию диспетчерских центров системного оператора. Выберите правильный вариант ответа.',
        answers: [
          'Ежеквартально (первого числа месяца квартала)',
          '2 раза в год - в третью среду июня и третью среду декабря',
          'Ежемесячно (первого числа месяца)',
        ],
        correctAnswers: [
          '2 раза в год - в третью среду июня и третью среду декабря',
        ],
      },
      {
        code: '64849140',
        text:
          'Каким документом определен Перечень технологически изолированных территориальных электроэнергетических систем и соответствующих субъектов оперативно-диспетчерского управления? Выберите правильный вариант ответа.',
        answers: [
          'Федеральным законом об электроэнергетике от 26.03.2003 № 35-ФЗ',
          'Правилами технологического функционирования электроэнергетических систем от 13.08.2018 № 937',
          'Правилами оперативно-диспетчерского управления в электроэнергетике, утвержденными постановлением Правительства Российской Федерации от 27 декабря 2004 г. № 854',
        ],
        correctAnswers: [
          'Правилами оперативно-диспетчерского управления в электроэнергетике, утвержденными постановлением Правительства Российской Федерации от 27 декабря 2004 г. № 854',
        ],
      },
      {
        code: '64849141',
        text:
          'Какое минимальное количество человек должно присутствовать при проведении процедуры проверки знаний работников организаций электроэнергетики? Выберите правильный вариант ответа.',
        answers: ['3', '2', '5', '4'],
        correctAnswers: ['3'],
      },
      {
        code: '64849142',
        text:
          'В каких из перечисленных случаев наряд должен быть выдан заново? Выберите правильный вариант ответа.',
        answers: [
          'Только при изменении состава бригады более чем на половину',
          'В любом из перечисленных случаев',
          'Только при изменении условий работы',
          'Только при замене ответственного руководителя работ или производителя работ, или наблюдающего',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
    ],
    64848: [
      {
        code: '64848000',
        text:
          'Какие сведения указываются при регистрации каждой диспетчерской команды (разрешения) диспетчерским центром? Выберите правильный вариант ответа.',
        answers: [
          'Время выдачи (получения) команды (разрешения)',
          'Требуемое время исполнения команды (в случае если предусмотренные командой действия требуется выполнить в определенное время)',
          'Фамилия лица, отдавшего команду (разрешение)',
          'Фамилия лица, которому адресована команда (разрешение)',
          'Содержание команды (разрешения)',
          'Все перечисленные сведения',
        ],
        correctAnswers: ['Все перечисленные сведения'],
      },
      {
        code: '64848001',
        text:
          'Что устанавливают Правила технологического функционирования электроэнергетических систем? Выберите 4 варианта ответа.',
        answers: [
          'Порядок технологического функционирования Единой энергетической системы России и технологически изолированных территориальных электроэнергетических систем',
          'Порядок и условия обеспечения параллельной работы объектов электроэнергетики и энергопринимающих установок потребителей электрической энергии в составе электроэнергетической системы',
          'Общие условия взаимодействия субъектов электроэнергетики и потребителей электрической энергии в целях обеспечения надежности и устойчивости энергосистемы, качественного и надежного снабжения потребителей электрической энергией',
          'Общие системные требования к релейной защите и автоматике, линиям электропередачи, оборудованию электрических станций и сетей, электроустановкам потребителей электрической энергии как элементам энергосистемы, организации и осуществлению их эксплуатации',
          'Технологические нарушения в работе электротехнического оборудования и автоматики',
          'Правовые основы экономических отношений в сфере электроэнергетики, определяет полномочия органов государственной власти на регулирование этих отношений, основные права и обязанности субъектов электроэнергетики',
        ],
        correctAnswers: [
          'Порядок технологического функционирования Единой энергетической системы России и технологически изолированных территориальных электроэнергетических систем',
          'Порядок и условия обеспечения параллельной работы объектов электроэнергетики и энергопринимающих установок потребителей электрической энергии в составе электроэнергетической системы',
          'Общие условия взаимодействия субъектов электроэнергетики и потребителей электрической энергии в целях обеспечения надежности и устойчивости энергосистемы, качественного и надежного снабжения потребителей электрической энергией',
          'Общие системные требования к релейной защите и автоматике, линиям электропередачи, оборудованию электрических станций и сетей, электроустановкам потребителей электрической энергии как элементам энергосистемы, организации и осуществлению их эксплуатации',
        ],
      },
      {
        code: '64848002',
        text:
          'В течение какого времени при выделении генераторов тепловых электростанций на собственные нужды или сбалансированную нагрузку действием частотной делительной автоматики должна обеспечиваться устойчивая работа выделяемого генерирующего оборудования? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 30 минут',
          'Не менее 20 минут',
          'Не менее 40 минут',
          'Не менее 60 минут',
        ],
        correctAnswers: ['Не менее 30 минут'],
      },
      {
        code: '64848003',
        text:
          'Каким требованиям должно удовлетворять быстродействие релейной защиты при отключении коротких замыканий? Выберите 2 варианта ответа.',
        answers: [
          'Обеспечения устойчивости параллельной работы генераторов энергосистемы',
          'Обеспечения устойчивости нагрузки потребителей',
          'Обеспечения устройствами релейной защиты и автоматики',
          'Защищенности от несанкционированного доступа к информации',
        ],
        correctAnswers: [
          'Обеспечения устойчивости параллельной работы генераторов энергосистемы',
          'Обеспечения устойчивости нагрузки потребителей',
        ],
      },
      {
        code: '64848004',
        text:
          'В каком случае комплексное опробование генерирующего оборудования ветровой (ветроэлектрической) электростанции и солнечной электростанции считается успешно пройденным? Выберите правильный вариант ответа.',
        answers: [
          'При непрерывной работе генерирующего оборудования в течение не менее 12 часов с любой нагрузкой',
          'При непрерывной работе генерирующего оборудования в течение не менее 1 часа с нагрузкой, не менее 75% от номинальной',
          'При работе генерирующего оборудования в течение не менее 24 часов с любой нагрузкой за период не более 45 календарных дней с момента начала комплексного опробования',
          'При непрерывной работе генерирующего оборудования в течение не менее 2 часов с любой нагрузкой',
        ],
        correctAnswers: [
          'При работе генерирующего оборудования в течение не менее 24 часов с любой нагрузкой за период не более 45 календарных дней с момента начала комплексного опробования',
        ],
      },
      {
        code: '64848005',
        text:
          'Кто должен назначаться допускающим в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Работники из числа оперативного персонала',
          'Работники из числа административного персонала',
          'Работники из числа электротехнологического персонала',
          'Работники из числа ремонтного персонала',
        ],
        correctAnswers: ['Работники из числа оперативного персонала'],
      },
      {
        code: '64848006',
        text:
          'Какие из перечисленных работ в электроустановках напряжением выше 1000 В необходимо проводить только по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'Неотложные работы, для выполнения которых требуется более 1 часа или участие более трех работников, включая работника из оперативного и оперативно-ремонтного персонала',
          'Работы на генераторе, от выводов которого отсоединены шины и кабели',
          'Работы в распределительных устройствах на выкаченных тележках комплектных распределительных устройств, у которых шторки отсеков заперты на замок',
          'Работы на электродвигателе, от которого отсоединен кабель, а концы его замкнуты накоротко и заземлены',
        ],
        correctAnswers: [
          'Неотложные работы, для выполнения которых требуется более 1 часа или участие более трех работников, включая работника из оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64848007',
        text:
          'В течение какого времени со дня утверждения комиссией акта расследования материалы расследования причин аварии подлежат хранению Ростехнадзором? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 1 года',
          'Не менее 3 лет',
          'Не менее 5 лет',
          'Не менее 2 лет',
        ],
        correctAnswers: ['Не менее 3 лет'],
      },
      {
        code: '64848008',
        text:
          'Какой состав постоянно действующей комиссии по проверке знаний организации? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 5 человек, председатель, заместители председателя и 2 члена центральной комиссии организации должны пройти проверку знаний руководителем или заместителем руководителя организации, прошедшим аттестацию по вопросам безопасности в сфере электроэнергетики в соответствии с нормативными правовыми актами по вопросам аттестации',
          'Не менее чем из 3 человек, включая председателя и заместителя(ей) председателя комиссии',
          'Не менее 3-х человек, которые прошли проверку знаний в комиссии органа, уполномоченного на осуществление федерального государственного энергетического надзора',
        ],
        correctAnswers: [
          'Не менее 5 человек, председатель, заместители председателя и 2 члена центральной комиссии организации должны пройти проверку знаний руководителем или заместителем руководителя организации, прошедшим аттестацию по вопросам безопасности в сфере электроэнергетики в соответствии с нормативными правовыми актами по вопросам аттестации',
        ],
      },
      {
        code: '64848009',
        text:
          'Какие требования должны соблюдаться при работе энергосистемы в нормальном режиме? Выберите 3 варианта ответа.',
        answers: [
          'Отклонения частоты электрического тока не должны превышать допустимые значения',
          'Перетоки активной мощности в контролируемых сечениях не должны превышать максимально допустимые значения',
          'Напряжение на объектах электроэнергетики должно быть выше минимально допустимых значений, но не должно превышать наибольшие рабочие значения',
          'Токовые нагрузки линий электропередачи и электросетевого оборудования должны значительно превышать длительно допустимые значения',
          'Напряжение на объектах электроэнергетики должно быть выше максимально допустимых значений',
        ],
        correctAnswers: [
          'Отклонения частоты электрического тока не должны превышать допустимые значения',
          'Перетоки активной мощности в контролируемых сечениях не должны превышать максимально допустимые значения',
          'Напряжение на объектах электроэнергетики должно быть выше минимально допустимых значений, но не должно превышать наибольшие рабочие значения',
        ],
      },
      {
        code: '64848010',
        text:
          'Каким образом оформляется решение о расследовании причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Несогласные члены комиссии подписывают акт с примечанием "не согласен"',
          'Несогласные члены комиссии подписывают акт, а их "особое мнение" прилагается к акту расследования',
          'Несогласные члены комиссии подписывают акт с "особым мнением", изложенным рядом с их подписью или адресующим к отдельному приложению',
          'Несогласные члены комиссии акт не подписывают и направляют "особое мнение" в Управление государственного энергетического надзора Ростехнадзора',
        ],
        correctAnswers: [
          'Несогласные члены комиссии подписывают акт, а их "особое мнение" прилагается к акту расследования',
        ],
      },
      {
        code: '64848011',
        text:
          'Какие общесистемные технические параметры не определяются для ветроэнергетических установок и фотоэлектрических солнечных модулей? Выберите правильный вариант ответа.',
        answers: [
          'Максимальная располагаемая мощность',
          'Регулировочный диапазон активной мощности',
          'Скорость снижения активной мощности',
          'Скорость набора активной мощности',
        ],
        correctAnswers: ['Скорость набора активной мощности'],
      },
      {
        code: '64848012',
        text:
          'В какой срок с момента отключения (повреждения) или разрушения оборудования или устройств, явившиеся причиной или следствием пожара на объекте, собственник или иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация принимает решение о создании комиссии по расследованию причин аварии и ее составе? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 8 часов с момента получения органом федерального государственного энергетического надзора информации об аварии',
          'Не позднее 72 часов с момента получения органом федерального государственного энергетического надзора информации об аварии',
          'Не позднее 48 часов с момента получения органом федерального государственного энергетического надзора информации об аварии',
          'Не позднее 24 часов с момента получения органом федерального государственного энергетического надзора информации об аварии',
        ],
        correctAnswers: [
          'Не позднее 24 часов с момента получения органом федерального государственного энергетического надзора информации об аварии',
        ],
      },
      {
        code: '64848013',
        text:
          'Какое минимальное количество членов комиссии организации по проверке знаний должно присутствовать при проведении процедуры проверки знаний работников организаций электроэнергетики? Выберите правильный вариант ответа.',
        answers: ['5', '2', '3', '4'],
        correctAnswers: ['3'],
      },
      {
        code: '64848014',
        text:
          'С какой периодичностью собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация представляют сводный отчет об авариях в электроэнергетике в орган федерального государственного энергетического надзора, уполномоченный орган в сфере электроэнергетики, а также субъекту оперативно-диспетчерского управления в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Ежемесячно',
          '1 раз в квартал',
          'Ежегодно',
          '1 раз в 6 месяцев',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64848015',
        text:
          'Какое время должно происходить снижение активной мощности генерирующего оборудования ветровой (ветроэлектрической) электростанции и солнечной электростанции при увеличении частоты за верхнюю границу "мертвой полосы" первичного регулирования? Выберите правильный вариант ответа.',
        answers: [
          'Не более 5 секунд',
          'Не более 10 секунд',
          'Не более 15 секунд',
          'Не более 20 секунд',
        ],
        correctAnswers: ['Не более 5 секунд'],
      },
      {
        code: '64848016',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 3 месяца',
          'Не реже 1 раза в месяц',
          'В зависимости от стажа работника, но не реже 1 раза в 6 месяцев',
          'На усмотрение непосредственного руководителя в зависимости от характера выполняемой работы',
        ],
        correctAnswers: ['Не реже 1 раза в 3 месяца'],
      },
      {
        code: '64848017',
        text:
          'Что из перечисленного не определяется при разработке балансов электрической энергии и балансов мощности на перспективный период? Выберите правильный вариант ответа.',
        answers: [
          'Показатели выработки электрической энергии гидроэлектростанций',
          'Показатели выработки электрической энергии атомных электростанций',
          'Величина прогнозного потребления электрической энергии и мощности по каждой территориальной энергосистеме',
          'Показатели выработки электрической энергии ветровых электростанций',
        ],
        correctAnswers: [
          'Показатели выработки электрической энергии ветровых электростанций',
        ],
      },
      {
        code: '64848018',
        text:
          'Каковы условия проведения специальной подготовки персонала? Выберите правильный вариант ответа.',
        answers: [
          'Должна проводиться с отрывом от выполнения основных функций не реже одного раза в месяц и составлять от 5 до 20% его рабочего времени',
          'Должна проводиться еженедельно в течение рабочего дня с отрывом от производства',
          'Должна проводиться по утвержденному графику в свободное от работы время',
          'Должна проводиться ежедневно в течение рабочей смены и составлять до 30% рабочего времени',
        ],
        correctAnswers: [
          'Должна проводиться с отрывом от выполнения основных функций не реже одного раза в месяц и составлять от 5 до 20% его рабочего времени',
        ],
      },
      {
        code: '64848019',
        text:
          'Какое допускается отклонение фактической мощности генерирующего оборудования от требуемой величины задания активной мощности в установившемся режиме? Выберите правильный вариант ответа.',
        answers: [
          'Не более 1 % от номинальной мощности генерирующего оборудования',
          'Не более 3 % от номинальной мощности генерирующего оборудования',
          'Не более 5 % от номинальной мощности генерирующего оборудования',
          'Не более 10 % от номинальной мощности генерирующего оборудования',
        ],
        correctAnswers: [
          'Не более 1 % от номинальной мощности генерирующего оборудования',
        ],
      },
      {
        code: '64848020',
        text:
          'Какое определение соответствует понятию «временная нормальная схема электрических соединений объекта электроэнергетики»? Выберите правильный вариант ответа.',
        answers: [
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и заземляющие разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап жизненного цикла строящегося (реконструируемого) объекта электроэнергетики',
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и заземляющие разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап капитального ремонта или реконструкции объекта электроэнергетики',
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и линейные (шинные) разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап жизненного цикла строящегося (реконструируемого) объекта электроэнергетики',
        ],
        correctAnswers: [
          'Схема электрических соединений объекта электроэнергетики, на которой все коммутационные аппараты и заземляющие разъединители изображаются в положении, соответствующем их нормальному коммутационному положению на предстоящий этап жизненного цикла строящегося (реконструируемого) объекта электроэнергетики',
        ],
      },
      {
        code: '64848021',
        text:
          'В каком эксплуатационном состоянии могут находиться устройства релейной защиты и автоматики? Выберите 3 варианта ответа.',
        answers: [
          'В резерве',
          'Введены в работу',
          'Оперативно выведены (не для производства работ);',
          'Выведены для технического обслуживания',
          'На испытаниях',
          'В ремонте',
        ],
        correctAnswers: [
          'Введены в работу',
          'Оперативно выведены (не для производства работ);',
          'Выведены для технического обслуживания',
        ],
      },
      {
        code: '64848022',
        text:
          'Каким образом оформляется наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'В двух экземплярах независимо от способа передачи',
          'В трех экземплярах независимо от способа передачи',
          'В трех экземплярах только письменно в присутствии производителя работ, передача по телефону или радио не допускается',
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
        ],
        correctAnswers: [
          'В двух экземплярах, а при передаче по телефону или радио - в трех экземплярах',
        ],
      },
      {
        code: '64848023',
        text:
          'Куда должна быть подана диспетчерская заявка при необходимости изменения технологического режима работы или эксплуатационного состояния объекта диспетчеризации его владельцем? Выберите правильный вариант ответа.',
        answers: [
          'В региональный центр Ростехнадзора',
          'В соответствующий диспетчерский центр субъекта оперативно-диспетчерского управления',
          'В орган местного самоуправления',
          'В региональное представительство министерства энергетики',
        ],
        correctAnswers: [
          'В соответствующий диспетчерский центр субъекта оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64848024',
        text:
          'Кто является ответственным за безопасное ведение работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только выдающий наряд-допуск, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, ответственный руководитель работ, допускающий',
          'Только выдающий наряд-допуск, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ',
          'Только выдающий наряд-допуск, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
        correctAnswers: [
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
      },
      {
        code: '64848025',
        text:
          'В какой срок комиссия по расследованию причин аварии уведомляет субъект электроэнергетики и (или) потребителя электрической энергии о начале обследования объектов электроэнергетики и (или) энергопринимающих установок? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее чем за 12 часов',
          'Не позднее чем за 3 часа',
          'Не позднее чем за 1 час',
          'Не позднее чем за 6 часов',
        ],
        correctAnswers: ['Не позднее чем за 3 часа'],
      },
      {
        code: '64848026',
        text:
          'Каким образом устанавливается продолжительность дублирования конкретного работника? Выберите правильный вариант ответа.',
        answers: [
          'Устанавливается руководителем территориального органа государственного энергетического надзора',
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
          'Определяется комиссией по проверке знаний в зависимости от уровня профессиональной подготовки, стажа и опыта оперативной работы работника',
          'Продолжительность определяется руководителем структурного подразделения',
        ],
        correctAnswers: [
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
        ],
      },
      {
        code: '64848027',
        text:
          'Какая выдача мощности солнечной или ветровой электростанций допускается? Выберите правильный вариант ответа.',
        answers: [
          'По одной отходящей от шин электростанции линии электропередачи классом напряжения 220 кВ и выше',
          'По одной отходящей от шин электростанции линии электропередачи классом напряжения 110 (154) кВ и ниже',
          'По одной отходящей от шин электростанции линии электропередачи классом напряжения 330 кВ и ниже',
          'По одной отходящей от шин электростанции линии электропередачи классом напряжения 220 кВ и ниже',
        ],
        correctAnswers: [
          'По одной отходящей от шин электростанции линии электропередачи классом напряжения 110 (154) кВ и ниже',
        ],
      },
      {
        code: '64848028',
        text:
          'Кто несет ответственность за работу с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель службы охраны труда',
          'Технический руководитель организации',
          'Руководитель организации или должностное лицо (должностные лица) из числа руководящих работников организации, которому (которым) руководитель организации передает эту функцию и права',
          'Руководитель отдела кадров',
        ],
        correctAnswers: [
          'Руководитель организации или должностное лицо (должностные лица) из числа руководящих работников организации, которому (которым) руководитель организации передает эту функцию и права',
        ],
      },
      {
        code: '64848029',
        text:
          'Выполнение каких функций автоматического режимного управления обеспечивает режимная автоматика, применяемая в энергосистеме? Выберите 3 варианта ответа.',
        answers: [
          'Регулирование температуры',
          'Регулирование частоты',
          'Регулирование перетоков активной мощности',
          'Регулирование напряжения и реактивной мощности',
          'Регулирование давления масла',
          'Регулирование давления воздуха',
        ],
        correctAnswers: [
          'Регулирование частоты',
          'Регулирование перетоков активной мощности',
          'Регулирование напряжения и реактивной мощности',
        ],
      },
      {
        code: '64848030',
        text:
          'С каким персоналом в организации должен проводиться производственный инструктаж? Выберите правильный вариант ответа.',
        answers: [
          'С работниками, не связанными с обслуживанием действующих энергоустановок',
          'Только с командированными работниками, прибывшими на предприятие для производственного обучения',
          'С работниками, относящимися к категории ремонтного персонала',
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
        correctAnswers: [
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64848031',
        text:
          'Кто имеет право включать электроустановки после полного окончания работ? Выберите правильный вариант ответа.',
        answers: [
          'Работник с группой по электробезопасности не ниже IV',
          'Работник из числа ремонтного персонала с группой по электробезопасности III',
          'Ответственное лицо, осуществляющее контроль и надзор за соблюдением требований безопасности при эксплуатации электроустановок',
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
        ],
      },
      {
        code: '64848032',
        text:
          'Какое напряжение должны иметь переносные электрические светильники, используемые в помещениях с повышенной опасностью и особо опасных? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 50 В',
          'Не выше 127 В',
          'Не выше 220 В',
          'Не выше 380 В',
        ],
        correctAnswers: ['Не выше 50 В'],
      },
      {
        code: '64848033',
        text:
          'Чем должны отличаться светильники аварийного освещения от светильников рабочего освещения? Выберите правильный вариант ответа.',
        answers: [
          'Материалом корпуса и контактов',
          'Знаками или окраской',
          'Конструкцией и функционалом',
          'Массой и габаритами',
        ],
        correctAnswers: ['Знаками или окраской'],
      },
      {
        code: '64848034',
        text:
          'Под наблюдением каких работников должен осуществляться проезд автомобилей, подъемных сооружений и механизмов по территории открытого распределительного устройства и в охранной зоне воздушной линии выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Одного из работников из числа оперативного персонала, работника, выдавшего наряд или ответственного руководителя',
          'Одного из работников из числа ремонтного персонала с группой III по электробезопасности',
          'Члена бригады с III группой по электробезопасности',
          'Наблюдающего с группой II по электробезопасности',
        ],
        correctAnswers: [
          'Одного из работников из числа оперативного персонала, работника, выдавшего наряд или ответственного руководителя',
        ],
      },
      {
        code: '64848035',
        text:
          'Кто осуществляет оперативно-диспетчерское управление в пределах Единой энергетической системы России? Выберите правильный вариант ответа.',
        answers: [
          'Оперативно-диспетчерское управление',
          'Системный оператор',
          'Управление энергонадзора',
          'Правительство Российской Федерации',
        ],
        correctAnswers: ['Системный оператор'],
      },
      {
        code: '64848036',
        text:
          'В отношении каких объектов и установок на время проведения испытаний и пусконаладочных работ не выдается временное разрешение на допуск в эксплуатацию? Выберите правильный вариант ответа.',
        answers: [
          'Для объектов по производству электрической энергии',
          'Для оборудования, работающего под избыточным давлением, ввод в эксплуатацию которого осуществляется в соответствии с законодательством в области промышленной безопасности',
          'Для объектов теплоснабжения',
          'Для вновь вводимых в эксплуатацию или реконструированных объектов по производству электрической энергии',
        ],
        correctAnswers: [
          'Для оборудования, работающего под избыточным давлением, ввод в эксплуатацию которого осуществляется в соответствии с законодательством в области промышленной безопасности',
        ],
      },
      {
        code: '64848037',
        text:
          'От каких факторов не зависит необходимость и длительность каждого этапа подготовки по новой должности оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'От стажа работы по смежным должностям (профессиям)',
          'От возраста работника',
          'От квалификации работника',
          'От уровня его знаний и технической сложности объекта',
        ],
        correctAnswers: ['От возраста работника'],
      },
      {
        code: '64848038',
        text:
          'В течение какого времени субъект оперативно-диспетчерского управления должен согласовать проект решения об отсутствии технической возможности участия в общем первичном регулировании частоты генерирующего оборудования тепловой электростанции с турбинами типа "Р" или отказать в согласовании такого проекта? Выберите правильный вариант ответа.',
        answers: [
          'В течение 3 рабочих дней',
          'В течение 15 рабочих дней',
          'В течение 5 рабочих дней',
          'В течение 7 рабочих дней',
        ],
        correctAnswers: ['В течение 15 рабочих дней'],
      },
      {
        code: '64848039',
        text:
          'Каким образом юридическим лицом представляются в орган федерального государственного энергетического надзора заявление о выдаче разрешения на допуск в эксплуатацию энергоустановки и прилагаемые к нему документы? Выберите правильный вариант ответа.',
        answers: [
          'На бумажном носителе непосредственно',
          'Посредством заказного почтового отправления с уведомлением о вручении',
          'В виде электронного документа',
          'Любым из перечисленных способов',
        ],
        correctAnswers: ['Любым из перечисленных способов'],
      },
      {
        code: '64848040',
        text:
          'На какое расстояние до токоведущих частей электроустановок, находящихся под напряжением 1 - 35 кВ, не допускается приближение людей при оперативном обслуживании, осмотрах электроустановок, а также выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Менее чем 0,6 м',
          'Менее чем 0,5 м',
          'Менее чем 0,4 м',
          'Менее чем 0,3 м',
        ],
        correctAnswers: ['Менее чем 0,6 м'],
      },
      {
        code: '64848041',
        text:
          'Кто из уполномоченных представителей не может быть включен при необходимости в состав комиссии по расследованию причин аварии в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Представители работодателя',
          'Представители уполномоченного органа в сфере электроэнергетики',
          'Представители  субъекта оперативно-диспетчерского управления в электроэнергетике',
          'Потребителей электрической энергии, присоединенная мощность которых не превышает 50 МВт',
          'Представители генерирующих компаний',
        ],
        correctAnswers: [
          'Потребителей электрической энергии, присоединенная мощность которых не превышает 50 МВт',
        ],
      },
      {
        code: '64848042',
        text:
          'В каких пределах должен находиться статизм первичного регулирования для гидротурбин для участия в общем первичном регулировании частоты? Выберите правильный вариант ответа.',
        answers: ['4,5 - 6,0 %', '4,0 - 5,0 %', '4,5 - 6,5 %', '15 - 100 %'],
        correctAnswers: ['4,5 - 6,0 %'],
      },
      {
        code: '64848043',
        text:
          'Какая температура верхних слоев масла должна быть у трансформаторов и реакторов с естественной циркуляцией воздуха и масла при номинальной нагрузке, если документацией организации-изготовителя не определены иные значения температуры? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 97 °C',
          'Не выше 95 °C',
          'Не выше 100 °C',
          'Не выше 115 °C',
        ],
        correctAnswers: ['Не выше 95 °C'],
      },
      {
        code: '64848044',
        text:
          'Для каких выключателей предусматривается резервирование отказа выключателя? Выберите 2 варианта ответа.',
        answers: [
          'Для каждого выключателя напряжением 135 киловольт и выше и выключателей генераторов, установленных на генераторном напряжении 6-10 киловольт и выше',
          'Для каждого выключателя напряжением 110 киловольт и выше',
          'Для каждого выключателя напряжением 220 киловольт и выше и выключателей генераторов, установленных на генераторном напряжении 10 киловольт и выше',
          'Для выключателей генераторов, установленных на генераторном напряжении 6 киловольт и выше',
        ],
        correctAnswers: [
          'Для каждого выключателя напряжением 110 киловольт и выше',
          'Для выключателей генераторов, установленных на генераторном напряжении 6 киловольт и выше',
        ],
      },
      {
        code: '64848045',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для диспетчерского, оперативного и оперативно-ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Еженедельно',
          'Ежемесячно',
          'Не реже 1 раза в 6 месяцев',
          'В зависимости от характера выполняемой работы, но не реже 1 раза в 3 месяца',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64848046',
        text:
          'Что должен пройти командированный персонал по прибытии на место своей командировки для выполнения работ в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Специальную подготовку',
          'Противоаварийную (противопожарную) тренировку',
          'Вводный и первичный инструктажи по охране труда',
          'Проверку знаний норм и правил при работах в электроустановках',
        ],
        correctAnswers: ['Вводный и первичный инструктажи по охране труда'],
      },
      {
        code: '64848047',
        text:
          'С какими категориями персонала проводится подготовка по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'С категориями всего персонала, за исключением административно-технического',
          'Со всеми категориями, за исключением вспомогательного персонала',
          'Только с оперативно-ремонтным и ремонтным персоналом',
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
        correctAnswers: [
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
      },
      {
        code: '64848048',
        text:
          'На какой персонал распространяются требования специальной подготовки? Выберите правильный вариант ответа.',
        answers: [
          'На ремонтный персонал, связанный с техническим обслуживанием энергоустановок',
          'На вновь принимаемых работников из числа административно-технического персонала',
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
          'На специалистов производственных подразделений',
        ],
        correctAnswers: [
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64848049',
        text:
          'Кто устанавливает порядок технологического присоединения энергопринимающих устройств юридических и физических лиц к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти.',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '64848050',
        text:
          'За сколько месяцев владелец объекта электроэнергетики обязан уведомить соответствующий диспетчерский центр о планируемом изменении формы организации оперативного обслуживания объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не менее чем за 3 месяца',
          'Не менее чес за 2 месяца',
          'Не менее чем за 1 месяц',
          'Не менее чем за 10 дней',
        ],
        correctAnswers: ['Не менее чем за 3 месяца'],
      },
      {
        code: '64848051',
        text:
          'С какой скоростью обеспечивается возможность разгрузки ветроэнергетических установок, фотоэлектрических солнечных модулей или их групп, работающих в составе энергосистемы через один преобразователь постоянного тока или одно устройство напряжением 10 кВ и выше, по активной мощности в пределах регулировочного диапазона? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 20% в минуту',
          'Не менее 10% в минуту',
          'Не менее 30% в минуту',
          'Не менее 5% в минуту',
        ],
        correctAnswers: ['Не менее 10% в минуту'],
      },
      {
        code: '64848052',
        text:
          'На какой срок и сколько раз может быть продлен наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Не более 15 календарных дней и не более чем 1 раз',
          'Не более 20 календарных дней и не более чем 2 раза',
          'Не более 25 календарных дней и не более чем 1 раз',
          'Не более 30 календарных дней и не более чем 2 раза',
        ],
        correctAnswers: ['Не более 15 календарных дней и не более чем 1 раз'],
      },
      {
        code: '64848053',
        text:
          'Каким документом должны быть оформлены работы в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только распоряжением',
          'Только перечнем работ, выполняемых в порядке текущей эксплуатации',
          'Только заданием на производство работы, определяющим содержание, место работы, время ее начала и окончания, условия безопасного проведения, состав бригады и работников, ответственных за безопасное выполнение работы',
          'Любым из перечисленных документов',
        ],
        correctAnswers: ['Любым из перечисленных документов'],
      },
      {
        code: '64848054',
        text:
          'В какой срок Ростехнадзор или его территориальный орган, принявшие решение о расследовании причин аварии, уведомляют об этом уполномоченный орган в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 56 часов',
          'Не позднее 48 часов',
          'Не позднее 72 часов',
          'Не позднее 80 часов',
        ],
        correctAnswers: ['Не позднее 48 часов'],
      },
      {
        code: '64848055',
        text:
          'В какие сроки проводится проверка знаний работников, относящихся к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала, при подготовке по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'В сроки, установленные программой подготовки по новой должности',
          'В сроки, установленные председателем комиссии по проверке знаний в организации',
          'Hе позднее одного месяца со вступления в новую должность',
          'Не позднее 14 дней после назначения на должность',
        ],
        correctAnswers: [
          'В сроки, установленные программой подготовки по новой должности',
        ],
      },
      {
        code: '64848056',
        text:
          'Кого уведомляет собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки, либо эксплуатирующая их организация о возникновении аварии? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерский центр субъекта оперативно-диспетчерского управления в электроэнергетике',
          'Орган федерального государственного энергетического надзора',
          'Всех перечисленных',
          'Подведомственное уполномоченному органу в сфере электроэнергетики государственное бюджетное учреждение',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '64848057',
        text:
          'Для каких категорий работников проводится стажировка? Выберите правильный вариант ответа.',
        answers: [
          'Только для диспетчерского персонала',
          'Для всех категорий, за исключением вспомогательного персонала',
          'Для всех категорий персонала',
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
        correctAnswers: [
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64848058',
        text:
          'Какой порядок допуска к самостоятельной работе вновь принятых работников или имевших перерыв в работе более 6 месяцев?  Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от категории персонала, после прохождения этапов подготовки в соответствии с индивидуальной программой',
          'Вне зависимости от категории персонала, после ознакомления со схемами и режимами работы энергоустановок, с нормативно-техническими документами, приказами и распоряжениями',
          'Вне зависимости от категории персонала, после прохождения специальной подготовки, установленных внутренним распорядительным документом инструктажей и стажировки',
          'В зависимости от категории персонала и его квалификации форму подготовки персонала для допуска к самостоятельной работе определяет руководитель структурного подразделения',
        ],
        correctAnswers: [
          'В зависимости от категории персонала, после прохождения этапов подготовки в соответствии с индивидуальной программой',
        ],
      },
      {
        code: '64848059',
        text:
          'На основании чего должны быть установлены нормальные и предельные значения контролируемых параметров владельцем объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'На основе документации по результатам испытания оборудования',
          'На основе документации организации-изготовителя',
          'На основе эксплуатационной документации',
        ],
        correctAnswers: ['На основе документации организации-изготовителя'],
      },
      {
        code: '64848060',
        text:
          'Какие формы работы с административно-техническим персоналом не проводятся? Выберите правильный вариант ответа.',
        answers: [
          'Дублирование и специальная подготовка',
          'Предэкзаменационная подготовка',
          'Проверка знаний',
          'Производственный инструктаж',
        ],
        correctAnswers: ['Дублирование и специальная подготовка'],
      },
      {
        code: '64848061',
        text:
          'Какое количество соединителей допускается на каждом проводе или тросе пересекающей воздушной линии в пролетах пересечения ее с другими воздушными линиями и линиями связи? Выберите правильный вариант ответа.',
        answers: [
          'Не более 3 соединителей',
          'Не более 2 соединителей',
          'Не более 4 соединителей',
          'Не более 5 соединителей',
        ],
        correctAnswers: ['Не более 2 соединителей'],
      },
      {
        code: '64848062',
        text:
          'Кому разрешается работать единолично в электроустановках напряжением до 1000 В, расположенных в помещениях, кроме особо опасных? Выберите правильный вариант ответа.',
        answers: [
          'Работнику, имеющему III группу по электробезопасности и право быть производителем работ',
          'Работать единолично не разрешается',
          'Работнику, имеющему группу II по электробезопасности',
          'Работнику, имеющему группу IV по электробезопасности',
        ],
        correctAnswers: [
          'Работнику, имеющему III группу по электробезопасности и право быть производителем работ',
        ],
      },
      {
        code: '64848063',
        text:
          'Какие организации электроэнергетики должны разработать порядок проведения работы с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Все организации электроэнергетики',
          'Только тепловые электростанции',
          'Только организации, эксплуатирующие трансформаторные подстанции',
          'Только гидроэлектростанции',
        ],
        correctAnswers: ['Все организации электроэнергетики'],
      },
      {
        code: '64848064',
        text:
          'В каком случае требуется оформление разрешения на допуск в эксплуатацию энергоустановки для включения ее в работу при проведении аварийно-восстановительных работ в условиях ликвидации аварийных ситуаций в работе электроэнергетической системы? Выберите правильный вариант ответа.',
        answers: [
          'В любом случае',
          'Ни в каком случае',
          'В случае неготовности допускаемого объекта к эксплуатации по истечении установленного срока с учетом его продления',
        ],
        correctAnswers: ['Ни в каком случае'],
      },
      {
        code: '64848065',
        text:
          'С кем заключают договор собственники объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, предусматривающий право собственников указанных объектов самостоятельно заключать договоры оказания услуг по передаче электрической энергии, в случаях, установленных Правительством Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'С Министерством энергетики Российской Федерации',
          'С организацией по управлению единой национальной (общероссийской) электрической сетью',
          'С органом исполнительной власти соответствующего субъекта Российской Федерации',
          'С администратором торговой системы оптового рынка',
        ],
        correctAnswers: [
          'С организацией по управлению единой национальной (общероссийской) электрической сетью',
        ],
      },
      {
        code: '64848066',
        text:
          'Какие действия персонала не относятся к организационным мероприятиям? Выберите правильный вариант ответа.',
        answers: [
          'Вывешивание запрещающих плакатов на приводах ручного и на ключах дистанционного управления коммутационных аппаратов',
          'Оформление работ нарядом-допуском, распоряжением или перечнем работ, выполняемых в порядке текущей эксплуатации',
          'Выдача разрешения на подготовку рабочего места и на допуск к работе',
          'Оформление перевода на другое место работы',
        ],
        correctAnswers: [
          'Вывешивание запрещающих плакатов на приводах ручного и на ключах дистанционного управления коммутационных аппаратов',
        ],
      },
      {
        code: '64848067',
        text:
          'Когда должна осуществляться подготовка персонала для обслуживания новых и реконструируемых объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'За 3 месяца до пуска объекта в эксплуатацию',
          'С опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
          'За год до пуска объекта в эксплуатацию',
          'В течении месяца после проведения пробных пусков',
        ],
        correctAnswers: [
          'С опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
        ],
      },
      {
        code: '64848068',
        text:
          'Какое определение соответствует понятию «нормальная схема электрических соединений объектов электроэнергетики, входящих в операционную зону диспетчерского центра»? Выберите правильный вариант ответа.',
        answers: [
          'Изображение объектов электроэнергетики энергосистемы и связей между ними, на котором все коммутационные аппараты и линейные (шинные) разъединители объектов электроэнергетики изображаются в положении, соответствующем их принятому нормальному коммутационному положению',
          'Изображение объектов электроэнергетики энергосистемы и связей между ними, на котором все коммутационные аппараты и заземляющие разъединители объектов электроэнергетики изображаются в положении, соответствующем их фактическому коммутационному положению',
          'Изображение объектов электроэнергетики энергосистемы и связей между ними, на котором все коммутационные аппараты и заземляющие разъединители объектов электроэнергетики изображаются в положении, соответствующем их принятому нормальному коммутационному положению',
        ],
        correctAnswers: [
          'Изображение объектов электроэнергетики энергосистемы и связей между ними, на котором все коммутационные аппараты и заземляющие разъединители объектов электроэнергетики изображаются в положении, соответствующем их принятому нормальному коммутационному положению',
        ],
      },
      {
        code: '64848069',
        text:
          'Какое определение соответствует понятию «центр управления объектами распределенной генерации»? Выберите правильный вариант ответа.',
        answers: [
          'Структурное подразделение субъекта электроэнергетики, осуществляющее все или часть функций оперативно- технологического управления в отношении принадлежащих такому субъекту на праве собственности или ином законном основании ветроэнергетических установок, фотоэлектрических солнечных модулей или их групп, непосредственно присоединенных к электроэнергетической системе и осуществляющих выдачу мощности через одно распределительное устройство напряжением 35 киловольт и ниже',
          'Структурное подразделение субъекта электроэнергетики, осуществляющее все или часть функций оперативно-технологического управления в отношении принадлежащих такому субъекту на праве собственности или ином законном основании ветроэнергетических установок, фотоэлектрических солнечных модулей или их групп, непосредственно присоединенных к электроэнергетической системе и осуществляющих выдачу мощности через одно (два) распределительное устройство напряжением 10 киловольт и ниже',
          'Структурное подразделение субъекта электроэнергетики, осуществляющее все функции оперативно-технологического управления в отношении принадлежащих такому субъекту на праве собственности или ином законном основании ветроэнергетических установок, фотоэлектрических солнечных модулей или их групп, непосредственно присоединенных к электроэнергетической системе и осуществляющих выдачу мощности через одно (два) распределительное устройство напряжением 110 киловольт и ниже',
        ],
        correctAnswers: [
          'Структурное подразделение субъекта электроэнергетики, осуществляющее все или часть функций оперативно- технологического управления в отношении принадлежащих такому субъекту на праве собственности или ином законном основании ветроэнергетических установок, фотоэлектрических солнечных модулей или их групп, непосредственно присоединенных к электроэнергетической системе и осуществляющих выдачу мощности через одно распределительное устройство напряжением 35 киловольт и ниже',
        ],
      },
      {
        code: '64848070',
        text:
          'Кто обязан обеспечить готовность к работе насосов откачки воды, поступающей в результате фильтрации или вследствие непредвиденных прорывов из водопроводящих трактов при эксплуатации зданий электростанции? Выберите правильный вариант ответа.',
        answers: [
          'Владелец электростанции',
          'Руководитель сторонней организации',
          'Представитель надзорных органов',
        ],
        correctAnswers: ['Владелец электростанции'],
      },
      {
        code: '64848071',
        text:
          'Какие из перечисленных работ не относятся к специальным, право проведения которых должно быть зафиксировано записью в удостоверении? Выберите правильный вариант ответа.',
        answers: [
          'Работы на высоте',
          'Работы без снятия напряжения с электроустановки, выполняемые с прикосновением к токоведущим частям, находящимся под рабочим напряжением, или на расстоянии от этих токоведущих частей менее допустимого',
          'Работы в устройствах управления, сигнализации, автоматики, защиты и измерений, связанных между собой вторичными цепями (совокупность кабелей и проводов, соединяющих данные устройства)',
          'Испытания оборудования повышенным напряжением',
        ],
        correctAnswers: [
          'Работы в устройствах управления, сигнализации, автоматики, защиты и измерений, связанных между собой вторичными цепями (совокупность кабелей и проводов, соединяющих данные устройства)',
        ],
      },
      {
        code: '64848072',
        text:
          'Для чего в энергосистеме осуществляется регулирование напряжения? Выберите 3 варианта ответа.',
        answers: [
          'Для обеспечения качества электрической энергии в соответствии с обязательными требованиями',
          'Для устойчивости генерирующих оборудования, энергосистем и нагрузки потребителей электрической энергии',
          'Для обеспечения уровней напряжения, допустимых для оборудования электрических станций и электрических сетей',
          'Для обеспечения безопасности поставщика электроэнергии',
          'Для экономии электроэнергии',
        ],
        correctAnswers: [
          'Для обеспечения качества электрической энергии в соответствии с обязательными требованиями',
          'Для устойчивости генерирующих оборудования, энергосистем и нагрузки потребителей электрической энергии',
          'Для обеспечения уровней напряжения, допустимых для оборудования электрических станций и электрических сетей',
        ],
      },
      {
        code: '64848073',
        text:
          'Какие из перечисленных работ в электроустановках напряжением до 1000 В не могут быть отнесены к перечню работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Прокладка и перекладка силовых и контрольных кабелей',
          'Замена предохранителей, ремонт осветительной электропроводки и арматуры',
          'Ремонт отдельных электроприемников, относящихся к инженерному оборудованию зданий и сооружений (электродвигателей, электрокалориферов, вентиляторов, насосов, установок кондиционирования воздуха)',
          'Работы в электроустановках с односторонним питанием',
        ],
        correctAnswers: [
          'Прокладка и перекладка силовых и контрольных кабелей',
        ],
      },
      {
        code: '64848074',
        text:
          'На основании какой документации должны выполняться изменения проектных схем и конструкций оборудования, которые могут влиять на работу водоподготовительных установок и установок для очистки конденсатов, а также на водно-химический режим электростанции (тепловых сетей)? Выберите правильный вариант ответа.',
        answers: [
          'На основании распорядительной документации',
          'На основании проектной документации',
          'На основании ремонтной документации',
          'На основании эксплуатационной документации',
        ],
        correctAnswers: ['На основании проектной документации'],
      },
      {
        code: '64848075',
        text:
          'На кого распространяются Правила по охране труда при эксплуатации электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'Только на работников организаций, занятых техническим обслуживанием электроустановок и выполняющих в них строительные, монтажные и ремонтные работы, в том числе работы с приборами учета электроэнергии и измерительными приборами',
          'На работодателей - юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
          'Только на работников организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих испытания и измерения',
          'Только на работников промышленных предприятий, осуществляющих управление технологическими режимами работы объектов энергопринимающих установок потребителей на балансе которых имеются электроустановки',
        ],
        correctAnswers: [
          'На работодателей - юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
        ],
      },
      {
        code: '64848076',
        text:
          'Расследования каких аварий осуществляют собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация? Выберите правильный вариант ответа.',
        answers: [
          'Только аварий, в результате которых произошли нарушения, приводящие к потере управляемости объекта электроэнергетики (потеря питания собственных нужд, оперативного тока, давления в магистралях сжатого воздуха, систем управления оборудованием) продолжительностью 1 час и более',
          'Только аварий, в результате которых произошли неправильные действия защитных устройств и (или) систем автоматики',
          'Только аварий, в результате которых произошли повреждение основного оборудования электростанции, а также отключение такого оборудования действием автоматических защитных устройств или оперативным персоналом вследствие недопустимых отклонений технологических параметров или ошибочных действий оперативного персонала',
          'Выясняет причины возникновения всех перечисленных аварий',
        ],
        correctAnswers: [
          'Выясняет причины возникновения всех перечисленных аварий',
        ],
      },
      {
        code: '64848077',
        text:
          'В какой срок Ростехнадзор должен завершить расследование причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'В срок, не превышающий 20 рабочих дней',
          'В срок, не превышающий 20 календарных дней',
          'В срок, не превышающий 30 рабочих дней',
          'В срок, не превышающий 30 календарных дней',
        ],
        correctAnswers: ['В срок, не превышающий 20 календарных дней'],
      },
      {
        code: '64848078',
        text:
          'Как оформляется акт расследования причин аварии при несогласии отдельных членов комиссии? Выберите правильный вариант ответа.',
        answers: [
          'Несогласные члены комиссии акт не подписывают',
          'Несогласные члены комиссии подписывают акт с примечанием "не согласен"',
          'Несогласные члены комиссии подписывают акт, а их "особое мнение" прилагается к акту расследования',
          'Несогласные члены комиссии акт не подписывают и направляют "особое мнение" в Управление государственного энергетического надзора России',
        ],
        correctAnswers: [
          'Несогласные члены комиссии подписывают акт, а их "особое мнение" прилагается к акту расследования',
        ],
      },
      {
        code: '64848079',
        text:
          'При какой величине естественного тока замыкания на землю в обмотке статора генераторов и синхронных компенсаторов, работающих на сборные шины, они должны разгружаться и отключаться от сети? Выберите правильный вариант ответа.',
        answers: ['5 А и более', '3 А и более', '4 А и более', 'Более 6 А'],
        correctAnswers: ['5 А и более'],
      },
      {
        code: '64848080',
        text:
          'Кто осуществляет управление государственной собственностью в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Органы местного самоуправления',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Органы законодательной власти',
        ],
        correctAnswers: [
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
        ],
      },
      {
        code: '64848081',
        text:
          'Какое основное условие осуществления параллельной или совместной работы Единой энергетической системы России с энергосистемами иностранных государств? Выберите правильный вариант ответа.',
        answers: [
          'Наличие действующих договоров об организации параллельной или совместной работы',
          'Наличие действующих соглашений об организации параллельной или совместной работы',
          'Наличие действующих положений об организации параллельной или совместной работы',
        ],
        correctAnswers: [
          'Наличие действующих договоров об организации параллельной или совместной работы',
        ],
      },
      {
        code: '64848082',
        text:
          'В какой срок лицо, получившее неудовлетворительную оценку по результатам проверки знаний, должно пройти повторную проверку? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее двух месяцев',
          'Не позднее одного месяца',
          'Не позднее одной недели',
          'Срок проведения повторной проверки устанавливается руководителем организации',
        ],
        correctAnswers: ['Не позднее одного месяца'],
      },
      {
        code: '64848083',
        text:
          'Для выполнения каких действий выдается временное разрешение на допуск в эксплуатацию? Выберите правильный вариант ответа.',
        answers: [
          'Пробных пусков и комплексных испытаний',
          'Комплексного опробования, иных испытаний и пусконаладочных работ',
          'Комплексного опробования и пусконаладочных работ в отношении допускаемых объектов, предусматривающих подключение таких объектов к системе теплоснабжения, подачу тепловой энергии и теплоносителя',
          'Для выполнения всего перечисленного',
        ],
        correctAnswers: ['Для выполнения всего перечисленного'],
      },
      {
        code: '64848084',
        text:
          'В каком случае внеочередная проверка знаний не проводится? Выберите правильный вариант ответа.',
        answers: [
          'При перерыве в работе по данной должности (рабочему месту) более 6 месяцев',
          'На основании предписания, выданного уполномоченным федеральным органом исполнительной власти по результатам осуществления в отношении организации федерального государственного энергетического надзора',
          'При переводе работника на новую должность, в другой филиал (представительство), структурное подразделение в пределах одной организации, если должностные обязанности (трудовые функции) работника по новой должности (рабочему месту) не требуют дополнительных знаний отраслевых актов и инструктивно-технических документов',
          'При вводе в эксплуатацию нового оборудования и изменениях технологических процессов, требующих дополнительных знаний работников',
        ],
        correctAnswers: [
          'При переводе работника на новую должность, в другой филиал (представительство), структурное подразделение в пределах одной организации, если должностные обязанности (трудовые функции) работника по новой должности (рабочему месту) не требуют дополнительных знаний отраслевых актов и инструктивно-технических документов',
        ],
      },
      {
        code: '64848085',
        text:
          'На сколько календарных дней, в случае необходимости, руководитель Ростехнадзора может продлить срок проведения расследования причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Не более чем на 45 дней',
          'Не более чем на 20 дней',
          'Не более чем на 10 дней',
          'Не более чем на 3 дня',
        ],
        correctAnswers: ['Не более чем на 45 дней'],
      },
      {
        code: '64848086',
        text:
          'В какие сроки проводится первичная проверка знаний работников, относящихся к категории административно-технического персонала или вспомогательного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 14 дней после назначения на должность',
          'Не позднее 10 дней после назначения на должность',
          'Не позднее одного месяца после назначения на должность',
          'Срок определяется руководителем по организации за работу с персоналом',
        ],
        correctAnswers: [
          'Не позднее одного месяца после назначения на должность',
        ],
      },
      {
        code: '64848087',
        text:
          'При каком положении затворов водосбосных и водопропускных отверстий допускается превышение нормального подпорного уровня верхних бьефов гидроузлов? Выберите правильный вариант ответа.',
        answers: [
          'При полностью открытых затворах всех водосбросных и водопропускных отверстий',
          'При полностью закрытых затворах всех водосбросных и водопропускных отверстий',
          'Не регламентируется',
          'При затворах открытых на 1/3',
        ],
        correctAnswers: [
          'При полностью открытых затворах всех водосбросных и водопропускных отверстий',
        ],
      },
      {
        code: '64848088',
        text:
          'Кому дано право утверждать технологические характеристики объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, порядок ведения реестра указанных объектов? Выберите правильный вариант ответа.',
        answers: [
          'Министерству энергетики Российской Федерации',
          'ФСК ЕЭС',
          'Администратору торговой системы оптового рынка',
          'Правительству Российской Федерации',
        ],
        correctAnswers: ['Правительству Российской Федерации'],
      },
      {
        code: '64848089',
        text:
          'Как определяется регулировочный диапазон по реактивной мощности для ветровой (ветроэлектрической) электростанции? Выберите правильный вариант ответа.',
        answers: [
          'Определяется для каждой группы ветроэнергетических установок в соответствии с характеристикой регулировочного диапазона по реактивной мощности в функциональной зависимости от текущей активной мощности, предоставляемой собственником генерирующего оборудования',
          'Определяется для каждой группы ветроэнергетических установок по результатам испытаний',
          'Определяется для электростанции в целом в соответствии с характеристикой регулировочного диапазона по реактивной мощности в функциональной зависимости от текущей активной мощности, предоставляемой собственником генерирующего оборудования',
          'Определяется для электростанции в целом по параметрам настройки системы возбуждения генератора',
        ],
        correctAnswers: [
          'Определяется для электростанции в целом в соответствии с характеристикой регулировочного диапазона по реактивной мощности в функциональной зависимости от текущей активной мощности, предоставляемой собственником генерирующего оборудования',
        ],
      },
      {
        code: '64848090',
        text:
          'Какие из перечисленных общесистемных технических характеристик, которые должны быть определены для каждой энергоустановки (энергоблока, единицы генерирующего оборудования) электрической станции, указаны верно? Выберите правильный вариант ответа.',
        answers: [
          'Максимальная располагаемая мощность',
          'Технологический минимум',
          'Регулировочный диапазон по реактивной мощности',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '64848091',
        text:
          'Какую группу по электробезопасности должны иметь работники из числа оперативного персонала, единолично обслуживающие электроустановки напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: ['Группу III', 'Группу II', 'Только IV группу'],
        correctAnswers: ['Группу III'],
      },
      {
        code: '64848092',
        text:
          'Какое требование Правил технической эксплуатации электростанций и сетей Российской Федерации к методам устранения повреждений контрольных кабелей или их наращивания указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'При устранении повреждений контрольных кабелей с металлической оболочкой или их наращивании соединение жил должно осуществляться с установкой герметичных муфт или с помощью предназначенных для этого коробок',
          'Кабели с поливинилхлоридной и резиновой оболочкой должны соединяться с помощью эпоксидных соединительных муфт или на переходных рядах зажимов',
          'На каждые 50 м одного кабеля в среднем должно быть не более одного соединения из эпоксидных соединительных муфт или с помощью предназначенных для этого коробок',
          'На каждые 100 м одного кабеля в среднем должно быть не менее трех соединений из эпоксидных соединительных муфт',
        ],
        correctAnswers: [
          'На каждые 100 м одного кабеля в среднем должно быть не менее трех соединений из эпоксидных соединительных муфт',
        ],
      },
      {
        code: '64848093',
        text:
          'Какое определение соответствует термину "дублирование"? Выберите правильный вариант ответа.',
        answers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
          'Практическое освоение непосредственно на рабочем месте навыков выполнения работы или группы работ, приобретенных при профессиональной подготовке',
          'Форма поддержания квалификации работника путем его систематической тренировки в управлении производственными процессами на учебно-тренировочных средствах, формирования его знаний, умения и навыков, проработки организационно-распорядительных документов и разборки технологических нарушений, пожаров и случаев производственного травматизма',
          'Одна из форм дополнительного повышения образовательного уровня персонала, осуществляемая путем систематического самообразования, проведения краткосрочного и длительного периодического обучения в соответствующих образовательных учреждениях',
        ],
        correctAnswers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
        ],
      },
      {
        code: '64848094',
        text:
          'Какие требования предъявляются к командированному персоналу? Выберите правильный вариант ответа.',
        answers: [
          'Все перечисленные требования',
          'Только прохождение вводного и первичного инструктажа по охране труда по прибытии на место командировки',
          'Только ознакомление с электрической схемой и особенностями электроустановки, в которой предстоит работать',
          'Только наличие удостоверения о проверке знаний норм и правил работы в электроустановках с отметкой о группе электробезопасности',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '64848095',
        text:
          'В каком случае допускается включать в состав бригады, выполняющей работы по наряду-допуску, работников, имеющих II группу по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'В случае, если общее число членов бригады, имеющих группу II по электробезопасности, не должно превышать трех',
          'В любом случае',
          'Ни в каком случае',
        ],
        correctAnswers: [
          'В случае, если общее число членов бригады, имеющих группу II по электробезопасности, не должно превышать трех',
        ],
      },
      {
        code: '64848096',
        text:
          'Какие мероприятия выполняются, если в процессе подготовки рабочего места по наряду-допуску возникают сомнения в достаточности и правильности мер по подготовке рабочего места и возможности безопасного выполнения работ? Выберите правильный вариант ответа.',
        answers: [
          'Подготовка должна быть прекращена, в наряд-допуск должны быть внесены необходимые дополнения',
          'Подготовка должна быть приостановлена, повторно должен быть проведен целевой инструктаж с указанием мер безопасности',
          'Подготовка должна быть приостановлена, лица, ответственные за безопасность работы, должны выполнить необходимые технические мероприятия, устраняющие возникшие сомнения, после чего подготовку допускается продолжить',
          'Подготовка должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
        ],
        correctAnswers: [
          'Подготовка должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
        ],
      },
      {
        code: '64848097',
        text:
          'В какой срок после дня получения запроса уполномоченного органа в сфере электроэнергетики собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация направляют копии акта расследования уполномоченному органу в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'В 10-дневный срок',
          'В 3-дневный срок',
          'В 1-дневный срок',
          'В 5-дневный срок',
        ],
        correctAnswers: ['В 10-дневный срок'],
      },
      {
        code: '64848098',
        text:
          'Какие мероприятия обязательно осуществляются перед допуском к проведению неотложных работ? Выберите правильный вариант ответа.',
        answers: [
          'Проверка количественного и качественного состава бригады',
          'Оформление работ нарядом-допуском, распоряжением или перечнем работ, выполняемых в порядке текущей эксплуатации',
          'Технические мероприятия по подготовке рабочего места',
          'Проведение внепланового инструктажа',
        ],
        correctAnswers: [
          'Технические мероприятия по подготовке рабочего места',
        ],
      },
      {
        code: '64848099',
        text:
          'В каком случае допускается присоединение энергопринимающих установок посторонних потребителей к шинам распределительных устройств собственных нужд электростанции? Выберите правильный вариант ответа.',
        answers: [
          'В случае отсутствия в соответствующей местности распределительной электрической сети напряжением 20 киловольт и ниже',
          'Ни в каком случае',
          'В случае присоединение объектов электроэнергетики, энергопринимающих установок ответвлениями (отпайками) к линиям электропередачи классом напряжения 330 киловольт и выше',
        ],
        correctAnswers: [
          'В случае отсутствия в соответствующей местности распределительной электрической сети напряжением 20 киловольт и ниже',
        ],
      },
      {
        code: '64848100',
        text:
          'В соответствии с каким документом осуществляется распределение объектов диспетчеризации по способу управления (ведения)? Выберите правильный вариант ответа.',
        answers: [
          'В соответствии с Правилами технологического функционирования электроэнергетических систем',
          'В соответствии с Правилами проведения испытаний и определения общесистемных технических параметров и характеристик генерирующего оборудования и о внесении изменений в Правила технической эксплуатации электрических станций и сетей Российской Федерации',
          'В соответствии с Правилами оперативно-диспетчерского управления в электроэнергетике',
        ],
        correctAnswers: [
          'В соответствии с Правилами технологического функционирования электроэнергетических систем',
        ],
      },
      {
        code: '64848101',
        text:
          'С какой периодичностью должно проводиться дополнительное профессиональное образование работников, относящихся к категориям административно-технического, диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 2 года',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '64848102',
        text:
          'Что из перечисленного не входит в технологическую основу функционирования электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Единая национальная (общероссийская) электрическая сеть',
          'Территориальные распределительные сети',
          'Система отношений, связанных с производством и оборотом электроэнергии на оптовом рынк',
          'Единая система оперативно-диспетчерского управления',
        ],
        correctAnswers: [
          'Единая национальная (общероссийская) электрическая сеть',
        ],
      },
      {
        code: '64848103',
        text:
          'Когда работники должны проходить обучение по оказанию первой помощи пострадавшему на производстве? Выберите правильный вариант ответа.',
        answers: [
          'В течение 3 дней после допуска к самостоятельной работе',
          'В течение первой недели после допуска к самостоятельной работе',
          'До допуска к самостоятельной работе',
          'Непосредственно сразу после допуска к самостоятельной работе',
        ],
        correctAnswers: ['До допуска к самостоятельной работе'],
      },
      {
        code: '64848104',
        text:
          'Что из перечисленного не относится к этапу по определению общесистемных технических параметров генерирующего оборудования при проведении комплексных испытаний генерирующего оборудования? Выберите правильный вариант ответа.',
        answers: [
          'Комплексное опробование генерирующего оборудования',
          'Работа с максимально возможной мощностью',
          'Определение нижнего предела регулировочного диапазона по активной мощности',
          'Определение скоростей набора и снижения нагрузки в пределах регулировочного диапазона по активной мощности',
          'Проведение автоматических пусков',
        ],
        correctAnswers: ['Проведение автоматических пусков'],
      },
      {
        code: '64848105',
        text:
          'Какие государственные органы имеют право принимать нормативные правовые акты в области государственного регулирования отношений в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации и уполномоченные им федеральные органы исполнительной власти',
          'Только федеральные органы исполнительной власти',
          'Органы государственной власти субъектов Российской Федерации',
          'Органы местного самоуправления',
        ],
        correctAnswers: [
          'Правительство Российской Федерации и уполномоченные им федеральные органы исполнительной власти',
        ],
      },
      {
        code: '64848106',
        text:
          'Каким образом должен выполняться капитальный ремонт электрооборудования напряжением выше 1000 В? Выберите 2 варианта ответа.',
        answers: [
          'По технологическим картам',
          'По техническому заданию',
          'По техническому проекту и плану работ',
          'По проекту производства работ',
        ],
        correctAnswers: [
          'По технологическим картам',
          'По проекту производства работ',
        ],
      },
      {
        code: '64848107',
        text:
          'Что понимается под аварией на объекте электроэнергетики и (или) энергопринимающей установке? Выберите правильный вариант ответа.',
        answers: [
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федерального закона "О промышленной безопасности опасных производственных объектов", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ',
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ; отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федеральных законов "Об электроэнергетике" и "О теплоснабжении", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
        ],
        correctAnswers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
        ],
      },
      {
        code: '64848108',
        text:
          'В какие сроки должна производиться проверка исправности аварийного освещения при отключении рабочего освещения на электростанциях, подстанциях и диспетчерских пунктах? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в месяц',
          'При вводе в эксплуатацию и в дальнейшем один раз в год',
          'Два раза в год',
          'Один раз в год',
        ],
        correctAnswers: ['Два раза в год'],
      },
      {
        code: '64848109',
        text:
          'Какие формы работы с ремонтным персоналом должны использоваться? Выберите правильный вариант ответа.',
        answers: [
          'Только стажировка (включая обучение безопасным методам и приемам выполнения работ)',
          'Только производственный инструктаж и подготовка по новой должности (рабочему месту)',
          'Только предэкзаменационная подготовка и проверка знаний',
          'Все перечисленные формы работы',
        ],
        correctAnswers: ['Все перечисленные формы работы'],
      },
      {
        code: '64848110',
        text:
          'Кем устанавливается порядок технологического присоединения энергопринимающих устройств и объектов электроэнергетики юридических лиц и физических лиц к электрическим сетям? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Правительство Российской Федерации'],
      },
      {
        code: '64848111',
        text:
          'Выполнение каких функций противоаварийного управления обеспечивает противоаварийная автоматика, применяемая в энергосистеме? Выберите 3 варианта ответа.',
        answers: [
          'Предотвращение нарушения устойчивости',
          'Ликвидация асинхронных режимов',
          'Ограничение снижения или повышения частоты и напряжения',
          'Предотвращения нарушения статической устойчивости',
          'Предотвращения нарушения динамической устойчивости',
        ],
        correctAnswers: [
          'Предотвращение нарушения устойчивости',
          'Ликвидация асинхронных режимов',
          'Ограничение снижения или повышения частоты и напряжения',
        ],
      },
      {
        code: '64848112',
        text:
          'В каких случаях проводится первичная проверка знаний работников организаций электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'При перерыве в работе более 3 лет',
          'При поступлении на работу впервые',
          'При перерыве в работе более 2 лет',
          'При переводе в другое структурное подразделение',
        ],
        correctAnswers: [
          'При перерыве в работе более 3 лет',
          'При поступлении на работу впервые',
        ],
      },
      {
        code: '64848113',
        text:
          'Какой персонал должен проходить дублирование? Выберите правильный вариант ответа.',
        answers: [
          'Специалисты, связанные с наладкой и испытанием энергоустановок, после прохождения специальной подготовки',
          'Лица, непосредственно связанные с ремонтом и техническим обслуживанием энергетического оборудования, после первичной проверки знаний',
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
        correctAnswers: [
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64848114',
        text:
          'В каких пределах должен находиться статизм первичного регулирования для участия в общем первичном регулировании частоты генерирующего оборудования солнечной электростанции, ветровой (ветроэлектрической) электростанции, волновой электростанции? Выберите правильный вариант ответа.',
        answers: [
          '4,5 - 5,5 %',
          '4,0 - 5,0 %',
          '5, 6 - 10,0 %',
          '10,0 - 15,5 %',
        ],
        correctAnswers: ['4,0 - 5,0 %'],
      },
      {
        code: '64848115',
        text:
          'Кто вправе рассматривать жалобы поставщиков и покупателей электрической и тепловой энергии о нарушениях их прав и законных интересов действиями (бездействием) иных субъектов электроэнергетики, а также запрашивать информацию, документы и иные доказательства, свидетельствующие о наличии признаков таких нарушений? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: ['Федеральные органы исполнительной власти'],
      },
      {
        code: '64848116',
        text:
          'Какое напряжение переносных светильников допускается при работе внутри трансформатора? Выберите правильный вариант ответа.',
        answers: [
          'Не более 12 В',
          'Не более 42 В',
          'Не более 50 В',
          'Не более 36 В',
        ],
        correctAnswers: ['Не более 12 В'],
      },
    ],
    64845: [
      {
        code: '64845000',
        text:
          'Куда вносятся все изменения технических параметров и характеристик линий электропередачи, оборудования, комплексов релейной защиты и автоматики и устройств релейной защиты и автоматики, средств диспетчерского и технологического управления, выполненные в процессе эксплуатации объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Только в паспорта',
          'Только в инструкции',
          'Только в схемы и чертежи',
          'Во все перечисленное',
        ],
        correctAnswers: ['Во все перечисленное'],
      },
      {
        code: '64845001',
        text:
          'Кем определяется класс энергетической эффективности товара в соответствии с правилами, которые утверждаются уполномоченным федеральным органом исполнительной власти и принципы которых устанавливаются Правительством Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'Федеральными органами исполнительной власти',
          'Органами исполнительной власти субъекта Российской Федерации',
          'Производителем, импортером',
          'Министерством промышленности и торговли',
        ],
        correctAnswers: ['Производителем, импортером'],
      },
      {
        code: '64845002',
        text:
          'Какая периодичность осмотра оборудования распределительных устройств без отключения от сети указана неверно? Выберите правильный вариант ответа.',
        answers: [
          'На объектах с постоянным дежурством персонала - не реже одного раза в неделю',
          'На объектах без постоянного дежурного персонала не реже одного раза в месяц',
          'В темное время суток для выявления разрядов, коронирования - не реже одного раза в месяц',
          'В трансформаторных и распределительных пунктах - не реже одного раза в 6 месяцев',
        ],
        correctAnswers: [
          'На объектах с постоянным дежурством персонала - не реже одного раза в неделю',
        ],
      },
      {
        code: '64845003',
        text:
          'Кто является ответственным за безопасное ведение работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, ответственный руководитель работ',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий',
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
        correctAnswers: [
          'Выдающий наряд, отдающий распоряжение, утверждающий перечень работ, выполняемых в порядке текущей эксплуатации, выдающий разрешение на подготовку рабочего места и на допуск, ответственный руководитель работ, допускающий, производитель работ, наблюдающий, члены бригады',
        ],
      },
      {
        code: '64845004',
        text:
          'Кому разрешается работать единолично в электроустановках напряжением до 1000 В, расположенных в помещениях, кроме особо опасных? Выберите правильный вариант ответа.',
        answers: [
          'Работнику имеющему III группу по электробезопасности и право быть производителем работ',
          'Работнику имеющему IV группу по электробезопасности',
          'Работнику имеющему II группу по электробезопасности',
        ],
        correctAnswers: [
          'Работнику имеющему III группу по электробезопасности и право быть производителем работ',
        ],
      },
      {
        code: '64845005',
        text:
          'Кто в соответствии с Федеральным законом от 27.07.2010 № 190-ФЗ "О теплоснабжении" является потребителем тепловой энергии? Выберите правильный вариант ответа.',
        answers: [
          'Лицо, приобретающее тепловую энергию (мощность), теплоноситель для использования на принадлежащих ему на праве собственности или ином законном основании теплопотребляющих установках либо для оказания коммунальных услуг в части горячего водоснабжения и отопления',
          'Лицо, осуществляющее деятельность в сфере оказания коммунальных услуг в части отопления производственных мощностей',
          'Юридические лица, получившие в установленном Федеральным законом порядке право участвовать в отношениях, связанных с обращением тепловой энергии на рынке',
        ],
        correctAnswers: [
          'Лицо, приобретающее тепловую энергию (мощность), теплоноситель для использования на принадлежащих ему на праве собственности или ином законном основании теплопотребляющих установках либо для оказания коммунальных услуг в части горячего водоснабжения и отопления',
        ],
      },
      {
        code: '64845006',
        text:
          'На кого распространяются Правила по охране труда при эксплуатации электроустановок? Выберите правильный вариант ответа.',
        answers: [
          'Только на работников организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих испытания и измерения',
          'На работодателей - юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
          'Только на работников организаций, занятых техническим обслуживанием  электроустановок и выполняющих в них строительные, монтажные и ремонтные работы',
          'Только на работников промышленных предприятий, на балансе которых имеются электроустановки',
        ],
        correctAnswers: [
          'На работодателей - юридических и физических лиц независимо от их организационно-правовых форм и работников из числа электротехнического, электротехнологического и неэлектротехнического персонала организаций, занятых техническим обслуживанием электроустановок, проводящих в них оперативные переключения, организующих и выполняющих строительные, монтажные, наладочные, ремонтные работы, испытания и измерения, а также осуществляющих управление технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей',
        ],
      },
      {
        code: '64845007',
        text:
          'Кто должен утверждать планы ремонта гидротехнических сооружений? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель ремонтных работ субъекта электроэнергетики',
          'Технический руководитель субъекта электроэнергетики',
          'Техническая комиссия субъекта электроэнергетики',
          'Руководитель субъекта электроэнергетики',
        ],
        correctAnswers: ['Технический руководитель субъекта электроэнергетики'],
      },
      {
        code: '64845008',
        text:
          'В какой срок Ростехнадзор должен завершить расследование причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'В срок, не превышающий 20 дней со дня начала расследования',
          'В срок, не превышающий 10 дней со дня начала расследования',
          'В срок, не превышающий 20 дней с момента аварии',
          'В срок, не превышающий 30 дней с момента получения информации об аварии',
          'В срок, не превышающий 10 дней с момента получения информации об аварии',
        ],
        correctAnswers: [
          'В срок, не превышающий 20 дней со дня начала расследования',
        ],
      },
      {
        code: '64845009',
        text:
          'В какие сроки проводится первичная проверка знаний работников, относящихся к категории административно-технического персонала или вспомогательного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Срок определяется ответственным за работу с персоналом организации',
          'Не позднее 14 дней',
          'Не позднее одного месяца после назначения на должность',
          'Не позднее 10 дней',
        ],
        correctAnswers: [
          'Не позднее одного месяца после назначения на должность',
        ],
      },
      {
        code: '64845010',
        text:
          'Какие организации электроэнергетики должны разработать порядок проведения работы с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Все организации электроэнергетики',
          'Только тепловые электростанции',
          'Только организации потребители электрической энергии',
          'Только организации распределяющие электрическую энергию',
        ],
        correctAnswers: ['Все организации электроэнергетики'],
      },
      {
        code: '64845011',
        text:
          'Что из перечисленного не относится к электрозащитным средствам? Выберите правильный вариант ответа.',
        answers: [
          'Изолирующие штанги всех видов',
          'Изолирующие клещи',
          'Указатели напряжения',
          'Комплекты индивидуальные экранирующие',
        ],
        correctAnswers: ['Комплекты индивидуальные экранирующие'],
      },
      {
        code: '64845012',
        text:
          'Какие действия не обязан выполнять оперативный персонал при переключении в электроустановках без бланка (типового бланка) переключений? Выберите правильный вариант ответа.',
        answers: [
          'Зачитывать операции непосредственно перед выполнением переключения',
          'Убеждаться в правильности выбранного присоединения, аппарата, устройства, ключа управления, накладки, испытательного блока, привода',
          'Фиксировать выполненную операцию в оперативном журнале',
          'Обязан выполнять все перечисленное',
        ],
        correctAnswers: [
          'Зачитывать операции непосредственно перед выполнением переключения',
        ],
      },
      {
        code: '64845013',
        text:
          'На основании чего должны быть установлены нормальные и предельные значения контролируемых параметров владельцем объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Правил технической эксплуатации электрических станций и сетей Российской Федерации',
          'Производственных инструкций, согласованных с Ростехнадзором',
          'Производственных инструкций, разработанных в соответствии с нормативной документацией',
          'Документации организации-изготовителя',
        ],
        correctAnswers: ['Документации организации-изготовителя'],
      },
      {
        code: '64845014',
        text:
          'Кто осуществляет определение системы мер по обеспечению надежности систем теплоснабжения поселений, городских округов в соответствии с правилами организации теплоснабжения, утвержденными Правительством Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'Правительство Российской Федерации',
          'Правительство Российской Федерации или уполномоченные им федеральные органы исполнительной власти',
          'Федеральные органы исполнительной власти',
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
        correctAnswers: [
          'Органы исполнительной власти субъектов Российской Федерации',
        ],
      },
      {
        code: '64845015',
        text:
          'На какой срок должны сформировать и утвердить годовые и перспективные графики технического освидетельствования владельцы объектов электроэнергетики с целью обеспечения проведения технического освидетельствования? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 5 лет',
          'Не менее 3 лет',
          'Не менее 2 лет',
          'Не менее 1 года',
        ],
        correctAnswers: ['Не менее 5 лет'],
      },
      {
        code: '64845016',
        text:
          'Для выполнения каких действий выдается временное разрешение на допуск в эксплуатацию? Выберите правильный вариант ответа.',
        answers: [
          'Только пробных пусков и комплексных испытаний, включая комплексное опробование, генерирующего оборудования объектов по производству электрической энергии (в том числе функционирующих в режиме комбинированной выработки электрической и тепловой энергии)',
          'Только комплексного опробования, иных испытаний и пусконаладочных работ в отношении допускаемых объектов, предусматривающих включение таких объектов в работу в составе энергосистемы, подачу на них рабочего напряжения и мощности (постановку их под нагрузку) и (или) поочередное (в соответствии с графиком строительно-монтажных и пусконаладочных работ) включение в работу в составе энергосистемы отдельного оборудования допускаемых объектов, если в соответствии с проектной документацией или техническими условиями на технологическое присоединение определена необходимость такого поочередного включения до завершения в полном объеме мероприятий, предусмотренных для этапа (очереди, пускового комплекса) строительства (реконструкции) или этапа технологического присоединения',
          'Только комплексного опробования и пусконаладочных работ в отношении допускаемых объектов, предусматривающих подключение таких объектов к системе теплоснабжения, подачу тепловой энергии и теплоносителя',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '64845017',
        text:
          'Что подразумевается под характеристиками, отражающими отношение полезного эффекта от использования энергетических ресурсов к затратам энергетических ресурсов, произведенным в целях получения такого эффекта, применительно к продукции, технологическому процессу, юридическому лицу, индивидуальному предпринимателю? Выберите правильный вариант ответа.',
        answers: [
          'Энергетическая эффективность',
          'Вторичный энергетический ресурс',
          'Класс энергетической эффективности',
          'Энергетический ресурс',
          'Энергосбережение',
        ],
        correctAnswers: ['Энергетическая эффективность'],
      },
      {
        code: '64845018',
        text:
          'Какие отключения генерирующего оборудования, приводящие к снижению надежности энергосистемы, не расследуются Ростехнадзором либо его территориальным органом? Выберите правильный вариант ответа.',
        answers: [
          'Разделение энергосистемы на части, выделение отдельных энергорайонов Российской Федерации на изолированную от Единой энергетической системы России работу (при отключении всех электрических связей с Единой энергетической системой России)',
          'Внеплановое ограничение выдачи мощности электростанцией на срок более 1 суток на величину 100 МВт и более',
          'Применение графиков временных отключений суммарным объемом 50 МВт или прекращение электроснабжения на величину 15 % общего объема потребления в операционной зоне диспетчерского центра',
          'Применение графиков временных отключений суммарным объемом 100 МВт и более или прекращение электроснабжения на величину 25 % и более общего объема потребления в операционной зоне диспетчерского центра',
        ],
        correctAnswers: [
          'Применение графиков временных отключений суммарным объемом 50 МВт или прекращение электроснабжения на величину 15 % общего объема потребления в операционной зоне диспетчерского центра',
        ],
      },
      {
        code: '64845019',
        text:
          'Каким образом передаются разрешение на подготовку рабочего места и допуск к работе работнику, выполняющему подготовку рабочего места и допуск бригады к работе? Выберите правильный вариант ответа.',
        answers: [
          'Лично, по телефону, радио, с нарочным или через оперативный персонал промежуточной подстанции',
          'Только лично или через оперативный персонал промежуточной подстанции',
          'Только лично или с нарочным',
          'Только лично',
          'Только лично или по телефону',
        ],
        correctAnswers: [
          'Лично, по телефону, радио, с нарочным или через оперативный персонал промежуточной подстанции',
        ],
      },
      {
        code: '64845020',
        text:
          'Какой документации должны соответствовать номенклатура и количество машин, механизмов, оборудования, технологической оснастки и средств малой механизации, диагностирования и контроля организации - исполнителя ремонта? Выберите правильный вариант ответа.',
        answers: [
          'Проектной документации',
          'Ремонтной документации',
          'Местной инструкции',
          'Инструкции по предотвращению развития и ликвидации нарушений нормального режима электрической части энергосистем и объектов электроэнергетики',
        ],
        correctAnswers: ['Ремонтной документации'],
      },
      {
        code: '64845021',
        text:
          'Кто из уполномоченных представителей не может быть включен при необходимости в состав комиссии по расследованию причин аварии в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Потребитель электрической энергии, присоединенная мощность которого составляет 30 МВт',
          'Должностное лицо органа федерального государственного энергетического надзора',
          'Представитель генерирующей компании',
          'Представитель уполномоченного органа в сфере электроэнергетики',
        ],
        correctAnswers: [
          'Потребитель электрической энергии, присоединенная мощность которого составляет 30 МВт',
        ],
      },
      {
        code: '64845022',
        text:
          'Кого должен информировать о ходе ликвидации нарушений нормального режима электрической части энергосистем и объектов электроэнергетики диспетчерский и оперативный персонал? Выберите правильный вариант ответа.',
        answers: [
          'Вышестоящий диспетчерский и оперативный персонал',
          'Территориальный орган Ростехнадзора',
          'Территориальное управление МЧС России',
          'Органы местного самоуправления',
        ],
        correctAnswers: ['Вышестоящий диспетчерский и оперативный персонал'],
      },
      {
        code: '64845023',
        text:
          'Каким образом разрешается выполнять проверку отключенного положения коммутационного аппарата в случае отсутствия видимого разрыва в комплектных распределительных устройствах заводского изготовления с выкатными элементами? Выберите правильный вариант ответа.',
        answers: [
          'По состоянию ламп сигнализации',
          'По амперметру, установленному на ячейке',
          'По механическому указателю гарантированного положения контактов',
          'Механической кнопкой отключения в приводе выключателя',
        ],
        correctAnswers: [
          'По механическому указателю гарантированного положения контактов',
        ],
      },
      {
        code: '64845024',
        text:
          'Каким образом оформляется решение о расследовании причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
          'Приказом организации, эксплуатирующей данный участок электроэнергетики, на котором произошла авария',
          'Протоколом должностного лица органа федерального государственного энергетического надзора',
          'Решением уполномоченного органа в сфере электроэнергетики',
        ],
        correctAnswers: [
          'Приказом руководителя органа федерального государственного энергетического надзора (его заместителя)',
        ],
      },
      {
        code: '64845025',
        text:
          'Что из перечисленного не должен делать производитель работ (наблюдающий) после полного окончания работы на электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Удалить бригаду с рабочего места',
          'Снять установленные бригадой временные ограждения',
          'Закрыть двери электроустановки на замок',
          'Оставить установленные заземления и вывесить на них предупредительные плакаты',
          'Снять переносные плакаты безопасности, флажки',
          'Оформить в наряде полное окончание работ своей подписью',
        ],
        correctAnswers: [
          'Оставить установленные заземления и вывесить на них предупредительные плакаты',
        ],
      },
      {
        code: '64845026',
        text:
          'Что должен сделать производитель работ или наблюдающий при необходимости временного ухода с рабочего места, если его не могут заменить ответственный руководитель работ, допускающий или работник, имеющий право выдачи нарядов-допусков? Выберите правильный вариант ответа.',
        answers: [
          'Удалить бригаду с места работы (вывести бригаду из РУ, закрыть входные двери на замок, организовать спуск членов бригады с опор ВЛ)',
          'Предупредить бригаду о своем уходе с места работы',
          'Передать наряд одному из членов бригады',
          'Приостановить работу',
        ],
        correctAnswers: [
          'Удалить бригаду с места работы (вывести бригаду из РУ, закрыть входные двери на замок, организовать спуск членов бригады с опор ВЛ)',
        ],
      },
      {
        code: '64845027',
        text:
          'В какой срок комиссия по расследованию причин аварии уведомляет субъект электроэнергетики и (или) потребителя электрической энергии о начале обследования? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее чем за 1 час',
          'Не позднее чем за 3 часа',
          'Не позднее чем за 12 часов',
          'Не позднее чем за 24 часа',
        ],
        correctAnswers: ['Не позднее чем за 3 часа'],
      },
      {
        code: '64845028',
        text:
          'Какие из перечисленных работ допускается осуществлять юридическим и физическим лицам в пределах охранных зон при наличии письменного согласования владельцев объектов? Выберите правильный вариант ответа.',
        answers: [
          'Убирать, перемещать, засыпать и повреждать предупреждающие знаки',
          'Производить сбор и слив едких и коррозионных веществ, в том числе растворов кислот, щелочей и солей, а также горюче-смазочных материалов',
          'Складировать любые материалы, в том числе взрывоопасные, пожароопасные и горюче-смазочные',
          'Осуществлять горные, взрывные, мелиоративные работы, в том числе связанные с временным затоплением земель',
        ],
        correctAnswers: [
          'Осуществлять горные, взрывные, мелиоративные работы, в том числе связанные с временным затоплением земель',
        ],
      },
      {
        code: '64845029',
        text:
          'Каким образом должны регистрироваться все оперативные переговоры диспетчерского и оперативного персонала при ликвидации нарушений нормального режима электрической части энергосистем и объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Электронными средствами фиксации переговоров',
          'Переговоры не должны регистрироваться',
          'Вручную в оперативном журнале',
        ],
        correctAnswers: ['Электронными средствами фиксации переговоров'],
      },
      {
        code: '64845030',
        text:
          'Какое требование безопасности при работе под напряжением в электроустановках напряжением до 1000 В указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Применять изолированный или изолирующий инструмент, предназначенный для работ под напряжением на токоведущих частях, и пользоваться диэлектрическими перчатками',
          'Работать в одежде с короткими или засученными рукавами  в условиях повышенной температуры',
          'Оградить расположенные вблизи рабочего места другие токоведущие части, находящиеся под напряжением, к которым возможно случайное прикосновение',
          'Работать в диэлектрических галошах или стоя на изолирующей подставке либо на резиновом диэлектрическом ковре',
        ],
        correctAnswers: [
          'Работать в одежде с короткими или засученными рукавами  в условиях повышенной температуры',
        ],
      },
      {
        code: '64845031',
        text:
          'С кем должны согласовываться изменения конструкции машины и (или) оборудования, возникающие при их ремонте? Выберите правильный вариант ответа.',
        answers: [
          'С разработчиком (проектировщиком)',
          'С технической комиссией',
          'С Ростехнадзором',
          'С научно-исследовательской организацией',
        ],
        correctAnswers: ['С разработчиком (проектировщиком)'],
      },
      {
        code: '64845032',
        text:
          'Какие допускаемые значения отклонений температуры сетевой воды в подающих трубопроводах от заданных теплового и гидравлического режимов? Выберите правильный вариант ответа.',
        answers: [
          'Не более ±6 %',
          'Не более ±3 %',
          'Не более ±4 %',
          'Не более ±5 %',
        ],
        correctAnswers: ['Не более ±3 %'],
      },
      {
        code: '64845033',
        text:
          'В какой срок заявитель - собственник объекта по производству электрической энергии (мощности) должен представить документы, необходимые для рассмотрения заявления о выводе из эксплуатации объекта диспетчеризации, в уполномоченный орган? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 12 месяцев с даты направления уполномоченным органом уведомления о необходимости представления документов',
          'Не позднее 6 месяцев с даты направления уполномоченным органом уведомления о необходимости представления документов',
          'Не позднее 18 месяцев с даты направления уполномоченным органом уведомления о необходимости представления документов',
          'Не позднее 3 месяцев с даты направления уполномоченным органом уведомления о необходимости представления документов',
        ],
        correctAnswers: [
          'Не позднее 12 месяцев с даты направления уполномоченным органом уведомления о необходимости представления документов',
        ],
      },
      {
        code: '64845034',
        text:
          'Каким образом должен выполняться капитальный ремонт электрооборудования напряжением выше 1000 В? Выберите 2 варианта ответа.',
        answers: [
          'По технологическим картам',
          'По техническому проекту',
          'По проекту производства работ',
          'По техническому заданию',
        ],
        correctAnswers: [
          'По технологическим картам',
          'По проекту производства работ',
        ],
      },
      {
        code: '64845035',
        text:
          'Кем осуществляется оценка соответствия зданий и сооружений, а также связанных со зданиями и с сооружениями процессов эксплуатации в форме эксплуатационного контроля? Выберите правильный вариант ответа.',
        answers: [
          'Уполномоченными федеральными органами исполнительной власти, органами исполнительной власти субъектов Российской Федерации в случаях',
          'Лицом, ответственным за эксплуатацию здания или сооружения',
          'Технической комиссией, формируемой из членов регионального объединения строителей',
          'Органами местного самоуправления',
        ],
        correctAnswers: [
          'Лицом, ответственным за эксплуатацию здания или сооружения',
        ],
      },
      {
        code: '64845036',
        text:
          'Формирование и утверждение каких из перечисленных графиков ремонта осуществляет субъект оперативно-диспетчерского управления? Выберите правильный вариант ответа.',
        answers: [
          'Только графика ремонта энергетического оборудования электростанций',
          'Только графика ремонта линий электропередачи и электросетевого оборудования',
          'Только графика технического обслуживания устройств релейной защиты и автоматики',
          'Только графика технического обслуживания средств диспетчерского и технологического управления',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '64845037',
        text:
          'Какие требования предъявляются к командированному персоналу? Выберите правильный вариант ответа.',
        answers: [
          'По прибытии на место командировки должен пройти вводный и первичный инструктажи по охране труда',
          'Должен быть ознакомлен с электрической схемой и особенностями электроустановки, в которой предстоит работать',
          'Должен иметь удостоверения о проверке знаний норм и правил работы в электроустановках с отметкой о группе электробезопасности, по прибытии на место командировки должен пройти вводный и первичный инструктажи по охране труда',
          'Все перечисленные требования',
        ],
        correctAnswers: ['Все перечисленные требования'],
      },
      {
        code: '64845038',
        text:
          'Какое требование Правил технической эксплуатации электростанций и сетей Российской Федерации к методам устранения повреждений контрольных кабелей или их наращивания указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Соединение жил кабеля с металлической оболочкой должно осуществляться с установкой герметичных муфт или с помощью предназначенных для этого коробок',
          'Муфты и коробки должны быть зарегистрированы',
          'Кабели с поливинилхлоридной и резиновой оболочкой должны соединяться, как правило, с помощью эпоксидных соединительных муфт или на переходных рядах зажимов',
          'На каждые 50 м одного кабеля в среднем должно быть не более 2-х соединений',
        ],
        correctAnswers: [
          'На каждые 50 м одного кабеля в среднем должно быть не более 2-х соединений',
        ],
      },
      {
        code: '64845039',
        text:
          'Что из перечисленного должен в обязательном порядке делать допускающий перед допуском к работе на электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только провести целевой инструктаж при работах по наряду ответственному руководителю работ, производителю работ (наблюдающему) и членам бригады',
          'Только доказать бригаде, что напряжение отсутствует, демонстрацией установленных заземлений или проверкой отсутствия напряжения, если заземления не видны с рабочего места',
          'Только проверить соответствие состава бригады составу, указанному в наряде или распоряжении, по именным удостоверениям членов бригады',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64845040',
        text:
          'Куда должна быть подана диспетчерская заявка при необходимости изменения технологического режима работы или эксплуатационного состояния объекта диспетчеризации его владельцем? Выберите правильный вариант ответа.',
        answers: [
          'В соответствующий диспетчерский центр субъекта оперативно-диспетчерского управления',
          'Системному оператору',
          'В орган местного самоуправления',
          'В территориальный орган по делам гражданской обороны и чрезвычайным ситуациям',
        ],
        correctAnswers: [
          'В соответствующий диспетчерский центр субъекта оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64845041',
        text:
          'Какие из перечисленных работ в электроустановках напряжением до 1000 В не могут быть отнесены к перечню работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Снятие и установка электросчетчиков, других приборов и средств измерений',
          'Отсоединение и присоединение кабеля, проводов электродвигателя и отдельных электроприемников инженерного оборудования зданий и сооружений',
          'Ремонт отдельно расположенных магнитных станций и блоков управления, уход за щеточным аппаратом электрических машин и смазка подшипников',
          'Прокладка и перекладка силовых и контрольных кабелей',
        ],
        correctAnswers: [
          'Прокладка и перекладка силовых и контрольных кабелей',
        ],
      },
      {
        code: '64845042',
        text:
          'Какая перегрузка по току допускается на период послеаварийного режима для кабелей, находящихся в эксплуатации более 15 лет? Выберите правильный вариант ответа.',
        answers: [
          'Не должна превышать 10 %',
          'Не должна превышать 15 %',
          'Не должна превышать 18 %',
          'Не должна превышать 30 %',
        ],
        correctAnswers: ['Не должна превышать 10 %'],
      },
      {
        code: '64845043',
        text:
          'Каким образом допускающий перед допуском к работе убеждается в выполнении технических мероприятий по подготовке рабочего места? Выберите правильный вариант ответа.',
        answers: [
          'Только личным осмотром и по записям в оперативном журнале',
          'Путем выполнения всего перечисленного',
          'Только по оперативной схеме',
          'По сообщениям лица, выдающего разрешение на подготовку рабочего места и допуск',
        ],
        correctAnswers: ['Путем выполнения всего перечисленного'],
      },
      {
        code: '64845044',
        text:
          'В каких электроустановках при использовании указателя напряжения необходимо надевать диэлектрические перчатки? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках напряжением выше 1000 В',
          'В электроустановках напряжением ниже 1000 В',
          'В электроустановках напряжением выше 380 В',
        ],
        correctAnswers: ['В электроустановках напряжением выше 1000 В'],
      },
      {
        code: '64845045',
        text:
          'Расследования каких аварий осуществляют собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация? Выберите правильный вариант ответа.',
        answers: [
          'Только в результате которых произошли неправильные действия защитных устройств и (или) систем автоматики',
          'Только в результате которых произошло повреждение основного оборудования электростанции, а также отключение такого оборудования действием автоматических защитных устройств или оперативным персоналом вследствие недопустимых отклонений технологических параметров или ошибочных действий оперативного персонала',
          'Только в результате которых произошло нарушение, приводящее к потере управляемости объекта электроэнергетики (потеря питания собственных нужд, оперативного тока, давления в магистралях сжатого воздуха, систем управления оборудованием) продолжительностью 1 час и более',
          'Выясняет причины возникновения всех перечисленных аварий',
        ],
        correctAnswers: [
          'Выясняет причины возникновения всех перечисленных аварий',
        ],
      },
      {
        code: '64845046',
        text:
          'До какой температуры должен подогреваться мазут в приемных емкостях и резервуарах хранения топлива на мазутосливе (в цистернах, лотках и приемных емкостях)? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 90 °С',
          'Не выше 100 °С',
          'Не выше 85 °С',
          'Не выше 95 °С',
        ],
        correctAnswers: ['Не выше 90 °С'],
      },
      {
        code: '64845047',
        text:
          'Что должно обязательно указываться в наряде-допуске рядом с фамилией и инициалами работников? Выберите правильный вариант ответа.',
        answers: [
          'Дата рождения',
          'Место работы',
          'Группа по электробезопасности',
          'Возраст',
        ],
        correctAnswers: ['Группа по электробезопасности'],
      },
      {
        code: '64845048',
        text:
          'Кто осуществляет допуск к работам на кабельных линиях, расположенных в распределительном устройстве, если распределительное устройство и кабельные линии принадлежат разным организациям? Выберите правильный вариант ответа.',
        answers: [
          'Персонал, обслуживающий распределительное устройство',
          'Персонал, обслуживающий кабельные линии',
          'Персонал, обслуживающий распределительное устройство и кабельные линии',
          'Персонал, обслуживающий распределительное устройство или кабельные линии',
        ],
        correctAnswers: [
          'Персонал, обслуживающий распределительное устройство',
        ],
      },
      {
        code: '64845049',
        text:
          'Какие из перечисленных технических устройств подлежат оценке соответствия, согласно требованиям ТР ТС 010/2011 "Технический регламент Таможенного союза. О безопасности машин и оборудования"? Выберите правильный вариант ответа.',
        answers: [
          'Технические устройства, выпускаемые в обращение на единой таможенной территории Таможенного союза',
          'Изготовленные для собственных нужд их изготовителей',
          'Бывшие ранее в эксплуатации',
          'Запасные части к техническим устройствам, используемые для ремонта (технического обслуживания)',
          'Комплектующие изделия к техническим устройствам',
        ],
        correctAnswers: [
          'Технические устройства, выпускаемые в обращение на единой таможенной территории Таможенного союза',
        ],
      },
      {
        code: '64845050',
        text:
          'В течение какого времени со дня ввода объекта в эксплуатацию владелец объекта подает заявление об установлении границ охранной зоны и сведения о границах охранной зоны? Выберите правильный вариант ответа.',
        answers: [
          'В течение 15 дней',
          'В течение 20 дней',
          'В течение 30 дней',
          'В течение 2 месяцев',
        ],
        correctAnswers: ['В течение 15 дней'],
      },
      {
        code: '64845051',
        text:
          'В течение какого времени диспетчерский персонал субъектов оперативно-диспетчерского управления передает информацию о состоянии оборудования на объекте, режимах его работы, последствиях аварии и ходе ее ликвидации в соответствующее объединенное диспетчерское управление системного оператора? Выберите правильный вариант ответа.',
        answers: [
          'В течение 1 часа с момента получения',
          'В течение 2 часов с момента получения',
          'В течение 3 часов с момента получения',
          'В течение 4 часов с момента получения',
        ],
        correctAnswers: ['В течение 1 часа с момента получения'],
      },
      {
        code: '64845052',
        text:
          'В течение какого времени со дня поступления повторного заявления об установлении границ охранной зоны заявление подлежит удовлетворению, если устранены обстоятельства, послужившие основанием для отказа в удовлетворении ранее поданного заявления? Выберите правильный вариант ответа.',
        answers: [
          'В течение 15 рабочих дней со дня поступления',
          'В течение 15 календарных дней со дня поступления',
          'В течение 20 рабочих дней со дня поступления',
          'В течение 30 рабочих дней со дня поступления',
        ],
        correctAnswers: ['В течение 15 рабочих дней со дня поступления'],
      },
      {
        code: '64845053',
        text:
          'Какое из перечисленных требований при эксплуатации конденсаторной установки указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Повторное включение конденсаторной установки допускается не ранее чем через одну минуту после отключения',
          'Включение конденсаторной установки, отключившейся действием защит, допускается после выяснения и устранения причины ее отключения',
          'Работа конденсаторной установки запрещается, если токи в фазах различаются более чем на 10%',
          'Осмотр конденсаторной установки без отключения должен производиться не реже 1 раза в 3 месяца',
        ],
        correctAnswers: [
          'Осмотр конденсаторной установки без отключения должен производиться не реже 1 раза в 3 месяца',
        ],
      },
      {
        code: '64845054',
        text:
          'Какой срок хранения установлен для журналов учета работ по нарядам-допускам и распоряжениям? Выберите правильный вариант ответа.',
        answers: [
          'Один месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Два месяца со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Три месяца со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
          'Один год со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
        correctAnswers: [
          'Один месяц со дня регистрации полного окончания работы по последнему зарегистрированному в журнале наряду-допуску или распоряжению',
        ],
      },
      {
        code: '64845055',
        text:
          'Кто должен назначаться допускающим в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Работники из числа оперативного персонала',
          'Работники из числа электротехнического персонала',
          'Работники из числа административного персонала',
          'Работники из числа ремонтного персонала',
        ],
        correctAnswers: ['Работники из числа оперативного персонала'],
      },
      {
        code: '64845056',
        text:
          'Какое из перечисленных требований к определению оптимального вида, состава и стоимости технического воздействия на оборудование указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Вероятность отказа функционального узла и единицы основного технологического оборудования, на которые не распространяется действие Методических указаний, проектная организация определяет на основании существующей статистики отказов оборудования одного вида',
          'Результаты расчетов объединяют в карту возможных сценариев выбора вида технических воздействий',
          'Субъект электроэнергетики проводит оценку возможного для него ущерба из-за отказа функционального узла или единицы основного технологического оборудования (последствия отказа основного технологического оборудования)',
          'В соответствии с принятой субъектом электроэнергетики технической политикой согласно карте возможных сценариев выбора вида технических воздействий планируются программы технического обслуживания и ремонта, технического перевооружения и реконструкции основного технологического оборудования и (или) объектов электроэнергетики',
        ],
        correctAnswers: [
          'Вероятность отказа функционального узла и единицы основного технологического оборудования, на которые не распространяется действие Методических указаний, проектная организация определяет на основании существующей статистики отказов оборудования одного вида',
        ],
      },
      {
        code: '64845057',
        text:
          'На какой срок выдается распоряжение на производство работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Распоряжение носит разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
          'На все время проведения работ',
          'Не более 20 календарных дней со дня начала работы',
          'Не более 5 календарных дней со дня начала работы',
          'Не более 10 календарных дней со дня начала работы',
        ],
        correctAnswers: [
          'Распоряжение носит разовый характер, срок его действия определяется продолжительностью рабочего дня или смены исполнителей',
        ],
      },
      {
        code: '64845058',
        text:
          'Каким образом оформляется наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только письменно в присутствии производителя работ в трех экземплярах, передача по телефону или радио не допускается',
          'В двух экземплярах, а при передаче по телефону, радио, факсимильным письмом или электронным документом - в трех экземплярах',
          'В двух экземплярах, независимо от способа передачи',
          'В трех экземплярах, независимо от способа передачи',
        ],
        correctAnswers: [
          'В двух экземплярах, а при передаче по телефону, радио, факсимильным письмом или электронным документом - в трех экземплярах',
        ],
      },
      {
        code: '64845059',
        text:
          'Кто проводит целевой инструктаж ответственному руководителю работ? Выберите 2 варианта ответа.',
        answers: [
          'Допускающий',
          'Выдающий наряд-допуск',
          'Дежурный оперативный персонал',
          'Специалист по охране труда',
        ],
        correctAnswers: ['Допускающий', 'Выдающий наряд-допуск'],
      },
      {
        code: '64845060',
        text:
          'Каким образом работник при непосредственном использовании может определить, что электрозащитные средства прошли эксплуатационные испытания и пригодны для применения? Выберите правильный вариант ответа.',
        answers: [
          'По внешнему виду средств защиты',
          'По штампу или маркировке на средстве защиты',
          'По записи в журнале испытаний средств защиты',
          'По протоколам эксплуатационных испытаний',
        ],
        correctAnswers: ['По штампу или маркировке на средстве защиты'],
      },
      {
        code: '64845061',
        text:
          'Какие мероприятия выполняются, если в процессе подготовки рабочего места по наряду-допуску возникают сомнения в достаточности и правильности мер по подготовке рабочего места и возможности безопасного выполнения работ? Выберите правильный вариант ответа.',
        answers: [
          'Подготовка должна быть прекращена, в наряд-допуск должны быть внесены необходимые дополнения',
          'Подготовка должна быть приостановлена, повторно должен быть проведен целевой инструктаж с указанием мер безопасности',
          'Подготовка должна быть приостановлена, лица, ответственные за безопасность работы, должны выполнить необходимые технические мероприятия, устраняющие возникшие сомнения, после чего подготовку допускается продолжить',
          'Подготовка должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
        ],
        correctAnswers: [
          'Подготовка должна быть прекращена, а намечаемая работа отложена до выдачи нового наряда, предусматривающего технические мероприятия, устраняющие возникшие сомнения в безопасности',
        ],
      },
      {
        code: '64845062',
        text:
          'В каком случае внеочередная проверка знаний не проводится? Выберите правильный вариант ответа.',
        answers: [
          'При переводе работника на новую должность',
          'При вводе в эксплуатацию нового оборудования и изменениях технологических процессов',
          'При перерыве в работе по данной должности (рабочему месту) более 6 месяцев',
          'При переводе работника на новую должность, в другой филиал (представительство), структурное подразделение в пределах одной организации, если должностные обязанности (трудовые функции) работника по новой должности (рабочему месту) не требуют дополнительных знаний отраслевых актов и инструктивно-технических документов',
        ],
        correctAnswers: [
          'При переводе работника на новую должность, в другой филиал (представительство), структурное подразделение в пределах одной организации, если должностные обязанности (трудовые функции) работника по новой должности (рабочему месту) не требуют дополнительных знаний отраслевых актов и инструктивно-технических документов',
        ],
      },
      {
        code: '64845063',
        text:
          'Какое должно быть наибольшее рабочее напряжение для генераторов и синхронных компенсаторов? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 105 % номинального',
          'Не выше 110 % номинального',
          'Не выше 115 % номинального',
          'Не выше 113 % номинального',
        ],
        correctAnswers: ['Не выше 110 % номинального'],
      },
      {
        code: '64845064',
        text:
          'Кто обязан организовать и обеспечивать работу систем обмена технологической информацией объектов электроэнергетики (энергопринимающих установок) с диспетчерскими центрами? Выберите 2 варианта ответа.',
        answers: [
          'Системный оператор',
          'Органы местного самоуправления',
          'Субъекты электроэнергетики',
          'Потребители электрической энергии, линии электропередачи, оборудование и устройство которых отнесены к объектам диспетчеризации',
        ],
        correctAnswers: [
          'Субъекты электроэнергетики',
          'Потребители электрической энергии, линии электропередачи, оборудование и устройство которых отнесены к объектам диспетчеризации',
        ],
      },
      {
        code: '64845065',
        text:
          'Когда работники должны проходить обучение по оказанию первой помощи пострадавшему на производстве? Выберите правильный вариант ответа.',
        answers: [
          'В течение месяца после допуска к самостоятельной работе',
          'В течение года после допуска к самостоятельной работе',
          'Непосредственно сразу после допуска к самостоятельной работе',
          'До допуска к самостоятельной работе',
        ],
        correctAnswers: ['До допуска к самостоятельной работе'],
      },
      {
        code: '64845066',
        text:
          'Какие изолирующие средства защиты для электроустановок напряжением выше 1000 В относятся к дополнительным? Выберите правильный вариант ответа.',
        answers: [
          'Указатели напряжения для проверки совпадения фаз',
          'Клещи электроизмерительные',
          'Устройства для прокола кабеля',
          'Штанги для переноса и выравнивания потенциала',
        ],
        correctAnswers: ['Штанги для переноса и выравнивания потенциала'],
      },
      {
        code: '64845067',
        text:
          'Кто имеет право на продление нарядов-допусков? Выберите правильный вариант ответа.',
        answers: [
          'Только работник, выдавший наряд-допуск, или другой работник, имеющий право выдачи наряда-допуска на работы в данной электроустановке',
          'Ответственный руководитель работ в данной электроустановке',
          'Ответственный за электрохозяйство структурного подразделения',
          'Руководитель объекта, на котором проводятся работы',
        ],
        correctAnswers: [
          'Только работник, выдавший наряд-допуск, или другой работник, имеющий право выдачи наряда-допуска на работы в данной электроустановке',
        ],
      },
      {
        code: '64845068',
        text:
          'При какой величине естественного тока замыкания на землю в обмотке статора генераторов и синхронных компенсаторов, работающих на сборные шины, они должны разгружаться и отключаться от сети? Выберите правильный вариант ответа.',
        answers: ['3 А и более', '4 А и более', '5 А и более', '2 А и более'],
        correctAnswers: ['5 А и более'],
      },
      {
        code: '64845069',
        text:
          'Применение какого количества дополнительных изолирующих электрозащитных средств достаточно (за исключением особо оговоренных случаев) при использовании основных изолирующих электрозащитных средств? Выберите правильный вариант ответа.',
        answers: [
          'Дополнительные средства в случае применения основного  не используются',
          'Одного дополнительного средства',
          'Трех дополнительных средств',
          'Двух дополнительных средств',
        ],
        correctAnswers: ['Одного дополнительного средства'],
      },
      {
        code: '64845070',
        text:
          'Каким из перечисленных документов не определяется порядок взаимодействия владельцев объектов электроэнергетики и потребителей, участвующих в противоаварийном управлении, с субъектом оперативно-диспетчерского управления? Выберите правильный вариант ответа.',
        answers: [
          'Инструктивно-техническими документами диспетчерских центров субъекта оперативно-диспетчерского управленияё',
          'Договорами об осуществлении технологического присоединения',
          'Правилами оперативно-диспетчерского управления в электроэнергетике',
          'Договорами возмездного оказания услуг по оперативно-диспетчерскому управлению в электроэнергетике и соглашениями о технологическом взаимодействии',
        ],
        correctAnswers: [
          'Договорами об осуществлении технологического присоединения',
        ],
      },
      {
        code: '64845071',
        text:
          'Кто несет ответственность за работу с персоналом? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель подразделения, ответственный за работу с кадрами',
          'Технический руководитель организации',
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права',
          'Лицо, осуществляющее хозяйственную деятельность организации',
        ],
        correctAnswers: [
          'Руководитель организации или должностное лицо из числа руководящих работников организации, которому руководитель организации передает эту функцию и права',
        ],
      },
      {
        code: '64845072',
        text:
          'В какой срок заявителем утверждается акт о выводе из эксплуатации объекта диспетчеризации, в случае если вывод объекта диспетчеризации из эксплуатации подлежит согласованию только с субъектом оперативно-диспетчерского управления? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 5 рабочих дней со дня одновременного наступления необходимых условий',
          'Не позднее 7 рабочих дней со дня одновременного наступления необходимых условий',
          'Не позднее 10 рабочих дней со дня одновременного наступления необходимых условий',
          'Не позднее 20 рабочих дней со дня одновременного наступления необходимых условий',
        ],
        correctAnswers: [
          'Не позднее 5 рабочих дней со дня одновременного наступления необходимых условий',
        ],
      },
      {
        code: '64845073',
        text:
          'В каких электроустановках диэлектрические перчатки применяются в качестве основного изолирующего электрозащитного средства? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках свыше 1000 В',
          'Во всех электроустановках они используются в качестве основного изолирующего средства',
          'Во всех электроустановках они используются в качестве дополнительного изолирующего средства',
          'В электроустановках до 1000 В',
        ],
        correctAnswers: ['В электроустановках до 1000 В'],
      },
      {
        code: '64845074',
        text:
          'В течение какого времени владелец объекта рассматривает заявление об осуществлении взрывных работ в пределах охранных зон и принимает решение о согласовании (об отказе в согласовании) этих действий? Выберите правильный вариант ответа.',
        answers: [
          'В течение 5 рабочих дней со дня поступления заявления',
          'В течение 10 рабочих дней со дня поступления заявления',
          'В течение 10 календарных дней со дня поступления заявления',
          'В течение 15 рабочих дней со дня поступления заявления',
        ],
        correctAnswers: [
          'В течение 5 рабочих дней со дня поступления заявления',
        ],
      },
      {
        code: '64845075',
        text:
          'Какую информацию не содержит заявление о выводе из эксплуатации объекта диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'Сведения о результатах оценки технического состояния основного технологического оборудования',
          'Диспетчерское наименование и местонахождение объекта по производству электрической энергии (мощности) или объекта электросетевого хозяйства, в состав которого входит такой объект диспетчеризации',
          'Дату, начиная с которой планируется вывод объекта диспетчеризации из эксплуатации',
          'Диспетчерское наименование объекта диспетчеризации, планируемого к выводу из эксплуатации',
        ],
        correctAnswers: [
          'Сведения о результатах оценки технического состояния основного технологического оборудования',
        ],
      },
      {
        code: '64845076',
        text:
          'В каких случаях из перечисленных наряд-допуск должен быть выдан заново? Выберите правильный вариант ответа.',
        answers: [
          'Только при замене ответственного руководителя или производителя работ (наблюдающего)',
          'Только при изменении состава бригады более чем наполовину',
          'Только при изменении условий работы',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '64845077',
        text:
          'Какие требования должны соблюдаться при работе энергосистемы в нормальном режиме? Выберите 3 варианта ответа.',
        answers: [
          'Отклонения частоты электрического тока не должны превышать допустимые значения',
          'Перетоки активной мощности в контролируемых сечениях не должны превышать максимально допустимые значения',
          'Напряжение на объектах электроэнергетики должно быть выше минимально допустимых значений, но не должно превышать наибольшие рабочие значения',
          'Токовые нагрузки линий электропередачи и электросетевого оборудования должны значительно превышать длительно допустимые значения',
          'Напряжение на объектах электроэнергетики должно быть ниже минимально допустимых значений',
        ],
        correctAnswers: [
          'Отклонения частоты электрического тока не должны превышать допустимые значения',
          'Перетоки активной мощности в контролируемых сечениях не должны превышать максимально допустимые значения',
          'Напряжение на объектах электроэнергетики должно быть выше минимально допустимых значений, но не должно превышать наибольшие рабочие значения',
        ],
      },
      {
        code: '64845078',
        text:
          'В каком случае допускается совмещение наблюдающим надзора с выполнением какой-либо работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Допускается в случае электроустановок напряжением до 1000 В',
          'Допускается в любом случае',
          'Допускается в случае работы на нескольких рабочих местах, находящихся в прямой видимости',
          'Совмещение не допускается',
        ],
        correctAnswers: ['Совмещение не допускается'],
      },
      {
        code: '64845079',
        text:
          'Что должны иметь в верхних точках все трубопроводы и теплообменные аппараты? Выберите правильный вариант ответа.',
        answers: [
          'Датчики давления',
          'Сигнализирующие устройства',
          'Датчики температуры',
          'Воздушники',
          'Дренажные устройства',
        ],
        correctAnswers: ['Воздушники'],
      },
      {
        code: '64845080',
        text:
          'В каком из перечисленных случаев не производится осмотр переносных заземлений? Выберите правильный вариант ответа.',
        answers: [
          'Непосредственно после применения',
          'Непосредственно перед применением',
          'В процессе эксплуатации не реже 1 раза в 3 месяца',
          'После воздействия токов короткого замыкания',
        ],
        correctAnswers: ['Непосредственно после применения'],
      },
      {
        code: '64845081',
        text:
          'Кем должна осуществляться организация технического обслуживания и ремонта в отношении объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Субъектами электроэнергетики в отношении объектов электроэнергетики, принадлежащих им на праве собственности или ином законном основании',
          'Территориальным органом Ростехнадзора',
          'Органами местного самоуправления',
          'Проектной организацией',
        ],
        correctAnswers: [
          'Субъектами электроэнергетики в отношении объектов электроэнергетики, принадлежащих им на праве собственности или ином законном основании',
        ],
      },
      {
        code: '64845082',
        text:
          'Какие организации подлежат проверке готовности к отопительному периоду? Выберите правильный вариант ответа.',
        answers: [
          'Только теплоснабжающие организации и теплосетевые организации',
          'Только потребители тепловой энергии, теплопотребляющие установки которых подключены (технологически присоединены) к системе теплоснабжения',
          'Только муниципальные образования',
          'Все перечисленные',
        ],
        correctAnswers: ['Все перечисленные'],
      },
      {
        code: '64845083',
        text:
          'Кто должен проверять подготовку рабочих мест при допуске бригады к работе по наряду? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель и производитель работ совместно с допускающим',
          'Производитель работ и наблюдающий',
          'Выдающий наряд, руководитель работ и производитель работ',
          'Руководитель работ, производитель работ и дежурный',
        ],
        correctAnswers: [
          'Руководитель и производитель работ совместно с допускающим',
        ],
      },
      {
        code: '64845084',
        text:
          'До какого числа необходимо подать предложения о выводе в ремонт объектов диспетчеризации для формирования и утверждения сводного годового графика ремонта? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 30 июля года, предшествующего планируемому году',
          'Не позднее 30 октября года, предшествующего планируемому году',
          'Не позднее 30 сентября года, предшествующего планируемому году',
          'Не позднее 30 августа года, предшествующего планируемому году',
        ],
        correctAnswers: [
          'Не позднее 30 июля года, предшествующего планируемому году',
        ],
      },
      {
        code: '64845085',
        text:
          'При каком уровне напряженности электрического поля разрешается пребывание персонала в электрическом поле в течение всего рабочего дня (8 ч)? Выберите правильный вариант ответа.',
        answers: [
          'Не превышающем 5 кВ/м',
          'Не превышающем 10 кВ/м',
          'Не превышающем 25 кВ/м',
        ],
        correctAnswers: ['Не превышающем 5 кВ/м'],
      },
      {
        code: '64845086',
        text:
          'Кому не предоставляется право выдачи разрешений на подготовку рабочих мест и допуск к работам на объектах электросетевого хозяйства? Выберите правильный вариант ответа.',
        answers: [
          'Работникам из числа административно-технического персонала, имеющим группу по электробезопасности не ниже IV, уполномоченным на это организационно-распорядительным документом организации (обособленного подразделения)',
          'Оперативному персоналу, имеющему группу по электробезопасности не ниже IV, уполномоченным на это организационно-распорядительным документом организации (обособленного подразделения)',
          'Работникам из числа оперативного персонала, имеющим группу III  по электробезопасности',
        ],
        correctAnswers: [
          'Работникам из числа оперативного персонала, имеющим группу III  по электробезопасности',
        ],
      },
      {
        code: '64845087',
        text:
          'С какой периодичностью должна производиться проверка шланговых противогазов на пригодность к использованию (отсутствие механических повреждений, герметичность, исправность шлангов и воздуховодов)? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в 3 месяца, а также перед каждой выдачей',
          'Не реже одного раза в 6 месяцев, а также перед каждой выдачей',
          'Не реже одного раза в 12 месяцев',
          'Не реже одного раза в 9 месяцев',
        ],
        correctAnswers: [
          'Не реже одного раза в 3 месяца, а также перед каждой выдачей',
        ],
      },
      {
        code: '64845088',
        text:
          'Что должно быть указано на средствах защиты, используемых для работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Маркировка с указанием завода-изготовителя, года выпуска изделия, а также класса напряжения',
          'Только штамп об испытании',
          'Маркировка с указанием наименования или типа изделия и года выпуска, а также штамп с указанием к какому типу относится средство защиты: к основному или дополнительному',
          'Маркировка с указанием завода-изготовителя, наименования или типа изделия и года выпуска, а также штамп об испытании',
        ],
        correctAnswers: [
          'Маркировка с указанием завода-изготовителя, наименования или типа изделия и года выпуска, а также штамп об испытании',
        ],
      },
      {
        code: '64845089',
        text:
          'Какую работу на воздушных линиях не разрешается выполнять по распоряжению одному работнику, имеющему группу II по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'Осмотр воздушной линии в темное время суток',
          'Восстановление постоянных обозначений на опоре',
          'Противопожарную очистку площадок вокруг опор',
          'Замер габаритов угломерными приборами',
        ],
        correctAnswers: ['Осмотр воздушной линии в темное время суток'],
      },
      {
        code: '64845090',
        text:
          'Куда должны заноситься результаты учебной противоаварийной тренировки? Выберите правильный вариант ответа.',
        answers: [
          'В протокол прохождения тренировки',
          'В журнал учета тренировок',
          'Во все перечисленные документы',
          'В личное дело работника',
        ],
        correctAnswers: ['В журнал учета тренировок'],
      },
      {
        code: '64845091',
        text:
          'В течение какого срока с момента получения запроса субъекта оперативно-диспетчерского управления подлежат представлению сведения, необходимые для разработки и принятия решений о применении мер, направленных на локализацию и ликвидацию режима с высокими рисками нарушения электроснабжения? Выберите правильный вариант ответа.',
        answers: [
          'В течение 2 часов или в иные предусмотренные запросом сроки',
          'В течение 3 часов или в иные предусмотренные запросом сроки',
          'В течение суток или в иные предусмотренные запросом сроки',
          'В течение 4 часов или в иные предусмотренные запросом сроки”',
        ],
        correctAnswers: [
          'В течение 2 часов или в иные предусмотренные запросом сроки',
        ],
      },
      {
        code: '64845092',
        text:
          'Что из перечисленного должна содержать оперативная информация об авариях в электроэнергетике, передаваемая организациями? Выберите правильный вариант ответа.',
        answers: [
          'Наименование защит, действием которых отключилось оборудование',
          'Диспетчерское наименование отключившегося оборудования',
          'Все перечисленное',
          'Дату и московское время аварии в формате “ДД.ММ в ЧЧ:ММ”',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64845093',
        text:
          'Какая допустимая скорость регулирования температуры воды на выходе из сетевых подогревателей, на выводах тепловой сети? Выберите правильный вариант ответа.',
        answers: [
          'Не более 40 °С в час',
          'Не более 37 °С в час',
          'Не более 30 °С в час',
          'Не более 35 °С в час',
        ],
        correctAnswers: ['Не более 30 °С в час'],
      },
      {
        code: '64845094',
        text:
          'Какое требование Правил технической эксплуатации электростанций и сетей Российской Федерациик включению трансформаторов на номинальную нагрузку в зависимости от температуры окружающего воздуха указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Включение трансформаторов с системами охлаждения М и Д на номинальную нагрузку допускается при любой отрицательной температуре наружного воздуха',
          'Включение трансформаторов с системами охлаждения ДЦ и Ц на номинальную нагрузку допускается при значениях температуры окружающего воздуха до минус 35 °С',
          'В аварийных условиях допускается включение трансформаторов с системами охлаждения ДЦ и Ц на полную нагрузку независимо от температуры окружающего воздуха',
          'Включение трансформаторов при системе охлаждения с направленным потоком масла в обмотках производится при значениях температуры, определяемых в соответствии с производственной (местной) инструкцией с учетом требований документации организации-изготовителя',
        ],
        correctAnswers: [
          'Включение трансформаторов с системами охлаждения ДЦ и Ц на номинальную нагрузку допускается при значениях температуры окружающего воздуха до минус 35 °С',
        ],
      },
      {
        code: '64845095',
        text:
          'С какой периодичностью должна производиться оценка технического состояния основного технологического оборудования? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в год',
          'Не реже 1 раза в 2 года',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в 5 лет',
        ],
        correctAnswers: ['Не реже 1 раза в год'],
      },
      {
        code: '64845096',
        text:
          'В каком случае не должна выполняться проверка времени закрытия стопорных (защитных, отсечных) клапанов паровой турбины? Выберите правильный вариант ответа.',
        answers: [
          'После монтажа турбины',
          'Непосредственно до и после капитального ремонта турбины',
          'Непосредственно до и после ремонта основных узлов системы регулирования или парораспределения',
          'При пуске после длительного простоя (более 3 месяцев) турбины',
        ],
        correctAnswers: [
          'При пуске после длительного простоя (более 3 месяцев) турбины',
        ],
      },
      {
        code: '64845097',
        text:
          'Какой срок по устранению причин ухудшения качества пара по нормам содержания соединений натрия, кремниевой кислоты и удельной электрической проводимости для котлов с естественной циркуляцией указан неверно? Выберите правильный вариант ответа.',
        answers: [
          'При превышении норм не более чем в 1,5 раза причина должна быть устранена в течение 4 суток',
          'При превышении норм не более чем в 2 раза причина должна быть устранена в течение 72 часов',
          'При превышении норм не более чем в 2 - 4 раза причина должна быть устранена в течение 24 часов',
          'При неустранении нарушений в установленные сроки, а также при превышении норм более чем в 4 раза или снижении рН ниже 5,5 турбина на блочных электростанциях или котел на электростанциях с поперечными связями должны быть остановлены не позднее чем через 24 часа по решению технического руководителя электростанции с уведомлением диспетчера энергосистемы',
        ],
        correctAnswers: [
          'При превышении норм не более чем в 1,5 раза причина должна быть устранена в течение 4 суток',
        ],
      },
      {
        code: '64845098',
        text:
          'Кем обеспечиваются организация эксплуатации, ремонт и техническое обслуживание линий электропередачи, оборудования и устройств объектов электроэнергетики, организация и осуществление в их отношении непрерывного оперативно-технологического управления для обеспечения функционирования объектов электроэнергетики в составе энергосистемы? Выберите правильный вариант ответа.',
        answers: [
          'Проектными организациями',
          'Системным оператором электроэнергетических систем России',
          'Субъектами оперативно-диспетчерского управления',
          'Владельцами',
        ],
        correctAnswers: ['Владельцами'],
      },
      {
        code: '64845099',
        text:
          'Вывод в ремонт и из эксплуатации каких из перечисленных объектов системного оператора не подлежит согласованию с диспетчерскими центрами Министерства энергетики Российской Федерации? Выберите правильный вариант ответа.',
        answers: [
          'Оборудование и устройства систем накопления электрической энергии установленной мощностью 10 МВт, с использованием которых осуществляется производство (преобразование в электрическую энергию) и выдача электрической энергии в электрическую сеть',
          'Линии электропередачи, оборудование и устройства, входящие в состав объектов электросетевого хозяйства',
          'Вывод в ремонт и из эксплуатации всех перечисленных объектов подлежит согласованию',
          'Оборудование и устройства электрических станций, установленная мощность которых составляет 2,5 МВт',
        ],
        correctAnswers: [
          'Оборудование и устройства электрических станций, установленная мощность которых составляет 2,5 МВт',
        ],
      },
      {
        code: '64845100',
        text:
          'В какой срок Ростехнадзор или его территориальный орган, принявшие решение о расследовании причин аварии, уведомляют об этом уполномоченный орган в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее 48 часов с момента принятия такого решения',
          'Не позднее 24 часов с момента принятия такого решения',
          'Не позднее 48 часов с момента аварии',
          'Не позднее 48 часов с момента получения информации об аварии',
        ],
        correctAnswers: [
          'Не позднее 48 часов с момента принятия такого решения',
        ],
      },
      {
        code: '64845101',
        text:
          'В какие сроки должна производиться проверка исправности аварийного освещения при отключении рабочего освещения на электростанциях, подстанциях и диспетчерских пунктах? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в месяц в дневное время',
          'Два раза в год',
          'При вводе в эксплуатацию и в дальнейшем один раз в год',
          'Один раз в два года',
        ],
        correctAnswers: ['Два раза в год'],
      },
      {
        code: '64845102',
        text:
          'Кто проводит первичный инструктаж командированному персоналу при проведении работ в электроустановках до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала, имеющий группу IV по электробезопасности',
          'Работник организации - владельца электроустановок из числа административно-технического персонала, имеющий группу V по электробезопасности',
          'Работник командирующей организации из числа административно-технического персонала, имеющий группу IV',
          'Работник организации - владельца электроустановок из числа электротехнического персонала, имеющий группу IV',
        ],
        correctAnswers: [
          'Работник организации - владельца электроустановок из числа административно-технического персонала, имеющий группу IV по электробезопасности',
        ],
      },
      {
        code: '64845103',
        text:
          'За какое время до предполагаемой даты проведения контрольной противоаварийной тренировки должно быть направлено уведомление в соответствующие организации электроэнергетики в случае необходимости участия в проведении контрольной тренировки оперативного персонала других организаций электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее, чем за семь календарных дней',
          'Не позднее, чем за один календарный месяц',
          'Не позднее, чем за пятнадцать календарных дней',
          'Не позднее, чем за десять календарных дней',
        ],
        correctAnswers: ['Не позднее, чем за один календарный месяц'],
      },
      {
        code: '64845104',
        text:
          'Какой должна быть чистота водорода в корпусах генераторов с непосредственным водородным охлаждением и синхронных компенсаторах всех типов, если иное не установлено в документации организации-изготовителя генератора и синхронного компенсатора? Выберите правильный вариант ответа.',
        answers: [
          'Не ниже 93 %',
          'Не ниже 95 %',
          'Не ниже 97 %',
          'Не ниже 98 %',
        ],
        correctAnswers: ['Не ниже 98 %'],
      },
      {
        code: '64845105',
        text:
          'Как оформляется акт расследования причин аварии при несогласии отдельных членов комиссии? Выберите правильный вариант ответа.',
        answers: [
          'Несогласные члены комиссии подписывают акт с “особым мнением”',
          'Несогласные члены комиссии акт не подписывают и направляют “особое мнение” в Управление государственного энергетического надзора Ростехнадзора',
          'Несогласные члены комиссии подписывают акт, а их “особое мнение” прилагается к акту расследования',
          'Несогласные члены комиссии акт не подписывают',
          'Несогласные члены комиссии подписывают акт с примечанием "Не согласен"',
        ],
        correctAnswers: [
          'Несогласные члены комиссии подписывают акт, а их “особое мнение” прилагается к акту расследования',
        ],
      },
      {
        code: '64845106',
        text:
          'В каком из перечисленных случаев энергоблок должен быть незамедлительно остановлен персоналом при отказе в работе защит или при их отсутствии? Выберите правильный вариант ответа.',
        answers: [
          'Только в случае отключения генератора или трансформатора энергоблока из-за внутреннего повреждения',
          'Только в случае отключения всех питательных насосов',
          'Только в случае исчезновения напряжения на всех измерительных приборах контроля энергоблока',
          'В любом из перечисленных случаев',
        ],
        correctAnswers: ['В любом из перечисленных случаев'],
      },
      {
        code: '64845107',
        text:
          'На сколько лет должна быть предоставлена гарантия качества в отношении работ по строительству и примененных материалов для подключения (технологического присоединения) к системам теплоснабжения тепловых сетей? Выберите правильный вариант ответа.',
        answers: [
          'Не менее чем десять лет',
          'Не менее чем семь лет',
          'Не менее чем пять лет',
          'Не менее чем три года',
        ],
        correctAnswers: ['Не менее чем десять лет'],
      },
      {
        code: '64845108',
        text:
          'С какой периодичностью должны подвергаться испытаниям на механическую прочность предохранительные пояса и страховочные канаты? Выберите правильный вариант ответа.',
        answers: [
          'Один раз в 6 месяцев и перед вводом в эксплуатацию испытываются статической нагрузкой',
          'Один раз в 6 месяцев испытываются статической нагрузкой и перед вводом в эксплуатацию испытываются динамической нагрузкой',
          'Один раз в 6 месяцев испытываются динамической нагрузкой и перед вводом в эксплуатацию испытываются статической нагрузкой',
          'Один раз в 6 месяцев и перед вводом в эксплуатацию испытываются динамической нагрузкой',
        ],
        correctAnswers: [
          'Один раз в 6 месяцев и перед вводом в эксплуатацию испытываются статической нагрузкой',
        ],
      },
      {
        code: '64845109',
        text:
          'Какие из перечисленных работ в электроустановках напряжением до и выше 1000 В необходимо проводить только по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'Работы на генераторе, от выводов которого отсоединены шины и кабели',
          'Работы на электродвигателе, от которого отсоединен кабель, а концы его замкнуты накоротко и заземлены',
          'В распределительных устройствах на выкаченных тележках комплектных распределительных устройств, у которых шторки отсеков заперты на замок',
          'Неотложные работы, для выполнения которых требуется более 1 часа или участие более трех работников, включая работника из оперативного и оперативно-ремонтного персонала',
        ],
        correctAnswers: [
          'Неотложные работы, для выполнения которых требуется более 1 часа или участие более трех работников, включая работника из оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64845110',
        text:
          'Кем утверждается перечень работ, выполняемых в порядке текущей эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Руководителем организации или руководителем обособленного подразделения',
          'Техническим руководителем организации',
          'Ответственным за электрохозяйство организации',
          'Технической комиссией организации',
        ],
        correctAnswers: [
          'Руководителем организации или руководителем обособленного подразделения',
        ],
      },
      {
        code: '64845111',
        text:
          'В каком случае разрешается проверять отсутствие напряжения выверкой схемы в натуре? Выберите правильный вариант ответа.',
        answers: [
          'В открытом распределительном устройстве напряжением 110 кВ и выше и на двухцепных воздушных линиях напряжением 110 кВ и выше',
          'В открытом распределительном устройстве напряжением 35 кВ и выше и на двухцепных воздушных линиях напряжением 35 кВ и выше',
          'В открытом распределительном устройстве и на комплектной трансформаторной подстанции наружной установки при тумане, дожде, снегопаде в случае отсутствия специальных указателей напряжения',
        ],
        correctAnswers: [
          'В открытом распределительном устройстве и на комплектной трансформаторной подстанции наружной установки при тумане, дожде, снегопаде в случае отсутствия специальных указателей напряжения',
        ],
      },
      {
        code: '64845112',
        text:
          'В каком документе регистрируется первичный и ежедневные допуски к работе по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'В оперативном журнале',
          'В журнале учета работ по нарядам-допускам и распоряжениям',
          'В журнале дефектов и неполадок на электрооборудовании',
          'В журнале учета электрооборудования',
        ],
        correctAnswers: ['В оперативном журнале'],
      },
      {
        code: '64845113',
        text:
          'Какие из перечисленных требований являются необходимыми при опробовании технологических защит? Выберите правильный вариант ответа.',
        answers: [
          'Перед пуском защищаемого оборудования после его капитального и среднего ремонта, а также после проведения ремонта в цепях технологических защит проверяется исправность и готовность защит к включению путем опробования на сигнал каждой защиты и действия защит на все исполнительные устройства',
          'Перед пуском защищаемого оборудования после его простоя более 3 суток проверяется действие защит на все исполнительные устройства, а также операции включения резерва технологического оборудования',
          'При недопустимости проверки исполнительных операций защит в связи с тепловым состоянием защищаемого оборудования опробование защиты производится без воздействия на исполнительные устройства',
          'Все перечисленные требования являются необходимыми',
        ],
        correctAnswers: ['Все перечисленные требования являются необходимыми'],
      },
      {
        code: '64845114',
        text:
          'При каких условиях оперативный персонал, находящийся на дежурстве, допускается привлекать к работе в бригаде по наряду-допуску? Выберите правильный вариант ответа.',
        answers: [
          'С разрешения работника из числа вышестоящего оперативного персонала, с записью в оперативном журнале с оформлением распоряжения или наряда-допуска в журнале учета работ по нарядам-допускам и распоряжениям',
          'С разрешения ответственного руководителя работ',
          'С разрешения работника из числа административно-технического персонала с оформлением в наряде-допуске',
        ],
        correctAnswers: [
          'С разрешения работника из числа вышестоящего оперативного персонала, с записью в оперативном журнале с оформлением распоряжения или наряда-допуска в журнале учета работ по нарядам-допускам и распоряжениям',
        ],
      },
      {
        code: '64845115',
        text:
          'При каких погодных условиях можно пользоваться изолирующими электрозащитными средствами в открытых электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только в сухую погоду',
          'В любую погоду',
          'Только при температуре окружающего воздуха не ниже 0 градусов Цельсия и скорости ветра не выше 15 м/с',
          'Только при осадках',
        ],
        correctAnswers: ['Только в сухую погоду'],
      },
      {
        code: '64845116',
        text:
          'В каких случаях из перечисленных котел должен быть остановлен по распоряжению технического руководителя? Выберите правильный вариант ответа.',
        answers: [
          'Только в случае недопустимого повышения температуры металла поверхностей нагрева, если понизить температуру изменением режима работы котла не удается',
          'Только в случае резкого ухудшения качества питательной воды по сравнению с установленными нормами',
          'Только в случае выхода из строя всех дистанционных указателей уровня воды в барабане котла',
          'В любом из перечисленных случаев, а также при неисправности отдельных защит или устройств дистанционного и автоматического управления и контрольно-измерительных приборов',
        ],
        correctAnswers: [
          'В любом из перечисленных случаев, а также при неисправности отдельных защит или устройств дистанционного и автоматического управления и контрольно-измерительных приборов',
        ],
      },
      {
        code: '64845117',
        text:
          'Каким образом осуществляется оперативно-диспетчерское управление в технологически изолированной территориальной электроэнергетической системе? Выберите правильный вариант ответа.',
        answers: [
          'Субъектом оперативно-диспетчерского управления с участием органов местного самоуправления',
          'Субъектом оперативно-диспетчерского управления с участием системного оператора',
          'Индивидуально для каждой технологически изолированной территориальной электроэнергетической системы',
          'Субъектом оперативно-диспетчерского управления самостоятельно',
        ],
        correctAnswers: [
          'Субъектом оперативно-диспетчерского управления самостоятельно',
        ],
      },
      {
        code: '64845118',
        text:
          'Какие установлены допустимые отклонения фактической среднесуточной температуры сетевой воды в обратном трубопроводе от заданной температурным графиком системы теплоснабжения? Выберите правильный вариант ответа.',
        answers: [
          'Превышение не более чем на 5 %, понижение не лимитируется',
          'Превышение не более чем на 6 %, понижение не лимитируется',
          'Превышение не более чем на 3 %, понижение не более чем на 3 %',
          'Превышение не более чем на 2 %, понижение не более чем на 2 %',
        ],
        correctAnswers: [
          'Превышение не более чем на 5 %, понижение не лимитируется',
        ],
      },
      {
        code: '64845119',
        text:
          'Что определяет системный оператор в целях обеспечения надежности функционирования Единой энергетической системы России? Выберите правильный вариант ответа.',
        answers: [
          'Только потребность в оказании услуг по обеспечению системной надежности и их объемы',
          'Только технические требования, обеспечивающие создание технической возможности технологического присоединения объектов электроэнергетики и энергопринимающих установок потребителей и возможность надежной работы строящихся (реконструируемых) объектов электроэнергетики в составе энергосистемы',
          'Только требования к созданию и (или) модернизации комплексов и устройств релейной защиты и автоматики',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64845120',
        text:
          'В соответствии с требованиями каких документов производятся все переключения в электроустановках при ликвидации нарушений нормального режима электрической части энергосистем и объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Инструкций по производству переключений, разработанных и утвержденных в соответствующем ДЦ, ЦУС, центре управления ВЭС (СЭС), структурном подразделении потребителя электрической энергии',
          'Правил по охране труда при эксплуатации электроустановок',
          'Технологических регламентов',
          'Правил предотвращения развития и ликвидации нарушений нормального режима электрической части энергосистем и объектов электроэнергетики',
        ],
        correctAnswers: [
          'Инструкций по производству переключений, разработанных и утвержденных в соответствующем ДЦ, ЦУС, центре управления ВЭС (СЭС), структурном подразделении потребителя электрической энергии',
        ],
      },
      {
        code: '64845121',
        text:
          'Как должны храниться экранирующие средства защиты? Выберите правильный вариант ответа.',
        answers: [
          'Отдельно от электрозащитных',
          'Вместе с электрозащитными',
          'Совместно с электрозащитными средствами для электроустановок напряжением до 1000 В',
          'Совместно с электрозащитными средствами для электроустановок напряжением выше 1000 В',
        ],
        correctAnswers: ['Отдельно от электрозащитных'],
      },
      {
        code: '64845122',
        text:
          'В каких случаях технологические защиты электролизных установок не должны действовать на отключение преобразовательных агрегатов (двигателей-генераторов)? Выберите правильный вариант ответа.',
        answers: [
          'При разности давлений в регуляторах давлений водорода и кислорода 100 кг/м² (1 кПа)',
          'При содержании кислорода в водороде 1 %',
          'При межполюсных коротких замыканиях',
          'При исчезновении напряжения на преобразовательных агрегатах (двигателях-генераторах) со стороны переменного тока',
        ],
        correctAnswers: [
          'При разности давлений в регуляторах давлений водорода и кислорода 100 кг/м² (1 кПа)',
        ],
      },
      {
        code: '64845123',
        text:
          'В какой срок лицо, получившее неудовлетворительную оценку по результатам проверки знаний, должно пройти повторную проверку? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее одного месяца',
          'Не позднее 10 дней',
          'Вопрос о сроках повторной проверки и о возможности сохранения трудового договора с работником решается руководителем',
          'Не позднее 14 дней',
        ],
        correctAnswers: ['Не позднее одного месяца'],
      },
      {
        code: '64845124',
        text:
          'Какой порядок допуска к самостоятельной работе вновь принятых работников или имевших перерыв в работе более 6 месяцев? Выберите правильный вариант ответа.',
        answers: [
          'В зависимости от категории персонала после ознакомления с изменениями в схемах и режимах работы энергоустановок, с вновь введенными в действие нормативно-техническими документами, приказами и распоряжениями',
          'В зависимости от категории персонала после прохождения этапов подготовки в соответствии с индивидуальной программой',
          'В зависимости от категории персонала после прохождения специальной подготовки, программу и порядок проведения которой определяет руководитель организации',
          'В зависимости от категории персонала форму подготовки персонала для допуска к самостоятельной работе определяет руководитель организации или структурного подразделения',
        ],
        correctAnswers: [
          'В зависимости от категории персонала после прохождения этапов подготовки в соответствии с индивидуальной программой',
        ],
      },
      {
        code: '64845125',
        text:
          'Какие установлены допускаемые значения отклонений давления сетевой воды в подающих трубопроводах от заданных теплового и гидравлического режимов? Выберите правильный вариант ответа.',
        answers: [
          'Не более ±5 %',
          'Не более ±7 %',
          'Не более ±8 %',
          'Не более ±10 %',
        ],
        correctAnswers: ['Не более ±5 %'],
      },
      {
        code: '64845126',
        text:
          'Какие мероприятия обязательно осуществляются перед допуском к проведению неотложных работ? Выберите правильный вариант ответа.',
        answers: [
          'Технические мероприятия по подготовке рабочего места',
          'Оформление наряда-допуска',
          'Проверка количественного и качественного состава бригады',
          'Проведение целевого инструктажа',
        ],
        correctAnswers: [
          'Технические мероприятия по подготовке рабочего места',
        ],
      },
      {
        code: '64845127',
        text:
          'Кто предоставляет командированному персоналу привлекаемой организации права работы в действующих электроустановках в качестве выдающих наряды-допуски и распоряжения, ответственных руководителей, производителей работ, членов бригады? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель организации )обособленного подразделения) - владелец электроустановки на письме командирующей организации или организационно-разрешительной документации организации (обособленного подразделения)',
          'Ответственный за электрохозяйство командирующей организации',
          'Руководитель командирующей организации',
          'Технический руководитель командирующей организации',
        ],
        correctAnswers: [
          'Руководитель организации )обособленного подразделения) - владелец электроустановки на письме командирующей организации или организационно-разрешительной документации организации (обособленного подразделения)',
        ],
      },
      {
        code: '64845128',
        text:
          'Какое определение соответствует термину "дублирование"? Выберите правильный вариант ответа.',
        answers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
          'Практическое освоение непосредственно на рабочем места навыков выполнения работы или группы работ, приобретенных при профессиональной подготовке',
          'Одна из форм дополнительного повышения образовательного уровня персонала, осуществляемая путем систематического самообразования, проведения производственно-экономической учебы, краткосрочного и длительного периодического обучения в соответствующих образовательных учреждениях',
          'Форма поддержания квалификации работника путем его систематической тренировки в управлении производственными процессами на учебно-тренировочных средствах, формирования его знаний, умения и навыков, прорабьотки организационно-распорядительных докмуентов и разборки технологических нарушений, пожаров и случаев производственного травматизма',
        ],
        correctAnswers: [
          'Выполнение дублируемым работником функциональных обязанностей диспетчерского, оперативного, оперативно-ремонтного персонала на его рабочем месте, осуществляемое под наблюдением лица, ответственного за подготовку дублируемого работника, с целью практического освоения им навыков перед допуском к самостоятельной работе',
        ],
      },
      {
        code: '64845129',
        text:
          'Какие из перечисленных действий обязан осуществлять заявитель в течение определенного решением уполномоченного органа срока, на который вывод объекта диспетчеризации из эксплуатации приостановлен (включая периоды продления такого срока по решению уполномоченного органа)? Выберите 2 варианта ответа.',
        answers: [
          'Поддерживать такой объект диспетчеризации в исправном техническом состоянии и осуществлять его эксплуатацию, включая оперативное, ремонтное и техническое обслуживание, в соответствии с требованиями нормативных правовых актов Российской Федерации в сфере электроэнергетики',
          'Воздерживаться от подачи диспетчерских заявок, направленных на вывод объекта диспетчеризации из эксплуатации',
          'Осуществлять мероприятия, направленные на уменьшение установленной генерирующей мощности объекта диспетчеризации',
          'Осуществлять фактические действия, направленные на вывод объекта диспетчеризации из эксплуатации',
        ],
        correctAnswers: [
          'Поддерживать такой объект диспетчеризации в исправном техническом состоянии и осуществлять его эксплуатацию, включая оперативное, ремонтное и техническое обслуживание, в соответствии с требованиями нормативных правовых актов Российской Федерации в сфере электроэнергетики',
          'Воздерживаться от подачи диспетчерских заявок, направленных на вывод объекта диспетчеризации из эксплуатации',
        ],
      },
      {
        code: '64845130',
        text:
          'В каком случае допускается включать в состав бригады, выполняющей работы по наряду-допуску, работников, имеющих II группу по электробезопасности? Выберите правильный вариант ответа.',
        answers: [
          'На каждого работника, имеющего группу III допускается включать одного работника, имеющего группу II, но не более трех в бригаду',
          'Допускается, но не более двух в бригаду',
          'Не допускается ни в каком случае',
          'На каждого работника, имеющего группу III, допускается включать только одного работника, имеющего группу II',
        ],
        correctAnswers: [
          'На каждого работника, имеющего группу III допускается включать одного работника, имеющего группу II, но не более трех в бригаду',
        ],
      },
      {
        code: '64845131',
        text:
          'Какую группу по электробезопасности при проведении неотложных работ должен иметь производитель работ или наблюдающий из числа оперативного персонала, соответственно выполняющий работу или осуществляющий надзор за работающими в электроустановках напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Группу IV по электробезопасности',
          'Группу III по электробезопасности',
          'Группу V по электробезопасности',
          'Группу II по электробезопасности',
        ],
        correctAnswers: ['Группу IV по электробезопасности'],
      },
      {
        code: '64845132',
        text:
          'Какие мероприятия включают в себя локальные нормативные акты технического обслуживания гидротехнических сооружений? Выберите правильный вариант ответа.',
        answers: [
          'Ведение технической документации по контролю состояния гидротехнических сооружений',
          'Выполнение ремонтных работ без создания помех в работе электростанции',
          'Все перечисленные мероприятия',
          'Контроль герметичности, ревизия и чистка элементов конструкций и систем',
        ],
        correctAnswers: ['Все перечисленные мероприятия'],
      },
      {
        code: '64845133',
        text:
          'В каком случае требуется оформление разрешения на допуск в эксплуатацию энергоустановки для включения ее в работу при проведении аварийно-восстановительных работ в условиях ликвидации аварийных ситуаций в работе электроэнергетической системы? Выберите правильный вариант ответа.',
        answers: [
          'Не требуется ни в каком случае',
          'Во всех случаях, кроме проведения неотложных аварийно-восстановительных работ',
          'Если это предусмотрено проектом',
          'Во всех случаях в соответствии с Порядком организации работ по выдаче разрешений на допуск в эксплуатацию энергоустановок',
        ],
        correctAnswers: ['Не требуется ни в каком случае'],
      },
      {
        code: '64845134',
        text:
          'Какая температура верхних слоев масла должна быть у трансформаторов и реакторов с естественной циркуляцией воздуха и масла при номинальной нагрузке, если документацией организации-изготовителя не определены иные значения температуры? Выберите правильный вариант ответа.',
        answers: [
          'Не выше 98 °С',
          'Не выше 100 °С',
          'Не выше 102 °С',
          'Не выше 95 °С',
        ],
        correctAnswers: ['Не выше 95 °С'],
      },
      {
        code: '64845135',
        text:
          'Чем должен руководствоваться владелец объекта электроэнергетики при проведении технического освидетельствования, в случае утраты документации организации-изготовителя оборудования, либо проектной документации? Выберите правильный вариант ответа.',
        answers: [
          'Правилами проведения технического освидетельствования оборудования, зданий и сооружений объектов электроэнергетики',
          'Внутренней документацией',
          'Техническим регламентом о безопасности зданий и сооружений',
          'Методикой оценки технического состояния основного технологического оборудования и линий электропередачи электрических станция и электричесских сетей',
        ],
        correctAnswers: [
          'Правилами проведения технического освидетельствования оборудования, зданий и сооружений объектов электроэнергетики',
        ],
      },
      {
        code: '64845136',
        text:
          'Каким образом должна обеспечиваться безопасность здания или сооружения в процессе эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Только посредством мониторинга состояния основания',
          'Только посредством периодических осмотров строительных конструкций',
          'Только посредством технического обслуживания систем инженерно-технического обеспечения',
          'Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения',
        ],
        correctAnswers: [
          'Посредством проведения всех перечисленных мероприятий, включая проведение текущих ремонтов здания или сооружения',
        ],
      },
      {
        code: '64845137',
        text:
          'У кого могут быть на учете ключи от электроустановок, не имеющих местного оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'У всех перечисленных лиц',
          'У руководящих работников и специалистов организации',
          'У административно-технического персонала',
          'У специалистов по охране труда',
        ],
        correctAnswers: ['У административно-технического персонала'],
      },
      {
        code: '64845138',
        text:
          'В каких электроустановках применяются указатели напряжения для проверки совпадения фаз напряжения (фазировки)? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках от 6 до 110 кВ',
          'В электроустановках до 1000 В',
          'В электроустановках выше 1000 В',
          'В электроустановках от 110 до 220 кВ',
        ],
        correctAnswers: ['В электроустановках от 6 до 110 кВ'],
      },
      {
        code: '64845139',
        text:
          'Каким требованиям должно определяться соответствие здания или сооружения оценкой соответствия здания или сооружения в процессе эксплуатации? Выберите 2 варианта ответа.',
        answers: [
          'Требованиям Федерального закона от 30.12.2009 № 384-ФЗ "Технический регламент о безопасности зданий и сооружений"',
          'Требованиям Федерального закона “О промышленной безопасности опасных производственных объектов” от 21.07.1997 № 116-ФЗ',
          'Требованиями Градостроительного кодекса Российской Федерации от 29.12.2004 № 190-ФЗ',
          'Требованиям проектной документации',
        ],
        correctAnswers: [
          'Требованиям Федерального закона от 30.12.2009 № 384-ФЗ "Технический регламент о безопасности зданий и сооружений"',
          'Требованиям проектной документации',
        ],
      },
      {
        code: '64845140',
        text:
          'Каким образом передается оперативная информация диспетчерским центрам субъектов оперативно-диспетчерского управления в электроэнергетике и территориальным органам Ростехнадзора об авариях в электроэнергетике? Выберите 2 варианта ответа.',
        answers: [
          'Средствами телефонной связи',
          'В электронном виде',
          'Заказным письмом',
          'При личном приеме',
        ],
        correctAnswers: ['Средствами телефонной связи', 'В электронном виде'],
      },
      {
        code: '64845141',
        text:
          'На какое расстояние до токоведущих частей электроустановок, находящихся под напряжением 1-35 кВ, не допускается приближение людей при оперативном обслуживании, осмотрах электроустановок, а также выполнении работ в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Менее чем 0,6 м',
          'Менее чем 1,0 м',
          'Менее чем 1,5 м',
          'Менее чем 1,2 м',
        ],
        correctAnswers: ['Менее чем 0,6 м'],
      },
      {
        code: '64845142',
        text:
          'В каком случае при работе с электроизмерительными клещами обязательно применение диэлектрических перчаток? Выберите правильный вариант ответа.',
        answers: [
          'Только для электроустановок напряжением до 1000 В',
          'Только для электроустановок напряжением выше 1000 В',
          'В любом случае',
          'Только для электроустановок напряжением выше 10 кВ',
        ],
        correctAnswers: ['Только для электроустановок напряжением выше 1000 В'],
      },
      {
        code: '64845143',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для диспетчерского, оперативного и оперативно-ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Ежемесячно',
          'В зависимости от квалификации работника, но не реже 1 раза в 3 месяца',
          'В зависимости от характера выполняемой работы, но не реже 1 раза в 3 месяца',
          'Не реже 1 раза в 6 месяцев',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64845144',
        text:
          'Какой состав постоянно действующей комиссии по проверке знаний организации? Выберите правильный вариант ответа.',
        answers: [
          'Не менее пяти человек, включая председателя и заместителя(ей) председателя комиссии',
          'Не менее семи человек, включая председателя и заместителя(ей) председателя комиссии',
          'Не менее трех человек, включая председателя и заместителя(ей) председателя комиссии',
          'Не менее трех человек, назначенных приказом по организации',
        ],
        correctAnswers: [
          'Не менее пяти человек, включая председателя и заместителя(ей) председателя комиссии',
        ],
      },
      {
        code: '64845145',
        text:
          'Кто должен назначаться контролирующим лицом при наличии в смене объекта электроэнергетики двух и более работников из числа оперативного персонала, допущенного к производству переключений в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'По решению начальника смены',
          'Лицо из числа ремонтного персонала',
          'Лицо из числа персонала релейной защиты и автоматики',
          'Один из работников, старший по должности',
        ],
        correctAnswers: ['Один из работников, старший по должности'],
      },
      {
        code: '64845146',
        text:
          'В каком случае при работе с указателями для проверки совпадения фаз напряжения обязательно применение диэлектрических перчаток? Выберите правильный вариант ответа.',
        answers: [
          'Только для электроустановок напряжением выше 10 кВ',
          'Только для электроустановок напряжением выше 1000 В',
          'Только для электроустановок напряжением до 1000 В',
          'В любом случае',
        ],
        correctAnswers: ['В любом случае'],
      },
      {
        code: '64845147',
        text:
          'Каким путем обеспечивается надежность электроснабжения собственных нужд переменного и постоянного тока электростанций и подстанций в нормальных, ремонтных и аварийных режимах при изменении технологического режима работы и эксплуатационного состояния ЛЭП и оборудования? Выберите правильный вариант ответа.',
        answers: [
          'Только секционированием шин и распределением механизмов собственных нужд по секциям шин из условия минимального нарушения работы электростанции или подстанции в случае выхода из строя любой секции',
          'Только автоматическим вводом резервного питания любой секции шин собственных нужд всех напряжений и применением автономных источников резервного питания для электроснабжения механизмов собственных нужд',
          'Только распределением источников питания собственных нужд по системам и секциям шин с учетом действия устройств автоматического ввода резерва и сохранением в работе механизмов собственных нужд при исчезновении напряжения на секции',
          'Только обеспечением надежного питания механизмов собственных нужд при несинхронной работе шин (частей) электростанции (секционирование шин высокого напряжения, выделение энергоблоков на отдельную линию, выполнение схем деления энергосистемы)',
          'Всеми перечисленными',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '64845148',
        text:
          'Кто имеет право производить переключения в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерский персонал диспетчерского центра',
          'Весь перечисленный персонал',
          'Оперативный  (оперативно-ремонтный) персонал объектов электроэнергетики',
          'Персонал, имеющий право самостоятельно выполнять работы по техническому обслуживанию соответствующих устройств релейной защиты и автоматики (РЗА), - в части выполнения операций с устройствами РЗА, не имеющими переключающих устройств оперативного ввода и вывода',
        ],
        correctAnswers: ['Весь перечисленный персонал'],
      },
      {
        code: '64845149',
        text:
          'Какое из перечисленных утверждений о перерыве в работе на протяжении рабочего дня (на обед, по условиям работы) во время работ на электроустановках не является верным? Выберите правильный вариант ответа.',
        answers: [
          'Бригада должна быть удалена с рабочего места',
          'Двери распределительного устройства должны быть закрыты на замок',
          'Наряд-допуск остается у производителя работ или наблюдающего',
          'Допуск к работе после перерыва выполняет производитель работ или наблюдающий с указанием в наряде-допуске',
        ],
        correctAnswers: [
          'Допуск к работе после перерыва выполняет производитель работ или наблюдающий с указанием в наряде-допуске',
        ],
      },
      {
        code: '64845150',
        text:
          'Кому осуществляет передачу оперативной информации об авариях в электроэнергетике оперативный персонал объекта, уполномоченный организацией? Выберите правильный вариант ответа.',
        answers: [
          'Территориальному органу по делам гражданской обороны и чрезвычайным ситуациям',
          'Территориальному органу Ростехнадзора',
          'Органу местного самоуправления',
          'Системному оператору',
        ],
        correctAnswers: ['Территориальному органу Ростехнадзора'],
      },
      {
        code: '64845151',
        text:
          'На основании какой документации должны выполняться изменения проектных схем и конструкций оборудования, которые могут влиять на работу водоподготовительных установок и установок для очистки конденсатов, а также на водно-химический режим электростанции (тепловых сетей)? Выберите правильный вариант ответа.',
        answers: [
          'На основании распорядительной документации',
          'На основании проектной документации',
          'На основании ремонтной документации',
          'На основании эксплуатационной документации',
        ],
        correctAnswers: ['На основании проектной документации'],
      },
      {
        code: '64845152',
        text:
          'Расследование причин каких аварий осуществляет Ростехнадзор либо его территориальный орган? Выберите правильный вариант ответа.',
        answers: [
          'Только повреждение турбины номинальной мощностью 10 МВт и более с разрушением проточной части турбины, изменением формы и геометрических размеров или смещением корпуса турбины на фундаменте',
          'Только повреждение энергетического котла паропроизводительностью 100 тонн в час и более или водогрейного котла производительностью 50 гигакалорий в час и более с разрушением, изменением формы или геометрических размеров котла или смещением блоков (элементов) котла или металлического каркаса',
          'Только нарушения в работе противоаварийной или режимной автоматики, в том числе обусловленные ошибочными действиями персонала, вызвавшие отключение объекта электросетевого хозяйства (высший класс напряжения 110 кВ и выше), отключение (включение) генерирующего оборудования, суммарная мощность которого составляет 100 МВт и более, или прекращение электроснабжения потребителей электрической энергии, суммарная мощность потребления которых составляет 100 МВт и более',
          'Расследование всех перечисленных аварий',
        ],
        correctAnswers: ['Расследование всех перечисленных аварий'],
      },
      {
        code: '64845153',
        text:
          'Что необходимо выполнить перед соединением или разрывом электрически связанных участков (проводов, тросов) на воздушных линиях и воздушных линиях связи? Выберите правильный вариант ответа.',
        answers: [
          'Уравнивание потенциалов участков',
          'Установку заземлений с обеих сторон разрыва (предполагаемого разрыва) с присоединением к разным заземлителям',
          'Заземление одного из участков',
          'Проверку отсутствия наведенного напряжения',
        ],
        correctAnswers: ['Уравнивание потенциалов участков'],
      },
      {
        code: '64845154',
        text:
          'С каким персоналом в организации должен проводиться производственный инструктаж? Выберите правильный вариант ответа.',
        answers: [
          'Только с временными работниками',
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
          'Только с работниками, принимаемыми на должности, не связанные с нахождением в зоне действующих энергоустановок и не связанных с их обслуживанием',
          'Только с командированными, студентами и учащимися, прибывшими на предприятие для производственного обучения или практики',
        ],
        correctAnswers: [
          'С работниками, относящимися к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64845155',
        text:
          'Где должны размещаться индивидуальные автоматические сигнализаторы напряжения? Выберите правильный вариант ответа.',
        answers: [
          'На каске',
          'На руке',
          'В нагрудном кармане',
          'На монтажном поясе работника',
        ],
        correctAnswers: ['На каске'],
      },
      {
        code: '64845156',
        text:
          'В какой срок после дня получения запроса уполномоченного органа в сфере электроэнергетики собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация направляют копии акта расследования уполномоченному органу в сфере электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'В 10-дневный срок',
          'В 30-дневный срок',
          'В 5-дневный срок',
          'В 15-дневный срок',
        ],
        correctAnswers: ['В 10-дневный срок'],
      },
      {
        code: '64845157',
        text:
          'По чьей команде вывешивается и снимается плакат "Не включать! Работа на линии!" на приводах разъединителей, которыми отключена для выполнения работ воздушная или кабельная линии? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
          'Выдающего наряд-допуск',
          'Ответственного руководителя работ',
          'Производителя работ',
        ],
        correctAnswers: [
          'Диспетчерского или оперативного персонала, в чьем соответственно диспетчерском или технологическом управлении находится воздушная линия, кабельно-воздушная линия или кабельная линия',
        ],
      },
      {
        code: '64845158',
        text:
          'Что является общими принципами организации отношений в сфере теплоснабжения? Выберите правильный вариант ответа.',
        answers: [
          'Только обеспечение экологической безопасности теплоснабжения',
          'Только обеспечение приоритетного использования комбинированной выработки электрической и тепловой энергии для организации теплоснабжения',
          'Только обеспечение безопасной эксплуатации объектов теплоснабжения',
          'Только соблюдение баланса экономических интересов теплоснабжающих организаций и интересов потребителей',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64845159',
        text:
          'Какие из перечисленных работ не относятся к специальным, право проведения которых должно быть зафиксировано записью в удостоверении? Выберите правильный вариант ответа.',
        answers: [
          'Работы, выполняемые со снятием рабочего напряжения с электроустановки или ее части с прикосновением к токоведущим частям, находящимся под наведенным напряжением более 25 В на рабочем месте',
          'Работы без снятия напряжения с электроустановки, выполняемые с прикосновением к первичным токоведущим частям, находящимся под рабочим напряжением',
          'Работы по измерению сопротивления изоляции',
          'Работы на высоте',
        ],
        correctAnswers: ['Работы по измерению сопротивления изоляции'],
      },
      {
        code: '64845160',
        text:
          'Что из перечисленного, согласно Инструкции по применению и испытанию средств защиты, используемых в электроустановках, является "средством защиты, используемым одним человеком"? Выберите правильный вариант ответа.',
        answers: [
          'Средство индивидуальной защиты',
          'Экранирующее устройство',
          'Средство защиты работающего',
          'Электрозащитное средство',
        ],
        correctAnswers: ['Средство индивидуальной защиты'],
      },
      {
        code: '64845161',
        text:
          'Какое требование к установке переносных заземлений указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Переносное заземление сначала нужно присоединить к заземляющему устройству, а затем, после проверки отсутствия напряжения, установить на токоведущие части',
          'Устанавливать заземление на токоведущие части необходимо непосредственно после проверки отсутствия напряжения',
          'Переносные заземления в электроустановках необходимо устанавливать с применением диэлектрических перчаток',
          'Производится проверка отсутствия напряжения непосредственно перед присоединением переносного заземления к заземляющему устройству, а затем производится наложение на токоведущие части',
        ],
        correctAnswers: [
          'Производится проверка отсутствия напряжения непосредственно перед присоединением переносного заземления к заземляющему устройству, а затем производится наложение на токоведущие части',
        ],
      },
      {
        code: '64845162',
        text:
          'С какими категориями персонала проводится подготовка по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'Со всеми категориями персонала',
          'Со всеми категориями персонала, за исключением административно-технического',
          'Со всеми категориями персонала, за исключением вспомогательного',
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
        correctAnswers: [
          'С лицами из числа диспетчерского, оперативного, оперативно-ремонтного, ремонтного персонала',
        ],
      },
      {
        code: '64845163',
        text:
          'С какой периодичностью осуществляется оперативно-диспетчерское управление в энергосистеме посредством централизованного управления эксплуатационным состоянием и взаимосвязанными технологическими режимами работы объектов электроэнергетики и энергопринимающих установок потребителей электрической энергии, образующими в совокупности электроэнергетический режим соответствующей энергосистемы? Выберите правильный вариант ответа.',
        answers: [
          'Круглосуточно',
          'Каждый час',
          'Каждые 3 часа',
          'Через каждые 30 минут',
        ],
        correctAnswers: ['Круглосуточно'],
      },
      {
        code: '64845164',
        text:
          'За сколько месяцев владелец объекта электроэнергетики обязан уведомить соответствующий диспетчерский центр о планируемом изменении формы организации оперативного обслуживания объекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не менее чем за 3 месяца',
          'Не менее чес за 2 месяца',
          'Не менее чем за 1 месяц',
          'Не менее чем за 10 дней',
        ],
        correctAnswers: ['Не менее чем за 3 месяца'],
      },
      {
        code: '64845165',
        text:
          'По согласованию с кем осуществляется вывод в ремонт линий электропередачи, оборудования и устройств, отнесенных к объектам диспетчеризации, и изменение технологических параметров, отнесенных к объектам диспетчеризации, вследствие вывода в ремонт линий электропередачи, оборудования и устройств объектов электроэнергетики, не являющихся объектами диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'С субъектом оперативно-диспетчерского управления',
          'С Ростехнадзором',
          'С субъектом электроэнергетики',
          'С технической комиссией собственника или иного законного владельца',
        ],
        correctAnswers: ['С субъектом оперативно-диспетчерского управления'],
      },
      {
        code: '64845166',
        text:
          'При каких температурах разрешается пользоваться фильтрующими противогазами с гопкалитовым патроном для защиты от окиси углерода? Выберите правильный вариант ответа.',
        answers: [
          'При температурах не ниже 6 °С',
          'При температурах не ниже 4 °С',
          'При температурах не ниже 0 °С',
          'При температурах не ниже 2 °С',
        ],
        correctAnswers: ['При температурах не ниже 6 °С'],
      },
      {
        code: '64845167',
        text:
          'Каковы условия проведения специальной подготовки персонала? Выберите правильный вариант ответа.',
        answers: [
          'Должна проводиться по утвержденному графику в рабочее время',
          'Должна проводиться еженедельно в течение одной рабочей смены с отрывом от производства',
          'Должна проводиться с отрывом от выполнения основных функций и составлять от 5 до 20 % его рабочего времени',
          'Должна проводиться еженедельно в течение одной рабочей смены и составлять до 20 % его рабочего времени',
        ],
        correctAnswers: [
          'Должна проводиться с отрывом от выполнения основных функций и составлять от 5 до 20 % его рабочего времени',
        ],
      },
      {
        code: '64845168',
        text:
          'Кто имеет право устанавливать переносные заземления в электроустановках напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Два работника, один с группой IV по электробезопасности из числа оперативного персонала, другой с группой III по электробезопасности',
          'Два работника, один с группой IV по электробезопасности, другой с группой III по электробезопасности',
          'Два работника с группой III по электробезопасности из числа оперативного персонала',
          'Один работник с группой V по электробезопасности из числа оперативного персонала',
        ],
        correctAnswers: [
          'Два работника, один с группой IV по электробезопасности из числа оперативного персонала, другой с группой III по электробезопасности',
        ],
      },
      {
        code: '64845169',
        text:
          'С каким персоналом организаций электроэнергетики должны проводиться тренировки для формирования и поддержания навыков работы и определения способности на основе совместных действий предотвращать развитие и ликвидировать нарушения нормального режима работы электрической части энергосистемы и технологические нарушения в работе объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Только с диспетчерским персоналом',
          'Только с оперативным персоналом',
          'Только с оперативно-ремонтным персоналом',
          'Со всеми перечисленными',
        ],
        correctAnswers: ['Со всеми перечисленными'],
      },
      {
        code: '64845170',
        text:
          'Какие действия персонала не относятся к организационным мероприятиям? Выберите правильный вариант ответа.',
        answers: [
          'Оформление работ нарядом-допуском, распоряжением или перечнем работ, выполняемых в порядке текущей эксплуатации',
          'Выдача разрешения на подготовку рабочего места и на допуск к работе',
          'Оформление перерыва в работе, перевода на другое место, окончания работы',
          'Вывешивание запрещающих плакатов на приводах ручного и на ключах дистанционного управления коммутационных аппаратов',
        ],
        correctAnswers: [
          'Вывешивание запрещающих плакатов на приводах ручного и на ключах дистанционного управления коммутационных аппаратов',
        ],
      },
      {
        code: '64845171',
        text:
          'Кто несет ответственность за проведение целевого инструктажа ответственному руководителю работ? Выберите правильный вариант ответа.',
        answers: [
          'Выдающий наряд-допуск',
          'Дежурный оперативный персонал',
          'Допускающий',
          'Специалист по охране труда',
        ],
        correctAnswers: ['Выдающий наряд-допуск'],
      },
      {
        code: '64845172',
        text:
          'На какой срок и сколько раз может быть продлен наряд-допуск на работы в электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Не более 15 календарных дней и не более чем 2 раза',
          'Не более 15 календарных дней и не более чем 1 раз',
          'Не более 30 календарных дней и не более чем 1 раз',
          'Не более 3 календарных дней и не более чем 2 раза',
        ],
        correctAnswers: ['Не более 15 календарных дней и не более чем 1 раз'],
      },
      {
        code: '64845173',
        text:
          'От каких факторов не зависит необходимость и длительность каждого этапа подготовки по новой должности оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'От стажа практической работы по смежным должностям',
          'От занимаемой должности перед допуском к подготовке на новой должности и с учетом технической сложности объекта',
          'От уровня профессионального образования работника и технических знаний',
          'От возраста работника',
        ],
        correctAnswers: ['От возраста работника'],
      },
      {
        code: '64845174',
        text:
          'За какой период времени владельцы объектов электроэнергетики при организации технического освидетельствования объекта технического освидетельствования, в отношении которого производится оценка его технического состояния в соответствии с методикой оценки технического состояния основного технологического оборудования и линий электропередачи электрических станций и электрических сетей, со значением его индекса технического состояния от "0" до "26" включительно, должны направить в орган федерального государственного энергетического надзора либо его территориальный орган уведомление о начале работы комиссии и возможности принятия участия в работе комиссии его представителей? Выберите правильный вариант ответа.',
        answers: [
          'Не позднее, чем за 10 рабочих дней до даты проведения технического освидетельствования',
          'Не позднее, чем за 15 рабочих дней до даты проведения технического освидетельствования',
          'Не позднее, чем за 7 рабочих дней до даты проведения технического освидетельствования',
          'Не позднее, чем за 5 рабочих дней до даты проведения технического освидетельствования',
        ],
        correctAnswers: [
          'Не позднее, чем за 10 рабочих дней до даты проведения технического освидетельствования',
        ],
      },
      {
        code: '64845175',
        text:
          'Какие плакаты при выполнении работ на электроустановках должны быть вывешены на приводах (рукоятках приводов) коммутационных аппаратов с ручным управлением (выключателей, отделителей, разъединителей, рубильников, автоматов) во избежание подачи напряжения на рабочее место? Выберите правильный вариант ответа.',
        answers: [
          '"Не включать! Работают люди"',
          '"Стой! Напряжение"',
          '"Работать здесь"',
        ],
        correctAnswers: ['"Не включать! Работают люди"'],
      },
      {
        code: '64845176',
        text:
          'Какие требования предъявляются к внешнему виду диэлектрических ковров? Выберите правильный вариант ответа.',
        answers: [
          'Особых требований не предусмотрено',
          'Они должны быть с ровной поверхностью, разноцветные',
          'Они должны иметь быть с рифленой лицевой поверхностью, одноцветные',
          'Они должны иметь быть с рифленой лицевой поверхностью, разноцветные',
        ],
        correctAnswers: [
          'Они должны иметь быть с рифленой лицевой поверхностью, одноцветные',
        ],
      },
      {
        code: '64845177',
        text:
          'Что из перечисленного должны содержать предложения в отношении перечня мероприятий по обеспечению вывода из эксплуатации объекта диспетчеризации, входящего в состав объекта по производству электрической энергии (мощности), функционирующего в составе Единой энергетической системы России? Выберите правильный вариант ответа.',
        answers: [
          'Не менее двух вариантов мероприятий по проектированию, строительству, реконструкции, модернизации и (или) техническому перевооружению объектов электросетевого хозяйства, выполнение которых необходимо для обеспечения возможности вывода объекта диспетчеризации из эксплуатации',
          'Все перечисленное',
          'Характеристики балансов мощности энергорайона размещения объекта, выводимого из эксплуатации',
          'Результаты расчетов статической устойчивости и токов короткого замыкания для рекомендуемого варианта мероприятий на объектах электросетевого хозяйства',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64845178',
        text:
          'С кем должны быть согласованы включение в работу и отключение оборудования, которое может вызывать ухудшение качества воды и пара? Выберите правильный вариант ответа.',
        answers: [
          'С техническим руководителем электростанции',
          'С химической службой энергосистемы',
          'С химическим цехом (лабораторией или соответствующим подразделением)',
          'С вышестоящей организацией',
          'С организацией, установившей действующие нормы качества',
        ],
        correctAnswers: [
          'С химическим цехом (лабораторией или соответствующим подразделением)',
        ],
      },
      {
        code: '64845179',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работники из числа административно-технического персонала, имеющий группу V по электробезопасности',
          'Работники, имеющий группу IV по электробезопасности, и право единоличного осмотра на основании письменного распоряжения руководителя организации',
          'Работники из числа ремонтного персонала, имеющий группу по электробезопасности не ниже IV',
          'Работник из числа административно-технического персонала, имеющий группу по электробезопасности IV',
        ],
        correctAnswers: [
          'Работники из числа административно-технического персонала, имеющий группу V по электробезопасности',
        ],
      },
      {
        code: '64845180',
        text:
          'Что понимается под аварией на объекте электроэнергетики и (или) энергопринимающей установке? Выберите правильный вариант ответа.',
        answers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ',
          'Отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федерального закона "О промышленной безопасности опасных производственных объектов", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
          'Разрушение сооружений и (или) технических устройств, применяемых на опасном производственном объекте; неконтролируемые взрыв и (или) выброс опасных веществ; отказ или повреждение технических устройств, применяемых на опасном производственном объекте, отклонение от режима технологического процесса, нарушение положений Федеральных законов "Об электроэнергетике" и "О теплоснабжении", других федеральных законов и иных нормативных правовых актов Российской Федерации, а также нормативных технических документов, устанавливающих правила ведения работ на опасном производственном объекте (если они не содержат признаков аварии)',
        ],
        correctAnswers: [
          'Технологические нарушения на объекте электроэнергетики и (или) энергопринимающей установке, приведшие к разрушению или повреждению зданий, сооружений и (или) технических устройств (оборудования) объекта электроэнергетики и (или) энергопринимающей установки, неконтролируемому взрыву, пожару и (или) выбросу опасных веществ, отклонению от установленного технологического режима работы объектов электроэнергетики и (или) энергопринимающих установок, нарушению в работе релейной защиты и автоматики, автоматизированных систем оперативно-диспетчерского управления в электроэнергетике или оперативно-технологического управления либо обеспечивающих их функционирование систем связи, полному или частичному ограничению режима потребления электрической энергии (мощности), возникновению или угрозе возникновения аварийного электроэнергетического режима работы энергосистемы',
        ],
      },
      {
        code: '64845181',
        text:
          'В течение какого периода времени должны храниться акты приемки отремонтированных гидротехнических сооружений у субъекта электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'В течение всего периода эксплуатации гидросооружений до их ликвидации',
          'Не более 2 лет',
          'Не более 5 лет',
          'Не более 10 лет',
        ],
        correctAnswers: [
          'В течение всего периода эксплуатации гидросооружений до их ликвидации',
        ],
      },
      {
        code: '64845182',
        text:
          'В каком из перечисленных случаев не допускается эксплуатация группы подогревателей высокого давления, объединенных аварийным обводом? Выберите правильный вариант ответа.',
        answers: [
          'Только при отсутствии или неисправности элементов защиты хотя бы на одном подогревателе высокого давления',
          'Только при неисправности клапана регулятора уровня любого подогревателя высокого давления',
          'Только при отключении по пару любого подогревателя высокого давления',
          'В любом из указанных случаев',
        ],
        correctAnswers: ['В любом из указанных случаев'],
      },
      {
        code: '64845183',
        text:
          'Какие работники могут выполнять единоличный осмотр электроустановок, электротехнической части технологического оборудования напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Работники из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работник из числа административно-технического персонала (руководящие работники и специалисты), на которого возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу IV и право единоличного осмотра на основании организационно-распорядительных документов организации',
          'Работники из числа ремонтного персонала, имеющий группу не ниже III',
          'Работники из числа административно-технического персонала, имеющий группу IV',
          'Работники, имеющий группу II и право единоличного осмотра на основании письменного распоряжения руководителя организации',
        ],
        correctAnswers: [
          'Работники из числа оперативного персонала, имеющий группу не ниже III, эксплуатирующий данную электроустановку, находящийся на дежурстве, либо работник из числа административно-технического персонала (руководящие работники и специалисты), на которого возложены обязанности по организации технического и оперативного обслуживания, проведения ремонтных, монтажных и наладочных работ в электроустановках, имеющий группу IV и право единоличного осмотра на основании организационно-распорядительных документов организации',
        ],
      },
      {
        code: '64845184',
        text:
          'С какой периодичностью в случае аварии на объекте электроэнергетики оперативный персонал объекта должен уточнять информацию по запросу диспетчерского персонала субъекта оперативно-диспетчерского управления? Выберите правильный вариант ответа.',
        answers: [
          'В течение 6 часов после получения запроса и далее ежесуточно (до 06.00 каждых суток)',
          'В течение 12 часов после получения запроса и далее ежесуточно (до 08.00 каждых суток)',
          'В течение 24 часов после получения запроса и далее ежесуточно (до 08.00 каждых суток)',
          'В течение 24 часов после получения запроса и далее ежесуточно (до 12.00 каждых суток)',
        ],
        correctAnswers: [
          'В течение 6 часов после получения запроса и далее ежесуточно (до 06.00 каждых суток)',
        ],
      },
      {
        code: '64845185',
        text:
          'Какой персонал должен проходить дублирование? Выберите правильный вариант ответа.',
        answers: [
          'Лица из числа оперативного персонала',
          'Лица, непосредственно связанные с ремонтом и техническим обслуживанием энергетического оборудования, после первичной проверки знаний',
          'Специалисты, связанные с наладкой и испытанием энергоустановок, после первичной проверки знаний',
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
        correctAnswers: [
          'Лица из числа диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64845186',
        text:
          'Какие из перечисленных требований при выполнении неотложных работ по распоряжению указаны неверно? Выберите правильный вариант ответа.',
        answers: [
          'Производитель работ или наблюдающий из числа оперативного персонала, соответственно выполняющий работу или осуществляющий надзор за работающими в электроустановках напряжением выше 1000 В, должен иметь группу IV по электробезопасности, а в электроустановках напряжением до 1000 В - группу III по электробезопасности',
          'Число работающих не должно превышать трех человек, включая работника, осуществляющего надзор',
          'Число работающих не должно превышать четырех человек, включая работника, осуществляющего надзор',
          'Продолжительность неотложных работ не более 1 часа без учета времени на подготовку рабочего места',
        ],
        correctAnswers: [
          'Число работающих не должно превышать четырех человек, включая работника, осуществляющего надзор',
        ],
      },
      {
        code: '64845187',
        text:
          'С какой периодичностью собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация представляют сводный отчет об авариях в электроэнергетике в орган федерального государственного энергетического надзора, уполномоченный орган в сфере электроэнергетики, а также субъекту оперативно-диспетчерского управления в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Ежемесячно',
          'Один раз в год',
          'Ежеквартально',
          'Один раз в полгода',
        ],
        correctAnswers: ['Ежемесячно'],
      },
      {
        code: '64845188',
        text:
          'На сколько календарных дней, в случае необходимости, руководитель Ростехнадзора может продлить срок проведения расследования причин аварии? Выберите правильный вариант ответа.',
        answers: [
          'Не более чем на 45 дней',
          'Не более чем на 20 дней',
          'Не более чем на 10 дней',
          'Не более чем на 3 дня',
        ],
        correctAnswers: ['Не более чем на 45 дней'],
      },
      {
        code: '64845189',
        text:
          'В течение какого срока должны храниться наряды-допуски, работы по которым полностью закончены, если при выполнении работ по этим нарядам-допускам не имели место аварии, инциденты или несчастные случаи? Выберите правильный вариант ответа.',
        answers: [
          'В течение 1 года',
          'В течение 20 суток',
          'В течение 6 месяцев',
          'В течение 30 суток',
        ],
        correctAnswers: ['В течение 1 года'],
      },
      {
        code: '64845190',
        text:
          'Кто дает разрешение на снятие напряжения для освобождения пострадавшего от действия электрического тока? Выберите правильный вариант ответа.',
        answers: [
          'Напряжение должно быть снято немедленно без предварительного разрешения',
          'Оперативный персонал энергообъекта',
          'Административно-технический персонал',
          'Вышестоящий оперативный персонал',
        ],
        correctAnswers: [
          'Напряжение должно быть снято немедленно без предварительного разрешения',
        ],
      },
      {
        code: '64845191',
        text:
          'Каким работникам предоставляется право выдачи нарядов-допусков и распоряжений (кроме работ по предотвращению аварий или ликвидации их последствий)? Выберите правильный вариант ответа',
        answers: [
          'Работникам из числа административно-технического персонала, имеющим группу V по электробезопасности (при эксплуатации электроустановок напряжением выше 1000 В), группу по электробезопасности не ниже IV (при эксплуатации электроустановок напряжением до 1000 В)',
          'Работникам из числа оперативного персонала организации, имеющим группу IV - в электроустановках напряжением выше 1000 В и группу III - в электроустановках напряжением до 1000 В',
          'Работникам из числа ремонтного персонала, имеющим группу не ниже IV, в соответствии с должностными инструкциями',
          'Работникам из числа оперативного персонала, имеющим группу не ниже III, в соответствии с должностными инструкциями',
        ],
        correctAnswers: [
          'Работникам из числа административно-технического персонала, имеющим группу V по электробезопасности (при эксплуатации электроустановок напряжением выше 1000 В), группу по электробезопасности не ниже IV (при эксплуатации электроустановок напряжением до 1000 В)',
        ],
      },
      {
        code: '64845192',
        text:
          'Какое из перечисленных возможных последствий не учитывается при выводе в ремонт объекта диспетчеризации и согласовании диспетчерской заявки? Выберите правильный вариант ответа.',
        answers: [
          'Угроза загрязнения окружающей среды',
          'Выход параметров электроэнергетического режима за пределы допустимых значений',
          'Угроза повреждения линий электропередачи, оборудования',
          'Нарушение устойчивости режима работы Единой энергетической системы России (ее части) или технологически изолированной территориальной электроэнергетической системы',
        ],
        correctAnswers: ['Угроза загрязнения окружающей среды'],
      },
      {
        code: '64845193',
        text:
          'Какое количество плакатов "Не включать! Работа на линии" должно вывешиваться на приводах разъединителей, которыми отключена для выполнения работ воздушная линия, кабельно-воздушная линия или кабельная линия, если на линии работает несколько бригад? Выберите правильный вариант ответа.',
        answers: [
          'Один, независимо от числа работающих бригад',
          'Два',
          'Не регламентируется',
          'По одному для каждой бригады',
        ],
        correctAnswers: ['Один, независимо от числа работающих бригад'],
      },
      {
        code: '64845194',
        text:
          'Какое минимальное количество членов комиссии организации по проверке знаний должно присутствовать при проведении процедуры проверки знаний работников организаций электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'Не менее четырех членов комиссии организации по проверке знаний',
          'Не менее трех членов комиссии организации по проверке знаний',
          'Не менее двух членов комиссии организации по проверке знаний',
          'Не менее пяти членов комиссии организации по проверке знаний',
        ],
        correctAnswers: [
          'Не менее трех членов комиссии организации по проверке знаний',
        ],
      },
      {
        code: '64845195',
        text:
          'Какие права предоставляются командированному персоналу привлекаемой организации? Выберите правильный вариант ответа.',
        answers: [
          'Право работы в действующих электроустановках в качестве выдающих наряд-допуск, ответственных руководителей и производителей работ, членов бригады',
          'Право работы в действующих электроустановках только в качестве выдающих наряд, ответственных руководителей и производителей работ',
          'Право работы в действующих электроустановках только в качестве членов бригады',
          'Право работы в действующих электроустановках только в качестве допускающих на воздушной линии',
        ],
        correctAnswers: [
          'Право работы в действующих электроустановках в качестве выдающих наряд-допуск, ответственных руководителей и производителей работ, членов бригады',
        ],
      },
      {
        code: '64845196',
        text:
          'В каких случаях проводится первичная проверка знаний работников организаций электроэнергетики? Выберите 2 варианта ответа.',
        answers: [
          'При перерыве в проверке знаний более 3 лет',
          'При поступлении на работу впервые',
          'После прохождения предэкзаменационной подготовки',
          'При нарушении работниками требований нормативных актов по охране труда',
        ],
        correctAnswers: [
          'При перерыве в проверке знаний более 3 лет',
          'При поступлении на работу впервые',
        ],
      },
      {
        code: '64845197',
        text:
          'В каком из перечисленных случаев разрешается эксплуатация теплообменных аппаратов? Выберите правильный вариант ответа.',
        answers: [
          'При отсутствии элементов защит',
          'При неисправности регуляторов уровня',
          'До истечения срока очередного освидетельствования',
          'После выявления дефектов, угрожающих нарушением надежной и безопасной работы',
        ],
        correctAnswers: ['До истечения срока очередного освидетельствования'],
      },
      {
        code: '64845198',
        text:
          'Передачу какой оперативной информации оперативный персонал объекта в обязательном порядке осуществляет диспетчерскому персоналу субъекта оперативно-диспетчерского управления в электроэнергетике? Выберите правильный вариант ответа.',
        answers: [
          'Полный сброс электрической или тепловой нагрузки электростанцией, в т.ч. с потерей собственных нужд',
          'Повреждение технологических зданий и сооружений, приводящее к отключению оборудования, угрозе отключения или невозможности его включения из резерва, ремонта, консервации',
          'Разгрузка, отключение или повреждение оборудования, приводящее к снижению величины располагаемой мощности электростанции на 50 МВт и более или на 25% установленной мощности электростанции и более',
          'Отключение или повреждение оборудования электрических сетей и/или линий электропередачи напряжением 110 кВ и выше',
          'Всю перечисленную информацию',
        ],
        correctAnswers: ['Всю перечисленную информацию'],
      },
      {
        code: '64845199',
        text:
          'Кто может выполнять перевод бригады на другое рабочее место в распределительном устройстве выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Допускающий, а также ответственный руководитель работ или производитель работ или наблюдающий в соответствии с поручением работника, выдающего наряд-допуск, с записью в строке "Отдельные указания" наряда-допуска',
          'Наблюдающий по поручению выдающего наряд-допуск',
          'Производитель работ по поручению выдающего наряд-допуск',
          'Ответственный руководитель по поручению выдающего наряд',
        ],
        correctAnswers: [
          'Допускающий, а также ответственный руководитель работ или производитель работ или наблюдающий в соответствии с поручением работника, выдающего наряд-допуск, с записью в строке "Отдельные указания" наряда-допуска',
        ],
      },
      {
        code: '64845200',
        text:
          'С какой периодичностью должен проводиться плановый производственный инструктаж для ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже одного раза в 3 месяца',
          'В зависимости от стажа работника, но не реже 1 раза в 6 месяцев',
          'На усмотрение главного технического руководителя в зависимости от характера выполняемой работы работника',
          'В зависимости от образования работника, но не реже 1 раза в 4 месяца',
        ],
        correctAnswers: ['Не реже одного раза в 3 месяца'],
      },
      {
        code: '64845201',
        text:
          'Какие допускаемые значения отклонений давления сетевой воды в обратном трубопроводе от заданных теплового и гидравлического режимов? Выберите правильный вариант ответа.',
        answers: [
          'Не более ±0,2 кгс/см² (±20 кПа)',
          'Не более ±0,3 кгс/см² (±30 кПа)',
          'Не более ±0,4 кгс/см² (±40 кПа)',
          'Не более ±0,5 кгс/см² (±50 кПа)',
        ],
        correctAnswers: ['Не более ±0,2 кгс/см² (±20 кПа)'],
      },
      {
        code: '64845202',
        text:
          'Какое требование Правил технической эксплуатации электростанций и сетей Российской Федерации к эксплуатации электродвигателей с короткозамкнутыми роторами указано неверно? Выберите правильный вариант ответа.',
        answers: [
          'Электродвигатели с короткозамкнутыми роторами разрешается пускать из холодного состояния 2 раза подряд, если заводской инструкцией не допускается большего количества пусков. Последующие пуски разрешаются после охлаждения электродвигателя в течение времени, определяемого заводской инструкцией для данного типа двигателя',
          'Электродвигатели с короткозамкнутыми роторами разрешается пускать из горячего состояния 2 раза. Последующий пуск разрешается после охлаждения электродвигателя в течение 10 минут',
          'Повторные включения электродвигателей в случае отключения их основными защитами разрешаются после обследования и проведения контрольных измерений сопротивления изоляции',
          'Повторное включение двигателей в случаях действия резервных защит до выяснения причины отключения не допускается',
        ],
        correctAnswers: [
          'Электродвигатели с короткозамкнутыми роторами разрешается пускать из горячего состояния 2 раза. Последующий пуск разрешается после охлаждения электродвигателя в течение 10 минут',
        ],
      },
      {
        code: '64845203',
        text:
          'Когда должна осуществляться подготовка персонала для обслуживания новых и реконструируемых объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'За 9 месяцев до пуска в эксплуатацию',
          'С опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
          'За год до пуска в эксплуатацию',
          'За пол года до пуска в эксплуатацию',
        ],
        correctAnswers: [
          'С опережением сроков ввода этих объектов до начала проведения пробных пусков и комплексного опробования оборудования',
        ],
      },
      {
        code: '64845204',
        text:
          'Какую группу по электробезопасности должны иметь работники из числа оперативного персонала, единолично обслуживающие электроустановки напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: ['Не ниже II', 'Группу III', 'Не ниже IV', 'Не ниже V'],
        correctAnswers: ['Группу III'],
      },
      {
        code: '64845205',
        text:
          'От кого должен получить подтверждение об окончании работ и удалении всех бригад с рабочего места диспетчерский или оперативный персонал перед отдачей команды на снятие плаката "Не включать! Работа на линии!"? Выберите правильный вариант ответа.',
        answers: [
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
          'От вышестоящего диспетчерского или оперативного персонала',
          'От выдающего наряд-допуск',
          'От ответственного руководителя работ',
        ],
        correctAnswers: [
          'От работника из числа оперативного персонала, выдающего разрешение на подготовку рабочего места и на допуск',
        ],
      },
      {
        code: '64845206',
        text:
          'В каком случае должны выполняться переключения в электроустановках, направленные на изменение технологического режима работы или эксплуатационного состояния ЛЭП, оборудования, устройств РЗА (за исключением переключений в электроустановках, выполняемых в целях предотвращения развития и ликвидации нарушений нормального режима в электрической части энергосистем или объектов электроэнергетики)? Выберите правильный вариант ответа.',
        answers: [
          'При наличии наряда-допуска',
          'При наличии письменного распоряжения технической комиссии',
          'При наличии письменного распоряжения технического руководителя',
          'При наличии диспетчерских или оперативных заявок, поданных, рассмотренных и согласованных',
        ],
        correctAnswers: [
          'При наличии диспетчерских или оперативных заявок, поданных, рассмотренных и согласованных',
        ],
      },
      {
        code: '64845207',
        text:
          'Какие меры безопасности необходимо принимать для предотвращения ошибочного включения коммутационных аппаратов при отсутствии в схеме предохранителей во время проведения планового ремонта электроустановки? Выберите правильный вариант ответа.',
        answers: [
          'Только закрытие кнопок',
          'Только наложение изолирующих накладок',
          'Только запирание рукояток или дверец шкафа управления',
          'Можно применять любые из перечисленных мер, либо провести расшиновку или отсоединение кабеля, проводов от коммутационного аппарата либо от оборудования, на котором будут проводиться работы',
        ],
        correctAnswers: [
          'Можно применять любые из перечисленных мер, либо провести расшиновку или отсоединение кабеля, проводов от коммутационного аппарата либо от оборудования, на котором будут проводиться работы',
        ],
      },
      {
        code: '64845208',
        text:
          'Какие формы работы с ремонтным персоналом должны использоваться? Выберите правильный вариант ответа.',
        answers: [
          'Только производственный инструктаж',
          'Все перечисленные формы работы',
          'Только предэкзаменационная подготовка и проверка знаний',
          'Только стажировка',
        ],
        correctAnswers: ['Все перечисленные формы работы'],
      },
      {
        code: '64845209',
        text:
          'Какими способами могут быть обнаружены места утечек хлора при обслуживании хлораторных установок? Выберите правильный вариант ответа.',
        answers: [
          'Всеми перечисленными',
          'Только по обмерзанию места утечки',
          'Только по низкой температуре сосуда, определяемой на ощупь',
          'Только по густому белому облаку, образующемуся при поднесении к месту утечки ваты, смоченной нашатырным спиртом (аммиачной водой)',
          'Только газоанализатором',
        ],
        correctAnswers: ['Всеми перечисленными'],
      },
      {
        code: '64845210',
        text:
          'Каким документом должны быть оформлены работы в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Только по наряду-допуску',
          'Только по распоряжению',
          'Только на основании перечня работ',
          'Любым из перечисленных документов',
        ],
        correctAnswers: ['Любым из перечисленных документов'],
      },
      {
        code: '64845211',
        text:
          'В каких электроустановках применяют диэлектрические галоши? Выберите правильный вариант ответа.',
        answers: [
          'В электроустановках напряжением до 1000 В',
          'В электроустановках напряжением выше 1000 В',
          'В электроустановках напряжением выше 10 кВ',
          'В электроустановках любого напряжения',
        ],
        correctAnswers: ['В электроустановках напряжением до 1000 В'],
      },
      {
        code: '64845212',
        text:
          'От какого воздействия должны быть защищены средства защиты из резины и полимерных материалов? Выберите правильный вариант ответа.',
        answers: [
          'Только от воздействия кислот, щелочей, масел, бензина и других разрушающих веществ',
          'Только от прямого воздействия солнечных лучей',
          'Только от теплоизлучения нагревательных приборов',
          'От воздействия кислот, щелочей, масел, бензина и других разрушающих веществ, а также от прямого воздействия солнечных лучей и теплоизлучения нагревательных приборов',
        ],
        correctAnswers: [
          'От воздействия кислот, щелочей, масел, бензина и других разрушающих веществ, а также от прямого воздействия солнечных лучей и теплоизлучения нагревательных приборов',
        ],
      },
      {
        code: '64845213',
        text:
          'В течение какого времени со дня утверждения комиссией акта расследования материалы расследования причин аварии подлежат хранению Ростехнадзором? Выберите правильный вариант ответа.',
        answers: [
          'Не менее одного года',
          'Не менее двух лет',
          'Не менее трех лет',
          'Не менее пяти лет',
        ],
        correctAnswers: ['Не менее трех лет'],
      },
      {
        code: '64845214',
        text:
          'Чем должны отличаться светильники аварийного освещения от светильников рабочего освещения? Выберите правильный вариант ответа.',
        answers: [
          'Видом источника света',
          'Знаками или окраской',
          'Типом светильника',
          'Степенью защиты',
        ],
        correctAnswers: ['Знаками или окраской'],
      },
      {
        code: '64845215',
        text:
          'Какие из перечисленных документов подтверждают соответствие технического устройства требованиям ТР ТС 010/2011 "Технический регламент Таможенного союза. О безопасности машин и оборудования"? Выберите 2 варианта ответа.',
        answers: [
          'Сертификат соответствия',
          'Заключение экспертизы промышленной безопасности',
          'Лицензия на соответствие',
          'Декларация соответствия',
        ],
        correctAnswers: ['Сертификат соответствия', 'Декларация соответствия'],
      },
      {
        code: '64845216',
        text:
          'Какую группу по электробезопасности должны иметь специалисты по охране труда субъектов электроэнергетики, контролирующие электроустановки? Выберите правильный вариант ответа.',
        answers: ['Не ниже III', 'Не ниже IV', 'Не ниже II', 'Не ниже V'],
        correctAnswers: ['Не ниже V'],
      },
      {
        code: '64845217',
        text:
          'Кем должно приниматься решение о применении организации ремонта по техническому состоянию оборудования для каждой единицы основного оборудования ПС напряжением 35 кВ и выше (силовые трансформаторы (автотрансформаторы), реакторы, высоковольтные выключатели)? Выберите правильный вариант ответа.',
        answers: [
          'Комиссией, состав которой должен определяться субъектом электроэнергетики',
          'Техническим руководителем субъекта электроэнергетики',
          'Руководителем ремонта',
          'Ростехнадзором',
        ],
        correctAnswers: [
          'Комиссией, состав которой должен определяться субъектом электроэнергетики',
        ],
      },
      {
        code: '64845218',
        text:
          'На какой персонал распространяются требования специальной подготовки? Выберите правильный вариант ответа.',
        answers: [
          'На работников структурных подразделений предприятия',
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
          'На ремонтный персонал, связанный с техническим обслуживанием, ремонтом, наладкой и испытанием энергоустановок',
          'На управленческий персонал и специалистов производственных подразделений',
        ],
        correctAnswers: [
          'На работников, относящихся к категории диспетчерского, оперативного и оперативно-ремонтного персонала',
        ],
      },
      {
        code: '64845219',
        text:
          'Каким образом оформляется периодический осмотр наличия и состояния электрозащитных средств? Выберите правильный вариант ответа.',
        answers: [
          'Записью результатов осмотра в журнал работником, ответственным за их состояние',
          'Оформлением акта проверки',
          'Записью результатов осмотра в журнал специалистом по охране труда',
          'Порядок оформления устанавливается техническим руководителем организации',
        ],
        correctAnswers: [
          'Записью результатов осмотра в журнал работником, ответственным за их состояние',
        ],
      },
      {
        code: '64845220',
        text:
          'Кто отвечает за правильную эксплуатацию и своевременный контроль за состоянием средств защиты, выданных в индивидуальное пользование? Выберите правильный вариант ответа.',
        answers: [
          'Работник, получивший эти средства защиты в индивидуальное пользование',
          'Работник, выдающий эти средства защиты в индивидуальное пользование',
          'Непосредственный руководитель работника, получившего эти средства защиты в индивидуальное пользование',
          'Специалист по охране труда предприятия',
        ],
        correctAnswers: [
          'Работник, получивший эти средства защиты в индивидуальное пользование',
        ],
      },
      {
        code: '64845221',
        text:
          'При каком условии работники, не обслуживающие электроустановки, могут быть допущены в распределительное устройство выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV, либо работника, имеющего право единоличного осмотра',
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III, либо работника, имеющего право единоличного осмотра',
          'В сопровождении опытного работника из числа ремонтного персонала, имеющего группу по электробезопасности не ниже V',
        ],
        correctAnswers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу IV, либо работника, имеющего право единоличного осмотра',
        ],
      },
      {
        code: '64845222',
        text:
          'Куда обязаны представлять владельцы объектов электроэнергетики схемы электрических соединений объектов электроэнергетики, оборудование и устройства которых относятся к объектам диспетчеризации, а также актуальные данные о технических параметрах и характеристиках линий электропередачи и оборудования? Выберите правильный вариант ответа.',
        answers: [
          'Диспетчерским центрам субъекта оперативно-диспетчерского управления',
          'Субъектам оперативно-технического управления',
          'Минэнерго России',
          'Органам местного самоуправления',
        ],
        correctAnswers: [
          'Диспетчерским центрам субъекта оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64845223',
        text:
          'На каком расстоянии устанавливается охранная зона вдоль границы земельного участка, предоставленного для размещения объекта по производству электрической энергии, для объектов высокой категории опасности? Выберите правильный вариант ответа.',
        answers: [
          'На расстоянии 50 метров от указанной границы',
          'На расстоянии 30 метров от указанной границы',
          'На расстоянии 10 метров от указанной границы',
          'На расстоянии 5 метров от указанной границы',
        ],
        correctAnswers: ['На расстоянии 50 метров от указанной границы'],
      },
      {
        code: '64845224',
        text:
          'С какой периодичностью должен производиться текущий ремонт зданий и сооружений тепловых электростанций? Выберите правильный вариант ответа.',
        answers: [
          'Круглогодично',
          'Один раз в пять лет',
          'Один раз в год',
          'Один раз в три года',
        ],
        correctAnswers: ['Круглогодично'],
      },
      {
        code: '64845225',
        text:
          'В какой срок с момента отключения (повреждения) или разрушения оборудования или устройств, явившиеся причиной или следствием пожара на объекте, собственник или иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки либо эксплуатирующая их организация принимает решение о создании комиссии по расследованию причин аварии и ее составе? Выберите правильный вариант ответа.',
        answers: [
          'В срок не позднее 24 часов',
          'В срок не позднее 12 часов',
          'В срок не позднее 48 часов',
          'В срок не позднее 3 рабочих дней',
        ],
        correctAnswers: ['В срок не позднее 24 часов'],
      },
      {
        code: '64845226',
        text:
          'Что должно быть обозначено на переносном заземлении? Выберите правильный вариант ответа.',
        answers: [
          'Только номинальное напряжение электроустановки',
          'Только сечение проводов',
          'Только инвентарный номер',
          'Все перечисленное',
        ],
        correctAnswers: ['Все перечисленное'],
      },
      {
        code: '64845227',
        text:
          'Какое количество соединителей допускается на каждом проводе или тросе пересекающей воздушной линии в пролетах пересечения ее с другими воздушными линиями и линиями связи? Выберите правильный вариант ответа.',
        answers: [
          'Не более 2 соединителей',
          'Не более 3 соединителей',
          'Не регламентируется',
          'Не более 1 соединителя',
        ],
        correctAnswers: ['Не более 2 соединителей'],
      },
      {
        code: '64845228',
        text:
          'Для каких категорий работников проводится стажировка? Выберите правильный вариант ответа.',
        answers: [
          'Определяется индивидуально по решению руководителя организации или уполномоченного им лица',
          'Для всех категорий, за исключением вспомогательного',
          'Для всех категорий персонала',
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
        correctAnswers: [
          'Для диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала',
        ],
      },
      {
        code: '64845229',
        text:
          'Кто обязан обеспечить разработку и согласование предложений в отношении перечня мероприятий по обеспечению вывода из эксплуатации при получении от уполномоченного органа уведомления о необходимости представления документов, необходимых для рассмотрения заявления о выводе из эксплуатации объекта диспетчеризации? Выберите правильный вариант ответа.',
        answers: [
          'Заявитель - собственник объекта по производству электрической энергии (мощности)',
          'Субъект оперативно-диспетчерского управления',
          'Проектная организация',
          'Научно-исследовательская организация',
        ],
        correctAnswers: [
          'Заявитель - собственник объекта по производству электрической энергии (мощности)',
        ],
      },
      {
        code: '64845230',
        text:
          'С какой периодичностью должно проводиться дополнительное профессиональное образование работников, относящихся к категориям административно-технического, диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Не реже 1 раза в 5 лет',
          'Не реже 1 раза в 3 года',
          'Не реже 1 раза в год',
        ],
        correctAnswers: ['Не реже 1 раза в 5 лет'],
      },
      {
        code: '64845231',
        text:
          'Сколько экземпляров наряда-допуска (независимо от способа его передачи) заполняется в случаях, когда производитель работ назначается одновременно допускающим? Выберите правильный вариант ответа.',
        answers: ['2 экземпляра', '1 экземпляр', '3 экземпляра'],
        correctAnswers: ['2 экземпляра'],
      },
      {
        code: '64845232',
        text:
          'Кого уведомляет собственник, иной законный владелец объекта электроэнергетики и (или) энергопринимающей установки, либо эксплуатирующая их организация о возникновении аварии? Выберите правильный вариант ответа.',
        answers: [
          'Только диспетчерский центр субъекта оперативно-диспетчерского управления в электроэнергетике, в операционной зоне которого находятся объект электроэнергетики и (или) энергопринимающая установка',
          'Только орган федерального государственного энергетического надзора',
          'Только уполномоченный орган в сфере электроэнергетики, а также подведомственное уполномоченному органу в сфере электроэнергетики государственное бюджетное учреждение',
          'Всех перечисленных',
        ],
        correctAnswers: ['Всех перечисленных'],
      },
      {
        code: '64845233',
        text:
          'Какие допустимые нормы суточной утечки и суточного расхода с учетом продувок водорода в генераторе (синхронном компенсаторе) от общего количества газа при рабочем давлении, если иное не установлено документацией организации-изготовителя? Выберите правильный вариант ответа.',
        answers: [
          'Не более 5 % и не более 10 % соответственно',
          'Не более 7 % и не более 11 % соответственно',
          'Не более 6 % и не более 12 % соответственно',
          'Не более 8 % и не более 12 % соответственно',
        ],
        correctAnswers: ['Не более 5 % и не более 10 % соответственно'],
      },
      {
        code: '64845234',
        text:
          'Какую группу по электробезопасности при проведении неотложных работ должен иметь производитель работ или наблюдающий из числа оперативного персонала, соответственно выполняющий работу или осуществляющий надзор за работающими в электроустановках напряжением до 1000 В? Выберите правильный вариант ответа.',
        answers: ['Группу III', 'Группу IV', 'Группу V', 'Группу II'],
        correctAnswers: ['Группу III'],
      },
      {
        code: '64845235',
        text:
          'Под наблюдением каких работников должен осуществляться проезд автомобилей, подъемных сооружений и механизмов по территории открытого распределительного устройства и в охранной зоне воздушной линии выше 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'Одного из работников из числа оперативного персонала, работника, выдавшего наряд, или ответственного руководителя',
          'Наблюдающего с группой III по электробезопасности',
          'Производителя работ',
          'Члена бригады с группой III по электробезопасности',
        ],
        correctAnswers: [
          'Одного из работников из числа оперативного персонала, работника, выдавшего наряд, или ответственного руководителя',
        ],
      },
      {
        code: '64845236',
        text:
          'При каком условии работники, не обслуживающие электроустановки, могут допускаться в распределительное устройство до 1000 В? Выберите правильный вариант ответа.',
        answers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III, либо работника, имеющего право единоличного осмотра',
          'На основании письменного распоряжения руководителя организации',
          'В сопровождении опытного работника из числа ремонтного персонала, имеющего группу по электробезопасности не ниже V',
        ],
        correctAnswers: [
          'В сопровождении оперативного персонала, обслуживающего данную электроустановку, имеющего группу III, либо работника, имеющего право единоличного осмотра',
        ],
      },
      {
        code: '64845237',
        text:
          'Какой должна быть разрывная статическая нагрузка предохранительного пояса с амортизатором? Выберите правильный вариант ответа.',
        answers: [
          'Не менее 7000 Н',
          'Не менее 6000 Н',
          'Не менее 5000 Н',
          'Не менее 3000 Н',
        ],
        correctAnswers: ['Не менее 7000 Н'],
      },
      {
        code: '64845238',
        text:
          'Как должны храниться средства защиты органов дыхания? Выберите правильный вариант ответа.',
        answers: [
          'В шкафах, на стеллажах, в сухом помещении и в открытом виде',
          'Не регламентируется',
          'В сухом помещении в специальных сумках',
          'В сухом помещении в специальных ящиках',
        ],
        correctAnswers: ['В сухом помещении в специальных сумках'],
      },
      {
        code: '64845239',
        text:
          'Куда должны быть занесены сведения о текущем ремонте объектов электроэнергетики? Выберите правильный вариант ответа.',
        answers: [
          'В технический журнал эксплуатации зданий, сооружений',
          'В паспорт производственного здания или сооружения',
          'В ведомости объемов работ',
          'В акты осмотров зданий и сооружений',
        ],
        correctAnswers: [
          'В технический журнал эксплуатации зданий, сооружений',
        ],
      },
      {
        code: '64845240',
        text:
          'В какой последовательности необходимо выполнять технические мероприятия, обеспечивающие безопасность работ со снятием напряжения? Выберите правильный вариант ответа.',
        answers: [
          'Произвести необходимые отключения, вывесить запрещающие плакаты, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить указательные, предупреждающие и предписывающие плакаты',
          'Произвести необходимые отключения, вывесить запрещающие, указательные и предписывающие плакаты, установить заземление, проверить отсутствие напряжения на токоведущих частях',
          'Вывесить запрещающие, указательные и предписывающие плакаты, произвести необходимые отключения, проверить отсутствие напряжения на токоведущих частях, установить заземление',
          'Произвести необходимые отключения, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить запрещающие, указательные и предписывающие плакаты',
        ],
        correctAnswers: [
          'Произвести необходимые отключения, вывесить запрещающие плакаты, проверить отсутствие напряжения на токоведущих частях, установить заземление, вывесить указательные, предупреждающие и предписывающие плакаты',
        ],
      },
      {
        code: '64845241',
        text:
          'Какие организации обязаны обеспечить соответствие зданий, строений, сооружений требованиям энергетической эффективности и требованиям оснащенности их приборами учета используемых энергетических ресурсов путем выбора оптимальных архитектурных, функционально-технологических, конструктивных и инженерно-технических решений и их надлежащей реализации при осуществлении строительства, реконструкции, капитального ремонта? Выберите правильный вариант ответа.',
        answers: [
          'Застройщики',
          'Заказчики',
          'Проектные организации',
          'Местные органы исполнительной власти',
        ],
        correctAnswers: ['Застройщики'],
      },
      {
        code: '64845242',
        text:
          'Кто может выполнять проверку подготовки рабочего места при отсутствии оперативного персонала? Выберите правильный вариант ответа.',
        answers: [
          'Руководитель работ совместно с производителем работ с разрешения оперативного персонала',
          'Руководитель работ с разрешения допускающего',
          'Наблюдающий с разрешения оперативного персонала',
          'Производитель работ с разрешения допускающего',
        ],
        correctAnswers: [
          'Руководитель работ совместно с производителем работ с разрешения оперативного персонала',
        ],
      },
      {
        code: '64845243',
        text:
          'Куда субъекты электроэнергетики и потребители электрической энергии представляют необходимые исходные данные по принадлежащим им объектам электроэнергетики и энергопринимающим устройствам для осуществления планирования и управления электроэнергетическим режимом энергосистемы? Выберите правильный вариант ответа.',
        answers: [
          'В диспетчерские центры субъекта оперативно-диспетчерского управления',
          'В орган местного самоуправления',
          'Системному оператору',
          'В территориальный орган по делам гражданской обороны и чрезвычайным ситуациям',
        ],
        correctAnswers: [
          'В диспетчерские центры субъекта оперативно-диспетчерского управления',
        ],
      },
      {
        code: '64845244',
        text:
          'Кем устанавливается порядок установки предупреждающих знаков для обозначения границ охранных зон? Выберите правильный вариант ответа.',
        answers: [
          'Органом энергетического надзора',
          'Министерство Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствия стихийных бедствий',
          'Органами местного самоуправления',
          'Не регламентируется',
        ],
        correctAnswers: ['Органом энергетического надзора'],
      },
      {
        code: '64845245',
        text:
          'Кто проводит оценку динамики изменения значений параметров технического состояния основного технологического оборудования, в ходе которой подготавливается прогноз изменения индекса технического состояния такого оборудования, и времени достижения критического состояния, при которых эксплуатация такого оборудования будет недопустима? Выберите правильный вариант ответа.',
        answers: [
          'Субъект электроэнергетики',
          'Орган местного самоуправления',
          'Ремонтная организация',
          'Проектная организация',
        ],
        correctAnswers: ['Субъект электроэнергетики'],
      },
      {
        code: '64845246',
        text:
          'Каким образом устанавливается продолжительность дублирования конкретного работника? Выберите правильный вариант ответа.',
        answers: [
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
          'Определяется руководителем структурного подразделения',
          'Органами Ростехнадзора',
          'Определяется комиссией по проверке знаний в зависимости от его уровня профессиональной подготовки, стажа и опыта оперативной работы',
        ],
        correctAnswers: [
          'Организационно-распорядительным документом организации (ее филиала, представительства), содержащим сроки дублирования, указание лиц, ответственных за дублирование работника',
        ],
      },
      {
        code: '64845247',
        text:
          'С какой периодичностью должны быть осмотрены защитные каски с целью контроля отсутствия механических повреждений? Выберите правильный вариант ответа.',
        answers: [
          'Перед каждым применением',
          'Не реже 1 раза в неделю',
          'Не реже 1 раза в месяц',
          'Не чаще 1 раза в 10 дней',
        ],
        correctAnswers: ['Перед каждым применением'],
      },
      {
        code: '64845248',
        text:
          'Каким требованиям из перечисленных должны соответствовать применяемые при выполнении ремонтных работ средства измерения? Выберите правильный вариант ответа.',
        answers: [
          'Должны иметь свидетельство о поверке и калибровке',
          'Должны быть внесены в Федеральный информационный фонд по обеспечению единства измерений',
          'Всем перечисленным',
          'Должны иметь действующий паспорт',
        ],
        correctAnswers: ['Всем перечисленным'],
      },
      {
        code: '64845249',
        text:
          'Что должен пройти командированный персонал по прибытии на место своей командировки для выполнения работ в действующих электроустановках? Выберите правильный вариант ответа.',
        answers: [
          'Противоаварийную тренировку',
          'Вводный и первичный инструктажи по охране труда',
          'Проверку знаний норм и правил для подтверждения группы по электробезопасности',
          'Специальную подготовку',
        ],
        correctAnswers: ['Вводный и первичный инструктажи по охране труда'],
      },
      {
        code: '64845250',
        text:
          'Каким образом производится установка накладок на токоведущие части электроустановок напряжением выше 1000 В и их снятие? Выберите правильный вариант ответа.',
        answers: [
          'И установка, и снятие производятся с применением диэлектрических перчаток и изолирующих штанг либо клещей',
          'Установка производится двумя работниками, а снятие - одним с применением диэлектрических перчаток',
          'И установка, и снятие производятся одним работником с применением диэлектрических перчаток и изолирующих штанг либо клещей',
          'Установка производится одним работником, а снятие - двумя с применением диэлектрических перчаток',
        ],
        correctAnswers: [
          'И установка, и снятие производятся с применением диэлектрических перчаток и изолирующих штанг либо клещей',
        ],
      },
      {
        code: '64845251',
        text:
          'В какие сроки проводится проверка знаний работников, относящихся к категории диспетчерского, оперативного, оперативно-ремонтного и ремонтного персонала, при подготовке по новой должности? Выберите правильный вариант ответа.',
        answers: [
          'В сроки, установленные программой подготовки по новой должности',
          'Не позднее одного месяца после назначения на должность',
          'Не позднее одной недели после назначения на должность',
          'По истечению трех месяцев после назначения на должность',
        ],
        correctAnswers: [
          'В сроки, установленные программой подготовки по новой должности',
        ],
      },
      {
        code: '64845252',
        text:
          'Что в соответствии с Федеральным законом от 27.07.2010 № 190-ФЗ "О теплоснабжении" определяется как совокупность источников тепловой энергии и теплопотребляющих установок, технологически соединенных тепловыми сетями? Выберите правильный вариант ответа.',
        answers: [
          'Cистема теплоснабжения',
          'Тепловая сеть',
          'Тепловая нагрузка',
          'Качество теплоснабжения',
        ],
        correctAnswers: ['Cистема теплоснабжения'],
      },
      {
        code: '64845253',
        text:
          'На какие объекты по производству электрической энергии распространяются Правила установления охранных зон объектов по производству электрической энергии и особых условий использования земельных участков, расположенных в границах таких зон? Выберите правильный вариант ответа.',
        answers: [
          'Энергетические установки, предназначенные для производства электрической или электрической и тепловой энергии, состоящие из сооружений, оборудования для преобразования различных видов энергии в электрическую или электрическую и тепловую и распределительных устройств, мощность которых составляет 500 кВт и более',
          'На генерирующие объекты, функционирующие на основе использования энергии ветра',
          'На объекты, расположенные в границах земельного участка, оборудованного инженерно-техническими средствами охраны и защиты, принадлежащего организации, которая осуществляет деятельность в сфере промышленности и основным видом деятельности которой не является производство электрической энергии',
          'На мобильные (передвижные) генерирующие объекты',
          'На все перечисленные',
        ],
        correctAnswers: [
          'Энергетические установки, предназначенные для производства электрической или электрической и тепловой энергии, состоящие из сооружений, оборудования для преобразования различных видов энергии в электрическую или электрическую и тепловую и распределительных устройств, мощность которых составляет 500 кВт и более',
        ],
      },
      {
        code: '64845254',
        text:
          'Как часто должны проводиться испытания на теплофикационных установках? Выберите правильный вариант ответа.',
        answers: [
          'Должны проводиться на вновь смонтированных теплофикационных установках, а также в процессе эксплуатации теплофикационных установок с периодичностью не реже одного раза в год',
          'Должны проводиться на вновь смонтированных теплофикационных установках, а также в процессе эксплуатации теплофикационных установок с периодичностью не реже одного раза в 5 лет',
          'Должны проводиться на вновь смонтированных теплофикационных установках, а также в процессе эксплуатации теплофикационных установок с периодичностью не реже одного раза в 7 лет',
          'Должны проводиться на вновь смонтированных теплофикационных установках, а также в процессе эксплуатации теплофикационных установок с периодичностью не реже одного раза в 3 года',
        ],
        correctAnswers: [
          'Должны проводиться на вновь смонтированных теплофикационных установках, а также в процессе эксплуатации теплофикационных установок с периодичностью не реже одного раза в 5 лет',
        ],
      },
      {
        code: '64845255',
        text:
          'Как долго должен храниться технический паспорт объекта технического освидетельствования с результатами технического освидетельствования? Выберите правильный вариант ответа.',
        answers: [
          'До момента вывода объекта из эксплуатации с целью ликвидации',
          'Не более 5 лет',
          'Не более 10 лет',
          'Не менее 20 лет',
        ],
        correctAnswers: [
          'До момента вывода объекта из эксплуатации с целью ликвидации',
        ],
      },
      {
        code: '64845256',
        text:
          'Какая диспетчерская заявка подается для вывода в ремонт объекта диспетчеризации, не предусмотренного сводным месячным графиком ремонта, в случае необходимости срочного отключения линии электропередачи и оборудования для выполнения работ по предотвращению повреждения линии электропередачи, оборудования и аварийных отключений вследствие выхода параметров их работы за пределы, допустимые по условиям безопасной эксплуатации? Выберите правильный вариант ответа.',
        answers: [
          'Дополнительная диспетчерская заявка',
          'Аварийная диспетчерская заявка',
          'Неотложная диспетчерская заявка',
          'Внеплановая диспетчерская заявка',
        ],
        correctAnswers: ['Неотложная диспетчерская заявка'],
      },
      {
        code: '64845257',
        text:
          'В каком документе оформляется допуск к работам по распоряжению? Выберите правильный вариант ответа.',
        answers: [
          'В журнале учета работ по нарядам-допускам и распоряжениям',
          'В журнале регистрации нарядов-допусков и распоряжений',
          'Допуск к работам оформляется только в самом распоряжении',
          'В журнале выдачи распоряжений',
        ],
        correctAnswers: [
          'В журнале учета работ по нарядам-допускам и распоряжениям',
        ],
      },
      {
        code: '64845258',
        text:
          'Какое из перечисленных требований в отношении оперативного персонала указано верно? Выберите правильный вариант ответа.',
        answers: [
          'Все перечисленные требования указаны верно',
          'Владельцы смежных или иным образом технологически связанных объектов электроэнергетики, оперативный персонал которых непосредственно взаимодействует между собой в процессе оперативно-технологического управления указанными объектами, обмениваются списками оперативного персонала',
          'Владельцы объектов электроэнергетики для каждого центра управления сетями и объекта электроэнергетики в целях организации и осуществления оперативно-технологического управления определяют оперативный персонал, уполномоченный ими в рамках оперативно-технологического управления  на ведение оперативных переговоров и осуществление действий по изменению технологического режима работы и эксплуатационного состояния в отношении находящихся в его технологическом управлении или ведении линий электропередачи, оборудования и устройств и (или) на координации таких действий',
          'Владельцы объектов электроэнергетики, линий электропередачи, оборудование и устройства которых отнесены к объектам диспетчеризации, Оперативный персонал обязан контролировать токовую загрузку ЛЭП и оборудования, относящихся к объектам диспетчеризации, представляют списки оперативного персонала в соответствующие диспетчерские центры субъекта оперативно-диспетчерского управления',
        ],
        correctAnswers: ['Все перечисленные требования указаны верно'],
      },
      {
        code: '64845259',
        text:
          'В каких случаях из перечисленных не допускается применение экранирующих комплектов для защиты от воздействия электрического поля? Выберите правильный вариант ответа.',
        answers: [
          'Только для работников, непосредственно проводящих испытания оборудования повышенным напряжением',
          'Только при работах, не исключающих возможности прикосновения к находящимся под напряжением до 1000 В токоведущим частям',
          'Только при электросварочных работах',
          'Во всех перечисленных случаях',
        ],
        correctAnswers: ['Во всех перечисленных случаях'],
      },
      {
        code: '64845260',
        text:
          'Где фиксируется распределение инвентарных средств защиты между объектами (электроустановками) и оперативно-выездными бригадами? Выберите правильный вариант ответа.',
        answers: [
          'В перечнях, утвержденных техническим руководителем организации или работником, ответственным за электрохозяйство',
          'В перечнях, утвержденных руководителем структурного подразделения организации',
          'В перечнях, утвержденных профсоюзным комитетом организации',
          'В перечнях, утвержденных инспектором Ростехнадзора',
        ],
        correctAnswers: [
          'В перечнях, утвержденных техническим руководителем организации или работником, ответственным за электрохозяйство',
        ],
      },
      {
        code: '64845261',
        text:
          'В каком случае допускается использовать средства защиты с истекшим сроком годности? Выберите правильный вариант ответа.',
        answers: [
          'Допускается с разрешения непосредственного руководителя',
          'Допускается при отсутствии внешних повреждений',
          'Не допускается ни в каком случае',
          'Допускается при согласовании с организацией-изготовителем',
        ],
        correctAnswers: ['Не допускается ни в каком случае'],
      },
      {
        code: '64845262',
        text:
          'Кто имеет право включать электроустановки после полного окончания работ? Выберите правильный вариант ответа.',
        answers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
          'Производитель работ',
          'Любой из членов бригады',
          'Только ответственный за электрохозяйство',
        ],
        correctAnswers: [
          'Работник из числа оперативного персонала, получивший разрешение на включение электроустановки',
        ],
      },
      {
        code: '64845263',
        text:
          'Кто имеет право давать разрешение на обход и осмотр тепломеханического оборудования электростанций и тепловых сетей? Выберите правильный вариант ответа.',
        answers: [
          'Только персонал (дежурный персонал), ведущий режим оборудования',
          'Любой специалист',
          'Только главный инженер организации',
          'Только начальник цеха (участка)',
        ],
        correctAnswers: [
          'Только персонал (дежурный персонал), ведущий режим оборудования',
        ],
      },
      {
        code: '64845264',
        text:
          'Какой персонал допускается к работам с кислотой, щелочью и свинцом? Выберите правильный вариант ответа.',
        answers: [
          'Специально обученные работники',
          'Работники, имеющие группу III по электробезопасности',
          'Лица, назначенные приказом по предприятию',
          'Оперативно-ремонтный персонал предприятия',
        ],
        correctAnswers: ['Специально обученные работники'],
      },
    ],
  },
};
